import {
  createContext,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type NavControllerContextValue = {
  views: ReactNode[];
  push: (view: ReactNode) => void;
  pop: () => void;
  clear: () => void;
  current: ReactNode | null;
};
const NavControllerContext = createContext<
  NavControllerContextValue | undefined
>(undefined);
export const NavControllerProvider = ({ children }: PropsWithChildren) => {
  const [views, setViews] = useState<ReactNode[]>([]);

  const push = useCallback((view: ReactNode) => {
    setViews((prevViews) => [...prevViews, view]);
  }, []);
  const pop = useCallback(() => {
    setViews((prevViews) => prevViews.slice(0, -1));
  }, []);
  const clear = useCallback(() => {
    setViews([]);
  }, []);
  const current = views.length > 0 ? views[views.length - 1] : null;

  const value: NavControllerContextValue = {
    views,
    push,
    pop,
    clear,
    current,
  };

  useEffect(() => {
    return () => {
      // Clear all views on unmount
      clear();
    };
  }, [clear]);

  return (
    <NavControllerContext.Provider value={value}>
      {children}
    </NavControllerContext.Provider>
  );
};
export const useNavController = () => {
  const context = useContext(NavControllerContext);
  if (!context) {
    throw new Error(
      "useNavController must be used within a NavControllerProvider",
    );
  }
  return context;
};
