import { Box, Chip, Grid, Typography } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import type * as React from "react";

import { FilterFooter } from "~/components/filter/filterFooter";
import {
  FilterMultiSelect,
  type FilterMultiSelectOptionProps,
} from "~/components/filter/filterMultiSelectRow";
import { type FilterProps } from "~/components/filter/FilterProps";
import { type Option } from "~/components/filter/types";
import { Ellipsis } from "~/components/transactions/Ellipsis";
import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { useDesign } from "~/hooks/useTheme";
import { displayCurrencyName, middleTrim } from "~/lib/index";
import { type CurrencyIdentifier } from "~/types/index";

interface FilterCurrencySelectRowProps
  extends FilterProps<string[], CurrencyIdentifier[]>,
    FilterMultiSelectOptionProps {
  selectionDisabled?: string[];
  selectionOptions: CurrencyIdentifier[];
  selectionOptionsAlreadySorted: boolean;
}

const filter = createFilterOptions<Option<CurrencyIdentifier>>({
  stringify: (option) =>
    `${option?.data?.symbol}_${option?.data?.name}_${option?.data?.contractAddress}`,
});

export const CurrencySelect: React.FC<FilterCurrencySelectRowProps> = ({
  name,
  selection,
  selectionDisabled = [],
  setSelection,
  selectionOptions,
  selectionOptionsAlreadySorted,
  applyFilters,
  handleClose,
  ...selectOptionProps
}) => {
  const { tokens } = useDesign();
  const standardSelectOptions: Option<CurrencyIdentifier>[] =
    selectionOptions.map((c) => ({
      value: c.id,
      label: c.nftId ? c.nftId : c.name,
      data: c,
    }));
  return (
    <Grid container justifyContent="center">
      <FilterMultiSelect
        name={name}
        selection={selection}
        selectionDisabled={selectionDisabled}
        setSelection={(values) => {
          setSelection(
            standardSelectOptions
              .filter((o) => values?.includes(o.value) && o.data)
              .map((o) => o.data as CurrencyIdentifier),
          );
        }}
        selectionOptions={standardSelectOptions}
        filterOptions={filter}
        renderOptionContent={({ data }: Option<CurrencyIdentifier>) =>
          !!data && (
            <Box display="inline-flex" alignItems="center">
              <Box component="span" pr="2px">
                <CurrencyLogo currency={data} />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="Metropolis/Body/Light">
                  <Ellipsis maxWidth={180}>
                    {displayCurrencyName(data, false)}
                  </Ellipsis>
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="11.25rem"
                >
                  <Box display="flex" alignItems="baseline">
                    {!!data.contractAddress && (
                      <>
                        <Typography
                          variant="Metropolis/Caption/Medium/Regular"
                          sx={{ color: tokens.text.low, width: "3.438rem" }}
                        >
                          {middleTrim(data.contractAddress)}
                        </Typography>
                        <CopyIconButton
                          contentToCopy={data.contractAddress}
                          width={14}
                        />
                      </>
                    )}
                  </Box>
                  <Box>
                    {!!data.nftId && (
                      <Typography
                        variant="Metropolis/Caption/Medium/Regular"
                        sx={{ color: tokens.text.low }}
                      >
                        #{data.nftId}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        }
        renderTag={(option: Option<CurrencyIdentifier>, tagProps: any) =>
          !!option?.data && (
            <Chip
              size="small"
              key={option.value}
              {...tagProps}
              label={
                option.data?.nftId
                  ? `${option.data.symbol}-${option.data.nftId} `
                  : option.data?.symbol
              }
              avatar={
                <CurrencyLogo currency={option.data} width={18} height={18} />
              }
              disabled={selectionDisabled.includes(option.value)}
            />
          )
        }
        sorted={selectionOptionsAlreadySorted}
        limitTags={5}
        {...selectOptionProps}
      />
      {applyFilters && (
        <FilterFooter
          onApply={applyFilters}
          onReset={() => {
            setSelection(undefined);
          }}
          onClose={() => {
            if (handleClose) handleClose();
          }}
          selection={selection}
        />
      )}
    </Grid>
  );
};
