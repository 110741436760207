import { useEffect } from "react";

import { useIsMobile } from "~/hooks/useIsMobile";
import { updateIntercomUser } from "~/services/intercom";

declare global {
  interface Window {
    _ubaq: any; // Unbounce Analytics
  }
}

const optionsDefault = {
  mobileRestricted: true, // Hide/show on mobile breakpoints.
};

function show() {
  updateIntercomUser({
    hide_default_launcher: false,
  });
}

function hide() {
  updateIntercomUser({
    hide_default_launcher: true,
  });
}

export function useHideIntercom(
  hideIntercom: boolean,
  options = optionsDefault,
) {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!hideIntercom) {
      show();
    } else if (hideIntercom) {
      if (options.mobileRestricted) {
        if (isMobile) hide();
      } else {
        hide();
      }
    }
  }, [hideIntercom, isMobile, options]);
}
