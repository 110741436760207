export enum OnboardingActionType {
  SetFlow = "setFlow",
  SetAccount = "setAccount",
  SetTradingType = "setTradingType",
  TogglePlatformType = "togglePlatformType",
  SetShowFinishImportConfirmationModal = "setShowFinishImportConfirmationModal",
  SeenAreYouSurePage = "seenAreYouSurePage",
  SeenImportCEXReminderPage = "seenImportCEXReminderPage",
  SeenLetsReviewTxModal = "seenLetsReviewTxModal",
  SeenFakeLoading = "seenFakeLoading",
  SetOnboardingStep = "setOnboardingStep",
}
export enum OnboardingPageActionType {
  Next = "next",
  Previous = "previous",
  Skip = "skip",
  Invalid = "invalid",
  Finish = "finish",
}
export enum UserAccountType {
  Individual = "individual",
  Accountant = "accountant",
}
export enum OnboardingEducationPlatformType {
  Cex = "cex",
  DeFi = "defi",
}
export enum OnboardingEducationTradingType {
  Trading = "trading",
  Staking = "staking",
  Airdrop = "airdrop",
  Nft = "nft",
  LiquidityPool = "liquidityPool",
  Other = "other",
}
export enum Flow {
  Onboarding = "onboarding",
  CoinbaseOnboarding = "coinbaseOnboarding",
  ReOnboarding = "reOnboarding",
  MetaMaskOnboarding = "metaMaskOnboarding",
  ProviderOnboarding = "providerOnboarding",
}

export enum FixedMobileAppBarPosition {
  TOP = "top",
  BOTTOM = "bottom",
}

export enum OnboardingStep {
  ConfirmCountry = "confirmCountry",
  SelectIntegration = "selectIntegration",
  ImportData = "importData",
  FreeTaxPreview = "freeTaxPreview",
}

export enum DelayBannerMode {
  LongerThanUsual = "longerThanUsual",
  LongerThanExpected = "longerThanExpected",
  ComeBackLater = "comeBackLater",
}

export enum CalculatingStatus {
  Calculating = "calculating",
  Calculated = "calculated",
}
