import { SyncStatusPlatform } from "@ctc/types";

import { queryClient } from "~/components/queryClient";
import { Scope } from "~/state/enums";
import { savedImports } from "~/state/importsV2";
import { useSyncStatus } from "~/state/sync";

/**
 * @todo - maybe there is a better way of keeping track of the sync state?
 */
export const useLoadingHoldings = () => {
  const reportSyncStatus = useSyncStatus(Scope.Report);
  const isLoadingHoldings =
    queryClient.getQueryState(savedImports.holdings())?.status === "pending";
  const isLoadingReport = reportSyncStatus === SyncStatusPlatform.Pending;
  return isLoadingReport || isLoadingHoldings;
};
