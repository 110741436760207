import { BillingHistoryPanel } from "~/components/settings-modal/views/billing/BillingHistoryPanel";
import { PaymentMethodPanel } from "~/components/settings-modal/views/billing/PaymentMethodPanel";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { useIsAccountant, useIsManagingOwnBilling } from "~/redux/auth";
import { useLang } from "~/redux/lang";

export const Billing = () => {
  const lang = useLang();
  const isAccountant = useIsAccountant();
  const isManagingOwnBilling = useIsManagingOwnBilling();

  if (!isManagingOwnBilling || !isAccountant) {
    return null;
  }

  return (
    <SettingsBox title={lang.billing.title}>
      <BillingHistoryPanel />
      <PaymentMethodPanel />
    </SettingsBox>
  );
};
