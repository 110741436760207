import ArrowForward from "@mui/icons-material/ArrowForward";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Link, Stack, Typography } from "@mui/material";

import { Chip } from "~/components/ui/Chips";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function Irs2025Warning() {
  const lang = useLang();
  const { tokens } = useDesign();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.5rem"
      border={`1px solid ${tokens.border.brand}`}
      borderRadius="0.25rem"
      p="0.75rem"
      bgcolor={tokens.elevation.medium}
    >
      <Chip
        display="flex"
        borderRadius="0.25rem"
        gap="0.25rem"
        p="0.125rem 0.25rem"
        bgcolor={tokens.background.accent.neutral.medium}
        alignItems="center"
      >
        <InfoOutlinedIcon
          sx={{ width: "1rem", height: "1rem", color: tokens.text.high }}
        />
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.high}
        >
          {lang.settings.report.irs2025Warning.chip}
        </Typography>
      </Chip>

      <Box display="flex" flexDirection="column" gap="0.5rem">
        <Typography variant="Metropolis/Body/Regular">
          {lang.settings.report.irs2025Warning.content.summary}
        </Typography>
        <Stack direction="column" gap="0.25rem">
          <Typography variant="Metropolis/Header/H5">
            {lang.settings.report.irs2025Warning.content.header1}
          </Typography>
          <Typography variant="Metropolis/Body/Regular">
            {lang.settings.report.irs2025Warning.content.body1}
          </Typography>
        </Stack>
        <Stack direction="column" gap="0.25rem">
          <Typography variant="Metropolis/Header/H5">
            {lang.settings.report.irs2025Warning.content.header2}
          </Typography>
          <Typography variant="Metropolis/Body/Regular">
            {lang.settings.report.irs2025Warning.content.body2}
          </Typography>
        </Stack>
      </Box>

      <Link
        href="https://help.cryptotaxcalculator.io/en/articles/10383360-us-tax-guide-irs-2025-changes-our-automatic-migration-to-per-wallet-cost-basis"
        target="_blank"
        rel="noopener noreferrer"
        component="a"
        underline="none"
        width="fit-content"
      >
        <Box display="flex" alignItems="center" gap="0.25rem">
          <Typography
            variant="Metropolis/Body/Regular"
            color={tokens.text.brand}
          >
            {lang.settings.report.irs2025Warning.learnMore}
          </Typography>
          <ArrowForward sx={{ width: "1rem", height: "1rem" }} />
        </Box>
      </Link>
    </Box>
  );
}
