import { type Trade } from "@ctc/types";

import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { getOptionsFromErpAccounts } from "~/components/transactions/command-palette/helpers/getOptionsFromErpAccounts";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { Confirmation } from "~/components/transactions/command-palette/views/change-pnl-account/Confirmation";
import { PnLAccountTopSection } from "~/components/transactions/command-palette/views/change-pnl-account/PnLAccountTopSection";
import { useLang } from "~/redux/lang";
import { useErpAvailableAccountsQuery } from "~/state/erp";

export const SelectPnLAccount = ({ tradeType }: { tradeType: Trade }) => {
  const { push } = useNavController();
  const lang = useLang();
  const availableAccounts = useErpAvailableAccountsQuery().data;

  const options = getOptionsFromErpAccounts(availableAccounts ?? []);
  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangePnLAccount} />
          <PnLAccountTopSection tradeType={tradeType} />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.changeTo}
      options={options}
      onSelection={({ erpAccount }) => {
        push(<Confirmation tradeType={tradeType} erpPnLAccount={erpAccount} />);
      }}
    />
  );
};
