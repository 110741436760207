import { Box } from "@mui/material";
import CircularProgress, {
  type CircularProgressProps,
} from "@mui/material/CircularProgress";
import styled from "styled-components/macro";

export function Spinner() {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
}

export function BoxSpinner({ size }: { size?: CircularProgressProps["size"] }) {
  return (
    <Box m="0 50%" position="relative" right="1.25rem" marginTop="1rem">
      <CircularProgress size={size} />
    </Box>
  );
}

const Container = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  margin-top: -1.25rem;
  margin-left: -1.25rem;
  width: 2.5rem;
  height: 2.5rem;
`;
