import React from "react";

import { useExperimentVariant } from "~/analytics/posthog";
import {
  IrsPaywallModalChoosePlan,
  PAYWALL_MODAL_VARIANT as CHOOSE_PLAN_VARIANT,
} from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModalChoosePlan";
import {
  IrsPaywallModalPlanSelected,
  PAYWALL_MODAL_VARIANT as PLAN_SELECTED_VARIANT,
} from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModalPlanSelected";
import {
  IrsPaywallModalViewPlans,
  PAYWALL_MODAL_VARIANT as VIEW_PLANS_VARIANT,
} from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModalViewPlans";
import { type PaywallModalProps } from "~/contexts/paywall-modal-context/irs-paywall-modal/types";
import { FeatureFlag } from "~/types/enums";

export function IrsPaywallModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const isChoosePlan = useExperimentVariant(
    FeatureFlag.PaywallModals,
    CHOOSE_PLAN_VARIANT,
  );
  const isViewPlans = useExperimentVariant(
    FeatureFlag.PaywallModals,
    VIEW_PLANS_VARIANT,
  );
  const isPlanSelected = useExperimentVariant(
    FeatureFlag.PaywallModals,
    PLAN_SELECTED_VARIANT,
  );

  const isUsCountdownBannerEnabled = !!useExperimentVariant(
    FeatureFlag.DashboardCountdownBanner,
    "countdown-banner",
  );

  const props: PaywallModalProps = {
    open,
    onClose,
    showCountdown: isUsCountdownBannerEnabled,
  };

  if (isChoosePlan) {
    return <IrsPaywallModalChoosePlan {...props} />;
  }

  if (isViewPlans) {
    return <IrsPaywallModalViewPlans {...props} />;
  }

  if (isPlanSelected) {
    return <IrsPaywallModalPlanSelected {...props} />;
  }

  return null;
}
