import { type LocalCurrency, type SupportedLang } from "@ctc/types";
import { SyncStatusPlatform } from "@ctc/types";
import { ArrowBack, ArrowForward, Circle } from "@mui/icons-material";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import groupBy from "lodash/groupBy";
import isNil from "lodash/isNil";
import uniqBy from "lodash/uniqBy";
import moment from "moment-timezone";
import { type ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ActionButtons } from "~/components/settings-modal/views/tax/ActionButtons";
import { Ellipsis } from "~/components/transactions/Ellipsis";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { Chip } from "~/components/ui/Chips";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useIsMobile } from "~/components/ui/hooks";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { useDesign } from "~/hooks/useTheme";
import { useSelectedIds } from "~/hooks/useTransactions";
import { type Translation } from "~/lang/index";
import { GroupingCriteria } from "~/lib/enums";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { groupingCriteriaDefinitions } from "~/lib/groupingCriteraDefinition";
import {
  displayCryptoQuantity,
  displayFiatValue,
  middleTrim,
  multiply,
  plus,
} from "~/lib/index";
import { ActionDefinitions, TradeInfo } from "~/lib/tradeTypeDefinitions";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { refreshReport, useTimezone } from "~/redux/report";
import { type AppDispatch } from "~/redux/types";
import { isGroupedTrade } from "~/services/transactions";
import {
  useActionGroupingCheckQuery,
  useSelectableActionRows,
} from "~/state/actions";
import { Scope } from "~/state/enums";
import { useSyncStatus } from "~/state/sync";
import { GroupedTrade, TradeDirection } from "~/types/enums";
import {
  type ActionRow,
  type ActionType,
  type TransactionDetails,
} from "~/types/index";

export type GroupingCheckResult = {
  subactionMatch: boolean;
  matchedGroupType: GroupedTrade;
  txCountsMatch: boolean;
  unsatisfiedCriteria: GroupingCriteria[];
};

export function GroupingWizard({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const selectedIds = useSelectedIds();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [prevSelected, setPrevSelected] = useState<string[]>(selectedIds);
  const lang = useLang();
  const isMobile = useIsMobile();

  const lastPage = page === pageCount - 1;

  return (
    <GeneralDialog
      isOpen={isOpen}
      title={lang.groupingWizard.modalTitle}
      handleClose={onClose}
      closeOnClickAway
      showCancel={false}
      secondary={!lastPage}
      fullWidth={isMobile}
      actionText={
        lastPage
          ? lang.groupingWizard.actionButton
          : lang.groupingWizard.nextPage
      }
      handleAction={() => {
        if (lastPage) {
          onClose();
        } else {
          setPage(page + 1);
        }
      }}
      actionButtonEndIcon={!lastPage ? <ArrowForward /> : null}
      leftButton={
        page > 0 ? (
          <TextButton
            onClick={() => {
              setPage(page - 1);
            }}
            startIcon={<ArrowBack />}
          >
            {lang.groupingWizard.backPage}
          </TextButton>
        ) : null
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="1.5rem"
        justifyContent="space-between"
        minHeight="28rem"
        minWidth="18.75rem"
        maxWidth="34.375rem"
        width="40vw"
      >
        {isOpen ? (
          <GroupingWizardContents
            page={page}
            pageCount={pageCount}
            setPage={setPage}
            setPageCount={setPageCount}
            prevSelected={prevSelected}
            setPrevSelected={setPrevSelected}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
    </GeneralDialog>
  );
}

function GroupingWizardContents({
  page,
  pageCount,
  setPage,
  setPageCount,
  prevSelected,
  setPrevSelected,
}: {
  page: number;
  pageCount: number;
  setPage: (pages: number) => void;
  setPageCount: (pages: number) => void;
  prevSelected: string[];
  setPrevSelected: (selected: string[]) => void;
}) {
  const { tokens } = useDesign();
  const selectedIds = useSelectedIds();
  const result = useActionGroupingCheckQuery(selectedIds);

  useEffect(() => {
    if (pageCount === 0 || prevSelected !== selectedIds) {
      setPrevSelected(selectedIds);
      if (!result.data) {
        setPage(0);
        setPageCount(0);
        return;
      }

      const { matchedGroupType, txCountsMatch } = result.data;

      if (!matchedGroupType) {
        setPage(0);
        setPageCount(1);
        return;
      }

      if (!txCountsMatch) {
        setPage(1);
        setPageCount(2);
        return;
      }

      setPage(2);
      setPageCount(3);
    }
  }, [
    result,
    pageCount,
    prevSelected,
    selectedIds,
    setPage,
    setPageCount,
    setPrevSelected,
  ]);

  if (!result.data) {
    return (
      <Box
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {page === 0 ? (
        <MatchTypePage />
      ) : page === 1 ? (
        <MatchCountPage />
      ) : (
        <MatchCriteriaPage />
      )}
      <Box display="flex" justifyContent="center" width="100%" gap="0.25rem">
        {Array.from({ length: pageCount }, (_, index) => index).map(
          (_, index) => {
            return (
              <Circle
                key={index}
                style={{
                  fontSize: "0.75rem",
                  color:
                    page === index
                      ? tokens.border.neutral.high
                      : tokens.border.neutral.default,
                }}
              />
            );
          },
        )}
      </Box>
    </>
  );
}

function MatchTypePage() {
  const { tokens } = useDesign();
  const lang = useLang();
  const selectedIds = useSelectedIds();
  const selectableActionRows = useSelectableActionRows();
  const result = useActionGroupingCheckQuery(selectedIds);

  if (!result.data) return null;

  const relevantActions = getRelevantActions(selectedIds, selectableActionRows);
  const selectedTradeTypes: ActionType[] = relevantActions.reduce(
    (acc: ActionType[], curr: ActionRow) => {
      if (!acc.includes(curr.type)) {
        acc.push(curr.type);
      }
      return acc;
    },
    [] as ActionType[],
  );

  const { matchedGroupType } = result.data;
  return (
    <Box width="100%" display="flex" flexDirection="column" gap="1rem">
      <Box
        display="flex"
        flexDirection="column"
        p="0.5rem"
        width="100%"
        borderRadius="0.25rem"
        border={`1px solid ${tokens.border.neutral.default}`}
        bgcolor={tokens.elevation.highest}
        gap="0.5rem"
      >
        <Typography variant="Metropolis/Header/H4">
          {lang.groupingWizard.matchTypePage.title}
        </Typography>
        <Typography variant="Metropolis/Caption/Medium/Regular">
          {lang.groupingWizard.matchTypePage.description}
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" gap="0.25rem">
        <Typography variant="Metropolis/Header/H5">
          {lang.groupingWizard.matchTypePage.selected}
        </Typography>
        {selectedTradeTypes.map((actionType) => {
          const Icon = TradeIcons[actionType];
          return (
            <Chip key={actionType}>
              <Box display="flex" alignItems="center" gap="0.25rem">
                <Icon style={{ fontSize: "0.75rem" }} />
                {getActionTypeName({
                  actionType,
                  lang,
                })}
              </Box>
            </Chip>
          );
        })}
      </Box>
      <Box mb="-0.75rem">
        <Typography variant="Metropolis/Header/H5">
          {lang.groupingWizard.matchTypePage.allowed}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        p="0.5rem"
        gap="0.5rem"
        justifyContent="space-around"
      >
        {Object.values(GroupedTrade).map((actionType, index) => {
          const disabled =
            !!matchedGroupType && actionType !== matchedGroupType;
          const selected =
            !!matchedGroupType && actionType === matchedGroupType;

          const Icon = TradeIcons[actionType];
          return (
            <Box
              display="flex"
              flexDirection="column"
              p="0.5rem"
              width="48%"
              borderRadius="0.25rem"
              border={`1px solid ${
                selected
                  ? tokens.border.brand
                  : disabled
                    ? tokens.border.disabled
                    : tokens.border.neutral.default
              }`}
              bgcolor={
                selected
                  ? tokens.background.brand.default
                  : disabled
                    ? tokens.background.disabled
                    : tokens.elevation.highest
              }
              gap="0.5rem"
              key={index}
            >
              <Box display="flex" alignItems="center" gap="0.25rem">
                <Icon />
                <Typography variant="Metropolis/Header/H5">
                  {getActionTypeName({
                    actionType,
                    lang,
                  })}
                  :
                </Typography>
              </Box>
              {ActionDefinitions[actionType].subActionTypes.map(
                (subAction, index) => {
                  const Icon = TradeIcons[subAction];
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="0.25rem"
                      ml="0.5rem"
                      key={index}
                    >
                      <Icon />
                      <Typography variant="Metropolis/Caption/Medium/Regular">
                        {getActionTypeName({
                          actionType: subAction,
                          lang,
                        })}
                      </Typography>
                    </Box>
                  );
                },
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
function MatchCountPage() {
  const { tokens } = useDesign();
  const lang = useLang();
  const selectedIds = useSelectedIds();
  const selectableActionRows = useSelectableActionRows();
  const result = useActionGroupingCheckQuery(selectedIds);

  if (!result.data) return null;

  const { matchedGroupType } = result.data;

  const relevantActions = getRelevantActions(selectedIds, selectableActionRows);
  const allTradeTypes: ActionType[] = relevantActions.map(
    (action) => action.type,
  );

  const insOuts = groupBy(allTradeTypes, (tradeType) => {
    if (isGroupedTrade(tradeType)) {
      return "group";
    }
    return TradeInfo[tradeType].direction;
  });

  const inCount = insOuts[TradeDirection.In]?.length ?? 0;
  const outCount = insOuts[TradeDirection.Out]?.length ?? 0;

  const expectedRatio = ActionDefinitions[matchedGroupType].groupRatio;

  return (
    <Box width="100%" display="flex" flexDirection="column" gap="1rem">
      <Box
        display="flex"
        flexDirection="column"
        p="0.5rem"
        width="100%"
        borderRadius="0.25rem"
        border={`1px solid ${tokens.border.neutral.default}`}
        bgcolor={tokens.elevation.highest}
        gap="0.5rem"
      >
        <Typography variant="Metropolis/Header/H4">
          {lang.groupingWizard.matchCountPage.title({
            type: getActionTypeName({
              actionType: matchedGroupType,
              lang,
            }).toLowerCase(),
          })}
        </Typography>
        <Typography variant="Metropolis/Caption/Medium/Regular">
          {lang.groupingWizard.matchCountPage.description}
        </Typography>
      </Box>
      <Stack gap="0.5rem">
        <Typography>
          {lang.groupingWizard.matchCountPage.ratios[expectedRatio]({
            type: getActionTypeName({
              actionType: matchedGroupType,
              lang,
            }),
          })}
        </Typography>
        <Typography>
          {lang.groupingWizard.matchCountPage.selected({ inCount, outCount })}
        </Typography>
      </Stack>
    </Box>
  );
}
function MatchCriteriaPage() {
  const { tokens } = useDesign();
  const lang = useLang();
  const timezone = useTimezone();
  const selectedIds = useSelectedIds();
  const selectableActionRows = useSelectableActionRows();
  const result = useActionGroupingCheckQuery(selectedIds);
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const reduxDispatch: AppDispatch = useDispatch();
  const reportSyncStatus = useSyncStatus(Scope.Report);
  const isReportLoading = reportSyncStatus === SyncStatusPlatform.Pending;

  if (!result.data || !localCurrency || !languagePreference) return null;

  const { matchedGroupType, unsatisfiedCriteria } = result.data;
  const relevantActions = getRelevantActions(selectedIds, selectableActionRows);
  const txs = relevantActions
    .map((action) => [...action.outgoing, ...action.incoming])
    .flat(); // We dont really care about the fees here so no need to include

  const resolutionActions = sortUnsatisfiedCriteria(unsatisfiedCriteria).map(
    (criteria) =>
      getResolutionAction(
        criteria,
        txs,
        lang,
        timezone,
        localCurrency,
        languagePreference,
      ),
  );

  const handleRefresh = () => {
    reduxDispatch(refreshReport());
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap="1rem">
      <Box
        display="flex"
        flexDirection="column"
        p="0.5rem"
        width="100%"
        borderRadius="0.25rem"
        border={`1px solid ${tokens.border.neutral.default}`}
        bgcolor={tokens.elevation.highest}
        gap="0.5rem"
      >
        <Typography variant="Metropolis/Header/H4">
          {lang.groupingWizard.matchCriteriaPage.title({
            type: getActionTypeName({
              actionType: matchedGroupType,
              lang,
            }).toLowerCase(),
          })}
        </Typography>
        <Typography variant="Metropolis/Caption/Medium/Regular">
          {lang.groupingWizard.matchCriteriaPage.description}
        </Typography>
      </Box>
      <Stack gap="0.5rem">
        {resolutionActions.length ? (
          resolutionActions.map((action, index) => (
            <div key={index}>{action}</div>
          ))
        ) : (
          <ExplainerChoiceBlock
            title={lang.groupingWizard.matchCriteriaPage.noIssues.title}
            cta={lang.groupingWizard.matchCriteriaPage.noIssues.cta}
            options={[
              <ActionButtons
                isLoading={isReportLoading}
                onRefreshClick={handleRefresh}
                key="refresh"
              />,
            ]}
          />
        )}
      </Stack>
    </Box>
  );
}

function getRelevantActions(selectedIds: string[], rows: ActionRow[]) {
  return rows.filter((row) => selectedIds.includes(row._id));
}

function sortUnsatisfiedCriteria(
  criteria: GroupingCriteria[],
): GroupingCriteria[] {
  return criteria.sort(
    (a, b) =>
      groupingCriteriaDefinitions[a].sortOrder -
      groupingCriteriaDefinitions[b].sortOrder,
  );
}

function getResolutionAction(
  unsatisfiedCriteria: GroupingCriteria,
  relevantTransactions: TransactionDetails[],
  lang: Translation,
  timezone: string,
  localCurrency: LocalCurrency,
  languagePreference: SupportedLang,
): ReactNode {
  switch (unsatisfiedCriteria) {
    case GroupingCriteria.SameTimestamp:
    case GroupingCriteria.SimilarTimestamp: {
      const dedupedTimestamps = uniqBy(
        relevantTransactions,
        (tx) => tx.timestamp,
      ).map((tx) =>
        moment.tz(tx.timestamp, timezone).format("YYYY-MM-DD HH:mm:ss"),
      );

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.timestamp.title}
          cta={lang.groupingWizard.matchCriteriaPage.timestamp.cta}
          options={dedupedTimestamps}
        />
      );
    }
    case GroupingCriteria.SameTxId: {
      const dedupedIds = uniqBy(relevantTransactions, (tx) => tx.id).map((tx) =>
        middleTrim(tx.id),
      );

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.txId.title}
          cta={lang.groupingWizard.matchCriteriaPage.txId.cta}
          options={dedupedIds}
        />
      );
    }
    case GroupingCriteria.SameValueFeeExclusive:
    case GroupingCriteria.SameValueFeeInclusive: {
      const insOuts = groupBy(relevantTransactions, (tx) => {
        return TradeInfo[tx.trade].direction;
      });

      const inValue = insOuts[TradeDirection.In].reduce((acc, curr) => {
        return acc + multiply(curr.quantity, curr.price);
      }, 0);
      const outValue = insOuts[TradeDirection.Out].reduce((acc, curr) => {
        return acc + multiply(curr.quantity, curr.price);
      }, 0);

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.value.title}
          cta={lang.groupingWizard.matchCriteriaPage.value.cta}
          options={[
            displayFiatValue({
              value: inValue,
              localCurrency,
              locale: languagePreference,
            }),

            displayFiatValue({
              value: outValue,
              localCurrency,
              locale: languagePreference,
            }),
          ]}
        />
      );
    }
    case GroupingCriteria.SimilarQuantity: {
      const insOuts = groupBy(relevantTransactions, (tx) => {
        return TradeInfo[tx.trade].direction;
      });

      const inQuantity = insOuts[TradeDirection.In].reduce((acc, curr) => {
        return plus(acc, curr.quantity);
      }, 0);
      const outQuantity = insOuts[TradeDirection.Out].reduce((acc, curr) => {
        return plus(acc, curr.quantity);
      }, 0);

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.quantity.title}
          cta={lang.groupingWizard.matchCriteriaPage.quantity.cta}
          options={[
            displayCryptoQuantity({
              quantity: inQuantity,
              locale: languagePreference,
            }),
            displayCryptoQuantity({
              quantity: outQuantity,
              locale: languagePreference,
            }),
          ]}
        />
      );
    }
    case GroupingCriteria.SameCurrency: {
      const dedupedCurrencies = uniqBy(
        relevantTransactions,
        (tx) => tx.currencyIdentifier.id,
      ).map((tx) => tx.currencyIdentifier);

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.sameCurrency.title}
          cta={lang.groupingWizard.matchCriteriaPage.sameCurrency.cta}
          options={dedupedCurrencies.map((currency) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                gap="0.25rem"
                key={currency.id}
              >
                <CurrencyLogo
                  margin="0rem"
                  currency={currency}
                  width={18}
                  height={18}
                  style={{ borderRadius: "100%" }}
                />
                <Ellipsis maxWidth={100}>{currency.symbol}</Ellipsis>
              </Box>
            );
          })}
        />
      );
    }
    case GroupingCriteria.DifferentCurrency: {
      const dedupedCurrencies = uniqBy(
        relevantTransactions,
        (tx) => tx.currencyIdentifier.id,
      ).map((tx) => tx.currencyIdentifier);

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.differentCurrency.title}
          cta={lang.groupingWizard.matchCriteriaPage.differentCurrency.cta}
          options={dedupedCurrencies.map((currency) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                gap="0.5rem"
                key={currency.id}
              >
                <CurrencyLogo
                  margin="0rem"
                  currency={currency}
                  width={18}
                  height={18}
                  style={{ borderRadius: "100%" }}
                />
                <Ellipsis maxWidth={100}>{currency.symbol}</Ellipsis>
              </Box>
            );
          })}
        />
      );
    }
    case GroupingCriteria.SameFromTo: {
      const dedupedFromTo = uniqBy(relevantTransactions, (tx) => {
        if (TradeInfo[tx.trade].direction === TradeDirection.In) {
          return `${tx.from}__${tx.to}`;
        }
        return `${tx.to}__${tx.from}`;
      }).map((tx) => `${tx.from}__${tx.to}`);

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.sameFromTo.title}
          cta={lang.groupingWizard.matchCriteriaPage.sameFromTo.cta}
          options={dedupedFromTo}
        />
      );
    }
    case GroupingCriteria.SameAccount: {
      const dedupedAccounts = uniqBy(relevantTransactions, (tx) => {
        if (TradeInfo[tx.trade].direction === TradeDirection.Out) {
          return tx.from;
        }
        return tx.to;
      }).map((tx) => `${tx.from}__${tx.to}`);

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.sameAccount.title}
          cta={lang.groupingWizard.matchCriteriaPage.sameAccount.cta}
          options={dedupedAccounts}
        />
      );
    }
    case GroupingCriteria.DifferentSource: {
      const dedupedSources = uniqBy(
        relevantTransactions,
        (tx) => tx.source,
      ).map((tx) => tx.source);

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.differentSources.title}
          cta={lang.groupingWizard.matchCriteriaPage.differentSources.cta}
          options={dedupedSources.map((source) => source)}
        />
      );
    }
    case GroupingCriteria.DontGroup: {
      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.dontGroup.title}
          cta={lang.groupingWizard.matchCriteriaPage.dontGroup.cta}
          options={[
            lang.groupingWizard.matchCriteriaPage.dontGroup.enableGrouping,
          ]}
        />
      );
    }
    case GroupingCriteria.DifferentAssetType: {
      const dedupedCurrencies = uniqBy(
        relevantTransactions,
        (tx) => tx.currencyIdentifier.id,
      ).map((tx) => tx.currencyIdentifier);

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.differentCurrency.title}
          cta={lang.groupingWizard.matchCriteriaPage.differentCurrency.cta}
          options={dedupedCurrencies.map((currency) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                gap="0.5rem"
                key={currency.id}
              >
                <CurrencyLogo
                  margin="0rem"
                  currency={currency}
                  width={18}
                  height={18}
                  style={{ borderRadius: "100%" }}
                />
                <Ellipsis maxWidth={100}>{currency.symbol}</Ellipsis>
              </Box>
            );
          })}
        />
      );
    }
    case GroupingCriteria.FiatCurrency: {
      const dedupedCurrencies = uniqBy(
        relevantTransactions,
        (tx) => tx.currencyIdentifier.id,
      ).map((tx) => tx.currencyIdentifier);

      return (
        <ExplainerChoiceBlock
          title={lang.groupingWizard.matchCriteriaPage.fiatCurrency.title}
          cta={lang.groupingWizard.matchCriteriaPage.fiatCurrency.cta}
          options={dedupedCurrencies.map((currency) => currency.symbol)}
        />
      );
    }
    default: {
      const exhaustiveCheck: never = unsatisfiedCriteria;
      throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
  }
}

function ExplainerChoiceBlock({
  title,
  cta,
  options,
}: {
  title: string;
  cta: string;
  options: string[] | ReactNode[];
}) {
  const [selected, setSelected] = useState<number | undefined>(undefined);

  return (
    <Box display="flex" flexDirection="column" gap="0.25rem">
      <Typography variant="Metropolis/Header/H5">{title}</Typography>
      <Typography variant="Metropolis/Body/Regular">{cta}</Typography>
      <Box mt="0.25rem" display="flex" gap="0.5rem">
        {options.map((option, index) => {
          const ButtonComponent = !isNil(selected)
            ? selected === index
              ? PrimaryButton
              : TertiaryButton
            : SecondaryButton;
          return (
            <ButtonComponent
              size="small"
              key={index}
              onClick={() => {
                setSelected(index);
              }}
              sx={{
                textAlign: "left",
                maxWidth: "12rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {option}
            </ButtonComponent>
          );
        })}
      </Box>
    </Box>
  );
}
