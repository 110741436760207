import sum from "lodash/sum";
import type * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import { useModal } from "~/components/transactions/command-palette/ModalProvider";
import { useIsMobile } from "~/components/ui/hooks";
import { useLang } from "~/redux/lang";

export type Option = {
  // text label
  label: string;
  // searchTags are used to match on search
  // this is used alongside the label
  searchTags?: string[];
  // icon on the left
  icon?: React.ReactNode;
  // icon on the right
  endIcon?: React.ReactNode;
  // explainer text in the (i)
  description?: string;
  // Level 1 > Level 2
  parentLabel?: string;
  // Groups (same id, no dividing line)
  sectionId?: string;
  // Group labels
  sectionLabel?: string;
  chevron?: boolean;
  // Must have `onClose` prop
  nextView?: React.ReactNode;
  // If true, the option label will not be censored in session recordings
  uncensored?: boolean;
};

export function useCommandPaletteMenu<TOption extends Option>({
  disabled,
  loading,
  onSelection,
  options,
  selectedOptionLabel,
  placeholder,
  itemSize,
  maxMenuHeight,
  onSearch,
  customFilter,
}: {
  disabled: boolean | undefined;
  loading: boolean | undefined;
  onSelection: (option: TOption) => void;
  options: TOption[] | Readonly<TOption[]>;
  selectedOptionLabel?: string;
  placeholder?: string;
  maxMenuHeight?: number | undefined; // need to supply this for the virtualiser scrolling to work
  itemSize: (
    filteredOption: TOption,
    index: number,
    filteredOptions: TOption[],
  ) => number;
  onSearch?: (value: string) => void;
  customFilter?: (filterText: string) => TOption[];
}) {
  const lang = useLang();
  const [filterText, setFilterText] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<any>();
  const { setLoading } = useModal();
  const isMobile = useIsMobile();
  const [usingKeyNav, setIsUsingKeyNav] = useState(false);

  useEffect(() => {
    if (listRef.current) {
      // Recalculates itemSize heights on search change.
      listRef.current.resetAfterIndex(0);
    }
  }, [filterText]);

  useEffect(() => {
    if (inputRef.current && !disabled && !isMobile) {
      inputRef.current.focus();
    }
  }, [disabled, isMobile]);

  useEffect(() => {
    setLoading(loading ?? false);
  }, [loading, setLoading]);

  // Combine first and second level option together
  const filteredOptions = customFilter
    ? customFilter(filterText)
    : options.filter((option) => {
        if (filterText.length > 0) {
          // they have typed something, do a search
          return option.label.toLowerCase().includes(filterText.toLowerCase());
        }
        if (option.sectionId === "recentlyUsed") {
          return true;
        }
        if (option.parentLabel) {
          return false;
        }
        return true;
      });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
    setSelectedIndex(0);

    if (onSearch) {
      onSearch(event.target.value);
    }
  };

  const handleOptionSelect = (option: TOption, index: number) => {
    setSelectedIndex(index);
    onSelection(option);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      const index = (selectedIndex + 1) % filteredOptions.length;
      setSelectedIndex(index);
      event.preventDefault();
      listRef.current?.scrollToItem(index);
      setIsUsingKeyNav(true);
    } else if (event.key === "ArrowUp") {
      const index =
        selectedIndex === 0 ? filteredOptions.length - 1 : selectedIndex - 1;
      setSelectedIndex(index);
      event.preventDefault();
      listRef.current?.scrollToItem(index);
      setIsUsingKeyNav(true);
    } else if (event.key === "Enter") {
      onSelection(filteredOptions[selectedIndex]);
    } else {
      setIsUsingKeyNav(false);
    }
  };

  const menuHeightUncapped =
    sum(
      filteredOptions.map((option, index) =>
        itemSize(option, index, filteredOptions),
      ),
    ) +
    16 * 0.5;
  const height = maxMenuHeight
    ? Math.min(maxMenuHeight, menuHeightUncapped)
    : menuHeightUncapped;

  const isOptionSelected = useCallback(
    (option: TOption) => option.label === selectedOptionLabel,
    [selectedOptionLabel],
  );

  return {
    filteredOptions,
    inputRef,
    usingKeyNav,
    handleKeyDown,
    handleOptionSelect,
    handleInputChange,
    filterText,
    setIsUsingKeyNav,
    listRef,
    selectedIndex,
    setSelectedIndex,
    // props for the NoResults component
    noResultProps: {
      loading: Boolean(loading),
      hasResults: filteredOptions.length > 0,
    },
    // props for the search component
    inputSearchProps: {
      placeholder: placeholder ?? lang.txTable.commandPallet.typeSearchCommand,
      value: filterText,
      onChange: handleInputChange,
      onKeyDown: handleKeyDown,
      autoComplete: "off",
      inputRef,
      disabled,
      type: "search",
    },
    // props for the virtualised list component
    variableSizeListProps: {
      height,
      width: "100%",
      itemSize: (index: number) => {
        return itemSize(filteredOptions[index], index, filteredOptions);
      },
      itemCount: filteredOptions.length,
      ref: listRef,
      itemData: filteredOptions.map((option, index) => ({
        option,
        isSelectedOption: isOptionSelected(option),
        setSelectedIndex,
        handleOptionSelect,
        disabled,
        selectedIndex,
        usingKeyNav,
        itemCount: filteredOptions.length,
        sectionId:
          filteredOptions[index - 1]?.sectionId !== option.sectionId
            ? option.sectionId
            : undefined,
        sectionLabel:
          filteredOptions[index - 1]?.sectionLabel !== option.sectionLabel
            ? option.sectionLabel
            : undefined,
      })),
    },
  };
}
