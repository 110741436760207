import { Trade } from "@ctc/types";
import { Close, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  type DialogProps,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styled from "styled-components/macro";

import {
  CurrencyAmountPriceValueTooltip,
  useGetTxAmountText,
} from "~/components/transactions/action-row/TxAmount";
import { CommandPaletteBonesProviders } from "~/components/transactions/command-palette/useCommandPalleteBones";
import {
  type EditActionAmountsValues,
  nameMap,
} from "~/components/transactions/edit/EditActionAmounts";
import {
  type EditActionDrawerTradeValues,
  getValues,
  type TxTrade,
} from "~/components/transactions/edit/EditActionTrade";
import { EditTxTradeButton } from "~/components/transactions/edit/EditTxTradeButton";
import { STSOptOut } from "~/components/transactions/edit/STSOptOut";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { BlockchainSymbolPosition } from "~/components/ui/enums";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { useCloseButtonStyles } from "~/components/ui/GeneralDialog";
import { StyledDialogActions } from "~/components/ui/StyledDialogActions";
import { devices } from "~/components/ui/theme/legacy";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import {
  displayCurrency,
  displayFiatValue,
  multiply,
  safeFloat,
} from "~/lib/index";
import { ActionDefinitions, TradeInfo } from "~/lib/tradeTypeDefinitions";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import {
  getPartyDetailsFromTx,
  isGroupedTrade,
  isUncategorisedTrade,
} from "~/services/transactions";
import { Direction, GroupedTrade, TradeDirection } from "~/types/enums";
import {
  type ActionRow,
  type ActionType,
  type CurrencyIdentifier,
  type TransactionDetails,
} from "~/types/index";

export function ActionCategorisationModal({
  isOpen,
  onClose,
  action,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: () => void;
  action: ActionRow;
  onSubmit: (txUpdates: TxTrade[]) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const classes = useCloseButtonStyles();
  const { watch, reset } = useFormContext<
    EditActionDrawerTradeValues & EditActionAmountsValues
  >();
  const [subComponentTrades] = watch(["subComponentTrades"]);
  const formData = watch();

  const { incoming, outgoing } = action;
  const txs = [...outgoing, ...incoming];

  const handleSubmit = () => {
    onSubmit(subComponentTrades);
    onClose();
  };

  const handleCancel = () => {
    onClose();
    reset({ ...formData, ...getValues({ row: action }) }); // Only reset the trade values
  };

  const disableButton = subComponentTrades.some((sct) =>
    isUncategorisedTrade(sct.trade),
  );

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") onClose();

    onClose();
  };

  // only show STS if the action is uncategorised
  const shouldShowSTS = isUncategorisedTrade(action.type);

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ p: "1.5rem", pb: 0 }}>
          <Box display="flex" alignItems="center">
            <Typography variant="Metropolis/Header/H4" sx={{ color: tokens.text.high }}>
              {lang.uncategorisedModal.title}
            </Typography>

            <TextIconButton
              aria-label="close"
              data-ctc="general-dialogue-close"
              className={classes.closeButton}
              onClick={handleCancel}
              size="medium"
            >
              <Close />
            </TextIconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ px: 0, pb: "0.5rem", height: "100%" }}>
          <PrefillOptions action={action} />
          <EditByRow txs={txs} row={action} />
        </DialogContent>
        <StyledDialogActions>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              {shouldShowSTS ? (
                <STSOptOut row={action} disableBackgroundFill />
              ) : null}
            </Box>
            <Box display="flex" alignItems="center">
              <TertiaryButton onClick={handleCancel}>
                {lang.uncategorisedModal.cancel}
              </TertiaryButton>
              <Tooltip
                title={
                  disableButton ? lang.uncategorisedModal.actionTooltip : ""
                }
                placement="top"
              >
                <span>
                  <PrimaryButton
                    onClick={handleSubmit}
                    disabled={disableButton}
                    sx={{ marginLeft: "0.5rem" }}
                  >
                    {lang.uncategorisedModal.save}
                  </PrimaryButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </StyledDialogActions>
      </Dialog>
    </Box>
  );
}

const GROUP_OPTIONS: ActionType[] = Object.values(GroupedTrade).filter(
  (type) => !ActionDefinitions[type].excludeFromRecategorisation,
);
const INCOMING_OPTIONS: ActionType[] = [
  Trade.Buy,
  Trade.Mint,
  Trade.BridgeIn,
  Trade.StakingWithdrawal,
  Trade.StakingReward,
  Trade.RemoveLiquidity,
  Trade.Income,
  Trade.Borrow,
  Trade.Spam,
  Trade.Airdrop,
];
const OUTGOING_OPTIONS: ActionType[] = [
  Trade.Sell,
  Trade.BridgeOut,
  Trade.StakingDeposit,
  Trade.AddLiquidity,
  Trade.Approval,
  Trade.Expense,
  Trade.LoanRepayment,
  Trade.Burn,
  Trade.IgnoreOut,
];

function PrefillOptions({ action }: { action: ActionRow }) {
  const { tokens } = useDesign();
  const lang = useLang();

  const ins = action.incoming.length > 0;
  const outs = action.outgoing.length > 0;
  const group = ins && outs;

  const options = group
    ? GROUP_OPTIONS
    : ins
      ? INCOMING_OPTIONS
      : OUTGOING_OPTIONS;

  const { setValue, watch } = useFormContext<EditActionDrawerTradeValues>();
  const [subComponentTrades] = watch(["subComponentTrades"]);

  const checkIsActive = useCallback(
    (type: ActionType) =>
      subComponentTrades.every((trade) => {
        if (isGroupedTrade(type)) {
          const definition = ActionDefinitions[type];
          return definition.subActionTypes.includes(trade.trade);
        }

        return trade.trade === type;
      }),
    [subComponentTrades],
  );

  return (
    <Grid p="1rem">
      {options.map((type) => {
        const Icon = TradeIcons[type];
        return (
          <StyledBox
            key={type}
            onClick={() => {
              setValue(
                "subComponentTrades",
                subComponentTrades.map((trade) => {
                  // Handle picking the correct side of a group
                  if (isGroupedTrade(type)) {
                    const definition = ActionDefinitions[type];
                    const direction = TradeInfo[trade.trade].direction;
                    const newType =
                      direction === TradeDirection.In
                        ? definition.inTrade
                        : definition.outTrade;

                    return {
                      ...trade,
                      trade: newType || trade.trade,
                    };
                  }

                  // Handle single sided if the direction matches
                  if (
                    TradeInfo[trade.trade].direction ===
                    TradeInfo[type].direction
                  ) {
                    return {
                      ...trade,
                      trade: type,
                    };
                  }

                  // No-op case
                  return trade;
                }),
                {
                  shouldValidate: false,
                  shouldDirty: true,
                },
              );
            }}
            active={checkIsActive(type)}
          >
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Icon
                sx={{
                  fontSize: "1rem !important",
                  color: `${tokens.text.low} !important`,
                }}
              />
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                sx={{ color: tokens.text.low }}
              >
                {lang.tradeType[type]}
              </Typography>
            </Box>
            <Tooltip
              title={
                lang.tradeTypeTaxDescription[
                  type as keyof typeof lang.tradeTypeTaxDescription
                ]
              }
            >
              <InfoOutlined sx={{ fontSize: "0.875rem" }} />
            </Tooltip>
          </StyledBox>
        );
      })}
    </Grid>
  );
}

function EditByRow({
  txs,
  row,
}: {
  txs: TransactionDetails[];
  row: ActionRow;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  const { control } = useFormContext<EditActionDrawerTradeValues>();

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        color={tokens.text.low}
        ml="1rem"
        mb="0.5rem"
      >
        {lang.uncategorisedModal.subTitle}
      </Typography>
      <Controller
        control={control}
        name="subComponentTrades"
        rules={{
          required: lang.editTx.mustCategory,
        }}
        render={({ field }) => (
          <>
            {field.value
              .sort((a, b) => {
                const aDirection = TradeInfo[a.trade].direction;
                const bDirection = TradeInfo[b.trade].direction;
                if (aDirection === bDirection) {
                  return 0;
                }
                return aDirection === TradeDirection.Out ? -1 : 1;
              })
              .map((line) => (
                <CommandPaletteBonesProviders
                  key={line.txId}
                  variant="popover"
                  popupId={`uncategorised-${line.txId}`}
                >
                  <SingleLineRows selected={line} txs={txs} row={row} />
                </CommandPaletteBonesProviders>
              ))}
          </>
        )}
      />
    </Box>
  );
}

function isCurrencyIdentifier(
  c: string | CurrencyIdentifier,
): c is CurrencyIdentifier {
  return typeof c === "object";
}

function SingleLineRows({
  selected,
  txs,
  row,
}: {
  selected: TxTrade;
  txs: TransactionDetails[];
  row: ActionRow;
}) {
  const { tokens } = useDesign();
  const { setValue, watch } = useFormContext<EditActionDrawerTradeValues>();
  const { control } = useFormContext<EditActionAmountsValues>();
  const [subComponentTrades] = watch(["subComponentTrades"]);
  const tx = txs.find((tx) => tx._id === selected.txId);
  const languagePreference = useLanguagePreference();
  const localCurrency = useLocalCurrency();
  const getAmountText = useGetTxAmountText();

  const definition = TradeInfo[selected.trade];
  const side =
    definition.direction === TradeDirection.In
      ? Direction.Incoming
      : Direction.Outgoing;

  const { fields } = useFieldArray({
    control,
    name: nameMap[side],
  });

  const onChange = (updateTrade: Trade) => {
    setValue(
      "subComponentTrades",
      subComponentTrades.map((trade) =>
        trade.txId === selected.txId ? { ...trade, trade: updateTrade } : trade,
      ),
      {
        shouldValidate: false,
        shouldDirty: true,
      },
    );
  };

  if (!tx || !localCurrency) return null;

  const field = fields.find((f) => f.txId === tx._id);
  const amountTx = field
    ? {
        ...tx,
        currencyIdentifier:
          field.currency && isCurrencyIdentifier(field.currency)
            ? field.currency
            : tx.currencyIdentifier,
        quantity: field.quantity ? safeFloat(field.quantity) : tx.quantity,
        price: field.price ? safeFloat(field.price) : tx.price,
      }
    : tx;

  const amountText = getAmountText(amountTx);
  const { exchange, blockchain } = getPartyDetailsFromTx(tx);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py="0.25rem"
      px="1rem"
      width="100%"
      borderTop={`1px solid ${tokens.border.neutral.default}`}
      flexWrap="wrap"
    >
      <Box maxWidth="50%" width="50%" minWidth="15rem">
        <EditTxTradeButton
          row={row}
          selectedTrade={selected.trade}
          onChange={onChange}
          showAccountSymbol={
            <ExchangeLogo
              name={exchange}
              blockchain={blockchain}
              showBlockchainSymbol
              blockchainSymbolPosition={BlockchainSymbolPosition.BottomRight}
              currencySymbol={tx.currencyIdentifier.symbol}
              width={16}
              height={16}
              margin="0"
              showDepositIcon={false}
              background={tokens.background.neutral.default}
            />
          }
        />
      </Box>
      <Box
        width="25%"
        maxWidth="25%"
        display="flex"
        alignItems="center"
        gap="0.25rem"
        minWidth="8rem"
      >
        <CurrencyLogo
          currency={tx.currencyIdentifier}
          margin="0"
          height={16}
          width={16}
        />
        <CurrencyAmountPriceValueTooltip tx={amountTx}>
          <Typography
            variant="IBM Plex Mono/Caption/Medium/Regular"
            maxWidth="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >{`${amountText} ${displayCurrency(amountTx.currencyIdentifier.symbol)}`}</Typography>
        </CurrencyAmountPriceValueTooltip>
      </Box>
      <Box width="25%" maxWidth="25%" textAlign="right" minWidth="8rem">
        <Typography
          variant="IBM Plex Mono/Caption/Medium/Regular"
          maxWidth="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {displayFiatValue({
            value: multiply(amountTx.quantity, amountTx.price),
            localCurrency,
            locale: languagePreference,
          })}
        </Typography>
      </Box>
    </Box>
  );
}

const StyledBox = styled(Box)<{ active: boolean }>`
  && {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
    color: ${({ theme }) => theme.tokens.text.low};
    border-radius: 0.25rem;
    background-color: ${({ active, theme }) =>
      active
        ? theme.tokens.background.neutral.pressed
        : theme.tokens.background.neutral.default};
    padding: 0.5rem;
    transition: background-color 0.25s ease;
    cursor: pointer;
  }
`;

const Grid = styled(Box)`
  && {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 0.5rem), 1fr));
    grid-gap: 0.25rem;

    @media ${devices.tabletS} {
      grid-template-columns: repeat(
        auto-fill,
        minmax(calc(33.33% - 0.5rem), 1fr)
      );
    }
  }
`;
