import Close from "@mui/icons-material/Close";
import { Box, Dialog, Skeleton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import moment from "moment-timezone";
import { useContext } from "react";

import { TLHSummary } from "~/components/report/tax-loss-harvesting-modal/TLHSummary";
import { TLHTable } from "~/components/report/tax-loss-harvesting-modal/TLHTable";
import { ZeroCostBanner } from "~/components/report/ZeroCostBanner";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useIsMobile } from "~/components/ui/hooks";
import { FYContext } from "~/contexts/FYContext";
import { TaxLossHarvestingContext } from "~/contexts/TaxLossHarvestingContext";
import { useDesign } from "~/hooks/useTheme";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { getReportSummary } from "~/services/report";
import { taxLossHarvestingQueryKeys } from "~/state/taxLossHarvesting";

export function TaxLossHarvestingModal() {
  const { tokens } = useDesign();
  const lang = useLang();
  const tlhContext = useContext(TaxLossHarvestingContext);
  const user = useUser();
  const isMobile = useIsMobile();
  const { fyDates } = useContext(FYContext);
  const currentFYDates = fyDates[0];

  const getSummaryData = () => async () => {
    if (!currentFYDates) return;

    const res = await getReportSummary({
      start: new Date(currentFYDates.start),
      end: new Date(currentFYDates.end),
      year: new Date(currentFYDates.start).getFullYear(),
    });

    if (res.error) {
      console.error(res);
      return;
    }

    return res.data;
  };

  const summaryData = useQuery({
    queryKey: taxLossHarvestingQueryKeys.summary(),
    queryFn: getSummaryData(),
    enabled: !!currentFYDates && !!user,
  });

  if (!tlhContext || !user) return null;

  const handleClose = () => {
    tlhContext.updateIsModalOpen(false);
  };

  const formatStr = "Do MMM YYYY";
  const currentFY = currentFYDates
    ? `${moment(currentFYDates.start).format(formatStr)} - ${moment(
        currentFYDates.end,
      ).format(formatStr)}`
    : "";

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      disableEscapeKeyDown={tlhContext.isEditing !== undefined}
      open={tlhContext.isModalOpen}
      sx={{
        ".MuiDialog-paperFullScreen": {
          background: tokens.elevation.default,
        },
        ".MuiDialog-scrollPaper": {
          background: "transparent",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        borderBottom={`1px solid ${tokens.border.neutral.default}`}
        p="1rem 2rem"
        bgcolor={tokens.elevation.medium}
      >
        <Box display="flex">
          <Typography fontSize="1.25rem" fontWeight="600" mr="0.5rem">
            {`${lang.taxLossHarvesting.title}:`}
          </Typography>
          <Typography fontSize="1.25rem">
            {currentFY || <Skeleton width="15rem" />}
          </Typography>
        </Box>
        <TextIconButton aria-label="close" onClick={handleClose} size="large">
          <Close />
        </TextIconButton>
      </Box>
      <Box
        maxWidth="100rem"
        width="100%"
        display="flex"
        flexDirection="column"
        alignSelf="center"
        height="100%"
        bgcolor={tokens.elevation.default}
      >
        <ZeroCostBanner
          sx={{
            marginTop: isMobile ? "0.5rem" : "1.5rem",
            paddingX: isMobile ? "0.5rem" : "2rem",
          }}
          zcbForPeriod={summaryData?.data ? summaryData?.data.zeroCostBuys : []}
        />
        <TLHSummary summaryData={summaryData?.data} />
        <TLHTable />
      </Box>
    </Dialog>
  );
}
