import { type Country } from "@ctc/types";
import moment from "moment-timezone";

import { getUserCountryDateFormat } from "~/lib/date";
import { useLang } from "~/redux/lang";

export const useFormatPeriodDate = () => {
  const lang = useLang();

  const formatPeriodDate = ({
    date,
    userCountry,
  }: {
    date: Date;
    userCountry: Country | undefined;
  }): string => {
    if (moment(date).isSame(moment.unix(0))) {
      return lang.settings.lockPeriod.beginningOfTime;
    }
    const format = getUserCountryDateFormat(userCountry);
    const formattedDate = moment(date).format(format);
    return formattedDate;
  };

  return formatPeriodDate;
};
