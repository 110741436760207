import { TaxRule } from "@ctc/types";

export function isWashSale(rule: TaxRule | undefined) {
  switch (rule) {
    case TaxRule.BedAndBreakfast:
    case TaxRule.SuperficialLossFromBuyBack:
    case TaxRule.SuperficialLossFromSell:
      return true;
    default:
      return false;
  }
}

export function isAdjustedPrice(rule: TaxRule | undefined) {
  switch (rule) {
    case TaxRule.BedAndBreakfast:
    case TaxRule.SuperficialLossFromBuyBack:
    case TaxRule.SuperficialLossFromSell:
    case TaxRule.SameDay:
      return true;
    default:
      return false;
  }
}
