import { Box, Typography } from "@mui/material";

import { AddPeriod } from "~/components/settings-modal/views/lock-period/AddLockPeriod";
import { LockedPeriodList } from "~/components/settings-modal/views/lock-period/LockPeriodList";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { useSettingsLockPeriod } from "~/hooks/useSettingsLockPeriod";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function LockPeriod() {
  const { tokens } = useDesign();
  const lang = useLang();
  // Whenever the default end date for the period changes
  // we reset the form
  const { defaultEndDate } = useSettingsLockPeriod();

  return (
    <SettingsBox
      title={
        <Box display="flex" alignItems="center" gap="1rem">
          <Typography variant="Metropolis/Header/H4">
            {lang.settings.lockPeriod.title}
          </Typography>
        </Box>
      }
    >
      <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
        {lang.settings.lockPeriod.description}
      </Typography>
      <AddPeriod key={defaultEndDate} />
      <LockedPeriodList />
    </SettingsBox>
  );
}
