import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { PriceTopSection } from "~/components/transactions/command-palette/views/change-price/PriceTopSection";
import { invariant } from "~/lib/invariant";
import { useNewBulkOpMutation } from "~/state/actions";
import { BulkOperations } from "~/types/enums";
import { type CurrencyIdentifier } from "~/types/index";

export function Confirmation({
  currency,
  price,
}: {
  currency: CurrencyIdentifier;
  price: number;
}) {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");

  const bulkOp = useNewBulkOpMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.ChangePrice,
  });

  const onConfirm = () => {
    bulkOp.mutate(
      {
        filter,
        operation: {
          type: BulkOperations.ChangePrice,
          currency,
          price,
        },
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangePrice} />
          <PriceTopSection currency={currency} price={price} />
        </BulkEditChips>
      }
      onConfirm={onConfirm}
      loading={bulkOp.isPending}
    />
  );
}
