import { Box, Stack, Typography } from "@mui/material";
import { StringParam, useQueryParam } from "use-query-params";

import { FORM_IMPORT_SUBMITTED_EVENT } from "~/analytics/analytics";
import { importNewAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { ShieldIcon } from "~/components/ui/Icons";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign } from "~/hooks/useTheme";
import { useOAuthFlow } from "~/oauth";
import { useBestUser, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { fireAnalyticsOnUpload } from "~/segment/importAnalytics";
import { useGetSavedImportsByIntegration } from "~/state/importsV2";
import { ImportType, Links } from "~/types/enums";
import { type ImportOptionV2 } from "~/types/index";

export const OAuthImport = ({
  importOption,
}: {
  importOption: ImportOptionV2;
}) => {
  const lang = useLang();
  const user = useUser();
  const bestUser = useBestUser();
  const { id, name } = importOption;
  const savedImport = useGetSavedImportsByIntegration(id);
  const isEnabled = !savedImport?.oauths.length; // only show the connect button if no oauth already connected
  const captureAnalytics = useCaptureAnalytics();
  const isEmbedded = useIsEmbedded();
  const handleOAuthFlow = useOAuthFlow({
    exchangeLabel: id,
    uid: bestUser?.uid,
    isEmbedded,
    redirectLink: user?.isOnboarding
      ? Links.OnboardingImportsCalculating
      : undefined,
  });
  const [suspectedMissingImport] = useQueryParam(
    "suspectedMissingImport",
    StringParam,
  );
  const isSuspectedMissingImport = suspectedMissingImport === "true";

  if (!bestUser) {
    return null;
  }
  if (!isEnabled) {
    return null;
  }
  const onClick = async () => {
    captureAnalytics("exchange_imported", {
      integration: importOption.id,
      exchangeName: importOption.name,
      importType: "oauth",
      isSuspectedMissingImport,
    });
    captureAnalytics(importNewAnalyticsKey(FORM_IMPORT_SUBMITTED_EVENT), {
      integration: importOption.id,
      exchangeName: importOption.name,
      importType: ImportType.OAuth,
    });
    fireAnalyticsOnUpload(user, {
      label: importOption.id,
      importType: ImportType.OAuth,
    });
    await handleOAuthFlow();
  };

  return (
    <>
      <Box mt="1rem">
        <Typography variant="Metropolis/Body/Bold">
          {lang.imports.oauth.description({ exchange: name })}
        </Typography>
      </Box>
      <Box mt="1rem">
        <PrimaryButton fullWidth onClick={onClick}>
          {lang.imports.oauth.connect({ exchange: name })}
        </PrimaryButton>
      </Box>
    </>
  );
};

function OAuthImportType({
  onClick,
  uri,
  name,
}: {
  onClick: () => void;
  uri: string;
  name: string;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  return (
    <>
      <Box>
        <PrimaryButton
          variant="contained"
          onClick={onClick}
          fullWidth
          startIcon={<ShieldIcon />}
        >
          {lang.imports.secureConnect}
        </PrimaryButton>
      </Box>
      <Box mt="1rem">
        <Stack direction="row" spacing={1}>
          <ShieldIcon fontSize="small" />
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            sx={{
              color: tokens.text.low,
            }}
          >
            {lang.imports.oauth.description({ exchange: name })}
          </Typography>
        </Stack>
      </Box>
    </>
  );
}
