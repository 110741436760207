export enum Severity {
  Error = "error",
  Warn = "warn",
} // Sequence here is important

export enum ActionTableGridArea {
  Type = "type",
  Time = "time",
  From = "from",
  To = "to",
  Fee = "fee",
  LedgerFee = "ledgerFee",
  Value = "value",
  Gain = "gain",
  AccountBalanceIn = "inAccountBalance",
  AccountBalanceOut = "outAccountBalance",
  OverallBalance = "overallBalance",
  Actions = "actions",
  Spacer = "spacer",
  Transaction = "transaction",
  Similar = "Similar",
  Counterparty = "Counterparty",
  CTA = "cta",
}
export enum ActionRowDefaultActions {
  Edit = "edit",
  ViewInContext = "viewInContext",
  ViewAssociatedTransactions = "viewAssociatedTransactions",
  Copy = "copy",
  Duplicate = "duplicate",
  Ungroup = "ungroup",
  Ignore = "ignore",
  Spam = "spam",
  Delete = "delete",
  GenerateTransfer = "generateTransfer",
}
