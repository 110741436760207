import { type Blockchain } from "@ctc/types";
import { Box, Tooltip } from "@mui/material";
import chroma from "chroma-js";

import { RemainingStackChip } from "~/components/imports-v2/AccountsTable";
import { exchangeColorConfig } from "~/components/imports-v2/exchangeColorConfigGenerated";
import { getTrimmedStack } from "~/components/imports-v2/helpers";
import { LazyLoadImageWithFallback } from "~/components/transactions/ImageWithFallback";
import {
  getBlockchainLogoSrc,
  getExchangeLogoSrc,
} from "~/components/ui/ExchangeLogo";
import { useIsMobile } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { middleTrim } from "~/lib/index";
import {
  BlockchainName,
  DefaultBlockchainCurrency,
  type SavedWalletImport,
} from "~/types/index";

export const BlockchainStack = ({
  blockchains,
  stackSize = 8,
  disableOverlap = false,
  showOnMobile = false,
}: {
  blockchains: Blockchain[];
  stackSize?: number;
  disableOverlap?: boolean;
  showOnMobile?: boolean;
}) => {
  let zIndex = blockchains.length;
  const isMobile = useIsMobile();

  if (isMobile && !showOnMobile) {
    return null;
  }

  const { shownItems, otherItemsCount } = getTrimmedStack(
    blockchains,
    stackSize,
  );

  return (
    <Box display="flex" alignItems="center">
      {shownItems.map((blockchain) => {
        zIndex -= 1;
        return (
          <BlockchainItem
            key={blockchain}
            blockchain={blockchain}
            zIndex={zIndex}
            disableOverlap={disableOverlap}
          />
        );
      })}
      {otherItemsCount ? (
        <RemainingStackChip>{`+${otherItemsCount}`}</RemainingStackChip>
      ) : null}
    </Box>
  );
};

function BlockchainItem({
  blockchain,
  zIndex,
  disableOverlap,
}: {
  blockchain: Blockchain;
  zIndex: number;
  disableOverlap: boolean;
}) {
  const { tokens } = useDesign();

  const blockchainSrc = getBlockchainLogoSrc(blockchain);

  const fileConfig = (exchangeColorConfig as any)[blockchain];
  const bg = chroma
    .mix(
      fileConfig?.backgroundColor || tokens.background.neutral.default,
      tokens.background.neutral.default,
      0.5,
      "rgb",
    )
    .hex();
  if (!blockchainSrc) {
    return null;
  }
  return (
    <Tooltip title={BlockchainName[blockchain]} arrow placement="top">
      <Box
        zIndex={zIndex}
        margin={`0 ${disableOverlap ? "0.125rem" : "-0.10rem"}`}
        paddingTop="0.25rem"
      >
        <LazyLoadImageWithFallback
          height={16}
          width={16}
          src={blockchainSrc}
          alt={`${blockchain}-logo`}
          effect="blur"
          style={{
            borderRadius: 8,
            backgroundColor: bg,
          }}
        />
      </Box>
    </Tooltip>
  );
}

export const BlockieStack = ({ wallets }: { wallets: SavedWalletImport[] }) => {
  let zIndex = wallets.length;
  const isMobile = useIsMobile();

  if (isMobile) {
    return null;
  }

  const { shownItems, otherItemsCount } = getTrimmedStack(wallets, 8);

  return (
    <Box display="flex" alignItems="center" margin="0  0.25rem">
      {shownItems.map((wallet) => {
        zIndex -= 1;
        return <BlockieItem key={wallet.id} wallet={wallet} zIndex={zIndex} />;
      })}
      {otherItemsCount ? (
        <RemainingStackChip>{`+${otherItemsCount}`}</RemainingStackChip>
      ) : null}
    </Box>
  );
};

function BlockieItem({
  wallet,
  zIndex,
}: {
  wallet: SavedWalletImport;
  zIndex: number;
}) {
  const src = getExchangeLogoSrc(
    wallet.address,
    wallet.name,
    wallet.blockchain
      ? DefaultBlockchainCurrency[wallet.blockchain]
      : undefined,
    wallet.blockchain,
  );

  // Load image when component mounts
  if (!src) {
    return null;
  }

  const title = wallet.name
    ? `${wallet.name} (${middleTrim(wallet.address)})`
    : middleTrim(wallet.address);

  return (
    <Tooltip key={wallet.id} title={title} arrow placement="top">
      <Box zIndex={zIndex} margin="0 -0.25rem" paddingTop="0.25rem">
        <LazyLoadImageWithFallback
          height={16}
          width={16}
          src={src}
          alt={`${wallet.address}-logo`}
          effect="blur"
          style={{
            borderRadius: 8,
          }}
        />
      </Box>
    </Tooltip>
  );
}
