import { Plan } from "@ctc/types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

import { type PlanSectionProps } from "~/components/payment/types";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { devices } from "~/components/ui/theme/legacy";
import { useDesign } from "~/hooks/useTheme";
import { useUserHasEmail } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { PlanGroup } from "~/types/enums";

export const AccountantSection = ({
  onPlanChange,
  onPlanGroupChange,
}: Pick<PlanSectionProps, "onPlanChange" | "onPlanGroupChange">) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const userHasEmail = useUserHasEmail();
  const langAccountant = lang.payment.base.copy.plans[Plan.Accountant];

  return (
    <>
      <Stack
        direction="column"
        gap="2rem"
        sx={{
          padding: "2rem 1rem",
          background: tokens.elevation.medium,
          [`@media ${devices.laptop}`]: {
            padding: "4rem 1rem 2rem",
          },
        }}
      >
        <Stack
          direction="column"
          gap="2rem"
          sx={{ maxWidth: "35rem", marginLeft: "auto", marginRight: "auto" }}
        >
          <Typography
            variant="Metropolis/Header/H1"
            sx={{
              textAlign: "center",
              fontWeight: 700,
              color: tokens.text.high,
            }}
          >
            {langAccountant.title}
          </Typography>
          <Typography
            variant="Metropolis/Header/H4"
            sx={{ textAlign: "center", fontWeight: 700 }}
          >
            {langAccountant.subtitle}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          gap="1rem"
          sx={{
            padding: "2rem",
            background: tokens.elevation.default,
            border: `1px solid ${tokens.border.neutral.default}`,
            borderRadius: "1.5rem",
            maxWidth: "43rem",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Typography
            variant="Metropolis/Header/H2"
            sx={{ fontWeight: 700, color: tokens.text.high }}
          >
            {langAccountant.title}
          </Typography>
          <Typography variant="Metropolis/Body/Bold">
            {langAccountant.description}
          </Typography>
          {langAccountant.points.map((point) => (
            <Stack key={point} direction="row" alignItems="center" gap="0.5rem">
              <CheckCircleRoundedIcon sx={{ color: tokens.text.default }} />
              <Typography variant="Metropolis/Body/Bold" style={{ fontWeight: 700 }}>
                {point}
              </Typography>
            </Stack>
          ))}
          <Typography variant="Metropolis/Body/Bold">
            {langAccountant.descriptionFooter}
          </Typography>
          <Box>
            <Tooltip title={!userHasEmail ? lang.auth.missingEmail : ""}>
              <span>
                <PrimaryButton
                  onClick={() => {
                    onPlanChange(Plan.Accountant, true, undefined);
                  }}
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 700,
                  }}
                  disabled={!userHasEmail}
                >
                  {langAccountant.getStarted}
                </PrimaryButton>
              </span>
            </Tooltip>
          </Box>
        </Stack>
        {onPlanGroupChange ? (
          <Box
            sx={{
              maxWidth: "81.125rem",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <TextButton
              startIcon={<ArrowBackIcon fontSize="small" />}
              onClick={() => {
                onPlanGroupChange(PlanGroup.Personal);
              }}
            >
              {lang.payment.viewIndividualPlan}
            </TextButton>
          </Box>
        ) : null}
      </Stack>
      <Stack
        direction="column"
        gap="2.5rem"
        sx={{
          textAlign: "center",
          background: tokens.elevation.default,
          padding: "2rem 1rem",
          [`@media ${devices.laptop}`]: {
            padding: "4rem 1rem",
          },
        }}
      >
        <Typography
          variant="Metropolis/Header/H1"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            color: tokens.text.high,
          }}
        >
          {langAccountant.toolsDemo}
        </Typography>
        <Stack
          direction="column"
          gap="1rem"
          sx={{ maxWidth: "33.25rem", margin: "0 auto" }}
        >
          <Typography
            variant="Metropolis/Header/H3"
            sx={{
              fontWeight: 700,
            }}
          >
            {langAccountant.manageClientsTitle}
          </Typography>
          <Typography variant="Metropolis/Body/Bold">
            {langAccountant.manageClientsDescription}
          </Typography>
        </Stack>
        <Box sx={{ maxWidth: "65.375rem", margin: "0 auto" }}>
          <img
            src="/accounting-images/client-list.png"
            alt={langAccountant.clientDataTitle}
          />
        </Box>
        <Stack
          direction="column"
          gap="1rem"
          sx={{
            maxWidth: "44.75rem",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <Typography
            variant="Metropolis/Header/H3"
            sx={{
              fontWeight: 700,
            }}
          >
            {langAccountant.clientDataTitle}
          </Typography>
          <Typography variant="Metropolis/Body/Bold">
            {langAccountant.clientDataDescription}
          </Typography>
          <Box mt="1rem">
            <img
              src="/accounting-images/client-settings.png"
              alt={langAccountant.manageClientsTitle}
            />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
