import { Box, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { useDesign } from "~/hooks/useTheme";

const ImportListItemContainerBox = styled(({ ...props }) => <Box {...props} />)`
  border-radius: 0.1rem;

  ${(props) =>
    props.importStatus === "warning"
      ? `border: 1px solid ${props.theme.tokens.border.warning}`
      : ""}
`;

export function ImportListItemContainer({
  children,
  listItemDescription,
  status,
}: {
  children: React.ReactNode;
  listItemDescription?: string;
  status?: string;
}) {
  return (
    <ImportListItemContainerBox importStatus={status}>
      {children}
      {listItemDescription && (
        <ImportListItemWarningDescription description={listItemDescription} />
      )}
    </ImportListItemContainerBox>
  );
}

const ImportListItemWarningBox = styled(({ ...props }) => <Box {...props} />)`
  background: ${({ theme }) => theme.tokens.elevation.low};
  color: ${({ theme }) => theme.tokens.text.low};
  position: flex;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
`;

function ImportListItemWarningDescription({
  description,
}: {
  description: string;
}) {
  const { tokens } = useDesign();
  return (
    <ImportListItemWarningBox>
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        sx={{
          color: tokens.text.low,
        }}
      >
        {description}
      </Typography>
    </ImportListItemWarningBox>
  );
}
