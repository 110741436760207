import { createContext } from "react";
import * as React from "react";

import { type ActionRow } from "~/types/index";

type ActionRowContextType = {
  actionRow: ActionRow;
};

export const ActionRowContext = createContext<ActionRowContextType | null>(
  null,
);

export const ActionRowContextProvider = ({
  children,
  row,
}: {
  children: React.ReactNode;
  row: ActionRow;
}) => {
  return (
    <ActionRowContext.Provider
      value={{
        actionRow: row,
      }}
    >
      {children}
    </ActionRowContext.Provider>
  );
};

export function useActionRow() {
  const context = React.useContext(ActionRowContext);
  if (context === null) {
    throw new Error(
      "useActionRow must be used within a ActionRowContextProvider",
    );
  }
  return context.actionRow;
}
