import { FindReplaceOutlined } from "@mui/icons-material";
import {
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  TextField,
  type TextFieldProps,
  Tooltip,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { type ControllerRenderProps } from "react-hook-form";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useLookupMarketPrice } from "~/hooks/useLookupMarketPrice";
import { useDesign } from "~/hooks/useTheme";
import { blurTarget } from "~/lib/blurCurrentTarget";
import { safeFloat } from "~/lib/index";
import { useLang } from "~/redux/lang";
import { type CurrencyIdentifier } from "~/types/index";

export const PriceField = forwardRef<
  HTMLDivElement,
  {
    currency: CurrencyIdentifier | string | null;
    timestamp: string;
    setPrice: (price: string) => void;
    label?: string;
    quantity?: string;
    showLookupMarketPrice?: boolean;
  } & ControllerRenderProps &
    TextFieldProps
>(function PriceField(
  {
    currency,
    timestamp,
    setPrice,
    label,
    error,
    helperText,
    quantity,
    showLookupMarketPrice = true,
    ...field
  },
  ref,
) {
  const [focused, setFocused] = useState(false);
  const { tokens } = useDesign();
  const lang = useLang();

  const [marketPrice, lookupMarketPrice, isLoadingMarketPrice] =
    useLookupMarketPrice();

  useEffect(() => {
    if (!isLoadingMarketPrice && marketPrice !== undefined) {
      const newPrice = quantity
        ? safeFloat(quantity) * marketPrice
        : marketPrice;

      setPrice(newPrice.toString());
    }
  }, [marketPrice, isLoadingMarketPrice]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFocused(false);
      }}
    >
      <TextField
        {...field}
        ref={ref}
        disabled={isLoadingMarketPrice}
        fullWidth
        type="number"
        sx={{ bgcolor: "transparent" }}
        onChange={(e) => {
          setPrice(e.target.value);
        }}
        inputProps={{
          ...field.inputProps,
          sx: {
            backgroundColor: tokens.background.input.default,
            borderRadius: "0.25rem",
          },
          min: 0,
          step: "any",
          onWheel: blurTarget,
        }}
        variant="outlined"
        label={label}
        error={error}
        helperText={helperText || ""}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(e) => {
          field.onBlur(e);
          setFocused(false);
        }}
        InputProps={{
          style: {
            backgroundColor: tokens.background.input.default,
          },
          endAdornment: showLookupMarketPrice ? (
            <InputAdornment position="end">
              <Tooltip title={lang.editTx.lookupPrice}>
                <span>
                  <TextIconButton
                    onClick={() => {
                      lookupMarketPrice(currency, timestamp);
                    }}
                    disabled={isLoadingMarketPrice}
                    color={focused ? "primary" : "default"}
                    size="medium"
                  >
                    {isLoadingMarketPrice ? (
                      <CircularProgress size="1.5rem" />
                    ) : (
                      <FindReplaceOutlined />
                    )}
                  </TextIconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          ) : null,
        }}
        FormHelperTextProps={{
          style: { lineHeight: "1rem", wordWrap: "break-word" },
        }}
      />
    </ClickAwayListener>
  );
});
