import { OpenInNew, WarningAmberOutlined } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  CircularProgress,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import {
  bindPopover,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import type * as React from "react";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { handlePayments } from "~/components/nav/AccountMenu";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import {
  PrimaryButton,
  PrimaryButtonLink,
} from "~/components/ui/ui-buttons/PrimaryButton";
import {
  TertiaryButton,
  TertiaryButtonLink,
} from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import {
  BannerSeverity,
  BannerVariant,
  type FlexDirection,
} from "~/components/ui/enums";
import { displayMessage } from "~/components/ui/Toaster";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useDesign } from "~/hooks/useTheme";
import { loadUser, useIsManagingClients } from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import { retryRenewals } from "~/services/subscription";
import { Align, DisplayMessage, Links } from "~/types/enums";

type ActionProp = {
  color?: string;
  variant?: "text" | "outlined" | "contained" | "stripe";
  to?: string;
  onClick?: () => void;
  popup?: (handleDismiss: () => void) => React.ReactNode;
  text?: string;
};

type BannerCommonProps = {
  actions?: ActionProp[];
  severity: BannerSeverity;
  children?: React.ReactNode;
  handleDismiss?: () => void;
  hideIcon?: boolean;
  text: string | React.ReactNode;
  title?: string;
  variant?: BannerVariant;
  handleClick?: () => void;
  flexDirection?: FlexDirection;
  hideButton?: boolean;
  isLoading?: boolean;
};

function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export function BannerCommon({
  actions,
  severity,
  children,
  handleClick,
  handleDismiss,
  hideIcon = false,
  text,
  title,
  variant = BannerVariant.Row,
  flexDirection,
  hideButton = false,
}: BannerCommonProps) {
  const { tokens } = useDesign();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const isMobile = useIsMobile();
  const isTextComponent = typeof text === "object";
  const lang = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // For Stripe loading button
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState({
    renewal: false,
    managePayments: false,
  });
  const isManagingClients = useIsManagingClients();

  const setLoadingPortalSessionWrapper = (value: boolean) => {
    setIsLoading((prevState) => ({
      ...prevState,
      renewal: value,
      managePayments: value,
    }));
  };

  // An unexpected onClose event will fire when onDismiss click has been
  // triggered, which needs to be prevented.
  const bindHandleClick = (event: React.MouseEvent) => {
    const target = event.target as Element;
    const isClickClose = closeButtonRef.current?.contains(target);
    if (!isClickClose && handleClick) {
      handleClick();
    }
  };

  // When the payment has failed, this checks if are managing clients, as we
  // currently don't support accountants to view their payment information
  // on Stripe, we will redirect them to the Billing page. The current
  // implementation is that failed payments gets automatically redirected to
  // Stripe/Billing page
  const handleFailedPaymentRedirect = () => {
    // Once we move Accountant to Stripe Checkout this can be removed
    // in the meantime, we have to redirect client to page
    if (isManagingClients) {
      navigate(Links.Billing);
    } else {
      handlePayments(setLoadingPortalSessionWrapper, stripe, lang);
    }
  };

  const handleManageBilling = () => {
    setIsLoading({ ...isLoading, managePayments: true });
    handleFailedPaymentRedirect();
    setIsLoading({ ...isLoading, managePayments: true });
  };

  const handleRetryRenewal = async () => {
    setIsLoading({ ...isLoading, renewal: true });
    const res = await retryRenewals();
    if (res.error) {
      handleFailedPaymentRedirect();
      displayMessage({
        message: lang.payment.renewFailed.renewError,
        type: DisplayMessage.Error,
      });
    } else {
      displayMessage({
        message: lang.payment.renewFailed.renewSuccess,
        type: DisplayMessage.Success,
      });
      dispatch(loadUser(LoadUserType.Login));
    }
    setIsLoading({ ...isLoading, renewal: false });
  };

  return (
    <StyledPaper
      severity={severity}
      variant={variant}
      isClickEnabled={!!handleClick}
      onClick={bindHandleClick}
    >
      <Box
        py={2}
        display="flex"
        position="relative"
        alignItems="center"
        flexDirection={{
          xs: "column",
          md: flexDirection || "row",
        }}
        justifyContent="space-between"
      >
        <Box
          mr={title ? "1rem" : "2rem"}
          pl={hideIcon ? "2rem" : "1rem"}
          flexGrow="1"
          display="flex"
          alignItems="flex-start"
          flexDirection="row"
          justifyContent="flex-start"
        >
          {!hideIcon && (
            <Box>
              {severity === BannerSeverity.Info ? (
                <InfoOutlinedIcon color="primary" />
              ) : severity === BannerSeverity.Warning ? (
                <StyledWarningIcon severity={severity} />
              ) : severity === BannerSeverity.Error ? (
                <InfoOutlinedIcon color={severity} />
              ) : severity === BannerSeverity.Notice ? (
                <InfoOutlinedIcon sx={{ color: tokens.icon.brand }} />
              ) : (
                assertUnreachable(severity)
              )}
            </Box>
          )}
          <Box display="flex" flexDirection="column">
            {title ? (
              <Typography
                variant="Metropolis/Header/H5"
                style={{
                  color: tokens.text.high,
                  whiteSpace: "pre-wrap",
                  marginLeft: "0.5rem",
                }}
              >
                {title}
              </Typography>
            ) : null}
            {!isTextComponent && (
              <Typography
                variant="Metropolis/Body/Light"
                style={{
                  position: "relative",
                  top: title ? "2px" : undefined,
                  whiteSpace: "pre-wrap",
                  marginLeft: "0.5rem",
                }}
              >
                {text}
              </Typography>
            )}
            {isTextComponent && text}
          </Box>
        </Box>

        <Box display="flex" pl={isMobile ? 2 : flexDirection ? 7 : undefined}>
          {actions?.map((action) => (
            <Box
              key={action.to}
              alignSelf="start"
              marginTop={{ xs: "0.5rem", md: "0.25rem" }}
              mr="0.5rem"
            >
              {action.to &&
                !hideButton &&
                (action.variant === "text" ? (
                  <TextButton
                    sx={{ color: action.color }}
                    size="small"
                    onClick={action.onClick}
                  >
                    {action.text}
                  </TextButton>
                ) : action.variant === "contained" ? (
                  <PrimaryButtonLink
                    to={action.to}
                    sx={{ color: action.color }}
                    onClick={action.onClick}
                    style={{ marginRight: 24 }}
                  >
                    {action.text}
                  </PrimaryButtonLink>
                ) : (
                  <TertiaryButtonLink
                    to={action.to}
                    sx={{ color: action.color }}
                    size="small"
                    onClick={action.onClick}
                  >
                    {action.text}
                  </TertiaryButtonLink>
                ))}
              {action.variant === "stripe" && !hideButton && (
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  gap="2rem"
                  pr="1.5rem"
                  alignItems={{ xs: "inherit", md: "center" }}
                  height={{ md: "4rem", lg: "3.5rem" }}
                >
                  <StyledTertiaryButtons
                    size="small"
                    disabled={isLoading.renewal || isLoading.managePayments}
                    onClick={handleRetryRenewal}
                    sx={{ minWidth: "9rem" }}
                  >
                    <Box display="flex" gap="0.5rem" alignItems="center">
                      {isLoading.renewal ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <>{lang.payment.renewFailed.renewPlan}</>
                      )}
                    </Box>
                  </StyledTertiaryButtons>
                  <StyledTertiaryButtons
                    size="small"
                    disabled={isLoading.renewal || isLoading.managePayments}
                    onClick={handleManageBilling}
                    sx={{ minWidth: "12rem" }}
                  >
                    <Box display="flex" gap="0.5rem" alignItems="center">
                      {isLoading.managePayments ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <>
                          {lang.nav.manageSubscription}
                          <OpenInNew fontSize="inherit" />
                        </>
                      )}
                    </Box>
                  </StyledTertiaryButtons>
                </Box>
              )}
              {action.popup && (
                <Popup text={action.text} variant={action.variant}>
                  {action.popup}
                </Popup>
              )}
              {!action.to && action.onClick && (
                <PrimaryButton
                  size="small"
                  onClick={action.onClick}
                  style={{ marginRight: 24 }}
                >
                  {action.text}
                </PrimaryButton>
              )}
            </Box>
          ))}
        </Box>
        {handleDismiss && (
          <Box
            position="absolute"
            style={{
              top: 0,
              right: 0,
              margin: "0.5rem 0.5rem 0 0 ",
            }}
            alignSelf={{ xs: "right", sm: "inherit" }}
          >
            <TextIconButton
              ref={closeButtonRef}
              onClick={handleDismiss}
              size="small"
            >
              <ClearIcon />
            </TextIconButton>
          </Box>
        )}
      </Box>
      {children && (
        <Box display="flex" pl={4} pr={2} pt={0} pb={2}>
          {children}
        </Box>
      )}
    </StyledPaper>
  );
}

type PopupProps = {
  color?: string;
  variant?: "text" | "outlined" | "contained" | "stripe";
  text?: string;
  children: (handleDismiss: () => void) => React.ReactNode;
  popoverAlignment?: Align;
};

const Popup = ({
  text,
  children,
  popoverAlignment = Align.Right,
}: PopupProps) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "filter-popup",
  });

  const handleDismiss = () => {
    popupState.close();
  };

  return (
    <>
      <TertiaryButton size="small" {...bindToggle(popupState)}>
        {text}
      </TertiaryButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: popoverAlignment,
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: "right",
        }}
        keepMounted
      >
        {children(handleDismiss)}
      </Popover>
    </>
  );
};

const StyledPaper = styled(
  ({ isClickEnabled, variant, severity, ...props }) => <Paper {...props} />,
)`
  && {
    ${({ isClickEnabled }) => isClickEnabled && "cursor: pointer;"}
    ${({ variant }) => variant === BannerVariant.List && "border-radius: 0;"}
    background-color: ${({ severity = true, theme }) =>
      severity === BannerSeverity.Info
        ? `${theme.tokens.background.neutral.default}`
        : severity === BannerSeverity.Warning
          ? `${theme.tokens.background.warning.default}}`
          : severity === BannerSeverity.Error
            ? `${theme.tokens.background.danger.default}`
            : severity === BannerSeverity.Notice
              ? theme.tokens.background.brand.default
              : "inherit"};

    border-left-style: solid;
    border-left-width: 0.25rem;
    border-left-color: ${({ severity = true, theme }) =>
      severity === BannerSeverity.Info
        ? `${theme.tokens.border.brand}`
        : severity === BannerSeverity.Warning
          ? `${theme.tokens.border.warning}`
          : severity === BannerSeverity.Error
            ? `${theme.tokens.border.danger}`
            : severity === BannerSeverity.Notice
              ? theme.tokens.border.brand
              : "inherit"};
    box-shadow: none;
  }
`;

const StyledWarningIcon = styled(({ severity, ...props }) => (
  <WarningAmberOutlined {...props} />
))`
  && {
    color: ${({ severity = true, theme }) =>
      severity === BannerSeverity.Info
        ? `${theme.tokens.icon.brand}`
        : `${theme.tokens.icon.warning}`};
  }
`;

const StyledTertiaryButtons = styled(TertiaryButton)`
  font-weight: 400;
  font-size: 0.875rem;
  height: 2.5rem;
`;
