import { Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { Disable2faModal } from "~/components/settings-modal/views/profile/security/Disable2faModal";
import { Enable2faModal } from "~/components/settings-modal/views/profile/security/Enable2faModal";
import { RecoveryCodesModal } from "~/components/settings-modal/views/profile/security/RecoveryCodesModal";
import { RegenerateCodesModal } from "~/components/settings-modal/views/profile/security/RegenerateCodesModal";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { secKeys } from "~/state/security";

export function TwoFactorAuth() {
  const [isEnableOpen, setIsEnableOpen] = useState(false);
  const [isDisableOpen, setIsDisableOpen] = useState(false);
  const [isRecoveryCodesOpen, setIsRecoveryCodesOpen] = useState(false);
  const [isRegenCodesOpen, setIsRegenCodesOpen] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState<string[] | undefined>();
  const lang = useLang();
  const user = useUser();
  const queryClient = useQueryClient();

  const is2faEnabled = !!user?.is2faEnabled;

  const openRecoveryCodes = (codes: string[]) => {
    setRecoveryCodes(codes);
    setIsRecoveryCodesOpen(true);
  };

  return (
    <>
      <Enable2faModal
        open={isEnableOpen}
        onClose={() => {
          setIsEnableOpen(false);
        }}
        openRecoveryCodes={openRecoveryCodes}
      />
      <Disable2faModal
        open={isDisableOpen}
        onClose={() => {
          setIsDisableOpen(false);
        }}
      />
      <RegenerateCodesModal
        open={isRegenCodesOpen}
        onClose={() => {
          setIsRegenCodesOpen(false);
        }}
        openRecoveryCodes={openRecoveryCodes}
      />
      <RecoveryCodesModal
        open={isRecoveryCodesOpen}
        onClose={() => {
          setIsRecoveryCodesOpen(false);
        }}
        recoveryCodes={recoveryCodes ?? []}
      />

      <Typography variant="Metropolis/Body/Light">{lang.twoFactorAuth["2FA"]}</Typography>
      <Box display="flex" gap="0.5rem">
        {is2faEnabled ? (
          <TertiaryButton
            size="small"
            onClick={() => {
              setIsRegenCodesOpen(true);
            }}
          >
            {lang.twoFactorAuth.regenerateRecoveryCodes}
          </TertiaryButton>
        ) : null}
        <TertiaryButton
          size="small"
          onClick={() => {
            if (!is2faEnabled) {
              queryClient.invalidateQueries({ queryKey: secKeys.qrCode() });
              setIsEnableOpen(true);
              return;
            }
            setIsDisableOpen(true);
          }}
        >
          {is2faEnabled
            ? lang.twoFactorAuth.disableTwoFactorAuth
            : lang.twoFactorAuth.enableTwoFactorAuth}
        </TertiaryButton>
      </Box>
    </>
  );
}
