// https://github.com/remix-run/react-router/discussions/9841#discussioncomment-4637486
import { useContext, useMemo } from "react";
import { Routes, UNSAFE_RouteContext as RouteContext } from "react-router-dom";

export function AbsoluteRoutes(props: any) {
  const ctx = useContext(RouteContext);

  const value = useMemo(
    () => ({
      ...ctx,
      matches: [],
    }),
    [ctx],
  );

  return (
    <RouteContext.Provider value={value}>
      <Routes {...props} />
    </RouteContext.Provider>
  );
}
