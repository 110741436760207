import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

import { AddressChip } from "~/components/ui/AddressChip";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useUpdateWalletsNameMutation } from "~/state/wallets";
import { type SavedImportOptionByAccount } from "~/types/index";

export const EditNicknameModal = ({
  open,
  handleClose,
  importOption,
}: {
  open: boolean;
  handleClose: () => void;
  importOption: SavedImportOptionByAccount;
}) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const [nickname, setNickname] = useState<string>(() => {
    // the `name` defaults to `wallet address`
    if (importOption?.name.toLowerCase() === importOption?.id.toLowerCase()) {
      return "";
    }

    return importOption?.name;
  });
  const walletNicknameMutate = useUpdateWalletsNameMutation();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{lang.imports.dialogOptions.editNickname}</DialogTitle>
      <DialogContent
        sx={{
          padding: "1.5rem",
        }}
      >
        <AddressChip address={importOption.id} enableCopy enableLink />
        <TextField
          variant="outlined"
          label={lang.api.nickname}
          required
          fullWidth
          onChange={(e) => {
            setNickname(e.target.value);
          }}
          value={nickname}
          sx={{
            marginTop: "1.2rem",
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          bgcolor: tokens.elevation.default,
          border: `1px solid ${tokens.border.neutral.default}`,
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            borderColor: tokens.border.neutral.default,
            "&:hover": {
              background: tokens.elevation.low,
              borderColor: tokens.border.neutral.default,
            },
          }}
        >
          {lang.cancel}
        </Button>
        <LoadingButton
          loading={walletNicknameMutate.isPending}
          onClick={() => {
            walletNicknameMutate.mutate(
              {
                address: importOption.id,
                name: nickname,
              },
              {
                onSuccess: () => {
                  handleClose();
                },
              },
            );
          }}
          color="primary"
          variant="contained"
        >
          {lang.save}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
