import { ArrowRightAlt, ErrorOutline } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import styled from "styled-components/macro";

import { CurrencyDisplay } from "~/components/ui/CurrencyDisplay";
import { DisplayExchange } from "~/components/ui/DisplayExchange";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { TextButtonLink } from "~/components/ui/ui-buttons/TextButton";
import { useDesign } from "~/hooks/useTheme";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { displayCurrency, displayQuantity } from "~/lib/index";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useActionBalancesQuery } from "~/state/actions";
import { Links, Size } from "~/types/enums";
import {
  type ActionRow,
  type BalancesRow,
  type CurrencyIdentifier,
} from "~/types/index";

import { useIsEmbedded } from "../../../hooks/useIsEmbedded";

function BalanceInfoTooltipWrapper({ title }: { title: string }) {
  return (
    <Tooltip
      title={title}
      placement="top"
      style={{
        display: "inline",
      }}
    >
      <InfoIcon
        style={{
          fontSize: "0.90rem",
          verticalAlign: "middle",
          marginLeft: "0.15rem",
          marginBottom: "2px",
        }}
      />
    </Tooltip>
  );
}

export function ActionViewBalance({ row }: { row: ActionRow }) {
  const res = useActionBalancesQuery(row._id);
  const lang = useLang();
  const langBalance = lang.txTable.balanceRemainingInfo;
  const isLoading = res.isPending;
  const data = res.data;
  const inLedgerView = window.location.pathname.includes(Links.Ledger);

  return (
    <Box>
      {!isLoading && data?.rows ? (
        <TableContainer>
          <StyledTable size="small" style={{ minWidth: "75rem" }}>
            <TableHead>
              <TableRow>
                <TableCell>{langBalance.account}</TableCell>
                <TableCell
                  style={{
                    paddingLeft: "0.875rem",
                  }}
                >
                  {langBalance.currency}
                </TableCell>
                <TableCell>{langBalance.type}</TableCell>
                <TableCell align="right">
                  {langBalance.startingBalance}
                  <BalanceInfoTooltipWrapper
                    title={langBalance.tooltips.startingBalance}
                  />
                </TableCell>
                <TableCell align="right">
                  {langBalance.change}
                  <BalanceInfoTooltipWrapper
                    title={langBalance.tooltips.change}
                  />
                </TableCell>
                <TableCell align="right">
                  {langBalance.endingBalance}
                  <BalanceInfoTooltipWrapper
                    title={langBalance.tooltips.endingBalance}
                  />
                </TableCell>
                <TableCell align="right">
                  {langBalance.overallBalance}
                  <BalanceInfoTooltipWrapper
                    title={langBalance.tooltips.overallBalance}
                  />
                </TableCell>
                {inLedgerView ? null : <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.rows.map((row) => (
                <BalanceRemainingRow
                  key={row.type}
                  row={row}
                  inLedgerView={inLedgerView}
                />
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      ) : (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          py="2rem"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

function BalanceRemainingCell({
  quantity,
  error = false,
  errorText,
  currency,
}: {
  quantity?: number;
  error?: boolean;
  errorText?: string;
  currency: CurrencyIdentifier;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const languagePreference = useLanguagePreference();
  const valueDisplay =
    quantity === undefined
      ? "---"
      : displayQuantity(quantity, languagePreference);
  return (
    <TableCell align="right">
      <Box
        component="span"
        mr="0.188rem"
        fontFamily={tokens.fontFamilies.numeric}
      >
        {valueDisplay} {displayCurrency(currency.symbol)}
      </Box>
      <Box component="span" fontSize="1rem" position="relative" top="0.25rem">
        {error && (
          <Tooltip
            title={errorText || lang.txTable.expansionPanel.balanceError}
          >
            <ErrorOutline
              style={{ color: tokens.icon.danger }}
              fontSize="inherit"
            />
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
}

function BalanceRemainingRow({
  row,
  inLedgerView,
}: {
  row: BalancesRow;
  inLedgerView: boolean;
}) {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const design = useDesign();
  const Icon = TradeIcons[row.type];

  return (
    <TableRow
      sx={{
        "&:hover": {
          backgroundColor: design.tokens.background.neutral.lowest.hover,
          cursor: "pointer",
        },
      }}
    >
      <TableCell>
        {row.account && row.displayName ? (
          <DisplayExchange
            exchange={row.account}
            displayName={row.displayName}
            currencySymbol={row.currency.symbol}
            blockchain={row.blockchain}
            enableActions={false}
            trade={row.type}
            size={Size.Small}
          />
        ) : null}
      </TableCell>
      <TableCell>
        <CurrencyDisplay
          currency={row.currency}
          showCurrencyName
          idFilter={null}
          currencyFilter={null}
          width={16}
          height={16}
        />
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <Icon />
          <span>
            {getActionTypeName({
              actionType: row.type,
              lang,
            })}
          </span>
        </Stack>
      </TableCell>
      <BalanceRemainingCell
        quantity={row.startingBalance}
        error={row.balanceErrors?.startingBalance}
        currency={row.currency}
      />
      <BalanceRemainingCell
        quantity={row.change}
        error={false}
        currency={row.currency}
      />
      <BalanceRemainingCell
        quantity={row.finalBalance}
        error={row.balanceErrors?.finalBalance}
        currency={row.currency}
      />
      <BalanceRemainingCell
        quantity={row.overallBalance}
        error={row.balanceErrors?.overall}
        currency={row.currency}
      />
      {inLedgerView ? null : (
        <TableCell align="right">
          <TextButtonLink
            target={isEmbedded ? "_self" : "_blank"}
            rel="noopener noreferrer"
            size="small"
            style={{ fontSize: "0.75rem", fontWeight: 400 }}
            to={`${Links.Ledger}/${row.currency.id}`}
            endIcon={<ArrowRightAlt fontSize="inherit" />}
          >
            {lang.txTable.balanceRemainingInfo.viewLedger}
          </TextButtonLink>
        </TableCell>
      )}
    </TableRow>
  );
}

const StyledTable = styled(Table)`
  &&& {
    th,
    td {
      border-radius: 0;
      border-color: ${({ theme }) => theme.tokens.border.neutral.default};
    }

    thead > tr > th,
    tbody > tr > td {
      font-size: 0.75rem;
      font-weight: 500;
      vertical-align: middle;

      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        padding-right: 1.5rem;
      }
    }

    thead > tr > th {
      color: ${({ theme }) => theme.tokens.text.low};
      font-weight: 500;
      border-bottom: 1px solid
        ${({ theme }) => theme.tokens.border.neutral.default};
      padding: 0.375rem;
    }

    tbody > tr > td {
      padding: 0.25rem 0.375rem;
    }

    tbody > tr > td strong {
      font-weight: 400;
      color: ${({ theme }) => theme.tokens.text.default};
    }

    tbody > tr:last-child > td {
      border-top: 1px solid
        ${({ theme }) => theme.tokens.border.neutral.default};
      border-bottom: 0;
    }
  }
`;
