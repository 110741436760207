/* eslint-disable no-restricted-syntax */
/*
 * DO NOT EDIT THIS FILE MANUALLY
 * IT IS AUTOMATICALLY GENERATED AND CHANGES WILL BE OVERWRITTEN
 * SEE `scripts/generateIconColors.ts` FOR MORE INFO
 */

export const exchangeColorConfig = {
  "0x60ae616a2155ee3d9a68541ba4544862310933d4": {
    backgroundColor: "#c76b64",
  },
  "0xe592427a0aece92de3edee1f18e0157c05861564": {
    backgroundColor: "#f7dce5",
  },
  "1337": {
    backgroundColor: "#d2c4c4",
  },
  "2give": {
    backgroundColor: "#ebcc69",
  },
  "42": {
    backgroundColor: "#464646",
  },
  "50x": {
    backgroundColor: "#06586a",
  },
  "55com": {
    backgroundColor: "#1a4a9c",
  },
  aax: {
    backgroundColor: "#4487f4",
  },
  abcc: {
    backgroundColor: "#2b2b2b",
  },
  abelian: {
    backgroundColor: "#58befc",
  },
  abra: {
    backgroundColor: "#441c64",
  },
  acala: {
    backgroundColor: "#ec264e",
  },
  acx: {
    backgroundColor: "#14b4bc",
  },
  adalite: {
    backgroundColor: "#faeff8",
  },
  adc: {
    backgroundColor: "#e1f1fb",
  },
  advcash: {
    backgroundColor: "#05ac7c",
  },
  aevo: {
    backgroundColor: "#e4e4e4",
  },
  agm: {
    backgroundColor: "#3c6cd9",
  },
  aia_chain: {
    backgroundColor: "#5483fa",
  },
  aidosmarket: {
    backgroundColor: "#07bdbd",
  },
  aioz_network: {
    backgroundColor: "#24241c",
  },
  airswap: {
    backgroundColor: "#2c73fb",
  },
  ajuna: {
    backgroundColor: "#4f8fc8",
  },
  aleph_zero: {
    backgroundColor: "#141c24",
  },
  algorand: {
    backgroundColor: "#bfbfbf",
  },
  alias: {
    backgroundColor: "#d4741c",
  },
  allbit: {
    backgroundColor: "#049ac4",
  },
  allcoin: {
    backgroundColor: "#5b91fc",
  },
  altair: {
    backgroundColor: "#bb8b48",
  },
  altcointrader: {
    backgroundColor: "#2c343c",
  },
  alterdice: {
    backgroundColor: "#07adf9",
  },
  altilly: {
    backgroundColor: "#0c5f81",
  },
  amazewallet: {
    backgroundColor: "#cccccc",
  },
  amber: {
    backgroundColor: "#282935",
  },
  ambire_wallet: {
    backgroundColor: "#6609f7",
  },
  amon: {
    backgroundColor: "#10c0e6",
  },
  amplitude: {
    backgroundColor: "#4ae099",
  },
  anchorage_digital: {
    backgroundColor: "#1b4a67",
  },
  anxpro: {
    backgroundColor: "#d2242c",
  },
  aptos: {
    backgroundColor: "#040404",
  },
  arbitrum: {
    backgroundColor: "#93bbdb",
  },
  "arbitrum-nova": {
    backgroundColor: "#f47c14",
  },
  arco: {
    backgroundColor: "#c8b66f",
  },
  arepa: {
    backgroundColor: "#84683e",
  },
  arg: {
    backgroundColor: "#a41434",
  },
  argent: {
    backgroundColor: "#ec845d",
  },
  ari: {
    backgroundColor: "#91d5f0",
  },
  "armory-wallet": {
    backgroundColor: "#3b4a61",
  },
  artisturba: {
    backgroundColor: "#f8b23e",
  },
  arweave: {
    backgroundColor: "#242424",
  },
  arweaveOriginal: {
    backgroundColor: "#242424",
  },
  astar: {
    backgroundColor: "#157ce0",
  },
  atato_custody: {
    backgroundColor: "#ccb4c4",
  },
  atomex: {
    backgroundColor: "#3464ac",
  },
  "atomic-wallet": {
    backgroundColor: "#2d3d58",
  },
  atomicdex: {
    backgroundColor: "#8cdcda",
  },
  atomicwallet: {
    backgroundColor: "#289cfc",
  },
  audax: {
    backgroundColor: "#9434cc",
  },
  aur: {
    backgroundColor: "#24645b",
  },
  aurora: {
    backgroundColor: "#6cd34c",
  },
  authereum: {
    backgroundColor: "#fc5241",
  },
  avalanche: {
    backgroundColor: "#ec4343",
  },
  "avalanche-c-chain": {
    backgroundColor: "#ec4343",
  },
  aventus: {
    backgroundColor: "#212938",
  },
  avme: {
    backgroundColor: "#1f0e48",
  },
  axiv: {
    backgroundColor: "#2c2f3a",
  },
  aztec: {
    backgroundColor: "#7c5ff3",
  },
  b3: {
    backgroundColor: "#20444b",
  },
  bajun: {
    backgroundColor: "#e06965",
  },
  balancer: {
    backgroundColor: "#080808",
  },
  ballet: {
    backgroundColor: "#9a9a9a",
  },
  bamboorelay: {
    backgroundColor: "#f4a364",
  },
  bancornetwork: {
    backgroundColor: "#0c0d2c",
  },
  base: {
    backgroundColor: "#0454fc",
  },
  basilisk: {
    backgroundColor: "#528129",
  },
  bay: {
    backgroundColor: "#e5e5f2",
  },
  bbk: {
    backgroundColor: "#7e78e0",
  },
  bbp: {
    backgroundColor: "#e4af2e",
  },
  bbtc: {
    backgroundColor: "#dcba6c",
  },
  bbx: {
    backgroundColor: "#52aefa",
  },
  bcc: {
    backgroundColor: "#e8740f",
  },
  bccx: {
    backgroundColor: "#b3b3b3",
  },
  bcex: {
    backgroundColor: "#77cbda",
  },
  bch: {
    backgroundColor: "#8ac14e",
  },
  "bcoin.sg": {
    backgroundColor: "#2474c5",
  },
  bct: {
    backgroundColor: "#b3924e",
  },
  bean: {
    backgroundColor: "#4e6b21",
  },
  beaxy: {
    backgroundColor: "#64cbcc",
  },
  beefy: {
    backgroundColor: "#cccccc",
  },
  bequant: {
    backgroundColor: "#ebeb6b",
  },
  bgogo: {
    backgroundColor: "#24a4f4",
  },
  bibox: {
    backgroundColor: "#d1d1d1",
  },
  bidesk: {
    backgroundColor: "#0474fc",
  },
  "bifrost-dot": {
    backgroundColor: "#040404",
  },
  "bifrost-ksm": {
    backgroundColor: "#040404",
  },
  bigone: {
    backgroundColor: "#b96894",
  },
  biki: {
    backgroundColor: "#f4c43c",
  },
  bilaxy: {
    backgroundColor: "#64dbfb",
  },
  binance: {
    backgroundColor: "#f4bc2c",
  },
  "binance-smart-chain": {
    backgroundColor: "#f4bc2c",
  },
  "binance.kr": {
    backgroundColor: "#222222",
  },
  binancechain: {
    backgroundColor: "#f4bc2c",
  },
  binancedex: {
    backgroundColor: "#f2bb0c",
  },
  binancefutures: {
    backgroundColor: "#f4bc2c",
  },
  binanceje: {
    backgroundColor: "#f2bd11",
  },
  binancejex: {
    backgroundColor: "#2ca2d2",
  },
  binancemargin: {
    backgroundColor: "#f4bc2c",
  },
  binanceus: {
    backgroundColor: "#f4bc2c",
  },
  bione: {
    backgroundColor: "#1499d0",
  },
  birakenetwork: {
    backgroundColor: "#1f2438",
  },
  bisq: {
    backgroundColor: "#040414",
  },
  bit2c: {
    backgroundColor: "#e7c819",
  },
  bitaroo: {
    backgroundColor: "#fc9c04",
  },
  bitasset: {
    backgroundColor: "#1182fc",
  },
  bitay: {
    backgroundColor: "#297bc0",
  },
  bitbank: {
    backgroundColor: "#0b3c6a",
  },
  bitbay: {
    backgroundColor: "#257dcf",
  },
  bitbns: {
    backgroundColor: "#9c04dc",
  },
  bitbuy: {
    backgroundColor: "#6b4c84",
  },
  bitci: {
    backgroundColor: "#ec4c04",
  },
  bitclude: {
    backgroundColor: "#1372d3",
  },
  bitcoin_well: {
    backgroundColor: "#747880",
  },
  bitcoincom: {
    backgroundColor: "#04c48c",
  },
  bitcoincom_wallet: {
    backgroundColor: "#24cca4",
  },
  bitcointrade: {
    backgroundColor: "#060606",
  },
  bitcountrypioneer: {
    backgroundColor: "#740978",
  },
  "bitex.la": {
    backgroundColor: "#1c4374",
  },
  bitexbook: {
    backgroundColor: "#3099d4",
  },
  bitexlive: {
    backgroundColor: "#0c203a",
  },
  bitfi: {
    backgroundColor: "#fccc24",
  },
  bitfinex: {
    backgroundColor: "#14b354",
  },
  bitfinex2: {
    backgroundColor: "#14b354",
  },
  bitflyer: {
    backgroundColor: "#349bd4",
  },
  bitforex: {
    backgroundColor: "#04cab0",
  },
  bitfront: {
    backgroundColor: "#1671d8",
  },
  bitget_wallet: {
    backgroundColor: "#29e4fb",
  },
  bitgo: {
    backgroundColor: "#11a7d3",
  },
  "bitgo-wallet": {
    backgroundColor: "#04acec",
  },
  bitgreen: {
    backgroundColor: "#29514f",
  },
  bithesap: {
    backgroundColor: "#434343",
  },
  bithumb: {
    backgroundColor: "#f47324",
  },
  bithumbglobal: {
    backgroundColor: "#f47323",
  },
  bithumbsingapore: {
    backgroundColor: "#f47323",
  },
  bitinka: {
    backgroundColor: "#5cfcfc",
  },
  bitkeep: {
    backgroundColor: "#4b5bfc",
  },
  bitkub: {
    backgroundColor: "#4cbc64",
  },
  "bitlox-wallet": {
    backgroundColor: "#d40515",
  },
  bitmart: {
    backgroundColor: "#241c1c",
  },
  bitmax: {
    backgroundColor: "#1e2e3e",
  },
  bitmex: {
    backgroundColor: "#135bfb",
  },
  bitnaru: {
    backgroundColor: "#043565",
  },
  bitonic: {
    backgroundColor: "#3bb44c",
  },
  bitpanda: {
    backgroundColor: "#d9d9d9",
  },
  bitpanda_custody: {
    backgroundColor: "#84cb6c",
  },
  bitpandapro: {
    backgroundColor: "#050505",
  },
  bitrabbit: {
    backgroundColor: "#f9daa8",
  },
  bitrue: {
    backgroundColor: "#f0d046",
  },
  bitsblockchain: {
    backgroundColor: "#043363",
  },
  bitsdaq: {
    backgroundColor: "#1c2a34",
  },
  bitsharesassetexchange: {
    backgroundColor: "#144c82",
  },
  bitso: {
    backgroundColor: "#7cbc5c",
  },
  bitsong: {
    backgroundColor: "#fb4f3f",
  },
  bitsonic: {
    backgroundColor: "#44b4c0",
  },
  bitstamp: {
    backgroundColor: "#139c4b",
  },
  "bitstamp(ripplegateway)": {
    backgroundColor: "#2c2c2c",
  },
  bitstamp1: {
    backgroundColor: "#139c4b",
  },
  bitstorage: {
    backgroundColor: "#15244b",
  },
  bittorrentchain: {
    backgroundColor: "#cacaca",
  },
  bittrade: {
    backgroundColor: "#040404",
  },
  bittrex: {
    backgroundColor: "#0483d4",
  },
  bittylicious: {
    backgroundColor: "#231c1c",
  },
  bitubu: {
    backgroundColor: "#1d1c3f",
  },
  bitvast: {
    backgroundColor: "#191b31",
  },
  bitvavo: {
    backgroundColor: "#0454fc",
  },
  bitwala: {
    backgroundColor: "#132b54",
  },
  bityard: {
    backgroundColor: "#fcbc34",
  },
  bitz: {
    backgroundColor: "#043393",
  },
  bkex: {
    backgroundColor: "#ec6c54",
  },
  blackmoon: {
    backgroundColor: "#040404",
  },
  blast: {
    backgroundColor: "#090906",
  },
  blc: {
    backgroundColor: "#c59340",
  },
  bleutrade: {
    backgroundColor: "#044473",
  },
  blitz: {
    backgroundColor: "#e22c54",
  },
  blk: {
    backgroundColor: "#d9b05b",
  },
  block: {
    backgroundColor: "#989ba3",
  },
  "blockchain-wallet": {
    backgroundColor: "#26a7d6",
  },
  blockchaindotcom: {
    backgroundColor: "#3e8bf1",
  },
  "blockchains-my": {
    backgroundColor: "#f0f0e1",
  },
  blockearner: {
    backgroundColor: "#040404",
  },
  blockfi: {
    backgroundColor: "#082e9f",
  },
  blockstream_green: {
    backgroundColor: "#131618",
  },
  blockwallet: {
    backgroundColor: "#79d30a",
  },
  bluewallet: {
    backgroundColor: "#c8acb4",
  },
  blur: {
    backgroundColor: "#180806",
  },
  bnode: {
    backgroundColor: "#212220",
  },
  boba: {
    backgroundColor: "#040404",
  },
  bold: {
    backgroundColor: "#171717",
  },
  boli: {
    backgroundColor: "#998244",
  },
  brave_software: {
    backgroundColor: "#f25c2d",
  },
  braziliex: {
    backgroundColor: "#4e89e9",
  },
  "bread-wallet": {
    backgroundColor: "#fc7b65",
  },
  bridgehub: {
    backgroundColor: "#040404",
  },
  bro: {
    backgroundColor: "#ebbf2e",
  },
  bsd: {
    backgroundColor: "#d4d5d7",
  },
  bsv: {
    backgroundColor: "#eab40b",
  },
  btc: {
    backgroundColor: "#fcac04",
  },
  "btc-alpha": {
    backgroundColor: "#3d5b9b",
  },
  "btc-exchange": {
    backgroundColor: "#e6dfcd",
  },
  btcalpha: {
    backgroundColor: "#04afb8",
  },
  btcbox: {
    backgroundColor: "#545554",
  },
  btccomau: {
    backgroundColor: "#3464fc",
  },
  btcmarkets: {
    backgroundColor: "#042c2c",
  },
  btctradeua: {
    backgroundColor: "#f3e496",
  },
  btcturkpro: {
    backgroundColor: "#0476fc",
  },
  btdx: {
    backgroundColor: "#0ab5fc",
  },
  btx: {
    backgroundColor: "#fcdaac",
  },
  buda: {
    backgroundColor: "#0494fc",
  },
  bundil: {
    backgroundColor: "#3cc3bf",
  },
  busy_dao: {
    backgroundColor: "#13558a",
  },
  bvm: {
    backgroundColor: "#f16927",
  },
  bwcom: {
    backgroundColor: "#cc2c24",
  },
  bwi: {
    backgroundColor: "#eea81b",
  },
  bxt: {
    backgroundColor: "#403f3d",
  },
  bybit: {
    backgroundColor: "#141c2c",
  },
  byc: {
    backgroundColor: "#f49194",
  },
  bynd: {
    backgroundColor: "#737373",
  },
  bytetrade: {
    backgroundColor: "#444c5c",
  },
  bytz: {
    backgroundColor: "#1c141f",
  },
  "c-patex": {
    backgroundColor: "#9695a4",
  },
  c2cx: {
    backgroundColor: "#f25548",
  },
  cake_wallet: {
    backgroundColor: "#07bcfa",
  },
  calamari: {
    backgroundColor: "#0c0c0c",
  },
  calebandbrown: {
    backgroundColor: "#042334",
  },
  cann: {
    backgroundColor: "#57633e",
  },
  canto: {
    backgroundColor: "#04fc9c",
  },
  caps: {
    backgroundColor: "#fc6606",
  },
  "carbon-wallet": {
    backgroundColor: "#383533",
  },
  cardano: {
    backgroundColor: "#286fd2",
  },
  casa: {
    backgroundColor: "#7650fb",
  },
  casascius_physical_bitcoin: {
    backgroundColor: "#797b41",
  },
  cashapp: {
    backgroundColor: "#04d433",
  },
  cashierest: {
    backgroundColor: "#14243b",
  },
  casper: {
    backgroundColor: "#fc2c2c",
  },
  "cat.ex": {
    backgroundColor: "#558bbb",
  },
  cbx: {
    backgroundColor: "#ee292a",
  },
  ccxcanada: {
    backgroundColor: "#2a7bfc",
  },
  cdax: {
    backgroundColor: "#fbd6d2",
  },
  cdn: {
    backgroundColor: "#f34d51",
  },
  celery: {
    backgroundColor: "#a5e4c4",
  },
  celestia: {
    backgroundColor: "#7c2cfc",
  },
  celo: {
    backgroundColor: "#34d37b",
  },
  celoOriginal: {
    backgroundColor: "#fbcb5c",
  },
  celsius: {
    backgroundColor: "#4454a4",
  },
  centrifuge: {
    backgroundColor: "#2f2f2f",
  },
  cex: {
    backgroundColor: "#17c7f3",
  },
  chainx: {
    backgroundColor: "#051324",
  },
  change_wallet: {
    backgroundColor: "#3cdcb4",
  },
  changelly: {
    backgroundColor: "#14d47c",
  },
  chaoex: {
    backgroundColor: "#ecc02f",
  },
  chbt: {
    backgroundColor: "#4a5ccd",
  },
  chess: {
    backgroundColor: "#efefef",
  },
  chia: {
    backgroundColor: "#3bab5b",
  },
  chihuahua: {
    backgroundColor: "#dddcdb",
  },
  chiliz: {
    backgroundColor: "#ab0424",
  },
  chivo: {
    backgroundColor: "#b0b1c3",
  },
  chromia: {
    backgroundColor: "#251d26",
  },
  circ: {
    backgroundColor: "#040404",
  },
  citadelone: {
    backgroundColor: "#040404",
  },
  citex: {
    backgroundColor: "#3c8cfc",
  },
  civ: {
    backgroundColor: "#f0cca2",
  },
  cloak: {
    backgroundColor: "#414141",
  },
  clover: {
    backgroundColor: "#24a474",
  },
  clvchain: {
    backgroundColor: "#a0f2e9",
  },
  cno: {
    backgroundColor: "#c4b595",
  },
  cobinhood: {
    backgroundColor: "#73dcc5",
  },
  "cobo-wallet": {
    backgroundColor: "#4b1bed",
  },
  coin98: {
    backgroundColor: "#debc53",
  },
  coin_wallet: {
    backgroundColor: "#62be7f",
  },
  coinall: {
    backgroundColor: "#1672f5",
  },
  coinapult: {
    backgroundColor: "#74bca4",
  },
  "coinbase-wallet": {
    backgroundColor: "#1357ec",
  },
  coinbaseprime: {
    backgroundColor: "#1c2c3c",
  },
  coinbasepro: {
    backgroundColor: "#1c2c3c",
  },
  coinbe: {
    backgroundColor: "#1c1c44",
  },
  coinbelly: {
    backgroundColor: "#eee9e8",
  },
  coinbene: {
    backgroundColor: "#1564fc",
  },
  coinberry: {
    backgroundColor: "#ec2c6c",
  },
  coinbit: {
    backgroundColor: "#14347c",
  },
  coincheck: {
    backgroundColor: "#18e5d8",
  },
  coincorner: {
    backgroundColor: "#2cb7e3",
  },
  coindcx: {
    backgroundColor: "#fc9c04",
  },
  coindeal: {
    backgroundColor: "#141c2c",
  },
  coineal: {
    backgroundColor: "#fcc31b",
  },
  coinegg: {
    backgroundColor: "#0988f3",
  },
  coinex: {
    backgroundColor: "#10d4c3",
  },
  coinexchange: {
    backgroundColor: "#c5c4c9",
  },
  coinfalcon: {
    backgroundColor: "#1eb6fb",
  },
  coinfield: {
    backgroundColor: "#04d3cb",
  },
  coinfloor: {
    backgroundColor: "#fcce04",
  },
  coingi: {
    backgroundColor: "#f39114",
  },
  coinhe: {
    backgroundColor: "#0d70e6",
  },
  coinhub: {
    backgroundColor: "#5c5c5c",
  },
  coinjar: {
    backgroundColor: "#050505",
  },
  coinjarexchange: {
    backgroundColor: "#050505",
  },
  coinlim: {
    backgroundColor: "#ef922d",
  },
  coinlist: {
    backgroundColor: "#741bc0",
  },
  coinlistpro: {
    backgroundColor: "#741bc0",
  },
  coinmate: {
    backgroundColor: "#647494",
  },
  coinme: {
    backgroundColor: "#382d50",
  },
  coinmetro: {
    backgroundColor: "#3e3a2e",
  },
  coinomi: {
    backgroundColor: "#d3d0cf",
  },
  coinone: {
    backgroundColor: "#048ffc",
  },
  coinpayments_wallet: {
    backgroundColor: "#c5cbd8",
  },
  coinplace: {
    backgroundColor: "#fc9424",
  },
  coinremitter: {
    backgroundColor: "#f1a829",
  },
  coins: {
    backgroundColor: "#fcf1d8",
  },
  coinsbank: {
    backgroundColor: "#457be2",
  },
  coinsbit: {
    backgroundColor: "#a88858",
  },
  coinsmart: {
    backgroundColor: "#edf5f7",
  },
  coinspace: {
    backgroundColor: "#575f5a",
  },
  coinspace_wallet: {
    backgroundColor: "#6cc484",
  },
  coinspot: {
    backgroundColor: "#243444",
  },
  coinsquare: {
    backgroundColor: "#cbcbcb",
  },
  coinstash: {
    backgroundColor: "#4443a0",
  },
  coinsuper: {
    backgroundColor: "#1c3a81",
  },
  cointiger: {
    backgroundColor: "#5475af",
  },
  cointracking: {
    backgroundColor: "#1f354b",
  },
  cointree: {
    backgroundColor: "#5beba3",
  },
  coinut: {
    backgroundColor: "#056399",
  },
  coinvault: {
    backgroundColor: "#136c9c",
  },
  coinweb: {
    backgroundColor: "#4c4c6c",
  },
  coinzest: {
    backgroundColor: "#eb4a4a",
  },
  coinzo: {
    backgroundColor: "#0454fa",
  },
  coldcard: {
    backgroundColor: "#f4dcdc",
  },
  collectives: {
    backgroundColor: "#c8c8c8",
  },
  colx: {
    backgroundColor: "#4f866b",
  },
  composable: {
    backgroundColor: "#160414",
  },
  compound: {
    backgroundColor: "#04d393",
  },
  conio: {
    backgroundColor: "#1f2460",
  },
  connectee: {
    backgroundColor: "#ddeffb",
  },
  coolwallet: {
    backgroundColor: "#34445b",
  },
  copay: {
    backgroundColor: "#2c3c54",
  },
  copperco: {
    backgroundColor: "#c48e78",
  },
  coreum: {
    backgroundColor: "#24b47f",
  },
  "cosmostation-wallet": {
    backgroundColor: "#232324",
  },
  coss: {
    backgroundColor: "#370485",
  },
  "coss-wallet": {
    backgroundColor: "#4d088a",
  },
  cpdax: {
    backgroundColor: "#040404",
  },
  cps: {
    backgroundColor: "#fab904",
  },
  crab: {
    backgroundColor: "#ab48c4",
  },
  crex24: {
    backgroundColor: "#4ca49c",
  },
  cronos: {
    backgroundColor: "#051323",
  },
  crossexchange: {
    backgroundColor: "#2374ba",
  },
  crt: {
    backgroundColor: "#4983ba",
  },
  crust: {
    backgroundColor: "#fc8c14",
  },
  crw: {
    backgroundColor: "#040c1c",
  },
  crxzone: {
    backgroundColor: "#67e5fc",
  },
  crypt: {
    backgroundColor: "#043c6a",
  },
  cryptaldash: {
    backgroundColor: "#436bac",
  },
  crypterium: {
    backgroundColor: "#2c93fb",
  },
  "crypto-wallet": {
    backgroundColor: "#042452",
  },
  cryptodotcom: {
    backgroundColor: "#232d58",
  },
  cryptodotcomexchange: {
    backgroundColor: "#232d58",
  },
  cryptology: {
    backgroundColor: "#fcc236",
  },
  cryptomarket: {
    backgroundColor: "#143c6c",
  },
  cryptomate: {
    backgroundColor: "#6fb0ce",
  },
  cryptonator: {
    backgroundColor: "#146cbb",
  },
  cryptonex: {
    backgroundColor: "#384493",
  },
  cryptopia: {
    backgroundColor: "#4d4d4d",
  },
  cryptospend: {
    backgroundColor: "#0494fc",
  },
  cryptosteel: {
    backgroundColor: "#e4e4e4",
  },
  cryptotag: {
    backgroundColor: "#1a0a0a",
  },
  cudos: {
    backgroundColor: "#34bcfc",
  },
  cure: {
    backgroundColor: "#bf946a",
  },
  currencycom: {
    backgroundColor: "#04c46c",
  },
  curve: {
    backgroundColor: "#1cbede",
  },
  cybavo: {
    backgroundColor: "#cddfec",
  },
  d: {
    backgroundColor: "#c8c8c8",
  },
  daedalus: {
    backgroundColor: "#2dbe97",
  },
  daoipci: {
    backgroundColor: "#07d4ce",
  },
  darwinia: {
    backgroundColor: "#fc0484",
  },
  dash: {
    backgroundColor: "#048be6",
  },
  dcoin: {
    backgroundColor: "#1c54ec",
  },
  ddex: {
    backgroundColor: "#050505",
  },
  decentrex: {
    backgroundColor: "#047b6b",
  },
  "decred-wallet": {
    backgroundColor: "#2c73fb",
  },
  degate: {
    backgroundColor: "#0484fc",
  },
  delta: {
    backgroundColor: "#08a2de",
  },
  deltabalances: {
    backgroundColor: "#3dd206",
  },
  dem: {
    backgroundColor: "#b2925f",
  },
  deribit: {
    backgroundColor: "#04d4bc",
  },
  "dex-trade": {
    backgroundColor: "#1c242c",
  },
  "dex.top": {
    backgroundColor: "#2c4b9d",
  },
  dfi: {
    backgroundColor: "#fc04ac",
  },
  dgb: {
    backgroundColor: "#052550",
  },
  dgc: {
    backgroundColor: "#dadbda",
  },
  digifinex: {
    backgroundColor: "#1343f4",
  },
  digitalsurge: {
    backgroundColor: "#24143c",
  },
  dime: {
    backgroundColor: "#044ca4",
  },
  divi: {
    backgroundColor: "#ec243f",
  },
  diviwallet: {
    backgroundColor: "#5e5ee2",
  },
  dmb: {
    backgroundColor: "#ceced9",
  },
  dmd: {
    backgroundColor: "#a4bce3",
  },
  dobiexchange: {
    backgroundColor: "#fcbc04",
  },
  dogcoin: {
    backgroundColor: "#fc970d",
  },
  doge: {
    backgroundColor: "#bda030",
  },
  donu: {
    backgroundColor: "#4b64ab",
  },
  dope: {
    backgroundColor: "#bab84b",
  },
  dopex: {
    backgroundColor: "#040404",
  },
  dragonex: {
    backgroundColor: "#1a1e23",
  },
  drz: {
    backgroundColor: "#183951",
  },
  dsx: {
    backgroundColor: "#3c24dc",
  },
  dusk: {
    backgroundColor: "#e4dcec",
  },
  dvc: {
    backgroundColor: "#c4dfc6",
  },
  dydx: {
    backgroundColor: "#232323",
  },
  dydx4: {
    backgroundColor: "#21212f",
  },
  dymension: {
    backgroundColor: "#efe0d3",
  },
  dynex: {
    backgroundColor: "#1596ed",
  },
  eac: {
    backgroundColor: "#715a31",
  },
  easycrypto: {
    backgroundColor: "#8366d2",
  },
  easytrade: {
    backgroundColor: "#fc9c54",
  },
  ec: {
    backgroundColor: "#14182d",
  },
  ecash: {
    backgroundColor: "#0771c0",
  },
  ecc: {
    backgroundColor: "#c78421",
  },
  eclipcoin: {
    backgroundColor: "#270b47",
  },
  ecomi: {
    backgroundColor: "#393939",
  },
  ecto_wallet: {
    backgroundColor: "#d6f0f6",
  },
  ecxx: {
    backgroundColor: "#0f0f0f",
  },
  "edge-app": {
    backgroundColor: "#0c365d",
  },
  edge_wallet: {
    backgroundColor: "#0c2343",
  },
  "eesty-wallet": {
    backgroundColor: "#dce1ee",
  },
  efl: {
    backgroundColor: "#fa940d",
  },
  egc: {
    backgroundColor: "#beded1",
  },
  eidoo: {
    backgroundColor: "#ebf5f6",
  },
  elaphant: {
    backgroundColor: "#fb415d",
  },
  elbaite: {
    backgroundColor: "#acfce4",
  },
  electra_protocol: {
    backgroundColor: "#10409c",
  },
  electron_cash_wallet: {
    backgroundColor: "#0454c3",
  },
  electrum: {
    backgroundColor: "#1288c7",
  },
  electrum_wallet: {
    backgroundColor: "#282829",
  },
  "ellipal-wallet": {
    backgroundColor: "#357cf1",
  },
  elrond: {
    backgroundColor: "#0c042c",
  },
  elrondOriginal: {
    backgroundColor: "#0c042c",
  },
  elt: {
    backgroundColor: "#d7dfe7",
  },
  emc2: {
    backgroundColor: "#0477af",
  },
  emd: {
    backgroundColor: "#0d5632",
  },
  emx: {
    backgroundColor: "#34cbe4",
  },
  enclaves: {
    backgroundColor: "#e4e4ec",
  },
  encointer: {
    backgroundColor: "#0929d0",
  },
  enjin: {
    backgroundColor: "#7b63d3",
  },
  enjin_wallet: {
    backgroundColor: "#070707",
  },
  enkrypt: {
    backgroundColor: "#6b4bfb",
  },
  enrg: {
    backgroundColor: "#7ec561",
  },
  eny: {
    backgroundColor: "#313177",
  },
  eos: {
    backgroundColor: "#040404",
  },
  epic_cash: {
    backgroundColor: "#d8bc94",
  },
  equicex: {
    backgroundColor: "#0484ec",
  },
  equilibrium: {
    backgroundColor: "#112639",
  },
  equos: {
    backgroundColor: "#131333",
  },
  ercdex: {
    backgroundColor: "#b1b6c7",
  },
  eterbase: {
    backgroundColor: "#17c0fa",
  },
  eternl: {
    backgroundColor: "#fb8442",
  },
  eth: {
    backgroundColor: "#c5cdf4",
  },
  ethen: {
    backgroundColor: "#2c3c4c",
  },
  etherc: {
    backgroundColor: "#3b3c53",
  },
  etherdelta: {
    backgroundColor: "#272928",
  },
  "ethereum-classic": {
    backgroundColor: "#3cbc3c",
  },
  etherflyer: {
    backgroundColor: "#2c6cbc",
  },
  etherli_wallet: {
    backgroundColor: "#3c3c3c",
  },
  etherscan: {
    backgroundColor: "#949494",
  },
  ethex: {
    backgroundColor: "#7a8080",
  },
  ethfinex: {
    backgroundColor: "#272736",
  },
  etoro: {
    backgroundColor: "#74be24",
  },
  evercoin: {
    backgroundColor: "#04bd9f",
  },
  evmos: {
    backgroundColor: "#de4337",
  },
  excl: {
    backgroundColor: "#31302c",
  },
  exir: {
    backgroundColor: "#b6b6b6",
  },
  exmarkets: {
    backgroundColor: "#142b3c",
  },
  exmo: {
    backgroundColor: "#1444e3",
  },
  exodus: {
    backgroundColor: "#8393f4",
  },
  exrates: {
    backgroundColor: "#242444",
  },
  exx: {
    backgroundColor: "#d6a454",
  },
  fantom: {
    backgroundColor: "#446cd4",
  },
  fatbtc: {
    backgroundColor: "#0e7ec2",
  },
  fetchai: {
    backgroundColor: "#242c44",
  },
  fex: {
    backgroundColor: "#0c1b34",
  },
  finexbox: {
    backgroundColor: "#1ba2a2",
  },
  finoa: {
    backgroundColor: "#cef1e9",
  },
  fio_protocol: {
    backgroundColor: "#a48cec",
  },
  fireblocks: {
    backgroundColor: "#ebebe7",
  },
  firo: {
    backgroundColor: "#e0b5bd",
  },
  flare: {
    backgroundColor: "#e41c5c",
  },
  flint: {
    backgroundColor: "#fc6404",
  },
  flow: {
    backgroundColor: "#04ec8c",
  },
  flowOriginal: {
    backgroundColor: "#04ec8c",
  },
  flowbtc: {
    backgroundColor: "#04ec8c",
  },
  foblgate: {
    backgroundColor: "#e41b7c",
  },
  folgory: {
    backgroundColor: "#54a034",
  },
  fortmatic: {
    backgroundColor: "#9c8cf4",
  },
  frame: {
    backgroundColor: "#f5edf8",
  },
  freewallet: {
    backgroundColor: "#047e92",
  },
  freiexchange: {
    backgroundColor: "#e00a0a",
  },
  frequency: {
    backgroundColor: "#34be8c",
  },
  frontier: {
    backgroundColor: "#c6c6c6",
  },
  frostbyte: {
    backgroundColor: "#13b2f9",
  },
  fst: {
    backgroundColor: "#eda225",
  },
  ftc: {
    backgroundColor: "#295676",
  },
  ftx: {
    backgroundColor: "#47bbcf",
  },
  ftxus: {
    backgroundColor: "#0c141c",
  },
  fv_bank: {
    backgroundColor: "#cacaca",
  },
  gap: {
    backgroundColor: "#3ec4e9",
  },
  gateio: {
    backgroundColor: "#dc5c5c",
  },
  gcr: {
    backgroundColor: "#d8ebc0",
  },
  gdac: {
    backgroundColor: "#0c7dc6",
  },
  gem_guard_wallet: {
    backgroundColor: "#04fcf4",
  },
  gemini: {
    backgroundColor: "#04dcfc",
  },
  genshiro: {
    backgroundColor: "#ec642c",
  },
  gero: {
    backgroundColor: "#04c790",
  },
  getpaidinbitcoin: {
    backgroundColor: "#d2dae6",
  },
  "giddy-wallet": {
    backgroundColor: "#2f2a23",
  },
  gio: {
    backgroundColor: "#054193",
  },
  glc: {
    backgroundColor: "#f4c617",
  },
  globalblockchainexchange: {
    backgroundColor: "#7d1b20",
  },
  globitex: {
    backgroundColor: "#202020",
  },
  glt: {
    backgroundColor: "#e0d1c8",
  },
  gm: {
    backgroundColor: "#f36934",
  },
  gmx: {
    backgroundColor: "#1c8fdd",
  },
  gopax: {
    backgroundColor: "#2c2c2b",
  },
  graviex: {
    backgroundColor: "#1b3b84",
  },
  "green-address": {
    backgroundColor: "#2a8331",
  },
  grn: {
    backgroundColor: "#241c1c",
  },
  grs: {
    backgroundColor: "#377d94",
  },
  grwi: {
    backgroundColor: "#53792f",
  },
  "guarda-wallet": {
    backgroundColor: "#8785d8",
  },
  gun: {
    backgroundColor: "#131010",
  },
  halodex: {
    backgroundColor: "#ac8454",
  },
  hanbitco: {
    backgroundColor: "#e41c3c",
  },
  hardblock: {
    backgroundColor: "#897e7f",
  },
  harmony: {
    backgroundColor: "#34d1d6",
  },
  hashed: {
    backgroundColor: "#040404",
  },
  hbtc: {
    backgroundColor: "#3474e4",
  },
  hcoin: {
    backgroundColor: "#fca404",
  },
  heatwallet: {
    backgroundColor: "#fc4c04",
  },
  hedera: {
    backgroundColor: "#040404",
  },
  hederaOriginal: {
    backgroundColor: "#7f7f7f",
  },
  heliowallet: {
    backgroundColor: "#05446b",
  },
  helium: {
    backgroundColor: "#3ca4fc",
  },
  heliumOriginal: {
    backgroundColor: "#3ca4fc",
  },
  hex_trust: {
    backgroundColor: "#dde1e5",
  },
  hexawallet: {
    backgroundColor: "#1079bc",
  },
  hitbtc: {
    backgroundColor: "#0c90c2",
  },
  hitbtc2: {
    backgroundColor: "#0c90c2",
  },
  hive: {
    backgroundColor: "#fbf9fa",
  },
  hodlnaut: {
    backgroundColor: "#def0fc",
  },
  hodlr_disks: {
    backgroundColor: "#f7c5b7",
  },
  hollaex: {
    backgroundColor: "#0c0c0c",
  },
  "holy-transaction": {
    backgroundColor: "#068bc2",
  },
  hotbit: {
    backgroundColor: "#dff7f4",
  },
  hth: {
    backgroundColor: "#158879",
  },
  html: {
    backgroundColor: "#eeb31a",
  },
  hubi: {
    backgroundColor: "#ecc44c",
  },
  humanode: {
    backgroundColor: "#ed5e5c",
  },
  huobi: {
    backgroundColor: "#049cdc",
  },
  huobiindonesia: {
    backgroundColor: "#449ccc",
  },
  huobijp: {
    backgroundColor: "#049cdc",
  },
  huobikorea: {
    backgroundColor: "#2cacdc",
  },
  huobipro: {
    backgroundColor: "#049cdc",
  },
  huobiru: {
    backgroundColor: "#049cdc",
  },
  huobirussia: {
    backgroundColor: "#272d4a",
  },
  huobithailand: {
    backgroundColor: "#1b2343",
  },
  hydradx: {
    backgroundColor: "#f355a4",
  },
  hypr_network: {
    backgroundColor: "#d3d3db",
  },
  hytopia: {
    backgroundColor: "#2c2d2d",
  },
  i0c: {
    backgroundColor: "#e6bb41",
  },
  ic: {
    backgroundColor: "#cdbe4c",
  },
  ice3x: {
    backgroundColor: "#0b7bca",
  },
  icn: {
    backgroundColor: "#3b74c2",
  },
  icol: {
    backgroundColor: "#818a94",
  },
  idcm: {
    backgroundColor: "#f1507b",
  },
  idex: {
    backgroundColor: "#084c6b",
  },
  idt: {
    backgroundColor: "#f4f6f7",
  },
  ifc: {
    backgroundColor: "#efc24a",
  },
  iflt: {
    backgroundColor: "#b8bee5",
  },
  il8p: {
    backgroundColor: "#040404",
  },
  imbue: {
    backgroundColor: "#7cee04",
  },
  img: {
    backgroundColor: "#f9c12f",
  },
  imgc: {
    backgroundColor: "#627922",
  },
  imkey: {
    backgroundColor: "#214755",
  },
  immutable: {
    backgroundColor: "#c9c9c9",
  },
  independentreserve: {
    backgroundColor: "#043b5c",
  },
  indiesquare: {
    backgroundColor: "#c93746",
  },
  indodax: {
    backgroundColor: "#0484b4",
  },
  indoex: {
    backgroundColor: "#34bb53",
  },
  "infinito-wallet": {
    backgroundColor: "#2a437d",
  },
  infinity_wallet: {
    backgroundColor: "#141424",
  },
  infinitycoinexchange: {
    backgroundColor: "#b2aa9d",
  },
  infx: {
    backgroundColor: "#0b9cfc",
  },
  injective: {
    backgroundColor: "#04a3fc",
  },
  inn: {
    backgroundColor: "#cadee9",
  },
  insn: {
    backgroundColor: "#2687b9",
  },
  integritee: {
    backgroundColor: "#c4c5c6",
  },
  "integritee-shell": {
    backgroundColor: "#c4c5c6",
  },
  interlay: {
    backgroundColor: "#2c1f3c",
  },
  internet_computer: {
    backgroundColor: "#f05d49",
  },
  ioc: {
    backgroundColor: "#0d1517",
  },
  ion: {
    backgroundColor: "#d6d6d6",
  },
  iotex: {
    backgroundColor: "#242424",
  },
  ipayyou: {
    backgroundColor: "#2467b1",
  },
  isx: {
    backgroundColor: "#cadbe4",
  },
  itbit: {
    backgroundColor: "#fcbc14",
  },
  itoken: {
    backgroundColor: "#24c368",
  },
  ixc: {
    backgroundColor: "#bcbcbc",
  },
  j: {
    backgroundColor: "#1c4f2c",
  },
  jackal_protocol: {
    backgroundColor: "#040c24",
  },
  "jaxx-wallet": {
    backgroundColor: "#25437a",
  },
  jubiter_blade: {
    backgroundColor: "#c4c4cc",
  },
  juno: {
    backgroundColor: "#040404",
  },
  kabocha: {
    backgroundColor: "#292929",
  },
  kapex: {
    backgroundColor: "#4c3c2c",
  },
  karlsen: {
    backgroundColor: "#243c54",
  },
  karura: {
    backgroundColor: "#f03549",
  },
  kaspa: {
    backgroundColor: "#74ccbc",
  },
  kava: {
    backgroundColor: "#fc544c",
  },
  kavaOriginal: {
    backgroundColor: "#fc544c",
  },
  ked: {
    backgroundColor: "#352323",
  },
  "keepkey-wallet": {
    backgroundColor: "#04040b",
  },
  keplr: {
    backgroundColor: "#4566f6",
  },
  keystone: {
    backgroundColor: "#050505",
  },
  kfx: {
    backgroundColor: "#474747",
  },
  khala: {
    backgroundColor: "#04f6f6",
  },
  kilt: {
    backgroundColor: "#961e55",
  },
  kintsugi: {
    backgroundColor: "#04143c",
  },
  kkex: {
    backgroundColor: "#439afb",
  },
  klks: {
    backgroundColor: "#d7c042",
  },
  knc: {
    backgroundColor: "#34cc9c",
  },
  kobo: {
    backgroundColor: "#b4dcc8",
  },
  koinim: {
    backgroundColor: "#3c8bd3",
  },
  konj: {
    backgroundColor: "#040404",
  },
  korbit: {
    backgroundColor: "#3cc4d4",
  },
  kore: {
    backgroundColor: "#ec7454",
  },
  kraken: {
    backgroundColor: "#5444d4",
  },
  krest: {
    backgroundColor: "#ca1c05",
  },
  krypte: {
    backgroundColor: "#4464d4",
  },
  kryptono: {
    backgroundColor: "#1f6fb4",
  },
  kucoin: {
    backgroundColor: "#0494ec",
  },
  kujira: {
    backgroundColor: "#e23b33",
  },
  kukai: {
    backgroundColor: "#5c64fc",
  },
  kuna: {
    backgroundColor: "#040404",
  },
  kusama: {
    backgroundColor: "#040404",
  },
  "kusama-shiden": {
    backgroundColor: "#6c41bb",
  },
  kyber: {
    backgroundColor: "#34cc9c",
  },
  kylacoin: {
    backgroundColor: "#c50ebb",
  },
  kylin: {
    backgroundColor: "#e4047b",
  },
  kyve_network: {
    backgroundColor: "#32514e",
  },
  lakebtc: {
    backgroundColor: "#e4751c",
  },
  lana: {
    backgroundColor: "#d1b657",
  },
  latoken: {
    backgroundColor: "#c1e5eb",
  },
  layerai: {
    backgroundColor: "#0c1314",
  },
  lbank: {
    backgroundColor: "#0483cc",
  },
  lcc: {
    backgroundColor: "#169324",
  },
  ledger: {
    backgroundColor: "#1c222a",
  },
  ledgerwallet: {
    backgroundColor: "#040404",
  },
  ledn: {
    backgroundColor: "#6cd5e2",
  },
  linea: {
    backgroundColor: "#141414",
  },
  linx: {
    backgroundColor: "#aba17a",
  },
  liqui: {
    backgroundColor: "#1c7cfc",
  },
  liquid: {
    backgroundColor: "#0494fc",
  },
  liquidfutures: {
    backgroundColor: "#0494fc",
  },
  liquidlayer: {
    backgroundColor: "#848484",
  },
  liquidmargin: {
    backgroundColor: "#0494fc",
  },
  liquidperpetual: {
    backgroundColor: "#0494fc",
  },
  lit: {
    backgroundColor: "#e0d087",
  },
  "litebit.eu": {
    backgroundColor: "#044ccc",
  },
  litentry: {
    backgroundColor: "#14ed85",
  },
  litevault: {
    backgroundColor: "#343434",
  },
  litmus: {
    backgroundColor: "#19191f",
  },
  livecoin: {
    backgroundColor: "#8cdcfc",
  },
  lkr: {
    backgroundColor: "#20201c",
  },
  lobstr: {
    backgroundColor: "#fad409",
  },
  localbitcoins: {
    backgroundColor: "#f47b25",
  },
  localtrade: {
    backgroundColor: "#c6d7c9",
  },
  looksrare: {
    backgroundColor: "#04cc5c",
  },
  loopring: {
    backgroundColor: "#1c64fc",
  },
  loopringexchange: {
    backgroundColor: "#1b5dfb",
  },
  lros: {
    backgroundColor: "#3d85e9",
  },
  ltc: {
    backgroundColor: "#b0acac",
  },
  ltn: {
    backgroundColor: "#faecd3",
  },
  lto_network: {
    backgroundColor: "#7548bc",
  },
  lukkiexchange: {
    backgroundColor: "#19232a",
  },
  lukso: {
    backgroundColor: "#fcf4fc",
  },
  "lumi-wallet": {
    backgroundColor: "#2e658e",
  },
  luno: {
    backgroundColor: "#066cc5",
  },
  lux: {
    backgroundColor: "#3cc3db",
  },
  lykke: {
    backgroundColor: "#2c2c44",
  },
  lykkeexchange: {
    backgroundColor: "#df0877",
  },
  lynx: {
    backgroundColor: "#8cc43c",
  },
  "magnum-wallet": {
    backgroundColor: "#142133",
  },
  maiar: {
    backgroundColor: "#ccced4",
  },
  mainnetz: {
    backgroundColor: "#dec9a2",
  },
  maker: {
    backgroundColor: "#5bb8a9",
  },
  mangatax: {
    backgroundColor: "#0a1015",
  },
  manna: {
    backgroundColor: "#d4ac04",
  },
  manta: {
    backgroundColor: "#12a8e7",
  },
  "manta-pacific": {
    backgroundColor: "#c572cc",
  },
  mantle: {
    backgroundColor: "#060606",
  },
  marks: {
    backgroundColor: "#3a0b69",
  },
  "math-wallet": {
    backgroundColor: "#242b31",
  },
  matrix_ai_network: {
    backgroundColor: "#094ef1",
  },
  mbaex: {
    backgroundColor: "#b62325",
  },
  mec: {
    backgroundColor: "#c13434",
  },
  "meet-one_wallet": {
    backgroundColor: "#310c31",
  },
  meld: {
    backgroundColor: "#df1546",
  },
  meow: {
    backgroundColor: "#050505",
  },
  mercado: {
    backgroundColor: "#f48424",
  },
  mercadobitcoin: {
    backgroundColor: "#465c6b",
  },
  mercatox: {
    backgroundColor: "#2cbc04",
  },
  metal_pay: {
    backgroundColor: "#8119be",
  },
  metalx: {
    backgroundColor: "#c6388e",
  },
  metis: {
    backgroundColor: "#04dbc3",
  },
  micro_lumens: {
    backgroundColor: "#cb6390",
  },
  "midas-wallet": {
    backgroundColor: "#fbd34b",
  },
  midaseco: {
    backgroundColor: "#373531",
  },
  minerva_wallet: {
    backgroundColor: "#e8e7f5",
  },
  mintlayer: {
    backgroundColor: "#14947c",
  },
  mnc: {
    backgroundColor: "#1f2122",
  },
  mobi: {
    backgroundColor: "#e4e7ea",
  },
  monolith: {
    backgroundColor: "#341ded",
  },
  moon: {
    backgroundColor: "#049cfc",
  },
  moonbeam: {
    backgroundColor: "#54cccb",
  },
  moonlet: {
    backgroundColor: "#04d4f4",
  },
  moonriver: {
    backgroundColor: "#0c142d",
  },
  mrc: {
    backgroundColor: "#f4e824",
  },
  msigna: {
    backgroundColor: "#dce5ec",
  },
  mue: {
    backgroundColor: "#fb9314",
  },
  muu: {
    backgroundColor: "#e5bec8",
  },
  muun: {
    backgroundColor: "#3c74dc",
  },
  mxc: {
    backgroundColor: "#1a6fe0",
  },
  "mycelium-wallet": {
    backgroundColor: "#050506",
  },
  myconstant: {
    backgroundColor: "#383280",
  },
  mycrypto: {
    backgroundColor: "#047c94",
  },
  myetherwallet: {
    backgroundColor: "#c6e5e9",
  },
  myria: {
    backgroundColor: "#040c14",
  },
  mytonwallet: {
    backgroundColor: "#dae4ee",
  },
  nagax: {
    backgroundColor: "#ec1c2c",
  },
  namebase: {
    backgroundColor: "#fcb404",
  },
  nami: {
    backgroundColor: "#d47411",
  },
  nav: {
    backgroundColor: "#8c58c5",
  },
  naxion: {
    backgroundColor: "#949494",
  },
  ndax: {
    backgroundColor: "#132147",
  },
  near: {
    backgroundColor: "#d3d3d3",
  },
  neo: {
    backgroundColor: "#e1f3d2",
  },
  neon: {
    backgroundColor: "#1c4cf4",
  },
  neonwallet: {
    backgroundColor: "#212934",
  },
  netcoins: {
    backgroundColor: "#4465b3",
  },
  netko: {
    backgroundColor: "#ccdc07",
  },
  neurai: {
    backgroundColor: "#d1ccd8",
  },
  neva: {
    backgroundColor: "#c4e1c6",
  },
  newton: {
    backgroundColor: "#141c2c",
  },
  nexa: {
    backgroundColor: "#fae95d",
  },
  nexo: {
    backgroundColor: "#4cb2ed",
  },
  nexo_wallet: {
    backgroundColor: "#4bb1ec",
  },
  nftbank: {
    backgroundColor: "#303bfa",
  },
  ngrave_graphene: {
    backgroundColor: "#878869",
  },
  ngrave_zero: {
    backgroundColor: "#878869",
  },
  nicehash: {
    backgroundColor: "#fbfbf2",
  },
  nimiq: {
    backgroundColor: "#eca617",
  },
  nix: {
    backgroundColor: "#3014a8",
  },
  nmc: {
    backgroundColor: "#d1dce9",
  },
  nobl: {
    backgroundColor: "#e4b005",
  },
  nocks: {
    backgroundColor: "#054a62",
  },
  nodle: {
    backgroundColor: "#1f1f1f",
  },
  nominex: {
    backgroundColor: "#049cfc",
  },
  nor: {
    backgroundColor: "#390623",
  },
  nordek: {
    backgroundColor: "#746cb4",
  },
  nova_wallet: {
    backgroundColor: "#5581d1",
  },
  novadax: {
    backgroundColor: "#1d5657",
  },
  now_wallet: {
    backgroundColor: "#292c3c",
  },
  numio: {
    backgroundColor: "#54d772",
  },
  nxtwallet: {
    backgroundColor: "#048cbb",
  },
  nyc: {
    backgroundColor: "#184fb3",
  },
  nym: {
    backgroundColor: "#f4f4f4",
  },
  oasisdex: {
    backgroundColor: "#3c53b3",
  },
  obvious: {
    backgroundColor: "#71b1a7",
  },
  oceanex: {
    backgroundColor: "#64c4ee",
  },
  ocp: {
    backgroundColor: "#1ca4a6",
  },
  octaspace: {
    backgroundColor: "#42045a",
  },
  ok: {
    backgroundColor: "#cbced2",
  },
  okcoin: {
    backgroundColor: "#096bf4",
  },
  okex: {
    backgroundColor: "#040404",
  },
  okexkorea: {
    backgroundColor: "#040404",
  },
  omgfin: {
    backgroundColor: "#0a7dea",
  },
  omni: {
    backgroundColor: "#180c18",
  },
  omniwallet: {
    backgroundColor: "#d9dcde",
  },
  onchain_custodian: {
    backgroundColor: "#a8c4db",
  },
  onekey: {
    backgroundColor: "#c6eccc",
  },
  onion: {
    backgroundColor: "#dae4f3",
  },
  ooobtc: {
    backgroundColor: "#317aca",
  },
  openfabric_ai: {
    backgroundColor: "#dfd2f7",
  },
  openmask: {
    backgroundColor: "#a9e0fb",
  },
  opensea: {
    backgroundColor: "#2383e3",
  },
  opera_touch: {
    backgroundColor: "#fae0e3",
  },
  optimism: {
    backgroundColor: "#fc0424",
  },
  origintrail: {
    backgroundColor: "#fc5cec",
  },
  osmosis: {
    backgroundColor: "#b870cf",
  },
  otcbtc: {
    backgroundColor: "#23dc7b",
  },
  ovex: {
    backgroundColor: "#242e4b",
  },
  ovis: {
    backgroundColor: "#2c2c2c",
  },
  ownr_wallet: {
    backgroundColor: "#5b6df5",
  },
  ozc: {
    backgroundColor: "#f9df31",
  },
  p2pb2b: {
    backgroundColor: "#3a9b4b",
  },
  pak: {
    backgroundColor: "#3f7939",
  },
  pancakeswap: {
    backgroundColor: "#d38b4b",
  },
  pandoras_wallet: {
    backgroundColor: "#eaeaeb",
  },
  paradex: {
    backgroundColor: "#0cdcb4",
  },
  parallel: {
    backgroundColor: "#357cf8",
  },
  "parallel-heiko": {
    backgroundColor: "#357cf8",
  },
  paribu: {
    backgroundColor: "#7b9728",
  },
  paritex: {
    backgroundColor: "#65d9a8",
  },
  parity: {
    backgroundColor: "#1e1e1e",
  },
  part: {
    backgroundColor: "#44d090",
  },
  paxful: {
    backgroundColor: "#374a5b",
  },
  paybito: {
    backgroundColor: "#2b8c81",
  },
  paymium: {
    backgroundColor: "#205f7a",
  },
  paytrie: {
    backgroundColor: "#8999e2",
  },
  pcn: {
    backgroundColor: "#4e266e",
  },
  pendulum: {
    backgroundColor: "#33243c",
  },
  petal: {
    backgroundColor: "#278e61",
  },
  phala: {
    backgroundColor: "#050605",
  },
  phantasma: {
    backgroundColor: "#14b3eb",
  },
  phantom: {
    backgroundColor: "#ebe7f6",
  },
  phemex: {
    backgroundColor: "#14dcfc",
  },
  pho: {
    backgroundColor: "#5cbeec",
  },
  phr: {
    backgroundColor: "#04d484",
  },
  picasso: {
    backgroundColor: "#140c04",
  },
  piggy: {
    backgroundColor: "#f98f92",
  },
  pink: {
    backgroundColor: "#f38bb3",
  },
  pionex: {
    backgroundColor: "#fbe0d0",
  },
  pivx: {
    backgroundColor: "#d2d0d8",
  },
  plena_finance: {
    backgroundColor: "#dfdfd9",
  },
  pnd: {
    backgroundColor: "#d2d1d1",
  },
  "pnd-old": {
    backgroundColor: "#d2d1d1",
  },
  pny: {
    backgroundColor: "#8c1cac",
  },
  "pny-old": {
    backgroundColor: "#8c1cac",
  },
  polkadot: {
    backgroundColor: "#1d1d1d",
  },
  "polkadot-astar": {
    backgroundColor: "#1579df",
  },
  "polkadot-clover": {
    backgroundColor: "#24a474",
  },
  polkadotjs: {
    backgroundColor: "#fb8c04",
  },
  polkagate: {
    backgroundColor: "#060405",
  },
  polonidex: {
    backgroundColor: "#3b3c3c",
  },
  poloniex: {
    backgroundColor: "#e6e7e9",
  },
  poltergeist_wallet: {
    backgroundColor: "#d6f0f6",
  },
  polygon: {
    backgroundColor: "#8444e4",
  },
  "polygon-pos-quick": {
    backgroundColor: "#1d285e",
  },
  "polygon-zkevm": {
    backgroundColor: "#7b3de4",
  },
  polyx: {
    backgroundColor: "#3a9674",
  },
  pooltool: {
    backgroundColor: "#5c84a4",
  },
  portis: {
    backgroundColor: "#dce4e8",
  },
  pot: {
    backgroundColor: "#175e2f",
  },
  ppc: {
    backgroundColor: "#ccead2",
  },
  "ppc-test": {
    backgroundColor: "#0d260c",
  },
  prizmbit: {
    backgroundColor: "#4534d5",
  },
  probit: {
    backgroundColor: "#e6e3f4",
  },
  probitexchange: {
    backgroundColor: "#4434cb",
  },
  prokey_optimum: {
    backgroundColor: "#e2f4f8",
  },
  ptc: {
    backgroundColor: "#eb8725",
  },
  pulsechain: {
    backgroundColor: "#ad13e6",
  },
  put: {
    backgroundColor: "#bbbabd",
  },
  pwrb: {
    backgroundColor: "#e63fe9",
  },
  pyrk: {
    backgroundColor: "#1461c8",
  },
  qanplatform: {
    backgroundColor: "#04aded",
  },
  qrk: {
    backgroundColor: "#403669",
  },
  qtrade: {
    backgroundColor: "#f9742b",
  },
  quantfury: {
    backgroundColor: "#64cbc3",
  },
  quartz: {
    backgroundColor: "#fc4c6c",
  },
  qubic: {
    backgroundColor: "#040404",
  },
  quickswap: {
    backgroundColor: "#1b235b",
  },
  "rabby-wallet": {
    backgroundColor: "#8b9bfb",
  },
  radarrelay: {
    backgroundColor: "#282828",
  },
  radiant: {
    backgroundColor: "#d6dfc5",
  },
  "rainbow-wallet": {
    backgroundColor: "#0d317a",
  },
  rekeningkucom: {
    backgroundColor: "#2f7ecf",
  },
  relaypay: {
    backgroundColor: "#1444eb",
  },
  revolut: {
    backgroundColor: "#050505",
  },
  revuto: {
    backgroundColor: "#def7fa",
  },
  rfinex: {
    backgroundColor: "#047dfb",
  },
  ric: {
    backgroundColor: "#a8dcb1",
  },
  rightbtc: {
    backgroundColor: "#0c2434",
  },
  ripple: {
    backgroundColor: "#c5c5c5",
  },
  ripplechina: {
    backgroundColor: "#6be310",
  },
  ripplefox: {
    backgroundColor: "#fc6f12",
  },
  rivoxyz: {
    backgroundColor: "#fc8214",
  },
  robinhood: {
    backgroundColor: "#26cb9a",
  },
  robonomics: {
    backgroundColor: "#d0e6f9",
  },
  roger: {
    backgroundColor: "#0b0705",
  },
  ronin: {
    backgroundColor: "#1373ec",
  },
  rudex: {
    backgroundColor: "#1f5877",
  },
  ruufpay: {
    backgroundColor: "#5536d2",
  },
  "safepal-wallet": {
    backgroundColor: "#241c14",
  },
  saito: {
    backgroundColor: "#f41c3c",
  },
  "samourai-wallet": {
    backgroundColor: "#c32424",
  },
  satangpro: {
    backgroundColor: "#1b83fc",
  },
  satoexchange: {
    backgroundColor: "#1575ce",
  },
  satoshivm: {
    backgroundColor: "#fcbc04",
  },
  saymatik: {
    backgroundColor: "#04153d",
  },
  scifi: {
    backgroundColor: "#14c76d",
  },
  scol: {
    backgroundColor: "#ba8b3a",
  },
  scribe: {
    backgroundColor: "#0f0f0f",
  },
  script_network: {
    backgroundColor: "#efe719",
  },
  scroll: {
    backgroundColor: "#f3e0c8",
  },
  secret: {
    backgroundColor: "#1d1d1d",
  },
  secretOriginal: {
    backgroundColor: "#1c1c1c",
  },
  secux_wallet: {
    backgroundColor: "#04040c",
  },
  sentinel: {
    backgroundColor: "#10a6ee",
  },
  serenity: {
    backgroundColor: "#90a8f4",
  },
  sfd: {
    backgroundColor: "#56575d",
  },
  shadow: {
    backgroundColor: "#fc8c14",
  },
  shakepay: {
    backgroundColor: "#04a3fc",
  },
  sharkrelay: {
    backgroundColor: "#92cac3",
  },
  shibuya: {
    backgroundColor: "#157ce0",
  },
  shiden: {
    backgroundColor: "#6c41bb",
  },
  shortex: {
    backgroundColor: "#26dee5",
  },
  simex: {
    backgroundColor: "#048ccc",
  },
  singularx: {
    backgroundColor: "#1e3e5a",
  },
  sistemkoin: {
    backgroundColor: "#1c4b9b",
  },
  skale: {
    backgroundColor: "#dddddd",
  },
  slg: {
    backgroundColor: "#5a6371",
  },
  slm: {
    backgroundColor: "#fbcb05",
  },
  slr: {
    backgroundColor: "#f8e5c6",
  },
  sls: {
    backgroundColor: "#48494a",
  },
  smly: {
    backgroundColor: "#65b3d6",
  },
  solana: {
    backgroundColor: "#4d87c3",
  },
  "solana-v2": {
    backgroundColor: "#4d87c3",
  },
  solflare: {
    backgroundColor: "#fc6724",
  },
  sollet: {
    backgroundColor: "#2394f3",
  },
  songbird: {
    backgroundColor: "#dedde1",
  },
  sora: {
    backgroundColor: "#e4252d",
  },
  southxchange: {
    backgroundColor: "#e4e4e4",
  },
  spd: {
    backgroundColor: "#ec1c24",
  },
  spectrocoin_wallet: {
    backgroundColor: "#042c74",
  },
  spend: {
    backgroundColor: "#f9f9fa",
  },
  spiritswap: {
    backgroundColor: "#4ea686",
  },
  spk: {
    backgroundColor: "#a11313",
  },
  spookyswap: {
    backgroundColor: "#1a1924",
  },
  sprts: {
    backgroundColor: "#9dc355",
  },
  sqr: {
    backgroundColor: "#0483fa",
  },
  stacks: {
    backgroundColor: "#5343fc",
  },
  staketax: {
    backgroundColor: "#c7779f",
  },
  stargatefinance: {
    backgroundColor: "#b8b8b8",
  },
  stargaze: {
    backgroundColor: "#070708",
  },
  starknet: {
    backgroundColor: "#2c2c6c",
  },
  statemine: {
    backgroundColor: "#040404",
  },
  statemint: {
    backgroundColor: "#050505",
  },
  stellar: {
    backgroundColor: "#040404",
  },
  stellarport: {
    backgroundColor: "#07aded",
  },
  stellarterm: {
    backgroundColor: "#34d674",
  },
  stex: {
    backgroundColor: "#c8c8c8",
  },
  stk: {
    backgroundColor: "#cea75a",
  },
  stormgain: {
    backgroundColor: "#04dc44",
  },
  stormrake: {
    backgroundColor: "#0c94b4",
  },
  strat: {
    backgroundColor: "#1c84c4",
  },
  strax: {
    backgroundColor: "#1666b6",
  },
  "strax-test": {
    backgroundColor: "#1666b6",
  },
  stronghold: {
    backgroundColor: "#4084c3",
  },
  sublimeip: {
    backgroundColor: "#c6db50",
  },
  subsocialx: {
    backgroundColor: "#b0048d",
  },
  subwallet: {
    backgroundColor: "#0c0434",
  },
  subzero: {
    backgroundColor: "#040404",
  },
  super: {
    backgroundColor: "#a53838",
  },
  sushiswap: {
    backgroundColor: "#cf2a40",
  },
  swing: {
    backgroundColor: "#040404",
  },
  swissborg: {
    backgroundColor: "#14ac8c",
  },
  switcheo: {
    backgroundColor: "#0d4454",
  },
  switcheonetwork: {
    backgroundColor: "#0e4250",
  },
  swyftx: {
    backgroundColor: "#050505",
  },
  sxc: {
    backgroundColor: "#b5b4b4",
  },
  sygnum_bank: {
    backgroundColor: "#fb506d",
  },
  sylo: {
    backgroundColor: "#1a1146",
  },
  synapse: {
    backgroundColor: "#c564fb",
  },
  sys: {
    backgroundColor: "#0488ca",
  },
  t3rn: {
    backgroundColor: "#43396a",
  },
  tagz: {
    backgroundColor: "#6453bb",
  },
  taho: {
    backgroundColor: "#cd893a",
  },
  taj: {
    backgroundColor: "#54aa9d",
  },
  talisman: {
    backgroundColor: "#fc4c4b",
  },
  "talk-old": {
    backgroundColor: "#9d9f9f",
  },
  taraxa: {
    backgroundColor: "#0c1414",
  },
  tectum: {
    backgroundColor: "#4c7cfc",
  },
  tenx: {
    backgroundColor: "#342d2d",
  },
  terra: {
    backgroundColor: "#172654",
  },
  terra2: {
    backgroundColor: "#e14938",
  },
  tes: {
    backgroundColor: "#fba51e",
  },
  tezos: {
    backgroundColor: "#0c64fc",
  },
  the_root_network: {
    backgroundColor: "#343434",
  },
  therock: {
    backgroundColor: "#067e88",
  },
  theta: {
    backgroundColor: "#2cbbe7",
  },
  thetaboard: {
    backgroundColor: "#f7a508",
  },
  thetawallet: {
    backgroundColor: "#1fbfd6",
  },
  thodex: {
    backgroundColor: "#046cfc",
  },
  thorchain: {
    backgroundColor: "#1de9c5",
  },
  thorchainOriginal: {
    backgroundColor: "#18e4cf",
  },
  thought: {
    backgroundColor: "#0c386b",
  },
  tidebit: {
    backgroundColor: "#4da2d2",
  },
  tidex: {
    backgroundColor: "#058362",
  },
  timex: {
    backgroundColor: "#fbb34c",
  },
  tinkernet: {
    backgroundColor: "#141414",
  },
  toa: {
    backgroundColor: "#265bc6",
  },
  toast: {
    backgroundColor: "#edf6f8",
  },
  tokel_platform: {
    backgroundColor: "#cacacc",
  },
  tokenion: {
    backgroundColor: "#272845",
  },
  tokenjar: {
    backgroundColor: "#f78a61",
  },
  tokenomy: {
    backgroundColor: "#0484bc",
  },
  "tokenpocket-wallet": {
    backgroundColor: "#247ef1",
  },
  "tokens.net": {
    backgroundColor: "#181f31",
  },
  tokenstore: {
    backgroundColor: "#644cea",
  },
  tokocrypto: {
    backgroundColor: "#1e8ba1",
  },
  tokok: {
    backgroundColor: "#f16d33",
  },
  toncoin: {
    backgroundColor: "#048bcc",
  },
  tonhub: {
    backgroundColor: "#704afc",
  },
  tonpay: {
    backgroundColor: "#050709",
  },
  tonwallet: {
    backgroundColor: "#1ca0fa",
  },
  topbtc: {
    backgroundColor: "#e57b17",
  },
  torus: {
    backgroundColor: "#b4d0fc",
  },
  "trade.io": {
    backgroundColor: "#059ddd",
  },
  tradebytrade: {
    backgroundColor: "#8f881a",
  },
  tradeogre: {
    backgroundColor: "#bebebc",
  },
  "trader-joe": {
    backgroundColor: "#c76b64",
  },
  tradesatoshi: {
    backgroundColor: "#07bdfc",
  },
  trc: {
    backgroundColor: "#346a4c",
  },
  trex: {
    backgroundColor: "#c2c1c2",
  },
  trezor: {
    backgroundColor: "#bcbcbc",
  },
  trezor_wallet: {
    backgroundColor: "#040404",
  },
  troll: {
    backgroundColor: "#c80504",
  },
  tron: {
    backgroundColor: "#fc0414",
  },
  tronlink: {
    backgroundColor: "#2c5cc4",
  },
  trump: {
    backgroundColor: "#ced7de",
  },
  trust: {
    backgroundColor: "#d5cba7",
  },
  "trust-wallet": {
    backgroundColor: "#3675b7",
  },
  trustwallet: {
    backgroundColor: "#3c76be",
  },
  ttn: {
    backgroundColor: "#d5be42",
  },
  turing: {
    backgroundColor: "#7f57c4",
  },
  txbit: {
    backgroundColor: "#3d7de3",
  },
  typhon: {
    backgroundColor: "#375aa6",
  },
  tzc: {
    backgroundColor: "#040404",
  },
  uberpay: {
    backgroundColor: "#f1f0ec",
  },
  ufo: {
    backgroundColor: "#047ccc",
  },
  ultimate: {
    backgroundColor: "#fb0470",
  },
  umo: {
    backgroundColor: "#97adc4",
  },
  unique: {
    backgroundColor: "#e4f5fc",
  },
  uniswap: {
    backgroundColor: "#f7dce5",
  },
  uno: {
    backgroundColor: "#333334",
  },
  unstoppable_wallet: {
    backgroundColor: "#1c242b",
  },
  upbit: {
    backgroundColor: "#0c3484",
  },
  upex: {
    backgroundColor: "#0c2343",
  },
  uphold: {
    backgroundColor: "#44cc6c",
  },
  utip: {
    backgroundColor: "#2548a7",
  },
  val: {
    backgroundColor: "#1dc272",
  },
  valr: {
    backgroundColor: "#4c6cec",
  },
  vauld: {
    backgroundColor: "#1c4b8c",
  },
  vcc: {
    backgroundColor: "#1b8a8f",
  },
  vccexchange: {
    backgroundColor: "#1985be",
  },
  vcn: {
    backgroundColor: "#bdccb5",
  },
  vebitcoin: {
    backgroundColor: "#352c43",
  },
  vechain: {
    backgroundColor: "#8a86da",
  },
  velas: {
    backgroundColor: "#04fcb4",
  },
  velic: {
    backgroundColor: "#04bbcc",
  },
  vgs: {
    backgroundColor: "#46443a",
  },
  via: {
    backgroundColor: "#2a2324",
  },
  vidulum: {
    backgroundColor: "#edf2f6",
  },
  vindax: {
    backgroundColor: "#3554db",
  },
  vinexnetwork: {
    backgroundColor: "#fbd858",
  },
  virtuseexchange: {
    backgroundColor: "#6083bf",
  },
  vision_wallet: {
    backgroundColor: "#9899a0",
  },
  vitex: {
    backgroundColor: "#0c71ee",
  },
  vls: {
    backgroundColor: "#2f2f2e",
  },
  voyager: {
    backgroundColor: "#4b4ad6",
  },
  vrc: {
    backgroundColor: "#448bca",
  },
  vrm: {
    backgroundColor: "#448ccb",
  },
  vtc: {
    backgroundColor: "#048153",
  },
  walletconnect: {
    backgroundColor: "#67485c",
  },
  walleth: {
    backgroundColor: "#c6b7a4",
  },
  wallmer: {
    backgroundColor: "#6a3c97",
  },
  wasabi_wallet: {
    backgroundColor: "#052548",
  },
  wavesexchange: {
    backgroundColor: "#e44c54",
  },
  wax: {
    backgroundColor: "#fc9324",
  },
  waxOriginal: {
    backgroundColor: "#fb9424",
  },
  wazirx: {
    backgroundColor: "#3464f4",
  },
  wbfexchange: {
    backgroundColor: "#1a786f",
  },
  wdc: {
    backgroundColor: "#d5e2f1",
  },
  wealth99: {
    backgroundColor: "#040404",
  },
  wex: {
    backgroundColor: "#f4d99c",
  },
  wgr: {
    backgroundColor: "#c40707",
  },
  whitebit: {
    backgroundColor: "#242424",
  },
  wigwam: {
    backgroundColor: "#83ea6b",
  },
  "wirex-wallet": {
    backgroundColor: "#cbfbc3",
  },
  witnet: {
    backgroundColor: "#dcd9ed",
  },
  x0z: {
    backgroundColor: "#fb7b04",
  },
  "xapo-wallet": {
    backgroundColor: "#ec5f18",
  },
  xbc: {
    backgroundColor: "#fcdfbf",
  },
  xc: {
    backgroundColor: "#1c1c1c",
  },
  xcalibra: {
    backgroundColor: "#49d7fb",
  },
  xcz: {
    backgroundColor: "#2b2523",
  },
  xdai: {
    backgroundColor: "#077c5c",
  },
  xdaiomnibridge: {
    backgroundColor: "#5cb1e6",
  },
  xdc_network: {
    backgroundColor: "#244c84",
  },
  xdefi: {
    backgroundColor: "#365de4",
  },
  xena: {
    backgroundColor: "#33c963",
  },
  xenaexchange: {
    backgroundColor: "#35cc65",
  },
  xfutures: {
    backgroundColor: "#40aaf0",
  },
  xjo: {
    backgroundColor: "#dae9e9",
  },
  xmg: {
    backgroundColor: "#b6b7b7",
  },
  xmy: {
    backgroundColor: "#edc033",
  },
  xp: {
    backgroundColor: "#69bc67",
  },
  xpm: {
    backgroundColor: "#f8d51a",
  },
  xst: {
    backgroundColor: "#060606",
  },
  xvp: {
    backgroundColor: "#079a07",
  },
  xwallet: {
    backgroundColor: "#e87d7a",
  },
  yobit: {
    backgroundColor: "#2b5c74",
  },
  yoroi: {
    backgroundColor: "#2c50d3",
  },
  youhodler: {
    backgroundColor: "#41b9fa",
  },
  yunex: {
    backgroundColor: "#141414",
  },
  zaif: {
    backgroundColor: "#045cac",
  },
  zano: {
    backgroundColor: "#f5fafa",
  },
  zbcom: {
    backgroundColor: "#e4241c",
  },
  zbg: {
    backgroundColor: "#9977e7",
  },
  zec: {
    backgroundColor: "#ecb446",
  },
  zeit: {
    backgroundColor: "#303334",
  },
  zeitgeist: {
    backgroundColor: "#050505",
  },
  "zelcore-wallet": {
    backgroundColor: "#1c3c84",
  },
  zenapay: {
    backgroundColor: "#ecfaea",
  },
  zengo: {
    backgroundColor: "#49dac3",
  },
  zenon: {
    backgroundColor: "#6cf34c",
  },
  zeply: {
    backgroundColor: "#683490",
  },
  zerion: {
    backgroundColor: "#2162ec",
  },
  zeroexprotocol: {
    backgroundColor: "#241c14",
  },
  zet: {
    backgroundColor: "#2c7e93",
  },
  zetachain: {
    backgroundColor: "#d0dfdc",
  },
  zgcom: {
    backgroundColor: "#fc541c",
  },
  zkbase: {
    backgroundColor: "#24acbc",
  },
  zksync: {
    backgroundColor: "#6a6ec9",
  },
  zln: {
    backgroundColor: "#e2ab37",
  },
  znz: {
    backgroundColor: "#8683dd",
  },
  zora: {
    backgroundColor: "#251c22",
  },
  zumminer: {
    backgroundColor: "#f0f8fa",
  },
  zumo: {
    backgroundColor: "#20252d",
  },
  zumo_wallet: {
    backgroundColor: "#fc14b4",
  },
};
