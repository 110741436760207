import { InfoOutlined } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import { type TimeRemaining } from "~/components/dashboard/embedded-dashboard/types";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";

/**
 * Calculates the time remaining until the US tax deadline (April 15th)
 * @returns {TimeRemaining} Object containing days, hours, and minutes remaining
 */
export function calculateUsDeadlineTimeRemaining(
  timezone: string,
): TimeRemaining {
  const now = moment();
  const currentYear = now.year();

  // Last second of april 15th
  let taxDeadline = moment()
    .tz(timezone)
    .year(currentYear)
    .month(3)
    .date(15)
    .hour(23)
    .minute(59)
    .second(59);

  // If we're past April 15th, use next year's deadline
  if (now.isAfter(taxDeadline)) {
    taxDeadline = taxDeadline.add(1, "year");
  }

  const duration = moment.duration(taxDeadline.diff(now));

  return {
    duration,
    days: Math.floor(duration.asDays()),
    hours: duration.hours(),
    minutes: duration.minutes(),
  };
}

/**
 * Formats the time remaining into a human readable string with 2 digits for each field
 * @param timeRemaining - The time remaining object
 * @returns {string} Formatted time remaining string
 */
function formatTimeRemaining(timeRemaining: TimeRemaining): string {
  const { duration, days, hours, minutes } = timeRemaining;

  // Pad numbers with leading zeros to ensure 2 digits
  const paddedDays = days.toString().padStart(2, "0");
  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");

  return `${paddedDays}d : ${paddedHours}h : ${paddedMinutes}m`;
}

/**
 * CountdownBanner component displaying time remaining until tax deadline
 */
export function CountdownBanner({ ctaButton }: { ctaButton: React.ReactNode }) {
  const lang = useLang();
  const { tokens } = useDesign();
  const timezone = useTimezone();

  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(
    calculateUsDeadlineTimeRemaining(timezone),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateUsDeadlineTimeRemaining(timezone));
    }, 60000); // Update every minute

    return () => {
      clearInterval(timer);
    };
  }, [timezone]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      p="1rem"
      bgcolor={tokens.background.brand.default}
      border={`1px solid ${tokens.border.brand}`}
      borderRadius="0.5rem"
      flexWrap="wrap"
      gap="1rem"
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <Typography
            variant="Metropolis/Caption/Large/Regular"
            color={tokens.text.default}
          >
            {lang.dashboard.embedded.countdownBanner.title}
          </Typography>
          <Tooltip title={lang.dashboard.embedded.countdownBanner.tooltip}>
            <InfoOutlined sx={{ fontSize: "1rem", color: tokens.icon.low }} />
          </Tooltip>
        </Box>
        <Typography variant="Metropolis/Header/H1" color={tokens.text.brand}>
          {formatTimeRemaining(timeRemaining)}
        </Typography>
      </Box>

      <Box>{ctaButton}</Box>
    </Box>
  );
}
