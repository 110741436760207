export enum Links {
  Signup = "/signup/",
  SignupAccountant = "/signup-accountant/",
  Login = "/login/",
  TokenLogin = "/token-login/",
  Invite = "/invite/:code",
  Dashboard = "/dashboard",
  Imports = "/accounts",
  ImportNew = `/accounts/new`,
  ImportSearch = `/accounts/search`,
  ImportCustom = `/accounts/custom`,
  Transactions = "/transactions",
  BalanceLedger = "/ledger/:currencyId",
  Reconciliation = "/reconciliation",
  ReconciliationUncategorisedTransaction = `/reconciliation/uncategorised-transactions`,
  ReconciliationNegativeBalanceIssues = `/reconciliation/negative-balance`,
  ReconciliationMissingPriceIssues = `/reconciliation/missing-price-issues`,
  ReconciliationMissingBlockchain = `/reconciliation/missing-blockchain`,
  ReconciliationUnmatchedTransfer = `/reconciliation/unmatched-transfer`,
  TransactionsUncategorised = "/transactions?warning=uncategorised",
  TransactionsNegativeBalance = "/transactions?warning=negativeBalance",
  TransactionsMissingPrice = "/transactions?warning=missingPrice",
  TransactionsSearch = "/transactions/:searchText",
  TransactionsAdd = "/transactions/add",
  Wallets = "/wallets",
  Report = "/report",
  Reset = "/reset",
  Forgot = "/forgot",
  Billing = "/billing",
  Plans = "/plans",
  Payment = "/subscribe",
  Clients = "/clients",
  ClientsAdd = "/clients?addClientModal=1",
  Unlock = "/unlock",
  PaymentStatus = "/payment",
  PaymentPending = "/payment?status=pending",
  PaymentSuccess = "/payment?status=success",
  Developer = "/developer",
  DeveloperSeedData = "/developer/seedData",
  DeveloperEditorIntegration = "/developer/editor-integration",
  DeveloperEntities = "/developer/entities",
  DeveloperReport = "/developer/report",
  DeveloperSessionRecording = "/developer/session-recording",
  DeveloperUserData = "/developer/user-data",
  Alpha = "/alpha",
  PartnerBenefit = "/partner-benefit",
  OptInSupportAccess = "/opt-in-support-access",
  Ledger = "/ledger",
  Onboarding = "/onboarding",
  OnboardingSelectType = "/onboarding/type",
  OnboardingSelectTheme = "/onboarding/theme",
  OnboardingSelectCountry = "/onboarding/country",
  OnboardingImports = "/onboarding/accounts",
  OnboardingImportsSearch = "/onboarding/accounts/search",
  OnboardingImportsNew = "/onboarding/accounts/new",
  OnboardingWallets = "/onboarding/wallets",
  OnboardingImportsCustom = "/onboarding/accounts/custom",
  OnboardingPlatformTypes = "/onboarding/platform-types",
  OnboardingTradingTypes = "/onboarding/trading-types",
  OnboardingAccuracy = "/onboarding/accuracy",
  OnboardingTaxableTrades = "/onboarding/taxable-trades",
  OnboardingTaxableStaking = "/onboarding/taxable-staking",
  OnboardingImportsOauth = "/onboarding/accounts/oauth",
  OnboardingAreYouSure = "/onboarding/accounts/are-you-sure",
  OnboardingImportCEXReminder = "/onboarding/accounts/centralized-exchange-reminder",
  OnboardingImportsCalculating = "/onboarding/accounts/calculating",
  OnboardingReconUncategorisedTransactions = "/onboarding/reconciliation/uncategorised-transactions",
  OnboardingOnYourWay = "/onboarding/on-your-way",
  OnboardingPayment = "/onboarding/subscribe",
  OnboardingPaymentStatus = "/onboarding/payment",
  OnboardingPaymentSuccess = "/onboarding/payment?status=success",
  ContactsIdentified = "/contacts/identified",
  ContactsUnidentified = "/contacts/unidentified",
  Contacts = "/contacts",
  Rules = "/rules",
  AccountingIntegrations = "/accounting-integrations",
  ReOnboarding = "/re-onboarding",
  ReOnboardingWelcomeBack = "/re-onboarding/welcome-back",
  ReOnboardingAccuracy = "/re-onboarding/accuracy",
  ReOnboardingTaxableTrades = "/re-onboarding/taxable-trades",
  ReOnboardingTaxableStaking = "/re-onboarding/taxable-staking",
  ReferAFriend = "/refer",
  RestoreUserData = "/restore-data",
  Close = "/close",
  OnboardingProviderWelcome = "/onboarding/welcome",
  OnboardingProviderSetPassword = "/onboarding/set-password",
  OnboardingProviderPasswordSetSuccess = "/onboarding/password-set-success",
  OnboardingProviderSyncing = "/onboarding/syncing",
  OnboardingEmbededOfferIntro = "/onboarding/offer-intro",
  ConnectProvider = "/connect-provider",
}

export enum PopupTarget {
  Payment = "payment",
}

export enum ImportMethod {
  API = "api",
  CSV = "csv",
  OAuth = "oauth",
  Wallet = "wallet",
  ConnectWallet = "connect-wallet",
  SoftWallet = "soft-wallet",
  BulkWallet = "bulk-wallet",
}

export enum DeprecatedAPIs {
  CoinbasePro = "coinbasepro",
}

// ERP refers to Enterprise Resource Planning software
// its a type of software that helps businesses manage their resources
// we generally use it to refer to our integrations with software
// like Xero, Quickbooks, Netsuite, etc.
// where they require us to map user transactions
// to double entry journals

export enum ERPAccountType {
  Asset = "asset", // an account that represents a resource that the business owns or controls
  PL = "pl", // an account that represents a business's income and expenses
  Gain = "gain", // an account that represents a business's realised gains and losses from trading
  Cash = "cash", // an account that represents a business's cash
  Loan = "loan", // an account that represents a business's loans
  ZeroCostBuy = "zero-cost-buy",
}

export enum Sort {
  DateDescending = "newestFirst",
  DateAscending = "oldestFirst",

  GainDescending = "gainDescending",
  GainAscending = "gainAscending",

  ValueDescending = "valueDescending",
  ValueAscending = "valueAscending",

  FeeDescending = "feeDescending",
  FeeAscending = "feeAscending",

  UncategorisedValueDescending = "uncategorisedValueDescending",
  UncategorisedValueAscending = "uncategorisedValueAscending",

  UncategorisedSizeDescending = "uncategorisedSizeDescending",
  UncategorisedSizeAscending = "uncategorisedSizeAscending",

  LastModifiedAscending = "lastModifiedAscending",
  LastModifiedDescending = "lastModifiedDescending",
}
// end reports

export enum IrsReportDownloadType {
  IRS_8949 = "irs-8949",
  IRS_8949_CONSOLIDATED = "irs-8949-consolidated",
  IRS_1040S1 = "irs-1040-s1",
  IRS_1040SD = "irs-1040-sd",
}
export enum IrsReportType {
  IRS_8949_SHORT = "irs-8949-short",
  IRS_8949_LONG = "irs-8949-long",
  IRS_8949_SHORT_CONSOLIDATED = "irs-8949-short-consolidated",
  IRS_8949_LONG_CONSOLIDATED = "irs-8949-long-consolidated",
  IRS_1040S1 = "irs-1040-s1",
  IRS_1040SD = "irs-1040-sd",
}
export enum TaxActReportType {
  TaxActCsv = "taxact-csv",
}
export enum TurboTaxReportType {
  TurboTaxTxf = "turbotax-txf",
  TurboTaxCsv = "turbotax-csv",
  CanadianTurboTaxPdf = "turbotax-canadian-pdf",
}
export enum OtherReportType {
  // Temporary solution for BGL integration is to download the XML from the report page
  // and upload it to BGL.  Later this will be implemented as a Software Connection.
  BGL = "bgl",
  FilteredTransaction = "transaction-filtered",
  TaxLots = "tax-lots",
}

export enum NormalReportType {
  ReportSummary = "report-summary",
  CapitalGains = "capital-gains",
  Income = "income",
  Expense = "expense",
  Transaction = "transaction",
  Inventory = "inventory",
  TradingStockAu = "trading-stock",
  TradingPnl = "trading-pnl",
  Audit = "audit",
  GiftAndLost = "gift-and-lost",
  MyGov = "mygov",
  NftReport = "nft-report",
  StartOfYearHoldings = "start-of-year-holdings",
}
export enum EnterpriseReportType {
  BillingCsv = "invoices-csv",
  BillingPdf = "invoices-pdf",
}
export enum GermanReportType {
  AnlageSoOtherIncome = "annex-so-other-income",
  WisoSteuer = "wiso-steuer",
}
export enum FrenchReportType {
  Form2086 = "cerfa2086",
}

export enum SpanishReportType {
  Modelo100 = "modelo-100",
}
export enum PortfolioTimeframe {
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
  All = "all",
  YTD = "ytd",
}
export enum InflowOutflowPortfolioTimeframe {
  Month = "month", // the last month - daily slices
  QuarterToDate = "quarterToDate", // Quater to date - weekly slices
  YearToDate = "ytd", // Calendar year to date - weekly slices
  Year = "year",
}
export enum BalanceType {
  Calculated = "calculated",
  Reported = "reported",
}
export enum BalanceError {
  Inconsistent = "inconsistent", // The reported and calculated balances are inconsistent.
  Negative = "negative",
  SyncFail = "syncFail",
}

// the order is important here for how it is displayed when selecting a country
export enum Region {
  NorthAmerica = "North America",
  AsiaPacific = "Asia & Pacific",
  Europe = "Europe",
  Africa = "Africa",
}

export enum PaymentCycle {
  Monthly = "monthly",
  Annual = "annual",
}
export enum PlanGroup {
  Personal = "personal",
  Professional = "professional",
  Business = "business",
}
export enum ImportStepType {
  Step = "step",
  Note = "note",
  Critical = "critical",
  Video = "video",
}
export enum ImportType {
  CSV = "csv",
  API = "api",
  OAuth = "oauth",
  Manual = "manual",
  ManualCSV = "manual-csv",
  Wallet = "wallet", // Pretty much unusued
  WalletCSV = "wallet-csv", // Pretty much unused
  WalletAPI = "wallet-api", // Used for all wallets
  SoftWallet = "soft-wallet", // Used for metamask, trust-wallet,
  SoftTransfer = "soft-transfer", // Soft transfer txs created in RR
  AssumedFee = "assumed-fee",
}
export enum ErrorType {
  FetchFail = "FetchFail",
  NotAuthenticated = "NotAuthenticated",
}

// TODO: Move OAuthProvider and AuthMethodType to the types package.
export enum AuthMethodType {
  Password = "password",
  Oauth = "oauth",
}

export enum GroupedTrade {
  MultiTrade = "multi-trade",
  Transfer = "transfer", // used when we have a grouped send & receive
  FiatTransfer = "fiat-transfer", // used when we have a grouped fiat send & receive
  Trade = "trade", // used when we have a grouped buy & sell
  Swap = "swap",
  Bridge = "bridge-transfer",
  ProvideLiquidityTrade = "provide-liquidity-trade",
  RemoveLiquidityTrade = "remove-liquidity-trade", // having this as RemoveLiquidity would clash with the Trade.RemoveLiquidity
  MultiMint = "multi-mint",
  IncreasePosition = "increase-position",
  DecreasePosition = "decrease-position",
  BridgeTrade = "bridge-trade",
  Uncategorised = "arbitrary-uncategorised", // Arbitrarily shaped group of uncategorised txs at the same time with the same txId
  StakingDepositGroup = "stake-deposit-group",
  StakingWithdrawalGroup = "stake-withdrawal-group",
}
export enum UpdateReason {
  Migration = "migration",
  System = "system",
  User = "user",
  Unknown = "unknown",
  Import = "import",
  Rule = "rule",
  TransactionMatching = "transactionMatching",
}
export enum ManualTransactionType {
  Trade = "trade",
  Transfer = "transfer",
  Other = "other",
}
export enum SyncStatusBadgeVariant {
  Info = "info",
  Success = "success",
  Error = "error",
}
export enum RefreshReason {
  BulkTransaction = "bulkTransaction",
  Entity = "entity",
  Tips = "tips",
  Transaction = "transaction",
  Import = "import",
  TaxSettings = "tax-settings",
  Version = "version",
  Manual = "manual", // due to a manual trigger
  Unknown = "unknown", // fallback when we don't know what trigger the report refresh
  Rules = "rules",
}
export enum ReconciliationIssues {
  UncategorisedTransactions = "uncategorisedTransactions",
  MissingPrices = "missingPrice",
  MissingBlockchain = "missingBlockchain",
  NegativeBalances = "negativeBalance",
  UnknownAddress = "unknownAddress",
  UnmatchedTransfer = "unmatchedTransfer",
  SuspectedMissingImport = "suspectedMissingImport",
}
export enum IssueDirection {
  To = "to",
  From = "from",
}

export enum BTCAltNetworks {
  DFI = "dfi",
}

export enum Theme {
  System = "system",
  Light = "light",
  Dark = "dark",
}
/**
 * Theme values after system preference has been resolved
 */
export type ResolvedTheme = Exclude<Theme, Theme.System>;

export enum BrandStyle {
  MetaMask = "metamask",
  CTC = "ctc",
  Coinbase = "coinbase",
  Kraken = "kraken",
  Binance = "binance",
}

/**
 * Type guard to check if a string is a valid Theme enum value
 * @param value - The string value to check
 * @returns True if the value is a valid Theme enum value
 */
export const isValidTheme = (value: string | null): value is Theme => {
  if (!value) return false;
  return (
    value === Theme.System || value === Theme.Light || value === Theme.Dark
  );
};

/**
 * Type guard to check if a string is a valid BrandStyle enum value
 * @param value - The string value to check
 * @returns True if the value is a valid BrandStyle enum value
 */
export const isValidBrandStyle = (
  value: string | null,
): value is BrandStyle => {
  if (!value) return false;
  return Object.values(BrandStyle).includes(value as BrandStyle);
};

export enum BlockChairCrypto {
  BTC = "BTC", // bitcoin
  BCH = "BCH", // bitcoin cash
  BCHA = "BCHA", // bitcoin cash ABC
  BSV = "BSV", // bitcoin SV
  XRP = "XRP", // ripple
  ADA = "ADA", // cardano
  LTC = "LTC", // litcoin
  EOS = "EOS", // EOS
  XTZ = "XTZ", // Tesos
  XLM = "XLM", // Stellar,
  XRM = "XRM", // Monero
  DASH = "DASH", // Dash
  ZEC = "ZEC", // Zcash
  DOGE = "DOGE", // Dogecoin
  XIN = "XIN", // Mixin
  GRS = "GRS",
}

export enum AssetType {
  Token = "token",
  Derivative = "derivative",
}
export enum EntityType {
  Exchange = "Exchange",
  Contract = "Contract",
  Manual = "Manual",
  Extension = "Extension",
}
export enum PriceSourceType {
  PriceWindow = "PriceWindow",
  PriceFromAction = "PriceFromAction",
  ManualUpdate = "ManualUpdate",
}

export enum BulkOperations {
  Patch = "patch",
  Recategorise = "recategorise",
  MarkAsSpam = "markAsSpam",
  ChangeCurrency = "changeCurrency",
  ChangeFeeCurrency = "changeFeeCurrency",
  AddComment = "addComment",
  Ignore = "ignore",
  Delete = "delete",
  ChangeSourceAccount = "changeSourceAccount",
  ChangeDestinationAccount = "changeDestinationAccount",
  LookUpMarketPrice = "lookUpMarketPrice",
  IgnoreWarnings = "IgnoreWarnings",
  ChangeErpAssetAccount = "changeErpAssetAccount",
  ChangeErpCashAccount = "changeErpCashAccount",
  ChangeErpPnLAccount = "changeErpPnLAccount",
  ChangeErpGainsAccount = "changeErpGainsAccount",
  ChangeErpLoanAccount = "changeErpLoanAccount",
  SyncToErp = "syncToErp",
  ChangePrice = "changePrice",
  TxRewind = "txRewind",
}
export enum BulkUpdateStatus {
  AcceptedForProcessing = "AcceptedForProcessing",
}
export enum FilterOperators {
  ActionId = "actionId", // Search by the action's _id
  ActionTrade = "trade", // Search by the action's trade type
  TxTrade = "category", // Search by all the trade types that make up an action, including the action itself's type (excluding fee) (e.g for a trade, will return on buy, sell and trade)
  Currency = "currency", // Search by all currencies on the action, fee inclusive (DEPRECATED IN THE UI - use txCurrency and feeCurrency)
  ImportType = "importType", // Search by the import types present in the action e.g wallet, api, manual etc
  Source = "source", // Search by the imported accounts and entities relating to the action
  TxHash = "txId", // Search by all transaction hashes present in the action
  Warning = "warning", // Search by warnings present on the action
  TransactionId = "id", // Search by the transaction's making up an action's _id
  From = "from", // Search by the from address
  To = "to", // Search by the to address
  Party = "party", // Search by the party (from or to)
  HasComments = "comments", // Boolean for whether a given action has comments
  TxFunctionName = "txFunctionName", // Search by the action's function names
  TxFunctionSignature = "txFunctionSignature", // Search by the action's function signatures
  Reconciliation = "reconciliation", // Specific filters for reconciliation views
  NegativeBalance = "negativeBalance", // Search by negative balances for a specific currency
  MissingPrice = "missingPrice", // Search by missing prices for a specific currency
  And = "and", // Combine multiple filters with an AND operator
  Or = "or", // Combine multiple filters with an OR operator
  Not = "not", // Negate a filter
  After = "after", // Search by actions after a specific date
  Before = "before", // Search by actions before a specific date
  Date = "date", // Search by actions on a specific date
  ImportId = "importId", // Search by the import ids present in the action
  Reviewed = "reviewed", // Boolean for whether a given action has been reviewed
  Blockchain = "blockchain", // Search by the blockchain
  NFTCollection = "nftCollection", // Search by the NFT collection (contract address for NFT currency without considering the NFT ID)
  Sync = "sync", // Search for the action by the syncIds present in the action
  Description = "description", // Search for text within the descriptions present in the action
  Suggestion = "suggestion", // Boolean for whether a given action has a suggestion star (e.g has been PMT'd, or is an assumed transfer)
  CommentContains = "comment", // Search for text within the comments present in the action
  TaxOutcomeType = "taxOutcomeType", // Search for the tax outcomes present in an action (e.g short term gain, long term gain, income etc)
  FeeCurrency = "feeCurrency", // Search for the currencies present in the fee txs of an action
  TxCurrency = "txCurrency", // Search for the currencies present in the non-fee txs of an action
  ErpAssetAccount = "erpAssetAccount", // Search by the ERP asset account overrides
  ErpCashAccount = "erpCashAccount", // Search by the ERP cash account overrides
  ErpGainsAccount = "erpGainsAccount", // Search by the ERP gains account overrides
  ErpLoanAccount = "erpLoanAccount", // Search by the ERP loan account overrides
  ErpPnlAccount = "erpPnlAccount", // Search by the ERP PNL account overrides
  ErpSyncStatus = "erpSyncStatus", // Search by the ERP sync status
  LockPeriod = "lockPeriod", // Boolean for whether an action exists in a locked period
  IsSmartContractInteraction = "isSmartContractInteraction", // Boolean for whether or not the counter party is a smart contract
  HasRule = "hasRule", // Boolean for whether a given action has a rule
  Rule = "rule", // Search by the rules applied to txs within the action
  RuleOperator = "ruleOperator",
  /**
   * Find all actions that contain a fee tx
   */
  IncludesFee = "includesFee",
  /**
   * Search by the integration used to bring in the txs, including the soft
   * wallet used for wallets. (e.g binance for binance api, eth and metamask for
   * an eth wallet imported via metamask)
   */
  Integration = "integrations",
}
export enum CommentsFilter {
  AllComments = "allComments",
}
export enum TaxOutcomeType {
  ShortTermCapitalGain = "shortTermCapitalGain",
  ShortTermCapitalLoss = "shortTermCapitalLoss",
  LongTermCapitalGain = "longTermCapitalGain",
  LongTermCapitalLoss = "longTermCapitalLoss",
  Income = "income",
}
export enum LockPeriodTxStatus {
  Locked = "LOCKED",
  NotLocked = "NOT_LOCKED",
}
export enum Warning {
  All = "all",
  Uncategorised = "uncategorised",
  NegativeBalance = "negativeBalance",
  MissingPrice = "missingPrice",
  ZeroCostBuy = "zeroCostBuy",
  SuspectedMissingImport = "suspectedMissingImport",
  SuspectedAirdropTransaction = "suspectedAirdropTransaction",
  SuspectedBurnTransaction = "suspectedBurnTransaction",
  MissingBlockchain = "missingBlockchain",
  UnmatchedTransfer = "unmatchedTransfer",
}
// Ignored warnings shown on the row's expanded view

export enum IgnorableWarningInfo {
  NegativeBalance = Warning.NegativeBalance,
  MissingBlockchain = Warning.MissingBlockchain,
}
export enum DateFormats {
  YearMonthDayDashes = "YYYY-MM-DD HH:mm:ss",
  YearMonthDaySlashes = "YYYY/MM/DD HH:mm:ss",
  YearMonthDaySlashesNoSeconds = "YYYY/MM/DD HH:mm",
  YearMonthDayDashesNoSeconds = "YYYY-MM-DD HH:mm",
  ShortYearMonthDaySlashes = "YY/MM/DD HH:mm:ss",
  ShortYearMonthDayDashes = "YY-MM-DD HH:mm:ss",
  DayMonthYearSlashes = "DD/MM/YYYY HH:mm:ss",
  DayMonthYearDashes = "DD-MM-YYYY HH:mm:ss",
  DayMonthYearSlashesNoSeconds = "DD/MM/YYYY HH:mm",
  MonthDayYearSlashes = "MM/DD/YYYY HH:mm:ss",
  MonthDayYearDashes = "MM-DD-YYYY HH:mm:ss",
  DMMYYYYHmmSlashes = "D/MM/YYYY H:mm",
  MMDDYYHHmmSlashes = "MM/DD/YYYY HH:mm",
}
export enum HoldingsBalanceType {
  Overall = "overall",
  Imports = "imports",
  Detected = "detected",
}

export enum Features {
  AdvancedReports = "advanced-reports",
  AdvancedInventory = "advanced-inventory",
  TradingStockReport = "trading-stock-report",
  TaxLossHarvesting = "tax-loss-harvesting",
  MultiTaxYears = "multi-tax-years",
  Tips = "tips",
  SmartContractTransactions = "smart-contract-transactions",
  ERP = "erp",
  Contacts = "contacts",
  LockTaxYear = "lock-tax-year",
  Rules = "rules",
  Sidebar = "sidebar",
  Treasury = "treasury",
}
export enum PartyToAction {
  user = "user",
  client = "client",
  accountant = "accountant",
  both = "both",
}
export enum PaywallReason {
  free = "free",
  txCount = "txCount",
  unpaid = "unpaid",
  importCount = "importCount",
  tradeType = "tradeType",
}
export enum WhoIsPaying {
  USER = "user",
  ACCOUNTANT = "accountant",
  BOTH = "both",
  NOBODY = "nobody",
}
export enum CardBrand {
  MasterCard = "mastercard",
  Visa = "visa",
  Amex = "amex",
  Diners = "diners",
  Discover = "discover",
  JCB = "jcb",
  UnionPay = "unionpay",
  Unknown = "uknown",
}
export enum InvitationStatus {
  Pending = "pending",
  Accepted = "accepted",
  Revoked = "revoked",
  Declined = "declined",
  Deleted = "deleted",
  RevokedLink = "revokedLink",
}
export enum PaidBy {
  Accountant = "accountant",
  Client = "client",
  User = "user",
}
export enum InvitationType {
  Accountant = "accountant", // if you are inviting an accountant (unused at this time)
  Collaborator = "collaborator", // if you are inviting a teammate
  Support = "support", // if you are inviting a support team member
  Client = "client", // if you are inviting a client
  TeamOwner = "team-owner",
}
export enum InvitationDirection {
  Sent = "sent",
  Received = "received",
}
export enum NotificationType {
  ImportDataWarning = "importDataWarning",
  PaywallLimitWarningBanner = "showPaywallLimitWarningBanner",
  TipsInfoModal = "showTipsInfoModal",
  KucoinFuturesMigratedBanner = "showKucoinFuturesMigratedBanner",
  OptInSupportAccess = "optInSupportAccess",
  ShowUnmatchedTransfers = "showUnmatchedTransfers",
}
export enum AccountantPortalStep {
  Welcome = "welcome",
  CompanyNameInput = "companyNameInput",
}
export enum UpgradeFirstClientStep {
  SelectPlan = "selectPlan",
  Payment = "payment",
}
export enum ErpUserAccounts {
  Cash = "cash",
  Asset = "asset",
  Fee = "fee",
  Loss = "loss",
  Expense = "expense",
  Loan = "loan",
  Income = "income",
  RealisedGain = "realised-gain",
  RealisedLoss = "realised-loss",
  ZeroCostBuy = "zero-cost-buy",
}
export enum TaxDivision {
  Buy = "buy",
  Transfer = "transfer",
  Income = "income",
  Ignore = "ignore",
  Disposal = "disposal",
  Payment = "payment",
  Withdrawal = "withdrawal",
  Hidden = "hidden",
  Group = "group",
}
export enum TradeGroupDirection {
  Group,
}
export enum TradeDirection {
  In = "in",
  Out = "out",
  Unknown = "Unknown",
}
// Represents the ratio of incoming to outgoing txs in a group

export enum GroupedActionRatio {
  OneToOne = "oneToOne", // Strictly 1 in and 1 out tx
  OneToN = "oneToN", // 1 out tx and N in txs
  NToN = "nToN", // Equal number of in and out txs, but not strictly 1 of each
  NToM = "nToM",
}
export enum GroupRecategorisationType {
  TradeTransferLike = "tradeTransferLike",
  LiquidityLike = "liquidityLike",
  MultiCurrencyTransaction = "multiCurrencyTransaction",
}
export enum ReconciliationSteps {
  Import = "import",
  Prepare = "prepare",
  Categorise = "categorise",
  Correct = "correct",
  Resolve = "resolve",
}
export enum Side {
  From = "from",
  To = "to",
}
export enum Direction {
  Incoming = "incoming",
  Outgoing = "outgoing",
}
export enum QuickEditFocusField {
  Incoming = "incoming",
  Outgoing = "outgoing",
  Fee = "fee",
  Value = "value",
}
export enum Align {
  Left = "left",
  Right = "right",
}
export enum Size {
  Small = "small",
  Medium = "medium",
  Large = "large",
}
export enum Order {
  Asc = "asc",
  Desc = "desc",
}

export enum CustomExchanges {
  Unknown = "unknown",
  Bank = "bank",
}
export enum RelatedWalletsAction {
  Ignore = "ignore",
  Notify = "notify",
  Import = "import",
}
// ---- Imports V2 ----
// Copied from BE may be out of date

export enum IntegrationCategory {
  CEX = "centralized-exchange",
  DEX = "decentralized-exchange",
  Blockchain = "blockchain",
  Wallet = "wallet",
  Service = "service",
  Manual = "manual",
}
// lastSyncSuccess is missing (and isn't that valuable either)
export enum UserFacingParseError {
  UnhandledTrade = "unhandledTrade",
  UnhandledTimestamp = "unhandledTimestamp",
  UnhandledFields = "unhandledFields",
  IntentionalNoOp = "noOp",
  UserSpecifiedCurrencyIssue = "userSpecifiedCurrencyIssue",
  NoTransactions = "noTransactions",
  GenericError = "genericError",
  APIError = "apiError",
  LockPeriod = "lockPeriod",
}
// From: https://docs.google.com/spreadsheets/d/1EMchH6F03Oj_XUWtmr_HgbABJqR7kyHM48XAouKRXDg/edit#gid=0

export enum CategoryBucket {
  Transfer = "transfer",
  Acquisition = "acquisition",
  Income = "income",
  Ignore = "ignore",
  Derivatives = "derivatives",
  Disposal = "disposal",
  Expenses = "expenses",
  Loss = "loss",
}
// Add new feature flags below:
// Please ensure all flags here have also been created in posthog

export enum FeatureFlag {
  // adding a placeholder as I don't want to remove this enum for obvious reasons
  placeholder = "placeholder",
  MultiVariantExample = "multi-variant-example",
  disableRecaptchaOnLogin = "disable-recaptcha-on-login", // Inverted logic: if this flag is enabled, recaptcha is disabled. Generally off in posthog by design
  TipsPaywall = "tips-paywall",
  Chartmander = "chartmander",
  SupportAccess = "support-access",
  ReconRevamp = "recon-revamp",
  OnboardingImportLoop = "onboarding-import-loop",
  OnboardingPaywall = "onboarding-paywall",
  LinearFlow = "linear-flow",
  BusinessClientDrawer = "business-client-drawer",
  TaxMinimisationCopy = "tax-minimisation-copy",
  ConnectWallet = "connect-wallet",
  OnboardingImportInstructions = "onboarding-import-instructions",
  ImportErrorReport = "import-error-report",
  PostEvmImportInsights = "post-evm-import-insights",
  ActiveSupport = "active-support",
  SuspectedMissingCEXImports = "suspected-missing-cex-imports",
  AiExplain = "ai-explain",
  ExperimentAiExplain = "experiment-ai-explain",
  UseTurnstileCaptcha = "use-turnstile-captcha",
  USHobbyistPlusExperiment = "us-hobbyist-plus",
  NFTGallery = "nft-gallery",
  ReferAFriendV2 = "refer-a-friend-v2",
  TaxHubOfferPreSignup = "tax-hub-offer-pre-signup",
  DashboardCountdownBanner = "dashboard-countdown-banner",
  CoinbaseTheme = "coinbase-theme",
  OkxOAuth = "okx-oauth",
  ImportFromDate = "import-from-date", // Feature flag for the import from date feature in advanced CSV options

  /**
   * Feature flag that controls the carousel in the embedded dashboard
   */
  EmbeddedDashboardCarousel = "embedded-dashboard-carousel",
  PaywallModals = "embedded-paywall-modals",
  OnboardingMobileImport = "onboarding-mobile-import",
  TaxHubOfferPreSignupWithMetamask = "tax-hub-pre-signup-offer-with-metamask-signup",
  TaxhubEnableRookiePlan = "taxhub-enable-rookie-plan",
  /**
   * Feature flag that enables the "remind me later" option for users who want to do their taxes,
   * but not right now. When enabled, users can request a reminder email to complete their taxes later.
   */
  FlexRemindMeLater = "flex-remind-me-later-button",
  MobileReportsPageUplift = "mobile-reports-page-uplift",
  MobileDemandTest = "mobile-demand-test",
}

export enum ImportViewMode {
  ByAddress = "byAddress",
  ByBlockchain = "byBlockchain",
}
export enum ErpSyncStatus {
  NotReadyToSync = "NOT_READY_TO_SYNC",
  SyncNotRequested = "SYNC_NOT_REQUESTED",
  SyncInProgress = "SYNC_IN_PROGRESS",
  SyncSuccessful = "SYNC_SUCCESSFUL",
  SyncFailed = "SYNC_FAILED",
  SyncOutOfDate = "SYNC_OUT_OF_DATE",
  SyncNoOp = "SYNC_NO_OP",
}
export enum HoldingViewMode {
  Currencies = "currencies",
  Nfts = "nfts",
}
export enum ReferralProgramType {
  InAppAmbassadorCash = "InAppAmbassadorCash",
}
export enum ClientType {
  Individual = "individual",
  Business = "business",
}
export enum DisplayMessage {
  Warning = "warning",
  Error = "error",
  Info = "info",
  Success = "success",
}

export enum YoutubePlayerState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5,
}

export enum SpecificInventoryByExchangeSetting {
  ON = "On",
  OFF = "Off",
  MIXED = "Mixed",
}
