import { useState } from "react";

import { getAssociatedTxFilterQuery } from "~/components/transactions/action-row/helpers";
import { ViewAssociatedTransactionsModal } from "~/components/transactions/action-row/ViewAssociatedTransactionsModal";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { FrameInspect } from "~/components/ui/Icons";
import { useLang } from "~/redux/lang";
import { useGetActionsCountFromFilterQuery } from "~/state/actions";
import { type ActionRow } from "~/types/index";

export const ViewAssociatedTxButton = ({ row }: { row: ActionRow }) => {
  const [isViewingAssociatedTransactions, setIsViewingAssociatedTransactions] =
    useState(false);
  const [txCount, setTxCount] = useState<undefined | number>(undefined);
  const lang = useLang();
  const filter = getAssociatedTxFilterQuery(row);
  const associatedTxQuery = useGetActionsCountFromFilterQuery({
    filter,
  });

  if (associatedTxQuery.data && !txCount) {
    setTxCount(associatedTxQuery.data);
  }

  return (
    <>
      <ViewAssociatedTransactionsModal
        isOpen={isViewingAssociatedTransactions}
        setIsOpen={setIsViewingAssociatedTransactions}
        row={row}
      />

      <TextButton
        onClick={() => {
          setIsViewingAssociatedTransactions(true);
        }}
        endIcon={<FrameInspect sx={{ height: "1rem", width: "1rem" }} />}
        sx={{ height: "1.75rem" }}
      >
        {lang.onboardingV2.viewAssociatedTransaction({ txCount: txCount ?? 0 })}
      </TextButton>
    </>
  );
};
