import { type ArchiveStatus } from "@ctc/types";
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from "@tanstack/react-query";

import { displayMessage } from "~/components/ui/Toaster";
import { useLang } from "~/redux/lang";
import { type Data } from "~/services/core";
import * as developerAPI from "~/services/developer";
import * as settingsAPI from "~/services/settings";
import { DisplayMessage } from "~/types/enums";

export const useArchiveStatus = (
  options?: Omit<
    UseQueryOptions<
      Data<{
        status: ArchiveStatus;
      }>,
      Error,
      ArchiveStatus | undefined,
      string[]
    >,
    "queryKey" | "queryFn" | "select"
  >,
) => {
  return useQuery({
    queryKey: ["settings", "user-archive-status"],
    queryFn: settingsAPI.userArchiveStatus,
    select: (response) => {
      if (response.error) {
        return undefined;
      }
      return response.data.status;
    },
    ...options,
  });
};

export const useArchiveUser = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await developerAPI.archiveUserData();

      if (res.error) {
        displayMessage({
          message: res.msg ?? "Failed to archive user data",
          type: DisplayMessage.Error,
        });
      }

      return res;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["settings", "user-archive-status"],
      });
    },
  });
  return mutation;
};

export const useRestoreUser = () => {
  const lang = useLang();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await settingsAPI.restoreUserData();

      if (res.error) {
        displayMessage({
          message: res.msg ?? lang.userArchive.failedToRestore,
          type: DisplayMessage.Error,
        });
      }

      return res;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["settings", "user-archive-status"],
      });
    },
  });
  return mutation;
};
