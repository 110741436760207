import { Box } from "@mui/material";
import { useState } from "react";

import { OAuthKeyItem } from "~/components/imports/api/OAuthKeyItem";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { useLang } from "~/redux/lang";
import { useLoadingSavedImports } from "~/state/imports";
import {
  type ImportOptionV2,
  type KeyCredentials,
  type SavedImportByIntegration,
} from "~/types/index";

interface OAuthImportListProps {
  importOption: ImportOptionV2;
  handleDelete: (key: KeyCredentials) => void;
  savedImport: SavedImportByIntegration | undefined;
}

export function OAuthImportList({
  importOption,
  handleDelete,
  savedImport,
}: OAuthImportListProps) {
  const { name: exchangeName, id: exchangeLabel } = importOption;
  const [open, setOpen] = useState<boolean>(false);
  const [deletingKey, setDeletingKey] = useState<KeyCredentials>();
  const isLoading = useLoadingSavedImports();

  if (!savedImport || (!savedImport.oauths.length && !isLoading)) {
    return null;
  }

  const sortedData = savedImport.oauths
    .sort(
      (a, b) =>
        new Date(a.createdAt || 0).valueOf() -
        new Date(b.createdAt || 0).valueOf(),
    )
    .map(
      (key): KeyCredentials => ({
        ...key,
        exchangeName: importOption.id,
        apiKey: key.id,
        isOauth: true,
        lastSyncComplete: key.completedAt
          ? new Date(key.completedAt)
          : undefined,
        syncStatus: key.status,
        syncDisplayError: key.error,
      }),
    );

  return (
    <>
      <DeleteOauthDialog
        deletingKey={deletingKey}
        exchangeName={exchangeName}
        isOpen={open}
        setOpen={setOpen}
        setDeletingKey={setDeletingKey}
        handleDelete={handleDelete}
      />
      <Box>
        {sortedData.map((key) => (
          <OAuthKeyItem
            key={key.id}
            keyDetails={key}
            exchangeLabel={exchangeLabel}
            exchangeName={exchangeName}
            handleDelete={() => {
              setDeletingKey(key);
              setOpen(true);
            }}
          />
        ))}
      </Box>
    </>
  );
}

function DeleteOauthDialog({
  deletingKey,
  exchangeName,
  isOpen,
  setOpen,
  setDeletingKey,
  handleDelete,
}: {
  deletingKey: KeyCredentials | undefined;
  exchangeName: string;
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  setDeletingKey: (val: KeyCredentials | undefined) => void;
  handleDelete: (key: KeyCredentials) => void;
}) {
  const lang = useLang();

  function handleConfirm(confirm: boolean, handler?: () => void): void {
    setOpen(false);
    if (confirm && handler) {
      handler();
      setDeletingKey(undefined);
    }
  }

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={lang.imports.deleteOauthKeyConfirmTitle({ exchangeName })}
      text={lang.imports.deleteOauthKeyConfirmText({ exchangeName })}
      actionText={lang.delete}
      handleClose={() => {
        handleConfirm(false);
      }}
      handleAction={() =>
        deletingKey
          ? handleConfirm(true, () => {
              handleDelete(deletingKey);
            })
          : null
      }
      critical
    />
  );
}
