import { Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { useIsMobile } from "~/components/ui/hooks";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { LocalStorageKey } from "~/constants/enums";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function OnMobileWarningBanner() {
  const lang = useLang();
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const [showBanner, setShowBanner] = useLocalStorage(
    LocalStorageKey.ShowOnMobileWarningBanner,
    isMobile,
  );

  if (!isMobile || !showBanner) return null;

  return (
    <StyledBanner>
      <Typography variant="Metropolis/Body/Bold">
        {lang.onboardingV2.onMobileBanner.title}
      </Typography>
      <TextIconButton
        size="small"
        sx={{ padding: 0 }}
        onClick={() => {
          setShowBanner(false);
        }}
      >
        <Close sx={{ color: tokens.text.low, fontSize: "1rem" }} />
      </TextIconButton>
    </StyledBanner>
  );
}

const StyledBanner = styled(Box)`
  display: flex;
  width: 95vw;
  padding: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${({ theme }) => theme.tokens.background.accent.purple.medium};
  background: ${({ theme }) => theme.tokens.background.accent.purple.lowest};
`;
