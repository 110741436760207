import { useAvailableImportOptions } from "~/state/imports";
import { useReconciliationDataForType } from "~/state/reconciliation";
import { type SuspectedMissingImport } from "~/types";
import { IntegrationCategory, ReconciliationIssues } from "~/types/enums";

/**
 * Hook that returns suspected missing imports based on user's entity interactions
 * @returns Array of import options for exchanges that the user has interacted with but hasn't imported yet
 */
export function useGetSuspectedMissingImports(): SuspectedMissingImport[] {
  const availableImportOptions = useAvailableImportOptions();
  const suspectedMissingImportIssuesQuery = useReconciliationDataForType(
    ReconciliationIssues.SuspectedMissingImport,
    true,
  );

  const suspectedMissingImportIssues =
    suspectedMissingImportIssuesQuery.issues ?? [];

  if (!suspectedMissingImportIssues) {
    return [];
  }

  // Create a map of CEX import options for faster lookup
  const cexImportOptionsMap = new Map(
    availableImportOptions
      .filter((option) => option.category === IntegrationCategory.CEX)
      .map((option) => [option.id, option]),
  );

  const suspectedMissingImports = suspectedMissingImportIssues.flatMap(
    (issue) => {
      // Skip if no matching CEX import option available
      const importOption = cexImportOptionsMap.get(issue.exchange);
      if (!importOption) {
        return [];
      }

      return [{ importOption, issue }];
    },
  );

  return suspectedMissingImports;
}
