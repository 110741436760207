import { type Blockchain } from "@ctc/types";

import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { middleTrim } from "~/lib/index";

export function getOptionsFromAccounts(
  accounts: {
    displayName: string;
    exchangeId: string;
    blockchain: Blockchain | undefined;
    currencySymbol: string;
  }[],
) {
  return (
    accounts.map((party) => ({
      label: `${middleTrim(party.displayName)} (${party.exchangeId})`,
      icon: (
        <ExchangeLogo
          name={party.exchangeId}
          currencySymbol={party.currencySymbol}
          blockchain={party.blockchain}
          showBlockchainSymbol
        />
      ),
      party,
      chevron: true,
    })) ?? []
  );
}
