import {
  Env,
  LocalCurrency,
  OAuthProvider,
  Plan,
  ReferrerSource,
  SupportedLang,
  TaxRule,
} from "@ctc/types";

import { Constants, CouponCode } from "~/constants/enums";
import { CardBrand, ERPAccountType, Links } from "~/types/enums";

export const MIN_ADDR_LEN = 16;

export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const ALLOW_CLIENT_DELETE = true;
export const SMALL_VALUE_THRESHOLD = 0.01;

export const MAX_ADDR_LENGTH = 9;
export const MAX_EXCHANGE_LENGTH = 13;
export const NFT_ID_MAX_DISPLAY_LENGTH = 9;

export const COMMENT_CHARACTER_LIMIT = 500;
export const DEFAULT_IGNORE_BALANCE_THRESHOLD = 2;

export const HARD_GROUPED_TX_IMPORT_LIMIT = 150_000; // used for the grouped count limit

export const REFERRAL_REWARD = 100;

export const MAX_RELATED_WALLETS_ACTION = 50;
/**
 * This is the message that is sent from the Auto-close page to the controlling window
 * to indicate that the page can be closed.
 */
export const AUTO_CLOSE_MESSAGE = "AUTO_CLOSE";

export const SHEET_ADVANCED_CSV_TEMPLATE =
  "https://docs.google.com/spreadsheets/d/1W6GE2rSQAJtNnsZVbY4fgS55-2i3tMML3TD1SGIPl-k/copy";
export const ADVANCED_CSV_DOWNLOAD =
  "https://cryptotaxcalculator.io/advanced.csv";
export const ADVANCED_XLSX_DOWNLOAD =
  "https://cryptotaxcalculator.io/advanced.xlsx";

export const AnalyticsUTMParams = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

export const CENTS_PER_DOLLAR = 100;

export const PlanRank: Record<Plan, number> = {
  [Plan.Free]: 0,
  [Plan.Rookie_DEPRECATED]: 1,
  [Plan.Rookie]: 1,
  [Plan.Hobbyist_DEPRECATED]: 2,
  [Plan.Hobbyist]: 2,
  [Plan.Investor]: 3,
  [Plan.InvestorUs]: 3,
  [Plan.InvestorUsPlus]: 3,
  [Plan.Trader]: 4,
  [Plan.TraderUs]: 4,
  [Plan.TraderUsPlus]: 4,
  [Plan.Pro]: 5,

  [Plan.Business]: 7,

  [Plan.Accountant]: 8,
  [Plan.Collaborator]: 8,
  [Plan.Enterprise]: 8,

  [Plan.BusinessTrial]: 6,
  [Plan.BusinessStarter]: 6,
  [Plan.BusinessPro]: 6,
  [Plan.BusinessPackage]: 6,
};

export const currencySymbol = {
  [LocalCurrency.USD]: "$",
  [LocalCurrency.AUD]: "$",
  [LocalCurrency.BGN]: "лв",
  [LocalCurrency.BRL]: "R$",
  [LocalCurrency.CAD]: "$",
  [LocalCurrency.CHF]: "CHF",
  [LocalCurrency.CNY]: "¥",
  [LocalCurrency.CZK]: "Kč",
  [LocalCurrency.DKK]: "kr",
  [LocalCurrency.EUR]: "€",
  [LocalCurrency.GBP]: "£",
  [LocalCurrency.HKD]: "HK$",
  [LocalCurrency.HUF]: "Ft",
  [LocalCurrency.IDR]: "Rp",
  [LocalCurrency.ILS]: "₪",
  [LocalCurrency.INR]: "₹",
  [LocalCurrency.ISK]: "kr",
  [LocalCurrency.JPY]: "¥",
  [LocalCurrency.KRW]: "₩",
  [LocalCurrency.MXN]: "$",
  [LocalCurrency.MYR]: "RM",
  [LocalCurrency.NOK]: "NOK", // kr is suffix
  [LocalCurrency.NZD]: "$",
  [LocalCurrency.PHP]: "₱",
  [LocalCurrency.PLN]: "zł",
  [LocalCurrency.RON]: "lei",
  [LocalCurrency.SEK]: "SEK", // kr is suffix
  [LocalCurrency.SGD]: "S$",
  [LocalCurrency.THB]: "฿",
  [LocalCurrency.TRY]: "₺",
  [LocalCurrency.ZAR]: "R",
  [LocalCurrency.AED]: "د.إ",
  [LocalCurrency.AFN]: "؋",
  [LocalCurrency.ALL]: "L",
  [LocalCurrency.AMD]: "֏",
  [LocalCurrency.ANG]: "ƒ",
  [LocalCurrency.AOA]: "Kz",
  [LocalCurrency.ARS]: "$",
  [LocalCurrency.AWG]: "ƒ",
  [LocalCurrency.AZN]: "₼",
  [LocalCurrency.BAM]: "KM",
  [LocalCurrency.BBD]: "$",
  [LocalCurrency.BDT]: "৳",
  [LocalCurrency.BHD]: "د.ب",
  [LocalCurrency.BIF]: "FBu",
  [LocalCurrency.BMD]: "$",
  [LocalCurrency.BND]: "$",
  [LocalCurrency.BOB]: "Bs",
  [LocalCurrency.BSD]: "$",
  [LocalCurrency.BTN]: "Nu.",
  [LocalCurrency.BWP]: "P",
  [LocalCurrency.BYN]: "Br",
  [LocalCurrency.BYR]: "Br",
  [LocalCurrency.BZD]: "$",
  [LocalCurrency.CDF]: "FC",
  [LocalCurrency.CLF]: "UF",
  [LocalCurrency.CLP]: "$",
  [LocalCurrency.COP]: "$",
  [LocalCurrency.CRC]: "₡",
  [LocalCurrency.CUC]: "$",
  [LocalCurrency.CUP]: "₱",
  [LocalCurrency.CVE]: "$",
  [LocalCurrency.DJF]: "Fdj",
  [LocalCurrency.DOP]: "$",
  [LocalCurrency.DZD]: "د.ج",
  [LocalCurrency.EGP]: "£",
  [LocalCurrency.ERN]: "Nfk",
  [LocalCurrency.ETB]: "Br",
  [LocalCurrency.FJD]: "$",
  [LocalCurrency.FKP]: "£",
  [LocalCurrency.GEL]: "₾",
  [LocalCurrency.GGP]: "£",
  [LocalCurrency.GHS]: "₵",
  [LocalCurrency.GIP]: "£",
  [LocalCurrency.GMD]: "D",
  [LocalCurrency.GNF]: "FG",
  [LocalCurrency.GTQ]: "Q",
  [LocalCurrency.GYD]: "$",
  [LocalCurrency.HNL]: "L",
  [LocalCurrency.HRK]: "kn",
  [LocalCurrency.HTG]: "G",
  [LocalCurrency.IMP]: "£",
  [LocalCurrency.IQD]: "ع.د",
  [LocalCurrency.IRR]: "﷼",
  [LocalCurrency.JEP]: "£",
  [LocalCurrency.JMD]: "$",
  [LocalCurrency.JOD]: "د.ا",
  [LocalCurrency.KES]: "Sh",
  [LocalCurrency.KGS]: "с",
  [LocalCurrency.KHR]: "៛",
  [LocalCurrency.KMF]: "CF",
  [LocalCurrency.KPW]: "₩",
  [LocalCurrency.KWD]: "د.ك",
  [LocalCurrency.KYD]: "$",
  [LocalCurrency.KZT]: "₸",
  [LocalCurrency.LAK]: "₭",
  [LocalCurrency.LBP]: "ل.ل",
  [LocalCurrency.LKR]: "₨",
  [LocalCurrency.LRD]: "$",
  [LocalCurrency.LSL]: "L",
  [LocalCurrency.LYD]: "ل.د",
  [LocalCurrency.MAD]: "د.م.",
  [LocalCurrency.MDL]: "L",
  [LocalCurrency.MGA]: "Ar",
  [LocalCurrency.MKD]: "ден",
  [LocalCurrency.MMK]: "K",
  [LocalCurrency.MNT]: "₮",
  [LocalCurrency.MOP]: "$",
  [LocalCurrency.MRU]: "UM",
  [LocalCurrency.MUR]: "₨",
  [LocalCurrency.MVR]: "Rf",
  [LocalCurrency.MWK]: "MK",
  [LocalCurrency.MZN]: "MT",
  [LocalCurrency.NAD]: "$",
  [LocalCurrency.NGN]: "₦",
  [LocalCurrency.NIO]: "C$",
  [LocalCurrency.NPR]: "₨",
  [LocalCurrency.OMR]: "ر.ع.",
  [LocalCurrency.PAB]: "B/.",
  [LocalCurrency.PEN]: "S/",
  [LocalCurrency.PGK]: "K",
  [LocalCurrency.PKR]: "₨",
  [LocalCurrency.PYG]: "₲",
  [LocalCurrency.QAR]: "ر.ق",
  [LocalCurrency.RSD]: "дин.",
  [LocalCurrency.RUB]: "₽",
  [LocalCurrency.RWF]: "R₣",
  [LocalCurrency.SAR]: "ر.س",
  [LocalCurrency.SBD]: "$",
  [LocalCurrency.SCR]: "₨",
  [LocalCurrency.SDG]: "ج.س.",
  [LocalCurrency.SHP]: "£",
  [LocalCurrency.SLE]: "Le",
  [LocalCurrency.SLL]: "Le",
  [LocalCurrency.SOS]: "S",
  [LocalCurrency.SRD]: "$",
  [LocalCurrency.STD]: "Db",
  [LocalCurrency.SVC]: "₡",
  [LocalCurrency.SYP]: "£",
  [LocalCurrency.SZL]: "E",
  [LocalCurrency.TJS]: "ЅМ",
  [LocalCurrency.TMT]: "T",
  [LocalCurrency.TND]: "د.ت",
  [LocalCurrency.TOP]: "$",
  [LocalCurrency.TTD]: "$",
  [LocalCurrency.TWD]: "$",
  [LocalCurrency.TZS]: "Sh",
  [LocalCurrency.UAH]: "₴",
  [LocalCurrency.UGX]: "Sh",
  [LocalCurrency.UYU]: "$",
  [LocalCurrency.UZS]: "so'm",
  [LocalCurrency.VEF]: "Bs",
  [LocalCurrency.VES]: "Bs",
  [LocalCurrency.VND]: "₫",
  [LocalCurrency.VUV]: "Vt",
  [LocalCurrency.WST]: "$",
  [LocalCurrency.XAF]: "FCFA",
  [LocalCurrency.XOF]: "CFA",
  [LocalCurrency.XCD]: "$",
  [LocalCurrency.XPF]: "₣",
  [LocalCurrency.YER]: "﷼",
  [LocalCurrency.ZMW]: "K",
  [LocalCurrency.ZWL]: "$",
} as const;

// BCP 47 language tag
// todo: this should be shared with core
export const LocalLanguageCode = {
  /** United States Dollar */
  [LocalCurrency.USD]: "en-US",
  /** Canadian Dollar */
  [LocalCurrency.CAD]: "en-CA",
  /** Australian Dollar */
  [LocalCurrency.AUD]: "en-AU",
  /** New Zealand Dollar */
  [LocalCurrency.NZD]: "en-NZ",
  /** British Pound Sterling */
  [LocalCurrency.GBP]: "en-GB",
  /** Euro */
  [LocalCurrency.EUR]: "en-US",
  /** South African Rand */
  [LocalCurrency.ZAR]: "en-ZA",
  /** Singapore Dollar */
  [LocalCurrency.SGD]: "zh-sg",
  /** Japanese Yen */
  [LocalCurrency.JPY]: "ja",
  /** Swedish Krona */
  [LocalCurrency.SEK]: "sv",
  /** Norwegian Krone */
  [LocalCurrency.NOK]: "no",
  /** Swiss Franc */
  [LocalCurrency.CHF]: "de-CH", // parity with BE
  /** Indian Rupee */
  [LocalCurrency.INR]: "en-IN",
  /** Bulgarian Lev */
  [LocalCurrency.BGN]: "bg-BG",
  /** Brazilian Real */
  [LocalCurrency.BRL]: "pt-BR",
  /** Chinese Yuan */
  [LocalCurrency.CNY]: "zh-CN",
  /** Czech Koruna */
  [LocalCurrency.CZK]: "cs-CZ",
  /** Danish Krone */
  [LocalCurrency.DKK]: "da-DK",
  /** Hong Kong Dollar */
  [LocalCurrency.HKD]: "zh-HK",
  /** Hungarian Forint */
  [LocalCurrency.HUF]: "hu-HU",
  /** Indonesian Rupiah */
  [LocalCurrency.IDR]: "id-ID",
  /** Israeli New Shekel */
  [LocalCurrency.ILS]: "he-IL",
  /** Icelandic Króna */
  [LocalCurrency.ISK]: "is-IS",
  /** South Korean Won */
  [LocalCurrency.KRW]: "ko-KR",
  /** Mexican Peso */
  [LocalCurrency.MXN]: "es-MX",
  /** Malaysian Ringgit */
  [LocalCurrency.MYR]: "ms-MY",
  /** Philippine Peso */
  [LocalCurrency.PHP]: "fil-PH",
  /** Polish Złoty */
  [LocalCurrency.PLN]: "pl-PL",
  /** Romanian Leu */
  [LocalCurrency.RON]: "ro-RO",
  /** Thai Baht */
  [LocalCurrency.THB]: "th-TH",
  /** Turkish Lira */
  [LocalCurrency.TRY]: "tr-TR",
  /** UAE Dirham */
  [LocalCurrency.AED]: "ar-AE",
  /** Afghan Afghani */
  [LocalCurrency.AFN]: "ps-AF",
  /** Albanian Lek */
  [LocalCurrency.ALL]: "sq-AL",
  /** Armenian Dram */
  [LocalCurrency.AMD]: "hy-AM",
  /** Netherlands Antillean Guilder */
  [LocalCurrency.ANG]: "nl-CW",
  /** Angolan Kwanza */
  [LocalCurrency.AOA]: "pt-AO",
  /** Argentine Peso */
  [LocalCurrency.ARS]: "es-AR",
  /** Aruban Florin */
  [LocalCurrency.AWG]: "nl-AW",
  /** Azerbaijani Manat */
  [LocalCurrency.AZN]: "az-AZ",
  /** Bosnia-Herzegovina Convertible Mark */
  [LocalCurrency.BAM]: "bs-BA",
  /** Barbadian Dollar */
  [LocalCurrency.BBD]: "en-BB",
  /** Bangladeshi Taka */
  [LocalCurrency.BDT]: "bn-BD",
  /** Bahraini Dinar */
  [LocalCurrency.BHD]: "ar-BH",
  /** Burundian Franc */
  [LocalCurrency.BIF]: "fr-BI",
  /** Bermudan Dollar */
  [LocalCurrency.BMD]: "en-BM",
  /** Brunei Dollar */
  [LocalCurrency.BND]: "ms-BN",
  /** Bolivian Boliviano */
  [LocalCurrency.BOB]: "es-BO",
  /** Bahamian Dollar */
  [LocalCurrency.BSD]: "en-BS",
  /** Bhutanese Ngultrum */
  [LocalCurrency.BTN]: "dz-BT",
  /** Botswanan Pula */
  [LocalCurrency.BWP]: "en-BW",
  /** Belarusian Ruble */
  [LocalCurrency.BYN]: "be-BY",
  /** Belarusian Ruble (pre-2016) */
  [LocalCurrency.BYR]: "be-BY",
  /** Belize Dollar */
  [LocalCurrency.BZD]: "en-BZ",
  /** Congolese Franc */
  [LocalCurrency.CDF]: "fr-CD",
  /** Chilean Unit of Account */
  [LocalCurrency.CLF]: "es-CL",
  /** Chilean Peso */
  [LocalCurrency.CLP]: "es-CL",
  /** Colombian Peso */
  [LocalCurrency.COP]: "es-CO",
  /** Costa Rican Colón */
  [LocalCurrency.CRC]: "es-CR",
  /** Cuban Convertible Peso */
  [LocalCurrency.CUC]: "es-CU",
  /** Cuban Peso */
  [LocalCurrency.CUP]: "es-CU",
  /** Cape Verdean Escudo */
  [LocalCurrency.CVE]: "pt-CV",
  /** Djiboutian Franc */
  [LocalCurrency.DJF]: "fr-DJ",
  /** Dominican Peso */
  [LocalCurrency.DOP]: "es-DO",
  /** Algerian Dinar */
  [LocalCurrency.DZD]: "ar-DZ",
  /** Egyptian Pound */
  [LocalCurrency.EGP]: "ar-EG",
  /** Eritrean Nakfa */
  [LocalCurrency.ERN]: "ti-ER",
  /** Ethiopian Birr */
  [LocalCurrency.ETB]: "am-ET",
  /** Fijian Dollar */
  [LocalCurrency.FJD]: "en-FJ",
  /** Falkland Islands Pound */
  [LocalCurrency.FKP]: "en-FK",
  /** Georgian Lari */
  [LocalCurrency.GEL]: "ka-GE",
  /** Guernsey Pound */
  [LocalCurrency.GGP]: "en-GG",
  /** Ghanaian Cedi */
  [LocalCurrency.GHS]: "ak-GH",
  /** Gibraltar Pound */
  [LocalCurrency.GIP]: "en-GI",
  /** Gambian Dalasi */
  [LocalCurrency.GMD]: "en-GM",
  /** Guinean Franc */
  [LocalCurrency.GNF]: "fr-GN",
  /** Guatemalan Quetzal */
  [LocalCurrency.GTQ]: "es-GT",
  /** Guyanaese Dollar */
  [LocalCurrency.GYD]: "en-GY",
  /** Honduran Lempira */
  [LocalCurrency.HNL]: "es-HN",
  /** Croatian Kuna */
  [LocalCurrency.HRK]: "hr-HR",
  /** Haitian Gourde */
  [LocalCurrency.HTG]: "ht-HT",
  /** Isle of Man Pound */
  [LocalCurrency.IMP]: "en-IM",
  /** Iraqi Dinar */
  [LocalCurrency.IQD]: "ar-IQ",
  /** Iranian Rial */
  [LocalCurrency.IRR]: "fa-IR",
  /** Jersey Pound */
  [LocalCurrency.JEP]: "en-JE",
  /** Jamaican Dollar */
  [LocalCurrency.JMD]: "en-JM",
  /** Jordanian Dinar */
  [LocalCurrency.JOD]: "ar-JO",
  /** Kenyan Shilling */
  [LocalCurrency.KES]: "sw-KE",
  /** Kyrgystani Som */
  [LocalCurrency.KGS]: "ky-KG",
  /** Cambodian Riel */
  [LocalCurrency.KHR]: "km-KH",
  /** Comorian Franc */
  [LocalCurrency.KMF]: "ar-KM",
  /** North Korean Won */
  [LocalCurrency.KPW]: "ko-KP",
  /** Kuwaiti Dinar */
  [LocalCurrency.KWD]: "ar-KW",
  /** Cayman Islands Dollar */
  [LocalCurrency.KYD]: "en-KY",
  /** Kazakhstani Tenge */
  [LocalCurrency.KZT]: "kk-KZ",
  /** Laotian Kip */
  [LocalCurrency.LAK]: "lo-LA",
  /** Lebanese Pound */
  [LocalCurrency.LBP]: "ar-LB",
  /** Sri Lankan Rupee */
  [LocalCurrency.LKR]: "si-LK",
  /** Liberian Dollar */
  [LocalCurrency.LRD]: "en-LR",
  /** Lesotho Loti */
  [LocalCurrency.LSL]: "st-LS",
  /** Libyan Dinar */
  [LocalCurrency.LYD]: "ar-LY",
  /** Moroccan Dirham */
  [LocalCurrency.MAD]: "ar-MA",
  /** Moldovan Leu */
  [LocalCurrency.MDL]: "ro-MD",
  /** Malagasy Ariary */
  [LocalCurrency.MGA]: "mg-MG",
  /** Macedonian Denar */
  [LocalCurrency.MKD]: "mk-MK",
  /** Myanmar Kyat */
  [LocalCurrency.MMK]: "my-MM",
  /** Mongolian Tugrik */
  [LocalCurrency.MNT]: "mn-MN",
  /** Macanese Pataca */
  [LocalCurrency.MOP]: "zh-MO",
  /** Mauritanian Ouguiya */
  [LocalCurrency.MRU]: "ar-MR",
  /** Mauritian Rupee */
  [LocalCurrency.MUR]: "en-MU",
  /** Maldivian Rufiyaa */
  [LocalCurrency.MVR]: "dv-MV",
  /** Malawian Kwacha */
  [LocalCurrency.MWK]: "ny-MW",
  /** Mozambican Metical */
  [LocalCurrency.MZN]: "pt-MZ",
  /** Namibian Dollar */
  [LocalCurrency.NAD]: "en-NA",
  /** Nigerian Naira */
  [LocalCurrency.NGN]: "en-NG",
  /** Nicaraguan Córdoba */
  [LocalCurrency.NIO]: "es-NI",
  /** Nepalese Rupee */
  [LocalCurrency.NPR]: "ne-NP",
  /** Omani Rial */
  [LocalCurrency.OMR]: "ar-OM",
  /** Panamanian Balboa */
  [LocalCurrency.PAB]: "es-PA",
  /** Peruvian Sol */
  [LocalCurrency.PEN]: "es-PE",
  /** Papua New Guinean Kina */
  [LocalCurrency.PGK]: "en-PG",
  /** Pakistani Rupee */
  [LocalCurrency.PKR]: "ur-PK",
  /** Paraguayan Guaraní */
  [LocalCurrency.PYG]: "es-PY",
  /** Qatari Riyal */
  [LocalCurrency.QAR]: "ar-QA",
  /** Serbian Dinar */
  [LocalCurrency.RSD]: "sr-RS",
  /** Russian Ruble */
  [LocalCurrency.RUB]: "ru-RU",
  /** Rwandan Franc */
  [LocalCurrency.RWF]: "rw-RW",
  /** Saudi Riyal */
  [LocalCurrency.SAR]: "ar-SA",
  /** Solomon Islands Dollar */
  [LocalCurrency.SBD]: "en-SB",
  /** Seychellois Rupee */
  [LocalCurrency.SCR]: "fr-SC",
  /** Sudanese Pound */
  [LocalCurrency.SDG]: "ar-SD",
  /** Saint Helena Pound */
  [LocalCurrency.SHP]: "en-SH",
  /** Sierra Leonean Leone */
  [LocalCurrency.SLE]: "en-SL",
  /** Sierra Leonean Leone (Old) */
  [LocalCurrency.SLL]: "en-SL",
  /** Somali Shilling */
  [LocalCurrency.SOS]: "so-SO",
  /** Surinamese Dollar */
  [LocalCurrency.SRD]: "nl-SR",
  /** São Tomé and Príncipe Dobra */
  [LocalCurrency.STD]: "pt-ST",
  /** Salvadoran Colón */
  [LocalCurrency.SVC]: "es-SV",
  /** Syrian Pound */
  [LocalCurrency.SYP]: "ar-SY",
  /** Swazi Lilangeni */
  [LocalCurrency.SZL]: "en-SZ",
  /** Tajikistani Somoni */
  [LocalCurrency.TJS]: "tg-TJ",
  /** Turkmenistan Manat */
  [LocalCurrency.TMT]: "tk-TM",
  /** Tunisian Dinar */
  [LocalCurrency.TND]: "ar-TN",
  /** Tongan Paʻanga */
  [LocalCurrency.TOP]: "to-TO",
  /** Trinidad and Tobago Dollar */
  [LocalCurrency.TTD]: "en-TT",
  /** New Taiwan Dollar */
  [LocalCurrency.TWD]: "zh-TW",
  /** Tanzanian Shilling */
  [LocalCurrency.TZS]: "sw-TZ",
  /** Ukrainian Hryvnia */
  [LocalCurrency.UAH]: "uk-UA",
  /** Ugandan Shilling */
  [LocalCurrency.UGX]: "sw-UG",
  /** Uruguayan Peso */
  [LocalCurrency.UYU]: "es-UY",
  /** Uzbekistani Som */
  [LocalCurrency.UZS]: "uz-UZ",
  /** Venezuelan Bolívar */
  [LocalCurrency.VEF]: "es-VE",
  /** Venezuelan Bolívar Soberano */
  [LocalCurrency.VES]: "es-VE",
  /** Vietnamese Đồng */
  [LocalCurrency.VND]: "vi-VN",
  /** Vanuatu Vatu */
  [LocalCurrency.VUV]: "bi-VU",
  /** Samoan Tala */
  [LocalCurrency.WST]: "sm-WS",
  /** CFA Franc BEAC */
  [LocalCurrency.XAF]: "fr-CM",
  /** CFA Franc BCEAO */
  [LocalCurrency.XOF]: "fr-SN",
  /** East Caribbean Dollar */
  [LocalCurrency.XCD]: "en-DM",
  /** CFP Franc */
  [LocalCurrency.XPF]: "fr-PF",
  /** Yemeni Rial */
  [LocalCurrency.YER]: "ar-YE",
  /** Zambian Kwacha */
  [LocalCurrency.ZMW]: "en-ZM",
  /** Zimbabwean Dollar */
  [LocalCurrency.ZWL]: "en-ZW",
} as const;

export const LangToLanguageCode: Record<SupportedLang, string> = {
  [SupportedLang.Xx]: "de-DE",
  [SupportedLang.XxShort]: "en-US",
  [SupportedLang.XxLong]: "en-US",
  [SupportedLang.De]: "de-DE",
  [SupportedLang.En]: "en-US",
  [SupportedLang.Es]: "es-ES",
  [SupportedLang.Fr]: "fr-FR",
  [SupportedLang.It]: "it-IT",
};

export const DISPLAY_BANNER = false;

export const TRANSACTIONS_ROWS_PER_PAGE = 25;

export const REPORT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const taxRuleLinks = {
  [TaxRule.BedAndBreakfast]:
    "https://help.cryptotaxcalculator.io/en/articles/8984080-how-to-calculate-your-uk-crypto-tax#h_6698cbd8ca",
  [TaxRule.RecalculatedAfterBedAndBreakfast]:
    "https://help.cryptotaxcalculator.io/en/articles/8984080-how-to-calculate-your-uk-crypto-tax#h_6698cbd8ca",
  [TaxRule.SameDay]:
    "https://help.cryptotaxcalculator.io/en/articles/8984080-how-to-calculate-your-uk-crypto-tax#h_99f5f6adef",
  [TaxRule.SuperficialLossFromBuyBack]:
    "https://help.cryptotaxcalculator.io/en/articles/8984259-how-to-calculate-canadian-crypto-tax#h_237965f88a",
  [TaxRule.SuperficialLossFromSell]:
    "https://help.cryptotaxcalculator.io/en/articles/8984259-how-to-calculate-canadian-crypto-tax#h_1e6e0602c6",
  [TaxRule.MarginFee]: undefined,
  [TaxRule.FeeForwarded]:
    "https://help.cryptotaxcalculator.io/en/articles/5305670-fee-is-missing",
  [TaxRule.ZeroCostBuy]:
    "https://help.cryptotaxcalculator.io/en/articles/5305370-handling-zero-cost-buy-transactions",
};

export const taxLossHarvestingBlogLink =
  "https://cryptotaxcalculator.io/au/blog/tax-loss-harvesting";

export const inventoryMethodForCryptocurrencyLink =
  "https://cryptotaxcalculator.io/au/guides/crypto-tax-accounting-methods";

export const categoriseTransactionsLink =
  "https://help.cryptotaxcalculator.io/en/articles/5279619-categorise-transactions";

export const solvingNegativeBalancesLink =
  "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances";

export const googleAuthenticatorLink =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2";

export const authyLink = "https://authy.com/download/";

export const AnalyticsPageName: Record<Links, string> = {
  [Links.Signup]: "signup",
  [Links.Invite]: "invite",
  [Links.SignupAccountant]: "signup-accountant",
  [Links.Login]: "login",
  [Links.TokenLogin]: "token-login",
  [Links.Dashboard]: "dashboard",
  [Links.Imports]: "import",
  [Links.ImportNew]: "import-new",
  [Links.ImportSearch]: "import-search",
  [Links.ImportCustom]: "import-custom",
  [Links.BalanceLedger]: "ledger",
  [Links.Transactions]: "transactions",
  [Links.TransactionsUncategorised]: "uncategorised-transactions",
  [Links.TransactionsNegativeBalance]: "negative-balance-transactions",
  [Links.TransactionsMissingPrice]: "missing-price-transactions",
  [Links.TransactionsSearch]: "search-transactions",
  [Links.TransactionsAdd]: "add-transaction",
  [Links.Wallets]: "import-wallets",
  [Links.Report]: "report",
  [Links.PaymentSuccess]: "payment-success",
  [Links.Reset]: "reset-password",
  [Links.Forgot]: "forgot-password",
  [Links.Billing]: "billing",
  [Links.Plans]: "plans",
  [Links.Payment]: "new-subscription",
  [Links.Clients]: "clients",
  [Links.ClientsAdd]: "clients",
  [Links.Unlock]: "unlock-account",
  [Links.Reconciliation]: "reconcilation",
  [Links.ReconciliationUncategorisedTransaction]:
    "reconcilation-uncategorised-transactions",
  [Links.ReconciliationMissingPriceIssues]:
    "reconcilation-missing-price-issues",
  [Links.ReconciliationNegativeBalanceIssues]:
    "reconcilation-negative-balance-issues",
  [Links.ReconciliationUnmatchedTransfer]: "reconcilation-unmatched-transfer",
  [Links.ReconciliationMissingBlockchain]: "reconciliation-missing-blockchain",
  [Links.PaymentStatus]: "payment",
  [Links.PaymentPending]: "payment-pending",
  [Links.Developer]: "developer",
  [Links.DeveloperSeedData]: "developer-seed-data",
  [Links.DeveloperEditorIntegration]: "developer-editor-integration",
  [Links.DeveloperEntities]: "developer-entities",
  [Links.DeveloperReport]: "developer-report",
  [Links.DeveloperSessionRecording]: "developer-session-recording",
  [Links.DeveloperUserData]: "developer-user-data",
  [Links.Alpha]: "alpha",
  [Links.OptInSupportAccess]: "opt-in-support-access",
  [Links.Ledger]: "ledger",
  [Links.Onboarding]: "onboarding",
  [Links.OnboardingSelectType]: "onboarding-select-type",
  [Links.OnboardingSelectTheme]: "onboarding-select-theme",
  [Links.OnboardingSelectCountry]: "onboarding-select-country",
  [Links.OnboardingWallets]: "onboarding-wallets",
  [Links.OnboardingImports]: "onboarding-imports",
  [Links.OnboardingImportsSearch]: "onboarding-imports-search",
  [Links.OnboardingImportsNew]: "onboarding-imports-new",
  [Links.OnboardingImportsCustom]: "onboarding-imports-custom",
  [Links.OnboardingPlatformTypes]: "onboarding-platform-types",
  [Links.OnboardingTradingTypes]: "onboarding-trading-types",
  [Links.OnboardingAccuracy]: "onboarding-accuracy",
  [Links.OnboardingTaxableTrades]: "onboarding-taxable-trades",
  [Links.OnboardingTaxableStaking]: "onboarding-taxable-staking",
  [Links.OnboardingImportsOauth]: "onboarding-imports-oauth",
  [Links.OnboardingAreYouSure]: "onboarding-are-you-sure",
  [Links.OnboardingImportCEXReminder]: "onboarding-import-cex-reminder",
  [Links.OnboardingImportsCalculating]: "onboarding-imports-calculating",
  [Links.OnboardingReconUncategorisedTransactions]:
    "onboarding-reconciliation-uncategorised-transactions",
  [Links.OnboardingOnYourWay]: "onboarding-on-your-way",
  [Links.OnboardingPayment]: "onboarding-subscription",
  [Links.OnboardingPaymentStatus]: "onboarding-payment-status",
  [Links.OnboardingPaymentSuccess]: "onboarding-payment-success",
  [Links.ContactsIdentified]: "contactsIdenitified",
  [Links.ContactsUnidentified]: "contactsUnidentified",
  [Links.Contacts]: "contacts",
  [Links.Rules]: "rules",
  [Links.AccountingIntegrations]: "accountingIntegrations",
  [Links.ReOnboarding]: "re-onboarding",
  [Links.ReOnboardingWelcomeBack]: "re-onboarding-welcome-back",
  [Links.ReOnboardingAccuracy]: "re-onboarding-accuracy",
  [Links.ReOnboardingTaxableTrades]: "re-onboarding-taxable-trades",
  [Links.ReOnboardingTaxableStaking]: "re-onboarding-taxable-staking",
  [Links.ReferAFriend]: "refer",
  [Links.RestoreUserData]: "restore-data",
  [Links.Close]: "close",
  [Links.OnboardingProviderWelcome]: "onboarding-provider-welcome",
  [Links.OnboardingProviderSetPassword]: "onboarding-provider-set-password",
  [Links.OnboardingProviderPasswordSetSuccess]:
    "onboarding-provider-password-set-success",
  [Links.OnboardingProviderSyncing]: "onboarding-provider-syncing",
  [Links.OnboardingEmbededOfferIntro]: "onboarding-embedded-offer-intro",
  [Links.ConnectProvider]: "connect-provider",
  [Links.PartnerBenefit]: "partner-benefit",
};

export const mostPopularPaidPersonalPlan = Plan.Investor;
export const mostPopularPaidExperimentPersonalPlan = Plan.InvestorUs;

export const CreditCardLabel: Record<CardBrand, string> = {
  [CardBrand.Amex]: "Amex",
  [CardBrand.Diners]: "Diners",
  [CardBrand.Discover]: "Discover",
  [CardBrand.JCB]: "JCB",
  [CardBrand.MasterCard]: "Master Card",
  [CardBrand.UnionPay]: "Union Pay",
  [CardBrand.Visa]: "Visa",
  [CardBrand.Unknown]: "Unknown",
};

export const CTC_LOGO_URL =
  "https://imagedelivery.net/i74borSBPB_SHtY369Spng/b0a9dae2-339e-4b37-ee0a-f7fd40588500/public";

export const IMAGE_PROXY_URL = `https://res.cloudinary.com/${import.meta.env.VITE_APP_CLOUDINARY_CLOUD}`;

/**
 * Note, the length of these 'popularExchangeLists' must be 12
 * otherwise the UI will look weird
 */
export const popularExchangesGlobal = [
  "metamask",
  "coinbase",
  "binance",
  "phantom",
  "cryptodotcom",
  "coinbase-wallet",
  "kucoin",
  "ledgerwallet",
  "blockchaindotcom",
  "kraken",
  "bybit",
  "cardano",
];

export const popularExchangesAU = [
  "metamask",
  "coinbase",
  "binance",
  "phantom",
  "cryptodotcom",
  "coinbase-wallet",
  "coinspot",
  "swyftx",
  "btcmarkets",
  "ledgerwallet",
  "coinjar",
  "independentreserve",
];

export const popularExchangesCA = [
  "metamask",
  "binance",
  "phantom",
  "ledgerwallet",
  "coinbase",
  "coinbase-wallet",
  "cryptodotcom",
  "shakepay",
  "newton",
  "ndax",
  "bitbuy",
  "coinsquare",
];

export const popularExchangesUK = [
  "metamask",
  "coinbase",
  "binance",
  "phantom",
  "cryptodotcom",
  "kucoin",
  "coinbase-wallet",
  "kraken",
  "uphold",
  "ledgerwallet",
  "blockchaindotcom",
  "bybit",
];

export const popularExchangesUS = [
  "metamask",
  "coinbase",
  "binanceus",
  "phantom",
  "cryptodotcom",
  "kucoin",
  "coinbase-wallet",
  "kraken",
  "bybit",
  "ledgerwallet",
  "blockchaindotcom",
  "cardano",
];

export const OAuthNames = {
  [OAuthProvider.Google]: "Google",
  [OAuthProvider.Xero]: "Xero",
  [OAuthProvider.Coinbase]: "Coinbase",
  [OAuthProvider.Moralis]: "Wallet",
  [OAuthProvider.IndependentReserve]: "Independent Reserve",
  [OAuthProvider.BitcoinDotComAu]: "bitcoin.com.au",
} as const;

export function isReferrerSource(
  value: string | null | undefined,
): value is ReferrerSource {
  if (value === null || value === undefined) {
    return false;
  }
  return (Object.values(ReferrerSource) as string[]).includes(value);
}

export const ReferrerSourceCouponCode: Record<
  ReferrerSource,
  CouponCode | undefined
> = {
  [ReferrerSource.Coinbase]: CouponCode.Coinbase30,
  [ReferrerSource.MetaMask]: CouponCode.MetaMaskCTC30,
  [ReferrerSource.CoinbaseOne]: CouponCode.CoinbaseOne40,
  [ReferrerSource.CoinbaseOnePremium]: undefined, // [FLEX-273: Handle coupons for coinbase one promotion](https://linear.app/ctc/issue/FLEX-273/handle-coupons-for-coinbase-one-promotion)
  [ReferrerSource.Coinstats]: CouponCode.Coinstats,
  [ReferrerSource.Coinjar]: CouponCode.Coinjar,
  [ReferrerSource.IndependentReserve]: undefined,
  [ReferrerSource.BitcoinDotComAu]: undefined,
  [ReferrerSource.MetamaskEmbedded]: CouponCode.MetaMaskEmbedded30,
  [ReferrerSource.CoinbaseEmbedded]: undefined,
  [ReferrerSource.KrakenEmbedded]: undefined,
  [ReferrerSource.BinanceEmbedded]: undefined,
  [ReferrerSource.ReferAFriend]: CouponCode.ReferAFriend20,
  [ReferrerSource.Phantom]: undefined, // todo: Add when we have coupon code
  [ReferrerSource.CTC]: undefined,
};

export const UserOverwritableErpAccounts = [
  ERPAccountType.Asset,
  ERPAccountType.Loan,
  ERPAccountType.Gain,
  ERPAccountType.PL,
  ERPAccountType.Cash,
];

// Give users the impression that we are doing something when "verifying"
export const secureConnectDelay = 1000;

// Captcha site keys
export const RECAPTCHA_SITE_KEY = [Env.Prod, Env.Staging].includes(
  import.meta.env.VITE_APP_ENV,
)
  ? Constants.Recaptcha
  : Constants.RecaptchaTest;

// If we're in dev/local, use a Cloudflare Turnstile test key to always pass the captcha
// See: https://developers.cloudflare.com/turnstile/troubleshooting/testing/
export const TURNSTILE_SITE_KEY =
  import.meta.env.VITE_APP_ENV === Env.Prod
    ? Constants.Turnstile
    : [Env.Dev, Env.Local].includes(import.meta.env.VITE_APP_ENV)
      ? "1x00000000000000000000AA"
      : Constants.TurnstileTest;
