import { type Blockchain } from "@ctc/types";
import { Close } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import { useContext } from "react";
import styled from "styled-components/macro";

import {
  type APIInputController,
  ImportApi,
  StyledImportTitleTypography,
} from "~/components/imports/api/index";
import { OAuthImport } from "~/components/imports/api/OAuthImport";
import { BlockchainSelector } from "~/components/imports/BlockchainSelector";
import { BulkWallet } from "~/components/imports/bulk-wallet/index";
import { ImportFormContext } from "~/components/imports/context/index";
import { isConnectWalletImportMethod } from "~/components/imports/helpers";
import { ImportErrorReport } from "~/components/imports/import-error-report-experiment/ImportErrorBox";
import { ImportCSV } from "~/components/imports/ImportCSV";
import { ImportSourceToggle } from "~/components/imports/ImportSourceToggle";
import { OnboardingNavBar } from "~/components/imports/OnboardingNavBar";
import { useInImportErrorReportExperiment } from "~/components/imports/useImportErrorReportExperiment";
import {
  ConnectWallet,
  isDerivedBlockchainImportMethod,
} from "~/components/imports/wallet/ConnectWallet";
import {
  Wallet,
  type WalletInputController,
} from "~/components/imports/wallet/index";
import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { useFormatPeriodDate } from "~/components/settings-modal/views/lock-period/useFormatPeriodDate";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { useIsOnboardingMobileImportExperiment } from "~/hooks/useIsOnboardingMobileImportExperiment";
import { useDesign } from "~/hooks/useTheme";
import { useCountry, useIsLockPeriodEnabled, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useCorrectImportOption } from "~/state/csvs";
import {
  accountHasImports,
  useGetSavedImportsByIntegration,
} from "~/state/importsV2";
import { useGetAllTaxPeriodsQuery, useLockPeriodEndDate } from "~/state/period";
import { ImportMethod, IntegrationCategory } from "~/types/enums";
import { type ImportMethodV2, type ImportOptionV2 } from "~/types/index";

export type ImportControllers = {
  bulkWalletInputController: WalletInputController;
  walletInputController: WalletInputController;
  apiInputController: APIInputController;
};

export function ImportFormInput({
  importMethod,
  importOption,
  controllers,
  updateSelectedLabel,
}: {
  importMethod: ImportMethodV2;
  importOption: ImportOptionV2;
  controllers: ImportControllers;
  updateSelectedLabel: (label: string) => void;
}) {
  const {
    walletInputController,
    apiInputController,
    bulkWalletInputController,
  } = controllers;
  const importFormContext = useContext(ImportFormContext);

  switch (importMethod.type) {
    case ImportMethod.CSV:
      // if not cleared we will import against the blockchain and not use the dedicated csv parser
      if (importOption.category === IntegrationCategory.Wallet) {
        importFormContext?.setSelectedBlockchain(undefined);
      }
      return (
        <ImportCSV
          importOption={importOption}
          importMethod={importMethod}
          updateSelectedLabel={updateSelectedLabel}
        />
      );
    case ImportMethod.ConnectWallet:
    case ImportMethod.Wallet:
      const blockchain =
        importOption.category === IntegrationCategory.Blockchain
          ? importOption.id
          : importFormContext?.selectedBlockchain;

      const isUnspecifiedBlockchainAllowed =
        !!importMethod.options?.isUnspecifiedBlockchainAllowed;

      const shouldIgnoreBlockchainSelectorOnConnectWallet =
        isDerivedBlockchainImportMethod(importMethod);

      const isBlockchainSelectorVisible =
        !isUnspecifiedBlockchainAllowed &&
        !shouldIgnoreBlockchainSelectorOnConnectWallet;

      if (
        (isUnspecifiedBlockchainAllowed && !isBlockchainSelectorVisible) ||
        shouldIgnoreBlockchainSelectorOnConnectWallet
      ) {
        // if the user doesn't need to specify a blockchain we treat the first as a default
        importFormContext?.setSelectedBlockchain(importMethod.blockchains[0]);
      }
      return (
        <>
          {isBlockchainSelectorVisible ? (
            <>
              <BlockchainSelector
                importOption={importOption}
                importMethod={importMethod}
              />
              {!blockchain && (
                <OnboardingNavBar
                  importOption={importOption}
                  importMethod={importMethod}
                />
              )}
            </>
          ) : null}
          {blockchain ? (
            <>
              {isConnectWalletImportMethod(importMethod) ? (
                <>
                  <ConnectWallet
                    importOption={importOption}
                    importMethod={importMethod}
                    blockchain={blockchain as Blockchain}
                  />
                  <OnboardingNavBar
                    importOption={importOption}
                    importMethod={importMethod}
                  />
                </>
              ) : (
                <Wallet
                  importOption={importOption}
                  importMethod={importMethod}
                  blockchain={blockchain as Blockchain}
                  controller={walletInputController}
                />
              )}
            </>
          ) : null}
        </>
      );
    case ImportMethod.OAuth:
      return (
        <>
          <OAuthImport importOption={importOption} />
          <OnboardingNavBar
            importOption={importOption}
            importMethod={importMethod}
          />
        </>
      );
    case ImportMethod.API:
      return (
        <ImportApi
          importOption={importOption}
          importMethod={importMethod}
          controller={apiInputController}
        />
      );
    case ImportMethod.BulkWallet:
      return (
        <BulkWallet
          importOption={importOption}
          importMethod={importMethod}
          controller={bulkWalletInputController}
        />
      );
  }

  return null;
}

export function ImportForm({
  importOption,
  importMethod,
  controllers,
  updateSelectedLabel,
}: {
  importOption: ImportOptionV2;
  importMethod: ImportMethodV2;
  controllers: ImportControllers;
  updateSelectedLabel: (label: string) => void;
}) {
  const user = useUser();
  const importFormContext = useContext(ImportFormContext);
  const { editingWalletAddress, handleCancelEdit: handleCancelWalletEdit } =
    controllers.walletInputController;
  const { editingApiId, handleCancelEdit: handleCancelApiEdit } =
    controllers.apiInputController;
  const isEditing = editingWalletAddress || editingApiId;
  const importOptionAccountingForBlockchain = useCorrectImportOption(
    importOption,
    importFormContext?.selectedBlockchain,
  );
  const savedImport = useGetSavedImportsByIntegration(
    importOptionAccountingForBlockchain.id,
  );
  const isOnboardingMobileImportExperiment =
    useIsOnboardingMobileImportExperiment();
  const lang = useLang();
  const isLockedPeriodEnabled = useIsLockPeriodEnabled();
  const isInImportErrorExperiment = useInImportErrorReportExperiment();

  const showAdditionalImportWrapper =
    savedImport &&
    accountHasImports(savedImport) &&
    !isOnboardingMobileImportExperiment;

  if (!user) {
    return null;
  }

  return (
    <StyledImportFormBox isDecorated={showAdditionalImportWrapper}>
      {isInImportErrorExperiment ? (
        <Box
          sx={{ marginBottom: "1rem", marginLeft: "0rem", paddingLeft: "0rem" }}
        >
          <ImportErrorReport
            issue={importFormContext?.importIssue}
            exchangeName={importOption.name}
          />
        </Box>
      ) : null}
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {showAdditionalImportWrapper || isEditing ? (
            <StyledImportTitleTypography>
              {isEditing
                ? lang.imports.editSource({ source: importOption.name })
                : lang.imports.addSource({ source: importOption.name })}
            </StyledImportTitleTypography>
          ) : null}
          {showAdditionalImportWrapper ? (
            <TextIconButton
              aria-label="close"
              onClick={() => {
                if (importMethod.type === ImportMethod.API) {
                  handleCancelApiEdit();
                }
                if (importMethod.type === ImportMethod.Wallet) {
                  handleCancelWalletEdit();
                }
                importFormContext?.setShowImportForm(false);
              }}
            >
              <Close sx={{ fontSize: "1rem" }} />
            </TextIconButton>
          ) : null}
        </Box>
        {!isEditing ? (
          <ImportSourceToggle
            importOption={importOption}
            importMethod={importMethod}
          />
        ) : null}
      </>
      <ImportFormInput
        importOption={importOption}
        importMethod={importMethod}
        controllers={controllers}
        updateSelectedLabel={updateSelectedLabel}
      />
      {isLockedPeriodEnabled ? <LockPeriodWarning /> : null}
    </StyledImportFormBox>
  );
}

const StyledImportFormBox = styled(({ isDecorated, ...props }) => (
  <Box {...props} />
))`
  ${({ isDecorated, theme }) =>
    isDecorated
      ? `
    background-color: ${theme.tokens.elevation.medium};
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid ${theme.tokens.border.neutral.default}
  `
      : ``}
`;

function LockPeriodWarning() {
  const formatPeriodDate = useFormatPeriodDate();
  const lang = useLang();
  const userCountry = useCountry();
  const lockedPeriods = useGetAllTaxPeriodsQuery();
  const lastLockedPeriod = useLockPeriodEndDate();
  const { setActive } = useSettingsModal();

  const { tokens } = useDesign();

  if (!lockedPeriods.data?.length) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap="0.25rem" mt="1rem">
      <Typography variant="Metropolis/Caption/Medium/Bold">
        {lang.imports.lastLockedSyncWarning.title}
      </Typography>
      <Typography variant="Metropolis/Caption/Medium/Regular">
        {lang.imports.lastLockedSyncWarning.body({
          date: formatPeriodDate({ date: lastLockedPeriod, userCountry }),
        })}
      </Typography>
      <Box>
        <Link
          onClick={() => {
            setActive(SettingsTabs.LockPeriod);
          }}
          component="button"
          underline="hover"
          width="fit-content"
        >
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            color={tokens.text.brand}
          >
            {lang.imports.lastLockedSyncWarning.action}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}
