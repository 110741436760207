import { LocalAnalytics } from "~/segment/localAnalytics";
import { SegmentAnalytics } from "~/segment/segmentAnalytics";
import {
  type CommonProperties,
  type GroupArgs,
  type GroupTraits,
  type IdentifyArgs,
  type IdentifyTraits,
  type PageArgs,
  type PageProperties,
  type TrackArgs,
  type TrackProperties,
} from "~/segment/types";
import { type UserInfo } from "~/types/index";

export interface AnalyticsClient {
  track(event: string, properties: TrackProperties): Promise<void>;
  page(name: string | undefined, properties: PageProperties): Promise<void>;
  identify(userId: string, traits: IdentifyTraits): Promise<void>;
  group(groupId: string, userId: string, traits: GroupTraits): Promise<void>;
  // Screen events for mobile and alias event for linking accounts not included.
  // https://segment.com/docs/connections/spec/screen/
  // https://segment.com/docs/connections/spec/alias/
}

export class Analytics {
  private client: AnalyticsClient;

  private constructor(client: AnalyticsClient) {
    this.client = client;
  }

  static async getInstance(): Promise<Analytics> {
    try {
      let client: AnalyticsClient;
      if (
        import.meta.env.VITE_APP_SEGMENT_WRITE_KEY &&
        import.meta.env.VITE_APP_ANALYTICS === "on"
      ) {
        client = await SegmentAnalytics.getInstance(
          import.meta.env.VITE_APP_SEGMENT_WRITE_KEY,
        );
      } else {
        client = LocalAnalytics.getInstance(
          import.meta.env.VITE_APP_ANALYTICS === "on",
        );
      }
      return new Analytics(client);
    } catch (e) {
      console.error(`Error initializing analytics: ${e}`);
      // using the LocalAnalytics as a fallback, but don't log anything to console
      console.error("Falling back to local analytics");
      const client = LocalAnalytics.getInstance(false);
      return new Analytics(client);
    }
  }

  /**
   * Analytics events that track an event happening.
   * e.g. xx_button_clicked, xx_image_loaded.
   * The event name must be unique for a long time, so please make it somewhat descriptive.
   * https://segment.com/docs/connections/spec/track/
   */
  track(track: TrackArgs): void {
    const props = Analytics.getTrackProps(track);
    this.client.track(track.event, props).catch((e) => {
      console.error(`Error sending track event: ${track.event}`, e);
    });
  }

  /**
   * To track when a particular page is seen.
   * The name should reflect the purpose of the page e.g. login or signup_v2.
   * https://segment.com/docs/connections/spec/page/
   */
  page(page: PageArgs): void {
    const props = Analytics.getPageProps(page);
    this.client.page(page.name, props).catch((e) => {
      console.error(`Error sending page event: ${page.name}`, e);
    });
  }

  /**
   * Used to identify the user after they login or if they change their user info.
   * e.g. signup, login, email change.
   * https://segment.com/docs/connections/spec/identify/
   */
  identify(identify: IdentifyArgs): void {
    const traits = Analytics.getIdentifyTraits(identify);
    this.client.identify(identify.user.uid, traits).catch((e) => {
      console.error(`Error sending identify event: ${identify.user.uid}`, e);
    });
  }

  /**
   * Used to link a user to a specific group.
   * e.g. link a client user to a parent accountant.
   * https://segment.com/docs/connections/spec/group/
   */
  group(group: GroupArgs): void {
    const groupId =
      group.user.parentProfileDetails?.uid ||
      group.user.adminProfileDetails?.adminProfile;
    if (!groupId) {
      return;
    }
    const traits = Analytics.getGroupTraits(group);
    this.client.group(groupId, group.user.uid, traits).catch((e) => {
      console.error(`Error sending group event: ${groupId}`, e);
    });
  }

  private static getTrackProps(track: TrackArgs): TrackProperties {
    return {
      ...Analytics.sanitizeProps(track.props),
      ...(track.user ? this.extractUserProps(track.user) : {}),
    };
  }

  private static getPageProps(page: PageArgs): PageProperties {
    return page.user ? this.extractUserProps(page.user) : {};
  }

  private static getIdentifyTraits(identify: IdentifyArgs): IdentifyTraits {
    const traits = this.extractUserProps(identify.user);
    return Analytics.sanitizeProps(traits);
  }

  private static getGroupTraits(group: GroupArgs): GroupTraits {
    const { userId, ...traits } = this.extractUserProps(group.user);
    return traits;
  }

  private static extractUserProps(user: UserInfo): Partial<CommonProperties> {
    return {
      // Properties of the user peforming the action.
      // If retail user, these come from the retail user's User object
      // If accountant is logged in, these come from the accountant's User object
      // If client is logged in, these come from the client's User object
      userId: user.uid,
      email: user.email,
      plan: user.paidPlan,
      parent_profile: user.parentProfileDetails?.uid,
      admin_profile: user.adminProfileDetails?.adminProfile,
      active_profile: user.activeProfileDetails?.uid,
      child_profile: user.childProfileDetails?.uid,
      country: user.country,
      referrerSource: user.referrerSource,

      // Properties of the user who the actions were performed on behalf of.
      // If retail user, these come from the retail user's DataSource object
      // If accountant or client, these come from the client's DataSource object
      isRetailUser: user.activeDataSource.isRetailUser,
    };
  }

  private static sanitizeProps(
    props: Record<string, any>,
  ): Record<string, any> {
    return Object.keys(props).reduce(
      (obj, key) => {
        if (props[key] !== undefined && props[key] !== null) {
          obj[key] = props[key];
        }
        return obj;
      },
      {} as Record<string, any>,
    );
  }
}
