import { divide, minus, multiply, plus } from "~/lib";

// Copied from BE

const VALUE_ERROR_TOLERANCE = 0.1; // 10% difference is allowed.

// Ensure the difference in total value is not too high
export function similarTotalFeeExclusive(
  inValue: number,
  outValue: number,
): boolean {
  const diff = Math.abs(minus(inValue, outValue));
  return !(diff && divide(diff, outValue) > VALUE_ERROR_TOLERANCE);
}

// Ensure the difference in total value is not too high after taking fees into account.
export function similarTotalFeeInclusive(
  inValue: number,
  outValue: number,
  feeValue: number,
): boolean {
  const inValueWithFee = plus(inValue, feeValue || 0);
  const outValueWithFee = minus(outValue, feeValue || 0);

  const diff = Math.abs(minus(outValueWithFee, inValueWithFee));

  const res = !(diff && divide(diff, outValueWithFee) > VALUE_ERROR_TOLERANCE);

  return res;
}

export function similarQuantity(inQuantity: number, outQuantity: number) {
  const feeLeeway = 0.05; // 5% fee leeway
  const feeIn = multiply(inQuantity, feeLeeway);
  const feeOut = multiply(outQuantity, feeLeeway);
  if (
    plus(inQuantity, feeIn) >= outQuantity &&
    minus(inQuantity, feeIn) <= outQuantity
  ) {
    // have a match
    return true;
  }
  if (
    plus(outQuantity, feeOut) >= inQuantity &&
    minus(outQuantity, feeOut) <= inQuantity
  ) {
    // have a match
    return true;
  }
  // no match
  return false;
}
