import { Plan } from "@ctc/types";

/**
 * Type guard to check if a string is a valid Plan enum value
 * @param value - The string value to check
 * @returns True if the value is a valid Plan enum value, false otherwise
 */
export const isPlan = (value: string): value is Plan => {
  return Object.values(Plan).includes(value as Plan);
};
