import {
  AccountBalanceWalletOutlined,
  Api,
  Cached,
  Delete,
  Description,
  Edit,
  MoreVert,
  OpenInNew,
} from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  type TooltipProps,
} from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import type * as React from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import {
  importAddIntegrationKey,
  importGlobalKey,
  useCaptureImportAnalytics,
} from "~/components/imports/AnalyticsHelpers";
import { useImportViewMode } from "~/components/imports/ImportViewMode";
import { AccountsTableContext } from "~/components/imports-v2/AccountsTable";
import { EditNicknameModal } from "~/components/imports-v2/EditNicknameModal";
import {
  getSavedAccountFilterRules,
  hasAvailableSyncMethods,
  isSyncPendingOrQueued,
} from "~/components/imports-v2/helpers";
import {
  type DrawerOptionsType,
  type MoreOptionsDialogProps,
} from "~/components/imports-v2/types";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useEntitiesQuery } from "~/state/entities";
import { useBulkSyncMutation } from "~/state/importsV2";
import {
  DeprecatedAPIs,
  FilterOperators,
  ImportMethod,
  ImportViewMode,
  IntegrationCategory,
  Links,
  Size,
} from "~/types/enums";
import { isBlockchainImport } from "~/types/index";

import { useIsEmbedded } from "../../hooks/useIsEmbedded";

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 1rem;
`;

export function MoreOptionsDropdown({
  options,
  size = Size.Small,
  moreVertIconColor,
  moreVertIconHoverColor,
  hoverBgColor,
  tooltipPlacement = "top",
}: {
  options: DrawerOptionsType[];
  size?: Size;
  moreVertIconColor?: string;
  tooltipPlacement?: TooltipProps["placement"];
  moreVertIconHoverColor?: string;
  hoverBgColor?: string;
}) {
  const { tokens } = useDesign();
  const captureAnalytics = useCaptureImportAnalytics();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-options",
  });

  return (
    <div
      className="more"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextIconButton
        size="small"
        {...bindTrigger(popupState)}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          e.stopPropagation();
          popupState.toggle(e);
        }}
        hoverBgColor={hoverBgColor}
        textColor={moreVertIconColor || tokens.icon.low}
        textHoverColor={moreVertIconHoverColor || tokens.icon.low}
      >
        <MoreVert fontSize={size} />
      </TextIconButton>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: "0.125rem" }}
      >
        <List
          sx={{ maxWidth: 300, bgcolor: tokens.elevation.low }}
          disablePadding
          dense
        >
          {options.map((item) => {
            return (
              <Tooltip
                key={item.label}
                title={item.tooltipTitle || ""}
                placement={tooltipPlacement}
              >
                <ListItem
                  button
                  disabled={item.disabled}
                  onClick={async (e: React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await item.handler();
                    captureAnalytics(importGlobalKey("more options"), {
                      action: item.id,
                    });
                    popupState.close();
                  }}
                  sx={{ gap: "0.5rem" }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      gap: ".5rem",
                    }}
                  >
                    {item.icon ? (
                      <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                    ) : null}
                    <ListItemText>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }}
                      >
                        {item.label}
                      </div>
                    </ListItemText>
                  </span>
                  {item.children ? item.children : null}
                </ListItem>
              </Tooltip>
            );
          })}
        </List>
      </Popover>
    </div>
  );
}

export const MoreOptionsImportOption = ({
  importOption,
  size = Size.Small,
}: MoreOptionsDialogProps) => {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const { viewMode } = useImportViewMode();
  const [open, setOpen] = useState(false);
  const bulkSyncMutation = useBulkSyncMutation(importOption);
  const navigate = useNavigate();
  const accountsTableContext = useContext(AccountsTableContext);
  const entities = useEntitiesQuery()?.data?.entities || [];
  const captureAnalytics = useCaptureImportAnalytics();
  const [isSyncHistoryOpen, setSyncHistoryOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewTxs = () => {
    const link = getTransactionPageLink({
      state: {
        filter: {
          type: FilterOperators.And,
          rules: getSavedAccountFilterRules(importOption, entities),
        },
      },
    });

    window.open(link, isEmbedded ? "_self" : "_blank", "noopener,noreferrer");
  };

  const handleSoftSync = () => {
    bulkSyncMutation.mutate({ hardSync: false });
  };

  const availableImportMethods = importOption.availableImportMethods;
  const hasCsvImportMethod = availableImportMethods.includes(ImportMethod.CSV);
  const hasApiImportMethod = availableImportMethods.includes(ImportMethod.API);
  const hasWalletImportMethod = availableImportMethods.includes(
    ImportMethod.Wallet,
  );

  const handleAdd = (importMethod: ImportMethod) => {
    if (importOption.category === IntegrationCategory.Manual) {
      navigate(`${Links.ImportCustom}/${importOption.id}`);
      return;
    }
    navigate(
      `${Links.Imports}/${importOption.id}?importMethod=${importMethod}`,
    );
  };

  const handleAddWrapped = (importMethod: ImportMethod) => {
    handleAdd(importMethod);
    captureAnalytics(importAddIntegrationKey, {
      context: "more_options",
      method: importMethod,
      exchange: importOption.id,
    });
  };
  const apiIsDeprecated = Object.values(DeprecatedAPIs).includes(
    importOption.id as DeprecatedAPIs,
  );

  const moreOptions: DrawerOptionsType[] = [
    {
      id: "view_transactions",
      label: lang.reconciliation.viewTransactions,
      icon: <OpenInNew fontSize="small" />,
      handler: handleViewTxs,
    },
    ...(viewMode === ImportViewMode.ByAddress &&
    isBlockchainImport(importOption) &&
    importOption.wallets.length > 0
      ? [
          {
            id: "nickname",
            label: lang.imports.dialogOptions.editNickname,
            icon: <Edit fontSize="small" />,
            handler: handleOpen,
          },
        ]
      : []),
    ...(hasAvailableSyncMethods(importOption)
      ? [
          {
            id: "sync",
            disabled: isSyncPendingOrQueued(importOption) || apiIsDeprecated,
            label: lang.imports.sync,
            icon: <Cached fontSize="small" />,
            handler: handleSoftSync,
          },
        ]
      : []),
    ...(hasCsvImportMethod
      ? [
          {
            id: "add_csv",
            label: lang.imports.dialogOptions.addCsv,
            icon: <Description fontSize="small" />,
            handler: () => {
              handleAddWrapped(ImportMethod.CSV);
            },
          },
        ]
      : []),
    ...(hasApiImportMethod
      ? [
          {
            id: "add_api",
            label: lang.imports.dialogOptions.addApi,
            icon: <Api fontSize="small" />,
            handler: () => {
              handleAddWrapped(ImportMethod.API);
            },
          },
        ]
      : []),
    ...(hasWalletImportMethod && viewMode === ImportViewMode.ByBlockchain
      ? [
          {
            id: "add_wallet",
            label: lang.imports.dialogOptions.addWallet,
            icon: <AccountBalanceWalletOutlined fontSize="small" />,
            handler: () => {
              handleAddWrapped(ImportMethod.Wallet);
            },
          },
        ]
      : []),
    // delete is on chain and exchange
    {
      id: "delete",
      label: lang.imports.dialogOptions.deleteAll,
      icon: <Delete fontSize="small" />,
      handler: () => {
        accountsTableContext?.setImportOptionToDelete(importOption);
      },
    },
  ];

  return (
    <>
      <MoreOptionsDropdown
        options={moreOptions}
        size={size}
        tooltipPlacement="left"
      />
      <EditNicknameModal
        open={open}
        handleClose={handleClose}
        importOption={importOption}
      />
    </>
  );
};
