import bitcoinDotComAuLogoDark from "~/assets/bitcoin-dot-com-au-named-logo-dark.svg";
import bitcoinDotComAuLogoLight from "~/assets/bitcoin-dot-com-au-named-logo-light.svg";
import { useResolvedTheme } from "~/hooks/useTheme";
import { Theme } from "~/types/enums";

export const BitcoinDotComAuLogo = ({
  themeOverride,
}: {
  themeOverride?: Theme;
}) => {
  const resolvedTheme = useResolvedTheme();
  const theme = themeOverride ?? resolvedTheme;
  return (
    <img
      src={
        theme === Theme.Light
          ? bitcoinDotComAuLogoLight
          : bitcoinDotComAuLogoDark
      }
      alt="bitcoin.com.au"
      style={{
        width: "170px",
        marginBottom: "-0.5rem",
      }}
    />
  );
};
