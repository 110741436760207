import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { Tooltip } from "@mui/material";
import sortBy from "lodash/sortBy";
import { useState } from "react";

import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { formatCategoryOption } from "~/components/transactions/command-palette/formatCategoryOption";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { Confirmation } from "~/components/transactions/command-palette/views/recategorise/Confirmation";
import { sortTradeTypeItems } from "~/components/transactions/helpers";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";
import { useSelectedIds } from "~/hooks/useTransactions";
import { invariant } from "~/lib/invariant";
import { useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import {
  filterGroupedTradeOptions,
  getTransactionCategoryOptionsByDirection,
  isGroupedTrade,
  isOutTrade,
} from "~/services/transactions";
import { useGetActionsQuery } from "~/state/actions";
import {
  GroupedTrade,
  TaxDivision,
  TradeDirection,
} from "~/types/enums";

export const ToCategory = () => {
  const lang = useLang();
  const { push } = useNavController();
  const taxSettings = useTaxSettings();
  const selectedIds = useSelectedIds();
  const query = useGetActionsQuery();
  const { tokens } = useDesign();

  const actions =
    query.data?.transactions.filter((action) =>
      selectedIds.includes(action._id),
    ) || [];

  // did they select a mix of ins, outs or groups?
  // if so, they can only change to a "pair"
  const selectedTypes = actions.reduce(
    (acc, action) => {
      if (isGroupedTrade(action.type)) {
        // Check if its exclusively outs or exclusively ins
        const ins = action.incoming.length > 0;
        const outs = action.outgoing.length > 0;
        const group = ins && outs;
        return {
          in: acc.in ? acc.in : ins,
          out: acc.out ? acc.out : outs,
          group: acc.group ? acc.group : group,
        };
      }
      if (isOutTrade(action.type)) {
        return {
          ...acc,
          out: true,
        };
      }
      // in trades
      return {
        ...acc,
        in: true,
      };
    },
    {
      in: false,
      out: false,
      group: false,
    },
  );

  const isSingleDirectionSelected =
    Object.values(selectedTypes).filter(Boolean).length === 1;

  const [direction, setDirection] = useState<
    Exclude<TradeDirection, TradeDirection.Unknown>
  >(
    isSingleDirectionSelected && selectedTypes.out
      ? TradeDirection.Out
      : TradeDirection.In,
  );

  if (!taxSettings) {
    return null;
  }

  const toTrade =
    isSingleDirectionSelected && !selectedTypes.group
      ? getTransactionCategoryOptionsByDirection({
          direction,
        })
      : // ins outs and groups selected, so they can only be groups
        filterGroupedTradeOptions(Object.values(GroupedTrade));

  const options = toTrade
    .flatMap((trade) => {
      const formatted = formatCategoryOption({
        trade,
        taxSettings,
        lang,
        showSubActionTypes: !isSingleDirectionSelected,
        tokens,
      });

      if (!formatted) {
        return [];
      }
      return [
        {
          ...formatted,
          section:
            formatted.taxDivision !== TaxDivision.Group 
              ? lang.taxDivision[formatted.taxDivision]
              : lang.taxDivision.group,
        },
      ];
    })
    .sort((a, b) => {
      invariant(
        a.taxDivision !== TaxDivision.Hidden,
        "Cannot sort hidden type",
      );

      invariant(
        b.taxDivision !== TaxDivision.Hidden,
        "Cannot sort hidden type",
      );
      const aItem = { label: a.label, taxDivision: a.taxDivision };
      const bItem = { label: b.label, taxDivision: b.taxDivision };

      return sortTradeTypeItems(aItem, bItem);
    });

  const handleDirectionClick = () => {
    setDirection((direction) => {
      return direction === TradeDirection.In
        ? TradeDirection.Out
        : TradeDirection.In;
    });
  };

  // Only show flip direction button if not in group
  const flipDirectionButton =
    isSingleDirectionSelected && !selectedTypes.group ? (
      <Tooltip
        title={lang.advancedTransactionOptions[direction].incorrectAction}
        placement="top"
      >
        <TextIconButton
          size="small"
          onClick={handleDirectionClick}
          sx={{ padding: "0.25rem" }}
        >
          <SwapHorizIcon sx={{ width: "1rem", height: "1rem" }} />
        </TextIconButton>
      </Tooltip>
    ) : null;

  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.Categorize} />
        </BulkEditChips>
      }
      options={sortBy(options, "section")}
      placeholder={lang.txTable.commandPallet.placeholders.toCategory}
      onSelection={({ trade }) => {
        push(<Confirmation toActionType={trade} />);
      }}
      searchAreaRightButton={flipDirectionButton}
    />
  );
};
