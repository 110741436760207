import { Box, Divider, Typography } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import styled from "styled-components/macro";

import { EventListeners } from "~/components/report/tax-loss-harvesting-modal/components/EventListeners";
import { WithoutMarketPriceToggle } from "~/components/report/tax-loss-harvesting-modal/components/WithoutMarketPriceToggle";
import { useIsMobile } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)`
  height: 2.25rem;
  padding: 0;
  background: ${({ theme }) => theme.tokens.background.input.default};
  margin-left: 0.25rem;
  & .MuiOutlinedInput-root {
    max-height: 2.25rem;
  }
  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.tokens.text.low};
  }
  & .MuiOutlinedInput-input {
    font-size: 0.875rem;
  }
  & .MuiOutlinedInput-input::placeholder {
    color: ${({ theme }) => theme.tokens.text.low};
    opacity: 1;
  }
`;

export function GridCustomToolbar() {
  const { tokens } = useDesign();
  const lang = useLang();
  const isMobile = useIsMobile();

  return (
    <GridToolbarContainer>
      <EventListeners />
      <Box
        display="flex"
        justifyContent={isMobile ? "left" : "space-between"}
        alignItems="center"
        width="100%"
        flexDirection={isMobile ? "column" : "row"}
      >
        <Box
          display="flex"
          alignItems="center"
          width={isMobile ? "100%" : undefined}
          justifyContent={isMobile ? "space-between" : "left"}
          gap="1.25rem"
        >
          <Typography
            sx={{
              fontSize: "0.875rem",
              color: tokens.text.high,
              fontWeight: 600,
              padding: "0.5rem 0 0.5rem 0.5rem",
            }}
          >
            {lang.taxLossHarvesting.table.title}
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ margin: 0 }}
          />
          <StyledGridToolbarQuickFilter
            variant="outlined"
            placeholder={lang.taxLossHarvesting.tableSearch}
          />
        </Box>

        <WithoutMarketPriceToggle />
      </Box>
    </GridToolbarContainer>
  );
}
