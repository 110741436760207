import SvgIcon, { type SvgIconProps } from "@mui/material/SvgIcon";

import { useDesign } from "~/hooks/useTheme";

export const SvgIconWrapper = ({ children, style, ...props }: SvgIconProps) => {
  const { tokens } = useDesign();
  return (
    <SvgIcon
      style={{
        color: tokens.icon.brand,
        fontSize: "1.25rem",
        ...(style || {}),
      }}
      viewBox="0 0 20 20"
      {...props}
    >
      {children}
    </SvgIcon>
  );
};
