import { type Analytics, AnalyticsBrowser } from "@segment/analytics-next";

import { type AnalyticsClient } from "~/segment";
import {
  type GroupTraits,
  type IdentifyTraits,
  type PageProperties,
  type TrackProperties,
} from "~/segment/types";

export class SegmentAnalytics implements AnalyticsClient {
  private static client?: SegmentAnalytics;

  private segment: Analytics;

  private constructor(segment: Analytics) {
    this.segment = segment;
  }

  static async getInstance(writeKey?: string): Promise<SegmentAnalytics> {
    if (SegmentAnalytics.client) {
      return SegmentAnalytics.client;
    } else if (!writeKey) {
      throw new Error("No writeKey provided.");
    }

    const segment = await AnalyticsBrowser.standalone(writeKey);
    SegmentAnalytics.client = new SegmentAnalytics(segment);
    return SegmentAnalytics.client;
  }

  track(event: string, allProps: TrackProperties) {
    const handleTrack = async () => {
      await this.segment.track(event, allProps);
    };
    return handleTrack();
  }

  page(name: string | undefined, allProps: PageProperties) {
    const handlePage = async () => {
      const { userId, ...properties } = allProps;
      await this.segment.page(undefined, name, { ...properties, userId });
    };
    return handlePage();
  }

  identify(userId: string, traits: IdentifyTraits) {
    const handleIdentify = async () => {
      await this.segment.identify(userId, traits);
    };
    return handleIdentify();
  }

  group(groupId: string, userId: string, traits: GroupTraits) {
    const handleGroup = async () => {
      await this.segment.group(groupId, { userId, ...traits });
    };
    return handleGroup();
  }
}
