import {
  Box,
  type BoxProps,
  Button,
  type ButtonProps,
  Tooltip,
  type TooltipProps,
  Typography,
  type TypographyProps,
} from "@mui/material";
import * as React from "react";

/* Each of these components can be used in the same way as their regular counterparts.
 * The censored versions will censor the components content in Hotjar recordings.
 * These components can be used to ensure all PII is censored. 
 * /


/* Known issue that ButtonProps doesn't contain button components.
 * Workaround has been used as per:
 * https://github.com/mui-org/material-ui/issues/15827#issuecomment-809209533
 */
type CensoredForwardRefButtonType = <C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }>,
  ref?: React.Ref<HTMLButtonElement>,
) => React.ReactElement;

const CensoredButtonFnc: CensoredForwardRefButtonType = (props, ref) => {
  const { children, ...rest } = props;
  return (
    <span data-hj-suppress className="ph-no-capture">
      <Button ref={ref} {...rest}>
        {children}
      </Button>
    </span>
  );
};

/* Use this in place of a regular button when the buttons content should be censored.
 * The buttons content will be shown as *** in Hotjar recordings.
 */
export const CensoredButton = React.forwardRef(
  CensoredButtonFnc,
) as CensoredForwardRefButtonType;

/* Use this in place of a regular typography when the content should be censored.
 * The typography content will be shown as *** in Hotjar recordings.
 */
export const CensoredTypography = ({
  children,
  ...typographyProps
}: React.PropsWithChildren & Omit<TypographyProps, "children">) => {
  return (
    <Typography {...typographyProps}>
      <span data-hj-suppress className="ph-no-capture">
        {children}
      </span>
    </Typography>
  );
};

/* Use this in place of a regular Tooltip when the popup's content should be censored.
 * The popup's content will be shown as *** in Hotjar recordings.
 * The child elements that the tooltip is wrapping will not be censored.
 */
export const CensoredTooltip = ({
  children,
  title,
  ...tooltipProps
}: {
  children: React.ReactElement;
  title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
} & Omit<TooltipProps, "children" | "title">) => {
  // Inline fragment for title required to ensure text is censored.
  return (
    <Tooltip
      title={
        title ? (
          <span data-hj-suppress className="ph-no-capture">
            {title}
          </span>
        ) : (
          ""
        )
      }
      {...tooltipProps}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

// Use this in place of a regular box when the content should be censored.
// The box's content and children will be censored in Hotjar recordings.
export const CensoredBox = React.forwardRef<
  HTMLSpanElement,
  { children: React.ReactNode } & Omit<BoxProps, "children">
>(({ children, ...boxProps }, ref) => {
  return (
    // Now the ref is passed to the span, which is the actual DOM element the Tooltip can bind to.
    // If Box component supports forwarding refs and you want to attach the ref to the Box's root element,
    // you can pass the ref directly to the Box instead.
    <span ref={ref} data-hj-suppress className="ph-no-capture">
      <Box {...boxProps}>{children}</Box>
    </span>
  );
});
