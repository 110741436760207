import {
  ActionHeaderSide,
  ActionRowSide,
} from "~/components/transactions/action-row/ActionRowSide";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { Side } from "~/types/enums";

export function ActionHeaderFrom() {
  return <ActionHeaderSide side={Side.From} />;
}

export function ActionRowFrom({ ...props }: ActionRowCellProps) {
  return <ActionRowSide {...props} side={Side.From} />;
}
