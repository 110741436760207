import { LoadingButton } from "@mui/lab";
import { Button, type ButtonProps } from "@mui/material";
import { type ComponentPropsWithRef } from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import styled from "styled-components/macro";

const styling = css`
  && {
    color: ${({ theme }) => theme.tokens.text.white};
    background-color: ${({ theme }) => theme.tokens.button.brand.default};
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: ${({ theme }) => theme.tokens.buttons.primary.borderRadius};
    justify-content: ${({ theme }) => theme.tokens.buttons.primary.alignment};
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.08),
      0 2px 5px rgba(31, 41, 55, 0.12),
      inset 0 -1px 1px rgba(0, 0, 0, 0.12);
    border: 1px rgba(0, 0, 0, 0) solid;
  }

  & .MuiButton-sizeMedium {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  & .MuiButton-sizeSmall {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.tokens.button.brand.hover};
  }

  &.Mui-focusVisible {
    background-color: ${({ theme }) => theme.tokens.button.brand.pressed};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.text.disabled};
    background-color: ${({ theme }) => theme.tokens.button.disabled};
    box-shadow: 0 0;
  }
`;

const PrimaryButtonStyled = styled(Button)`
  ${styling}
`;

const PrimaryLoadingButtonStyled = styled(LoadingButton)`
  ${styling}
`;

const PrimaryButtonLinkStyled = styled(
  (
    props: ComponentPropsWithRef<typeof Link> &
      ComponentPropsWithRef<typeof Button>,
  ) => <Button component={Link} {...props} />,
)`
  ${styling}
`;

const PrimaryLoadingButtonLinkStyled = styled(
  (
    props: ComponentPropsWithRef<typeof Link> &
      ComponentPropsWithRef<typeof LoadingButton>,
  ) => <LoadingButton component={Link} {...props} />,
)`
  ${styling}
`;

const PrimaryButtonLinkExternalStyled = styled(
  (props: ComponentPropsWithRef<"a"> & ButtonProps) => (
    <Button component="a" {...props} />
  ),
)`
  ${styling}
`;

/**
 * Primary button component that automatically applies pill styling and center alignment when embedded
 */
export const PrimaryButton = (props: ButtonProps) => {
  return <PrimaryButtonStyled {...props} />;
};

/**
 * Primary loading button component that automatically applies pill styling and center alignment when embedded
 */
export const PrimaryLoadingButton = (
  props: ComponentPropsWithRef<typeof LoadingButton>,
) => {
  return <PrimaryLoadingButtonStyled {...props} />;
};

/**
 * Primary button link component that automatically applies pill styling and center alignment when embedded
 */
export const PrimaryButtonLink = (
  props: ComponentPropsWithRef<typeof Link> &
    ComponentPropsWithRef<typeof Button>,
) => {
  return <PrimaryButtonLinkStyled {...props} />;
};

/**
 * Primary loading button link component that automatically applies pill styling and center alignment when embedded
 */
export const PrimaryLoadingButtonLink = (
  props: ComponentPropsWithRef<typeof Link> &
    ComponentPropsWithRef<typeof LoadingButton>,
) => {
  return <PrimaryLoadingButtonLinkStyled {...props} />;
};

/**
 * Primary button external link component that automatically applies pill styling and center alignment when embedded
 */
export const PrimaryButtonLinkExternal = (
  props: ComponentPropsWithRef<"a"> & ButtonProps,
) => {
  return <PrimaryButtonLinkExternalStyled {...props} />;
};
