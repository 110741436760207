import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import upperFirst from "lodash/upperFirst";
import { useDispatch } from "react-redux";

import { setAddClientSuccess } from "~/redux/accountant";
import { useAuth, useIsManagingClients, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import * as accountantApi from "~/services/accountant";
import { HttpError } from "~/services/core";
import * as invitationsApi from "~/services/invitations";
import { InvitationType } from "~/types/enums";
import { type ClientPayload } from "~/types/index";

export const clientKeys = {
  all: () => ["clients"] as const,
  list: () => [...clientKeys.all(), "list"] as const,
  createClient: () => [...clientKeys.all(), "createClient"] as const,
};

export function useClientList() {
  const user = useUser();
  const isManagingClients = useIsManagingClients();
  const lang = useLang();

  return useQuery({
    queryKey: clientKeys.list(),
    queryFn: async () => {
      const res = await accountantApi.getClientList();
      if (res.error) {
        throw new HttpError(res, ["getClientList"]);
      }

      return res.data;
    },
    enabled: !!user && isManagingClients,
  });
}

export function useCreateClientMutation() {
  const lang = useLang();
  const auth = useAuth();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: clientKeys.createClient(),
    mutationFn: async (payload: ClientPayload) => {
      const res = await accountantApi.createClient(payload);

      if (res.error) {
        throw new Error(res.msg ?? lang.accountant.addClientError);
      }

      const { uuid: clientProfile } = res.data;
      // automatically invite the client if email is given
      const { email } = payload as ClientPayload;
      if (email) {
        const invitationRes = await invitationsApi.createInvitation({
          fromName: auth.user?.email,
          toEmail: email,
          invitationType: InvitationType.Client,
          clientProfile,
        });

        if (invitationRes.error) {
          throw new Error(
            upperFirst(invitationRes.msg) || lang.errorMessageGeneric,
          );
        }
      }
      return { clientProfile };
    },
    onSuccess: async (data, variables: ClientPayload) => {
      dispatch(setAddClientSuccess({ visible: true }));
      const { setActiveClient } = variables;
      setActiveClient(data.clientProfile);
      await queryClient.invalidateQueries({ queryKey: clientKeys.all() });
    },
  });
}
