import { Box, Slider, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import { type SyntheticEvent, useState } from "react";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

type PriceWindowSelectorProps = {
  value: number;
  onChange: (priceWindow: number) => void;
  onSave: (priceWindow: number) => void;
};

export function PriceWindowSelector({
  value,
  onChange,
  onSave,
}: PriceWindowSelectorProps) {
  const { tokens } = useDesign();
  const lang = useLang();
  const [localValue, setLocalValue] = useState(value);

  const debouncedChangeHandler = debounce(onChange, 300);

  const handleChange = (_event: Event, value: number | number[]) => {
    const val = Array.isArray(value) ? value[0] : value;

    debouncedChangeHandler(val);
    setLocalValue(val);
  };

  const handleSave = (
    _event: Event | SyntheticEvent<Element, Event>,
    value: number | number[],
  ) => {
    const val = Array.isArray(value) ? value[0] : value;

    onSave(val);
  };

  return (
    <Box>
      <Typography variant="Metropolis/Header/H5">
        {lang.settings.priceWindow.title}
      </Typography>
      <Typography
        mt="1rem"
        sx={{
          fontWeight: 400,
          fontSize: "0.875rem",
          color: tokens.text.low,
        }}
      >
        {localValue === 0
          ? lang.settings.priceWindow.off
          : lang.settings.priceWindow.on({
              value: localValue,
            })}
      </Typography>
      <Box mt="1rem">
        <Slider
          min={0}
          max={24}
          marks
          value={localValue}
          valueLabelDisplay="auto"
          onChange={handleChange}
          onChangeCommitted={handleSave}
        />
      </Box>
    </Box>
  );
}
