import React from "react";

import { OnboardingAccountMenu } from "~/components/onboarding-v2/OnboardingAccountMenu";
import { OnboardingTitle } from "~/components/onboarding-v2/OnboardingTitle";
import { OnBoardingWindow } from "~/components/onboarding-v2/ui/OnBoardingWindow";
import { Partnership } from "~/components/ui/Partnership";

import { type ProviderConfig } from "../onboarding-v2/provider/types";

export function ProviderOnboardingBox({
  providerConfig,
  title,
  children,
}: {
  providerConfig: ProviderConfig;
  title?: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <OnboardingAccountMenu hideAccountList />
      <OnBoardingWindow
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        p="0.5rem"
        gap="1rem"
      >
        <Partnership
          partnerSrcLight={providerConfig.nameLogoLight}
          partnerSrcDark={providerConfig.nameLogoDark}
          partnerAlt={providerConfig.name}
          disableHeader
        />
        {title ? <OnboardingTitle mb="0.5rem">{title}</OnboardingTitle> : null}
        {children}
      </OnBoardingWindow>
    </>
  );
}
