import { Autocomplete, TextField, type TextFieldProps } from "@mui/material";
import {
  Controller,
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
} from "react-hook-form";

import { getErrorHelperText } from "~/components/contacts/getErrorHelperText";
import { useLang } from "~/redux/lang";

type LabelProps<TValue> = TValue extends string
  ? {
      getOptionLabel?: (value: TValue) => string;
    }
  : {
      getOptionLabel: (value: TValue) => string;
    };

export function FormAutocomplete<
  TValue,
  // copied from UseControllerProps
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  options,
  disabled,
  loading,
  getOptionLabel = (value: TValue) => `${value}`,
  ...controllerProps
}: UseControllerProps<TFieldValues, TName> & {
  loading?: boolean;
  label: string;
  options: TValue[];
} & Pick<TextFieldProps, "disabled"> &
  LabelProps<TValue>) {
  const lang = useLang();

  return (
    <Controller
      {...controllerProps}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // match the option to the value stored
        return (
          <Autocomplete
            loading={loading}
            value={value}
            multiple={false}
            disabled={disabled}
            onChange={(e, option) => {
              onChange(option);
            }}
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                helperText={getErrorHelperText(error, lang)}
                size="small"
                error={!!error}
                label={label}
                variant="outlined"
                style={{ margin: "0.5rem 0" }}
              />
            )}
          />
        );
      }}
    />
  );
}
