import { Box, Stack, Typography } from "@mui/material";

import { Comment } from "~/components/transactions/action-row/actionCommentsBox";
import { Chip } from "~/components/ui/Chips";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useActionCommentsQuery } from "~/state/actions";
import { type ActionRow } from "~/types/index";

export function RecentComments({ row }: { row: ActionRow }) {
  const { data } = useActionCommentsQuery(row._id);
  const { tokens } = useDesign();
  const comments = data ?? row.comments ?? [];

  const commentCount = comments.length;
  const recentComments = comments
    ?.sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    )
    .slice(0, 2);
  const recentCommentsCount = recentComments.length;
  const moreCount = Math.max(commentCount - recentCommentsCount, 0);
  const lang = useLang();
  return (
    <Box
      sx={{
        width: comments.length === 0 ? "10rem" : "25rem",
        transition: "width 0.5ms ease-out",
        padding: "1rem",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="Metropolis/Body/Bold">
          {lang.txTable.recentComments}
        </Typography>
        {moreCount ? (
          <Chip bgcolor={tokens.background.accent.neutral.lowest}>
            {lang.txTable.recentCommentsMore({ count: moreCount })}
          </Chip>
        ) : null}
      </Stack>
      <Stack direction="column" marginTop="0.5rem">
        {recentComments?.length === 0 ? (
          <div>
            <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
              {lang.txTable.noComments}
            </Typography>
          </div>
        ) : null}
        {recentComments?.map((comment, index) => {
          return (
            <Box
              borderTop={`1px solid ${tokens.border.neutral.high}`}
              paddingTop="0.5rem"
              paddingBottom={
                index !== (recentComments?.length ?? 0) - 1 ? "0.5rem" : 0
              }
              key={comment._id}
            >
              <Comment
                actionId={row._id}
                comment={comment}
                commentListLength={commentCount}
                editControls={false}
                lineClamp
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}
