import { Box, Switch, Typography } from "@mui/material";
import { useState } from "react";

import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { Spinner } from "~/components/ui/Spinner";
import { useUser } from "~/redux/auth";
import {
  useArchiveStatus,
  useArchiveUser,
  useRestoreUser,
} from "~/state/archiveUser";
import { useSetOnboardingStatus } from "~/state/developer";

export function UserData() {
  const user = useUser();
  const { mutate: updateOnboardingStatus } = useSetOnboardingStatus();
  const isOnboarding = user?.isOnboarding ?? true; // no user means we are in onboarding
  const handleOnboardingToggle = () => {
    updateOnboardingStatus(!isOnboarding);
  };

  return (
    <Box
      display="flex"
      gap="0.5rem"
      flexDirection="column"
      component="form"
      px={{ xs: 2, sm: 0 }}
      mt={4}
    >
      <Typography variant="Metropolis/Header/H4">User Data</Typography>
      <Typography variant="Metropolis/Body/Bold">
        Change the users state in the DB
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="start"
        alignItems="center"
      >
        <Switch checked={isOnboarding} onChange={handleOnboardingToggle} />
        <Typography variant="Metropolis/Body/Bold">Is Onboarding</Typography>
      </Box>
      <UserArchive />
    </Box>
  );
}

const UserArchive = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: archiveUser } = useArchiveUser();
  const { mutateAsync: restoreUserData } = useRestoreUser();
  const { data: archiveStatus } = useArchiveStatus();

  return (
    <>
      <Typography variant="Metropolis/Body/Bold">Archive User</Typography>
      {archiveStatus === undefined && <Spinner />}
      {archiveStatus === "active" && (
        <PrimaryLoadingButton
          loading={isLoading}
          onMouseDown={async () => {
            setIsLoading(true);
            await archiveUser();
            setIsLoading(false);
          }}
          sx={{ width: "10rem" }}
        >
          Archive User
        </PrimaryLoadingButton>
      )}
      {archiveStatus === "archived" && (
        <PrimaryLoadingButton
          loading={isLoading}
          onMouseDown={async () => {
            setIsLoading(true);
            await restoreUserData();
            setIsLoading(false);
          }}
          sx={{ width: "10rem" }}
        >
          Restore User
        </PrimaryLoadingButton>
      )}
      {(archiveStatus === "archiving" ||
        archiveStatus === "restoring" ||
        archiveStatus === "dirty-archive") && (
        <Typography variant="Metropolis/Body/Bold">
          Archiving not available ({archiveStatus})
        </Typography>
      )}
    </>
  );
};
