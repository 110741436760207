import { css } from "styled-components/macro";

import { ActionTableGridArea } from "~/components/transactions/action-row/enums";

const columnSizes: Record<ActionTableGridArea, string> = {
  [ActionTableGridArea.Type]: "minmax(10rem, 6fr)",
  [ActionTableGridArea.Time]: "7.5rem",
  [ActionTableGridArea.From]: "minmax(0, 5fr)",
  [ActionTableGridArea.To]: "minmax(0, 5fr)",
  [ActionTableGridArea.Fee]: "5rem",
  [ActionTableGridArea.LedgerFee]: "minmax(0, 5fr)",
  [ActionTableGridArea.Value]: "7.5rem",
  [ActionTableGridArea.Gain]: "7.5rem",
  [ActionTableGridArea.AccountBalanceIn]: "minmax(0, 5fr)",
  [ActionTableGridArea.AccountBalanceOut]: "minmax(0, 5fr)",
  [ActionTableGridArea.OverallBalance]: "minmax(0, 5fr)",
  [ActionTableGridArea.Actions]: "7.5rem",
  [ActionTableGridArea.Spacer]: "6.25rem",
  [ActionTableGridArea.Transaction]: "minmax(0, 4fr)",
  [ActionTableGridArea.Similar]: "minmax(0, 5rem)",
  [ActionTableGridArea.Counterparty]: "8rem",
  [ActionTableGridArea.CTA]: "12rem",
};

export const scrollColumnSizes: Record<ActionTableGridArea, string> = {
  [ActionTableGridArea.Type]: "16rem",
  [ActionTableGridArea.Time]: "7.5rem",
  [ActionTableGridArea.From]: "13rem",
  [ActionTableGridArea.To]: "13rem",
  [ActionTableGridArea.Fee]: "6.25rem",
  [ActionTableGridArea.LedgerFee]: "13rem",
  [ActionTableGridArea.Value]: "7.5rem",
  [ActionTableGridArea.Gain]: "7.5rem",
  [ActionTableGridArea.AccountBalanceIn]: "11.75rem",
  [ActionTableGridArea.AccountBalanceOut]: "11.75rem",
  [ActionTableGridArea.OverallBalance]: "11.75rem",
  [ActionTableGridArea.Actions]: "7.5rem",
  [ActionTableGridArea.Spacer]: "6.25rem",
  [ActionTableGridArea.Transaction]: "25rem",
  [ActionTableGridArea.Similar]: "5rem",
  [ActionTableGridArea.Counterparty]: "8rem",
  [ActionTableGridArea.CTA]: "12rem",
};

export const getColumnSizeCss = (
  columnSizes: Record<ActionTableGridArea, string>,
  columns: ActionTableGridArea[],
) => {
  return columns
    .map((column) => {
      return columnSizes[column];
    })
    .join(" ");
};

export const transactionActionRowLayoutStyles = css<{
  columns: ActionTableGridArea[];
  defaultSizeOverrides?: { [key in ActionTableGridArea]?: string };
  // the min width the screen needs to be to fit the table without scrolling
  minScreenSizeForContent: number;
}>`
  display: grid;
  column-gap: 0.5rem;
  grid-template-rows: auto;
  flex-direction: row;
  grid-template-areas: "${(props) => props.columns.join(" ")}";

  @media ${(props) => `(min-width:${props.minScreenSizeForContent}px)`} {
    grid-template-columns: ${(props) =>
      getColumnSizeCss(
        { ...columnSizes, ...props.defaultSizeOverrides },
        props.columns,
      )};
  }
  grid-template-columns: ${(props) =>
    getColumnSizeCss(
      { ...scrollColumnSizes, ...props.defaultSizeOverrides },
      props.columns,
    )};
`;
