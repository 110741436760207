import {
  IrsReportDownloadType,
  NormalReportType,
  TaxActReportType,
  TurboTaxReportType,
} from "~/types/enums";
import { type ReportDownloadType } from "~/types/index";

const defaultSelfReportingReports = new Set<ReportDownloadType>([
  NormalReportType.ReportSummary,
  NormalReportType.CapitalGains,
  NormalReportType.Income,

  TurboTaxReportType.TurboTaxTxf,
  TurboTaxReportType.TurboTaxCsv,
  TurboTaxReportType.CanadianTurboTaxPdf,
  TaxActReportType.TaxActCsv,

  IrsReportDownloadType.IRS_8949,
  IrsReportDownloadType.IRS_1040S1,
  IrsReportDownloadType.IRS_1040SD,

  NormalReportType.MyGov,
]);

/*
 * Returns whether the report belongs in the "Self" or "Accountant" category.
 * At the moment, any reports that aren't in the "Self" list above
 * are considered "Accountant"
 */
export const getReportCategory = (report: ReportDownloadType) => {
  return defaultSelfReportingReports.has(report) ? "Self" : "Accountant";
};
