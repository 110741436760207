export enum AddressVariant {
  Contained = "contained",
  Text = "text",
}
export enum AddressChipStyle {
  Default = "default",
  Raised = "raised",
  Button = "button",
  Text = "text",
}
export enum FlexDirection {
  Column = "column",
  Inherit = "inherit",
  MozInternal = "-moz-initial",
  Initial = "initial",
  Revert = "revert",
  Unset = "unset",
  ColumnReverse = "column-reverse",
  Row = "row",
  RowReverse = "row-reverse",
}
export enum BannerVariant {
  List = "list",
  Row = "row",
}
export enum BannerSeverity {
  Info = "info",
  Notice = "notice",
  Warning = "warning",
  Error = "error",
}
export enum DateFormat {
  String = "string",
  Date = "date",
  Number = "number",
}
export enum DateTime {
  Start = "start",
  End = "end",
}
export enum AddressLink {
  Address = "address",
  Tx = "tx",
}
export enum BlockchainSymbolPosition {
  TopRight = "top-right",
  BottomRight = "bottom-right",
}
export enum ChipColor {
  GREEN = "green",
  YELLOW = "yellow",
  RED = "red",
  GREY = "grey",
  DARKER = "darker",
}
export enum StatusType {
  Warning = "warning",
  Error = "error",
}
