import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Grid,
  Link,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import isNil from "lodash/isNil";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components/macro";

import { CurrencyComboBox } from "~/components/transactions/CurrencyComboBox";
import { nameMap } from "~/components/transactions/edit/EditActionAmounts";
import { FEE_FIELD_ARRAY_NAME } from "~/components/transactions/edit/EditActionFee";
import { PriceField } from "~/components/transactions/edit/PriceField";
import { TxValueReferenceCurrencySelector } from "~/components/transactions/edit/TxValueReferenceCurrencySelector";
import {
  validateGreaterZeroValue,
  validatePositiveValue,
} from "~/components/transactions/helpers";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";
import { blurTarget } from "~/lib/blurCurrentTarget";
import { divide, middleTrim, multiply, safeFloat } from "~/lib/index";
import { useIsMarkAsNFTEnabled, useLocalCurrency } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  isCurrencyNFTForTaxPurposes,
  isMarkAsNFTEnabledForCurrency,
} from "~/services/currencies";
import { Direction, QuickEditFocusField } from "~/types/enums";
import { type CurrencyIdentifier } from "~/types/index";

export type TxAmount = {
  txId: string;
  timestamp: string;
  markIgnoreMissingPrice: boolean;
  currency?: CurrencyIdentifier | string;
  quantity?: string;
  price?: string;
  value?: string;
  priceValueCurrency?: string;
  // links a tx to a fee tx
  groupById?: string | null;
  blockchain: string | undefined;
};

const StyledGrid = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "top",
  flexWrap: "nowrap",
  "& > div:not(:first-child):not(:last-child) .MuiOutlinedInput-notchedOutline":
    {
      borderRadius: 0,
    },
  "& > div:not(:last-child) div:not(.Mui-focused):not(div:hover):not(.Mui-error) .MuiOutlinedInput-notchedOutline":
    {
      borderRight: "none",
    },
  "& > div:first-child .MuiOutlinedInput-notchedOutline": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  "& > div:last-child .MuiOutlinedInput-notchedOutline": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

/**
 * Sum the values of the transactions.
 */
function getValuesTotal(amounts: { value: string }[]) {
  const newTotalValue = amounts.reduce((sum: number, item: any) => {
    return sum + safeFloat(item.value || 0);
  }, 0);
  return newTotalValue;
}

// TODO: remove currencies that are the same as the transactions currency
export const EditActionTxAmount = ({
  index,
  showMissingPriceWarning = false,
  name,
  side,
  onRemove,
  showPriceValueSelector = true,
  shouldLinkValue = false,
  modifyQuantity = false,
  showLookupMarketPrice = false,
  focusField,
  timestamp,
  blockchain,
}: {
  index: number;
  showMissingPriceWarning?: boolean;
  name: string;
  side: Direction;
  showPriceValueSelector?: boolean;
  shouldLinkValue?: boolean;
  modifyQuantity?: boolean;
  onRemove?: () => void;
  showLookupMarketPrice?: boolean;
  focusField?: QuickEditFocusField;
  timestamp: string | undefined;
  blockchain: string | undefined;
}) => {
  const localCurrency = useLocalCurrency();
  const { control, watch, trigger, getValues, setValue, setFocus } =
    useFormContext();
  const txData = watch(`${name}.${index}`);

  // Timestamp can come from a forced value as a prop or a linked value in form.
  const timestampResolved = watch("timestamp") || timestamp;

  const priceValueCurrencyLabel = txData.priceValueCurrency
    ? `(${txData.priceValueCurrency})`
    : `(${localCurrency})`;

  const { tokens } = useDesign();
  const lang = useLang();

  // This state is for optimistically hiding the warning text when the user clicks the "Ignore" button
  const [hideWarning, setHideWarning] = useState(false);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const {
    currencyName = "",
    currencySymbol = "",
    currencyId = null,
  } = txData.currency
    ? typeof txData.currency === "string"
      ? {
          currencyName: txData.currency,
          currencySymbol: txData.currency,
        }
      : {
          currencyName: txData.currency.name,
          currencySymbol: txData.currency.symbol,
          currencyId: txData.currency.id,
        }
    : {};

  const shouldShowNFTToggle =
    useIsMarkAsNFTEnabled() && isMarkAsNFTEnabledForCurrency(currencyId);
  const isMarkedAsNFT = watch(`updateMarkAsNFT.${currencyId}`);

  const [storedLocalCurrencyPriceValue, setStoredLocalCurrencyPriceValue] =
    useState<{ price: string; value: string } | undefined>();

  const handleMarkIgnoreMissingPrice = () => {
    setHideWarning(true);

    setValue(
      `${name}.${index}.markIgnoreMissingPrice`,
      !txData.markIgnoreMissingPrice,
      {
        shouldValidate: false,
        shouldDirty: true,
      },
    );
  };

  const onQuantityChange = (quantity: string) => {
    setValue(`${name}.${index}.quantity`, quantity, {
      shouldValidate: true,
      shouldDirty: true,
    });

    const price = txData.price;

    const totalValue = txData.value;

    if (price.length > 0 && quantity.length > 0) {
      const value = multiply(safeFloat(price), safeFloat(quantity));
      setValue(`${name}.${index}.value`, value.toString(), {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else if (totalValue.length > 0 && quantity.length > 0) {
      const price = divide(safeFloat(totalValue), safeFloat(quantity));
      setValue(`${name}.${index}.price`, price.toString(), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    // handle changing the other side when linked
    if (shouldLinkValue && side) {
      const value = multiply(safeFloat(price), safeFloat(quantity));
      if (modifyQuantity) {
        setOppositeSideQuantity(safeFloat(quantity), value, side);
      } else {
        const currentSideAmounts = getValues(name);
        const sideValueTotal = getValuesTotal(currentSideAmounts);
        setOppositeSideValues(sideValueTotal, side);
      }
    }
  };

  /**
   * Given one sides total value, it updates the other sides values
   * to maintain the 1:1 ratio.
   *
   * For example:
   * If you edit the incoming side so its total changes from $50 to $100, this
   * function will update the opposite side values so their total goes from
   * $50 to $100. Because that is a 2x change, it adjusts all values by 2x.
   *
   * @param totalSideValue - The total value of the side that was modified.
   * @param side - The side of the transaction was modified.
   */
  const setOppositeSideValues = (totalSideValue: number, side: Direction) => {
    const oppositeName = getOppositeName(side);

    // other side
    const otherSide = getValues(oppositeName);

    // total for other side
    const oldTotalValue = otherSide.reduce(
      (sum: number, item: any, i: number) => {
        return sum + safeFloat(item.value || 0);
      },
      0,
    );

    otherSide.forEach((item: any, oppositeIndex: number) => {
      // @todo if value is 0 either side, value propagation will break, decide on how to handle
      const newValue = divide(
        multiply(item.value, totalSideValue),
        oldTotalValue,
      );
      setValue(`${oppositeName}.${oppositeIndex}.value`, newValue, {
        shouldValidate: true,
        shouldDirty: true,
      });

      const quantity = getValues(`${oppositeName}.${oppositeIndex}.quantity`);
      const parsedQuantity = safeFloat(quantity);

      if (!parsedQuantity) {
        // quantity is set to 0 or NaN, its impossible to calculate a price that would give a value
        // so unset the price so the user has to fix it
        setValue(`${oppositeName}.${oppositeIndex}.price`, undefined, {
          shouldValidate: true,
          shouldDirty: true,
        });
      } else {
        const price = divide(newValue, parsedQuantity);

        setValue(`${oppositeName}.${oppositeIndex}.price`, price.toString(), {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    });
  };

  const setOppositeSideQuantity = (
    quantity: number,
    value: number,
    side: Direction,
  ) => {
    const oppositeName = getOppositeName(side);
    const oppositeIndex = 0; // This should only ever happen on 1:1 trades, so index always 0

    setValue(`${oppositeName}.${oppositeIndex}.quantity`, quantity, {
      shouldValidate: true,
      shouldDirty: true,
    });

    const currentSideAmounts = getValues(name);
    const sideValueTotal = getValuesTotal(currentSideAmounts);
    setOppositeSideValues(sideValueTotal, side);
  };

  // Optimistically validate the price field when the user click the ignore button or when showMissingPriceWarning is set to true
  // If moved into handleIgnore, the validation doesn't trigger immediately and the error persist until user click into the box and click away
  useEffect(() => {
    if (showMissingPriceWarning || hideWarning) {
      trigger(`${name}.${index}.price`);
    }
  }, [showMissingPriceWarning, trigger, hideWarning, index, name]);

  useEffect(() => {
    if (!focusField) {
      return;
    }
    switch (focusField) {
      case QuickEditFocusField.Incoming:
        setFocus(`${nameMap[QuickEditFocusField.Incoming]}.${0}.quantity`);
        break;
      case QuickEditFocusField.Outgoing:
        setFocus(`${nameMap[QuickEditFocusField.Outgoing]}.${0}.quantity`);
        break;
      case QuickEditFocusField.Value: {
        if (side === Direction.Incoming) {
          setFocus(`${nameMap[QuickEditFocusField.Incoming]}.${0}.value`);
        } else {
          setFocus(`${nameMap[QuickEditFocusField.Outgoing]}.${0}.value`);
        }
        break;
      }
      case QuickEditFocusField.Fee:
        setFocus(`${name}.${0}.quantity`);
        break;
      default:
    }
  }, [focusField, setFocus, name, side]);

  const priceController = (
    <Controller
      control={control}
      name={`${name}.${index}.price`}
      rules={{
        required: lang.editTx.mustPrice,
        validate: {
          invalid: validatePositiveValue(
            lang.editTx.validatePositivePriceValue,
          ),
          infinite: (value) => {
            if (parseFloat(value) === Infinity) {
              return lang.editTx.validatePositivePriceValue;
            }
            return true;
          },
          // Nonzero check only apply to missing price trade
          nonZero: (value) => {
            if (
              parseFloat(value) === 0 &&
              showMissingPriceWarning &&
              !hideWarning
            ) {
              // no error message should be displayed for the nonZero rule in this specific case.
              return lang.editTx.validatePositivePriceValue;
            }
            return true;
          },
        },
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <PriceField
          {...field}
          showLookupMarketPrice={
            showLookupMarketPrice ||
            txData.priceValueCurrency === localCurrency ||
            !txData.priceValueCurrency
          }
          currency={txData.currency}
          timestamp={timestampResolved}
          label={`${lang.editTx.price} ${priceValueCurrencyLabel}`}
          required
          setPrice={(marketPrice: string) => {
            setValue(`${name}.${index}.price`, marketPrice, {
              shouldValidate: true,
              shouldDirty: true,
            });
            if (parseFloat(marketPrice) > 0 && showMissingPriceWarning) {
              setHideWarning(true);
            }
            if (parseFloat(marketPrice) <= 0 && showMissingPriceWarning) {
              setHideWarning(false);
            }
            const quantity = txData?.quantity;
            const totalValue = multiply(
              safeFloat(marketPrice),
              safeFloat(quantity),
            );
            if (!isNil(quantity)) {
              setValue(`${name}.${index}.value`, totalValue.toString(), {
                shouldValidate: true,
                shouldDirty: true,
              });
            }

            // handle changing the other side when linked
            if (shouldLinkValue && side) {
              if (modifyQuantity) {
                setOppositeSideQuantity(safeFloat(quantity), totalValue, side);
              } else {
                const currentSideAmounts = getValues(name);
                const sideValueTotal = getValuesTotal(currentSideAmounts);
                setOppositeSideValues(sideValueTotal, side);
              }
            }
          }}
          error={invalid || error?.type === "nonZero"}
          helperText={error?.message}
          inputProps={{
            "data-hj-allow": true,
            style: { fontSize: "0.875rem" },
          }}
        />
      )}
    />
  );

  return (
    <Box>
      <Stack direction="row" alignItems="center" gap="0.5rem">
        <Grid container spacing={2} mt="0">
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              control={control}
              name={`${name}.${index}.currency`}
              rules={{
                required: lang.editTx.mustCurrency,
              }}
              render={({
                field: { value, onBlur },
                fieldState: { invalid, error },
              }) => (
                <CurrencyComboBox
                  id="base-currency-combo-box"
                  label={lang.editTx.currency}
                  value={value}
                  onBlur={onBlur}
                  placeholder={lang.addManualTX.currencyPlaceholder}
                  setValue={(value: CurrencyIdentifier | string | null) => {
                    setValue(`${name}.${index}.currency`, value || "", {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    // If fee currency match with incoming/outgoing currency,
                    // set fee price to be the same as incoming/outgoing price
                    const incomingValues = getValues(
                      nameMap[Direction.Incoming],
                    );
                    const outgoingValues = getValues(
                      nameMap[Direction.Outgoing],
                    );
                    const matched = [...incomingValues, ...outgoingValues].find(
                      (tx) =>
                        value &&
                        (typeof value === "string"
                          ? tx.currency === value
                          : tx.currency.id === value.id),
                    );
                    if (name === FEE_FIELD_ARRAY_NAME) {
                      setValue(
                        `${name}.${index}.price`,
                        matched ? matched.price : 0,
                        {
                          shouldValidate: true,
                          shouldDirty: true,
                        },
                      );
                    }
                  }}
                  required
                  error={invalid}
                  helperText={error?.message}
                  inputProps={{
                    "data-hj-allow": true,
                    style: { fontSize: "0.875rem" },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              control={control}
              name={`${name}.${index}.quantity`}
              rules={{
                required: lang.editTx.mustQuantity,
                validate: validateGreaterZeroValue(
                  lang.editTx.validatePositiveQuantityValue,
                ),
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  {...control.register(`${name}.${index}.quantity`)}
                  fullWidth
                  variant="outlined"
                  type="number"
                  sx={{ bgcolor: "transparent" }}
                  inputProps={{
                    sx: {
                      backgroundColor: tokens.background.input.default,
                      fontSize: "0.875rem",
                      borderRadius: "0.25rem",
                    },
                    min: 0,
                    step: "any",
                    required: false,
                    onWheel: blurTarget,
                    "data-hj-allow": true,
                  }}
                  label={lang.editTx.quantity}
                  required
                  error={invalid}
                  helperText={error?.message}
                  onChange={(e) => {
                    onQuantityChange(e.target.value);
                  }}
                />
              )}
            />
          </Grid>

          {isMedium ? (
            <Grid item xs={12} sm={6} md={3}>
              {priceController}
            </Grid>
          ) : null}
          <StyledGrid container item xs>
            {!isMedium ? priceController : null}
            <Controller
              control={control}
              name={`${name}.${index}.value`}
              rules={{
                required: lang.editTx.mustValue,
                validate: {
                  invalid: validatePositiveValue(
                    lang.editTx.validatePositiveValue,
                  ),
                },
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={`${lang.editTx.value} ${priceValueCurrencyLabel}`}
                  variant="outlined"
                  type="number"
                  inputProps={{
                    style: {
                      fontSize: "0.875rem",
                      backgroundColor: tokens.background.input.default,
                    },
                    min: 0,
                    step: "any",
                    onWheel: blurTarget,
                  }}
                  onChange={(e) => {
                    const totalValue = e.target.value;
                    setValue(`${name}.${index}.value`, totalValue, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });

                    const quantity = txData?.quantity;

                    if (quantity === "0") {
                      // quantity is set to 0, its impossible to calculate a price that would give a value
                      // so unset the price so the user has to fix it
                      setValue(`${name}.${index}.price`, undefined, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }
                    if (!isNil(quantity)) {
                      const price = divide(
                        safeFloat(totalValue),
                        safeFloat(quantity),
                      );

                      setValue(`${name}.${index}.price`, price.toString(), {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }

                    // handle changing the other side when linked
                    if (shouldLinkValue && side) {
                      if (modifyQuantity) {
                        setOppositeSideQuantity(
                          safeFloat(quantity),
                          safeFloat(totalValue),
                          side,
                        );
                      } else {
                        const currentSideAmounts = getValues(name);
                        const sideValueTotal =
                          getValuesTotal(currentSideAmounts);
                        setOppositeSideValues(sideValueTotal, side);
                      }
                    }
                  }}
                  error={invalid}
                  helperText={error?.message}
                  sx={{
                    bgcolor: "transparent",
                  }}
                />
              )}
            />
            {localCurrency && showPriceValueSelector ? (
              <Controller
                control={control}
                defaultValue={localCurrency}
                name={`${name}.${index}.priceValueCurrency`}
                rules={{
                  required: lang.editTx.mustValue,
                }}
                render={({ field: { value } }) => {
                  return (
                    <TxValueReferenceCurrencySelector
                      value={value}
                      localCurrency={localCurrency}
                      blockchain={blockchain}
                      setValue={(value: string) => {
                        setValue(`${name}.${index}.priceValueCurrency`, value, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        const isChangingFromLocalToOtherCurrency =
                          !storedLocalCurrencyPriceValue &&
                          value !== localCurrency;

                        if (isChangingFromLocalToOtherCurrency) {
                          // if we are changing out of the local currency, store the values
                          setStoredLocalCurrencyPriceValue({
                            price: txData.price,
                            value: txData.value,
                          });
                        }
                        const isChangingBackToLocalCurrency =
                          storedLocalCurrencyPriceValue &&
                          value === localCurrency;
                        if (isChangingBackToLocalCurrency) {
                          // we are changing back to the local currency, restore use those previously stored values
                          setValue(
                            `${name}.${index}.price`,
                            storedLocalCurrencyPriceValue.price,
                          );
                          setValue(
                            `${name}.${index}.value`,
                            storedLocalCurrencyPriceValue.value,
                          );
                          setStoredLocalCurrencyPriceValue(undefined);
                        } else {
                          // clear the values because its going from local to usd or eth
                          setValue(`${name}.${index}.price`, "");
                          setValue(`${name}.${index}.value`, "");
                        }
                      }}
                    />
                  );
                }}
              />
            ) : null}
          </StyledGrid>
        </Grid>
        {onRemove ? (
          <div>
            <TextIconButton
              style={{
                color: tokens.icon.danger,
                position: "relative",
                top: "0.5rem",
              }}
              onClick={onRemove}
            >
              <DeleteIcon fontSize="small" style={{ fontSize: "1rem" }} />
            </TextIconButton>
          </div>
        ) : null}
      </Stack>

      <Box
        display="flex"
        justifyContent={
          showMissingPriceWarning && !hideWarning ? "space-between" : "flex-end"
        }
        alignItems="center"
        flexWrap="wrap"
      >
        {showMissingPriceWarning && !hideWarning ? (
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            sx={{ color: tokens.text.low }}
          >
            {lang.editTx.noPriceFound({
              currency: currencyName,
            })}
            <Link
              onClick={handleMarkIgnoreMissingPrice}
              sx={{ cursor: "pointer" }}
            >
              {lang.reconciliation.ignoreWarning}
            </Link>
          </Typography>
        ) : null}
        {shouldShowNFTToggle && (
          <Box>
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              sx={{ color: tokens.text.low }}
            >
              {lang.editTx.treatAsNFT({
                symbol: middleTrim(currencySymbol, 8),
              })}
            </Typography>
            <Controller
              control={control}
              name={`updateMarkAsNFT.${currencyId}`}
              defaultValue={
                isMarkedAsNFT ?? isCurrencyNFTForTaxPurposes(txData.currency)
              }
              render={({ field }) => (
                <Tooltip title={lang.editTx.treatAsNFTTooltip} placement="top">
                  <Switch
                    checked={field.value}
                    onChange={(e) => {
                      setValue(
                        `updateMarkAsNFT.${currencyId}`,
                        e.target.checked,
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                        },
                      );
                    }}
                    color="primary"
                    inputProps={{
                      "aria-label": lang.editTx.treatAsNFT({
                        symbol: middleTrim(currencySymbol, 8),
                      }),
                    }}
                  />
                </Tooltip>
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

function getOppositeName(side: Direction): string {
  return nameMap[
    side === Direction.Incoming ? Direction.Outgoing : Direction.Incoming
  ];
}
