import { Blockchain } from "@ctc/types";
import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { BlockchainStack } from "~/components/imports-v2/BlockchainStack";
import { ImportRelatedWalletsDialog } from "~/components/imports-v2/ImportRelatedWalletsDialog";
import { type RelatedChainsForAddress } from "~/components/imports-v2/types";
import { getProxiedImageUrl } from "~/components/ui/CurrencyLogo";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { displayMessage } from "~/components/ui/Toaster";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { LocalStorageKey } from "~/constants/enums";
import { BroadcastChannels } from "~/hooks/enums";
import { useBroadcastChannel } from "~/hooks/useBroadcastChannel";
import {
  LocalStorageUserKeyGenerator,
  useLocalStorage,
} from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import { middleTrim } from "~/lib";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  useDequeueRelatedWalletMutation,
  useFirstRelatedWallet,
} from "~/state/sync";
import { isEvmRelatedWallet } from "~/state/syncHelpers";
import { useAddWalletMutation } from "~/state/wallets";
import { DefaultBlockchainCurrency, isEVMBlockchain } from "~/types";
import { DisplayMessage, RelatedWalletsAction } from "~/types/enums";

enum RelatedWalletBroadcastMessages {
  Dequeue = "dequeue_related_wallet",
}

export const ImportRelatedWalletsNotifier = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const relatedWallet = useFirstRelatedWallet();
  const useAddWallet = useAddWalletMutation({ isUsingImportForm: false });
  const dequeueRelatedWallet = useDequeueRelatedWalletMutation();
  const [showClusterImage, setShowClusterImage] = useState(true);
  const [, setSelectedBlockchains] = useState<RelatedChainsForAddress>({});
  const [showDialog, setShowDialog] = useState(false);

  const dequeueBroadcastHandler = useMemo(() => {
    return (event: MessageEvent<string>) => {
      if (event.data === RelatedWalletBroadcastMessages.Dequeue) {
        dequeueRelatedWallet.mutate();
      }
    };
  }, [dequeueRelatedWallet]);

  const { broadcast } = useBroadcastChannel({
    channelName: BroadcastChannels.DequeueRelatedWallet,
    onMessage: dequeueBroadcastHandler,
  });

  useEffect(() => {
    if (relatedWallet) {
      setShowClusterImage(true);
      if (
        isEvmRelatedWallet(relatedWallet) &&
        relatedWallet.chains.length > 0
      ) {
        const newState: RelatedChainsForAddress = {};
        newState[relatedWallet.address] = new Set(relatedWallet.chains);
        setSelectedBlockchains(newState);
        setShowDialog(true);
      }
    }
  }, [relatedWallet]);

  const dequeueWalletOnAllTabs = () => {
    dequeueRelatedWallet.mutate();
    broadcast(RelatedWalletBroadcastMessages.Dequeue);
  };

  function handleClose() {
    setShowDialog(false);
    dequeueWalletOnAllTabs();
  }

  if (!relatedWallet) {
    return null;
  }

  if (isEvmRelatedWallet(relatedWallet)) {
    if (!isEVMBlockchain(relatedWallet.originalBlockchain)) {
      return null;
    }

    return (
      <ImportRelatedWalletsDialog
        name={relatedWallet.name}
        isOpen={showDialog}
        handleClose={handleClose}
        address={{
          address: relatedWallet.address,
          blockchain: relatedWallet.originalBlockchain,
          name: relatedWallet.name,
          importFromTimeMillis: relatedWallet.importFromDate
            ? new Date(relatedWallet.importFromDate).valueOf()
            : undefined,
          importSource: relatedWallet.importSource,
        }}
      />
    );
  }

  const addWallets = () => {
    displayMessage({
      message: `Importing cluster ${relatedWallet.clusterName}`,
      type: DisplayMessage.Info,
    });

    relatedWallet.wallets.forEach((wallet) => {
      wallet.blockchains.forEach((blockchain) => {
        // Clusters doesnt specify the specific EVM chains anymore, so use the
        // same logic we do for MM to import the correct EVM chains
        const isUnspecifiedBlockchain =
          blockchain === Blockchain.ETH && wallet.blockchains.length === 1;

        useAddWallet.mutate({
          address: wallet.address,
          type: blockchain,
          name: wallet.name,
          relatedWalletsAction: isUnspecifiedBlockchain
            ? RelatedWalletsAction.Import
            : RelatedWalletsAction.Ignore,
          showMessage: false,
          importFromTimeMillis: relatedWallet.importFromDate
            ? new Date(relatedWallet.importFromDate).valueOf()
            : undefined,
          importSource: wallet.importSource,
          isUnspecifiedBlockchain,
        });
      });
    });
    dequeueWalletOnAllTabs();
  };

  const { clusterName, wallets, clusterImageUrl } = relatedWallet;

  const proxiedImageUrl = getProxiedImageUrl(clusterImageUrl);

  return (
    <GeneralDialog
      isOpen
      title={lang.imports.relatedChains.cluster.title}
      actionText={lang.imports.relatedChains.cluster.import}
      cancelText={lang.imports.relatedChains.cluster.dontImport}
      handleClose={() => {
        dequeueWalletOnAllTabs();
      }}
      handleAction={addWallets}
      disableClose
      closeOnClickAway={false}
      leftButton={
        <IgnoreClusterButton
          clusterName={clusterName}
          dequeueWalletOnAllTabs={dequeueWalletOnAllTabs}
        />
      }
    >
      <Box display="flex" flexDirection="column" gap="0.5rem">
        <Typography>{lang.imports.relatedChains.cluster.text}</Typography>
        <Box
          display="flex"
          flexDirection="column"
          gap="0.5rem"
          p="0.5rem"
          bgcolor={tokens.elevation.low}
          borderRadius="0.25rem"
        >
          <Box display="flex" width="100%" gap="0.25rem" alignItems="flex-end">
            {showClusterImage && clusterImageUrl ? (
              <LazyLoadImage
                src={proxiedImageUrl}
                alt={clusterName}
                width="32px"
                height="32px"
                effect="blur"
                style={{ borderRadius: "20%" }}
                onError={() => {
                  setShowClusterImage(false);
                }}
              />
            ) : null}
            <Typography variant="Metropolis/Header/H4">
              {clusterName}/
            </Typography>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" gap="0.5rem">
            {wallets.map((wallet) => (
              <Box
                key={`${wallet.address}-${wallet.blockchains.join("-")}`}
                display="grid"
                gridTemplateColumns="2fr 1fr 5rem"
                alignItems="center"
                gap="1rem"
                justifyContent="space-between"
              >
                <Typography
                  variant="Metropolis/Body/Regular"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {wallet.name}
                </Typography>

                <Box display="flex" alignItems="center" gap="0.25rem">
                  <ExchangeLogo
                    name={wallet.address}
                    blockchain={wallet.blockchains[0]}
                    currencySymbol={
                      DefaultBlockchainCurrency[wallet.blockchains[0]]
                    }
                    margin="0"
                    width={16}
                    height={16}
                  />
                  <Typography variant="IBM Plex Mono/Body/Bold">
                    {middleTrim(wallet.address)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  width="100%"
                >
                  <BlockchainStack
                    blockchains={wallet.blockchains}
                    stackSize={5}
                    showOnMobile
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </GeneralDialog>
  );
};

function IgnoreClusterButton({
  clusterName,
  dequeueWalletOnAllTabs,
}: {
  clusterName: string;
  dequeueWalletOnAllTabs: () => void;
}) {
  const lang = useLang();
  const user = useUser();
  const [ignoredClusterNames, setIgnoredClusterNames] = useLocalStorage<
    string[]
  >(
    LocalStorageUserKeyGenerator(
      user?.uid,
      LocalStorageKey.IgnoredClusterNames,
    ),
    [],
  );
  const onIgnoreCluster = () => {
    setIgnoredClusterNames([...ignoredClusterNames, clusterName]);
    dequeueWalletOnAllTabs();
  };
  return (
    <TextButton size="small" onClick={onIgnoreCluster}>
      {lang.imports.relatedChains.cluster.ignore}
    </TextButton>
  );
}
