import { Env } from "@ctc/types";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

const DD_SERVICE = "core";
const DD_GIT_REPOSITORY_URL = "github.com/cryptotaxcalculator/monorepo";

export function initDataDogMonitoring() {
  if (import.meta.env.VITE_APP_ENV === Env.Local) {
    console.log("Datadog is not initialized in local environment");
    return;
  }

  datadogLogs.init({
    // Required configuration
    clientToken: "pub9621359f5d596a4531ec3f4118848724",
    site: "datadoghq.com",

    // Unified service tagging
    env: import.meta.env.VITE_APP_ENV,
    service: DD_SERVICE,
    version: import.meta.env.VITE_APP_DD_GIT_RELEASE_TAG,

    // Error tracking configuration
    /**
     * Automatically forwards JS errors to logs
     * Enables correlation between errors and logs
     */
    forwardErrorsToLogs: true,

    /**
     * Forwards console logs by severity level
     * Provides complete visibility into application behavior
     */
    forwardConsoleLogs: ["error", "warn", "info", "debug", "log"],

    /**
     * Forwards all crash reports and performance data
     * Helps identify stability issues
     */
    forwardReports: "all",

    // Sampling configuration
    /**
     * Percentage of sessions to collect logs from
     * Set to 100 to collect all logs
     */
    sessionSampleRate: 100,

    /**
     * Use a partitioned secure cross-site session cookie. This allows the RUM Browser SDK to run
     * when the site is loaded from another one (iframe)
     */
    usePartitionedCrossSiteSessionCookie: true,
  });

  datadogRum.init({
    // Required configuration
    applicationId: "2c7d9d0e-f4cc-4f2c-8088-8995561bf544",
    clientToken: "pub9621359f5d596a4531ec3f4118848724",
    site: "datadoghq.com",

    // Unified service tagging
    env: import.meta.env.VITE_APP_ENV,
    service: DD_SERVICE,
    version: import.meta.env.VITE_APP_DD_GIT_RELEASE_TAG,

    // Sampling rates
    /**
     * Percentage of sessions to track (100 = track all sessions)
     * Reduces to lower value in production for cost optimization
     */
    sessionSampleRate: 100,

    /**
     * Percentage of sessions to record for session replay
     * Enables visual reproduction of user sessions
     */
    sessionReplaySampleRate: 20, // Starting at 20% to reduce cost for now

    /**
     * Percentage of requests to trace
     * Enables correlation between RUM and APM
     */
    traceSampleRate: 100,

    // Session handling
    /**
     * Preserves user context across page refreshes and navigation
     * Enables better user journey tracking
     */
    storeContextsAcrossPages: true,

    // Interaction tracking
    /**
     * Automatically tracks clicks, taps, and user interactions
     * Provides insights into user behavior and feature usage
     */
    trackUserInteractions: true,

    /**
     * Tracks all resources (images, fonts, scripts) loading performance
     * Helps identify slow-loading assets
     */
    trackResources: true,

    /**
     * Tracks JavaScript tasks taking >50ms
     * Helps identify UI performance issues
     */
    trackLongTasks: true,

    // Privacy and security
    /**
     * masks all HTML text, user input, images, links and data-* attributes.
     * Ensures sensitive data isn't collected
     */
    defaultPrivacyLevel: "mask",

    // Tracing configuration
    /**
     * Injects tracing headers into all requests
     * Enables end-to-end request tracing
     */
    traceContextInjection: "all",

    beforeSend: (event) => {
      // Specific for now, can make generic later
      event.view.url = event.view.url.replace(/email=[^&]*/, "email=REDACTED");
      event.view.referrer = event.view.referrer
        ? event.view.referrer.replace(/email=[^&]*/, "email=REDACTED")
        : undefined;

      event.view.url = event.view.url.replace(
        /userEmail=[^&]*/,
        "userEmail=REDACTED",
      );
      event.view.referrer = event.view.referrer
        ? event.view.referrer.replace(/userEmail=[^&]*/, "userEmail=REDACTED")
        : undefined;

      // Return true to send the event, false to drop it
      return true;
    },
  });

  const context = {
    // Unified service tagging
    env: import.meta.env.VITE_APP_ENV,
    service: DD_SERVICE,
    version: import.meta.env.VITE_APP_DD_GIT_RELEASE_TAG,

    // Source code integration
    "git.commit.sha": import.meta.env.VITE_APP_DD_GIT_COMMIT_SHA,
    "git.repository_url": DD_GIT_REPOSITORY_URL,
  };
  datadogRum.setGlobalContext(context);
  datadogLogs.setGlobalContext(context);
}

export function setDatadogUser(userId: string) {
  console.log("setting datadog user", userId);
  datadogRum.setUser({ id: userId });
  datadogLogs.setUser({ id: userId });
}
