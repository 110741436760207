import { Typography } from "@mui/material";

import { type DataSet } from "~/components/developer/seed-data/index";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useDeleteSeedDataSetMutation } from "~/state/developer";

export const DeleteDataSetModal = ({
  isOpen,
  handleClose,
  dataSet,
}: {
  isOpen: boolean;
  handleClose: () => void;
  dataSet: DataSet;
}) => {
  const mutation = useDeleteSeedDataSetMutation();

  const onSubmit = () => {
    mutation
      .mutateAsync({
        dataSetId: dataSet._id,
      })
      .then(handleClose);
  };

  return (
    <GeneralDialog
      isOpen={isOpen}
      title="Delete data set"
      handleClose={handleClose}
      actionText="Delete"
      handleAction={() => {
        onSubmit();
      }}
      pending={mutation.isPending}
      critical
    >
      <Typography>
        {`This will delete the "${dataSet.name}" seed data set for all CTC devs. Continue?`}
      </Typography>
    </GeneralDialog>
  );
};
