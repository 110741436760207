import ArticleIcon from "@mui/icons-material/Article";

import { BulkEditCurrencyChipIcon } from "~/components/transactions/command-palette/BulkEditCurrencyChipIcon";
import { DualChip } from "~/components/transactions/command-palette/views/DualChip";
import { useLang } from "~/redux/lang";
import { type ErpAccount } from "~/redux/types";
import { type CurrencyIdentifier } from "~/types/index";

/**
 * The chips at the top of bulk change asset account
 * @param param0
 * @returns
 */
export const AssetAccountTopSection = ({
  fromCurrency,
  assetAccount,
}: {
  fromCurrency?: CurrencyIdentifier;
  assetAccount?: ErpAccount;
}) => {
  const lang = useLang();
  return (
    <DualChip
      leftIcon={
        fromCurrency ? (
          <BulkEditCurrencyChipIcon currency={fromCurrency} />
        ) : undefined
      }
      leftLabel={fromCurrency ? fromCurrency.symbol : lang.txTable.currency}
      rightLabel={
        assetAccount
          ? assetAccount.name
          : lang.txTable.expansionPanel.chartOfAccounts.assetAccount
      }
      rightIcon={
        assetAccount ? (
          <ArticleIcon sx={{ height: "1rem", width: "1rem" }} />
        ) : undefined
      }
    />
  );
};
