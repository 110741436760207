import { EmbeddedForgotPasswordForm } from "~/components/user/EmbeddedForgotPasswordForm";
import { ForgotPasswordForm } from "~/components/user/ForgotPasswordForm";
import { UserDetailsPage } from "~/components/user/UserDetails";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useLang } from "~/redux/lang";

export const ForgotPasswordWrapper = () => {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();

  // If we're in an embedded context, show the embedded form
  if (isEmbedded) {
    return <EmbeddedForgotPasswordForm />;
  }

  // Otherwise show the regular forgot password form
  return (
    <UserDetailsPage
      formText={{
        title: lang.auth.forgotPassword,
        subTitle: lang.auth.passwordResetInstructions,
      }}
      formComponent={ForgotPasswordForm}
    />
  );
};
