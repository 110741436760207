import { createContext, useContext } from "react";
import { createEnumParam, useQueryParams, withDefault } from "use-query-params";

import { SettingsModal } from "~/components/settings-modal/index";
import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { ReportSettingsProvider } from "~/components/settings-modal/views/tax/ReportSettingsContext";
import { FYContextProvider } from "~/contexts/FYContext";
import { PaywallModalContextProvider } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { ReportModalsContextProvider } from "~/contexts/ReportModalsContext";

type SettingsModalContext = {
  open: boolean;
  setOpen: (open: boolean) => void;
  active: SettingsTabs | undefined;
  setActive: (active: SettingsTabs) => void;
};

const initialContext: SettingsModalContext = {
  open: false,
  setOpen: () => {},
  active: undefined,
  setActive: () => {},
};

const SettingsModalContext =
  createContext<SettingsModalContext>(initialContext);

export enum SettingsModalActionType {
  SetFilter = "SetFilter",
}

export const SettingsModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, setState] = useQueryParams({
    settings: withDefault(
      createEnumParam(Object.values(SettingsTabs)),
      undefined,
    ),
  });

  // Wraps setting the open state in the URL so that when its closed we remove
  // it from the URL
  const setOpen = (value: boolean) => {
    if (value) {
      setState({ settings: SettingsTabs.Profile });
    } else {
      setState({ settings: undefined }, "replaceIn");
    }
  };

  const setActive = (tab: SettingsTabs) => {
    setState({ settings: tab });
  };

  const open = !!state.settings;
  const active = state.settings;

  return (
    <SettingsModalContext.Provider value={{ open, setOpen, active, setActive }}>
      <PaywallModalContextProvider>
        <ReportSettingsProvider>
          <FYContextProvider>
            <ReportModalsContextProvider>
              <SettingsModal
                open={open}
                setOpen={setOpen}
                active={active}
                setActive={setActive}
              />
            </ReportModalsContextProvider>
          </FYContextProvider>
        </ReportSettingsProvider>
      </PaywallModalContextProvider>
      {children}
    </SettingsModalContext.Provider>
  );
};

export function useSettingsModal() {
  const context = useContext(SettingsModalContext);
  if (context === undefined) {
    throw new Error(
      "useSettingsModal must be used within a SettingsModalProvider",
    );
  }
  return context;
}
