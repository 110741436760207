import { Blockchain } from "@ctc/types";
import { debounce } from "@mui/material";
import Fuse from "fuse.js";

import popularityScores from "~/data/popularityScores.json";
import { isValidWalletAddress } from "~/services/wallet/isValidWalletAddress";

/**
 * WeakMap to cache sorted data copies for better performance
 * Uses original data arrays as keys and stores their sorted copies
 */
const sortedDataCache = new WeakMap<any[], any[]>();

/**
 * Creates a new instance of a search function with optimized performance
 * @param data Data array to be searched
 * @returns A sorted copy of the data to avoid modifying the original
 */
function createSortedDataCopy<T>(data: T[]): T[] {
  // Check if we already have a sorted copy of this data
  if (!sortedDataCache.has(data)) {
    // Create and cache the sorted copy
    sortedDataCache.set(data, sortByPopularity([...data]));
  }

  // Return the cached sorted copy
  return sortedDataCache.get(data)!;
}

// Fixes a type error
interface Cancelable {
  clear(): void;
}

/**
 * Helper function to find exact matches against search keys
 * @param item - Item to check for exact matches
 * @param keys - Keys to check for exact matches
 * @param value - Search value to match against
 * @returns True if any key exactly matches the search value
 */
function hasExactMatch<T>(item: T, keys: string[], value: string): boolean {
  if (!value) return false;

  // Handle nested keys (e.g. "user.name")
  for (const key of keys) {
    const parts = key.split(".");
    let itemValue: any = item;

    // Navigate through nested object properties
    for (const part of parts) {
      if (itemValue == null) break;
      itemValue = (itemValue as any)[part];
    }

    // Check if the value is a string and exactly matches the search term
    if (
      typeof itemValue === "string" &&
      itemValue.toLowerCase() === value.toLowerCase()
    ) {
      return true;
    }
  }

  return false;
}

/**
 * Type for the popularityScores.json data structure
 */
interface PopularityScore {
  /** Item identifier */
  id: string;
  /** Popularity score (higher values indicate more popular items) */
  popularity: number;
}

/**
 * Map of item IDs to their popularity scores for quick lookup
 */
const popularityMap: Record<string, number> = {};

// Initialize the popularity map from the imported data
popularityScores.scores.forEach((score: PopularityScore) => {
  popularityMap[score.id] = score.popularity;
});

export function search<Data>({
  data,
  keys,
  threshold = 0.3,
  callback,
}: {
  data: Data[];
  keys: string[];
  threshold?: number;
  callback: (result: Data[], val: string | null | undefined) => any;
}): ((value: string | null) => void) & Cancelable {
  // Pre-sort data by popularity once when initializing the search
  const sortedData = createSortedDataCopy(data);
  const fuse = new Fuse(data, { keys, threshold });

  return debounce((value: string | null) => {
    if (!value) {
      callback(sortedData, value);
      return;
    }

    const fuseResult = fuse.search(value).map((i) => i.item);
    const sortedResults = prioritizeExactMatches(fuseResult, keys, value);
    callback(sortedResults, value);
  }, 200);
}

export function importSearch<Data extends { id?: string }>({
  data,
  keys,
  threshold = 0.3,
  callback,
}: {
  data: Data[];
  keys: string[];
  threshold?: number;
  callback: (result: Data[], val: string | null | undefined) => any;
}): ((value: string | null) => void) & Cancelable {
  // Pre-sort data by popularity once when initializing the search
  const sortedData = createSortedDataCopy(data);
  const fuse = new Fuse(data, { keys, threshold });

  return debounce((value: string | null) => {
    if (!value) {
      callback(sortedData, value);
      return;
    }
    const MIN_WALLET_ADDRESS_LENGTH = 10;
    const potentialWalletMatches =
      value.length > MIN_WALLET_ADDRESS_LENGTH
        ? Object.values(Blockchain).filter((blockchain) =>
            isValidWalletAddress(value, blockchain, false),
          )
        : [];

    const fuseResult = fuse.search(value).map((i) => i.item);
    // need to do the lookups from the blockchains to the data
    const potentialBlockchainMatches = data.filter(
      (i) => i.id && potentialWalletMatches.includes(i.id as Blockchain),
    );
    // combine the results
    const result = [...fuseResult, ...potentialBlockchainMatches]
      // remove duplicates
      .filter((item, index, self) => index === self.indexOf(item));

    const sortedResults = prioritizeExactMatches(result, keys, value);
    callback(sortedResults, value);
  }, 200);
}

/**
 * Prioritizes exact matches in search results
 * @param items - Array of search results
 * @param keys - Search keys to check for exact matches
 * @param value - Search value to match against
 * @returns Sorted array with exact matches first, then sorted by popularity
 */
function prioritizeExactMatches<T>(
  items: T[],
  keys: string[],
  value: string,
): T[] {
  // Separate exact matches from other results
  const exactMatches: T[] = [];
  const otherMatches: T[] = [];

  items.forEach((item) => {
    if (hasExactMatch(item, keys, value)) {
      exactMatches.push(item);
    } else {
      otherMatches.push(item);
    }
  });

  // Sort each group by popularity
  const sortedExactMatches = sortByPopularity(exactMatches);
  const sortedOtherMatches = sortByPopularity(otherMatches);

  // Combine with exact matches first
  return [...sortedExactMatches, ...sortedOtherMatches];
}

/**
 * Gets the popularity score for an item
 * @param item Item that may have an id or popularity property
 * @returns Popularity score or -1 if not found
 */
function getPopularityScore(item: any): number {
  // First check if the item already has a popularity property
  if (typeof item.popularity === "number") {
    return item.popularity;
  }

  // Then check if the item has an id that exists in our popularity map
  if (item.id && popularityMap[item.id] !== undefined) {
    return popularityMap[item.id];
  }

  // No popularity found
  return -1;
}

/**
 * Sorts an array of items by their popularity (from popularityScores or item.popularity)
 * @param items Array of items to sort
 * @returns Sorted array with most popular items first
 */
function sortByPopularity<T>(items: T[]): T[] {
  return [...items].sort((a, b) => {
    const aPopularity = getPopularityScore(a);
    const bPopularity = getPopularityScore(b);

    // If both items have popularity, sort by popularity (higher first)
    if (aPopularity >= 0 && bPopularity >= 0) {
      return bPopularity - aPopularity;
    }
    // If only a has popularity, it comes first
    if (aPopularity >= 0) return -1;
    // If only b has popularity, it comes first
    if (bPopularity >= 0) return 1;
    // If neither has popularity, maintain original order
    return 0;
  });
}
