import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { Box, Stack, TextField, Typography } from "@mui/material";
import moment from "moment-timezone";
import { type ComponentProps, useState } from "react";
import { VariableSizeList } from "react-window";
import styled from "styled-components/macro";

import { CommandMenuListStyled } from "~/components/transactions/command-palette/CommandMenuList";
import {
  type CommandPaletteMenu,
  Row,
} from "~/components/transactions/command-palette/CommandPaletteMenu";
import {
  CommandPaletteTitle,
  TitleContainer,
} from "~/components/transactions/command-palette/CommandPaletteTitle";
import { useCommandPaletteMenu } from "~/components/transactions/command-palette/hooks/useCommandPaletteMenu";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { ProgressDivider } from "~/components/transactions/command-palette/ProgressDivider";
import { useDateTimeMomentFormat, useDateTimePlaceholder } from "~/redux/auth";
import { useLang } from "~/redux/lang";

export function CommandPaletteTimestampInput({
  topArea,
  onConfirm,
  loading = false,
  placeholder,
  description,
}: {
  topArea?: ComponentProps<typeof CommandPaletteMenu>["topArea"];
  onConfirm: (value: Date) => void;
  loading?: boolean;
  placeholder?: string;
  description?: string;
}) {
  const [value, setValue] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const { pop } = useNavController();
  const lang = useLang();
  const dateTimePlaceholder = useDateTimePlaceholder();
  const dateTimeFormat = useDateTimeMomentFormat();

  const options = [
    {
      label: lang.txTable.commandPallet.actions.accept,
      icon: <CheckIcon />,
    },
    {
      label: lang.txTable.commandPallet.actions.goBack,
      icon: <CloseIcon />,
    },
  ];

  const onSelection = (option: (typeof options)[number]) => {
    if (option.label === lang.txTable.commandPallet.actions.accept) {
      if (!value) {
        return;
      }
      onConfirm(value);
    } else if (option.label === lang.txTable.commandPallet.actions.goBack) {
      pop();
    }
  };

  const { setIsUsingKeyNav, variableSizeListProps, inputSearchProps } =
    useCommandPaletteMenu({
      loading,
      onSelection,
      options,
      placeholder: placeholder ?? dateTimePlaceholder,
      itemSize: () => 50,
      maxMenuHeight: 300,
      disabled: loading,
    });

  const { onKeyDown, inputRef, disabled } = inputSearchProps;
  const limitedInputSearchProps = { onKeyDown, inputRef, disabled };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <TitleContainer>
        <CommandPaletteTitle topArea={topArea} loading={loading} />
        <Stack direction="column" gap="0.5rem" alignItems="center">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={handleClose}
              DialogProps={{
                disablePortal: true,
              }}
              PopperProps={{
                disablePortal: true,
              }}
              renderInput={(props) => (
                <Input
                  {...props}
                  fullWidth
                  placeholder={placeholder ?? dateTimePlaceholder}
                  variant="standard"
                  {...limitedInputSearchProps}
                />
              )}
              value={value}
              onChange={(date: moment.Moment | null) => {
                const newDate = date?.toDate();
                if (newDate) {
                  setValue(newDate);
                }
              }}
              inputFormat={dateTimeFormat}
              disableFuture
              minDate={moment("2009-01-09")}
              maxDate={moment()}
              mask="____-__-__ __:__:__"
              {...limitedInputSearchProps}
            />
          </LocalizationProvider>
          {description && (
            <Typography variant="Metropolis/Caption/Medium/Regular">
              {description}
            </Typography>
          )}
        </Stack>
      </TitleContainer>

      <ProgressDivider loading={loading} />

      <CommandMenuListStyled
        as="div"
        onMouseMove={() => {
          setIsUsingKeyNav(false);
        }}
      >
        <VariableSizeList {...variableSizeListProps}>{Row}</VariableSizeList>
      </CommandMenuListStyled>
    </Box>
  );
}

const Input = styled(TextField)(({ theme }) => ({
  "&&": {
    backgroundColor: "transparent",
    width: "100%",
  },

  "& .MuiInputBase-input": {
    ...theme.mui.typography['Metropolis/Caption/Medium/Regular'],
    fontSize: "1.25rem",
    color: theme.tokens.text.default,
    "&::placeholder": {
      color: theme.tokens.text.disabled,
    },
  },
}));
