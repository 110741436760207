import { Blockchain } from "@ctc/types";
import { type QueryKey } from "@tanstack/react-query";

import {
  type ClusterRelatedWalletDetails,
  type EvmRelatedWalletDetails,
  type RelatedWalletDetails,
} from "~/redux/types";
import { type useGetSavedAccounts } from "~/state/importsV2";

/**
 * Define wallet keys for React Query
 */
export const walletsKeys = {
  all: () => ["wallets"] as const,
  related: (address: string, blockchain: Blockchain) =>
    [...walletsKeys.all(), "related", `${address}_${blockchain}`] as const,
};

/**
 * Define sync keys for React Query related to related wallets
 */
export const syncRelatedWalletsKey = () => ["sync", "relatedWallets"] as const;

/**
 * Type guard to check if a related wallet is an EVM wallet
 * @param relatedWallet - The wallet to check
 * @returns True if the wallet is an EVM wallet
 */
export function isEvmRelatedWallet(
  relatedWallet: RelatedWalletDetails,
): relatedWallet is EvmRelatedWalletDetails {
  return "address" in relatedWallet;
}

/**
 * Type guard to check if a related wallet is a cluster wallet
 * @param relatedWallet - The wallet to check
 * @returns True if the wallet is a cluster wallet
 */
export function isClusterRelatedWallet(
  relatedWallet: RelatedWalletDetails,
): relatedWallet is ClusterRelatedWalletDetails {
  return "clusterName" in relatedWallet;
}

/**
 * Creates a unique wallet identifier set for checking duplicate imports
 * @param allImports - All saved wallet imports
 * @returns Set of unique wallet identifiers
 */
export const createImportedWalletSet = (
  allImports: ReturnType<typeof useGetSavedAccounts>,
): Set<string> => {
  return new Set(
    allImports
      .flatMap((importOption) => importOption.wallets)
      .flatMap(({ address, blockchain }) => [
        address.toLowerCase(),
        `${address.toLowerCase()}__${blockchain}`,
      ]),
  );
};

/**
 * Checks if an EVM wallet is already in the existing wallets list
 * @param info - The EVM related wallet info to check
 * @param existingRelatedWallets - List of current related wallets
 * @returns True if wallet already exists, false otherwise
 */
export const isEvmWalletDuplicate = (
  info: EvmRelatedWalletDetails,
  existingRelatedWallets: RelatedWalletDetails[],
): boolean => {
  return !!existingRelatedWallets.find(
    (i) => isEvmRelatedWallet(i) && i.address === info.address,
  );
};

/**
 * Checks if a cluster is already in the existing wallets list
 * @param info - The cluster related wallet info to check
 * @param existingRelatedWallets - List of current related wallets
 * @returns True if cluster already exists, false otherwise
 */
export const isClusterDuplicate = (
  info: ClusterRelatedWalletDetails,
  existingRelatedWallets: RelatedWalletDetails[],
): boolean => {
  return !!existingRelatedWallets.find(
    (i) => isClusterRelatedWallet(i) && i.clusterName === info.clusterName,
  );
};

/**
 * Checks if all wallets in a cluster are already imported
 * @param info - The cluster related wallet info to check
 * @param importedSet - Set of already imported wallets
 * @returns True if all wallets are imported, false otherwise
 */
export const areAllClusterWalletsImported = (
  info: ClusterRelatedWalletDetails,
  importedSet: Set<string>,
): boolean => {
  const allSuggestions = info.wallets.map(({ address }) =>
    address.toLowerCase(),
  );
  return allSuggestions.every((address) => importedSet.has(address));
};

/**
 * Creates an EVM related wallet from the first wallet in an ignored cluster
 * @param firstWallet - The first wallet in the cluster
 * @param unAddedChains - Chains that haven't been added yet
 * @param importFromDate - Optional import date
 * @returns EVM related wallet details
 */
export const createEvmRelatedWalletFromCluster = (
  firstWallet: ClusterRelatedWalletDetails["wallets"][0],
  unAddedChains: Blockchain[],
  importFromDate?: Date,
): EvmRelatedWalletDetails => {
  return {
    address: firstWallet.address,
    chains: unAddedChains,
    originalBlockchain: Blockchain.ETH,
    name: firstWallet.name,
    importSource: firstWallet.importSource,
    importFromDate,
  };
};

/**
 * Gets chains from a wallet that haven't been added yet
 * @param firstWallet - The wallet to check
 * @param importedSet - Set of already imported wallets
 * @returns Array of blockchains that haven't been added yet
 */
export const getUnaddedChains = (
  firstWallet: ClusterRelatedWalletDetails["wallets"][0],
  importedSet: Set<string>,
): Blockchain[] => {
  return firstWallet.blockchains.filter(
    (blockchain) =>
      !importedSet.has(`${firstWallet.address.toLowerCase()}__${blockchain}`),
  );
};

/**
 * Adds a wallet to the related wallets list and updates state
 * @param hackyMutate - Function to update state
 * @param wallet - Wallet details to add
 * @param existingWallets - Existing related wallets
 */
export const addToRelatedWallets = (
  hackyMutate: <T>(queryKey: QueryKey, newData: T) => void,
  wallet: RelatedWalletDetails,
  existingWallets: RelatedWalletDetails[],
): void => {
  hackyMutate(syncRelatedWalletsKey(), [...existingWallets, wallet].flat());
};

/**
 * Updates available blockchain chains for an EVM wallet in state
 * @param hackyMutate - Function to update state
 * @param address - Wallet address
 * @param blockchain - Original blockchain
 * @param chains - Available chains
 */
export const updateWalletChains = (
  hackyMutate: <T>(queryKey: QueryKey, newData: T) => void,
  address: string,
  blockchain: Blockchain,
  chains: Blockchain[] | undefined,
): void => {
  hackyMutate(walletsKeys.related(address, blockchain), chains ?? []);
};

/**
 * Checks if an ignored cluster has chains that can be added to an existing wallet
 * @param firstWallet - First wallet in the cluster
 * @param unAddedChains - Chains not yet added
 * @param importedSet - Set of already imported wallets
 * @returns True if there are chains to be added, false otherwise
 */
export const shouldHandleIgnoredClusterRelatedChains = (
  firstWallet: ClusterRelatedWalletDetails["wallets"][0],
  unAddedChains: Blockchain[],
  importedSet: Set<string>,
): boolean => {
  // Check if wallet is imported but has chains that haven't been added
  return (
    importedSet.has(firstWallet.address.toLowerCase()) &&
    unAddedChains.length > 0
  );
};

/**
 * Performs the action of adding chains from an ignored cluster
 * @param hackyMutate - Function to update state
 * @param info - Cluster wallet info
 * @param firstWallet - First wallet in the cluster
 * @param unAddedChains - Chains not yet added
 * @param existingRelatedWallets - Existing related wallets
 * @returns True if action was performed
 */
export const performIgnoredClusterChainsAction = (
  hackyMutate: <T>(queryKey: QueryKey, newData: T) => void,
  info: ClusterRelatedWalletDetails,
  firstWallet: ClusterRelatedWalletDetails["wallets"][0],
  unAddedChains: Blockchain[],
  existingRelatedWallets: RelatedWalletDetails[],
): void => {
  // Create EVM wallet from cluster data
  const evmRelatedWallet = createEvmRelatedWalletFromCluster(
    firstWallet,
    unAddedChains,
    info.importFromDate,
  );

  // Update state
  addToRelatedWallets(hackyMutate, evmRelatedWallet, existingRelatedWallets);
  updateWalletChains(
    hackyMutate,
    firstWallet.address,
    Blockchain.ETH,
    unAddedChains,
  );
};
