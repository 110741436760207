import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import { useContext, useState } from "react";
import {
  type Control,
  Controller,
  useForm,
  type UseFormReturn,
} from "react-hook-form";
import styled from "styled-components/macro";

import { importAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { CurrencySelect } from "~/components/filter/filterCurrencySelectRow";
import { AdvancedImportOptions } from "~/components/imports/advancedImportOptions";
import { ImportFormContext } from "~/components/imports/context/index";
import { ImportFromSelector } from "~/components/imports/ImportFromSelector";
import { useImportContext } from "~/components/imports/ImportItem";
import { ImportTypeEtaBox } from "~/components/imports/ImportTypeEtaBox";
import { useInImportErrorReportExperiment } from "~/components/imports/useImportErrorReportExperiment";
import { Checkbox } from "~/components/ui/Checkbox";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { ShieldIcon } from "~/components/ui/Icons";

import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { secureConnectDelay } from "~/constants/constants";
import { useIsOnboardingMobileImportExperiment } from "~/hooks/useIsOnboardingMobileImportExperiment";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useGetSavedImportsByIntegration } from "~/state/importsV2";
import {
  useDeleteKeyMutation,
  useSaveKeyMutation,
  useUpdateKeyMutation,
} from "~/state/keys";
import { ImportMethod } from "~/types/enums";
import {
  type APIFormValues,
  type ApiImportMethod,
  type CurrencyIdentifier,
  type ImportMethodV2,
  type ImportOptionV2,
  type KeyCredentials,
  type KeyUpdateDetails,
} from "~/types/index";

import { OnboardingNavBar } from "../OnboardingNavBar";

const TICKERS_EXCHANGE = "binance";

type APIInputControllerOptions = {
  isEditLocked?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
};

export type APIInputController = {
  formController: UseFormReturn<APIFormValues>;
  initTickers: string[];
  setInitTickers: (tickers: string[]) => void;
  tickers: string[];
  setTickers: (tickers: string[]) => void;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  importFromTime: Date | undefined;
  setImportFromTime: (importFromTime: Date | undefined) => void;
  editingApiId: string | undefined;
  setEditingApiId: (editingApiId: string | undefined) => void;
  isUpdatingKey: boolean;
  setIsUpdatingKey: (isUpdatingKey: boolean) => void;
  handleEdit: (key: KeyCredentials) => void;
  handleUpdate: (key: KeyCredentials) => void;
  handleCancelEdit: () => void;
  handleDelete: (key: KeyCredentials) => void;
  options: APIInputControllerOptions;
};

const SecureConnectModule = ({
  isEditing,
  loading,
}: {
  isEditing: boolean;
  loading: boolean;
}) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const buttonText = loading
    ? lang.imports.verifying
    : isEditing
      ? lang.api.editingSubmit
      : lang.imports.secureConnect;

  return (
    <>
      <PrimaryButton
        disabled={loading}
        type="submit"
        variant="contained"
        fullWidth
      >
        {loading ? (
          <CircularProgress
            sx={{
              marginRight: "0.5rem",
              marginBottom: "0.1rem",
              color: tokens.text.white,
            }}
            size="1rem"
          />
        ) : (
          <ShieldIcon sx={{ marginRight: "0.25rem" }} />
        )}
        {buttonText}
      </PrimaryButton>
      <SecureConnectNote />
    </>
  );
};

export const SecureConnectNote = () => {
  const lang = useLang();
  const { tokens } = useDesign();
  return (
    <Stack direction="row" spacing={1} mt="1rem">
      <ShieldIcon sx={{ marginRight: "0.25rem", fill: tokens.text.low }} />
      <Typography
        color={tokens.text.low}
        variant="Metropolis/Caption/Medium/Regular"
      >
        {lang.imports.secureConnectNote}
      </Typography>
    </Stack>
  );
};

export const CreateAPIImportInputController = (
  importOption: ImportOptionV2,
  importMethod: ImportMethodV2,
  options: APIInputControllerOptions = {},
): APIInputController => {
  const deleteKey = useDeleteKeyMutation();
  const context = useContext(ImportFormContext);
  const savedImport = useGetSavedImportsByIntegration(importOption.id);
  const { onImportDeleted } = useImportContext();

  const formController = useForm<APIFormValues>({
    mode: "onChange",
    defaultValues: {
      name: "",
      apiKey: "",
      secret: "",
      tickers: [],
      password: "",
      uid: "",
      refreshToken: false,
      importFromTimeMillis: undefined,
    },
  });

  const [initTickers, setInitTickers] = useState<string[]>([]);
  const [tickers, setTickers] = useState<string[]>([]);
  const [checked, setChecked] = useState(false);
  const [importFromTime, setImportFromTime] = useState<Date | undefined>(
    undefined,
  );
  const [editingApiId, setEditingApiId] = useState<string | undefined>();
  const [isUpdatingKey, setIsUpdatingKey] = useState(false);

  const { setValue, reset, clearErrors } = formController;

  const handleEdit = (key: KeyCredentials) => {
    setValue("name", key.name);
    if (key.tickers) {
      setInitTickers(key.tickers);
      setTickers(key.tickers);
      setChecked(true);
    } else {
      setChecked(false);
    }
    setValue("apiKey", key.apiKey);
    setImportFromTime(key.importFromDate);
    setEditingApiId(key.id);
    setIsUpdatingKey(false);
    clearErrors();

    context?.setImportIssue(key.errorIssue);
    context?.setImportMethod(importMethod);
    context?.setShowImportForm(true);
  };

  const handleUpdate = (key: KeyCredentials) => {
    setValue("name", key.name);
    setValue("apiKey", key.apiKey);
    if (key.tickers?.length) {
      setChecked(true);
      setTickers(key.tickers);
    } else {
      setChecked(false);
      setTickers([]);
    }

    setEditingApiId(key.id);
    setImportFromTime(key.importFromDate);
    setIsUpdatingKey(true);
    clearErrors();
    context?.setImportIssue(key.errorIssue);
    context?.setImportMethod(importMethod);
    context?.setShowImportForm(true);
  };

  const handleCancelEdit = () => {
    reset();
    setChecked(false);
    setTickers([]);
    setInitTickers([]);
    setEditingApiId(undefined);
    setImportFromTime(undefined);
    setIsUpdatingKey(false);
    context?.setImportIssue(undefined);
  };

  const handleDelete = (key: KeyCredentials) => {
    if (!savedImport) {
      return;
    }
    deleteKey.mutate({ id: key.id, isOAuth: !!key.isOauth, savedImport });
    handleCancelEdit();
    onImportDeleted?.();
  };

  return {
    formController,
    initTickers,
    setInitTickers,
    tickers,
    setTickers,
    checked,
    setChecked,
    importFromTime,
    setImportFromTime,
    editingApiId,
    setEditingApiId,
    isUpdatingKey,
    setIsUpdatingKey,
    handleEdit,
    handleUpdate,
    handleCancelEdit,
    handleDelete,
    options,
  };
};

export function ImportApi({
  importOption,
  importMethod,
  controller,
}: {
  importOption: ImportOptionV2;
  importMethod: ApiImportMethod;
  controller: APIInputController;
}) {
  const requiredCredentials = importMethod.credentials;
  const { tokens } = useDesign();
  const {
    apiKey: apiKeyRequired,
    secret: secretRequired,
    uid: uidRequired,
    password: passwordRequired,
  } = requiredCredentials;
  const tickersRequired = importMethod.options?.tickers;
  const { setSyncStarted } = useImportContext();
  const captureAnalytics = useCaptureAnalytics();
  const advancedOptionsKey = importAnalyticsKey("advanced options");
  const lang = useLang();
  const savedImport = useGetSavedImportsByIntegration(importOption.id);
  const saveKeyMutation = useSaveKeyMutation(importOption);
  const updateKeyMutation = useUpdateKeyMutation(importOption);
  const defaultTickers: CurrencyIdentifier[] =
    importMethod.options?.tickers || [];
  const [loading, setLoading] = useState(false);
  const [importFromModal, setImportFromModal] = useState(false);
  const importFormContext = useContext(ImportFormContext);
  const isInImportErrorExperiment = useInImportErrorReportExperiment();
  const hasErrorIssue =
    !!importFormContext?.importIssue && !!isInImportErrorExperiment;
  const isOnboardingMobileImportExperiment =
    useIsOnboardingMobileImportExperiment();

  const {
    formController,
    initTickers,
    setInitTickers,
    tickers,
    setTickers,
    checked,
    setChecked,
    importFromTime,
    editingApiId,
    isUpdatingKey,
    handleCancelEdit,
    options,
  } = controller;

  const { handleSubmit, setValue, control } = formController;

  const onSubmit = (formData: APIFormValues) => {
    // Load for 1 second and return to imports page
    setLoading(true);
    setTimeout(() => {
      if (importFormContext?.setShowImportForm) {
        importFormContext.setShowImportForm(false);
      }

      setSyncStarted({
        value: true,
        accountId: importOption.id,
        savedIntegration: { type: ImportMethod.API },
      });

      if (Boolean(savedImport) && editingApiId) {
        const updatePayload: KeyUpdateDetails = isUpdatingKey
          ? {
              name: formData.name,
              apiKey: formData.apiKey,
              secret: formData.secret,
              password: formData.password,
              uid: formData.uid,
              tickers,
            }
          : {
              name: formData.name,
            };
        updateKeyMutation.mutate({
          importOption,
          id: editingApiId,
          data: updatePayload,
        });
        handleCancelEdit();
      } else {
        if (!importOption) return;
        if (tickersRequired && checked && !tickers.length) {
          alert(
            `${importOption.name} requires all tickers to be specified when requesting trade histories through their API. Please add ALL tickers you traded to return accurate tax reports. Alternatively you can import using the CSV option.`,
          );
          return;
        }
        if (formData.importFromTimeMillis || tickers.length) {
          captureAnalytics(advancedOptionsKey("modified"), {
            integration: importOption.id,
            importMethod: importMethod.type,
            isRequiredModification: !formData.importFromTimeMillis,
          });
        }
        saveKeyMutation.mutate({
          importOption,
          key: {
            ...formData,
            importFromTimestamp: formData.importFromTimeMillis,
            tickers,
          },
        });
        setTickers([]);
        setInitTickers([]);
        handleCancelEdit();
      }
      setImportFromModal(false);
      setLoading(false);
    }, secureConnectDelay);
  };

  const setSelect = (values: CurrencyIdentifier[] | undefined) => {
    if (!values) {
      setTickers([]);
      return;
    }

    setTickers(values.map((v) => v.id));
  };

  function checkModal(formData: APIFormValues) {
    if (formData.importFromTimeMillis) {
      setImportFromModal(true);
      return;
    }
    onSubmit(formData);
  }

  const handleImportFromChange = (date: string) => {
    const value = date ? moment(date).valueOf() : undefined;
    setValue("importFromTimeMillis", value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const isNicknameUpdateMode = editingApiId && !isUpdatingKey;
  const isEditing = Boolean(editingApiId);

  const existing = (savedImport?.keys.length || 0) > 0;
  const existingApiKeys = savedImport?.keys.map(({ key }) => key) || [];

  const advancedImportOptionsVisibility = {
    "import-from": !isUpdatingKey, // Only display the "Import From" field when not updating the key, as simply disabling it doesn't allow us to pass validation.
    "sync-margin": importOption.id === TICKERS_EXCHANGE,
    "select-margin": checked && importOption.id === TICKERS_EXCHANGE,
  };

  // show the oauth if we don't have any legacy keys
  return (
    <>
      <ConfirmationDialog
        isOpen={importFromModal}
        title={lang.imports.importFrom.modal.title}
        text={lang.imports.importFrom.modal.text}
        actionText={lang.imports.importFrom.modal.actionText}
        handleClose={() => {
          setImportFromModal(false);
        }}
        handleAction={handleSubmit(onSubmit)}
        critical
      />
      <Grid container direction="column" spacing={1}>
        <MuiImportGrid item xs={12}>
          <form onSubmit={handleSubmit(checkModal)}>
            {apiKeyRequired && (
              <Controller
                control={control}
                name="apiKey"
                rules={{
                  required: lang.api.importRules.missingApi,
                  validate: {
                    exists: (value) =>
                      !(!editingApiId && existingApiKeys.includes(value)) ||
                      lang.api.importRules.existingApi,
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    placeholder={savedImport?.keys[0]?.key}
                    variant="outlined"
                    margin="normal"
                    className="fs-exclude"
                    type="text"
                    label={lang.api.apiKeyLabel}
                    disabled={(isEditing && !isUpdatingKey) || loading}
                    fullWidth
                    sx={{
                      display: "block",
                      backgroundColor: "transparent",
                      ".MuiOutlinedInput-input": {
                        backgroundColor: tokens.background.input.default,
                      },
                    }}
                    InputLabelProps={{ required: true }}
                    inputProps={{ autocomplete: "off" }}
                    error={invalid || hasErrorIssue}
                    helperText={error?.message}
                  />
                )}
              />
            )}
            {secretRequired && !isNicknameUpdateMode && (
              <Controller
                control={control}
                name="secret"
                rules={{
                  required: lang.api.importRules.missingSecret,
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    className="fs-exclude"
                    type="text"
                    label={lang.api.secretLabel}
                    disabled={loading}
                    InputLabelProps={{ required: true }}
                    inputProps={{ autocomplete: "off" }}
                    error={invalid || hasErrorIssue}
                    helperText={error?.message}
                    fullWidth
                    sx={{
                      backgroundColor: "transparent",
                      ".MuiOutlinedInput-input": {
                        backgroundColor: tokens.background.input.default,
                      },
                    }}
                  />
                )}
              />
            )}

            {passwordRequired && !isNicknameUpdateMode && (
              <Controller
                control={control}
                name="password"
                rules={{
                  required: lang.api.importRules.missingPassphrase,
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    className="fs-exclude"
                    type="search" //to stop lastpass autofill https://stackoverflow.com/a/58290773
                    label={lang.api.passwordLabel}
                    disabled={loading}
                    InputLabelProps={{ required: true }}
                    inputProps={{ autocomplete: "off" }}
                    error={invalid}
                    helperText={error?.message}
                    fullWidth
                    sx={{
                      backgroundColor: "transparent",
                      ".MuiOutlinedInput-input": {
                        backgroundColor: tokens.background.input.default,
                      },
                    }}
                  />
                )}
              />
            )}
            {uidRequired && !isNicknameUpdateMode && (
              <Controller
                control={control}
                name="uid"
                rules={{
                  required: lang.api.importRules.missingUid,
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    className="fs-exclude"
                    type="text"
                    label={lang.api.uidLabel}
                    disabled={loading}
                    InputLabelProps={{ required: true }}
                    error={invalid}
                    helperText={error?.message || lang.api.importRules.uidHint}
                    fullWidth
                    sx={{
                      backgroundColor: "transparent",
                      ".MuiOutlinedInput-input": {
                        backgroundColor: tokens.background.input.default,
                      },
                    }}
                  />
                )}
              />
            )}
            {isOnboardingMobileImportExperiment ? null : (
              <>
                <ControllerName control={control} />
                <ImportTypeEtaBox
                  avgMs={importMethod.avgMs}
                  p95Ms={importMethod.p95Ms}
                />
                {
                  // Note: Only show the advanced options if at least one of input fields is visible
                  Object.values(advancedImportOptionsVisibility).some(
                    (value) => value,
                  ) && (
                    <AdvancedImportOptions
                      importOption={importOption}
                      importMethod={ImportMethod.API}
                    >
                      {advancedImportOptionsVisibility["import-from"] && (
                        <ImportFromSelector
                          disabled={isUpdatingKey || isEditing || loading}
                          control={control}
                          handleChange={handleImportFromChange}
                          importFromTime={importFromTime}
                        />
                      )}
                      {
                        // NOTE: if this gets expanded out please refactor, don't just add to this
                        advancedImportOptionsVisibility["sync-margin"] && (
                          <Box display="flex" alignItems="center">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  disabled={initTickers.length > 0 || loading}
                                  onChange={(_, checked) => {
                                    setChecked(checked);
                                  }}
                                  color="primary"
                                  size="small"
                                />
                              }
                              label={
                                lang.api.exchanges[TICKERS_EXCHANGE]
                                  .syncMarginTickers
                              }
                            />
                          </Box>
                        )
                      }
                      {advancedImportOptionsVisibility["select-margin"] && (
                        <>
                          <Box mt={1} ml={1} p={1} borderRadius="4px">
                            <Typography variant="Metropolis/Body/Light" gutterBottom>
                              {
                                lang.api.exchanges[TICKERS_EXCHANGE]
                                  .syncMarginTickersExplanation
                              }
                            </Typography>
                          </Box>
                          <CurrencySelect
                            name={
                              lang.api.exchanges[TICKERS_EXCHANGE]
                                .crossMarginCurrencies
                            }
                            selection={tickers}
                            selectionDisabled={initTickers}
                            setSelection={setSelect}
                            selectionOptions={defaultTickers}
                            selectionOptionsAlreadySorted={false}
                            inline
                            search
                          />
                        </>
                      )}
                    </AdvancedImportOptions>
                  )
                }
                {existing ? (
                  <Box display="flex" justifyContent="flex-start" mt="1rem">
                    {isEditing && (
                      <Box mx="1rem">
                        <TertiaryButton
                          disabled={loading}
                          onClick={() => {
                            if (options.onCancel) options.onCancel();
                            if (!options.isEditLocked) handleCancelEdit();
                          }}
                        >
                          {lang.cancel}
                        </TertiaryButton>
                      </Box>
                    )}
                    <Box width="100%">
                      <SecureConnectModule
                        isEditing={isEditing}
                        loading={loading}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box mt="1rem">
                    <SecureConnectModule
                      isEditing={isEditing}
                      loading={loading}
                    />
                  </Box>
                )}
              </>
            )}
          </form>
        </MuiImportGrid>
        {isOnboardingMobileImportExperiment ? (
          <OnboardingNavBar
            importOption={importOption}
            importMethod={importMethod}
            handleSubmit={handleSubmit(onSubmit)}
          />
        ) : null}
      </Grid>
    </>
  );
}

function ControllerName({ control }: { control: Control<APIFormValues> }) {
  const { tokens } = useDesign();
  const lang = useLang();
  return (
    <Controller
      control={control}
      name="name"
      render={({ field }) => (
        <TextField
          {...field}
          variant="outlined"
          margin="normal"
          type="text"
          label={lang.api.name}
          fullWidth
          sx={{
            display: "block",
            backgroundColor: "transparent",
            ".MuiOutlinedInput-input": {
              backgroundColor: tokens.background.input.default,
            },
          }}
        />
      )}
    />
  );
}

export const MuiImportGrid = styled(Grid)`
  max-width: 100% !important;
`;

export const StyledImportTitleTypography = styled(Typography)`
  font-size: "1rem";
  font-weight: 600;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.tokens.text.high};
`;
