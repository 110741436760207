import { useGridApiContext, useGridApiEventHandler } from "@mui/x-data-grid";

// This is not a custom component, but a way to add listeners within the scope of the grid context
// we should be able to use the apiRef outside of the grid context, but that wasn't working for some reason

export function EventListeners() {
  const apiRef = useGridApiContext();
  // when the sort model changes, go to the first page
  const goToFirstPage = () => {
    apiRef.current.setPage(0);
  };

  useGridApiEventHandler(apiRef, "sortModelChange", goToFirstPage);

  return <></>;
}
