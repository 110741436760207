import { MenuList } from "@mui/material";
import styled from "styled-components/macro";

export const CommandMenuListStyled = styled(MenuList)`
  && {
    padding: 0;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    max-height: 408px;
    overflow: auto;
  }
`;
