import { Box } from "@mui/material";
import styled from "styled-components/macro";

export const StyledChip = styled(Box)`
  background: ${({ theme }) => theme.tokens.background.success.default};
  border: 1px solid ${({ theme }) => theme.tokens.border.success};
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
