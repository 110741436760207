export default {
  importInstructions: {
    abra: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Crypto Tax Calculator ignorerà i trasferimenti che coinvolgono criptovalute che generano interessi, poiché non si tratta di eventi imponibili, pertanto il numero di righe importate potrebbe essere inferiore al numero totale di righe originali nel CSV.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Si prega di leggere e seguire i passaggi dettagliati per l'esportazione di Abra sul loro sito web: https://support.abra.com/hc/en-us/articles/360002528832-How-can-I-obtain-my-full-transaction-history- per ottenere un file CSV tramite e-mail.",
              1: () =>
                "Una volta ricevuta l'email con il file CSV, scaricalo e carica il file CSV qui.",
              2: () =>
                "Se hai più portafogli Abra, ripeti questi passaggi per ciascuno di essi.",
            },
          },
        },
      },
    },
    abstract: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Open your Abstract wallet",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the address into Crypto Tax Calculator, add an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    agoric: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Agorico nello sviluppo iniziale",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Agoric nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    airswap: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e clicca sull'icona di ricerca",
              2: () => "Seleziona lo scambio dal menu a discesa",
              3: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              4: () => "Mantieni le altre impostazioni predefinite",
              5: () => 'Fai clic su "Recupera cronologia"',
              6: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              7: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    akash: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Akash nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    algorand: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () =>
                "Copia l'indirizzo del tuo portafoglio pubblico Algorand",
              2: () =>
                "In CTC, seleziona la blockchain pertinente dal menu a discesa",
              3: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico e clicca su "Importa portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    altcointrader: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account AltCoinTrader",
              1: () =>
                "Scarica i tuoi depositi, prelievi e tutti i tuoi file CSV di trading da AltCoinTrader",
              2: () => "Carica questi file qui nel Crypto Tax Calculator",
            },
          },
        },
      },
    },
    aptos: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio Aptos",
              1: () =>
                "Copia l'indirizzo del tuo portafoglio pubblico che inizia con '0x...'",
              2: () =>
                'Incolla l\'indirizzo nel Crypto Tax Calculator, aggiungi un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    arbitrum: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ora supportiamo i token ERC-1155, tuttavia non siamo in grado di importare i tuoi saldi correnti per questi token. Ciò potrebbe causare discrepanze tra il saldo importato dall'API di origine e il saldo calcolato dall'app.",
            },
            title: () => "Token ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio che hai utilizzato per fare trading tramite Arbitrum (iniziando con 0x)",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    "arbitrum-nova": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio che hai utilizzato per fare trading tramite Arbitrum (iniziando con 0x)",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    archway: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Archway nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    atani: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Atani è attualmente in versione beta e in fase di ulteriore sviluppo. Informa il nostro team di supporto se riscontri problemi di integrazione.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Atani",
              1: () => "Passare alla scheda Portfolio",
              2: () =>
                "Trova e fai clic su Scarica report, quindi scegli Dati storici",
              3: () => "Scegli l'intervallo di date che preferisci",
              4: () => "Premi Scarica per ottenere il tuo report",
            },
          },
        },
      },
    },
    atomicwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "A causa delle limitazioni del CSV della cronologia delle transazioni di Atomic Wallet, non siamo in grado di importare automaticamente il lato acquisto di qualsiasi transazione in-app. Se hai effettuato delle transazioni in Atomic Wallet, genera il lato acquisizione della transazione tramite il nostro pulsante `Aggiungi transazione manuale` o importando un CSV personalizzato utilizzando le istruzioni `Importazione di transazioni personalizzate` di seguito.",
            },
            title: () => "Scambi in-app",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Nella scheda Portafoglio, seleziona la moneta di cui desideri conoscere la cronologia.",
              1: () =>
                "Fare clic sull'icona dell'ingranaggio delle impostazioni nell'angolo in alto a destra.",
              2: () => "Fare clic su Esporta transazioni.",
              3: () =>
                "Un file CSV con la cronologia delle transazioni per quella valuta dovrebbe essere stato scaricato nella cartella Desktop.",
              4: () =>
                "Importa quel file in Crypto Tax Calculator e specifica il simbolo della valuta. Ad esempio, per un portafoglio Bitcoin dovresti incollare 'BTC'",
            },
          },
          1: {
            title: () =>
              "Nota: non utilizzare la pagina Cronologia sulla barra dei menu per generare un report CSV. È necessario generare un report separato per ogni valuta con cui hai interagito",
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio Atomic.",
              1: () =>
                "Seleziona la criptovaluta che desideri importare. Ad esempio, Bitcoin o Ethereum.",
              2: () =>
                "Copia l'indirizzo del portafoglio pubblico corrispondente per quell'asset. Per trovarlo, potresti dover cliccare su 'Ricevi'.",
              3: () =>
                "In CTC, seleziona la rete blockchain corrispondente dal menu a discesa.",
              4: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico in CTC e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    aurora: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Al momento stiamo riscontrando alcuni problemi nella raccolta delle transazioni di bridging e stiamo lavorando a una soluzione. Se nel frattempo riscontri questo problema, prova invece il nostro processo di caricamento CSV manuale. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Transazioni ponte",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Aurora nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    avalanche: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Potrebbero verificarsi problemi durante l'importazione di tutti gli indirizzi forniti, poiché Avalanche cerca di nascondere le relazioni tra gli indirizzi per motivi di sicurezza.",
              1: () =>
                "Di conseguenza, potrebbe essere necessario importare indirizzi aggiuntivi come descritto in questa guida: https://cryptotaxcalculator.io/integrations/avalanche-tax/#please-note.",
            },
          },
          1: {
            items: {
              0: () =>
                "A causa di problemi con il nostro provider API non siamo in grado di recuperare le tue transazioni sulla catena X e P. Stiamo attualmente esaminando provider alternativi per risolvere questo problema.",
            },
            title: () => "Transazioni della catena X e P mancanti",
          },
          2: {
            items: {
              0: () =>
                "Ora supportiamo i token ERC-1155, tuttavia non siamo in grado di importare i tuoi saldi correnti per questi token. Ciò potrebbe causare discrepanze tra il saldo importato dall'API di origine e il saldo calcolato dall'app.",
            },
            title: () => "Token ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Per importare il tuo indirizzo C-Chain (che inizia con 0x), inseriscilo semplicemente in Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    bambooRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    base: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio base nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    bch: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
              1: () =>
                "Supportiamo anche gli indirizzi xpub, ypub e zpub, tuttavia è preferibile xpub",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Bitcoin Cash nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    berachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ora supportiamo i token ERC-1155, tuttavia non siamo in grado di importare i tuoi saldi correnti per questi token. Ciò potrebbe causare discrepanze tra il saldo importato dall'API di origine e il saldo calcolato dall'app.",
            },
            title: () => "Token ERC-1155",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Berachain nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    bigone: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account BigONE",
              1: () => "Vai alle impostazioni del tuo account",
              2: () =>
                'In "Impostazioni Exchange" puoi generare una chiave API',
              3: () =>
                'Inserisci sia la "chiave API" che il "segreto API" qui nel calcolatore delle imposte sulle criptovalute',
            },
          },
        },
      },
    },
    binance: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente supportiamo la sincronizzazione API dei record storici per le seguenti operazioni: depositi e prelievi di criptovalute, depositi e prelievi di valute fiat, operazioni spot, conversioni e distribuzioni di piccoli saldi BNB 'Dust'",
              1: () =>
                "Binance API ha alcune limitazioni: le transazioni effettuate con monete delistate non possono essere sincronizzate. Se hai transazioni come queste, ti consigliamo di caricare la cronologia tramite CSV",
              2: () =>
                "A causa delle limitazioni dell'API di Binance, è possibile recuperare solo gli ultimi 90 giorni di transazioni OTC e conversioni cripto-cripto.",
              3: () =>
                "Da settembre 2020 Binance non fornisce un endpoint API per ottenere dati storici per determinati acquisti di monete da transazioni 'Cash Balance'. Inoltre Binance non fornisce un endpoint per 'Locked Staking' (https://dev.binance.vision/t/earn-locked-staking/1453)",
              4: () =>
                "Binance non fornisce un endpoint API per recuperare dati storici per Fiat Purchase History, Binance Leveraged Tokens (BLVT), Binance Liquid Swap (Swap), Binance Savings Interest, USD-M Futures Income, COIN-M Futures Income, Cross Margin Trades e Isolated Margin Trades",
              5: () => "Per aggiungere transazioni non supportate:",
              6: () => "1. Scarica e apri il modello CSV",
              7: () => "2. Compila i dettagli della tua transazione",
              8: () =>
                "3. Assicurati di compilare tutti i campi inclusi per evitare errori durante il caricamento",
              9: () => "4. Esporta il tuo documento come CSV e caricalo qui",
              10: () => "5. Aggiungi CSV direttamente al portafoglio associato",
            },
            title: () => "Sincronizzazione API - Transazioni mancanti",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Nel tuo account Binance, vai su "Gestione API" dal menu a discesa "Profilo". (https://www.binance.com/en/my/settings/api-management)',
              1: () =>
                'Fare clic su "Crea API" e scegliere "Generato dal sistema" come tipo di chiave API, quindi fare clic su "Avanti".',
              2: () =>
                "Imposta l'etichetta che preferisci, clicca su \"Avanti\" e completa l'autenticazione a due fattori.",
              3: () =>
                'Impostare l\'accesso IP su "Senza restrizioni" e assicurarsi che sia selezionata la casella "Abilita lettura".',
              4: () =>
                'Incolla l\'API e le chiavi segrete in Crypto Tax Calculator e clicca su "Aggiungi API Binance".',
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/19oc30s0mVM",
            title: () => "Come importare la tua API Binance",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Binance ha recentemente limitato le esportazioni CSV a un intervallo massimo di cronologia di 3 mesi alla volta, con solo 6 esportazioni al mese consentite. Se hai fatto molto trading, ti consigliamo di usare l'opzione di importazione API",
              1: () =>
                "Attualmente la nostra opzione di importazione CSV supporta depositi, prelievi, transazioni Fiat, Spot, Coin-Futures, USDT-Futures, margine isolato e cross margin.",
              2: () =>
                "Se hai ricevuto una cartella '.zip' o '.tar.gz' da Binance, dovrai prima estrarre il contenuto della cartella compressa. Puoi usare un servizio come https://extract.me/ per estrarre il contenuto. Puoi quindi caricare i file estratti su Crypto Tax Calculator",
              3: () =>
                "Binance non include le commissioni di trading per le negoziazioni P2P nel suo CSV. Consigliamo di importare tramite API se hai fatto trading P2P.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account Binance e vai su Portafoglio > Panoramica.",
              1: () => "Fare clic su Ordini > Cronologia transazioni.",
              2: () =>
                'Fare clic su "Esporta record transazioni" in alto a destra.',
              3: () =>
                'Seleziona "Personalizza" per l\'intervallo di tempo e imposta le date di inizio/fine (fino a 12 mesi alla volta).',
              4: () =>
                'Assicurati che sia selezionato "Tutti" per Account, Coin e Sottoaccount.',
              5: () => 'Fare clic su "Genera" e attendere il report.',
              6: () =>
                "Ripeti la stessa operazione per l'intera cronologia delle tue negoziazioni.",
              7: () =>
                "Se il file è '.zip' o '.tar.gz', estrailo prima di caricarlo.",
              8: () => "Carica i file estratti su Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/N7TNcCW2N7g",
            title: () => "Come importare i file CSV di Binance",
          },
        },
      },
    },
    "binance-smart-chain": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ora supportiamo i token ERC-1155, tuttavia non siamo in grado di importare i tuoi saldi correnti per questi token. Ciò potrebbe causare discrepanze tra il saldo importato dall'API di origine e il saldo calcolato dall'app.",
            },
            title: () => "Token ERC-1155",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Binance Smart Chain nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    binancechain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Binance ha ufficialmente chiuso Binance Chain (BNB Beacon Chain). Di conseguenza, i dati storici di questa catena non possono più essere ottenuti tramite API. Le transazioni importate in precedenza rimarranno accessibili nel tuo account. Puoi comunque visualizzare la cronologia delle tue transazioni su https://explorer.bnbchain.org/ fino al 3 dicembre 2024. Per aggiungere nuove transazioni, utilizza l'opzione di importazione CSV personalizzata. Per maggiori dettagli sulla chiusura, visita https://www.bnbchain.org/en/bnb-chain-fusion.",
            },
            title: () => "Chiusura di Binance Chain (BNB Beacon Chain)",
          },
        },
        step: {
          0: {
            title: () =>
              "Binance ha ufficialmente chiuso la Binance Chain (BNB Beacon Chain). Di conseguenza, i dati storici di questa catena non possono più essere ottenuti tramite API.",
          },
        },
      },
    },
    binancedex: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ciò sincronizzerà le negoziazioni eseguite su Binance DEX e tutte le transazioni Binance Chain associate a questo portafoglio",
              1: () =>
                'In alcune occasioni non sono disponibili dati per la ricezione di token BNB e BEP2 tramite un Cross Chain Bridge nel tuo portafoglio Binance Chain. In questo caso potresti dover inserire manualmente le rispettive transazioni "ricevi" in Crypto Tax Calculator',
              2: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio Binance Chain (BEP2) che hai utilizzato per fare trading tramite Binance DEX",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    binanceus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente supportiamo la sincronizzazione API dei record storici per le seguenti operazioni: depositi e prelievi di criptovalute, depositi e prelievi di valute fiat, operazioni spot, conversioni e distribuzioni di piccoli saldi BNB 'Dust'",
              1: () =>
                "Binance API ha alcune limitazioni: le transazioni effettuate con monete delistate non possono essere sincronizzate. Se hai transazioni come queste, ti consigliamo di caricare la cronologia tramite CSV",
              2: () =>
                "A causa delle limitazioni dell'API di Binance, è possibile recuperare solo gli ultimi 90 giorni di transazioni OTC e conversioni cripto-cripto.",
              3: () =>
                "Da settembre 2020 Binance non fornisce un endpoint API per ottenere dati storici per determinati acquisti di monete da transazioni 'Cash Balance'. Inoltre Binance non fornisce un endpoint per 'Locked Staking' (https://dev.binance.vision/t/earn-locked-staking/1453)",
              4: () =>
                "Binance non fornisce un endpoint API per recuperare dati storici per Fiat Purchase History, Binance Leveraged Tokens (BLVT), Binance Liquid Swap (Swap), Binance Savings Interest, USD-M Futures Income, COIN-M Futures Income, Cross Margin Trades e Isolated Margin Trades",
              5: () => "Per aggiungere transazioni non supportate:",
              6: () => "1. Scarica e apri il modello CSV",
              7: () => "2. Compila i dettagli della tua transazione",
              8: () =>
                "3. Assicurati di compilare tutti i campi inclusi per evitare errori durante il caricamento",
              9: () => "4. Esporta il tuo documento come CSV e caricalo qui",
              10: () => "5. Aggiungi CSV direttamente al portafoglio associato",
            },
            title: () => "Sincronizzazione API - Transazioni mancanti",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account BinanceUS",
              1: () =>
                'In alto a destra della barra di navigazione, passa il mouse sull\'icona "Profilo" e clicca su "Gestione API" dal menu a discesa (https://www.binance.com/en/my/settings/api-management)',
              2: () =>
                'Inserisci l\'etichetta per una nuova API come "Calcolatrice delle imposte sulle criptovalute" e fai clic su "Crea API" (pulsante giallo)',
              3: () =>
                "Potrebbe essere necessario inserire alcune informazioni di verifica",
              4: () =>
                'Una volta creata, copia la "Chiave API" e la "Chiave segreta"',
              5: () =>
                "Fai clic su Modifica restrizioni e deseleziona 'Abilita trading spot'. Assicurati inoltre che 'Abilita prelievi' sia deselezionato",
              6: () =>
                'Controlla attentamente che "Può leggere" sia l\'unica opzione selezionata!',
              7: () =>
                'Lasciare la selezione della restrizione di accesso IP su "Senza restrizioni"',
              8: () =>
                'Incolla la "chiave API" e la "chiave segreta" copiate qui nel calcolatore delle imposte sulle criptovalute e fai clic su "Aggiungi API"',
              9: () =>
                "La sincronizzazione di questa API può richiedere circa 20 minuti a causa delle restrizioni da parte di BinanceUS",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente la nostra opzione di importazione CSV supporta solo Depositi, Prelievi e Negoziazioni Fiat/Spot Wallet. Se hai altri tipi di record storici, importali tramite la nostra opzione 'configura Autosync'.",
              1: () =>
                "Se hai ricevuto una cartella '.zip' o '.tar.gz' da BinanceUS, dovrai prima estrarre il contenuto della cartella compressa. Puoi usare un servizio come https://extract.me/ per estrarre il contenuto. Puoi quindi caricare i file estratti su Crypto Tax Calculator.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Accedi al tuo account BinanceUS e clicca su "Portafoglio" nella barra di navigazione.',
              1: () =>
                'Selezionare l\'opzione "Esporta report" scritta in giallo sul lato destro.',
              2: () =>
                "In Report personalizzati, seleziona Tutto per il tipo di Asset e Transazioni; per Intervallo, seleziona il periodo desiderato (fino a un massimo di un anno alla volta, vedi passaggio 5) e seleziona CSV per Formato file.",
              3: () =>
                'Fare clic su "Genera" e attendere che il report venga generato: potrebbe volerci un po\' di tempo.',
              4: () =>
                "Ripeti l'esportazione per ogni anno in cui hai fatto trading su BinanceUS.",
              5: () =>
                "Se il file è in formato '.zip' o '.tar.gz', sarà necessario estrarre prima il contenuto della cartella compressa prima di caricarlo; fare riferimento alle note.",
              6: () => "Carica i file su Crypto Tax Calculator.",
            },
            title: () => "Per depositi e prelievi e operazioni su conti spot:",
          },
        },
      },
    },
    bingx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account BingX e vai su Risorse > Record delle risorse",
              1: () =>
                'Fare clic su "Esporta" nella pagina della cronologia delle transazioni o utilizzare questo collegamento: https://bingx.com/en/transaction-history/?timeKey=ninetyDaysFlag',
              2: () =>
                "Seleziona l'intervallo di tempo e scegli tutti gli account: Fondo, USD-M Perpetual Futures, Coin-M Perpetual Futures e Standard Futures.",
              3: () =>
                'Imposta il tipo di file su Excel e il formato dei dati su Standard, quindi fai clic su "Genera".',
              4: () =>
                "Attendi che il file Excel venga generato e scaricalo non appena sarà pronto.",
              5: () =>
                "Apri il file Excel, che conterrà quattro fogli separati. Salva ogni foglio come un file .csv individuale e importali in CTC uno alla volta.",
            },
          },
        },
      },
    },
    bitaroo: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Bitaroo",
              1: () =>
                "Scarica i tuoi file CSV di trading, deposito e prelievo da Bitaroo",
              2: () => "Carica questi file qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitbuy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Il report dettagliato di Bitbuy per l'attività di trading a volte non include depositi o prelievi da e verso Bitbuy. Se hai depositi e prelievi, contatta il nostro supporto per ricevere assistenza nell'importazione di queste transazioni.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account Bitbuy qui (https://app.bitbuy.ca/sign-in).",
              1: () =>
                'Fai clic sul menu a discesa accanto al tuo nome (in alto a destra) e seleziona "Documenti".',
              2: () =>
                'Per ottenere la cronologia delle transazioni prima del 25 maggio 2024, vai alla scheda "Documenti legacy" e scarica i tuoi file.',
              3: () =>
                'Per tutte le attività successive al 25 maggio 2024, vai alla scheda "Estratti conto" e scarica i file CSV.',
              4: () =>
                "Carica tutti i file CSV scaricati qui in Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    bitcoindotcomau: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Contatta il team di supporto di bitcoin.com.au per generare o modificare la tua chiave API",
              1: () => "Copia e incolla le chiavi in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitfinex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento non supportiamo i dati di trading sui derivati Bitfinex. Se hai effettuato trading sui derivati tramite Bitfinex, contatta l'assistenza.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a Bitfinex.",
              1: () =>
                'Nell\'angolo in alto a destra, passa il mouse sull\'icona della persona e seleziona "API keys" dal menu a discesa. Questo dovrebbe portarti a "https://www.bitfinex.com/api".',
              2: () => 'Fare clic sulla scheda "Crea nuova chiave".',
              3: () =>
                'Assicurarsi che le autorizzazioni predefinite non siano cambiate e che tutte le autorizzazioni di "Lettura" siano state abilitate.',
              4: () =>
                'In fondo alla pagina, inserisci un nome per la chiave API (ad esempio Crypto Tax Calculator) nel campo "Etichetta la tua chiave API", quindi fai clic sul pulsante "Genera chiave API".',
              5: () =>
                "Se hai abilitato la 2FA, potrebbe essere necessario immettere il token 2FA.",
              6: () =>
                "Dopo aver ricevuto un'e-mail di verifica da Bitfinex, conferma la nuova chiave API.",
              7: () =>
                'Copia la "chiave API" e il "segreto della chiave API" e inseriscili entrambi in Crypto Tax Calculator.',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento non supportiamo i dati di trading sui derivati Bitfinex. Se hai effettuato trading sui derivati tramite Bitfinex, contatta l'assistenza.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a Bitfinex.",
              1: () =>
                'Nell\'angolo in alto a destra, passa il mouse su "Wallet" e seleziona "Report" dal menu a discesa. Questo dovrebbe portarti a "https://report.bitfinex.com".',
              2: () =>
                "Fare clic sull'icona del calendario nella parte superiore della pagina.",
              3: () =>
                "Seleziona un intervallo di date personalizzato che includa l'intera cronologia delle tue negoziazioni.",
              4: () =>
                'Fare clic sul pulsante "Esporta", dovrebbe apparire un popup.',
              5: () =>
                "Nel campo 'Dati da esportare' seleziona solo 'Registri' e 'Movimenti'. Seleziona il 'Formato data' come 'GG-MM-AA'. Seleziona la casella che dice 'Visualizza millisecondi'",
              6: () =>
                'Fai clic sul pulsante "Esporta" in fondo al popup. Verranno generati file CSV che verranno inviati alla tua EMAIL.',
              7: () =>
                "Nel tuo client di posta elettronica, apri l'email e clicca su 'Download CSV'. Questo scaricherà un file .zip contenente due CSV (uno per i registri e uno per i movimenti).",
              8: () =>
                "Estrarre e caricare ENTRAMBI i file CSV su Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    bitflyer: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Bitflyer",
              1: () => "Vai all'API",
              2: () => 'Fare clic sul pulsante "Genera nuova chiave"',
              3: () =>
                'Copia la "chiave API" e il "segreto API" e inseriscili entrambi qui nel calcolatore delle imposte sulle criptovalute',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account BitFlyer",
              1: () => "Scarica la cronologia delle tue transazioni",
              2: () => "Carica il file CSV in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitforex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Se il caricamento dei file CSV non riesce, prova a cambiare l'estensione da '.csv' a '.xlsx' e riprova.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account BitForex",
              1: () =>
                "Contatta l'assistenza e chiedi i tuoi file CSV di trading",
              2: () => "Carica questi file qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitget: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "Per recuperare dati più vecchi, importa le tue negoziazioni tramite CSV.",
            },
            title: () =>
              "L'API di Bitget fornisce solo gli ultimi 90 giorni di cronologia delle negoziazioni",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a Bitget",
              1: () =>
                "Passare alla gestione API dalla barra di navigazione in alto",
              2: () => "Dal menu a discesa, seleziona Gestione API",
              3: () => "Fare clic su Crea una nuova API",
              4: () => "Fare clic su Chiave API generata dal sistema",
              5: () =>
                "Nella finestra pop-up, crea una passphrase per la tua API",
              6: () =>
                "In Autorizzazioni, seleziona Sola lettura per tutte le caselle di controllo",
              7: () =>
                'Completa la verifica a due fattori e clicca su "Avanti".',
              8: () => "Copia la tua chiave API e il segreto",
            },
          },
        },
      },
    },
    bitgo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Assicurati che il tuo report comprenda tutti gli anni in cui hai fatto trading di criptovalute",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account BitGo tramite un browser web",
              1: () =>
                'Vai alla sezione "Report" dalla barra di navigazione in alto',
              2: () => 'Seleziona "Tutte le monete/gettoni" nel menu a discesa',
              3: () => "Scarica il tuo report come file CSV",
              4: () =>
                "Carica il file che hai appena scaricato qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitkeep: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento sono supportati solo gli indirizzi del portafoglio Solana memorizzati nel portafoglio BitKeep",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio blockchain Solana che hai utilizzato per fare trading tramite BitKeep",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    bitmart: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Bitmart.",
              1: () =>
                "Passa il mouse sul logo dell'Account nell'angolo in alto a destra.",
              2: () => "Fare clic su Account.",
              3: () => "Fare clic su Gestione API.",
              4: () => "Inserisci il promemoria che preferisci.",
              5: () => "Fare clic su Invia.",
              6: () => "Copia la chiave API, il segreto e il Memo.",
              7: () => "Incolla nei campi pertinenti su CTC",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "BitMart non ha un'API con la cronologia completa delle transazioni, né un CSV disponibile nella sua UI. Dovrai inviare loro un'e-mail per richiedere l'esportazione CSV della cronologia delle tue transazioni. Ciò potrebbe richiedere 5 giorni.",
              1: () =>
                "Una volta ricevuto l'export della cronologia delle negoziazioni, puoi importarlo in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitmax: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente l'API AscendEX fornisce solo gli ultimi 30 giorni di cronologia delle negoziazioni",
              1: () => "Sarà necessario creare nuove chiavi API dopo 90 giorni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account AscendEX",
              1: () =>
                "Fare clic sull'icona dell'avatar nell'angolo in alto a destra",
              2: () =>
                'Fare clic su "Impostazioni API" e quindi su "Nuova chiave API"',
              3: () =>
                'Controlla l\'autorizzazione "Visualizza" per la chiave e inserisci il codice di verifica e-mail e il codice 2FA',
              4: () => 'Fai clic su "Genera chiave API"',
              5: () => "Copia la tua chiave API e il segreto",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "AscendEX al momento non ha un'esportazione CSV per i tuoi depositi/prelievi. Dovrai aggiungerlo manualmente.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account AscendEX",
              1: () =>
                'Seleziona "Il mio ordine" (https://bitmax.io/it/order/cash-order)',
              2: () => 'Fare clic su "Richiedi esportazione"',
              3: () =>
                'Puoi quindi fare clic sul pulsante a destra "I miei file esportati" e scaricare il file una volta completato',
              4: () =>
                "Carica il file CSV scaricato direttamente qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitmex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Assicurati di non aver abilitato alcuna autorizzazione sugli ordini durante la creazione della chiave API!",
              1: () =>
                "Se l'API non importa i tuoi dati, potrebbe essere dovuto al fatto che non hai completato la nuova politica di verifica KYC di BitMEX. Se ciò ti accade, completa la loro verifica KYC prima di riprovare.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account BitMEX",
              1: () =>
                "Fai clic sul tuo nome utente nell'angolo in alto a destra",
              2: () =>
                'Vai alla pagina dell\'account cliccando su "Account e sicurezza"',
              3: () =>
                'Sul lato sinistro della pagina seleziona "Chiavi API" (https://www.bitmex.com/app/apiKeys)',
              4: () =>
                "Quando si crea la chiave API, assicurarsi che i permessi della chiave siano vuoti, come indicato da '-'",
              5: () => "Controlla di non aver abilitato alcun permesso",
              6: () =>
                'Inserisci il tuo 2FA se richiesto e clicca su "Crea chiave API"',
              7: () =>
                'Copia l\'ID e il segreto e inseriscili come "Chiave API" e "Segreto API" qui nel calcolatore delle imposte sulle criptovalute',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () => "Sarà necessario salvare il CSV per ogni pagina di dati",
              1: () =>
                "Se il CSV non viene importato, potrebbe essere dovuto al fatto che i tuoi dati hanno un formato di timestamp diverso da quello che ci aspettavamo. Se ciò si verifica, contatta il nostro supporto.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account BitMEX",
              1: () =>
                "Fai clic sul tuo nome utente nell'angolo in alto a destra",
              2: () => "Vai ad account e sicurezza",
              3: () =>
                'Sul lato sinistro della pagina seleziona "Portafoglio - > Saldi" (https://www.bitmex.com/app/wallet)',
              4: () =>
                'Nell\'angolo in alto a destra clicca su "Salva come CSV"',
              5: () =>
                'Assicurati che il nome del file CSV appena scaricato inizi con "Wallet History". Esistono altri tipi di file CSV scaricabili che non supportiamo',
              6: () => "Carica il file CSV qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitpanda: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a Bitpanda e seleziona l'icona del panda nell'angolo in alto a destra.",
              1: () =>
                "Seleziona la chiave API e inserisci un nome per la tua chiave API.",
              2: () =>
                "Nell'ambito dell'ambito, controllare le transazioni, il saldo e le negoziazioni.",
              3: () => "Seleziona Genera nuova chiave API.",
              4: () =>
                "Fare clic su Conferma. Verrà generata un'e-mail di conferma.",
              5: () =>
                'Copia la chiave API e inseriscila come "chiave API" e "segreto API" qui nel calcolatore delle imposte sulle criptovalute',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a Bitpanda e seleziona l'icona del panda nell'angolo in alto a destra.",
              1: () =>
                "Selezionare Cronologia, quindi selezionare Cronologia transazioni in alto a destra.",
              2: () => "Esporta il CSV e importalo in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitrue: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente la nostra opzione di importazione CSV supporta solo depositi, prelievi e cronologia delle negoziazioni.",
              1: () =>
                "Se hai dei record che non supportiamo e desideri richiederne l'aggiunta, contattaci tramite la nostra chat di supporto",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a Bitrue",
              1: () =>
                "Passa il mouse su Trade nell'angolo in alto a sinistra e seleziona Spot. Quindi fai clic su Order History. In alternativa, puoi provare questo collegamento diretto (https://www.bitrue.com/order/spot/history-orders)",
              2: () => "Fare clic su Esporta in alto a destra",
              3: () =>
                'Nel popup modale, seleziona "Cronologia scambi" come opzione dati',
              4: () =>
                'Seleziona le date in cui hai effettuato la negoziazione e fai clic sul pulsante "OK"',
              5: () =>
                "Bitrue consente solo 3 mesi alla volta, quindi potrebbe essere necessario scaricare più volte per coprire l'intera cronologia di trading",
            },
          },
        },
      },
    },
    bitso: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Nella barra di navigazione in alto a destra, fai clic sull'opzione a discesa del profilo utente e fai clic su Cronologia (https://bitso.com/r/history/alltransactions)",
              1: () =>
                'Nella parte inferiore della tabella delle transazioni, dovresti vedere "Scarica le tue transazioni in un file CSV" seguito da quattro opzioni: depositi, prelievi, conversioni e negoziazioni',
              2: () =>
                "Fai clic su ciascuna opzione, quindi prendi i quattro file CSV appena scaricati e importali in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitsong: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio BitSong nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    bitstamp: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Ripetere i passaggi precedenti per aggiungere le chiavi API per tutti gli account associati.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai su Il mio profilo > Impostazioni > Impostazioni account > Accesso API",
              1: () => 'Fai clic su "Crea nuova chiave API"',
              2: () => 'Fare clic sul pulsante "Impostazioni avanzate"',
              3: () =>
                'Selezionare un account appropriato in "Account associato"',
              4: () =>
                'Assicurati che siano attivate solo le opzioni "Autorizzazioni generali > Visualizza saldo conto", "Autorizzazioni generali > Visualizza le tue transazioni" e "Guadagna > Visualizza e gestisci funzionalità Guadagna" e fai clic su "Crea nuova chiave API"',
              5: () =>
                'Inserisci il tuo codice Bitstamp 2FA e clicca su "Conferma"',
              6: () =>
                'Copia la chiave API e il segreto in Crypto Tax Calculator. NON cliccare ancora su "Aggiungi API Bitstamp". Nota che il segreto è disponibile solo per pochi minuti e non verrà più mostrato. Se perdi il tuo segreto, dovrai creare una nuova chiave API.',
              7: () => 'In Bitstamp, fai clic su "Attiva chiave API"',
              8: () =>
                "Bitstamp ti invierà un'email di conferma. Fai clic sul pulsante \"Attiva chiave API\" su quell'email.",
              9: () => 'Fai clic su "Aggiungi API Bitstamp"',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Le transazioni dei sottoconti sono già incluse nel CSV scaricato.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Bitstamp",
              1: () =>
                'Vai alla pagina Transazioni cliccando sull\'icona dell\'utente nell\'angolo in alto a destra, quindi su "Attività" e poi su "Cronologia transazioni" nella barra laterale sinistra (https://www.bitstamp.net/account/transactions)',
              2: () => 'Fare clic sul pulsante "Apri opzioni di esportazione"',
              3: () =>
                "Seleziona l'intervallo di date che copre tutta la cronologia delle tue transazioni",
              4: () => 'Seleziona "Tutte" per le valute',
              5: () => 'Seleziona "Tutti" per i tipi di transazione',
              6: () => "Seleziona 'RFC 4180 (nuovo)' per il formato CSV",
              7: () =>
                'Fare clic sul pulsante "Esporta selezione" per scaricare il file CSV',
              8: () =>
                "Carica questo CSV qui nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                'Fai clic su "Connetti a Bitstamp" per andare a Bitstamp Exchange',
              1: () => "Accedi al tuo account Bitstamp.",
              2: () =>
                "Se il tuo account ha 2FA abilitato, inserisci il codice richiesto. Altrimenti, salta questo passaggio.",
              3: () => "Seleziona il sottoaccount appropriato",
              4: () => 'Fare clic sul pulsante "Accetta"',
            },
          },
        },
      },
    },
    bittorrentchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Al momento stiamo riscontrando alcuni problemi nella raccolta di token ERC-1155 e nelle transazioni di bridging e stiamo lavorando a una soluzione. Se nel frattempo riscontri questo problema, prova invece il nostro processo di caricamento CSV manuale. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155 Token e transazioni bridge",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio BitTorrent Chain nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    bittrex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Bittrex",
              1: () => "Vai alle chiavi API",
              2: () => 'Fare clic sul pulsante "Aggiungi nuova chiave"',
              3: () =>
                'Assicurati che l\'autorizzazione "READ INFO" per la nuova chiave sia abilitata',
              4: () => "Una volta fatto, clicca sul pulsante 'Salva'",
              5: () => "Copia la chiave API e il segreto API",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Per scaricare la cronologia dei tuoi depositi/prelievi in formato CSV, devi inviare un ticket di supporto a Bittrex. Una volta ricevuti questi CSV, puoi caricarli su Crypto Tax Calculator. Per maggiori dettagli, vedi qui: https://bittrex.zendesk.com/hc/en-us/articles/360001359006-Where-s-my-Order-History-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Accedi a Bittrex, vai su "Ordini" nell\'angolo in alto e scorri verso il basso fino a Cronologia ordini.',
              1: () =>
                'Fai clic sul pulsante "Cronologia download", seleziona l\'anno in cui hai effettuato le negoziazioni e fai clic su "Scarica".',
              2: () =>
                "Puoi scaricare solo un anno alla volta, ma dovresti aggiungere ogni anno in cui hai effettuato operazioni di trading al Crypto Tax Calculator.",
              3: () =>
                "Assicurati di non aprire o salvare nuovamente il file prima di importarlo perché potresti compromettere il formato del CSV.",
            },
            title: () => "Per la cronologia degli ordini:",
          },
        },
      },
    },
    bitvavo: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente importiamo Depositi, Prelievi e Negoziazioni",
              1: () =>
                "Non possiamo importare il reddito da Staking poiché l'API Bitvavo al momento non lo supporta",
              2: () =>
                "Se utilizzi lo Staking, è possibile che i saldi per le valute in Staking da noi segnalati non corrispondano a quelli segnalati da Bitvavo, a meno che tu non inserisca manualmente le transazioni di reddito dello Staking.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi allo scambio Bitvavo",
              1: () =>
                "Vai alla pagina delle impostazioni API (https://account.bitvavo.com/user/api)",
              2: () => 'Fare clic sul pulsante "Richiedi nuova chiave API"',
              3: () =>
                "Genera chiavi API di sola lettura che non abilitano il trading o i prelievi",
              4: () =>
                "Inserisci la chiave API e il segreto qui nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo exchange Bitvavo",
              1: () =>
                "Nell'angolo in alto a destra, fai clic sul nome del tuo profilo",
              2: () => 'Fare clic su "Cronologia transazioni"',
              3: () =>
                "Fare clic sul pulsante di download nell'angolo in alto a destra",
              4: () => "Carica il CSV qui nel Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bityard: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Bityard",
              1: () => "Per la cronologia commerciale:",
              2: () =>
                'Vai alla sezione "Ordini" e seleziona "Spot" - "Cronologia scambi" dal menu (https://www.bityard.com/en-US/account/fm/spotHistory/2)',
              3: () =>
                'Fare clic sul pulsante "Esporta" e selezionare il periodo di tempo desiderato per i dati esportati',
              4: () => "Per la cronologia dei depositi e dei prelievi:",
              5: () =>
                'Vai alla sezione "Risorse" e seleziona "Le mie risorse" - "Cronologia fondi" - "Cronologia depositi" o "Cronologia prelievi" dal menu (https://www.bityard.com/en-US/account/fm/fundHistory/1)',
              6: () =>
                'Fare clic sul pulsante "Esporta" e selezionare il periodo di tempo desiderato per i dati esportati',
              7: () => "Carica entrambi i CSV qui nel Crypto Tax Calculator",
            },
          },
        },
      },
    },
    blast: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Blast (che inizia con 0x) nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    blockchaindotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Puoi importare la cronologia dei tuoi depositi e prelievi solo tramite l'importazione CSV. Inoltre, il CSV non fornisce informazioni sulle commissioni pagate per questi depositi/prelievi",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo portafoglio (https://login.blockchain.com/#/login) e seleziona una valuta a sinistra (ad esempio Bitcoin)",
              1: () =>
                'Se hai un saldo per la criptovaluta selezionata, vedrai un pulsante "Download": clicca su questo pulsante',
              2: () =>
                "Ti verrà chiesto di selezionare un intervallo di date per cui vuoi scaricare le tue transazioni. Assicurati di selezionare il periodo che copre l'intera cronologia delle tue transazioni per la criptovaluta selezionata",
              3: () =>
                'Fai clic su "Genera report". Questo pulsante cambierà in "Scarica report" quando il report sarà pronto per essere scaricato. Fai clic su questo pulsante per scaricare il tuo report',
              4: () =>
                "Carica il file che hai appena scaricato qui in Crypto Tax Calculator",
              5: () =>
                "Assicurati di ripetere questo processo per tutte le criptovalute che stai archiviando sul nostro Portafoglio",
            },
          },
        },
      },
    },
    blockearner: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a Block Earner e vai alla pagina Transazioni (https://app.blockearner.com.au/transactions)",
              1: () =>
                "Scarica i file necessari in base alla cronologia delle tue negoziazioni.",
              2: () =>
                "Il file delle transazioni si trova in alto a destra dello schermo all'interno di ciascun account: Prestiti, DeFi, Acquisto/Vendita Criptovalute e Contanti.",
              3: () =>
                "Carica tutti i file scaricati qui in Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    blockfi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Ignoriamo il tipo di transazione "Trade" nel file CSV "Transaction History". Invece, utilizziamo le informazioni più accurate nel file CSV "Trading" per ottenere i tuoi dati di trading.',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account BlockFi e clicca sull'icona del tuo profilo in alto a destra",
              1: () => 'Seleziona "Report" dal menu a discesa',
              2: () =>
                "In fondo alla pagina vedrai 'Trading' e 'Cronologia delle transazioni'",
              3: () =>
                'Per "Trading" seleziona l\'intervallo di date che desideri esportare (o scarica tutto) e clicca sul pulsante "Scarica .csv": questo scaricherà tutti i tuoi dati di trading dettagliati',
              4: () =>
                "Per 'Cronologia delle transazioni' seleziona l'intervallo di date che desideri esportare (o scarica tutto) e clicca sul pulsante 'Scarica .csv': questo scaricherà tutti i dati dettagliati delle tue transazioni come depositi, prelievi, pagamenti di interessi, ecc.",
              5: () =>
                "Carica entrambi i file CSV qui su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    boba: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Al momento stiamo riscontrando alcuni problemi nella raccolta dei token ERC-1155 e stiamo lavorando a una soluzione. Se nel frattempo riscontri questo problema, prova invece il nostro processo di caricamento CSV manuale. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Token ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Boba nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    brave_software: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri Brave Wallet nel browser",
              1: () =>
                "Dalla scheda Account, clicca per copiare l'indirizzo del tuo portafoglio pubblico che inizia con '0x...'",
              2: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    bsv: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
              1: () =>
                "Supportiamo anche gli indirizzi xpub, ypub e zpub, tuttavia è preferibile xpub",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Bitcoin SV nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    btc: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Supportiamo anche gli indirizzi xpub, ypub e zpub. Ti consigliamo di usare xPub. Leggi questa guida (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator) per maggiori dettagli.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () =>
                "Copia l'indirizzo del tuo portafoglio pubblico che inizia con 'xpub...'",
              2: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico in CTC e fai clic su "Importa portafoglio"',
            },
          },
        },
      },
    },
    btcAltNetwork: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Inserisci l'indirizzo del tuo portafoglio " +
                d.exchangeDisplayName +
                ' nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
            },
          },
        },
      },
    },
    btcalpha: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Nella sezione account del tuo account vai alla sezione API",
              1: () => "Crea una nuova chiave API",
              2: () => "Copia e incolla le chiavi in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    btccomau: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a BTC.com.au",
              1: () =>
                "Vai su Impostazioni > Rapporti di trading tramite il menu a sinistra dello schermo",
              2: () =>
                'Fare clic sul pulsante "Report singolo" a destra della riga appena sotto l\'intestazione "Segnalazione attività"',
              3: () =>
                'Selezionare "Transazione" nell\'elenco a discesa "Tipo di report"',
              4: () => "Compila le date di inizio e fine",
              5: () => 'Fare clic sul pulsante "Crea"',
              6: () =>
                'Fare clic sul pulsante "Crea report" nel popup di conferma',
              7: () =>
                "Dopo il messaggio che la creazione del report è riuscita, potrebbe essere ancora necessario aggiornare la pagina per visualizzare il nuovo report",
              8: () =>
                'Fare clic su "Scarica report" nell\'ultima riga sotto Report disponibili per il download',
              9: () => "Carica il CSV scaricato qui su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    btcmarkets: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Per maggiori informazioni consulta i nostri passaggi dettagliati: https://cryptotaxcalculator.io/integrations/btcmarkets-tax/",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Accedi a BTC Markets e vai alla pagina "API Key" (https://app.btcmarkets.net/account/apikey)',
              1: () =>
                'Fare clic su "Aggiungi nuova chiave API", assicurarsi che le autorizzazioni siano impostate su "Sola lettura", quindi fare clic su "Invia"',
              2: () =>
                'Copia il segreto e incollalo in "Chiave segreta" nel calcolatore delle imposte sulle criptovalute',
              3: () =>
                'Fare clic su "Invia", quindi fare clic sul pulsante Copia sulla chiave API e incollarla in Crypto Tax Calculator.',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a BTC Markets e vai su Acquista/Vendi > Cronologia delle negoziazioni",
              1: () =>
                'Fare clic su "Esporta in CSV" (dovrebbe essere scaricato come "AccountTradingReport_XXX.csv")',
              2: () =>
                "Carica qui il file CSV Calcolatore delle imposte sulle criptovalute",
            },
            title: () => "Per i dati di trading:",
          },
          1: {
            items: {
              0: () =>
                "Accedi a BTC Markets e vai su Account > Cronologia transazioni",
              1: () =>
                "Assicurati che la valuta sia 'TUTTI' e clicca su 'Esporta in CSV' (dovrebbe essere scaricato come 'TransactionReport_XXX.csv').",
              2: () => "Carica il file CSV qui su Crypto Tax Calculator",
            },
            title: () => "Per depositi e prelievi:",
          },
        },
      },
    },
    bybit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Potrebbero esserci piccole discrepanze di saldo tra i tuoi saldi su Bybit e i saldi riportati in Crypto Tax Calculator. Questo è un problema da parte di Bybit ed è dovuto a piccole differenze di arrotondamento tra i record PNL di Bybit e i loro record Wallet. Se ci sono discrepanze significative, contatta il nostro supporto tramite la nostra chat web.",
              1: () =>
                "L'API Bybit importa solo le transazioni di conversione degli ultimi 6 mesi. Se hai transazioni più vecchie, importale tramite un file CSV",
              2: () =>
                "A causa delle limitazioni da parte di Bybit, la chiave API scadrà dopo tre mesi dal momento della creazione",
              3: () =>
                "L'API del tuo account principale includerà i dati dei sottoaccount, quindi non sarà necessario crearne una seconda, poiché ciò potrebbe causare duplicati nel tuo account.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Accedi a Bybit, passa il mouse sul tuo nome nell\'angolo in alto a destra e seleziona "API" (https://www.bybit.com/app/user/api-management)',
              1: () =>
                'Fai clic sul pulsante "Crea nuova chiave". Se non hai ancora impostato 2FA, ti verrà chiesto di farlo.',
              2: () =>
                'Seleziona "chiavi API generate dal sistema" come tipo di chiave API',
              3: () =>
                'Seleziona "Transazione API" come utilizzo della chiave API e inserisci il nome che preferisci.',
              4: () =>
                'Modificare il pulsante superiore su "Sola lettura" e lasciare vuoto il campo dell\'indirizzo IP.',
              5: () =>
                'Fare clic sulle caselle di controllo per "Trading unificato" e "Attività"',
              6: () =>
                'Fai clic su "Invia", quindi copia e incolla la chiave API e il segreto qui in CTC',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Raccomandiamo VIVAMENTE di importare tramite l'opzione \"Sincronizzazione tramite API\" e di connettersi direttamente all'API Bybit, poiché questa contiene dati più precisi.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ti chiediamo di caricare i file CSV "Cronologia P&L chiusi derivati" e "Cronologia asset".',
              1: () =>
                "È possibile generare il file 'Derivative Closed P&L History' tramite questo link (https://www.bybit.com/user/assets/order/closed-pnl/inverse).",
              2: () =>
                'Vai su Ordini > Ordine derivati > Chiudi P&L > Fai clic sul pulsante "Esporta" nell\'angolo in alto a destra.',
              3: () =>
                "NOTA: i trader possono esportare fino a due anni di dati, mentre l'intervallo massimo di dati esportabile in un singolo batch è di tre mesi.",
              4: () =>
                "Puoi generare il file 'Cronologia risorse' tramite questo link (https://www.bybit.com/user/assets/exportStatment).",
              5: () =>
                "NOTA: quando esporti il tuo 'Estratto conto' nella cronologia delle tue attività, assicurati che siano selezionati tutti i tipi di conto (Spot, Finanziamento, Trading unificato, Derivati USDC).",
              6: () =>
                "È importante notare che non è necessario il file CSV della cronologia delle negoziazioni",
              7: () =>
                "Il rapporto P&L chiuso mostra i guadagni/le perdite realizzati con la negoziazione di futures ecc.",
              8: () =>
                "Il report Cronologia Asset ti consentirà di importare i depositi/prelievi",
            },
          },
        },
      },
    },
    calebandbrown: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al portale su app.calebandbrown.com",
              1: () => "Vai alla pagina Transazioni (menu a sinistra)",
              2: () =>
                "In alto a destra della tabella Transazioni, si trova il pulsante Riepilogo esportazione",
              3: () =>
                'Seleziona Riepilogo transazione, seleziona "Tutto il tempo" come periodo di tempo e fai clic su Esporta',
            },
          },
        },
      },
    },
    canto: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Al momento stiamo riscontrando alcuni problemi nella raccolta di token ERC-1155 e nelle transazioni di bridging e stiamo lavorando a una soluzione. Se nel frattempo riscontri questo problema, prova invece il nostro processo di caricamento CSV manuale. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155 Token e transazioni bridge",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Canto nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    cardano: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Inserisci solo UN indirizzo da ogni portafoglio. Eseguiamo una ricerca e estraiamo automaticamente i dati per tutti gli altri indirizzi nel portafoglio.",
              1: () =>
                "L'importazione di più indirizzi dallo stesso portafoglio comporterà transazioni duplicate.",
              2: () =>
                "Al momento non supportiamo l'ottenimento della cronologia completa delle transazioni di wallet con più di 1000 indirizzi individuali o quando il numero di transazioni è superiore a 2000 in alcuni casi. Stiamo lavorando per fornire ulteriore supporto per tali wallet.",
            },
            title: () => "Più indirizzi wallet",
          },
          1: {
            items: {
              0: () =>
                "Se importi un indirizzo che inizia con 'addr1', lo modifichiamo automaticamente con l'indirizzo del proprietario dell'account che inizia con 'stake1'.",
              1: () =>
                "Ciò ci consente di importare tutti gli altri indirizzi che potresti creare nello stesso portafoglio e di acquisire le tue ricompense di staking.",
            },
            title: () => "Perché il mio indirizzo è stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () =>
                "Copia l'indirizzo del tuo portafoglio pubblico che inizia con 'addr1...' o 'stake1...'",
              2: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico in CTC e fai clic su "Importa portafoglio"',
            },
          },
        },
      },
    },
    cashapp: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a Cash App e vai alla pagina Attività (https://cash.app/account/activity)",
              1: () => 'Fare clic su "Estratti conto"',
              2: () => 'Fare clic su "Esporta CSV"',
              3: () => "Ora puoi caricare questo CSV su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    celestia: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Celestia nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    celo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Al momento stiamo riscontrando alcuni problemi nella raccolta dei token ERC-1155 e stiamo lavorando a una soluzione. Se nel frattempo riscontri questo problema, prova invece il nostro processo di caricamento CSV manuale. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Token ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Celo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    celsius: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Celsius",
              1: () =>
                "Fare clic sul menu a discesa in alto a destra della piattaforma",
              2: () => 'Seleziona "API" e poi clicca su "Crea nuova chiave"',
              3: () =>
                'Copia la "chiave API" generata qui in Crypto Tax Calculator, inserisci un nickname facoltativo e poi clicca su "Aggiungi API" per sincronizzare la tua API',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Per passaggi più dettagliati con screenshot, visualizza la pagina di supporto all'indirizzo https://support.celsius.network/hc/en-us/articles/360004491118-How-do-I-export-my-transaction-history-as-CSV-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => 'Apri l\'app Celsius e tocca il "logo Celsius".',
              1: () => 'Tocca il pulsante "Profilo".',
              2: () =>
                'Tocca "Scarica cronologia transazioni" nella sezione impostazioni.',
              3: () =>
                "Una volta toccato il pulsante Scarica cronologia transazioni, controlla l'email che hai usato per registrare il tuo portafoglio Celsius. Clicca sul pulsante Scarica nell'email.",
              4: () =>
                "Carica il file CSV in Crypto Tax Calculator utilizzando il pulsante di importazione.",
            },
          },
        },
      },
    },
    cex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Cex.io",
              1: () =>
                "Vai alla pagina di accesso API https://cex.io/trade/profile#/api",
              2: () => 'Fare clic sul pulsante "Genera chiave API"',
              3: () =>
                'Vai su Autorizzazioni per abilitare solo le autorizzazioni "saldo conto" e "ordini aperti"',
              4: () => 'Fare clic sul pulsante "Genera chiave"',
              5: () => "Copia l'ID utente, la chiave API e il segreto API",
              6: () =>
                'Fai clic sul pulsante "Attiva" accanto alla tua nuova chiave API',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Cex.io",
              1: () =>
                "Vai a Finanza > Transazioni https://cex.io/finance/transactions",
              2: () =>
                'Seleziona "Tutti i tipi di transazione" con date che vanno da tutte le negoziazioni',
              3: () =>
                'Fare clic sul pulsante "Scarica in CSV" prima di caricare su Crypto Tax Calculator',
            },
          },
        },
      },
    },
    changelly: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vai a: Il mio account - > Cronologia https://changelly.com/history",
              1: () => "Premere 'Esporta CSV'",
              2: () => "Carica qui il file CSV",
            },
          },
        },
      },
    },
    chihuahua: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Chihuahua nel Crypto Tax Calculator, fornisci un soprannome facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    chiliz: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "I file devono seguire il formato: Timestamp (UTC), Borsa, Asset scambiato, Importo scambiato, Asset ricevuto, Importo ricevuto",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Puoi importare transazioni da Chiliz Exchange e app associate, ad esempio Socios",
            },
          },
        },
      },
    },
    clvchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Al momento stiamo riscontrando alcuni problemi nella raccolta dei token ERC-1155 e stiamo lavorando a una soluzione. Se nel frattempo riscontri questo problema, prova invece il nostro processo di caricamento CSV manuale. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Token ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio CLV P-Chain nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    coin98: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento sono supportati solo gli indirizzi del portafoglio Solana memorizzati nel tuo portafoglio Coin98",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio blockchain Solana che hai utilizzato per fare trading tramite Coin98",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    coinbase: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Assicurati che le impostazioni di valuta di Coinbase corrispondano alla tua valuta locale su https://accounts.coinbase.com/profile > Preferenze > Valuta.",
              1: () =>
                "Questa è una connessione API di sola lettura. Per motivi di sicurezza, Crypto Tax Calculator non supporta la connessione di chiavi che consentono il trading o il prelievo. Leggi di più su https://cryptotaxcalculator.io/privacy-policy",
              2: () =>
                "Potrebbero mancare alcune transazioni a causa dell'utilizzo di valute non supportate da Coinbase. (https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/lost-assets)",
              3: () =>
                'Se l\'importazione avviene correttamente tramite chiavi API, non utilizzare il metodo "Connect Coinbase" poiché ciò causerà duplicati.',
              4: () =>
                "I prelievi e i depositi tramite staking vengono ignorati, ma ciò non influisce sui report.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi alla piattaforma per sviluppatori Coinbase (https://portal.cloud.coinbase.com/).",
              1: () => "Vai alle chiavi API.",
              2: () =>
                'In "Crea chiave API", imposta un nickname, seleziona il tuo Portfolio e scegli le autorizzazioni "Visualizza (sola lettura)".',
              3: () => 'Fare clic su "Crea e scarica".',
              4: () => "Autenticazione completa a due fattori.",
              5: () =>
                "Copia il nome della chiave API e il segreto e incollali in Crypto Tax Calculator.",
              6: () =>
                "Il tuo segreto API viene mostrato solo una volta. Scarica il file JSON come backup. Se lo perdi, crea una nuova chiave API in Coinbase e aggiornala in Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/7GbjlA9B3bI",
            title: () => "Come importare la tua API Coinbase",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Se hai utilizzato operazioni di riempimento, le opzioni "Sincronizzazione tramite API" o "Connetti Coinbase" potrebbero fornire risultati più accurati.',
              1: () =>
                "Assicurati che le impostazioni di valuta di Coinbase corrispondano alla tua valuta locale su https://accounts.coinbase.com/profile > Preferenze > Valuta.",
              2: () =>
                "I prelievi e i depositi tramite staking vengono ignorati, ma ciò non influisce sui report.",
            },
          },
          1: {
            items: {
              0: () =>
                "Le righe di Advanced Trade non contengono informazioni complete sui prezzi.",
              1: () =>
                "Ciò potrebbe comportare piccole differenze tra l'importazione CSV e i dettagli Advanced Trade visualizzati in Coinbase.",
              2: () =>
                "Per ottenere risultati ottimali, prova a importare tramite la nostra integrazione API.",
            },
            title: () => "API Coinbase migliore per le negoziazioni avanzate",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai su Conti > Estratto conto (https://accounts.coinbase.com/statements)",
              1: () =>
                'In "Genera estratto conto personalizzato", mantieni i campi impostati su "Cronologia transazioni", "Tutte le attività", "Tutte le transazioni" e "Tutto il tempo".',
              2: () => 'Seleziona "CSV" e clicca su "Genera".',
              3: () => "Carica il report.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/nm2-aOO5iI4",
            title: () => "Come importare i file CSV di Coinbase",
          },
        },
      },
      oauth: {
        note: {
          0: {
            items: {
              0: () =>
                "Assicurati che le impostazioni di valuta di Coinbase corrispondano alla tua valuta locale su https://accounts.coinbase.com/profile > Preferenze > Valuta.",
              1: () =>
                "Se ti sei connesso utilizzando questo metodo, non è necessario aggiornare le chiavi in quanto le chiavi legacy sono state ritirate nel febbraio 2025.",
              2: () =>
                'Se la connessione non funziona, fare clic su "Riautorizza".',
              3: () =>
                "I prelievi e i depositi tramite staking vengono ignorati, ma ciò non influisce sui report.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Fai clic su "Connetti Coinbase" per aprire una nuova scheda.',
              1: () => "Accedi al tuo account Coinbase.",
              2: () => "Autorizza l'accesso in sola lettura.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/qHL8O_alo8Q",
            title: () => "Come importare i dati di Coinbase tramite Oauth",
          },
        },
      },
    },
    "coinbase-wallet": {
      "bulk-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Apri il portafoglio Coinbase sul tuo cellulare o nel tuo browser",
              1: () =>
                "Fai clic sul nome del tuo portafoglio in alto a sinistra dello schermo",
              2: () => 'Fai clic su "Aggiungi e gestisci portafogli"',
              3: () => "Seleziona un portafoglio",
              4: () => "Fare clic su Esporta indirizzi pubblici",
              5: () => "Fai clic su Copia",
              6: () =>
                "Incolla l'elenco dei portafogli nella casella di testo esattamente come è stato copiato",
              7: () =>
                "Tutti i tuoi portafogli nel portafoglio Coinbase saranno connessi",
            },
          },
        },
      },
    },
    coinbaseexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Coinbase Exchange.",
              1: () => "Crea una nuova chiave API.",
              2: () =>
                "Assicurarsi di impostare le autorizzazioni appropriate (sola visualizzazione).",
              3: () =>
                "Una volta creati, incolla la chiave API e il segreto in Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinbaseprime: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Abbiamo risolto un problema che riguardava le transazioni TWAP importate prima di marzo 2025. Per ripristinare le transazioni mancanti, elimina e aggiungi di nuovo le tue chiavi API. L'esecuzione di una hard-sync importerà anche le transazioni mancanti, ma potrebbe causare voci duplicate, quindi assicurati di rivedere e rimuovere eventuali duplicati. Per ulteriori informazioni, contatta l'assistenza.",
            },
            title: () => "Aggiornamento delle transazioni TWAP",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a Coinbase Prime",
              1: () =>
                "Nell'angolo inferiore sinistro della pagina, fai clic sull'icona delle impostazioni dell'ingranaggio",
              2: () => "Fai clic sulle API",
              3: () =>
                'Accanto a "Chiavi attivate", fai clic su "Crea chiave API"',
              4: () =>
                'Nella finestra pop-up, inserisci il "Nome API", la "Data di scadenza" e il "Tipo di accesso" di sola visualizzazione',
              5: () => 'Fare clic su "Continua"',
              6: () => "Verifica la tua identità con YubiKey",
              7: () => "Attendere le approvazioni consensuali",
              8: () => 'In "Chiavi in sospeso", trova la tua chiave API',
              9: () => 'Fare clic su "Attiva chiave"',
              10: () =>
                'Fai clic su "Attiva chiave API" e verifica nuovamente con YubiKey',
              11: () =>
                "Copia e salva la chiave API, il segreto e la passphrase generati. Incolla la chiave di accesso in 'API key', la passphrase in 'Passphrase' e la chiave di firma in 'Secret'.",
              12: () =>
                "Istruzioni più dettagliate sono disponibili all'indirizzo: https://help.coinbase.com/en/prime/coinbase-prime-api/creating-a-coinbase-prime-api",
            },
          },
        },
      },
    },
    coinbasepro: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Se hai più di 500 transazioni OTC, ti consigliamo di caricare le transazioni OTC tramite un CSV. Tieni presente che potrebbero verificarsi transazioni OTC duplicate quando si combinano API e CSV in questo modo.",
              1: () =>
                'Tutte le negoziazioni OTC importate tramite API contengono la dicitura "OTC" nella descrizione della transazione, che, combinata con l\'eliminazione in blocco, può essere utilizzata per rimuovere facilmente eventuali duplicati.',
            },
            title: () => "Negoziazioni OTC",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'A partire dal 20 novembre 2023, Coinbase Pro e la sua API sono stati deprecati da Coinbase. Se non hai importato l\'API prima di questa data, passa a "Carica file" e segui le istruzioni per caricare la cronologia delle transazioni di Coinbase Pro tramite CSV.',
              1: () =>
                "Se hai già importato l'API prima di questa data e la sincronizzazione non riesce a causa di una chiave API scaduta, importa la cronologia delle transazioni di Coinbase Pro tramite CSV a partire dall'ultima data di sincronizzazione dell'API.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nota bene: gestiamo solo Depositi e Prelievi dal CSV 'Estratto Conto'. Tutte le altre transazioni vengono prese dal CSV 'Compila Estratto Conto'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Coinbase.",
              1: () =>
                "Fare clic sull'icona del profilo nell'angolo in alto a destra.",
              2: () =>
                'Selezionare "Report" dal menu a discesa (https://www.coinbase.com/reports).',
              3: () =>
                'Seleziona "Visualizza estratti conto" nella sezione "Estratti conto Coinbase Pro" (https://accounts.coinbase.com/statements/pro).',
              4: () =>
                'Lasciare le impostazioni "Tutti" nei menu a discesa Portafoglio, Tipo di conto e Data.',
              5: () =>
                'Selezionare "Account" per Tipo di report, selezionare CSV, quindi fare clic su "Genera" per scaricare il file "account.csv"',
              6: () =>
                'Selezionare "Riempimenti" per Tipo di report, selezionare CSV e fare clic su Genera per scaricare il file "fills.csv"',
              7: () =>
                "Carica entrambi questi file CSV in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinberry: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Assicurati che il tuo report comprenda tutti gli anni in cui hai fatto trading di criptovalute",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Coinberry tramite un browser web",
              1: () =>
                'Vai alla tua Dashboard e clicca su "Attività Coinberry" per scaricare i tuoi dati di trading Download CSV Coinberry',
              2: () =>
                "Non aprire o salvare nuovamente il file prima di importarlo, poiché ciò potrebbe modificare il formato del file CSV",
              3: () =>
                "Carica il file che hai appena scaricato qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coincheck: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Coincheck",
              1: () =>
                "Nel menu sul lato sinistro, seleziona l'icona dell'ingranaggio, quindi fai clic su \"Chiave API\"",
              2: () =>
                'Completa il controllo 2FA, quindi fai clic su "Aggiungi un\'altra chiave API"',
              3: () =>
                'Nella finestra che si apre, sotto la voce "Ordine", seleziona l\'opzione "Cronologia delle negoziazioni"',
              4: () =>
                'Sotto la voce "Account", seleziona le opzioni "Invia cronologia" e "Cronologia depositi"',
              5: () =>
                'Sotto la voce "Preleva JPY", seleziona l\'opzione "Cronologia prelievi JPY"',
              6: () =>
                "Lascia vuoto il campo IP, inserisci la tua password e clicca su 'OK' per generare la chiave API",
              7: () =>
                'Inserisci sia la "chiave API" che il "segreto API" qui nel calcolatore delle imposte sulle criptovalute',
            },
          },
        },
      },
    },
    coincorner: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Il file deve essere un file CSV. Esporta in CSV se hai un file .xlsx",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Puoi importare le transazioni da un file CSV esportato da Coincorner",
            },
          },
        },
      },
    },
    coindcx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Apri l'app mobile CoinDCX e clicca sull'icona del profilo nell'angolo in alto a sinistra.",
              1: () => 'Selezionare "Scarica report".',
              2: () =>
                'Fare clic su "Rapporto sulle negoziazioni" nella sezione "Scarica report".',
              3: () =>
                'Inserisci l\'anno finanziario e il periodo di tempo, quindi fai clic su "Genera". Il tuo report verrà scaricato automaticamente.',
              4: () =>
                "Se le tue negoziazioni coprono più periodi, ripeti questo passaggio secondo necessità.",
              5: () => "Carica i file su Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account CoinEx",
              1: () => 'Espandi il menu "Risorse" e clicca su "Cronologia"',
              2: () =>
                'Imposta l\'intervallo di tempo dei dati della tua transazione e clicca su "Esporta" per scaricare un file ".xlsx"',
              3: () => "Carica il tuo file '.xlsx' in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinexchange: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Si prega di notare che i prelievi e i depositi non sono inclusi nei dati delle transazioni CSV e dovranno essere aggiunti manualmente",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Questo scambio è stato disattivato, tuttavia puoi comunque caricare i dati delle transazioni salvate in formato CSV",
            },
          },
        },
      },
    },
    coinfalcon: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Vai alla sezione Account del tuo account",
              1: () => "Fare clic su Accesso API",
              2: () =>
                "Crea una nuova chiave API e copia le chiavi in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinfield: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "CoinField ti consente di scaricare report per un solo mercato alla volta, quindi assicurati di generare report che comprendano tutti i mercati in cui hai fatto trading (ad esempio BTC/USD, ETH/CAD) e caricali qui",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account CoinField tramite un browser web",
              1: () =>
                "Vai alla tua Dashboard e clicca su Commercio > Cronologia Commercio dalla barra di navigazione a sinistra",
              2: () =>
                "Scarica il file CSV della cronologia delle tue negoziazioni",
              3: () =>
                "Assicurati di ripetere questo processo per tutti i mercati/criptovalute con cui hai fatto trading",
              4: () =>
                "Carica i file che hai appena scaricato qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinjar: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account CoinJar",
              1: () =>
                "Fai clic sul pulsante sottostante per essere reindirizzato a CoinJar in una nuova scheda",
              2: () =>
                "Abilita le autorizzazioni di segnalazione nel tuo account CoinJar",
              3: () =>
                "Sincronizzeremo quindi la cronologia delle tue transazioni CoinJar",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "L'opzione di importazione CSV non supporta Bundle, Unbundle e Liquidate Transactions, se hai queste transazioni, importa tramite l'opzione CSV personalizzata",
              1: () =>
                "Se i tuoi prelievi sono stati effettuati per uso personale o per transazioni di vendita, puoi contrassegnarli come tali nella pagina successiva",
              2: () =>
                'Se hai effettuato transazioni tramite lo scambio CoinJar, devi anche aggiungere il file CSV "fill"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a CoinJar",
              1: () =>
                'Nella barra di navigazione in alto clicca su "Impostazioni"',
              2: () =>
                'Nella barra laterale sinistra clicca su "Report e rendiconti"',
              3: () => 'Fare clic su "Estratti conto cronologia transazioni"',
              4: () => "Fare clic su Scarica 'Estratto conto' CSV",
              5: () =>
                "Fai clic su Intervallo di date personalizzato e seleziona l'intera cronologia di trading come intervallo di date",
              6: () =>
                "Esporta il file CSV e carica entrambi i file CSV in Crypto Tax Calculator",
              7: () =>
                "Dovresti ricevere un'email con il tuo CSV. Scarica questo CSV e caricalo qui.",
            },
          },
        },
      },
    },
    coinjarexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account CoinJar Exchange",
              1: () =>
                "Nelle impostazioni del tuo account vai alla sezione API",
              2: () => "Crea una nuova chiave API",
              3: () =>
                'Assicurarsi che la chiave appartenga all\'ambito "sola lettura"',
              4: () =>
                'Copia il "segreto" della tua nuova chiave API qui in Crypto Tax Calculator e fornisci un soprannome',
            },
          },
        },
      },
    },
    coinlist: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "La nostra integrazione CSV di CoinList è attualmente in versione Beta. Se riscontri problemi o hai feedback, contatta il nostro supporto",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account CoinList",
              1: () =>
                'Fai clic sul menu a discesa in alto a destra della piattaforma CoinList e vai su "Portafogli"',
              2: () =>
                'Seleziona i tuoi portafogli e imposta l\'intervallo di tempo dei dati della transazione e fai clic su "Scarica CSV"',
              3: () =>
                "Carica il file CSV nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
    },
    coinlistpro: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account CoinList Pro",
              1: () =>
                "Fare clic sul menu a discesa in alto a destra della piattaforma CoinList Pro",
              2: () => 'Seleziona "API" e poi clicca su "Crea nuova chiave"',
              3: () =>
                'Genera una chiave API univoca con autorizzazioni di "sola lettura"',
              4: () =>
                'Copia la "chiave API" e il "segreto API" generati qui in Crypto Tax Calculator, inserisci un nickname facoltativo e poi clicca su "Aggiungi API" per sincronizzare la tua API',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account CoinList Pro",
              1: () =>
                "Fare clic sul menu a discesa in alto a destra della piattaforma CoinList Pro",
              2: () =>
                "Seleziona Estratti conto e imposta 'Tipo di estratto conto', 'Data di inizio' e 'Data di fine' e clicca su 'Richiedi estratto conto'",
              3: () =>
                "Carica ENTRAMBI i file CSV e CSV dell'account nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
    },
    coinmetro: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account CoinMetro tramite un browser web",
              1: () =>
                "Vai alla tua Dashboard e scorri verso il basso fino alla Cronologia delle transazioni",
              2: () =>
                "Seleziona l'intervallo di date corrispondente all'inizio del tuo periodo di trading o al periodo di interesse utilizzando il menu a discesa \"Intervallo di date\"",
              3: () =>
                'Seleziona "Deposito e prelievo" nel menu a discesa "Tipi"',
              4: () =>
                'Fai clic su "Scarica CSV" per scaricare la cronologia delle transazioni',
              5: () =>
                'Assicurati di ripetere questa operazione con tutti i tuoi asset negoziati, che possono essere selezionati nel menu a discesa "Asset"',
              6: () =>
                "Carica i file che hai appena scaricato qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinsmart: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account Bitbuy utilizzando le tue vecchie credenziali Coinsmart e contatta il team di assistenza clienti di Bitbuy per richiedere la cronologia delle tue transazioni.",
              1: () => "Carica qui i tuoi file CSV nel Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinspot: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Sincronizziamo tutti i tuoi dati CoinSpot tramite API. Ciò include scambi, airdrop, trasferimenti di criptovalute, depositi/prelievi AUD, pagamenti di reddito di affiliazione e pagamenti di reddito di referral.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Accedi a CoinSpot e vai alla pagina "API" (https://www.coinspot.com.au/my/api).',
              1: () => 'Fare clic su "Genera nuova chiave API".',
              2: () =>
                'Assegna un nome alla chiave e imposta il tipo di chiave API su "Sola lettura".',
              3: () =>
                'Inserisci il tuo codice a due fattori e clicca su "Crea nuova chiave".',
              4: () =>
                "Conferma la creazione della chiave API cliccando sul link inviato alla tua email.",
              5: () => 'Copia e incolla la "chiave API" in CTC',
              6: () =>
                'Rivela, quindi copia e incolla la "Chiave segreta" in CTC (non potrai più accedervi).',
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/Pls-kQAg94U",
            title: () => "Come importare la tua API Coinspot",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Il CSV non include informazioni su airdrop, depositi/prelievi AUD o pagamenti di affiliazione/referral, dovrai aggiungerli manualmente o utilizzare la sincronizzazione API di CoinSpot",
            },
            title: () => "Transazioni mancanti",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Visita https://www.coinspot.com.au/my/orders/history",
              1: () =>
                'Fare clic su "Invia/Ricevi CSV" per scaricare la cronologia di invio e ricezione come "sendreceives.csv".',
              2: () =>
                'Fai clic su "Acquista/Vendi CSV" per scaricare la cronologia degli ordini in formato "orderhistory.csv".',
              3: () => "Carica entrambi i file su Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/IXAkAHO2N9k",
            title: () => "Come importare i tuoi CSV Coinspot",
          },
        },
      },
    },
    coinsquare: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account Coinsquare qui (https://app-new.coinsquare.com/sign-in).",
              1: () =>
                'Fai clic sul menu a discesa accanto al tuo nome (in alto a destra) e seleziona "Documenti".',
              2: () =>
                'In "Estratti conto", seleziona l\'anno fiscale per il quale desideri visualizzare la cronologia delle transazioni e clicca su "Scarica".',
              3: () =>
                "Carica tutti i file CSV scaricati qui in Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinstash: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Coinstash",
              1: () =>
                "Vai alla pagina delle impostazioni (https://coinstash.com.au/settings)",
              2: () =>
                'Scorri verso il basso fino alla sezione "Report". Fai clic sul pulsante "Download" accanto a "Cronologia delle transazioni"',
              3: () => "Ora carica il CSV in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinswitch: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account CoinSwitch Kuber.",
              1: () =>
                "Tocca l'icona blu del profilo nell'angolo in alto a sinistra della home page.",
              2: () => 'Vai alla sezione "Report" e scarica l\'estratto conto.',
              3: () => "Selezionare la sezione 'Profitti e perdite fiscali'.",
              4: () =>
                'Seleziona l\'anno finanziario, seleziona "Tutti i trimestri" e tocca "Invia estratto conto".',
              5: () => "Carica il file su Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    cointracking: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Una volta importate, le transazioni verranno suddivise nelle rispettive importazioni di cambio, ove pertinente.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Puoi importare tutta la cronologia dei tuoi scambi da Cointracking",
              1: () =>
                'Accedi al tuo account CoinTracking e nella barra di navigazione clicca su "Inserisci monete" (https://cointracking.info/enter_coins.php)',
              2: () =>
                "Nella tabella vedrai un pulsante 'Esporta'. Fai clic su questo pulsante e seleziona 'CSV' dalla selezione a discesa. Un file CSV verrà scaricato automaticamente",
              3: () =>
                "Carica qui il file CSV scaricato su Crypto Tax Calculator.",
            },
            title: () => "Tabella di scambio (consigliata):",
          },
          1: {
            items: {
              0: () =>
                "In alternativa, puoi esportare il saldo di chiusura dell'ultimo anno finanziario.",
              1: () =>
                "Assicurati che l'esportazione avvenga nella tua valuta fiat locale.",
              2: () => "Il formato della data dovrebbe essere 'GG.MM.AAAA'.",
            },
            title: () => "Saldo di chiusura:",
          },
        },
      },
    },
    cointree: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Assicurati di conservare il tuo API Secret in un luogo sicuro.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a Cointree",
              1: () =>
                "Fai clic sull'icona del tuo profilo nell'angolo in alto a destra e vai su \"Impostazioni\"",
              2: () => 'Vai a "Chiavi API" (ultima opzione nel menu in alto)',
              3: () =>
                'Inserisci un nome visualizzato per la tua chiave API nel campo "Nome visualizzato chiave API"',
              4: () =>
                'Scegli un livello di accesso per la tua chiave API e clicca su "Genera chiave API + segreto"',
              5: () =>
                "Copia e incolla la tua chiave API e il segreto API in Crypto Tax Calculator",
              6: () =>
                'Inserisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Cointree",
              1: () => "Vai a Portafoglio > Transazioni",
              2: () =>
                'Fare clic sul pulsante "Esporta" nella tabella "Tutte le transazioni"',
              3: () =>
                'Fai clic su "Esporta ordini (csv)" dal menu a discesa per scaricare la cronologia delle tue negoziazioni',
              4: () =>
                'Fai clic su "Esporta transazioni (csv)" dal menu a discesa per scaricare la cronologia dei tuoi depositi/prelievi',
              5: () =>
                "Carica entrambi i file CSV qui su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "cosmos/ibc": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () =>
                "Vai all'account che desideri importare e copia l'indirizzo del tuo portafoglio pubblico",
              2: () =>
                "In CTC, seleziona la rete blockchain pertinente dal menu a discesa",
              3: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    cosmoshub: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "I dati precedenti al 20/02/2021 non sono disponibili a causa di una limitazione della fonte dati.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Cosmos Hub nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    coss: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Coss",
              1: () => "Vai alla pagina di gestione API",
              2: () => "Tipo Nome della chiave",
              3: () => 'Fare clic sul pulsante "Crea nuova chiave"',
              4: () => "Inserisci il tuo codice 2FA",
              5: () => 'Fare clic sul pulsante "Avanti"',
              6: () =>
                'Controlla la tua e-mail e clicca sul link "Clicca qui per creare la chiave API"',
              7: () => "Copia sia la chiave pubblica che quella segreta",
              8: () =>
                'Fare clic sul pulsante "Modifica" per disattivare l\'autorizzazione di trading per questa chiave',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Coss",
              1: () => "Vai alla cronologia degli scambi",
              2: () =>
                'Fare clic sul pulsante "Esporta cronologia completa delle transazioni"',
              3: () =>
                "Assicurati che le date selezionate siano per un intervallo precedente all'apertura del tuo account e ora",
              4: () => 'Fare clic su "Esporta"',
              5: () =>
                "Carica CSV sul calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
    },
    crex24: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Quando si generano le chiavi API, accertarsi di selezionare solo le opzioni R1 e R3.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a CREX24",
              1: () =>
                'Fai clic su "Il mio account" nell\'angolo in alto a destra',
              2: () => 'Fare clic sulla scheda "Chiavi API"',
              3: () =>
                'Fai clic su "Nuova chiave API" e inserisci il codice ricevuto via email',
              4: () => "Ora dovresti vedere una nuova chiave API nella tabella",
              5: () =>
                'Impostare la scheda "Stato" su "ON" e selezionare le caselle di controllo R1 e R3',
              6: () =>
                "Copia la chiave API e il segreto e incollali nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
    },
    cronos: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ora supportiamo i token CRC-20. Se hai precedentemente importato il tuo account Cronos, potresti dover effettuare una 'hard sync' della tua API per aggiornare i dati storici.",
            },
            title: () => "Gettoni CRC-20",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio che hai utilizzato per fare trading tramite Cronos (iniziando con 0x)",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    cryptodotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Il formato della data deve essere uno dei seguenti: 'GG-MM-AA H:mm', 'M/G/AA H:mm' o 'AAAA-MM-GG HH:mm:ss'. Se non hai il formato della data corretto nel tuo CSV, contatta il nostro team di supporto tramite chat web.",
              1: () =>
                "Se importi il tuo CSV 'fiat_transactions_record', DEVI rimuovere tutte le righe Transaction Kind 'viban_purchase' e 'recurring_buy_order'. Poiché questi dati sono già registrati sul CSV 'crypto_transactions_record', importarli entrambi causerà errori di importazione.",
            },
            title: () => "Formattazione CSV",
          },
          1: {
            items: {
              0: () =>
                "A volte il CSV esportato è danneggiato: se si verificano errori di caricamento, provare a salvare nuovamente il file localmente come CSV e quindi reimportarlo.",
            },
            title: () => "Errori di caricamento",
          },
        },
        step: {
          0: {
            items: {
              0: () => 'Apri l\'app Crypto.com e vai alla scheda "Account".',
              1: () =>
                'Fare clic su "Cronologia transazioni", quindi su "Esporta".',
              2: () => 'Seleziona "Portafoglio cripto" in "Transazione".',
              3: () =>
                "Seleziona una data di inizio per tutte le transazioni e imposta la data di fine su oggi.",
              4: () => 'Fare clic su "Esporta in CSV" per scaricare il file.',
              5: () => "Carica il file CSV su Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    cryptodotcomexchange: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "La sincronizzazione dell'API può richiedere fino a 90 minuti poiché è necessario ripetere l'operazione ogni giorno ed è limitata da Crypto.com",
              1: () =>
                "A volte l'API non riesce a sincronizzarsi a causa di problemi con Crypto.com. Se ti sta succedendo questo, prova invece l'importazione CSV",
              2: () =>
                "L'API di Crypto.com supporterà solo gli ultimi 6 mesi di cronologia delle transazioni. Se hai bisogno di dati più vecchi, usa il metodo CSV.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Fare clic su Impostazioni > Chiavi API (https://crypto.com/exchange/personal/api-management).",
              1: () =>
                "Genera chiavi API di sola lettura che non abilitano il trading",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento non supportiamo il trading di Margin o Derivative a causa delle restrizioni nazionali. Se hai negoziato questi prodotti, contatta il nostro supporto tramite chat in-app. Inoltre, il CSV di prelievo non include le commissioni. Dovrai aggiornarle manualmente.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Crypto.com fornisce più formati CSV per l'esportazione dei dati. Ti serviranno tutti (Transazioni, Depositi e Prelievi e Cronologia delle transazioni) per importare la cronologia completa delle tue transazioni.",
              1: () =>
                "Si prega di notare che Crypto.com consente un intervallo di date massimo di 180 giorni per esportazione. Per coprire l'intera cronologia delle transazioni, potrebbe essere necessario esportare più file per ogni formato.",
              2: () => "Accedi al tuo account Crypto.com.",
              3: () => "Cambia la lingua in inglese (se non è già impostata).",
              4: () =>
                "Dalla dashboard, seleziona Portafoglio dal menu a sinistra.",
              5: () =>
                "Vai alla scheda Transazioni. Scegli un intervallo di date e clicca su Esporta per scaricare il file CSV delle transazioni.",
              6: () => "Torna al menu Portafoglio.",
              7: () =>
                "Vai alla scheda Depositi e prelievi. Scegli un intervallo di date e clicca su Esporta per scaricare il file CSV Depositi e prelievi.",
              8: () => "Dalla dashboard, seleziona Ordini dal menu a sinistra.",
              9: () =>
                "Vai alla scheda Cronologia delle transazioni. Scegli un intervallo di date e clicca su Esporta per scaricare il file CSV Cronologia delle transazioni.",
              10: () =>
                "Importa tutti e tre i file scaricati (deposit&Withdrawal.csv, OEX_TRANSACTION.csv in CTC, OEX_TRADE.csv) in CTC.",
            },
          },
        },
      },
    },
    cryptospend: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Wayex",
              1: () =>
                'Vai alla pagina "Account", scorri verso il basso fino alle transazioni, quindi premi "Ottieni transazioni"',
              2: () =>
                "Verrà generato un file CSV che verrà inviato alla tua email",
              3: () => "Scarica il file CSV dalla tua email",
              4: () =>
                "Carica questo CSV qui nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
    },
    cryptotaxcalculatorlegacy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Solo per importare operazioni manuali da legacy.cryptotaxcalculator.io",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Per prima cosa, ti preghiamo di inviarci un'e-mail se stai convertendo da legacy per ottenere le negoziazioni manuali come file CSV",
              1: () => "Carica qui il file CSV su Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    customCSV: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "CSV avanzato per transazioni più dettagliate: https://cryptotaxcalculator.io/guides/advanced-manual-csv-import",
              1: () =>
                "Assicurati che i tuoi dati siano preparati correttamente e in un formato di file .csv!",
            },
            title: () =>
              "Se il tuo scambio non è disponibile tramite la nostra importazione automatica, puoi importarlo utilizzando uno dei nostri modelli CSV personalizzati:",
          },
        },
      },
    },
    daedalus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Inserisci solo UN indirizzo da ogni portafoglio. Eseguiamo una ricerca e estraiamo automaticamente i dati per tutti gli altri indirizzi nel portafoglio.",
              1: () =>
                "L'importazione di più indirizzi dallo stesso portafoglio comporterà transazioni duplicate.",
              2: () =>
                "Al momento non supportiamo l'ottenimento della cronologia completa delle transazioni dei wallet in cui ogni singolo indirizzo ha più di 10 transazioni. Stiamo lavorando per fornire ulteriore supporto per tali wallet.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci qualsiasi indirizzo Shelly (addr1..) o indirizzo Stake (stake1...) dal tuo portafoglio Daedalus nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Ora puoi importare in alternativa le transazioni del tuo portafoglio Daedalus direttamente tramite la blockchain, connettendoti con l\'opzione "Sincronizzazione tramite API". Questo estrarrà tutte le transazioni e le ricompense di staking per il tuo portafoglio Daedalus',
              1: () =>
                "In alcune occasioni, il CSV della transazione sembra contenere dati errati. Questo è un problema con Daedalus: contatta l'assistenza se noti delle discrepanze",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo portafoglio Daedalus ed esporta il tuo premio CSV",
              1: () => "Puoi anche importare le transazioni CSV",
            },
          },
        },
      },
    },
    dash: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
              1: () =>
                "Supportiamo anche gli indirizzi xpub, ypub e zpub, tuttavia è preferibile xpub",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Dash nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    ddex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () => "Seleziona lo scambio dal menu a discesa",
              3: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              4: () => "Mantieni le altre impostazioni predefinite",
              5: () => 'Fai clic su "Recupera cronologia"',
              6: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              7: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    decentrex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Vai a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                'Sul lato sinistro del menu espandi "Cronologia scambi" e fai clic su Decentrex',
              3: () => 'In "Tipi di operazione" clicca su "Operazioni + Fondi"',
              4: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              5: () => "Mantieni le altre impostazioni predefinite",
              6: () => 'Fai clic su "Recupera cronologia"',
              7: () =>
                'Una volta completato, puoi esportare il csv predefinito in "Esporta scambi" e "Esporta fondi"',
              8: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questi file CSV",
            },
          },
        },
      },
    },
    deltaBalances: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () => "Seleziona lo scambio dal menu a discesa",
              3: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              4: () => "Mantieni le altre impostazioni predefinite",
              5: () => 'Fai clic su "Recupera cronologia"',
              6: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              7: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    deribit: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Apri la pagina Chiavi API su Deribit e seleziona Aggiungi nuova chiave API",
              1: () =>
                "Seleziona l'autorizzazione di lettura per Account, Commercio e Portafoglio",
              2: () => "Copia la chiave e il segreto in Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                'Accedi a Deribit e clicca sul tuo nome utente nell\'angolo in alto a destra e seleziona "Registro transazioni"',
              1: () =>
                "Seleziona le date in cui hai effettuato le negoziazioni e scarica i registri",
              2: () =>
                "Se hai fatto trading con Ethereum dovrai anche cliccare su Ethereum nell'angolo in alto a sinistra e ripetere i passaggi precedenti",
              3: () =>
                "Puoi quindi importare i file CSV nell'app per segnalare i profitti/le perdite guadagnati dal trading",
            },
          },
        },
      },
    },
    digitalsurge: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Digital Surge",
              1: () =>
                "Fai clic sul menu hamburger in alto a destra della pagina. Nel popover a discesa, seleziona 'API key' per andare alla pagina API Key (https://digitalsurge.com.au/account-settings/api-keys)",
              2: () =>
                'In alto a destra della pagina, clicca sul pulsante "Crea una nuova chiave API"',
              3: () =>
                'Nel popup, assicurati di selezionare "Sola lettura" e quindi fai clic su "Avanti"',
              4: () =>
                'Inserisci il codice di conferma 2FA e poi clicca su "Genera la chiave API"',
              5: () =>
                'Copia la chiave API visualizzata, incollala nel campo "Segreto" qui su Crypto Tax Calculator, inserisci un nickname facoltativo e quindi fai clic su "Aggiungi API" per sincronizzare la tua API',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Digital Surge",
              1: () =>
                "Fai clic sul menu hamburger in alto a destra della pagina. Nel popover a discesa seleziona 'Cronologia transazioni' per andare alla pagina Cronologia transazioni (https://digitalsurge.com.au/account-settings/transactions-history)",
              2: () =>
                'Nella pagina Cronologia transazioni, clicca sul pulsante "Esporta cronologia" (per dispositivi mobili, clicca sui tre puntini a destra del titolo Cronologia transazioni)',
              3: () =>
                "Nel menu a discesa, seleziona l'opzione 'Cronologia delle negoziazioni' per aprire il popup 'Esporta cronologia delle negoziazioni'. In questo popup, lascia vuota la selezione della data (ciò copre la cronologia completa delle negoziazioni) e scegli il formato 'CSV'. Una volta selezionato, fai clic sul pulsante 'Esporta cronologia' per scaricare il file 'trades.csv'",
              4: () =>
                "Nel menu a discesa, seleziona l'opzione 'Transfers history' per aprire il popup 'Export transfers history'. In questo popup, lascia vuota la selezione della data e scegli il formato 'CSV'. Una volta selezionato, clicca sul pulsante 'Export history' per scaricare il tuo file 'transfers.csv'",
              5: () =>
                "Carica qui i file 'trades.csv' e 'transfers.csv' in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    doge: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
              1: () =>
                "Supportiamo anche gli indirizzi xpub, ypub e zpub, tuttavia è preferibile xpub",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Dogecoin nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    dydx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Visita trade.dYdX.exchange e collega il/i tuo/i portafoglio/i",
              1: () => 'Fare clic su "Portfolio" nella barra dei menu',
              2: () => 'Seleziona "Cronologia" nel pannello laterale',
              3: () =>
                'Fare clic su "Esporta" nell\'angolo in alto a destra dello schermo',
              4: () =>
                "Scarica tutti e 3 i CSV disponibili: Trasferimenti, Scambi, Finanziamenti",
              5: () => "Carica i file estratti su Crypto Tax Calculator",
              6: () =>
                "Nota: al momento, avrai un problema di cronologia degli acquisti mancante a causa del modo in cui dYdX registra le transazioni short. Stiamo lavorando a una risoluzione. Per ora, puoi potenzialmente calcolare il PnL e usare il nostro CSV manuale per aggiungere una perdita/profitto realizzati per la transazione short.",
            },
          },
        },
      },
    },
    dydx4: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "In questa fase sono supportate solo le operazioni di trading derivate, come l'aumento/diminuzione di una posizione.",
            },
            title: () => "Supporto operativo limitato",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Individua il tuo indirizzo dYdX v4 andando all'applicazione del protocollo e copiando l'indirizzo dal menu in alto a destra",
              1: () =>
                'Inserisci l\'indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    dymension: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Dymension nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    easyTrade: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () => "Seleziona lo scambio dal menu a discesa",
              3: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              4: () => "Mantieni le altre impostazioni predefinite",
              5: () => 'Fai clic su "Recupera cronologia"',
              6: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              7: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    easycrypto: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento supportiamo acquisti e vendite su Easy Crypto. Se stai eseguendo swap o altri tipi di scambi, contatta l'assistenza e invia il tuo file CSV al nostro team di assistenza",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Easy Crypto",
              1: () =>
                'Vai su "Il mio account" - > "Ordini" (https://easycrypto.ai/au/account/orders)',
              2: () =>
                "Vicino alla parte inferiore della pagina vedrai un link per 'Scarica un CSV dei tuoi ordini completati'. Clicca su questo link e un file CSV verrà scaricato sul tuo computer",
              3: () =>
                "Carica questo file scaricato qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    elbaite: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Elbaite",
              1: () => "Vai alla scheda [Scambi]",
              2: () => "Fare clic su [Esporta in CSV]",
              3: () =>
                "Carica il file CSV qui sul tuo account Crypto Tax Calculator",
            },
          },
        },
      },
    },
    enclaves: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Vai a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                'Sul lato sinistro del menu espandi "Cronologia scambi" e fai clic su Enclaves',
              3: () => 'In "Tipi di operazione" clicca su "Operazioni + Fondi"',
              4: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              5: () => "Mantieni le altre impostazioni predefinite",
              6: () => 'Fai clic su "Recupera cronologia"',
              7: () =>
                'Una volta completato, puoi esportare il csv predefinito in "Esporta scambi" e "Esporta fondi"',
              8: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questi file CSV",
            },
          },
        },
      },
    },
    enkrypt: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio Enkrypt",
              1: () => "Fare clic sulla rete che si desidera importare",
              2: () => "Fare clic sull'icona Copia indirizzo",
              3: () =>
                "In Crypto Tax Calculator seleziona la rete corrispondente",
              4: () =>
                'Incolla l\'indirizzo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    ercDex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    eth: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ora supportiamo i token ERC-1155, tuttavia non siamo in grado di importare i tuoi saldi correnti per questi token. Ciò potrebbe causare discrepanze tra il saldo importato dall'API di origine e il saldo calcolato dall'app.\n Attualmente i token ERC-404 non sono supportati, anche se stiamo lavorando per aggiungerli. Per ora, dovrai aggiungere manualmente queste transazioni nell'app.",
            },
            title: () => "Appunti",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inserisci l'indirizzo del tuo portafoglio Ethereum",
              1: () =>
                'Aggiungi un soprannome facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    ethen: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Vai a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                'Sul lato sinistro del menu espandi "Cronologia scambi" e clicca su ETHEN',
              3: () => 'In "Tipi di operazione" clicca su "Operazioni + Fondi"',
              4: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              5: () => "Mantieni le altre impostazioni predefinite",
              6: () => 'Fai clic su "Recupera cronologia"',
              7: () =>
                'Una volta completato, puoi esportare il csv predefinito in "Esporta scambi" e "Esporta fondi"',
              8: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questi file CSV",
            },
          },
        },
      },
    },
    etherc: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () => "Seleziona lo scambio dal menu a discesa",
              3: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              4: () => "Mantieni le altre impostazioni predefinite",
              5: () => 'Fai clic su "Recupera cronologia"',
              6: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              7: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    etherdelta: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Vai a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                'Sul lato sinistro del menu espandi "Cronologia scambi" e fai clic su EtherDelta',
              3: () => 'In "Tipi di operazione" clicca su "Operazioni + Fondi"',
              4: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              5: () => "Mantieni le altre impostazioni predefinite",
              6: () => 'Fai clic su "Recupera cronologia"',
              7: () =>
                'Una volta completato, puoi esportare il csv predefinito in "Esporta scambi" e "Esporta fondi"',
              8: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questi file CSV",
            },
          },
        },
      },
    },
    "ethereum-classic": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
          1: {
            items: {
              0: () =>
                "Potrebbero mancare alcune transazioni interne per alcuni wallet.",
            },
            title: () => "Transazioni interne mancanti",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Ethereum Classic nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    etherscan: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Il modo migliore per importare dati da EtherScan è importare direttamente l'indirizzo del tuo portafoglio pubblico",
              1: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico Ethereum nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    ethex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    ethfinex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () => "Seleziona lo scambio dal menu a discesa",
              3: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              4: () => "Mantieni le altre impostazioni predefinite",
              5: () => 'Fai clic su "Recupera cronologia"',
              6: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              7: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    etoro: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "eToro modifica frequentemente il formato del file CSV delle negoziazioni esportate. Se il tuo file non viene importato sulla piattaforma, è possibile che lo abbiano modificato di nuovo. In questo caso, ti consigliamo di utilizzare l'importazione CSV CTC avanzata per le tue negoziazioni eToro.",
              1: () =>
                "Poiché Crypto Tax Calculator è progettato per transazioni in criptovaluta, non siamo in grado di importare transazioni non in criptovaluta. Se il tuo account eToro include tali transazioni, dovrai contabilizzarle esternamente.",
            },
            title: () => "Note:",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account eToro. Puoi accedere direttamente all'estratto conto qui (https://www.etoro.com/documents/accountstatement) oppure seguire i passaggi sottostanti.",
              1: () =>
                'Dalla barra laterale, clicca su "Portfolio", quindi vai su "Cronologia"',
              2: () =>
                'Espandi il menu a discesa in alto a destra e clicca su "Estratto conto"',
              3: () =>
                'Imposta l\'intervallo di tempo dei dati della tua transazione e clicca su "Crea"',
              4: () =>
                "In alto a destra, clicca sull'icona 'XLS' per scaricare un file '.xlsx'",
              5: () =>
                'Nel campo "Simbolo valuta" della casella di importazione, fai corrispondere la valuta esattamente all\'intestazione CSV (ad esempio, usa USD anziché $).',
              6: () => "Carica il tuo file '.xlsx' in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    evmos: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Evmos in fase di sviluppo iniziale",
              1: () => "I dati precedenti al 28/04/2022 non sono disponibili",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Evmos nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    exmo: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
              1: () =>
                'Non supportiamo indirizzi IP specifici sulla nostra piattaforma, assicurati che "Accesso API solo da indirizzi IP attendibili" sia disabilitato quando generi una chiave API',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account Exmo e vai alla scheda delle impostazioni",
              1: () =>
                'Fare clic sulla scheda API e assicurarsi che l\'opzione "Accesso API solo da indirizzi IP attendibili" sia disattivata',
              2: () =>
                'Crea una chiave API cliccando su "Genera chiave API" e inseriscila qui nel calcolatore delle imposte sulle criptovalute rispettivamente come "Chiave API" e "Segreta"',
            },
          },
        },
      },
    },
    exodus: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vai alla sezione cronologia del tuo portafoglio (in alto a destra del tuo portafoglio Exodus)",
              1: () => "Fare clic sull'opzione esporta tutte le transazioni",
              2: () => "Carica il file su Crypto Tax Calculator",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio Exodus.",
              1: () =>
                "Seleziona la criptovaluta che desideri importare. Ad esempio, Bitcoin o Ethereum.",
              2: () =>
                "Copia l'indirizzo del portafoglio pubblico corrispondente per quella risorsa.",
              3: () =>
                "In CTC, seleziona la rete blockchain corrispondente dal menu a discesa.",
              4: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico in CTC e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    fantom: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ora supportiamo i token ERC-1155, tuttavia non siamo in grado di importare i tuoi saldi correnti per questi token. Ciò potrebbe causare discrepanze tra il saldo importato dall'API di origine e il saldo calcolato dall'app.",
            },
            title: () => "Token ERC-1155",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Fantom nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    "fearless-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio Fearless",
              1: () => "In alto a destra, seleziona la rete che vuoi importare",
              2: () =>
                "Fare clic sull'icona di copia dell'indirizzo in alto a sinistra",
              3: () =>
                "In Crypto Tax Calculator seleziona la rete corrispondente",
              4: () =>
                'Incolla l\'indirizzo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    fetchai: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Fetch.AI nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    flare: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
          1: {
            items: {
              0: () =>
                "Potrebbero mancare alcune transazioni interne per alcuni wallet.",
            },
            title: () => "Transazioni interne mancanti",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Flare nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    ftx: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Cronologia del commercio",
              1: () => "Rendiconti PnL Futures & Perpetuals",
              2: () => "Cronologia depositi/prelievi",
            },
            title: () =>
              "FTX non è più operativo. Utilizza i CSV FTX esistenti o l'opzione di importazione CSV personalizzata di Crypto Tax Calculator per importare le tue transazioni FTX. I CSV FTX esistenti che supportiamo sono:",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Importiamo solo le negoziazioni spot per il CSV 'Trade History', non le negoziazioni futures e perpetue (ad esempio XXX-PERP). Utilizza il CSV PnL 'Futures & Perpetuals' per importare questi dati.",
              1: () =>
                "Il CSV 'Order History' non è supportato. Utilizzare invece i CSV PnL 'Trade History' e 'Futures & Perpetuals'.",
            },
            title: () => "Note di supporto CSV FTX preesistenti",
          },
        },
      },
      api: {
        step: {
          0: {
            title: () =>
              "FTX non è più operativo. Utilizza l'opzione di importazione CSV personalizzata per importare le tue transazioni FTX.",
          },
        },
      },
    },
    ftxus: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX non è più operativo e non è più possibile ottenere dati storici da questo exchange. Le transazioni FTX importate in precedenza saranno comunque accessibili nel tuo account. Le nuove importazioni devono utilizzare CSV FTX esistenti o l'opzione di importazione CSV personalizzata. Le sincronizzazioni API non sono più possibili.",
            },
            title: () => "FTX non è più operativo",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX non è più operativo. Utilizza l'opzione di importazione CSV personalizzata per importare le tue transazioni FTX.",
          },
        },
      },
      csv: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX non è più operativo e non è più possibile ottenere dati storici da questo exchange. Le transazioni FTX importate in precedenza saranno comunque accessibili nel tuo account. Le nuove importazioni devono utilizzare CSV FTX esistenti o l'opzione di importazione CSV personalizzata. Le sincronizzazioni API non sono più possibili.",
            },
            title: () => "FTX non è più operativo",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX non è più operativo. Utilizza l'opzione di importazione CSV personalizzata per importare le tue transazioni FTX.",
          },
        },
      },
    },
    gate: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Gate.io",
              1: () => "Vai alla pagina Chiavi API",
              2: () =>
                "Se applicabile, inserisci la password del fondo e il TOTP",
              3: () => 'Fare clic sul pulsante "Mostra chiavi API"',
              4: () => "Copia sia la chiave API che il segreto API",
              5: () => "Abilita la sola lettura per la tua chiave API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Gate.io",
              1: () => "Vai a Portafogli > Cronologia delle negoziazioni",
              2: () => 'Fai clic su "TUTTI" per mercati e monete',
              3: () =>
                "Assicurare che le date selezionate siano comprese in tutti gli scambi",
              4: () =>
                'Fare clic sul pulsante "Download" che appare come una freccia rivolta verso il basso',
              5: () =>
                "Carica CSV sul calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
    },
    gateio: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Potrebbero esserci piccole discrepanze nel saldo dovute a problemi di arrotondamento sui file CSV stessi",
            },
            title: () => "Formattazione CSV",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account Gate.io e vai su https://www.gate.io/myaccount/mypurselog . Puoi anche accedere a questa pagina dal menu della barra laterale > Cronologia delle transazioni.",
              1: () => 'Selezionare "Tutti" per Moneta e "Tutti" per Filtro.',
              2: () => 'Fare clic su "Filtro" > Scarica',
              3: () =>
                'Puoi anche cliccare su "Richiedi dati batch" nell\'angolo in alto a destra.',
              4: () => 'Seleziona "Tutti" per Coin e invia la domanda.',
              5: () =>
                "Puoi scaricare 90 giorni di cronologia delle transazioni ogni volta. Se ne hai di più, ripeti i passaggi per periodi diversi.",
              6: () => "Importare il file esportato 'mypurselog.csv' in CTC.",
            },
          },
        },
      },
    },
    gemini: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Crypto Tax Calculator dovrebbe essere l'unico servizio che utilizza la chiave API che hai fornito. Probabilmente riscontrerai problemi di importazione se utilizzi una chiave API condivisa con un altro servizio.",
              1: () =>
                "Si prega di notare che le transazioni con importi inferiori a 10^-12 (di qualsiasi valuta) non verranno importate.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a Gemini e vai su Account > Impostazioni (in alto a destra dello schermo)",
              1: () =>
                "Scorri verso il basso fino alla scheda API sul lato sinistro",
              2: () =>
                "Fai clic su Crea una nuova chiave API (non utilizzare una chiave esistente)",
              3: () =>
                'Seleziona l\'ambito: "Primario" e fai clic su "Crea chiave API"',
              4: () =>
                "Spunta la casella di controllo 'Auditor' nelle impostazioni della chiave API. NON fare nient'altro nelle impostazioni API.",
              5: () => "Copia la chiave API e il segreto",
              6: () =>
                'Seleziona "Sì" nella casella di controllo copia e incolla',
              7: () => "Clicca su conferma",
              8: () =>
                "Aggiungi la chiave API e il segreto copiati nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                'Fai clic su "Connetti a Gemini" per essere reindirizzato a Gemini Exchange',
              1: () => "Accedi al tuo account Gemini.",
              2: () => "Fornire il codice 2FA se richiesto",
              3: () =>
                'Seleziona l\'account che vuoi connettere, quindi fai clic su "Continua"',
              4: () => 'Fare clic sul pulsante "Consenti"',
            },
          },
        },
      },
    },
    "giddy-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                'Apri l\'app mobile Giddy Wallet e vai alla sezione "Portafoglio" (angolo in basso a sinistra).',
              1: () => "Fare clic sull'icona QR in alto a sinistra.",
              2: () =>
                "Copia l'indirizzo del portafoglio pubblico che inizia con '0x...'",
              3: () => 'Incolla l\'indirizzo e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    graviex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Vai alla pagina della cronologia del tuo account",
              1: () =>
                "Fare clic sulla cronologia delle negoziazioni ed esportare i dati",
              2: () => "Carica su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    grs: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
              1: () =>
                "Supportiamo anche gli indirizzi xpub, ypub e zpub, tuttavia è preferibile xpub",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Groestlcoin nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    hedera: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Inserisci l'indirizzo del tuo portafoglio Hedera",
              1: () =>
                'Aggiungi un soprannome facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    hitbtc: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento non supportiamo addebiti di interessi sui margini o pagamenti di finanziamenti futures per l'integrazione API, poiché HitBTC non offre un endpoint API per ottenere queste informazioni. Stiamo lavorando con HitBTC per risolvere questo problema.",
              1: () =>
                "HitBTC non restituisce la cronologia delle negoziazioni spot per monete delistate come VERI. Ciò può comportare che i saldi non corrispondano se hai negoziazioni storiche su quelle coppie.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account HitBTC e vai alla pagina delle chiavi API di HitBTC, nella scheda Account (https://hitbtc.com/settings/api-keys)",
              1: () =>
                'Fai clic sul pulsante "Nuova chiave API" e inserisci il codice di autenticazione a due fattori',
              2: () =>
                "Abilita entrambe le caselle di controllo: 'Libro ordini, Cronologia, Saldo spot' e 'Informazioni di pagamento'",
              3: () =>
                "Copia la chiave API e il segreto e inseriscili nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento non supportiamo i pagamenti di finanziamento futures per l'integrazione CSV. Stiamo lavorando per risolvere questo problema.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account HitBTC e vai alla pagina dei report HitBTC (https://hitbtc.com/reports/trades)",
              1: () =>
                'Per le transazioni tramite portafoglio: seleziona "Cronologia transazioni" in "Portafoglio"',
              2: () =>
                'Per le negoziazioni spot: seleziona "Le mie negoziazioni" in "Spot"',
              3: () =>
                'Per le negoziazioni a margine: seleziona "Le mie negoziazioni" e "Interessi" sotto "Margine"',
              4: () => "Fare clic su Esporta come .csv e scaricare il file CSV",
              5: () => "Carica i tuoi file CSV qui su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    hodlnaut: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Per passaggi più dettagliati con screenshot, visualizza la pagina di supporto di Hodlnaut all'indirizzo: https://www.hodlnaut.com/crypto-guides/hodlnaut-revamps-csv-format",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Una volta effettuato l\'accesso a Hodlnaut, vai su "Il mio account" sulla barra dei menu in alto',
              1: () =>
                'Scorri verso il basso fino a "Transazioni" e fai clic sul pulsante Esporta nell\'angolo in alto a destra',
              2: () => "Scarica il report CSV sul tuo dispositivo",
              3: () =>
                "Carica il file CSV in Crypto Tax Calculator utilizzando il pulsante di importazione.",
            },
          },
        },
      },
    },
    honeyswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente classifichiamo automaticamente gli Swap e aggiungiamo/rimuoviamo la Liquidità su Honeyswap",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio Gnosis Chain che hai utilizzato per fare trading tramite Honeyswap",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    hotbit: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Poiché l'opzione di esportazione della cronologia dei download di Hotbit consente di esportare solo 30 giorni di operazioni alla volta, dovrai contattare il loro supporto e richiedere di ottenere un registro completo della cronologia delle tue operazioni.",
              1: () =>
                "Il supporto Hotbit dovrebbe inviarti un file .xlsx con le seguenti intestazioni: 'time', 'user_id', 'market', 'side', 'role', 'price', 'amount', 'deal', 'fee', 'platform', 'stock', 'deal_stock'. Conferma che il file ricevuto abbia le intestazioni di cui sopra, non modificare o salvare nuovamente il file nel processo",
              2: () => "Carica il file .xlsx qui su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    huobi: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Huobi",
              1: () => "Vai alla pagina di gestione API",
              2: () => "Inserisci una nota a tua scelta",
              3: () => 'Fare clic sul pulsante "Crea"',
              4: () => "Copia sia la chiave di accesso che la chiave privata",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                'Dovrai contattare l\'assistenza e chiedere loro di inviarti via e-mail i file CSV "Cronologia transazioni", "Cronologia prelievi" e "Cronologia depositi"',
              1: () =>
                "Potrebbero volerci fino a 5 giorni per l'invio da parte di Huobi",
              2: () =>
                "Una volta ricevuti questi file, puoi caricarli qui su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    hyperliquid: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento non siamo in grado di importare automaticamente le transazioni relative ai premi di referral. Ciò potrebbe causare piccole discrepanze nel saldo. Per garantire che i tuoi record siano accurati, ti consigliamo di importare manualmente queste transazioni, se applicabile. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () => "Ricompense per referral",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inserisci l'indirizzo del tuo portafoglio Hyper",
              1: () =>
                'Aggiungi un soprannome facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    ice3x: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a ice3x",
              1: () =>
                "Vai su account > cronologia > ordini https://ice3x.com/account/history",
              2: () => 'Fare clic sul pulsante "Esporta in Excel"',
              3: () => "Carica su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    idex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi a IDEX http://idex.market",
              1: () => "Vai a Profilo > Indirizzo portafoglio",
              2: () => "Copia l'indirizzo del tuo portafoglio IDEX",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a IDEX",
              1: () =>
                "Vai a Ordini > Cronologia delle negoziazioni https://idex.market/trades",
              2: () => "Clicca su 'Scarica cronologia scambi'",
              3: () =>
                "Carica CSV sul calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
    },
    idt: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    immutable: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Immutable X nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
              1: () =>
                "Nota: a causa dell'inaffidabilità dei dati forniti da Immutable X, alcuni portafogli potrebbero non riuscire a importare le transazioni.",
              2: () =>
                "Finché non saremo in grado di garantire una fonte dati affidabile, dovrai importare manualmente questi dati utilizzando il nostro metodo CSV personalizzato.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    independentreserve: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Independent Reserve",
              1: () =>
                'Nella barra di navigazione in alto, vai alla sezione "Impostazioni" (https://app.independentreserve.com/settings)',
              2: () => 'Fare clic sulla scheda "Chiavi API"',
              3: () =>
                'Fare clic sul pulsante "Genera" per creare una nuova chiave',
              4: () =>
                'Seleziona l\'opzione "Sola lettura (consigliata)" e aggiungi la descrizione della chiave API come "Calcolatrice delle imposte sulle criptovalute"',
              5: () =>
                'Inserisci la tua password e clicca sul pulsante "Salva"',
              6: () =>
                'Copia la "chiave API" e il "segreto API" qui nel calcolatore delle imposte sulle criptovalute',
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/4-HxdE3BdmU",
            title: () => "Come importare la tua API Independent Reserve",
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Independent Reserve",
              1: () =>
                'Nella barra di navigazione in alto, vai al menu a discesa "IMPOSTE E REPORT" e seleziona "Report"',
            },
          },
          1: {
            items: {
              0: () =>
                'Nei report, fare clic sul pulsante "Report cronologia ordini"',
              1: () =>
                "Nel popup, seleziona l'intervallo di date che copre la cronologia completa degli ordini per l'account",
              2: () =>
                'Seleziona l\'opzione "Scarica cronologia ordini come file CSV", quindi fai clic su "Scarica" per scaricare la cronologia ordini in formato CSV',
              3: () =>
                "Carica questo file CSV 'OrderStatement' qui in Crypto Tax Calculator",
            },
            title: () => "Per la cronologia degli ordini:",
          },
          2: {
            items: {
              0: () =>
                'Nei report, fare clic sul pulsante "Report cronologia transazioni"',
              1: () =>
                "Nel popup, seleziona l'intervallo di date che copre la cronologia completa degli ordini per l'account",
              2: () =>
                'Seleziona l\'opzione "Scarica la cronologia delle transazioni come file CSV", quindi fai clic su "Scarica" per scaricare la cronologia delle transazioni in formato CSV',
              3: () =>
                "Carica questo file CSV 'TransactionHistory' qui in Crypto Tax Calculator",
            },
            title: () => "Per depositi/prelievi:",
          },
        },
        video: {
          0: {
            items: {
              0: () =>
                'Non recupereremo i tuoi dati "Trades" dal file CSV "TransactionHistory", bensì dal file CSV "OrderStatement", poiché è più accurato.',
              1: () =>
                "Attualmente, gli unici formati di data e ora supportati sono:",
              2: () => "'GG/MM/AAAA HH:mm' (ad esempio 12/02/2020 13:09)",
              3: () =>
                "'GG MMM AAAA HH:mm:ss' (ad esempio 12 feb 2020 13:09:22)",
            },
            link: () => "https://youtu.be/Qaz6XSJ-jck",
            title: () => "Come importare i file CSV della Riserva Indipendente",
          },
        },
      },
    },
    injective: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Injective nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    iotex: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "IoTeX è in fase di sviluppo iniziale",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio IoTeX nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    "jaxx-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Accedi a Jaxx Liberty",
              1: () =>
                "Selezionare il menu a tre linee (angolo in alto a destra)",
            },
          },
          1: {
            items: {
              0: () => "Seleziona Visualizza frase di backup",
              1: () =>
                "Seleziona la casella È sicuro e fai clic su Visualizza frase di backup",
              2: () => "Vai su https://iancoleman.io/bip39",
              3: () =>
                "Incolla la frase di backup Jaxx nel campo mnemonico BIP39",
              4: () => "Seleziona moneta",
              5: () => "Seleziona la scheda BIP44",
              6: () =>
                "Digitare '0' in Esterno / Interno per ottenere xPub per ricevere gli indirizzi",
              7: () => "Copia la chiave pubblica estesa BIP32",
              8: () =>
                "Digitare '1' in Esterno/Interno per ottenere xPub per modificare gli indirizzi",
              9: () => "Copia la chiave pubblica estesa BIP32",
              10: () =>
                "Aggiungi l'indirizzo xPub in CTC e fai clic su Sincronizza",
            },
            title: () =>
              "Per i portafogli Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin e Zcash",
          },
          2: {
            items: {
              0: () => "Seleziona Visualizza chiavi private",
              1: () =>
                'Seleziona la casella "È sicuro" e fai clic su Visualizza chiavi private',
              2: () =>
                "Seleziona il portafoglio di monete pertinente (può essere ripetuto per più portafogli)",
              3: () =>
                "Copiare gli indirizzi pubblici. Non copiare le chiavi private",
              4: () => "Trova gli indirizzi rilevanti per ogni transazione",
              5: () => "Copia ogni indirizzo",
              6: () => "Incolla in CTC e fai clic su Sincronizza",
            },
            title: () => "Per tutte le altre monete",
          },
        },
      },
    },
    juno: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Giunone in fase di sviluppo iniziale",
              1: () =>
                "Solo etichette di trasferimenti/picchettamento (le altre appaiono come sconosciute)",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Juno nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    kaspa: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Enter your Kaspa wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    kava: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Al momento stiamo riscontrando alcuni problemi nella raccolta di token ERC-1155 e transazioni non EVM e stiamo lavorando a una soluzione. Se nel frattempo riscontri questo problema, prova invece il nostro processo di caricamento CSV manuale. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Token ERC-1155 e transazioni non EVM",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Kava EVM nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    kraken: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "L'API di Kraken ha un limite di velocità rigoroso di circa 50 transazioni ogni 6 secondi, il che potrebbe rallentare la sincronizzazione di un gran numero di transazioni. Per maggiori dettagli, visita la loro [pagina del limite di velocità API](https://www.kraken.com/features/api#api-call-rate-limit).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a Kraken e vai su Impostazioni > Sicurezza > API. (https://pro.kraken.com/app/settings/api)",
              1: () => 'Fai clic su "Crea chiave API"',
              2: () =>
                'Assegna un nome alla tua chiave API e seleziona le autorizzazioni: "Query", "Query ordini e negoziazioni chiuse" e "Query voci del registro"',
              3: () =>
                'Lascia tutte le altre autorizzazioni e impostazioni così come sono e fai clic su "Genera chiave"',
              4: () =>
                'Copia la "Chiave API" e la "Chiave privata" in Crypto Tax Calculator come "Chiave API" e "Segreto".',
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/2WAYzAyPOKQ",
            title: () => "Come importare la tua API Kraken",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente, gli unici formati di data supportati per Kraken CSV sono MM/GG/AAAA, AAAA-MM-GG, GG/MM/AA HH:mm, AAAA-MM-GG HH:mm:ss.SSS, AAAA-MM-GG HH:mm:ss e MM/GG/AAAA HH:mm",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Accedi al tuo account Kraken, vai alla scheda "Cronologia", quindi clicca su "Esporta".',
              1: () =>
                'Seleziona "Conto mastro" come tipo di esportazione, scegli il periodo di negoziazione, seleziona "Seleziona tutto" e clicca su "Invia".',
              2: () =>
                "Attendi qualche secondo, aggiorna la pagina se necessario e scarica il CSV in un file .zip.",
              3: () =>
                "Decomprimi il file e carica i file CSV su Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/X7v6RyNCe7A",
            title: () => "Come importare i CSV di Kraken",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                'Fai clic su "Connetti a Kraken" per andare al Kraken Exchange',
              1: () => "Accedi al tuo account Kraken.",
              2: () =>
                "Se il tuo account utilizza una Passkey o 2FA, inserisci il codice richiesto. Altrimenti, salta questo passaggio.",
              3: () => 'Fare clic sul pulsante "Consenti"',
            },
          },
        },
        video: {
          0: {
            link: () => "Italiano: https://youtu.be/Hrn695jsmoA",
            title: () => "Come importare il tuo account Kraken tramite Oauth",
          },
        },
      },
    },
    kucoin: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "L'API di KuCoin fornisce solo la cronologia delle transazioni dell'ultimo anno. Utilizza un file CSV per i dati più vecchi.",
              1: () =>
                "Al momento supportiamo i dati storici di Depositi, Prelievi, Spot e Margin Trading e Futures per KuCoin. Si noti che i saldi potrebbero non essere corretti poiché non otteniamo la cronologia di prestiti/prestiti a margine.",
              2: () =>
                "Kucoin ha recentemente disabilitato il suo endpoint di trading storico. Nel frattempo, finché questo problema non sarà risolto, dovrai aggiungere questi dati in Crypto Tax Calculator separatamente utilizzando l'opzione di importazione CSV CTC (https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import)",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a KuCoin e vai su Gestione API > Chiavi API (https://www.kucoin.com/account/api)",
              1: () =>
                'Fare clic su "Crea API" e selezionare "Trading basato su API".',
              2: () => "Inserisci un nome e una passphrase a tua scelta.",
              3: () =>
                'Abilita solo l\'autorizzazione "Generale"; disabilita le altre.',
              4: () =>
                'Imposta la restrizione IP su "No", fai clic su "Avanti" e completa la verifica di sicurezza',
              5: () =>
                "Copia la passphrase, la chiave API e il segreto API e incollali nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/PtORZYAm-gc",
            title: () => "Come importare la tua API Kucoin",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () => "Accedi a KuCoin",
              1: () =>
                "Vai a Ordini > Cronologia scambi spot > Cronologia scambi",
              2: () =>
                "Clicca su 'clicca qui per visualizzare le transazioni della versione precedente'",
              3: () => 'Fare clic su "Esporta in CSV"',
              4: () =>
                "KuCoin consente di esportare solo 100 giorni alla volta, quindi seleziona intervalli di 100 giorni dalla cronologia di trading di tutti i tempi",
              5: () =>
                "Una volta scaricato il CSV, vai su https://www.kucoin.com/account/download",
              6: () => "Fare clic sull'icona di download",
            },
            title: () => "Istruzioni per l'importazione CSV versione 1",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account KuCoin e vai al Centro Ordini. (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1)",
              1: () => 'Fare clic su "Esporta".',
              2: () =>
                "Selezionare il report desiderato: Cronologia depositi/prelievi, Ordini spot, Ordini a margine o Ordini futures.",
              3: () =>
                "Selezionare l'intervallo di date (fino a 12 mesi per esportazione).",
              4: () =>
                'Selezionare "Include sottoconti" se necessario (solo per Spot, Margine e Futures).',
              5: () => "Seleziona '.csv' e clicca su 'Conferma' per scaricare.",
              6: () =>
                "Carica i file rilevanti su CTC: Cronologia depositi, Registro prelievi, Ordini spot - Ordini eseguiti, Ordini a margine - Ordini eseguiti - Incrociati e isolati, Ordini a margine - Prestiti - Incrociati e isolati e PnL realizzato.",
            },
          },
          1: {
            items: {
              0: () =>
                "Segui i passaggi sul Centro assistenza KuCoin (https://www.kucoin.com/support/14047696578841)",
              1: () =>
                "Accedi al tuo account Kucoin e visita il Centro Ordini (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1)",
              2: () => 'Fare clic sul pulsante "Esporta"',
              3: () =>
                "Fare clic sul menu a discesa del mercato e selezionare Cronologia depositi/prelievi, Ordini spot, Ordini margine, Ordini futures",
              4: () =>
                "Seleziona l'intervallo di date che desideri scaricare (solo 12 mesi per esportazione)",
              5: () =>
                'Se devi includere dati da sottoconti, seleziona l\'opzione "Include sottoconti". (Per i sottoconti sono supportati solo i report Spot, Margine e Futures).',
              6: () =>
                "Selezionare '.csv' e quindi fare clic su 'conferma' per scaricare i file.",
              7: () =>
                "Carica i file necessari su CTC. Non tutti i file scaricati sono utili per CTC, i file rilevanti includono: Cronologia depositi, Record prelievi, Ordini spot - Ordini eseguiti, Ordini a margine - Ordini eseguiti - Cross e isolati, Ordini a margine - Prestiti - Cross e isolati e PnL realizzato",
            },
            title: () => "Cronologia del commercio",
          },
          2: {
            title: () => "Cronologia depositi e prelievi",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/81gyRIU1HYM",
            title: () => "Come importare il tuo CSV Kucoin",
          },
        },
      },
    },
    kujira: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Kujira in fase di sviluppo iniziale",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Kujira nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    kyber: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () => "Seleziona lo scambio dal menu a discesa",
              3: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              4: () => "Mantieni le altre impostazioni predefinite",
              5: () => 'Fai clic su "Recupera cronologia"',
              6: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              7: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    kyve_network: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Kyve nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    latoken: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Dovrai contattare l'assistenza LATOKEN per richiedere il report sulla cronologia delle transazioni",
              1: () =>
                "Una volta ricevuto questo, puoi importarlo direttamente nella piattaforma",
            },
          },
        },
      },
    },
    leather_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                'Fare clic su "Collega portafoglio" e seguire le istruzioni.',
            },
          },
        },
      },
    },
    ledger_blue: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Collega il tuo Ledger al tuo dispositivo",
              1: () => "Accedi all'app desktop Ledger Live",
              2: () => "Apri il portafoglio Ledger per la moneta di tua scelta",
              3: () => "Selezionare l'account pertinente (lato sinistro)",
            },
          },
          1: {
            items: {
              0: () =>
                "Fare clic sull'icona della chiave inglese (in alto a destra)",
              1: () => "Fare clic su Registri avanzati",
              2: () =>
                "Copia l'indirizzo del portafoglio HD preceduto da 'xpub' tra virgolette",
              3: () => "Incolla in CTC",
            },
            title: () =>
              "Per i portafogli Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin e Zcash",
          },
          2: {
            items: {
              0: () =>
                "Visualizza l'elenco delle transazioni e degli indirizzi nel portafoglio",
              1: () => "Copia ogni indirizzo",
              2: () => "Incolla in CTC",
            },
            title: () => "Per tutte le altre monete",
          },
        },
      },
    },
    ledgerwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "L'esportazione CSV da Ledger Live potrebbe avere delle transazioni mancanti, il che potrebbe causare l'errata elaborazione dei saldi da parte di Crypto Tax Calculator. Ove possibile, ti consigliamo di sincronizzare direttamente ciascuno dei wallet blockchain sottostanti utilizzando l'opzione \"Sincronizzazione tramite API\".",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Fare clic su Account nel pannello di sinistra.",
              1: () =>
                "Fare clic sul pulsante ⋯ Opzioni nell'angolo in alto a destra.",
              2: () => "Selezionare l'opzione Esporta cronologia operazioni.",
              3: () =>
                "Seleziona gli account da includere nella finestra che appare.",
              4: () => "Fare clic su Salva.",
              5: () => "Salva il file nella cartella che preferisci.",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Accedi all'app desktop Ledger Live",
              1: () => "Apri il portafoglio Ledger per la moneta di tua scelta",
              2: () => "Selezionare l'account pertinente (lato sinistro)",
            },
          },
          1: {
            items: {
              0: () =>
                "Fare clic sull'icona della chiave inglese (in alto a destra)",
              1: () => "Fare clic su Registri avanzati",
              2: () =>
                "Copia l'indirizzo del portafoglio HD preceduto da 'xpub' tra virgolette",
              3: () => "Incolla in CTC",
            },
            title: () =>
              "Per i portafogli Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin e Zcash",
          },
          2: {
            items: {
              0: () =>
                "Visualizza l'elenco delle transazioni e degli indirizzi nel portafoglio",
              1: () => "Copia ogni indirizzo",
              2: () => "Incolla in CTC",
            },
            title: () => "Per tutte le altre monete",
          },
        },
      },
    },
    ledn: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Assicurati che il tuo report comprenda tutti gli anni in cui hai fatto trading di criptovalute",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Ledn tramite un browser web",
              1: () =>
                'Vai alla pagina "Commercio" dal menu di navigazione a sinistra',
              2: () =>
                'Fai clic su "Scarica CSV" per scaricare i tuoi dati di trading',
              3: () =>
                "Non aprire o salvare nuovamente il file prima di importarlo, poiché ciò potrebbe modificare il formato del file CSV",
              4: () =>
                "Carica il file che hai appena scaricato qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    linea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Linea nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    liqui: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vai alla sezione fondi/portafoglio nell'angolo in alto a destra",
              1: () =>
                "Seleziona l'intervallo di date ed esporta tramite e-mail (potresti dover eseguire questa operazione più volte per tutte le tue transazioni)",
              2: () =>
                "Una volta ricevuta l'email, carica il file ricevuto su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    liquid: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Offriamo prelievi Spot, Margin, Infinity, Perpetuals e Criptovalute.",
              1: () =>
                "Liquid API non fornisce informazioni sui depositi di criptovaluta. Dovrai aggiungerli manualmente se desideri monitorarli.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a Liquid e apri il menu Liquid cliccando sull'icona del tuo utente in alto a destra",
              1: () =>
                "Vai a Token API > Chiavi API https://app.liquid.com/settings/api-tokens",
              2: () => 'Fare clic sul pulsante "Crea token API"',
              3: () =>
                "Seleziona le seguenti autorizzazioni di sola lettura: 'Account', 'Esecuzioni', 'Ordini', 'Posizioni', 'Conti di trading' e 'Prelievi'",
              4: () =>
                "Nonostante le istruzioni sembrino richiedere l'indirizzo IP, la chiave API può essere generata senza richiederne uno. Lascia vuoto questo campo.",
              5: () => 'Fare clic sul pulsante "Crea token"',
              6: () =>
                "Copia l'ID del token (chiave API) e il segreto del token (segreto API) e inseriscili qui nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Liquid",
              1: () =>
                "Vai a Portafogli > Transazioni > Scorri verso il basso fino al Report di panoramica",
              2: () =>
                'Assicurati che "Seleziona fuso orario" sia "UTC+00:00" e assicurati che le date selezionate siano comprese in tutte le negoziazioni',
              3: () => 'Fare clic sul pulsante "Invia rapporto tramite e-mail"',
              4: () =>
                "Una volta ricevuta l'email da Liquid, scarica il file CSV e caricalo qui su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    localbitcoins: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account LocalBitcoins",
              1: () =>
                "Nell'angolo in alto a destra della barra di navigazione, fai clic sull'icona del profilo e fai clic su \"Impostazioni\" dal menu a discesa per accedere alle impostazioni (https://localbitcoins.com/settings)",
              2: () =>
                'Fare clic sulla scheda "Sicurezza", quindi in "Dati personali", fare clic su "Visualizza"',
              3: () =>
                'Fai clic sul pulsante blu "Raccogli dati personali" per iniziare la generazione della cronologia completa delle tue negoziazioni',
              4: () =>
                'Una volta che il download è pronto, clicca sul pulsante blu "Scarica dati personali" per scaricare i tuoi dati in un file .zip',
              5: () =>
                "Estrarre il contenuto del file .zip e isolare i due file denominati 'contacts.csv' e 'transaction_history.csv'",
              6: () =>
                "Infine, importa questi due file qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    lros: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Living Room of Satoshi (LROS)",
              1: () =>
                'Nella barra di navigazione in alto, clicca su "Il mio account" per andare alla pagina delle bollette (https://www.livingroomofsatoshi.com/bills)',
              2: () =>
                "Scarica il file CSV che contiene la cronologia completa dei tuoi pagamenti",
              3: () =>
                "Carica questo CSV qui nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
    },
    ltc: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova la chiave pubblica del portafoglio Ltub per il tuo portafoglio Litecoin. Per trovarla, (ad esempio) vai su Ledger Live e inserisci il tuo account Litecoin. Fai clic sull'icona della chiave inglese. Espandi 'Advanced Logs' sotto 'Edit Account'. Copia l'indirizzo che inizia con 'Ltub'.",
              1: () =>
                "Una volta trovata, copia questa chiave pubblica e accedi a un convertitore Xpub come https://jlopp.github.io/xpub-converter/ per convertire il tuo indirizzo 'Ltub' in un indirizzo 'Xpub'.",
              2: () =>
                "Copia e incolla l'indirizzo 'Xpub' convertito nel campo di importazione del Crypto Tax Calculator (a sinistra) e clicca su 'Aggiungi portafoglio' per sincronizzare le tue transazioni.",
            },
          },
        },
      },
    },
    luno: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account Luno (https://www.luno.com/it/exchange)",
              1: () =>
                "Nell'angolo in alto a destra clicca su Profilo e vai su Sicurezza - > Chiavi API",
              2: () =>
                "Crea la chiave API e assicurati che l'autorizzazione della chiave sia `Accesso di sola lettura`",
              3: () =>
                "Assicurati di autorizzare la chiave API seguendo il link che ti è stato inviato via SMS o e-mail prima di utilizzarla",
              4: () =>
                'Inserisci sia la "chiave API" che il "segreto API" qui nel calcolatore delle imposte sulle criptovalute',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account Luno (https://www.luno.com/it/exchange)",
              1: () =>
                "Scarica l'esportazione CSV delle tue transazioni andando su Portafogli - > Portafoglio BTC (ad esempio) - > Transazioni - > Scarica CSV",
              2: () =>
                "Ripeti questa operazione per ciascuno dei tuoi portafogli su Luno",
              3: () => "Ora puoi caricare i file CSV in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    lykke: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                'Accedi al tuo account Lykke e sulla barra di navigazione in alto sullo schermo clicca su "FONDI"',
              1: () =>
                'Una volta nella pagina Fondi, fare clic sul pulsante "Cronologia"',
              2: () =>
                'Assicurati di aver selezionato "Tutti" in modo da visualizzare Depositi, Prelievi e Negoziazioni',
              3: () =>
                'Fare clic sul pulsante "ESPORTA IN CSV" per scaricare il CSV',
              4: () => "Carica qui il CSV su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "manta-pacific": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
          1: {
            items: {
              0: () =>
                "Potrebbero mancare alcune transazioni interne per alcuni wallet.",
            },
            title: () => "Transazioni interne mancanti",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Manta Pacific nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    mantle: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Mantle (che inizia con 0x) nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    mercatox: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Mercatox",
              1: () =>
                "Vai a Portafoglio > Cronologia transazioni https://mercatox.com/wallet/transactions",
              2: () =>
                'Fare clic sul pulsante "Scarica CSV" prima di caricare su Crypto Tax Calculator',
            },
          },
        },
      },
    },
    metamask: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                'Fare clic su "Collega portafoglio" e seguire le istruzioni.',
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio MetaMask",
              1: () =>
                "Per ogni account associato a MetaMask, seleziona l'account e passa il mouse sul nome dell'account",
              2: () =>
                "Copia l'indirizzo del portafoglio pubblico che inizia con '0x...'",
              3: () =>
                'Incolla l\'indirizzo del portafoglio pubblico copiato in CTC e fai clic su "Importa portafoglio"',
            },
          },
        },
      },
    },
    metis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Metis nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    mode: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Copia l'indirizzo del tuo portafoglio pubblico Mode che inizia con '0x...'",
              1: () =>
                "Incolla l'indirizzo del tuo portafoglio pubblico in CTC",
              2: () =>
                'Inserisci un nickname e clicca su "Importa portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio in modalità aggiuntiva"',
            },
          },
        },
      },
    },
    moonbeam: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
          1: {
            items: {
              0: () =>
                "Al momento non siamo in grado di recuperare le tue ricompense moonbeam. Stiamo lavorando attivamente a una soluzione a questo problema. Nel frattempo puoi aggiungere le tue ricompense tramite il nostro processo di importazione CSV manuale",
            },
            title: () => "Ricompense mancanti",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Moonbeam nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    moonriver: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Moonriver nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
      },
    },
    mudrex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Apri l'app mobile Mudrex e accedi al tuo account.",
              1: () =>
                "Tocca l'icona del tuo profilo nell'angolo in alto a sinistra (mostra le tue iniziali o un'emoji).",
              2: () =>
                'Scorri verso il basso nella sezione del profilo e seleziona "Estratto conto delle transazioni" in Rapporti e rendiconti.',
              3: () => "Scegli l'anno finanziario",
              4: () =>
                "Tocca \"Richiedi estratto conto\" e riceverai un'e-mail con l'estratto conto",
              5: () => "Carica il file su Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    multichain: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Apri il tuo portafoglio " + d.exchangeDisplayName + " .",
              1: () =>
                "Seleziona la criptovaluta che desideri importare. Ad esempio, Bitcoin o Ethereum.",
              2: () =>
                "Copia l'indirizzo del portafoglio pubblico corrispondente per quell'asset. Per trovarlo, potresti dover cliccare su 'Ricevi'.",
              3: () =>
                "In CTC, seleziona la rete blockchain corrispondente dal menu a discesa.",
              4: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico in CTC e clicca su "Importa portafoglio".',
            },
          },
        },
      },
    },
    mxc: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "L'API MEXC al momento consente l'accesso solo alla cronologia delle transazioni degli ultimi 30 giorni. Per importare l'intera cronologia delle transazioni, utilizzare il metodo di importazione MEXC 'upload file'",
            },
            title: () => "Limitazioni dell'API MEXC.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Le chiavi API generate da MEXC sono valide solo per 90 giorni. Dopo questo periodo sarà necessario creare e aggiungere una nuova chiave.",
              1: () =>
                "L'API MEXC NON consente l'accesso a depositi/prelievi/airdrop ecc., questi dovranno essere aggiunti manualmente.",
              2: () =>
                "Al momento NON supportiamo il trading di derivati o di margine tramite MEXC.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account MEXC",
              1: () =>
                "In alto a destra dello schermo, passa il mouse sull'immagine dell'account e seleziona \"Gestione API\" dall'elenco a discesa (https://www.mexc.com/user/openapi)",
              2: () =>
                'Sotto la voce "Spot", seleziona "Account: Visualizza dettagli account" e "Trade: Visualizza dettagli ordine"',
              3: () =>
                'Nelle Note (obbligatorio), fornisci un soprannome per la chiave API, ad esempio "Calcolatrice delle imposte sulle criptovalute"',
              4: () =>
                'Seleziona "Ho letto e accetto i promemoria sui rischi per gli utenti API" e fai clic sul pulsante "Crea"',
              5: () =>
                'Copia la "Chiave di accesso" e la "Chiave segreta" e inseriscile entrambe nel Crypto Tax Calculator',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account MEXC. Ci sono quattro file diversi di cui abbiamo bisogno in base alla cronologia delle tue negoziazioni",
              1: () =>
                'Per i dati di trading sui futures: sulla barra dei menu in alto clicca su "Ordini" e seleziona "Ordini futures"',
              2: () =>
                'Seleziona "Cronologia ordini e scambi" e clicca sul pulsante "Esporta cronologia ordini" (https://futures.mexc.com/orders/contract?type=historic)',
              3: () =>
                'Nella finestra popup, seleziona l\'intervallo di tempo che desideri scaricare, seleziona "EXCEL" per il formato di esportazione e premi il pulsante "Esporta" per esportare i record delle transazioni',
              4: () =>
                'Per i dati di trading spot: sulla barra dei menu in alto clicca su "Ordini" e seleziona "Ordini spot"',
              5: () =>
                'Seleziona "Cronologia delle negoziazioni" e clicca sul pulsante "Esporta" (https://www.mexc.com/orders/spot?tab=deal)',
              6: () =>
                'Nella finestra popup, seleziona l\'intervallo di tempo che desideri scaricare e premi il pulsante "Genera" per esportare i record delle transazioni',
              7: () =>
                'Per i dati sulla cronologia dei depositi: sulla barra dei menu in alto clicca su "Portafogli" e seleziona "Cronologia dei finanziamenti"',
              8: () =>
                'Selezionare "Deposito" e fare clic sul pulsante "Esporta" (https://www.mexc.com/assets/record)',
              9: () =>
                'Nella finestra popup, seleziona l\'intervallo di tempo che desideri scaricare, deseleziona "Crittografa il file" e premi il pulsante "Genera" per esportare i tuoi record di deposito',
              10: () =>
                'Per i dati sulla cronologia dei prelievi: nella barra dei menu in alto clicca su "Portafogli" e seleziona "Cronologia dei finanziamenti"',
              11: () =>
                'Seleziona "Prelievo" e clicca sul pulsante "Esporta" (https://www.mexc.com/assets/record)',
              12: () =>
                'Nella finestra pop-up, seleziona l\'intervallo di tempo che desideri scaricare, deseleziona "Crittografa il file" e premi il pulsante "Genera" per esportare i tuoi registri di prelievo.',
              13: () =>
                "Carica tutti i file scaricati qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    myEtherWallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Per ogni account associato a MEW:",
              1: () =>
                "Trova l'indirizzo pubblico della blockchain Ethereum dell'account e copia questo indirizzo negli appunti. Suggerimento: se stai utilizzando l'app mobile MEW, puoi trovare questi dettagli in \"Gestisci account\" sull'app mobile MEW.",
              2: () =>
                'Incolla l\'indirizzo del portafoglio Ethereum copiato in Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    myTonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puoi inserire il tuo indirizzo bounceable, non bounceable, TON DNS o in formato raw e noi lo tradurremo per te nel formato raw.",
            },
            title: () => "Tipi di indirizzo",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova e copia l'indirizzo del tuo portafoglio TON dall'estensione MyTonWallet",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio nel calcolatore delle imposte sulle criptovalute",
              2: () =>
                'Inserisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    ndax: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a NDAX",
              1: () =>
                "Sul lato sinistro clicca su report ed esporta il tuo CSV 'Ledger'",
              2: () => "Puoi quindi caricarlo in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    near: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Il supporto per le transazioni NFT arriverà presto",
            },
            title: () => "Non tutte le operazioni sono supportate",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Near nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    neo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Il nostro fornitore di dati per questa blockchain è attualmente inattivo. Stiamo esaminando un nuovo fornitore; tuttavia, per ora ti consigliamo di importare i tuoi dati utilizzando il nostro modello CSV personalizzato. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () =>
              "La nostra integrazione Neo non è al momento disponibile.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Nota bene: a causa del GAS non reclamato, potrebbe esserci una discrepanza tra i saldi GAS calcolati e quelli segnalati.",
            },
            title: () => "Discrepanze di equilibrio",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () => "Copia l'indirizzo del tuo portafoglio NEO pubblico",
              2: () =>
                "In CTC, seleziona la blockchain pertinente dal menu a discesa",
              3: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    netcoins: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "La nostra integrazione Netcoins è attualmente in versione Beta, se riscontri problemi contatta il nostro supporto con il tuo file CSV da Netcoins",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Netcoins",
              1: () =>
                "Nella pagina dei tuoi saldi, scorri verso il basso fino alla sezione Transazioni",
              2: () =>
                'Fare clic sul pulsante "esporta" nella parte superiore della tabella delle transazioni per scaricare un\'esportazione CSV delle transazioni',
              3: () =>
                "Carica questo CSV qui nel calcolatore delle imposte sulle criptovalute",
            },
          },
        },
      },
    },
    neutron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Neutron nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    newton: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Newton tramite un browser web",
              1: () =>
                'Vai al pulsante "Cronologia completa" accanto all\'intestazione "Attività recenti". Questo ti porterà alla pagina "Cronologia account"',
              2: () => 'Fare clic sul pulsante "Esporta cronologia account"',
              3: () =>
                "Esporta il 'Report per YYYY' dove 'YYYY' è l'anno che desideri esportare (ad esempio 2020). Questo scaricherà un file CSV con un nome nel formato 'account-history-YYYY-Firstname-Lastname.csv'",
              4: () =>
                "Carica il file che hai appena scaricato qui in Crypto Tax Calculator",
              5: () =>
                "Assicurati di ripetere questo processo per tutti gli anni per i quali sono disponibili report da scaricare",
            },
          },
        },
      },
    },
    nexo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Si prega di notare che le transazioni "sell" corrispondenti a ogni "buy" dovranno essere aggiunte manualmente, poiché il lato "sell" dell\'exchange non è disponibile nei dati. È possibile farlo nella pagina "Review Transactions".',
              1: () =>
                "Assicurati che la data e l'ora siano nel formato 'AAAA-MM-GG HH:mm:ss' o 'MM/GG/AAAA HH:mm'",
              2: () =>
                "Nota: alcune righe CSV potrebbero non essere importate poiché non rappresentano la cronologia delle negoziazioni, ma piuttosto i record interni di Nexo. Ad esempio, una riga di interessi con un importo USD negativo rappresenta un record di interessi da pagare e non un'operazione effettiva.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a Nexo e clicca sulla scheda transazioni (https://platform.nexo.io/transactions)",
              1: () =>
                "Seleziona l'intervallo di date che copre tutta la cronologia delle tue transazioni",
              2: () =>
                'Mantieni i tipi di transazione e le risorse predefinite come "Tutti"',
              3: () => "Esportare il CSV e importarlo in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    nftbank: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "I CSV nella cartella 'only_symbol' non includono i tuoi ID NFT, solo il simbolo",
              1: () =>
                "Non caricare i CSV dalla cartella 'only_symbol' se hai già caricato il CSV pertinente dalla cartella radice o viceversa",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account NFTBank tramite un browser web",
              1: () =>
                'Vai al tuo portafoglio e clicca su "Dichiarazione fiscale"',
              2: () =>
                'Seleziona l\'opzione "Calcolatrice delle imposte sulle criptovalute" e i portafogli che desideri esportare',
              3: () => 'Conferma il tuo indirizzo email e clicca su "Invia"',
              4: () =>
                "Scarica il file zip contenente i file CSV inviati alla tua email",
              5: () =>
                "Carica qui i file CSV pertinenti contenuti nel file zip (non il file zip stesso)",
            },
          },
        },
      },
    },
    nicehash: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "L'ID dell'organizzazione può essere trovato nella pagina Chiavi API e non verrà fornito con la chiave e il segreto",
              1: () =>
                'Le commissioni di mining potrebbero mancare per le vecchie transazioni, il che può causare imprecisioni nei saldi calcolati. Se riscontri questo problema, puoi invece importare tramite il metodo "Carica file", che in genere include queste commissioni',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account NiceHash",
              1: () =>
                "Nell'angolo in alto a destra della barra di navigazione, fai clic sull'icona del tuo profilo, quindi fai clic sul pulsante arancione \"Le mie impostazioni\" per accedere alla pagina delle impostazioni (https://www.nicehash.com/my/settings/)",
              2: () =>
                'Nella barra di navigazione a sinistra, fare clic su "Chiavi API" (https://www.nicehash.com/my/settings/keys)',
              3: () =>
                'Fare clic su "Crea nuova chiave API" per aprire la finestra di dialogo di creazione della chiave API',
              4: () =>
                'In "Autorizzazioni portafoglio", consenti l\'autorizzazione a "Visualizzare saldi, attività portafoglio e indirizzi di deposito"',
              5: () =>
                'In "Autorizzazioni del mercato", consenti l\'autorizzazione a "Visualizzare gli ordini di hashpower"',
              6: () =>
                'In "Autorizzazioni di scambio", consenti l\'autorizzazione a "Visualizzare gli ordini di scambio"',
              7: () =>
                'In "Autorizzazioni di mining", consenti l\'autorizzazione a "Visualizzare dati e statistiche di mining"',
              8: () =>
                "Ti verrà richiesto di fornire un'autenticazione aggiuntiva",
              9: () =>
                'Copia la chiave API, il segreto e l\'ID organizzazione del tuo account e incollali qui in Crypto Tax Calculator rispettivamente come "chiave API", "segreto" e "UID"',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovranno essere generati report separati per ogni criptovaluta per cui hai attività storica. Questo può essere fatto seguendo i passaggi sopra per ciascuna delle diverse valute.",
              1: () =>
                "Il calcolatore delle imposte sulle criptovalute preleva direttamente le commissioni di mining dall'importo ottenuto (dal mining) al momento della ricezione del pagamento",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account NiceHash",
              1: () =>
                "Nell'angolo in alto a destra della barra di navigazione, fai clic sull'icona del tuo profilo, quindi fai clic sul pulsante arancione \"Le mie impostazioni\" per accedere alla pagina delle impostazioni (https://www.nicehash.com/my/settings/)",
              2: () =>
                'Nella barra di navigazione a sinistra, fare clic su "Report" (https://www.nicehash.com/my/settings/reports)',
              3: () =>
                'Fare clic su "Nuovo report" per aprire la finestra di dialogo di creazione del report',
              4: () =>
                "Ora dovrai selezionare la criptovaluta per il report, così come la valuta 'FIAT'. Assicurati che la valuta 'FIAT' sia nella tua valuta locale e la stessa che hai in Crypto Tax Calculator. Assicurati che 'Time-aggregation' sia 'NONE'. Seleziona l'intervallo di date da/a che include tutta la tua attività. Assicurati che il fuso orario sia GMT'",
              5: () =>
                'Una volta compilato il modulo, clicca su "Crea" per generare il report. L\'elaborazione del report richiederà un po\' di tempo. Una volta elaborato, lo stato passerà a "pronto"',
              6: () =>
                'Scarica il report cliccando sui tre puntini sotto "Azione" e seleziona "Scarica report" dal menu a discesa. In questo modo scaricherai il report come file CSV',
              7: () => "Ora puoi caricare questo CSV in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    nolus: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Nolus nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    oasisdex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () => "Seleziona lo scambio dal menu a discesa",
              3: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              4: () => "Mantieni le altre impostazioni predefinite",
              5: () => 'Fai clic su "Recupera cronologia"',
              6: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              7: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    oceanex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                'Passa il mouse su "fondi" nella parte superiore della pagina',
              1: () =>
                "Dal menu a discesa seleziona cronologia delle negoziazioni",
              2: () => "Esportare i risultati della ricerca",
              3: () =>
                "Carica tutti i file che hai scaricato su Crypto Tax Calculator. Assicurati di avere tutte le tue transazioni",
            },
          },
        },
      },
    },
    okex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Al momento, le negoziazioni a margine e futures non sono supportate. Stiamo lavorando per aggiungere il supporto per queste negoziazioni.",
              1: () =>
                "OKX consente solo l'esportazione dei dati della cronologia delle transazioni degli ultimi 3 mesi dalla sua API. Se hai transazioni più vecchie, dovrai anche caricare un file CSV con le transazioni più vecchie.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a OKX",
              1: () => "Vai al menu API (https://www.okx.com/account/my-api)",
              2: () => 'Fai clic su "Crea chiave API V5"',
              3: () =>
                'Inserisci un nome e una passphrase, lascia vuoto il campo indirizzo IP e seleziona solo la casella "Leggi" sotto le autorizzazioni',
              4: () =>
                "Verifica il tuo account con un codice email e un codice di autenticazione 2FA, quindi fai clic su conferma",
              5: () =>
                "Inserisci la chiave API, il segreto e la passphrase che hai creato qui nel Crypto Tax Calculator",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/ZOf5ZjUYsEQ",
            title: () => "Come importare la tua API OKX",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Al momento supportiamo solo i file CSV del tipo di account "Unificato".',
              1: () =>
                "Al momento, le negoziazioni di opzioni non sono supportate. Stiamo lavorando per aggiungere il supporto per queste negoziazioni.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a OKX",
              1: () =>
                "Vai al centro report (https://www.okx.com/balance/report-center/)",
              2: () =>
                'Per le negoziazioni, in "Cronologia delle negoziazioni", fai clic su Scarica, seleziona "Tutti" come strumento e imposta l\'intervallo di date in modo appropriato per coprire tutte le tue transazioni',
              3: () =>
                'Carica qui il file CSV "Cronologia delle negoziazioni" nel calcolatore delle imposte sulle criptovalute',
              4: () =>
                'Per depositi e prelievi, in "Fattura di finanziamento", clicca su Scarica, seleziona "Finanziamento" come tipo di conto e imposta l\'intervallo di date in modo appropriato per coprire tutte le tue transazioni',
              5: () =>
                'Carica qui il file CSV "Cronologia account di finanziamento" nel calcolatore delle imposte sulle criptovalute',
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/HpNmKgPLu3k",
            title: () => "Come importare i file CSV OKX",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () => "Click 'Connect to OKX' to open a new tab.",
              1: () => "Log in to your OKX account.",
              2: () => "Click 'Approve' button",
            },
          },
        },
      },
    },
    openMask: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puoi inserire il tuo indirizzo bounceable, non bounceable, TON DNS o in formato raw e noi lo tradurremo per te nel formato raw.",
            },
            title: () => "Tipi di indirizzo",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova e copia l'indirizzo del tuo portafoglio TON all'interno dell'estensione OpenMask",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio nel calcolatore delle imposte sulle criptovalute",
              2: () =>
                'Inserisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    opensea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "In OpenSea, navigate to your profile",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the copied public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    optimism: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio che hai utilizzato per fare trading tramite Optimism (iniziando con 0x)",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    osmosis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Osmosis nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    pancakeSwap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente categorizziamo automaticamente le transazioni di cambio e liquidità su PancakeSwap",
              1: () =>
                "Se hai eseguito operazioni di Farming, Pools o IFO, le tue transazioni verranno importate, ma potrebbe essere necessario rivederle e ricategorizzarle in modo appropriato",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio Binance Smart Chain che hai utilizzato per fare trading tramite PancakeSwap",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    paradex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    phantom: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio blockchain Solana che hai utilizzato per fare trading tramite Phantom",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    phemex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "L'API Phemex supporta solo 3 mesi di dati storici, quindi utilizziamo il CSV",
              1: () =>
                "Al momento supportiamo solo spot trading, deposito/prelievo e CSV PNL chiusi",
            },
          },
          1: {
            items: {
              0: () =>
                'Accedi a Phemex e passa il mouse sul menu "Risorse" nell\'angolo in alto a destra',
              1: () =>
                'Nel menu a discesa, fare clic su "Spot History" e quindi su "Order History" (https://phemex.com/assets/spot-order-history)',
              2: () =>
                'Puoi quindi fare clic su "Esporta cronologia ordini spot" sulla destra e selezionare il periodo di tempo in cui hai effettuato operazioni',
              3: () =>
                "Puoi selezionare solo 3 mesi alla volta, quindi se hai utilizzato la piattaforma per più tempo dovrai scaricarla per ogni mese",
              4: () =>
                "Assicurati di aver scaricato l'intera cronologia degli ordini e quindi carica i file su Crypto Tax Calculator",
            },
            title: () => "Operazioni spot:",
          },
          2: {
            items: {
              0: () =>
                'Accedi a Phemex e passa il mouse sul menu "Risorse" nell\'angolo in alto a destra',
              1: () => 'Nel menu a discesa, fare clic sul pulsante "Deposito"',
              2: () =>
                'Scorri fino in fondo alla pagina e clicca su "Visualizza tutto" nella tabella Cronologia recente che ti porterà alla pagina del deposito (https://phemex.com/assets/details?tab=Deposit)',
              3: () => 'Fare clic su "Esporta cronologia depositi"',
              4: () =>
                'Ora puoi cliccare sulla scheda "Preleva" (https://phemex.com/assets/details?tab=Withdrawal) e scaricare anche questi dati',
              5: () =>
                "Di nuovo, hai solo l'opzione di scaricare 3 mesi di dati alla volta. Assicurati di aver scaricato tutta la cronologia di depositi/prelievi e poi carica tutti i file su Crypto Tax Calculator",
            },
            title: () => "Depositi/Prelievi:",
          },
          3: {
            items: {
              0: () =>
                'Accedi a Phemex e passa il mouse sul menu "Risorse" nell\'angolo in alto a destra',
              1: () =>
                'Nel menu a discesa, fare clic su "Cronologia contratti" e quindi su "Dettagli PNL" (https://phemex.com/assets/contract-pnl-details)',
              2: () =>
                'Scorri fino in fondo alla pagina e clicca su "Visualizza tutto" nella tabella Cronologia recente che ti porterà alla pagina di deposito (https://phemex.com/assets/details?tab=Deposit)',
              3: () => 'Fare clic su "PNL chiuso" tra le opzioni disponibili',
              4: () =>
                'Puoi quindi fare clic su "Esporta cronologia PNL chiusa" sulla destra e selezionare il periodo di tempo in cui hai effettuato operazioni',
              5: () =>
                "Di nuovo, hai solo l'opzione di scaricare 3 mesi di dati alla volta. Assicurati di aver scaricato tutta la cronologia PNL chiusa e poi carica tutti i file su Crypto Tax Calculator",
            },
            title: () => "PNL chiuso:",
          },
        },
      },
    },
    pionex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                'Vai alla scheda "Cronologia transazioni" sul lato sinistro',
              1: () =>
                "Seleziona l'intervallo di tempo della cronologia delle transazioni che desideri scaricare e fai clic su Invia",
              2: () =>
                "Una volta che i file saranno pronti, riceverai un'e-mail da Pionex non appena il file sarà pronto per essere scaricato",
              3: () =>
                "Torna al tuo account Pionex > Cronologia transazioni per scaricare il file",
              4: () =>
                "Decomprimi i file scaricati e importa 'trading.csv' e 'depositwithdraw.csv' in CTC",
              5: () =>
                "Se hai altri file di transazione, importali utilizzando il nostro CSV personalizzato",
              6: () => "Nota: c'è un limite di 3 esportazioni al mese",
            },
          },
        },
      },
    },
    polkadot: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
          1: {
            items: {
              0: () =>
                "Attualmente i trasferimenti cross-chain IBC non sono supportati",
            },
            title: () => "Transazioni mancanti",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Polkadot (iniziando con 1) nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    polkadotjs: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio Polkadot.js",
              1: () =>
                "Fare clic sull'icona delle impostazioni in alto a destra",
              2: () =>
                'Seleziona "Visualizza formato indirizzo per" e scegli la rete desiderata',
              3: () =>
                "Torna alla visualizzazione dei tuoi account e fai clic sull'icona Copia negli appunti accanto all'account desiderato",
              4: () =>
                "In Crypto Tax Calculator seleziona la rete corrispondente",
              5: () =>
                'Incolla l\'indirizzo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    polkagate: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio Polkagate",
              1: () =>
                "Fai clic sul menu a 3 punti accanto all'account che desideri importare",
              2: () =>
                'Selezionare la rete desiderata nel menu a discesa "catena"',
              3: () =>
                "Torna alla visualizzazione dei tuoi account e fai clic sull'icona Copia negli appunti accanto all'account desiderato",
              4: () =>
                "In Crypto Tax Calculator seleziona la rete corrispondente",
              5: () =>
                'Incolla l\'indirizzo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    poloniex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Si prega di notare che, al momento, l'API Poloniex sincronizzerà solo i trasferimenti effettuati a partire dal 22 gennaio 2020 e le negoziazioni effettuate a partire dal 30 luglio 2021 (https://docs.poloniex.com/#authenticated-endpoints-trades).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Accedi a Poloniex e clicca sulla scheda "Impostazioni" nella barra di navigazione',
              1: () =>
                'Vai alla pagina "Chiavi API" (https://poloniex.com/apiKeys)',
              2: () => 'Fare clic sul pulsante "Crea nuova chiave"',
              3: () =>
                "Se applicabile, inserisci il codice di autenticazione a due fattori e/o le istruzioni via e-mail",
              4: () =>
                "Una volta ricevuta un'e-mail di verifica da Poloniex, conferma la tua nuova chiave API",
              5: () =>
                'Per la tua nuova chiave API, deseleziona le caselle di controllo "Abilita trading" e "Abilita prelievi"',
              6: () =>
                "Imposta la restrizione di accesso IP su Senza restrizioni",
              7: () => "Copia sia la chiave API che il segreto API",
              8: () =>
                'Incolla la "chiave API" e il "segreto API" copiati qui in Crypto Tax Calculator e fai clic su "Aggiungi API"',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Importa tutte le tue "Attività" rilevanti (ad esempio Depositi, Prelievi, Negoziazioni, ecc.) per le quali sono disponibili dati, in modo che possiamo categorizzare correttamente le tue transazioni.',
              1: () =>
                'Al momento non supportiamo le importazioni automatiche per i tipi di attività "Prestito su margine" o "Prestito".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Accedi a Poloniex e clicca sulla scheda "Attività" nella barra di navigazione',
              1: () =>
                'Questo dovrebbe portarti alla pagina della cronologia dei "Portafogli" (https://poloniex.com/activity/wallet)',
              2: () =>
                "In alto a destra dello schermo, sotto la barra di navigazione, clicca sul pulsante 'Esporta'",
              3: () =>
                "In Export Activity, seleziona il tuo 'Activity Type'. Attualmente supportiamo quanto segue: 'Deposits', 'Withdrawals', 'Trades', 'Distributions'",
              4: () =>
                "Dopo aver selezionato il tipo di attività, seleziona l'intervallo di date che copre tutta la cronologia",
              5: () =>
                'Fai clic sul pulsante "Download". Dovresti ricevere un CSV denominato "[activityType]-[selectedDateRange].csv" in cui [activityType] è il tipo di attività selezionato e [selectedDateRange] è l\'intervallo di date selezionato',
              6: () =>
                "Importa i file CSV qui in Crypto Tax Calculator senza salvarli di nuovo o aprirli (ciò potrebbe modificare il formato su alcuni computer)",
            },
          },
        },
      },
    },
    polygon: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
              1: () => "I saldi potrebbero subire ritardi fino a un paio d'ore",
            },
          },
          1: {
            items: {
              0: () =>
                "Ora supportiamo i token ERC-1155, tuttavia non siamo in grado di importare i tuoi saldi correnti per questi token. Ciò potrebbe causare discrepanze tra il saldo importato dall'API di origine e il saldo calcolato dall'app.",
            },
            title: () => "Token ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Polygon (Matic) nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    "polygon-zkevm": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Polygon Hermez (zkEVM) nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    pooltool: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Poiché PoolTool non detiene nessuno dei tuoi asset, non ci saranno partecipazioni in criptovaluta direttamente associate a questa importazione. Invece, le ricompense di staking sono associate al tuo indirizzo di staking bech32 stesso",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Vai a (https://pooltool.io)",
              1: () =>
                'In alto a sinistra, clicca su "dati premi per le tasse"',
              2: () => "Incolla il tuo indirizzo di ricompensa per lo staking",
              3: () =>
                "in basso a sinistra seleziona: formato - generico; valuta - dollaro USA; periodo - l'anno per il quale vuoi esportare i tuoi dati di staking",
              4: () =>
                'Fai clic su "Scarica dati" per scaricare i tuoi dati come file CSV',
              5: () =>
                "Inserisci il tuo indirizzo di stake bech32, quindi carica questo file CSV qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    probit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "La sincronizzazione API di Probit fornisce solo l'accesso alla cronologia delle tue negoziazioni. Devi importare manualmente i tuoi depositi e prelievi Probit in Crypto Tax Calculator separatamente. Puoi farlo tramite il nostro modello CSV personalizzato avanzato: https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Probit",
              1: () =>
                'In alto a destra della barra di navigazione, passa il mouse su "La mia pagina" e clicca su "Gestione API" dal menu a discesa (https://www.probit.com/en-us/user-center/api-management/api-credential)',
              2: () =>
                'Assicurati che la scheda "Gestione API" sia evidenziata e inserisci "Calcolatrice delle imposte sulle criptovalute" come "Etichetta chiave API" e fai clic su "Crea nuova chiave"',
              3: () =>
                "Ti verrà richiesto di fornire un'autenticazione aggiuntiva",
              4: () =>
                "Copia/salva l'ID cliente e la chiave segreta del cliente in un luogo sicuro",
              5: () =>
                ' Una volta autenticato DEVI modificare le autorizzazioni della chiave API: fai clic sul pulsante "Modifica", quindi deseleziona la casella di controllo "Trading" e fai clic su "Modifica completata".',
              6: () =>
                'IMPORTANTE: assicurati che l\'unica casella di controllo selezionata sia "Leggi (predefinito)"',
              7: () =>
                'Inserisci "Client ID" come chiave API e "Client Secret Key" come segreto qui nel Crypto Tax Calculator',
            },
          },
        },
      },
    },
    protocol: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Apri il portafoglio che hai utilizzato per interagire con " +
                d.exchangeDisplayName,
              1: () => "Copia l'indirizzo del tuo portafoglio pubblico",
              2: () =>
                "In CTC, seleziona la blockchain pertinente dal menu a discesa",
              3: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico e clicca su "Importa portafoglio"',
              4: (d) =>
                "CTC importerà tutte le transazioni dal menu a discesa, comprese quelle associate a " +
                d.exchangeDisplayName,
            },
          },
        },
      },
    },
    pulsechain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
              1: () =>
                "L'integrazione è attualmente in versione Beta e quindi potrebbe periodicamente fallire a volte. Se ciò accade, riprova dopo un po'.",
            },
          },
          1: {
            items: {
              0: () => "Al momento non supportiamo le transazioni ERC-1155.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio PulseChain nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    quantfury: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "A questo punto, dovrai contattare l'assistenza Quantfury e richiedere il file .xlsx con la cronologia delle transazioni.",
              1: () =>
                "Il formato dovrebbe essere: 'Nome, Azione, Quantità, Prezzo, Valore, Data, Posizione totale'.",
              2: () =>
                "Una volta ricevuto questo formato, dovrai aprirlo ed esportarlo in formato CSV.",
              3: () =>
                "Dovrai anche rimuovere tutte le transazioni non crittografiche poiché non le gestiamo",
              4: () =>
                "Stiamo lavorando con Quantfury per migliorare il supporto per questo",
            },
          },
        },
      },
    },
    quickswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Attualmente classifichiamo automaticamente gli Swap e aggiungiamo/rimuoviamo Liquidità su QuickSwap",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio Polygon (Matic) che hai utilizzato per fare trading tramite QuickSwap",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    "rabby-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Fai clic sull'estensione Rabby nel tuo browser",
              1: () =>
                "Per ogni account associato a Rabby, seleziona l'account e clicca sull'icona Copia negli appunti",
              2: () =>
                'Incolla l\'indirizzo del portafoglio pubblico copiato (per la rete specificata) in Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    radarRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    "rainbow-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Fai clic sull'estensione Rainbow Wallet nel tuo browser",
              1: () =>
                "Per ogni account associato a Rainbow Wallet, seleziona l'account e fai clic sull'icona di copia",
              2: () =>
                'Incolla l\'indirizzo del portafoglio pubblico copiato in CTC e fai clic su "Importa portafoglio"',
            },
          },
        },
      },
    },
    relaypay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account RelayPay",
              1: () =>
                'Fai clic sul tuo nome utente nell\'angolo in alto a destra e seleziona "Il mio profilo" (https://relaypay.io/profile)',
              2: () => 'Vai alla scheda "Acquista criptovalute"',
              3: () => 'Fare clic su "Scarica tutto come CSV"',
              4: () =>
                'Ripetere il passaggio 3 per le schede "Bonifico bancario" e "Pagamento bollette"',
              5: () =>
                "Puoi quindi importare i file CSV qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    revolut: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a Revolut.",
              1: () => 'Seleziona "Cripto" nel menu in basso.',
              2: () => "Scegli una criptovaluta.",
              3: () => 'Tocca "Estratto conto".',
              4: () => "Scegli un periodo di tempo.",
              5: () => 'Selezionare "Genera".',
              6: () =>
                "Ripeti i passaggi da 3 a 6 per ogni criptovaluta, poiché non puoi generarle tutte in una volta. ",
              7: () => "Scarica l'estratto conto nella tua app Revolut.",
            },
          },
        },
      },
    },
    ripple: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
              1: () =>
                'Per XRP, al momento supportiamo solo le Transazioni "Pagamento" XRP. Se hai altri tipi di transazione (ad esempio valute diverse), contattaci tramite il nostro supporto chat.',
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () => "Copia l'indirizzo del tuo portafoglio XRP pubblico",
              2: () =>
                "In CTC, seleziona la blockchain pertinente dal menu a discesa",
              3: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    robinhood: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "I prelievi e i depositi non sono supportati tramite API, utilizzare l'opzione di importazione CSV",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Accedi al tuo account Robinhood. Fai clic su "Account" nell\'angolo in alto a destra, quindi seleziona "Impostazioni".',
              1: () =>
                'Vai alla sezione "Cripto" e individua l\'opzione "API Trading".',
              2: () =>
                "Segui https://help.cryptotaxcalculator.io/en/articles/10448126-import-robinhood-transactions-into-crypto-tax-calculator-using-api-keys per generare una coppia di chiavi base64.",
              3: () =>
                'Aggiungi la "Chiave pubblica" generata al tuo account Robinhood.',
              4: () =>
                "In API Permissions, abilita tutti i permessi di lettura. Salta l'impostazione di IP consentiti o data di scadenza per la chiave API.",
              5: () =>
                "Conferma la creazione della chiave API. Robinhood visualizzerà la chiave API.",
              6: () =>
                "Nel tuo account CTC, inserisci la chiave API in 'Chiave API' e la 'Chiave privata' del passaggio 3 in 'Segreto'.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Contatta il supporto Robinhood visitando https://robinhood.com/contact",
              1: () =>
                "Fai clic su Documenti e tasse → Cronologia transazioni → Contattaci",
              2: () =>
                "Scegli come vuoi essere contattato, via email o telefono",
              3: () =>
                "Richiedi una copia delle tue transazioni per i tuoi acquisti/vendite e invii/ricevimenti. Possono essere due file",
              4: () =>
                "Una volta ottenuti i file, convertili da PDF a CSV se necessario",
              5: () =>
                "Riformattare i file nel formato CSV avanzato di CTC (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
              6: () => "Carica qui i tuoi file CSV",
            },
          },
        },
      },
    },
    robinhood_wallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Questo è per il portafoglio non-custodial Robinhood Web3. Per importare il tuo account Robinhood Exchange, vai qui: https://app.cryptotaxcalculator.io/imports/new/robinhood",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Apri l'app del portafoglio web3 non custodiale Robinhood",
              1: () =>
                "Dalla schermata Home, seleziona Menu (in alto a sinistra) e scegli Ricevi",
              2: () => "Copia il tuo indirizzo pubblico",
              3: () => "Scegli la blockchain pertinente dal menu a discesa",
              4: () => "Incolla l'indirizzo pubblico e fai clic su Sincronizza",
            },
          },
        },
      },
    },
    ronin: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Visita https://nftbank.ai/ e accedi",
              1: () =>
                'Fare clic sulla scheda "Dichiarazione fiscale" nel menu a sinistra',
              2: () =>
                'Seleziona "Ottieni la cronologia delle tue transazioni per la dichiarazione dei redditi"',
              3: () =>
                'Seleziona "Calcolatrice delle imposte sulle criptovalute" e fai clic su "Continua"',
              4: () =>
                "Seleziona 'Valuta originale - Formato fornitore di servizi fiscali'",
              5: () =>
                'Se non hai ancora importato il tuo portafoglio Ronin, clicca su "Aggiungi portafoglio" e segui le istruzioni di NFTbank',
              6: () =>
                'Una volta importato, seleziona il/i tuo/i portafoglio/i Ronin, clicca su "Continua"',
              7: () =>
                "Inserisci la tua email dove riceverai un link per il download",
              8: () =>
                "Il file scaricato dovrebbe essere un file .zip, apri il file per visualizzarne il contenuto",
              9: () =>
                "All'interno della nuova cartella, trova il file ronin.csv e importalo in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    saga: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Saga nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    scroll: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Scroll nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    sei: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Enter your Sei wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    sentinel: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Sentinel nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    shakepay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Shakepay.",
              1: () => "Vai alla pagina principale “Portafogli”.",
              2: () =>
                'Fare clic sul pulsante "Cronologia download" accanto al titolo Cronologia transazioni nella pagina principale.',
              3: () => "Importa il file CSV nel Crypto Tax Calculator.",
              4: () =>
                "Per maggiori dettagli, consultare https://help.shakepay.com/en/articles/3336094-where-can-i-see-my-full-transaction-history-monthly-statements-on-shakepay.",
            },
          },
        },
      },
    },
    sharkRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    singularx: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () => "Seleziona lo scambio dal menu a discesa",
              3: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              4: () => "Mantieni le altre impostazioni predefinite",
              5: () => 'Fai clic su "Recupera cronologia"',
              6: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              7: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    softWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Se non riesci a trovare la tua moneta o la tua rete, significa che Crypto Tax Calculator non può ancora sincronizzare le transazioni per essa. Contatta la nostra live chat così possiamo aggiungere supporto!",
              1: () =>
                "In alternativa, puoi inserire queste transazioni manualmente tramite l'opzione avanzata di importazione CSV di CTC: https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import ",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Seleziona la rete",
              1: () =>
                "Copia la tua chiave pubblica xpub, ypub o zpub. (ad esempio la chiave xpub BTC)",
              2: () =>
                'Incolla l\'indirizzo pubblico e clicca su "Importa portafoglio".',
              3: () =>
                "Maggiori dettagli (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator)",
            },
            title: () =>
              "Bitcoin, BitcoinCash, Dogecoin, BSV e altre reti con portafogli xpub:",
          },
          1: {
            items: {
              0: () => "Seleziona la rete",
              1: () => "Copia l'indirizzo del tuo portafoglio pubblico.",
              2: () =>
                'Incolla il tuo indirizzo pubblico e clicca su "Importa portafoglio".',
            },
            title: () =>
              "Ethereum (e token ETH), Binance Smart Chain, Cardano, Ripple, Gnosis, Polygon e altre reti con un indirizzo wallet EVM:",
          },
          2: {
            items: {
              0: () => "Seleziona la rete",
              1: () =>
                "Trova la tua chiave pubblica Ltub (ad esempio, in Ledger Live in 'Registri avanzati').",
              2: () =>
                "Converti l'indirizzo Ltub in un indirizzo Xpub qui (https://jlopp.github.io/xpub-converter/).",
              3: () =>
                'Incolla l\'indirizzo Xpub e clicca su "Importa portafoglio".',
            },
            title: () => "Litecoin:",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/g8JcDe6zRZA",
            title: () => "Come importare il tuo portafoglio Ledger Bitcoin",
          },
        },
      },
    },
    solana: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
            },
          },
          1: {
            items: {
              0: () =>
                "Se si caricano transazioni tramite l'importazione manuale di CSV, utilizzare 'solana-deprecated' o 'sol-deprecated' come blockchain.",
            },
            title: () => "Utilizzo dell'importazione CSV manuale",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Solana nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    "solana-v2": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () => "Copia l'indirizzo del tuo portafoglio pubblico Solana",
              2: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico in CTC e fai clic su "Importa portafoglio"',
            },
          },
        },
      },
    },
    solflare: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio blockchain Solana che hai utilizzato per fare trading tramite SolFlare",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    sollet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio blockchain Solana che hai utilizzato per fare trading tramite Sollet",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    sonic: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
          1: {
            items: {
              0: () =>
                "I saldi live per i token su Sonic non sono attualmente disponibili. I dettagli delle transazioni e i calcoli delle imposte non sono interessati",
            },
            title: () => "Saldi in tempo reale",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Copia l'indirizzo del tuo portafoglio pubblico Sonic che inizia con '0x...'",
              1: () =>
                "Incolla l'indirizzo del tuo portafoglio pubblico in CTC",
              2: () =>
                'Inserisci un nickname e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    spiritswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio blockchain Fantom che hai utilizzato per fare trading tramite SpiritSwap.",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    spookyswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio blockchain Fantom che hai utilizzato per fare trading tramite SpookySwap.",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    stacks: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Stacks (che inizia con SP) nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    staketax: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Cerca l'indirizzo del tuo portafoglio su stake.tax e seleziona Crypto Tax Calculator nel prompt di download",
              1: () => "Carica il CSV qui nel Crypto Tax Calculator",
            },
          },
        },
      },
    },
    stargaze: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Osservazione delle stelle nello sviluppo iniziale",
              1: () =>
                "Solo etichette di trasferimenti/picchettamento (le altre appaiono come sconosciute)",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Stargaze nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    starknet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Apri il tuo portafoglio Starknet (Argent, Braavos, ecc.)",
              1: () =>
                "Copia l'indirizzo del tuo portafoglio pubblico o l'indirizzo del contratto che inizia con '0x...'",
              2: () =>
                'Incolla l\'indirizzo in Crypto Tax Calculator, aggiungi un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    stellar: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () => "Copia l'indirizzo pubblico del tuo portafoglio Stellar",
              2: () =>
                "In CTC, seleziona la blockchain pertinente dal menu a discesa",
              3: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    stex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi a STEX, clicca sulla tua email in alto a destra e seleziona profilo.",
              1: () =>
                'Sul lato sinistro clicca su "Segnala" (https://app.stex.com/en/profile/report)',
              2: () =>
                'Fare clic su "Cronologia utente" e fare clic sul pulsante Esporta',
              3: () => "Ora puoi caricare questo CSV in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    stormrake: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account Stormrake",
              1: () => "Scarica la cronologia delle tue transazioni",
              2: () => "Carica il file CSV in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "substrate-chains": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Potrebbero mancare alcune commissioni sulle transazioni ponte poiché in alcuni casi la blockchain non fornisce queste informazioni.",
              1: () =>
                "Inoltre, potremmo non avere alcune transazioni di trasferimento dalla blockchain. Se riscontri questo problema, contatta l'assistenza.",
            },
            title: () => "Transazioni mancanti",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) =>
                "Inserisci l'indirizzo del tuo portafoglio " +
                d.exchangeDisplayName +
                ' nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    subwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio SubWallet",
              1: () =>
                "Seleziona l'account che desideri importare nel menu a discesa dell'account in alto",
              2: () => "Fare clic sull'icona Copia indirizzo",
              3: () => "Copia l'indirizzo della rete desiderata",
              4: () =>
                "In Crypto Tax Calculator seleziona la rete corrispondente",
              5: () =>
                'Incolla l\'indirizzo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    sui: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio Sui",
              1: () =>
                "Per ogni account associato a Sui, seleziona l'account e passa il mouse sul nome dell'account",
              2: () =>
                "Copia l'indirizzo del portafoglio pubblico che inizia con '0x...'",
              3: () =>
                'Incolla l\'indirizzo del portafoglio pubblico copiato in CTC e fai clic su "Importa portafoglio"',
            },
          },
        },
      },
    },
    swissborg: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Per passaggi più dettagliati, SwissBorg ha messo insieme una guida qui: https://help.swissborg.com/hc/en-gb/articles/360016499698-Account-Statement",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account SwissBorg tramite l'app SwissBorg",
              1: () => 'Vai al tuo "profilo" e tocca "Estratto conto"',
              2: () =>
                "Seleziona l'intervallo di date che copre la cronologia completa delle tue negoziazioni",
              3: () =>
                "Seleziona la valuta di riferimento come valuta fiat locale",
              4: () => "Scegli il formato 'Excel' e salva il file",
              5: () => "Carica questo file qui nel Crypto Tax Calculator",
            },
          },
        },
      },
    },
    switcheo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Il formato della data dovrebbe essere AAAA-MM-GG HH:mm:ss",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Switcheo Exporter (https://benammann.github.io/switcheo-exporter/#/).",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico ed esporta il CSV.",
              2: () =>
                'Fare clic sul pulsante "Scarica CSV" nell\'angolo in alto a destra.',
              3: () => "Ora puoi caricare questo CSV in Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    swyftx: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Stiamo attualmente lavorando con Swyftx per supportare la loro nuova funzionalità di Staking. Depositi e prelievi di Staking non saranno importati in questo momento",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Visita https://trade.swyftx.com.au/profile/api/",
              1: () => 'Fare clic su "Crea nuova chiave".',
              2: () => 'Dopo aver letto l\'avviso, fare clic su "Ho capito".',
              3: () =>
                'Inserisci un\'etichetta (ad esempio, Calcolatrice delle imposte sulle criptovalute), imposta l\'"Ambito" su "Sola lettura" e inserisci la tua password Swyftx per confermare.',
              4: () =>
                'Copia la "Chiave API" e il "Token di accesso", quindi incollali in Crypto Tax Calculator come "Chiave API" e "Segreto".',
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q9QJesWUiMk",
            title: () => "Come importare la tua API Swyftx",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "L'esportazione CSV da Swyftx potrebbe presentare incongruenze con i timestamp dell'ora legale. Per una maggiore accuratezza, utilizzare l'importazione API se possibile.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Visita https://trade.swyftx.com/profile/transaction-reports",
              1: () => "Seleziona il periodo di transazione",
              2: () => 'Fare clic su "Download.csv".',
              3: () => "Carica il file CSV su Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q_WXPgSRQoQ",
            title: () => "Come importare i file CSV di Swyftx",
          },
        },
      },
    },
    taiko: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio Taiko aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Copia l'indirizzo del tuo portafoglio pubblico Taiko che inizia con '0x...'",
              1: () =>
                "Incolla l'indirizzo del tuo portafoglio pubblico in CTC",
              2: () =>
                'Inserisci un nickname e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    talisman: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Apri il tuo portafoglio Talisman",
              1: () => "Fare clic sull'account che si desidera importare",
              2: () => "Fare clic sull'icona Copia indirizzo",
              3: () => "Seleziona la rete desiderata",
              4: () =>
                "In Crypto Tax Calculator seleziona la rete corrispondente",
              5: () =>
                'Incolla l\'indirizzo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    terra: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Se hai importato le tue transazioni Terra prima del 18 maggio 2022, potresti riscontrare errori di prezzo nell'app a causa della ridenominazione di LUNA in LUNC. Ti consigliamo di eliminare e reimportare il tuo portafoglio Terra Classic per correggere questi problemi.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Terra nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    terra2: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Terra 2.0 è in fase di sviluppo iniziale",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Terra 2.0 nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    theta: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
              1: () =>
                'Al momento non supportiamo i token TNT721 e dovrai aggiungerli manualmente nella pagina "Controlla transazioni".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Theta nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    thetaboard: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Prima di caricare il file assicurati di inserire l'indirizzo del tuo portafoglio, così sappiamo dove associare queste transazioni.",
              1: () =>
                'Al momento non supportiamo i token TNT721 e dovrai aggiungerli manualmente nella pagina "Controlla transazioni".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai su Thetaboard (https://thetaboard.io/wallet/explorer).",
              1: () => "Inserisci l'indirizzo del tuo portafoglio pubblico.",
              2: () =>
                'Fare clic su "Esporta cronologia transazioni" (in Transazioni).',
              3: () => "Ora puoi caricare questo CSV in Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    thetawallet: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Theta nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Prima di caricare il file assicurati di inserire l'indirizzo del tuo portafoglio in modo che sappiamo dove associare queste transazioni",
              1: () =>
                'Se desideri informazioni più precise sulle commissioni, dovresti usare l\'importazione "Theta" che legge dalla blockchain',
              2: () =>
                'Al momento non supportiamo i token TNT721 e dovrai aggiungerli manualmente nella pagina "Controlla transazioni".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Vai su ThetaScan (http://www.thetascan.io/).",
              1: () => "Inserisci l'indirizzo del tuo portafoglio pubblico.",
              2: () => 'Fai clic su "Esporta transazioni" (sotto il tuo saldo)',
              3: () => "Ora puoi caricare questo CSV in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    thorchain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Al momento supportiamo solo i tipi di transazione "Invia" e "Scambia".',
            },
            title: () => "Tipi supportati",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio THORChain nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio THORChain"',
            },
          },
        },
      },
    },
    toast: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Per Ripple, al momento supportiamo solo le Transazioni "Pagamento" XRP. Se hai altri tipi di transazione (ad esempio valute diverse), contattaci tramite la nostra chat di supporto.',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Per ogni account associato al tuo portafoglio Toast:",
              1: () => "Trova l'indirizzo blockchain pubblico dell'account.",
              2: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    tokenJar: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    tokenStore: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Vai a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                'Sul lato sinistro del menu espandi "Cronologia scambi" e fai clic su TokenStore',
              3: () => 'In "Tipi di operazione" clicca su "Operazioni + Fondi"',
              4: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              5: () => "Mantieni le altre impostazioni predefinite",
              6: () => 'Fai clic su "Recupera cronologia"',
              7: () =>
                'Una volta completato, puoi esportare il csv predefinito in "Esporta scambi" e "Esporta fondi"',
              8: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questi file CSV",
            },
          },
        },
      },
    },
    tokenion: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    ton: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Puoi inserire il tuo indirizzo bounceable, non bounceable, TON DNS o in formato raw e noi lo tradurremo per te nel formato raw.",
            },
            title: () => "Tipi di indirizzo",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio TON o il DNS TON nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    tonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puoi inserire il tuo indirizzo bounceable, non bounceable, TON DNS o in formato raw e noi lo tradurremo per te nel formato raw.",
            },
            title: () => "Tipi di indirizzo",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Trova l\'indirizzo del tuo portafoglio TON all\'interno dell\'app Wallet in Telegram. Per visualizzare il tuo indirizzo, potresti dover cliccare sul pulsante "Aggiungi criptovaluta", seguito dalle opzioni "Portafoglio esterno", quindi "Toncoin" e copiare l\'indirizzo da lì',
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio nel calcolatore delle imposte sulle criptovalute",
              2: () =>
                'Inserisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
              3: () =>
                "Ripeti i passaggi precedenti per tutti i portafogli TON Space che possiedi, trovando l'indirizzo cliccando su TON Space all'interno del tuo portafoglio e cliccando sulla stenografia dell'indirizzo per copiarlo negli appunti",
            },
          },
        },
      },
    },
    toncoin: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puoi inserire il tuo indirizzo bounceable, non bounceable, TON DNS o in formato raw e noi lo tradurremo per te nel formato raw.",
            },
            title: () => "Tipi di indirizzo",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio TON o il DNS TON nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    tonhub: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puoi inserire il tuo indirizzo bounceable, non bounceable, TON DNS o in formato raw e noi lo tradurremo per te nel formato raw.",
            },
            title: () => "Tipi di indirizzo",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova e copia l'indirizzo del tuo portafoglio TON dall'app Tonhub. Per visualizzare il tuo indirizzo, potresti dover cliccare sul pulsante \"ricevi\" e copiare l'indirizzo da lì",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio nel calcolatore delle imposte sulle criptovalute",
              2: () =>
                'Inserisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    tonkeeper: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puoi inserire il tuo indirizzo bounceable, non bounceable, TON DNS o in formato raw e noi lo tradurremo per te nel formato raw.",
            },
            title: () => "Tipi di indirizzo",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del tuo portafoglio TON all'interno dell'app Tonkeeper, sia su Telegram, sia nell'app mobile o desktop. Per visualizzare il tuo indirizzo, potresti dover cliccare sul pulsante \"ricevi\" e copiare l'indirizzo da lì",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio nel calcolatore delle imposte sulle criptovalute",
              2: () =>
                'Inserisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    tonpay: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puoi inserire il tuo indirizzo bounceable, non bounceable, TON DNS o in formato raw e noi lo tradurremo per te nel formato raw.",
            },
            title: () => "Tipi di indirizzo",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trova e copia l'indirizzo del tuo portafoglio TON dall'app Telegram Tonpay. Puoi visualizzare l'indirizzo del tuo portafoglio digitando /start e selezionando l'opzione \"Finanza\", quindi \"Portafoglio\"",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio nel calcolatore delle imposte sulle criptovalute",
              2: () =>
                'Inserisci un nickname facoltativo e clicca su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    tradeSatoshi: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi a TradeSatoshi",
              1: () =>
                "Vai alla cronologia degli ordini https://tradesatoshi.com/Orders",
              2: () =>
                'Fare clic sul pulsante "Esporta" prima di caricare il CSV su Crypto Tax Calculator',
            },
          },
        },
      },
    },
    "trader-joe": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio Avalanche C-Chain che hai utilizzato per fare trading tramite Trader Joe.",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    trezor: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai aggiungere il simbolo del ticker per ogni valuta che desideri caricare",
              1: () =>
                "Assicurati che la data e l'ora siano nel formato 'GG/MM/AAAA HH:mm:ss', 'AAAA-MM-GG HH:mm:ss' o 'M/G/AAAA h:mm:ss A'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Puoi seguire i passaggi qui per esportare i tuoi dati CSV (https://wiki.trezor.io/User_manual:Transaction_details)",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Collega il tuo Trezor al tuo dispositivo",
              1: () => "Visita wallet.trezor.io",
            },
          },
          1: {
            items: {
              0: () => "Seleziona l'account pertinente e vai alla scheda Base",
              1: () => "Fare clic su Mostra XPUB",
              2: () => "Copia l'indirizzo xPub",
              3: () => "Incolla in CTC",
            },
            title: () =>
              "Per i portafogli Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin e Zcash",
          },
          2: {
            items: {
              0: () =>
                "Seleziona Seleziona la moneta pertinente e visualizza gli indirizzi del portafoglio pubblico sotto Transazioni",
              1: () => "Copia ogni indirizzo pubblico",
              2: () => "Incolla in CTC",
            },
            title: () => "Per tutte le altre monete",
          },
        },
      },
    },
    tron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo".',
              1: () =>
                "Al momento supportiamo solo i token TRX e TRC10 Tron; tutte le altre transazioni dovranno essere importate manualmente in Crypto Tax Calculator.",
              2: () =>
                "L'importazione riguarda solo i trasferimenti. In questa fase non gestiamo interazioni on-chain come l'implementazione di smart contract.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () => "Copia l'indirizzo del tuo portafoglio pubblico Tron",
              2: () =>
                "In CTC, seleziona la blockchain pertinente dal menu a discesa",
              3: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    trustwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Apri l'app mobile Trust Wallet o l'estensione del browser",
              1: () =>
                "Se necessario, seleziona il tuo portafoglio in Impostazioni",
              2: () =>
                "Nella scheda Portafoglio, seleziona Ricevi e seleziona il token",
              3: () => "Copia l'indirizzo pubblico ",
              4: () =>
                "Incolla in CTC e premi Sincronizza per importare le tue transazioni",
            },
          },
        },
      },
    },
    unichain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ora supportiamo i token ERC-1155, tuttavia non siamo in grado di importare i tuoi saldi correnti per questi token. Ciò potrebbe causare discrepanze tra il saldo importato dall'API di origine e il saldo calcolato dall'app.",
            },
            title: () => "Token ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Unichain nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    uniswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trova l'indirizzo del portafoglio blockchain Ethereum che hai utilizzato per fare trading tramite Uniswap.",
              1: () =>
                'Inserisci questo indirizzo nel Crypto Tax Calculator, fornisci un nickname facoltativo e clicca su "Aggiungi portafoglio".',
            },
          },
        },
      },
    },
    uphold: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Per maggiori dettagli sui passaggi per le diverse interfacce di Uphold, clicca qui: https://support.uphold.com/hc/en-us/articles/360046137012-How-can-I-download-my-transaction-history-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account Uphold e vai alla dashboard dei portafogli",
              1: () =>
                'A sinistra, clicca sull\'icona "Attività" per andare alla pagina delle attività (https://wallet.uphold.com/dashboard/activity)',
              2: () =>
                "Nel pannello di sinistra, in alto a destra, fare clic sull'icona Documento",
              3: () => 'Fare clic sul pulsante verde "Genera report"',
              4: () =>
                "Pochi minuti dopo riceverai un'e-mail con la possibilità di scaricare la cronologia delle tue transazioni in formato CSV",
              5: () =>
                "Carica il file CSV scaricato qui su Crypto Tax Calculator",
            },
          },
        },
      },
    },
    valr: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account VALR",
              1: () =>
                "Scarica il file CSV della cronologia delle transazioni da VALR",
              2: () => "Carica questo file CSV qui in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    vechain: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vai su 'https://explore.vechain.org/download?address=INSERT_ADDRESS' ma sostituisci INSERT_ADDRESS con l'indirizzo del tuo portafoglio pubblico",
              1: () =>
                "Seleziona il periodo di tempo in cui preferisci che venga visualizzata la cronologia delle transazioni",
              2: () => "Verifica di essere un essere umano",
              3: () => 'Fare clic su "Esporta"',
              4: () => "Carica qui il file CSV",
            },
          },
        },
      },
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Apri il tuo portafoglio " + d.exchangeDisplayName,
              1: () => "Copia l'indirizzo del tuo portafoglio pubblico Vechain",
              2: () =>
                "In CTC, seleziona la blockchain pertinente dal menu a discesa",
              3: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico e clicca su "Importa portafoglio"',
            },
          },
        },
      },
    },
    velas: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
          1: {
            items: {
              0: () =>
                "Potrebbero mancare alcune transazioni interne per alcuni wallet.",
            },
            title: () => "Transazioni interne mancanti",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Velas nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    virgocx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accedi al tuo account VirgoCX qui (https://virgocx.ca/login).",
              1: () =>
                'Fare clic su "eDocument" nell\'angolo in alto a destra sotto il tuo nome.',
              2: () =>
                'Nella barra di navigazione a sinistra, fare clic su "Esporta dati".',
              3: () =>
                'Seleziona "Dati sulle transazioni" come tipo di report e "Criptovaluta" come asset.',
              4: () =>
                'In "Data", scegli l\'intervallo che include la cronologia completa delle tue transazioni con VirgoCX. Potresti dover scaricare più file per garantire una copertura completa.',
              5: () => "Procedere con l'esportazione dei file CSV.",
              6: () =>
                'Esegui lo stesso procedimento, ma questa volta seleziona "Valuta fiat" come asset.',
              7: () =>
                'Ripeti tutti i passaggi precedenti, ma questa volta seleziona "Dati di negoziazione" anziché "Dati di transazione".',
              8: () => "Carica i file CSV su Crypto Tax Calculator qui.",
            },
          },
        },
      },
    },
    wealth99: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account di scambio Wealth99",
              1: () =>
                'Vai su Impostazioni nel menu a sinistra e seleziona la scheda "Sicurezza"',
              2: () => 'Fai clic su "Crea una chiave API"',
              3: () =>
                'Assicurati di aver selezionato "Abilita informazioni di lettura"',
              4: () =>
                'Copia la "Chiave API" e il "Segreto API", inseriscili entrambi nel Calcolatore delle imposte sulle criptovalute qui e fai clic su "Sincronizza API" per sincronizzare i tuoi dati',
            },
          },
        },
      },
    },
    xdai: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
              1: () =>
                'Poiché non sono disponibili dati per la ricezione di xDai tramite xDai Bridge durante la conversione di DAI in XDAI dalla rete Ethereum, dovrai inserire manualmente queste transazioni come "acquisti" in Crypto Tax Calculator',
              2: () =>
                'Poiché non sono disponibili dati sulla ricezione di token sulla xDai Chain tramite xDai OmniBridge quando si esegue il bridging dalla rete Ethereum, sarà necessario immettere manualmente queste transazioni come "acquisti" nel Crypto Tax Calculator',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Gnosis nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    xverse_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                'Fare clic su "Collega portafoglio" e seguire le istruzioni.',
            },
          },
        },
      },
    },
    yobit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "L'API Yobit ottiene solo la cronologia delle transazioni di una settimana fa",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Sotto il nome del tuo account seleziona Chiavi API",
              1: () => "Crea una nuova chiave API per Crypto Tax Calculator",
              2: () =>
                "Incolla la chiave e la chiave segreta nel Crypto Tax Calculator",
            },
          },
        },
      },
    },
    yoroi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'In alternativa, puoi importare le transazioni del tuo portafoglio Yoroi direttamente tramite la blockchain, connettendoti all\'opzione "Sincronizzazione tramite API". Questo attirerà tutte le transazioni e le ricompense di staking per il tuo portafoglio Yoroi',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accedi a Yoroi",
              1: () => "Vai alla pagina di riepilogo della transazione",
              2: () => "In alto a destra vedrai l'icona di esportazione",
              3: () => "Ora puoi importare questo CSV in Crypto Tax Calculator",
            },
          },
        },
      },
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Inserisci solo UN indirizzo da ogni portafoglio. Eseguiamo una ricerca e estraiamo automaticamente i dati per tutti gli altri indirizzi nel portafoglio.",
              1: () =>
                "L'importazione di più indirizzi dallo stesso portafoglio comporterà transazioni duplicate.",
              2: () =>
                "Al momento non supportiamo l'ottenimento della cronologia completa delle transazioni dei wallet in cui ogni singolo indirizzo ha più di 10 transazioni. Stiamo lavorando per fornire ulteriore supporto per tali wallet.",
            },
          },
          1: {
            items: {
              0: () =>
                "Se importi un indirizzo che inizia con 'addr1', lo modifichiamo automaticamente con l'indirizzo del proprietario dell'account che inizia con 'stake1'.",
              1: () =>
                "Ciò ci consente di importare tutti gli altri indirizzi che potresti creare nello stesso portafoglio e di acquisire le tue ricompense di staking.",
            },
            title: () => "Perché il mio indirizzo è stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Apri il tuo portafoglio Yoroi e fai clic su Portafoglio > Ricevi",
              1: () =>
                'Fai clic su "Ha saldo", quindi copia uno degli indirizzi pubblici che iniziano con "addr1..."',
              2: () =>
                'Incolla l\'indirizzo del tuo portafoglio pubblico in CTC e fai clic su "Importa portafoglio"',
            },
          },
        },
      },
    },
    zebpay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accedi al tuo account ZebPay.",
              1: () =>
                "Fare clic sull'icona del profilo nell'angolo in alto a destra.",
              2: () => "Seleziona Dichiarazioni.",
              3: () => "Selezionare Estratto Conto Consolidato.",
              4: () =>
                "Imposta le date di inizio e fine, quindi fai clic su Invia.",
              5: () => "Riceverai il report via email: scaricalo da lì.",
              6: () => "Carica i file su Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    zeroExProtocol: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Dovrai importare i file CSV per ogni mese in cui hai effettuato operazioni",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vai a Delta Balances > Cronologia scambi > Protocollo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Inserisci l'indirizzo del tuo portafoglio pubblico nella barra di ricerca in cima alla pagina e fai clic sull'icona di ricerca",
              2: () =>
                "Nell'intervallo di date seleziona il mese in cui hai effettuato la negoziazione",
              3: () => "Mantieni le altre impostazioni predefinite",
              4: () => 'Fai clic su "Recupera cronologia"',
              5: () =>
                'Una volta completato, fai clic su "Esporta scambi" e seleziona "Default V2 (CSV)"',
              6: () =>
                "In Crypto Tax Calculator ora puoi inserire l'indirizzo del tuo portafoglio e caricare questo CSV",
            },
          },
        },
      },
    },
    zetachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio ZetaChain (che inizia con 0x) nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    zksync: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Si noti che le transazioni zkSync Era non saranno importate finché non saranno verificate dalla blockchain. Ciò richiede in genere 30-60 minuti, ma può estendersi fino a 24 ore o più. Ciò potrebbe anche causare discrepanze temporanee nel saldo, che saranno risolte dopo la verifica delle transazioni.",
            },
            title: () => "Ritardo della transazione zkSync Era",
          },
          1: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio zkSync Era (che inizia con 0x) in Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
    zora: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inserisci l\'indirizzo del tuo portafoglio Zora (che inizia con 0x) nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
            },
          },
        },
      },
    },
  },
  importInstructionsNotice: {
    binance: () =>
      "Binance ha condiviso nel suo annuncio del 2021-08-06 che l'accesso API sarà fornito solo per gli account che hanno completato la verifica dell'identità. Se la sincronizzazione API non funziona, ti consigliamo di completare la verifica dell'identità KYC (intermedia) di Binance prima di riprovare.",
    coinsmart: () =>
      "Coinsmart ha cessato le operazioni a partire da ottobre 2023 e tutti gli account utente sono stati trasferiti a Bitbuy. Vedi l'annuncio: 'https://www.coinsmart.com/blog/important-notice-changes-to-your-coinsmart-account/'",
  },
};
