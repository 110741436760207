import without from "lodash/without";
import React, { useCallback, useContext } from "react";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { CheckboxActionType } from "~/components/transactions/filter-bar/enums";
import { invariant } from "~/lib/invariant";
import { useGetActionsQuery } from "~/state/actions";

type Action =
  | {
      type: CheckboxActionType.RemoveSelectedId;
      id: string;
    }
  | {
      type: CheckboxActionType.AddSelectedId;
      id: string;
      shiftIndex?: number;
    }
  | { type: CheckboxActionType.SetSelectedIds; ids: string[] }
  | { type: CheckboxActionType.SetShiftSelectedId; ids: string[] }
  | { type: CheckboxActionType.SetSelectAll }
  | { type: CheckboxActionType.ResetSelectAll }
  | { type: CheckboxActionType.ResetSelectedIds };

type Dispatch = (action: Action) => void;

type ActionId = string;

type State = {
  selectedIds: ActionId[];
  selectAll: boolean;
  shiftIndex: number | undefined;
};

type CheckboxProviderProps = {
  children: React.ReactNode;
  initialState?: Partial<State>;
};

const CheckboxStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function checkboxReducer(state: State, action: Action): State {
  switch (action.type) {
    case CheckboxActionType.AddSelectedId: {
      return {
        ...state,
        selectedIds: [...state.selectedIds, action.id],
        shiftIndex: action.shiftIndex,
        selectAll: false,
      };
    }
    case CheckboxActionType.RemoveSelectedId: {
      return {
        ...state,
        selectedIds: without(state.selectedIds, action.id),
        selectAll: false,
        shiftIndex: undefined,
      };
    }
    case CheckboxActionType.SetShiftSelectedId: {
      return {
        ...state,
        selectedIds: action.ids,
        selectAll: false,
      };
    }
    case CheckboxActionType.SetSelectedIds: {
      return {
        ...state,
        selectedIds: action.ids,
        selectAll: false,
        shiftIndex: undefined,
      };
    }
    case CheckboxActionType.ResetSelectedIds: {
      return {
        ...state,
        selectedIds: [],
        selectAll: false,
        shiftIndex: undefined,
      };
    }
    case CheckboxActionType.SetSelectAll: {
      return {
        ...state,
        selectAll: true,
        shiftIndex: undefined,
      };
    }
    case CheckboxActionType.ResetSelectAll: {
      return {
        ...state,
        selectAll: false,
        shiftIndex: undefined,
      };
    }
  }
}

export function CheckboxProvider({
  initialState,
  children,
}: CheckboxProviderProps) {
  const [state, dispatch] = React.useReducer(checkboxReducer, {
    selectedIds: [],
    selectAll: false,
    shiftIndex: undefined,
    ...initialState,
  });

  const value = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <CheckboxStateContext.Provider value={value}>
      {children}
    </CheckboxStateContext.Provider>
  );
}

export function useTransactionCheckbox() {
  const context = useContext(CheckboxStateContext);
  const captureAnalytics = useCaptureAnalytics();
  const actions = useGetActionsQuery().data;
  if (!context) {
    throw new Error(
      "useTransactionCheckbox must be used within a CheckboxProvider",
    );
  }

  const { state, dispatch } = context;

  /**
   * Selects a single row
   */
  const handleSelect = useCallback(
    (actionId: string) => {
      invariant(actions, "Must have loaded actions to select checkboxes");
      const currentIndex = actions.transactions.findIndex(
        (action) => action._id === actionId,
      );
      dispatch({
        type: CheckboxActionType.AddSelectedId,
        id: actionId,
        shiftIndex: currentIndex,
      });
    },
    [actions, dispatch],
  );

  /**
   * Select a range of checkboxes using the shift key
   */
  const handleShiftSelect = useCallback(
    (actionId: string) => {
      invariant(actions, "Must have loaded actions to select checkboxes");

      const currentIndex = actions.transactions.findIndex(
        (action) => action._id === actionId,
      );
      invariant(currentIndex !== -1, "Could not find transaction");

      if (state.shiftIndex === undefined) {
        dispatch({
          type: CheckboxActionType.AddSelectedId,
          id: actionId,
          shiftIndex: currentIndex,
        });
        return;
      }

      const startIndex = Math.min(state.shiftIndex, currentIndex);
      const endIndex = Math.max(state.shiftIndex, currentIndex);

      const actionRows = actions.transactions.slice(startIndex, endIndex + 1);
      const analyticsKey = transactionAnalyticsKey("checkbox");
      captureAnalytics(analyticsKey("shiftSelect"));
      dispatch({
        type: CheckboxActionType.SetShiftSelectedId,
        ids: actionRows.map((action) => action._id),
      });
    },
    [actions, state.shiftIndex, captureAnalytics, dispatch],
  );

  return {
    state,
    dispatch,
    handleShiftSelect,
    handleSelect,
  };
}
