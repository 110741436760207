import { Lock } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useContext, useState } from "react";


import { PaywallModalContext } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { useDesign } from "~/hooks/useTheme";
import {
  type HyperlinkKeyToType,
  HyperlinkTextContent,
} from "~/lib/HyperlinkTextContent";
import {
  useIsAccountant,
  useIsCollaborator,
  useIsEnterprise,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";

const RandomGreyscaleSVG = React.memo(
  ({
    width,
    height,
    pixelSize,
  }: {
    width: number;
    height: number;
    pixelSize: number;
  }) => {
    const { tokens } = useDesign();

    const allTokens = [
      tokens.background.accent.neutral.lowest,
      tokens.background.accent.neutral.low,
      tokens.background.accent.neutral.medium,
      tokens.background.accent.neutral.high,
      tokens.background.accent.neutral.highest,
    ];

    // Function to generate a random greyscale color
    const randomToken = () => {
      const tokenValue = Math.floor(Math.random() * allTokens.length);
      return allTokens[tokenValue];
    };

    // Calculate the number of pixels based on the pixelSize
    const numPixelsX = Math.floor(width / pixelSize);
    const numPixelsY = Math.floor(height / pixelSize);

    // Generate pixels
    const pixels = [];
    for (let x = 0; x < numPixelsX; x++) {
      for (let y = 0; y < numPixelsY; y++) {
        pixels.push({
          x: x * pixelSize,
          y: y * pixelSize,
          fill: randomToken(),
        });
      }
    }

    // Render the SVG
    return (
      <svg width={`${width}px`} height={`${height}px`}>
        {pixels.map((pixel, index) => (
          <rect
            key={index}
            x={pixel.x}
            y={pixel.y}
            width={pixelSize}
            height={pixelSize}
            fill={pixel.fill}
          />
        ))}
      </svg>
    );
  },
);

export const PixelatedBox = React.memo(
  ({
    width,
    height,
    pixelSize,
    className,
  }: {
    width: number;
    height: number;
    pixelSize: number;
    className?: string;
  }) => {
    const lang = useLang();
    const { tokens } = useDesign();
    const isAccountant = useIsAccountant();
    const isCollaborator = useIsCollaborator();
    const isEnterprise = useIsEnterprise();
    const context = useContext(PaywallModalContext);
    const [isHovering, setIsHovering] = useState(false);
    const linkTextKey = "linkTextKey";

    const hyperlinkProps = ((): HyperlinkKeyToType => {
      // If the user is an accountant, open the accountant modal
      if (isAccountant) {
        return {
          linkTextKey: {
            fn: () => {
              context?.setUpgradeClientDialogOpen(true);
            },
          },
        };
      }

      // If the user is a regular user, redirect them to the plans page
      if (!isCollaborator && !isEnterprise) {
        return { [linkTextKey]: { linkTo: "/plans" } };
      }

      return { [linkTextKey]: { fn: null } };
    })();

    return (
      <Box
        width={width}
        height={height}
        sx={{
          border: `1px solid ${tokens.border.neutral.default}`,
          borderRadius: "0.25rem",
          overflow: "hidden",
          position: "relative",
        }}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        className={className}
      >
        <RandomGreyscaleSVG
          width={width}
          height={height}
          pixelSize={pixelSize}
        />
        <Tooltip
          title={
            <Box display="flex" gap="0.25rem">
              <Typography variant="Metropolis/Caption/Medium/Regular">
                <HyperlinkTextContent
                  text={lang.report.lookTooltip({
                    linkTextKey,
                  })}
                  actions={hyperlinkProps}
                />
              </Typography>
              <Lock style={{ color: tokens.icon.low, fontSize: "1rem" }} />
            </Box>
          }
          placement="top"
          open={isHovering}
        >
          <Lock
            sx={{
              position: "absolute",
              color: isHovering ? tokens.text.default : tokens.icon.low,
              fontSize: "0.75rem",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Tooltip>
      </Box>
    );
  },
);
