import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { displayMessage } from "~/components/ui/Toaster";
import { setUpdate } from "~/redux/auth";
import * as developerAPI from "~/services/developer";
import { DisplayMessage } from "~/types/enums";
import { type RefreshReason } from "~/types/enums";

export const useSeedDataSets = () => {
  return useQuery({
    queryKey: ["developer", "seedData"],
    queryFn: developerAPI.getDataSets,
  });
};

export const useCloneDataToSelfMutation = () => {
  return useMutation({ mutationFn: developerAPI.cloneDataSetToSelf });
};

export const useCloneDataToNewClientMutation = () => {
  return useMutation({ mutationFn: developerAPI.cloneDataSetToNewClient });
};

export const useCreateSeedDataSetMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: developerAPI.createDataSet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["developer", "seedData"] });
    },
  });
};

export const useDeleteSeedDataSetMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: developerAPI.deleteDataSet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["developer", "seedData"] });
    },
  });
};

export const useEditSeedDataSetMetadataMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: developerAPI.editDataSet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["developer", "seedData"] });
    },
  });
};

export const useTriggerDeveloperReportRefresh = () => {
  const mutation = useMutation({
    mutationFn: async ({ reason }: { reason: RefreshReason }) => {
      const res = await developerAPI.developerReportRefresh(reason);

      if (res.error) {
        displayMessage({
          message: res.msg || "",
          type: DisplayMessage.Error,
        });
      }
      return res;
    },
  });
  return mutation;
};

export const useSetOnboardingStatus = () => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: (newIsOnboarding: boolean) =>
      developerAPI.updateOnboardingStatus(newIsOnboarding),
    onMutate: (newIsOnboarding) => {
      dispatch(setUpdate({ isOnboarding: newIsOnboarding }));
    },
    onError: (error, newIsOnboarding) => {
      dispatch(setUpdate({ isOnboarding: !newIsOnboarding }));
    },
    // onSuccess callback is optional here, as we don't need to do anything extra on success
  });
  return mutation;
};
