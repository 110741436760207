import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Skeleton, Typography } from "@mui/material";
import styled from "styled-components/macro";


import { SMALL_VALUE_THRESHOLD } from "~/constants/constants";
import { useScreenedFiatValue } from "~/hooks/useScreenedFiatValue";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLanguagePreference } from "~/redux/lang";

/**
 * Determines if the value is close enough to zero
 * for the purposes of display to neither be a gain or a loss
 */
function isCloseToZero(value: number) {
  return Math.abs(value) < SMALL_VALUE_THRESHOLD;
}

/**
 * Determine the color of the value display based on the value
 */
function getColorByValue(value: number, tokens: any) {
  if (isNaN(value) || isCloseToZero(value)) {
    return {
      backgroundColor: tokens.background.neutral.default,
      color: tokens.text.default,
    };
  } else if (value > 0) {
    return {
      backgroundColor: tokens.background.success.default,
      color: tokens.text.success,
    };
  }
  return {
    backgroundColor: tokens.background.danger.default,
    color: tokens.text.danger,
  };
}

const StyledBox = styled(Box)<{ backgroundColor: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  height: 1.5rem;
  padding: 0.125rem 0.25rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0.25rem;
`;

const StyledTypography = styled(Typography)<{ color: string }>`
  color: ${({ color }) => color};
  white-space: nowrap;
`;

/**
 * Use to display a value in a box with a background color
 */
export function FiatValueDisplay({
  value,
  isLoading = false,
  isPercentage = false,
  showDirectionIcon = false,
  screenValue = false,
  disableCurrencySymbol = false,
}: {
  value: number;
  isLoading?: boolean;
  isPercentage?: boolean;
  showDirectionIcon?: boolean;
  screenValue?: boolean;
  disableCurrencySymbol?: boolean;
}) {
  const displayScreenedFiatValue = useScreenedFiatValue();
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();

  const formatValue = displayFiatValue({
    value: Math.abs(value),
    localCurrency,
    locale: languagePreference,
    disableCurrencySymbol: isPercentage || disableCurrencySymbol,
  });

  const screenedValue = screenValue
    ? displayScreenedFiatValue(value)
    : displayFiatValue({
        value,
        localCurrency,
        locale: languagePreference,
        disableCurrencySymbol: isPercentage || disableCurrencySymbol,
      });

  const { tokens } = useDesign();
  const { backgroundColor, color } = getColorByValue(value, tokens);

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Skeleton
          variant="rectangular"
          width="4rem"
          height="1.5rem"
          sx={{ borderRadius: "0.25rem" }}
        />
      </Box>
    );
  }

  return (
    <StyledBox backgroundColor={backgroundColor}>
      <StyledTypography
        color={color}
        variant={"IBM Plex Mono/Caption/Medium/Regular"}      >
        {isPercentage ? `${formatValue}%` : screenedValue}
      </StyledTypography>
      {showDirectionIcon && !(isNaN(value) || isCloseToZero(value)) ? (
        <ArrowDropDownIcon
          sx={{
            fontSize: "1rem",
            color,
            transform: value > 0 ? "rotate(180deg)" : "none",
            marginTop: "0.125rem",
          }}
        />
      ) : null}
    </StyledBox>
  );
}
