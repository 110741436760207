import { Trade } from "@ctc/types";
import { ArrowDropDown } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { useRef } from "react";

import { useCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import {
  ModalProvider,
  useModal,
} from "~/components/transactions/command-palette/ModalProvider";
import { NavControllerProvider } from "~/components/transactions/command-palette/NavController";
import { SmallCommandPaletteModal } from "~/components/transactions/command-palette/SmallCommandPaletteModal";
import { CategoryBucketSelector } from "~/components/transactions/command-palette/views/recategorise/TopLevelTxCategory";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { type ActionType } from "~/types/index";

type CategorySelectorProps = {
  value: ActionType | null;
  handleChange: (value: ActionType | null) => void;
  isInTrade?: boolean;
};

export function CategorySelector(props: CategorySelectorProps) {
  return (
    <NavControllerProvider>
      <ModalProvider variant="popover" popupId="category-filter-selector">
        <CategorySelectorInner {...props} />
      </ModalProvider>
    </NavControllerProvider>
  );
}

function CategorySelectorInner({
  value,
  handleChange,
  isInTrade = false,
}: CategorySelectorProps) {
  const modal = useModal();
  const lang = useLang();
  const { open, close } = useCommandPalette();
  const anchor = useRef<HTMLDivElement>(null);
  const { tokens } = useDesign();
  const taxSettings = useTaxSettings();

  if (!taxSettings) {
    return null;
  }

  const root = (
    <CategoryBucketSelector
      row={
        {
          type: isInTrade ? Trade.Buy : Trade.Sell,
          incoming: [],
          outgoing: [],
          fees: [],
        } as any
      }
      selectedFromTrade={isInTrade ? Trade.Buy : Trade.Sell}
      onSubmit={(trade) => {
        handleChange(trade ?? null);
        close();
      }}
      taxSettings={taxSettings}
    />
  );

  return (
    <>
      <div ref={anchor}>
        <TertiaryButton
          {...bindTrigger(modal)}
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();
            open(root, e);
          }}
          sx={{
            justifyContent: "space-between",
            width: "100%",
          }}
          endIcon={
            <ArrowDropDown
              sx={{
                width: "2rem",
                color: tokens.text.low,
              }}
            />
          }
        >
          <Typography
            sx={{ fontSize: "1rem" }}
            color={value ? tokens.text.default : tokens.text.low}
          >
            {value
              ? getActionTypeName({ actionType: value, lang })
              : lang.rules.operatorSelection.categoryPlaceholder}
          </Typography>
        </TertiaryButton>
      </div>
      <SmallCommandPaletteModal modal={modal} anchor={anchor} />
    </>
  );
}
