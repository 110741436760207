import { useEffect, useRef, useState } from "react";

/**
 * Track the previous value of a variable
 */
export function usePrevious<T>(value: T): T | undefined {
  const [current, setCurrent] = useState<T>(value);
  const [previous, setPrevious] = useState<T | undefined>(undefined);

  if (value !== current) {
    setPrevious(current);
    setCurrent(value);
  }

  return previous;
}

/**
 * @deprecated The `usePreviousWithUseEffect` hook is deprecated. Please use the `usePrevious` hook instead.
 *
 * useEffect is no longer recommended for this job.
 * https://react.dev/reference/react/useState#storing-information-from-previous-renders
 *
 * Should be removed but is being used high up in the app and switching
 * to the state version above causes the app to crashloop.
 */
export function usePreviousWithUseEffect<T>(value: T): T | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
