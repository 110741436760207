import autoAnimate from "@formkit/auto-animate";
import { useEffect, useRef } from "react";

// https://github.com/formkit/auto-animate/issues/29#issuecomment-1147742645
// https://github.com/formkit/auto-animate/issues/11
export const useAutoAnimate = (config?: Parameters<typeof autoAnimate>[1]) => {
  const parent = useRef(null);

  useEffect(() => {
    if (parent.current) autoAnimate(parent.current, config);
  }, [config, parent]);

  return [parent];
};
