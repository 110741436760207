import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from "@mui/material";
import { useContext } from "react";
import styled from "styled-components/macro";

import { IconlessDropdown } from "~/components/ui/Dropdown";
import { FYContext } from "~/contexts/FYContext";
import { useAvailableTimezones } from "~/redux/auth";
import { useLang } from "~/redux/lang";

type TimezoneSelectorProps = {
  disabled?: boolean;
  value?: string;
  onChange: (timezone: string) => void;
};

export function TimezoneSelector({
  disabled = false,
  value,
  onChange,
}: TimezoneSelectorProps) {
  const availableTimezones = useAvailableTimezones();
  const lang = useLang();
  const fyContext = useContext(FYContext);

  const handleChange = (e: SelectChangeEvent<string>) => {
    onChange(e.target.value);
    fyContext.refetch();
  };

  return (
    <IconlessDropdown title={lang.settings.timezone}>
      <FormControl required fullWidth>
        <StyledSelect
          disabled={disabled}
          labelId="timezone"
          name="timezone"
          onChange={handleChange}
          required
          value={value}
          variant="outlined"
        >
          {availableTimezones.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </IconlessDropdown>
  );
}

export const StyledSelect = styled(Select)`
  && {
    width: 100%;
    max-width: 22rem;
  }
  .MuiSelect-select {
    padding: 0.25rem 0.75rem;
  }
` as typeof Select;
