import {
  ActionHeaderSide,
  ActionRowSide,
} from "~/components/transactions/action-row/ActionRowSide";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { Side } from "~/types/enums";

export function ActionHeaderTo() {
  return <ActionHeaderSide side={Side.To} />;
}

export function ActionRowTo({ ...props }: ActionRowCellProps) {
  return <ActionRowSide {...props} side={Side.To} />;
}
