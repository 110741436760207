import { parse } from "superjson";

import { logoutSuccess } from "~/redux/auth";
import { resetUserData } from "~/redux/reset";
import { store } from "~/redux/store";
import { CORE } from "~/services/uri";
import { ErrorType } from "~/types/enums";

import { csrfTokenManager } from "../../lib/csrfTokenManager";

type ErrorData = {
  status: number;
  error: true;
  errorType?: string;
  msg?: string;
};

export type Data<ExpectedData> =
  | {
      status: number;
      error: false;
      data: ExpectedData;
      msg?: string;
    }
  | ErrorData;

export class HttpError extends Error {
  data: ErrorData;

  keys: string[];

  log: string;

  constructor(error: ErrorData, keys: string[]) {
    const msg = error.msg || error.errorType || "unknown";
    const log = [...keys, ":", msg].join(" ");

    super(msg);
    this.name = "HttpError";
    this.data = error;
    this.keys = keys;
    this.log = log;
  }
}

const networkConnectivityErrorMessage = `Something went wrong, please try again.`;

/**
 * Checks if an error message indicates a CSRF token error
 * @param msg - The error message to check
 * @returns True if the message indicates a CSRF error, false otherwise
 */
export const isCsrfError = (msg?: string) =>
  msg?.toLowerCase().includes("csrf");

/**
 * Checks if the superjson header is set
 * @param res Response
 * @returns Parse method based on superjson or not
 */
async function jsonService(res: Response) {
  const text = await res.text();
  return {
    parse: () =>
      res.headers.get("Is-Superjson") === "true"
        ? parse(text)
        : JSON.parse(text),
  };
}

/**
 * Fetch wrapper that handles CSRF token retry logic
 * @param path The request path
 * @param config The fetch configuration
 * @param maxRetries Maximum number of retry attempts for CSRF errors
 * @returns The fetch Response
 */
async function fetchWithCsrf(
  path: string,
  config: RequestInit = {},
  maxRetries = 1,
): Promise<Response> {
  let csrfToken = await csrfTokenManager.initializeCsrfToken();
  let retryCount = 0;

  const makeRequest = async () => {
    const requestConfig: RequestInit = {
      ...config,
      headers: {
        ...config.headers,
        ...(csrfToken ? { "x-csrf-token": csrfToken } : {}),
      },
    };

    return fetch(path, requestConfig);
  };

  // First attempt
  let res = await makeRequest();

  // Retry on CSRF errors up to maxRetries times
  while (res.status === 403 && retryCount < maxRetries) {
    // Clone the response before consuming its body
    const clonedRes = res.clone();
    const text = await clonedRes.text();

    try {
      const data = JSON.parse(text);
      if (!isCsrfError(data.msg)) {
        break; // Not a CSRF error, stop retrying
      }
      // The CSRF token we used for this request is stale
      csrfTokenManager.clearStaleToken(csrfToken);
      csrfToken = await csrfTokenManager.initializeCsrfToken();
      res = await makeRequest();
      retryCount++;
    } catch (e) {
      console.error("CSRF retry failed:", e);
      break;
    }
  }

  return res;
}

function mutation(method: string) {
  return async function <ExpectedData>(
    path: string,
    payload: any,
    options?: {
      stringify: boolean;
      defaultContentType?: boolean;
      responseType?: string;
    },
    signal?: AbortSignal,
  ): Promise<Data<ExpectedData>> {
    try {
      // if options.responseType, Accept to options.responseType
      // otherwise, set Accept to application/json
      // done

      // if options.defaultContentType,don't set a content type
      // otherwise then set Content-Type to application/json

      const headers: HeadersInit = {
        Accept: options?.responseType ?? "application/json",
        ...(!options?.defaultContentType && {
          "Content-Type": "application/json",
        }),
      };

      const config: RequestInit = {
        headers,
        method,
        credentials: "include",
        body:
          !options || options.stringify
            ? JSON.stringify(payload)
            : (payload as FormData),
        signal,
      };

      const res = await fetchWithCsrf(`${CORE}${path}`, config);
      const myJson = await jsonService(res);
      const genericErrorMessage = store.getState().lang.map.errorMessageGeneric;

      if (!res.ok) {
        // we have a 400-500 error

        // might or might not be json
        // could a 502 bad gateway from cloudflare if it can't reach the backend

        try {
          const { message, msg, errorType } = myJson.parse();

          return {
            status: res.status,
            error: true,
            msg: message ?? msg,
            errorType,
          };
        } catch (e) {
          // not json, so just return error + what we got
          return {
            status: res.status,
            error: true,
            // e.g. "502 - Something went wrong - whatever the backend sent"
            msg: `${res.status} ${res.statusText} - ${genericErrorMessage}`,
            errorType: ErrorType.FetchFail,
          };
        }
      }

      // no 400-500
      const { data, error, message, msg, errorType } = myJson.parse();

      if (error) {
        // could still have a error: true with a 200 status (shouldnt, but could)
        return {
          status: res.status,
          error: true,
          msg: message || msg,
          errorType,
        };
      }
      return { data, status: res.status, error: false, msg };
    } catch (e) {
      console.error(e);
      return {
        status: 500,
        error: true,
        msg: networkConnectivityErrorMessage,
        errorType: ErrorType.FetchFail,
      };
    }
  };
}

export const put = mutation("put");
export const patch = mutation("patch");
export const post = mutation("post");

export async function get<ExpectedData>(
  path: string,
  options?: { headers?: HeadersInit },
): Promise<Data<ExpectedData>> {
  try {
    const config: RequestInit = {
      credentials: "include",
      headers: {
        ...(options?.headers ?? {}),
      },
    };

    const res = await fetch(`${CORE}${path}`, config);
    const genericErrorMessage = store.getState().lang.map.errorMessageGeneric;
    const myJson = await jsonService(res);
    if (!res.ok) {
      // we have a 400-500 error

      // might or might not be json
      // could a 502 bad gateway from cloudflare if it can't reach the backend
      // that won't have a json response

      try {
        const { message, msg, errorType } = myJson.parse();

        // Intercept NotAuthenticated messages to log out client side
        const { user } = store.getState().auth;
        if (
          user &&
          (message || msg) === "NotAuthenticated" &&
          res.status === 403
        ) {
          store.dispatch(resetUserData());
          store.dispatch(logoutSuccess());
        }
        return {
          status: res.status,
          error: true,
          msg: message ?? msg,
          errorType,
        };
      } catch (e) {
        // not json, so just return error + what we got
        return {
          status: res.status,
          error: true,
          // e.g. "502 - Something went wrong - whatever the backend sent"
          msg: `${res.status} ${res.statusText} - ${genericErrorMessage}`,
          errorType: ErrorType.FetchFail,
        };
      }
    }

    // Parse the json response either using superjson or json.parse
    const { data, error, message, msg, errorType } = myJson.parse();

    if (error) {
      // could still have a error: true with a 200 status (shouldnt, but could)
      return {
        status: res.status,
        error: true,
        msg: message || msg,
        errorType,
      };
    }

    return { data, status: res.status, error: false };
  } catch (e) {
    console.error(e);
    return {
      status: 500,
      error: true,
      msg: networkConnectivityErrorMessage,
      errorType: ErrorType.FetchFail,
    };
  }
}

/**
 * Generic function which creates a delete request to the specified endpoint
 * @param path The path of the endpoint
 * @param options Any additional payload options
 */
export async function deleteRequest<ExpectedData>(
  path: string,
  options?: { headers?: HeadersInit },
): Promise<Data<ExpectedData>> {
  try {
    const config: RequestInit = {
      method: "delete",
      credentials: "include",
      headers: {
        ...(options?.headers ? options.headers : {}),
      },
    };
    const res = await fetchWithCsrf(`${CORE}${path}`, config);
    const myJson = await jsonService(res);
    const { data, error, message, msg, errorType } = await myJson.parse();
    if (res.ok && !error) {
      return { status: res.status, error: false, msg: message || msg, data };
    }
    return { status: res.status, error: true, msg: message || msg, errorType };
  } catch (e) {
    console.error(e);
    return {
      status: 500,
      error: true,
      msg: networkConnectivityErrorMessage,
      errorType: ErrorType.FetchFail,
    };
  }
}
