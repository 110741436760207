import { SyncStatusPlatform, Trade } from "@ctc/types";
import {
  Add,
  ArrowDropDown,
  Close,
  Delete,
  Done,
  Edit,
  PowerOffOutlined,
  PowerOutlined,
} from "@mui/icons-material";
import {
  Box,
  type BoxProps,
  Dialog,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import isNil from "lodash/isNil";
import { useCallback, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components/macro";

import { rulesIntegrationsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { CategorySelector } from "~/components/rules/CategoryRuleSelector";
import {
  getInnerOuterFilter,
  RuleCardFilterSection,
} from "~/components/rules/RuleCardFilterSection";
import {
  RuleDeleteConfirmationModal,
  RuleDisableConfirmationModal,
  RuleEnableConfirmationModal,
} from "~/components/rules/RuleConfirmationModals";
import { ruleNameValidation } from "~/components/rules/RuleTableRow";
import { type NewRuleComponents } from "~/components/rules/types";
import { AccountSelector } from "~/components/settings-modal/views/integrations/AccountSelector";
import {
  ButtonType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import {
  FilterProvider,
  useTransactionFilter,
} from "~/components/transactions/filter-bar/FilterContext";
import {
  getInactiveFilterCount,
  SelectDropdownWrapper,
} from "~/components/transactions/filter-bar/FilterQueryBuilder";
import {
  type AutocompleteFilterOption,
  convertFilterQueryToSelectedAutocompleteFilterOption,
  FilterOption,
  getAutocompleteFilterOptions,
} from "~/components/transactions/filter-bar/FilterSearch";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { Chip } from "~/components/ui/Chips";
import { ErrorFallback } from "~/components/ui/error-boundary/ErrorBoundaryWrapper";
import { useConditionalAutoFocus } from "~/components/ui/hooks";
import { TransactionTableModalInner } from "~/components/ui/TransactionTableModal";
import { useDesign } from "~/hooks/useTheme";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { useCanAccessFeature, useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { type RuleBulkOperation, type RuleDTO } from "~/services/rules";
import {
  useGetActionsCountFromFilterQuery,
  useGetFilterOptionsQuery,
} from "~/state/actions";
import { Scope } from "~/state/enums";
import { useErpAvailableAccountsQuery, useErpSettingsQuery } from "~/state/erp";
import { useLoadExchangeNames } from "~/state/exchanges";
import {
  useCreateNewRuleMutation,
  useEditRuleMutation,
  useGetEnabledRulesQuery,
  useRenameRuleMutation,
} from "~/state/rules";
import { useSyncStatus } from "~/state/sync";
import { BulkOperations, Features, FilterOperators } from "~/types/enums";
import { type CategoryFilter, type FilterQuery } from "~/types/index";

export function NewRuleCard({
  initialFilter,
  ...props
}: {
  initialFilter?: FilterQuery;
  editRuleId?: string;
  initialName?: string;
  initialOperations?: RuleBulkOperation[];
  handleClose: () => void;
  onSubmit?: ({ name, filter, operations }: NewRuleComponents) => void;
}) {
  return (
    <FilterProvider initialState={{ filter: initialFilter }}>
      <NewRuleCardContents {...props} />
    </FilterProvider>
  );
}

type DisplayRuleCardProps = {
  rule: RuleDTO;
  enableTxCounts?: boolean;
};

export function DisplayRuleCard(props: DisplayRuleCardProps) {
  const [isEditing, setIsEditing] = useState(false);
  const { rule } = props;

  const [name, setName] = useState(rule.name);
  const [filter, setFilter] = useState(rule.filter);
  const [operations, setOperations] = useState(rule.operations);

  if (isEditing) {
    return (
      <NewRuleCard
        handleClose={() => {
          setIsEditing(false);
        }}
        initialName={name}
        initialFilter={filter}
        initialOperations={operations}
        editRuleId={rule.id}
        onSubmit={({ name, filter, operations }) => {
          setName(name);
          setFilter(filter);
          setOperations(operations);
        }}
      />
    );
  }

  return (
    <FilterProvider
      initialState={{
        filter: {
          type: FilterOperators.And,
          rules: [
            {
              type: FilterOperators.Rule,
              value: [rule.id],
            },
          ],
        },
      }}
    >
      <DisplayRuleCardContents
        onEdit={() => {
          setIsEditing(true);
        }}
        {...props}
        rule={{ ...props.rule, name, filter, operations }}
      />
    </FilterProvider>
  );
}

function NewRuleCardContents({
  editRuleId,
  initialName,
  initialOperations,
  handleClose,
  onSubmit,
}: {
  editRuleId?: string;
  initialName?: string;
  initialOperations?: RuleBulkOperation[];
  handleClose: () => void;
  onSubmit?: ({ name, filter, operations }: NewRuleComponents) => void;
}) {
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [operations, _setOperations] = useState<
    (RuleBulkOperation & {
      erpAccountCode: string | undefined;
      toActionType: string | undefined;
    })[]
  >((initialOperations as any) ?? []);
  useGetFilterOptionsQuery(); // prefetch filter options
  const { tokens } = useDesign();
  const lang = useLang();
  const {
    dispatch,
    state: { filter: validFilter },
  } = useTransactionFilter();

  const erpQuery = useErpSettingsQuery();
  const erp = erpQuery.data;

  const isRulesEnabled = useCanAccessFeature(Features.Rules);

  const erpAvailableAccounts = useErpAvailableAccountsQuery().data ?? [];
  const addRuleMutation = useCreateNewRuleMutation();
  const editRuleMutation = useEditRuleMutation();
  const allRules = useGetEnabledRulesQuery();
  const [name, _setName] = useState(
    initialName ??
      lang.contacts.ruleCard.newRule({
        number: (allRules.data?.length ?? 0) + 1,
      }),
  );

  const analyticsKey = rulesIntegrationsAnalyticsKey("add");
  const captureAnalytics = useCaptureAnalytics();

  // Internal state that lets us store invalid filter states
  const [filter, _setFilter] = useState<FilterQuery | undefined>(validFilter);

  const { innerFilter: initialInnerFilter, outerFilters: initialOuterFilters } =
    getInnerOuterFilter(filter);

  const [innerFilter, _setInnerFilter] = useState<FilterQuery | undefined>(
    initialInnerFilter,
  );
  const [outerFilters, _setOuterFilters] =
    useState<FilterQuery[]>(initialOuterFilters);

  const updateFilterHelper = ({
    outer,
    inner,
  }: {
    outer: FilterQuery[];
    inner: FilterQuery | undefined;
  }) => {
    if (outer.length) {
      setFilter({
        type: FilterOperators.And,
        rules: [inner, ...outer].filter((f): f is FilterQuery => !!f),
      });
    } else {
      // Else just use the inner filter
      setFilter(inner);
    }
  };

  const setOuterFilters = (newFilters: FilterQuery[]) => {
    _setOuterFilters(newFilters);
    updateFilterHelper({ outer: newFilters, inner: innerFilter });
  };

  const setInnerFilter = (newFilter: FilterQuery | undefined) => {
    _setInnerFilter(newFilter);
    updateFilterHelper({ outer: outerFilters, inner: newFilter });
  };

  const setFilter = useCallback(
    (updatedFilter: FilterQuery | undefined) => {
      _setFilter(updatedFilter);
      setHasBeenEdited(true);

      if (!updatedFilter) {
        dispatch({
          type: FilterActionType.ResetFilter,
        });
        return;
      }
      const inactiveCount = getInactiveFilterCount(updatedFilter);
      if (inactiveCount > 0) {
        // dont sync to the global, dont do the search
        // there are some empty inputs, so lets wait for the user to
        // remove them, or fill them in
        return;
      }

      // if this is an `or` query, and it only has 1 element
      // we will switch it to be an and
      if (
        updatedFilter.type === FilterOperators.Or &&
        updatedFilter.rules.length === 1
      ) {
        dispatch({
          type: FilterActionType.SetFilter,
          filter: {
            type: FilterOperators.And,
            rules: updatedFilter.rules,
          },
        });
      } else {
        dispatch({
          type: FilterActionType.SetFilter,
          filter: updatedFilter,
        });
      }
    },
    [dispatch],
  );

  const setOperations = (
    operations: (RuleBulkOperation & {
      erpAccountCode: string | undefined;
      toActionType: string | undefined;
    })[],
  ) => {
    _setOperations(operations);
    setHasBeenEdited(true);

    // Handle case where category op has been added but no txTrade filter
    if (
      operations.some((op) => op.type === BulkOperations.Recategorise) &&
      !outerFilters.some((filter) => filter.type === FilterOperators.TxTrade)
    ) {
      // If there was already an inner category filter, find and remove it, but record if it selected in/out type so we can just elevate that
      const categoryFilter = innerFilter
        ? findCategoryFilter(innerFilter)
        : null;

      if (innerFilter && categoryFilter) {
        // We need to remove the category filter from the inner filter
        const filteredInnerFilter = removeOperatorFromFilter(
          innerFilter,
          FilterOperators.TxTrade,
        );
        setInnerFilter(filteredInnerFilter ?? undefined);
      }

      const outerFilterValue =
        categoryFilter?.value?.[0] === Trade.Out
          ? Trade.Out
          : Trade.In;

      setOuterFilters([
        ...outerFilters,
        {
          type: FilterOperators.TxTrade,
          value: [outerFilterValue],
        },
      ]);
    }

    // Handle case where category op has been removed but txTrade filter still exists
    if (
      operations.every((op) => op.type !== BulkOperations.Recategorise) &&
      outerFilters.some((filter) => filter.type === FilterOperators.TxTrade)
    ) {
      setOuterFilters(
        outerFilters.filter(
          (filter) => filter.type !== FilterOperators.TxTrade,
        ),
      );
    }
  };

  const setName = (name: string) => {
    _setName(name);
    setHasBeenEdited(true);
  };

  const enabledOperations: BulkOperations[] = [
    ...(erp?.erp
      ? [
          BulkOperations.ChangeErpAssetAccount,
          BulkOperations.ChangeErpGainsAccount,
          BulkOperations.ChangeErpLoanAccount,
          BulkOperations.ChangeErpPnLAccount,
          BulkOperations.ChangeErpCashAccount,
        ]
      : []),
    ...(isRulesEnabled ? [BulkOperations.Recategorise] : []),
  ].filter((op) => !operations.some((operation) => operation.type === op));

  const operationControl = useMemo(() => {
    return enabledOperations.map((operation) => ({
      field: operation,
      label:
        lang.contacts.rules.operators[
          operation as keyof typeof lang.contacts.rules.operators
        ],
    }));
  }, [enabledOperations, lang]);

  const onClose = () => {
    handleClose();
    setName(
      lang.contacts.ruleCard.newRule({
        number: (allRules.data?.length ?? 0) + 1,
      }),
    );
    setFilter(validFilter);
    setOperations([]);
    dispatch({
      type: FilterActionType.ResetFilter,
    });
  };

  // To get an accurate count of what this rule will apply to, we need to look
  // for txs that match the filter but don't already have a rule for that
  // operator applied
  const getAppliesToFilter = (
    filter: FilterQuery | undefined,
    operators: BulkOperations[],
    editRuleId?: string,
  ): FilterQuery | undefined => {
    if (!filter) {
      return filter;
    }

    const fullFilter: FilterQuery = {
      type: FilterOperators.And,
      rules: [
        filter,
        ...(operators.filter((op) => op !== BulkOperations.Recategorise).length
          ? [
              {
                type: FilterOperators.Not,
                rule: { type: FilterOperators.RuleOperator, value: operators },
              } as FilterQuery,
            ]
          : []),
      ],
    };

    if (!editRuleId) return fullFilter;

    // Check txs that either match the filter, or would match the filter if we
    // allow overwriting rules
    return {
      type: FilterOperators.Or,
      rules: [
        fullFilter,
        {
          type: FilterOperators.And,
          rules: [
            filter,
            {
              type: FilterOperators.Rule,
              value: [editRuleId],
            },
          ],
        },
      ],
    };
  };

  const searchFilterSafe =
    !!validFilter &&
    operations.every((op) =>
      isValidSearchFilter({ operator: op, filter: validFilter }),
    );

  const categoryFilter = validFilter ? findCategoryFilter(validFilter) : null;
  const isInTrade =
    !!categoryFilter && categoryFilter.value.includes(Trade.In);

  return (
    <Box
      bgcolor={tokens.elevation.low}
      borderRadius="0.25rem"
      border={`1px solid ${tokens.border.neutral.default}`}
    >
      <Box p="1rem" display="flex" flexDirection="column" gap="1rem">
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <TextField
            size="small"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            sx={{
              maxWidth: "16rem",
            }}
          />
          <Box
            width="3.125rem"
            textAlign="center"
            borderRadius="0.25rem"
            bgcolor={tokens.background.accent.neutral.medium}
          >
            <Typography variant="Metropolis/Caption/Medium/Regular">
              {lang.contacts.ruleCard.if}
            </Typography>
          </Box>
          <RuleCardFilterSection
            innerFilter={innerFilter}
            outerFilters={outerFilters}
            setInnerFilter={setInnerFilter}
            setOuterFilters={setOuterFilters}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <Box
            width="3.125rem"
            textAlign="center"
            borderRadius="0.25rem"
            bgcolor={tokens.background.success.default}
          >
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              sx={{ color: tokens.text.success }}
            >
              {lang.contacts.ruleCard.then}
            </Typography>
          </Box>

          <Stack gap="0.5rem">
            {operations.map((operation, index) => {
              return (
                <Box
                  key={operation.type}
                  display="flex"
                  alignItems="center"
                  gap="0.75rem"
                >
                  <SelectDropdownWrapper
                    selection={
                      lang.contacts.rules.operators[
                        operation.type as keyof typeof lang.contacts.rules.operators
                      ]
                    }
                    selectionOptions={operationControl}
                    setSelection={(value) => {
                      const prev = [...operations];
                      prev[index].type = value as any; // @todo fix cast
                      setOperations(prev);
                    }}
                    endIcon={<ArrowDropDown />}
                    buttonType={ButtonType.Tertiary}
                    disabled={!operationControl.length}
                  />
                  <Box minWidth="24rem" width="100%">
                    {operation.type === BulkOperations.Recategorise ? (
                      <CategorySelector
                        value={operation.toActionType ?? null}
                        handleChange={(value) => {
                          if (value) {
                            const prev = [...operations];
                            if (
                              prev[index].type === BulkOperations.Recategorise
                            ) {
                              prev[index].toActionType = value;
                              setOperations(prev);
                            }
                          }
                        }}
                        isInTrade={isInTrade}
                      />
                    ) : (
                      <AccountSelector
                        title={undefined}
                        tooltip={undefined}
                        value={
                          erpAvailableAccounts.find(
                            (acc) => acc.code === operation.erpAccountCode,
                          ) ?? null
                        }
                        handleChange={(_e, value) => {
                          if (value) {
                            const prev = [...operations];
                            prev[index].erpAccountCode = value?.code;
                            setOperations(prev);
                          }
                        }}
                      />
                    )}
                  </Box>
                  <TextIconButton
                    size="small"
                    onClick={() => {
                      setOperations([
                        ...operations.slice(0, index),
                        ...operations.slice(index + 1),
                      ]);
                    }}
                  >
                    <Close style={{ fontSize: "1rem" }} />
                  </TextIconButton>
                </Box>
              );
            })}
          </Stack>

          <Box>
            <SelectDropdownWrapper
              selection={lang.contacts.ruleCard.setAccount}
              selectionOptions={operationControl}
              setSelection={(val) => {
                setOperations([
                  ...operations,
                  {
                    type: val,
                    erpAccountCode: undefined,
                  } as any, // @todo fix cast
                ]);
              }}
              startIcon={<Add />}
              buttonType={ButtonType.Text}
              disabled={!operationControl.length}
              disableAddFullWidth
            />
          </Box>
        </Box>

        <AppliesToBox
          filter={getAppliesToFilter(
            validFilter,
            operations.map((o) => o.type),
            editRuleId,
          )}
          isNew
        />
      </Box>

      <Box
        px="1rem"
        py="0.75rem"
        display="flex"
        gap="0.75rem"
        borderTop={`1px solid ${tokens.border.neutral.default}`}
        flexWrap="wrap"
      >
        <TertiaryButton
          onClick={() => {
            onClose();
            captureAnalytics(analyticsKey("cancel"));
          }}
        >
          {lang.contacts.ruleCard.cancel}
        </TertiaryButton>
        <PrimaryButton
          disabled={
            !hasBeenEdited ||
            !validFilter ||
            !operations.length ||
            operations.some((op) => !(op.erpAccountCode || op.toActionType)) ||
            !searchFilterSafe
          }
          onClick={() => {
            if (validFilter) {
              if (editRuleId) {
                editRuleMutation.mutate({
                  id: editRuleId,
                  name,
                  filter: validFilter,
                  operations,
                });
              } else {
                addRuleMutation.mutate({
                  name,
                  filter: validFilter,
                  operations,
                });
              }
              if (onSubmit) {
                onSubmit({
                  name,
                  filter: validFilter,
                  operations,
                });
              }
            }
            captureAnalytics(analyticsKey("submit"));
            onClose();
          }}
        >
          {lang.contacts.ruleCard.save}
        </PrimaryButton>
      </Box>
    </Box>
  );
}

function findCategoryFilter(filter: FilterQuery): CategoryFilter | null {
  switch (filter.type) {
    case FilterOperators.TxTrade:
      return filter;
    case FilterOperators.And:
      return filter.rules.reduce((acc: CategoryFilter | null, curr) => {
        if (acc) return acc;
        return findCategoryFilter(curr);
      }, null);
    default:
      return null;
  }
}

function removeOperatorFromFilter(
  filter: FilterQuery,
  operator: FilterOperators,
): FilterQuery | null {
  if (filter.type === operator) {
    return null;
  }

  if (
    filter.type === FilterOperators.And ||
    filter.type === FilterOperators.Or
  ) {
    return {
      ...filter,
      rules: filter.rules
        .map((rule) => removeOperatorFromFilter(rule, operator))
        .filter((rule): rule is FilterQuery => rule !== null),
    };
  }

  if (filter.type === FilterOperators.Not) {
    const res = removeOperatorFromFilter(filter.rule, operator);
    if (!res) {
      return null;
    }
    return {
      ...filter,
      rule: res,
    };
  }

  return filter;
}

// If there is a recategorise operation, we need to make sure that the filter by either incoming or outgoing exclusively
function isValidSearchFilter({
  operator,
  filter,
}: {
  operator: RuleBulkOperation;
  filter: FilterQuery;
}): boolean {
  if (operator.type !== BulkOperations.Recategorise) return true;

  const categoryFilter = findCategoryFilter(filter);

  return (
    !!categoryFilter &&
    categoryFilter.value.length === 1 &&
    [Trade.In, Trade.Out, Trade.Unknown].includes(
      categoryFilter.value[0] as Trade,
    )
  );
}

type NonValueFilters =
  | FilterOperators.After
  | FilterOperators.Before
  | FilterOperators.Date
  | FilterOperators.And
  | FilterOperators.Or
  | FilterOperators.Not;
type ValueOnlyFilters = Exclude<FilterQuery, { type: NonValueFilters }>;
function isValueOnlyFilter(filter: FilterQuery): filter is ValueOnlyFilters {
  const key = "value";
  return key in filter;
}

const QueryBuilderDisplayRowBox = styled(Box)<{ showOperation: boolean }>`
  display: grid;
  grid-template-rows: auto;
  grid-template-areas: ${(props) =>
    props.showOperation
      ? `"operation type polarity value"`
      : `"type polarity value"`};
  grid-template-columns: ${(props) =>
    props.showOperation ? "5rem 10rem 4.5rem 1fr" : "10rem 4.5rem 1fr"};
`;

const DisplayGridBox = styled(Box)`
  && {
    border-right: 1px solid
      ${({ theme }) => theme.tokens.border.neutral.default};
    padding: 0.75rem;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.tokens.text.disabled};
    font-weight: 500;
    line-height: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &&:last-child {
    border-right: none;
  }
`;

function QueryBuilderDisplayRow({
  filter,
  operation,
  showOperation = false,
  ...boxProps
}: {
  filter: FilterQuery;
  operation?: FilterOperators.Or | FilterOperators.And;
  showOperation?: boolean;
} & BoxProps) {
  const { tokens } = useDesign();
  const lang = useLang();
  const unwrappedFilter =
    filter.type === FilterOperators.Not ? filter.rule : filter;
  const isNot = filter.type === FilterOperators.Not;

  const { data: exchangeNames } = useLoadExchangeNames();
  const query = useGetFilterOptionsQuery();
  const country = useCountry();
  const autocompleteFilterOptions: AutocompleteFilterOption[] = useMemo(() => {
    if (!exchangeNames) {
      return [];
    }
    return getAutocompleteFilterOptions({
      data: query.data,
      lang,
      exchangeNames,
      alwaysMiddleTrimAddresses: true,
    });
  }, [query.data, lang, exchangeNames]);

  const filterOptions = convertFilterQueryToSelectedAutocompleteFilterOption(
    unwrappedFilter,
    autocompleteFilterOptions,
    country,
  );

  if (
    unwrappedFilter.type === FilterOperators.Or ||
    unwrappedFilter.type === FilterOperators.And
  ) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        borderRadius="0.25rem"
        border={`1px solid ${tokens.border.neutral.default}`}
      >
        {unwrappedFilter.rules.map((rule, index) => {
          return (
            <QueryBuilderDisplayRow
              key={rule.type}
              filter={rule}
              operation={unwrappedFilter.type}
              showOperation={index !== 0}
              borderTop={
                index !== 0
                  ? `1px solid ${tokens.border.neutral.default}`
                  : undefined
              }
            />
          );
        })}
      </Box>
    );
  }

  if (!isValueOnlyFilter(unwrappedFilter)) {
    return null;
  }

  const filterOptionsTrimmed = filterOptions.slice(0, 5);
  const remainingLength = filterOptions.length - filterOptionsTrimmed.length;

  return (
    <QueryBuilderDisplayRowBox
      showOperation={showOperation}
      width="100%"
      {...boxProps}
    >
      {showOperation && operation ? (
        <DisplayGridBox gridArea="operation">
          {lang.txTable.filter.operators[operation]}
        </DisplayGridBox>
      ) : null}
      <DisplayGridBox gridArea="type">
        {lang.txTable.filter.operators[unwrappedFilter.type]}
      </DisplayGridBox>
      <DisplayGridBox gridArea="polarity">
        {isNot
          ? lang.txTable.filter.negation.isNot
          : lang.txTable.filter.negation.is}
      </DisplayGridBox>
      <DisplayGridBox
        gridArea="value"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        padding="0rem"
      >
        {filterOptionsTrimmed.map((option) => (
          <Chip
            color={tokens.text.low}
            bgcolor={tokens.background.accent.neutral.low}
            pl="0.25rem"
            key={option.key}
          >
            <FilterOption {...option} size="small" hideCategoryNameOverride />
          </Chip>
        ))}
        {remainingLength ? (
          <Chip
            color={tokens.text.low}
            bgcolor={tokens.background.accent.neutral.low}
            width={undefined}
            maxWidth={undefined}
            overflow={undefined}
          >{`+${remainingLength}`}</Chip>
        ) : null}
      </DisplayGridBox>
    </QueryBuilderDisplayRowBox>
  );
}

function RenamableRuleName({
  rule,
  disableEdit = false,
}: {
  rule: RuleDTO;
  disableEdit?: boolean;
}) {
  const { tokens } = useDesign();
  const conditionalAutoFocus = useConditionalAutoFocus();
  const [isEditing, setIsEditing] = useState(false);
  const [displayName, setDisplayName] = useState<string>(rule.name);
  const renameRuleMutation = useRenameRuleMutation();

  const analyticsKey = rulesIntegrationsAnalyticsKey("rule");
  const captureAnalytics = useCaptureAnalytics();

  const submit = () => {
    // If the name isnt too long or short and has changed, submit the change
    if (ruleNameValidation(rule.name, displayName)) {
      renameRuleMutation.mutate({
        id: rule.id,
        newName: displayName.trim(),
      });
    }
    setIsEditing(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexWrap="nowrap"
      gap="0.25rem"
      maxWidth={{ xs: "100%", sm: "50%" }}
    >
      {isEditing ? (
        <TextField
          size="small"
          value={displayName}
          {...conditionalAutoFocus}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            setDisplayName(e.target.value);
          }}
          onBlur={() => {
            submit();
          }}
          sx={{
            height: "1.5rem",
            marginTop: "-0.625rem",
            marginBottom: "0.5rem",
            flexGrow: "1",
            width: "100%",
          }}
          inputProps={{
            sx: {
              fontSize: "1rem",
              bgcolor: tokens.background.neutral.lowest.default,
              borderRadius: "0.25rem",
            },
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
              submit();
            }
          }}
        />
      ) : (
        <Typography
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          variant="Metropolis/Header/H5"
        >
          {rule.name}
        </Typography>
      )}
      {!isEditing && !disableEdit ? (
        <TextIconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(true);
            setDisplayName(rule.name);
            captureAnalytics(analyticsKey("edit name"));
          }}
        >
          <Edit
            sx={{
              cursor: "pointer",
              color: tokens.text.low,
              width: "1rem",
            }}
          />
        </TextIconButton>
      ) : isEditing ? (
        <TextIconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            submit();
          }}
        >
          <Done
            sx={{
              cursor: "pointer",
              color: tokens.text.success,
              width: "1rem",
            }}
          />
        </TextIconButton>
      ) : null}
    </Box>
  );
}

function DisplayRuleCardContents({
  rule,
  enableTxCounts = false,
  onEdit,
}: DisplayRuleCardProps & { onEdit: () => void }) {
  const { tokens } = useDesign();
  const lang = useLang();
  const { filter, operations } = rule;

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDisabledOpen, setIsDisabledOpen] = useState(false);
  const [isEnabledOpen, setIsEnabledOpen] = useState(false);

  const ruleFilter: FilterQuery = {
    type: FilterOperators.And,
    rules: [
      {
        type: FilterOperators.Rule,
        value: [rule.id],
      },
    ],
  };

  const isDisabled = rule.isDisabled;

  return (
    <>
      <RuleDeleteConfirmationModal
        rule={rule}
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
      />
      <RuleDisableConfirmationModal
        rule={rule}
        isOpen={isDisabledOpen}
        setIsOpen={setIsDisabledOpen}
      />
      <RuleEnableConfirmationModal
        rule={rule}
        isOpen={isEnabledOpen}
        setIsOpen={setIsEnabledOpen}
      />
      <Box
        bgcolor={tokens.elevation.low}
        borderRadius="0.25rem"
        border={`1px solid ${tokens.border.neutral.default}`}
      >
        <Box p="1rem" display="flex" flexDirection="column" gap="1rem">
          <Box display="flex" flexDirection="column" gap="0.5rem">
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              gap="0.5rem"
            >
              <RenamableRuleName rule={rule} disableEdit={isDisabled} />
              <Box
                display="flex"
                gap="0.25rem"
                alignItems="center"
                flexWrap="wrap"
              >
                <RuleStatusChip isDisabled={isDisabled} />

                {isDisabled ? (
                  <TextButton
                    size="small"
                    endIcon={<PowerOutlined />}
                    onClick={() => {
                      setIsEnabledOpen(true);
                    }}
                  >
                    {lang.contacts.ruleCard.enableRule}
                  </TextButton>
                ) : (
                  <>
                    <TextButton
                      size="small"
                      endIcon={<Edit />}
                      onClick={onEdit}
                    >
                      {lang.contacts.ruleCard.editRule}
                    </TextButton>
                    <TextButton
                      size="small"
                      endIcon={<PowerOffOutlined />}
                      onClick={() => {
                        setIsDisabledOpen(true);
                      }}
                    >
                      {lang.contacts.ruleCard.disableRule}
                    </TextButton>
                  </>
                )}
                <TextButton
                  size="small"
                  style={{ color: tokens.text.danger }}
                  endIcon={<Delete />}
                  onClick={() => {
                    setIsDeleteOpen(true);
                  }}
                >
                  {lang.contacts.ruleCard.deleteRule}
                </TextButton>
              </Box>
            </Box>
            <Box
              width="3.125rem"
              textAlign="center"
              borderRadius="0.25rem"
              bgcolor={tokens.background.accent.neutral.medium}
            >
              <Typography variant="Metropolis/Caption/Medium/Regular">
                {lang.contacts.ruleCard.if}
              </Typography>
            </Box>
            <QueryBuilderDisplayRow filter={filter} />
          </Box>
          <Box display="flex" flexDirection="column" gap="0.5rem">
            <Box
              width="3.125rem"
              textAlign="center"
              borderRadius="0.25rem"
              bgcolor={tokens.background.success.default}
            >
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                sx={{ color: tokens.text.success }}
              >
                {lang.contacts.ruleCard.then}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              borderRadius="0.25rem"
              border={`1px solid ${tokens.border.neutral.default}`}
            >
              {operations.map((operation, index) => {
                if (operation.type === BulkOperations.Recategorise) {
                  return (
                    <Box
                      key={operation.type}
                      display="grid"
                      gridTemplateRows="auto"
                      gridTemplateAreas={`"type override"`}
                      gridTemplateColumns="10rem 1fr"
                      width="100%"
                      borderTop={
                        index !== 0
                          ? `1px solid ${tokens.border.neutral.default}`
                          : undefined
                      }
                    >
                      <DisplayGridBox gridArea="type">
                        {
                          lang.contacts.rules.operators[
                            operation.type as keyof typeof lang.contacts.rules.operators
                          ]
                        }
                      </DisplayGridBox>

                      <DisplayGridBox gridArea="override" minWidth="24rem">
                        <Chip
                          color={tokens.text.low}
                          bgcolor={tokens.background.accent.neutral.low}
                        >
                          {getActionTypeName({
                            actionType: operation.toActionType,
                            lang,
                          })}
                        </Chip>
                      </DisplayGridBox>
                    </Box>
                  );
                }

                return (
                  <ErpRuleOperationContents
                    key={operation.type}
                    operation={operation}
                    index={index}
                  />
                );
              })}
            </Box>
          </Box>
          {enableTxCounts ? <AppliesToBox filter={ruleFilter} /> : null}
        </Box>
      </Box>
    </>
  );
}

function RuleStatusChip({ isDisabled }: { isDisabled: boolean }) {
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <Tooltip
      title={
        isDisabled
          ? lang.contacts.ruleCard.disabledTooltip
          : lang.contacts.ruleCard.enabledTooltip
      }
    >
      <Box
        bgcolor={
          isDisabled
            ? tokens.background.warning.default
            : tokens.background.success.default
        }
        color={isDisabled ? tokens.text.warning : tokens.text.success}
        px="0.5rem"
        py="0.13rem"
        borderRadius="0.25rem"
        display="flex"
        gap="0.25rem"
        alignItems="center"
      >
        {isDisabled ? (
          <PowerOffOutlined sx={{ fontSize: "1rem" }} />
        ) : (
          <PowerOutlined sx={{ fontSize: "1rem" }} />
        )}
        <Typography color="inherit" variant="Metropolis/Caption/Medium/Regular">
          {isDisabled
            ? lang.contacts.ruleCard.disabled
            : lang.contacts.ruleCard.active}
        </Typography>
      </Box>
    </Tooltip>
  );
}

function ErpRuleOperationContents({
  operation,
  index,
}: {
  operation: Extract<
    RuleBulkOperation,
    {
      type:
        | BulkOperations.ChangeErpAssetAccount
        | BulkOperations.ChangeErpPnLAccount
        | BulkOperations.ChangeErpGainsAccount
        | BulkOperations.ChangeErpLoanAccount
        | BulkOperations.ChangeErpCashAccount;
    }
  >;
  index: number;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const erpAvailableAccounts = useErpAvailableAccountsQuery().data ?? [];

  const account =
    erpAvailableAccounts.find((acc) => acc.code === operation.erpAccountCode) ??
    null;

  return (
    <Box
      display="grid"
      gridTemplateRows="auto"
      gridTemplateAreas={`"type override"`}
      gridTemplateColumns="10rem 1fr"
      width="100%"
      borderTop={
        index !== 0 ? `1px solid ${tokens.border.neutral.default}` : undefined
      }
    >
      <DisplayGridBox gridArea="type">
        {
          lang.contacts.rules.operators[
            operation.type as keyof typeof lang.contacts.rules.operators
          ]
        }
      </DisplayGridBox>

      <DisplayGridBox gridArea="override" minWidth="24rem">
        <Chip
          color={tokens.text.low}
          bgcolor={tokens.background.accent.neutral.low}
        >{`(${account?.code}) ${account?.name}`}</Chip>
      </DisplayGridBox>
    </Box>
  );
}

export function AppliesToBox({
  isNew = false,
  filter,
}: {
  isNew?: boolean;
  filter: FilterQuery | undefined;
}) {
  const { tokens } = useDesign();
  const [isOpen, setIsOpen] = useState(false);
  const lang = useLang();
  const langChoice = isNew
    ? lang.contacts.ruleCard.willApplyTo
    : lang.contacts.ruleCard.appliesTo;

  const count = useGetActionsCountFromFilterQuery({ filter });

  const analyticsKey = rulesIntegrationsAnalyticsKey("add");
  const captureAnalytics = useCaptureAnalytics();
  const rulesStatus = useSyncStatus(Scope.Rules);

  const formattedCount = count.data ?? 0;
  const isRulesRunning = rulesStatus === SyncStatusPlatform.Pending;

  const cantSeeWhatItWillApplyTo = // If we're loading the count or the count isnt defined, while the rule's filter has been defined
    ((count.isPending || isNil(count.data)) && !isNil(filter)) ||
    // If the count is 0 but the report is refreshing (could still be applying)
    (formattedCount === 0 && isRulesRunning) ||
    // If the count is 0 and we're loading the count (could have just been applied and we're updating)
    (formattedCount === 0 && count.isFetching);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="xl"
        onClose={() => {
          setIsOpen(false);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <TransactionTableModalInner
            filter={filter}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </ErrorBoundary>
      </Dialog>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px="1rem"
        py="0.5rem"
        borderRadius="0.25rem"
        bgcolor={tokens.background.neutral.default}
        border={`1px solid ${tokens.border.neutral.default}`}
        flexWrap="wrap"
        gap="0.5rem"
      >
        <Typography
          variant="Metropolis/Body/Regular"
          display="flex"
          alignItems="center"
          gap="0.25rem"
        >
          {cantSeeWhatItWillApplyTo ? (
            <Skeleton width="12rem" />
          ) : (
            langChoice({ count: formattedCount })
          )}
        </Typography>
        <TextButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
            captureAnalytics(analyticsKey("view"));
          }}
          size="small"
          sx={{ fontSize: "0.75rem" }}
          disabled={cantSeeWhatItWillApplyTo}
        >
          <Typography variant="Metropolis/Body/Regular" color="inherit">
            {lang.contacts.ruleCard.view}
          </Typography>
        </TextButton>
      </Box>
    </>
  );
}
