import { useCallback } from "react";

import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { getSelectedRows } from "~/components/transactions/command-palette/helpers/getSelectedRows";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import {
  useGetActionsQuery,
  useNewBulkMarkAsReviewedMutation,
} from "~/state/actions";
import { useIsErpReadyToSync } from "~/state/erp";
import { type FilterQuery, syncableERPStatuses } from "~/types/index";

export function useBulkEditReview() {
  const bulkMarkAsReviewedMutation = useNewBulkMarkAsReviewedMutation();

  const onMarkAsReviewSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.MarkAsReviewed,
  });
  const onUnreviewedSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.MarkAsNotReviewed,
  });
  const onConfirm = useCallback(
    ({ reviewed, filter }: { reviewed: boolean; filter: FilterQuery }) => {
      bulkMarkAsReviewedMutation.mutate(
        {
          filter,
          reviewed,
        },
        {
          onSuccess: reviewed ? onMarkAsReviewSuccess : onUnreviewedSuccess,
        },
      );
    },
    [bulkMarkAsReviewedMutation, onMarkAsReviewSuccess, onUnreviewedSuccess],
  );

  return { onConfirm, isLoading: bulkMarkAsReviewedMutation.isPending };
}

export function useSelectedRowReviewedStatus({
  filter,
}: {
  filter: FilterQuery;
}) {
  const actionsQuery = useGetActionsQuery();

  const selectedRows = getSelectedRows({ filter, actionsQuery });

  const isAllTxSelectedReviewed = selectedRows.every(
    (action) => action.reviewed,
  );
  const isAllTxSelectedUnreviewed = selectedRows.every(
    (action) => action.reviewed === false,
  );

  let shouldReviewed;

  // Unreview all tx if all selected row is reviewed
  if (isAllTxSelectedReviewed) {
    shouldReviewed = false;
  } else if (isAllTxSelectedUnreviewed) {
    shouldReviewed = true;
  } else {
    shouldReviewed = true;
  }

  return {
    isAllTxSelectedReviewed,
    isAllTxSelectedUnreviewed,
    shouldReviewed,
  };
}

export function useIsSelectedRowERPSyncable({
  filter,
}: {
  filter: FilterQuery;
}) {
  const actionsQuery = useGetActionsQuery();
  const selectedRows = getSelectedRows({ filter, actionsQuery });
  const canSyncERP = useIsErpReadyToSync();

  // Haven't done the default erp mapping
  if (!canSyncERP) {
    return false;
  }
  const isSomeTxsSyncable = selectedRows.some(
    (action) =>
      action.erpSyncStatus &&
      syncableERPStatuses.includes(action.erpSyncStatus),
  );

  // as long as there is at least one syncable tx, we show the sync option
  return isSomeTxsSyncable;
}
