import { type QueryKey, useQueries, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { useUser } from "~/redux/auth";
import { type RootState } from "~/redux/types";
import { CouponService } from "~/services/coupon";
import * as subscriptionAPI from "~/services/subscription";
import { usePlans } from "~/state/plans";
import {
  type BillingHistory,
  type PaymentMethod,
  type StripeCouponCode,
  type StripeCouponDetails,
} from "~/types/index";

const BILLING_HISTORY_KEY = "billing-history";
const PAYMENT_METHOD_KEY = "payment-method";
const COUPON_KEY = "coupon";

export const billingKeys = {
  billingHistory: () => [BILLING_HISTORY_KEY] as const,
  paymentMethod: () => [PAYMENT_METHOD_KEY] as const,
  coupon: (couponCode: string | null) => [COUPON_KEY, couponCode] as const,
};

async function fetchBillingHistory(
  errorMessage: string,
): Promise<BillingHistory[]> {
  const res = await subscriptionAPI.getBillingHistory();
  if (res.error) {
    throw new Error(errorMessage);
  }
  return res.data;
}

async function fetchPaymentMethod(
  errorMessage: string,
): Promise<PaymentMethod | null> {
  const res = await subscriptionAPI.getPaymentMethod();
  if (res.error) {
    throw new Error(errorMessage);
  }
  return res.data;
}

export async function fetchCouponDetails(coupon: string | null) {
  if (!coupon) return null;

  const res = await subscriptionAPI.getCouponDetails(coupon);

  // Don't throw an error if the coupon is invalid
  if (res.error) {
    return null;
  }
  return res.data;
}

/**
 * Hook to get the best available coupons for each plan
 * @returns Object containing best coupons and their discounts by plan, along with loading state
 */
export function useBestCoupons() {
  const plans = usePlans();
  const user = useUser();
  const stripeCouponCodes = CouponService.getUserStripeCouponCodes(user);
  const couponsQuery = useCouponsDetails({ stripeCouponCodes });
  if (!couponsQuery.data || !plans?.data) {
    return { data: null, isLoading: couponsQuery.isLoading };
  }
  return {
    data: CouponService.calculateBestCoupons(plans.data, couponsQuery.data),
    isLoading: couponsQuery.isLoading || plans.isLoading,
  };
}

/**
 * Hook to fetch stripe coupon details for a list of coupon codes
 * @param coupons - List of coupon codes to fetch details for
 * @returns Object containing coupon details and loading state
 */
export function useCouponsDetails({
  stripeCouponCodes,
}: {
  stripeCouponCodes: StripeCouponCode[];
}) {
  const couponQueries = useQueries({
    queries: stripeCouponCodes.map((coupon) => ({
      queryKey: billingKeys.coupon(coupon),
      queryFn: async () => fetchCouponDetails(coupon),
    })),
  });
  const isLoading = couponQueries.some((query) => query.isLoading);
  const data = couponQueries
    .map((query) => query.data)
    .filter(Boolean) as StripeCouponDetails[];

  return {
    data,
    isLoading,
  };
}

export function useCouponDetails({ coupon }: { coupon: string | null }) {
  const queryKey: QueryKey = billingKeys.coupon(coupon);
  const queryResult = useQuery({
    queryKey,
    queryFn: () => fetchCouponDetails(coupon),
  });
  return queryResult;
}

export function useBillingHistoryQuery() {
  const errorMessage = useSelector(
    (state: RootState) => state.lang.map.billing.wentWrongBillingHistory,
  );

  const queryKey: QueryKey = billingKeys.billingHistory();
  const queryResult = useQuery<BillingHistory[], Error>({
    queryKey,
    queryFn: () => fetchBillingHistory(errorMessage),
  });
  return queryResult;
}

// if user is not stripe enabled, don't run the query - stops free users spamming the API
export function usePaymentMethodQuery() {
  const user = useUser();

  // We'll use the enabled option to conditionally run the query
  const isEnabled = !!user?.activeDataSource.paidBy;

  const errorMessage = useSelector(
    (state: RootState) => state.lang.map.billing.wentWrongPaymentMethods,
  );

  const queryKey: QueryKey = billingKeys.paymentMethod();
  const queryResult = useQuery<PaymentMethod | null, Error>({
    queryKey,
    queryFn: () => fetchPaymentMethod(errorMessage),
    enabled: isEnabled,
  });

  // Return the result as it is, since the query will not run if isEnabled is false
  return queryResult;
}

export const usePaymentFailed = (): boolean | null => {
  const paymentIntentsQuery = useBillingHistoryQuery();
  const paymentMethodQuery = usePaymentMethodQuery();
  const isLoading =
    paymentIntentsQuery.isPending || paymentMethodQuery.isPending;

  if (isLoading) {
    return null;
  }

  const paymentIntents = paymentIntentsQuery.data;
  const paymentMethod = paymentMethodQuery.data;

  if (!paymentIntents?.length || !paymentMethod) {
    return null;
  }

  const { latestChargeCreated, succeeded } = paymentIntents[0];

  if (latestChargeCreated > paymentMethod.created && !succeeded) {
    return true;
  }

  return false;
};
