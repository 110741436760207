import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Chip, Stack } from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

import { CommandPaletteIconButton } from "~/components/transactions/command-palette/Buttons";
import { useNumSelectedOrFiltered } from "~/components/transactions/command-palette/hooks/useNumSelectedOrFiltered";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { devices } from "~/components/ui/theme/legacy";
import { useLang } from "~/redux/lang";

export const CommandPaletteTitle = ({
  topArea,
  loading,
}: {
  loading: boolean | undefined;
  topArea?: React.ReactNode;
}) => {
  const { views, pop } = useNavController();
  const lang = useLang();
  const { numSelected = 0 } = useNumSelectedOrFiltered();

  return (
    <SearchBoxTitle>
      <Stack direction="row" gap="0.5rem">
        {views.length > 1 ? (
          <CommandPaletteIconButton
            disabled={loading}
            onClick={() => {
              pop();
            }}
          >
            <ArrowBackIcon fontSize="small" />
          </CommandPaletteIconButton>
        ) : null}
        <CommandPaletteChip
          label={lang.txTable.commandPallet.numSelected({
            numSelected,
          })}
          size="small"
        />
      </Stack>
      {topArea ? topArea : null}
    </SearchBoxTitle>
  );
};

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
`;

const SearchBoxTitle = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;

  @media ${devices.tabletS} {
    flex-direction: row;
    align-items: center;
  }
`;

export const CommandPaletteChip = styled(Chip)`
  ${({ theme }) => ({ ...theme.mui.typography['Metropolis/Caption/Medium/Regular'] })};
  background-color: ${({ theme }) =>
    theme.tokens.background.accent.neutral.lowest};
`;
