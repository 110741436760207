import { Trade } from "@ctc/types";
import { type Blockchain } from "@ctc/types";
import { Box, Popover, Stack, Typography } from "@mui/material";
import groupBy from "lodash/groupBy";
import isNil from "lodash/isNil";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import type * as React from "react";

import { getMostRelevantOverallBalanceActionRow } from "~/components/balance-ledger/helpers";
import { getTrimmedStack } from "~/components/imports-v2/helpers";
import { ActionHeaderCell } from "~/components/transactions/action-row/ActionHeaderCell";
import { ActionRowCell } from "~/components/transactions/action-row/ActionRowCell";
import { ActionRowHover } from "~/components/transactions/action-row/ActionRowHover";
import { EnDash } from "~/components/transactions/action-row/EnDash";
import { ActionTableGridArea } from "~/components/transactions/action-row/enums";
import {
  ActionRowFirstLineBox,
  ActionRowSecondLineBox,
  Overflower,
} from "~/components/transactions/action-row/Overflower";
import { scrollColumnSizes } from "~/components/transactions/action-row/TransactionActionRowLayout";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { formatDisplayAddress } from "~/components/transactions/helpers";
import { CensoredTooltip } from "~/components/ui/CensoredComponents";

import { useDesign } from "~/hooks/useTheme";
import { displayCryptoQuantity } from "~/lib/index";
import { TradeInfo } from "~/lib/tradeTypeDefinitions";
import { useIsAddressLike } from "~/redux/imports";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useEntityLookupAsync } from "~/state/entities";
import { Align, TradeDirection } from "~/types/enums";
import {
  type ActionRow,
  type CurrencyIdentifier,
  type Entity,
  type TransactionDetails,
} from "~/types/index";

const MAX_ROWS_PER_SIDE = 5;

export function ActionRowAccountBalance({
  direction,
  row,
  currencyIdentifier,
}: ActionRowCellProps & {
  direction: TradeDirection;
  currencyIdentifier?: CurrencyIdentifier[];
}) {
  const { getEntityForAddress } = useEntityLookupAsync();
  const currencyIds = (currencyIdentifier || []).map((currency) => currency.id);

  const relevantTxs = (
    direction === TradeDirection.In
      ? [...row.incoming]
      : [...row.outgoing, ...row.fees]
  ).filter(
    (tx) =>
      tx.balanceRemaining !== undefined &&
      (currencyIdentifier === undefined ||
        currencyIds.includes(tx.currencyIdentifier.id)),
  );

  // De-dupe the accounts, only show the most relevant
  // Group by account
  const txsByAccountAndCurrency = groupBy(
    relevantTxs,
    (tx) =>
      `${getRelevantSide(tx, getEntityForAddress).exchange}__${
        tx.currencyIdentifier.id
      }`,
  );

  // Pick the most relevant tx
  const selectedTxs = Object.values(txsByAccountAndCurrency).map((txs) => {
    const latestTimestamp = txs.reduce((latestTimestamp, tx) => {
      const currentTimestamp = new Date(tx.timestamp);
      const latestTimestampDate = new Date(latestTimestamp);

      if (currentTimestamp > latestTimestampDate) {
        return tx.timestamp;
      }
      return latestTimestamp;
    }, txs[0].timestamp);

    const txsAtLatestTimestamp = txs.filter(
      (tx) => tx.timestamp === latestTimestamp,
    );

    // Return the last tx if there's only one
    if (txsAtLatestTimestamp.length === 1) {
      return txsAtLatestTimestamp[0];
    }

    // prioritize in order: fee, out, in
    const fee = txsAtLatestTimestamp.find((tx) => tx.trade === Trade.Fee);
    if (fee) return fee;

    const out = txsAtLatestTimestamp.find(
      (tx) => TradeInfo[tx.trade].direction === TradeDirection.Out,
    );
    if (out) return out;

    const inTx = txsAtLatestTimestamp.find(
      (tx) => TradeInfo[tx.trade].direction === TradeDirection.In,
    );

    if (inTx) return inTx;

    // Base case, return first tx in list
    return txs[0];
  });

  const id =
    direction === TradeDirection.Out
      ? ActionTableGridArea.AccountBalanceOut
      : ActionTableGridArea.AccountBalanceIn;

  if (!selectedTxs.length) {
    return (
      <ActionRowCell id={id} align={Align.Left}>
        <ActionRowFirstLineBox>
          <EnDash />
        </ActionRowFirstLineBox>
      </ActionRowCell>
    );
  }

  const { shownItems, otherItemsCount } = getTrimmedStack(
    selectedTxs,
    MAX_ROWS_PER_SIDE,
  );

  return (
    <ActionRowCell id={id} align={Align.Left}>
      <Overflower>
        <Stack direction="column">
          {shownItems.map((tx) => (
            <AccountBalance
              key={tx._id}
              tx={tx}
              currencyIdentifier={tx.currencyIdentifier}
            />
          ))}
          {otherItemsCount !== 0 ? (
            <BalanceOverflowCell
              txs={selectedTxs.slice(MAX_ROWS_PER_SIDE)}
              count={String(otherItemsCount)}
            />
          ) : null}
        </Stack>
      </Overflower>
    </ActionRowCell>
  );
}
const getRelevantSide = (
  tx: TransactionDetails,
  getEntity: (
    address: string,
    blockchain?: Blockchain | undefined,
  ) => Entity | undefined,
) => {
  const fromEntity = getEntity(tx.from, tx.blockchain as Blockchain);
  const toEntity = getEntity(tx.to, tx.blockchain as Blockchain);

  const config: Record<
    TradeDirection,
    { exchange: string; balance: number; displayName: string }
  > = {
    [TradeDirection.In]: {
      displayName: toEntity ? toEntity.displayName : tx.toDisplayName,
      exchange: toEntity ? toEntity.displayName : tx.to,
      balance: tx.balanceRemaining?.toTotal || 0, // TODO handle undefined correctly
    },
    [TradeDirection.Out]: {
      displayName: fromEntity ? fromEntity.displayName : tx.fromDisplayName,

      exchange: fromEntity ? fromEntity.displayName : tx.from,
      balance: tx.balanceRemaining?.fromTotal || 0, // TODO handle undefined correctly
    },
    [TradeDirection.Unknown]: {
      // TODO think about what to do in this case
      displayName: fromEntity ? fromEntity.displayName : tx.fromDisplayName,
      exchange: fromEntity ? fromEntity.displayName : tx.from,
      balance: tx.balanceRemaining?.fromTotal || 0,
    },
  };

  return config[TradeInfo[tx.trade].direction];
};

function AccountBalance({
  tx,
  currencyIdentifier,
}: {
  tx: TransactionDetails;
  currencyIdentifier: CurrencyIdentifier;
}) {
  const { tokens } = useDesign();
  const languagePreference = useLanguagePreference();
  const { getEntityForAddress } = useEntityLookupAsync();

  const { displayName, exchange, balance } = getRelevantSide(
    tx,
    getEntityForAddress,
  );

  const isAnAddress = !!useIsAddressLike(exchange.trim());
  const entity = getEntityForAddress(exchange, tx.blockchain as Blockchain);

  if (isNil(balance)) return null;

  const formattedBalance = displayCryptoQuantity({
    quantity: balance,
    locale: languagePreference,
    precision: 6,
  });

  const isNicknamed =
    Boolean(entity) || (exchange !== displayName && isAnAddress);
  const finalDisplayName = entity ? entity.displayName : displayName.trim();

  return (
    <>
      <ActionRowFirstLineBox>
        <Overflower>
          <SmallAmountWrapper amount={balance}>
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              fontFamily={tokens.fontFamilies.numeric}
              sx={{
                color: balance < 0 ? tokens.text.danger : tokens.text.default, // TODO take into account negative balance threshold
              }}
            >
              {formattedBalance} {currencyIdentifier.symbol}
            </Typography>
          </SmallAmountWrapper>
        </Overflower>
      </ActionRowFirstLineBox>
      <ActionRowSecondLineBox>
        <Typography
          className="action-row-account__account"
          variant="IBM Plex Mono/Caption/Small/Regular"
          sx={{
            color: tokens.text.low,
          }}
        >
          {isNicknamed
            ? finalDisplayName
            : formatDisplayAddress(finalDisplayName, isNicknamed, isAnAddress)}
        </Typography>
      </ActionRowSecondLineBox>
    </>
  );
}

export function ActionRowOverallBalance({
  row,
  currencyIdentifier,
}: ActionRowCellProps & { currencyIdentifier: CurrencyIdentifier[] }) {
  const { shownItems, otherItemsCount } = getTrimmedStack(
    currencyIdentifier,
    MAX_ROWS_PER_SIDE,
  );

  const overflow = currencyIdentifier.slice(MAX_ROWS_PER_SIDE);

  return (
    <ActionRowCell id={ActionTableGridArea.OverallBalance}>
      <ActionRowOverallBalanceInner row={row} currencyIdentifier={shownItems} />
      {otherItemsCount !== 0 ? (
        <BalanceOverallOverflowCell
          row={row}
          currencies={overflow}
          count={String(otherItemsCount)}
        />
      ) : null}
    </ActionRowCell>
  );
}

function ActionRowOverallBalanceInner({
  row,
  currencyIdentifier,
}: ActionRowCellProps & { currencyIdentifier: CurrencyIdentifier[] }) {
  const { tokens } = useDesign();
  const languagePreference = useLanguagePreference();

  return (
    <>
      {currencyIdentifier.map((currency) => {
        const { overallBalance } = getMostRelevantOverallBalanceActionRow(
          row,
          currency.id,
        );

        const formattedOverallBalance = displayCryptoQuantity({
          quantity: overallBalance,
          locale: languagePreference,
          precision: 6,
        });

        if (isNil(overallBalance)) {
          return (
            <ActionRowFirstLineBox key={currency.id}>
              <EnDash />
            </ActionRowFirstLineBox>
          );
        }

        return (
          <ActionRowFirstLineBox key={currency.id}>
            <Overflower>
              <SmallAmountWrapper amount={overallBalance}>
                <Typography
                  variant="Metropolis/Caption/Medium/Regular"
                  fontFamily={tokens.fontFamilies.numeric}
                  sx={{
                    color:
                      overallBalance < 0
                        ? tokens.text.danger
                        : tokens.text.default, // TODO take into account negative balance threshold
                  }}
                >
                  {formattedOverallBalance} {currency.symbol}
                </Typography>
              </SmallAmountWrapper>
            </Overflower>
          </ActionRowFirstLineBox>
        );
      })}
    </>
  );
}

export function ActionHeaderAccountBalance({
  direction,
}: {
  direction: TradeDirection.In | TradeDirection.Out;
}) {
  const lang = useLang();
  return (
    <Box style={{ gridArea: `${direction}AccountBalance` }}>
      <ActionHeaderCell>
        {lang.txTable.accountBalance[direction]}
      </ActionHeaderCell>
    </Box>
  );
}

export function ActionHeaderOverallBalance() {
  const lang = useLang();
  return (
    <Box style={{ gridArea: "overallBalance" }}>
      <ActionHeaderCell>{lang.txTable.overallBalance}</ActionHeaderCell>
    </Box>
  );
}

const SMALL_NO_CUTOFF = 0.000_000_1;
const TOOLTIP_PRECISION = 16;

const roundNumberToDp = (num: number, dp: number) => {
  return num.toLocaleString("fullwide", {
    useGrouping: false,
    maximumFractionDigits: dp,
  });
};

const SmallAmountWrapper = ({
  amount,
  children,
}: {
  amount: number;
  children: React.ReactElement;
}) => {
  if (Math.abs(amount) < SMALL_NO_CUTOFF && amount !== 0) {
    return (
      <CensoredTooltip
        title={roundNumberToDp(amount, TOOLTIP_PRECISION)}
        placement="top"
      >
        {children}
      </CensoredTooltip>
    );
  }
  return children;
};

function BalanceOverallOverflowCell({
  row,
  currencies,
  count,
}: {
  row: ActionRow;
  currencies: CurrencyIdentifier[];
  count: string;
}) {
  const { tokens } = useDesign();
  const lang = useLang().txTable.txRow;

  const popupState = usePopupState({
    variant: "popover",
    popupId: `row-overflow`,
  });

  return (
    <>
      <Overflower
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box display="flex" flexWrap="wrap" gap="0.5rem" alignItems="center">
          <ActionRowHover
            {...bindTrigger(popupState)}
            display="flex"
            flexWrap="nowrap"
            gap="0.5rem"
            sx={{
              "& svg": {
                visibility: "hidden",
              },
              cursor: "pointer",
              borderRadius: "0.25rem",
              "&:hover": {
                "& svg": {
                  visibility: "visible",
                },
              },
            }}
          >
            <Typography
              variant="Metropolis/Body/Regular"
              fontFamily={tokens.fontFamilies.numeric}
              color={tokens.text.default}
            >
              {lang.overflowCurrencies({ count })}
            </Typography>
          </ActionRowHover>
        </Box>
      </Overflower>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box
          maxHeight="20rem"
          overflow="auto"
          bgcolor={tokens.background.neutral.lowest.default}
          p="0.25rem"
          pl="1rem"
          maxWidth={scrollColumnSizes[ActionTableGridArea.From]}
        >
          <ActionRowOverallBalanceInner
            row={row}
            currencyIdentifier={currencies}
          />
        </Box>
      </Popover>
    </>
  );
}

function BalanceOverflowCell({
  txs,
  count,
}: {
  txs: TransactionDetails[];
  count: string;
}) {
  const { tokens } = useDesign();
  const lang = useLang().txTable.txRow;

  const popupState = usePopupState({
    variant: "popover",
    popupId: `row-overflow`,
  });

  return (
    <>
      <Overflower
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box display="flex" flexWrap="wrap" gap="0.5rem" alignItems="center">
          <ActionRowHover
            {...bindTrigger(popupState)}
            display="flex"
            flexWrap="nowrap"
            gap="0.5rem"
            sx={{
              "& svg": {
                visibility: "hidden",
              },
              cursor: "pointer",
              borderRadius: "0.25rem",
              "&:hover": {
                "& svg": {
                  visibility: "visible",
                },
              },
            }}
          >
            <Typography
              variant="Metropolis/Body/Regular"
              fontFamily={tokens.fontFamilies.numeric}
              color={tokens.text.default}
            >
              {lang.overflowCurrencies({ count })}
            </Typography>
          </ActionRowHover>
        </Box>
      </Overflower>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Stack
          maxHeight="20rem"
          overflow="auto"
          bgcolor={tokens.background.neutral.lowest.default}
          p="0.25rem"
          pl="1rem"
          maxWidth={scrollColumnSizes[ActionTableGridArea.From]}
        >
          {txs.map((tx) => {
            return (
              <Box key={tx._id}>
                <AccountBalance
                  key={tx._id}
                  tx={tx}
                  currencyIdentifier={tx.currencyIdentifier}
                />
              </Box>
            );
          })}
        </Stack>
      </Popover>
    </>
  );
}
