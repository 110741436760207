import { Plan } from "@ctc/types";
import { Player } from "@lottiefiles/react-lottie-player";
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useRef, useState } from "react";

import { captureGoogleAnalytics } from "~/analytics/google";
import calcoPhoneCall from "~/assets/lottie-animations/calcoPhoneCall.json";
import { AccountantPortal } from "~/components/payment/AccountantPortal";
import { Faq } from "~/components/payment/payment/Faq";
import { PlanSection } from "~/components/payment/PlanSection";
import { stripePromise } from "~/components/payment/StripePayment";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import {
  PaymentProvider,
  usePayment,
} from "~/contexts/payment-context/PaymentContext";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { usePlans } from "~/state/plans";
import { type StripeCouponCode } from "~/types";
import { PlanGroup } from "~/types/enums";

export function Payment({ skipSection }: { skipSection?: JSX.Element }) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentProvider>
        <PaymentWithoutElements skipSection={skipSection} />
      </PaymentProvider>
    </Elements>
  );
}

function PaymentWithoutElements({
  skipSection,
}: {
  skipSection?: JSX.Element;
}) {
  const lang = useLang();
  const user = useUser();
  const isMountedRef = useRef<boolean | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>();
  const [accountantPortalOpen, setAccountantPortalOpen] = useState(false);
  const [planGroup, setPlanGroup] = useState<PlanGroup>(
    user?.paidPlan === Plan.Business ? PlanGroup.Business : PlanGroup.Personal,
  );
  const plans = usePlans();
  const { isRedirecting, handlePlanChange } = usePayment();

  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current) {
      captureGoogleAnalytics({
        category: "payment",
        action: "view payment options",
      });
    }

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  if (!user) return null;

  const { isOnboarding } = user;

  const isPersonalPlan = planGroup === PlanGroup.Personal;

  const faqData = isPersonalPlan ? lang.faqPricing : lang.faqAccountant;

  const onPlanChange = async (
    plan: Plan,
    applyCoupon = true,
    couponCode: StripeCouponCode | undefined,
  ) => {
    if (plan === Plan.Accountant) {
      setAccountantPortalOpen(true);
    } else {
      setSelectedPlan(plan);
      await handlePlanChange(plan, applyCoupon, couponCode);
    }
  };

  const handlePlanGroupChange = (planGroup: PlanGroup) => {
    setPlanGroup(planGroup);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (plans.isPending) {
    return (
      <Box
        width="100%"
        height="70vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Player
          loop
          autoplay
          src={calcoPhoneCall}
          style={{ height: "10rem" }}
        />
      </Box>
    );
  }

  return (
    <>
      <AccountantPortal
        open={accountantPortalOpen}
        handleClose={() => {
          setAccountantPortalOpen(false);
        }}
      />
      <PlanSection
        planGroup={planGroup}
        // We don't want to allow view accountant plan for onboarding user
        onPlanGroupChange={isOnboarding ? undefined : handlePlanGroupChange}
        onPlanChange={onPlanChange}
        selectedPlan={selectedPlan}
        isRedirecting={isRedirecting}
      />
      {skipSection}
      <Faq data={faqData} />
      <Box
        my="2rem"
        sx={{
          maxWidth: "80.125rem",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "right",
          padding: "0 1rem",
        }}
      >
        <TextButton
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          endIcon={<ArrowUpIcon fontSize="small" />}
        >
          {lang.payment.backToTop}
        </TextButton>
      </Box>
    </>
  );
}
