import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { ActionHeaderCell } from "~/components/transactions/action-row/ActionHeaderCell";
import { ActionRowCell } from "~/components/transactions/action-row/ActionRowCell";
import { ActionRowHover } from "~/components/transactions/action-row/ActionRowHover";
import { ActionTableGridArea } from "~/components/transactions/action-row/enums";
import {
  ActionRowFirstLineStyle,
  ActionRowSecondLineBox,
} from "~/components/transactions/action-row/Overflower";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  CheckboxActionType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import {
  getTransactionPageLink,
  useTransactionFilter,
} from "~/components/transactions/filter-bar/FilterContext";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { Align, FilterOperators, Sort } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

export function ActionRowTime({
  row,
  openInNewTab = true,
  disablePointerEvents = false,
}: ActionRowCellProps & { openInNewTab?: boolean }) {
  const timezone = useTimezone();
  const lang = useLang();
  const { dispatch } = useTransactionFilter();
  const { dispatch: checkboxDispatch } = useTransactionCheckbox();
  const { tokens } = useDesign();

  const date = moment.tz(row.timestamp, timezone).format("DD MMM YY");
  const time = moment.tz(row.timestamp, timezone).format("HH:mm:ss");

  const isEmbedded = useIsEmbedded();

  const timeFrom =
    moment.tz(row.timestamp, timezone).startOf("day").unix() * 1000;

  const filter: FilterQuery = {
    type: FilterOperators.And,
    rules: [
      {
        type: FilterOperators.Date,
        value: [timeFrom],
      },
    ],
  };

  const dateFilterLink = getTransactionPageLink({
    state: {
      filter,
    },
  });

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (!openInNewTab && !e.metaKey) {
      e.preventDefault();

      dispatch({
        type: FilterActionType.SetFilter,
        filter,
      });

      checkboxDispatch({
        type: CheckboxActionType.ResetSelectedIds,
      });

      window.scrollTo(0, 0);
    }
  };

  return (
    <ActionRowCell
      id={ActionTableGridArea.Time}
      align={Align.Left}
      disablePointerEvents={disablePointerEvents}
    >
      <ActionRowDateStyled>
        <ActionRowHover style={{ marginLeft: "-0.25rem" }}>
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            sx={{
              fontFamily: tokens.fontFamilies.numeric,
              width: "100%",
            }}
            data-uncensored="true"
          >
            {date}
          </Typography>
          <Tooltip
            title={
              openInNewTab ? lang.txTable.openInNewTab : lang.txTable.search
            }
          >
            <span>
              <StyledIconButton
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  verticalAlign: "middle",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem",
                  padding: 0,
                  marginLeft: "2px",
                  marginTop: "3px",
                }}
                to={dateFilterLink}
                target={openInNewTab && !isEmbedded ? "_blank" : "_self"}
                onClick={handleLinkClick}
              >
                {openInNewTab ? (
                  <OpenInNewIcon fontSize="inherit" />
                ) : (
                  <SearchIcon fontSize="inherit" />
                )}
              </StyledIconButton>
            </span>
          </Tooltip>
        </ActionRowHover>
      </ActionRowDateStyled>
      <ActionRowSecondLineBox>
        <ActionRowTimeStyled>
          <Typography
            variant="IBM Plex Mono/Caption/Small/Regular"
            sx={{
              color: tokens.text.low,
            }}
          >
            {time}
          </Typography>
        </ActionRowTimeStyled>
      </ActionRowSecondLineBox>
    </ActionRowCell>
  );
}

export function ActionHeaderTime() {
  const lang = useLang();
  return (
    <Box style={{ gridArea: ActionTableGridArea.Time }}>
      <ActionHeaderCell
        sort={{
          sortAscending: Sort.DateAscending,
          sortDescending: Sort.DateDescending,
        }}
      >
        {lang.txTable.time}
      </ActionHeaderCell>
    </Box>
  );
}

const ActionRowDateStyled = styled(Box)`
  ${ActionRowFirstLineStyle}

  svg {
    visibility: hidden;
    margin-left: 0.25rem;
  }

  &:hover svg {
    visibility: visible;
  }
`;

export const ActionRowTimeStyled = styled(Box)`
  white-space: nowrap;
`;

const StyledIconButton = styled((props: any) => (
  <TextIconButton component={Link} {...props} />
))``;
