import { useExperimentVariant } from "~/analytics/posthog";
import { FeatureFlag } from "~/types/enums";

/**
 * Hook that returns true if the user is in any of the new IRS paywall variants,
 * and false if they are in control (or if the are in no variant - say the flag
 * is off in posthog, and we want to fall back to control)
 */
export function useIsIrsPaywallModalVariant() {
  const isInControlExperiment = useExperimentVariant(
    FeatureFlag.PaywallModals,
    "control",
  );

  // Check for explicitly false, as if its undefined that means the flag isnt
  // set for the posthog user and we should default to the control
  const isIrsPaywallModalEnabled = isInControlExperiment === false;

  return isIrsPaywallModalEnabled;
}
