import "styled-components/macro";

import { CheckCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { displayTimestamp } from "~/components/report/helpers/displayTimestamp";
import { TertiaryLoadingButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import * as invitationsAPI from "~/services/invitations";
import { invitationKeys, useSupportInvitation } from "~/state/invitations";

export const SupportAccess = () => {
  const queryClient = useQueryClient();
  const lang = useLang();
  const timezone = useTimezone();
  const { tokens } = useDesign();
  const { data: supportInvitation } = useSupportInvitation();
  const [isPending, setIsPending] = useState(false);

  const handleGrantAccess = async () => {
    setIsPending(true);
    const res = await invitationsAPI.createSupportInvitation();
    setIsPending(false);
    if (!res.error) {
      void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
    }
  };

  const handleRevokeAccess = async () => {
    if (!supportInvitation) return;
    setIsPending(true);
    const res = await invitationsAPI.deleteInvitation(supportInvitation._id);
    setIsPending(false);
    if (!res.error) {
      void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
    }
  };

  const hasSupportAccess = !!supportInvitation;

  const buttonText = hasSupportAccess
    ? lang.settings.supportAccess.body.button.revokeAccess
    : lang.settings.supportAccess.body.button.grantAccess;

  const handleButtonClick = hasSupportAccess
    ? handleRevokeAccess
    : handleGrantAccess;

  const accessGrantedAt = supportInvitation?.createdAt
    ? displayTimestamp(supportInvitation.createdAt, timezone, "lll")
    : undefined;

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={1}>
        <Typography variant="Metropolis/Body/Regular">
          {lang.settings.supportAccess.body.title}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
          {lang.settings.supportAccess.body.description}
        </Typography>
      </Box>
      <Box
        css={`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        {!!(hasSupportAccess && accessGrantedAt) && (
          <Box display="flex" alignItems="center">
            <Box pr="0.5rem" pt="0.5rem">
              <CheckCircle
                style={{ color: tokens.icon.success, fontSize: "1.25rem" }}
              />
            </Box>
            <Box>
              <Typography
                variant="Metropolis/Body/Regular"
                color={tokens.text.low}
              >
                {lang.settings.supportAccess.body.accessGrantedAt({
                  accessGrantedAt,
                })}
              </Typography>
            </Box>
          </Box>
        )}
        <TertiaryLoadingButton
          size="small"
          onClick={handleButtonClick}
          disabled={isPending}
          loading={isPending}
        >
          {buttonText}
        </TertiaryLoadingButton>
      </Box>
    </Box>
  );
};
