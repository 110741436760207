import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  DialogTitle,
  InputAdornment,
  TextField,
  type TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { PasswordTextField } from "~/components/ui/PasswordTextField";
import { StyledDialogActions } from "~/components/ui/StyledDialogActions";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useLang } from "~/redux/lang";

export function PasswordField(props: {} & TextFieldProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Box>
      <TextField
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <TextIconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </TextIconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Box>
  );
}

export function DialogHeader({
  onClose,
  children,
}: {
  onClose: () => void;
  children: React.ReactNode;
}) {
  return (
    <DialogTitle>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="0.5rem"
      >
        <Box width="100%" display="flex" alignItems="center" gap="0.5rem">
          {children}
        </Box>
        <TextIconButton aria-label="close" onClick={onClose}>
          <Close />
        </TextIconButton>
      </Box>
    </DialogTitle>
  );
}

export function DialogFooter({
  actionText,
  dataCtc,
  onCancel,
  onSubmit,
  isSubmitting,
}: {
  actionText?: string;
  dataCtc?: string;
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
}) {
  const lang = useLang();
  return (
    <StyledDialogActions>
      <TertiaryButton onClick={onCancel}>{lang.cancel}</TertiaryButton>
      <PrimaryButton
        onClick={onSubmit}
        disabled={isSubmitting}
        data-ctc={dataCtc}
      >
        {isSubmitting ? (
          <CircularProgress size="1.5rem" />
        ) : (
          actionText ?? lang.twoFactorAuth.submit
        )}
      </PrimaryButton>
    </StyledDialogActions>
  );
}

export function PasswordAuthCodeFormContents() {
  const { control } = useFormContext<{
    password: string;
    code: string;
  }>();
  const lang = useLang();
  return (
    <>
      <Controller
        control={control}
        name="password"
        rules={{ required: lang.twoFactorAuth.passwordRequired }}
        render={({ field, fieldState: { invalid, error } }) => (
          <PasswordTextField
            {...field}
            name="password"
            fullWidth
            label={lang.twoFactorAuth.password}
            variant="outlined"
            sx={{
              marginTop: "1rem",
            }}
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="code"
        rules={{
          required: lang.twoFactorAuth.enterCodeOrRecoveryCodeHelperText,
        }}
        render={({ field, fieldState: { invalid, error } }) => (
          <TextField
            {...field}
            name="code"
            fullWidth
            label={lang.twoFactorAuth.authenticationCode}
            variant="outlined"
            sx={{
              marginTop: "1rem",
            }}
            error={!!invalid}
            helperText={lang.twoFactorAuth.enterCodeOrRecoveryCodeHelperText}
          />
        )}
      />
    </>
  );
}
