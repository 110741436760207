import { type Blockchain } from "@ctc/types";

import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { AccountChip } from "~/components/transactions/command-palette/views/change-destination-account/AccountChip";
import { Confirmation } from "~/components/transactions/command-palette/views/change-source-account/Confirmation";
import { getOptionsFromExchangeOptions } from "~/components/transactions/command-palette/views/change-source-account/getOptionsFromExchangeOptions";
import { useLang } from "~/redux/lang";
import { useLoadExchangeOptions } from "~/state/exchanges";

export const ToSourceAccount = ({
  fromParty,
}: {
  fromParty: {
    exchangeId: string;
    displayName: string;
    blockchain: Blockchain | undefined;
    currencySymbol: string;
  };
}) => {
  const { push } = useNavController();
  const {
    data: exchangeOptions,
    isLoading,
    isError,
  } = useLoadExchangeOptions();
  const lang = useLang();

  if (isError) {
    return null;
  }
  // get the from currencies
  const options = getOptionsFromExchangeOptions(
    exchangeOptions ?? [],
    fromParty,
  );

  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.SourceAccount} />
          <AccountChip party={fromParty} />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.toSourceAccount}
      disabled={isLoading}
      loading={isLoading}
      options={options}
      onSelection={({ toParty }) => {
        push(<Confirmation fromParty={fromParty} toParty={toParty} />);
      }}
    />
  );
};
