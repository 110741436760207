import { type Blockchain } from "@ctc/types";
import uniqBy from "lodash/uniqBy";

import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { ToDestinationAccount } from "~/components/transactions/command-palette/views/change-destination-account/ToDestinationAccount";
import { getOptionsFromAccounts } from "~/components/transactions/command-palette/views/change-source-account/getOptionsFromAccounts";
import { formatDisplayAddress } from "~/components/transactions/helpers";
import { useSelectedIds } from "~/hooks/useTransactions";
import { invariant } from "~/lib/invariant";
import { useIsAdressLikeFn } from "~/redux/imports";
import { useLang } from "~/redux/lang";
import { isGroupedTrade } from "~/services/transactions";
import { useGetActionsQuery } from "~/state/actions";
import { GroupedTrade } from "~/types/enums";

export const FromDestinationAccount = () => {
  const { push } = useNavController();
  const lang = useLang();
  const isAddressLike = useIsAdressLikeFn();
  const selectedIds = useSelectedIds();
  const query = useGetActionsQuery();

  // TODO: make this more efficient maybe
  const fromDestinationAccounts = uniqBy(
    query.data?.transactions.flatMap((action) => {
      if (selectedIds.length && !selectedIds.includes(action._id)) {
        return [];
      }

      if (
        isGroupedTrade(action.type) &&
        (action.type === GroupedTrade.Bridge ||
          action.type === GroupedTrade.Transfer)
      ) {
        const tx = action.incoming[0];
        const isAnAddress = isAddressLike(tx.to);
        const isNicknamed = tx.to !== tx.toDisplayName && isAnAddress;
        const finalDisplayName = tx.toDisplayName.trim();
        const displayName = isNicknamed
          ? finalDisplayName
          : formatDisplayAddress(finalDisplayName, isNicknamed, isAnAddress);

        return [
          {
            displayName,
            exchangeId: tx.to,
            blockchain: tx.blockchain as Blockchain | undefined,
            currencySymbol: tx.currencyIdentifier.symbol,
          },
        ];
      }
      // For all types excepts bridges, the "destination" is always
      // where you sent the money to
      // and if you didnt send any many, because its a stand alone "buy"
      // then its the incomings to, and if there is neither of those
      // then it must be where the fee went
      const tx = action.outgoing[0] ?? action.incoming[0] ?? action.fees[0];
      invariant(tx, "Unable to determine `Incoming` from transactions");
      const isAnAddress = isAddressLike(tx.to);
      const isNicknamed = tx.to !== tx.toDisplayName && isAnAddress;
      const finalDisplayName = tx.toDisplayName.trim();
      const displayName = isNicknamed
        ? finalDisplayName
        : formatDisplayAddress(finalDisplayName, isNicknamed, isAnAddress);

      return [
        {
          displayName,
          exchangeId: tx.to,
          blockchain: tx.blockchain as Blockchain | undefined,
          currencySymbol: tx.currencyIdentifier.symbol,
        },
      ];
    }),
    (party) => `${party.exchangeId}__${party.blockchain}`,
  );

  // get the accounts
  const options = getOptionsFromAccounts(fromDestinationAccounts);
  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.DestinationAccount} />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.incomingAccount}
      options={options}
      onSelection={({ party }) => {
        push(<ToDestinationAccount fromParty={party} />);
      }}
    />
  );
};
