import { OAuthProvider } from "@ctc/types";
import type curry from "lodash/curry";
import without from "lodash/without";
import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import {
  onboardingAnalyticsKey,
  reOnboardingAnalyticsKey,
} from "~/analytics/analyticsKeys";
import {
  Flow,
  OnboardingActionType,
  type OnboardingEducationPlatformType,
  type OnboardingEducationTradingType,
  type OnboardingStep,
  UserAccountType,
} from "~/components/onboarding-v2/enums";
import {
  getDefaultPlatformType,
  getInitialFlow,
} from "~/components/onboarding-v2/helpers";
import { type Provider } from "~/components/onboarding-v2/provider/types";
import { LogoSpinner } from "~/components/ui/LogoSpinner";
import { LocalStorageKey } from "~/constants/enums";
import { LocalStorageUserKeyGenerator } from "~/hooks/useLocalStorage";
import { invariant } from "~/lib/invariant";
import { useUser } from "~/redux/auth";
import { type UserInfo } from "~/types/index";

type Action =
  | {
      type: OnboardingActionType.SetFlow;
      flow?: Flow;
      providerId?: Provider;
      isLoadingFlow?: boolean;
      isAutoImportOauth: boolean;
    }
  | {
      type: OnboardingActionType.SetAccount;
      accountType: UserAccountType;
    }
  | {
      type: OnboardingActionType.TogglePlatformType;
      platformType: OnboardingEducationPlatformType;
    }
  | {
      type: OnboardingActionType.SetTradingType;
      tradingTypes: OnboardingEducationTradingType[];
    }
  | {
      type: OnboardingActionType.SetShowFinishImportConfirmationModal;
      showFinishImportConfirmationModal: boolean;
    }
  | {
      type: OnboardingActionType.SeenAreYouSurePage;
    }
  | {
      type: OnboardingActionType.SeenImportCEXReminderPage;
    }
  | {
      type: OnboardingActionType.SeenLetsReviewTxModal;
    }
  | {
      type: OnboardingActionType.SeenFakeLoading;
    }
  | {
      type: OnboardingActionType.SetOnboardingStep;
      onboardingStep: OnboardingStep;
    };

type Dispatch = (action: Action) => void;

type OnboardingState = {
  accountType?: UserAccountType;
  isOnboardingFinish?: boolean;
  flow?: Flow;
  isLoadingFlow?: boolean;
  platformTypes: OnboardingEducationPlatformType[];
  tradingTypes: OnboardingEducationTradingType[];
  showFinishImportConfirmationModal?: boolean;
  isAutoImportOauth: boolean;
  seenAreYouSurePage?: boolean;
  seenImportCEXReminderPage?: boolean;
  seenLetsReviewTxModal?: boolean;
  seenFakeLoading?: boolean;
  onboardingStep?: OnboardingStep;
  providerId?: Provider;
};

type OnboardingProviderProps = {
  children: ReactNode;
  initialState?: Partial<OnboardingState>;
};

const OnboardingStateContext = createContext<
  | {
      state: OnboardingState;
      dispatch: Dispatch;
      educationAnalyticsKey: ReturnType<typeof curry>;
    }
  | undefined
>(undefined);

function onboardingReducer(
  state: OnboardingState,
  action: Action,
): OnboardingState {
  switch (action.type) {
    case OnboardingActionType.SetFlow: {
      return {
        ...state,
        flow: action.flow,
        providerId: action.providerId,
        isLoadingFlow: action.isLoadingFlow,
      };
    }
    case OnboardingActionType.SetAccount: {
      return {
        ...state,
        accountType: action.accountType,
      };
    }
    case OnboardingActionType.TogglePlatformType: {
      if (state.platformTypes.includes(action.platformType)) {
        return {
          ...state,
          platformTypes: without(state.platformTypes, action.platformType),
        };
      }
      return {
        ...state,
        platformTypes: [...state.platformTypes, action.platformType],
      };
    }
    case OnboardingActionType.SetTradingType: {
      return {
        ...state,
        tradingTypes: action.tradingTypes,
      };
    }
    case OnboardingActionType.SetShowFinishImportConfirmationModal: {
      return {
        ...state,
        showFinishImportConfirmationModal:
          action.showFinishImportConfirmationModal,
      };
    }
    case OnboardingActionType.SeenAreYouSurePage: {
      return {
        ...state,
        seenAreYouSurePage: true,
      };
    }
    case OnboardingActionType.SeenImportCEXReminderPage: {
      return {
        ...state,
        seenImportCEXReminderPage: true,
      };
    }
    case OnboardingActionType.SeenLetsReviewTxModal: {
      return {
        ...state,
        seenLetsReviewTxModal: true,
      };
    }
    case OnboardingActionType.SeenFakeLoading: {
      return {
        ...state,
        seenFakeLoading: true,
      };
    }
    case OnboardingActionType.SetOnboardingStep: {
      return {
        ...state,
        onboardingStep: action.onboardingStep,
      };
    }
  }
}

// Keeping this in case we need feature flags
// function useIsCoinbaseFeatureFlagLoading() {
//   // get the feature flag
//   const coinbaseOnboardingEnabled = useFeatureFlagEnabled(
//     FeatureFlag.CoinbaseOnboarding,
//   );
//   const client = usePostHog();

//   // get the variant
//   const coinbaseOnboardingVariant = useFeatureFlagVariantKey(
//     FeatureFlag.CoinbaseOnboarding,
//   );

//   const [isLoading, setIsLoading] = useState(true);

//   const user = useUser();
//   // Only show this flow to coinbase referred users
//   const localReferrerSource = localStorage.getItem(
//     LocalStorageKey.ReferrerSource,
//   );
//   const isCoinbaseUser =
//     localReferrerSource === ReferrerSource.Coinbase ||
//     user?.referrerSource === ReferrerSource.Coinbase;

//   // Setting referrerSource here since we know this from local storage
//   // Posthog might take awhile to realise this user property
//   // therefore incorrectly say that a user isn't in this experiment when they are.
//   // causing FF to go from false to true.  This value will be overridden by posthog
//   if (isCoinbaseUser) {
//     client.setPersonPropertiesForFlags(
//       {
//         referrerSource: "coinbase",
//       },
//       true,
//     );
//   }

//   const isCoinbaseOauthSignup = user?.oAuthProvider === OAuthProvider.Coinbase;

//   const timerRef = useRef<number | undefined>();
//   // Problem: The PostHog user property is not available when we first load the FF
//   // so we need to wait for the user property to be loaded before we can correctly
//   // identify if the user is qualify for the experiment or not
//   useEffect(() => {
//     if (!isCoinbaseUser) {
//       // not a coinbase user, not loading
//       setIsLoading(false);
//       return;
//     }
//     if (isCoinbaseOauthSignup) {
//       // signed up with oauth, so no feature flag for them
//       setIsLoading(false);
//       return;
//     }
//     if (coinbaseOnboardingEnabled === undefined) {
//       // dont do anything
//       // waiting for posthog to load
//       return;
//     }
//     if (coinbaseOnboardingVariant === false) {
//       // when its false, wait 2 more seconds
//       // this is because it might still be updated
//       // and might turn true or oauth/control
//       timerRef.current = window.setTimeout(() => {
//         setIsLoading(false);
//       }, 2000);
//     }
//     if (typeof coinbaseOnboardingVariant === "string") {
//       // its loaded, end the timer
//       setIsLoading(false);
//       window.clearTimeout(timerRef.current);
//       return;
//     }
//     return () => {
//       if (timerRef.current) {
//         window.clearTimeout(timerRef.current);
//       }
//     };
//   }, [
//     coinbaseOnboardingEnabled,
//     coinbaseOnboardingVariant,
//     isCoinbaseOauthSignup,
//     isCoinbaseUser,
//   ]);
//   return isLoading;
// }
export function UserOnboarding({ children }: { children: React.ReactNode }) {
  // This following code is needed only if there's a Feature Flag set up for the onboarding flow
  // When there's a FF, we need to wait for the PostHog user property to be loaded before we can
  // correctly identify if the user is qualify for the experiment or not

  // Hardcoded for now, only needed if we bring back feature flags here.
  // If we do, we need to use useIsCoinbaseFeatureFlagLoading() to determine the loading state.
  const isLoading = false;

  if (isLoading) {
    return <LogoSpinner />;
  }
  return <OnboardingProvider>{children}</OnboardingProvider>;
}
export function OnboardingProvider({ children }: OnboardingProviderProps) {
  const user = useUser();
  invariant(user, "User is required");
  const flowResult = getInitialFlow(user);
  const providerId =
    flowResult.flow === Flow.ProviderOnboarding
      ? flowResult.providerId
      : undefined;
  const flow = flowResult.flow;

  const [state, dispatch] = useReducer(
    onboardingReducer,
    getInitialState({ flow, user, providerId }),
  );

  useEffect(() => {
    localStorage.setItem(
      LocalStorageUserKeyGenerator(user?.uid, LocalStorageKey.OnboardingState),
      JSON.stringify(state),
    );
  }, [state, user?.uid]);
  const educationAnalyticsKey =
    flow === Flow.ReOnboarding
      ? reOnboardingAnalyticsKey
      : onboardingAnalyticsKey;

  const value = useMemo(
    () => ({ state, dispatch, educationAnalyticsKey }),
    [state, dispatch, educationAnalyticsKey],
  );
  return (
    <OnboardingStateContext.Provider value={value}>
      {children}
    </OnboardingStateContext.Provider>
  );
}

function getInitialState({
  flow,
  user,
  providerId,
}: {
  flow: Flow;
  user: UserInfo;
  providerId?: OAuthProvider;
}) {
  const onboardingStateString = localStorage.getItem(
    LocalStorageUserKeyGenerator(user?.uid, LocalStorageKey.OnboardingState),
  );
  const onboardingState = onboardingStateString
    ? JSON.parse(onboardingStateString)
    : undefined;

  if (onboardingState) {
    return onboardingState;
  }
  const isMMSignup = user?.oAuthProvider === OAuthProvider.Moralis;
  return {
    flow,
    platformTypes: getDefaultPlatformType(flow),
    tradingTypes: [],
    isAutoImportOauth: user.oAuthProvider
      ? [OAuthProvider.Coinbase, OAuthProvider.Moralis].includes(
          user.oAuthProvider,
        )
      : false,
    accountType: isMMSignup ? UserAccountType.Individual : undefined,
    onboardingStep: 0,
    providerId,
  };
}

export function useOnboardingContext() {
  const context = useContext(OnboardingStateContext);
  if (context === undefined) {
    throw new Error(
      "useOnboardingContext must be used within a OnboardingProvider",
    );
  }
  return context;
}
