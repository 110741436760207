import { Box, Typography } from "@mui/material";
import { useState } from "react";

import {
  type ImportRelatedChainAddress,
  ImportRelatedWallets,
} from "~/components/imports-v2/ImportRelatedWallets";
import { type RelatedChainsForAddress } from "~/components/imports-v2/types";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useIsMobile } from "~/components/ui/hooks";

import { displayMessage } from "~/components/ui/Toaster";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useAddWalletMutation } from "~/state/wallets";
import { BlockchainName } from "~/types";
import { DisplayMessage, RelatedWalletsAction } from "~/types/enums";

export function ImportRelatedWalletsDialog({
  isOpen,
  handleClose,
  address,
  name,
}: {
  isOpen: boolean;
  handleClose: () => void;
  address: ImportRelatedChainAddress;
  name?: string;
}) {
  const [isNoResults, setNoResults] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const { tokens } = useDesign();
  const lang = useLang();

  const useAddWallet = useAddWalletMutation({ isUsingImportForm: false });

  const [selectedBlockchains, setSelectedBlockchains] =
    useState<RelatedChainsForAddress>({});

  const onSubmit = () => {
    if (!selectedBlockchains[address.address]?.size) return;

    displayMessage({
      message: lang.imports.relatedChains.syncingOnChains({
        wallet: address.address,
        chains: Array.from(selectedBlockchains[address.address]?.values() ?? [])
          .map((chain) => BlockchainName[chain])
          .join(", "),
      }),
      type: DisplayMessage.Info,
    });

    Array.from(selectedBlockchains[address.address]?.values() ?? []).forEach(
      (chain) => {
        useAddWallet.mutate({
          address: address.address,
          type: chain,
          showMessage: false,
          relatedWalletsAction: RelatedWalletsAction.Ignore,
          name,
          importFromTimeMillis: address.importFromTimeMillis,
          importSource: address.importSource,
        });
      },
    );

    handleClose();
  };

  const handleNoImports = () => {
    setSelectedBlockchains((state) => ({
      ...state,
      [address.address]: new Set(),
    }));
    handleClose();
  };

  const hasRelatedChains = selectedBlockchains[address.address]?.size;

  return (
    <GeneralDialog
      title={lang.wallet.importRelatedWallets.title}
      fullWidth
      autoFullScreen
      isOpen={isOpen}
      closeOnClickAway
      handleClose={handleNoImports}
      handleAction={hasRelatedChains ? onSubmit : undefined}
      maxWidth="md"
      actionText={lang.imports.relatedChains.import}
      cancelText={
        hasRelatedChains ? lang.imports.relatedChains.dontImport : undefined
      }
    >
      <Box>
        {isNoResults ? (
          <Box sx={{ textAlign: "center", padding: "1rem" }}>
            <Typography variant={"Metropolis/Body/Regular"}>
              {lang.wallet.importRelatedWallets.noResults}
            </Typography>
          </Box>
        ) : (
          <ImportRelatedWallets
            address={address}
            onClose={handleClose}
            selectedBlockchains={selectedBlockchains}
            setSelectedBlockchains={setSelectedBlockchains}
            name={name}
            onNoResults={() => {
              setNoResults(true);
            }}
          />
        )}
      </Box>
    </GeneralDialog>
  );
}
