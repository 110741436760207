import { Done } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Chip,
  InputBase,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  bindHover,
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { type Dispatch, type SetStateAction, useEffect } from "react";
import * as React from "react";
import { VariableSizeList } from "react-window";
import styled from "styled-components/macro";

import { CommandPaletteIconButton } from "~/components/transactions/command-palette/Buttons";
import { CommandMenuListStyled } from "~/components/transactions/command-palette/CommandMenuList";
import {
  type Option,
  useCommandPaletteMenu,
} from "~/components/transactions/command-palette/hooks/useCommandPaletteMenu";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { NoResults } from "~/components/transactions/command-palette/NoResults";
import { ProgressDivider } from "~/components/transactions/command-palette/ProgressDivider";
import { DividerStyled } from "~/components/transactions/command-palette/views/DividerStyled";
import { useIsMobile } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export type SmallCommandPaletteMenuProps<TOption extends Option> = {
  options: TOption[] | Readonly<TOption[]>;
  selectedOptionLabel: string | undefined; // Label is the only required field of TOption so unfortunately while its not strictly unique its the only thing we can determine selected by
  onSelection: (option: TOption) => void;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  searchAreaRightButton?: React.ReactNode;
  bannerSection?: React.ReactNode;
  footerSection?: React.ReactNode;
  hideSearch?: boolean;
  title?: string;
  customFilter?: (filterText: string) => TOption[];
  listRef?: React.RefObject<VariableSizeList<any>>;
};

// This props are sent to the component when its in hover menu mode
export type HoverMenuProps = {
  onClose: () => void;
  inHoverMenu: boolean;
};

const Row = ({
  index,
  style,
  data,
}: {
  index: number;
  style: any;
  data: {
    // cant get the generic TOption to work with option
    // so having to use any
    option: any;
    isSelectedOption: boolean;
    disabled: boolean | undefined;
    setSelectedIndex: Dispatch<SetStateAction<number>>;
    handleOptionSelect: (option: any, index: number) => void;
    selectedIndex: number;
    usingKeyNav: boolean;
    itemCount: number;
    sectionId?: string;
    sectionLabel?: string;
  }[];
}) => {
  const lang = useLang();
  const rowData = data[index];
  const { option, isSelectedOption } = rowData as {
    option: Option;
    isSelectedOption: boolean;
  };
  const { usingKeyNav, itemCount, sectionId, sectionLabel } = rowData;
  const isSelected = index === rowData.selectedIndex;
  const { tokens } = useDesign();
  const isMobile = useIsMobile();

  const popupState = usePopupState({
    variant: "popover",
    popupId: option.label,
  });

  // This itemCount is use to check if there's a scrollbar
  const rightTooltipOffset =
    itemCount > 6 ? (option.endIcon ? 48 : 26) : option.endIcon ? 32 : 10;

  const triggerProps = bindTrigger(popupState);
  const hoverMenuProps: HoverMenuProps = {
    onClose: () => {
      popupState.close();
    },
    inHoverMenu: true,
  };
  return (
    <Box
      style={{
        ...style,
        paddingTop: "0.5rem",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      }}
    >
      {!isMobile && option.nextView ? (
        <HoverPopover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: 64,
            horizontal: 0,
          }}
          PaperProps={{
            sx: {
              maxWidth: "18rem",
              width: "100%",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: tokens.elevation.medium,
            }}
          >
            {React.cloneElement(
              option.nextView as any,
              // inject the onClose prop
              // so the component can tell us, when to close the popover
              // and the hover menu prop
              hoverMenuProps,
              null,
            )}
          </Box>
        </HoverPopover>
      ) : null}

      <DividerStyled sectionId={sectionId} index={index}>
        {sectionLabel ? (
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            sx={{ color: `${tokens.text.low} !important` }}
          >
            {sectionLabel}
          </Typography>
        ) : null}

        <StyledMenuItem
          {...bindHover(popupState)}
          disabled={rowData.disabled || rowData.option.disabled}
          onClick={(e) => {
            triggerProps.onClick(e);
            rowData.handleOptionSelect(option, index);
          }}
          selected={isSelected}
          sx={{
            ":hover": {
              backgroundColor: usingKeyNav
                ? "transparent"
                : tokens.background.neutral.hover,
              color: usingKeyNav ? tokens.text.low : tokens.text.high,
            },
            "&.Mui-selected:hover": {
              backgroundColor: tokens.background.neutral.pressed,
              color: tokens.text.high,
            },
          }}
        >
          <IconLabel>
            {option.parentLabel ? (
              <>
                {option.icon}
                {option.parentLabel}
                <ChevronRightIcon />
                <LabelChip label={option.label} size="small" />
              </>
            ) : (
              <>
                {option.icon}
                {option.label}
              </>
            )}
          </IconLabel>
          <Stack direction="row" gap="0.5rem" alignItems="center">
            {isSelectedOption ? (
              <Tooltip title={lang.txTable.commandPallet.currentlySelected}>
                <Done
                  sx={{
                    color: tokens.text.low,
                    height: "0.875rem",
                    width: "0.875rem",
                  }}
                />
              </Tooltip>
            ) : null}
            {option.description && !option.nextView ? (
              <Tooltip
                title={option.description}
                placement={isMobile ? "top" : "right"}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: ({ placement }: { placement: any }) => {
                          if (placement === "right") {
                            return [0, rightTooltipOffset];
                          }
                          return [0, 10];
                        },
                      },
                    },
                  ],
                }}
              >
                <InfoOutlinedIcon
                  sx={{
                    color: tokens.text.low,
                    height: "0.875rem",
                    width: "0.875rem",
                  }}
                />
              </Tooltip>
            ) : null}
            {option.endIcon ? option.endIcon : null}
          </Stack>
        </StyledMenuItem>
      </DividerStyled>
    </Box>
  );
};

export const SmallCommandPaletteMenu = <TOption extends Option>({
  options,
  selectedOptionLabel,
  onSelection,
  disabled,
  loading,
  placeholder,
  searchAreaRightButton,
  bannerSection,
  footerSection,
  hideSearch,
  title,
  customFilter,
}: SmallCommandPaletteMenuProps<TOption>) => {
  const { views, pop } = useNavController();
  const { tokens } = useDesign();

  const {
    filteredOptions,
    setIsUsingKeyNav,
    inputSearchProps,
    variableSizeListProps,
    noResultProps,
  } = useCommandPaletteMenu({
    disabled,
    loading,
    onSelection,
    options,
    selectedOptionLabel,
    placeholder,
    itemSize: (option, index, filteredOptions) => {
      const prevOption = filteredOptions[index - 1];
      const sameSection = option.sectionId === prevOption?.sectionId;

      const elementHeight = 32;
      const padding = 0.5 * 16;

      const baseHeight = elementHeight + padding;

      if (sameSection) {
        return baseHeight;
      }
      let height = baseHeight;

      // horizontal line
      if (option.sectionId !== prevOption?.sectionId && index !== 0) {
        height = height + 1 + padding;
      }
      // text label
      if (
        option.sectionLabel &&
        option.sectionLabel !== prevOption?.sectionLabel
      ) {
        height = height + 24;
      }
      return height;
    },
    // TODO there are a bunch of hard coded vars here and above
    // should really be dependent on the screen size
    // and element props
    // also see CommandMenuList:10
    maxMenuHeight: 408,
    customFilter,
  });

  const { ref } = variableSizeListProps;

  // The row heights werent updating properly when recently used was updated in
  // the category selector, so this forces a re-render whenever the options
  // change so that we force recalculate the heights
  useEffect(() => {
    ref.current?.resetAfterIndex(0, true);
  }, [filteredOptions, ref]);

  return (
    <Box>
      {hideSearch ? null : (
        <>
          <TitleContainer>
            <Stack direction="row" gap="0.5rem" alignItems="center">
              {views.length > 1 ? (
                <CommandPaletteIconButton
                  disabled={loading}
                  onClick={() => {
                    pop();
                  }}
                >
                  <ArrowBackIcon fontSize="small" />
                </CommandPaletteIconButton>
              ) : null}
              <InputSearch {...inputSearchProps} />
              {searchAreaRightButton}
            </Stack>
          </TitleContainer>
          <ProgressDivider loading={loading} />
        </>
      )}

      {bannerSection ? (
        <Box width="100%" p="0.5rem" pb="0">
          {bannerSection}
        </Box>
      ) : null}

      <NoResults {...noResultProps} />

      {hideSearch && title ? (
        <TitleBox>
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            sx={{
              color: `${tokens.text.low} !important`,
            }}
          >
            {title}
          </Typography>
        </TitleBox>
      ) : null}

      {filteredOptions.length ? (
        <CommandMenuListStyled
          as="div"
          onMouseMove={() => {
            setIsUsingKeyNav(false);
          }}
        >
          <VariableSizeList {...variableSizeListProps}>{Row}</VariableSizeList>
        </CommandMenuListStyled>
      ) : null}
      {footerSection ? (
        <>
          <ProgressDivider loading={loading} />
          <Box width="100%" p="0.5rem">
            {footerSection}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
  max-height: 2.75rem;
`;

const InputSearch = styled(InputBase)(({ theme }) => ({
  ...theme.mui.typography['Metropolis/Caption/Medium/Regular'],
  fontSize: "0.875rem",
  width: "100%",
  "& .MuiInputBase-input": {
    color: theme.tokens.text.default,
    height: "auto",
    "&::placeholder": {
      color: theme.tokens.text.disabled,
    },
  },
}));

const StyledMenuItem = styled(MenuItem)`
  height: 2rem;
  min-height: 0;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => ({ ...theme.mui.typography['Metropolis/Caption/Medium/Regular'] })};
  color: ${({ theme }) => theme.tokens.text.low};
  padding: 0.5rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.tokens.background.neutral.default};

  &.Mui-selected {
    background-color: ${({ theme }) => theme.tokens.background.neutral.pressed};
    color: ${({ theme }) => theme.tokens.text.default};
  }
`;

const IconLabel = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const LabelChip = styled(Chip)`
  ${({ theme }) => ({ ...theme.mui.typography['Metropolis/Caption/Medium/Regular'] })};
  height: 1rem;
  color: ${({ theme }) => theme.tokens.text.low};
  background-color: ${({ theme }) => theme.tokens.elevation.high};
  ${StyledMenuItem}:hover & {
    color: ${({ theme }) => theme.tokens.text.high};
    background-color: ${({ theme }) => theme.tokens.elevation.highest};
  }
`;

const TitleBox = styled(Box)`
  padding: 0.5rem;
  line-height: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.tokens.border.neutral.medium};
`;
