import { useResolvedBrandStyle } from "~/hooks/useTheme";
import { BrandStyle } from "~/types/enums";

/**
 * Hook to determine if OAuth login is available for the current embedded environment
 * This should be expanded to include which OAuth providers are available when we have the embedded config available in app
 * @returns boolean indicating if OAuth login is available
 */
export function useIsEmbeddedOAuthAvailable(): boolean {
  const style = useResolvedBrandStyle();

  if (style === BrandStyle.MetaMask || style === BrandStyle.CTC) {
    return true;
  }

  return false;
}
