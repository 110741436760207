import { Tooltip, type TooltipProps } from "@mui/material";
import styled from "styled-components/macro";

type EllipsisProps = {
  maxWidth?: number;
  disableMargin?: boolean;
  underline?: boolean;
};

export const Ellipsis = styled.span<EllipsisProps>`
  max-width: ${({ maxWidth }) => maxWidth || 110}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  margin-right: ${({ disableMargin }) => disableMargin && "-0.25rem"};
  ${({ underline }) => underline && "text-decoration: underline dashed"}
`;

export const EllipsisWithTooltip = (
  props: EllipsisProps & { text: string } & Omit<
      TooltipProps,
      "children" | "title"
    >,
) => {
  const { text } = props;
  const { maxWidth, disableMargin, underline } = props;
  const ellipsisProps = { maxWidth, disableMargin, underline };
  return (
    <Tooltip title={text} placement="top">
      <Ellipsis {...ellipsisProps}>{text}</Ellipsis>
    </Tooltip>
  );
};
