import { useDesign } from "~/hooks/useTheme";

export const CoinjarLogo = ({ fillOverride }: { fillOverride?: string }) => {
  const { tokens } = useDesign();
  return (
    <svg
      width="182"
      height="48"
      viewBox="0 0 182 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7121 34.5C33.9898 37.277 30.1961 39 26 39C21.8039 39 18.0102 37.277 15.2878 34.5H36.7121ZM38.9933 31.5H13.0067C11.7305 29.2937 11 26.7322 11 24C11 15.7157 17.7157 9 26 9C34.2843 9 41 15.7157 41 24C41 26.7322 40.2695 29.2937 38.9933 31.5ZM43.7511 27C43.9148 26.0244 44 25.0221 44 24C44 14.0589 35.9411 6 26 6C16.0589 6 8 14.0589 8 24C8 25.0221 8.0852 26.0244 8.24887 27H5L2.75 34.5H11.3781C14.6457 39.0422 19.9774 42 26 42C32.0226 42 37.3543 39.0422 40.6219 34.5H49.25L47 27H43.7511Z"
        fill={fillOverride || tokens.text.high}
      />
      <path
        d="M67.29 14C61.256 14 57 18.27 57 24.164C57 30.058 61.256 34.328 67.29 34.328C70.874 34.328 73.828 32.732 75.564 30.184L72.372 27.888C71.196 29.596 69.432 30.576 67.262 30.576C63.804 30.576 61.088 28.098 61.074 24.164C61.074 20.356 63.706 17.752 67.262 17.752C69.446 17.752 71.224 18.76 72.33 20.454L75.69 18.396C74.094 15.652 70.902 14 67.29 14Z"
        fill={fillOverride || tokens.text.high}
      />
      <path
        d="M87.1766 14C81.1426 14 76.8866 18.312 76.8866 24.164C76.8866 30.016 81.1426 34.328 87.1766 34.328C93.2106 34.328 97.4806 30.016 97.4806 24.164C97.4806 18.312 93.2106 14 87.1766 14ZM87.1766 17.752C90.7886 17.752 93.3926 20.356 93.3926 24.164C93.3926 27.972 90.7886 30.576 87.1766 30.576C83.5786 30.576 80.9606 27.972 80.9606 24.164C80.9606 20.356 83.5786 17.752 87.1766 17.752Z"
        fill={fillOverride || tokens.text.high}
      />
      <path
        d="M104.051 14.364H100.145V33.964H104.051V14.364Z"
        fill={fillOverride || tokens.text.high}
      />
      <path
        d="M125.2 14.364H121.28V27.454L111.326 14.364H107.672V33.964H111.578L111.592 20.874L121.532 33.964H125.2V14.364Z"
        fill={fillOverride || tokens.text.high}
      />
      <path
        d="M140.282 14.364H136.376V26.46C136.376 29.288 135.144 30.576 133.072 30.576C131.266 30.576 130.062 29.736 129.264 28.756L126.996 31.598C128.06 32.9 130.006 34.314 133.254 34.314C137.342 34.314 140.282 31.738 140.282 26.824V14.364Z"
        fill={fillOverride || tokens.text.high}
      />
      <path
        d="M153.465 14.364H148.705L141.075 33.964H145.149L146.857 29.4H155.215L156.923 33.964H161.095L153.465 14.364ZM151.043 18.228L153.871 25.802H148.201L151.043 18.228Z"
        fill={fillOverride || tokens.text.high}
      />
      <path
        d="M162.684 14.364V33.964H166.59V26.166H167.738L173.45 33.964H178.182L172.148 26.11C175.914 25.746 178.154 23.548 178.154 20.328C178.154 16.576 175.648 14.364 170.972 14.364H162.684ZM170.902 17.962C172.974 17.962 174.136 18.704 174.136 20.328C174.136 21.98 173.03 22.764 170.902 22.764H166.59V17.962H170.902Z"
        fill={fillOverride || tokens.text.high}
      />
    </svg>
  );
};
