import { type QueryClient, useQuery } from "@tanstack/react-query";

import { useLang } from "~/redux/lang";
import * as ledgerAPI from "~/services/ledger";
import { type CurrencyIdentifier, type FilterQuery } from "~/types/index";

export const ledgerIssueQueryKeys = {
  ledgerData: (filter?: FilterQuery) => ["ledger-data", filter].filter(Boolean),
  ledgerFilterOptions: (currencyId?: string) =>
    ["ledger-filter-options", currencyId] as const,
};

export const refreshLedgerDataPayload = async (queryClient: QueryClient) => {
  queryClient.invalidateQueries({
    queryKey: ledgerIssueQueryKeys.ledgerData(),
  });
  queryClient.invalidateQueries({
    queryKey: ledgerIssueQueryKeys.ledgerFilterOptions(),
  });
};

export function useGetLedgerDataQuery(
  filter: FilterQuery | undefined,
  ledgerCurrency: CurrencyIdentifier,
) {
  const lang = useLang();
  return useQuery({
    queryKey: ledgerIssueQueryKeys.ledgerData(filter),
    queryFn: async () => {
      if (!ledgerCurrency.id || !filter) return;

      const res = await ledgerAPI.getLedgerData(ledgerCurrency.id, filter);

      if (res.error) {
        throw new Error(res.msg ?? lang.ledger.errors.unableLoadRecon);
      }

      return res.data;
    },
  });
}

export function useGetLedgerFilterOptions(ledgerCurrency: CurrencyIdentifier) {
  const lang = useLang();
  return useQuery({
    queryKey: ledgerIssueQueryKeys.ledgerFilterOptions(ledgerCurrency.id),
    queryFn: async () => {
      const res = await ledgerAPI.getLedgerDataFilterOptions(ledgerCurrency.id);

      if (res.error) {
        throw new Error(res.msg ?? lang.ledger.errors.unableLoadRecon);
      }

      return res.data;
    },
  });
}
