import {
  HelpOutline,
  InfoOutlined,
  KeyboardArrowDown,
  OpenInNew,
  Support,
} from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import React from "react";

import { navButtonAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { ToolbarButton } from "~/components/nav/SideNav";
import { useIsMobile } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { showIntercom } from "~/services/intercom";

export function HelpItem() {
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const lang = useLang();
  const captureAnalytics = useCaptureAnalytics();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "help-popup",
  });
  const onHelpClick = () => {
    captureAnalytics(navButtonAnalyticsKey, {
      button_name: "help",
    });
    window.open("https://help.cryptotaxcalculator.io/", "_blank");
  };

  return isMobile ? (
    <ToolbarButton>
      <Box onClick={popupState.toggle} display="flex" alignItems="center">
        <HelpOutline className="navIcon" sx={{ color: tokens.text.low }} />
        <KeyboardArrowDown />
      </Box>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: "left",
        }}
      >
        <List>
          <HelpDropdownItem
            startIcon={<InfoOutlined style={{ fontSize: "1rem" }} />}
            label={lang.navbar.helpCenter}
            onClick={onHelpClick}
            endIcon={<OpenInNew style={{ fontSize: "1rem" }} />}
          />
          <HelpDropdownItem
            startIcon={<Support style={{ fontSize: "1rem" }} />}
            label={lang.navbar.support}
            onClick={() => {
              captureAnalytics(navButtonAnalyticsKey, {
                button_name: "support",
              });
              showIntercom();
            }}
          />
        </List>
      </Popover>
    </ToolbarButton>
  ) : (
    <Tooltip title={lang.navbar.help}>
      <ToolbarButton onClick={onHelpClick}>
        <HelpOutline className="navIcon" sx={{ color: tokens.text.low }} />
      </ToolbarButton>
    </Tooltip>
  );
}

const HelpDropdownItem = ({
  startIcon,
  label,
  onClick,
  endIcon,
}: {
  startIcon: React.ReactNode;
  label: string;
  onClick: () => void;
  endIcon?: React.ReactNode;
}) => {
  const { tokens } = useDesign();
  return (
    <ListItem
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: "0.5rem",
        color: tokens.text.low,
      }}
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        gap="0.5rem"
        alignItems="center"
      >
        {startIcon}
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          fontSize="0.75rem"
        >
          {label}
        </Typography>
      </Box>
      {endIcon}
    </ListItem>
  );
};
