import { ErrorOutlineOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import styled from "styled-components/macro";

interface ErrorBoxProps {
  title: string;
  description: string;
  component?: React.ReactNode;
}

export function ErrorBox({ title, description, component }: ErrorBoxProps) {
  return (
    <StyledBox>
      <IconBox>
        <ErrorOutlineOutlined color="inherit" />
      </IconBox>
      <Box>
        <Typography variant="Metropolis/Header/H5">{title}</Typography>
        <Typography variant="Metropolis/Body/Light">{description}</Typography>
        {!!component && component}
      </Box>
    </StyledBox>
  );
}

const IconBox = styled(Box)`
  color: ${({ theme }) => theme.tokens.icon.danger};
  margin-right: 0.5rem;
`;

const StyledBox = styled(Box)`
  border-radius: 4px;
  border-left: 0.25rem solid ${({ theme }) => theme.tokens.border.danger};
  padding: 1rem;
  display: flex;
  background-color: ${({ theme }) => theme.tokens.background.danger.default};
`;
