import { Country, Plan as PlanType } from "@ctc/types";
import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";

import { SelectClientType } from "~/components/clients/SelectClientType";
import { useLimitExceeded } from "~/components/payment/helpers";
import { BusinessPlanContainer } from "~/components/plans/BusinessPlanContainer";
import { RetailPlanContainer } from "~/components/plans/RetailPlanContainer";
import { getDefaultPlanType } from "~/components/settings-modal/views/plan/helpers/getDefaultPlanType";
import { PageContents } from "~/components/settings-modal/views/plan/PageContents";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useBusinessPlans, usePlans } from "~/state/plans";
import { ClientType } from "~/types/enums";
import { isBusinessPlanType, type UserInfo } from "~/types/index";

const getDefaultPlanGroup = (
  user?: UserInfo | null,
  selectedPlanType?: PlanType,
) => {
  if (!user) {
    return ClientType.Individual;
  }
  const plan = selectedPlanType ?? user.activeDataSource.paidPlan;

  if (isBusinessPlanType(plan)) {
    return ClientType.Business;
  }

  return ClientType.Individual;
};

export function Plan() {
  const user = useUser();
  const lang = useLang();
  const plansQuery = usePlans();
  const businessPlansQuery = useBusinessPlans();
  const { isLimitedExceeded } = useLimitExceeded();

  // req from shane: hr block is not allowed to see the business plan because they dont have crypto businesses and
  // have been buying it accidentally
  const canSeeBusinessPlan =
    !user?.isWithinEnterprise && user?.country !== Country.India; // business plans are not available in India

  const [selectedPlanType, setSelectedPlanType] = useQueryParam(
    "plan",
    withDefault(
      createEnumParam(Object.values(PlanType)),
      getDefaultPlanType(user, plansQuery?.data, isLimitedExceeded),
    ),
  );

  const [planGroup, _setPlanGroup] = useState<ClientType>(
    getDefaultPlanGroup(user, selectedPlanType),
  );

  const [selectedBusinessPlan, _setSelectedBusinessPlan] = useState<PlanType>(
    getDefaultPlanType(user, plansQuery?.data, isLimitedExceeded, "business"),
  );
  const [selectedRetailPlan, _setSelectedRetailPlan] = useState<PlanType>(
    getDefaultPlanType(user, plansQuery?.data, isLimitedExceeded, "retail"),
  );

  const setPlanGroup = (newPlanGroup: ClientType) => {
    _setPlanGroup(newPlanGroup);
    if (newPlanGroup === ClientType.Business) {
      setSelectedPlanType(selectedBusinessPlan);
    } else {
      setSelectedPlanType(selectedRetailPlan);
    }
  };

  const setSelectedBusinessPlan = (plan: PlanType) => {
    _setSelectedBusinessPlan(plan);
    if (planGroup === ClientType.Business && canSeeBusinessPlan) {
      setSelectedPlanType(plan);
    }
  };

  const setSelectedRetailPlan = (plan: PlanType) => {
    _setSelectedRetailPlan(plan);
    if (planGroup === ClientType.Individual || !canSeeBusinessPlan) {
      setSelectedPlanType(plan);
    }
  };

  return (
    <>
      <SettingsBox title={lang.settings.plan.title}>
        {canSeeBusinessPlan ? (
          <SelectClientType
            selectedClientType={planGroup}
            setSelectedClientType={setPlanGroup}
          />
        ) : null}
        {planGroup === ClientType.Business && canSeeBusinessPlan ? (
          <BusinessPlanContainer
            setSelectedPlanType={setSelectedBusinessPlan}
            selectedPlanType={selectedBusinessPlan}
          />
        ) : (
          <RetailPlanContainer
            setSelectedPlanType={setSelectedRetailPlan}
            selectedPlanType={selectedRetailPlan}
          />
        )}
      </SettingsBox>
      {!(
        (planGroup === ClientType.Individual && plansQuery.isLoading) ||
        (planGroup === ClientType.Business && businessPlansQuery.isLoading)
      ) ? (
        <SettingsBox>
          <PageContents selectedPlanType={selectedPlanType} />
        </SettingsBox>
      ) : (
        <Box
          width="100%"
          height="10rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
