import { createConfig, http, WagmiProvider } from "wagmi";
import { mainnet } from "wagmi/chains";
import { metaMask } from "wagmi/connectors";

import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { getAppKitConfig } from "~/lib/appKit";

const metamaskConfig = createConfig({
  ssr: true,
  chains: [mainnet],
  connectors: [metaMask()],
  transports: {
    [mainnet.id]: http(),
  },
});

export function WagmiWrapper({ children }: { children: React.ReactNode }) {
  const isEmbedded = useIsEmbedded();
  const config = isEmbedded ? metamaskConfig : getAppKitConfig();
  return <WagmiProvider config={config}>{children}</WagmiProvider>;
}
