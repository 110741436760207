import { Country } from "@ctc/types";
import { Box, Stack, Typography } from "@mui/material";


import { useDesign, useResolvedTheme } from "~/hooks/useTheme";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Theme } from "~/types/enums";

export const Partners = () => {
  const lang = useLang();
  const resolvedTheme = useResolvedTheme();
  const { tokens } = useDesign();
  const country = useCountry();

  // the partner list is not related to AU
  if (country === Country.Australia) {
    return null;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          marginTop: "3.5rem",
          paddingBottom: "1.5rem",
          position: "relative",
          overflow: "hidden",
          borderBottom: `1px solid ${tokens.border.neutral.high}`,
          "&::before": {
            content: '""',
            background: tokens.background.accent.purple.medium,
            height: 15,
            width: "60%",
            position: "absolute",
            bottom: -15,
            left: 0,
            right: 0,
            margin: "auto",
            zIndex: -1,
            borderRadius: "100%",
            filter: "blur(30px)",
          },
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          gap="2rem"
          flexWrap="wrap"
          direction="row"
          sx={{
            img: {
              maxHeight: "1.5rem",
            },
          }}
        >
          <Typography
            variant={"Metropolis/Header/H4"}
            sx={{ fontWeight: 500 }}
          >
            {lang.worksWith}
          </Typography>
          {resolvedTheme === Theme.Light ? (
            <>
              <img src="/partner-logos/turbo-light.png" alt="TurboTax" />
              <img src="/partner-logos/tax-act-light.png" alt="TaxAct" />
              <img src="/partner-logos/hr-block-light.png" alt="H&R Block" />
            </>
          ) : (
            <>
              <img src="/partner-logos/turbo-dark.png" alt="TurboTax" />
              <img src="/partner-logos/tax-act-dark.png" alt="TaxAct" />
              <img src="/partner-logos/hr-block-dark.png" alt="H&R Block" />
            </>
          )}
        </Stack>
      </Box>
      <Box mt="1rem">
        <Stack
          alignItems="center"
          justifyContent="center"
          gap="2rem"
          flexWrap="wrap"
          direction="row"
          sx={{
            img: {
              maxHeight: "1.5rem",
            },
          }}
        >
          {resolvedTheme === Theme.Light ? (
            <img src="/partner-logos/trustpilot-light.png" alt="TrustPilot" />
          ) : (
            <img src="/partner-logos/trustpilot-dark.png" alt="TrustPilot" />
          )}
        </Stack>
      </Box>
    </Box>
  );
};
