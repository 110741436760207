import { type PartnerWithEmployeeBenefit } from "@ctc/types";
import { Typography } from "@mui/material";

import { useLang } from "~/redux/lang";

import { REFERRER_CONFIG } from "../payment/referrer-config";
import { TextButton } from "../ui/ui-buttons/TextButton";
import { Header } from "./ui/Header";
import { StyledBox } from "./ui/StyledBox";
import { StyledContainer } from "./ui/StyledContainer";

export function ConfirmEmail({
  partner,
  errorCode,
  setSubmitted,
}: {
  partner: PartnerWithEmployeeBenefit;
  errorCode?: number;
  setSubmitted: (submitted: boolean) => void;
}) {
  const lang = useLang();
  const partnerLabel = REFERRER_CONFIG[partner]?.label ?? partner;

  const getErrorMessage = () => {
    if (!errorCode) return lang.partnerEmployeeBenefits.verifyEmail.success;

    switch (errorCode) {
      case 403:
        return lang.partnerEmployeeBenefits.verifyEmail.error403({
          partner: partnerLabel,
        });
      case 400:
        return lang.partnerEmployeeBenefits.verifyEmail.error400;
      case 500:
        return lang.partnerEmployeeBenefits.verifyEmail.error500;
      default:
        return lang.partnerEmployeeBenefits.verifyEmail.error500;
    }
  };

  return (
    <StyledContainer>
      <Header partner={partner} />
      <StyledBox sx={{ alignItems: "center" }}>
        <Typography variant="Metropolis/Header/H4">
          {lang.partnerEmployeeBenefits.verifyEmail.confirmEmail}
        </Typography>
        <Typography variant="Metropolis/Body/Regular">
          {getErrorMessage()}
        </Typography>
      </StyledBox>
      {errorCode ? (
        <TextButton
          onClick={() => {
            setSubmitted(false);
          }}
        >
          {lang.partnerEmployeeBenefits.verifyEmail.reEnterEmail}
        </TextButton>
      ) : null}
    </StyledContainer>
  );
}
