import { Close } from "@mui/icons-material";
import {
  Box,
  Drawer as MuiDrawer,
  type DrawerProps,
  Typography,
} from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

import { devices } from "~/components/ui/theme/legacy";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";

type CustomDrawerProps = {
  isOpen: boolean;
  onClose: (isSaving: boolean) => void;
  drawerWidth?: string;
  children: React.ReactNode;
  backgroundColor?: string;
} & DrawerProps;

type DrawerTitleProps = {
  title: string;
};

const CloseButton = styled(TextIconButton)`
  margin: 0.5rem;
  height: 2rem;
  width: 2rem;
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 20;
`;

const Wrapper = styled(({ drawerWidth, ...props }) => <div {...props} />)`
  width: 100vw;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.mui.typography.fontFamily};
  position: relative;
  @media ${devices.laptop} {
    width: ${({ drawerWidth }) => drawerWidth};
  }
`;

export const DrawerContent = styled.div`
  padding: 1.5rem;
  background: ${({ theme }) => theme.tokens.elevation.default};
`;

export function DrawerTitle({ title }: DrawerTitleProps) {
  return <Typography variant="Metropolis/Header/H4">{title}</Typography>;
}

export const StyledHeaderContent = styled(DrawerContent)`
  display: flex;
  align-items: center;
  height: 4rem;
  position: fixed;
  background-color: ${({ theme }) => theme.tokens.elevation.default};
  border-bottom: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  width: 100%;
  z-index: 2;
`;

export function Drawer({
  isOpen,
  onClose,
  drawerWidth = "52.5rem",
  children,
  backgroundColor,
  ...drawerProps
}: CustomDrawerProps) {
  const { tokens } = useDesign();
  return (
    <MuiDrawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{ zIndex: 1201 }}
      PaperProps={{
        sx: {
          background: backgroundColor ?? tokens.elevation.default,
          borderLeft: 1,
          borderColor: tokens.border.neutral.default,
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...drawerProps}
    >
      <Box style={{ textAlign: "right" }}>
        <CloseButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClose(false);
          }}
        >
          <Close />
        </CloseButton>
      </Box>
      <Wrapper drawerWidth={drawerWidth}>{children}</Wrapper>
    </MuiDrawer>
  );
}
