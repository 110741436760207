import Box from "@mui/material/Box";

import independentReserveLogoDark from "~/assets/independent-reserve-logo.svg";
import independentReserveLogoLight from "~/assets/independent-reserve-logo-light.svg";
import { useResolvedTheme } from "~/hooks/useTheme";
import { Theme } from "~/types/enums";

export const IndependentReserveLogo = ({
  themeOverride,
  height,
}: {
  themeOverride?: Theme;
  /** Height of the logo in pixels. Will forcefully override any existing styles */
  height?: number;
}) => {
  const resolvedTheme = useResolvedTheme();
  const theme = themeOverride ?? resolvedTheme;
  return (
    <Box
      sx={{
        ...(height && {
          "& img": {
            height: `${height}px !important`,
          },
        }),
      }}
    >
      <img
        src={
          theme === Theme.Light
            ? independentReserveLogoLight
            : independentReserveLogoDark
        }
        alt="Independent Reserve"
        style={{
          width: "170px",
        }}
      />
    </Box>
  );
};
