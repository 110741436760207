import posthog from "posthog-js";
import { useFeatureFlagVariantKey } from "posthog-js/react";

import { useRemoveBEFeatureFlagMutation } from "~/state/featureFlags";
import { FeatureFlag } from "~/types/enums";

import { useCaptureAnalytics } from "../analytics/posthog";
/**
 * Hook to handle removing the US Pro Plan experiment flag and related data
 * @returns {removeHobbyistPlusExperiment} - Function to remove the experiment
 */
export function useRemoveHobbyistPlusExperiment() {
  const removeFeatureFlagMutation = useRemoveBEFeatureFlagMutation();
  const usHobbyistPlusExperimentVariant = useFeatureFlagVariantKey(
    FeatureFlag.USHobbyistPlusExperiment,
  );
  const captureAnalytics = useCaptureAnalytics();

  const removeHobbyistPlusExperiment = () => {
    // If user is not in the experiment, do nothing
    if (!usHobbyistPlusExperimentVariant) {
      return;
    }

    // Set posthog property
    posthog.setPersonProperties({
      "2025InAppUSHobbyistPlusExperiment": "false",
    });

    captureAnalytics(
      `${FeatureFlag.USHobbyistPlusExperiment}:remove_from_experiment`,
    );

    // Remove the feature flag from the BE
    removeFeatureFlagMutation.mutate(FeatureFlag.USHobbyistPlusExperiment);
  };

  return { removeHobbyistPlusExperiment };
}
