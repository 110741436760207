import { Stack } from "@mui/material";
import { type PropsWithChildren } from "react";

export function BulkEditChips({ children }: PropsWithChildren) {
  return (
    <Stack direction="row" gap="0.5rem" alignItems="center" flexWrap="wrap">
      {children}
    </Stack>
  );
}
