import {
  setNotification,
  setNotificationAuthUser,
  useBestUser,
  useUser,
} from "~/redux/auth";
import { type AppThunk } from "~/redux/types";
import {
  putNotifications,
  putNotificationsAuthUser,
} from "~/services/settings";
import { type NotificationKey } from "~/types/index";

export const useIsVisibleNotification = (notificationType: NotificationKey) => {
  const user = useBestUser();
  const { notifications } = user || {};
  // undefined means show banner
  return notifications?.[notificationType] !== false;
};

export const useIsVisibleNotificationDontShowUndefined = (
  notificationType: NotificationKey,
) => {
  const user = useBestUser();
  const { notifications } = user || {};
  // undefined means don't show banner
  return !!notifications?.[notificationType];
};

export const hideBanner =
  (notificationType: NotificationKey): AppThunk =>
  async (dispatch) => {
    dispatch(setNotification(notificationType, false));
    await putNotifications({ [notificationType]: false });
  };

export function useIsVisibleAuthUserFactory() {
  const user = useUser();
  const { notifications } = user || {};
  return (notificationType: NotificationKey) => {
    return notifications?.[notificationType] !== false;
  };
}

export const useIsVisibleNotificationAuthUser = (
  notificationType: NotificationKey,
) => {
  const user = useUser();
  const { notifications } = user || {};
  // undefined means show banner
  return notifications?.[notificationType] !== false;
};

export const hideBannerAuthUser =
  (notificationType: NotificationKey): AppThunk =>
  async (dispatch) => {
    dispatch(setNotificationAuthUser(notificationType, false));
    await putNotificationsAuthUser({ [notificationType]: false });
  };
