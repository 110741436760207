import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PaymentPopupMessage } from "~/components/payment/enums";
import { BroadcastChannels } from "~/hooks/enums";
import { createPopup, type PopupOptions } from "~/lib/popup";
import { useLang } from "~/redux/lang";
import {
  setIsPaymentPopupOpen,
  setPaymentPopup,
  useIsPaymentPopupOpen,
  usePaymentPopupWindow,
} from "~/redux/paymentPopup";
import { Links, PopupTarget } from "~/types/enums";

type PaymentPopupInterface = {
  options: Omit<PopupOptions, "target">;
  onClose?: () => void;
  onPaymentSuccess?: () => void;
  onPaymentCancelled?: () => void;
  onPopupOpenFailed?: () => void;
};

export function usePaymentPopup() {
  const dispatch = useDispatch();
  const isOpen = useIsPaymentPopupOpen();
  const popup = usePaymentPopupWindow();
  const navigate = useNavigate();
  const lang = useLang();

  // Store callbacks in refs to avoid stale closures
  const callbacksRef = useRef<{
    onClose?: () => void;
    onPaymentSuccess?: () => void;
    onPaymentCancelled?: () => void;
  }>({});

  const handlePopupClosed = useCallback(() => {
    callbacksRef.current.onClose?.();
    dispatch(setPaymentPopup(null));
    dispatch(setIsPaymentPopupOpen(false));
  }, [dispatch]);

  const handlePaymentSuccess = useCallback(() => {
    callbacksRef.current.onPaymentSuccess?.();
    // no matter where in app on payment success detected navigate to payment pending
    navigate(Links.PaymentPending);
  }, [navigate]);

  const handleMessage = useCallback(
    (event: MessageEvent<PaymentPopupMessage>) => {
      const { data } = event;
      if (!data) return;

      switch (data) {
        case PaymentPopupMessage.PaymentSuccess:
          handlePaymentSuccess();
          break;
        case PaymentPopupMessage.PaymentCancelled:
          callbacksRef.current.onPaymentCancelled?.();
          break;
      }
    },
    [handlePaymentSuccess],
  );

  const openPopup = useCallback(
    ({
      options,
      onClose,
      onPaymentSuccess,
      onPaymentCancelled,
      onPopupOpenFailed,
    }: PaymentPopupInterface): Window | null => {
      if (isOpen) return null;

      callbacksRef.current = {
        onClose,
        onPaymentSuccess,
        onPaymentCancelled,
      };

      return createPopup({
        channelName: BroadcastChannels.PaymentChannel,
        options: {
          ...options,
          target: PopupTarget.Payment,
        },
        onPopupCreated: (popup: Window) => {
          dispatch(setPaymentPopup(popup));
          dispatch(setIsPaymentPopupOpen(true));
        },
        onPopupClosed: handlePopupClosed,
        onMessage: handleMessage,
        failedToOpenMessage: lang.popup.enablePopups,
        onPopupOpenFailed,
      });
    },
    [dispatch, isOpen, handlePopupClosed, handleMessage, lang],
  );

  const closePopup = useCallback(() => {
    if (popup) {
      popup.close();
      handlePopupClosed();
    }
  }, [popup, handlePopupClosed]);

  return {
    openPopup,
    closePopup,
    isOpen,
  };
}
