import { Blockchain } from "@ctc/types";

import { get, post, put } from "~/services/core";
import { type RelatedWalletsAction } from "~/types/enums";
import {
  isEVMBlockchain,
  type SyncReturnPayload,
  type WalletDetails,
} from "~/types/index";

export const STAKE_ADDRESS_PREFIX = "stake1";

export function storeWallet(
  data: Pick<
    WalletDetails,
    "address" | "name" | "type" | "importFromTimestamp"
  > & {
    relatedChains?: Blockchain[];
    relatedWalletsAction: RelatedWalletsAction;
    isUnspecifiedBlockchain?: boolean;
    notifyUser?: boolean;
    importSource?: string;
  },
) {
  const link = "/wallets";
  return post<WalletDetails>(link, {
    ...data,
  });
}

export function updateWallet(id: string, data: { name?: string }) {
  const link = `/wallets/${id}`;
  return put<{ name?: string }>(link, {
    ...data,
  });
}

export function syncWallet(
  id: string,
  isHardSync: boolean,
  relatedWalletsAction?:
    | RelatedWalletsAction.Notify
    | RelatedWalletsAction.Ignore,
) {
  const link = `/wallets/${id}/sync`;
  return post<SyncReturnPayload>(link, { isHardSync, relatedWalletsAction });
}

export function importRelatedChains(address: string) {
  const link = `/wallets/import-related`;
  return post<void>(link, { address });
}

export function getRelatedChains(
  address: string,
  blockchain: Blockchain,
  options: { relatedChains?: Readonly<Blockchain[]> } = {},
) {
  const params = new URLSearchParams();
  const { relatedChains = [] } = options;

  relatedChains.forEach((chain) => {
    params.append("relatedChains", chain);
  });

  let link = `/wallets/related/${address}/${blockchain}`;
  if (params.size) link += `?${params.toString()}`;
  return get<Blockchain[]>(link);
}

export function updateWalletsName(address: string, name: string) {
  const link = `/wallets/byAddress/${address}`;
  return put<Pick<WalletDetails, "name">>(link, {
    name,
  });
}

export function formatWalletAddress(
  address: string,
  blockchain: Blockchain,
): string {
  switch (blockchain) {
    case Blockchain.Theta:
      return address.toLowerCase();
    default:
      if (isEVMBlockchain(blockchain)) {
        return address.toLowerCase();
      }
      return address;
  }
}

export async function resolveNameServiceName(
  blockchain: Blockchain,
  domain: string,
) {
  const path = `/wallets/${blockchain}/resolve-name?name=${encodeURIComponent(domain)}`;
  return get<{ address: string }>(path);
}
