import { type Trade } from "@ctc/types";

import { ActionDefinitions } from "~/lib/tradeTypeDefinitions";
import { type GroupedTrade } from "~/types/enums";
import { type ActionType } from "~/types/index";

const transferLikeTradeTypes = new Set<Trade | GroupedTrade>();

// Add all transfer-like trade types to the set
// This includes the grouped trade types and their subActionTypes
for (const key in ActionDefinitions) {
  const actionData = ActionDefinitions[key as GroupedTrade];
  if (actionData.isTransferLike) {
    transferLikeTradeTypes.add(key as GroupedTrade);
    for (const tradeType of actionData.subActionTypes) {
      transferLikeTradeTypes.add(tradeType);
    }
  }
}

// Checks if an actionType is a transfer-like trade type
export function isTransferLikeActionType(actionType: ActionType) {
  return transferLikeTradeTypes.has(actionType);
}
