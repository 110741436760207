import { type Plan } from "@ctc/types";

import type { BillableCurrency } from "~/types";

export function getGAItem({
  isAccountant,
  newPlan,
  currency,
  price,
  total,
  currentPlan,
}: {
  isAccountant: boolean;
  newPlan: Plan;
  currency: BillableCurrency;
  price: number;
  total: number;
  currentPlan?: Plan;
}) {
  const userType = isAccountant ? "client" : "personal";
  const itemId = [userType, newPlan, currency].join("__");
  return {
    item_id: itemId, // distinguish plans based on country
    item_name: itemId,
    quantity: 1,
    currency,
    price,
    item_category: userType,
    item_category2: newPlan,
    item_category3: currency,
    discount: price - total, // The monetary discount value associated with the item.
    ...(currentPlan && { item_category4: currentPlan }),
  };
}
