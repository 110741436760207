import {
  LazyLoadImage,
  type LazyLoadImageProps,
} from "react-lazy-load-image-component";

import bananaCalco from "~/components/ui/calco/images/calco-calconana.svg";
import caclocorn from "~/components/ui/calco/images/calco-calocorn.svg";
import calcoCool from "~/components/ui/calco/images/calco-cool.svg";
import finishedCalco from "~/components/ui/calco/images/calco-finished-taxes.svg";
import calcoGift from "~/components/ui/calco/images/calco-gift.svg";
import cowboyCalco from "~/components/ui/calco/images/calco-greetings-02.svg";
import calcoHair from "~/components/ui/calco/images/calco-hair.svg";
import responsibleCalco from "~/components/ui/calco/images/calco-i-pay-my-taxes.svg";
import monopolyCalco from "~/components/ui/calco/images/calco-monopoly-mickey.svg";
import moonCalco from "~/components/ui/calco/images/calco-moon.svg";
import calcoOG2 from "~/components/ui/calco/images/calco-og-2.svg";
import calcoPiggyBack from "~/components/ui/calco/images/calco-piggy.svg";
import calcoPiggyBank from "~/components/ui/calco/images/calco-piggy-bank.svg";
import calcoPlain from "~/components/ui/calco/images/calco-plain.svg";
import referCalco from "~/components/ui/calco/images/calco-refer.svg";
import calcOSht from "~/components/ui/calco/images/calco-shit-my-taxes.svg";
import smartCalco from "~/components/ui/calco/images/calco-smart.svg";
import calcoWizard from "~/components/ui/calco/images/calco-wizard.svg";
import calcoWizardStaff from "~/components/ui/calco/images/calco-wizard-2.svg";
import calcoZen from "~/components/ui/calco/images/calco-zen.svg";
import captainCalco from "~/components/ui/calco/images/captain-calco.svg";
import captainCalcoLeft from "~/components/ui/calco/images/captain-calco-left.svg";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";

const mapping = {
  osht: calcOSht,
  zen: calcoZen,
  moon: moonCalco,
  unicorn: caclocorn,
  plain: calcoPlain,
  captain: captainCalco,
  captainLeft: captainCalcoLeft,
  smart: smartCalco,
  banana: bananaCalco,
  finished: finishedCalco,
  responsible: responsibleCalco,
  monopoly: monopolyCalco,
  refer: referCalco,
  wizard: calcoWizard,
  wizardStaff: calcoWizardStaff,
  OG2: calcoOG2,
  hair: calcoHair,
  piggyBank: calcoPiggyBank,
  piggyBack: calcoPiggyBack,
  cool: calcoCool,
  cowboy: cowboyCalco,
  gift: calcoGift,
};

export type CalcoVariants = keyof typeof mapping;

export const Calco = ({
  type,
  eagerLoad,
  ...rest
}: { type: CalcoVariants; eagerLoad?: boolean } & LazyLoadImageProps) => {
  const isEmbedded = useIsEmbedded();

  if (isEmbedded) {
    return null;
  }

  if (eagerLoad) {
    // Extract valid HTML img attributes
    const {
      alt,
      src,
      style,
      className,
      width,
      height,
      onClick,
      onLoad,
      onError,
      id,
      loading,
    } = {
      alt: type,
      src: mapping[type],
      style: { ...rest.style, alignSelf: "center" },
      ...rest,
    };

    return (
      <img
        alt={alt}
        src={src}
        style={style}
        className={className}
        width={width}
        height={height}
        onClick={onClick}
        onLoad={onLoad}
        onError={onError}
        id={id}
        loading={loading}
      />
    );
  }

  return (
    <LazyLoadImage
      alt={type}
      src={mapping[type]}
      style={{
        ...rest.style,
        alignSelf: "center",
      }}
      effect="blur"
      {...rest}
    />
  );
};
