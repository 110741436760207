import { SyncStatusPlatform } from "@ctc/types";
import {
  Box,
  Skeleton,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  type Theme,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { BannerCommon } from "~/components/ui/BannerCommon";
import { BannerSeverity, FlexDirection } from "~/components/ui/enums";
import { useIsMobile } from "~/components/ui/hooks";
import { solvingNegativeBalancesLink } from "~/constants/constants";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useScreenedFiatValue } from "~/hooks/useScreenedFiatValue";
import { useDesign } from "~/hooks/useTheme";
import { displayCurrency } from "~/lib/index";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { dismissWarningZeroCost, useShowWarningZeroCost } from "~/redux/report";
import { useUserSyncStatus } from "~/state/sync";
import { Links } from "~/types/enums";
import {
  type PayloadZeroCostBasis,
  shouldReplaceCGTWithPNL,
} from "~/types/index";

export function ZeroCostBanner({
  zcbForPeriod,
  sx,
}: {
  zcbForPeriod: PayloadZeroCostBasis;
  sx?: SxProps<Theme>;
}) {
  const { tokens } = useDesign();
  const dispatch = useDispatch();
  const lang = useLang();
  const showWarningZeroCost = useShowWarningZeroCost();
  const country = useCountry();
  const isMobile = useIsMobile();
  const syncStatus = useUserSyncStatus();
  const displayScreenedFiatValue = useScreenedFiatValue();
  const isReportRefreshing = syncStatus === SyncStatusPlatform.Pending;

  const handleDismissWarningZeroCost = () => {
    dispatch(dismissWarningZeroCost());
  };

  const alertPayloadZeroCost: PayloadZeroCostBasis = zcbForPeriod.sort(
    (a, b) => b.gain - a.gain,
  );

  const topFive = alertPayloadZeroCost.slice(0, 5);
  const rest = alertPayloadZeroCost.slice(5);
  const restTotalGain = rest.reduce((acc, curr) => acc + curr.gain, 0);

  const zeroCostBreakdown = topFive.map((line, index) => {
    const path = `${Links.Ledger}/${line._id}`;
    return (
      <>
        <li key={line._id}>
          <Link to={path} style={{ color: tokens.text.brand }}>
            {displayCurrency(line.name)} ({displayCurrency(line.symbol)})
          </Link>
          <span>
            {" "}
            (
            {isReportRefreshing ? (
              <Skeleton sx={{ display: "inline-block", width: "5rem" }} />
            ) : (
              displayScreenedFiatValue(line.gain)
            )}
            )
          </span>
        </li>
        {index === topFive.length - 1 && rest.length !== 0 && (
          <>
            {`...and ${rest.length} other assets`}
            <span>
              {" "}
              (
              {isReportRefreshing ? (
                <Skeleton sx={{ display: "inline-block", width: "5rem" }} />
              ) : (
                displayScreenedFiatValue(restTotalGain)
              )}
              )
            </span>
          </>
        )}
      </>
    );
  });

  const zeroCostTotal = alertPayloadZeroCost.reduce(
    (total, line) => total + line.gain,
    0,
  );

  if (!showWarningZeroCost || !alertPayloadZeroCost.length) return <></>;

  return (
    <Box sx={sx}>
      <BannerCommon
        handleDismiss={handleDismissWarningZeroCost}
        severity={BannerSeverity.Warning}
        flexDirection={FlexDirection.Column}
        title={
          shouldReplaceCGTWithPNL(country)
            ? lang.report.warningZeroCost.titleNoCGT
            : lang.report.warningZeroCost.title
        }
        actions={
          alertPayloadZeroCost.length > 5
            ? [
                {
                  text: lang.report.warningZeroCost.buttonText,
                  popup: () => (
                    <ZeroCostPopoverContents
                      total={zeroCostTotal}
                      items={alertPayloadZeroCost}
                    />
                  ),
                },
              ]
            : undefined
        }
        text={
          <div>
            <Typography variant="Metropolis/Body/Light" gutterBottom>
              {lang.report.warningZeroCost.bodyText1}
              {lang.report.warningZeroCost.bodyValuePreNumber}
              <span style={{ fontWeight: "bold" }}>
                {isReportRefreshing ? (
                  <Skeleton sx={{ display: "inline-block", width: "5rem" }} />
                ) : (
                  displayScreenedFiatValue(zeroCostTotal)
                )}
              </span>
              {lang.report.warningZeroCost.bodyValuePostNumber}
              {lang.report.warningZeroCost.bodyText2}
              <a
                href={solvingNegativeBalancesLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: tokens.text.brand }}
              >
                {lang.learnMore}
              </a>
            </Typography>
            {!isMobile && (
              <Typography variant="Metropolis/Body/Light" component="div">
                <ol style={{ paddingLeft: "1.5rem" }}>{zeroCostBreakdown}</ol>
              </Typography>
            )}
          </div>
        }
      />
    </Box>
  );
}

function ZeroCostPopoverContents({
  total,
  items,
}: {
  total: number;
  items: PayloadZeroCostBasis;
}) {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const displayScreenedFiatValue = useScreenedFiatValue();
  const syncStatus = useUserSyncStatus();
  const isReportRefreshing = syncStatus === SyncStatusPlatform.Pending;

  const zeroCostBreakdown = items.map((line, index) => {
    const path = `${Links.Ledger}/${line._id}`;
    return (
      <TableRow>
        <StyledCell>
          {`${index + 1}. `}
          <StyledLink
            to={path}
            target={isEmbedded ? "_self" : "_blank"}
            rel="noopener noreferrer"
          >
            {line.name} ({line.symbol})
          </StyledLink>
        </StyledCell>
        <StyledCell align="right">
          <span style={{ fontWeight: "bold" }}>
            {isReportRefreshing ? (
              <Skeleton sx={{ display: "inline-block", width: "5rem" }} />
            ) : (
              displayScreenedFiatValue(line.gain)
            )}
          </span>
        </StyledCell>
      </TableRow>
    );
  });

  return (
    <StyledBox>
      <Box mt={1} mx={2} mb={2}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box mt={1}>
            <Typography variant="Metropolis/Header/H5">
              {lang.report.warningZeroCost.innerTitle}
            </Typography>
          </Box>
        </Box>
        <Typography style={{ fontSize: "0.875rem" }}>
          {lang.report.warningZeroCost.bodyText1}
          {lang.report.warningZeroCost.bodyValuePreNumber}
          <span style={{ fontWeight: "bold" }}>
            {isReportRefreshing ? (
              <Skeleton sx={{ display: "inline-block", width: "5rem" }} />
            ) : (
              displayScreenedFiatValue(total)
            )}
          </span>
          {lang.report.warningZeroCost.bodyValuePostNumber}
        </Typography>
      </Box>
      <Box>
        <StyledTable>
          <TableBody>{zeroCostBreakdown}</TableBody>
        </StyledTable>
      </Box>
    </StyledBox>
  );
}

const StyledTable = styled(Table)`
  && {
    margin-bottom: 0.5rem;

    tbody > tr > td {
      border: 0;
    }

    tbody > tr:nth-child(odd) > td {
      background-color: ${({ theme }) =>
        theme.tokens.background.accent.neutral.lowest};
    }
  }
`;

const StyledCell = styled(TableCell)`
  && {
    margin: 0;
    padding-top: 2px;
    padding-bottom: 2px;
  }
`;

const StyledLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.tokens.text.brand};
  }
`;

const StyledBox = styled(Box)`
  && {
    max-height: 25rem;
  }
`;
