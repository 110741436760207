import { useContext } from "react";

import { getMUITheme } from "~/components/ui/theme/mui";
import { getPalette } from "~/components/ui/theme/palette";
import { getTokens } from "~/components/ui/theme/tokens";
import { ThemeContext } from "~/contexts/ThemeContext";

export function useThemeContext() {
  return useContext(ThemeContext);
}

export function useResolvedTheme() {
  return useThemeContext().resolvedTheme;
}

export function useResolvedBrandStyle() {
  return useThemeContext().brandStyle;
}

export function useDesign() {
  const resolvedMode = useResolvedTheme();
  const brandStyle = useResolvedBrandStyle();
  return {
    mui: getMUITheme(brandStyle, resolvedMode),
    tokens: getTokens(brandStyle, resolvedMode),
    palette: getPalette(brandStyle, resolvedMode),
    resolvedMode,
    brandStyle,
  };
}
