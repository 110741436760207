import { Box, Grid } from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useLang } from "~/redux/lang";

interface FilterFooterProps {
  onApply: () => void;
  onReset: () => void;
  onClose: () => void;
  selection: string[] | undefined;
}

export const FilterFooter: React.FC<FilterFooterProps> = ({
  onApply,
  onReset,
  onClose,
  selection,
}) => {
  const lang = useLang();

  // Applies the selected filter and closes the dropdown
  const applyButton = () => {
    onApply();
    onClose();
  };

  return (
    <StyledBox p={1}>
      <Grid container direction="row" justifyContent="flex-end">
        {selection && selection.length !== 0 ? (
          <TextButton size="small" onClick={onReset}>
            {lang.reset}
          </TextButton>
        ) : (
          <TextButton size="small" onClick={onClose}>
            {lang.cancel}
          </TextButton>
        )}
        <Box ml={1}>
          <PrimaryButton size="small" onClick={applyButton}>
            {lang.apply}
          </PrimaryButton>
        </Box>
      </Grid>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  && {
    border-top: ${({ theme }) => theme.tokens.border.neutral.default} 1px solid;
    width: 100%;
  }
`;
