// https://github.com/moment/moment-timezone/issues/647#issuecomment-1093116284
// Note: Will have to import each locale as we add languages
// import "moment-timezone/node_modules/moment/locale/de";
import "moment/locale/de"; // German
import "moment/locale/es"; // Spanish
import "moment/locale/fr"; // French
import "moment/locale/it"; // Italian

import { SupportedLang } from "@ctc/types";
import dateFnsDe from "date-fns/locale/de";
// Provided for react-datepicker
import dateFnsEs from "date-fns/locale/es";
import dateFnsFr from "date-fns/locale/fr";
import dateFnsIt from "date-fns/locale/it";
// the date pickers internally are using moment
// everywhere else in the app we are using moment-timezone
// so we need to import both, and set the locale on both
// since we can't control what the date pickers are using internally
import originalMoment from "moment";
import moment from "moment-timezone";
import { registerLocale } from "react-datepicker";
import merge from "ts-deepmerge";

import { deTyped, enTyped, esTyped, frTyped, itTyped } from "~/lang/lang-typed";
import { lazy } from "~/lib/lazy";
import { LanguageLocales } from "~/types/index";

registerLocale("es", dateFnsEs);
registerLocale("de", dateFnsDe);
registerLocale("fr", dateFnsFr);
registerLocale("it", dateFnsIt);

// this covers the payment stuff we inject at runtime for some reason
export type Translation =
  | typeof eng
  | typeof de
  | typeof es
  | typeof fr
  | typeof it;

const PRODUCTION_LANGUAGES = [
  SupportedLang.En,
  SupportedLang.De,
  SupportedLang.Es,
  SupportedLang.Fr,
  SupportedLang.It,
];

export const eng = enTyped;

export const de = deTyped;

export const es = esTyped;

export const fr = frTyped;

export const it = itTyped;

// Debug "xx" language
const xifier = (obj: any, length: "half" | "same" | "double") =>
  recursiveTranslate(obj, xLanguage, length);

// returns a copy of obj
const recursiveTranslate = (
  obj: any,
  translate: (current: any, length: "half" | "same" | "double") => string,
  length: "half" | "same" | "double",
): any => {
  if (typeof obj === "function") {
    return (...args: any[]) => {
      return translate(obj(...args), length);
    };
  } else if (
    typeof obj === "string" ||
    typeof obj === "number" ||
    typeof obj === "undefined"
  ) {
    return translate(obj, length);
  } else if (typeof obj === "object") {
    const copy: any = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      copy[key] = recursiveTranslate(obj[key], translate, length);
    }
    return copy;
  }
  return obj;
};

const xLanguage = (english: any, length: "half" | "same" | "double") => {
  // Support icon names
  if (
    english === "PersonIcon" ||
    english === "CheckCircleIcon" ||
    english === "true" ||
    english === "false"
  ) {
    return english;
  }

  let out = "";
  const asString = english?.toString() || "";
  for (let i = 0; i < asString.length; i++) {
    if (asString[i] === " ") {
      out += " ";
    } else if (length === "half") {
      if (i % 2 === 0) {
        out += "x";
      }
    } else if (length === "double") {
      out += "xx";
    } else {
      out += "x";
    }
  }
  return out;
};

const xxShort = xifier(eng, "half");
const xx = xifier(eng, "same");
const xxLong = xifier(eng, "double");

export const translationMap: Record<SupportedLang, Translation> = {
  [SupportedLang.En]: eng,
  [SupportedLang.Xx]: merge.withOptions({ mergeArrays: false }, {}, eng, xx),
  [SupportedLang.XxShort]: merge.withOptions(
    { mergeArrays: false },
    {},
    eng,
    xxShort,
  ),
  [SupportedLang.XxLong]: merge.withOptions(
    { mergeArrays: false },
    {},
    eng,
    xxLong,
  ),
  [SupportedLang.De]: de,
  [SupportedLang.Es]: es,
  [SupportedLang.Fr]: fr,
  [SupportedLang.It]: it,
};

function generateStaticTranslationsSet(): Set<string> {
  const staticTranslations = new Set<string>();

  const traverse = (entry: unknown) => {
    if (typeof entry === "string") {
      staticTranslations.add(entry);
    } else if (typeof entry === "object" && entry !== null) {
      for (const value of Object.values(entry)) {
        traverse(value);
      }
    }
  };

  for (const supportedLang of PRODUCTION_LANGUAGES) {
    traverse(translationMap[supportedLang]);
  }

  return staticTranslations;
}

// Create the set of static translations for all languages
export const staticTranslationsSet = lazy(generateStaticTranslationsSet);

export function setMomentLocale(lang: SupportedLang) {
  const locale = LanguageLocales[lang];
  // set the original moment locale
  // this is used internally by the date picker components
  // so we have to set the locale there
  originalMoment.locale(locale);
  // used everywhere else in our code
  moment.locale(locale);
  moment.localeData(locale);
}
