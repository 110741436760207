import { ArrowForward, Check } from "@mui/icons-material";
import { Box, type BoxProps, Typography } from "@mui/material";
import { type ReactNode } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components/macro";

import { useExperimentVariant } from "~/analytics/posthog";
import { OnboardingPageActionType } from "~/components/onboarding-v2/enums";
import { usePageNavigation } from "~/components/onboarding-v2/helpers";
import preview from "~/components/onboarding-v2/images/metamask-taxhub-preview.png";
import { OnBoardingWindow } from "~/components/onboarding-v2/ui/OnBoardingWindow";
import metamaskLight from "~/components/ui/images/SVG_MetaMask_Horizontal_black.svg";
import metamaskDark from "~/components/ui/images/SVG_MetaMask_Horizontal_White.svg";
import { Partnership } from "~/components/ui/Partnership";
import { devices } from "~/components/ui/theme/legacy";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { MetamaskWalletAuthButton } from "~/components/user/components/MetamaskWalletAuthButton";
import { WagmiWrapper } from "~/components/user/wallet-auth/WagmiProvider";
import { useIsEmbeddedOAuthAvailable } from "~/hooks/useIsEmbeddedOAuthAvailable";
import { useDesign } from "~/hooks/useTheme";
import { useWalletOAuthLogin } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { FeatureFlag } from "~/types/enums";

export function MetamaskOnboardingOfferIntro() {
  const { navigateTo } = usePageNavigation();

  return (
    <MetamaskOfferIntro
      onConfirm={() => {
        navigateTo(OnboardingPageActionType.Next);
      }}
    />
  );
}

export function MetamaskOfferIntro({
  showMetamaskSignup = false,
  onConfirm,
}: {
  showMetamaskSignup?: boolean;
  onConfirm: () => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();

  const preSignupNewText = useExperimentVariant(
    FeatureFlag.TaxHubOfferPreSignupWithMetamask,
    "new_copy",
  );

  return (
    <WagmiWrapper>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ position: "relative", zIndex: 1 }}
      >
        <OnBoardingWindow
          width="100%"
          maxWidth="58rem"
          overflow="hidden"
          containerProps={{ p: 0 }}
          p="0"
          sx={{ minWidth: 0 }}
        >
          <Box
            width="100%"
            maxWidth="56.5rem"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            sx={{ minWidth: 0 }}
            mt={{ xs: "1rem", md: "1.5rem" }}
            ml={{ xs: "1rem", md: "1.5rem" }}
          >
            <Box width="100%">
              <Partnership
                partnerSrcLight={metamaskLight}
                partnerSrcDark={metamaskDark}
                partnerAlt="Metamask"
              />
            </Box>
            <GridBox>
              <Box
                width="100%"
                mt="1.5rem"
                pb={{ xs: "1rem", md: "1.5rem" }}
                pr={{ xs: "1rem", md: "1.5rem" }}
                display="flex"
                flexDirection="column"
                gap="0.5rem"
              >
                {preSignupNewText ? (
                  <VariantTwoContents />
                ) : (
                  <VariantOneContents />
                )}
                <Box>
                  <CTASection
                    showMetamaskSignup={showMetamaskSignup}
                    onConfirm={onConfirm}
                  />
                </Box>
              </Box>
              <Box width="100%" overflow="hidden">
                <Box width="100%" position="relative" height={0}>
                  <LazyLoadImage
                    src={preview}
                    alt={lang.onboardingV2.metamask.previewAlt}
                    effect="blur"
                    // images doesnt work if height isn't defined for some reason
                    height="527px"
                    width="700px"
                    style={{
                      position: "absolute",
                      left: -20,
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            </GridBox>
          </Box>
        </OnBoardingWindow>
        {!preSignupNewText ? (
          <Box
            width="95vw"
            maxWidth="58rem"
            display="flex"
            mt="0.5rem"
            ml="2rem"
          >
            <Typography
              variant="Metropolis/Caption/Small/Regular"
              color={tokens.text.low}
            >
              {lang.onboardingV2.metamask.disclaimer}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </WagmiWrapper>
  );
}

const GridBox = styled(Box)`
  && {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 0;
    border-radius: 0 0 1.5rem 0;

    @media ${devices.tablet} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

function CheckRow({ children, ...props }: { children: ReactNode } & BoxProps) {
  const { tokens } = useDesign();

  return (
    <Box display="flex" gap="0.75rem" {...props} alignItems="center">
      <Check sx={{ fontSize: "1rem", color: tokens.icon.success }} />
      <Typography variant="Metropolis/Body/Regular">{children}</Typography>
    </Box>
  );
}

function CTASection({
  showMetamaskSignup = false,
  onConfirm,
}: {
  showMetamaskSignup?: boolean;
  onConfirm: () => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const isEmbeddedOAuthAvailable = useIsEmbeddedOAuthAvailable();
  const { isLoading: isLoadingWalletAuth } = useWalletOAuthLogin();

  if (isEmbeddedOAuthAvailable && showMetamaskSignup) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap="0.5rem"
        width="fit-content"
        mt="0.5rem"
      >
        <MetamaskWalletAuthButton
          isLoading={isLoadingWalletAuth}
          text={lang.onboardingV2.metamask.continueWith({
            provider: lang.auth.metamask,
          })}
          rightIcon
        />
        <TextButton onClick={onConfirm} endIcon={<ArrowForward />}>
          {lang.onboardingV2.metamask.skip}
        </TextButton>
      </Box>
    );
  }

  return (
    <PrimaryButton
      onClick={onConfirm}
      sx={{ mt: "0.5rem" }}
      endIcon={<ArrowForward sx={{ color: "inherit", fontSize: "1rem" }} />}
    >
      {lang.onboardingV2.metamask.cta}
    </PrimaryButton>
  );
}

function VariantOneContents() {
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <>
      <Box>
        <Typography variant="Metropolis/Header/H1" color={tokens.text.high}>
          {lang.onboardingV2.metamask.taxhubPoweredBy}
        </Typography>
        <Typography variant="Metropolis/Header/H1" color={tokens.text.high}>
          {lang.onboardingV2.metamask.cryptoTaxCalculator}
        </Typography>
      </Box>
      <Typography variant="Metropolis/Body/Regular">
        {lang.onboardingV2.metamask.manageCryptoTaxes}
      </Typography>
      <CheckRow mt="0.5rem">
        {lang.onboardingV2.metamask.accurateCpaEndorsed}
      </CheckRow>
      <CheckRow mt="0.5rem">
        {lang.onboardingV2.metamask.fullSupportIRS}
      </CheckRow>
      <CheckRow mt="0.5rem">
        {lang.onboardingV2.metamask.supportsAllWallets}
      </CheckRow>
      <CheckRow mt="0.5rem">
        {lang.onboardingV2.metamask.newCustomersDiscount}
      </CheckRow>
    </>
  );
}

function VariantTwoContents() {
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <>
      <Box>
        <Typography
          variant="Metropolis/Header/H1"
          color={tokens.text.high}
          sx={{ whiteSpace: "pre-wrap" }}
        >
          {lang.onboardingV2.metamask.accurateCryptoTaxes}
        </Typography>
      </Box>
      <Typography variant="Metropolis/Body/Regular">
        {lang.onboardingV2.metamask.manageCryptoTaxes}
      </Typography>
      <CheckRow mt="0.5rem">
        {lang.onboardingV2.metamask.accurateCpaEndorsed}
      </CheckRow>
      <CheckRow mt="0.5rem">
        {lang.onboardingV2.metamask.fullSupportIRS}
      </CheckRow>
      <CheckRow mt="0.5rem">
        {lang.onboardingV2.metamask.supportsAllWallets}
      </CheckRow>
    </>
  );
}
