import { SyncStatusPlatform } from "@ctc/types";
import { HourglassBottom, OpenInNew, SyncDisabled } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SyncIcon from "@mui/icons-material/Sync";
import { CircularProgress, Link, Tooltip, Typography } from "@mui/material";
import type * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import { ERPProvider } from "~/components/settings-modal/views/enums";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";
import { useErpLang, useLang } from "~/redux/lang";
import { isUncategorisedTrade } from "~/services/transactions";
import { useSyncToErpMutation } from "~/state/actions";
import { useErpSettingsQuery, useIsErpReadyToSync } from "~/state/erp";
import { useUserSyncStatus } from "~/state/sync";
import { ErpSyncStatus, Links } from "~/types/enums";
import { type ActionRow } from "~/types/index";

const erpProviderLink: Record<ERPProvider, (journalId: string) => string> = {
  [ERPProvider.Qbo]: (journalId) =>
    `https://app.qbo.intuit.com/app/journal?txnId=${journalId}`,
  [ERPProvider.Xero]: (journalId) =>
    `https://go.xero.com/Journal/View.aspx?invoiceID=${journalId}`,
};

/**
 * Button for Pushing Action to ERP
 * Indicates if the action has been pushed to ERP or not.
 * @returns
 */
export function ActionRowErpPushStatusButton({ row }: { row: ActionRow }) {
  const lang = useLang();
  const erpQuery = useErpSettingsQuery();
  const syncToErpMutation = useSyncToErpMutation();
  const canSync = useIsErpReadyToSync();
  const erpLang = useErpLang(erpQuery.data?.erp);
  const reportStatus = useUserSyncStatus();
  const isReportRefreshing = reportStatus === SyncStatusPlatform.Pending;
  const isReportFailed = reportStatus === SyncStatusPlatform.Fail;

  if (!erpQuery.data) {
    // ERP not connected
    return null;
  }

  const erpProvider = erpQuery.data.erp;

  const status = row.erpSyncStatus ?? ErpSyncStatus.SyncNotRequested;

  // get one of the journal links from the transactions
  // @todo - consider edge case if they have different journal ids
  const journalId = [...row.incoming, ...row.outgoing, ...row.fees].find(
    (tx) => tx.erp.journalId,
  )?.erp?.journalId;

  // get the link to the journal
  const journalLink =
    journalId && erpProvider ? erpProviderLink[erpProvider](journalId) : null;

  // write a function to handle the sync
  const handleSync = async (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (erpQuery.data) {
      await syncToErpMutation.mutateAsync({
        actionId: row._id,
        erp: erpProvider,
      });
    }
  };

  const isButtonDisabled =
    [
      ErpSyncStatus.SyncInProgress,
      ErpSyncStatus.SyncSuccessful,
      ErpSyncStatus.SyncNoOp,
      ErpSyncStatus.NotReadyToSync,
    ].includes(status) ||
    !canSync ||
    isReportRefreshing ||
    isReportFailed;

  const linkStyles = {
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: "1rem",
    display: "inline-flex",
    alignItems: "center",
  };

  const TooltipContent = () => {
    const renderTooltipLink = (
      href: string,
      external = false,
      label: string,
    ) => (
      <Link
        component={external ? "a" : RouterLink}
        href={external ? href : undefined}
        to={external ? undefined : href}
        target={external ? "_blank" : undefined}
        underline="hover"
        variant="inherit"
        style={{
          paddingLeft: "0.25rem",
          ...linkStyles,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {label}
        <OpenInNew
          sx={{ fontSize: "1.125rem", pl: label ? "0.25rem" : undefined }}
        />
      </Link>
    );

    const {
      text,
      link,
      // onclick: onClick,
    } = (() => {
      if (isReportFailed) {
        return {
          text: lang.txTable.txRow.erp.reportFailed,
          link: null,
        };
      }

      if (isReportRefreshing) {
        return {
          text: lang.txTable.txRow.erp.waitForCalculationsToFinish,
          link: null,
        };
      }

      if (!canSync) {
        return {
          text: lang.txTable.txRow.erp.provideDefaultAccountMappings,
          link: renderTooltipLink(Links.AccountingIntegrations, false, ""),
          // onclick: null,
        };
      }
      // this is a bit of a hack, but the status can be not ready for 2 reason
      // if uncategorised
      // or if the  mappings are not done
      if (isUncategorisedTrade(row.type)) {
        return {
          text: lang.txTable.txRow.erp.categoriseToSync,
          link: null,
          // onclick: null, // todo open categorisation drawer
        };
      }

      switch (status) {
        case ErpSyncStatus.NotReadyToSync: {
          if (
            row.warnings?.length &&
            erpQuery?.data?.txWithWarningsNotReadyToSync
          ) {
            return {
              text: lang.txTable.txRow.erp.resolveAllWarnings,
              link: null,
            };
          }

          // its not uncategorised so if the status is not ready to sync, then it must be the mappings
          return {
            text: lang.txTable.txRow.erp.mapAllAccounts,
            link: null,
            // onclick: () => {
            //   setBreakdownDefaultTab(BreakdownTab.ChartOfAccounts);
            //   setBreakdownOpenAndTabStatus(getActionRowKey(row), {
            //     isOpen: true,
            //     tab: BreakdownTab.ChartOfAccounts,
            //   });
            // },
          };
        }
        case ErpSyncStatus.SyncInProgress: {
          return {
            text: lang.txTable.txRow.erp.status.SYNC_IN_PROGRESS({
              erpProvider: erpLang.integrationShortName,
            }),
            link: null,
          };
        }
        case ErpSyncStatus.SyncFailed: {
          return {
            text: lang.txTable.txRow.erp.status.SYNC_FAILED({
              erpProvider: erpLang.integrationShortName,
            }),
            link: journalLink
              ? renderTooltipLink(
                  journalLink,
                  true,
                  lang.txTable.txRow.erp.viewPreviousJournal,
                )
              : null,
          };
        }
        default:
          return {
            text: lang.txTable.txRow.erp.status[status]({
              erpProvider: erpLang.integrationShortName,
            }),
            link: journalLink
              ? renderTooltipLink(
                  journalLink,
                  true,
                  lang.txTable.txRow.erp.viewJournal,
                )
              : null,
          };
      }
    })();

    return (
      <Typography variant="inherit" style={linkStyles}>
        {text}
        {link}
      </Typography>
    );
  };
  return (
    <Tooltip title={<TooltipContent />} placement="top">
      <span>
        <TextIconButton
          size="small"
          onClick={handleSync}
          disabled={isButtonDisabled}
        >
          <SyncStatusIcon
            status={status}
            canSync={!!canSync}
            isReportRefreshing={isReportRefreshing}
            isReportFailed={isReportFailed}
          />
        </TextIconButton>
      </span>
    </Tooltip>
  );
}

function SyncStatusIcon({
  status,
  canSync,
  isReportRefreshing,
  isReportFailed,
}: {
  status: ErpSyncStatus;
  canSync: boolean;
  isReportRefreshing: boolean;
  isReportFailed: boolean;
}) {
  const { tokens } = useDesign();
  if (isReportFailed) {
    return (
      <SyncDisabled
        fontSize="small"
        style={{
          color: tokens.icon.disabled,
        }}
      />
    );
  }
  if (isReportRefreshing) {
    return (
      <HourglassBottom fontSize="small" sx={{ color: tokens.icon.disabled }} />
    );
  }

  if (!canSync) {
    return (
      <SyncDisabled
        fontSize="small"
        style={{
          color: tokens.icon.disabled,
        }}
      />
    );
  }
  switch (status) {
    case ErpSyncStatus.NotReadyToSync: {
      return (
        <SyncDisabled
          fontSize="small"
          style={{
            color: tokens.icon.disabled,
          }}
        />
      );
    }
    case ErpSyncStatus.SyncFailed: {
      return (
        <SyncIcon
          fontSize="small"
          style={{
            color: tokens.icon.danger,
          }}
        />
      );
    }
    case ErpSyncStatus.SyncNotRequested: {
      return (
        <SyncIcon
          fontSize="small"
          style={{
            color: tokens.icon.low,
          }}
        />
      );
    }
    case ErpSyncStatus.SyncSuccessful:
    case ErpSyncStatus.SyncNoOp: {
      return (
        <CheckCircleOutlineIcon
          fontSize="small"
          style={{
            color: tokens.icon.success,
          }}
        />
      );
    }
    case ErpSyncStatus.SyncOutOfDate: {
      return (
        <SyncIcon
          fontSize="small"
          style={{
            color: tokens.icon.warning,
          }}
        />
      );
    }
    case ErpSyncStatus.SyncInProgress: {
      return (
        <CircularProgress
          size="20px" // so it is aligned with the icons above
          style={{
            color: tokens.icon.disabled,
          }}
        />
      );
    }
    default: {
      const exhaustiveCheck: never = status;
      throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
  }
}
