import "react-lazy-load-image-component/src/effects/blur.css";

import { Box } from "@mui/material";

import { LazyLoadImageWithFallback } from "~/components/transactions/ImageWithFallback";
import { getBlockchainLogoSrc } from "~/components/ui/ExchangeLogo";

const DEFAULT_LOGO_DIMENSION = 24;
const DEFAULT_LOGO_MARGIN = "0.5rem";

export function BlockchainLogo({
  blockchain,
  className,
  width,
  height,
  margin,
}: {
  blockchain?: string;
  className?: string;
  height?: number;
  width?: number;
  margin?: string;
}) {
  const logoWidth = width || DEFAULT_LOGO_DIMENSION;
  const logoHeight = height || DEFAULT_LOGO_DIMENSION;
  const logoMargin = margin || DEFAULT_LOGO_MARGIN;
  const blockchainSrc = getBlockchainLogoSrc(blockchain);
  return (
    <Box
      className={className}
      margin={logoMargin}
      height={logoHeight}
      display="inline-flex"
      position="relative"
    >
      <LazyLoadImageWithFallback
        height={logoHeight}
        width={logoWidth}
        src={blockchainSrc}
        alt={`${blockchain || "CTC"}-logo`}
        effect="blur"
        style={{ borderRadius: logoWidth / 2 }}
      />
    </Box>
  );
}
