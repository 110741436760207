import { Blockchain } from "@ctc/types";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import styled from "styled-components/macro";

import { BlockchainComboBox } from "~/components/transactions/BlockchainComboBox";
import { WalletImage } from "~/components/transactions/ManageEntityDialog";
import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { CensoredTypography } from "~/components/ui/CensoredComponents";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import {
  useAtomicUpdateTransactions,
  useNewBulkIgnoreWarningsMutation,
} from "~/state/actions";
import { FilterOperators, Warning } from "~/types/enums";

const isBlockchain = (v: Blockchain | string | null): v is Blockchain => {
  return !!v && Object.values(Blockchain).includes(v as Blockchain);
};

export function UpdateBlockchainActionDialog({
  txId,
  rowId,
  isOpen,
  handleClose,
}: {
  txId: string;
  rowId: string;
  isOpen: boolean;
  handleClose: () => void;
}) {
  const [blockchainValue, setBlockchainValue] = useState<
    Blockchain | string | null
  >(null);

  const updateTransactionsMutation = useAtomicUpdateTransactions();
  const bulkIgnoreWarningsMutation = useNewBulkIgnoreWarningsMutation();
  const isLoading =
    updateTransactionsMutation.isPending ||
    bulkIgnoreWarningsMutation.isPending;

  const handleIgnore = () => {
    bulkIgnoreWarningsMutation.mutate(
      {
        filter: {
          type: FilterOperators.ActionId,
          value: [rowId],
        },
        warnings: [Warning.MissingBlockchain],
      },
      {
        onSuccess: () => {
          setBlockchainValue(null);
          handleClose();
        },
      },
    );
  };

  const handleSubmit = () => {
    if (isBlockchain(blockchainValue)) {
      updateTransactionsMutation.mutate(
        {
          actionId: rowId,
          update: {
            updateTx: {
              payload: [
                {
                  _id: txId,
                  blockchain: blockchainValue,
                },
              ],
              applySts: false,
              createCategoryRule: false,
            },
          },
        },
        {
          onSuccess: () => {
            setBlockchainValue(null);
            handleClose();
          },
        },
      );
    }
  };

  return (
    <UpdateBlockchainDialogContents
      source={txId}
      isOpen={isOpen}
      blockchainValue={blockchainValue}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleIgnore={handleIgnore}
      setBlockchainValue={setBlockchainValue}
      isLoading={isLoading}
    />
  );
}

export function UpdateBlockchainDialogContents({
  source,
  isOpen,
  blockchainValue,
  handleClose,
  handleSubmit,
  handleIgnore,
  setBlockchainValue,
  isLoading,
}: {
  source: string;
  isOpen: boolean;
  blockchainValue: Blockchain | string | null;
  handleClose: () => void;
  handleSubmit: () => void;
  handleIgnore?: () => void;
  setBlockchainValue: (value: Blockchain | string | null) => void;
  isLoading?: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang();

  const ignoreButton = handleIgnore ? (
    <TextButton onClick={handleIgnore}>
      {lang.reconciliation.ignoreWarning}
    </TextButton>
  ) : undefined;

  return (
    <GeneralDialog
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth
      maxWidth="xs"
      leftButton={ignoreButton}
      title={lang.txTable.assignMissingBlockchain}
      handleAction={handleSubmit}
      actionText={lang.update}
      cancelText={lang.cancel}
      disableAction={!blockchainValue}
      stopPropagation
      pending={isLoading}
    >
      <Box>
        <TxIdBox>
          <WalletImage address={source} />
          <CensoredTypography
            sx={{ fontSize: "0.75rem", fontWeight: 500, display: "inline" }}
            noWrap
          >
            {source}
          </CensoredTypography>
          <CopyIconButton contentToCopy={source} color={tokens.icon.default} />
        </TxIdBox>
      </Box>
      <Box mt="1rem">
        <BlockchainComboBox
          id="blockchain-combo-box"
          label={lang.editTx.blockchain}
          value={blockchainValue || null}
          placeholder={lang.editTx.blockchainPlaceholder}
          setValue={(value) => {
            // Allow falsy or valid blockchains only.
            if (!value || isBlockchain(value)) {
              setBlockchainValue(value);
            }
          }}
          error={blockchainValue === null}
          helperText={
            blockchainValue === null ? lang.editTx.mustHaveBlockchain : ""
          }
          inputProps={{ "data-hj-allow": true }}
        />
        <Typography sx={{ fontSize: "0.75rem", fontWeight: 500, pt: "0.5rem" }}>
          {lang.editTx.willSetBlockchain}
        </Typography>
      </Box>
    </GeneralDialog>
  );
}

const TxIdBox = styled(Box)`
  & {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 4px;
    color: ${({ theme }) => theme.tokens.text.default};
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.neutral.lowest};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
