import { type EntityAddressQuery } from "@ctc/types";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SubdirectoryArrowRightOutlinedIcon from "@mui/icons-material/SubdirectoryArrowRightOutlined";
import { Box, Chip, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";

import {
  useIconButtonSuccessButton,
  useSuccessButton,
} from "~/components/reconciliation/hooks";
import { CensoredEllipsisAddress } from "~/components/transactions/CensoredEllipsisAddress";
import { getAddressLink } from "~/components/transactions/helpers";
import { WalletImage } from "~/components/transactions/ManageEntityDialog";
import { DisplayExchangeActionItems } from "~/components/ui/DisplayExchangeActionItems";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useAutoAnimate } from "~/hooks/useAutoAnimate";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import {
  matchAddressWithEntityAddress,
  useAddAddressToEntityMutation,
  useChangeEntityDisplayNameMutation,
  useRemoveAddressFromEntityMutation,
} from "~/state/entities";
import { EntityType } from "~/types/enums";
import {
  BlockchainName,
  DefaultBlockchainCurrency,
  type Entity,
} from "~/types/index";

export type EntityAddressesProps = {
  isTreeView?: boolean;
  entity: Entity;
  currentAddress?: EntityAddressQuery;
};

export function EntityAddress({
  entity,
  address,
  isNew,
  isTreeView,
  canRemove,
  isCurrentAddress,
  onLastRemoved,
}: {
  entity: Entity;
  address: EntityAddressQuery;
  isTreeView: boolean;
  isNew?: boolean;
  canRemove?: boolean;
  isCurrentAddress?: boolean;
  onLastRemoved?: () => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();

  // add address to entity
  const addAddressToEntityMutation = useAddAddressToEntityMutation();
  const { getButtonProps } = useSuccessButton(addAddressToEntityMutation);

  // remove address from entity
  const removeAddressFromEntityMutation = useRemoveAddressFromEntityMutation();
  const { getButtonProps: getDeleteButtonProps } = useIconButtonSuccessButton(
    removeAddressFromEntityMutation,
  );
  // the address link
  const addressLink = getAddressLink(address.address, undefined, undefined);
  return (
    <Stack
      flexWrap="wrap"
      direction="row"
      alignItems="center"
      sx={{
        padding: "0.25rem 1rem",
        background: !isNew ? undefined : tokens.background.neutral.default,
        width: "100%",
        "& + &": {
          borderTop: `1px solid ${tokens.border.neutral.low}`,
        },
      }}
      spacing="0.75rem"
    >
      {isTreeView ? (
        <SubdirectoryArrowRightOutlinedIcon
          fontSize="small"
          sx={{
            fontSize: "1rem",
          }}
        />
      ) : null}
      <Stack direction="row" alignItems="center" spacing="0.75rem">
        {isNew ? (
          <WalletImage {...address} />
        ) : (
          <ExchangeLogo
            name={address.address}
            margin="0"
            blockchain={address.blockchain}
            showBlockchainSymbol={Boolean(address.blockchain)}
            walletAddress={address.address}
            currencySymbol={
              address.blockchain
                ? DefaultBlockchainCurrency[address.blockchain]
                : undefined
            }
            disableEntityIcon={!!address.blockchain}
          />
        )}
        <div>
          <CensoredEllipsisAddress address={address.address} />
          <Box
            sx={{
              fontSize: "0.6rem",
              color: tokens.text.low,
              textTransform: "uppercase",
            }}
          >
            {address.blockchain ? BlockchainName[address.blockchain] : null}
          </Box>
        </div>
      </Stack>
      {isCurrentAddress ? (
        <Chip
          label={lang.tag.thisAddress}
          size="small"
          sx={{ fontSize: "0.75rem", fontWeight: 500 }}
        />
      ) : null}
      <DisplayExchangeActionItems
        exchange={address.address}
        addressLink={addressLink}
        width={14}
      />
      {isNew ? (
        <Box sx={{ marginLeft: "auto !important" }}>
          <PrimaryButton
            size="small"
            {...getButtonProps({
              sx: {
                fontSize: "0.75rem",
              },
            })}
            onClick={() => {
              addAddressToEntityMutation.mutate({
                entity,
                newAddress: address,
              });
            }}
          >
            {lang.tag.identify}
          </PrimaryButton>
        </Box>
      ) : null}
      {canRemove ? (
        <TextIconButton
          onClick={() => {
            removeAddressFromEntityMutation.mutate(
              {
                entity,
                removedAddress: address,
              },
              {
                onSuccess() {
                  const noGlobalAddresses =
                    entity.type === EntityType.Manual ||
                    entity.globalAddresses.length === 0;
                  if (
                    onLastRemoved &&
                    noGlobalAddresses &&
                    entity.addresses.length === 1
                  ) {
                    onLastRemoved();
                  }
                },
              },
            );
          }}
          size="small"
          {...getDeleteButtonProps({
            sx: {
              marginLeft: "auto !important",
            },
            children: (
              <DeleteOutlineOutlinedIcon
                sx={{
                  color: tokens.icon.danger,
                  fontSize: "1rem",
                }}
              />
            ),
          })}
        />
      ) : null}
    </Stack>
  );
}

export const EntityAddresses = ({
  entity,
  currentAddress,
  isTreeView,
}: EntityAddressesProps) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const changeEntityDisplayNameMutation = useChangeEntityDisplayNameMutation();
  const { getButtonProps: getEditButtonProps } = useSuccessButton(
    changeEntityDisplayNameMutation,
  );
  const [isEntityEditActive, setEntityEditActive] = useState(false);
  const [displayName, setDisplayName] = useState<string>(
    entity?.displayName || "",
  );

  const isEntityEditEnabled = entity.type === EntityType.Manual;
  const count =
    entity.addresses.length +
    ("globalAddresses" in entity ? entity.globalAddresses.length : 0);

  const [animationParent] = useAutoAnimate({
    duration: 180,
  });

  return (
    <Box
      sx={{
        width: "100%",
        border: `1px solid ${tokens.border.neutral.default}`,
        borderRadius: "0.25rem",
        overflow: "hidden",
        backgroundColor: tokens.background.neutral.lowest.default,
      }}
      ref={animationParent}
    >
      {isTreeView ? (
        <Box
          sx={{
            padding: "0.75rem 1rem",
            background: tokens.background.neutral.default,
            borderBottom: `1px solid ${tokens.border.neutral.default}`,
            width: "100%",
          }}
        >
          <Stack direction="row" alignItems="center" spacing="0.5rem">
            <EntityLogo entity={entity} />
            <Typography variant="Metropolis/Body/Regular">{entity.displayName}</Typography>
            <Chip
              size="small"
              label={lang.tag.identified({ count })}
              sx={{
                background: tokens.background.accent.purple.lowest,
                color: tokens.text.default,
                fontSize: "0.75rem",
                fontWeight: 500,
              }}
            />
            {isEntityEditEnabled ? (
              <Box sx={{ marginLeft: "auto !important" }}>
                {!isEntityEditActive ? (
                  <EditIcon
                    sx={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => {
                      setDisplayName(entity.displayName);
                      setEntityEditActive(true);
                    }}
                  />
                ) : null}
                {isEntityEditActive ? (
                  <CloseIcon
                    fontSize="small"
                    onClick={() => {
                      setEntityEditActive(false);
                    }}
                  />
                ) : null}
              </Box>
            ) : null}
          </Stack>
          {!!isEntityEditEnabled && isEntityEditActive ? (
            <Stack mt="1rem" direction="row" spacing="0.5rem">
              <TextField
                size="small"
                value={displayName}
                label={lang.name}
                onChange={(e) => {
                  setDisplayName(e.target.value);
                }}
                sx={{
                  flexGrow: "1",
                  background: tokens.background.input.default,
                }}
              />
              <PrimaryButton
                size="small"
                {...getEditButtonProps()}
                onClick={() => {
                  changeEntityDisplayNameMutation.mutate(
                    {
                      entity,
                      displayName,
                    },
                    {
                      onSuccess() {
                        setEntityEditActive(false);
                        changeEntityDisplayNameMutation.reset();
                      },
                    },
                  );
                }}
              >
                {lang.save}
              </PrimaryButton>
            </Stack>
          ) : null}
        </Box>
      ) : null}
      {entity.type !== EntityType.Manual &&
        entity.globalAddresses.map((address) => (
          <EntityAddress
            key={address.address}
            address={address}
            isTreeView={Boolean(isTreeView)}
            entity={entity}
            isCurrentAddress={
              currentAddress && address.address === currentAddress.address
            }
          />
        ))}
      {entity.addresses.map((address) => (
        <EntityAddress
          key={address.address}
          address={address}
          isTreeView={Boolean(isTreeView)}
          canRemove
          entity={entity}
          isCurrentAddress={
            currentAddress &&
            matchAddressWithEntityAddress({
              address: currentAddress.address,
              blockchain: currentAddress.blockchain,
              entityAddress: address.address,
              entityBlockchain: address.blockchain,
            })
          }
        />
      ))}
      {
        // the "currentAddress" is new to be added, so show it as a new row
        currentAddress &&
        // its not in in the global addresses
        !(
          entity.type !== EntityType.Manual &&
          entity.globalAddresses.find(
            (a) => a.address === currentAddress.address,
          )
        ) &&
        // its not in the addresses
        !entity.addresses.find((a) => {
          return matchAddressWithEntityAddress({
            address: currentAddress.address,
            blockchain: currentAddress.blockchain,
            entityAddress: a.address,
            entityBlockchain: a.blockchain,
          });
        }) ? (
          <EntityAddress
            address={currentAddress}
            isTreeView={Boolean(isTreeView)}
            isNew
            entity={entity}
          />
        ) : null
      }
    </Box>
  );
};

export function EntityLogo({
  entity,
  ...props
}: { entity: Entity } & Partial<React.ComponentProps<typeof ExchangeLogo>>) {
  return (
    <>
      {entity.type === EntityType.Exchange ||
      entity.type === EntityType.Extension ? (
        // exchanges show the exchange logo
        <ExchangeLogo {...props} name={entity.ref} margin="0" />
      ) : entity.addresses.length ? (
        // addresses show the first address icon
        <ExchangeLogo
          {...props}
          name={entity.addresses[0].address}
          blockchain={entity.addresses[0].blockchain}
          margin="0"
        />
      ) : (
        // show the default ctc logo
        <ExchangeLogo {...props} name={entity._id} margin="0" />
      )}
    </>
  );
}
