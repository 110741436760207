import { Blockchain } from "@ctc/types";

import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { useLang } from "~/redux/lang";
import { BlockchainName } from "~/types/index";

interface HardSyncConfirmationDialogProps {
  open: boolean;
  title: string;
  setOpen: (value: boolean) => void;
  onHardSync: () => void;
  text: string;
  blockchain?: Blockchain;
}

export const HardSyncConfirmationDialog = ({
  open,
  title,
  setOpen,
  onHardSync,
  text,
  blockchain,
}: HardSyncConfirmationDialogProps) => {
  const lang = useLang();
  const handleAction = () => {
    onHardSync();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Temporary as we have changed the way UTXO imports work.
  const getWarning = (): string | undefined => {
    if (blockchain && deprecationWarningChains.includes(blockchain)) {
      const name = BlockchainName[blockchain];
      return lang.wallet.hardSyncWarning({ name });
    }
  };

  return (
    <ConfirmationDialog
      handleAction={handleAction}
      actionText={lang.imports.hardSync}
      handleClose={handleClose}
      isOpen={open}
      title={title}
      text={text}
      warning={getWarning()}
    />
  );
};

const deprecationWarningChains: Blockchain[] = [
  Blockchain.BTC,
  Blockchain.LTC,
  Blockchain.DOGE,
  Blockchain.DASH,
  Blockchain.ZEC,
];
