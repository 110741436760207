import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { getOptionsFromCurrencies } from "~/components/transactions/command-palette/helpers/getOptionsFromCurrencies";
import { useSelectedCurrencies } from "~/components/transactions/command-palette/helpers/useSelectedCurrencies";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { AssetAccountTopSection } from "~/components/transactions/command-palette/views/change-asset-account/AssetAccountTopSection";
import { SelectAssetAccount } from "~/components/transactions/command-palette/views/change-asset-account/SelectAssetAccount";
import { useLang } from "~/redux/lang";

/**
 * Select which currency the user wants to make to an asset account
 * If there is only one, we skip this screen
 */
export const SelectCurrency = () => {
  const { push } = useNavController();
  const lang = useLang();

  const fromCurrencies = useSelectedCurrencies({ includeFees: true });
  // Only one currency, so we dont even need to ask
  if (fromCurrencies.length === 1) {
    return <SelectAssetAccount fromCurrency={fromCurrencies[0]} />;
  }

  // get the from currencies
  const options = getOptionsFromCurrencies(fromCurrencies);
  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangeAssetAccount} />
          <AssetAccountTopSection />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.currency}
      options={options}
      onSelection={({ currency }) => {
        push(<SelectAssetAccount fromCurrency={currency} />);
      }}
    />
  );
};
