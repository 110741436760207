import multiply from "lodash/multiply";

import { minus, plus } from "~/lib/index";
import { isInTrade } from "~/services/transactions";
import { type ActionRow, type TransactionDetails } from "~/types/index";

export const removeOneSecondFromDate = (timestamp: string) => {
  const date = new Date(timestamp);
  date.setSeconds(date.getSeconds() - 1);
  return date.toISOString();
};

// A function which, given a trade type, amount and currency, returns the
// corresponding number to sum up with for the calculation of the amountChanged
// for a given action
function getAmountChangedOnSide(tx?: TransactionDetails) {
  if (!tx) {
    return 0;
  }

  if (isInTrade(tx.trade)) {
    return Math.abs(tx.quantity);
  }

  return minus(0, Math.abs(tx.quantity));
}

function getOverallBalanceValue(tx: TransactionDetails): number | undefined {
  const balance = tx.balanceRemaining?.taxEngineOverallBalance;
  return balance ? multiply(balance, tx.price) : undefined;
}

export function getMostRelevantOverallBalanceActionRow(
  tx: ActionRow,
  currencyId: string,
): {
  overallBalance: number;
  amountChanged: number;
  overallBalanceValue: number;
} {
  const releventOutTxs = tx.outgoing.filter(
    (tx) => tx.currencyIdentifier.id === currencyId,
  );
  const releventInTxs = tx.incoming.filter(
    (tx) => tx.currencyIdentifier.id === currencyId,
  );
  const releventFeeTxs = tx.fees.filter(
    (tx) => tx.currencyIdentifier.id === currencyId,
  );

  const feeTx = releventFeeTxs.length ? releventFeeTxs[0] : undefined;
  const inTx = releventInTxs.length ? releventInTxs[0] : undefined;
  const outTx = releventOutTxs.length ? releventOutTxs[0] : undefined;

  // the latest timestamp will have the correct overall balance
  // if there are multiple transactions with the same timestamp, the order is
  // fees, incoming, outgoing
  const latestTimestamp = [feeTx, inTx, outTx]
    .filter((tx) => tx)
    .reduce((acc, tx) => {
      if (tx) {
        const numberTimestamp = new Date(tx.timestamp).valueOf();
        return numberTimestamp > acc ? numberTimestamp : acc;
      }
      return acc;
    }, 0);

  const amountChanged = plus(
    plus(getAmountChangedOnSide(feeTx), getAmountChangedOnSide(inTx)),
    getAmountChangedOnSide(outTx),
  );

  const isLatestTimestamp = (tx: TransactionDetails) => {
    return new Date(tx.timestamp).valueOf() === latestTimestamp;
  };

  if (feeTx && isLatestTimestamp(feeTx)) {
    return {
      overallBalance: feeTx.balanceRemaining?.taxEngineOverallBalance ?? 0,
      amountChanged,
      overallBalanceValue: getOverallBalanceValue(feeTx) ?? 0,
    };
  }
  if (inTx && isLatestTimestamp(inTx)) {
    return {
      overallBalance: inTx.balanceRemaining?.taxEngineOverallBalance ?? 0,
      amountChanged,
      overallBalanceValue: getOverallBalanceValue(inTx) ?? 0,
    };
  }
  if (outTx && isLatestTimestamp(outTx)) {
    return {
      overallBalance: outTx.balanceRemaining?.taxEngineOverallBalance ?? 0,
      amountChanged,
      overallBalanceValue: getOverallBalanceValue(outTx) ?? 0,
    };
  }
  return { overallBalance: 0, amountChanged, overallBalanceValue: 0 };
}
