export const setFavicon = (env: string) => {
  let faviconUrl = "/favicon.ico";

  switch (env) {
    case "prod":
      faviconUrl = "/favicon.ico";
      break;
    case "spt-prod":
      faviconUrl = "/favicon-spt-prod.ico";
      break;
    case "staging":
      faviconUrl = "/favicon-staging.ico";
      break;
    case "dev":
      faviconUrl = "/favicon-dev.ico";
      break;
    case "local":
      faviconUrl = "/favicon-local.ico";
      break;
    default:
      faviconUrl = "/favicon.ico";
  }

  const link = document.createElement("link");
  link.rel = "icon";
  link.href = faviconUrl;
  document.head.appendChild(link);
};
