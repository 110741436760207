import "styled-components/macro";

import { OpenInNew } from "@mui/icons-material";
import { Box, Tooltip, Typography, TypographyProps } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import {
  CensoredBox,
  CensoredTooltip,
} from "~/components/ui/CensoredComponents";
import { Chip } from "~/components/ui/Chips";
import {
  CurrencyLogo,
  type CurrencyLogoProps,
} from "~/components/ui/CurrencyLogo";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { NFT_ID_MAX_DISPLAY_LENGTH } from "~/constants/constants";
import { FYContext } from "~/contexts/FYContext";
import { useDesign } from "~/hooks/useTheme";
import { displayCurrency, displayCurrencyName, middleTrim } from "~/lib/index";
import { useLang } from "~/redux/lang";
import { FilterOperators, Sort } from "~/types/enums";
import {
  type CurrencyIdentifier,
  type FilterQuery,
  type GroupedCurrencyIdentifier,
} from "~/types/index";

import { useIsEmbedded } from "../../hooks/useIsEmbedded";

function isGroupedCurrencyIdentifier(
  currency: GroupedCurrencyIdentifier | CurrencyIdentifier,
): currency is GroupedCurrencyIdentifier {
  return "imageURLs" in currency;
}

export const CurrencyDisplay = ({
  currency,
  showCurrencyName = false,
  idFilter,
  currencyFilter,
  disableLink,
  width,
  height,
}: {
  currency: GroupedCurrencyIdentifier | CurrencyIdentifier;
  showCurrencyName?: boolean;
  idFilter: string | null;
  currencyFilter: string | null;
  disableLink?: boolean | null;
  width?: number;
  height?: number;
}) => {
  const { tokens } = useDesign();
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const { timeframe } = useContext(FYContext);

  const isNftCollection =
    isGroupedCurrencyIdentifier(currency) &&
    currency.nftIds &&
    currency.nftIds.length > 1;

  const timeframeQuery: FilterQuery[] = timeframe
    ? [
        {
          type: FilterOperators.After,
          value: timeframe.start.valueOf(),
        },
        {
          type: FilterOperators.Before,
          value: timeframe.end.valueOf(),
        },
      ]
    : [];
  const link = getTransactionPageLink({
    state: currencyFilter
      ? {
          filter: {
            type: FilterOperators.And,
            rules: [
              {
                type: FilterOperators.Currency,
                value: [currencyFilter],
              },
              ...timeframeQuery,
            ],
          },
        }
      : idFilter
        ? {
            highlight: idFilter,
            sort: Sort.DateAscending,
            filter: {
              type: FilterOperators.And,
              rules: [
                {
                  type: FilterOperators.TransactionId,
                  value: [idFilter],
                  showAssociated: 1,
                },
              ],
            },
          }
        : {},
  });
  const xor = (a: any, b: any) => a !== b;
  const showLinkToTxPage = !disableLink && xor(idFilter, currencyFilter); //xor because if they are both defined it's an error state
  return (
    <Box display="flex" alignItems="center">
      <Box alignSelf="center">
        <CurrencyLogo currency={currency} width={width} height={height} />
      </Box>
      <CensoredTooltip title={currency.name}>
        <Box
          alignSelf="center"
          fontWeight={500}
          whiteSpace="nowrap"
          data-uncensored="true"
        >
          {showCurrencyName
            ? displayCurrencyName(currency)
            : displayCurrency(currency.symbol)}
        </Box>
      </CensoredTooltip>
      {!isNftCollection && currency.nftId ? (
        <CensoredTooltip title={`Token ID: #${currency.nftId}`}>
          <CensoredBox display="flex" pl="0.5rem">
            <Chip>
              {`#${middleTrim(currency.nftId, NFT_ID_MAX_DISPLAY_LENGTH)}`}
            </Chip>
          </CensoredBox>
        </CensoredTooltip>
      ) : null}

      {showLinkToTxPage ? (
        <Link
          onClick={(e) => {
            // stop the row expanding
            e.stopPropagation();
          }}
          target={isEmbedded ? "_self" : "_blank"}
          rel="noopener noreferrer"
          to={link}
        >
          <Tooltip title={lang.txTable.expansionPanel.viewAssociated}>
            <Box component="span" ml="0.25rem">
              <TextIconButton
                size="small"
                style={{ color: tokens.text.default }}
              >
                <OpenInNew sx={{ fontSize: "1.125rem" }} />
              </TextIconButton>
            </Box>
          </Tooltip>
        </Link>
      ) : null}
    </Box>
  );
};

export const TokenAllocationCurrency = ({
  currency,
  showCurrencyName = false,
  variant = "IBM Plex Mono/Caption/Medium/Regular",
  currencyLogoProps = {},
}: {
  currency: CurrencyIdentifier;
  showCurrencyName?: boolean;
  variant?: TypographyProps["variant"];
  currencyLogoProps?: Partial<CurrencyLogoProps>;
}) => {
  return (
    <div
      css={`
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
      `}
    >
      <CurrencyLogo
        width={20}
        height={20}
        margin="0"
        currency={currency}
        {...currencyLogoProps}
      />

      <Box alignSelf="center" whiteSpace="nowrap">
        <Typography variant={variant} noWrap>
          {showCurrencyName
            ? displayCurrencyName(currency)
            : displayCurrency(currency.symbol)}
        </Typography>
      </Box>
    </div>
  );
};

export const CurrencyDisplayWithTypography = ({
  currency,
  showCurrencyName = false,
  variant = "Metropolis/Body/Regular",
  currencyLogoProps = {},
}: {
  currency: CurrencyIdentifier;
  showCurrencyName?: boolean;
  variant?: TypographyProps["variant"];
  currencyLogoProps?: Partial<CurrencyLogoProps>;
}) => {
  return (
    <Box display="flex">
      <Box alignSelf="center">
        <CurrencyLogo currency={currency} {...currencyLogoProps} />
      </Box>
      <Box alignSelf="center" whiteSpace="nowrap">
        <Typography variant={variant} noWrap>
          {showCurrencyName
            ? displayCurrencyName(currency)
            : displayCurrency(currency.symbol)}
        </Typography>
      </Box>
    </Box>
  );
};
