import { Box, Typography } from "@mui/material";

import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { InviteTeamMember } from "~/components/settings-modal/views/team/InviteTeamMember";
import { TeamTable } from "~/components/settings-modal/views/team/TeamTable";
import { useLang } from "~/redux/lang";

export function Team() {
  const lang = useLang();

  return (
    <SettingsBox title={lang.settings.tabs.team}>
      <Typography variant="Metropolis/Header/H5">
        {lang.settings.team.managementAndPermissions}
      </Typography>
      <TeamTable />
      <Box mt="0.5rem" mb="2rem">
        <InviteTeamMember />
      </Box>
    </SettingsBox>
  );
}
