import { type AppThunk, type PortfolioState as State } from "~/redux/types";
import { useSelector } from "~/redux/useSelector";
import * as reportAPI from "~/services/report";
import { HoldingsBalanceType, PortfolioTimeframe } from "~/types/enums";
import { type TimeSeriesData } from "~/types/index";

enum Portfolio {
  GetPortfolio = "portfolio/getPortfolio",
  GetPortfolioRequest = "portfolio/getPortfolio/request",
  GetPortfolioSuccess = "portfolio/getPortfolio/success",
  GetPortfolioFailure = "portfolio/getPortfolio/failure",
  GetPortfolioPartialValue = "portfolio/getPortfolio/partialValue",
  ResetPortfolio = "portfolio/reset",
}

const emptyViewState = {
  loading: false,
  error: false,
  value: null,
  fiatInvested: null,
};

const emptyHoldingsViewState = {
  [PortfolioTimeframe.Day]: emptyViewState,
  [PortfolioTimeframe.Week]: emptyViewState,
  [PortfolioTimeframe.Month]: emptyViewState,
  [PortfolioTimeframe.Year]: emptyViewState,
  [PortfolioTimeframe.All]: emptyViewState,
  [PortfolioTimeframe.YTD]: emptyViewState,
};

const initialState: State = {
  timeframes: emptyHoldingsViewState,
};

type PortfolioActions =
  | {
      type: Portfolio.GetPortfolioRequest;
      timeframe: PortfolioTimeframe;
    }
  | {
      type: Portfolio.GetPortfolioFailure;
      timeframe: PortfolioTimeframe;
    }
  | {
      type: Portfolio.GetPortfolioSuccess;
      timeframe: PortfolioTimeframe;
      payload: { value: TimeSeriesData[]; fiatInvested: TimeSeriesData[] };
    }
  | {
      type: Portfolio.GetPortfolioPartialValue;
      timeframe: PortfolioTimeframe;
      payload: { value: TimeSeriesData[]; fiatInvested: TimeSeriesData[] };
    }
  | { type: Portfolio.ResetPortfolio };

export function portfolioReducer(
  state = initialState,
  action: PortfolioActions,
): State {
  switch (action.type) {
    case Portfolio.GetPortfolioRequest:
      return {
        ...state,
        timeframes: {
          ...state.timeframes,
          [action.timeframe]: {
            ...state.timeframes[action.timeframe],
            loading: true,
            error: false,
          },
        },
      };
    case Portfolio.GetPortfolioFailure:
      return {
        ...state,
        timeframes: {
          ...state.timeframes,
          [action.timeframe]: {
            ...state.timeframes[action.timeframe],
            loading: false,
            error: true,
          },
        },
      };
    case Portfolio.GetPortfolioSuccess:
      return {
        ...state,
        timeframes: {
          ...state.timeframes,
          [action.timeframe]: {
            ...state.timeframes[action.timeframe],
            error: false,
            loading: false,
            value: action.payload.value,
            fiatInvested: action.payload.fiatInvested,
          },
        },
      };
    case Portfolio.GetPortfolioPartialValue:
      return {
        ...state,
        timeframes: {
          ...state.timeframes,
          [action.timeframe]: {
            ...state.timeframes[action.timeframe],
            error: false,
            value: [
              ...(state.timeframes[action.timeframe].value || []),
              ...action.payload.value,
            ],
            fiatInvested: [
              ...(state.timeframes[action.timeframe].fiatInvested || []),
              ...(action.payload.fiatInvested || []),
            ],
          },
        },
      };
    case Portfolio.ResetPortfolio:
      return initialState;
    default:
      return state;
  }
}

export function resetPortfolio() {
  return {
    type: Portfolio.ResetPortfolio,
  };
}

function getPortfolioRequest(timeframe: PortfolioTimeframe): PortfolioActions {
  return {
    type: Portfolio.GetPortfolioRequest,
    timeframe,
  };
}

export function getPortfolioFailure(
  timeframe: PortfolioTimeframe,
): PortfolioActions {
  return {
    type: Portfolio.GetPortfolioFailure,
    timeframe,
  };
}

export function getPortfolioSuccess(
  timeframe: PortfolioTimeframe,
  value: TimeSeriesData[],
  fiatInvested: TimeSeriesData[],
): PortfolioActions {
  return {
    type: Portfolio.GetPortfolioSuccess,
    timeframe,
    payload: {
      value,
      fiatInvested,
    },
  };
}

export function getPortfolioPartialValue(
  timeframe: PortfolioTimeframe,
  value: TimeSeriesData[],
  fiatInvested: TimeSeriesData[],
): PortfolioActions {
  return {
    type: Portfolio.GetPortfolioPartialValue,
    timeframe,
    payload: {
      value,
      fiatInvested,
    },
  };
}

export const loadPortfolio =
  (timeframe: PortfolioTimeframe): AppThunk =>
  async (dispatch, getState) => {
    if (getState().portfolio.timeframes?.[timeframe].loading) {
      return;
    }
    if (getState().portfolio.timeframes?.[timeframe].value) {
      return;
    }

    dispatch(getPortfolioRequest(timeframe));
    // we listen to a websocket for the success event in sync.ts
    // so no need to wait for the response
    reportAPI.getPorfolioChange(timeframe, HoldingsBalanceType.Overall);
  };

const byX = (a: { x: Date }, b: { x: Date }) => a.x.valueOf() - b.x.valueOf();

export const usePortfolioChangeInfo = (timeframe: PortfolioTimeframe) => {
  return useSelector((state) => {
    const timeframeInfo = state.portfolio.timeframes[timeframe];
    const priceData = timeframeInfo.value?.map(({ x, y }) => ({
      x: new Date(x),
      y,
    }));
    const fiatInvestedData = timeframeInfo.fiatInvested?.map(({ x, y }) => ({
      x: new Date(x),
      y,
    }));

    return {
      loading: timeframeInfo.loading,
      error: timeframeInfo.error,
      priceData: priceData ? priceData.sort(byX) : priceData,
      fiatInvestedData: fiatInvestedData
        ? fiatInvestedData.sort(byX)
        : fiatInvestedData,
    };
  });
};
