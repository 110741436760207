import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { FullyReconciledComponent } from "~/components/reconciliation/FullyReconciled";
import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

export const BusinessClientPaymentSuccess = () => {
  const lang = useLang();
  const [uidQuery] = useQueryParam("uid", StringParam);
  const navigate = useNavigate();

  if (!uidQuery) {
    // In the case where the uid is not provided, redirect to the clients page
    navigate(Links.Clients);
    return null;
  }
  return (
    <FullyReconciledComponent
      calcoType="unicorn"
      title={lang.paymentStatus.success.title}
      subTitle={lang.paymentStatus.success.subtitle}
      nextLink={() => {
        navigate(Links.AccountingIntegrations);
      }}
      buttonLang={lang.accountingIntegrations.connectAccountingSoftware}
    />
  );
};
