import { Box, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { AnalyticEvent } from "~/analytics/analyticsProperties";
import { useCaptureAnalyticEvents } from "~/analytics/captureAnalyticEvents";
import { useIsMobile } from "~/components/ui/hooks";
import metamaskBlack from "~/components/ui/images/SVG_MetaMask_Horizontal_black.svg";
import metamaskWhite from "~/components/ui/images/SVG_MetaMask_Horizontal_White.svg";
import { Partnership } from "~/components/ui/Partnership";
import { ctcLight } from "~/components/ui/theme/generated";
import { PrimaryButtonLink } from "~/components/ui/ui-buttons/PrimaryButton";
import { AnimationPanel } from "~/contexts/paywall-modal-context/irs-paywall-modal/AnimationPanel";
import { FeatureCard } from "~/contexts/paywall-modal-context/irs-paywall-modal/helpers";
import { IrsPaywallModalBase } from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModalBase";
import { type PaywallModalProps } from "~/contexts/paywall-modal-context/irs-paywall-modal/types";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useIsMetamaskEmbedded } from "~/hooks/useIsMetamaskEmbedded";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { Links, Theme } from "~/types/enums";

export const PAYWALL_MODAL_VARIANT = "view_plans" as const;

export function IrsPaywallModalViewPlans({
  open,
  onClose,
  showCountdown = false,
}: PaywallModalProps) {
  const lang = useLang();
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const captureAnalytics = useCaptureAnalyticEvents();
  const isEmbedded = useIsEmbedded();
  const isMetamaskEmbedded = useIsMetamaskEmbedded();

  return (
    <IrsPaywallModalBase
      open={open}
      variant={PAYWALL_MODAL_VARIANT}
      onClose={onClose}
      showCountdown={showCountdown}
    >
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        gap="1.5rem"
        width="100%"
        height="100%"
        p={!isMobile ? "1rem" : undefined}
      >
        {!isMobile ? (
          <Box width="11.625rem">
            <AnimationPanel topSection={<AnimationTopSection />} />
          </Box>
        ) : null}

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="1rem"
          height={isMobile ? "100%" : undefined}
        >
          <Box display="flex" flexDirection="column" gap="1rem">
            {isEmbedded && isMetamaskEmbedded ? (
              <Box mr={!showCountdown ? "2rem" : undefined}>
                <EmbeddedOfferBanner />
              </Box>
            ) : null}
            <Typography variant="Metropolis/Header/H2" color={tokens.text.high}>
              {lang.paywallModal.irsVariantPreSelectedPlan.title}
            </Typography>
            <Box display="flex" flexDirection="column" gap="0.75rem">
              {lang.paywallModal.irsVariantPreSelectedPlan.features.map(
                ({ title, description }, index) => (
                  <FeatureCard
                    key={index}
                    title={title}
                    description={description}
                  />
                ),
              )}
            </Box>
          </Box>
          <Box width="100%">
            <PrimaryButtonLink
              to={Links.Plans}
              onClick={() => {
                captureAnalytics(
                  AnalyticEvent.PAYWALL_MODAL.VIEW_PLANS_BUTTON_CLICKED,
                  {
                    showTaxSeasonCountdown: showCountdown,
                    variant: PAYWALL_MODAL_VARIANT,
                  },
                );
                onClose();
              }}
              sx={{ width: "100%" }}
            >
              {lang.paywallModal.irsVariantViewPlans.cta}
            </PrimaryButtonLink>
          </Box>
        </Box>
      </Box>
    </IrsPaywallModalBase>
  );
}

function EmbeddedOfferBanner() {
  const { tokens, resolvedMode } = useDesign();
  const lang = useLang();

  const isLightMode = resolvedMode === Theme.Light;

  return (
    <Box
      borderRadius="0.5rem"
      border={`1px solid ${tokens.border.neutral.high}`}
      sx={{
        background: isLightMode
          ? "radial-gradient(circle at 10% -17.46%, #FF7A43 0%, #FFF 40%)"
          : "radial-gradient(circle at 10% -17.46%, #FF7A43 0%, #000 40%)",
      }}
    >
      <Box display="flex" flexDirection="column" mb="1rem">
        <Box
          bgcolor={tokens.background.accent.purple.medium}
          borderRadius="0 0.5rem 0 0.5rem"
          alignSelf="flex-end"
          p="0.125rem 0.5rem"
        >
          <Typography
            variant="Metropolis/Caption/Small/Regular"
            color={tokens.text.white}
          >
            {lang.paywallModal.irsVariantPreSelectedPlan.discountApplied}
          </Typography>
        </Box>
        <Box display="flex" gap="1.5rem" alignItems="center" mx="1rem">
          <LazyLoadImage
            src={isLightMode ? metamaskBlack : metamaskWhite}
            alt="Metamask"
            width="62"
          />
          <Typography variant="Metropolis/Body/Bold" color={tokens.text.high}>
            {lang.paywallModal.irsVariantViewPlans.metamaskOffer}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function ReportsReadyBanner() {
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <Box
      p="0.125rem 0.5rem"
      borderRadius="0.25rem"
      // Remi wanted this hardcoded to the CTC light theme variant
      bgcolor={ctcLight.background.success.default}
      textAlign="center"
    >
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        // Remi wanted this hardcoded to the CTC light theme variant
        color={ctcLight.text.success}
      >
        {lang.paywallModal.irsVariantViewPlans.reportsReady}
      </Typography>
    </Box>
  );
}

function AnimationTopSection() {
  const isMobile = useIsMobile();
  const isEmbedded = useIsEmbedded();
  const isMetamaskEmbedded = useIsMetamaskEmbedded();

  if (isMobile) {
    return null;
  }

  if (isEmbedded && isMetamaskEmbedded) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        mx="0.5rem"
        mt="0.5rem"
        flexDirection="column"
        gap="1rem"
      >
        <Box display="flex" justifyContent="center">
          <Partnership
            partnerSrcLight={metamaskBlack}
            partnerSrcDark={metamaskWhite}
            partnerAlt="Metamask"
            disableHeader
            themeOverride={Theme.Dark}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <ReportsReadyBanner />
        </Box>
      </Box>
    );
  }

  if (isMobile) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      px="0.5rem"
      mt="0.5rem"
    >
      <ReportsReadyBanner />
    </Box>
  );
}
