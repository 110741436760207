import { Link, Typography } from "@mui/material";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

/**
 * A reusable component that displays the terms and conditions text with links
 */
export function TermsAndConditions() {
  const lang = useLang();
  const { tokens } = useDesign();

  return (
    <Typography
      variant="Metropolis/Caption/Small/Regular"
      color={tokens.text.low}
    >
      {lang.auth.iAgree}{" "}
      <Link
        href="https://cryptotaxcalculator.io/terms-of-service"
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        {lang.auth.termsAndConditions}
      </Link>{" "}
      {lang.auth.and}{" "}
      <Link
        href="https://cryptotaxcalculator.io/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        {lang.auth.privacyPolicy}
      </Link>
      .
    </Typography>
  );
}
