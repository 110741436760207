import { Cached } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import type * as React from "react";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useLang } from "~/redux/lang";
import { refreshTaxLossHarvestingHoldingsPayload } from "~/state/taxLossHarvesting";

export function PriceHeader() {
  const lang = useLang();
  const handleRefresh = (e: React.MouseEvent) => {
    //prevent default otherwise clicking the button applies sort behaviour
    e.preventDefault();
    e.stopPropagation();
    refreshTaxLossHarvestingHoldingsPayload();
  };
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      gap="0.5rem"
    >
      <Typography fontWeight={500} fontSize="0.875rem">
        {lang.taxLossHarvesting.tableHeaders.price}
      </Typography>
      <Tooltip title={lang.taxLossHarvesting.iconTooltips.refreshPrice}>
        <TextIconButton
          size="small"
          aria-label="close"
          onClick={handleRefresh}
          sx={{
            svg: {
              width: "1.25rem",
              height: "1.25rem",
            },
          }}
        >
          <Cached />
        </TextIconButton>
      </Tooltip>
    </Box>
  );
}
