import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import type * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { useDesign } from "~/hooks/useTheme";
import { updateDataSource, useOwnsChildProfileData } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { type AppDispatch } from "~/redux/types";

export function DataSource({ accountant = false }: { accountant?: boolean }) {
  const { tokens } = useDesign();
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLang();
  const { dataSource: dataSourceLang } = useLang();
  const ownsChildProfileData = useOwnsChildProfileData();
  const [radioSelection, setRadioSelection] = useState(
    String(ownsChildProfileData),
  );

  useEffect(() => {
    setRadioSelection(String(ownsChildProfileData));
  }, [ownsChildProfileData]);

  const handleChangeDataSource = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setRadioSelection(value);
    dispatch(updateDataSource(value === "true")).then(({ error }) => {
      if (error) {
        setRadioSelection(String(ownsChildProfileData));
      }
    });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <Box display="inline-flex">
          <Typography variant="Metropolis/Body/Regular">
            {dataSourceLang.title}
          </Typography>
          {accountant && (
            <Tooltip title={lang.settings.clientData.tooltip}>
              <Box component="span" ml="0.25rem">
                <InfoOutlined
                  sx={{ color: tokens.text.low, fontSize: "1rem" }}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
        <Box>
          <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
            {accountant
              ? lang.settings.clientData.dataBody
              : dataSourceLang.subtitle}
          </Typography>
          <Box mt="0.5rem">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="dataSource"
                name="dataSource"
                value={radioSelection}
                onChange={handleChangeDataSource}
              >
                <StyledFormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label={dataSourceLang.radioButtonLabels.client({
                    accountant,
                  })}
                  disabled={accountant}
                />
                <StyledFormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label={dataSourceLang.radioButtonLabels.accountant}
                  disabled={accountant}
                  sx={{ marginTop: "-0.5rem" }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: -0.25rem;
    margin-top: 0.25rem;
  }

  &&.Mui-disabled .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.tokens.text.default};
  }
`;
