import { Divider, LinearProgress } from "@mui/material";

import { useDesign } from "~/hooks/useTheme";

export function ProgressDivider({ loading }: { loading: boolean | undefined }) {
  const { tokens } = useDesign();
  return (
    <>
      <Divider sx={{ color: tokens.border.neutral.default }} />
      {loading ? <LinearProgress /> : null}
    </>
  );
}
