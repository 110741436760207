import { Box, CircularProgress, Container, Typography } from "@mui/material";
import styled from "styled-components/macro";

import {
  historyGridCssBase,
  HistoryItem,
} from "~/components/transactions/action-row/actionRowDetailsHistory";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useGetActionPreviousVersions } from "~/state/actions";
import { type ActionRow } from "~/types/index";

export function ActionHistory({ row }: { row: ActionRow }) {
  const { tokens } = useDesign();
  const lang = useLang();
  const { isLoading, data: history } = useGetActionPreviousVersions(row._id);

  if (isLoading || !history) {
    return (
      <Container maxWidth="md">
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          py="2rem"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  const isFirstOriginal = history[0].original;

  return (
    <Box gap="1rem">
      <HistoryHeader>
        <Box gridArea="date">
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            color={tokens.text.low}
          >
            {lang.txTable.expansionPanel.history.headings.date}
          </Typography>
        </Box>
        <Box gridArea="actor">
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            color={tokens.text.low}
          >
            {lang.txTable.expansionPanel.history.headings.actor}
          </Typography>
        </Box>
        <Box gridArea="transaction">
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            color={tokens.text.low}
          >
            {lang.txTable.expansionPanel.history.headings.transaction}
          </Typography>
        </Box>
      </HistoryHeader>
      {history.map((historyItem, index) => {
        return (
          <HistoryItem
            historyItems={history}
            index={index}
            historyItem={historyItem}
            row={row}
            key={historyItem.updatedAt ?? "0"}
            isUndo={index === 0}
            isFirstOriginal={isFirstOriginal}
          />
        );
      })}
    </Box>
  );
}

export const HistoryHeader = styled.div`
  ${historyGridCssBase}
  padding: 0.5rem;
`;
