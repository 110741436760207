import { IconButton } from "@mui/material";
import styled from "styled-components/macro";

export const TertiaryIconButton = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.tokens.icon.default};
    border: 1px ${({ theme }) => theme.tokens.border.neutral.default} solid;
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.default};
    border-radius: 4px;
    transition: all 100ms;
  }

  &:hover {
    border: 1px ${({ theme }) => theme.tokens.border.neutral.highest} solid;
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.hover};
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.08),
      0 2px 5px rgba(31, 41, 55, 0.12),
      inset 0 -1px 1px rgba(0, 0, 0, 0.12);
  }

  &.Mui-focusVisible {
    border: 1px ${({ theme }) => theme.tokens.border.neutral.highest} solid;
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.pressed};
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.08),
      0 2px 5px rgba(31, 41, 55, 0.12),
      inset 0 -1px 1px rgba(0, 0, 0, 0.12);
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.icon.disabled};
    background-color: ${({ theme }) => theme.tokens.button.disabled};
    border: 1px rgba(0, 0, 0, 0) solid;
  }
`;
