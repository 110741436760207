import { type Plan } from "@ctc/types";
import { LocalOfferOutlined } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";
import moment from "moment-timezone";
import styled from "styled-components";

import { getBilledCurrencyForCountry } from "~/components/payment/helpers";
import { CENTS_PER_DOLLAR } from "~/constants/constants";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib/index";
import { useApplyDiscount, useCountry, useUser } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useAllPlans } from "~/state/plans";

export const BillingBreakdown = ({
  selectedPlan,
  dueDate,
  disableDate = false,
}: {
  selectedPlan: Plan;
  dueDate?: number;
  disableDate?: boolean;
}) => {
  const user = useUser();
  const country = useCountry();
  const languagePreference = useLanguagePreference();
  const { tokens } = useDesign();
  const lang = useLang();
  const lineItemLang = lang.payment.billing.lineItems;
  const applyDiscount = useApplyDiscount();
  const plans = useAllPlans();

  if (!country || plans?.[selectedPlan] === undefined) return null;

  const plan = plans[selectedPlan];

  if (plan === undefined) return null;

  const { amount } = plan;
  const fullAmount = amount / CENTS_PER_DOLLAR;
  const discountedAmount = applyDiscount(fullAmount);
  const discountAmount = discountedAmount - fullAmount;
  const userDiscount = user?.discount ? Number(user.discount) : undefined;

  const currency = getBilledCurrencyForCountry(country);
  const billingDate = dueDate
    ? moment(dueDate * 1000)
        .local()
        .format("D MMMM YYYY")
    : moment().utc().endOf("month").local().format("D MMMM YYYY");

  const getCouponText = () => {
    // if (discountInfo) {
    //   return percentOff
    //     ? lineItemLang.couponWithAmount({
    //         couponId,
    //         amountOff: percentOff,
    //       })
    //     : couponId;
    // }
    if (userDiscount) {
      return lang.payment.billing.payment.discount({ amount: userDiscount });
    }
    return null;
  };

  return (
    <>
      <LineItem>
        <Typography variant="Metropolis/Body/Regular">
          {lineItemLang.billingCycle}
        </Typography>
        <Typography variant="Metropolis/Body/Regular">
          {lang.payment.paymentCycle.annual}
        </Typography>
      </LineItem>
      <LineItem>
        <Typography variant="Metropolis/Body/Regular">
          {lineItemLang.planPrice}
        </Typography>
        <Typography variant="Metropolis/Body/Regular">
          {displayFiatValue({
            value: fullAmount,
            localCurrency: currency,
            locale: languagePreference,
          })}
        </Typography>
      </LineItem>
      {userDiscount ? (
        <LineItem>
          <Chip
            label={getCouponText()}
            sx={{
              backgroundColor: tokens.background.brand.default,
              color: tokens.text.default,
            }}
            size="small"
            icon={
              <LocalOfferOutlined
                sx={{
                  color: `${tokens.text.default} !important`,
                  fontSize: "1rem",
                }}
              />
            }
          />
          <Typography variant="Metropolis/Body/Regular">{`${displayFiatValue({ value: discountAmount, localCurrency: currency, locale: languagePreference })}`}</Typography>
        </LineItem>
      ) : null}
      {discountedAmount && billingDate ? (
        <LineItem>
          <Typography
            variant="Metropolis/Body/Bold"
            sx={{ color: tokens.text.high }}
          >
            {disableDate
              ? lineItemLang.totalDue
              : lineItemLang.totalDueDate({
                  date: billingDate,
                })}
          </Typography>
          <Typography
            variant="Metropolis/Body/Bold"
            sx={{ color: tokens.text.high }}
          >
            {displayFiatValue({
              value: discountedAmount,
              localCurrency: currency,
              locale: languagePreference,
              fractionDigits: 2,
            })}
          </Typography>
        </LineItem>
      ) : null}
    </>
  );
};
const LineItem = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
