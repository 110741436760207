import { Box, Skeleton } from "@mui/material";
import { type ReactElement } from "react";

import { PixelatedBox } from "~/components/report/components/summary-box/PixelatedBox";
import { useIsFreePlan } from "~/redux/auth";

export function PaywalledNumberBox({
  children,
  isLoading,
  small = false,
}: {
  children: ReactElement;
  isLoading: boolean;
  small?: boolean;
}) {
  const isPaywalled = useIsFreePlan();

  return (
    <Box display="flex" sx={{ marginLeft: { sm: "auto" } }}>
      {isLoading ? (
        <Skeleton width={small ? "4.5rem" : "10rem"} />
      ) : isPaywalled ? (
        <PixelatedBox
          width={small ? 72 : 156}
          height={24}
          pixelSize={12}
          className="tour-paywall"
        />
      ) : (
        children
      )}
    </Box>
  );
}
