import { SyncStatusPlatform } from "@ctc/types";
import { Close, Sync } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { settingsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import poweredByCtcDark from "~/assets/powered-by-ctc-big-badge-dark.svg";
import poweredByCtcLight from "~/assets/powered-by-ctc-big-badge-light.svg";
import { type SettingsTabs } from "~/components/settings-modal/views/enums";
import { useTabsForUser } from "~/components/settings-modal/views/hooks/useTabsForUser";
import { SettingsPageContents } from "~/components/settings-modal/views/index";
import { type SettingsTab } from "~/components/settings-modal/views/types";
import { ErrorBoundaryWrapper } from "~/components/ui/error-boundary/ErrorBoundaryWrapper";
import {
  getSyncStatusLabel,
  StatusChip,
  useIsLoadingReconciliationAndRRFinished,
  useReportStatusIncludingPendingSyncs,
} from "~/components/ui/SyncStatusBadge";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useActiveClient } from "~/hooks/useActiveClient";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign, useResolvedTheme } from "~/hooks/useTheme";
import { useIsManagingClients, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { refreshReport } from "~/redux/report";
import { type AppDispatch } from "~/redux/types";
import { useReportStatus } from "~/state/sync";
import { Theme } from "~/types/enums";

export function DesktopSettings({
  setOpen,
  active,
  setActive,
}: {
  setOpen: (open: boolean) => void;
  active: SettingsTabs | undefined;
  setActive: (active: SettingsTabs) => void;
}) {
  return (
    <Box width="100%" height="90vh">
      <Box position="absolute" top="0.88rem" right="2rem">
        <TextIconButton
          onClick={() => {
            setOpen(false);
          }}
          data-ctc="settings-close"
        >
          <Close />
        </TextIconButton>
      </Box>
      <Box height="100%" width="100%" display="flex">
        <Sidebar active={active} setActive={setActive} />
        <Box
          p="1.5rem 5rem 1.5rem 2rem"
          height="100%"
          maxHeight="100%"
          overflow="auto"
          flexShrink={1}
          width="100%"
        >
          <ErrorBoundaryWrapper>
            <SettingsPageContents
              active={active}
              setActive={setActive}
              setOpen={setOpen}
            />
          </ErrorBoundaryWrapper>
        </Box>
      </Box>
    </Box>
  );
}

function Sidebar({
  active,
  setActive,
}: {
  active: SettingsTabs | undefined;
  setActive: (active: SettingsTabs) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const settingsTabs = useTabsForUser();
  const isManagingClients = useIsManagingClients();
  const user = useUser();

  const title = (tab: SettingsTab) => {
    return lang.settings.tabs[tab.name];
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="13.625rem"
      bgcolor={tokens.elevation.low}
      p="0.88rem 1rem"
      flexShrink={0}
      gap="1rem"
      justifyContent="space-between"
      borderRight={`1px solid ${tokens.border.neutral.medium}`}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
        gap="0.125rem"
      >
        <Typography
          variant="Metropolis/Body/Bold"
          color={tokens.text.low}
          sx={{ mb: "0.5rem", textTransform: "capitalize" }}
        >
          {isManagingClients
            ? user?.activeDataSource.name
            : lang.settings.title}
        </Typography>
        {settingsTabs.map((option) => {
          const isActive = active === option.name;
          const Icon = option.icon;
          return (
            <StyledButton
              onClick={() => {
                setActive(option.name);
              }}
              fullWidth
              key={option.name}
              startIcon={<Icon />}
              isActive={isActive}
              disableRipple
            >
              <Typography
                color="inherit"
                variant="Metropolis/Caption/Medium/Regular"
              >
                {title(option)}
              </Typography>
            </StyledButton>
          );
        })}
      </Box>
      <SidebarFooter />
    </Box>
  );
}

function SidebarFooter() {
  const reduxDispatch: AppDispatch = useDispatch();
  const { tokens } = useDesign();
  const lang = useLang();
  const statusIncludingPendingSyncs = useReportStatusIncludingPendingSyncs();
  const updatedAt = moment(useReportStatus()?.reportUpdatedAt) || null;
  const isReportLoading =
    statusIncludingPendingSyncs === SyncStatusPlatform.Pending;
  const isLoadingReconciliationAndRRFinished =
    useIsLoadingReconciliationAndRRFinished();
  const isManagingClients = useIsManagingClients();
  const activeClient = useActiveClient();
  const captureAnalytics = useCaptureAnalytics();
  const theme = useResolvedTheme();
  const isEmbedded = useIsEmbedded();

  // If an accountant/collaborator is viewing themselves, they don't have data
  // so don't render the chip
  if (isManagingClients && !activeClient) return null;

  const handleRefresh = () => {
    captureAnalytics(settingsAnalyticsKey("refresh")("clicked"));
    reduxDispatch(refreshReport());
  };

  const label = getSyncStatusLabel(
    lang,
    statusIncludingPendingSyncs,
    isLoadingReconciliationAndRRFinished,
    updatedAt,
  );

  return (
    <Box display="flex" flexDirection="column" gap="0.5rem" width="100%">
      <Box width="100%" display="flex" alignItems="center" gap="0.5rem">
        <StatusChip
          status={statusIncludingPendingSyncs}
          isLoading={isLoadingReconciliationAndRRFinished}
        />
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.low}
        >
          {label}
        </Typography>
      </Box>
      <TertiaryButton onClick={handleRefresh} disabled={isReportLoading}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          gap="0.5rem"
          color={tokens.text.low}
        >
          {isReportLoading ? (
            <CircularProgress size="1rem" sx={{ color: tokens.text.low }} />
          ) : (
            <Sync
              sx={{
                fontSize: "1rem",
                transform: "rotate(-90deg)",
                color: "inherit",
              }}
            />
          )}
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            color="inherit"
          >
            {isReportLoading
              ? lang.settings.reportActionButton.loadingAction
              : lang.settings.reportActionButton.action}
          </Typography>
        </Box>
      </TertiaryButton>
      {isEmbedded && (
        <Box
          borderTop={`1px solid ${tokens.border.neutral.medium}`}
          paddingTop="1rem"
          marginTop="0.5rem"
        >
          <img
            src={theme === Theme.Dark ? poweredByCtcDark : poweredByCtcLight}
            alt="Powered by CTC"
          />
        </Box>
      )}
    </Box>
  );
}

const StyledButton = styled(Button)<{ isActive: boolean }>`
  justify-content: flex-start;
  color: ${({ isActive, theme }) =>
    isActive ? theme.tokens.text.high : theme.tokens.text.default};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.tokens.button.neutral.pressed : "transparent"};
  border: ${({ isActive, theme }) =>
    isActive
      ? `1px solid ${theme.tokens.border.neutral.default}`
      : "1px solid transparent"};
  border-radius: 0.25rem;

  &:hover {
    color: ${({ theme }) => theme.tokens.text.high};
    background-color: ${({ theme }) => theme.tokens.button.neutral.hover};
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }

  &:active {
    border: ${({ theme }) => `1px solid ${theme.tokens.border.neutral.high}`};
  }

  svg {
    color: ${({ isActive, theme }) =>
      isActive ? theme.tokens.text.high : theme.tokens.text.low};
  }

  &:hover svg {
    color: ${({ theme }) => theme.tokens.text.high};
  }
`;
