import { Help } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import {
  bindPopper,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { type SyntheticEvent, useRef } from "react";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { DateFormats } from "~/types/enums";

export const DateFormatOverride = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (value: string) => void;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const containerRef = useRef();
  const options = Object.values(DateFormats);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string | null,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setValue((newValue as string) || "");
  };

  const popupState = usePopupState({
    variant: "popper",
    popupId: "tickerInfo",
  });

  return (
    <>
      <Typography
        variant="Metropolis/Body/Light"
        style={{ marginRight: "0.125rem" }}
        display="inline"
      >
        {lang.manualCSV.manualSelectDateFormat}
      </Typography>
      <ClickAwayListener
        onClickAway={() => {
          popupState.close();
        }}
      >
        <Box component="span">
          <TextIconButton
            size="small"
            {...bindTrigger(popupState)}
            color={popupState.isOpen ? "primary" : "default"}
          >
            <Help fontSize="inherit" />
          </TextIconButton>
          <Popper
            {...bindPopper(popupState)}
            container={containerRef.current}
            placement="right-end"
            transition
            style={{ zIndex: 2000 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={250}>
                <Paper>
                  <Box p="1rem" maxWidth="20rem">
                    <Typography variant="Metropolis/Body/Light">
                      {lang.manualCSV.dateFormatHelper}
                    </Typography>
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
      <Box mt="0.5rem">
        <Autocomplete
          onChange={handleChange}
          value={value || null}
          options={options}
          noOptionsText={lang.manualCSV.noOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              InputLabelProps={{
                style: { color: tokens.text.default, fontWeight: 400 },
              }}
              label={lang.manualCSV.selectDateFormat}
              fullWidth
              style={{ backgroundColor: tokens.background.input.default }}
            />
          )}
        />
      </Box>
    </>
  );
};
