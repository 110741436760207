import { Edit } from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { entityIdQueryParam } from "~/components/contacts/config";
import { ContactsAddAddress } from "~/components/contacts/ContactsAddAddress";
import { DrawerInnerContent } from "~/components/contacts/DrawerInnerContent";
import { getAllAddresses } from "~/components/contacts/getAllAddresses";
import { DisplayRulesDrawerContent } from "~/components/rules/DisplayRulesDrawer";
import {
  Drawer,
  DrawerTitle,
  StyledHeaderContent,
} from "~/components/ui/Drawer";
import { EntityAddresses } from "~/components/ui/EntityAddresses";
import { useConditionalAutoFocus } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { useCanAccessFeature } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  useChangeEntityDisplayNameMutation,
  useEntitiesQuery,
} from "~/state/entities";
import { useRulesForEntity } from "~/state/rules";
import { EntityType, Features, FilterOperators } from "~/types/enums";
import { type Entity } from "~/types/index";

export function ContactsEdit({ entity }: { entity: Entity | undefined }) {
  const { tokens } = useDesign();
  const lang = useLang();
  useEntitiesQuery();
  const [, setSelectedEntityId] = useQueryParam(
    entityIdQueryParam,
    StringParam,
  );

  const totalAddresses = getAllAddresses(entity).length;
  const isRulesEnabled = useCanAccessFeature(Features.Rules);
  return (
    <Drawer
      isOpen={!!entity}
      onClose={() => {
        setSelectedEntityId(undefined);
      }}
    >
      {entity ? (
        <>
          <EditHeader entity={entity} />
          <DrawerInnerContent
            display="flex"
            flexDirection="column"
            gap="0.62rem"
          >
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              color={tokens.text.low}
            >{`${lang.contacts.edit.associatedAddresses} (${totalAddresses})`}</Typography>
            <EntityAddresses entity={entity} />
            <Box>
              <ContactsAddAddress entity={entity} />
            </Box>
            {isRulesEnabled ? (
              <DrawerRulesManagementForEntity parentId={entity._id} />
            ) : null}
          </DrawerInnerContent>
        </>
      ) : null}
    </Drawer>
  );
}

export function DrawerRulesManagementForEntity({
  parentId,
}: {
  parentId: string;
}) {
  const rules = useRulesForEntity({ id: parentId });

  return (
    <DisplayRulesDrawerContent
      rules={rules}
      initialFilter={{
        type: FilterOperators.And,
        rules: [{ type: FilterOperators.Source, value: [parentId] }],
      }}
      allowAdd
    />
  );
}

function EditHeader({ entity }: { entity: Entity }) {
  const { tokens } = useDesign();
  const conditionalAutoFocus = useConditionalAutoFocus();
  const [isEditing, setIsEditing] = useState(false);
  const [displayName, setDisplayName] = useState<string>(entity.displayName);
  const changeEntityDisplayNameMutation = useChangeEntityDisplayNameMutation();

  const submit = () => {
    changeEntityDisplayNameMutation.mutate(
      {
        entity,
        displayName,
      },
      {
        onSuccess() {
          changeEntityDisplayNameMutation.reset();
        },
      },
    );
    setIsEditing(false);
  };

  return (
    <StyledHeaderContent style={{ gap: "0.5rem" }}>
      {!isEditing ? (
        <DrawerTitle title={entity.displayName} />
      ) : (
        <Box width="80%">
          <TextField
            size="small"
            value={displayName}
            {...conditionalAutoFocus}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
            onBlur={() => {
              submit();
            }}
            inputProps={{
              sx: {
                fontSize: "1rem",
                fontWeight: 600,
                bgcolor: tokens.background.neutral.lowest.default,
                borderRadius: "0.25rem",
              },
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                submit();
              }
            }}
          />
        </Box>
      )}
      {entity.type === EntityType.Manual && !isEditing ? (
        <Edit
          sx={{
            cursor: "pointer",
            color: tokens.text.low,
            width: "1rem",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(true);
            setDisplayName(entity.displayName);
          }}
        />
      ) : null}
    </StyledHeaderContent>
  );
}
