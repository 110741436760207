import { Box, Skeleton } from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

import { useLoadingSavedImports } from "~/state/imports";

const ImportListItemBox = styled(({ highlighted, isLoading, ...props }) => (
  <Box {...props} />
))<{ highlighted?: boolean; isLoading?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ highlighted, theme }) =>
    highlighted
      ? theme.tokens.background.accent.purple.lowest
      : theme.tokens.elevation.medium};
  border: 1px solid
    ${({ highlighted, theme }) =>
      highlighted
        ? theme.tokens.background.accent.purple.lowest
        : theme.tokens.border.neutral.default};
  border-radius: 4px 4px 0px 0px;
  ${({ isLoading }) =>
    isLoading
      ? "padding-left: 0.5rem;"
      : `
    padding: 0.25rem;
  padding-left: 0.5rem;`}
  transition:
    background-color 0.1s,
    border-color 0.1s;
  margin-bottom: 0rem;

  &:first-child:last-child {
    border-radius: 0.25rem;
  }
`;

const StyledImportListItemDetails = styled(({ isLoading, ...props }) => (
  <Box {...props} />
))<{ isLoading?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  background-color: ${({ theme }) => theme.tokens.elevation.low};
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.medium};
  border-top: 0;
  border-radius: 0px 0px 4px 4px;
  margin-bottom: 0.25rem;
  padding: ${({ isLoading }) => (isLoading ? "0rem 0.5rem" : "0.5rem 0.75rem")};
`;

export function ImportListItemWrapper({
  highlighted = false,
  children,
}: {
  highlighted?: boolean;
  children: React.ReactNode;
  overrideSx?: React.CSSProperties;
}) {
  const isLoading = useLoadingSavedImports();
  return (
    <ImportListItemBox highlighted={highlighted} isLoading={isLoading}>
      {isLoading ? <Skeleton width="40%" height="100%" /> : children}
    </ImportListItemBox>
  );
}

export function ImportListItemDetails({
  children,
  sx = {},
}: {
  children: React.ReactNode;
  sx?: Record<string, any>;
}) {
  const isLoading = useLoadingSavedImports();
  return (
    <StyledImportListItemDetails isLoading={isLoading} sx={sx}>
      {isLoading ? <Skeleton width="50%" height="100%" /> : children}
    </StyledImportListItemDetails>
  );
}
