import sum from "lodash/sum";

import { FilterCountType } from "~/lib/enums";
import { FilterOperators } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

type FilterOperatorConfig = {
  /** Can the user select this filter, or is it just for code */
  filterSearchEnabled: boolean;
  /** Can you select multiple values of this type */
  multiselect: boolean;
  /** Can you select this operator in the query builder */
  queryBuilderEnabled: boolean;
  /** Does this appear in the other filters section or the filter search */
  otherFiltersEnabled: boolean;
  /** Does the option take what the user has entered? Like a txid search */
  isAutoGenerated: boolean;
  /** No select all in query builder */
  disabledSelectAll: boolean;
  /** Matches text when autogenerating */
  textMatching: boolean;
  /** Shows the operator in the query builder, but does not allow the actual selection of it */
  queryBuilderOptionShowing: boolean;
  /** Can this filter be used to create rules */
  isRulesEnabled: boolean;
};

export const filterOperatorConfig = {
  [FilterOperators.ActionTrade]: {
    filterSearchEnabled: false,
    multiselect: true,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.TxTrade]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.IncludesFee]: {
    filterSearchEnabled: true,
    multiselect: false,
    otherFiltersEnabled: false,
    queryBuilderEnabled: true,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.Blockchain]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.Currency]: {
    filterSearchEnabled: true,
    multiselect: false,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.FeeCurrency]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.TxCurrency]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.ImportType]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.Source]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.TxHash]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    disabledSelectAll: true,
    isAutoGenerated: true,
    otherFiltersEnabled: false,
    textMatching: true,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.Warning]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.TransactionId]: {
    filterSearchEnabled: false,
    multiselect: true,
    isAutoGenerated: false,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.ActionId]: {
    filterSearchEnabled: false,
    multiselect: true,
    isAutoGenerated: false,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.From]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.To]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.HasComments]: {
    filterSearchEnabled: true,
    multiselect: false,
    otherFiltersEnabled: true,
    queryBuilderEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.TxFunctionName]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.TxFunctionSignature]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.Reconciliation]: {
    filterSearchEnabled: false,
    multiselect: false,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.NegativeBalance]: {
    filterSearchEnabled: false,
    multiselect: false,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.MissingPrice]: {
    filterSearchEnabled: false,
    multiselect: false,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.ImportId]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.And]: {
    filterSearchEnabled: false,
    multiselect: false,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.Or]: {
    filterSearchEnabled: false,
    multiselect: false,
    queryBuilderEnabled: false,
    isAutoGenerated: false,
    otherFiltersEnabled: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.Not]: {
    filterSearchEnabled: false,
    multiselect: false,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.After]: {
    filterSearchEnabled: true,
    multiselect: false,
    isAutoGenerated: true,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.Before]: {
    filterSearchEnabled: true,
    multiselect: false,
    isAutoGenerated: true,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.Date]: {
    filterSearchEnabled: true,
    multiselect: true,
    isAutoGenerated: true,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.Reviewed]: {
    filterSearchEnabled: true,
    multiselect: false,
    otherFiltersEnabled: true,
    queryBuilderEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.Suggestion]: {
    filterSearchEnabled: true,
    multiselect: false,
    otherFiltersEnabled: true,
    queryBuilderEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.NFTCollection]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.Sync]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.Description]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    disabledSelectAll: true,
    textMatching: true,
    queryBuilderOptionShowing: true,
    isAutoGenerated: true,
    otherFiltersEnabled: false,
    isRulesEnabled: false,
  },
  [FilterOperators.CommentContains]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    disabledSelectAll: true,
    textMatching: true,
    queryBuilderOptionShowing: true,
    isAutoGenerated: true,
    otherFiltersEnabled: false,
    isRulesEnabled: false,
  },
  [FilterOperators.TaxOutcomeType]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isAutoGenerated: false,
    otherFiltersEnabled: false,
    isRulesEnabled: true,
  },
  [FilterOperators.ErpAssetAccount]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.ErpCashAccount]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.ErpGainsAccount]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.ErpLoanAccount]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.ErpPnlAccount]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.ErpSyncStatus]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.LockPeriod]: {
    filterSearchEnabled: true,
    multiselect: false,
    otherFiltersEnabled: true,
    queryBuilderEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.IsSmartContractInteraction]: {
    filterSearchEnabled: true,
    multiselect: false,
    otherFiltersEnabled: false,
    queryBuilderEnabled: true,
    isAutoGenerated: false,
    disabledSelectAll: true,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
  [FilterOperators.HasRule]: {
    filterSearchEnabled: true,
    multiselect: false,
    otherFiltersEnabled: true,
    queryBuilderEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.Rule]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: false,
  },
  [FilterOperators.RuleOperator]: {
    filterSearchEnabled: false,
    multiselect: true,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.Party]: {
    filterSearchEnabled: false,
    multiselect: true,
    queryBuilderEnabled: false,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: false,
    isRulesEnabled: false,
  },
  [FilterOperators.Integration]: {
    filterSearchEnabled: true,
    multiselect: true,
    queryBuilderEnabled: true,
    otherFiltersEnabled: false,
    isAutoGenerated: false,
    disabledSelectAll: false,
    textMatching: false,
    queryBuilderOptionShowing: true,
    isRulesEnabled: true,
  },
} as const satisfies Record<FilterOperators, FilterOperatorConfig>;

type FilterOperatorConfigType = typeof filterOperatorConfig;
type FilterOperatorConfigKey = {
  [key in keyof FilterOperatorConfigType]: FilterOperatorConfigType[key] & {
    key: key;
  };
};
export type AutoSuggestionsOperator = Extract<
  FilterOperatorConfigKey[keyof FilterOperatorConfigKey],
  { isAutoGenerated: true }
>["key"];
export type QueryBuilderOperator = Extract<
  FilterOperatorConfigKey[keyof FilterOperatorConfigKey],
  { queryBuilderEnabled: true }
>["key"];
export type TextMatchingOperator = Extract<
  FilterOperatorConfigKey[keyof FilterOperatorConfigKey],
  { textMatching: true }
>["key"];
export type MultiSelectOperator = Extract<
  FilterOperatorConfigKey[keyof FilterOperatorConfigKey],
  { multiselect: true }
>["key"];
export type FilterSearchOperator = Extract<
  FilterOperatorConfigKey[keyof FilterOperatorConfigKey],
  { filterSearchEnabled: true }
>["key"];

export function isAutoGeneratedOption(
  filterOperator: FilterOperators,
): filterOperator is AutoSuggestionsOperator {
  return filterOperatorConfig[filterOperator].isAutoGenerated;
}

export function isMultiSelectOperator(
  operator: FilterOperators,
): operator is MultiSelectOperator {
  return Boolean(filterOperatorConfig[operator]?.multiselect);
}

export function isQueryBuilderEnabledOperator(
  operator?: FilterOperators,
): operator is QueryBuilderOperator {
  if (!operator) return false;
  return Boolean(filterOperatorConfig[operator]?.queryBuilderEnabled);
}

export function getFilterCount(
  filter: FilterQuery | undefined,
  type: FilterCountType,
  count = 0,
): number {
  if (!filter) {
    return 0;
  }
  if (
    filter.type === FilterOperators.And ||
    filter.type === FilterOperators.Or
  ) {
    // Recursive filter, look at the children
    return (
      count +
      sum(filter.rules.map((filter) => getFilterCount(filter, type, count)))
    );
  }
  if (filter.type === FilterOperators.Not) {
    return count + getFilterCount(filter.rule, type, count);
  }
  if (
    type === FilterCountType.Query &&
    !filterOperatorConfig[filter.type].queryBuilderEnabled
  ) {
    return 0;
  }
  if (
    type === FilterCountType.Other &&
    !filterOperatorConfig[filter.type]?.otherFiltersEnabled
  ) {
    return 0;
  }
  if (Array.isArray(filter.value) && filter.value.length === 0) {
    // e.g. Currency: []
    return 0;
  }
  // Everything else we count as 1
  return 1;
}
