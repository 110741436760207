import { Trade } from "@ctc/types";
import {
  Add,
  ContentCopy,
  Delete,
  Edit,
  HideSource,
  LinkOff,
  OpenInNew,
  Share,
  ViewList,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { type ReactNode, useCallback, useState } from "react";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureActionAnalytics } from "~/analytics/posthog";
import { MoreOptionsDropdown } from "~/components/transactions/action-row/actionMoreOptionsDropdown";
import { ActionRowCell } from "~/components/transactions/action-row/ActionRowCell";
import {
  ActionRowDefaultActions,
  ActionTableGridArea,
} from "~/components/transactions/action-row/enums";
import { getActionRowCounterParty } from "~/components/transactions/action-row/helpers";
import { ViewAssociatedTransactionsModal } from "~/components/transactions/action-row/ViewAssociatedTransactionsModal";
import { EditActionDrawer } from "~/components/transactions/edit/EditActionDrawer";
import { ContextIdType } from "~/components/transactions/filter-bar/enums";
import {
  getViewInContextPageLink,
  useTransactionFilter,
} from "~/components/transactions/filter-bar/FilterContext";
import { type OptionsType } from "~/components/transactions/TransactionRow";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { displayMessage } from "~/components/ui/Toaster";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { invariant } from "~/lib/invariant";
import { isTransferLikeActionType } from "~/lib/isTransferLikeActionType";
import { useLocalCurrency } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { isGroupedTrade, isInTrade } from "~/services/transactions";
import {
  useBulkUngroupActionsMutation,
  useDeleteActionMutation,
  useDuplicateActionMutation,
  useGenerateTransferForActionMutation,
  useNewBulkIgnoreActionsMutation,
  useNewBulkOpMutation,
} from "~/state/actions";
import { useIsAccountImported } from "~/state/imports";
import {
  BulkOperations,
  DisplayMessage,
  FilterOperators,
  Size,
} from "~/types/enums";
import { type ActionRow } from "~/types/index";

import { useIsEmbedded } from "../../../hooks/useIsEmbedded";

const defaultConfig: Record<ActionRowDefaultActions, boolean> = {
  [ActionRowDefaultActions.Edit]: true,
  [ActionRowDefaultActions.ViewInContext]: true,
  [ActionRowDefaultActions.ViewAssociatedTransactions]: true,
  [ActionRowDefaultActions.Copy]: true,
  [ActionRowDefaultActions.Duplicate]: true,
  [ActionRowDefaultActions.Ungroup]: true,
  [ActionRowDefaultActions.Ignore]: true,
  [ActionRowDefaultActions.Spam]: true,
  [ActionRowDefaultActions.Delete]: true,
  [ActionRowDefaultActions.GenerateTransfer]: true,
};

const lockedAllowedActions = [
  ActionRowDefaultActions.ViewInContext,
  ActionRowDefaultActions.Copy,
];

export const ActionRowActionsWrapper = ({
  row,
  children,
  addedOptions = [],
  defaultOptions = defaultConfig,
  isLoading = false,
}: {
  row: ActionRow;
  addedOptions?: OptionsType[];
  defaultOptions?: Record<ActionRowDefaultActions, boolean>;
  children?: ReactNode;
  isLoading?: boolean;
}) => {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const captureActionAnalytics = useCaptureActionAnalytics();
  const actionRowActionsAnalyticsKey = transactionAnalyticsKey("row-actions");
  const isAccountImported = useIsAccountImported();
  const localCurrency = useLocalCurrency();
  const deleteAction = useDeleteActionMutation();
  const duplicateAction = useDuplicateActionMutation();
  const spamAction = useNewBulkOpMutation();
  const ignoreAction = useNewBulkIgnoreActionsMutation();
  const ungroupAction = useBulkUngroupActionsMutation();
  const generateTransferMutation = useGenerateTransferForActionMutation();
  const [toDelete, setToDelete] = useState<null | ActionRow>(null);

  const [toSpamRow, setToSpamRow] = useState<null | ActionRow>(null);
  const [toIgnoreRow, setToIgnoreRow] = useState<null | ActionRow>(null);
  const [ungroupOpen, setUngroupOpen] = useState(false);
  const transactionFilters = useTransactionFilter();
  const deleteConfirmationText = lang.txTable.dialogs.deleteConfirmationText;
  const ignoreTransactionText = lang.txTable.dialogs.ignoreTransactionText;
  const [isEditing, setIsEditing] = useState(false);
  const [isViewingAssociatedTransactions, setIsViewingAssociatedTransactions] =
    useState(false);

  const counterParty = getActionRowCounterParty(row);

  // Don't show the dropdown option if the option is set to false
  // OR their is no counterParty OR the counterParty is transfer-like
  const shouldShowAssociatedTransactionsModalDropdownOption =
    defaultOptions.viewAssociatedTransactions &&
    !!counterParty &&
    !isTransferLikeActionType(row.type);

  const onDelete = useCallback((): void => {
    setToDelete(row);
    captureActionAnalytics("transaction_delete_clicked", row);
  }, [row, captureActionAnalytics]);

  const onSpam = useCallback((): void => {
    setToSpamRow(row);
    captureActionAnalytics("transaction_mark_spam_clicked", row);
  }, [row, captureActionAnalytics]);

  const onIgnore = useCallback((): void => {
    setToIgnoreRow(row);
    captureActionAnalytics("transaction_ignore_clicked", row);
  }, [row, captureActionAnalytics]);

  const onUngroup = useCallback((): void => {
    setUngroupOpen(true);
    captureActionAnalytics("transaction_ungroup_clicked", row);
  }, [row, captureActionAnalytics]);

  if (!localCurrency) {
    return null;
  }

  // Only counting grouped outs/ins, not including fees
  const isGroupedRow = isGroupedTrade(row.type);

  const handleConfirmIgnore = async () => {
    const ids = [row._id];
    captureActionAnalytics("transaction_ignore_confirmed", row);
    ignoreAction.mutate(
      {
        filter: {
          type: FilterOperators.ActionId,
          value: ids,
        },
      },
      {
        onSuccess: () => {
          setToIgnoreRow(null);
        },
      },
    );
  };

  const handleConfirmSpam = async () => {
    const ids = [row._id];
    captureActionAnalytics("transaction_mark_spam_confirmed", row);
    spamAction.mutate(
      {
        filter: {
          type: FilterOperators.ActionId,
          value: ids,
        },
        operation: {
          type: BulkOperations.MarkAsSpam,
        },
      },
      {
        onSuccess: () => {
          setToSpamRow(null);
        },
      },
    );
  };

  const handleConfirmDelete = async () => {
    captureActionAnalytics("transaction_delete_confirmed", row);
    deleteAction.mutate(
      {
        id: row._id,
      },
      {
        onSuccess: () => {
          setToDelete(null);
        },
      },
    );
  };

  const handleConfirmUnGroup = async () => {
    captureActionAnalytics("transaction_ungroup_confirmed", row);
    ungroupAction.mutate({
      ids: [row._id],
    });
  };

  const handleConfirmDuplicate = async () => {
    captureActionAnalytics("transaction_duplicate_clicked", row);
    duplicateAction.mutate({
      actionId: row._id,
    });
  };

  const handleViewAssociatedTransactions = async () => {
    setIsViewingAssociatedTransactions(true);
  };

  const handleViewInContext = () => {
    const link = getViewInContextPageLink(
      row._id,
      ContextIdType.ActionId,
      transactionFilters.state.sort,
    );

    // If we're in an embedded context, open the link in the same tab
    window.open(link, isEmbedded ? "_self" : "_blank", "noopener,noreferrer");
  };

  // an unmatched transfer is a send/receive and both sides of the tx are imported
  const isUnmatchedTransfer =
    [Trade.Withdrawal, Trade.Deposit].includes(row.type as Trade) &&
    [...row.incoming, ...row.outgoing].every(
      (tx) => isAccountImported(tx.to) && isAccountImported(tx.from),
    );

  const moreOptions = row.isLocked ? [] : [...addedOptions];

  Object.values(ActionRowDefaultActions).forEach((action) => {
    if (!defaultOptions[action]) {
      return;
    }
    if (row.isLocked && !lockedAllowedActions.includes(action)) {
      return;
    }

    switch (action) {
      case ActionRowDefaultActions.Edit: {
        moreOptions.push({
          handler: () => {
            setIsEditing(true);
          },
          label: lang.edit,
          icon: <Edit style={{ fontSize: "1rem" }} />,
        });
        return;
      }
      case ActionRowDefaultActions.ViewInContext: {
        moreOptions.push({
          handler: handleViewInContext,
          label: lang.context,
          icon: <OpenInNew style={{ fontSize: "1rem" }} />,
        });
        return;
      }
      case ActionRowDefaultActions.ViewAssociatedTransactions: {
        if (shouldShowAssociatedTransactionsModalDropdownOption) {
          moreOptions.push({
            handler: handleViewAssociatedTransactions,
            label: lang.viewAssociatedTransactions,
            icon: <ViewList style={{ fontSize: "1rem" }} />,
          });
        }
        return;
      }
      case ActionRowDefaultActions.Copy: {
        moreOptions.push({
          handler: () => {
            const txs = [...row.incoming, ...row.outgoing, ...row.fees];
            if (txs.length === 0) {
              return;
            }
            const link = getViewInContextPageLink(
              txs[0]._id,
              ContextIdType.TxId,
            );
            navigator.clipboard.writeText(`${window.location.host}${link}`);
            captureActionAnalytics(
              actionRowActionsAnalyticsKey("share link"),
              row,
            );
            displayMessage({
              message: lang.copyTxLinkSuccess,
              type: DisplayMessage.Success,
            });
          },
          label: lang.copyTxLink,
          icon: <Share style={{ fontSize: "1rem" }} />,
        });
        return;
      }
      case ActionRowDefaultActions.Duplicate: {
        moreOptions.push({
          handler: handleConfirmDuplicate,
          label: lang.duplicate,
          icon: <ContentCopy style={{ fontSize: "1rem" }} />,
          pending: duplicateAction.isPending,
        });
        return;
      }
      case ActionRowDefaultActions.Ungroup: {
        if (isGroupedRow) {
          moreOptions.push({
            handler: onUngroup,
            label: lang.transactions.ungroup.action,
            icon: <LinkOff style={{ fontSize: "1rem" }} />,
          });
        }
        return;
      }
      case ActionRowDefaultActions.Ignore: {
        if (
          ![Trade.IgnoreIn, Trade.IgnoreOut].includes(
            row.type as Trade,
          )
        ) {
          moreOptions.push({
            handler: onIgnore,
            label: lang.ignore,
            icon: <HideSource style={{ fontSize: "1rem" }} />,
          });
        }
        return;
      }
      case ActionRowDefaultActions.Spam: {
        if (
          isInTrade(row.type as Trade) &&
          ![Trade.Spam].includes(row.type as Trade)
        ) {
          moreOptions.push({
            handler: () => {
              onSpam();
            },
            label: lang.spam,
            icon: <HideSource style={{ fontSize: "1rem" }} />,
          });
        }
        return;
      }
      case ActionRowDefaultActions.Delete: {
        moreOptions.push({
          handler: onDelete,
          label: lang.delete,
          icon: <Delete style={{ fontSize: "1rem" }} />,
        });
        return;
      }
      case ActionRowDefaultActions.GenerateTransfer: {
        if (isUnmatchedTransfer) {
          moreOptions.push({
            handler: () => {
              // there can only be 1 transaction
              const transaction = row.outgoing[0] ?? row.incoming[0];
              invariant(transaction, "No transaction found");
              generateTransferMutation.mutate({
                actionId: row._id,
                transactionId: transaction._id,
              });
              captureActionAnalytics(
                transactionAnalyticsKey("generate transfer")("clicked"),
                row,
              );
            },
            label: `${
              lang.txTable.txRow.moreOptions.generateMatchingTransfer
            } ${
              row.type === Trade.Withdrawal
                ? getActionTypeName({
                    actionType: Trade.Deposit,
                    lang,
                  })
                : getActionTypeName({
                    actionType: Trade.Withdrawal,
                    lang,
                  })
            }`,
            icon: <Add style={{ fontSize: "1rem" }} />,
            pending: generateTransferMutation.isPending,
          });
        }
        return;
      }
      default: {
        const exhaustiveCheck: never = action;
        throw new Error(`Unhandled case: ${exhaustiveCheck}`);
      }
    }
  });

  return (
    <>
      <EditActionDrawer
        row={row}
        isOpen={isEditing}
        onClose={() => {
          setIsEditing(false);
        }}
      />

      {shouldShowAssociatedTransactionsModalDropdownOption ? (
        <ViewAssociatedTransactionsModal
          isOpen={isViewingAssociatedTransactions}
          setIsOpen={setIsViewingAssociatedTransactions}
          row={row}
        />
      ) : null}
      <ConfirmationDialog
        isOpen={Boolean(toDelete)}
        title={lang.txTable.deleteTransactionConfirmationTitle}
        text={deleteConfirmationText}
        actionText={lang.delete}
        handleClose={() => {
          setToDelete(null);
        }}
        handleAction={handleConfirmDelete}
        pending={deleteAction.isPending}
        critical
        stopPropagation
      />
      <ConfirmationDialog
        isOpen={Boolean(toSpamRow)}
        title={lang.txTable.spamTransactionConfirmationTitle}
        text={lang.txTable.dialogs.spamTransactionText}
        actionText={lang.spam}
        handleClose={() => {
          setToSpamRow(null);
        }}
        handleAction={handleConfirmSpam}
        pending={spamAction.isPending}
        stopPropagation
      />
      <ConfirmationDialog
        isOpen={Boolean(toIgnoreRow)}
        title={lang.txTable.ignoreTransactionConfirmationTitle}
        text={ignoreTransactionText}
        actionText={lang.ignore}
        handleClose={() => {
          setToIgnoreRow(null);
        }}
        handleAction={handleConfirmIgnore}
        pending={ignoreAction.isPending}
        stopPropagation
      />
      <ConfirmationDialog
        isOpen={ungroupOpen}
        title={lang.transactions.ungroup.title}
        text={lang.transactions.ungroup.body1}
        actionText={lang.transactions.ungroup.action}
        handleClose={() => {
          setUngroupOpen(false);
        }}
        handleAction={handleConfirmUnGroup}
        pending={ungroupAction.isPending}
        stopPropagation
      />
      <ActionRowCell id={ActionTableGridArea.Actions} style={{ width: "100%" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "0.25rem",
            marginTop: "0.15rem",
            justifyContent: "flex-end",
          }}
        >
          {children}
          <MoreOptionsDropdown
            row={row}
            options={moreOptions}
            size={Size.Small}
            isLoading={isLoading}
          />
        </Box>
      </ActionRowCell>
    </>
  );
};
