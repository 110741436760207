import { ErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "~/components/ui/error-boundary/ErrorBoundaryWrapper";
import { AcceptAccountantInvite } from "~/components/user/AcceptAccountantInvite";
import { CancellationSurvey } from "~/components/user/CancellationSurvey";
import { useBestInvitation } from "~/state/invitations";

export function UserPopupManager() {
  const { isLoading } = useBestInvitation();
  if (isLoading) {
    return null;
  }
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AcceptAccountantInvite />
      <CancellationSurvey />
    </ErrorBoundary>
  );
}
