import { useEffect } from "react";

import { nameServiceConfig } from "~/components/imports/wallet/config";
import {
  AddressChip,
  type AddressChipProps,
} from "~/components/ui/AddressChip";

type NameServiceAwareAddressChipProps = AddressChipProps & {
  updateAddressToNameServiceNameMap: (address: string, name: string) => void;
  addressToNameServiceNameMap: Map<string, string>;
};

export function NameServiceAwareAddressChip({
  address,
  blockchain,
  updateAddressToNameServiceNameMap,
  addressToNameServiceNameMap,
  addressError: _addressError,
  ...rest
}: NameServiceAwareAddressChipProps) {
  const nameService = Object.values(nameServiceConfig).find((ns) =>
    address.endsWith(ns.suffix),
  );
  const addressUsesNameService = Boolean(nameService);

  const { data: resolvedAddress, isLoading } = nameService?.useResolver(
    address,
  ) ?? {
    data: null,
    isLoading: false,
  };

  const addressError = (() => {
    if (nameService && addressUsesNameService) {
      if (resolvedAddress === null) {
        return nameService.errorType;
      }
      return undefined;
    }
    return _addressError;
  })();

  useEffect(() => {
    if (!addressUsesNameService || !nameService) return;
    const formattedAddress = resolvedAddress;
    if (
      formattedAddress &&
      !addressToNameServiceNameMap?.has(formattedAddress)
    ) {
      const normalizedName = nameService.normalize?.(address) ?? address;
      updateAddressToNameServiceNameMap(formattedAddress, normalizedName);
    }
  }, [
    addressUsesNameService,
    nameService,
    resolvedAddress,
    addressToNameServiceNameMap,
    updateAddressToNameServiceNameMap,
    address,
  ]);

  const name = addressToNameServiceNameMap?.get(address);
  const displayAddress = name ?? address;
  const tooltip = name ? `${name}\n${address}` : address;

  return (
    <AddressChip
      address={address}
      blockchain={blockchain}
      enableDelete
      addressError={addressError}
      tooltip={tooltip}
      displayAddress={displayAddress}
      isLoading={isLoading}
      {...rest}
    />
  );
}
