import { useState } from "react";

import { displayMessage } from "~/components/ui/Toaster";
import { useLang } from "~/redux/lang";
import * as priceAPI from "~/services/prices";
import { DisplayMessage } from "~/types/enums";
import { type CurrencyIdentifier } from "~/types/index";

export function useLookupMarketPrice(
  defaultMarketPrice?: number | null,
): [
  number | undefined,
  (
    currencyIdentifier: CurrencyIdentifier | string | null,
    timestamp: string,
  ) => void,
  boolean,
] {
  const [marketPrice, setMarketPrice] = useState<number | undefined>(
    defaultMarketPrice || undefined,
  );
  const lang = useLang().currency;
  const [isLoading, setIsLoading] = useState(false);
  const lookupMarketPrice = async (
    currencyIdentifier: CurrencyIdentifier | string | null,
    timestamp: string,
  ) => {
    // don't do a lookup if we don't have a proper identifier
    if (
      isLoading ||
      !currencyIdentifier ||
      typeof currencyIdentifier === "string"
    ) {
      return;
    }
    setIsLoading(true);
    const res = await priceAPI.getMarketPrice(currencyIdentifier, timestamp);
    if (res.error) {
      console.error(res);
      displayMessage({
        message: lang.wentWrongFetchingPrice({
          name: currencyIdentifier.name,
        }),
        type: DisplayMessage.Error,
      });
    } else {
      const price = res.data || 0;
      if (price === 0) {
        // 0 is sent instead of an error when we can't find the price.
        displayMessage({
          message: lang.unableToFindPrice({
            name: currencyIdentifier.name,
          }),
          type: DisplayMessage.Error,
        });
      } else {
        setMarketPrice(price);
      }
    }
    setIsLoading(false);
  };
  return [marketPrice, lookupMarketPrice, isLoading];
}
