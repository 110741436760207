import { Plan } from "@ctc/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { type AccountantInviteFormValues } from "~/components/settings-modal/views/team/types";
import { useUser } from "~/redux/auth";
import { HttpError } from "~/services/core";
import * as invitationsApi from "~/services/invitations";
import { invitationKeys } from "~/state/invitations";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import { InvitationType } from "~/types/enums";
import { type TeamInvitationDetails } from "~/types/index";

export const inviteKeys = {
  all: () => ["invite"] as const,
  details: () => [...inviteKeys.all(), "details"] as const,
  team: () => [...inviteKeys.all(), "team"] as const,
};

export function useTeam() {
  const user = useUser();

  return useQuery({
    queryKey: inviteKeys.team(),
    queryFn: async () => {
      const res = await invitationsApi.getTeam();

      if (res.error) {
        throw new HttpError(res, ["getTeam"]);
      }

      return res.data;
    },
    enabled: user?.paidPlan === Plan.Accountant,
  });
}

export function useInviteTeamMemberMutation() {
  const queryClient = useQueryClient();
  const invitations = useTeam().data ?? [];

  return useMutation({
    mutationFn: async (formData: AccountantInviteFormValues) => {
      const existingInvitation = invitations.find(
        (invitation) =>
          invitation.toEmail.toLowerCase() === formData.toEmail.toLowerCase(),
      );

      const res = await invitationsApi.createInvitation({
        ...formData,
        toEmail: formData.toEmail.toLowerCase(),
        invitationType: InvitationType.Collaborator,
        replacingInvitationId: existingInvitation?._id,
      });

      if (res.error) {
        throw new HttpError(res, ["createInvitation"]);
      }

      return res.data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
      void queryClient.invalidateQueries({ queryKey: inviteKeys.all() });
    },
  });
}

export function useCancelInvitationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (invitationId: string) => {
      const res = await invitationsApi.deleteInvitation(invitationId);

      if (res.error) {
        throw new HttpError(res, ["deleteInvitation"]);
      }

      return res.data;
    },
    onMutate: async (invitationId: string) => {
      const previous = queryClient.getQueryData<TeamInvitationDetails[]>(
        inviteKeys.team(),
      );
      await queryClient.cancelQueries({ queryKey: inviteKeys.team() });

      queryClient.setQueryData<TeamInvitationDetails[]>(
        inviteKeys.team(),
        (old) => {
          if (!old) {
            return old;
          }
          return old.filter((invitation) => invitation._id !== invitationId);
        },
      );

      return { previous };
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(inviteKeys.team(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
      void queryClient.invalidateQueries({ queryKey: inviteKeys.all() });
    },
  });
}
