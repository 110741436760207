import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { CashAccountTopSection } from "~/components/transactions/command-palette/views/change-cash-account/CashAccountTopSection";
import { invariant } from "~/lib/invariant";
import { type ErpAccount } from "~/redux/types";
import { type PartyDetails } from "~/services/transactions";
import { useNewBulkOpMutation } from "~/state/actions";
import { BulkOperations } from "~/types/enums";

export const Confirmation = ({
  party,
  erpCashAccount,
}: {
  party: PartyDetails;
  erpCashAccount: ErpAccount;
}) => {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");

  const bulkOp = useNewBulkOpMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.ChangeCashAccount,
  });

  const onConfirm = () => {
    bulkOp.mutate(
      {
        filter,
        operation: {
          type: BulkOperations.ChangeErpCashAccount,
          erpAccountCode: erpCashAccount.code,
          account: party.exchange,
        },
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangeCashAccount} />
          <CashAccountTopSection party={party} cashAccount={erpCashAccount} />
        </BulkEditChips>
      }
      onConfirm={onConfirm}
      loading={bulkOp.isPending}
    />
  );
};
