import { useEffect, useRef } from "react";

export const useInterval = (callback: () => void, delay?: number) => {
  const refCallback = useRef(callback);

  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if ((!delay && delay !== 0) || !callback) {
      return;
    }

    const intervalId = setInterval(() => {
      refCallback.current();
    }, delay);

    return () => {
      clearInterval(intervalId);
    };
  }, [delay]);
};
