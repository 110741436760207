import { Plan } from "@ctc/types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { type PlanSectionProps } from "~/components/payment/types";
import { devices } from "~/components/ui/theme/legacy";
import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useDesign } from "~/hooks/useTheme";
import { displayQuantity, safeFloat } from "~/lib/index";
import { useBestUser, useUserHasEmail } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { PlanGroup } from "~/types/enums";

import { useBusinessPlanPriceData } from "../plans/hooks/usePriceDataByPlanType";
import { useIsMobile } from "../ui/hooks";
import { TextButton } from "../ui/ui-buttons/TextButton";

export const BusinessSection = ({
  onPlanChange,
  onPlanGroupChange,
  isRedirecting,
}: Pick<
  PlanSectionProps,
  "onPlanChange" | "onPlanGroupChange" | "isRedirecting"
>) => {
  const lang = useLang();
  const locale = useLanguagePreference();
  const user = useBestUser();
  const { tokens } = useDesign();
  const userHasEmail = useUserHasEmail();
  const isMobile = useIsMobile();
  const langBusiness =
    lang.payment.base.copy.plans[Plan.Business].retailPlansPage;

  const { getSelectedPlanData } = useBusinessPlanPriceData();

  const selectedPlanData = getSelectedPlanData(Plan.Business);

  const isCurrentPlan = user?.paidPlan === Plan.Business;

  return (
    <Stack
      direction="column"
      gap="2rem"
      sx={{
        padding: "2rem 1rem",
        background: tokens.elevation.low,
        [`@media ${devices.laptop}`]: {
          padding: "4rem 1rem 2rem",
        },
      }}
    >
      <Stack
        direction="column"
        gap="0.5rem"
        sx={{ maxWidth: "35rem", marginLeft: "auto", marginRight: "auto" }}
      >
        <Typography
          variant="Metropolis/Header/H1"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            color: tokens.text.high,
          }}
        >
          {langBusiness.title}
        </Typography>
        <Typography variant="Metropolis/Header/H4" sx={{ textAlign: "center", fontWeight: 700 }}>
          {langBusiness.subtitle}
        </Typography>
      </Stack>

      <Box width="100%">
        <Container direction={isMobile ? "column" : "row"}>
          <ValueWithUnit
            value={selectedPlanData?.planPriceInLocalCurrencyFormat || ""}
            unit={lang.payment.paymentCycle.annual}
          />
          <ValueWithUnit
            value={`${displayQuantity(safeFloat(selectedPlanData?.data.paywallLimits.txCountLimit || 0), locale)}`}
            unit={lang.payment.totalTxs}
          />
        </Container>
      </Box>

      <Container direction="column">
        <Typography variant="Metropolis/Header/H4">
          {langBusiness.description}
        </Typography>
        <Stack direction="column" gap="1rem">
          {langBusiness.points.map((point) => (
            <FeaturePoint key={point.feature}>
              <CheckIcon
                sx={{
                  color: tokens.text.success,
                }}
              />
              <FeatureContent>
                <Typography variant="Metropolis/Header/H5">
                  {point.feature}
                </Typography>
                {point.description ? (
                  <Typography
                    variant="Metropolis/Body/Regular"
                    color={tokens.text.low}
                  >
                    {point.description}
                  </Typography>
                ) : null}
              </FeatureContent>
            </FeaturePoint>
          ))}
        </Stack>
        <Box>
          <Tooltip title={!userHasEmail ? lang.auth.missingEmail : ""}>
            <span>
              <PrimaryLoadingButton
                onClick={() => {
                  onPlanChange(Plan.Business, true, undefined);
                }}
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 700,
                }}
                disabled={
                  isCurrentPlan ||
                  isRedirecting ||
                  !selectedPlanData ||
                  !userHasEmail
                }
                loading={isRedirecting}
              >
                {isCurrentPlan ? lang.currentPlan : langBusiness.cta}
              </PrimaryLoadingButton>
            </span>
          </Tooltip>
        </Box>
      </Container>
      {onPlanGroupChange ? (
        <Box
          sx={{
            maxWidth: "81.125rem",
            width: "100%",
            margin: "0 auto",
          }}
        >
          <TextButton
            startIcon={<ArrowBackIcon fontSize="small" />}
            onClick={() => {
              onPlanGroupChange(PlanGroup.Personal);
            }}
          >
            {lang.payment.viewIndividualPlan}
          </TextButton>
        </Box>
      ) : null}
    </Stack>
  );
};

function ValueWithUnit({ value, unit }: { value: string; unit: string }) {
  const { tokens } = useDesign();

  return (
    <Stack
      direction="row"
      alignItems="baseline"
      gap="0.25rem"
      width="100%"
      sx={{ whiteSpace: "nowrap" }}
    >
      <Typography
        sx={{ color: tokens.text.high, fontWeight: 700, fontSize: "2rem" }}
      >
        {value && value !== "0" ? (
          value
        ) : (
          <Skeleton variant="text" width="6rem" height="100%" />
        )}
      </Typography>

      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        sx={{ color: tokens.text.low }}
      >
        {unit}
      </Typography>
    </Stack>
  );
}

const FeaturePoint = styled(Stack)`
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
`;

const FeatureContent = styled(Stack)`
  flex-direction: column;
  gap: 0.5rem;
`;

const Container = styled(Stack)`
  gap: 1.5rem;
  padding: 1.5rem;
  background: ${({ theme }) => theme.tokens.elevation.medium};
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  border-radius: 1.5rem;
  max-width: 43rem;
  margin-left: auto;
  margin-right: auto;
`;
