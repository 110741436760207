/*
 * DO NOT EDIT THIS FILE MANUALLY
 * IT IS AUTOMATICALLY GENERATED AND CHANGES WILL BE OVERWRITTEN
 * SEE `scripts/compilerTranslations.ts` FOR MORE INFO
 */

import lang from "./it.import-instructions.js";
type Arg = string | number | boolean;
export default {
  importInstructions: {
    abra: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["abra"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["abra"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["abra"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["abra"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    abstract: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["abstract"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["abstract"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["abstract"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    agoric: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["agoric"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["agoric"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["agoric"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    airswap: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["airswap"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["airswap"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["airswap"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["airswap"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["airswap"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["airswap"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["airswap"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["airswap"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["airswap"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    akash: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["akash"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["akash"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    algorand: {
      "wallet-api": {
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["algorand"]["wallet-api"]["step"][0][
                  "items"
                ][0](args) as string,
              lang["importInstructions"]["algorand"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["algorand"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["algorand"]["wallet-api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["algorand"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    altcointrader: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["altcointrader"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["altcointrader"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["altcointrader"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    aptos: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["aptos"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["aptos"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["aptos"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    arbitrum: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["arbitrum"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["arbitrum"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["arbitrum"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["arbitrum"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    "arbitrum-nova": {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["arbitrum-nova"]["wallet-api"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["arbitrum-nova"]["wallet-api"][
                "step"
              ][0]["items"][1](),
            ],
          },
        ],
      },
    },
    archway: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["archway"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["archway"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    atani: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["atani"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["atani"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["atani"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["atani"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["atani"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["atani"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    atomicwallet: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["atomicwallet"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["atomicwallet"]["csv"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["atomicwallet"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["atomicwallet"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["atomicwallet"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["atomicwallet"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["atomicwallet"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
          {
            title:
              lang["importInstructions"]["atomicwallet"]["csv"]["step"][1][
                "title"
              ](),
          },
        ],
      },
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["atomicwallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["atomicwallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["atomicwallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["atomicwallet"]["soft-wallet"][
                "step"
              ][0]["items"][3](),
              lang["importInstructions"]["atomicwallet"]["soft-wallet"][
                "step"
              ][0]["items"][4](),
            ],
          },
        ],
      },
    },
    aurora: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["aurora"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["aurora"]["wallet-api"]["critical"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["aurora"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["aurora"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    avalanche: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["avalanche"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["avalanche"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["avalanche"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["avalanche"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["avalanche"]["wallet-api"]["note"][2][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["avalanche"]["wallet-api"]["note"][2][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["avalanche"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    bambooRelay: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bambooRelay"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bambooRelay"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bambooRelay"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bambooRelay"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bambooRelay"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bambooRelay"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bambooRelay"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bambooRelay"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    base: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["base"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["base"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    bch: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["bch"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bch"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bch"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    berachain: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["berachain"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["berachain"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["berachain"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["berachain"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    bigone: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["bigone"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bigone"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bigone"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bigone"]["api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    binance: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][2](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][3](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][4](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][5](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][6](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][7](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][8](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][9](),
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "items"
              ][10](),
            ],
            title:
              lang["importInstructions"]["binance"]["api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["binance"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binance"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["binance"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["binance"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["binance"]["api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["binance"]["api"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["binance"]["api"]["video"][0][
                "title"
              ](),
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["binance"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binance"]["csv"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["binance"]["csv"]["note"][0][
                "items"
              ][2](),
              lang["importInstructions"]["binance"]["csv"]["note"][0][
                "items"
              ][3](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["binance"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binance"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["binance"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["binance"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["binance"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["binance"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["binance"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["binance"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["binance"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["binance"]["csv"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["binance"]["csv"]["video"][0][
                "title"
              ](),
          },
        ],
      },
    },
    "binance-smart-chain": {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["binance-smart-chain"]["wallet-api"][
                "note"
              ][0]["items"][0](),
            ],
            title:
              lang["importInstructions"]["binance-smart-chain"]["wallet-api"][
                "note"
              ][0]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["binance-smart-chain"]["wallet-api"][
                "note"
              ][1]["items"][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["binance-smart-chain"]["wallet-api"][
                "step"
              ][0]["items"][0](),
            ],
          },
        ],
      },
    },
    binancechain: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["binancechain"]["wallet-api"][
                "critical"
              ][0]["items"][0](),
            ],
            title:
              lang["importInstructions"]["binancechain"]["wallet-api"][
                "critical"
              ][0]["title"](),
          },
        ],
        step: [
          {
            title:
              lang["importInstructions"]["binancechain"]["wallet-api"][
                "step"
              ][0]["title"](),
          },
        ],
      },
    },
    binancedex: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["binancedex"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binancedex"]["wallet-api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["binancedex"]["wallet-api"]["note"][0][
                "items"
              ][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["binancedex"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binancedex"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    binanceus: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][2](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][3](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][4](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][5](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][6](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][7](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][8](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][9](),
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "items"
              ][10](),
            ],
            title:
              lang["importInstructions"]["binanceus"]["api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][8](),
              lang["importInstructions"]["binanceus"]["api"]["step"][0][
                "items"
              ][9](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["binanceus"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binanceus"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["binanceus"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["binanceus"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["binanceus"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["binanceus"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["binanceus"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["binanceus"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["binanceus"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
            title:
              lang["importInstructions"]["binanceus"]["csv"]["step"][0][
                "title"
              ](),
          },
        ],
      },
    },
    bingx: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["bingx"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bingx"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bingx"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bingx"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bingx"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bingx"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    bitaroo: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitaroo"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitaroo"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitaroo"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    bitbuy: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitbuy"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitbuy"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitbuy"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitbuy"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitbuy"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitbuy"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    bitcoindotcomau: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitcoindotcomau"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitcoindotcomau"]["api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    bitfinex: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitfinex"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitfinex"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitfinex"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitfinex"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitfinex"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitfinex"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitfinex"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bitfinex"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["bitfinex"]["api"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitfinex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitfinex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitfinex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitfinex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitfinex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitfinex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitfinex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bitfinex"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["bitfinex"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["bitfinex"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
    },
    bitflyer: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitflyer"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitflyer"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitflyer"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitflyer"]["api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitflyer"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitflyer"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitflyer"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    bitforex: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitforex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitforex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitforex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitforex"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    bitget: {
      api: {
        critical: [
          {
            items: [
              lang["importInstructions"]["bitget"]["api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["bitget"]["api"]["critical"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitget"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitget"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitget"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitget"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitget"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitget"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bitget"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["bitget"]["api"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["bitget"]["api"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
    },
    bitgo: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitgo"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitgo"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitgo"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitgo"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitgo"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitgo"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    bitkeep: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["bitkeep"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitkeep"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitkeep"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    bitmart: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitmart"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitmart"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitmart"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitmart"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitmart"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitmart"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bitmart"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["bitmart"]["api"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitmart"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitmart"]["csv"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    bitmax: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitmax"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitmax"]["api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitmax"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitmax"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitmax"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitmax"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitmax"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitmax"]["api"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitmax"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitmax"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitmax"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitmax"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitmax"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitmax"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    bitmex: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitmex"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitmex"]["api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitmex"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitmex"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitmex"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitmex"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitmex"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitmex"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bitmex"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["bitmex"]["api"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitmex"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitmex"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitmex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitmex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitmex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitmex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitmex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitmex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bitmex"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    bitpanda: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitpanda"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitpanda"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitpanda"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitpanda"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitpanda"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitpanda"]["api"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitpanda"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitpanda"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitpanda"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    bitrue: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitrue"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitrue"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitrue"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitrue"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitrue"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitrue"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitrue"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitrue"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    bitso: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitso"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitso"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitso"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    bitsong: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["bitsong"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["bitsong"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    bitstamp: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitstamp"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][8](),
              lang["importInstructions"]["bitstamp"]["api"]["step"][0][
                "items"
              ][9](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitstamp"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitstamp"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitstamp"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitstamp"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitstamp"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitstamp"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bitstamp"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bitstamp"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["bitstamp"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["bitstamp"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
      oauth: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitstamp"]["oauth"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitstamp"]["oauth"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitstamp"]["oauth"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitstamp"]["oauth"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitstamp"]["oauth"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    bittorrentchain: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["bittorrentchain"]["wallet-api"][
                "critical"
              ][0]["items"][0](),
            ],
            title:
              lang["importInstructions"]["bittorrentchain"]["wallet-api"][
                "critical"
              ][0]["title"](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bittorrentchain"]["wallet-api"][
                "step"
              ][0]["items"][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["bittorrentchain"]["wallet-api"][
                "note"
              ][0]["items"][0](),
            ],
          },
        ],
      },
    },
    bittrex: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["bittrex"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bittrex"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bittrex"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bittrex"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bittrex"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bittrex"]["api"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bittrex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bittrex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bittrex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bittrex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bittrex"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
            title:
              lang["importInstructions"]["bittrex"]["csv"]["step"][0][
                "title"
              ](),
          },
        ],
      },
    },
    bitvavo: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["bitvavo"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitvavo"]["api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitvavo"]["api"]["note"][0][
                "items"
              ][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bitvavo"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitvavo"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitvavo"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitvavo"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitvavo"]["api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["bitvavo"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bitvavo"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bitvavo"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bitvavo"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bitvavo"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    bityard: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["bityard"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bityard"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bityard"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bityard"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bityard"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bityard"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bityard"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["bityard"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    blast: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["blast"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["blast"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    blockchaindotcom: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["blockchaindotcom"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["blockchaindotcom"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["blockchaindotcom"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["blockchaindotcom"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["blockchaindotcom"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["blockchaindotcom"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["blockchaindotcom"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    blockearner: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["blockearner"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["blockearner"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["blockearner"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["blockearner"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    blockfi: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["blockfi"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["blockfi"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["blockfi"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["blockfi"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["blockfi"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["blockfi"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["blockfi"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    boba: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["boba"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["boba"]["wallet-api"]["critical"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["boba"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["boba"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    brave_software: {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["brave_software"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["brave_software"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["brave_software"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
            ],
          },
        ],
      },
    },
    bsv: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["bsv"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bsv"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bsv"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    btc: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["btc"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["btc"]["wallet-api"]["step"][0][
                  "items"
                ][0](args) as string,
              lang["importInstructions"]["btc"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["btc"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    btcAltNetwork: {
      "wallet-api": {
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["btcAltNetwork"]["wallet-api"][
                  "step"
                ][0]["items"][0](args) as string,
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["btcAltNetwork"]["wallet-api"][
                "note"
              ][0]["items"][0](),
            ],
          },
        ],
      },
    },
    btcalpha: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["btcalpha"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["btcalpha"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["btcalpha"]["api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    btccomau: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][8](),
              lang["importInstructions"]["btccomau"]["csv"]["step"][0][
                "items"
              ][9](),
            ],
          },
        ],
      },
    },
    btcmarkets: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["btcmarkets"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["btcmarkets"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["btcmarkets"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["btcmarkets"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["btcmarkets"]["api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["btcmarkets"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["btcmarkets"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["btcmarkets"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
            title:
              lang["importInstructions"]["btcmarkets"]["csv"]["step"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["btcmarkets"]["csv"]["step"][1][
                "items"
              ][0](),
              lang["importInstructions"]["btcmarkets"]["csv"]["step"][1][
                "items"
              ][1](),
              lang["importInstructions"]["btcmarkets"]["csv"]["step"][1][
                "items"
              ][2](),
            ],
            title:
              lang["importInstructions"]["btcmarkets"]["csv"]["step"][1][
                "title"
              ](),
          },
        ],
      },
    },
    bybit: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["bybit"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bybit"]["api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bybit"]["api"]["note"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bybit"]["api"]["note"][0][
                "items"
              ][3](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bybit"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bybit"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bybit"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bybit"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bybit"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bybit"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bybit"]["api"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["bybit"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["bybit"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["bybit"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["bybit"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["bybit"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["bybit"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["bybit"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["bybit"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["bybit"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["bybit"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
    },
    calebandbrown: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["calebandbrown"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["calebandbrown"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["calebandbrown"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["calebandbrown"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    canto: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["canto"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["canto"]["wallet-api"]["critical"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["canto"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["canto"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    cardano: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["cardano"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["cardano"]["wallet-api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["cardano"]["wallet-api"]["note"][0][
                "items"
              ][2](),
            ],
            title:
              lang["importInstructions"]["cardano"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["cardano"]["wallet-api"]["note"][1][
                "items"
              ][0](),
              lang["importInstructions"]["cardano"]["wallet-api"]["note"][1][
                "items"
              ][1](),
            ],
            title:
              lang["importInstructions"]["cardano"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["cardano"]["wallet-api"]["step"][0][
                  "items"
                ][0](args) as string,
              lang["importInstructions"]["cardano"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["cardano"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    cashapp: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["cashapp"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["cashapp"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["cashapp"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["cashapp"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    celestia: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["celestia"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["celestia"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    celo: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["celo"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["celo"]["wallet-api"]["critical"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["celo"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["celo"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    celsius: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["celsius"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["celsius"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["celsius"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["celsius"]["api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["celsius"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["celsius"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["celsius"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["celsius"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["celsius"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["celsius"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    cex: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["cex"]["api"]["step"][0]["items"][0](),
              lang["importInstructions"]["cex"]["api"]["step"][0]["items"][1](),
              lang["importInstructions"]["cex"]["api"]["step"][0]["items"][2](),
              lang["importInstructions"]["cex"]["api"]["step"][0]["items"][3](),
              lang["importInstructions"]["cex"]["api"]["step"][0]["items"][4](),
              lang["importInstructions"]["cex"]["api"]["step"][0]["items"][5](),
              lang["importInstructions"]["cex"]["api"]["step"][0]["items"][6](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["cex"]["csv"]["step"][0]["items"][0](),
              lang["importInstructions"]["cex"]["csv"]["step"][0]["items"][1](),
              lang["importInstructions"]["cex"]["csv"]["step"][0]["items"][2](),
              lang["importInstructions"]["cex"]["csv"]["step"][0]["items"][3](),
            ],
          },
        ],
      },
    },
    changelly: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["changelly"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["changelly"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["changelly"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    chihuahua: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["chihuahua"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["chihuahua"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    chiliz: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["chiliz"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["chiliz"]["csv"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    clvchain: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["clvchain"]["wallet-api"][
                "critical"
              ][0]["items"][0](),
            ],
            title:
              lang["importInstructions"]["clvchain"]["wallet-api"][
                "critical"
              ][0]["title"](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["clvchain"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["clvchain"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    coin98: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["coin98"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coin98"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coin98"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    coinbase: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinbase"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbase"]["api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinbase"]["api"]["note"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinbase"]["api"]["note"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinbase"]["api"]["note"][0][
                "items"
              ][4](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinbase"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbase"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinbase"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinbase"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinbase"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coinbase"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["coinbase"]["api"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["coinbase"]["api"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["coinbase"]["api"]["video"][0][
                "title"
              ](),
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinbase"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbase"]["csv"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinbase"]["csv"]["note"][0][
                "items"
              ][2](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["coinbase"]["csv"]["note"][1][
                "items"
              ][0](),
              lang["importInstructions"]["coinbase"]["csv"]["note"][1][
                "items"
              ][1](),
              lang["importInstructions"]["coinbase"]["csv"]["note"][1][
                "items"
              ][2](),
            ],
            title:
              lang["importInstructions"]["coinbase"]["csv"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinbase"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbase"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinbase"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinbase"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["coinbase"]["csv"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["coinbase"]["csv"]["video"][0][
                "title"
              ](),
          },
        ],
      },
      oauth: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinbase"]["oauth"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbase"]["oauth"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinbase"]["oauth"]["note"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinbase"]["oauth"]["note"][0][
                "items"
              ][3](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinbase"]["oauth"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbase"]["oauth"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinbase"]["oauth"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["coinbase"]["oauth"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["coinbase"]["oauth"]["video"][0][
                "title"
              ](),
          },
        ],
      },
    },
    "coinbase-wallet": {
      "bulk-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["coinbase-wallet"]["bulk-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["coinbase-wallet"]["bulk-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["coinbase-wallet"]["bulk-wallet"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["coinbase-wallet"]["bulk-wallet"][
                "step"
              ][0]["items"][3](),
              lang["importInstructions"]["coinbase-wallet"]["bulk-wallet"][
                "step"
              ][0]["items"][4](),
              lang["importInstructions"]["coinbase-wallet"]["bulk-wallet"][
                "step"
              ][0]["items"][5](),
              lang["importInstructions"]["coinbase-wallet"]["bulk-wallet"][
                "step"
              ][0]["items"][6](),
              lang["importInstructions"]["coinbase-wallet"]["bulk-wallet"][
                "step"
              ][0]["items"][7](),
            ],
          },
        ],
      },
    },
    coinbaseexchange: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinbaseexchange"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbaseexchange"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinbaseexchange"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinbaseexchange"]["api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    coinbaseprime: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinbaseprime"]["api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["coinbaseprime"]["api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][8](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][9](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][10](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][11](),
              lang["importInstructions"]["coinbaseprime"]["api"]["step"][0][
                "items"
              ][12](),
            ],
          },
        ],
      },
    },
    coinbasepro: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinbasepro"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbasepro"]["api"]["note"][0][
                "items"
              ][1](),
            ],
            title:
              lang["importInstructions"]["coinbasepro"]["api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinbasepro"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbasepro"]["api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinbasepro"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinbasepro"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinbasepro"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinbasepro"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinbasepro"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinbasepro"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coinbasepro"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["coinbasepro"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["coinbasepro"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    coinberry: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinberry"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinberry"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinberry"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinberry"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinberry"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    coincheck: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["coincheck"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coincheck"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coincheck"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coincheck"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coincheck"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coincheck"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["coincheck"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["coincheck"]["api"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    coincorner: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["coincorner"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coincorner"]["csv"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    coindcx: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["coindcx"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coindcx"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coindcx"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coindcx"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coindcx"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coindcx"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    coinex: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinex"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    coinexchange: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinexchange"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinexchange"]["csv"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    coinfalcon: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinfalcon"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinfalcon"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinfalcon"]["api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    coinfield: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinfield"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinfield"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinfield"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinfield"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinfield"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinfield"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    coinjar: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinjar"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinjar"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinjar"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinjar"]["api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinjar"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinjar"]["csv"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinjar"]["csv"]["note"][0][
                "items"
              ][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinjar"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinjar"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinjar"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinjar"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinjar"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coinjar"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["coinjar"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["coinjar"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    coinjarexchange: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinjarexchange"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinjarexchange"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinjarexchange"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinjarexchange"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinjarexchange"]["api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    coinlist: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinlist"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinlist"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinlist"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinlist"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinlist"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    coinlistpro: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinlistpro"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinlistpro"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinlistpro"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinlistpro"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinlistpro"]["api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinlistpro"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinlistpro"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinlistpro"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinlistpro"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    coinmetro: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinmetro"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinmetro"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinmetro"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinmetro"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinmetro"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coinmetro"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["coinmetro"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    coinsmart: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinsmart"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinsmart"]["csv"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    coinspot: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinspot"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinspot"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinspot"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinspot"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinspot"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinspot"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coinspot"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["coinspot"]["api"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["coinspot"]["api"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["coinspot"]["api"]["video"][0][
                "title"
              ](),
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["coinspot"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["coinspot"]["csv"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["coinspot"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinspot"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinspot"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinspot"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["coinspot"]["csv"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["coinspot"]["csv"]["video"][0][
                "title"
              ](),
          },
        ],
      },
    },
    coinsquare: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinsquare"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinsquare"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinsquare"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinsquare"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    coinstash: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinstash"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinstash"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinstash"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinstash"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    coinswitch: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["coinswitch"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coinswitch"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coinswitch"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coinswitch"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coinswitch"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coinswitch"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    cointracking: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["cointracking"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["cointracking"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["cointracking"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["cointracking"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["cointracking"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
            title:
              lang["importInstructions"]["cointracking"]["csv"]["step"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["cointracking"]["csv"]["step"][1][
                "items"
              ][0](),
              lang["importInstructions"]["cointracking"]["csv"]["step"][1][
                "items"
              ][1](),
              lang["importInstructions"]["cointracking"]["csv"]["step"][1][
                "items"
              ][2](),
            ],
            title:
              lang["importInstructions"]["cointracking"]["csv"]["step"][1][
                "title"
              ](),
          },
        ],
      },
    },
    cointree: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["cointree"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["cointree"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["cointree"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["cointree"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["cointree"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["cointree"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["cointree"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["cointree"]["api"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["cointree"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["cointree"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["cointree"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["cointree"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["cointree"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["cointree"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    "cosmos/ibc": {
      "wallet-api": {
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["cosmos/ibc"]["wallet-api"][
                  "step"
                ][0]["items"][0](args) as string,
              lang["importInstructions"]["cosmos/ibc"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["cosmos/ibc"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["cosmos/ibc"]["wallet-api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    cosmoshub: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["cosmoshub"]["wallet-api"][
                "critical"
              ][0]["items"][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["cosmoshub"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["cosmoshub"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    coss: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["coss"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coss"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coss"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coss"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coss"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coss"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["coss"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["coss"]["api"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["coss"]["api"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["coss"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["coss"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["coss"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["coss"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["coss"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["coss"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    crex24: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["crex24"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["crex24"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["crex24"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["crex24"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["crex24"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["crex24"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["crex24"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["crex24"]["api"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    cronos: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["cronos"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["cronos"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["cronos"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["cronos"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    cryptodotcom: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["cryptodotcom"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["cryptodotcom"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
            title:
              lang["importInstructions"]["cryptodotcom"]["csv"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["cryptodotcom"]["csv"]["note"][1][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["cryptodotcom"]["csv"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["cryptodotcom"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["cryptodotcom"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["cryptodotcom"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["cryptodotcom"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["cryptodotcom"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["cryptodotcom"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    cryptodotcomexchange: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["cryptodotcomexchange"]["api"][
                "note"
              ][0]["items"][0](),
              lang["importInstructions"]["cryptodotcomexchange"]["api"][
                "note"
              ][0]["items"][1](),
              lang["importInstructions"]["cryptodotcomexchange"]["api"][
                "note"
              ][0]["items"][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["cryptodotcomexchange"]["api"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["cryptodotcomexchange"]["api"][
                "step"
              ][0]["items"][1](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "note"
              ][0]["items"][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][3](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][4](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][5](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][6](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][7](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][8](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][9](),
              lang["importInstructions"]["cryptodotcomexchange"]["csv"][
                "step"
              ][0]["items"][10](),
            ],
          },
        ],
      },
    },
    cryptospend: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["cryptospend"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["cryptospend"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["cryptospend"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["cryptospend"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["cryptospend"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    cryptotaxcalculatorlegacy: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["cryptotaxcalculatorlegacy"]["csv"][
                "note"
              ][0]["items"][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["cryptotaxcalculatorlegacy"]["csv"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["cryptotaxcalculatorlegacy"]["csv"][
                "step"
              ][0]["items"][1](),
            ],
          },
        ],
      },
    },
    customCSV: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["customCSV"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["customCSV"]["csv"]["step"][0][
                "items"
              ][1](),
            ],
            title:
              lang["importInstructions"]["customCSV"]["csv"]["step"][0][
                "title"
              ](),
          },
        ],
      },
    },
    daedalus: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["daedalus"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["daedalus"]["api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["daedalus"]["api"]["note"][0][
                "items"
              ][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["daedalus"]["api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["daedalus"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["daedalus"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["daedalus"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["daedalus"]["csv"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    dash: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["dash"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["dash"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["dash"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    ddex: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["ddex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["ddex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ddex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ddex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ddex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["ddex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["ddex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["ddex"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["ddex"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    decentrex: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["decentrex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["decentrex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["decentrex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["decentrex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["decentrex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["decentrex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["decentrex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["decentrex"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["decentrex"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["decentrex"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
    },
    deltaBalances: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["deltaBalances"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["deltaBalances"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["deltaBalances"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["deltaBalances"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["deltaBalances"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["deltaBalances"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["deltaBalances"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["deltaBalances"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["deltaBalances"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    deribit: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["deribit"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["deribit"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["deribit"]["api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["deribit"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["deribit"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["deribit"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["deribit"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    digitalsurge: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["digitalsurge"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["digitalsurge"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["digitalsurge"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["digitalsurge"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["digitalsurge"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["digitalsurge"]["api"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["digitalsurge"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["digitalsurge"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["digitalsurge"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["digitalsurge"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["digitalsurge"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["digitalsurge"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    doge: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["doge"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["doge"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["doge"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    dydx: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["dydx"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["dydx"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["dydx"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["dydx"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["dydx"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["dydx"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["dydx"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    dydx4: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["dydx4"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["dydx4"]["wallet-api"]["critical"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["dydx4"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["dydx4"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    dymension: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["dymension"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["dymension"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    easyTrade: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["easyTrade"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["easyTrade"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["easyTrade"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["easyTrade"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["easyTrade"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["easyTrade"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["easyTrade"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["easyTrade"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["easyTrade"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    easycrypto: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["easycrypto"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["easycrypto"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["easycrypto"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["easycrypto"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["easycrypto"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    elbaite: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["elbaite"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["elbaite"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["elbaite"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["elbaite"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    enclaves: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["enclaves"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["enclaves"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["enclaves"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["enclaves"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["enclaves"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["enclaves"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["enclaves"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["enclaves"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["enclaves"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["enclaves"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
    },
    enkrypt: {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["enkrypt"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["enkrypt"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["enkrypt"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["enkrypt"]["soft-wallet"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["enkrypt"]["soft-wallet"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    ercDex: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["ercDex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["ercDex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ercDex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ercDex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ercDex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["ercDex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["ercDex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["ercDex"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    eth: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["eth"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["eth"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["eth"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["eth"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    ethen: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["ethen"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["ethen"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ethen"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ethen"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ethen"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["ethen"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["ethen"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["ethen"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["ethen"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["ethen"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
    },
    etherc: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["etherc"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["etherc"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["etherc"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["etherc"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["etherc"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["etherc"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["etherc"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["etherc"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["etherc"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    etherdelta: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["etherdelta"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["etherdelta"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["etherdelta"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["etherdelta"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["etherdelta"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["etherdelta"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["etherdelta"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["etherdelta"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["etherdelta"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["etherdelta"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
    },
    "ethereum-classic": {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["ethereum-classic"]["wallet-api"][
                "note"
              ][0]["items"][0](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["ethereum-classic"]["wallet-api"][
                "note"
              ][1]["items"][0](),
            ],
            title:
              lang["importInstructions"]["ethereum-classic"]["wallet-api"][
                "note"
              ][1]["title"](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["ethereum-classic"]["wallet-api"][
                "step"
              ][0]["items"][0](),
            ],
          },
        ],
      },
    },
    etherscan: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["etherscan"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["etherscan"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    ethex: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["ethex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["ethex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ethex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ethex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ethex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["ethex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["ethex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["ethex"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    ethfinex: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["ethfinex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["ethfinex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ethfinex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ethfinex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ethfinex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["ethfinex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["ethfinex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["ethfinex"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["ethfinex"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    etoro: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["etoro"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["etoro"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
            title:
              lang["importInstructions"]["etoro"]["csv"]["note"][0]["title"](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["etoro"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["etoro"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["etoro"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["etoro"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["etoro"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["etoro"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["etoro"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    evmos: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["evmos"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
              lang["importInstructions"]["evmos"]["wallet-api"]["critical"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["evmos"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["evmos"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    exmo: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["exmo"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["exmo"]["api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["exmo"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["exmo"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["exmo"]["api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    exodus: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["exodus"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["exodus"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["exodus"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["exodus"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["exodus"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["exodus"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["exodus"]["soft-wallet"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["exodus"]["soft-wallet"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    fantom: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["fantom"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["fantom"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["fantom"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["fantom"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    "fearless-wallet": {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["fearless-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["fearless-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["fearless-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["fearless-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][3](),
              lang["importInstructions"]["fearless-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][4](),
            ],
          },
        ],
      },
    },
    fetchai: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["fetchai"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["fetchai"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    flare: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["flare"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["flare"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["flare"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["flare"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    ftx: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["ftx"]["csv"]["step"][0]["items"][0](),
              lang["importInstructions"]["ftx"]["csv"]["step"][0]["items"][1](),
              lang["importInstructions"]["ftx"]["csv"]["step"][0]["items"][2](),
            ],
            title:
              lang["importInstructions"]["ftx"]["csv"]["step"][0]["title"](),
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["ftx"]["csv"]["note"][0]["items"][0](),
              lang["importInstructions"]["ftx"]["csv"]["note"][0]["items"][1](),
            ],
            title:
              lang["importInstructions"]["ftx"]["csv"]["note"][0]["title"](),
          },
        ],
      },
      api: {
        step: [
          {
            title:
              lang["importInstructions"]["ftx"]["api"]["step"][0]["title"](),
          },
        ],
      },
    },
    ftxus: {
      api: {
        critical: [
          {
            items: [
              lang["importInstructions"]["ftxus"]["api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["ftxus"]["api"]["critical"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            title:
              lang["importInstructions"]["ftxus"]["api"]["step"][0]["title"](),
          },
        ],
      },
      csv: {
        critical: [
          {
            items: [
              lang["importInstructions"]["ftxus"]["csv"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["ftxus"]["csv"]["critical"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            title:
              lang["importInstructions"]["ftxus"]["csv"]["step"][0]["title"](),
          },
        ],
      },
    },
    gate: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["gate"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["gate"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["gate"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["gate"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["gate"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["gate"]["api"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["gate"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["gate"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["gate"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["gate"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["gate"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["gate"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    gateio: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["gateio"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["gateio"]["csv"]["note"][0]["title"](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["gateio"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["gateio"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["gateio"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["gateio"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["gateio"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["gateio"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["gateio"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    gemini: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["gemini"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["gemini"]["api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["gemini"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["gemini"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["gemini"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["gemini"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["gemini"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["gemini"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["gemini"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["gemini"]["api"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["gemini"]["api"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
      oauth: {
        step: [
          {
            items: [
              lang["importInstructions"]["gemini"]["oauth"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["gemini"]["oauth"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["gemini"]["oauth"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["gemini"]["oauth"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["gemini"]["oauth"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    "giddy-wallet": {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["giddy-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["giddy-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["giddy-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["giddy-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][3](),
            ],
          },
        ],
      },
    },
    graviex: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["graviex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["graviex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["graviex"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    grs: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["grs"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["grs"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["grs"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    hedera: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["hedera"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["hedera"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    hitbtc: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["hitbtc"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["hitbtc"]["api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["hitbtc"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["hitbtc"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["hitbtc"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["hitbtc"]["api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["hitbtc"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["hitbtc"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["hitbtc"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["hitbtc"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["hitbtc"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["hitbtc"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["hitbtc"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    hodlnaut: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["hodlnaut"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["hodlnaut"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["hodlnaut"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["hodlnaut"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["hodlnaut"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    honeyswap: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["honeyswap"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["honeyswap"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["honeyswap"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    hotbit: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["hotbit"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["hotbit"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["hotbit"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    huobi: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["huobi"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["huobi"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["huobi"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["huobi"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["huobi"]["api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["huobi"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["huobi"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["huobi"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    hyperliquid: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["hyperliquid"]["wallet-api"][
                "note"
              ][0]["items"][0](),
            ],
            title:
              lang["importInstructions"]["hyperliquid"]["wallet-api"][
                "note"
              ][0]["title"](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["hyperliquid"]["wallet-api"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["hyperliquid"]["wallet-api"][
                "step"
              ][0]["items"][1](),
            ],
          },
        ],
      },
    },
    ice3x: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["ice3x"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ice3x"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ice3x"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ice3x"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    idex: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["idex"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["idex"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["idex"]["api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["idex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["idex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["idex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["idex"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    idt: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["idt"]["csv"]["note"][0]["items"][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["idt"]["csv"]["step"][0]["items"][0](),
              lang["importInstructions"]["idt"]["csv"]["step"][0]["items"][1](),
              lang["importInstructions"]["idt"]["csv"]["step"][0]["items"][2](),
              lang["importInstructions"]["idt"]["csv"]["step"][0]["items"][3](),
              lang["importInstructions"]["idt"]["csv"]["step"][0]["items"][4](),
              lang["importInstructions"]["idt"]["csv"]["step"][0]["items"][5](),
              lang["importInstructions"]["idt"]["csv"]["step"][0]["items"][6](),
            ],
          },
        ],
      },
    },
    immutable: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["immutable"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["immutable"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["immutable"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["immutable"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    independentreserve: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["independentreserve"]["api"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["independentreserve"]["api"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["independentreserve"]["api"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["independentreserve"]["api"][
                "step"
              ][0]["items"][3](),
              lang["importInstructions"]["independentreserve"]["api"][
                "step"
              ][0]["items"][4](),
              lang["importInstructions"]["independentreserve"]["api"][
                "step"
              ][0]["items"][5](),
              lang["importInstructions"]["independentreserve"]["api"][
                "step"
              ][0]["items"][6](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["independentreserve"]["api"][
              "video"
            ][0]["link"](),
            title:
              lang["importInstructions"]["independentreserve"]["api"][
                "video"
              ][0]["title"](),
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][0]["items"][1](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][1]["items"][0](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][1]["items"][1](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][1]["items"][2](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][1]["items"][3](),
            ],
            title:
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][1]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][2]["items"][0](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][2]["items"][1](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][2]["items"][2](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][2]["items"][3](),
            ],
            title:
              lang["importInstructions"]["independentreserve"]["csv"][
                "step"
              ][2]["title"](),
          },
        ],
        video: [
          {
            items: [
              lang["importInstructions"]["independentreserve"]["csv"][
                "video"
              ][0]["items"][0](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "video"
              ][0]["items"][1](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "video"
              ][0]["items"][2](),
              lang["importInstructions"]["independentreserve"]["csv"][
                "video"
              ][0]["items"][3](),
            ],
            link: lang["importInstructions"]["independentreserve"]["csv"][
              "video"
            ][0]["link"](),
            title:
              lang["importInstructions"]["independentreserve"]["csv"][
                "video"
              ][0]["title"](),
          },
        ],
      },
    },
    injective: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["injective"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["injective"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    iotex: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["iotex"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["iotex"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["iotex"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    "jaxx-wallet": {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][0](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][1](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][2](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][3](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][4](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][5](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][6](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][7](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][8](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][9](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["items"][10](),
            ],
            title:
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][1]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][2]["items"][0](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][2]["items"][1](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][2]["items"][2](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][2]["items"][3](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][2]["items"][4](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][2]["items"][5](),
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][2]["items"][6](),
            ],
            title:
              lang["importInstructions"]["jaxx-wallet"]["soft-wallet"][
                "step"
              ][2]["title"](),
          },
        ],
      },
    },
    juno: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["juno"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
              lang["importInstructions"]["juno"]["wallet-api"]["critical"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["juno"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["juno"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    kaspa: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["kaspa"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["kaspa"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    kava: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["kava"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["kava"]["wallet-api"]["critical"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["kava"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["kava"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    kraken: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["kraken"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["kraken"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["kraken"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["kraken"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["kraken"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["kraken"]["api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["kraken"]["api"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["kraken"]["api"]["video"][0][
                "title"
              ](),
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["kraken"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["kraken"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["kraken"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["kraken"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["kraken"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["kraken"]["csv"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["kraken"]["csv"]["video"][0][
                "title"
              ](),
          },
        ],
      },
      oauth: {
        step: [
          {
            items: [
              lang["importInstructions"]["kraken"]["oauth"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["kraken"]["oauth"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["kraken"]["oauth"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["kraken"]["oauth"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["kraken"]["oauth"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["kraken"]["oauth"]["video"][0][
                "title"
              ](),
          },
        ],
      },
    },
    kucoin: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["kucoin"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["kucoin"]["api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["kucoin"]["api"]["note"][0][
                "items"
              ][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["kucoin"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["kucoin"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["kucoin"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["kucoin"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["kucoin"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["kucoin"]["api"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["kucoin"]["api"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["kucoin"]["api"]["video"][0][
                "title"
              ](),
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["kucoin"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["kucoin"]["csv"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["kucoin"]["csv"]["note"][0][
                "items"
              ][2](),
              lang["importInstructions"]["kucoin"]["csv"]["note"][0][
                "items"
              ][3](),
              lang["importInstructions"]["kucoin"]["csv"]["note"][0][
                "items"
              ][4](),
              lang["importInstructions"]["kucoin"]["csv"]["note"][0][
                "items"
              ][5](),
              lang["importInstructions"]["kucoin"]["csv"]["note"][0][
                "items"
              ][6](),
            ],
            title:
              lang["importInstructions"]["kucoin"]["csv"]["note"][0]["title"](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["kucoin"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["kucoin"]["csv"]["step"][1][
                "items"
              ][0](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][1][
                "items"
              ][1](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][1][
                "items"
              ][2](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][1][
                "items"
              ][3](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][1][
                "items"
              ][4](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][1][
                "items"
              ][5](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][1][
                "items"
              ][6](),
              lang["importInstructions"]["kucoin"]["csv"]["step"][1][
                "items"
              ][7](),
            ],
            title:
              lang["importInstructions"]["kucoin"]["csv"]["step"][1]["title"](),
          },
          {
            title:
              lang["importInstructions"]["kucoin"]["csv"]["step"][2]["title"](),
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["kucoin"]["csv"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["kucoin"]["csv"]["video"][0][
                "title"
              ](),
          },
        ],
      },
    },
    kujira: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["kujira"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["kujira"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["kujira"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    kyber: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["kyber"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["kyber"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["kyber"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["kyber"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["kyber"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["kyber"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["kyber"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["kyber"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["kyber"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    kyve_network: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["kyve_network"]["wallet-api"][
                "step"
              ][0]["items"][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["kyve_network"]["wallet-api"][
                "note"
              ][0]["items"][0](),
            ],
          },
        ],
      },
    },
    latoken: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["latoken"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["latoken"]["csv"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    leather_wallet: {
      "connect-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["leather_wallet"]["connect-wallet"][
                "step"
              ][0]["items"][0](),
            ],
          },
        ],
      },
    },
    ledger_blue: {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][0]["items"][3](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][1]["items"][0](),
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][1]["items"][1](),
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][1]["items"][2](),
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][1]["items"][3](),
            ],
            title:
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][1]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][2]["items"][0](),
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][2]["items"][1](),
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][2]["items"][2](),
            ],
            title:
              lang["importInstructions"]["ledger_blue"]["soft-wallet"][
                "step"
              ][2]["title"](),
          },
        ],
      },
    },
    ledgerwallet: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["ledgerwallet"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["ledgerwallet"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ledgerwallet"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ledgerwallet"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ledgerwallet"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["ledgerwallet"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["ledgerwallet"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][1]["items"][0](),
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][1]["items"][1](),
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][1]["items"][2](),
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][1]["items"][3](),
            ],
            title:
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][1]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][2]["items"][0](),
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][2]["items"][1](),
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][2]["items"][2](),
            ],
            title:
              lang["importInstructions"]["ledgerwallet"]["soft-wallet"][
                "step"
              ][2]["title"](),
          },
        ],
      },
    },
    ledn: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["ledn"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["ledn"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ledn"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ledn"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ledn"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["ledn"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    linea: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["linea"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["linea"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    liqui: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["liqui"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["liqui"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["liqui"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    liquid: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["liquid"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["liquid"]["api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["liquid"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["liquid"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["liquid"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["liquid"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["liquid"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["liquid"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["liquid"]["api"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["liquid"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["liquid"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["liquid"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["liquid"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["liquid"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    localbitcoins: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["localbitcoins"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["localbitcoins"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["localbitcoins"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["localbitcoins"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["localbitcoins"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["localbitcoins"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["localbitcoins"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    lros: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["lros"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["lros"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["lros"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["lros"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    ltc: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["ltc"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ltc"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ltc"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    luno: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["luno"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["luno"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["luno"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["luno"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["luno"]["api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["luno"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["luno"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["luno"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["luno"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    lykke: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["lykke"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["lykke"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["lykke"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["lykke"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["lykke"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    "manta-pacific": {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["manta-pacific"]["wallet-api"][
                "note"
              ][0]["items"][0](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["manta-pacific"]["wallet-api"][
                "note"
              ][1]["items"][0](),
            ],
            title:
              lang["importInstructions"]["manta-pacific"]["wallet-api"][
                "note"
              ][1]["title"](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["manta-pacific"]["wallet-api"][
                "step"
              ][0]["items"][0](),
            ],
          },
        ],
      },
    },
    mantle: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["mantle"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["mantle"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    mercatox: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["mercatox"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["mercatox"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["mercatox"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    metamask: {
      "connect-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["metamask"]["connect-wallet"][
                "step"
              ][0]["items"][0](),
            ],
          },
        ],
      },
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["metamask"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["metamask"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["metamask"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["metamask"]["soft-wallet"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    metis: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["metis"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["metis"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    mode: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["mode"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["mode"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["mode"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["mode"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    moonbeam: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["moonbeam"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["moonbeam"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["moonbeam"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["moonbeam"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    moonriver: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["moonriver"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["moonriver"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    mudrex: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["mudrex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["mudrex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["mudrex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["mudrex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["mudrex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["mudrex"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    multichain: {
      "wallet-api": {
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["multichain"]["wallet-api"][
                  "step"
                ][0]["items"][0](args) as string,
              lang["importInstructions"]["multichain"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["multichain"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["multichain"]["wallet-api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["multichain"]["wallet-api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    mxc: {
      api: {
        critical: [
          {
            items: [
              lang["importInstructions"]["mxc"]["api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["mxc"]["api"]["critical"][0][
                "title"
              ](),
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["mxc"]["api"]["note"][0]["items"][0](),
              lang["importInstructions"]["mxc"]["api"]["note"][0]["items"][1](),
              lang["importInstructions"]["mxc"]["api"]["note"][0]["items"][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["mxc"]["api"]["step"][0]["items"][0](),
              lang["importInstructions"]["mxc"]["api"]["step"][0]["items"][1](),
              lang["importInstructions"]["mxc"]["api"]["step"][0]["items"][2](),
              lang["importInstructions"]["mxc"]["api"]["step"][0]["items"][3](),
              lang["importInstructions"]["mxc"]["api"]["step"][0]["items"][4](),
              lang["importInstructions"]["mxc"]["api"]["step"][0]["items"][5](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][0](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][1](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][2](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][3](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][4](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][5](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][6](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][7](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][8](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0]["items"][9](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0][
                "items"
              ][10](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0][
                "items"
              ][11](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0][
                "items"
              ][12](),
              lang["importInstructions"]["mxc"]["csv"]["step"][0][
                "items"
              ][13](),
            ],
          },
        ],
      },
    },
    myEtherWallet: {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["myEtherWallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["myEtherWallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["myEtherWallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
            ],
          },
        ],
      },
    },
    myTonWallet: {
      "soft-wallet": {
        note: [
          {
            items: [
              lang["importInstructions"]["myTonWallet"]["soft-wallet"][
                "note"
              ][0]["items"][0](),
            ],
            title:
              lang["importInstructions"]["myTonWallet"]["soft-wallet"][
                "note"
              ][0]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["myTonWallet"]["soft-wallet"][
                "note"
              ][1]["items"][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["myTonWallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["myTonWallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["myTonWallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
            ],
          },
        ],
      },
    },
    ndax: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["ndax"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ndax"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ndax"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    near: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["near"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["near"]["wallet-api"]["critical"][0][
                "title"
              ](),
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["near"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["near"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    neo: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["neo"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["neo"]["wallet-api"]["critical"][0][
                "title"
              ](),
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["neo"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["neo"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["neo"]["wallet-api"]["step"][0][
                  "items"
                ][0](args) as string,
              lang["importInstructions"]["neo"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["neo"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["neo"]["wallet-api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    netcoins: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["netcoins"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["netcoins"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["netcoins"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["netcoins"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["netcoins"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    neutron: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["neutron"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["neutron"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    newton: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["newton"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["newton"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["newton"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["newton"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["newton"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["newton"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    nexo: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["nexo"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["nexo"]["csv"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["nexo"]["csv"]["note"][0][
                "items"
              ][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["nexo"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["nexo"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["nexo"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["nexo"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    nftbank: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["nftbank"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["nftbank"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["nftbank"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["nftbank"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["nftbank"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["nftbank"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["nftbank"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["nftbank"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    nicehash: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["nicehash"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["nicehash"]["api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][8](),
              lang["importInstructions"]["nicehash"]["api"]["step"][0][
                "items"
              ][9](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["nicehash"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["nicehash"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["nicehash"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["nicehash"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["nicehash"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["nicehash"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["nicehash"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["nicehash"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["nicehash"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["nicehash"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    nolus: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["nolus"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["nolus"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    oasisdex: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["oasisdex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["oasisdex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["oasisdex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["oasisdex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["oasisdex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["oasisdex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["oasisdex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["oasisdex"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["oasisdex"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    oceanex: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["oceanex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["oceanex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["oceanex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["oceanex"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    okex: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["okex"]["api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["okex"]["api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["okex"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["okex"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["okex"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["okex"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["okex"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["okex"]["api"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["okex"]["api"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["okex"]["api"]["video"][0]["title"](),
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["okex"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["okex"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["okex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["okex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["okex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["okex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["okex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["okex"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["okex"]["csv"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["okex"]["csv"]["video"][0]["title"](),
          },
        ],
      },
      oauth: {
        step: [
          {
            items: [
              lang["importInstructions"]["okex"]["oauth"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["okex"]["oauth"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["okex"]["oauth"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    openMask: {
      "soft-wallet": {
        note: [
          {
            items: [
              lang["importInstructions"]["openMask"]["soft-wallet"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["openMask"]["soft-wallet"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["openMask"]["soft-wallet"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["openMask"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["openMask"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["openMask"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    opensea: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["opensea"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["opensea"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["opensea"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    optimism: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["optimism"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["optimism"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    osmosis: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["osmosis"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    pancakeSwap: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["pancakeSwap"]["wallet-api"][
                "note"
              ][0]["items"][0](),
              lang["importInstructions"]["pancakeSwap"]["wallet-api"][
                "note"
              ][0]["items"][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["pancakeSwap"]["wallet-api"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["pancakeSwap"]["wallet-api"][
                "step"
              ][0]["items"][1](),
            ],
          },
        ],
      },
    },
    paradex: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["paradex"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["paradex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["paradex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["paradex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["paradex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["paradex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["paradex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["paradex"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    phantom: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["phantom"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["phantom"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    phemex: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["phemex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["phemex"]["csv"]["step"][0][
                "items"
              ][1](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["phemex"]["csv"]["step"][1][
                "items"
              ][0](),
              lang["importInstructions"]["phemex"]["csv"]["step"][1][
                "items"
              ][1](),
              lang["importInstructions"]["phemex"]["csv"]["step"][1][
                "items"
              ][2](),
              lang["importInstructions"]["phemex"]["csv"]["step"][1][
                "items"
              ][3](),
              lang["importInstructions"]["phemex"]["csv"]["step"][1][
                "items"
              ][4](),
            ],
            title:
              lang["importInstructions"]["phemex"]["csv"]["step"][1]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["phemex"]["csv"]["step"][2][
                "items"
              ][0](),
              lang["importInstructions"]["phemex"]["csv"]["step"][2][
                "items"
              ][1](),
              lang["importInstructions"]["phemex"]["csv"]["step"][2][
                "items"
              ][2](),
              lang["importInstructions"]["phemex"]["csv"]["step"][2][
                "items"
              ][3](),
              lang["importInstructions"]["phemex"]["csv"]["step"][2][
                "items"
              ][4](),
              lang["importInstructions"]["phemex"]["csv"]["step"][2][
                "items"
              ][5](),
            ],
            title:
              lang["importInstructions"]["phemex"]["csv"]["step"][2]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["phemex"]["csv"]["step"][3][
                "items"
              ][0](),
              lang["importInstructions"]["phemex"]["csv"]["step"][3][
                "items"
              ][1](),
              lang["importInstructions"]["phemex"]["csv"]["step"][3][
                "items"
              ][2](),
              lang["importInstructions"]["phemex"]["csv"]["step"][3][
                "items"
              ][3](),
              lang["importInstructions"]["phemex"]["csv"]["step"][3][
                "items"
              ][4](),
              lang["importInstructions"]["phemex"]["csv"]["step"][3][
                "items"
              ][5](),
            ],
            title:
              lang["importInstructions"]["phemex"]["csv"]["step"][3]["title"](),
          },
        ],
      },
    },
    pionex: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["pionex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["pionex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["pionex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["pionex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["pionex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["pionex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["pionex"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    polkadot: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["polkadot"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["polkadot"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["polkadot"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["polkadot"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    polkadotjs: {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["polkadotjs"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["polkadotjs"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["polkadotjs"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["polkadotjs"]["soft-wallet"][
                "step"
              ][0]["items"][3](),
              lang["importInstructions"]["polkadotjs"]["soft-wallet"][
                "step"
              ][0]["items"][4](),
              lang["importInstructions"]["polkadotjs"]["soft-wallet"][
                "step"
              ][0]["items"][5](),
            ],
          },
        ],
      },
    },
    polkagate: {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["polkagate"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["polkagate"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["polkagate"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["polkagate"]["soft-wallet"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["polkagate"]["soft-wallet"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["polkagate"]["soft-wallet"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    poloniex: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["poloniex"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["poloniex"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["poloniex"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["poloniex"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["poloniex"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["poloniex"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["poloniex"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["poloniex"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["poloniex"]["api"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["poloniex"]["api"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["poloniex"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["poloniex"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["poloniex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["poloniex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["poloniex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["poloniex"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["poloniex"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["poloniex"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["poloniex"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    polygon: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["polygon"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["polygon"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["polygon"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["polygon"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["polygon"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    "polygon-zkevm": {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["polygon-zkevm"]["wallet-api"][
                "note"
              ][0]["items"][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["polygon-zkevm"]["wallet-api"][
                "step"
              ][0]["items"][0](),
            ],
          },
        ],
      },
    },
    pooltool: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["pooltool"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["pooltool"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["pooltool"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["pooltool"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["pooltool"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["pooltool"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["pooltool"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    probit: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["probit"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["probit"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["probit"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["probit"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["probit"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["probit"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["probit"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["probit"]["api"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["probit"]["api"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    protocol: {
      "wallet-api": {
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["protocol"]["wallet-api"]["step"][0][
                  "items"
                ][0](args) as string,
              lang["importInstructions"]["protocol"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["protocol"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["protocol"]["wallet-api"]["step"][0][
                "items"
              ][3](),
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["protocol"]["wallet-api"]["step"][0][
                  "items"
                ][4](args) as string,
            ],
          },
        ],
      },
    },
    pulsechain: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["pulsechain"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["pulsechain"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["pulsechain"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["pulsechain"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    quantfury: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["quantfury"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["quantfury"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["quantfury"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["quantfury"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["quantfury"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    quickswap: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["quickswap"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["quickswap"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["quickswap"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    "rabby-wallet": {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["rabby-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["rabby-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["rabby-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
            ],
          },
        ],
      },
    },
    radarRelay: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["radarRelay"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["radarRelay"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["radarRelay"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["radarRelay"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["radarRelay"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["radarRelay"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["radarRelay"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["radarRelay"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    "rainbow-wallet": {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["rainbow-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["rainbow-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["rainbow-wallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
            ],
          },
        ],
      },
    },
    relaypay: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["relaypay"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["relaypay"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["relaypay"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["relaypay"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["relaypay"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["relaypay"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    revolut: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["revolut"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["revolut"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["revolut"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["revolut"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["revolut"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["revolut"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["revolut"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["revolut"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    ripple: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["ripple"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ripple"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["ripple"]["wallet-api"]["step"][0][
                  "items"
                ][0](args) as string,
              lang["importInstructions"]["ripple"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ripple"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ripple"]["wallet-api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    robinhood: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["robinhood"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["robinhood"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["robinhood"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["robinhood"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["robinhood"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["robinhood"]["api"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["robinhood"]["api"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["robinhood"]["api"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["robinhood"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["robinhood"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["robinhood"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["robinhood"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["robinhood"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["robinhood"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["robinhood"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    robinhood_wallet: {
      "soft-wallet": {
        note: [
          {
            items: [
              lang["importInstructions"]["robinhood_wallet"]["soft-wallet"][
                "note"
              ][0]["items"][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["robinhood_wallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["robinhood_wallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["robinhood_wallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["robinhood_wallet"]["soft-wallet"][
                "step"
              ][0]["items"][3](),
              lang["importInstructions"]["robinhood_wallet"]["soft-wallet"][
                "step"
              ][0]["items"][4](),
            ],
          },
        ],
      },
    },
    ronin: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][8](),
              lang["importInstructions"]["ronin"]["csv"]["step"][0][
                "items"
              ][9](),
            ],
          },
        ],
      },
    },
    saga: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["saga"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["saga"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    scroll: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["scroll"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["scroll"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    sei: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["sei"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["sei"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["sei"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    sentinel: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["sentinel"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["sentinel"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    shakepay: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["shakepay"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["shakepay"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["shakepay"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["shakepay"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["shakepay"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    sharkRelay: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["sharkRelay"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["sharkRelay"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["sharkRelay"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["sharkRelay"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["sharkRelay"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["sharkRelay"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["sharkRelay"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["sharkRelay"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    singularx: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["singularx"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["singularx"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["singularx"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["singularx"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["singularx"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["singularx"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["singularx"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["singularx"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["singularx"]["csv"]["step"][0][
                "items"
              ][7](),
            ],
          },
        ],
      },
    },
    softWallet: {
      "soft-wallet": {
        note: [
          {
            items: [
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "note"
              ][0]["items"][0](),
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "note"
              ][0]["items"][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][0]["items"][3](),
            ],
            title:
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][0]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][1]["items"][0](),
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][1]["items"][1](),
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][1]["items"][2](),
            ],
            title:
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][1]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][2]["items"][0](),
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][2]["items"][1](),
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][2]["items"][2](),
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][2]["items"][3](),
            ],
            title:
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "step"
              ][2]["title"](),
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["softWallet"]["soft-wallet"][
              "video"
            ][0]["link"](),
            title:
              lang["importInstructions"]["softWallet"]["soft-wallet"][
                "video"
              ][0]["title"](),
          },
        ],
      },
    },
    solana: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["solana"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["solana"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["solana"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["solana"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    "solana-v2": {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["solana-v2"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["solana-v2"]["wallet-api"][
                  "step"
                ][0]["items"][0](args) as string,
              lang["importInstructions"]["solana-v2"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["solana-v2"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    solflare: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["solflare"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["solflare"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    sollet: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["sollet"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["sollet"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    sonic: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["sonic"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["sonic"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["sonic"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["sonic"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["sonic"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["sonic"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    spiritswap: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["spiritswap"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["spiritswap"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    spookyswap: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["spookyswap"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["spookyswap"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    stacks: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["stacks"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["stacks"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    staketax: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["staketax"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["staketax"]["csv"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    stargaze: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["stargaze"]["wallet-api"][
                "critical"
              ][0]["items"][0](),
              lang["importInstructions"]["stargaze"]["wallet-api"][
                "critical"
              ][0]["items"][1](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["stargaze"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["stargaze"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    starknet: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["starknet"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["starknet"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["starknet"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    stellar: {
      "wallet-api": {
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["stellar"]["wallet-api"]["step"][0][
                  "items"
                ][0](args) as string,
              lang["importInstructions"]["stellar"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["stellar"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["stellar"]["wallet-api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    stex: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["stex"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["stex"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["stex"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["stex"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    stormrake: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["stormrake"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["stormrake"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["stormrake"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    "substrate-chains": {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["substrate-chains"]["wallet-api"][
                "note"
              ][0]["items"][0](),
              lang["importInstructions"]["substrate-chains"]["wallet-api"][
                "note"
              ][0]["items"][1](),
            ],
            title:
              lang["importInstructions"]["substrate-chains"]["wallet-api"][
                "note"
              ][0]["title"](),
          },
          {
            items: [
              lang["importInstructions"]["substrate-chains"]["wallet-api"][
                "note"
              ][1]["items"][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["substrate-chains"]["wallet-api"][
                  "step"
                ][0]["items"][0](args) as string,
            ],
          },
        ],
      },
    },
    subwallet: {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["subwallet"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["subwallet"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["subwallet"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["subwallet"]["soft-wallet"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["subwallet"]["soft-wallet"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["subwallet"]["soft-wallet"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    sui: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["sui"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["sui"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["sui"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["sui"]["wallet-api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    swissborg: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["swissborg"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["swissborg"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["swissborg"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["swissborg"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["swissborg"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["swissborg"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["swissborg"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    switcheo: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["switcheo"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["switcheo"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["switcheo"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["switcheo"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["switcheo"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    swyftx: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["swyftx"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["swyftx"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["swyftx"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["swyftx"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["swyftx"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["swyftx"]["api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["swyftx"]["api"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["swyftx"]["api"]["video"][0][
                "title"
              ](),
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["swyftx"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["swyftx"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["swyftx"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["swyftx"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["swyftx"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
        video: [
          {
            link: lang["importInstructions"]["swyftx"]["csv"]["video"][0][
              "link"
            ](),
            title:
              lang["importInstructions"]["swyftx"]["csv"]["video"][0][
                "title"
              ](),
          },
        ],
      },
    },
    taiko: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["taiko"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["taiko"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["taiko"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["taiko"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    talisman: {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["talisman"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["talisman"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["talisman"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["talisman"]["soft-wallet"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["talisman"]["soft-wallet"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["talisman"]["soft-wallet"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    terra: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["terra"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["terra"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["terra"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    terra2: {
      "wallet-api": {
        critical: [
          {
            items: [
              lang["importInstructions"]["terra2"]["wallet-api"]["critical"][0][
                "items"
              ][0](),
            ],
          },
        ],
        note: [
          {
            items: [
              lang["importInstructions"]["terra2"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["terra2"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    theta: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["theta"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["theta"]["wallet-api"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["theta"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    thetaboard: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["thetaboard"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["thetaboard"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["thetaboard"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["thetaboard"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["thetaboard"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["thetaboard"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    thetawallet: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["thetawallet"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["thetawallet"]["api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["thetawallet"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["thetawallet"]["csv"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["thetawallet"]["csv"]["note"][0][
                "items"
              ][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["thetawallet"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["thetawallet"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["thetawallet"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["thetawallet"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    thorchain: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["thorchain"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["thorchain"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["thorchain"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    toast: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["toast"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["toast"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["toast"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["toast"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    tokenJar: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["tokenJar"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["tokenJar"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["tokenJar"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tokenJar"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["tokenJar"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["tokenJar"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["tokenJar"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["tokenJar"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    tokenStore: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["tokenStore"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["tokenStore"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["tokenStore"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tokenStore"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["tokenStore"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["tokenStore"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["tokenStore"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["tokenStore"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["tokenStore"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["tokenStore"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
    },
    tokenion: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["tokenion"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["tokenion"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["tokenion"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tokenion"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["tokenion"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["tokenion"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["tokenion"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["tokenion"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    ton: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["ton"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["ton"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["ton"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["ton"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    tonWallet: {
      "soft-wallet": {
        note: [
          {
            items: [
              lang["importInstructions"]["tonWallet"]["soft-wallet"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["tonWallet"]["soft-wallet"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["tonWallet"]["soft-wallet"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["tonWallet"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["tonWallet"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tonWallet"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["tonWallet"]["soft-wallet"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    toncoin: {
      "soft-wallet": {
        note: [
          {
            items: [
              lang["importInstructions"]["toncoin"]["soft-wallet"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["toncoin"]["soft-wallet"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["toncoin"]["soft-wallet"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["toncoin"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    tonhub: {
      "soft-wallet": {
        note: [
          {
            items: [
              lang["importInstructions"]["tonhub"]["soft-wallet"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["tonhub"]["soft-wallet"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["tonhub"]["soft-wallet"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["tonhub"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["tonhub"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tonhub"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    tonkeeper: {
      "soft-wallet": {
        note: [
          {
            items: [
              lang["importInstructions"]["tonkeeper"]["soft-wallet"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["tonkeeper"]["soft-wallet"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["tonkeeper"]["soft-wallet"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["tonkeeper"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["tonkeeper"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tonkeeper"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    tonpay: {
      "soft-wallet": {
        note: [
          {
            items: [
              lang["importInstructions"]["tonpay"]["soft-wallet"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["tonpay"]["soft-wallet"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["tonpay"]["soft-wallet"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["tonpay"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["tonpay"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tonpay"]["soft-wallet"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    tradeSatoshi: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["tradeSatoshi"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["tradeSatoshi"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tradeSatoshi"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    "trader-joe": {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["trader-joe"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["trader-joe"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    trezor: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["trezor"]["csv"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["trezor"]["csv"]["note"][0][
                "items"
              ][1](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["trezor"]["csv"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][0][
                "items"
              ][1](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][1][
                "items"
              ][0](),
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][1][
                "items"
              ][1](),
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][1][
                "items"
              ][2](),
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][1][
                "items"
              ][3](),
            ],
            title:
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][1][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][2][
                "items"
              ][0](),
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][2][
                "items"
              ][1](),
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][2][
                "items"
              ][2](),
            ],
            title:
              lang["importInstructions"]["trezor"]["soft-wallet"]["step"][2][
                "title"
              ](),
          },
        ],
      },
    },
    tron: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["tron"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["tron"]["wallet-api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tron"]["wallet-api"]["note"][0][
                "items"
              ][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["tron"]["wallet-api"]["step"][0][
                  "items"
                ][0](args) as string,
              lang["importInstructions"]["tron"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["tron"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["tron"]["wallet-api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    trustwallet: {
      "soft-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["trustwallet"]["soft-wallet"][
                "step"
              ][0]["items"][0](),
              lang["importInstructions"]["trustwallet"]["soft-wallet"][
                "step"
              ][0]["items"][1](),
              lang["importInstructions"]["trustwallet"]["soft-wallet"][
                "step"
              ][0]["items"][2](),
              lang["importInstructions"]["trustwallet"]["soft-wallet"][
                "step"
              ][0]["items"][3](),
              lang["importInstructions"]["trustwallet"]["soft-wallet"][
                "step"
              ][0]["items"][4](),
            ],
          },
        ],
      },
    },
    unichain: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["unichain"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["unichain"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["unichain"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    uniswap: {
      "wallet-api": {
        step: [
          {
            items: [
              lang["importInstructions"]["uniswap"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["uniswap"]["wallet-api"]["step"][0][
                "items"
              ][1](),
            ],
          },
        ],
      },
    },
    uphold: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["uphold"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["uphold"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["uphold"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["uphold"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["uphold"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["uphold"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["uphold"]["csv"]["step"][0][
                "items"
              ][5](),
            ],
          },
        ],
      },
    },
    valr: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["valr"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["valr"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["valr"]["csv"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    vechain: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["vechain"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["vechain"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["vechain"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["vechain"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["vechain"]["csv"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
      "wallet-api": {
        step: [
          {
            items: [
              (args: { exchangeDisplayName: Arg }): string =>
                lang["importInstructions"]["vechain"]["wallet-api"]["step"][0][
                  "items"
                ][0](args) as string,
              lang["importInstructions"]["vechain"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["vechain"]["wallet-api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["vechain"]["wallet-api"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
    },
    velas: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["velas"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["velas"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["velas"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["velas"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    virgocx: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["virgocx"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["virgocx"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["virgocx"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["virgocx"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["virgocx"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["virgocx"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["virgocx"]["csv"]["step"][0][
                "items"
              ][6](),
              lang["importInstructions"]["virgocx"]["csv"]["step"][0][
                "items"
              ][7](),
              lang["importInstructions"]["virgocx"]["csv"]["step"][0][
                "items"
              ][8](),
            ],
          },
        ],
      },
    },
    wealth99: {
      api: {
        step: [
          {
            items: [
              lang["importInstructions"]["wealth99"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["wealth99"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["wealth99"]["api"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["wealth99"]["api"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["wealth99"]["api"]["step"][0][
                "items"
              ][4](),
            ],
          },
        ],
      },
    },
    xdai: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["xdai"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["xdai"]["wallet-api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["xdai"]["wallet-api"]["note"][0][
                "items"
              ][2](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["xdai"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    xverse_wallet: {
      "connect-wallet": {
        step: [
          {
            items: [
              lang["importInstructions"]["xverse_wallet"]["connect-wallet"][
                "step"
              ][0]["items"][0](),
            ],
          },
        ],
      },
    },
    yobit: {
      api: {
        note: [
          {
            items: [
              lang["importInstructions"]["yobit"]["api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["yobit"]["api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["yobit"]["api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["yobit"]["api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    yoroi: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["yoroi"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["yoroi"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["yoroi"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["yoroi"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["yoroi"]["csv"]["step"][0][
                "items"
              ][3](),
            ],
          },
        ],
      },
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["yoroi"]["wallet-api"]["note"][0][
                "items"
              ][0](),
              lang["importInstructions"]["yoroi"]["wallet-api"]["note"][0][
                "items"
              ][1](),
              lang["importInstructions"]["yoroi"]["wallet-api"]["note"][0][
                "items"
              ][2](),
            ],
          },
          {
            items: [
              lang["importInstructions"]["yoroi"]["wallet-api"]["note"][1][
                "items"
              ][0](),
              lang["importInstructions"]["yoroi"]["wallet-api"]["note"][1][
                "items"
              ][1](),
            ],
            title:
              lang["importInstructions"]["yoroi"]["wallet-api"]["note"][1][
                "title"
              ](),
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["yoroi"]["wallet-api"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["yoroi"]["wallet-api"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["yoroi"]["wallet-api"]["step"][0][
                "items"
              ][2](),
            ],
          },
        ],
      },
    },
    zebpay: {
      csv: {
        step: [
          {
            items: [
              lang["importInstructions"]["zebpay"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["zebpay"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["zebpay"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["zebpay"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["zebpay"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["zebpay"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["zebpay"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    zeroExProtocol: {
      csv: {
        note: [
          {
            items: [
              lang["importInstructions"]["zeroExProtocol"]["csv"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["zeroExProtocol"]["csv"]["step"][0][
                "items"
              ][0](),
              lang["importInstructions"]["zeroExProtocol"]["csv"]["step"][0][
                "items"
              ][1](),
              lang["importInstructions"]["zeroExProtocol"]["csv"]["step"][0][
                "items"
              ][2](),
              lang["importInstructions"]["zeroExProtocol"]["csv"]["step"][0][
                "items"
              ][3](),
              lang["importInstructions"]["zeroExProtocol"]["csv"]["step"][0][
                "items"
              ][4](),
              lang["importInstructions"]["zeroExProtocol"]["csv"]["step"][0][
                "items"
              ][5](),
              lang["importInstructions"]["zeroExProtocol"]["csv"]["step"][0][
                "items"
              ][6](),
            ],
          },
        ],
      },
    },
    zetachain: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["zetachain"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["zetachain"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    zksync: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["zksync"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
            title:
              lang["importInstructions"]["zksync"]["wallet-api"]["note"][0][
                "title"
              ](),
          },
          {
            items: [
              lang["importInstructions"]["zksync"]["wallet-api"]["note"][1][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["zksync"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
    zora: {
      "wallet-api": {
        note: [
          {
            items: [
              lang["importInstructions"]["zora"]["wallet-api"]["note"][0][
                "items"
              ][0](),
            ],
          },
        ],
        step: [
          {
            items: [
              lang["importInstructions"]["zora"]["wallet-api"]["step"][0][
                "items"
              ][0](),
            ],
          },
        ],
      },
    },
  },
  importInstructionsNotice: {
    binance: lang["importInstructionsNotice"]["binance"](),
    coinsmart: lang["importInstructionsNotice"]["coinsmart"](),
  },
};
