import { Box } from "@mui/material";

import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { AddressLink } from "~/components/ui/enums";
import { ExternalAddressLink } from "~/components/ui/ExternalAddressLink";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export const DisplayExchangeActionItems = ({
  type = AddressLink.Address,
  exchange,
  addressLink,
  width = 16,
  noPadding = false,
  disableCopy = false,
}: {
  type?: AddressLink;
  exchange: string;
  addressLink?: string | null;
  width?: number;
  noPadding?: boolean;
  disableCopy?: boolean;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      gap={noPadding ? "0.25rem" : undefined}
    >
      {addressLink ? (
        <ExternalAddressLink
          link={addressLink}
          tooltip={
            type === AddressLink.Address
              ? lang.txTable.viewAddress
              : lang.txTable.viewTx
          }
          width={width}
          color={tokens.text.default}
          noPadding={noPadding}
        />
      ) : null}
      {!disableCopy ? (
        <CopyIconButton
          contentToCopy={exchange}
          width={width}
          color={tokens.text.default}
          noPadding={noPadding}
        />
      ) : null}
    </Box>
  );
};
