import { Typography } from "@mui/material";

import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useLang } from "~/redux/lang";

export const UnlockBlockchainDialog = ({
  isOpen,
  handleClose,
  handleAction,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleAction: () => void;
}) => {
  const lang = useLang();
  return (
    <GeneralDialog
      title={lang.editTx.unlockBlockchainTitle}
      isOpen={isOpen}
      handleClose={handleClose}
      handleAction={handleAction}
      actionText={lang.editTx.unlock}
      cancelText={lang.transactionEdit.dialog.cancel}
      critical
    >
      <Typography sx={{ whiteSpace: "pre-line" }}>
        {lang.editTx.unlockBlockchainBody}
      </Typography>
    </GeneralDialog>
  );
};
