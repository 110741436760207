import { type Blockchain, type Trade } from "@ctc/types";
import { Stack } from "@mui/material";

import { BlockchainSymbolPosition } from "~/components/ui/enums";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { useDesign } from "~/hooks/useTheme";
import { ActionDefinitions } from "~/lib/tradeTypeDefinitions";
import {
  getPartyDetails,
  isGroupedTrade,
  isInTrade,
} from "~/services/transactions";
import { getEntityExchangeLogoName, useEntityLookup } from "~/state/entities";
import { Side } from "~/types/enums";
import { type GroupedTrade } from "~/types/enums";
import { type ActionRow, type TransactionDetails } from "~/types/index";

type AccountProps = {
  key?: string;
  exchange: string;
  currencySymbol: string;
  blockchain?: Blockchain;
  width?: number;
  height?: number;
};

export function ActionRowAccountIcon({
  exchange, // might be an address, or an exchange name
  currencySymbol,
  blockchain,
  width = 18,
  height = 18,
}: AccountProps) {
  const entity = useEntityLookup(exchange, blockchain);
  const design = useDesign();

  return (
    <ExchangeLogo
      name={getEntityExchangeLogoName(entity) ?? exchange}
      blockchain={blockchain}
      showBlockchainSymbol
      blockchainSymbolPosition={BlockchainSymbolPosition.BottomRight}
      currencySymbol={currencySymbol}
      width={width}
      height={height}
      margin="0"
      showDepositIcon={false}
      background={design.tokens.background.neutral.default}
    />
  );
}

function getPartyDetailsComponents(row: ActionRow, counterparty = false) {
  const partyDetails: AccountProps[] = [];
  const isGroupedRow = isGroupedTrade(row.type);

  const firstOutgoing = row.outgoing[0] as TransactionDetails | undefined;
  const firstIncoming = row.incoming[0] as TransactionDetails | undefined;

  if (isGroupedRow) {
    const definition = ActionDefinitions[row.type as GroupedTrade];
    if (definition.isTransferLike) {
      if (!firstOutgoing) {
        return partyDetails;
      }
      const from = getPartyDetails(
        firstOutgoing,
        counterparty ? Side.To : Side.From,
      );

      partyDetails.push({
        key: firstOutgoing._id,
        exchange: from.exchange,
        currencySymbol: firstOutgoing.currencyIdentifier.symbol,
        blockchain: firstOutgoing.blockchain as Blockchain,
      });
    } else {
      if (!firstOutgoing) {
        if (!firstIncoming) {
          return partyDetails;
        }
        const party = getPartyDetails(
          firstIncoming,
          counterparty ? Side.From : Side.To,
        );

        partyDetails.push({
          key: firstIncoming._id,
          exchange: party.exchange,
          currencySymbol: firstIncoming.currencyIdentifier.symbol,
          blockchain: firstIncoming.blockchain as Blockchain,
        });
        return partyDetails;
      }
      const party = getPartyDetails(
        firstOutgoing,
        counterparty ? Side.To : Side.From,
      );

      partyDetails.push({
        key: firstOutgoing._id,
        exchange: party.exchange,
        currencySymbol: firstOutgoing.currencyIdentifier.symbol,
        blockchain: firstOutgoing.blockchain as Blockchain,
      });
    }
  } else if (isInTrade(row.type as Trade)) {
    if (!firstIncoming) {
      return partyDetails;
    }
    const party = getPartyDetails(
      firstIncoming,
      counterparty ? Side.From : Side.To,
    );

    partyDetails.push({
      key: firstIncoming._id,
      exchange: party.exchange,
      currencySymbol: firstIncoming.currencyIdentifier.symbol,
      blockchain: firstIncoming.blockchain as Blockchain,
    });
  } else {
    if (!firstOutgoing) {
      return partyDetails;
    }
    const party = getPartyDetails(
      firstOutgoing,
      counterparty ? Side.To : Side.From,
    );

    partyDetails.push({
      key: firstOutgoing._id,
      exchange: party.exchange,
      currencySymbol: firstOutgoing.currencyIdentifier.symbol,
      blockchain: firstOutgoing.blockchain as Blockchain,
    });
  }

  return partyDetails;
}

export function ActionRowAccount({ row }: { row: ActionRow }) {
  const partyDetails = getPartyDetailsComponents(row);

  return (
    <Stack direction="row" style={{ position: "relative" }}>
      {partyDetails.map((props) => (
        <ActionRowAccountIcon key={props.key} {...props} />
      ))}
    </Stack>
  );
}
