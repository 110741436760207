import { Env } from "@ctc/types";
import Cookies from "js-cookie";
import isEqual from "lodash/isEqual";
import posthog, {
  type FeatureFlagsCallback,
  type IsFeatureEnabledOptions,
} from "posthog-js";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  determineEventSource,
  determineTaxHubClient,
} from "~/analytics/events";
import { getInitialFlow } from "~/components/onboarding-v2/helpers";
import { getInitialReferrerSource } from "~/components/payment/referrer-config";
import {
  BootstrapQueryParameter,
  CookieKey,
  LocalStorageKey,
} from "~/constants/enums";
import { PAYWALL_MODAL_VARIANT as CHOOSE_PLAN_VARIANT } from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModalChoosePlan";
import { PAYWALL_MODAL_VARIANT as PLAN_SELECTED_VARIANT } from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModalPlanSelected";
import { PAYWALL_MODAL_VARIANT as VIEW_PLANS_VARIANT } from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModalViewPlans";
import { isWindowEmbedded } from "~/hooks/useIsEmbedded";
import { useResolvedTheme } from "~/hooks/useTheme";
import { staticTranslationsSet } from "~/lang/index";
import { invariant } from "~/lib/invariant";
import { useBestUser, useUser } from "~/redux/auth";
import { useSelector } from "~/redux/useSelector";
import { useSocketConnected } from "~/state/sync";
import { FeatureFlag, Links, type Theme } from "~/types/enums";
import {
  type ActionRow,
  type BaseUserDetails,
  type UserDetails,
  type UserInfo,
} from "~/types/index";

let isInitialised = false;
let isPosthogIdentified = false;

// Maximum allowed size of a property value in posthog
const MAX_PROP_SIZE = 1000;

const shouldEnable = (): boolean => {
  return (
    Boolean(import.meta.env.VITE_APP_POSTHOG_API_KEY) &&
    Boolean(import.meta.env.VITE_APP_POSTHOG_HOST) &&
    import.meta.env.VITE_APP_POSTHOG_ENABLED === "on"
  );
};
export function censorTextForPosthog(
  text: string,
  element: HTMLElement | null,
) {
  if (
    element?.dataset["uncensored"] === "true" ||
    staticTranslationsSet.value.has(text)
  ) {
    return text;
  }
  return "*".repeat(text.trim().length);
}

function sanitizeUrlForPosthog(unsanitizedUrlString: string): string {
  const currentUrl = new URL(unsanitizedUrlString);

  // don't leak reset tokens
  if (currentUrl.pathname.includes(Links.Reset)) {
    // Clear all query parameters to avoid leaking sensitive information
    currentUrl.search = "";
  }

  // don't leak wallet addresses and networks passed via query params
  if (currentUrl.searchParams.has(BootstrapQueryParameter.Accounts)) {
    currentUrl.searchParams.delete(BootstrapQueryParameter.Accounts);
  }

  if (currentUrl.searchParams.has(BootstrapQueryParameter.Networks)) {
    currentUrl.searchParams.delete(BootstrapQueryParameter.Networks);
  }

  return currentUrl.toString();
}

export const initPosthogAnalytics = (): void => {
  if (!shouldEnable()) return;

  const isProd = import.meta.env.VITE_APP_ENV === Env.Prod;

  posthog.init(import.meta.env.VITE_APP_POSTHOG_API_KEY, {
    api_host: import.meta.env.VITE_APP_POSTHOG_HOST,
    disable_persistence: !isProd,
    autocapture: false,
    capture_pageview: false,
    disable_session_recording: !isProd,
    session_recording: {
      // Mask all inputs by default
      maskAllInputs: true,
      // Mask all text by default
      maskTextSelector: "*",
      /**
       * Allow devs to manually unmask text by adding data-uncensored="true" to the element
       * e.g. <p data-uncensored="true">I will be visible!</p>
       * https://posthog.com/docs/session-replay/privacy#:~:text=As%20any%20input%20element%20is,would%20like%20to%20be%20masked.
       *
       * Adding `as any` because the typescript definitions are wrong
       * it says there is no element, but it is passed and is mentioned in the docs
       */
      maskTextFn: censorTextForPosthog,
    },
    sanitize_properties: (properties) => {
      try {
        // sanitize the current url, this is for all events fired to posthog except the first event for a user
        if (properties.$current_url) {
          properties.$current_url = sanitizeUrlForPosthog(
            properties.$current_url as string,
          );
        }

        // sanitize the initial url if it exists, the initial url is what is sent for the first time a user is seen by PH
        // In mm embedded the users iframe is loaded with their wallets so we need to sanitize the url so we don't leak any sensitive information
        if (properties.$initial_current_url) {
          properties.$initial_current_url = sanitizeUrlForPosthog(
            properties.$initial_current_url as string,
          );
        }

        // Events sometimes include an `initial_person_info` property, before the `initial_current_url` event is fired.
        // So we want to sanitize this as well if it exists. This was found experimentally
        if (properties.$initial_person_info?.u) {
          properties.$initial_person_info.u = sanitizeUrlForPosthog(
            properties.$initial_person_info.u as string,
          );
        }

        // sometimes posthog will try to add a properties
        return properties;
      } catch (error) {
        console.error("Error sanitizing properties", error);
        return properties;
      }
    },
    /**
     * Set any properties that we need on the posthog user for the initial feature flag logic
     * that happens before login
     */
    loaded: (posthogInstance) => {
      const urlParams = new URLSearchParams(window.location.search);
      // save via source ( these are affiliates ) to posthog user properties
      const viaSource = urlParams.get("via");

      posthogInstance.setPersonProperties({
        env: import.meta.env.VITE_APP_ENV,
        unconfirmedCountry:
          Cookies.get(CookieKey.HpSelectedCountry) ??
          Cookies.get(CookieKey.UnconfirmedCountry),
        referrerSource: getInitialReferrerSource(),
        isEmbedded: isWindowEmbedded(),
        via: viaSource,
      });
    },
    bootstrap: {
      featureFlags: {
        [FeatureFlag.UseTurnstileCaptcha]: true,
      },
    },
  });

  isInitialised = true;
};

/**
 * Identify the user and return when their feature flags are ready
 */
export const identifyPosthogUser = async (
  uid: string,
  newUserInfo: UserInfo,
  currentUserInfo: UserInfo | null | undefined,
): Promise<void> => {
  if (!shouldEnable() || !isInitialised) return;

  // we dont want to fire the event every time loadUser is called
  // so change if the properties we are reporting have changed
  if (
    isPosthogIdentified &&
    currentUserInfo?._id === uid &&
    isEqual(getUserProperties(currentUserInfo), getUserProperties(newUserInfo))
  ) {
    return;
  }
  // don't reload the feature flags yet, they will be reloaded when the user is identified
  posthog.setPersonPropertiesForFlags(
    getBootstrappedPersonPropertiesForFlags(),
    false,
  );
  posthog.identify(
    uid,
    getUserProperties(newUserInfo),
    getOnFirstSeenUserProperties(),
  );
  isPosthogIdentified = true;
  return new Promise((resolve) => {
    let unsubscribe: () => void;
    const timer = setTimeout(() => {
      unsubscribe();
      resolve();
    }, 2000);

    const callback: FeatureFlagsCallback = (flags, variants, errors) => {
      const errorsLoading = errors?.errorsLoading;
      if (!flags.length || errorsLoading) {
        // FF haven't loaded from server yet or an error occurred so wait until it recovers
        return;
      }
      clearTimeout(timer);
      // move unsubscribe to the next tick so both unsubscribe and resolve are called
      setTimeout(() => {
        unsubscribe();
        resolve();
      }, 0);
    };
    posthog.featureFlags.addFeatureFlagsHandler(callback);
    unsubscribe = () => {
      posthog.featureFlags.removeFeatureFlagsHandler(callback);
    };
  });
};

const getUserProperties = (userInfo: UserInfo): Record<string, unknown> => {
  const { activeDataSource } = userInfo;

  const getDataSourceInfo = () => {
    if (userInfo._id === activeDataSource?.uid) {
      const {
        isRetailUser,
        taxSettings,
        txGroupCount,
        txBilledCount,
        txRealCount,
        txFreeCount,
        isInAlpha,
        showSpamTransactions,
        hasGeneratedAnyReconciliationIssues,
        version,
      } = activeDataSource;
      return {
        ...taxSettings,
        env: import.meta.env.VITE_APP_ENV,
        isRetailUser,
        txGroupCount,
        txBilledCount,
        txRealCount,
        txFreeCount,
        isInAlpha,
        showSpamTransactions,
        hasGeneratedAnyReconciliationIssues,
        version,
      };
    }

    return {};
  };

  const dataSourceInfo = getDataSourceInfo();
  const initialOnboardingFlow = getInitialFlow(userInfo);

  return {
    // Posthog does get these IDs but its easier to have them in their
    // own person property too for the purpose of bulk exporting cohorts
    // by UID to CSVs without having to filter out posthog internal ids
    uid: userInfo._id,

    // User properties
    country: userInfo.country,
    createdAt: userInfo.createdAt,
    discount: userInfo.discount,
    inventoryMethod: userInfo.inventoryMethod,
    language: userInfo.language,
    localCurrency: userInfo.localCurrency,
    ...userInfo.notifications,
    ownsChildProfileData: userInfo.ownsChildProfileData,
    paidPlan: userInfo.paidPlan,
    timezone: userInfo.timezone,
    bypassTXPaywall: userInfo.bypassTXPaywall,
    emailReports: userInfo.emailReports,
    isInternalUser: userInfo.email?.endsWith("@cryptotaxcalculator.io"),
    referrerSource: userInfo.referrerSource,
    oAuthProvider: userInfo.oAuthProvider,
    lastLogin: userInfo.lastLogin,
    onboardingFlow:
      userInfo.isOnboarding || userInfo.isReOnboarding
        ? initialOnboardingFlow.flow
        : undefined,
    // Tax Settings
    ...dataSourceInfo,
  };
};

export const isFeatureEnabled = (
  featureName: FeatureFlag,
  options?: IsFeatureEnabledOptions | undefined,
): boolean => {
  if (!shouldEnable() || !isInitialised) return false;

  // Will return undefined if the feature flag is disabled, so default to false
  return posthog.isFeatureEnabled(featureName, options) ?? false;
};

export function useFeatureFlag(flagId: FeatureFlag) {
  const flags = useFeatureFlags();
  return flags.isFeaturedEnabled(flagId);
}

/**
 * Reset posthog to its initial state
 * Unlink any future Posthog events made on this device from the current user
 */
export function resetPosthog() {
  posthog.reset();
  isPosthogIdentified = false;
}

type ValueOf<T> = T[keyof T];
function createExperimentVariantHook<
  TExperiments extends { [flag in FeatureFlag]?: Readonly<string[]> },
>(config: TExperiments) {
  /**
   * Returns
   *  undefined: flag is loading.
   *  true: user is in the variant.
   *  false: user is not in the variant.
   *
   * Note: Don't use this if you have a boolean feature flag.
   * Only use it if you have variants
   */
  return function useExperiment<TFeatureFlag extends keyof typeof config>(
    // the feature flag
    featureFlag: TFeatureFlag,
    // the experiments variants
    variant: ValueOf<(typeof config)[TFeatureFlag]>,
  ) {
    invariant(typeof featureFlag === "string", "Must be a feature flag");
    // due to PHs hook implementation the initial call to useFeatureFlagVariantKey for each flag will return undefined
    // https://github.com/PostHog/posthog-js/blob/main/react/src/hooks/useFeatureFlagVariantKey.ts
    // We fall back to getFeatureFlag as it has data available immediately.
    const posthogExperimentFeatureFlag =
      useFeatureFlagVariantKey(featureFlag) ??
      posthog.getFeatureFlag(featureFlag);

    const overrides = useSelector(
      (state) => state.developer.featureFlagOverrides[featureFlag],
    );

    // take our overrides
    if (overrides) {
      return overrides.enabled === (variant as any);
    }

    if (posthogExperimentFeatureFlag === undefined) {
      return undefined;
    }
    if (posthogExperimentFeatureFlag === true) {
      return undefined;
    }
    // and if no override, whatever is in posthog
    return (posthogExperimentFeatureFlag as unknown) === variant;
  };
}

export const experiments = {
  [FeatureFlag.MultiVariantExample]: ["test", "control"] as const,
  // Define more experiments here
  [FeatureFlag.TipsPaywall]: ["test", "control"] as const,
  [FeatureFlag.LinearFlow]: [
    "control",
    "recon-only",
    "recon-with-paywall",
  ] as const,
  [FeatureFlag.BusinessClientDrawer]: [
    "none",
    "freeTrial",
    "paymentFlow",
  ] as const,
  [FeatureFlag.TaxMinimisationCopy]: [
    "control",
    "you-could-save-xyz-in-taxes",
    "you-could-minimise-your-tax-liability-by",
    "our-tax-minimisation-algorithm-could-save-you",
  ] as const,
  [FeatureFlag.OnboardingImportInstructions]: [
    "control",
    "images",
    "simple",
  ] as const,
  [FeatureFlag.ImportErrorReport]: ["control", "import-error-report"] as const,
  [FeatureFlag.PostEvmImportInsights]: ["control", "show-insights"] as const,
  [FeatureFlag.USHobbyistPlusExperiment]: ["control", "hobbyist-plus"] as const,
  [FeatureFlag.TaxHubOfferPreSignup]: ["control", "pre-signup"],
  [FeatureFlag.DashboardCountdownBanner]: [
    "control",
    "countdown-banner",
  ] as const,
  [FeatureFlag.CoinbaseTheme]: ["control", "test"] as const,
  [FeatureFlag.PaywallModals]: [
    "control",
    CHOOSE_PLAN_VARIANT,
    VIEW_PLANS_VARIANT,
    PLAN_SELECTED_VARIANT,
  ] as const,
  [FeatureFlag.OnboardingMobileImport]: [
    "control",
    "hidden-instructions",
    "displayed-instructions",
  ] as const,
  [FeatureFlag.TaxHubOfferPreSignupWithMetamask]: [
    "control",
    "original_copy",
    "new_copy",
  ] as const,
  [FeatureFlag.TaxhubEnableRookiePlan]: ["control", "test"] as const,
  [FeatureFlag.MobileReportsPageUplift]: [
    "control",
    "reports-page-uplift",
  ] as const,
  [FeatureFlag.FlexRemindMeLater]: ["control", "test"] as const,
};

export const isExperiment = (key: string): key is keyof typeof experiments => {
  return key in experiments;
};

export const useExperimentVariant = createExperimentVariantHook(experiments);

export function useFeatureFlags() {
  const [flags, setFlags] = useState(
    Array.from(
      new Set([
        // combine the flags from posthog
        ...posthog.featureFlags.getFlags(),
        // plus our typed flags
        ...Object.values(FeatureFlag),
      ]),
    ),
  );
  const overrides = useSelector(
    (state) => state.developer.featureFlagOverrides,
  );

  // Enable reactive posthog flags.
  useEffect(() => {
    const flags = Array.from(
      new Set([
        // combine the flags from posthog
        ...posthog.featureFlags.getFlags(),
        // plus our typed flags
        ...Object.values(FeatureFlag),
      ]),
    );
    setFlags(flags);
  }, [overrides]);

  const isFeatureEnabledCallback = useCallback(
    (
      featureName: FeatureFlag,
      options?: IsFeatureEnabledOptions | undefined,
    ) => {
      return isFeatureEnabled(featureName, options);
    },
    [flags],
  );

  return { isFeaturedEnabled: isFeatureEnabledCallback, flags };
}

const parseEvent = (rawEvent: string) => {
  return rawEvent.replaceAll(" ", "_").toLowerCase();
};

const getDefaultProperties = (): Record<string, unknown> => {
  return { env: import.meta.env.VITE_APP_ENV };
};

const extractUserProperties = <T extends BaseUserDetails>(
  user: UserDetails,
  theme: Theme,
  bestUser: T,
) => {
  return {
    // Pretty much every attribute in the UserDocument
    // Undefined attributes will be ignored in Posthog
    clientBridge: user.clientBridge,
    country: user.country,
    discount: user.discount,
    importRelatedWallets: user.importRelatedWallets,
    inventoryMethod: user.inventoryMethod,
    isOAuth: user.isOAuth,
    language: user.language,
    localCurrency: user.localCurrency,
    oAuthProvider: user.oAuthProvider,
    ownsChildProfileData: user.ownsChildProfileData,
    paidPlan: user.paidPlan,
    paidPlanTrial: user.paidPlanTrial,
    paidPlanTrialUntil: user.paidPlanTrialUntil,
    referrerSource: user.referrerSource,
    reportRefreshStatus: user.reportRefreshStatus,
    reportRefreshUpdatedAt: user.reportRefreshUpdatedAt,
    showSpamTransactions: user.showSpamTransactions,
    xeroEnabled: user.xeroEnabled,
    activeProfilePaidPlan: bestUser.paidPlan,
    theme,
  };
};

/**
 * Filters out large objects from a record based on their JSON stringified length.
 * Replaces any property values exceeding a size limit (MAX_PROP_SIZE) with a notice of omission.
 */
const filterOutLargeObjects = (
  data: Record<string, unknown>,
): Record<string, unknown> => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      if (!value) return [key, value];

      const stringValue = JSON.stringify(value);
      return stringValue.length > MAX_PROP_SIZE
        ? [key, "Omitted due to large size"]
        : [key, value];
    }),
  );
};

/**
 * Capture general analytics event
 * @param event Please use generateAnalyticsKey to format the event name
 * @param additionalProperties Additional properties to be added to the event
 * @returns
 */
export const captureAnalytics = (
  event: string,
  additionalProperties: Record<string, unknown> = {},
): void => {
  if (!shouldEnable()) return;

  const props = {
    ...getDefaultProperties(),
    ...additionalProperties,
  };

  if (import.meta.env.VITE_APP_LOG_ANALYTICS === "on") {
    console.info("event triggered: ", event, props);
    console.trace();
  }

  posthog.capture(parseEvent(event), filterOutLargeObjects(props));
};

/**
 * Capture action analytics event
 * @param event Please use transactionAnalyticsKey to format the event name
 * @param row ActionRow
 * @param properties Additional properties to be added to the event
 * @returns
 */
export const useCaptureActionAnalytics = () => {
  const userDetails = useUser();
  const bestUser = useBestUser();
  const theme = useResolvedTheme();

  const captureTxAnalytics = useCallback(
    (
      event: string,
      row: ActionRow,
      properties: Record<string, unknown> = {},
    ) => {
      const userProps =
        userDetails && bestUser
          ? extractUserProperties(userDetails, theme, bestUser)
          : {};
      const getTx = () => {
        if (!!row.outgoing && row.outgoing.length) {
          return row.outgoing[0];
        }
        if (!!row.incoming && row.incoming.length) {
          return row.incoming[0];
        }
        return undefined;
      };
      const tx = getTx();
      if (!tx) return;
      captureAnalytics(event, {
        ...properties,
        ...userProps,
        blockchain: tx.blockchain,
        currency: tx.currencyIdentifier.symbol,
        importType: tx.importType,
        price: tx.price,
        quantity: tx.quantity,
        trade: tx.trade,
        timestamp: tx.timestamp,
      });
    },
    [userDetails, theme, bestUser],
  );
  return captureTxAnalytics;
};

/**
 * Capture general analytics event
 * @param event Please use generateAnalyticsKey to format the event name
 * @param additionalProperties Additional properties to be added to the event
 * @returns
 */
export const useCaptureAnalytics: () => (
  event: string,
  properties?: Record<string, unknown>,
) => void = () => {
  const userProps = useUserEventProps();

  const callback = useCallback(
    (event: string, properties: Record<string, unknown> = {}) => {
      // additional properties overrides default properties
      captureAnalytics(event, { ...userProps, ...properties });
    },
    [userProps],
  );
  return callback;
};

/**
 * The standard properties we include in every event sent to posthog
 */
export type StandardEventProperties = (
  | ReturnType<typeof extractUserProperties>
  | {}
) & {
  webSocketConnect: boolean;
};

/**
 * The user properties we include in every event sent to posthog
 */
export function useUserEventProps() {
  const userDetails = useUser();
  const bestUser = useBestUser();
  const theme = useResolvedTheme();
  const isWebsocketConnected = useSocketConnected();

  const userProps = useMemo(() => {
    const userDetailsProps =
      userDetails && bestUser
        ? extractUserProperties(userDetails, theme, bestUser)
        : {};
    const props = {
      webSocketConnect: isWebsocketConnected,
      ...userDetailsProps,
      eventSource: determineEventSource(),
      taxHubClient: determineTaxHubClient(),
    };
    return props as StandardEventProperties;
  }, [userDetails, bestUser, theme, isWebsocketConnected]);

  return userProps;
}

/**
 * Posthog properties aren't available on `identify` so if a FF is dependant on them it will initially return false.
 * This function is to bootstrap the posthog person properties so the correct FF is evaluated on first load.
 * these properties should ONLY be used for `setPersonPropertiesForFlags` as to make sure they aren't permanently saved in PH.
 */
function getBootstrappedPersonPropertiesForFlags() {
  // for bootstrap purposes add the onFirstSeen properties. They will be overridden with the stored properties if available
  const assumedFirstSeenValues = getOnFirstSeenUserProperties();
  const referrerSource = localStorage.getItem(LocalStorageKey.ReferrerSource);
  return {
    $device_type: getPosthogDeviceType(),
    ...(referrerSource && { referrerSource }),
    ...assumedFirstSeenValues,
  };
}

/**
 * Get the device type from the user agent and return the appropriate Posthog device type
 * used for `quick-onboarding`
 */
const getPosthogDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "Tablet";
  }
  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return "Mobile";
  }
  return "Desktop";
};

/**
 * Get the user properties that should be set when the user is first seen. If these change they will not be updated in Posthog.
 */
function getOnFirstSeenUserProperties(): Record<string, unknown> {
  const countryFromHp =
    Cookies.get(CookieKey.HpSelectedCountry) ??
    Cookies.get(CookieKey.UnconfirmedCountry);
  return {
    ...(countryFromHp && {
      unconfirmedCountry: countryFromHp,
    }),
  };
}
