import { DoNotDisturbAlt, OpenInNew } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import type * as React from "react";
import { type MouseEventHandler, type ReactNode, useState } from "react";

import { ActionButtonTypes } from "~/components/transactions/enums";
import { TertiaryIconButton } from "~/components/ui/ui-buttons/icon-buttons/TertiaryIconButton";
import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useIsMobile } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

type RowWarningProps = {
  warning: string;
  onActionButtonClick?: MouseEventHandler; // The onClick function for the action button, when defined, the action button is displayed
  warningIcon?: ReactNode; // The icon to be displayed at the start of the warning (defaults to outlined warning triangle)
  actionButtonIcon?: ReactNode; // The icon to use in the action button Icon Button (defaults to a cancel icon)
  color?: string; // The color of the warning, can be changed to indicate severity
  info?: ReactNode; // If defined, enables the info popover and changes the style to be clickable
  wrap?: boolean; // Whether to wrap the warning text as the screen narrows
  onTextClick?: MouseEventHandler; // The onClick function for the warning text to open action modal
};

export function RowWarning({
  warning,
  onActionButtonClick,
  onTextClick,
  warningIcon = <WarningAmberIcon style={{ fontSize: "16" }} />,
  actionButtonIcon = <DoNotDisturbAlt style={{ fontSize: "12" }} />,
  color,
  info,
  wrap = false,
}: RowWarningProps) {
  const { tokens } = useDesign();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "warningInfo",
  });

  const isSmall = useIsMobile();

  // Wrap the warning when we get to smaller widths and is a negative balance
  const isWrappedWarning = isSmall && wrap;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          color: color ?? tokens.button.danger.default,
          ...(!!info && {
            "&:hover": { color: tokens.button.danger.hover },
          }),
        }}
        width={isWrappedWarning ? "5rem" : "inherit"}
        flexWrap={isWrappedWarning ? "wrap" : "inherit"}
        gap="0.5rem"
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" mr="0.25rem">
            {warningIcon}
          </Box>
          {/* Needs to be a button for the popover to anchor to it */}
          <ButtonBase
            disableRipple
            color="inherit"
            sx={{
              fontWeight: 500,
              fontSize: "0.75rem",
              overflow: "visible",
              textDecoration: !!info || !!onTextClick ? "underline dashed" : 0,
              textUnderlineOffset: "2px",
              cursor: !!info || !!onTextClick ? "pointer" : "default",
              whiteSpace: isWrappedWarning ? "normal" : "nowrap",
              textAlign: "left",
            }}
            {...bindTrigger(popupState)}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              popupState.toggle(e);
              if (onTextClick) {
                onTextClick(e);
              }
            }}
          >
            {warning}
          </ButtonBase>
        </Box>
        {onActionButtonClick ? (
          <Box ml="0.5rem">
            <TertiaryIconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onActionButtonClick(e);
              }}
            >
              {actionButtonIcon}
            </TertiaryIconButton>
          </Box>
        ) : null}
      </Box>
      {info ? (
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: -5,
            horizontal: "left",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            popupState.toggle(e);
          }}
        >
          <div>{info}</div>
        </Popover>
      ) : null}
    </>
  );
}

type WarningInfoProps = {
  title: string;
  message: string;
  linkExternal?: string;
  icon?: React.ReactNode;
  color?: string;
  actionButtonText?: string | React.ReactNode;
  onActionButtonClick?: () => void;
  actionButtonComponent?: ActionButtonTypes;
  actionButtonLoading?: boolean;
  actionButtonSecondaryText?: string;
  onActionButtonSecondaryClick?: () => void;
  actionButtonSecondaryLoading?: boolean;
};

export function WarningInfo({
  title,
  message,
  linkExternal,
  icon = <WarningAmberIcon style={{ fontSize: "16" }} />,
  color,
  actionButtonText,
  onActionButtonClick,
  actionButtonComponent = ActionButtonTypes.Secondary,
  actionButtonLoading = false,
  onActionButtonSecondaryClick,
  actionButtonSecondaryText,
  actionButtonSecondaryLoading = false,
}: WarningInfoProps) {
  const { tokens } = useDesign();
  const lang = useLang();
  const [isClosed, setClosed] = useState(false);

  // This little guy fixes a bug where the info panel will shift to the top
  // left when a new window is opened on click. The popup will re-render this
  // component, which resets `isClosed`.
  const handleExternalClick = () => {
    if (linkExternal) {
      setClosed(true);
      window.open(linkExternal, "_blank");
    }
  };

  const display = isClosed ? "none" : "flex";

  const ActionButton =
    actionButtonComponent === ActionButtonTypes.Secondary
      ? SecondaryButton
      : TertiaryButton;

  return (
    <Box
      display={display}
      justifyContent="flex-end"
      component="span"
      style={{ wordBreak: "break-word" }}
    >
      <Paper>
        <Box p="1rem" maxWidth="22rem">
          <Box display="flex" alignItems="top">
            <Box pr="0.5rem" pt="0.375rem" color={color ?? tokens.icon.danger}>
              {icon}
            </Box>
            <Typography
              variant="Metropolis/Body/Regular"
              style={{ paddingTop: "0.313rem" }}
            >
              {title}
            </Typography>
            {linkExternal && (
              <Box style={{ marginLeft: "auto" }}>
                <TextButton
                  size="small"
                  style={{ fontWeight: 400 }}
                  endIcon={
                    <OpenInNew
                      style={{ fontSize: "1rem", marginTop: "-0.125rem" }}
                    />
                  }
                  onClick={handleExternalClick}
                >
                  {lang.learnMore}
                </TextButton>
              </Box>
            )}
          </Box>
          <Typography
            variant="Metropolis/Body/Light"
            style={{
              fontSize: "0.813rem",
              whiteSpace: "pre-line",
              marginTop: "0.25rem",
            }}
          >
            {message}
          </Typography>
          <Box
            mt="1rem"
            display="flex"
            justifyContent="flex-end"
            component="span"
          >
            {actionButtonSecondaryText && onActionButtonSecondaryClick ? (
              <TextButton
                size="small"
                style={{ color: tokens.text.danger }}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onActionButtonSecondaryClick();
                }}
                disabled={actionButtonSecondaryLoading}
                endIcon={
                  actionButtonSecondaryLoading ? (
                    <CircularProgress size={16} />
                  ) : null
                }
              >
                {actionButtonSecondaryText}
              </TextButton>
            ) : null}
            {actionButtonText && onActionButtonClick ? (
              <ActionButton
                size="small"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onActionButtonClick();
                }}
                sx={{ marginLeft: "0.5rem" }}
                disabled={actionButtonLoading}
                endIcon={
                  actionButtonLoading ? <CircularProgress size={16} /> : null
                }
              >
                {actionButtonText}
              </ActionButton>
            ) : null}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
