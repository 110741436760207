import { TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";

import { type DataSet } from "~/components/developer/seed-data/index";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { clientKeys } from "~/state/clients";
import { useCloneDataToNewClientMutation } from "~/state/developer";

export const CloneDataSetNewClientModal = ({
  isOpen,
  handleClose,
  dataSet,
}: {
  isOpen: boolean;
  handleClose: () => void;
  dataSet: DataSet;
}) => {
  const queryClient = useQueryClient();
  const { control, handleSubmit, getValues, reset } = useForm();

  const mutation = useCloneDataToNewClientMutation();

  const onSubmit = (values: { clientName?: string }) => {
    mutation
      .mutateAsync({
        clientName: values.clientName || dataSet.name,
        dataSetId: dataSet._id,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: clientKeys.all() });
        reset();
      })
      .then(handleClose);
  };

  return (
    <GeneralDialog
      isOpen={isOpen}
      title="Clone data into new client"
      handleClose={handleClose}
      actionText="Clone"
      handleAction={() => {
        onSubmit(getValues());
      }}
      pending={mutation.isPending}
    >
      <Typography>
        This will create a new client with a new copy of the data from this data
        set.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="clientName"
          rules={{ required: "Client name is required" }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              type="text"
              label="Client Name"
              placeholder={dataSet.name}
              InputLabelProps={{ required: true }}
              error={invalid}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </form>
    </GeneralDialog>
  );
};
