import { type Plan } from "@ctc/types";

import { useBusinessPlanPriceData } from "~/components/plans/hooks/usePriceDataByPlanType";
import { PlanContainer } from "~/components/plans/PlanContainer";
import { useLang } from "~/redux/lang";
import { Features } from "~/types/enums";

export function BusinessPlanContainer({
  selectedPlanType,
  setSelectedPlanType,
}: {
  selectedPlanType: Plan;
  setSelectedPlanType: (plan: Plan) => void;
}) {
  const lang = useLang();
  const { priceData: businessPlanPriceData, getSelectedPlanData } =
    useBusinessPlanPriceData();

  const highlightedFeatures = [
    Features.ERP,
    Features.Rules,
    Features.LockTaxYear,
    Features.Treasury,
  ];

  const additionalFeatures = [
    {
      text: lang.payment.base.copy.highlightFeatures.prioritySupport,
      available: true,
    },
    {
      text: lang.payment.base.copy.highlightFeatures.allOtherFeatures,
      available: true,
    },
  ];

  return (
    <PlanContainer
      plans={businessPlanPriceData}
      highlightedFeatures={highlightedFeatures}
      setSelectedPlanType={setSelectedPlanType}
      additionalFeatures={additionalFeatures}
      defaultPlanData={getSelectedPlanData(selectedPlanType)}
    />
  );
}
