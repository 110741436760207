import { Stack } from "@mui/material";
import { useState } from "react";

import { ApiKeyItem } from "~/components/imports/api/ApiKeyItem";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { useLang } from "~/redux/lang";
import { useLoadingSavedImports } from "~/state/imports";
import {
  type ImportOptionV2,
  type KeyCredentials,
  type SavedImportByIntegration,
} from "~/types/index";

export interface ApiImportListProps {
  importOption: ImportOptionV2;
  savedImport?: SavedImportByIntegration;
  handleDelete: (key: KeyCredentials) => void;
  handleEdit: (key: KeyCredentials) => void;
  handleUpdate: (key: KeyCredentials) => void;
  editingApiId?: string;
}

export function ApiImportList({
  importOption,
  savedImport,
  handleDelete,
  handleUpdate,
  handleEdit,
  editingApiId,
}: ApiImportListProps) {
  const { name: exchangeName, id: exchangeLabel } = importOption;
  const [open, setOpen] = useState<boolean>(false);
  const [deletingKey, setDeletingKey] = useState<KeyCredentials>();
  const isLoading = useLoadingSavedImports();

  if (!savedImport || (!savedImport.keys.length && !isLoading)) {
    return null;
  }

  const sortedData = savedImport.keys
    .sort(
      (a, b) =>
        new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
    )
    .map(
      (key): KeyCredentials => ({
        ...key,
        exchangeName: importOption.id,
        apiKey: key.key,
        isOauth: false,
        lastSyncComplete: key.completedAt
          ? new Date(key.completedAt)
          : undefined,
        syncStatus: key.status,
        syncDisplayError: key.error,
      }),
    );

  return (
    <>
      <DeleteApiDialog
        deletingKey={deletingKey}
        exchangeName={exchangeName}
        isOpen={open}
        setOpen={setOpen}
        setDeletingKey={setDeletingKey}
        handleDelete={handleDelete}
      />
      <Stack direction="column" gap="0.5rem">
        {sortedData.map((key) => (
          <ApiKeyItem
            key={key.apiKey}
            keyDetails={key}
            exchangeLabel={exchangeLabel}
            exchangeName={exchangeName}
            editing={editingApiId === key.id}
            handleUpdate={handleUpdate}
            handleEdit={handleEdit}
            handleDelete={() => {
              setDeletingKey(key);
              setOpen(true);
            }}
          />
        ))}
      </Stack>
    </>
  );
}

function DeleteApiDialog({
  deletingKey,
  exchangeName,
  isOpen,
  setOpen,
  setDeletingKey,
  handleDelete,
}: {
  deletingKey: KeyCredentials | undefined;
  exchangeName: string;
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  setDeletingKey: (val: KeyCredentials | undefined) => void;
  handleDelete: (key: KeyCredentials) => void;
}) {
  const lang = useLang();

  function handleConfirm(confirm: boolean, handler?: () => void): void {
    setOpen(false);
    if (confirm && handler) {
      handler();
      setDeletingKey(undefined);
    }
  }

  const displayApi = (deletingKey: KeyCredentials) => {
    return deletingKey.name
      ? `${deletingKey.apiKey} - (${deletingKey.name})`
      : deletingKey.apiKey;
  };

  const deleteConfirmationText = deletingKey
    ? lang.api.deleteConfirmationText({
        exchangeName,
        api: displayApi(deletingKey),
      })
    : "";

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={lang.imports.deleteApiKeyConfirmTitle}
      text={deleteConfirmationText}
      actionText={lang.delete}
      handleClose={() => {
        handleConfirm(false);
      }}
      handleAction={() =>
        deletingKey
          ? handleConfirm(true, () => {
              handleDelete(deletingKey);
            })
          : null
      }
      critical
    />
  );
}
