import { Env } from "@ctc/types";
import posthog from "posthog-js";

import { LocalStorageKey } from "~/constants/enums";
import { type AppThunk, type DeveloperState } from "~/redux/types";
import { useSelector } from "~/redux/useSelector";

const initialState: DeveloperState = {
  featureFlagOverrides: {},
};

enum Developer {
  SetFeatureFlagOverrides = "DEVELOPER_SET_FEATURE_FLAG_OVERRIDES",
}

export type FeatureFlagOverride = {
  // either boolean, or variant string
  enabled: boolean | string;
  name: string;
};

type Action = {
  type: Developer.SetFeatureFlagOverrides;
  featureFlagOverrides: Record<string, FeatureFlagOverride>;
};

export function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case Developer.SetFeatureFlagOverrides:
      return { ...state, featureFlagOverrides: action.featureFlagOverrides };
    default:
      return { ...state };
  }
}

function setFeatureFlagOverridesAction(
  flags: Record<string, FeatureFlagOverride>,
) {
  return {
    type: Developer.SetFeatureFlagOverrides,
    featureFlagOverrides: flags,
  };
}

const isProduction = import.meta.env.VITE_APP_ENV === Env.Prod;

export const isDeveloperEnabled =
  import.meta.env.VITE_APP_DEVELOPER === "on" || !isProduction;

export const setFeatureFlagOverrides =
  (flags: Record<string, FeatureFlagOverride>): AppThunk =>
  async (dispatch) => {
    const override: Record<string, boolean | string> = {};

    Object.values(flags).forEach((flag) => {
      override[flag.name] = flag.enabled;
    });

    posthog.featureFlags.override(override);

    const values = JSON.stringify(flags);
    localStorage.setItem(LocalStorageKey.DevFeatureFlagOverrides, values);

    dispatch(setFeatureFlagOverridesAction(flags));
  };

export const setFeatureFlagOverride =
  (flagKey: string, value: string | boolean): AppThunk =>
  async (dispatch, getState) => {
    const flags = getState().developer.featureFlagOverrides;
    const flag = flags[flagKey];

    if (!flag) return;

    const update = {
      ...flags,
      [flagKey]: { ...flag, enabled: value },
    };

    dispatch(setFeatureFlagOverrides(update));
  };

export const toggleFeatureFlagOverride =
  (flagKey: string): AppThunk =>
  async (dispatch, getState) => {
    const flags = getState().developer.featureFlagOverrides;
    const flag = flags[flagKey];

    if (!flag) return;

    const update = {
      ...flags,
      [flagKey]: { ...flag, enabled: !flag.enabled },
    };

    dispatch(setFeatureFlagOverrides(update));
  };

export const setFeatureFlagOverridesFromStorage =
  (): AppThunk => async (dispatch) => {
    const storage = localStorage.getItem(
      LocalStorageKey.DevFeatureFlagOverrides,
    );

    if (!storage) return;

    try {
      const featureFlags = JSON.parse(storage);
      dispatch(setFeatureFlagOverrides(featureFlags));
    } catch (e) {
      console.warn("Could not parse feature flag storage");
    }
  };

export const useFeatureFlagOverrides = () => {
  return useSelector((state) => state.developer.featureFlagOverrides) || {};
};
