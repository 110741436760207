import * as React from "react";

/**
 * Props for the TrustPilotLink component, extending HTML anchor attributes
 */
type Props = React.ComponentPropsWithoutRef<"a"> & {
  /** Content to be rendered inside the TrustPilot link */
  children: React.ReactNode;
};

/**
 * A component that renders a link to CTC's TrustPilot review page
 * Includes default security attributes and allows all standard anchor props
 *
 * @param props - Component props including all HTML anchor attributes
 * @param props.children - Content to be rendered inside the link
 * @returns A styled anchor element linking to TrustPilot
 *
 * @example
 * <TrustPilotLink>View our reviews</TrustPilotLink>
 *
 * @example
 * <TrustPilotLink className="custom-class" style={{ color: 'blue' }}>
 *   <img src={trustpilotLogo} alt="Trustpilot" />
 * </TrustPilotLink>
 */
export const TrustPilotLink: React.FC<Props> = ({ children, ...props }) => (
  <a
    target="_blank"
    href="https://trustpilot.com/review/cryptotaxcalculator.io"
    rel="noopener"
    {...props}
  >
    {children}
  </a>
);
