import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { invariant } from "~/lib/invariant";
import { useNewBulkLookupMarketPriceActionsMutation } from "~/state/actions";

export const LookUpMarketPriceConfirmation = () => {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");
  const bulkLookupMarketPriceActionsMutation =
    useNewBulkLookupMarketPriceActionsMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.LookUpMarketPrice,
  });

  const onConfirm = () => {
    bulkLookupMarketPriceActionsMutation.mutate(
      {
        filter,
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={<BulkEditOptionChip type={BulkEditOptions.LookUpMarketPrice} />}
      onConfirm={onConfirm}
      loading={bulkLookupMarketPriceActionsMutation.isPending}
    />
  );
};
