import { SyncStatusPlatform } from "@ctc/types";
import {
  type QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import cloneDeep from "lodash/cloneDeep";
import keyBy from "lodash/keyBy";

import {
  importAccountKey,
  useCaptureImportAnalytics,
} from "~/components/imports/AnalyticsHelpers";
import { getRelatedWalletsAction } from "~/components/imports/helpers";
import { useImportViewMode } from "~/components/imports/ImportViewMode";
import { isSavedKeyImport } from "~/components/imports-v2/helpers";
import { displayMessage } from "~/components/ui/Toaster";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useSelector } from "~/redux/useSelector";
import { fireAnalyticsOnDelete } from "~/segment/importAnalytics";
import { HttpError } from "~/services/core";
import * as importsV2 from "~/services/imports";
import { deleteSync } from "~/services/imports";
import { useSyncKeyMutation } from "~/state/keys";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import { useSyncWalletMutation } from "~/state/wallets";
import {
  DisplayMessage,
  ImportType,
  ImportViewMode,
  IntegrationCategory,
} from "~/types/enums";
import {
  type AllSavedImports,
  isBlockchain,
  type SavedFileImport,
  type SavedImportByIntegration,
  type SavedImportOptionByAccount,
  type SavedKeyImport,
  type SavedOAuthImport,
  type SavedWalletImport,
} from "~/types/index";

export const savedImports = {
  all: () => ["savedImports"] as const,
  list: (viewMode: ImportViewMode) => [...savedImports.all(), "list", viewMode],
  summary: (id: string, viewMode: ImportViewMode) =>
    [...savedImports.all(), "summary", id, viewMode] as const,
  holdings: () => [...savedImports.all(), "holdings"] as const,
  import: (id: string, viewMode: ImportViewMode) =>
    [...savedImports.all(), "import", id, viewMode] as const,
  syncs: (importId: string) =>
    [...savedImports.all(), "syncs", importId] as const,
};
export type SavedImportKey = ReturnType<typeof savedImports.list>;

export const useViewModeSyncKey = () => {
  const { viewMode } = useImportViewMode();
  return savedImports.list(viewMode);
};

export const accountHasImports = (
  account: SavedImportOptionByAccount | SavedImportByIntegration,
): boolean => {
  return getTotalImportItems(account) > 0;
};

export const getTotalImportItems = (
  account: SavedImportOptionByAccount | SavedImportByIntegration,
): number => {
  return (
    account.wallets.length +
    account.keys.length +
    account.oauths.length +
    account.files.length
  );
};

export const updateAccountIfHasImportsElseDelete = <
  T extends
    | Record<string, SavedImportByIntegration>
    | Record<string, SavedImportOptionByAccount>,
>(
  id: string,
  accountsMap: T,
  dataToUpdate: Partial<T[keyof T]>,
) => {
  const oldAccountsData = accountsMap[id];

  if (!oldAccountsData) {
    return;
  }

  const updatedAccount = { ...oldAccountsData, ...dataToUpdate };

  if (!accountHasImports(updatedAccount)) {
    delete accountsMap[id];
  } else {
    accountsMap[id] = updatedAccount;
  }
};

export const canSyncAccount = (
  account: SavedImportOptionByAccount | SavedImportByIntegration,
): boolean => {
  return !!(
    account.wallets.length ||
    account.keys.length ||
    account.oauths.length
  );
};

export const useResetSavedImports = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.resetQueries({ queryKey: savedImports.all() });
  };
};

export const useFetchSavedAccount = (importId: string) => {
  const { viewMode } = useImportViewMode();
  return useFetchSavedAccountForView(importId, viewMode);
};

export const fetchSavedAccount = async ({
  queryClient,
  importId,
}: {
  queryClient: QueryClient;
  importId: string;
}) => {
  const { all } = await queryClient.fetchQuery({
    queryKey: savedImports.import(importId, ImportViewMode.ByBlockchain),
    queryFn: async () => {
      const data = await savedImportQueryFn(
        importId,
        ImportViewMode.ByBlockchain,
      );

      const key = savedImports.list(ImportViewMode.ByBlockchain);
      const prevValues = queryClient.getQueryData<AllSavedImports>(key);

      // Update import list state with new update.
      if (prevValues) {
        const newAll = cloneDeep(prevValues.all);

        const newImportsByAccountMap = cloneDeep(
          prevValues.importsByAccountMap,
        );

        const newImportsByIntegrationMap = cloneDeep(
          prevValues.importsByIntegration,
        );

        const propKeys = ["wallets", "oauths", "keys"] as const;

        // Update all saved import options with new import option.
        newAll.forEach((s, i) => {
          propKeys.forEach((accKey) => {
            const accs = s[accKey];
            accs.forEach((acc, accIndex) => {
              if (acc.id === importId) {
                newAll[i][accKey][accIndex] = data.all[0][accKey][0];
              }
            });
          });
        });

        // Update saved import option by with new import option.
        const updateImportOptionBy = (
          savedImportByIntegration:
            | Record<string, SavedImportOptionByAccount>
            | Record<string, SavedImportByIntegration>,
          savedImportOptionByAccountMap:
            | Record<string, SavedImportOptionByAccount>
            | Record<string, SavedImportByIntegration>,
        ) => {
          Object.entries(savedImportByIntegration).forEach(
            ([accId, integration]) => {
              propKeys.forEach((accKey) => {
                const match = (integration[accKey] || []).find(
                  (i) => i.id === importId,
                );

                if (match) {
                  const accs = savedImportByIntegration[accId][accKey];
                  const update = savedImportOptionByAccountMap[accId][accKey];
                  const index = accs.findIndex((acc) => acc.id === importId);
                  savedImportByIntegration[accId][accKey][index] = update[0];
                }
              });
            },
          );
        };

        updateImportOptionBy(newImportsByAccountMap, data.importsByAccountMap);
        updateImportOptionBy(
          newImportsByIntegrationMap,
          data.importsByIntegration,
        );

        // Maybe update account to success if all import options complete.
        newAll.forEach((s, i) => {
          let isComplete = true;

          propKeys.forEach((accKey) => {
            const accs = s[accKey];
            if (isComplete) {
              isComplete = accs.every(
                (acc) => acc.status === SyncStatusPlatform.Success,
              );
            }
          });

          if (isComplete) {
            newAll[i].syncStatus = SyncStatusPlatform.Success;
          }
        });

        // Maybe update import options by account to success if all import
        // options complete.
        Object.entries(newImportsByAccountMap).forEach(([accId]) => {
          let isComplete = true;

          propKeys.forEach((accKey) => {
            if (isComplete) {
              isComplete = newImportsByAccountMap[accId][accKey].every(
                (acc) => acc.status === SyncStatusPlatform.Success,
              );
            }
          });

          if (isComplete) {
            newImportsByAccountMap[accId].syncStatus =
              SyncStatusPlatform.Success;
          }
        });

        const update = {
          all: newAll,
          importsByAccountMap: newImportsByAccountMap,
          importsByIntegration: newImportsByIntegrationMap,
        };

        queryClient.setQueryData(key, update);
      }

      return data;
    },
  });

  return all;
};

// We should be using only this for the source of data that has been imported.
// We should also stitch in holdings data for legacy.
export const useFetchSavedAccounts = () => {
  const { viewMode } = useImportViewMode();
  return useFetchSavedAccountsForView(viewMode);
};

export const fetchSavedAccounts = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}) => {
  const { all } = await queryClient.fetchQuery({
    // this should probably be broken up so we dont need to fetch by view mode to
    // get integrations all together which is unrelated to the view mode
    queryKey: savedImports.list(ImportViewMode.ByBlockchain),
    queryFn: () => allSavedImportsQueryFn(ImportViewMode.ByBlockchain),
  });
  return all;
};

export const allSavedImportsQueryFn = async (
  viewMode: ImportViewMode,
): Promise<AllSavedImports> => {
  const savedAccountsPromise =
    viewMode === ImportViewMode.ByAddress
      ? importsV2.getSavedAccountsByAddress()
      : importsV2.getSavedAccountsByBlockchain();
  const [savedAccounts, savedIntegrations] = await Promise.all([
    savedAccountsPromise,
    importsV2.getSavedIntegrations(),
  ]);

  if (savedAccounts.error) {
    throw new HttpError(savedAccounts, ["useFetchSavedAccounts"]);
  }

  if (savedIntegrations.error) {
    throw new HttpError(savedIntegrations, ["useFetchSavedAccounts"]);
  }

  return {
    all: savedAccounts.data,
    importsByAccountMap: keyBy(savedAccounts.data, "id"),
    importsByIntegration: keyBy(savedIntegrations.data, "id"),
  };
};

const savedImportQueryFn = async (
  importId: string,
  viewMode: ImportViewMode,
): Promise<AllSavedImports> => {
  const savedAccountsPromise =
    viewMode === ImportViewMode.ByAddress
      ? importsV2.getSavedAccountByAddress(importId)
      : importsV2.getSavedAccountByBlockchain(importId);

  const [savedAccounts, savedIntegrations] = await Promise.all([
    savedAccountsPromise,
    importsV2.getSavedIntegration(importId),
  ]);

  if (savedAccounts.error) {
    throw new HttpError(savedAccounts, ["useFetchSavedAccount"]);
  }

  if (savedIntegrations.error) {
    throw new HttpError(savedIntegrations, ["useFetchSavedAccount"]);
  }

  return {
    all: savedAccounts.data,
    importsByAccountMap: keyBy(savedAccounts.data, "id"),
    importsByIntegration: keyBy(savedIntegrations.data, "id"),
  };
};

export function useFetchSavedAccountForView(
  importId: string,
  viewMode: ImportViewMode,
) {
  const user = useUser();
  return useQuery({
    queryKey: savedImports.import(importId, viewMode),
    queryFn: async () => savedImportQueryFn(importId, viewMode),
    staleTime: Infinity,
    enabled: !!user && !!user.country,
  });
}

export function useFetchSavedAccountsForView(viewMode: ImportViewMode) {
  const user = useUser();
  return useQuery({
    queryKey: savedImports.list(viewMode),
    queryFn: async () => allSavedImportsQueryFn(viewMode),
    staleTime: Infinity,
    enabled: !!user && !!user.country,
  });
}

const empty: SavedImportOptionByAccount[] = [];

export const useGetSavedAccounts = () => {
  const savedAccounts = useFetchSavedAccounts();
  if (!savedAccounts.data?.all) {
    return empty;
  }
  return savedAccounts.data?.all;
};

export const useGetSavedImportsByIntegration = (id: string) => {
  const savedAccounts = useFetchSavedAccounts();
  return savedAccounts.data?.importsByIntegration[id];
};

export const useGetMapSavedImportsByIntegrations = (ids: string[]) => {
  const savedAccounts = useFetchSavedAccounts();
  return ids.reduce(
    (acc, id) => {
      const savedImport = savedAccounts.data?.importsByIntegration[id];
      if (savedImport) {
        acc[id] = savedImport;
      }
      return acc;
    },
    {} as Record<string, SavedImportByIntegration>,
  );
};

export const useGetSavedAccountSummaryById = (id: string) => {
  const { viewMode } = useImportViewMode();

  return useQuery({
    queryKey: savedImports.summary(id, viewMode),
    queryFn: async () => {
      const res =
        viewMode === ImportViewMode.ByAddress
          ? await importsV2.getAccountSummaryByAddress(id)
          : await importsV2.getAccountSummaryByBlockchain(id);
      if (res.error) {
        throw new HttpError(res, ["useGetSavedAccountSummaryById"]);
      }

      return res.data;
    },
    staleTime: Infinity,
  });
};

export const useGetMetrics = (
  accountId: string,
  addressBlockchainHash: string | "all",
) => {
  const summary = useGetSavedAccountSummaryById(accountId).data;

  const {
    entitySummaries = {},
    txCount,
    tradeVolume,
    feeVolume,
    accountHoldings,
  } = summary || {};

  if (addressBlockchainHash === "all") {
    return { txCount, tradeVolume, feeVolume, balance: accountHoldings?.value };
  }

  if (entitySummaries[addressBlockchainHash]) {
    return {
      txCount: entitySummaries[addressBlockchainHash].txCount,
      tradeVolume: entitySummaries[addressBlockchainHash].tradeVolume,
      feeVolume: entitySummaries[addressBlockchainHash].feeVolume,
      balance: entitySummaries[addressBlockchainHash].holdings?.value,
    };
  }

  return null;
};

export const useGetHoldings = (
  accountId: string,
  addressBlockchainHash: string | "all",
) => {
  const { data: summary, isLoading } = useGetSavedAccountSummaryById(accountId);
  const { accountHoldings, entitySummaries = {} } = summary || {};

  let holdings;
  if (addressBlockchainHash === "all") {
    holdings = accountHoldings;
  } else {
    holdings = entitySummaries[addressBlockchainHash]?.holdings;
  }

  return {
    holdings,
    isLoading,
  };
};

export const useBulkSyncMutation = (
  importOption: SavedImportOptionByAccount,
) => {
  const queryClient = useQueryClient();

  const lang = useLang();
  const syncKey = useSyncKeyMutation(importOption.name);
  const syncWallet = useSyncWalletMutation();
  const viewModeSyncKey = useViewModeSyncKey();
  const captureAnalytics = useCaptureImportAnalytics();

  return useMutation({
    mutationFn: async ({
      hardSync,
      showMessage = true,
    }: {
      hardSync: boolean;
      showMessage?: boolean;
    }) => {
      const { id, keys, wallets, oauths, name } = importOption;

      captureAnalytics(importAccountKey("bulk sync"), {
        isHardSync: hardSync,
        exchange:
          importOption.category === IntegrationCategory.Blockchain &&
          !isBlockchain(id)
            ? "wallet"
            : id,
        isApi: !!keys.length,
        isOauth: !!oauths.length,
        isWallets: !!wallets.length,
        numSynced: keys.length + oauths.length + wallets.length,
      });

      if (wallets.length) {
        if (showMessage) {
          displayMessage({
            message: lang.sync.syncingBlockchainWallets({
              name,
            }),
            type: DisplayMessage.Info,
          });
        }

        wallets.forEach((c) => {
          syncWallet.mutate({
            wallet: { id: c.id, blockchain: c.blockchain, address: c.address },
            // hard sync if no timestamp
            isHardSync: hardSync,
            relatedWalletsAction: getRelatedWalletsAction(wallets.length),
          });
        });

        // We no longer want to auto import related chains, uncomment this line if we want this behavior again
        // fire and forget, user doesn't need confirmation on this and BE will handle updating FE via socket
        // importRelatedChains(id);
      }
      if (oauths.length) {
        if (showMessage) {
          displayMessage({
            message: lang.sync.syncingName({ name }),
            type: DisplayMessage.Info,
          });
        }

        oauths.forEach((oauth) => {
          syncKey.mutate({
            exchangeId: id,
            name,
            isHardSync: hardSync,
            isOAuth: true,
            apiDetails: oauth,
            showMessage,
          });
        });
      }
      if (keys.length) {
        if (showMessage) {
          displayMessage({
            message: lang.sync.syncingAPIs({ name }),
            type: DisplayMessage.Info,
          });
        }

        keys.forEach((key) => {
          syncKey.mutate({
            exchangeId: id,
            name,
            isHardSync: hardSync,
            isOAuth: false,
            apiDetails: key,
            showMessage,
          });
        });
      }
    },
    onMutate: async () => {
      // id is either Integration (e.g. 'coinbase') | WalletAddress (e.g. 0x123)
      const { id, keys, wallets, oauths } = importOption;
      await queryClient.cancelQueries({ queryKey: savedImports.all() });

      const prevValues =
        queryClient.getQueryData<AllSavedImports>(viewModeSyncKey);

      if (prevValues) {
        const newImportsByAccountMap = cloneDeep(
          prevValues.importsByAccountMap,
        );
        const newImportsByIntegrationMap = cloneDeep(
          prevValues.importsByIntegration,
        );

        const prevImportByAccount = newImportsByAccountMap[id];
        const prevImportByIntegration = newImportsByIntegrationMap[id];

        const newSyncDetails = {
          status: SyncStatusPlatform.Pending,
        };

        if (prevImportByAccount) {
          newImportsByAccountMap[id] = {
            ...prevImportByAccount,
            wallets: (wallets ?? []).map((c) => ({
              ...c,
              ...newSyncDetails,
            })),
            oauths: (oauths ?? []).map((o) => ({
              ...o,
              ...newSyncDetails,
            })),
            keys: (keys ?? []).map((k) => ({
              ...k,
              ...newSyncDetails,
            })),
          };
        }

        if (prevImportByIntegration) {
          newImportsByIntegrationMap[id] = {
            ...prevImportByIntegration,
            keys: (keys ?? []).map((key) => ({
              ...key,
              ...newSyncDetails,
              completedAt: null,
            })),
            oauths: (oauths ?? []).map((o) => ({
              ...o,
              ...newSyncDetails,
              completedAt: null,
            })),
          };
        }

        if (wallets?.length) {
          wallets.forEach((c) => {
            const blockchainAccount = newImportsByIntegrationMap[c.blockchain];
            if (blockchainAccount) {
              newImportsByIntegrationMap[c.blockchain] = {
                ...blockchainAccount,
                wallets: wallets.map((c) => {
                  // check if the current chain address is the same as the `id`
                  if (c.address === id) {
                    return {
                      ...c,
                      ...newSyncDetails,
                    };
                  }
                  return c;
                }),
              };
            }
          });
        }

        const updatedPrevData: AllSavedImports = {
          all: prevValues.all.map((i) => {
            return i.id === importOption.id
              ? {
                  ...i,
                  lastSyncComplete: null,
                  syncStatus: SyncStatusPlatform.Pending,
                  wallets: i.wallets.map((c) => ({
                    ...c,
                    status: SyncStatusPlatform.Pending,
                  })),
                  oauths: i.oauths.map((o) => ({
                    ...o,
                    status: SyncStatusPlatform.Pending,
                  })),
                  keys: i.keys.map((k) => ({
                    ...k,
                    status: SyncStatusPlatform.Pending,
                  })),
                }
              : i;
          }),
          importsByAccountMap: newImportsByAccountMap,
          importsByIntegration: newImportsByIntegrationMap,
        };

        queryClient.setQueryData(viewModeSyncKey, updatedPrevData);
      }

      return {
        prevValues,
      };
    },
    onError: (err, variables, context) => {
      if (context?.prevValues) {
        queryClient.setQueryData<AllSavedImports>(
          viewModeSyncKey,
          context.prevValues,
        );
      }
      queryErrorHandler(err);
    },
  });
};

// todo: The following DeleteKey, DeleteWallet, and DeleteFile mutations are only used by functions in the drawer which are currently not used in the app.
// These could be deleted if we drop the drawer or combined with the delete mutations in wallets.ts, keys.ts, and csv.ts respectively.
// The bulk delete is still used and is the only bulk delete mutation.
export const useDeleteKeyMutation = (
  savedImport: SavedImportOptionByAccount,
) => {
  const queryClient = useQueryClient();
  const user = useUser();
  const viewModeKey = useViewModeSyncKey();
  const captureAnalytics = useCaptureImportAnalytics();
  const errorMessage = useSelector(
    (state) => state.lang.map.keys.wentWrongDeletingAPI,
  );

  return useMutation({
    mutationFn: async (key: SavedKeyImport | SavedOAuthImport) => {
      captureAnalytics(importAccountKey("delete"), {
        exchange: savedImport.id,
        type: "api",
        numDeleted: 1,
      });

      const res = await importsV2.deleteImportByIdAndType({
        importType: isSavedKeyImport(key) ? ImportType.API : ImportType.OAuth,
        importId: key.id,
      });

      await fireAnalyticsOnDelete(user, {
        label: savedImport.id,
        name: key.name,
        importType: ImportType.API,
      });
      if (res.error) {
        throw new Error(errorMessage);
      }
      return res.data;
    },
    onMutate: async (key: SavedKeyImport | SavedOAuthImport) => {
      await queryClient.cancelQueries({ queryKey: savedImports.all() });

      const previousSavedImports =
        queryClient.getQueryData<AllSavedImports>(viewModeKey);
      const isKeyOauth = !isSavedKeyImport(key);

      if (previousSavedImports) {
        const newImportsByAccountMap = cloneDeep(
          previousSavedImports.importsByAccountMap,
        );
        const newImportsByIntegrationMap = cloneDeep(
          previousSavedImports.importsByIntegration,
        );
        const fieldToUpdate = isKeyOauth ? "oauths" : "keys";

        updateAccountIfHasImportsElseDelete(
          savedImport.id,
          newImportsByAccountMap,
          {
            [fieldToUpdate]: savedImport[fieldToUpdate].filter(
              (i) => i.id !== key.id,
            ),
          },
        );

        updateAccountIfHasImportsElseDelete(
          savedImport.id,
          newImportsByIntegrationMap,
          {
            [fieldToUpdate]: savedImport[fieldToUpdate].filter(
              (i) => i.id !== key.id,
            ),
          },
        );

        queryClient.setQueryData(viewModeKey, {
          all: previousSavedImports?.all
            ?.map((prevSavedImport) => {
              if (prevSavedImport.id === savedImport.id) {
                return {
                  ...savedImport,
                  [fieldToUpdate]: savedImport[fieldToUpdate].filter(
                    (i) => i.id !== key.id,
                  ),
                };
              }
              return prevSavedImport;
            })
            .filter((i) => accountHasImports(i)),
          importsByAccountMap: newImportsByAccountMap,
          importsByBlockchainMap: newImportsByIntegrationMap,
        });
      }

      return { previousSavedImports };
    },
    onError: (err, key, context) => {
      if (context?.previousSavedImports) {
        queryClient.setQueryData<AllSavedImports>(
          viewModeKey,
          context.previousSavedImports,
        );
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
    },
  });
};

export const useDeleteWalletMutation = (
  savedImport: SavedImportOptionByAccount,
) => {
  const queryClient = useQueryClient();
  const user = useUser();
  const viewModeKey = useViewModeSyncKey();
  const captureAnalytics = useCaptureImportAnalytics();

  return useMutation({
    mutationFn: async (wallet: SavedWalletImport) => {
      const res = await importsV2.deleteImportByIdAndType({
        importId: wallet.id,
        importType: ImportType.Wallet,
      });
      captureAnalytics(importAccountKey("delete"), {
        exchange: wallet.blockchain,
        type: "wallet",
        numDeleted: 1,
      });

      if (res.error) {
        throw new HttpError(res, ["deleteWallet"]);
      }

      await fireAnalyticsOnDelete(user, {
        blockchain: wallet.blockchain,
        address: wallet.id,
        name: wallet.name,
        importType: ImportType.Wallet,
      });
    },

    onMutate: async (wallet: SavedWalletImport) => {
      const { wallets, id } = savedImport;
      await queryClient.cancelQueries({ queryKey: savedImports.all() });
      const previousSavedImports =
        queryClient.getQueryData<AllSavedImports>(viewModeKey);

      if (previousSavedImports) {
        const newImportsByAccountMap = cloneDeep(
          previousSavedImports.importsByAccountMap,
        );
        const newImportsByIntegrationMap = cloneDeep(
          previousSavedImports.importsByIntegration,
        );

        updateAccountIfHasImportsElseDelete(id, newImportsByAccountMap, {
          wallets: (wallets ?? []).filter((c) => c.id !== wallet.id),
        });

        if (wallets?.length) {
          wallets.forEach((c) => {
            updateAccountIfHasImportsElseDelete(
              c.blockchain,
              newImportsByIntegrationMap,
              {
                wallets: wallets.filter((c) => c.id !== wallet.id),
              },
            );
          });
        }

        queryClient.setQueryData<AllSavedImports>(viewModeKey, {
          all: previousSavedImports.all
            ?.map((prevSavedImport) => {
              if (prevSavedImport.id === savedImport.id) {
                return {
                  ...savedImport,
                  wallets: savedImport.wallets.filter(
                    (c) => c.id !== wallet.id,
                  ),
                };
              }
              return prevSavedImport;
            })
            .filter((i) => accountHasImports(i)),
          importsByAccountMap: newImportsByAccountMap,
          importsByIntegration: newImportsByIntegrationMap,
        });
      }

      return { previousSavedImports };
    },
    onError: (err, wallet, context) => {
      if (context?.previousSavedImports) {
        queryClient.setQueryData<AllSavedImports>(
          viewModeKey,
          context.previousSavedImports,
        );
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
    },
  });
};

export const useDeleteFileMutation = (
  savedImport: SavedImportOptionByAccount,
) => {
  const viewModeKey = useViewModeSyncKey();
  const queryClient = useQueryClient();
  const user = useUser();
  // TODO: if savedImport is of blockchain type ensure exchange is set to blockchain name
  const { id: exchange } = savedImport;
  const errorMessage = useSelector(
    (state) => state.lang.map.imports.wentWrongDeleteFile,
  );
  const captureAnalytics = useCaptureImportAnalytics();

  return useMutation({
    mutationFn: async (file: SavedFileImport) => {
      const { id, name } = file;
      captureAnalytics(importAccountKey("delete"), {
        exchange,
        type: "csv",
        numDeleted: 1,
      });

      await fireAnalyticsOnDelete(user, {
        label: exchange,
        name,
        importType: ImportType.CSV,
      });
      const res = await importsV2.deleteImportByIdAndType({
        importId: id,
        importType: ImportType.CSV,
      });
      if (res.error) {
        throw new Error(errorMessage);
      }
      return res.data;
    },

    onMutate: async (file: SavedFileImport) => {
      await queryClient.cancelQueries({ queryKey: savedImports.all() });
      const previousSavedImports =
        queryClient.getQueryData<AllSavedImports>(viewModeKey);

      if (previousSavedImports) {
        const newImportsByAccountMap = cloneDeep(
          previousSavedImports.importsByAccountMap,
        );
        const newImportsByIntegrationMap = cloneDeep(
          previousSavedImports.importsByIntegration,
        );
        updateAccountIfHasImportsElseDelete(
          savedImport.id,
          newImportsByAccountMap,
          {
            files: savedImport.files.filter((f) => f.id !== file.id),
          },
        );

        updateAccountIfHasImportsElseDelete(
          savedImport.id,
          newImportsByIntegrationMap,
          {
            files: savedImport.files.filter((f) => f.id !== file.id),
          },
        );

        queryClient.setQueryData<AllSavedImports>(viewModeKey, {
          all: previousSavedImports?.all
            ?.map((prevSavedImport) => {
              if (prevSavedImport.id === savedImport.id) {
                return {
                  ...savedImport,
                  files: savedImport.files.filter((f) => f.id !== file.id),
                };
              }
              return prevSavedImport;
            })
            .filter((i) => accountHasImports(i)),
          importsByAccountMap: newImportsByAccountMap,
          importsByIntegration: newImportsByIntegrationMap,
        });
      }

      return { previousSavedImports };
    },
    onError: (err, file, context) => {
      if (context?.previousSavedImports) {
        queryClient.setQueryData<AllSavedImports>(
          viewModeKey,
          context.previousSavedImports,
        );
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
    },
  });
};

export const useBulkDeleteMutations = (
  importOption: SavedImportOptionByAccount,
) => {
  const queryClient = useQueryClient();
  const viewModeKey = useViewModeSyncKey();
  const captureAnalytics = useCaptureImportAnalytics();

  return useMutation({
    mutationFn: async () => {
      const { id, keys: key, wallets: wallet, oauths, files } = importOption;
      captureAnalytics(importAccountKey("delete"), {
        exchange:
          importOption.category === IntegrationCategory.Blockchain &&
          !isBlockchain(id)
            ? "wallet"
            : id,
        type: "bulk",
        numDeleted: key.length + oauths.length + wallet.length + files.length,
      });

      const deleteResponses = await Promise.all([
        ...importOption.keys.map(
          async (key) =>
            await importsV2.deleteImportByIdAndType({
              importId: key.id,
              importType: ImportType.API,
            }),
        ),
        ...importOption.oauths.map(
          async (oauth) =>
            await importsV2.deleteImportByIdAndType({
              importId: oauth.id,
              importType: ImportType.OAuth,
            }),
        ),
        ...importOption.files.map(
          async (file) =>
            await importsV2.deleteImportByIdAndType({
              importId: file.id,
              importType: ImportType.CSV,
            }),
        ),
        ...importOption.wallets.map(
          async (wallet) =>
            await importsV2.deleteImportByIdAndType({
              importId: wallet.id,
              importType: ImportType.Wallet,
            }),
        ),
      ]);

      const errorRes = deleteResponses.find((res) => res.error);

      if (errorRes?.error) {
        throw new HttpError(errorRes, ["bulkDelete"]);
      }

      return {};
    },
    onMutate: async () => {
      const {
        id,
        wallets: importOptionChains,
        keys,
        oauths,
        files,
      } = importOption;

      await queryClient.cancelQueries({ queryKey: savedImports.all() });

      const prevValues = queryClient.getQueryData<AllSavedImports>(viewModeKey);

      if (prevValues) {
        const newImportsByAccountMap = cloneDeep(
          prevValues.importsByAccountMap,
        );
        const newImportsByIntegrationMap = cloneDeep(
          prevValues.importsByIntegration,
        );
        delete newImportsByAccountMap[id];

        if (importOptionChains?.length) {
          importOptionChains.forEach((c) => {
            const oldBlockchainAccountData =
              newImportsByIntegrationMap[c.blockchain];

            updateAccountIfHasImportsElseDelete(
              c.blockchain,
              newImportsByIntegrationMap,
              {
                wallets: oldBlockchainAccountData
                  ? oldBlockchainAccountData.wallets.filter(
                      (wallet) => wallet.id !== c.id,
                    )
                  : [],
              },
            );
          });
        }

        if (keys?.length || oauths?.length || files?.length) {
          delete newImportsByIntegrationMap[id];
        }

        queryClient.setQueryData<AllSavedImports>(viewModeKey, {
          all: prevValues.all.filter((account) => account.id !== id),
          importsByAccountMap: newImportsByAccountMap,
          importsByIntegration: newImportsByIntegrationMap,
        });
      }

      return {
        prevValues,
      };
    },
    onError: (err, variables, context) => {
      if (context?.prevValues) {
        queryClient.setQueryData<AllSavedImports>(
          viewModeKey,
          context.prevValues,
        );
      }
      queryErrorHandler(err);
    },
  });
};

export const useDeleteSyncMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const res = await deleteSync({ id });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["deleteSync"]);
      }

      return res.data;
    },
    onError: (err) => {
      queryErrorHandler(err);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: savedImports.all() });
    },
  });
};

export const useGetSyncsByImportId = (importId: string, isOpen: boolean) => {
  const { data, isLoading } = useQuery({
    queryKey: savedImports.syncs(importId),
    queryFn: async () => {
      const res = await importsV2.getSyncsByImportId(importId);
      if (res.error) {
        throw new HttpError(res, ["useGetSyncByImportId"]);
      }
      return res.data;
    },
    enabled: isOpen,
    staleTime: Infinity,
  });
  return { data, isLoading };
};
