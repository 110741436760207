import { ArrowForward } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

import { Calco } from "../ui/calco/Calco";
import { PrimaryButton } from "../ui/ui-buttons/PrimaryButton";
import { StyledBox } from "./ui/StyledBox";
import { StyledChip } from "./ui/StyledChip";
import { StyledContainer } from "./ui/StyledContainer";

/**
 * Component to display when a coupon is successfully applied
 */
export function PartnerBenefitApplied() {
  const lang = useLang();
  const { tokens } = useDesign();
  const navigate = useNavigate();

  return (
    <StyledContainer mt="8rem">
      <StyledChip>
        <Typography variant="Metropolis/Header/H4" color={tokens.text.success}>
          {lang.partnerEmployeeBenefits.discountApplied.title}
        </Typography>
      </StyledChip>

      <StyledBox sx={{ alignItems: "center" }}>
        <Calco type="gift" />
        <Typography>
          {lang.partnerEmployeeBenefits.discountApplied.description}
        </Typography>

        <PrimaryButton
          onClick={() => {
            navigate(Links.Plans);
          }}
          fullWidth
          endIcon={<ArrowForward />}
        >
          {lang.partnerEmployeeBenefits.discountApplied.cta}
        </PrimaryButton>
      </StyledBox>
    </StyledContainer>
  );
}
