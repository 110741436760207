import { type Blockchain } from "@ctc/types";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, MenuItem, Stack, TextField, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from "react";
import styled from "styled-components/macro";

import { type RelatedChainsForAddress } from "~/components/imports-v2/types";
import { AddressChip } from "~/components/ui/AddressChip";
import { BlockchainLogo } from "~/components/ui/BlockchainLogo";
import { Chip } from "~/components/ui/Chips";
import { AddressChipStyle } from "~/components/ui/enums";
import { useIsMobile } from "~/components/ui/hooks";

import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useFakeProgress } from "~/hooks/useFakeProgress";
import { useDesign } from "~/hooks/useTheme";
import { middleTrim } from "~/lib/index";
import { useLang } from "~/redux/lang";
import { useGetAllRelatedChains } from "~/state/wallets";
import { BlockchainName, EvmBlockchains, isEVMBlockchain } from "~/types/index";

const StyledTextButton = styled(TextButton)`
  color: ${({ theme }) => `${theme.tokens.text.low}`} !important;

  &:hover,
  &:focus {
    color: ${({ theme }) => `${theme.tokens.text.default}`} !important;
  }
`;

function BlockchainSelector({
  value,
  onChange,
}: {
  value?: Blockchain;
  onChange: (blockchain: Blockchain) => void;
}) {
  const blockchains = Object.values(EvmBlockchains).sort();
  const lang = useLang();

  return (
    <TextField
      select
      variant="outlined"
      label={lang.imports.relatedChains.addBlockchain}
      onChange={(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        const blockchain = event.target.value as Blockchain;
        onChange(blockchain);
      }}
      value={value}
      size="small"
      sx={{
        minWidth: "12rem",
        ".MuiInputLabel-root": {
          fontSize: "0.75rem",
          lineHeight: "1.75",
        },
      }}
      InputLabelProps={{
        shrink: false,
      }}
    >
      {blockchains.map((blockchain) => {
        const blockchainTyped = blockchain as Blockchain;
        return (
          <MenuItem key={blockchainTyped} value={blockchainTyped}>
            {BlockchainName[blockchainTyped]}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export function ImportsRelatedWalletsSuccess({
  address,
  addressFormatted,
  blockchain,
  name,
  importFromTimeMillis,
  importSource,
  onNext,
  selectedBlockchains,
  setSelectedBlockchains,
}: {
  address: string;
  addressFormatted: string;
  blockchain: Blockchain;
  name?: string | undefined;
  importFromTimeMillis?: number;
  importSource?: string;
  onNext: () => void;
  selectedBlockchains: RelatedChainsForAddress;
  setSelectedBlockchains: Dispatch<SetStateAction<RelatedChainsForAddress>>;
}) {
  const { tokens } = useDesign();
  const lang = useLang();

  const [isBlockchainSelectorVisible, setBlockchainSelectorVisible] =
    useState(false);

  const handleBlockchainCancel = (blockchainRemove: Blockchain) => {
    return () => {
      setSelectedBlockchains((state) => {
        const newState = new Set(state[address] ?? []);
        newState.delete(blockchainRemove);
        return {
          ...state,
          [address]: newState,
        };
      });
    };
  };

  const handleBlockchainAdd = (blockchainAdd: Blockchain) => {
    setSelectedBlockchains((state) => {
      const newState = new Set(state[address] ?? []);
      newState.add(blockchainAdd);
      return {
        ...state,
        [address]: newState,
      };
    });

    setBlockchainSelectorVisible(false);
  };

  return (
    <Stack direction="column" gap="0.75rem">
      <Box
        sx={{
          background: tokens.background.success.default,
          color: tokens.text.success,
          border: `1px solid ${tokens.border.success}`,
          padding: "0.5rem 0.75rem",
          borderRadius: "4px",
        }}
      >
        <Stack direction="row" gap="1rem" alignItems="center">
          <CheckCircleIcon fontSize="small" />
          <Typography
            variant={"Metropolis/Header/H5"}
            sx={{ color: "inherit" }}
          >
            {lang.imports.relatedChains.scanningSuccess}
          </Typography>
          <Box sx={{ position: "relative", top: "-1px" }}>
            <AddressChip
              style={AddressChipStyle.Default}
              typographyProps={{ sx: { fontSize: "1rem", fontWeight: 600 } }}
              address={address}
              blockchain={blockchain}
              enableCopy
            />
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          borderTop: `1px solid ${tokens.border.neutral.default}`,
          paddingTop: "0.5rem",
        }}
      >
        <Stack direction="row" gap="0.5rem" sx={{ flexWrap: "wrap" }}>
          {Array.from(selectedBlockchains[address] ?? [])
            .filter((chain) => !!BlockchainName[chain])
            .map((chain) => (
              <Chip
                key={chain}
                sx={{
                  background: tokens.background.accent.neutral.medium,
                  padding: "0 0.5rem 0 0",
                }}
              >
                <Stack direction="row" alignItems="center">
                  <BlockchainLogo width={12} height={12} blockchain={chain} />
                  <Typography variant={"Metropolis/Body/Regular"}>
                    {BlockchainName[chain]}
                  </Typography>
                  <CloseIcon
                    onClick={handleBlockchainCancel(chain)}
                    sx={{
                      cursor: "pointer",
                      fontSize: "0.75rem",
                      marginLeft: "0.25rem",
                      "&:hover": { color: tokens.text.high },
                    }}
                    fontSize="small"
                  />
                </Stack>
              </Chip>
            ))}
          {!isBlockchainSelectorVisible ? (
            <Chip
              onClick={() => {
                setBlockchainSelectorVisible(true);
              }}
              sx={{
                padding: "0.25rem 0.75rem 0.25rem 0.25rem",
                color: tokens.text.brand,
                cursor: "pointer",
                "&:hover": { color: tokens.text.high },
              }}
            >
              <Stack direction="row" alignItems="center" gap="0.25rem">
                <AddIcon
                  sx={{
                    cursor: "pointer",
                    fontSize: "0.75rem",
                    marginLeft: "0.25rem",
                    color: "inherit",
                  }}
                  fontSize="small"
                />
                <Typography
                  variant={"Metropolis/Body/Regular"}
                  sx={{
                    color: "inherit",
                  }}
                >
                  {lang.add}
                </Typography>
              </Stack>
            </Chip>
          ) : null}
        </Stack>
      </Box>
      {isBlockchainSelectorVisible ? (
        <Stack direction="row" gap="0.25rem" sx={{ textAlign: "left" }}>
          <Box>
            <BlockchainSelector
              onChange={(chain) => {
                handleBlockchainAdd(chain);
              }}
            />
          </Box>
          <StyledTextButton
            size="small"
            sx={{
              paddingLeft: "0.25rem",
              paddingRight: "0.25rem",
              minWidth: 0,
            }}
            onClick={() => {
              setBlockchainSelectorVisible(false);
            }}
          >
            <CloseIcon sx={{ fontSize: "0.75rem" }} fontSize="small" />
          </StyledTextButton>
        </Stack>
      ) : null}
    </Stack>
  );
}

const START_PERCENTAGE = 15;
const STALL_PERCENTAGE = 90;
const INCREMENT_MS = 100;

export type ImportRelatedChainAddress = {
  address: string;
  blockchain: Blockchain;
  name?: string | undefined;
  importFromTimeMillis?: number;
  importSource?: string;
};

export function ImportRelatedWallets({
  address,
  onClose,
  selectedBlockchains,
  setSelectedBlockchains,
  onNoResults,
  name,
}: {
  address: ImportRelatedChainAddress;
  onClose: () => void;
  selectedBlockchains: RelatedChainsForAddress;
  setSelectedBlockchains: Dispatch<SetStateAction<RelatedChainsForAddress>>;
  onNoResults?: () => void;
  name?: string;
}) {
  const [isDone, setDone] = useState<boolean>(false);
  // Tracks the steps when multiple addresses are paged in related chains UI.
  const isMobile = useIsMobile();

  if (!isEVMBlockchain(address.blockchain)) {
    return null;
  }

  const addressFormatted = middleTrim(address.address);

  const relatedChains = useGetAllRelatedChains([
    {
      ...address,
      relatedChains: EvmBlockchains,
    },
  ]);
  const lang = useLang();
  const { tokens } = useDesign();
  const progress = useFakeProgress({
    isDone,
    increment: 0.01,
    incrementInterval: INCREMENT_MS,
    initialProgress: START_PERCENTAGE,
    stalledQuantity: STALL_PERCENTAGE,
  });

  useEffect(() => {
    if (relatedChains.isFetched) {
      if (relatedChains.data) {
        setDone(true);
        if (relatedChains.data.size === 0) {
          onNoResults ? onNoResults() : onClose();
        } else {
          const newState: RelatedChainsForAddress = {};
          relatedChains.data.forEach((chains, address) => {
            newState[address] = new Set(chains);
          });
          setSelectedBlockchains(newState);
        }
      }
    }
  }, [relatedChains.isFetched]);

  if (relatedChains.isLoading) {
    const progressFormatted = Math.round(progress.value);
    return (
      <Box sx={{ textAlign: "center", padding: "1rem" }}>
        <Typography variant={"Metropolis/Body/Regular"} mb="0.75rem">
          {lang.imports.relatedChains.scanning({ wallet: addressFormatted })}
        </Typography>
        <Box sx={{ position: "relative", margin: "0 auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Box sx={{ mr: "0.75rem", mt: "0.25rem" }}>
              <LinearProgress
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor: tokens.background.accent.purple.medium,
                  },
                  [`&.${linearProgressClasses.root}`]: {
                    width: "17.5rem",
                    height: "0.375rem",
                    backgroundColor: tokens.background.accent.neutral.low,
                  },
                }}
                variant="determinate"
                value={progress.value}
              />
            </Box>
            <Box>
              <Typography
                variant={"Metropolis/Caption/Medium/Regular"}
                color={tokens.text.low}
                sx={{ position: isMobile ? "relative" : "absolute", top: 0 }}
              >
                {progressFormatted >= STALL_PERCENTAGE
                  ? lang.imports.relatedChains.stillScanning
                  : `${progressFormatted}%`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: "0.25rem" }}>
            <StyledTextButton
              size="small"
              onClick={() => {
                setSelectedBlockchains({});
                onClose();
              }}
              startIcon={
                <CloseIcon sx={{ fontSize: "0.75rem" }} fontSize="small" />
              }
            >
              {lang.skip}
            </StyledTextButton>
          </Box>
        </Box>
      </Box>
    );
  }

  const addressesWithRelatedChains = relatedChains.data?.get(address.address);
  if (!addressesWithRelatedChains) {
    return (
      <Box sx={{ textAlign: "center", padding: "1rem" }}>
        <Typography variant={"Metropolis/Body/Regular"} mb="0.75rem">
          <Box>
            {lang.imports.relatedChains.scanningNoResults({
              address: addressFormatted,
            })}
          </Box>
        </Typography>
      </Box>
    );
  }

  return (
    <Box textAlign="center">
      <ImportsRelatedWalletsSuccess
        address={address.address}
        addressFormatted={addressFormatted}
        blockchain={address.blockchain}
        name={name ?? address.name}
        importFromTimeMillis={address.importFromTimeMillis}
        importSource={address.importSource}
        selectedBlockchains={selectedBlockchains}
        setSelectedBlockchains={setSelectedBlockchains}
        onNext={onClose}
      />
    </Box>
  );
}
