import { Plan } from "@ctc/types";
import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { AnalyticEvent } from "~/analytics/analyticsProperties";
import { useCaptureAnalyticEvents } from "~/analytics/captureAnalyticEvents";
import { usePlanPricing } from "~/components/payment/helpers";
import { useIsMobile } from "~/components/ui/hooks";
import metamaskLight from "~/components/ui/images/SVG_MetaMask_Horizontal_black.svg";
import metamaskDark from "~/components/ui/images/SVG_MetaMask_Horizontal_White.svg";
import { Partnership } from "~/components/ui/Partnership";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import {
  PaymentProvider,
  usePayment,
} from "~/contexts/payment-context/PaymentContext";
import {
  FeatureCard,
  PlanCard,
  StyledLink,
} from "~/contexts/paywall-modal-context/irs-paywall-modal/helpers";
import { IrsPaywallModalBase } from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModalBase";
import { type PaywallModalProps } from "~/contexts/paywall-modal-context/irs-paywall-modal/types";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useIsMetamaskEmbedded } from "~/hooks/useIsMetamaskEmbedded";
import { useDesign } from "~/hooks/useTheme";
import { useTransactionsBilledCount } from "~/hooks/useTransactions";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useGetActionsCountQuery } from "~/state/actions";
import { useBestCoupons } from "~/state/billing";
import { usePlans } from "~/state/plans";
import { type RetailPlanData } from "~/types";
import { Links } from "~/types/enums";

export const PAYWALL_MODAL_VARIANT = "choose_plan" as const;

export function IrsPaywallModalChoosePlan({
  open,
  onClose,
  showCountdown = false,
}: PaywallModalProps) {
  const lang = useLang();
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const txCount = useGetActionsCountQuery();
  const isEmbedded = useIsEmbedded();
  const isMetamaskEmbedded = useIsMetamaskEmbedded();

  const planOptions = usePlanOptions();
  const [selectedPlan, setSelectedPlan] = useState(planOptions[0]);

  useEffect(() => {
    if (!selectedPlan && planOptions.length > 0) {
      setSelectedPlan(planOptions[0]);
    }
  }, [selectedPlan, planOptions]);

  if (planOptions.length === 0) {
    return (
      <IrsPaywallModalBase
        open={open}
        variant={PAYWALL_MODAL_VARIANT}
        onClose={onClose}
        showCountdown={showCountdown}
        maxWidth="md"
      >
        <Box
          width="100%"
          height="10rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      </IrsPaywallModalBase>
    );
  }

  return (
    <IrsPaywallModalBase
      open={open}
      variant={PAYWALL_MODAL_VARIANT}
      onClose={onClose}
      showCountdown={showCountdown}
      maxWidth="md"
    >
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        gap="3.25rem"
        width="100%"
        height="100%"
        justifyContent={isMobile ? "space-between" : "center"}
        p={!isMobile ? "2rem 2.5rem" : undefined}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          gap="1rem"
          width={isMobile ? "100%" : "50%"}
        >
          <Box display="flex" flexDirection="column" gap="1rem">
            {isEmbedded && isMetamaskEmbedded ? (
              <Box width="60%">
                <Partnership
                  partnerSrcLight={metamaskLight}
                  partnerSrcDark={metamaskDark}
                  partnerAlt="Metamask"
                  disableHeader
                />
              </Box>
            ) : null}
            <Typography
              variant="Metropolis/Header/H2"
              color={tokens.text.high}
              whiteSpace="pre-wrap"
            >
              {lang.paywallModal.irsVariantChoosePlan.title}
            </Typography>
            <Box display="flex" flexDirection="column" gap="0.75rem">
              {lang.paywallModal.irsVariantPreSelectedPlan.features.map(
                ({ title, description }, index) => (
                  <FeatureCard
                    key={index}
                    title={title}
                    description={description}
                  />
                ),
              )}
            </Box>
          </Box>
        </Box>
        <PaymentProvider>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            gap="0.5rem"
            width={isMobile ? "100%" : "50%"}
            mt={isMobile ? "0rem" : "1rem"}
          >
            {txCount.data ? (
              <Typography
                variant="Metropolis/Caption/Small/Regular"
                color={tokens.text.low}
              >
                {lang.paywallModal.irsVariantChoosePlan.txCount({
                  txCount: txCount.data.txBilledCount ?? 0,
                })}
              </Typography>
            ) : (
              <Skeleton width="7.5rem" height="0.625rem" />
            )}
            <Box display="flex" flexDirection="column" gap="1rem" width="100%">
              {planOptions.map((plan) => (
                <PlanCard
                  key={plan}
                  selected={selectedPlan === plan}
                  setSelected={setSelectedPlan}
                  plan={plan}
                  showTaxSeasonCountdown={showCountdown}
                  variant={PAYWALL_MODAL_VARIANT}
                />
              ))}
              <PaymentSection
                showTaxSeasonCountdown={showCountdown}
                selectedPlan={selectedPlan}
                onClose={onClose}
              />
            </Box>
          </Box>
        </PaymentProvider>
      </Box>
    </IrsPaywallModalBase>
  );
}

/**
 * Returns an array of plans (1-2) based on transaction limits and willRemovePaywall:
 * 1. Filters plans to only those with willRemovePaywall: true
 * 2. If eligible for Investor plan, selects it and removes from filtered array
 * 3. Selects lowest tx limit plan from filtered array
 * 4. If only 1 plan selected, selects next lowest from filtered array
 * 5. If no filtered plans, selects highest tx limit plan from unfiltered array
 * Returns selected plans sorted by tx limit
 */
function usePlanOptions(): Plan[] {
  const txCount = useTransactionsBilledCount();
  const plans = usePlans().data;

  if (!plans) {
    return [];
  }

  // Convert plans to array of entries and sort by tx limit
  const allPlans: [Plan, RetailPlanData][] = Object.entries(plans).sort(
    ([, a], [, b]) =>
      a.paywallLimits.txCountLimit - b.paywallLimits.txCountLimit,
  ) as [Plan, RetailPlanData][];

  // Filter plans that will remove paywall
  const filteredPlans = allPlans.filter(([, plan]) => plan.willRemovePaywall);

  // Check if user is eligible for Investor plan
  const investorPlan = plans[Plan.Investor];
  const isEligibleForInvestor =
    investorPlan && txCount < investorPlan.paywallLimits.txCountLimit;

  // Initialize selected plans array
  const selectedPlans: Plan[] = [];

  // If eligible for Investor, add it and remove from filtered plans
  if (isEligibleForInvestor) {
    selectedPlans.push(Plan.Investor);
    const filteredPlansWithoutInvestor = filteredPlans.filter(
      ([planType]) => planType !== Plan.Investor,
    );

    // If we have filtered plans, add the lowest one
    if (filteredPlansWithoutInvestor.length > 0) {
      selectedPlans.push(filteredPlansWithoutInvestor[0][0] as Plan);
    }
  } else {
    // If not eligible for Investor, try to get plans from filtered array
    if (filteredPlans.length > 0) {
      // Add lowest plan
      selectedPlans.push(filteredPlans[0][0] as Plan);

      // If we have more filtered plans, add the next lowest
      if (filteredPlans.length > 1) {
        selectedPlans.push(filteredPlans[1][0] as Plan);
      }
    } else {
      // If no filtered plans, select highest tx limit plan from all plans
      selectedPlans.push(allPlans[allPlans.length - 1][0] as Plan);
    }
  }

  // Sort selected plans by tx limit
  return selectedPlans.sort(
    (a, b) =>
      (plans[a]?.paywallLimits.txCountLimit ?? 0) -
      (plans[b]?.paywallLimits.txCountLimit ?? 0),
  );
}

function PaymentSection({
  showTaxSeasonCountdown,
  selectedPlan,
  onClose,
}: {
  showTaxSeasonCountdown: boolean;
  selectedPlan: Plan;
  onClose: () => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  const { isRedirecting, handlePlanChange } = usePayment();
  const couponData = useBestCoupons();
  const captureAnalytics = useCaptureAnalyticEvents();

  const { priceDiscounted } = usePlanPricing({
    planType: selectedPlan,
  });

  const isDiscounted = !!priceDiscounted;

  const stripeCouponCode =
    couponData.data?.couponsByPlan[selectedPlan]?.stripeCouponCode;

  const onClick = () => {
    handlePlanChange(selectedPlan, true, stripeCouponCode);
    captureAnalytics(AnalyticEvent.PAYWALL_MODAL.PURCHASE_PLAN_BUTTON_CLICKED, {
      plan: selectedPlan,
      showTaxSeasonCountdown,
      variant: PAYWALL_MODAL_VARIANT,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="0.5rem"
      width="100%"
    >
      <PrimaryButton
        onClick={onClick}
        disabled={isRedirecting}
        sx={{ width: "100%" }}
        endIcon={
          isRedirecting ? (
            <CircularProgress
              size="1rem"
              sx={{ color: tokens.text.disabled }}
            />
          ) : null
        }
      >
        {lang.paywallModal.irsVariantChoosePlan.cta}
      </PrimaryButton>
      <Box
        display="flex"
        flexDirection="column"
        gap="0.25rem"
        alignItems="center"
      >
        <StyledLink
          to={Links.Plans}
          onClick={() => {
            captureAnalytics(
              AnalyticEvent.PAYWALL_MODAL.VIEW_PLANS_BUTTON_CLICKED,
              {
                showTaxSeasonCountdown,
                variant: PAYWALL_MODAL_VARIANT,
              },
            );
            onClose();
          }}
        >
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            color={tokens.text.brand}
          >
            {lang.paywallModal.irsVariantChoosePlan.secondary}
          </Typography>
        </StyledLink>
        <Box textAlign="center">
          <Typography
            variant="Metropolis/Caption/Small/Regular"
            color={tokens.text.low}
          >
            {lang.paywallModal.irsVariantPreSelectedPlan.disclaimerOne}
          </Typography>
          {isDiscounted ? (
            <Typography
              variant="Metropolis/Caption/Small/Regular"
              color={tokens.text.low}
            >
              {lang.paywallModal.irsVariantPreSelectedPlan.disclaimerTwo}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
