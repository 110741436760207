import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  type OutlinedInputProps,
  Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { type FilterOptionsState } from "@mui/material/useAutocomplete";
import type * as React from "react";

import {
  ListboxComponent,
  renderGroup,
} from "~/components/transactions/AutocompleteListboxComponent";
import { BlockchainLogo } from "~/components/ui/BlockchainLogo";
import { useDesign } from "~/hooks/useTheme";
import { displayCurrency } from "~/lib/index";
import { useUniqueBlockchainsFromImports } from "~/redux/imports";
import { useLoadingHoldings } from "~/state/holdings";
import { BlockchainName } from "~/types/index";

type BlockchainLabel = {
  blockchain: string | null;
  label: string;
};

const filter = createFilterOptions<BlockchainLabel>({
  stringify: (option) => `${option.blockchain}__${option.label}`,
});

export const BlockchainComboBox = ({
  id,
  value,
  helperText,
  setValue,
  size = "medium",
  disabled = false,
  ...outlinedInputProps
}: {
  id: string;
  value: string | null;
  helperText?: string;
  setValue: (value: string | null) => void;
  size?: "small" | "medium";
  disabled?: boolean;
} & OutlinedInputProps) => {
  const { tokens } = useDesign();
  const isLoadingImportOptions = useLoadingHoldings();

  // Unique blockchains from imports, such as "polygon" or "avalanche"
  const options: BlockchainLabel[] = useUniqueBlockchainsFromImports().map(
    (blockchain) => {
      return {
        blockchain,
        label: BlockchainName[blockchain as keyof typeof BlockchainName],
      };
    },
  );
  const logoSize = size === "small" ? 20 : undefined;

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value: BlockchainLabel): boolean => {
        return (
          option.blockchain === value.blockchain || option.label === value.label
        );
      }}
      filterOptions={(
        options: BlockchainLabel[],
        params: FilterOptionsState<BlockchainLabel>,
      ) => {
        return filter(options, params);
      }}
      size={size}
      disabled={disabled}
      loading={isLoadingImportOptions}
      fullWidth
      freeSolo
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string" || newValue === null) {
          setValue(newValue);
        } else {
          setValue(newValue.blockchain);
        }
      }}
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={id}
      options={options}
      getOptionLabel={(option: BlockchainLabel | string) => {
        return typeof option === "string"
          ? BlockchainName[option as keyof typeof BlockchainName] || ""
          : option.label || "";
      }}
      renderGroup={renderGroup}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <BlockchainFilterRow
            data={option}
            wide={size !== "small"}
            hideMargin
          />
        </Box>
      )}
      renderInput={(params) => {
        const { InputProps, InputLabelProps, inputProps, ...otherParams } =
          params;
        return (
          <FormControl
            fullWidth
            error={outlinedInputProps.error}
            required={outlinedInputProps.required}
            size={size}
            style={{ height: "100%" }}
          >
            <InputLabel
              variant="outlined"
              {...InputLabelProps}
              shrink
              sx={{
                color: disabled ? tokens.text.disabled : tokens.text.default,
              }}
            >
              {outlinedInputProps.label}
            </InputLabel>
            <OutlinedInput
              {...otherParams}
              {...outlinedInputProps}
              {...InputProps}
              notched
              sx={{
                backgroundColor: disabled
                  ? tokens.background.disabled
                  : tokens.background.input.default,
              }}
              required={false}
              // Stops lastpass from popping up
              autoComplete="off"
              {...(value
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <BlockchainLogo
                          blockchain={value}
                          width={logoSize}
                          height={logoSize}
                          margin="0.5rem 0 0.5rem 0.5rem"
                        />
                      </InputAdornment>
                    ),
                  }
                : null)}
              inputProps={{ ...inputProps, ...outlinedInputProps.inputProps }}
            />
            {helperText ? (
              <FormHelperText required={outlinedInputProps.required}>
                {helperText || ""}
              </FormHelperText>
            ) : null}
          </FormControl>
        );
      }}
    />
  );
};

const BlockchainFilterRow = ({
  data,
  hideMargin = false,
  wide = false,
}: {
  data: BlockchainLabel;
  hideMargin?: boolean;
  wide?: boolean;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      ml={hideMargin ? "-0.5rem" : "0"}
    >
      <Box component="span" pr="2px">
        <BlockchainLogo
          blockchain={data.blockchain ? data.blockchain : undefined}
        />
      </Box>
      <Box display="flex" flexDirection="column" width="calc(100% - 1.5rem)">
        <Typography variant="Metropolis/Body/Light">
          <Box
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {wide
              ? displayCurrency(data.label, 32, 28)
              : displayCurrency(data.label, 12, 10)}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};
