import styled from "styled-components/macro";

export const TxTableOverflowShadow = styled.div<{ scrollPoint: number }>`
  position: relative;
  @media (max-width: ${(props) => props.scrollPoint}px) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 111;
      width: 10px;
      box-shadow: inset -10px 0 10px -10px
        ${(props) =>
          (props.theme as any).palette?.mode === "light"
            ? `rgba(0, 0, 0, 0.2)`
            : `rgba(0, 0, 0, 1)`};
      pointer-events: none; /* To allow interaction with content below */
    }
  }
`;
