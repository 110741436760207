import { Plan } from "@ctc/types";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getBilledCurrencyForCountry } from "~/components/payment/helpers";
import { PaymentInput } from "~/components/payment/StripePayment";
import { hashPlanChangeEvent } from "~/components/payment-status/helpers";
import {
  useBusinessPlanPriceData,
  useRetailPlanPriceData,
} from "~/components/plans/hooks/usePriceDataByPlanType";
import { AddCardDialog } from "~/components/settings-modal/views/billing/AddCardDialog";
import { PaymentMethodItem } from "~/components/settings-modal/views/billing/PaymentMethodItem";
import { BillingBreakdown } from "~/components/settings-modal/views/plan/BillingBreakdown";
import { useHandlePayAndUpgrade } from "~/components/settings-modal/views/plan/hooks/useHandlePayAndUpgrade";
import { useIsFirstClientUpgrade } from "~/components/settings-modal/views/plan/hooks/useIsFirstClientUpgrade";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { ErrorBox } from "~/components/ui/ErrorBox";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { CENTS_PER_DOLLAR } from "~/constants/constants";
import { LocalStorageKey } from "~/constants/enums";
import { useActiveClient } from "~/hooks/useActiveClient";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib/index";
import { useApplyDiscount, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { billingKeys, usePaymentMethodQuery } from "~/state/billing";
import { useClientList } from "~/state/clients";
import { useAllPlans } from "~/state/plans";
import { Links } from "~/types/enums";
import { RenewalStateError } from "~/types/index";

export function Upgrade({ selectedPlanType }: { selectedPlanType: Plan }) {
  const isFirstClientPayment = useIsFirstClientUpgrade();
  // For the time being you can only upgrade retail clients, so we only need to check for retail plan price data
  const retailPlanPriceData = useRetailPlanPriceData();
  const businessPlanPriceData = useBusinessPlanPriceData();

  const priceData =
    retailPlanPriceData.getSelectedPlanData(selectedPlanType) ??
    businessPlanPriceData.getSelectedPlanData(selectedPlanType);

  if (!priceData) return null;

  return (
    <Box p="0.5rem 1rem">
      <SettingsBox title={priceData.planName}>
        <BillingBreakdown
          selectedPlan={selectedPlanType}
          disableDate={isFirstClientPayment}
        />
      </SettingsBox>
      <SettingsBox>
        {isFirstClientPayment ? (
          <CapturePaymentAndUpgrade selectedPlan={selectedPlanType} />
        ) : (
          <UpgradeOnly selectedPlan={selectedPlanType} />
        )}
      </SettingsBox>
    </Box>
  );
}

function UpgradeOnly({ selectedPlan }: { selectedPlan: Plan }) {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  const plans = useAllPlans();
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [error, setError] = useState("");
  const { data: clientList = [] } = useClientList();
  const handlePayAndUpgrade = useHandlePayAndUpgrade();
  const applyDiscount = useApplyDiscount();
  const hasAnyClientRenewalErrors = clientList.some(
    (client) => client.renewalErrorState === RenewalStateError.Unpaid,
  );

  const country = user?.country;

  if (!country || plans?.[selectedPlan] === undefined) return null;

  const currency = getBilledCurrencyForCountry(country);
  const plan = plans[selectedPlan];

  if (plan === undefined) return null;

  const { amount, credits } = plan;
  const fullAmount = amount / CENTS_PER_DOLLAR;
  const discountedAmount = applyDiscount(fullAmount);

  const selectedPlanHasCredits = plans[selectedPlan]?.credits;
  const upgradeLang = lang.accountant.upgradeClient;
  const confirmText = selectedPlanHasCredits
    ? upgradeLang.upgrade
    : upgradeLang.payAmountAndUpgrade({
        amount: displayFiatValue({
          value: discountedAmount,
          localCurrency: currency,
        }),
      });

  return (
    <>
      <OnFileCard />
      {error ? (
        <ErrorBox title={upgradeLang.failMsg} description={error} />
      ) : null}
      {credits ? (
        <Typography variant="Metropolis/Body/Regular">
          {upgradeLang.payment.noneRequired}
        </Typography>
      ) : null}
      <PrimaryButton
        size="small"
        disabled={
          isPaymentProcessing ||
          !(Object.keys(plans) as Plan[]).includes(selectedPlan) ||
          hasAnyClientRenewalErrors
        }
        onClick={() =>
          handlePayAndUpgrade({
            selectedPlan,
            setError,
            setIsPaymentProcessing,
          })
        }
        endIcon={isPaymentProcessing ? <CircularProgress size="1rem" /> : null}
      >
        {confirmText}
      </PrimaryButton>
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        color={tokens.text.low}
      >
        {lang.payment.billing.payment.start}
        <Box component="span" color={tokens.text.high}>
          {displayFiatValue({
            value: discountedAmount,
            localCurrency: currency,
          })}
        </Box>
        {lang.payment.billing.payment.endWithDate({
          date: moment().utc().endOf("month").local().format("Do [of] MMMM"),
        })}
      </Typography>
    </>
  );
}

function CapturePaymentAndUpgrade({ selectedPlan }: { selectedPlan: Plan }) {
  const lang = useLang();
  const user = useUser();
  const plans = useAllPlans();
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [error, setError] = useState("");
  const { data: clientList = [] } = useClientList();
  const navigate = useNavigate();
  const [percentOff, setPercentOff] = useState(0);
  const [coupon, setCoupon] = useState<string>();
  const queryClient = useQueryClient();
  const handlePayAndUpgrade = useHandlePayAndUpgrade();
  const hasAnyClientRenewalErrors = clientList.some(
    (client) => client.renewalErrorState === RenewalStateError.Unpaid,
  );
  const client = useActiveClient();

  const handleSuccess = () => {
    localStorage.setItem(
      LocalStorageKey.PlanUpgradeEvent,
      hashPlanChangeEvent({
        newPlan: selectedPlan,
        oldPlan: client?.paidPlan ?? Plan.Free,
      }),
    );
    navigate(Links.PaymentSuccess);
    queryClient.invalidateQueries({ queryKey: billingKeys.paymentMethod() });
  };

  const country = user?.country;

  if (!country || plans?.[selectedPlan] === undefined) return null;

  const plan = plans[selectedPlan];

  if (plan === undefined) return null;

  const { credits } = plan;

  const selectedPlanHasCredits = plans[selectedPlan]?.credits;
  const upgradeLang = lang.accountant.upgradeClient;
  const confirmText = selectedPlanHasCredits
    ? upgradeLang.upgrade
    : upgradeLang.payAndUpgrade;

  return (
    <>
      {error ? (
        <ErrorBox title={upgradeLang.failMsg} description={error} />
      ) : null}
      {credits ? (
        <>
          <Typography variant="Metropolis/Body/Regular">
            {upgradeLang.payment.noneRequired}
          </Typography>
          <PrimaryButton
            size="small"
            disabled={
              isPaymentProcessing ||
              !(Object.keys(plans) as Plan[]).includes(selectedPlan) ||
              hasAnyClientRenewalErrors
            }
            onClick={() =>
              handlePayAndUpgrade({
                selectedPlan,
                setError,
                setIsPaymentProcessing,
              })
            }
          >
            {confirmText}
          </PrimaryButton>
        </>
      ) : (
        <PaymentInput
          plan={selectedPlan}
          percentOff={percentOff}
          setPercentOff={setPercentOff}
          handleSuccess={handleSuccess}
          handleClose={() => {}}
          coupon={coupon}
          setCoupon={setCoupon}
        />
      )}
    </>
  );
}

function OnFileCard() {
  const lang = useLang();
  const {
    data: _paymentMethod,
    isLoading,
    refetch: refetchPaymentMethod,
  } = usePaymentMethodQuery();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    refetchPaymentMethod();
  }, [refetchPaymentMethod]);

  if (isLoading) return <CircularProgress />;

  const paymentMethod = _paymentMethod ?? null;

  if (!paymentMethod) return null;

  return (
    <>
      <Typography variant="Metropolis/Body/Regular">
        {lang.payment.billing.payment.activeCard}
      </Typography>
      <PaymentMethodItem paymentMethod={paymentMethod} disableStatus />
      <Box>
        <TertiaryButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {lang.payment.billing.payment.changePayment}
        </TertiaryButton>
      </Box>
      <AddCardDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}
