import { type Plan } from "@ctc/types";
import { AccountCircle, Logout } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { type Stripe } from "@stripe/stripe-js";
import {
  bindPopover,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import Popover from "material-ui-popup-state/HoverPopover";
import styled from "styled-components/macro";

import { navButtonAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics, useExperimentVariant } from "~/analytics/posthog";
import { AccountItem } from "~/components/nav/AccountItem";
import { DarkModeToggleListItem } from "~/components/nav/DarkModeSettingsToggle";
import { type ActionItemDetails } from "~/components/nav/NavBar";
import { ToolbarButton } from "~/components/nav/SideNav";
import {
  type ButtonItemDetails,
  isButtonItemDetails,
  isLinkItemDetails,
  type StepItemDetails,
} from "~/components/nav/types";
import { displayMessage } from "~/components/ui/Toaster";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import * as subscriptionAPI from "~/services/subscription";
import { DisplayMessage, FeatureFlag } from "~/types/enums";
import { type Align } from "~/types/enums";

export function assertIsManageSubscriptionItem(
  lang: Translation,
  item: StepItemDetails,
): item is ButtonItemDetails {
  return item.name === lang.nav.manageSubscription && isButtonItemDetails(item);
}

export const handlePayments = async (
  setLoadingPortalSession: (value: boolean) => void,
  stripe: Stripe | null,
  lang: Translation,
  newPlan?: Plan | null,
) => {
  if (!stripe) {
    displayMessage({
      message: lang.billing.stripe.noStripe,
      type: DisplayMessage.Error,
    });
    return;
  }

  setLoadingPortalSession(true);
  const res = await subscriptionAPI.createCustomerPortalSession(newPlan);
  setLoadingPortalSession(false);

  // This should not be shown for the accountant's clients, as they should not be able to access Stripe's portal.
  if (res.error) {
    displayMessage({
      message: lang.billing.stripe.noPortal,
      type: DisplayMessage.Error,
    });
    return;
  }

  const link = res.data.url;
  window.open(link, "_blank");
};

export function AccountMenu({
  accountItems,
  logoutItem,
  align,
}: {
  accountItems: StepItemDetails[];
  logoutItem: ActionItemDetails;
  align?: Align;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  const captureAnalytics = useCaptureAnalytics();
  const isOnboarding = useUser()?.isOnboarding;
  const isInCoinbaseThemeExperiment = useExperimentVariant(
    FeatureFlag.CoinbaseTheme,
    "test",
  );

  const popupState = usePopupState({
    variant: "popover",
    popupId: "account-popup",
    disableAutoFocus: true,
  });
  const handleClick = (item: StepItemDetails) => () => {
    if (assertIsManageSubscriptionItem(lang, item)) {
      return;
    }
    captureAnalytics(navButtonAnalyticsKey, {
      button_name: isLinkItemDetails(item) ? item.id : item.name,
    });
    popupState.close();
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        popupState.close();
      }}
    >
      <div>
        <ToolbarButton {...bindToggle(popupState)}>
          <AccountCircle
            className="navIcon"
            sx={{
              width: "1rem",
              height: "1rem",
              color: isOnboarding ? tokens.text.low : undefined,
            }}
          />
        </ToolbarButton>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          disableScrollLock
        >
          <Box bgcolor={tokens.background.input.default}>
            <List>
              {accountItems.map((item) => {
                const isManageSubscription = assertIsManageSubscriptionItem(
                  lang,
                  item,
                );
                return (
                  <AccountItem
                    key={item.name}
                    align={align}
                    item={item}
                    onClick={handleClick(item)}
                    leftIcon={item.icon}
                    {...(isManageSubscription && {
                      rightIcon: item.endAdornment,
                      disabled: item.disabled,
                    })}
                  />
                );
              })}
              {isInCoinbaseThemeExperiment ? null : <DarkModeToggleListItem />}

              <StyledListItem
                onClick={logoutItem.action}
                sx={{
                  backgroundColor: tokens.background.input.default,
                  "&:hover": {
                    backgroundColor: tokens.background.input.hover,
                  },
                }}
              >
                <Box display="flex" alignItems="center">
                  <ListItemIcon
                    style={{
                      marginLeft: "-0.5rem",
                      minWidth: "1.75rem",
                      fontSize: "inherit",
                      color: tokens.text.default,
                    }}
                  >
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <span style={{ fontSize: "1rem" }}>
                        {logoutItem.name}
                      </span>
                    }
                    sx={{ color: tokens.text.default }}
                  />
                </Box>
              </StyledListItem>
            </List>
          </Box>
        </Popover>
      </div>
    </ClickAwayListener>
  );
}

export const StyledListItem = styled(ListItemButton)`
  background-color: ${({ theme }) => theme.tokens.background.input.default};

  &:hover {
    background-color: ${({ theme }) => theme.tokens.background.input.hover};
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.tokens.text.default};
    font-weight: 600;
  }
`;
