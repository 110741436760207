import { Stack } from "@mui/material";
import { type ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { useOnboardingProgressBarMediaQuery } from "~/components/onboarding-v2/helpers";
import { useIsMobile } from "~/components/ui/hooks";
import { Links } from "~/types/enums";

export function OnboardingPageContainer({ children }: { children: ReactNode }) {
  const isMobile = useIsMobile();
  const fullQuickOnboardingProgressBarEnabled =
    useOnboardingProgressBarMediaQuery();
  const { pathname } = useLocation();

  // These pages have less space at the top of the page
  const pagesWithLessSpaceAtTop = [
    Links.OnboardingImportsNew,
    Links.OnboardingImportsCustom,
    Links.OnboardingReconUncategorisedTransactions,
    Links.OnboardingPayment,
  ].some((link) => pathname.includes(link));

  const pagesWithLessSpaceAtTopOnMobile = [Links.OnboardingImportsSearch].some(
    (link) => pathname.includes(link),
  );

  const getPaddingTop = () => {
    // Mobile has a sticky header, so we can just add 1rem padding
    if (isMobile) return "1rem";
    // On tablet and quick onboarding pages, increase padding
    // to avoid overlapping with the progress bar

    if (isMobile && pagesWithLessSpaceAtTopOnMobile) return "5vh";
    if (fullQuickOnboardingProgressBarEnabled) return "12vh";
    // pages with less space at top, show less padding
    if (pagesWithLessSpaceAtTop) return "7vh";
    return "15vh"; // default padding top
  };

  return (
    <Stack
      gap="2rem"
      direction="column"
      pt={getPaddingTop()}
      pb={isMobile ? undefined : "5vh"}
      alignItems="center"
    >
      {children}
    </Stack>
  );
}
