export default {
  importInstructions: {
    abra: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "La Calculadora de impuestos de criptomonedas ignorará las transferencias que involucren criptomonedas que generen intereses, ya que no son eventos imponibles, por lo que la cantidad de filas importadas puede ser menor que la cantidad total de filas originales en el CSV.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Lea y siga los pasos de exportación detallados para Abra en su sitio web: https://support.abra.com/hc/en-us/articles/360002528832-How-can-I-obtain-my-full-transaction-history- para obtener un archivo CSV por correo electrónico.",
              1: () =>
                "Una vez que reciba el correo electrónico con el archivo CSV, descárguelo y cargue el archivo CSV aquí.",
              2: () =>
                "Si tiene varias billeteras Abra, repita estos pasos para cada una de ellas.",
            },
          },
        },
      },
    },
    abstract: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Open your Abstract wallet",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the address into Crypto Tax Calculator, add an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    agoric: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Agorismo en desarrollo temprano",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Agoric en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    airswap: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () => "Seleccione el intercambio del menú desplegable",
              3: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              4: () => "Mantener las demás configuraciones predeterminadas",
              5: () => 'Haga clic en "Recuperar historial"',
              6: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              7: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    akash: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Akash en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    algorand: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () => "Copia la dirección de tu billetera pública de Algorand",
              2: () =>
                "En CTC, elija la cadena de bloques relevante del menú desplegable",
              3: () =>
                'Pegue la dirección de su billetera pública y haga clic en "Importar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    altcointrader: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de AltCoinTrader",
              1: () =>
                "Descargue sus archivos CSV de depósito, retiro y todas sus operaciones desde AltCoinTrader",
              2: () =>
                "Sube estos archivos aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    aptos: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Abre tu billetera Aptos",
              1: () =>
                "Copia la dirección de tu billetera pública comenzando con '0x...'",
              2: () =>
                'Pegue la dirección en la Calculadora de impuestos criptográficos, agregue un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    arbitrum: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ahora admitimos tokens ERC-1155, pero no podemos importar los saldos actuales de estos tokens. Esto puede generar discrepancias entre el saldo importado desde la API de origen y el saldo calculado por la aplicación.",
            },
            title: () => "Fichas ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de billetera que ha utilizado para operar a través de Arbitrum (comenzando con 0x)",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    "arbitrum-nova": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de billetera que ha utilizado para operar a través de Arbitrum (comenzando con 0x)",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    archway: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Archway en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    atani: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Atani se encuentra actualmente en fase beta y en desarrollo. Si tiene algún problema de integración, comuníquese con nuestro equipo de soporte.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Atani",
              1: () => "Vaya a la pestaña Portafolio",
              2: () =>
                "Busque y haga clic en Descargar informes, luego elija Datos históricos",
              3: () => "Elija su rango de fechas preferido",
              4: () => "Pulse Descargar para obtener su informe",
            },
          },
        },
      },
    },
    atomicwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Debido a las limitaciones del historial de transacciones CSV de Atomic Wallet, no podemos importar automáticamente el lado de compra de ninguna transacción dentro de la aplicación. Si ha realizado alguna transacción dentro de Atomic Wallet, genere el lado de adquisición de la transacción a través de nuestro botón "Agregar transacción manual" o importando un CSV personalizado utilizando las instrucciones "Importar transacciones personalizadas" que aparecen a continuación.',
            },
            title: () => "Operaciones dentro de la aplicación",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "En la pestaña Monedero, selecciona la moneda cuyo historial necesitas.",
              1: () =>
                "Haga clic en el ícono de engranaje de configuración en la esquina superior derecha.",
              2: () => "Haga clic en Exportar transacciones.",
              3: () =>
                "Debería haberse descargado en su carpeta de Escritorio un archivo CSV con su historial de transacciones para esa moneda.",
              4: () =>
                'Importa ese archivo a Crypto Tax Calculator y especifica el símbolo de la moneda. Por ejemplo, para una billetera de Bitcoin, deberás pegar "BTC".',
            },
          },
          1: {
            title: () =>
              "Nota: No utilice la página Historial de la barra de menú para generar un informe CSV. Debe generar un informe independiente para cada moneda con la que haya interactuado.",
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abre tu billetera Atomic.",
              1: () =>
                "Seleccione la criptomoneda que desea importar. Por ejemplo, Bitcoin o Ethereum.",
              2: () =>
                'Copia la dirección de la billetera pública correspondiente a ese activo. Para encontrarla, es posible que tengas que hacer clic en "Recibir".',
              3: () =>
                "En CTC, elija la red blockchain correspondiente en el menú desplegable.",
              4: () =>
                'Pegue la dirección de su billetera pública en CTC y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    aurora: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Actualmente estamos experimentando algunos problemas al recopilar transacciones de enlace y estamos trabajando para encontrar una solución. Si mientras tanto tienes este problema, prueba nuestro proceso de carga manual de CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Transacciones puente",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Aurora en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    avalanche: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Puede haber problemas al importar todas las direcciones dada la dirección proporcionada, porque Avalanche intenta ocultar las relaciones entre las direcciones por razones de seguridad.",
              1: () =>
                "Como resultado, es posible que tengas que importar direcciones adicionales como se describe en esta guía: https://cryptotaxcalculator.io/integrations/avalanche-tax/#please-note.",
            },
          },
          1: {
            items: {
              0: () =>
                "Debido a problemas con nuestro proveedor de API, no podemos recuperar sus transacciones en las cadenas X y P. Actualmente estamos investigando proveedores alternativos para solucionar este problema.",
            },
            title: () => "Faltan transacciones en las cadenas X y P",
          },
          2: {
            items: {
              0: () =>
                "Ahora admitimos tokens ERC-1155, pero no podemos importar los saldos actuales de estos tokens. Esto puede generar discrepancias entre el saldo importado desde la API de origen y el saldo calculado por la aplicación.",
            },
            title: () => "Fichas ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Para importar su dirección C-Chain (que comienza con 0x), simplemente ingrésela en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    bambooRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    base: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera base en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    bch: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
              1: () =>
                "También admitimos direcciones xpub, ypub y zpub; sin embargo, se prefiere xpub.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Bitcoin Cash en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    berachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ahora admitimos tokens ERC-1155, pero no podemos importar los saldos actuales de estos tokens. Esto puede generar discrepancias entre el saldo importado desde la API de origen y el saldo calculado por la aplicación.",
            },
            title: () => "Fichas ERC-1155",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Berachain en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    bigone: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta BigONE",
              1: () => "Vaya a la configuración de su cuenta",
              2: () =>
                "En 'Configuración de Exchange' puede generar una clave API",
              3: () =>
                "Ingrese aquí tanto la 'Clave API' como el 'Secreto API' en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    binance: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente admitimos la sincronización API de registros históricos para las siguientes operaciones: depósitos y retiros de criptomonedas, depósitos y retiros de fiat, operaciones al contado, conversiones y distribuciones de pequeños saldos de BNB 'Dust'",
              1: () =>
                "La API de Binance tiene ciertas limitaciones: las transacciones realizadas con monedas excluidas de la lista no se pueden sincronizar. Si tienes transacciones como esta, te recomendamos que cargues tu historial a través de CSV.",
              2: () =>
                "Debido a las limitaciones de la API de Binance, solo se pueden recuperar los últimos 90 días de transacciones OTC y conversiones de cripto a cripto.",
              3: () =>
                'Desde septiembre de 2020, Binance no proporciona un punto final de API para obtener datos históricos de ciertas compras de monedas de transacciones de "Saldo en efectivo". Además, Binance no proporciona un punto final para "Staking bloqueado" (https://dev.binance.vision/t/earn-locked-staking/1453)',
              4: () =>
                "Binance no proporciona un punto final de API para obtener datos históricos para el historial de compras de Fiat, tokens apalancados de Binance (BLVT), Binance Liquid Swap (Swaps), intereses de Binance Savings, ingresos de futuros de USD-M, ingresos de futuros de COIN-M, operaciones de margen cruzado y operaciones de margen aislado",
              5: () => "Para agregar transacciones no admitidas:",
              6: () => "1. Descargue y abra la plantilla CSV",
              7: () => "2. Complete los detalles de su transacción",
              8: () =>
                "3. Asegúrese de completar todos los campos incluidos para evitar errores al cargar",
              9: () => "4. Exporta tu documento como CSV y cárgalo aquí",
              10: () => "5. Agregue CSV directamente a la billetera asociada",
            },
            title: () => "Sincronización de API: transacciones faltantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "En su cuenta de Binance, vaya a “Gestión de API” desde el menú desplegable “Perfil”. (https://www.binance.com/en/my/settings/api-management)",
              1: () =>
                "Haga clic en “Crear API” y elija “Generado por el sistema” para el tipo de clave API y haga clic en “Siguiente”.",
              2: () =>
                "Establezca una etiqueta de su elección, haga clic en “Siguiente” y complete la autenticación de dos factores.",
              3: () =>
                "Mantenga el acceso IP configurado como 'Sin restricciones' y asegúrese de que la opción 'Habilitar lectura' esté marcada.",
              4: () =>
                'Pegue las claves API y secretas en la Calculadora de impuestos de criptomonedas y haga clic en "Agregar API de Binance".',
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/19oc30s0mVM",
            title: () => "Cómo importar tu API de Binance",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Binance ha limitado recientemente las exportaciones de CSV a un rango máximo de historial de 3 meses a la vez, con solo 6 exportaciones por mes permitidas. Si ha realizado muchas operaciones, le sugerimos que utilice la opción de importación de API",
              1: () =>
                "Actualmente, nuestra opción de importación CSV admite depósitos, retiros, transacciones Fiat, Spot, Coin-Futures, USDT-Futures, margen aislado y margen cruzado.",
              2: () =>
                "Si recibió una carpeta '.zip' o '.tar.gz' de Binance, primero deberá extraer el contenido de la carpeta comprimida. Puede utilizar un servicio como https://extract.me/ para extraer el contenido. Luego, puede cargar los archivos extraídos en Crypto Tax Calculator",
              3: () =>
                "Binance no incluye las comisiones por operaciones P2P en su CSV. Recomendamos importar mediante API si ha realizado operaciones P2P.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Binance y vaya a Billetera > Descripción general.",
              1: () => "Haga clic en Pedidos > Historial de transacciones.",
              2: () =>
                "Haga clic en “Exportar registros de transacciones” en la parte superior derecha.",
              3: () =>
                "Seleccione “Personalizar” para el rango de tiempo y establezca las fechas de inicio/finalización (hasta 12 meses a la vez).",
              4: () =>
                "Asegúrese de que “Todos” esté seleccionado para Cuenta, Moneda y Subcuenta.",
              5: () => "Haga clic en “Generar” y espere el informe.",
              6: () => "Repita esto para todo su historial comercial.",
              7: () =>
                "Si el archivo es '.zip' o '.tar.gz', extráigalo antes de cargarlo.",
              8: () => "Sube los archivos extraídos a Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/N7TNcCW2N7g",
            title: () => "Cómo importar tus archivos CSV de Binance",
          },
        },
      },
    },
    "binance-smart-chain": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ahora admitimos tokens ERC-1155, pero no podemos importar los saldos actuales de estos tokens. Esto puede generar discrepancias entre el saldo importado desde la API de origen y el saldo calculado por la aplicación.",
            },
            title: () => "Fichas ERC-1155",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Binance Smart Chain en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    binancechain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Binance ha cerrado oficialmente la Binance Chain (BNB Beacon Chain). Como resultado, los datos históricos de esta cadena ya no se pueden obtener a través de la API. Las transacciones importadas anteriormente seguirán estando accesibles en su cuenta. Aún puede ver su historial de transacciones en https://explorer.bnbchain.org/ hasta el 3 de diciembre de 2024. Para agregar nuevas transacciones, utilice la opción de importación CSV personalizada. Para obtener más detalles sobre el cierre, visite https://www.bnbchain.org/en/bnb-chain-fusion.",
            },
            title: () => "Cierre de Binance Chain (BNB Beacon Chain)",
          },
        },
        step: {
          0: {
            title: () =>
              "Binance ha cerrado oficialmente la Binance Chain (BNB Beacon Chain). Como resultado, ya no es posible obtener datos históricos de esta cadena a través de la API.",
          },
        },
      },
    },
    binancedex: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Esto sincronizará las transacciones realizadas en Binance DEX, así como cualquier transacción de Binance Chain asociada con esta billetera.",
              1: () =>
                'En algunas ocasiones, no hay datos disponibles para la recepción de tokens BNB y BEP2 a través de un puente entre cadenas en su billetera Binance Chain. En este caso, es posible que deba ingresar manualmente las transacciones de "recepción" respectivas en la Calculadora de impuestos sobre criptomonedas',
              2: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de billetera de Binance Chain (BEP2) que utilizó para operar a través de Binance DEX",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    binanceus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente admitimos la sincronización API de registros históricos para las siguientes operaciones: depósitos y retiros de criptomonedas, depósitos y retiros de fiat, operaciones al contado, conversiones y distribuciones de pequeños saldos de BNB 'Dust'",
              1: () =>
                "La API de Binance tiene ciertas limitaciones: las transacciones realizadas con monedas excluidas de la lista no se pueden sincronizar. Si tienes transacciones como esta, te recomendamos que cargues tu historial a través de CSV.",
              2: () =>
                "Debido a las limitaciones de la API de Binance, solo se pueden recuperar los últimos 90 días de transacciones OTC y conversiones de cripto a cripto.",
              3: () =>
                'Desde septiembre de 2020, Binance no proporciona un punto final de API para obtener datos históricos de ciertas compras de monedas de transacciones de "Saldo en efectivo". Además, Binance no proporciona un punto final para "Staking bloqueado" (https://dev.binance.vision/t/earn-locked-staking/1453)',
              4: () =>
                "Binance no proporciona un punto final de API para obtener datos históricos para el historial de compras de Fiat, tokens apalancados de Binance (BLVT), Binance Liquid Swap (Swaps), intereses de Binance Savings, ingresos de futuros de USD-M, ingresos de futuros de COIN-M, operaciones de margen cruzado y operaciones de margen aislado",
              5: () => "Para agregar transacciones no admitidas:",
              6: () => "1. Descargue y abra la plantilla CSV",
              7: () => "2. Complete los detalles de su transacción",
              8: () =>
                "3. Asegúrese de completar todos los campos incluidos para evitar errores al cargar",
              9: () => "4. Exporta tu documento como CSV y cárgalo aquí",
              10: () => "5. Agregue CSV directamente a la billetera asociada",
            },
            title: () => "Sincronización de API: transacciones faltantes",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de BinanceUS",
              1: () =>
                'En la parte superior derecha de la barra de navegación, pase el cursor sobre el ícono "Perfil" y haga clic en "Administración de API" en el menú desplegable (https://www.binance.com/en/my/settings/api-management)',
              2: () =>
                "Ingrese la etiqueta para una nueva API como 'Calculadora de impuestos criptográficos' y haga clic en 'Crear API' (botón amarillo)",
              3: () =>
                "Es posible que tengas que ingresar alguna información de verificación",
              4: () =>
                "Una vez creado, copie la 'Clave API' y la 'Clave secreta'",
              5: () =>
                'Haga clic en Editar restricciones y desmarque la opción "Habilitar operaciones al contado". Además, asegúrese de que la opción "Habilitar retiros" no esté marcada.',
              6: () =>
                '¡Vuelva a verificar que "Puede leer" sea la única opción marcada!',
              7: () =>
                "Deje la selección de restricción de acceso IP como 'Sin restricciones'",
              8: () =>
                "Pegue la 'Clave API' y la 'Clave secreta' copiadas aquí en Crypto Tax Calculator y haga clic en 'Agregar API'",
              9: () =>
                "La sincronización de esta API puede demorar aproximadamente 20 minutos debido a restricciones del lado de BinanceUS",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Actualmente, nuestra opción de importación de CSV solo admite depósitos, retiros y transacciones con billetera fiduciaria o al contado. Si tiene otros tipos de registros históricos, impórtelos a través de nuestra opción "Configurar sincronización automática".',
              1: () =>
                "Si recibió una carpeta '.zip' o '.tar.gz' de BinanceUS, primero deberá extraer el contenido de la carpeta comprimida. Puede utilizar un servicio como https://extract.me/ para extraer el contenido. Luego, puede cargar los archivos extraídos en Crypto Tax Calculator.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de BinanceUS y haga clic en “Billetera” en la barra de navegación.",
              1: () =>
                "Seleccione la opción ‘Exportar informes’ en escritura amarilla en el lado derecho.",
              2: () =>
                "En Informes personalizados, seleccione Todos para el tipo de Activo y Transacciones; para el Rango, seleccione el período deseado (hasta un año como máximo a la vez, consulte el paso 5) y seleccione CSV para el Formato de archivo.",
              3: () =>
                "Haga clic en “Generar” y espere a que se genere el informe; esto puede llevar algún tiempo.",
              4: () =>
                "Repita la exportación para cada año que haya estado operando en BinanceUS.",
              5: () =>
                "Si el archivo tiene formato '.zip' o '.tar.gz', primero deberá extraer el contenido de la carpeta comprimida antes de cargarlo; consulte las notas.",
              6: () =>
                "Sube los archivos a la Calculadora de impuestos criptográficos.",
            },
            title: () =>
              "Para depósitos, retiros y operaciones en cuentas al contado:",
          },
        },
      },
    },
    bingx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de BingX y navegue a Activos > Registros de activos",
              1: () =>
                'Haga clic en "Exportar" en la página del historial de transacciones o utilice este enlace: https://bingx.com/en/transaction-history/?timeKey=ninetyDaysFlag',
              2: () =>
                "Seleccione el rango de tiempo y elija todas las cuentas: Fondo, Futuros perpetuos USD-M, Futuros perpetuos Coin-M y Futuros estándar.",
              3: () =>
                "Establezca el Tipo de archivo en Excel y el Formato de datos en Estándar, luego haga clic en “Generar”.",
              4: () =>
                "Espere a que se genere el archivo Excel y descárguelo una vez que esté listo.",
              5: () =>
                "Abra el archivo de Excel, que contendrá cuatro hojas independientes. Guarde cada hoja como un archivo .csv individual e impórtelas en CTC una por una.",
            },
          },
        },
      },
    },
    bitaroo: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Bitaroo",
              1: () =>
                "Descargue sus archivos CSV de operaciones, depósitos y retiros de Bitaroo",
              2: () =>
                "Sube estos archivos aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    bitbuy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "El informe detallado de Bitbuy sobre la actividad comercial a veces no incluye depósitos o retiros hacia y desde Bitbuy. Si tiene depósitos y retiros, comuníquese con nuestro servicio de asistencia para obtener ayuda para importar estas transacciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Bitbuy aquí (https://app.bitbuy.ca/sign-in).",
              1: () =>
                'Haga clic en el menú desplegable junto a su nombre (arriba a la derecha) y seleccione "Documentos".',
              2: () =>
                "Para obtener su historial de transacciones antes del 25 de mayo de 2024, vaya a la pestaña 'Documentos heredados' y descargue sus archivos.",
              3: () =>
                "Para toda la actividad posterior al 25 de mayo de 2024, vaya a la pestaña 'Declaraciones' y descargue los archivos CSV.",
              4: () =>
                "Sube todos los archivos CSV descargados aquí en Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    bitcoindotcomau: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Comuníquese con el equipo de soporte de bitcoin.com.au para generar o modificar su clave API",
              1: () =>
                "Copie y pegue las claves en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    bitfinex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente no admitimos datos de operaciones de derivados de Bitfinex: si ha realizado operaciones de derivados a través de Bitfinex, comuníquese con el soporte.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en Bitfinex.",
              1: () =>
                'En la esquina superior derecha, pase el cursor sobre el ícono de la persona y seleccione "Claves API" en el menú desplegable. Esto debería llevarlo a "https://www.bitfinex.com/api".',
              2: () => "Haga clic en la pestaña “Crear nueva clave”.",
              3: () =>
                'Asegúrese de que los permisos predeterminados no hayan cambiado y que todos los permisos de "Lectura" estén habilitados.',
              4: () =>
                'En la parte inferior de la página, ingrese un nombre para la clave API (por ejemplo, Calculadora de impuestos criptográficos) en el campo "Etiquete su clave API" y luego haga clic en el botón "Generar clave API".',
              5: () =>
                "Es posible que tengas que ingresar tu token 2FA si tienes 2FA habilitado.",
              6: () =>
                "Una vez que reciba un correo electrónico de verificación de Bitfinex, confirme la nueva clave API.",
              7: () =>
                "Copie la 'clave API' y la 'clave API secreta' e ingréselas en la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente no admitimos datos de operaciones de derivados de Bitfinex: si ha realizado operaciones de derivados a través de Bitfinex, comuníquese con el soporte.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en Bitfinex.",
              1: () =>
                'En la esquina superior derecha, pase el cursor sobre "Billetera" y seleccione "Informes" en el menú desplegable. Esto debería llevarlo a "https://report.bitfinex.com".',
              2: () =>
                "Haga clic en el icono del calendario en la parte superior de la página.",
              3: () =>
                "Seleccione un rango personalizado de fechas que incluya todo su historial comercial.",
              4: () =>
                'Haga clic en el botón "Exportar"; debería aparecer una ventana emergente.',
              5: () =>
                'En el campo "Datos para exportar", seleccione únicamente "Libros mayores" y "Movimientos". Seleccione "Formato de fecha" como "DD-MM-AA". Marque la casilla que dice "Mostrar milisegundos".',
              6: () =>
                'Haga clic en el botón "Exportar" en la parte inferior de la ventana emergente. Esto generará archivos CSV que se enviarán a su CORREO ELECTRÓNICO.',
              7: () =>
                'En su cliente de correo electrónico, abra el correo electrónico y haga clic en "Descargar CSV". Esto descargará un archivo .zip que contiene dos CSV (uno para los libros contables y otro para los movimientos).',
              8: () =>
                "Extraiga y cargue AMBOS CSV a la Calculadora de impuestos de criptomonedas.",
            },
          },
        },
      },
    },
    bitflyer: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Bitflyer",
              1: () => "Navegar a API",
              2: () => "Haga clic en el botón “Generar nueva clave”",
              3: () =>
                "Copie la 'Clave API' y el 'Secreto API' e ingréselos aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de BitFlyer",
              1: () => "Descargue su historial de transacciones",
              2: () =>
                "Sube el archivo CSV a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    bitforex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Si los archivos CSV no se pueden cargar, intente cambiar la extensión de '.csv' a '.xlsx' e intente nuevamente.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de BitForex",
              1: () =>
                "Comuníquese con el servicio de asistencia y solicíteles sus archivos CSV de operaciones",
              2: () =>
                "Sube estos archivos aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    bitget: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "Para recuperar datos más antiguos, importe sus operaciones mediante CSV.",
            },
            title: () =>
              "La API de Bitget solo proporciona los últimos 90 días del historial comercial",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Bitget",
              1: () =>
                "Navegue hasta la gestión de API desde la barra de navegación en la parte superior",
              2: () => "En el menú desplegable, seleccione Gestión de API",
              3: () => "Haga clic en Crear una nueva API",
              4: () => "Haga clic en Clave API generada por el sistema",
              5: () =>
                "En la ventana emergente, crea una frase de contraseña para tu API",
              6: () =>
                "En Permisos, seleccione Solo lectura en todas las casillas de verificación.",
              7: () =>
                'Complete la verificación de dos factores y haga clic en "Siguiente".',
              8: () => "Copia tu clave API y tu secreto",
            },
          },
        },
      },
    },
    bitgo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Asegúrese de que su informe abarque todos los años en los que ha estado negociando criptomonedas.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta BitGo en un navegador web",
              1: () =>
                "Vaya a la sección 'Informes' desde la barra de navegación en la parte superior",
              2: () =>
                "Seleccione 'Todas las monedas/tokens' en el menú desplegable",
              3: () => "Descargue su informe como archivo CSV",
              4: () =>
                "Sube el archivo que acabas de descargar aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    bitkeep: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente, solo se admiten las direcciones de billetera Solana almacenadas en su billetera BitKeep",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de la billetera blockchain de Solana que ha utilizado para operar a través de BitKeep",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    bitmart: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Bitmart.",
              1: () =>
                "Coloque el cursor sobre el logotipo de Cuenta en la esquina superior derecha.",
              2: () => "Haga clic en Cuenta.",
              3: () => "Haga clic en Gestión de API.",
              4: () => "Ingrese su nota preferida.",
              5: () => "Haga clic en enviar.",
              6: () => "Copiar la clave API, el secreto y el Memo.",
              7: () => "Pegar en los campos correspondientes en CTC",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "BitMart no tiene una API con el historial completo de transacciones ni un archivo CSV disponible en su interfaz de usuario. Deberá enviarles un correo electrónico para solicitar la exportación en formato CSV de su historial de transacciones. Esto puede demorar 5 días.",
              1: () =>
                "Una vez que haya recibido la exportación del historial comercial, puede importarla a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitmax: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente, la API de AscendEX solo proporciona los últimos 30 días de historial comercial",
              1: () => "Necesitará crear nuevas claves API después de 90 días",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de AscendEX",
              1: () =>
                "Haga clic en el icono del avatar en la esquina superior derecha.",
              2: () =>
                "Haga clic en “Configuración de API” y haga clic en “Nueva clave de API”.",
              3: () =>
                "Marque el permiso 'Ver' de la clave e ingrese su código de verificación de correo electrónico y el código 2FA",
              4: () => "Haga clic en 'Generar clave API'",
              5: () => "Copia tu clave API y tu secreto",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente, AscendEX no cuenta con una exportación CSV para sus depósitos y retiros. Deberá agregarla manualmente.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de AscendEX",
              1: () =>
                "Seleccione 'Mi pedido' (https://bitmax.io/en/order/cash-order)",
              2: () => 'Haga clic en "Solicitar exportación"',
              3: () =>
                "Luego puede hacer clic en el botón de la derecha 'Mis archivos exportados' y descargar el archivo una vez completado.",
              4: () =>
                "Sube el archivo CSV descargado directamente aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    bitmex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "¡Asegúrate de no haber habilitado ningún permiso de pedido al crear la clave API!",
              1: () =>
                "Si la API no importa tus datos, es posible que se deba a que no has completado la nueva política de verificación KYC de BitMEX. Si esto te sucede, completa la verificación KYC antes de volver a intentarlo.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta BitMEX",
              1: () =>
                "Haga clic en su nombre de usuario en la esquina superior derecha",
              2: () =>
                'Vaya a la página de la cuenta haciendo clic en "Cuenta y seguridad"',
              3: () =>
                "En el lado izquierdo de la página, seleccione 'Claves API' (https://www.bitmex.com/app/apiKeys)",
              4: () =>
                "Al crear la clave API, asegúrese de que los permisos de la clave estén en blanco, como se indica con '-'",
              5: () =>
                "Comprueba nuevamente que no hayas habilitado ningún permiso.",
              6: () =>
                "Ingrese su 2FA si es necesario y haga clic en 'Crear clave API'",
              7: () =>
                "Copie el ID y el secreto e ingréselos como 'Clave API' y 'Secreto API' aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () => "Necesitará guardar el CSV para cada página de datos",
              1: () =>
                "Si el archivo CSV no se importa, es posible que se deba a que sus datos tienen un formato de marca de tiempo diferente al que esperábamos. Comuníquese con nuestro servicio de asistencia si esto sucede.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta BitMEX",
              1: () =>
                "Haga clic en su nombre de usuario en la esquina superior derecha",
              2: () => "Navegar a cuenta y seguridad",
              3: () =>
                "En el lado izquierdo de la página, seleccione 'Billetera - > Saldos' (https://www.bitmex.com/app/wallet)",
              4: () =>
                "En la esquina superior derecha haga clic en 'Guardar como CSV'",
              5: () =>
                'Asegúrate de que el nombre del archivo CSV que acabas de descargar comience con "Historial de billetera". Hay otros tipos de archivos CSV descargables que no admitimos.',
              6: () =>
                "Sube el archivo CSV aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    bitpanda: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Bitpanda y seleccione el ícono del panda en la esquina superior derecha.",
              1: () =>
                "Seleccione la clave API e ingrese un nombre para su clave API.",
              2: () =>
                "Bajo el alcance, verificar transacciones, saldos y operaciones.",
              3: () => "Seleccione generar nueva clave API.",
              4: () =>
                "Haga clic en Confirmar, lo que generará un correo electrónico de confirmación.",
              5: () =>
                "Copie la clave API e ingrésela como 'Clave API' y 'Secreto API' aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Bitpanda y seleccione el ícono del panda en la esquina superior derecha.",
              1: () =>
                "Seleccione historial y luego seleccione historial de transacciones en la parte superior derecha.",
              2: () => "Exporte el CSV e impórtelo a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitrue: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente, nuestra opción de importación CSV solo admite depósitos, retiros e historial comercial.",
              1: () =>
                "Si tiene algún registro que no admitimos y desea solicitar que se agregue, comuníquese con nosotros a través de nuestro soporte de chat.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Bitrue",
              1: () =>
                "Coloque el cursor sobre Comercio en la esquina superior izquierda y seleccione Spot. Luego, haga clic en Historial de órdenes. También puede probar este enlace directo (https://www.bitrue.com/order/spot/history-orders)",
              2: () => "Haga clic en exportar en la parte superior derecha.",
              3: () =>
                "En la ventana emergente modal, seleccione “Historial comercial” como opción de datos.",
              4: () =>
                "Seleccione las fechas en las que estaba operando y haga clic en el botón “Aceptar”",
              5: () =>
                "Bitrue solo permite 3 meses a la vez, por lo que es posible que deba descargar varias veces para cubrir todo su historial comercial.",
            },
          },
        },
      },
    },
    bitso: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "En la barra de navegación superior derecha, haga clic en la opción desplegable del perfil de usuario y haga clic en Historial (https://bitso.com/r/history/alltransactions)",
              1: () =>
                "En la parte inferior de la tabla de transacciones, debería ver 'Descargar sus transacciones en un archivo CSV' seguido de cuatro opciones: depósitos, retiros, conversiones y transacciones.",
              2: () =>
                "Haga clic en cada opción, luego tome los cuatro archivos CSV que acaba de descargar e impórtelos a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitsong: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera BitSong en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    bitstamp: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Repita los pasos anteriores para agregar claves API para todas las cuentas asociadas.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Mi perfil > Configuración > Configuración de la cuenta > Acceso a la API",
              1: () => 'Haga clic en "Crear nueva clave API"',
              2: () => "Haga clic en el botón 'Configuración avanzada'",
              3: () => "Seleccione una cuenta adecuada en 'Cuenta asociada'",
              4: () =>
                "Asegúrese de que solo estén activados 'Permisos generales > Ver saldo de cuenta', 'Permisos generales > Ver sus transacciones' y 'Gana > Ver y administrar la funcionalidad de Ganancias' y haga clic en 'Crear nueva clave API'.",
              5: () =>
                "Ingresa tu código Bitstamp 2FA y haz clic en 'Confirmar'",
              6: () =>
                'Copia la clave API y el secreto en Crypto Tax Calculator. NO hagas clic en "Agregar API de Bitstamp" todavía. Ten en cuenta que el secreto solo está disponible durante unos minutos y no se volverá a mostrar. Si pierdes tu secreto, deberás crear una nueva clave API.',
              7: () => "En Bitstamp, haga clic en 'Activar clave API'",
              8: () =>
                'Bitstamp te enviará un correo electrónico de confirmación. Haz clic en el botón "Activar clave API" que aparece en ese correo electrónico.',
              9: () => 'Haga clic en "Agregar API de Bitstamp"',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Las transacciones de subcuentas ya están incluidas en el CSV descargado.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Bitstamp",
              1: () =>
                "Vaya a la página de Transacciones haciendo clic en el ícono de usuario en la esquina superior derecha, luego en 'Actividad' y luego en 'Historial de transacciones' en la barra lateral izquierda (https://www.bitstamp.net/account/transactions)",
              2: () => "Haga clic en el botón 'Abrir opciones de exportación'",
              3: () =>
                "Seleccione el 'Rango de fechas' que cubra todo su historial de transacciones",
              4: () => "Seleccione 'Todas' para las monedas",
              5: () => "Seleccione 'Todos' para los tipos de transacción",
              6: () => "Seleccione 'RFC 4180 (nuevo)' para el formato CSV",
              7: () =>
                "Haga clic en el botón 'Exportar selección' para descargar el archivo CSV",
              8: () =>
                "Sube este CSV aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                'Haga clic en "Conectarse a Bitstamp" para ir al intercambio de Bitstamp',
              1: () => "Inicie sesión en su cuenta de Bitstamp.",
              2: () =>
                "Si su cuenta tiene habilitada la autenticación de dos factores, ingrese el código requerido. De lo contrario, omita este paso.",
              3: () => "Seleccione la subcuenta apropiada",
              4: () => 'Haga clic en el botón "Aceptar"',
            },
          },
        },
      },
    },
    bittorrentchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Actualmente estamos experimentando algunos problemas al recopilar tokens ERC-1155 y conectar transacciones, y estamos trabajando para encontrar una solución. Si mientras tanto tienes este problema, prueba nuestro proceso de carga manual de CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Tokens ERC-1155 y transacciones puente",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera de BitTorrent Chain en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    bittrex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Bittrex",
              1: () => "Navegar hasta Claves API",
              2: () => "Haga clic en el botón 'Agregar nueva clave'",
              3: () =>
                "Asegúrese de que el permiso 'LEER INFORMACIÓN' para la nueva clave esté habilitado",
              4: () => "Una vez hecho esto, haga clic en el botón 'Guardar'",
              5: () => "Copiar la clave API y el secreto API",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Para descargar su historial de depósitos y retiros en formato CSV, debe enviar un ticket de soporte a Bittrex. Una vez que reciba estos archivos CSV, puede cargarlos en la Calculadora de impuestos sobre criptomonedas. Vea más detalles aquí: https://bittrex.zendesk.com/hc/en-us/articles/360001359006-Where-s-my-Order-History-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Bittrex, navegue hasta “Pedidos” en la esquina superior y desplácese hacia abajo hasta Historial de pedidos.",
              1: () =>
                "Haga clic en el botón “Descargar historial”, seleccione el año en el que realizó operaciones y haga clic en “Descargar”.",
              2: () =>
                "Solo puedes descargar un año a la vez, pero debes agregar cada año en que realizaste operaciones a la Calculadora de impuestos de criptomonedas.",
              3: () =>
                "Asegúrese de no abrir ni volver a guardar el archivo antes de importarlo porque podría afectar el formato del CSV.",
            },
            title: () => "Para el historial de pedidos:",
          },
        },
      },
    },
    bitvavo: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente importamos depósitos, retiros y transacciones.",
              1: () =>
                "No podemos importar ingresos de staking ya que la API de Bitvavo actualmente no lo admite",
              2: () =>
                "Si utiliza Staking, es posible que los saldos de las monedas en stake que informamos no coincidan con los informados por Bitvavo a menos que ingrese manualmente las transacciones de ingresos por Staking.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en el exchange Bitvavo",
              1: () =>
                "Vaya a la página de configuración de API (https://account.bitvavo.com/user/api)",
              2: () => 'Haga clic en el botón "Solicitar nueva clave API"',
              3: () =>
                "Generar claves API de solo lectura que no permitan realizar operaciones ni retiros",
              4: () =>
                "Inserte la clave API y el secreto aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su exchange de Bitvavo",
              1: () =>
                "En la esquina superior derecha, haz clic en tu nombre de perfil.",
              2: () => "Haga clic en 'Historial de transacciones'",
              3: () =>
                "Haga clic en el botón de descarga en la esquina superior derecha.",
              4: () =>
                "Sube el CSV aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    bityard: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicia sesión en tu cuenta de Bityard",
              1: () => "Para el historial comercial:",
              2: () =>
                "Vaya a la sección 'Órdenes' y seleccione 'Spot' - 'Historial de transacciones' en el menú (https://www.bityard.com/en-US/account/fm/spotHistory/2)",
              3: () =>
                "Haga clic en el botón 'Exportar' y seleccione el período de tiempo deseado para los datos exportados",
              4: () => "Para el historial de depósitos y retiros:",
              5: () =>
                "Vaya a la sección 'Activos' y seleccione 'Mis activos' - 'Historial de fondos' - 'Historial de depósitos' o 'Historial de retiros' en el menú (https://www.bityard.com/en-US/account/fm/fundHistory/1)",
              6: () =>
                "Haga clic en el botón 'Exportar' y seleccione el período de tiempo deseado para los datos exportados",
              7: () =>
                "Sube ambos CSV aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    blast: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Blast (que comienza con 0x) en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    blockchaindotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Solo puedes importar tu historial de depósitos y retiros a través de la importación CSV. Además, el CSV no proporciona información sobre las tarifas pagadas por estos depósitos/retiros.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su billetera (https://login.blockchain.com/#/login) y seleccione una moneda a la izquierda (por ejemplo, Bitcoin)",
              1: () =>
                'Si tiene algún saldo para la criptomoneda seleccionada, verá un botón "Descargar": haga clic en este botón',
              2: () =>
                "Se le solicitará que seleccione un rango de fechas para las que desea descargar sus transacciones. Asegúrese de seleccionar el período que cubra todo su historial de transacciones para la criptomoneda seleccionada",
              3: () =>
                'Haga clic en "Generar informe". Este botón cambiará a "Descargar informe" una vez que el informe esté listo para descargarse. Haga clic en este botón para descargar su informe.',
              4: () =>
                "Sube el archivo que acabas de descargar aquí a la Calculadora de impuestos sobre criptomonedas",
              5: () =>
                "Asegúrate de repetir este proceso para todas las criptomonedas que estés almacenando en nuestra Billetera",
            },
          },
        },
      },
    },
    blockearner: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Block Earner y vaya a la página de Transacciones (https://app.blockearner.com.au/transactions)",
              1: () =>
                "Descargue los archivos necesarios en función de su historial comercial.",
              2: () =>
                "El archivo de transacciones se puede encontrar en la parte superior derecha de la pantalla dentro de cada cuenta respectiva: Préstamos, DeFi, Compra/Venta de criptomonedas y Efectivo.",
              3: () =>
                "Sube todos los archivos descargados aquí en la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
    },
    blockfi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Ignoramos el tipo de transacción "Operación" en el archivo CSV "Historial de transacciones". En su lugar, utilizamos la información más precisa del archivo CSV "Operación" para obtener sus datos de operaciones.',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de BlockFi y haga clic en el ícono de su perfil en la parte superior derecha",
              1: () => "Seleccione 'Informes' del menú desplegable",
              2: () =>
                "En la parte inferior de la página, verá 'Trading' y 'Historial de transacciones'.",
              3: () =>
                "Para 'Trading', seleccione el rango de fechas que desea exportar (o descargar todo) y haga clic en el botón 'Descargar .csv'; esto descargará todos sus datos comerciales detallados.",
              4: () =>
                "Para 'Historial de transacciones', seleccione el rango de fechas que desea exportar (o descargar todo) y haga clic en el botón 'Descargar .csv'; esto descargará todos los datos detallados de sus transacciones, como depósitos, retiros, pagos de intereses, etc.",
              5: () =>
                "Sube ambos archivos CSV aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    boba: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Actualmente estamos experimentando algunos problemas al recopilar tokens ERC-1155 y estamos trabajando para encontrar una solución. Si mientras tanto tienes este problema, prueba nuestro proceso de carga manual de CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Fichas ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Boba en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    brave_software: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abra Brave Wallet en el navegador",
              1: () =>
                "Desde la pestaña Cuentas, haz clic para copiar la dirección de tu billetera pública que comienza con '0x...'",
              2: () =>
                'Pegue la dirección de su billetera pública y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    bsv: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
              1: () =>
                "También admitimos direcciones xpub, ypub y zpub; sin embargo, se prefiere xpub.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Bitcoin SV en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    btc: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "También admitimos direcciones xpub, ypub y zpub. Le recomendamos que utilice xPub. Lea esta guía (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator) para obtener más detalles.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () =>
                "Copia la dirección de tu billetera pública comenzando con 'xpub...'",
              2: () =>
                'Pegue la dirección de su billetera pública en CTC y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    btcAltNetwork: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Ingrese la dirección de su billetera " +
                d.exchangeDisplayName +
                ' en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
            },
          },
        },
      },
    },
    btcalpha: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "En la sección de cuentas de su cuenta, navegue hasta la sección API",
              1: () => "Crear una nueva clave API",
              2: () =>
                "Copie y pegue las claves en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    btccomau: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en BTC.com.au",
              1: () =>
                "Vaya a Configuración > Informes comerciales según el menú a la izquierda de su pantalla.",
              2: () =>
                "Haga clic en el botón 'Informe único' a la derecha de la línea justo debajo del encabezado 'Informes de actividad'",
              3: () =>
                "Seleccione “Transacción” en la lista desplegable “Tipo de informe”",
              4: () => "Complete las fechas de inicio y finalización",
              5: () => "Haga clic en el botón 'Crear'",
              6: () =>
                "Haga clic en el botón 'Crear informe' en la ventana emergente de confirmación.",
              7: () =>
                "Después del mensaje que indica que la creación del informe se realizó correctamente, es posible que aún deba actualizar la página para ver el nuevo informe.",
              8: () =>
                'Haga clic en "Descargar informe" en la última fila debajo de Informes disponibles para descargar',
              9: () =>
                "Sube el CSV descargado aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    btcmarkets: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Para obtener más información, consulte nuestros pasos detallados: https://cryptotaxcalculator.io/integrations/btcmarkets-tax/",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en BTC Markets y navegue a la página 'Clave API' (https://app.btcmarkets.net/account/apikey)",
              1: () =>
                'Haga clic en "Agregar nueva clave API", asegúrese de que los permisos estén configurados en "Solo lectura" y luego haga clic en "Enviar".',
              2: () =>
                "Copie el secreto y péguelo en 'Clave secreta' en la Calculadora de impuestos criptográficos",
              3: () =>
                'Haga clic en "Enviar", luego haga clic en el botón copiar en la clave API y péguela en la Calculadora de impuestos criptográficos.',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en BTC Markets y navegue hasta Comprar/Vender > Historial de transacciones",
              1: () =>
                'Haga clic en "Exportar a CSV" (debería descargarse como "AccountTradingReport_XXX.csv")',
              2: () =>
                "Sube el archivo CSV aquí Calculadora de impuestos sobre criptomonedas",
            },
            title: () => "Para datos comerciales:",
          },
          1: {
            items: {
              0: () =>
                "Inicie sesión en BTC Markets y navegue a Cuenta > Historial de transacciones",
              1: () =>
                "Asegúrese de que la moneda sea 'ALL' y haga clic en 'Exportar a CSV' (esto debería descargarse como 'TransactionReport_XXX.csv').",
              2: () =>
                "Sube el archivo CSV aquí a la Calculadora de impuestos criptográficos",
            },
            title: () => "Para depósitos y retiros:",
          },
        },
      },
    },
    bybit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Es posible que existan pequeñas discrepancias entre los saldos de Bybit y los saldos informados en la Calculadora de impuestos sobre criptomonedas. Este es un problema de Bybit y se debe a pequeñas diferencias de redondeo entre los registros de PNL de Bybit y los registros de su billetera. Si hay alguna discrepancia significativa, comuníquese con nuestro servicio de asistencia a través de nuestro chat web.",
              1: () =>
                "La API de Bybit solo importa transacciones de conversión de los últimos 6 meses. Si tiene transacciones anteriores, impórtelas mediante un archivo CSV",
              2: () =>
                "Debido a limitaciones por parte de Bybit, la clave API caducará después de tres meses desde el momento de su creación.",
              3: () =>
                "La API de sus cuentas principales incluirá datos de subcuentas, por lo que no es necesario crear una segunda ya que esto podría generar duplicados dentro de su cuenta.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inicie sesión en Bybit, pase el cursor sobre su nombre en la esquina superior derecha y seleccione "API" (https://www.bybit.com/app/user/api-management)',
              1: () =>
                'Haz clic en el botón "Crear nueva clave". Si aún no has configurado la autenticación de dos factores, se te solicitará que lo hagas.',
              2: () =>
                "Seleccione 'claves API generadas por el sistema' como el tipo de clave API",
              3: () =>
                "Seleccione “Transacción API” como uso de la clave API e ingrese el nombre que elija.",
              4: () =>
                "Cambie el interruptor superior a “Sólo lectura” y deje el campo de dirección IP en blanco.",
              5: () =>
                "Haga clic en las casillas de verificación de 'Negociación unificada' y 'Activos'",
              6: () =>
                'Haga clic en "Enviar" y luego copie y pegue la clave API y el secreto aquí en CTC',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "RECOMENDAMOS ENFÁTICAMENTE importar a través de la opción 'Sincronizar mediante API' y conectarse directamente a la API de Bybit, ya que aquí tiene datos más precisos.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Le solicitamos que cargue sus archivos CSV 'Historial de pérdidas y ganancias cerrado de derivados' e 'Historial de activos'.",
              1: () =>
                "Puede generar el archivo 'Historial de pérdidas y ganancias cerrado de derivados' a través de este enlace (https://www.bybit.com/user/assets/order/closed-pnl/inverse).",
              2: () =>
                "Vaya a Órdenes > Órdenes de derivados > Cerrar pérdidas y ganancias > Haga clic en el botón “Exportar” en la esquina superior derecha.",
              3: () =>
                "NOTA: Los comerciantes pueden exportar hasta dos años de datos, mientras que el rango máximo de datos exportable en un solo lote es de tres meses.",
              4: () =>
                "Puede generar su archivo 'Historial de activos' a través de este enlace (https://www.bybit.com/user/assets/exportStatment).",
              5: () =>
                "NOTA: al exportar su 'Estado de cuenta' dentro de su Historial de activos, asegúrese de que todos los tipos de cuenta estén seleccionados (Spot, Financiamiento, Trading unificado, Derivados del USDC).",
              6: () =>
                "Es importante destacar que no requerimos el historial comercial CSV.",
              7: () =>
                "El informe de pérdidas y ganancias cerrado muestra las ganancias/pérdidas obtenidas mediante la negociación de futuros, etc.",
              8: () =>
                "El informe de Historial de activos le permitirá importar los depósitos/retiros",
            },
          },
        },
      },
    },
    calebandbrown: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en el portal en app.calebandbrown.com",
              1: () =>
                "Vaya a la página Transacciones (menú del lado izquierdo)",
              2: () =>
                "En la parte superior derecha de la tabla de Transacciones, se encuentra el botón Resumen de exportación",
              3: () =>
                "Seleccione Resumen de transacción, seleccione 'Todo el tiempo' como Período de tiempo y haga clic en exportar",
            },
          },
        },
      },
    },
    canto: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Actualmente estamos experimentando algunos problemas al recopilar tokens ERC-1155 y conectar transacciones, y estamos trabajando para encontrar una solución. Si mientras tanto tienes este problema, prueba nuestro proceso de carga manual de CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Tokens ERC-1155 y transacciones puente",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Canto en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    cardano: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ingrese solo UNA dirección de cada billetera. Realizamos una búsqueda y extraemos los datos de todas las demás direcciones de la billetera automáticamente.",
              1: () =>
                "Importar varias direcciones de la misma billetera dará como resultado transacciones duplicadas.",
              2: () =>
                "Actualmente no ofrecemos soporte para obtener el historial completo de transacciones de billeteras con más de 1000 direcciones individuales o cuando la cantidad de transacciones es superior a 2000 en algunos casos. Estamos trabajando para brindar soporte adicional para dichas billeteras.",
            },
            title: () => "Varias direcciones de billetera",
          },
          1: {
            items: {
              0: () =>
                "Si importa una dirección que comienza con 'addr1', la cambiaremos automáticamente a la dirección del propietario de la cuenta que comienza con 'stake1'.",
              1: () =>
                "Esto nos permite importar todas las demás direcciones que pueda crear en la misma billetera y capturar sus recompensas de staking.",
            },
            title: () => "¿Por qué mi dirección es stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () =>
                "Copia la dirección de tu billetera pública comenzando con 'addr1...' o 'stake1...'",
              2: () =>
                'Pegue la dirección de su billetera pública en CTC y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    cashapp: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Cash App y navegue hasta la página de Actividad (https://cash.app/account/activity)",
              1: () => "Haga clic en 'Declaraciones'",
              2: () => 'Haga clic en "Exportar CSV"',
              3: () =>
                "Ahora puedes cargar este CSV en la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    celestia: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Celestia en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    celo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Actualmente estamos experimentando algunos problemas al recopilar tokens ERC-1155 y estamos trabajando para encontrar una solución. Si mientras tanto tienes este problema, prueba nuestro proceso de carga manual de CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Fichas ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Celo en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    celsius: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta Celsius",
              1: () =>
                "Haga clic en el menú desplegable en la parte superior derecha de la plataforma.",
              2: () =>
                "Seleccione “API” y luego haga clic en “Crear nueva clave”",
              3: () =>
                "Copie la 'Clave API' generada aquí en Crypto Tax Calculator, ingrese un apodo opcional y luego haga clic en 'Agregar API' para sincronizar su API",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Para conocer los pasos más detallados con capturas de pantalla, consulte la página de soporte en https://support.celsius.network/hc/en-us/articles/360004491118-How-do-I-export-my-transaction-history-as-CSV-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Abra la aplicación Celsius y toque el “logotipo de Celsius”.",
              1: () => 'Toque el botón "Perfil".',
              2: () =>
                "Toque ‘Descargar historial de transacciones’ en la sección de configuración.",
              3: () =>
                "Una vez que toque el botón Descargar historial de transacciones, verifique el correo electrónico que utilizó para registrar su billetera Celsius. Haga clic en el botón Descargar en el correo electrónico.",
              4: () =>
                "Sube el CSV a la Calculadora de impuestos criptográficos usando el botón de importación.",
            },
          },
        },
      },
    },
    cex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Cex.io",
              1: () =>
                "Vaya a la página de acceso a la API https://cex.io/trade/profile#/api",
              2: () => "Haga clic en el botón 'Generar clave API'",
              3: () =>
                "Vaya a Permisos para habilitar únicamente los permisos de 'saldo de cuenta' y 'órdenes abiertas'",
              4: () => "Haga clic en el botón 'Generar clave'",
              5: () => "Copiar el ID de usuario, la clave API y el secreto API",
              6: () =>
                "Haga clic en el botón 'Activar' junto a su nueva clave API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Cex.io",
              1: () =>
                "Vaya a Finanzas > Transacciones https://cex.io/finance/transactions",
              2: () =>
                "Seleccione 'Todos los tipos de transacciones' con fechas que abarquen todas las transacciones",
              3: () =>
                'Haga clic en el botón "Descargar a CSV" antes de cargarlo en la Calculadora de impuestos de criptomonedas',
            },
          },
        },
      },
    },
    changelly: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a: Mi cuenta - > Historial https://changelly.com/history",
              1: () => 'Pulse "Exportar CSV"',
              2: () => "Sube el archivo CSV aquí",
            },
          },
        },
      },
    },
    chihuahua: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Chihuahua en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    chiliz: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Los archivos deben seguir el formato: Marca de tiempo (UTC), Intercambio, Activo negociado, Monto negociado, Activo recibido, Monto recibido",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Puede importar transacciones desde el intercambio de Chiliz y aplicaciones asociadas, por ejemplo, Socios",
            },
          },
        },
      },
    },
    clvchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Actualmente estamos experimentando algunos problemas al recopilar tokens ERC-1155 y estamos trabajando para encontrar una solución. Si mientras tanto tienes este problema, prueba nuestro proceso de carga manual de CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Fichas ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera CLV P-Chain en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    coin98: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente, solo se admiten las direcciones de billetera Solana almacenadas en su billetera Coin98",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de la billetera blockchain de Solana que ha utilizado para operar a través de Coin98",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    coinbase: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Asegúrese de que su configuración de moneda de Coinbase coincida con su moneda local en https://accounts.coinbase.com/profile > Preferencias > Moneda.",
              1: () =>
                "Esta es una conexión API de solo lectura. Por razones de seguridad, Crypto Tax Calculator no admite la conexión de claves que permitan realizar operaciones o retiros. Obtenga más información en https://cryptotaxcalculator.io/privacy-policy",
              2: () =>
                "Es posible que falten algunas transacciones debido a que se trata de una moneda que Coinbase no admite. (https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/lost-assets)",
              3: () =>
                "Si se importa correctamente a través de claves API, no utilice el método “Conectar Coinbase” ya que esto generará duplicados.",
              4: () =>
                "Los retiros y depósitos de staking se ignoran, pero esto no afecta los informes.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en la plataforma para desarrolladores de Coinbase (https://portal.cloud.coinbase.com/).",
              1: () => "Vaya a claves API.",
              2: () =>
                "En “Crear clave API”, establezca un apodo, seleccione su cartera y elija los permisos “Ver (solo lectura)”.",
              3: () => 'Haga clic en "Crear y descargar".',
              4: () => "Autenticación completa de dos factores.",
              5: () =>
                "Copie el nombre de la clave API y el secreto y péguelos en la Calculadora de impuestos criptográficos.",
              6: () =>
                "Tu secreto de API se muestra solo una vez. Descarga el archivo JSON como respaldo. Si lo pierdes, crea una nueva clave de API en Coinbase y actualízala en Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/7GbjlA9B3bI",
            title: () => "Cómo importar tu API de Coinbase",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Si ha utilizado operaciones de relleno, las opciones 'Sincronizar mediante API' o 'Conectar Coinbase' pueden brindar resultados más precisos.",
              1: () =>
                "Asegúrese de que su configuración de moneda de Coinbase coincida con su moneda local en https://accounts.coinbase.com/profile > Preferencias > Moneda.",
              2: () =>
                "Los retiros y depósitos de staking se ignoran, pero esto no afecta los informes.",
            },
          },
          1: {
            items: {
              0: () =>
                "Las filas de Comercio avanzado no contienen información de precios completa.",
              1: () =>
                "Esto puede generar pequeñas diferencias entre su importación CSV y los detalles de Comercio Avanzado que se muestran en Coinbase.",
              2: () =>
                "Para obtener mejores resultados, intente importar a través de nuestra integración API.",
            },
            title: () =>
              "La API de Coinbase es la mejor para operaciones avanzadas",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Cuentas > Extracto (https://accounts.coinbase.com/statements)",
              1: () =>
                'En "Generar declaración personalizada", mantenga los campos configurados en "Historial de transacciones", "Todos los activos", "Todas las transacciones" y "Todo el tiempo".',
              2: () => "Seleccione “CSV” y haga clic en “Generar”.",
              3: () => "Subir el informe.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/nm2-aOO5iI4",
            title: () => "Cómo importar tus archivos CSV de Coinbase",
          },
        },
      },
      oauth: {
        note: {
          0: {
            items: {
              0: () =>
                "Asegúrese de que su configuración de moneda de Coinbase coincida con su moneda local en https://accounts.coinbase.com/profile > Preferencias > Moneda.",
              1: () =>
                "Si se ha conectado utilizando este método, no necesita actualizar sus claves según la descontinuación de claves heredadas de febrero de 2025.",
              2: () =>
                'Si la conexión no funciona, haga clic en "Reautorizar".',
              3: () =>
                "Los retiros y depósitos de staking se ignoran, pero esto no afecta los informes.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Haga clic en “Conectar Coinbase” para abrir una nueva pestaña.",
              1: () => "Inicie sesión en su cuenta de Coinbase.",
              2: () => "Autorizar acceso de sólo lectura.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/qHL8O_alo8Q",
            title: () =>
              "Cómo importar tus datos de Coinbase a través de Oauth",
          },
        },
      },
    },
    "coinbase-wallet": {
      "bulk-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Abre la billetera Coinbase en tu móvil o en tu navegador",
              1: () =>
                "Haga clic en el nombre de su billetera en la parte superior izquierda de la pantalla.",
              2: () => 'Haga clic en "agregar y administrar billeteras"',
              3: () => "Seleccione una billetera",
              4: () => "Haga clic en exportar direcciones públicas",
              5: () => "Haga clic en Copiar",
              6: () =>
                "Pegue la lista de billeteras en el cuadro de texto exactamente como fue copiada",
              7: () =>
                "Todas tus billeteras en Coinbase Wallet estarán conectadas",
            },
          },
        },
      },
    },
    coinbaseexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Coinbase Exchange.",
              1: () => "Crear una nueva clave API.",
              2: () =>
                "Asegúrese de establecer los permisos adecuados (solo lectura).",
              3: () =>
                "Una vez creado, pegue la clave API y el secreto en la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
    },
    coinbaseprime: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Hemos solucionado un problema que afectaba a las transacciones TWAP importadas antes de marzo de 2025. Para restaurar las transacciones faltantes, elimine y vuelva a agregar sus claves API. Realizar una sincronización forzada también importará las transacciones faltantes, pero puede generar entradas duplicadas, así que asegúrese de revisar y eliminar las duplicadas. Para obtener más información, comuníquese con el servicio de asistencia.",
            },
            title: () => "Actualización de transacciones de TWAP",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Coinbase Prime",
              1: () =>
                "En la esquina inferior izquierda de la página, haga clic en el ícono de configuración del engranaje.",
              2: () => "Haga clic en API",
              3: () =>
                "Junto a “Claves activadas”, haga clic en “Crear clave API”",
              4: () =>
                "En la ventana emergente, ingrese el 'Nombre de API', la 'Fecha de vencimiento' y el 'Tipo de acceso' de solo lectura.",
              5: () => 'Haga clic en "Continuar"',
              6: () => "Verifica tu identidad con YubiKey",
              7: () => "Esperar aprobaciones por consenso",
              8: () => "En 'Claves pendientes', busque su clave API",
              9: () => "Haga clic en 'Activar clave'",
              10: () =>
                "Haga clic en 'Activar clave API' y verifique con YubiKey una vez más",
              11: () =>
                'Copie y guarde la clave API, el secreto y la frase de contraseña generados. Pegue la clave de acceso en "Clave API", la frase de contraseña en "Frase de contraseña" y la clave de firma en "Secreto".',
              12: () =>
                "Puede encontrar instrucciones más detalladas en: https://help.coinbase.com/en/prime/coinbase-prime-api/creating-a-coinbase-prime-api",
            },
          },
        },
      },
    },
    coinbasepro: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Si tiene más de 500 transacciones OTC, le recomendamos cargarlas mediante un archivo CSV. Tenga en cuenta que pueden surgir transacciones OTC duplicadas al combinar API y CSV de esta manera.",
              1: () =>
                'Todas las transacciones OTC importadas a través de API contienen "OTC" en la descripción de la transacción, que combinada con la eliminación masiva se puede utilizar para eliminar fácilmente cualquier duplicado.',
            },
            title: () => "Operaciones OTC",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'A partir del 20 de noviembre de 2023, Coinbase dejará de utilizar Coinbase Pro y su API. Si no ha importado la API antes de esta fecha, cambie a "Cargar archivo" y siga las instrucciones para cargar su historial de transacciones de Coinbase Pro mediante CSV.',
              1: () =>
                "Si ya ha importado la API antes de esta fecha y la sincronización falla debido a una clave API vencida, importe su historial de transacciones de Coinbase Pro a través de CSV a partir de la última fecha de sincronización de su API.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Tenga en cuenta: Solo procesamos depósitos y retiros desde el archivo CSV "Extracto de cuenta". Todas las demás transacciones se toman desde el archivo CSV "Completar extracto de cuenta".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Coinbase.",
              1: () =>
                "Haga clic en el icono de perfil en la esquina superior derecha.",
              2: () =>
                "Seleccione “Informes” del menú desplegable (https://www.coinbase.com/reports).",
              3: () =>
                "Seleccione ‘Ver estados de cuenta’ en la sección ‘Estados de cuenta de Coinbase Pro’ (https://accounts.coinbase.com/statements/pro).",
              4: () =>
                'Deje los menús desplegables Cartera, Tipo de cuenta y Fecha con sus respectivas configuraciones "Todos".',
              5: () =>
                "Seleccione 'Cuenta' para el tipo de informe, seleccione CSV y luego haga clic en 'Generar' para descargar el archivo 'account.csv'",
              6: () =>
                "Seleccione 'Rellenos' para el tipo de informe, seleccione CSV y haga clic en Generar para descargar el archivo 'fills.csv'",
              7: () =>
                "Sube ambos archivos CSV a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    coinberry: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Asegúrese de que su informe abarque todos los años en los que ha estado negociando criptomonedas.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Coinberry en un navegador web",
              1: () =>
                "Vaya a su Panel de Control y haga clic en 'Actividad de Coinberry' para descargar sus datos comerciales Descarga CSV de Coinberry",
              2: () =>
                "No abra ni vuelva a guardar el archivo antes de importarlo, ya que esto podría cambiar el formato del archivo CSV",
              3: () =>
                "Sube el archivo que acabas de descargar aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    coincheck: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Coincheck",
              1: () =>
                'En el menú del lado izquierdo, seleccione el ícono de engranaje y luego haga clic en "Clave API".',
              2: () =>
                'Complete la verificación 2FA y luego haga clic en "Agregar otra clave API"',
              3: () =>
                "En la ventana que aparece, bajo el encabezado 'Orden', seleccione la opción 'Historial de operaciones'",
              4: () =>
                "En el encabezado 'Cuenta', seleccione las opciones 'Historial de envío' e 'Historial de depósitos'",
              5: () =>
                "En el encabezado 'Retirar JPY', seleccione la opción 'Historial de retiros de JPY'",
              6: () =>
                'Deje el campo IP en blanco, ingrese su contraseña y haga clic en "Aceptar" para generar la clave API',
              7: () =>
                "Ingrese aquí tanto la 'Clave API' como el 'Secreto API' en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    coincorner: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "El archivo debe ser un archivo CSV. Exporte a CSV si tiene un archivo .xlsx",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Puede importar transacciones desde un archivo CSV exportado por Coincorner",
            },
          },
        },
      },
    },
    coindcx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Abra la aplicación móvil CoinDCX y haga clic en el ícono de perfil en la esquina superior izquierda.",
              1: () => "Seleccione 'Descargar informes'.",
              2: () =>
                "Haga clic en “Informe comercial” dentro de la sección “Descargar informes”.",
              3: () =>
                'Ingrese el año fiscal y el período de tiempo y luego haga clic en "Generar". Su informe se descargará automáticamente.',
              4: () =>
                "Si sus transacciones abarcan varios períodos, repita este paso según sea necesario.",
              5: () =>
                "Sube los archivos a la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
    },
    coinex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de CoinEx",
              1: () => "Expande el menú 'Activos' y haz clic en 'Historial'",
              2: () =>
                'Establezca el rango de tiempo de los datos de su transacción y haga clic en "Exportar" para descargar un archivo ".xlsx"',
              3: () =>
                "Sube tu archivo '.xlsx' a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    coinexchange: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Tenga en cuenta que los retiros y depósitos no están incluidos en el CSV de datos de transacción y deberán agregarse manualmente.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Este intercambio ha sido desactivado, sin embargo, aún puedes cargar tus datos de transacciones guardadas en formato CSV",
            },
          },
        },
      },
    },
    coinfalcon: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Vaya a la sección Cuenta de su cuenta.",
              1: () => "Haga clic en Acceso API",
              2: () =>
                "Cree una nueva clave API y copie las claves en Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinfield: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "CoinField solo le permite descargar informes de un mercado a la vez, así que asegúrese de generar informes que abarquen todos los mercados en los que ha negociado (por ejemplo, BTC/USD, ETH/CAD) y cárguelos aquí.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de CoinField en un navegador web",
              1: () =>
                "Vaya a su Panel de Control y haga clic en Comercio > Historial de Comercio en la barra de navegación de la izquierda.",
              2: () => "Descargue su archivo CSV de historial comercial",
              3: () =>
                "Asegúrese de repetir este proceso para todos los mercados/criptomonedas con los que haya negociado.",
              4: () =>
                "Sube los archivos que acabas de descargar aquí a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinjar: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de CoinJar",
              1: () =>
                "Haga clic en el botón a continuación para ser redirigido a CoinJar en una nueva pestaña",
              2: () =>
                "Habilite los permisos de informes en su cuenta de CoinJar",
              3: () =>
                "Luego sincronizaremos su historial de transacciones de CoinJar.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "La opción de importación CSV no admite transacciones en paquetes, desagregadas y liquidadas. Si tiene estas transacciones, impórtelas mediante la opción CSV personalizada.",
              1: () =>
                "Si sus retiros fueron para uso personal o transacciones de venta, puede marcarlos como tales en la página siguiente",
              2: () =>
                "Si realizó operaciones a través del intercambio CoinJar, también debe agregar el archivo CSV 'rellenar'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en CoinJar",
              1: () =>
                "En la barra de navegación superior, haga clic en 'Configuración'",
              2: () =>
                "En la barra lateral izquierda, haga clic en 'Informes y declaraciones'",
              3: () =>
                "Haga clic en 'Declaraciones del historial de transacciones'",
              4: () =>
                "Haga clic en descargar el archivo CSV 'Estado de cuenta'",
              5: () =>
                "Haga clic en Intervalo de fechas personalizado y seleccione todo su historial comercial como intervalo de fechas.",
              6: () =>
                "Exporte el archivo CSV y cargue ambos archivos CSV en Crypto Tax Calculator",
              7: () =>
                "Deberías recibir un correo electrónico con tu archivo CSV. Descarga este archivo CSV y cárgalo aquí.",
            },
          },
        },
      },
    },
    coinjarexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de CoinJar Exchange",
              1: () =>
                "En la configuración de su cuenta navegue hasta la sección API",
              2: () => "Crear una nueva clave API",
              3: () =>
                'Asegúrese de que la clave pertenezca al ámbito de "solo lectura"',
              4: () =>
                "Copie el 'secreto' de su nueva clave API aquí en Crypto Tax Calculator y proporcione un apodo",
            },
          },
        },
      },
    },
    coinlist: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nuestra integración de CSV de CoinList se encuentra actualmente en versión beta. Si tiene algún problema o desea hacer algún comentario, comuníquese con nuestro servicio de asistencia.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de CoinList",
              1: () =>
                "Haga clic en el menú desplegable en la parte superior derecha de la plataforma CoinList y navegue hasta 'Billeteras'",
              2: () =>
                "Seleccione sus billeteras y configure el rango de tiempo de los datos de la transacción y haga clic en 'Descargar CSV'",
              3: () =>
                "Subir archivo CSV a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    coinlistpro: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de CoinList Pro",
              1: () =>
                "Haga clic en el menú desplegable en la parte superior derecha de la plataforma CoinList Pro",
              2: () =>
                "Seleccione “API” y luego haga clic en “Crear nueva clave”",
              3: () =>
                "Generar una clave API única con permisos de 'solo lectura'",
              4: () =>
                "Copie la 'Clave API' y el 'Secreto API' generados aquí en Crypto Tax Calculator, ingrese un apodo opcional y luego haga clic en 'Agregar API' para sincronizar su API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de CoinList Pro",
              1: () =>
                "Haga clic en el menú desplegable en la parte superior derecha de la plataforma CoinList Pro",
              2: () =>
                "Seleccione Declaraciones y configure 'Tipo de declaración', 'Fecha de inicio' y 'Fecha de finalización' y haga clic en 'Solicitar declaración'",
              3: () =>
                "Cargue AMBOS archivos CSV y CSV de cuenta en la Calculadora de impuestos de criptomonedas",
            },
          },
        },
      },
    },
    coinmetro: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de CoinMetro en un navegador web",
              1: () =>
                "Vaya a su Panel de Control y desplácese hacia abajo hasta su Historial de Transacciones",
              2: () =>
                "Seleccione el rango de fechas correspondiente al inicio de su período comercial o el período de interés utilizando el menú desplegable 'Rango de fechas'",
              3: () =>
                "Seleccione 'Depositar y retirar' en el menú desplegable 'Tipos'",
              4: () =>
                'Haga clic en "Descargar CSV" para descargar su historial de transacciones',
              5: () =>
                'Asegúrese de repetir esto con todos sus activos negociados, que pueden seleccionarse en el menú desplegable "Activo".',
              6: () =>
                "Sube los archivos que acabas de descargar aquí a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinsmart: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Bitbuy utilizando sus antiguas credenciales de Coinsmart y comuníquese con el equipo de atención al cliente de Bitbuy para solicitar su historial de transacciones.",
              1: () =>
                "Sube tus archivos CSV aquí en la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
    },
    coinspot: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Sincronizamos todos tus datos de CoinSpot a través de la API. Esto incluye transacciones, airdrops, transferencias de criptomonedas, depósitos/retiros en AUD, pagos de ingresos de afiliados y pagos de ingresos por referencias.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inicie sesión en CoinSpot y vaya a la página "API" (https://www.coinspot.com.au/my/api).',
              1: () => 'Haga clic en "Generar nueva clave API".',
              2: () =>
                'Asigne un nombre a la clave y configure el tipo de clave API en "Solo lectura".',
              3: () =>
                "Ingrese su código de dos factores y haga clic en “Crear nueva clave”.",
              4: () =>
                "Confirme la creación de la clave API haciendo clic en el enlace enviado a su correo electrónico.",
              5: () => "Copie y pegue la 'Clave API' en CTC",
              6: () =>
                "Revela, luego copia y pega la 'Clave secreta' en CTC (no podrás acceder a ella nuevamente).",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/Pls-kQAg94U",
            title: () => "Cómo importar tu API de Coinspot",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "El CSV no incluye información sobre airdrops, depósitos/retiros en AUD o pagos de afiliados/referencias, tendrás que agregarlos manualmente o usar la sincronización de API de CoinSpot.",
            },
            title: () => "Transacciones faltantes",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Visita https://www.coinspot.com.au/my/orders/history",
              1: () =>
                "Haga clic en “Enviar/Recibir CSV” para descargar su historial de envío y recepción como “sendreceives.csv”.",
              2: () =>
                "Haga clic en “Compra/Venta CSV” para descargar su historial de pedidos como “orderhistory.csv”.",
              3: () =>
                "Sube ambos archivos a la Calculadora de impuestos criptográficos.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/IXAkAHO2N9k",
            title: () => "Cómo importar tus CSV de Coinspot",
          },
        },
      },
    },
    coinsquare: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Coinsquare aquí (https://app-new.coinsquare.com/sign-in).",
              1: () =>
                'Haga clic en el menú desplegable junto a su nombre (arriba a la derecha) y seleccione "Documentos".',
              2: () =>
                "En “Declaraciones”, seleccione el año fiscal para el cual necesita su historial de transacciones y haga clic en “Descargar”.",
              3: () =>
                "Sube todos los archivos CSV descargados aquí en Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinstash: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Coinstash",
              1: () =>
                "Vaya a su página de configuración (https://coinstash.com.au/settings)",
              2: () =>
                'Desplácese hacia abajo hasta la sección "Informes". Haga clic en el botón "Descargar" junto a "Historial de transacciones".',
              3: () =>
                "Ahora cargue el CSV en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    coinswitch: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de CoinSwitch Kuber.",
              1: () =>
                "Toque el ícono de perfil azul en la esquina superior izquierda de la página de inicio.",
              2: () =>
                "Vaya a la sección ‘Informes’ y descargue su estado de cuenta.",
              3: () =>
                "Seleccione la sección 'Pérdidas y Ganancias de Impuestos'.",
              4: () =>
                "Seleccione el año financiero, seleccione “Todos los trimestres” y toque “Enviar estado de cuenta”.",
              5: () =>
                "Sube el archivo a la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
    },
    cointracking: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Una vez importadas, las transacciones se desglosarán en sus importaciones de intercambio correspondientes cuando sea pertinente.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Puede importar todo su historial comercial desde Cointracking",
              1: () =>
                "Inicie sesión en su cuenta de CoinTracking y, en la barra de navegación, haga clic en “Ingresar monedas” (https://cointracking.info/enter_coins.php)",
              2: () =>
                'En la tabla verás un botón "Exportar". Haz clic en este botón y selecciona "CSV" en el menú desplegable. Se descargará automáticamente un archivo CSV.',
              3: () =>
                "Sube el archivo CSV descargado aquí a Crypto Tax Calculator.",
            },
            title: () => "Mesa de intercambio (recomendada):",
          },
          1: {
            items: {
              0: () =>
                "Alternativamente, puede exportar el saldo de cierre del último ejercicio financiero.",
              1: () =>
                "Asegúrese de que su exportación se realice en su moneda fiduciaria local.",
              2: () => "El formato de la fecha debe ser 'DD.MM.AAAA'.",
            },
            title: () => "Saldo de cierre:",
          },
        },
      },
    },
    cointree: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Asegúrese de tomar nota de su secreto de API en un lugar seguro.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Cointree",
              1: () =>
                'Haz clic en el icono de tu perfil en la esquina superior derecha y navega hasta "Configuración".',
              2: () =>
                "Vaya a 'Claves API' (última opción en el menú superior)",
              3: () =>
                "Ingrese un nombre para mostrar para su clave API en el campo 'Nombre para mostrar de la clave API'",
              4: () =>
                "Elija un nivel de acceso para su clave API y haga clic en 'Generar clave API + secreto'",
              5: () =>
                "Copie y pegue su clave API y su secreto API en la Calculadora de impuestos criptográficos",
              6: () =>
                'Proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Cointree",
              1: () => "Vaya a Monedero > Transacciones",
              2: () =>
                'Haga clic en el botón "Exportar" en la tabla "Todas las transacciones"',
              3: () =>
                "Haga clic en 'Exportar órdenes (csv)' en el menú desplegable para descargar su historial de operaciones",
              4: () =>
                "Haga clic en 'Exportar transacciones (csv)' en el menú desplegable para descargar su historial de depósitos/retiros",
              5: () =>
                "Sube ambos archivos CSV aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    "cosmos/ibc": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () =>
                "Navega hasta la cuenta que deseas importar y copia la dirección de tu billetera pública",
              2: () =>
                "En CTC, elija la red blockchain relevante del menú desplegable",
              3: () =>
                'Pegue la dirección de su billetera pública y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    cosmoshub: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Los datos anteriores al 20/02/2021 no están disponibles debido a una limitación de la fuente de datos.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Cosmos Hub en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    coss: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Coss",
              1: () => "Vaya a la página de administración de API",
              2: () => "Tipo Nombre de la clave",
              3: () => 'Haga clic en el botón "Crear nueva clave"',
              4: () => "Ingresa tu código 2FA",
              5: () => "Haga clic en el botón 'Siguiente'",
              6: () =>
                'Revise su correo electrónico y haga clic en el enlace "Haga clic aquí para crear una clave API"',
              7: () => "Copiar tanto la clave pública como la clave secreta",
              8: () =>
                'Haga clic en el botón "Editar" para desactivar el permiso comercial para esta clave',
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Coss",
              1: () => "Navegar al historial comercial",
              2: () =>
                'Haga clic en el botón "Exportar historial comercial completo"',
              3: () =>
                "Asegúrese de que las fechas seleccionadas correspondan a un rango anterior a la apertura de su cuenta y ahora",
              4: () => 'Haga clic en "Exportar"',
              5: () =>
                "Subir CSV a la calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    crex24: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Al generar las claves API, verifique dos veces para asegurarse de seleccionar solo las opciones R1 y R3.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en CREX24",
              1: () =>
                "Haga clic en 'Mi cuenta' en la esquina superior derecha",
              2: () => "Haga clic en la pestaña 'Claves API'",
              3: () =>
                'Haga clic en "Nueva clave API" e ingrese el código que recibió por correo electrónico',
              4: () => "Ahora debería ver una nueva clave API en la tabla",
              5: () =>
                "Establezca la pestaña 'Estado' en 'ACTIVADO' y marque las casillas de verificación R1 y R3",
              6: () =>
                "Copie la clave API y el secreto y péguelos en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    cronos: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Ahora admitimos tokens CRC-20. Si ya has importado tu cuenta de Cronos, es posible que debas realizar una "sincronización completa" de tu API para actualizar los datos históricos.',
            },
            title: () => "Fichas CRC-20",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentra la dirección de la billetera que has usado para operar a través de Cronos (comenzando con 0x)",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    cryptodotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'El formato de fecha debe ser uno de los siguientes: "DD-MM-AA H:mm", "M/D/AA H:mm" o "AAAA-MM-DD HH:mm:ss". Si no tiene el formato de fecha correcto en su CSV, comuníquese con nuestro equipo de soporte a través del chat web.',
              1: () =>
                "Si importa su CSV 'fiat_transactions_record', DEBE eliminar todas las filas de tipo de transacción 'viban_purchase' y 'recurring_buy_order'. Como estos datos ya están registrados en el CSV 'crypto_transactions_record', importar ambos provocará errores de importación.",
            },
            title: () => "Formato CSV",
          },
          1: {
            items: {
              0: () =>
                "A veces, el CSV exportado está dañado: si recibe errores de carga, intente guardar el archivo nuevamente localmente como CSV y luego vuelva a importarlo.",
            },
            title: () => "Errores de subida",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Abra su aplicación Crypto.com y vaya a la pestaña "Cuentas".',
              1: () =>
                "Haga clic en “Historial de transacciones” y luego en “Exportar”.",
              2: () => "Seleccione 'Billetera criptográfica' en 'Transacción'.",
              3: () =>
                "Seleccione una fecha de inicio para todas las transacciones y establezca la fecha de finalización en hoy.",
              4: () =>
                "Haga clic en «Exportar a CSV» para descargar el archivo.",
              5: () =>
                "Sube el archivo CSV a la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
    },
    cryptodotcomexchange: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "La API puede tardar hasta 90 minutos en sincronizarse, ya que necesitamos iterarla todos los días y Crypto.com la limita.",
              1: () =>
                "A veces, la API no se sincroniza debido a problemas con Crypto.com. Si esto le sucede, intente importar CSV.",
              2: () =>
                "La API de Crypto.com solo admite el historial de transacciones de los últimos 6 meses. Si necesita datos más antiguos, utilice el método CSV.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Haga clic en Configuración > Claves API (https://crypto.com/exchange/personal/api-management).",
              1: () =>
                "Generar claves API de solo lectura que no permitan el comercio",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente no admitimos operaciones con margen o derivados debido a restricciones por país. Si ha negociado estos productos, comuníquese con nuestro servicio de asistencia por chat en la aplicación. Además, el archivo CSV de retiro no incluye tarifas. Deberá actualizarlos manualmente.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Crypto.com ofrece varios formatos CSV para exportar datos. Necesitará todos ellos (Transacciones, Depósitos y retiros e Historial de transacciones) para importar su historial de transacciones completo.",
              1: () =>
                "Tenga en cuenta que Crypto.com permite un rango máximo de fechas de 180 días por exportación. Para cubrir todo su historial de transacciones, es posible que deba exportar varios archivos para cada formato.",
              2: () => "Inicie sesión en su cuenta de Crypto.com.",
              3: () => "Cambie el idioma a inglés (si aún no lo está).",
              4: () =>
                "Desde el panel de control, seleccione Monedero en el menú de la izquierda.",
              5: () =>
                "Vaya a la pestaña Transacciones. Elija un rango de fechas y haga clic en Exportar para descargar el archivo CSV de Transacciones.",
              6: () => "Regresar al menú Billetera.",
              7: () =>
                "Vaya a la pestaña Depósitos y retiros. Elija un rango de fechas y haga clic en Exportar para descargar el archivo CSV de depósitos y retiros.",
              8: () =>
                "Desde el panel de control, seleccione Pedidos en el menú de la izquierda.",
              9: () =>
                "Vaya a la pestaña Historial de transacciones. Elija un rango de fechas y haga clic en Exportar para descargar el archivo CSV del Historial de transacciones.",
              10: () =>
                "Importe los tres archivos descargados (deposit&Withdrawal.csv, OEX_TRANSACTION.csv en CTC, OEX_TRADE.csv) en CTC.",
            },
          },
        },
      },
    },
    cryptospend: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Wayex",
              1: () =>
                "Vaya a la página 'Cuenta', desplácese hacia abajo hasta Transacciones y luego presione 'Obtener Transacciones'",
              2: () =>
                "Esto generará un archivo CSV que se enviará a su correo electrónico.",
              3: () => "Descargue el archivo CSV desde su correo electrónico",
              4: () =>
                "Sube este CSV aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    cryptotaxcalculatorlegacy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Solo para importar transacciones manuales desde legacy.cryptotaxcalculator.io",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Primero envíenos un correo electrónico si está convirtiendo desde una versión anterior para obtener las operaciones manuales como un archivo CSV",
              1: () =>
                "Sube el CSV aquí a la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
    },
    customCSV: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "CSV avanzado para transacciones más detalladas: https://cryptotaxcalculator.io/guides/advanced-manual-csv-import",
              1: () =>
                "¡Asegúrese de que sus datos estén preparados correctamente y en formato de archivo .csv!",
            },
            title: () =>
              "Si su intercambio no está disponible a través de nuestra importación automática, puede importarlo utilizando una de nuestras plantillas CSV personalizadas:",
          },
        },
      },
    },
    daedalus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Ingrese solo UNA dirección de cada billetera. Realizamos una búsqueda y extraemos los datos de todas las demás direcciones de la billetera automáticamente.",
              1: () =>
                "Importar varias direcciones de la misma billetera dará como resultado transacciones duplicadas.",
              2: () =>
                "Actualmente no ofrecemos soporte para obtener el historial completo de transacciones de billeteras en las que cada dirección individual tiene más de 10 transacciones. Estamos trabajando para brindar soporte adicional para dichas billeteras.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese cualquier dirección shell (addr1..) o dirección de participación (stake1...) de su billetera Daedalus en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Ahora puedes importar las transacciones de tu billetera Daedalus de manera alternativa directamente a través de la cadena de bloques conectándote con la opción "Sincronizar a través de API". Esto incorporará todas las transacciones y recompensas de participación a tu billetera Daedalus.',
              1: () =>
                "En algunas ocasiones, el archivo CSV de la transacción parece contener datos erróneos. Este es un problema con Daedalus. Comuníquese con el servicio de asistencia si nota alguna discrepancia.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su billetera Daedalus y exporte su CSV de recompensa",
              1: () => "También puedes importar las transacciones CSV",
            },
          },
        },
      },
    },
    dash: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
              1: () =>
                "También admitimos direcciones xpub, ypub y zpub; sin embargo, se prefiere xpub.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Dash en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    ddex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () => "Seleccione el intercambio del menú desplegable",
              3: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              4: () => "Mantener las demás configuraciones predeterminadas",
              5: () => 'Haga clic en "Recuperar historial"',
              6: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              7: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    decentrex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el lado izquierdo del menú, expanda 'Historial de intercambio' y haga clic en Decentrex",
              3: () =>
                "En “Tipos de operaciones”, haga clic en “Operaciones + Fondos”.",
              4: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              5: () => "Mantener las demás configuraciones predeterminadas",
              6: () => 'Haga clic en "Recuperar historial"',
              7: () =>
                "Una vez completado, puede exportar el csv predeterminado en 'Exportar operaciones' y 'Exportar fondos'.",
              8: () =>
                "En Crypto Tax Calculator ahora puedes ingresar la dirección de tu billetera y cargar estos archivos CSV",
            },
          },
        },
      },
    },
    deltaBalances: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () => "Seleccione el intercambio del menú desplegable",
              3: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              4: () => "Mantener las demás configuraciones predeterminadas",
              5: () => 'Haga clic en "Recuperar historial"',
              6: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              7: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    deribit: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Abra la página Claves API en Deribit y seleccione Agregar nueva clave API",
              1: () =>
                "Seleccione permiso de lectura para Cuenta, Comercio y Monedero",
              2: () => "Copia la clave y el secreto en Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Deribit y haga clic en su nombre de usuario en la esquina superior derecha y seleccione 'Registro de transacciones'",
              1: () =>
                "Seleccione las fechas en las que realizó operaciones y descargue los registros",
              2: () =>
                "Si intercambió Ethereum, también deberá hacer clic en Ethereum en la esquina superior izquierda y repetir los pasos anteriores.",
              3: () =>
                "Luego, puede importar los archivos CSV a la aplicación para informar los ingresos por ganancias o pérdidas obtenidos por las operaciones.",
            },
          },
        },
      },
    },
    digitalsurge: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Digital Surge",
              1: () =>
                'Haga clic en el menú desplegable ubicado en la parte superior derecha de la página. En el menú emergente, seleccione "Clave API" para navegar a la página Clave API (https://digitalsurge.com.au/account-settings/api-keys)',
              2: () =>
                'En la parte superior derecha de la página, haga clic en el botón "Crear una nueva clave API"',
              3: () =>
                'En la ventana emergente, asegúrese de seleccionar "Solo lectura" y luego haga clic en "Siguiente".',
              4: () =>
                "Ingrese el código de confirmación 2FA y luego haga clic en 'Generar la clave API'",
              5: () =>
                "Copie la clave API que se muestra, péguela en el campo 'Secreto' aquí en Crypto Tax Calculator, ingrese un apodo opcional y luego haga clic en 'Agregar API' para sincronizar su API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Digital Surge",
              1: () =>
                'Haga clic en el menú desplegable ubicado en la parte superior derecha de la página. En el menú emergente, seleccione "Historial de transacciones" para navegar a la página Historial de transacciones (https://digitalsurge.com.au/account-settings/transactions-history)',
              2: () =>
                'En la página Historial de transacciones, haga clic en el botón "Exportar historial" (para dispositivos móviles, haga clic en los tres puntos a la derecha del título Historial de transacciones)',
              3: () =>
                'En el menú desplegable, seleccione la opción "Historial de operaciones" para abrir la ventana emergente "Exportar historial de operaciones". En esta ventana emergente, deje la selección de fecha vacía (esto cubre todo su historial de operaciones) y elija el formato "CSV". Una vez seleccionado, haga clic en el botón "Exportar historial" para descargar su archivo "trades.csv".',
              4: () =>
                'En el menú desplegable, seleccione la opción "Historial de transferencias" para abrir la ventana emergente "Exportar historial de transferencias". En esta ventana emergente, deje la selección de fecha vacía y elija el formato "CSV". Una vez seleccionado, haga clic en el botón "Exportar historial" para descargar su archivo "transfers.csv".',
              5: () =>
                "Cargue los archivos 'trades.csv' y 'transfers.csv' aquí en Crypto Tax Calculator",
            },
          },
        },
      },
    },
    doge: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
              1: () =>
                "También admitimos direcciones xpub, ypub y zpub; sin embargo, se prefiere xpub.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Dogecoin en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    dydx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Visita trade.dYdX.exchange y conecta tu(s) billetera(s)",
              1: () => "Haga clic en 'Portafolio' en la barra de menú",
              2: () => "Seleccione 'Historial' en el panel lateral",
              3: () =>
                'Haga clic en "Exportar" en la esquina superior derecha de la pantalla.',
              4: () =>
                "Descargue los 3 CSV disponibles: Transferencias, Intercambios, Financiamiento",
              5: () => "Sube los archivos extraídos a Crypto Tax Calculator",
              6: () =>
                "Nota: Actualmente, tendrá un problema de historial de compras faltante debido a la forma en que dYdX registra las operaciones en corto. Estamos trabajando para encontrar una solución. Por ahora, puede calcular las pérdidas y ganancias y usar nuestro CSV manual para agregar una pérdida/ganancia realizada para la operación en corto.",
            },
          },
        },
      },
    },
    dydx4: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "En esta etapa, solo se admiten operaciones comerciales derivadas, como aumentar o disminuir una posición.",
            },
            title: () => "Soporte de operación limitada",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Localice su dirección dYdX v4 navegando a la aplicación de protocolo y copiando la dirección desde el menú superior derecho",
              1: () =>
                'Ingrese la dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    dymension: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Dymension en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    easyTrade: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () => "Seleccione el intercambio del menú desplegable",
              3: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              4: () => "Mantener las demás configuraciones predeterminadas",
              5: () => 'Haga clic en "Recuperar historial"',
              6: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              7: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    easycrypto: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente admitimos compras y ventas en Easy Crypto. Si está realizando intercambios u otros tipos de transacciones, comuníquese con el servicio de asistencia y envíe su archivo CSV a nuestro equipo de asistencia.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta Easy Crypto",
              1: () =>
                "Vaya a 'Mi cuenta' - > 'Pedidos' (https://easycrypto.ai/au/account/orders)",
              2: () =>
                'Cerca de la parte inferior de la página verá un enlace para "Descargar un archivo CSV de sus pedidos completados". Haga clic en este enlace y se descargará un archivo CSV en su computadora.',
              3: () =>
                "Sube este archivo descargado aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    elbaite: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Elbaite",
              1: () => "Vaya a la pestaña [Operaciones]",
              2: () => "Haga clic en [Exportar a CSV]",
              3: () =>
                "Sube el archivo CSV aquí a tu cuenta de Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    enclaves: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el lado izquierdo del menú, expanda 'Historial de intercambio' y haga clic en Enclaves.",
              3: () =>
                "En “Tipos de operaciones”, haga clic en “Operaciones + Fondos”.",
              4: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              5: () => "Mantener las demás configuraciones predeterminadas",
              6: () => 'Haga clic en "Recuperar historial"',
              7: () =>
                "Una vez completado, puede exportar el csv predeterminado en 'Exportar operaciones' y 'Exportar fondos'.",
              8: () =>
                "En Crypto Tax Calculator ahora puedes ingresar la dirección de tu billetera y cargar estos archivos CSV",
            },
          },
        },
      },
    },
    enkrypt: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abre tu billetera Enkrypt",
              1: () => "Haga clic en la red que desea importar",
              2: () => "Haga clic en el icono de copia de dirección",
              3: () =>
                "En la Calculadora de impuestos criptográficos, seleccione la red correspondiente",
              4: () => 'Pegue la dirección y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    ercDex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    eth: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ahora admitimos tokens ERC-1155, pero no podemos importar los saldos actuales de estos tokens. Esto puede generar discrepancias entre el saldo importado desde la API de origen y el saldo calculado por la aplicación.\n Actualmente, no se admiten tokens ERC-404, aunque estamos trabajando para agregarlos. Por ahora, deberá agregar manualmente estas transacciones a la aplicación.",
            },
            title: () => "Notas",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Ingresa la dirección de tu billetera Ethereum",
              1: () =>
                'Agregue un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    ethen: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el lado izquierdo del menú, expanda 'Historial de intercambio' y haga clic en ETHEN",
              3: () =>
                "En “Tipos de operaciones”, haga clic en “Operaciones + Fondos”.",
              4: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              5: () => "Mantener las demás configuraciones predeterminadas",
              6: () => 'Haga clic en "Recuperar historial"',
              7: () =>
                "Una vez completado, puede exportar el csv predeterminado en 'Exportar operaciones' y 'Exportar fondos'.",
              8: () =>
                "En Crypto Tax Calculator ahora puedes ingresar la dirección de tu billetera y cargar estos archivos CSV",
            },
          },
        },
      },
    },
    etherc: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () => "Seleccione el intercambio del menú desplegable",
              3: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              4: () => "Mantener las demás configuraciones predeterminadas",
              5: () => 'Haga clic en "Recuperar historial"',
              6: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              7: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    etherdelta: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el lado izquierdo del menú, expanda 'Historial de intercambio' y haga clic en EtherDelta",
              3: () =>
                "En “Tipos de operaciones”, haga clic en “Operaciones + Fondos”.",
              4: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              5: () => "Mantener las demás configuraciones predeterminadas",
              6: () => 'Haga clic en "Recuperar historial"',
              7: () =>
                "Una vez completado, puede exportar el csv predeterminado en 'Exportar operaciones' y 'Exportar fondos'.",
              8: () =>
                "En Crypto Tax Calculator ahora puedes ingresar la dirección de tu billetera y cargar estos archivos CSV",
            },
          },
        },
      },
    },
    "ethereum-classic": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
          1: {
            items: {
              0: () =>
                "Es posible que falten algunas transacciones internas en algunas billeteras.",
            },
            title: () => "Transacciones internas faltantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Ethereum Classic en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    etherscan: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "La mejor manera de importar datos de EtherScan es importar directamente la dirección de su billetera pública",
              1: () =>
                'Pegue la dirección de su billetera pública Ethereum en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    ethex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    ethfinex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () => "Seleccione el intercambio del menú desplegable",
              3: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              4: () => "Mantener las demás configuraciones predeterminadas",
              5: () => 'Haga clic en "Recuperar historial"',
              6: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              7: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    etoro: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "eToro cambia con frecuencia el formato de archivo CSV de las operaciones exportadas. Si su archivo no se importa a la plataforma, es posible que lo hayan cambiado nuevamente. En este caso, recomendamos utilizar la importación avanzada de CSV de CTC para sus operaciones de eToro.",
              1: () =>
                "Dado que la Calculadora de impuestos sobre criptomonedas está diseñada para transacciones con criptomonedas, no podemos importar transacciones que no sean de criptomonedas. Si su cuenta de eToro incluye dichas transacciones, deberá contabilizarlas externamente.",
            },
            title: () => "Notas:",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de eToro. Puede acceder al estado de cuenta directamente aquí (https://www.etoro.com/documents/accountstatement) o seguir los pasos que se indican a continuación.",
              1: () =>
                "Desde la barra lateral, haga clic en 'Portafolio' y luego navegue hasta 'Historial'",
              2: () =>
                "Expande el menú desplegable en la parte superior derecha y haz clic en “Estado de cuenta”",
              3: () =>
                "Establezca el rango de tiempo de los datos de su transacción y haga clic en 'Crear'",
              4: () =>
                "En la parte superior derecha, haga clic en el ícono 'XLS' para descargar un archivo '.xlsx'",
              5: () =>
                'En el campo "Símbolo de moneda" del cuadro de importación, haga coincidir exactamente la moneda con el encabezado CSV (por ejemplo, use USD en lugar de $).',
              6: () =>
                "Sube tu archivo '.xlsx' a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    evmos: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Evmos en desarrollo temprano",
              1: () => "Datos anteriores al 28/04/2022 no disponibles",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Evmos en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    exmo: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
              1: () =>
                'No admitimos direcciones IP específicas en nuestra plataforma; asegúrese de que la opción "Acceso a la API solo desde direcciones IP confiables" esté deshabilitada al generar una clave API',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta Exmo y navegue a la pestaña de configuración",
              1: () =>
                'Haga clic en la pestaña API y asegúrese de que la opción "Acceso a la API solo desde direcciones IP confiables" esté desactivada.',
              2: () =>
                "Cree una clave API haciendo clic en 'Generar clave API' e ingrésela aquí en Crypto Tax Calculator como 'Clave API' y 'Secreto' respectivamente",
            },
          },
        },
      },
    },
    exodus: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Navega a la sección de historial de tu billetera (arriba a la derecha de tu billetera Exodus)",
              1: () =>
                "Haga clic en la opción exportar todas las transacciones",
              2: () =>
                "Subir el archivo a la Calculadora de Impuestos Criptográficos",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abre tu billetera Exodus.",
              1: () =>
                "Seleccione la criptomoneda que desea importar. Por ejemplo, Bitcoin o Ethereum.",
              2: () =>
                "Copia la dirección de billetera pública correspondiente para ese activo.",
              3: () =>
                "En CTC, elija la red blockchain correspondiente en el menú desplegable.",
              4: () =>
                'Pegue la dirección de su billetera pública en CTC y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    fantom: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ahora admitimos tokens ERC-1155, pero no podemos importar los saldos actuales de estos tokens. Esto puede generar discrepancias entre el saldo importado desde la API de origen y el saldo calculado por la aplicación.",
            },
            title: () => "Fichas ERC-1155",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Fantom en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    "fearless-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abre tu billetera Fearless",
              1: () =>
                "En la parte superior derecha, selecciona la red que deseas importar",
              2: () =>
                "Haga clic en el icono de copia de dirección en la parte superior izquierda",
              3: () =>
                "En la Calculadora de impuestos criptográficos, seleccione la red correspondiente",
              4: () => 'Pegue la dirección y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    fetchai: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Fetch.AI en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    flare: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
          1: {
            items: {
              0: () =>
                "Es posible que falten algunas transacciones internas en algunas billeteras.",
            },
            title: () => "Transacciones internas faltantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Flare en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    ftx: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Historial comercial",
              1: () =>
                "Declaraciones de pérdidas y ganancias de futuros y perpetuos",
              2: () => "Historial de depósitos y retiros",
            },
            title: () =>
              "FTX ya no está operativo. Utilice los archivos CSV de FTX existentes o la opción de importación de archivos CSV personalizados de Crypto Tax Calculator para importar sus transacciones de FTX. Los archivos CSV de FTX existentes que admitimos son:",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Solo importamos las operaciones al contado para el archivo CSV "Historial de operaciones", no las operaciones de futuros y perpetuos (por ejemplo, XXX-PERP). Utilice el archivo CSV de pérdidas y ganancias "Futuros y perpetuos" para importar estos datos.',
              1: () =>
                'No se admite el archivo CSV "Historial de órdenes". En su lugar, utilice los archivos CSV de pérdidas y ganancias "Historial de operaciones" y "Futuros y perpetuos".',
            },
            title: () => "Notas de soporte de CSV de FTX preexistentes",
          },
        },
      },
      api: {
        step: {
          0: {
            title: () =>
              "FTX ya no está operativo. Utilice la opción de importación CSV personalizada para importar sus transacciones FTX.",
          },
        },
      },
    },
    ftxus: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX ya no está operativo y ya no es posible obtener datos históricos de este exchange. Las transacciones de FTX que se hayan importado previamente seguirán estando accesibles en su cuenta. Las nuevas importaciones deben utilizar archivos CSV de FTX existentes o la opción de importación de CSV personalizada. Las sincronizaciones de API ya no son posibles.",
            },
            title: () => "FTX ya no está operativo",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX ya no está operativo. Utilice la opción de importación CSV personalizada para importar sus transacciones FTX.",
          },
        },
      },
      csv: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX ya no está operativo y ya no es posible obtener datos históricos de este exchange. Las transacciones de FTX que se hayan importado previamente seguirán estando accesibles en su cuenta. Las nuevas importaciones deben utilizar archivos CSV de FTX existentes o la opción de importación de CSV personalizada. Las sincronizaciones de API ya no son posibles.",
            },
            title: () => "FTX ya no está operativo",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX ya no está operativo. Utilice la opción de importación CSV personalizada para importar sus transacciones FTX.",
          },
        },
      },
    },
    gate: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Gate.io",
              1: () => "Vaya a la página de claves API",
              2: () =>
                "Si corresponde, ingrese la contraseña del fondo y el TOTP",
              3: () => 'Haga clic en el botón "Mostrar claves API"',
              4: () => "Copiar tanto la clave API como el secreto API",
              5: () => "Habilite la opción de solo lectura para su clave API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Gate.io",
              1: () => "Vaya a Monederos > Historial de transacciones",
              2: () => "Haga clic en 'TODOS' para mercados y monedas",
              3: () =>
                "Asegúrese de que las fechas seleccionadas se ajusten a todos los intercambios",
              4: () =>
                'Haga clic en el botón "Descargar", que aparece como una flecha que apunta hacia abajo.',
              5: () =>
                "Subir CSV a la calculadora de impuestos de criptomonedas",
            },
          },
        },
      },
    },
    gateio: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Puede haber algunas discrepancias de saldo menores debido a problemas de redondeo en los propios archivos CSV.",
            },
            title: () => "Formato CSV",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Gate.io y diríjase a https://www.gate.io/myaccount/mypurselog. También puede acceder a esta página desde el menú de la barra lateral > Historial de transacciones.",
              1: () => "Seleccione “Todos” para Moneda y “Todos” para Filtro.",
              2: () => "Haga clic en 'Filtro' > Descargar",
              3: () =>
                "También puede hacer clic en “Solicitar datos por lotes” en la esquina superior derecha.",
              4: () => "Seleccione “Todos” para Moneda y envíe la solicitud.",
              5: () =>
                "Puede descargar 90 días de historial de transacciones cada vez. Si tiene más, repita los pasos para diferentes períodos.",
              6: () => "Importe el archivo exportado 'mypurselog.csv' en CTC.",
            },
          },
        },
      },
    },
    gemini: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Crypto Tax Calculator debería ser el único servicio que utilice la clave API que has proporcionado. Lo más probable es que experimentes problemas de importación si utilizas una clave API compartida con otro servicio.",
              1: () =>
                "Tenga en cuenta que las transacciones con montos inferiores a 10^-12 (de cualquier moneda) no se importarán.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Gemini y navegue a Cuenta > Configuración (arriba a la derecha de la pantalla)",
              1: () =>
                "Desplácese hacia abajo hasta la pestaña API en el lado izquierdo",
              2: () =>
                "Haga clic en crear una nueva clave API (no utilice una clave existente)",
              3: () =>
                "Seleccione el alcance: 'Principal' y haga clic en 'Crear clave API'",
              4: () =>
                'Marque la casilla "Auditor" en la configuración de la clave API. NO haga nada más en la configuración API.',
              5: () => "Copiar la clave API y el secreto",
              6: () =>
                'Marque "Sí" en la casilla de verificación copiar y pegar',
              7: () => "Haga clic en confirmar",
              8: () =>
                "Agregue la clave API y el secreto copiados a la calculadora de impuestos criptográficos",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                'Haga clic en "Conectarse a Gemini" para ser redirigido a Gemini Exchange',
              1: () => "Inicie sesión en su cuenta de Gemini.",
              2: () => "Proporcione el código 2FA si es necesario",
              3: () =>
                'Seleccione la cuenta que desea conectar y luego haga clic en "Continuar"',
              4: () => 'Haga clic en el botón "Permitir"',
            },
          },
        },
      },
    },
    "giddy-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Abra su aplicación móvil Giddy Wallet y navegue hasta la sección 'Billetera' (esquina inferior izquierda).",
              1: () =>
                "Haga clic en el ícono QR en la parte superior izquierda.",
              2: () =>
                "Copia la dirección de la billetera pública que comienza con '0x...'",
              3: () => "Pegue la dirección y haga clic en “Importar billetera”",
            },
          },
        },
      },
    },
    graviex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Navega a la página de historial de tu cuenta",
              1: () =>
                "Haga clic en el historial comercial y exporte los datos",
              2: () => "Subir a la calculadora de impuestos de criptomonedas",
            },
          },
        },
      },
    },
    grs: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
              1: () =>
                "También admitimos direcciones xpub, ypub y zpub; sin embargo, se prefiere xpub.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Groestlcoin en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    hedera: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Ingresa la dirección de tu billetera Hedera",
              1: () =>
                'Agregue un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    hitbtc: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente no admitimos cargos por intereses de margen ni pagos de financiación de futuros para la integración de API, ya que HitBTC no ofrece un punto final de API para obtener esta información. Estamos trabajando con HitBTC para resolver esto.",
              1: () =>
                "HitBTC no devuelve el historial de transacciones al contado de monedas excluidas de la lista, como VERI. Esto puede provocar que los saldos no coincidan si tenía transacciones históricas en esos pares.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de HitBTC y navegue a la página Claves API de HitBTC: en la pestaña Cuentas (https://hitbtc.com/settings/api-keys)",
              1: () =>
                "Haga clic en el botón 'Nueva clave API' e ingrese su código de autenticación de dos factores",
              2: () =>
                "Habilite ambas casillas de verificación: 'Libro de pedidos, Historial, Saldo al contado' e 'Información de pago'",
              3: () =>
                "Copie la clave API y el secreto e ingréselos en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente no admitimos pagos de financiación de futuros para la integración de CSV. Estamos trabajando para resolver este problema.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de HitBTC y navegue a la página de informes de HitBTC (https://hitbtc.com/reports/trades)",
              1: () =>
                "Para transacciones de billetera: seleccione 'Historial de transacciones' en 'Billetera'",
              2: () =>
                'Para operaciones al contado: seleccione "Mis operaciones" en "Spot"',
              3: () =>
                'Para operaciones con margen: seleccione "Mis operaciones" e "Intereses" en "Margen".',
              4: () =>
                "Haga clic en Exportar como .csv y descargue el archivo CSV",
              5: () =>
                "Sube tus archivos CSV aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    hodlnaut: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Para conocer pasos más detallados con capturas de pantalla, consulte la página de soporte de Hodlnaut en: https://www.hodlnaut.com/crypto-guides/hodlnaut-revamps-csv-format",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Una vez que haya iniciado sesión en Hodlnaut, navegue a 'Mi cuenta' en la barra de menú superior",
              1: () =>
                "Desplácese hacia abajo hasta 'Transacciones' y haga clic en el botón exportar en la esquina superior derecha",
              2: () => "Descargue el informe CSV en su dispositivo",
              3: () =>
                "Sube el CSV a la Calculadora de impuestos criptográficos usando el botón de importación.",
            },
          },
        },
      },
    },
    honeyswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente categorizamos automáticamente los swaps y agregamos o eliminamos liquidez en Honeyswap",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentra la dirección de la billetera Gnosis Chain que has utilizado para operar a través de Honeyswap",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    hotbit: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Como la opción de descarga del historial de exportación de Hotbit solo le permite exportar 30 días de operaciones a la vez, deberá comunicarse con su soporte y solicitar obtener un registro completo de su historial de operaciones.",
              1: () =>
                "El soporte de Hotbit debería enviarte un archivo .xlsx con los siguientes encabezados: 'time', 'user_id', 'market', 'side', 'role', 'price', 'amount', 'deal', 'fee', 'platform', 'stock', 'deal_stock'. Confirma que el archivo recibido tenga los encabezados anteriores, no modifiques ni vuelvas a guardar el archivo en el proceso",
              2: () => "Sube el archivo .xlsx aquí a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    huobi: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Huobi",
              1: () => "Vaya a la página de administración de API",
              2: () => "Ingrese una nota de su elección",
              3: () => 'Haga clic en el botón "Crear"',
              4: () => "Copiar tanto la clave de acceso como la clave privada",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Tendrá que ponerse en contacto con el soporte y solicitarles que le envíen por correo electrónico los archivos CSV 'Historial de transacciones', 'Historial de retiros' e 'Historial de depósitos'.",
              1: () =>
                "Estos pueden tardar hasta 5 días en enviarse desde Huobi.",
              2: () =>
                "Una vez que haya recibido estos archivos, puede cargarlos aquí en Crypto Tax Calculator",
            },
          },
        },
      },
    },
    hyperliquid: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente no podemos importar automáticamente las transacciones relacionadas con las recompensas por recomendación. Esto puede generar pequeñas discrepancias en el saldo. Para garantizar que sus registros sean precisos, le recomendamos que importe estas transacciones manualmente, si corresponde. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () => "Recompensas por recomendación",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Ingresa la dirección de tu billetera Hyper",
              1: () =>
                'Agregue un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    ice3x: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en ice3x",
              1: () =>
                "Vaya a cuenta > historial > pedidos https://ice3x.com/account/history",
              2: () => 'Haga clic en el botón "Exportar a Excel"',
              3: () => "Subir a la calculadora de impuestos de criptomonedas",
            },
          },
        },
      },
    },
    idex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en IDEX http://idex.market",
              1: () => "Vaya a Perfil > Dirección de billetera",
              2: () => "Copia la dirección de tu billetera IDEX",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en IDEX",
              1: () =>
                "Vaya a Órdenes > Historial de operaciones https://idex.market/trades",
              2: () => "Haga clic en 'Descargar historial comercial'",
              3: () =>
                "Subir CSV a la calculadora de impuestos de criptomonedas",
            },
          },
        },
      },
    },
    idt: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    immutable: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Immutable X en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
              1: () =>
                "Tenga en cuenta: debido a que los datos de Immutable X no son confiables, es posible que algunas billeteras no puedan importar transacciones.",
              2: () =>
                "Hasta que podamos asegurar una fuente de datos confiable, deberá importar estos datos manualmente utilizando nuestro método CSV personalizado.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    independentreserve: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Reserva Independiente",
              1: () =>
                'En la barra de navegación superior, navegue hasta la sección "configuración" (https://app.independentreserve.com/settings)',
              2: () => "Haga clic en la pestaña 'Claves API'",
              3: () =>
                'Haga clic en el botón "generar" para crear una nueva clave',
              4: () =>
                "Seleccione la opción 'Solo lectura (recomendado)' y agregue la descripción de la clave API como 'Calculadora de impuestos criptográficos'",
              5: () =>
                "Introduce tu contraseña y haz clic en el botón 'guardar'",
              6: () =>
                "Copie la 'Clave API' y el 'Secreto API' aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/4-HxdE3BdmU",
            title: () => "Cómo importar tu API de Reserva Independiente",
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Reserva Independiente",
              1: () =>
                "En la barra de navegación superior, navegue hasta el menú desplegable 'IMPUESTOS E INFORMES' y seleccione 'Informes'",
            },
          },
          1: {
            items: {
              0: () =>
                'En Informes, haga clic en el botón "Informe de historial de pedidos"',
              1: () =>
                "En la ventana emergente, seleccione el rango de fechas que cubre el historial completo de pedidos de la cuenta.",
              2: () =>
                "Seleccione la opción 'Descargar historial de pedidos como archivo CSV' y luego haga clic en 'descargar' para descargar su historial de pedidos CSV",
              3: () =>
                "Sube este archivo CSV 'OrderStatement' aquí en Crypto Tax Calculator",
            },
            title: () => "Para el historial de pedidos:",
          },
          2: {
            items: {
              0: () =>
                'En Informes, haga clic en el botón "Informe de historial de transacciones"',
              1: () =>
                "En la ventana emergente, seleccione el rango de fechas que cubre el historial completo de pedidos de la cuenta.",
              2: () =>
                "Seleccione la opción 'Descargar historial de transacciones como archivo CSV' y luego haga clic en 'descargar' para descargar su historial de transacciones CSV",
              3: () =>
                "Sube este archivo CSV 'Historial de transacciones' aquí en la Calculadora de impuestos criptográficos",
            },
            title: () => "Para depósitos/retiros:",
          },
        },
        video: {
          0: {
            items: {
              0: () =>
                "No extraeremos sus 'Operaciones' del archivo CSV 'Historial de transacciones' sino del archivo CSV 'Declaración de pedido' ya que es más preciso.",
              1: () =>
                "Actualmente, los únicos formatos de fecha y hora que admitimos son:",
              2: () => "'DD/MM/AAAA HH:mm' (p. ej. 02/12/2020 13:09)",
              3: () => "'DD MMM AAAA HH:mm:ss' (p. ej. 12 feb 2020 13:09:22)",
            },
            link: () => "https://youtu.be/Qaz6XSJ-jck",
            title: () =>
              "Cómo importar sus archivos CSV de Reserva Independiente",
          },
        },
      },
    },
    injective: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Injective en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    iotex: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "IoTeX está en desarrollo inicial",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera IoTeX en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    "jaxx-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Jaxx Liberty",
              1: () =>
                "Seleccione el menú de tres líneas (esquina superior derecha)",
            },
          },
          1: {
            items: {
              0: () => "Seleccionar Ver frase de respaldo",
              1: () =>
                "Marque la casilla Es seguro y haga clic en Ver frase de respaldo",
              2: () => "Vaya a https://iancoleman.io/bip39",
              3: () =>
                "Pegue la frase de respaldo de Jaxx en el campo Mnemónico BIP39",
              4: () => "Seleccionar moneda",
              5: () => "Seleccionar pestaña BIP44",
              6: () =>
                "Escriba '0' en Externo / Interno para obtener xPub para direcciones de recepción",
              7: () => "Copiar la clave pública extendida BIP32",
              8: () =>
                "Escriba '1' en Externo / Interno para obtener xPub para las direcciones de cambio",
              9: () => "Copiar clave pública extendida BIP32",
              10: () =>
                "Agregue la dirección xPub en CTC y haga clic en sincronizar",
            },
            title: () =>
              "Para billeteras de Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin y Zcash",
          },
          2: {
            items: {
              0: () => "Seleccione Mostrar claves privadas",
              1: () =>
                'Marque la casilla "Es seguro" y haga clic en Ver claves privadas',
              2: () =>
                "Seleccione la billetera de monedas correspondiente (se puede repetir para múltiples billeteras)",
              3: () =>
                "Copiar direcciones públicas. No copiar claves privadas.",
              4: () =>
                "Encuentra las direcciones relevantes para cada transacción",
              5: () => "Copiar cada dirección",
              6: () => "Pegar en CTC y hacer clic en sincronizar",
            },
            title: () => "Para todas las demás monedas",
          },
        },
      },
    },
    juno: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Juno en desarrollo temprano",
              1: () =>
                "Solo etiquetas de transferencias/participaciones (las demás aparecen como desconocidas)",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Juno en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    kaspa: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Enter your Kaspa wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    kava: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Actualmente estamos experimentando algunos problemas al recopilar tokens ERC-1155 y transacciones que no sean EVM, y estamos trabajando para encontrar una solución. Si mientras tanto tienes este problema, prueba nuestro proceso de carga manual de CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Tokens ERC-1155 y transacciones que no son EVM",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Kava EVM en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    kraken: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "La API de Kraken tiene un límite de velocidad estricto de aproximadamente 50 transacciones cada 6 segundos, lo que puede ralentizar la sincronización de grandes cantidades de transacciones. Para obtener más detalles, visite su [página de límite de velocidad de la API](https://www.kraken.com/features/api#api-call-rate-limit).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Kraken y vaya a Configuración > Seguridad > API. (https://pro.kraken.com/app/settings/api)",
              1: () => "Haga clic en “Crear clave API”",
              2: () =>
                "Asigne un nombre a su clave API y seleccione los permisos: “Consulta”, “Consulta de órdenes y transacciones cerradas” y “Consulta de entradas del libro mayor”.",
              3: () =>
                "Deje todos los demás permisos y configuraciones como están y haga clic en “Generar clave”.",
              4: () =>
                "Copie la 'Clave API' y la 'Clave privada' en Crypto Tax Calculator como 'Clave API' y 'Secreto'.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/2WAYzAyPOKQ",
            title: () => "Cómo importar tu API de Kraken",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente, los únicos formatos de fecha que admitimos para Kraken CSV son MM/DD/AAAA, AAAA-MM-DD, DD/MM/AA HH:mm, AAAA-MM-DD HH:mm:ss.SSS, AAAA-MM-DD HH:mm:ss y MM/DD/AAAA HH:mm.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inicie sesión en su cuenta de Kraken, vaya a la pestaña "Historial" y luego haga clic en "Exportar".',
              1: () =>
                "Seleccione “Libro mayor” para el tipo de exportación, elija su período comercial, marque “Seleccionar todo” y haga clic en “Enviar”.",
              2: () =>
                "Espere unos segundos, actualice si es necesario y descargue el CSV en un archivo .zip.",
              3: () =>
                "Descomprima el archivo y cargue los CSV en Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/X7v6RyNCe7A",
            title: () => "Cómo importar tus CSV de Kraken",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                'Haga clic en "Conectarse a Kraken" para ir a Kraken Exchange',
              1: () => "Inicie sesión en su cuenta de Kraken.",
              2: () =>
                "Si su cuenta utiliza una clave de acceso o autenticación de dos factores, ingrese el código requerido. De lo contrario, omita este paso.",
              3: () => 'Haga clic en el botón "Permitir"',
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/Hrn695jsmoA",
            title: () => "Cómo importar tu cuenta de Kraken a través de Oauth",
          },
        },
      },
    },
    kucoin: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "La API de KuCoin solo proporciona el historial de transacciones del último año. Utilice un archivo CSV para los datos más antiguos.",
              1: () =>
                "Actualmente, admitimos depósitos, retiros, operaciones al contado y con margen y datos históricos de futuros para KuCoin. Cabe señalar que los saldos pueden no ser correctos, ya que no obtenemos el historial de préstamos y préstamos con margen.",
              2: () =>
                "Kucoin ha desactivado recientemente su punto final de transacciones históricas. Mientras tanto, deberá agregar estos datos a Crypto Tax Calculator por separado utilizando la opción de importación CSV de CTC hasta que se resuelva este problema (https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import)",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en KuCoin y vaya a Gestión de API > Claves API (https://www.kucoin.com/account/api)",
              1: () =>
                "Haga clic en “Crear API” y seleccione “Trading basado en API”.",
              2: () => "Introduzca un nombre y una contraseña de su elección.",
              3: () =>
                "Habilite solo el permiso 'General'; deshabilite los demás.",
              4: () =>
                "Establezca la restricción de IP en 'No', haga clic en 'Siguiente' y complete la verificación de seguridad",
              5: () =>
                "Copie la frase de contraseña, la clave API y el secreto API y péguelos en la Calculadora de impuestos criptográficos",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/PtORZYAm-gc",
            title: () => "Cómo importar tu API de Kucoin",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () => "Iniciar sesión en KuCoin",
              1: () =>
                "Vaya a Órdenes > Historial de operaciones al contado > Historial de operaciones",
              2: () =>
                "Haga clic en 'haga clic aquí para ver las transacciones de la versión anterior'",
              3: () => 'Haga clic en "Exportar a CSV"',
              4: () =>
                "KuCoin solo permite exportar 100 días a la vez, así que seleccione intervalos de 100 días de su historial comercial de todos los tiempos",
              5: () =>
                "Una vez descargado el CSV, navegue a https://www.kucoin.com/account/download",
              6: () => "Haga clic en el icono de descarga",
            },
            title: () => "Instrucciones de importación CSV versión 1",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de KuCoin y vaya al Centro de pedidos. (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1)",
              1: () => 'Haga clic en "Exportar".',
              2: () =>
                "Seleccione el informe deseado: Historial de depósitos/retiros, Órdenes al contado, Órdenes de margen u Órdenes de futuros.",
              3: () =>
                "Elija el rango de fechas (hasta 12 meses por exportación).",
              4: () =>
                "Marque “Incluye subcuentas” si es necesario (solo para Spot, Margen y Futuros).",
              5: () =>
                "Seleccione '.csv' y haga clic en 'Confirmar' para descargar.",
              6: () =>
                "Sube los archivos relevantes a CTC: Historial de depósitos, Registro de retiros, Órdenes al contado - Órdenes completadas, Órdenes de margen - Órdenes completadas - Cruzadas y aisladas, Órdenes de margen - Préstamos - Cruzados y aislados y PnL realizado.",
            },
          },
          1: {
            items: {
              0: () =>
                "Siga los pasos en el Centro de ayuda de KuCoin (https://www.kucoin.com/support/14047696578841)",
              1: () =>
                "Inicie sesión en su cuenta de Kucoin y visite el Centro de pedidos (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1)",
              2: () => "Haga clic en el botón “Exportar”",
              3: () =>
                "Haga clic en el menú desplegable del mercado y seleccione Historial de depósitos/retiros, Órdenes al contado, Órdenes de margen, Órdenes de futuros",
              4: () =>
                "Seleccione el rango de fechas que desea descargar (Solo 12 meses por exportación)",
              5: () =>
                'Si necesita incluir datos de subcuentas, marque la opción "Incluye subcuentas". (Solo se admiten informes de Spot, Margen y Futuros para subcuentas).',
              6: () =>
                "Seleccione '.csv' y luego haga clic en 'confirmar' para descargar los archivos.",
              7: () =>
                "Sube los archivos necesarios a CTC. No todos los archivos descargados son útiles para CTC, los archivos relevantes incluyen: historial de depósitos, registro de retiros, órdenes al contado: órdenes completadas, órdenes de margen: órdenes completadas: cruzadas y aisladas, órdenes de margen: préstamos: cruzadas y aisladas, y pérdidas y ganancias realizadas.",
            },
            title: () => "Historial comercial",
          },
          2: {
            title: () => "Historial de depósitos y retiros",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/81gyRIU1HYM",
            title: () => "Cómo importar tu CSV de Kucoin",
          },
        },
      },
    },
    kujira: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Kujira en desarrollo temprano",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Kujira en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    kyber: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () => "Seleccione el intercambio del menú desplegable",
              3: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              4: () => "Mantener las demás configuraciones predeterminadas",
              5: () => 'Haga clic en "Recuperar historial"',
              6: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              7: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    kyve_network: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Kyve en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    latoken: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Necesitará ponerse en contacto con el soporte de LATOKEN para solicitar el informe del historial de transacciones",
              1: () =>
                "Una vez que hayas recibido esto puedes importarlo directamente a la plataforma.",
            },
          },
        },
      },
    },
    leather_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Haga clic en “conectar billetera” y siga las instrucciones.",
            },
          },
        },
      },
    },
    ledger_blue: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Conecte su Ledger a su dispositivo",
              1: () =>
                "Iniciar sesión en la aplicación de escritorio Ledger Live",
              2: () => "Abra la billetera Ledger para la moneda de su elección",
              3: () => "Seleccione la cuenta correspondiente (lado izquierdo)",
            },
          },
          1: {
            items: {
              0: () =>
                "Haga clic en el icono de llave inglesa (parte superior derecha)",
              1: () => "Haga clic en Registros avanzados",
              2: () =>
                "Copia la dirección de la billetera HD con el prefijo 'xpub' entre comillas",
              3: () => "Pegar en CTC",
            },
            title: () =>
              "Para billeteras de Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin y Zcash",
          },
          2: {
            items: {
              0: () =>
                "Ver la lista de transacciones y direcciones en la billetera",
              1: () => "Copiar cada dirección",
              2: () => "Pegar en CTC",
            },
            title: () => "Para todas las demás monedas",
          },
        },
      },
    },
    ledgerwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Es posible que falten transacciones en la exportación CSV de Ledger Live, lo que puede provocar que los saldos calculados por Crypto Tax Calculator sean incorrectos. Siempre que sea posible, recomendamos que sincronice cada una de las billeteras blockchain subyacentes directamente mediante la opción "Sincronizar mediante API".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Haga clic en Cuentas en el panel izquierdo.",
              1: () =>
                "Haga clic en el botón ⋯ Opciones en la esquina superior derecha.",
              2: () =>
                "Seleccione la opción Historial de operaciones de exportación.",
              3: () =>
                "Seleccione las cuentas que desea incluir en la ventana que aparece.",
              4: () => "Haga clic en Guardar.",
              5: () => "Guarde el archivo en una carpeta de su elección.",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Iniciar sesión en la aplicación de escritorio Ledger Live",
              1: () => "Abra la billetera Ledger para la moneda de su elección",
              2: () => "Seleccione la cuenta correspondiente (lado izquierdo)",
            },
          },
          1: {
            items: {
              0: () =>
                "Haga clic en el icono de llave inglesa (parte superior derecha)",
              1: () => "Haga clic en Registros avanzados",
              2: () =>
                "Copia la dirección de la billetera HD con el prefijo 'xpub' entre comillas",
              3: () => "Pegar en CTC",
            },
            title: () =>
              "Para billeteras de Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin y Zcash",
          },
          2: {
            items: {
              0: () =>
                "Ver la lista de transacciones y direcciones en la billetera",
              1: () => "Copiar cada dirección",
              2: () => "Pegar en CTC",
            },
            title: () => "Para todas las demás monedas",
          },
        },
      },
    },
    ledn: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Asegúrese de que su informe abarque todos los años en los que ha estado negociando criptomonedas.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Ledn en un navegador web",
              1: () =>
                "Vaya a la página 'Comercio' desde el menú de navegación de la izquierda.",
              2: () =>
                "Haga clic en 'Descargar CSV' para descargar sus datos comerciales",
              3: () =>
                "No abra ni vuelva a guardar el archivo antes de importarlo, ya que esto podría cambiar el formato del archivo CSV",
              4: () =>
                "Sube el archivo que acabas de descargar aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    linea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Linea en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    liqui: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a la sección de fondos/billetera en la esquina superior derecha",
              1: () =>
                "Seleccione el rango de fechas y exporte por correo electrónico (es posible que tenga que hacer esto varias veces para todas sus transacciones)",
              2: () =>
                "Una vez que llegue el correo electrónico, cargue el archivo recibido en Crypto Tax Calculator",
            },
          },
        },
      },
    },
    liquid: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Admitimos retiros al contado, de margen, infinitos, perpetuos y de criptomonedas.",
              1: () =>
                "Liquid API no proporciona información sobre depósitos de criptomonedas. Deberá agregarlos manualmente si desea realizar un seguimiento de ellos.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Liquid y abra el menú de Liquid haciendo clic en su ícono de usuario en la parte superior derecha",
              1: () =>
                "Vaya a Tokens API > Claves API https://app.liquid.com/settings/api-tokens",
              2: () => 'Haga clic en el botón "Crear token de API"',
              3: () =>
                "Seleccione los siguientes permisos de solo lectura: 'Cuentas', 'Ejecuciones', 'Órdenes', 'Posiciones', 'Cuentas de operaciones' y 'Retiros'",
              4: () =>
                "A pesar de que las instrucciones parecen indicar que se requiere la dirección IP, la clave API se puede generar sin necesidad de una. Deje este campo en blanco.",
              5: () => 'Haga clic en el botón "Crear token"',
              6: () =>
                "Copie el ID del token (clave API) y el secreto del token (secreto API) e ingréselos aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Liquid",
              1: () =>
                "Vaya a Monederos > Transacciones > Desplácese hacia abajo hasta Informe de descripción general",
              2: () =>
                "Asegúrese de que 'Seleccionar zona horaria' sea 'UTC+00:00' y asegúrese de que las fechas seleccionadas abarquen todos los intercambios",
              3: () =>
                'Haga clic en el botón "Enviar informe por correo electrónico"',
              4: () =>
                "Una vez que reciba el correo electrónico de Liquid, descargue el archivo CSV y cárguelo aquí en Crypto Tax Calculator",
            },
          },
        },
      },
    },
    localbitcoins: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de LocalBitcoins",
              1: () =>
                "En la esquina superior derecha de la barra de navegación, haga clic en el ícono de perfil y haga clic en 'Configuración' en el menú desplegable para navegar a su configuración (https://localbitcoins.com/settings)",
              2: () =>
                'Haga clic en la pestaña "Seguridad" y, luego, en "Datos personales", haga clic en "Ver".',
              3: () =>
                "Haga clic en el botón azul 'Recopilar datos personales' para comenzar a generar su historial comercial completo",
              4: () =>
                "Una vez que la descarga esté lista, haga clic en el botón azul 'Descargar datos personales' para descargar sus datos en un archivo .zip",
              5: () =>
                "Extraiga el contenido del archivo .zip y aísle los dos archivos llamados 'contacts.csv' y 'transaction_history.csv'",
              6: () =>
                "Por último, importe estos dos archivos aquí a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    lros: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Living Room of Satoshi (LROS)",
              1: () =>
                "En la barra de navegación superior, haga clic en 'Mi cuenta' para navegar a la página de facturas (https://www.livingroomofsatoshi.com/bills)",
              2: () =>
                "Descargue el archivo CSV que cubre su historial de pagos completo",
              3: () =>
                "Sube este CSV aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    ltc: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Busque la clave pública de la billetera Ltub para su billetera Litecoin. Para encontrarla, (por ejemplo) vaya a Ledger Live e ingrese a su cuenta Litecoin. Haga clic en el ícono de llave inglesa. Expanda "Registros avanzados" en "Editar cuenta". Copie la dirección que comienza con "Ltub".',
              1: () =>
                "Una vez encontrada, copie esta clave pública y diríjase a un convertidor Xpub como https://jlopp.github.io/xpub-converter/ para convertir su dirección 'Ltub' en una dirección 'Xpub'.",
              2: () =>
                "Copie y pegue la dirección 'Xpub' convertida en el campo de importación de la Calculadora de impuestos de criptomonedas (a la izquierda) y haga clic en 'Agregar billetera' para sincronizar sus transacciones.",
            },
          },
        },
      },
    },
    luno: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Luno (https://www.luno.com/en/exchange)",
              1: () =>
                "En la esquina superior derecha, haga clic en Perfil y navegue hasta Seguridad - > Claves API",
              2: () =>
                'Cree la clave API y asegúrese de que el permiso de la clave sea "Acceso de solo lectura"',
              3: () =>
                "Asegúrese de autorizar la clave API siguiendo el enlace que le enviamos por SMS o correo electrónico antes de usarla.",
              4: () =>
                "Ingrese aquí tanto la 'Clave API' como el 'Secreto API' en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Luno (https://www.luno.com/en/exchange)",
              1: () =>
                "Descargue la exportación CSV de sus transacciones yendo a sus Billeteras - > Billetera BTC (por ejemplo) - > Transacciones - > Descargar CSV",
              2: () => "Repita esto para cada una de sus billeteras en Luno",
              3: () =>
                "Ahora puedes cargar los archivos CSV en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    lykke: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Lykke y en la barra de navegación en la parte superior de la pantalla, haga clic en 'FONDOS'",
              1: () =>
                "Una vez en la página de Fondos, haga clic en el botón 'Historial'",
              2: () =>
                'Asegúrate de tener seleccionado "Todos" para poder ver depósitos, retiros y operaciones.',
              3: () =>
                "Haga clic en el botón 'EXPORTAR A CSV' para descargar su CSV",
              4: () =>
                "Sube el CSV aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    "manta-pacific": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
          1: {
            items: {
              0: () =>
                "Es posible que falten algunas transacciones internas en algunas billeteras.",
            },
            title: () => "Transacciones internas faltantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Manta Pacific en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    mantle: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Mantle (que comienza con 0x) en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    mercatox: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Mercatox",
              1: () =>
                "Vaya a Monedero > Historial de transacciones https://mercatox.com/wallet/transactions",
              2: () =>
                "Haga clic en el botón 'Descargar CSV' antes de cargarlo en la Calculadora de impuestos de criptomonedas",
            },
          },
        },
      },
    },
    metamask: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Haga clic en “conectar billetera” y siga las instrucciones.",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abra su billetera MetaMask",
              1: () =>
                "Para cada cuenta asociada con MetaMask, seleccione la cuenta y pase el cursor sobre el nombre de la cuenta.",
              2: () =>
                "Copia la dirección de la billetera pública que comienza con '0x...'",
              3: () =>
                'Pegue la dirección de billetera pública copiada en CTC y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    metis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Metis en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    mode: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Copia la dirección de tu billetera pública Mode comenzando con '0x...'",
              1: () => "Pegue la dirección de su billetera pública en CTC",
              2: () =>
                'Proporcione un apodo y haga clic en "Importar billetera"',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera de modo adicional"',
            },
          },
        },
      },
    },
    moonbeam: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
          1: {
            items: {
              0: () =>
                "Actualmente no podemos obtener tus recompensas de Moonbeam. Estamos trabajando activamente para encontrar una solución. Mientras tanto, puedes agregar tus recompensas a través de nuestro proceso de importación manual de CSV.",
            },
            title: () => "Recompensas faltantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Moonbeam en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    moonriver: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Moonriver en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
      },
    },
    mudrex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Abra la aplicación móvil Mudrex e inicie sesión en su cuenta.",
              1: () =>
                "Toca el ícono de tu perfil en la esquina superior izquierda (muestra tus iniciales o un emoji).",
              2: () =>
                "Desplácese hacia abajo en la sección de perfil y seleccione “Declaración de transacción” en Informes y declaraciones.",
              3: () => "Seleccione el año fiscal",
              4: () =>
                "Pulsa en “Solicitar estado de cuenta” y recibirás un correo electrónico con el estado de cuenta.",
              5: () =>
                "Sube el archivo a la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
    },
    multichain: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName + " .",
              1: () =>
                "Seleccione la criptomoneda que desea importar. Por ejemplo, Bitcoin o Ethereum.",
              2: () =>
                'Copia la dirección de la billetera pública correspondiente a ese activo. Para encontrarla, es posible que tengas que hacer clic en "Recibir".',
              3: () =>
                "En CTC, elija la red blockchain correspondiente en el menú desplegable.",
              4: () =>
                'Pegue la dirección de su billetera pública en CTC y haga clic en "Importar billetera".',
            },
          },
        },
      },
    },
    mxc: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                'Actualmente, la API de MEXC solo brinda acceso al historial de transacciones de los últimos 30 días. Para importar todo el historial de transacciones, utilice el método de importación "cargar archivo" de MEXC',
            },
            title: () => "Limitaciones de la API de MEXC.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Las claves API generadas desde MEXC solo son válidas durante 90 días. Después de este tiempo, será necesario crear y agregar una nueva clave.",
              1: () =>
                "La API de MEXC NO da acceso a depósitos/retiros/airdrops, etc., estos deberán agregarse manualmente.",
              2: () =>
                "Actualmente NO admitimos operaciones con derivados o márgenes a través de MEXC.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta MEXC",
              1: () =>
                "En la parte superior derecha de la pantalla, pase el cursor sobre la imagen de la cuenta y seleccione 'Administración de API' en la lista desplegable (https://www.mexc.com/user/openapi)",
              2: () =>
                "En el encabezado 'Spot', seleccione 'Cuenta: Ver detalles de la cuenta' y 'Operación: Ver detalles de la orden'",
              3: () =>
                "En las Notas (Obligatorio), proporcione un apodo para la clave API, como 'Calculadora de impuestos criptográficos'",
              4: () =>
                "Marque la casilla 'He leído y acepto los recordatorios de riesgo para usuarios de API' y haga clic en el botón 'Crear'",
              5: () =>
                "Copie la 'Clave de acceso' y la 'Clave secreta' e ingréselas en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de MEXC. Hay cuatro archivos diferentes que necesitamos según su historial de operaciones",
              1: () =>
                'Para obtener datos sobre operaciones de futuros: en la barra de menú superior, haga clic en "Órdenes" y seleccione "Órdenes de futuros".',
              2: () =>
                "Seleccione 'Historial de órdenes y transacciones' y haga clic en el botón 'Exportar historial de órdenes' (https://futures.mexc.com/orders/contract?type=historic)",
              3: () =>
                "En la ventana emergente, seleccione el rango de tiempo que desea descargar, seleccione 'EXCEL' para el formato de exportación y presione el botón 'Exportar' para exportar sus registros de transacciones.",
              4: () =>
                'Para obtener datos de operaciones al contado: en la barra de menú superior, haga clic en "Órdenes" y seleccione "Órdenes al contado".',
              5: () =>
                "Seleccione 'Historial comercial' y haga clic en el botón 'Exportar' (https://www.mexc.com/orders/spot?tab=deal)",
              6: () =>
                "En la ventana emergente, seleccione el rango de tiempo que desea descargar y presione el botón 'Generar' para exportar sus registros de transacciones.",
              7: () =>
                'Para obtener datos del historial de depósitos: en la barra de menú superior, haga clic en "Billeteras" y seleccione "Historial de fondos"',
              8: () =>
                "Seleccione ‘Depositar’ y haga clic en el botón ‘Exportar’ (https://www.mexc.com/assets/record)",
              9: () =>
                "En la ventana emergente, seleccione el rango de tiempo que desea descargar, desmarque 'Encriptar el archivo' y presione el botón 'Generar' para exportar sus registros de depósito.",
              10: () =>
                'Para obtener datos del historial de retiros: en la barra de menú superior, haga clic en "Billeteras" y seleccione "Historial de fondos"',
              11: () =>
                "Seleccione ‘Retiro’ y haga clic en el botón ‘Exportar’ (https://www.mexc.com/assets/record)",
              12: () =>
                "En la ventana emergente, seleccione el rango de tiempo que desea descargar, desmarque “Cifrar el archivo” y presione el botón “Generar” para exportar sus registros de retiro.",
              13: () =>
                "Sube todos los archivos descargados aquí a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    myEtherWallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Para cada cuenta asociada a MEW:",
              1: () =>
                'Busque la dirección pública de la cadena de bloques Ethereum de la cuenta y cópiela en el portapapeles. Sugerencia: si está utilizando la aplicación móvil MEW, puede encontrar estos detalles en "Administrar cuentas" en la aplicación móvil MEW.',
              2: () =>
                'Pegue la dirección de la billetera Ethereum copiada en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    myTonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puede ingresar su dirección con formato rebotable, no rebotable, TON DNS o sin procesar y la traduciremos al formato sin procesar para usted.",
            },
            title: () => "Tipos de direcciones",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre y copie la dirección de su billetera TON desde la extensión MyTonWallet",
              1: () =>
                "Ingresa la dirección de tu billetera en la Calculadora de impuestos de criptomonedas",
              2: () =>
                'Proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    ndax: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en NDAX",
              1: () =>
                "En el lado izquierdo, haga clic en informes y exporte su CSV 'Libro mayor'",
              2: () =>
                "Luego puedes cargar esto en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    near: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Próximamente se admitirán transacciones NFT",
            },
            title: () => "No se admiten todas las operaciones",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Near en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    neo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Nuestro proveedor de datos para esta cadena de bloques no está disponible en este momento. Estamos investigando un nuevo proveedor; sin embargo, le sugerimos que importe sus datos utilizando nuestra plantilla CSV personalizada por ahora. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () =>
              "Nuestra integración de Neo no está disponible actualmente.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Tenga en cuenta: debido al GAS no reclamado, puede haber una discrepancia entre los saldos de GAS calculados e informados.",
            },
            title: () => "Discrepancias de equilibrio",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () => "Copia la dirección de tu billetera pública NEO",
              2: () =>
                "En CTC, elija la cadena de bloques relevante del menú desplegable",
              3: () =>
                'Pegue la dirección de su billetera pública y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    netcoins: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nuestra integración de Netcoins se encuentra actualmente en versión Beta. Si tiene algún problema, comuníquese con nuestro soporte con su archivo CSV de Netcoins.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Netcoins",
              1: () =>
                "En su página de saldos, desplácese hacia abajo hasta la sección Transacciones",
              2: () =>
                'Haga clic en el botón "exportar" en la parte superior de la tabla de transacciones para descargar una exportación CSV de sus transacciones',
              3: () =>
                "Sube este CSV aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    neutron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Neutron en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    newton: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Newton en un navegador web",
              1: () =>
                'Vaya al botón "Historial completo" que se encuentra junto al encabezado "Actividad reciente". Esto lo llevará a la página "Historial de la cuenta".',
              2: () => "Haga clic en el botón 'Exportar historial de cuenta'",
              3: () =>
                'Exporte el "Informe para AAAA", donde AAAA es el año que desea exportar (por ejemplo, 2020). Esto descargará un archivo CSV con un nombre con el formato "account-history-AAAA-Firstname-Lastname.csv"',
              4: () =>
                "Sube el archivo que acabas de descargar aquí a la Calculadora de impuestos sobre criptomonedas",
              5: () =>
                "Asegúrese de repetir este proceso para todos los años en los que haya informes disponibles para descargar.",
            },
          },
        },
      },
    },
    nexo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'Tenga en cuenta que las transacciones de "venta" correspondientes a cada "compra" deberán agregarse manualmente, ya que el lado de "venta" del intercambio no está disponible en los datos. Puede hacerlo en la página "Revisar transacciones".',
              1: () =>
                "Asegúrese de que la fecha y la hora estén en el formato 'AAAA-MM-DD HH:mm:ss' o 'MM/DD/AAAA HH:mm'",
              2: () =>
                "Nota: Es posible que algunas filas CSV no se importen porque no representan el historial de transacciones, sino los registros internos de Nexo. Por ejemplo, una fila de intereses con un monto negativo en USD representa un registro de intereses a pagar y no una transacción real.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Nexo y haga clic en la pestaña de transacciones (https://platform.nexo.io/transactions)",
              1: () =>
                "Seleccione el rango de fechas que cubra todo su historial de transacciones",
              2: () =>
                "Mantenga los tipos de transacciones y activos predeterminados como 'Todos'",
              3: () =>
                "Exportar el CSV e importarlo a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    nftbank: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Los CSV en la carpeta 'only_symbol' no incluyen sus identificaciones de NFT, solo el símbolo",
              1: () =>
                "No cargue los CSV desde la carpeta 'only_symbol' si ya ha cargado el CSV correspondiente desde la carpeta raíz o viceversa",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de NFTBank en un navegador web",
              1: () =>
                "Vaya a su Cartera y haga clic en 'Presentación de impuestos'",
              2: () =>
                "Seleccione la opción 'Calculadora de impuestos criptográficos' y las billeteras que desea exportar",
              3: () =>
                "Confirme su dirección de correo electrónico y haga clic en 'Enviar'",
              4: () =>
                "Descargue el archivo zip que contiene los archivos CSV enviados a su correo electrónico",
              5: () =>
                "Sube aquí los archivos CSV relevantes contenidos en el archivo zip (no el archivo zip en sí)",
            },
          },
        },
      },
    },
    nicehash: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "El ID de la organización se puede encontrar en la página de claves API y no se proporcionará con la clave y el secreto.",
              1: () =>
                'Es posible que falten las tarifas de minería en transacciones antiguas, lo que puede provocar imprecisiones en los saldos calculados. Si experimenta este problema, puede importar mediante el método "Cargar archivo", que generalmente incluye estas tarifas.',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de NiceHash",
              1: () =>
                'En la esquina superior derecha de la barra de navegación, haga clic en el ícono de su perfil y luego haga clic en el botón naranja "Mi configuración" para navegar a la página de configuración (https://www.nicehash.com/my/settings/)',
              2: () =>
                "En el panel de navegación de la izquierda, haga clic en 'Claves API' (https://www.nicehash.com/my/settings/keys)",
              3: () =>
                "Haga clic en 'Crear nueva clave API' para abrir el cuadro de diálogo de creación de clave API",
              4: () =>
                "En 'Permisos de billetera', permita el permiso para 'Ver saldos, actividades de billetera y direcciones de depósito'",
              5: () =>
                "En 'Permisos del mercado', permita el permiso para 'Ver órdenes de hashpower'",
              6: () =>
                "En 'Permisos de intercambio', permita el permiso para 'Ver órdenes de intercambio'",
              7: () =>
                "En 'Permisos de minería', permita el permiso para 'Ver datos y estadísticas de minería'",
              8: () =>
                "Se le solicitará que proporcione alguna autenticación adicional.",
              9: () =>
                "Copie la clave API, el secreto y el ID de la organización de su cuenta y péguelos aquí en Crypto Tax Calculator como 'Clave API', 'Secreto' y 'UID' respectivamente",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Será necesario generar informes separados para cada criptomoneda para la que tenga actividad histórica. Esto se puede hacer siguiendo los pasos anteriores para cada una de las diferentes monedas.",
              1: () =>
                "La calculadora de impuestos criptográficos deduce cualquier tarifa de minería directamente del monto obtenido (de la minería) al momento de recibir el pago",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de NiceHash",
              1: () =>
                'En la esquina superior derecha de la barra de navegación, haga clic en el ícono de su perfil y luego haga clic en el botón naranja "Mi configuración" para navegar a la página de configuración (https://www.nicehash.com/my/settings/)',
              2: () =>
                'En el panel de navegación de la izquierda, haga clic en "Informes" (https://www.nicehash.com/my/settings/reports)',
              3: () =>
                'Haga clic en "Nuevo informe" para abrir el cuadro de diálogo de creación de informes.',
              4: () =>
                'Ahora deberá seleccionar la criptomoneda para el informe, así como la moneda "FIAT". Asegúrese de que la moneda "FIAT" sea su moneda local y la misma que tiene en la Calculadora de impuestos sobre criptomonedas. Asegúrese de que la "Agregación horaria" sea "NINGUNA". Seleccione el rango de fechas desde/hasta que incluya toda su actividad. Asegúrese de que la zona horaria sea GMT.',
              5: () =>
                'Una vez completado el formulario, haga clic en "Crear" para generar el informe. El informe tardará un tiempo en procesarse. Una vez procesado, el estado pasará a ser "listo".',
              6: () =>
                'Descargue el informe haciendo clic en los tres puntos que se encuentran debajo de "Acción" y seleccione "Descargar informe" en el menú desplegable. Esto descargará el informe como un archivo CSV.',
              7: () =>
                "Ahora puedes cargar este CSV en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    nolus: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Nolus en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    oasisdex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () => "Seleccione el intercambio del menú desplegable",
              3: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              4: () => "Mantener las demás configuraciones predeterminadas",
              5: () => 'Haga clic en "Recuperar historial"',
              6: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              7: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    oceanex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                'Coloque el cursor sobre "fondos" en la parte superior de la página.',
              1: () =>
                "Desde el menú desplegable, seleccione historial comercial",
              2: () => "Exportar resultados de búsqueda",
              3: () =>
                "Sube todos los archivos que descargaste a Crypto Tax Calculator. Asegúrate de tener todas tus transacciones",
            },
          },
        },
      },
    },
    okex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente, no se admiten operaciones con margen ni con futuros. Estamos trabajando para agregar compatibilidad con estas operaciones.",
              1: () =>
                "OKX solo permite exportar datos del historial de transacciones de los últimos 3 meses desde su API. Si tienes transacciones anteriores, también tendrás que cargar un archivo CSV con las transacciones anteriores.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en OKX",
              1: () => "Vaya al menú API (https://www.okx.com/account/my-api)",
              2: () => 'Haga clic en "Crear clave API V5"',
              3: () =>
                'Ingrese un nombre y una contraseña, deje el campo de dirección IP en blanco y marque solo la casilla "Leer" debajo de los permisos.',
              4: () =>
                "Verifique su cuenta con un código de correo electrónico y un código de autenticador 2FA, y haga clic en confirmar",
              5: () =>
                "Ingresa la clave API, el secreto y la frase de contraseña que creaste aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/ZOf5ZjUYsEQ",
            title: () => "Cómo importar tu API de OKX",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente, solo admitimos archivos CSV del tipo de cuenta “Unificado”.",
              1: () =>
                "Actualmente, no se admiten operaciones con opciones. Estamos trabajando para agregar compatibilidad con estas operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en OKX",
              1: () =>
                "Vaya al centro de informes (https://www.okx.com/balance/report-center/)",
              2: () =>
                "Para las transacciones, en “Historial de transacciones”, haga clic en descargar, seleccione “Todos” como instrumento y configure el rango de fechas de manera adecuada para cubrir todas sus transacciones.",
              3: () =>
                "Sube el archivo CSV 'Historial de operaciones' aquí en la Calculadora de impuestos sobre criptomonedas",
              4: () =>
                "Para depósitos y retiros, en 'Factura de financiación', haga clic en descargar, seleccione 'Financiación' como tipo de cuenta y configure el rango de fechas de manera adecuada para cubrir todas sus transacciones.",
              5: () =>
                "Cargue aquí el archivo CSV 'Historial de cuenta de financiación' en la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/HpNmKgPLu3k",
            title: () => "Cómo importar sus archivos CSV de OKX",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () => "Click 'Connect to OKX' to open a new tab.",
              1: () => "Log in to your OKX account.",
              2: () => "Click 'Approve' button",
            },
          },
        },
      },
    },
    openMask: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puede ingresar su dirección con formato rebotable, no rebotable, TON DNS o sin procesar y la traduciremos al formato sin procesar para usted.",
            },
            title: () => "Tipos de direcciones",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre y copie la dirección de su billetera TON dentro de la extensión OpenMask",
              1: () =>
                "Ingresa la dirección de tu billetera en la Calculadora de impuestos de criptomonedas",
              2: () =>
                'Proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    opensea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "In OpenSea, navigate to your profile",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the copied public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    optimism: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Encuentra la dirección de la billetera que usaste para operar a través de Optimism (comenzando con 0x)",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    osmosis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Osmosis en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    pancakeSwap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente categorizamos automáticamente las transacciones de Exchange y Liquidez en PancakeSwap",
              1: () =>
                "Si ha realizado agricultura, pools o IFO, sus transacciones se importarán, pero es posible que deba revisarlas y volver a categorizarlas adecuadamente.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentra la dirección de la billetera Binance Smart Chain que has utilizado para operar a través de PancakeSwap",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    paradex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    phantom: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de la billetera blockchain de Solana que ha utilizado para operar a través de Phantom",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    phemex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "La API de Phemex solo admite 3 meses de datos históricos, por lo que en su lugar utilizamos el CSV",
              1: () =>
                "Actualmente solo admitimos operaciones al contado, depósitos/retiros y CSV de PNL cerrados.",
            },
          },
          1: {
            items: {
              0: () =>
                'Inicie sesión en Phemex y pase el cursor sobre el menú "Activos" en la esquina superior derecha',
              1: () =>
                "En el menú desplegable, haga clic en 'Historial de spot' y luego en 'Historial de pedidos' (https://phemex.com/assets/spot-order-history)",
              2: () =>
                'Luego puede hacer clic en "Exportar historial de órdenes al contado" a la derecha y seleccionar el período de tiempo en el que ha negociado.',
              3: () =>
                "Solo puedes seleccionar 3 meses a la vez, por lo que si has usado la plataforma por más tiempo tendrás que descargarla para cada mes.",
              4: () =>
                "Asegúrese de haber descargado todo su historial de pedidos y luego cargue los archivos en Crypto Tax Calculator",
            },
            title: () => "Operaciones al contado:",
          },
          2: {
            items: {
              0: () =>
                'Inicie sesión en Phemex y pase el cursor sobre el menú "Activos" en la esquina superior derecha',
              1: () =>
                'En el menú desplegable, haga clic en el botón "Depositar"',
              2: () =>
                'Desplácese hasta la parte inferior de la página y haga clic en "Ver todo" en la tabla Historial reciente que lo llevará a la página de depósito (https://phemex.com/assets/details?tab=Deposit)',
              3: () => 'Haga clic en "Exportar historial de depósitos"',
              4: () =>
                "Ahora puede hacer clic en la pestaña 'Retirar' (https://phemex.com/assets/details?tab=Withdrawal) y también descargar estos datos",
              5: () =>
                "Nuevamente, solo tiene la opción de descargar 3 meses de datos a la vez. Asegúrese de haber descargado todo el historial de depósitos y retiros y luego cargue todos los archivos en Crypto Tax Calculator",
            },
            title: () => "Depósitos/Retiros:",
          },
          3: {
            items: {
              0: () =>
                'Inicie sesión en Phemex y pase el cursor sobre el menú "Activos" en la esquina superior derecha',
              1: () =>
                "En el menú desplegable, haga clic en “Historial del contrato” y luego en “Detalles de PNL” (https://phemex.com/assets/contract-pnl-details)",
              2: () =>
                'Desplácese hasta la parte inferior de la página y haga clic en "Ver todo" en la tabla Historial reciente que lo llevará a la página de depósito (https://phemex.com/assets/details?tab=Deposit)',
              3: () => "Haga clic en 'PNL cerrado' de las opciones disponibles",
              4: () =>
                'Luego puede hacer clic en "Exportar historial de PNL cerrado" a la derecha y seleccionar el período de tiempo en el que ha negociado.',
              5: () =>
                "Nuevamente, solo tiene la opción de descargar 3 meses de datos a la vez. Asegúrese de haber descargado todo el historial de pérdidas y ganancias cerrado y luego cargue todos los archivos en Crypto Tax Calculator",
            },
            title: () => "PNL cerrado:",
          },
        },
      },
    },
    pionex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a la pestaña Historial de transacciones en el lado izquierdo",
              1: () =>
                "Seleccione el período de tiempo del historial de transacciones que desea descargar y haga clic en enviar",
              2: () =>
                "Una vez que los archivos estén listos, recibirá un correo electrónico de Pionex una vez que el archivo esté listo para descargarse.",
              3: () =>
                "Regrese a su cuenta de Pionex > Historial de transacciones para descargar el archivo",
              4: () =>
                "Descomprima los archivos descargados e importe 'trading.csv' y 'depositwithdraw.csv' en CTC",
              5: () =>
                "Si tiene otros archivos de transacciones, impórtelos utilizando nuestro CSV personalizado",
              6: () => "Nota: Hay un límite de 3 exportaciones por mes.",
            },
          },
        },
      },
    },
    polkadot: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
          1: {
            items: {
              0: () =>
                "Actualmente no se admiten transferencias entre cadenas de IBC",
            },
            title: () => "Transacciones faltantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Polkadot (comenzando con 1) en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    polkadotjs: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abra su billetera Polkadot.js",
              1: () =>
                "Haga clic en el icono de configuración en la parte superior derecha.",
              2: () =>
                "Seleccione 'Mostrar formato de dirección para' y elija la red deseada",
              3: () =>
                "Regrese a la vista de sus cuentas y haga clic en el ícono de copiar al portapapeles junto a la cuenta deseada",
              4: () =>
                "En la Calculadora de impuestos criptográficos, seleccione la red correspondiente",
              5: () => 'Pegue la dirección y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    polkagate: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abre tu billetera Polkagate",
              1: () =>
                "Haga clic en el menú de 3 puntos junto a la cuenta que desea importar",
              2: () =>
                "Seleccione la red deseada en el menú desplegable 'cadena'",
              3: () =>
                "Regrese a la vista de sus cuentas y haga clic en el ícono de copiar al portapapeles junto a la cuenta deseada",
              4: () =>
                "En la Calculadora de impuestos criptográficos, seleccione la red correspondiente",
              5: () => 'Pegue la dirección y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    poloniex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Tenga en cuenta que, en este momento, la API de Poloniex solo sincronizará las transferencias que se realicen a partir del 22 de enero de 2020 y las transacciones que se realicen a partir del 30 de julio de 2021 (https://docs.poloniex.com/#authenticated-endpoints-trades).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Poloniex y haga clic en la pestaña 'Configuración' en la barra de navegación",
              1: () =>
                "Vaya a la página 'Claves API' (https://poloniex.com/apiKeys)",
              2: () => 'Haga clic en el botón "Crear nueva clave"',
              3: () =>
                "Si corresponde, ingrese su código de autenticación de dos factores o instrucciones por correo electrónico",
              4: () =>
                "Una vez que reciba un correo electrónico de verificación de Poloniex, confirme su nueva clave API",
              5: () =>
                "Para su nueva clave API, desmarque las casillas de verificación 'Habilitar comercio' y 'Habilitar retiros'",
              6: () =>
                "Establezca la restricción de acceso IP en Sin restricciones",
              7: () => "Copiar tanto la clave API como el secreto API",
              8: () =>
                "Pegue la 'Clave API' y el 'Secreto API' copiados aquí en Crypto Tax Calculator y haga clic en 'Agregar API'",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Importe todas sus 'Actividades' relevantes (por ejemplo, depósitos, retiros, transacciones, etc.) para las que existan datos para que podamos categorizar correctamente sus transacciones.",
              1: () =>
                'En este momento no admitimos importaciones automáticas para los tipos de actividad "Préstamo de margen" o "Préstamo".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en Poloniex y haga clic en la pestaña 'Actividad' en la barra de navegación",
              1: () =>
                "Esto debería llevarlo a la página del historial de 'Billeteras' (https://poloniex.com/activity/wallet)",
              2: () =>
                "En la parte superior derecha de la pantalla, debajo de la barra de navegación, haga clic en el botón 'Exportar'",
              3: () =>
                'En Actividad de exportación, seleccione su "Tipo de actividad". Actualmente admitimos lo siguiente: "Depósitos", "Retiros", "Operaciones", "Distribuciones".',
              4: () =>
                "Una vez que haya seleccionado su tipo de actividad, seleccione el rango de fechas que cubra todo su historial.",
              5: () =>
                'Haga clic en el botón "Descargar". Debería recibir un archivo CSV llamado "[activityType]-[selectedDateRange].csv", donde [activityType] es el tipo de actividad seleccionado y [selectedDateRange] es el rango de fechas seleccionado.',
              6: () =>
                "Importe los CSV aquí a Crypto Tax Calculator sin volver a guardarlos ni abrirlos (esto puede cambiar el formato en algunas computadoras)",
            },
          },
        },
      },
    },
    polygon: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
              1: () => "Los saldos pueden retrasarse hasta un par de horas.",
            },
          },
          1: {
            items: {
              0: () =>
                "Ahora admitimos tokens ERC-1155, pero no podemos importar los saldos actuales de estos tokens. Esto puede generar discrepancias entre el saldo importado desde la API de origen y el saldo calculado por la aplicación.",
            },
            title: () => "Fichas ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Polygon (Matic) en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    "polygon-zkevm": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Polygon Hermez (zkEVM) en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    pooltool: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Como PoolTool no guarda ninguno de sus activos, no habrá tenencias de criptomonedas directamente asociadas con esta importación. En cambio, las recompensas de staking están asociadas con su dirección de staking de bech32.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Vaya a (https://pooltool.io)",
              1: () =>
                'En la parte superior izquierda, haga clic en "datos de recompensas por impuestos"',
              2: () => "Pegue su dirección de recompensa por staking",
              3: () =>
                "En la parte inferior izquierda, seleccione: formato - genérico; moneda - dólar estadounidense; período - el año para el cual desea exportar sus datos de staking",
              4: () =>
                'Haga clic en "Descargar datos" para descargar sus datos como un archivo CSV',
              5: () =>
                "Ingrese su dirección de participación en bech32 y luego cargue este archivo CSV aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    probit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "La sincronización de API de Probit solo brinda acceso a su historial de operaciones. Debe importar manualmente sus depósitos y retiros de Probit a Crypto Tax Calculator por separado. Puede hacerlo a través de nuestra plantilla CSV personalizada avanzada: https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta Probit",
              1: () =>
                'En la parte superior derecha de la barra de navegación, pase el cursor sobre "Mi página" y haga clic en "Administración de API" en el menú desplegable (https://www.probit.com/en-us/user-center/api-management/api-credential)',
              2: () =>
                'Asegúrese de que la pestaña "Administración de API" esté resaltada e ingrese "Calculadora de impuestos criptográficos" como "Etiqueta de clave API" y haga clic en "Crear nueva clave".',
              3: () =>
                "Se le solicitará que proporcione alguna autenticación adicional.",
              4: () =>
                "Copie y guarde el 'ID del cliente' y la 'Clave secreta del cliente' en algún lugar seguro",
              5: () =>
                ' Una vez autenticado, DEBE editar los permisos de la clave API: haga clic en el botón "Editar" y luego desmarque la casilla "Comercio" y luego haga clic en "Edición completa".',
              6: () =>
                "IMPORTANTE: Asegúrese de que la única casilla de verificación que esté marcada sea 'Leer (predeterminado)'",
              7: () =>
                "Ingrese el 'ID del cliente' como la clave API y la 'Clave secreta del cliente' como el secreto aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    protocol: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Abra la billetera que utilizó para interactuar con " +
                d.exchangeDisplayName,
              1: () => "Copia la dirección de tu billetera pública",
              2: () =>
                "En CTC, elija la cadena de bloques relevante del menú desplegable",
              3: () =>
                'Pegue la dirección de su billetera pública y haga clic en "Importar billetera"',
              4: (d) =>
                "CTC importará todas las transacciones del menú desplegable, incluidas aquellas asociadas con " +
                d.exchangeDisplayName,
            },
          },
        },
      },
    },
    pulsechain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
              1: () =>
                "La integración se encuentra actualmente en versión beta, por lo que puede fallar periódicamente. Si esto ocurre, vuelva a intentarlo después de un tiempo.",
            },
          },
          1: {
            items: {
              0: () => "No admitimos transacciones ERC-1155 en este momento.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera PulseChain en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    quantfury: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "En esta etapa, deberá comunicarse con el soporte de Quantfury y solicitar el archivo .xlsx del historial comercial.",
              1: () =>
                "El formato debe ser: 'Nombre, Acción, Cantidad, Precio, Valor, Fecha, Posición Total'.",
              2: () =>
                "Una vez que haya recibido este formato, deberá abrirlo y exportarlo en formato CSV.",
              3: () =>
                "También deberá eliminar cualquier transacción que no sea criptográfica, ya que no las manejamos.",
              4: () =>
                "Estamos trabajando con Quantfury para mejorar el soporte para esto",
            },
          },
        },
      },
    },
    quickswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente categorizamos automáticamente los swaps y agregamos o eliminamos liquidez en QuickSwap",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de la billetera Polygon (Matic) que ha utilizado para operar a través de QuickSwap",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    "rabby-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Haga clic en la extensión Rabby en su navegador",
              1: () =>
                "Para cada cuenta asociada con Rabby, seleccione la cuenta y haga clic en el ícono de copiar al portapapeles",
              2: () =>
                'Pegue la dirección de billetera pública copiada (para la red especificada) en Crypto Tax Calculator, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    radarRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    "rainbow-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Haga clic en la extensión Rainbow Wallet en su navegador",
              1: () =>
                "Para cada cuenta asociada con Rainbow Wallet, seleccione la cuenta y haga clic en el ícono de copia",
              2: () =>
                'Pegue la dirección de billetera pública copiada en CTC y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    relaypay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de RelayPay",
              1: () =>
                "Haga clic en su nombre de usuario en la esquina superior derecha y seleccione 'Mi perfil' (https://relaypay.io/profile)",
              2: () => 'Vaya a la pestaña "Comprar criptomonedas"',
              3: () => 'Haga clic en "Descargar todo como CSV"',
              4: () =>
                'Repita el paso 3 para las pestañas "Transferencia bancaria" y "Pago de facturas".',
              5: () =>
                "Luego puede importar los archivos CSV aquí a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    revolut: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicia sesión en Revolut.",
              1: () => "Seleccione “Cripto” en el menú inferior.",
              2: () => "Elija una criptomoneda.",
              3: () => "Toque en “Declaración”.",
              4: () => "Elija un período de tiempo.",
              5: () => "Seleccione 'Generar'.",
              6: () =>
                "Repita los pasos 3 a 6 para cada criptomoneda, ya que no puede generarlas todas a la vez. ",
              7: () => "Descarga el extracto en tu aplicación Revolut.",
            },
          },
        },
      },
    },
    ripple: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
              1: () =>
                'En el caso de XRP, actualmente solo admitimos transacciones de "Pago" de XRP. Si tiene otros tipos de transacciones (por ejemplo, monedas diferentes), comuníquese con nosotros a través de nuestro chat de soporte.',
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () => "Copia la dirección de tu billetera pública XRP",
              2: () =>
                "En CTC, elija la cadena de bloques relevante del menú desplegable",
              3: () =>
                'Pegue la dirección de su billetera pública y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    robinhood: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Los retiros y depósitos no son compatibles a través de la API, utilice la opción de importación CSV",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Inicie sesión en su cuenta de Robinhood. Haga clic en "Cuenta" en la esquina superior derecha y luego seleccione "Configuración".',
              1: () =>
                "Vaya a la sección 'Cripto' y localice la opción 'API Trading'.",
              2: () =>
                "Siga https://help.cryptotaxcalculator.io/en/articles/10448126-import-robinhood-transactions-into-crypto-tax-calculator-using-api-keys para generar un par de claves base64.",
              3: () =>
                "Agregue la 'Clave pública' generada a su cuenta de Robinhood.",
              4: () =>
                "En Permisos de API, habilite todos los permisos de lectura. Omita la configuración de direcciones IP permitidas o fecha de vencimiento para la clave de API.",
              5: () =>
                "Confirme la creación de la clave API. Robinhood mostrará la clave API.",
              6: () =>
                "En su cuenta CTC, ingrese la clave API en 'Clave API' y la 'Clave privada' del Paso 3 en 'Secreto'.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Comuníquese con el soporte de Robinhood visitando https://robinhood.com/contact",
              1: () =>
                "Haga clic en Documentos e impuestos → Historial de transacciones → Contáctenos",
              2: () =>
                "Elige cómo quieres que nos pongamos en contacto contigo, por correo electrónico o por teléfono.",
              3: () =>
                "Solicite una copia de sus transacciones de compra/venta y envío/recepción. Pueden ser dos archivos",
              4: () =>
                "Una vez que tenga sus archivos, conviértalos de PDF a CSV si es necesario",
              5: () =>
                "Reformatee los archivos en el formato CSV avanzado de CTC (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
              6: () => "Sube tus archivos CSV aquí",
            },
          },
        },
      },
    },
    robinhood_wallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Esto es para la billetera sin custodia Robinhood Web3. Para importar su cuenta de Robinhood Exchange, ingrese aquí: https://app.cryptotaxcalculator.io/imports/new/robinhood",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Abra la aplicación de billetera web3 sin custodia de Robinhood",
              1: () =>
                "Desde la pantalla de inicio, seleccione Menú (arriba a la izquierda) y elija Recibir",
              2: () => "Copia tu dirección pública",
              3: () =>
                "Elija la cadena de bloques relevante del menú desplegable.",
              4: () => "Pegue la dirección pública y haga clic en sincronizar",
            },
          },
        },
      },
    },
    ronin: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Visita https://nftbank.ai/ e inicia sesión",
              1: () =>
                "Haga clic en la pestaña 'Presentación de impuestos' en el menú de la izquierda.",
              2: () =>
                "Seleccione 'Obtener su historial de transacciones para la declaración de impuestos'",
              3: () =>
                "Seleccione 'Calculadora de impuestos criptográficos' y haga clic en 'Continuar'",
              4: () =>
                "Seleccione 'Moneda original - Formato del proveedor de servicios tributarios'",
              5: () =>
                'Si aún no ha importado su billetera Ronin, haga clic en "Agregar billetera" y siga las instrucciones de NFTbank',
              6: () =>
                'Una vez importado, seleccione su(s) billetera(s) Ronin y haga clic en "Continuar".',
              7: () => "Ingresa tu email donde recibirás un link de descarga",
              8: () =>
                "El archivo descargado debe ser un archivo .zip, abra el archivo para mostrar su contenido",
              9: () =>
                "Dentro de la nueva carpeta, busque el archivo ronin.csv e impórtelo a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    saga: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Saga en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    scroll: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Scroll en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    sei: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Enter your Sei wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    sentinel: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Sentinel en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    shakepay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Shakepay.",
              1: () => "Vaya a la página principal “Billeteras”.",
              2: () =>
                "Haga clic en el botón “Descargar historial” junto al título Historial de transacciones en la página principal.",
              3: () =>
                "Importe el CSV a la Calculadora de impuestos de criptomonedas.",
              4: () =>
                "Consulte https://help.shakepay.com/es/articles/3336094-donde-puedo-ver-mi-historial-completo-de-transacciones-estados-de-cuenta-mensuales-en-shakepay para obtener más detalles.",
            },
          },
        },
      },
    },
    sharkRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    singularx: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () => "Seleccione el intercambio del menú desplegable",
              3: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              4: () => "Mantener las demás configuraciones predeterminadas",
              5: () => 'Haga clic en "Recuperar historial"',
              6: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              7: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    softWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Si no puede encontrar su moneda o red, significa que Crypto Tax Calculator aún no puede sincronizar transacciones. Comuníquese con nuestro chat en vivo para que podamos brindarle asistencia.",
              1: () =>
                "Alternativamente, puede ingresar estas transacciones manualmente a través de la opción de importación CSV avanzada de CTC: https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import ",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Seleccione la red",
              1: () =>
                "Copia tu clave pública xpub, ypub o zpub (por ejemplo, la clave xpub de BTC)",
              2: () =>
                'Pegue la dirección pública y haga clic en "Importar billetera".',
              3: () =>
                "Más detalles (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator)",
            },
            title: () =>
              "Bitcoin, BitcoinCash, Dogecoin, BSV y otras redes con billeteras xpub:",
          },
          1: {
            items: {
              0: () => "Seleccione la red",
              1: () => "Copia la dirección de tu billetera pública.",
              2: () =>
                "Pegue su dirección pública y haga clic en “Importar billetera”.",
            },
            title: () =>
              "Ethereum (y tokens ETH), Binance Smart Chain, Cardano, Ripple, Gnosis, Polygon y otras redes con una dirección de billetera EVM:",
          },
          2: {
            items: {
              0: () => "Seleccione la red",
              1: () =>
                "Encuentre su clave pública Ltub (por ejemplo, en Ledger Live en 'Registros avanzados').",
              2: () =>
                "Convierta la dirección Ltub en una dirección Xpub aquí (https://jlopp.github.io/xpub-converter/).",
              3: () =>
                "Pegue la dirección Xpub y haga clic en “Importar billetera”.",
            },
            title: () => "Litecoin:",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/g8JcDe6zRZA",
            title: () => "Cómo importar su billetera Ledger Bitcoin",
          },
        },
      },
    },
    solana: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
            },
          },
          1: {
            items: {
              0: () =>
                "Si carga transacciones a través de la importación manual de CSV, utilice 'solana-deprecated' o 'sol-deprecated' como cadena de bloques.",
            },
            title: () => "Uso de la importación manual de CSV",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Solana en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    "solana-v2": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () => "Copia la dirección de tu billetera pública Solana",
              2: () =>
                'Pegue la dirección de su billetera pública en CTC y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    solflare: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de la billetera blockchain de Solana que ha utilizado para operar a través de SolFlare",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    sollet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de la billetera blockchain de Solana que ha utilizado para operar a través de Sollet",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    sonic: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
          1: {
            items: {
              0: () =>
                "Los saldos en vivo de los tokens en Sonic no están disponibles actualmente. Los detalles de las transacciones y los cálculos de impuestos no se ven afectados.",
            },
            title: () => "Saldos vivos",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Copia la dirección de tu billetera pública de Sonic que comienza con '0x...'",
              1: () => "Pegue la dirección de su billetera pública en CTC",
              2: () =>
                'Proporcione un apodo y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    spiritswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de la billetera blockchain Fantom que ha utilizado para operar a través de SpiritSwap.",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    spookyswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Encuentra la dirección de la billetera blockchain Fantom que has utilizado para operar a través de SpookySwap.",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    stacks: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Stacks (que comienza con SP) en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    staketax: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Busque la dirección de su billetera en stake.tax y seleccione Calculadora de impuestos de criptomonedas en el mensaje de descarga",
              1: () =>
                "Sube el CSV aquí a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    stargaze: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Stargaze en desarrollo temprano",
              1: () =>
                "Solo etiquetas de transferencias/participaciones (las demás aparecen como desconocidas)",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Stargaze en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    starknet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Abre tu billetera Starknet (Argent, Braavos, etc.)",
              1: () =>
                "Copia la dirección de tu billetera pública o la dirección de tu contrato que comience con '0x...'",
              2: () =>
                'Pegue la dirección en la Calculadora de impuestos criptográficos, agregue un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    stellar: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () => "Copia la dirección de tu billetera Stellar pública",
              2: () =>
                "En CTC, elija la cadena de bloques relevante del menú desplegable",
              3: () =>
                'Pegue la dirección de su billetera pública y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    stex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicia sesión en STEX, haz clic en tu correo electrónico en la parte superior derecha y selecciona perfil.",
              1: () =>
                "En el lado izquierdo, haga clic en “Informar” (https://app.stex.com/en/profile/report)",
              2: () =>
                "Haga clic en 'Historial del usuario' y haga clic en el botón exportar",
              3: () =>
                "Ahora puedes cargar este CSV en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    stormrake: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de Stormrake",
              1: () => "Descargue su historial de transacciones",
              2: () =>
                "Sube el archivo CSV a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    "substrate-chains": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Es posible que falten algunas tarifas en las transacciones puente ya que la cadena de bloques no proporciona esta información en algunos casos.",
              1: () =>
                "Además, es posible que no detectemos algunas transacciones de transferencia de la cadena de bloques. Si experimenta este problema, comuníquese con el servicio de asistencia.",
            },
            title: () => "Transacciones faltantes",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) =>
                "Ingresa la dirección de tu billetera " +
                d.exchangeDisplayName +
                ' en la Calculadora de impuestos de criptomonedas, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    subwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abra su billetera SubWallet",
              1: () =>
                "Seleccione la cuenta que desea importar en el menú desplegable de cuentas superior",
              2: () => "Haga clic en el icono de copia de dirección",
              3: () => "Copiar la dirección de la red deseada",
              4: () =>
                "En la Calculadora de impuestos criptográficos, seleccione la red correspondiente",
              5: () => 'Pegue la dirección y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    sui: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Abre tu billetera Sui",
              1: () =>
                "Para cada cuenta asociada con Sui, seleccione la cuenta y pase el cursor sobre el nombre de la cuenta.",
              2: () =>
                "Copia la dirección de la billetera pública que comienza con '0x...'",
              3: () =>
                'Pegue la dirección de billetera pública copiada en CTC y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    swissborg: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Para conocer los pasos más detallados, SwissBorg ha elaborado una guía aquí: https://help.swissborg.com/hc/en-gb/articles/360016499698-Account-Statement",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de SwissBorg a través de la aplicación de SwissBorg",
              1: () => "Vaya a su 'perfil' y toque 'Estado de cuenta'",
              2: () =>
                "Seleccione el rango de fechas que cubre su historial comercial completo",
              3: () =>
                "Seleccione la moneda de referencia como su moneda fiduciaria local",
              4: () => "Elija el formato 'Excel' y guarde el archivo",
              5: () =>
                "Sube este archivo aquí a la Calculadora de impuestos sobre criptomonedas",
            },
          },
        },
      },
    },
    switcheo: {
      csv: {
        note: {
          0: {
            items: {
              0: () => "El formato de fecha debe ser AAAA-MM-DD HH:mm:ss",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Switcheo Exporter (https://benammann.github.io/switcheo-exporter/#/).",
              1: () =>
                "Ingrese la dirección de su billetera pública y exporte el CSV.",
              2: () =>
                "Haga clic en el botón «Descargar CSV» en la esquina superior derecha.",
              3: () =>
                "Ahora puedes cargar este CSV en la Calculadora de impuestos de criptomonedas.",
            },
          },
        },
      },
    },
    swyftx: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente estamos trabajando con Swyftx para respaldar su nueva función de staking. Los depósitos y retiros de staking no se importarán en este momento",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Visita https://trade.swyftx.com.au/profile/api/",
              1: () => 'Haga clic en "Crear nueva clave".',
              2: () =>
                "Haga clic en “Entiendo” después de leer la advertencia.",
              3: () =>
                "Ingrese una etiqueta (por ejemplo, Calculadora de impuestos criptográficos), configure el “Alcance” en “Solo lectura” e ingrese su contraseña de Swyftx para confirmar.",
              4: () =>
                "Copie la 'Clave API' y el 'Token de acceso', luego péguelos en Crypto Tax Calculator como 'Clave API' y 'Secreto'.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q9QJesWUiMk",
            title: () => "Cómo importar tu API de Swyftx",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "La exportación CSV de Swyftx puede tener inconsistencias con las marcas de tiempo del horario de verano. Para mayor precisión, utilice la importación API si es posible.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Visita https://trade.swyftx.com/profile/transaction-reports",
              1: () => "Seleccione el período de la transacción",
              2: () => 'Haga clic en "Descargar.csv".',
              3: () =>
                "Sube el CSV a la Calculadora de impuestos de criptomonedas.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q_WXPgSRQoQ",
            title: () => "Cómo importar archivos CSV de Swyftx",
          },
        },
      },
    },
    taiko: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera Taiko adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Copia la dirección de tu billetera pública Taiko comenzando con '0x...'",
              1: () => "Pegue la dirección de su billetera pública en CTC",
              2: () =>
                'Proporcione un apodo y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    talisman: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Abre tu billetera Talisman",
              1: () => "Haga clic en la cuenta que desea importar",
              2: () => "Haga clic en el icono de copia de dirección",
              3: () => "Seleccione la red deseada",
              4: () =>
                "En la Calculadora de impuestos criptográficos, seleccione la red correspondiente",
              5: () => 'Pegue la dirección y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    terra: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Si importó sus transacciones de Terra antes del 18 de mayo de 2022, es posible que se produzcan errores de precios en la aplicación debido al cambio de nombre de LUNA a LUNC. Le recomendamos que elimine y vuelva a importar su billetera Terra Classic para corregir estos problemas.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Terra en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    terra2: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Terra 2.0 está en desarrollo inicial",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Terra 2.0 en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    theta: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
              1: () =>
                'Actualmente no admitimos tokens TNT721 y tendrás que agregarlos manualmente en la página "Revisar transacciones".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Theta en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    thetaboard: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Antes de cargar el archivo, asegúrese de ingresar la dirección de su billetera para que sepamos dónde asociar estas transacciones.",
              1: () =>
                'Actualmente no admitimos tokens TNT721 y tendrás que agregarlos manualmente en la página "Revisar transacciones".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Thetaboard (https://thetaboard.io/wallet/explorer).",
              1: () => "Introduzca la dirección de su billetera pública.",
              2: () =>
                "Haga clic en “Exportar historial de transacciones” (en Transacciones).",
              3: () =>
                "Ahora puedes cargar este CSV en la Calculadora de impuestos de criptomonedas.",
            },
          },
        },
      },
    },
    thetawallet: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Theta en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Antes de cargar el archivo, asegúrese de ingresar la dirección de su billetera para que sepamos dónde asociar estas transacciones.",
              1: () =>
                "Si desea información de tarifas más precisa, debe utilizar la importación 'Theta', que lee desde la cadena de bloques.",
              2: () =>
                'Actualmente no admitimos tokens TNT721 y tendrás que agregarlos manualmente en la página "Revisar transacciones".',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Vaya a ThetaScan (http://www.thetascan.io/).",
              1: () => "Introduzca la dirección de su billetera pública.",
              2: () =>
                "Haga clic en “Exportar transacciones” (debajo de su saldo)",
              3: () =>
                "Ahora puedes cargar este CSV en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    thorchain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Actualmente solo admitimos los tipos de transacciones “Enviar” e “Intercambiar”.",
            },
            title: () => "Tipos admitidos",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera THORChain en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera THORChain"',
            },
          },
        },
      },
    },
    toast: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'En el caso de Ripple, actualmente solo admitimos transacciones de "Pago" con XRP. Si tiene otros tipos de transacciones (por ejemplo, monedas diferentes), comuníquese con nosotros a través de nuestro chat de soporte.',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Para cada cuenta asociada a su billetera Toast:",
              1: () =>
                "Encuentre la dirección de blockchain pública de la cuenta.",
              2: () =>
                'Ingrese esta dirección en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    tokenJar: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En la Calculadora de impuestos de criptomonedas ahora puede ingresar la dirección de su billetera y cargar este CSV",
            },
          },
        },
      },
    },
    tokenStore: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el lado izquierdo del menú, expanda 'Historial de intercambio' y haga clic en TokenStore",
              3: () =>
                "En “Tipos de operaciones”, haga clic en “Operaciones + Fondos”.",
              4: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              5: () => "Mantener las demás configuraciones predeterminadas",
              6: () => 'Haga clic en "Recuperar historial"',
              7: () =>
                "Una vez completado, puede exportar el csv predeterminado en 'Exportar operaciones' y 'Exportar fondos'.",
              8: () =>
                "En Crypto Tax Calculator ahora puedes ingresar la dirección de tu billetera y cargar estos archivos CSV",
            },
          },
        },
      },
    },
    tokenion: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En Crypto Tax Calculator ahora puedes ingresar la dirección de tu billetera y cargar este CSV",
            },
          },
        },
      },
    },
    ton: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Puede ingresar su dirección con formato rebotable, no rebotable, TON DNS o sin procesar y la traduciremos al formato sin procesar para usted.",
            },
            title: () => "Tipos de direcciones",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera TON o el DNS de TON en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    tonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puede ingresar su dirección con formato rebotable, no rebotable, TON DNS o sin procesar y la traduciremos al formato sin procesar para usted.",
            },
            title: () => "Tipos de direcciones",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Encuentra la dirección de tu billetera TON dentro de la aplicación Wallet en Telegram. Para ver tu dirección, es posible que tengas que hacer clic en el botón "Agregar criptomoneda", seguido de las opciones "Billetera externa", "Toncoin" y copiar la dirección desde allí.',
              1: () =>
                "Ingresa la dirección de tu billetera en la Calculadora de impuestos de criptomonedas",
              2: () =>
                'Proporcione un apodo opcional y haga clic en "Agregar billetera"',
              3: () =>
                "Repita los pasos anteriores para cualquier billetera TON Space que tenga, busque la dirección haciendo clic en TON Space dentro de su billetera y haga clic en la abreviatura de dirección para copiarla a su portapapeles.",
            },
          },
        },
      },
    },
    toncoin: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puede ingresar su dirección con formato rebotable, no rebotable, TON DNS o sin procesar y la traduciremos al formato sin procesar para usted.",
            },
            title: () => "Tipos de direcciones",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera TON o el DNS de TON en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    tonhub: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puede ingresar su dirección con formato rebotable, no rebotable, TON DNS o sin procesar y la traduciremos al formato sin procesar para usted.",
            },
            title: () => "Tipos de direcciones",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Busque y copie la dirección de su billetera TON desde la aplicación Tonhub. Para ver su dirección, es posible que deba hacer clic en el botón "Recibir" y copiar la dirección desde allí.',
              1: () =>
                "Ingresa la dirección de tu billetera en la Calculadora de impuestos de criptomonedas",
              2: () =>
                'Proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    tonkeeper: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puede ingresar su dirección rebotable, no rebotable, TON DNS o en formato sin procesar y la traduciremos al formato sin procesar para usted.",
            },
            title: () => "Tipos de direcciones",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Encuentra la dirección de tu billetera TON dentro de la aplicación Tonkeeper, ya sea en Telegram o en la aplicación móvil o de escritorio. Para ver tu dirección, es posible que tengas que hacer clic en el botón "Recibir" y copiar la dirección desde allí.',
              1: () =>
                "Ingresa la dirección de tu billetera en la Calculadora de impuestos de criptomonedas",
              2: () =>
                'Proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    tonpay: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Puede ingresar su dirección con formato rebotable, no rebotable, TON DNS o sin procesar y la traduciremos al formato sin procesar para usted.",
            },
            title: () => "Tipos de direcciones",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Busque y copie la dirección de su billetera TON desde la aplicación de Telegram Tonpay. Puede ver la dirección de su billetera escribiendo /start y seleccionando la opción "Finanzas" y luego "Billetera".',
              1: () =>
                "Ingresa la dirección de tu billetera en la Calculadora de impuestos de criptomonedas",
              2: () =>
                'Proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    tradeSatoshi: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en TradeSatoshi",
              1: () =>
                "Vaya al Historial de pedidos https://tradesatoshi.com/Orders",
              2: () =>
                'Haga clic en el botón "Exportar" antes de cargar el CSV en la Calculadora de impuestos sobre criptomonedas',
            },
          },
        },
      },
    },
    "trader-joe": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de la billetera Avalanche C-Chain que utilizó para operar a través de Trader Joe.",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    trezor: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará agregar el símbolo de cotización para cada moneda que desee cargar",
              1: () =>
                "Asegúrese de que la fecha y la hora estén en el formato 'DD/MM/AAAA HH:mm:ss', 'AAAA-MM-DD HH:mm:ss' o 'M/D/AAAA h:mm:ss A'.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Puede seguir los pasos aquí para exportar sus datos CSV (https://wiki.trezor.io/User_manual:Transaction_details)",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Conecte su Trezor a su dispositivo",
              1: () => "Visita wallet.trezor.io",
            },
          },
          1: {
            items: {
              0: () =>
                "Seleccione la cuenta correspondiente y navegue hasta la pestaña Básica",
              1: () => "Haga clic en Mostrar XPUB",
              2: () => "Copiar la dirección xPub",
              3: () => "Pegar en CTC",
            },
            title: () =>
              "Para billeteras de Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin y Zcash",
          },
          2: {
            items: {
              0: () =>
                "Seleccionar Seleccione la moneda relevante y vea las direcciones de billetera pública debajo de Transacciones",
              1: () => "Copiar cada dirección pública",
              2: () => "Pegar en CTC",
            },
            title: () => "Para todas las demás monedas",
          },
        },
      },
    },
    tron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional".',
              1: () =>
                "Actualmente solo admitimos tokens Tron TRX y TRC10, cualquier otra transacción deberá importarse manualmente en Crypto Tax Calculator.",
              2: () =>
                "La importación solo cubre las transferencias. No manejamos interacciones en cadena, como la implementación de contratos inteligentes en esta etapa.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () => "Copia la dirección de tu billetera pública Tron",
              2: () =>
                "En CTC, elija la cadena de bloques relevante del menú desplegable",
              3: () =>
                'Pegue la dirección de su billetera pública y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    trustwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Abra la aplicación móvil o la extensión del navegador Trust Wallet",
              1: () =>
                "Si es necesario, seleccione su billetera en Configuración",
              2: () =>
                "En la pestaña Monedero, elija Recibir y seleccione el token",
              3: () => "Copiar la dirección pública ",
              4: () =>
                "Pegue en CTC y presione sincronizar para importar sus transacciones",
            },
          },
        },
      },
    },
    unichain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ahora admitimos tokens ERC-1155, pero no podemos importar los saldos actuales de estos tokens. Esto puede generar discrepancias entre el saldo importado desde la API de origen y el saldo calculado por la aplicación.",
            },
            title: () => "Fichas ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera Unichain en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    uniswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Encuentre la dirección de la billetera blockchain de Ethereum que ha utilizado para operar a través de Uniswap.",
              1: () =>
                'Ingrese esta dirección en la Calculadora de impuestos de criptomonedas, proporcione un apodo opcional y haga clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    uphold: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vea más detalles de los pasos para las diferentes interfaces de Uphold aquí: https://support.uphold.com/hc/en-us/articles/360046137012-How-can-I-download-my-transaction-history-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta de Uphold y vaya al panel de control de billeteras",
              1: () =>
                "A la izquierda, haga clic en el ícono de 'Actividad' para ir a la página de actividad (https://wallet.uphold.com/dashboard/activity)",
              2: () =>
                "En el panel izquierdo, en la parte superior derecha, haga clic en el ícono Documento",
              3: () => "Haga clic en el botón verde 'Generar informe'",
              4: () =>
                "Unos minutos más tarde recibirás un correo electrónico con la opción de descargar tu historial de transacciones en formato CSV",
              5: () =>
                "Sube el archivo CSV descargado aquí a Crypto Tax Calculator",
            },
          },
        },
      },
    },
    valr: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta VALR",
              1: () =>
                "Descargue su archivo CSV de historial de transacciones desde VALR",
              2: () =>
                "Sube este archivo CSV aquí en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    vechain: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a 'https://explore.vechain.org/download?address=INSERT_ADDRESS' pero reemplace INSERT_ADDRESS con la dirección de su billetera pública",
              1: () =>
                "Seleccione el período de tiempo que prefiere que incluya su historial de transacciones",
              2: () => "Verifica que eres humano",
              3: () => 'Haga clic en "Exportar"',
              4: () => "Sube el archivo CSV aquí",
            },
          },
        },
      },
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Abra su billetera " + d.exchangeDisplayName,
              1: () => "Copia la dirección de tu billetera pública Vechain",
              2: () =>
                "En CTC, elija la cadena de bloques relevante del menú desplegable",
              3: () =>
                'Pegue la dirección de su billetera pública y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    velas: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
          1: {
            items: {
              0: () =>
                "Es posible que falten algunas transacciones internas en algunas billeteras.",
            },
            title: () => "Transacciones internas faltantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Velas en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    virgocx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Inicie sesión en su cuenta VirgoCX aquí (https://virgocx.ca/login).",
              1: () =>
                "Haga clic en “eDocument” en la esquina superior derecha debajo de su nombre.",
              2: () =>
                "En la barra de navegación izquierda, haga clic en “Exportar datos”.",
              3: () =>
                "Seleccione “Datos de transacción” como su tipo de informe y “Criptomoneda” como su activo.",
              4: () =>
                'En "Fecha", elija el rango que incluye su historial completo de transacciones con VirgoCX. Es posible que deba descargar varios archivos para garantizar una cobertura completa.',
              5: () => "Continúe con la exportación de los archivos CSV.",
              6: () =>
                "Complete este mismo proceso pero seleccione 'Moneda fiduciaria' como activo esta vez.",
              7: () =>
                "Repita todos los pasos anteriores, pero ahora elija “Datos comerciales” en lugar de “Datos de transacción”.",
              8: () =>
                "Sube los archivos CSV a la Calculadora de impuestos criptográficos aquí.",
            },
          },
        },
      },
    },
    wealth99: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta de intercambio Wealth99",
              1: () =>
                "Vaya a Configuración en el menú del lado izquierdo y seleccione la pestaña 'Seguridad'",
              2: () => 'Haga clic en "Crear una clave API"',
              3: () =>
                "Asegúrate de haber marcado 'Habilitar lectura de información'",
              4: () =>
                "Copie la 'Clave API' y el 'Secreto API', ingréselos en la Calculadora de impuestos criptográficos aquí y haga clic en 'Sincronizar API' para sincronizar sus datos.",
            },
          },
        },
      },
    },
    xdai: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
              1: () =>
                'Como no hay datos disponibles para la recepción de xDai a través del puente xDai al convertir DAI a XDAI desde la red Ethereum, tendrá que ingresar manualmente estas transacciones como "compras" en la Calculadora de impuestos criptográficos.',
              2: () =>
                'Como no hay datos disponibles para la recepción de tokens en la cadena xDai a través de xDai OmniBridge al hacer un puente desde la red Ethereum, deberá ingresar manualmente estas transacciones como "compras" en la Calculadora de impuestos criptográficos.',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Gnosis en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    xverse_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Haga clic en “conectar billetera” y siga las instrucciones.",
            },
          },
        },
      },
    },
    yobit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "La API de Yobit solo obtiene el historial de transacciones de hace una semana",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Debajo del nombre de su cuenta, seleccione Claves API",
              1: () =>
                "Crear una nueva clave API para la Calculadora de impuestos sobre criptomonedas",
              2: () =>
                "Pegue la clave y la clave secreta en la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
    },
    yoroi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                'También puedes importar las transacciones de tu billetera Yoroi directamente a través de la cadena de bloques conectándote con la opción "Sincronizar mediante API". Esto incorporará todas las transacciones y recompensas de participación en tu billetera Yoroi.',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Iniciar sesión en Yoroi",
              1: () => "Navegar a la página de resumen de transacciones",
              2: () =>
                "En la parte superior derecha verás el icono de exportación.",
              3: () =>
                "Ahora puedes importar este CSV a la Calculadora de impuestos criptográficos",
            },
          },
        },
      },
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Ingrese solo UNA dirección de cada billetera. Realizamos una búsqueda y extraemos los datos de todas las demás direcciones de la billetera automáticamente.",
              1: () =>
                "Importar varias direcciones de la misma billetera dará como resultado transacciones duplicadas.",
              2: () =>
                "Actualmente no ofrecemos soporte para obtener el historial de transacciones completo de billeteras en las que cada dirección individual tiene más de 10 transacciones. Estamos trabajando para brindar soporte adicional para dichas billeteras.",
            },
          },
          1: {
            items: {
              0: () =>
                "Si importa una dirección que comienza con 'addr1', la cambiaremos automáticamente a la dirección del propietario de la cuenta que comienza con 'stake1'.",
              1: () =>
                "Esto nos permite importar todas las demás direcciones que pueda crear en la misma billetera y capturar sus recompensas de staking.",
            },
            title: () => "¿Por qué mi dirección es stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Abra su billetera Yoroi y haga clic en Billetera > Recibir",
              1: () =>
                "Haga clic en “Tiene saldo” y luego copie una de las direcciones públicas que comiencen con “addr1…”",
              2: () =>
                'Pegue la dirección de su billetera pública en CTC y haga clic en "Importar billetera"',
            },
          },
        },
      },
    },
    zebpay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Inicie sesión en su cuenta ZebPay.",
              1: () =>
                "Haga clic en el icono de perfil en la esquina superior derecha.",
              2: () => "Declaraciones seleccionadas.",
              3: () => "Seleccione Estado de cuenta consolidado.",
              4: () =>
                "Establezca las fechas de inicio y finalización, luego haga clic en Enviar.",
              5: () =>
                "Recibirás el informe por correo electrónico: descárgalo desde allí.",
              6: () =>
                "Sube los archivos a la Calculadora de impuestos criptográficos.",
            },
          },
        },
      },
    },
    zeroExProtocol: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Necesitará importar los archivos CSV de cada mes en el que realizó operaciones",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vaya a Saldos Delta > Historial de intercambio > Protocolo 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Ingrese la dirección de su billetera pública en la barra de búsqueda en la parte superior de la página y haga clic en el ícono de búsqueda",
              2: () =>
                "En el rango de fechas seleccione el mes en el que realizó operaciones",
              3: () => "Mantener las demás configuraciones predeterminadas",
              4: () => 'Haga clic en "Recuperar historial"',
              5: () =>
                "Una vez completado, haga clic en “Exportar operaciones” y seleccione “Predeterminado V2 (CSV)”.",
              6: () =>
                "En Crypto Tax Calculator ahora puedes ingresar la dirección de tu billetera y cargar este CSV",
            },
          },
        },
      },
    },
    zetachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingrese la dirección de su billetera ZetaChain (que comienza con 0x) en la Calculadora de impuestos criptográficos, proporcione un apodo opcional y haga clic en "Agregar billetera"',
            },
          },
        },
      },
    },
    zksync: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Tenga en cuenta que las transacciones de zkSync Era no se importarán hasta que la cadena de bloques las verifique. Esto suele tardar entre 30 y 60 minutos, pero puede extenderse a 24 horas o más. Esto también puede generar discrepancias de saldo temporales, que se resolverán después de que se verifiquen las transacciones.",
            },
            title: () => "Retraso en las transacciones de zkSync Era",
          },
          1: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera zkSync Era (que comienza con 0x) en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
            },
          },
        },
      },
    },
    zora: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                'Ingresa la dirección de tu billetera Zora (que comienza con 0x) en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera"',
            },
          },
        },
      },
    },
  },
  importInstructionsNotice: {
    binance: () =>
      "Binance ha compartido en su anuncio del 6 de agosto de 2021 que el acceso a la API se proporcionará solo a las cuentas que hayan completado la verificación de identidad. Si la sincronización de la API no funciona, recomendamos completar la verificación de identidad KYC (intermedia) de Binance antes de volver a intentarlo.",
    coinsmart: () =>
      "Coinsmart ha dejado de operar a partir de octubre de 2023 y todas las cuentas de usuario se han migrado a Bitbuy. Ver anuncio: 'https://www.coinsmart.com/blog/important-notice-changes-to-your-coinsmart-account/'",
  },
};
