import styled from "styled-components/macro";

import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  CheckboxActionType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { ButtonBase } from "~/components/transactions/filter-bar/style";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { FilterCountType } from "~/lib/enums";
import { getFilterCount } from "~/lib/filterOperatorConfig";
import { useLang } from "~/redux/lang";
import { Size } from "~/types/enums";

export function FilterReset({
  onReset,
  size,
}: {
  size?: Size;
  onReset?: () => void;
}) {
  const lang = useLang();
  const { state, dispatch } = useTransactionFilter();
  const { dispatch: checkboxDispatch } = useTransactionCheckbox();

  const filterQuery = state.filter;

  const handleClick = () => {
    if (onReset) {
      onReset();
      return;
    }

    dispatch({
      type: FilterActionType.ResetFilter,
    });
    checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
  };

  const filterCount = getFilterCount(filterQuery, FilterCountType.All);
  if (!filterCount) return null;

  return (
    <StyledButton
      size="small"
      onClick={handleClick}
      style={{ height: size === Size.Small ? "1.75rem" : undefined }}
    >
      {lang.txTable.filter.clearFilters}
    </StyledButton>
  );
}

const StyledButton = styled(TextButton)`
  ${ButtonBase}
`;
