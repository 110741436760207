import { type ActionRow } from "~/types/index";

/**
 * Creates a mostly stable ID for an action row
 * Uses the _id of the transactions in the row - unless grouping changes
 * this will be a stable ID
 * @param actionRow The action row
 * @returns action row key
 */
export function getActionRowKey(actionRow: ActionRow) {
  return [...actionRow.incoming, ...actionRow.outgoing, ...actionRow.fees]
    .map((a) => a._id)
    .sort()
    .join("__");
}
