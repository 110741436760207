import { Divider, InputBase, Stack, Typography } from "@mui/material";
import type * as React from "react";
import { useState } from "react";

import { CommandPaletteFooter } from "~/components/transactions/command-palette/CommandPaletteFooter";
import {
  CommandPaletteTitle,
  TitleContainer,
} from "~/components/transactions/command-palette/CommandPaletteTitle";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { Confirmation } from "~/components/transactions/command-palette/views/comment/Confirmation";
import { useConditionalAutoFocus } from "~/components/ui/hooks";
import { COMMENT_CHARACTER_LIMIT } from "~/constants/constants";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export const Comment = () => {
  const { push } = useNavController();
  const conditionalAutoFocus = useConditionalAutoFocus();
  const { mui, tokens } = useDesign();
  const [text, setText] = useState("");
  const lang = useLang();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const onSubmit = () => {
    const value = text.trim();
    if (value.length === 0 || value.length > COMMENT_CHARACTER_LIMIT) return;
    push(<Confirmation value={value} />);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <Stack direction="column" onKeyDown={handleKeyDown}>
      <TitleContainer>
        <CommandPaletteTitle
          topArea={
            <BulkEditOptionChip
              type={BulkEditOptions.AddCommentToTransactions}
            />
          }
          loading={false}
        />
      </TitleContainer>
      <Divider sx={{ color: tokens.border.neutral.default }} />
      <Stack direction="column" sx={{ padding: "1rem", gap: "0.5rem" }}>
        <InputBase
          id="comment-box"
          value={text}
          onChange={handleChange}
          multiline
          minRows={4}
          maxRows={10}
          placeholder={lang.txTable.bulkEditBar.addComment}
          {...conditionalAutoFocus}
        />
        <Typography
          sx={{
            ...mui.typography['Metropolis/Caption/Medium/Regular'],
            color:
              text.length > COMMENT_CHARACTER_LIMIT
                ? tokens.text.danger
                : tokens.text.low,
          }}
        >
          {text.length}/{COMMENT_CHARACTER_LIMIT}
        </Typography>
      </Stack>
      <CommandPaletteFooter
        actionLabel={lang.txTable.commandPallet.actions.add}
        onApply={onSubmit}
        disabled={text.length > COMMENT_CHARACTER_LIMIT}
      />
    </Stack>
  );
};
