import { type employeeDiscountRequestSchema } from "@ctc/contracts";
import { type Country, type Plan } from "@ctc/types";
import qs from "query-string";
import { type z } from "zod";

import { type AccountantClientWithCrypto } from "~/constants/enums";
import { get, post } from "~/services/core";
import { client } from "~/services/tsRestClient";
import { type PaymentCycle } from "~/types/enums";
import {
  type BillingHistory,
  type BusinessPlansRes,
  type CheckoutSession,
  type PaymentMethod,
  type PlansRes,
  type StripeCouponCode,
  type StripeCouponDetails,
} from "~/types/index";

export function payForFirstClient({
  plan,
  payment_method,
  discount,
}: {
  plan: string;
  payment_method: string;
  discount?: string;
}) {
  const fields = { plan, payment_method, discount };
  const path = "/client/first";
  return post<Plan>(path, fields);
}

export function upgradePaidUserToAccountant(
  firstClientName: string,
  accountantCompanyName: string,
  accountantContactName: string,
  accountantClientsWithCrypto: AccountantClientWithCrypto,
) {
  const path = `/subscription/accountant/upgrade`;
  return post<any>(path, {
    firstClientName,
    accountantCompanyName,
    accountantContactName,
    accountantClientsWithCrypto,
  });
}

export function upgradeFreeUserToAccountant(
  accountantCompanyName: string,
  accountantContactName: string,
  accountantClientsWithCrypto: AccountantClientWithCrypto,
  skipUserMigration?: boolean,
) {
  const path = `/subscription/accountant/create`;
  return post<any>(path, {
    accountantCompanyName,
    accountantContactName,
    accountantClientsWithCrypto,
    skipUserMigration,
  });
}

export function createCheckoutSession({
  plan,
  applyCoupon = true,
  isEmbedded,
  couponCode,
}: {
  plan: Plan;
  applyCoupon?: boolean;
  isEmbedded?: boolean;
  couponCode?: StripeCouponCode;
}) {
  const path = `/subscription/create/checkout-session/${plan}`;
  return post<CheckoutSession>(path, {
    ...(couponCode && applyCoupon ? { coupon: couponCode } : {}),
    ...(isEmbedded ? { isEmbedded } : {}),
  });
}

export function createBusinessClientCheckoutSession(
  plan: Plan,
  paymentCycle: PaymentCycle,
  hasTrialPeriod: boolean,
  name: string,
  email: string,
  country: Country,
) {
  const path = `/subscription/checkout-session/business/${plan}`;
  return post<CheckoutSession>(path, {
    paymentCycle,
    hasTrialPeriod,
    name,
    email,
    country,
  });
}

export function createCustomerPortalSession(plan?: Plan | null) {
  const path = `/subscription/create/customer-portal-session`;
  return post<CheckoutSession>(path, { newPlan: plan });
}

export function getCouponDetails(coupon: string) {
  const path = `/subscription/coupons/${coupon}`;
  return get<StripeCouponDetails>(path);
}

export function getBillingHistory() {
  const path = "/subscription/billing-history";
  return get<BillingHistory[]>(path);
}

export function getBillingDate() {
  const path = "/subscription/billing-date";
  return get<number>(path);
}

export function getPaymentMethod() {
  const path = "/subscription/payment-method";
  return get<PaymentMethod | null>(path);
}

export function addPaymentMethod(paymentMethodId: string) {
  const path = "/subscription/payment-method";
  const payload = { id: paymentMethodId };
  return post<boolean>(path, payload);
}

export function getPlans({ business = false }: { business?: boolean }) {
  const path = `/plans?${qs.stringify({ business })}`;
  return get<PlansRes>(path);
}

export function getBusinessPlans() {
  const path = "/plans/business";
  return get<BusinessPlansRes>(path);
}

export type CurrentSubscriptionRes = {
  amount: number;
};

export function retryRenewals() {
  const path = "/subscription/retry-renewal";
  return post<any>(path, {});
}

/**
 * Applies a coupon to a user's active subscription for future renewals
 *
 * @param coupon - Coupon code to apply
 * @returns Promise that resolves with the results of applying the coupon
 */
export function applyCouponsToFutureRenewals(coupon: StripeCouponCode) {
  const path = `/subscription/discounts`;
  return post<{
    couponApplied: boolean;
  }>(path, {
    coupon,
  });
}

/**
 * Checks if the user's subscription has a discount applied
 *
 * @returns Promise that resolves with whether the subscription has a discount
 */
export function checkSubscriptionDiscount() {
  const path = `/subscription/discounts`;
  return get<{ hasDiscount: boolean }>(path);
}

/**
 * Sends a partner employee discount email
 * @param partner The partner enum value
 * @param email The employee's work email
 */
export function employeeDiscount(
  body: z.infer<typeof employeeDiscountRequestSchema>,
) {
  return client.subscription.employeeDiscount({
    body,
  });
}
