import { Dialog, DialogContent } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import {
  DialogFooter,
  DialogHeader,
  PasswordAuthCodeFormContents,
} from "~/components/settings-modal/views/profile/security/common";
import { useLang } from "~/redux/lang";
import { useRegenerateRecoveryCodesMutation } from "~/state/security";

export function RegenerateCodesModal({
  open,
  onClose,
  openRecoveryCodes,
}: {
  open: boolean;
  onClose: () => void;
  openRecoveryCodes: (recoveryCodes: string[]) => void;
}) {
  const lang = useLang();
  const regenerateRecoveryCodesMutation = useRegenerateRecoveryCodesMutation();
  const form = useForm<{
    password: string;
    code: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      password: "",
      code: "",
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
    getValues,
  } = form;

  const onSubmit = async () => {
    const res = await regenerateRecoveryCodesMutation.mutateAsync(getValues());
    openRecoveryCodes(res.recoveryCodes);
    onClose();
    reset();
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogHeader onClose={onClose}>
        {lang.twoFactorAuth.regenerateRecoveryCodes}
      </DialogHeader>
      <DialogContent>
        <FormProvider {...form}>
          <PasswordAuthCodeFormContents />
        </FormProvider>
      </DialogContent>
      <DialogFooter
        onCancel={onCancel}
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isSubmitting}
      />
    </Dialog>
  );
}
