import { Plan } from "@ctc/types";

import { type ReportMeta } from "~/components/report/download-popup/PackCustomModal";

// A helper variable to give the order of reports to display in the pack custom modal
// Based on the order of the Plan enum
export const planTypeOrder = Object.values(Plan).reduce(
  (acc: Record<string, number>, value: string, index: number) => {
    acc[value] = index;
    return acc;
  },
  {} as Record<string, number>,
);

// Sorts reports without a locked plan to the top
export function sortReportsByLockedPlan(
  reportA: ReportMeta,
  reportB: ReportMeta,
) {
  if (!reportA.lockedPlan && reportB.lockedPlan) return -1;
  if (reportA.lockedPlan && !reportB.lockedPlan) return 1;
  return 0;
}

// Sorts reports that are visible to the top
export function sortReportsByVisibility(
  reportA: ReportMeta,
  reportB: ReportMeta,
) {
  if (reportA.visible && !reportB.visible) return -1;
  if (!reportA.visible && reportB.visible) return 1;
  return 0;
}

// Sorts reports by the order of the Plan enum
export function sortReportsByPlanOrder(
  reportA: ReportMeta,
  reportB: ReportMeta,
) {
  const getPlanOrder = (plan?: string): number =>
    plan && plan in planTypeOrder
      ? planTypeOrder[plan as keyof typeof Plan]
      : -1;

  const planA = getPlanOrder(reportA.requiredPlan);
  const planB = getPlanOrder(reportB.requiredPlan);
  return planA - planB;
}
