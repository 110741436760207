import { useEffect, useState } from "react";

import { Pagination } from "~/components/nav/Pagination";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  CheckboxActionType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import {
  getTransactionPageLink,
  useTransactionFilter,
} from "~/components/transactions/filter-bar/FilterContext";
import { useGetActionsQuery } from "~/state/actions";
import { type Align } from "~/types/enums";

export function FilterPagination({
  isUrlDriven = false,
  align,
}: {
  isUrlDriven?: boolean;
  align: Align;
}) {
  const {
    dispatch,
    state,
    state: { count, page, rowsPerPageOptions },
  } = useTransactionFilter();
  const { dispatch: checkboxDispatch } = useTransactionCheckbox();

  const query = useGetActionsQuery();
  const isLoading = query.isInitialLoading;
  const totalFilteredCount = query.data?.total;
  const [totalFilteredCountPrev, setTotalFilteredCountPrev] = useState(
    totalFilteredCount ?? 0,
  );
  const itemCount = isLoading
    ? totalFilteredCountPrev
    : totalFilteredCount ?? 0;

  const disabled = !(totalFilteredCount && totalFilteredCount > count);

  useEffect(() => {
    if (totalFilteredCount) {
      setTotalFilteredCountPrev(totalFilteredCount);
    }
  }, [totalFilteredCount, isLoading]);

  const nextLink = getTransactionPageLink({
    state: { ...state, page: page + 1 },
  });

  const prevLink = getTransactionPageLink({
    state: { ...state, page: page - 1 },
  });

  const firstLink = getTransactionPageLink({
    state: { ...state, page: 1 },
  });

  const lastLink = getTransactionPageLink({
    state: { ...state, page: Math.ceil(itemCount / count) },
  });

  return (
    <div>
      <Pagination
        count={itemCount}
        rowsPerPage={count}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        align={align}
        nextLink={isUrlDriven ? nextLink : undefined}
        prevLink={isUrlDriven ? prevLink : undefined}
        firstLink={isUrlDriven ? firstLink : undefined}
        lastLink={isUrlDriven ? lastLink : undefined}
        onAfterLinkClicked={() => {
          checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
        }}
        onPageChange={(e, page) => {
          dispatch({
            type: FilterActionType.GoToPage,
            page,
          });
        }}
        onRowsPerPageChange={(count) => {
          dispatch({
            type: FilterActionType.SetCount,
            count,
          });
        }}
        isLoading={isLoading}
        disabled={disabled}
      />
    </div>
  );
}
