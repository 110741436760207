import { useQuery } from "@tanstack/react-query";
import { useContext, useMemo } from "react";

import { TableColumns } from "~/components/report/tax-loss-harvesting-modal/enums";
import { cleanCurrencyId } from "~/components/report/tax-loss-harvesting-modal/TLHTable";
import { TaxLossHarvestingContext } from "~/contexts/TaxLossHarvestingContext";
import { type Translation } from "~/lang/index";
import { minus, multiply } from "~/lib/index";
import { useLang } from "~/redux/lang";
import * as dashboardApi from "~/services/dashboard";
import * as reconciliationAPI from "~/services/reconciliation";
import { useReconciliationQuery } from "~/state/reconciliation";
import { taxLossHarvestingQueryKeys } from "~/state/taxLossHarvesting";
import { ReconciliationIssues } from "~/types/enums";

const getHoldingsData = (lang: Translation) => async () => {
  const res = await dashboardApi.getDashboardHoldings();

  if (res.error)
    throw new Error(res.msg ?? lang.taxLossHarvesting.errors.unableToLoad);

  return res.data.currencyHoldings.map((row) => {
    return {
      id: cleanCurrencyId(row.currencyIdentifier.id),
      HACK_IN_ORDER_TO_FILTER_BY_CURRENCY: row.currencyIdentifier.name,
      [TableColumns.Currency]: row.currencyIdentifier,
      [TableColumns.Balance]: row.summary.totalBalance,
      [TableColumns.Price]: row.summary.marketPrice,
      [TableColumns.Value]: row.summary.marketValue,
      [TableColumns.Cost]: row.summary.cost,
      [TableColumns.Gain]: row.summary.unrealizedGain,
      [TableColumns.AmountToSell]: 0,
    };
  });
};
const useHoldingsDataQuery = () => {
  const lang = useLang();
  return useQuery({
    queryKey: taxLossHarvestingQueryKeys.holdings(),
    queryFn: getHoldingsData(lang),
  });
};

const getTLHMissingPriceCurrencyIds = () => async (): Promise<Set<string>> => {
  const res = await reconciliationAPI.getReconciliationIssuesForType(
    ReconciliationIssues.MissingPrices,
    { noPagination: true },
  );
  if (res.error) {
    // fail silently for now
    return new Set();
  }
  return new Set(
    res.data.issues.map((issue) => cleanCurrencyId(issue.currencyId)),
  );
};
// this is separate to the rec query because we want all the issues to be loaded at once
const useMissingPriceReconciliationQuery = () => {
  return useQuery({
    queryKey: taxLossHarvestingQueryKeys.missingPrices(),
    queryFn: getTLHMissingPriceCurrencyIds(),
  });
};

export function useTLHTableRows() {
  const { data: holdings, isLoading: isLoadingData } = useHoldingsDataQuery();
  const { data: missingPriceIds, isLoading: isLoadingMissingPrices } =
    useMissingPriceReconciliationQuery();
  // get the uncategorised issues
  const { data: allRecData, isLoading: isLoadingRec } =
    useReconciliationQuery();

  const tlhContext = useContext(TaxLossHarvestingContext);
  const lossesToHarvest = tlhContext?.lossesToHarvest;
  const priceOverrides = tlhContext?.modifiedPrices;

  const noIssuesHoldings = useMemo(() => {
    if (isLoadingData || isLoadingMissingPrices || isLoadingRec) return null;
    if (!holdings || !allRecData || !missingPriceIds) return null;

    const uncategorisedCurrencies =
      allRecData.uncategorisedTransactions?.issues[0]?.currencies || [];
    const uncategorisedCurrencyIds = new Set(
      uncategorisedCurrencies.map((currencyIdentifier) =>
        cleanCurrencyId(currencyIdentifier.id),
      ),
    );

    return holdings
      .filter(
        (row) =>
          !missingPriceIds.has(row.id) &&
          !uncategorisedCurrencyIds.has(row.id) &&
          row.balance &&
          row.balance > 0 &&
          !!row.cost,
      )
      .map((row) => {
        const isSelected = lossesToHarvest?.has(row.id);
        const price = priceOverrides?.get(row.id) ?? row[TableColumns.Price];
        const value = multiply(row[TableColumns.Balance] || 0, price || 0);
        const gain = minus(value, row[TableColumns.Cost] || 0);

        // mutation - unselect the row if the gain is now positive
        if (isSelected && gain > 0) {
          const newLossesToHarvest = new Set(lossesToHarvest);
          newLossesToHarvest.delete(row.id);
          tlhContext?.updateLossesToHarvest(newLossesToHarvest);
        }

        return {
          ...row,
          [TableColumns.AmountToSell]: isSelected ? row.balance : 0,
          [TableColumns.Price]: price,
          [TableColumns.Value]: value,
          [TableColumns.Gain]: gain,
        };
      });
  }, [
    tlhContext,
    holdings,
    missingPriceIds,
    allRecData,
    isLoadingData,
    isLoadingRec,
    isLoadingMissingPrices,
    lossesToHarvest,
    priceOverrides,
  ]);

  return noIssuesHoldings;
}
