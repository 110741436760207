import {
  Delete,
  Done,
  DragIndicator,
  Edit,
  InfoOutlined,
  MoreVert,
  PowerOffOutlined,
  PowerOutlined,
  VerticalAlignBottom,
  VerticalAlignTop,
} from "@mui/icons-material";
import {
  Box,
  Popover,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import type { Identifier, XYCoord } from "dnd-core";
import { bindPopover, bindToggle } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import type React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { rulesIntegrationsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { TableBodyCell, TableRow } from "~/components/contacts/helper";
import { DisplayRulesDrawer } from "~/components/rules/DisplayRulesDrawer";
import {
  RuleDeleteConfirmationModal,
  RuleDisableConfirmationModal,
  RuleEnableConfirmationModal,
} from "~/components/rules/RuleConfirmationModals";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import {
  type AutocompleteFilterOption,
  convertFilterQueryToSelectedAutocompleteFilterOption,
  FilterOption,
  getAutocompleteFilterOptions,
} from "~/components/transactions/filter-bar/FilterSearch";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { Chip } from "~/components/ui/Chips";
import { useConditionalAutoFocus } from "~/components/ui/hooks";
import { FrameInspect } from "~/components/ui/Icons";
import { useHover } from "~/hooks/useHover";
import { useDesign } from "~/hooks/useTheme";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { type RuleBulkOperation, type RuleDTO } from "~/services/rules";
import { useGetFilterOptionsQuery } from "~/state/actions";
import { useErpAvailableAccountsQuery } from "~/state/erp";
import { useLoadExchangeNames } from "~/state/exchanges";
import {
  useGetEnabledRulesQuery,
  useRenameRuleMutation,
  useReorderRuleMutation,
} from "~/state/rules";
import { BulkOperations, FilterOperators } from "~/types/enums";

const ROW_HEIGHT = "1.5rem";

type RowProps = {
  rule: RuleDTO;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  draggingId: string | undefined;
  setDraggingId: (val: string | undefined) => void;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function RuleTableRow({
  rule,
  index,
  moveRow,
  draggingId,
  setDraggingId,
}: RowProps) {
  const { ref: previewRef, value: isHovered } = useHover<HTMLTableRowElement>();
  const { tokens } = useDesign();
  const [isOpen, setIsOpen] = useState(false);
  const reorderRuleMutation = useReorderRuleMutation();

  const dragRef = useRef<HTMLDivElement>(null);

  const analyticsKey = rulesIntegrationsAnalyticsKey("row");
  const captureAnalytics = useCaptureAnalytics();

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "ROW",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: () => {
      reorderRuleMutation.mutate({ id: rule.id, newIndex: index });
      captureAnalytics(analyticsKey("reordered"));
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "ROW",
    item: () => {
      return { rule, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drop(preview(previewRef));
  drag(dragRef);

  // Lock on the dragging state
  useEffect(() => {
    if (isDragging && draggingId !== rule.id) {
      setDraggingId(rule.id);
    }
    if (!isDragging && draggingId === rule.id) {
      setDraggingId(undefined);
    }
  }, [isDragging, draggingId, setDraggingId, rule.id]);

  // Show the drag handle always if this is the row being dragged, or if the
  // row is being hovered and no other row is being dragged, or if its being
  // hovered and this row is being dragged
  const showDragHandle =
    isDragging || (isHovered && (!draggingId || draggingId === rule.id));

  return (
    <>
      <DisplayRulesDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        rules={[rule]}
        enableTxCounts
        hideHeader
      />
      <TableRow
        ref={previewRef}
        style={{
          border: `1px dashed ${tokens.border.neutral.default}`,
          padding: "0.5rem 1rem",
          marginBottom: ".5rem",
          opacity,
        }}
        data-handler-id={handlerId}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <TableBodyCell>
          <Box
            ref={dragRef}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="2rem"
            height={ROW_HEIGHT}
            sx={{ cursor: "move" }}
          >
            {showDragHandle ? (
              <DragIndicator
                sx={{ fontSize: "1rem", color: tokens.icon.default }}
              />
            ) : (
              <Typography variant="IBM Plex Mono/Body/Regular">
                {index + 1}
              </Typography>
            )}
          </Box>
        </TableBodyCell>
        <NameCell isHovered={isHovered} rule={rule} />
        <FilterChipsCell isHovered={isHovered} rule={rule} />
        <OperationsCell isHovered={isHovered} rule={rule} />
        <RulesRowMoreOptionsCell
          rule={rule}
          openDrawer={() => {
            setIsOpen(true);
          }}
        />
      </TableRow>
    </>
  );
}

export function DisabledRuleTableRow({ rule }: { rule: RuleDTO }) {
  const { tokens } = useDesign();
  const { ref: previewRef, value: isHovered } = useHover<HTMLTableRowElement>();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <DisplayRulesDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        rules={[rule]}
        enableTxCounts
        hideHeader
      />
      <TableRow
        style={{
          border: `1px dashed ${tokens.border.neutral.default}`,
          padding: "0.5rem 1rem",
          marginBottom: ".5rem",
        }}
        ref={previewRef}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <NameCell isHovered={isHovered} rule={rule} disableEdit />
        <FilterChipsCell isHovered={isHovered} rule={rule} />
        <OperationsCell isHovered={isHovered} rule={rule} />
        <DisabledRulesRowMoreOptionsCell
          rule={rule}
          openDrawer={() => {
            setIsOpen(true);
          }}
        />
      </TableRow>
    </>
  );
}

export function ruleNameValidation(oldName: string, newName: string): boolean {
  return (
    newName.trim().length > 0 &&
    newName.trim().length <= 280 &&
    newName.trim() !== oldName
  );
}

function NameCell({
  rule,
  disableEdit = false,
  isHovered,
}: {
  rule: RuleDTO;
  disableEdit?: boolean;
  isHovered: boolean;
}) {
  const { tokens } = useDesign();
  const conditionalAutoFocus = useConditionalAutoFocus();
  const [isEditing, setIsEditing] = useState(false);
  const [displayName, setDisplayName] = useState<string>(rule.name);
  const renameRuleMutation = useRenameRuleMutation();

  const analyticsKey = rulesIntegrationsAnalyticsKey("rule");
  const captureAnalytics = useCaptureAnalytics();

  const submit = () => {
    // If the name isnt too long or short and has changed, submit the change
    if (ruleNameValidation(rule.name, displayName)) {
      renameRuleMutation.mutate({
        id: rule.id,
        newName: displayName.trim(),
      });
    }
    setIsEditing(false);
  };

  return (
    <TableBodyCell>
      <Box
        height={ROW_HEIGHT}
        width="15rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {isEditing ? (
          <TextField
            size="small"
            value={displayName}
            {...conditionalAutoFocus}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
            onBlur={() => {
              submit();
            }}
            sx={{
              height: "1.5rem",
              marginTop: "-0.625rem",
              flexGrow: "1",
            }}
            inputProps={{
              sx: {
                fontSize: "0.75rem",
                bgcolor: tokens.background.neutral.lowest.default,
                borderRadius: "0.25rem",
              },
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                submit();
              }
            }}
          />
        ) : (
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            color={isHovered ? tokens.text.high : tokens.text.low}
          >
            {rule.name}
          </Typography>
        )}
        {!isEditing && !disableEdit ? (
          <TextIconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setIsEditing(true);
              setDisplayName(rule.name);
              captureAnalytics(analyticsKey("edit name"));
            }}
          >
            <Edit
              sx={{
                cursor: "pointer",
                color: tokens.text.low,
                width: "1rem",
              }}
            />
          </TextIconButton>
        ) : isEditing ? (
          <TextIconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              submit();
            }}
          >
            <Done
              sx={{
                cursor: "pointer",
                color: tokens.text.success,
                width: "1rem",
              }}
            />
          </TextIconButton>
        ) : null}
      </Box>
    </TableBodyCell>
  );
}

function FilterChipsCell({
  rule,
  isHovered,
}: {
  rule: RuleDTO;
  isHovered: boolean;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  const { data: exchangeNames } = useLoadExchangeNames();
  const query = useGetFilterOptionsQuery();
  const country = useCountry();

  const autocompleteFilterOptions: AutocompleteFilterOption[] = useMemo(() => {
    if (!exchangeNames) {
      return [];
    }
    return getAutocompleteFilterOptions({
      data: query.data,
      lang,
      exchangeNames,
      alwaysMiddleTrimAddresses: true,
    });
  }, [query.data, lang, exchangeNames]);

  const filterOptions = convertFilterQueryToSelectedAutocompleteFilterOption(
    rule.filter,
    autocompleteFilterOptions,
    country,
  );

  const filterOptionsTrimmed = filterOptions.slice(0, 5);
  const remainingLength = filterOptions.length - filterOptionsTrimmed.length;

  return (
    <TableBodyCell width="100%">
      <div style={{ width: "100%" }}>
        {filterOptionsTrimmed.length ? (
          <Stack direction="row" gap="0.25rem" flexWrap="wrap">
            {filterOptionsTrimmed.map((option) => (
              <Chip
                bgcolor={tokens.background.accent.neutral.low}
                pl="0.25rem"
                key={option.key}
              >
                <FilterOption
                  {...option}
                  size="small"
                  textColor={isHovered ? tokens.text.high : tokens.text.low}
                  hideCategoryNameOverride={
                    ![FilterOperators.From, FilterOperators.To].includes(
                      option.type,
                    )
                  }
                />
              </Chip>
            ))}
            {remainingLength ? (
              <Chip
                color={tokens.text.low}
                bgcolor={tokens.background.accent.neutral.low}
                width={undefined}
                maxWidth={undefined}
                overflow={undefined}
              >{`+${remainingLength}`}</Chip>
            ) : null}
          </Stack>
        ) : (
          <Skeleton width="100%" />
        )}
      </div>
    </TableBodyCell>
  );
}

function OperationsCell({
  rule,
  isHovered,
}: {
  rule: RuleDTO;
  isHovered: boolean;
}) {
  const { operations } = rule;
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <TableBodyCell>
      <Box width="19rem">
        <Stack direction="row" gap="0.25rem" flexWrap="wrap">
          {operations.map((operation, index) => {
            if (operation.type === BulkOperations.Recategorise) {
              return (
                <Chip
                  key={`${operation.type}_${index}`}
                  color={isHovered ? tokens.text.high : tokens.text.low}
                  bgcolor={tokens.background.accent.neutral.low}
                >
                  {`${lang.rules.operationShorthands[operation.type]}: ${getActionTypeName(
                    {
                      actionType: operation.toActionType,
                      lang,
                    },
                  )}`}
                </Chip>
              );
            }

            return (
              <ErpAccountCell
                key={`${operation.type}_${index}`}
                operation={operation}
                isHovered={isHovered}
              />
            );
          })}
        </Stack>
      </Box>
    </TableBodyCell>
  );
}

function trimName(name: string) {
  if (name.length > 16) {
    return `${name.substring(0, 13)}...`;
  }
  return name;
}

function ErpAccountCell({
  operation,
  isHovered,
}: {
  operation: Extract<
    RuleBulkOperation,
    {
      type:
        | BulkOperations.ChangeErpAssetAccount
        | BulkOperations.ChangeErpPnLAccount
        | BulkOperations.ChangeErpGainsAccount
        | BulkOperations.ChangeErpLoanAccount
        | BulkOperations.ChangeErpCashAccount;
    }
  >;
  isHovered: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const erpAvailableAccounts = useErpAvailableAccountsQuery().data ?? [];
  const account =
    erpAvailableAccounts.find((acc) => acc.code === operation.erpAccountCode) ??
    null;

  return (
    <Chip
      color={isHovered ? tokens.text.high : tokens.text.low}
      bgcolor={tokens.background.accent.neutral.low}
    >
      {`${
        lang.rules.operationShorthands[operation.type]
      }: (${account?.code}) ${trimName(account?.name ?? "")}`}
    </Chip>
  );
}

function RulesRowMoreOptionsCell({
  rule,
  openDrawer,
}: {
  rule: RuleDTO;
  openDrawer: () => void;
}) {
  const navigate = useNavigate();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDisableOpen, setIsDisableOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const allRules = useGetEnabledRulesQuery();
  const reorderRuleMutation = useReorderRuleMutation();
  const lang = useLang().rules.rulesTable.row.options;
  const [, setEditId] = useQueryParam(
    "edit",
    withDefault(StringParam, undefined),
  );

  const popupState = usePopupState({
    variant: "popover",
    popupId: `rule-${rule.id}`,
    disableAutoFocus: true,
  });

  const listItems = [
    {
      key: "edit",
      name: lang.edit,
      icon: <Edit sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        setEditId(rule.id);
        popupState.close();
      },
    },
    {
      key: "viewTxs",
      name: lang.viewTxs,
      icon: <FrameInspect sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        navigate(
          getTransactionPageLink({
            state: {
              filter: {
                type: FilterOperators.And,
                rules: [{ type: FilterOperators.Rule, value: [rule.id] }],
              },
            },
          }),
        );
        popupState.close();
      },
    },
    {
      key: "viewRule",
      name: lang.viewRule,
      icon: <FrameInspect sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        openDrawer();
        popupState.close();
      },
    },
    {
      key: "moveToTop",
      name: lang.moveToTop,
      icon: <VerticalAlignTop sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        reorderRuleMutation.mutate({
          id: rule.id,
          newIndex: 0,
        });
        popupState.close();
      },
    },
    {
      key: "moveToBottom",
      name: lang.moveToBottom,
      icon: <VerticalAlignBottom sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        if (!allRules.data) {
          throw new Error("rules not loaded, but how is that possible");
        }

        reorderRuleMutation.mutate({
          id: rule.id,
          newIndex: allRules.data.length - 1,
        });
        popupState.close();
      },
    },
    {
      key: "disable",
      name: lang.disable,
      icon: <PowerOffOutlined sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        setIsDisableOpen(true);
        popupState.close();
      },
      info: lang.disableInfo,
    },
    {
      key: "delete",
      name: lang.delete,
      icon: <Delete sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        setIsDeleteOpen(true);
        popupState.close();
      },
      critical: true,
      info: lang.deleteInfo,
    },
  ];

  return (
    <>
      <RuleDeleteConfirmationModal
        rule={rule}
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
      />
      <RuleDisableConfirmationModal
        rule={rule}
        isOpen={isDisableOpen}
        setIsOpen={setIsDisableOpen}
      />
      <RulesRowMoreOptionsCellBase
        popupState={popupState}
        menuItems={listItems}
      />
    </>
  );
}

function DisabledRulesRowMoreOptionsCell({
  rule,
  openDrawer,
}: {
  rule: RuleDTO;
  openDrawer: () => void;
}) {
  const navigate = useNavigate();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEnableOpen, setIsEnableOpen] = useState(false);
  const lang = useLang().rules.rulesTable.row.options;

  const popupState = usePopupState({
    variant: "popover",
    popupId: `rule-${rule.id}`,
    disableAutoFocus: true,
  });

  const listItems = [
    {
      key: "enable",
      name: lang.enable,
      icon: <PowerOutlined sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        setIsEnableOpen(true);
        popupState.close();
      },
    },
    {
      key: "viewTxs",
      name: lang.viewTxs,
      icon: <FrameInspect sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        navigate(
          getTransactionPageLink({
            state: {
              filter: {
                type: FilterOperators.And,
                rules: [{ type: FilterOperators.Rule, value: [rule.id] }],
              },
            },
          }),
        );
        popupState.close();
      },
    },
    {
      key: "viewRule",
      name: lang.viewRule,
      icon: <FrameInspect sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        openDrawer();
        popupState.close();
      },
    },
    {
      key: "delete",
      name: lang.delete,
      icon: <Delete sx={{ fontSize: "1rem" }} />,
      onClick: () => {
        setIsDeleteOpen(true);
        popupState.close();
      },
      critical: true,
      info: lang.deleteInfo,
    },
  ];

  return (
    <>
      <RuleDeleteConfirmationModal
        rule={rule}
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
      />
      <RuleEnableConfirmationModal
        rule={rule}
        isOpen={isEnableOpen}
        setIsOpen={setIsEnableOpen}
      />
      <RulesRowMoreOptionsCellBase
        popupState={popupState}
        menuItems={listItems}
      />
    </>
  );
}

function RulesRowMoreOptionsCellBase({
  popupState,
  menuItems,
}: {
  popupState: ReturnType<typeof usePopupState>;
  menuItems: {
    key: string;
    name: string;
    icon: React.ReactNode;
    onClick: () => void;
    info?: string;
    critical?: boolean;
  }[];
}) {
  const { tokens } = useDesign();

  const analyticsKey = rulesIntegrationsAnalyticsKey("more options");
  const captureAnalytics = useCaptureAnalytics();
  return (
    <TableBodyCell>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="1.75rem"
        height={ROW_HEIGHT}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <TextIconButton size="small" {...bindToggle(popupState)}>
          <MoreVert />
        </TextIconButton>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          disableScrollLock
          sx={{
            position: "absolute",
          }}
        >
          <Box
            bgcolor={tokens.background.input.default}
            display="flex"
            flexDirection="column"
          >
            {menuItems.map((item) => {
              return (
                <ListItem
                  key={item.key}
                  onClick={() => {
                    item.onClick();
                    captureAnalytics(analyticsKey("clicked"), {
                      option: item.key,
                    });
                  }}
                  color={item.critical ? tokens.text.danger : tokens.text.low}
                >
                  {item.icon}
                  <Typography
                    fontSize="0.75rem"
                    fontWeight={500}
                    color="inherit"
                  >
                    {item.name}
                  </Typography>
                  {item.info ? (
                    <Tooltip title={item.info} placement="bottom">
                      <InfoOutlined
                        sx={{ fontSize: "1rem", color: tokens.text.low }}
                      />
                    </Tooltip>
                  ) : null}
                </ListItem>
              );
            })}
          </Box>
        </Popover>
      </Box>
    </TableBodyCell>
  );
}

const ListItem = styled(Box)`
  && {
    display: flex;
    align-items: center;
    padding: 0.38rem 1rem;
    gap: 0.5rem;
    cursor: pointer;
  }

  &&:hover {
    background-color: ${({ theme }) => theme.tokens.background.input.hover};
  }
`;
