enum PasswordErrors {
  Short = "short",
  Long = "long",
  Case = "case",
  Number = "number",
}

export function passwordChecker(
  password: string,
): { error: true; msg: PasswordErrors } | { error: false } {
  const uppercase = new RegExp("(?=.*[A-Z])");
  const otherCharNotSpace = new RegExp("(?=.*[^a-zA-Zs])");
  if (password.length < 8) {
    return {
      error: true,
      msg: PasswordErrors.Short,
    };
  } else if (password.length > 64) {
    return {
      error: true,
      msg: PasswordErrors.Long,
    };
  } else if (!uppercase.test(password)) {
    return {
      error: true,
      msg: PasswordErrors.Case,
    };
  } else if (!otherCharNotSpace.test(password)) {
    return {
      error: true,
      msg: PasswordErrors.Number,
    };
  }
  return {
    error: false,
  };
}
