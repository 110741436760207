import { type LocalCurrency } from "@ctc/types";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  type TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

import { displayTimestamp } from "~/components/report/helpers/displayTimestamp";
import { PaymentStatusNotification } from "~/components/settings-modal/views/billing/PaymentStatusNotification";
import { ConfigurationPanel } from "~/components/ui/ConfigurationPanel";
import { ChipColor } from "~/components/ui/enums";
import { NoData } from "~/components/ui/NoData";
import { SquareChip } from "~/components/ui/SquareChip";
import { displayFiatValue } from "~/lib/index";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { useBillingHistoryQuery, usePaymentFailed } from "~/state/billing";
import { type BillingHistory } from "~/types/index";

const TableComponent = ({ children }: React.PropsWithChildren) => {
  return (
    <Paper elevation={0} variant="outlined">
      {children}
    </Paper>
  );
};

const StyledTableCell = styled(
  ({ last, ...props }: { last: boolean } & TableCellProps) => (
    <TableCell {...props} />
  ),
)`
  && {
    border-bottom: ${(props) => (props.last ? "none" : "inherit")};
  }
`;

const BillingHistoryTable = ({ rows }: { rows: BillingHistory[] }) => {
  const timezone = useTimezone();
  const lang = useLang();
  const languagePreference = useLanguagePreference();
  return (
    <TableContainer component={TableComponent}>
      <Table aria-label="billing-history">
        <TableHead>
          <TableRow>
            <TableCell>{lang.billing.billingHistory.table.date}</TableCell>
            <TableCell>
              {lang.billing.billingHistory.table.description}
            </TableCell>
            <TableCell align="right">
              {lang.billing.billingHistory.table.amount}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 ? (
            <TableRow>
              <StyledTableCell last colSpan={4} padding="none">
                <NoData title="No billing history" />
              </StyledTableCell>
            </TableRow>
          ) : (
            rows.map(
              (
                {
                  id,
                  created,
                  description,
                  amount,
                  currency,
                  succeeded,
                  errorMessage,
                },
                index,
              ) => {
                const last = index === rows.length - 1;
                return (
                  <TableRow hover key={id}>
                    <StyledTableCell last={last}>
                      {displayTimestamp(created, timezone, "lll")}
                    </StyledTableCell>
                    <StyledTableCell last={last}>
                      {description || "No description"}
                    </StyledTableCell>
                    <StyledTableCell align="right" last={last}>
                      {displayFiatValue({
                        value: amount,
                        localCurrency: currency.toUpperCase() as LocalCurrency,
                        locale: languagePreference,
                      })}
                    </StyledTableCell>
                    <StyledTableCell style={{ paddingLeft: 0 }} last={last}>
                      <Tooltip title={errorMessage || ""}>
                        <span>
                          <SquareChip
                            color={succeeded ? ChipColor.GREEN : ChipColor.RED}
                            label={
                              succeeded
                                ? lang.accountant.billingPage.paid
                                : lang.accountant.billingPage.failed
                            }
                            fontWeight={400}
                          />
                        </span>
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                );
              },
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const BillingHistoryPanel = () => {
  const lang = useLang();
  const billingHistoryQuery = useBillingHistoryQuery();
  const paymentFailed = usePaymentFailed();
  return (
    <>
      {paymentFailed && <PaymentStatusNotification />}
      <ConfigurationPanel
        title={lang.billing.billingHistory.title}
        isLoading={billingHistoryQuery.isPending}
      >
        <BillingHistoryTable rows={billingHistoryQuery.data || []} />
      </ConfigurationPanel>
    </>
  );
};
