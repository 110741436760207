import { Sync } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CommentIcon from "@mui/icons-material/Comment";
import { Tooltip, tooltipClasses, type TooltipProps } from "@mui/material";
import styled from "styled-components/macro";

import { useFeatureFlag } from "~/analytics/posthog";
import { ActionRowActionsWrapper } from "~/components/transactions/action-row/ActionRowActionsWrapper";
import { ActionRowErpPushStatusButton } from "~/components/transactions/action-row/ActionRowErpPushStatusButton";
import { type ActionRowDefaultActions } from "~/components/transactions/action-row/enums";
import { ExplainActionButton } from "~/components/transactions/action-row/ExplainActionButton";
import { RecentComments } from "~/components/transactions/action-row/RecentComments";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { useBreakdownsStore } from "~/components/transactions/BreakdownContext";
import { BreakdownTab } from "~/components/transactions/enums";
import { type OptionsType } from "~/components/transactions/TransactionRow";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";
import { getActionRowKey } from "~/lib/getActionRowKey";
import { useLocalCurrency } from "~/redux/auth";
import { useErpLang, useLang } from "~/redux/lang";
import { hasUnignoredErrors } from "~/services/actions";
import {
  useSyncToErpMutation,
  useUpdateActionReviewedMutation,
} from "~/state/actions";
import { useErpSettingsQuery, useIsErpReadyToSync } from "~/state/erp";
import { ErpSyncStatus, FeatureFlag } from "~/types/enums";

// @ts-ignore
const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    padding: 0,
    backgroundColor: (props: any) =>
      props.theme.tokens.background.accent.neutral.low,
  },
  [`& .MuiTooltip-arrow`]: {
    color: (props: any) => props.theme.tokens.background.accent.neutral.low,
  },
});

export const ActionRowActions = ({
  row,
  addedOptions,
  hideReview = false,
  defaultOptions,
}: ActionRowCellProps & {
  addedOptions?: OptionsType[];
  hideReview?: boolean;
  defaultOptions?: Record<ActionRowDefaultActions, boolean>;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const updateRowTransactionMutation = useUpdateActionReviewedMutation();

  const isReviewed = row.reviewed;
  const isComments = !!row.comments && row.comments.length > 0;
  const toggleBreakdownOpenStatus = useBreakdownsStore(
    (state) => state.toggleBreakdown,
  );

  const erpQuery = useErpSettingsQuery();
  const syncToErpMutation = useSyncToErpMutation();
  const canSync = useIsErpReadyToSync();
  const erpLang = useErpLang(erpQuery.data?.erp);

  const erpStatus = row.erpSyncStatus ?? ErpSyncStatus.SyncNotRequested;

  const isExplainEnabled = useFeatureFlag(FeatureFlag.AiExplain);

  const handleSync = async () => {
    if (erpQuery.data) {
      await syncToErpMutation.mutateAsync({
        actionId: row._id,
        erp: erpQuery.data.erp,
      });
    }
  };

  const onReviewed = () => {
    updateRowTransactionMutation.mutate({
      actionRow: row,
      reviewed: !isReviewed,
    });
  };

  if (!localCurrency) {
    return null;
  }

  const shouldAddForceSync =
    erpStatus === ErpSyncStatus.SyncSuccessful && canSync;
  const erpSyncOption: OptionsType = {
    handler: handleSync,
    label: lang.forceSyncErp({ erp: erpLang.integrationShortName }),
    icon: <Sync style={{ fontSize: "1rem" }} />,
  };
  const allAddedOptions: OptionsType[] | undefined = shouldAddForceSync
    ? [erpSyncOption].concat(addedOptions ?? [])
    : addedOptions;

  const getCommentIconColor = () => {
    if (isComments) {
      return tokens.icon.brand;
    }
    if (row.isLocked) {
      return tokens.text.disabled;
    }
    return tokens.text.low;
  };

  return (
    <ActionRowActionsWrapper
      addedOptions={row.isLocked ? undefined : allAddedOptions}
      row={row}
      defaultOptions={defaultOptions}
    >
      <ActionRowErpPushStatusButton row={row} />

      {isExplainEnabled ? <ExplainActionButton row={row} /> : null}

      {hideReview ? null : (
        <Tooltip
          title={isReviewed ? lang.markAsUnreviewed : lang.markAsReviewed}
          placement="top"
        >
          <TextIconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onReviewed();
            }}
            disabled={row.isLocked}
          >
            {!isReviewed ? (
              <AssignmentIcon
                fontSize="small"
                style={{
                  color: row.isLocked ? tokens.text.disabled : tokens.text.low,
                }}
              />
            ) : hasUnignoredErrors(row) ? (
              <AssignmentLateIcon
                fontSize="small"
                style={{ color: tokens.icon.danger }}
              />
            ) : (
              <AssignmentTurnedInIcon
                fontSize="small"
                style={{ color: tokens.icon.success }}
              />
            )}
          </TextIconButton>
        </Tooltip>
      )}

      <NoMaxWidthTooltip
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -5],
              },
            },
          ],
        }}
        title={<RecentComments row={row} />}
        placement="top"
        disableInteractive
      >
        <TextIconButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleBreakdownOpenStatus(
              getActionRowKey(row),
              BreakdownTab.Comments,
            );
          }}
          disabled={row.isLocked}
        >
          <CommentIcon
            fontSize="small"
            style={{
              color: getCommentIconColor(),
            }}
          />
        </TextIconButton>
      </NoMaxWidthTooltip>
    </ActionRowActionsWrapper>
  );
};
