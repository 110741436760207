import { type Blockchain, Plan, type Trade } from "@ctc/types";
import CreateIcon from "@mui/icons-material/Create";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

import { Severity } from "~/components/transactions/action-row/enums";
import {
  ActionRowHoverStyled,
  Overflower,
} from "~/components/transactions/action-row/Overflower";
import { WarningDot } from "~/components/transactions/action-row/Warnings";
import { EditActionAccountsModal } from "~/components/transactions/edit/EditActionAccounts";
import {
  formatDisplayAddress,
  getAddressLink,
} from "~/components/transactions/helpers";
import { ManageEntityDialog } from "~/components/transactions/ManageEntityDialog";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { DisplayExchangeActionItems } from "~/components/ui/DisplayExchangeActionItems";
import { useDesign } from "~/hooks/useTheme";
import { isTransferLikeActionType } from "~/lib/isTransferLikeActionType";
import { useBestUser } from "~/redux/auth";
import { useIsAddressLike } from "~/redux/imports";
import { useLang } from "~/redux/lang";
import { isEntityable, useEntityLookup } from "~/state/entities";
import { useGetSavedAccounts } from "~/state/importsV2";
import { CustomExchanges, type ImportType, type Side } from "~/types/enums";
import { type ActionRow } from "~/types/index";

export function Account({
  row,
  exchange, // might be an address, or an exchange name
  displayName,
  currencySymbol,
  blockchain,
  importType,
  trade,
  isSmartContract,
  side,
}: {
  row: ActionRow;
  exchange: string;
  displayName: string;
  currencySymbol: string;
  blockchain?: Blockchain;
  showDepositIcon?: boolean;
  trade: Trade;
  importType?: ImportType;
  isSmartContract?: boolean;
  side?: Side;
}) {
  const lang = useLang();
  const [isEntityOpen, setIsEntityOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { tokens } = useDesign();
  const isAnAddress = !!useIsAddressLike(exchange.trim());
  const entity = useEntityLookup(exchange, blockchain);
  const savedImports = useGetSavedAccounts();
  const addressLink = getAddressLink(exchange, currencySymbol, blockchain);
  const user = useBestUser();

  const isUnknownAccount = exchange === CustomExchanges.Unknown && !!side;

  const showUnknownAccountWarning =
    isUnknownAccount &&
    user?.paidPlan !== Plan.Free &&
    isTransferLikeActionType(row.type);

  const isNicknamed =
    Boolean(entity) ||
    (exchange.toLowerCase() !== displayName.toLowerCase() && isAnAddress);

  const finalDisplayName = entity ? entity.displayName : displayName.trim();

  const isNameable =
    importType &&
    isEntityable(
      entity,
      exchange,
      importType,
      savedImports,
      isAnAddress,
      blockchain,
      trade,
    );

  return (
    <>
      <ManageEntityDialog
        isOpen={isEntityOpen}
        handleClose={() => {
          setIsEntityOpen(false);
        }}
        address={exchange}
        blockchain={blockchain}
        isSmartContract={isSmartContract}
      />
      <EditActionAccountsModal
        row={row}
        isOpen={isEditOpen}
        onClose={() => {
          setIsEditOpen(false);
        }}
        isBlockchain={!!blockchain}
        showUnknownAccountWarning={showUnknownAccountWarning}
        side={side}
      />
      <Overflower>
        <ActionRowHoverStyled
          style={{ cursor: "default" }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Stack direction="row" alignItems="center" gap="0.25rem">
            <Stack direction="row" alignItems="center">
              <Tooltip
                title={
                  showUnknownAccountWarning && side
                    ? lang.txTable.unknownAccountWarning.tooltip({ side })
                    : lang.txTable.identifyAddress
                }
                disableHoverListener={!(isNameable || isUnknownAccount)}
              >
                <Typography
                  className="action-row-account__account"
                  variant="IBM Plex Mono/Caption/Small/Regular"
                  sx={{
                    color: tokens.text.low,
                    cursor: isNameable ? "pointer" : "inherit",
                    textDecoration: isNameable ? "underline dashed" : 0,
                    textUnderlineOffset: "2px",
                  }}
                  onClick={(e) => {
                    if (isNameable) {
                      e.stopPropagation();
                      setIsEntityOpen(true);
                    }
                  }}
                >
                  {isNicknamed
                    ? finalDisplayName
                    : formatDisplayAddress(
                        finalDisplayName,
                        isNicknamed,
                        isAnAddress,
                      )}
                </Typography>
              </Tooltip>
            </Stack>
            {showUnknownAccountWarning ? (
              <WarningDot severity={Severity.Warn} />
            ) : null}
            <Box
              className="action-row-account__actions"
              sx={{
                margin: "-4px 0 !important",
              }}
            >
              <Stack direction="row" spacing="0.25rem">
                {!row.isLocked ? (
                  <Tooltip title={lang.edit}>
                    <TextIconButton
                      size="small"
                      style={{ padding: 0, color: tokens.icon.default }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsEditOpen(true);
                      }}
                    >
                      <CreateIcon style={{ fontSize: "0.875rem" }} />
                    </TextIconButton>
                  </Tooltip>
                ) : null}
                <DisplayExchangeActionItems
                  exchange={exchange}
                  addressLink={addressLink}
                  width={14}
                  noPadding
                  disableCopy={isUnknownAccount}
                />
              </Stack>
            </Box>
          </Stack>
        </ActionRowHoverStyled>
      </Overflower>
    </>
  );
}
