import { type Plan } from "@ctc/types";
import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components/macro";

import calcoPhoneCall from "~/assets/lottie-animations/calcoPhoneCall.json";
import { type PriceDataType } from "~/components/plans/hooks/usePriceDataByPlanType";
import {
  type FeatureDisplay,
  PlanFeatures,
} from "~/components/plans/PlanFeatures";
import { PlanPrice } from "~/components/plans/PlanPrice";
import { PlanTabs } from "~/components/plans/PlanTabs";
import { useDesign } from "~/hooks/useTheme";
import { type Features, type PaymentCycle } from "~/types/enums";
import { type PlanDataBase } from "~/types/index";

export type PlanContainerData<T extends PlanDataBase> = PriceDataType & {
  data: T;
};

export function PlanContainer<T extends PlanDataBase>({
  plans,
  highlightedFeatures,
  defaultPlanData,
  setSelectedPlanType,
  selectedPaymentCycle,
  setSelectedPaymentCycle,
  comingSoon = [],
  additionalFeatures = [],
}: {
  plans: PlanContainerData<T>[];
  highlightedFeatures: Features[];
  defaultPlanData?: PlanContainerData<T>;
  setSelectedPlanType: (planType: Plan) => void;
  selectedPaymentCycle?: PaymentCycle;
  setSelectedPaymentCycle?: (paymentCycle: PaymentCycle) => void;
  comingSoon?: Features[];
  additionalFeatures?: FeatureDisplay[];
}) {
  const { tokens } = useDesign();

  const [selectedPlanData, setSelectedPlanData] = useState<
    PlanContainerData<T>
  >(defaultPlanData ?? plans[0]);

  useEffect(() => {
    if (plans.length > 0) {
      if (defaultPlanData) {
        setSelectedPlanData(defaultPlanData);
      } else {
        setSelectedPlanData(plans[0]);
      }
    }
  }, [plans, selectedPlanData, defaultPlanData]);

  const handlePlanSelection = (planType: Plan) => {
    const plan = plans.find((plan) => plan.planType === planType);
    if (plan) {
      setSelectedPlanData(plan);
    }
    setSelectedPlanType(planType);
  };

  return (
    <Container>
      {selectedPlanData === undefined ? (
        <Box
          width="100%"
          height="15rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Player
            loop
            autoplay
            src={calcoPhoneCall}
            style={{ height: "8rem" }}
          />
        </Box>
      ) : (
        <>
          <PlanTabs
            plans={plans}
            selectedPlanType={selectedPlanData.planType}
            selectedPaymentCycle={selectedPaymentCycle}
            setSelectedPlanType={handlePlanSelection}
          />
          <PlanPrice
            selectedPlan={selectedPlanData}
            selectedPaymentCycle={selectedPaymentCycle}
            setSelectedPaymentCycle={setSelectedPaymentCycle}
          />
          <Divider sx={{ borderColor: tokens.border.neutral.default }} />
          <PlanFeatures
            selectedPlanData={selectedPlanData}
            highlightedFeatures={highlightedFeatures}
            comingSoon={comingSoon}
            additionalFeatures={additionalFeatures}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background: ${({ theme }) => theme.tokens.elevation.low};
`;
