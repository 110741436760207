import { OpenInNew } from "@mui/icons-material";
import type * as React from "react";

export const StyledOpenInNew = ({
  onClick,
  style,
}: {
  onClick?: (e: React.SyntheticEvent) => void;
  style?: React.CSSProperties;
}) => {
  return (
    <OpenInNew
      onClick={onClick}
      fontSize="small"
      style={{
        marginLeft: 4,
        ...style,
      }}
    />
  );
};
