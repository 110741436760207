import { type Plan } from "@ctc/types";

import { deleteRequest, get, post, put } from "~/services/core";
import { type ClientDetails, type ClientPayload } from "~/types/index";

enum Path {
  Client = "/client",
}

export function getClientList() {
  const path = `${Path.Client}/list`;
  return get<ClientDetails[]>(path);
}

export function createClient(payload: ClientPayload) {
  const path = Path.Client;
  return post<ClientDetails>(path, payload);
}

export function upgradeClient(id: string, plan: Plan) {
  const path = `${Path.Client}/${id}`;
  return put<ClientDetails>(path, { plan });
}

export function deleteClient(id: string) {
  const path = `${Path.Client}/${id}`;
  return deleteRequest<true>(path);
}

export function cancelClientSub(id: string) {
  const path = `${Path.Client}/cancel-sub/${id}`;
  return post<true>(path, {});
}

export function uncancelClientSub(id: string) {
  const path = `${Path.Client}/uncancel-sub/${id}`;
  return post<true>(path, {});
}
