import { Button, Stack } from "@mui/material";
import styled from "styled-components/macro";

import { useLang } from "~/redux/lang";
import { HoldingViewMode } from "~/types/enums";

export const HoldingViewToggleButton = ({
  holdingView,
  setHoldingView,
}: {
  holdingView: HoldingViewMode;
  setHoldingView: (holdingView: HoldingViewMode) => void;
}) => {
  const lang = useLang();

  return (
    <Stack direction="row" gap="0.5rem">
      <StyledButton
        disabled={holdingView === HoldingViewMode.Currencies}
        onClick={() => {
          setHoldingView(HoldingViewMode.Currencies);
        }}
        aria-label="currencies"
        data-uncensored="true"
      >
        {lang.imports.drawer.currencyTableHeadings.currencies}
      </StyledButton>
      <StyledButton
        disabled={holdingView === HoldingViewMode.Nfts}
        onClick={() => {
          setHoldingView(HoldingViewMode.Nfts);
        }}
        aria-label="nfts"
        data-uncensored="true"
      >
        {lang.imports.drawer.nftTableHeadings.ntfs}
      </StyledButton>
    </Stack>
  );
};

const StyledButton = styled(Button)`
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  color: ${({ theme }) => theme.tokens.text.low};
  background: ${({ theme }) => theme.tokens.button.neutral.default};
  &:hover {
    color: ${({ theme }) => theme.tokens.text.high};
  }
  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.text.high};
    background: ${({ theme }) => theme.tokens.button.neutral.pressed};
  }
`;
