/**
 * The id matches the specific currencyIdentifier in the currencies DB.
 * The name is the display name.
 */
export const networks = {
  "42": { name: "42-coin", id: "42-coin" },
  "1337": { name: "Elite", id: "1337" },
  "2give": { name: "2Give", id: "2give" },
  adc: { name: "AudioCoin", id: "audiocoin" },
  // agm: "Argoneum",
  alias: { name: "Alias", id: "spectrecoin" },
  arco: { name: "AquariusCoin", id: "aquariuscoin" },
  arepa: { name: "Arepacoin", id: "arepacoin" },
  arg: { name: "Argentum", id: "argentum" },
  // ari: "Aricoin",
  audax: { name: "Audax", id: "audax" },
  aur: { name: "Auroracoin", id: "auroracoin" },
  // axiv: "AXIV",
  // b3: "B3Coin",
  // Bitbay API has id: "bitbay", the wallet has an id "bay"
  bay: { name: "BitBay", id: "bitbay" },
  bbk: { name: "BitBlocks", id: "bitblocks-project" },
  bbp: { name: "Biblepay", id: "biblepay" },
  // bbtc: "BlakeBitcoin",
  // bcc: "BitConnect Coin",
  // bccx: "BlockChainCoinX",
  // bct: "BitcoinTrust",
  bean: { name: "Bean Cash", id: "bean-cash" },
  // blc: "Blakecoin",
  // blitz: "Blitzcash",
  blk: { name: "BlackCoin", id: "blackcoin" },
  block: { name: "Blocknet", id: "blocknet" },
  bnode: { name: "Beenode", id: "beenode" },
  boli: { name: "BolivarCoin", id: "bolivarcoin" },
  bro: { name: "Bitradio", id: "bitradio" },
  bsd: { name: "BitSend", id: "bitsend" },
  // btc: { name: "Bitcoin", id: ""},
  // btc2: { name: "Bitcoin2", id: "",},
  btdx: { name: "BitCloud", id: "bitcloud" },
  btx: { name: "BitCore", id: "bitcore" },
  bwi: { name: "BITWIN24", id: "" }, // CHECK
  bxt: { name: "BitToken", id: "bittokens" },
  byc: { name: "Bytecent", id: "" }, // CHECK
  bynd: { name: "Beyondcoin", id: "beyondcoin" },
  bytz: { name: "BYTZ", id: "slate" },
  cann: { name: "CannabisCoin", id: "cannabiscoin" },
  // caps: { name: "BottleCapsPlus", id: "" },
  cbx: { name: "Bullion", id: "bullion" },
  // cdn: { name: "Canada-eCoin", id: "chbt" },
  chbt: { name: "CHBToken", id: "chbt" },
  chess: { name: "ChessCoin 0.32%", id: "chesscoin-0-32" },
  // circ: { name: "Circcash", id: "" },
  civ: { name: "Civitas", id: "civitas" },
  cloak: { name: "CloakCoin", id: "cloakcoin" },
  // cno: { name: "Coino", id: "" },
  colx: { name: "ColossusXT", id: "colossuscoinxt" },
  cps: { name: "Capricoin+", id: "capricoin" },
  crt: { name: "Certurium", id: "certurium" },
  crypt: { name: "CryptCoin", id: "cryptcoin" },
  crw: { name: "CROWN", id: "crown" },
  cure: { name: "CureCoin", id: "curecoin" },
  // dash: { name: "Dash", id: "",},
  dem: { name: "Deutsche eMark", id: "deutsche-emark" },
  dgb: { name: "DigiByte", id: "digibyte" },
  dgc: { name: "DigitalCoin", id: "digitalcoin" },
  dime: { name: "Dimecoin", id: "dimecoin" },
  divi: { name: "Divi", id: "divi" },
  dmd: { name: "Diamond", id: "dmd" },
  d: { name: "Denarius", id: "denarius" },
  dfi: { name: "DeFiChain", id: "defichain" },
  dmb: { name: "Digitalmoneybits", id: "digital-money-bits" },
  donu: { name: "Donu", id: "donu" },
  dope: { name: "DopeCoin", id: "dopecoin" },
  // drz: { name: "Droidz", id: "" },
  dvc: { name: "DevCoin", id: "devcoin" },
  eac: { name: "EarthCoin", id: "earthcoin" },
  // ec: { name: "Eclipse", id: "" },
  ecc: { name: "ECC", id: "ecc" },
  efl: { name: "e-Gulden", id: "electronicgulden" },
  // egc: { name: "EverGreenCoin", id: "" },
  // elt: { name: "Electron", id: "" },
  emc2: { name: "Einsteinium", id: "einsteinium" },
  emd: { name: "Emerald", id: "emerald-crypto" },
  enrg: { name: "EnergyCoin", id: "energycoin" },
  eny: { name: "EmergencyCoin", id: "emergency-coin" },
  excl: { name: "ExclusiveCoin", id: "exclusivecoin" },
  firo: { name: "Firo", id: "zcoin" },
  fst: { name: "Fastcoin", id: "gapcoin" },
  ftc: { name: "Feathercoin", id: "feathercoin" },
  gap: { name: "GapCoin", id: "gapcoin" },
  // gcr: { name: "Global Currency Reserve", id: "" },
  gio: { name: "Graviocoin", id: "graviocoin" },
  glc: { name: "GoldCoin", id: "goldcoin" },
  glt: { name: "GlobalToken", id: "globaltoken" },
  // grn: { name: "Granite", id: "" },
  // grs: { name: "Groestlcoin", id: "",},
  // "grs-test": { name: "Groestlcoin TestNet", id: "",},
  grwi: { name: "Growers", id: "growers-international" },
  gun: { name: "Guncoin", id: "guncoin" },
  hth: { name: "HTH", id: "help-the-homeless-coin" },
  html: { name: "HTMLCOIN", id: "htmlcoin" },
  i0c: { name: "I0Coin", id: "i0coin" },
  ic: { name: "Ignition", id: "ignition" },
  // icn: { name: "iCoin", id: "" },
  icol: { name: "Icolcoin", id: "icolcoin" },
  ifc: { name: "InfiniteCoin", id: "infinitecoin" },
  iflt: { name: "InflationCoin", id: "inflationcoin" },
  // il8p: { name: "InfiniLooP", id: "" },
  img: { name: "ImageCoin", id: "imagecoin" },
  imgc: { name: "ImgCash", id: "imagecash" },
  infx: { name: "Influx", id: "influxcoin" },
  inn: { name: "Innova", id: "innova" },
  insn: { name: "InsaneCoin", id: "insanecoin" },
  ioc: { name: "I/O Coin", id: "iocoin" },
  ion: { name: "Ion", id: "ion" },
  ixc: { name: "iXcoin", id: "ixcoin" },
  // j: { name: "JoinCoin", id: "" },
  // ked: { name: "Darsek", id: "" },
  kfx: { name: "KnoxFS", id: "knoxfs" },
  klks: { name: "Kalkulus", id: "kalkulus" },
  kobo: { name: "Kobocoin", id: "kobocoin" },
  konj: { name: "Konjungate", id: "konjungate" },
  // kore: { name: "Kore", id: "" },
  lana: { name: "Lanacoin", id: "lanacoin" },
  lcc: { name: "Litecoin Cash", id: "litecoin-cash" },
  // linx: { name: "Linx", id: "" },
  // lit: { name: "Lithium", id: "" },
  lkr: { name: "LKRcoin", id: "lkr-coin" },
  // ltc: { name: "Litecoin", id: "",},
  ltn: { name: "Latinio", id: "life-token" },
  lux: { name: "LUXCoin", id: "luxcoin" },
  lynx: { name: "Lynx", id: "lynx" },
  manna: { name: "Manna", id: "manna" },
  marks: { name: "Bitmark", id: "bitmark" },
  mec: { name: "Megacoin", id: "megacoin" },
  mnc: { name: "Mincoin", id: "mincoin" },
  moon: { name: "Mooncoin", id: "mooncoin" },
  mrc: { name: "Meritcoins", id: "meritcoins" },
  mue: { name: "MonetaryUnit", id: "monetaryunit" },
  nav: { name: "NavCoin", id: "nav-coin" },
  netko: { name: "NETKO", id: "netko" },
  neva: { name: "Nevacoin", id: "nevacoin" },
  nix: { name: "NIX Core", id: "nix-platform" },
  nmc: { name: "Namecoin", id: "namecoin" },
  nobl: { name: "Noblecoin", id: "noblecoin" },
  nor: { name: "Noir", id: "bring" },
  nyc: { name: "NewYorkCoin", id: "newyorkcoin" },
  ocp: { name: "OC Protocol", id: "oc-protocol" },
  ok: { name: "Okcash", id: "okcash" },
  onion: { name: "DeepOnion", id: "deeponion" },
  ozc: { name: "Ozziecoin", id: "ozziecoin" },
  pak: { name: "PakCoin", id: "pakcoin" },
  part: { name: "Particl", id: "particl" },
  pcn: { name: "PeepCoin", id: "peepcoin" },
  // petal: { name: "bitFlowers", id: "" },
  pho: { name: "Photon", id: "photon" },
  phr: { name: "Phore", id: "phore" },
  // piggy: { name: "Piggycoin", id: "" },
  pink: { name: "Pinkcoin", id: "pinkcoin" },
  pivx: { name: "PIVX", id: "pivx" },
  pnd: { name: "PandaCoin", id: "pandacoin" },
  // "pnd-old": { name: "PandaCoin OLD", id: "" },
  pny: { name: "Peony", id: "peony-coin" },
  // "pny-old": { name: "Peony OLD", id: "" },
  pot: { name: "PotCoin", id: "potcoin" },
  ppc: { name: "PeerCoin", id: "peercoin" },
  // "ppc-test": { name: "PeerCoin TestNet", id: "" },
  ptc: { name: "Pesetacoin", id: "pesetacoin" },
  // put: { name: "PutinCoin v2", id: "" },
  pwrb: { name: "PWRB", id: "powerbalt" },
  pyrk: { name: "Pyrk", id: "pyrk" },
  qrk: { name: "Quark", id: "quark" },
  ric: { name: "Riecoin", id: "riecoin" },
  roger: { name: "TheHolyRogerCoin", id: "theholyrogercoin" },
  // scifi: { name: "UnitedSciFi", id: "" },
  scol: { name: "Scolcoin", id: "scolcoin" },
  scribe: { name: "Scribe", id: "scribe" },
  sfd: { name: "SafeDeal Coin", id: "safe-deal" },
  // slg: { name: "Sterlingcoin", id: "" },
  slm: { name: "Slimcoin", id: "slimcoin" },
  slr: { name: "SolarCoin", id: "solarcoin" },
  sls: { name: "SaluS", id: "salus" },
  smly: { name: "Smileycoin", id: "smileycoin" },
  spd: { name: "Stipend", id: "stipend" },
  spk: { name: "Sparks", id: "sparks" },
  sprts: { name: "Sprouts", id: "sprouts" },
  // sqr: { name: "sQuorum", id: "" },
  // stk: { name: "Stakecoin", id: "" },
  // strat: { name: "Stratis", id: "" }, // TODO
  // strax: { name: "Strax", id: "" }, // TODO
  // "strax-test": { name: "Strax TestNet", id: "" },
  super: { name: "SuperCoin", id: "supercoin" },
  swing: { name: "Swing", id: "swing" },
  sxc: { name: "SexCoin", id: "sexcoin" },
  sys: { name: "Syscoin", id: "syscoin" },
  taj: { name: "TajCoin", id: "tajcoin" },
  // "talk-old": { name: "BTCTalkCoin", id: "" },
  // tes: { name: "Teslacoin", id: "" },
  toa: { name: "ToaCoin", id: "toacoin" },
  trex: { name: "Trexcoin", id: "trexcoin" },
  trc: { name: "Terracoin", id: "terracoin" },
  troll: { name: "Trollcoin", id: "trollcoin" },
  trump: { name: "TrumpCoin", id: "trumpcoin" },
  ttn: { name: "Titan Coin", id: "titan-coin" },
  tzc: { name: "Trezarcoin", id: "trezarcoin" },
  ufo: { name: "UFO", id: "ufocoin" },
  // umo: { name: "UniversalMolecule", id: "" },
  uno: { name: "Unobtanium", id: "unobtanium" },
  // utip: { name: "uTipcoin", id: "" },
  val: { name: "Validity", id: "radium" },
  vcn: { name: "Versacoin", id: "versacoin" },
  vls: { name: "Veles", id: "veles" },
  // vgs: { name: "LasVegasCoin", id: "" },
  via: { name: "Viacoin", id: "viacoin" },
  vrc: { name: "VeriCoin", id: "vericoin" },
  // vrm: { name: "Verium", id: "" },
  vtc: { name: "Vertcoin", id: "vertcoin" },
  wdc: { name: "Worldcoin", id: "worldcoin" },
  // wex: { name: "Wexcoin", id: "" },
  wgr: { name: "Wagerr", id: "wagerr" },
  x0z: { name: "Zerozed", id: "zerozed" },
  xbc: { name: "BitcoinPlus", id: "bitcoin-plus" },
  // xc: { name: "XCurrency", id: "" },
  xcz: { name: "XChainZ", id: "xchainz" },
  xjo: { name: "JouleCoin", id: "joulecoin" },
  xmg: { name: "Magi", id: "magi" },
  xmy: { name: "Myriad", id: "myriadcoin" },
  xp: { name: "Experience Points", id: "xp" },
  xpm: { name: "Primecoin", id: "primecoin" },
  xst: { name: "Stealth", id: "stealthcoin" },
  // trust: "TRUSTplus",
  // xvp: "VirtacoinPlus",
  zeit: { name: "ZeitCoin", id: "zeitcoin" },
  // zet: "Zetacoin",
  zln: { name: "ZillionCoin", id: "zillioncoin" },
  znz: { name: "ZENZO", id: "zenzo" },
};

export const btcAltCurrencies = Object.keys(networks).reduce(
  (acc: Record<keyof typeof networks, string>, curr) => {
    acc[curr as keyof typeof networks] = curr.toUpperCase();
    return acc;
  },
  {} as Record<keyof typeof networks, string>,
);
