import moment from "moment-timezone";
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import { type FinancialYear } from "~/contexts/FYContext";
import { useGetFinancialYearOptions } from "~/state/fyDates";
import { useGetLockPeriodEndDateIfExists } from "~/state/period";

/**
 * Control the lock period part of the settings page by the url
 * The default is the current date, unless the user supplies a default
 * end date override.
 */
export function useSettingsLockPeriod() {
  const fyDatesQuery = useGetFinancialYearOptions();
  const lastPeriodDate = useGetLockPeriodEndDateIfExists();

  // get the last completed financial year
  const lastCompletedFY = fyDatesQuery.data
    ?.sort((a, b) => moment(b.end).diff(moment(a.end)))
    .find((fy) => moment(fy.end).isBefore(moment()));

  // The override for the default end date
  const [defaultEndDateOverride, setDefaultEndDateOverride] = useQueryParam(
    "lockPeriodDefaultEndDate",
    StringParam,
  );
  // Show/hide the add panel
  const [isAdding, setIsAdding] = useQueryParam(
    "lockPeriodIsAdding",
    withDefault(BooleanParam, false),
  );

  const dateFormat = "YYYY-MM-DD";

  const defaultEndDate: string | undefined = getDefaultEndDate({
    todaysDate: new Date(),
    lastPeriodDate,
    lastCompletedFY,
    dateFormat,
  });

  /**
   * Gets the current default end date for the lock period
   * Either the override from the url, or default (today)
   */
  const getDefaultEndDateOverride = () => {
    return defaultEndDateOverride ?? defaultEndDate;
  };

  const startAddingLockPeriod = (endDate?: Date) => {
    setIsAdding(true);
    if (endDate) {
      setDefaultEndDateOverride(moment(endDate).format(dateFormat));
    }
  };

  const stopAddingLockPeriod = () => {
    setIsAdding(false);
    setDefaultEndDateOverride(undefined);
  };

  return {
    defaultEndDate,
    isAdding,
    setDefaultEndDateOverride,
    getDefaultEndDateOverride,
    startAddingLockPeriod,
    stopAddingLockPeriod,
  };
}

/**
 * Gets the default end date for the next lock period
 */
function getDefaultEndDate({
  todaysDate,
  lastPeriodDate,
  lastCompletedFY,
  dateFormat,
}: {
  todaysDate: Date;
  lastPeriodDate: Date | undefined;
  lastCompletedFY: FinancialYear | undefined;
  dateFormat: string;
}) {
  // its always the last financial year
  // unless its not complete yet
  // or its locked
  if (lastPeriodDate && lastPeriodDate > todaysDate) {
    // locked into the future, so no default date
    return undefined;
  }
  if (
    !lastCompletedFY ||
    (lastPeriodDate && lastCompletedFY.end <= lastPeriodDate)
  ) {
    // havent completed a financial year
    // or you have already locked that financial year
    return moment().format(dateFormat);
  }
  return moment(lastCompletedFY.end).format(dateFormat);
}
