import { createContext, type PropsWithChildren, useState } from "react";

import {
  type TradingStockValuationMethod,
  type TurboTaxInformationalDialogType,
} from "~/components/reconciliation/enums";
import { BGLAccountIDDialog } from "~/components/report/BGLAccountIDDialog";
import { IrsCustomReportDialog } from "~/components/report/IrsCustomReportDialog";
import { TradingStockInventorySelectorDialog } from "~/components/report/TradingStockInventorySelectorDialog";
import { TurboTaxInformationalDialog } from "~/components/report/TurboTaxInformationalDialog";

type ReportModalsContextType = {
  isOpenBGLAccountIDDialog: boolean;
  setIsOpenBGLAccountIDDialog: (newLoadingStatus: boolean) => void;
  isOpenTradingStockInventorySelectorDialog: boolean;
  setIsOpenTradingStockInventorySelectorDialog: (
    state: boolean,
  ) => Promise<TradingStockValuationMethod | undefined>;
  isOpenIRSCustomNameSSNDialog: boolean;
  setIsOpenIRSCustomNameSSNDialog: (
    state: boolean,
  ) => Promise<{ name: string; social: string }>;
  turboTaxInformationalDialogType: TurboTaxInformationalDialogType | undefined;
  setTurboTaxInformationalDialogType: (
    newStatus: TurboTaxInformationalDialogType | undefined,
  ) => void;
};

export const ReportModalsContext =
  createContext<ReportModalsContextType | null>(null);

export const ReportModalsContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [isOpenBGLAccountIDDialog, setIsOpenBGLAccountIDDialog] =
    useState(false);

  const [tradingStockDialogCallback, setTradingStockDialogCallback] = useState<
    ((valuationMethod: TradingStockValuationMethod) => void) | null
  >(null);

  const [turboTaxInformationalDialogType, setTurboTaxInformationalDialogType] =
    useState<TurboTaxInformationalDialogType | undefined>(undefined);

  const setIsOpenTradingStockInventorySelectorDialog = (state: boolean) => {
    if (!state) {
      setTradingStockDialogCallback(null);
      return Promise.resolve(undefined);
    }
    return new Promise<TradingStockValuationMethod>((resolve) => {
      setTradingStockDialogCallback(
        () => (valuationMethod: TradingStockValuationMethod) => {
          resolve(valuationMethod);
        },
      );
    });
  };

  const [IRSCustomNameSSNDialogCallback, setIRSCustomNameSSNDialogCallback] =
    useState<((args: { name: string; social: string }) => void) | null>(null);

  const setIsOpenIRSCustomNameSSNDialog = (state: boolean) => {
    if (!state) {
      setIRSCustomNameSSNDialogCallback(null);
      return Promise.resolve({ name: "", social: "" });
    }
    return new Promise<{ name: string; social: string }>((resolve) => {
      setIRSCustomNameSSNDialogCallback(
        () => (args: { name: string; social: string }) => {
          resolve(args);
        },
      );
    });
  };

  return (
    <ReportModalsContext.Provider
      value={{
        isOpenBGLAccountIDDialog,
        setIsOpenBGLAccountIDDialog,
        isOpenTradingStockInventorySelectorDialog:
          tradingStockDialogCallback !== null,
        setIsOpenTradingStockInventorySelectorDialog,
        isOpenIRSCustomNameSSNDialog: IRSCustomNameSSNDialogCallback !== null,
        setIsOpenIRSCustomNameSSNDialog,
        turboTaxInformationalDialogType,
        setTurboTaxInformationalDialogType,
      }}
    >
      <>
        {isOpenBGLAccountIDDialog && (
          <BGLAccountIDDialog isOpen setIsOpen={setIsOpenBGLAccountIDDialog} />
        )}
        {tradingStockDialogCallback !== null && (
          <TradingStockInventorySelectorDialog
            isOpen
            setIsOpen={setIsOpenTradingStockInventorySelectorDialog}
            successCallback={tradingStockDialogCallback}
          />
        )}
        {IRSCustomNameSSNDialogCallback !== null && (
          <IrsCustomReportDialog
            isOpen
            setIsOpen={setIsOpenIRSCustomNameSSNDialog}
            successCallback={IRSCustomNameSSNDialogCallback}
          />
        )}

        <TurboTaxInformationalDialog
          type={turboTaxInformationalDialogType}
          setIsOpen={setTurboTaxInformationalDialogType}
        />
      </>
      {children}
    </ReportModalsContext.Provider>
  );
};
