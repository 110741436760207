import { type InvitationDirection } from "~/types/enums";
import { InvitationStatus } from "~/types/enums";
import { type InvitationDetails } from "~/types/index";

export function getNextStatusOptions(
  invitation: InvitationDetails | null,
  direction: InvitationDirection | null,
) {
  if (!invitation || !direction) {
    return [InvitationStatus.Pending];
  }
  const { status } = invitation;
  switch (status) {
    case InvitationStatus.Pending:
      return direction === "sent"
        ? [InvitationStatus.Deleted]
        : [InvitationStatus.Accepted, InvitationStatus.Declined];
    case InvitationStatus.Accepted:
      return [InvitationStatus.Revoked];
    case InvitationStatus.Revoked:
      return direction === "sent" ? [InvitationStatus.Deleted] : null;
    case InvitationStatus.Declined:
      return direction === "sent" ? [InvitationStatus.Deleted] : null;
    case InvitationStatus.Deleted:
    default:
      return null;
  }
}
