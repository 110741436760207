import { AccessTimeOutlined } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import styled from "styled-components/macro";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

interface ImportTypeEtaBoxProps {
  /**
   * Baseline ETA in milliseconds (Lower bound)
   */
  avgMs: number | null;
  /**
   * 95th percentile ETA in milliseconds (Upper bound)
   */
  p95Ms: number | null;
}

function useLangTimeHumanizeEquivalent(humanize: string): string {
  const lang = useLang();
  const humanizeTyped =
    humanize as keyof typeof lang.etaTime.momentHumanizeEquivalent;

  const humanizeEquivalent =
    lang.etaTime.momentHumanizeEquivalent[humanizeTyped];

  // for e.g "a few seconds"
  if (humanize.startsWith("a few")) {
    return humanizeEquivalent;
  }

  if (!humanizeEquivalent) {
    const [number, timeUnit] = humanize.split(" ");

    const timeUnitTyped =
      timeUnit as keyof typeof lang.etaTime.momentHumanizeEquivalent;

    const timeUnitEquivalent =
      lang.etaTime.momentHumanizeEquivalent[timeUnitTyped];

    if (!timeUnitEquivalent) {
      return humanize;
    }

    // e.g "a minute" -> "1 minute", "an hour" -> "1 hour"
    if (["a", "an"].includes(number)) {
      return `1 ${timeUnitEquivalent}`;
    }

    return `${number} ${timeUnitEquivalent}`;
  }

  return humanize;
}

export function ImportTypeEtaBox({ avgMs, p95Ms }: ImportTypeEtaBoxProps) {
  const lang = useLang();
  const { tokens } = useDesign();
  const avg = useLangTimeHumanizeEquivalent(moment.duration(avgMs).humanize());
  const p95 = useLangTimeHumanizeEquivalent(moment.duration(p95Ms).humanize());

  if (!avgMs || !p95Ms) return null;
  if (avgMs > p95Ms) return null; // this is a sanity check to make sure the p95 is

  const title =
    avg === p95
      ? lang.etaTime.tooltipSameTime({ avgHumanizeTime: avg })
      : lang.etaTime.tooltip({
          avgHumanizeTime: avg,
          p95HumanizeTime: p95,
        });

  return (
    <Tooltip title={title}>
      <StyledEtaContainer>
        <AccessTimeOutlined
          sx={{ fontSize: "0.875rem", color: tokens.icon.low }}
        />
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          sx={{
            color: tokens.text.low,
          }}
        >
          {avg}
        </Typography>
      </StyledEtaContainer>
    </Tooltip>
  );
}

const StyledEtaContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  max-width: fit-content;
  width: auto;
  cursor: pointer;
`;
