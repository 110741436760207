import { Box } from "@mui/material";
import { type PropsWithChildren } from "react";
import styled from "styled-components/macro";

/**
 * Container for content placeholder with grid layout
 */
const Outer = styled(Box)`
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  flex-grow: 1;
  width: 100%;
`;

/**
 * Inner container for content placeholder content
 */
const Inner = styled(Box)`
  grid-row: 2;
  margin: auto;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentPlaceholder = ({
  height = 214,
  children,
}: PropsWithChildren<{
  height?: string | number;
}>) => (
  <Outer minHeight={height}>
    <Inner>{children}</Inner>
  </Outer>
);
