import { CheckCircle, DoDisturb, WatchLater } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { useIsMobile } from "~/components/ui/hooks";
import { type Tokens } from "~/components/ui/theme/tokens";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { InvitationDirection, InvitationStatus } from "~/types/enums";
import { type InvitationDetails } from "~/types/index";

export function getInvitationStatusIcon(
  invitation: InvitationDetails | null,
  direction: InvitationDirection | null,
  tokens: Tokens,
) {
  if (!invitation || !direction) {
    return null;
  }
  const { status, createdAt, acceptedAt } = invitation;
  let details: { title: string; icon: JSX.Element } | null = null;
  switch (status) {
    case InvitationStatus.Pending:
      details = {
        // TODO: add date sent
        title: `Invitation ${direction} on ${new Date(
          createdAt,
        ).toDateString()}`,
        icon: (
          <WatchLater
            style={{ color: tokens.icon.warning, fontSize: "1.25rem" }}
          />
        ),
      };
      break;
    case InvitationStatus.Accepted:
      details = {
        title: acceptedAt
          ? `Invitation accepted on ${new Date(acceptedAt).toDateString()}`
          : "Invitation accepted",
        icon: (
          <CheckCircle
            style={{ color: tokens.icon.success, fontSize: "1.25rem" }}
          />
        ),
      };
      break;
    case InvitationStatus.Declined:
      details = {
        title: "Invite declined",
        icon: (
          <DoDisturb
            style={{ color: tokens.icon.danger, fontSize: "1.25rem" }}
          />
        ),
      };
      break;
    case InvitationStatus.Revoked:
      details = {
        title: "Access revoked",
        icon: (
          <DoDisturb
            style={{ color: tokens.icon.danger, fontSize: "1.25rem" }}
          />
        ),
      };
      break;
    default:
      details = null;
      break;
  }
  if (!details) {
    return null;
  }
  return <Tooltip title={details.title}>{details.icon}</Tooltip>;
}

export function useInvitationTitle(
  invitation: InvitationDetails | null,
  direction: InvitationDirection | null,
) {
  const { status } = useLang().settings.clientData.invitation;

  if (!invitation || !direction) {
    return "Invite accountant";
  }

  switch (invitation.status) {
    case InvitationStatus.Pending:
      return status.pending({ direction });
    case InvitationStatus.Accepted:
      return status.accepted;
    case InvitationStatus.Declined:
      return status.declined;
    case InvitationStatus.Revoked:
      return status.revoked;
    default:
      return status.default;
  }
}

export function InvitationStatusDetails({
  invitation,
  verbose = false,
}: {
  invitation: InvitationDetails;
  verbose?: boolean;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  const info = {
    statusIcon: getInvitationStatusIcon(
      invitation,
      InvitationDirection.Sent,
      tokens,
    ),
  };
  const isMobile = useIsMobile();

  const getVerboseDetails = (invitation: InvitationDetails | null) => {
    if (!invitation) return "";
    const { status } = invitation;
    return lang.settings.clientData.invitation.verboseStatus[status];
  };

  return (
    <Box>
      <StyledBox>
        <IconBox>{info.statusIcon}</IconBox>
        {(verbose || !isMobile) && (
          <Box minWidth="4rem" mt="0.15rem">
            <StatusTypography variant="Metropolis/Body/Light">
              {invitation.status}
            </StatusTypography>
          </Box>
        )}
      </StyledBox>
      {verbose && (
        <DetailsBox>
          <Typography variant="Metropolis/Body/Light">
            {getVerboseDetails(invitation)}
          </Typography>
        </DetailsBox>
      )}
    </Box>
  );
}

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;

const DetailsBox = styled(Box)`
  margin-top: 1rem;
`;

const StatusTypography = styled(Typography)`
  text-transform: capitalize;
  line-height: 0;
`;
