import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Chip,
  Paper,
  type PaperProps,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components/macro";

import { importAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { AdvancedCSVOptions } from "~/components/imports/AdvancedCSVOptions";
import { ImportFormContext } from "~/components/imports/context/index";
import { useImportContext } from "~/components/imports/ImportItem";
import { ImportTypeEtaBox } from "~/components/imports/ImportTypeEtaBox";
import { OnboardingNavBar } from "~/components/imports/OnboardingNavBar";
import { SecondaryIconButton } from "~/components/ui/ui-buttons/icon-buttons/SecondaryIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useIsOnboardingMobileImportExperiment } from "~/hooks/useIsOnboardingMobileImportExperiment";
import { useDesign } from "~/hooks/useTheme";
import { middleTrim } from "~/lib/index";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import {
  useCorrectImportOption,
  useUploadCsvFilesMutation,
} from "~/state/csvs";
import { useAvailableImportOptions } from "~/state/imports";
import { useGetSavedImportsByIntegration } from "~/state/importsV2";
import { ImportMethod } from "~/types/enums";
import {
  type CSVFormData,
  type CsvImportMethod,
  type CsvRequirements,
  type ExtraCsvParams,
  type ImportOptionV2,
} from "~/types/index";

function ExtraCsvInfoForm({
  name,
  requirements,
  handleChange,
}: {
  name: string;
  requirements?: CsvRequirements;
  handleChange: (e: any) => void;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  const { currency, address } = requirements || {};
  const placeholder =
    name.toLowerCase() === "easycrypto"
      ? lang.imports.csvExtraInfo.fiatCurrency
      : lang.imports.csvExtraInfo.tickerPlaceHolder;
  const helperText =
    name.toLowerCase() === "easycrypto"
      ? lang.imports.csvExtraInfo.fiatCurrency
      : lang.imports.csvExtraInfo.currencyHelper;
  return (
    <>
      {currency ? (
        <Box mb={2}>
          <TextField
            color="primary"
            label={lang.imports.csvExtraInfo.currencySymbol}
            variant="outlined"
            placeholder={placeholder}
            helperText={helperText}
            name="currency"
            onChange={handleChange}
            required
            fullWidth
            sx={{
              backgroundColor: "transparent",
              ".MuiOutlinedInput-input": {
                backgroundColor: tokens.background.input.default,
              },
            }}
          />
        </Box>
      ) : null}
      {address ? (
        <Box mb={2}>
          <TextField
            color="primary"
            label={lang.imports.csvExtraInfo.address}
            variant="outlined"
            placeholder={lang.imports.csvExtraInfo.addressPlaceholder}
            helperText={lang.imports.csvExtraInfo.addressHelper}
            name="address"
            onChange={handleChange}
            required
            fullWidth
            sx={{
              backgroundColor: "transparent",
              ".MuiOutlinedInput-input": {
                backgroundColor: tokens.background.input.default,
              },
            }}
          />
        </Box>
      ) : null}
    </>
  );
}

function validFilename(name: string): boolean {
  return !name.match(/[\\;"'`$\n]|(\.\.)/g) && name.length < 500;
}

function manualLabelToName(label?: string): string | undefined {
  if (!label) return undefined;
  const parsedLabel = label.replaceAll("-", " ");
  return parsedLabel[0]?.toUpperCase() + parsedLabel.slice(1).toLowerCase();
}

export function ImportCSV({
  importOption,
  importMethod,
  singleFileOnly = false,
  updateSelectedLabel,
}: {
  importOption: ImportOptionV2;
  importMethod: CsvImportMethod;
  singleFileOnly?: boolean;
  updateSelectedLabel: (label: string) => void;
}) {
  const isManual = !!importOption.manual;
  const [extraParams, setExtraParams] = React.useState<
    undefined | ExtraCsvParams
  >();
  const taxSettingTimezone = useTimezone();
  const lang = useLang();
  const captureAnalytics = useCaptureAnalytics();
  const advancedOptionsKey = importAnalyticsKey("advanced options");
  const existingLabels = useAvailableImportOptions().map((o) => o.id);
  const { setSyncStarted } = useImportContext();
  const importFormContext = useContext(ImportFormContext);
  const uploadCsvFilesMutation = useUploadCsvFilesMutation(
    importOption,
    importFormContext?.selectedBlockchain || null,
  );
  const importOptionAccountingForBlockchain = useCorrectImportOption(
    importOption,
    importFormContext?.selectedBlockchain,
  );
  const savedImport = useGetSavedImportsByIntegration(
    importOptionAccountingForBlockchain.id,
  );
  const isOnboardingMobileImportExperiment =
    useIsOnboardingMobileImportExperiment();
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [isOpeningFileDialog, setIsOpeningFileDialog] = useState(false);

  const { handleSubmit, control, setValue } = useForm<CSVFormData>({
    mode: "onBlur",
    defaultValues: {
      manualName: importOption.name,
      currencies: null,
      dateFormat: "",
    },
  });

  const handleExtraCsvInfo = (e: any) => {
    const newParams = extraParams || {};
    setExtraParams({ ...newParams, [e.target.name]: e.target.value });
  };

  const onImportLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value || "";
    setValue("manualName", value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onSubmit = (formData: CSVFormData) => {
    const {
      currencies,
      dateFormat,
      manualName,
      timezone: selectedTimezone,
      importFromDate,
    } = formData;

    setSyncStarted({
      value: true,
      accountId: importOption.id,
      savedIntegration: { type: ImportMethod.CSV },
    });

    const invalidFiles = files
      .map((file) => file.name)
      .filter((name) => !validFilename(name));
    const totalSize = files.reduce(
      (totalSize, file) => totalSize + file.size,
      0,
    );
    const tickers = currencies?.map((i) => ({
      id: i.id,
      symbol: i.symbol,
    }));

    if (!files.length) {
      // Only open the file dialog if we're not already in the process of opening it
      if (fileInputRef.current && !isOpeningFileDialog) {
        setIsOpeningFileDialog(true);
        fileInputRef.current.click();
      }
      return; // Exit early so we don't proceed with the upload
    } else if (singleFileOnly && files.length > 1) {
      alert(lang.imports.csvAlerts.onlyOneFile);
    } else if (
      files.some((file) => {
        const lowercaseFileName = file.name.toLowerCase();
        return !(
          lowercaseFileName.endsWith(".csv") ||
          lowercaseFileName.endsWith(".xlsx") ||
          lowercaseFileName.endsWith(".xls")
        );
      })
    ) {
      alert(lang.imports.csvAlerts.fileTypeNotSupported);
    } else if (invalidFiles.length > 0) {
      alert(
        lang.imports.csvAlerts.invalidFileName({
          fileNames: invalidFiles.join(", "),
        }),
      );
    } else if (totalSize > 20 * 1024 * 1024) {
      alert(lang.imports.csvAlerts.fileSizeTooBig);
    } else if (importMethod.requirements?.currency && !extraParams?.currency) {
      alert(lang.imports.csvAlerts.missingCurrencySymbols);
    } else if (importMethod.requirements?.address && !extraParams?.address) {
      alert(lang.imports.csvAlerts.missingAddress);
    } else {
      const newLabel = isManual
        ? manualName?.toLowerCase().replaceAll(" ", "-") // replace spaces with dashes as BE expects this
        : undefined;
      if (newLabel) {
        updateSelectedLabel(newLabel);
      }
      if (tickers?.length || dateFormat) {
        captureAnalytics(advancedOptionsKey("modified"), {
          integration: importOption.id,
          importMethod: importMethod.type,
          dateFormat,
        });
      }

      uploadCsvFilesMutation.mutate({
        files,
        timezone: isManual
          ? selectedTimezone?.value || "UTC"
          : selectedTimezone?.value || taxSettingTimezone,
        extraParams: {
          ...extraParams,
          tickers,
          dateFormat,
          importFromDate: importFromDate || undefined,
        },
        manualLabel: newLabel,
        manualName: manualLabelToName(newLabel),
      });
      importFormContext?.setShowImportForm(false);
    }
  };
  return (
    <form
      style={{
        width: "100%",
        paddingTop: "1rem",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Hidden file input for when user submits with no files */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          // Reset the flag since file dialog is closed
          setIsOpeningFileDialog(false);

          if (e.target.files && e.target.files.length > 0) {
            const newFiles = Array.from(e.target.files);
            setFiles((prev) => [...prev, ...newFiles]);
          }
        }}
        accept=".csv,.xls,.xlsx"
        multiple={!singleFileOnly}
      />
      {importMethod.requirements && (
        <ExtraCsvInfoForm
          name={importOption.id}
          requirements={importMethod.requirements}
          handleChange={handleExtraCsvInfo}
        />
      )}
      {isManual && !savedImport?.files.length && (
        <Controller
          control={control}
          name="manualName"
          rules={{
            required: lang.manualCSV.exchangeRule,
            pattern: {
              value: /^[a-z0-9 ]+$/gi,
              message: lang.manualCSV.patternRule,
            },
            maxLength: {
              value: 35,
              message: lang.manualCSV.nameTooLong,
            },
            validate: (label?: string) =>
              !existingLabels.includes(label?.toLowerCase() || "") ||
              lang.manualCSV.nameExists,
          }}
          render={({ field, fieldState: { invalid, error } }) => {
            return (
              <TextField
                {...field}
                sx={{ mb: "0.75rem" }}
                onChange={onImportLabelChange}
                fullWidth
                variant="outlined"
                label={lang.imports.nameOfSource}
                required
                defaultValue={importOption.id}
                error={invalid}
                helperText={error?.message}
              />
            );
          }}
        />
      )}
      <SaveCSVOnDrop
        filesToUpload={files}
        setFilesToUpload={setFiles}
        singleFileOnly={singleFileOnly}
      />
      {isOnboardingMobileImportExperiment ? (
        <OnboardingNavBar
          importOption={importOption}
          importMethod={importMethod}
          handleSubmit={handleSubmit(onSubmit)}
        />
      ) : (
        <>
          <ImportTypeEtaBox
            avgMs={importMethod.avgMs}
            p95Ms={importMethod.p95Ms}
          />
          <AdvancedCSVOptions
            importOption={importOption}
            control={control}
            setValue={setValue}
          />
          <PrimaryButton sx={{ mt: "1rem" }} type="submit">
            {lang.imports.importCSV({ source: importOption.name })}
          </PrimaryButton>
        </>
      )}
    </form>
  );
}

function SaveCSVOnDrop({
  filesToUpload,
  setFilesToUpload,
  singleFileOnly,
}: {
  filesToUpload: File[];
  setFilesToUpload: (files: File[]) => void;
  singleFileOnly: boolean;
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: setFilesToUpload,
    maxFiles: singleFileOnly ? 1 : 0,
    multiple: !singleFileOnly,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <StyledPaper
        variant="outlined"
        isActive={isDragActive || filesToUpload.length > 0}
      >
        <FileUploadArea
          filesToUpload={filesToUpload}
          isDragActive={isDragActive}
          singleFileOnly={singleFileOnly}
          setFilesToUpload={setFilesToUpload}
        />
      </StyledPaper>
    </div>
  );
}

const StyledPaper = styled(
  ({ isActive, ...props }: { isActive?: boolean } & PaperProps) => (
    <Paper {...props} />
  ),
)`
  && {
    border-style: ${(props) => (props.isActive ? "solid" : "dashed")};
    border-width: 1px;
    border-color: ${(props) => {
      const theme = props.theme;
      return props.isActive
        ? theme.tokens.background.accent.purple.medium
        : theme.tokens.border.neutral.high;
    }};
    text-align: center;
    padding: 2rem;
    background-color: ${({ theme }) => theme.tokens.elevation.high};
    cursor: pointer;
    &:hover {
      border-color: ${({ theme }) =>
        theme.tokens.background.accent.purple.medium};
    }
  }
`;

function FileUploadArea({
  isDragActive,
  singleFileOnly,
  filesToUpload,
  setFilesToUpload,
}: {
  isDragActive: boolean;
  singleFileOnly: boolean;
  filesToUpload: File[];
  setFilesToUpload: (files: File[]) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const isPendingUpload = filesToUpload.length > 0;
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Typography variant="Metropolis/Header/H5">
        {isPendingUpload ? (
          <Box justifyContent="center" display="flex" flexWrap="wrap">
            {filesToUpload.map((file, i) => (
              <Box display="flex" justifyContent="space-between" key={i}>
                <Chip
                  sx={{
                    height: "auto",
                    borderRadius: "0.5rem",
                    fontSize: "1rem",
                    m: "0.25rem",
                    p: "0.75rem",
                  }}
                  label={middleTrim(file.name, 19, 8, false)}
                  variant="outlined"
                  // Remove the file at the given index.
                  deleteIcon={
                    <SecondaryIconButton
                      sx={{
                        width: "1.25rem",
                        height: "1.25rem",
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "1rem" }} />
                    </SecondaryIconButton>
                  }
                  onDelete={() => {
                    setFilesToUpload(
                      filesToUpload
                        .slice(0, i)
                        .concat(
                          filesToUpload.slice(i + 1, filesToUpload.length),
                        ),
                    );
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : isDragActive ? (
          lang.imports.dropFiles
        ) : (
          <span>
            <span
              style={{
                color: tokens.text.brand,
                cursor: "pointer",
                textDecoration: "underline",
                fontWeight: 500,
              }}
            >
              {lang.imports.browse}
            </span>{" "}
            {singleFileOnly
              ? lang.imports.selectFile
              : lang.imports.selectFiles}
          </span>
        )}
      </Typography>
    </Box>
  );
}
