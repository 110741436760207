import { useContext } from "react";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { AccountsTableContext } from "~/components/imports-v2/AccountsTable";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { useLang } from "~/redux/lang";
import { useBulkDeleteMutations } from "~/state/importsV2";
import { type SavedImportOptionByAccount } from "~/types/index";

export function DeleteImportConfirmationDialog({
  importOption,
  open,
  close,
}: {
  importOption: SavedImportOptionByAccount;
  open: boolean;
  close: () => void;
}) {
  const lang = useLang();
  const captureAnalytics = useCaptureAnalytics();
  const bulkDelete = useBulkDeleteMutations(importOption);
  const { name } = importOption;
  const accountsTableContext = useContext(AccountsTableContext);

  function handleDelete(): void {
    close();
    bulkDelete.mutate();
    captureAnalytics("exchange_deleted", { name });
  }

  function handleConfirm(confirm: boolean): void {
    close();
    if (confirm) {
      handleDelete();
      accountsTableContext?.closeDrawer();
    }
  }

  return (
    <ConfirmationDialog
      isOpen={open}
      title={lang.imports.deleteImportExchangeConfirmation.title}
      text={lang.imports.deleteImportExchangeConfirmation.body({ name })}
      actionText={lang.delete}
      handleClose={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleConfirm(false);
      }}
      handleAction={() => {
        handleConfirm(true);
      }}
      critical
    />
  );
}
