import { type ReactNode, type SyntheticEvent, useCallback } from "react";

import { bulkEditAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { useModal } from "~/components/transactions/command-palette/ModalProvider";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import { CheckboxActionType } from "~/components/transactions/filter-bar/enums";

export const useCommandPalette = () => {
  const { open: openModal, close: closeModal } = useModal();
  const { push, clear } = useNavController();
  const open = useCallback(
    (root: ReactNode, anchorEl: Element | SyntheticEvent<Element, Event>) => {
      openModal(anchorEl);
      push(root);
    },
    [openModal, push],
  );
  const close = useCallback(() => {
    closeModal();
    // wait for the modal to close
    // and then clear all the ui
    setTimeout(() => {
      clear();
    }, 300);
  }, [clear, closeModal]);
  return {
    open,
    close,
  };
};

export const useBulkEditCommandPalette = () => {
  const commands = useCommandPalette();
  const { dispatch: checkboxDispatch } = useTransactionCheckbox();
  const captureAnalytics = useCaptureAnalytics();
  const close = useCallback(() => {
    commands.close();
    setTimeout(() => {
      checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
    }, 100);
  }, [checkboxDispatch, commands]);
  const open = useCallback(
    (root: ReactNode, anchorEl: Element | SyntheticEvent<Element, Event>) => {
      commands.open(root, anchorEl);
      captureAnalytics(bulkEditAnalyticsKey("modal open"));
    },
    [commands, captureAnalytics],
  );
  return {
    ...commands,
    close,
    open,
  };
};
