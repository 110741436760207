import { Env } from "@ctc/types";
import { createBrowserHistory } from "history";
import ReactGA from "lachie-ga4";
import { type UaEventOptions } from "lachie-ga4/types/ga4";

import { Constants } from "~/constants/enums";

const isProd = import.meta.env.VITE_APP_ENV === Env.Prod;

/**
 * Initializes page view tracking using the browser history.
 * This function sets up a listener on the browser history that triggers a
 * Google Analytics page view event each time the user navigates to a new page.
 * It also supports tracking of URL fragments, often used for tracking campaign attributions.
 */
const initPageViewTracking = () => {
  const browserHistory = createBrowserHistory();
  browserHistory.listen((location) => {
    const page = location.pathname;
    const fragment = location.hash;

    if (isProd) {
      // Initialize page view tracking
      ReactGA.set({ page, fragment }); // Update the user's current page

      // Record a pageview for the given page
      ReactGA.send({
        hitType: "pageview",
        page,
        fragment,
      });

      // Support fragment tracking (e.g. user came from Finder ad if url ends in #finder)
      if (fragment) {
        ReactGA.event({
          category: "Fragment",
          action: `fragment: ${fragment}`,
          label: fragment,
        });
      }
    } else {
      console.log(`Page view tracked: ${page}`);
      if (fragment) console.log(`Fragment tracked: ${fragment}`);
    }
  });
};

/**
 * Initializes Google Analytics with the Measurement ID and Gtag URL
 * Only initializes if the application environment is production.
 * Logs a message in the console if not in production.
 */
export const initGoogleAnalytics = (): void => {
  if (isProd) {
    ReactGA.initialize(Constants.GoogleAnalyticsMeasurementID, {
      gtagUrl: Constants.GoogleAnalyticsGtagUrl,
    });
  } else {
    console.log("Google Analytics not initialized - not in production");
  }

  initPageViewTracking();
};

/**
 * Sets the user ID for Google Analytics.
 * This is typically called when a user logs in to tie subsequent events to the user's ID.
 * @param {string} uid - The unique user identifier
 */
export const identifyGoogleUser = (uid: string): void => {
  if (isProd) {
    ReactGA.set({ user_id: uid });
  } else {
    console.log("identifyGoogleUser", uid);
  }
};

/**
 * Captures a basic Google Analytics event.
 * @param {UaEventOptions} params - Event options such as category, action, label, and value
 */
export const captureGoogleAnalytics = (params: UaEventOptions): void => {
  if (isProd) {
    ReactGA.event(params);
  } else {
    console.log("captureGoogleAnalytics", params);
  }
};

/**
 * Captures an enhanced Google Analytics event, allowing for more fine-grained parameters.
 * All accepted events and associated parameters are documented here:
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
 *
 * NOTE: for most events, captureGoogleAnalytics should be sufficient.
 *
 * @param {string} eventName - The name of the event (e.g., 'purchase')
 * @param {string} eventCategory - The category of the event (e.g., 'user engagement')
 * @param {Record<string, unknown> | undefined} params - Additional parameters to include with the event
 */
export const captureGoogleAnalyticsEnhanced = (
  eventName: string,
  eventCategory: string,
  params: Record<string, unknown> | undefined,
): void => {
  if (isProd) {
    ReactGA.gtag(eventName, eventCategory, params);
  } else {
    console.log(
      "captureGoogleAnalyticsEnhanced",
      eventName,
      eventCategory,
      params,
    );
  }
};

/**
 * Returns a function for capturing a basic Google Analytics event.
 * Use this in components when you want to log an event.
 * @returns {Function} - A function that takes an UaEventOptions object and captures the event
 */
export const useCaptureGoogleAnalytics = () => {
  return (params: UaEventOptions) => {
    captureGoogleAnalytics(params);
  };
};

/**
 * Returns a function for capturing an enhanced Google Analytics event, allowing for more fine-grained parameters.
 * All accepted events and associated parameters are documented here:
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
 *
 * NOTE: for most events, useCaptureGoogleAnalytics should be sufficient.
 *
 * @returns {Function} - A function that takes an eventName, eventCategory, and params object and captures the event
 */
export const useCaptureGoogleAnalyticsEnhanced = () => {
  return (
    eventName: string,
    eventCategory: string,
    params: Record<string, unknown> | undefined,
  ) => {
    captureGoogleAnalyticsEnhanced(eventName, eventCategory, params);
  };
};
