import { ListSubheader } from "@mui/material";
import { type AutocompleteRenderGroupParams } from "@mui/material/Autocomplete";
import {
  Children,
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from "react";
import { type ListChildComponentProps, VariableSizeList } from "react-window";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

export const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

// Adapter for react-window
export const ListboxComponent = forwardRef<
  HTMLDivElement,
  {
    itemSize?: number;
    overscanCount?: number;
    children?: React.ReactNode;
    itemsToShow?: number;
  }
>(function ListboxComponent(props, ref) {
  const {
    children,
    itemSize = 48,
    overscanCount = 5,
    itemsToShow = 8,
    ...other
  } = props;
  const itemData = Children.toArray(children);
  const itemCount = itemData.length;

  const getChildSize = () => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > itemsToShow) {
      return itemsToShow * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={overscanCount}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
