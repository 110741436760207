import { BulkEditCurrencyChipIcon } from "~/components/transactions/command-palette/BulkEditCurrencyChipIcon";
import { DualChip } from "~/components/transactions/command-palette/views/DualChip";
import { useLang } from "~/redux/lang";
import { type CurrencyIdentifier } from "~/types/index";

export const CurrencyTopSection = ({
  fromCurrency,
  toCurrency,
}: {
  fromCurrency?: CurrencyIdentifier;
  toCurrency?: CurrencyIdentifier;
}) => {
  const lang = useLang();
  return (
    <DualChip
      leftLabel={fromCurrency ? fromCurrency.symbol : lang.txTable.currency}
      leftIcon={
        fromCurrency ? (
          <BulkEditCurrencyChipIcon currency={fromCurrency} />
        ) : undefined
      }
      rightLabel={toCurrency ? toCurrency.symbol : lang.txTable.currency}
      rightIcon={
        toCurrency ? (
          <BulkEditCurrencyChipIcon currency={toCurrency} />
        ) : undefined
      }
    />
  );
};
