import { type Blockchain } from "@ctc/types";

import { type TableHeaders } from "~/components/imports-v2/enums";
import { type OptionsType } from "~/components/transactions/TransactionRow";
import { type Size } from "~/types/enums";
import { type SavedImportOptionByAccount } from "~/types/index";

export type SortType = {
  field: TableHeaders;
  ascending: boolean;
};

export type MoreOptionsDialogProps = {
  importOption: SavedImportOptionByAccount;
  size?: Size;
};

export enum ImportOptionAction {
  Delete = "delete",
  Update = "update",
  Edit = "edit",
  HardSync = "hard_sync",
  SyncHistory = "sync-history",
  Sync = "sync",
  ViewTransactions = "view_transactions",
  RelatedWallets = "related_wallets",
  DownloadCSV = "download_csv",
}

export interface ImportDrawerOptionsType extends OptionsType {
  id: ImportOptionAction;
  children?: React.ReactNode;
  tooltipTitle?: string;
}

/**
 * In the onboarding process we only want a subset of import actions.
 * e.g. hard-sync or sync history don't make sense in onboarding
 */
export const validOnboardingImportOptions = [
  ImportOptionAction.Sync,
  ImportOptionAction.Delete,
  ImportOptionAction.Edit,
  ImportOptionAction.Update,
];

/**
 * Legacy type for use in a three dots menu. Doesn't typeguard the id.
 */
export interface DrawerOptionsType extends OptionsType {
  id: string;
  children?: React.ReactNode;
  tooltipTitle?: string;
}

export type RelatedChainsForAddress = Record<
  string,
  Set<Blockchain> | undefined
>;
