import { EventSource, type ReportFormat, TaxHubClient } from "@ctc/types";

import { isWindowEmbedded } from "~/hooks/useIsEmbedded";
import { type ReportDownloadType } from "~/types/index";

/**
 * All the analytic events we can capture
 */
export const AnalyticEvent = {
  REPORT_PAGE: {
    VIEW: "report_page:viewed",
    REPORT_DOWNLOAD_PACK_BUTTON_CLICKED:
      "report_page:report_download_pack_button_clicked",
  },
} as const;

/**
 * Any extra properties included in the event
 */
export type EventProperties = {
  [AnalyticEvent.REPORT_PAGE.REPORT_DOWNLOAD_PACK_BUTTON_CLICKED]: {
    reportFormat: ReportFormat;
    reportDownloadTypes: ReportDownloadType[];
    packId: string;
    packType: string;
    // If the report is paywalled, it will be a sample
    isSample: boolean;
  };
};

/**
 * Determines the tax hub client (embedded context) based on URL patterns
 *
 * @returns The identified referrer source for the embedded context, or undefined if not determined
 */
export function determineTaxHubClient(): TaxHubClient | undefined {
  const isEmbedded = isWindowEmbedded();
  if (!isEmbedded) return undefined;

  const { hostname } = window.location;

  // Check URL patterns to identify the embedding platform
  if (hostname.includes(TaxHubClient.MetaMask)) {
    return TaxHubClient.MetaMask;
  }

  if (hostname.includes(TaxHubClient.Coinbase)) {
    return TaxHubClient.Coinbase;
  }

  if (hostname.includes(TaxHubClient.Kraken)) {
    return TaxHubClient.Kraken;
  }

  if (hostname.includes(TaxHubClient.Binance)) {
    return TaxHubClient.Binance;
  }

  return undefined;
}

/**
 * Determines the source of the event based on the current context of the user.
 * For example, if the user is on the TaxHub, the event source is TaxHub.
 * If the user is on the WebApp, the event source is WebApp.
 *
 * This can be extended to include other sources in the future.
 */

export function determineEventSource(): EventSource {
  const isEmbedded = isWindowEmbedded();
  return isEmbedded ? EventSource.TaxHub : EventSource.WebApp;
}
