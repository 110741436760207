import { LocalCurrency, SupportedLang, Trade } from "@ctc/types";
import BigNumber from "bignumber.js";

import {
  LangToLanguageCode,
  LocalLanguageCode,
  SMALL_VALUE_THRESHOLD,
} from "~/constants/constants";
import { TradeInfo } from "~/lib/tradeTypeDefinitions";
import { TaxDivision, TradeDirection } from "~/types/enums";
import {
  type CurrencyIdentifier,
  type TaxSettings,
  type TransactionDetails,
} from "~/types/index";

export function minus(a: number, b: number): number {
  return new BigNumber(a).minus(new BigNumber(b)).toNumber();
}

export function plus(a: number, b: number): number {
  return new BigNumber(a).plus(new BigNumber(b)).toNumber();
}

export function multiply(a: number, b: number): number {
  return new BigNumber(a).multipliedBy(new BigNumber(b)).toNumber();
}

export function divide(a: number, b: number): number {
  return new BigNumber(a).dividedBy(new BigNumber(b)).toNumber();
}

export function round(value: number, decimals: number): number {
  return new BigNumber(value).decimalPlaces(decimals).toNumber();
}

// parse floats safely
export function safeFloat(n: string | number): number {
  if (!n) {
    return 0;
  }
  if (typeof n === "number") {
    return n;
  }
  return parseFloat(n.split(",").join("")); // some exchanges add commas in floats
}

/**
 * Determines if the currency is a fiat currency
 */
export function isFiatCurrency(baseCurrency: string): boolean {
  return Object.values(LocalCurrency).includes(baseCurrency as LocalCurrency);
}

export function displayCryptoQuantity({
  quantity,
  locale,
  precision = 8,
  displayLessThan = false,
}: {
  quantity: number;
  locale: SupportedLang;
  precision?: number;
  displayLessThan?: boolean;
}): string {
  const localeString =
    locale !== SupportedLang.En ? LangToLanguageCode[locale] : undefined;
  if (quantity === 0) return "0";
  const bound = Math.pow(0.1, precision);
  const lessThanDefaultMin = Math.abs(quantity) < bound;
  if (lessThanDefaultMin && displayLessThan) {
    return `< ${bound.toFixed(precision)}`;
  }
  return parseFloat(
    round(quantity, precision).toFixed(precision),
  ).toLocaleString(localeString, {
    maximumFractionDigits: precision,
  });
}

/**
 * Display the quantity of the asset, always round to 8
 */
export function displayQuantity(
  quantity: number,
  locale: SupportedLang,
): string {
  return displayCryptoQuantity({ quantity, locale });
}

export function displayScientificNotation(
  quantity: number,
  digits = 3,
): string {
  return quantity.toExponential(digits).replace(/e\+|e/, " x 10^");
}

function getDefaultFractionDigits(value: number, fallBackMin: number) {
  if (value === 0 || Math.abs(value) < 0.00000001) {
    return 2;
  }
  if (Math.abs(value) < 0.05) {
    return 8;
  }
  if (Math.abs(value) < 1) {
    return 6;
  }
  return fallBackMin;
}

export function displayFiatCompactValue(
  value: number,
  localCurrency: LocalCurrency | undefined,
  locale = SupportedLang.En,
  precision = 2,
): string {
  if (!localCurrency) {
    return value.toLocaleString(locale, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
  }

  const formatString = getFormat(locale, localCurrency);
  const formatter = new Intl.NumberFormat(formatString, {
    style: "currency",
    notation: "compact",
    compactDisplay: "short",
    currency: localCurrency as string,
    minimumFractionDigits: value > 9999 ? 1 : 0, //if over 10k show more significant figures (e.g. 10.2k instead of 10k)
    // trailingZeroDisplay: "stripIfInteger", // could replace regex below but doesn't seem to be implemented
  });
  const formatedString = formatter.format(value);
  return formatedString.replace(/\.0+(\D?)$/, "$1"); // remove trailing zeros but keep the compact notation
}

export function displayFiatValue({
  value,
  localCurrency,
  locale = SupportedLang.En,
  fallbackMinFractionDigits = 2,
  disableCurrencySymbol = false,
  fractionDigits,
  roundUp = false,
}: {
  value: number;
  localCurrency: LocalCurrency | undefined;
  locale?: SupportedLang;
  fallbackMinFractionDigits?: number;
  disableCurrencySymbol?: boolean;
  /** The number of fraction digits to display (both the min and max number)  */
  fractionDigits?: number;
  /** Round the value to the nearest 0.05 e.g. 0.001 becomes 0.05 */
  roundUp?: boolean;
}): string {
  if (Number.isNaN(value)) {
    return "N/A";
  }
  if (!localCurrency) {
    return value.toFixed(2);
  }
  try {
    const formatString = getFormat(locale, localCurrency);

    const adjustedFractionDigits =
      fractionDigits ??
      getDefaultFractionDigits(value, fallbackMinFractionDigits);

    const formatter = new Intl.NumberFormat(formatString, {
      style: "currency",
      maximumFractionDigits: adjustedFractionDigits,
      minimumFractionDigits: adjustedFractionDigits,
      currency: localCurrency as string,
    });

    const lessThanDefaultMin = Math.abs(value) < SMALL_VALUE_THRESHOLD;
    if (value === 0) {
      const minValueFormatter = new Intl.NumberFormat(formatString, {
        style: "currency",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        currency: localCurrency as string,
      });
      return minValueFormatter.format(0);
    }
    if (lessThanDefaultMin) {
      if (disableCurrencySymbol) {
        return "< 0.01";
      }
      // Create a formatter specifically for the minimum value with 2 decimal places
      const minValueFormatter = new Intl.NumberFormat(formatString, {
        style: "currency",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        currency: localCurrency as string,
      });
      const formattedMinValue = minValueFormatter.format(0.01);
      return `< ${formattedMinValue}`;
    }

    if (disableCurrencySymbol) {
      return value.toLocaleString(LocalLanguageCode[localCurrency], {
        maximumFractionDigits:
          formatter.resolvedOptions().maximumFractionDigits,
      });
    }

    const roundedValue = roundUp ? roundUpToNearestPoint05(value) : value;

    return formatter.format(roundedValue);
  } catch (error) {
    // In case of an error, return the raw number as a string
    return value.toString();
  }
}

function roundUpToNearestPoint05(value: number) {
  return Math.ceil(value / 0.05) * 0.05;
}

export function displayFiatSymbol(
  localCurrency: LocalCurrency | undefined,
  locale = SupportedLang.En,
): string | null {
  if (!localCurrency) {
    return null;
  }
  const formatString = getFormat(locale, localCurrency);
  const formatter = new Intl.NumberFormat(formatString, {
    style: "currency",
    currency: localCurrency as string,
  });
  const currencySymbol = formatter
    .formatToParts(1)
    .find((x) => x.type === "currency")?.value;

  return currencySymbol === undefined ? null : currencySymbol;
}

function getFormat(locale: SupportedLang, localCurrency: LocalCurrency) {
  if (locale === SupportedLang.En) {
    return LocalLanguageCode[localCurrency];
  }
  return LangToLanguageCode[locale];
}

export function displayCurrency(
  str: string | undefined,
  threshold = 16,
  sliceTo = 12,
) {
  if (!str) {
    return "";
  }
  if (str.length > threshold) {
    return `${str.slice(0, sliceTo)}...`;
  }
  return str;
}

/**
 * Displays the currency as a nice name, taking into account any NFT tokens
 * @param currencyIdentifier
 */
export function displayCurrencyName(
  currencyIdentifier: CurrencyIdentifier,
  showNftId = false,
  threshold?: number,
  sliceTo?: number,
): string {
  let name: string;
  if (
    currencyIdentifier.name.toLowerCase() ===
    currencyIdentifier.symbol.toLowerCase()
  ) {
    name = displayCurrency(
      currencyIdentifier.symbol,
      threshold || 16,
      sliceTo || 14,
    );
  } else {
    name = `${displayCurrency(
      currencyIdentifier.name,
      threshold || 12,
      sliceTo || 10,
    )} (${displayCurrency(
      currencyIdentifier.symbol,
      threshold || 16,
      sliceTo || 14,
    )})`;
  }
  if (currencyIdentifier.nftId && showNftId) {
    name = `${name} #${displayCurrency(
      currencyIdentifier.nftId,
      threshold || 8,
      sliceTo || 8,
    )}`;
  }
  return name;
}

export function middleTrim(
  str: string,
  max = 20,
  sideSize = 3,
  format = true,
): string {
  const formatted = format ? str.replace(/\s\(.*\)/, "") : str;
  if (formatted.length > max) {
    // \u2026 is the unicode for an ellipsis
    return `${formatted.slice(0, sideSize)}\u2026${formatted.slice(-sideSize)}`;
  }
  return formatted;
}

export function ellipsis(str: string, maxLength = 12): string {
  if (str.length > maxLength) {
    // \u2026 is the unicode for an ellipsis
    return `${str.slice(0, maxLength)}\u2026`;
  }
  return str;
}

export function geIdAndBlockchain(
  exchangeId: string,
): [string, string | undefined] {
  const [id, blockchain] = exchangeId.split("__");
  return [id.split("-")[0], blockchain];
}

// We do not return id in this function because the id can be based off the tokens name
// in the case we don't have that currency which can be named differently by different APIs
export function createCurrencyIdentifierKey(cid: CurrencyIdentifier) {
  if (cid.contractAddress) {
    return cid.contractAddress.toLowerCase();
  }
  return cid.symbol.toLowerCase();
}

export function getTransferTrades(taxSettings: TaxSettings) {
  return Object.values(Trade).filter(
    (trade) =>
      TaxDivision.Transfer === TradeInfo[trade].taxDivision(taxSettings),
  );
}

export function getOwnedAccountFromTx(tx: TransactionDetails): {
  exchange: string;
  displayName: string;
} {
  return {
    exchange:
      TradeInfo[tx.trade].direction === TradeDirection.In ? tx.to : tx.from,
    displayName:
      TradeInfo[tx.trade].direction === TradeDirection.In
        ? tx.toDisplayName
        : tx.fromDisplayName,
  };
}

export function getIsMacOs() {
  const userAgent = window.navigator.userAgent;
  return /Mac/i.test(userAgent);
}
