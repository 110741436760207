import { OpenInNew } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";

import { getTxIdLink } from "~/components/transactions/helpers";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { formatFunctionName } from "~/services/transactions";
import { type TransactionDetails } from "~/types/index";

export function CategorisationFunctionFooter({
  chipLinkPairs,
}: {
  chipLinkPairs: FunctionLinkPair[];
}) {
  const { tokens } = useDesign();
  const lang = useLang();

  if (chipLinkPairs.length === 0) return null;

  return (
    <Box display="flex" gap="0.5rem" alignItems="center" flexWrap="wrap">
      <Typography color={tokens.text.low} variant="Metropolis/Caption/Medium/Regular" noWrap>
        {lang.txTable.commandPallet.placeholders.tradeTypeSearch.hint}
      </Typography>
      {chipLinkPairs.map(({ functionName, link }) => {
        return (
          <Box
            display="flex"
            bgcolor={tokens.background.accent.neutral.low}
            p="0.125rem 0.5rem"
            alignItems="center"
            gap=" 0.25rem"
            borderRadius="1.5rem"
            key={`${functionName}-${link}`}
            {...(link
              ? {
                  component: Link,
                  href: link,
                  target: "_blank",
                  rel: "noopener noreferrer",
                }
              : {})}
            sx={{ textDecoration: "none" }}
          >
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              sx={{
                maxWidth: "12.5rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              noWrap
            >
              {formatFunctionName(functionName)}
            </Typography>
            {link ? (
              <OpenInNew style={{ fontSize: "1rem", color: tokens.text.low }} />
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
}

type FunctionLinkPair = {
  functionName: string;
  link: string | null;
};

export function getChipPairs(txs: TransactionDetails[]): FunctionLinkPair[] {
  const functionNames = new Set<string>();

  return txs.reduce((acc: FunctionLinkPair[], tx) => {
    const pair = getFunctionLinkPairs(tx);
    if (pair && !functionNames.has(pair.functionName)) {
      functionNames.add(pair.functionName);
      return acc.concat(pair);
    }
    return acc;
  }, []);
}

function getFunctionLinkPairs(
  tx: TransactionDetails,
): FunctionLinkPair | undefined {
  const { id, blockchain, currencyIdentifier, functionName } = tx;

  if (!functionName) return undefined;

  const link = getTxIdLink(id, currencyIdentifier.symbol, blockchain);

  return {
    functionName,
    link,
  };
}
