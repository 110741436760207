import {
  havePartnerEmployeeBenefits,
  type PartnerWithEmployeeBenefit,
} from "@ctc/types";
import { ArrowForward } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { loadUser, useUser } from "~/redux/auth";
import { employeeDiscount } from "~/services/subscription";
import { Links } from "~/types/enums";

import { LoadUserType } from "../../redux/enums";
import { useLang } from "../../redux/lang";
import { REFERRER_CONFIG } from "../payment/referrer-config";
import { PrimaryButton } from "../ui/ui-buttons/PrimaryButton";
import { ConfirmEmail } from "./ConfirmEmail";
import { PartnerBenefitApplied } from "./PartnerBenefitApplied";
import { Header } from "./ui/Header";
import { StyledBox } from "./ui/StyledBox";
import { StyledContainer } from "./ui/StyledContainer";
/**
 * Component for partner benefit verification
 */
export function PartnerBenefit() {
  const user = useUser();
  const lang = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [partner, setPartner] = useState<PartnerWithEmployeeBenefit | null>(
    null,
  );
  const urlParams = new URLSearchParams(window.location.search);
  const partnerParam = urlParams.get("partner");
  const statusParam = urlParams.get("status");
  const [submitted, setSubmitted] = useState(false);
  const [errorCode, setErrorCode] = useState<number>();

  // Get partner from URL query parameter and validate
  useEffect(() => {
    // Check if the partner is in our enum
    if (partnerParam && havePartnerEmployeeBenefits(partnerParam)) {
      setPartner(partnerParam as PartnerWithEmployeeBenefit);
    } else {
      // If no partner or invalid partner, redirect back to app
      navigate(Links.Imports);
    }
  }, [navigate, partnerParam, setPartner]);

  if (!partner) {
    return null;
  }

  if (statusParam === "applied") {
    return <PartnerBenefitApplied />;
  }

  const partnerLabel = REFERRER_CONFIG[partner]?.label ?? partner;

  // If user has submitted the form or has work email stored, show the confirm email component
  if (
    (submitted && !loading) ||
    (user?.partnerEmployeeDetails?.workEmail &&
      user?.partnerEmployeeDetails?.partner === partner)
  ) {
    return (
      <ConfirmEmail
        partner={partner}
        errorCode={errorCode}
        setSubmitted={setSubmitted}
      />
    );
  }

  const onSubmit = async () => {
    setLoading(true);
    setSubmitted(true);
    // Validate email domain for the partner and send discount email if valid
    const response = await employeeDiscount({
      partner,
      email,
    });
    if (response.status !== 200) {
      setErrorCode(response.status);
    } else {
      dispatch(loadUser(LoadUserType.UserUpdate));
      setErrorCode(undefined);
    }
    setLoading(false);
  };

  return (
    <StyledContainer>
      <Header partner={partner} />

      <StyledBox sx={{ alignItems: "flex-start" }}>
        <Typography variant="Metropolis/Header/H4">
          {lang.partnerEmployeeBenefits.discount.cta}
        </Typography>

        <StyledTextField
          variant="outlined"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          fullWidth
          disabled={loading}
          placeholder={lang.partnerEmployeeBenefits.verifyEmail.placeholder({
            partner: partnerLabel,
          })}
          helperText={lang.partnerEmployeeBenefits.verifyEmail.helperText({
            partner: partnerLabel,
          })}
        />

        <PrimaryButton
          onClick={onSubmit}
          disabled={loading}
          fullWidth
          endIcon={<ArrowForward />}
        >
          {lang.partnerEmployeeBenefits.verifyEmail.cta}
        </PrimaryButton>
      </StyledBox>
    </StyledContainer>
  );
}

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;
  }
  .MuiInputBase-input {
    font-size: 0.875rem;
    padding: 1rem 0.5rem;
  }
  .MuiInputBase-root {
    background: ${({ theme }) => theme.tokens.background.input.default};
    border-radius: 0.25rem;
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }
  .MuiFormHelperText-root {
    font-size: 0.625rem;
    color: ${({ theme }) => theme.tokens.text.low};
    margin-left: 0;
  }
`;
