import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { CurrencyTopSection } from "~/components/transactions/command-palette/views/change-currency/CurrencyTopSection";
import { type BulkEditCurrencyOptions } from "~/components/transactions/command-palette/views/change-currency/types";
import { invariant } from "~/lib/invariant";
import { useNewBulkUpdateCurrencyActionsMutation } from "~/state/actions";
import { BulkOperations } from "~/types/enums";
import { type CurrencyIdentifier } from "~/types/index";

export const Confirmation = ({
  fromCurrency,
  toCurrency,
  bulkEditOption,
}: {
  fromCurrency: CurrencyIdentifier;
  toCurrency: CurrencyIdentifier;
  bulkEditOption: BulkEditCurrencyOptions;
}) => {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");
  const bulkUpdateCurrencyActionsMutation =
    useNewBulkUpdateCurrencyActionsMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption,
  });
  const onConfirm = () => {
    bulkUpdateCurrencyActionsMutation.mutate(
      {
        type:
          bulkEditOption === BulkEditOptions.ChangeCurrency
            ? BulkOperations.ChangeCurrency
            : BulkOperations.ChangeFeeCurrency,
        filter,
        fromCurrency,
        toCurrency,
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={bulkEditOption} />
          <CurrencyTopSection
            fromCurrency={fromCurrency}
            toCurrency={toCurrency}
          />
        </BulkEditChips>
      }
      onConfirm={onConfirm}
      loading={bulkUpdateCurrencyActionsMutation.isPending}
    />
  );
};
