import { Blockchain, LocalCurrency } from "@ctc/types";
import { MenuItem, Select, type SelectChangeEvent } from "@mui/material";

import { DefaultBlockchainCurrency, isBlockchain } from "~/types/index";

/**
 * set value in
 * 1. local currency (if not USD)
 * 2. USD
 * 3. ETH
 * 4. Blockchain native currency (if not ETH)
 *  */
export function TxValueReferenceCurrencySelector({
  value,
  localCurrency,
  blockchain,
  setValue,
}: {
  value: string;
  localCurrency: LocalCurrency | undefined;
  blockchain: string | undefined; // string as its from TransactioDetails
  setValue: (value: string) => void;
}) {
  const handleChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      sx={{
        fontSize: "0.875rem",
        maxHeight: "3.3125rem",
        "& .MuiSelect-select": {
          height: "1.25rem",
          minHeight: "unset",
        },
      }}
    >
      <MenuItem key={localCurrency} value={localCurrency}>
        {localCurrency}
      </MenuItem>
      {localCurrency !== LocalCurrency.USD && (
        <MenuItem key={LocalCurrency.USD} value={LocalCurrency.USD}>
          {LocalCurrency.USD}
        </MenuItem>
      )}
      <MenuItem
        key={DefaultBlockchainCurrency[Blockchain.ETH]}
        value={DefaultBlockchainCurrency[Blockchain.ETH]}
      >
        {DefaultBlockchainCurrency[Blockchain.ETH]}
      </MenuItem>
      {blockchain &&
        isBlockchain(blockchain) &&
        DefaultBlockchainCurrency[blockchain] !==
          DefaultBlockchainCurrency[Blockchain.ETH] && (
          <MenuItem
            key={DefaultBlockchainCurrency[blockchain]}
            value={DefaultBlockchainCurrency[blockchain]}
          >
            {DefaultBlockchainCurrency[blockchain]}
          </MenuItem>
        )}
    </Select>
  );
}
