import { type LocalCurrency } from "@ctc/types";
import { Box, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

import { ActionHeaderCell } from "~/components/transactions/action-row/ActionHeaderCell";
import { ActionRowCell } from "~/components/transactions/action-row/ActionRowCell";
import { ActionRowHover } from "~/components/transactions/action-row/ActionRowHover";
import { EnDash } from "~/components/transactions/action-row/EnDash";
import { ActionTableGridArea } from "~/components/transactions/action-row/enums";
import { getCompactFiatValue } from "~/components/transactions/action-row/getCompactFiatValue";
import { getCompactFormattedValue } from "~/components/transactions/action-row/helpers";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { Warnings } from "~/components/transactions/action-row/Warnings";
import { EditActionAmountsDialog } from "~/components/transactions/edit/EditActionAmounts";

import { useDesign } from "~/hooks/useTheme";
import { displayFiatSymbol, displayFiatValue, multiply } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { getMissingPrice } from "~/services/actions";
import { Align, QuickEditFocusField, Sort, Warning } from "~/types/enums";
import { type ActionRow, type TransactionDetails } from "~/types/index";

import { ActionRowFirstLineBox } from "./Overflower";

function getTxValue(tx: TransactionDetails): number {
  return multiply(tx.quantity, tx.price);
}

function getRowValue(row: ActionRow): number {
  // for group summaries use the precalcuated totals from the BE
  if (
    row.incomingGroupSummaries?.totalValue !== undefined ||
    row.outgoingGroupSummaries?.totalValue !== undefined
  ) {
    const incomingValue = row.incomingGroupSummaries?.totalValue ?? 0;
    const outgoingValue = row.outgoingGroupSummaries?.totalValue ?? 0;
    return Math.max(incomingValue, outgoingValue);
  }

  // If no group summaries, calculate based on individual transactions
  const txs = [...row.incoming, ...row.outgoing].map(getTxValue);
  return txs.sort((a, b) => b - a)[0];
}

export function ActionRowValue({
  row,
  disablePointerEvents = false,
}: ActionRowCellProps) {
  const { tokens } = useDesign();
  const [isValueDialogueOpen, setIsValueDialogueOpen] = useState(false);
  const languagePreference = useLanguagePreference();
  const localCurrency = useLocalCurrency();

  const txs = [...row.incoming, ...row.outgoing];

  // Just in case.
  if (!txs.length) return null;

  const isMissingPrice = txs.some((tx) =>
    getMissingPrice(tx.errors, tx.ignoreWarning, tx.price),
  );

  const value = getRowValue(row);
  const showEnDash = value === 0 && !isMissingPrice;
  const valueDisplay = getCompactFiatValue(value, 2);
  const compactValue = getCompactFormattedValue(
    languagePreference,
    value,
    localCurrency as LocalCurrency,
    valueDisplay.toString(),
  );

  return (
    <>
      <EditActionAmountsDialog
        row={row}
        isOpen={isValueDialogueOpen}
        handleClose={() => {
          setIsValueDialogueOpen(false);
        }}
        focusField={QuickEditFocusField.Value}
      />
      <ActionRowCell
        id={ActionTableGridArea.Value}
        align={Align.Right}
        disablePointerEvents={disablePointerEvents}
      >
        {showEnDash ? (
          <ActionRowFirstLineBox>
            <EnDash />
          </ActionRowFirstLineBox>
        ) : (
          <Box display="flex">
            <ActionRowHover
              onClick={(e) => {
                e.stopPropagation();
                setIsValueDialogueOpen(true);
              }}
              style={{ position: "relative" }}
            >
              <Tooltip
                title={`${displayFiatValue({
                  value,
                  localCurrency,
                  locale: languagePreference,
                  fallbackMinFractionDigits: 6,
                })}`}
                placement="top"
              >
                <Typography
                  variant="Metropolis/Caption/Medium/Regular"
                  sx={{
                    fontFamily: tokens.fontFamilies.numeric,
                  }}
                >
                  {compactValue}
                </Typography>
              </Tooltip>
              <Box style={{ position: "absolute", right: "-1.375rem" }}>
                <Warnings
                  row={row}
                  txIds={[
                    ...row.incoming.map((tx) => tx._id),
                    ...row.outgoing.map((tx) => tx._id),
                  ]}
                  warnings={[Warning.MissingPrice]}
                />
              </Box>
            </ActionRowHover>
          </Box>
        )}
      </ActionRowCell>
    </>
  );
}

export function ActionHeaderValue() {
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const symbol = displayFiatSymbol(localCurrency, languagePreference);
  return (
    <Box style={{ gridArea: ActionTableGridArea.Value }}>
      <ActionHeaderCell
        sort={{
          sortAscending: Sort.ValueAscending,
          sortDescending: Sort.ValueDescending,
        }}
        align={Align.Right}
      >
        {lang.txTable.value} {symbol ? `(${symbol})` : null}
      </ActionHeaderCell>
    </Box>
  );
}
