import { useQuery } from "@tanstack/react-query";

import { displayMessage } from "~/components/ui/Toaster";
import { HttpError } from "~/services/core";
import * as invitationsAPI from "~/services/invitations";
import {
  DisplayMessage,
  InvitationDirection,
  InvitationStatus,
} from "~/types/enums";
import {
  type IndividualInvitations,
  type InvitationDetails,
} from "~/types/index";

// Define query keys
export const invitationKeys = {
  all: () => ["invitations"] as const,
  list: () => [...invitationKeys.all(), "list"] as const,
};

const fetchInvitations = async () => {
  const res = await invitationsAPI.getInvitations();
  if (res.error) {
    displayMessage({
      message: "Something went wrong while getting invitations",
      type: DisplayMessage.Error,
    });
    throw new HttpError(res, ["getInvitiations"]);
  }
  return res.data;
};

export const useSupportInvitation = () => {
  return useQuery({
    queryKey: invitationKeys.list(),
    queryFn: fetchInvitations,
    select: (data) => data?.support?.[0] ?? null,
  });
};

export const useBestInvitation = () => {
  return useQuery({
    queryKey: invitationKeys.list(),
    queryFn: fetchInvitations,
    select: selectBestInvitation,
  });
};

/**
 * Given invitations that may be sent or received, select the best one.
 * The best one is the one that was created first.
 */
export function selectBestInvitation(data: IndividualInvitations | undefined): {
  invitation: InvitationDetails | null;
  type: InvitationDirection | null;
} {
  if (!data) {
    return { invitation: null, type: null };
  }

  const isNotHiddenStatus = ({ status }: InvitationDetails) =>
    ![InvitationStatus.Declined, InvitationStatus.Revoked].includes(status);

  const sentInvitations = data.sent.map((invitation) => ({
    ...invitation,
    type: InvitationDirection.Sent,
  }));

  const receivedInvitations = data.received
    .filter(isNotHiddenStatus)
    .map((invitation) => ({
      ...invitation,
      type: InvitationDirection.Received,
    }));

  const allInvitations = [...sentInvitations, ...receivedInvitations];

  if (allInvitations.length === 0) {
    return { invitation: null, type: null };
  }

  const bestInvitation = allInvitations.sort(
    (a, b) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
  )[0];

  const { type, ...invitation } = bestInvitation;

  return {
    invitation,
    type,
  };
}
