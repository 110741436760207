export enum EditApiMode {
  Update = "update",
  Nickname = "nickname",
}
export enum TableHeaders {
  Account = "account",
  Synced = "synced",
  Type = "type",
  Tx = "tx",
  Assets = "assets",
  Balance = "balance",
  Spacer = "spacer",
}
