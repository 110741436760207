import { Box } from "@mui/material";

import { StyledImportTitleTypography } from "~/components/imports/api";
import { EntityAddresses } from "~/components/ui/EntityAddresses";
import { useLang } from "~/redux/lang";
import { useFindEntityQuery } from "~/state/entities";
import { useLoadingSavedImports } from "~/state/imports";
import { EntityType } from "~/types/enums";

export const EntityAddressList = ({
  exchangeName,
  exchangeId,
}: {
  exchangeName: string;
  exchangeId: string;
}) => {
  const lang = useLang();
  const isLoading = useLoadingSavedImports();
  const entityQuery = useFindEntityQuery((entity) => {
    return (
      (entity.type === EntityType.Exchange ||
        entity.type === EntityType.Extension) &&
      entity.ref === exchangeId
    );
  });
  const entity = entityQuery.data.entity;

  if (!entity || entity.addresses.length === 0 || isLoading) {
    return null;
  }

  return (
    <Box>
      <StyledImportTitleTypography>
        {lang.api.addressesIdentified({ exchangeName })}
      </StyledImportTitleTypography>
      <Box mt="0.5rem" mb="1rem">
        <EntityAddresses entity={entity} />
      </Box>
    </Box>
  );
};
