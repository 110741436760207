import { CaptchaProvider, Plan, ReferrerSource } from "@ctc/types";
import { Alert, Box, Link, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { useExperimentVariant } from "~/analytics/posthog";
import poweredByCtcDark from "~/assets/powered-by-ctc-dark.svg";
import poweredByCtcLight from "~/assets/powered-by-ctc-light.svg";
import { LanguageButton } from "~/components/nav/LanguageButton";
import { MetamaskOfferIntro } from "~/components/onboarding-v2/MetamaskOnboardingOfferIntro";
import { Breaker } from "~/components/ui/Breaker";
import { SuccessIcon } from "~/components/ui/Icons";
import { LogoSpinner } from "~/components/ui/LogoSpinner";
import { PasswordTextField } from "~/components/ui/PasswordTextField";
import { StyledNoLineLink } from "~/components/ui/StyledLink";

import { displayMessage } from "~/components/ui/Toaster";
import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { MetamaskWalletAuthButton } from "~/components/user/components/MetamaskWalletAuthButton";
import { EmbeddedNonChromiumBlock } from "~/components/user/EmbeddedNonChromiumBlock";
import { useIsChromiumOrNewerSafari184 } from "~/components/user/hooks/useIsChromiumOrNewerSafari184";
import { useIsPreLoginFeatureFlagsLoaded } from "~/components/user/hooks/useIsPreLoginFeatureFlagsLoaded";
import { Recaptcha } from "~/components/user/Recaptcha";
import { AlertWarningIcon } from "~/components/user/SignupForm";
import { Turnstile } from "~/components/user/Turnstile";
import {
  formatEmail,
  isValidEmail,
  stripSpacesOnKeyDown,
} from "~/components/user/validators";
import { AnalyticsUTMParams } from "~/constants/constants";
import { LocalStorageKey } from "~/constants/enums";
import { useCaptcha } from "~/hooks/useCaptchaVerification";
import { useIsEmbeddedOAuthAvailable } from "~/hooks/useIsEmbeddedOAuthAvailable";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useDesign, useResolvedTheme } from "~/hooks/useTheme";
import { passwordChecker } from "~/lib/passwordChecker";
import { signup, useAuth, useWalletOAuthLogin } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Analytics } from "~/segment/index";
import { DisplayMessage, FeatureFlag, Links, Theme } from "~/types/enums";

const AuthContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 58rem;
  background: ${({ theme }) => theme.tokens.elevation.default};
`;

const AuthFormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  max-width: 27.25rem;
  width: 27.25rem;
  background: ${({ theme }) => theme.tokens.elevation.low};
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  border-radius: 1rem 1rem 0 0;
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const EmbeddedSignupForm = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const dispatch = useDispatch();
  const { loading, error } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const params = useParams<Record<string, string>>();
  const { isLoading: isLoadingWalletAuth } = useWalletOAuthLogin();
  const isMobile = useIsMobile();
  const [showOffer, setShowOffer] = useState(true);

  const [passwordError, setPasswordError] = useState("");
  const isEmbeddedOAuthAvailable = useIsEmbeddedOAuthAvailable();

  const {
    recaptchaRef,
    setTurnstileCaptcha,
    getCaptchaToken,
    captchaProvider,
    turnstileCaptcha,
  } = useCaptcha();

  // Wait for the feature flags to be loaded before rendering the form
  const isFeatureFlagsLoaded = useIsPreLoginFeatureFlagsLoaded();

  // Redirects to handle which page is seen post-signup, depending on which
  // experiment variant the user is in
  const [redirect, setRedirect, unsetRedirect] = useLocalStorage(
    LocalStorageKey.LoginRedirect,
    { redirect: "" },
  );

  const isFormValid =
    isValidEmail(email) && !passwordError && password.length > 0;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      displayMessage({
        message: lang.auth.invalidCombination,
        type: DisplayMessage.Error,
      });
      return;
    }

    const captcha = await getCaptchaToken({ login: false });
    if (!captcha) {
      return; // Error already displayed by the hook
    }

    const validPassword = passwordChecker(password);
    if (validPassword.error) {
      setPasswordError(lang.password[validPassword.msg]);
      return;
    }

    const queryParams = Object.keys(params)
      .filter(
        (param) =>
          AnalyticsUTMParams.includes(param) ||
          (param.endsWith("clid") && param.length < 20),
      )
      .reduce((obj, k) => ({ ...obj, [k]: params[k] }), {});

    const formattedEmail = formatEmail(email);

    const analytics = await Analytics.getInstance();
    analytics.track({
      event: "embedded_sign_up_button_clicked",
      props: { email: formattedEmail, ...queryParams },
    });

    const signupData = {
      email: formattedEmail,
      password,
      captcha,
      captchaProvider,
      paidPlan: Plan.Free,
    };

    dispatch(signup(signupData));
  };

  useEffect(() => {
    const handlePasswordChange = () => {
      if (password) {
        const validPassword = passwordChecker(password);
        if (validPassword.error) {
          setPasswordError(lang.password[validPassword.msg]);
        } else {
          setPasswordError("");
        }
      }
    };
    handlePasswordChange();
  }, [password, lang.password]);

  const localStorageReferrer = localStorage.getItem(
    LocalStorageKey.ReferrerSource,
  );
  const isMetamaskEmbedded =
    localStorageReferrer === ReferrerSource.MetamaskEmbedded;

  const preSignupNewText = useExperimentVariant(
    FeatureFlag.TaxHubOfferPreSignupWithMetamask,
    "new_copy",
  );
  const preSignupOldTextText = useExperimentVariant(
    FeatureFlag.TaxHubOfferPreSignupWithMetamask,
    "original_copy",
  );

  const showPreSignup = preSignupNewText || preSignupOldTextText;

  // If the user is in the "view info pre signup" variant, we redirect them
  // directly to the country select page, bypassing the offer info page, since
  // they already saw it pre-signup
  useEffect(() => {
    if (isMetamaskEmbedded && showPreSignup) {
      if (redirect.redirect !== Links.OnboardingSelectCountry) {
        setRedirect({ redirect: Links.OnboardingSelectCountry });
      }
    } else {
      unsetRedirect();
    }
  }, [isMetamaskEmbedded, showOffer, showPreSignup]);

  const isChromiumOrNewerSafari = useIsChromiumOrNewerSafari184();

  if (!isChromiumOrNewerSafari) {
    return <EmbeddedNonChromiumBlock />;
  }

  if (!isFeatureFlagsLoaded) {
    return <LogoSpinner />;
  }

  if (isMetamaskEmbedded && showOffer && showPreSignup) {
    return (
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <MetamaskOfferIntro
          onConfirm={() => {
            setShowOffer(false);
          }}
          showMetamaskSignup
        />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      minWidth="100vw"
      padding="1.5rem"
      sx={{
        backgroundColor: tokens.elevation.default,
      }}
    >
      <PoweredByCtcAuthContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginBottom="1.75rem"
        >
          <Box display="flex" flexDirection="column" gap="0.25rem">
            <Typography
              variant={
                isMobile ? "Metropolis/Header/H4" : "Metropolis/Header/H3"
              }
            >
              {lang.auth.embedded.title}
            </Typography>

            <Typography variant={"Metropolis/Body/Regular"}>
              {lang.auth.alreadyAccount}{" "}
              <StyledNoLineLink to={Links.Login}>
                {lang.auth.login}
              </StyledNoLineLink>
            </Typography>
          </Box>

          <LanguageButton />
        </Box>

        {isEmbeddedOAuthAvailable ? (
          <Box display="flex" flexDirection="column" gap="0.5rem" width="100%">
            <MetamaskWalletAuthButton
              isLoading={isLoadingWalletAuth}
              text={lang.auth.embedded.connectWith({
                provider: lang.auth.metamask,
              })}
            />

            <Breaker
              text={lang.auth.orSignup}
              background={tokens.elevation.low}
            />
          </Box>
        ) : null}

        <form
          onSubmit={handleSubmit}
          style={{
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {error ? <Alert severity="error">{error}</Alert> : null}
          <TextField
            autoComplete="off"
            variant="outlined"
            name="email"
            type="email"
            label={lang.auth.email}
            required
            fullWidth
            onKeyDown={stripSpacesOnKeyDown}
            onChange={(e) => {
              setEmail(formatEmail(e.target.value));
            }}
            value={email}
          />
          <PasswordTextField
            variant="outlined"
            name="password"
            label={lang.auth.password}
            autoComplete="new-password"
            required
            fullWidth
            error={!!passwordError}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            inputProps={{ maxLength: 64, minLength: 8 }}
            value={password}
          />
          {password ? (
            <Box height="1rem">
              {passwordError && (
                <Box display="flex" alignItems="center">
                  <AlertWarningIcon />
                  <Typography
                    color={tokens.text.danger}
                    variant="Metropolis/Caption/Medium/Regular"
                  >
                    {passwordError}
                  </Typography>
                </Box>
              )}
              {!passwordError && (
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <SuccessIcon size="0.8rem" />
                  <Typography
                    variant="Metropolis/Caption/Medium/Regular"
                    color={tokens.text.default}
                  >
                    {lang.auth.strongPassword}
                  </Typography>
                </Box>
              )}
            </Box>
          ) : null}
          <Box display="flex" sx={{ mt: 1, mb: "0.5rem" }}>
            <Box display="flex" alignItems="center">
              <Typography variant="Metropolis/Caption/Medium/Regular">
                {lang.auth.iAgree}{" "}
                <Link
                  href="https://cryptotaxcalculator.io/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                >
                  {lang.auth.termsAndConditions}
                </Link>{" "}
                {lang.auth.and}{" "}
                <Link
                  href="https://cryptotaxcalculator.io/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                >
                  {lang.auth.privacyPolicy}
                </Link>
                .
              </Typography>
            </Box>
          </Box>
          {captchaProvider === CaptchaProvider.Turnstile ? (
            <Box padding="0.0625rem">
              <Turnstile onVerify={setTurnstileCaptcha} />
            </Box>
          ) : null}
          <PrimaryLoadingButton
            type="submit"
            disabled={
              loading ||
              !isFormValid ||
              (captchaProvider === CaptchaProvider.Turnstile &&
                !turnstileCaptcha)
            }
            loading={loading}
            fullWidth
            css={`
              height: 2.75rem;
              border-radius: 62.5rem;
            `}
          >
            <Typography variant={"Metropolis/Header/H5"} color="inherit">
              {lang.auth.signup}
            </Typography>
          </PrimaryLoadingButton>
        </form>
      </PoweredByCtcAuthContainer>
      {captchaProvider === CaptchaProvider.Recaptcha ? (
        <Box marginTop="1rem">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </Box>
      ) : null}
    </Box>
  );
};

export const PoweredByCtcAuthContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <AuthContentContainer>
      <AuthFormWrapper>{children}</AuthFormWrapper>
      <PoweredByCtcFormFooter />
    </AuthContentContainer>
  );
};

export const PoweredByCtcFormFooter = () => {
  const { tokens } = useDesign();
  const theme = useResolvedTheme();
  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      height="2.5rem"
      borderRadius="0 0 1rem 1rem"
      padding="0.3rem"
      sx={{
        background: tokens.elevation.high,
        border: `1px solid ${tokens.border.neutral.default}`,
        borderTop: 0, // remove top border
      }}
    >
      <img
        src={theme === Theme.Dark ? poweredByCtcDark : poweredByCtcLight}
        alt="Powered by CTC"
      />
    </Box>
  );
};
