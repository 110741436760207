import {
  binanceLight,
  coinbaseLight,
  ctcDark,
  ctcLight,
  krakenLight,
  metaMaskDark,
  metaMaskLight,
} from "~/components/ui/theme/generated";
import { BrandStyle, type ResolvedTheme, Theme } from "~/types/enums";

/**
 * Dark mode tokens for both CTC and MetaMask
 */
export type DarkModeTokens = typeof ctcDark | typeof metaMaskDark;

/**
 * Light mode tokens for both CTC and MetaMask
 */
export type LightModeTokens =
  | typeof ctcLight
  | typeof metaMaskLight
  | typeof coinbaseLight;

/**
 * Union of all possible tokens
 */
export type Tokens = DarkModeTokens | LightModeTokens;

/**
 * Theme configuration mapping for different brand styles
 */
const TOKEN_CONFIG = {
  [BrandStyle.CTC]: {
    color: {
      [Theme.Dark]: ctcDark,
      [Theme.Light]: ctcLight,
    },
    colorModes: [Theme.Dark, Theme.Light],
  },
  // Metamask and Coinbase are the same for light and dark mode
  [BrandStyle.MetaMask]: {
    color: {
      [Theme.Dark]: metaMaskDark,
      [Theme.Light]: metaMaskLight,
    },
    colorModes: [Theme.Dark, Theme.Light],
  },
  [BrandStyle.Coinbase]: {
    color: {
      [Theme.Dark]: coinbaseLight,
      [Theme.Light]: coinbaseLight,
    },
    colorModes: [Theme.Light],
  },
  [BrandStyle.Kraken]: {
    color: {
      [Theme.Dark]: krakenLight,
      [Theme.Light]: krakenLight,
    },
    colorModes: [Theme.Light],
  },
  [BrandStyle.Binance]: {
    color: {
      [Theme.Light]: binanceLight,
      [Theme.Dark]: binanceLight,
    },
    colorModes: [Theme.Light],
  },
} as const satisfies Record<
  BrandStyle,
  {
    /**
     * Valid color modes for the brand style e.g. light and dark
     * Color modes are in order of default preference. If the user does not select
     * a color mode preference, the first color mode will be used.
     */
    colorModes: ResolvedTheme[];
    color: Record<ResolvedTheme, DarkModeTokens | LightModeTokens>;
  }
>;

/**
 * Gets the theme tokens based on brand style and theme mode
 * @param brandStyle The brand style to use (CTC or Metamask), defaults to CTC
 * @param mode The resolved theme mode (Light or Dark)
 * @returns The corresponding theme tokens
 */
export const getTokens = (
  brandStyle: BrandStyle,
  mode: ResolvedTheme,
): Tokens => {
  const { color } = TOKEN_CONFIG[brandStyle] ?? TOKEN_CONFIG[BrandStyle.CTC];
  return color[mode];
};

/**
 * Gets the valid color modes for a brand style
 * @param brandStyle The brand style to get valid color modes for
 * @returns The valid color modes for the brand style
 */
export const getValidColorModes = (brandStyle: BrandStyle): ResolvedTheme[] => {
  return TOKEN_CONFIG[brandStyle]?.colorModes;
};
