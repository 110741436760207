import { OAuthProvider } from "@ctc/types";
import { useEffect, useState } from "react";
import { type Connector, useConnect } from "wagmi";

import { OAuthLoginButton } from "~/components/user/OAuthLoginButton";

/**
 * Custom hook to check if the provider is ready
 */
const useProviderReady = (connector: Connector) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const checkProvider = async () => {
      try {
        await connector.getProvider();
      } catch (error) {
        // swallow error
      }
      setIsReady(true);
    };

    checkProvider();
  }, [connector]);

  return isReady;
};

/**
 * Metamask wallet auth button for embedded signup and login
 *
 * Uses the Reown AppKit WalletButton https://docs.reown.com/appkit/react-native/core/hooks#useappkit
 * This selects only metamask and opens it directly
 * @returns Metamask wallet auth button
 */
export function MetamaskWalletAuthButton({
  isLoading,
  text,
  rightIcon = false,
}: {
  /** The loading state that combines metamask + the backend auth call */
  isLoading: boolean;
  text: string;
  rightIcon?: boolean;
}) {
  const { connectors, connect } = useConnect();
  const metamaskConnector = connectors[0];

  // Use the custom hook to check if the provider is ready
  const isReady = useProviderReady(metamaskConnector);

  return (
    <OAuthLoginButton
      text={text}
      action={() => {
        connect({ connector: metamaskConnector });
      }}
      oAuthProvider={OAuthProvider.Moralis}
      loading={isLoading}
      disabled={!isReady}
      rightIcon={rightIcon}
    />
  );
}
