import { type Trade } from "@ctc/types";

import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { GainsAccountTopSection } from "~/components/transactions/command-palette/views/change-gains-account/GainsAccountTopSection";
import { invariant } from "~/lib/invariant";
import { type ErpAccount } from "~/redux/types";
import { useNewBulkOpMutation } from "~/state/actions";
import { BulkOperations } from "~/types/enums";

export const Confirmation = ({
  tradeType,
  erpGainsAccount,
}: {
  tradeType: Trade;
  erpGainsAccount: ErpAccount;
}) => {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");

  const bulkOp = useNewBulkOpMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.ChangeGainsAccount,
  });

  const onConfirm = () => {
    bulkOp.mutate(
      {
        filter,
        operation: {
          type: BulkOperations.ChangeErpGainsAccount,
          erpAccountCode: erpGainsAccount.code,
          tradeType,
        },
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={
        <GainsAccountTopSection
          tradeType={tradeType}
          gainsAccount={erpGainsAccount}
        />
      }
      onConfirm={onConfirm}
      loading={bulkOp.isPending}
    />
  );
};
