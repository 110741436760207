import { contracts } from "@ctc/contracts";
import { initClient as initTsRestClient, tsRestFetchApi } from "@ts-rest/core";

import { isCsrfError } from "~/services/core";

import { csrfTokenManager } from "../../lib/csrfTokenManager";
import { CORE } from "./uri";

const maxRetries = 1;
// Initialize the TS-Rest client
export const client = initTsRestClient(contracts, {
  baseUrl: CORE,
  baseHeaders: {},
  credentials: "include",
  api: async (args) => {
    // Handles adding, refreshing the csrf token
    // retries a request if the csrf token is stale
    let csrfToken = await csrfTokenManager.initializeCsrfToken();
    let retryCount = 0;

    function makeRequest() {
      return tsRestFetchApi({
        ...args,
        headers: {
          ...args.headers,
          ...(csrfToken ? { "x-csrf-token": csrfToken } : {}),
        },
      });
    }
    // First attempt
    let res = await makeRequest();

    // Retry on CSRF errors up to maxRetries times
    while (res.status === 403 && retryCount < maxRetries) {
      try {
        const data = res.body;
        if (
          typeof data !== "object" ||
          !data ||
          !("msg" in data) ||
          typeof data.msg !== "string"
        ) {
          break;
        }

        if (!isCsrfError(data.msg)) {
          break; // Not a CSRF error, stop retrying
        }
        // The CSRF token we used for this request is stale
        csrfTokenManager.clearStaleToken(csrfToken);
        csrfToken = await csrfTokenManager.initializeCsrfToken();
        res = await makeRequest();
        retryCount++;
      } catch (e) {
        console.error("CSRF retry failed:", e);
        break;
      }
    }

    return res;
  },
});
