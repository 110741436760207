import { Box, ListItemIcon, ListItemText } from "@mui/material";
import type * as React from "react";
import { Link } from "react-router-dom";

import { StyledListItem } from "~/components/nav/AccountMenu";
import {
  isButtonItemDetails,
  isLinkItemDetails,
  type StepItemDetails,
} from "~/components/nav/types";
import { useDesign } from "~/hooks/useTheme";
import { Align } from "~/types/enums";

export const AccountItem = ({
  onClick,
  item,
  align = Align.Right,
  leftIcon,
  rightIcon,
  disabled = false,
}: {
  onClick: () => void;
  item: StepItemDetails;
  align?: Align;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  disabled?: boolean;
}) => {
  const { tokens } = useDesign();

  if (!isLinkItemDetails(item) && !isButtonItemDetails(item)) {
    return null;
  }

  const props = isLinkItemDetails(item)
    ? {
        component: Link,
        to: item.to,
      }
    : {};

  const onClickWrapped = () => {
    if (isButtonItemDetails(item)) {
      item.onClick();
    }
    onClick();
  };

  return (
    <StyledListItem onClick={onClickWrapped} disabled={disabled}>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent={align === Align.Right ? "flex-end" : "flex-start"}
        sx={{ textDecoration: "none" }}
        {...props}
      >
        {leftIcon ? (
          <ListItemIcon
            style={{
              marginLeft: "-0.5rem",
              minWidth: "1.75rem",
              fontSize: "inherit",
              color: tokens.text.default,
            }}
          >
            {leftIcon}
          </ListItemIcon>
        ) : null}
        <Box display="flex" alignItems="center" gap="0.5rem">
          <ListItemText
            primary={<span style={{ fontSize: "1rem" }}>{item.name}</span>}
          />
          {isLinkItemDetails(item) && item.endAdornment
            ? item.endAdornment
            : null}
        </Box>
        {rightIcon ? (
          <ListItemIcon
            style={{
              minWidth: "0rem",
              fontSize: "inherit",
              color: tokens.text.default,
              marginLeft: "0.25rem",
            }}
          >
            {rightIcon}
          </ListItemIcon>
        ) : null}
      </Box>
    </StyledListItem>
  );
};
