import { Blockchain, Plan } from "@ctc/types";
import { ErrorOutline, InfoOutlined } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { type CSSProperties, memo, type ReactNode } from "react";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import styled from "styled-components/macro";

import { type Severity } from "~/components/dashboard/enums";
import {
  CensoredBox,
  CensoredTooltip,
} from "~/components/ui/CensoredComponents";
import { OutlinedWarning } from "~/components/ui/Icons";

import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign } from "~/hooks/useTheme";
import {
  displayCryptoQuantity,
  displayCurrency,
  displayFiatValue,
  displayScientificNotation,
  middleTrim,
} from "~/lib/index";
import {
  useLocalCurrency,
  useLocalCurrencySymbol,
  useUser,
} from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { getUserPaidPlan } from "~/services/user";
import { BalanceError, Links } from "~/types/enums";
import { type BalanceType } from "~/types/enums";
import { type CurrencyIdentifier } from "~/types/index";

const FIXED_SIZE_LIST_CHILDREN_ITEM_IN_PX = 50;

export type BalanceWarning = {
  severity: Severity;
  type: BalanceError;
  currencyIdentifier?: CurrencyIdentifier;
  cryptoDiff: number;
  fiatDiff?: number;
};

// TODO add an import lastSyncSuccess for when this struct is used in the (1 currency - many imports) scenario
export type HoldingsBreakdownRowProps = {
  id: string;
  image: ReactNode;
  name: string; // display name, can be Exchange/address or currency name
  subName?: string;
  price?: number;
  value: number;
  balance: number;
  balanceType: BalanceType;
  warning?: BalanceWarning;
  link: string;
  // this data struct is always associated with an import/currency pair and thus should have both
  source: string;
  walletDetails?: {
    blockchain: string;
  };
  currencyIdentifier: CurrencyIdentifier;
};

const HoldingsBreakdownRow = memo(
  ({
    data,
    index,
    style,
  }: {
    data: {
      holdings: HoldingsBreakdownRowProps[];
    };
    index: number;
    style?: CSSProperties;
  }) => {
    const isEmbedded = useIsEmbedded();
    const lang = useLang();
    const localCurrency = useLocalCurrency();
    const languagePreference = useLanguagePreference();
    const currencySymbol = useLocalCurrencySymbol();
    const { tokens } = useDesign();

    const row = data.holdings[index];
    const {
      image,
      name,
      subName,
      price,
      value,
      balance: quantity,
      warning,
      link,
      currencyIdentifier,
      walletDetails,
    } = row;
    if (!!warning && !warning?.currencyIdentifier)
      warning.currencyIdentifier = currencyIdentifier;

    const SCI_NO_THRESHOLD = 18;
    // Get all the strings we might need
    const priceString = displayFiatValue({
      value: price || 0,
      localCurrency,
      locale: languagePreference,
      fallbackMinFractionDigits: 2,
      disableCurrencySymbol: true,
    });
    const priceSciNo = `${currencySymbol}${displayScientificNotation(
      price || 0,
    )}`;

    const valueString = displayFiatValue({
      value,
      localCurrency,
      locale: languagePreference,
      fallbackMinFractionDigits: 2,
      disableCurrencySymbol: true,
    });
    const valueSciNo = `${currencySymbol}${displayScientificNotation(value)}`;
    const quantityString = displayCryptoQuantity({
      quantity: quantity || 0,
      locale: languagePreference,
    });
    const quantitySciNo = displayScientificNotation(quantity || 0);

    // We want to show a special message for Solana wallets holding SOL.
    const isSolanaOnSolanaChain = [
      Blockchain.Solana,
      Blockchain.SolanaV2,
    ].includes(walletDetails?.blockchain as Blockchain);

    const MAX_NAME_LENGTH = 16; // Define a max length for truncation
    const trimmedName =
      name.length > MAX_NAME_LENGTH ? middleTrim(name, 6) : name;
    const trimmedSubName =
      subName && subName.length > MAX_NAME_LENGTH
        ? middleTrim(subName, 6)
        : subName;

    const nameTypography = (
      <Typography
        variant={"IBM Plex Mono/Caption/Medium/Regular"}        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {trimmedName}
      </Typography>
    );

    const nameContent = (
      <Box display="flex" alignItems="center">
        {image}
        <CensoredBox ml="0.25rem" display="flex" alignItems="center">
          <CensoredTooltip title={`${name}${subName ? ` (${subName})` : ""}`}>
            {nameTypography}
          </CensoredTooltip>
          {subName ? (
            <CensoredTooltip title={subName}>
              <Typography
                variant={"IBM Plex Mono/Caption/Medium/Regular"}                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {`(${trimmedSubName})`}
              </Typography>
            </CensoredTooltip>
          ) : null}
          {isSolanaOnSolanaChain && (
            <Tooltip title={lang.imports.solanaBalanceWarning} placement="top">
              <InfoOutlined
                style={{
                  height: "1rem",
                  width: "1rem",
                  marginLeft: "0.5rem",
                  color: tokens.icon.low,
                }}
              />
            </Tooltip>
          )}
        </CensoredBox>
      </Box>
    );

    return (
      <TableRow
        style={style}
        showDivider={!(index === data.holdings.length - 1)}
        component={Link}
        target={isEmbedded ? "_self" : "_blank"}
        to={link}
        rel="noopener noreferrer"
        sx={{ textDecoration: "none" }}
        enableHover
      >
        <TableCell display="flex" alignItems="center">
          {nameContent}
        </TableCell>
        <TableCell
          sx={{ textAlign: "right" }}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Box display="flex" alignItems="center" gap="0.25rem">
            <Typography
              variant={"IBM Plex Mono/Caption/Medium/Regular"}            >
              {quantityString.length > SCI_NO_THRESHOLD
                ? quantitySciNo
                : quantityString}
            </Typography>
            {warning?.type === BalanceError.Inconsistent && (
              <BalanceWarningIcon warning={warning} />
            )}
            {warning?.type === BalanceError.Negative && (
              <NegativeBalanceWarning />
            )}
          </Box>
        </TableCell>
        <TableCell sx={{ textAlign: "right" }}>
          <Typography
            variant={"IBM Plex Mono/Caption/Medium/Regular"}          >
            {price
              ? priceString.length > SCI_NO_THRESHOLD
                ? priceSciNo
                : priceString
              : lang.na}
          </Typography>
        </TableCell>
        <TableCell
          sx={{ textAlign: "right" }}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant={"IBM Plex Mono/Caption/Medium/Regular"}            >
              {price
                ? valueString.length > SCI_NO_THRESHOLD
                  ? valueSciNo
                  : valueString
                : lang.na}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  },
);

export function HoldingsBreakdownDropdown({
  firstColumnTitle,
  rows,
}: {
  firstColumnTitle: string;
  rows: HoldingsBreakdownRowProps[];
  enableSort?: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const currencySymbol = useLocalCurrencySymbol();

  const itemCount = rows.length;
  const itemSize = FIXED_SIZE_LIST_CHILDREN_ITEM_IN_PX;
  const itemKey = (
    index: number,
    data: { holdings: HoldingsBreakdownRowProps[] },
  ) => {
    const item = data.holdings[index];
    return item.id;
  };

  const sorter = (
    a: HoldingsBreakdownRowProps,
    b: HoldingsBreakdownRowProps,
  ) => {
    return b.value - a.value;
  };

  const sortedHoldings = rows.sort(sorter);

  const Table = () =>
    rows.length === 0 ? (
      <Box>
        <Typography
          variant={"IBM Plex Mono/Caption/Medium/Regular"}          sx={{
            marginLeft: "1rem",
            paddingTop: "1.25rem",
            paddingBottom: "1.25rem",
          }}
        >
          {lang.holdingsDropdown.noHoldings}
        </Typography>
      </Box>
    ) : (
      <Box width="100%" minWidth="43.75rem">
        <Box width="100%">
          <TableRow showDivider>
            <HeaderCell sx={{ justifyContent: "flex-start" }}>
              <Typography
                variant={"IBM Plex Mono/Caption/Medium/Regular"}              >
                {`${firstColumnTitle} (${rows.length})`}
              </Typography>
            </HeaderCell>
            <HeaderCell sx={{ justifyContent: "flex-end" }}>
              <Typography
                variant={"IBM Plex Mono/Caption/Medium/Regular"}              >
                {lang.holdingsDropdown.quantity}
              </Typography>
              <Tooltip title={lang.holdingsDropdown.quantityTooltip}>
                <Box display="flex" ml="0.25rem" mt="0.175rem">
                  <InfoOutlined style={{ fontSize: "0.875rem" }} />
                </Box>
              </Tooltip>
            </HeaderCell>
            <HeaderCell sx={{ justifyContent: "flex-end" }}>
              <Typography
                variant={"IBM Plex Mono/Caption/Medium/Regular"}              >
                {localCurrency
                  ? `${lang.holdingsDropdown.price} (${currencySymbol})`
                  : lang.holdingsDropdown.price}
              </Typography>
            </HeaderCell>
            <HeaderCell sx={{ justifyContent: "flex-end" }}>
              <Typography
                variant={"IBM Plex Mono/Caption/Medium/Regular"}              >
                {localCurrency
                  ? `${lang.holdingsDropdown.value} (${currencySymbol})`
                  : lang.holdingsDropdown.value}
              </Typography>
            </HeaderCell>
          </TableRow>
        </Box>
        <Box height={Math.min(280, itemCount * itemSize)} width="100%">
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                itemCount={itemCount}
                itemSize={itemSize}
                width={width}
                itemData={{
                  holdings: sortedHoldings,
                }}
                itemKey={itemKey}
                overscanCount={10}
              >
                {HoldingsBreakdownRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      </Box>
    );

  return (
    <Box
      sx={{
        borderRadius: "0.25rem",
        backgroundColor: tokens.elevation.low,
      }}
      maxWidth="43.75rem"
      overflow="auto"
      width="100%"
    >
      <Box width="100%">
        <Table />
      </Box>
    </Box>
  );
}

function GoToTipsOrSubscribe({ userPlan }: { userPlan: Plan }) {
  const lang = useLang();

  const breakdownListTooltip =
    lang.dashboard.holdings.breakdownListTooltip.review;

  return userPlan === Plan.Free ? (
    <>
      <Link to={Links.Payment} target="_self">
        {breakdownListTooltip.subscribe}
      </Link>
      {` ${breakdownListTooltip.access}`}
    </>
  ) : null;
}

export function NegativeBalanceWarning({
  iconOverride,
}: {
  iconOverride?: ReactNode;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  const userPlan = getUserPaidPlan(user);

  return (
    <Box component="span" mb="-0.375rem">
      <Tooltip
        title={
          <Box display="flex" mr="0.25rem" mb="0.25rem">
            <Typography variant="inherit" color={tokens.special.tooltipText}>
              {`${lang.dashboard.holdings.breakdownListNegative} `}
              <GoToTipsOrSubscribe userPlan={userPlan} />
            </Typography>
          </Box>
        }
      >
        <Box>
          {iconOverride ? (
            iconOverride
          ) : (
            <ErrorOutline color="error" sx={{ fontSize: "0.875rem" }} />
          )}
        </Box>
      </Tooltip>
    </Box>
  );
}

export function BalanceWarningIcon({
  warning,
  iconOverride,
}: {
  warning: BalanceWarning | undefined;
  iconOverride?: ReactNode;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const user = useUser();
  const userPlan = getUserPaidPlan(user);

  if (!warning) return null;
  if (warning.severity === 0) return null;

  const tooltipContent =
    !!warning.fiatDiff && warning.fiatDiff > 0
      ? lang.dashboard.holdings.breakdownListTooltip.warningBalanceMoreThan
      : !!warning.fiatDiff && warning.fiatDiff < 0
        ? lang.dashboard.holdings.breakdownListTooltip.warningBalanceLessThan
        : warning.cryptoDiff > 0
          ? lang.dashboard.holdings.breakdownListTooltip
              .warningBalanceNoMarketPriceMoreThan
          : lang.dashboard.holdings.breakdownListTooltip
              .warningBalanceNoMarketPriceLessThan;

  return (
    <Box display="flex">
      <Tooltip
        title={
          <Box display="flex" mr="0.25rem" mb="0.25rem">
            <Typography variant="inherit" color={tokens.special.tooltipText}>
              {`${tooltipContent({
                currencyAmountDifference: displayCryptoQuantity({
                  quantity: Math.abs(warning.cryptoDiff),
                  locale: languagePreference,
                }),
                currencyLabel: warning?.currencyIdentifier?.symbol
                  ? displayCurrency(warning.currencyIdentifier.symbol)
                  : "",
                fiatDifference: displayFiatValue({
                  value: Math.abs(warning.fiatDiff || 0),
                  localCurrency,
                  locale: languagePreference,
                }),
              })} `}
              <GoToTipsOrSubscribe userPlan={userPlan} />
            </Typography>
          </Box>
        }
      >
        <Box display="flex" alignItems="center">
          {iconOverride ? (
            iconOverride
          ) : (
            <OutlinedWarning
              style={{
                fontSize: "1rem",
                color: tokens.icon.warning,
              }}
            />
          )}
        </Box>
      </Tooltip>
    </Box>
  );
}

const HeaderCell = styled(Box)`
  && {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 20%;
  }
`;

const TableCell = styled(Box)`
  && {
    width: 20%;
  }
`;

const TableRow = styled(({ showDivider, enableHover, ...props }) => (
  <Box {...props} />
))`
  && {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    ${({ showDivider, theme }) =>
      !!showDivider &&
      `border-bottom: 1px ${theme.tokens.border.neutral.default} solid;`}
  }

  ${({ enableHover, theme }) =>
    !!enableHover &&
    `&:hover {
    cursor: pointer;
    background-color: ${theme.tokens.background.neutral.lowest.hover};
  }`}
`;
