import { type Blockchain, type SyncErrorIssue } from "@ctc/types";
import * as React from "react";

import { type ImportMethodV2 } from "~/types/index";

type ImportFormContextType = {
  importMethod: ImportMethodV2;
  setImportMethod: (importMethod: ImportMethodV2) => void;
  showImportForm: boolean;
  setShowImportForm: (show: boolean) => void;
  selectedBlockchain?: Blockchain;
  setSelectedBlockchain: (blockchain?: Blockchain) => void;
  setImportIssue: (issue: SyncErrorIssue | undefined) => void;
  importIssue?: SyncErrorIssue;
  setShowInstructions: (show: boolean) => void;
  showInstructions: boolean;
};

export const ImportFormContext =
  React.createContext<ImportFormContextType | null>(null);
