import moment from "moment-timezone";

import { DATE_FORMAT } from "~/constants/constants";

export function displayTimestamp(
  timestamp: number | string,
  timezone: string,
  format?: string,
): string {
  if (timestamp === 0) {
    return "Date not available";
  }
  return moment.tz(timestamp, timezone).format(format || DATE_FORMAT);
}
