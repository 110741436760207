import { type AnalyticsClient } from "~/segment";
import {
  type GroupTraits,
  type IdentifyTraits,
  type PageProperties,
  type TrackProperties,
} from "~/segment/types";

type Logger = {
  log: (message?: any, ...optionalParams: any[]) => void;
};

/**
 * Local implementation of the segment client.
 * Just logs the event information locally.
 */
export class LocalAnalytics implements AnalyticsClient {
  private readonly logger: Logger;

  private constructor(logger: Logger) {
    this.logger = logger;
    // do nothing.
  }

  static getInstance(logToConsole?: boolean) {
    if (logToConsole) {
      return new LocalAnalytics({ log: console.log });
    }
    return new LocalAnalytics({ log: () => undefined });
  }

  track(event: string, props: TrackProperties) {
    return Promise.resolve(
      this.logger.log(`Local track event: '${event}'`, props),
    );
  }

  page(name: string, props: PageProperties) {
    return Promise.resolve(
      this.logger.log(`Local page event: '${name}'`, props),
    );
  }

  identify(userId: string, traits: IdentifyTraits) {
    return Promise.resolve(
      this.logger.log(`Local identify event: '${userId}'`, traits),
    );
  }

  group(groupId: string, userId: string, traits: GroupTraits) {
    return Promise.resolve(
      this.logger.log(
        `Local group event: '${groupId}' for user: '${userId}'`,
        traits,
      ),
    );
  }

  flush() {
    return Promise.resolve(this.logger.log("Local event flush"));
  }
}
