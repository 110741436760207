import curry from "lodash/curry";
import snakeCase from "lodash/snakeCase";

/**
 * Generates formatted event name for Posthog analytics
 * @param page
 * @param subView
 * @param action
 * @returns "page:sub_view:action"
 */
const generateAnalyticsKey = (
  page: string,
  subView: string,
  action: string,
): string => {
  return [page, subView, action].map(snakeCase).join(":");
};

const curriedGenerateAnalyticsKey = curry(generateAnalyticsKey);

export const reconciliationAnalyticsKey =
  curriedGenerateAnalyticsKey("reconciliation");
export const transactionAnalyticsKey =
  curriedGenerateAnalyticsKey("transactions");
export const importAnalyticsKey = curriedGenerateAnalyticsKey("import");
export const importNewAnalyticsKey =
  curriedGenerateAnalyticsKey("import")("new");
export const onboardingAnalyticsKey = curriedGenerateAnalyticsKey("onboarding");
export const globalAnalyticsKey = curriedGenerateAnalyticsKey("global");
export const settingsAnalyticsKey = curriedGenerateAnalyticsKey("settings");

export const bulkEditAnalyticsKey = transactionAnalyticsKey("bulk edit");

export const lockPeriodAnalyticsKey =
  curriedGenerateAnalyticsKey("lock-period");
export const accountingIntegrationsAnalyticsKey = curriedGenerateAnalyticsKey(
  "accounting integrations",
);
export const rulesIntegrationsAnalyticsKey =
  curriedGenerateAnalyticsKey("rules");
export const contactsIntegrationsAnalyticsKey =
  curriedGenerateAnalyticsKey("contacts");
export const reOnboardingAnalyticsKey =
  curriedGenerateAnalyticsKey("re onboarding");
export const paymentAnalyticsKey = curriedGenerateAnalyticsKey("payment");

export const navButtonAnalyticsKey = globalAnalyticsKey("nav")("clicked");
export const dashboardAnalyticsKey = curriedGenerateAnalyticsKey("dashboard");
