import { mainnet } from "@reown/appkit/networks";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";

export const APPKIT_PROJECT_ID = "98840701d994c0bb12a8d159a53035fe";

const networks = [mainnet];

export const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId: APPKIT_PROJECT_ID,
  ssr: true,
});

export function getAppKitConfig() {
  return wagmiAdapter.wagmiConfig;
}
