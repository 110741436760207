import { useCallback } from "react";

import { useCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import {
  ModalProvider,
  type PopupStateParams,
  useModal,
} from "~/components/transactions/command-palette/ModalProvider";
import {
  NavControllerProvider,
  useNavController,
} from "~/components/transactions/command-palette/NavController";

export function useCommandPaletteBones() {
  const modal = useModal();
  const commandPalette = useCommandPalette();
  const navController = useNavController();

  const { views, pop } = navController;
  const { close } = commandPalette;

  const handleClose = useCallback(
    // union is coming from mui
    (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
      // if you are loading, you cant close it
      if (modal.loading) {
        return;
      }
      // at the root page, so escape gets you out
      if (reason === "backdropClick" || views.length === 1) {
        close();
        return;
      }
      // not at the root page, so go back 1 view
      pop();
    },
    [close, modal.loading, pop, views.length],
  );

  return { modal, ...commandPalette, ...navController, handleClose };
}

export function CommandPaletteBonesProviders({
  children,
  ...popupStateParams
}: {
  children: React.ReactNode;
} & PopupStateParams &
  Required<Pick<PopupStateParams, "popupId">>) {
  return (
    <NavControllerProvider>
      <ModalProvider {...popupStateParams}>{children}</ModalProvider>
    </NavControllerProvider>
  );
}
