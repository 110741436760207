import { type Blockchain } from "@ctc/types";

import { CommandPaletteChip } from "~/components/transactions/command-palette/CommandPaletteTitle";
import { type Party } from "~/components/transactions/command-palette/views/change-destination-account/types";
import { useExchangeDisplayName } from "~/components/transactions/helpers";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";

export function AccountChip({ party }: { party: Party }) {
  return (
    <CommandPaletteChip
      icon={
        <ExchangeLogo
          name={party.exchangeId}
          currencySymbol={party.currencySymbol}
          blockchain={party.blockchain}
          showBlockchainSymbol
          width={20}
          height={20}
          margin="0"
        />
      }
      size="small"
      label={useExchangeDisplayName(
        party.exchangeId,
        party.blockchain as Blockchain | undefined,
        party.displayName,
      )}
    />
  );
}
