import { Check, ContentCopyOutlined } from "@mui/icons-material";
import {
  type ButtonProps,
  CircularProgress,
  type IconButtonProps,
  Typography,
} from "@mui/material";

import { useCopyToClipboard } from "~/hooks/useCopyToClipboard";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export const CopyButton: React.FC<{
  contentToCopy: string;
  ButtonComponent: React.ComponentType<ButtonProps | IconButtonProps>;
  handleClick?: () => void;
  loading?: boolean;
  color?: string;
}> = ({ contentToCopy, handleClick, loading, ButtonComponent, color }) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const { onClick, copied } = useCopyToClipboard(contentToCopy, handleClick);
  const StartIcon = copied ? (
    <Check style={{ color: color ?? tokens.text.low }} />
  ) : loading ? (
    <CircularProgress />
  ) : (
    <ContentCopyOutlined sx={{ color: color ?? tokens.text.low }} />
  );
  return (
    <ButtonComponent
      onClick={onClick}
      disabled={loading}
      startIcon={StartIcon}
      size="small"
    >
      <Typography variant="Metropolis/Caption/Medium/Regular" color={color ?? undefined}>
        {copied ? lang.copied : lang.copy}
      </Typography>
    </ButtonComponent>
  );
};
