import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from "@mui/icons-material";
import {
  Box,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { css } from "styled-components";
import styled from "styled-components/macro";

import nftLogo from "~/assets/default-nft-logo.svg";
import {
  importDrawerKey,
  useCaptureImportAnalytics,
} from "~/components/imports/AnalyticsHelpers";
import { getNFTStack } from "~/components/imports-v2/helpers";
import { MoreOptionsDropdown } from "~/components/imports-v2/MoreOptionsDialog";
import { type DrawerOptionsType } from "~/components/imports-v2/types";
import { CurrencyStack } from "~/components/reconciliation/components/CurrencyStack";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { devices } from "~/components/ui/theme/legacy";
import { LocalStorageKey } from "~/constants/enums";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import {
  LocalStorageUserKeyGenerator,
  useLocalStorage,
} from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import {
  displayCurrency,
  displayFiatValue,
  displayQuantity,
} from "~/lib/index";
import { useLocalCurrency, useUser } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { FilterOperators } from "~/types/enums";
import {
  type CurrencyIdentifier,
  type GroupedCurrencyIdentifier,
  type PricedHoldingsDetails,
} from "~/types/index";

type SortType = {
  field: TableHeaders;
  ascending: boolean;
};

const RemainingCurrenciesChip = styled(Box)<{ isNFT?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 9px;
  width: 37px;
  height: 28px;
  background: ${({ theme }) => theme.tokens.background.disabled};
  border-radius: ${({ isNFT }) => (isNFT ? "0.25rem" : "0.5rem")};
  border: 2px solid ${({ theme }) => theme.tokens.background.neutral.pressed};
  z-index: 6;
`;

enum TableHeaders {
  Title = "title",
  Logos = "logos",
  PriceCrypto = "priceCrypto",
  PriceFiat = "priceFiat",
  Options = "options",
}

const TableLayout = css`
  display: grid;
  color: ${({ theme }) => theme.tokens.text.low};
  background: ${({ theme }) => theme.tokens.background.neutral.lowest.default};
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  grid-template-rows: auto;
  grid-template-areas: "title logos priceCrypto priceFiat options";
  grid-template-columns: 16.25rem repeat(3, 1fr) 2.25rem;
  gap: 0.5rem;

  &:first-child {
    border-top: none;
  }

  @media ${devices.tablet} {
    gap: 1rem;
  }
`;

const AccTableHeader = styled(Box)`
  ${TableLayout}
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0.75rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  @media ${devices.tablet} {
    padding: 0.75rem 0rem 0.75rem 1.25rem;
  }

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }
`;

const AccTableRow = styled(Box)`
  ${TableLayout}
  align-items: center;
  padding: 0.75rem 0.5rem;
  gap: 0.5rem;
  &&:hover {
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.hover};

    & .MuiTypography-captionTextRegular {
      color: ${({ theme }) => theme.tokens.text.high};
    }
  }

  & .MuiTypography-captionTextRegular {
    color: ${({ theme }) => theme.tokens.text.low};
  }

  @media ${devices.tablet} {
    padding: 1rem 0rem 1rem 1rem;
  }
`;

const AccTableCell = styled(Box)`
  && {
    align-items: center;
    overflow: hidden;
  }
`;

const AccTableHeaderCell = ({
  text,
  align,
  setSortOrder,
  activeSort,
  header,
}: {
  text: string;
  align?: string;
  setSortOrder?: (sort: SortType) => void;
  activeSort: SortType;
  header: TableHeaders;
}) => {
  const { tokens } = useDesign();
  const captureAnalyics = useCaptureImportAnalytics();
  const { field, ascending } = activeSort;
  const isActive = field === header;

  const handleChange = () => {
    if (setSortOrder) {
      setSortOrder({
        ascending: !ascending,
        field: header,
      });
      captureAnalyics(importDrawerKey("nft_sort"), { header });
    }
  };

  return (
    <Box
      justifySelf={align}
      position="relative"
      onClick={handleChange}
      gridArea={header}
      display="flex"
      alignItems="center"
    >
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        sx={{
          color: isActive ? tokens.text.default : tokens.text.low,
          "&:hover": {
            color: tokens.text.default,
          },
          cursor: "pointer",
          whiteSpace: "nowrap",
        }}
        data-uncensored="true"
      >
        {text}
      </Typography>
      {setSortOrder && (
        <>
          {isActive && ascending ? (
            <KeyboardArrowUp
              style={{
                color: tokens.text.default,
                position: "absolute",
                right: "-1.5rem",
              }}
            />
          ) : (
            <KeyboardArrowDown
              style={{
                color: isActive ? tokens.text.default : tokens.text.disabled,
                position: "absolute",
                right: "-1.5rem",
              }}
              visibility={isActive ? "visible" : "hidden"}
            />
          )}
        </>
      )}
    </Box>
  );
};

const sortByField = (
  sortOptions: SortType,
  a: PricedHoldingsDetails & {
    currencyIdentifier: GroupedCurrencyIdentifier;
    referencePrice: number | null;
  },
  b: PricedHoldingsDetails & {
    currencyIdentifier: GroupedCurrencyIdentifier;
    referencePrice: number | null;
  },
): number => {
  const { ascending, field } = sortOptions;
  const sortOrder = ascending ? 1 : -1;

  switch (field) {
    case TableHeaders.Title:
      return (
        a.currencyIdentifier.name.localeCompare(b.currencyIdentifier.name) *
        sortOrder
      );
    case TableHeaders.Logos:
      return (
        ((a.currencyIdentifier.imageURLs?.length || 0) -
          (b.currencyIdentifier.imageURLs?.length || 0)) *
        sortOrder
      );
    case TableHeaders.PriceCrypto:
      return ((a.referencePrice || 0) - (b.referencePrice || 0)) * sortOrder;
    case TableHeaders.PriceFiat:
      return (a.cost - b.cost) * sortOrder;
  }

  return 0;
};

const TableDummyRow = () => {
  return (
    <AccTableRow>
      {Object.values(TableHeaders)
        .filter((h) => h !== TableHeaders.Options)
        .map((header) => (
          <AccTableCell key={header} gridArea={header}>
            <Skeleton width="100%" height="2.7rem" />
          </AccTableCell>
        ))}
    </AccTableRow>
  );
};

export const NftTable = ({
  holdings,
  importId,
  referenceCurrency,
  width = "auto",
  stackSize,
  isLoading,
}: {
  importId?: string;
  holdings: (PricedHoldingsDetails & {
    currencyIdentifier: GroupedCurrencyIdentifier;
    referencePrice: number | null;
  })[];
  referenceCurrency: CurrencyIdentifier | null;
  width?: string;
  stackSize?: number;
  isLoading?: boolean;
}) => {
  const user = useUser();
  const lang = useLang();
  const headingLang = lang.imports.drawer.nftTableHeadings;
  const { tokens } = useDesign();
  const [sortOrder, setSortOrder] = useLocalStorage<SortType>(
    LocalStorageUserKeyGenerator(
      user?.uid,
      LocalStorageKey.DrawerNftSortOption,
    ),
    {
      field: TableHeaders.PriceFiat,
      ascending: false,
    },
  );
  const sortedHoldings = [...holdings].sort((a, b) =>
    sortByField(sortOrder, a, b),
  );

  // virtualiser setup
  const itemHeightPx = 62;
  const rowsToShow = Math.min(5, sortedHoldings.length);
  const headerHeightPx = 41;
  const boxLoadedHeight = headerHeightPx + rowsToShow * itemHeightPx;
  const boxLoadingHeight = headerHeightPx + 56 * 3;
  const boxHeight = isLoading ? boxLoadingHeight : boxLoadedHeight;
  const parentRef = useRef(null);
  const rowVirtualizer = useVirtualizer({
    count: sortedHoldings.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemHeightPx,
    overscan: 10,
  });

  if (!holdings.length && !isLoading) {
    return (
      <Box minWidth="42.5rem" p="1rem">
        <Typography
          variant="Metropolis/Body/Bold"
          sx={{ color: tokens.text.low }}
        >
          {lang.holdingsDropdown.noHoldings}
        </Typography>
      </Box>
    );
  }

  const referencePriceSymbol = referenceCurrency?.symbol;
  return (
    <Box>
      <Box
        sx={{
          height: boxHeight,
          width,
        }}
      >
        <AccTableHeader>
          <AccTableHeaderCell
            header={TableHeaders.Title}
            text={`${headingLang.collections} ${
              isLoading ? "" : `(${holdings.length})`
            }`}
            activeSort={sortOrder}
            setSortOrder={setSortOrder}
            align="start"
          />
          <AccTableHeaderCell
            header={TableHeaders.Logos}
            text={headingLang.ntfs}
            activeSort={sortOrder}
            setSortOrder={setSortOrder}
            align="start"
          />
          <AccTableHeaderCell
            header={TableHeaders.PriceCrypto}
            text={`${headingLang.totalSpend} (${referencePriceSymbol})`}
            activeSort={sortOrder}
            setSortOrder={setSortOrder}
            align="end"
          />
          <AccTableHeaderCell
            header={TableHeaders.PriceFiat}
            text={headingLang.combinedCost}
            activeSort={sortOrder}
            setSortOrder={setSortOrder}
            align="end"
          />
          <AccTableCell />
        </AccTableHeader>
        {isLoading ? (
          Array.from({ length: 3 }, (_, index) => index).map((i) => (
            <TableDummyRow key={i} />
          ))
        ) : (
          <div
            ref={parentRef}
            style={{
              height: `${rowsToShow * itemHeightPx}px`,
              width,
              overflow: "auto",
            }}
          >
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const holding = sortedHoldings[virtualRow.index];
                return (
                  <div
                    key={virtualRow.index}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <AccTableRow key={holding.currencyIdentifier.id}>
                      <CurrencyCell
                        currencyIdentifier={holding.currencyIdentifier}
                      />
                      <LogoCell
                        currency={holding.currencyIdentifier}
                        stackSize={stackSize}
                      />
                      <NumberCell
                        gridArea={TableHeaders.PriceCrypto}
                        value={holding.referencePrice}
                      />
                      <NumberCell
                        gridArea={TableHeaders.PriceFiat}
                        value={holding.cost}
                        isFiat
                      />
                      <MoreOptions
                        currencyIdentifier={holding.currencyIdentifier}
                        importId={importId}
                      />
                    </AccTableRow>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
};

const LogoCell = ({
  currency,
  stackSize,
}: {
  currency: GroupedCurrencyIdentifier;
  stackSize?: number;
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { shownCurrencies, otherCurrenciesCount } = getNFTStack(
    currency,
    stackSize ? stackSize : isSmall ? 3 : isMd ? 4 : 6,
  );

  const nftNotFoundLogo = (
    <Box
      height={28}
      display="inline-flex"
      position="relative"
      alignItems="center"
    >
      <LazyLoadImage
        height={28}
        width={28}
        src={nftLogo}
        alt="nft not found logo"
        effect="blur"
        style={{
          borderRadius: "0.25rem",
        }}
      />
    </Box>
  );
  return (
    <AccTableCell gridArea={TableHeaders.Logos} display="flex">
      {shownCurrencies.length === 0 ? (
        nftNotFoundLogo
      ) : (
        <CurrencyStack currencies={shownCurrencies} />
      )}

      {otherCurrenciesCount ? (
        <RemainingCurrenciesChip>{`+${otherCurrenciesCount}`}</RemainingCurrenciesChip>
      ) : null}
    </AccTableCell>
  );
};

const CurrencyCell = ({
  currencyIdentifier,
}: {
  currencyIdentifier: CurrencyIdentifier;
}) => {
  const { name, symbol } = currencyIdentifier;
  // TODO: fix
  return (
    <Tooltip title={<span>{`${name} (${symbol})`}</span>} placement="top-start">
      <AccTableCell gridArea="title" gap="0.5rem" display="flex">
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          whiteSpace="nowrap"
          data-uncensored="true"
        >
          {displayCurrency(name, 24, 20)}
        </Typography>
        <Typography
          whiteSpace="nowrap"
          variant="Metropolis/Caption/Medium/Regular"
          data-uncensored="true"
        >{`(${displayCurrency(symbol, 8, 3)})`}</Typography>
      </AccTableCell>
    </Tooltip>
  );
};

const NumberCell = ({
  value,
  isFiat,
  gridArea,
}: {
  isFiat?: boolean;
  value: number | null;
  gridArea: TableHeaders;
}) => {
  const lang = useLang();
  const langPref = useLanguagePreference();
  const localCurrency = useLocalCurrency();

  const getDisplayValue = () => {
    if (value === null) {
      return lang.na;
    }

    if (isFiat) {
      return displayFiatValue({ value, localCurrency, locale: langPref });
    }

    return displayQuantity(value, langPref);
  };

  return (
    <AccTableCell justifySelf="end" gridArea={gridArea}>
      <Typography variant="Metropolis/Caption/Medium/Regular" fontWeight={600}>
        {getDisplayValue()}
      </Typography>
    </AccTableCell>
  );
};

function MoreOptions({
  currencyIdentifier,
  importId,
}: {
  currencyIdentifier: CurrencyIdentifier;
  importId?: string;
}) {
  const lang = useLang();
  const isEmbedded = useIsEmbedded();
  if (importId) {
    throw new Error("handle importId");
  }

  const viewTxsHandler = () => {
    const link = getTransactionPageLink({
      state: {
        filter: {
          type: FilterOperators.And,
          rules: [
            {
              type: FilterOperators.NFTCollection,
              value: [currencyIdentifier.contractAddress as string],
            },
          ],
        },
      },
    });

    window.open(link, isEmbedded ? "_self" : "_blank", "noopener,noreferrer");
  };

  const options: DrawerOptionsType[] = [
    {
      id: "view_transactions",
      handler: viewTxsHandler,
      label: lang.imports.drawer.viewCollection,
      icon: <OpenInNew fontSize="small" />,
    },
  ];

  return (
    <AccTableCell gridArea={TableHeaders.Options}>
      <MoreOptionsDropdown options={options} />
    </AccTableCell>
  );
}
