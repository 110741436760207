import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { CloneDataSetModal } from "~/components/developer/seed-data/CloneDataSetModal";
import { CloneDataSetNewClientModal } from "~/components/developer/seed-data/CloneDataSetNewClientModal";
import { CreateSeedDataModal } from "~/components/developer/seed-data/CreateSeedDataModal";
import { DeleteDataSetModal } from "~/components/developer/seed-data/DeleteDataSetModal";
import { EditDataSetModal } from "~/components/developer/seed-data/EditDataSetModal";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useIsManagingClients } from "~/redux/auth";
import { useSeedDataSets } from "~/state/developer";

export type DataSet = {
  _id: string;
  uid: string;
  email: string;
  password: string;
  name: string;
  description: string;
  transactionCount: number;
  props: Record<string, number>;
};

const DataSetRow = ({ dataSet }: { dataSet: DataSet }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [newClientOpen, setNewClientOpen] = useState(false);
  const [cloneOpen, setCloneOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const isManagingClients = useIsManagingClients();

  return (
    <>
      <CloneDataSetNewClientModal
        isOpen={newClientOpen}
        handleClose={() => {
          setNewClientOpen(false);
        }}
        dataSet={dataSet}
      />
      <CloneDataSetModal
        isOpen={cloneOpen}
        handleClose={() => {
          setCloneOpen(false);
        }}
        dataSet={dataSet}
      />
      <EditDataSetModal
        isOpen={editOpen}
        handleClose={() => {
          setEditOpen(false);
        }}
        dataSet={dataSet}
      />
      <DeleteDataSetModal
        isOpen={deleteOpen}
        handleClose={() => {
          setDeleteOpen(false);
        }}
        dataSet={dataSet}
      />
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell width="5%">
          <TextIconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setDetailsOpen(!detailsOpen);
            }}
          >
            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </TextIconButton>
        </TableCell>
        <TableCell>
          <Breadcrumbs>
            {dataSet.name.split("/").map((segment, index) => (
              <div key={index}>{segment}</div>
            ))}
          </Breadcrumbs>
        </TableCell>
        <TableCell align="right">
          {dataSet.transactionCount.toLocaleString()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
        <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={detailsOpen} timeout="auto" unmountOnExit>
            {dataSet.description}
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell align="right">{dataSet.uid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">{dataSet.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Password</TableCell>
                  <TableCell align="right">{dataSet.password}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transaction Count</TableCell>
                  <TableCell align="right">
                    {dataSet.transactionCount}
                  </TableCell>
                </TableRow>

                {Object.keys(dataSet.props).map((key) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell align="right">{dataSet.props[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {isManagingClients && (
              <Button
                onClick={() => {
                  setNewClientOpen(true);
                }}
              >
                Clone into new client
              </Button>
            )}
            <Button
              onClick={() => {
                setCloneOpen(true);
              }}
            >
              Clone into this user
            </Button>
            <Button
              onClick={() => {
                setEditOpen(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setDeleteOpen(true);
              }}
            >
              Delete
            </Button>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const SeedData = () => {
  const { data, isLoading } = useSeedDataSets();
  const dataSets: DataSet[] = data?.error === false ? data.data : [];

  const [isCreateOpen, setIsCreateOpen] = useState(false);

  return (
    <Grid item xs={12}>
      <Box px={{ xs: 2, sm: 0 }} mt={4}>
        <Stack direction="column" spacing={3} sx={{ width: "100%" }} />
        <Box>
          <Stack direction="row" alignItems="flex-start" alignContent="center">
            <Typography variant="Metropolis/Header/H4" sx={{ flex: 1 }}>
              Seed data
            </Typography>
            <Box>
              <Button
                onClick={() => {
                  setIsCreateOpen(true);
                }}
              >
                Create Seed Data Set
              </Button>
              <CreateSeedDataModal
                isOpen={isCreateOpen}
                handleClose={() => {
                  setIsCreateOpen(false);
                }}
              />
            </Box>
          </Stack>
        </Box>

        <Box>
          <Box>
            <Typography>
              A collection of well-known data sets that can be used for testing
              various scenarios within the app.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell align="right">TXs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box textAlign="center">
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {dataSets.map((dataSet) => (
                <DataSetRow dataSet={dataSet} key={dataSet._id} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Grid>
  );
};
