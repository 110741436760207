import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteTimestampInput } from "~/components/transactions/command-palette/CommandPaletteTimestampInput";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { Confirmation } from "~/components/transactions/command-palette/views/rewind-transactions/Confirmation";
import { useLang } from "~/redux/lang";

export function SetTimestamp() {
  const lang = useLang();
  const { push } = useNavController();

  return (
    <CommandPaletteTimestampInput
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.TxRewind} />
        </BulkEditChips>
      }
      onConfirm={(timestamp) => {
        push(<Confirmation timestamp={timestamp} />);
      }}
      description={
        lang.txTable.commandPallet.bulkEditOptionDescriptions.txRewind
      }
    />
  );
}
