import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { resetPosthog, useCaptureAnalytics } from "~/analytics/posthog";
import { capitalizeName } from "~/components/report/helpers/capitalizeName";
import { TextConfirmationModal } from "~/components/ui/TextConfirmationModal";
import { displayMessage } from "~/components/ui/Toaster";
import { useSetActiveClient } from "~/hooks/useActiveClient";
import {
  logoutSuccess,
  setAuth,
  useActiveUser,
  useIsActiveUserABridge,
  useIsManagingClients,
  useUser,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { resetUserData } from "~/redux/reset";
import * as accountantAPI from "~/services/accountant";
import { resetIntercom } from "~/services/intercom";
import * as settingsAPI from "~/services/settings";
import { DisplayMessage } from "~/types/enums";
import { type ClientDetails } from "~/types/index";

export const DeleteAccountConfirmation = ({
  isOpen,
  handleClose,
  clientToDelete,
}: {
  /** Modal open state */
  isOpen: boolean;
  /** Handler to close the modal */
  handleClose: () => void;
  /** Optional client details for deletion */
  clientToDelete?: ClientDetails;
}) => {
  const lang = useLang();
  const user = useUser();
  const activeUser = useActiveUser();
  const isManagingClients = useIsManagingClients();
  const captureAnalytics = useCaptureAnalytics();
  const isABridgeAccount = useIsActiveUserABridge();
  const setActiveClient = useSetActiveClient();
  const dispatch = useDispatch();

  const isClientData =
    Boolean(clientToDelete) ||
    (isManagingClients && user?.uid !== activeUser?.uid);

  const deleteStandaloneUserMutation = useMutation({
    mutationFn: settingsAPI.deleteEverything,
    onSuccess: () => {
      dispatch(resetUserData());
      dispatch(setAuth(logoutSuccess()));
      resetPosthog();
      resetIntercom();
      handleClose();
    },
    onError: () => {
      displayMessage({
        message: lang.settings.somethingWentWrong,
        type: DisplayMessage.Error,
      });
    },
  });

  const deleteClientMutation = useMutation({
    mutationFn: (uid: string) => accountantAPI.deleteClient(uid),
    onSuccess: () => {
      if (user) {
        setActiveClient(user.uid);
        handleClose();
      }
    },
    onError: () => {
      displayMessage({
        message: lang.settings.somethingWentWrong,
        type: DisplayMessage.Error,
      });
    },
  });

  const handleSubmit = () => {
    if (!user) return;

    captureAnalytics("close_account_success");

    const isStandaloneUser = !clientToDelete && !isABridgeAccount;
    if (isStandaloneUser) {
      deleteStandaloneUserMutation.mutate();
    } else {
      const uid = clientToDelete?.uuid ?? user?.activeProfileDetails.uid;
      if (!uid) return;
      deleteClientMutation.mutate(uid);
    }
  };

  const titleText = (() => {
    if (!isClientData) return lang.settings.manageData.titleSelf;

    if (clientToDelete) {
      return lang.settings.manageData.titleClient({
        clientName: capitalizeName(clientToDelete.name),
      });
    }
    return lang.settings.manageData.titleClientFallback;
  })();

  return (
    <TextConfirmationModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title={titleText}
      actionText={lang.settings.manageData.delete}
      pending={
        deleteStandaloneUserMutation.isPending || deleteClientMutation.isPending
      }
      critical
      text={lang.settings.manageData.text({ isAccountant: isClientData })}
      confirmationText={lang.settings.manageData.confirmDelete}
      confirmationPrompt={lang.settings.manageData.confirmDeletePrompt}
    />
  );
};
