import { Country } from "@ctc/types";
import { Box, Skeleton, Typography } from "@mui/material";

import { getReportTypeLang } from "~/components/report/helpers/lang";
import { useReportsForCountry } from "~/components/report/helpers/useReportsForCountry";
import { useTLHTableRows } from "~/components/report/tax-loss-harvesting-modal/useTLHTableRows";
import { MIN_TLH_GAIN_FOR_SHOW_TO_USER } from "~/components/report/TaxLossHarvestingBanner";
import { BannerCommon } from "~/components/ui/BannerCommon";
import { BannerSeverity } from "~/components/ui/enums";
import { PaywallModalType } from "~/contexts/paywall-modal-context/enums";
import { type PaywallModalContent } from "~/contexts/paywall-modal-context/types";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib/index";
import { useCountry, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  getDefaultInventoryMethodByCountry,
  useAvailableInventoryMethods,
} from "~/redux/report";
import { Features, NormalReportType, OtherReportType } from "~/types/enums";
import {
  AdvancedReports,
  type ReportType,
  shouldReplaceCGTWithPNL,
} from "~/types/index";

export const usePaywallContent = (): Record<
  PaywallModalType,
  PaywallModalContent
> => {
  const { tokens } = useDesign();
  const user = useUser();
  const lang = useLang();
  const country = useCountry();
  const usePNL = shouldReplaceCGTWithPNL(country);
  const availableInventoryMethods = useAvailableInventoryMethods();
  const defaultInventoryMethod = getDefaultInventoryMethodByCountry(country);

  const excludedAdvancedReports = [
    NormalReportType.TradingStockAu, // Not included with other adv reports
    country !== Country.Australia ? OtherReportType.BGL : null, // BGL only for AU
  ].filter(Boolean) as ReportType[];

  // AdvancedReports
  const advReports = AdvancedReports.filter(
    (report) => !excludedAdvancedReports.includes(report),
  );

  const allReports = useReportsForCountry().map((report) => report.type);

  // AdvancedInventory
  const advancedMethods = availableInventoryMethods.filter(
    (method) => method != defaultInventoryMethod,
  );

  // TaxLossHarvesting
  const tableRows = useTLHTableRows();

  const totalUnrealizedLosses = tableRows
    ? tableRows
        .filter((row) => !!row.gain && row.gain < 0)
        .reduce((acc, row) => acc + row.gain || 0, 0)
    : null;

  const tlhBenefitsLang = lang.taxLossHarvesting.paywallModal.benefits;

  return {
    [PaywallModalType.GenericPaywall]: {
      ...lang.paywallModal.implementations.paywall,
      benefits: [],
      calco: true,
    },
    [PaywallModalType.MultiTaxYears]: {
      ...lang.paywallModal.implementations.multiTaxYears,
      feature: Features.MultiTaxYears,
      benefits: [],
      calco: true,
    },
    [PaywallModalType.TradingStockReport]: {
      ...lang.paywallModal.implementations.tradingStockReport,
      feature: Features.TradingStockReport,
      benefits: [],
      calco: false,
    },
    [PaywallModalType.AllReports]: {
      ...lang.paywallModal.implementations.allReports,
      prompt: "",
      subtitle: null,
      benefits: allReports.map((report) =>
        getReportTypeLang(lang, report, country),
      ),
      calco: false,
    },
    [PaywallModalType.AdvancedReports]: {
      ...lang.paywallModal.implementations.advancedReports,
      subtitle: null,
      benefits: advReports.map((report) =>
        getReportTypeLang(lang, report, country),
      ),
      feature: Features.AdvancedReports,
      calco: true,
    },
    [PaywallModalType.AdvancedInventory]: {
      ...lang.paywallModal.implementations.inventory,
      benefits: advancedMethods.map((method) => lang.report[method]),
      feature: Features.AdvancedInventory,
      subtitle: null,
      calco: false,
    },
    [PaywallModalType.Tips]: {
      title: lang.paywallModal.implementations.tips.title,
      benefits: [],
      feature: Features.Tips,
      prompt: lang.paywallModal.implementations.tips.subtitleReview,
      calco: true,
      subtitle: null,
    },
    [PaywallModalType.TaxLossHarvesting]: {
      title: lang.taxLossHarvesting.paywallModal.title,
      prompt: lang.taxLossHarvesting.paywallModal.upgradePrompt,
      benefits: [
        tlhBenefitsLang.optimize,
        tlhBenefitsLang[usePNL ? "lockInNoCGT" : "lockIn"],
        tlhBenefitsLang[usePNL ? "offsetNoCGT" : "offset"],
      ],
      feature: Features.TaxLossHarvesting,
      calco: false,
      subtitle:
        totalUnrealizedLosses &&
        Math.abs(totalUnrealizedLosses) > MIN_TLH_GAIN_FOR_SHOW_TO_USER ? (
          // Whilst table rows are loading, show a skeleton
          tableRows !== null ? (
            <Box>
              <Typography display="inline" fontWeight="600" fontSize="1.5rem">
                {lang.taxLossHarvesting.paywallModal.subtitlePre}
              </Typography>
              <Typography
                display="inline"
                fontWeight="600"
                fontSize="1.5rem"
                color={tokens.text.warning}
              >
                {" "}
                {displayFiatValue({
                  value: Math.abs(totalUnrealizedLosses),
                  localCurrency: user?.localCurrency,
                  locale: user?.language,
                })}{" "}
              </Typography>
              <Typography display="inline" fontWeight="600" fontSize="1.5rem">
                {lang.taxLossHarvesting.paywallModal.subtitlePost}
              </Typography>
            </Box>
          ) : (
            <Skeleton height={32} />
          )
        ) : null,
    },
    [PaywallModalType.SmartContractInteraction]: {
      title: lang.paywallModal.implementations.smartContractInteraction.title,
      benefits: [],
      feature: Features.SmartContractTransactions,
      prompt:
        lang.paywallModal.implementations.smartContractInteraction.subtitle,
      calco: true,
      subtitle: null,
      banner: (
        <Box width="100%">
          <BannerCommon
            severity={BannerSeverity.Warning}
            title={
              lang.paywallModal.implementations.smartContractInteraction.banner
                .title
            }
            text={
              <Box ml="0.5rem">
                <Typography variant="Metropolis/Body/Light">
                  {
                    lang.paywallModal.implementations.smartContractInteraction
                      .banner.text
                  }
                </Typography>

                <Typography variant="Metropolis/Body/Light" sx={{ pl: "0.5rem" }}>
                  <li>
                    {
                      lang.paywallModal.implementations.smartContractInteraction
                        .banner.subText
                    }
                  </li>
                </Typography>
              </Box>
            }
          />
        </Box>
      ),
    },
  };
};
