import { type Party } from "~/components/transactions/command-palette/views/change-destination-account/types";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { type ExchangeOption } from "~/types/index";

export function getOptionsFromExchangeOptions(
  exchangeOptions: ExchangeOption[],
  excludeParty: Party,
) {
  return exchangeOptions.flatMap((eo) => {
    if (eo.label === excludeParty.exchangeId) {
      // TODO: work out why this isnt filtering
      return [];
    }
    return [
      {
        label: eo.name,
        icon: (
          <ExchangeLogo
            name={eo.label}
            currencySymbol={eo.currency}
            blockchain={eo.blockchain}
            showBlockchainSymbol
          />
        ),
        toParty: {
          // what a nightmare label === id
          exchangeId: eo.label,
          blockchain: eo.blockchain,
          displayName: eo.name,
          currencySymbol: eo.currency,
        },
      },
    ];
  });
}
