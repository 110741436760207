import { KeyboardArrowDown } from "@mui/icons-material";
import { CircularProgress, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

import { useDesign } from "~/hooks/useTheme";

export function TextDropdown({
  text,
  options,
  endIcon,
  onSelect,
}: {
  text: string;
  options: { name: string; isLoading: boolean }[] | string[];
  endIcon?: React.ReactNode;
  onSelect: (option: string) => void;
}) {
  const { tokens } = useDesign();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: string, stayOpen?: boolean) => {
    onSelect(option);
    if (!stayOpen) {
      handleClose();
    }
  };

  return (
    <div>
      <Link
        aria-controls={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
        sx={{
          textWrap: "nowrap",
          display: "flex",
          gap: "0.1rem",
          flexDirection: "row",
          alignItems: "center",
          textDecoration: "none",
          marginRight: "0.5rem",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {text}
        <KeyboardArrowDown />
      </Link>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => {
          if (typeof option === "string") {
            return (
              <MenuItem
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.5rem",
                  color: tokens.text.low,
                  fontSize: "0.75rem",
                }}
                key={option}
                value={option}
                onClick={() => {
                  handleMenuItemClick(option);
                }}
              >
                {option}
                {endIcon}
              </MenuItem>
            );
          }

          const { name, isLoading } = option;

          return (
            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
                color: tokens.text.low,
                fontSize: "0.75rem",
                lineHeight: "1rem",
                "&.Mui-disabled": {
                  opacity: 1,
                  color: tokens.text.low,
                },
              }}
              key={name}
              value={name}
              disabled={isLoading}
              onClick={() => {
                handleMenuItemClick(name, true);
              }}
            >
              <span>{name}</span>
              {isLoading ? (
                <CircularProgress
                  sx={{ color: tokens.text.low }}
                  size="0.75rem"
                />
              ) : (
                endIcon
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
