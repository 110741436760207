import { type Plan } from "@ctc/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  type BoxProps,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import styled from "styled-components/macro";

import {
  useCurrentPlan,
  useHaveSmartContractTx,
  useLimitExceeded,
  usePlanVisibility,
} from "~/components/payment/helpers";
import { type PlanContainerData } from "~/components/plans/PlanContainer";
import { SuccessIconRounded } from "~/components/ui/Icons";
import { devices } from "~/components/ui/theme/legacy";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type PaymentCycle } from "~/types/enums";
import { type PlanDataBase } from "~/types/index";

export function PlanTabs<T extends PlanDataBase>({
  plans,
  selectedPlanType,
  setSelectedPlanType,
}: {
  plans: PlanContainerData<T>[];
  selectedPlanType: Plan;
  selectedPaymentCycle?: PaymentCycle;
  setSelectedPlanType: (planType: Plan) => void;
}) {
  const currentPlan = useCurrentPlan();

  return (
    <CustomTabs>
      {plans.map((plan) => (
        <CustomTab
          key={plan.planType}
          planData={plan}
          isActive={selectedPlanType === plan.planType}
          isSelected={currentPlan === plan.planType}
          setSelectedPlanType={setSelectedPlanType}
        />
      ))}
    </CustomTabs>
  );
}

function CustomTab<T extends PlanDataBase>({
  planData,
  isActive,
  isSelected,
  setSelectedPlanType,
  disableDowngradeWarning = false,
}: {
  planData: PlanContainerData<T>;
  isActive: boolean;
  isSelected: boolean;
  setSelectedPlanType: (planType: Plan) => void;
  disableDowngradeWarning?: boolean;
}) {
  const lang = useLang();
  const { tokens } = useDesign();

  const { isViewable } = usePlanVisibility();
  const { isLimitedExceeded } = useLimitExceeded();
  const { isSmartContractTxExist } = useHaveSmartContractTx();
  const isDisabled = !isViewable(planData.planType);

  const infoIcon = (
    <InfoOutlinedIcon
      sx={{ color: isActive ? tokens.text.brand : tokens.text.low }}
      fontSize="small"
    />
  );
  let tabIcon = null;
  const { planType, planName, planPriceInLocalCurrencyFormat } = planData;
  if (isLimitedExceeded(planType)) {
    tabIcon = <Tooltip title={lang.payment.tooManyTx}>{infoIcon}</Tooltip>;
  } else if (isSmartContractTxExist(planType)) {
    tabIcon = (
      <Tooltip title={lang.payment.haveSmartContractTx}>{infoIcon}</Tooltip>
    );
  } else if (isSelected) {
    tabIcon = (
      <Tooltip title={lang.payment.currentPlan}>
        <SvgIcon
          style={{
            fontSize: "1rem",
            borderRadius: "100%",
            boxShadow: `0 0 0 0.3rem ${tokens.background.accent.green.lowest}`,
            color: tokens.background.accent.green.medium,
            marginRight: "0.25rem",
          }}
        >
          <SuccessIconRounded />
        </SvgIcon>
      </Tooltip>
    );
  } else if (!disableDowngradeWarning && isDisabled) {
    tabIcon = (
      <Tooltip title={lang.payment.downgradeWarning}>{infoIcon}</Tooltip>
    );
  }

  return (
    <CustomTabPanel
      active={isActive}
      onClick={() => {
        if (!isDisabled) {
          setSelectedPlanType(planType);
        }
      }}
      disabled={isDisabled}
    >
      <Stack direction="row" alignItems="center" gap="0.25rem">
        {tabIcon}
        <TabTypography active={isActive} variant="Metropolis/Header/H5" disabled={isDisabled}>
          {planName}
        </TabTypography>
      </Stack>
      <Stack direction="row" alignItems="center" gap="0.25rem">
        <TabTypography active={isActive} variant="Metropolis/Header/H5" disabled={isDisabled}>
          {planPriceInLocalCurrencyFormat}
        </TabTypography>
      </Stack>
    </CustomTabPanel>
  );
}

const CustomTabs = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media ${devices.tabletL} {
    flex-direction: row;
  }

  // everything except the first and last
  & > div:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  // everything except the last
  & > div:not(:last-child) {
    border-right: 1px solid
      ${({ theme }) => theme.tokens.border.neutral.default};
  }
  // the first one
  & > div:first-child {
    border-top-left-radius: 0.5rem;
  }
  // the last one
  & > div:last-child {
    border-top-right-radius: 0.5rem;
  }
`;

const CustomTabPanel = styled(
  ({
    active,
    disabled,
    ...rest
  }: { active: boolean; disabled: boolean } & BoxProps) => <Box {...rest} />,
)`
  && {
    width: 100%;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    border-bottom: 1px solid
      ${({ theme }) => theme.tokens.border.neutral.default};
    background-color: ${({ theme }) => theme.tokens.button.neutral.default};
    ${({ active, theme }) =>
      active
        ? `
        background-color: ${theme.tokens.button.neutral.pressed};
        border-bottom: 1px solid ${theme.tokens.border.brand};
        `
        : null};
  }

  ${({ active, disabled, theme }) =>
    !active && !disabled
      ? `
      &:hover {
        color: ${theme.tokens.text.default};
        background-color: ${theme.tokens.button.neutral.hover};
  }
  `
      : null}
`;

const TabTypography = styled(({ active, disabled, ...props }) => (
  <Typography {...props} />
))`
  && {
    color: ${({ theme, active, disabled }) =>
      active
        ? theme.tokens.text.brand
        : disabled
          ? theme.tokens.text.disabled
          : theme.tokens.text.low};
  }
`;
