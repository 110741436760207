import { useQuery } from "@tanstack/react-query";

import { HttpError } from "~/services/core";
import * as exchangeAPI from "~/services/exchanges";

export const exchangeKeys = {
  // The users exchanges
  names: () => ["exchangeNames"] as const,
  // All the exchanges which are available
  options: () => ["exchangeOptions"] as const,
};

// Fetch exchange names
export const useLoadExchangeNames = () => {
  return useQuery({
    queryKey: exchangeKeys.names(),
    queryFn: async () => {
      const res = await exchangeAPI.getExchangeNames();
      if (res.error) {
        throw new HttpError(res, ["loadExchangeNames"]);
      }
      return res.data;
    },
    throwOnError: true,
  });
};

// Fetch exchange options
export const useLoadExchangeOptions = () => {
  return useQuery({
    queryKey: exchangeKeys.options(),
    queryFn: async () => {
      const res = await exchangeAPI.getAllExchangeOptions();
      if (res.error) {
        console.error(res);
        throw new HttpError(res, ["loadExchangeOptions"]);
      }
      return res.data;
    },
    throwOnError: true,
  });
};
