import { MoreVert } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
} from "@mui/material";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { type PropsWithChildren, type ReactNode } from "react";
import styled from "styled-components/macro";

import {
  LedgerActionRowColumns,
  useActionRowActionWidthOverride,
} from "~/components/transactions/action-row/actionRow";
import {
  ActionHeaderAccountBalance,
  ActionHeaderOverallBalance,
} from "~/components/transactions/action-row/ActionRowBalance";
import { ActionHeaderFee } from "~/components/transactions/action-row/ActionRowFee";
import { ActionHeaderFrom } from "~/components/transactions/action-row/ActionRowFrom";
import { ActionHeaderGain } from "~/components/transactions/action-row/ActionRowGain";
import { ActionHeaderLoading } from "~/components/transactions/action-row/ActionRowLoading";
import { ActionHeaderTime } from "~/components/transactions/action-row/ActionRowTime";
import { ActionHeaderTo } from "~/components/transactions/action-row/ActionRowTo";
import { ActionHeaderType } from "~/components/transactions/action-row/ActionRowType";
import { ActionHeaderValue } from "~/components/transactions/action-row/ActionRowValue";
import { ActionTableGridArea } from "~/components/transactions/action-row/enums";
import { transactionActionRowLayoutStyles } from "~/components/transactions/action-row/TransactionActionRowLayout";
import { FilterActionType } from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { useMinScreenWidthForTableContent } from "~/components/transactions/useTableStopsScrollingPx";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";
import {
  reconUncategorisedActionRowColumns,
  transactionActionRowColumns,
} from "~/hooks/useTransactionActionRowColumns";
import { useLang } from "~/redux/lang";
import { Align, Sort, TradeDirection } from "~/types/enums";

const ActionTableHeader = styled(Box)<{
  columns: ActionTableGridArea[];
  defaultSizeOverrides?: { [key in ActionTableGridArea]?: string };
  // the min width the screen needs to be to fit the table without scrolling
  minScreenSizeForContent: number;
}>`
  padding: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background: ${({ theme }) => theme.tokens.background.neutral.lowest.default};
  border-radius: 0.25rem 0.25rem 0 0;
  ${transactionActionRowLayoutStyles}
`;

export function ActionRowHeader() {
  const columns = transactionActionRowColumns();
  const defaultSizeOverrides = useActionRowActionWidthOverride();

  return (
    <ActionTableHeaderWrapper
      columns={columns}
      defaultSizeOverrides={defaultSizeOverrides}
      additionalSorts={[Sort.LastModifiedDescending]}
    >
      <ActionHeaderType isCheckboxEnabled />
      <ActionHeaderTime />
      <ActionHeaderFrom />
      <ActionHeaderTo />
      <ActionHeaderValue />
      <ActionHeaderFee />
      <ActionHeaderGain />
    </ActionTableHeaderWrapper>
  );
}

export function ActionTableHeaderWrapper({
  columns,
  defaultSizeOverrides,
  additionalSorts = [],
  children,
}: PropsWithChildren<{
  columns: ActionTableGridArea[];
  defaultSizeOverrides?: { [key in ActionTableGridArea]?: string };
  children: ReactNode;
  additionalSorts?: Sort[];
}>) {
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  return (
    <ActionTableHeader
      columns={columns}
      defaultSizeOverrides={defaultSizeOverrides}
      minScreenSizeForContent={minScreenSizeForContent}
    >
      {children}
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="flex-end"
        style={{ gridArea: ActionTableGridArea.Actions }}
        my="-0.19rem"
      >
        <ActionHeaderLoading />
        {additionalSorts.length ? (
          <ActionHeaderAdditionalSorts additionalSorts={additionalSorts} />
        ) : null}
      </Box>
    </ActionTableHeader>
  );
}

function ActionHeaderAdditionalSorts({
  additionalSorts,
}: {
  additionalSorts: Sort[];
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const { state, dispatch } = useTransactionFilter();

  const currentSort = state.sort;

  const popupState = usePopupState({
    variant: "popover",
    popupId: "actionHeaderAdditionalSorts",
  });

  const isAnyOptionSelected = additionalSorts.some(
    (option) => option === currentSort,
  );

  return (
    <>
      <TextIconButton
        size="small"
        {...bindTrigger(popupState)}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          popupState.toggle(e);
        }}
        active={popupState.isOpen}
      >
        <MoreVert
          style={{
            fontSize: "1.25rem",
            color: isAnyOptionSelected ? tokens.text.high : tokens.text.low,
          }}
        />
      </TextIconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClick={(e) => {
          popupState.toggle(e);
        }}
      >
        <Paper>
          <List sx={{ maxWidth: 300 }}>
            {additionalSorts.map((option) => (
              <ListItem
                key={option}
                selected={currentSort === option}
                onClick={() => {
                  dispatch({ type: FilterActionType.SetSort, sort: option });
                }}
              >
                <ListItemText>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                  >
                    {lang.txTable.sort[option]}
                  </div>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popover>
    </>
  );
}

export function LedgerRowHeader() {
  const defaultSizeOverrides = useActionRowActionWidthOverride();

  return (
    <ActionTableHeaderWrapper
      columns={LedgerActionRowColumns}
      defaultSizeOverrides={defaultSizeOverrides}
      additionalSorts={[Sort.LastModifiedDescending]}
    >
      <ActionHeaderType isCheckboxEnabled />
      <ActionHeaderTime />
      <ActionHeaderFrom />
      <ActionHeaderTo />
      <ActionHeaderFee
        align={Align.Left}
        gridArea={ActionTableGridArea.LedgerFee}
        disableSymbol
      />
      <ActionHeaderAccountBalance direction={TradeDirection.Out} />
      <ActionHeaderAccountBalance direction={TradeDirection.In} />
      <ActionHeaderOverallBalance />
    </ActionTableHeaderWrapper>
  );
}

export function ReconUncategorisedActionRowHeader() {
  const columns = reconUncategorisedActionRowColumns();
  const defaultSizeOverrides = useActionRowActionWidthOverride();

  return (
    <ActionTableHeaderWrapper
      columns={columns}
      defaultSizeOverrides={defaultSizeOverrides}
    >
      <ActionHeaderType isCheckboxEnabled={false} />
      <ActionHeaderTime />
      <ActionHeaderFrom />
      <ActionHeaderTo />
      <ActionHeaderFee />
      <ActionHeaderValue />
    </ActionTableHeaderWrapper>
  );
}
