import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { getOptionsFromParties } from "~/components/transactions/command-palette/helpers/getOptionsFromParty";
import { useSelectedAccounts } from "~/components/transactions/command-palette/helpers/useSelectedAccounts";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { CashAccountTopSection } from "~/components/transactions/command-palette/views/change-cash-account/CashAccountTopSection";
import { SelectCashAccount } from "~/components/transactions/command-palette/views/change-cash-account/SelectCashAccount";
import { useLang } from "~/redux/lang";

/**
 * Select which exchange account the user wants to make to an cash account
 */
export const SelectAccount = () => {
  const { push } = useNavController();
  const lang = useLang();

  const fromAccounts = useSelectedAccounts();

  // get the from currencies
  const options = getOptionsFromParties(fromAccounts);

  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangeCashAccount} />
          <CashAccountTopSection />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.account}
      options={options}
      onSelection={({ party }) => {
        push(<SelectCashAccount party={party} />);
      }}
    />
  );
};
