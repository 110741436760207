import { CommandPaletteChip } from "~/components/transactions/command-palette/CommandPaletteTitle";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { useLang } from "~/redux/lang";
import { type ActionType } from "~/types/index";

export function CategoryChip({ trade }: { trade: ActionType }) {
  const Icon = TradeIcons[trade];
  const lang = useLang();
  const label = getActionTypeName({ actionType: trade, lang });
  return <CommandPaletteChip icon={<Icon />} size="small" label={label} />;
}
