import { Blockchain } from "@ctc/types";
import { AddressPurpose, request as xverseRequest } from "sats-connect";

import { HttpError } from "~/services/core";

import { type ConnectWalletConfigHandler } from "../types";

export const xverseConfig: ConnectWalletConfigHandler = {
  walletConnect: async () => {
    const resp = await xverseRequest("wallet_connect", {
      addresses: [
        AddressPurpose.Payment,
        AddressPurpose.Stacks,
        AddressPurpose.Ordinals,
      ],
    });

    if (resp.status === "error") {
      throw new HttpError(
        {
          status: 500,
          error: true,
          msg: resp.error.message,
        },
        ["walletConnect"],
      );
    }
    const initialState: Record<Blockchain.BTC | Blockchain.Stacks, string[]> = {
      [Blockchain.BTC]: [],
      [Blockchain.Stacks]: [],
    };

    await xverseRequest("wallet_disconnect", null); // we don't need to maintain the connection

    return resp.result.addresses.reduce((acc, { purpose, address }) => {
      const isBTCAddress =
        purpose === AddressPurpose.Payment ||
        purpose === AddressPurpose.Ordinals;

      if (isBTCAddress) {
        acc[Blockchain.BTC].push(address);
      } else if (purpose === AddressPurpose.Stacks) {
        acc[Blockchain.Stacks].push(address);
      }

      return acc;
    }, initialState);
  },
  shouldIgnoreBlockchainSelector: true,
  isInstalled:
    !!window?.XverseProviders && typeof window?.XverseProviders === "object",
};
