import { useIsChromium } from './useIsChromium';
import { useIsSafari184OrGreater } from './useIsSafari';

/**
 * Hook to detect if the current browser is either Chromium-based or Safari 18.4+
 * @returns {boolean} True if the browser is Chromium-based or Safari 18.4+, false otherwise
 */
export function useIsChromiumOrNewerSafari184() {
  const isChromium = useIsChromium();
  const isSafari184OrGreater = useIsSafari184OrGreater();
  
  return isChromium || isSafari184OrGreater;
} 