import { number, plural, select } from "@messageformat/runtime";
import { fr } from "@messageformat/runtime/lib/cardinals";
export default {
  accept: () => "Accepter",
  acceptTest: () => "Accepter le test",
  accountant: {
    addClient: () => "Nouveau client",
    addClientError: () =>
      "Impossible d'ajouter un nouveau client. Si ce problème persiste, veuillez contacter le support",
    billingOwner: {
      accountant: () => "Comptable",
      client: () => "Client",
    },
    billingPage: {
      failed: () => "Échoué",
      paid: () => "Payé",
    },
    clientDefault: () => "Mon premier client",
    clientInListNotFound: () =>
      "Impossible de trouver le client dans la liste des clients",
    clientListHeader: {
      billingDate: () => "date de facturation",
      dateAdded: () => "date d'ajout",
      email: () => "e-mail",
      name: () => "nom",
      plan: () => "plan",
      status: () => "statut",
    },
    clientTable: {
      billingTooltip: () =>
        "Le plan a été annulé et restera actif jusqu'à la prochaine date de facturation",
      clearFilters: () => "Effacer les filtres",
      headers: {
        billingDate: () => "Date de facturation",
        dateAdded: () => "Date d'ajout",
        name: () => "Nom",
        plan: () => "Plan",
        subscriber: () => "Abonné",
        transaction: () => "Transaction",
      },
      invitePending: () => "Invitation en attente :",
      invited: () => "Invité",
      newClient: () => "Nouveau client",
      noClients: () => "Vos clients apparaîtront ici",
      noClientsMatchFilter: () => "Aucun client ne correspond au filtre ",
      view: () => "voir",
    },
    clientVersionNotFound: () =>
      "Impossible de déterminer la version de l'abonnement. Si ce problème persiste, veuillez contacter le support",
    defaultAccount: () => "Compte par défaut",
    emptyState: {
      body: () => "Commencez par ajouter votre premier client.",
      title: () => "Vous n'avez pas encore de clients",
    },
    errorLoadingClient: () => "Erreur lors du chargement du client",
    invite: {
      emailInvalid: () => "Adresse email invalide",
      emailRequired: () => "Email requis",
      fromName: () => "Votre nom",
      fromNamePlaceholder: () => "Votre nom complet",
      invite: () => "Inviter",
      inviteAccountant: () => "Inviter un comptable",
      inviteWarning: () =>
        "Lorsque le comptable accepte l’invitation, vos données remplaceront les siennes.",
      nameInvalid: () => "Seuls les caractères alphanumériques sont autorisés.",
      nameRequired: () => "Nom requis",
      toEmail: () => "Courriel du comptable",
      toEmailPlaceholder: () => "L'email de votre comptable",
    },
    loadingClients: () => "Chargement...",
    maxClientLimit: {
      text: () =>
        "Vous avez atteint la limite client maximale pour votre compte.",
      title: () => "Limite client atteinte",
    },
    mostPopularPlanText: () => "Le plus populaire",
    moveAccountSettings: {
      body: () =>
        "Vous pouvez désormais trouver les informations et les paramètres de votre compte dans la navigation sous Compte.",
      title: () => "Nous avons déplacé les paramètres du compte",
    },
    newClient: {
      buttonText: (d) =>
        select(d.isSaving, {
          true: " Ajout d'un client ",
          other: " Ajouter un client ",
        }),
      clientType: {
        new: () => "Bêta",
        title: () => "Type de client :",
        type: {
          business: () => "Entreprise",
          individual: () => "Individuel",
        },
      },
      error: {
        invalidName: (d) =>
          "Nom " +
          d.name +
          " non valide. Seuls les caractères alphanumériques sont autorisés.",
        missingCountry: () => "Vous devez sélectionner un pays",
        missingName: () => "Vous devez entrer un nom pour votre client",
      },
      form: {
        email: {
          helperText: () =>
            "Ajoutez une adresse e-mail pour inviter et partager l'accès avec le client.",
          label: () => "Courriel (facultatif)",
        },
        name: {
          business: {
            helperText: () => "Ce nom apparaîtra sur la liste des clients.",
            label: () => "Nom de l'organisation ou du client",
          },
          individual: {
            helperText: () => "Ce nom apparaîtra sur la liste des clients.",
            label: () => "Nom du client",
          },
        },
      },
      planCard: {
        aYear: () => " un an",
        creditsAvailable: (d) =>
          d.credits +
          " " +
          select(d.singleCredit, { true: " crédit ", other: " crédits " }) +
          " disponibles",
        free: () => "Gratuit",
        nextYear: (d) => "Ensuite " + d.price + " pour les années suivantes.",
      },
      section1: {
        field1: () => "Surnom du client",
        field2: () => "Courriel (facultatif)",
        field3: () => "Pays",
        title: () => "Ajouter des informations sur le client",
      },
      section2: {
        title: () => "Sélectionnez un plan",
      },
      section3: {
        body1: () => "Le paiement dû est ",
        body2: () => " facturé sur ",
        title: () => "Paiement",
      },
      success: () => "Nouveau client ajouté",
      title: () => "Ajouter un nouveau client",
    },
    noClientFound: () => "Aucun client trouvé",
    noClientSelected: () => "Aucun client sélectionné",
    or: () => "Ou",
    savingClient: () => "Ajout d'un client",
    subscriber: {
      accountant: () => "Comptable",
      both: () => "Les deux",
      bothCTA: () =>
        "Possibilité de paiement en double. Veuillez contacter le support.",
      client: () => "Client",
      free: () => "Gratuit",
      header: () => "abonné",
      nobody: () => "—",
      tooltip: () => "Payeur du compte.",
    },
    upgradeClient: {
      cancel: () => "Annuler",
      clientName: () => "Nom du client : ",
      failMsg: () => "La mise à niveau du client a échoué",
      nextStepButtonText: () => "Suivant",
      payAmountAndUpgrade: (d) =>
        "Payez " + d.amount + " et effectuez une mise à niveau",
      payAndUpgrade: () => "Payer et mettre à niveau",
      payment: {
        defaultErrorMsg: () => "Veuillez contacter le support si cela continue",
        detail1: () => "Paiement de ",
        detail2: () => " sera facturé sur ",
        noneRequired: () => "Aucun paiement requis",
        title: () => "Paiement",
      },
      renewalErrorBanner: {
        body: () =>
          "Vous avez des factures en souffrance, vous ne pouvez pas mettre à niveau vos clients tant que les factures ne sont pas payées",
        buttonText: () => "Afficher les factures impayées",
        title: () => "Les factures passées sont en souffrance",
      },
      selectAPlan: () => "Sélectionnez un plan",
      stepLabels: {
        payment: () => "Paiement",
        selectPlan: () => "Sélectionnez un plan",
      },
      title: () => "Mettre à jour le client",
      upgrade: () => "Mise à niveau",
    },
  },
  accountantPortal: {
    createAccount: () => "Créer un compte",
    dialogLabel: () => "Intégration des comptables",
    errorMessage: () =>
      "Une erreur s'est produite lors de la mise à niveau des plans. Veuillez contacter le support",
    paymentCtaPrefix: () => "Mise à niveau pour ",
    steps: {
      accountantInfo: {
        accountantCompanyDescription: () =>
          "Le nom de votre cabinet comptable.",
        continue: () => "Continuer",
        nameDescription: () => "Nom que vos clients verront.",
        required: () => "*Champs obligatoires",
      },
      payment: {
        cancelAnyTimeText: () => " Vous pouvez annuler à tout moment.",
        cardDetailsLabel: () => "Détails de la carte",
        couponText: () => "Avez-vous un code promo ?",
        discountAppliedText: () => "Remise appliquée",
        discountDescription1: () => "Vous serez facturé ",
        discountDescription2: () => " pour la première année et ",
        discountDescription3: () => " pour les années suivantes.",
        label: () => "Paiement",
        paymentDescription1: () => "Vous serez facturé ",
        paymentDescription2: () =>
          " annuellement jusqu'à ce que vous annuliez votre abonnement.",
        planTextSuffix: () => " plan",
        submitButtonSuffix: () => " et terminer",
        subtotalText: () => "Total",
        title: () => "Récapitulatif des paiements",
        yearlySubscriptionText: () => "Abonnement annuel",
      },
      welcome: {
        info: {
          switching: {
            subtitle: () =>
              "En passant au portail comptable, vous ne pourrez pas annuler l'action. Vous pouvez gérer votre compte à partir des paramètres, notamment en modifiant votre adresse e-mail.",
            title: () => "Changer de compte",
          },
          transactions: {
            subtitle: () =>
              "Vos transactions et votre plan de paiement seront transférés vers votre nouveau portail comptable et vos données apparaîtront sur la liste des clients en tant que premier client. Vous pouvez continuer à gérer vos transactions comme avant.",
            title: () => "Transactions et migration de données",
          },
        },
      },
    },
    subtitle: () =>
      "Gérez et calculez les taxes de vos clients en un seul endroit.",
    title: () => "Bienvenue sur le portail des comptables",
  },
  accountingIntegrations: {
    connectAccountingSoftware: () => "Connecter un logiciel de comptabilité",
    connectIntegration: {
      connected: {
        connected: () => "Connecté",
        connectedAt: (d) => "Connecté à " + d.time,
        disconnect: () => "Supprimer la connexion",
        disconnectWarning: {
          actionText: () => "Déconnecter",
          alert: () =>
            "Les mappages de compte seront supprimés, vous devrez les remapper lors de la reconnexion.",
          body: (d) =>
            "Cela déconnectera votre compte de " +
            d.erp +
            " , vous ne pourrez plus synchroniser vos transactions avec " +
            d.erp +
            " .",
          title: (d) => "Déconnecter " + d.erp,
        },
        selectTenant: () => "Sélectionnez une entreprise",
      },
      disconnected: {
        title: () => "Connecter l'intégration",
      },
    },
    defaults: {
      accountMapping: {
        asset: {
          title: () => "Compte d'actifs",
          tooltip: () =>
            "Compte représentant la valeur des actifs du portefeuille de l'utilisateur",
        },
        cash: {
          title: () => "Compte de caisse",
          tooltip: () =>
            "Compte représentant les avoirs en espèces. Si vous vendez des crypto-monnaies, nous représenterons le produit en espèces ici et vice versa lorsque vous achetez des crypto-monnaies. Les échanges de crypto à crypto n'auront aucun impact sur ce compte.",
        },
        expense: {
          title: () => "Compte de dépenses",
          tooltip: () => "Compte représentant les dépenses cryptographiques",
        },
        fee: {
          title: () => "Compte de frais",
          tooltip: () => "Compte représentant le montant payé en frais",
        },
        income: {
          title: () => "Compte de revenus",
          tooltip: () =>
            "Compte représentant les revenus provenant de la crypto",
        },
        loan: {
          title: () => "Compte de prêt",
          tooltip: () =>
            "Compte représentant des prêts et remboursements cryptographiques",
        },
        loss: {
          title: () => "Compte de pertes",
          tooltip: () => "Compte représentant le montant de crypto perdu",
        },
        "realised-gain": {
          title: () => "Compte de gain réalisé",
          tooltip: () =>
            "Compte représentant les gains qui ont été réalisés à partir de la cession d'actifs cryptographiques",
        },
        "realised-loss": {
          title: () => "Compte de pertes réalisées",
          tooltip: () =>
            "Compte représentant les pertes résultant de la cession d'actifs cryptographiques",
        },
        "zero-cost-buy": {
          title: () => "Historique d'achat manquant",
          tooltip: () =>
            "Lorsqu'un actif est acheté ou obtenu et qu'aucun paiement n'est effectué pour celui-ci, nous considérons que le prix de base de l'actif est la valeur marchande et ce compte est utilisé pour équilibrer l'autre côté du journal.",
        },
      },
      empty: () => "Commencez par connecter votre intégration comptable",
      mapped: () => "Cartographié",
      required: () => "Requis",
      selectorPlaceholder: (d) =>
        "Sélectionnez " + d.account + " dans " + d.erp,
      title: () => "Comptes par défaut",
      unmapped: () => "Non cartographié",
    },
    integrations: () => "Cartographie par défaut",
    rollupSettings: {
      options: {
        day: () => "Jour",
        month: () => "Mois",
        none: () => "Pas de cumul",
      },
      placeholder: () => "Sélectionnez la période de cumul",
      subtitle: () =>
        "Toutes les transactions de la période sélectionnée seront regroupées dans une seule écriture de journal",
      title: () => "Paramètres de cumul",
    },
    rules: () => "Règles personnalisées",
    subtitle: (d) =>
      "Associez les catégories de CTC à vos journaux dans " + d.erp + " .",
    subtitleWithoutERP: () =>
      "Associez les catégories de CTC à votre logiciel de comptabilité.",
    title: () => "Intégrations comptables",
    warningSettings: {
      label: () =>
        "Autoriser la synchronisation des transactions avec des avertissements",
      subtitle: (d) =>
        "Si cette option est désactivée, tous les avertissements doivent être résolus sur une transaction donnée avant de pouvoir être synchronisés avec " +
        d.erp,
      title: () =>
        "Autoriser la synchronisation des transactions avec des avertissements",
    },
  },
  accountingSteps: {
    body: () => "Tout ce dont vous avez besoin pour gérer vos clients",
    steps: {
      0: {
        body: () =>
          "Notre portail comptable vous permet d'inviter et de gérer des clients à partir d'un tableau de bord facile à utiliser.",
        title: () => "Gérez tous vos clients en un seul endroit",
      },
      1: {
        body: () =>
          "Nous prenons directement en charge des centaines d'échanges, de blockchains et de services. Ajoutez simplement des clés API et des adresses de portefeuille.",
        title: () =>
          "Obtenez facilement des données clients grâce à des échanges directs et à des intégrations de blockchain",
      },
    },
    title: () => "Outils de comptabilité et démo",
  },
  add: () => "Ajouter",
  addManualTX: {
    currencyPaidIn: () => "Devise dans laquelle vous avez payé les frais",
    currencyPlaceholder: () => "par exemple BTC, ETH, XRP",
    error: () => "Une erreur s'est produite lors de l'ajout d'une transaction.",
    exchangeName: () => "Nom de l'échange",
    feePaidIn: () => "Montant des frais payés dans la devise des frais",
    in: () => "dans",
    mustDestination: () => "Vous devez entrer une destination",
    mustEnterExchange: () => "Vous devez saisir un nom d'échange",
    mustSource: () => "Vous devez entrer une source",
    optional: () => "Facultatif",
    paidInFees: () => "payé en frais",
    priceForOne: () => "Prix pour 1",
    quotePlaceholder: () => "par exemple TUSD, BTC, ETH, XRP",
    saving: () => "Économie",
    success: () => "Transaction manuelle créée.",
    totalQuantityOf: () => "Quantité totale de",
    totalValueIn: () => "Valeur totale en",
    viewTransaction: () => "Voir",
    whereDestination: () => "Où est la destination de votre transaction",
    whereFrom: () => "D'où êtes-vous transféré ?",
    whereSource: () => "Où est la source de votre transaction",
    whereTo: () => "Où allez-vous être transféré ?",
    whichCategory: () => "Quelle catégorie choisir ?",
  },
  adjustedPrice: {
    considersWashSalesDescription: () =>
      " et compte tenu des règles de vente de lavage",
    description: () =>
      "Il s'agit du prix ajusté après application du prix moyen du pool",
  },
  advanced: () => "Avancé",
  advancedTransactionOptions: {
    findCategory: () => "Rechercher une catégorie",
    grouped: {
      description: () =>
        "Il est vraiment important de vous assurer d’avoir importé toutes vos données à partir de toutes les sources possibles.",
      title: () => "Transactions groupées",
    },
    in: {
      categoryGuide: () => "Guide des catégories de transactions",
      description: () =>
        "Il est vraiment important de vous assurer d’avoir importé toutes vos données à partir de toutes les sources possibles.",
      incorrectAction: () => "Afficher les catégories sortantes",
      incorrectPrefix: () => "Pas une transaction entrante ?",
      inputGroup: () => "Catégories entrantes",
      title: () => "Transactions entrantes",
    },
    out: {
      categoryGuide: () => "Guide des catégories de transactions",
      description: () =>
        "Il est vraiment important de vous assurer d’avoir importé toutes vos données à partir de toutes les sources possibles.",
      incorrectAction: () => "Voir les catégories entrantes",
      incorrectPrefix: () => "Ce n'est pas une transaction sortante ?",
      inputGroup: () => "Catégories sortantes",
      title: () => "Transactions sortantes",
    },
  },
  alerts: {
    customCSV: () =>
      "Assurez-vous que vos données sont préparées correctement et dans un format de fichier CSV",
    noCookie: () =>
      "Veuillez activer les cookies sinon vous ne pourrez pas vous connecter ! Si vous utilisez le navigateur Brave, vous devez cliquer sur le lion près de la barre de navigation et désactiver les boucliers pour ce site.",
  },
  all: () => "Tous",
  allPlans: () =>
    "Tous les plans bénéficient d'une garantie de remboursement de 30 jours",
  api: {
    addressesIdentified: (d) => "Adresses identifiées comme " + d.exchangeName,
    apiKeyLabel: () => "Clé API",
    connect: () => "Connecter",
    delete: () => "Supprimer l'API",
    deleteConfirmationText: (d) =>
      "Êtes-vous sûr de vouloir supprimer la clé API " +
      d.exchangeName +
      " « " +
      d.api +
      " » ? Cela supprimera également TOUTES les transactions importées par cette clé API. Cette action ne peut pas être annulée.",
    disabledExplanation: () =>
      "Vous ne pouvez pas synchroniser via OAuth si vous avez déjà synchronisé via la clé API",
    edit: () => "Modifier les détails de l'API",
    editNickname: () => "Modifier le nom de l'API",
    editingSubmit: () => "Mise à jour de l'API",
    exchanges: {
      binance: {
        crossMarginCurrencies: () => "Devises à marge croisée",
        syncMarginTickers: () => "Synchronisation des marges croisées",
        syncMarginTickersExplanation: () =>
          "Veuillez sélectionner les devises à marge croisée que vous avez négociées. Cela est nécessaire pour améliorer les performances en raison des limitations imposées par l'API de Binance lors de la récupération de vos données.",
      },
    },
    existingSubmit: (d) => "Ajouter une API " + d.source + " supplémentaire",
    hardSync: {
      confirmationAllAPIs: (d) =>
        "Synchronisation matérielle des API " + d.name + " ?",
      confirmationOneAPI: () => "API de synchronisation matérielle ?",
      textOneAPI: (d) =>
        "La synchronisation matérielle importera toutes les transactions de l'API " +
        d.name +
        " à partir du " +
        d.date +
        " . Cela peut parfois entraîner des transactions en double qui doivent être gérées manuellement, dans le cas où l'intégration a changé depuis votre dernière synchronisation.",
      titleAllChipAPIs: () =>
        "Resynchronisez toutes les données, cela renverra toutes les transactions supprimées pour cette intégration.",
      titleOneAPI: (d) =>
        "Resynchroniser toutes les transactions à partir du " +
        d.date +
        " pour l'API " +
        d.name,
    },
    import: () => "Importer",
    importRules: {
      existingApi: () => "La clé API existe déjà.",
      missingApi: () => "Vous devez saisir une clé API",
      missingPassphrase: () => "Vous devez entrer une phrase secrète",
      missingSecret: () => "Vous devez entrer le secret",
      missingUid: () => "Vous devez entrer un uid",
      multipleKeyError: (d) =>
        "Impossible d'ajouter plusieurs clés API pour " + d.name,
      uidHint: () =>
        "Astuce : cela peut parfois être le nom ou le « mémo » que vous avez donné à l'API",
    },
    maxTickerLabel: () =>
      "Vous avez atteint le nombre maximum de marchés autorisés par l'API. Si vous avez d'autres marchés à ajouter, veuillez les importer via CSV.",
    missingTickerAlert: (d) =>
      d.exchangeName +
      " exige que tous les tickers soient spécifiés lors de la demande d'historiques de transactions via son API. Veuillez ajouter TOUS les tickers que vous avez négociés pour renvoyer des rapports fiscaux précis. Vous pouvez également importer à l'aide de l'option CSV.",
    name: () => "Nom de l'API (facultatif)",
    nickname: () => "Surnom",
    passwordLabel: () => "Mot de passe",
    reauthorize: () => "Réautoriser",
    resync: () => "Resynchronisation",
    secretLabel: () => "Secrète",
    softSync: (d) => "Synchroniser les transactions API " + d.name + " depuis",
    submit: (d) => "Ajouter l'API " + d.source,
    sync: () => "Synchroniser",
    syncing: () => "Synchronisation",
    tickerLabel: () => "Sélectionnez tous les tickers utilisés",
    uidLabel: () => "ID utilisateur",
    update: () => "Mettre à jour les détails de l'API",
  },
  apply: () => "Appliquer",
  auth: {
    acceptTermsToContinue: () =>
      "Vous devez accepter les conditions d'utilisation et la politique de confidentialité pour continuer",
    accountantClient: () => "Des clients avec des cryptos ?",
    accountantCompanyName: () => "Nom de l'entreprise",
    accountantContactName: () => "Nom du contact",
    accountantSignUp: () => "s'inscrire comme comptable",
    accountantSignupSubTitle: () =>
      "L'inscription prend moins d'une minute - aucune carte de crédit requise",
    accountantSignupTitle: () => "Inscription comptable",
    accountantWorkEmail: () => "Courriel professionnel",
    alreadyAccount: () => "Vous avez un compte ?",
    and: () => "et",
    areAccountant: () => "Etes-vous comptable ?",
    backTo: () => "Retourner à",
    bitcoinDotComAu30Banner: () =>
      "Réduction de 30% appliquée sur les forfaits payants",
    breaker: () => "OU",
    captcha: {
      and: () => "et",
      apply: () => "appliquer.",
      privacyPolicy: () => "politique de confidentialité",
      protectedBy: () =>
        "Ce site est protégé par reCAPTCHA et la politique de confidentialité de Google ",
      termsOfService: () => "Conditions d'utilisation",
      verificationFailed: () => "La vérification a échoué. Veuillez réessayer.",
    },
    coinbase: () => "Coinbase",
    coinbase30Banner: () =>
      "10 000 transactions Coinbase gratuites et 30 % de réduction sur les forfaits payants appliqués",
    coinbaseOne1DollarBanner: (d) =>
      d.rookiePlanPrice +
      " Forfait Rookie. 40 % de réduction sur tous les forfaits supplémentaires",
    coinbaseOne1DollarBannerNoCurrency: () =>
      "Offre exclusive pour les membres de Coinbase appliquée",
    coinbaseOne40Banner: () =>
      "40 % de réduction sur les forfaits payants appliquée à l'inscription, exclusivement pour les membres Coinbase One",
    coinbaseOnePremium40Banner: () =>
      "50 % de réduction sur le niveau premium de CTC, Trader",
    coinjar30Banner: () =>
      "1 000 transactions CoinJar gratuites et 30 % de réduction sur les forfaits payants appliqués lors de l'inscription",
    coinstatsBanner: () =>
      "30 $ de réduction sur les forfaits payants appliqués à l'inscription",
    compliance: () => "Facilitez la conformité avec Crypto Tax Calculator",
    countryWarningBody: () =>
      "Vous ne pouvez pas accepter une invitation d'un comptable d'un autre pays. Veuillez contacter le support.",
    countryWarningTitle: () =>
      "Votre pays est différent de celui de votre comptable",
    customerNum: () => "Plus de 200 000 clients",
    differentSignUpMethod: () =>
      "Votre adresse e-mail existe déjà sous une autre méthode d'inscription. Veuillez réessayer via votre méthode d'origine.",
    email: () => "E-mail",
    embedded: {
      connectWith: (d) => "Connectez-vous avec " + d.provider,
      signInWith: (d) => "Connectez-vous avec " + d.provider,
      tagline: () => "Rapports fiscaux transparents et précis",
      title: () => "Démarrer avec Tax Hub",
    },
    errors: {
      invalidCode: () => "Code 2FA non valide. Veuillez réessayer.",
      invalidCredentials: () =>
        "Email ou mot de passe invalide. Veuillez réessayer.",
      invalidSignup: () => "Inscription non valide. Veuillez réessayer.",
    },
    forgot: () => "Mot de passe oublié ?",
    forgotPassword: () => "Réinitialiser le mot de passe",
    goBack: () => "Retourner",
    google: () => "Google",
    iAgree: () => "En créant un compte, vous acceptez les",
    independentReserve30Banner: () =>
      "Réduction de 30% appliquée sur les forfaits payants",
    invalidCombination: () => "Combinaison email/mot de passe invalide",
    invalidToken: () => "Jeton ou e-mail non valide",
    inviteReceived: () => "Invitation reçue de :",
    login: () => "Se connecter",
    loginPage: {
      oauth: {
        coinbase: () => "Se connecter avec Coinbase",
        google: () => "Se connecter avec Google",
        metamask: () => "Se connecter avec MetaMask",
      },
      title: () => "Bon retour",
    },
    loginTitle: () => "Content de te revoir!",
    loginTitleEmbedded: () => "Connectez-vous à Tax Hub",
    metamask: () => "MetaMask",
    metamask30Banner: () =>
      "30% de réduction sur les forfaits payants appliquée à l'inscription",
    missingEmail: () =>
      "Pour utiliser cette fonctionnalité, veuillez saisir une adresse e-mail via la page des paramètres",
    noAccount: () => "Vous n'avez pas de compte ?",
    notAccountant: () => "Vous n'êtes pas comptable ?",
    officialPartner: () => "PARTENAIRE OFFICIEL DE",
    officialPartners: () => "Partenaires officiels",
    orLogin: () => "Ou connectez-vous avec votre e-mail",
    orSignup: () => "Ou inscrivez-vous avec votre e-mail",
    orSwitchTo: () => "ou",
    password: () => "Mot de passe",
    passwordCondition: () =>
      "Votre mot de passe doit comporter au moins 8 caractères. Il doit également contenir un chiffre (0-9) ou un caractère spécial (!@#$%^&*).",
    passwordResetButton: () => "Réinitialiser le mot de passe",
    passwordResetFailed: () => "La réinitialisation du mot de passe a échoué",
    passwordResetInstructions: () =>
      "Entrez votre adresse email ci-dessous et nous vous enverrons un lien pour réinitialiser votre mot de passe :",
    passwordResetSuccessfully: () => "Réinitialisation du mot de passe réussie",
    passwordUpdatedSuccessfully: () => "Mot de passe mis à jour avec succès",
    privacyPolicy: () => "politique de confidentialité",
    recaptcha: {
      part1: () => "Ce site est protégé par reCAPTCHA et les",
      part2: () => "et",
      part3: () => "de Google s'appliquent.",
    },
    recogniseBody: () => "Vos données seront partagées avec eux",
    recogniseTitle: () =>
      "Assurez-vous de reconnaître l'email de votre comptable !",
    resetEmailSent: (d) =>
      "Si l'e-mail fourni est associé à un compte, un e-mail de réinitialisation a été envoyé à " +
      d.email,
    resetPasswordSubTitle: () =>
      "Saisissez un nouveau mot de passe pour votre compte",
    resetPasswordTitle: () => "Réinitialiser votre mot de passe",
    setPasswordTitle: () => "Définissez votre mot de passe",
    signInWith: () => "Connectez-vous avec :",
    signin: () => "Se connecter",
    signup: () => "S'inscrire",
    signupPage: {
      accurateCryptoTaxes: () => "Impôts crypto précis.",
      mobileTitle: () => "Commencez gratuitement !",
      noGuesswork: () => "Sans approximation.",
      oauth: {
        coinbase: () => "S'inscrire avec Coinbase",
        google: () => "S'inscrire avec Google",
        metamask: () => "S'inscrire avec MetaMask",
      },
      terms: {
        and: () => "et",
        body: () => "J'accepte les",
        privacyPolicy: () => "Politique de confidentialité",
        termsNotAccepted: () =>
          "Veuillez accepter les Conditions d'utilisation et la Politique de confidentialité pour continuer",
        termsOfService: () => "Conditions d'utilisation",
      },
      text: () => "S'inscrire",
      title: () => "Inscrivez-vous et commencez gratuitement",
      trustpilot: {
        reviews: () => "Plus de 500 avis",
        title: () => "Basé sur",
      },
    },
    signupPromoText: {
      phantom: () =>
        "Obtenez 200 transactions gratuites sur Phantom Wallet et 20 % de réduction sur votre forfait CTC",
    },
    signupSubTitle: () => "Il faut moins d'une minute pour s'inscrire",
    signupTitle: () => "Créez votre compte gratuit maintenant",
    somethingWentWrong: () => "Une erreur s'est produite, veuillez réessayer",
    strongPassword: () => "Super. Votre mot de passe répond aux exigences.",
    termsAndConditions: () => "Conditions d'utilisation",
    toSignUp: () => "pour vous inscrire à Crypto Tax Calculator !",
    tooManyPasswordResetAttempts: () =>
      "Trop de tentatives de réinitialisation du mot de passe, veuillez réessayer plus tard.",
    transparentReports: () => "Rapports fiscaux transparents et précis",
    trustPilotExcellent: () => "Excellent",
    trustedBy: () => "Approuvé par",
    unableToResetEmail: (d) =>
      "Impossible de réinitialiser le mot de passe pour l'e-mail : " + d.email,
    unlock: () => "Compte débloqué",
    userSignUp: () => "s'inscrire en tant qu'utilisateur",
    worldwide: () => "mondial.",
    xero: () => "Xéro",
    yourAccountant: () => "votre comptable",
    youreInvited: () => "Vous êtes invité par",
  },
  autoClose: {
    title: () =>
      "Cet onglet devrait se fermer automatiquement, mais n'hésitez pas à le fermer maintenant.",
  },
  back: () => "Dos",
  billing: {
    44359: () =>
      "44359 : une erreur s'est produite lors de la création du mode de paiement",
    45732: () =>
      "45732 : une erreur s'est produite lors de la recherche de la carte",
    addCard: {
      cancel: () => "Annuler",
      description: () =>
        "Cette nouvelle carte deviendra votre moyen de paiement par défaut",
      loading: () => "Ajout de carte",
      submit: () => "Ajouter une carte",
      title: () => "Ajouter une carte",
    },
    billingHistory: {
      table: {
        amount: () => "Montant",
        date: () => "Date",
        description: () => "Description",
      },
      title: () => "Historique de facturation",
    },
    paymentFailed: () =>
      "Le paiement a échoué. Une erreur s'est produite. Veuillez réessayer ou contacter le support si le problème persiste.",
    paymentFailedCode: (d) =>
      "Paiement échoué. Une erreur s'est produite avec notre processeur de paiement. Veuillez réessayer ou contacter le support avec le code d'erreur " +
      d.code +
      " .",
    paymentMethod: {
      button: () => "Ajouter une carte",
      ending: () => "fin",
      expired: () => "Expiré",
      expires: () => "Expire",
      inUse: () => "Utilisé",
      title: () => "Carte active",
    },
    paymentStatus: {
      failed: {
        description: () =>
          "Veuillez mettre à jour les informations de votre carte pour continuer à utiliser toutes les fonctionnalités de l'application.",
        title: () => "Paiement échoué",
      },
    },
    somethingWentWrong: () => "Quelque chose s'est mal passé",
    stripe: {
      44359: () =>
        "44359 : une erreur s'est produite lors de la création du mode de paiement",
      45732: () =>
        "45732 : une erreur s'est produite lors de la recherche de l'élément de carte",
      applied: () => "Appliqué",
      apply: () => "Appliquer",
      cardSuccessfullyAdded: () => "Carte ajoutée avec succès.",
      clear: () => "Clair",
      couponExpired: (d) => "Le coupon " + d.name + " a expiré",
      couponNotValid: (d) => "Le coupon " + d.name + " n'est pas valide",
      discountApplied: (d) =>
        "Remise appliquée " + d.percent + " sur votre première année !",
      failedToAddTheCard: () => "Impossible d'ajouter la carte",
      noPortal: () =>
        "Impossible d'accéder au portail Stripe. Veuillez réessayer ou contacter le support.",
      noSession: () =>
        "Impossible de créer une session Stripe. Veuillez réessayer ou contacter le support.",
      noStripe: () =>
        "Une erreur s'est produite lors de l'accès à Stripe. Veuillez réessayer ou contacter le support.",
    },
    title: () => "Facturation",
    wentWrongBillingHistory: () =>
      "Une erreur s'est produite lors de l'obtention de l'historique de facturation. Veuillez réessayer ultérieurement.",
    wentWrongCoupon: () =>
      "Une erreur s'est produite lors de l'obtention des détails du coupon. Veuillez réessayer plus tard.",
    wentWrongPaymentMethods: () =>
      "Une erreur s'est produite lors de l'obtention des modes de paiement. Veuillez réessayer ultérieurement.",
  },
  bulkSelect: {
    moreActions: () => "Plus d'actions",
    recategoriseAs: () => "Reclasser comme",
  },
  button: {
    accept: () => "Accepter",
    decline: () => "Déclin",
    pending: () => "En attente",
  },
  calcoTour: {
    buttons: {
      back: () => "Dos",
      next: () => "Suivant",
    },
    reconciliation: {
      uncategorise: {
        CTA: () => "Commencer à catégoriser",
        addAnotherAccount: () =>
          "Si vous vous rendez compte que vous avez oublié d'importer un compte, vous pouvez en ajouter un autre ici",
        associateTx: (d) =>
          "Pour vous aider à identifier et à catégoriser cette transaction, consultez toutes vos autres transactions avec " +
          d.counterParty,
        categoriesButton: () =>
          "Si vous savez de quelle transaction il s'agit, catégorisez-la et nous appliquerons automatiquement la catégorie à d'autres transactions similaires.",
        exampleTx: () =>
          "Voici un exemple de transaction que vous devriez catégoriser.",
        incomingWallet: () => "Il s’agit du portefeuille qui a reçu l’actif.",
        outgoingWallet: () => "Il s’agit du portefeuille qui a envoyé l’actif.",
        typeSelectorIncoming: () =>
          "Celui-ci est étiqueté « entrant » car vous avez reçu un actif, mais une catégorie plus spécifique est requise pour déterminer le résultat fiscal.",
        typeSelectorOutgoing: () =>
          "Celui-ci est étiqueté « sortant » car vous avez envoyé un actif, mais une catégorie plus spécifique est requise pour déterminer le résultat fiscal.",
      },
    },
  },
  cancel: () => "Annuler",
  changeLang: {
    changeLang: () => "Changer de langue",
    error: () => "Erreur lors de l'enregistrement de la préférence de langue",
    language: () => "Langue",
    languages: () => "Langues",
    pleaseSelect: () => "Veuillez sélectionner votre langue",
  },
  clear: () => "Clair",
  clientPays: {
    bannerText: () =>
      "Afin que votre comptable puisse générer vos rapports fiscaux, vous devrez mettre à niveau votre abonnement.",
    bannerTitle: () => "Mise à niveau pour générer des rapports fiscaux",
    buttonText: () => "Mise à niveau de l'abonnement",
  },
  clients: {
    cancelSub: {
      areYouSure: () =>
        "Une fois votre période de facturation actuelle terminée, ce client sera annulé. Toutes les données et tous les liens vers le client seront conservés. Êtes-vous sûr de vouloir annuler l'abonnement ?",
      buttonText: () => "Annuler l'abonnement",
      exit: () => "Sortie",
    },
    clone: {
      clone: () => "Cloner",
      cloneClient: () => "Cloner le client",
      clonesAccountantData: () =>
        "Cela clonera les données que vous avez téléchargées pour ce client (c'est-à-dire pas les données de votre client si vous consultez son compte). Cela n'aura aucun impact sur le compte existant. Le compte cloné sera un compte gratuit mais pourra être mis à niveau.",
    },
    delete: {
      accessOutcome: () =>
        "Les données deviendront inaccessibles pour vous et vos collaborateurs.",
      areYouSure: (d) =>
        select(d.name, {
          unknown: " Êtes-vous sûr de vouloir supprimer ce client ? ",
          other:
            " Êtes-vous sûr de vouloir supprimer le client « " +
            d.name +
            " » ? ",
        }),
      cannotBeUndone: () => "Cette action ne peut pas être annulée.",
      clientAccessOutcome: (d) =>
        select(d.accountantOwnedData, {
          true: " Votre client perdra également l'accès à ses données. ",
          other: " Votre client continuera à avoir accès à ses données. ",
        }),
      deleteButton: () => "Supprimer",
      deleteClient: () => "Supprimer le client",
    },
    invite: {
      emailRequired: () => "Email requis",
      fromName: () => "Votre nom",
      fromNamePlaceholder: () => "Votre nom complet",
      invite: () => "Inviter",
      inviteClient: () => "Inviter un client",
      inviteWarning: () =>
        "Lorsque le client accepte l'invitation, par défaut, il verra les données que vous avez téléchargées en son nom. Si le client souhaite voir les données originales qu'il a téléchargées, il a cette option dans ses paramètres.",
      nameRequired: () => "Nom requis",
      toEmail: () => "Courriel du client",
      toEmailPlaceholder: () => "L'email de votre client",
    },
    migrate: {
      settingsBody: () =>
        "À compter du 8 septembre 2022, tous les comptes de démonstration ont été supprimés. Toutes les transactions précédemment importées ont été migrées vers un nouveau client, qui peut être supprimé à tout moment.",
      settingsTitle: () => "Plus de comptes de démonstration",
    },
    newAccountInvite: {
      acceptFailed: () =>
        "Échec de l'acceptation de l'invitation, veuillez réessayer.",
      declineFailed: () =>
        "Impossible de refuser l'invitation, veuillez réessayer.",
      isNotActionable: () =>
        "Cette invitation n'est plus exploitable, veuillez contacter le support pour obtenir de l'aide.",
      textAccountantOwns: (d) =>
        "Votre comptable ( " +
        d.email +
        " ) vous a invité à collaborer. En acceptant cette invitation, vous pourrez consulter et modifier les données que votre comptable a téléchargées en votre nom. Vous pouvez révoquer l'accès à tout moment, mais les données resteront la propriété de votre comptable. Êtes-vous sûr de vouloir accepter cette invitation ?",
      textClientOwns: (d) =>
        "Votre comptable ( " +
        d.email +
        " ) vous a invité à collaborer. En acceptant cette invitation, votre comptable pourra consulter et modifier vos données. Vous pouvez révoquer l'accès à tout moment. Êtes-vous sûr de vouloir accepter cette invitation ?",
      title: () => "Vous avez été invité par votre comptable",
    },
    reinvite: {
      emailRequired: () => "Email requis",
      fromName: () => "Votre nom",
      fromNamePlaceholder: () => "Votre nom complet",
      nameRequired: () => "Nom requis",
      reinvite: () => "Réinviter",
      reinviteClient: () => "Réinviter le client",
      reinviteWarning: () =>
        "Le client visualisera le compte partagé après avoir accepté l’invitation.",
      toEmail: () => "Courriel du client",
      toEmailPlaceholder: () => "L'email de votre client",
    },
    settings: () => "Paramètres du client",
    uncancelSub: {
      areYouSure: () =>
        "Ce client ne sera plus annulé et vous serez facturé au début de la nouvelle période de facturation. Êtes-vous sûr de vouloir annuler l'abonnement ?",
      buttonText: () => "Annuler l'abonnement",
      exit: () => "Sortie",
    },
  },
  close: () => "Fermer",
  collaborators: {
    cancelInvitation: () => "Annuler l'invitation",
    cancelInvitationGenericError: () =>
      "Une erreur s'est produite lors de la suppression de l'invitation. Veuillez réessayer ultérieurement.",
    cancelInvitationSuccess: () => "Invitation annulée avec succès.",
    email: () => "E-mail",
    invitationSent: () => "Invitation envoyée !",
    inviteTeamMember: () => "Inviter un membre de l'équipe",
    inviteTeamMembers: {
      linkText: () => "Inviter des membres",
      postLinkText: () => "pour collaborer et partager des clients",
    },
    name: () => "Nom",
    pending: () => "En attente",
    reinvite: () => "Réinviter un membre de l'équipe",
    reinviteSuccess: () => "Membre de l'équipe réinvité avec succès.",
    revokeAccess: () => "Révoquer l'accès",
    revokeAccessSuccess: () => "Accès révoqué avec succès.",
    send: () => "Envoyer",
    team: () => "Équipe",
    teamMemberEmail: () => "Adresse e-mail du membre de l'équipe",
    teamMemberName: () => "Nom complet du membre de l'équipe",
    teamMembers: () => "Membres de l'équipe",
    teamMembersEmpty: () => "Aucun membre de l'équipe n'est invité",
  },
  confirm: () => "Confirmer",
  contacts: {
    addAddress: {
      addAddress: () => "Ajouter une adresse",
      address: () => "Adresse",
      blockchain: () => "Blockchain",
      buttons: {
        add: () => "Ajouter",
        cancel: () => "Annuler",
      },
      duplicateAddress: () => "Adresse en double",
      title: () => "Ajouter une nouvelle adresse",
    },
    addContact: () => "Ajouter un contact",
    addressBook: () => "Contacts",
    contact: () => "Nom du contact",
    create: {
      addAddress: () => "Ajouter une adresse",
      address: () => "Adresse",
      alreadyExists: () => "Le contact existe déjà",
      associatedAddresses: () => "Adresses associées",
      blockchain: () => "Blockchain",
      cancel: () => "Annuler",
      createContact: () => "Créer un contact",
      duplicateAddress: () => "Adresse en double",
      name: () => "Nom du contact",
      nameTitle: () => "Nom",
      title: () => "Nouveau contact",
    },
    csv: {
      errorFileNotCsv: () => "Doit être un fichier CSV",
      exportCsv: () => "Exporter les adresses non identifiées au format CSV",
      importCsv: () => "Importer un fichier CSV",
      success: (d) => d.number + " contacts mis à jour",
    },
    edit: {
      associatedAddresses: () => "Adresses associées",
      rules: () => "Règles",
    },
    entityType: {
      contract: () => "Contrat intelligent",
      imported: () => "Portefeuille importé",
      manual: () => "Contact manuel",
    },
    error: () => "Erreur",
    findAddress: () => "Trouver l'adresse",
    identified: () => "Identifié",
    identifiedTable: {
      allTooltip: () =>
        "Certaines adresses de ce contact ont été identifiées sur tous les réseaux",
      asterisk: () => "*",
      networks: () => "Réseaux",
      rules: () => "Règles",
      rulesApplied: (d) =>
        plural(d.count, 0, fr, {
          one: " 1 règle ",
          other: number("fr", d.count, 0) + " règles ",
        }) + " appliqué",
      setRule: () => "Définir une règle",
      transactions: () => "Transactions",
    },
    newContact: () => "Ajouter un nouveau contact",
    noContacts: () =>
      "Cliquez sur « Ajouter un contact » pour ajouter votre premier contact.",
    noResults: () => "Aucun résultat de recherche",
    noUnidentified: () => "Toutes les adresses identifiées. Rien à voir ici.",
    required: () => "Requis",
    ruleCard: {
      active: () => "Actif",
      addCondition: () => "Ajouter une condition",
      appliesTo: (d) =>
        "Cette règle s'applique à " +
        plural(d.count, 0, fr, {
          one: " 1 transaction ",
          other: number("fr", d.count, 0) + " transactions ",
        }),
      cancel: () => "Annuler",
      createMoreRules: () => "Créer une autre règle",
      createRule: () => "Créer une règle",
      deleteRule: () => "Supprimer la règle",
      disableRule: () => "Désactiver la règle",
      disabled: () => "Désactivé",
      disabledTooltip: () =>
        "La règle ne s'applique pas aux nouvelles transactions. Les transactions existantes sont toujours liées à cette règle et ses effets ne seront pas annulés par d'autres règles.",
      editRule: () => "Modifier",
      enableRule: () => "Activer la règle",
      enabledTooltip: () =>
        "Cette règle est active et s'appliquera aux transactions nouvelles et existantes",
      if: () => "SI",
      newRule: (d) => "Règle " + d.number,
      save: () => "Sauvegarder",
      setAccount: () => "Ajouter une opération",
      then: () => "ALORS",
      tooltip: () =>
        "Veuillez connecter votre logiciel de comptabilité pour créer des règles",
      view: () => "Voir",
      willApplyTo: (d) =>
        "Cette règle s'appliquera à " +
        plural(d.count, 0, fr, {
          one: " 1 transaction ",
          other: number("fr", d.count, 0) + " transactions ",
        }),
    },
    rules: {
      importsDrawerTitle: () => "Gérer les règles",
      operators: {
        changeErpAssetAccount: () => "Compte d'actifs",
        changeErpCashAccount: () => "Compte de caisse",
        changeErpGainsAccount: () => "Compte de gains",
        changeErpLoanAccount: () => "Compte de prêt",
        changeErpPnLAccount: () => "Compte de résultat",
        recategorise: () => "Catégorie",
      },
      ruleNotFound: () => "Règle non trouvée",
      rulesImportsButton: () => "Règles",
    },
    table: {
      address: () => "Adresse",
      addresses: () => "Adresses",
      name: () => "Nom",
      transactions: () => "Transactions",
      type: () => "Taper",
    },
    unidentified: () => "Non identifié",
    unidentifiedTable: {
      assets: () => "Actifs envoyés/reçus",
      chains: () => "Chaînes",
      firstDate: () => "Date de la première transaction",
      lastDate: () => "Date de la dernière transaction",
      modal: {
        assets: () => "Actifs:",
        buttonCTA: () => "Identifier l'adresse",
        importCTA: () => "Importez-le",
        importLabel: () => "Est-ce votre portefeuille ?",
        transferAmount: () => "Montant du transfert :",
        txs: () => "Transactions:",
      },
      transferAmount: () => "Montant du transfert",
      txs: () => "Transactions",
    },
  },
  context: () => "Voir dans le contexte",
  copied: () => "Copié",
  copy: () => "Copie",
  copyAddress: () => "Copier l'adresse",
  copyTxLink: () => "Copier le lien",
  copyTxLinkSuccess: () => "Lien de transaction copié dans le presse-papiers",
  countries: {
    AD: () => "Andorre",
    AE: () => "Émirats arabes unis",
    AF: () => "Afghanistan",
    AG: () => "Antigua-et-Barbuda",
    AI: () => "Anguilla",
    AL: () => "Albanie",
    AM: () => "Arménie",
    AO: () => "Angola",
    AQ: () => "Antarctique",
    AR: () => "Argentine",
    AS: () => "Samoa américaines",
    AT: () => "Autriche",
    AU: () => "Australie",
    AW: () => "Aruba",
    AZ: () => "Azerbaïdjan",
    BA: () => "Bosnie-Herzégovine",
    BB: () => "Barbade",
    BD: () => "Bangladesh",
    BE: () => "Belgique",
    BF: () => "Burkina Faso",
    BG: () => "Bulgarie",
    BH: () => "Bahreïn",
    BI: () => "Burundi",
    BJ: () => "Bénin",
    BL: () => "Saint Barthélemy",
    BM: () => "Bermudes",
    BN: () => "Brunéi Darussalam",
    BO: () => "Bolivie",
    BQ: () => "Bonaire",
    BR: () => "Brésil",
    BS: () => "Bahamas",
    BT: () => "Bhoutan",
    BV: () => "Ile Bouvet",
    BW: () => "Botswana",
    BY: () => "Biélorussie",
    BZ: () => "Bélize",
    CA: () => "Canada",
    CC: () => "Îles Cocos",
    CD: () => "République démocratique du Congo",
    CF: () => "République centrafricaine",
    CG: () => "Congo",
    CH: () => "Suisse",
    CI: () => "Côte d'Ivoire",
    CK: () => "Îles Cook",
    CL: () => "Chili",
    CM: () => "Cameroun",
    CN: () => "Chine",
    CO: () => "Colombie",
    CR: () => "Costa Rica",
    CU: () => "Cuba",
    CV: () => "Cap-Vert",
    CW: () => "Curaçao",
    CX: () => "L'île Christmas",
    CY: () => "Chypre",
    CZ: () => "République tchèque",
    DE: () => "Allemagne",
    DJ: () => "Djibouti",
    DK: () => "Danemark",
    DM: () => "Dominique",
    DO: () => "République dominicaine",
    DZ: () => "Algérie",
    EC: () => "Équateur",
    EE: () => "Estonie",
    EG: () => "Egypte",
    EH: () => "Sahara occidental",
    ER: () => "Érythrée",
    ES: () => "Espagne",
    ET: () => "Ethiopie",
    FI: () => "Finlande",
    FJ: () => "Fidji",
    FK: () => "Îles Malouines",
    FM: () => "Micronésie",
    FO: () => "Îles Féroé",
    FR: () => "France",
    GA: () => "Gabon",
    GD: () => "Grenade",
    GE: () => "Géorgie",
    GF: () => "Guyane française",
    GG: () => "Guernesey",
    GH: () => "Ghana",
    GI: () => "Gibraltar",
    GL: () => "Groenland",
    GN: () => "Guinée",
    GP: () => "Guadeloupe",
    GQ: () => "Guinée équatoriale",
    GR: () => "Grèce",
    GS: () => "Géorgie du Sud et îles Sandwich du Sud",
    GT: () => "Guatemala",
    GU: () => "Guam",
    GW: () => "Guinée Bissau",
    GY: () => "Guyane",
    HK: () => "Hong Kong",
    HM: () => "Îles Heard et McDonald",
    HN: () => "Honduras",
    HR: () => "Croatie",
    HT: () => "Haïti",
    HU: () => "Hongrie",
    ID: () => "Indonésie",
    IE: () => "Irlande",
    IL: () => "Israël",
    IM: () => "Île de Man",
    IN: () => "Inde",
    IQ: () => "Irak",
    IR: () => "L'Iran",
    IS: () => "Islande",
    IT: () => "Italie",
    JE: () => "Jersey",
    JM: () => "Jamaïque",
    JO: () => "Jordanie",
    JP: () => "Japon",
    KE: () => "Kenya",
    KG: () => "Kirghizistan",
    KI: () => "Kiribati",
    KN: () => "Saint-Kitts-et-Nevis",
    KP: () => "Corée du Nord",
    KR: () => "Corée du Sud",
    KW: () => "Koweit",
    KY: () => "Îles Caïmans",
    KZ: () => "Kazakhstan",
    LA: () => "République démocratique populaire lao",
    LB: () => "Liban",
    LC: () => "Sainte-Lucie",
    LI: () => "Liechtenstein",
    LK: () => "Sri Lanka",
    LR: () => "Libéria",
    LS: () => "Lesotho",
    LT: () => "Lituanie",
    LU: () => "Luxembourg",
    LV: () => "Lettonie",
    LY: () => "Libye",
    MA: () => "Maroc",
    MC: () => "Monaco",
    MD: () => "Moldavie",
    ME: () => "Monténégro",
    MF: () => "Saint-Martin",
    MG: () => "Madagascar",
    MH: () => "Îles Marshall",
    MK: () => "Macédoine du Nord",
    ML: () => "Mali",
    MM: () => "Birmanie",
    MN: () => "Mongolie",
    MO: () => "Macao",
    MP: () => "Îles Mariannes du Nord",
    MQ: () => "Martinique",
    MR: () => "Mauritanie",
    MS: () => "Montserrat",
    MT: () => "Malte",
    MU: () => "Ile Maurice",
    MV: () => "Maldives",
    MW: () => "Malawi",
    MX: () => "Mexique",
    MY: () => "Malaisie",
    MZ: () => "Mozambique",
    NA: () => "Namibie",
    NC: () => "Nouvelle-Calédonie",
    NE: () => "Niger",
    NF: () => "Île Norfolk",
    NG: () => "Nigeria",
    NI: () => "Nicaragua",
    NL: () => "Pays-Bas",
    NO: () => "Norvège",
    NP: () => "Népal",
    NR: () => "Nauru",
    NU: () => "Niue",
    NZ: () => "Nouvelle-Zélande",
    OM: () => "Oman",
    PA: () => "Panama",
    PE: () => "Pérou",
    PG: () => "Papouasie-Nouvelle-Guinée",
    PH: () => "Philippines",
    PK: () => "Pakistan",
    PL: () => "Pologne",
    PM: () => "Saint-Pierre-et-Miquelon",
    PN: () => "Pitcairn",
    PR: () => "Porto Rico",
    PS: () => "Palestine",
    PT: () => "Portugal",
    PW: () => "Palaos",
    PY: () => "Paraguay",
    QA: () => "Qatar",
    RE: () => "Réunion",
    RO: () => "Roumanie",
    RS: () => "Serbie",
    RU: () => "Fédération de Russie",
    RW: () => "Rwanda",
    SA: () => "Arabie Saoudite",
    SB: () => "Îles Salomon",
    SC: () => "Seychelles",
    SD: () => "Soudan",
    SE: () => "Suède",
    SG: () => "Singapour",
    SH: () => "Sainte-Hélène",
    SI: () => "Slovénie",
    SJ: () => "Svalbard et Jan Mayen",
    SK: () => "Slovaquie",
    SL: () => "Sierra Leone",
    SM: () => "Saint-Marin",
    SN: () => "Sénégal",
    SO: () => "Somalie",
    SR: () => "Suriname",
    SS: () => "Soudan du Sud",
    ST: () => "Sao Tomé-et-Principe",
    SV: () => "Le Salvador",
    SX: () => "Saint-Martin",
    SY: () => "République arabe syrienne",
    TC: () => "Îles Turques-et-Caïques",
    TG: () => "Aller",
    TH: () => "Thaïlande",
    TJ: () => "Tadjikistan",
    TK: () => "Tokélaou",
    TL: () => "Timor-Leste",
    TM: () => "Turkménistan",
    TN: () => "Tunisie",
    TO: () => "Tonga",
    TR: () => "Turquie",
    TT: () => "Trinité-et-Tobago",
    TV: () => "Tuvalu",
    TW: () => "Taïwan",
    TZ: () => "Tanzanie",
    UA: () => "Ukraine",
    UG: () => "Ouganda",
    UK: () => "Royaume-Uni",
    US: () => "États-Unis",
    UY: () => "Uruguay",
    UZ: () => "Ouzbékistan",
    VA: () => "Cité du Vatican",
    VC: () => "Saint-Vincent-et-les Grenadines",
    VE: () => "Venezuela",
    VG: () => "Îles Vierges britanniques",
    VI: () => "Îles Vierges américaines",
    VN: () => "Vietnam",
    VU: () => "Vanuatu",
    WF: () => "Wallis et Futuna",
    WS: () => "Samoa",
    YE: () => "Yémen",
    YT: () => "Mayotte",
    ZA: () => "Afrique du Sud",
    ZM: () => "Zambie",
    ZW: () => "Zimbabwe",
  },
  countryAbbreviations: {
    AD: () => "ET",
    AE: () => "Émirats arabes unis",
    AF: () => "AFG",
    AG: () => "Conditions générales de vente",
    AI: () => "AGL",
    AL: () => "ALB",
    AM: () => "BRAS",
    AO: () => "FR",
    AQ: () => "FOURMI",
    AR: () => "ARG",
    AS: () => "AMS",
    AT: () => "AUTRICHE",
    AU: () => "Australie",
    AW: () => "ARU",
    AZ: () => "AZÉ",
    BA: () => "BOS",
    BB: () => "BAR",
    BD: () => "INTERDIRE",
    BE: () => "BEL",
    BF: () => "BUR",
    BG: () => "BUL",
    BH: () => "BHR",
    BI: () => "BDI",
    BJ: () => "BEN",
    BL: () => "STB",
    BM: () => "BER",
    BN: () => "BRU",
    BO: () => "BOL",
    BQ: () => "BON",
    BR: () => "SOUTIEN-GORGE",
    BS: () => "BAH",
    BT: () => "BHU",
    BV: () => "BVT",
    BW: () => "BOT",
    BY: () => "BLR",
    BZ: () => "BLZ",
    CA: () => "PEUT",
    CC: () => "CCK",
    CD: () => "MORUE",
    CF: () => "CAF",
    CG: () => "DENT",
    CH: () => "SUI",
    CI: () => "Lit bébé",
    CK: () => "CKI",
    CL: () => "CHI",
    CM: () => "CAME",
    CN: () => "CHN",
    CO: () => "COL",
    CR: () => "CRI",
    CU: () => "Lionceau",
    CV: () => "TAXI",
    CW: () => "COUR",
    CX: () => "CHR",
    CY: () => "CYP",
    CZ: () => "République tchèque",
    DE: () => "ALLEMAGNE",
    DJ: () => "DJI",
    DK: () => "TANIÈRE",
    DM: () => "DMA",
    DO: () => "DOM",
    DZ: () => "ALG",
    EC: () => "ECU",
    EE: () => "HNE",
    EG: () => "ÉGYPTE",
    EH: () => "ESH",
    ER: () => "ERI",
    ES: () => "ESP",
    ET: () => "ETH",
    FI: () => "AILETTE",
    FJ: () => "FIJ",
    FK: () => "FLK",
    FM: () => "FSM",
    FO: () => "FROI",
    FR: () => "FR",
    GA: () => "GAB",
    GD: () => "GRD",
    GE: () => "GÉO",
    GF: () => "RFA",
    GG: () => "GGY",
    GH: () => "GHA",
    GI: () => "GIB",
    GL: () => "GRL",
    GN: () => "GIN",
    GP: () => "BPL",
    GQ: () => "ÉQUI",
    GR: () => "GRE",
    GS: () => "SGS",
    GT: () => "GTM",
    GU: () => "GOMME",
    GW: () => "GNB",
    GY: () => "GARS",
    HK: () => "Hong Kong",
    HM: () => "Casque de réalité virtuelle",
    HN: () => "Honneur",
    HR: () => "CRO",
    HT: () => "HTI",
    HU: () => "HUN",
    ID: () => "Nom de domaine",
    IE: () => "COLÈRE",
    IL: () => "ISR",
    IM: () => "IMN",
    IN: () => "Inde",
    IQ: () => "IRQ",
    IR: () => "IRA",
    IS: () => "GLACE",
    IT: () => "ITA",
    JE: () => "JEY",
    JM: () => "CONFITURE",
    JO: () => "JOR",
    JP: () => "JAPONAIS",
    KE: () => "KEN",
    KG: () => "KGZ",
    KI: () => "KIR",
    KN: () => "STK",
    KP: () => "PRK",
    KR: () => "CORÉE",
    KW: () => "KUW",
    KY: () => "BANC DE SABLE",
    KZ: () => "KAZ",
    LA: () => "LAO",
    LB: () => "LeB",
    LC: () => "STL",
    LI: () => "MENSONGE",
    LK: () => "ISR",
    LR: () => "LIB",
    LS: () => "LES",
    LT: () => "LTU",
    LU: () => "LUX",
    LV: () => "LAT",
    LY: () => "LBY",
    MA: () => "MOC",
    MC: () => "LUN",
    MD: () => "MDA",
    ME: () => "EMN",
    MF: () => "STM",
    MG: () => "FOU",
    MH: () => "MHL",
    MK: () => "N.M.K.",
    ML: () => "MLI",
    MM: () => "ROR",
    MN: () => "MGL",
    MO: () => "MAC",
    MP: () => "INM",
    MQ: () => "MTQ",
    MR: () => "MRT",
    MS: () => "MSR",
    MT: () => "MLT",
    MU: () => "MUS",
    MV: () => "MDV",
    MW: () => "GUEULE",
    MX: () => "MEX",
    MY: () => "MAS",
    MZ: () => "MOZ",
    NA: () => "NAM",
    NC: () => "NCL",
    NE: () => "Ner",
    NF: () => "NFK",
    NG: () => "NIG",
    NI: () => "Carte réseau",
    NL: () => "NED",
    NO: () => "NI",
    NP: () => "NPL",
    NR: () => "NRU",
    NU: () => "Université nationale d'études universitaires",
    NZ: () => "Nouvelle-Zélande",
    OM: () => "OMA",
    PA: () => "POÊLE",
    PE: () => "PAR",
    PG: () => "PNG",
    PH: () => "PHI",
    PK: () => "PAK",
    PL: () => "POL",
    PM: () => "STP",
    PN: () => "PCN",
    PR: () => "PRI",
    PS: () => "PSE",
    PT: () => "PAR",
    PW: () => "PLW",
    PY: () => "PAR",
    QA: () => "QAT",
    RE: () => "REU",
    RO: () => "ROM",
    RS: () => "SER",
    RU: () => "russe",
    RW: () => "RWA",
    SA: () => "SAU",
    SB: () => "SOL",
    SC: () => "SEY",
    SD: () => "Réseau de développement de sites (SDN)",
    SE: () => "SUÈDE",
    SG: () => "PÉCHÉ",
    SH: () => "STH",
    SI: () => "SVN",
    SJ: () => "SJM",
    SK: () => "SVK",
    SL: () => "LED",
    SM: () => "SMR",
    SN: () => "SÉN",
    SO: () => "SOM",
    SR: () => "SUR",
    SS: () => "Disque SSD",
    ST: () => "SAO",
    SV: () => "ELS",
    SX: () => "SMX",
    SY: () => "SYR",
    TC: () => "TCA",
    TG: () => "TOG",
    TH: () => "THA",
    TJ: () => "TJK",
    TK: () => "TKL",
    TL: () => "TLS",
    TM: () => "TKM",
    TN: () => "TONNEAU",
    TO: () => "TONNE",
    TR: () => "TUR",
    TT: () => "TTO",
    TV: () => "TÜV",
    TW: () => "TWN",
    TZ: () => "TANNÉ",
    UA: () => "Royaume-Uni",
    UG: () => "UGA",
    UK: () => "ROYAUME-UNI",
    US: () => "NOUS",
    UY: () => "URU",
    UZ: () => "Ouzbékistan",
    VA: () => "T.V.A.",
    VC: () => "STV",
    VE: () => "VÉN",
    VG: () => "VGB",
    VI: () => "VIR",
    VN: () => "VIE",
    VU: () => "VUT",
    WF: () => "WLF",
    WS: () => "SAM",
    YE: () => "Oui",
    YT: () => "MYT",
    ZA: () => "RSA",
    ZM: () => "ZAM",
    ZW: () => "ZWE",
  },
  coupon: {
    label: () => "Entrez le code promotionnel",
    placeholder: () => "Code promotionnel",
  },
  couponIntegration: {
    CB140: () => "Coinbase",
    COINBASE30: () => "Coinbase",
    COINJARUK: () => "CoinJar",
    COINSTATS: () => "CoinStats",
    CTCFRIEND20: () => "Parrainer un ami",
    METAMASK10: () => "Metamask",
    MMCTC30: () => "MétaMasque",
    bitcoincomau30: () => "bitcoin.com.au",
    cb1rookie: () => "Coinbase",
    coinbase140: () => "Coinbase",
    coinbase140p: () => "Coinbase",
    coinbase1p: () => "Coinbase",
    ir30: () => "Independent Reserve",
    metamask30: () => "MétaMasque",
  },
  createTransactionBefore: () => "Créer une transaction avant",
  ctcAutomations: () => "✨ Magie CTC ✨",
  currency: {
    errorLoadingAllCurrencies: () =>
      "Erreur lors du chargement de toutes les devises",
    errorLoadingUserCurrencies: () =>
      "Erreur lors du chargement des devises des utilisateurs",
    unableToFindPrice: (d) =>
      "Impossible de trouver le prix du marché pour " + d.name,
    wentWrongFetchingPrice: (d) =>
      "Une erreur s'est produite lors de la récupération du prix de " + d.name,
  },
  currentPlan: () => "Plan actuel",
  darkModeBanner: {
    actionButton: () => "Essayez le mode sombre",
    cancelButton: () => "Non merci",
    description: () =>
      "Basculez entre le mode sombre et le mode clair depuis le menu de votre profil.",
    title: () => "Le mode sombre est arrivé !",
  },
  dashboard: {
    allAssets: () => "Tous les actifs",
    balance: () => "Équilibre",
    carousel: {
      moneyBack: {
        description: () => "Essayez sans risque",
        subtitle: () =>
          "Nous offrons une garantie de remboursement de 30 jours sur tous les forfaits.",
        title: () => "Garantie de remboursement",
      },
      partnerOffer: {
        description: () => "Remise de 30%",
        subtitle: () => "Automatiquement appliquée lors du paiement",
        title: () => "Offre spéciale partenaire",
      },
    },
    chartCalculationDialog: {
      body1: () =>
        "Pour obtenir un résultat précis, importez toutes vos transactions ",
      note: () =>
        "Remarque : Si le prix d'une devise ne peut pas être déterminé pendant la période où elle a été détenue, elle sera exclue.",
      title: () => "Explication des calculs graphiques",
    },
    costBasis: () => "Base de coûts",
    costBasisTooltip: () =>
      "Coût de base combiné (y compris les frais) des actifs qui ont une valeur marchande actuelle",
    currentBalance: () => "Solde actuel",
    embedded: {
      accuracyChecks: () => "Contrôles de précision",
      accuracyComplete: () => "Vos tâches de précision sont toutes terminées.",
      accuracyTasks: () => "Tâches de précision",
      accuracyTasksAction: () => "Commencer l'examen",
      accuracyTasksDescription: () =>
        "Il y a des tâches de précision à accomplir qui permettent de garantir que vos gains sont corrects et conformes (plan requis).",
      countdownBanner: {
        title: () =>
          "Temps restant pour soumettre vos déclarations fiscales à l'IRS",
        tooltip: () =>
          "La date limite de soumission fiscale est le mardi 15 avril 2025",
      },
      estimatedGains: () => "Estimation des gains en capital",
      estimatedGainsTooltip: () =>
        "Le gain en capital est une estimation basée sur les informations fournies.",
      fy: () => "Exercice financier",
      getTaxReports: () => "Obtenir des rapports fiscaux",
      missingAccounts: () => "Comptes manquants",
      missingAccountsAction: () => "Ajouter des comptes",
      missingAccountsDescription: () =>
        "Certains comptes ne sont pas liés, ce qui entraîne des transactions manquantes. Cela peut entraîner une surestimation de vos gains.",
      remindMeLater: {
        title: () =>
          "Recevez un rappel fiscal et bénéficiez de 5% de réduction supplémentaire",
        description: () =>
          "Vous préférez faire vos impôts plus tard ? Nous vous comprenons. Inscrivez-vous et nous ajouterons 5% de réduction supplémentaire lors de l'achat d'un forfait.",
        buttonText: () => "Me rappeler",
        modals: {
          reminder: {
            chipText: () => "Définir un rappel par e-mail",
            title: () =>
              "Obtenez 5% de réduction supplémentaire sur tous les forfaits !",
            description: () =>
              "Fournissez votre e-mail pour bénéficier d'une réduction totale de 35% sur votre rapport fiscal, simplement parce que vous êtes utilisateur de MetaMask.",
            emailLabel: () => "Saisissez votre adresse e-mail",
            emailError: () => "Veuillez saisir une adresse e-mail valide",
            buttonText: () => "Me rappeler",
            privacyNotice: () =>
              "En continuant, vous acceptez de recevoir occasionnellement des e-mails marketing.",
          },
          success: {
            chipText: () => "Rappel configuré !",
            title: () => "Réduction supplémentaire de 5% appliquée.",
            description: () =>
              "Vous bénéficiez de 35% de réduction sur votre rapport fiscal en tant qu'utilisateur de MetaMask.",
            buttonText: () => "Obtenez votre rapport fiscal maintenant",
          },
        },
      },
      savingsOpportunity: () => "Votre opportunité d'économies :",
      savingsOpportunityTooltip: () =>
        "L'opportunité d'épargne est le montant estimé dont vous pourriez réduire vos gains en capital si vous connectez tous vos comptes et examinez les tâches de conformité.",
    },
    error: () =>
      "Une erreur s'est produite lors de l'obtention de vos avoirs sur le tableau de bord. Veuillez réessayer ultérieurement.",
    feesPaid: () => "Frais payés",
    feesPaidTooltip: () =>
      "La valeur fiduciaire totale des frais que vous avez payés.",
    fiatInvested: () => "Fiat a investi",
    fiatProceeds: () => "Fiat procède",
    fiatProceedsTooltip: () =>
      "Le montant de monnaie fiduciaire que vous avez reçu suite à la vente de votre crypto",
    heading: () => "Tableau de bord",
    holdings: {
      balanceSyncTimeExplainer: () =>
        "Les soldes horaires ont été synchronisés pour la dernière fois pour cette importation",
      balances: {
        subheading: () =>
          "Basculez entre les soldes d'affichage calculés par Crypto Tax Calculator et le solde réel de la bourse. Toute différence peut être due à des transactions non rapprochées ou manquantes.",
        title: () => "Soldes restants",
      },
      balancesSubheading: () =>
        "Il s'agit d'un texte explicatif expliquant comment les soldes sont calculés et comment l'utilisateur peut basculer entre le solde de l'application et celui de l'échange.",
      balancesTitle: () => "Soldes restants",
      breakdownListNegative: () =>
        "Les avoirs actuels sont négatifs. Cela peut être dû à des données non catégorisées ou manquantes.",
      breakdownListTitle: () => "Avoirs en crypto-monnaie",
      breakdownListTooltip: {
        marketPrice: (d) =>
          "Prix du marché de " + d.symbol + " en " + d.currency,
        positiveBalance: (d) =>
          "Le solde correspond aux avoirs sur " + d.exchangeName,
        review: {
          access: () =>
            "pour accéder à notre outil d'évaluation et en savoir plus.",
          subscribe: () => "S'abonner",
          tips: () => "Revoir",
          toLearnMore: () => "vos transactions pour en savoir plus.",
        },
        warningBalanceLessThan: (d) =>
          "Nous avons calculé que " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " ( " +
          d.fiatDifference +
          " ) était inférieur à ce qui a été rapporté par votre plateforme d'échange. Cela peut être dû à des données non catégorisées ou manquantes.",
        warningBalanceMoreThan: (d) =>
          "Nous avons calculé " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " ( " +
          d.fiatDifference +
          " ) de plus que ce qui a été rapporté par votre plateforme d'échange. Cela peut être dû à des données non catégorisées ou manquantes.",
        warningBalanceNoMarketPriceLessThan: (d) =>
          "Nous avons calculé " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " était inférieur à ce qui a été rapporté par votre plateforme d'échange. Cela peut être dû à des données non catégorisées ou manquantes.",
        warningBalanceNoMarketPriceMoreThan: (d) =>
          "Nous avons calculé " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " de plus que ce qui a été rapporté par votre plateforme d'échange. Cela peut être dû à des données non catégorisées ou manquantes.",
      },
      cost: () => "Coût",
      costTooltip: () => "Coût total de base",
      costTooltipTitle: () => "Base de coût totale",
      currency: () => "Devise",
      empty: {
        title: () => "Aucune donnée ajoutée",
      },
      marketPrice: () => "Prix",
      marketPriceTooltip: () => "Prix actuel du marché",
      marketValue: () => "Valeur",
      marketValueTooltip: () => "Valeur marchande actuelle",
      moreThan: () => "Plus que",
      of: () => "de",
      quantity: () => "Quantité",
      roi: () => "Retour sur investissement",
      roiTooltip: () => "Retour sur investissement",
      rowsPerPage: () => "Lignes par page",
      showAppBalances: () => "Afficher les soldes dans l'application",
      syncStatus: {
        fail: () => "La synchronisation a échoué",
        noSync: () => "Pas de dernière synchronisation",
        success: () => "Synchronisé",
      },
      title: () => "Actifs",
      tooltip: {
        off: () => "Affichage des soldes importés depuis l'API source",
        on: () => "Affichage des soldes calculés à partir de l'application",
        sort: () => "Cliquez pour trier par la valeur marchande totale",
      },
      unrealizedGain: () => "Gain non réalisé",
      unrealizedGainTooltip: () => "Gain non réalisé",
    },
    holdingsTypes: {
      calculatedBody: () =>
        "Affiche vos soldes tels que calculés par CTC, en fonction de vos portefeuilles et échanges importés. La précision repose sur un rapprochement correct des transactions.",
      calculatedSwitch: () => "Calculé",
      calculatedTitle: () => "Bilan historique",
      done: () => "Fait",
      reportedBody: () =>
        "Affiche une vue en direct de vos soldes signalés par vos échanges et portefeuilles. Les soldes qui ne se trouvent pas dans vos portefeuilles ne seront pas affichés. Veuillez noter que les importations CSV, ainsi que certains échanges et portefeuilles, ne prennent pas en charge les rapports en direct des soldes. Dans ces cas, les avoirs calculés par CTC seront utilisés.",
      reportedSwitch: () => "Signalé",
      reportedTitle: () => "Solde en direct",
      title: () => "Type d'équilibre",
    },
    howThisChartIsCalculated: () => "Comment ce graphique est calculé",
    inflow: () => "Afflux",
    inflowOutflowChart: {
      header: () => "Entrée/Sortie",
      legend: {
        fees: () => "Frais",
        inflow: () => "Afflux",
        net: () => "Flux net",
        outflow: () => "Sortie",
      },
      linear: () => "Passer au linéaire",
      log: () => "Passer au logarithme",
      timeframeSelector: {
        month: () => "Mois",
        placeholder: () => "Sélectionnez la période",
        quarterToDate: () => "Trimestre à ce jour",
        year: () => "Année",
        ytd: () => "Année à ce jour",
      },
      tooltip: {
        fees: () => "Frais:",
        inflow: () => "Afflux:",
        net: () => "Flux net :",
        outflow: () => "Sortie:",
      },
    },
    inflowTooltip: () =>
      "La valeur des actifs reçus sur vos comptes connectés. Les gains/pertes réalisés sur les transactions sont exclus",
    losers: () => "Les perdants",
    missingImportsWarning: {
      actionButton: () => "Gérer les paramètres",
      description: () =>
        "Sans toutes les sources de données, nous ne pouvons pas refléter avec précision votre solde global. Vous pouvez gérer les données à afficher dans les paramètres, sous « Paramètres du solde ».",
      importsTitle: () => "Actifs importés",
      nextStepButton: () => "Importer des données",
      overallSubtitle: () =>
        "Il s’agit d’actifs trouvés sur des portefeuilles ou des échanges que vous n’avez pas encore importés.",
      overallTitle: () => "Actifs détectés",
      title: () => "Gérer les données importées",
    },
    negativeBalanceWarning: (d) =>
      "Le solde de vos avoirs " +
      d.currencySymbol +
      " est négatif, veuillez vérifier vos transactions pour corriger cette erreur",
    netFiatInvested: () => "Fiat net investi",
    netFiatInvestedTooltip: () =>
      "Le montant de monnaie fiduciaire que vous avez utilisé pour acheter des crypto-monnaies",
    nfts: {
      filter: {
        allChains: () => "Toutes les chaînes",
        chainPlaceholder: () => "Rechercher par chaîne",
      },
      imageNotFound: () => "Image non trouvée",
      noNFTs: () => "Aucun NFT trouvé",
      noNFTsCTA: (d) =>
        "Vos NFT apparaîtront ici, pour commencer [importez des données.](" +
        d.linkTextKey +
        ") ",
    },
    noAssetAllocation: {
      body: () => "Vos principaux atouts apparaîtront ici. Pour commencer",
      importData: () => "importer des données",
      title: () => "Aucune donnée ajoutée",
    },
    noHoldings: {
      body: () => "Tous vos avoirs importés apparaîtront ici. Pour commencer",
      importData: () => "importer des données",
      title: () => "Aucune donnée ajoutée",
    },
    noWinnersLosers: {
      body: () =>
        "Vos gains et pertes les plus importants apparaîtront ici. Pour commencer",
      importData: () => "importer des données",
      title: () => "Aucune donnée ajoutée",
    },
    noWinnersLosersToDisplay: (d) => "Aucune " + d.direction + " à afficher...",
    notApplicable: () => "N / A",
    other: () => "Autre",
    outflow: () => "Sortie",
    outflowTooltip: () =>
      "La valeur des actifs envoyés par vos comptes connectés. Les gains/pertes réalisés sur les transactions sont exclus",
    pageHeading: () => "Portefeuille",
    percentageTooltipTitle: () =>
      "Variation relative du solde global par rapport au prix de revient",
    portfolioChart: {
      error: () =>
        "Erreur lors du chargement des performances du portefeuille, veuillez réessayer plus tard",
      loading: () =>
        "Génération de données historiques, cela peut prendre un certain temps...",
      noData: {
        body: () =>
          "Les performances de votre portefeuille apparaîtront ici. Pour commencer",
        importData: () => "importer des données",
        title: () => "Aucune donnée ajoutée",
      },
      totalValue: () => "Valeur totale",
    },
    portfolioPerformance: () => "Performance du portefeuille",
    portfolioPerformanceTooltip: () =>
      "Performances de votre portefeuille au cours de l'année écoulée. Les avoirs négatifs ou les soldes nuls sont ignorés.",
    portfolioTimeframe: {
      all: () => "Tous",
      day: () => "1D",
      month: () => "1M",
      week: () => "1W",
      year: () => "1 an",
      ytd: () => "cumul à ce jour",
    },
    portfolioTooltip: {
      accuracyNote: () =>
        "Les performances sont basées sur vos soldes calculés dans Crypto Tax Calculator. La précision repose sur le rapprochement correct des transactions.",
      all: () =>
        "La performance de votre portefeuille dès votre premier achat.",
      day: () =>
        "La performance de votre portefeuille au cours de la dernière journée.",
      ignoreNegativeHoldings: () =>
        "Tous les avoirs négatifs ou soldes de valeur nulle sont ignorés.",
      month: () =>
        "La performance de votre portefeuille au cours du mois dernier.",
      week: () =>
        "La performance de votre portefeuille au cours de la semaine dernière.",
      year: () =>
        "La performance de votre portefeuille au cours de l’année écoulée.",
    },
    somethingWentWrong: () =>
      "Une erreur s'est produite. Veuillez essayer de recharger la page.",
    step1: {
      content: () =>
        "Importez tout votre historique de transactions en sélectionnant votre plateforme d'échange et en suivant les instructions associées. Vous pouvez importer via CSV, API ou connecter directement votre portefeuille.",
      detailedGuide: () => "Guide détaillé",
      link: () => "/imports",
      title: () => "1. Importer des données",
    },
    step2: {
      content: () =>
        "Assurez-vous d'avoir importé vos transactions de tous vos échanges et de les avoir correctement catégorisées sur la page des transactions.",
      link: () => "/transactions",
      title: () => "2. Examiner les transactions",
    },
    step3: {
      content: () =>
        "Accédez à la page des rapports pour consulter et télécharger vos fichiers de déclaration fiscale pour l'année fiscale en cours et toutes les années fiscales précédentes. Vous pouvez ensuite partager ces rapports avec votre comptable.",
      link: () => "/report",
      title: () => "3. Obtenir le rapport",
    },
    steps: {
      getStarted: () => "Commencer",
      gettingStarted: () =>
        "Suivez simplement les étapes ci-dessous pour commencer :",
    },
    tabs: {
      nfts: () => "NFT",
      overview: () => "Aperçu",
    },
    title: () => "Vous êtes sur le point de régler vos impôts !",
    topAssets: () => "Attribution de jetons",
    totalReturn: () => "Rendement total",
    totalReturnTooltip: () =>
      "Votre rendement total correspond à votre performance globale en investissant dans les crypto-monnaies. Il est calculé comme la somme de la valeur de votre portefeuille et du produit net en monnaie fiduciaire après déduction du montant net investi en monnaie fiduciaire.",
    treasury: () => "Trésorerie",
    treasuryTimeframe: {
      lastMonth: () => "Mois dernier",
      lastYear: () => "L'année dernière",
      monthToDate: () => "Le mois courant",
      yearToDate: () => "Année à ce jour",
    },
    unrealizedPosition: () => "Gains non réalisés",
    unrealizedPositionTooltip: () =>
      "La différence entre la valeur nette actuelle de votre portefeuille et le coût d’acquisition des actifs qui composent votre portefeuille.",
    valueOfHoldings: () => "Valeur des avoirs",
    valueOfHoldingsTooltip: () =>
      "La différence entre votre prix de base et la valeur actuelle de tous vos actifs",
    valueOfHoldingsTooltipTitle: () => "Gain non réalisé :",
    winners: () => "Gagnants",
    winnersAndLosers: {
      help: () => "Améliorez la précision en catégorisant les transactions",
      importData: () => "importer des données",
      noData: () =>
        "Aucun gagnant ni perdant n'est identifié dans vos calculs, continuez à importer vos transactions pour améliorer la précision.",
      noDataBody: () =>
        "Vos actifs les plus performants et les moins performants apparaîtront ici. Pour commencer",
      noDataTitle: () => "Aucune donnée ajoutée",
      title: () => "Gagnants et perdants",
    },
  },
  dataSource: {
    radioButtonLabels: {
      accountant: () => "Données comptables",
      client: (d) =>
        select(d.accountant, { true: " Client ", other: " Mon " }) +
        " données importées",
    },
    subtitle: () =>
      "Sélectionnez une source de données partagée entre vous et votre comptable",
    title: () => "Données de travail",
  },
  delete: () => "Supprimer",
  deny: () => "Refuser",
  developer: {
    activeFlags: () => "Drapeaux actifs",
    addFlag: () => "Ajouter un drapeau",
    clearFlagOverrides: () => "Annuler les remplacements",
    enableLocalCensoring: () => "Activer la censure locale",
    noActiveFlags: () => "Aucun drapeau actif",
    tabs: {
      currencies: () => "Devises",
      editorIntegration: () => "Accéder au code",
      entities: () => "Entités",
      featureFlags: () => "Drapeaux de fonctionnalité",
      reconciliation: () => "Réconciliation",
      reportRefresh: () => "Actualiser le rapport",
      rules: () => "Règles d'intégration des logiciels de comptabilité",
      seedData: () => "Données sur les semences",
      sessionRecording: () => "Enregistrements de sessions",
      userData: () => "Données de l'utilisateur",
    },
    title: () => "Promoteur",
  },
  done: () => "Fait",
  duplicate: () => "Double",
  edit: () => "Modifier",
  editTx: {
    accounts: () => "Comptes",
    addFee: () => "Ajouter des frais",
    advanced: () => "Avancé",
    baseCurrency: () => "Devise de base",
    blockchain: () => "Blockchain",
    blockchainFrom: () => "Source Blockchain",
    blockchainPlaceholder: () => "par exemple ETH, Avalanche, etc.",
    blockchainTo: () => "Destination Blockchain",
    buyCurrency: () => "Acheter des devises",
    buyFee: () => "Frais d'achat",
    buyFeeFor: () => "Frais d'achat pour transaction en",
    buyPrice: () => "Prix d'achat",
    buyQuantity: () => "Acheter Quantité",
    cantEditLocked: () =>
      "Vous ne pouvez pas modifier une transaction verrouillée",
    cantEditLockedButton: () =>
      "Afficher les paramètres de transaction de verrouillage",
    cantMixBlockchain: () =>
      "Impossible de mélanger vers et depuis les blockchains",
    currency: () => "Devise",
    currencyPaidIn: () =>
      "Devise dans laquelle vous avez payé les frais (le cas échéant)",
    destinationOfTransaction: () => "Destination de la transaction",
    editFee: () => "Modifier les frais",
    editPriceCurrencyValue: () => "Modifier le prix, la devise et la valeur",
    feeForIncoming: () => "Frais pour transaction entrante en",
    feeForOutgoing: () => "Frais pour transaction sortante en",
    feeQuantityPaidIn: () => "Montant des frais payés dans la devise des frais",
    feeValue: () => "Valeur des frais",
    in: () => "dans",
    inFee: () => "En frais",
    inPrice: () => "Dans le prix",
    inQuantity: () => "En quantité",
    inTime: () => "Dans le temps",
    linkValueTooltipTradeLike: () =>
      "Assurez-vous que les valeurs totales des deux côtés restent égales en ajustant les valeurs des transactions.",
    linkValueTooltipTransferLike: () =>
      "Assurez-vous que la quantité totale des deux côtés reste égale en ajustant les quantités de transaction.",
    lookupPrice: () => "Rechercher le prix du marché",
    mustBeLess: () => "Doit contenir moins de 256 caractères",
    mustBuy: () => "Vous devez entrer un prix d'achat",
    mustCategory: () => "Vous devez entrer une catégorie",
    mustCurrency: () => "Vous devez entrer une devise",
    mustDestination: () => "Vous devez entrer une destination",
    mustFee: () => "Vous devez saisir une valeur de frais",
    mustHaveBlockchain: () =>
      "Vous devez attribuer une blockchain à cette transaction",
    mustOutTime: () => "Vous devez saisir une heure de sortie valide",
    mustPrice: () => "Vous devez entrer un prix",
    mustQuantity: () => "Vous devez entrer une quantité",
    mustSell: () => "Vous devez saisir un prix de vente",
    mustSource: () => "Vous devez entrer une source",
    mustTime: () => "Vous devez entrer une heure valide",
    mustValue: () => "Vous devez entrer une valeur",
    noPriceFound: (d) =>
      "Aucun prix trouvé pour " +
      d.currency +
      " . Saisir manuellement le prix ou ",
    notes: () => "Remarques",
    optionalNotes: () => "Notes facultatives pour cette transaction",
    outFee: () => "Frais de sortie",
    outPrice: () => "Prix de sortie",
    outQuantity: () => "Quantité en sortie",
    outTime: () => "Heure de sortie",
    paidInFees: () => "payé en frais",
    price: () => "Prix",
    priceForOne: () => "Prix pour 1",
    quantity: () => "Quantité",
    removeFee: () => "Supprimer les frais",
    sellCurrency: () => "Vendre des devises",
    sellFee: () => "Frais de vente",
    sellFeeFor: () => "Frais de vente pour transaction en",
    sellPrice: () => "Prix de vente",
    sellQuantity: () => "Vendre la quantité",
    somethingWentWrong: () =>
      "Une erreur s'est produite lors de la mise à jour de la transaction.",
    sourceOfTransaction: () => "Source de la transaction",
    swapIconIncoming: () => "Passer à la transaction entrante",
    swapIconOutgoing: () => "Passer à la transaction sortante",
    totalFeeValueIn: () => "Valeur totale des frais en",
    totalQuantityOf: () => "Quantité totale de",
    totalValue: (d) => "Valeur totale : " + d.amount,
    treatAsNFT: (d) => "Traiter " + d.symbol + " comme un NFT ?",
    treatAsNFTTooltip: () =>
      "La modification de cette valeur mettra à jour toutes les transactions avec cette devise.",
    ungroupCriteria: {
      differentAssetType: () =>
        "Les devises ne répondent pas aux exigences en matière de type d'actifs",
      differentCurrency: () => "Les devises sont les mêmes",
      differentSource: () =>
        "Les transactions ne proviennent pas de sources distinctes (pas de transferts internes)",
      dontGroup: () => 'L\'indicateur "Ne pas regrouper" est défini',
      fiatCurrency: () =>
        "Les transactions doivent être des transactions en monnaie fiduciaire",
      sameAccount: () => "Les transactions n'ont pas de comptes correspondants",
      sameCurrency: () => "Les devises sont différentes",
      sameFromTo: () =>
        "Les transactions n'ont pas de valeurs de et vers correspondantes",
      sameTimestamp: () => "Les horodatages sont différents",
      sameTxId: () => "Les identifiants de transaction sont différents",
      sameValueFeeExclusive: () =>
        "Les valeurs de chaque côté ne sont pas assez similaires",
      sameValueFeeInclusive: () =>
        "Les valeurs de chaque côté ne sont pas assez similaires",
      similarQuantity: () =>
        "Les quantités de chaque côté ne sont pas assez similaires",
      similarTimestamp: () => " Les horodatages sont trop différents",
    },
    ungroupWarning: (d) =>
      "Les modifications que vous avez apportées peuvent entraîner le dégroupement de ces transactions pour les " +
      plural(d.count, 0, fr, { 1: " reason ", other: " reasons " }) +
      " suivantes :",
    unlock: () => "Changement",
    unlockBlockchainBody: () =>
      "La sélection d'une nouvelle blockchain peut signifier que vos transactions précédentes sur cette chaîne ne sont plus réconciliées avec précision",
    unlockBlockchainTitle: () =>
      "Etes-vous sûr de vouloir changer la blockchain ?",
    validTime: () => "Vous devez entrer une heure valide",
    validatePositiveFeeValue: () => "Les frais doivent être supérieurs à zéro",
    validatePositiveFiatValue: () =>
      "La valeur fiat doit être supérieure à zéro",
    validatePositivePriceValue: () => "Le prix doit être supérieur à zéro",
    validatePositiveQuantityValue: () =>
      "La quantité doit être supérieure à zéro",
    validatePositiveValue: () => "La valeur doit être supérieure à zéro",
    value: () => "Valeur",
    valueLockLabel: () => "Lier la valeur de chaque côté de la transaction",
    willSetBlockchain: () => "La blockchain sur laquelle se trouve l'adresse.",
  },
  enforceUpgrade: {
    tooltip: () =>
      "Votre nombre de transactions a dépassé la limite de ce forfait",
  },
  enterprise: {
    getReport: () => "Obtenir un rapport",
    incomingInvoicesDownloadModal: {
      billingPeriod: () => "Période de facturation",
      customDateRange: () => "Plage de dates personnalisée",
      downloadCsv: () => "Télécharger CSV",
      downloadPdf: () => "Télécharger le PDF",
      endDate: () => "Date de fin",
      startDate: () => "Date de début",
      startDateBeforeEndDateError: () =>
        "La date de début doit être antérieure à la date de fin",
      title: () => "Télécharger le rapport",
    },
    office: {
      addNew: {
        button: () => "Ajouter un nouveau bureau",
        dialog: {
          accept: () => "Ajouter un bureau",
          title: () => "Ajouter un nouveau bureau ?",
        },
      },
      addNewButton: () => "Ajouter un nouveau bureau",
      addNewOffice: () => "Ajouter un nouveau bureau",
      all: () => "Tous",
      allOffices: () => "Tous les bureaux",
      offices: () => "Bureaux",
    },
    valueCards: {
      numberOfOffices: () => "Nombre de bureaux",
      totalAccountants: () => "Total des comptables",
      totalClients: () => "Nombre total de clients",
      upcomingInvoices: () => "Facture à venir",
    },
  },
  error: () => "Erreur",
  errorBoundaries: {
    debugInfo: () => "Informations de débogage",
    error: () => "Erreur",
    oopsSomethingWentWrong: () => "Oups ! Quelque chose s'est mal passé :(",
    weCantProcess: () =>
      "Nous ne pouvons pas traiter votre demande. Veuillez essayer de rafraîchir la page. Si le problème persiste, contactez notre équipe d'assistance.",
  },
  errorMessageGeneric: () => "Quelque chose s'est mal passé",
  errorReporting: {
    actionButtonText: {
      contactSupport: () => "Contacter le support",
      retrySync: () => "Réessayer la synchronisation",
      updateAddress: () => "Mettre à jour l'adresse",
      updateApiKey: () => "Mettre à jour la clé API",
      viewDetails: () => "Voir les détails",
    },
    csvErrorDetails: {
      description: () =>
        "Les lignes suivantes dans le CSV ont été ignorées en raison de problèmes dans le CSV.",
      title: (d) => d.exchangeName + " a ignoré des transactions",
    },
  },
  etaTime: {
    momentHumanizeEquivalent: {
      "a few seconds": () => "quelques secondes",
      day: () => "jour",
      days: () => "jours",
      hour: () => "heure",
      hours: () => "heures",
      minute: () => "minute",
      minutes: () => "minutes",
      month: () => "mois",
      months: () => "mois",
      second: () => "deuxième",
      seconds: () => "secondes",
      week: () => "semaine",
      weeks: () => "semaines",
      year: () => "année",
    },
    tooltip: (d) =>
      "Les synchronisations prennent généralement " +
      d.avgHumanizeTime +
      " mais peuvent prendre jusqu'à " +
      d.p95HumanizeTime +
      " pour se terminer",
    tooltipSameTime: (d) =>
      "Les synchronisations prennent généralement " +
      d.avgHumanizeTime +
      " pour se terminer",
  },
  existing: {
    cancel: () => "Annuler le plan",
    goBack: () => "Retourner",
    pricingUrl: () => "https://cryptotaxcalculator.io/pricing/personal/",
    title: () => "Plan actuel",
    upgrade: () => "Mise à niveau",
    upgradePlan: () => "Plan de mise à niveau",
    viewPricingInfo: () => "Voir les informations sur les tarifs",
  },
  existingImports: {
    title: () => "Importations existantes",
  },
  faqAccountant: {
    description: () => "Questions fréquemment posées",
    questions: {
      0: {
        answer: () =>
          "Vous pouvez être redevable à la fois de l'impôt sur les plus-values et de l'impôt sur le revenu en fonction du type de transaction de cryptomonnaie et de votre situation personnelle. Par exemple, vous devrez peut-être payer des plus-values sur les bénéfices réalisés lors de l'achat et de la vente de cryptomonnaies, ou payer l'impôt sur le revenu sur les intérêts perçus lors de la détention de cryptomonnaies.",
        question: () => "Comment est calculé l'impôt sur les crypto-monnaies ?",
      },
      1: {
        answer: () =>
          "La manière dont les cryptomonnaies sont taxées dans la plupart des pays signifie que les investisseurs peuvent toujours être tenus de payer des impôts, qu'ils aient réalisé un bénéfice ou une perte globale. Selon votre situation, les impôts sont généralement perçus au moment de la transaction, et non sur la situation globale à la fin de l'exercice.",
        question: () =>
          "J'ai perdu de l'argent en échangeant des cryptomonnaies. Dois-je quand même payer des impôts ?",
      },
      2: {
        answer: () =>
          "Dans la plupart des pays, vous devez enregistrer la valeur de la cryptomonnaie dans votre devise locale au moment de la transaction. Cette opération peut prendre beaucoup de temps à effectuer à la main, car la plupart des registres d'échange n'ont pas de prix de référence et les registres entre les échanges ne sont pas facilement compatibles.",
        question: () =>
          "Comment calculer la taxe sur les transactions de crypto à crypto ?",
      },
      3: {
        answer: () =>
          "Il vous suffit d'importer l'historique de vos transactions et nous vous aiderons à classer vos transactions et à calculer les bénéfices et revenus réalisés. Vous pouvez ensuite générer les rapports appropriés à envoyer à votre comptable et conserver des enregistrements détaillés à portée de main à des fins d'audit.",
        question: () =>
          "Comment Crypto Tax Calculator peut-il vous aider avec les taxes sur les crypto-monnaies ?",
      },
      4: {
        answer: () =>
          "Nous vous recommandons toujours de travailler avec votre comptable pour examiner vos dossiers. Cependant, la préparation manuelle de vos livres peut prendre beaucoup de temps, et la plupart des comptables utiliseront simplement un logiciel tel que Crypto Tax Calculator pour le faire, facturant une prime élevée.",
        question: () =>
          "Ne puis-je pas simplement demander à mon comptable de le faire pour moi ?",
      },
      5: {
        answer: () =>
          "Nous gérons toutes les activités non liées à l'échange, telles que les transactions en chaîne telles que les Airdrops, le Staking, le Mining, les ICO et d'autres activités DeFi. Quelle que soit l'activité que vous avez effectuée dans le domaine des crypto-monnaies, nous vous proposons notre fonctionnalité de catégorisation facile à utiliser, similaire à Expensify.",
        question: () => "Gérez-vous des activités non boursières ?",
      },
    },
    title: () => "FAQ Comptable",
  },
  faqPricing: {
    description: () => "Questions fréquemment posées",
    questions: {
      0: {
        answer: () =>
          "Vous pouvez être redevable à la fois de l'impôt sur les plus-values et de l'impôt sur le revenu en fonction du type de transaction de cryptomonnaie et de votre situation personnelle. Par exemple, vous devrez peut-être payer des plus-values sur les bénéfices réalisés lors de l'achat et de la vente de cryptomonnaies, ou payer l'impôt sur le revenu sur les intérêts perçus lors de la détention de cryptomonnaies.",
        question: () => "Comment est calculé l'impôt sur les crypto-monnaies ?",
      },
      1: {
        answer: () =>
          "La manière dont les cryptomonnaies sont taxées dans la plupart des pays signifie que les investisseurs peuvent toujours être tenus de payer des impôts, qu'ils aient réalisé un bénéfice ou une perte globale. Selon votre situation, les impôts sont généralement perçus au moment de la transaction, et non sur la situation globale à la fin de l'exercice.",
        question: () =>
          "J'ai perdu de l'argent en échangeant des cryptomonnaies. Dois-je quand même payer des impôts ?",
      },
      2: {
        answer: () =>
          "Dans la plupart des pays, vous devez enregistrer la valeur de la cryptomonnaie dans votre devise locale au moment de la transaction. Cette opération peut prendre beaucoup de temps à effectuer à la main, car la plupart des registres d'échange n'ont pas de prix de référence et les registres entre les échanges ne sont pas facilement compatibles.",
        question: () =>
          "Comment calculer la taxe sur les transactions de crypto à crypto ?",
      },
      3: {
        answer: () =>
          "Il vous suffit d'importer l'historique de vos transactions et nous vous aiderons à classer vos transactions et à calculer les bénéfices et revenus réalisés. Vous pourrez ensuite générer les rapports appropriés à envoyer à votre comptable et conserver des enregistrements détaillés à portée de main à des fins d'audit.",
        question: () =>
          "Comment Crypto Tax Calculator peut-il vous aider avec les taxes sur les crypto-monnaies ?",
      },
      4: {
        answer: () =>
          "Nous vous recommandons toujours de travailler avec votre comptable pour examiner vos dossiers. Cependant, la préparation manuelle de vos livres peut prendre beaucoup de temps, et la plupart des comptables utiliseront simplement un logiciel tel que Crypto Tax Calculator pour le faire, facturant une prime élevée.",
        question: () =>
          "Ne puis-je pas simplement demander à mon comptable de le faire pour moi ?",
      },
      5: {
        answer: () =>
          "Nous gérons toutes les activités non liées à l'échange, telles que les transactions en chaîne telles que les Airdrops, le Staking, le Mining, les ICO et d'autres activités DeFi. Quelle que soit l'activité que vous avez effectuée dans le domaine des crypto-monnaies, nous vous proposons notre fonctionnalité de catégorisation facile à utiliser, similaire à Expensify.",
        question: () => "Gérez-vous des activités non boursières ?",
      },
    },
    title: () => "FAQ",
  },
  faqs: {
    capitalGainsWrong: {
      ans: {
        0: () =>
          "Il y a deux raisons principales à cela. Soit vous avez classé certaines transactions entre portefeuilles comme achat/vente alors que vous ne faisiez que déplacer des cryptomonnaies (dépôt/retrait). Sinon, vous avez peut-être oublié d'ajouter certaines transactions ou identifiants de portefeuille, ce qui pourrait compenser une partie de vos gains en capital.",
      },
      q: () => "Que faire si mes gains en capital semblent incorrects ?",
    },
    ensureAccuracy: {
      ans: {
        0: () =>
          "Pour garantir que votre déclaration fiscale soit aussi précise que possible, deux étapes sont nécessaires :",
        1: () => "Importez toutes vos données",
        2: () =>
          "Cela comprend les transactions fiat vers crypto-monnaie, crypto-monnaie vers crypto-monnaie, crypto-monnaie vers fiat, les transactions DeFi, le jalonnement, les airdrops, les transactions de cette année, mais aussi les transactions de votre premier achat de crypto. Tout doit être inclus pour fournir un rapport précis.",
        3: () => "Vérifier/classer chaque transaction",
        4: () =>
          "Il peut être difficile de se rappeler de quelle transaction il s'agit, mais c'est également impératif. Si vous étiquetez une transaction comme un dépôt/retrait, cela n'aura pas d'impact sur vos impôts, contrairement à un achat/vente. Si vous classez une transaction comme un parachutage, un jalonnement, un intérêt, etc., elle sera classée comme un revenu et non comme un gain en capital. Le fait est que la manière dont vous étiquetez chaque transaction est importante, vous devez donc être précis dans la manière dont vous les étiquetez.",
        5: () => "Corrigez vos avertissements de solde restant",
        6: () =>
          "Si vous disposez de cryptomonnaies, mais que vous n'avez pas assez de solde, cela fera apparaître un message d'avertissement de solde restant dans l'application. Vous devez vérifier vos notifications pour vous assurer que vous n'avez pas d'avertissement de solde restant. Si vous avez ces avertissements, vous pouvez les résoudre en suivant les étapes du guide ici : https://cryptotaxcalculator.io/guides/negative-balance-remaining-warnings ",
      },
      q: () => "Comment puis-je m’assurer que ce rapport est exact ?",
    },
    howToUseCTC: {
      ans: {
        0: () =>
          "La première chose à faire est d'accéder à la page d'importation et de télécharger TOUTES vos données de transaction à partir des comptes d'échange et des transactions de portefeuille. Chaque compte d'échange ou portefeuille aura des instructions d'importation qui impliquent le téléchargement de données CSV, la configuration d'une API ou la saisie des détails de votre portefeuille.",
        1: () =>
          "Une fois que tout a été téléchargé, vérifiez l'historique de vos transactions. Cela impliquera de classer chaque transaction par type. Soyez attentif aux soldes qui ne correspondent pas, par exemple, CTC mettra en évidence si votre compte entre dans un historique d'achats manquants, ce qui signifie que vous avez manqué certaines transactions.",
        2: () =>
          "Enfin, rendez-vous sur la page du rapport fiscal et effectuez une vérification rapide pour vous assurer que les chiffres et la répartition du portefeuille semblent raisonnables. Vous pouvez ensuite télécharger votre rapport fiscal en toute sécurité.",
        3: () =>
          "Pour des informations plus détaillées, veuillez lire notre guide ici : https://cryptotaxcalculator.io/guides/how-to-use-crypto-tax-calculator",
      },
      q: () =>
        "Comment utiliser le calculateur d'impôts sur les crypto-monnaies ?",
    },
    missingIncome: {
      ans: {
        0: () =>
          "Oui, les gains en capital à long terme et les gains en capital à court terme sont déclarés séparément.",
        1: () =>
          "Les gains sur les actifs détenus pendant un an ou moins sont classés comme gains à court terme, tandis que les gains sur les actifs détenus pendant plus d'un an sont classés comme gains à long terme.",
        2: () =>
          "Selon votre juridiction, vous pourriez avoir droit à une réduction d’impôt sur tout gain à long terme.",
      },
      q: () =>
        "Les gains à long et à court terme sont-ils déclarés séparément ?",
    },
  },
  for: () => "pour",
  forceSyncErp: (d) => "Forcer la resynchronisation vers " + d.erp,
  gainsToggles: () => "Options de catégorie de gains en capital",
  generic: {
    isAccountant: () => "Vous êtes comptable ? Cliquez ici",
    notAccountant: () => "Retour à la tarification individuelle",
    select: () => "Sélectionner",
    yourPlan: () => "Votre plan",
  },
  groupingWizard: {
    actionButton: () => "Fermer",
    backPage: () => "Dos",
    matchCountPage: {
      description: () =>
        "Pour que les transactions soient groupées, vous devez disposer du nombre correct de transactions entrantes et sortantes pour former le groupe souhaité.",
      ratios: {
        NToOne: (d) =>
          "Les groupes " +
          d.type +
          " nécessitent au moins une transaction entrante et une transaction sortante pour former un groupe.",
        nToM: (d) =>
          "Les groupes " +
          d.type +
          " nécessitent au moins une transaction entrante et au moins une transaction sortante pour former un groupe, et le nombre d'entrées doit correspondre au nombre de sorties.",
        nToN: (d) =>
          "Les groupes " +
          d.type +
          " nécessitent au moins une transaction entrante et au moins une transaction sortante pour former un groupe.",
        oneToN: (d) =>
          "Les groupes " +
          d.type +
          " nécessitent une transaction entrante et au moins une transaction sortante pour former un groupe.",
        oneToOne: (d) =>
          "Les groupes " +
          d.type +
          " nécessitent une transaction entrante et une transaction sortante pour former un groupe.",
      },
      selected: (d) =>
        "Vous avez sélectionné " +
        plural(d.inCount, 0, fr, {
          one: " 1 in transaction ",
          other: number("fr", d.inCount, 0) + " in transactions ",
        }) +
        " et " +
        plural(d.outCount, 0, fr, {
          one: " 1 out transaction ",
          other: number("fr", d.outCount, 0) + " out transactions ",
        }) +
        " .",
      title: (d) =>
        "Avez-vous sélectionné le bon nombre de transactions pour créer un groupe " +
        d.type +
        " ?",
    },
    matchCriteriaPage: {
      description: () =>
        "Pour que les transactions soient groupées, les données doivent satisfaire à un ensemble de critères pour créer avec succès un groupe.",
      differentAssetType: {
        cta: () =>
          "Les devises d'un produit dérivé doivent être des types d'actifs différents, vous devez avoir exactement un jeton de position.",
        title: () => "Devises:",
      },
      differentCurrency: {
        cta: () =>
          "Les devises d’une transaction doivent être différentes, vous ne pouvez pas vendre une devise pour acheter la même devise.",
        title: () => "Devises:",
      },
      differentSources: {
        cta: () =>
          "Pour une transaction, les côtés achat et vente doivent provenir de la même importation. Vous ne pouvez pas effectuer de transaction sur différentes importations.",
        title: () => "Sources d'importation :",
      },
      dontGroup: {
        cta: () =>
          "Une ou plusieurs des transactions que vous avez sélectionnées ont l'indicateur Ne pas regrouper activé. Ceci est activé lorsque vous utilisez la fonctionnalité de dégroupage pour empêcher le regroupement des transactions. Si vous souhaitez que ces transactions soient groupées, veuillez désactiver l'indicateur Ne pas regrouper",
        enableGrouping: () => "Activer le regroupement",
        title: () => "Drapeau de dissociation :",
      },
      fiatCurrency: {
        cta: () =>
          "Les transactions doivent être des transactions en monnaie fiduciaire",
        title: () => "Monnaie fiduciaire :",
      },
      noIssues: {
        cta: () =>
          "Aucun problème de regroupement n'a été détecté pour les transactions sélectionnées. Veuillez essayer un recalcul manuel pour regrouper ces transactions. Si les transactions restent dégroupées après un recalcul manuel, veuillez contacter le support.",
        title: () => "Aucun problème de regroupement détecté",
      },
      quantity: {
        cta: () =>
          "La quantité traitée dans les transactions entrantes ne correspond pas aux quantités traitées dans les transactions sortantes. Pour regrouper ces transactions, veuillez sélectionner la quantité latérale que vous souhaitez utiliser pour le groupe",
        title: () => "Quantité de devise :",
      },
      sameAccount: {
        cta: () =>
          "Les comptes appartenant aux utilisateurs dans les transactions doivent correspondre. Pour regrouper ces transactions, assurez-vous qu'elles proviennent toutes du même compte.",
        title: () => "Comptes:",
      },
      sameCurrency: {
        cta: () =>
          "La devise envoyée ne correspond pas à la devise reçue. Pour regrouper ces transactions, veuillez modifier les transactions dans la devise que vous souhaitez utiliser pour le groupe.",
        title: () => "Devises:",
      },
      sameFromTo: {
        cta: () =>
          "L'adresse de l'expéditeur et l'adresse du destinataire doivent correspondre. Pour regrouper ces transactions, veuillez modifier les transactions en fonction de la paire d'adresses de/à que vous souhaitez utiliser pour le groupe.",
        title: () => "De et vers :",
      },
      timestamp: {
        cta: () =>
          "Les transactions sélectionnées ne se trouvent pas dans la fenêtre d'horodatage requise pour être regroupées. Pour regrouper ces transactions, veuillez modifier les transactions en fonction de l'horodatage que vous souhaitez utiliser pour le groupe",
        title: () => "Horodatages :",
      },
      title: (d) =>
        "Avez-vous sélectionné des transactions qui répondent à tous les critères d’un groupe " +
        d.type +
        " ?",
      txId: {
        cta: () =>
          "Les transactions sélectionnées n'ont pas le même ID de transaction. L'ID de transaction ne peut pas être modifié pour le moment.",
        title: () => "ID de transaction :",
      },
      value: {
        cta: () =>
          "La valeur des entrées pour cette transaction ne correspond pas aux valeurs des sorties. Pour regrouper ces transactions, veuillez modifier les transactions en fonction de la valeur que vous souhaitez utiliser pour le groupe",
        title: () => "Valeur de la transaction :",
      },
    },
    matchTypePage: {
      allowed: () => "Types de groupes autorisés :",
      description: () =>
        "Pour que les transactions soient groupées, leurs catégories doivent correspondre aux sous-types d’une de nos catégories groupées.",
      selected: () => "Catégories que vous avez sélectionnées :",
      title: () =>
        "Les types de transactions sélectionnés correspondent-ils à un groupe ?",
    },
    modalTitle: () => "Assistant de regroupement",
    nextPage: () => "Suivant",
  },
  help: () => "Aide",
  holdingsDropdown: {
    assets: () => "Actifs",
    balances: () => "Soldes",
    calcBal: () => "Solde calculé",
    calculatedTooltip: () => "Le solde calculé par Crypto Tax Calculator",
    currentPrice: () => "Prix actuel",
    holdings: () => "Fonds",
    noData: () => "N / A",
    noHoldings: () => "Aucune participation",
    price: () => "Prix",
    quantity: () => "Quantité",
    quantityTooltip: () =>
      "Le montant indiqué par l'échange ou le portefeuille, ou si aucun montant n'est indiqué, le montant calculé par Crypto Tax Calculator",
    repBal: () => "Solde déclaré",
    reportedTooltip: () => "Le solde indiqué par l'échange ou le portefeuille",
    value: () => "Valeur",
    valueTooltip: () =>
      "La valeur indiquée par l'échange ou le portefeuille, ou si aucun montant n'est indiqué, la valeur calculée par Crypto Tax Calculator",
    wallets: () => "Portefeuilles",
  },
  ignore: () => "Ignorer",
  ignoreIssue: () => "Ignorer le problème",
  importStepType: {
    critical: () => "critique",
    note: () => "note",
    step: () => "étape",
    video: () => "vidéo",
  },
  imports: {
    accountTableTypes: {
      blockchain: () => "Blockchain",
      exchange: () => "Échange",
    },
    accounts: () => "Comptes",
    accountsTableHeadings: {
      account: () => "Compte",
      assets: () => "Actifs",
      balance: () => "Équilibre",
      synced: () => "Synchronisé",
      tx: () => "Tx",
      type: () => "Taper",
    },
    addIntegrationTitle: () => "Ajouter un compte",
    addIntegrations: () => "Ajouter un compte",
    addSource: (d) => "Ajouter une source " + d.source + " supplémentaire",
    addYourFirst: () => "Ajoutez votre premier compte pour commencer",
    additionalNotes: () => "Notes complémentaires",
    advancedOptions: () => "Options avancées",
    api: () => "API",
    apiDisabled: (d) =>
      select(d.isNew, {
        true: " L'option de synchronisation automatique via API n'est pas disponible ",
        other:
          " L'option de synchronisation automatique via API n'est pas disponible car les données sont déjà importées via le téléchargement de fichiers ",
      }),
    apiStillSyncing: () =>
      "L'API est toujours en cours de synchronisation, veuillez patienter quelques minutes.",
    apiWarning: () =>
      "N'ajoutez jamais de clés API avec des autorisations privées qui permettent le trading !",
    backToExistingImports: () => "Retour aux importations existantes",
    backToImport: () => "Retour à la recherche d'importation",
    backToImportTypeSelector: () =>
      "Retour à la sélection du type d'importation",
    backToIntegrations: () => "Retour aux comptes",
    backToIntegrationsSearch: () => "Retour à la recherche de comptes",
    beta: () => "Bêta",
    betaTooltipDefault: () =>
      "Cette intégration est en version bêta et peut présenter quelques problèmes. Veuillez signaler tout problème à notre équipe d'assistance.",
    breakdownTooltip: () => "Cliquez pour trier",
    browse: () => "Télécharger",
    bulkSyncTooltip: {
      api: (d) =>
        "Resynchronisez vos API " + d.name + " à partir de " + d.dateTime,
      deprecatedTooltip: (d) =>
        "L'API " +
        d.name +
        " n'est plus opérationnelle, veuillez l'importer via CSV",
      failed: () => "La synchronisation a échoué, cliquez pour resynchroniser",
      lastSyncTime: () => "dernière heure de synchronisation",
      lockedPeriod: (d) =>
        "Resynchronisation à partir de la fin de la dernière période de verrouillage : " +
        d.dateTime,
      noSync: () => "Rien à resynchroniser",
      pending: () => "Synchronisation",
      wallet: (d) =>
        "Resynchronisez vos portefeuilles " +
        d.name +
        " à partir du " +
        d.dateTime,
    },
    chainDisabled: (d) => d.name + " désactivé",
    chips: {
      api: () => "API",
      csv: () => "CSV",
      service: () => "Service",
      wallet: (d) =>
        plural(d.walletCount, 0, fr, {
          0: " Portefeuille ",
          one: " 1 Portefeuille ",
          other: number("fr", d.walletCount, 0) + " Portefeuilles ",
        }),
    },
    clickHere: () => "Cliquez ici",
    connectWallet: () => "Connecter le portefeuille",
    connectWalletErrors: {
      duplicateWallet: (d) =>
        "Ce portefeuille " + d.address + " a déjà été connecté",
      duplicateWallets: (d) =>
        "Ces portefeuilles " + d.addresses + " ont déjà été connectés",
      maxWallets: (d) =>
        "Vous ne pouvez connecter que " + d.max + " portefeuilles à la fois",
      walletNotInstalled: (d) =>
        "Le portefeuille " +
        d.wallet +
        " n'est pas installé. Veuillez installer " +
        d.wallet +
        " à partir de son site Web officiel ou en tant qu'extension pour continuer à connecter votre portefeuille",
    },
    connected: () => "Connecté",
    connectionSuccess: () => "Connexion réussie !",
    continue: () => "Continuer",
    cryptoHoldings: () => "Avoirs en crypto-monnaies",
    csv: () => "csv",
    csvAlerts: {
      fileSizeTooBig: () =>
        "La taille maximale de 20 Mo est dépassée. Veuillez supprimer les fichiers ou fournir des fichiers plus petits.",
      fileTypeNotSupported: () =>
        "Nous prenons uniquement en charge les fichiers CSV, XLS ou XLSX.",
      invalidFileName: (d) =>
        "Les noms de fichiers " +
        d.fileNames +
        " contiennent des symboles non valides.",
      missingAddress: () =>
        "Vous devez saisir l'adresse associée à ce fichier CSV",
      missingCurrencySymbols: () =>
        "Vous devez saisir la devise car elle varie selon les fichiers CSV",
      noFiles: () => "Aucun fichier sélectionné !",
      onlyOneFile: () => "Veuillez télécharger un seul fichier.",
    },
    csvCaps: () => "CSV",
    csvDisabled: (d) =>
      select(d.isNew, {
        true: " L'option de téléchargement de fichier n'est pas disponible ",
        other:
          " L'option de téléchargement de fichier n'est pas disponible car les données sont déjà importées via la synchronisation automatique ",
      }),
    csvExtraInfo: {
      address: () => "Adresse",
      addressHelper: () => "Entrez l'adresse du portefeuille associée à ce CSV",
      addressPlaceholder: () => "Entrez l'adresse du portefeuille",
      currencyHelper: () => "Veuillez saisir la devise sur ce CSV",
      currencySymbol: () => "Symbole monétaire",
      fiatCurrency: () =>
        "Confirmez la devise fiduciaire de ce CSV, par exemple NZD ou AUD",
      tickerPlaceHolder: () => "Entrez la valeur du ticker, par exemple BTC",
    },
    csvWarning: () =>
      "Nous vous recommandons d'utiliser les importations CSV lorsque cela est possible",
    customCSV: () => "Importer un fichier CSV personnalisé",
    customCsvButton: () =>
      "Vous ne trouvez pas votre échange ? Cliquez ici pour ajouter un fichier CSV personnalisé",
    customCsvLabel: () => "Nom de l'échange",
    customCsvPlaceholder: () => "par exemple Binance, Coinbase",
    deleteApiKeyConfirmTitle: () => "Supprimer la clé API",
    deleteImportExchangeConfirmation: {
      body: (d) =>
        "Êtes-vous sûr de vouloir supprimer toutes les données de " +
        d.name +
        " ? Cela supprimera toutes les transactions associées à cette importation. Cette opération ne peut pas être annulée.",
      title: () => "Supprimer l'importation",
    },
    deleteImportedFileConfirmation: {
      body: () =>
        "Etes-vous sûr de vouloir supprimer le fichier ? Cela supprimera également TOUTES les transactions importées par ce fichier. Cette action ne peut pas être annulée.",
      title: () => "Supprimer le fichier",
    },
    deleteOauthKeyConfirmText: (d) =>
      "Êtes-vous sûr de vouloir déconnecter votre compte " +
      d.exchangeName +
      " ? Cela supprimera TOUTES les transactions importées depuis votre compte " +
      d.exchangeName +
      " connecté. Cette action ne peut pas être annulée.",
    deleteOauthKeyConfirmTitle: (d) => "Déconnecter " + d.exchangeName,
    deleteWalletConfirmTitle: () => "Supprimer le portefeuille",
    deprecated: () => "Obsolète",
    deprecatedTooltipDefault: () =>
      "Cette intégration est obsolète et n'est plus activement maintenue",
    deprecation: {
      description: () =>
        "Cette source d'importation a été supprimée et n'est plus maintenue. Importez des données à l'aide de notre format CSV manuel. https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
      title: () => "Source retirée",
    },
    dialogOptions: {
      addApi: () => "Ajouter une API",
      addCsv: () => "Ajouter un CSV",
      addWallet: () => "Ajouter l'adresse du portefeuille",
      copyAddress: () => "Copier l'adresse",
      delete: () => "Supprimer",
      deleteAll: () => "Supprimer tout",
      downloadCsv: () => "Télécharger CSV",
      editApi: () => "Modifier l'API",
      editNickname: () => "Modifier le pseudo",
      hardSync: () => "Synchronisation matérielle",
      ignoreChain: () => "Ignorer la chaîne",
      sync: () => "Synchroniser",
      viewExplorer: () => "Afficher dans l'explorateur de blocs",
      viewLedger: () => "Grand livre des devises",
      viewTransactions: () => "Voir les transactions",
    },
    done: () => "Fait",
    downloadCsvDisplayMessage: {
      info: () => "Préparation du téléchargement CSV en cours...",
      success: () => "Téléchargement CSV prêt !",
    },
    drawer: {
      accountMetricsHeading: {
        balance: () => "Équilibre",
        feesPaid: () => "Frais payés",
        tradeVolume: () => "Volume des échanges",
        transactions: () => "Transactions",
      },
      addApiKey: () => "Ajouter une clé API",
      addCsv: () => "Ajouter un CSV",
      addWallet: () => "Ajouter une adresse de portefeuille",
      currencyTableHeadings: {
        amount: () => "Montant",
        currencies: () => "Devises",
        price: () => "Prix",
        value: () => "Valeur",
      },
      deleteApiKey: {
        body: (d) =>
          "Êtes-vous sûr de vouloir supprimer cette clé API " +
          d.key +
          " ? Cela supprimera TOUTES les transactions importées par cette clé. Cette action ne peut pas être annulée.",
        title: () => "Supprimer la clé API",
      },
      deleteBlockchain: {
        body: (d) =>
          "Êtes-vous sûr de vouloir supprimer " +
          d.address +
          " sur " +
          d.blockchain +
          " ? Cela supprimera TOUTES les transactions importées depuis cette adresse sur la blockchain. Cette action ne peut pas être annulée.",
        title: () => "Supprimer la blockchain",
      },
      deleteFile: {
        body: (d) =>
          "Etes-vous sûr de vouloir supprimer le fichier " +
          d.name +
          " ? Cette action supprimera TOUTES les transactions importées par ce fichier. Cette action ne peut pas être annulée.",
        title: () => "Supprimer le fichier",
      },
      deleteOauth: {
        body: (d) =>
          "Êtes-vous sûr de vouloir déconnecter votre compte " +
          d.exchangeName +
          " ? Cela supprimera TOUTES les transactions importées depuis votre compte " +
          d.exchangeName +
          " connecté. Cette action ne peut pas être annulée.",
        title: (d) => "Déconnecter " + d.exchangeName,
      },
      nftTableHeadings: {
        collections: () => "Collections",
        combinedCost: () => "Coût combiné",
        ntfs: () => "NFT",
        totalSpend: () => "Dépenses totales",
      },
      overview: () => "Aperçu",
      showLess: () => "Afficher moins de devises",
      showLessNft: () => "Afficher moins de NFT",
      showMore: () => "Afficher plus de devises",
      showMoreNft: () => "Afficher plus de NFT",
      statusBanner: {
        accountHasNoTransactions: () => "Le compte n'a aucune transaction",
        and: () => "et",
        chainSyncFailed: (d) =>
          "La synchronisation de " + d.chains + " a échoué",
        generalImportsFailed: (d) =>
          select(d.all, { true: " Tout ", other: " Certains " }) +
          " les importations d' " +
          d.integration +
          " ont échoué",
        generalSyncsFailed: (d) =>
          select(d.all, { true: " All ", other: " Some " }) +
          " " +
          d.integration +
          " les importations n'ont pas pu être synchronisées",
        selectedImportFailed: (d) =>
          d.name +
          " importation échouée " +
          select(d.reason, { undefined: "", other: " : " + d.reason }) +
          select(d.code, { undefined: "", other: " - code : " + d.code }),
        selectedImportHasNoTransactions: (d) =>
          "L'importation de " + d.name + " n'a aucune transaction",
        selectedSyncFailed: (d) =>
          d.name +
          " sync a échoué " +
          select(d.reason, { undefined: "", other: " : " + d.reason }) +
          select(d.code, { undefined: "", other: " - code: " + d.code }),
      },
      viewCollection: () => "Voir la collection",
      viewLedger: () => "Grand livre des devises",
      viewOnTxTable: () => "Vue sur le tableau des transactions",
      viewTxs: () => "Voir les transactions",
    },
    dropFiles: () => "Déposez votre/vos fichier(s) ici",
    editSource: (d) => "Modifier la source " + d.source,
    emptyHoldings: () => "Pas de détention de crypto-monnaie...",
    enable: () => "Activer",
    enableImportChainTransactions: () =>
      "Activer l'importation des transactions de cette chaîne",
    error: (d) =>
      "Erreur lors de l'importation de " +
      select(d.blockchain, { true: "adresse " + d.name, other: d.name }) +
      select(d.showError, { true: ": " + d.error, other: "" }) +
      select(d.showCode, { true: " - code : " + d.code, other: "" }),
    exchangeGridTitle: {
      all: () => "Tous les échanges et portefeuilles",
      allBlockchains: () => "Toutes les blockchains et portefeuilles",
      popular: () => "Le plus populaire",
      results: () => "Résultats",
    },
    existingOptions: () => "Portefeuilles et échanges importés",
    findAccount: () => "Trouver un compte",
    firstTx: () => "Première transaction importée :",
    goBack: () => "Retourner",
    hardSync: () => "Synchronisation matérielle",
    import: () => "Importer",
    importBannerMessages: {
      connectingToIntegration: (d) => "Connexion à " + d.name,
      success: () => "Importation réussie",
    },
    importByManualCSV: (d) =>
      "Importer un fichier CSV basé sur un modèle CTC pour « " +
      d.exchange +
      " »",
    importCSV: (d) => "Importer " + d.source + " CSV",
    importDataTitle: () => "Ajouter un compte",
    importExchange: (d) => "Importer " + d.exchangeName,
    importFailed: () => "Échec de l'importation",
    importFileDuplicateWarning: () =>
      "Toutes les transactions de ce fichier ont été identifiées comme des doublons et n'ont pas été importées",
    importFrom: {
      chip: {
        notRecommended: () => "Non recommandé",
        recommended: () => "Recommandé",
      },
      modal: {
        actionText: () => "Continuer avec l'importation",
        text: () =>
          "L'utilisation de la fonctionnalité « Importer à partir de la date » empêche l'importation de transactions antérieures à la date sélectionnée. Cela peut entraîner des erreurs d'historique d'achat manquantes, qui peuvent être corrigées à l'aide d'une transaction « manuelle » pour fournir le solde initial de l'importation avant la date d'importation.",
        title: () =>
          "L'importation à partir de la date peut entraîner des données manquantes",
      },
      selector: {
        beginning: () => "Début",
        custom: () => "Date personnalisée",
        title: () => "Démarrer l'importation à partir de :",
      },
    },
    importInstructionsAccordionTitle: () => "Instructions étape par étape",
    importTypeModal: {
      buttonLabel: {
        csv: {
          active: () => "Télécharger CSV",
          default: () => "Télécharger",
        },
      },
      description: () => "Comment souhaitez-vous importer vos données ?",
      importMethod: {
        api: {
          description: () =>
            "Accès en lecture seule. La connexion est confidentielle et cryptée de manière sécurisée.",
          title: () => "Se connecter via API",
        },
        "bulk-wallet": {
          description: () =>
            "Importez des transactions en collant vos adresses publiques depuis votre application ou extension Coinbase Wallet",
          title: () => "Importer des adresses publiques",
        },
        "connect-wallet": {
          description: () =>
            "Importe en toute sécurité les transactions depuis la blockchain",
          title: () => "Connecter le portefeuille",
        },
        csv: {
          description: () =>
            "Télécharger un fichier d'historique des transactions ",
          title: () => "Importer via un fichier CSV",
        },
        oauth: {
          description: () =>
            "Importer automatiquement les transactions via OAuth",
          title: () => "Synchronisation automatique",
        },
        wallet: {
          description: () =>
            "Importez automatiquement les transactions depuis la blockchain en collant l'adresse de votre portefeuille",
          title: () => "Synchronisation d'adresse",
        },
      },
      instructionTitles: {
        api: () => "Instructions de synchronisation de l'API",
        "bulk-wallet": () =>
          "Instructions de synchronisation de portefeuille en masse",
        "connect-wallet": () => "Instructions de connexion au portefeuille",
        csv: () => "Instructions pour télécharger le fichier",
        oauth: (d) => "Instructions de connexion " + d.exchange,
        wallet: () => "Instructions de synchronisation du portefeuille",
      },
      methods: {
        api: () => "Entrez les détails de l'API",
        "bulk-wallet": () => "Entrez les adresses de portefeuille",
        wallet: () => "Entrez l'adresse du portefeuille",
      },
      title: (d) => "Importer " + d.name,
    },
    imported: () => "Importé",
    importedFileWarning: () =>
      "Aucune transaction n'a été importée à partir de ce fichier",
    importedTransactions: (d) =>
      d.count +
      " " +
      plural(d.count, 0, fr, {
        one: " transaction ",
        other: " transactions ",
      }) +
      " importé depuis " +
      d.exchangeDisplayName +
      " .",
    imports: () => "Importations",
    integrationChips: {
      all: () => "Tous",
      blockchains: () => "Les blockchains",
      exchanges: () => "Échanges",
      services: () => "Services",
      wallets: () => "Portefeuilles",
    },
    integrationSuccessful: () => "Intégration réussie",
    integrations: () => "Intégrations",
    label: () => "Sélectionnez votre bourse ou votre portefeuille",
    lastLockedSyncWarning: {
      action: () => "Afficher les périodes verrouillées",
      body: (d) =>
        "Les transactions antérieures au " +
        d.date +
        " ne seront pas importées",
      title: () => "Périodes verrouillées :",
    },
    lastSynced: () => "Dernière synchronisation",
    lastTx: () => "Dernière transaction importée :",
    manualCSV: {
      csvText: () => "CSV",
      customCSVToExchange: {
        description: {
          link: () =>
            "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
          linkText: () => " format CSV personnalisé.",
          preLinkText: () =>
            "Si vous souhaitez importer des transactions personnalisées sur ce compte, suivez les étapes ci-dessous en utilisant notre",
        },
        title: () => "Importation de transactions personnalisées",
      },
      excelText: () => "Modèle Excel",
      finishAdding: () => "Terminez l’ajout des fichiers et continuez",
      googleSheetsText: () => "Modèle de feuille de calcul Google",
      learnMore: {
        link: () =>
          "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
        text: () => "En savoir plus sur les importations CSV",
      },
      seeAdvancedGuide: {
        link: () =>
          "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
        linkText: () => "guide CSV avancé",
        postLinkText: () =>
          "pour les transactions où les frais n'ont pas encore été appliqués avec les données de trading.",
        preLinkText: () => "Voir notre",
      },
      steps: {
        0: () =>
          "Complétez les détails de votre transaction dans le modèle Excel, CSV ou Google Sheets.",
        1: () =>
          "Assurez-vous que tous les champs sont remplis pour éviter les erreurs de téléchargement.",
        2: () =>
          "Exportez votre document au format CSV et cliquez sur Télécharger.",
      },
      warningText: () =>
        "Assurez-vous que vos données sont préparées correctement et au format de fichier CSV personnalisé de Crypto Tax Calculator",
    },
    manualCSVImport: () => "Utilisez le modèle CSV CTC pour importer",
    manualImportTooltip: () =>
      "Si vous ne trouvez pas votre portefeuille ou votre bourse, vous pouvez importer vos données à l'aide du modèle CSV CTC",
    marketValue: (d) => "Valeur marchande en " + d.localCurrency,
    nameOfSource: () => "Nom de la source",
    noExchange: () => "Vous ne trouvez pas votre échange ?",
    noImports: () =>
      "Aucun échange, blockchain ou portefeuille importé trouvé !",
    noOptions: () => "Aucune option",
    noSync: () => "Pas de dernière synchronisation",
    noTxFound: (d) =>
      "Aucune transaction " +
      select(d.showNew, { true: " new ", other: " " }) +
      " trouvée pour " +
      d.exchangeDisplayName +
      " .",
    noTxFoundWallet: (d) =>
      "Aucune transaction " +
      select(d.showNew, { true: " new ", other: " " }) +
      " trouvée pour le portefeuille " +
      d.blockchain +
      " " +
      d.address +
      " .",
    note: () => "Note",
    notifications: {
      kucoinfutures: {
        buttonLabel: () => "Rejeter",
        text: () =>
          "Vos données Kucoin Futures ont été consolidées dans l'intégration Kucoin",
        title: () => "Suppression des contrats à terme sur Kucoin",
      },
    },
    oauth: {
      connect: (d) => "Connectez-vous à " + d.exchange,
      description: (d) =>
        "Vous serez invité à vous connecter à " +
        d.exchange +
        " et à accorder un accès en lecture seule à votre compte. Cela ne nous donne pas le droit de transférer vos fonds.",
    },
    onboarding: {
      expect: () => "Voici à quoi vous attendre",
      getStarted: () => "Commencer",
      steps: {
        0: () => "Choisissez votre pays",
        1: () => "Intégrez vos échanges, portefeuilles et blockchains",
        2: () =>
          "Obtenez votre rapport fiscal et vérifiez l'exactitude de vos données",
      },
      welcome: () =>
        "Bienvenue sur le calculateur de taxes sur les crypto-monnaies",
    },
    options: {
      api: () => "Synchronisation via API",
      "bulk-wallet": () => "Portefeuille en vrac",
      connect: (d) => "Connectez-vous à " + d.exchange,
      csv: () => "Télécharger le fichier",
      oauth: (d) => "Connectez-vous à " + d.exchange,
      "single-import": () => "Importation unique",
    },
    prompt: () => "Par exemple Coinbase, Bitcoin",
    relatedChains: {
      account: () => "Compte",
      addBlockchain: () => "Ajouter une blockchain",
      cluster: {
        dontImport: () => "Ne pas importer",
        ignore: () => "Ne plus demander",
        import: () => "Importer",
        text: () =>
          "Souhaitez-vous importer le reste des portefeuilles de ce cluster ?",
        title: () =>
          "Nous avons détecté que votre adresse appartient à un cluster",
      },
      dontImport: () => "Ne pas importer",
      import: () => "Importer",
      importTransactions: () =>
        "Importer des transactions sur les chaînes suivantes pour ce compte ?",
      scanning: (d) =>
        "Analyse des chaînes EVM pour les transactions sur " + d.wallet,
      scanningNoResults: (d) =>
        "Nous n'avons trouvé aucune autre Blockchain sous " + d.address,
      scanningSuccess: () =>
        "Transactions sur d'autres chaînes EVM trouvées pour",
      stepProgress: (d) =>
        "Portefeuille " + d.step + " sur " + d.totalSteps + " :",
      stillScanning: () => "Toujours en cours de numérisation...",
      syncingOnChains: (d) =>
        "Synchronisation " + d.wallet + " sur les chaînes " + d.chains,
      title: () => "Transactions détectées sur des chaînes liées",
    },
    requestAnIntegration: () => "Demander une intégration",
    resultNotFound: () => "Résultat non trouvé",
    searchPrompt: () =>
      "Recherchez votre portefeuille, votre bourse ou votre blockchain",
    secureConnect: () => "Connexion sécurisée",
    secureConnectNote: () =>
      "Nous demandons uniquement des autorisations de visualisation. Nous ne demanderons jamais de clés privées et ne pourrons pas transférer de fonds.",
    secureImport: (d) =>
      "Importez en toute sécurité l'historique de vos transactions " +
      d.exchangeName +
      " :",
    selectCountry: {
      chooseJurisdiction: () => "Choisissez votre juridiction fiscale",
      findCountry: () => "Trouvez votre pays",
      pleaseSelect: () => "Veuillez sélectionner un pays pour continuer",
      search: () => "Recherche",
      somethingWentWrong: (d) =>
        "Une erreur s'est produite lors de la définition du pays : $ " +
        d.message,
      somethingWentWrongSupport: () =>
        "Une erreur s'est produite, contactez le support.",
    },
    selectFile: () => "ou faites glisser et déposez votre fichier ici",
    selectFiles: () => "ou faites glisser et déposez vos fichiers ici",
    skippedRows: {
      cta: (d) =>
        plural(d.count, 0, fr, {
          1: " 1 ligne ignorée ",
          other: number("fr", d.count, 0) + " lignes ignorées ",
        }),
      errors: {
        apiError: () =>
          "Une erreur s'est produite lors de l'interaction avec une API externe.",
        genericError: () => "Une erreur inattendue s'est produite.",
        lockPeriod: () =>
          "La ligne a été ignorée car elle aurait créé une transaction dans une période verrouillée",
        noOp: () =>
          "La ligne a été intentionnellement ignorée car les données ne sont pas pertinentes ou parce qu'elles seront récupérées à partir d'un autre fichier CSV",
        noTransactions: () => "Aucune transaction n'a été trouvée dans le CSV.",
        unhandledFields: () =>
          "Des champs inattendus ont été trouvés dans la ligne.",
        unhandledTimestamp: () =>
          "L'horodatage de la ligne n'était pas au format attendu.",
        unhandledTrade: () =>
          "La ligne avait un type d’échange non pris en charge.",
        userSpecifiedCurrencyIssue: () =>
          "Une erreur s'est produite avec la devise spécifiée par l'utilisateur.",
      },
      header: () => "lignes sautées",
      info: (d) =>
        plural(d.count, 0, fr, {
          1: " 1 ligne dans le CSV a été ignorée pour la raison suivante ",
          other:
            number("fr", d.count, 0) +
            " lignes dans le CSV ont été ignorées pour les raisons suivantes ",
        }),
    },
    softWalletHeading: (d) =>
      "Nous importons vos données " +
      d.name +
      " directement depuis la blockchain. Sélectionnez ci-dessous le réseau blockchain de votre portefeuille.",
    softWalletNetworkSelect: () => "Entrez dans votre réseau de portefeuille",
    softWalletPrompt: () => "Par exemple Bitcoin, Ethereum",
    solanaBalanceWarning: () =>
      "Le solde comprend les SOL mis en jeu et les SOL détenus dans des comptes de jetons. Certains explorateurs peuvent ne pas les inclure dans le solde global.",
    sortImports: {
      aToZ: () => "Nom AZ",
      mostTransactions: () => "La plupart des transactions",
      zToA: () => "Nom ZA",
    },
    successImportWallet: (d) =>
      "Transactions importées avec succès depuis le portefeuille " +
      d.blockchain +
      " " +
      d.address,
    suspectedMissingImports: {
      addAccount: () => "Ajouter un compte",
      chips: {
        amount: () => "Montant du transfert :",
        count: () => "Transactions:",
        currencies: () => "Devises:",
      },
      explainer: (d) =>
        "Il semble que vous ayez effectué " +
        plural(d.count, 0, fr, {
          1: " 1 transaction ",
          other: number("fr", d.count, 0) + " transactions ",
        }) +
        " impliquant " +
        d.symbol1 +
        plural(d.additionalCurrencyCount, 0, fr, {
          0: select(d.symbol2, { undefined: "", other: " et " + d.symbol2 }),
          1: select(d.symbol2, {
            undefined: "",
            other: ", " + d.symbol2 + " et 1 autre devise ",
          }),
          other: select(d.symbol2, {
            undefined: "",
            other:
              ", " +
              d.symbol2 +
              " et " +
              number("fr", d.additionalCurrencyCount, 0) +
              " autres devises ",
          }),
        }) +
        " sur " +
        d.exchange +
        " " +
        select(d.startDate, {
          undefined: " dans ",
          other: " entre " + d.startDate + " et ",
        }) +
        " " +
        d.endDate +
        ".",
      title: () => "Comptes recommandés",
      toastBody: () => "Vous pouvez ajouter ce compte à tout moment",
      toastHeading: () => "Recommandation rejetée",
      tooltip: () =>
        "Nous avons constaté des interactions avec ces comptes. La connexion de tous vos comptes améliorera la précision de votre déclaration fiscale.",
      viewTransactions: (d) =>
        "Afficher " +
        plural(d.count, 0, fr, {
          1: " transaction ",
          other: " transactions ",
        }) +
        ".",
      whyThis: () => "Pourquoi ce compte ?",
    },
    sync: () => "Synchroniser",
    syncAll: () => "Synchroniser tout",
    syncFailed: () => "La synchronisation a échoué",
    syncStatus: {
      failed: (d) =>
        select(d.isCsv, {
          true: " Échec de l'importation ",
          other: " Échec de la synchronisation ",
        }),
      noSync: () => "Pas de synchronisation",
      pending: (d) =>
        select(d.isCsv, { true: " Importation ", other: " Synchronisation " }),
      queued: (d) =>
        select(d.isCsv, {
          true: " Importation ",
          other: " En file d'attente ",
        }),
      success: (d) =>
        select(d.isCsv, { true: " Importé ", other: " Synchronisé " }),
    },
    syncedFrom: () => "Synchronisé depuis :",
    syncing: () => "Synchronisation...",
    syncingWallet: (d) => "Synchronisation du portefeuille " + d.name,
    title: () => "Ajouter un compte",
    transactionCount: {
      noTransactions: () => "Aucune transaction",
      noTxs: () => "Pas de TX",
      transaction: () => "transaction",
      transactions: () => "transactions",
      tx: () => "TX",
      txs: () => "TX",
    },
    transactionsImported: () => "Transactions importées",
    updatedAt: (d) => "Mis à jour " + d.time,
    uploaded: (d) => "Téléchargé " + d.time,
    verifying: () => "Vérification",
    view: () => "Voir",
    viewGuide: () => "Voir le guide",
    viewMode: {
      byAddress: () => "Par portefeuille",
      byBlockchain: () => "Par Blockchain",
    },
    viewSBSInstructions: () => "Voir les instructions étape par étape",
    wentWrongAvailableImports: () =>
      "Une erreur s'est produite lors de l'obtention des importations disponibles. Veuillez réessayer ultérieurement.",
    wentWrongCurrentHoldings: () =>
      "Une erreur s'est produite lors de l'obtention des fonds actuels. Veuillez réessayer ultérieurement.",
    wentWrongDeleteFile: () =>
      "Une erreur s'est produite lors de la tentative de suppression du fichier. Veuillez réessayer ultérieurement.",
    wentWrongDeletingCSV: (d) =>
      "Une erreur s'est produite lors de la suppression du fichier CSV " +
      d.name +
      " .",
    wentWrongDownloadFile: () => "Impossible de télécharger le fichier",
    wentWrongExistingSaved: () =>
      "Une erreur s'est produite lors de la demande d'importations enregistrées existantes. Veuillez réessayer ultérieurement",
  },
  inTxCategoryOptionsTitle: () => "Transactions entrantes",
  incomeToggles: () => "Options de catégorie de revenu",
  info: () => "Information",
  instructions: () => "Instructions",
  integrationWarnings: {
    btc: {
      invalidWallet: {
        description: () =>
          "Nous vous recommandons d'utiliser votre adresse xPub, yPub ou zPub car les portefeuilles Bitcoin génèrent plusieurs adresses sous un seul compte principal",
        noTxs: () =>
          "Aucune transaction n'a été importée car l'adresse du portefeuille utilisée n'est pas une adresse xPub, yPub ou zPub. Nous vous recommandons d'importer votre adresse xPub, yPub ou zPub.",
        title: () => "Veuillez saisir une adresse xPub, yPub ou zPub",
        wrongFormat: () =>
          "L'adresse du portefeuille utilisée n'est pas une adresse xPub, yPub ou zPub. Nous vous recommandons d'importer votre adresse xPub, yPub ou zPub pour garantir que toutes vos transactions Bitcoin sont importées.",
      },
    },
    default: {
      noTxs: () =>
        "Aucune transaction détectée pour ce portefeuille importé. Cela peut être dû à une adresse de portefeuille incorrecte ou au fait que le portefeuille n'a aucune transaction sur la blockchain sélectionnée. Veuillez vérifier les détails de votre portefeuille et la sélection de la blockchain.",
    },
  },
  invitation: {
    buttonText: {
      accepted: () => "Accepter",
      declined: () => "Déclin",
      deleted: () => "Supprimer",
      pending: () => "Inviter",
      revoked: () => "Révoquer l'accès",
      revokedLink: () => "Révoquer l'accès",
    },
    confirmationDialog: {
      collaborator: {
        accepted: {
          actionText: () => "Accepter l'invitation",
          cancelText: () => "Dos",
          text: () =>
            "Etes-vous sûr de vouloir accepter cette invitation ? Cela vous ajoutera en tant que membre de l'équipe de l'organisation du comptable, où vous pourrez gérer ses clients.",
          title: () => "Accepter l'invitation ?",
        },
        declined: {
          actionText: () => "Refuser l'invitation",
          cancelText: () => "Dos",
          text: () =>
            "Etes-vous sûr de vouloir refuser cette invitation ? Vous ne serez pas ajouté en tant que membre de l'équipe de l'organisation du comptable.",
          title: () => "Refuser l'invitation ?",
        },
        deleted: {
          actionText: () => "Supprimer",
          cancelText: () => "Dos",
          text: () => "Etes-vous sûr de vouloir supprimer cette invitation ?",
          title: () => "Supprimer l'invitation ?",
        },
        pending: {
          actionText: () => "Annuler",
          cancelText: () => "Dos",
          text: () => "Il s'agit d'une invitation en attente",
          title: () => "Invitation en attente",
        },
        revoked: {
          actionText: () => "Révoquer l'accès",
          cancelText: () => "Dos",
          confirmationPrompt: () =>
            "Saisissez 'revoke-accountant-access-forever' pour procéder à la révocation de l'accès.",
          confirmationText: () => "revoke-accountant-access-forever",
          text: () =>
            "Êtes-vous sûr de vouloir révoquer l'accès au profil de votre comptable ? Cela supprimera votre capacité à visualiser ou modifier toute donnée téléchargée via l'ensemble de données de votre comptable. Même si vous avez téléchargé des données vous-même, la propriété peut toujours résider chez votre comptable.",
          title: () => "Révoquer l'accès ?",
        },
      },
      other: {
        accepted: {
          actionText: () => "Accepter l'invitation",
          cancelText: () => "Dos",
          text: () =>
            "Après avoir accepté l'invitation, vous aurez le choix d'afficher les données téléchargées par votre comptable ou celles téléchargées depuis votre propre compte. Si votre comptable n'a téléchargé aucune de vos données, nous vous suggérons de choisir d'afficher les données sur ce compte. Si vous le souhaitez, vous pouvez révoquer cet accès à tout moment, mais les données resteront chez votre comptable et vous reviendrez à vos données d'origine. Êtes-vous sûr de vouloir accepter cette invitation ?",
          title: () => "Accepter l'invitation ?",
        },
        declined: {
          actionText: () => "Refuser l'invitation",
          cancelText: () => "Dos",
          text: () => "Etes-vous sûr de vouloir refuser cette invitation ?",
          title: () => "Refuser l'invitation ?",
        },
        deleted: {
          actionText: () => "Supprimer",
          cancelText: () => "Dos",
          text: () => "Etes-vous sûr de vouloir supprimer cette invitation ?",
          title: () => "Supprimer l'invitation ?",
        },
        pending: {
          actionText: () => "Annuler",
          cancelText: () => "Dos",
          text: () => "Il s'agit d'une invitation en attente",
          title: () => "Invitation en attente",
        },
        revoked: {
          actionText: () => "Révoquer l'accès",
          cancelText: () => "Dos",
          text: () =>
            "Etes-vous sûr de vouloir révoquer votre accès au profil de votre comptable ? Vous ne pourrez plus lire ni modifier les données que votre comptable a téléchargées en votre nom.",
          title: () => "Révoquer l'accès ?",
        },
      },
    },
    noInvites: () =>
      "Vous n'avez pas encore reçu d'invitations de la part des comptables",
    receivedInvitations: () => "Invitations reçues",
    warning: () =>
      "Une erreur s'est produite ! Veuillez contacter le support si le problème persiste.",
    wentWrongGettingInvitations: () =>
      "Une erreur s'est produite lors de la réception des invitations. Veuillez réessayer ultérieurement.",
  },
  invitationStatus: {
    accepted: () => "Accepté",
    declined: () => "Refusé",
    deleted: () => "Supprimé",
    notInvited: () => "Pas invité",
    pending: () => "En attente",
    revoked: () => "Révoqué",
    revokedLink: () => "Révoqué",
  },
  invite: () => "Inviter un client",
  keys: {
    deprecated: () => "Obsolète",
    deprecatedInfo: (d) =>
      d.exchange +
      " a abandonné cette méthode d'authentification. Nous la prenons toujours en charge, mais nous vous recommandons de mettre à jour vos clés conformément aux nouvelles instructions. [Plus d'informations]( " +
      d.linkTextKey +
      " )",
    deprecatedInfoByExchange: {
      coinbase: (d) =>
        d.exchange +
        " a rendu cette méthode d'authentification obsolète. Veuillez mettre à jour vos clés conformément aux nouvelles instructions. [Plus d'informations]( " +
        d.linkTextKey +
        " )",
    },
    wentWrongDeleteExchangeAPI: (d) =>
      "Une erreur s'est produite lors de la tentative de suppression des clés API pour " +
      d.name +
      " . Veuillez réessayer ultérieurement.",
    wentWrongDeletingAPI: () =>
      "Une erreur s'est produite lors de la tentative de suppression de la clé API. Veuillez réessayer ultérieurement.",
    wentWrongSavingAPI: (d) =>
      "Une erreur s'est produite lors de l'enregistrement des clés API " +
      d.name +
      " .",
    wentWrongUpdatingAPI: (d) =>
      "Une erreur s'est produite lors de la mise à jour des clés API " +
      d.name +
      " .",
  },
  labels: {
    addExistingLabels: () => "Ajouter des étiquettes existantes",
    addLabel: () => "Ajouter une étiquette",
    cancel: () => "Annuler",
    createNewLabel: () => "Créer une nouvelle étiquette",
    labelColor: () => "Couleur de l'étiquette",
    labelDescription: () => "Description de l'étiquette (facultatif)",
    labelName: () => "Nom de l'étiquette",
    noLabels: () => "Aucune étiquette créée",
    saveAndAdd: () => "Enregistrer et ajouter",
  },
  language: () => "Langue",
  learnMore: () => "En savoir plus dans le centre d'aide",
  ledger: {
    banner: {
      text: () =>
        "L'historique des achats manquants se produit lorsque le nombre de crypto-actifs vendus est supérieur au nombre d'actifs achetés à l'origine. Cela suggère que certains enregistrements d'acquisition peuvent être manquants, non catégorisés ou classés de manière incorrecte.",
      title: () => "c'est la bannière du grand livre",
    },
    confirmations: {
      deleteTransactionConfirmation: {
        text: () =>
          "Attention ! Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir supprimer cette transaction ?",
        title: () => "Supprimer la transaction",
      },
      ignoreTransactionConfirmation: {
        text: () =>
          "Êtes-vous sûr de vouloir ignorer cette transaction ? Cette action ignorera cette transaction de tous les calculs de taxes. Les transactions ignorées peuvent être masquées dans le tableau des transactions dans les filtres avancés.",
        title: () => "Ignorer la transaction",
      },
    },
    deposit: () => "Dépôt",
    descriptions: {
      amountAcquired: (d) =>
        "La quantité totale de " +
        d.ticker +
        " que vous avez acquise et enregistrée dans l'application.",
      amountDisposed: (d) =>
        "La quantité totale de " +
        d.ticker +
        " que vous avez vendue ou cédée, telle qu'enregistrée dans l'application.",
      amountMissing: (d) =>
        "Le nombre de " +
        d.ticker +
        " non comptabilisés dans vos transactions. Cela peut être dû à des transactions non catégorisées ou à des données manquantes.",
      amountUncategorised: (d) =>
        "Montant de " +
        d.ticker +
        " qui n'a pas encore été catégorisé. Pour des rapports et calculs fiscaux précis, veuillez attribuer des catégories à ces transactions.",
    },
    errors: {
      unableLoadRecon: () => "Impossible de charger le grand livre",
    },
    filters: {
      onlyShowNegativeBalance: () =>
        "Afficher uniquement l'historique des achats manquants",
    },
    goBack: () => "Retourner",
    headings: {
      amountNegative: () => "Montant négatif :",
      holdings: () => "Titres détenus :",
      taxImpact: () => "Impact fiscal :",
      transactions: () => "Transactions:",
      value: () => "Valeur:",
    },
    headings2: {
      amountAcquired: () => "Montant total acquis",
      amountDisposed: () => "Montant total éliminé",
      amountMissing: () => "Montant manquant",
      amountUncategorised: () => "Montant Non classé",
      transactions: () => "Transactions",
    },
    ledger: () => "Grand livre",
    ledgerSourceFilter: {
      accounts: () => "Comptes",
      noOptions: () => "Aucune option",
      placeholder: () => "Afficher tous les comptes",
      selectAll: () => "Sélectionner tout",
    },
    loading: () => "Chargement",
    negativeBalanceOverallTooltip: () =>
      "Cet actif n'a pas d'historique d'achat.",
    negativeBalanceTooltip: (d) =>
      "Il y a eu plus de transactions de vente " +
      d.ticker +
      " que d'achats. Vérifiez les transactions entrantes.",
    newest: () => "Le plus récent",
    notFound: () => "Non trouvé",
    oldest: () => "Le plus ancien",
    pageTitle: (d) => "Grand livre " + d.currencyName,
    tableHeader: {
      account: () => "Compte",
      amount: () => "Montant",
      dateAndType: () => "Date et type",
      overallBalance: () => "Bilan global",
      tooltips: {
        account: () =>
          "Portefeuilles, échanges, données importées ou similaires et le solde de ce compte immédiatement après la transaction.",
        overallBalance: () =>
          "Le solde total de tous vos comptes immédiatement après la transaction.",
      },
    },
    uncategorisedTooltip: () =>
      "Vous devez catégoriser cette transaction pour qu'elle soit comptabilisée dans le solde du grand livre.",
    unmatchedTooltip: () =>
      "Cette transaction ne compte pas dans le solde du grand livre car elle est unilatérale.",
    withdrawal: () => "Retrait",
  },
  legacyPlanTooltip: () =>
    "Vous êtes sur un plan hérité avec 3000 transactions",
  manual: {
    Date: () => "Date",
    addNewTransaction: () => "Ajouter une nouvelle transaction",
    addTransaction: () => "Ajouter une transaction",
    currency: () => "Devise",
    feeCurrency: () => "Devise des frais",
    feeQuantity: () => "Quantité de frais",
    from: () => "Depuis",
    price: () => "Prix",
    quantity: () => "Quantité",
    quoteCurrency: () => "Devise de cotation",
    quoteQuantity: () => "Quantité de devis",
    receiveCurrency: () => "Acheter des devises",
    receiveQuantity: () => "Acheter la quantité",
    requiredText: {
      currency: () => "Vous devez entrer une devise",
      feeCurrency: () => "Vous devez saisir une devise de frais",
      feeQuantity: () => "Vous devez saisir un montant de frais",
      notInLockedPeriod: () =>
        "La date ne doit pas être dans la période verrouillée",
      price: () => "Vous devez entrer un prix",
      quantity: () => "Vous devez entrer une quantité",
      time: (d) =>
        "Vous devez saisir une heure valide au format " + d.timeFormat,
      value: () => "Vous devez entrer une valeur",
    },
    sellCurrency: () => "Vendre des devises",
    sellQuantity: () => "Vendre la quantité",
    stepTitle: {
      category: () => "Catégorie de transaction",
      details: (d) =>
        select(d.category, {
          trade: " Détails de la transaction ",
          transfer: " Détails du transfert ",
          other: " Détails de la transaction ",
        }),
      fees: () => "Frais",
      sourceAndDest: () => "Source et destination",
    },
    time: () => "Temps",
    to: () => "À",
    trade: () => "Catégorie",
    transactionType: {
      other: () => "Autre",
      trade: () => "Commerce",
      transfer: () => "Transfert",
    },
    transactionTypeDescription: {
      other: () => "Toutes les autres transactions",
      trade: () => "C'est ici que vous vendez une devise contre une autre",
      transfer: () =>
        "C'est ici que vous effectuez des transferts entre vos propres portefeuilles/échanges",
    },
    value: () => "Valeur",
  },
  manualCSV: {
    addCustomCSV: () => "Ajouter un CSV personnalisé",
    advanced: {
      link: () =>
        "https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-manual-csv-import/",
      part1: () =>
        "Pour les transactions avancées où les frais n'ont pas encore été appliqués avec les données de trading, consultez notre ",
      part2: () => "guide CSV avancé.",
    },
    advancedOptions: () => "Options avancées",
    cryptoGroup: () => "Crypto",
    dateFormatHelper: () =>
      "Sélectionnez le format de date qui apparaît sur votre fichier CSV. Si vous ne le spécifiez pas, le format de date par défaut sera AAAA/MM/JJ HH:mm:ss.",
    example: () => "Télécharger un exemple de format CSV",
    exchange: () => "Échange",
    exchangeRule: () => "Vous devez entrer un échange",
    fiatGroup: () => "Décret",
    here: () => "ici",
    importFromDateHelper: () =>
      "Sélectionnez la date à partir de laquelle vous souhaitez commencer à importer les transactions. Si non spécifié, toutes les transactions seront importées.",
    importHelper: {
      helpText: () => "Besoin d'aide pour importer des données ?",
      link: () =>
        "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
    },
    manualGroup: () => "Manuel",
    manualSelectCurrencies: () => "Attribuer manuellement des devises",
    manualSelectDateFormat: () => "Sélectionner manuellement le format de date",
    manualSelectImportFromDate: () =>
      "Sélectionner manuellement la date d'importation",
    manualSelectTimezone: () => "Sélectionner manuellement le fuseau horaire",
    nameExists: () =>
      "Le nom existe déjà, pour le télécharger sur cet échange, veuillez le sélectionner à partir de la page d'importation",
    nameTooLong: () => "Nom trop long",
    noOptions: () => "Aucune option",
    patternRule: () =>
      "Seuls les caractères alphanumériques et les espaces sont autorisés",
    pendingUploadMessage: {
      manyFiles: (d) => d.numFiles + " fichiers à télécharger",
      singleFile: (d) => "1 fichier ( " + d.filename + " ) à télécharger",
    },
    preparationWarning: () =>
      "Les données CSV nécessitent une préparation avant le téléchargement",
    selectCurrencies: () => "Sélectionnez les devises",
    selectDateFormat: () => "Sélectionnez le format de date",
    simple: {
      link: () => "https://cryptotaxcalculator.io/advanced.csv",
      part1: () => "Télécharger un exemple de fichier CSV",
      part2: () => " pour voir un exemple du format requis.",
    },
    tickerHelper: () =>
      "Sélectionnez les tickers qui apparaissent sur votre fichier CSV et que vous souhaitez attribuer à une devise spécifique. Par exemple, si vous souhaitez attribuer le ticker « BTC » à « Bitcoin », sélectionnez « Bitcoin (BTC) » dans la liste déroulante ci-dessous. Tous les tickers non spécifiés seront attribués en fonction de leur classement par capitalisation boursière, dans la mesure du possible.",
    timezoneHelper: () =>
      "Sélectionnez le fuseau horaire dans lequel les transactions ont eu lieu. Si vous ne le spécifiez pas, le fuseau horaire sera automatiquement détecté.",
    title: () => "Importer des données par CSV",
    uploadFileWarning: () => "Veuillez télécharger un fichier CSV",
  },
  markAsReviewed: () => "Marquer comme révisé",
  markAsUnreviewed: () => "Marquer comme non révisé",
  mobileDemandTest: {
    buttonText: () => "Obtenir l'application",
    chip: () => "S'inscrire pour la BÊTA",
    description: () =>
      "Inscrivez-vous et soyez parmi les premiers à obtenir un accès anticipé à l'application CTC – rendant la fiscalité crypto plus simple que jamais.",
    noThanks: () => "Non merci",
    registerInterest: () => "Manifester son intérêt",
    title: () => "Soyez parmi les premiers à obtenir l'application",
    toast: {
      description: () => "Surveillez votre boîte de réception",
      title: () => "Vous avez manifesté votre intérêt",
    },
  },
  mockReport: {
    disabled: () => "Désactivé pour les données de test",
    infoBanner: {
      button: () => "Afficher les données d'exemple",
      subtext: () =>
        "Vous pouvez consulter les données de test avant de choisir un plan tarifaire.",
      title: () => "Afficher les données d'exemple",
    },
    signup: {
      button: (d) =>
        select(d.isAccountant, {
          true: " Mettre à niveau le client ",
          other: " Commencer ",
        }),
      errorMessage: () =>
        "Une erreur s'est produite lors de la mise à niveau du client. Veuillez contacter le support",
      subtext: (d) =>
        select(d.isAccountant, {
          true: select(d.txCount, {
            0: "Ce client n'a actuellement aucun plan payant. Afin de générer un rapport fiscal, vous devrez acheter un plan 👉",
            other:
              "Ce client a actuellement " +
              d.txCount +
              " " +
              plural(d.txCount, 0, fr, {
                1: "transaction",
                other: "transactions",
              }) +
              " importé. Afin de générer un rapport fiscal, vous devrez mettre à niveau ce client vers le plan " +
              d.suggestedPlan +
              " 👉",
          }),
          other:
            "Choisissez l'un de nos plans pour afficher vos données et télécharger les rapports fiscaux.",
        }),
      title: (d) =>
        select(d.isAccountant, {
          true:
            " Voulez-vous générer un rapport fiscal pour " +
            d.clientName +
            " ? ",
          other: " Générez votre rapport fiscal ",
        }),
    },
    testData: () => "Données de test",
  },
  na: () => "N / A",
  name: () => "Nom",
  nav: {
    billing: () => "Facturation",
    contacts: () => "Contacts",
    developer: () => "Promoteur",
    imports: () => "Importer des données d'échange",
    logout: () => "Déconnexion",
    manageSubscription: () => "Gérer l'abonnement",
    manual: () => "Manuel d'importation CSV",
    menu: () => "Menu",
    plans: () => "Plans",
    report: () => "Voir le rapport fiscal",
    settings: () => "Paramètres",
    transactions: () => "Examiner les transactions",
    wallet: () => "Importer les données du portefeuille",
    warnings: () => "Avertissements",
  },
  navbar: {
    accountingIntegrations: () => "Intégrations comptables",
    addressBook: () => "Carnet d'adresses",
    clientSettings: () => "Paramètres du client",
    clients: () => "Clients",
    cryptoIntegrations: () => "Intégrations cryptographiques",
    dashboard: () => "Tableau de bord",
    followUsOnX: () => "Suivez-nous sur X",
    footer: {
      buttonCta: () => "Débloquez des économies d'impôts",
      referralXOffAllPlans: (d) =>
        "Parrainage " + d.discount + "% de réduction sur tous les forfaits",
      xPercentOffAllPlans: (d) =>
        d.discount + "% de réduction sur tous les forfaits",
      youHaveXTransactions: (d) => "Vous avez " + d.count + " transactions",
    },
    help: () => "Accéder au centre d'aide",
    helpCenter: () => "Centre d'aide",
    imports: () => "Importations",
    integrations: () => "Comptes",
    more: () => "Plus",
    myClients: () => "Mes clients",
    mySettings: () => "Paramètres de l'espace de travail",
    myTeam: () => "Mon équipe",
    portfolio: () => "Portefeuille",
    reconciliation: () => "Réconciliation",
    referAFriend: () => "Parrainer un ami",
    referFriends: () => "Invitez un ami - Obtenez 40 $",
    referFriendsV2: (d) => "Parrainer et gagner " + d.reward,
    referGetHundredCash: (d) => "Obtenez " + d.reward + " en espèces",
    report: () => "Rapports",
    review: () => "Revoir",
    rules: () => "Règles",
    selectClient: () => "Sélectionner un client",
    settings: () => "Paramètres",
    subheadings: {
      integrations: () => "Comptes",
      reconciliation: () => "Réconciliation",
      reports: () => "Rapports",
    },
    subscribe: () => "S'abonner",
    support: () => "Discuter avec l'assistance",
    tips: () => "Conseils",
    title: () => "CTC",
    tooltip: (d) =>
      "[Abonnez-vous]( " +
      d.linkTextKey +
      " ) pour voir des conseils sur la façon d'économiser sur votre déclaration de revenus",
    tooltipReview: (d) =>
      "[Abonnez-vous]( " +
      d.linkTextKey +
      " ) pour consulter vos transactions et économiser sur votre déclaration de revenus",
    transactionSubItems: {
      failed: () => "Échoué",
      notReadyToSync: () => "Pas prêt à synchroniser",
      outOfDate: () => "Obsolète",
      readyToSync: () => "Prêt à synchroniser",
      synced: () => "Synchronisé",
    },
    transactions: () => "Transactions",
  },
  new: () => "Nouveau",
  newTxModal: {
    actionButton: () => "Vérifiez-le!",
    back: () => "Dos",
    description: {
      0: () =>
        "Le tableau des transactions a été amélioré pour permettre une compréhension rapide et facile des données",
      1: () =>
        "Lorsque vous catégorisez une transaction, nous identifions désormais les transactions similaires à appliquer en masse afin que vous n'ayez pas à vous répéter",
      2: () =>
        "Nous avons remplacé la page de réconciliation par une nouvelle version simplifiée appelée « Conseils » qui est disponible pour les abonnés actifs",
      3: () =>
        "Notre algorithme d'automatisation a été mis à niveau afin qu'il puisse désormais catégoriser plus intelligemment les transactions complexes en chaîne",
    },
    nextPage: () => "Suivant",
    title: () => "Nouvelle table de transactions et plus encore !",
  },
  next: () => "Suivant",
  no: () => "Non",
  noResults: () => "Aucun résultat",
  none: () => "Aucun",
  notifications: {
    missingBlockchain: {
      blogLink: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6388387-how-to-resolve-missing-blockchain-warnings",
      cta: () => "Résoudre",
      link: () => "/transactions?avertissement=manquantBlockchain",
      primary: () => "Blockchain manquante",
      secondary: () =>
        "Examiner les transactions pour lesquelles aucune blockchain n’a été trouvée.",
    },
    missingPrice: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/missing-market-prices",
      cta: () => "Résoudre",
      link: () => "/transactions?warning=missingPrice",
      primary: () => "Prix du marché manquants",
      secondary: () =>
        "Examiner et mettre à jour les transactions pour lesquelles aucun prix de marché n’a été trouvé.",
    },
    negativeBalance: {
      blogLink: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
      cta: () => "Résoudre",
      link: () => "/transactions?warning=negativeBalance",
      primary: () => "Historique d'achat manquant",
      secondary: () =>
        "Examinez les transactions qui entraînent un solde négatif.",
    },
    uncategorised: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/cryptocurrency-tax-categories",
      cta: () => "Résoudre",
      link: () => "/transactions?warning=uncategorised",
      primary: () => "Transactions non classées",
      secondary: () =>
        "Recatégorisez vos transactions pour améliorer la précision de la calculatrice.",
    },
    zeroCostBuy: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/negative-balance-remaining-warnings",
      blogLink2: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
      cta: () => "Résoudre",
      link: () => "/transactions?warning=negativeBalance",
      primary: () => "Achats à coût nul",
      secondary: () =>
        "Examinez les transactions de vente pour lesquelles vous avez un prix de base nul en raison d'un historique d'achats antérieurs manquant et non résolu. Cela peut entraîner des gains déclarés plus élevés qu'ils ne devraient l'être.",
    },
  },
  onboardingV2: {
    LetsReviewTx: {
      body1: () => "Cela ne devrait-il pas être automatisé, dites-vous ?",
      body2: () =>
        "Oui, la plupart de vos transactions sont automatiquement catégorisées, mais dans certains cas, la catégorie ne peut pas être déterminée. Pour garantir l'exactitude, nous les signalons afin que vous puissiez les clarifier.",
      cta: () => "Continuer",
      title: () => "OK, passons en revue certaines de vos transactions",
    },
    accountConfirmation: {
      CTA: () => "Continuer l'importation",
      nonCTA: () => "Passer à l'étape suivante",
      subtitle: () =>
        "Un historique complet des transactions est nécessaire pour garantir l’exactitude des chiffres.",
      title: (d) =>
        "Vous avez " +
        plural(d.count, 0, fr, {
          one: " ajouté seulement 1 compte ",
          other: " ajouté " + number("fr", d.count, 0) + " comptes ",
        }) +
        " !",
      title2: () => "Êtes-vous sûr d’avoir tout importé ?",
    },
    accountCountry: {
      plusMore: (d) => "+ " + d.count + " de plus",
      showLess: () => "Afficher moins",
      showMore: () => "Afficher plus",
      subtitle: () =>
        "Cela détermine les règles fiscales par défaut et la devise locale.",
      title: () => "Choisissez votre juridiction fiscale",
    },
    accountList: {
      addAnotherAccount: () => "Ajouter un autre compte",
      yourAccounts: () => "Vos comptes",
    },
    accountTheme: {
      footerButton: () => "Suivant",
      subtitle: () => "Vous pourrez toujours le modifier plus tard.",
      themes: {
        dark: () => "Mode sombre",
        light: () => "Mode lumière",
        system: () => "Mode système",
      },
      title: () => "Quel thème préférez-vous ?",
    },
    accountType: {
      footerButton: () => "Suivant",
      securityCta: () => "Sécurité et confidentialité des données",
      subtitle: () => "Quel type d'utilisateur êtes-vous ?",
      title: () =>
        "Bienvenue sur le calculateur d'impôts sur les crypto-monnaies 👋",
      types: {
        accountant: () => "Comptable",
        individual: () => "Individuel",
      },
    },
    accuracy: {
      content: {
        accounts: () => "comptes",
        categorise: () => "Catégorisez vos transactions",
        complete: () =>
          "Un historique complet de toutes vos transactions doit être fourni. Cela permet à notre algorithme de calcul des impôts de faire correspondre automatiquement les transactions sur vos comptes, minimisant ainsi le travail requis de votre part et garantissant l'exactitude des gains calculés.",
        considerations: () => "Considérations clés :",
        costBase: () => "base de coût",
        determine: () => "Afin de déterminer avec précision votre",
        ensure: () =>
          "La plupart des transactions sont automatiquement catégorisées. Dans certains cas, il n'est pas possible de déterminer la catégorie appropriée. La catégorisation de vos transactions garantit que le résultat fiscal approprié est appliqué.",
        import: () => "Importez tous vos ",
        inactive: () => " même ceux que vous n’utilisez plus activement.",
        noAccess: () =>
          "Notez que CTC n'a jamais accès aux fonds des utilisateurs. Les importations sont strictement limitées aux informations de transaction nécessaires au calcul des taxes.",
      },
      footerBack: () => "Dos",
      footerNext: () => "Suivant",
      subtitle: () => "Produire des rapports fiscaux précis",
      title: () => "Comment obtenir des rapports fiscaux précis",
    },
    areYouSure: {
      body1: {
        costBasis: () => "base de coût",
        costBasisTooltip: () =>
          "Le prix initial que vous avez payé pour l'actif, plus les frais de transaction",
        frontPart: () =>
          "Un historique complet de vos transactions améliorera considérablement la précision des chiffres. Vous permettant de suivre avec précision vos ",
        lastPart: () => " sur l’ensemble de vos actifs.",
      },
      body2: {
        accounts: () => "comptes,",
        accountsTooltip: () =>
          "Portefeuilles et échanges que vous utilisez pour effectuer des transactions avec des crypto-monnaies.",
        frontPart: () =>
          "Ceci est particulièrement important lors du transfert d’actifs entre ",
        lastPart: () =>
          " par exemple, lors de l'envoi de crypto-monnaies d'un exchange vers un autre portefeuille. Si cet exchange n'a pas été importé, nous ne pourrons pas déterminer le prix de base de cet actif.",
      },
      buttons: {
        doneImport: () => "Je suis sûr d'avoir tout importé",
        importMore: () => "Laissez-moi en importer quelques autres",
      },
      footer: () =>
        "Vous pouvez toujours ajouter d'autres portefeuilles plus tard, mais nous vous recommandons de le faire maintenant pour garantir une expérience fluide.",
      title: () => "Êtes-vous sûr d’avoir importé tous vos comptes ?",
    },
    calculating: {
      autoSaved: () => "La progression est enregistrée automatiquement",
      buttons: {
        continue: () => "Continuer",
        finish: () => "Finition",
        goBack: () => "Retourner",
        importMore: () => "Importer plus",
        skip: () => "Entrez l'application",
      },
      loading10Minutes: () =>
        "Wow, on dirait que vous avez beaucoup de transactions ! Cela peut prendre un certain temps, mais nous nous en occupons. Nous vous enverrons un e-mail dès que l'importation sera terminée.",
      loading3Minutes: () =>
        "L'importation de vos transactions peut prendre un certain temps, pendant que vous attendez, continuez à configurer votre compte.",
      loadingETA: () => "Cela prend généralement 2 à 3 minutes",
      loadingETAFake: () => "Cela ne prendra pas longtemps",
      loadingTakingLongerThanUsual: () =>
        "Cela prend plus de temps que d'habitude. Je suis encore en train de traiter ces chiffres.",
      loadingText: {
        calculatingTheCostBaseForEachAsset: () =>
          "Calcul du prix de base de chaque actif",
        categorisingYourTransactions: () => "Catégoriser vos transactions",
        categorizingBridges: () => "Détection de ponts",
        categorizingStaking: () => "Détection du jalonnement",
        categorizingSwaps: () => "Détection des swaps",
        categorizingTrades: () => "Détection des transactions",
        categorizingTransfers: () => "Détection des transferts",
        computingGainsForEachTransaction: () =>
          "Calcul des gains pour chaque transaction",
        detectingAnyCryptoIncome: () => "Détecter tout revenu cryptographique",
        finalizingInitialTaxReports: () =>
          "Finalisation des déclarations fiscales initiales",
        importingTransactionData: () =>
          "Importation des données de transaction",
        summarizingCapitalGainByTaxYear: () =>
          "Récapitulatif des gains en capital par année d'imposition",
      },
      titleCalculationsComplete: () => "Calculs terminés !",
    },
    cexImportReminder: {
      bodyText: () =>
        "Il est important d'importer tous vos portefeuilles, y compris tous les échanges centralisés que vous avez utilisés. Si nous ne disposons pas de toutes vos activités de trading, les chiffres ne seront pas corrects.",
      modalText: () =>
        "Par exemple, certains échanges que vous avez peut-être utilisés et que vous devez importer sont :",
      primaryButton: () => "Importer un échange",
      secondaryButton: () => "Continuer sans importer",
      title: () => "N'oubliez pas d'importer vos échanges centralisés",
    },
    coinbaseOauth: {
      import: {
        connectCoinbase: () => "Connectez-vous à Coinbase",
        continue: () => "Continuer",
        importedSubtitle: () => "Continuez à configurer votre compte.",
        importedTitle: () => "Transactions Coinbase importées",
        importingBanner: () =>
          "L'importation de vos transactions Coinbase peut prendre un certain temps, pendant que vous attendez,",
        importingBannerLink: () => "Continuez à configurer votre compte",
        importingSubtitle: () =>
          "En attendant, continuons à configurer votre compte.",
        importingTitle: () => "Nous importons vos transactions",
        skipConnectCoinbase: () => "Vous n'avez pas de compte Coinbase ?",
        skipConnectCoinbaseCTA: () => "Continuer sans connecter Coinbase",
        subtitle: () => "Connectons votre compte",
        title: () => "Connectez votre compte Coinbase",
      },
      welcomeTitle: () => "Bienvenue utilisateur de Coinbase 👋",
    },
    competitorSurvey: {
      options: {
        no: () => "Non",
        yes: () => "Oui",
      },
      title: () => "Avez-vous déjà utilisé un autre logiciel de crypto-impôt ?",
    },
    example: () => "Exemple",
    import: {
      autoImported: () => "Importé automatiquement",
      footer: () => "+ plus de 2 900 intégrations supplémentaires.",
      footerCTA: () => "Recherchez le vôtre",
      hasSavedAccounts: {
        finishImport: () => "J'ai ajouté tous mes comptes",
        keepAdding: () => "Continuez à ajouter vos comptes",
        or: () => "ou",
        subTitle: () =>
          "Assurez-vous d’ajouter tous vos échanges et portefeuilles afin que les chiffres s’additionnent !",
        title: () => "👉 Ajoutez votre prochain compte",
      },
      importManualCSV: () => "Manuel d'importation CSV",
      navBar: {
        importType: {
          api: () => "API",
          "bulk-wallet": () => "Portefeuille en vrac",
          "connect-wallet": () => "Connecter le portefeuille",
          csv: () => "CSV",
          oauth: () => "Connecter",
          "soft-wallet": () => "Connecter",
          wallet: () => "API",
        },
      },
      searchBar: {
        importWallet: () => "Importer un portefeuille",
        placeholder: () =>
          "Recherchez votre échange ou collez votre portefeuille",
        section: {
          allOthers: () => "Tous les échanges et portefeuilles",
          manual: () => "Manuel",
          popular: () => "Populaire",
        },
      },
      subTitle: () =>
        "Recherchez votre échange ou collez l'adresse de votre portefeuille 👇 ",
      title: () => "Ajoutez votre premier compte",
    },
    metamask: {
      accurateCpaEndorsed: () =>
        "Des rapports fiscaux précis, approuvés par un CPA, sans aucune incertitude",
      accurateCryptoTaxes: () =>
        "Impôts sur les cryptomonnaies précis.\nSans approximation.",
      continueWith: (d) => "Continuer avec " + d.provider,
      cryptoTaxCalculator: () => "Calculateur de taxes sur les crypto-monnaies",
      cta: () => "Continuer",
      disclaimer: () =>
        "*Réservé aux nouveaux clients du calculateur de taxes sur les crypto-monnaies. La remise s'applique uniquement à la première année.",
      fullSupportIRS: () =>
        "Prise en charge complète des règles de l'IRS. Importez directement dans TurboTax ou H&R Block, ou partagez en toute sécurité avec votre comptable.",
      manageCryptoTaxes: () =>
        "Gérez vos taxes crypto directement depuis l'endroit où vous négociez.",
      newCustomersDiscount: () =>
        "Les nouveaux clients bénéficient de 30 % de réduction*",
      partnerAlt: () => "Partenaire officiel CryptoTaxCalculator x Metamask",
      previewAlt: () => "Aperçu du Tax Hub",
      skip: () => "Ou utiliser email et mot de passe",
      supportsAllWallets: () =>
        "Prend en charge tous vos portefeuilles, échanges et activités en chaîne.",
      taxhubPoweredBy: () => "Centre fiscal propulsé par",
    },
    onMobileBanner: {
      title: () =>
        "Il semble que vous soyez sur mobile, pour une meilleure expérience, connectez-vous depuis votre ordinateur de bureau !",
    },
    onYourWay: {
      title: () =>
        "Super, vous êtes sur le point d'obtenir votre rapport d'impôts !",
      viewPlans: () => "Voir les plans",
      viewReports: () => "Voir les rapports",
    },
    platformTypes: {
      cex: () => "Échanges cryptographiques centralisés",
      count: (d) => "+ " + d.count + " de plus",
      defi: () => "Activité on-chain (DeFi et NFT)",
      footerBack: () => "Dos",
      footerNext: () => "Suivant",
      footerSkip: () => "Sauter",
      subtitle: () =>
        "Personnalisez votre expérience en fonction du type de crypto trader que vous êtes :",
      title: () =>
        "Sur quels types de plateformes avez-vous effectué des transactions ?",
    },
    progressBar: {
      confirmCountry: () => "Confirmer le pays",
      freeTaxPreview: () => "Aperçu fiscal gratuit",
      importData: () => "Importer des données",
      selectIntegration: () => "Sélectionner l'intégration",
    },
    provider: {
      connect: {
        "2fa": () => "Entrez votre code 2FA",
        cta: () => "Connectez-vous et associez votre compte",
        disclaimer: (d) =>
          "En vous connectant, vous lierez votre compte " +
          d.provider +
          " avec Crypto Tax Calculator, ce qui vous permettra de vous connecter en 1 clic depuis Independent Reserve.",
        email: () => "E-mail",
        password: () => "Mot de passe",
        subtitle: () =>
          "Il semble que vous ayez déjà un compte Crypto Tax Calculator sous cette adresse e-mail.",
        title: () => "Connectez-vous pour continuer",
      },
      setPassword: {
        cta: () => "Enregistrer le mot de passe",
        email: () => "E-mail",
        password: () => "Mot de passe",
        passwordsDoNotMatch: () => "Les mots de passe ne correspondent pas",
        retypePassword: () => "Retaper le mot de passe",
        title: () => "Définissez votre mot de passe",
      },
      setPasswordSuccess: {
        cta: () => "Continuer",
        description: () =>
          "Utilisez ce mot de passe pour vous connecter à cryptotaxcalculator.io",
        providerLoginNote: (d) =>
          "Remarque : Vous pouvez également vous connecter à tout moment en 1 clic depuis votre compte " +
          d.provider +
          ".",
        title: () => "Mot de passe défini !",
      },
      syncing: {
        bannerCTA: () => "Continuez à configurer votre compte",
        bannerText: (d) =>
          "L'importation de vos transactions " +
          d.provider +
          " peut prendre un certain temps, pendant que vous attendez,",
        completeTitle: () => "Transactions importées",
        getTaxReportCta: () => "Obtenir un rapport fiscal",
        inProgressTitle: () => "Nous importons vos transactions",
        viewTaxSummaryCta: () => "Voir le résumé fiscal",
      },
      welcome: {
        cta: () => "Continuer",
        description: () =>
          "Crypto Tax Calculator est une plateforme tout-en-un pour calculer vos impôts sur les crypto-monnaies. Nous préparerons des rapports fiscaux qui incluent vos :",
        items: {
          0: () => "Des gains à long terme",
          1: () => "Gains à court terme",
          2: () => "Revenu",
          3: () => "Dépenses",
          4: () => "Déductions",
        },
        title: () =>
          "Bienvenue sur le calculateur de taxes sur les crypto-monnaies",
      },
    },
    security: {
      buttonText: () => "Sécurité et confidentialité des données",
      privacy: {
        description: () =>
          "Nous ne vous demanderons jamais les clés privées de vos portefeuilles cryptographiques. Nos intégrations ne nécessitent qu'un accès en lecture à vos données et toutes les clés API sont stockées et cryptées en toute sécurité en transit et au repos",
        title: () => "Confidentialité totale des données",
      },
      soc2: {
        description: () =>
          "Nous sommes certifiés SOC 2 Type 2, avec des mécanismes sécurisés en place pour assurer la sécurité de vos données.",
        title: () => "Certifié SOC 2 Type 2",
      },
      subtitle: () => "Nous prenons la sécurité des données très au sérieux",
      title: () => "Vos données sont en sécurité",
    },
    skipAndEnterApp: () => "Sauter et entrer dans l'application",
    taxCalculator: {
      buttons: {
        addAnotherAccount: () => "Ajouter un autre compte",
        back: () => "Dos",
        getTaxReport: () => "Obtenir un rapport fiscal",
      },
      disclaimer: () =>
        "Il s'agit d'une estimation basée sur les données importées jusqu'à présent. Une image complète de toutes vos transactions depuis votre premier achat de crypto-monnaie est nécessaire pour obtenir un résultat précis",
      estimatedGain: () => "Gain estimé",
      heading: () => "Estimation des gains en capital",
      income: () => "Revenu",
      tooltips: {
        estimatedGain: () =>
          "Le gain estimé représente vos revenus totaux provenant de la crypto-monnaie, il est calculé comme Gains en capital + Revenus - Pertes en capital.",
        gains: () =>
          "Le bénéfice que vous avez réalisé grâce à des transactions qui ont généré un gain. (Transactions sur lesquelles vous avez gagné de l'argent)",
        income: () =>
          "Revenus provenant d'activités cryptographiques, généralement provenant de récompenses de jalonnement",
        losses: () =>
          "Les pertes que vous avez subies suite à des transactions. (Transactions sur lesquelles vous avez perdu de l'argent)",
        txCount: () => "Le nombre de transactions sur vos comptes",
      },
      tradingGains: () => "Gains commerciaux",
      tradingLosses: () => "Pertes commerciales",
      transactions: (d) => d.count + " transactions",
    },
    taxableStaking: {
      content: {
        added: () => "Le 98,34 doit ensuite être ajouté à votre",
        assessableIncome: () => "revenu imposable",
        at: () => "à",
        breakdown: () => "En résumé :",
        rewards: () => "Le contrat vous récompense avec 0,001 ETH chaque mois",
        value: () =>
          "La valeur de la récompense est déterminée par la valeur marchande de l'actif lorsque vous l'avez reçu",
        year: () => "pour l'année",
        youStake: () => "Vous misez 1 Ethereum dans un contrat de jalonnement",
      },
      footerBack: () => "Dos",
      footerNext: () => "Suivant",
      headings: {
        assessableIncome: () => "Revenu imposable",
        stakingContract: () => "Contrat de jalonnement",
        stakingRewards: () => "Récompenses de jalonnement",
        yourSalary: () => "Votre salaire",
      },
      subtitle: () => "Guide fiscal sur les crypto-monnaies pour :",
      title: () => "Les récompenses de jalonnement sont des revenus",
    },
    taxableTrades: {
      content: {
        andYour: () => "Et ton",
        breakdown: () => "En résumé :",
        capitalGain: () => "gain en capital",
        costBase: () => "base de coût",
        for: () =>
          "pour vos 5 Ethereum que vous avez acquis, c'est maintenant 20 000 $",
        is15k: () => "est de 15 000 $",
        is5k: () => "est de 5 000 $",
        marketValue: () => "valeur marchande",
        the: () => "Le",
        youTrade: () => "Vous échangez 1 Bitcoin contre 5 Ethereum",
        your: () => "Ton",
        yourBitcoin: () => "pour votre 1 Bitcoin vaut 20 000 $ et le",
      },
      footerBack: () => "Dos",
      footerNext: () => "Suivant",
      headings: {
        capitalGain: () => "Gain en capital",
        costBase: () => "Base de coûts",
        marketValue: () => "Valeur marchande",
      },
      subtitle: () => "Guide fiscal sur les crypto-monnaies pour :",
      title: () => "Les échanges de crypto à crypto sont imposables",
    },
    tooltip: {
      accounts: () =>
        "Portefeuilles et échanges que vous utilisez pour effectuer des transactions avec des crypto-monnaies.",
      assessableIncome: () =>
        "Revenu gagné qui est imposable. Par exemple, votre salaire.",
      capitalGain: () =>
        "Le montant réalisé après la vente d'un actif et déduction du prix de base. ",
      costBase: () =>
        "Le prix initial que vous avez payé pour l'actif, plus les frais de transaction",
      marketValue: () => "La valeur en temps réel de l'actif.",
    },
    tradingTypes: {
      footerBack: () => "Dos",
      footerNext: () => "Suivant",
      footerSkip: () => "Sauter",
      subtitle: () =>
        "Personnalisez votre expérience en fonction du type de crypto trader que vous êtes :",
      title: () => "Quels types d’activités de trading avez-vous effectués ?",
      types: {
        airdrop: () => "Parachutages aériens",
        liquidityPool: () => "Pools de liquidité",
        nft: () => "NFT",
        other: () => "Autre",
        staking: () => "Jalonnement",
        trading: () => "Commerce",
      },
    },
    userValueSurvey: {
      options: {
        accurateReport: () => "Obtenir un rapport précis",
        auditProtection: () => "Protection contre un audit",
        minimiseTax: () => "Réduire ma facture d'impôt",
        other: () => "Autre",
        simplifyTax: () => "Simplifier les taxes sur les crypto-monnaies",
        timeSave: () => "Gagnez du temps dans la préparation de vos impôts",
      },
      title: () =>
        "Quel résultat compte le plus pour vous lorsque vous utilisez une solution fiscale cryptographique ?",
    },
    viewAssociatedTransaction: (d) =>
      "Afficher les " +
      plural(d.txCount, 0, fr, {
        0: " transactions ",
        one: " transaction ( " + number("fr", d.txCount, 0) + " ) ",
        other: " transactions ( " + number("fr", d.txCount, 0) + " ) ",
      }),
    wallets: {
      address: () => "Adresse",
      blockchain: () => "Blockchain",
      continue: () => "Continuer",
      importTransactions: () => "Opérations d'importation",
      subtitle: () =>
        "Nous allons importer les transactions sur les portefeuilles suivants",
      title: () => "Salut les utilisateurs de MetaMask",
    },
  },
  organisation: {
    wentWrongGettingDashboard: () =>
      "Une erreur s'est produite lors de l'obtention de votre tableau de bord. Veuillez réessayer ultérieurement.",
  },
  outTxCategoryOptionsTitle: () => "Transactions sortantes",
  pageFailed: () =>
    "Une erreur s'est produite. Veuillez essayer de recharger la page.",
  pageTitle: {
    AccountingIntegrations: () => "Intégrations comptables",
    Alpha: () => "Fonctionnalités Alpha",
    BalanceLedger: () => "Grand livre de solde",
    Billing: () => "Facturation",
    Clients: () => "Clients",
    ClientsAdd: () => "Ajouter un client",
    Close: () => "Fermer",
    ConnectProvider: () => "Fournisseur de connexion",
    Contacts: () => "Contacts",
    ContactsIdentified: () => "Contacts identifiés",
    ContactsUnidentified: () => "Contacts non identifiés",
    Dashboard: () => "Portefeuille",
    Developer: () => "Drapeaux de fonctionnalité",
    DeveloperEditorIntegration: () => "Accéder au code",
    DeveloperEntities: () => "Entités",
    DeveloperReport: () => "Actualiser le rapport",
    DeveloperSeedData: () => "Données sur les semences",
    DeveloperSessionRecording: () => "Enregistrements de sessions",
    DeveloperUserData: () => "Données utilisateur",
    Forgot: () => "Mot de passe oublié",
    ImportCustom: () => "Compte personnalisé",
    ImportNew: () => "Ajouter un compte",
    ImportSearch: () => "Rechercher des comptes",
    Imports: () => "Comptes",
    Invite: () => "Inviter",
    Ledger: () => "Grand livre",
    Login: () => "Se connecter",
    Onboarding: () => "Intégration",
    OnboardingAccuracy: () => "Précision",
    OnboardingAreYouSure: () => "Es-tu sûr",
    OnboardingCompetitorSurvey: () => "Autres logiciels fiscaux",
    OnboardingEmbededOfferIntro: () => "Accueillir",
    OnboardingImportCEXReminder: () => "Rappel d'échange centralisé",
    OnboardingImports: () => "Comptes",
    OnboardingImportsCalculating: () => "Calcul de l'importation",
    OnboardingImportsCustom: () => "Comptes personnalisés",
    OnboardingImportsNew: () => "Nouveau compte",
    OnboardingImportsOauth: () => "Comptes OAuth",
    OnboardingImportsSearch: () => "Rechercher des comptes",
    OnboardingOnYourWay: () => "Sur ton chemin",
    OnboardingPayment: () => "Paiement",
    OnboardingPaymentStatus: () => "Statut de paiement",
    OnboardingPaymentSuccess: () => "Paiement réussi",
    OnboardingPlatformTypes: () => "Types de plateformes",
    OnboardingProviderPasswordSetSuccess: () => "Mot de passe défini",
    OnboardingProviderSetPassword: () => "Définir un mot de passe",
    OnboardingProviderSyncing: () => "De liaison",
    OnboardingProviderWelcome: () => "Accueillir",
    OnboardingReconUncategorisedTransactions: () =>
      "Transactions non catégorisées",
    OnboardingSelectCountry: () => "Sélectionnez un pays",
    OnboardingSelectTheme: () => "Sélectionnez le thème de couleur",
    OnboardingSelectType: () => "Sélectionnez le type de compte",
    OnboardingTaxSettings: () => "Paramètres fiscaux de l'ATO",
    OnboardingTaxableStaking: () => "Jalonnement taxable",
    OnboardingTaxableTrades: () => "Les métiers taxables",
    OnboardingTradingTypes: () => "Types de trading",
    OnboardingUserValueSurvey: () => "Important pour vous",
    OnboardingWallets: () => "Portefeuilles",
    OptInSupportAccess: () => "Accès au support opt-in",
    PartnerBenefit: () => "Avantage partenaire",
    Payment: () => "Paiement",
    PaymentPending: () => "Paiement en attente",
    PaymentStatus: () => "Statut de paiement",
    PaymentSuccess: () => "Paiement réussi",
    Plans: () => "Plans",
    ReOnboarding: () => "Réintégration",
    ReOnboardingAccuracy: () => "Précision",
    ReOnboardingTaxableStaking: () => "Jalonnement taxable",
    ReOnboardingTaxableTrades: () => "Les métiers taxables",
    ReOnboardingWelcomeBack: () => "Content de te revoir",
    Reconciliation: () => "Réconciliation",
    ReconciliationMissingBlockchain: () => "Blockchain manquante",
    ReconciliationMissingPriceIssues: () => "Problèmes de prix manquants",
    ReconciliationNegativeBalanceIssues: () => "Problèmes de solde négatif",
    ReconciliationUncategorisedTransaction: () =>
      "Transactions non catégorisées",
    ReconciliationUnmatchedTransfer: () => "Des transferts inégalés",
    ReferAFriend: () => "Parrainer un ami",
    Report: () => "Rapport",
    Reset: () => "Réinitialiser le mot de passe",
    RestoreUserData: () => "Restaurer les données",
    Rules: () => "Règles",
    Signup: () => "S'inscrire",
    SignupAccountant: () => "Inscription comptable",
    TokenLogin: () => "Connexion par jeton",
    Transactions: () => "Transactions",
    TransactionsAdd: () => "Ajouter une transaction",
    TransactionsMissingPrice: () => "Transactions de prix manquantes",
    TransactionsNegativeBalance: () => "Transactions à solde négatif",
    TransactionsSearch: () => "Rechercher des transactions",
    TransactionsUncategorised: () => "Transactions non catégorisées",
    Unlock: () => "Débloquer le compte",
    Wallets: () => "Portefeuilles",
  },
  partnerEmployeeBenefits: {
    discount: {
      cta: () => "Réclamez votre réduction",
      description: () => "100% de réduction sur tous les forfaits",
      title: (d) =>
        "En tant qu'employé(e) de " +
        d.partner +
        ", vous bénéficiez d'un abonnement CTC gratuit à 100 % pendant un an",
    },
    discountApplied: {
      cta: () => "Voir les plans",
      description: () =>
        "Nous avons automatiquement appliqué ce coupon à votre compte. Procédez au paiement pour bénéficier de cette remise.",
      title: () => "Remise de 100 % appliquée sur tous les plans",
    },
    verifyEmail: {
      confirmEmail: () => "Vérifiez votre e-mail",
      cta: () => "Soumettre",
      error400: () =>
        "Il semble que cette adresse e-mail a déjà réclamé un code de réduction",
      error403: (d) =>
        "Veuillez saisir une adresse e-mail professionnelle " +
        d.partner +
        " valide",
      error500: () =>
        "Une erreur s'est produite, veuillez contacter le support technique",
      helperText: (d) =>
        "Vous devez disposer d'un e-mail " +
        d.partner +
        " valide pour réclamer",
      placeholder: (d) => "Saisissez votre e-mail professionnel " + d.partner,
      reEnterEmail: () => "Saisissez à nouveau l'e-mail",
      success: () =>
        "Nous vous avons envoyé un e-mail contenant un lien qui appliquera automatiquement votre code de réduction",
    },
  },
  partnership: {
    officialPartner: () => "PARTENAIRES OFFICIELS",
  },
  password: {
    case: () => "Votre mot de passe doit contenir au moins 1 lettre majuscule",
    long: () => "Votre mot de passe doit contenir moins de 64 caractères",
    number: () =>
      "Votre mot de passe doit contenir au moins 1 chiffre ou caractère spécial.",
    short: () =>
      "Votre mot de passe est faible. Il doit contenir au moins 8 caractères.",
  },
  payment: {
    accountantPackage: {
      clientDefault: () => "Commencer",
      color: () => "blue",
      cta: () => "Commencer",
      description: () =>
        "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
      details: {
        0: {
          icon: () => "CheckCircleIcon",
          text: () =>
            "Couvre la DeFi, les DEX, les produits dérivés et le staking",
        },
      },
      points: {
        0: () => "Échanges illimités",
        1: () => "Accès à tous les rapports",
        2: () => "Couvre les exercices 2013-2023",
      },
      pointsByCountry: {
        AU: {
          0: () => "Échanges illimités",
          1: () => "Accès à tous les rapports",
          2: () => "Couvre les exercices fiscaux 2013-2023",
          3: () => "Support basé en Australie",
        },
      },
      subtitle: () =>
        "Pour les comptables et les teneurs de livres gérant les dossiers des clients",
      title: () => "Comptable",
    },
    backToTop: () => "Retour en haut",
    base: {
      allTicks: {
        0: () =>
          "Tous les plans bénéficient d'une garantie de remboursement de 30 jours.",
        1: () =>
          "Assistance par chat en direct. Contactez-nous si vous avez besoin d'aide !",
        2: () =>
          "La cotisation annuelle couvre tous les exercices financiers depuis 2013.",
        3: () =>
          "Aucun coût supplémentaire pour effectuer les années fiscales précédentes.",
      },
      cancel: () => "Annuler",
      cancelAnytime: () => "Annuler à tout moment",
      compare: {
        subtitle: () => "Choisissez le plan le mieux adapté à vos besoins.",
        title: () => "Comparaison des fonctionnalités",
      },
      copy: {
        actions: {
          buy: {
            text: () => "Acheter",
          },
          current: () => "Plan actuel",
          getStarted: {
            text: () => "Commencer",
          },
          signUp: {
            text: () => "Acheter maintenant",
          },
          upgrade: {
            text: () => "Mise à niveau vers",
          },
          upgradeFor: (d) => "Mise à niveau pour " + d.upgradePrice,
        },
        annually: () => "Annuellement",
        costCurrentPlanDeducted: () => "Coût du plan actuel déduit",
        discountAppliedFirstYear: (d) => d.discount + " % de remise appliquée",
        features: {
          advancedTaxReports: () => "Rapports fiscaux avancés",
          auditReport: () => "Rapport d'audit",
          integrations: () => "Intégrations illimitées",
          ltfo: () => "Algorithme de minimisation des impôts",
          onchain: () => "Transactions automatisées en chaîne",
          portfolioTracking: () => "Suivi de portefeuille",
          report: () =>
            "Rapports fiscaux illimités (TOUTES les années fiscales)",
          reportAuthority: (d) =>
            "Rapports fiscaux " +
            d.authority +
            " illimités (TOUTES les années fiscales)",
          smartContract: () => "Interactions entre contrats intelligents",
          support: () => "Soutien",
          tlh: () => "Outil de récupération des pertes fiscales",
          tsr: () => "Rapport sur les transactions boursières",
          txLimit: (d) => "Transactions " + d.txLimit,
          xero: () => "Intégration Xero",
        },
        featuresReport: {
          reportAllFinancialYears: () => "Tous les exercices financiers",
          reportUnlimited: () => "Rapports illimités",
        },
        featuresTableCell: {
          experimentSupportEmailChat: () => "Courriel + Chat",
          experimentSupportPriority: () => "Assistance prioritaire + expert",
          integrations: () => "Illimité",
          supportEmailChat: () => "Assistance par e-mail et chat",
          supportPriority: () => "Assistance prioritaire",
        },
        featuresTableHeader: {
          integrations: () => "Intégrations",
          support: () => "Soutien",
          txLimit: () => "Transactions",
        },
        featuresTooltip: {
          advancedTaxReports: () =>
            "Rapport de dépenses, rapport d'inventaire, rapport de négociation de produits dérivés, rapport sur les cadeaux sortants et les biens perdus/volés",
          auditReport: () =>
            "Un rapport qui fournit un journal des données importées et de toutes les modifications qui y ont été apportées, y compris les paramètres fiscaux appliqués.",
          ltfo: () =>
            "L'utilisation de notre algorithme le plus efficace sur le plan fiscal pourrait vous faire économiser des impôts potentiels",
          tlh: () =>
            "Un outil pour identifier les actifs en perte afin de les revendre avant la fin de l'année fiscale afin de réduire votre gain en capital global",
        },
        firstYear: (d) =>
          "pour la première année, puis " + d.price + " facturé annuellement",
        freeTransactions: () => "+ 10 000 transactions Coinbase gratuites",
        highlightFeatures: {
          "advanced-inventory": () => "Algorithme de minimisation des impôts",
          "advanced-reports": () => "Rapports fiscaux avancés",
          allOtherFeatures: () =>
            "+ tout ce qui est inclus dans le plan Trader",
          comingSoon: () => "À venir",
          contacts: () => "Carnet d'adresses",
          erp: () => "Intégrations comptables",
          "lock-tax-year": () => "Verrouiller les années d'imposition",
          "multi-tax-years": () =>
            "Rapports fiscaux illimités pour toutes les années fiscales",
          prioritySupport: () => "Assistance prioritaire",
          rules: () => "Moteur de règles amélioré",
          sidebar: () => "barre latérale",
          "smart-contract-transactions": () =>
            "Transactions de contrats intelligents",
          "tax-loss-harvesting": () =>
            "Outil de récupération des pertes fiscales",
          tips: () => "Conseils",
          "trading-stock-report": () =>
            "Rapport sur les transactions boursières",
          treasury: () => "Tableau de bord du Trésor",
        },
        planCommonDetails: () =>
          "Le plan unique couvre toutes les années fiscales de 2013 à 2022",
        plans: {
          accountant: {
            clientDataDescription: () =>
              "Nous prenons directement en charge des centaines d'échanges, de blockchains et de services. Ajoutez simplement des clés API et des adresses de portefeuille.",
            clientDataTitle: () =>
              "Obtenez facilement des données clients grâce à des échanges directs et à des intégrations de blockchain",
            clientDefault: () => "Commencer",
            color: () => "blue",
            description: () =>
              "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
            descriptionFooter: () =>
              "Commencez gratuitement et ne commencez à payer que lorsque vous importez les données de vos clients.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () =>
                  "Couvre la DeFi, les DEX, les produits dérivés et le staking",
              },
            },
            getStarted: () => "Commencez gratuitement",
            manageClientsDescription: () =>
              "Notre portail comptable vous permet d'inviter et de gérer des clients à partir d'un tableau de bord facile à utiliser.",
            manageClientsTitle: () =>
              "Gérez tous vos clients en un seul endroit",
            points: {
              0: () =>
                "Invitez et gérez vos clients depuis votre tableau de bord de gestion client",
              1: () =>
                "Prend en charge jusqu'à 100 000 transactions par client",
              2: () =>
                "Couvre la DeFi, les DEX, les produits dérivés et le staking",
            },
            subtitle: () =>
              "Gérez facilement la déclaration de revenus de crypto-monnaie de votre client avec notre suite comptable professionnelle.",
            title: () => "Suite comptable",
            toolsDemo: () => "Outils de comptabilité et démo",
          },
          advanced: {
            color: () => "purple",
            cta: () => "Acheter maintenant",
            description: () =>
              "Déverrouille un algorithme propriétaire pour maximiser les remises à long terme et les pertes à court terme",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les investisseurs actifs qui ont des transactions complexes.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Couvre les transactions DeFi, DEX, produits dérivés, jalonnement et " +
                  d.txLimit +
                  " .",
              },
            },
            points: {
              0: () => "10 000 transactions",
              1: () => "Échanges illimités",
              2: () => "Importations de portefeuille",
              3: () => "Jalonnement, minage, parachutages, DeFi",
              4: () => "Transactions Long/Short et CFD",
              5: () => "Négociations sur marge et à terme",
              6: () => "Rapport d'impôt sur le revenu",
              7: () => "Rapport sur l'impôt sur les gains en capital",
              8: () => "Assistance par chat en ligne",
              9: () => "Couvre les exercices 2013-2023",
            },
            subtitle: () =>
              "Pour les investisseurs en crypto-monnaies qui sont entrés dans le monde de la finance décentralisée",
            title: () => "Investisseur",
          },
          business: {
            color: () => "blue",
            cta: () => "Commencer",
            description: () =>
              "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () =>
                  "Couvre la DeFi, les DEX, les produits dérivés et le staking",
              },
            },
            points: {
              0: () => "Accès à tous les rapports",
              1: () => "Couvre les exercices 2013-2023",
            },
            retailPlansPage: {
              cta: () => "Acheter une entreprise",
              description: () =>
                "Outil de comptabilité cryptographique précis pour les entreprises",
              points: {
                0: {
                  description: () => "S'intègre à Xero et Quickbooks",
                  feature: () => "Intégrations comptables",
                },
                1: {
                  description: () =>
                    "Suivez et gérez toutes les adresses et entités en un seul endroit ",
                  feature: () => "Carnet d'adresses",
                },
                2: {
                  description: () =>
                    "Créez et gérez des règles automatisées pour catégoriser et mapper les transactions à l'importation",
                  feature: () => "Moteur de règles",
                },
                3: {
                  feature: () => "+ Toutes les fonctionnalités du plan Trader",
                },
              },
              subtitle: () => "Automatisez votre comptabilité cryptographique",
              title: () => "Plan d'affaires",
            },
            subtitle: () => "Pour les entreprises du web3",
            title: () => "Entreprise",
          },
          businessPackage: {
            color: () => "blue",
            cta: () => "Commencer",
            description: () =>
              "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () =>
                  "Couvre la DeFi, les DEX, les produits dérivés et le staking",
              },
            },
            points: {
              0: () => "Échanges illimités",
              1: () => "Accès à tous les rapports",
              2: () => "Couvre les exercices 2013-2023",
            },
            subtitle: () =>
              "Pour les comptables et les teneurs de livres gérant les dossiers des clients",
            title: () => "Entreprise",
          },
          business_pro: {
            caption: () =>
              "Plus de 10 000 transactions ? Contactez-nous pour bénéficier d'un prix imbattable sur le marché.",
            color: () => "blue",
            cta: () => "Commencer",
            description: () =>
              "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () =>
                  "Couvre la DeFi, les DEX, les produits dérivés et le staking",
              },
            },
            points: {
              0: () => "Échanges illimités",
              1: () => "Accès à tous les rapports",
              2: () => "Couvre les exercices 2013-2023",
            },
            subtitle: () =>
              "Pour les entreprises plus grandes ou plus établies",
            title: () => "Entreprise Pro",
          },
          business_starter: {
            color: () => "blue",
            cta: () => "Commencer",
            description: () =>
              "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () =>
                  "Couvre la DeFi, les DEX, les produits dérivés et le staking",
              },
            },
            points: {
              0: () => "Échanges illimités",
              1: () => "Accès à tous les rapports",
              2: () => "Couvre les exercices 2013-2023",
            },
            subtitle: () => "Pour les startups et les petites entreprises",
            title: () => "Démarrage d'entreprise",
          },
          business_trial: {
            color: () => "blue",
            cta: () => "Commencer",
            description: () =>
              "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () =>
                  "Couvre la DeFi, les DEX, les produits dérivés et le staking",
              },
            },
            points: {
              0: () => "Échanges illimités",
              1: () => "Accès à tous les rapports",
              2: () => "Couvre les exercices 2013-2023",
            },
            subtitle: () => "Pour les startups et les petites entreprises",
            title: () => "Plan d'affaires",
          },
          client: {
            collaborator: {
              color: () => "blue",
              cta: () => "Commencer",
              description: () =>
                "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
              details: {
                0: {
                  icon: () => "CheckCircleIcon",
                  text: () =>
                    "Couvre la DeFi, les DEX, les produits dérivés et le staking",
                },
              },
              points: {
                0: () => "Échanges illimités",
                1: () => "Accès à tous les rapports",
                2: () => "Couvre les exercices 2013-2023",
              },
              subtitle: () =>
                "Pour les comptables et les teneurs de livres gérant les dossiers des clients",
              title: () => "Comptable",
            },
            color: () => "blue",
            cta: () => "Commencer",
            description: () =>
              "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () =>
                  "Couvre la DeFi, les DEX, les produits dérivés et le staking",
              },
            },
            enterprise: {
              color: () => "blue",
              cta: () => "Commencer",
              description: () =>
                "Solution fiscale cryptographique tout-en-un pour les comptables et les teneurs de livres.",
              details: {
                0: {
                  icon: () => "CheckCircleIcon",
                  text: () =>
                    "Couvre la DeFi, les DEX, les produits dérivés et le staking",
                },
              },
              points: {
                0: () => "Échanges illimités",
                1: () => "Accès à tous les rapports",
                2: () => "Couvre les exercices 2013-2023",
              },
              subtitle: () =>
                "Pour les comptables et les teneurs de livres gérant les dossiers des clients",
              title: () => "Comptable",
            },
            points: {
              0: () => "Échanges illimités",
              1: () => "Accès à tous les rapports",
              2: () => "Couvre les exercices 2013-2023",
            },
            subtitle: () =>
              "Pour les comptables et les teneurs de livres gérant les dossiers des clients",
            title: () => "Comptable",
          },
          collaborator: {
            subtitle: () => "collaborateur",
            title: () => "Collaborateur",
          },
          enterprise: {
            subtitle: () => "entreprise",
            title: () => "Entreprise",
          },
          expert: {
            color: () => "blue",
            cta: () => "Acheter maintenant",
            description: () =>
              "Solution haute capacité pour les traders actifs\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les day traders avec un volume de transactions élevé.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Prend en charge tous les plans d'investisseur et jusqu'à " +
                  d.txLimit +
                  " transactions.",
              },
            },
            points: {
              0: () => "100 000 transactions",
              1: () => "Toutes les fonctionnalités de Investor",
              2: () => "Comparer les stratégies fiscales",
            },
            subtitle: () =>
              "Pour les traders de crypto-monnaies qui souhaitent déclarer leur trading comme une entreprise",
            title: () => "Commerçant",
          },
          free: {
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les utilisateurs qui s'intéressent à l'espace crypto.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Prend en charge les transactions " +
                  d.txLimit +
                  " où les actifs sont détenus sur des échanges centralisés.",
              },
            },
            subtitle: () => "gratuit",
            title: () => "Gratuit",
          },
          investorUs: {
            color: () => "grey",
            cta: () => "Acheter maintenant",
            description: () => "Pour les investisseurs crypto expérimentés",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les passionnés de crypto qui utilisent des échanges centralisés.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Prend en charge le stockage d'actifs dans des portefeuilles et jusqu'à " +
                  d.txLimit +
                  " transactions.",
              },
            },
            points: {
              0: () => "1 000 transactions",
              1: () => "Intégrations illimitées",
              2: () =>
                "Rapports fiscaux illimités pour toutes les années fiscales",
              3: () => "Intégration de TurboTax",
              4: () => "Tableau de bord du portefeuille",
              5: () => "Invitez votre comptable",
              6: () => "Calculs automatisés des taxes DeFi",
              7: () => "Minimisation des impôts",
            },
            subtitle: () => "Pour les investisseurs crypto expérimentés",
            title: () => "Investisseur",
          },
          investorUsPlus: {
            color: () => "grey",
            cta: () => "Acheter maintenant",
            description: () => "Pour les investisseurs crypto expérimentés",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les passionnés de crypto qui utilisent des échanges centralisés.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Prend en charge le stockage d'actifs dans des portefeuilles et jusqu'à " +
                  d.txLimit +
                  " transactions.",
              },
            },
            points: {
              0: () => "3 000 transactions",
              1: () => "Intégrations illimitées",
              2: () =>
                "Rapports fiscaux illimités pour toutes les années fiscales",
              3: () => "Intégration de TurboTax",
              4: () => "Tableau de bord du portefeuille",
              5: () => "Invitez votre comptable",
              6: () => "Calculs automatisés des taxes DeFi",
              7: () => "Minimisation des impôts",
            },
            subtitle: () => "Pour les investisseurs crypto expérimentés",
            title: () => "Investisseur +",
          },
          pro: {
            subtitle: () =>
              "Pour les traders de crypto-monnaies qui souhaitent déclarer leur trading comme une entreprise",
            title: () => "Pro",
          },
          rookie: {
            color: () => "grey",
            cta: () => "Acheter maintenant",
            description: () =>
              "Solution économique pour les amateurs occasionnels de crypto-monnaies pour gérer sans effort les taxes sur les crypto-monnaies",
            points: {
              0: () => "200 Transactions",
              1: () => "Échanges illimités",
              2: () => "Rapport d'impôt sur le revenu",
              3: () => "Rapport sur l'impôt sur les gains en capital",
              4: () => "Couvre les exercices 2013-2023",
              5: () => "Assistance par chat en ligne",
            },
            subtitle: () =>
              "Pour les utilisateurs de crypto-monnaies qui ont commencé à se lancer dans le monde du trading",
            title: () => "Débutant",
          },
          rookie_100: {
            color: () => "grey",
            cta: () => "Acheter maintenant",
            description: () =>
              "Solution économique pour les amateurs occasionnels de crypto-monnaies pour gérer sans effort les taxes sur les crypto-monnaies",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les utilisateurs qui s'intéressent à l'espace crypto.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Prend en charge les transactions " +
                  d.txLimit +
                  " où les actifs sont détenus sur des échanges centralisés.",
              },
            },
            points: {
              0: () => "100 Transactions",
              1: () => "Parachutages aériens",
              2: () => "Échanges illimités",
              3: () => "Rapport d'impôt sur le revenu",
              4: () => "Rapport sur l'impôt sur les gains en capital",
              5: () => "Couvre les exercices 2013-2023",
              6: () => "Assistance par chat en ligne",
            },
            subtitle: () =>
              "Pour les utilisateurs de crypto-monnaies qui ont commencé à se lancer dans le monde du trading",
            title: () => "Débutant",
          },
          standard: {
            color: () => "grey",
            cta: () => "Acheter maintenant",
            description: () =>
              "Prise en charge complète de la chaîne pour DeFi et NFT, avec intégrations directes de contrats intelligents",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les passionnés de crypto qui utilisent des échanges centralisés.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Prend en charge le stockage d'actifs dans des portefeuilles et jusqu'à " +
                  d.txLimit +
                  " transactions.",
              },
            },
            points: {
              0: () => "2 500 transactions",
              1: () => "Échanges illimités",
              2: () => "Importations de portefeuille",
              3: () => "Parachutages aériens",
              4: () => "Rapport d'impôt sur le revenu",
              5: () => "Rapport sur l'impôt sur les gains en capital",
              6: () => "Couvre les exercices 2013-2023",
              7: () => "Assistance par chat en ligne",
            },
            subtitle: () =>
              "Pour les passionnés de crypto-monnaie qui utilisent des échanges centralisés et ont tendance à hodler",
            title: () => "Amateur",
          },
          standard_100: {
            color: () => "grey",
            cta: () => "Acheter maintenant",
            description: () =>
              "Prise en charge complète de la chaîne pour DeFi et NFT, avec intégrations directes de contrats intelligents",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les passionnés de crypto qui utilisent des échanges centralisés.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Prend en charge le stockage d'actifs dans des portefeuilles et jusqu'à " +
                  d.txLimit +
                  " transactions.",
              },
            },
            points: {
              0: () => "1 000 transactions",
              1: () => "Échanges illimités",
              2: () => "Importations de portefeuille",
              3: () => "Parachutages aériens",
              4: () => "Rapport d'impôt sur le revenu",
              5: () => "Rapport sur l'impôt sur les gains en capital",
              6: () => "Couvre les exercices 2013-2023",
              7: () => "Assistance par chat en ligne",
            },
            subtitle: () =>
              "Pour les passionnés de crypto-monnaie qui utilisent des échanges centralisés et ont tendance à hodler",
            title: () => "Amateur",
          },
          traderUs: {
            color: () => "blue",
            cta: () => "Acheter maintenant",
            description: () => "Pour les fanatiques expérimentés de crypto\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les day traders avec un volume de transactions élevé.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Prend en charge tous les plans d'investisseur et jusqu'à " +
                  d.txLimit +
                  " transactions.",
              },
            },
            points: {
              0: () => "10 000 transactions",
              1: () => "Toutes les fonctionnalités d'Investor+",
              2: () => "Déclaration fiscale avancée",
              3: () => "Journal d'audit",
            },
            subtitle: () =>
              "Pour les traders de crypto-monnaies qui souhaitent déclarer leur trading comme une entreprise",
            title: () => "Commerçant",
          },
          traderUsPlus: {
            color: () => "blue",
            cta: () => "Acheter maintenant",
            description: () => "Pour les fanatiques expérimentés de crypto\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Pour les day traders avec un volume de transactions élevé.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Prend en charge tous les plans d'investisseur et jusqu'à " +
                  d.txLimit +
                  " transactions.",
              },
            },
            points: {
              0: () => "100 000 transactions",
              1: () => "Toutes les fonctionnalités d'Investor+",
              2: () => "Déclaration fiscale avancée",
              3: () => "Journal d'audit",
            },
            subtitle: () =>
              "Pour les traders de crypto-monnaies qui souhaitent déclarer leur trading comme une entreprise",
            title: () => "Commerçant +",
          },
        },
        plus: () => "+",
        subtitle: () =>
          "Payez le moins d’impôts et obtenez les rapports les plus précis avec CTC.",
        subtitleAccountant: () =>
          "Gérez facilement les déclarations fiscales de crypto-monnaie de vos clients avec notre suite comptable professionnelle",
        tabTitle: () =>
          "Calculateur de taxes sur les crypto-monnaies pour les entreprises",
        title: () => "Choisissez votre forfait",
        titleAccountants: () => "Suite comptable",
        usExperimentPlanDescription: {
          investorUs: () => "Pour les investisseurs crypto expérimentés ",
          investorUsPlus: () => "Pour les investisseurs crypto expérimentés ",
          rookie_100: () => "Pour ceux qui débutent",
          traderUs: () => "Pour les fanatiques expérimentés de crypto",
          traderUsPlus: () => "Pour les fanatiques expérimentés de crypto",
        },
        usExperimentPlanTitle: {
          investorUs: () => "Investisseur",
          investorUsPlus: () => "Investisseur +",
          rookie_100: () => "Débutant",
          traderUs: () => "Commerçant",
          traderUsPlus: () => "Commerçant +",
        },
        usExperimentPlanTxCount: {
          investorUs: {
            hasPlusTxs: () => "3 000",
            txCount: () => "1 000",
          },
          rookie_100: {
            hasPlusTxs: () => "0",
            txCount: () => "100",
          },
          traderUs: {
            hasPlusTxs: () => "Plus de 100 000",
            txCount: () => "10 000",
          },
        },
        usUserExperimentFeatures: {
          accountantInvite: () => "Invitez votre comptable",
          advancedTaxReports: () => "Déclaration fiscale avancée",
          auditLog: () => "Journal d'audit",
          dashboard: () => "Tableau de bord du portefeuille",
          defiTaxAutomation: () => "Calculs automatisés des taxes DeFi",
          integrations: () => "Intégrations illimitées",
          support: () => "Soutien",
          taxMinimization: () => "Minimisation des impôts",
          taxYearReports: () =>
            "Rapports fiscaux illimités pour toutes les années fiscales",
          turboTaxIntegration: () => "Intégration de TurboTax",
        },
        usUserExperimentFeaturesTooltip: {
          accountantInvite: () =>
            "Collaborez avec votre fiscaliste. Les comptables ont accès à CTC sans frais supplémentaires.",
          advancedTaxReports: () =>
            "Rapports précis pour tous les scénarios fiscaux concernant les dépenses, les stocks, les transactions de produits dérivés, les dons sortants et les actifs perdus/volés.",
          auditLog: () =>
            "Réduisez le risque d’audits grâce à un journal complet des données importées et des modifications qui y ont été apportées.",
          dashboard: () =>
            "Gagnez confiance dans les chiffres grâce à une vue conviviale de l’ensemble de votre portefeuille.",
          defiTaxAutomation: () =>
            "Nous faisons le gros du travail pour que vous n'ayez pas à le faire. Évaluation automatique des implications fiscales sur votre activité en chaîne et vos contrats intelligents.",
          integrations: () =>
            "Importez tous vos portefeuilles et sources en quelques minutes.",
          support: () =>
            "Ne vous lancez pas seul. Notre équipe d'experts en conformité cryptographique se fera un plaisir de vous aider.",
          taxMinimization: () =>
            "Ne dépensez pas trop en impôts, grâce à l'algorithme exclusif de minimisation des impôts de CTC, à la possibilité de choisir la méthode d'inventaire la plus efficace et à la récupération des pertes fiscales.",
          taxYearReports: () =>
            "Pas de frais supplémentaires pour les rapports des années précédentes. *Toux toux* contrairement à nos concurrents.",
          turboTaxIntegration: () =>
            "CTC se connecte à la fois aux versions de navigateur et de bureau de TurboTax, ce qui vous permet de soumettre vos impôts en toute simplicité.",
        },
        validFirstYear: () => "Valable pour votre abonnement de première année",
        wasPrice: (d) => "était " + d.price,
      },
      currency: () => "Dollars américains",
      currencySymbol: () => "$",
      email: () => "E-mail",
      guarantee: () => "Garantie de remboursement de 30 jours",
      loading: () => "Traitement...",
      payNow: () => "Payer maintenant",
      period: () => "année",
      plan: () => "Plan",
      planDisabled: () =>
        "Le nombre de transactions de ce client a dépassé la limite de ce forfait.",
      title: () => "Plans de tarification",
    },
    billedYearly: () => "Facturé annuellement",
    billing: {
      chips: {
        cancelledChip: (d) => "Annulé : Expire le " + d.date,
        renewChip: (d) => "Renouveler le : " + d.date,
        trialEndsChip: (d) => "Fin du procès : " + d.date,
      },
      cta: {
        cancelPlan: () => "Annuler le plan",
        reSubscribe: () => "Se réabonner",
      },
      footer: {
        client: {
          cancelledPlanFooter: (d) =>
            "Ce plan ne sera pas renouvelé le " + d.date,
          estimatedPaymentFooter: (d) =>
            "Votre prochain paiement estimé pour ce client sera de " +
            d.amount +
            " le " +
            d.date,
          estimatedPaymentFooterWithTrial: (d) =>
            "Votre prochain paiement estimé pour ce client sera de " +
            d.amount +
            " le " +
            d.date +
            " , une fois l'essai terminé",
        },
      },
      lineItems: {
        billingCycle: () => "Cycle de facturation :",
        couponWithAmount: (d) =>
          d.couponId + " ( " + d.amountOff + " % de réduction)",
        planPrice: () => "Prix du forfait :",
        totalDue: () => "Total dû :",
        totalDueDate: (d) => "Total dû le " + d.date + " :",
      },
      payment: {
        activeCard: () => "Carte active :",
        changePayment: () => "Changer de mode de paiement",
        discount: (d) => d.amount + " % de réduction",
        end: () =>
          " chaque année jusqu'à ce que vous annuliez votre abonnement. Vous pouvez annuler à tout moment.",
        endWithDate: (d) =>
          " le " +
          d.date +
          " puis annuellement à cette date jusqu'à ce que vous annuliez l'abonnement, vous pouvez annuler à tout moment.",
        start: () => "Vous serez facturé ",
      },
    },
    cancelled: {
      button: () => "Annulé",
      tooltip: (d) =>
        "Le forfait " +
        d.plan +
        " a été annulé. Il restera actif jusqu'à la fin de la période de facturation en cours.",
    },
    coupon: {
      unknownCoupon: () => "Offre exclusive",
    },
    currentPlan: () => "Le plan actuel du client",
    disclaimer: () =>
      "*Tous les plans peuvent être déductibles d’impôt lorsqu’ils sont engagés en tant que dépense liée à la gestion de vos propres affaires fiscales.",
    downgradeWarning: () =>
      "Ce plan n'est pas disponible car vous avez déjà utilisé des fonctionnalités d'un plan de niveau supérieur au cours de cette période de facturation",
    enterprise: {
      demoCTA: {
        badge: () => "Première offre client",
        button: () => "Planifier une démo",
        newRelease: {
          badge: () => "Offre de nouvelle version",
          ctaLink: () => "Planifiez un appel avec nous",
          ctaText: () => "Si vous avez des questions ou souhaitez une démo",
          subtitle: () =>
            "Commencez à utiliser un client professionnel gratuitement, sans limites ni restrictions. Après 30 jours, nous vous contacterons pour faire le point.",
          title: () => "30 jours gratuits !",
        },
        subtitle: () =>
          "Laissez-nous vous aider à démarrer avec votre premier client professionnel. Planifiez une démonstration avec notre équipe.",
        title: () => "Essai gratuit de 30 jours pour votre premier client.",
      },
    },
    excludes: () => "Exclut:",
    freeTx: {
      coinbase: (d) => d.freeTxCount + " transactions Coinbase gratuites",
      coinjar: (d) => d.freeTxCount + " transactions CoinJar gratuites",
      default: (d) => d.freeTxCount + " transactions gratuites",
      phantom: (d) => d.freeTxCount + " transactions Phantom gratuites",
    },
    haveSmartContractTx: () =>
      "Vous avez des transactions de contrats intelligents.",
    ignoredTx: (d) =>
      d.ignoredTxCount + " transactions marquées comme spam ou ignorées",
    moneyBackGuarantee: () => "Garantie de remboursement de 30 jours",
    moneyBackGuaranteeLower: () => "Garantie de remboursement de 30 jours",
    mostPopular: () => "Le plus populaire",
    paymentCycle: {
      annual: () => "Annuellement",
      monthly: () => "Mensuel",
    },
    paymentCycleShort: {
      annual: () => "Pennsylvanie",
      monthly: () => "p/m",
    },
    perMonthBilledYearly: () => "Par mois, facturé annuellement",
    promoBanner: {
      applied: () => "Appliqué",
      bitcoinDotComAu: {
        0: () => "Réduction de 30% sur les forfaits payants",
      },
      coinbase: {
        0: () => "10 000 transactions Coinbase gratuites",
        1: () => "30% de réduction sur les forfaits payants",
      },
      coinbaseone: {
        otherPlans: () =>
          "40 % de réduction sur les forfaits Hobbyist, Investor et Trader",
        rookie: (d) => d.price + " Forfait débutant",
      },
      coinbaseonepremium: {
        0: () => "50 % de réduction sur le niveau premium de CTC, Trader",
        1: () =>
          "40 % de réduction sur les forfaits Rookie, Hobbyist et Investor",
      },
      coinjar: {
        0: () => "1 000 transactions CoinJar gratuites",
        1: () => "30% de réduction sur les forfaits payants",
      },
      coinstats: () => "de votre première année avec CTC",
      independentReserve: {
        0: () => "Réduction de 30% sur les forfaits payants",
      },
      metamask: {
        0: () => "30% de réduction sur votre première année",
      },
      partnerOffer: () => "Offre partenaire officielle",
      phantom20: {
        0: () =>
          "20% de réduction sur les forfaits payants (première année uniquement)",
        1: () => "200 transactions gratuites sur le portefeuille Phantom",
      },
      referAFriend: {
        0: () => "20% de réduction sur tous les plans retail",
      },
      referralOffer: () => "Offre de parrainage",
    },
    renewFailed: {
      bannerTitle: () => "Oh non ! Nous n'avons pas pu traiter votre paiement.",
      renewError: () =>
        "Veuillez vérifier votre mode de paiement par défaut et que vous disposez de fonds suffisants pour couvrir votre facture.",
      renewPlan: () => "Renouveler le plan",
      renewSuccess: () => "Votre abonnement a été renouvelé avec succès",
      settingText: () =>
        "Pour générer des rapports, renouvelez votre forfait. Mettez à jour vos informations de paiement en procédant à la gestion des paiements.",
    },
    save20: () => "Économisez 20%",
    taxDeductible: () => "Déductible des impôts*",
    tooManyTx: () => "Vous avez trop de transactions pour ce forfait",
    totalTxs: () => "Total des transactions",
    txCount: {
      prefix: () => "Tu as",
      suffix: () => "transactions facturables",
    },
    txCountFiltered: (d) =>
      "Vous avez <s> " +
      d.filtered +
      " </s> <span> " +
      d.count +
      " </span> transactions",
    viewAccountant: () => "Voir le plan comptable",
    viewBusinessPlan: () => "Voir le plan d'affaires",
    viewIndividualPlan: () => "Voir les plans individuels",
    yourOffers: () => "Vos offres",
  },
  paymentPortal: {
    cardDetailsLabel: () => "Détails de la carte*",
    dialogLabel: () => "Paiement",
    payPrefixText: () => "Payer",
    paymentError: () => "Erreur lors du traitement du paiement",
  },
  paymentStatus: {
    pending: {
      subtitle: () =>
        "Si cela prend trop de temps, essayez d'actualiser la page. Veuillez contacter le support si le problème persiste.",
      title: () => "Traitement du paiement...",
    },
    success: {
      buttonText: () => "Obtenez votre rapport d'impôt",
      subtitle: () => "Merci d'avoir choisi Crypto Tax Calculator.",
      title: () => "Achat réussi !",
    },
  },
  paywallBanner: {
    cancel: () => "Annuler",
    implementations: {
      accountant: {
        enforceUpgrade: {
          buttonText: () => "Plan de mise à niveau",
          subtitle: () =>
            "Demandez à votre client de mettre à niveau son plan pour télécharger les rapports fiscaux",
          subtitleMulti: () => "Votre rapport comprend :",
          title: (d) =>
            "Votre client a dépassé la limite de transaction actuelle de son forfait ( " +
            d.count +
            " sur " +
            d.limit +
            " )",
          titleMulti: () =>
            "Votre client a dépassé les limites de son forfait, en ayant :",
        },
        renewalFailed: {
          buttonText: () => "Gérer l'abonnement",
          subtitle: () =>
            "Demandez à votre client de résoudre le problème de paiement pour télécharger les rapports fiscaux.",
          title: () =>
            "Oh non ! Nous n'avons pas pu traiter le paiement de votre client.",
        },
        signUp: {
          buttonText: () => "S'inscrire",
          subtitle: () =>
            "Afin de générer un rapport fiscal, vous devrez mettre à niveau votre client vers le plan suivant.",
          title: () =>
            "Vous souhaitez générer un rapport fiscal pour votre client ?",
        },
      },
      standard: {
        enforceUpgrade: {
          buttonText: () => "Plan de mise à niveau",
          subtitle: () =>
            "Mettez à niveau votre plan pour télécharger les rapports fiscaux",
          subtitleMulti: () => "Votre rapport comprend :",
          title: (d) =>
            "Vous avez dépassé la limite de transaction actuelle de votre forfait ( " +
            d.count +
            " sur " +
            d.limit +
            " )",
          titleMulti: () =>
            "Vous avez dépassé les limites de votre forfait, en ayant :",
        },
        renewalFailed: {
          buttonText: () => "Gérer l'abonnement",
          subtitle: () =>
            "Vérifiez votre abonnement et vos détails de paiement pour rectifier le problème.",
          title: () => "Oh non ! Nous n'avons pas pu traiter votre paiement.",
        },
        signUp: {
          buttonText: () => "Plan de mise à niveau",
          subtitle: () =>
            "Afin de générer un rapport fiscal, vous devrez passer au plan suivant.",
          title: () => "Vous souhaitez générer un rapport fiscal ?",
        },
      },
    },
    paywallReasons: {
      importCount: (d) => "Plus de " + d.importLimit + " importations",
      tradeType: () => "Transactions de contrats intelligents",
      txCount: (d) => "Plus de " + d.txLimit + " transactions",
    },
    reportTable: () =>
      "Veuillez effectuer une mise à niveau pour accéder au tableau de rapport",
    upgradeFor: () => "Mise à niveau pour",
    upgradeNow: () => "Mettre à jour maintenant",
  },
  paywallModal: {
    cancel: () => "Annuler",
    downloadSample: () => "Télécharger un exemple de rapport",
    implementations: {
      advancedReports: {
        prompt: () => "Débloquez ces rapports avancés : ",
        title: () => "Voir l'image entière",
      },
      allReports: {
        prompt: () => "Déverrouillez ces rapports : ",
        title: () =>
          "Abonnez-vous pour accéder à tous les rapports et formulaires fiscaux",
      },
      inventory: {
        prompt: () => "Passez au plan investisseur pour",
        title: () => "Optimisez votre situation fiscale",
      },
      multiTaxYears: {
        subtitle: () =>
          "Passez à un forfait payant pour accéder aux rapports pour autant d’années fiscales que vous le souhaitez.",
        title: () => "Plusieurs années fiscales, un seul abonnement",
      },
      paywall: {
        subtitle: () =>
          "Effectuez une mise à niveau pour accéder à vos rapports.",
        title: () => "Vous avez dépassé les inclusions de votre forfait",
      },
      smartContractInteraction: {
        banner: {
          subText: () => "Transactions de contrats intelligents",
          text: () => "Votre rapport comprend :",
          title: () => "Mise à niveau requise",
        },
        subtitle: () =>
          "Passez au plan amateur pour bénéficier de la prise en charge de tous les types de DeFi, d'activités complexes sur la chaîne et d'importations illimitées.",
        title: () => "Libérez votre dégénéré intérieur",
      },
      tips: {
        subtitle: () =>
          "Passez à un forfait payant pour accéder à des conseils qui peuvent vous aider à améliorer la précision de votre rapport et à économiser sur votre facture fiscale.",
        subtitleReview: () =>
          "Passez à un forfait payant pour accéder à notre processus d’examen guidé, qui peut vous aider à améliorer la précision de votre rapport et à économiser sur votre facture fiscale.",
        title: () => "Abonnez-vous pour des opportunités d'économies",
      },
      tradingStockReport: {
        subtitle: () =>
          "Passez au plan Trader pour avoir accès au rapport sur les actions Trader",
        title: () => "Flexibilité des rapports professionnels",
      },
    },
    irsModal: {
      dateString: (d) =>
        d.days + " jours : " + d.hours + " heures : " + d.minutes + " mins",
      irsTaxSubmissionDeadline: () =>
        "Date limite de soumission fiscale à l'IRS",
    },
    irsVariantChoosePlan: {
      cta: () => "Procéder au paiement",
      secondary: () => "Comparer les forfaits",
      title: () => "Améliorez votre forfait.\nDébloquez des économies.",
      txCount: (d) =>
        "Vous avez " +
        plural(d.txCount, 0, fr, {
          one: "1 transaction",
          other: number("fr", d.txCount, 0) + " transactions",
        }),
      txSubtitle: (d) => "Jusqu'à " + d.txCount + " transactions",
    },
    irsVariantPreSelectedPlan: {
      cta: () => "Acheter le forfait",
      disclaimerOne: () => "Garantie de remboursement de 30 jours",
      disclaimerTwo: () =>
        "*La remise s'applique uniquement à la première année",
      discountApplied: () => "Remise appliquée",
      features: {
        0: {
          description: () => "Restez en règle avec les autorités fiscales",
          title: () => "Rapports fiscaux prêts pour un audit",
        },
        1: {
          description: () =>
            "Obtenez des conseils pour réduire légalement votre facture fiscale",
          title: () => "Débloquez des économies d'impôts",
        },
        2: {
          description: () =>
            "Nous vous guiderons tout au long du processus pour garantir l'exactitude de votre déclaration",
          title: () => "Ne faites pas cavalier seul",
        },
      },
      link: () => "Comparer les forfaits",
      price: (d) =>
        d.price +
        plural(d.discount, 0, fr, {
          0: "",
          other: " (économisez " + number("fr", d.discount, 0) + "%)*",
        }),
      title: () =>
        "Améliorez votre forfait et débloquez toutes les fonctionnalités",
    },
    irsVariantViewPlans: {
      cta: () => "Voir les forfaits",
      metamaskOffer: () => "30% de réduction sur tous les forfaits",
      reportsReady: () => "Vos rapports sont prêts",
    },
    modalText: {
      "annex-so-other-income": () =>
        "Téléchargez un exemple de rapport allemand - Anlage So (Sonstige Einkünfte)",
      audit: () => "Télécharger un exemple de rapport de transaction d'audit",
      bgl: () => "Télécharger l'exemple de BGL Simple Fund 360 XML",
      "capital-gains": () =>
        "Télécharger un exemple de rapport sur les gains en capital",
      "capital-gains-nz": () =>
        "Télécharger un exemple de rapport sur les profits/pertes réalisés",
      cerfa2086: () => "Télécharger l'exemple de Formulaire n°2086",
      expense: () => "Télécharger un exemple de rapport de dépenses diverses",
      "gift-and-lost": () =>
        "Téléchargez un exemple de rapport sur les cadeaux sortants et les biens perdus ou volés",
      income: () => "Télécharger un exemple de rapport sur les revenus",
      inventory: () => "Télécharger un exemple de rapport d'inventaire",
      "inventory-v2": () => "Télécharger un exemple de rapport d'inventaire",
      "irs-1040-s1": () =>
        "Téléchargez l'exemple de l'annexe 1 de l'IRS (formulaire 1040)",
      "irs-1040-sd": () =>
        "Télécharger l'exemple de l'annexe D (formulaire 1040)",
      "irs-8949": () => "Téléchargez l'exemple du formulaire IRS 8949",
      "irs-8949-consolidated": () =>
        "Téléchargez l'exemple du formulaire IRS 8949 (consolidé)",
      "irs-8949-long": () =>
        "Télécharger l'exemple de formulaire 8949 à long terme",
      "irs-8949-long-consolidated": () =>
        "Télécharger l'exemple du formulaire 8949 Long terme (consolidé)",
      "irs-8949-short": () =>
        "Télécharger l'exemple de formulaire 8949 Court terme",
      "irs-8949-short-consolidated": () =>
        "Télécharger l'exemple de formulaire 8949 Consolidé à court terme",
      "modelo-100": () => "Télécharger l'échantillon du rapport Modelo 100",
      mygov: () => "Télécharger un exemple de rapport MyGov",
      "nft-capital-gains": () => "Exemple de rapport NFT téléchargé",
      "nft-report": () => "Télécharger un exemple de rapport NFT",
      pack: () => "Télécharger des exemples de rapports",
      "report-summary": () => "Télécharger un exemple de résumé du rapport",
      "start-of-year-holdings": () =>
        "Télécharger un exemple de rapport sur les avoirs de début d'année",
      "tax-lots": () =>
        "Télécharger un exemple de rapport d'inventaire instantané",
      "taxact-csv": () =>
        "Télécharger l'exemple de TaxAct - Rapport sur les gains en capital",
      "trading-pnl": () =>
        "Télécharger un exemple de rapport sur les transactions de produits dérivés",
      "trading-stock": () =>
        "Télécharger un exemple de rapport sur les transactions boursières",
      transaction: () => "Télécharger un exemple de rapport de transactions",
      "turbotax-canadian-pdf": () => "Télécharger un exemple de TurboTax PDF",
      "turbotax-csv": () =>
        "Téléchargez l'exemple de TurboTax - Rapport sur les gains en capital",
      "turbotax-txf": () =>
        "Téléchargez l'exemple de rapport TurboTax TXF - Gains en capital",
      "wiso-steuer": () => "Téléchargez un exemple de rapport CSV WISO Steuer",
    },
    reportTable: () =>
      "Veuillez effectuer une mise à niveau pour accéder au tableau de rapport",
    upgradeFor: () => "Mise à niveau pour",
    upgradeNow: () => "Mettre à jour maintenant",
  },
  pending: () => "En attente",
  planCard: {
    annualSubscription: () => "Abonnement annuel",
    bestValue: () => "Le plus populaire",
    billedYearly: () => "facturé annuellement",
    taxDeductible: () => "Déductible des impôts",
  },
  planFeatures: {
    coreFeatures: {
      features: {
        assetHistoryLedgers: () =>
          "Registres complets d'historique des actifs en partie double",
        deFiDEX: () => "Intégrations DeFi et DEX",
        fileImports: () => "API Exchange et importations CSV",
        marginTrades: () => "Négociations sur marge et contrats à terme",
        miningStaking: () =>
          "Exploitation minière, jalonnement, prêt, parachutages",
        nftTrading: () => "Négoce de NFT",
        smartErrorSuggestions: () =>
          "Suggestions intelligentes de gestion des erreurs",
        transanctionCategorisation: () =>
          "Algorithme d'auto-catégorisation des transactions",
        transferMatching: () => "Correspondance de transfert intelligente",
        walletSync: () => "Synchronisation du portefeuille en chaîne",
      },
      name: () => "Fonctionnalités avancées",
    },
    dynamic: {
      features: {
        complexTransactions: () => "Transactions complexes sur la chaîne",
        integrations: () => "Intégrations",
        inventoryAdvanced: () => "Méthodes d'inventaire avancées",
        portfolioTracking: () => "Suivi de portefeuille",
        reportPreview: () => "Aperçu du rapport gratuit",
        reportsAdvanced: () => "Rapports fiscaux avancés",
        reportsPrevYears: () => "Rapports des années fiscales précédentes",
        supportEmailChat: () => "Assistance par e-mail et chat",
        supportPriority: () => "Assistance prioritaire",
        taxLossHarvesting: () => "Outil de récupération des pertes fiscales",
        transactions: () => "Transactions dans les déclarations fiscales",
      },
      name: () => "Caractéristiques de base",
    },
    integrations: {
      features: {
        blockchains: () => "Les blockchains",
        centralisedExchanges: () => "Échanges centralisés",
        decentralisedExchanges: () => "Échanges décentralisés",
        nftMarketplaces: () => "Marchés NFT",
        smartContract: () => "Prise en charge des contrats intelligents",
      },
      name: () => "Intégrations",
    },
    smartSuggestions: {
      features: {
        airdropDetection: () => "Détection de largage aérien",
        balanceVerification: () => "Vérification automatique du solde",
        incorrectCategoristion: () => "Détection de catégorisation incorrecte",
        integration: () => "Suggestions d'intégration",
        negativeBalance: () =>
          "Avertissements sur l'historique des achats manquants",
        spamDetection: () => "Détection de jeton de spam",
        transactionCategory: () => "Suggestions de catégories de transactions",
        transferMatching: () => "Suggestions de correspondance de transfert",
      },
      name: () => "Des suggestions intelligentes",
    },
    support: {
      features: {
        chat: () => "Assistance par chat",
        email: () => "Assistance par e-mail",
        priority: () => "Assistance prioritaire",
      },
      name: () => "Soutien",
    },
    transactionsManagement: {
      features: {
        advancedFiltering: () => "Filtrage avancé",
        bulkEdit: () => "Fonctionnalité d'édition en masse",
        noteTaking: () => "Prise de notes",
        protocolIdentification: () =>
          "Identification et étiquetage du protocole",
        quickSearch: () => "Recherche rapide",
      },
      name: () => "Gestion des transactions",
    },
  },
  plans: {
    personal: () => "Personnel",
    professional: () => "Comptable",
  },
  "polygon-zkevm": {
    "wallet-api": {
      0: () =>
        "Saisissez l'adresse de votre portefeuille Polygon Hermez (zkEVM) dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
      1: {
        items: {
          0: () =>
            "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
        },
        type: () => "note",
      },
    },
  },
  popup: {
    enablePopups: () =>
      "Veuillez activer les fenêtres contextuelles pour continuer",
  },
  portfolioToggles: () => "Bascules de portefeuille",
  qbo: {
    accountMapping: {
      accounts: {
        asset: {
          title: () => "Compte d'actifs",
          tooltip: () =>
            "Compte représentant la valeur des actifs du portefeuille de l'utilisateur",
        },
        cash: {
          title: () => "Compte de caisse",
          tooltip: () =>
            "Compte représentant les avoirs en espèces. Si vous vendez des crypto-monnaies, nous représenterons le produit en espèces ici et vice versa lorsque vous achetez des crypto-monnaies. Les échanges de crypto à crypto n'auront aucun impact sur ce compte.",
        },
        expense: {
          title: () => "Compte de dépenses",
          tooltip: () => "Compte représentant les dépenses cryptographiques",
        },
        fee: {
          title: () => "Compte de frais",
          tooltip: () => "Compte représentant le montant payé en frais",
        },
        income: {
          title: () => "Compte de revenus",
          tooltip: () =>
            "Compte représentant les revenus provenant de la crypto",
        },
        loan: {
          title: () => "Compte de prêt",
          tooltip: () =>
            "Compte représentant des prêts et remboursements cryptographiques",
        },
        loss: {
          title: () => "Compte de pertes",
          tooltip: () => "Compte représentant le montant de crypto perdu",
        },
        "realised-gain": {
          title: () => "Compte de gain réalisé",
          tooltip: () =>
            "Compte représentant les gains qui ont été réalisés à partir de la cession d'actifs cryptographiques",
        },
        "realised-loss": {
          title: () => "Compte de pertes réalisées",
          tooltip: () =>
            "Compte représentant les pertes résultant de la cession d'actifs cryptographiques",
        },
        "zero-cost-buy": {
          title: () => "Historique d'achat manquant",
          tooltip: () =>
            "Lorsqu'un actif est acheté ou obtenu et qu'aucun paiement n'est effectué pour celui-ci, nous considérons que le prix de base de l'actif est la valeur marchande et ce compte est utilisé pour équilibrer l'autre côté du journal.",
        },
      },
      lastSynced: (d) => "Dernière synchronisation " + d.date,
      refreshInstructions: () =>
        "Si vous avez créé un nouveau compte sur QuickBooks et que vous ne le voyez pas, actualisez la liste.",
      syncError: () => "Nous n'avons pas pu synchroniser les données.",
      syncInstructions: () =>
        "Pour démarrer la synchronisation, vous devez choisir les comptes auxquels les données seront mappées. Vous pouvez mettre à jour les comptes à tout moment. La synchronisation peut être démarrée lorsque le mappage est défini.",
      title: () => "Cartographie des comptes",
    },
    buttons: {
      disconnect: () => "Se déconnecter de QuickBooks",
      refreshList: {
        completed: () => "Liste des comptes QuickBooks actualisée",
        done: () => "Actualiser la liste des comptes",
        pending: () => "Liste actualisée",
        tooltip: {
          done: () => "Actualiser les comptes disponibles",
          pending: () => "Cela peut prendre un certain temps",
        },
      },
      syncAccounts: {
        done: () => "Synchroniser les comptes",
        pending: () => "Synchronisation des comptes",
        tooltip: {
          disabled: () =>
            "Assurez-vous que chaque compte est sélectionné pour activer la synchronisation",
          done: () => "Synchroniser les comptes avec QuickBooks",
          pending: () => "Cela peut prendre un certain temps",
        },
      },
    },
    connect: () => "Se connecter à QuickBooks",
    errors: {
      disconnecting: () =>
        "Une erreur s'est produite lors de la déconnexion de QuickBooks.",
      fetchingAccounts: () =>
        "Une erreur s'est produite lors de la récupération de la liste des comptes QuickBooks.",
      fetchingSettings: () =>
        "Une erreur s'est produite lors de la récupération des paramètres de QuickBooks.",
      fetchingTenants: () =>
        "Une erreur s'est produite lors de la récupération de la liste des locataires de QuickBooks.",
      updatingAccounts: () =>
        "Une erreur s'est produite lors de la mise à jour des comptes QuickBooks.",
      updatingTenant: () =>
        "Une erreur s'est produite lors de la mise à jour du locataire QuickBooks.",
    },
    integrationName: () => "QuickBooks en ligne",
    integrationShortName: () => "QBO",
    newIntegration: {
      connected: () => " Connecté",
      connectedAt: (d) => "Dernière connexion " + d.date,
      description: () =>
        "Résumez les activités commerciales quotidiennes dans des journaux comptables dans QuickBooks. Cela peut vous aider à suivre vos finances et à comprendre les sources de revenus, de pertes et de dépenses. Les mappages de comptes peuvent être personnalisés pour correspondre à la façon dont vous gérez votre comptabilité.",
      disconnectConfirmation: () =>
        "Attention ! Êtes-vous sûr de vouloir déconnecter votre compte QuickBooks ? Cette action ne peut pas être annulée, vous devrez resélectionner vos comptes à l'avenir. Vos journaux synchronisés resteront dans QuickBooks.",
      disconnected: () => "Déconnecté",
      status: () => "Statut: ",
      title: () => "Connectez votre compte QuickBooks",
    },
    reportFooter: {
      settingsLink: () => "Afficher les paramètres pour plus d'informations",
      subTitle: () =>
        "Connectez le calculateur de taxes cryptographiques à QuickBooks",
      title: () => "Intégration QuickBooks",
    },
    rollup: {
      day: () => "Jour",
      description: {
        day: () =>
          "Toutes les transactions d'un jour calendaire donné seront regroupées dans un seul journal",
        month: () =>
          "Toutes les transactions d'un mois calendaire donné seront regroupées dans un seul journal",
        none: () =>
          "Toutes les transactions seront exportées sous forme de journaux individuels",
      },
      month: () => "Mois",
      none: () => "Pas de cumul",
    },
    syncDialog: {
      confirmation: () =>
        "Êtes-vous sûr de vouloir effectuer la synchronisation ? Veuillez vous assurer que vos données sont correctes. Les modifications apportées aux transactions déjà synchronisées ne seront pas reflétées dans QuickBooks.",
      error: () => "Oh non ! On dirait qu'il y a eu un problème...",
      syncing: () =>
        "Nous synchronisons actuellement les données, cela peut prendre un certain temps...",
    },
    tenantList: {
      ensureCorrectTenant: () =>
        "Assurez-vous que le bon compte est sélectionné pour le flux de données.",
      exportJournals: () => "Exporter des journaux",
      listPlaceholder: () => "Sélectionner le flux de données",
      listTitle: () => "Compte QuickBooks",
    },
  },
  reOnboarding: {
    sync: {
      ExchangeBlockchain: () => "Bourse/blockchain",
      lastSynced: () => "Dernière synchronisation",
      noImport: () =>
        "Aucun échange ou blockchain détecté, ajoutez votre premier compte pour commencer",
    },
    welcomeBack: {
      afterSyncBanner: () =>
        "La synchronisation peut prendre un certain temps, en attendant, révisez les principales règles fiscales et accédez à l'application !",
      brushUp: () => "Révisez les principales règles fiscales",
      integrationsPage: () => "page d'intégrations",
      letMeIn: () => "Laisse-moi juste entrer",
      subTitle: () =>
        "Avant de vous y remettre, pensez à resynchroniser vos comptes et à importer tous les nouveaux comptes, voici une liste de vos échanges et blockchains :",
      sync: () => "Synchroniser",
      syncFailedBanner: () =>
        "Certaines synchronisations d'intégration ont échoué, pour résoudre ces problèmes, passez à la",
      title: () =>
        "Bienvenue mon vieil ami, il est temps de refaire mes impôts",
    },
  },
  reconciliation: {
    allTransactions: () => "Toutes transactions",
    api: {
      assignBlockchainSource: () =>
        "Une erreur s'est produite lors de l'attribution de la blockchain. Veuillez réessayer.",
      bulkLookupMarketPriceError: () =>
        "Une erreur s'est produite lors de la recherche de ces devises. Veuillez réessayer.",
      generateTransferError: () =>
        "Une erreur s'est produite lors de la génération des transferts. Veuillez réessayer.",
      identifyUnknownParty: () =>
        "Une erreur s'est produite lors du remplacement du tiers inconnu. Veuillez réessayer.",
      ignoreAllMissingPriceError: () =>
        "Une erreur s'est produite lors de l'ignorance de tous les problèmes de prix du marché manquants. Veuillez réessayer.",
      ignoreError: () =>
        "Une erreur s'est produite lors de l'ignorance du problème. Veuillez réessayer.",
      markAirdropError: () =>
        "Une erreur s'est produite lors du marquage de cette transaction comme largage aérien. Veuillez réessayer.",
      markAllAirdropError: () =>
        "Une erreur s'est produite lors du marquage de ces transactions comme largages aériens. Veuillez réessayer.",
      markAllSpamCurrencyError: () =>
        "Une erreur s'est produite lors du marquage de ces transactions comme spam. Veuillez réessayer.",
      markSpamCurrencyError: () =>
        "Une erreur s'est produite lors du marquage de cette transaction comme spam. Veuillez réessayer.",
      refreshError: () =>
        "Une erreur s'est produite lors de l'actualisation des données de rapprochement. Veuillez réessayer.",
    },
    backToTransactions: () => "Retour aux transactions",
    completedCountChip: (d) => d.issueCount + " % catégorisé",
    completedCountEmbeddedChip: (d) => d.issueCount + " % terminé",
    explainer: {
      consider: () => "Tenez compte des éléments suivants :",
      done: () => "Fait",
      usefulLinks: () => "Liens utiles :",
    },
    fullyReconciledState: {
      ctaText: () => "Obtenir un rapport fiscal",
      disclaimer: () =>
        "Assurez-vous toujours de vérifier que tout semble correct.",
      neutralMessage: () =>
        "Il n'y a actuellement aucune suggestion de réconciliation.",
      successMessage: () => "Toutes les suggestions ont été résolues !",
      successSubMessage: () =>
        "Vous avez mis en œuvre toutes nos suggestions de réconciliation, bon travail !",
    },
    helpArticles: () => "Articles d'aide",
    ignoreIssue: () => "Ignorer le problème",
    ignoreWarning: () => "Ignorer l'avertissement",
    impactChip: {
      high: () => "Impact élevé",
      low: () => "Faible impact",
      medium: () => "Impact moyen",
    },
    issueCountChip: (d) =>
      plural(d.issueCount, 0, fr, {
        one: " 1 suggestion ",
        other: number("fr", d.issueCount, 0) + " suggestions ",
      }),
    issues: {
      incorrectSendReceives: {
        associatedTransactions: () => "Transactions",
        ctaButton: {
          importDestination: () => "Destination d'importation",
          importSource: () => "Source d'importation",
          name: () => "Nom",
        },
        description: () =>
          "Les transactions de cette catégorie ont été classées par erreur comme Envois ou Réceptions internes. L'envoi et la réception ne doivent être utilisés qu'entre les portefeuilles qui vous appartiennent. Ces transactions doivent avoir une catégorie différente ou le portefeuille inconnu doit être importé.",
        ignore: () => "Ignorer",
        ignoreIssue: {
          action: () => "Ignorer le problème",
          description: (d) =>
            "Cela ignorera tous les avertissements d'importation suspectés d'être manquants sur les transactions vers ou depuis " +
            select(d.source, { missing: "this source", other: d.source }) +
            ".",
          title: () => "Ignorer le problème d'importation manquant suspecté ?",
        },
        importSource: () => "Source d'importation",
        imports: {
          address: () => "Adresse",
          bannerText: () =>
            "L'importation d'un portefeuille qui n'est pas le vôtre ou une adresse de contrat aura un impact sur vos obligations fiscales.",
          bannerTitle: () =>
            "Assurez-vous que ce portefeuille vous appartient avant de l’importer !",
          goBack: () => "Retourner",
          label: () => "Trouvez votre bourse ou votre portefeuille",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
            title: () => "Résoudre les problèmes d'équilibre en vol",
          },
        },
        missingSource: () => "Source",
        navButton: () => "Envois/réceptions incorrects",
        notMyWallet: () => "Ce n'est pas mon portefeuille",
        steps: {
          0: () =>
            "La source ou la destination vous appartient-elle ? Utilisez le bouton d'importation et importez l'échange ou le portefeuille.",
          1: () =>
            "Si la source ou la destination ne vous appartient pas. Développez l'accordéon et catégorisez correctement les transactions.",
        },
        tableHeader: {
          assets: () => "Actifs",
          associatedGainsLaptop: () => "Gains associés",
          destination: () => "Destination",
          source: () => "Source",
          transactions: () => "Les Tx",
          transactionsLaptop: () => "Transactions",
          transferAmount: () => "Montant",
          transferAmountLaptop: () => "Montant du transfert",
        },
        title: () => "Envois/réceptions incorrects",
        transferBalance: () => "Montant du transfert",
        transferBalanceTooltip: () =>
          "Le montant du transfert représente la valeur fiduciaire de la crypto-monnaie envoyée vers et depuis cette importation suspectée de disparition",
        viewTransactions: () => "Voir les transactions",
      },
      missingBlockchain: {
        assignBlockchain: () => "Attribuer une blockchain",
        chipTitle: () => "Résoudre les problèmes de blockchains manquants",
        description: () => "Attribuer une chaîne à ces transactions",
        headers: {
          amount: () => "Montant",
          assets: () => "Actifs",
          source: () => "Source",
          transactions: () => "Transactions",
          transferAmount: () => "Montant du transfert",
          txs: () => "Les Tx",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6388387-how-to-resolve-missing-blockchain-warnings",
            title: () =>
              "Comment résoudre les avertissements de blockchain manquants",
          },
        },
        navButton: () => "Attribuer des blockchains",
        steps: {
          0: () =>
            "Reconnaissez-vous la transaction et vous souvenez-vous de la blockchain ?",
          1: () =>
            "Quel explorateur de blocs renvoie un résultat pour l'ID de transaction ?",
        },
        title: () => "Blockchains manquantes",
      },
      missingPrice: {
        added: () => "Ajouté",
        bulkLookupButton: {
          failUpdated: () =>
            "Impossible de trouver les prix du marché pour ces transactions",
          label: () => "Recherche de prix",
          successUpdated: (d) =>
            "Prix du marché mis à jour pour " +
            plural(d.count, 0, fr, {
              one: " 1 transaction ",
              other: number("fr", d.count, 0) + " transactions ",
            }),
        },
        chipTitle: () => "Résoudre les prix manquants",
        ctaButton: {
          label: () => "Ignorer l'avertissement",
          labelLaptop: () => "Ignorer l'avertissement",
        },
        description: () =>
          "Les prix d'une devise dans les transactions suivantes n'ont pas pu être trouvés",
        drawer: {
          cta: () => "Pourquoi les prix manquent-ils ?",
          headerTwo: () =>
            "Que se passe-t-il si le jeton dont le prix manque est courant et répertorié sur des services comme Coingecko ?",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5411667-how-to-fix-missing-market-price-warning",
              title: () => "Prix du marché manquants",
            },
          },
          linkSectionHeader: () => "Articles d'aide",
          listItems: {
            0: () => "Le jeton a une liquidité extrêmement faible.",
            1: () =>
              "Le jeton est tout nouveau et n’a pas encore été capturé par l’oracle des prix.",
            2: () =>
              "Le jeton n'est pas coté sur les principales bourses et dexes.",
            3: () => "Non répertorié.",
          },
          listTitle: () =>
            "Quelques scénarios dans lesquels les informations sur les prix historiques du marché sont manquantes :",
          paragraphOne: () =>
            "Les prix manquants se produisent généralement lorsque le prix d'un jeton n'existe dans aucune des bases de données Oracle de tarification que nous utilisons. Cela est extrêmement rare car nous avons des prix pour plus de 300 000 devises.",
          paragraphTwo: () =>
            "Si vous parvenez à trouver le jeton sur Coingecko, il est fort probable que CTC attribue la mauvaise instance du jeton. Par exemple, un jeton avec le même ticker a été attribué à la transaction. Vous pouvez résoudre le problème en cliquant sur la devise et en modifiant la devise dans le menu fourni.",
        },
        ignoreAllIssue: {
          action: () => "Ignorer tout",
          description: () =>
            "Cette opération ignorera les avertissements de prix manquants pour toutes les transactions de votre compte et est irréversible. Vous ne devez continuer que si les prix sont de 0 $.",
          title: () => "Ignorer tous les avertissements de prix manquants ?",
        },
        ignoreIssue: {
          action: () => "Ignorer les avertissements",
          description: (d) =>
            select(d.name, {
              unknown:
                " Ceci ignorera tous les avertissements de prix manquants sur les transactions qui utilisent cette devise. ",
              other:
                " Ceci ignorera tous les avertissements de prix manquants sur les transactions qui utilisent " +
                d.name +
                " . ",
            }),
          title: (d) =>
            "Ignorer les problèmes de prix manquants pour " +
            select(d.name, { unknown: " this currency ", other: " name " }) +
            select(d.ticker, { unknown: "", other: " ( " + d.ticker + " ) " }) +
            " ?",
        },
        missingPrice: () => "Prix manquant",
        navButton: () => "Résoudre les prix manquants",
        tableHeader: {
          assets: () => "Actifs",
          associatedGains: () => "Gains",
          associatedGainsLaptop: () => "Gains associés",
          currency: () => "Devise",
          transactions: () => "Les Tx",
          transactionsLaptop: () => "Transactions",
        },
        title: () => "Transactions avec prix manquants",
        viewTransactions: () => "Voir les transactions",
      },
      negativeBalance: {
        amountExplanation: () =>
          "Si vous ne corrigez pas un problème d'historique d'achat manquant, vos obligations fiscales seront calculées comme si votre base de coût pour tout actif concerné était nulle. Cela augmentera votre impôt à payer.",
        chipTitle: () => "Résoudre l'historique d'achat manquant",
        ctaButton: {
          label: () => "Grand livre des devises",
          labelLaptop: () => "Afficher dans le tableau des transactions",
        },
        currency: () => "Devise",
        description: () =>
          "L'historique des achats manquants se produit lorsque plus d'actifs cryptographiques ont été vendus que ceux initialement achetés. Cela suggère que certains enregistrements d'acquisition peuvent être manquants, non catégorisés ou classés de manière incorrecte.",
        drawer: {
          cta: () => "Qu'est-ce qui manque dans l'historique des achats ?",
          headerTwo: () => "Résolution de l'historique d'achat manquant.",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
              title: () =>
                "Résoudre les problèmes d'historique d'achat manquant",
            },
            1: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5305370-faq-what-is-a-zero-cost-buy",
              title: () => "Qu'est-ce qu'un achat à coût nul",
            },
          },
          linkSectionHeader: () => "Articles d'aide",
          listTitle: () =>
            "Si vous avez définitivement téléchargé tous vos comptes, des avertissements d'historique d'achat manquant peuvent survenir pour une ou plusieurs des raisons suivantes :",
          orderedListItems: {
            0: () => "Un achat ou un échange manquant",
            1: () =>
              "Les transactions n'ont pas été catégorisées ou ont été catégorisées de manière incorrecte. Par exemple, un achat a été catégorisé comme une réception.",
            2: () =>
              "Un envoi/réception manquant Les envois et les réceptions doivent toujours être regroupés dans un transfert. Par exemple, chaque envoi doit avoir une réception correspondante et vice versa.",
          },
          paragraphOne: () =>
            "L'historique des achats manquants se produit lorsque plus d'actifs cryptographiques ont été vendus que ceux initialement achetés. Cela suggère que certains enregistrements d'acquisition peuvent être manquants, non catégorisés ou classés de manière incorrecte.",
          paragraphThree: () =>
            "Dans certains cas, l'un de vos échanges peut ne pas parvenir à signaler avec précision les récompenses ou les frais de jalonnement.",
          paragraphTwoBoldSection: () =>
            "La première étape pour résoudre le problème de l’historique d’achat manquant est de vous assurer que vous avez téléchargé TOUS vos comptes de portefeuille et d’échange.",
          paragraphTwoRegularSection: () =>
            "Il s’agit de la cause la plus courante, car elle entraîne des lacunes importantes dans votre historique de transactions.",
        },
        gains: () => "Gains associés",
        grouping: {
          byCurrency: () => "Par devise",
          byWallet: () => "Par importation",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
            title: () => "Résoudre les problèmes d'historique d'achat manquant",
          },
          1: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
            title: () => "Résoudre les problèmes d'équilibre en vol",
          },
          2: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5284400-balance-remaining-is-incorrect",
            title: () => "Le solde restant est incorrect",
          },
          3: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5305370-handling-zero-cost-buy-transactions",
            title: () => "Gestion des transactions d'achat à coût nul",
          },
        },
        navButton: () => "Résoudre l'historique d'achat manquant",
        steps: {
          0: () =>
            "Assurez-vous d’avoir importé toutes les transactions de tous les exercices financiers, y compris les transactions OTC.",
          1: () =>
            "Cliquez sur les transactions associées et une nouvelle fenêtre s'ouvrira avec toutes les transactions de cette source particulière. Assurez-vous que toutes les transactions sont catégorisées.",
          2: () =>
            "Créez une transaction manuelle pour gérer les données manquantes des échanges ou des transactions OTC.",
        },
        tableHeader: {
          amountNegative: () => "Montant",
          amountNegativeDesktop: () => "Montant manquant",
          assets: () => "Devises",
          associatedGains: () => "Gains",
          associatedGainsLaptop: () => "Gains associés",
          currency: () => "Devise",
          source: () => "Source",
          taxImpact: () => "Impact fiscal",
          transactions: () => "Les Tx",
          transactionsDesktop: () => "Transactions",
          transferAmount: () => "Montant",
          transferAmountDesktop: () => "Montant du transfert",
        },
        title: () => "Réviser l'historique des achats manquants",
        transactions: () => "Transactions",
        viewLedger: () => "Grand livre des devises",
        viewTransactions: () => "Voir les transactions",
      },
      negativeBalanceByWallet: {
        title: () => "Problèmes d'historique d'achat manquant",
      },
      smartContractInteractions: {
        categorise: () => "Classer par catégories",
        description: () =>
          "Ces transactions sont des interactions entre vos portefeuilles et vos contrats intelligents. Examinez chaque adresse de contrat et étiquetez-les avec un nom approprié si nécessaire, puis catégorisez les transactions.",
        navButton: () => "Catégoriser les transactions",
        tableHeader: {
          addresses: () => "Adresses",
          amount: () => "Montant",
          assets: () => "Actifs",
          transactions: () => "Transactions",
          transferAmount: () => "Montant du transfert",
          txs: () => "Les Tx",
        },
        title: () => "Interactions entre contrats intelligents",
      },
      suspectedAirdropTransaction: {
        description: () =>
          "Les largages aériens suspects incluent les adresses de sources de largage aérien connues. Marquez une adresse pour confirmer qu'il s'agit d'un largage aérien.",
        links: {
          0: {
            href: () =>
              "https://cryptotaxcalculator.io/blog/crypto-airdrop-tax",
            title: () => "Comment sont taxés les parachutages ?",
          },
        },
        markAllAsTrade: {
          button: () => "Marquer tout comme Airdrops",
          confirm: () => "Confirmer",
          description: () =>
            "Cela attribuera la catégorie « Airdrop » à toutes les transactions répertoriées ici et résoudra ces problèmes.",
          title: () => "Marquer toutes les transactions comme des Airdrops ?",
        },
        markAsNotTrade: () => "Marquer comme non Airdrop",
        markAsTrade: () => "Marquer comme Airdrop",
        markSelectedAsTrade: () => "Marquer sélectionné comme parachutage",
        navButton: () => "Catégoriser les transactions",
        notTrade: () => "Pas de largage aérien",
        steps: {
          0: () => "Reconnaissez-vous le nom du jeton ?",
          1: () =>
            "Vous souvenez-vous d’avoir interagi avec le protocole pour recevoir le jeton ?",
        },
        title: () => "Largages aériens suspects",
        trade: () => "Parachutage aérien",
      },
      suspectedBurnTransaction: {
        description: () =>
          "Nous avons détecté des transactions qui ont envoyé des jetons à une adresse de gravure connue. Indiquez si ces transactions sont des gravures.",
        markAllAsTrade: {
          button: () => "Tout marquer comme brûlé",
          confirm: () => "Confirmer",
          description: () =>
            "Cela attribuera la catégorie « Graver » à toutes les transactions répertoriées ici et résoudra ces problèmes.",
          title: () => "Marquer toutes les transactions comme brûlées ?",
        },
        markAsNotTrade: () => "Marquer comme non brûlé",
        markAsTrade: () => "Marquer comme brûlé",
        markSelectedAsTrade: () => "Marquer sélectionné comme brûlé",
        navButton: () => "Catégoriser les transactions",
        notTrade: () => "Ne brûle pas",
        steps: {
          0: () => "Reconnaissez-vous le nom du jeton ?",
          1: () =>
            "Vous souvenez-vous d’avoir envoyé les jetons à une adresse de gravure ?",
        },
        title: () => "Transactions suspectées de Burn",
        trade: () => "Brûler",
      },
      suspectedMissingImports: {
        associatedTransactions: () => "Transactions",
        ctaButton: {
          label: () => "Importer",
          name: () => "Nom",
        },
        description: () =>
          "Les importations suspectées d'être manquantes sont des adresses avec lesquelles vous avez interagi et qui n'ont pas été importées dans Crypto Tax Calculator. Résolvez les avertissements de cette catégorie de rapprochement en important l'adresse manquante ou en confirmant que ce portefeuille ne vous appartient pas.",
        ignore: () => "Ignorer",
        ignoreIssue: {
          action: () => "Ignorer le problème",
          description: (d) =>
            "Cela ignorera tous les avertissements d'importation suspectés d'être manquants sur les transactions vers ou depuis " +
            select(d.source, { missing: "this source", other: d.source }) +
            ".",
          title: () => "Ignorer le problème d'importation manquant suspecté ?",
        },
        importSource: () => "Source d'importation",
        imports: {
          address: () => "Adresse",
          bannerText: () =>
            "L'importation d'un portefeuille qui n'est pas le vôtre ou une adresse de contrat aura un impact sur vos obligations fiscales.",
          bannerTitle: () =>
            "Assurez-vous que ce portefeuille vous appartient avant de l’importer !",
          goBack: () => "Retourner",
          label: () => "Trouvez votre bourse ou votre portefeuille",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279670-import-exchange-transactions",
            title: () => "Opérations de change à l'importation",
          },
          1: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279724-import-wallet-and-defi-transactions",
            title: () => "Importer des transactions de portefeuille et DeFi",
          },
          2: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5597463-how-to-add-an-additional-wallet-for-the-same-blockchain",
            title: () =>
              "Comment ajouter un portefeuille supplémentaire pour la même blockchain ?",
          },
        },
        missingSource: () => "Source",
        navButton: () => "Importations suspectées de disparition",
        notMyWallet: () => "Ce n'est pas mon portefeuille",
        steps: {
          0: () =>
            "Avez-vous transféré des actifs entre vos propres portefeuilles ? Vous avez peut-être oublié d'importer les données de l'un de ces portefeuilles.",
          1: () =>
            "S'il s'agit d'une transaction entre vous et un portefeuille externe, vous pouvez sélectionner la catégorie qui décrit le mieux ces transactions. Ne choisissez pas « Envoyer » ou « Recevoir », car ces catégories ne doivent être utilisées que pour les transferts entre les portefeuilles que vous possédez.",
        },
        tableHeader: {
          assets: () => "Actifs",
          associatedGainsLaptop: () => "Gains associés",
          source: () => "Source",
          transactions: () => "Les Tx",
          transactionsLaptop: () => "Transactions",
          transferAmount: () => "Montant",
          transferAmountLaptop: () => "Montant du transfert",
        },
        title: () => "Importations suspectées de disparition",
        transferBalance: () => "Montant du transfert",
        transferBalanceTooltip: () =>
          "Le montant du transfert représente la valeur fiduciaire de la crypto-monnaie envoyée vers et depuis cette importation suspectée de disparition",
        viewImport: () => "Afficher l'importation",
        viewTransactions: () => "Voir les transactions",
      },
      suspectedSpamToken: {
        currency: () => "Devise",
        description: () =>
          "Les jetons suspects de spam sont identifiés comme n'ayant aucune donnée de prix de marché, n'ayant qu'une seule transaction entrante ou provenant d'une source de spam connue. Marquer une transaction comme spam la masquera et l'exclura de vos calculs de solde.",
        headers: {
          asset: () => "Actif",
          price: () => "Prix",
          source: () => "Source",
          transactions: () => "Transactions",
          txs: () => "Les Tx",
          value: () => "Valeur",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6185693-dealing-with-scam-airdrops",
            title: () => "Faire face aux parachutages frauduleux",
          },
        },
        markAllAsSpam: {
          button: () => "Marquer tout comme spam",
          confirm: () => "Confirmer",
          description: () =>
            "Cela attribuera la catégorie « Spam » à toutes les transactions répertoriées ici et résoudra ces problèmes.",
          title: () => "Marquer toutes les transactions comme spam ?",
        },
        markAsNotSpam: () => "Marquer comme non-spam",
        markAsSpam: () => "Marquer comme spam",
        markSelectedAsSpam: () => "Marquer la sélection comme spam",
        navButton: () => "Catégoriser les transactions",
        notSpam: () => "Pas de spam",
        spam: () => "Courrier indésirable",
        steps: {
          0: () => "Reconnaissez-vous le nom du jeton ?",
          1: () =>
            "Vous souvenez-vous d’avoir interagi avec le protocole pour recevoir le jeton ?",
          2: () =>
            "Si aucune des situations ci-dessus ne s'applique, ce jeton est probablement une tentative d'escroquerie et doit être marqué comme spam.",
        },
        title: () => "Jetons de spam suspectés",
        transactionSource: () => "Source de la transaction",
        transactions: () => "Transactions",
      },
      uncategorisedIntraExchangeTransactions: {
        associatedTransactions: () => "Transactions",
        ctaButton: {
          label: () => "Classer par catégories",
        },
        description: () =>
          "Les transactions intra-bourses sont des transactions dont la source et la destination sont toutes deux la même bourse. Les transactions de cette catégorie sont généralement des paiements d'intérêts, des récompenses de jalonnement, des revenus ou des parachutages.",
        missingSource: () => "Source",
        navButton: () => "Catégoriser les transactions",
        tableHeader: {
          assets: () => "Actifs",
          associatedGainsLaptop: () => "Gains associés",
          source: () => "Source",
          transactions: () => "Les Tx",
          transactionsLaptop: () => "Transactions",
          transferAmount: () => "Montant",
          transferAmountLaptop: () => "Montant du transfert",
        },
        title: () => "Transactions intra-boursières",
        transferBalance: () => "Montant du transfert",
        transferBalanceTooltip: () =>
          "Le montant du transfert représente la valeur fiduciaire de la crypto-monnaie envoyée vers et depuis cette importation suspectée de disparition",
        viewTransactions: () => "Voir les transactions",
      },
      uncategorisedTransactions: {
        button: () => "Classer par catégories",
        chipTitle: () => "Catégoriser les transactions",
        description: () =>
          "Appliquez la catégorie appropriée aux transactions suivantes pour améliorer la précision de votre rapport",
        drawer: {
          cta: () => "En savoir plus sur la catégorisation",
          headerOne: () => "Pourquoi la catégorisation est importante",
          headerTwo: () =>
            "Tout ne devrait-il pas être catégorisé automatiquement ?",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5279619-categorize-transactions",
              title: () => "Guide détaillé pour tous les types de transactions",
            },
          },
          linkSectionHeader: () => "Articles d'aide",
          paragraphOne: () =>
            "La catégorisation permet à CTC d'appliquer le résultat fiscal approprié à la transaction et de suivre avec précision la base de coût dans l'ensemble de votre historique de transactions. Les transactions non catégorisées sont étiquetées comme « entrantes » et « sortantes ». Elles sont ignorées de vos calculs fiscaux, ce qui peut entraîner des gains en capital plus élevés en raison des lacunes dans le suivi de votre base de coût.",
          paragraphTwo: () =>
            "Si vous avez importé tous vos comptes, même ceux que vous n'utilisez plus, la plupart de vos transactions devraient être classées automatiquement. Dans certains cas, il n'a pas été possible de déterminer une catégorie de transaction appropriée. Plutôt que de faire des suppositions, nous les mettons en évidence pour que vous puissiez les clarifier.",
        },
        inContext: {
          categorised: () => "Catégorisé!",
          finish: () => "Finition",
          goToNext: () => "Aller à la transaction suivante",
          nextTransaction: () => "Prochaine transaction",
          notSure: () =>
            "Vous n'êtes pas sûr ? Passez à la transaction suivante",
          previousTransaction: () => "Transaction précédente",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279619-categorise-transactions",
            title: () => "Catégoriser les transactions",
          },
        },
        navButton: () => "Catégoriser les transactions",
        similarChip: (d) => d.count + " similaire",
        steps: {
          0: () =>
            "Assurez-vous d’avoir importé toutes vos données depuis toutes les sources possibles.",
          1: () =>
            "Attribuez manuellement les catégories des transactions que vous voyez ci-dessous.",
        },
        title: () => "Clarifier les transactions",
        transactionSource: () => "Source de la transaction",
        type: () => "Catégorie",
        uncategorisedTransactions: () => "Transactions non classées",
        viewSwitcher: {
          inContext: () => "Dans le contexte",
          onlyUncategorised: () => "Uniquement non catégorisé",
        },
        viewTransactions: () => "Voir les transactions",
      },
      uncategorisedWalletInteractions: {
        associatedTransactions: () => "Transactions",
        categorise: () => "Classer par catégories",
        description: () =>
          "Les interactions de portefeuille sont d'autres adresses de portefeuille avec lesquelles vos portefeuilles importés ont interagi, comme vos propres portefeuilles, les portefeuilles d'échange ou le portefeuille d'une autre personne. Résolvez ces avertissements en catégorisant correctement les interactions.",
        navButton: () => "Catégoriser les transactions",
        steps: {
          0: () =>
            "Avez-vous déjà transféré des actifs entre vos propres portefeuilles ? Si tel est le cas, les transactions sortantes doivent être classées dans la catégorie « Envoyer » et les transactions entrantes dans la catégorie « Recevoir ».",
          1: () =>
            "Si vous avez interagi avec le portefeuille de quelqu'un d'autre, vous souhaiterez peut-être envisager des catégories telles que cadeau, usage personnel, vente ou achat.",
        },
        tableHeader: {
          assets: () => "Actifs",
          transactions: () => "Les Tx",
          transactionsLaptop: () => "Transactions",
          transferAmount: () => "Montant",
          transferAmountLaptop: () => "Montant du transfert",
          wallets: () => "Portefeuilles",
        },
        title: () => "Interactions avec le portefeuille",
        transferBalance: () => "Montant du transfert",
      },
      unknownParty: {
        ctaButton: {
          label: () => "Remplacer une partie inconnue",
        },
        description: () =>
          "Une partie inconnue se produit lorsqu'un portefeuille/une plateforme d'échange ne signale pas la source ou la destination d'une transaction. Cela signifie que le mouvement d'actifs entrant ou sortant est signalé, mais que l'autre partie est considérée comme « inconnue ». Pour corriger ce problème, la source/destination inconnue doit être remplacée par une partie connue.",
        modal: {
          actionButton: () => "Remplacer une partie inconnue",
          actionButtonMobile: () => "Remplacer le parti",
          cancelButton: () => "Annuler",
          description: (d) =>
            "Cela remplacera toutes les parties inconnues " +
            d.direction +
            " sur",
          destinationDirection: () => "destination",
          exchangePlaceholder: () => "Partie connue",
          sourceDirection: () => "source",
          title: () => "Remplacer le parti",
        },
        navButton: () => "Identifier la partie",
        steps: {
          0: () =>
            "Avez-vous importé tous vos portefeuilles et échanges ? Notre algorithme de correspondance peut être en mesure de détecter et de résoudre automatiquement la partie inconnue.",
          1: () =>
            "Savez-vous où l'actif a été envoyé ou d'où il a été reçu ? Si c'est le cas, vous pouvez affecter manuellement la partie à la transaction à l'aide du bouton « Remplacer la partie inconnue ».",
        },
        tableHeader: {
          Destination: () => "Destination",
          assets: () => "Actifs",
          source: () => "Source",
          transactions: () => "Transactions",
          transactionsMobile: () => "Txs",
          transferAmount: () => "Montant du transfert",
          transferAmountMobile: () => "Montant",
        },
        title: () => "Parties inconnues",
      },
      unmatchedTransfer: {
        button: () => "Résoudre",
        chipTitle: () => "Résoudre les transferts non appariés",
        ctaButton: {
          import: () => "Contrepartie d'importation",
          recategorise: () => "Recatégoriser",
        },
        description: () =>
          "Reliez correctement tous les transferts pour garantir que toutes vos bases de coûts sont correctement calculées",
        drawer: {
          cta: () => "Comment résoudre les transferts non appariés",
          headerFive: () => "Pour résoudre ces divergences, vous pouvez soit :",
          headerFour: () => "Résolution des transferts non appariés",
          headerOne: () => "Que sont les transferts non appariés ?",
          headerSix: () =>
            "Une correspondance précise des transferts est essentielle pour un calcul fiscal correct et pour garantir que votre historique de transactions cryptographiques est complet.",
          headerThree: () =>
            "Cela se produit généralement dans trois scénarios :",
          headerTwo: () => "Comprendre les transferts non appariés",
          listOneItems: {
            0: () =>
              "Vous avez envoyé ou reçu des crypto-monnaies d'un portefeuille qui n'a pas été importé sur votre compte",
            1: () =>
              "Une transaction a été classée comme envoi ou réception, mais nous ne parvenons pas à trouver son équivalent correspondant",
            2: () =>
              "Vous avez utilisé un pont pour transférer des actifs entre différentes blockchains, et nous ne pouvons voir qu'un seul côté de la transaction du pont",
          },
          listThreeItems: {
            0: () =>
              "Reclasser la transaction si elle a été incorrectement étiquetée comme envoi/réception ou pont sortant/entrant",
          },
          listTwoItems: {
            0: () =>
              "Importez le portefeuille de contrepartie manquant pour compléter la paire de transfert",
          },
          paragraphOne: () =>
            "Les transferts non appariés se produisent lorsqu'une transaction sortante de votre portefeuille importé vers une destination inconnue, ou lorsqu'une transaction d'envoi/réception manque de sa paire de transfert correspondante.",
          paragraphThree: () =>
            "Si vous avez un transfert non apparié entre deux sources que vous avez déjà importées, vous avez deux options :",
          paragraphTwo: () =>
            "Lorsque vous envoyez ou recevez des cryptomonnaies entre des portefeuilles ou des comptes, la transaction doit toujours avoir deux côtés : le portefeuille expéditeur et le portefeuille destinataire. Un transfert sans correspondance indique que nous ne pouvons voir qu'un seul côté de la transaction, ce qui peut conduire à un suivi inexact de vos avoirs et de leur base de coûts associée.",
          subListOneItems: {
            0: () =>
              "Activez le paramètre « Assumer les transferts » pour faire correspondre automatiquement les transferts entre vos comptes",
            1: () =>
              "Générez manuellement un virement en sélectionnant « Générer un virement » dans le menu des options de la transaction",
          },
        },
        headers: {
          amount: () => "Montant",
          assets: () => "Actifs",
          source: () => "Contrepartie",
          transactions: () => "Transactions",
          transferAmount: () => "Montant du transfert",
          txs: () => "Les Tx",
        },
        navButton: () => "Résoudre les transferts non appariés",
        steps: {
          0: () =>
            "Assurez-vous d’avoir importé toutes vos données depuis toutes les sources possibles.",
          1: () =>
            "Réaffectez manuellement les catégories des transactions que vous voyez ci-dessous.",
        },
        title: () => "Résoudre les transferts non appariés",
      },
    },
    loadMore: () => "Charger plus",
    noRemainingIssues: {
      allCleared: (d) =>
        "Bon travail ! Vous avez maintenant résolu tous les problèmes " +
        d.issueTitle,
    },
    noTransactionsState: {
      importCTA: () => "Accéder aux importations",
      instructions: () => "Aucune donnée ajoutée",
      instructionsSubtext: () =>
        "Importez vos comptes pour commencer la réconciliation.",
    },
    quantity: (d) => "Quantité : " + d.amount,
    reconcileCTA: {
      itemsReconciled: () => "Transactions rapprochées",
      progressBanner: {
        body: () =>
          "Certaines transactions présentent des problèmes à corriger. Améliorer l'intégrité de vos données peut réduire votre charge fiscale.",
        completeText: () =>
          "Il n'y a aucune suggestion de réconciliation, c'est bien ! Assurez-vous toujours de vérifier que tout semble correct.",
        title: () => "État actuel de la réconciliation",
      },
      reconcileItems: (d) =>
        plural(d.issueCount, 0, fr, {
          one: " Rapprocher les transactions ",
          other: " Rapprocher les transactions ",
        }),
      suggestionBanner: {
        body: (d) =>
          "Il reste " +
          plural(d.suggestionNum, 0, fr, {
            one: " est 1 suggestion ",
            other:
              " sont " + number("fr", d.suggestionNum, 0) + " suggestions ",
          }) +
          " pour vous aider à réconcilier vos transactions.",
        cta: () => "Suggestions de révision",
        suggestionChip: (d) =>
          plural(d.suggestionNum, 0, fr, {
            one: " 1 suggestion ",
            other: number("fr", d.suggestionNum, 0) + " suggestions ",
          }),
      },
    },
    resolvedChip: {
      resolved: () => "Résolu",
    },
    sidebar: {
      knowledgeBase: () => "Base de connaissances",
      steps: {
        categorise: () => "Classer par catégories",
        correct: () => "Correct",
        import: () => "Importer",
        prepare: () => "Préparer",
        resolve: () => "Résoudre",
      },
      suggestions: (d) =>
        plural(d.count, 0, fr, {
          one: " 1 suggestion ",
          other: number("fr", d.count, 0) + " suggestions ",
        }),
      tip: {
        message: () =>
          "Pour des résultats optimaux, rapprochez vos transactions dans l’ordre dans lequel elles apparaissent de haut en bas dans la barre latérale.",
        title: () => "Conseil de pro",
      },
    },
    tipsInfoModal: {
      items: {
        0: () =>
          "Toutes les transactions sur la blockchain sont visibles par l'administration fiscale et peuvent être retracées jusqu'à votre échange KYC",
        1: () =>
          "Vous devez fournir une image complète de tout votre historique de trading",
        2: () =>
          "Nous vous aidons à identifier les problèmes afin que vous puissiez passer un audit",
      },
      subtitle: () =>
        "Nous avons identifié quelques conseils pour réduire votre impôt à payer et assurer l'exactitude des déclarations.",
      subtitleReview: () =>
        "Passez en revue ces éléments pour réduire votre obligation fiscale et garantir l’exactitude des rapports.",
      title: (d) => "Vous pourriez potentiellement économiser " + d.amount,
    },
    title: () => "Réconciliation",
    tradeSuggestion: {
      currency: () => "Devise",
      headers: {
        amount: () => "Montant",
        asset: () => "Actif",
        price: () => "Prix",
        transactions: () => "Transactions",
        txs: () => "Les Tx",
        value: () => "Valeur",
      },
      transactionSource: () => "Source de la transaction",
      transactions: () => "Transactions",
    },
    unignoreIssue: () => "Ne plus ignorer le problème",
    viewTransactions: () => "Voir les transactions",
  },
  referAFriend: {
    copyCTA: () => "Copie",
    disclaimer: (d) =>
      "*Pour chaque utilisateur qui s'inscrit à CTC et achète un forfait pour la première fois en utilisant votre lien de parrainage unique, vous recevrez " +
      d.reward +
      " en espèces ou le montant total que la personne parrainée paie pour son forfait, selon le montant le moins élevé. Les paiements seront distribués via PayPal 60 jours après la fin du mois au cours duquel ils ont souscrit à un forfait. Le montant final reçu peut varier si des fluctuations de devises se produisent entre le moment où vous gagnez la récompense et celui où vous recevez le paiement. Tous les paiements sont effectués en AUD. Pour être éligible, les utilisateurs parrainés doivent s'inscrire en utilisant votre lien de parrainage personnel. Les auto-parrainages ne sont pas éligibles pour recevoir les avantages de récompense.",
    helpCenterLink: () => "Centre d'aide",
    messageUs: (d) =>
      "Envoyez-nous un DM [@CryptoTaxHQ](" +
      d.linkTextKey +
      ") et explorons comment nous pouvons collaborer !",
    moreDetails: (d) =>
      "Pour plus de détails, veuillez consulter notre [centre d'aide](" +
      d.linkTextKey +
      ").",
    paypalSection: {
      available: () => "Disponible",
      availableTooltip: () =>
        "Le montant disponible que vous pouvez réclamer en espèces",
      claimCTA: () => "Réclamer maintenant",
      claimCTATooltip: () => "Doit attendre 60 jours avant de réclamer",
      connectCTA: () => "Connecter PayPal",
      connectedSubtext: () =>
        "Les gains sont disponibles pour réclamation une fois que la personne parrainée a été sur un forfait payant pendant 60 jours",
      error: () => "Veuillez saisir une adresse e-mail valide",
      lifetimeEarnings: () => "Gains à vie",
      lifetimeEarningsTooltip: () => "Montant total gagné et payé à vous",
      modal: {
        connectCTA: () => "Connecter",
        connectPlaceholder: () => "Saisir les détails PayPal",
        connectSubtext: () => "Saisissez votre adresse e-mail PayPal",
        connectTitle: () => "Connecter PayPal",
        disconnectCTA: () => "Déconnecter PayPal",
        updateCTA: () => "Changer PayPal",
        updatePlaceholder: () => "Saisir les nouveaux détails PayPal",
        updateSubtext: (d) => d.username + " est actuellement connecté",
        updateTitle: () => "Mettre à jour PayPal",
      },
      plansPurchased: () => "Forfaits achetés",
      plansPurchasedTooltip: () =>
        "Le nombre d'utilisateurs qui ont acheté un forfait en utilisant votre lien de parrainage",
      subtext: () =>
        "Commencez à partager votre lien de parrainage, puis connectez votre PayPal pour réclamer votre argent.",
      title: () => "Vos gains de parrainage",
      updateCTA: () => "Mettre à jour PayPal",
    },
    socialLinks: {
      email: (d) =>
        "J'utilise Crypto Tax Calculator pour mes impôts sur les cryptomonnaies - c'est rapide, facile et précis, en plus il prend en charge de nombreuses intégrations.\n\n Si vous devez encore faire les vôtres, vous pouvez utiliser mon lien de parrainage et économiser 20% sur votre forfait.\n\n " +
        d.link,
      emailSubject: () =>
        "Économisez 20% sur Crypto Tax Calculator en utilisant mon lien !",
      telegram: (d) =>
        "Pour tous ceux qui, comme moi, ont failli abandonner leurs déclarations fiscales crypto, jetez un œil à @CryptoTaxHQ. Ça a totalement préservé ma santé mentale. Si vous voulez les essayer, utilisez mon lien de parrainage et nous économisons tous les deux ! " +
        d.link,
      x: (d) =>
        "Pour tous ceux qui, comme moi, ont failli abandonner leurs déclarations fiscales crypto, jetez un œil à @CryptoTaxHQ. Ça a totalement préservé ma santé mentale. Si vous voulez les essayer, utilisez mon lien de parrainage et nous économisons tous les deux ! " +
        d.link,
    },
    subtext: (d) =>
      "Partagez CTC avec vos amis pour gagner jusqu'à " +
      d.reward +
      " en espèces pour chaque parrainage que vous effectuez. Ils économiseront 20% sur leur forfait, et vous recevrez jusqu'à " +
      d.reward +
      " en espèces pour chaque parrainage. N'est-ce pas une situation gagnant-gagnant?!",
    title: (d) => "Gagnez " + d.reward + " en espèces* pour chaque parrainage",
    workWithUs: () =>
      "Vous avez une communauté crypto ou un grand nombre d'abonnés sur X ?",
  },
  referrals: {
    description: () =>
      "Lorsqu'un ami s'inscrit à CTC et achète un forfait pour la première fois en utilisant votre lien de parrainage, vous obtenez un crédit de 40 $ sur votre abonnement. Cumulez suffisamment de crédits et votre prochain abonnement pourrait être gratuit. Pour être éligible, votre ami doit s'inscrire en utilisant votre lien de parrainage personnel.",
    linkText: () => "Votre lien de parrainage :",
    subtext: () =>
      "Bénéficiez d'une réduction de 40 $ sur CTC pour chaque ami que vous parrainez. Ils bénéficieront également d'une réduction de 40 $. Ka-ching 💰",
    title: () => "Donnez 40 $ et recevez 40 $",
  },
  report: {
    ACB: () => "Coût de base ajusté",
    AVCO: () => "Coût moyen",
    FIFO: () => "Premier entré, premier sorti",
    HIFO: () => "Le plus élevé dans le premier sorti",
    HMRC: () => "HM Revenue and Customs",
    IRS: {
      done: () => "Fait",
      downloadIrs8949Long: () =>
        "Téléchargez le formulaire 8949 de l'IRS sur les gains à long terme",
      downloadIrs8949LongConsolidated: () =>
        "Téléchargez le formulaire 8949 de l'IRS sur les gains à long terme (consolidés)",
      downloadIrs8949Short: () =>
        "Téléchargez le formulaire 8949 de l'IRS sur les gains à court terme",
      downloadIrs8949ShortConsolidated: () =>
        "Téléchargez le formulaire 8949 de l'IRS sur les gains à court terme (consolidés)",
      downloadIrsForm: () =>
        "Téléchargez l'annexe 1 de l'IRS (formulaire 1040)",
      downloadIrsScheduleDForm: () =>
        "Téléchargez l'annexe D de l'IRS (formulaire 1040)",
      provideIrsDetails: () => "Fournissez vos coordonnées IRS",
      stepComplete: () =>
        "Toutes les étapes sont terminées – vous avez terminé",
    },
    LIFO: () => "Dernier entré, premier sorti",
    LTFO: () => "Le plus efficace sur le plan fiscal",
    PFU: () => "PFU / France",
    acknowledged: () => "Reconnu",
    airdropIncome: () => "Parachutages aériens",
    allTradeSelector: () => "Tous les types de txs",
    amountRemaining: () => "Montant restant",
    amountYouCouldSave: () =>
      "Le montant que vous pourriez économiser en améliorant la précision de votre rapport",
    approvals: () => "Approbations",
    assetSummary: {
      amountRemainingEndFy: () => "Solde de fin d'exercice",
      amountRemainingEndFyTooltip: () =>
        "Solde total détenu à la fin de l'exercice financier.",
      amountRemainingStartFy: () => "Solde de début d'exercice",
      capitalGain: () => "Capital P/L",
      cost: () => "Coût",
      currency: () => "Devise",
      fee: () => "Frais",
      income: () => "Revenu",
      longTerm: () => "À long terme",
      noDisposals: () => "Aucune élimination durant cette période.",
      overallGain: () => "Résultat total",
      proceeds: () => "Recettes",
      quantity: () => "Quantité éliminée",
      quantityTooltip: () =>
        "Quantité totale cédée au cours de l'exercice financier.",
      shortTerm: () => "À court terme",
    },
    ausFinancialYear: (d) =>
      "1er juillet " + d.prevYear + " - 30 juin " + d.year,
    buyTime: () => "Acheté",
    capitalGainSelector: {
      all: () => "Tous",
      gains: () => "Gains",
      longTermCapitalGains: () => "Des gains à long terme",
      longTermCapitalLosses: () => "Pertes à long terme",
      shortTermCapitalGains: () => "Gains à court terme",
      shortTermCapitalLosses: () => "Pertes à court terme",
      totalLosses: () => "Pertes",
    },
    checkWithAgent: () =>
      "Vous n'êtes pas sûr ? Vérifiez auprès de votre agent fiscal.",
    closed: () => "Fermé",
    confirmInventoryMethod: () => "Confirmer la méthode d'inventaire",
    confirmationText: (d) =>
      "Vous avez sélectionné la méthode d'inventaire " +
      d.method +
      " , qui n'est pas l'option par défaut. Vérifiez toujours auprès de votre fiscaliste avant d'utiliser la méthode d'inventaire " +
      d.method +
      " .",
    cost: () => "Base de coûts",
    costBasisRedistributionMethod: {
      "largest-wallet-highest-cost": () =>
        "Le plus grand portefeuille Le coût le plus élevé",
      "largest-wallet-lowest-cost": () =>
        "Le plus grand portefeuille Le coût le plus bas",
      "least-adjustment": () => "Ajustement minimal",
      title: () => "Méthode de redistribution",
      tooltip: () =>
        "La méthode utilisée pour redistribuer la base de coût des actifs lors du basculement entre les méthodes de base de coût universelles et par portefeuille et échange",
    },
    csv: () => "CSV",
    currency: () => "Devise",
    customDateRange: () => "Dates de l'exercice financier",
    customDates: () => "Sélectionnez des dates personnalisées",
    defaultPaywallBannerText: () => "Abonnez-vous pour voir votre rapport",
    derivativeTradingIncome: () => "Revenus de négociation de produits dérivés",
    disclaimer: (d) =>
      "Vous devez utiliser ces résultats à titre d'estimation et à titre indicatif uniquement. Bien que nous essayions de garantir l'exactitude de ces résultats, des erreurs peuvent survenir. Assurez-vous de vérifier les résultats. " +
      select(d.isRegularUser, {
        true: " Si vous avez des doutes, parlez-en à votre fiscaliste. ",
        other: "",
      }),
    disclaimerTitle: () =>
      "Les résultats calculés sont basés sur les informations que vous avez fournies au moment du calcul.",
    download: {
      bglAccountIDModal: {
        buttonText: () => "Fait",
        copyId: () => "Copier l'ID",
        subtitle: () =>
          "Utilisez cet identifiant comme identifiant de compte lors de l'importation de vos données dans BGL :",
        title: () => "ID de compte BGL",
      },
      buttonIrs: () => "Générer un formulaire",
      downloading: () => "Téléchargement...",
      exportCSV: () => "Exporter vers un fichier CSV",
      exportIrs1040s1Pdf: () =>
        "Exporter vers l'annexe 1 de l'IRS (formulaire 1040) PDF",
      exportIrs8949Pdf: () => "Exporter vers le formulaire IRS 8949 PDF",
      exportPDF: () => "Exporter vers un fichier PDF",
      exportTurboTaxOnline: () => "Exporter vers TurboTax Online (CSV)",
      exportTurboTaxTXF: () => "Exporter vers TurboTax Desktop (TXF)",
      irsDownload: {
        customTitle: () => "Détails du formulaire IRS (facultatif)",
        download: () => "Télécharger",
        name: () => "Nom(s) indiqué(s) sur la déclaration",
        skip: (d) =>
          "Étant donné que vous n’avez pas de gains de terme " +
          d.termLength +
          " , vous pouvez ignorer cette étape.",
        social: () => "SSN ou TIN",
        whyUserData: (d) =>
          "Si vous souhaitez que nous remplissions automatiquement vos informations sur " +
          d.formName +
          " , veuillez saisir votre nom et un numéro SSN/TIN valide. Nous respectons votre vie privée, c'est pourquoi ces informations ne sont stockées nulle part et ne sont utilisées que pour générer les fichiers PDF.",
        whyUserDataCustom: () =>
          "Si vous souhaitez que nous remplissions automatiquement vos données, veuillez saisir votre nom et un numéro SSN/TIN valide. Nous respectons votre vie privée, c'est pourquoi ces données ne sont stockées nulle part et ne sont utilisées que pour générer les fichiers PDF.",
      },
      loading: () => "Téléchargement",
      sample: () => "Échantillon",
      tabCryptoTax: () => "Rapport d'impôt",
      tabGermanReports: () => "Formes allemandes",
      tabIrs: () => "Formulaires de l'IRS",
      tabTaxIntegrations: () => "Intégrations de logiciels",
      title: () => "Télécharger les rapports",
      tradingStockReportDownload: {
        buttons: {
          cancel: () => "Annuler",
          downloadReport: () => "Télécharger le rapport",
        },
        costBasisMethod: {
          description: () =>
            "Le prix de base des actifs est utilisé pour déterminer les valeurs d'ouverture et de clôture de vos actions. S'il existe une valeur de marché correspondante pour un actif, elle sera remplacée.",
          title: () => "Utiliser la méthode du prix de revient",
        },
        description: () =>
          "Veuillez sélectionner la méthode que vous souhaitez utiliser pour déterminer les valeurs d’ouverture et de clôture de vos actions.",
        fallbackMethod: {
          description: () =>
            "Les valeurs de marché sont utilisées pour tous les actifs. Lorsqu'un prix de marché ne peut être récupéré et qu'une valeur de marché ne peut donc pas être déterminée, la base de coût est utilisée à la place.",
          title: () => "Utiliser la méthode de secours",
        },
        marketValueMethod: {
          description: () =>
            "Les valeurs de marché sont utilisées pour tous les actifs. Lorsqu'un prix de marché ne peut pas être récupéré et qu'une valeur de marché ne peut donc pas être déterminée, nous afficherons la valeur comme « N/A » (non disponible). Il s'agit de la méthode utilisée par défaut dans le passé.",
          title: () =>
            "Utiliser la méthode de la valeur marchande (par défaut)",
        },
        table: {
          defaultValuationMethod: () =>
            "Méthode de la valeur marchande (par défaut)",
          tooltip: () =>
            "La méthode d'évaluation par défaut est utilisée pour ce tableau, ce qui signifie que les valeurs de marché sont utilisées pour tous les actifs. Lorsqu'un prix de marché ne peut pas être récupéré et donc une valeur de marché ne peut pas être déterminée, nous afficherons la valeur comme « N/A ».",
        },
        title: () => "Rapport sur les transactions boursières",
      },
      turbotaxModal: {
        buttonText: () => "Accédez à TurboTax maintenant",
        helpLinkText: () => "Guide d'importation en ligne de TurboTax",
        subtitle: () =>
          "Ce rapport peut être importé dans TurboTax en ligne en suivant les instructions ci-dessous.",
        title: () => "Rapport TurboTax",
      },
    },
    downloadReports: {
      downloadReportAnyway: () => "Télécharger le rapport quand même",
      primaryButton: () => "Télécharger les rapports",
      quickDownload: () => "Téléchargement rapide",
      title: () => "Rapports",
    },
    emailReportsModal: {
      always: () => "Toujours envoyer les rapports par courrier électronique",
      body: () =>
        "Il s'agit d'un rapport volumineux dont la création peut prendre plus d'une minute. Souhaitez-vous recevoir ce rapport par e-mail une fois terminé ?",
      dontShowAgain: () => "Ne plus afficher ceci",
      emailMe: () => "Envoyez-moi un e-mail",
      illWait: () => "J'attendrai",
      title: () => "Rapport par e-mail ?",
    },
    errorTitle: () => "Signaler des avertissements",
    estimatedGainBanner: {
      title: () => "Gain estimé",
      tooltip: () =>
        "Le gain estimé représente vos gains totaux provenant des crypto-monnaies. Il est calculé comme Gains en capital + Revenus - Pertes en capital. Cette estimation est basée sur les données importées jusqu'à présent. Une image complète de toutes vos transactions depuis votre premier achat de crypto-monnaies est nécessaire pour obtenir un résultat précis.",
    },
    expense: () => "Frais",
    expenses: () => "Dépenses",
    fee: () => "Frais",
    fees: () => "Frais",
    financialYear: () => "Exercice financier",
    form3916ExchangeInfo: {
      address: () => "Adresse",
      company: () => "Désignation de l'organisme de gestion du compte (*)",
      country: () => "Pays",
      title: () => "Déclaration de comptes à l'étranger",
      website: () => "URL du site Web de l'organisation de gestion de compte",
    },
    fyChip: (d) => "À " + d.timeframe,
    gain: () => "Gain (perte)",
    gainNoCGT: () => "Bénéfice (perte)",
    grid: {
      softwareConnections: () => "Connexions logicielles",
      taxReports: () => "Rapports fiscaux",
      txTypes: () => "Types de transactions",
    },
    headings: {
      derivativeTradingIncome: () =>
        "Revenus de négociation de produits dérivés",
      expenses: () => "Dépenses",
      gains: () => "Gains en capital",
      longTermCapitalGains: () => "Gains en capital à long terme",
      longTermCapitalLosses: () => "Pertes en capital à long terme",
      nonTaxableTransactions: () => "Transactions non imposables",
      ordinaryIncome: () => "Revenu ordinaire",
      shortTermCapitalGains: () => "Gains en capital à court terme",
      shortTermCapitalLosses: () => "Pertes en capital à court terme",
      totalCapitalGains: () => "Gains nets en capital",
      totalExpenses: () => "Dépenses nettes",
      totalIncome: () => "Revenu net",
      totalLosses: () => "Pertes",
    },
    income: () => "Revenu",
    incurredTime: () => "Encouru",
    interestIncome: () => "Intérêt",
    inventory: {
      endBalance: () => "Solde de clôture",
      endCostBasis: () => "Frais de clôture",
      endValue: () => "Valeur marchande à la clôture",
      startBalance: () => "Solde d'ouverture",
      startCostBasis: () => "Coût d'ouverture",
      startValue: () => "Valeur marchande d'ouverture",
    },
    inventoryMethod: () => "Méthode d'inventaire",
    loanRepayments: () => "Remboursements de prêts",
    lockedPeriod: {
      all: () => "Cette période est verrouillée",
      costBasisTracking: () =>
        "Vous ne pouvez pas modifier le paramètre de suivi de la base de coûts lorsque vous utilisez des périodes verrouillées",
      multipleInventoryMethods: () => "Divers",
      none: () => "Période de blocage",
      some: () => "Cette période contient des transactions verrouillées",
      tooltip: () =>
        "Vous ne pouvez pas modifier les paramètres pour une période verrouillée",
    },
    longShortIncome: () => "Revenus de trading sur marge",
    longTermGains: () => "Gains en capital à long terme",
    longTermGainsNoCGT: () => "Bénéfices réalisés à long terme",
    lookTooltip: (d) => "[Abonnez-vous]( " + d.linkTextKey + " ) pour voir",
    miningRewards: () => "Exploitation minière",
    miscellaneousExpenses: () => "Dépenses",
    missingPurchase: {
      text: () => "Achat manquant",
      tooltip: (d) =>
        "L'achat pour cette élimination est manquant, nous avons supposé une base de coût de 0,00 $. [Résolvez l'historique des achats manquants]( " +
        d.linkTextKey +
        " ) pour attribuer correctement la base de coût.",
    },
    modifyTaxSettings: () => "Modifier",
    nav: {
      reportSummary: () => "Résumé du rapport",
      taxReports: () => "Rapports fiscaux",
    },
    noFrenchReportDataModal: {
      body1: (d) =>
        "Il n'y a aucune transaction crypto-fiat enregistrée pour " +
        d.year +
        " , donc Forumalaire 2086 n'est pas requis.",
      body2: (d) =>
        "Si vous vous attendiez à effectuer des transactions crypto-monnaie vers monnaie fiduciaire pour " +
        d.year +
        " , vérifiez que toutes les transactions sont importées. Vérifiez d'autres rapports pour des transactions de revenus potentielles.",
      title: (d) =>
        "Aucune transaction crypto-monnaie vers monnaie fiduciaire en " +
        d.year,
    },
    noRecordsFound: () => "Aucun enregistrement pertinent trouvé...",
    nzFinancialYear: (d) => "1er avril " + d.prevYear + " - 31 mars " + d.year,
    opened: () => "Ouvert",
    ordinaryIncome: () => "Revenu ordinaire",
    otherIncome: () => "Revenu",
    otherReports: () => "Autres rapports et intégrations",
    overallGain: () => "Gain global",
    packs: {
      customModal: {
        columns: {
          details: () => "Détails",
          report: () => "Rapport",
        },
        csv: () => "CSV",
        downloadSelected: (d) =>
          select(d.isFree, {
            true:
              " Télécharger des exemples de rapports ( " +
              d.selectedCount +
              " ) ",
            other: " Télécharger la sélection ( " + d.selectedCount + " ) ",
          }),
        getSample: () => "Obtenir un échantillon",
        pdf: () => "PDF",
        selectPlanBanner: {
          body: () => "Sélectionnez un plan pour télécharger vos rapports",
          cta: () => "Sélectionnez un plan",
        },
        title: () => "Rapports fiscaux",
        txf: () => "Merci",
        upgrade: (d) =>
          "Passez à " + d.planTitle + " pour accéder à ce rapport",
        xml: () => "XML",
      },
      filters: {
        accountant: () => "Pour les comptables",
        all: () => "Tous",
        self: () => "Pour l'auto-déclaration",
      },
      metadata: {
        8949: {
          description: () => "Ventes et autres cessions",
          title: () => "Formulaire 8949 de l'IRS",
        },
        accountant: {
          description: () => "Envoyer à votre comptable",
          title: () => "Pack comptable",
        },
        custom: {
          description: () => "Afficher tous les rapports disponibles",
          title: () => "Coutume",
        },
        form3916bis: {
          description: () => "Déclaration de comptes à l'étranger",
          title: () => "Formulaire 3916 bis",
        },
        fr2086: {
          description: () => "Pour déclarer les transactions imposables",
          title: () => "Formulaire n°2086",
        },
        mygov: {
          description: () => "Fichier via MyGov",
          title: () => "Rapport ATO (MyTax)",
        },
        scheduleS1AndSD: {
          description: () =>
            "Revenus supplémentaires et ajustements de revenus",
          title: () => "IRS 1040 s1 et sD",
        },
        self: {
          description: () => "Pour remplir vos propres impôts",
          title: () => "Auto-déclaration",
        },
        taxLots: {
          description: () =>
            "Consultez un aperçu des lots qui composent votre inventaire",
          title: () => "Aperçu de l'inventaire",
        },
        turbotax: {
          description: () => "Pour importer dans TurboTax Online",
          title: () => "TurboTax en ligne",
        },
      },
    },
    paywallBannerFYText: () =>
      "Sélectionnez un plan pour voir les rapports de plusieurs années fiscales",
    paywallBannerText: () => "Sélectionnez un plan pour afficher votre rapport",
    paywallBannerTextCollaborator: () =>
      "Demandez à votre comptable de s'abonner pour consulter votre rapport",
    paywallBannerTextMap: {
      accountant: {
        accountantOrCollaboratorView: {
          free: () =>
            "Sélectionnez un plan pour voir les gains et télécharger les rapports de votre client",
          importCount: () =>
            "Votre client a atteint la limite d'importation pour ce plan. Effectuez une mise à niveau pour afficher les rapports de votre client",
          tradeType: () =>
            "Votre client a des transactions de contrats intelligents qui ne sont pas prises en charge par ce plan. Effectuez une mise à niveau pour afficher les rapports de votre client",
          txCount: () =>
            "Votre client a atteint la limite de transaction pour ce forfait. Effectuez une mise à niveau pour consulter les rapports de votre client",
          unpaid: () =>
            "Vous avez un abonnement impayé. Payez l'abonnement restant pour consulter les rapports de votre client",
        },
        clientView: {
          free: () =>
            "Demandez à votre comptable de s'abonner pour consulter votre rapport",
          importCount: () =>
            "Vous avez atteint la limite d'importations pour votre forfait. Demandez à votre comptable de s'abonner pour consulter votre rapport",
          tradeType: () =>
            "Vous avez des transactions de contrats intelligents qui ne sont pas prises en charge dans votre forfait. Demandez à votre comptable de s'abonner pour consulter votre rapport",
          txCount: () =>
            "Vous avez atteint la limite de transaction de votre forfait. Demandez à votre comptable de souscrire à un forfait supérieur pour consulter votre rapport",
          unpaid: () =>
            "Demandez à votre comptable de s'abonner pour consulter votre rapport",
        },
      },
      both: {
        accountantOrCollaboratorView: {
          free: () =>
            "Sélectionnez un plan pour voir les gains et télécharger les rapports de votre client",
          importCount: () =>
            "Votre client a atteint la limite d'importation pour ce plan. Effectuez une mise à niveau ou demandez à votre client de procéder à une mise à niveau pour afficher les rapports de votre client",
          tradeType: () =>
            "Votre client a des transactions de contrats intelligents qui ne sont pas prises en charge dans ce plan. Effectuez une mise à niveau ou demandez à votre client de procéder à une mise à niveau pour afficher les rapports de votre client",
          txCount: () =>
            "Votre client a atteint la limite de transaction pour ce forfait. Effectuez une mise à niveau ou demandez à votre client de procéder à une mise à niveau pour consulter les rapports de votre client",
          unpaid: () =>
            "Vous avez un abonnement impayé. Payez l'abonnement restant pour consulter les rapports de votre client",
        },
        clientView: {
          free: () =>
            "Sélectionnez un plan ou demandez à votre comptable de s'abonner pour consulter votre rapport",
          importCount: () =>
            "Vous avez atteint la limite d'importations pour votre forfait. Sélectionnez un forfait ou demandez à votre comptable de s'abonner pour consulter votre rapport",
          tradeType: () =>
            "Vous avez des transactions de contrats intelligents qui ne sont pas prises en charge par votre forfait. Sélectionnez un forfait ou demandez à votre comptable de s'abonner pour consulter votre rapport",
          txCount: () =>
            "Vous avez atteint la limite de transaction de votre forfait. Sélectionnez un forfait ou demandez à votre comptable de souscrire à un forfait supérieur pour consulter votre rapport",
          unpaid: () =>
            "Votre abonnement n'est pas payé. Sélectionnez un forfait ou demandez à votre comptable de vous abonner pour consulter votre rapport",
        },
      },
      client: {
        accountantOrCollaboratorView: {
          free: () =>
            "Sélectionnez un plan pour afficher le rapport de votre client",
          importCount: () =>
            "Votre client a atteint la limite d'importation pour ce plan. Demandez à votre client de passer à un plan supérieur pour consulter son rapport",
          tradeType: () =>
            "Votre client a des transactions de contrats intelligents qui ne sont pas prises en charge dans ce plan. Demandez à votre client de procéder à une mise à niveau pour afficher son rapport",
          txCount: () =>
            "Votre client a atteint la limite de transaction pour ce forfait. Demandez à votre client de passer à un forfait supérieur pour consulter son rapport",
          unpaid: () =>
            "Votre client a un abonnement impayé. Demandez à votre client de régler l'abonnement impayé pour pouvoir consulter son rapport",
        },
        clientView: {
          free: () => "Sélectionnez un plan pour afficher votre rapport",
          importCount: () =>
            "Vous avez atteint la limite d'importations pour votre forfait. Sélectionnez un forfait pour afficher votre rapport",
          tradeType: () =>
            "Vous avez des transactions de contrats intelligents qui ne sont pas prises en charge par votre forfait. Sélectionnez un forfait pour afficher votre rapport",
          txCount: () =>
            "Vous avez atteint la limite de transaction de votre forfait. Souscrivez à un forfait supérieur pour consulter votre rapport",
          unpaid: () =>
            "Votre abonnement n'est pas payé. Abonnez-vous pour consulter votre rapport",
        },
      },
      user: {
        free: () => "Sélectionnez un plan pour télécharger votre rapport",
        importCount: () =>
          "Vous avez atteint la limite d'importations pour votre forfait. Sélectionnez un forfait pour télécharger votre rapport",
        tradeType: () =>
          "Vous avez des transactions de contrats intelligents qui ne sont pas prises en charge par votre forfait. Sélectionnez un forfait pour télécharger votre rapport",
        txCount: () =>
          "Vous avez atteint la limite de transaction de votre forfait. Souscrivez à un forfait supérieur pour consulter votre rapport",
        unpaid: () =>
          "Votre abonnement n'est pas payé. Abonnez-vous pour consulter votre rapport",
      },
    },
    paywalledNumberTooltip: () => "Abonnez-vous pour voir",
    pdf: () => "PDF",
    proceeds: () => "Recettes",
    quantity: () => "Quantité",
    receivedTime: () => "Reçu",
    reportDetails: () => "Détails du rapport",
    reportSubTitles: {
      "annex-so-other-income": () =>
        "Rapport allemand avec Anlage SO complété automatiquement",
      audit: () =>
        "Répertorie toutes les transactions, leur source et les modifications enregistrées par les utilisateurs",
      bgl: () => "Permet l'importation dans le logiciel BGL Simple Fund 360",
      "capital-gains": () =>
        "Gains en capital liés à l'achat et à la vente de crypto-monnaies",
      "capital-gains-nz": () =>
        "Événements de profits/pertes réalisés suite à l'achat et à la vente de crypto-monnaies",
      cerfa2086: () => "Pour déclarer les transactions imposables",
      expense: () => "Frais divers engagés lors du trading de crypto-monnaie",
      form3916bis: () => "Déclaration de comptes étrangers",
      "gift-and-lost": () =>
        "Enregistrement détaillé de chaque cadeau sortant et de chaque transaction perdue/volée",
      income: () => "Revenus gagnés lors de la réception de crypto",
      inventory: () => "Bilan des stocks de début et de fin d'année",
      "inventory-v2": () => "Bilan des stocks de début et de fin d'année",
      "irs-1040-s1": () => "Revenus supplémentaires et ajustements de revenus",
      "irs-1040-sd": () => "Gains et pertes en capital",
      "irs-8949": () => "Ventes et autres aliénations d'actifs en capital",
      "irs-8949-consolidated": () =>
        "Ventes et autres cessions d'actifs immobilisés (consolidées par devise)",
      "modelo-100": () =>
        "Formulaire Modèle 100 complété automatiquement avec les gains en capital et les revenus",
      mygov: () => "Pour auto-déclarer vos impôts dans MyGov",
      "nft-report": () =>
        "Gains en capital et redevances sur l'achat et la vente de NFT",
      "report-summary": () =>
        "Résumé de haut niveau de votre activité de trading complète",
      "start-of-year-holdings": () =>
        "Récapitulatif de vos avoirs en début d'année",
      "tax-lots": () =>
        "Consultez un aperçu des lots qui composent votre inventaire",
      "taxact-csv": () => "Permet l'importation dans le logiciel TaxAct Online",
      "trading-pnl": () =>
        "Bénéfices ou pertes provenant des transactions sur produits dérivés, sur marge et à terme",
      "trading-stock": () => "Utilisé si vous exercez une activité commerciale",
      transaction: () => "Enregistrements détaillés de chaque transaction",
      "turbotax-canadian-pdf": () =>
        "Permet le collage dans TurboTax Online pour les utilisateurs canadiens",
      "turbotax-csv": () =>
        "Permet l'importation dans le logiciel TurboTax Online",
      "turbotax-txf": () =>
        "Permet l'importation dans le logiciel TurboTax CD/DVD",
      "wiso-steuer": () => " WISO Steuer formaté au format csv",
    },
    reportType: {
      "annex-so-other-income": () =>
        "Rapport allemand - Anlage So (Sonstige Einkünfte)",
      audit: () => "Rapport de transaction d'audit",
      bgl: () => "Exportation de données BGL Simple Fund 360",
      "capital-gains": () => "Rapport sur les gains en capital",
      "capital-gains-nz": () => "Rapport sur les profits/pertes réalisés",
      cerfa2086: () => "Formulaire n°2086",
      expense: () => "Rapport de dépenses diverses",
      form3916bis: () => "Formulaire 3916 bis",
      "gift-and-lost": () =>
        "Rapport sur les cadeaux sortants et les biens perdus ou volés",
      income: () => "Rapport de revenus",
      inventory: () => "Rapport d'inventaire",
      "inventory-v2": () => "Rapport d'inventaire",
      "irs-1040-s1": () => "Annexe 1 de l'IRS (formulaire 1040)",
      "irs-1040-sd": () => "Annexe D (formulaire 1040)",
      "irs-8949": () => "Formulaire 8949 de l'IRS",
      "irs-8949-consolidated": () => "Formulaire 8949 de l'IRS (consolidé)",
      "irs-8949-long": () => "Formulaire 8949 à long terme",
      "irs-8949-long-consolidated": () =>
        "Formulaire 8949 Long terme (consolidé)",
      "irs-8949-short": () => "Formulaire 8949 Court terme",
      "irs-8949-short-consolidated": () =>
        "Formulaire 8949 Court terme (consolidé)",
      "modelo-100": () => "Modèle 100",
      mygov: () => "Rapport myTax de l'ATO",
      "nft-report": () => "Rapport NFT",
      "report-summary": () => "Résumé du rapport",
      "start-of-year-holdings": () => "Rapport de début d'année sur les avoirs",
      "tax-lots": () => "Rapport d'inventaire instantané",
      "taxact-csv": () => "TaxAct - Rapport sur les gains en capital",
      "trading-pnl": () => "Rapport sur les transactions sur produits dérivés",
      "trading-stock": () => "Rapport sur les transactions boursières (Trader)",
      transaction: () => "Rapport de transactions",
      "turbotax-canadian-pdf": () => "TurboTax PDF",
      "turbotax-csv": () => "TurboTax – Rapport sur les gains en capital",
      "turbotax-txf": () => "TurboTax TXF – Rapport sur les gains en capital",
      "wiso-steuer": () => "Rapport CSV WISO Steuer",
    },
    reportTypeSelector: () => "Type de rapport",
    rewardsAndInterest: () => "Récompenses et intérêts",
    rowsPerPage: () => "Lignes par page",
    royalties: () => "Redevance",
    saFinancialYear: (d) =>
      "1er mars " + d.prevYear + " - " + d.febLastDay + " février " + d.year,
    saveMoney: () =>
      "Complétez les étapes d’évaluation pour économiser de l’argent !",
    savingsOpportunity: () => "Possibilité d'économies",
    selectPlan: () => "Sélectionnez un plan",
    sellTime: () => "Vendu",
    shortTermGains: () => "Gains en capital à court terme",
    shortTermGainsNoCGT: () => "Bénéfices totaux réalisés",
    stakingIncome: () => "Récompenses de jalonnement",
    summary: {
      reviewTips: {
        button: () => "Consultez les transactions pour économiser",
        subTitle: {
          control: () => "Votre opportunité d'épargne est ",
          ourTaxMinimisationAlgorithmCouldSaveYou: () =>
            "Notre algorithme de minimisation des impôts pourrait vous faire économiser ",
          youCouldMinimiseYourTaxLiabilityBy: () =>
            "Vous pourriez minimiser votre obligation fiscale en ",
          youCouldSaveXyzInTaxes: {
            prefix: () => "Vous pourriez économiser ",
            suffix: () => " dans les impôts",
          },
        },
        title: () => "Ne payez pas trop d'impôts ! 💸",
        tooltip: () =>
          "Le montant estimé par lequel vous pourriez réduire votre gain en capital en catégorisant et en résolvant tous les problèmes examinés.",
      },
      title: () =>
        "Abonnez-vous pour découvrir des conseils sur la façon d'économiser sur votre déclaration de revenus",
      txs: (d) => plural(d.count, 0, fr, { 1: " tx ", other: " txs " }),
    },
    tablePagination: (d) => d.from + " - " + d.to + " de " + d.count,
    taxReports: () => "Rapports fiscaux",
    taxSettingWarnings: {
      uncategorisedBothAsTaxable: () =>
        "Les transactions sortantes non catégorisées sont traitées comme des ventes sur le marché et les transactions entrantes non catégorisées sont traitées comme des achats sur le marché.",
      uncategorisedInAsTaxable: () =>
        "Les transactions entrantes non catégorisées sont traitées comme des achats sur le marché",
      uncategorisedOutAsTaxable: () =>
        "Les transactions sortantes non catégorisées sont traitées comme des ventes sur le marché",
    },
    taxSettings: () => "Paramètres fiscaux",
    taxablePortfolioValue: () => "Valeur du portefeuille",
    tooltips: {
      airdrop: () => "Revenus générés par les crypto-monnaies parachutées",
      approvals: () =>
        "Frais d'approbation encourus lors de la négociation de crypto-monnaies",
      capitalLosses: () =>
        "Total des pertes en capital réalisées au cours de l'exercice",
      capitalLossesNoCGT: () =>
        "Pertes totales réalisées tout au long de l'exercice",
      derivativeTradingIncome: () =>
        "Bénéfices ou pertes provenant des transactions sur produits dérivés, sur marge et à terme",
      expenses: () => "Frais divers engagés lors du trading de crypto",
      fees: () => "Frais encourus lors du trading de crypto-monnaies",
      income: () =>
        "Autres revenus reçus, tels que des récompenses, des parrainages, des bonus, etc.",
      loan: () =>
        "Intérêts et/ou capital payés pour le maintien d'un prêt ouvert ou d'une position sur marge",
      longTermCapitalLosses: (d) =>
        "Total des pertes en capital réalisées sur les actifs détenus plus de " +
        d.longTermThreshold +
        " mois",
      longTermThreshold: (d) =>
        "Total des gains en capital réalisés sur les actifs détenus plus de " +
        d.longTermThreshold +
        " mois",
      longTermThresholdNoCGT: (d) =>
        "Bénéfices totaux des actifs détenus plus de " +
        d.longTermThreshold +
        " mois",
      mining: () => "Récompenses minières considérées comme des revenus",
      miscellaneousExpenses: () =>
        "Dépenses diverses engagées tout au long de l'exercice",
      miscellaneousIncome: () =>
        "Revenus divers perçus tout au long de l'exercice",
      noCapitalGains: () =>
        "Total des plus-values réalisées au cours de l'exercice",
      noCapitalGainsNoCGT: () =>
        "Bénéfices totaux réalisés tout au long de l'exercice",
      noLongTermThreshold: () =>
        "Pas de seuil à long terme, tous les gains sont considérés comme à court terme",
      pnl: () =>
        "Bénéfice réalisé sur marge commerciale, contrats à terme, contrats perpétuels, options, etc.",
      royalty: () => "Revenus perçus sous forme de redevances",
      shortTermCapitalLosses: (d) =>
        "Total des pertes en capital réalisées sur les actifs détenus pendant moins de " +
        d.longTermThreshold +
        " mois",
      staking: () =>
        "Revenus provenant des intérêts et des récompenses de jalonnement",
      totalCapitalGains: (d) =>
        "Total des gains en capital réalisés sur les actifs détenus moins de " +
        d.longTermThreshold +
        " mois",
      totalCapitalGainsNoCGT: (d) =>
        "Total des bénéfices/pertes réalisés sur les actifs détenus depuis moins de " +
        d.longTermThreshold +
        " mois",
      totalGains: () =>
        "Gains en capital nets, plus revenu net, moins dépenses nettes",
    },
    totalCapitalGains: () => "Gains en capital totaux",
    totalCapitalGainsNoCGT: () => "Total des profits/pertes réalisés",
    totalGains: () => "Gains totaux",
    totalLosses: () => "Pertes totales en capital",
    totalLossesNoCGT: () => "Total des pertes réalisées",
    tradingPnL: () => "Trading PnL",
    tradingStockAu: {
      acquiredStockQuantity: () => "Quantité acquise",
      acquiredTotalValue: () => "Valeur acquise",
      currency: () => "Devise",
      disposedStockQuantity: () => "Quantité éliminée",
      disposedTotalValue: () => "Valeur cédée",
      endingPrice: () => "Prix de fin",
      endingStock: () => "Stock de fin",
      endingValue: () => "Valeur finale",
      netProfit: () => "Bénéfice net",
      startingPrice: () => "Prix initial",
      startingStock: () => "Stock de départ",
      startingValue: () => "Valeur de départ",
    },
    txf: () => "Merci",
    ukFinancialYear: (d) => "6 avril " + d.prevYear + " - 5 avril " + d.year,
    unknownGains: () => "Des gains inconnus",
    unknownGainsNoCGT: () => "Bénéfices inconnus",
    upgradeToPlanTooltip: (d) =>
      "Passez au forfait " + d.plan + " pour accéder à ce rapport",
    viewAll: () => "Tout voir",
    viewOtherReports: () => "Voir d'autres rapports",
    warning: () =>
      "Les avertissements peuvent conduire à des calculs incorrects. Assurez-vous d'avoir importé toutes vos données et d'avoir confirmé vos transactions",
    warningActionText: () => "Examiner les transactions",
    warningTitle: () =>
      "Vous avez des avertissements de transaction en suspens",
    warningZeroCost: {
      bodyText1: () =>
        "Nous avons détecté des problèmes avec vos transactions ",
      bodyText2: () =>
        "Une transaction à coût nul se produit lorsqu'il y a une vente mais qu'aucun actif n'est disponible. Vous pouvez réduire vos impôts en réglant les problèmes liés aux actifs manquants. Vous pouvez également ignorer cet avertissement. ",
      bodyValuePostNumber: () => ". ",
      bodyValuePreNumber: () => "totalisant ",
      breakdownPrefix: () =>
        "Principales devises avec problèmes non résolus et actifs présumés vendus",
      buttonText: () => "Voir toutes les devises",
      includedInGains: () => "Ne payez pas plus que ce que vous devez !",
      innerBodyText: () =>
        "Nous avons supposé un coût nul pour les actifs vendus",
      innerTitle: () => "Toutes les devises avec des problèmes non résolus",
      title: () =>
        "Des avis sont nécessaires - Le gain réalisé peut être inexact.",
      titleNoCGT: () =>
        "Avis nécessaires - Le bénéfice réalisé peut être inexact.",
    },
    warningZeroCostSummary: {
      body: (d) =>
        "Nous avons supposé un coût nul pour les actifs vendus au total " +
        d.total +
        " . Vous pouvez réduire vos impôts en réglant les problèmes liés aux actifs manquants.",
      title: () => "Révision nécessaire",
    },
    wentWrongDownloadingBGL: () =>
      "Une erreur s'est produite lors de l'exportation du XML BGL Simple Fund 360.",
    wentWrongDownloadingCSV: () =>
      "Une erreur s'est produite lors du téléchargement du fichier CSV.",
    wentWrongDownloadingPDF: () =>
      "Une erreur s'est produite lors du téléchargement du PDF.",
    wentWrongDownloadingTXF: () =>
      "Une erreur s'est produite lors du téléchargement du fichier TurboTax TXF.",
    wentWrongRefreshing: () =>
      "Une erreur s'est produite lors de l'actualisation du rapport.",
    xml: () => "XML",
    ytd: () => "Exercice à ce jour",
  },
  reportError: {
    button: {
      display: () => "Afficher les erreurs",
      hide: () => "Masquer les erreurs",
    },
    soldOut: () =>
      "vendre des transactions sans solde restant suffisant - sur la base d'un coût nul supposé",
    title: () => "Vérification de la santé mentale",
    tooltip: {
      soldOut: () =>
        "Nous avons supposé que le coût de ces transactions était nul. Cela est dû au fait que les transactions de vente ne sont pas suffisamment nombreuses. Si le montant est important, cela peut être dû à des transactions manquantes. Dans le cas contraire, s'il s'agit d'un petit montant, cela peut être dû aux intérêts de change, à l'arrondi des frais, etc. Vous pouvez filtrer par erreur sur la page d'examen des transactions.",
      uncategorized: () =>
        "Nous avons ignoré ces transactions lors des calculs. Vous pouvez cliquer sur le lien pour classer les transactions entrantes/sortantes.",
    },
    uncategorized: () =>
      "transactions non catégorisées - ces transactions ont été ignorées",
  },
  reset: () => "Réinitialiser",
  retry: () => "Réessayer",
  rules: {
    addRule: () => "Ajouter une règle",
    categoryRuleWarning: () =>
      "Pour créer une règle de catégorie, assurez-vous que l'une de vos conditions est que la catégorie de transaction est uniquement entrante ou sortante.",
    createRule: () => "Créer une règle",
    deleteDialog: {
      text: {
        checkbox: () =>
          "Autoriser d'autres règles à écraser les modifications précédemment apportées par cette règle",
        lineOne: () => "Cela va",
        lineTwo: () =>
          "Notez que les modifications apportées par cette règle resteront en vigueur à moins qu'elles ne soient modifiées manuellement ou modifiées par une nouvelle règle.",
        listOne: () =>
          "Détacher cette règle des transactions auxquelles elle a été appliquée",
        listTwo: () =>
          "Arrêter l’application de la règle aux transactions futures",
      },
      title: () => "Supprimer la règle",
    },
    disableDialog: {
      button: () => "Désactiver",
      text: {
        listOne: () =>
          "Cela empêchera l’application de la règle aux transactions futures.",
        listThree: () => "Vous pouvez toujours réactiver cette règle plus tard",
        listTwo: () =>
          "Les transactions auxquelles cette règle s'applique actuellement resteront concernées par celle-ci. Cela signifie que les nouvelles règles avec les mêmes critères ne remplaceront pas cette règle.",
      },
      title: () => "Désactiver la règle",
    },
    enableDialog: {
      button: () => "Activer",
      text: {
        listOne: () =>
          "L'activation de cette règle l'appliquera à toute transaction qui répond à ses conditions et pour laquelle aucune règle n'est déjà appliquée.",
      },
      title: () => "Activer la règle",
    },
    learnAboutRules: {
      button: () => "En savoir plus sur les règles",
      content: () =>
        "Les règles vous permettent d'automatiser à la fois la catégorisation de vos transactions et la mise en correspondance du plan comptable pour chaque transaction. Cela peut être utile lorsque vous avez de nombreuses actions courantes que vous souhaitez que CTC effectue pour vous !",
      content2list1: () =>
        "Les règles s'appliquent dans l'ordre dans lequel elles apparaissent dans le tableau. Cela signifie que si vous avez deux règles qui peuvent toutes deux s'appliquer à une transaction, celle qui est la plus haute dans le tableau aura toujours la priorité.",
      content2list2: () =>
        "Les règles ne peuvent pas être appliquées partiellement. Cela signifie que si vous avez une règle de priorité supérieure qui ne définit que le compte d'actifs, puis une règle de priorité inférieure qui s'applique à la fois aux comptes d'actifs et de gains, seule la règle de priorité supérieure s'appliquera en raison du premier point, et ensuite la seconde ne pourra pas s'appliquer puisque le compte d'actifs a déjà été défini par une règle.",
      content2list3: () =>
        "Les règles ne peuvent pas être écrasées une fois appliquées. Cela signifie que si vous avez une règle existante appliquée à une transaction, puis que vous créez une nouvelle règle qui s'appliquerait également à cette transaction et que vous lui donnez une priorité plus élevée, elle ne s'appliquera toujours pas à cette transaction, sauf si vous supprimez la première règle.",
      content2part1: () =>
        "Il y a trois choses principales à retenir lorsque vous travaillez avec des règles :",
      content2part2: () =>
        "En vous rappelant ces principes, vous pourrez maîtriser les règles et accélérer vos flux de travail !",
      content3: () =>
        "Vous pouvez créer des règles directement depuis cette page, ou sur les pages du carnet d'adresses ou des intégrations pour les règles relatives à des portefeuilles et des échanges spécifiques, mais vous pouvez également créer des règles en contexte sur la page des transactions ! Lors de la catégorisation des transactions ou de la définition du plan comptable d'une transaction, vous verrez l'option permettant d'appliquer ce choix aux transactions futures. Si cette option est sélectionnée, une règle basée sur votre choix et un critère identifiant les transactions similaires à celle sur laquelle vous travaillez actuellement sera créée, et sera automatiquement ajoutée au bas de la hiérarchie sur la page des règles !",
      subtitle1: () => "Quelles sont les règles ?",
      subtitle2: () => "Comment fonctionnent les règles ?",
      subtitle3: () => "Par où commencer avec les règles ?",
      title: () => "En savoir plus sur les règles",
    },
    navTabs: {
      rules: () => "Règles actives",
      rulesDisabled: () => "Règles désactivées",
    },
    operationShorthands: {
      changeErpAssetAccount: () => "Actif",
      changeErpCashAccount: () => "Espèces",
      changeErpGainsAccount: () => "Gains",
      changeErpLoanAccount: () => "Prêt",
      changeErpPnLAccount: () => "Compte de résultat",
      recategorise: () => "Catégorie",
    },
    operatorSelection: {
      accountPlaceholder: () => "Sélectionnez un compte",
      categoryPlaceholder: () => "Sélectionnez une catégorie",
    },
    pageTitle: () => "Règles",
    rulesTable: {
      empty: () => "Aucune règle",
      headers: {
        accountsMapping: () => "Cartographie des comptes",
        conditions: () => "Conditions",
        name: () => "Nom",
      },
      loadMore: () => "Charger plus",
      row: {
        options: {
          delete: () => "Supprimer la règle",
          deleteInfo: () =>
            "La règle sera supprimée, les transactions où la règle a été appliquée seront libres de se voir appliquer de nouvelles règles.",
          disable: () => "Désactiver la règle",
          disableInfo: () =>
            "Cette règle ne s'appliquera pas aux nouvelles transactions. Toutefois, les transactions existantes continueront d'être régies par cette règle.",
          edit: () => "Modifier",
          enable: () => "Activer la règle",
          moveToBottom: () => "Déplacer vers le bas",
          moveToTop: () => "Déplacer vers le haut",
          viewRule: () => "Voir la règle",
          viewTxs: () => "Voir les transactions",
        },
      },
    },
  },
  save: () => "Sauvegarder",
  selectCountry: {
    autoDetectedCountry: {
      applyLatestRules: (d) =>
        "Nous appliquerons les dernières règles fiscales " + d.authority + ".",
      countryDetected: (d) =>
        "Il semblerait que vous soyez dans " +
        select(d.prefixCountryWithThe, { true: " the ", other: "" }) +
        d.countryName +
        ".",
      plusMore: (d) => "+ " + d.count + " de plus",
      selectAnotherCountry: () => "Ou sélectionnez un autre pays",
      submitCTA: () => "Allons-y",
    },
    body: () =>
      "Votre pays est tenu de déterminer les règles fiscales pour cette calculatrice",
    continue: () => "Continuer",
    loadingOption: () => "Options de chargement...",
    noOptions: () => "Aucune option",
    noResults: () => "Aucun résultat",
    optimizeTaxSettings: {
      AT: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences du BMF.",
      AU: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de l'ATO.",
      BE: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences du FPSF.",
      CA: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de l'ARC.",
      CH: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de la SFTA.",
      DE: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences du BZSt.",
      ES: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de l'AEAT.",
      FI: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de la FTA.",
      FR: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de la DGFIP.",
      GR: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de l'IAPR.",
      IE: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences du TRC.",
      IN: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de l'ITR.",
      IT: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de l'ADE.",
      JP: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de la NTA.",
      NL: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de la DTCA.",
      NO: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de la NTA.",
      NZ: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de l'IRD.",
      PT: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences du PTCA.",
      SE: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de la STA.",
      SG: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de l'IRAS.",
      UK: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences du HMRC.",
      US: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de l'IRS.",
      ZA: () =>
        "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences du SARS.",
    },
    optimizeTaxSettingsGeneric: (d) =>
      "Nous optimiserons vos paramètres fiscaux pour répondre aux exigences de " +
      d.taxAuthority +
      ".",
    pending: () => "En attente",
    reportForms: {
      ATO: () => "ATO",
      Form8949: () => "Formulaire 8949",
      HMRC: () => "HM Revenue and Customs",
      IRS: () => "IRS",
      adjustedCostBase: () => "Coût de base ajusté",
      averageCostBase: () => "Coût de base moyen",
      bedAndBreakfast: () => "30 jours de chambre et petit-déjeuner",
      capitalGainsReport: () => "Rapport sur les gains en capital",
      cerfa2086: () => "Formulaire n°2086",
      cra: () => "ARC",
      incomeReport: () => "Rapport de revenus",
      myTax: () => "Rapport myTax de l'ATO",
      reports: () => "Rapports fiscaux",
      sameDay: () => "Règle du jour même",
      schedule3: () => "Annexe 3",
      scheduleD: () => "Annexe D 1040",
      superficialLoss: () => "Règle de perte superficielle",
      t1Income: () => "Revenu T1",
      turboTaxOnline: () => "TurboTax en ligne",
    },
    title: () => "Sélectionnez un pays",
    title2: () => "Sélectionnez un pays",
  },
  settings: {
    accounting: {
      dataManagement: () => "Gestion des données",
      inviteLink: {
        body: () =>
          "En utilisant ce lien, les clients seront dirigés vers la création d'un compte auprès de CTC. Leurs données seront automatiquement partagées avec vous.",
        bodyWide: () =>
          "Partagez ce lien avec vos clients, ils seront redirigés vers la création d'un compte avec CTC. Leurs données seront automatiquement partagées avec vous.",
        copy: () => "Copie",
        customiseLink: () => "Personnaliser le lien",
        generatingCode: () => "Génération de code...",
        new: () => "Nouveau",
        title: () => "Lien d'invitation personnelle",
        titleCollaborator: () => "Lien d'invitation pour comptable",
        titleWide: () => "Votre lien d'invitation client personnel ",
        titleWideCollaborator: () =>
          "Lien d'invitation client de votre comptable",
      },
      profile: () => "Paramètres du comptable",
      title: () => "Comptabilité",
    },
    accountingData: {
      updateDataSourceError: () =>
        "Une erreur s'est produite lors de la mise à jour de la source de données. Veuillez réessayer ultérieurement.",
      updateDataSourceSuccess: () =>
        "Source de données mise à jour avec succès",
    },
    billing: {
      description: {
        part1: () => "Gérez votre abonnement. Voir ",
        part2: () => "conditions de service.",
      },
      manage: () => "Gérer l'abonnement",
      nextBilling: () => "Prochaine date de facturation",
      nextBillingDetails: (d) =>
        d.date + " pour " + d.amount + " " + d.currency,
      perYear: () => "/année",
      title: () => "Détails de l'abonnement",
      upgrade: () => "Mise à niveau de l'abonnement",
    },
    clearData: {
      buttonText: () => "Effacer les données",
      description: (d) =>
        select(d.isAccountant, {
          true: " Efface toutes les données client du compte, y compris les transactions et les sources d'importation. Cette action n'est pas réversible. ",
          other:
            " Efface toutes les données de votre compte. Cette action n'est pas réversible. ",
        }),
      text: (d) =>
        select(d.isAccountant, {
          true: " Attention ! Voulez-vous vraiment supprimer toutes les données de votre client ? Cette action ne peut pas être annulée. ",
          other:
            " Attention ! Voulez-vous vraiment supprimer toutes vos données ? Cette action ne peut pas être annulée. ",
        }),
      titleClient: (d) => "Effacer les données pour " + d.clientName,
      titleClientFallback: () => "Effacer les données client",
      titleSelf: () => "Effacer les données",
    },
    clientAccount: () => "Profil client",
    clientData: {
      dataBody: () => "Source de données partagée entre vous et votre client.",
      invitation: {
        status: {
          accepted: () => "Invitation acceptée",
          declined: () => "Invitation refusée",
          default: () => "Inviter un comptable",
          pending: (d) => "Invitation " + d.direction,
          revoked: () => "Invitation révoquée",
        },
        verboseStatus: {
          accepted: () => "Le client a accepté votre invitation",
          declined: () => "Il semble que votre invitation ait été refusée",
          deleted: () => "Il semble que votre invitation ait été supprimée",
          pending: () =>
            "Invitation envoyée. Vous recevrez un e-mail lorsque votre client aura accepté l'invitation.",
          revoked: () => "Le client a révoqué votre invitation",
          revokedLink: () => "Le client a révoqué votre invitation",
        },
      },
      invitationBody: () =>
        "Ajoutez l'adresse e-mail de votre client pour l'inviter à partager et à gérer des données. Une fois que votre client a accepté l'invitation, vous ne pourrez plus modifier son adresse e-mail.",
      invitationStatus: () => "Statut de l'invitation",
      paymentPlan: () => "Plan de paiement",
      profile: () => "Détails du client",
      tooltip: () => "Ce paramètre ne peut être modifié que par le client",
    },
    dataCleared: () => "Données effacées",
    exitSurvey: {
      cancellationReasonQuestion: (d) =>
        select(d.variation, {
          DeleteAccount:
            " Avant de supprimer votre compte, quelle est la principale raison de l'annulation de votre service ? ",
          other:
            " Avant de continuer, quelle était la principale raison de l'annulation de votre forfait ? ",
        }),
      cancellationReasons: {
        accountant: () => "Faire appel à un comptable",
        countrySupport: () => "Pays non pris en charge",
        hardToUse: () => "Difficile à utiliser",
        importSupport: () => "Échange ou blockchain non pris en charge",
        incorrectResults: () => "Résultats incorrects",
        noLongerTradingCrypto: () => "Ne plus échanger de crypto-monnaies",
        timeConsuming: () => "Prend du temps",
        tooExpensive: () => "Trop cher",
      },
      continue: () => "Continuer",
      otherCommentsQuestion: () =>
        "Y a-t-il autre chose que vous aimeriez que nous sachions ?",
      title: (d) =>
        select(d.variation, {
          DeleteAccount: " Supprimer votre compte ",
          other: " Plan annulé ",
        }),
    },
    hideSpamError: () =>
      "Une erreur s'est produite lors de la modification des paramètres du filtre, veuillez réessayer plus tard",
    hmrc: {
      actionText: () => "Reconnu",
      text: () =>
        "Les calculs hérités du HM Revenue and Customs ne doivent être utilisés qu'à titre de référence, veuillez utiliser la méthode mise à jour pour déclarer vos impôts",
      title: () =>
        "Confirmez que vous souhaitez consulter les calculs hérités du HM Revenue and Customs",
    },
    lockPeriod: {
      add: {
        firstTransaction: () => "Votre première transaction",
        from: () => "Depuis",
        to: () => "À",
      },
      addButton: () => "Ajouter une période de verrouillage",
      bannerText: () =>
        "Le verrouillage n'est recommandé que pour les périodes antérieures qui ont déjà été soumises à votre bureau des impôts.",
      beginningOfTime: () => "Commencer",
      cancel: () => "Annuler",
      currentPeriod: {
        tooltip: () =>
          "Veuillez cliquer sur le bouton Ajouter une période de verrouillage ci-dessus pour créer une nouvelle période de verrouillage",
      },
      description: () =>
        "Le blocage d'une période empêchera toute modification des transactions effectuées au cours de cette période. Le but de cet outil est de vous permettre de vous assurer que vos calculs d'impôt pour les années fiscales passées ne changent pas une fois que vous avez soumis vos déclarations fiscales.",
      dialog: {
        action: () => "Période de déverrouillage",
        checklist: {
          balances: () => "Des soldes à changer",
          gains: () => "Des gains calculés pour changer",
          reports: () => "Rapports à modifier",
        },
        confirm: () =>
          "Confirmez que vous reconnaissez les risques mentionnés ci-dessus en cochant les cases avant de continuer",
        text: () => "Le déblocage de cette période peut entraîner :",
        title: () => "Période de déverrouillage ?",
        warning: () => "Attention : ceci ne peut pas être annulé",
        warningParagraph: () =>
          "Pour conserver une copie des valeurs de votre rapport actuel, nous vous recommandons de télécharger vos rapports fiscaux avant de déverrouiller l'année fiscale, car les valeurs peuvent changer.",
        warningWithSubsequent: () =>
          "Attention : toutes les périodes suivantes seront également débloquées. Cette opération ne peut pas être annulée",
      },
      disabledCreateLock: () => "Tu es enfermé jusqu'à aujourd'hui",
      inventoryMethodDisabled: (d) =>
        "Vous ne pouvez pas ajouter de périodes verrouillées lorsque vous utilisez la méthode d'inventaire " +
        d.inventoryMethod,
      inventorySnapshots: {
        closing: {
          description: () =>
            "Un instantané des lots qui composent votre inventaire à la fin de la période, avant que d'éventuelles redistributions pour la période suivante n'aient été appliquées.",
          title: () => "Aperçu de l'inventaire de clôture",
        },
        opening: {
          description: () =>
            "Un aperçu des lots qui composent votre inventaire au début de la période, après application des éventuelles redistributions nécessaires.",
          title: () => "Instantané d'ouverture de l'inventaire",
        },
        title: () => "Instantanés d'inventaire",
      },
      issuesModal: {
        cancel: () => "Annuler",
        currentIssues: () => "Questions d'actualité",
        description: () =>
          "Certains éléments nécessitent votre attention avant de verrouiller cette période pour garantir l'exactitude.",
        ignoreAndLock: () => "Ignorer et verrouiller quand même",
        newIssueExplainer: () =>
          "Étant donné que vous vous préparez à verrouiller cette période, nous avons signalé les transferts non correspondants qui doivent être résolus pour verrouiller correctement votre base de coût.",
        title: () => "Problèmes à résoudre avant le verrouillage",
        viewIssues: () => "Afficher et résoudre les problèmes",
        warning: () =>
          "Le verrouillage maintenant sans résoudre ces problèmes pourrait entraîner des problèmes de données insolubles et vous obliger à déverrouiller cette période plus tard.",
      },
      lockPeriodTooltip: () => "Période de blocage",
      lockUnlockedWarning: {
        action: () => "Verrouiller quand même",
        line1: () =>
          "Assurez-vous d'avoir rapproché toutes les transactions avant de procéder au verrouillage. Le non-respect de cette étape pourrait entraîner des problèmes ultérieurs.",
        line2: () =>
          "Vous pouvez toujours déverrouiller ces périodes plus tard, mais les modifications apportées aux paramètres fiscaux ne seront pas conservées.",
        title: () => "Confirmer les périodes de verrouillage",
        warning: () =>
          "Avertissement : la création d'une nouvelle période verrouillée verrouillera également toutes les périodes déverrouillées",
        warningLockExisting: () =>
          "Attention : le verrouillage de cette période verrouillera également toutes les périodes déverrouillées antérieures",
      },
      noLockPeriods: () => "Vous n'avez pas de périodes verrouillées",
      now: () => "Maintenant",
      saveButton: () => "Enregistrer la période verrouillée",
      title: () => "Périodes de blocage",
      unlockAllSubsequentButtonTooltip: () =>
        "Toutes les périodes suivantes seront également débloquées",
      unlockButtonTooltip: () => "Période de déverrouillage",
    },
    longTermThreshold: {
      custom: () => "Coutume",
      default: (d) => "Par défaut ( " + d.defaultValue + " + mois)",
      longTermThreshold: () => "Seuil à long terme",
      months: () => "mois",
      never: () => "Jamais",
    },
    manageData: {
      buttonText: () => "Supprimer le compte",
      clear: () => "Clair",
      confirmClearData: () => "effacer-les-données-pour-toujours",
      confirmClearDataPrompt: () =>
        "Tapez « clear-data-forever » pour procéder à la suppression des données de ce compte.",
      confirmDelete: () => "supprimer-ce-compte-pour-toujours",
      confirmDeletePrompt: () =>
        "Tapez « supprimer-ce-compte-pour-toujours » pour procéder à la suppression de ce compte.",
      delete: () => "Supprimer",
      description: (d) =>
        select(d.isAccountant, {
          true: " En supprimant le compte du client, les données et le client seront supprimés. Cette action n'est pas réversible. ",
          other:
            " En supprimant définitivement votre compte, toutes vos données seront supprimées de notre système et vous serez déconnecté. ",
        }),
      manageData: () => "Gérer les données",
      text: (d) =>
        select(d.isAccountant, {
          true: " Attention ! Vous serez déconnecté des données de votre client et celles-ci pourront être supprimées. Son abonnement, si vous en avez payé un, sera annulé. Il n'y a aucun moyen d'annuler cette action. ",
          other:
            " Attention ! Toutes vos données seront supprimées. Une fois l'opération terminée, vous serez déconnecté et votre compte sera supprimé de notre système. Il n'y a aucun moyen d'annuler cette action. ",
        }),
      titleClient: (d) => "Supprimer " + d.clientName + " en tant que client",
      titleClientFallback: () => "Supprimer le client",
      titleSelf: () => "Supprimer le compte",
    },
    notifications: {
      errors: {
        generic: () =>
          "Impossible de mettre à jour vos paramètres de notification. Veuillez contacter le support.",
      },
    },
    pageTitle: (d) => "Paramètres " + d.tab,
    plan: {
      clientOwned: {
        body: () =>
          "Veuillez contacter directement le client et lui demander de mettre à niveau son compte si vous souhaitez accéder à des fonctionnalités verrouillées ou à des nombres de transactions plus élevés.",
        title: () => "Ce client gère sa propre facturation",
      },
      title: () => "Plans",
    },
    portfolio: () => "Portefeuille",
    portfolioTab: {
      assetTracking: () => "Suivi des actifs",
    },
    priceWindow: {
      off: () =>
        "Aucune fenêtre de correspondance des prix ne sera utilisée pour déterminer les prix du marché",
      on: (d) =>
        "Lorsqu'il n'existe pas de prix de marché exact pour un actif cryptographique, utilisez le prix disponible le plus proche dans un laps de temps de " +
        d.value +
        " heures",
      title: () => "Fenêtre de repli des prix",
    },
    privacy: {
      part1: () => "Nous ne partageons pas vos données. Lisez notre ",
      part2: () => "politique de confidentialité",
    },
    profile: {
      addEmail: () => "Ajouter une adresse e-mail",
      addInviteCode: () => "Ajoutez votre code d'invitation",
      addName: () => "Ajoutez votre nom",
      changeEmail: () => "Changer d'email",
      changeInviteCode: () => "Changer votre code d'invitation",
      changeName: () => "Changer de nom",
      country: () => "Pays",
      debug: {
        title: () => "Soutien",
        uid: {
          notification: () => "UID copié dans le presse-papiers",
          title: () => "ID de l'utilisateur",
        },
      },
      duplicateError: () => "Ce code est déjà utilisé",
      edit: () => "Modifier",
      email: () => "E-mail",
      emailAddressChanged: () => "Adresse e-mail modifiée",
      emailError: () => "Erreur de courrier électronique",
      emailSent: () => "E-mail envoyé",
      experience: {
        smoothScrolling: {
          title: () => "Défilement fluide (ce navigateur uniquement)",
          tooltipOff: () =>
            "Le défilement fluide sur la page Transactions est désactivé pour ce navigateur",
          tooltipOn: () =>
            "Le défilement fluide sur la page Transactions est activé pour ce navigateur",
        },
        title: () => "Expérience",
      },
      inviteCode: () => "Code d'invitation",
      inviteCodeChanged: () => "Le code d'invitation a été modifié",
      lengthError: () => "Votre code doit comporter entre 4 et 24 caractères.",
      linkedClientsEmail: () => "Courriel du client",
      linkedClientsName: () => "Nom du client",
      localCurrency: () => "Monnaie locale",
      logout: () => "Déconnexion",
      name: () => "Nom",
      nameChanged: () => "Nom changé",
      nameToolTip: () =>
        "Si fourni, ce nom sera affiché sur vos rapports générés",
      noLinkedClient: () => "Aucun client lié",
      notConnected: () => "Non connecté",
      oauth: {
        areYouSure: (d) =>
          "Êtes-vous sûr de vouloir déconnecter " + d.oauth + " OAuth ?",
        cancel: () => "Annuler",
        connected: () => "Connecté",
        disconnect: () => "Déconnecter",
        error: () =>
          "Impossible de mettre à jour les paramètres OAuth. Veuillez réessayer ultérieurement.",
        success: () => "Déconnexion réussie de OAuth",
        title: (d) => d.oauth + " OAuth",
        walletOauth: () => "Connecté via un portefeuille",
        warning: (d) =>
          "La déconnexion d'OAuth ne peut pas être annulée. En cas de déconnexion, vous devrez vous connecter à l'aide de votre adresse e-mail ( " +
          d.email +
          " ) et de votre mot de passe.",
      },
      password: {
        accountPassword: () => "Mot de passe du compte",
        changePassword: () => "Changer le mot de passe",
        confirmNewPassword: () => "Retapez le nouveau mot de passe",
        dontMatch: () => "Les mots de passe ne correspondent pas",
        newPassword: () => "Nouveau mot de passe",
        oldPassword: () => "Ancien mot de passe",
        resetPassword: () => "réinitialiser le mot de passe",
        saveNewPassword: () => "Enregistrer le nouveau mot de passe",
      },
      resendVerificationEmail: () => "Renvoyer l'e-mail",
      title: () => "Profil",
      unverified: () => "Non vérifié",
      validationError: () =>
        "Veuillez saisir un code d'invitation valide et sécurisé par URL",
      verified: () => "Vérifié",
      verifiedBanner: () => "Votre email a été vérifié",
      verifyEmail: () => "Vérifier l'email",
      wentWrongUpdatingEmail: () =>
        "Une erreur s'est produite lors de la mise à jour de l'e-mail",
      wentWrongUpdatingInviteCode: () =>
        "Une erreur s'est produite lors de la mise à jour du code d'invitation",
      wentWrongUpdatingName: () =>
        "Une erreur s'est produite lors de la mise à jour du nom",
    },
    report: {
      airdropsAsIncome: {
        title: () => "Considérez les parachutages comme des revenus",
        tooltip: {
          off: () =>
            "Les parachutages sont actuellement traités comme des acquisitions avec un coût nul",
          on: () =>
            "Les parachutages sont actuellement traités comme des revenus",
        },
      },
      allowBridgeFromUncategorised: {
        title: () => "Identifier automatiquement les transactions de pont",
        tooltip: {
          off: () =>
            "Les transactions de pont ne sont pas automatiquement identifiées à partir des transactions non catégorisées",
          on: () =>
            "Les transactions de pont sont automatiquement identifiées à partir des transactions non catégorisées",
        },
      },
      allowGenerateFeeOnGroupedQuantityDiff: {
        title: () => "Détecter automatiquement les frais de transfert",
        tooltip: {
          off: () =>
            "Ne génère pas automatiquement de frais en cas de divergence entre les montants envoyés et reçus pour les transferts/ponts groupés",
          on: () =>
            "Générer automatiquement des frais en cas de divergence entre les montants envoyés et reçus pour les transferts/ponts groupés",
        },
      },
      assumeTransferCounterparts: {
        title: () =>
          "Générer automatiquement les contreparties des transferts non appariés",
        tooltip: {
          off: () =>
            "CTC ne tentera pas de générer la contrepartie des transferts non appariés",
          on: () =>
            "CTC tentera de générer la contrepartie des transferts non appariés lorsqu'il pourra le faire en toute sécurité",
        },
      },
      bridgeAsNonTaxable: {
        title: () => "Considérer les ponts comme des biens non imposables",
        tooltip: {
          off: () =>
            "Les ponts sont actuellement traités comme des cessions et acquisitions imposables",
          on: () =>
            "Les ponts sont actuellement traités comme des transferts de base de coûts",
        },
      },
      collateralAsNonTaxable: {
        title: () =>
          "Considérez les dépôts et les retraits de garantie comme non imposables",
        tooltip: {
          off: () =>
            "Les dépôts et retraits de garanties sont actuellement traités comme des cessions et acquisitions imposables",
          on: () =>
            "Les dépôts et retraits de garanties sont actuellement traités comme des transferts",
        },
      },
      cryptoToCryptoNonTaxable: {
        title: () =>
          "Considérez les échanges de crypto à crypto comme des cessions non imposables",
        tooltip: {
          nftToCryptoTaxable: () =>
            "Les échanges de NFT vers des crypto-monnaies resteront des cessions et acquisitions imposables",
          off: () =>
            "Les échanges de crypto à crypto sont actuellement traités comme des cessions et acquisitions imposables",
          on: () =>
            "Les échanges de crypto à crypto sont actuellement traités comme des transferts de base de coûts",
          onWithDate: (d) =>
            "Les transactions de crypto à crypto à partir du " +
            d.date +
            " sont traitées comme des transferts de base de coût",
        },
      },
      emailReports: {
        options: {
          off: {
            description: () =>
              "Lors du téléchargement de rapports, nous ne les enverrons jamais par e-mail.",
            title: () => "Ne jamais envoyer de rapports par e-mail",
          },
          on: {
            description: () =>
              "Lors du téléchargement des rapports, nous vous enverrons également un lien pour télécharger le rapport. Ce lien sera valable 1 jour.",
            title: () => "Envoyez toujours les rapports par e-mail",
          },
        },
        title: () => "Rapports par courrier électronique",
        wentWrong: () =>
          "Une erreur s'est produite lors de la mise à jour des paramètres de votre rapport par e-mail.",
      },
      holdingsBalanceType: {
        title: () => "Utiliser les soldes déclarés sur le portefeuille",
        tooltip: {
          off: () =>
            "Utilisez le solde calculé déterminé par l'algorithme du moteur fiscal",
          on: () => "Utiliser le solde rapporté donné par l'intégration",
        },
      },
      ignoreBalanceRemainingValueThreshold: {
        subtext: () =>
          "Nous ignorons les problèmes de solde restant en dessous d'un certain seuil fiduciaire en raison de problèmes d'arrondi des décimales. Cela est généralement dû à des données de transaction d'échange qui ont été arrondies. Vous pouvez ajuster ce seuil ci-dessous.",
        title: () => "Ajuster la précision de l'équilibre",
      },
      ignoreFiat: {
        title: () => "Ignorer les gains sur les conversions de devises",
        tooltip: {
          off: () =>
            "Les gains sur les conversions de devises sont actuellement considérés",
          on: () =>
            "Les gains sur les conversions de devises sont actuellement ignorés",
        },
      },
      ignoreFiatLabel: () => "Ignorer Fiat",
      irs2025Warning: {
        chip: () => "À propos des changements à venir dans les règles de l'IRS",
        content: {
          body1: () =>
            "Ne vous inquiétez pas : votre déclaration de revenus de l'exercice 24 n'est pas affectée par ces changements et vous pouvez continuer à utiliser la méthode d'inventaire universel si telle est votre préférence.",
          body2: () =>
            "Ces changements s'appliquent uniquement aux transactions cryptographiques à compter du 1er janvier 2025. Vous devrez utiliser la méthode par portefeuille pour votre déclaration de revenus de l'exercice 2025, que vous déposerez en 2026.",
          header1: () =>
            "Qu'est-ce que cela signifie pour votre déclaration de revenus de l'exercice 2024 ?",
          header2: () => "Quand devez-vous passer au suivi par portefeuille ?",
          summary: () =>
            "À compter du 1er janvier 2025, l'IRS exigera l'utilisation d'une méthode par portefeuille (également connue sous le nom de « ID spécifique par portefeuille et échange ») pour suivre votre base de coût de crypto-monnaie.",
        },
        learnMore: () => "Apprendre encore plus",
      },
      lostStolenAsNonTaxable: {
        title: () =>
          "Considérez les transactions perdues ou volées comme des cessions non imposables",
        tooltip: {
          off: () =>
            "Les transactions perdues ou volées sont actuellement traitées comme des cessions imposables",
          on: () =>
            "Les transactions perdues ou volées sont actuellement traitées comme des cessions non imposables",
        },
      },
      miningAsIncome: {
        title: () => "Considérer l’exploitation minière comme un revenu",
        tooltip: {
          off: () =>
            "Les transactions minières sont actuellement traitées comme des acquisitions à coût nul",
          on: () =>
            "Les transactions minières sont actuellement traitées comme des revenus",
        },
      },
      outgoingGiftAsNonTaxable: {
        title: () =>
          "Considérez le don sortant comme une cession non imposable",
        tooltip: {
          off: () =>
            "Les transactions de cadeaux sortants sont actuellement traitées comme des cessions imposables",
          on: () =>
            "Les transactions de cadeaux sortants sont actuellement traitées comme des cessions non imposables",
        },
      },
      personalUseAsNonTaxable: {
        title: () =>
          "Considérez l’utilisation personnelle comme une cession non imposable",
        tooltip: {
          off: () =>
            "Les transactions à usage personnel sont actuellement traitées comme des cessions taxables",
          on: () =>
            "Les transactions à usage personnel sont actuellement traitées comme des cessions non imposables",
        },
      },
      report: () => "Rapport",
      royaltiesAsIncome: {
        title: () => "Considérer les redevances comme des revenus",
        tooltip: {
          off: () =>
            "Les transactions de redevances sont actuellement traitées comme des acquisitions à coût nul.",
          on: () =>
            "Les transactions de redevances sont actuellement traitées comme des revenus",
        },
      },
      showAllAccountsOnPortfolio: {
        title: () => "Afficher tous les comptes du portefeuille",
        tooltip: {
          off: () =>
            "Seuls les comptes importés sont affichés dans le portefeuille",
          on: () =>
            "Lorsque cette option est désactivée, seuls les comptes importés s'affichent dans le portefeuille. Lorsque cette option est activée, les avoirs de votre portefeuille incluront également les comptes suspects, qui sont des portefeuilles et des échanges avec lesquels une interaction a été effectuée mais qui n'ont pas été importés. Remarque : cette option affecte uniquement la vue du portefeuille, pas les calculs fiscaux.",
        },
      },
      showFiatHoldings: {
        title: () =>
          "Afficher les avoirs en monnaie fiduciaire native dans les rapports et le tableau de bord",
        tooltip: {
          off: () =>
            "Les avoirs Fiat ne sont actuellement pas affichés dans les rapports et sur le tableau de bord",
          on: () =>
            "Les avoirs Fiat sont actuellement affichés dans les rapports et sur le tableau de bord",
        },
      },
      specificInventoryByExchange: {
        disabledReason: () =>
          "Non autorisé selon la méthode d'inventaire actuelle",
        options: {
          mixed: {
            description: () =>
              "Pendant cette période, un suivi des coûts universel, par portefeuille et par échange est utilisé.",
            title: () => "Paramètres mixtes",
          },
          off: {
            description: () =>
              "Le coût de base sera suivi universellement sur tous les portefeuilles et échanges.",
            title: () => "Universel",
          },
          on: {
            description: () =>
              "Le coût de base est suivi par portefeuille et par bourse. Cela signifie qu'il peut y avoir plusieurs bases de coût pour un actif en fonction du prix d'achat auquel la devise a été achetée.",
            title: () => "Par portefeuille et échange",
          },
        },
        title: () => "Suivi des coûts de base",
      },
      splitFeesEvenlyAcrossGroupedMints: {
        title: () =>
          "Répartir les frais de manière égale sur les transactions monétaires groupées",
        tooltip: {
          off: () =>
            "Les transactions de frappe groupées ne comportent actuellement pas de frais répartis de manière égale sur tous les actifs frappés",
          on: () =>
            "Les transactions de frappe groupées ont actuellement des frais répartis de manière égale sur tous les actifs frappés",
        },
      },
      stakingAsIncome: {
        title: () =>
          "Considérez les récompenses de jalonnement comme des revenus",
        tooltip: {
          off: () =>
            "Les récompenses de jalonnement sont actuellement traitées comme des acquisitions avec un coût nul",
          on: () =>
            "Les récompenses de jalonnement sont actuellement traitées comme des revenus",
        },
      },
      stakingDepositWithdrawalNonTaxable: {
        title: () =>
          "Considérez les dépôts et les retraits de jalonnement comme non imposables",
        tooltip: {
          off: () =>
            "Les dépôts et retraits de jalonnement sont actuellement traités comme des cessions et acquisitions imposables",
          on: () =>
            "Les dépôts et les retraits de jalonnement sont actuellement traités comme des transferts",
        },
      },
      taxSettings: () => "Paramètres fiscaux",
      title: () => "Paramètres du rapport",
      treatLiquidityAddRemovalNonTaxable: {
        title: () => "Traiter l’ajout de liquidité comme non imposable",
        tooltip: {
          off: () =>
            "Les opérations de liquidité sont actuellement traitées comme des cessions et acquisitions imposables",
          on: () =>
            "Les transactions de liquidité sont actuellement traitées comme des transferts de base de coût",
        },
      },
      uncategorisedInAsTaxable: {
        title: () =>
          "Traiter les transactions entrantes non catégorisées comme des achats",
        tooltip: {
          off: () =>
            "Les transactions entrantes non catégorisées sont actuellement traitées comme des transferts non imposables",
          on: () =>
            "Les transactions entrantes non catégorisées sont actuellement traitées comme des achats",
        },
      },
      uncategorisedOutAsTaxable: {
        title: () =>
          "Traiter les transactions sortantes non catégorisées comme des ventes",
        tooltip: {
          off: () =>
            "Les transactions sortantes non catégorisées sont actuellement traitées comme des transferts non imposables",
          on: () =>
            "Les transactions sortantes non catégorisées sont actuellement traitées comme des ventes",
        },
      },
      updatedTaxSettingsError: () =>
        "Une erreur s'est produite lors de la mise à jour de vos paramètres fiscaux. Veuillez réessayer ultérieurement.",
      updatedTaxSettingsSuccess: () =>
        "Mise à jour réussie des paramètres fiscaux",
    },
    reportActionButton: {
      action: () => "Rafraîchir les calculs",
      loadingAction: () => "Rafraîchissant",
      loadingTooltip: () => "Cela peut prendre un certain temps",
      tooltip: () =>
        "Déclencher manuellement une actualisation de tous les calculs",
    },
    reportSettings: () => "Paramètres du rapport",
    sidebar: {
      loading: () => "Chargement...",
      searchClients: () => "Rechercher des clients",
    },
    somethingWentWrong: () =>
      "Une erreur s'est produite ! Veuillez contacter le support",
    somethingWentWrongContact: () =>
      "Une erreur s'est produite ! Veuillez contacter le support si le problème persiste.",
    supportAccess: {
      body: {
        accessGrantedAt: (d) => "Accès accordé : " + d.accessGrantedAt,
        button: {
          grantAccess: () => "Accorder l'accès",
          revokeAccess: () => "Révoquer l'accès",
        },
        description: () =>
          "Autorisez l'assistance à accéder aux données de votre compte pour vous aider à résoudre les problèmes. L'accès sera accordé pour une durée maximale de 30 jours.",
        title: () => "Accès aux subventions",
      },
      title: () => "Soutien",
    },
    tabs: {
      account: () => "Compte",
      alpha: () => "Alpha",
      billing: () => "Facturation",
      general: () => "Impôt",
      integrations: () => "Comptes",
      "integrations-deprecated": () => "Intégrations",
      loading: () => "Chargement",
      "lock-period": () => "Périodes de blocage",
      permissions: () => "Autorisations",
      plan: () => "Plan",
      portfolio: () => "Portefeuille",
      team: () => "Équipe",
    },
    team: {
      header: {
        email: () => "E-mail",
        lastLogin: () => "Dernière connexion",
        name: () => "Nom",
        search: () => "Recherche",
      },
      invite: () => "Inviter",
      inviteTeamMember: () => "Inviter un membre de l'équipe",
      inviteTooltip: (d) => "Invité " + d.date,
      managementAndPermissions: () => "Gestion",
      noResults: () => "Aucun résultat",
      noTeamMembers: () => "Aucun membre de l'équipe",
      owner: () => "Propriétaire",
    },
    threshold: () => "Seuil",
    timezone: () => "Fuseau horaire",
    title: () => "Paramètres",
  },
  similarTransactionsSuggestion: {
    accept: () => "Confirmer",
    applyToFuture: () => "Appliquer aux transactions futures",
    categorizeItAs: () => "Catégoriser comme :",
    deny: () => "Refuser",
    modal: {
      buttonCTA: () => "Sélectionnez une catégorie",
      receivedFrom: () => "Reçu de :",
      sentTo: () => "Envoyé à :",
      similarTxs: () => "Transactions similaires:",
    },
    noSimilar: () => "Aucune transaction similaire trouvée",
    notNow: () => "Pas maintenant",
    operators: {
      actionId: () => "ID d'action",
      after: () => "Après",
      and: () => "Et",
      before: () => "Avant",
      blockchain: () => "Blockchain",
      category: () => "Catégorie",
      comment: () => "Commentaire",
      comments: () => "Commentaires",
      currency: () => "Devise",
      date: () => "Date",
      description: () => "Description",
      feeCurrency: () => "Devise des frais",
      from: () => "Source",
      hasRule: () => "Masquer les transactions non affectées par les règles",
      id: () => "IDENTIFIANT",
      importId: () => "ID d'importation",
      importType: () => "Type d'importation",
      isSmartContractInteraction: () => "Interaction",
      missingPrice: () => "Prix manquant",
      negativeBalance: () => "Historique d'achat manquant",
      nftCollection: () => "Collection NFT",
      not: () => "Pas",
      or: () => "Ou",
      party: () => "Faire la fête",
      reconciliation: () => "Réconciliation",
      reviewed: () => "Révisé",
      rule: () => "Règle",
      ruleOperator: () => "Opérateur de règle",
      source: () => "Compte",
      suggestion: () => "Suggestion",
      sync: () => "Synchroniser",
      to: () => "Destination",
      trade: () => "Catégorie",
      txCurrency: () => "Devise du Texas",
      txFunctionName: () => "Signature de la transaction",
      txFunctionSignature: () => "ID de la méthode de transaction",
      txId: () => "ID de transaction",
      warning: () => "Avertissement",
    },
    optOut: {
      button: () => "Voir",
      loadingSimilar: () => "Trouver des transactions similaires",
      noSimilar: () => "Aucune transaction similaire",
      title: (d) =>
        "Appliquer cette modification à [ " +
        plural(d.count, 0, fr, {
          one: " 1 similar transaction ",
          other: number("fr", d.count, 0) + " similar transactions ",
        }) +
        " ]( " +
        d.linkTextKey +
        " )",
      tooltip: {
        criteriaFour: () => "Avoir la même signature de transaction",
        criteriaOne: () => "Sont dans la même direction",
        criteriaThree: () => "Avoir la même monnaie",
        criteriaTwo: () => "Sont vers et depuis les mêmes portefeuilles",
        title: () => "Les transactions similaires sont celles qui :",
      },
    },
    similarTransactionsFound: (d) =>
      plural(d.count, 0, fr, {
        one: " 1 transaction similaire ",
        other: number("fr", d.count, 0) + " transactions similaires ",
      }),
    successToast: (d) =>
      "Catégorisé " +
      d.count +
      " similaire " +
      plural(d.count, 0, fr, { one: " transaction ", other: " transactions " }),
    viewTransactions: () => "Voir les transactions",
    weHaveFound: (d) =>
      "Nous avons trouvé " +
      plural(d.count, 0, fr, {
        one: " 1 transaction similaire ",
        other: number("fr", d.count, 0) + " transactions similaires ",
      }) +
      " . " +
      plural(d.count, 0, fr, {
        one: " Cette transaction a ",
        other: " Ces transactions ont ",
      }) +
      " la même chose :",
  },
  skip: () => "Sauter",
  spam: () => "Marquer comme spam",
  start: () => "Commencer",
  support: {
    contact: () => "Veuillez contacter le support pour obtenir de l'aide",
    deleteAccountDisabled: () =>
      "Cette fonctionnalité n'est pas disponible pour le plan comptable. Veuillez contacter le support pour obtenir de l'aide.",
  },
  supportedLanguages: {
    de: () => "Allemand",
    eng: () => "Anglais",
    es: () => "Espagnol",
    fr: () => "Français",
    it: () => "Italien",
    xx: () => "Langage de débogage Xx",
    xxl: () => "XX long",
    xxs: () => "XX court",
  },
  sync: {
    actionErpSyncFailed: (d) =>
      "Échec de la synchronisation avec " + d.erpProvider,
    apiStillSyncing: () =>
      "L'API est toujours en cours de synchronisation, veuillez patienter quelques minutes.",
    integrationSyncing: (d) => d.name + " est en train de synchroniser...",
    reportRefreshFailed: () => "Les calculs n'ont pas pu être mis à jour",
    syncFailed: (d) =>
      "La synchronisation pour " +
      d.name +
      " a échoué. Assurez-vous de suivre correctement les instructions d'importation. Si le problème persiste, contactez le support.",
    syncOneHour: (d) =>
      "Synchronisation avec l'API " +
      d.name +
      " . Ce processus peut prendre jusqu'à 1 heure.",
    syncSeveralMinutes: (d) =>
      "Synchronisation avec l'API " +
      d.name +
      " . Ce processus peut prendre plusieurs minutes.",
    syncingAPIs: (d) => "Synchronisation des API " + d.name,
    syncingBlockchainWallets: (d) =>
      "Synchronisation des portefeuilles " + d.name,
    syncingName: (d) => "Synchronisation " + d.name,
    uploadDeprecated: {
      coinbase: () =>
        "Vous utilisez une clé API Coinbase obsolète, veuillez mettre à jour votre API conformément aux dernières instructions",
      generic: () =>
        "Vous utilisez une clé API obsolète, veuillez mettre à jour votre API conformément aux dernières instructions",
    },
    uploadFail: (d) =>
      "Un problème est survenu lors de l'importation " +
      d.name +
      " . Si cela continue, veuillez contacter le support.",
    uploadingFiveMins: (d) =>
      "Téléchargement des données " +
      d.name +
      " . Cette opération peut prendre jusqu'à 5 minutes. Vous pouvez continuer à utiliser l'application pendant ce temps.",
    xeroSyncFailed: () => "La synchronisation Xero a échoué",
  },
  syncErrorReasons: {
    "api-changed": {
      ctaType: () => "Contactez le support pour obtenir de l'aide.",
      message: () => "Les données reçues de l'échange ont changé.",
    },
    "api-unavailable": {
      ctaType: () => "Veuillez réessayer plus tard.",
      message: (d) =>
        "Le serveur API " +
        d.exchangeName +
        " n'est actuellement pas disponible.",
    },
    "app-error": {
      ctaType: () => "Contactez le support si le problème persiste.",
      message: () => "Une erreur inattendue s'est produite dans l'application.",
    },
    "csv-invalid-column": {
      ctaType: () => "Vérifiez le fichier et réessayez.",
      message: () => "Le fichier CSV contient une colonne non valide.",
    },
    "csv-not-required": {
      ctaType: () => "Assurez-vous d'avoir téléchargé le bon fichier.",
      message: () => "Le fichier CSV n'est pas requis pour cette intégration.",
    },
    "csv-not-supported": {
      ctaType: () => "Assurez-vous d'avoir téléchargé le bon fichier.",
      message: () =>
        "Le fichier CSV n'est pas pris en charge pour cette intégration.",
    },
    "expired-api-key": {
      ctaType: () =>
        "Générez une nouvelle clé en suivant les instructions d'intégration étape par étape.",
      message: () => "Clé API expirée.",
    },
    "invalid-api-key": {
      ctaType: () => "Vérifiez les instructions et réessayez.",
      message: () => "Clé API non valide.",
    },
    "invalid-csv": {
      ctaType: () => "Vérifiez le fichier et le contenu.",
      message: () => "Format CSV non valide.",
    },
    "invalid-csv-headers": {
      ctaType: () => "Vérifiez le format et réessayez.",
      message: () => "Noms de colonnes non valides.",
    },
    "invalid-permissions": {
      ctaType: () =>
        "Vérifiez si les restrictions IP ou d'accès sont correctement configurées.",
      message: () => "Autorisations API manquantes.",
    },
    "invalid-user-defined-field": {
      ctaType: () => "Vérifiez vos entrées ou votre configuration.",
      message: () => "La demande contient des champs non valides.",
    },
    "invalid-wallet-address": {
      ctaType: () => "Vérifiez les instructions et réessayez.",
      message: () => "Adresse de portefeuille non valide.",
    },
    "no-transactions": {
      ctaType: () => "Vérifiez la source des données ou les entrées.",
      message: () => "Aucune transaction n'a été trouvée.",
    },
    "rate-limit-failed": {
      ctaType: () => "Réessayez dans 5 minutes.",
      message: () => "Limite de débit atteinte.",
    },
    timeout: {
      ctaType: () => "Veuillez réessayer plus tard.",
      message: () => "La demande a expiré. ",
    },
    "too-many-transactions": {
      ctaType: () => "Contactez le support pour obtenir de l'aide.",
      message: () => "Limite de transaction dépassée",
    },
    "unknown-error": {
      ctaType: () => "Contactez le support pour obtenir de l'aide.",
      message: () => "Une erreur inconnue s'est produite.",
    },
    "unsupported-data-format": {
      ctaType: () => "Contactez le support pour obtenir de l'aide.",
      message: () =>
        "Le format de données reçu de l'API n'est pas pris en charge.",
    },
  },
  syncHistory: {
    confirm: {
      text: () =>
        "Êtes-vous sûr de vouloir supprimer toutes les données de cette synchronisation ? Cela supprimera toutes les transactions associées à cette synchronisation. Cette opération ne peut pas être annulée.",
      title: () => "Supprimer la synchronisation d'importation",
      warning: (d) => d.name + " le " + d.date,
    },
    date: () => "Date",
    delete: () => "Supprimer",
    hardSync: () => "Synchronisation matérielle",
    id: () => "IDENTIFIANT",
    initialSync: () => "Synchronisation initiale",
    name: () => "Nom",
    noneFound: () => "Aucun historique de synchronisation réussi trouvé.",
    title: () => "Synchroniser l'historique",
    transactions: () => "transactions",
    transactionsHeader: () => "Transactions",
    viewTx: () => "Voir les transactions",
  },
  syncStatus: {
    failed: () => "La mise à jour a échoué",
    reconciliation: () => "Rapprochement des transactions",
    retry: () => "Rafraîchir les calculs",
    updated: () => "Mis à jour",
    updating: () => "Mise à jour en cours",
  },
  tabNav: {
    connectedAccounts: () => "Comptes connectés",
    more: () => "Plus",
    nfts: () => "NFT",
    plan: () => "Plan",
    report: () => "Rapport",
    review: () => "Revoir",
    settings: () => "Paramètres",
    summary: () => "Résumé",
    transactions: () => "Transactions",
  },
  tag: {
    createNew: () => "Créer une identité",
    createNewIdentity: () => "Créer une nouvelle identité",
    deleteMessage: (d) =>
      d.address + " ne sera plus identifié comme " + d.entityName,
    groups: {
      Contract: () => "Contrats",
      Exchange: () => "Échanges",
      Extension: () => "Échanges",
      Manual: () => "Coutume",
    },
    identified: (d) => d.count + " identifié",
    identify: () => "Identifier",
    identifyAddress: () => "Identifier l'adresse",
    identifyAddressModal: {
      description: () =>
        "Cela identifiera l'adresse sur toutes les transactions.",
      doneButton: () => "Fait",
      title: () => "Identifier l'adresse",
    },
    identifyDescription: () =>
      "Cela identifiera l'adresse sur toutes les transactions.",
    manageAddressModal: {
      description: (d) =>
        "Cette adresse est identifiée comme " + d.entityDisplayName + " .",
      descriptionContract: (d) =>
        "Ce contrat est identifié comme " + d.entityDisplayName + " .",
      doneButton: () => "Fait",
      title: () => "Gérer l'adresse",
    },
    refAddressModal: {
      description: (d) =>
        "Toutes les adresses identifiées comme " + d.entityDisplayName + " .",
      doneButton: () => "Fait",
      title: (d) => "Gérer " + d.entityDisplayName,
    },
    startTypingName: () => "Commencez à taper un nom",
    thisAddress: () => "Cette adresse",
  },
  taxAuthorityByCountry: {
    AD: () => "DT",
    AE: () => "FTA",
    AF: () => "ARCA",
    AG: () => "IRD",
    AI: () => "IRD",
    AL: () => "GDT",
    AM: () => "SRC",
    AO: () => "AGT",
    AR: () => "AFIP",
    AS: () => "ASG-T",
    AT: () => "BMF",
    AU: () => "ATO",
    AW: () => "DIMP",
    AZ: () => "STM",
    BA: () => "ITA",
    BB: () => "BRA",
    BD: () => "NBR",
    BE: () => "SPFF",
    BF: () => "DGI",
    BG: () => "NRA",
    BH: () => "NBR",
    BI: () => "OBR",
    BJ: () => "DGI",
    BM: () => "OTC",
    BN: () => "MOFE",
    BO: () => "SIN",
    BQ: () => "BCN",
    BR: () => "RFB",
    BS: () => "IRD",
    BT: () => "DRC",
    BV: () => "BVT",
    BW: () => "BURS",
    BY: () => "MTC",
    BZ: () => "IRD",
    CA: () => "ARC",
    CF: () => "DGID",
    CG: () => "DGI",
    CH: () => "AFC",
    CI: () => "DGI",
    CL: () => "SII",
    CM: () => "DGT",
    CN: () => "STA",
    CO: () => "DIAN",
    CR: () => "DGT",
    CU: () => "ONAT",
    CV: () => "DNRE",
    CW: () => "NOB",
    CY: () => "TAD",
    CZ: () => "FSAD",
    DE: () => "BZSt",
    DJ: () => "DGI",
    DK: () => "SKAT",
    DM: () => "IRD",
    DO: () => "DGII",
    DZ: () => "DGI",
    EC: () => "SRI",
    EE: () => "ETCB",
    EG: () => "ETA",
    ER: () => "IRD",
    ES: () => "AEAT",
    ET: () => "ERCA",
    FI: () => "FTA",
    FJ: () => "FRCS",
    FK: () => "FIG",
    FM: () => "DoF",
    FO: () => "TAKS",
    FR: () => "DGFIP",
    GA: () => "DGI",
    GB: () => "HMRC",
    GD: () => "IRD",
    GE: () => "GRS",
    GF: () => "DGFIP",
    GH: () => "GRA",
    GI: () => "ITO",
    GL: () => "TSN",
    GM: () => "GRA",
    GN: () => "DNI",
    GP: () => "DGFIP",
    GQ: () => "DGI",
    GR: () => "IAPR",
    GT: () => "SAT",
    GU: () => "DRT",
    GW: () => "DGI",
    GY: () => "GRA",
    HK: () => "IRD",
    HN: () => "SAR",
    HR: () => "TA",
    HT: () => "DGI",
    HU: () => "NTCA",
    ID: () => "DGT",
    IE: () => "TRC",
    IL: () => "ITA",
    IM: () => "ITD",
    IN: () => "ITR",
    IR: () => "INTA",
    IS: () => "RSK",
    IT: () => "ADE",
    JE: () => "RT",
    JM: () => "TAJ",
    JO: () => "ISTD",
    JP: () => "NTA",
    KE: () => "KRA",
    KG: () => "STS",
    KH: () => "GDT",
    KI: () => "MFED",
    KM: () => "AGID",
    KN: () => "IRD",
    KP: () => "MoF",
    KR: () => "NTS",
    KW: () => "MT",
    KY: () => "DCI",
    KZ: () => "SRC",
    LA: () => "TD",
    LB: () => "MoF",
    LC: () => "IRD",
    LI: () => "STV",
    LK: () => "IRD",
    LR: () => "LRA",
    LS: () => "LRA",
    LT: () => "STI",
    LU: () => "ACD",
    LV: () => "SRS",
    LY: () => "TD",
    MA: () => "DGI",
    MC: () => "DSF",
    MD: () => "STS",
    ME: () => "TA",
    MF: () => "DGFIP",
    MG: () => "DGI",
    MH: () => "MIRC",
    MK: () => "PRO",
    ML: () => "DGI",
    MM: () => "IRD",
    MN: () => "MTA",
    MO: () => "DSF",
    MP: () => "DRT",
    MQ: () => "DGFIP",
    MR: () => "DGI",
    MS: () => "IRD",
    MT: () => "CfR",
    MU: () => "MRA",
    MV: () => "MIRA",
    MW: () => "MRA",
    MX: () => "SAT",
    MY: () => "LHDN",
    MZ: () => "AT",
    NA: () => "IR",
    NC: () => "DSF",
    NE: () => "DGI",
    NG: () => "FIRS",
    NI: () => "DGI",
    NL: () => "DTCA",
    NO: () => "NTA",
    NP: () => "IRD",
    NR: () => "DoF",
    NU: () => "TR",
    NZ: () => "IRD",
    OM: () => "TR",
    PA: () => "DGI",
    PE: () => "SUNAT",
    PF: () => "DICP",
    PG: () => "IRC",
    PH: () => "BIR",
    PK: () => "FBR",
    PL: () => "KAS",
    PM: () => "DGFIP",
    PR: () => "DH",
    PS: () => "ITA",
    PT: () => "PTCA",
    PW: () => "BRT",
    PY: () => "SET",
    QA: () => "GTA",
    RE: () => "DGFIP",
    RO: () => "ANAF",
    RS: () => "PU",
    RU: () => "FTS",
    RW: () => "RRA",
    SA: () => "GAZT",
    SB: () => "IRD",
    SC: () => "SRC",
    SD: () => "SRTD",
    SE: () => "STA",
    SG: () => "IRAS",
    SI: () => "FURS",
    SK: () => "FR",
    SL: () => "NRA",
    SM: () => "SMR",
    SN: () => "DGID",
    SO: () => "MoF",
    SR: () => "DB",
    SS: () => "NRA",
    SV: () => "DGII",
    SX: () => "TD",
    SY: () => "MoF",
    SZ: () => "SRA",
    TC: () => "RD",
    TD: () => "DGI",
    TG: () => "OTR",
    TH: () => "RD",
    TJ: () => "TC",
    TL: () => "DNRT",
    TM: () => "MTT",
    TN: () => "DGI",
    TO: () => "IRD",
    TR: () => "GIB",
    TT: () => "BIR",
    TV: () => "IRD",
    TW: () => "NTBT",
    TZ: () => "TRA",
    UA: () => "STS",
    UG: () => "URA",
    UK: () => "HMRC",
    US: () => "IRS",
    UY: () => "DGI",
    UZ: () => "STC",
    VA: () => "N/A",
    VC: () => "IRD",
    VE: () => "SENIAT",
    VG: () => "IRD",
    VI: () => "IRB",
    VN: () => "GDT",
    VU: () => "DCIR",
    WF: () => "DGFIP",
    WS: () => "MfR",
    YE: () => "TY",
    YT: () => "DGFIP",
    ZA: () => "SARS",
    ZM: () => "ZRA",
    ZW: () => "ZIMRA",
  },
  taxDivision: {
    buy: () => "Acheter",
    disposal: () => "Élimination",
    group: () => "Groupes",
    hidden: () => "Caché",
    ignore: () => "Ignorer",
    income: () => "Revenu",
    lastUsed: () => "Récemment sélectionné",
    payment: () => "Paiement",
    transfer: () => "Transfert",
    withdrawal: () => "Retrait",
  },
  taxLossHarvesting: {
    errors: {
      unableToLoad: () =>
        "Impossible de charger la fenêtre modale de récupération des pertes",
    },
    gain: () => "Gains",
    iconTooltips: {
      editPrice: () => "Modifier le prix",
      refreshPrice: () => "Actualiser les prix du marché",
      resetPrice: () => "Réinitialiser le prix au prix actuel du marché",
      savePrice: () => "Économisez le prix",
    },
    open: () => "Pertes de récoltes",
    openWhenNoLosses: () => "Voir l'outil de récolte",
    paywallModal: {
      aboutLTFO: () =>
        "À propos de notre méthode d’inventaire la plus efficace sur le plan fiscal",
      aboutTaxLossHarvesting: () =>
        "À propos de la récupération des pertes fiscales",
      benefits: {
        lockIn: () =>
          "Possibilité de bloquer les pertes en capital pour les reporter sur les années futures",
        lockInNoCGT: () =>
          "Possibilité de bloquer les pertes non réalisées pour les reporter sur les années futures",
        offset: () =>
          "Potentiellement compenser les gains en capital en dehors de la crypto avec des pertes en capital en crypto",
        offsetNoCGT: () =>
          "Potentiellement compenser les bénéfices réalisés en dehors de la crypto avec les pertes réalisées en crypto",
        optimize: () =>
          "Optimisez vos impôts grâce à la méthode d'inventaire la plus efficace fiscalement",
      },
      subtitlePost: () => "en pertes.",
      subtitlePre: () => "Vous pourriez récolter",
      title: () => "Réduisez potentiellement votre facture fiscale.",
      upgradePrompt: () =>
        "Passez au plan investisseur ou supérieur pour débloquer la récupération des pertes",
    },
    postHarvestCapitalGains: () => "Plus-values après récolte",
    postHarvestCapitalGainsNoCGT: () => "Bénéfices après récolte",
    realisedCapitalGains: () => "Gains en capital réalisés",
    realisedCapitalGainsNoCGT: () => "Bénéfices réalisés",
    showAssetsWithoutMarketPrice: () =>
      "Afficher les actifs sans prix de marché",
    summary: {
      netCapitalGainLoss: () => "Gain/perte net en capital",
      netCapitalGainLossNoCGT: () => "Bénéfice/perte net réalisé",
    },
    table: {
      loading: () => "Chargement...",
      noHoldings: () => "Aucune participation à afficher",
      rowSelectionDisabled: () =>
        "Vous ne pouvez sélectionner que des devises avec des pertes non réalisées.",
      title: () => "Possibilités d’économie d’impôt",
    },
    tableHeaders: {
      amountHeld: () => "Montant détenu",
      amountToSell: () => "Montant à vendre",
      asset: () => "Actif",
      costBasis: () => "Base de coûts",
      currentMarketValue: () => "Valeur marchande",
      price: () => "Prix",
      unrealizedGainsLosses: () => "Gains/pertes non réalisés",
    },
    tableSearch: () => "Trouver un actif",
    taxSavingOpportunities: () => "Possibilités d’économie d’impôt",
    title: () => "Récolte des pertes fiscales",
    tooltips: {
      unrealizedGains: () =>
        "La différence entre le prix de base d'un actif et sa valeur marchande actuelle",
    },
    viewTaxLosses: () => "Afficher les pertes fiscales",
  },
  taxRule: {
    "bed-and-breakfast": () => "Règle BnB",
    "fee-forwarded": () => "Frais transférés",
    "margin-fee": () => "Frais de marge",
    "recalculated-after-bed-and-breakfast": () => "Recalcul BnB",
    "same-day": () => "Règle du jour même",
    "superficial-loss-from-buy-back": () => "Rachat superficiel",
    "superficial-loss-from-sell": () => "Vente superficielle",
    "zero-cost-buy": () => "Solde manquant",
  },
  taxSettingBanner: {
    affectCalculationsWarning: () =>
      "Veuillez noter que ces options peuvent avoir un impact sur vos calculs pour les années fiscales passées.",
    cta: () => "Jetez un oeil",
    title: () => "✨ Nouvelle automatisation disponible !",
  },
  taxToggles: () => "Bascules fiscales",
  testKey: () =>
    "Cela ne se traduira PLUS et ajoutera du texte traduit supplémentaire",
  theCounterParty: () => "la contrepartie",
  theme: {
    betaModal: {
      actionText: () => "Passer en mode sombre",
      content1: () =>
        "Nous sommes encore en train de régler certains problèmes liés aux couleurs en mode sombre. Certains composants risquent de ne pas s'afficher correctement.",
      content2: () => "Cela n'a aucun impact sur vos calculs et vos données.",
      title: () => "Le mode sombre est en version bêta",
    },
    short: {
      dark: () => "Sombre",
      light: () => "Lumière",
      system: () => "Système",
    },
    toggle: {
      dark: () => "Mode sombre",
      light: () => "Mode lumière",
      system: () => "Valeur par défaut du système",
    },
  },
  title: () => "Calculateur de taxes sur les crypto-monnaies",
  to: () => "à",
  tokenLogin: {
    errorSubtitle: () =>
      "Veuillez vérifier votre lien de connexion - ou vous pouvez essayer de vous connecter en utilisant votre e-mail et votre mot de passe.",
    errorTitle: () => "Impossible de vous connecter",
    subtitle: () =>
      "Veuillez patienter pendant que nous vous connectons à votre compte",
    title: () => "Vous connecter à votre compte",
    visitLogin: () => "Accéder à la page de connexion",
  },
  topBanner: {
    coupon: () => "Vendredi noir 2020",
    msg: () =>
      "Black Friday • bénéficiez de 60 % de réduction sur l'abonnement de la première année • CODE : ",
  },
  tradeType: {
    addLiquidity: () => "Ajouter de la liquidité",
    airdrop: () => "Parachutage aérien",
    approval: () => "Approbation",
    "arbitrary-uncategorised": () => "Classer par catégories",
    borrow: () => "Prêt",
    bridge: () => "Pont",
    "bridge-trade": () => "Commerce inter-chaînes",
    "bridge-transfer": () => "Pont",
    bridgeIn: () => "Pont dans",
    bridgeOut: () => "Pont sortant",
    bridgeTradeIn: () => "Achat en chaîne croisée",
    bridgeTradeOut: () => "Vente en chaîne croisée",
    bridgeTransferIn: () => "Transfert de pont en",
    bridgeTransferOut: () => "Pont-Transfert de sortie",
    burn: () => "Brûler",
    buy: () => "Acheter",
    cashback: () => "Rabais",
    chainSplit: () => "Séparation de la chaîne",
    collateral: () => "Dépôt de garantie",
    collateralWithdrawal: () => "Retrait de garantie",
    "decrease-position": () => "Diminuer la position",
    decreasePositionDeposit: () => "Diminuer la position",
    deposit: () => "Recevoir",
    derivatives: () => "Trading PnL",
    expense: () => "Frais",
    failed: () => "Échoué",
    failedIn: () => "Échec dans",
    failedOut: () => "Échec",
    fee: () => "Frais",
    fiat: () => "Décret",
    "fiat-transfer": () => "Transfert Fiat",
    fiatDeposit: () => "Dépôt fiduciaire",
    fiatWithdrawal: () => "Retrait de Fiat",
    gift: () => "Cadeau",
    gifts: () => "Cadeaux",
    ignoreIn: () => "Ignorer dans",
    ignoreOut: () => "Ignorer",
    ignored: () => "Ignoré",
    in: () => "Entrant",
    income: () => "Revenu",
    "increase-position": () => "Augmenter la position",
    increasePositionWithdrawal: () => "Augmenter la position",
    interest: () => "Intérêt",
    liquidate: () => "Liquidation",
    liquidity: () => "Liquidité",
    loan: () => "Prêts",
    loanRepayment: () => "Remboursement du prêt",
    long: () => "Long",
    lost: () => "Perdu",
    marginFee: () => "Frais de marge",
    mining: () => "Exploitation minière",
    mint: () => "Menthe",
    "multi-mint": () => "Menthe",
    "multi-trade": () => "Commerce",
    notAvailable: () => "Achat sans frais",
    out: () => "Sortant",
    outgoingGift: () => "Cadeau sortant",
    personalUse: () => "Utilisation personnelle",
    "provide-liquidity-trade": () => "Ajouter de la liquidité",
    realisedLoss: () => "Perte réalisée",
    realisedProfit: () => "Bénéfice réalisé",
    receivePQ: () => "Recevoir un jeton de position",
    receivingLiquidityProviderToken: () => "Recevoir un jeton de réception",
    "remove-liquidity-trade": () => "Supprimer la liquidité",
    removeLiquidity: () => "Supprimer la liquidité",
    returningLiquidityProviderToken: () => "Envoyer un jeton de réception",
    reward: () => "Récompense",
    royalty: () => "Redevance",
    sell: () => "Vendre",
    sendPQ: () => "Envoyer un jeton de position",
    short: () => "Court",
    spamIn: () => "Courrier indésirable",
    "stake-deposit-group": () => "Dépôt de jalonnement",
    "stake-withdrawal-group": () => "Retrait de jalonnement",
    staking: () => "Récompense de jalonnement",
    stakingDeposit: () => "Dépôt de jalonnement",
    stakingWithdrawal: () => "Retrait de jalonnement",
    stakings: () => "Jalonnement",
    stolen: () => "Volé",
    swap: () => "Échanger",
    swapIn: () => "Échanger",
    swapOut: () => "Échanger",
    swaps: () => "Échanges",
    trade: () => "Commerce",
    transfer: () => "Transfert",
    uncategorised: () => "Non classé",
    unknown: () => "Inconnu",
    withdrawal: () => "Envoyer",
  },
  tradeTypeCombined: {
    borrow_loanRepayment: () => "Prêt et remboursement de prêt",
    bridgeIn_bridgeOut: () => "Entrée et sortie du pont",
    bridgeTradeIn_bridgeTradeOut: () => "Achat et vente de chaînes croisées",
    bridgeTransferIn_bridgeTransferOut: () => "Entrée et sortie du pont",
    buy_sell: () => "Acheter et vendre",
    collateralWithdrawal_collateral: () => "Dépôt et retrait de garanties",
    failedIn_failedOut: () => "Échec à l'entrée et à la sortie",
    fiatDeposit_fiatWithdrawal: () => "Dépôt et retrait en monnaie fiduciaire",
    ignoreIn_ignoreOut: () => "Ignorer les entrées et sorties",
    receive_send: () => "Recevoir et envoyer",
    receivingLiquidityProviderToken_stakingDeposit: () =>
      "Jalonnement Dépôt et réception de jetons de réception",
    removeLiquidity_addLiquidity: () => "Supprimer et ajouter de la liquidité",
    returningLiquidityProviderToken_stakingWithdrawal: () =>
      "Retrait de jalonnement et envoi de jeton de réception",
    stakingWithdrawal_stakingDeposit: () => "Jalonnement, retrait et dépôt",
  },
  tradeTypeGroup: {
    acquisition: {
      description: () =>
        "Vous êtes devenu propriétaire d’un actif cryptographique, définissant ainsi le prix de base de l’actif.",
      label: () => "Acquisition",
    },
    derivatives: {
      description: () =>
        "Vous avez perçu des bénéfices en négociant des produits dérivés, par exemple des contrats à terme/sur marge.",
      label: () => "Produits dérivés",
    },
    disposal: {
      description: () =>
        "Vous n'êtes plus propriétaire d'un actif cryptographique, ce qui déclenche un événement CGT.",
      label: () => "Élimination",
    },
    expenses: {
      description: () => "Vous avez utilisé un actif pour payer une dépense.",
      label: () => "Dépenses",
    },
    "fiat-transfer": {
      description: () =>
        "Fiat a été transférée mais la propriété a été conservée.",
      label: () => "Transfert Fiat",
    },
    ignore: {
      description: () =>
        "Ignorer la transaction des calculs de taxes et de solde.",
      label: () => "Ignorer",
    },
    income: {
      description: () =>
        "Vous avez reçu des revenus en crypto-monnaies. Vous pouvez également classer les commissions, etc. dans cette catégorie.",
      label: () => "Revenu",
    },
    loss: {
      description: () =>
        "Vous avez perdu de la crypto-monnaie et souhaitez déduire le coût de revient de vos impôts.",
      label: () => "Perte",
    },
    transfer: {
      description: () =>
        "La crypto a été transférée mais la propriété a été conservée.",
      label: () => "Transfert",
    },
  },
  tradeTypeTaxDescription: {
    addLiquidity: () => "Vous avez ajouté ces pièces dans un pool de liquidité",
    airdrop: () =>
      "Vous avez reçu des jetons « gratuits » dans le cadre d'une promotion ou similaire",
    approval: () => "Vous avez approuvé l'utilisation d'un contrat intelligent",
    "arbitrary-uncategorised": () => "Classer par catégories",
    borrow: () =>
      "Vous avez reçu des crypto-monnaies ou des monnaies fiduciaires en fournissant une garantie",
    "bridge-trade": () =>
      "Vous avez effectué un échange inter-chaînes, impliquant un échange d'une devise contre une autre sur différentes blockchains.",
    "bridge-transfer": () => "Pont d'entrée/sortie groupé automatiquement",
    bridgeIn: () =>
      "Utilisé pour transférer la base de coût d'une blockchain à une autre. Remarque : doit correspondre à une autre transaction classée dans la catégorie « Bridge Out »",
    bridgeOut: () =>
      "Utilisé pour transférer la base de coût d'une blockchain à une autre. Remarque : doit correspondre à une autre transaction classée dans la catégorie « Bridge In »",
    bridgeTradeIn: () =>
      "Vous avez échangé des crypto-monnaies sur une blockchain et les avez envoyées vers une autre blockchain via un pont.",
    bridgeTradeOut: () =>
      "Vous avez reçu des crypto-monnaies sur une blockchain à partir d’une transaction initiée sur une blockchain différente.",
    bridgeTransferIn: () =>
      "Utilisé pour transférer la base de coût d'une blockchain à une autre. Remarque : doit correspondre à une autre transaction classée dans la catégorie « Bridge Out »",
    bridgeTransferOut: () =>
      "Utilisé pour transférer la base de coût d'une blockchain à une autre. Remarque : doit correspondre à une autre transaction classée dans la catégorie « Bridge In »",
    burn: () =>
      "Vous avez envoyé vos fonds à une adresse de courrier indésirable.",
    buy: () => "Événement non imposable",
    cashback: () =>
      "J'ai reçu une remise sur une transaction, comme une réduction de frais ou une remise en argent sur un paiement par carte de crédit, etc.",
    chainSplit: () =>
      "Vous avez reçu des crypto-monnaies à la suite d'un hard fork",
    collateral: () =>
      "Vous avez mis ces pièces de côté en garantie d'un prêt. Cela équivaut à un retrait de votre compte",
    collateralWithdrawal: () =>
      "Vous avez retiré ces pièces d'une plateforme d'emprunt/prêt. Cela agit de manière similaire à un dépôt sur votre compte",
    "decrease-position": () =>
      "Utilisé pour fermer ou réduire une position existante dans un protocole. Utilisé pour les produits dérivés ou le jalonnement.",
    decreasePositionDeposit: () =>
      "Vous avez fermé ou réduit une position et reçu des retours sur votre compte.",
    deposit: () => "Événement non imposable",
    expense: () =>
      "Cela agit de manière similaire à une vente, mais vous souhaitez l'étiqueter comme une dépense.",
    fee: () => "Vous avez eu une dépense diverse",
    "fiat-transfer": () =>
      "Fiat a été transférée mais la propriété a été conservée.",
    fiatDeposit: () =>
      "Vous avez transféré de la monnaie fiduciaire sur une bourse à partir de votre compte bancaire",
    fiatWithdrawal: () =>
      "Vous avez encaissé de votre échange, espérons-le, plus que ce que vous avez investi",
    gift: () =>
      "Vous avez reçu un cadeau d'un tiers, par exemple d'un membre de votre famille",
    in: () => "Transaction non catégorisée",
    income: () =>
      "Vous avez reçu des revenus en crypto-monnaies. Vous pouvez également classer les commissions, etc. dans cette catégorie",
    "increase-position": () =>
      "Utilisé pour ouvrir ou augmenter une position existante dans un protocole. Utilisé pour les produits dérivés ou le jalonnement.",
    increasePositionWithdrawal: () =>
      "Vous avez ouvert ou augmenté une position en investissant des crypto-monnaies depuis votre compte.",
    interest: () =>
      "Vous avez reçu des intérêts pour le prêt de votre crypto-monnaie",
    liquidate: () =>
      "Vous faisiez du trading avec un effet de levier et vous avez reçu un appel de marge",
    loanRepayment: () => "Vous avez remboursé la dette d'un prêt",
    lost: () =>
      "Vous avez perdu de la crypto-monnaie et souhaitez déduire le coût de revient de vos impôts.",
    marginFee: () => "Vous avez effectué un paiement d'intérêts sur un prêt",
    mint: () =>
      "Cela agit de manière similaire à un « achat » et un cas d'utilisation courant est lorsqu'un utilisateur « crée » des NFT",
    "multi-mint": () =>
      "Cela agit de manière similaire à un « achat » et est couramment utilisé pour « créer » des NFT",
    "multi-trade": () => "multi-métier",
    out: () => "Transaction non catégorisée",
    outgoingGift: () =>
      "Vous avez offert un cadeau à un tiers, par exemple à un membre de votre famille",
    personalUse: () =>
      "Vous avez effectué un achat à usage personnel et vous souhaitez le déclarer comme non imposable (attention : vérifiez auprès de votre comptable avant d'utiliser cette option pour vous assurer que vous remplissez les critères)",
    "provide-liquidity-trade": () =>
      "Vous avez ajouté des jetons à un pool de liquidité en échange d'un jeton de réception.",
    realisedLoss: () =>
      "Vous avez subi une perte lors de la négociation de produits dérivés, par exemple des contrats à terme/sur marge",
    realisedProfit: () =>
      "Vous avez réalisé des bénéfices en négociant des produits dérivés, par exemple des contrats à terme/sur marge",
    receivePQ: () =>
      "Vous avez ouvert ou augmenté une position et reçu un jeton de position.",
    receivingLiquidityProviderToken: () =>
      "Vous avez reçu des jetons pour avoir ajouté des pièces dans un pool de liquidité",
    "remove-liquidity-trade": () =>
      "Vous avez restitué votre jeton de liquidité en échange de la récupération de vos fonds du pool de liquidité.",
    removeLiquidity: () => "Vous avez retiré ces pièces d'un pool de liquidité",
    returningLiquidityProviderToken: () =>
      "Vous avez renvoyé des jetons pour avoir retiré des pièces d'un pool de liquidités",
    royalty: () =>
      "Vous avez reçu des revenus provenant de ventes secondaires, par exemple en tant que créateur de NFT",
    sell: () => "Déclenche un événement fiscal sur les gains en capital",
    sendPQ: () =>
      "Vous avez fermé ou réduit une position en renvoyant le jeton de position.",
    staking: () => "Vous avez gagné des intérêts grâce au jalonnement",
    stakingDeposit: () =>
      "Vous avez déposé ces pièces dans un pool de jalonnement. Cela agit de manière similaire à un retrait",
    stakingWithdrawal: () =>
      "Vous avez retiré ces pièces du pool de jalonnement. Cela agit de manière similaire à un dépôt",
    stolen: () =>
      "Votre crypto-monnaie a été volée et vous souhaitez déduire le prix de revient de votre achat à titre de déduction fiscale.",
    swap: () => "Échange groupé automatique en entrée/sortie",
    trade: () => "Achat/vente groupés automatiquement",
    transfer: () => "Retrait/dépôt groupé automatiquement",
    withdrawal: () => "Événement non imposable",
  },
  tradeTypeTaxDescriptionExpanded: {
    addLiquidity: () => "Vous avez contribué en crypto à un pool de liquidité.",
    airdrop: () =>
      "Vous avez reçu une distribution gratuite de crypto directement dans votre portefeuille.",
    approval: () =>
      "Vous avez accordé des autorisations dans les protocoles DeFi, impliquant souvent certains coûts.",
    "arbitrary-uncategorised": () => "Classer par catégories",
    borrow: () =>
      "Vous avez emprunté des crypto-monnaies auprès d’une plateforme, généralement en déposant une garantie.",
    "bridge-trade": () =>
      "Vous avez effectué un échange inter-chaînes, impliquant un échange d'une devise contre une autre sur différentes blockchains.",
    "bridge-transfer": () =>
      "Vous avez transféré des crypto-monnaies d’une blockchain à une autre.",
    bridgeIn: () =>
      "Vous avez reçu des crypto-monnaies d’une autre blockchain via un pont.",
    bridgeOut: () =>
      "Vous avez envoyé de la crypto à une autre blockchain via un pont.",
    bridgeTradeIn: () =>
      "Vous avez échangé des crypto-monnaies sur une blockchain et les avez envoyées vers une autre blockchain via un pont.",
    bridgeTradeOut: () =>
      "Vous avez reçu des crypto-monnaies sur une blockchain à partir d’une transaction initiée sur une blockchain différente.",
    bridgeTransferIn: () =>
      "Vous avez reçu des crypto-monnaies d’une autre blockchain via un pont.",
    bridgeTransferOut: () =>
      "Vous avez envoyé de la crypto à une autre blockchain via un pont.",
    burn: () =>
      "Vous avez envoyé des crypto-monnaies à une adresse de destruction, les retirant ainsi de la circulation.",
    buy: () =>
      "Vous avez acheté des crypto-monnaies en utilisant une monnaie fiduciaire ou une autre crypto-monnaie.",
    cashback: () =>
      "Vous avez reçu des remises en crypto pour des achats ou des activités spécifiques.",
    chainSplit: () =>
      "Vous avez obtenu une nouvelle crypto-monnaie en raison d'un fork ou d'une division de la blockchain.",
    collateral: () =>
      "Vous avez utilisé la crypto comme garantie pour un emprunt ou d’autres activités.",
    collateralWithdrawal: () =>
      "Vous avez récupéré la crypto précédemment utilisée comme garantie.",
    "decrease-position": () =>
      "Utilisé pour fermer ou réduire une position existante dans un protocole. Utilisé pour les produits dérivés ou le jalonnement.",
    decreasePositionDeposit: () =>
      "Vous avez fermé ou réduit une position et reçu des retours sur votre compte.",
    deposit: () =>
      "Vous avez reçu des crypto-monnaies d’un autre compte que vous possédez.",
    expense: () =>
      "Vous avez utilisé la crypto pour des services, des frais divers ou liés à votre entreprise.",
    failedOut: () =>
      "Une transaction qui a échoué pendant le traitement ou qui n'a pas été finalisée. Exclue des calculs de taxes et de solde.",
    fee: () => "Vous avez payé des frais de réseau ou de transaction.",
    "fiat-transfer": () =>
      "Fiat a été transférée mais la propriété a été conservée.",
    fiatDeposit: () =>
      "Vous avez déposé de la monnaie fiduciaire sur une bourse ou une plateforme.",
    fiatWithdrawal: () =>
      "Vous avez retiré de la monnaie fiduciaire d’un échange ou d’une plateforme.",
    gift: () => "Vous avez reçu des crypto-monnaies en cadeau d’un tiers.",
    ignoreIn: () =>
      "Une transaction entrante qui est exclue des calculs de taxes et de solde.",
    ignoreOut: () =>
      "Une transaction sortante qui est exclue des calculs de taxes et de solde.",
    in: () => "Une transaction entrante qui n'a pas été catégorisée",
    income: () =>
      "Vous avez reçu des revenus cryptographiques provenant de diverses activités.",
    "increase-position": () =>
      "Utilisé pour ouvrir ou augmenter une position existante dans un protocole. Utilisé pour les produits dérivés ou le jalonnement.",
    increasePositionWithdrawal: () =>
      "Vous avez ouvert ou augmenté une position en investissant des crypto-monnaies depuis votre compte.",
    interest: () =>
      "Vous avez gagné des intérêts en prêtant ou en conservant votre crypto.",
    liquidate: () =>
      "Vous avez vendu des crypto-monnaies, souvent à perte, pour couvrir des dettes ou des appels de marge.",
    loanRepayment: () =>
      "Vous avez remboursé la crypto empruntée à une plateforme.",
    lost: () =>
      "Vous avez perdu des crypto-monnaies en raison d'erreurs, de clés oubliées ou d'autres raisons.",
    marginFee: () =>
      "Vous avez encouru des frais liés à des opérations sur marge ou à effet de levier.",
    mining: () =>
      "Vous avez gagné des crypto-monnaies en validant des transactions ou en effectuant des tâches sur une blockchain.",
    mint: () =>
      "Vous avez créé de nouvelles cryptos ou NFT, généralement réalisées sur des plateformes décentralisées.",
    "multi-mint": () =>
      "Vous avez créé de nouvelles cryptos ou NFT, généralement réalisées sur des plateformes décentralisées.",
    "multi-trade": () =>
      "Vous avez échangé deux actifs (par exemple crypto/fiat ou crypto/crypto).",
    out: () => "Une transaction sortante qui n’a pas été catégorisée.",
    outgoingGift: () =>
      "Vous avez offert des crypto-monnaies à un tiers, comme un membre de votre famille.",
    personalUse: () =>
      "Vous avez utilisé la crypto pour des dépenses ou des achats personnels.",
    "provide-liquidity-trade": () =>
      "Vous avez ajouté de la crypto à un pool de liquidité et avez reçu un jeton de réception en retour.",
    realisedLoss: () =>
      "Vous avez subi une perte suite à la clôture d’un contrat financier ou dérivé.",
    realisedProfit: () =>
      "Vous avez réalisé un bénéfice en concluant un contrat financier ou dérivé.",
    receivePQ: () =>
      "Vous avez ouvert ou augmenté une position et reçu un jeton de position.",
    receivingLiquidityProviderToken: () =>
      "Vous avez reçu des jetons en récompense pour avoir fourni des liquidités à un pool.",
    "remove-liquidity-trade": () =>
      "Vous avez retourné votre jeton de liquidité pour récupérer la crypto du pool de liquidité.",
    removeLiquidity: () =>
      "Vous avez retiré des crypto-monnaies d’un pool de liquidité.",
    returningLiquidityProviderToken: () =>
      "Vous avez retourné des jetons représentant votre part dans un pool de liquidité.",
    royalty: () =>
      "Vous avez reçu des crypto-monnaies grâce aux redevances provenant de créations basées sur les crypto-monnaies, telles que les NFT.",
    sell: () =>
      "Vous avez vendu votre crypto contre de la monnaie fiduciaire ou une autre crypto.",
    sendPQ: () =>
      "Vous avez fermé ou réduit une position en renvoyant le jeton de position.",
    spamIn: () =>
      "Vous avez reçu des cryptomonnaies sans votre consentement. Il s'agit souvent d'un spam ou d'une escroquerie. Exclu des calculs d'impôts et de solde.",
    "stake-deposit-group": () =>
      "Vous avez déposé un jeton dans un contrat de jalonnement et reçu un jeton de réception représentant votre position.",
    "stake-withdrawal-group": () =>
      "Vous avez retiré des actifs mis en jeu d'un contrat de jalonnement et renvoyé le jeton de réception représentant la position.",
    staking: () =>
      "Vous avez reçu des crypto-monnaies ou des récompenses supplémentaires suite au jalonnement de vos crypto-monnaies.",
    stakingDeposit: () =>
      "Vous avez déposé des crypto-monnaies dans un contrat ou une plateforme de jalonnement.",
    stakingWithdrawal: () =>
      "Vous avez retiré des crypto-monnaies d'un contrat ou d'une plateforme de jalonnement.",
    stolen: () => "Votre crypto a été volée.",
    swap: () =>
      "Vous avez effectué une transaction crypto-monnaie à crypto-monnaie non imposable.",
    swapIn: () =>
      "Vous avez reçu des crypto-monnaies provenant d'une transaction crypto-à-crypto non imposable.",
    swapOut: () =>
      "Vous avez acquis des crypto-monnaies à partir d’un échange crypto-à-crypto non imposable.",
    trade: () =>
      "Vous avez échangé deux actifs (par exemple crypto/fiat ou crypto/crypto).",
    transfer: () =>
      "Vous avez transféré des crypto-monnaies entre vos comptes, en conservant la propriété.",
    withdrawal: () =>
      "Vous avez transféré des crypto-monnaies d’un compte à un autre dont vous étiez propriétaire.",
  },
  transactionEdit: {
    dialog: {
      actionButton: () => "Quitter sans enregistrer",
      body: () =>
        "Toutes les modifications que vous avez apportées seront perdues.",
      cancel: () => "Annuler",
      title: () => "Quitter sans sauvegarder ?",
    },
    somethingWentWrong: () => "Quelque chose s'est mal passé.",
    trade: () => "Modifier le commerce",
    transaction: () => "Modifier la transaction",
    transfer: () => "Modifier le transfert",
  },
  transactionTableModal: {
    defaultTitle: (d) =>
      plural(d.count, 0, fr, {
        one: " 1 Transaction ",
        other: number("fr", d.count, 0) + " Transactions ",
      }),
    viewTxTable: () => "Vue sur la table tx",
  },
  transactions: {
    add: () => "Ajouter",
    deletedTheTxs: () => "Les transactions ont été supprimées.",
    groups: {
      exchanges: () => "Échanges",
      other: () => "Autre",
      wallets: () => "Portefeuilles",
    },
    partiallyDeleted: () =>
      "Une erreur s'est produite lors de la suppression de la transaction. La transaction n'a été supprimée que partiellement.",
    reconcile: (d) => "Rapprocher " + d.count + " éléments",
    title: () => "Transactions",
    ungroup: {
      action: () => "Dissocier",
      body1: () => "Êtes-vous sûr de vouloir dissocier ces transactions ?",
      title: () => "Dissocier les transactions",
    },
    updateTable: () => "Mise à jour du tableau des transactions",
    wentWrongDeleting: () =>
      "Une erreur s'est produite lors de la suppression des transactions",
    wentWrongDeletingComment: () =>
      "Une erreur s'est produite lors de la suppression du commentaire",
    wentWrongDeletingTx: () =>
      "Une erreur s'est produite lors de la suppression de la transaction.",
    wentWrongDuplicatingTx: () =>
      "Une erreur s'est produite lors de la duplication des transactions. Veuillez réessayer.",
    wentWrongFetching: () =>
      "Une erreur s'est produite lors de la récupération du prix du marché. Veuillez réessayer.",
    wentWrongIgnoringWarning: () =>
      "Une erreur s'est produite lors de l'ignorance de l'avertissement.",
    wentWrongLeavingNote: () =>
      "Une erreur s'est produite lors de la rédaction d'une note",
    wentWrongRecategorizing: () =>
      "Une erreur s'est produite lors de la reclassification des transactions. Veuillez réessayer.",
    wentWrongRegrouping: () =>
      "Une erreur s'est produite lors du regroupement des transactions",
    wentWrongUngrouping: () =>
      "Une erreur s'est produite lors du dégroupage des transactions",
    wentWrongUpdateCurrency: () =>
      "Une erreur s'est produite lors de la mise à jour de la devise des transactions. Veuillez réessayer.",
    wentWrongUpdatingTrade: () =>
      "Une erreur s'est produite lors de la mise à jour de la transaction commerciale.",
    wentWrongUpdatingTx: () =>
      "Une erreur s'est produite lors de la mise à jour de la transaction.",
  },
  transferring: () => "Transfert",
  tryAgain: () => "Essayer à nouveau",
  twoFactorAuth: {
    "2FA": () => "2FA",
    authenticationCode: () => "Code d'authentification",
    authy: () => "Authy",
    disableError: () => "Impossible de désactiver la 2FA",
    disableSuccess: () => "2FA désactivé avec succès",
    disableTwoFactorAuth: () => "Désactiver 2FA",
    done: () => "Fait",
    download: () => "Télécharger",
    enableError: () => "Impossible d'activer 2FA",
    enableSuccess: () => "2FA activé avec succès",
    enableTwoFactorAuth: () => "Activer 2FA",
    enterCodeLabel: () => "Entrez le code à 6 chiffres",
    enterCodeOrRecoveryCodeHelperText: () =>
      "Code d'authentification à 6 chiffres ou code de récupération",
    enterCodeSubText: () =>
      "Entrez le code à 6 chiffres de votre application d'authentification",
    enterCodeToChangePassword: () =>
      "Entrez le code à 6 chiffres généré par votre application d'authentification ou l'un des codes de récupération pour modifier votre mot de passe",
    enterCodeToDisable: () =>
      "Saisissez le code à 6 chiffres généré par votre application d'authentification pour désactiver la 2FA",
    enterCodeToRegenerate: () =>
      "Saisissez le code à 6 chiffres généré par votre application d'authentification pour régénérer et afficher vos codes de récupération",
    enterYourPassword: () => "1. Entrez votre mot de passe",
    googleAuthenticator: () => "Google Authenticator",
    manualCodeEntry: () =>
      "Si vous ne pouvez pas scanner le code, copiez le code ci-dessous, saisissez-le dans votre application d'authentification et s'il existe un menu déroulant avec des options, choisissez « Basé sur le temps »",
    or: () => " ou ",
    password: () => "Mot de passe",
    passwordRequired: () => "Le mot de passe est requis",
    qrCodeError: () => "Impossible de générer le code QR",
    qrCodeForTwoFactor: () => "Code QR pour 2FA",
    recoveryCodes: () => "Afficher les codes de récupération",
    recoveryCodesContent: () =>
      "Si vous perdez votre appareil mobile, vous devrez utiliser l'un de ces codes de récupération pour vous connecter à votre compte CTC. Chaque code ne peut être utilisé qu'une seule fois.",
    recoveryCodesInfoBoxContent: () =>
      "Si vous perdez votre dispositif d'authentification et n'avez pas accès à vos codes de récupération, vous perdrez l'accès à votre compte CTC !",
    recoveryCodesInfoBoxTitle: () => "Conservez les codes dans un endroit sûr",
    regenerate: () => "Régénérer",
    regenerateError: () => "Impossible de régénérer les codes de récupération",
    regenerateRecoveryCodes: () => "Régénérer les codes de récupération",
    regenerateSuccess: () => "Codes de récupération régénérés avec succès",
    scanQrCode: () => "2. Scannez ce code QR",
    scanWithApp: () =>
      "Scannez le code QR ci-dessous avec une application comme ",
    staySignedIn: () => "Restez connecté pendant 30 jours",
    submit: () => "Soumettre",
    twoStepVerification: () => "Vérification en deux étapes",
    verify: () => "Vérifier",
    verifyAuthenticationCode: () => "3. Vérifiez le code d'authentification",
    verifyCode: () => "Vérifier le code",
  },
  txLimitExceeded: () => "Limite de Tx dépassée",
  txTable: {
    account: () => "Compte",
    accountBalance: {
      in: () => "Solde entrant",
      out: () => "Solde sortant",
    },
    add: () => "Ajouter une transaction",
    addComment: () => "Ajouter un commentaire",
    addPrice: () => "Ajouter un prix",
    all: () => "Tous",
    allTxs: () => "Toutes vos transactions apparaîtront ici. Pour commencer",
    amount: () => "Montant",
    assignMissingBlockchain: () => "Attribuer une blockchain",
    assumedTransfer: () =>
      "Une partie de cette transaction a été générée automatiquement par notre moteur de suggestions intelligentes",
    balanceRemainingInfo: {
      account: () => "Compte",
      change: () => "Changement",
      currency: () => "Devise",
      endingBalance: () => "Solde final",
      overallBalance: () => "Bilan global",
      startingBalance: () => "Solde de départ",
      tooltips: {
        change: () => "Le changement de solde résultant de cette transaction",
        endingBalance: () =>
          "Le solde détenu sur ce compte suite à cette transaction",
        overallBalance: () =>
          "Le solde détenu sur tous les comptes suite à cette transaction.",
        startingBalance: () =>
          "Le solde détenu sur ce compte avant cette transaction.",
      },
      type: () => "Catégorie",
      viewLedger: () => "Grand livre des devises",
    },
    bulkEditBar: {
      addComment: () => "Ajouter un commentaire...",
      bulkLookupConfirmationText: () =>
        "Êtes-vous sûr de vouloir consulter le prix du marché pour les transactions sélectionnées ? Cette opération remplacera le prix existant de cette transaction et ne pourra pas être annulée.",
      changeCurrency: () => "Changer la devise",
      clearSelection: () => "Effacer la sélection",
      close: () => "Fermer le menu d'édition",
      command: () => "Commande",
      confirm: () => "Confirmer",
      delete: () => "Supprimer",
      deleteConfirmationText: () =>
        "Attention ! Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir supprimer ces transactions ? Nous vous recommandons d'ignorer les transactions dans la mesure du possible.",
      edit: () => "Modifier",
      editAll: () => "Tout modifier",
      groupingWizard: () => "Assistant de regroupement (version bêta)",
      groups: () => "Groupes",
      ignore: () => "Ignorer",
      ignoreConfirmationText: () =>
        "Êtes-vous sûr de vouloir ignorer ces transactions ? Cette action ignorera ces transactions de tous les calculs de taxes. Les transactions ignorées peuvent être masquées dans le tableau des transactions dans les filtres avancés.",
      markAsReviewed: () => "Marquer comme révisé",
      markAsUnreviewed: () => "Marquer comme non révisé",
      numSelected: (d) => d.numSelected + " sélectionné",
      numSelectedAll: (d) =>
        "Toutes les " +
        d.numSelected +
        " transactions de ce filtre sont sélectionnées",
      open: () => "Ouvrir le menu de commande",
      recategorizeError: () =>
        "Une erreur s'est produite lors de la reclassification des transactions. Veuillez réessayer.",
      selectAll: () =>
        "Sélectionnez toutes les transactions qui correspondent à ce filtre",
      selected: () => "Choisi",
      spam: () => "Marquer comme spam",
      spamConfirmationText: () =>
        "Êtes-vous sûr de vouloir marquer ces transactions comme spam ? Cette action ignorera ces transactions de tous les calculs de taxes. Les transactions de spam peuvent être masquées dans le tableau des transactions dans les filtres avancés.",
      txCount: (d) => d.numSelected + " transactions",
    },
    bulkLookupPriceConfirmationTitle: () => "Rechercher le prix du marché",
    commandPallet: {
      acceptedForProcessing: () =>
        "Mise à jour en cours, cela peut prendre quelques minutes...",
      actions: {
        accept: () => "Accepter",
        add: () => "Ajouter",
        confirm: () => "Confirmer",
        goBack: () => "Retourner",
      },
      bulkEditOptionDescriptions: {
        txRewind: () =>
          "Veuillez saisir la date et l'heure auxquelles vous souhaitez annuler les modifications de transaction. Remarque : cette action annulera uniquement les modifications apportées aux transactions entre le moment actuel et l'horodatage sélectionné. Elle ne supprimera pas les transactions nouvellement créées ou importées, ni ne restaurera les transactions supprimées. Seules les modifications visibles dans l'onglet Historique des lignes de transaction concernées seront annulées.",
      },
      bulkEditOptions: {
        addCommentToTransactions: () =>
          "Ajouter un commentaire aux transactions",
        allowGrouping: () => "Autoriser le regroupement",
        categorize: () => "Classer par catégories",
        changeAssetAccount: () => "Changer le compte d'actif",
        changeCashAccount: () => "Changer de compte en espèces",
        changeCurrency: () => "Changer la devise",
        changeFeeCurrency: () => "Changer la devise des frais",
        changeGainsAccount: () => "Compte de gains de changement",
        changeLoanAccount: () => "Changer de compte de prêt",
        changePnLAccount: () => "Changer de compte PnL",
        changePrice: () => "Changer le prix",
        delete: () => "Supprimer",
        destinationAccount: () => "Changer le compte entrant",
        groupingWizard: () => "Assistant de regroupement (version bêta)",
        ignore: () => "Ignorer",
        ignoreWarnings: () => "Ignorer les avertissements",
        lookUpMarketPrice: () => "Rechercher le prix du marché",
        markAsNotReviewed: () => "Sans révision",
        markAsReviewed: () => "Marquer comme révisé",
        markAsSpam: () => "Marquer comme spam",
        sourceAccount: () => "Changer le compte sortant",
        syncToErp: () =>
          "Synchronisation avec l'intégration du logiciel de comptabilité",
        txRewind: () => "Transactions de rembobinage (version bêta)",
        ungroup: () => "Dissocier",
      },
      chips: {
        type: () => "Taper",
      },
      currentlySelected: () => "Actuellement sélectionné",
      groups: () => "Groupes",
      ignoreWarningsOptions: {
        all: () => "Tous",
        missingBlockchain: () => "Blockchain manquante",
        missingPrice: () => "Prix manquant",
        negativeBalance: () => "Historique d'achat manquant",
        uncategorised: () => "Non classé",
        unmatchedTransfer: () => "Transfert inégalé",
      },
      loadingOptions: () => "Options de chargement...",
      noOptions: () => "Aucune option",
      notAvailableOnSelectAll: () => "Non disponible en mode Sélectionner tout",
      numSelected: (d) => d.numSelected + " transactions",
      placeholders: {
        account: () => "Quel compte...",
        category: () => "Quelle catégorie...",
        changeTo: () => "Changer pour...",
        currency: () => "Quelle monnaie...",
        ignoreWarning: () => "Ignorer les avertissements...",
        incomingAccount: () => "Quel compte entrant...",
        outgoingAccount: () => "Quel compte sortant...",
        searchSubType: (d) => "Recherche " + d.subType,
        toCategory: () => "Changer pour...",
        toSourceAccount: () => "Changer pour...",
        tradeType: () => "Quelle catégorie...",
        tradeTypeSearch: {
          group: () => "Rechercher des catégories groupées",
          hint: () => "Astuce ! Cette fonction de contrat est",
          in: () => "Rechercher les catégories entrantes",
          out: () => "Rechercher les catégories sortantes",
          uncategorisedTooltipOverride: (d) =>
            "La catégorisation de cette transaction résoudra " +
            d.value +
            " dans les avertissements de transaction non catégorisés",
        },
      },
      successMessage: {
        addCommentToTransactions: (d) =>
          "Commentaire ajouté sur " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        allowGrouping: (d) =>
          plural(d.count, 0, fr, {
            1: " 1 Transaction ",
            other: number("fr", d.count, 0) + " Transactions ",
          }) + " pouvant être groupées",
        categorize: (d) =>
          "Recatégorisé avec succès " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        changeAssetAccount: (d) =>
          "Compte d'actifs mis à jour le " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        changeCashAccount: (d) =>
          "Compte de caisse mis à jour le " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        changeCurrency: (d) =>
          "La devise a été modifiée avec succès pour " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        changeFeeCurrency: (d) =>
          "La devise des frais a été modifiée le " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        changeGainsAccount: (d) =>
          "Compte de gains mis à jour le " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        changeLoanAccount: (d) =>
          "Compte de prêt mis à jour le " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        changePnLAccount: (d) =>
          "Compte PnL mis à jour le " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        changePrice: (d) =>
          "Mise à jour réussie du prix pour " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        delete: (d) =>
          "Supprimé " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        destinationAccount: (d) =>
          "Compte entrant modifié le " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        ignore: (d) =>
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }) + " ignoré",
        ignoreWarnings: (d) =>
          d.warning +
          " avertissements ignorés sur " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        lookUpMarketPrice: (d) =>
          "J'ai recherché le prix du marché sur " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        markAsNotReviewed: (d) =>
          plural(d.count, 0, fr, {
            1: " 1 Transaction ",
            other: number("fr", d.count, 0) + " Transactions ",
          }) + " marqué comme non révisé",
        markAsReviewed: (d) =>
          plural(d.count, 0, fr, {
            1: " 1 Transaction ",
            other: number("fr", d.count, 0) + " Transactions ",
          }) + " marqué comme examiné",
        markAsSpam: (d) =>
          plural(d.count, 0, fr, {
            1: " 1 Transaction ",
            other: number("fr", d.count, 0) + " Transactions ",
          }) + " marqué comme spam",
        sourceAccount: (d) =>
          "Compte sortant modifié le " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        syncToErp: (d) =>
          "Demande de synchronisation envoyée pour " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        txRewind: (d) =>
          "Rembobiné " +
          plural(d.count, 0, fr, {
            1: " 1 transaction ",
            other: number("fr", d.count, 0) + " transactions ",
          }),
        ungroup: (d) =>
          plural(d.count, 0, fr, {
            1: " 1 Transaction ",
            other: number("fr", d.count, 0) + " Transactions ",
          }) + " non groupé",
      },
      syncToErp: (d) => "Synchroniser avec " + d.erp,
      typeSearchCommand: () => "Tapez une commande ou recherchez...",
      undo: () => "Défaire",
      undoFailed: () => "Impossible d'annuler les modifications",
      undoLoading: () => "Annuler les modifications...",
      undoSuccess: () => "Succès!",
    },
    commentLabels: {
      allComments: () => "Tous les commentaires",
      hasComments: () => "A des commentaires",
    },
    contractAddress: () => "Adresse du contrat",
    cost: () => "Base de coûts",
    counterparty: () => "Contrepartie",
    currency: () => "Devise",
    date: {
      helperFrom: () => "Vous devez saisir une date de début de validité",
      helperTo: () => "Vous devez saisir une date valide",
    },
    deleteBulkTransactionsConfirmationTitle: (d) =>
      "Supprimer " +
      plural(d.txCount, 0, fr, {
        one: " 1 transaction ",
        other: number("fr", d.txCount, 0) + " transactions ",
      }),
    deleteTransactionConfirmationTitle: () => "Supprimer la transaction",
    destination: () => "Destination",
    dialogs: {
      deleteConfirmationText: () =>
        "Attention ! Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir supprimer cette transaction ? Nous vous recommandons d'ignorer la transaction dans la mesure du possible.",
      ignoreTransactionText: () =>
        "Êtes-vous sûr de vouloir ignorer cette transaction ? Cette action ignorera cette transaction de tous les calculs de taxes. Les transactions ignorées peuvent être masquées dans le tableau des transactions dans les filtres avancés.",
      spamTransactionText: () =>
        "Êtes-vous sûr de vouloir marquer cette transaction comme spam ? Cette action ignorera cette transaction de tous les calculs de taxes. Les transactions de spam peuvent être masquées dans le tableau des transactions dans les filtres avancés.",
    },
    entities: {
      explainerTooltip: () =>
        "En autorisant cette entité à agir en tant qu'agent séquestre, elle pourra détenir des fonds et exécuter des cessions en votre nom. Vous souhaiterez peut-être activer cette fonction pour les entités qui représentent des contrats d'enchères, des contrats de moyenne d'achat en dollars ou tout type d'interaction dans laquelle vous déposez des fonds tout en conservant leur propriété, jusqu'à un moment ultérieur où le contrat les aliène en votre nom.",
      falsePositive: () => "N'est plus marqué comme séquestre",
      globalEscrowTooltip: () =>
        "Cette entité a été marquée comme agent séquestre par CTC",
      switchText: () =>
        "Autoriser cette entité à agir en tant qu'agent séquestre",
      truePositive: () => "Marqué comme séquestre",
    },
    expansionPanel: {
      adjPrice: () => "Prix ajusté",
      amount: () => "Montant",
      balanceError: () => "Les soldes sont négatifs",
      chartOfAccounts: {
        accountMappingNotRequired: () => "Aucun mappage de compte requis",
        accountNotFound: (d) =>
          "Le compte qui a été mappé ne peut pas être trouvé dans le plan comptable de " +
          d.erp,
        accountTypeLabel: {
          asset: () => "Actif",
          cash: () => "Espèces",
          gain: () => "Gagner",
          loan: () => "Prêt",
          pl: () => "PnL",
          "zero-cost-buy": () => "ZCB",
        },
        assetAccount: () => "Compte d'actifs",
        cashAccount: () => "Compte de caisse",
        dropdown: () => "Rechercher un compte",
        erpAccounts: (d) => "Comptes " + d.erp,
        from: () => "Depuis",
        gainAccount: () => "Obtenir un compte",
        hiddenAccounts: () =>
          "Nous avons filtré cette liste pour ne pas inclure les comptes bancaires et tout autre compte qui ne peut pas être journalisé.",
        loanAccount: () => "Compte de prêt",
        plAccount: () => "Compte de résultat",
        title: () => "Plan comptable",
        to: () => "À",
        transaction: () => "Transaction",
        uncategorised: () =>
          "Veuillez définir la catégorie de transaction afin d'attribuer des comptes",
        zeroCostBuyAccount: () => "Compte ZCB",
      },
      comments: {
        accountant: () => "Comptable",
        button: () => "Laisser un commentaire",
        client: () => "Client",
        collaborator: () => "Collaborateur",
        comments: (d) =>
          plural(d.count, 0, fr, {
            0: " Commentaires ",
            other: " Commentaires ( " + d.count + " ) ",
          }),
        editError: () =>
          "Une erreur s'est produite lors de la modification du commentaire",
        edited: () => "(édité)",
        editedAt: (d) => "Modifié " + d.time,
        noComments: () => "Les commentaires apparaîtront ici",
        placeholder: () => "Ajouter un commentaire...",
        saveChanges: () => "Enregistrer les modifications",
        you: () => "Toi",
      },
      cost: () => "Base de coûts",
      currency: () => "Devise",
      date: () => "Date",
      delete: {
        body: () =>
          "Une fois le commentaire supprimé, il disparaîtra pour toujours.",
        title: () => "Supprimer le commentaire ?",
      },
      events: () => "Événements associés à cette transaction",
      fee: () => "Frais",
      functionName: (d) => "Fonction de contrat : " + d.functionName,
      functionNameLabel: () => "Fonction de contrat",
      gain: () => "Gagner",
      history: {
        changed: () => "modifié",
        created: () => "créé manuellement",
        currentVersion: () => "Version actuelle",
        deleted: () => "Supprimé",
        false: () => "Non",
        from: () => "depuis",
        headings: {
          actor: () => "Acteur",
          date: () => "Date",
          transaction: () => "Changement",
        },
        imported: () => "importé",
        modalTitle: (d) => "Restaurer les transactions sur " + d.timestamp,
        noChange: () => "Aucun changement",
        noChangesFromCurrentDetected: () => "Aucun changement détecté",
        notRecorded: () => "Non enregistré",
        notSet: () => "Non défini",
        original: () => "Original",
        restore: (d) => "Restaurer à " + d.time,
        restoreOnly: () => "Restaurer",
        thisVersion: () => "Cette version",
        thisWillChange: () => "Cela va changer :",
        title: () => "Historique des modifications",
        to: () => "à",
        transaction: () => "transaction",
        transactionKeys: {
          blockchain: () => "Blockchain",
          currencyIdentifier: () => "Devise",
          description: () => "Description",
          dontGroup: () => "Ne pas regrouper",
          "erp.accountMappings.asset": () => "Remplacement du compte d'actifs",
          "erp.accountMappings.cash": () => "Remplacement du compte de caisse",
          "erp.accountMappings.gain": () => "Obtenir un remplacement de compte",
          "erp.accountMappings.pl": () => "Remplacement du compte PnL",
          fee: () => "Frais",
          feeCurrencyIdentifier: () => "Devise des frais",
          feeQuantity: () => "Quantité de frais",
          from: () => "Depuis",
          functionName: () => "Fonction Tx",
          functionSignature: () => "Signature de transmission",
          price: () => "Prix",
          quantity: () => "Quantité",
          reviewed: () => "Révisé",
          suggestion: () => "Suggestion",
          timestamp: () => "Horodatage",
          to: () => "À",
          trade: () => "Catégorie",
        },
        true: () => "Oui",
        unableToCompare: () => "Impossible de comparer",
        unableToFindPreviousVersion: () =>
          "Impossible de trouver la version précédente",
        undo: () => "Défaire",
        undoLatestChange: () => "Annuler cette modification ?",
        updateReason: {
          import: () => "Oracle des prix CTC",
          migration: () => "Système CTC",
          rule: () => "Service des règles du CTC",
          system: () => "Système CTC",
          transactionMatching: () => "Correspondance des transactions CTC",
          unknown: () => "Inconnu",
          user: () => "Utilisateur",
        },
        updateReasonActorCTCSystem: () => "Système CTC",
        updateReasonActorUnknown: () => "Inconnu",
        updateReasonActorUser: () => "Utilisateur",
      },
      id: () => "IDENTIFIANT:",
      importSource: (d) => "Source d'importation : " + d.sourceDisplayName,
      inflightBalanceError: () =>
        "Un historique d'achat manquant indique que certaines de vos transactions sortantes sont manquantes. Cela peut se produire si vous n'avez pas importé toutes vos données",
      moreInfo: () => "Plus d'infos",
      moreInfoPopover: {
        importSource: () => "Source d'importation :",
        learnAbout: () => "En savoir plus sur",
        title: () => "Informations complémentaires",
      },
      na: () => "N / A",
      notes: (d) => "Description : " + d.notes,
      price: () => "Prix",
      proceeds: () => "Recettes",
      receivedFrom: () => "Reçu de",
      rolloverFrom: (d) => "Roulement à partir de " + d.currency,
      ruleApplied: () => "Règle appliquée",
      sentBy: () => "Envoyé à",
      sourceType: (d) => "Source du prix : " + d.sourceType,
      sourceTypes: {
        ManualUpdate: () => "Mise à jour manuelle",
        PriceFromAction: () => "Prix de l'action",
        PriceWindow: () => "Fenêtre de repli des prix",
      },
      time: () => "Temps",
      total: () => "Total",
      tradedOn: () => "Échangé sur",
      transactionId: (d) =>
        plural(d.count, 0, fr, { one: " Tx ID ", other: " Tx ID " }),
      transactionIdLabel: () => "ID de transmission",
      transactionsTab: () => "Transactions",
      type: () => "Catégorie",
      ungroup: {
        buttonText: () => "Autoriser le regroupement",
        infoBody: () =>
          "Cette transaction a été dissociée manuellement et, par conséquent, ne sera plus considérée par notre algorithme de regroupement comme faisant partie d'une transaction ou d'un transfert.",
        infoTitle: () => "Transaction non groupée",
        title: () => "Non groupé",
      },
      updating: () => "Mise à jour des transactions",
      value: () => "Valeur",
      viewAssociated: () => "Afficher les transactions associées",
      viewBalance: () => "Consulter le grand livre",
      viewRelatedTx: () => "Afficher les transactions associées",
      viewTaxRule: () => "Voir la règle fiscale",
      viewTx: () => "Voir TX",
    },
    exportCSV: {
      filterOptionCaption: () => "les transactions correspondent à ce filtre",
      filterOptionTitle: () => "Transactions dans le filtre",
      pageOptionCaption: () => "transactions actuellement sur la page",
      pageOptionTitle: () => "Transactions sur la page",
      paywall: {
        button: () => "Sélectionnez un plan",
        text: () => "Un plan est nécessaire pour exporter des transactions.",
      },
      pending: {
        captionPt1: () =>
          "Le rapport sera téléchargé automatiquement lorsqu'il sera prêt.",
        captionPt2: () => "Vous pouvez fermer cette fenêtre.",
        title: () => "Génération de rapport",
      },
      submitButton: (d) => "Exporter " + d.count + " transactions",
      success: {
        captionPt1: () => "Si vous ne voyez pas votre fichier, vous pouvez ",
        captionPt2: () => "téléchargez-le à nouveau",
        title: () => "Votre exportation a été téléchargée",
      },
      title: () => "Exporter les transactions au format CSV",
    },
    fee: () => "Frais",
    feeValue: (d) => "Valeur des frais : " + d.amount,
    fiatTradeLabel: {
      deposit: () => "Dépôt",
      withdrawal: () => "Retrait",
    },
    filter: {
      addFilter: () => "Ajouter un filtre",
      advanced: () => "Avancé",
      advancedFilters: () => "Effacer les filtres pour rechercher",
      category: () => "Catégorie",
      clearFilters: () => "Effacer les filtres",
      comment: () => "Commentaires",
      currency: () => "Devise",
      date: () => "Date",
      descriptions: {
        actionId: () => "Rechercher une transaction par son identifiant",
        after: () => "Rechercher des transactions après la date spécifiée",
        and: () =>
          "Rechercher les transactions qui correspondent à TOUS les filtres sélectionnés",
        before: () =>
          "Rechercher des transactions antérieures à la date spécifiée",
        blockchain: () =>
          "Rechercher des transactions par la blockchain sur laquelle elles ont eu lieu",
        category: () => "Rechercher des transactions par catégorie",
        comment: () =>
          "Rechercher des transactions par le contenu de leurs commentaires",
        comments: () =>
          "Rechercher des transactions par le contenu de leurs commentaires",
        currency: () =>
          "Rechercher des transactions par devise en cours de transaction",
        date: () => "Rechercher des transactions à une date précise",
        description: () =>
          "Rechercher des transactions par le contenu de leur description",
        erpAssetAccount: () =>
          "Rechercher des transactions par remplacement du mappage de compte d'actif d'intégration comptable",
        erpCashAccount: () =>
          "Recherche de transactions par remplacement du mappage de compte de caisse d'intégration comptable",
        erpGainsAccount: () =>
          "La recherche de transactions par leur intégration comptable permet de remplacer le mappage de compte",
        erpLoanAccount: () =>
          "Recherche de transactions par remplacement du mappage de compte de prêt d'intégration comptable",
        erpPnlAccount: () =>
          "Recherche de transactions par leur intégration comptable Remplacement du mappage des comptes P&L",
        erpSyncStatus: () =>
          "Rechercher des transactions en fonction de leur statut de synchronisation d'intégration comptable",
        feeCurrency: () =>
          "Rechercher des transactions par devise dans laquelle les frais ont été payés",
        from: () => "Rechercher des transactions par leur compte « de »",
        hasRule: () => "Masquer les transactions non affectées par les règles",
        id: () => "Rechercher une transaction par son identifiant",
        importId: () =>
          "Rechercher des transactions en fonction de la synchronisation sur laquelle elles ont été importées",
        importType: () =>
          "Rechercher des transactions par type d'importation par lesquelles elles ont été effectuées",
        isSmartContractInteraction: () =>
          "Rechercher des transactions avec des contrats intelligents",
        ledger: () =>
          "Rechercher des transactions relatives à la vue du grand livre actuel",
        missingPrice: () => "Rechercher des transactions par",
        negativeBalance: () => "Rechercher des transactions par",
        nftCollection: () =>
          "Rechercher des transactions de trading NFT d'une collection spécifique",
        not: () =>
          "Rechercher des transactions qui ne correspondent PAS au filtre sélectionné",
        or: () =>
          "Rechercher des transactions qui correspondent à CERTAINS des filtres sélectionnés",
        party: () =>
          "Rechercher des transactions par les comptes traités entre",
        reconciliation: () =>
          "Rechercher des transactions relatives à un conseil",
        reviewed: () => "Rechercher des transactions examinées",
        rule: () =>
          "Rechercher des transactions manipulées par une règle spécifique",
        ruleOperator: () =>
          "Rechercher des transactions manipulées par un opérateur de règle spécifique",
        source: () =>
          "Rechercher des transactions par les comptes traités entre",
        suggestion: () =>
          "Recherchez des transactions classées par notre moteur de suggestions intelligentes",
        sync: () =>
          "Rechercher des transactions en fonction de la synchronisation sur laquelle elles ont été importées",
        taxOutcomeType: () =>
          "Rechercher des transactions par leur résultat fiscal",
        to: () => "Rechercher des transactions par leur compte « à »",
        trade: () => "Rechercher des transactions par type",
        txCurrency: () =>
          "Rechercher des transactions par devises qui ont été traitées",
        txFunctionName: () =>
          "Recherche de transactions par la fonction de contrat",
        txFunctionSignature: () =>
          "Rechercher des transactions par l'ID de méthode",
        txId: () => "Recherche de transactions par hachage de blockchain",
        warning: () =>
          "Rechercher des transactions en fonction des avertissements qu'elles contiennent",
      },
      erpSyncStatus: {
        NOT_READY_TO_SYNC: () => "Pas prêt à synchroniser",
        SYNC_FAILED: () => "Échoué",
        SYNC_IN_PROGRESS: () => "En cours",
        SYNC_NOT_REQUESTED: () => "Prêt à synchroniser",
        SYNC_NO_OP: () => "Pas d'opération",
        SYNC_OUT_OF_DATE: () => "Obsolète",
        SYNC_SUCCESSFUL: () => "Réussi",
      },
      filter: () => "Filtre",
      filterBuilderOperatorOverrides: {
        category: () => "Catégorie",
      },
      filters: () => "Filtres",
      hasRule: () => "Masquer les transactions non affectées par les règles",
      hideIgnored: () => "Masquer les transactions ignorées/spam",
      hideLockedTransactions: () => "Masquer les transactions verrouillées",
      hideNonSuggested: () =>
        "Afficher uniquement les transactions classées par catégorie intelligente",
      hideReviewed: () => "Masquer les transactions examinées",
      importSource: () => "Source d'importation",
      importType: () => "Type d'importation",
      imports: () => "Importations",
      includesFee: {
        doesNotIncludesFee: () => "Pas de frais",
        includesFee: () => "A des frais",
      },
      isSmartContractInteraction: {
        smartContract: () => "Contrat intelligent",
        wallet: () => "Portefeuille",
      },
      lockPeriodTooltip: () => "Ajoutez une période verrouillée pour activer",
      manual: () => "Manuel",
      moreFilters: () => "Plus de filtres",
      negation: {
        is: () => "Est",
        isNot: () => "N'est pas",
      },
      noOptions: () => "Aucune option",
      noResults: () => "Aucun résultat",
      noWarnings: () => "Vous n'avez aucun avertissement",
      operators: {
        actionId: () => "ID d'action",
        after: () => "Après",
        and: () => "Et",
        before: () => "Avant",
        blockchain: () => "Blockchain",
        category: () => "Catégorie",
        comment: () => "Commentaire",
        comments: () => "Commentaires",
        currency: () => "Devise",
        date: () => "Date",
        description: () => "Description",
        erpAssetAccount: () => "Compte d'actifs",
        erpCashAccount: () => "Compte de caisse",
        erpGainsAccount: () => "Obtenir un compte",
        erpLoanAccount: () => "Compte de prêt",
        erpPnlAccount: () => "Compte de résultat",
        erpSyncStatus: () =>
          "État de synchronisation de l'intégration comptable",
        feeCurrency: () => "Devise des frais",
        from: () => "Depuis",
        hasRule: () => "Masquer les transactions non affectées par les règles",
        id: () => "IDENTIFIANT",
        importId: () => "ID d'importation",
        importType: () => "Type d'importation",
        includesFee: () => "Frais",
        integrations: () => "Intégration",
        isSmartContractInteraction: () => "Interaction",
        ledger: () => "Afficher les soldes",
        lockPeriod: () => "Période de blocage",
        missingPrice: () => "Prix manquant",
        negativeBalance: () => "Historique d'achat manquant",
        nftCollection: () => "Collection NFT",
        not: () => "Pas",
        or: () => "Ou",
        party: () => "Faire la fête",
        reconciliation: () => "Réconciliation",
        reviewed: () => "Révisé",
        rule: () => "Règle",
        ruleOperator: () => "Opérateur de règle",
        source: () => "Compte",
        suggestion: () => "Suggestion",
        sync: () => "Synchroniser",
        taxOutcomeType: () => "Résultat fiscal",
        to: () => "À",
        trade: () => "Catégorie",
        txCurrency: () => "Devise du Texas",
        txFunctionName: () => "Signature de transmission",
        txFunctionSignature: () => "ID de la méthode Tx",
        txId: () => "ID de transmission",
        warning: () => "Avertissement",
      },
      otherFilter: () => "Voir",
      placeholder: () => "Trouver des transactions",
      present: () => "Présent",
      resetFilters: () => "Réinitialiser les filtres",
      search: () => "Opérateurs de filtre de recherche",
      selectAll: () => "Sélectionner tout",
      settingsFilter: () => "Tableau",
      showIgnored: () => "Afficher les transactions ignorées/spam",
      sort: () => "Trier",
      source: () => "Source",
      table: () => "Soldes",
      taxOutcomeType: {
        income: () => "Revenu",
        longTermCapitalGain: () => "Gain en capital à long terme",
        longTermCapitalLoss: () => "Perte de capital à long terme",
        shortTermCapitalGain: () => "Gain en capital à court terme",
        shortTermCapitalLoss: () => "Perte de capital à court terme",
      },
      textMatching: {
        is: () => "Contient",
        isNot: () => "Ne contient pas",
      },
      transactionId: () => "Entrez l'ID de transaction",
      trySearchingFor: () => "Essayez de rechercher...",
      txFunctionName: () => "Signature de transmission",
      txFunctionSignature: () => "ID de la méthode Tx",
      txId: () => "ID de transmission",
      txSignature: () => "Signature",
      typeToSearch: () => "Tapez pour rechercher",
      warningLabel: (d) => d.label + " ( " + d.number + " )",
      warnings: () => "Avertissements",
    },
    filteredCount: (d) =>
      d.totalFilteredCount + " de " + d.totalCount + " transactions",
    from: () => "Sortant",
    gain: () => "Gagner",
    generateReport: () => "Générer un rapport",
    id: () => "IDENTIFIANT",
    identifyAddress: () => "Identifier l'adresse",
    ignoreBulkTransactionsConfirmationTitle: () => "Ignorer les transactions",
    ignoreTransactionConfirmationTitle: () => "Ignorer la transaction",
    ignored: () => "Ignoré",
    ignoredWarningLabels: {
      missingBlockchain: () => "Blockchain manquante (ignorée)",
      negativeBalance: () => "Historique d'achat manquant (ignoré)",
    },
    importData: () => "importer des données",
    importFilterOther: () => "Autre",
    importMoreData: () => "Importer plus de données",
    importType: {
      api: () => "API",
      "assumed-fee": () => "Frais supposés",
      "bulk-wallet": () => "Importation en masse",
      csv: () => "CSV",
      manual: () => "Manuel",
      "manual-csv": () => "Manuel CSV",
      oauth: () => "OAuth",
      "soft-transfer": () => "Suggestion intelligente",
      "soft-wallet": () => "Portefeuille souple",
      wallet: () => "Portefeuille",
      "wallet-api": () => "API de portefeuille",
      "wallet-csv": () => "Portefeuille CSV",
    },
    invalidBulkEditTooltip: () =>
      "Impossible de modifier en masse les transactions groupées",
    invalidCategoryTradeUncategorised: (d) =>
      "Améliorez la précision en catégorisant cette transaction. Elle utilise actuellement la catégorie par défaut " +
      d.tradeType +
      " , ce qui peut déclencher un événement de gain en capital.",
    labels: () => "Étiquettes",
    missingBlockchainDescription: () =>
      "Cela définira la blockchain d'où provient cette adresse.",
    moreThan: () => "Plus que",
    noComments: () => "Sans commentaires",
    noData: () => "Aucune donnée ajoutée",
    noTxFound: () => "Aucune transaction trouvée",
    noTxMatching: () =>
      "Aucune transaction correspondant au filtre n'a été trouvée. Veuillez essayer un autre filtre.",
    noTxMatchingWithReportRefreshRunning: () =>
      "Nous mettons actuellement à jour vos transactions. D'autres transactions pourront être trouvées une fois cette opération terminée.",
    none: () => "Aucun",
    of: () => "de",
    openInNewTab: () => "Ouvrir dans un nouvel onglet",
    overallBalance: () => "Bilan global",
    pagination: {
      pageXofY: (d) => d.pageNum + " sur " + d.totalPages,
    },
    plusMore: (d) => "+ " + d.count + " de plus",
    price: () => "Prix",
    quantity: () => "Quantité",
    recentComments: () => "Commentaires récents",
    recentCommentsMore: (d) => "+ " + d.count + " de plus",
    remaining: () => "Restant",
    rowsPerPage: () => "Lignes par page :",
    savedAddresses: {
      addError: () =>
        "Une erreur s'est produite lors de l'enregistrement de l'adresse de la blockchain",
      body: () =>
        "Cela renommera l'adresse partout où il y a une transaction vers et depuis cette adresse.",
      deleteError: () =>
        "Une erreur s'est produite lors de la suppression de l'adresse de blockchain enregistrée",
      importWallet: () => "Importer un portefeuille",
      lengthError: () => "Le nom ne peut pas dépasser 64 caractères",
      nameAddress: () => "Nom adresse",
      placeholder: () => "Surnom",
      title: () => "Nom adresse",
      updateError: () =>
        "Une erreur s'est produite lors de la mise à jour de l'adresse de blockchain enregistrée",
    },
    search: () => "Recherche",
    select: () => "Sélectionner",
    showing: () => "Affichage : ",
    similarTxs: () => "Tx similaires",
    smartSuggestion: () =>
      "Cette transaction a été classée automatiquement par notre moteur de suggestions intelligentes",
    sort: {
      feeAscending: () => "Frais - du plus bas au plus élevé",
      feeDescending: () => "Frais - du plus élevé au plus bas",
      gainAscending: () => "Gain - Du plus faible au plus élevé",
      gainDescending: () => "Gain - Du plus élevé au plus bas",
      lastModifiedAscending: () => "Trier par première modification",
      lastModifiedDescending: () => "Trier par dernière modification",
      newestFirst: () => "Le plus récent",
      oldestFirst: () => "Le plus ancien",
      uncategorisedSizeAscending: () => "non classéTailleAscendante",
      uncategorisedSizeDescending: () => "non classéTailleDécroissante",
      uncategorisedValueAscending: () => "non classéValeurAscendant",
      uncategorisedValueDescending: () => "non classéValeurDécroissante",
      valueAscending: () => "Valeur - du plus bas au plus élevé",
      valueDescending: () => "Valeur - du plus élevé au plus bas",
    },
    source: () => "Source",
    sourceFilter: {
      exchanges: () => "Échanges",
      other: () => "Autre",
      wallets: () => "Portefeuilles",
    },
    spamBulkTransactionsConfirmationTitle: () =>
      "Marquer les transactions comme spam",
    spamTransactionConfirmationTitle: () => "Marquer la transaction comme spam",
    time: () => "Date",
    title: () => "Transactions",
    to: () => "Entrant",
    tooltipGain: (d) => "Gain total de " + d.totalGain,
    tooltipLoss: (d) => "Perte totale de " + d.totalLoss,
    total: () => "Total",
    totalFiltered: () => "Nombre total de transactions filtrées",
    totalTxCount: (d) => d.totalCount + " transactions",
    totalTxs: () => "Nombre total de transactions",
    trade: () => "Catégorie",
    transaction: () => "Transaction",
    transactionId: () => "ID de transmission",
    transactions: () => "Transactions: ",
    txRow: {
      balanceRemaining: (d) => "Solde restant ( " + d.displayName + " ):",
      balanceRemainingOverall: () => "Solde restant (global) :",
      cost: () => "Coût",
      erp: {
        categoriseToSync: () =>
          "Catégoriser cette transaction pour la synchroniser",
        mapAllAccounts: () => "Complétez tous les mappages de comptes",
        provideDefaultAccountMappings: () =>
          "Fournir des mappages de compte par défaut",
        reportFailed: () =>
          "Impossible de synchroniser les transactions lorsque les calculs sont en état d'échec",
        resolveAllWarnings: () =>
          "Résoudre tous les avertissements de transaction",
        status: {
          NOT_READY_TO_SYNC: (d) =>
            "Pas prêt à être synchronisé avec " + d.erpProvider,
          SYNC_FAILED: (d) =>
            "Échec de la synchronisation avec " + d.erpProvider,
          SYNC_IN_PROGRESS: (d) =>
            "Synchronisation avec " + d.erpProvider + " en cours",
          SYNC_NOT_REQUESTED: (d) => "Synchroniser avec " + d.erpProvider,
          SYNC_NO_OP: (d) =>
            "Aucun journal à synchroniser avec " + d.erpProvider,
          SYNC_OUT_OF_DATE: (d) => "Resynchroniser avec " + d.erpProvider,
          SYNC_SUCCESSFUL: (d) => "Synchronisé avec " + d.erpProvider,
        },
        viewJournal: () => "voir le journal",
        viewPreviousJournal: () => "voir le journal précédent",
        waitForCalculationsToFinish: () =>
          "Veuillez attendre que vos calculs soient terminés",
      },
      feeValue: () => "Valeur des frais de",
      lockedRowTooltip: {
        action: () => "Afficher les paramètres de transaction de verrouillage",
        text: () =>
          "Vous ne pouvez pas déverrouiller les transactions individuelles",
      },
      moreOptions: {
        generateMatchingTransfer: () => "Générer une correspondance",
      },
      noFees: () => "Aucun frais payé",
      overflowCurrencies: (d) => "+ " + d.count + " de plus...",
      prefix: {
        exchange: () => "Échange",
        from: () => "Source",
        fromTo: () => "Source/Destination",
        to: () => "Destination",
      },
      similarCount: (d) =>
        plural(d.count, 0, fr, {
          one: " 1 tx ",
          other: number("fr", d.count, 0) + " txs ",
        }),
      similarValue: (d) => d.symbol + d.value + " total",
      singleSideLabels: {
        from: () => "Source:",
        to: () => "Destination:",
      },
      smartContract: () => "Contrat intelligent",
      tooltip: () =>
        "Heure de la transaction (dans votre fuseau horaire local)",
      totalTransactionValue: () => "Valeur totale de la transaction",
    },
    type: () => "Catégorie",
    typeUpdateWarningModal: {
      transferSmartContract: {
        change: () => "Changer de catégorie",
        contract: () => "Contracter",
        ignore: () => "Ignorer",
        receiveFromAction: () =>
          "Veuillez sélectionner une catégorie plus descriptive pour classer correctement les transactions avec des contrats intelligents.",
        receiveFromInfo: () =>
          "Cette transaction s'effectue via un contrat intelligent. La catégorie « Recevoir » ne doit être utilisée que lors de la réception d'un autre portefeuille que vous possédez.",
        receiveFromTitle: () => "Réception d'un contrat intelligent",
        sendToAction: () =>
          "Veuillez sélectionner une catégorie plus descriptive pour classer correctement les transactions avec des contrats intelligents.",
        sendToInfo: () =>
          "Cette transaction s'effectue via un contrat intelligent. La catégorie « Envoyer » ne doit être utilisée que lors de l'envoi vers votre propre portefeuille.",
        sendToTitle: () => "Envoi vers un contrat intelligent",
      },
    },
    uncategorised: () => "Non classé",
    unknownAccountWarning: {
      CTA: (d) =>
        "Si le compte avec lequel vous êtes " +
        select(d.side, {
          from: " receiving from ",
          to: " send to ",
          other: " interagir ",
        }) +
        " vous appartient, [importez-le]( " +
        d.linkTextKey +
        " ). La transaction sera alors automatiquement classée correctement comme un « transfert ».",
      body: (d) =>
        "L'adresse " +
        select(d.side, {
          from: " sending ",
          to: " receiving ",
          other: " unknown ",
        }) +
        " pour cette transaction n'a pas été fournie par " +
        select(d.isBlockchain, {
          true: " blockchain ",
          false: " exchange ",
          other: " account ",
        }) +
        ".",
      subTitle: (d) =>
        "S'agit-il d'un transfert " +
        select(d.side, {
          from: " from another ",
          to: " vers un autre ",
          other: " avec un compte crypto inconnu ",
        }) +
        " que vous utilisez ?",
      title: (d) =>
        select(d.side, {
          from: " Transaction entrante d'un expéditeur inconnu ",
          to: " Transaction sortante vers un destinataire inconnu ",
          other: " Transaction avec une partie inconnue ",
        }),
      tooltip: (d) =>
        select(d.side, {
          from: " Expéditeur inconnu ",
          to: " Destinataire inconnu ",
          other: " Partie inconnue ",
        }),
    },
    updatePrice: () => "Prix de mise à jour",
    updateValue: () => "Mettre à jour la valeur",
    value: () => "Valeur",
    viewAddress: () => "Voir les détails de l'adresse",
    viewAssociatedTransactionsModal: {
      exchange: () => "Échange",
      import: (d) => "Si c'est votre " + d.importType,
      smartContract: () => "Contrat intelligent",
      transactions: () => "Transactions:",
      type: () => "Taper:",
      wallet: () => "Portefeuille",
    },
    viewComments: () => "Voir les commentaires",
    viewLedger: () => "Grand livre des devises",
    viewTx: () => "Afficher les détails de la transaction",
    warningLabels: {
      all: () => "Tous",
      missingBlockchain: () => "Blockchain manquante",
      missingPrice: () => "Prix du marché manquant",
      negativeBalance: () => "Historique d'achat manquant",
      uncategorised: () => "Nécessite une catégorisation",
      unknownInOut: () => "Entrée/Sortie inconnue",
      unmatchedTransfer: () => "Transfert inégalé",
      zeroCostBuy: () => "Achat sans frais",
    },
    warnings: {
      bridgeTransferIn: () =>
        "Il s'agit d'un pont unilatéral - veuillez faire correspondre cette transaction avec l'autre côté du pont.",
      bridgeTransferOut: () =>
        "Il s'agit d'un pont unilatéral - veuillez faire correspondre cette transaction avec l'autre côté du pont.",
      bridgein: () =>
        "Il s'agit d'un pont unilatéral - veuillez faire correspondre cette transaction avec l'autre côté du pont.",
      bridgeout: () =>
        "Il s'agit d'un pont unilatéral - veuillez faire correspondre cette transaction avec l'autre côté du pont.",
      receive: () =>
        "Cette réception provient d'un portefeuille qui n'a pas été importé ou il manque des données pour le côté envoi de la transaction. \n\n Si cette transaction provient d’un tiers, sélectionnez une autre catégorie appropriée. \n\n Si cette réception manque du côté envoi, vous pouvez rapprocher les transferts non appariés sur la page de rapprochement.",
      send: () =>
        "Cet envoi est destiné à un portefeuille qui n'a pas été importé ou il manque des données pour le côté réception de la transaction. \n\n Si cette transaction est effectuée auprès d’un tiers, sélectionnez une autre catégorie appropriée. \n\n Si cet envoi manque du côté réception, vous pouvez rapprocher les transferts non appariés sur la page de rapprochement.",
      uncategorized: (d) =>
        'Suivez nos <a href="' +
        d.link +
        '" target="_blank"> conseils </a> pour fournir plus de contexte sur cette transaction',
      uncategorizedReview: () =>
        "Catégorisez cette transaction pour améliorer la précision du rapport",
    },
  },
  uncategorisedModal: {
    actionTooltip: () =>
      "Les transactions du groupe doivent avoir une catégorie",
    cancel: () => "Annuler",
    moreOptions: () => "Plus d'options",
    save: () => "Sauvegarder",
    subTitle: () => "Transactions dans le groupe :",
    title: () => "Catégoriser le groupe",
  },
  uncategorisedToggles: () => "Traitement des transactions non catégorisées",
  unknownInOut: {
    url: () =>
      "https://help.cryptotaxcalculator.io/en/articles/5279619-categorise-transactions",
  },
  update: () => "Mettre à jour",
  userArchive: {
    failedToRestore: () => "Impossible de restaurer les données archivées",
    restoreData: () => "Restaurez vos données",
    title: () =>
      "Bon retour ! Cela fait un moment que vous ne vous êtes pas connecté, nous avons donc archivé vos données pour les conserver en sécurité. Cliquez sur restaurer les données ci-dessous pour continuer la réconciliation",
  },
  viewAssociatedTransactions: () => "Afficher les transactions associées",
  wallet: {
    address: (d) => "Entrez l'adresse du portefeuille " + d.source,
    addresses: () => "Adresses de portefeuille",
    bulk: () => "Collez vos adresses publiques ici",
    bulkError: () => "Impossible d'analyser les adresses de portefeuille",
    bulkHelperText: () =>
      "Collez les adresses de votre portefeuille exactement comme elles ont été exportées depuis l'application de portefeuille Coinbase",
    deleteConfirmationText: (d) =>
      "Êtes-vous sûr de vouloir supprimer le portefeuille " +
      d.wallet +
      " « " +
      d.walletAddress +
      " » ? Cette opération supprimera également TOUTES les transactions associées à ce portefeuille. Cette opération ne peut pas être annulée.",
    editDetails: () => "Modifier les détails du portefeuille",
    editingSubmit: () => "Mettre à jour le nom du portefeuille",
    equivWallet: (d) =>
      "Ce portefeuille utilise la blockchain " +
      d.name +
      " pour la synchronisation. Une fois synchronisé, vous pouvez voir vos portefeuilles connectés dans le panneau « " +
      d.name +
      " » de cette page.",
    existingSubmit: (d) =>
      "Ajouter un portefeuille " + d.source + " supplémentaire",
    hardSync: {
      beginningOfTime: () => "le début des temps",
      confirmationOneWallet: () => "Portefeuille à synchronisation dure ?",
      lastLockedPeriod: () =>
        "(la date de fin de votre période de verrouillage la plus récente)",
      syncedFromDate: () =>
        "(la date à laquelle ce portefeuille est synchronisé)",
      textOneWallet: (d) =>
        "La synchronisation matérielle importera toutes les transactions du portefeuille " +
        d.name +
        " à partir du " +
        d.date +
        " . Cela peut parfois entraîner des transactions en double qui doivent être gérées manuellement, dans le cas où l'intégration a changé depuis votre dernière synchronisation.",
      titleOneWallet: (d) =>
        "Resynchroniser toutes les transactions du portefeuille " +
        d.name +
        " à partir du " +
        d.date,
    },
    hardSyncWarning: (d) =>
      "La synchronisation matérielle des portefeuilles " +
      d.name +
      " créés avant le 5 mai 2022 entraînera des transactions en double en raison de modifications récentes. Nous conseillons aux utilisateurs qui ont créé des portefeuilles " +
      d.name +
      " avant cette date de supprimer leur portefeuille et de le réimporter, ou d'éviter de synchroniser matériellement leurs portefeuilles.",
    importRelatedWallets: {
      actionTitle: () => "Analyser les chaînes EVM",
      actionTooltip: () =>
        "Recherchez les transactions associées à cette adresse de portefeuille sur toutes les chaînes compatibles EVM.",
      noResults: () => "Aucune activité connexe trouvée.",
      syncError: () =>
        "Erreur lors de la récupération des portefeuilles associés",
      syncSuccess: () =>
        "Des transactions ont été détectées sur plusieurs blockchains. Celles-ci seront désormais importées.",
      title: () => "Analyser les chaînes EVM",
      tooltipOff: () =>
        "Actuellement, les transactions sur les chaînes associées ne seront pas importées",
      tooltipOn: () =>
        "Actuellement, les transactions sur les chaînes associées seront importées",
    },
    importRules: {
      addressAlreadyExistsError: () =>
        "Cette adresse de portefeuille a déjà été importée.",
      bulkEmpty: (d) =>
        "Vous devez saisir votre liste d'adresses publiques " + d.name + " .",
      bulkExisting: (d) =>
        "L'une des adresses " +
        d.name +
        " que vous avez saisies est déjà ajoutée.",
      bulkInvalid: (d) =>
        "L'une des adresses que vous avez saisies n'est pas une adresse de portefeuille " +
        d.name +
        " valide.",
      empty: (d) =>
        "Vous devez saisir l'adresse de votre portefeuille public " +
        d.name +
        " .",
      existing: (d) =>
        "L'adresse du portefeuille " + d.name + " a déjà été ajoutée.",
      invalid: (d) => "Adresse de portefeuille " + d.name + " non valide.",
      invalidAddressError: () =>
        "Ce n'est pas une adresse de portefeuille valide pour cette source d'importation.",
      invalidBulkBlockchain: (d) =>
        "Valeur de blockchain non valide « " + d.chain + " ».",
      invalidEnsError: () => "Le nom ENS n'a pas pu être résolu.",
      invalidSnsError: () => "Le nom SNS n'a pas pu être résolu.",
      tooManyWallets: (d) =>
        "Vous ne pouvez importer qu'un maximum de " +
        d.maxWalletImports +
        " portefeuilles à la fois.",
      tooManyWalletsError: () =>
        "Ce portefeuille dépasse le nombre maximum de portefeuilles que vous pouvez importer à la fois.",
    },
    invalid: () =>
      "Nom de portefeuille invalide, veuillez ne pas utiliser les caractères : $ ; \" ' `",
    name: () => "Nom du portefeuille",
    nameRequired: (d) =>
      "Veuillez saisir un nom pour votre portefeuille " + d.walletName,
    nickname: () => "Surnom (facultatif)",
    nicknameCanBeAddedLater: () =>
      "Vous pouvez surnommer ces portefeuilles après les avoir importés",
    relatedChains: {
      body: (d) => d.chains,
      title: () => "Chaînes EVM associées :",
    },
    safetyInfo: {
      frontPart: () => "Nous demandons votre ",
      highlightPart: () => "adresse de portefeuille public uniquement",
      lastPart: () =>
        "Nous ne demanderons jamais de clés privées et ne pourrons pas transférer de fonds.",
    },
    softSync: {
      action: () => "Synchronisation douce",
      title: (d) =>
        "Synchronisez les transactions du portefeuille " + d.name + " depuis",
    },
    submit: (d) => "Importer le portefeuille " + d.source,
    submitMultiple: () => "Importer des portefeuilles",
    title: () => "Importer les données du portefeuille",
    type: () => "Type de blockchain",
    walletNicknameUpdate: () =>
      "Le surnom du portefeuille a été mis à jour avec succès",
    wentWrongSavingWalletData: () =>
      "Une erreur s'est produite lors de l'enregistrement des données du portefeuille.",
    wentWrongSyncingWalletData: () =>
      "Une erreur s'est produite lors de la synchronisation des données du portefeuille.",
  },
  warningDisplayName: {
    all: () => "Tous",
    missingBlockchain: () => "Blockchain manquante",
    missingPrice: () => "Prix du marché manquant",
    negativeBalance: () => "Historique d'achat manquant",
    suspectedAirdropTransaction: () => "Transaction Airdrop suspectée",
    uncategorised: () => "Non classé",
    unknownInOut: () => "Importation suspectée manquante",
    zeroCostBuy: () => "Achat sans frais",
  },
  warnings: {
    missingBlockchain: () =>
      "Nous n'avons pas pu déterminer la blockchain pour cette transaction. Cela est souvent dû à la même adresse de portefeuille sur plusieurs blockchains. \n\n Affectez la blockchain pour résoudre cet avertissement.",
    missingPrice: (d) =>
      "Nous n'avons pas de prix de marché pour le jeton " +
      d.symbol +
      " à cette date, nous avons donc supposé un prix de zéro. Accédez au graphique des prix de ce jeton et vérifiez quel était le prix à cette date spécifique, puis modifiez la transaction pour mettre à jour le prix à cette valeur. Cela devrait résoudre l'avertissement.",
    negativeBalance: (d) =>
      select(d.balanceRemaining, {
        true:
          " Cette transaction a entraîné un solde négatif de votre solde global de " +
          d.symbol +
          " , ce qui a donné lieu à un solde restant de " +
          d.balanceTotal +
          " " +
          d.symbol +
          " . \n\n Avez-vous acheté " +
          d.symbol +
          " auprès d'une bourse ou d'une autre source ? C'est probablement la pièce manquante. Assurez-vous d'importer les données de transaction de toutes les sources auprès desquelles vous avez acheté " +
          d.symbol +
          " , et cela devrait résoudre le problème. ",
        other: "",
      }),
    uncategorised: (d) =>
      "Cette transaction utilise la catégorie " +
      select(d.trade, {
        in: " par défaut « Inconnu », ce qui peut entraîner des soldes inexacts ",
        out: " par défaut « Sortie inconnue », ce qui peut déclencher un événement de gain en capital ",
        other: " catégorie « Inconnu » qui doit être modifiée ",
      }) +
      " . Si vous importez tous vos échanges et portefeuilles, notre algorithme devrait pouvoir catégoriser automatiquement cela. Sinon, il peut y avoir des données manquantes, auquel cas vous pouvez sélectionner manuellement une catégorie dans la liste déroulante.",
    unknownInOut: () =>
      "Vous avez transféré des actifs entre vos propres portefeuilles ? Vous avez peut-être oublié d'importer les données depuis l'un de ces portefeuilles. \n\n S'il s'agit d'une transaction entre vous et un portefeuille externe, vous pouvez sélectionner la catégorie qui décrit le mieux cette transaction dans la liste déroulante à gauche (par exemple, cadeau reçu, récompenses de jalonnement, airdrop, etc.). \n\n Vous pouvez également nommer cette adresse pour résoudre les futurs avertissements d'importation manquante suspectée pour cette adresse.",
    zeroCostBuy: () =>
      "Il n'existe aucun solde pour couvrir cette transaction. Par conséquent, un achat à coût nul (entraînant une base de coût de zéro) a été supposé. Cela peut entraîner des gains calculés plus élevés qu'ils ne devraient. Corrigez tous les avertissements d'historique d'achat manquants antérieurs pour résoudre ce problème.",
  },
  warningsDropdown: {
    missingImportData: {
      description: () =>
        "Sans toutes les sources de données, nous ne pouvons pas refléter avec précision votre solde global.",
      title: () => "Données d'importation manquantes",
    },
  },
  was: () => "était",
  watchVideo: () => "Regarder la vidéo",
  worksWith: () => "Fonctionne avec",
  xero: {
    accountMapping: {
      accounts: {
        asset: {
          title: () => "Compte d'actifs",
          tooltip: () =>
            "Compte représentant la valeur des actifs du portefeuille de l'utilisateur",
        },
        cash: {
          title: () => "Compte de caisse",
          tooltip: () =>
            "Compte représentant les avoirs en espèces. Si vous vendez des crypto-monnaies, nous représenterons le produit en espèces ici et vice versa lorsque vous achetez des crypto-monnaies. Les échanges de crypto à crypto n'auront aucun impact sur ce compte.",
        },
        expense: {
          title: () => "Compte de dépenses",
          tooltip: () => "Compte représentant les dépenses cryptographiques",
        },
        fee: {
          title: () => "Compte de frais",
          tooltip: () => "Compte représentant le montant payé en frais",
        },
        income: {
          title: () => "Compte de revenus",
          tooltip: () =>
            "Compte représentant les revenus provenant de la crypto",
        },
        loan: {
          title: () => "Compte de prêt",
          tooltip: () =>
            "Compte représentant des prêts et remboursements cryptographiques",
        },
        loss: {
          title: () => "Compte de pertes",
          tooltip: () => "Compte représentant le montant de crypto perdu",
        },
        "realised-gain": {
          title: () => "Compte de gain réalisé",
          tooltip: () =>
            "Compte représentant les gains qui ont été réalisés à partir de la cession d'actifs cryptographiques",
        },
        "realised-loss": {
          title: () => "Compte de pertes réalisées",
          tooltip: () =>
            "Compte représentant les pertes résultant de la cession d'actifs cryptographiques",
        },
        "zero-cost-buy": {
          title: () => "Historique d'achat manquant",
          tooltip: () =>
            "Lorsqu'un actif est acheté ou obtenu et qu'aucun paiement n'est effectué pour celui-ci, nous considérons que le prix de base de l'actif est la valeur marchande et ce compte est utilisé pour équilibrer l'autre côté du journal.",
        },
      },
      lastSynced: (d) => "Dernière synchronisation " + d.date,
      refreshInstructions: () =>
        "Si vous avez créé un nouveau compte sur Xero et que vous ne le voyez pas, actualisez la liste.",
      syncError: () => "Nous n'avons pas pu synchroniser les données.",
      syncInstructions: () =>
        "Pour démarrer la synchronisation, vous devez choisir les comptes auxquels les données seront mappées. Vous pouvez mettre à jour les comptes à tout moment. La synchronisation peut être démarrée lorsque le mappage est défini.",
      title: () => "Cartographie des comptes",
    },
    buttons: {
      disconnect: () => "Se déconnecter de Xero",
      refreshList: {
        completed: () => "Liste des comptes Xero actualisée",
        done: () => "Actualiser la liste des comptes",
        pending: () => "Liste actualisée",
        tooltip: {
          done: () => "Actualiser les comptes disponibles",
          pending: () => "Cela peut prendre un certain temps",
        },
      },
      syncAccounts: {
        done: () => "Synchroniser les comptes",
        pending: () => "Synchronisation des comptes",
        tooltip: {
          disabled: () =>
            "Assurez-vous que chaque compte est sélectionné pour activer la synchronisation",
          done: () => "Synchroniser les comptes avec Xero",
          pending: () => "Cela peut prendre un certain temps",
        },
      },
    },
    connect: () => "Se connecter à Xero",
    errors: {
      disconnecting: () =>
        "Une erreur s'est produite lors de la déconnexion de Xero.",
      fetchingAccounts: () =>
        "Une erreur s'est produite lors de la récupération de la liste des comptes Xero.",
      fetchingSettings: () =>
        "Une erreur s'est produite lors de la récupération des paramètres Xero.",
      fetchingTenants: () =>
        "Une erreur s'est produite lors de la récupération de la liste des locataires Xero.",
      updatingAccounts: () =>
        "Une erreur s'est produite lors de la mise à jour des comptes Xero.",
      updatingTenant: () =>
        "Une erreur s'est produite lors de la mise à jour du locataire Xero.",
    },
    integrationName: () => "Xéro",
    integrationShortName: () => "Xéro",
    newIntegration: {
      connected: () => " Connecté",
      connectedAt: (d) => "Dernière connexion " + d.date,
      description: () =>
        "Résumez les activités commerciales quotidiennes dans des journaux comptables au sein de Xero. Cela peut vous aider à suivre vos finances et à comprendre les sources de revenus, de pertes et de dépenses. Les mappages de comptes peuvent être personnalisés pour correspondre à la façon dont vous gérez votre comptabilité.",
      disconnectConfirmation: () =>
        "Attention ! Êtes-vous sûr de vouloir déconnecter votre compte Xero ? Cette action ne peut pas être annulée, vous devrez resélectionner vos comptes à l'avenir. Vos journaux synchronisés resteront dans Xero.",
      disconnected: () => "Déconnecté",
      status: () => "Statut: ",
      title: () => "Connectez-vous au compte Xero",
    },
    reportFooter: {
      settingsLink: () => "Afficher les paramètres pour plus d'informations",
      subTitle: () =>
        "Connectez le calculateur de taxes sur les crypto-monnaies à Xero",
      title: () => "Intégration Xero",
    },
    rollup: {
      day: () => "Jour",
      description: {
        day: () =>
          "Toutes les transactions d'un jour calendaire donné seront regroupées dans un seul journal",
        month: () =>
          "Toutes les transactions d'un mois calendaire donné seront regroupées dans un seul journal",
        none: () =>
          "Toutes les transactions seront exportées sous forme de journaux individuels",
      },
      month: () => "Mois",
      none: () => "Pas de cumul",
    },
    syncDialog: {
      confirmation: () =>
        "Êtes-vous sûr de vouloir effectuer la synchronisation ? Veuillez vous assurer que vos données sont correctes. Les modifications apportées aux transactions déjà synchronisées ne seront pas reflétées dans Xero.",
      error: () => "Oh non ! On dirait qu'il y a eu un problème...",
      syncing: () =>
        "Nous synchronisons actuellement les données, cela peut prendre un certain temps...",
    },
    tenantList: {
      ensureCorrectTenant: () =>
        "Assurez-vous que le bon compte est sélectionné pour le flux de données.",
      exportJournals: () => "Exporter des journaux",
      listPlaceholder: () => "Sélectionner le flux de données",
      listTitle: () => "Compte Xero",
    },
  },
  yes: () => "Oui",
};
