import { type InventoryMethod } from "@ctc/types";
import { FormControl, MenuItem, type SelectChangeEvent } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { StyledSelect } from "~/components/settings-modal/views/tax/TimezoneSelector";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { IconlessDropdown } from "~/components/ui/Dropdown";
import { PaywallModalType } from "~/contexts/paywall-modal-context/enums";
import { PaywallModalContext } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { useCountry, useHighestRankingPlanUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  getDefaultInventoryMethodByCountry,
  useAvailableInventoryMethods,
  useInventoryMethod,
} from "~/redux/report";
import { Features } from "~/types/enums";

type InventorySelectorProps = {
  disabled?: boolean;
  onChange: (inventoryMethod: InventoryMethod) => void;
};

export function InventorySelector({
  disabled = false,
  onChange,
}: InventorySelectorProps) {
  const country = useCountry();
  const defaultInventoryMethod = getDefaultInventoryMethodByCountry(country);
  const currentInventoryMethod = useInventoryMethod();
  const availableInventoryMethods = useAvailableInventoryMethods();
  const highestRankingPlanUser = useHighestRankingPlanUser();
  const lang = useLang();
  const paywallContext = useContext(PaywallModalContext);
  const [selectedInventoryMethod, setSelectedInventoryMethod] = useState<
    InventoryMethod | ""
  >(currentInventoryMethod || "");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

  const handleChange = (e: SelectChangeEvent<string>) => {
    const choice = e.target.value as InventoryMethod;
    if (!choice) return;
    // If the user selects the default inventory method, set it and return
    if (choice === defaultInventoryMethod) {
      setSelectedInventoryMethod(choice);
      onChange(choice);
    }
    // If the user selects a non-default inventory method, check if they have advanced inventory
    const hasAdvancedInventory =
      highestRankingPlanUser?.features[Features.AdvancedInventory];

    if (!hasAdvancedInventory) {
      return paywallContext?.setSelectedModal(
        PaywallModalType.AdvancedInventory,
      );
    }
    setSelectedInventoryMethod(choice);
    setIsConfirmationOpen(true);
  };

  useEffect(() => {
    setSelectedInventoryMethod(currentInventoryMethod || "");
  }, [currentInventoryMethod]);

  const handleConfirm = (inventoryMethod: InventoryMethod) => {
    if (inventoryMethod) {
      onChange(inventoryMethod);
    }
    setIsConfirmationOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmationOpen(false);
    setSelectedInventoryMethod(currentInventoryMethod || "");
  };
  const confirmationText = selectedInventoryMethod
    ? `You have selected the ${lang.report[selectedInventoryMethod]} inventory method, which is not the default option. Always check with your tax professional before using the ${lang.report[selectedInventoryMethod]} inventory method.`
    : "";

  return (
    <>
      <ConfirmationDialog
        title="Confirm Inventory Method"
        text={confirmationText}
        actionText="Acknowledged"
        handleAction={() => {
          handleConfirm(selectedInventoryMethod as InventoryMethod);
        }}
        isOpen={isConfirmationOpen}
        handleClose={handleCancel}
      />
      <IconlessDropdown title={lang.report.inventoryMethod}>
        <FormControl required fullWidth>
          <StyledSelect
            disabled={disabled}
            labelId="inventory"
            value={selectedInventoryMethod}
            onChange={handleChange}
            required
            variant="outlined"
          >
            {availableInventoryMethods.map((method) => (
              <MenuItem key={method} value={method}>
                {lang.report[method]}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </IconlessDropdown>
    </>
  );
}
