import _ from "lodash";
import { type ComponentProps, memo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

/** A list of srcs to try searching for the correct image to display */
export type FallbackSrcs = string | string[] | undefined;

/**
 * Takes an array of possible image srcs
 * Keeps trying each one until it resolves one
 * @returns
 */
function useImageSrcFallback(src: FallbackSrcs) {
  const [index, setIndex] = useState(0);
  const [chosenSrc, setImageSrc] = useState<string | undefined>(
    getInitialSrc(src, index),
  );

  // If the user passes in a new array of srcs, reset the index back to zero
  const [prevItems, setPrevItems] = useState(src);
  if (src !== prevItems) {
    setPrevItems(src);
    setIndex(0);
    setImageSrc(getInitialSrc(src, 0));
  }

  return {
    src: chosenSrc,
    // if the new src happens to be the same as the previous src, force a re-render to keep traversing fallbacks
    key: index,
    onError: () => {
      if (src === undefined) {
        return;
      }
      if (typeof src === "string") {
        setImageSrc(undefined);
        return;
      }
      if (index + 1 < src.length) {
        setIndex(index + 1);
        setImageSrc(src[index + 1]);
      } else {
        setImageSrc(undefined);
      }
    },
  };
}

/**
 * For a given src(s) choose the first one
 * @param src An array of srcs, or a single src
 * @param index The index we are up to try
 * @returns
 */
function getInitialSrc(src: FallbackSrcs, index: number) {
  if (!src) {
    return undefined;
  }
  if (typeof src === "string") {
    return src;
  }
  return src[index];
}

/**
 * A LazyImageLoad component but it takes an array of fallback srcs
 * that it will try and load in sequence if they aren't found
 */
export const LazyLoadImageWithFallback = memo(
  function LazyLoadImageWithFallback({
    src,
    ...rest
  }: Omit<ComponentProps<typeof LazyLoadImage>, "src"> & {
    src: FallbackSrcs;
  }) {
    const imageProps = useImageSrcFallback(src);
    if (!imageProps.src) {
      return null;
    }
    return <LazyLoadImage {...rest} {...imageProps} />;
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps.src, nextProps.src);
  },
);

/**
 * A "img"" component but it takes an array of fallback srcs
 * that it will try and load in sequence if they aren't found
 */
export const ImageWithFallback = memo(
  function ImageWithFallback({
    src,
    ...rest
  }: Omit<ComponentProps<"img">, "src"> & { src: FallbackSrcs }) {
    const imageProps = useImageSrcFallback(src);

    if (!imageProps.src) {
      return null;
    }
    return <img {...rest} {...imageProps} />;
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps.src, nextProps.src);
  },
);
