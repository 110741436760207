import { EntityType } from "~/types/enums";
import { type Entity, type ExchangeOption } from "~/types/index";

/* Show the label as a subscript if it's different to the name if we 
     are on an address that belongs to a wallet or entity
     but if it's an entity and the label is the ref (for internal use), don't show it
  */
export function shouldShowExchangeOptionSubLabel(
  option: ExchangeOption,
  entity?: Entity,
) {
  return (
    ((Boolean(entity?.displayName) &&
      !(
        entity &&
        entity?.type === EntityType.Exchange &&
        entity?.ref === option.label
      )) ||
      option.isWallet) &&
    option.name !== option.label
  );
}
