// import { useNavController } from "../../NavController";
import { type Blockchain } from "@ctc/types";
import uniqBy from "lodash/uniqBy";

import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { getOptionsFromAccounts } from "~/components/transactions/command-palette/views/change-source-account/getOptionsFromAccounts";
import { ToSourceAccount } from "~/components/transactions/command-palette/views/change-source-account/ToSourceAccount";
import { formatDisplayAddress } from "~/components/transactions/helpers";
import { useSelectedIds } from "~/hooks/useTransactions";
import { invariant } from "~/lib/invariant";
import { useIsAdressLikeFn } from "~/redux/imports";
import { useLang } from "~/redux/lang";
import { useGetActionsQuery } from "~/state/actions";

export const FromSourceAccount = () => {
  const { push } = useNavController();
  const lang = useLang();
  const isAddressLike = useIsAdressLikeFn();

  const selectedIds = useSelectedIds();
  const query = useGetActionsQuery();

  // TODO: make this more efficient maybe
  const fromAccounts = uniqBy(
    query.data?.transactions.flatMap((action) => {
      if (selectedIds.length && !selectedIds.includes(action._id)) {
        return [];
      }
      const tx = action.outgoing[0] ?? action.incoming[0] ?? action.fees[0];
      invariant(tx, "Unable to determine `Source` from transactions");
      const isAnAddress = isAddressLike(tx.from);
      const isNicknamed = tx.from !== tx.fromDisplayName && isAnAddress;
      const finalDisplayName = tx.fromDisplayName.trim();
      const displayName = isNicknamed
        ? finalDisplayName
        : formatDisplayAddress(finalDisplayName, isNicknamed, isAnAddress);

      return [
        {
          displayName,
          exchangeId: tx.from,
          blockchain: tx.blockchain as Blockchain | undefined,
          currencySymbol: tx.currencyIdentifier.symbol,
        },
      ];
    }),
    (party) => `${party.exchangeId}__${party.blockchain}`,
  );

  // get the from currencies
  const options = getOptionsFromAccounts(fromAccounts);
  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.SourceAccount} />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.outgoingAccount}
      options={options}
      onSelection={({ party }) => {
        push(<ToSourceAccount fromParty={party} />);
      }}
    />
  );
};
