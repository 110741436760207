import { OAuthProvider } from "@ctc/types";

import namedBTCLogoDark from "~/assets/bitcoin-dot-com-au-named-logo-dark.svg";
import namedBTCLogoLight from "~/assets/bitcoin-dot-com-au-named-logo-light.svg";
import NamedIRLogoDark from "~/assets/independent-reserve-logo.svg";
import NamedIRLogoLight from "~/assets/independent-reserve-logo-light.svg";
import successBTCDarkSrc from "~/assets/lottie-animations/bitcoin-dot-com-au/sync-complete-dark.json";
import successBTCLightSrc from "~/assets/lottie-animations/bitcoin-dot-com-au/sync-complete-light.json";
import connectingBTCDarkSrc from "~/assets/lottie-animations/bitcoin-dot-com-au/sync-loading-dark.json";
import connectingBTCLightSrc from "~/assets/lottie-animations/bitcoin-dot-com-au/sync-loading-light.json";
import successIRDarkSrc from "~/assets/lottie-animations/independent-reserve/sync-complete-dark.json";
import successIRLightSrc from "~/assets/lottie-animations/independent-reserve/sync-complete-light.json";
import connectingIRDarkSrc from "~/assets/lottie-animations/independent-reserve/sync-loading-dark.json";
import connectingIRLightSrc from "~/assets/lottie-animations/independent-reserve/sync-loading-light.json";
import {
  type Provider,
  type ProviderConfig as ProviderConfigType,
} from "~/components/onboarding-v2/provider/types";

/**
 * A map of configs for each provider to be used in the onboarding flow.
 * Allows us to add new providers without having to update onboarding.
 */
export const ProviderConfig: Record<Provider, ProviderConfigType> = {
  [OAuthProvider.IndependentReserve]: {
    name: "Independent Reserve",
    nameLogoLight: NamedIRLogoLight,
    nameLogoDark: NamedIRLogoDark,
    animations: {
      connectionLight: connectingIRLightSrc,
      connectionDark: connectingIRDarkSrc,
      successLight: successIRLightSrc,
      successDark: successIRDarkSrc,
    },
    shouldSetPassword: true,
  },
  [OAuthProvider.BitcoinDotComAu]: {
    name: "bitcoin.com.au",
    nameLogoLight: namedBTCLogoLight,
    nameLogoDark: namedBTCLogoDark,
    animations: {
      connectionLight: connectingBTCLightSrc,
      connectionDark: connectingBTCDarkSrc,
      successLight: successBTCLightSrc,
      successDark: successBTCDarkSrc,
    },
    shouldSetPassword: true,
  },
};
