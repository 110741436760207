import { IconButton } from "@mui/material";
import { css } from "styled-components";
import styled from "styled-components/macro";

export const TextIconButton = styled(IconButton)<{
  "aria-controls"?: string | undefined;
  active?: boolean;
  textColor?: string;
  textHoverColor?: string;
  hoverBgColor?: string;
}>`
  && {
    color: ${({ theme, textColor }) => textColor || theme.tokens.icon.default};
    background-color: transparent;
    border-radius: 4px;
    transition: all 100ms;

    ${(props) =>
      // If this button is active or has an aria-controls attribute
      // this means its controlling some menu thats open, so we need to
      // show it in the active state
      props.active || Boolean(props["aria-controls"])
        ? css`
            background: ${props.theme.tokens.background.brand.pressed};
          `
        : ""}
  }

  &:hover {
    background-color: ${({ theme, hoverBgColor }) =>
      hoverBgColor || theme.tokens.background.brand.hover};
    color: ${({ theme, textColor, textHoverColor }) =>
      textHoverColor || textColor || theme.tokens.icon.default};
  }

  &.Mui-focusVisible {
    background-color: ${({ theme }) => theme.tokens.background.brand.pressed};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.text.disabled};
  }
`;
