import { type Country } from "@ctc/types";

import { type Translation } from "~/lang/index";
import { NormalReportType } from "~/types/enums";
import {
  type ReportDownloadType,
  shouldReplaceCGTWithPNL,
} from "~/types/index";

export function getReportTypeLang(
  lang: Translation,
  reportType: ReportDownloadType,
  country?: Country,
): string {
  if (
    reportType === NormalReportType.CapitalGains &&
    shouldReplaceCGTWithPNL(country)
  ) {
    // There must be a better way than this
    return lang.report.reportType["capital-gains-nz"];
  }

  return lang.report.reportType[reportType];
}

export function getReportSubtitleLang(
  lang: Translation,
  reportType: ReportDownloadType,
  country?: Country,
): string {
  if (
    reportType === NormalReportType.CapitalGains &&
    shouldReplaceCGTWithPNL(country)
  ) {
    // There must be a better way than this
    return lang.report.reportSubTitles["capital-gains-nz"];
  }

  return lang.report.reportSubTitles[reportType];
}

export function getCapitalGainsTooltipLang(
  lang: Translation,
  longTermThreshold?: number,
  country?: Country,
): string {
  if (shouldReplaceCGTWithPNL(country)) {
    return longTermThreshold
      ? lang.report.tooltips.totalCapitalGainsNoCGT({
          longTermThreshold,
        })
      : lang.report.tooltips.noCapitalGainsNoCGT;
  }

  return longTermThreshold
    ? lang.report.tooltips.totalCapitalGains({
        longTermThreshold,
      })
    : lang.report.tooltips.noCapitalGains;
}
