import { createContext, type PropsWithChildren, useState } from "react";

import { CapitalGainsCategories } from "~/components/reconciliation/enums";
import { NormalReportType } from "~/types/enums";
import { type ActionType } from "~/types/index";

interface ReportFilterProps {
  reportType: NormalReportType;
  setReportType: (reportType: NormalReportType) => void;
  tradeType?: ActionType;
  category: CapitalGainsCategories;
  setTradeType: (type: ActionType | undefined) => void;
  setCategory: (category: CapitalGainsCategories) => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

export const ReportFilter = createContext<ReportFilterProps | undefined>(
  undefined,
);

export function ReportFilterProvider({ children }: PropsWithChildren) {
  const [reportType, setReportType] = useState<NormalReportType>(
    NormalReportType.ReportSummary,
  );
  const [tradeType, setTradeType] = useState<ActionType | undefined>(undefined);
  const [category, setCategory] = useState<CapitalGainsCategories>(
    CapitalGainsCategories.All,
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <ReportFilter.Provider
      value={{
        tradeType,
        category,
        setTradeType,
        setCategory,
        showModal,
        setShowModal,
        reportType,
        setReportType,
      }}
    >
      {children}
    </ReportFilter.Provider>
  );
}
