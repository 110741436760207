import { FormControl, Grid } from "@mui/material";
import moment from "moment-timezone";
import {
  Controller,
  useFormContext,
  type UseFormReturn,
} from "react-hook-form";

import { DateTimePicker } from "~/components/transactions/edit/DateTimePicker";
import { useLang } from "~/redux/lang";
import { type PutTransactionPayload } from "~/services/actions";
import { useLockPeriodEndDate } from "~/state/period";
import { type ActionRow } from "~/types/index";

export type EditActionDrawerDateValues = {
  timestamp: string;
};
export const getValues = ({
  row,
}: {
  row: ActionRow;
}): EditActionDrawerDateValues => {
  return {
    timestamp: row.timestamp.toString(),
  };
};

export const applyUpdates = (
  row: ActionRow,
  form: UseFormReturn<EditActionDrawerDateValues>,
  formData: EditActionDrawerDateValues,
  updateMap: Map<string, PutTransactionPayload>,
) => {
  const newTimestamp = formData.timestamp;

  // Don't apply any update if field not changed.
  if (!form.formState.dirtyFields.timestamp || !newTimestamp) return;

  const update = (id: string, update: PutTransactionPayload) => {
    if (!updateMap.has(id)) updateMap.set(id, { _id: id });
    const value = updateMap.get(id);
    updateMap.set(id, { ...value, ...update });
  };

  // Update both sides of tx.
  row.incoming.forEach((tx) => {
    update(tx._id, { timestamp: newTimestamp.toString() });
  });

  row.outgoing.forEach((tx) => {
    update(tx._id, { timestamp: newTimestamp.toString() });
  });

  row.fees.forEach((tx) => {
    update(tx._id, { timestamp: newTimestamp.toString() });
  });
};

export const EditActionDate = () => {
  const lang = useLang();
  const lockPeriodEndDate = useLockPeriodEndDate();

  const methods = useFormContext();

  return (
    <Grid item xs={12}>
      <FormControl fullWidth margin="none">
        <Controller
          control={methods.control}
          name="timestamp"
          rules={{
            required: lang.editTx.mustTime,
            validate: {
              // Checks that the date is not in the locked period
              // You can't add a manual transaction into a locked period
              notInLockedPeriod: (value: string) => {
                const selectedDate = new Date(value);
                const isValid = selectedDate > lockPeriodEndDate;
                return isValid || lang.manual.requiredText.notInLockedPeriod;
              },
            },
          }}
          render={({
            field: { value, onBlur },
            fieldState: { invalid, error },
          }) => (
            <DateTimePicker
              value={value}
              onBlur={(e) => {
                const mValue = moment(value);
                const inputValue = e.target.value;
                if (inputValue) {
                  const isSame = moment(inputValue).isSame(mValue);
                  // Prevent dirty if value has not changed.
                  if (isSame) return;
                }

                onBlur();
              }}
              label={lang.manual.Date}
              handleChange={(date: string) => {
                methods.setValue("timestamp", date, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                methods.trigger();
              }}
              helperText={error?.message}
              error={invalid}
              required
              inputProps={{
                "data-hj-allow": true,
                sx: { fontSize: "0.875rem", minHeight: "2.22rem" },
              }}
            />
          )}
        />{" "}
      </FormControl>
    </Grid>
  );
};
