// Needs to be imported before react and react-dom
import { CssBaseline } from "@mui/material";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { scan } from "react-scan";

import { initGoogleAnalytics } from "~/analytics/google";
import { initPosthogAnalytics } from "~/analytics/posthog";
import { Main as App } from "~/components/App";
import { initDataDogMonitoring } from "~/datadog";
import { setFavicon } from "~/services/favicon";
import { initIntercom } from "~/services/intercom";

initGoogleAnalytics();
initPosthogAnalytics();
initDataDogMonitoring();
initIntercom();

scan({
  enabled: import.meta.env.VITE_APP_REACT_SCAN === "enabled",
  trackUnnecessaryRenders: true,
});

// Sentry.init({
//   release: import.meta.env.VITE_APP_VERSION || "local",
//   environment: import.meta.env.VITE_APP_ENV,
//   dsn: "https://d9e9c0d2aff44ca490e30d6146c94a6c@o1029408.ingest.sentry.io/4504731678801920",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 0.1,
// });

const container = document.getElementById("root");
const root = createRoot(container!);
const Main = () => {
  useEffect(() => {
    setFavicon(import.meta.env.VITE_APP_ENV);
  }, []);

  return (
    <>
      <CssBaseline />
      <App />
    </>
  );
};

root.render(<Main />);
export { isValidWalletAddress } from "~/services/wallet/isValidWalletAddress";
export { isValidPolkadotChainAddress } from "~/services/wallet/isValidWalletAddress";
export { isValidCardanoWalletAddress } from "~/services/wallet/isValidWalletAddress";
