import { Box } from "@mui/material";

import { useDesign } from "~/hooks/useTheme";

export const CountChip = ({
  chip,
  disabled,
}: {
  chip: string | number;
  disabled?: boolean;
}) => {
  const { tokens } = useDesign();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="100%"
      bgcolor={
        disabled
          ? tokens.background.disabled
          : tokens.background.accent.purple.low
      }
      px="0.4rem"
      fontSize="0.85rem"
      fontWeight={500}
      minWidth="1.25rem"
      color={disabled ? tokens.text.low : tokens.text.default}
    >
      {chip}
    </Box>
  );
};
