import { toast } from "sonner";

import { queryClient } from "~/components/queryClient";
import { resetPortfolio } from "~/redux/portfolio";
import { resetReport } from "~/redux/report";
import { type AppThunk } from "~/redux/types";

import { csrfTokenManager } from "../../lib/csrfTokenManager";

export const resetUserData = (): AppThunk => async (dispatch) => {
  csrfTokenManager.clearStoredToken();
  toast.dismiss();

  dispatch(resetPortfolio());
  dispatch(resetReport());

  // delete all data in react query
  queryClient.clear();
};
