import { Country, Env } from "@ctc/types";
import { Error } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";

import { StyledDialogActions } from "~/components/ui/StyledDialogActions";
import { displayMessage } from "~/components/ui/Toaster";
import { Constants } from "~/constants/enums";
import { useDesign } from "~/hooks/useTheme";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import * as subscriptionAPI from "~/services/subscription";
import { usePaymentMethodQuery } from "~/state/billing";
import { DisplayMessage } from "~/types/enums";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  import.meta.env.VITE_APP_ENV === Env.Prod
    ? Constants.Stripe
    : Constants.StripeTest,
);

const AddCardForm = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const { refetch: refetchPaymentMethod } = usePaymentMethodQuery();

  const onClose = () => {
    setErrorMessage(null);
    handleClose();
  };

  if (!user) {
    return null;
  }

  const handleSubmit = async (event: any) => {
    setIsLoading(true);
    setErrorMessage(null);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    if (!card) {
      const code = "45732";
      console.error(lang.billing.stripe[code]);
      setErrorMessage(lang.billing.stripe.failedToAddTheCard);
      setIsLoading(false);
      return;
    }
    const paymentMethodResult = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        email: user.email,
      },
    });

    if (paymentMethodResult.error || !paymentMethodResult.paymentMethod) {
      const code = "44359";
      console.error(
        lang.billing.stripe[code],
        paymentMethodResult.paymentMethod,
        paymentMethodResult.error,
      );
      setErrorMessage(lang.billing.stripe.failedToAddTheCard);
      setIsLoading(false);
      return;
    }

    const result = await subscriptionAPI.addPaymentMethod(
      paymentMethodResult.paymentMethod.id,
    );
    if (result.error) {
      setErrorMessage(lang.billing.stripe.failedToAddTheCard);
      setIsLoading(false);
      return;
    }

    displayMessage({
      message: lang.billing.stripe.cardSuccessfullyAdded,
      type: DisplayMessage.Info,
    });
    refetchPaymentMethod();
    setIsLoading(false);
    onClose();
  };

  return (
    <form>
      <Dialog
        open={open}
        onClose={!isLoading ? onClose : undefined}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="Metropolis/Header/H4">{lang.billing.addCard.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="Metropolis/Body/Light">
              {lang.billing.addCard.description}
            </Typography>
          </Box>
          <Box
            my={2}
            p={2}
            border={`1px solid ${tokens.background.accent.neutral.low}`}
            borderRadius="4px"
          >
            <CardElement
              onChange={(e) => {
                setErrorMessage(e.error?.message);
              }}
              className="fs-exclude"
              options={{
                hidePostalCode: user.country !== Country.USA, // Disable all except for the US
                style: {
                  base: {
                    color: tokens.text.default,
                    fontFamily: "Metropolis, Helvetica, Arial, sans-serif",
                    fontSmoothing: "antialiased",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                    letterSpacing: "-0.00833em",
                    fontSize: "1rem",
                    "::placeholder": {
                      color: tokens.text.low,
                    },
                  },
                  invalid: {
                    color: tokens.text.danger,
                    iconColor: tokens.icon.default,
                  },
                },
              }}
            />
          </Box>
          {errorMessage && (
            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                color="error"
                style={{
                  display: "block",
                  float: "right",
                }}
              >
                {errorMessage.replace(".", "")}
                <Box
                  component="span"
                  position="relative"
                  top="0.25rem"
                  ml="0.125rem"
                  fontSize="1rem"
                >
                  <Error fontSize="inherit" color="error" />
                </Box>
              </Typography>
            </Box>
          )}
        </DialogContent>
        <StyledDialogActions>
          <Button onClick={onClose} variant="outlined" color="secondary">
            {lang.cancel}
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={isLoading}
            style={{ minWidth: "7.5rem" }}
          >
            {isLoading
              ? lang.billing.addCard.loading
              : lang.billing.addCard.submit}
          </Button>
        </StyledDialogActions>
      </Dialog>
    </form>
  );
};

export function AddCardDialog({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  return (
    <Elements stripe={stripePromise}>
      <AddCardForm open={open} handleClose={handleClose} />
    </Elements>
  );
}
