import { Typography } from "@mui/material";

import { useLang } from "~/redux/lang";

export function ClientManaged() {
  const lang = useLang();

  return (
    <>
      <Typography variant="Metropolis/Header/H5">
        {lang.settings.plan.clientOwned.title}
      </Typography>
      <Typography variant="Metropolis/Body/Regular">
        {lang.settings.plan.clientOwned.body}
      </Typography>
    </>
  );
}
