import { type LocalCurrency } from "@ctc/types";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import sum from "lodash/sum";
import { useState } from "react";

import { ActionHeaderCell } from "~/components/transactions/action-row/ActionHeaderCell";
import { ActionRowCell } from "~/components/transactions/action-row/ActionRowCell";
import { ActionRowHover } from "~/components/transactions/action-row/ActionRowHover";
import { EnDash } from "~/components/transactions/action-row/EnDash";
import { ActionTableGridArea } from "~/components/transactions/action-row/enums";
import { feeAccounts } from "~/components/transactions/action-row/getAccounts";
import { getCompactFiatValue } from "~/components/transactions/action-row/getCompactFiatValue";
import {
  ActionRowFirstLineBox,
  Overflower,
} from "~/components/transactions/action-row/Overflower";
import { AmountText } from "~/components/transactions/action-row/TxAmount";
import {
  type ActionRowCellProps,
  type ActionRowFeeType,
} from "~/components/transactions/action-row/types";
import { Warnings } from "~/components/transactions/action-row/Warnings";
import { EditActionFee } from "~/components/transactions/edit/EditActionFee";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";

import { useDesign } from "~/hooks/useTheme";
import {
  displayCryptoQuantity,
  displayFiatSymbol,
  displayFiatValue,
  divide,
} from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { getFeesFromActionRow } from "~/services/actions";
import { Align, Side, Sort, Warning } from "~/types/enums";
import { type ActionRow } from "~/types/index";

import { getCompactFormattedValue } from "./helpers";

function Fee({ fees }: { fees: ActionRowFeeType[] }) {
  // total fee value
  const totalFeeValue = sum(fees.map((fee) => fee.value));

  const languagePreference = useLanguagePreference();
  const localCurrency = useLocalCurrency();
  const { tokens } = useDesign();

  const compactTotalFeeValue = getCompactFiatValue(totalFeeValue, 2);

  const compactValue = getCompactFormattedValue(
    languagePreference,
    totalFeeValue,
    localCurrency as LocalCurrency,
    compactTotalFeeValue.toString(),
  );

  // Outputs: $1.00 | 0.01 ETH, $2.00 | 0.02 ETH
  const hoverText = fees
    .map((fee) => {
      const {
        currency: feeCurrency,
        quantity: feeQuantity,
        value: feeValue,
      } = fee;
      const amountCrypto = `${displayCryptoQuantity({
        quantity: feeQuantity,
        locale: languagePreference,
        precision: 6,
      })}`;
      const isLocalCurrency = feeCurrency.symbol === localCurrency;
      return `${displayFiatValue({
        value: feeValue,
        localCurrency,
        locale: languagePreference,
        fallbackMinFractionDigits: 6,
      })}  ${isLocalCurrency ? "" : `| ${amountCrypto} ${feeCurrency.symbol}`}`;
    })
    .join(", ");

  return (
    <Stack direction="row" alignItems="center" spacing="0.5rem">
      <Tooltip title={hoverText} placement="top">
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          sx={{
            fontFamily: tokens.fontFamilies.numeric,
            color: tokens.text.default,
            lineHeight: 1,
          }}
        >
          {compactValue}
        </Typography>
      </Tooltip>
    </Stack>
  );
}

export function ActionRowFee({
  row,
  disablePointerEvents = false,
}: ActionRowCellProps) {
  const fees = getFeesFromActionRow(row);
  const localCurrency = useLocalCurrency();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <ActionRowCell
      id={ActionTableGridArea.Fee}
      align={Align.Right}
      disablePointerEvents={disablePointerEvents}
    >
      {!localCurrency || fees.length === 0 ? (
        <ActionRowCell id={ActionTableGridArea.Fee} align={Align.Right}>
          <ActionRowFirstLineBox>
            <EnDash />
          </ActionRowFirstLineBox>
        </ActionRowCell>
      ) : (
        <>
          <EditActionFee isOpen={isOpen} handleClose={handleClose} row={row} />

          <ActionRowHover
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }}
            style={{ position: "relative", minHeight: "1.75rem" }}
          >
            <Fee fees={fees} />
            <div style={{ position: "absolute", right: "-1.375rem" }}>
              <Warnings
                row={row}
                txIds={fees.map((tx) => tx.id)}
                warnings={[Warning.NegativeBalance, Warning.MissingPrice]}
              />
            </div>
          </ActionRowHover>
        </>
      )}
    </ActionRowCell>
  );
}

export function ActionHeaderFee({
  align = Align.Right,
  gridArea = ActionTableGridArea.Fee,
  disableSymbol = false,
}: {
  align?: Align;
  gridArea?: ActionTableGridArea.Fee | ActionTableGridArea.LedgerFee;
  disableSymbol?: boolean;
}) {
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const symbol = displayFiatSymbol(localCurrency, languagePreference);
  return (
    <Box style={{ gridArea }}>
      <ActionHeaderCell
        sort={{
          sortAscending: Sort.FeeAscending,
          sortDescending: Sort.FeeDescending,
        }}
        align={align}
      >
        {lang.txTable.fee} {!disableSymbol && symbol ? `(${symbol})` : null}
      </ActionHeaderCell>
    </Box>
  );
}

export function LedgerRowFee({ row }: ActionRowCellProps) {
  const fees = getFeesFromActionRow(row);
  const localCurrency = useLocalCurrency();

  const accounts = feeAccounts({ row, fees });

  return (
    <ActionRowCell id={ActionTableGridArea.LedgerFee} align={Align.Left}>
      {!localCurrency || fees.length === 0 ? (
        <ActionRowCell id={ActionTableGridArea.Fee} align={Align.Left}>
          <ActionRowFirstLineBox>
            <EnDash />
          </ActionRowFirstLineBox>
        </ActionRowCell>
      ) : (
        fees.map((fee) => {
          return (
            <LedgerFeeAmount
              key={`${fee.id}_${fee.currency.id}`}
              row={row}
              fee={fee}
            />
          );
        })
      )}
      {accounts}
    </ActionRowCell>
  );
}

function LedgerFeeAmount({
  row,
  fee,
}: {
  row: ActionRow;
  fee: ActionRowFeeType;
}) {
  const [isValueDialogueOpen, setIsValueDialogueOpen] = useState(false);
  const handleOpen = () => {
    setIsValueDialogueOpen(!isValueDialogueOpen);
  };

  const handleClose = () => {
    setIsValueDialogueOpen(false);
  };

  return (
    <>
      <EditActionFee
        isOpen={isValueDialogueOpen}
        handleClose={handleClose}
        row={row}
      />
      <Overflower>
        <Box display="flex" flexWrap="wrap" gap="0.5rem" alignItems="center">
          <ActionRowHover
            display="flex"
            flexWrap="nowrap"
            gap="0.5rem"
            sx={{
              "& svg": {
                visibility: "hidden",
              },
              cursor: "pointer",
              borderRadius: "0.25rem",
              "&:hover": {
                "& svg": {
                  visibility: "visible",
                },
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleOpen();
            }}
          >
            <CurrencyLogo
              currency={fee.currency}
              width={20}
              height={20}
              margin="0"
              style={{
                borderRadius: "100%",
                background: "transparent",
              }}
            />
            <AmountText
              side={Side.From}
              amount={fee.quantity}
              currency={fee.currency}
              price={divide(fee.value, fee.quantity)}
            />
            <Warnings
              row={row}
              txIds={[fee.id]}
              warnings={[Warning.NegativeBalance]}
            />
          </ActionRowHover>
        </Box>
      </Overflower>
    </>
  );
}
