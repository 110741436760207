import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { LocalStorageKey } from "~/constants/enums";
import { LocalStorageUserKeyGenerator } from "~/hooks/useLocalStorage";
import { invariant } from "~/lib/invariant";
import { useUser } from "~/redux/auth";
import { type UserInfo } from "~/types/index";

const TourContext = createContext<
  | {
      currentStep: number;
      setCurrentStep: (page: number) => void;
      numberOfSteps: number;
      finishTour: boolean;
      setFinishTour: (isFinish: boolean) => void;
    }
  | undefined
>(undefined);

/**
 * Provider for the tour context
 */
export const TourProvider = ({
  children,
  numberOfSteps,
}: {
  children: ReactNode;
  /** The number of steps in the tour */
  numberOfSteps: number;
}) => {
  const user = useUser();
  invariant(user, "User is required");
  const calcoTourState = getTourState({ user });
  const [currentStep, setCurrentStep] = useState(calcoTourState.currentStep);
  const [finishTour, setFinishTour] = useState(calcoTourState.finishTour);

  useEffect(() => {
    localStorage.setItem(
      LocalStorageUserKeyGenerator(user?.uid, LocalStorageKey.CalcoTourState),
      JSON.stringify({
        currentStep,
        finishTour,
      }),
    );
  }, [finishTour, currentStep, user?.uid]);

  return (
    <TourContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        finishTour,
        setFinishTour,
        numberOfSteps,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

/**
 * Controls the tour

function ExampleTourItem({
  children,
  stepNumber,
}: {
  children: ReactNode;
  stepNumber: number;
}) {
  const { getNextProps, getPrevProps, getRootProps } = useTour(stepNumber);
  return (
    <div {...getRootProps()}>
      {children}
      <button {...getPrevProps()}>{"prev"}</button>
      <button {...getNextProps()}>{"next"}</button>
    </div>
  );
}
 * @param stepNumber The number of the step the content is for
 *
 */
export const useTour = (stepNumber: number) => {
  const context = useContext(TourContext);
  invariant(context, "useTour must be used within a TourProvider");

  const {
    currentStep,
    setCurrentStep,
    numberOfSteps,
    finishTour,
    setFinishTour,
  } = context;

  const isVisible = currentStep === stepNumber;
  const atEnd = currentStep + 1 === numberOfSteps;

  useEffect(() => {
    // When this becomes visible, scroll to the tour step
    if (isVisible && !finishTour) {
      const element = document.getElementById(`tour-${stepNumber}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isVisible, stepNumber, finishTour]);

  const getRootProps = useCallback(() => {
    if (finishTour) return;
    if (isVisible) {
      return {
        id: `tour-${stepNumber}`,
      };
    }
    return {
      style: {
        display: "none",
      },
      id: `tour-${stepNumber}`,
    };
  }, [isVisible, stepNumber, finishTour]);

  const getNextProps = useCallback(() => {
    return {
      onClick: () => {
        if (atEnd) {
          setFinishTour(true);
          return;
        }
        setCurrentStep(currentStep + 1);
      },
    };
  }, [currentStep, setCurrentStep, setFinishTour, atEnd]);

  const getPrevProps = useCallback(() => {
    const atBeginning = currentStep === 0;
    return {
      disabled: atBeginning,
      onClick: () => {
        if (atBeginning) return;
        setCurrentStep(currentStep - 1);
      },
    };
  }, [currentStep, setCurrentStep]);

  return {
    /**
     * Spread this on a wrapper around your component
     * e.g. <div {..getRootProps()}><MyComponent /></div>
     */
    getRootProps,
    /**
     * Spread this on your next button
     * e.g. <Button {...getNextProps()}>Next</Button>
     */
    getNextProps,
    /**
     * Spread this on your previous button
     * e.g. <Button {...getPrevProps()}>Previous</Button>
     */
    getPrevProps,
    isVisible,
    finishTour,
    atEnd,

    /**
     * Manually end the tour
     */
    setFinishTour,
  };
};

function getTourState({ user }: { user: UserInfo }) {
  const calcoTourStateString = localStorage.getItem(
    LocalStorageUserKeyGenerator(user?.uid, LocalStorageKey.CalcoTourState),
  );
  const calcoTourState = calcoTourStateString
    ? JSON.parse(calcoTourStateString)
    : undefined;

  if (calcoTourState) {
    return calcoTourState;
  }

  return {
    currentStep: 0,
    finishTour: false,
  };
}
