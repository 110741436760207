import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const MAX_CLIENT_AGE = 24 * 60 * 60 * 1000;
const PAGE_AGE_KEY = "lastHardRefresh";

// Set the last page load time in session storage on first load
const setLastHardRefresh = () => {
  sessionStorage.setItem(
    PAGE_AGE_KEY,
    new Date().getTime().valueOf().toString(),
  );
};
/**
 * This component will hard refresh the page if the user has navigated to a new page
 * and their client is older than MAX_CLIENT_AGE
 *
 **/
export function PageRefresher() {
  const location = useLocation();

  // Set the page age on first load
  useEffect(() => {
    setLastHardRefresh();
  }, []);

  useEffect(() => {
    const lastPageLoad = sessionStorage.getItem(PAGE_AGE_KEY);
    if (!lastPageLoad) {
      setLastHardRefresh();
      return;
    }
    const now = new Date().getTime();

    // Perform a hard refresh of the page if its older than MAX_CLIENT_AGE
    if (lastPageLoad && now - parseInt(lastPageLoad) > MAX_CLIENT_AGE) {
      window.location.reload();
    }
  }, [location]);

  return null;
}
