import { useExperimentVariant } from "~/analytics/posthog";
import { FeatureFlag } from "~/types/enums";

/**
 * Checks if the user is in the show import error report experiment
 * @returns {boolean} If the user is in the show import error report experiment
 */
export function useInImportErrorReportExperiment() {
  return useExperimentVariant(
    FeatureFlag.ImportErrorReport,
    "import-error-report",
  );
}
