import styled from "styled-components/macro";

export const TxTableOverflowHorizontalScroll = styled.div<{
  autoScrollableX: boolean;
  scrollPoint: number;
}>`
  overflow: auto;
  @media (min-width: ${(props) =>
      props.autoScrollableX ? 0 : props.scrollPoint}px) {
    overflow: visible;
  }
`;
