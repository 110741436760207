import { ActionTableGridArea } from "~/components/transactions/action-row/enums";

export function transactionActionRowColumns() {
  return [
    ActionTableGridArea.Type,
    ActionTableGridArea.Time,
    ActionTableGridArea.From,
    ActionTableGridArea.To,
    ActionTableGridArea.Fee,
    ActionTableGridArea.Value,
    ActionTableGridArea.Gain,
    ActionTableGridArea.Actions,
  ].filter(Boolean) as ActionTableGridArea[];
}

export function transactionPageLedgerActionRowColumns() {
  return [
    ActionTableGridArea.Type,
    ActionTableGridArea.Time,
    ActionTableGridArea.From,
    ActionTableGridArea.To,
    ActionTableGridArea.Fee,
    ActionTableGridArea.AccountBalanceOut,
    ActionTableGridArea.AccountBalanceIn,
    ActionTableGridArea.OverallBalance,
    ActionTableGridArea.Actions,
  ].filter(Boolean) as ActionTableGridArea[];
}

export function reconUncategorisedActionRowColumns() {
  return [
    ActionTableGridArea.Type,
    ActionTableGridArea.Time,
    ActionTableGridArea.From,
    ActionTableGridArea.To,
    ActionTableGridArea.Fee,
    ActionTableGridArea.Value,
    ActionTableGridArea.Actions,
  ].filter(Boolean) as ActionTableGridArea[];
}
