import ArticleIcon from "@mui/icons-material/Article";

import { BulkEditPartyChipIcon } from "~/components/transactions/command-palette/BulkEditPartyChipIcon";
import { DualChip } from "~/components/transactions/command-palette/views/DualChip";
import { useLang } from "~/redux/lang";
import { type ErpAccount } from "~/redux/types";
import { type PartyDetails } from "~/services/transactions";

/**
 * The chips at the top of bulk change cash account
 * @param param0
 * @returns
 */
export const CashAccountTopSection = ({
  party,
  cashAccount,
}: {
  party?: PartyDetails;
  cashAccount?: ErpAccount;
}) => {
  const lang = useLang();
  return (
    <DualChip
      leftIcon={party ? <BulkEditPartyChipIcon party={party} /> : null}
      leftLabel={party ? party.displayName : lang.txTable.account}
      rightLabel={
        cashAccount
          ? cashAccount.name
          : lang.txTable.expansionPanel.chartOfAccounts.cashAccount
      }
      rightIcon={
        cashAccount ? (
          <ArticleIcon sx={{ height: "1rem", width: "1rem" }} />
        ) : undefined
      }
    />
  );
};
