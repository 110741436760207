import qs from "query-string";

import { get, post } from "~/services/core";
import {
  type CsvDownloadResponse,
  type ExtraCsvParams,
  type ObjectIdString,
  type SyncReturnPayload,
} from "~/types/index";

function getSaveCsvParams(
  exchangeName: string,
  files: File[],
  timezone: string,
  manual?: boolean,
  extraCsvParams?: ExtraCsvParams,
) {
  const data = new FormData();
  const urlParams = new URLSearchParams();

  urlParams.append("exchangeName", exchangeName);
  urlParams.append("timezone", timezone);

  files.forEach((file) => {
    data.append(`${exchangeName}-${file.name}`, file, file.name);
  });

  if (manual) {
    urlParams.append("manual", "true");
  }

  if (extraCsvParams?.currency) {
    urlParams.append("currency", extraCsvParams.currency);
  }

  if (extraCsvParams?.address) {
    urlParams.append("address", extraCsvParams.address);
  }

  if (extraCsvParams?.dateFormat) {
    urlParams.append("dateFormat", extraCsvParams.dateFormat);
  }

  if (extraCsvParams?.importFromDate) {
    urlParams.append(
      "importFromDate",
      extraCsvParams.importFromDate.toISOString(),
    );
  }

  const tickers = extraCsvParams?.tickers;
  const tickersString = tickers?.length
    ? `&${qs.stringify(
        {
          tickerId: tickers.map((i) => i.id),
          tickerSymbol: tickers.map((i) => i.symbol),
        },
        { arrayFormat: "bracket" },
      )}`
    : "";

  return {
    data,
    query: `${urlParams.toString()}${tickersString}`,
  };
}

export async function saveCsv(
  exchangeName: string,
  files: File[],
  timezone: string,
  manual?: boolean,
  extraCsvParams?: ExtraCsvParams,
) {
  const params = getSaveCsvParams(
    exchangeName,
    files,
    timezone,
    manual,
    extraCsvParams,
  );

  const root = "/v2/csvs";
  const path = `${root}?${params.query}`;

  return post<string[]>(path, params.data, {
    stringify: false,
    defaultContentType: true,
  });
}

export async function syncCSV(id: ObjectIdString) {
  const link = `/v2/csvs/${id}/sync`;
  return post<SyncReturnPayload>(link, {});
}

/**
 * Downloads the original CSV file that was uploaded
 * @param id The import ID of the CSV file
 * @returns Object containing the signed URL and filename for download
 */
export async function downloadCSV(id: ObjectIdString) {
  const path = `/v2/csvs/${id}/download`;
  return get<CsvDownloadResponse>(path);
}
