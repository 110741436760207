import { useCallback, useState } from "react";

import { useInterval } from "~/hooks/useInterval";

/**
 * Progress for use with a fake progress bar
 */
export function useFakeProgress({
  isDone,
  increment = 0.06,
  incrementInterval = 3000,
  initialProgress = 5,
  stalledQuantity = 90,
  onProgressChange,
}: {
  /** Done when we should animate to 100% */
  isDone: boolean;
  increment?: number;
  initialProgress?: number;
  incrementInterval?: number;
  stalledQuantity?: number;
  onProgressChange?: (progress: number) => void;
}) {
  const [progress, setProgress] = useState(initialProgress);
  const updateProgress = useCallback(
    (newProgress: number) => {
      const clampedProgress = clampProgress(newProgress);
      setProgress(clampedProgress);
      onProgressChange?.(clampedProgress);
    },
    [onProgressChange],
  );

  const updateProgressFromCallback = useCallback(
    (getNewProgress: (current: number) => number) => {
      setProgress((current) => {
        const newProgress = getNewProgress(current);
        const clampedProgress = clampProgress(newProgress);
        onProgressChange?.(clampedProgress);
        return clampedProgress;
      });
    },
    [onProgressChange],
  );

  // When its done, move it to 100%
  const [prevIsDone, setPrevIsDone] = useState(isDone);
  if (isDone !== prevIsDone) {
    setPrevIsDone(isDone);
    if (isDone) {
      updateProgress(100);
    } else {
      // changed from done to not done, so reset the progress
      updateProgress(initialProgress);
    }
  }

  // Incrementing code
  useInterval(
    () => {
      // add increment% every delay second
      updateProgressFromCallback((progress) => {
        if (progress === 100) {
          return progress;
        }
        return progress + (stalledQuantity - progress) * increment;
      });
    },
    isDone ? undefined : incrementInterval,
  );

  return { value: progress };
}

/**
 * Clamps progress between 0 and 100
 */
function clampProgress(progress: number, min = 0, max = 100) {
  return Math.min(Math.max(progress, min), max);
}
