import { ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import fullyReconcileBg from "~/assets/fully-reconciled-background.png";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { Calco, type CalcoVariants } from "~/components/ui/calco/Calco";
import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

export const FullyReconciledComponent = ({
  calcoType,
  title,
  subTitle,
  nextLink,
  buttonLang,
}: {
  calcoType: CalcoVariants;
  title: string;
  nextLink: Links | (() => void);
  buttonLang: string;
  subTitle?: string;
}) => {
  const navigate = useNavigate();
  return (
    <Container>
      <StyledBox>
        <Calco type={calcoType} width="228px" height="269px" />
        <Typography variant="Metropolis/Header/H4">{title}</Typography>
        {subTitle ? (
          <Typography variant="Metropolis/Body/Regular">{subTitle}</Typography>
        ) : null}
        <PrimaryButton
          onClick={() => {
            if (typeof nextLink === "function") {
              nextLink();
              return;
            }
            navigate(nextLink);
          }}
          sx={{ mt: "1.5rem" }}
          endIcon={<ArrowForward />}
        >
          {buttonLang}
        </PrimaryButton>
      </StyledBox>
    </Container>
  );
};

export const FullyReconciledState = ({
  hasGeneratedAnyReconciliationIssues,
}: {
  hasGeneratedAnyReconciliationIssues: boolean;
}) => {
  const lang = useLang();
  if (hasGeneratedAnyReconciliationIssues) {
    return (
      <FullyReconciledComponent
        calcoType="moon"
        title={lang.reconciliation.fullyReconciledState.successMessage}
        subTitle={lang.reconciliation.fullyReconciledState.successSubMessage}
        nextLink={Links.Report}
        buttonLang={lang.reconciliation.fullyReconciledState.ctaText}
      />
    );
  }
  return (
    <FullyReconciledComponent
      calcoType="moon"
      title={lang.reconciliation.fullyReconciledState.neutralMessage}
      subTitle={lang.reconciliation.fullyReconciledState.disclaimer}
      nextLink={Links.Report}
      buttonLang={lang.reconciliation.fullyReconciledState.ctaText}
    />
  );
};

const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.tokens.elevation.low};
  background-image: url(${fullyReconcileBg});
  background-size: initial;
  background-repeat: no-repeat;
  width: 37.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 1.5rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background-size: contain;
  margin-top: 6rem;
  padding: 1rem;
`;

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 80vw;
`;
