import { Box, Popover, Stack, Typography } from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { getTrimmedStack } from "~/components/imports-v2/helpers";
import { ActionHeaderCell } from "~/components/transactions/action-row/ActionHeaderCell";
import { ActionRowCell } from "~/components/transactions/action-row/ActionRowCell";
import { ActionRowHover } from "~/components/transactions/action-row/ActionRowHover";
import { EnDash } from "~/components/transactions/action-row/EnDash";
import { ActionTableGridArea } from "~/components/transactions/action-row/enums";
import { getAccounts } from "~/components/transactions/action-row/getAccounts";
import {
  ActionRowFirstLineBox,
  Overflower,
} from "~/components/transactions/action-row/Overflower";
import { scrollColumnSizes } from "~/components/transactions/action-row/TransactionActionRowLayout";
import { TxAmount } from "~/components/transactions/action-row/TxAmount";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { Side } from "~/types/enums";
import { type ActionRow, type TransactionDetails } from "~/types/index";

export function ActionHeaderSide({ side }: { side: Side }) {
  const lang = useLang();
  const gridArea =
    side === Side.From ? ActionTableGridArea.From : ActionTableGridArea.To;
  const headerText = side === Side.From ? lang.txTable.from : lang.txTable.to;

  return (
    <Box style={{ gridArea }}>
      <ActionHeaderCell>{headerText}</ActionHeaderCell>
    </Box>
  );
}

const MAX_ROWS_PER_SIDE = 5;

function getRenderedTxAmounts({
  row,
  side,
}: ActionRowCellProps & { side: Side }): JSX.Element[] {
  const amounts = side === Side.From ? row.outgoing : row.incoming;
  const summaries =
    side === Side.From
      ? row.outgoingGroupSummaries?.summaries
      : row.incomingGroupSummaries?.summaries;

  const { shownItems: shownAmounts, otherItemsCount: remainingAmounts } =
    getTrimmedStack(summaries || amounts, MAX_ROWS_PER_SIDE);
  const renderedAmounts =
    shownAmounts.length > 0
      ? shownAmounts.map((tx) => {
          return (
            <TxAmount
              row={row}
              key={tx.currencyIdentifier.id}
              tx={tx}
              side={side}
              isTransferLike={false}
            />
          );
        })
      : [
          <ActionRowFirstLineBox key="empty">
            <EnDash />
          </ActionRowFirstLineBox>,
        ];

  if (remainingAmounts !== 0) {
    const overflowAmounts = amounts.slice(MAX_ROWS_PER_SIDE);
    renderedAmounts.push(
      <AmountsOverflowCell
        row={row}
        side={side}
        amounts={overflowAmounts}
        remainingAmountsText={String(remainingAmounts)}
      />,
    );
  }
  return renderedAmounts;
}

export function ActionRowSide({
  row,
  side,
  gridAreaOverride,
  disablePointerEvents = false,
}: ActionRowCellProps & { side: Side }) {
  const amounts = side === Side.From ? row.outgoing : row.incoming;
  const renderedAmounts = getRenderedTxAmounts({ row, side });

  const accounts = getAccounts({
    row,
    side,
    txs: side === Side.From ? row.outgoing : row.incoming,
    otherSideTxs: side === Side.From ? row.incoming : row.outgoing,
  });

  const otherSideAccounts = getAccounts({
    row,
    side: side === Side.From ? Side.To : Side.From,
    txs: side === Side.From ? row.incoming : row.outgoing,
    otherSideTxs: side === Side.From ? row.outgoing : row.incoming,
  });

  // if each side has only one account, and they are the same, then we don't
  // need to show the same account twice
  // check every account in the array
  const areAccountsTheSame = accounts.every((_, index) => {
    return accounts[index].key === otherSideAccounts[index].key;
  });

  const shouldRenderAccounts = !(areAccountsTheSame && amounts.length === 0);

  return (
    <ActionRowCell
      id={
        gridAreaOverride ??
        (side === Side.From ? ActionTableGridArea.From : ActionTableGridArea.To)
      }
      disablePointerEvents={disablePointerEvents}
    >
      <Overflower>
        <Stack direction="column">
          {renderedAmounts}
          {shouldRenderAccounts ? accounts : null}
        </Stack>
      </Overflower>
    </ActionRowCell>
  );
}

function AmountsOverflowCell({
  row,
  side,
  amounts,
  remainingAmountsText,
}: {
  row: ActionRow;
  side: Side;
  amounts: TransactionDetails[];
  remainingAmountsText: string;
}) {
  const { tokens } = useDesign();
  const lang = useLang().txTable.txRow;

  const popupState = usePopupState({
    variant: "popover",
    popupId: `row-overflow-${side}-${row._id}`,
  });

  return (
    <>
      <Overflower
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box display="flex" flexWrap="wrap" gap="0.5rem" alignItems="center">
          <ActionRowHover
            {...bindTrigger(popupState)}
            display="flex"
            flexWrap="nowrap"
            gap="0.5rem"
            sx={{
              "& svg": {
                visibility: "hidden",
              },
              cursor: "pointer",
              borderRadius: "0.25rem",
              "&:hover": {
                "& svg": {
                  visibility: "visible",
                },
              },
            }}
          >
            <Typography
              variant="Metropolis/Body/Regular"
              fontFamily={tokens.fontFamilies.numeric}
              color={tokens.text.default}
            >
              {lang.overflowCurrencies({ count: remainingAmountsText })}
            </Typography>
          </ActionRowHover>
        </Box>
      </Overflower>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Stack
          maxHeight="20rem"
          overflow="auto"
          bgcolor={tokens.background.neutral.lowest.default}
          p="0.25rem"
          maxWidth={scrollColumnSizes[ActionTableGridArea.From]}
        >
          {amounts.map((tx) => {
            return (
              <Box minHeight="1.75rem" key={tx._id}>
                <TxAmount
                  row={row}
                  tx={{ ...tx, txIds: [tx._id] }}
                  side={side}
                  isTransferLike={false}
                />
              </Box>
            );
          })}
        </Stack>
      </Popover>
    </>
  );
}
