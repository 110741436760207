import { AppBar, Toolbar } from "@mui/material";
import { type CSSProperties } from "react";

import { FixedMobileAppBarPosition } from "~/components/onboarding-v2/enums";
import { useDesign } from "~/hooks/useTheme";

export const FixedMobileAppBar = ({
  position,
  justifyContent,
  children,
  style,
}: {
  position: FixedMobileAppBarPosition;
  justifyContent: CSSProperties["justifyContent"];
  children: React.ReactNode;
  style?: CSSProperties;
}) => {
  const { tokens } = useDesign();
  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{
        top: position === FixedMobileAppBarPosition.TOP ? 0 : "auto",
        bottom: position === FixedMobileAppBarPosition.BOTTOM ? 0 : "auto",
        boxShadow: "none",
        backgroundColor: tokens.elevation.default,
        ...style,
      }}
    >
      <Toolbar
        sx={{
          justifyContent,
          borderTop:
            position === FixedMobileAppBarPosition.BOTTOM
              ? `1px solid ${tokens.border.neutral.default}`
              : undefined,
          borderBottom:
            position === FixedMobileAppBarPosition.TOP
              ? `1px solid ${tokens.border.neutral.default}`
              : undefined,
          fontSize: "0.75rem",
        }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};
