import { CurrencySourcePlatform } from "@ctc/types";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  type OutlinedInputProps,
  Skeleton,
  Stack,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { type FilterOptionsState } from "@mui/material/useAutocomplete";
import makeStyles from "@mui/styles/makeStyles";
import kebabCase from "lodash/kebabCase";
import type * as React from "react";
import { useMemo, useState } from "react";
import styled from "styled-components/macro";

import {
  ListboxComponent,
  renderGroup,
} from "~/components/transactions/AutocompleteListboxComponent";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { useDesign } from "~/hooks/useTheme";
import { displayCurrencyName, middleTrim } from "~/lib/index";
import { useCurrencySearchResults } from "~/state/currencies";
import {
  type CurrencyIdentifier,
  type CurrencyIdentifierWithContractAddresses,
} from "~/types/index";

export const filter = createFilterOptions<
  CurrencyIdentifier | CurrencyIdentifierWithContractAddresses
>({
  // limit: 200,
  stringify: (option) =>
    `${option.symbol}_${option.name}_${
      option.contractAddress
    }_${// if its an "all currency", include any contract ids
    option.platforms?.map((p) => p.contractAddress).join("_")}_${
      "contractAddresses" in option
        ? option?.contractAddresses?.map((ca) => ca).join("_")
        : ""
    }`,
});

const useStyles = makeStyles({
  popper: { minWidth: "300px !important" },
});

export function currencyGroupSort(
  a: CurrencyIdentifier,
  b: CurrencyIdentifier,
) {
  if (
    a.source === CurrencySourcePlatform.Fiat &&
    b.source !== CurrencySourcePlatform.Fiat
  ) {
    return -1;
  }
  if (
    a.source !== CurrencySourcePlatform.Fiat &&
    b.source === CurrencySourcePlatform.Fiat
  ) {
    return 1;
  }
  return 0;
}

/**
 * A virtualised currency combo box
 */

export const CurrencyComboBox = ({
  id,
  value,
  helperText,
  setValue,
  size = "medium",
  disabled = false,
  hideLabel = false,
  setHideLabel = () => {},
  isBulkSelectActionBar = false,
  onChange,
  expandOnFocus,
  options,
  autocomplete = {},
  ...outlinedInputProps
}: {
  id: string;
  value: CurrencyIdentifier | string | null;
  helperText?: string;
  options?: CurrencyIdentifier[];
  setValue: (value: CurrencyIdentifier | null) => void;
  size?: "small" | "medium";
  disabled?: boolean;
  hideLabel?: boolean;
  setHideLabel?: (value: boolean) => void;
  expandOnFocus?: boolean;
  isBulkSelectActionBar?: boolean;
  autocomplete?: {
    clearIcon?: boolean;
    freeSolo?: boolean;
  };
} & OutlinedInputProps) => {
  const classes = useStyles();
  const [input, setInput] = useState<string | undefined>();

  const currenciesSearchQuery = useCurrencySearchResults(input);
  const {
    data: { currencies },
    isSearching: isSearchingQuery,
  } = currenciesSearchQuery;

  const isSearching = !options && isSearchingQuery;

  const optionsResolved = useMemo(() => {
    return (options || currencies).sort(currencyGroupSort);
  }, [options, currencies]);

  const logoSize = size === "small" ? 20 : undefined;
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { tokens } = useDesign();
  const Input = isBulkSelectActionBar ? StyledOutlinedInput : OutlinedInput;

  function getManualCurrencyIdentifier(inputValue: string): CurrencyIdentifier {
    return {
      id: `${kebabCase(inputValue).toLowerCase()}-manual`,
      symbol: kebabCase(inputValue).toUpperCase(),
      source: CurrencySourcePlatform.Manual,
      name: inputValue,
    };
  }

  return (
    <Autocomplete
      clearIcon={autocomplete.clearIcon}
      isOptionEqualToValue={(option, value: CurrencyIdentifier): boolean => {
        return typeof value === "string"
          ? option.symbol === value
          : option.id === value.id;
      }}
      filterOptions={(
        options: CurrencyIdentifier[],
        params: FilterOptionsState<CurrencyIdentifier>,
      ) => {
        const filtered = filter(options, params);

        // add skeletons when all currencies is loading
        if (isSearching) {
          filtered.push({
            id: "skeleton",
            symbol: "skeleton",
            source: "skeleton" as CurrencySourcePlatform,
            name: "skeleton",
          });
          filtered.push({
            id: "skeleton",
            symbol: "skeleton",
            source: "skeleton" as CurrencySourcePlatform,
            name: "skeleton",
          });
        }

        if (autocomplete.freeSolo === false) return filtered;

        if (params.inputValue !== "" && params.inputValue.length >= 2) {
          const id = getManualCurrencyIdentifier(params.inputValue);
          filtered.push(id);
        }

        return filtered;
      }}
      size={size}
      disabled={disabled}
      loading={currenciesSearchQuery?.isLoading}
      fullWidth
      freeSolo
      classes={{ popper: classes.popper }}
      value={value}
      sx={{
        width: expandOnFocus && isFocused ? "16rem" : "100%",
        transition: "50ms width",
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      onOpen={() => {
        // Because we adjust the size of the box on click, the autocomplete
        // is also showing the popover on click, and its coming in as the
        // small size.
        // the timeout means we wait for after that render
        setTimeout(() => {
          setIsOpen(true);
        }, 100);
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          const id = getManualCurrencyIdentifier(newValue);
          setValue(id);
        } else {
          setValue(newValue);
        }
      }}
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={id}
      options={optionsResolved}
      getOptionLabel={(option: CurrencyIdentifier | string) => {
        return typeof option === "string" ? option : option.symbol;
      }}
      groupBy={(option) =>
        option.source === CurrencySourcePlatform.Fiat ? "Fiat" : "Crypto"
      }
      renderGroup={renderGroup}
      renderOption={(autocompleteProps, option) => {
        const contractAddresses: string[] =
          option.platforms?.map((p) => p.contractAddress) ?? [];
        if ("contractAddresses" in option) {
          contractAddresses.push(...(option.contractAddresses as any));
        }
        if (option.contractAddress) {
          contractAddresses.push(option.contractAddress);
        }

        const contractAddress =
          contractAddresses.length > 0 ? contractAddresses[0] : undefined;

        return (
          <Box
            {...autocompleteProps}
            component="li"
            onClick={
              autocompleteProps["aria-disabled"]
                ? undefined
                : autocompleteProps.onClick
            }
            className=""
            sx={{
              background: tokens.background.neutral.default,
              "&.Mui-focused, &:hover": {
                backgroundColor: tokens.background.neutral.hover,
              },
            }}
            display="flex"
            alignItems="center"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              {option.source === ("skeleton" as CurrencySourcePlatform) ? (
                <Box width="100%" p="0.375rem 0.75rem">
                  <Skeleton width="100%" height="2rem" />
                </Box>
              ) : (
                <>
                  <Box display="flex" alignItems="center" gap="0.25rem">
                    <CurrencyLogo currency={option} />
                    {displayCurrencyName(option, false)}
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    gap="0.125rem"
                    flexWrap="wrap"
                  >
                    {option.nftId ? (
                      <Box sx={{ marginLeft: "auto !important" }}>
                        <Chip
                          label={`#${middleTrim(option.nftId)}`}
                          size="small"
                          sx={{
                            fontSize: "0.625rem",
                            fontWeight: 500,
                          }}
                        />
                      </Box>
                    ) : null}
                    {contractAddress ? (
                      <Box sx={{ marginLeft: "auto !important" }}>
                        <Chip
                          label={middleTrim(contractAddress)}
                          size="small"
                          sx={{
                            fontSize: "0.625rem",
                            fontWeight: 500,
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </>
              )}
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => {
        const { InputProps, InputLabelProps, inputProps, ...otherParams } =
          params;
        return (
          <FormControl
            fullWidth
            error={outlinedInputProps.error}
            required={outlinedInputProps.required}
            size={size}
          >
            {((!hideLabel && !value && isBulkSelectActionBar) ||
              !isBulkSelectActionBar) && (
              <StyledInputLabel
                variant="outlined"
                {...InputLabelProps}
                isBulkSelectActionBar={isBulkSelectActionBar}
              >
                {outlinedInputProps.label}
              </StyledInputLabel>
            )}
            <Input
              {...otherParams}
              {...outlinedInputProps}
              {...InputProps}
              type="search"
              sx={{
                backgroundColor: tokens.background.input.default,
                ...(outlinedInputProps.sx ? outlinedInputProps.sx : {}),
              }}
              required={false}
              onFocus={() => {
                setHideLabel(true);
              }}
              onBlur={() => {
                setHideLabel(false);
              }}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              {...(value && typeof value !== "string" && value.symbol
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyLogo
                          currency={value}
                          width={logoSize}
                          height={logoSize}
                          margin="0.5rem 0 0.5rem 0.5rem"
                        />
                      </InputAdornment>
                    ),
                  }
                : null)}
              inputProps={{ ...inputProps, ...outlinedInputProps.inputProps }}
            />
            {helperText ? (
              <FormHelperText required={outlinedInputProps.required}>
                {helperText || ""}
              </FormHelperText>
            ) : null}
          </FormControl>
        );
      }}
      {...autocomplete}
    />
  );
};

const StyledInputLabel = styled(({ isBulkSelectActionBar, ...props }) => (
  <InputLabel {...props} />
))`
  ${({ isBulkSelectActionBar, theme }) =>
    isBulkSelectActionBar &&
    `&& {
        color: ${theme.tokens.text.default};
      }
      &.Mui-focused {
        border-color: ${theme.tokens.border.brand} !important;
      }`}
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  && {
    color: ${({ theme }) => theme.tokens.text.default};
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.default};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.tokens.border.neutral.default};
  }
  &.Mui-focused > fieldset {
    border-color: ${({ theme }) => theme.tokens.border.brand} !important;
  }
  &:hover:not(.Mui-focused) > fieldset {
    border: 1px ${({ theme }) => theme.tokens.border.neutral.highest} solid;
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.08),
      0 2px 5px rgba(31, 41, 55, 0.12),
      inset 0 -1px 1px rgba(0, 0, 0, 0.12);
  }
` as typeof OutlinedInput;
