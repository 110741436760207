import { Box, DialogContentText, TextField } from "@mui/material";
import { useState } from "react";

import {
  GeneralDialog,
  type GeneralDialogProps,
} from "~/components/ui/GeneralDialog";

export const TextConfirmationModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  title,
  actionText,
  text,
  confirmationText,
  confirmationPrompt,
  pending,
  ...props
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  title: string;
  actionText: string;
  text: string;
  confirmationText: string;
  confirmationPrompt: string;
  pending: boolean;
  critical?: boolean;
} & Omit<GeneralDialogProps, "children">) => {
  const [confirmationInput, setConfirmationInput] = useState("");

  return (
    <GeneralDialog
      title={title}
      actionText={actionText}
      isOpen={isOpen}
      handleClose={handleClose}
      handleAction={handleSubmit}
      disableAction={confirmationInput !== confirmationText}
      pending={pending}
      {...props}
    >
      <DialogContentText>{text}</DialogContentText>
      <br />
      <DialogContentText>{confirmationPrompt}</DialogContentText>

      <Box
        display="flex"
        width="100%"
        maxWidth="22rem"
        justifyContent="space-between"
        mt="0.5rem"
        mb="0.5rem"
      >
        <TextField
          onChange={(e) => {
            setConfirmationInput(e.target.value);
          }}
          variant="outlined"
          size="small"
          placeholder={confirmationText}
          fullWidth
        />
      </Box>
    </GeneralDialog>
  );
};
