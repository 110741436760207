import { type Country } from "@ctc/types";

import { getCapitalGainsTooltipLang } from "~/components/report/helpers/lang";
import { type Translation } from "~/lang/index";
import { round } from "~/lib/index";
import {
  type ReportSummary,
  shouldReplaceCGTWithPNL,
  type TaxSettings,
} from "~/types/index";

export type GainLossEntryType = {
  total: number | null;
  title: string;
  tooltip?: string;
};

export type GainLossData = {
  short: GainLossEntryType;
  long?: GainLossEntryType;
  loss: GainLossEntryType;
};

export type IncomeExpenseData = {
  ordinaryIncome: GainLossEntryType;
  derivativeIncome: GainLossEntryType;
  expense: GainLossEntryType;
};

export const getGainLossData = (
  lang: Translation,
  summaryData: ReportSummary | null | void,
  taxSettings?: TaxSettings,
  country?: Country,
): GainLossData => {
  const isNoCGT = shouldReplaceCGTWithPNL(country);
  return {
    short: {
      total: summaryData ? summaryData.capitalGains.shortTermGains : null,
      title: isNoCGT
        ? lang.report.shortTermGainsNoCGT
        : lang.report.shortTermGains,
      tooltip: getCapitalGainsTooltipLang(
        lang,
        taxSettings?.longTermThreshold,
        country,
      ),
    },
    long: taxSettings?.longTermThreshold
      ? {
          // the NaN makes the column nicely return "N/A" if the value doesn't exist
          total: summaryData ? summaryData.capitalGains.longTermGains : null,
          title: isNoCGT
            ? lang.report.longTermGainsNoCGT
            : lang.report.longTermGains,
          tooltip: isNoCGT
            ? lang.report.tooltips.longTermThresholdNoCGT({
                longTermThreshold: taxSettings?.longTermThreshold ?? 0,
              })
            : lang.report.tooltips.longTermThreshold({
                longTermThreshold: taxSettings?.longTermThreshold ?? 0,
              }),
        }
      : undefined,
    loss: {
      total: summaryData ? summaryData.capitalGains.totalLosses : null,
      title: isNoCGT ? lang.report.totalLossesNoCGT : lang.report.totalLosses,
      tooltip: isNoCGT
        ? lang.report.tooltips.capitalLossesNoCGT
        : lang.report.tooltips.capitalLosses,
    },
  };
};

export const getIncomeExpenseData = (
  lang: Translation,
  summaryData: ReportSummary | null | void,
): IncomeExpenseData => {
  return {
    ordinaryIncome: {
      total: summaryData
        ? round(summaryData.totalOrdinaryIncome || 0, 2)
        : null,
      title: lang.report.ordinaryIncome,
      tooltip: lang.report.tooltips.miscellaneousIncome,
    },
    derivativeIncome: {
      total: summaryData
        ? round(summaryData.totalDerivativeIncome || 0, 2)
        : null,
      title: lang.report.derivativeTradingIncome,
      tooltip: lang.report.tooltips.derivativeTradingIncome,
    },
    expense: {
      total: summaryData
        ? summaryData.expenses
          ? round(
              Object.values(summaryData.expenses).reduce(
                (acc, curr) => acc + curr || 0,
                0,
              ),
              2,
            )
          : 0
        : null,
      title: lang.report.miscellaneousExpenses,
      tooltip: lang.report.tooltips.miscellaneousExpenses,
    },
  };
};
