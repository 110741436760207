import {
  Box,
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { DialogExitBar } from "~/components/ui/DialogExitBar";
import { displayMessage } from "~/components/ui/Toaster";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { Logo } from "~/components/user/UserDetails";
import { AccountantClientWithCrypto } from "~/constants/enums";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useRemoveHobbyistPlusExperiment } from "~/hooks/useRemoveHobbyistPlusExperiment";
import { useDesign } from "~/hooks/useTheme";
import { useIsProcessingPayment } from "~/redux/accountant";
import { loadUser, useUser, useUserHasPaid } from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import { type Data } from "~/services/core";
import {
  upgradeFreeUserToAccountant,
  upgradePaidUserToAccountant,
} from "~/services/subscription";
import { AccountantPortalStep, DisplayMessage, Links } from "~/types/enums";

function WelcomeAccountantPortalStep({
  handleSubmit,
}: {
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const accountantPortal = lang.accountantPortal;
  const welcomeInfo = Object.values(accountantPortal.steps.welcome.info);

  return (
    <Box p="1rem 3rem">
      <Box
        width="100%"
        p="1.5rem 1rem"
        mb="1.5rem"
        sx={{
          backgroundColor: tokens.background.brand.default,
        }}
        borderRadius="4px"
      >
        {welcomeInfo.map(({ title, subtitle }) => (
          <Box key={`${title}-${subtitle}`} mb="1rem">
            <Typography variant="Metropolis/Body/Bold" sx={{ mb: "0.5rem" }}>
              {title}
            </Typography>
            <Typography variant="Metropolis/Body/Light">{subtitle}</Typography>
          </Box>
        ))}
      </Box>
      <PrimaryButton
        fullWidth
        sx={{
          height: "3.25rem",
          fontSize: "1.125rem",
          mb: "3rem",
          fontWeight: 500,
        }}
        onClick={handleSubmit}
      >
        {accountantPortal.createAccount}
      </PrimaryButton>
    </Box>
  );
}

function AccountantInfoInput({
  skipUserMigration,
}: {
  skipUserMigration?: boolean;
}) {
  const [accountantClientsWithCrypto, setAccountantClientsWithCrypto] =
    useState<AccountantClientWithCrypto | null>(null);
  const [accountantCompanyName, setAccountantCompanyName] = useState("");
  const [accountantContactName, setAccountantContactName] = useState("");
  const lang = useLang();
  const dispatch = useDispatch();
  const isPaidPlan = useUserHasPaid();
  const accountantPortal = lang.accountantPortal.steps.accountantInfo;
  const defaultClient = lang.accountant.clientDefault;
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const { removeHobbyistPlusExperiment } = useRemoveHobbyistPlusExperiment();

  const handleSubmitRes = (res: Data<string>) => {
    if (res.error) {
      displayMessage({
        message: lang.accountantPortal.errorMessage,
        type: DisplayMessage.Error,
      });
    } else {
      dispatch(loadUser(LoadUserType.Login));
      navigate(Links.Clients);

      // If user change to Accountant plan, remove them from the experiment
      removeHobbyistPlusExperiment();
    }
  };

  const handleSubmit = async (e: any) => {
    if (!accountantClientsWithCrypto) {
      return;
    }
    e.preventDefault();
    setIsLoading(true);
    let res;
    if (isPaidPlan) {
      res = await upgradePaidUserToAccountant(
        defaultClient,
        accountantCompanyName,
        accountantContactName,
        accountantClientsWithCrypto,
      );
    } else {
      res = await upgradeFreeUserToAccountant(
        accountantCompanyName,
        accountantContactName,
        accountantClientsWithCrypto,
        skipUserMigration,
      );
    }
    handleSubmitRes(res);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box p="1rem 3rem" display="flex" flexDirection="column" gap="1rem">
        <StyledTypography variant="Metropolis/Body/Light">
          {accountantPortal.required}
        </StyledTypography>

        <FormControl fullWidth variant="outlined" required>
          <InputLabel id="accountant-client-label">
            {lang.auth.accountantClient}
          </InputLabel>
          <Select
            data-testid="accountant-client-select"
            id="accountant-client-label"
            required
            label={lang.auth.accountantClient}
            value={accountantClientsWithCrypto}
            onChange={(e) => {
              setAccountantClientsWithCrypto(
                e.target.value as AccountantClientWithCrypto,
              );
            }}
          >
            {Object.values(AccountantClientWithCrypto).map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          <TextField
            variant="outlined"
            name="accountantCompanyName"
            type="text"
            label={lang.auth.accountantCompanyName}
            required
            fullWidth
            onChange={(e) => {
              setAccountantCompanyName(e.target.value);
            }}
            value={accountantCompanyName}
          />
          <StyledTypography variant="Metropolis/Caption/Medium/Regular">
            {accountantPortal.accountantCompanyDescription}
          </StyledTypography>
        </Box>
        <Box>
          <TextField
            variant="outlined"
            name="accountantContactName"
            type="text"
            label={lang.auth.accountantContactName}
            required
            fullWidth
            onChange={(e) => {
              setAccountantContactName(e.target.value);
            }}
            value={accountantContactName}
          />
          <StyledTypography variant="Metropolis/Caption/Medium/Regular">
            {accountantPortal.nameDescription}
          </StyledTypography>
        </Box>
        <PrimaryButton
          fullWidth
          sx={{
            height: "3.25rem",
            fontSize: "1.125rem",
            mb: "5rem",
            mt: "1rem",
            fontWeight: 500,
          }}
          type="submit"
          color={loading ? "primary" : "secondary"}
          disabled={loading}
          startIcon={loading ? <CircularProgress size="1.125rem" /> : null}
        >
          {accountantPortal.continue}
        </PrimaryButton>
      </Box>
    </form>
  );
}

export function AccountantPortal({
  open,
  handleClose,
  hideExitBar,
  initialStep,
  skipUserMigration,
}: {
  open: boolean;
  handleClose: () => void;
  hideExitBar?: boolean;
  initialStep?: AccountantPortalStep;
  skipUserMigration?: boolean;
}) {
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const lang = useLang();

  const user = useUser();
  const stripe = useStripe();
  const elements = useElements();
  const isProcessingPayment = useIsProcessingPayment();
  const [activeStep, setActiveStep] = useState(
    initialStep || AccountantPortalStep.Welcome,
  );

  if (!user || !stripe || !elements) {
    return null;
  }

  const handleNext =
    (currentStep: AccountantPortalStep) =>
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      switch (currentStep) {
        case AccountantPortalStep.Welcome:
          setActiveStep(AccountantPortalStep.CompanyNameInput);
          break;
        case AccountantPortalStep.CompanyNameInput:
        default:
          break;
      }
    };

  const stepForm = (currentStep: AccountantPortalStep) => {
    switch (currentStep) {
      case AccountantPortalStep.Welcome:
        return (
          <WelcomeAccountantPortalStep handleSubmit={handleNext(currentStep)} />
        );
      case AccountantPortalStep.CompanyNameInput:
        return <AccountantInfoInput skipUserMigration={skipUserMigration} />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen={isMobile}
      disableEscapeKeyDown={hideExitBar}
    >
      {!hideExitBar && (
        <DialogExitBar
          title={lang.accountantPortal.dialogLabel}
          handleClose={handleClose}
          closeDisabled={isProcessingPayment}
        />
      )}
      <ResponsiveBox isMobile={isMobile} minHeight="35rem">
        <Box p="1rem 3rem">
          <Logo width="9.375rem" />
          <TitleText variant="Metropolis/Header/H4">{lang.accountantPortal.title}</TitleText>
          <Typography variant="Metropolis/Header/H5" sx={{ color: tokens.text.low }}>
            {lang.accountantPortal.subtitle}
          </Typography>
        </Box>
        {stepForm(activeStep)}
      </ResponsiveBox>
    </Dialog>
  );
}

const ResponsiveBox = styled(({ isMobile, ...props }) => <Box {...props} />)`
  position: relative;
  flex-grow: 1;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? "inherit" : "34rem")};
  background-color: ${({ theme }) => theme.tokens.elevation.default};
`;

const TitleText = styled(Typography)`
  && {
    font-size: 1.75rem;
    margin-top: 2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.tokens.text.high};
  }
`;

const StyledTypography = styled(Typography)`
  && {
    font-weight: 500;
  }
`;
