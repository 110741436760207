import EventEmitter from "node:events";

import { useEffect, useState } from "react";
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import { type SessionStorageKeys } from "~/constants/enums";
import { LocalStorageKey } from "~/constants/enums";
import { useUser } from "~/redux/auth";
import { Sort } from "~/types/enums";

// Create a singleton event emitter for localStorage changes
const storageEmitter = new EventEmitter();

export function useLocalStorageCountQueryParams() {
  const uuid = useUser()?.uid;
  const localStorageCountValue = localStorage.getItem(
    LocalStorageUserKeyGenerator(uuid, LocalStorageKey.RowsPerPage),
  );

  return useQueryParam(
    "count",
    withDefault(
      NumberParam,
      localStorageCountValue ? Number(localStorageCountValue) : undefined,
    ),
  );
}

export function useLocalStorageSortQueryParams(
  sortQueryOverride?: string | null,
) {
  const uuid = useUser()?.uid;
  const localStorageSortValue = localStorage.getItem(
    LocalStorageUserKeyGenerator(uuid, LocalStorageKey.SortOrder),
  );

  return useQueryParam(
    "sort",
    withDefault(
      StringParam,
      sortQueryOverride ?? (localStorageSortValue || Sort.DateDescending),
    ),
  );
}

export const LocalStorageUserKeyGenerator = (
  uuid: string | undefined = "",
  key: LocalStorageKey | SessionStorageKeys,
  contextKey?: string,
) => {
  return `${uuid}-${key}-${contextKey}` as const;
};

// Hook
// https://usehooks.com/useLocalStorage/
type Serializable =
  | string
  | number
  | boolean
  | null
  | undefined
  | { [key: string]: Serializable }
  | Serializable[];
export function useLocalStorage<InitialValue extends Serializable>(
  key: string,
  initialValue: InitialValue,
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? (JSON.parse(item) as InitialValue) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });
  // Listen for changes from other components
  useEffect(() => {
    const handleStorageChange = (newValue: InitialValue) => {
      setStoredValue(newValue);
    };

    storageEmitter.on(key, handleStorageChange);
    return () => {
      storageEmitter.off(key, handleStorageChange);
    };
  }, [key]);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (
    value: InitialValue | ((currentValue: InitialValue) => InitialValue),
  ) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        // Emit change event
        storageEmitter.emit(key, valueToStore);
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };
  const unsetValue = () => {
    try {
      // Remove from local storage by key
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(key);
      }
      // Update state
      setStoredValue(initialValue);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue, unsetValue] as const;
}
