import { SyncStatusPlatform } from "@ctc/types";
import {
  Delete,
  HourglassEmpty,
  OpenInNew,
  RestartAlt,
  Sync,
} from "@mui/icons-material";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Tooltip } from "@mui/material";
import moment from "moment-timezone";
import { useState } from "react";

import { type ApiImportListProps } from "~/components/imports/api/ApiImportList";
import { HardSyncConfirmationDialog } from "~/components/imports/HardSyncConfirmationDialog";
import {
  formatHardSyncTimestamp,
  getOptionFilterForOnboarding,
} from "~/components/imports/helpers";
import { ImportErrorReportingBox } from "~/components/imports/import-error-report-experiment/ImportErrorBox";
import { ImportIndicator } from "~/components/imports/ImportHeaderStatus";
import { ImportListItemContainer } from "~/components/imports/ImportListContainer";
import { ImportListItemWrapper } from "~/components/imports/ImportListItemWrapper";
import { LastSyncTimestampWrapper } from "~/components/imports/LastSyncTimestampWrapper";
import { MoreOptionsDropdown } from "~/components/imports-v2/MoreOptionsDialog";
import { SyncTableDialog } from "~/components/imports-v2/SyncHistoryDialog";
import {
  type ImportDrawerOptionsType,
  ImportOptionAction,
} from "~/components/imports-v2/types";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { CensoredTypography } from "~/components/ui/CensoredComponents";
import { displayMessage } from "~/components/ui/Toaster";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { InternalErrorCode } from "~/constants/enums";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useOAuthFlow } from "~/oauth";
import { useBestUser, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { useSyncKeyMutation } from "~/state/keys";
import { useGetLockPeriodEndDateIfExists } from "~/state/period";
import { DisplayMessage, FilterOperators, ImportType } from "~/types/enums";
import { type KeyCredentials, type KeyDetails } from "~/types/index";

export const OAuthKeyItem = ({
  exchangeLabel,
  exchangeName,
  keyDetails,
  handleDelete,
}: {
  exchangeLabel: string;
  exchangeName: string;
  keyDetails: KeyCredentials;
  handleDelete: ApiImportListProps["handleDelete"];
}) => {
  const isOnboarding = !!useUser()?.isOnboarding;
  const bestUser = useBestUser();
  const lang = useLang();
  const timezone = useTimezone();
  const syncKey = useSyncKeyMutation(exchangeName);
  const [isHardSyncOpen, setIsHardSyncOpen] = useState(false);
  const [isSyncHistoryOpen, setSyncHistoryOpen] = useState(false);
  const { syncStatus, error, errorIssue } = keyDetails;
  const isEmbedded = useIsEmbedded();

  const lockPeriodEndDate = useGetLockPeriodEndDateIfExists();
  const handleOAuthFlow = useOAuthFlow({
    exchangeLabel,
    uid: bestUser?.uid,
    isEmbedded,
  });

  // will default to the same behaviour if not currently available
  const isSyncing = syncStatus === SyncStatusPlatform.Pending;
  if (!bestUser) {
    return null;
  }

  function handleSync(apiDetails: KeyDetails, isHardSync: boolean) {
    if (!isSyncing) {
      syncKey.mutate({
        exchangeId: exchangeLabel,
        name: exchangeName,
        isHardSync,
        isOAuth: true,
        apiDetails,
      });
    } else {
      displayMessage({
        message: lang.sync.apiStillSyncing,
        type: DisplayMessage.Info,
      });
    }
  }

  const dateTime = keyDetails.lastImportedTxTimestamp
    ? moment.tz(keyDetails.lastImportedTxTimestamp, timezone).format("ll LTS")
    : lang.imports.bulkSyncTooltip.lastSyncTime;

  const softSyncTitle = `${lang.api.softSync({
    name: keyDetails.name || "",
  })} ${dateTime}`;

  const handleSoftSync = () => {
    handleSync(keyDetails, false);
  };

  const handleHardSync = () => {
    setIsHardSyncOpen(true);
  };

  const importIds = [
    `${ImportType.API}_${keyDetails.id}`, // TODO: Remove this one. It's only here for backwards compatibility.
    `${ImportType.OAuth}_${keyDetails.id}`,
  ];

  const handleViewTxs = () => {
    const link = getTransactionPageLink({
      state: {
        filter: {
          type: FilterOperators.And,
          rules: [
            {
              type: FilterOperators.ImportId,
              value: importIds,
            },
          ],
        },
      },
    });
    window.open(link, isEmbedded ? "_self" : "_blank", "noopener,noreferrer");
  };

  const options: ImportDrawerOptionsType[] = [
    // view txs
    ...(syncStatus === SyncStatusPlatform.Success
      ? [
          {
            id: ImportOptionAction.ViewTransactions,
            label: lang.reconciliation.viewTransactions,
            icon: <OpenInNew fontSize="small" />,
            handler: handleViewTxs,
          },
        ]
      : []),
    {
      id: ImportOptionAction.SyncHistory,
      disabled: isSyncing,
      tooltipTitle: lang.syncHistory.title,
      label: lang.syncHistory.title,
      icon: <HistoryIcon fontSize="small" />,
      handler: () => {
        setSyncHistoryOpen(true);
      },
    },
    // hard sync
    ...(!isSyncing
      ? [
          {
            id: ImportOptionAction.HardSync,
            tooltipTitle: lang.api.hardSync.titleOneAPI({
              name: keyDetails.name || "",
              date: formatHardSyncTimestamp(
                keyDetails.importFromDate,
                timezone,
                lang,
                lockPeriodEndDate,
              ),
            }),
            label: lang.imports.hardSync,
            icon: <RestartAlt fontSize="small" />,
            handler: handleHardSync,
          },
        ]
      : []),
    // delete
    {
      id: ImportOptionAction.Delete,
      label: lang.delete,
      icon: <Delete fontSize="small" />,
      handler: () => {
        handleDelete(keyDetails);
      },
    },
  ].filter(getOptionFilterForOnboarding(isOnboarding));

  return (
    <>
      <HardSyncConfirmationDialog
        open={isHardSyncOpen}
        title={lang.api.hardSync.confirmationOneAPI}
        setOpen={setIsHardSyncOpen}
        onHardSync={() => {
          handleSync(keyDetails, true);
        }}
        text={lang.api.hardSync.textOneAPI({
          name: exchangeName,
          date: formatHardSyncTimestamp(
            keyDetails.importFromDate,
            timezone,
            lang,
            lockPeriodEndDate,
          ),
        })}
      />
      <SyncTableDialog
        import={{
          ...keyDetails,
          completedAt: keyDetails.lastSyncComplete?.toISOString() || null,
          importType: ImportType.OAuth,
        }}
        isOpen={isSyncHistoryOpen}
        setOpen={setSyncHistoryOpen}
        name={exchangeName}
      />
      <ImportListItemContainer>
        <ImportListItemWrapper>
          {syncStatus === SyncStatusPlatform.Fail ? (
            <Box mr={1}>
              <ImportIndicator
                importType={ImportType.API}
                syncStatus={syncStatus}
                tooltipTitle={errorIssue}
                exchangeName={exchangeName}
              />
            </Box>
          ) : syncStatus ? (
            <LastSyncTimestampWrapper
              status={syncStatus}
              errorCode={keyDetails.syncDisplayError?.status}
              errorMsg={keyDetails.syncDisplayError?.message}
              lastCompleted={keyDetails.lastSyncComplete}
            >
              <Box component="span" display="inline-flex" mr={1}>
                <ImportIndicator
                  importType={ImportType.API}
                  syncStatus={syncStatus}
                  tooltipTitle={errorIssue}
                  exchangeName={exchangeName}
                />
              </Box>
            </LastSyncTimestampWrapper>
          ) : null}
          <CensoredTypography variant="Metropolis/Body/Light" noWrap>
            {exchangeName}
          </CensoredTypography>
          <Box ml="auto" display="flex" justifyContent="flex-end">
            <SecondaryButton
              sx={{
                alignSelf: "center",
                fontSize: "0.75rem",
                marginRight: "0.5rem",
              }}
              onClick={handleOAuthFlow}
            >
              {lang.api.reauthorize}
            </SecondaryButton>
            <TextIconButton
              size="small"
              onClick={handleSoftSync}
              disabled={syncStatus === SyncStatusPlatform.Pending}
              sx={{
                display:
                  error?.status === InternalErrorCode.expiredAuthorization &&
                  syncStatus === SyncStatusPlatform.Fail
                    ? "none"
                    : "flex",
              }}
            >
              <Tooltip title={softSyncTitle}>
                {syncStatus === SyncStatusPlatform.Pending ? (
                  <HourglassEmpty />
                ) : (
                  <Sync />
                )}
              </Tooltip>
            </TextIconButton>
            <MoreOptionsDropdown options={options} tooltipPlacement="right" />
          </Box>
        </ImportListItemWrapper>
      </ImportListItemContainer>
      <ImportErrorReportingBox
        issue={errorIssue}
        handleSoftSync={handleSoftSync}
        handleUpdate={() => {}}
        importType={ImportType.OAuth}
        exchangeName={exchangeName}
        syncStatus={syncStatus}
      />
    </>
  );
};
