import { Tooltip, type TooltipProps } from "@mui/material";
import styled from "styled-components/macro";

interface IStyledTooltip extends TooltipProps {
  position: "top" | "bottom";
}
export const StyledTooltip = styled(
  ({ position, className, ...props }: IStyledTooltip) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)`
  && .MuiTooltip-tooltip {
    ${({ position }) =>
      position === "top" ? `margin-bottom: 0.25rem` : `margin-top: 0.25rem`}
  }
`;
