import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Box, Typography } from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

import { CommandPaletteTextButton } from "~/components/transactions/command-palette/Buttons";

interface CommandPaletteFooterProps {
  onApply: () => void;
  actionLabel: string;
  disabled: boolean;
}

export const CommandPaletteFooter: React.FC<CommandPaletteFooterProps> = ({
  onApply,
  actionLabel,
  disabled,
}) => {
  return (
    <StyledBox>
      <CommandPaletteTextButton onClick={onApply} disabled={disabled}>
        <Box>
          <KeyboardReturnIcon fontSize="small" />
        </Box>
        <Typography variant="Metropolis/Caption/Medium/Regular">{actionLabel}</Typography>
      </CommandPaletteTextButton>
    </StyledBox>
  );
};

const StyledBox = styled.div`
  && {
    border-top: ${({ theme }) => theme.tokens.border.neutral.default} 1px solid;
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: flex-end;
  }
`;
