import { deleteRequest, put } from "~/services/core";

/**
 * Creates or updates a BE feature flag for the current user
 * @param flag - The name of the feature flag
 * @param value - The value to set for the feature flag
 */
export async function createOrUpdateFeatureFlag(flag: string, value: string) {
  return put<void>(`/settings/flags/${flag}`, { value });
}

/**
 * Removes a BE feature flag for the current user
 * @param flag - The name of the feature flag to remove
 */
export async function removeFeatureFlag(flag: string) {
  return deleteRequest<void>(`/settings/flags/${flag}`);
}
