import { type Blockchain, type Country, type Trade } from "@ctc/types";
import {
  Assignment,
  CalendarMonth,
  CheckCircleOutline,
  Comment,
  DescriptionOutlined,
  ErrorOutline,
  FilterAltOutlined,
  KeyboardArrowDown,
  MenuBook,
  RequestPage as RequestPageIcon,
  Sync,
  SyncDisabled,
  WarningAmber,
} from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Paper,
  Popper,
  type PopperProps,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import keyBy from "lodash/keyBy";
import startCase from "lodash/startCase";
import truncate from "lodash/truncate";
import { matchSorter } from "match-sorter";
import moment from "moment-timezone";
import { transparentize } from "polished";
import * as React from "react";
import { useMemo, useState } from "react";
import { type ListChildComponentProps, VariableSizeList } from "react-window";
import { css } from "styled-components";
import styled from "styled-components/macro";

import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  CheckboxActionType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { isSimpleFilterQuery } from "~/components/transactions/filter-bar/isSimpleFilterQuery";
import { BlockchainLogo } from "~/components/ui/BlockchainLogo";
import { Checkbox } from "~/components/ui/Checkbox";
import { Chip } from "~/components/ui/Chips";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { NFT_ID_MAX_DISPLAY_LENGTH } from "~/constants/constants";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import { getUserCountryDateFormat, transformInputToTz } from "~/lib/date";
import {
  type AutoSuggestionsOperator,
  filterOperatorConfig,
  type FilterSearchOperator,
  isAutoGeneratedOption,
  isMultiSelectOperator,
  type QueryBuilderOperator,
  type TextMatchingOperator,
} from "~/lib/filterOperatorConfig";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { middleTrim } from "~/lib/index";
import { invariant } from "~/lib/invariant";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { formatFunctionName } from "~/services/transactions";
import { useGetFilterOptionsQuery } from "~/state/actions";
import { useEntityLookupAsync } from "~/state/entities";
import { useLoadExchangeNames } from "~/state/exchanges";
import {
  ErpSyncStatus,
  FilterOperators,
  Size,
  type TaxOutcomeType,
} from "~/types/enums";
import {
  type ActionType,
  type AfterFilter,
  type BeforeFilter,
  BlockchainName,
  type CurrencyIdentifier,
  type CurrencyIdentifierWithContractAddresses,
  type DateFilter,
  DefaultBlockchainCurrency,
  type ExchangeNames,
  type ExchangeOption,
  type FilterQuery,
  type SourceFilterOption,
} from "~/types/index";
// Note:
// There is the FilterQuery === the nested filter logic we send and receive from the backend
// There is the FilterOption === what the GET /filterOptions returns (all the facets we can use)
// There is AutocompleteFilterOption === Transforms FilterOption into something useable by the Autocomplete component

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type TransactionFilterOptionsWithWarnings = Required<
  NonNullable<ReturnType<typeof useGetFilterOptionsQuery>["data"]>
>;
type FilterOptionsEntries = Entries<TransactionFilterOptionsWithWarnings>;

type NonArrayQueries = Exclude<FilterQuery, { value: any[] }>;
type ArrayQueries = Extract<FilterQuery, { value: any[] }>;
type SwapArrayToArrayOfOne<Type> = {
  [Key in keyof Type]: Type[Key] extends any[] ? [Type[Key][0]] : Type[Key];
};
type UpdatedArrayQueries = SwapArrayToArrayOfOne<ArrayQueries>;
type SingularFilterQuery = NonArrayQueries | UpdatedArrayQueries;

export type AutocompleteFilterOption = {
  filter: SingularFilterQuery;
  displayName: string;
  searchable: string[];
  key: string;
  type: FilterSearchOperator;
  multiselect?: boolean;

  // Icon/metadata props
  currencyIdentifier?:
    | CurrencyIdentifier
    | CurrencyIdentifierWithContractAddresses;
  party?: ExchangeOption; // address or exchange name
  tradeType?: ActionType;
  timestamp?: number;
  txCount?: number;
  blockchain?: Blockchain;
  source?: SourceFilterOption;
  erpSyncStatus?: ErpSyncStatus;
};

// Sub-components (like the FilterOption) need to know where they are
// are they inside the FilterSearch or inside the FilterQueryBuilder
// because they show slightly different styles
// This context stores whether we are restricted (aka in the FilterQueryBuilder)
// or just all options as in the FilterSearch
const RestrictTypeContext = React.createContext<string | undefined>(undefined);

/**
 * Passes the necessary information from the root FilterSearch component
 * to the Select all Popper component
 */
const SelectAllContext = React.createContext<
  | {
      autoSuggestions: AutoSuggestionsOperator[];
      inputValue: string;
      setInputValue: React.Dispatch<React.SetStateAction<string>>;
      autocompleteFilterOptions: AutocompleteFilterOption[];
      timezone: string;
      selectedFilterOptions: AutocompleteFilterOption[];
      onChange: (filter: FilterQuery | undefined) => any;
      restrictType: QueryBuilderOperator | undefined;
    }
  | undefined
>(undefined);

function FilterOptionIcon(
  props: Omit<AutocompleteFilterOption, "key"> & { size?: "small" },
) {
  const {
    currencyIdentifier,
    tradeType,
    party,
    source,
    type,
    size,
    blockchain,
    erpSyncStatus,
    filter,
  } = props;

  const { getEntityById } = useEntityLookupAsync();
  const { tokens } = useDesign();
  const iconSizePx = size === "small" ? 16 : 24;

  switch (type) {
    case FilterOperators.Currency:
    case FilterOperators.FeeCurrency:
    case FilterOperators.TxCurrency:
    case FilterOperators.NFTCollection:
      if (currencyIdentifier) {
        return (
          <CurrencyLogo
            margin="0rem"
            currency={currencyIdentifier}
            width={iconSizePx}
            height={iconSizePx}
            style={{ borderRadius: "100%" }}
          />
        );
      }
      break;
    case FilterOperators.Blockchain:
      if (blockchain) {
        return (
          <BlockchainLogo
            blockchain={blockchain}
            width={iconSizePx}
            height={iconSizePx}
            margin="0"
          />
        );
      }
      break;
    case FilterOperators.Integration:
      if (
        filter.type === FilterOperators.Integration &&
        filter.value.length > 0
      ) {
        const integration = filter.value[0];
        return (
          <ExchangeLogo
            name={integration}
            height={iconSizePx}
            width={iconSizePx}
            margin="0rem"
          />
        );
      }
      break;
    case FilterOperators.From:
    case FilterOperators.To:
    case FilterOperators.Source:
      if (source) {
        return (
          <ExchangeLogo
            margin="0rem"
            name={source.name}
            blockchain={source.blockchain}
            currencySymbol={
              source.blockchain && DefaultBlockchainCurrency[source.blockchain]
            }
            width={iconSizePx}
            height={iconSizePx}
            entity={getEntityById(source.id)}
            showBlockchainSymbol
          />
        );
      }

      if (party) {
        return (
          <ExchangeLogo
            margin="0rem"
            name={party.name}
            backupName={party.label}
            blockchain={party.blockchain}
            currencySymbol={party.currency}
            width={iconSizePx}
            height={iconSizePx}
            entity={getEntityById(party.label)}
            showBlockchainSymbol
          />
        );
      }
      break;
    case FilterOperators.Sync:
      if (source) {
        return (
          <ExchangeLogo
            margin="0rem"
            name={source.id}
            blockchain={source.blockchain}
            currencySymbol={
              source.blockchain && DefaultBlockchainCurrency[source.blockchain]
            }
            width={iconSizePx}
            height={iconSizePx}
            entity={getEntityById(source.id)}
            showBlockchainSymbol
          />
        );
      }
      break;
    case FilterOperators.TxTrade:
      if (tradeType) {
        const Icon = TradeIcons[tradeType];
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="1.25rem"
            width="1.25rem"
            borderRadius="1.25rem"
            sx={{ backgroundColor: tokens.button.subtle.default }}
          >
            <Icon style={{ fontSize: "1rem" }} />
          </Box>
        );
      }
      break;
    case FilterOperators.Warning:
      return (
        <Box
          sx={{
            backgroundColor: tokens.icon.warning,
            width: iconSizePx,
            height: iconSizePx,
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: iconSizePx,
          }}
        />
      );
    case FilterOperators.ImportType:
    case FilterOperators.ImportId:
    case FilterOperators.TxHash:
    case FilterOperators.Description:
    case FilterOperators.CommentContains:
    case FilterOperators.TxFunctionName:
    case FilterOperators.TxFunctionSignature:
      return (
        <DescriptionOutlined
          style={{
            width: iconSizePx,
            height: iconSizePx,
          }}
        />
      );
    case FilterOperators.After:
    case FilterOperators.Before:
    case FilterOperators.Date:
      return (
        <CalendarMonth
          style={{
            width: iconSizePx,
            height: iconSizePx,
          }}
        />
      );
    case FilterOperators.HasComments:
      return (
        <Comment
          style={{
            width: iconSizePx,
            height: iconSizePx,
          }}
        />
      );
    case FilterOperators.Reviewed:
      return (
        <Assignment
          style={{
            width: iconSizePx,
            height: iconSizePx,
          }}
        />
      );
    case FilterOperators.TaxOutcomeType:
      return (
        <RequestPageIcon
          style={{
            width: iconSizePx,
            height: iconSizePx,
          }}
        />
      );
    case FilterOperators.ErpAssetAccount:
    case FilterOperators.ErpGainsAccount:
    case FilterOperators.ErpLoanAccount:
    case FilterOperators.ErpPnlAccount:
    case FilterOperators.ErpCashAccount:
      return (
        <MenuBook
          style={{
            width: iconSizePx,
            height: iconSizePx,
          }}
        />
      );
    case FilterOperators.ErpSyncStatus:
      if (erpSyncStatus) {
        switch (erpSyncStatus) {
          case ErpSyncStatus.NotReadyToSync:
            return (
              <SyncDisabled
                style={{
                  width: iconSizePx,
                  height: iconSizePx,
                }}
              />
            );
          case ErpSyncStatus.SyncFailed:
            return (
              <ErrorOutline
                style={{
                  width: iconSizePx,
                  height: iconSizePx,
                }}
              />
            );
          case ErpSyncStatus.SyncInProgress:
            return (
              <Sync
                style={{
                  width: iconSizePx,
                  height: iconSizePx,
                }}
              />
            );
          case ErpSyncStatus.SyncNotRequested:
            return (
              <Sync
                style={{
                  width: iconSizePx,
                  height: iconSizePx,
                }}
              />
            );
          case ErpSyncStatus.SyncOutOfDate:
            return (
              <WarningAmber
                style={{
                  width: iconSizePx,
                  height: iconSizePx,
                }}
              />
            );
          case ErpSyncStatus.SyncSuccessful:
          case ErpSyncStatus.SyncNoOp:
            return (
              <CheckCircleOutline
                style={{
                  width: iconSizePx,
                  height: iconSizePx,
                }}
              />
            );
          default: {
            const exhaustiveCheck: never = erpSyncStatus;
            throw new Error(`Unhandled case: ${exhaustiveCheck}`);
          }
        }
      }
      break;
    case FilterOperators.Rule:
      return (
        <FilterAltOutlined
          style={{
            width: iconSizePx,
            height: iconSizePx,
          }}
        />
      );
    case FilterOperators.IsSmartContractInteraction:
      return null;
  }
  return (
    <Box
      sx={{
        backgroundColor: tokens.icon.brand,
        width: iconSizePx,
        height: iconSizePx,
        borderRadius: "100%",
      }}
    />
  );
}

export function FilterOption(
  props: Omit<AutocompleteFilterOption, "key"> & {
    size?: "small";
    hideCategoryNameOverride?: boolean;
    textColor?: string;
  },
) {
  const { hideCategoryNameOverride } = props;
  const { tokens } = useDesign();
  const lang = useLang();
  const restrictType = React.useContext(RestrictTypeContext);
  const hideCategoryName = !!hideCategoryNameOverride || !!restrictType;
  const { displayName, type, size, currencyIdentifier, txCount, textColor } =
    props;

  const gap = size === "small" ? "0.25rem" : "0.5rem";
  const fontSize = size === "small" ? "0.75rem" : "0.875rem";

  const getDisplayName = (): string => {
    const output = truncate(displayName, { length: 46 });

    if (type === FilterOperators.TxFunctionName) {
      return formatFunctionName(output);
    }

    return output;
  };

  const categoryName = `${lang.txTable.filter.operators[type]}:`;

  const getChipContents = () => {
    if (currencyIdentifier && currencyIdentifier.nftId) {
      return `#${middleTrim(
        currencyIdentifier.nftId,
        NFT_ID_MAX_DISPLAY_LENGTH,
      )}`;
    }
    if (txCount) {
      return `${txCount}${txCount === 1 ? " tx" : " txs"}`;
    }
    return undefined;
  };

  const chipContents = getChipContents();

  return (
    <Stack direction="row" gap={gap} alignItems="center" minWidth={0}>
      {size || !!hideCategoryName ? null : <Chip>{categoryName}</Chip>}

      <FilterOptionIcon {...props} />

      {size && !hideCategoryName ? categoryName : null}

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="0.25rem"
        minWidth={0}
      >
        <Typography
          sx={{
            fontSize,
            fontWeight: 600,
            color: textColor || tokens.text.default,
            transform: "capitalize",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexShrink: 1,
          }}
        >
          {getDisplayName()}
        </Typography>
        {!size && chipContents ? (
          <Chip flexShrink={0}>{chipContents}</Chip>
        ) : null}
      </Box>
    </Stack>
  );
}

export function FilterSearch() {
  // we dont want the autocomplete to use all options
  const { state, dispatch } = useTransactionFilter();
  const { dispatch: checkboxDispatch } = useTransactionCheckbox();
  const filter = state.filter;
  const lang = useLang();

  if (!isSimpleFilterQuery(filter)) {
    return (
      <TertiaryButton sx={{ height: "1.75rem" }} size="small" disabled>
        {lang.txTable.filter.advancedFilters}
      </TertiaryButton>
    );
  }
  return (
    <FilterAutocomplete
      filter={filter}
      onChange={(filter) => {
        if (!filter) {
          dispatch({ type: FilterActionType.ResetFilter });
        } else {
          dispatch({ type: FilterActionType.SetFilter, filter });
        }
        checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
      }}
    />
  );
}

const LISTBOX_PADDING = 10; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index] as any;
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
    overflow: "visible",
  } as any;

  const rowProps = dataSet[0];
  const option = dataSet[1];

  return (
    <div component="li" {...rowProps} noWrap style={inlineStyle}>
      {!option.size && option.multiselect && (
        <Checkbox checked={rowProps["aria-selected"]} />
      )}
      <FilterOption {...option} />
    </div>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const PopperStyledComponent = styled(Popper)`
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.tokens.background.neutral.default};
  & .${autocompleteClasses.listbox} {
    box-sizing: "border-box";
    & ul {
      padding: 0;
      margin: 0;
    }
  }
`;
// Custom config for the popper
const popperOptions = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 2],
      },
    },
  ],
};

const trySearchingForButtonCss = css`
  &&& {
    font-size: 11px;
    display: block;
    padding: 4px 12px;
    min-width: 0;
  }
`;

// the select all button will be hidden if more than 250 options are visible
const MAX_SELECT_ALL_OPTIONS = 250;
/**
 * Replaces the default Popper with a custom one so we can add
 * a select all at the top.
 *
 * @param props
 * @returns
 */
const PopperComponent = (props: PopperProps) => {
  const { tokens } = useDesign();
  const selectAllContext = React.useContext(SelectAllContext);
  const lang = useLang();
  const country = useCountry();

  if (!selectAllContext) {
    return null;
  }
  const {
    autoSuggestions,
    inputValue,
    setInputValue,
    autocompleteFilterOptions,
    timezone,
    selectedFilterOptions,
    onChange,
    restrictType,
  } = selectAllContext;

  const { children, ...rest } = props;
  const noSelectAll = (
    <PopperStyledComponent {...rest} popperOptions={popperOptions}>
      <Box {...rest}>{typeof children === "function" ? null : children}</Box>
    </PopperStyledComponent>
  );

  // its the filter search (not advanced) and the input is empty
  if (!restrictType && inputValue === "") {
    if (inputValue === "") {
      return (
        <PopperStyledComponent {...rest} popperOptions={popperOptions}>
          <Box
            sx={{
              borderBottom: `1px solid ${tokens.border.neutral.default}`,
              padding: "0.875rem 1rem",
              fontWeight: 500,
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            <Stack direction="column" gap="0.6rem">
              <Box
                sx={{
                  color: tokens.text.low,
                  fontSize: 10,
                }}
              >
                {lang.txTable.filter.trySearchingFor}
              </Box>
              <Stack direction="row" gap="0.5rem" flexWrap="wrap">
                {[
                  FilterOperators.Source,
                  FilterOperators.Blockchain,
                  FilterOperators.TxCurrency,
                  FilterOperators.TxTrade,
                  FilterOperators.TxFunctionName,
                ].map((operator) => (
                  <TertiaryButton
                    size="small"
                    key={operator}
                    onClick={() => {
                      const operatorToSearchPrefix: Partial<
                        Record<FilterOperators, string>
                      > = {
                        [FilterOperators.TxFunctionName]: "txfunc",
                        [FilterOperators.Source]: "account",
                      };
                      setInputValue(
                        `${operatorToSearchPrefix[operator] ?? operator}: `,
                      );
                    }}
                    css={trySearchingForButtonCss}
                  >
                    {lang.txTable.filter.operators[operator]}
                  </TertiaryButton>
                ))}
              </Stack>
            </Stack>
          </Box>
          <Box {...rest}>
            {typeof children === "function" ? null : children}
          </Box>
        </PopperStyledComponent>
      );
    }
  }
  // in the filter search (no restrict type) or its txd, description, don't show the checkbox
  if (!restrictType || filterOperatorConfig[restrictType].disabledSelectAll) {
    return noSelectAll;
  }
  const options = generateAutocompleteFilterOptionSuggestionsBasedOnInput({
    autoSuggestions,
    selectedFilterOptions,
    inputValue,
    options: autocompleteFilterOptions,
    timezone,
    country,
  });

  // if there are more than max options, dont show the checkbox
  if (options.length > MAX_SELECT_ALL_OPTIONS) {
    return noSelectAll;
  }
  const selectedOptions = options.filter((o) =>
    selectedFilterOptions.find((fo) => isEqual(fo.filter, o.filter)),
  );
  const checkboxState =
    selectedOptions.length === options.length
      ? "checked"
      : selectedOptions.length === 0
        ? "unchecked"
        : "intermediate";

  const checkAllChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    // already checked, so unselecting everything
    if (checkboxState === "checked") {
      onChange(undefined);
      return;
    }

    // add all the options the user has available based on their search filter
    const filter: FilterQuery = convertAutocompleteFilterOptionToFilterQuery(
      [
        ...selectedFilterOptions,
        ...options.filter(
          (o) =>
            !selectedFilterOptions.find((c) => isEqual(c.filter, o.filter)),
        ),
      ],
      restrictType,
    );
    onChange(filter);
  };

  return (
    <PopperStyledComponent {...rest} popperOptions={popperOptions}>
      <Box
        sx={{
          borderBottom: `1px solid ${tokens.border.neutral.default}`,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          padding: "0.0625rem 1rem",
          fontWeight: 500,
          fontSize: "0.875rem",
          color: tokens.text.default,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: transparentize(0.7, tokens.elevation.highest),
          },
        }}
        role="button"
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        onClick={checkAllChange}
      >
        <Checkbox
          checked={checkboxState === "checked"}
          indeterminate={checkboxState === "intermediate"}
          id="check-all"
          sx={{ marginRight: "0.25rem" }}
        />

        {lang.txTable.filter.selectAll}
      </Box>
      <Box {...rest}>{typeof children === "function" ? null : children}</Box>
    </PopperStyledComponent>
  );
};

export function getAutocompleteFilterOptions({
  restrictType,
  overwriteRestrictTypeOptions,
  data,
  lang,
  exchangeNames,
  alwaysMiddleTrimAddresses = false,
}: {
  data?: ReturnType<typeof useGetFilterOptionsQuery>["data"];
  lang: Translation;
  restrictType?: QueryBuilderOperator;
  overwriteRestrictTypeOptions?: Partial<
    ReturnType<typeof useGetFilterOptionsQuery>["data"]
  >;
  exchangeNames: ExchangeNames;
  alwaysMiddleTrimAddresses?: boolean;
}): AutocompleteFilterOption[] {
  if (restrictType) {
    if (isAutoGeneratedOption(restrictType)) {
      return [];
    }

    return convertFilterOptionToFilterOptionAutocomplete(
      {
        [restrictType]: overwriteRestrictTypeOptions
          ? overwriteRestrictTypeOptions[restrictType]
          : data?.[restrictType],
      },
      lang,
      exchangeNames,
      alwaysMiddleTrimAddresses,
    );
  }

  return convertFilterOptionToFilterOptionAutocomplete(
    data,
    lang,
    exchangeNames,
    alwaysMiddleTrimAddresses,
  ).map((option) => {
    // Enable multiple select on restricted type only.
    return restrictType ? option : { ...option, multiselect: false };
  });
}

export function FilterAutocomplete(props: {
  filter: FilterQuery | undefined;
  onChange: (filter: FilterQuery | undefined) => any;
  restrictType?: QueryBuilderOperator;
  overwriteRestrictTypeOptions?: Partial<
    ReturnType<typeof useGetFilterOptionsQuery>["data"]
  >;
  size?: Size;
  disabled?: boolean;
}) {
  return <FilterAutocompleteSync {...props} />;
}

export function FilterAutocompleteSync(props: {
  filter: FilterQuery | undefined;
  onChange: (filter: FilterQuery | undefined) => any;
  restrictType?: QueryBuilderOperator;
  overwriteRestrictTypeOptions?: Partial<
    ReturnType<typeof useGetFilterOptionsQuery>["data"]
  >;
  size?: Size;
  disabled?: boolean;
}) {
  const { tokens } = useDesign();
  const { data: exchangeNames, isLoading: isLoadingExchangeNames } =
    useLoadExchangeNames();
  const timezone = useTimezone();
  const country = useCountry();
  const lang = useLang();
  const query = useGetFilterOptionsQuery();
  const isLoadingFilterOptions = query.isPending || isLoadingExchangeNames;

  const { filter, onChange, size, disabled } = props;
  const [inputValue, setInputValue] = useState("");

  const { restrictType, overwriteRestrictTypeOptions } = props;

  const autocompleteFilterOptions: AutocompleteFilterOption[] = useMemo(() => {
    if (!exchangeNames) {
      return [];
    }
    return getAutocompleteFilterOptions({
      restrictType,
      overwriteRestrictTypeOptions,
      data: query.data,
      lang,
      exchangeNames,
    });
  }, [
    restrictType,
    overwriteRestrictTypeOptions,
    query.data,
    lang,
    exchangeNames,
  ]);

  const selectedFilterOptions: AutocompleteFilterOption[] = useMemo(() => {
    if (isLoadingFilterOptions) {
      return [];
    }
    return convertFilterQueryToSelectedAutocompleteFilterOption(
      filter,
      autocompleteFilterOptions,
      country,
    );
  }, [filter, autocompleteFilterOptions, isLoadingFilterOptions, country]);

  // If we don't have multiselect enabled, we only allow 1 option max.
  const maxOptionsSelected = !!(
    selectedFilterOptions.length &&
    restrictType &&
    !isMultiSelectOperator(restrictType)
  );
  const [isFocused, setIsFocused] = useState(false);

  const autoSuggestions = getAutoSuggestionTypes(restrictType);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SelectAllContext.Provider
      value={{
        autoSuggestions,
        inputValue,
        setInputValue,
        autocompleteFilterOptions,
        timezone,
        selectedFilterOptions,
        onChange,
        restrictType,
      }}
    >
      <RestrictTypeContext.Provider value={restrictType}>
        <Box
          sx={{
            maxWidth: "100%",
            // the width of search input box
            width: restrictType
              ? "100%"
              : {
                  md: "36rem",
                  xs: "100%",
                },
          }}
        >
          <Autocomplete
            clearOnBlur={false}
            componentsProps={{ paper: { elevation: 8 } }}
            PaperComponent={StyledPaper}
            // Contains the select all button
            PopperComponent={PopperComponent}
            popupIcon={<KeyboardArrowDown />}
            noOptionsText={
              inputValue.length ||
              (restrictType &&
                !filterOperatorConfig[restrictType].disabledSelectAll)
                ? lang.txTable.filter.noResults
                : lang.txTable.filter.typeToSearch
            }
            ListboxComponent={ListboxComponent}
            open={isOpen}
            onOpen={() => {
              // Because we adjust the size of the box on click, the autocomplete
              // is also showing the popover on click, and its coming in as the
              // small size.
              // the timeout means we wait for after that render
              setTimeout(() => {
                setIsOpen(true);
              }, 100);
            }}
            onClose={() => {
              setIsOpen(false);
            }}
            disabled={query.isInitialLoading || disabled || maxOptionsSelected}
            loading={query.isInitialLoading}
            autoHighlight
            disableCloseOnSelect={
              // Allows multiple selections without closing the dropdown.
              restrictType
                ? filterOperatorConfig[restrictType].multiselect
                : false
            }
            value={selectedFilterOptions as any}
            onChange={(event: any, newValue: AutocompleteFilterOption[]) => {
              const filter: FilterQuery =
                convertAutocompleteFilterOptionToFilterQuery(
                  newValue,
                  restrictType,
                );
              onChange(filter);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            multiple
            limitTags={restrictType ? 10 : 1}
            filterOptions={(
              options,
              { inputValue },
            ): AutocompleteFilterOption[] => {
              return generateAutocompleteFilterOptionSuggestionsBasedOnInput({
                selectedFilterOptions,
                autoSuggestions,
                inputValue,
                options,
                timezone,
                country,
              });
            }}
            options={autocompleteFilterOptions}
            renderOption={(props, option) => [props, option] as React.ReactNode}
            // TODO: Post React 18 update - validate this conversion, look like a hidden bug
            // renderGroup={(params) => params as unknown as React.ReactNode}
            getOptionLabel={(option) => `${option.displayName}`}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option: AutocompleteFilterOption, index) => {
                const { onDelete, ...otherProps } = getTagProps({ index });
                return (
                  <Stack
                    direction="row"
                    gap="0.25rem"
                    {...otherProps}
                    sx={{
                      maxWidth: "70%",
                      padding: "2px",
                      fontWeight: 500,
                      fontSize: "0.75rem",
                      lineHeight: "1rem",
                      color: tokens.text.default,
                      background: tokens.background.brand.default,
                      borderRadius: 4,
                    }}
                    key={option.key}
                  >
                    <FilterOption {...option} size="small" />
                    <Button
                      sx={{
                        minWidth: "inherit",
                        padding: 0,
                        color: tokens.icon.low,
                      }}
                      onClick={(e) => {
                        onDelete(e);
                      }}
                    >
                      <CancelIcon
                        sx={{
                          height: "1rem",
                          width: "1rem",
                        }}
                      />
                    </Button>
                  </Stack>
                );
              });
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: false,
                }}
                sx={
                  restrictType
                    ? {
                        "&&& .MuiAutocomplete-input": {
                          padding: 0,
                          height: "1.3rem",
                        },
                        "&&& .MuiAutocomplete-tag": {
                          // no margins on tags
                          marginTop: 0,
                          marginBottom: 0,
                          maxWidth: "65%",
                          "@media (min-width: 420px)": {
                            maxWidth: "80%",
                          },
                        },
                        // caps height of box at 100px and adds scrollbars
                        "&&& .MuiInputBase-root.Mui-focused": {
                          maxHeight: 100,
                          overflowY: "auto",
                          outline: `solid 1px ${tokens.border.brand}`,
                          outlineOffset: "-1px",
                        },
                        "&&& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          display: "none",
                        },
                        "&&& .MuiInputBase-root": {
                          display: "flex",
                          gap: "2px",
                          ...(size === Size.Small
                            ? {
                                fontSize: "0.875rem",
                                padding: "0.188rem 0.5rem",
                              }
                            : {}),
                        },
                      }
                    : {
                        // caps height of box at 100px and adds scrollbars
                        "&&& .MuiInputBase-root.Mui-focused": {
                          flexWrap: "wrap",
                          maxHeight: 100,
                          overflowY: "auto",
                          outline: `solid 1px ${tokens.border.brand}`,
                          outlineOffset: "-1px",
                        },
                        "&&& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          display: "none",
                        },
                        "&&& .Mui-focused > fieldset": {
                          borderColor: `${tokens.border.neutral.default} !important`,
                        },
                        "&&& .MuiInputBase-root": {
                          display: "flex",
                          gap: "2px",
                          flexWrap: "nowrap",
                          // animate the padding so it grows when you click on it
                          transition: "all 0.1s",
                          paddingTop: isFocused ? "0.625rem" : "5px",
                          paddingBottom: isFocused ? "0.625rem" : "5px",
                        },
                        "&&& .MuiAutocomplete-input": {
                          padding: 0,
                          fontSize: "0.75rem",
                          fontWeight: 500,
                        },
                        "&&& .MuiAutocomplete-tag": {
                          // no margins on tags
                          marginTop: 0,
                          marginBottom: 0,
                          maxWidth: "55%",
                          "@media (min-width: 420px)": {
                            maxWidth: "70%",
                          },
                        },
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }
                }
                placeholder={
                  selectedFilterOptions.length
                    ? undefined
                    : restrictType
                      ? lang.txTable.filter.operators[restrictType]
                      : lang.txTable.filter.placeholder
                }
              />
            )}
            sx={
              restrictType
                ? {}
                : {
                    zIndex: 120,
                    position: "relative",
                  }
            }
          />
        </Box>
      </RestrictTypeContext.Provider>
    </SelectAllContext.Provider>
  );
}

function getAutoSuggestionTypes(filterOperator: FilterOperators | undefined) {
  if (!filterOperator) {
    const keys = Object.keys(
      filterOperatorConfig,
    ) as (keyof typeof filterOperatorConfig)[];
    const autogeneratedTypes = keys.filter(
      (type) => filterOperatorConfig[type].isAutoGenerated,
    ) as AutoSuggestionsOperator[];
    return autogeneratedTypes;
  }
  if (filterOperatorConfig[filterOperator].isAutoGenerated) {
    return [filterOperator] as AutoSuggestionsOperator[];
  }
  return [];
}

/**
 * If autosuggestion, the UI will add in dates and txid if no results
 *
 * Inserts autocomplete filter optionsed based on user input
 * e.g. if they type a date, or "date:2021-01-01" it will insert a date filter option
 * or if they dont get any existing results, it will suggest a txid
 *
 * @param autoSuggestion - should we provide date and txid suggestions
 * @param inputValue - what the user typed in
 * @param inputValue - the list of options the user can choose from
 * @returns
 */
function generateAutocompleteFilterOptionSuggestionsBasedOnInput({
  autoSuggestions,
  selectedFilterOptions,
  inputValue,
  options,
  timezone,
  country,
}: {
  selectedFilterOptions: AutocompleteFilterOption[];
  autoSuggestions: AutoSuggestionsOperator[];
  inputValue: string;
  options: AutocompleteFilterOption[];
  timezone?: string;
  country?: Country;
}) {
  const operatorOrder = new Map<FilterSearchOperator, number>([
    [FilterOperators.Source, 1],
    [FilterOperators.TxCurrency, 2],
    [FilterOperators.NFTCollection, 3],
    [FilterOperators.To, 4],
    [FilterOperators.From, 5],
    [FilterOperators.Blockchain, 6],
    [FilterOperators.Sync, 7],
    [FilterOperators.FeeCurrency, 8],
    [FilterOperators.TxFunctionSignature, 9],
  ]);
  const selectedKeys = keyBy(selectedFilterOptions, "key");
  const unselectedOptions = options.filter(
    (option) => !selectedKeys[option.key],
  );

  const results = matchSorter(unselectedOptions, inputValue, {
    keys: ["searchable"],
    baseSort: (a, b) => {
      if (a.rank !== b.rank) {
        // ranks dont match, use the rank
        return b.rank - a.rank;
      }
      if (a.item.type === b.item.type) {
        if (a.item.type === FilterOperators.Sync) {
          if (a.item.timestamp !== b.item.timestamp) {
            return (b.item.timestamp || 0) - (a.item.timestamp || 0);
          }
        }

        // For currencies, always use the BE sorting
        if (
          [FilterOperators.TxCurrency, FilterOperators.FeeCurrency].includes(
            a.item.type,
          ) // Parent conditional checks a and b are the same, so b is implicitly also a currency filter
        ) {
          return 0;
        }

        // same type, string compare the values
        return String(a.rankedValue).localeCompare(b.rankedValue);
      }
      // different types, use the preference above if its defined
      const preferenceRankA = operatorOrder.get(a.item.type);
      const preferenceRankB = operatorOrder.get(b.item.type);
      if (!preferenceRankA || !preferenceRankB) {
        return String(a.rankedValue).localeCompare(b.rankedValue);
      }
      return preferenceRankA - preferenceRankB;
    },
  });

  if (autoSuggestions.length === 0) {
    return results;
  }

  // has the user enter colon separated e.g. date:2022-01-01
  const [textOperator, value] = inputValue.includes(":")
    ? inputValue.split(":")
    : [undefined, inputValue];

  const format = getUserCountryDateFormat(country);

  // Date input will convert from browser time to profile time, this transforms
  // the given input to the profile timezone without conversion.
  const date = transformInputToTz({
    value,
    timezone,
    format,
  });

  // Don't search if user input 0
  if (value.length === 1 && Number(value) === 0) return results;

  // The order is important here
  // handle adding date, before and after options
  if (date.isValid()) {
    if (
      autoSuggestions.includes(FilterOperators.After) &&
      (textOperator?.toLowerCase() === "after" || !textOperator)
    ) {
      const afterFilter: FilterQuery = {
        type: FilterOperators.After,
        value: date.valueOf(),
      };
      results.unshift(
        ...createDateAutocompleteFilterOption(
          FilterOperators.After,
          afterFilter,
          country,
        ),
      );
    }
    if (
      autoSuggestions.includes(FilterOperators.Before) &&
      (textOperator?.toLowerCase() === "before" || !textOperator)
    ) {
      const beforeFilter: FilterQuery = {
        type: FilterOperators.Before,
        value: date.valueOf(),
      };
      results.unshift(
        ...createDateAutocompleteFilterOption(
          FilterOperators.Before,
          beforeFilter,
          country,
        ),
      );
    }
    if (
      autoSuggestions.includes(FilterOperators.Date) &&
      (textOperator?.toLowerCase() === "date" || !textOperator)
    ) {
      const dateFilter: FilterQuery = {
        type: FilterOperators.Date,
        value: [date.valueOf()],
      };
      results.unshift(
        ...createDateAutocompleteFilterOption(
          FilterOperators.Date,
          dateFilter,
          country,
        ),
      );
    }
  }

  if (autoSuggestions.includes(FilterOperators.TxHash)) {
    if (inputValue.length && !textOperator) {
      results.push(
        ...createTextMatchingAutocompleteFilterOption(
          {
            type: FilterOperators.TxHash,
            value: [inputValue],
          },
          FilterOperators.TxHash,
          "txId",
        ),
      );
    }
  }

  if (autoSuggestions.includes(FilterOperators.Description)) {
    if (inputValue.length && !textOperator) {
      results.push(
        ...createTextMatchingAutocompleteFilterOption(
          {
            type: FilterOperators.Description,
            value: [inputValue],
          },
          FilterOperators.Description,
          "description",
        ),
      );
    }
  }

  if (autoSuggestions.includes(FilterOperators.CommentContains)) {
    if (inputValue.length && !textOperator) {
      results.push(
        ...createTextMatchingAutocompleteFilterOption(
          {
            type: FilterOperators.CommentContains,
            value: [inputValue],
          },
          FilterOperators.CommentContains,
          "comment",
        ),
      );
    }
  }
  return results;
}

// Helper function to find matching filter options
const findMatchingFilterOption = (
  filter: any,
  autocompleteFilterOptions: AutocompleteFilterOption[],
) => autocompleteFilterOptions.find((fo) => isEqual(fo.filter, filter));

/**
 * Converts FilterQuery to something useable by the autocomplete
 */
export function convertFilterQueryToSelectedAutocompleteFilterOption(
  filter: FilterQuery | undefined,
  autocompleteFilterOptions: AutocompleteFilterOption[],
  country?: Country,
): AutocompleteFilterOption[] {
  if (!filter) {
    return [];
  }
  if (
    "value" in filter &&
    Array.isArray(filter.value) &&
    filter.value.length === 0
  ) {
    return [];
  }

  if (
    filter.type === FilterOperators.And ||
    filter.type === FilterOperators.Or
  ) {
    return filter.rules.flatMap((r) => {
      return convertFilterQueryToSelectedAutocompleteFilterOption(
        r,
        autocompleteFilterOptions,
        country,
      );
    });
  }

  // handle the auto-suggestions
  if (filter.type === FilterOperators.Before) {
    return createDateAutocompleteFilterOption(
      FilterOperators.Before,
      filter,
      country,
    );
  }
  if (filter.type === FilterOperators.After) {
    return createDateAutocompleteFilterOption(
      FilterOperators.After,
      filter,
      country,
    );
  }
  if (filter.type === FilterOperators.Date) {
    return createDateAutocompleteFilterOption(
      FilterOperators.Date,
      filter,
      country,
    );
  }
  if (filter.type === FilterOperators.TxHash) {
    return createTextMatchingAutocompleteFilterOption(
      filter,
      FilterOperators.TxHash,
      "txId",
    );
  }
  if (filter.type === FilterOperators.Description) {
    return createTextMatchingAutocompleteFilterOption(
      filter,
      FilterOperators.Description,
      "description",
    );
  }
  if (filter.type === FilterOperators.CommentContains) {
    return createTextMatchingAutocompleteFilterOption(
      filter,
      FilterOperators.CommentContains,
      "comment",
    );
  }

  // handle the rest
  if ("value" in filter && Array.isArray(filter.value)) {
    return filter.value.flatMap((v) => {
      const newFilter = {
        ...filter,
        value: [v],
      };
      const matchingFilterOption = findMatchingFilterOption(
        newFilter,
        autocompleteFilterOptions,
      );
      if (!matchingFilterOption) {
        console.error(
          "Filter option is not valid for this user",
          filter,
          autocompleteFilterOptions,
        );
        return [];
      }
      return [matchingFilterOption];
    });
  }
  const matchingFilterOption = findMatchingFilterOption(
    filter,
    autocompleteFilterOptions,
  );
  if (!matchingFilterOption) {
    console.error(
      "Filter option is not valid for this user",
      filter,
      autocompleteFilterOptions,
    );
    return [];
  }
  return [matchingFilterOption];
}

/**
 * Converts a Date filter query to soething useable by the autocomplete
 */
function createDateAutocompleteFilterOption(
  operator:
    | FilterOperators.Before
    | FilterOperators.After
    | FilterOperators.Date,
  filter: DateFilter | BeforeFilter | AfterFilter,
  country?: Country,
): AutocompleteFilterOption[] {
  if (
    filter.type === FilterOperators.Before ||
    filter.type === FilterOperators.After
  ) {
    const value = filter.value;
    const date = moment(value);
    return [
      {
        key: `${operator}:${date.format("DD/MM/YYYY")}`,
        filter,
        displayName: `${date.format(getUserCountryDateFormat(country))}`,
        searchable: getSearchableText(operator, [
          date.format(getUserCountryDateFormat(country)),
        ]),
        type: operator,
      },
    ];
  }
  invariant(operator === FilterOperators.Date, "invalid operator");
  const values = filter.value;
  return values.map((value) => {
    const date = moment(value);
    return {
      key: `${operator}:${date.format("DD-MM-YYYY")}`,
      filter: {
        ...filter,
        value: [value],
      },
      displayName: `${date.format(getUserCountryDateFormat(country))}`,
      searchable: getSearchableText(operator, [
        date.format(getUserCountryDateFormat(country)),
      ]),
      type: operator,
    };
  });
}

/**
 *  * Converts a text matching type of Filter Query to something useable by the autocomplete
 * @param filter TxIdFilter | DescriptionFilter | CommentContentFilter,
 * @param type UserFilterOperators
 * @param keyPrefix string
 * @returns
 */
type TextMatchingFilters = Extract<FilterQuery, { type: TextMatchingOperator }>;
function createTextMatchingAutocompleteFilterOption(
  filter: TextMatchingFilters,
  type: FilterSearchOperator,
  keyPrefix: string,
): AutocompleteFilterOption[] {
  return filter.value.map((v) => {
    return {
      key: `${keyPrefix}:${v}`,
      filter: {
        ...filter,
        value: [v.trim()],
      },
      displayName: `${v}`,
      searchable: getSearchableText(keyPrefix, [`${v}`]),
      type,
    };
  });
}

/**
 * Transforms the list of filters from the autocomplete to a structured filter query
 * Here is how the grouping works:
 *  - Multiple currencies are "OR"
 *  - Multiple filters of any other type is "OR"
 *  - All filters are then AND together
 *
 * e.g. (BTC OR ETH) AND (date:2021-01-01 OR date:2021-01-02)
 * @param newValue Selected list of filters from the autocomplete
 * @returns
 */
function convertAutocompleteFilterOptionToFilterQuery(
  newValue: AutocompleteFilterOption[],
  restricted: QueryBuilderOperator | undefined,
) {
  if (restricted) {
    // restricted is used because non-restricted as special logic for currencies
    // that we dont want to replicate here
    if (isMultiSelectOperator(restricted)) {
      const filter = {
        type: restricted,
        value: newValue.flatMap((v) => (v.filter as any).value),
      } as FilterQuery;
      return filter;
    }
    return newValue[0]?.filter;
  }

  // group the new value filters into $in queries
  const groupedByFilterOptions = groupBy(newValue, (o) => o.filter.type);
  const groupedFilters: FilterQuery[] = Object.entries(
    groupedByFilterOptions,
  ).map(([type, values]) => {
    if (values.length >= 2) {
      // its multiple values, so group into an or
      return {
        type,
        value: values.flatMap(
          (v) => Array.from((v.filter as any).value) as any,
        ),
      } as FilterQuery;
    }
    return values[0].filter;
  });
  const filter: FilterQuery = {
    type: FilterOperators.And,
    rules: [...groupedFilters],
  };
  return filter;
}

/**
 * Creates a string that can be used to search for the filter option
 * @param data
 * @returns
 */
function getSearchableText(prefix: string | string[], terms: string[]) {
  const options = terms.flatMap((term) => {
    const arrPrefix = Array.isArray(prefix) ? prefix : [prefix];

    // Temporary fix as term can be undefined
    // undefined comes from BlockchainName[blockchain]
    if (!term) {
      return [];
    }
    // we dont want to factor in capitalisation in our ranking
    const lowerCaseTerm = term.toLocaleLowerCase();
    return [
      lowerCaseTerm,
      ...arrPrefix.flatMap((prefix) => [
        `${prefix} ${lowerCaseTerm}`,
        `${prefix}:${lowerCaseTerm}`,
        `${prefix}: ${lowerCaseTerm}`,
      ]),
    ];
  });
  // keep the shortest option first, since its the best option
  return options.sort((a, b) => a.length - b.length);
}

/**
 * For each FilterOption, create a AutocompleteFilterOption that can be used by
 * the MUI autocomplete to display the data, and also converts the filter option
 * into a filter query.
 * @param data Array of filter options
 * @param lang
 * @returns
 */
export function convertFilterOptionToFilterOptionAutocomplete(
  data: Partial<ReturnType<typeof useGetFilterOptionsQuery>["data"]>,
  lang: Translation,
  exchangeNames: ExchangeNames,
  alwaysMiddleTrimAddresses = false,
) {
  const filterOptionsEntries = Object.entries(
    data ?? {},
  ) as FilterOptionsEntries;

  const filterOptions: AutocompleteFilterOption[] = [];

  for (const [filterType, options] of filterOptionsEntries) {
    if (!options) continue;

    switch (filterType) {
      case "currency": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.Currency,
              value: [o.id],
            };
            return {
              key: `currency:${o.id}`,
              filter,
              displayName: `${o.name} (${o.symbol})`,
              searchable: [],
              type: FilterOperators.Currency as const,
              currencyIdentifier: o,
              multiselect:
                filterOperatorConfig[FilterOperators.Currency].multiselect,
            };
          }),
        );
        break;
      }
      case "category": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.TxTrade,
              value: [o as Trade],
            };
            const displayName =
              getActionTypeName({
                actionType: o as ActionType,
                lang,
              }) ?? o;
            return {
              filter,
              key: `cat:${o}`,
              displayName,
              searchable: getSearchableText(["cat", "category"], [displayName]),

              type: FilterOperators.TxTrade as const,
              tradeType: o as ActionType,
              multiselect:
                filterOperatorConfig[FilterOperators.TxTrade].multiselect,
            };
          }),
        );
        break;
      }
      case "txCurrency": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.TxCurrency,
              value: [o.id],
            };
            return {
              key: `txCurrency:${o.id}`,
              filter,
              displayName: `${o.name} (${o.symbol})`,

              searchable: getSearchableText("tx currency", [
                o.symbol,
                o.name,
                ...(o.contractAddresses
                  ? o.contractAddresses.map(
                      (contractAddress) => contractAddress,
                    )
                  : []),
                ...(o.nftId
                  ? [o.nftId, `${o.symbol}#${o.nftId}`, `${o.name}#${o.nftId}`]
                  : []),
              ]),
              type: FilterOperators.TxCurrency as const,
              currencyIdentifier: o,
              multiselect:
                filterOperatorConfig[FilterOperators.TxCurrency].multiselect,
            };
          }),
        );
        break;
      }
      case "feeCurrency": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.FeeCurrency,
              value: [o.id],
            };
            return {
              key: `feeCurrency:${o.id}`,
              filter,
              displayName: `${o.name} (${o.symbol})`,

              searchable: getSearchableText("fee currency", [
                o.symbol,
                o.name,
                ...(o.contractAddresses
                  ? o.contractAddresses.map(
                      (contractAddress) => contractAddress,
                    )
                  : []),
                ...(o.nftId
                  ? [o.nftId, `${o.symbol}#${o.nftId}`, `${o.name}#${o.nftId}`]
                  : []),
              ]),
              type: FilterOperators.FeeCurrency as const,
              currencyIdentifier: o,
              multiselect:
                filterOperatorConfig[FilterOperators.FeeCurrency].multiselect,
            };
          }),
        );
        break;
      }
      case "to": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.To,
              value: [
                o.blockchain
                  ? `${o.label.toLowerCase()}__${o.blockchain}`
                  : o.label,
              ],
            };
            const formattedName =
              o.entity || alwaysMiddleTrimAddresses
                ? formatName(o.name, o.entity?.displayName, exchangeNames, true)
                : o.name;

            return {
              key: `to:${o.label}${o.blockchain ?? ""}`,
              filter,
              displayName: formattedName,
              searchable: getSearchableText("to", [
                o.name,
                formattedName,
                ...(o.entity ? [o.entity.displayName] : []),
              ]),
              type: FilterOperators.To as const,
              party: o,
              multiselect: filterOperatorConfig[FilterOperators.To].multiselect,
            };
          }),
        );
        break;
      }
      case "from": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.From,
              value: [
                o.blockchain
                  ? `${o.label.toLowerCase()}__${o.blockchain}`
                  : o.label,
              ],
            };
            const formattedName =
              o.entity || alwaysMiddleTrimAddresses
                ? formatName(o.name, o.entity?.displayName, exchangeNames, true)
                : o.name;

            return {
              key: `from:${o.label}${o.blockchain ?? ""}`,
              filter,
              displayName: formattedName,
              searchable: getSearchableText("from", [
                o.name,
                formattedName,
                ...(o.entity ? [o.entity.displayName] : []),
              ]),
              type: FilterOperators.From as const,
              party: o,
              multiselect:
                filterOperatorConfig[FilterOperators.From].multiselect,
            };
          }),
        );
        break;
      }

      case "trade": {
        // handle by actions
        break;
      }
      // csv, api, wallet
      case "importType": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.ImportType,
              value: [o],
            };
            return {
              key: `importtype:${o}`,
              filter,
              displayName: lang.txTable.importType[o],
              searchable: getSearchableText("importtype", [o]),
              type: FilterOperators.ImportType as const,
              multiselect:
                filterOperatorConfig[FilterOperators.ImportType].multiselect,
            };
          }),
        );
        break;
      }
      // source is "import source"
      case "source": {
        filterOptions.push(
          ...options.map((o) => {
            const { name, nickname } = o;
            const formattedName = formatName(
              name,
              nickname,
              exchangeNames,
              true,
            );

            const filter: SingularFilterQuery = {
              type: FilterOperators.Source,
              value: [o.id],
            };
            return {
              key: `import:${o.id}`,
              filter,
              displayName: formattedName,
              searchable: getSearchableText("account", [o.name, formattedName]),
              type: FilterOperators.Source as const,
              source: o,
              multiselect:
                filterOperatorConfig[FilterOperators.Source].multiselect,
            };
          }),
        );
        break;
      }
      case "txFunctionName": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.TxFunctionName,
              value: [o],
            };
            return {
              key: `txfunc:${o}`,
              filter,
              displayName: o,
              searchable: getSearchableText("txfunc", [
                o,
                formatFunctionName(o),
              ]),
              type: FilterOperators.TxFunctionName as const,
              multiselect:
                filterOperatorConfig[FilterOperators.TxFunctionName]
                  .multiselect,
            };
          }),
        );
        break;
      }
      case "txFunctionSignature": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.TxFunctionSignature,
              value: [o],
            };
            return {
              key: `txmethod:${o}`,
              filter,
              displayName: o,
              searchable: getSearchableText("txmethod", [
                o,
                formatFunctionName(o),
              ]),
              type: FilterOperators.TxFunctionSignature as const,
              multiselect:
                filterOperatorConfig[FilterOperators.TxFunctionSignature]
                  .multiselect,
            };
          }),
        );
        break;
      }
      case "warning": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.Warning,
              value: [o.type],
            };
            const displayName =
              lang.txTable.warningLabels[
                o.type as keyof typeof lang.txTable.warningLabels
              ];
            return {
              key: `warning:${o.type}`,
              filter,
              displayName,
              searchable: getSearchableText("warning", [o.type, displayName]),
              type: FilterOperators.Warning as const,
              multiselect:
                filterOperatorConfig[FilterOperators.Warning].multiselect,
            };
          }),
        );
        break;
      }
      case "importId": {
        filterOptions.push(
          ...options.map((o) => {
            const key = `${o.importType}_${o.importId}`;
            const filter: SingularFilterQuery = {
              type: FilterOperators.ImportId,
              value: [key],
            };
            return {
              key: `importid:${key}`,
              filter,
              displayName: `${
                o.exchangeName ?? lang.txTable.filter.manual
              } (${o.importType.toUpperCase()}) ${
                o.nickname ?? ""
              } ${middleTrim(o.name)}`,
              searchable: getSearchableText("importid", [key]),
              type: FilterOperators.ImportId as const,
              multiselect:
                filterOperatorConfig[FilterOperators.ImportId].multiselect,
            };
          }),
        );
        // break;
        break;
      }
      case "blockchain": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.Blockchain,
              value: [o],
            };
            return {
              filter,
              key: `blockchain:${o}`,
              // if its an unsupported blockchain, show blockchain name so we dont crash
              displayName: BlockchainName[o] ?? o,
              searchable: getSearchableText("blockchain", [
                o,
                BlockchainName[o],
              ]),
              type: FilterOperators.Blockchain as const,
              blockchain: o,
              multiselect:
                filterOperatorConfig[FilterOperators.Blockchain].multiselect,
            };
          }),
        );
        break;
      }
      case "nftCollection": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.NFTCollection,
              value: [o.contractAddress ?? ""],
            };
            return {
              key: `nftcollection:${o.id}`,
              filter,
              displayName: `${o.name} (${o.symbol})`,
              searchable: getSearchableText("nftcollection", [
                o.name,
                o.symbol,
                ...(o.contractAddress ? [o.contractAddress] : []),
              ]),
              type: FilterOperators.NFTCollection as const,
              currencyIdentifier: { ...o, nftId: undefined },
              multiselect:
                filterOperatorConfig[FilterOperators.NFTCollection].multiselect,
            };
          }),
        );
        break;
      }
      case "sync": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.Sync,
              value: [o.syncId],
            };
            const formattedDate = moment
              .utc(o.dateMs)
              .local()
              .format("YYYY-MM-DD h:mm A");
            const exchangeName: string | undefined =
              exchangeNames[o.exchangeOrAddress];
            const formattedName = formatName(
              exchangeName || o.exchangeOrAddress,
              o.nickname,
              exchangeNames,
              true,
            );
            return {
              key: `sync:${o.syncId}`,
              filter,
              displayName: `${formattedName} ${formattedDate}`,
              source: {
                id: o.exchangeOrAddress,
                name: formattedName,
                blockchain: o.blockchain,
              },
              timestamp: o.dateMs,
              txCount: o.txCount,
              searchable: getSearchableText("sync", [
                formattedDate,
                o.exchangeOrAddress,
                ...(exchangeName ? [exchangeName] : []),
                ...(o.nickname ? [o.nickname] : []),
                ...(o.blockchain ? [o.blockchain] : []),
              ]),
              type: FilterOperators.Sync as const,
              multiselect:
                filterOperatorConfig[FilterOperators.Sync].multiselect,
            };
          }),
        );
        break;
      }
      case "preset": {
        break;
      }
      case "taxOutcomeType": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.TaxOutcomeType,
              value: [o as TaxOutcomeType],
            };
            return {
              key: `taxOutcome:${o}`,
              filter,
              displayName: lang.txTable.filter.taxOutcomeType[o],
              searchable: getSearchableText("taxOutcome", [o, startCase(o)]),
              type: FilterOperators.TaxOutcomeType as const,
              multiselect:
                filterOperatorConfig[FilterOperators.TaxOutcomeType]
                  .multiselect,
            };
          }),
        );
        break;
      }
      case "erpAssetAccount": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.ErpAssetAccount,
              value: [o.code],
            };
            return {
              key: `erpassetaccount:${o.code}`,
              filter,
              displayName: `(${o.code}) ${o.name}`,
              searchable: getSearchableText("erpassetaccount", [
                o.name,
                o.code,
              ]),
              type: FilterOperators.ErpAssetAccount as const,
              multiselect:
                filterOperatorConfig[FilterOperators.ErpAssetAccount]
                  .multiselect,
            };
          }),
        );
        break;
      }
      case "erpCashAccount": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.ErpCashAccount,
              value: [o.code],
            };
            return {
              key: `erpcashaccount:${o.code}`,
              filter,
              displayName: `(${o.code}) ${o.name}`,
              searchable: getSearchableText("erpcashaccount", [o.name, o.code]),
              type: FilterOperators.ErpCashAccount as const,
              multiselect:
                filterOperatorConfig[FilterOperators.ErpCashAccount]
                  .multiselect,
            };
          }),
        );
        break;
      }
      case "erpGainsAccount": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.ErpGainsAccount,
              value: [o.code],
            };
            return {
              key: `erpgainsaccount:${o.code}`,
              filter,
              displayName: `(${o.code}) ${o.name}`,
              searchable: getSearchableText("erpgainsaccount", [
                o.name,
                o.code,
              ]),
              type: FilterOperators.ErpGainsAccount as const,
              multiselect:
                filterOperatorConfig[FilterOperators.ErpGainsAccount]
                  .multiselect,
            };
          }),
        );
        break;
      }
      case "erpLoanAccount": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.ErpLoanAccount,
              value: [o.code],
            };
            return {
              key: `erploanaccount:${o.code}`,
              filter,
              displayName: `(${o.code}) ${o.name}`,
              searchable: getSearchableText("erploanaccount", [o.name, o.code]),
              type: FilterOperators.ErpLoanAccount as const,
              multiselect:
                filterOperatorConfig[FilterOperators.ErpLoanAccount]
                  .multiselect,
            };
          }),
        );
        break;
      }
      case "erpPnlAccount": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.ErpPnlAccount,
              value: [o.code],
            };
            return {
              key: `erppnlaccount:${o.code}`,
              filter,
              displayName: `(${o.code}) ${o.name}`,
              searchable: getSearchableText("erppnlaccount", [o.name, o.code]),
              type: FilterOperators.ErpPnlAccount as const,
              multiselect:
                filterOperatorConfig[FilterOperators.ErpPnlAccount].multiselect,
            };
          }),
        );
        break;
      }
      case "erpSyncStatus": {
        filterOptions.push(
          ...Object.values(options).map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.ErpSyncStatus,
              value: [o],
            };
            return {
              key: `erpsyncstatus:${o}`,
              filter,
              displayName: lang.txTable.filter.erpSyncStatus[o],
              searchable: getSearchableText("erpsyncstatus", [
                o,
                lang.txTable.filter.erpSyncStatus[o],
              ]),
              type: FilterOperators.ErpSyncStatus as const,
              multiselect:
                filterOperatorConfig[FilterOperators.ErpSyncStatus].multiselect,
              erpSyncStatus: o,
            };
          }),
        );
        break;
      }
      case "rule": {
        filterOptions.push(
          ...Object.values(options).map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.Rule,
              value: [o.id],
            };
            return {
              key: `rule:${o.id}`,
              filter,
              displayName: o.name,
              searchable: getSearchableText("rule", [o.id, o.name]),
              type: FilterOperators.Rule as const,
              multiselect:
                filterOperatorConfig[FilterOperators.Rule].multiselect,
            };
          }),
        );
        break;
      }
      case "isSmartContractInteraction": {
        filterOptions.push(
          ...Object.values(options).map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.IsSmartContractInteraction,
              value: o,
            };
            return {
              key: `isSmartContractInteraction:${o.toString()}`,
              filter,
              displayName: o
                ? lang.txTable.filter.isSmartContractInteraction.smartContract
                : lang.txTable.filter.isSmartContractInteraction.wallet,
              searchable: getSearchableText("isSmartContractInteraction", [
                o.toString(),
              ]),
              type: FilterOperators.IsSmartContractInteraction as const,
              multiselect:
                filterOperatorConfig[FilterOperators.IsSmartContractInteraction]
                  .multiselect,
            };
          }),
        );
        break;
      }
      case "includesFee": {
        filterOptions.push(
          ...Object.values(options).map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.IncludesFee,
              value: o,
            };
            const displayName = o
              ? lang.txTable.filter.includesFee.includesFee
              : lang.txTable.filter.includesFee.doesNotIncludesFee;
            return {
              key: `includesFee:${o.toString()}`,
              filter,
              displayName,
              searchable: getSearchableText("fee", [displayName]),
              type: FilterOperators.IncludesFee as const,
              multiselect:
                filterOperatorConfig[FilterOperators.IncludesFee].multiselect,
            };
          }),
        );
        break;
      }
      case "integrations": {
        filterOptions.push(
          ...options.map((o) => {
            const filter: SingularFilterQuery = {
              type: FilterOperators.Integration,
              value: [o.value],
            };
            return {
              filter,
              key: `integration:${o.value}`,
              displayName: o.label,
              searchable: getSearchableText("integration", [o.label, o.value]),
              type: FilterOperators.Integration as const,
              multiselect:
                filterOperatorConfig[FilterOperators.Integration].multiselect,
            };
          }),
        );
        break;
      }
      default: {
        const exhaustiveCheck: never = filterType;
        console.error(`Unhandled filter option: ${exhaustiveCheck}`);
        break;
      }
    }
  }
  return filterOptions;
}

function formatName(
  name: SourceFilterOption["name"],
  nickname: SourceFilterOption["nickname"],
  exchangeNames: ExchangeNames,
  trim = false,
) {
  return nickname
    ? `${nickname} (${trim ? middleTrim(name) : name})`
    : exchangeNames[name] || (trim ? middleTrim(name) : name);
}

const StyledPaper = styled(Paper)`
  && {
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
  }
`;
