import { Typography } from "@mui/material";
import styled from "styled-components/macro";

import { devices } from "~/components/ui/theme/legacy";

export const OnboardingTitle = styled(Typography)<{
  nowrap?: boolean;
}>`
  @media ${devices.laptop} {
    ${({ theme }) => ({
      ...theme.mui.typography["Metropolis/Header/H1"],
      color: theme.tokens.text.high,
    })}
    ${({ nowrap }) => (nowrap ? "white-space: nowrap" : "")}
  }
  ${({ theme }) => ({
    ...theme.mui.typography["Metropolis/Header/H3"],
    color: theme.tokens.text.high,
  })}
`;
