import { OpenInNew } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { importAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import Youtube from "~/assets/youtube.svg?react";
import { TertiaryButtonLink } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type ImportTypeInstruction } from "~/types/index";

export const ImportVideoV2 = ({ note }: { note: ImportTypeInstruction }) => {
  const { tokens } = useDesign();
  const lang = useLang();

  const captureAnalytics = useCaptureAnalytics();
  const analyticsKey = importAnalyticsKey("import video");

  if (!note.link) {
    return null;
  }

  return (
    <TertiaryButtonLink
      to={note.link}
      target="_blank"
      rel="noopener noreferrer"
      fullWidth
      onClick={() => {
        captureAnalytics(analyticsKey("clicked"), {
          videoTitle: note.title,
        });
      }}
      sx={{
        borderRadius: "0.25rem",
        border: `1px solid ${tokens.border.neutral.default}`,
        backgroundColor: tokens.elevation.low,
        width: "9.375rem",
        height: "1.875rem",
        padding: "0.375rem 0.75rem !important",
        mr: "0.45rem",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Box display="flex" gap="0.5rem">
          <Youtube />
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            color={tokens.text.low}
          >
            {lang.watchVideo}
          </Typography>
        </Box>
        <OpenInNew
          sx={{
            color: tokens.text.low,
            width: "1rem",
          }}
        />
      </Box>
    </TertiaryButtonLink>
  );
};
