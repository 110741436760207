import {
  type Blockchain,
  type LocalCurrency,
  type Trade,
} from "@ctc/types";
import { Box, Tooltip, Typography } from "@mui/material";
import { type ReactNode, useState } from "react";

import { ActionRowHover } from "~/components/transactions/action-row/ActionRowHover";
import { Overflower } from "~/components/transactions/action-row/Overflower";
import { Warnings } from "~/components/transactions/action-row/Warnings";
import { EditActionAmountsDialog } from "~/components/transactions/edit/EditActionAmounts";
import { Ellipsis } from "~/components/transactions/Ellipsis";
import {
  formatDisplayAddress,
  getTokenLink,
} from "~/components/transactions/helpers";
import { Address } from "~/components/ui/Address";
import {
  CensoredBox,
  CensoredTooltip,
} from "~/components/ui/CensoredComponents";
import { Chip } from "~/components/ui/Chips";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { AddressVariant } from "~/components/ui/enums";

import { NFT_ID_MAX_DISPLAY_LENGTH } from "~/constants/constants";
import { useDesign } from "~/hooks/useTheme";
import {
  displayCryptoQuantity,
  displayFiatValue,
  isFiatCurrency,
  middleTrim,
} from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { isBridgeTrade } from "~/services/transactions";
import { QuickEditFocusField, Side, Size, Warning } from "~/types/enums";
import {
  type ActionRow,
  type CurrencyIdentifier,
  type TransactionDetails,
} from "~/types/index";

import { getCompactFormattedValue } from "./helpers";

export function AmountText({
  side,
  amount,
  currency,
  price,
  enableDirection = true,
  blockchain,
  size,
}: {
  side?: Side;
  amount: number;
  currency: CurrencyIdentifier;
  price?: number;
  enableDirection?: boolean;
  blockchain?: string;
  size?: Size;
}) {
  const { tokens } = useDesign();
  const localCurrency = useLocalCurrency();
  const getAmountText = useGetTxAmountText();
  const isFiat = isFiatCurrency(currency.id);

  const amountString = getAmountText({
    currencyIdentifier: currency,
    quantity: amount,
  });

  const compactValue = getCompactFormattedValue(
    useLanguagePreference(),
    amount,
    localCurrency as LocalCurrency,
    amountString,
  );

  return (
    <CurrencyAmountPriceValueTooltip
      tx={{
        quantity: amount,
        currencyIdentifier: currency,
        price: price || 0,
        blockchain,
      }}
    >
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        fontFamily={tokens.fontFamilies.numeric}
        sx={{
          color: tokens.text.default,
          fontSize: size === Size.Small ? "0.75rem" : undefined,
          display: "flex",
          alignItems: "center",
        }}
      >
        {enableDirection && <span>{side === Side.From ? "-" : "+"}</span>}
        <span>
          <Ellipsis maxWidth={150}>{compactValue}</Ellipsis>
        </span>
        {!isFiat ? (
          <span style={{ marginLeft: "0.25rem" }}>
            <Ellipsis maxWidth={100} data-uncensored="true">
              {currency.symbol}
            </Ellipsis>
          </span>
        ) : null}
      </Typography>
    </CurrencyAmountPriceValueTooltip>
  );
}

export type TxAmountProps = {
  tx: {
    trade: Trade;
    currencyIdentifier: CurrencyIdentifier;
    blockchain?: string;
    quantity: number;
    price: number;
    txIds: string[];
  };
  // Side controls showing the + or -
  side: Side;
  isTransferLike?: boolean;
  row: ActionRow;
};

export function TxAmount({ row, tx, side, isTransferLike }: TxAmountProps) {
  const showBlockchainSymbol = isBridgeTrade(tx.trade);
  const [isValueDialogueOpen, setIsValueDialogueOpen] = useState(false);
  const handleOpen = () => {
    setIsValueDialogueOpen(!isValueDialogueOpen);
  };

  const handleClose = () => {
    setIsValueDialogueOpen(false);
  };

  return (
    <>
      <EditActionAmountsDialog
        row={row}
        isOpen={isValueDialogueOpen}
        handleClose={handleClose}
        focusField={
          side === Side.From
            ? QuickEditFocusField.Outgoing
            : QuickEditFocusField.Incoming
        }
      />

      <Overflower>
        <Box display="flex" flexWrap="wrap" gap="0.5rem" alignItems="center">
          <ActionRowHover
            display="flex"
            flexWrap="nowrap"
            gap="0.5rem"
            sx={{
              "& svg": {
                visibility: "hidden",
              },
              cursor: "pointer",
              borderRadius: "0.25rem",
              "&:hover": {
                "& svg": {
                  visibility: "visible",
                },
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleOpen();
            }}
          >
            <CurrencyLogo
              currency={tx.currencyIdentifier}
              width={20}
              height={20}
              margin="0"
              style={{
                borderRadius: "100%",
                background: "transparent",
              }}
              blockchain={tx.blockchain as Blockchain}
              showBlockchainSymbol={showBlockchainSymbol}
            />
            <AmountText
              side={side}
              amount={tx.quantity}
              currency={tx.currencyIdentifier}
              price={tx.price}
              enableDirection={!isTransferLike}
              blockchain={tx.blockchain}
            />
            {tx.currencyIdentifier.nftId ? (
              <CensoredTooltip
                title={`Token ID: #${tx.currencyIdentifier.nftId}`}
              >
                <CensoredBox display="flex">
                  <Chip>
                    {`#${middleTrim(
                      tx.currencyIdentifier.nftId,
                      NFT_ID_MAX_DISPLAY_LENGTH,
                    )}`}
                  </Chip>
                </CensoredBox>
              </CensoredTooltip>
            ) : null}
            <Warnings
              row={row}
              txIds={tx.txIds}
              warnings={[Warning.NegativeBalance, Warning.MissingBlockchain]}
            />
          </ActionRowHover>
        </Box>
      </Overflower>
    </>
  );
}

export function useGetTxAmountText() {
  const languagePreference = useLanguagePreference();
  return <
    T extends Pick<TransactionDetails, "currencyIdentifier" | "quantity">,
  >(
    tx: T,
  ) => {
    const isFiat = isFiatCurrency(tx.currencyIdentifier.id);
    const fiatValue = isFiat
      ? displayFiatValue({
          value: tx.quantity,
          localCurrency: tx.currencyIdentifier.symbol as LocalCurrency,
          locale: languagePreference,
        })
      : "";
    return isFiat
      ? fiatValue
      : displayCryptoQuantity({
          quantity: tx.quantity,
          locale: languagePreference,
          precision: 6,
        });
  };
}

export function CurrencyAmountPriceValueTooltip<
  T extends Pick<
    TransactionDetails,
    "currencyIdentifier" | "blockchain" | "quantity" | "price"
  >,
>({ tx, children }: { tx: T; children: ReactNode }) {
  const { currencyIdentifier: currency, blockchain, quantity, price } = tx;
  const { tokens } = useDesign();
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const isFiat = isFiatCurrency(tx.currencyIdentifier.id);

  const fiatValue = isFiat
    ? displayFiatValue({
        value: quantity,
        localCurrency: currency.symbol as LocalCurrency,
        locale: languagePreference,
      })
    : "";

  const currencyAddress = currency.contractAddress ? (
    <Box
      display="flex"
      alignItems="center"
      gap="0.25rem"
      color={tokens.text.low}
    >
      <span>{lang.txTable.contractAddress}:</span>
      <Address
        size={Size.Small}
        color={tokens.text.low}
        address={currency.contractAddress}
        addressLabel={formatDisplayAddress(
          currency.contractAddress,
          false,
          true,
        )}
        variant={AddressVariant.Text}
        enableTooltip={false}
        addressLinkExternal={
          blockchain
            ? getTokenLink(currency.contractAddress, undefined, blockchain)
            : undefined
        }
      />
    </Box>
  ) : null;

  const tooltipTitle = (
    <Box style={{ whiteSpace: "nowrap" }}>
      <Box>
        {isFiat
          ? fiatValue
          : `${quantity} ${currency.name} (${currency.symbol})${
              price
                ? ` @ ${displayFiatValue({
                    value: price,
                    localCurrency,
                    locale: languagePreference,
                  })}/${currency.symbol}`
                : ""
            }`}
      </Box>
      {currencyAddress}
    </Box>
  );

  return (
    <Tooltip
      title={tooltipTitle}
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "inherit",
          },
        },
      }}
    >
      <span>{children}</span>
    </Tooltip>
  );
}
