import { ErrorOutline } from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";
import HistoryIcon from "@mui/icons-material/History";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { type ReactNode } from "react";
import styled from "styled-components/macro";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureActionAnalytics } from "~/analytics/posthog";
import { CommentsBox } from "~/components/transactions/action-row/actionCommentsBox";
import { ActionHistory } from "~/components/transactions/action-row/ActionHistory";
import { ActionBreakdownTable } from "~/components/transactions/action-row/actionRowBreakdownTable";
import { ActionViewBalance } from "~/components/transactions/action-row/actionViewBalance";
import { ChartOfAccountsTable } from "~/components/transactions/action-row/ChartOfAccountsTable";
import { TransactionDetailsInfoPanel } from "~/components/transactions/action-row/TransactionDetailsInfoPanel";
import { BreakdownTab } from "~/components/transactions/enums";
import { useDesign } from "~/hooks/useTheme";
import { useCanAccessFeature } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  useActionBalancesQuery,
  useActionBreakdownQuery,
} from "~/state/actions";
import { useErpSettingsQuery } from "~/state/erp";
import { Features } from "~/types/enums";
import { type ActionRow } from "~/types/index";

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
  transactionId: string;
};

const StyledTab = styled(Tab)`
  min-height: initial;
  padding: 0.8rem 1.5rem 0.625rem 1.5rem;
  text-transform: uppercase;
  font-size: 0.8125rem;
`;

function TabPanel(props: TabPanelProps) {
  const { children, transactionId, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${transactionId}-tabpanel-${index}`}
      aria-labelledby={`${transactionId}-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(transactionId: string, index: number) {
  return {
    id: `${transactionId}-tab-${index}`,
    "aria-controls": `${transactionId}-tabpanel-${index}`,
  };
}

function DetailsTabsHeader({
  row,
  tab,
  onTabChanged,
}: {
  row: ActionRow;
  tab: BreakdownTab;
  onTabChanged: (tab: BreakdownTab) => void;
}) {
  const { tokens } = useDesign();
  const res = useActionBalancesQuery(row._id);
  const lang = useLang();
  const erpSettings = useErpSettingsQuery();
  const userHasErpFeature = useCanAccessFeature(Features.ERP);

  const hideBalanceTab =
    res.data && (!res.data.from || !res.data.to || !res.data.rows.length);

  const captureActionAnalytics = useCaptureActionAnalytics();
  const analyticsKey = transactionAnalyticsKey("history");

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={tab}
        onChange={(_, value) => {
          if (value === BreakdownTab.History) {
            captureActionAnalytics(analyticsKey("viewed"), row);
          }
          onTabChanged(value);
        }}
        aria-label="basic tabs example"
        variant="scrollable"
      >
        <StyledTab
          label={lang.txTable.expansionPanel.transactionsTab}
          {...a11yProps(row._id, 0)}
          value={BreakdownTab.Transactions}
        />
        {!hideBalanceTab ? (
          <StyledTab
            label={lang.txTable.expansionPanel.viewBalance}
            value={BreakdownTab.ViewBalance}
            icon={
              res.data?.hasBalanceErrors ? (
                <ErrorOutline
                  fontSize="small"
                  sx={{ color: tokens.icon.danger }}
                />
              ) : undefined
            }
            iconPosition="end"
            {...a11yProps(row._id, 1)}
          />
        ) : null}
        {userHasErpFeature && erpSettings.data?.connectedAt && (
          <StyledTab
            label={lang.txTable.expansionPanel.chartOfAccounts.title}
            {...a11yProps(row._id, 0)}
            value={BreakdownTab.ChartOfAccounts}
          />
        )}
        <StyledTab
          label={lang.txTable.expansionPanel.comments.comments({
            count: row.comments?.length || 0,
          })}
          value={BreakdownTab.Comments}
          icon={<CommentIcon fontSize="small" />}
          iconPosition="end"
          {...a11yProps(row._id, 2)}
        />
        <StyledTab
          label={lang.txTable.expansionPanel.history.title}
          icon={<HistoryIcon fontSize="small" />}
          iconPosition="end"
          value={BreakdownTab.History}
          {...a11yProps(row._id, 3)}
        />
      </Tabs>
    </Box>
  );
}

function DetailsTabPanel({ row, tab }: { row: ActionRow; tab: BreakdownTab }) {
  return (
    <>
      <TabPanel
        transactionId={row._id}
        value={tab}
        index={BreakdownTab.ChartOfAccounts}
      >
        <ChartOfAccountsTable />
      </TabPanel>

      <TabPanel
        transactionId={row._id}
        value={tab}
        index={BreakdownTab.Transactions}
      >
        <TaxBreakdownTab row={row} />
      </TabPanel>
      <TabPanel
        transactionId={row._id}
        value={tab}
        index={BreakdownTab.ViewBalance}
      >
        <ActionViewBalance row={row} />
      </TabPanel>
      <TabPanel
        transactionId={row._id}
        value={tab}
        index={BreakdownTab.Comments}
      >
        <Box p="1rem">
          <CommentsBox row={row} />
        </Box>
      </TabPanel>
      <TabPanel
        transactionId={row._id}
        value={tab}
        index={BreakdownTab.History}
      >
        <ActionHistory row={row} />
      </TabPanel>
    </>
  );
}

function TaxBreakdownTab({ row }: { row: ActionRow }) {
  const res = useActionBreakdownQuery(row._id);

  return (
    <>
      <TransactionDetailsInfoPanel row={row} />
      {res.data && !res.isPending ? (
        <ActionBreakdownTable data={res.data} />
      ) : (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          py="2rem"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export function ActionRowDetails({
  row,
  tab,
  onTabChanged,
}: {
  row: ActionRow;
  tab: BreakdownTab;
  onTabChanged: (tab: BreakdownTab) => void;
}) {
  const { tokens } = useDesign();
  return (
    <Box width="100%" bgcolor={tokens.elevation.low} p="0.5rem">
      <Box bgcolor={tokens.elevation.default} fontSize="0.75rem" width="100%">
        <DetailsTabsHeader row={row} tab={tab} onTabChanged={onTabChanged} />
        <DetailsTabPanel row={row} tab={tab} />
      </Box>
    </Box>
  );
}
