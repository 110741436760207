import { Box } from "@mui/material";
import * as React from "react";

import { NewInvitationDialog } from "~/components/settings-modal/views/permissions/NewInvitationDialog";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { InvitationStatusDetails } from "~/components/ui/InvitationStatusDetails";
import { useLang } from "~/redux/lang";
import { InvitationType } from "~/types/enums";
import { type ClientDetails } from "~/types/index";

enum Variant {
  Outlined = "outlined",
  Contained = "contained",
}

export function ClientInviteStatus({
  client,
  verbose = false,
}: {
  client: ClientDetails;
  variant?: Variant;
  verbose?: boolean;
}) {
  const lang = useLang();
  const { invitationDetails, uuid, isDefaultAccount } = client;

  const [open, setOpen] = React.useState(false);

  const handleInvite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  if (isDefaultAccount) {
    return null;
  }

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <NewInvitationDialog
        open={open}
        handleClose={handleDialogClose}
        invitationType={InvitationType.Client}
        clientProfile={uuid}
      />
      <Box
        display="flex"
        justifyContent="flex-start"
        width={!verbose ? "6rem" : "auto"}
      >
        {invitationDetails ? (
          <InvitationStatusDetails
            invitation={invitationDetails}
            verbose={verbose}
          />
        ) : (
          <Box>
            <PrimaryButton onClick={handleInvite}>{lang.invite}</PrimaryButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}
