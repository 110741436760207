export default {
  importInstructions: {
    abra: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Der Crypto Tax Calculator ignoriert Überweisungen im Zusammenhang mit zinsbringenden Kryptowährungen, da es sich hierbei nicht um steuerpflichtige Vorgänge handelt. Daher kann die Anzahl der importierten Zeilen geringer sein als die Gesamtzahl der ursprünglichen Zeilen in der CSV-Datei.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Bitte lesen und befolgen Sie die detaillierten Exportschritte für Abra auf deren Website: https://support.abra.com/hc/en-us/articles/360002528832-How-can-I-obtain-my-full-transaction-history-, um eine CSV-Datei per E-Mail zu erhalten.",
              1: () =>
                "Sobald Sie die E-Mail mit der CSV-Datei erhalten haben, laden Sie sie herunter und laden Sie die CSV-Datei hier hoch.",
              2: () =>
                "Wenn Sie mehrere Abra-Wallets haben, wiederholen Sie diese Schritte für jedes einzelne.",
            },
          },
        },
      },
    },
    abstract: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Open your Abstract wallet",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the address into Crypto Tax Calculator, add an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    agoric: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Agoric in der frühen Entwicklung",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Agoric-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    airswap: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () => "Wählen Sie die Börse aus dem Dropdown-Menü",
              3: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              4: () => "Behalten Sie die anderen Standardeinstellungen bei",
              5: () => "Klicken Sie auf „Verlauf abrufen“.",
              6: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              7: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    akash: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Akash-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    algorand: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () => "Kopieren Sie Ihre öffentliche Algorand-Wallet-Adresse",
              2: () =>
                "Wählen Sie in CTC die entsprechende Blockchain aus der Dropdown-Liste",
              3: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    altcointrader: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem AltCoinTrader-Konto an",
              1: () =>
                "Laden Sie Ihre Einzahlungs-, Auszahlungs- und alle Ihre Handels-CSV-Dateien von AltCoinTrader herunter",
              2: () =>
                "Laden Sie diese Dateien hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    aptos: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr Aptos-Wallet",
              1: () =>
                "Kopieren Sie Ihre öffentliche Wallet-Adresse, die mit „0x…“ beginnt.",
              2: () =>
                "Fügen Sie die Adresse in den Crypto Tax Calculator ein, fügen Sie einen optionalen Spitznamen hinzu und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    arbitrum: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen jetzt ERC-1155-Token, können jedoch Ihre aktuellen Guthaben für diese Token nicht importieren. Dies kann zu Abweichungen zwischen dem aus der Quell-API importierten Guthaben und dem von der App berechneten Guthaben führen.",
            },
            title: () => "ERC-1155-Token",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Wallet-Adresse, die Sie für den Handel über Arbitrum verwendet haben (beginnend mit 0x)",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    "arbitrum-nova": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Wallet-Adresse, die Sie für den Handel über Arbitrum verwendet haben (beginnend mit 0x)",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    archway: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Archway-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    atani: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Atani befindet sich derzeit in der Betaphase und wird weiterentwickelt. Bitte informieren Sie unser Supportteam, wenn bei der Integration Probleme auftreten.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Atani-Konto an",
              1: () => "Navigieren Sie zur Registerkarte „Portfolio“",
              2: () =>
                "Klicken Sie auf „Berichte herunterladen“ und wählen Sie „Historische Daten“",
              3: () => "Wählen Sie Ihren gewünschten Datumsbereich",
              4: () =>
                "Klicken Sie auf „Herunterladen“, um Ihren Bericht zu erhalten",
            },
          },
        },
      },
    },
    atomicwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Aufgrund von Einschränkungen der CSV-Datei mit dem Transaktionsverlauf von Atomic Wallet können wir die Kaufseite von In-App-Trades nicht automatisch importieren. Wenn Sie Trades innerhalb von Atomic Wallet getätigt haben, generieren Sie bitte die Kaufseite des Trades über unsere Schaltfläche „Manuelle Transaktion hinzufügen“ oder indem Sie eine benutzerdefinierte CSV-Datei importieren. Verwenden Sie dazu die nachstehenden Anweisungen zum Importieren benutzerdefinierter Transaktionen.",
            },
            title: () => "In-App-Handel",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Wählen Sie auf der Registerkarte „Wallet“ die Münze aus, für die Sie Ihren Verlauf benötigen.",
              1: () =>
                "Klicken Sie oben rechts auf das Zahnradsymbol für die Einstellungen.",
              2: () => "Klicken Sie auf „Transaktionen exportieren“.",
              3: () =>
                "Eine CSV-Datei mit Ihrem Transaktionsverlauf für diese Währung sollte in Ihren Desktop-Ordner heruntergeladen worden sein.",
              4: () =>
                "Importieren Sie diese Datei in den Crypto Tax Calculator und geben Sie das Währungssymbol an. Für ein Bitcoin-Wallet müssen Sie beispielsweise „BTC“ einfügen.",
            },
          },
          1: {
            title: () =>
              "Hinweis: Bitte verwenden Sie nicht die Seite „Verlauf“ in der Menüleiste, um einen CSV-Bericht zu erstellen. Für jede Währung, mit der Sie interagiert haben, muss ein separater Bericht erstellt werden.",
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr Atomic-Wallet.",
              1: () =>
                "Wählen Sie die Kryptowährung aus, die Sie importieren möchten. Zum Beispiel Bitcoin oder Ethereum.",
              2: () =>
                "Kopieren Sie die entsprechende öffentliche Wallet-Adresse für diesen Vermögenswert. Um diese zu finden, müssen Sie möglicherweise auf „Empfangen“ klicken.",
              3: () =>
                "Wählen Sie in CTC das entsprechende Blockchain-Netzwerk aus der Dropdown-Liste aus.",
              4: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    aurora: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Wir haben derzeit einige Probleme beim Sammeln von Überbrückungstransaktionen und arbeiten an einer Lösung. Wenn dieses Problem in der Zwischenzeit auftritt, versuchen Sie stattdessen unseren manuellen CSV-Upload-Prozess. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Überbrückungstransaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Aurora-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    avalanche: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Bei der Importierung aller Adressen können unter der von Ihnen angegebenen Adresse möglicherweise Probleme auftreten, da Avalanche aus Sicherheitsgründen versucht, die Beziehungen zwischen Adressen zu verschleiern.",
              1: () =>
                "Daher müssen Sie möglicherweise zusätzliche Adressen importieren, wie in diesem Handbuch beschrieben: https://cryptotaxcalculator.io/integrations/avalanche-tax/#please-note.",
            },
          },
          1: {
            items: {
              0: () =>
                "Aufgrund von Problemen mit unserem API-Anbieter können wir Ihre Transaktionen nicht auf der X- und P-Kette abrufen. Wir prüfen derzeit alternative Anbieter, um dieses Problem zu beheben.",
            },
            title: () => "Fehlende X- und P-Kettentransaktionen",
          },
          2: {
            items: {
              0: () =>
                "Wir unterstützen jetzt ERC-1155-Token, können jedoch Ihre aktuellen Guthaben für diese Token nicht importieren. Dies kann zu Abweichungen zwischen dem aus der Quell-API importierten Guthaben und dem von der App berechneten Guthaben führen.",
            },
            title: () => "ERC-1155-Token",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Um Ihre C-Chain-Adresse (beginnend mit 0x) zu importieren, geben Sie sie einfach in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    bambooRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    base: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Base-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    bch: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
              1: () =>
                "Wir unterstützen auch xpub, ypub und zpub Adressen, allerdings wird xpub bevorzugt",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Bitcoin Cash-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    berachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen jetzt ERC-1155-Token, können jedoch Ihre aktuellen Guthaben für diese Token nicht importieren. Dies kann zu Abweichungen zwischen dem aus der Quell-API importierten Guthaben und dem von der App berechneten Guthaben führen.",
            },
            title: () => "ERC-1155-Token",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Berachain-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    bigone: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem BigONE-Konto an",
              1: () => "Navigieren Sie zu Ihren Kontoeinstellungen",
              2: () =>
                "Unter „Exchange Settings“ können Sie einen API-Schlüssel generieren",
              3: () =>
                "Geben Sie hier sowohl den „API-Schlüssel“ als auch das „API-Geheimnis“ in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
    },
    binance: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützen wir die API-Synchronisierung historischer Aufzeichnungen für die folgenden Vorgänge: Krypto-Einzahlungen und -Abhebungen, Fiat-Einzahlungen und -Abhebungen, Spot-Trades, Konvertierungen und Verteilungen kleiner BNB-„Dust“-Guthaben.",
              1: () =>
                "Die Binance-API hat bestimmte Einschränkungen – Trades mit dekotierten Coins können nicht synchronisiert werden. Wenn Sie Trades wie diese haben, empfehlen wir Ihnen, Ihren Verlauf stattdessen per CSV hochzuladen.",
              2: () =>
                "Aufgrund von Einschränkungen der Binance-API können nur die letzten 90 Tage an OTC-Trades und Krypto-zu-Krypto-Konvertierungen abgerufen werden.",
              3: () =>
                "Seit September 2020 bietet Binance keinen API-Endpunkt mehr, um historische Daten für bestimmte Münzkäufe aus „Cash Balance“-Transaktionen abzurufen. Darüber hinaus bietet Binance keinen Endpunkt für „Locked Staking“ (https://dev.binance.vision/t/earn-locked-staking/1453).",
              4: () =>
                "Binance bietet keinen API-Endpunkt zum Abrufen historischer Daten für Fiat-Kaufhistorie, Binance Leveraged Tokens (BLVT), Binance Liquid Swap (Swaps), Binance Savings Interest, USD-M Futures Income, COIN-M Futures Income, Cross Margin Trades und Isolated Margin Trades.",
              5: () => "So fügen Sie nicht unterstützte Transaktionen hinzu:",
              6: () =>
                "1. Laden Sie die CSV-Vorlage herunter und öffnen Sie sie",
              7: () => "2. Füllen Sie Ihre Transaktionsdetails aus",
              8: () =>
                "3. Achten Sie darauf, alle enthaltenen Felder auszufüllen, um Fehler beim Hochladen zu vermeiden",
              9: () =>
                "4. Exportieren Sie Ihr Dokument als CSV und laden Sie es hier hoch",
              10: () => "5. CSV direkt zum zugehörigen Wallet hinzufügen",
            },
            title: () => "API-Synchronisierung – Fehlende Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Gehen Sie in Ihrem Binance-Konto im Dropdown-Menü „Profil“ zu „API-Verwaltung“. (https://www.binance.com/en/my/settings/api-management)",
              1: () =>
                "Klicken Sie auf „API erstellen“, wählen Sie „Systemgeneriert“ als API-Schlüsseltyp und klicken Sie auf „Weiter“.",
              2: () =>
                "Legen Sie eine Bezeichnung Ihrer Wahl fest, klicken Sie auf „Weiter“ und schließen Sie die Zwei-Faktor-Authentifizierung ab.",
              3: () =>
                "Belassen Sie den IP-Zugriff auf „Uneingeschränkt“ und stellen Sie sicher, dass „Lesen aktivieren“ aktiviert ist.",
              4: () =>
                "Fügen Sie die API- und Geheimschlüssel in den Crypto Tax Calculator ein und klicken Sie auf „Binance API hinzufügen“.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/19oc30s0mVM",
            title: () => "So importieren Sie Ihre Binance-API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Binance hat kürzlich die CSV-Exporte auf einen maximalen Bereich von 3 Monaten auf einmal beschränkt, wobei nur 6 Exporte pro Monat erlaubt sind. Wenn Sie viel gehandelt haben, empfehlen wir die Verwendung der API-Importoption",
              1: () =>
                "Derzeit unterstützt unsere CSV-Importoption Einzahlungen, Abhebungen, Fiat-, Spot-, Coin-Futures-, USDT-Futures-, Isolated Margin- und Cross-Margin-Transaktionen.",
              2: () =>
                "Wenn Sie von Binance einen '.zip'- oder '.tar.gz'-Ordner erhalten haben, müssen Sie zuerst den Inhalt des komprimierten Ordners extrahieren. Sie können einen Dienst wie https://extract.me/ verwenden, um den Inhalt zu extrahieren. Anschließend können Sie die extrahierte(n) Datei(en) auf den Crypto Tax Calculator hochladen.",
              3: () =>
                "Binance berücksichtigt in seiner CSV-Datei keine Handelsgebühren für P2P-Trades. Wir empfehlen den Import über die API, wenn Sie bereits P2P-Trades durchgeführt haben.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Binance-Konto an und gehen Sie zu Wallet > Übersicht.",
              1: () =>
                "Klicken Sie auf „Bestellungen“ > „Transaktionsverlauf“.",
              2: () =>
                "Klicken Sie oben rechts auf „Transaktionsdatensätze exportieren“.",
              3: () =>
                "Wählen Sie „Anpassen“ für den Zeitraum und legen Sie die Start-/Enddaten fest (bis zu 12 Monate auf einmal).",
              4: () =>
                "Stellen Sie sicher, dass für Konto, Münze und Unterkonto „Alle“ ausgewählt ist.",
              5: () =>
                "Klicken Sie auf „Generieren“ und warten Sie auf den Bericht.",
              6: () =>
                "Wiederholen Sie dies für Ihren gesamten Handelsverlauf.",
              7: () =>
                "Wenn die Datei das Format „.zip“ oder „.tar.gz“ hat, extrahieren Sie sie vor dem Hochladen.",
              8: () =>
                "Laden Sie die extrahierten Dateien zum Crypto Tax Calculator hoch.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/N7TNcCW2N7g",
            title: () => "So importieren Sie Ihre Binance CSV-Dateien",
          },
        },
      },
    },
    "binance-smart-chain": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen jetzt ERC-1155-Token, können jedoch Ihre aktuellen Guthaben für diese Token nicht importieren. Dies kann zu Abweichungen zwischen dem aus der Quell-API importierten Guthaben und dem von der App berechneten Guthaben führen.",
            },
            title: () => "ERC-1155-Token",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Binance Smart Chain-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    binancechain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Binance hat die Binance Chain (BNB Beacon Chain) offiziell geschlossen. Infolgedessen können historische Daten dieser Chain nicht mehr über die API abgerufen werden. Zuvor importierte Transaktionen bleiben in Ihrem Konto zugänglich. Sie können Ihren Transaktionsverlauf bis zum 3. Dezember 2024 weiterhin auf https://explorer.bnbchain.org/ einsehen. Um neue Transaktionen hinzuzufügen, verwenden Sie bitte die benutzerdefinierte CSV-Importoption. Weitere Einzelheiten zur Schließung finden Sie unter https://www.bnbchain.org/en/bnb-chain-fusion.",
            },
            title: () => "Abschaltung der Binance Chain (BNB Beacon Chain)",
          },
        },
        step: {
          0: {
            title: () =>
              "Binance hat die Binance Chain (BNB Beacon Chain) offiziell geschlossen. Infolgedessen können historische Daten dieser Chain nicht mehr über die API abgerufen werden.",
          },
        },
      },
    },
    binancedex: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Dadurch werden die auf Binance DEX durchgeführten Trades sowie alle mit dieser Wallet verbundenen Binance Chain-Transaktionen synchronisiert.",
              1: () =>
                "In einigen Fällen sind keine Daten für den Empfang von BNB- und BEP2-Tokens über eine Cross Chain Bridge in Ihrem Binance Chain-Wallet verfügbar. In diesem Fall müssen Sie die entsprechenden „Empfangs“-Transaktionen möglicherweise manuell in den Crypto Tax Calculator eingeben.",
              2: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Binance Chain (BEP2)-Wallet-Adresse, die Sie für den Handel über Binance DEX verwendet haben",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    binanceus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützen wir die API-Synchronisierung historischer Aufzeichnungen für die folgenden Vorgänge: Krypto-Einzahlungen und -Abhebungen, Fiat-Einzahlungen und -Abhebungen, Spot-Trades, Konvertierungen und Verteilungen kleiner BNB-„Dust“-Guthaben.",
              1: () =>
                "Die Binance-API hat bestimmte Einschränkungen – Trades mit dekotierten Coins können nicht synchronisiert werden. Wenn Sie Trades wie diese haben, empfehlen wir Ihnen, Ihren Verlauf stattdessen per CSV hochzuladen.",
              2: () =>
                "Aufgrund von Einschränkungen der Binance-API können nur die letzten 90 Tage an OTC-Trades und Krypto-zu-Krypto-Konvertierungen abgerufen werden.",
              3: () =>
                "Seit September 2020 bietet Binance keinen API-Endpunkt mehr, um historische Daten für bestimmte Münzkäufe aus „Cash Balance“-Transaktionen abzurufen. Darüber hinaus bietet Binance keinen Endpunkt für „Locked Staking“ (https://dev.binance.vision/t/earn-locked-staking/1453).",
              4: () =>
                "Binance bietet keinen API-Endpunkt zum Abrufen historischer Daten für Fiat-Kaufhistorie, Binance Leveraged Tokens (BLVT), Binance Liquid Swap (Swaps), Binance Savings Interest, USD-M Futures Income, COIN-M Futures Income, Cross Margin Trades und Isolated Margin Trades.",
              5: () => "So fügen Sie nicht unterstützte Transaktionen hinzu:",
              6: () =>
                "1. Laden Sie die CSV-Vorlage herunter und öffnen Sie sie",
              7: () => "2. Füllen Sie Ihre Transaktionsdetails aus",
              8: () =>
                "3. Achten Sie darauf, alle enthaltenen Felder auszufüllen, um Fehler beim Hochladen zu vermeiden",
              9: () =>
                "4. Exportieren Sie Ihr Dokument als CSV und laden Sie es hier hoch",
              10: () => "5. CSV direkt zum zugehörigen Wallet hinzufügen",
            },
            title: () => "API-Synchronisierung – Fehlende Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem BinanceUS-Konto an",
              1: () =>
                "Bewegen Sie den Mauszeiger oben rechts in der Navigationsleiste über das Symbol „Profil“ und klicken Sie im Dropdown-Menü auf „API-Verwaltung“ (https://www.binance.com/en/my/settings/api-management).",
              2: () =>
                "Geben Sie als Bezeichnung für eine neue API „Crypto Tax Calculator“ ein und klicken Sie auf „API erstellen“ (gelber Button).",
              3: () =>
                "Möglicherweise müssen Sie einige Bestätigungsinformationen eingeben",
              4: () =>
                "Kopieren Sie nach der Erstellung den „API-Schlüssel“ und den „Geheimschlüssel“.",
              5: () =>
                "Klicken Sie auf Einschränkungen bearbeiten und deaktivieren Sie „Spot-Trading aktivieren“. Stellen Sie außerdem sicher, dass „Abhebungen aktivieren“ deaktiviert ist.",
              6: () =>
                "Überprüfen Sie noch einmal, dass nur die Option „Kann lesen“ aktiviert ist!",
              7: () =>
                "Belassen Sie die Auswahl der IP-Zugriffsbeschränkung auf „Uneingeschränkt“.",
              8: () =>
                "Fügen Sie den kopierten „API-Schlüssel“ und „Geheimschlüssel“ hier in den Crypto Tax Calculator ein und klicken Sie auf „API hinzufügen“.",
              9: () =>
                "Die Synchronisierung dieser API kann aufgrund von Einschränkungen auf der Seite von BinanceUS etwa 20 Minuten dauern",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützt unsere CSV-Importoption nur Einzahlungen, Abhebungen und Fiat/Spot Wallet Trades. Wenn Sie andere Arten von historischen Aufzeichnungen haben, importieren Sie diese bitte über unsere Option „Autosync einrichten“.",
              1: () =>
                "Wenn Sie von BinanceUS einen .zip- oder .tar.gz-Ordner erhalten haben, müssen Sie zuerst den Inhalt des komprimierten Ordners extrahieren. Sie können einen Dienst wie https://extract.me/ verwenden, um den Inhalt zu extrahieren. Anschließend können Sie die extrahierte(n) Datei(en) in den Crypto Tax Calculator hochladen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem BinanceUS-Konto an und klicken Sie in der Navigationsleiste auf „Wallet“.",
              1: () =>
                "Wählen Sie die Option „Berichte exportieren“ in gelber Schrift auf der rechten Seite.",
              2: () =>
                "Wählen Sie unter „Benutzerdefinierte Berichte“ bei den Vermögenswert- und Transaktionstypen „Alle“ aus. Wählen Sie bei „Bereich“ den gewünschten Zeitraum aus (bis zu maximal einem Jahr auf einmal, siehe Schritt 5) und wählen Sie „CSV“ als Dateiformat.",
              3: () =>
                "Klicken Sie auf „Generieren“ und warten Sie, bis der Bericht erstellt wird – dies kann einige Zeit dauern.",
              4: () =>
                "Wiederholen Sie den Export für jedes Jahr, in dem Sie auf BinanceUS gehandelt haben.",
              5: () =>
                "Wenn die Datei das Format „.zip“ oder „.tar.gz“ hat, müssen Sie vor dem Hochladen zuerst den Inhalt des komprimierten Ordners extrahieren. Beachten Sie hierzu die Hinweise.",
              6: () => "Laden Sie die Dateien zum Crypto Tax Calculator hoch.",
            },
            title: () =>
              "Für Einzahlungen und Abhebungen sowie Spot-Kontogeschäfte:",
          },
        },
      },
    },
    bingx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem BingX-Konto an und navigieren Sie zu Assets > Asset Records",
              1: () =>
                "Klicken Sie auf der Seite mit dem Transaktionsverlauf auf „Exportieren“ oder verwenden Sie diesen Link: https://bingx.com/en/transaction-history/?timeKey=ninetyDaysFlag",
              2: () =>
                "Wählen Sie den Zeitraum und wählen Sie alle Konten aus: Fonds, USD-M Perpetual Futures, Coin-M Perpetual Futures und Standard Futures.",
              3: () =>
                "Stellen Sie den Dateityp auf Excel und das Datenformat auf Standard ein und klicken Sie dann auf „Generieren“.",
              4: () =>
                "Warten Sie, bis die Excel-Datei generiert wurde, und laden Sie sie herunter, sobald sie fertig ist.",
              5: () =>
                "Öffnen Sie die Excel-Datei, die vier separate Blätter enthält. Speichern Sie jedes Blatt als einzelne CSV-Datei und importieren Sie sie nacheinander in CTC.",
            },
          },
        },
      },
    },
    bitaroo: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Bitaroo-Konto an",
              1: () =>
                "Laden Sie Ihre CSV-Dateien für Handel, Einzahlungen und Auszahlungen von Bitaroo herunter",
              2: () =>
                "Laden Sie diese Dateien hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    bitbuy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Bitbuys detaillierter Bericht zur Handelsaktivität enthält manchmal keine Einzahlungen oder Abhebungen von und zu Bitbuy. Wenn Sie Einzahlungen und Abhebungen haben, wenden Sie sich bitte an unseren Support, um Hilfe beim Importieren dieser Transaktionen zu erhalten.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich hier bei Ihrem Bitbuy-Konto an (https://app.bitbuy.ca/sign-in).",
              1: () =>
                "Klicken Sie auf das Dropdown-Menü neben Ihrem Namen (oben rechts) und wählen Sie „Dokumente“ aus.",
              2: () =>
                "Um Ihren Transaktionsverlauf vor dem 25. Mai 2024 abzurufen, gehen Sie auf die Registerkarte „Legacy-Dokumente“ und laden Sie Ihre Dateien herunter.",
              3: () =>
                "Für alle Aktivitäten nach dem 25. Mai 2024 gehen Sie auf die Registerkarte „Kontoauszüge“ und laden Sie die CSV-Dateien herunter.",
              4: () =>
                "Laden Sie alle Ihre heruntergeladenen CSV-Dateien hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    bitcoindotcomau: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Bitte kontaktieren Sie das Support-Team von bitcoin.com.au, um Ihren API-Schlüssel zu generieren oder zu ändern",
              1: () =>
                "Kopieren Sie die Schlüssel und fügen Sie sie in den Crypto Tax Calculator ein",
            },
          },
        },
      },
    },
    bitfinex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützen wir keine Bitfinex-Derivatehandelsdaten. Wenn Sie Derivatehandel über Bitfinex durchgeführt haben, wenden Sie sich bitte an den Support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Bitfinex an.",
              1: () =>
                "Bewegen Sie den Mauszeiger in der oberen rechten Ecke über das Personensymbol und wählen Sie „API-Schlüssel“ aus der Dropdown-Liste. Dadurch sollten Sie zu „https://www.bitfinex.com/api“ weitergeleitet werden.",
              2: () =>
                "Klicken Sie auf die Registerkarte „Neuen Schlüssel erstellen“.",
              3: () =>
                "Stellen Sie sicher, dass die Standardberechtigungen unverändert bleiben und alle Leseberechtigungen aktiviert wurden.",
              4: () =>
                "Geben Sie unten auf der Seite im Feld „Beschriften Sie Ihren API-Schlüssel“ einen Namen für den API-Schlüssel ein (z. B. „Crypto Tax Calculator“) und klicken Sie dann auf die Schaltfläche „API-Schlüssel generieren“.",
              5: () =>
                "Wenn Sie 2FA aktiviert haben, müssen Sie möglicherweise Ihr 2FA-Token eingeben.",
              6: () =>
                "Sobald Sie eine Bestätigungs-E-Mail von Bitfinex erhalten haben, bestätigen Sie den neuen API-Schlüssel.",
              7: () =>
                "Kopieren Sie den „API-Schlüssel“ und das „API-Schlüsselgeheimnis“ und geben Sie beide in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützen wir keine Bitfinex-Derivatehandelsdaten. Wenn Sie Derivatehandel über Bitfinex durchgeführt haben, wenden Sie sich bitte an den Support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Bitfinex an.",
              1: () =>
                "Bewegen Sie den Mauszeiger in der oberen rechten Ecke über „Wallet“ und wählen Sie „Berichte“ aus der Dropdown-Liste. Dadurch sollten Sie zu „https://report.bitfinex.com“ weitergeleitet werden.",
              2: () => "Klicken Sie oben auf der Seite auf das Kalendersymbol.",
              3: () =>
                "Wählen Sie einen benutzerdefinierten Datumsbereich aus, der Ihren gesamten Handelsverlauf umfasst.",
              4: () =>
                "Klicken Sie auf die Schaltfläche „Exportieren“. Ein Popup sollte angezeigt werden.",
              5: () =>
                "Wählen Sie im Feld „Zu exportierende Daten“ nur „Hauptbücher“ und „Bewegungen“ aus. Wählen Sie das „Datumsformat“ auf „TT-MM-JJ“. Aktivieren Sie das Kontrollkästchen „Millisekunden anzeigen“.",
              6: () =>
                "Klicken Sie unten im Popup auf die Schaltfläche „Exportieren“. Dadurch werden CSV-Dateien generiert, die an Ihre E-MAIL gesendet werden.",
              7: () =>
                "Öffnen Sie die E-Mail in Ihrem E-Mail-Client und klicken Sie auf „CSV herunterladen“. Dadurch wird eine ZIP-Datei mit zwei CSVs heruntergeladen (eine für Hauptbücher und eine für Bewegungen).",
              8: () =>
                "Extrahieren und laden Sie BEIDE CSV-Dateien in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    bitflyer: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Bitflyer an",
              1: () => "Navigieren Sie zur API",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Neuen Schlüssel generieren“",
              3: () =>
                "Kopieren Sie den „API-Schlüssel“ und das „API-Geheimnis“ und geben Sie beide hier in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem BitFlyer-Konto an",
              1: () => "Laden Sie Ihren Transaktionsverlauf herunter",
              2: () =>
                "Laden Sie die CSV-Datei in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    bitforex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Wenn das Hochladen der CSV-Dateien fehlschlägt, ändern Sie die Erweiterung von „.csv“ in „.xlsx“ und versuchen Sie es erneut.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem BitForex-Konto an",
              1: () =>
                "Kontaktieren Sie den Support und bitten Sie ihn um Ihre CSV-Handelsdateien",
              2: () =>
                "Laden Sie diese Dateien hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    bitget: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "Um ältere Daten abzurufen, importieren Sie Ihre Trades bitte per CSV.",
            },
            title: () =>
              "Die API von Bitget bietet nur die letzten 90 Tage der Handelshistorie",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Bitget an",
              1: () =>
                "Navigieren Sie über die Navigationsleiste oben zur API-Verwaltung",
              2: () => "Wählen Sie im Dropdown-Menü API Management",
              3: () => "Klicken Sie auf „Neue API erstellen“.",
              4: () => "Klicken Sie auf Systemgenerierter API-Schlüssel",
              5: () =>
                "Erstellen Sie im Popup-Fenster eine Passphrase für Ihre API",
              6: () =>
                "Wählen Sie unter Berechtigungen in allen Kontrollkästchen Schreibgeschützt aus.",
              7: () =>
                "Schließen Sie die Zwei-Faktor-Verifizierung ab und klicken Sie auf „Weiter“.",
              8: () => "Kopieren Sie Ihren API-Schlüssel und Ihr Geheimnis",
            },
          },
        },
      },
    },
    bitgo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Stellen Sie sicher, dass Ihr Bericht alle Jahre umfasst, in denen Sie mit Kryptowährung gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich in einem Webbrowser bei Ihrem BitGo-Konto an.",
              1: () =>
                "Navigieren Sie über die Navigationsleiste oben zum Abschnitt „Berichte“",
              2: () => "Wählen Sie „Alle Münzen/Token“ im Dropdown-Menü",
              3: () => "Laden Sie Ihren Bericht als CSV-Datei herunter",
              4: () =>
                "Laden Sie die gerade heruntergeladene Datei hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    bitkeep: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit werden nur Solana-Wallet-Adressen unterstützt, die in Ihrem BitKeep-Wallet gespeichert sind.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Finden Sie die Solana Blockchain Wallet-Adresse, die Sie für den Handel über BitKeep verwendet haben",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    bitmart: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Bitmart-Konto an.",
              1: () =>
                "Bewegen Sie den Mauszeiger über das Kontologo in der oberen rechten Ecke.",
              2: () => "Klicken Sie auf „Konto“.",
              3: () => "Klicken Sie auf API-Verwaltung.",
              4: () => "Geben Sie Ihr bevorzugtes Memo ein.",
              5: () => "Klicken Sie auf „Senden“.",
              6: () =>
                "Kopieren Sie den API-Schlüssel, das Geheimnis und das Memo.",
              7: () => "In die entsprechenden Felder im CTC einfügen",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "BitMart verfügt weder über eine API mit vollständigem Transaktionsverlauf noch über eine CSV-Datei in der Benutzeroberfläche. Sie müssen ihnen eine E-Mail senden, um den CSV-Export Ihres Handelsverlaufs anzufordern. Dies kann 5 Tage dauern.",
              1: () =>
                "Sobald Sie den Export der Handelshistorie erhalten haben, können Sie ihn in den Crypto Tax Calculator importieren.",
            },
          },
        },
      },
    },
    bitmax: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Die AscendEX API liefert derzeit nur die letzten 30 Tage der Handelshistorie",
              1: () => "Sie müssen nach 90 Tagen neue API-Schlüssel erstellen",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem AscendEX-Konto an",
              1: () => "Klicken Sie oben rechts auf das Avatar-Symbol",
              2: () =>
                "Klicken Sie auf „API-Einstellungen“ und dann auf „Neuer API-Schlüssel“.",
              3: () =>
                "Aktivieren Sie die Berechtigung „Anzeigen“ für den Schlüssel und geben Sie Ihren E-Mail-Bestätigungscode und den 2FA-Code ein.",
              4: () => "Klicken Sie auf „API-Schlüssel generieren“.",
              5: () => "Kopieren Sie Ihren API-Schlüssel und Ihr Geheimnis",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "AscendEX verfügt derzeit nicht über einen CSV-Export für Ihre Ein-/Auszahlungen. Sie müssen diesen manuell hinzufügen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem AscendEX-Konto an",
              1: () =>
                "Wählen Sie „Meine Bestellung“ (https://bitmax.io/en/order/cash-order)",
              2: () => "Klicken Sie auf „Export anfordern“.",
              3: () =>
                "Sie können dann auf die Schaltfläche rechts „Meine exportierten Dateien“ klicken und die Datei herunterladen, sobald sie fertig ist",
              4: () =>
                "Laden Sie die heruntergeladene CSV-Datei direkt hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    bitmex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Stellen Sie sicher, dass Sie beim Erstellen des API-Schlüssels keine Bestellberechtigungen aktiviert haben!",
              1: () =>
                "Wenn die API Ihre Daten nicht importiert, liegt das möglicherweise daran, dass Sie die neue KYC-Verifizierungsrichtlinie von BitMEX nicht erfüllen. Wenn dies bei Ihnen der Fall ist, schließen Sie bitte deren KYC-Verifizierung ab, bevor Sie es erneut versuchen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem BitMEX-Konto an",
              1: () => "Klicken Sie oben rechts auf Ihren Benutzernamen",
              2: () =>
                "Navigieren Sie zur Kontoseite, indem Sie auf „Konto und Sicherheit“ klicken.",
              3: () =>
                "Wählen Sie auf der linken Seite der Seite „API-Schlüssel“ aus (https://www.bitmex.com/app/apiKeys).",
              4: () =>
                "Stellen Sie beim Erstellen des API-Schlüssels sicher, dass die Schlüsselberechtigungen leer sind, was durch „-“ angezeigt wird.",
              5: () =>
                "Überprüfen Sie noch einmal, ob Sie keine Berechtigungen aktiviert haben",
              6: () =>
                "Geben Sie bei Bedarf Ihre 2FA ein und klicken Sie auf „API-Schlüssel erstellen“.",
              7: () =>
                "Kopieren Sie die ID und das Geheimnis und geben Sie sie als „API-Schlüssel“ und „API-Geheimnis“ hier in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () => "Sie müssen die CSV-Datei für jede Datenseite speichern",
              1: () =>
                "Wenn die CSV-Datei nicht importiert wird, liegt das möglicherweise daran, dass Ihre Daten ein anderes Zeitstempelformat aufweisen als erwartet. Wenden Sie sich in diesem Fall bitte an unseren Support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem BitMEX-Konto an",
              1: () => "Klicken Sie oben rechts auf Ihren Benutzernamen",
              2: () => "Navigieren Sie zu Konto und Sicherheit",
              3: () =>
                "Wählen Sie auf der linken Seite der Seite „Wallet - > Guthaben“ (https://www.bitmex.com/app/wallet)",
              4: () => "Klicken Sie oben rechts auf „Als CSV speichern“.",
              5: () =>
                "Bitte stellen Sie sicher, dass der Name der CSV-Datei, die Sie gerade heruntergeladen haben, mit „Wallet History“ beginnt. Es gibt andere Arten von herunterladbaren CSV-Dateien, die wir nicht unterstützen.",
              6: () =>
                "Laden Sie die CSV-Datei hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    bitpanda: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Melde dich bei Bitpanda an und wähle das Panda-Symbol in der oberen rechten Ecke.",
              1: () =>
                "Wählen Sie API-Schlüssel und geben Sie einen Namen für Ihren API-Schlüssel ein.",
              2: () =>
                "Überprüfen Sie im Rahmen dessen Transaktionen, Guthaben und Handel.",
              3: () => "Wählen Sie „Neuen API-Schlüssel generieren“ aus.",
              4: () =>
                "Klicken Sie auf „Bestätigen“, woraufhin eine Bestätigungs-E-Mail generiert wird.",
              5: () =>
                "Kopieren Sie den API-Schlüssel und geben Sie ihn hier als „API-Schlüssel“ und „API-Geheimnis“ in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melde dich bei Bitpanda an und wähle das Panda-Symbol in der oberen rechten Ecke.",
              1: () =>
                "Wählen Sie „Verlauf“ und dann oben rechts „Transaktionsverlauf“ aus.",
              2: () =>
                "Exportieren Sie die CSV-Datei und importieren Sie sie in den Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitrue: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützt unsere CSV-Importoption nur Einzahlungen, Abhebungen und Handelsverläufe.",
              1: () =>
                "Wenn Sie Datensätze haben, die wir nicht unterstützen und Sie deren Hinzufügen beantragen möchten, kontaktieren Sie uns bitte über unseren Chat-Support",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Bitrue an",
              1: () =>
                "Bewegen Sie den Mauszeiger über Trade in der oberen linken Ecke und wählen Sie Spot. Klicken Sie dann auf Order History. Alternativ können Sie diesen direkten Link ausprobieren (https://www.bitrue.com/order/spot/history-orders)",
              2: () => "Klicken Sie oben rechts auf „Exportieren“",
              3: () =>
                "Wählen Sie im modalen Popup „Handelsverlauf“ als Datenoption",
              4: () =>
                "Wählen Sie die Daten aus, an denen Sie gehandelt haben, und klicken Sie auf die Schaltfläche „OK“.",
              5: () =>
                "Bitrue erlaubt jeweils nur 3 Monate, daher müssen Sie möglicherweise mehrere Downloads durchführen, um Ihren gesamten Handelsverlauf abzudecken",
            },
          },
        },
      },
    },
    bitso: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie in der Navigationsleiste oben rechts auf die Dropdown-Option „Benutzerprofil“ und dann auf „Verlauf“ (https://bitso.com/r/history/alltransactions).",
              1: () =>
                "Unten in der Transaktionstabelle sollten Sie „Laden Sie Ihre Transaktionen in einer CSV-Datei herunter“ sehen, gefolgt von vier Optionen: Einzahlungen, Abhebungen, Konvertierungen und Trades.",
              2: () =>
                "Klicken Sie auf jede Option, und importieren Sie dann die vier CSV-Dateien, die Sie gerade heruntergeladen haben, in den Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    bitsong: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre BitSong-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    bitstamp: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Wiederholen Sie die obigen Schritte, um API-Schlüssel für alle zugehörigen Konten hinzuzufügen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Mein Profil > Einstellungen > Kontoeinstellungen > API-Zugriff",
              1: () => "Klicken Sie auf „Neuen API-Schlüssel erstellen“.",
              2: () =>
                "Klicken Sie auf den Schalter „Erweiterte Einstellungen“",
              3: () =>
                "Wählen Sie unter „Verknüpftes Konto“ ein entsprechendes Konto aus.",
              4: () =>
                "Stellen Sie sicher, dass nur „Allgemeine Berechtigungen > Kontostand anzeigen“, „Allgemeine Berechtigungen > Ihre Transaktionen anzeigen“ und „Verdienen > Earn-Funktionalität anzeigen und verwalten“ aktiviert sind, und klicken Sie auf „Neuen API-Schlüssel erstellen“.",
              5: () =>
                "Geben Sie Ihren Bitstamp 2FA-Code ein und klicken Sie auf „Bestätigen“.",
              6: () =>
                "Kopieren Sie den API-Schlüssel und das Secret in den Crypto Tax Calculator. Klicken Sie noch NICHT auf „Bitstamp API hinzufügen“. Beachten Sie, dass das Secret nur für ein paar Minuten verfügbar ist und nicht erneut angezeigt wird. Wenn Sie Ihr Secret verlieren, müssen Sie einen neuen API-Schlüssel erstellen.",
              7: () =>
                "Klicken Sie in Bitstamp auf „API-Schlüssel aktivieren“.",
              8: () =>
                "Bitstamp sendet Ihnen eine Bestätigungs-E-Mail. Klicken Sie in dieser E-Mail auf die Schaltfläche „API-Schlüssel aktivieren“.",
              9: () => "Klicken Sie auf „Bitstamp-API hinzufügen“.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Transaktionen für Unterkonten sind bereits in der heruntergeladenen CSV-Datei enthalten.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Bitstamp-Konto an",
              1: () =>
                "Gehen Sie zur Seite „Transaktionen“, indem Sie oben rechts auf das Benutzersymbol, dann auf „Aktivität“ und dann in der linken Seitenleiste auf „Transaktionsverlauf“ klicken (https://www.bitstamp.net/account/transactions).",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Exportoptionen öffnen“",
              3: () =>
                "Wählen Sie den „Datumsbereich“, der Ihren gesamten Transaktionsverlauf abdeckt",
              4: () => "Wählen Sie „Alle“ für die Währungen",
              5: () => "Wählen Sie „Alle“ für die Transaktionsarten",
              6: () => "Wählen Sie 'RFC 4180 (neu)' für das CSV-Format",
              7: () =>
                "Klicken Sie auf die Schaltfläche „Auswahl exportieren“, um die CSV-Datei herunterzuladen",
              8: () =>
                "Laden Sie diese CSV hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie auf „Mit Bitstamp verbinden“, um zur Bitstamp-Börse zu gelangen",
              1: () => "Melden Sie sich bei Ihrem Bitstamp-Konto an.",
              2: () =>
                "Wenn für Ihr Konto 2FA aktiviert ist, geben Sie den erforderlichen Code ein. Andernfalls überspringen Sie diesen Schritt.",
              3: () => "Wählen Sie das entsprechende Unterkonto aus",
              4: () => "Klicken Sie auf die Schaltfläche „Akzeptieren“",
            },
          },
        },
      },
    },
    bittorrentchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Wir haben derzeit einige Probleme beim Sammeln von ERC-1155-Tokens und Überbrückungstransaktionen und arbeiten an einer Lösung. Wenn dieses Problem in der Zwischenzeit auftritt, versuchen Sie stattdessen unseren manuellen CSV-Upload-Prozess. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155-Token und Bridge-Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre BitTorrent Chain-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    bittrex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Bittrex an",
              1: () => "Navigieren Sie zu API-Schlüsseln",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Neuen Schlüssel hinzufügen“",
              3: () =>
                "Stellen Sie sicher, dass die Berechtigung „INFO LESEN“ für den neuen Schlüssel aktiviert ist.",
              4: () =>
                "Klicken Sie anschließend auf die Schaltfläche „Speichern“.",
              5: () => "Kopieren Sie den API-Schlüssel und das API-Geheimnis",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Um Ihre Einzahlungs-/Auszahlungshistorie als CSV herunterzuladen, müssen Sie ein Support-Ticket bei Bittrex einreichen. Sobald Sie diese CSVs erhalten haben, können Sie sie in den Crypto Tax Calculator hochladen. Weitere Einzelheiten finden Sie hier: https://bittrex.zendesk.com/hc/en-us/articles/360001359006-Where-s-my-Order-History-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Bittrex an, navigieren Sie zu „Bestellungen“ in der oberen Ecke und scrollen Sie nach unten zum Bestellverlauf.",
              1: () =>
                "Klicken Sie auf die Schaltfläche „Verlauf herunterladen“, wählen Sie das Jahr aus, in dem Sie gehandelt haben, und klicken Sie auf „Herunterladen“.",
              2: () =>
                "Sie können jeweils nur ein Jahr herunterladen, sollten aber jedes Jahr, in dem Sie gehandelt haben, zum Crypto Tax Calculator hinzufügen.",
              3: () =>
                "Stellen Sie sicher, dass Sie die Datei vor dem Importieren nicht öffnen oder erneut speichern, da dadurch möglicherweise das Format der CSV-Datei beeinträchtigt wird.",
            },
            title: () => "Für den Bestellverlauf:",
          },
        },
      },
    },
    bitvavo: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Wir importieren derzeit Einzahlungen, Abhebungen und Trades",
              1: () =>
                "Wir können keine Staking-Einnahmen importieren, da die Bitvavo-API dies derzeit nicht unterstützt.",
              2: () =>
                "Wenn Sie Staking verwenden, ist es möglich, dass die von uns gemeldeten Salden für eingesetzte Währungen nicht mit denen von Bitvavo gemeldeten übereinstimmen, es sei denn, Sie geben die Staking-Einnahmetransaktionen manuell ein.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei der Bitvavo-Börse an",
              1: () =>
                "Navigieren Sie zur API-Einstellungsseite (https://account.bitvavo.com/user/api)",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Neuen API-Schlüssel anfordern“",
              3: () =>
                "Generieren Sie schreibgeschützte API-Schlüssel, die weder Handel noch Abhebungen ermöglichen",
              4: () =>
                "Fügen Sie den API-Schlüssel und das Geheimnis hier in den Crypto Tax Calculator ein",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrer Bitvavo-Börse an",
              1: () => "Klicken Sie oben rechts auf Ihren Profilnamen",
              2: () => "Klicken Sie auf „Transaktionsverlauf“.",
              3: () =>
                "Klicken Sie oben rechts auf die Schaltfläche „Herunterladen“.",
              4: () =>
                "Laden Sie die CSV hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    bityard: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Bityard-Konto an",
              1: () => "Für die Handelshistorie:",
              2: () =>
                "Navigieren Sie zum Abschnitt „Orders“ und wählen Sie im Menü „Spot“ – „Trade History“ (https://www.bityard.com/en-US/account/fm/spotHistory/2).",
              3: () =>
                "Klicken Sie auf die Schaltfläche „Exportieren“ und wählen Sie den gewünschten Zeitraum für die exportierten Daten aus",
              4: () => "Zum Einzahlungs- und Auszahlungsverlauf:",
              5: () =>
                "Navigieren Sie zum Abschnitt „Vermögenswerte“ und wählen Sie im Menü „Meine Vermögenswerte“ – „Geldverlauf“ – „Einzahlungsverlauf“ oder „Auszahlungsverlauf“ (https://www.bityard.com/en-US/account/fm/fundHistory/1).",
              6: () =>
                "Klicken Sie auf die Schaltfläche „Exportieren“ und wählen Sie den gewünschten Zeitraum für die exportierten Daten aus",
              7: () =>
                "Laden Sie beide CSVs hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    blast: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Blast-Wallet-Adresse (beginnend mit 0x) in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    blockchaindotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können Ihren Einzahlungs- und Auszahlungsverlauf nur über den CSV-Import importieren. Darüber hinaus enthält die CSV-Datei keine Informationen zu den für diese Einzahlungen/Auszahlungen gezahlten Gebühren.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Loggen Sie sich in Ihr Wallet ein (https://login.blockchain.com/#/login) und wählen Sie links eine Währung aus (z. B. Bitcoin)",
              1: () =>
                "Wenn Sie über ein Guthaben für die ausgewählte Kryptowährung verfügen, wird eine Schaltfläche „Herunterladen“ angezeigt. Klicken Sie auf diese Schaltfläche",
              2: () =>
                "Sie werden aufgefordert, einen Datumsbereich auszuwählen, für den Sie Ihre Transaktionen herunterladen möchten. Stellen Sie sicher, dass Sie den Zeitraum auswählen, der Ihren gesamten Transaktionsverlauf für die ausgewählte Kryptowährung abdeckt",
              3: () =>
                "Klicken Sie auf „Bericht erstellen“. Diese Schaltfläche ändert sich in „Bericht herunterladen“, sobald der Bericht zum Herunterladen bereit ist. Klicken Sie auf diese Schaltfläche, um Ihren Bericht herunterzuladen.",
              4: () =>
                "Laden Sie die gerade heruntergeladene Datei hier in den Crypto Tax Calculator hoch.",
              5: () =>
                "Stellen Sie sicher, dass Sie diesen Vorgang für alle Kryptowährungen wiederholen, die Sie in unserem Wallet speichern.",
            },
          },
        },
      },
    },
    blockearner: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Block Earner an und gehen Sie zur Seite „Transaktionen“ (https://app.blockearner.com.au/transactions).",
              1: () =>
                "Laden Sie die erforderlichen Dateien basierend auf Ihrem Handelsverlauf herunter.",
              2: () =>
                "Die Transaktionsdatei finden Sie oben rechts auf dem Bildschirm in jedem jeweiligen Konto: Kredite, DeFi, Krypto-Kauf/-Verkauf und Bargeld.",
              3: () =>
                "Laden Sie alle heruntergeladenen Dateien hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    blockfi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Wir ignorieren den Transaktionstyp „Handel“ in der CSV-Datei „Transaktionsverlauf“. Stattdessen verwenden wir die genaueren Informationen in der CSV-Datei „Handel“, um Ihre Handelsdaten abzurufen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem BlockFi-Konto an und klicken Sie oben rechts auf Ihr Profilsymbol",
              1: () => "Wählen Sie „Berichte“ aus dem Dropdown-Menü",
              2: () =>
                "Unten auf der Seite finden Sie „Handel“ und „Transaktionsverlauf“.",
              3: () =>
                "Wählen Sie für „Trading“ den Datumsbereich aus, den Sie exportieren möchten (oder laden Sie alles herunter) und klicken Sie auf die Schaltfläche „.csv herunterladen“. Dadurch werden alle Ihre detaillierten Handelsdaten heruntergeladen.",
              4: () =>
                "Wählen Sie für „Transaktionsverlauf“ den Datumsbereich aus, den Sie exportieren möchten (oder laden Sie alles herunter) und klicken Sie auf die Schaltfläche „.csv herunterladen“. Dadurch werden alle Ihre detaillierten Transaktionsdaten wie Einzahlungen, Abhebungen, Zinszahlungen usw. heruntergeladen.",
              5: () =>
                "Laden Sie beide CSV-Dateien hier zum Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    boba: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Wir haben derzeit einige Probleme beim Sammeln von ERC-1155-Tokens und arbeiten an einer Lösung. Wenn dieses Problem in der Zwischenzeit auftritt, versuchen Sie stattdessen unseren manuellen CSV-Upload-Prozess. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155-Token",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Boba-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    brave_software: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Brave Wallet im Browser öffnen",
              1: () =>
                "Klicken Sie auf der Registerkarte „Konten“, um Ihre öffentliche Wallet-Adresse zu kopieren, die mit „0x...“ beginnt.",
              2: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    bsv: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
              1: () =>
                "Wir unterstützen auch xpub, ypub und zpub Adressen, allerdings wird xpub bevorzugt",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Bitcoin SV-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    btc: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen auch xpub-, ypub- und zpub-Adressen. Wir empfehlen Ihnen, xPub zu verwenden. Weitere Einzelheiten finden Sie in diesem Leitfaden (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () =>
                "Kopieren Sie Ihre öffentliche Wallet-Adresse, die mit „xpub…“ beginnt.",
              2: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    btcAltNetwork: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Geben Sie Ihre " +
                d.exchangeDisplayName +
                " -Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    btcalpha: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie im Abschnitt „Konten“ Ihres Kontos zum Abschnitt „API“",
              1: () => "Einen neuen API-Schlüssel erstellen",
              2: () =>
                "Kopieren Sie die Schlüssel und fügen Sie sie in den Crypto Tax Calculator ein",
            },
          },
        },
      },
    },
    btccomau: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei BTC.com.au an",
              1: () =>
                "Navigieren Sie über das Menü auf der linken Seite Ihres Bildschirms zu Einstellungen > Handelsberichte",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Einzelbericht“ rechts neben der Zeile direkt unter der Überschrift „Aktivitätsberichterstattung“.",
              3: () =>
                "Wählen Sie „Transaktion“ in der Dropdown-Liste „Berichtstyp“",
              4: () => "Füllen Sie das Start- und Enddatum aus",
              5: () => "Klicken Sie auf die Schaltfläche „Erstellen“.",
              6: () =>
                "Klicken Sie im Bestätigungs-Popup auf die Schaltfläche „Bericht erstellen“",
              7: () =>
                "Nach der Meldung, dass die Berichterstellung erfolgreich war, müssen Sie die Seite möglicherweise noch aktualisieren, um den neuen Bericht anzuzeigen.",
              8: () =>
                "Klicken Sie in der letzten Zeile unter „Zum Download verfügbare Berichte“ auf „Bericht herunterladen“.",
              9: () =>
                "Laden Sie die heruntergeladene CSV-Datei hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    btcmarkets: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Weitere Informationen finden Sie in unseren detaillierten Schritten: https://cryptotaxcalculator.io/integrations/btcmarkets-tax/",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei BTC Markets an und navigieren Sie zur Seite „API-Schlüssel“ (https://app.btcmarkets.net/account/apikey).",
              1: () =>
                "Klicken Sie auf „Neuen API-Schlüssel hinzufügen“, stellen Sie sicher, dass die Berechtigungen auf „Nur Lesen“ eingestellt sind, und klicken Sie dann auf „Senden“.",
              2: () =>
                "Kopieren Sie das Geheimnis und fügen Sie es in „Geheimer Schlüssel“ im Crypto Tax Calculator ein.",
              3: () =>
                "Klicken Sie auf „Senden“, dann auf die Schaltfläche „Kopieren“ des API-Schlüssels und fügen Sie ihn in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei BTC Markets an und navigieren Sie zu Kaufen/Verkaufen > Handelsverlauf",
              1: () =>
                "Klicken Sie auf „Nach CSV exportieren“ (der Download sollte „AccountTradingReport_XXX.csv“ lauten).",
              2: () => "Laden Sie die CSV-Datei hier hoch Krypto-Steuerrechner",
            },
            title: () => "Für Handelsdaten:",
          },
          1: {
            items: {
              0: () =>
                "Melden Sie sich bei BTC Markets an und navigieren Sie zu Konto > Transaktionsverlauf",
              1: () =>
                "Stellen Sie sicher, dass bei „Währung“ „ALLE“ eingestellt ist, und klicken Sie auf „In CSV exportieren“ (der Download sollte „TransactionReport_XXX.csv“ sein).",
              2: () =>
                "Laden Sie die CSV-Datei hier zum Crypto Tax Calculator hoch",
            },
            title: () => "Für Ein- und Auszahlungen:",
          },
        },
      },
    },
    bybit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Es kann zu geringfügigen Abweichungen zwischen Ihren Guthaben auf Bybit und den im Crypto Tax Calculator angegebenen Guthaben kommen. Dies ist ein Problem auf der Seite von Bybit und ist auf geringfügige Rundungsunterschiede zwischen den Bybit-PNL-Aufzeichnungen und ihren Wallet-Aufzeichnungen zurückzuführen. Bei erheblichen Abweichungen wenden Sie sich bitte über unseren Web-Chat an unseren Support.",
              1: () =>
                "Bybit API importiert nur Konvertierungstransaktionen der letzten 6 Monate. Wenn Sie ältere Transaktionen haben, importieren Sie diese bitte mithilfe einer CSV-Datei.",
              2: () =>
                "Aufgrund von Einschränkungen auf der Seite von Bybit verfällt der API-Schlüssel drei Monate nach der Erstellung.",
              3: () =>
                "Die API Ihres Hauptkontos umfasst die Daten Ihres Unterkontos. Sie müssen also kein zweites erstellen, da dies zu Duplikaten in Ihrem Konto führen könnte.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Bybit an, bewegen Sie den Mauszeiger über Ihren Namen in der oberen rechten Ecke und wählen Sie „API“ (https://www.bybit.com/app/user/api-management).",
              1: () =>
                "Klicken Sie auf die Schaltfläche „Neuen Schlüssel erstellen“. Wenn Sie 2FA noch nicht eingerichtet haben, werden Sie dazu aufgefordert.",
              2: () =>
                "Wählen Sie „systemgenerierte API-Schlüssel“ als API-Schlüsseltyp",
              3: () =>
                "Wählen Sie „API-Transaktion“ als API-Schlüsselverwendung und geben Sie einen Namen Ihrer Wahl ein.",
              4: () =>
                "Ändern Sie den oberen Schalter in „Nur Lesen“ und lassen Sie das IP-Adressfeld leer.",
              5: () =>
                "Aktivieren Sie die Kontrollkästchen für „Unified Trading“ und „Assets“.",
              6: () =>
                "Klicken Sie auf „Senden“ und kopieren Sie dann den API-Schlüssel und das Geheimnis hier in CTC.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Wir empfehlen DRINGEND, den Import über die Option „Über API synchronisieren“ durchzuführen und eine direkte Verbindung mit der Bybit-API herzustellen, da diese über genauere Daten verfügt.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Wir verlangen, dass Sie Ihre CSV-Dateien „Derivative Closed P&L History“ und „Asset History“ hochladen.",
              1: () =>
                "Sie können die Datei „Derivative Closed P&L History“ über diesen Link (https://www.bybit.com/user/assets/order/closed-pnl/inverse) generieren.",
              2: () =>
                "Gehen Sie zu „Bestellungen“ > „Derivatebestellung“ > „Gewinn- und Verlustrechnung schließen“ > Klicken Sie oben rechts auf die Schaltfläche „Exportieren“.",
              3: () =>
                "HINWEIS: Händler können Daten von bis zu zwei Jahren exportieren, wobei der maximale Datenumfang, der in einem einzelnen Stapel exportiert werden kann, drei Monate beträgt.",
              4: () =>
                "Sie können Ihre „Asset History“-Datei über diesen Link (https://www.bybit.com/user/assets/exportStatment) generieren.",
              5: () =>
                "HINWEIS: Wenn Sie Ihren „Kontoauszug“ in Ihrer Vermögenshistorie exportieren, stellen Sie sicher, dass alle Kontotypen ausgewählt sind (Spot, Funding, Unified Trading, USDC-Derivate).",
              6: () =>
                "Wichtig ist, dass wir die Handelshistorie nicht als CSV-Datei benötigen.",
              7: () =>
                "Der geschlossene Gewinn- und Verlustbericht zeigt die Gewinne/Verluste aus dem Handel mit Futures usw.",
              8: () =>
                "Der Bericht zur Vermögenshistorie ermöglicht Ihnen den Import der Ein- und Auszahlungen",
            },
          },
        },
      },
    },
    calebandbrown: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich beim Portal unter app.calebandbrown.com an.",
              1: () =>
                "Navigieren Sie zur Seite „Transaktionen“ (Menü auf der linken Seite).",
              2: () =>
                "Oben rechts in der Transaktionstabelle befindet sich die Schaltfläche Zusammenfassung exportieren",
              3: () =>
                "Wählen Sie „Transaktionszusammenfassung“, wählen Sie „Gesamte Zeit“ als Zeitraum und klicken Sie auf „Exportieren“",
            },
          },
        },
      },
    },
    canto: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Wir haben derzeit einige Probleme beim Sammeln von ERC-1155-Tokens und Überbrückungstransaktionen und arbeiten an einer Lösung. Wenn dieses Problem in der Zwischenzeit auftritt, versuchen Sie stattdessen unseren manuellen CSV-Upload-Prozess. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155-Token und Bridge-Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Canto-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    cardano: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Geben Sie nur EINE Adresse aus jeder Brieftasche ein. Wir führen eine Suche durch und ziehen die Daten für alle anderen Adressen in der Brieftasche automatisch ein.",
              1: () =>
                "Das Importieren mehrerer Adressen aus derselben Brieftasche führt zu doppelten Transaktionen.",
              2: () =>
                "Derzeit unterstützen wir nicht den Erhalt des vollständigen Transaktionsverlaufs von Wallets mit mehr als 1000 einzelnen Adressen oder wenn die Anzahl der Transaktionen in manchen Fällen mehr als 2000 beträgt. Wir arbeiten daran, zusätzliche Unterstützung für solche Wallets bereitzustellen.",
            },
            title: () => "Mehrere Wallet-Adressen",
          },
          1: {
            items: {
              0: () =>
                "Wenn Sie eine Adresse importieren, die mit „addr1“ beginnt, ändern wir sie automatisch in die Adresse des Kontoinhabers, die mit „stake1“ beginnt.",
              1: () =>
                "Dadurch können wir alle anderen Adressen, die Sie möglicherweise in derselben Brieftasche erstellen, importieren und Ihre Staking-Belohnungen erfassen.",
            },
            title: () => "Warum steht meine Adresse auf Stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () =>
                "Kopieren Sie Ihre öffentliche Wallet-Adresse, die mit „addr1…“ oder „stake1…“ beginnt.",
              2: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    cashapp: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Cash App an und navigieren Sie zur Aktivitätsseite (https://cash.app/account/activity).",
              1: () => "Klicken Sie auf „Kontoauszüge“.",
              2: () => "Klicken Sie auf „CSV exportieren“.",
              3: () =>
                "Sie können diese CSV jetzt in den Crypto Tax Calculator hochladen",
            },
          },
        },
      },
    },
    celestia: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Celestia-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    celo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Wir haben derzeit einige Probleme beim Sammeln von ERC-1155-Tokens und arbeiten an einer Lösung. Wenn dieses Problem in der Zwischenzeit auftritt, versuchen Sie stattdessen unseren manuellen CSV-Upload-Prozess. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155-Token",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Celo-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    celsius: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Celsius-Konto an.",
              1: () =>
                "Klicken Sie auf das Dropdown-Menü oben rechts auf der Plattform",
              2: () =>
                "Wählen Sie „API“ und klicken Sie dann auf „Neuen Schlüssel erstellen“.",
              3: () =>
                "Kopieren Sie den generierten „API-Schlüssel“ hier in den Crypto Tax Calculator, geben Sie einen optionalen Spitznamen ein und klicken Sie dann auf „API hinzufügen“, um Ihre API zu synchronisieren",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Ausführlichere Schritte mit Screenshots finden Sie auf der Support-Seite unter https://support.celsius.network/hc/en-us/articles/360004491118-How-do-I-export-my-transaction-history-as-CSV-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Öffnen Sie die Celsius-App und tippen Sie auf das „Celsius-Logo“.",
              1: () => "Tippen Sie auf die Schaltfläche „Profil“.",
              2: () =>
                "Tippen Sie im Einstellungsbereich auf „Transaktionsverlauf herunterladen“.",
              3: () =>
                "Sobald Sie auf die Schaltfläche „Transaktionsverlauf herunterladen“ getippt haben, überprüfen Sie die E-Mail, die Sie zur Registrierung Ihres Celsius-Wallets verwendet haben. Klicken Sie in der E-Mail auf die Schaltfläche „Herunterladen“.",
              4: () =>
                "Laden Sie die CSV-Datei mit der Schaltfläche „Importieren“ in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    cex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Cex.io an",
              1: () =>
                "Navigieren Sie zur API-Zugriffsseite https://cex.io/trade/profile#/api",
              2: () =>
                "Klicken Sie auf die Schaltfläche „API-Schlüssel generieren“",
              3: () =>
                "Navigieren Sie zu Berechtigungen, um nur die Berechtigungen „Kontostand“ und „Offene Bestellungen“ zu aktivieren.",
              4: () =>
                "Klicken Sie auf die Schaltfläche „Schlüssel generieren“",
              5: () =>
                "Kopieren Sie die Benutzer-ID, den API-Schlüssel und das API-Geheimnis",
              6: () =>
                "Klicken Sie neben Ihrem neuen API-Schlüssel auf die Schaltfläche „Aktivieren“.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Cex.io an",
              1: () =>
                "Navigieren Sie zu Finanzen > Transaktionen https://cex.io/finance/transactions",
              2: () =>
                "Wählen Sie „Alle Transaktionsarten“ mit Datumsangaben für alle Trades",
              3: () =>
                "Klicken Sie auf die Schaltfläche „Als CSV herunterladen“, bevor Sie den Crypto Tax Calculator hochladen.",
            },
          },
        },
      },
    },
    changelly: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu: Mein Konto - > Verlauf https://changelly.com/history",
              1: () => "Klicken Sie auf „CSV exportieren“.",
              2: () => "Laden Sie hier die CSV-Datei hoch",
            },
          },
        },
      },
    },
    chihuahua: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Chihuahua-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    chiliz: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Die Dateien sollten folgendem Format entsprechen: Zeitstempel (UTC), Börse, gehandelter Vermögenswert, gehandelter Betrag, erhaltener Vermögenswert, erhaltener Betrag",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sie können Transaktionen von Chiliz Exchange und zugehörigen Apps wie Socios importieren.",
            },
          },
        },
      },
    },
    clvchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Wir haben derzeit einige Probleme beim Sammeln von ERC-1155-Tokens und arbeiten an einer Lösung. Wenn dieses Problem in der Zwischenzeit auftritt, versuchen Sie stattdessen unseren manuellen CSV-Upload-Prozess. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155-Token",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre CLV P-Chain-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    coin98: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit werden nur Solana-Wallet-Adressen unterstützt, die in Ihrem Coin98-Wallet gespeichert sind",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Solana-Blockchain-Wallet-Adresse, die Sie für den Handel über Coin98 verwendet haben",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    coinbase: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Bitte stellen Sie unter https://accounts.coinbase.com/profile > Einstellungen > Währung sicher, dass Ihre Coinbase-Währungseinstellungen mit Ihrer lokalen Währung übereinstimmen.",
              1: () =>
                "Dies ist eine schreibgeschützte API-Verbindung. Aus Sicherheitsgründen unterstützt Crypto Tax Calculator nicht die Verbindung von Schlüsseln, die Handel oder Abhebungen ermöglichen. Weitere Informationen finden Sie unter https://cryptotaxcalculator.io/privacy-policy",
              2: () =>
                "Möglicherweise fehlen einige Transaktionen, da es sich um Währungen handelt, die von Coinbase nicht unterstützt werden. (https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/lost-assets)",
              3: () =>
                "Wenn der Import über API-Schlüssel erfolgreich war, verwenden Sie nicht die Methode „Coinbase verbinden“, da dies zu Duplikaten führt.",
              4: () =>
                "Staking-Abhebungen und Einzahlungen werden ignoriert, dies hat jedoch keinen Einfluss auf Berichte.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei der Coinbase Developer Platform an (https://portal.cloud.coinbase.com/).",
              1: () => "Gehen Sie zu den API-Schlüsseln.",
              2: () =>
                "Legen Sie unter „API-Schlüssel erstellen“ einen Spitznamen fest, wählen Sie Ihr Portfolio aus und wählen Sie die Berechtigung „Anzeigen (nur Lesen)“.",
              3: () => "Klicken Sie auf „Erstellen und herunterladen“.",
              4: () => "Vollständige 2-Faktor-Authentifizierung.",
              5: () =>
                "Kopieren Sie den API-Schlüsselnamen und das Geheimnis und fügen Sie sie in den Crypto Tax Calculator ein.",
              6: () =>
                "Ihr API-Geheimnis wird nur einmal angezeigt. Laden Sie die JSON-Datei als Backup herunter. Bei Verlust erstellen Sie einen neuen API-Schlüssel in Coinbase und aktualisieren Sie ihn im Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/7GbjlA9B3bI",
            title: () => "So importieren Sie Ihre Coinbase-API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Wenn Sie Fill-Trades verwendet haben, liefern die Optionen „Über API synchronisieren“ oder „Coinbase verbinden“ möglicherweise genauere Ergebnisse.",
              1: () =>
                "Bitte stellen Sie unter https://accounts.coinbase.com/profile > Einstellungen > Währung sicher, dass Ihre Coinbase-Währungseinstellungen mit Ihrer lokalen Währung übereinstimmen.",
              2: () =>
                "Staking-Abhebungen und Einzahlungen werden ignoriert, dies hat jedoch keinen Einfluss auf Berichte.",
            },
          },
          1: {
            items: {
              0: () =>
                "Erweiterte Handelszeilen enthalten keine vollständigen Preisinformationen.",
              1: () =>
                "Dies kann zu geringfügigen Unterschieden zwischen Ihrem CSV-Import und den in Coinbase angezeigten erweiterten Handelsdetails führen.",
              2: () =>
                "Die besten Ergebnisse erzielen Sie, wenn Sie den Import über unsere API-Integration durchführen.",
            },
            title: () => "Coinbase API am besten für fortgeschrittene Trades",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Gehen Sie zu Konten > Kontoauszug (https://accounts.coinbase.com/statements)",
              1: () =>
                "Belassen Sie unter „Benutzerdefinierten Kontoauszug generieren“ die Felder „Transaktionsverlauf“, „Alle Vermögenswerte“, „Alle Transaktionen“ und „Alle Zeiten“.",
              2: () => "Wählen Sie „CSV“ und klicken Sie auf „Generieren“.",
              3: () => "Laden Sie den Bericht hoch.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/nm2-aOO5iI4",
            title: () => "So importieren Sie Ihre Coinbase CSV-Dateien",
          },
        },
      },
      oauth: {
        note: {
          0: {
            items: {
              0: () =>
                "Bitte stellen Sie unter https://accounts.coinbase.com/profile > Einstellungen > Währung sicher, dass Ihre Coinbase-Währungseinstellungen mit Ihrer lokalen Währung übereinstimmen.",
              1: () =>
                "Wenn Sie die Verbindung mit dieser Methode hergestellt haben, müssen Sie Ihre Schlüssel aufgrund der Abkündigung veralteter Schlüssel im Februar 2025 nicht aktualisieren.",
              2: () =>
                "Sollte die Verbindung nicht funktionieren, klicken Sie bitte auf „Erneut autorisieren“.",
              3: () =>
                "Staking-Abhebungen und Einzahlungen werden ignoriert, dies hat jedoch keinen Einfluss auf Berichte.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie auf „Coinbase verbinden“, um eine neue Registerkarte zu öffnen.",
              1: () => "Melden Sie sich bei Ihrem Coinbase-Konto an.",
              2: () => "Autorisieren Sie nur den Lesezugriff.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/qHL8O_alo8Q",
            title: () => "So importieren Sie Ihre Coinbase-Daten über Oauth",
          },
        },
      },
    },
    "coinbase-wallet": {
      "bulk-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Öffnen Sie die Coinbase-Wallet auf Ihrem Mobiltelefon oder in Ihrem Browser",
              1: () =>
                "Klicken Sie oben links auf dem Bildschirm auf den Namen Ihrer Brieftasche.",
              2: () => "Klicken Sie auf „Wallets hinzufügen und verwalten“.",
              3: () => "Wählen Sie eine Brieftasche aus",
              4: () => "Klicken Sie auf „Öffentliche Adressen exportieren“",
              5: () => "Klicken Sie auf Kopieren",
              6: () =>
                "Fügen Sie die Liste der Wallets genau so in das Textfeld ein, wie sie kopiert wurde",
              7: () =>
                "Alle Ihre Geldbörsen in Coinbase Wallet werden verbunden",
            },
          },
        },
      },
    },
    coinbaseexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Coinbase Exchange-Konto an.",
              1: () => "Erstellen Sie einen neuen API-Schlüssel.",
              2: () =>
                "Stellen Sie sicher, dass Sie die entsprechenden Berechtigungen (Nur-Anzeige) festlegen.",
              3: () =>
                "Sobald Sie den API-Schlüssel und das Geheimnis erstellt haben, fügen Sie sie in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
    },
    coinbaseprime: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Wir haben ein Problem behoben, das TWAP-Transaktionen betraf, die vor März 2025 importiert wurden. Um fehlende Transaktionen wiederherzustellen, löschen Sie bitte Ihre API-Schlüssel und fügen Sie sie erneut hinzu. Durch eine Hard-Sync-Synchronisierung werden auch die fehlenden Transaktionen importiert, es können jedoch doppelte Einträge entstehen. Überprüfen und entfernen Sie daher alle Duplikate. Weitere Informationen erhalten Sie beim Support.",
            },
            title: () => "TWAP-Transaktions-Update",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Coinbase Prime an",
              1: () =>
                "Klicken Sie unten links auf der Seite auf das Zahnradsymbol",
              2: () => "Klicken Sie auf APIs",
              3: () =>
                "Klicken Sie neben „Aktivierte Schlüssel“ auf „API-Schlüssel erstellen“.",
              4: () =>
                "Geben Sie im Popup-Fenster den „API-Namen“, das „Ablaufdatum“ und den schreibgeschützten „Zugriffstyp“ ein.",
              5: () => "Klicken Sie auf „Weiter“.",
              6: () => "Bestätigen Sie Ihre Identität mit YubiKey",
              7: () => "Warten Sie auf Konsensgenehmigungen",
              8: () =>
                "Suchen Sie unter „Ausstehende Schlüssel“ Ihren API-Schlüssel",
              9: () => "Klicken Sie auf „Schlüssel aktivieren“.",
              10: () =>
                "Klicken Sie auf „API-Schlüssel aktivieren“ und verifizieren Sie noch einmal mit YubiKey",
              11: () =>
                "Kopieren und speichern Sie den generierten API-Schlüssel, das Geheimnis und die Passphrase. Fügen Sie den Zugriffsschlüssel in „API-Schlüssel“, die Passphrase in „Passphrase“ und den Signaturschlüssel in „Geheimnis“ ein.",
              12: () =>
                "Ausführlichere Anweisungen finden Sie unter: https://help.coinbase.com/en/prime/coinbase-prime-api/creating-a-coinbase-prime-api",
            },
          },
        },
      },
    },
    coinbasepro: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Wenn Sie mehr als 500 OTC-Trades haben, empfehlen wir, die OTC-Trades mithilfe einer CSV-Datei hochzuladen. Bitte beachten Sie, dass bei einer solchen Kombination von API und CSV doppelte OTC-Trades entstehen können.",
              1: () =>
                "Alle über die API importierten OTC-Trades enthalten „OTC“ in der Transaktionsbeschreibung. In Kombination mit der Massenlöschung können damit Duplikate problemlos entfernt werden.",
            },
            title: () => "OTC-Handel",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Seit dem 20. November 2023 werden Coinbase Pro und seine API von Coinbase nicht mehr unterstützt. Wenn Sie die API vor diesem Datum nicht importiert haben, wechseln Sie bitte zu „Datei hochladen“ und folgen Sie den Anweisungen, um Ihren Coinbase Pro-Transaktionsverlauf per CSV hochzuladen.",
              1: () =>
                "Wenn Sie die API bereits vor diesem Datum importiert haben und die Synchronisierung aufgrund eines abgelaufenen API-Schlüssels fehlschlägt, importieren Sie Ihren Coinbase Pro-Transaktionsverlauf per CSV, beginnend mit dem letzten Synchronisierungsdatum Ihrer API.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Bitte beachten Sie: Wir verarbeiten nur Einzahlungen und Abhebungen aus der CSV-Datei „Kontoauszug“. Alle anderen Transaktionen werden aus der CSV-Datei „Kontoauszug ausfüllen“ übernommen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Coinbase-Konto an.",
              1: () => "Klicken Sie oben rechts auf das Profilsymbol.",
              2: () =>
                "Wählen Sie „Berichte“ aus dem Dropdown-Menü (https://www.coinbase.com/reports).",
              3: () =>
                "Wählen Sie „Kontoauszüge anzeigen“ im Abschnitt „Coinbase Pro-Kontoauszüge“ (https://accounts.coinbase.com/statements/pro).",
              4: () =>
                "Belassen Sie die Dropdown-Menüs „Portfolio“, „Kontotyp“ und „Datum“ auf den jeweiligen „Alle“-Einstellungen.",
              5: () =>
                "Wählen Sie „Konto“ als Berichtstyp, wählen Sie CSV und klicken Sie dann auf „Generieren“, um die Datei „account.csv“ herunterzuladen.",
              6: () =>
                "Wählen Sie „Fills“ als Berichtstyp, wählen Sie CSV und klicken Sie auf „Generieren“, um die Datei „fills.csv“ herunterzuladen.",
              7: () =>
                "Laden Sie beide CSV-Dateien in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    coinberry: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Stellen Sie sicher, dass Ihr Bericht alle Jahre umfasst, in denen Sie mit Kryptowährung gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich in einem Webbrowser bei Ihrem Coinberry-Konto an.",
              1: () =>
                "Navigieren Sie zu Ihrem Dashboard und klicken Sie auf „Coinberry-Aktivität“, um Ihre Handelsdaten herunterzuladen. Coinberry CSV-Download",
              2: () =>
                "Öffnen oder speichern Sie die Datei nicht vor dem Import, da dies das Format der CSV-Datei ändern könnte",
              3: () =>
                "Laden Sie die gerade heruntergeladene Datei hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    coincheck: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Coincheck-Konto an",
              1: () =>
                "Wählen Sie im Menü auf der linken Seite das Zahnradsymbol aus und klicken Sie dann auf „API-Schlüssel“.",
              2: () =>
                "Führen Sie die 2FA-Prüfung durch und klicken Sie dann auf „Weiteren API-Schlüssel hinzufügen“.",
              3: () =>
                "Wählen Sie im angezeigten Fenster unter der Überschrift „Bestellung“ die Option „Handelsverlauf“",
              4: () =>
                "Wählen Sie unter der Überschrift „Konto“ die Optionen „Sendeverlauf“ und „Einzahlungsverlauf“ aus.",
              5: () =>
                "Wählen Sie unter der Überschrift „JPY abheben“ die Option „JPY-Abhebungsverlauf“",
              6: () =>
                "Lassen Sie das IP-Feld leer, geben Sie Ihr Passwort ein und klicken Sie auf „OK“, um den API-Schlüssel zu generieren",
              7: () =>
                "Geben Sie hier sowohl den „API-Schlüssel“ als auch das „API-Geheimnis“ in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
    },
    coincorner: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Die Datei muss eine CSV-Datei sein. Bitte exportieren Sie in CSV, wenn Sie eine XLSX-Datei haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sie können Transaktionen aus einer von Coincorner exportierten CSV-Datei importieren",
            },
          },
        },
      },
    },
    coindcx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Öffnen Sie die mobile CoinDCX-App und klicken Sie oben links auf das Profilsymbol.",
              1: () => "Wählen Sie „Berichte herunterladen“ aus.",
              2: () =>
                "Klicken Sie im Abschnitt „Berichte herunterladen“ auf „Handelsbericht“.",
              3: () =>
                "Geben Sie das Geschäftsjahr und den Zeitraum ein und klicken Sie dann auf „Generieren“. Ihr Bericht wird automatisch heruntergeladen.",
              4: () =>
                "Wenn sich Ihre Trades über mehrere Zeiträume erstrecken, wiederholen Sie diesen Schritt nach Bedarf.",
              5: () => "Laden Sie die Dateien zum Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    coinex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem CoinEx-Konto an",
              1: () =>
                "Erweitern Sie das Menü „Assets“ und klicken Sie auf „Verlauf“.",
              2: () =>
                "Legen Sie den Zeitraum Ihrer Transaktionsdaten fest und klicken Sie auf „Exportieren“, um eine XLSX-Datei herunterzuladen.",
              3: () =>
                "Laden Sie Ihre XLSX-Datei in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    coinexchange: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Bitte beachten Sie, dass Abhebungen und Einzahlungen nicht in der CSV-Datei mit den Transaktionsdaten enthalten sind und manuell hinzugefügt werden müssen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Dieser Austausch wurde deaktiviert, Sie können Ihre gespeicherten Transaktionsdaten jedoch weiterhin im CSV-Format hochladen",
            },
          },
        },
      },
    },
    coinfalcon: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Navigieren Sie zum Abschnitt „Konto“ Ihres Kontos",
              1: () => "Klicken Sie auf API-Zugriff",
              2: () =>
                "Erstellen Sie einen neuen API-Schlüssel und kopieren Sie die Schlüssel in den Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinfield: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "CoinField lässt Sie immer nur Berichte für einen Markt gleichzeitig herunterladen. Stellen Sie daher sicher, dass Sie Berichte erstellen, die alle Märkte umfassen, auf denen Sie gehandelt haben (z. B. BTC/USD, ETH/CAD), und laden Sie diese hier hoch.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich in einem Webbrowser bei Ihrem CoinField-Konto an.",
              1: () =>
                "Navigieren Sie zu Ihrem Dashboard und klicken Sie in der Navigationsleiste links auf Trade > Handelsverlauf",
              2: () =>
                "Laden Sie die CSV-Datei mit Ihrem Handelsverlauf herunter",
              3: () =>
                "Stellen Sie sicher, dass Sie diesen Vorgang für alle Märkte/Kryptowährungen wiederholen, mit denen Sie gehandelt haben",
              4: () =>
                "Laden Sie die gerade heruntergeladenen Dateien hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    coinjar: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem CoinJar-Konto an",
              1: () =>
                "Klicken Sie auf die Schaltfläche unten, um in einem neuen Tab zu CoinJar weitergeleitet zu werden",
              2: () =>
                "Aktivieren Sie Berichtsberechtigungen in Ihrem CoinJar-Konto",
              3: () =>
                "Wir synchronisieren dann Ihren CoinJar-Transaktionsverlauf",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Die CSV-Importoption unterstützt keine Bündel-, Entbündelungs- und Liquidationstransaktionen. Wenn Sie diese Transaktionen haben, importieren Sie sie bitte über die benutzerdefinierte CSV-Option",
              1: () =>
                "Wenn Ihre Abhebungen für den persönlichen Gebrauch oder Verkaufstransaktionen waren, können Sie sie auf der nächsten Seite als solche kennzeichnen",
              2: () =>
                "Wenn Sie über die CoinJar-Börse gehandelt haben, müssen Sie auch die CSV-Datei „fill“ hinzufügen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei CoinJar an",
              1: () =>
                "Klicken Sie in der oberen Navigationsleiste auf „Einstellungen“.",
              2: () =>
                "Klicken Sie in der linken Seitenleiste auf „Berichte und Kontoauszüge“.",
              3: () => "Klicken Sie auf „Transaktionsverlaufsauszüge“.",
              4: () =>
                "Klicken Sie auf „Kontoauszug“ im CSV-Format herunterladen",
              5: () =>
                "Klicken Sie auf „Benutzerdefinierter Datumsbereich“ und wählen Sie Ihren gesamten Handelsverlauf als Datumsbereich aus.",
              6: () =>
                "Exportieren Sie die CSV-Datei und laden Sie beide CSV-Dateien in den Crypto Tax Calculator hoch.",
              7: () =>
                "Sie sollten eine E-Mail mit Ihrer CSV erhalten. Laden Sie diese CSV herunter und laden Sie sie hier hoch.",
            },
          },
        },
      },
    },
    coinjarexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem CoinJar Exchange-Konto an",
              1: () =>
                "Navigieren Sie in den Einstellungen Ihres Kontos zum Abschnitt API",
              2: () => "Einen neuen API-Schlüssel erstellen",
              3: () =>
                "Stellen Sie sicher, dass der Schlüssel zum Bereich „schreibgeschützt“ gehört.",
              4: () =>
                "Kopieren Sie das „Geheimnis“ Ihres neuen API-Schlüssels hier in den Crypto Tax Calculator und geben Sie einen Spitznamen ein",
            },
          },
        },
      },
    },
    coinlist: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Unsere CoinList CSV-Integration befindet sich derzeit in der Betaphase. Wenn Sie Probleme haben oder Feedback haben, wenden Sie sich bitte an unseren Support",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem CoinList-Konto an",
              1: () =>
                "Klicken Sie auf das Dropdown-Menü oben rechts auf der CoinList-Plattform und navigieren Sie zu „Wallets“.",
              2: () =>
                "Wählen Sie Ihre Wallets aus, legen Sie den Zeitraum für die Transaktionsdaten fest und klicken Sie auf „CSV herunterladen“.",
              3: () =>
                "Laden Sie die CSV-Datei in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    coinlistpro: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem CoinList Pro-Konto an",
              1: () =>
                "Klicken Sie auf das Dropdown-Menü oben rechts auf der CoinList Pro-Plattform",
              2: () =>
                "Wählen Sie „API“ und klicken Sie dann auf „Neuen Schlüssel erstellen“.",
              3: () =>
                "Generieren Sie einen eindeutigen API-Schlüssel mit Nur-Lese-Berechtigungen",
              4: () =>
                "Kopieren Sie den generierten „API-Schlüssel“ und das „API-Geheimnis“ hier in den Crypto Tax Calculator, geben Sie einen optionalen Spitznamen ein und klicken Sie dann auf „API hinzufügen“, um Ihre API zu synchronisieren",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem CoinList Pro-Konto an",
              1: () =>
                "Klicken Sie auf das Dropdown-Menü oben rechts auf der CoinList Pro-Plattform",
              2: () =>
                "Wählen Sie „Kontoauszüge“ aus, legen Sie „Kontoauszugstyp“, „Startdatum“ und „Enddatum“ fest und klicken Sie auf „Kontoauszug anfordern“.",
              3: () =>
                "Laden Sie BEIDE CSV-Dateien und die Konto-CSV in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    coinmetro: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich in einem Webbrowser bei Ihrem CoinMetro-Konto an.",
              1: () =>
                "Navigieren Sie zu Ihrem Dashboard und scrollen Sie nach unten zu Ihrem Transaktionsverlauf",
              2: () =>
                "Wählen Sie über das Dropdown-Menü „Datumsbereich“ den Datumsbereich aus, der dem Beginn Ihrer Handelsperiode oder dem gewünschten Zeitraum entspricht.",
              3: () =>
                "Wählen Sie „Einzahlung & Auszahlung“ im Dropdown-Menü „Typen“",
              4: () =>
                "Klicken Sie auf „CSV herunterladen“, um Ihren Transaktionsverlauf herunterzuladen",
              5: () =>
                "Stellen Sie sicher, dass Sie dies mit allen gehandelten Vermögenswerten wiederholen, die im Dropdown-Menü „Vermögenswert“ ausgewählt werden können.",
              6: () =>
                "Laden Sie die gerade heruntergeladenen Dateien hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    coinsmart: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich mit Ihren alten Coinsmart-Anmeldedaten bei Ihrem Bitbuy-Konto an und wenden Sie sich an den Bitbuy-Kundensupport, um Ihren Transaktionsverlauf anzufordern.",
              1: () =>
                "Laden Sie Ihre CSV-Dateien hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    coinspot: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Wir synchronisieren alle Ihre CoinSpot-Daten über die API. Dazu gehören Trades, Airdrops, Krypto-Transfers, AUD-Einzahlungen/-Abhebungen, Affiliate-Einnahmenzahlungen und Empfehlungseinnahmenzahlungen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei CoinSpot an und gehen Sie zur „API“-Seite (https://www.coinspot.com.au/my/api).",
              1: () => "Klicken Sie auf „Neuen API-Schlüssel generieren“.",
              2: () =>
                "Benennen Sie den Schlüssel und legen Sie den API-Schlüsseltyp auf „Nur Lesen“ fest.",
              3: () =>
                "Geben Sie Ihren Zwei-Faktor-Code ein und klicken Sie auf „Neuen Schlüssel erstellen“.",
              4: () =>
                "Bestätigen Sie die Erstellung des API-Schlüssels, indem Sie auf den Link klicken, der an Ihre E-Mail gesendet wurde.",
              5: () =>
                "Kopieren Sie den „API-Schlüssel“ und fügen Sie ihn in CTC ein",
              6: () =>
                "Zeigen Sie den „Geheimschlüssel“ an, kopieren Sie ihn und fügen Sie ihn in CTC ein (Sie können nicht mehr darauf zugreifen).",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/Pls-kQAg94U",
            title: () => "So importieren Sie Ihre Coinspot-API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Die CSV-Datei enthält keine Informationen zu Airdrops, AUD-Einzahlungen/-Abhebungen oder Affiliate-/Empfehlungszahlungen. Sie müssen diese entweder manuell hinzufügen oder die CoinSpot-API-Synchronisierung verwenden.",
            },
            title: () => "Fehlende Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Besuchen Sie https://www.coinspot.com.au/my/orders/history",
              1: () =>
                "Klicken Sie auf „Sendet/Empfangt CSV“, um Ihren Sende- und Empfangsverlauf als „sendreceives.csv“ herunterzuladen.",
              2: () =>
                "Klicken Sie auf „Käufe/Verkäufe CSV“, um Ihren Bestellverlauf als „orderhistory.csv“ herunterzuladen.",
              3: () =>
                "Laden Sie beide Dateien zum Crypto Tax Calculator hoch.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/IXAkAHO2N9k",
            title: () => "So importieren Sie Ihre Coinspot-CSVs",
          },
        },
      },
    },
    coinsquare: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich hier bei Ihrem Coinsquare-Konto an (https://app-new.coinsquare.com/sign-in).",
              1: () =>
                "Klicken Sie auf das Dropdown-Menü neben Ihrem Namen (oben rechts) und wählen Sie „Dokumente“ aus.",
              2: () =>
                "Wählen Sie unter „Kontoauszüge“ das Steuerjahr aus, für das Sie Ihren Transaktionsverlauf benötigen, und klicken Sie auf „Herunterladen“.",
              3: () =>
                "Laden Sie alle Ihre heruntergeladenen CSV-Dateien hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    coinstash: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Coinstash-Konto an",
              1: () =>
                "Gehen Sie zu Ihrer Einstellungsseite (https://coinstash.com.au/settings)",
              2: () =>
                "Scrollen Sie nach unten zum Abschnitt „Berichte“. Klicken Sie neben „Transaktionsverlauf“ auf die Schaltfläche „Herunterladen“.",
              3: () =>
                "Laden Sie nun die CSV in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    coinswitch: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem CoinSwitch Kuber-Konto an.",
              1: () =>
                "Tippen Sie auf das blaue Profilsymbol in der oberen linken Ecke der Startseite.",
              2: () =>
                "Gehen Sie zum Abschnitt „Berichte“ und laden Sie Ihren Kontoauszug herunter.",
              3: () =>
                "Wählen Sie den Abschnitt „Steuer-Gewinn- und Verlustrechnung“ aus.",
              4: () =>
                "Wählen Sie das Geschäftsjahr aus, wählen Sie „Alle Quartale“ und tippen Sie auf „Kontoauszug senden“.",
              5: () => "Laden Sie die Datei zum Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    cointracking: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nach dem Import werden die Transaktionen, sofern relevant, in die entsprechenden Börsenimporte aufgeteilt.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sie können Ihren gesamten Handelsverlauf von Cointracking importieren",
              1: () =>
                "Melden Sie sich bei Ihrem CoinTracking-Konto an und klicken Sie in der Navigationsleiste auf „Coins eingeben“ (https://cointracking.info/enter_coins.php).",
              2: () =>
                "In der Tabelle sehen Sie eine Schaltfläche „Exportieren“. Klicken Sie auf diese Schaltfläche und wählen Sie „CSV“ aus der Dropdown-Auswahl. Eine CSV-Datei wird automatisch heruntergeladen",
              3: () =>
                "Laden Sie die heruntergeladene CSV-Datei hier zum Crypto Tax Calculator hoch.",
            },
            title: () => "Handelstabelle (empfohlen):",
          },
          1: {
            items: {
              0: () =>
                "Alternativ können Sie Ihre Schlussbilanz des letzten Geschäftsjahres exportieren.",
              1: () =>
                "Bitte stellen Sie sicher, dass Ihr Export in Ihrer lokalen Fiat-Währung erfolgt.",
              2: () => "Das Datumsformat sollte „TT.MM.JJJJ“ sein.",
            },
            title: () => "Schlussbilanz:",
          },
        },
      },
    },
    cointree: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Notieren Sie Ihr API-Geheimnis unbedingt an einem sicheren Ort.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Cointree an",
              1: () =>
                "Klicken Sie oben rechts auf Ihr Profilsymbol und navigieren Sie zu „Einstellungen“.",
              2: () =>
                "Navigieren Sie zu „API-Schlüssel“ (letzte Option im oberen Menü)",
              3: () =>
                "Geben Sie im Feld „Anzeigename des API-Schlüssels“ einen Anzeigenamen für Ihren API-Schlüssel ein.",
              4: () =>
                "Wählen Sie eine Zugriffsebene für Ihren API-Schlüssel und klicken Sie auf „API-Schlüssel + Geheimnis generieren“.",
              5: () =>
                "Kopieren Sie Ihren API-Schlüssel und Ihr API-Geheimnis und fügen Sie sie in den Crypto Tax Calculator ein",
              6: () =>
                "Geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Cointree an",
              1: () => "Navigieren Sie zu Wallet > Transaktionen",
              2: () =>
                "Klicken Sie in der Tabelle „Alle Transaktionen“ auf die Schaltfläche „Exportieren“.",
              3: () =>
                "Klicken Sie im Dropdown-Menü auf „Aufträge exportieren (csv)“, um Ihren Handelsverlauf herunterzuladen.",
              4: () =>
                "Klicken Sie im Dropdown-Menü auf „Transaktionen exportieren (csv)“, um Ihren Einzahlungs-/Auszahlungsverlauf herunterzuladen.",
              5: () =>
                "Laden Sie beide CSV-Dateien hier zum Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    "cosmos/ibc": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () =>
                "Navigieren Sie zu dem Konto, das Sie importieren möchten, und kopieren Sie Ihre öffentliche Wallet-Adresse",
              2: () =>
                "Wählen Sie in CTC das entsprechende Blockchain-Netzwerk aus der Dropdown-Liste",
              3: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    cosmoshub: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Daten vor dem 20.02.2021 sind aufgrund einer Einschränkung der Datenquelle nicht verfügbar.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Cosmos Hub-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    coss: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Bei Coss anmelden",
              1: () => "Navigieren Sie zur API-Verwaltungsseite",
              2: () => "Geben Sie den Namen des Schlüssels ein",
              3: () =>
                "Klicken Sie auf die Schaltfläche „Neuen Schlüssel erstellen“",
              4: () => "Geben Sie Ihren 2FA-Code ein",
              5: () => "Klicken Sie auf die Schaltfläche „Weiter“",
              6: () =>
                "Überprüfen Sie Ihre E-Mails und klicken Sie auf den Link „Klicken Sie hier, um einen API-Schlüssel zu erstellen“.",
              7: () =>
                "Kopieren Sie sowohl den öffentlichen Schlüssel als auch den geheimen Schlüssel",
              8: () =>
                "Klicken Sie auf die Schaltfläche „Bearbeiten“, um die Handelsberechtigung für diesen Schlüssel zu deaktivieren.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Bei Coss anmelden",
              1: () => "Navigieren Sie zur Handelshistorie",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Kompletten Handelsverlauf exportieren“",
              3: () =>
                "Stellen Sie sicher, dass die ausgewählten Daten für einen Zeitraum vor der Kontoeröffnung liegen und jetzt",
              4: () => "Klicken Sie auf „Exportieren“.",
              5: () => "Laden Sie CSV zum Krypto-Steuerrechner hoch",
            },
          },
        },
      },
    },
    crex24: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Überprüfen Sie beim Generieren der API-Schlüssel noch einmal, dass Sie nur die Optionen R1 und R3 auswählen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei CREX24 an",
              1: () => "Klicken Sie oben rechts auf „Mein Konto“.",
              2: () => "Klicken Sie auf die Registerkarte „API-Schlüssel“",
              3: () =>
                "Klicken Sie auf „Neuer API-Schlüssel“ und geben Sie den Code ein, den Sie per E-Mail erhalten haben",
              4: () =>
                "Sie sollten jetzt einen neuen API-Schlüssel in der Tabelle sehen",
              5: () =>
                "Setzen Sie die Registerkarte „Status“ auf „Ein“ und aktivieren Sie die Kontrollkästchen R1 und R3.",
              6: () =>
                "Kopieren Sie den API-Schlüssel und das Geheimnis und fügen Sie sie in den Crypto Tax Calculator ein",
            },
          },
        },
      },
    },
    cronos: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen jetzt CRC-20-Token. Wenn Sie Ihr Cronos-Konto zuvor importiert haben, müssen Sie Ihre API möglicherweise „hart synchronisieren“, um historische Daten zu aktualisieren.",
            },
            title: () => "CRC-20-Token",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Wallet-Adresse, die Sie für den Handel über Cronos verwendet haben (beginnend mit 0x)",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    cryptodotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Das Datumsformat muss eines der folgenden sein: „TT-MM-JJ H:mm“, „M/T/JJ H:mm“ oder „JJJJ-MM-TT HH:mm:ss“. Wenn Ihre CSV-Datei nicht das richtige Datumsformat hat, wenden Sie sich bitte per Webchat an unser Supportteam.",
              1: () =>
                "Wenn Sie Ihre CSV-Datei „fiat_transactions_record“ importieren, MÜSSEN Sie alle Zeilen mit den Transaktionsarten „viban_purchase“ und „recurring_buy_order“ entfernen. Da diese Daten bereits in der CSV-Datei „crypto_transactions_record“ aufgezeichnet sind, führt der Import beider Daten zu Importfehlern.",
            },
            title: () => "CSV-Formatierung",
          },
          1: {
            items: {
              0: () =>
                "Manchmal ist die exportierte CSV-Datei beschädigt. Wenn beim Hochladen Fehler auftreten, versuchen Sie, die Datei erneut lokal als CSV zu speichern und dann erneut zu importieren.",
            },
            title: () => "Upload-Fehler",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Öffnen Sie Ihre Crypto.com-App und gehen Sie zur Registerkarte „Konten“.",
              1: () =>
                "Klicken Sie auf „Transaktionsverlauf“ und dann auf „Exportieren“.",
              2: () => "Wählen Sie unter „Transaktion“ „Krypto-Wallet“ aus.",
              3: () =>
                "Wählen Sie ein Startdatum für alle Transaktionen und legen Sie das Enddatum auf heute fest.",
              4: () =>
                "Klicken Sie auf „In CSV exportieren“, um die Datei herunterzuladen.",
              5: () =>
                "Laden Sie die CSV-Datei zum Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    cryptodotcomexchange: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Die Synchronisierung der API kann bis zu 90 Minuten dauern, da wir jeden Tag eine Iteration durchführen müssen und die API von Crypto.com gedrosselt wird.",
              1: () =>
                "Manchmal schlägt die API-Synchronisierung aufgrund von Problemen mit Crypto.com fehl. Wenn dies bei Ihnen der Fall ist, versuchen Sie stattdessen den CSV-Import",
              2: () =>
                "Die API von Crypto.com unterstützt nur die letzten 6 Monate des Transaktionsverlaufs. Wenn Sie ältere Daten benötigen, verwenden Sie bitte die CSV-Methode.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie auf Einstellungen > API-Schlüssel (https://crypto.com/exchange/personal/api-management).",
              1: () =>
                "Generieren Sie schreibgeschützte API-Schlüssel, die keinen Handel ermöglichen",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Aufgrund länderspezifischer Einschränkungen unterstützen wir derzeit keinen Margin- oder Derivatehandel. Wenn Sie diese Produkte gehandelt haben, wenden Sie sich bitte an unseren In-App-Chat-Support. Darüber hinaus enthält die Auszahlungs-CSV keine Gebühren. Sie müssen diese manuell aktualisieren.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Crypto.com bietet mehrere CSV-Formate zum Exportieren von Daten. Sie benötigen alle davon (Transaktionen, Ein- und Auszahlungen und Handelsverlauf), um Ihren vollständigen Transaktionsverlauf zu importieren.",
              1: () =>
                "Bitte beachten Sie, dass Crypto.com einen maximalen Datumsbereich von 180 Tagen pro Export zulässt. Um Ihren gesamten Transaktionsverlauf abzudecken, müssen Sie möglicherweise mehrere Dateien für jedes Format exportieren.",
              2: () => "Melden Sie sich bei Ihrem Crypto.com-Konto an.",
              3: () =>
                "Ändern Sie die Sprache auf Englisch (falls dies nicht bereits geschehen ist).",
              4: () => "Wählen Sie im Dashboard im Menü links „Wallet“ aus.",
              5: () =>
                "Navigieren Sie zur Registerkarte „Transaktionen“. Wählen Sie einen Datumsbereich und klicken Sie auf „Exportieren“, um die CSV-Datei mit den Transaktionen herunterzuladen.",
              6: () => "Kehren Sie zum Wallet-Menü zurück.",
              7: () =>
                "Navigieren Sie zur Registerkarte „Einzahlung und Auszahlung“. Wählen Sie einen Datumsbereich und klicken Sie auf „Exportieren“, um die CSV-Datei „Einzahlung und Auszahlung“ herunterzuladen.",
              8: () =>
                "Wählen Sie im Dashboard im Menü links „Bestellungen“ aus.",
              9: () =>
                "Navigieren Sie zur Registerkarte „Handelsverlauf“. Wählen Sie einen Datumsbereich und klicken Sie auf „Exportieren“, um die CSV-Datei mit dem Handelsverlauf herunterzuladen.",
              10: () =>
                "Importieren Sie alle drei heruntergeladenen Dateien (deposit&Withdrawal.csv, OEX_TRANSACTION.csv in CTC, OEX_TRADE.csv) in CTC.",
            },
          },
        },
      },
    },
    cryptospend: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Wayex-Konto an",
              1: () =>
                "Gehen Sie zur Seite „Konto“, scrollen Sie nach unten zu „Transaktionen“ und klicken Sie dann auf „Transaktionen abrufen“.",
              2: () =>
                "Dadurch wird eine CSV-Datei generiert, die an Ihre E-Mail gesendet wird",
              3: () => "Laden Sie die CSV-Datei aus Ihrer E-Mail herunter",
              4: () =>
                "Laden Sie diese CSV hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    cryptotaxcalculatorlegacy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nur zum Importieren manueller Trades von legacy.cryptotaxcalculator.io",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Bitte senden Sie uns zunächst eine E-Mail, wenn Sie vom Legacy-Modell konvertieren, um die manuellen Trades als CSV-Datei zu erhalten.",
              1: () => "Laden Sie die CSV hier zum Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    customCSV: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Erweitertes CSV für detailliertere Transaktionen: https://cryptotaxcalculator.io/guides/advanced-manual-csv-import",
              1: () =>
                "Stellen Sie sicher, dass Ihre Daten korrekt vorbereitet sind und im CSV-Dateiformat vorliegen!",
            },
            title: () =>
              "Wenn Ihre Börse nicht über unseren automatischen Import verfügbar ist, können Sie sie mithilfe einer unserer benutzerdefinierten CSV-Vorlagen importieren:",
          },
        },
      },
    },
    daedalus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Geben Sie nur EINE Adresse aus jeder Brieftasche ein. Wir führen eine Suche durch und ziehen die Daten für alle anderen Adressen in der Brieftasche automatisch ein.",
              1: () =>
                "Das Importieren mehrerer Adressen aus derselben Brieftasche führt zu doppelten Transaktionen.",
              2: () =>
                "Derzeit unterstützen wir nicht den Erhalt des vollständigen Transaktionsverlaufs von Wallets, bei denen jede einzelne Adresse mehr als 10 Transaktionen aufweist. Wir arbeiten daran, zusätzliche Unterstützung für solche Wallets bereitzustellen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie eine beliebige Shelly-Adresse (addr1..) oder Stake-Adresse (stake1...) aus Ihrem Daedalus-Wallet in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können Ihre Daedalus-Wallet-Transaktionen jetzt alternativ direkt über die Blockchain importieren, indem Sie die Option „Synchronisieren über API“ verwenden. Dadurch werden alle Transaktionen und Staking-Belohnungen für Ihr Daedalus-Wallet abgerufen.",
              1: () =>
                "Die Transaktions-CSV scheint in einigen Fällen fehlerhafte Daten zu enthalten. Dies ist ein Problem mit Daedalus. Bitte wenden Sie sich an den Support, wenn Sie Unstimmigkeiten feststellen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Daedalus-Wallet an und exportieren Sie Ihre Belohnungs-CSV",
              1: () => "Sie können die Transaktionen auch als CSV importieren.",
            },
          },
        },
      },
    },
    dash: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
              1: () =>
                "Wir unterstützen auch xpub, ypub und zpub Adressen, allerdings wird xpub bevorzugt",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Dash-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    ddex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () => "Wählen Sie die Börse aus dem Dropdown-Menü",
              3: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              4: () => "Behalten Sie die anderen Standardeinstellungen bei",
              5: () => "Klicken Sie auf „Verlauf abrufen“.",
              6: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              7: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    decentrex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Erweitern Sie auf der linken Seite im Menü „Exchange History“ und klicken Sie auf Decentrex",
              3: () =>
                "Klicken Sie unter „Operationsarten“ auf „Trades + Fonds“.",
              4: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              5: () => "Behalten Sie die anderen Standardeinstellungen bei",
              6: () => "Klicken Sie auf „Verlauf abrufen“.",
              7: () =>
                "Sobald Sie fertig sind, können Sie die Standard-CSV unter „Export Trades“ und „Export Funds“ exportieren.",
              8: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV-Dateien hochladen",
            },
          },
        },
      },
    },
    deltaBalances: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () => "Wählen Sie die Börse aus dem Dropdown-Menü",
              3: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              4: () => "Behalten Sie die anderen Standardeinstellungen bei",
              5: () => "Klicken Sie auf „Verlauf abrufen“.",
              6: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              7: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    deribit: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Öffnen Sie die API-Schlüsselseite auf Deribit und wählen Sie „Neuen API-Schlüssel hinzufügen“",
              1: () =>
                "Wählen Sie die Leseberechtigung für Konto, Handel und Wallet",
              2: () =>
                "Kopieren Sie den Schlüssel und das Geheimnis in den Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Deribit an, klicken Sie oben rechts auf Ihren Benutzernamen und wählen Sie „Transaktionsprotokoll“.",
              1: () =>
                "Wählen Sie die Daten aus, an denen Sie gehandelt haben, und laden Sie die Protokolle herunter",
              2: () =>
                "Wenn Sie mit Ethereum gehandelt haben, müssen Sie auch oben links auf Ethereum klicken und die obigen Schritte wiederholen",
              3: () =>
                "Sie können dann die CSV-Dateien in die App importieren, um Gewinn-/Verlusteinnahmen aus dem Handel zu melden",
            },
          },
        },
      },
    },
    digitalsurge: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Digital Surge-Konto an.",
              1: () =>
                "Klicken Sie oben rechts auf der Seite auf das Hamburger-Menü. Wählen Sie im Dropdown-Popup „API-Schlüssel“ aus, um zur API-Schlüsselseite zu navigieren (https://digitalsurge.com.au/account-settings/api-keys).",
              2: () =>
                "Klicken Sie oben rechts auf der Seite auf die Schaltfläche „Neuen API-Schlüssel erstellen“",
              3: () =>
                "Wählen Sie im Popup-Fenster unbedingt „Nur lesen“ aus und klicken Sie dann auf „Weiter“.",
              4: () =>
                "Geben Sie den 2FA-Bestätigungscode ein und klicken Sie dann auf „API-Schlüssel generieren“.",
              5: () =>
                "Kopieren Sie den angezeigten API-Schlüssel, fügen Sie ihn in das Feld „Geheim“ hier im Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie dann auf „API hinzufügen“, um Ihre API zu synchronisieren",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Digital Surge-Konto an.",
              1: () =>
                "Klicken Sie oben rechts auf der Seite auf das Hamburger-Menü. Wählen Sie im Dropdown-Popup „Transaktionsverlauf“ aus, um zur Seite „Transaktionsverlauf“ zu navigieren (https://digitalsurge.com.au/account-settings/transactions-history).",
              2: () =>
                "Klicken Sie auf der Seite „Transaktionsverlauf“ auf die Schaltfläche „Verlauf exportieren“ (auf dem Handy klicken Sie auf die drei Punkte rechts neben dem Titel „Transaktionsverlauf“).",
              3: () =>
                "Wählen Sie in der Dropdown-Liste die Option „Handelsverlauf“, um das Popup „Handelsverlauf exportieren“ zu öffnen. Lassen Sie in diesem Popup die Datumsauswahl leer (dies deckt Ihren gesamten Handelsverlauf ab) und wählen Sie das Format „CSV“. Klicken Sie nach der Auswahl auf die Schaltfläche „Verlauf exportieren“, um Ihre Datei „trades.csv“ herunterzuladen.",
              4: () =>
                "Wählen Sie in der Dropdown-Liste die Option „Überweisungsverlauf“, um das Popup „Überweisungsverlauf exportieren“ zu öffnen. Lassen Sie in diesem Popup die Datumsauswahl leer und wählen Sie das Format „CSV“. Klicken Sie nach der Auswahl auf die Schaltfläche „Verlauf exportieren“, um Ihre Datei „transfers.csv“ herunterzuladen.",
              5: () =>
                "Laden Sie hier sowohl die Dateien „trades.csv“ als auch „transfers.csv“ in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    doge: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
              1: () =>
                "Wir unterstützen auch xpub, ypub und zpub Adressen, allerdings wird xpub bevorzugt",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Dogecoin-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    dydx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Besuchen Sie trade.dYdX.exchange und verbinden Sie Ihre Wallet(s)",
              1: () => "Klicken Sie in der Menüleiste auf „Portfolio“",
              2: () => "Wählen Sie „Verlauf“ in der Seitenleiste",
              3: () =>
                "Klicken Sie oben rechts auf dem Bildschirm auf „Exportieren“.",
              4: () =>
                "Laden Sie alle 3 verfügbaren CSVs herunter: Überweisungen, Trades, Finanzierung",
              5: () =>
                "Laden Sie die extrahierten Dateien zum Crypto Tax Calculator hoch",
              6: () =>
                "Hinweis: Derzeit fehlt aufgrund der Art und Weise, wie dYdX die Leerverkäufe aufzeichnet, der Kaufverlauf. Wir arbeiten an einer Lösung. Im Moment können Sie möglicherweise den Gewinn und Verlust berechnen und unsere manuelle CSV-Datei verwenden, um einen realisierten Verlust/Gewinn für den Leerverkauf hinzuzufügen.",
            },
          },
        },
      },
    },
    dydx4: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Derzeit werden nur Derivatehandelsvorgänge wie die Erhöhung/Verringerung einer Position unterstützt.",
            },
            title: () => "Eingeschränkter Betriebssupport",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie Ihre dYdX v4-Adresse, indem Sie zur Protokollanwendung navigieren und die Adresse aus dem Menü oben rechts kopieren",
              1: () =>
                "Geben Sie die Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    dymension: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Dymension-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    easyTrade: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () => "Wählen Sie die Börse aus dem Dropdown-Menü",
              3: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              4: () => "Behalten Sie die anderen Standardeinstellungen bei",
              5: () => "Klicken Sie auf „Verlauf abrufen“.",
              6: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              7: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    easycrypto: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen derzeit Käufe und Verkäufe auf Easy Crypto. Wenn Sie Swaps oder andere Arten von Trades durchführen, wenden Sie sich bitte an den Support und senden Sie Ihre CSV-Datei an unser Support-Team.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Easy Crypto-Konto an",
              1: () =>
                "Navigieren Sie zu „Mein Konto“ - > „Bestellungen“ (https://easycrypto.ai/au/account/orders)",
              2: () =>
                "Unten auf der Seite finden Sie einen Link zum Herunterladen einer CSV-Datei Ihrer abgeschlossenen Bestellungen. Klicken Sie auf diesen Link, und eine CSV-Datei wird auf Ihren Computer heruntergeladen.",
              3: () =>
                "Laden Sie diese heruntergeladene Datei hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    elbaite: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Elbaite-Konto an",
              1: () => "Navigieren Sie zur Registerkarte [Trades]",
              2: () => "Klicken Sie auf [Nach CSV exportieren].",
              3: () =>
                "Laden Sie die CSV-Datei hier in Ihr Crypto Tax Calculator-Konto hoch",
            },
          },
        },
      },
    },
    enclaves: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Erweitern Sie auf der linken Seite im Menü „Exchange History“ und klicken Sie auf „Enclaves“",
              3: () =>
                "Klicken Sie unter „Operationsarten“ auf „Trades + Fonds“.",
              4: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              5: () => "Behalten Sie die anderen Standardeinstellungen bei",
              6: () => "Klicken Sie auf „Verlauf abrufen“.",
              7: () =>
                "Sobald Sie fertig sind, können Sie die Standard-CSV unter „Export Trades“ und „Export Funds“ exportieren.",
              8: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV-Dateien hochladen",
            },
          },
        },
      },
    },
    enkrypt: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr Enkrypt-Wallet",
              1: () =>
                "Klicken Sie auf das Netzwerk, das Sie importieren möchten",
              2: () => "Klicken Sie auf das Symbol „Adresse kopieren“",
              3: () =>
                "Wählen Sie im Crypto Tax Calculator das entsprechende Netzwerk aus",
              4: () =>
                "Fügen Sie die Adresse ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    ercDex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    eth: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen jetzt ERC-1155-Token, können jedoch Ihre aktuellen Guthaben für diese Token nicht importieren. Dies kann zu Abweichungen zwischen dem aus der Quell-API importierten Guthaben und dem von der App berechneten Guthaben führen.\n Derzeit werden ERC-404-Token nicht unterstützt, wir arbeiten jedoch daran, sie hinzuzufügen. Im Moment müssen Sie diese Transaktionen manuell zur App hinzufügen.",
            },
            title: () => "Hinweise",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Geben Sie Ihre Ethereum-Wallet-Adresse ein",
              1: () =>
                "Fügen Sie optional einen Spitznamen hinzu und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    ethen: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Erweitern Sie auf der linken Seite im Menü „Exchange History“ und klicken Sie auf ETHEN",
              3: () =>
                "Klicken Sie unter „Operationsarten“ auf „Trades + Fonds“.",
              4: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              5: () => "Behalten Sie die anderen Standardeinstellungen bei",
              6: () => "Klicken Sie auf „Verlauf abrufen“.",
              7: () =>
                "Sobald Sie fertig sind, können Sie die Standard-CSV unter „Export Trades“ und „Export Funds“ exportieren.",
              8: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV-Dateien hochladen",
            },
          },
        },
      },
    },
    etherc: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () => "Wählen Sie die Börse aus dem Dropdown-Menü",
              3: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              4: () => "Behalten Sie die anderen Standardeinstellungen bei",
              5: () => "Klicken Sie auf „Verlauf abrufen“.",
              6: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              7: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    etherdelta: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Erweitern Sie auf der linken Seite im Menü „Exchange History“ und klicken Sie auf EtherDelta",
              3: () =>
                "Klicken Sie unter „Operationsarten“ auf „Trades + Fonds“.",
              4: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              5: () => "Behalten Sie die anderen Standardeinstellungen bei",
              6: () => "Klicken Sie auf „Verlauf abrufen“.",
              7: () =>
                "Sobald Sie fertig sind, können Sie die Standard-CSV unter „Export Trades“ und „Export Funds“ exportieren.",
              8: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV-Dateien hochladen",
            },
          },
        },
      },
    },
    "ethereum-classic": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
          1: {
            items: {
              0: () =>
                "Bei einigen Geldbörsen können interne Transaktionen fehlen.",
            },
            title: () => "Fehlende interne Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Ethereum Classic-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    etherscan: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Der beste Weg, Daten aus EtherScan zu importieren, besteht darin, Ihre öffentliche Wallet-Adresse direkt zu importieren",
              1: () =>
                "Fügen Sie Ihre öffentliche Ethereum-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    ethex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    ethfinex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () => "Wählen Sie die Börse aus dem Dropdown-Menü",
              3: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              4: () => "Behalten Sie die anderen Standardeinstellungen bei",
              5: () => "Klicken Sie auf „Verlauf abrufen“.",
              6: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              7: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    etoro: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "eToro ändert häufig das CSV-Dateiformat exportierter Trades. Wenn Ihre Datei nicht in die Plattform importiert wird, ist es möglich, dass sie erneut geändert wurde. In diesem Fall empfehlen wir, den erweiterten CTC-CSV-Import für Ihre eToro-Trades zu verwenden.",
              1: () =>
                "Da der Crypto Tax Calculator für Kryptotransaktionen konzipiert ist, können wir keine Nicht-Krypto-Trades importieren. Wenn Ihr eToro-Konto solche Trades enthält, müssen Sie diese extern abrechnen.",
            },
            title: () => "Hinweise:",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem eToro-Konto an. Sie können den Kontoauszug direkt hier (https://www.etoro.com/documents/accountstatement) abrufen oder die folgenden Schritte ausführen.",
              1: () =>
                "Klicken Sie in der Seitenleiste auf „Portfolio“ und navigieren Sie dann zu „Verlauf“.",
              2: () =>
                "Erweitern Sie das Dropdown-Menü oben rechts und klicken Sie auf „Kontoauszug“.",
              3: () =>
                "Legen Sie den Zeitraum Ihrer Transaktionsdaten fest und klicken Sie auf „Erstellen“.",
              4: () =>
                "Klicken Sie oben rechts auf das „XLS“-Symbol, um eine „.xlsx“-Datei herunterzuladen.",
              5: () =>
                "Passen Sie im Feld „Währungssymbol“ des Importfelds die Währung genau an Ihren CSV-Header an (verwenden Sie beispielsweise USD statt $).",
              6: () =>
                "Laden Sie Ihre XLSX-Datei in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    evmos: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Evmos in der frühen Entwicklung",
              1: () => "Daten vor dem 28.04.2022 nicht verfügbar",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Evmos-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    exmo: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
              1: () =>
                "Wir unterstützen keine spezifischen IP-Adressen auf unserer Plattform. Bitte stellen Sie sicher, dass die Option „API-Zugriff nur von vertrauenswürdigen IP-Adressen“ beim Generieren eines API-Schlüssels deaktiviert ist.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Exmo-Konto an und navigieren Sie zur Registerkarte „Einstellungen“.",
              1: () =>
                "Klicken Sie auf die Registerkarte „API“ und stellen Sie sicher, dass die Option „API-Zugriff nur von vertrauenswürdigen IP-Adressen“ deaktiviert ist.",
              2: () =>
                "Erstellen Sie einen API-Schlüssel, indem Sie auf „API-Schlüssel generieren“ klicken und geben Sie ihn hier in den Crypto Tax Calculator als „API-Schlüssel“ bzw. „Geheimnis“ ein.",
            },
          },
        },
      },
    },
    exodus: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zum Verlaufsbereich Ihrer Brieftasche (oben rechts in Ihrer Exodus-Brieftasche).",
              1: () =>
                "Klicken Sie auf die Option „Alle Transaktionen exportieren“",
              2: () => "Laden Sie die Datei zum Crypto Tax Calculator hoch",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr Exodus-Wallet.",
              1: () =>
                "Wählen Sie die Kryptowährung aus, die Sie importieren möchten. Zum Beispiel Bitcoin oder Ethereum.",
              2: () =>
                "Kopieren Sie die entsprechende öffentliche Wallet-Adresse für diesen Vermögenswert.",
              3: () =>
                "Wählen Sie in CTC das entsprechende Blockchain-Netzwerk aus der Dropdown-Liste aus.",
              4: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    fantom: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen jetzt ERC-1155-Token, können jedoch Ihre aktuellen Guthaben für diese Token nicht importieren. Dies kann zu Abweichungen zwischen dem aus der Quell-API importierten Guthaben und dem von der App berechneten Guthaben führen.",
            },
            title: () => "ERC-1155-Token",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Fantom-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    "fearless-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr Fearless-Portemonnaie",
              1: () =>
                "Wählen Sie oben rechts das Netzwerk aus, das Sie importieren möchten",
              2: () =>
                "Klicken Sie oben links auf das Symbol „Adresse kopieren“",
              3: () =>
                "Wählen Sie im Crypto Tax Calculator das entsprechende Netzwerk aus",
              4: () =>
                "Fügen Sie die Adresse ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    fetchai: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Fetch.AI-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    flare: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
          1: {
            items: {
              0: () =>
                "Bei einigen Geldbörsen können interne Transaktionen fehlen.",
            },
            title: () => "Fehlende interne Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Flare-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    ftx: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Handelsgeschichte",
              1: () =>
                "Gewinn- und Verlustrechnungen für Futures und Perpetuals",
              2: () => "Einzahlungs-/Auszahlungsverlauf",
            },
            title: () =>
              "FTX ist nicht mehr betriebsbereit. Bitte verwenden Sie vorhandene FTX-CSVs oder die benutzerdefinierte CSV-Importoption des Crypto Tax Calculators, um Ihre FTX-Transaktionen zu importieren. Folgende vorhandene FTX-CSVs werden von uns unterstützt:",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Wir importieren nur die Spot-Trades für die CSV-Datei „Handelsverlauf“, nicht die Futures- und Perpetual-Trades (z. B. XXX-PERP). Verwenden Sie die PnL-CSV-Datei „Futures & Perpetuals“, um diese Daten zu importieren.",
              1: () =>
                "Die CSV-Datei „Bestellverlauf“ wird nicht unterstützt. Verwenden Sie stattdessen die PnL-CSV-Dateien „Handelsverlauf“ und „Futures & Perpetuals“.",
            },
            title: () => "Vorhandene FTX CSV-Supporthinweise",
          },
        },
      },
      api: {
        step: {
          0: {
            title: () =>
              "FTX ist nicht mehr betriebsbereit. Bitte verwenden Sie die benutzerdefinierte CSV-Importoption, um Ihre FTX-Transaktionen zu importieren.",
          },
        },
      },
    },
    ftxus: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX ist nicht mehr betriebsbereit und es ist nicht mehr möglich, historische Daten von dieser Börse abzurufen. Zuvor importierte FTX-Transaktionen sind weiterhin in Ihrem Konto zugänglich. Neue Importe müssen vorhandene FTX-CSVs oder die benutzerdefinierte CSV-Importoption verwenden. API-Synchronisierungen sind nicht mehr möglich.",
            },
            title: () => "FTX nicht mehr betriebsbereit",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX ist nicht mehr betriebsbereit. Bitte verwenden Sie die benutzerdefinierte CSV-Importoption, um Ihre FTX-Transaktionen zu importieren.",
          },
        },
      },
      csv: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX ist nicht mehr betriebsbereit und es ist nicht mehr möglich, historische Daten von dieser Börse abzurufen. Zuvor importierte FTX-Transaktionen sind weiterhin in Ihrem Konto zugänglich. Neue Importe müssen vorhandene FTX-CSVs oder die benutzerdefinierte CSV-Importoption verwenden. API-Synchronisierungen sind nicht mehr möglich.",
            },
            title: () => "FTX nicht mehr betriebsbereit",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX ist nicht mehr betriebsbereit. Bitte verwenden Sie die benutzerdefinierte CSV-Importoption, um Ihre FTX-Transaktionen zu importieren.",
          },
        },
      },
    },
    gate: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Gate.io an",
              1: () => "Navigieren Sie zur Seite „API-Schlüssel“",
              2: () => "Geben Sie ggf. das Fondskennwort und TOTP ein.",
              3: () =>
                "Klicken Sie auf die Schaltfläche „API-Schlüssel anzeigen“",
              4: () =>
                "Kopieren Sie sowohl den API-Schlüssel als auch das API-Geheimnis",
              5: () =>
                "Aktivieren Sie den schreibgeschützten Modus für Ihren API-Schlüssel",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Gate.io an",
              1: () => "Navigieren Sie zu Wallets > Handelsverlauf",
              2: () => 'Klicken Sie auf "ALLE" für Märkte und Münzen',
              3: () =>
                "Stellen Sie sicher, dass die ausgewählten Daten alle Trades abdecken",
              4: () =>
                "Klicken Sie auf die Schaltfläche „Herunterladen“, die als nach unten zeigender Pfeil angezeigt wird",
              5: () => "Laden Sie CSV zum Krypto-Steuerrechner hoch",
            },
          },
        },
      },
    },
    gateio: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Aufgrund von Rundungsproblemen in den CSV-Dateien selbst kann es zu geringfügigen Abweichungen beim Saldo kommen.",
            },
            title: () => "CSV-Formatierung",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Gate.io-Konto an und gehen Sie zu https://www.gate.io/myaccount/mypurselog. Sie können diese Seite auch über das Seitenleistenmenü > Transaktionsverlauf aufrufen.",
              1: () => "Wählen Sie „Alle“ für Münze und „Alle“ für Filter.",
              2: () => "Klicken Sie auf „Filter“ > Herunterladen“",
              3: () =>
                "Sie können auch oben rechts auf „Stapeldaten anfordern“ klicken.",
              4: () =>
                "Wählen Sie bei Coin „Alle“ aus und senden Sie die Bewerbung ab.",
              5: () =>
                "Sie können den Transaktionsverlauf jedes Mal für 90 Tage herunterladen. Wenn Sie mehr haben, wiederholen Sie die Schritte für unterschiedliche Zeiträume.",
              6: () =>
                "Importieren Sie die exportierte Datei „mypurselog.csv“ in CTC.",
            },
          },
        },
      },
    },
    gemini: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Crypto Tax Calculator sollte der einzige Dienst sein, der den von Ihnen angegebenen API-Schlüssel verwendet. Wenn Sie einen API-Schlüssel verwenden, der mit einem anderen Dienst geteilt wird, treten höchstwahrscheinlich Importprobleme auf.",
              1: () =>
                "Bitte beachten Sie, dass Transaktionen mit Beträgen unter 10^-12 (jeder Währung) nicht importiert werden.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Gemini an und navigieren Sie zu Konto > Einstellungen (oben rechts auf dem Bildschirm).",
              1: () =>
                "Scrollen Sie nach unten zur Registerkarte „API“ auf der linken Seite",
              2: () =>
                "Klicken Sie auf „Neuen API-Schlüssel erstellen“ (verwenden Sie keinen vorhandenen Schlüssel).",
              3: () =>
                "Wählen Sie den Bereich „Primär“ und klicken Sie auf „API-Schlüssel erstellen“.",
              4: () =>
                "Aktivieren Sie das Kontrollkästchen „Auditor“ unter den API-Schlüsseleinstellungen. Nehmen Sie in den API-Einstellungen NICHTS anderes vor.",
              5: () => "Kopieren Sie den API-Schlüssel und das Geheimnis",
              6: () =>
                "Aktivieren Sie das Kontrollkästchen „Ja“ für „Kopieren und Einfügen“.",
              7: () => "Klicken Sie auf „Bestätigen“.",
              8: () =>
                "Fügen Sie den kopierten API-Schlüssel und das Geheimnis zum Crypto Tax Calculator hinzu",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie auf „Mit Gemini verbinden“, um zur Gemini-Börse weitergeleitet zu werden",
              1: () => "Melden Sie sich bei Ihrem Gemini-Konto an.",
              2: () => "Geben Sie bei Bedarf den 2FA-Code ein",
              3: () =>
                "Wählen Sie das Konto aus, das Sie verbinden möchten, und klicken Sie dann auf „Weiter“.",
              4: () => "Klicken Sie auf die Schaltfläche „Zulassen“",
            },
          },
        },
      },
    },
    "giddy-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Öffnen Sie Ihre mobile Giddy Wallet-App und navigieren Sie zum Abschnitt „Wallet“ (unten links).",
              1: () => "Klicken Sie oben links auf das QR-Symbol.",
              2: () =>
                "Kopieren Sie die öffentliche Wallet-Adresse, die mit „0x…“ beginnt.",
              3: () =>
                "Fügen Sie die Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    graviex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Navigieren Sie zur Verlaufsseite Ihres Kontos",
              1: () =>
                "Klicken Sie auf Handelsverlauf und exportieren Sie die Daten",
              2: () => "Zum Krypto-Steuerrechner hochladen",
            },
          },
        },
      },
    },
    grs: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
              1: () =>
                "Wir unterstützen auch xpub, ypub und zpub Adressen, allerdings wird xpub bevorzugt",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Groestlcoin-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    hedera: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Geben Sie Ihre Hedera-Wallet-Adresse ein",
              1: () =>
                "Fügen Sie optional einen Spitznamen hinzu und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    hitbtc: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen derzeit keine Margin-Zinsgebühren oder Zahlungen für Futures-Finanzierungen für die API-Integration, da HitBTC keinen API-Endpunkt zum Abrufen dieser Informationen bietet. Wir arbeiten mit HitBTC an einer Lösung dieses Problems.",
              1: () =>
                "HitBTC gibt keine Spot-Handelshistorie für dekotierte Münzen wie VERI zurück. Dies kann dazu führen, dass Salden nicht übereinstimmen, wenn Sie historische Trades für diese Paare hatten.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem HitBTC-Konto an und navigieren Sie zur Seite „HitBTC-API-Schlüssel“ – unter der Registerkarte „Konten“ (https://hitbtc.com/settings/api-keys).",
              1: () =>
                "Klicken Sie auf die Schaltfläche „Neuer API-Schlüssel“ und geben Sie Ihren Zwei-Faktor-Authentifizierungscode ein",
              2: () =>
                "Aktivieren Sie beide Kontrollkästchen: „Auftragsbuch, Verlauf, Spot-Guthaben“ und „Zahlungsinformationen“.",
              3: () =>
                "Kopieren Sie den API-Schlüssel und das Geheimnis und geben Sie sie in den Crypto Tax Calculator ein",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützen wir keine Futures-Finanzierungszahlungen für die CSV-Integration. Wir arbeiten an einer Lösung.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem HitBTC-Konto an und navigieren Sie zur HitBTC-Berichtsseite (https://hitbtc.com/reports/trades).",
              1: () =>
                "Für Wallet-Transaktionen: Wählen Sie „Transaktionsverlauf“ unter „Wallet“",
              2: () =>
                "Für Spot-Trades: Wählen Sie „Meine Trades“ unter „Spot“",
              3: () =>
                "Für Margin-Trades: Wählen Sie „Meine Trades“ und „Interessen“ unter „Margin“",
              4: () =>
                "Klicken Sie auf Als CSV exportieren und laden Sie die CSV-Datei herunter",
              5: () =>
                "Laden Sie Ihre CSV-Datei(en) hier zum Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    hodlnaut: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Ausführlichere Schritte mit Screenshots finden Sie auf der Support-Seite von Hodlnaut unter: https://www.hodlnaut.com/crypto-guides/hodlnaut-revamps-csv-format",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sobald Sie sich bei Hodlnaut angemeldet haben, navigieren Sie zu „Mein Konto“ in der oberen Menüleiste.",
              1: () =>
                "Scrollen Sie nach unten zu „Transaktionen“ und klicken Sie oben rechts auf die Schaltfläche „Exportieren“.",
              2: () => "Laden Sie den CSV-Bericht auf Ihr Gerät herunter",
              3: () =>
                "Laden Sie die CSV-Datei mit der Schaltfläche „Importieren“ in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    honeyswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir kategorisieren derzeit Swaps und das Hinzufügen/Entfernen von Liquidität auf Honeyswap automatisch",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Finden Sie die Gnosis Chain Wallet-Adresse, die Sie für den Handel über Honeyswap verwendet haben",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    hotbit: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Da die Option zum Herunterladen und Exportieren des Verlaufs von Hotbit nur den Export von 30 Handelstagen auf einmal ermöglicht, müssen Sie sich an den Support wenden und eine vollständige Aufzeichnung Ihres Handelsverlaufs anfordern.",
              1: () =>
                "Der Hotbit-Support sollte Ihnen eine XLSX-Datei mit den folgenden Headern senden: „time“, „user_id“, „market“, „side“, „role“, „price“, „amount“, „deal“, „fee“, „platform“, „stock“, „deal_stock“. Bestätigen Sie, dass die empfangene Datei die oben genannten Header enthält. Ändern oder speichern Sie die Datei dabei nicht erneut.",
              2: () =>
                "Laden Sie die XLSX-Datei hier zum Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    huobi: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Bei Huobi anmelden",
              1: () => "Navigieren Sie zur API-Verwaltungsseite",
              2: () => "Geben Sie eine Notiz Ihrer Wahl ein",
              3: () => "Klicken Sie auf die Schaltfläche „Erstellen“",
              4: () =>
                "Kopieren Sie sowohl den Zugriffsschlüssel als auch den privaten Schlüssel",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Sie müssen den Support kontaktieren und ihn bitten, Ihnen die CSV-Dateien „Transaktionsverlauf“, „Auszahlungsverlauf“ und „Einzahlungsverlauf“ per E-Mail zuzusenden.",
              1: () =>
                "Es kann bis zu 5 Tage dauern, bis diese von Huobi versendet werden",
              2: () =>
                "Sobald Sie diese Dateien erhalten haben, können Sie sie hier in den Crypto Tax Calculator hochladen.",
            },
          },
        },
      },
    },
    hyperliquid: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit können wir Transaktionen im Zusammenhang mit Empfehlungsprämien nicht automatisch importieren. Dies kann zu geringfügigen Abweichungen im Kontostand führen. Um sicherzustellen, dass Ihre Aufzeichnungen korrekt sind, empfehlen wir, diese Transaktionen ggf. manuell zu importieren. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () => "Empfehlungsprämien",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Geben Sie Ihre Hyper-Wallet-Adresse ein",
              1: () =>
                "Fügen Sie optional einen Spitznamen hinzu und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    ice3x: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Bei ice3x anmelden",
              1: () =>
                "Navigieren Sie zu Konto > Verlauf > Bestellungen https://ice3x.com/account/history",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Nach Excel exportieren“",
              3: () => "Zum Krypto-Steuerrechner hochladen",
            },
          },
        },
      },
    },
    idex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei IDEX an: http://idex.market",
              1: () => "Navigieren Sie zu Profil > Wallet-Adresse",
              2: () => "Kopieren Sie Ihre IDEX Wallet-Adresse",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Bei IDEX anmelden",
              1: () =>
                "Navigieren Sie zu Bestellungen > Handelsverlauf https://idex.market/trades",
              2: () => "Klicken Sie auf „Handelsverlauf herunterladen“.",
              3: () => "Laden Sie CSV zum Krypto-Steuerrechner hoch",
            },
          },
        },
      },
    },
    idt: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    immutable: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Immutable X-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
              1: () =>
                "Bitte beachten Sie: Aufgrund unzuverlässiger Daten von Immutable X kann es vorkommen, dass bei einigen Wallets Transaktionen nicht importiert werden können.",
              2: () =>
                "Bis wir eine zuverlässige Datenquelle sicherstellen können, müssen Sie diese Daten manuell mit unserer benutzerdefinierten CSV-Methode importieren.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    independentreserve: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Independent Reserve-Konto an",
              1: () =>
                "Navigieren Sie in der oberen Navigationsleiste zum Abschnitt „Einstellungen“ (https://app.independentreserve.com/settings).",
              2: () => "Klicken Sie auf die Registerkarte „API-Schlüssel“",
              3: () =>
                "Klicken Sie auf die Schaltfläche „Generieren“, um einen neuen Schlüssel zu erstellen",
              4: () =>
                "Wählen Sie die Option „Nur lesen (empfohlen)“ und fügen Sie die API-Schlüsselbeschreibung als „Crypto Tax Calculator“ hinzu.",
              5: () =>
                "Geben Sie Ihr Passwort ein und klicken Sie auf die Schaltfläche „Speichern“",
              6: () =>
                "Kopieren Sie den „API-Schlüssel“ und das „API-Geheimnis“ hier in den Crypto Tax Calculator",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/4-HxdE3BdmU",
            title: () => "So importieren Sie Ihre Independent Reserve API",
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Independent Reserve-Konto an",
              1: () =>
                "Navigieren Sie in der oberen Navigationsleiste zum Dropdown-Menü „STEUERN UND BERICHTE“ und wählen Sie „Berichte“ aus.",
            },
          },
          1: {
            items: {
              0: () =>
                "Klicken Sie unter „Berichte“ auf die Schaltfläche „Bestellverlaufsbericht“",
              1: () =>
                "Wählen Sie im Popup den Datumsbereich aus, der Ihren gesamten Bestellverlauf für das Konto abdeckt",
              2: () =>
                "Wählen Sie die Option „Bestellverlauf als CSV-Datei herunterladen“ und klicken Sie dann auf „Herunterladen“, um Ihren Bestellverlauf als CSV-Datei herunterzuladen.",
              3: () =>
                "Laden Sie diese CSV-Datei „OrderStatement“ hier in den Crypto Tax Calculator hoch",
            },
            title: () => "Für den Bestellverlauf:",
          },
          2: {
            items: {
              0: () =>
                "Klicken Sie unter „Berichte“ auf die Schaltfläche „Bericht zum Transaktionsverlauf“.",
              1: () =>
                "Wählen Sie im Popup den Datumsbereich aus, der Ihren gesamten Bestellverlauf für das Konto abdeckt",
              2: () =>
                "Wählen Sie die Option „Transaktionsverlauf als CSV-Datei herunterladen“ und klicken Sie dann auf „Herunterladen“, um Ihren Transaktionsverlauf als CSV-Datei herunterzuladen.",
              3: () =>
                "Laden Sie diese CSV-Datei „TransactionHistory“ hier in den Crypto Tax Calculator hoch",
            },
            title: () => "Für Einzahlungen/Auszahlungen:",
          },
        },
        video: {
          0: {
            items: {
              0: () =>
                "Wir werden Ihre „Trades“ nicht aus der CSV-Datei „TransactionHistory“ abrufen, sondern aus der CSV-Datei „OrderStatement“, da diese genauer ist.",
              1: () =>
                "Derzeit unterstützen wir nur die folgenden Datums-/Uhrzeitformate:",
              2: () => "„TT/MM/JJJJ HH:mm“ (z. B. 12/02/2020 13:09)",
              3: () =>
                "„TT MMM JJJJ HH:mm:ss“ (z. B. 12. Februar 2020 13:09:22)",
            },
            link: () => "https://youtu.be/Qaz6XSJ-jck",
            title: () =>
              "So importieren Sie Ihre Independent Reserve-CSV-Dateien",
          },
        },
      },
    },
    injective: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Injective-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    iotex: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "IoTeX befindet sich in der frühen Entwicklung",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre IoTeX-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    "jaxx-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Bei Jaxx Liberty anmelden",
              1: () => "Wählen Sie das Drei-Zeilen-Menü (oben rechts)",
            },
          },
          1: {
            items: {
              0: () => "Wählen Sie „Backup-Phrase anzeigen“",
              1: () =>
                "Aktivieren Sie das Kontrollkästchen Es ist sicher und klicken Sie auf Backup-Phrase anzeigen",
              2: () => "Navigieren Sie zu https://iancoleman.io/bip39",
              3: () =>
                "Fügen Sie die Jaxx-Backup-Phrase in das BIP39-Mnemonikfeld ein",
              4: () => "Münze auswählen",
              5: () => "Wählen Sie die Registerkarte BIP44",
              6: () =>
                "Geben Sie „0“ in Extern/Intern ein, um xPub für Empfangsadressen zu erhalten",
              7: () =>
                "Kopieren Sie den erweiterten öffentlichen BIP32-Schlüssel",
              8: () =>
                "Geben Sie „1“ in Extern/Intern ein, um xPub für Änderungsadressen zu erhalten",
              9: () => "Erweiterten öffentlichen BIP32-Schlüssel kopieren",
              10: () =>
                "Fügen Sie die xPub-Adresse in CTC hinzu und klicken Sie auf „Synchronisieren“",
            },
            title: () =>
              "Für Bitcoin-, Bitcoin Cash-, Dash-, Dogecoin-, Litecoin- und Zcash-Wallets",
          },
          2: {
            items: {
              0: () => "Wählen Sie „Private Schlüssel anzeigen“",
              1: () =>
                "Aktivieren Sie das Kontrollkästchen „Es ist sicher“ und klicken Sie auf „Private Schlüssel anzeigen“",
              2: () =>
                "Wählen Sie die entsprechende Münzbörse aus (kann für mehrere Geldbörsen wiederholt werden)",
              3: () =>
                "Öffentliche Adresse(n) kopieren. Private Schlüssel nicht kopieren.",
              4: () =>
                "Finden Sie die relevanten Adressen für jede Transaktion",
              5: () => "Kopieren Sie jede Adresse",
              6: () => "In CTC einfügen und auf Synchronisieren klicken",
            },
            title: () => "Für alle anderen Münzen",
          },
        },
      },
    },
    juno: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Juno in der frühen Entwicklung",
              1: () =>
                "Nur Transfer-/Staking-Labels (andere erscheinen als unbekannt)",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Juno-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    kaspa: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Enter your Kaspa wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    kava: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Wir haben derzeit einige Probleme beim Sammeln von ERC-1155-Token und Nicht-EVM-Transaktionen und arbeiten an einer Lösung. Wenn dieses Problem in der Zwischenzeit auftritt, versuchen Sie stattdessen unseren manuellen CSV-Upload-Prozess. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155-Token und Nicht-EVM-Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Kava EVM-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    kraken: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Die API von Kraken hat eine strikte Ratenbegrenzung von etwa 50 Transaktionen alle 6 Sekunden, was die Synchronisierung einer großen Anzahl von Transaktionen verlangsamen kann. Weitere Einzelheiten finden Sie auf der [API-Ratenbegrenzungsseite](https://www.kraken.com/features/api#api-call-rate-limit).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Kraken an und gehen Sie zu Einstellungen > Sicherheit > API. (https://pro.kraken.com/app/settings/api)",
              1: () => "Klicken Sie auf „API-Schlüssel erstellen“",
              2: () =>
                "Benennen Sie Ihren API-Schlüssel und wählen Sie die Berechtigungen: „Abfrage“, „Abfrage geschlossener Aufträge und Trades“ und „Abfrage von Ledgereinträgen“",
              3: () =>
                "Lassen Sie alle anderen Berechtigungen und Einstellungen unverändert und klicken Sie auf „Schlüssel generieren“.",
              4: () =>
                "Kopieren Sie den „API-Schlüssel“ und den „privaten Schlüssel“ als „API-Schlüssel“ und „Geheimnis“ in den Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/2WAYzAyPOKQ",
            title: () => "So importieren Sie Ihre Kraken-API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützen wir für Kraken CSV nur die folgenden Datumsformate: MM/TT/JJJJ, JJJJ-MM-TT, TT/MM/JJ HH:mm, JJJJ-MM-TT HH:mm:ss.SSS, JJJJ-MM-TT HH:mm:ss und MM/TT/JJJJ HH:mm",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Kraken-Konto an, gehen Sie zur Registerkarte „Verlauf“ und klicken Sie dann auf „Exportieren“.",
              1: () =>
                "Wählen Sie „Hauptbuch“ als Exporttyp, wählen Sie Ihren Handelszeitraum, aktivieren Sie „Alles auswählen“ und klicken Sie auf „Senden“.",
              2: () =>
                "Warten Sie einige Sekunden, aktualisieren Sie bei Bedarf und laden Sie die CSV-Datei in einer ZIP-Datei herunter.",
              3: () =>
                "Entpacken Sie die Datei und laden Sie die CSV(s) in den Crypto Tax Calculator hoch.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/X7v6RyNCe7A",
            title: () => "So importieren Sie Ihre Kraken CSVs",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie auf „Mit Kraken verbinden“, um zur Kraken-Börse zu gelangen",
              1: () => "Melden Sie sich bei Ihrem Kraken-Konto an.",
              2: () =>
                "Wenn Ihr Konto einen Passkey oder 2FA verwendet, geben Sie den erforderlichen Code ein. Andernfalls überspringen Sie diesen Schritt.",
              3: () => "Klicken Sie auf die Schaltfläche „Zulassen“",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/Hrn695jsmoA",
            title: () => "So importieren Sie Ihr Kraken-Konto über Oauth",
          },
        },
      },
    },
    kucoin: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Die API von KuCoin stellt nur den Transaktionsverlauf des letzten Jahres bereit. Verwenden Sie für ältere Daten eine CSV-Datei.",
              1: () =>
                "Wir unterstützen derzeit Einzahlungen, Abhebungen, Spot- und Margin-Handel sowie historische Futures-Daten für KuCoin. Es ist zu beachten, dass Salden möglicherweise nicht korrekt sind, da wir die Margin-Ausleih-/Verleihhistorie nicht erhalten.",
              2: () =>
                "Kucoin hat kürzlich seinen Endpunkt für historische Transaktionen deaktiviert. Sie müssen diese Daten in der Zwischenzeit mithilfe der CTC-CSV-Importoption separat in den Crypto Tax Calculator eingeben, bis dieses Problem behoben ist (https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei KuCoin an und gehen Sie zu API-Verwaltung > API-Schlüssel (https://www.kucoin.com/account/api).",
              1: () =>
                "Klicken Sie auf „API erstellen“ und wählen Sie „API-basierter Handel“.",
              2: () =>
                "Geben Sie einen Namen und eine Passphrase Ihrer Wahl ein.",
              3: () =>
                "Aktivieren Sie nur die allgemeine Berechtigung; deaktivieren Sie die anderen.",
              4: () =>
                "Setzen Sie die IP-Beschränkung auf „Nein“, klicken Sie auf „Weiter“ und schließen Sie die Sicherheitsüberprüfung ab",
              5: () =>
                "Kopieren Sie die Passphrase, den API-Schlüssel und das API-Geheimnis und fügen Sie sie in den Crypto Tax Calculator ein",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/PtORZYAm-gc",
            title: () => "So importieren Sie Ihre Kucoin-API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () => "Melden Sie sich bei KuCoin an",
              1: () =>
                "Navigieren Sie zu Bestellungen > Spot-Handelsverlauf > Handelsverlauf",
              2: () =>
                "Klicken Sie auf „Klicken Sie hier, um Transaktionen der älteren Version anzuzeigen“.",
              3: () => "Klicken Sie auf „Nach CSV exportieren“.",
              4: () =>
                "KuCoin erlaubt nur den Export von jeweils 100 Tagen. Wählen Sie daher 100-Tage-Intervalle aus Ihrem gesamten Handelsverlauf aus.",
              5: () =>
                "Sobald die CSV-Datei heruntergeladen ist, navigieren Sie zu https://www.kucoin.com/account/download",
              6: () => "Klicken Sie auf das Download-Symbol",
            },
            title: () => "Anleitung zum CSV-Import der Version 1",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem KuCoin-Konto an und gehen Sie zum Bestellcenter. (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1)",
              1: () => "Klicken Sie auf „Exportieren“.",
              2: () =>
                "Wählen Sie den gewünschten Bericht aus: Einzahlungs-/Auszahlungsverlauf, Spot-Orders, Margin-Orders oder Futures-Orders.",
              3: () =>
                "Wählen Sie den Datumsbereich (bis zu 12 Monate pro Export).",
              4: () =>
                "Aktivieren Sie bei Bedarf „Beinhaltet Unterkonten“ (nur für Spot, Margin und Futures).",
              5: () =>
                "Wählen Sie „.csv“ und klicken Sie zum Herunterladen auf „Bestätigen“.",
              6: () =>
                "Laden Sie die relevanten Dateien zu CTC hoch: Einzahlungsverlauf, Auszahlungsaufzeichnung, Spot-Orders – Ausgeführte Orders, Margin-Orders – Ausgeführte Orders – Kreuz und isoliert, Margin-Orders – Kreditaufnahmen – Kreuz und isoliert und realisierter PnL.",
            },
          },
          1: {
            items: {
              0: () =>
                "Befolgen Sie die Schritte im KuCoin-Hilfecenter (https://www.kucoin.com/support/14047696578841).",
              1: () =>
                "Melden Sie sich bei Ihrem Kucoin-Konto an und besuchen Sie das Bestellcenter (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1).",
              2: () => "Klicken Sie auf die Schaltfläche „Exportieren“",
              3: () =>
                "Klicken Sie auf das Markt-Dropdown-Menü und wählen Sie Einzahlungs-/Auszahlungsverlauf, Spot-Orders, Margin-Orders, Futures-Orders",
              4: () =>
                "Wählen Sie den Datumsbereich, den Sie herunterladen möchten (nur 12 Monate pro Export)",
              5: () =>
                "Wenn Sie Daten aus Unterkonten einschließen müssen, aktivieren Sie die Option „Schließt Unterkonten ein“. (Für Unterkonten werden nur Spot-, Margin- und Futures-Berichte unterstützt.)",
              6: () =>
                "Wählen Sie „.csv“ und klicken Sie dann auf „Bestätigen“, um die Dateien herunterzuladen.",
              7: () =>
                "Laden Sie die erforderlichen Dateien zu CTC hoch. Nicht alle heruntergeladenen Dateien sind für CTC nützlich. Zu den relevanten Dateien gehören: Einzahlungsverlauf, Auszahlungsprotokoll, Spot-Orders – ausgeführte Orders, Margin-Orders – ausgeführte Orders – Cross & Isolated, Margin-Orders – Borrowings – Cross & Isolated und Realisierter PnL",
            },
            title: () => "Handelsgeschichte",
          },
          2: {
            title: () => "Einzahlungs- und Auszahlungsverlauf",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/81gyRIU1HYM",
            title: () => "So importieren Sie Ihre Kucoin CSV",
          },
        },
      },
    },
    kujira: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Kujira in der frühen Entwicklung",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Kujira-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    kyber: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () => "Wählen Sie die Börse aus dem Dropdown-Menü",
              3: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              4: () => "Behalten Sie die anderen Standardeinstellungen bei",
              5: () => "Klicken Sie auf „Verlauf abrufen“.",
              6: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              7: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    kyve_network: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Kyve-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    latoken: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Sie müssen sich an den LATOKEN-Support wenden, um den Transaktionsverlaufsbericht anzufordern",
              1: () =>
                "Sobald Sie diese erhalten haben, können Sie sie direkt in die Plattform importieren",
            },
          },
        },
      },
    },
    leather_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie auf „Wallet verbinden“ und folgen Sie den Anweisungen.",
            },
          },
        },
      },
    },
    ledger_blue: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Verbinden Sie Ihr Ledger mit Ihrem Gerät",
              1: () => "Melden Sie sich bei der Ledger Live-Desktop-App an",
              2: () => "Öffnen Sie das Ledger-Wallet für die Münze Ihrer Wahl",
              3: () => "Wählen Sie das entsprechende Konto aus (linke Seite)",
            },
          },
          1: {
            items: {
              0: () =>
                "Klicken Sie auf das Schraubenschlüssel-Symbol (oben rechts)",
              1: () => "Klicken Sie auf Erweiterte Protokolle",
              2: () =>
                "Kopieren Sie die HD-Wallet-Adresse mit dem Präfix „xpub“ zwischen den Anführungszeichen",
              3: () => "In CTC einfügen",
            },
            title: () =>
              "Für Bitcoin-, Bitcoin Cash-, Dash-, Dogecoin-, Litecoin- und Zcash-Wallets",
          },
          2: {
            items: {
              0: () =>
                "Zeigen Sie die Liste der Transaktionen und Adressen im Wallet an",
              1: () => "Kopieren Sie jede Adresse",
              2: () => "In CTC einfügen",
            },
            title: () => "Für alle anderen Münzen",
          },
        },
      },
    },
    ledgerwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Beim CSV-Export von Ledger Live fehlen möglicherweise Transaktionen, was dazu führen kann, dass die vom Crypto Tax Calculator berechneten Salden falsch sind. Wenn möglich, empfehlen wir Ihnen, jedes der zugrunde liegenden Blockchain-Wallets direkt mit der Option „Über API synchronisieren“ zu synchronisieren.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Klicken Sie im linken Bereich auf „Konten“.",
              1: () =>
                "Klicken Sie oben rechts auf die Schaltfläche ⋯ Optionen.",
              2: () => "Wählen Sie die Option „Vorgangsverlauf exportieren“.",
              3: () =>
                "Wählen Sie im angezeigten Fenster die einzuschließenden Konten aus.",
              4: () => "Klicken Sie auf Speichern.",
              5: () => "Speichern Sie die Datei in einem Ordner Ihrer Wahl.",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei der Ledger Live-Desktop-App an",
              1: () => "Öffnen Sie das Ledger-Wallet für die Münze Ihrer Wahl",
              2: () => "Wählen Sie das entsprechende Konto aus (linke Seite)",
            },
          },
          1: {
            items: {
              0: () =>
                "Klicken Sie auf das Schraubenschlüssel-Symbol (oben rechts)",
              1: () => "Klicken Sie auf Erweiterte Protokolle",
              2: () =>
                "Kopieren Sie die HD-Wallet-Adresse mit dem Präfix „xpub“ zwischen den Anführungszeichen",
              3: () => "In CTC einfügen",
            },
            title: () =>
              "Für Bitcoin-, Bitcoin Cash-, Dash-, Dogecoin-, Litecoin- und Zcash-Wallets",
          },
          2: {
            items: {
              0: () =>
                "Zeigen Sie die Liste der Transaktionen und Adressen im Wallet an",
              1: () => "Kopieren Sie jede Adresse",
              2: () => "In CTC einfügen",
            },
            title: () => "Für alle anderen Münzen",
          },
        },
      },
    },
    ledn: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Stellen Sie sicher, dass Ihr Bericht alle Jahre umfasst, in denen Sie mit Kryptowährung gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich in einem Webbrowser bei Ihrem Ledn-Konto an.",
              1: () =>
                "Navigieren Sie über das Navigationsmenü auf der linken Seite zur Seite „Handel“",
              2: () =>
                "Klicken Sie auf „CSV herunterladen“, um Ihre Handelsdaten herunterzuladen",
              3: () =>
                "Öffnen oder speichern Sie die Datei nicht vor dem Import, da dies das Format der CSV-Datei ändern könnte",
              4: () =>
                "Laden Sie die gerade heruntergeladene Datei hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    linea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Linea-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    liqui: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zum Bereich „Guthaben/Wallet“ in der oberen rechten Ecke.",
              1: () =>
                "Wählen Sie den Datumsbereich aus und exportieren Sie per E-Mail (möglicherweise müssen Sie dies für alle Ihre Transaktionen mehrmals tun)",
              2: () =>
                "Sobald die E-Mail eintrifft, laden Sie die erhaltene Datei in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    liquid: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen Spot-, Margin-, Infinity-, Perpetuals- und Kryptowährungsabhebungen.",
              1: () =>
                "Die Liquid API stellt keine Informationen zu Kryptowährungseinzahlungen bereit. Sie müssen diese manuell hinzufügen, wenn Sie sie verfolgen möchten.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Liquid an und öffnen Sie das Liquid-Menü, indem Sie oben rechts auf Ihr Benutzersymbol klicken.",
              1: () =>
                "Navigieren Sie zu API-Tokens > API-Schlüssel https://app.liquid.com/settings/api-tokens",
              2: () => "Klicken Sie auf die Schaltfläche „API-Token erstellen“",
              3: () =>
                "Wählen Sie die folgende Nur-Lese-Berechtigung: „Konten“, „Ausführungen“, „Aufträge“, „Positionen“, „Handelskonten“ und „Abhebungen“.",
              4: () =>
                "Obwohl es in den Anweisungen so aussieht, als ob die IP-Adresse erforderlich wäre, kann der API-Schlüssel auch ohne eine solche generiert werden. Lassen Sie dieses Feld leer.",
              5: () => "Klicken Sie auf die Schaltfläche „Token erstellen“",
              6: () =>
                "Kopieren Sie die Token-ID (API-Schlüssel) und das Token-Geheimnis (API-Geheimnis) und geben Sie sie hier in den Crypto Tax Calculator ein",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Bei Liquid anmelden",
              1: () =>
                "Navigieren Sie zu Geldbörsen > Transaktionen > Scrollen Sie nach unten zum Übersichtsbericht",
              2: () =>
                "Stellen Sie sicher, dass bei „Zeitzone auswählen“ „UTC+00:00“ eingestellt ist und dass die ausgewählten Daten alle Trades umfassen.",
              3: () =>
                "Klicken Sie auf die Schaltfläche „Bericht per E-Mail senden“",
              4: () =>
                "Sobald Sie die E-Mail von Liquid erhalten haben, laden Sie die CSV-Datei herunter und laden Sie sie hier zum Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    localbitcoins: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem LocalBitcoins-Konto an",
              1: () =>
                "Klicken Sie oben rechts in der Navigationsleiste auf das Profilsymbol und dann im Dropdown-Menü auf „Einstellungen“, um zu Ihren Einstellungen zu navigieren (https://localbitcoins.com/settings).",
              2: () =>
                "Klicken Sie auf die Registerkarte „Sicherheit“ und dann unter „Persönliche Daten“ auf „Anzeigen“.",
              3: () =>
                "Klicken Sie auf die blaue Schaltfläche „Persönliche Daten erfassen“, um mit der Generierung Ihrer vollständigen Handelshistorie zu beginnen",
              4: () =>
                "Sobald der Download abgeschlossen ist, klicken Sie auf die blaue Schaltfläche „Persönliche Daten herunterladen“, um Ihre Daten in einer ZIP-Datei herunterzuladen.",
              5: () =>
                "Extrahieren Sie den Inhalt der ZIP-Datei und isolieren Sie die beiden Dateien „contacts.csv“ und „transaction_history.csv“.",
              6: () =>
                "Importieren Sie abschließend diese beiden Dateien hier in den Crypto Tax Calculator",
            },
          },
        },
      },
    },
    lros: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Living Room of Satoshi (LROS)-Konto an",
              1: () =>
                "Klicken Sie in der oberen Navigationsleiste auf „Mein Konto“, um zur Rechnungsseite zu navigieren (https://www.livingroomofsatoshi.com/bills).",
              2: () =>
                "Laden Sie die CSV-Datei mit Ihrem gesamten Zahlungsverlauf herunter",
              3: () =>
                "Laden Sie diese CSV hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    ltc: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie den öffentlichen Schlüssel des Ltub-Wallets für Ihr Litecoin-Wallet. Um diesen zu finden, gehen Sie (zum Beispiel) zu Ledger Live und geben Sie Ihr Litecoin-Konto ein. Klicken Sie auf das Schraubenschlüsselsymbol. Erweitern Sie „Erweiterte Protokolle“ unter „Konto bearbeiten“. Kopieren Sie die Adresse, die mit „Ltub“ beginnt.",
              1: () =>
                "Sobald Sie ihn gefunden haben, kopieren Sie diesen öffentlichen Schlüssel und gehen Sie zu einem Xpub-Konverter wie https://jlopp.github.io/xpub-converter/, um Ihre „Ltub“-Adresse in eine „Xpub“-Adresse umzuwandeln.",
              2: () =>
                "Kopieren Sie die konvertierte „Xpub“-Adresse, fügen Sie sie in das Importfeld des Crypto Tax Calculator (links) ein und klicken Sie auf „Wallet hinzufügen“, um Ihre Transaktionen zu synchronisieren.",
            },
          },
        },
      },
    },
    luno: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Luno-Konto an (https://www.luno.com/en/exchange)",
              1: () =>
                "Klicken Sie oben rechts auf Profil und navigieren Sie zu Sicherheit - > API-Schlüssel",
              2: () =>
                "Erstellen Sie den API-Schlüssel und stellen Sie sicher, dass die Schlüsselberechtigung „Nur-Lese-Zugriff“ lautet.",
              3: () =>
                "Stellen Sie sicher, dass Sie den API-Schlüssel autorisieren, indem Sie dem Link folgen, der Ihnen per SMS oder E-Mail gesendet wurde, bevor Sie ihn verwenden",
              4: () =>
                "Geben Sie hier sowohl den „API-Schlüssel“ als auch das „API-Geheimnis“ in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Luno-Konto an (https://www.luno.com/en/exchange)",
              1: () =>
                "Laden Sie den CSV-Export Ihrer Transaktionen herunter, indem Sie zu Ihren Wallets - > BTC Wallet (zum Beispiel) - > Transaktionen - > CSV herunterladen gehen.",
              2: () =>
                "Wiederholen Sie dies für jede Ihrer Geldbörsen auf Luno",
              3: () =>
                "Sie können jetzt die CSV-Dateien in den Crypto Tax Calculator hochladen",
            },
          },
        },
      },
    },
    lykke: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Lykke-Konto an und klicken Sie in der Navigationsleiste oben auf dem Bildschirm auf „GELD“.",
              1: () =>
                "Klicken Sie auf der Seite „Fonds“ auf die Schaltfläche „Verlauf“",
              2: () =>
                "Stellen Sie sicher, dass Sie „Alle“ ausgewählt haben, damit Sie Einzahlungen, Abhebungen und Handel sehen können.",
              3: () =>
                "Klicken Sie auf die Schaltfläche „IN CSV EXPORTIEREN“, um Ihre CSV-Datei herunterzuladen.",
              4: () => "Laden Sie die CSV hier zum Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    "manta-pacific": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
          1: {
            items: {
              0: () =>
                "Bei einigen Geldbörsen können interne Transaktionen fehlen.",
            },
            title: () => "Fehlende interne Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Manta Pacific-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    mantle: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Mantle-Wallet-Adresse (beginnend mit 0x) in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    mercatox: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Bei Mercatox anmelden",
              1: () =>
                "Navigieren Sie zu Wallet > Transaktionsverlauf https://mercatox.com/wallet/transactions",
              2: () =>
                "Klicken Sie auf die Schaltfläche „CSV herunterladen“, bevor Sie es in den Crypto Tax Calculator hochladen.",
            },
          },
        },
      },
    },
    metamask: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie auf „Wallet verbinden“ und folgen Sie den Anweisungen.",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr MetaMask-Wallet",
              1: () =>
                "Wählen Sie für jedes mit MetaMask verknüpfte Konto das Konto aus und bewegen Sie den Mauszeiger über den Kontonamen",
              2: () =>
                "Kopieren Sie die öffentliche Wallet-Adresse, die mit „0x…“ beginnt.",
              3: () =>
                "Fügen Sie die kopierte öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    metis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Metis-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    mode: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Kopieren Sie die öffentliche Mode-Wallet-Adresse, die mit „0x...“ beginnt.",
              1: () => "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein",
              2: () =>
                "Geben Sie einen Spitznamen ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Mode Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    moonbeam: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
          1: {
            items: {
              0: () =>
                "Derzeit können wir Ihre Moonbeam-Belohnungen nicht abrufen. Wir arbeiten aktiv an einer Lösung dafür. In der Zwischenzeit können Sie Ihre Belohnungen über unseren manuellen CSV-Importprozess hinzufügen.",
            },
            title: () => "Fehlende Belohnungen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Moonbeam-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    moonriver: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Moonriver-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
      },
    },
    mudrex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Öffnen Sie die Mudrex-App und melden Sie sich bei Ihrem Konto an.",
              1: () =>
                "Tippen Sie oben links auf Ihr Profilsymbol (es zeigt Ihre Initialen oder ein Emoji).",
              2: () =>
                "Scrollen Sie im Profilbereich nach unten und wählen Sie unter „Berichte und Kontoauszüge“ „Transaktionskontoauszug“ aus.",
              3: () => "Wählen Sie das Geschäftsjahr",
              4: () =>
                "Tippen Sie auf „Kontoauszug anfordern“. Sie erhalten dann eine E-Mail mit dem Kontoauszug.",
              5: () => "Laden Sie die Datei zum Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    multichain: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet.",
              1: () =>
                "Wählen Sie die Kryptowährung aus, die Sie importieren möchten. Zum Beispiel Bitcoin oder Ethereum.",
              2: () =>
                "Kopieren Sie die entsprechende öffentliche Wallet-Adresse für diesen Vermögenswert. Um diese zu finden, müssen Sie möglicherweise auf „Empfangen“ klicken.",
              3: () =>
                "Wählen Sie in CTC das entsprechende Blockchain-Netzwerk aus der Dropdown-Liste aus.",
              4: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    mxc: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "Die MEXC-API bietet derzeit nur Zugriff auf den Transaktionsverlauf der letzten 30 Tage. Um Ihren gesamten Transaktionsverlauf zu importieren, verwenden Sie die MEXC-Importmethode „Datei hochladen“",
            },
            title: () => "MEXC API-Einschränkungen.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Von MEXC generierte API-Schlüssel sind nur 90 Tage lang gültig. Nach Ablauf dieser Zeit muss ein neuer Schlüssel erstellt und hinzugefügt werden.",
              1: () =>
                "Die MEXC-API gewährt KEINEN Zugriff auf Einzahlungen/Abhebungen/Airdrops usw., diese müssen manuell hinzugefügt werden.",
              2: () =>
                "Wir unterstützen derzeit KEINEN Derivate- oder Margin-Handel über MEXC.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem MEXC-Konto an",
              1: () =>
                "Bewegen Sie den Mauszeiger oben rechts auf dem Bildschirm über das Kontobild und wählen Sie „API-Verwaltung“ aus der Dropdown-Liste (https://www.mexc.com/user/openapi).",
              2: () =>
                "Wählen Sie unter der Überschrift „Spot“ die Optionen „Konto: Kontodetails anzeigen“ und „Handel: Auftragsdetails anzeigen“.",
              3: () =>
                "Geben Sie in den Anmerkungen (Erforderlich) einen Spitznamen für den API-Schlüssel an, z. B. „Crypto Tax Calculator“.",
              4: () =>
                "Aktivieren Sie das Kontrollkästchen „Ich habe die Risikohinweise für API-Benutzer gelesen und stimme ihnen zu“ und klicken Sie auf die Schaltfläche „Erstellen“.",
              5: () =>
                "Kopieren Sie den „Zugriffsschlüssel“ und den „Geheimschlüssel“ und geben Sie beide in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem MEXC-Konto an. Abhängig von Ihrer Handelshistorie benötigen wir vier verschiedene Dateien.",
              1: () =>
                "Für Futures-Handelsdaten: Klicken Sie in der oberen Menüleiste auf „Orders“ und wählen Sie „Futures Orders“",
              2: () =>
                "Wählen Sie „Bestell- und Handelsverlauf“ und klicken Sie auf die Schaltfläche „Bestellverlauf exportieren“ (https://futures.mexc.com/orders/contract?type=historic).",
              3: () =>
                "Wählen Sie im Popup-Fenster den Zeitraum aus, den Sie herunterladen möchten, wählen Sie „EXCEL“ als Exportformat und klicken Sie auf die Schaltfläche „Exportieren“, um Ihre Transaktionsdatensätze zu exportieren.",
              4: () =>
                "Für Spot-Trading-Daten: Klicken Sie in der oberen Menüleiste auf „Orders“ und wählen Sie „Spot Orders“",
              5: () =>
                "Wählen Sie „Handelsverlauf“ und klicken Sie auf die Schaltfläche „Exportieren“ (https://www.mexc.com/orders/spot?tab=deal).",
              6: () =>
                "Wählen Sie im Popup-Fenster den Zeitraum aus, den Sie herunterladen möchten, und klicken Sie auf die Schaltfläche „Generieren“, um Ihre Transaktionsaufzeichnungen zu exportieren.",
              7: () =>
                "Für Daten zum Einzahlungsverlauf: Klicken Sie in der oberen Menüleiste auf „Wallets“ und wählen Sie „Finanzierungsverlauf“",
              8: () =>
                "Wählen Sie „Einzahlen“ und klicken Sie auf die Schaltfläche „Exportieren“ (https://www.mexc.com/assets/record).",
              9: () =>
                "Wählen Sie im Popup-Fenster den Zeitraum aus, den Sie herunterladen möchten, deaktivieren Sie „Datei verschlüsseln“ und klicken Sie auf die Schaltfläche „Generieren“, um Ihre Einzahlungsaufzeichnungen zu exportieren.",
              10: () =>
                "Für Daten zum Auszahlungsverlauf: Klicken Sie in der oberen Menüleiste auf „Wallets“ und wählen Sie „Finanzierungsverlauf“.",
              11: () =>
                "Wählen Sie „Abhebung“ und klicken Sie auf die Schaltfläche „Exportieren“ (https://www.mexc.com/assets/record).",
              12: () =>
                "Wählen Sie im Popup-Fenster den Zeitraum aus, den Sie herunterladen möchten, deaktivieren Sie „Datei verschlüsseln“ und klicken Sie auf die Schaltfläche „Generieren“, um Ihre Auszahlungsaufzeichnungen zu exportieren.",
              13: () =>
                "Laden Sie alle heruntergeladenen Dateien hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    myEtherWallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Für jedes mit MEW verknüpfte Konto:",
              1: () =>
                "Suchen Sie die öffentliche Ethereum-Blockchain-Adresse des Kontos und kopieren Sie diese Adresse in die Zwischenablage. Hinweis: Wenn Sie die MEW Mobile App verwenden, finden Sie diese Details unter „Konten verwalten“ in der MEW Mobile App.",
              2: () =>
                "Fügen Sie die kopierte Ethereum-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    myTonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können entweder Ihre bouncefähige, nicht bouncefähige, TON DNS- oder Rohformatadresse eingeben und wir übersetzen sie für Sie in das Rohformat.",
            },
            title: () => "Adresstypen",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen und kopieren Sie Ihre TON-Wallet-Adresse in der MyTonWallet-Erweiterung",
              1: () =>
                "Geben Sie Ihre Wallet-Adresse in den Crypto Tax Calculator ein",
              2: () =>
                "Geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    ndax: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei NDAX an",
              1: () =>
                "Klicken Sie auf der linken Seite auf Berichte und exportieren Sie Ihr Hauptbuch im CSV-Format.",
              2: () =>
                "Sie können dies dann in den Crypto Tax Calculator hochladen",
            },
          },
        },
      },
    },
    near: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Unterstützung für NFT-Transaktionen kommt bald",
            },
            title: () => "Nicht alle Operationen werden unterstützt",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Near-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    neo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Unser Datenanbieter für diese Blockchain ist derzeit nicht erreichbar. Wir prüfen derzeit einen neuen Anbieter. Wir empfehlen jedoch, Ihre Daten vorerst mithilfe unserer benutzerdefinierten CSV-Vorlage zu importieren. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () => "Unsere Neo-Integration ist derzeit nicht verfügbar.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Bitte beachten Sie: Aufgrund nicht beanspruchter GAS kann es zu Abweichungen zwischen den berechneten und den gemeldeten GAS-Guthaben kommen.",
            },
            title: () => "Bilanzdiskrepanzen",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () => "Kopieren Sie Ihre öffentliche NEO-Wallet-Adresse",
              2: () =>
                "Wählen Sie in CTC die entsprechende Blockchain aus der Dropdown-Liste",
              3: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    netcoins: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Unsere Netcoins-Integration befindet sich derzeit in der Betaphase. Wenn Sie Probleme haben, wenden Sie sich bitte mit Ihrer CSV-Datei von Netcoins an unseren Support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Netcoins-Konto an",
              1: () =>
                "Scrollen Sie auf Ihrer Saldenseite nach unten zum Abschnitt Transaktionen",
              2: () =>
                "Klicken Sie oben in der Transaktionstabelle auf die Schaltfläche „Exportieren“, um einen CSV-Export Ihrer Transaktionen herunterzuladen.",
              3: () =>
                "Laden Sie diese CSV hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    neutron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Neutron-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    newton: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich in einem Webbrowser bei Ihrem Newton-Konto an",
              1: () =>
                "Navigieren Sie zur Schaltfläche „Vollständiger Verlauf“ neben der Überschrift „Letzte Aktivität“. Dadurch gelangen Sie zur Seite „Kontoverlauf“",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Kontoverlauf exportieren“",
              3: () =>
                "Exportieren Sie den „Bericht für JJJJ“, wobei „JJJJ“ das Jahr ist, das Sie exportieren möchten (z. B. 2020). Dadurch wird eine CSV-Datei mit einem Namen im Format „Kontoverlauf-JJJJ-Vorname-Nachname.csv“ heruntergeladen.",
              4: () =>
                "Laden Sie die gerade heruntergeladene Datei hier in den Crypto Tax Calculator hoch.",
              5: () =>
                "Stellen Sie sicher, dass Sie diesen Vorgang für alle Jahre wiederholen, für die Berichte zum Herunterladen verfügbar sind.",
            },
          },
        },
      },
    },
    nexo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Bitte beachten Sie, dass die Verkaufstransaktionen, die jedem Kauf entsprechen, manuell hinzugefügt werden müssen, da die Verkaufsseite des Austauschs in den Daten nicht verfügbar ist. Sie können dies auf der Seite „Transaktionen überprüfen“ tun.",
              1: () =>
                "Bitte stellen Sie sicher, dass Datum und Uhrzeit im Format „JJJJ-MM-TT HH:mm:ss“ oder „MM/TT/JJJJ HH:mm“ vorliegen.",
              2: () =>
                "Hinweis: Einige CSV-Zeilen können nicht importiert werden, da sie nicht den Handelsverlauf, sondern interne Datensätze von Nexo darstellen. Eine Zinszeile mit einem negativen USD-Betrag stellt beispielsweise einen Datensatz zu zahlender Zinsen dar, nicht einen tatsächlichen Handel.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Nexo an und klicken Sie auf die Registerkarte „Transaktionen“ (https://platform.nexo.io/transactions).",
              1: () =>
                "Wählen Sie den Datumsbereich aus, der Ihren gesamten Transaktionsverlauf abdeckt",
              2: () =>
                "Behalten Sie die Standardtransaktionstypen und -Assets als „Alle“ bei.",
              3: () =>
                "Exportieren Sie die CSV-Datei und importieren Sie sie in den Crypto Tax Calculator",
            },
          },
        },
      },
    },
    nftbank: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Die CSVs im Ordner „only_symbol“ enthalten nicht Ihre NFT-IDs, sondern nur das Symbol",
              1: () =>
                "Laden Sie die CSVs nicht aus dem Ordner „only_symbol“ hoch, wenn Sie die entsprechende CSV bereits aus dem Stammordner hochgeladen haben oder umgekehrt",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich in einem Webbrowser bei Ihrem NFTBank-Konto an.",
              1: () =>
                "Navigieren Sie zu Ihrem Portfolio und klicken Sie auf „Steuererklärung“.",
              2: () =>
                "Wählen Sie die Option „Kryptosteuerrechner“ und die Wallets, die Sie exportieren möchten",
              3: () =>
                "Bestätigen Sie Ihre E-Mail-Adresse und klicken Sie auf „Senden“.",
              4: () =>
                "Laden Sie die ZIP-Datei mit den an Ihre E-Mail gesendeten CSV-Dateien herunter",
              5: () =>
                "Laden Sie hier die in der Zip-Datei enthaltenen relevanten CSV-Dateien hoch (nicht die Zip-Datei selbst).",
            },
          },
        },
      },
    },
    nicehash: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Die Organisations-ID finden Sie auf der Seite API-Schlüssel und wird nicht mit dem Schlüssel und dem Geheimnis angegeben.",
              1: () =>
                "Mining-Gebühren können für alte Transaktionen fehlen, was zu Ungenauigkeiten in den berechneten Salden führen kann. Wenn dieses Problem auftritt, können Sie stattdessen über die Methode „Datei hochladen“ importieren, die diese Gebühren normalerweise einschließt.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem NiceHash-Konto an",
              1: () =>
                "Klicken Sie oben rechts in der Navigationsleiste auf Ihr Profilsymbol und dann auf die orangefarbene Schaltfläche „Meine Einstellungen“, um zur Einstellungsseite zu navigieren (https://www.nicehash.com/my/settings/).",
              2: () =>
                "Klicken Sie in der linken Navigationsleiste auf „API-Schlüssel“ (https://www.nicehash.com/my/settings/keys).",
              3: () =>
                "Klicken Sie auf „Neuen API-Schlüssel erstellen“, um den Dialog zur Erstellung des API-Schlüssels zu öffnen.",
              4: () =>
                "Erlauben Sie unter „Wallet-Berechtigungen“ die Berechtigung zum „Anzeigen von Guthaben, Wallet-Aktivitäten und Einzahlungsadressen“.",
              5: () =>
                "Unter „Marktplatzberechtigungen“ die Berechtigung „Hashpower-Bestellungen anzeigen“ erteilen",
              6: () =>
                "Erlauben Sie unter „Austauschberechtigungen“ die Berechtigung zum „Anzeigen von Austauschaufträgen“.",
              7: () =>
                "Erlauben Sie unter „Mining-Berechtigungen“ die Berechtigung zum „Anzeigen von Mining-Daten und -Statistiken“.",
              8: () =>
                "Sie werden aufgefordert, einige zusätzliche Authentifizierungsinformationen anzugeben",
              9: () =>
                "Kopieren Sie den API-Schlüssel, das Geheimnis und die Organisations-ID Ihres Kontos und fügen Sie sie hier im Crypto Tax Calculator als „API-Schlüssel“, „Geheimnis“ bzw. „UID“ ein.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Für jede Kryptowährung, für die Sie historische Aktivitäten haben, müssen separate Berichte erstellt werden. Dies können Sie tun, indem Sie die oben genannten Schritte für jede der verschiedenen Währungen befolgen.",
              1: () =>
                "Der Crypto Tax Calculator zieht alle Mining-Gebühren direkt von dem erhaltenen Betrag (aus dem Mining) zum Zeitpunkt des Zahlungseingangs ab.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem NiceHash-Konto an",
              1: () =>
                "Klicken Sie oben rechts in der Navigationsleiste auf Ihr Profilsymbol und dann auf die orangefarbene Schaltfläche „Meine Einstellungen“, um zur Einstellungsseite zu navigieren (https://www.nicehash.com/my/settings/).",
              2: () =>
                "Klicken Sie in der linken Navigationsleiste auf „Berichte“ (https://www.nicehash.com/my/settings/reports).",
              3: () =>
                "Klicken Sie auf „Neuer Bericht“, um das Dialogfeld zur Berichterstellung zu öffnen",
              4: () =>
                "Sie müssen nun die Kryptowährung für den Bericht sowie die „FIAT“-Währung auswählen. Stellen Sie sicher, dass die „FIAT“-Währung Ihre lokale Währung ist und dieselbe, die Sie im Crypto Tax Calculator haben. Stellen Sie sicher, dass die „Zeitaggregation“ auf „KEINE“ eingestellt ist. Wählen Sie den Von/Bis-Datumsbereich aus, der alle Ihre Aktivitäten umfasst. Stellen Sie sicher, dass die Zeitzone GMT ist.",
              5: () =>
                "Sobald das Formular ausgefüllt ist, klicken Sie auf „Erstellen“, um den Bericht zu erstellen. Die Verarbeitung des Berichts wird einige Zeit in Anspruch nehmen. Nach der Verarbeitung wird der Status auf „bereit“ geändert.",
              6: () =>
                "Laden Sie den Bericht herunter, indem Sie auf die drei Punkte unter „Aktion“ klicken und im Dropdown-Menü „Bericht herunterladen“ auswählen. Dadurch wird der Bericht als CSV-Datei heruntergeladen.",
              7: () =>
                "Sie können diese CSV jetzt in den Crypto Tax Calculator hochladen",
            },
          },
        },
      },
    },
    nolus: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Nolus-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    oasisdex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () => "Wählen Sie die Börse aus dem Dropdown-Menü",
              3: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              4: () => "Behalten Sie die anderen Standardeinstellungen bei",
              5: () => "Klicken Sie auf „Verlauf abrufen“.",
              6: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              7: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    oceanex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Bewegen Sie den Mauszeiger über „Fonds“ oben auf der Seite",
              1: () => "Wählen Sie im Dropdown-Menü Handelsverlauf",
              2: () => "Suchergebnisse exportieren",
              3: () =>
                "Laden Sie alle heruntergeladenen Dateien in den Crypto Tax Calculator hoch. Stellen Sie sicher, dass alle Ihre Transaktionen",
            },
          },
        },
      },
    },
    okex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit werden Margin- und Futures-Trades nicht unterstützt. Wir arbeiten daran, Unterstützung für diese Trades hinzuzufügen.",
              1: () =>
                "OKX erlaubt über seine API nur den Export von Handelsverlaufsdaten der letzten 3 Monate. Wenn Sie ältere Trades haben, müssen Sie auch eine CSV-Datei mit älteren Transaktionen hochladen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Bei OKX anmelden",
              1: () =>
                "Navigieren Sie zum API-Menü (https://www.okx.com/account/my-api)",
              2: () => "Klicken Sie auf „V5-API-Schlüssel erstellen“.",
              3: () =>
                "Geben Sie einen Namen und eine Passphrase ein, lassen Sie das Feld für die IP-Adresse leer und aktivieren Sie unter Berechtigungen nur das Kontrollkästchen „Lesen“",
              4: () =>
                "Bestätigen Sie Ihr Konto mit einem E-Mail-Code und einem 2FA-Authentifizierungscode und klicken Sie auf „Bestätigen“.",
              5: () =>
                "Geben Sie den API-Schlüssel, das Geheimnis und die Passphrase, die Sie hier erstellt haben, in den Crypto Tax Calculator ein",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/ZOf5ZjUYsEQ",
            title: () => "So importieren Sie Ihre OKX-API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützen wir nur CSVs vom Kontotyp „Unified“.",
              1: () =>
                "Derzeit werden Optionsgeschäfte nicht unterstützt. Wir arbeiten daran, diese Geschäfte zu unterstützen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Bei OKX anmelden",
              1: () =>
                "Navigieren Sie zum Berichtscenter (https://www.okx.com/balance/report-center/)",
              2: () =>
                "Klicken Sie für Trades unter „Handelsverlauf“ auf „Herunterladen“, wählen Sie „Alle“ als Instrument und legen Sie den Datumsbereich entsprechend fest, um alle Ihre Transaktionen abzudecken.",
              3: () =>
                "Laden Sie die CSV-Datei „Handelsverlauf“ hier in den Crypto Tax Calculator hoch",
              4: () =>
                "Klicken Sie für Ein- und Auszahlungen unter „Finanzierungsrechnung“ auf „Herunterladen“, wählen Sie „Finanzierung“ als Kontotyp und legen Sie den entsprechenden Datumsbereich fest, um alle Ihre Transaktionen abzudecken",
              5: () =>
                "Laden Sie die CSV-Datei „Kontoverlauf der Finanzierung“ hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/HpNmKgPLu3k",
            title: () => "So importieren Sie Ihre OKX CSV-Dateien",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () => "Click 'Connect to OKX' to open a new tab.",
              1: () => "Log in to your OKX account.",
              2: () => "Click 'Approve' button",
            },
          },
        },
      },
    },
    openMask: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können entweder Ihre bouncefähige, nicht bouncefähige, TON DNS- oder Rohformatadresse eingeben und wir übersetzen sie für Sie in das Rohformat.",
            },
            title: () => "Adresstypen",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen und kopieren Sie Ihre TON-Wallet-Adresse in der OpenMask-Erweiterung",
              1: () =>
                "Geben Sie Ihre Wallet-Adresse in den Crypto Tax Calculator ein",
              2: () =>
                "Geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    opensea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "In OpenSea, navigate to your profile",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the copied public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    optimism: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Wallet-Adresse, die Sie für den Handel über Optimism verwendet haben (beginnend mit 0x)",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    osmosis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Osmosis-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    pancakeSwap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir kategorisieren derzeit automatisch Börsen- und Liquiditätstransaktionen auf PancakeSwap",
              1: () =>
                "Wenn Sie Farming, Pools oder IFOs durchgeführt haben, werden Ihre Transaktionen importiert, aber Sie müssen diese möglicherweise überprüfen und entsprechend neu kategorisieren",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Binance Smart Chain-Wallet-Adresse, die Sie für den Handel über PancakeSwap verwendet haben",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    paradex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    phantom: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Solana-Blockchain-Wallet-Adresse, die Sie für den Handel über Phantom verwendet haben",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    phemex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Die Phemex-API unterstützt nur 3 Monate historische Daten, daher verwenden wir stattdessen die CSV",
              1: () =>
                "Wir unterstützen derzeit nur Spot-Trading, Einzahlungen/Abhebungen und geschlossene PNL-CSVs",
            },
          },
          1: {
            items: {
              0: () =>
                "Melden Sie sich bei Phemex an und bewegen Sie den Mauszeiger über das Menü „Asset“ in der oberen rechten Ecke.",
              1: () =>
                "Klicken Sie in der Dropdown-Liste auf „Spot History“ und dann auf „Order History“ (https://phemex.com/assets/spot-order-history).",
              2: () =>
                "Klicken Sie dann rechts auf „Spot-Order-Historie exportieren“ und wählen Sie den Zeitraum aus, in dem Sie gehandelt haben.",
              3: () =>
                "Sie können jeweils nur 3 Monate auswählen. Wenn Sie die Plattform also länger genutzt haben, müssen Sie sie für jeden Monat herunterladen.",
              4: () =>
                "Stellen Sie sicher, dass Sie Ihren gesamten Bestellverlauf heruntergeladen haben und laden Sie die Dateien dann in den Crypto Tax Calculator hoch.",
            },
            title: () => "Spot-Handel:",
          },
          2: {
            items: {
              0: () =>
                "Melden Sie sich bei Phemex an und bewegen Sie den Mauszeiger über das Menü „Asset“ in der oberen rechten Ecke.",
              1: () =>
                "Klicken Sie in der Dropdown-Liste auf die Schaltfläche „Einzahlen“",
              2: () =>
                "Scrollen Sie zum Ende der Seite und klicken Sie in der Tabelle „Neuester Verlauf“ auf „Alle anzeigen“. Dadurch gelangen Sie zur Einzahlungsseite (https://phemex.com/assets/details?tab=Deposit).",
              3: () => "Klicken Sie auf „Einzahlungsverlauf exportieren“.",
              4: () =>
                "Sie können nun auf die Registerkarte „Abheben“ (https://phemex.com/assets/details?tab=Withdrawal) klicken und diese Daten ebenfalls herunterladen",
              5: () =>
                "Auch hier haben Sie nur die Möglichkeit, Daten für jeweils 3 Monate herunterzuladen. Bitte stellen Sie sicher, dass Sie den gesamten Einzahlungs-/Auszahlungsverlauf heruntergeladen haben, und laden Sie dann alle Dateien in den Crypto Tax Calculator hoch.",
            },
            title: () => "Einzahlung/Auszahlung:",
          },
          3: {
            items: {
              0: () =>
                "Melden Sie sich bei Phemex an und bewegen Sie den Mauszeiger über das Menü „Asset“ in der oberen rechten Ecke.",
              1: () =>
                "Klicken Sie in der Dropdown-Liste auf „Vertragsverlauf“ und dann auf „PNL-Details“ (https://phemex.com/assets/contract-pnl-details).",
              2: () =>
                "Scrollen Sie zum Ende der Seite und klicken Sie in der Tabelle „Neuester Verlauf“ auf „Alle anzeigen“. Dadurch gelangen Sie zur Einzahlungsseite (https://phemex.com/assets/details?tab=Deposit).",
              3: () =>
                "Klicken Sie in den verfügbaren Optionen auf „Geschlossenes PNL“",
              4: () =>
                "Sie können dann rechts auf „Geschlossenen PNL-Verlauf exportieren“ klicken und den Zeitraum auswählen, in dem Sie gehandelt haben.",
              5: () =>
                "Auch hier haben Sie nur die Möglichkeit, Daten für jeweils 3 Monate herunterzuladen. Bitte stellen Sie sicher, dass Sie den gesamten geschlossenen PNL-Verlauf heruntergeladen haben, und laden Sie dann alle Dateien in den Crypto Tax Calculator hoch.",
            },
            title: () => "Geschlossene PNL:",
          },
        },
      },
    },
    pionex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Gehen Sie zur Registerkarte „Transaktionsverlauf“ auf der linken Seite",
              1: () =>
                "Wählen Sie den Zeitraum des Transaktionsverlaufs aus, den Sie herunterladen möchten, und klicken Sie auf „Senden“.",
              2: () =>
                "Sobald die Dateien bereit sind, erhalten Sie eine E-Mail von Pionex, sobald die Datei zum Download bereit ist",
              3: () =>
                "Gehen Sie zurück zu Ihrem Pionex-Konto > Transaktionsverlauf, um die Datei herunterzuladen",
              4: () =>
                "Entpacken Sie die heruntergeladenen Dateien und importieren Sie „trading.csv“ und „depositwithdraw.csv“ in CTC",
              5: () =>
                "Wenn Sie andere Transaktionsdateien haben, importieren Sie diese bitte mithilfe unserer benutzerdefinierten CSV",
              6: () => "Hinweis: Es gibt ein Limit von 3 Exporten pro Monat",
            },
          },
        },
      },
    },
    polkadot: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
          1: {
            items: {
              0: () =>
                "Derzeit werden IBC-Cross-Chain-Transfers nicht unterstützt.",
            },
            title: () => "Fehlende Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Polkadot-Wallet-Adresse (beginnend mit 1) in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    polkadotjs: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr Polkadot.js-Wallet",
              1: () => "Klicken Sie oben rechts auf das Einstellungssymbol",
              2: () =>
                "Wählen Sie „Adressformat anzeigen für“ und wählen Sie das gewünschte Netzwerk",
              3: () =>
                "Gehen Sie zurück zur Kontenansicht und klicken Sie neben dem gewünschten Konto auf das Symbol „In die Zwischenablage kopieren“.",
              4: () =>
                "Wählen Sie im Crypto Tax Calculator das entsprechende Netzwerk aus",
              5: () =>
                "Fügen Sie die Adresse ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    polkagate: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr Polkagate-Wallet",
              1: () =>
                "Klicken Sie auf das 3-Punkte-Menü neben dem Konto, das Sie importieren möchten",
              2: () =>
                "Wählen Sie das gewünschte Netzwerk im Dropdown-Menü „Kette“ aus",
              3: () =>
                "Gehen Sie zurück zur Kontenansicht und klicken Sie neben dem gewünschten Konto auf das Symbol „In die Zwischenablage kopieren“.",
              4: () =>
                "Wählen Sie im Crypto Tax Calculator das entsprechende Netzwerk aus",
              5: () =>
                "Fügen Sie die Adresse ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    poloniex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Bitte beachten Sie, dass die Poloniex-API derzeit nur Überweisungen ab dem 22. Januar 2020 und Trades ab dem 30. Juli 2021 synchronisiert (https://docs.poloniex.com/#authenticated-endpoints-trades).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Poloniex an und klicken Sie in der Navigationsleiste auf die Registerkarte „Einstellungen“",
              1: () =>
                "Navigieren Sie zur Seite „API-Schlüssel“ (https://poloniex.com/apiKeys).",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Neuen Schlüssel erstellen“",
              3: () =>
                "Geben Sie ggf. Ihren Zwei-Faktor-Authentifizierungscode und/oder E-Mail-Anweisungen ein",
              4: () =>
                "Sobald Sie eine Bestätigungs-E-Mail von Poloniex erhalten haben, bestätigen Sie Ihren neuen API-Schlüssel",
              5: () =>
                "Deaktivieren Sie für Ihren neuen API-Schlüssel die Kontrollkästchen „Handel aktivieren“ und „Abhebungen aktivieren“.",
              6: () =>
                "Setzen Sie die IP-Zugriffsbeschränkung auf Unbeschränkt",
              7: () =>
                "Kopieren Sie sowohl den API-Schlüssel als auch das API-Geheimnis",
              8: () =>
                "Fügen Sie den kopierten „API-Schlüssel“ und das „API-Geheimnis“ hier in den Crypto Tax Calculator ein und klicken Sie auf „API hinzufügen“.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Bitte importieren Sie alle Ihre relevanten „Aktivitäten“ (z. B. Einzahlungen, Abhebungen, Handelsgeschäfte usw.), für die Daten vorhanden sind, damit wir Ihre Transaktionen richtig kategorisieren können.",
              1: () =>
                "Derzeit unterstützen wir keine automatischen Importe für die Aktivitätsarten „Margin Borrowing“ oder „Lending“.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Poloniex an und klicken Sie in der Navigationsleiste auf die Registerkarte „Aktivität“",
              1: () =>
                "Dadurch sollten Sie zur Verlaufsseite „Wallets“ weitergeleitet werden (https://poloniex.com/activity/wallet).",
              2: () =>
                "Klicken Sie oben rechts auf dem Bildschirm unter der Navigationsleiste auf die Schaltfläche „Exportieren“",
              3: () =>
                "Wählen Sie unter Exportaktivität Ihren „Aktivitätstyp“ aus. Derzeit unterstützen wir Folgendes: „Einzahlungen“, „Abhebungen“, „Handel“, „Ausschüttungen“.",
              4: () =>
                "Nachdem Sie Ihren Aktivitätstyp ausgewählt haben, wählen Sie den Datumsbereich aus, der den gesamten Verlauf abdeckt",
              5: () =>
                "Klicken Sie auf die Schaltfläche „Herunterladen“. Sie sollten eine CSV-Datei mit dem Namen „[activityType]-[selectedDateRange].csv“ erhalten, wobei [activityType] Ihr ausgewählter Aktivitätstyp und [selectedDateRange] der von Ihnen ausgewählte Datumsbereich ist.",
              6: () =>
                "Importieren Sie die CSV(s) hier in den Crypto Tax Calculator, ohne sie erneut zu speichern oder zu öffnen (dies kann auf einigen Computern das Format ändern)",
            },
          },
        },
      },
    },
    polygon: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
              1: () =>
                "Guthaben können sich um bis zu ein paar Stunden verzögern",
            },
          },
          1: {
            items: {
              0: () =>
                "Wir unterstützen jetzt ERC-1155-Token, können jedoch Ihre aktuellen Guthaben für diese Token nicht importieren. Dies kann zu Abweichungen zwischen dem aus der Quell-API importierten Guthaben und dem von der App berechneten Guthaben führen.",
            },
            title: () => "ERC-1155-Token",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Polygon (Matic)-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    "polygon-zkevm": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Polygon Hermez (zkEVM)-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    pooltool: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Da PoolTool keine Ihrer Vermögenswerte hält, werden mit diesem Import keine Kryptowährungsbestände direkt verknüpft. Stattdessen sind die Staking-Belohnungen mit Ihrer bech32-Stake-Adresse selbst verknüpft.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Navigieren Sie zu (https://pooltool.io)",
              1: () =>
                "Klicken Sie oben links auf „Belohnungsdaten für Steuern“.",
              2: () => "Fügen Sie Ihre Staking-Belohnungsadresse ein",
              3: () =>
                "Wählen Sie unten links: Format - allgemein; Währung - US-Dollar; Zeitraum - das Jahr, für das Sie Ihre Staking-Daten exportieren möchten",
              4: () =>
                "Klicken Sie auf „Daten herunterladen“, um Ihre Daten als CSV-Datei herunterzuladen",
              5: () =>
                "Geben Sie Ihre bech32-Stake-Adresse ein und laden Sie diese CSV-Datei hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    probit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Die API-Synchronisierung von Probit bietet nur Zugriff auf Ihren Handelsverlauf. Sie müssen Ihre Probit-Einzahlungen und -Abhebungen separat manuell in den Crypto Tax Calculator importieren. Sie können dies über unsere erweiterte benutzerdefinierte CSV-Vorlage tun: https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Probit-Konto an",
              1: () =>
                "Bewegen Sie den Mauszeiger oben rechts in der Navigationsleiste über „Meine Seite“ und klicken Sie im Dropdown-Menü auf „API-Verwaltung“ (https://www.probit.com/en-us/user-center/api-management/api-credential).",
              2: () =>
                "Stellen Sie sicher, dass die Registerkarte „API-Verwaltung“ hervorgehoben ist, geben Sie „Crypto Tax Calculator“ als „API-Schlüsselbezeichnung“ ein und klicken Sie auf „Neuen Schlüssel erstellen“.",
              3: () =>
                "Sie werden aufgefordert, einige zusätzliche Authentifizierungsinformationen anzugeben",
              4: () =>
                "Kopieren/speichern Sie die „Client-ID“ und den „Clients Secret Key“ an einem sicheren Ort.",
              5: () =>
                " Nach der Authentifizierung MÜSSEN Sie die API-Schlüsselberechtigungen bearbeiten - klicken Sie auf die Schaltfläche „Bearbeiten“, deaktivieren Sie das Kontrollkästchen „Handel“ und klicken Sie dann auf „Bearbeiten abgeschlossen“.",
              6: () =>
                "WICHTIG: Stellen Sie sicher, dass das einzige aktivierte Kontrollkästchen „Lesen (Standard)“ ist.",
              7: () =>
                "Geben Sie die „Client-ID“ als API-Schlüssel und den „Clients Secret Key“ als Geheimnis hier in den Crypto Tax Calculator ein.",
            },
          },
        },
      },
    },
    protocol: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Öffnen Sie die Brieftasche, die Sie für die Interaktion mit " +
                d.exchangeDisplayName +
                " verwendet haben",
              1: () => "Kopieren Sie Ihre öffentliche Wallet-Adresse",
              2: () =>
                "Wählen Sie in CTC die entsprechende Blockchain aus der Dropdown-Liste",
              3: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse ein und klicken Sie auf „Wallet importieren“.",
              4: (d) =>
                "CTC importiert alle Transaktionen aus der Dropdown-Liste, einschließlich der mit " +
                d.exchangeDisplayName +
                " verknüpften",
            },
          },
        },
      },
    },
    pulsechain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
              1: () =>
                "Die Integration befindet sich derzeit in der Betaphase und kann daher gelegentlich fehlschlagen. In diesem Fall versuchen Sie es nach einer Weile erneut.",
            },
          },
          1: {
            items: {
              0: () => "Wir unterstützen derzeit keine ERC-1155-Transaktionen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre PulseChain-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    quantfury: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Zu diesem Zeitpunkt müssen Sie den Quantfury-Support kontaktieren und die XLSX-Datei mit der Handelshistorie anfordern.",
              1: () =>
                "Das Format sollte sein: „Name, Aktion, Menge, Preis, Wert, Datum, Gesamtposition“.",
              2: () =>
                "Sobald Sie dieses Format erhalten haben, müssen Sie es öffnen und in ein CSV-Format exportieren.",
              3: () =>
                "Sie müssen auch alle nicht-kryptowährungsbezogenen Transaktionen entfernen, da wir diese nicht verarbeiten",
              4: () =>
                "Wir arbeiten mit Quantfury zusammen, um die Unterstützung hierfür zu verbessern",
            },
          },
        },
      },
    },
    quickswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit kategorisieren wir Swaps automatisch und fügen Liquidität auf QuickSwap hinzu/entfernen",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Polygon (Matic)-Wallet-Adresse, die Sie für den Handel über QuickSwap verwendet haben",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    "rabby-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Klicken Sie in Ihrem Browser auf die Rabby-Erweiterung",
              1: () =>
                "Wählen Sie für jedes mit Rabby verknüpfte Konto das Konto aus und klicken Sie auf das Symbol „In die Zwischenablage kopieren“.",
              2: () =>
                "Fügen Sie die kopierte öffentliche Wallet-Adresse (für das angegebene Netzwerk) in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    radarRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    "rainbow-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie in Ihrem Browser auf die Rainbow Wallet-Erweiterung",
              1: () =>
                "Wählen Sie für jedes mit Rainbow Wallet verknüpfte Konto das Konto aus und klicken Sie auf das Kopiersymbol",
              2: () =>
                "Fügen Sie die kopierte öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    relaypay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem RelayPay-Konto an",
              1: () =>
                "Klicken Sie oben rechts auf Ihren Benutzernamen und wählen Sie „Mein Profil“ (https://relaypay.io/profile).",
              2: () => "Navigieren Sie zur Registerkarte „Krypto kaufen“",
              3: () => "Klicken Sie auf „Alles als CSV herunterladen“.",
              4: () =>
                "Wiederholen Sie Schritt 3 für die Registerkarten „Banküberweisung“ und „Rechnungszahlung“.",
              5: () =>
                "Die CSV-Dateien könnt ihr dann hier in den Crypto Tax Calculator importieren",
            },
          },
        },
      },
    },
    revolut: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Revolut an.",
              1: () => "Wählen Sie im unteren Menü „Krypto“ aus.",
              2: () => "Wählen Sie eine Kryptowährung.",
              3: () => "Tippen Sie auf „Kontoauszug“.",
              4: () => "Wählen Sie einen Zeitraum.",
              5: () => "Wählen Sie „Generieren“ aus.",
              6: () =>
                "Wiederholen Sie die Schritte 3 bis 6 für jede Kryptowährung, da Sie nicht alle auf einmal generieren können. ",
              7: () =>
                "Laden Sie den Kontoauszug in Ihrer Revolut-App herunter.",
            },
          },
        },
      },
    },
    ripple: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
              1: () =>
                "Für XRP unterstützen wir derzeit nur XRP-Zahlungstransaktionen. Wenn Sie andere Transaktionsarten haben (z. B. verschiedene Währungen), kontaktieren Sie uns bitte über unseren Chat-Support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () => "Kopieren Sie Ihre öffentliche XRP-Wallet-Adresse",
              2: () =>
                "Wählen Sie in CTC die entsprechende Blockchain aus der Dropdown-Liste",
              3: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    robinhood: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Abhebungen und Einzahlungen werden über die API nicht unterstützt. Bitte verwenden Sie die CSV-Importoption.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Robinhood-Konto an. Klicken Sie oben rechts auf „Konto“ und wählen Sie dann „Einstellungen“.",
              1: () =>
                "Navigieren Sie zum Abschnitt „Krypto“ und suchen Sie die Option „API-Handel“.",
              2: () =>
                "Folgen Sie https://help.cryptotaxcalculator.io/en/articles/10448126-import-robinhood-transactions-into-crypto-tax-calculator-using-api-keys, um ein Base64-Schlüsselpaar zu generieren.",
              3: () =>
                "Fügen Sie den generierten „öffentlichen Schlüssel“ zu Ihrem Robinhood-Konto hinzu.",
              4: () =>
                "Aktivieren Sie unter API-Berechtigungen alle Leseberechtigungen. Überspringen Sie das Festlegen zulässiger IPs oder des Ablaufdatums für den API-Schlüssel.",
              5: () =>
                "Bestätigen Sie die Erstellung des API-Schlüssels. Robinhood zeigt den API-Schlüssel an.",
              6: () =>
                "Geben Sie in Ihrem CTC-Konto den API-Schlüssel unter „API-Schlüssel“ und den „Privaten Schlüssel“ aus Schritt 3 unter „Geheim“ ein.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Kontaktieren Sie den Robinhood-Support unter https://robinhood.com/contact",
              1: () =>
                "Klicken Sie auf Dokumente und Steuern → Transaktionsverlauf → Kontaktieren Sie uns",
              2: () =>
                "Wählen Sie, wie Sie kontaktiert werden möchten: per E-Mail oder Telefon",
              3: () =>
                "Fordern Sie eine Kopie Ihrer Transaktionen für Ihre Käufe/Verkäufe und Sendungen/Empfangsaufträge an. Dies können zwei Dateien sein",
              4: () =>
                "Sobald Sie Ihre Datei(en) haben, konvertieren Sie diese bei Bedarf von PDF in CSV",
              5: () =>
                "Formatieren Sie die Datei(en) in das erweiterte CSV-Format von CTC neu (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import).",
              6: () => "Laden Sie hier Ihre CSV-Datei(en) hoch",
            },
          },
        },
      },
    },
    robinhood_wallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Dies ist für die nicht verwahrte Robinhood Web3-Wallet. Um Ihr Robinhood Exchange-Konto zu importieren, gehen Sie bitte hierhin: https://app.cryptotaxcalculator.io/imports/new/robinhood",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie die Robinhood Non-Custodial Web3 Wallet-App",
              1: () =>
                "Wählen Sie auf dem Startbildschirm Menü (oben links) und wählen Sie Empfangen",
              2: () => "Kopieren Sie Ihre öffentliche Adresse",
              3: () =>
                "Wählen Sie die entsprechende Blockchain aus der Dropdown-Liste",
              4: () =>
                "Fügen Sie die öffentliche Adresse ein und klicken Sie auf „Synchronisieren“",
            },
          },
        },
      },
    },
    ronin: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Besuchen Sie https://nftbank.ai/ und melden Sie sich an",
              1: () =>
                "Klicken Sie im linken Menü auf die Registerkarte „Steuererklärung“",
              2: () =>
                "Wählen Sie „Transaktionsverlauf für Steuererklärung abrufen“",
              3: () =>
                "Wählen Sie „Kryptosteuerrechner“ und klicken Sie auf „Weiter“.",
              4: () =>
                "Wählen Sie „Originalwährung – Format des Steuerdienstleisters“",
              5: () =>
                "Wenn Sie Ihr Ronin-Wallet noch nicht importiert haben, klicken Sie auf „Wallet hinzufügen“ und folgen Sie den Anweisungen von NFTbank",
              6: () =>
                "Wählen Sie nach dem Importieren Ihre Ronin-Wallets aus und klicken Sie auf „Weiter“.",
              7: () =>
                "Geben Sie Ihre E-Mail-Adresse ein, unter der Sie einen Download-Link erhalten",
              8: () =>
                "Die heruntergeladene Datei sollte eine ZIP-Datei sein. Öffnen Sie die Datei, um ihren Inhalt anzuzeigen.",
              9: () =>
                "Suchen Sie im neuen Ordner nach der Datei ronin.csv und importieren Sie diese in den Crypto Tax Calculator",
            },
          },
        },
      },
    },
    saga: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Saga-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    scroll: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Scroll-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    sei: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Enter your Sei wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    sentinel: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Sentinel-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    shakepay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Shakepay-Konto an.",
              1: () => "Gehen Sie zur Hauptseite „Wallets“.",
              2: () =>
                "Klicken Sie auf der Hauptseite neben dem Titel „Transaktionsverlauf“ auf die Schaltfläche „Downloadverlauf“.",
              3: () =>
                "Importieren Sie die CSV-Datei in den Crypto Tax Calculator.",
              4: () =>
                "Weitere Informationen finden Sie unter https://help.shakepay.com/en/articles/3336094-where-can-i-see-my-full-transaction-history-monthly-statements-on-shakepay.",
            },
          },
        },
      },
    },
    sharkRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    singularx: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () => "Wählen Sie die Börse aus dem Dropdown-Menü",
              3: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              4: () => "Behalten Sie die anderen Standardeinstellungen bei",
              5: () => "Klicken Sie auf „Verlauf abrufen“.",
              6: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              7: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    softWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Wenn Sie Ihre Münze oder Ihr Netzwerk nicht finden können, bedeutet das, dass der Crypto Tax Calculator noch keine Transaktionen dafür synchronisieren kann. Bitte kontaktieren Sie unseren Live-Chat, damit wir Unterstützung hinzufügen können!",
              1: () =>
                "Alternativ können Sie diese Transaktionen manuell über die erweiterte CSV-Importoption von CTC eingeben: https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import ",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Wählen Sie das Netzwerk",
              1: () =>
                "Kopieren Sie Ihren öffentlichen xpub-, ypub- oder zpub-Schlüssel. (z. B. BTC xpub-Schlüssel)",
              2: () =>
                "Fügen Sie die öffentliche Adresse ein und klicken Sie auf „Wallet importieren“.",
              3: () =>
                "Weitere Einzelheiten (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator)",
            },
            title: () =>
              "Bitcoin, BitcoinCash, Dogecoin, BSV und andere Netzwerke mit xpub-Wallets:",
          },
          1: {
            items: {
              0: () => "Wählen Sie das Netzwerk",
              1: () => "Kopieren Sie Ihre öffentliche Wallet-Adresse.",
              2: () =>
                "Fügen Sie Ihre öffentliche Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
            title: () =>
              "Ethereum (und ETH-Token), Binance Smart Chain, Cardano, Ripple, Gnosis, Polygon und andere Netzwerke mit einer EVM-Wallet-Adresse:",
          },
          2: {
            items: {
              0: () => "Wählen Sie das Netzwerk",
              1: () =>
                "Suchen Sie Ihren öffentlichen Ltub-Schlüssel (z. B. in Ledger Live unter „Erweiterte Protokolle“).",
              2: () =>
                "Konvertieren Sie die Ltub-Adresse hier in eine Xpub-Adresse (https://jlopp.github.io/xpub-converter/).",
              3: () =>
                "Fügen Sie die Xpub-Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
            title: () => "Litecoin:",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/g8JcDe6zRZA",
            title: () => "So importieren Sie Ihr Ledger Bitcoin-Wallet",
          },
        },
      },
    },
    solana: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
            },
          },
          1: {
            items: {
              0: () =>
                "Wenn Sie Transaktionen über den manuellen CSV-Import hochladen, verwenden Sie bitte „solana-deprecated“ oder „sol-deprecated“ als Blockchain.",
            },
            title: () => "Verwenden des manuellen CSV-Imports",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Solana-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    "solana-v2": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () => "Kopieren Sie Ihre öffentliche Solana-Wallet-Adresse",
              2: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    solflare: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Finden Sie die Solana Blockchain Wallet-Adresse, die Sie für den Handel über SolFlare verwendet haben",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    sollet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Solana-Blockchain-Wallet-Adresse, die Sie für den Handel über Sollet verwendet haben",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    sonic: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
          1: {
            items: {
              0: () =>
                "Live-Guthaben für Token auf Sonic sind derzeit nicht verfügbar. Transaktionsdetails und Steuerberechnungen sind davon nicht betroffen",
            },
            title: () => "Live-Guthaben",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Kopieren Sie die Adresse Ihres öffentlichen Sonic-Wallets, die mit „0x...“ beginnt.",
              1: () => "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein",
              2: () =>
                "Geben Sie einen Spitznamen ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    spiritswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Fantom-Blockchain-Wallet-Adresse, die Sie für den Handel über SpiritSwap verwendet haben.",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    spookyswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Fantom-Blockchain-Wallet-Adresse, die Sie für den Handel über SpookySwap verwendet haben.",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    stacks: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Stacks-Wallet-Adresse (beginnend mit SP) in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    staketax: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie auf stake.tax nach Ihrer Wallet-Adresse und wählen Sie in der Download-Eingabeaufforderung Crypto Tax Calculator aus",
              1: () =>
                "Laden Sie die CSV hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    stargaze: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Sterne beobachten in der frühen Entwicklung",
              1: () =>
                "Nur Transfer-/Staking-Labels (andere erscheinen als unbekannt)",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Stargaze-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    starknet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr Starknet-Wallet (Argent, Braavos usw.)",
              1: () =>
                "Kopieren Sie Ihre öffentliche Wallet-Adresse oder Vertragsadresse, die mit „0x…“ beginnt.",
              2: () =>
                "Fügen Sie die Adresse in den Crypto Tax Calculator ein, fügen Sie einen optionalen Spitznamen hinzu und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    stellar: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () => "Kopieren Sie Ihre öffentliche Stellar-Wallet-Adresse",
              2: () =>
                "Wählen Sie in CTC die entsprechende Blockchain aus der Dropdown-Liste",
              3: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    stex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei STEX an, klicken Sie oben rechts auf Ihre E-Mail und wählen Sie „Profil“ aus.",
              1: () =>
                "Klicken Sie auf der linken Seite auf „Bericht“ (https://app.stex.com/en/profile/report).",
              2: () =>
                "Klicken Sie auf „Benutzerverlauf“ und dann auf die Schaltfläche „Exportieren“.",
              3: () =>
                "Sie können diese CSV jetzt in den Crypto Tax Calculator hochladen",
            },
          },
        },
      },
    },
    stormrake: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Stormrake-Konto an.",
              1: () => "Laden Sie Ihren Transaktionsverlauf herunter",
              2: () =>
                "Laden Sie die CSV-Datei in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    "substrate-chains": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Bei Bridge-Transaktionen können möglicherweise Gebühren fehlen, da die Blockchain diese Informationen in einigen Fällen nicht bereitstellt.",
              1: () =>
                "Darüber hinaus fehlen möglicherweise einige Überweisungstransaktionen aus der Blockchain. Wenn dieses Problem bei Ihnen auftritt, wenden Sie sich bitte an den Support.",
            },
            title: () => "Fehlende Transaktionen",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) =>
                "Geben Sie Ihre " +
                d.exchangeDisplayName +
                " -Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    subwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr SubWallet-Wallet",
              1: () =>
                "Wählen Sie das Konto, das Sie importieren möchten, aus der oberen Dropdown-Liste aus.",
              2: () => "Klicken Sie auf das Symbol „Adresse kopieren“",
              3: () => "Kopieren Sie die Adresse des gewünschten Netzwerks",
              4: () =>
                "Wählen Sie im Crypto Tax Calculator das entsprechende Netzwerk aus",
              5: () =>
                "Fügen Sie die Adresse ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    sui: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihr Sui-Wallet",
              1: () =>
                "Wählen Sie für jedes mit Sui verknüpfte Konto das Konto aus und bewegen Sie den Mauszeiger über den Kontonamen",
              2: () =>
                "Kopieren Sie die öffentliche Wallet-Adresse, die mit „0x…“ beginnt.",
              3: () =>
                "Fügen Sie die kopierte öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    swissborg: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Für detailliertere Schritte hat SwissBorg hier einen Leitfaden zusammengestellt: https://help.swissborg.com/hc/en-gb/articles/360016499698-Account-Statement",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich über die SwissBorg-App bei Ihrem SwissBorg-Konto an.",
              1: () =>
                "Gehen Sie zu Ihrem „Profil“ und tippen Sie auf „Kontoauszug“.",
              2: () =>
                "Wählen Sie den Datumsbereich aus, der Ihre komplette Handelshistorie abdeckt",
              3: () =>
                "Wählen Sie als Referenzwährung Ihre lokale Fiat-Währung aus",
              4: () =>
                "Wählen Sie das Format „Excel“ und speichern Sie die Datei",
              5: () =>
                "Laden Sie diese Datei hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    switcheo: {
      csv: {
        note: {
          0: {
            items: {
              0: () => "Das Datumsformat sollte JJJJ-MM-TT HH:mm:ss sein.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zum Switcheo Exporter (https://benammann.github.io/switcheo-exporter/#/).",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse ein und exportieren Sie die CSV.",
              2: () =>
                "Klicken Sie oben rechts auf die Schaltfläche „CSV herunterladen“.",
              3: () =>
                "Sie können diese CSV jetzt in den Crypto Tax Calculator hochladen.",
            },
          },
        },
      },
    },
    swyftx: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Wir arbeiten derzeit mit Swyftx zusammen, um deren neue Staking-Funktion zu unterstützen. Staking-Einzahlungen und -Abhebungen werden derzeit nicht importiert.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Besuchen Sie https://trade.swyftx.com.au/profile/api/",
              1: () => "Klicken Sie auf „Neuen Schlüssel erstellen“.",
              2: () =>
                "Klicken Sie nach dem Lesen der Warnung auf „Ich verstehe“.",
              3: () =>
                "Geben Sie eine Bezeichnung ein (z. B. „Crypto Tax Calculator“), legen Sie den „Bereich“ auf „Nur Lesen“ fest und geben Sie zur Bestätigung Ihr Swyftx-Passwort ein.",
              4: () =>
                "Kopieren Sie den „API-Schlüssel“ und das „Zugriffstoken“ und fügen Sie sie dann als „API-Schlüssel“ und „Geheimnis“ in den Crypto Tax Calculator ein.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q9QJesWUiMk",
            title: () => "So importieren Sie Ihre Swyftx-API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Der CSV-Export von Swyftx weist möglicherweise Inkonsistenzen bei den Zeitstempeln für die Sommerzeit auf. Für eine höhere Genauigkeit verwenden Sie nach Möglichkeit den API-Import.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Besuchen Sie https://trade.swyftx.com/profile/transaction-reports",
              1: () => "Wählen Sie den Transaktionszeitraum",
              2: () => "Klicken Sie auf „Download.csv“.",
              3: () =>
                "Laden Sie die CSV-Datei in den Crypto Tax Calculator hoch.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q_WXPgSRQoQ",
            title: () => "So importieren Sie Ihre Swyftx CSV-Dateien",
          },
        },
      },
    },
    taiko: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Taiko-Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Kopieren Sie die Adresse Ihres öffentlichen Taiko-Wallets, die mit „0x...“ beginnt.",
              1: () => "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein",
              2: () =>
                "Geben Sie einen Spitznamen ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    talisman: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Öffnen Sie Ihre Talisman-Brieftasche",
              1: () => "Klicken Sie auf das Konto, das Sie importieren möchten",
              2: () => "Klicken Sie auf das Symbol „Adresse kopieren“",
              3: () => "Wählen Sie das gewünschte Netzwerk aus",
              4: () =>
                "Wählen Sie im Crypto Tax Calculator das entsprechende Netzwerk aus",
              5: () =>
                "Fügen Sie die Adresse ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    terra: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Wenn Sie Ihre Terra-Transaktionen vor dem 18. Mai 2022 importiert haben, können aufgrund der Umbenennung von LUNA in LUNC Preisfehler in der App auftreten. Wir empfehlen, Ihr Terra Classic-Wallet zu löschen und erneut zu importieren, um diese Probleme zu beheben.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Terra-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    terra2: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Terra 2.0 befindet sich in der frühen Entwicklung",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Terra 2.0-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    theta: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
              1: () =>
                "Wir unterstützen derzeit keine TNT721-Token und Sie müssen diese manuell auf der Seite „Transaktionen überprüfen“ hinzufügen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Theta-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    thetaboard: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Geben Sie vor dem Hochladen der Datei unbedingt Ihre Wallet-Adresse ein, damit wir wissen, wo diese Transaktionen zuzuordnen sind.",
              1: () =>
                "Wir unterstützen derzeit keine TNT721-Token und Sie müssen diese manuell auf der Seite „Transaktionen überprüfen“ hinzufügen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Thetaboard (https://thetaboard.io/wallet/explorer).",
              1: () => "Geben Sie Ihre öffentliche Wallet-Adresse ein.",
              2: () =>
                "Klicken Sie auf „Transaktionsverlauf exportieren“ (unter „Transaktionen“).",
              3: () =>
                "Sie können diese CSV jetzt in den Crypto Tax Calculator hochladen.",
            },
          },
        },
      },
    },
    thetawallet: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Theta-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Bevor Sie die Datei hochladen, geben Sie unbedingt Ihre Wallet-Adresse ein, damit wir wissen, wo diese Transaktionen zuordnen sollen.",
              1: () =>
                "Wenn Sie genauere Gebühreninformationen wünschen, sollten Sie stattdessen den „Theta“-Import verwenden, der aus der Blockchain liest",
              2: () =>
                "Wir unterstützen derzeit keine TNT721-Token und Sie müssen diese manuell auf der Seite „Transaktionen überprüfen“ hinzufügen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu ThetaScan (http://www.thetascan.io/).",
              1: () => "Geben Sie Ihre öffentliche Wallet-Adresse ein.",
              2: () =>
                "Klicken Sie auf „Transaktionen exportieren“ (unter Ihrem Guthaben).",
              3: () =>
                "Sie können diese CSV jetzt in den Crypto Tax Calculator hochladen",
            },
          },
        },
      },
    },
    thorchain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Derzeit unterstützen wir nur die Transaktionstypen „Senden“ und „Tauschen“.",
            },
            title: () => "Unterstützte Typen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre THORChain-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „THORChain-Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    toast: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Für Ripple unterstützen wir derzeit nur XRP-Zahlungstransaktionen. Wenn Sie andere Transaktionsarten haben (z. B. verschiedene Währungen), kontaktieren Sie uns bitte über unseren Chat-Support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Für jedes mit Ihrem Toast-Wallet verknüpfte Konto:",
              1: () =>
                "Suchen Sie die öffentliche Blockchain-Adresse des Kontos.",
              2: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    tokenJar: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    tokenStore: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Erweitern Sie auf der linken Seite im Menü „Exchange History“ und klicken Sie auf „TokenStore“.",
              3: () =>
                "Klicken Sie unter „Operationsarten“ auf „Trades + Fonds“.",
              4: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              5: () => "Behalten Sie die anderen Standardeinstellungen bei",
              6: () => "Klicken Sie auf „Verlauf abrufen“.",
              7: () =>
                "Sobald Sie fertig sind, können Sie die Standard-CSV unter „Export Trades“ und „Export Funds“ exportieren.",
              8: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV-Dateien hochladen",
            },
          },
        },
      },
    },
    tokenion: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    ton: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können entweder Ihre bouncefähige, nicht bouncefähige, TON DNS- oder Rohformatadresse eingeben und wir übersetzen sie für Sie in das Rohformat.",
            },
            title: () => "Adresstypen",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre TON-Wallet-Adresse oder TON DNS in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    tonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können entweder Ihre bouncefähige, nicht bouncefähige, TON DNS- oder Rohformatadresse eingeben und wir übersetzen sie für Sie in das Rohformat.",
            },
            title: () => "Adresstypen",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Finden Sie Ihre TON-Wallet-Adresse in der Wallet-App in Telegram. Um Ihre Adresse anzuzeigen, müssen Sie möglicherweise auf die Schaltfläche „Krypto hinzufügen“ klicken, gefolgt von den Optionen „Externes Wallet“ und dann „Toncoin“ und die Adresse von dort kopieren.",
              1: () =>
                "Geben Sie Ihre Wallet-Adresse in den Crypto Tax Calculator ein",
              2: () =>
                "Geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
              3: () =>
                "Wiederholen Sie die obigen Schritte für alle TON Space-Wallets, die Sie haben. Suchen Sie die Adresse, indem Sie in Ihrer Wallet auf den TON Space klicken und auf die Adressabkürzung klicken, um sie in Ihre Zwischenablage zu kopieren.",
            },
          },
        },
      },
    },
    toncoin: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können entweder Ihre bouncefähige, nicht bouncefähige, TON DNS- oder Rohformatadresse eingeben und wir übersetzen sie für Sie in das Rohformat.",
            },
            title: () => "Adresstypen",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre TON-Wallet-Adresse oder TON DNS in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    tonhub: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können entweder Ihre bouncefähige, nicht bouncefähige, TON DNS- oder Rohformatadresse eingeben und wir übersetzen sie für Sie in das Rohformat.",
            },
            title: () => "Adresstypen",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen und kopieren Sie Ihre TON-Wallet-Adresse in der Tonhub-App. Um Ihre Adresse anzuzeigen, müssen Sie möglicherweise auf die Schaltfläche „Empfangen“ klicken und die Adresse von dort kopieren.",
              1: () =>
                "Geben Sie Ihre Wallet-Adresse in den Crypto Tax Calculator ein",
              2: () =>
                "Geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    tonkeeper: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können entweder Ihre bouncefähige, nicht bouncefähige, TON DNS- oder Rohformatadresse eingeben und wir übersetzen sie für Sie in das Rohformat.",
            },
            title: () => "Adresstypen",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Finden Sie Ihre TON-Wallet-Adresse in der Tonkeeper-App, entweder auf Telegram oder in der mobilen oder Desktop-App. Um Ihre Adresse anzuzeigen, müssen Sie möglicherweise auf die Schaltfläche „Empfangen“ klicken und die Adresse von dort kopieren.",
              1: () =>
                "Geben Sie Ihre Wallet-Adresse in den Crypto Tax Calculator ein",
              2: () =>
                "Geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    tonpay: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können entweder Ihre bouncefähige, nicht bouncefähige, TON DNS- oder Rohformatadresse eingeben und wir übersetzen sie für Sie in das Rohformat.",
            },
            title: () => "Adresstypen",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Suchen und kopieren Sie Ihre TON-Wallet-Adresse in der Tonpay-Telegramm-App. Sie können Ihre Wallet-Adresse anzeigen, indem Sie /start eingeben und die Option „Finanzen“ und dann „Wallet“ auswählen.",
              1: () =>
                "Geben Sie Ihre Wallet-Adresse in den Crypto Tax Calculator ein",
              2: () =>
                "Geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    tradeSatoshi: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei TradeSatoshi an",
              1: () =>
                "Navigieren Sie zum Bestellverlauf https://tradesatoshi.com/Orders",
              2: () =>
                "Klicken Sie auf die Schaltfläche „Exportieren“, bevor Sie CSV in den Crypto Tax Calculator hochladen",
            },
          },
        },
      },
    },
    "trader-joe": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Avalanche C-Chain-Wallet-Adresse, die Sie für den Handel über Trader Joe verwendet haben.",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    trezor: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen das Tickersymbol für jede Währung hinzufügen, die Sie hochladen möchten",
              1: () =>
                "Bitte stellen Sie sicher, dass Datum und Uhrzeit im Format „TT/MM/JJJJ HH:mm:ss“, „JJJJ-MM-TT HH:mm:ss“ oder „M/T/JJJJ h:mm:ss A“ vorliegen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sie können die Schritte hier befolgen, um Ihre CSV-Daten zu exportieren (https://wiki.trezor.io/User_manual:Transaction_details).",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Verbinden Sie Ihren Trezor mit Ihrem Gerät",
              1: () => "Besuchen Sie wallet.trezor.io",
            },
          },
          1: {
            items: {
              0: () =>
                "Wählen Sie das entsprechende Konto aus und navigieren Sie zur Registerkarte „Basis“",
              1: () => "Klicken Sie auf XPUBs anzeigen",
              2: () => "Kopieren Sie die xPub-Adresse",
              3: () => "In CTC einfügen",
            },
            title: () =>
              "Für Bitcoin-, Bitcoin Cash-, Dash-, Dogecoin-, Litecoin- und Zcash-Wallets",
          },
          2: {
            items: {
              0: () =>
                "Wählen Sie die entsprechende Münze aus und zeigen Sie die öffentlichen Wallet-Adressen unter Transaktionen an.",
              1: () => "Kopieren Sie jede öffentliche Adresse",
              2: () => "In CTC einfügen",
            },
            title: () => "Für alle anderen Münzen",
          },
        },
      },
    },
    tron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Zusätzliche Wallet hinzufügen“ klicken.",
              1: () =>
                "Derzeit unterstützen wir nur TRX- und TRC10-Tron-Token, alle anderen Transaktionen müssen manuell in den Crypto Tax Calculator importiert werden.",
              2: () =>
                "Der Import umfasst nur Übertragungen. Wir behandeln derzeit keine On-Chain-Interaktionen wie die Bereitstellung von Smart Contracts.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () => "Kopieren Sie Ihre öffentliche Tron-Wallet-Adresse",
              2: () =>
                "Wählen Sie in CTC die entsprechende Blockchain aus der Dropdown-Liste",
              3: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    trustwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Öffnen Sie die Trust Wallet-App oder Browsererweiterung",
              1: () =>
                "Wählen Sie bei Bedarf unter Einstellungen Ihr Wallet aus",
              2: () =>
                "Wählen Sie auf der Registerkarte Wallet die Option „Empfangen“ und wählen Sie das Token aus.",
              3: () => "Kopieren Sie die öffentliche Adresse ",
              4: () =>
                "Fügen Sie es in CTC ein und drücken Sie „Synchronisieren“, um Ihre Transaktionen zu importieren",
            },
          },
        },
      },
    },
    unichain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Wir unterstützen jetzt ERC-1155-Token, können jedoch Ihre aktuellen Guthaben für diese Token nicht importieren. Dies kann zu Abweichungen zwischen dem aus der Quell-API importierten Guthaben und dem von der App berechneten Guthaben führen.",
            },
            title: () => "ERC-1155-Token",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Unichain-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    uniswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Suchen Sie die Ethereum-Blockchain-Wallet-Adresse, die Sie für den Handel über Uniswap verwendet haben.",
              1: () =>
                "Geben Sie diese Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    uphold: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Weitere detaillierte Schritte für die verschiedenen Uphold-Schnittstellen finden Sie hier: https://support.uphold.com/hc/en-us/articles/360046137012-How-can-I-download-my-transaction-history-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich bei Ihrem Uphold-Konto an und gehen Sie zum Wallet-Dashboard",
              1: () =>
                "Klicken Sie links auf das Symbol „Aktivität“, um zur Aktivitätsseite zu gelangen (https://wallet.uphold.com/dashboard/activity).",
              2: () =>
                "Klicken Sie im linken Bereich oben rechts auf das Dokumentsymbol",
              3: () =>
                "Klicken Sie auf die grüne Schaltfläche „Bericht erstellen“.",
              4: () =>
                "Wenige Minuten später erhalten Sie eine E-Mail mit der Möglichkeit, Ihren Transaktionsverlauf im CSV-Format herunterzuladen",
              5: () =>
                "Laden Sie die heruntergeladene CSV-Datei hier zum Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    valr: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem VALR-Konto an",
              1: () =>
                "Laden Sie Ihre CSV-Datei mit dem Transaktionsverlauf von VALR herunter",
              2: () =>
                "Laden Sie diese CSV-Datei hier in den Crypto Tax Calculator hoch",
            },
          },
        },
      },
    },
    vechain: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu „https://explore.vechain.org/download?address=INSERT_ADDRESS“, ersetzen Sie jedoch INSERT_ADDRESS durch Ihre öffentliche Wallet-Adresse.",
              1: () =>
                "Wählen Sie den gewünschten Zeitraum für Ihren Transaktionsverlauf aus.",
              2: () => "Bestätigen Sie, dass Sie ein Mensch sind",
              3: () => "Klicken Sie auf „Exportieren“.",
              4: () => "Laden Sie hier die CSV-Datei hoch",
            },
          },
        },
      },
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Öffnen Sie Ihr " + d.exchangeDisplayName + " -Wallet",
              1: () => "Kopieren Sie Ihre öffentliche Vechain-Wallet-Adresse",
              2: () =>
                "Wählen Sie in CTC die entsprechende Blockchain aus der Dropdown-Liste",
              3: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    velas: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
          1: {
            items: {
              0: () =>
                "Bei einigen Geldbörsen können interne Transaktionen fehlen.",
            },
            title: () => "Fehlende interne Transaktionen",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Velas-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    virgocx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Melden Sie sich hier bei Ihrem VirgoCX-Konto an (https://virgocx.ca/login).",
              1: () =>
                "Klicken Sie oben rechts unter Ihrem Namen auf „eDocument“.",
              2: () =>
                "Klicken Sie in der linken Navigationsleiste auf „Datenexport“.",
              3: () =>
                "Wählen Sie „Transaktionsdaten“ als Berichtstyp und „Kryptowährung“ als Vermögenswert.",
              4: () =>
                "Wählen Sie unter „Datum“ den Bereich aus, der Ihren gesamten Transaktionsverlauf mit VirgoCX umfasst. Möglicherweise müssen Sie mehrere Dateien herunterladen, um eine vollständige Abdeckung sicherzustellen.",
              5: () => "Fahren Sie mit dem Exportieren der CSV-Dateien fort.",
              6: () =>
                "Schließen Sie den gleichen Vorgang ab, wählen Sie diesmal jedoch „Fiat-Währung“ als Vermögenswert aus.",
              7: () =>
                "Wiederholen Sie alle oben genannten Schritte, aber wählen Sie jetzt „Handelsdaten“ statt „Transaktionsdaten“.",
              8: () =>
                "Laden Sie die CSV-Dateien hier in den Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    wealth99: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem Wealth99-Börsenkonto an",
              1: () =>
                "Gehen Sie im linken Menü zu „Einstellungen“ und wählen Sie die Registerkarte „Sicherheit“",
              2: () => "Klicken Sie auf „API-Schlüssel erstellen“.",
              3: () =>
                "Stellen Sie sicher, dass Sie „Informationen lesen aktivieren“ aktiviert haben.",
              4: () =>
                "Kopieren Sie den „API-Schlüssel“ und das „API-Geheimnis“, geben Sie beide hier in den Crypto Tax Calculator ein und klicken Sie auf „API synchronisieren“, um Ihre Daten zu synchronisieren",
            },
          },
        },
      },
    },
    xdai: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
              1: () =>
                "Da beim Konvertieren von DAI in XDAI aus dem Ethereum-Netzwerk keine Daten zum Empfang der xDai über die xDai-Brücke verfügbar sind, müssen Sie diese Transaktionen manuell als „Käufe“ in den Crypto Tax Calculator eingeben.",
              2: () =>
                "Da beim Überbrücken vom Ethereum-Netzwerk keine Daten zum Empfang von Token auf der xDai-Kette über die xDai OmniBridge verfügbar sind, müssen Sie diese Transaktionen manuell als „Käufe“ in den Crypto Tax Calculator eingeben.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Gnosis-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    xverse_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Klicken Sie auf „Wallet verbinden“ und folgen Sie den Anweisungen.",
            },
          },
        },
      },
    },
    yobit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Die Yobit-API ruft nur den Transaktionsverlauf von vor einer Woche ab",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Wählen Sie unter Ihrem Kontonamen API-Schlüssel aus",
              1: () =>
                "Erstellen Sie einen neuen API-Schlüssel für den Crypto Tax Calculator",
              2: () =>
                "Fügen Sie den Schlüssel und den geheimen Schlüssel in den Crypto Tax Calculator ein",
            },
          },
        },
      },
    },
    yoroi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Alternativ können Sie Ihre Yoroi-Wallet-Transaktionen direkt über die Blockchain importieren, indem Sie die Option „Synchronisieren über API“ verwenden. Dadurch werden alle Transaktionen und Staking-Belohnungen für Ihr Yoroi-Wallet abgerufen.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Yoroi an",
              1: () => "Navigieren Sie zur Transaktionsübersichtsseite",
              2: () => "Oben rechts sehen Sie das Exportsymbol",
              3: () =>
                "Sie können diese CSV jetzt in den Crypto Tax Calculator importieren",
            },
          },
        },
      },
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Geben Sie nur EINE Adresse aus jeder Brieftasche ein. Wir führen eine Suche durch und ziehen die Daten für alle anderen Adressen in der Brieftasche automatisch ein.",
              1: () =>
                "Das Importieren mehrerer Adressen aus derselben Brieftasche führt zu doppelten Transaktionen.",
              2: () =>
                "Derzeit unterstützen wir nicht den Erhalt des vollständigen Transaktionsverlaufs von Wallets, bei denen jede einzelne Adresse mehr als 10 Transaktionen aufweist. Wir arbeiten daran, zusätzliche Unterstützung für solche Wallets bereitzustellen.",
            },
          },
          1: {
            items: {
              0: () =>
                "Wenn Sie eine Adresse importieren, die mit „addr1“ beginnt, ändern wir sie automatisch in die Adresse des Kontoinhabers, die mit „stake1“ beginnt.",
              1: () =>
                "Dadurch können wir alle anderen Adressen, die Sie möglicherweise in derselben Brieftasche erstellen, importieren und Ihre Staking-Belohnungen erfassen.",
            },
            title: () => "Warum steht meine Adresse auf Stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Öffnen Sie Ihr Yoroi-Wallet und klicken Sie auf Wallet > Empfangen",
              1: () =>
                "Klicken Sie auf „Hat Guthaben“ und kopieren Sie dann eine der öffentlichen Adressen, die mit „addr1…“ beginnt.",
              2: () =>
                "Fügen Sie Ihre öffentliche Wallet-Adresse in CTC ein und klicken Sie auf „Wallet importieren“.",
            },
          },
        },
      },
    },
    zebpay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Melden Sie sich bei Ihrem ZebPay-Konto an.",
              1: () => "Klicken Sie oben rechts auf das Profilsymbol.",
              2: () => "Wählen Sie „Anweisungen“ aus.",
              3: () => "Wählen Sie Konsolidierter Kontoauszug.",
              4: () =>
                "Legen Sie das Start- und Enddatum fest und klicken Sie dann auf „Senden“.",
              5: () =>
                "Sie erhalten den Bericht per E-Mail und können ihn dort herunterladen.",
              6: () => "Laden Sie die Dateien zum Crypto Tax Calculator hoch.",
            },
          },
        },
      },
    },
    zeroExProtocol: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sie müssen die CSV-Dateien für jeden Monat importieren, in dem Sie gehandelt haben",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigieren Sie zu Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Geben Sie Ihre öffentliche Wallet-Adresse in die Suchleiste oben auf der Seite ein und klicken Sie auf das Suchsymbol",
              2: () =>
                "Wählen Sie im Datumsbereich den Monat aus, in dem Sie gehandelt haben",
              3: () => "Behalten Sie die anderen Standardeinstellungen bei",
              4: () => "Klicken Sie auf „Verlauf abrufen“.",
              5: () =>
                "Klicken Sie nach Abschluss auf „Trades exportieren“ und wählen Sie „Standard V2 (CSV)“.",
              6: () =>
                "Im Crypto Tax Calculator können Sie jetzt Ihre Wallet-Adresse eingeben und diese CSV hochladen",
            },
          },
        },
      },
    },
    zetachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre ZetaChain-Wallet-Adresse (beginnend mit 0x) in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    zksync: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Beachten Sie, dass zkSync Era-Transaktionen erst importiert werden, wenn sie von der Blockchain verifiziert wurden. Dies dauert normalerweise 30–60 Minuten, kann sich aber auch auf 24 Stunden oder länger erstrecken. Dies kann auch zu vorübergehenden Saldenabweichungen führen, die nach der Überprüfung der Transaktionen behoben werden.",
            },
            title: () => "Transaktionsverzögerung in der zkSync-Ära",
          },
          1: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre zkSync Era-Wallet-Adresse (beginnend mit 0x) in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
    zora: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Geben Sie Ihre Zora-Wallet-Adresse (beginnend mit 0x) in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
            },
          },
        },
      },
    },
  },
  importInstructionsNotice: {
    binance: () =>
      "Binance hat in seiner Ankündigung vom 06.08.2021 mitgeteilt, dass API-Zugriff nur für Konten bereitgestellt wird, deren Identitätsprüfung abgeschlossen ist. Wenn Ihre API-Synchronisierung nicht funktioniert, empfehlen wir Ihnen, die KYC-Identitätsprüfung (Zwischenprüfung) von Binance abzuschließen, bevor Sie es erneut versuchen.",
    coinsmart: () =>
      "Coinsmart hat den Betrieb ab Oktober 2023 eingestellt und alle Benutzerkonten wurden zu Bitbuy migriert. Siehe Ankündigung: 'https://www.coinsmart.com/blog/important-notice-changes-to-your-coinsmart-account/'",
  },
};
