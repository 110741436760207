import { post } from "~/services/core";
import { type ObjectIdString, type SyncReturnPayload } from "~/types/index";

/**
 * Triggers a sync for a given oauth key
 * @param id The unique _id of the oauth key to refresh
 * @param isHardSync Whether to perform a hard sync
 */
export function syncOAuthKey(id: ObjectIdString, isHardSync: boolean) {
  const link = `/oauth/${id}/sync`;
  return post<SyncReturnPayload>(link, {
    isHardSync,
  });
}
