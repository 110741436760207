import { type SyncErrorIssue, SyncStatusPlatform } from "@ctc/types";
import { Check, ErrorOutline } from "@mui/icons-material";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import { useCallback } from "react";
import styled from "styled-components/macro";

import { captureAnalytics } from "~/analytics/posthog";
import { importAccountKey } from "~/components/imports/AnalyticsHelpers";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type ImportType } from "~/types/enums";

const ImportIndicatorChip = styled(({ color, backgroundColor, ...props }) => (
  <Box {...props} />
))`
  && {
    color: ${({ color }) => color};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 0.75rem;
    padding: 0.125rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.125rem;
  }
`;

export function useGetTooltipTitle(
  tooltipTitle: string | SyncErrorIssue | undefined,
  exchangeName: string,
): string {
  const lang = useLang();

  if (!tooltipTitle) return "";

  if (typeof tooltipTitle === "string") return tooltipTitle;

  const syncErrorReason =
    lang.syncErrorReasons[
      tooltipTitle.reason as keyof typeof lang.syncErrorReasons
    ];

  if (!syncErrorReason) return "";

  if (typeof syncErrorReason.message === "function")
    return `${syncErrorReason.message({ exchangeName })} ${syncErrorReason.ctaType}`;

  return `${syncErrorReason.message} ${syncErrorReason.ctaType}`;
}

export const ImportIndicator = ({
  syncStatus,
  importType,
  useWarningIconForBtc = false,
  isExistingFile = false,
  tooltipTitle,
  exchangeName,
}: {
  syncStatus: SyncStatusPlatform;
  importType: ImportType.API | ImportType.CSV;
  exchangeName: string;
  useWarningIconForBtc?: boolean;
  isExistingFile?: boolean;
  tooltipTitle?: string | SyncErrorIssue;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const title = useGetTooltipTitle(tooltipTitle, exchangeName);

  const handleMouseOver = useCallback(
    debounce(() => {
      captureAnalytics(importAccountKey("indicator-tooltip-hover"));
    }, 10000),
    [],
  );

  const getProps = () => {
    switch (syncStatus) {
      case SyncStatusPlatform.Success:
        if (useWarningIconForBtc) {
          return {
            color: tokens.text.warning,
            backgroundColor: tokens.background.accent.neutral.medium,
            icon: <ErrorOutline sx={{ width: "1rem" }} />,
          };
        }
        if (isExistingFile) {
          return {
            color: tokens.text.default,
            backgroundColor: tokens.background.neutral.pressed,
            icon: <ErrorOutline sx={{ width: "1rem" }} />,
          };
        }
        return {
          color: tokens.text.success,
          backgroundColor: tokens.background.success.default,
          icon: <Check sx={{ width: "1rem" }} />,
        };
      case SyncStatusPlatform.Fail:
        return {
          color: tokens.text.danger,
          backgroundColor: tokens.background.danger.default,
          icon: <ErrorOutline sx={{ width: "1rem" }} />,
        };
      default:
        return {
          color: tokens.text.warning,
          backgroundColor: tokens.background.warning.default,
          icon: (
            // to match icons total width: 1rem, total height: 1.5rem.
            // need padding to match size of icons as they don't take up full width unlike the spinner
            <CircularProgress
              size="0.8rem"
              thickness={4}
              sx={{ color: tokens.icon.warning, my: "0.35rem", mx: "0.1rem" }}
            />
          ),
        };
    }
  };

  const props = getProps();

  return (
    <ImportIndicatorChip
      color={props.color}
      backgroundColor={props.backgroundColor}
    >
      <Tooltip title={title} onMouseOver={handleMouseOver}>
        {props.icon}
      </Tooltip>
      <Typography fontSize="0.75rem" fontWeight={500} color="inherit">
        {isExistingFile ? lang.duplicate : lang.imports.chips[`${importType}`]}
      </Typography>
    </ImportIndicatorChip>
  );
};

type ChipColorType = {
  backgroundColor: string;
  color: string;
};

function useChipColor(status: SyncStatusPlatform | null): ChipColorType {
  const { tokens } = useDesign();
  switch (status) {
    case SyncStatusPlatform.Pending:
    case SyncStatusPlatform.Queued:
      return {
        backgroundColor: tokens.background.warning.default,
        color: tokens.text.warning,
      };
    case SyncStatusPlatform.Fail:
      return {
        backgroundColor: tokens.background.danger.default,
        color: tokens.text.danger,
      };
    case SyncStatusPlatform.Success:
      return {
        backgroundColor: tokens.background.success.default,
        color: tokens.text.success,
      };
    default:
      return {
        backgroundColor: tokens.background.neutral.default,
        color: tokens.text.default,
      };
  }
}
