import { Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useLang } from "~/redux/lang";
import {
  reconciliationIssueQueryKeys,
  useAllEvenHiddenReconciliationQuery,
} from "~/state/reconciliation";
import { useSetUserNotificationMutation } from "~/state/settings";
import { NotificationType } from "~/types/enums";

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface IssuesWhenLockingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onIgnoreIssuesAndLock: () => void;
}

export function IssuesWhenLockingModal({
  isOpen,
  onClose,
  onIgnoreIssuesAndLock,
}: IssuesWhenLockingModalProps) {
  const lang = useLang();
  const navigate = useNavigate();
  const mutation = useSetUserNotificationMutation({ authUser: false });
  const queryClient = useQueryClient();
  const handleViewIssues = async () => {
    // we need to reload the data, but dont wait for it
    (async () => {
      //once the user clicks to see the issues, they will see unmatched transfer issues forever more
      await mutation.mutateAsync({
        [NotificationType.ShowUnmatchedTransfers]: true,
      });
      // because we need to make sure the unmatched transfer issues are shown
      // probably not required, since we already have them in the FE, but just to be safe
      await queryClient.invalidateQueries({
        queryKey: reconciliationIssueQueryKeys.all(),
      });
    })();
    onClose();
    navigate("/reconciliation");
  };

  // if the user had no issues before, but now has issues,
  // show them a warning explaining why we are showing them new issues

  const allRecData = useAllEvenHiddenReconciliationQuery().data;
  const hasUnmatchedTransferIssues =
    (allRecData?.unmatchedTransfer?.issueCount ?? 0) > 0;

  return (
    <GeneralDialog
      isOpen={isOpen}
      onCancel={onIgnoreIssuesAndLock}
      showCancel
      handleAction={handleViewIssues}
      handleClose={onClose} // we actually want to do nothing, just close the modal
      title={lang.settings.lockPeriod.issuesModal.title}
      maxWidth="sm"
      actionText={lang.settings.lockPeriod.issuesModal.viewIssues}
      cancelText={lang.settings.lockPeriod.issuesModal.ignoreAndLock}
    >
      <ContentWrapper>
        <Typography>
          {lang.settings.lockPeriod.issuesModal.description}
        </Typography>
        {hasUnmatchedTransferIssues ? (
          <Typography fontWeight="bold" sx={{ marginTop: 1 }}>
            {lang.settings.lockPeriod.issuesModal.newIssueExplainer}
          </Typography>
        ) : null}
        <Typography color="warning.main" sx={{ marginTop: 1 }}>
          {lang.settings.lockPeriod.issuesModal.warning}
        </Typography>
      </ContentWrapper>
    </GeneralDialog>
  );
}
