import { type GridRowId } from "@mui/x-data-grid";
import { createContext, type PropsWithChildren, useState } from "react";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import { TaxLossHarvestingModal } from "~/components/report/tax-loss-harvesting-modal/TaxLossHarvestingModal";

export const TLH_TABLE_OPEN = "tlhModalOpen";
type TaxLossHarvestingContextType = {
  isEditing?: GridRowId;
  updateIsEditing: (newIsEditing?: GridRowId) => void;
  isModalOpen: boolean;
  updateIsModalOpen: (newLoadingStatus: boolean) => void;
  lossesToHarvest: Set<string>;
  updateLossesToHarvest: (newLossesToHarvest: Set<string>) => void;
  showZeroPrice: boolean;
  updateShowZeroPrice: (newShowZeroPriceOnly: boolean) => void;
  modifiedPrices: Map<string, number>;
  updateModifiedPrices: (newModifiedPriceId: Map<string, number>) => void;
};

export const TaxLossHarvestingContext =
  createContext<TaxLossHarvestingContextType | null>(null);

export const TaxLossHarvestingContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [isEditing, setIsEditing] = useState<GridRowId | undefined>();
  const [isModalOpen, setIsModalOpen] = useQueryParam(
    TLH_TABLE_OPEN,
    withDefault(BooleanParam, false),
  );
  const [showZeroPrice, setShowZeroPrice] = useState<boolean>(true);
  const [modifiedPriceIds, setModifiedPriceIds] = useState<Map<string, number>>(
    new Map(),
  );
  const [lossesToHarvest, setLossesToHarvest] = useState<Set<string>>(
    new Set(),
  );

  return (
    <TaxLossHarvestingContext.Provider
      value={{
        isEditing,
        updateIsEditing: setIsEditing,
        isModalOpen,
        updateIsModalOpen: setIsModalOpen,
        lossesToHarvest,
        updateLossesToHarvest: setLossesToHarvest,
        showZeroPrice,
        updateShowZeroPrice: setShowZeroPrice,
        modifiedPrices: modifiedPriceIds,
        updateModifiedPrices: setModifiedPriceIds,
      }}
    >
      {isModalOpen ? <TaxLossHarvestingModal /> : null}
      {children}
    </TaxLossHarvestingContext.Provider>
  );
};
