import { Dialog, DialogContent, useMediaQuery } from "@mui/material";

import { DynamicPageTitle } from "~/components/PageTitle";
import { DesktopSettings } from "~/components/settings-modal/DesktopSettings";
import { MobileSettings } from "~/components/settings-modal/MobileSettings";
import { type SettingsTabs } from "~/components/settings-modal/views/enums";
import { devices } from "~/components/ui/theme/legacy";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";

export function SettingsModal({
  open,
  setOpen,
  active,
  setActive,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  active: SettingsTabs | undefined;
  setActive: (active: SettingsTabs) => void;
}) {
  const user = useUser();
  const isWide = useMediaQuery(devices.tablet);
  const lang = useLang();
  if (!user) return null;

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullScreen={!isWide}
      fullWidth
      maxWidth="lg"
    >
      {active ? (
        <DynamicPageTitle
          title={lang.settings.pageTitle({ tab: lang.settings.tabs[active] })}
        />
      ) : null}
      <DialogContent sx={{ p: "0", overflow: "hidden" }}>
        {isWide ? (
          <DesktopSettings
            setOpen={setOpen}
            active={active}
            setActive={setActive}
          />
        ) : (
          <MobileSettings
            setOpen={setOpen}
            active={active}
            setActive={setActive}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
