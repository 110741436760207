import { useMemo, useState } from "react";

import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { getOptionsFromCurrencies } from "~/components/transactions/command-palette/helpers/getOptionsFromCurrencies";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { Confirmation } from "~/components/transactions/command-palette/views/change-currency/Confirmation";
import { CurrencyTopSection } from "~/components/transactions/command-palette/views/change-currency/CurrencyTopSection";
import { type BulkEditCurrencyOptions } from "~/components/transactions/command-palette/views/change-currency/types";
import { currencyGroupSort } from "~/components/transactions/CurrencyComboBox";
import { useLang } from "~/redux/lang";
import { useCurrencySearchResults } from "~/state/currencies";
import { type CurrencyIdentifier } from "~/types/index";

export const ToCurrency = ({
  fromCurrency,
  bulkEditOption,
}: {
  fromCurrency: CurrencyIdentifier;
  bulkEditOption: BulkEditCurrencyOptions;
}) => {
  const { push } = useNavController();
  const lang = useLang();
  const [input, setInput] = useState("");

  const currenciesSearchQuery = useCurrencySearchResults(input);
  const {
    data: { currencies },
    isFetching,
    isSearching,
  } = currenciesSearchQuery;

  const optionsResolved = useMemo(() => {
    return currencies.sort(currencyGroupSort);
  }, [currencies]);

  // get the currencies
  const options = getOptionsFromCurrencies(optionsResolved, fromCurrency.id);

  return (
    <CommandPaletteMenu
      onSearch={setInput}
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={bulkEditOption} />
          <CurrencyTopSection fromCurrency={fromCurrency} />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.changeTo}
      options={options}
      loading={isFetching || isSearching}
      onSelection={({ currency }) => {
        push(
          <Confirmation
            fromCurrency={fromCurrency}
            toCurrency={currency}
            bulkEditOption={bulkEditOption}
          />,
        );
      }}
    />
  );
};
