import { useCallback, useEffect } from "react";

import { LocalStorageKey } from "~/constants/enums";

type FiberNode = {
  _debugOwner: FiberNode;
  _debugSource: null | {
    columnNumber?: number;
    fileName: string;
    lineNumber: number;
  };
};

/**
 * Checks for react dev
 * @param element HtmlElement to find the fiber node for
 * @returns
 */
export function getFiberNodeForElement(
  element: HTMLElement,
): FiberNode | undefined {
  if ("__REACT_DEVTOOLS_GLOBAL_HOOK__" in window) {
    const { renderers } = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

    for (const renderer of renderers.values()) {
      try {
        const fiber = renderer.findFiberByHostInstance(element);
        if (fiber) {
          return fiber;
        }
      } catch (e) {
        console.error("Failed to find fiber for element", e);
      }
    }
  }
}

function findSourcePath(instance: FiberNode): {
  columnNumber: number;
  fileName: string;
  lineNumber: number;
} {
  if (!instance._debugSource) {
    return findSourcePath(instance._debugOwner);
  }
  const {
    _debugSource: { columnNumber = 1, fileName, lineNumber = 1 },
  } = instance;
  return { columnNumber, fileName, lineNumber };
}
export function VsCodeClick() {
  const onClick = useCallback(function handleClick(event: MouseEvent) {
    if (!event.altKey) {
      return;
    }
    const target = event.target;
    if (target instanceof HTMLElement) {
      const fiberNode = getFiberNodeForElement(target);
      if (!fiberNode) {
        console.error("Couldn't find fiber node for element");
        return;
      }
      const { columnNumber, fileName, lineNumber } = findSourcePath(fiberNode);
      // sorry elliot

      const storedEditor =
        localStorage.getItem(LocalStorageKey.Editor) ?? "vscode";

      const url = getUrl(storedEditor)({ fileName, lineNumber, columnNumber });

      event.preventDefault();
      event.stopPropagation();
      window.location.assign(url);
    }
  }, []);

  useEffect(
    function addEventListenersToWindow() {
      window.addEventListener("click", onClick, { capture: true });
      return () => {
        window.removeEventListener("click", onClick, { capture: true });
      };
    },
    [onClick],
  );

  return null;
}

function getUrl(
  editor: string,
): ({
  fileName,
  lineNumber,
  columnNumber,
}: {
  fileName: string;
  lineNumber: number;
  columnNumber: number;
}) => string {
  switch (editor) {
    case "vscode":
      return ({ fileName, lineNumber, columnNumber }) =>
        `vscode://file/${fileName}:${lineNumber}:${columnNumber}`;
    case "vscode-insiders":
      return ({ fileName, lineNumber, columnNumber }) =>
        `vscode-insiders://file/${fileName}:${lineNumber}:${columnNumber}`;
    case "cursor":
      return ({ fileName, lineNumber, columnNumber }) =>
        `cursor://file/${fileName}:${lineNumber}:${columnNumber}`;
    case "webstorm":
      return ({ fileName, lineNumber }) =>
        `webstorm://open?file=${fileName}&line=${lineNumber}`;
    default:
      return ({ fileName, lineNumber, columnNumber }) =>
        `${editor}://file/${fileName}:${lineNumber}:${columnNumber}`;
  }
}
