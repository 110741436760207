import { type LocalCurrency, type TaxRule, Trade } from "@ctc/types";
import {
  ChevronRight,
  ExpandMore,
  Help,
  OpenInNew,
  SwapHoriz,
} from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Divider,
  Link,
  Popover,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import type * as React from "react";
import { useState } from "react";
import styled from "styled-components/macro";

import { displayTimestamp } from "~/components/report/helpers/displayTimestamp";
import { CurrencyCellContent } from "~/components/transactions/CurrencyCell";
import { DisplayValueCell } from "~/components/transactions/DisplayValueCell";
import { ContextIdType } from "~/components/transactions/filter-bar/enums";
import { getViewInContextPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { Cell } from "~/components/transactions/styles";
import { TableRow } from "~/components/transactions/TransactionRow";
import { FrameInspect } from "~/components/ui/Icons";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { taxRuleLinks } from "~/constants/constants";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { TradeInfo } from "~/lib/tradeTypeDefinitions";
import { isAdjustedPrice, isWashSale } from "~/lib/washSaleRule";
import {
  useConsidersWashSales,
  useLocalCurrency,
  useLocalCurrencySymbol,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { isInTrade, isOutTrade } from "~/services/transactions";
import { Align, PriceSourceType, TradeDirection } from "~/types/enums";
import { type BreakdownRow, type PriceMetadata } from "~/types/index";

export function ActionBreakdownTable({ data }: { data: BreakdownRow[] }) {
  return (
    <TableContainer>
      <StyledTable className="cost-analysis">
        <BreakdownTableHead
          isAdjusted={data.some((row) => isAdjustedPrice(row.moreInfo))}
        />
        {data.map((parentRow, index) => (
          <BreakdownRowGroup key={index} row={parentRow} />
        ))}
      </StyledTable>
    </TableContainer>
  );
}

function BreakdownTableHead({ isAdjusted }: { isAdjusted: boolean }) {
  const lang = useLang().txTable.expansionPanel;
  const localCurrency = useLocalCurrency();
  const localCurrencySymbol = useLocalCurrencySymbol();
  return (
    <TableHead>
      <TableRow disablePointer>
        <HeadingCell align={Align.Left} />
        <HeadingCell align={Align.Left} style={{ paddingLeft: "0.875rem" }}>
          {lang.type}
        </HeadingCell>
        <HeadingCell align={Align.Left}>{lang.currency}</HeadingCell>
        <HeadingCell align={Align.Left}>{lang.date}</HeadingCell>
        <HeadingCell align={Align.Left}>{lang.time}</HeadingCell>
        <HeadingCell align={Align.Right}>{lang.amount}</HeadingCell>
        <HeadingCell align={Align.Right}>
          <PriceHeader
            isAdjusted={isAdjusted}
            localCurrency={localCurrency}
            localCurrencySymbol={localCurrencySymbol}
          />
        </HeadingCell>
        <HeadingCell align={Align.Right}>
          {localCurrency
            ? `${lang.value} (${localCurrencySymbol})`
            : lang.value}
        </HeadingCell>
        <HeadingCell align={Align.Right}>
          {localCurrency ? `${lang.cost} (${localCurrencySymbol})` : lang.cost}
        </HeadingCell>
        <HeadingCell align={Align.Right}>
          {localCurrency ? `${lang.fee} (${localCurrencySymbol})` : lang.fee}
        </HeadingCell>
        <HeadingCell align={Align.Right}>
          {localCurrency
            ? `${lang.proceeds} (${localCurrencySymbol})`
            : lang.proceeds}
        </HeadingCell>
        <HeadingCell align={Align.Right}>
          {localCurrency ? `${lang.gain} (${localCurrencySymbol})` : lang.gain}
        </HeadingCell>
        <HeadingCell align={Align.Right} style={{ paddingRight: "1.625rem" }} />
      </TableRow>
    </TableHead>
  );
}

function HeadingCell({
  children,
  colSpan,
  align = Align.Left,
  style = {},
}: {
  children?: React.ReactNode;
  colSpan?: number;
  align?: Align;
  style?: React.CSSProperties;
}) {
  const { tokens } = useDesign();
  return (
    <Cell
      colSpan={colSpan}
      align={align}
      sx={{
        padding: "0.375rem",
        fontSize: "0.75rem",
      }}
      style={{
        background: tokens.elevation.default,
        borderBottom: `1px solid ${tokens.border.neutral.default}`,
        ...style,
        color: tokens.text.low,
      }}
      data-uncensored="true"
    >
      {children}
    </Cell>
  );
}

function PriceHeader({
  isAdjusted,
  localCurrency,
  localCurrencySymbol,
}: {
  isAdjusted: boolean;
  localCurrency: LocalCurrency | undefined;
  localCurrencySymbol: string | undefined;
}) {
  const lang = useLang();
  const considersWashSales = useConsidersWashSales();
  if (isAdjusted) {
    const title = `${lang.adjustedPrice.description}${
      considersWashSales ? lang.adjustedPrice.considersWashSalesDescription : ""
    }.`;
    return (
      <Tooltip title={title}>
        <span data-uncensored="true">
          {localCurrency
            ? `${lang.txTable.expansionPanel.adjPrice} (${localCurrencySymbol})`
            : lang.txTable.expansionPanel.adjPrice}
          <Help
            style={{
              fontSize: "1rem",
              position: "relative",
              top: "3px",
              left: "3px",
            }}
          />
        </span>
      </Tooltip>
    );
  }
  return (
    <span data-uncensored="true">
      {localCurrency
        ? `${lang.txTable.expansionPanel.price} (${localCurrencySymbol})`
        : lang.txTable.expansionPanel.price}
    </span>
  );
}

function BreakdownRowGroup({ row }: { row: BreakdownRow }) {
  const { children } = row;
  const [state, setState] = useState<BreakdownRowStates | null>(
    children?.length ? BreakdownRowStates.CLOSED : null,
  );

  const onClick = () => {
    if (state === null) {
      return;
    }
    if (state === BreakdownRowStates.OPEN) {
      setState(BreakdownRowStates.CLOSED);
      return;
    }
    if (state === BreakdownRowStates.CLOSED) {
      setState(BreakdownRowStates.OPEN);
      return;
    }
  };

  const getChildState = (index: number, length: number): BreakdownRowStates => {
    if (index === length - 1) return BreakdownRowStates.LAST_GROUPED;
    if (index === 0) return BreakdownRowStates.FIRST_GROUPED;
    return BreakdownRowStates.GROUPED;
  };

  return (
    <>
      <ActionBreakdownRow row={row} state={state} onClick={onClick} />
      {state === BreakdownRowStates.OPEN
        ? children?.map((r, i) => {
            const state = getChildState(i, children.length);
            return (
              <ActionBreakdownRow
                key={i}
                row={r}
                showViewInContext
                state={state}
                length={children.length}
                onClick={() => {}}
              />
            );
          })
        : null}
    </>
  );
}

const ActionBreakdownRow = ({
  row,
  state,
  length,
  onClick,
  showViewInContext,
}: {
  row: BreakdownRow;
  state: BreakdownRowStates | null;
  length?: number;
  onClick: () => void;
  showViewInContext?: boolean;
}) => {
  const { tokens } = useDesign();
  const {
    type,
    date: timestamp,
    currency,
    amount,
    price,
    value,
    cost,
    gain,
    fee,
    proceeds,
    moreInfo,
    source,
    priceMetadata,
    id,
    rolloverOriginTx,
  } = row;

  const timezone = useTimezone();
  const localCurrency = useLocalCurrency();
  const lang = useLang().txTable.expansionPanel;

  const date =
    type === Trade.ZeroCostBuy
      ? lang.na
      : displayTimestamp(timestamp, timezone, "ll");
  const time =
    type === Trade.ZeroCostBuy
      ? lang.na
      : displayTimestamp(timestamp, timezone, "LTS");

  // only show wash sales line-through on "buys"
  const isWashSaleIn =
    TradeInfo[type].direction === TradeDirection.In && isWashSale(moreInfo);

  const originTxId = rolloverOriginTx?.id || id;

  return (
    <TableBody>
      <StyledTableRow
        state={state}
        length={length}
        disablePointer
        disableBackground
        style={{ height: "1.75rem" }}
        onClick={onClick}
      >
        <Cell>
          <TextIconButton size="small" sx={{ padding: 0 }}>
            {state === BreakdownRowStates.OPEN ? (
              <ExpandMore />
            ) : state === BreakdownRowStates.CLOSED ? (
              <ChevronRight />
            ) : null}
          </TextIconButton>
        </Cell>
        <TradeCell
          trade={type}
          lineThrough={isWashSaleIn}
          viewInContextId={
            showViewInContext && originTxId ? originTxId : undefined
          }
        />

        <Cell align={Align.Right}>
          <Stack direction="row" spacing="0.25rem">
            <CurrencyCellContent
              currencyIdentifier={currency}
              showFullName
              lineThrough={isWashSaleIn}
            />

            {rolloverOriginTx?.currencyIdentifier ? (
              <Tooltip
                title={lang.rolloverFrom({
                  currency: rolloverOriginTx.currencyIdentifier.symbol,
                })}
              >
                <SwapHoriz
                  style={{
                    color: tokens.icon.default,
                    fontSize: "1rem",
                    marginLeft: "0.rem",
                  }}
                  fontSize="small"
                />
              </Tooltip>
            ) : null}
          </Stack>
        </Cell>

        <Cell
          align={Align.Left}
          style={{ color: tokens.text.default, fontWeight: 500 }}
          sx={{
            textDecoration: isWashSaleIn ? "line-through" : undefined,
            opacity: isWashSaleIn ? "50%" : undefined,
          }}
        >
          {date}
        </Cell>
        <Cell
          align={Align.Left}
          style={{ color: tokens.text.default, fontWeight: 500 }}
          sx={{
            textDecoration: isWashSaleIn ? "line-through" : undefined,
            opacity: isWashSaleIn ? "50%" : undefined,
          }}
        >
          {time}
        </Cell>
        <DisplayValueCell value={amount} lineThrough={isWashSaleIn} />
        <DisplayValueCell
          value={price}
          displayFiat={localCurrency}
          lineThrough={isWashSaleIn}
          tooltip={getPriceMetadata(lang, priceMetadata)}
        />
        <DisplayValueCell
          value={value}
          displayFiat={localCurrency}
          lineThrough={isWashSaleIn}
        />
        <DisplayValueCell
          value={cost || null}
          displayFiat={localCurrency}
          lineThrough={isWashSaleIn}
        />
        <DisplayValueCell
          value={fee || null}
          displayFiat={localCurrency}
          lineThrough={isWashSaleIn}
        />
        <DisplayValueCell
          value={proceeds || null}
          displayFiat={localCurrency}
          lineThrough={isWashSaleIn}
        />
        <DisplayValueCell
          value={gain || null}
          displayFiat={localCurrency}
          displayColor
          lineThrough={isWashSaleIn}
        />
        <AssociatedTaxEventsCell taxRule={moreInfo} source={source} />
      </StyledTableRow>
    </TableBody>
  );
};

function TradeCell({
  trade,
  lineThrough,
  viewInContextId,
}: {
  trade: Trade;
  lineThrough?: boolean;
  viewInContextId: string | undefined;
}) {
  const isEmbedded = useIsEmbedded();
  const { tokens } = useDesign();
  const lang = useLang();

  const handleViewInContext = () => {
    if (!viewInContextId) return;
    return getViewInContextPageLink(viewInContextId, ContextIdType.TxId);
  };

  return (
    <Cell align={Align.Left}>
      <Box
        mr="0.25rem"
        color={tokens.text.default}
        fontWeight={500}
        sx={{
          textDecoration: lineThrough ? "line-through" : undefined,
          opacity: lineThrough ? "50%" : undefined,
        }}
      >
        <TradeSign trade={trade} />
        {getActionTypeName({
          actionType: trade,
          lang,
        })}
        {viewInContextId && (
          <a
            href={handleViewInContext()}
            target={isEmbedded ? "_self" : "_blank"}
            rel="noopener noreferrer"
            style={{ marginLeft: "0.25rem", textDecoration: "none" }}
          >
            <TextIconButton size="small">
              <FrameInspect
                fontSize="small"
                sx={{
                  height: "0.75rem",
                  width: "0.75rem",
                  color: tokens.icon.low,
                }}
              />
            </TextIconButton>
          </a>
        )}
      </Box>
    </Cell>
  );
}

const TradeSign = ({ trade }: { trade: Trade }) => {
  const { tokens } = useDesign();
  const styleProps = {
    display: "inline-block",
    marginRight: "0.5rem",
    marginLeft: "0.125rem",
  };

  if (
    [Trade.IgnoreIn, Trade.IgnoreOut, Trade.Unknown].includes(trade)
  ) {
    return null;
  }
  if (isOutTrade(trade)) {
    return (
      <div style={{ color: tokens.icon.danger, ...styleProps }}>&ndash;</div>
    );
  }
  if (isInTrade(trade)) {
    return <div style={{ color: tokens.icon.success, ...styleProps }}>+</div>;
  }
  return null;
};

function AssociatedTaxEventsCell({
  taxRule,
  source,
}: {
  taxRule?: TaxRule;
  source?: string;
}) {
  const isEmbedded = useIsEmbedded();
  const { tokens } = useDesign();
  const lang = useLang();
  const popupState = usePopupState({
    variant: "popover",
    popupId: `popover-${source}`,
  });

  const link = taxRule ? taxRuleLinks[taxRule] : null;

  const taxRuleSection = taxRule ? (
    <Stack direction="column" gap="0.5rem">
      <Divider sx={{ borderColor: tokens.border.neutral.medium }} />
      {link ? (
        <Link
          target={isEmbedded ? "_self" : "_blank"}
          rel="noopener noreferrer"
          href={link}
          underline="hover"
          sx={{
            color: tokens.text.brand,
          }}
        >
          <Stack direction="row" gap="0.25rem">
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              color="inherit"
            >
              {`${lang.txTable.expansionPanel.moreInfoPopover.learnAbout} ${lang.taxRule[taxRule]}`}
            </Typography>
            <OpenInNew
              style={{
                color: "inherit",
                fontSize: "1rem",
              }}
            />
          </Stack>
        </Link>
      ) : (
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.brand}
        >
          {lang.taxRule[taxRule]}
        </Typography>
      )}
    </Stack>
  ) : null;

  return (
    <>
      <Cell
        align={Align.Right}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <TextIconButton
          sx={{ height: "1.25rem", width: "1.25rem" }}
          {...bindTrigger(popupState)}
        >
          <InfoOutlinedIcon
            sx={{
              color: taxRule ? tokens.text.brand : tokens.text.low,
              height: "0.75em",
              width: "0.75em",
            }}
          />
        </TextIconButton>
      </Cell>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: "right",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <PopoverContent>
          <Typography variant="Metropolis/Body/Regular">
            {lang.txTable.expansionPanel.moreInfoPopover.title}
          </Typography>
          {source ? (
            <Box>
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                color={tokens.text.low}
              >
                {lang.txTable.expansionPanel.moreInfoPopover.importSource}
              </Typography>
              <Typography variant="Metropolis/Caption/Medium/Regular">
                {source}
              </Typography>
            </Box>
          ) : null}

          {taxRule ? taxRuleSection : null}
        </PopoverContent>
      </Popover>
    </>
  );
}

const PopoverContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.tokens.elevation.default};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
`;

enum BreakdownRowStates {
  OPEN = "open",
  GROUPED = "grouped",
  FIRST_GROUPED = "firstGrouped",
  LAST_GROUPED = "lastGrouped",
  CLOSED = "closed",
}

const groupedRows = [
  BreakdownRowStates.LAST_GROUPED,
  BreakdownRowStates.FIRST_GROUPED,
  BreakdownRowStates.GROUPED,
];

const StyledTable = styled(
  ({
    state,
    length,
    ...props
  }: {
    state?: BreakdownRowStates;
    length?: number;
  } & React.ComponentProps<typeof Table>) => <Table {...props} />,
)`
  border-collapse: inherit;
  &&& {
    th,
    td {
      font-weight: 500;
      white-space: nowrap;
    }

    tbody > tr > td {
      border: 0;

      border-bottom: 1px solid
        ${({ theme }) => theme.tokens.border.neutral.default} !important;
    }

    tbody > tr:last-child > td {
      padding: 0.375rem;
      font-size: 0.75rem;
    }

    tbody:last-child > tr > td {
      padding: 0.7rem 0.375rem;
      font-size: 0.75rem;
      border-bottom: none !important;
    }
  }
`;

const StyledTableRow = styled(
  ({
    state,
    length,
    ...props
  }: {
    state?: BreakdownRowStates;
    length?: number;
  } & React.ComponentProps<typeof TableRow>) => <TableRow {...props} />,
)`
  && {
    font-size: 0.75rem;
    padding: 0.375rem 0.375rem;

    border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default} !important;

    cursor: ${({ state }) =>
      state === BreakdownRowStates.CLOSED || state === BreakdownRowStates.OPEN
        ? "pointer"
        : "inherit"};

    background-color: ${({ state, theme }) =>
      state && groupedRows.includes(state)
        ? theme.tokens.elevation.low
        : state === BreakdownRowStates.OPEN
          ? theme.tokens.elevation.medium
          : "inherit"};
  }

  :hover {
    background-color: ${({ theme }) => theme.tokens.background.neutral.hover};
  }
`;

const getPriceMetadata = (
  expansionPanelLang: Translation["txTable"]["expansionPanel"],
  priceMetadata?: PriceMetadata,
) => {
  if (
    priceMetadata?.sourceType &&
    [PriceSourceType.PriceWindow, PriceSourceType.PriceFromAction].includes(
      priceMetadata?.sourceType,
    )
  ) {
    return expansionPanelLang.sourceType({
      sourceType: expansionPanelLang.sourceTypes[priceMetadata.sourceType],
    });
  }

  return;
};
