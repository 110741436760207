import { Check } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { type UseMutationResult } from "@tanstack/react-query";

import { useDesign } from "~/hooks/useTheme";
/**
 * Takes in a react query mutation result and uses it to control the appearance of a button
 *
 * @param mutationResult Result from react query mutation hook
 * @returns Props to be applied to a button component
 */
export function useSuccessButton(
  mutationResult: UseMutationResult<any, any, any, any> | undefined,
) {
  const { tokens } = useDesign();
  if (!mutationResult) {
    return {
      getButtonProps: (props: any = {}) => {
        return { ...props };
      },
    };
  }
  const { isPending: isLoading, isSuccess } = mutationResult;
  return {
    getButtonProps: (props: any = {}) => {
      const { disabled, sx, startIcon, ...rest } = props;
      return {
        ...rest,
        startIcon: isLoading ? (
          <CircularProgress size="0.875rem" />
        ) : isSuccess ? (
          <Check
            sx={{
              color: tokens.icon.success,
            }}
          />
        ) : (
          startIcon ?? null
        ),
        disabled: isLoading || isSuccess || disabled,
        sx: {
          ...sx,
          ...(isSuccess && {
            "&&&.Mui-disabled": {
              color: tokens.icon.success,
              backgroundColor: tokens.background.success.default,
            },
          }),
        },
      };
    },
  };
}

/**
 * Handles loading spinners for the icon button
 * @param mutationResult
 * @returns
 */
export function useIconButtonSuccessButton(
  mutationResult: UseMutationResult<any, any, any, any>,
) {
  const { tokens } = useDesign();
  const { getButtonProps } = useSuccessButton(mutationResult);

  const results = {
    getButtonProps: (props: any = {}) => {
      return {
        ...props,
        ...getButtonProps(props),
        children: mutationResult.isPending ? (
          <CircularProgress size="0.875rem" />
        ) : mutationResult.isSuccess ? (
          <Check
            sx={{
              color: tokens.icon.success,
            }}
          />
        ) : (
          props.children ?? null
        ),
      };
    },
  };
  return results;
}
