import { ArrowForward, Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import { horizontalNavHeight } from "~/components/nav/SideNav";
import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { type ToggleTaxSettings } from "~/components/settings-modal/views/tax/TaxSettings";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import {
  hideBannerAuthUser,
  useIsVisibleAuthUserFactory,
} from "~/redux/notification";
import { useTaxSettings } from "~/redux/report";

/**
 * Add tax settings here to enable the banner for them
 */
const taxSettingsToNotify: (keyof ToggleTaxSettings)[] = [
  // "allowGenerateFeeOnGroupedQuantityDiff",
  // "allowBridgeFromUncategorised",
];

export function useHighlightedTaxSettings(): (keyof ToggleTaxSettings)[] {
  // We want to show the banner once per logged in user, but based on the data
  // theyre viewing. So for example, an accountant will only have to dismiss the
  // banner once, but theyll only see it once they view a client who is eligible
  // to see the notification
  const taxSettings = useTaxSettings();
  const isVisibleNotification = useIsVisibleAuthUserFactory();

  if (!taxSettings) return [];

  // If the setting is false and the notification is visible
  return taxSettingsToNotify.filter(
    (setting) =>
      taxSettings[setting] === false && isVisibleNotification(setting),
  );
}

export function useHideNewTaxSettingBanner(
  selectedSetting?: keyof ToggleTaxSettings,
) {
  const reduxDispatch = useDispatch();
  const highlightedTaxSettings = useHighlightedTaxSettings();

  return () => {
    (selectedSetting ? [selectedSetting] : highlightedTaxSettings).forEach(
      (setting) => {
        reduxDispatch(hideBannerAuthUser(setting));
      },
    );
  };
}

export function NewTaxSettingBanner() {
  const lang = useLang();
  const { tokens } = useDesign();
  const taxSettings = useTaxSettings();
  const highlightedTaxSettings = useHighlightedTaxSettings();
  const hideBannerFunction = useHideNewTaxSettingBanner();
  const { setActive } = useSettingsModal();

  if (!taxSettings || !highlightedTaxSettings.length) return null;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      bgcolor={tokens.background.accent.purple.low}
      flexWrap="wrap"
      px="1rem"
      position="sticky"
      top={horizontalNavHeight}
      zIndex={1000}
    >
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        gap="1rem"
        alignItems="center"
        flexWrap="wrap"
        py="0.25rem"
      >
        <Typography variant="Metropolis/Header/H5" color={tokens.text.high}>
          {lang.taxSettingBanner.title}
        </Typography>
        <Box>
          <SecondaryButton
            size="small"
            endIcon={<ArrowForward />}
            onClick={() => {
              setActive(SettingsTabs.General);
            }}
          >
            {lang.taxSettingBanner.cta}
          </SecondaryButton>
        </Box>
      </Box>
      <TextIconButton size="small" onClick={hideBannerFunction}>
        <Close />
      </TextIconButton>
    </Box>
  );
}
