function getCore() {
  const { VITE_APP_API_URI_DOMAIN, VITE_APP_API_URI_PATH } = import.meta.env;
  const VITE_APP_API_URI = `${VITE_APP_API_URI_DOMAIN}${VITE_APP_API_URI_PATH ?? ""}`;
  return { VITE_APP_API_URI, VITE_APP_API_URI_DOMAIN, VITE_APP_API_URI_PATH };
}

export const {
  VITE_APP_API_URI: CORE,
  VITE_APP_API_URI_DOMAIN: URI_DOMAIN,
  VITE_APP_API_URI_PATH: URI_PATH,
} = getCore();
