export enum Constants {
  GoogleAnalyticsGtagUrl = "https://an4.cryptotaxcalculator.io/gtag/js",
  GoogleAnalyticsMeasurementID = "G-G762EZZCLS",
  Stripe = "pk_live_ZiQWjrdYwkbSNYgztkp2fTon",
  StripeTest = "pk_test_51KLHu0BRj4w2t94K4MrwxfTdPQNq7eekFLdTgB1GseZH5nNT1aFGCs8h2IwAn79Jz81kurZBR18RUHC7yOOaO6Ue00A2vbR73B",

  /* Google Recaptcha */
  Recaptcha = "6LebcZAcAAAAAPq3wBuc51G3GEU07WS1vZPdeeN_",
  RecaptchaTest = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",

  /*
   * Cloudflare Turnstile
   * We use a test key for non-prod/staging environments to always pass the captcha
   * See: https://developers.cloudflare.com/turnstile/troubleshooting/testing/
   */
  Turnstile = "0x4AAAAAAA563iuvr4G2NVL6",
  TurnstileTest = "0x4AAAAAAA5ZZV8kUS_x5Hao",
}
export enum PriceAUD {
  Free = 0,
  Rookie_DEPRECATED = 39,
  Rookie100 = 49,
  Standard_DEPRECATED = 127,
  Standard100 = 129,
  Advanced = 249,
  Expert = 399,
  Accountant = 499,
  Business = 1299,
}
export enum PriceUSD {
  Free = 0,
  Rookie_DEPRECATED = 29,
  Rookie100 = 49,
  Standard_DEPRECATED = 99,
  Standard100 = 99,
  InvestorUsPlus = 199,
  Advanced = 249,
  TraderUs = 299,
  Trader = 499,
  Expert = 499,
  Accountant = 499,
  TraderUsPlus = 599,
  Business = 999,
  Pro = 599,
}
export enum PriceGBP {
  Free = 0,
  Rookie_DEPRECATED = 29,
  Rookie100 = 49,
  Standard_DEPRECATED = 69,
  Standard100 = 99,
  Advanced = 249,
  Expert = 499,
  Accountant = 499,
  Business = 999,
}
export enum PriceEUR {
  Free = 0,
  Rookie_DEPRECATED = 29,
  Rookie100 = 49,
  Standard_DEPRECATED = 79,
  Standard100 = 99,
  Advanced = 249,
  Expert = 499,
  Accountant = 499,
  Business = 999,
}
export enum PriceNZD {
  Free = 0,
  Rookie_DEPRECATED = 39,
  Rookie100 = 49,
  Standard_DEPRECATED = 129,
  Standard100 = 99,
  Advanced = 249,
  Expert = 499,
  Accountant = 499,
  Business = 1399,
}
export enum PriceCAD {
  Free = 0,
  Rookie_DEPRECATED = 29,
  Rookie100 = 49,
  Standard_DEPRECATED = 99,
  Standard100 = 99,
  Advanced = 249,
  Expert = 499,
  Accountant = 499,
  Business = 1399,
}

export enum PriceINR {
  Free = 0,
  Rookie100 = 199,
  Standard100 = 999,
  Advanced = 1999,
  Expert = 4999,
}

/**
 * Query parameters that are used to bootstrap the app
 */
export enum BootstrapQueryParameter {
  /** Language to use */
  Lang = "lang",
  /** Theme, ctc, coinbase, metamask, kraken, etc */
  BrandStyles = "brandStyles",
  /** Light mode, dark mode */
  ColorMode = "colorMode",
  /** Coupon code */
  Coupon = "coupon",

  // MetaMask initialization
  /** What accounts to use */
  Accounts = "accounts",
  /** What networks to use */
  Networks = "networks",

  /** Referral and tracking */
  ReferrerSource = "referrerSource",

  /** Initial authentication state */
  AuthState = "authState",
}

/**
 * Values for the initial authentication state when bootstrapping the app
 */
export enum AuthStateValue {
  /** Start a new session, logging out any existing user */
  NewSession = "new_session",
  /** Preserve any existing authenticated session (default) */
  PreserveSession = "preserve_session",
}

export enum CookieKey {
  /**
   * HP now sets the country in the `hpSelectedCountry` cookie. This is kept for backwards compatibility until we're confident most users have migrated to the new cookie.
   * @deprecated
   */
  UnconfirmedCountry = "gatsby-intl-language",
  HpSelectedCountry = "hp-selected-country",
  ReferrerSource = "referrerSource",
  /** Coupon code */
  Coupon = "coupon",
}

export enum LocalStorageKey {
  BrandStyle = "brandStyle",
  PlanUpgradeEvent = "planUpgradeEvent",
  Ledger = " ledger",
  Lang = "lang",
  ImportSortOption = "importSortOption",
  SmoothScroll = "smoothScrollEnabledPreference",
  Editor = "editor",
  StripeCoupon = "stripeCoupon",
  InviteCode = "inviteCode",
  RowsPerPage = "rowsPerPage",
  SortOrder = "sortOrder",
  DevFeatureFlagOverrides = "developer.featureFlagOverrides",
  Theme = "theme",
  LastUsedIncomingTrade = "lastUsedIncomingTrades",
  LastUsedOutgoingTrade = "lastUsedOutgoingTrades",
  AccountSortOption = "accountSortOption",
  DrawerCurrencySortOption = "drawerCurrencySortOption",
  DrawerNftSortOption = "drawerNftSortOption",
  ImportViewMode = "importViewModeV2",
  OnboardingAccountant = "onboardingAccountant",
  HideLockedPeriod = "hideLockedPeriod",
  ContactsSort = "contactsSort",
  SideNavOpen = "sideNavOpen",
  SideNavCollapsed = "sideNavCollapsed",
  ReferrerSource = "referrerSource",
  MetaMaskWallets = "metaMaskWallets",
  ShowOnMobileWarningBanner = "showOnMobileWarningBanner",
  SelectedReportType = "selectedReportType",
  ReportSortOrder = "reportSortOrder",
  ReportSortedColumn = "reportSortedColumn",
  ReportRowsPerPage = "reportRowsPerPage",
  ReportCurrentPage = "reportCurrentPage",
  OnboardingState = "onboardingState",
  CalcoTourState = "calcoTourState",
  TreasuryChartTimeframe = "treasuryChartTimeframe",
  TreasuryChartScale = "treasuryChartScale",
  TreasurySummary = "treasurySummary",
  ClientTableSort = "clientTableSorts",
  TeamSort = "teamSort",
  LoginRedirect = "loginRedirect",
  LocalCensoringEnabled = "localCensoringEnabled",
  PostOnboardingGuidedTour = "postOnboardingGuidedTour",
  NavTabsAccordionState = "navTabsAccordionState",
  OnboardingCalculatingState = "onboardingCalculatingState",
  Country = "country",
  /**
   * Set whenever a user is successfully loaded (login or signup) so we have a
   * record if the user have ever seen the app before (on this device/browser)
   */
  HasPreviousLogin = "hasPreviousLogin",
  /**
   * Sets if the user has had a future subscription renewal coupon applied.
   */
  FutureSubscriptionRenewalCouponApplicationStatus = "futureSubscriptionRenewalCouponApplicationStatus",
  /**
   * List of cluster names that the user has ignored, so that they dont see
   * cluster suggestions for those clusters in the future. (related wallets)
   */
  IgnoredClusterNames = "ignoredClusterNames",
}

export enum SessionStorageKeys {
  StateWithoutSelectedIds = "stateWithoutSelectedIds",
  MetaMaskWallets = "metaMaskWallets",
}
export enum InternalErrorCode {
  noTransactions = 1000,
  tooManyTransactions = 2000,
  expiredAuthorization = 3000,
  unknownError = 9000,
}
export enum CouponCode {
  Coinbase30 = "COINBASE30",
  CoinbaseOne40 = "CB140",
  Coinstats = "COINSTATS",
  Coinjar = "COINJARUK",
  CoinbaseOne1DollarRookie = "cb1rookie",
  CoinbaseOne40PercentOff = "coinbase140",
  CoinbaseOnePremiumTrader50 = "coinbase1p",
  CoinbaseOnePremium40PercentOff = "coinbase140p",
  /** MetaMask 2025 30% off Coupon */
  MetaMaskCTC30 = "MMCTC30",
  /** MetaMask Embedded 30% off Coupon */
  MetaMaskEmbedded30 = "metamask30",
  IndependentReserve30 = "ir30",
  BitcoinDotComAu30 = "bitcoincomau30",
  /** Refer a Friend 20% off Coupon */
  ReferAFriend20 = "CTCFRIEND20", // TODO: Update - this just matches FirstPromoter config for now.
  /** Phantom 20% off Coupon */
  Phantom20 = "phantom20",
}
export enum AuthType {
  Signup = "signup",
  Login = "login",
}

export enum AccountantClientWithCrypto {
  NoClientsYet = "No clients yet",
  SomeClients = "Some clients",
  MostClients = "Most clients",
  AlmostAllClients = "Almost all clients",
}
