import { Box, type ButtonProps, Popover } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import { type ReactNode } from "react";
import { css } from "styled-components";

import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { Size } from "~/types/enums";

export function BasicPopover(props: {
  buttonText: ReactNode;
  content: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  active?: boolean;
}) {
  const { buttonText, content, endIcon, disabled, active } = props;

  const popupState = usePopupState({
    variant: "popover",
    popupId: "filter-bar-popover",
  });

  return (
    <div>
      <FilterButton
        popoverProps={bindTrigger(popupState)}
        endIcon={endIcon}
        disabled={disabled}
        active={active}
      >
        {buttonText}
      </FilterButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "left",
        }}
      >
        <Box>{content}</Box>
      </Popover>
    </div>
  );
}

export function FilterButton({
  active = false,
  popoverProps,
  size = Size.Small,
  ...props
}: {
  active?: boolean;
  popoverProps: ReturnType<typeof bindTrigger>;
} & ButtonProps) {
  const disabled = props.disabled || false;
  const style = props.style || {};
  style.height = size === Size.Small ? "1.75rem" : "2.25rem";

  const filterButtonCssBase = css`
    white-space: nowrap;
    min-width: 0;
    padding: 0.75rem;
    font-size: 12px;
    font-weight: 500;
    line-height: 0;

    svg {
      height: 1rem;
      width: 1rem;
      fill: ${({ theme }) =>
        disabled ? theme.tokens.text.disabled : theme.tokens.text.low};
    }
  `;

  const filterButtonCssActive = css`
    ${filterButtonCssBase};
    &&& {
      border-color: ${({ theme }) => theme.tokens.border.brand};
    }
  `;

  return (
    <div {...popoverProps}>
      {active ? (
        <SecondaryButton css={filterButtonCssActive} style={style} {...props} />
      ) : (
        <TertiaryButton css={filterButtonCssBase} style={style} {...props} />
      )}
    </div>
  );
}
