import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import type * as React from "react";

import { useCaptureActionAnalytics } from "~/analytics/posthog";
import { type OptionsType } from "~/components/transactions/TransactionRow";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";
import { type Size } from "~/types/enums";
import { type ActionRow } from "~/types/index";

export function MoreOptionsDropdown({
  row,
  options,
  size,
  isLoading = false,
}: {
  row: ActionRow;
  options: OptionsType[];
  size?: Size;
  isLoading?: boolean;
}) {
  const { tokens } = useDesign();
  const captureActionAnalytics = useCaptureActionAnalytics();

  const popupState = usePopupState({
    variant: "popper",
    popupId: "more-options",
  });

  return (
    <div
      className="more"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextIconButton
        size="small"
        {...bindTrigger(popupState)}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          e.stopPropagation();
          popupState.toggle(e);
          captureActionAnalytics("transaction_options_clicked", row);
        }}
      >
        {isLoading ? (
          <CircularProgress size="1rem" sx={{ color: tokens.icon.low }} />
        ) : (
          <MoreVertIcon fontSize={size} style={{ color: tokens.icon.low }} />
        )}
      </TextIconButton>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper
          onMouseLeave={() => {
            popupState.close();
          }}
        >
          <List sx={{ maxWidth: 300 }}>
            {options.map((item) => {
              return (
                <ListItem
                  key={item.label}
                  button
                  onClick={async (e: React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await item.handler();
                    popupState.close();
                  }}
                  sx={{ gap: "0.5rem" }}
                >
                  <Box mt="0.125rem">
                    {item.pending ? (
                      <CircularProgress size="1.125rem" />
                    ) : (
                      item.icon
                    )}
                  </Box>
                  <ListItemText>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {item.label}
                    </div>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Popover>
    </div>
  );
}
