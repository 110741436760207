import { InfoOutlined } from "@mui/icons-material";
import { Box, Tooltip, type TooltipProps, Typography } from "@mui/material";
import type * as React from "react";
import styled, { type DefaultTheme } from "styled-components/macro";

import { CensoredTooltip } from "~/components/ui/CensoredComponents";
import { ChipColor } from "~/components/ui/enums";
import { useDesign } from "~/hooks/useTheme";

type SquareChipProps = {
  label: string;
  tooltip?: string;
  rightIcon?: React.ReactElement;
  color?: ChipColor;
  censored?: boolean;
  tooltipProps?: Omit<TooltipProps, "children" | "title">;
  fontSize?: string;
  fontWeight?: number;
};

function getTextColor(tokens: DefaultTheme["tokens"], color?: ChipColor) {
  switch (color) {
    case ChipColor.GREEN:
      return tokens.text.success;
    case ChipColor.YELLOW:
      return tokens.text.warning;
    case ChipColor.RED:
      return tokens.text.danger;
    case ChipColor.GREY:
      return tokens.text.disabled;
    case ChipColor.DARKER:
      return tokens.text.default;
    default:
      return tokens.text.default;
  }
}

function getBackgroundColor(tokens: DefaultTheme["tokens"], color?: ChipColor) {
  switch (color) {
    case ChipColor.GREEN:
      return tokens.background.success.default;
    case ChipColor.YELLOW:
      return tokens.background.warning.default;
    case ChipColor.RED:
      return tokens.background.danger.default;
    case ChipColor.GREY:
      return tokens.background.accent.neutral.low;
    case ChipColor.DARKER:
      return tokens.background.neutral.default;
    default:
      return tokens.background.accent.neutral.low;
  }
}

const OptionalTooltipWrapper = ({
  tooltip,
  censored,
  children,
  tooltipProps,
}: {
  tooltip?: string;
  censored?: boolean;
  children: JSX.Element;
  tooltipProps?: Omit<TooltipProps, "children" | "title">;
}) => {
  if (!tooltip) return <>{children}</>;
  if (censored) {
    return (
      <CensoredTooltip title={tooltip} {...tooltipProps}>
        {children}
      </CensoredTooltip>
    );
  }

  return (
    <Tooltip title={tooltip} {...tooltipProps}>
      {children}
    </Tooltip>
  );
};

const StyledTypographyWrapper = ({
  censored,
  color,
  fontSize,
  fontWeight,
  children,
}: {
  censored?: boolean;
  color?: ChipColor;
  fontSize?: string;
  fontWeight?: number;
  children: string;
}) => {
  if (censored) {
    return (
      <StyledTypography
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        <span data-hj-suppress className="ph-no-capture">
          {children}
        </span>
      </StyledTypography>
    );
  }
  return (
    <StyledTypography color={color} fontSize={fontSize} fontWeight={fontWeight}>
      {children}
    </StyledTypography>
  );
};

const StyledTypography = styled(Typography)<{
  color?: ChipColor;
  fontSize?: string;
  fontWeight?: number;
  censored?: boolean;
}>`
  && {
    color: ${({ theme, color }) => getTextColor(theme.tokens, color)};
    font-size: ${({ fontSize }) => fontSize || "0.875rem"};
    font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  }
`;

const ChipWrapper = styled(Box)<{ color?: ChipColor }>`
  && {
    align-self: flex-start;
    border-radius: 0.25rem;
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    background-color: ${({ theme, color }) =>
      getBackgroundColor(theme.tokens, color)};
  }
`;

export const SquareChip = ({
  label,
  rightIcon,
  tooltip,
  color,
  censored,
  tooltipProps, // Eg, can set placement of tooltip to "top" in here
  fontSize,
  fontWeight,
}: SquareChipProps) => {
  const { tokens } = useDesign();
  /**
   * If there is a tooltip prop, default to the info icon and do not allow
   * overwriting, and show a tooltip. Otherwise, use the rightIcon prop if
   * it exists.
   */

  return (
    <OptionalTooltipWrapper
      tooltip={tooltip}
      censored={censored}
      tooltipProps={tooltipProps}
    >
      <ChipWrapper color={color}>
        <Box
          sx={{
            ...((rightIcon || tooltip) && {
              // Shift the text down to be center with the icon
              transform: "translateY(0.05rem)",
            }),
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          <StyledTypographyWrapper
            color={color}
            censored={censored}
            fontSize={fontSize}
            fontWeight={fontWeight}
          >
            {label}
          </StyledTypographyWrapper>
        </Box>
        {(rightIcon || tooltip) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: "0.25rem",
              fontSize: "1.25rem",
              color: getTextColor(tokens, color),
            }}
          >
            {!tooltip ? (
              // Must add size styling inside right icon
              rightIcon
            ) : (
              <InfoOutlined
                sx={{
                  fontSize: "small",
                }}
              />
            )}
          </Box>
        )}
      </ChipWrapper>
    </OptionalTooltipWrapper>
  );
};
