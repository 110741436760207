import { Player } from "@lottiefiles/react-lottie-player";
import { Box, type BoxProps } from "@mui/material";

import logoSpinnerSrc from "~/assets/lottie-animations/logoSpinner.json";

export function LogoSpinner({
  width = 2.5,
  height = 2.5,
  ...props
}: { width?: number; height?: number } & BoxProps) {
  const widthOffset = width / 2;
  const heightOffset = height / 2;
  return (
    <Box
      position="absolute"
      zIndex="99"
      top="50%"
      left="50%"
      marginTop={`-${heightOffset}rem`}
      marginLeft={`-${widthOffset}rem`}
      width={`${width}rem`}
      height={`${height}rem`}
      {...props}
    >
      <Player loop autoplay src={logoSpinnerSrc} />
    </Box>
  );
}
