// @url https://github.com/Swyftx/crypto-address-validator/blob/main/src/currencies.js
export enum WAVNetwork {
  ALGO = "algo",
  BTC = "btc",
  BCH = "bch",
  BCC = "bcc",
  LTC = "ltc",
  PPC = "ppc",
  TRX = "trx",
  DOGE = "doge",
  BVC = "bvc",
  FRC = "frc",
  PTS = "pts",
  MEC = "mec",
  XPM = "xpm",
  AUR = "aur",
  NMC = "nmc",
  BIO = "bio",
  GRLC = "grlc",
  VTC = "vtc",
  BTG = "btg",
  KMD = "kmd",
  BTCP = "btcz",
  HUSH = "hush",
  SNG = "sng",
  ZEC = "zec",
  ZCL = "zcl",
  ZEN = "zen",
  VOT = "vot",
  DCR = "dcr",
  GAME = "game",
  PIVX = "pivx",
  SLR = "slr",
  MONA = "mona",
  DGB = "dgb",
  USDT = "usdt",
  XRP = "xrp",
  DASH = "dash",
  NEO = "neo",
  GAS = "gas",
  QTUM = "qtum",
  XVG = "xvg",
  WAVES = "waves",
  ETH = "eth",
  ETZ = "etz",
  ETC = "etc",
  ENG = "eng",
  CLO = "clo",
  BKX = "bkx",
  ADA = "ada",
  XMR = "xmr",
  ANT = "ant",
  BAT = "bat",
  BNT = "bnt",
  CVC = "cvc",
  DNT = "dnt",
  GNO = "gno",
  GNT = "gnt",
  GUP = "gup",
  MLN = "mln",
  NMR = "nmr",
  OMG = "omg",
  PAY = "pay",
  RCN = "rcn",
  REP = "rep",
  RLC = "rlc",
  SALT = "salt",
  SNT = "snt",
  STORJ = "storj",
  SWT = "swt",
  TUSD = "tusd",
  WINGS = "wings",
  ZRX = "zrx",
  EXP = "exp",
  VIB = "vib",
  OCN = "ocn",
  POLY = "poly",
  STORM = "storm",
  NANO = "nano",
  XRB = "xrb",
  ADX = "adx",
  BNB = "bnb",
  ETHOS = "ethos",
  BQX = "bqx",
  FUN = "fun",
  MCO = "mco",
  POWR = "powr",
  SUB = "sub",
  WTC = "wtc",
  LSK = "lsk",
  IOTA = "iota",
  EOS = "eos",
  BTT = "btt",
  DENT = "dent",
  HOT = "hot",
  LINK = "link",
  MTL = "mtl",
  NPXS = "npxs",
  XLM = "xlm",
  ZIL = "zil",
  AUD = "aud",
  SYS = "sys",
  PPT = "ppt",
  VET = "vet",
  ONT = "ont",
  ONG = "ong",
  XEM = "xem",
  USDC = "usdc",
  BCD = "bcd",
  RVN = "rvn",
  BTS = "bts",
  ICX = "icx",
  PAX = "pax",
  AE = "ae",
  SC = "sc",
  ATOM = "atom",
  STEEM = "steem",
  STX = "stx",
  ENJ = "enj",
  THETA = "theta",
  STRAT = "strat",
  ELF = "elf",
  ARDR = "ardr",
  LEND = "lend",
  NXS = "nxs",
  IOST = "iost",
  MANA = "mana",
  XTZ = "xtz",
  HBAR = "hbar",
}
