import { Box, Stack, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { onboardingAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { useImportV2Metadata } from "~/components/imports-v2/helpers";
import { useOnboardingContext } from "~/components/onboarding-v2/context/onboarding";
import {
  OnboardingActionType,
  OnboardingPageActionType,
} from "~/components/onboarding-v2/enums";
import { usePageNavigation } from "~/components/onboarding-v2/helpers";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { Calco } from "~/components/ui/calco/Calco";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export const ImportedAllConfirmationBox = ({
  accountNum,
}: {
  accountNum: number;
}) => {
  const { dispatch } = useOnboardingContext();
  const { tokens } = useDesign();
  const captureAnalytics = useCaptureAnalytics();
  const analyticsKey = onboardingAnalyticsKey("import confirmation box");
  const { navigateTo } = usePageNavigation();
  const lang = useLang();
  const importMetadata = useImportV2Metadata();

  return (
    <>
      <Box sx={{ position: "absolute", right: 0, top: 0 }}>
        <Calco type="OG2" height="55px" width="48px" />
      </Box>
      <ConfirmationBox>
        <Box>
          <Typography
            variant="Metropolis/Body/Bold"
            sx={{ color: tokens.text.high }}
          >
            {lang.onboardingV2.accountConfirmation.title({ count: accountNum })}
          </Typography>
          <Typography
            variant="Metropolis/Body/Bold"
            sx={{ color: tokens.text.high }}
          >
            {lang.onboardingV2.accountConfirmation.title2}
          </Typography>
          <Typography variant="Metropolis/Caption/Medium/Regular">
            {lang.onboardingV2.accountConfirmation.subtitle}
          </Typography>
        </Box>
        <Stack direction="row" gap="1rem">
          <TertiaryButton
            style={{ padding: "0.5rem " }}
            onClick={() => {
              captureAnalytics(
                analyticsKey("clicked skip importing"),
                importMetadata,
              );
              dispatch({
                type: OnboardingActionType.SetShowFinishImportConfirmationModal,
                showFinishImportConfirmationModal: false,
              });
              navigateTo(OnboardingPageActionType.Next);
            }}
          >
            {lang.onboardingV2.accountConfirmation.nonCTA}
          </TertiaryButton>
          <PrimaryButton
            style={{ padding: "0.5rem " }}
            onClick={() => {
              captureAnalytics(
                analyticsKey("clicked continue importing"),
                importMetadata,
              );
              dispatch({
                type: OnboardingActionType.SetShowFinishImportConfirmationModal,
                showFinishImportConfirmationModal: false,
              });
            }}
          >
            {lang.onboardingV2.accountConfirmation.CTA}
          </PrimaryButton>
        </Stack>
      </ConfirmationBox>
    </>
  );
};

const ConfirmationBox = styled(Box)`
  display: flex;
  width: 20.8125rem;
  padding: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.brand};
  background: ${({ theme }) => theme.tokens.elevation.highest};
  top: 2.5rem;
`;
