import {
  CostBasisRedistributionMethod,
  Country,
  SyncStatusPlatform,
} from "@ctc/types";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  type BoxProps,
  CircularProgress,
  debounce,
  FormControl,
  MenuItem,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { ReportSettingsActionType } from "~/components/settings-modal/views/enums";
import { RadioButton } from "~/components/settings-modal/views/RadioButton";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { ActionButtons } from "~/components/settings-modal/views/tax/ActionButtons";
import { IgnoreBalanceRemainingValueThreshold } from "~/components/settings-modal/views/tax/IgnoreBalanceRemainingValueThreshold";
import { InventorySelector } from "~/components/settings-modal/views/tax/InventorySelector";
import { LongTermThresholdSelector } from "~/components/settings-modal/views/tax/LongTermThresholdSelector";
import { PriceWindowSelector } from "~/components/settings-modal/views/tax/PriceWindowSelector";
import { useLocalReportSettings } from "~/components/settings-modal/views/tax/ReportSettingsContext";
import { TaxSettingsSections } from "~/components/settings-modal/views/tax/TaxSettings";
import {
  StyledSelect,
  TimezoneSelector,
} from "~/components/settings-modal/views/tax/TimezoneSelector";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import {
  isSpecificInventoryByExchangeSupported,
  refreshReport,
  updateTaxSettings,
  useInventoryMethod,
  useIsLoadingTaxSettings,
  useTaxSettings,
} from "~/redux/report";
import { type AppDispatch } from "~/redux/types";
import { Scope } from "~/state/enums";
import { useGetAllTaxPeriodsQuery } from "~/state/period";
import { useSyncStatus } from "~/state/sync";
import { type TaxSettings } from "~/types/index";

import { useBestUser } from "../../../../redux/auth";
import { Irs2025Warning } from "./Irs2025Warning";

export function ReportSettings() {
  const reduxDispatch: AppDispatch = useDispatch();
  const taxSettings = useTaxSettings();
  const isTaxSettingsLoading = useIsLoadingTaxSettings();
  const {
    state: { taxSettings: localSettings },
    dispatch,
  } = useLocalReportSettings();
  const reportSyncStatus = useSyncStatus(Scope.Report);
  const lang = useLang();
  const defaultLongTermThreshold = taxSettings?.defaultLongTermThreshold;
  const isReportLoading = reportSyncStatus === SyncStatusPlatform.Pending;
  const user = useBestUser();

  const handleRefresh = () => {
    reduxDispatch(refreshReport());
  };

  const nonDebouncedSaveSettings = (update: TaxSettings) => {
    reduxDispatch(updateTaxSettings(update)).then(({ error }) => {
      if (error) {
        setTaxSettings(taxSettings as TaxSettings);
      }
    });
  };

  const saveSettings = useCallback(
    debounce(
      nonDebouncedSaveSettings,
      1000, // This means if a tax setting update fails, the local tax setting will revert back in a second
    ),
    [],
  );

  const updateLocalTaxSettings = useCallback(
    (update: TaxSettings) => {
      dispatch({ type: ReportSettingsActionType.SetTaxSettings, update });
    },
    [dispatch],
  );

  const setTaxSettings = (update: TaxSettings) => {
    updateLocalTaxSettings(update);
    saveSettings(update);
  };

  if (isTaxSettingsLoading || !localSettings) {
    return (
      <Box
        width="100%"
        height="20rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <SettingsBox title={lang.settings.report.taxSettings}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          rowGap="0.75rem"
          width="100%"
        >
          <Typography variant="Metropolis/Header/H5">
            {lang.settings.report.specificInventoryByExchange.title}
          </Typography>
          <ActionButtons
            isLoading={isReportLoading}
            onRefreshClick={handleRefresh}
          />
        </Box>
        <CostBasisTracking
          setTaxSettings={(update: Partial<TaxSettings>) => {
            setTaxSettings({ ...localSettings, ...update });
          }}
          specificInventoryByExchange={
            localSettings.specificInventoryByExchange
          }
          redistributionMethod={localSettings.costBasisRedistributionMethod}
        />
        {user?.country === Country.USA ? <Irs2025Warning /> : null}
        <Box>
          <InventorySelector
            onChange={(inventoryMethod) => {
              setTaxSettings({ ...localSettings, inventoryMethod });
            }}
          />
        </Box>
        <Box>
          <TimezoneSelector
            value={localSettings.timezone}
            onChange={(timezone) => {
              setTaxSettings({ ...localSettings, timezone });
            }}
          />
        </Box>
      </SettingsBox>

      <TaxSettingsSections setTaxSettings={setTaxSettings} />
      <LongTermThresholdSelector
        defaultValue={defaultLongTermThreshold}
        value={localSettings.longTermThreshold}
        onChange={(value) => {
          dispatch({
            type: ReportSettingsActionType.SetTaxSettings,
            update: { ...localSettings, longTermThreshold: value },
          });
        }}
        onSave={(value) => {
          saveSettings({ ...localSettings, longTermThreshold: value });
        }}
      />
      <SettingsBox>
        <PriceWindowSelector
          value={localSettings.priceWindow}
          onChange={(value) => {
            dispatch({
              type: ReportSettingsActionType.SetTaxSettings,
              update: { ...localSettings, priceWindow: value },
            });
          }}
          onSave={() => {
            saveSettings(localSettings);
          }}
        />
      </SettingsBox>
      <SettingsBox>
        <IgnoreBalanceRemainingValueThreshold
          value={localSettings.ignoreBalanceRemainingValueThreshold}
          onChange={(value) => {
            dispatch({
              type: ReportSettingsActionType.SetTaxSettings,
              update: {
                ...localSettings,
                ignoreBalanceRemainingValueThreshold: value,
              },
            });
          }}
          onSave={() => {
            saveSettings(localSettings);
          }}
        />
      </SettingsBox>
    </>
  );
}

function CostBasisTracking({
  setTaxSettings,
  specificInventoryByExchange,
  redistributionMethod,
}: {
  setTaxSettings: (update: Partial<TaxSettings>) => void;
  specificInventoryByExchange: boolean;
  redistributionMethod: CostBasisRedistributionMethod;
}) {
  const allLang = useLang();
  const lang = allLang.settings.report.specificInventoryByExchange;
  const inventoryMethod = useInventoryMethod();
  const isSpecIDAllowed =
    isSpecificInventoryByExchangeSupported(inventoryMethod);

  const onChange = (value: boolean) => {
    setTaxSettings({
      specificInventoryByExchange: value,
    });
  };

  return (
    <Box>
      <span>
        <RadioGroup
          aria-label="specificInventoryByExchange"
          value={specificInventoryByExchange}
          onChange={(_, value) => {
            onChange(value === "true");
          }}
        >
          <Box display="flex" flexDirection="column" gap="1rem">
            <SelectorBox isSelected={!specificInventoryByExchange}>
              <RadioButton
                value={false}
                title={lang.options.off.title}
                description={lang.options.off.description}
              />
            </SelectorBox>

            <SelectorBox isSelected={specificInventoryByExchange}>
              <RadioButton
                value
                title={lang.options.on.title}
                description={lang.options.on.description}
                disabled={!isSpecIDAllowed}
              />
              <RedistributionSelector
                ml="2rem"
                mt="0.25rem"
                redistributionMethod={redistributionMethod}
                specificInventoryByExchange={specificInventoryByExchange}
                setTaxSettings={setTaxSettings}
              />
            </SelectorBox>
          </Box>
        </RadioGroup>
      </span>
    </Box>
  );
}

function RedistributionSelector({
  redistributionMethod,
  specificInventoryByExchange,
  setTaxSettings,
  ...props
}: {
  redistributionMethod: CostBasisRedistributionMethod;
  specificInventoryByExchange: boolean;
  setTaxSettings: (update: Partial<TaxSettings>) => void;
} & BoxProps) {
  const lang = useLang();
  const { tokens } = useDesign();
  const allTaxPeriodsQuery = useGetAllTaxPeriodsQuery();
  const periodsSorted = useMemo(() => {
    return allTaxPeriodsQuery.data?.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    );
  }, [allTaxPeriodsQuery.data]);

  const latestPeriod = periodsSorted?.[0];

  if (!latestPeriod) {
    return null;
  }

  /**
   * Only show the redistribution selector if the latest period is not using
   * specific inventory by exchange (ie is universal) but the current period
   * (ie current tax settings) are
   */
  const shouldShowRedistributionSelector =
    !latestPeriod.specificInventoryByExchange && !!specificInventoryByExchange;

  if (!shouldShowRedistributionSelector) {
    return null;
  }

  const onChange = (value: CostBasisRedistributionMethod) => {
    setTaxSettings({
      costBasisRedistributionMethod: value,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="0.25rem" {...props}>
      <Box display="flex" alignItems="center" gap="0.5rem">
        <Typography variant="Metropolis/Body/Regular">
          {lang.report.costBasisRedistributionMethod.title}
        </Typography>
        <Box>
          <Tooltip title={lang.report.costBasisRedistributionMethod.tooltip}>
            <InfoOutlined sx={{ fontSize: "1rem", color: tokens.text.low }} />
          </Tooltip>
        </Box>
      </Box>
      <FormControl required>
        <StyledSelect
          labelId="redistributionMethod"
          value={redistributionMethod}
          onChange={(e) => {
            onChange(e.target.value as CostBasisRedistributionMethod);
          }}
          required
          variant="outlined"
        >
          {Object.values(CostBasisRedistributionMethod).map((method) => (
            <MenuItem key={method} value={method}>
              {lang.report.costBasisRedistributionMethod[method]}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Box>
  );
}

const SelectorBox = styled(Box)<{ isSelected: boolean }>`
  && {
    padding: 0 0.5rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
    ${({ theme, isSelected }) =>
      isSelected && `background-color: ${theme.tokens.elevation.low};`}
  }
`;
