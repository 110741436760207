import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { useLocalReportSettings } from "~/components/settings-modal/views/tax/ReportSettingsContext";
import {
  GeneralTaxSettingToggle,
  HeaderBox,
  type NonBooleanToggleProps,
} from "~/components/settings-modal/views/tax/TaxSettings";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useIsLoadingTaxSettings } from "~/redux/report";
import { setTaxSettings } from "~/services/report";
import { BalanceType } from "~/types/enums";

const portfolioToggles = [
  "showAllAccountsOnPortfolio",
  "showFiatHoldings",
] as const;

const nonBooleanPortfolioToggles = ["holdingsBalanceType"] as const;

const nonBooleanToggleProps: {
  [key in (typeof nonBooleanPortfolioToggles)[number]]: NonBooleanToggleProps<key>;
} = {
  holdingsBalanceType: {
    booleanToTaxSettingValue: (isChecked: boolean) =>
      isChecked ? BalanceType.Reported : BalanceType.Calculated,
    taxSettingsValueToBoolean: (value: BalanceType) =>
      value === BalanceType.Reported,
  },
};

export function PortfolioSettings() {
  const { tokens } = useDesign();
  const lang = useLang();

  const isTaxSettingsLoading = useIsLoadingTaxSettings();
  const {
    state: { taxSettings: localSettings },
  } = useLocalReportSettings();

  if (isTaxSettingsLoading || !localSettings) {
    return (
      <Box
        width="100%"
        height="20rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <SettingsBox title={lang.settings.tabs.portfolio}>
      <HeaderBox>
        <Typography variant="Metropolis/Header/H5">
          {lang.settings.portfolioTab.assetTracking}
        </Typography>
        <Tooltip
          title={lang.taxSettingBanner.affectCalculationsWarning}
          placement="top"
        >
          <InfoOutlined sx={{ color: tokens.icon.default, fontSize: "1rem" }} />
        </Tooltip>
      </HeaderBox>
      <Stack>
        {portfolioToggles.map((taxSetting) => {
          return (
            <Box key={taxSetting}>
              <GeneralTaxSettingToggle
                property={taxSetting}
                setTaxSettings={setTaxSettings}
              />
            </Box>
          );
        })}
        {nonBooleanPortfolioToggles.map((taxSetting) => {
          const { taxSettingsValueToBoolean, booleanToTaxSettingValue } =
            nonBooleanToggleProps[taxSetting];
          return (
            <Box key={taxSetting}>
              <GeneralTaxSettingToggle
                property={taxSetting}
                setTaxSettings={setTaxSettings}
                taxSettingsValueToBoolean={taxSettingsValueToBoolean}
                booleanToTaxSettingValue={booleanToTaxSettingValue}
              />
            </Box>
          );
        })}
      </Stack>
    </SettingsBox>
  );
}
