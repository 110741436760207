import { Box, Typography } from "@mui/material";

import { InfoIcon } from "~/components/ui/Icons";
import { useDesign } from "~/hooks/useTheme";

export const NoData = ({ title }: { title: string }) => {
  const { tokens } = useDesign();
  return (
    <Box
      bgcolor={tokens.background.neutral.default}
      padding={2}
      borderRadius="4px"
    >
      <Box display="flex" alignItems="center">
        <InfoIcon color={tokens.icon.brand} />
        <Box component="span" pl={1}>
          <Typography variant="Metropolis/Body/Light">{title}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
