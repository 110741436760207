import { type Blockchain } from "@ctc/types";
import { Box, Link, Typography } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import { Fragment } from "react";
import Linkify from "react-linkify";
import styled from "styled-components";

import {
  AdditionalNotes,
  requiredIntegrationsOnboardingNote,
} from "~/components/imports/AdditionalNotes";
import {
  getImportInstructionKey,
  validateImportTypeInstructionsWithImages,
} from "~/components/imports/helpers";
import { ImportInstructionsWithImages } from "~/components/imports/import-instructions-experiment/ImportInstructionsWithImages";
import instructionsWithImagesJSON from "~/components/imports/import-instructions-experiment/instructions.json";
import instructionsSimplifiedJSON from "~/components/imports/import-instructions-experiment/instructions-simplified.json";
import { InstructionsSimplified } from "~/components/imports/import-instructions-experiment/InstructionsSimplified";
import { ImportVideo } from "~/components/imports/ImportVideo";
import { ManualCSVActions } from "~/components/imports/ManualCSVInstructions";
import {
  useInImportInstructionsSimplifiedExperiment,
  useInImportInstructionsWithImagesExperiment,
} from "~/components/onboarding-v2/hooks/useInImportInstructionsWithImagesExperiment";
import { type Size } from "~/hooks/useComponentSize";
import { useDesign } from "~/hooks/useTheme";
import { useUser } from "~/redux/auth";
import { useLang, useNotice, useSteps } from "~/redux/lang";
import { useAvailableImportOption } from "~/state/imports";
import { ImportMethod, ImportType, IntegrationCategory } from "~/types/enums";
import {
  BlockchainName,
  type ImportMethodV2,
  type WalletOptions,
} from "~/types/index";

export function ImportInstructions({
  exchangeLabel,
  exchangeDisplayName,
  importType,
  importMethod,
  selectedBlockchain,
  size,
}: {
  exchangeLabel: string;
  importType: ImportType | ImportMethod;
  importMethod: ImportMethodV2;
  exchangeDisplayName?: string;
  selectedBlockchain: Blockchain | undefined;
  size: Size;
}) {
  const lang = useLang();
  const inImportInstructionsWithImagesExperiment =
    useInImportInstructionsWithImagesExperiment();
  const inImportInstructionsSimplifiedExperiment =
    useInImportInstructionsSimplifiedExperiment();
  const importOption = useAvailableImportOption(exchangeLabel);
  const steps = useSteps({
    exchangeLabel,
    importType,
    importMethod,
    exchangeDisplayName,
    selectedBlockchain,
  });

  const stepsResolved = cloneDeep(steps);

  const isUnspecifiedBlockchainAllowed = !!(
    importMethod.options as WalletOptions | undefined
  )?.isUnspecifiedBlockchainAllowed;

  const potentialNote = useNotice(exchangeLabel);

  const user = useUser();
  const dontShowOnboardingNote = !!(
    user?.isOnboarding &&
    !requiredIntegrationsOnboardingNote[importOption?.id ?? ""]?.includes(
      importType as ImportType,
    )
  );

  const notice =
    importOption?.category === IntegrationCategory.Wallet &&
    (importOption?.category.length === 1 || isUnspecifiedBlockchainAllowed) &&
    selectedBlockchain
      ? lang.wallet.equivWallet({ name: BlockchainName[selectedBlockchain] })
      : potentialNote;

  // If the integration supports its own CSV - add advanced CSV format as a note
  // If the integration doesn't supports a CSV - add advanced CSV format as a section
  const isManualCSVOnly =
    importOption?.manual ||
    importType === ImportType.ManualCSV ||
    (importMethod.type === ImportMethod.CSV && importMethod.options?.manual) ||
    importOption?.category === IntegrationCategory.Manual;

  const isImportingCSV =
    importType === ImportType.CSV || importType === ImportType.ManualCSV;

  if (!stepsResolved) {
    return null;
  }

  const { video = [], step = [], note = [] } = stepsResolved;

  const exchangeLabelTyped =
    exchangeLabel as keyof typeof instructionsWithImagesJSON.importInstructions;
  const instructionsWithImages =
    instructionsWithImagesJSON.importInstructions[exchangeLabelTyped];
  const importTypeTyped = importType as keyof typeof instructionsWithImages;

  if (
    inImportInstructionsWithImagesExperiment &&
    validateImportTypeInstructionsWithImages(instructionsWithImagesJSON) &&
    instructionsWithImages?.[importTypeTyped]
  ) {
    return (
      <ImportInstructionsWithImages
        exchangeLabel={exchangeLabel}
        importType={importType}
        importMethod={importMethod}
        selectedBlockchain={selectedBlockchain}
        size={size}
      />
    );
  }

  const exchangeLabelTypedSimplified =
    exchangeLabel as keyof typeof instructionsSimplifiedJSON.importInstructions;
  const instructionsSimplified =
    instructionsSimplifiedJSON.importInstructions[exchangeLabelTypedSimplified];
  const importTypeTypedSimplified =
    importType as keyof typeof instructionsSimplified;

  if (
    inImportInstructionsSimplifiedExperiment &&
    instructionsSimplified?.[importTypeTypedSimplified]
  ) {
    return (
      <InstructionsSimplified
        exchangeLabel={exchangeLabel}
        importType={importType as ImportType}
        importMethod={importMethod}
        selectedBlockchain={selectedBlockchain}
      />
    );
  }

  return (
    <StyledInstructionContainer>
      {video.length > 0 ? (
        <Box display="flex" flexDirection="column" gap="0.5rem" width="100%">
          {video.map((videoLinkInstr) => (
            <ImportVideo key={videoLinkInstr.link} note={videoLinkInstr} />
          ))}
        </Box>
      ) : null}
      {step.map((stepInstr, index) => (
        <Fragment key={getImportInstructionKey(stepInstr, index)}>
          {stepInstr.title ? (
            <Typography
              variant="Metropolis/Body/Light"
              style={{ fontWeight: 500 }}
              gutterBottom
            >
              <span>{stepInstr.title}</span>
            </Typography>
          ) : null}
          {stepInstr.items && stepInstr.items?.length > 0 ? (
            <StepList steps={stepInstr.items} />
          ) : null}
        </Fragment>
      ))}
      {notice && (
        <Box>
          <Typography variant="Metropolis/Body/Light">{lang.imports.note}:</Typography>
          <Typography variant="Metropolis/Body/Light">{notice}</Typography>
        </Box>
      )}
      <AdditionalNotes
        noteSteps={note}
        isManualCSVOnly={isManualCSVOnly}
        isImportingCSV={isImportingCSV}
        dontShowOnboardingNote={dontShowOnboardingNote}
      />
      {isManualCSVOnly && isImportingCSV && <ManualCSVActions />}
    </StyledInstructionContainer>
  );
}

export function StepList({ steps }: { steps: string[] }) {
  return (
    <Box display="flex" flexDirection="column" gap="0.5rem" alignSelf="stretch">
      {steps.map((step, i) => (
        <StepItem
          key={step ?? i}
          item={step}
          index={i}
          totalSteps={steps.length}
        />
      ))}
    </Box>
  );
}

export function StepItem({
  item,
  index,
  totalSteps,
  noNumber,
}: {
  item: string;
  index: number;
  totalSteps: number;
  noNumber?: boolean;
}) {
  const { tokens } = useDesign();
  const isOnboarding = !!useUser()?.isOnboarding;

  if (totalSteps === 1 || noNumber) {
    return (
      <Typography variant="Metropolis/Body/Light" flex="1 0 0" fontWeight={500}>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <Link
              href={decoratedHref}
              key={key}
              target="_blank"
              rel="noopener noreferrer"
            >
              {decoratedText}
            </Link>
          )}
        >
          {item}
        </Linkify>
      </Typography>
    );
  }
  return (
    <Box display="flex" gap="0.375rem" alignItems="stretch">
      <Box
        width="1.25rem"
        minWidth="1.25rem"
        height="1.25rem"
        borderRadius="1rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={
          isOnboarding ? undefined : tokens.background.accent.neutral.low
        }
        border={
          isOnboarding
            ? undefined
            : `1px solid ${tokens.border.neutral.default}`
        }
        pt={isOnboarding ? undefined : "1px"}
      >
        <Typography variant="Metropolis/Caption/Medium/Regular" color={tokens.text.high}>
          {index + 1}
        </Typography>
      </Box>
      <Typography
        variant="Metropolis/Body/Light"
        flex="1 0 0"
        overflow="auto"
        fontWeight={500}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <Link
              href={decoratedHref}
              key={key}
              target="_blank"
              rel="noopener noreferrer"
            >
              {decoratedText}
            </Link>
          )}
        >
          {item}
        </Linkify>
      </Typography>
    </Box>
  );
}

const StyledInstructionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  min-width: 15rem;
  flex: 1 0 0;
`;
