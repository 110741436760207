import { type Plan, type ReportFormat } from "@ctc/types";

import { type CarouselCardType } from "~/components/dashboard/embedded-dashboard/carousel/cards/types";
import { type PaywallModalType } from "~/contexts/paywall-modal-context/enums";
import { type ReportDownloadType } from "~/types/index";

type EmptyObject = Record<string, never>;

/**
 * All the analytic events we can capture
 */
export const AnalyticEvent = {
  ONBOARDING: {
    COMPETITOR_SURVEY_ANSWER_SELECTED:
      "onboarding:competitor_survey_answer_submitted",
    COMPETITOR_SURVEY_SKIPPED: "onboarding:competitor_survey_skipped",
    USER_VALUE_SURVEY_ANSWER_SELECTED:
      "onboarding:user_value_survey_outcomes_answer_submitted",
    USER_VALUE_SURVEY_SKIPPED: "onboarding:user_value_survey_outcomes_skipped",
    COUNTRY_AUTO_SELECT_CONFIRMED: "onboarding:country_auto_select_confirmed",
    COUNTRY_AUTO_SELECT_REJECTED: "onboarding:country_auto_select_rejected",
  },
  REPORT_PAGE: {
    VIEW: "report_page:viewed",
    REVIEW_TRANSACTIONS_TO_SAVE_CLICKED:
      "report_page:review_transactions_to_save_clicked",
    REPORT_DOWNLOAD_PACK_BUTTON_CLICKED:
      "report_page:report_download_pack_button_clicked",
  },
  IMPORTS: {
    OPEN_ADD_SUSPECTED_MISSING_IMPORT_DIALOG:
      "import:open_add_suspected_missing_import_dialog",
    DISMISS_SUSPECTED_MISSING_IMPORT_SUGGESTION:
      "import:dismiss_suspected_missing_import_suggestion",
    EXPAND_SUSPECTED_MISSING_IMPORT_SUGGESTION:
      "import:expand_suspected_missing_import_suggestion",
  },
  EMBEDDED_DASHBOARD: {
    GET_TAX_REPORT_BUTTON_CLICKED:
      "embedded_dashboard:get_tax_report_button_clicked",
    CAROUSEL_CARD_CLICKED: "embedded_dashboard:carousel_card_clicked",
  },
  PAYWALL_MODAL: {
    CLOSED: "paywall_modal:closed",
    PURCHASE_PLAN_BUTTON_CLICKED: "paywall_modal:purchase_plan_button_clicked",
    VIEW_PLANS_BUTTON_CLICKED: "paywall_modal:view_plans_button_clicked",
    AUTOMATICALLY_OPENED: "paywall_modal:automatically_opened",
    VIEWED: "paywall_modal:viewed",
    PLAN_SELECTED: "paywall_modal:plan_selected",
  },
} as const;

/**
 * Any extra properties included in the event
 */
export type EventProperties = {
  [AnalyticEvent.REPORT_PAGE.REPORT_DOWNLOAD_PACK_BUTTON_CLICKED]: {
    reportFormat: ReportFormat;
    reportDownloadTypes: ReportDownloadType[];
    packId: string;
    packType: string;
    // If the report is paywalled, it will be a sample
    isSample: boolean;
  };
  [AnalyticEvent.ONBOARDING.USER_VALUE_SURVEY_ANSWER_SELECTED]: {
    answer: string;
  };
  [AnalyticEvent.ONBOARDING.COMPETITOR_SURVEY_ANSWER_SELECTED]: {
    answer: string;
  };
  [AnalyticEvent.ONBOARDING.COUNTRY_AUTO_SELECT_CONFIRMED]: {
    autoDetectedCountry: string;
  };
  [AnalyticEvent.ONBOARDING.COUNTRY_AUTO_SELECT_REJECTED]: {
    autoDetectedCountry: string;
  };
  [AnalyticEvent.IMPORTS.OPEN_ADD_SUSPECTED_MISSING_IMPORT_DIALOG]: {
    importId: string;
    importName: string;
    suspectedMissingImportsCount: number;
  };
  [AnalyticEvent.IMPORTS.DISMISS_SUSPECTED_MISSING_IMPORT_SUGGESTION]: {
    importId: string;
    importName: string;
    suspectedMissingImportsCount: number;
  };
  [AnalyticEvent.IMPORTS.EXPAND_SUSPECTED_MISSING_IMPORT_SUGGESTION]: {
    importId: string;
    importName: string;
    suspectedMissingImportsCount: number;
  };
  [AnalyticEvent.EMBEDDED_DASHBOARD.CAROUSEL_CARD_CLICKED]: {
    cardType: CarouselCardType;
  };
  [AnalyticEvent.EMBEDDED_DASHBOARD.GET_TAX_REPORT_BUTTON_CLICKED]: EmptyObject;
  [AnalyticEvent.PAYWALL_MODAL.PURCHASE_PLAN_BUTTON_CLICKED]: {
    plan: Plan;
    showTaxSeasonCountdown: boolean;
    variant: string;
  };
  [AnalyticEvent.PAYWALL_MODAL.CLOSED]: {
    showTaxSeasonCountdown: boolean;
    variant: string;
  };
  [AnalyticEvent.PAYWALL_MODAL.VIEW_PLANS_BUTTON_CLICKED]: {
    showTaxSeasonCountdown: boolean;
    variant: string;
  };
  [AnalyticEvent.PAYWALL_MODAL.AUTOMATICALLY_OPENED]: EmptyObject;
  [AnalyticEvent.PAYWALL_MODAL.VIEWED]: {
    type: PaywallModalType;
  };
  [AnalyticEvent.PAYWALL_MODAL.PLAN_SELECTED]: {
    plan: Plan;
    showTaxSeasonCountdown: boolean;
    variant: string;
  };
};
