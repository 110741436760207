import { useExperimentVariant } from "~/analytics/posthog";
import { FeatureFlag } from "~/types/enums";

/**
 * Checks if the user is in the onboarding import instructions with images experiment
 * @returns {boolean} If the user is in the onboarding import instructions with images experiment
 */
export function useInImportInstructionsWithImagesExperiment() {
  const inImportInstructionsWithImagesExperiment = useExperimentVariant(
    FeatureFlag.OnboardingImportInstructions,
    "images",
  );

  return inImportInstructionsWithImagesExperiment;
}

export function useInImportInstructionsSimplifiedExperiment() {
  const inImportInstructionsSimplifiedExperiment = useExperimentVariant(
    FeatureFlag.OnboardingImportInstructions,
    "simple",
  );

  return inImportInstructionsSimplifiedExperiment;
}
