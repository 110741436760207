import { useNavigate } from "react-router-dom";

import { AUTO_CLOSE_MESSAGE } from "~/constants/constants";
import { BroadcastChannels } from "~/hooks/enums";
import { createPopup } from "~/lib/popup";
import { useLang } from "~/redux/lang";
import { CORE } from "~/services/uri";
import { getEmbeddedJwt } from "~/services/user";
import { Links } from "~/types/enums";

// must ensure there is an endpoint in the BE for /keys/:exchangeLabel to use this
export const useOAuthFlow = ({
  exchangeLabel,
  uid,
  isEmbedded,
  redirectLink = Links.Imports,
}: {
  exchangeLabel: string;
  uid: string | undefined;
  isEmbedded: boolean;
  redirectLink?: string;
}): (() => Promise<void>) => {
  const navigate = useNavigate();
  const lang = useLang();

  if (!uid) {
    return () => Promise.resolve();
  }

  const baseURI = `${CORE}/keys/${exchangeLabel}`;
  const params = new URLSearchParams({
    uid,
  });
  if (isEmbedded) {
    // if the app is embedded, change the current page to the redirect link
    return async () => {
      const data = await getEmbeddedJwt();
      if (data.error) {
        console.error("Error fetching embedded JWT", data.error);
        return;
      }
      // The redirectLink is where the user will be redirected to after the OAuth flow is complete
      // it is default to bring user back to import page
      // if the app is embedded, we use the close link to close the popup
      params.set(
        "redirect",
        `${Links.Close}?channel=${BroadcastChannels.OAuthChannel}`,
      );
      params.set("embeddedJwt", data.data.jwt);
      const uri = `${baseURI}?${params.toString()}`;
      handleOAuthPopupFlow(uri, lang.popup.enablePopups, () => {
        navigate(redirectLink);
      });
    };
  }
  return () => {
    params.set("redirect", redirectLink);
    const uri = `${baseURI}?${params.toString()}`;
    window.location.href = uri;
    return Promise.resolve();
  };
};

const handleOAuthPopupFlow = (
  uri: string,
  failedToOpenMessage: string,
  onOAuthComplete?: () => void,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const popup = createPopup({
      channelName: BroadcastChannels.OAuthChannel,
      options: {
        url: uri,
        target: "_blank",
      },
      onPopupCreated: () => {},
      onPopupClosed: () => {},
      onMessage: (event: MessageEvent) => {
        if (event.data === AUTO_CLOSE_MESSAGE) {
          if (onOAuthComplete) {
            onOAuthComplete();
          }
          resolve();
        }
      },
      failedToOpenMessage,
    });
    if (!popup) {
      reject(new Error("Failed to open authentication window"));
      return;
    }
  });
};
