import { type Blockchain, SyncStatusPlatform } from "@ctc/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StringParam, useQueryParam } from "use-query-params";

import { FORM_IMPORT_SUBMITTED_EVENT } from "~/analytics/analytics";
import { importNewAnalyticsKey } from "~/analytics/analyticsKeys";
import { captureAnalytics, useCaptureAnalytics } from "~/analytics/posthog";
import { importAccountKey } from "~/components/imports/AnalyticsHelpers";
import { queryClient } from "~/components/queryClient";
import { displayMessage } from "~/components/ui/Toaster";
import { useUser } from "~/redux/auth";
import { getFileSyncLabel } from "~/redux/imports";
import { useLang } from "~/redux/lang";
import { useSelector } from "~/redux/useSelector";
import {
  fireAnalyticsOnDelete,
  fireAnalyticsOnUpload,
} from "~/segment/importAnalytics";
import * as csvAPI from "~/services/csvs";
import * as importsV2 from "~/services/imports";
import {
  optimisticallyUpdateAccountPending,
  optimisticallyUpdateKey,
} from "~/services/imports";
import { entityKeys } from "~/state/entities";
import { useAvailableImportOption } from "~/state/imports";
import {
  getTotalImportItems,
  type SavedImportKey,
  savedImports,
  useViewModeSyncKey,
} from "~/state/importsV2";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import {
  useRemoveSyncMutation,
  useSetSyncMutation,
  useUploadFailHandler,
  useUploadPendingHandler,
} from "~/state/sync";
import {
  DisplayMessage,
  ImportMethod,
  ImportType,
  ImportViewMode,
  IntegrationCategory,
} from "~/types/enums";
import {
  type AllSavedImports,
  type ExtraCsvParams,
  type ImportOptionV2,
  isCustomImport,
  type SavedFileImport,
  type SavedImportByIntegration,
  type SavedImportOptionByAccount,
} from "~/types/index";

export function useCorrectImportOption(
  importOption: ImportOptionV2,
  selectedBlockchain?: string,
) {
  const blockchainImport = useAvailableImportOption(selectedBlockchain || null);
  if (
    [IntegrationCategory.Blockchain, IntegrationCategory.Wallet].includes(
      importOption.category,
    ) &&
    blockchainImport
  ) {
    return blockchainImport;
  }
  return importOption;
}

type AllPreviousData = [SavedImportKey, AllSavedImports | undefined];
export const useUploadCsvFilesMutation = (
  rawImportOption: ImportOptionV2,
  selectedBlockchain: Blockchain | null,
) => {
  const syncCsvMutation = useSyncCsvMutation(rawImportOption.name);
  const queryClient = useQueryClient();
  const user = useUser();
  const captureAnalytics = useCaptureAnalytics();
  const importOption = useCorrectImportOption(
    rawImportOption,
    selectedBlockchain || undefined,
  );
  const uploadFail = useUploadFailHandler();
  const uploadPending = useUploadPendingHandler();
  const [suspectedMissingImport] = useQueryParam(
    "suspectedMissingImport",
    StringParam,
  );
  const isSuspectedMissingImport = suspectedMissingImport === "true";

  return useMutation({
    mutationFn: async ({
      files,
      timezone,
      manualLabel,
      extraParams,
    }: {
      files: File[];
      timezone: string;
      manualLabel?: string;
      manualName?: string;
      extraParams?: ExtraCsvParams;
    }) => {
      uploadPending({ ...importOption, showMessage: true });
      const label = manualLabel || importOption.id;
      await fireAnalyticsOnUpload(user, {
        label,
        importType: ImportType.CSV,
      });
      captureAnalytics("exchange_imported", {
        integration: importOption.id,
        exchangeName: importOption.name,
        importType: ImportType.CSV,
        isSuspectedMissingImport,
      });
      captureAnalytics(importNewAnalyticsKey(FORM_IMPORT_SUBMITTED_EVENT), {
        integration: importOption.id,
        exchangeName: importOption.name,
        importType: ImportType.CSV,
      });
      const isManualCsv = !!rawImportOption.importMethods.find(
        (m) => m.type === ImportMethod.CSV && m.options?.manual,
      );

      const res = await csvAPI.saveCsv(
        label,
        files,
        timezone,
        importOption.manual || isCustomImport(importOption) || isManualCsv,
        extraParams,
      );

      if (res.error) {
        uploadFail(importOption, res.msg);
        throw new Error(res.msg);
      }

      for (const fileImportId of res.data) {
        syncCsvMutation.mutate({
          exchangeId: importOption.id,
          name: importOption.name,
          category: importOption.category,
          fileImportId,
        });
      }

      return res.data;
    },
    onMutate: async ({ files, manualLabel, manualName }) => {
      const label = manualLabel || importOption.id;
      const name = manualName || importOption.name;

      await queryClient.cancelQueries({ queryKey: savedImports.all() });

      const allPreviousData: AllPreviousData[] = [];

      // For all the view modes, we need to optimistically set the data for this
      // import
      for (const viewMode of Object.values(ImportViewMode)) {
        const viewModeKey = savedImports.list(viewMode);
        const previousData =
          queryClient.getQueryData<AllSavedImports>(viewModeKey);

        allPreviousData.push([viewModeKey, previousData]);

        const previousSavedAccountImport =
          previousData?.importsByAccountMap[label];
        const previousSavedIntegrationImport =
          previousData?.importsByIntegration[label];

        const dummyFiles: SavedFileImport[] = files.map((file) => ({
          id: file.name,
          name: file.name,
          status: SyncStatusPlatform.Pending,
          completedAt: null,
          createdAt: new Date().toISOString(),
          exchangeName: importOption.id,
          numTxsInserted: 0,
          numRowsParsed: 0,
          firstTxDate: undefined,
          lastTxDate: undefined,
          parseErrors: [],
          importType: ImportType.CSV,
          lastImportedTxTimestamp: null,
          isDuplicate: false,
          isDownloadable: false,
        }));
        const accountImport: SavedImportOptionByAccount = {
          id: label,
          name,
          category: rawImportOption.category,
          availableImportMethods: [],
          assets: [],
          value: 0,
          totalTxCount: undefined,
          spamTxCount: undefined,
          lastImportedTxTimestamp: null,
          lastSyncComplete: null,
          keys: [],
          oauths: [],
          wallets: [],
          ...previousSavedAccountImport,
          syncStatus: SyncStatusPlatform.Pending,
          files: [...(previousSavedAccountImport?.files || []), ...dummyFiles],
        };

        const savedIntegration: SavedImportByIntegration = {
          id: label,
          name,
          category: rawImportOption.category,
          keys: [],
          oauths: [],
          wallets: [],
          ...previousSavedIntegrationImport,
          files: [
            ...(previousSavedIntegrationImport?.files || []),
            ...dummyFiles,
          ],
        };

        const newData: AllSavedImports = {
          all: (previousData?.all || [])
            .filter((s) => s.id !== label)
            .concat([accountImport]),
          importsByAccountMap: { ...previousData?.importsByAccountMap },
          importsByIntegration: { ...previousData?.importsByIntegration },
        };
        newData.importsByAccountMap[label] = accountImport;
        newData.importsByIntegration[label] = savedIntegration;

        queryClient.setQueryData(viewModeKey, newData);
      }

      return {
        allPreviousData,
      };
    },
    onError(
      err,
      variables,
      context:
        | {
            allPreviousData?: AllPreviousData[];
          }
        | undefined,
    ) {
      if (!context?.allPreviousData) {
        return;
      }
      for (const [viewModeKey, previousData] of context.allPreviousData) {
        queryClient.setQueryData(viewModeKey, previousData);
      }
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
      queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const useDeleteCsvMutation = () => {
  const user = useUser();
  const queryClient = useQueryClient();
  const viewModeKey = useViewModeSyncKey();
  const errorMessage = useSelector(
    (state) => state.lang.map.imports.wentWrongDeleteFile,
  );
  const syncRemove = useRemoveSyncMutation();

  return useMutation({
    mutationFn: async (file: SavedFileImport) => {
      const { exchangeName, id } = file;
      syncRemove.mutate({ scope: exchangeName });
      syncRemove.mutate({ scope: getFileSyncLabel(exchangeName, id) });

      await fireAnalyticsOnDelete(user, {
        label: exchangeName,
        importType: ImportType.CSV,
      });
      const res = await importsV2.deleteImportByIdAndType({
        importId: id,
        importType: ImportType.CSV,
      });
      if (res.error) {
        throw new Error(errorMessage);
      }
      return res.data;
    },
    onMutate: async (file: SavedFileImport) => {
      await queryClient.cancelQueries({ queryKey: savedImports.all() });

      const previousData = queryClient.getQueryData(
        viewModeKey,
      ) as AllSavedImports;

      const previousSavedAddressAccount =
        previousData?.importsByAccountMap[file.exchangeName];
      const previousSavedIntegrations =
        previousData?.importsByIntegration[file.exchangeName];

      const willDeleteAddressAccount =
        previousSavedAddressAccount &&
        getTotalImportItems(previousSavedAddressAccount) === 1;
      const willDeleteIntegration =
        previousSavedIntegrations &&
        getTotalImportItems(previousSavedIntegrations) === 1;

      const newSavedAddressAccountById = {
        ...previousData?.importsByAccountMap,
      };
      const newSavedIntegrationById = {
        ...previousData?.importsByIntegration,
      };

      if (previousSavedIntegrations) {
        newSavedIntegrationById[file.exchangeName] = {
          ...previousSavedIntegrations,
          files: previousSavedIntegrations.files.filter(
            (f) => f.id !== file.id,
          ),
        };
      }

      if (previousSavedAddressAccount) {
        newSavedAddressAccountById[file.exchangeName] = {
          ...previousSavedAddressAccount,
          files: previousSavedAddressAccount.files.filter(
            (f) => f.id !== file.id,
          ),
        };
      }

      if (willDeleteAddressAccount) {
        delete newSavedAddressAccountById[file.exchangeName];
      }
      if (willDeleteIntegration) {
        delete newSavedIntegrationById[file.exchangeName];
      }

      // We aren't optimistically updating the files under the account
      // If we want to we can get inspiration from keys.ts
      const newData: AllSavedImports = {
        all:
          previousData?.all.filter(
            (p) => p.id !== file.exchangeName || !willDeleteAddressAccount,
          ) || [],
        importsByAccountMap: newSavedAddressAccountById,
        importsByIntegration: newSavedIntegrationById,
      };

      // Need to test what happens here.
      queryClient.setQueryData(viewModeKey, newData);
      return { previousData };
    },
    onError: (err, file, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(viewModeKey, context.previousData);
      }
      queryErrorHandler(err);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
      queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const useSyncCsvMutation = (name: string) => {
  const lang = useLang();
  const errorMessage = useSelector((state) =>
    state.lang.map.sync.syncFailed({ name }),
  );
  const uploadPending = useUploadPendingHandler();
  const syncSet = useSetSyncMutation();
  const viewModeKey = useViewModeSyncKey();

  return useMutation({
    retry: 3,
    mutationFn: async ({
      exchangeId,
      name,
      fileImportId,
      showMessage = true,
    }: {
      exchangeId: string;
      name: string;
      fileImportId: string;
      category: IntegrationCategory;
      showMessage?: boolean;
    }) => {
      captureAnalytics(importAccountKey("resync"), {
        exchange: exchangeId,
        type: "csv",
      });

      const res = await csvAPI.syncCSV(fileImportId);

      if (res.error) {
        syncSet.mutate({ scope: exchangeId, status: SyncStatusPlatform.Fail });
        throw new Error(errorMessage);
      }

      const message = lang.sync.syncSeveralMinutes({ name });

      uploadPending({
        name,
        id: exchangeId,
        message,
        showMessage,
      });

      return res.data;
    },
    onMutate: async ({ exchangeId, name, fileImportId, category }) => {
      const { previousData } = await optimisticallyUpdateKey({
        queryClient,
        viewModeKey,
        accountId: exchangeId,
        getUpdates: optimisticallyUpdateAccountPending(
          fileImportId,
          exchangeId,
          name,
          category,
          "files",
        ),
      });

      return {
        previousData,
      };
    },
    onError(
      err,
      params,
      context:
        | {
            previousData?: AllSavedImports;
          }
        | undefined,
    ) {
      const previousData = context?.previousData;
      queryClient.setQueryData(viewModeKey, previousData);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
    },
  });
};

function getCSVName(name: string, exchangeName: string, id: string) {
  let fileName = name || `${exchangeName}_${id}`;
  if (!fileName.toLowerCase().endsWith(".csv")) {
    fileName += ".csv";
  }
  return fileName;
}

export const useDownloadCsvMutation = () => {
  const errorMessage = useSelector(
    (state) => state.lang.map.imports.wentWrongDownloadFile,
  );
  const captureAnalytics = useCaptureAnalytics();
  const lang = useLang();

  return useMutation({
    mutationFn: async (file: SavedFileImport) => {
      try {
        const { id, name, exchangeName } = file;
        if (!id || !name || !exchangeName) {
          throw new Error("Missing required file information");
        }

        captureAnalytics("csv_redownload_clicked", {
          fileId: id,
          fileName: name,
          exchangeName,
        });

        console.log(`Initiating CSV download for file: ${id}`);

        // Show toast notification for download started
        const toastId = `csv-download-${id}`;
        displayMessage({
          message: lang.imports.downloadCsvDisplayMessage.info,
          type: DisplayMessage.Info,
          id: toastId,
        });

        // Get the signed URL and filename from the backend
        const response = await csvAPI.downloadCSV(id);
        if (response.error || !response.data) {
          console.error(
            "CSV download failed:",
            response.msg || "No response data",
          );
          throw new Error(response.msg || "Failed to get download URL");
        }

        const { url, filename } = response.data;

        if (!url) {
          console.error("CSV download failed: No signed URL received");
          throw new Error("No signed URL received from server");
        }

        console.log(
          `Received signed URL for download: ${url.substring(0, 100)}...`,
        );

        // Update toast to success
        displayMessage({
          message: lang.imports.downloadCsvDisplayMessage.success,
          type: DisplayMessage.Success,
          id: toastId,
        });

        // Use a suggested filename (either from server or fallback to a generated one)
        const finalFilename = filename || getCSVName(name, exchangeName, id);

        // Create a download anchor and trigger it
        const a = document.createElement("a");
        a.href = url;
        a.download = finalFilename;
        a.target = "_blank"; // Open in a new tab as fallback
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Track successful download
        captureAnalytics("csv_redownload_completed", {
          fileId: id,
          fileName: name,
          exchangeName,
        });

        return { id, success: true };
      } catch (err) {
        console.error("CSV download failed:", err);
        if (err instanceof Error) {
          displayMessage({
            message: errorMessage,
            type: DisplayMessage.Error,
          });
        }
        return { success: false };
      }
    },
  });
};
