import { Plan } from "@ctc/types";
import { ArrowForward } from "@mui/icons-material";
import { Box, Typography, useMediaQuery } from "@mui/material";
import type * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import short from "short-uuid";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { InviteLinkVariant } from "~/components/settings-modal/views/enums";
import { displayMessage } from "~/components/ui/Toaster";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { useSetActiveClient } from "~/hooks/useActiveClient";
import { useDesign } from "~/hooks/useTheme";
import {
  setUpdateBestActiveUser,
  useIsCollaborator,
  useUser,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import * as invitationsAPI from "~/services/invitations";
import { DisplayMessage } from "~/types/enums";
import { EditButtons } from "./EditButtons";

const MIN_LENGTH_CONSTRAINT = 4;
const MAX_LENGTH_CONSTRAINT = 24;

export const AccountantInviteLink = ({
  variant,
}: {
  variant: InviteLinkVariant;
}) => {
  const lang = useLang().settings;
  const user = useUser();
  const { tokens } = useDesign();

  const [inviteCode, setInviteCode] = useState(user?.inviteCode || "");
  const [loading, setLoading] = useState(false);
  const [inviteCodeEditMode, setInviteCodeEditMode] = useState(false);
  const [dupeError, setDupeError] = useState(false);
  const dispatch = useDispatch();
  const isTablet = useMediaQuery("(min-width: 1200px)");
  const urlSafeRegex = /^[A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=]+$/;
  const validationError = !urlSafeRegex.test(inviteCode);
  const minLengthError = inviteCode.length < MIN_LENGTH_CONSTRAINT;
  const maxLengthError = inviteCode.length > MAX_LENGTH_CONSTRAINT;
  const { setOpen } = useSettingsModal();
  const isCollaborator = useIsCollaborator();

  const setActiveClient = useSetActiveClient();
  const captureAnalytics = useCaptureAnalytics();

  useEffect(() => {
    if (user && user.paidPlan === Plan.Accountant && !user?.inviteCode) {
      const newCode = short().new();
      updateInviteCode(newCode, true);
    }
  }, [user?.inviteCode]);

  if (
    !user ||
    ![Plan.Accountant, Plan.Collaborator, Plan.Enterprise].includes(
      user.paidPlan,
    )
  )
    return null;

  // If the user is a collaborator, we need to get the invite code from the accountant
  const accountantInviteCode = isCollaborator
    ? user?.accountant?.inviteCode
    : user?.inviteCode;

  const uri = `${window.location.origin}/invite/${accountantInviteCode}`;

  const handleChangeInviteCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const update = e.target.value;
    setInviteCode(update);
    setDupeError(false);
  };

  const handleCancelInviteCode = () => {
    setInviteCode(user?.inviteCode || "");
    setInviteCodeEditMode(false);
  };

  const handleCopyClick = () => {
    captureAnalytics("copy_invite_link", { user: user?._id });
  };

  const updateInviteCode = async (newInviteCode: string, automated = false) => {
    setLoading(true);
    if (newInviteCode) {
      const res = await invitationsAPI.updateInviteCode(
        newInviteCode as string,
        automated,
      );
      if (res.error) {
        if (res.status === 409) {
          setDupeError(true);
          displayMessage({
            message: lang.profile.wentWrongUpdatingInviteCode,
            type: DisplayMessage.Error,
          });
        } else {
          setInviteCode(user?.inviteCode || "");
          displayMessage({
            message: lang.profile.wentWrongUpdatingInviteCode,
            type: DisplayMessage.Error,
          });
        }
      } else {
        setInviteCode(res.data.inviteCode);
        dispatch(setUpdateBestActiveUser({ inviteCode: res.data.inviteCode }));
        if (!res.data.automated) {
          displayMessage({
            message: lang.profile.inviteCodeChanged,
            type: DisplayMessage.Success,
          });
        }
        setInviteCodeEditMode(false);
      }
    }
    setLoading(false);
  };

  const noCode = !accountantInviteCode;
  const showWide = variant === InviteLinkVariant.WIDE && isTablet;

  const deselectClient = () => {
    //set the active client to be the accountant
    setActiveClient(user.uid);
  };

  return (
    <WrapperComponent variant={variant} isTablet={isTablet}>
      <Box>
        {variant !== InviteLinkVariant.EDIT ? (
          <Typography
            variant="Metropolis/Header/H5"
            sx={{ color: tokens.text.high, marginBottom: showWide ? 1 : 2 }}
          >
            {isCollaborator
              ? lang.accounting.inviteLink.titleWideCollaborator
              : lang.accounting.inviteLink.titleWide}
          </Typography>
        ) : null}
        <Typography variant="Metropolis/Body/Light" sx={{ marginBottom: showWide ? 0 : 2 }}>
          {
            lang.accounting.inviteLink[
              variant !== InviteLinkVariant.WIDE ? "body" : "bodyWide"
            ]
          }
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={
          isTablet &&
          variant !== InviteLinkVariant.EDIT &&
          variant !== InviteLinkVariant.READ
            ? "flex-end"
            : "flex-start"
        }
        gap="0.5rem"
      >
        <Box
          sx={{
            background: tokens.elevation.highest,
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            borderRadius: 1,
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "0.875rem",
              whiteSpace: "nowrap",
              overflow:
                isTablet && variant !== InviteLinkVariant.EDIT
                  ? "unset"
                  : "hidden",
              textOverflow: "ellipsis",
              paddingRight:
                isTablet && variant !== InviteLinkVariant.EDIT ? "0.5rem" : "0",
            }}
          >
            {noCode ? lang.accounting.inviteLink.generatingCode : uri}
          </Typography>
          <CopyIconButton contentToCopy={uri} handleClick={handleCopyClick} />
        </Box>

        {variant !== InviteLinkVariant.EDIT && !isCollaborator ? (
          <TextButton
            size="large"
            onClick={() => {
              deselectClient();
              setOpen(true);
            }}
          >
            <Typography
              style={{
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                fontSize: "0.75rem",
              }}
            >
              {lang.accounting.inviteLink.customiseLink}
              <ArrowForward
                style={{
                  color: tokens.text.brand,
                  marginLeft: "0.5rem",
                  fontSize: "1rem",
                }}
              />
            </Typography>
          </TextButton>
        ) : null}
      </Box>

      {variant === InviteLinkVariant.EDIT && !isCollaborator ? (
        <Box sx={{ marginTop: 2.5 }}>
          <EditButtons
            editMode={inviteCodeEditMode}
            setEditMode={setInviteCodeEditMode}
            value={inviteCode}
            handleChangeValue={handleChangeInviteCode}
            handleCancel={handleCancelInviteCode}
            handleUpdate={() => updateInviteCode(inviteCode)}
            buttonText={lang.profile.changeInviteCode}
            placeholder={lang.profile.addInviteCode}
            fieldName={lang.profile.inviteCode}
            inputProps={{
              minLength: MIN_LENGTH_CONSTRAINT,
              maxLength: MAX_LENGTH_CONSTRAINT,
              pattern: "[A-Za-z0-9-._~:/?#[\\]@!$&'()*+,;=]+", // URL safe validation
            }}
            disabled={loading}
            error={
              validationError || dupeError || minLengthError || maxLengthError
            }
            helperText={(() => {
              if (validationError) return lang.profile.validationError;
              if (dupeError) return lang.profile.duplicateError;
              if (minLengthError || maxLengthError)
                return lang.profile.lengthError;

              return undefined;
            })()}
          />
        </Box>
      ) : null}
    </WrapperComponent>
  );
};

const WrapperComponent = ({
  children,
  variant,
  isTablet,
}: {
  variant: InviteLinkVariant;
  children: React.ReactNode;
  isTablet: boolean;
}) => {
  const { tokens } = useDesign();
  const lang = useLang().settings;
  const isCollaborator = useIsCollaborator();

  if (variant === InviteLinkVariant.EDIT)
    return (
      <SettingsBox>
        {variant !== InviteLinkVariant.EDIT ? null : (
          <Typography variant="Metropolis/Header/H5">
            {isCollaborator
              ? lang.accounting.inviteLink.titleCollaborator
              : lang.accounting.inviteLink.title}
          </Typography>
        )}
        {children}
      </SettingsBox>
    );

  return (
    <Box
      sx={{
        padding: "0.5rem 1rem",
        background: tokens.elevation.medium,
        borderRadius: 1,
        border: `1px solid ${tokens.border.neutral.default}`,
        display: "flex",
        flexDirection:
          isTablet && variant !== InviteLinkVariant.READ ? "row" : "column",
        gap: isTablet && variant !== InviteLinkVariant.READ ? "1.5rem" : 0,
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      {children}
    </Box>
  );
};
