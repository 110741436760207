import { InfoOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Chip,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import styled from "styled-components/macro";

import { type PlanContainerData } from "~/components/plans/PlanContainer";
import { ChipColor } from "~/components/ui/enums";
import { SquareChip } from "~/components/ui/SquareChip";
import { useDesign } from "~/hooks/useTheme";
import { displayQuantity, safeFloat } from "~/lib/index";
import { useUser } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { PaymentCycle } from "~/types/enums";
import { type PlanDataBase } from "~/types/index";

import {
  INVESTOR_US_PLUS_TX_LIMIT,
  isLegacyUsInvestorUsPlusPlan,
} from "../payment/helpers";

export function PlanPrice<T extends PlanDataBase>({
  selectedPlan,
  selectedPaymentCycle = PaymentCycle.Annual,
  setSelectedPaymentCycle,
}: {
  selectedPlan: PlanContainerData<T>;
  selectedPaymentCycle?: PaymentCycle;
  setSelectedPaymentCycle?: (paymentCycle: PaymentCycle) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const locale = useLanguagePreference();
  const user = useUser();
  const planData = selectedPlan.data;

  if (!planData) return null;

  const specificPlanLang = lang.payment.base.copy.plans[selectedPlan.planType];

  const isCurrentPlan =
    user?.activeDataSource.paidPlan === selectedPlan.planType;
  const isLegacyUsInvestorUsPlus = isLegacyUsInvestorUsPlusPlan(
    user,
    selectedPlan.planType,
  );
  const txLimit = isLegacyUsInvestorUsPlus
    ? INVESTOR_US_PLUS_TX_LIMIT
    : planData.paywallLimits.txCountLimit || 0;

  return (
    <PlanPriceContainer>
      <Stack direction="column" alignItems="start" width="100%">
        <Stack direction="row" alignItems="center" gap="0.25rem">
          <Typography variant="Metropolis/Header/H1" sx={{ color: tokens.text.high }}>
            {selectedPlan.planName}
          </Typography>
          {isCurrentPlan && (
            <Chip
              label={lang.payment.base.copy.actions.current}
              size="small"
              icon={
                <CheckCircleIcon
                  sx={{ color: `${tokens.text.success} !important` }}
                />
              }
              sx={{
                color: tokens.text.success,
                backgroundColor: tokens.background.success.default,
              }}
            />
          )}
        </Stack>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          sx={{ color: tokens.text.low }}
        >
          {specificPlanLang.subtitle}
        </Typography>
      </Stack>
      <Box width="100%">
        <InfoContainer>
          <ValueWithUnit
            value={selectedPlan.planPriceInLocalCurrencyFormat}
            unit={lang.payment.paymentCycle[selectedPaymentCycle]}
          />
          <ValueWithUnit
            value={`${displayQuantity(safeFloat(txLimit), locale)}`}
            unit={lang.payment.totalTxs}
            endIcon={
              isLegacyUsInvestorUsPlus ? (
                <Tooltip title={lang.legacyPlanTooltip} placement="top">
                  <IconButton
                    size="small"
                    sx={{
                      position: "relative",
                      top: "0.3rem",
                    }}
                  >
                    <InfoOutlined
                      fontSize="small"
                      sx={{
                        color: tokens.icon.low,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              ) : null
            }
          />
        </InfoContainer>
        <PaymentCycleSwitcher
          selectedPaymentCycle={selectedPaymentCycle}
          setSelectedPaymentCycle={setSelectedPaymentCycle}
        />
        {"caption" in specificPlanLang ? (
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            color={tokens.text.low}
          >
            {specificPlanLang.caption}
          </Typography>
        ) : null}
      </Box>
    </PlanPriceContainer>
  );
}

function ValueWithUnit({
  value,
  unit,
  endIcon,
}: {
  value: string;
  unit: string;
  endIcon?: React.ReactNode;
}) {
  const { tokens } = useDesign();

  return (
    <Stack
      direction="row"
      alignItems="baseline"
      gap="0.25rem"
      width="100%"
      sx={{ whiteSpace: "nowrap" }}
    >
      <Typography variant="Metropolis/Header/H1" sx={{ color: tokens.text.high }}>
        {value}
      </Typography>
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        sx={{ color: tokens.text.low }}
      >
        {unit}
      </Typography>
      {endIcon ? endIcon : null}
    </Stack>
  );
}

function PaymentCycleSwitcher({
  selectedPaymentCycle,
  setSelectedPaymentCycle,
}: {
  selectedPaymentCycle: PaymentCycle;
  setSelectedPaymentCycle?: (paymentCycle: PaymentCycle) => void;
}) {
  const lang = useLang().payment;

  if (!setSelectedPaymentCycle) return null;

  return (
    <Stack direction="row" alignItems="center" gap="0.25rem" width="100%">
      <Stack direction="row" alignItems="center">
        <Typography variant="Metropolis/Caption/Medium/Regular">
          {lang.paymentCycle.monthly}
        </Typography>
        <Switch
          inputProps={{ "aria-label": "Switch payment cycle" }}
          defaultChecked
          onChange={() => {
            setSelectedPaymentCycle(
              selectedPaymentCycle === PaymentCycle.Monthly
                ? PaymentCycle.Annual
                : PaymentCycle.Monthly,
            );
          }}
        />
        <Typography variant="Metropolis/Caption/Medium/Regular">
          {lang.paymentCycle.annual}
        </Typography>
      </Stack>
      <Box>
        <SquareChip
          label={lang.save20}
          color={
            selectedPaymentCycle === PaymentCycle.Monthly
              ? ChipColor.GREY
              : ChipColor.GREEN
          }
          fontSize="0.75rem"
        />
      </Box>
    </Stack>
  );
}

const PlanPriceContainer = styled(Box)`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-wrap: wrap;
  flex-direction: column;
`;

const InfoContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
`;
