import { type UseQueryResult } from "@tanstack/react-query";

import { type PostActionsQueryResponse } from "~/services/actions";
import { FilterOperators } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

// Get user selected action rows
export const getSelectedRows = ({
  filter,
  actionsQuery,
}: {
  filter: FilterQuery;
  actionsQuery: UseQueryResult<PostActionsQueryResponse, Error>;
}) => {
  if (filter.type !== FilterOperators.ActionId) {
    return actionsQuery.data?.transactions ?? [];
  }
  return (
    actionsQuery.data?.transactions
      ?.filter((action) => filter.value.includes(action._id))
      .flat() ?? []
  );
};
