import { useExperimentVariant } from "~/analytics/posthog";
import { useIsMobile } from "~/components/ui/hooks";
import { useUser } from "~/redux/auth";
import { FeatureFlag } from "~/types/enums";

export function useIsOnboardingMobileImportExperiment(): boolean {
  const isHiddenInstructionsVariant =
    useIsOnboardingMobileHiddenInstructionsExperiment();
  const isDisplayedInstructionsVariant =
    useIsOnboardingMobileDisplayedInstructionsExperiment();

  if (isDisplayedInstructionsVariant || isHiddenInstructionsVariant) {
    return true;
  }

  return false;
}

export function useIsOnboardingMobileHiddenInstructionsExperiment(): boolean {
  const isEnabled = useIsOnboardingMobileExperimentEnabled();
  const isHiddenInstructionsVariant = useExperimentVariant(
    FeatureFlag.OnboardingMobileImport,
    "hidden-instructions",
  );

  if (!isEnabled) {
    return false;
  }

  return !!isHiddenInstructionsVariant;
}

export function useIsOnboardingMobileDisplayedInstructionsExperiment(): boolean {
  const isEnabled = useIsOnboardingMobileExperimentEnabled();
  const isDisplayedInstructionsVariant = useExperimentVariant(
    FeatureFlag.OnboardingMobileImport,
    "displayed-instructions",
  );

  if (!isEnabled) {
    return false;
  }

  return !!isDisplayedInstructionsVariant;
}

function useIsOnboardingMobileExperimentEnabled(): boolean {
  const isMobile = useIsMobile();
  const isOnboarding = !!useUser()?.isOnboarding;
  return isMobile && isOnboarding;
}
