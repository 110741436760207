import { ErrorOutline, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import type * as React from "react";
import { useRef } from "react";
import styled from "styled-components/macro";

import { StepItem } from "~/components/imports/ImportSteps";
import { useDesign } from "~/hooks/useTheme";
import { type ImportTypeInstruction } from "~/types/index";

export function ImportInstructionsAccordion({
  instruction,
  listItems,
  actions,
  titleDefault,
  noNumber = true,
  handleCaptureAnalytics,
}: {
  instruction: ImportTypeInstruction;
  listItems?: boolean;
  actions?: React.ReactNode;
  titleDefault?: string;
  noNumber?: boolean;
  handleCaptureAnalytics?: () => void;
}) {
  const { title, items } = instruction;
  const hasBeenOpenedRef = useRef(false);

  if (!items) return null;

  const titleResolved = title ?? titleDefault;

  return (
    <StyledAccordion
      disableGutters
      onChange={(_, expanded) => {
        if (expanded && handleCaptureAnalytics && !hasBeenOpenedRef.current) {
          handleCaptureAnalytics();
          hasBeenOpenedRef.current = true;
        }
      }}
    >
      {titleResolved ? (
        <ImportInstructionsAccordionTitle title={titleResolved} />
      ) : null}
      <StyledAccordionDetails sx={{ pb: 0 }}>
        {listItems ? (
          <ol style={{ paddingLeft: "1.5rem" }}>
            {items.map((item) => (
              <li key={item}>
                <Typography variant="Metropolis/Body/Light">{item}</Typography>
              </li>
            ))}
          </ol>
        ) : (
          <>
            {items.map((item: string, index) => (
              <Box p="0.5rem" key={item ?? index}>
                <StepItem
                  noNumber={noNumber}
                  item={item}
                  index={index}
                  totalSteps={items.length}
                />
              </Box>
            ))}
          </>
        )}
      </StyledAccordionDetails>
      {actions ? <Box ml="1rem">{actions}</Box> : null}
    </StyledAccordion>
  );
}

export function ImportInstructionsAccordionTitle({ title }: { title: string }) {
  const { tokens } = useDesign();
  return (
    <StyledAccordionSummary expandIcon={<ExpandMore />}>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box ml="0.5rem">
          <Typography
            sx={{
              color: tokens.text.default,
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <ErrorOutline />
        </Box>
      </Box>
    </StyledAccordionSummary>
  );
}

const StyledAccordion = styled(Accordion)`
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background: ${({ theme }) => theme.tokens.background.neutral.default};
  box-shadow: none;
  width: 100%;
  border-radius: 0.25rem;

  &:first-child {
    border-radius: 0.25rem 0.25rem 0 0;
  }

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:last-child {
    border-radius: 0 0 0.25rem 0.25rem;
  }

  &:first-child:last-child {
    border-radius: 0.25rem;
  }

  &:before {
    display: none;
    border-radius: 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    flex-direction: row-reverse;
    box-shadow: none;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    color: ${({ theme }) => theme.tokens.text.default};
    transform: rotate(-90deg);
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0);
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }
`;
