import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import type * as React from "react";
import { createContext, useEffect, useState } from "react";
import {
  type DefaultTheme,
  ThemeProvider as StyleComponentsThemeProvider,
} from "styled-components";
import useLocalStorageState from "use-local-storage-state";

import { useExperimentVariant } from "~/analytics/posthog";
import { getMUITheme } from "~/components/ui/theme/mui";
import { getTokens, getValidColorModes } from "~/components/ui/theme/tokens";
import { LocalStorageKey } from "~/constants/enums";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import {
  BrandStyle,
  FeatureFlag,
  type ResolvedTheme,
  Theme,
} from "~/types/enums";

type ThemeContextType = {
  /** The preferred color mode the user has chosen, or undefined if they have not chosen one */
  selectedTheme: Theme | undefined;
  /** The color mode that is currently active, might be different to the selectedTheme */
  resolvedTheme: ResolvedTheme;
  brandStyle: BrandStyle;
  setTheme: (theme: Theme) => void;
  setBrandStyle: (brandStyle: BrandStyle) => void;
};

const initialContext = {
  selectedTheme: Theme.System,
  // This will be resolved by the system preference
  resolvedTheme: Theme.Light as const,
  brandStyle: BrandStyle.CTC,
  setTheme() {},
  setBrandStyle() {},
};

export const ThemeContext = createContext<ThemeContextType>(initialContext);

const getSystemDarkModePreference = () => {
  return window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
    ? Theme.Dark
    : Theme.Light;
};

/**
 * Hook to get the system's dark mode preference
 * @returns Theme.Dark if system prefers dark mode, Theme.Light otherwise
 */
const useSystemDarkModePreference = (): ResolvedTheme => {
  const [systemPreference, setSystemPreference] = useState<ResolvedTheme>(
    getSystemDarkModePreference(),
  );

  useEffect(() => {
    const onChange = () => {
      setSystemPreference(getSystemDarkModePreference());
    };
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.addEventListener("change", onChange);

    return () => {
      matchMedia.removeEventListener("change", onChange);
    };
  }, [setSystemPreference]);

  return systemPreference;
};

export const ThemeContextProvider = ({
  themeOverride,
  children,
}: {
  themeOverride?: ResolvedTheme;
  children: React.ReactNode;
}) => {
  // If the app is embedded, we allow the user to override the CTC styling
  // with styling for their particular brand
  const [brandStyle, setBrandStyle] = useLocalStorageState<BrandStyle>(
    LocalStorageKey.BrandStyle,
    { defaultValue: BrandStyle.CTC },
  );

  const [userColorModePreference, setUserColorModePreference] =
    useLocalStorageState<Theme>(LocalStorageKey.Theme, {
      defaultValue: Theme.System,
    });
  const systemThemePreference = useSystemDarkModePreference();

  const getColorMode = () => {
    // If there is a theme override (color mode), use that
    // If the user has chosen "system" resolve that to whatever the system says
    // If the user has chosen light or dark, use that
    const resolvedUserColorModePreference = themeOverride
      ? themeOverride
      : userColorModePreference === Theme.System
        ? systemThemePreference
        : userColorModePreference;

    // Some color modes aren't valid for some brand styles
    // So first get all the valid color modes for the brand style
    const validColorModes = getValidColorModes(brandStyle);

    if (!resolvedUserColorModePreference) {
      // The user has no preference
      // return the first valid color mode
      return validColorModes[0];
    }

    // The user doesn't have a valid color mode preference for their chosen brand style
    // E.g. they prefer dark, but dark mode isn't available
    // So choose the first available color mode
    if (!validColorModes.includes(resolvedUserColorModePreference)) {
      return validColorModes[0];
    }

    // The user has a valid color mode preference for their chosen brand style
    return resolvedUserColorModePreference;
  };

  const getTheme = () => {
    const colorMode = getColorMode();

    const value = {
      selectedTheme: userColorModePreference,
      resolvedTheme: colorMode,
      setTheme: setUserColorModePreference,
      setBrandStyle,
      brandStyle,
    };

    const mui = getMUITheme(brandStyle, colorMode);
    const tokens = getTokens(brandStyle, colorMode);

    const styledComponentsTheme: DefaultTheme = { mui, tokens };
    return {
      resolvedTheme: colorMode,
      value,
      styledComponentsTheme,
      mui,
      tokens,
      brandStyle,
    };
  };

  const generateTheme = () => {
    if (themeOverride) {
      const colorMode = getColorMode();

      const mui = getMUITheme(brandStyle, colorMode);
      const tokens = getTokens(brandStyle, colorMode);
      const styledComponentsTheme: DefaultTheme = { mui, tokens };
      return {
        resolvedTheme: colorMode,
        brandStyle,
        value: {
          brandStyle,
          selectedTheme: themeOverride,
          resolvedTheme: colorMode,
          setTheme: setUserColorModePreference,
          setBrandStyle,
        },
        styledComponentsTheme,
        mui,
        tokens,
      };
    }
    return getTheme();
  };

  const { resolvedTheme, value, styledComponentsTheme, mui } = generateTheme();

  useEffect(() => {
    document.body.style.fontFamily = mui.typography.fontFamily as string;
  }, [mui]);

  /**
   * Start of experimental coinbase theme handling
   */
  const isEmbedded = useIsEmbedded();
  const isInCoinbaseThemeExperiment = useExperimentVariant(
    FeatureFlag.CoinbaseTheme,
    "test",
  );
  if (
    isInCoinbaseThemeExperiment &&
    !isEmbedded &&
    brandStyle !== BrandStyle.Coinbase
  ) {
    setBrandStyle(BrandStyle.Coinbase);
  }
  /**
   * End of experimental coinbase theme handling
   */

  return (
    <MuiThemeProvider theme={getMUITheme(brandStyle, resolvedTheme)}>
      <ThemeContext.Provider value={value}>
        <StyleComponentsThemeProvider theme={styledComponentsTheme}>
          {children}
        </StyleComponentsThemeProvider>
      </ThemeContext.Provider>
    </MuiThemeProvider>
  );
};
