import {
  LazyLoadImage,
  type LazyLoadImageProps,
} from "react-lazy-load-image-component";

function getUrl(country: string) {
  return new URL(`../assets/flags/${country}.svg`, import.meta.url).href;
}

export function CountryFlag({
  isoCode,
  ...props
}: { isoCode: string } & LazyLoadImageProps) {
  return (
    <LazyLoadImage
      src={getUrl(isoCode.toUpperCase())}
      alt={isoCode}
      {...props}
    />
  );
}
