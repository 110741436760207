import { SyncStatusPlatform } from "@ctc/types";
import type { QueryClient } from "@tanstack/react-query";

import { deleteRequest, get, post } from "~/services/core";
import { savedImports } from "~/state/importsV2";
import { type ImportType, IntegrationCategory } from "~/types/enums";
import {
  type AccountSummary,
  type AllSavedImports,
  type ImportOptionV2,
  type ObjectIdString,
  type SavedImportByIntegration,
  type SavedImportOptionByAccount,
  type SavedImportSync,
} from "~/types/index";

export function getSavedAccountByBlockchain(importId: string) {
  const path = `/imports/v2/saved-accounts-by-blockchain/${importId}`;
  return get<SavedImportOptionByAccount[]>(path);
}

export function getSavedAccountByAddress(importId: string) {
  const path = `/imports/v2/saved-accounts-by-address/${importId}`;
  return get<SavedImportOptionByAccount[]>(path);
}

export function getSavedAccountsByAddress() {
  const path = "/imports/v2/saved-accounts-by-address";
  return get<SavedImportOptionByAccount[]>(path);
}

export function getSavedAccountsByBlockchain() {
  const path = "/imports/v2/saved-accounts-by-blockchain";
  return get<SavedImportOptionByAccount[]>(path);
}

export function getSavedIntegration(importId: string) {
  const path = `/imports/v2/saved-integrations/${importId}`;
  return get<SavedImportByIntegration[]>(path);
}

export function getSavedIntegrations() {
  const path = "/imports/v2/saved-integrations";
  return get<SavedImportByIntegration[]>(path);
}

export function getAccountSummaryByAddress(accountId: string) {
  const path = `/imports/summary-by-address/${accountId}`;
  return get<AccountSummary>(path);
}

export function getAccountSummaryByBlockchain(accountId: string) {
  const path = `/imports/summary-by-blockchain/${accountId}`;
  return get<AccountSummary>(path);
}

export function getAvailableImports() {
  const path = "/imports/v2/available";
  return get<ImportOptionV2[]>(path);
}

export function markIntegrationVisited(integration: string) {
  const path = `/imports/integration-visited/${integration}`;
  return post(path, {});
}

export function deleteSync({ id }: { id: string }) {
  const path = `/imports/v2/sync/${id}`;
  return deleteRequest(path);
}

export function deleteImportByIdAndType({
  importType,
  importId,
}: {
  importType: ImportType;
  importId: string;
}) {
  const path = `/imports/v2/${importType}/${importId}`;
  return deleteRequest(path);
}

export function getSyncsByImportId(importId: string) {
  const path = `/imports/v2/${importId}/syncs`;
  return get<SavedImportSync[]>(path);
}

/**
 * Used by mutations to optimistically update local state after an import.
 */
export type GetUpdatesFunc = ({
  prevByAccount,
  prevByIntegration,
}: {
  prevByAccount: SavedImportOptionByAccount | undefined;
  prevByIntegration: SavedImportByIntegration | undefined;
}) => {
  newByAccount: SavedImportOptionByAccount | null;
  newByIntegration: SavedImportByIntegration | null;
};

export function optimisticallyUpdateAccountPending(
  accountId: ObjectIdString | undefined,
  exchangeId: ObjectIdString,
  name: string,
  category: IntegrationCategory,
  fieldToUpdate: "oauths" | "keys" | "files",
): GetUpdatesFunc {
  return ({ prevByAccount, prevByIntegration }) => {
    const newByAccount: SavedImportOptionByAccount = {
      id: exchangeId,
      name,
      category,
      availableImportMethods: [],
      assets: [],
      value: 0,
      totalTxCount: undefined,
      spamTxCount: undefined,
      lastImportedTxTimestamp: null,
      lastSyncComplete: null,
      wallets: [],
      files: [],
      keys: [],
      oauths: [],
      ...prevByAccount,
      syncStatus: SyncStatusPlatform.Pending,
      ...{
        [fieldToUpdate]: (prevByAccount?.[fieldToUpdate] || []).map(
          (account) =>
            account.id === accountId
              ? { ...account, status: SyncStatusPlatform.Pending }
              : account,
        ),
      },
    };

    const newByIntegration: SavedImportByIntegration = {
      id: exchangeId,
      name,
      category: IntegrationCategory.CEX,
      files: [],
      wallets: [],
      keys: [],
      oauths: [],
      ...prevByIntegration,
      ...{
        [fieldToUpdate]: (prevByAccount?.[fieldToUpdate] || []).map(
          (account) =>
            account.id === accountId
              ? { ...account, status: SyncStatusPlatform.Pending }
              : account,
        ),
      },
    };

    return {
      newByAccount,
      newByIntegration,
    };
  };
}

/**
 * Used by mutations to optimistically update local state after an import.
 */
export async function optimisticallyUpdateKey({
  queryClient,
  viewModeKey,
  accountId,
  getUpdates,
}: {
  queryClient: QueryClient;
  viewModeKey: string[];
  accountId: string;
  getUpdates: GetUpdatesFunc;
}): Promise<{ previousData?: AllSavedImports; newData: AllSavedImports }> {
  await queryClient.cancelQueries({ queryKey: savedImports.all() });
  const previousData = queryClient.getQueryData<AllSavedImports>(viewModeKey);
  const previousSavedAccountImport =
    previousData?.importsByAccountMap[accountId];
  const previousSavedIntegration =
    previousData?.importsByIntegration[accountId];

  const { newByAccount, newByIntegration } = getUpdates({
    prevByAccount: previousSavedAccountImport,
    prevByIntegration: previousSavedIntegration,
  });

  let newAll: SavedImportOptionByAccount[] = [];
  const newImportsByAccountMap = { ...previousData?.importsByAccountMap };
  const newImportsByIntegration = { ...previousData?.importsByIntegration };

  if (newByAccount) {
    newAll =
      previousData?.all
        .filter((s) => s.id !== accountId)
        .concat([newByAccount]) || [];
    newImportsByAccountMap[accountId] = newByAccount;
  } else {
    delete newImportsByAccountMap[accountId];
    newAll = previousData?.all.filter((s) => s.id !== accountId) || [];
  }

  if (newByIntegration) {
    newImportsByIntegration[accountId] = newByIntegration;
  } else {
    delete newImportsByIntegration[accountId];
  }

  const newData: AllSavedImports = {
    all: newAll,
    importsByAccountMap: newImportsByAccountMap,
    importsByIntegration: newImportsByIntegration,
  };

  queryClient.setQueryData(viewModeKey, newData);
  return {
    previousData,
    newData,
  };
}
