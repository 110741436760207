import { Blockchain, SyncStatusPlatform } from "@ctc/types";
import {
  Cached,
  Delete,
  Edit,
  OpenInNew,
  RestartAlt,
} from "@mui/icons-material";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Typography } from "@mui/material";
import { useState } from "react";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { HardSyncConfirmationDialog } from "~/components/imports/HardSyncConfirmationDialog";
import {
  formatHardSyncTimestamp,
  formatLastSyncTimestamp,
  getOptionFilterForOnboarding,
} from "~/components/imports/helpers";
import { ImportErrorReportingBox } from "~/components/imports/import-error-report-experiment/ImportErrorBox";
import { ImportIndicator } from "~/components/imports/ImportHeaderStatus";
import { ImportListItemContainer } from "~/components/imports/ImportListContainer";
import {
  ImportListItemDetails,
  ImportListItemWrapper,
} from "~/components/imports/ImportListItemWrapper";
import { SetRules, SyncedFrom } from "~/components/imports/ImportMetadata";
import { LastSyncTimestampWrapper } from "~/components/imports/LastSyncTimestampWrapper";
import { useInImportErrorReportExperiment } from "~/components/imports/useImportErrorReportExperiment";
import { type WalletImportListProps } from "~/components/imports/wallet/WalletImportList";
import { ImportRelatedWalletsDialog } from "~/components/imports-v2/ImportRelatedWalletsDialog";
import { MoreOptionsDropdown } from "~/components/imports-v2/MoreOptionsDialog";
import { SyncTableDialog } from "~/components/imports-v2/SyncHistoryDialog";
import {
  type ImportDrawerOptionsType,
  ImportOptionAction,
} from "~/components/imports-v2/types";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { formatDisplayAddress } from "~/components/transactions/helpers";
import { AddressChipWithNickname } from "~/components/ui/AddressChip";
import { displayMessage } from "~/components/ui/Toaster";
import { useDesign } from "~/hooks/useTheme";
import { hashExchangeId } from "~/lib/hashExchangeId";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { useGetLockPeriodEndDateIfExists } from "~/state/period";
import { useUserSyncStatus } from "~/state/sync";
import { useSyncWalletMutation } from "~/state/wallets";
import {
  DisplayMessage,
  FilterOperators,
  ImportType,
  RelatedWalletsAction,
} from "~/types/enums";
import {
  BlockchainName,
  isEVMBlockchain,
  type SavedWalletImport,
} from "~/types/index";

import { useIsEmbedded } from "../../../hooks/useIsEmbedded";

export const BTC_PUB_ADDRESS_REGEX = /^([xyz]pub)/;

function assertIsBlockchainBtc(
  blockchain: string,
): blockchain is Blockchain.BTC {
  return blockchain === Blockchain.BTC;
}

export function WalletItem({
  wallet,
  blockchain,
  highlighted = false,
  handleDelete,
  handleEdit,
  walletName,
}: {
  wallet: SavedWalletImport;
  walletName: string;
  blockchain: Blockchain;
  highlighted?: boolean;
  handleDelete: WalletImportListProps["handleDelete"];
  handleEdit: WalletImportListProps["handleEdit"];
}) {
  const isEmbedded = useIsEmbedded();
  const userSyncStatus = useUserSyncStatus();
  const isOnboarding = !!useUser()?.isOnboarding;
  const lang = useLang();
  const { tokens } = useDesign();
  const [isSyncHistoryOpen, setSyncHistoryOpen] = useState(false);
  const [isRelatedWalletsOpen, setRelatedWalletsOpen] = useState(false);
  const syncWallet = useSyncWalletMutation();
  const captureAnalytics = useCaptureAnalytics();
  const lockPeriodEndDate = useGetLockPeriodEndDateIfExists();
  const isInImportErrorExperiment = useInImportErrorReportExperiment();

  const { status, importFromDate, errorIssue } = wallet;

  const isSyncing = status === SyncStatusPlatform.Pending;

  const timezone = useTimezone();
  const walletLastSync = wallet.completedAt
    ? formatLastSyncTimestamp(new Date(wallet.completedAt), timezone)
    : null;
  const dateTime = wallet.lastImportedTxTimestamp
    ? formatLastSyncTimestamp(wallet.lastImportedTxTimestamp, timezone)
    : lang.imports.bulkSyncTooltip.lastSyncTime;

  const name = wallet.name
    ? `"${wallet.name}"`
    : BlockchainName[wallet.blockchain];
  const softSyncTitle = `${lang.wallet.softSync.title({
    name: wallet.name || "",
  })} ${dateTime}`;
  const hardSyncTitle = lang.wallet.hardSync.titleOneWallet({
    name,
    date: formatHardSyncTimestamp(
      importFromDate,
      timezone,
      lang,
      lockPeriodEndDate,
    ),
  });
  const hardSyncText = lang.wallet.hardSync.textOneWallet({
    name,
    date: formatHardSyncTimestamp(
      importFromDate,
      timezone,
      lang,
      lockPeriodEndDate,
    ),
  });

  function handleSync(wallet: SavedWalletImport, isHardSync: boolean) {
    if (!isSyncing) {
      displayMessage({
        message: lang.imports.syncingWallet({
          name,
        }),
        type: DisplayMessage.Info,
      });
      syncWallet.mutate({
        wallet,
        isHardSync,
        relatedWalletsAction: RelatedWalletsAction.Notify,
      });
      captureAnalytics("data_source_synced", {
        ...wallet,
        isHardSync,
      });
    } else {
      displayMessage({
        message: lang.imports.apiStillSyncing,
        type: DisplayMessage.Info,
      });
    }
  }

  const handleSoftSync = () => {
    handleSync(wallet, false);
  };

  const [isHardSyncOpen, setIsHardSyncOpen] = useState(false);

  const handleHardSync = () => {
    setIsHardSyncOpen(true);
  };

  const handleViewTxs = () => {
    const link = getTransactionPageLink({
      state: {
        filter: {
          type: FilterOperators.And,
          rules: [
            {
              type: FilterOperators.Source,
              value: [wallet.id],
            },
          ],
        },
      },
    });

    window.open(link, isEmbedded ? "_self" : "_blank");
  };

  const options: ImportDrawerOptionsType[] = [
    // view txs
    {
      id: ImportOptionAction.ViewTransactions,
      label: lang.reconciliation.viewTransactions,
      icon: <OpenInNew fontSize="small" />,
      handler: handleViewTxs,
    },
    // soft sync
    {
      id: ImportOptionAction.Sync,
      disabled: isSyncing,
      tooltipTitle: softSyncTitle,
      label: isSyncing ? lang.imports.syncing : lang.imports.sync,
      icon: <Cached fontSize="small" />,
      handler: handleSoftSync,
    },
    {
      id: ImportOptionAction.SyncHistory,
      disabled: isSyncing,
      tooltipTitle: lang.syncHistory.title,
      label: lang.syncHistory.title,
      icon: <HistoryIcon fontSize="small" />,
      handler: () => {
        setSyncHistoryOpen(true);
      },
    },
    // hard sync

    ...(!isSyncing
      ? [
          {
            id: ImportOptionAction.HardSync,
            tooltipTitle: hardSyncTitle,
            label: lang.imports.hardSync,
            icon: <RestartAlt fontSize="small" />,
            handler: handleHardSync,
          },
        ]
      : []),
    // related wallets (EVM only)
    ...(isEVMBlockchain(wallet.blockchain)
      ? [
          {
            id: ImportOptionAction.RelatedWallets,
            label: lang.wallet.importRelatedWallets.actionTitle,
            tooltipTitle: lang.wallet.importRelatedWallets.actionTooltip,
            icon: <FindReplaceIcon fontSize="small" />,
            handler: () => {
              setRelatedWalletsOpen(true);
            },
          },
        ]
      : []),
    // edit
    {
      id: ImportOptionAction.Edit,
      label: lang.edit,
      icon: <Edit fontSize="small" />,
      handler: () => {
        handleEdit(wallet);
      },
    },
    // delete
    {
      id: ImportOptionAction.Delete,
      label: lang.delete,
      icon: <Delete fontSize="small" />,
      handler: () => {
        handleDelete(wallet);
      },
    },
  ].filter(getOptionFilterForOnboarding(isOnboarding));

  const isValidBtcAddress = BTC_PUB_ADDRESS_REGEX.test(wallet.address);

  function getImportListItemContainerProps(): Partial<{
    listItemDescription?: string;
    status?: string;
    helpText?: string;
  }> {
    // Need to wait for import sync and report refresh success
    if (
      wallet.status !== SyncStatusPlatform.Success ||
      userSyncStatus !== SyncStatusPlatform.Success
    ) {
      return {};
    }
    const importedTxCount = wallet.importedTxCount;
    const isTxCountZero = importedTxCount === 0;
    const status = isTxCountZero ? "warning" : undefined;
    const defaultItemWarningDescProps = isTxCountZero
      ? {
          listItemDescription: lang.integrationWarnings.default.noTxs,
        }
      : {};

    switch (wallet.blockchain) {
      case Blockchain.BTC:
        const invalidWallet =
          lang.integrationWarnings[wallet.blockchain].invalidWallet;

        // check if btc wallet address is an invalid (xyz)pub address
        if (isValidBtcAddress) return {};

        return {
          status,
          listItemDescription: isTxCountZero
            ? invalidWallet.noTxs
            : invalidWallet.wrongFormat,
        };
      default:
        return {
          status,
          ...defaultItemWarningDescProps,
        };
    }
  }

  return (
    <>
      <HardSyncConfirmationDialog
        open={isHardSyncOpen}
        title={lang.wallet.hardSync.confirmationOneWallet}
        setOpen={setIsHardSyncOpen}
        onHardSync={() => {
          handleSync(wallet, true);
        }}
        text={hardSyncText}
        blockchain={blockchain}
      />
      <SyncTableDialog
        import={wallet}
        isOpen={isSyncHistoryOpen}
        setOpen={setSyncHistoryOpen}
        name={formatDisplayAddress(
          wallet.name ?? wallet.address,
          false,
          isEVMBlockchain(wallet.blockchain),
        )}
      />
      {isEVMBlockchain(wallet.blockchain) ? (
        <ImportRelatedWalletsDialog
          name={wallet.name}
          isOpen={isRelatedWalletsOpen}
          handleClose={() => {
            setRelatedWalletsOpen(false);
          }}
          address={{
            address: wallet.address,
            blockchain: wallet.blockchain,
            importSource: wallet.importSource ?? undefined,
          }}
        />
      ) : null}
      <ImportListItemContainer {...getImportListItemContainerProps()}>
        <ImportListItemWrapper highlighted={highlighted}>
          {status === SyncStatusPlatform.Fail ? (
            <ImportIndicator
              importType={ImportType.API}
              syncStatus={status}
              tooltipTitle={errorIssue}
              exchangeName={walletName}
              useWarningIconForBtc={
                !isValidBtcAddress &&
                assertIsBlockchainBtc(wallet.blockchain) &&
                wallet.importedTxCount === 0
              }
            />
          ) : status ? (
            <LastSyncTimestampWrapper
              status={status}
              lastCompleted={
                wallet.completedAt ? new Date(wallet.completedAt) : undefined
              }
              errorCode={wallet.error?.status}
              errorMsg={wallet.error?.message}
            >
              <Box component="span" display="inline-flex" mr={1}>
                <ImportIndicator
                  importType={ImportType.API}
                  exchangeName={walletName}
                  syncStatus={status}
                  useWarningIconForBtc={
                    !isValidBtcAddress &&
                    assertIsBlockchainBtc(wallet.blockchain) &&
                    wallet.importedTxCount === 0
                  }
                />
              </Box>
            </LastSyncTimestampWrapper>
          ) : null}
          <AddressChipWithNickname
            nickname={wallet.name || undefined}
            address={wallet.address}
            blockchain={blockchain}
            enableCopy
            enableLink
          />
          <Box display="flex" alignItems="center" ml="auto">
            <MoreOptionsDropdown options={options} tooltipPlacement="right" />
          </Box>
        </ImportListItemWrapper>
        {walletLastSync ? (
          <ImportListItemDetails
            sx={{
              marginBottom: isInImportErrorExperiment
                ? "0rem !important"
                : "0.25rem",
            }}
          >
            <Typography
              variant="Metropolis/Body/Regular"
              color={tokens.text.low}
              sx={{
                fontSize: "0.75rem",
                lineHeight: "1rem",
              }}
            >
              {lang.reOnboarding.sync.lastSynced}: {walletLastSync}
            </Typography>
          </ImportListItemDetails>
        ) : null}
        <SetRules
          savedImportId={hashExchangeId({
            exchange: wallet.address,
            blockchain: wallet.blockchain,
          })}
        />
        <SyncedFrom importFromDate={importFromDate} />
        {highlighted ? null : (
          <ImportErrorReportingBox
            issue={errorIssue}
            handleSoftSync={handleSoftSync}
            handleUpdate={() => {
              handleEdit(wallet);
            }}
            importType={ImportType.Wallet}
            exchangeName={walletName}
            syncStatus={status}
          />
        )}
      </ImportListItemContainer>
    </>
  );
}
