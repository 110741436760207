import {
  ARCHIVE_STATUS_ACTIVE,
  Country,
  Env,
  Plan,
  SupportedLang,
} from "@ctc/types";
import { isValidReferrerSource } from "@ctc/types/src/referrer-source";
import {
  Box,
  StyledEngineProvider,
  type Theme,
  Typography,
} from "@mui/material";
import { mainnet } from "@reown/appkit/networks";
import { createAppKit } from "@reown/appkit/react";
import { Elements } from "@stripe/react-stripe-js";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import Cookies from "js-cookie";
import posthog from "posthog-js";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { parse, stringify } from "query-string";
import * as React from "react";
import { lazy, Suspense, useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import {
  determineEventSource,
  determineTaxHubClient,
} from "~/analytics/events";
import { identifyGoogleUser } from "~/analytics/google";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { Alpha } from "~/components/alpha/index";
import { BalanceLedger } from "~/components/balance-ledger/BalanceLedger";
import { BusinessClientPaymentSuccess } from "~/components/clients/BusinessClientPaymentStatus";
import { Developer } from "~/components/developer/index";
import { AutoClose } from "~/components/imports/AutoClose";
import { LocalPosthogCensor } from "~/components/LocalPosthogCensor";
import { Layout } from "~/components/nav/NavBar";
import { CouponBanner } from "~/components/notifications/index";
import { setMetaMaskOnboardingPayloadFromQuery } from "~/components/onboarding-v2/helpers";
import { PageRefresher } from "~/components/PageRefresher";
import { DefaultPageTitle } from "~/components/PageTitle";
import { PartnerBenefit } from "~/components/partner-benefit/index";
import { Payment } from "~/components/payment/Payment";
import { getInitialReferrerSource } from "~/components/payment/referrer-config";
import { stripePromise } from "~/components/payment/StripePayment";
import {
  PaymentStatus,
  PaymentStatusSuccess,
} from "~/components/payment-status/index";
import { useIsBusinessPlansEnabled } from "~/components/plans/hooks/useIsBusinessPlansEnabled";
import { queryClient } from "~/components/queryClient";
import { ReferAFriend } from "~/components/refer-a-friend/ReferAFriend";
import { RestoreUserData } from "~/components/RestoreUserData";
import { OptInSupportAccess } from "~/components/settings-modal/views/profile/OptInSupportAccess";
import { ErrorBoundaryWrapper } from "~/components/ui/error-boundary/ErrorBoundaryWrapper";
import { useIsMobile } from "~/components/ui/hooks";
import { LogoSpinner } from "~/components/ui/LogoSpinner";
import { CustomToaster, displayMessage } from "~/components/ui/Toaster";
import { ConnectProvider } from "~/components/user/ConnectProvider";
import { ForgotPasswordWrapper } from "~/components/user/ForgotPasswordWrapper";
import { ImportRelatedWalletsNotifier } from "~/components/user/ImportRelatedWalletsNotifier";
import { InviteLinkPage } from "~/components/user/invite-link-page/InviteLinkPage";
import { LoginWrapper } from "~/components/user/LoginWrapper";
import { ResetPasswordForm } from "~/components/user/ResetPasswordForm";
import { SignupWrapper } from "~/components/user/SignupWrapper";
import { TokenLogin } from "~/components/user/TokenLogin";
import { UserDetailsPage } from "~/components/user/UserDetails";
import { UserPopupManager } from "~/components/user/UserPopupManager";
import { UserUnlockRedirect } from "~/components/user/UserUnlockRedirect";
import { WagmiWrapper } from "~/components/user/wallet-auth/WagmiProvider";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { VsCodeClick } from "~/components/VsCodeClick";
import { AnalyticsPageName, DISPLAY_BANNER } from "~/constants/constants";
import {
  AuthStateValue,
  BootstrapQueryParameter,
  LocalStorageKey,
} from "~/constants/enums";
import { EmailVerificationContext } from "~/contexts/EmailVerificationContext";
import { ContextProvider } from "~/contexts/index";
import { SettingsModalProvider } from "~/contexts/SettingsModalContext";
import { ThemeContextProvider } from "~/contexts/ThemeContext";
import { LoginRedirectContextProvider } from "~/contexts/useRedirectLogin";
import { WebsocketProvider } from "~/contexts/WebSocketContext";
import {
  getSelectedClientFromCookie,
  useActiveClient,
} from "~/hooks/useActiveClient";
import { useAutoDetectedCountry } from "~/hooks/useAutoDetectedCountry";
import { useHideIntercom } from "~/hooks/useHideIntercom";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { usePreviousWithUseEffect } from "~/hooks/usePrevious";
import { useDesign, useResolvedTheme, useThemeContext } from "~/hooks/useTheme";
import { useTransactionsTotalCount } from "~/hooks/useTransactions";
import { APPKIT_PROJECT_ID, wagmiAdapter } from "~/lib/appKit";
import {
  loadUser,
  useAuth,
  useCountry,
  useIsAccountant,
  useIsManagingClients,
  useIsStripeEnabled,
  useLogout,
  usePlan,
  useUserArchiveStatus,
  useUserHasPaid,
} from "~/redux/auth";
import {
  isDeveloperEnabled,
  setFeatureFlagOverridesFromStorage,
} from "~/redux/developer";
import { LoadUserType } from "~/redux/enums";
import { getLanguageOptions, updateLanguage, useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { store } from "~/redux/store";
import { Analytics } from "~/segment/index";
import { CouponService } from "~/services/coupon";
import { updateIntercomUser } from "~/services/intercom";
import { importsKeys, preFetchAllImportOptions } from "~/state/imports";
import { useReconciliationIntercomData } from "~/state/reconciliation";
import { useSetReferrerMutation } from "~/state/settings";
import {
  DisplayMessage,
  FeatureFlag,
  isValidBrandStyle,
  isValidTheme,
  Links,
  Theme as ThemeEnum,
} from "~/types/enums";
import { BrowserLanguageMap } from "~/types/index";

import { SuspenseCircularProgress } from "./ui/SuspenseCircularProgress";
import { getDefaultInAppRedirectPath } from "~/utils/navigation";

declare module "@mui/styles/defaultTheme" {
  // Applying this ESLint autofix will cause a type error in src/components/report/components/SummaryBox/SummaryBox.tsx
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// To use this, copy your .env file and name it .env.local
// add `VITE_APP_RQ_LOCAL_STORAGE=true` to your .env.local file
// this will use local storage to persist all your network calls
// means you dont have to refetch data when you refresh the page
if (import.meta.env.VITE_APP_RQ_LOCAL_STORAGE === "true") {
  const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
  });
  persistQueryClient({
    queryClient: queryClient as any,
    persister: localStoragePersister,
    maxAge: 24 * 60 * 60 * 1000, // 24 hours in miliseconds
  });
}

// https://stackoverflow.com/questions/58687397/react-lazy-and-prefetching-components
function lazyWithPreload(factory: () => any) {
  const Component = React.lazy(factory);
  // @ts-ignore
  Component.preload = factory;
  return Component;
}

const Dashboard = lazy(() =>
  import("~/components/dashboard/index").then((module) => ({
    default: module.Dashboard,
  })),
);
const Transactions = lazy(() =>
  import("~/components/transactions/index").then((module) => ({
    default: module.TransactionsPage,
  })),
);
// const AddManualTransaction = lazy(
//   () => import("./transactions/manual/AddManualTransaction"),
// );
const Report = lazy(() =>
  import("~/components/report/index").then((module) => ({
    default: module.Report,
  })),
);
const AccountantCurrentPlan = lazy(() =>
  import("~/components/payment/AccountantCurrentPlan").then((module) => ({
    default: module.AccountCurrentPlan,
  })),
);
const ContactsList = lazy(() =>
  import("~/components/contacts/ContactsList").then((module) => ({
    default: module.ContactsList,
  })),
);
const RulesList = lazy(() =>
  import("~/components/rules/index").then((module) => ({
    default: module.RulesPage,
  })),
);
const AccountingIntegrations = lazy(() =>
  import("~/components/accounting-integrations/index").then((module) => ({
    default: module.AccountingIntegrationsPage,
  })),
);
const Billing = lazy(() =>
  import("~/components/settings-modal/views/billing/index").then((module) => ({
    default: module.Billing,
  })),
);
const Clients = lazy(() =>
  import("~/components/clients/index").then((module) => ({
    default: module.Clients,
  })),
);
const Reconciliation = lazy(() =>
  import("~/components/reconciliation/index").then((module) => ({
    default: module.ReconciliationContent,
  })),
);
const Imports = lazyWithPreload(() =>
  import("~/components/imports/index").then((module) => ({
    default: module.Imports,
  })),
);
const DashboardEnterprise = lazy(() =>
  import("~/components/dashboard-enterprise/index").then((module) => ({
    default: module.DashboardEnterprise,
  })),
);
const OnboardingV2 = lazy(() =>
  import("~/components/onboarding-v2/index").then((module) => ({
    default: module.OnboardingV2,
  })),
);
const UserOnboarding = lazy(() =>
  import("~/components/onboarding-v2/context/onboarding").then((module) => ({
    default: module.UserOnboarding,
  })),
);
const ReOnboarding = lazy(() =>
  import("~/components/re-onboarding-flow/index").then((module) => ({
    default: module.ReOnboarding,
  })),
);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

/* Custom global scroll bar styles */
const GlobalStyles = createGlobalStyle`
  ::-webkit-scrollbar {
    width:1rem;
  }
  ::-webkit-scrollbar-corner {
    background:transparent;
  }
  ::-webkit-scrollbar-track {
    background:transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.tokens.border.neutral.medium};
    border-radius: 1rem;
    border: 0.25rem solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.tokens.border.neutral.high};
  }
`;

createAppKit({
  adapters: [wagmiAdapter],
  networks: [mainnet],
  projectId: APPKIT_PROJECT_ID,
  enableWalletGuide: false,
  termsConditionsUrl: "https://cryptotaxcalculator.io/terms-of-service",
  privacyPolicyUrl: "https://cryptotaxcalculator.io/privacy-policy",
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
    email: false,
    socials: false,
  },
});

function getLoginRedirectPath(isEmbedded: boolean) {
  if (!isEmbedded) return Links.Login;

  const hasPreviousLogin = localStorage.getItem(
    LocalStorageKey.HasPreviousLogin,
  );
  if (!hasPreviousLogin) {
    return Links.Signup;
  }

  return Links.Login;
}

/**
 * Displays an error message when an invalid referrer source is provided in embedded mode.
 * This component shows a centered error message explaining that the referrer ID is not valid.
 *
 * @returns {JSX.Element} A Box component containing the error message
 */
function InvalidReferrerError() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      gap={2}
    >
      <Typography variant="Metropolis/Header/H5" color="error">
        Invalid referrerId
      </Typography>
      <Typography>
        The provided referrerId is not valid for embedded mode.
      </Typography>
    </Box>
  );
}

const App = () => {
  const { user, error: authError, loading } = useAuth();
  const uid = user?.uid;

  /**
   * Apply coupon to future subscription renewals
   * This is for coinbase users who have a coinbase one subscription and
   * a paid ctc account.
   * We want to apply a discount to their future subscription renewals
   */
  // useAutoApplyFutureRenewalCoupon();

  /**
   * Detect the user's country and store it in localStorage
   */
  useAutoDetectedCountry();

  const isPaidPlan = useUserHasPaid();
  const archiveStatus = useUserArchiveStatus();
  const isManagingClients = useIsManagingClients();
  const isAccountant = useIsAccountant();
  const activeClient = useActiveClient();
  const transactionTotalCount = useTransactionsTotalCount();
  const taxSettings = useTaxSettings();
  const dispatch = useDispatch();
  const lang = useLang();
  const plan = usePlan();
  const country = useCountry();
  const navigate = useNavigate();
  const query = useParams() as Record<string, string | undefined>;
  const isStripeEnabled = useIsStripeEnabled();
  const reconciliationIntercomData = useReconciliationIntercomData();
  const urlParams = new URLSearchParams(useLocation().search);
  const { tokens } = useDesign();
  const theme = useResolvedTheme();
  const emailVerificationContext = useContext(EmailVerificationContext);
  const location = useLocation();
  const captureAnalytics = useCaptureAnalytics();
  const isBusinessClientEnabled = useIsBusinessPlansEnabled();
  const themeContext = useThemeContext();
  const isEmbedded = useIsEmbedded();
  const isMobile = useIsMobile();
  useHideIntercom(isMobile && !!user);
  const usHobbyistPlusExperimentVariant = useFeatureFlagVariantKey(
    FeatureFlag.USHobbyistPlusExperiment,
  );
  const cookieUSHobbyistPlusExperimentVariant = Cookies.get(
    FeatureFlag.USHobbyistPlusExperiment,
  );
  const logout = useLogout();

  useEffect(() => {
    let timeoutId: number;
    const urlParams = new URLSearchParams(window.location.search);
    const initialBrandStyle = urlParams.get(
      BootstrapQueryParameter.BrandStyles,
    );
    const initialColorMode = urlParams.get(BootstrapQueryParameter.ColorMode);
    if (
      isValidBrandStyle(initialBrandStyle) ||
      isValidTheme(initialColorMode)
    ) {
      if (isValidBrandStyle(initialBrandStyle)) {
        themeContext.setBrandStyle(initialBrandStyle);
      }
      if (isValidTheme(initialColorMode)) {
        themeContext.setTheme(initialColorMode);
      }
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [themeContext]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get(BootstrapQueryParameter.Lang);
    // use the query lang if exists
    if (lang && BrowserLanguageMap[lang]) {
      dispatch(updateLanguage(BrowserLanguageMap[lang]));
    } else {
      // fallback to local storage lang if exists
      const localLang = localStorage.getItem(LocalStorageKey.Lang);
      const isSupportedLang = Object.values(SupportedLang).includes(
        localLang as SupportedLang,
      );
      if (localLang && isSupportedLang) {
        dispatch(updateLanguage(localLang as SupportedLang));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmbedded) {
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    setMetaMaskOnboardingPayloadFromQuery(urlParams);
  }, [isEmbedded]);

  // Initialize coupon from URL or cookies
  CouponService.storeManualCoupon(urlParams);

  useEffect(() => {
    const referrerSource = getInitialReferrerSource();
    if (!referrerSource) {
      return;
    }
    localStorage.setItem(LocalStorageKey.ReferrerSource, referrerSource);
  }, [isEmbedded]);

  // save country to local storage
  const countryParam = urlParams.get("country");
  if (
    countryParam &&
    Object.values(Country).includes(countryParam as Country)
  ) {
    localStorage.setItem(LocalStorageKey.Country, countryParam);
  }

  // handle email verification token
  const emailVerificationToken = urlParams.get("emailVerificationToken");
  if (emailVerificationToken) {
    emailVerificationContext?.setEmailVerificationToken(emailVerificationToken);
  }

  useEffect(() => {
    dispatch(getLanguageOptions());
    if (uid) {
      queryClient.invalidateQueries({ queryKey: importsKeys.all() });
      preFetchAllImportOptions(); // start loading the import options so the user doesn't have to wait
      dispatch(setFeatureFlagOverridesFromStorage());
    }
  }, [dispatch, uid, user]);

  useEffect(() => {
    if (user) {
      updateIntercomUser({
        transaction_count: transactionTotalCount,
        active_profile: activeClient?.uuid,
        // TODO: remove as any once we have a proper type for oAuthProvider
        oauth_provider: user.oAuthProvider as any,
      });
    }
  }, [user, transactionTotalCount, activeClient?.uuid]);

  useEffect(() => {
    if (query.error === "1") {
      dispatch(
        displayMessage({
          message: lang.auth.invalidCombination,
          type: DisplayMessage.Error,
        }),
      );
      navigate(Links.Login);
    } else if (query.signup === "1") {
      dispatch(loadUser(LoadUserType.Signup));
    } else {
      dispatch(loadUser(LoadUserType.Login));
    }
  }, [dispatch]);

  const prevPathName = usePreviousWithUseEffect(location.pathname);
  useEffect(() => {
    let isMounted = true; // local variable to track if component is still mounted

    const setAnalytics = async () => {
      const isSamePath = prevPathName === location.pathname;
      if (isMounted && !isSamePath) {
        const analytics = await Analytics.getInstance();
        analytics.page({
          name: AnalyticsPageName[location.pathname as Links],
          user,
        });

        /**
         * ensure posthog captures the current url for embedded TaxHub correctly
         */
        if (isEmbedded) {
          const currentUrl = window.location.href;
          posthog.capture("$pageview", {
            $current_url: currentUrl,
          });
        } else {
          posthog.capture("$pageview");
        }
      }
    };
    setAnalytics();

    return () => {
      isMounted = false; // set to false when component unmounts
    };
  }, [location.pathname, prevPathName, user, isEmbedded]); // watch for changes in location.pathname

  useEffect(() => {
    // update intercom user with event source and tax hub client
    if (uid) {
      updateIntercomUser({
        event_source: determineEventSource(),
        tax_hub_client: determineTaxHubClient(),
      });
    }
  }, [uid]);

  useEffect(() => {
    if (plan) {
      updateIntercomUser({ plan });
    }
  }, [plan]);

  useEffect(() => {
    if (country) {
      updateIntercomUser({ country });
    }
  }, [country]);

  useEffect(() => {
    // Set GA user id if user is logged in
    if (uid) identifyGoogleUser(uid);
  }, [uid]);

  useEffect(() => {
    const { bypassTXPaywall, inventoryMethod } = user?.activeDataSource ?? {};
    const { holdingsBalanceType } = taxSettings ?? {};

    updateIntercomUser({
      bypassTXPaywall: !!bypassTXPaywall,
      inventoryMethod,
      holdingsBalanceType,
    });
  }, [
    taxSettings?.holdingsBalanceType,
    user?.activeDataSource?.bypassTXPaywall,
    user?.activeDataSource?.inventoryMethod,
  ]);

  useEffect(() => {
    updateIntercomUser(reconciliationIntercomData);
  }, [reconciliationIntercomData]);

  const referrerMutation = useSetReferrerMutation();

  useEffect(() => {
    if (user?.uid && user?.isOnboarding) {
      // Only update referrer source if it doesn't exist for the user and the mutation is not pending
      if (!user.referrerSource && !referrerMutation.isPending) {
        // Ensure the local referrer source is up to date
        // Because it can get deleted if another user signs up, logs out and then
        // another user signs up in the same session
        referrerMutation.mutate();
        posthog.setPersonProperties({
          referrerSource,
        });
      }
    }
  }, [user?.uid, referrerMutation.isPending]);

  // This is used to send posthog event when a user closes the app
  useEffect(() => {
    const beforeUnloadHandler = () => {
      captureAnalytics("user closed app", {
        pathname: location.pathname,
      });
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [captureAnalytics, location]);

  // If the user didn't come from HP and they are in the experiment, set the cookie
  useEffect(() => {
    if (
      typeof usHobbyistPlusExperimentVariant === "string" &&
      !cookieUSHobbyistPlusExperimentVariant
    ) {
      Cookies.set(
        FeatureFlag.USHobbyistPlusExperiment,
        usHobbyistPlusExperimentVariant.toString(),
        {
          expires: 90,
          domain: import.meta.env.VITE_APP_DOMAIN,
          path: "/",
        },
      );
      captureAnalytics(`${FeatureFlag.USHobbyistPlusExperiment}:set_cookie`, {
        variant: usHobbyistPlusExperimentVariant,
      });
    }
  }, [usHobbyistPlusExperimentVariant, cookieUSHobbyistPlusExperimentVariant]);

  // @ts-ignore
  Imports.preload();
  if (loading) {
    return <LogoSpinner />;
  }

  // Check for new_session parameter before any UI rendering, but after loading
  // Only check in embedded mode
  const params = new URLSearchParams(window.location.search);
  const authState = params.get(BootstrapQueryParameter.AuthState);

  if (
    isEmbedded &&
    authState === AuthStateValue.NewSession &&
    user &&
    !loading
  ) {
    logout();
    return null; // Return nothing while logout processes
  }

  document.body.style.backgroundColor = tokens.elevation.default;
  document.documentElement.style.colorScheme =
    theme === ThemeEnum.Dark ? "dark" : "light";

  // Add error state check before the main app render
  const referrerSource = urlParams.get(BootstrapQueryParameter.ReferrerSource);
  if (isEmbedded && referrerSource && !isValidReferrerSource(referrerSource)) {
    return <InvalidReferrerError />;
  }

  if (!user || authError) {
    return (
      <LoginRedirectContextProvider
        // Login/Signup pages generate the redirect, we dont want to execute
        // the redirect here
        isRedirector={false}
      >
        <Routes>
          {([Links.Signup, Links.SignupAccountant] as const).map((path) => (
            <Route
              path={`${path}/*`}
              key={path}
              element={
                <ErrorBoundaryWrapper>
                  <WagmiWrapper>
                    <SignupWrapper path={path} />
                  </WagmiWrapper>
                </ErrorBoundaryWrapper>
              }
            />
          ))}
          <Route
            path={`${Links.Invite}/*`}
            element={
              <ErrorBoundaryWrapper>
                <InviteLinkPage />
              </ErrorBoundaryWrapper>
            }
          />
          <Route
            path={`${Links.Login}/*`}
            element={
              <ErrorBoundaryWrapper>
                <WagmiWrapper>
                  <LoginWrapper />
                </WagmiWrapper>
              </ErrorBoundaryWrapper>
            }
          />
          <Route
            path={`${Links.TokenLogin}/*`}
            element={
              <ErrorBoundaryWrapper>
                <TokenLogin />
              </ErrorBoundaryWrapper>
            }
          />
          <Route
            path={`${Links.Forgot}/*`}
            element={
              <ErrorBoundaryWrapper>
                <ForgotPasswordWrapper />
              </ErrorBoundaryWrapper>
            }
          />
          <Route
            path={`${Links.Unlock}/*`}
            element={
              <ErrorBoundaryWrapper>
                <UserUnlockRedirect />
              </ErrorBoundaryWrapper>
            }
          />
          <Route
            path={`${Links.Reset}/*`}
            element={
              <ErrorBoundaryWrapper>
                <UserDetailsPage
                  formText={{
                    title: query.invitation
                      ? lang.auth.setPasswordTitle
                      : lang.auth.resetPasswordTitle,
                    subTitle: lang.auth.resetPasswordSubTitle,
                  }}
                  formComponent={ResetPasswordForm}
                />
              </ErrorBoundaryWrapper>
            }
          />
          <Route
            path={`${Links.ConnectProvider}/*`}
            element={
              <ErrorBoundaryWrapper>
                <ConnectProvider />
              </ErrorBoundaryWrapper>
            }
          />
          <Route
            path={`${Links.Close}/*`}
            element={
              <ErrorBoundaryWrapper>
                <AutoClose />
              </ErrorBoundaryWrapper>
            }
          />
          <Route
            path="*"
            element={
              <Navigate
                to={getLoginRedirectPath(isEmbedded)}
                state={{ from: location }}
                replace
              />
            }
          />
        </Routes>
      </LoginRedirectContextProvider>
    );
  }

  // If the user has no country, only allow them to the onboarding add country route
  // when we have a new getting started page we can add it here
  if (!user.country) {
    return (
      <LoginRedirectContextProvider
        // We also dont want to redirect in onboarding, except for the case of the
        // embedded experience where redirects are otherwise disabled. It is
        // currently enabled in onboarding as we are leveraging the redirects to
        // alter the onboarding flow depending on a pre-auth feature flag
        isRedirector={isEmbedded}
      >
        <UserPopupManager />
        <Suspense fallback={<SuspenseCircularProgress />}>
          <Routes>
            <Route
              path={`${Links.Onboarding}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <UserOnboarding>
                    <OnboardingV2 />
                  </UserOnboarding>
                </ErrorBoundaryWrapper>
              }
            />

            {isDeveloperEnabled && (
              <Route
                path={`${Links.Developer}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <Developer />
                  </ErrorBoundaryWrapper>
                }
              />
            )}
            <Route
              path="*"
              element={<Navigate to={Links.Onboarding} replace />}
            />
          </Routes>
        </Suspense>
      </LoginRedirectContextProvider>
    );
  }

  if (archiveStatus !== ARCHIVE_STATUS_ACTIVE) {
    return (
      <>
        <UserPopupManager />
        <Suspense fallback={<SuspenseCircularProgress />}>
          <Routes>
            <Route
              path={`${Links.RestoreUserData}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <UserOnboarding>
                    <RestoreUserData />
                  </UserOnboarding>
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path="*"
              element={<Navigate to={Links.RestoreUserData} replace />}
            />
          </Routes>
        </Suspense>
      </>
    );
  }

  if (user.paidPlan === Plan.Enterprise) {
    return (
      <Layout>
        <>
          <UserPopupManager />

          <Suspense fallback={<SuspenseCircularProgress />}>
            <Routes>
              <Route
                path={`${Links.Dashboard}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <DashboardEnterprise />
                  </ErrorBoundaryWrapper>
                }
              />

              <Route
                path="*"
                element={<Navigate to={Links.Dashboard} replace />}
              />
            </Routes>
          </Suspense>
        </>
      </Layout>
    );
  }

  const selectedClient = getSelectedClientFromCookie();

  // if the user is an accountant and has no selected client, don't let them view anything other than clients
  // and the settings page
  if (isManagingClients && !selectedClient) {
    return (
      <Layout>
        <>
          <UserPopupManager />
          <Suspense fallback={<SuspenseCircularProgress />}>
            <Routes>
              <Route
                path={`${Links.Clients}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <Clients />
                  </ErrorBoundaryWrapper>
                }
              />
              <Route
                path={`${Links.Alpha}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <Alpha />
                  </ErrorBoundaryWrapper>
                }
              />
              <Route
                path={`${Links.OptInSupportAccess}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <OptInSupportAccess />
                  </ErrorBoundaryWrapper>
                }
              />
              <Route
                path={`${Links.Billing}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <Billing />
                  </ErrorBoundaryWrapper>
                }
              />
              <Route
                path={`${Links.Plans}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <AccountantCurrentPlan />
                  </ErrorBoundaryWrapper>
                }
              />

              {isBusinessClientEnabled ? (
                <Route
                  path={`${Links.PaymentStatus}/*`}
                  element={
                    <ErrorBoundaryWrapper>
                      <PaymentStatus
                        successComponent={<BusinessClientPaymentSuccess />}
                      />
                    </ErrorBoundaryWrapper>
                  }
                />
              ) : null}

              <Route
                path="*"
                element={
                  <ErrorBoundaryWrapper>
                    <Clients />
                  </ErrorBoundaryWrapper>
                }
              />
            </Routes>
          </Suspense>
        </>
      </Layout>
    );
  }

  // if the user is still onboarding don't let them view anything other than the onboarding flow
  if (user.isOnboarding) {
    return (
      <LoginRedirectContextProvider
        // We also dont want to redirect in onboarding, except for the case of the
        // embedded experience where redirects are otherwise disabled. It is
        // currently enabled in onboarding as we are leveraging the redirects to
        // alter the onboarding flow depending on a pre-auth feature flag
        isRedirector={isEmbedded}
      >
        <UserPopupManager />
        <ImportRelatedWalletsNotifier />
        <Suspense fallback={<SuspenseCircularProgress />}>
          <Routes>
            <Route
              path={`${Links.Onboarding}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <UserOnboarding>
                    <OnboardingV2 />
                  </UserOnboarding>
                </ErrorBoundaryWrapper>
              }
            />
            {isDeveloperEnabled ? (
              <Route
                path={`${Links.Developer}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <Developer />
                  </ErrorBoundaryWrapper>
                }
              />
            ) : null}
            <Route
              path={`${Links.Close}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <AutoClose />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path="*"
              element={<Navigate to={Links.Onboarding} replace />}
            />
          </Routes>
        </Suspense>
      </LoginRedirectContextProvider>
    );
  }

  // if the user still re-onboarding, don't let them view anything other than the re-onboarding flow
  if (user.isReOnboarding) {
    return (
      <>
        <UserPopupManager />
        <Suspense fallback={<SuspenseCircularProgress />}>
          <Routes>
            <Route
              path={`${Links.ReOnboarding}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <UserOnboarding>
                    <ReOnboarding />
                  </UserOnboarding>
                </ErrorBoundaryWrapper>
              }
            />
            {isDeveloperEnabled ? (
              <Route
                path={`${Links.Developer}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <Developer />
                  </ErrorBoundaryWrapper>
                }
              />
            ) : null}
            <Route
              path="*"
              element={<Navigate to={Links.ReOnboarding} replace />}
            />
          </Routes>
        </Suspense>
      </>
    );
  }

  return (
    <LoginRedirectContextProvider
      // Once the user has made it into the app, we want to execute the redirect.
      // Redirects are disabled for the embedded experience to avoid unexpected
      // nav behaviour from the iframe
      isRedirector={!isEmbedded}
    >
      <Layout>
        <UserPopupManager />
        <ImportRelatedWalletsNotifier />
        <Suspense fallback={<SuspenseCircularProgress />}>
          <Routes>
            <Route
              path={`${Links.Dashboard}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Dashboard />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Invite}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Dashboard />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Imports}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Imports />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Imports}/:import/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Imports />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.ImportSearch}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Imports importSearch />
                </ErrorBoundaryWrapper>
              }
            />

            <Route
              path={`${Links.ImportNew}/:import/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Imports importNew />
                </ErrorBoundaryWrapper>
              }
            />

            <Route
              path={`${Links.ImportCustom}/:import/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Imports importCustom />
                </ErrorBoundaryWrapper>
              }
            />

            <Route
              path={`${Links.Reconciliation}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Reconciliation />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.TransactionsSearch}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Transactions />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Transactions}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Transactions />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Report}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Report />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.BalanceLedger}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <BalanceLedger />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Plans}/*`}
              element={
                <ErrorBoundaryWrapper>
                  {!isAccountant ? (
                    <Navigate to={Links.Payment} replace />
                  ) : (
                    <AccountantCurrentPlan />
                  )}
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Payment}/*`}
              element={
                <ErrorBoundaryWrapper>
                  {!isAccountant ? (
                    <Payment />
                  ) : (
                    <Navigate to={Links.Plans} replace />
                  )}
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Contacts}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <ContactsList />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Rules}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <RulesList />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.AccountingIntegrations}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <AccountingIntegrations />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Billing}/*`}
              element={
                <ErrorBoundaryWrapper>
                  {!isPaidPlan || !isStripeEnabled ? (
                    <Navigate to={Links.Payment} replace />
                  ) : (
                    <Billing />
                  )}
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Clients}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Clients />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.PaymentStatus}/*`}
              element={
                <ErrorBoundaryWrapper>
                  {isBusinessClientEnabled && isAccountant ? (
                    <PaymentStatus
                      successComponent={<BusinessClientPaymentSuccess />}
                    />
                  ) : (
                    <PaymentStatus
                      successComponent={<PaymentStatusSuccess />}
                    />
                  )}
                </ErrorBoundaryWrapper>
              }
            />
            {isDeveloperEnabled && (
              <Route
                path={`${Links.Developer}/*`}
                element={
                  <ErrorBoundaryWrapper>
                    <Developer />
                  </ErrorBoundaryWrapper>
                }
              />
            )}
            <Route
              path={`${Links.ReferAFriend}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <ReferAFriend />
                </ErrorBoundaryWrapper>
              }
            />

            <Route
              path={`${Links.PartnerBenefit}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <PartnerBenefit />
                </ErrorBoundaryWrapper>
              }
            />

            <Route
              path={`${Links.Alpha}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <Alpha />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.OptInSupportAccess}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <OptInSupportAccess />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path={`${Links.Close}/*`}
              element={
                <ErrorBoundaryWrapper>
                  <AutoClose />
                </ErrorBoundaryWrapper>
              }
            />
            <Route
              path="*"
              element={
                <ErrorBoundaryWrapper>
                  <Navigate
                    to={{
                      pathname: getDefaultInAppRedirectPath(user, isEmbedded),
                    }}
                  />
                </ErrorBoundaryWrapper>
              }
            />
          </Routes>
        </Suspense>
      </Layout>
    </LoginRedirectContextProvider>
  );
};

export function Main() {
  return (
    <HelmetProvider>
      <Helmet
        defaultTitle="Crypto Tax Calculator"
        titleTemplate="%s | Crypto Tax Calculator"
      />
      {import.meta.env.VITE_APP_ENV === Env.Local ? <VsCodeClick /> : null}
      {isDeveloperEnabled ? <LocalPosthogCensor /> : null}
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <WebsocketProvider>
            <ThemeContextProvider>
              <GlobalStyles />
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              <StyledEngineProvider injectFirst>
                <Elements stripe={stripePromise}>
                  <Router>
                    <ScrollToTop />
                    <PageRefresher />
                    <DefaultPageTitle />
                    {DISPLAY_BANNER && <CouponBanner />}
                    <QueryParamProvider
                      adapter={ReactRouter6Adapter}
                      options={{
                        searchStringToObject: parse,
                        objectToSearchString: stringify,
                      }}
                    >
                      <SettingsModalProvider>
                        <ContextProvider>
                          <DndProvider backend={HTML5Backend}>
                            <CustomToaster />
                            <App />
                          </DndProvider>
                        </ContextProvider>
                      </SettingsModalProvider>
                    </QueryParamProvider>
                  </Router>
                </Elements>
              </StyledEngineProvider>
            </ThemeContextProvider>
          </WebsocketProvider>
        </QueryClientProvider>
      </Provider>
    </HelmetProvider>
  );
}
