import { PaymentStatus as CurrentPaymentStatus, Plan } from "@ctc/types";
import { ArrowForward } from "@mui/icons-material";
import { Box, CircularProgress, SvgIcon, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { StringParam, useQueryParam } from "use-query-params";

import { paymentAnalyticsKey } from "~/analytics/analyticsKeys";
import { captureGoogleAnalyticsEnhanced } from "~/analytics/google";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { captureTwitterAnalytics } from "~/analytics/twitter";
import { useImportV2Metadata } from "~/components/imports-v2/helpers";
import { getBilledCurrencyForCountry } from "~/components/payment/helpers";
import { getGAItem } from "~/components/payment/payment/helpers/getGAItem";
import { unhashPlanChangeEvent } from "~/components/payment-status/helpers";
import { SuccessIconRounded } from "~/components/ui/Icons";
import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { LocalStorageKey } from "~/constants/enums";
import { PaywallModalContext } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { useSetActiveClient } from "~/hooks/useActiveClient";
import { useDesign } from "~/hooks/useTheme";
import {
  getCountryPricesDEPRECATED,
  useUserDiscountDEPRECATED,
} from "~/hooks/useUserDiscountDEPRECATED";
import {
  loadUser,
  useActiveUser,
  useIsAccountant,
  useUser,
} from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import { trackIntercomEvent } from "~/services/intercom";
import { isPaidPlan } from "~/services/user";
import { Links } from "~/types/enums";

export const PaymentStatus = ({
  successComponent,
  pendingComponent = <PaymentStatusPending />,
}: {
  successComponent: React.ReactNode;
  pendingComponent?: React.ReactNode;
}) => {
  const [statusQuery] = useQueryParam("status", StringParam);
  const [uidQuery] = useQueryParam("uid", StringParam);
  const activeUser = useActiveUser();
  const navigate = useNavigate();
  const discount = useUserDiscountDEPRECATED();
  const isAccountant = useIsAccountant();
  const lang = useLang();
  const [hasFiredEvent, setHasFiredEvents] = useState(false);
  const importMetadata = useImportV2Metadata();
  const captureAnalytics = useCaptureAnalytics();
  const user = useUser();
  const setActiveClient = useSetActiveClient();
  const success = statusQuery === CurrentPaymentStatus.Success;
  const pending = statusQuery === CurrentPaymentStatus.Pending;
  const cancelled = statusQuery === CurrentPaymentStatus.Cancelled;

  useEffect(() => {
    if (uidQuery && activeUser?.uid !== uidQuery) {
      setActiveClient(uidQuery);
    }
  }, [uidQuery, activeUser, setActiveClient]);
  useEffect(() => {
    // Embedded will close the current page and fire the event from the caller.
    if (activeUser && !hasFiredEvent && user && !cancelled) {
      const { country } = activeUser;
      const billedCurrency = getBilledCurrencyForCountry(country);
      const prices = getCountryPricesDEPRECATED(country);
      // Set just-in-time in our purchase flows
      const planUpgradeEvent = localStorage.getItem(
        LocalStorageKey.PlanUpgradeEvent,
      );

      if (!planUpgradeEvent) {
        return;
      }

      const { newPlan: potentialNewPlan, oldPlan } =
        unhashPlanChangeEvent(planUpgradeEvent);

      if (potentialNewPlan && isPaidPlan(potentialNewPlan as Plan)) {
        // Prevent sending multiple events
        setHasFiredEvents(true);

        // Must be true because of the above check
        const newPlan = potentialNewPlan as Plan;

        localStorage.removeItem(LocalStorageKey.PlanUpgradeEvent);
        const total = discount.priceList[newPlan];
        const date = moment.utc().format("YYYY-MM-DDTHH:mm");
        // transaction_id helps avoid getting duplicate events for a purchase.
        const transactionId = `${activeUser.uid}__${newPlan}__${date}`;
        const item = getGAItem({
          isAccountant,
          newPlan,
          currency: billedCurrency,
          price: prices[newPlan],
          total,
          currentPlan: oldPlan,
        });

        // use gtag for enhanced ecommerce data types and actions
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
        captureGoogleAnalyticsEnhanced("event", "purchase", {
          transaction_id: transactionId, // required
          value: total, // The monetary value of the event.
          currency: billedCurrency,
          items: [item],
        });

        captureTwitterAnalytics("Purchase", {
          contents: [
            {
              content_id: item.item_id,
              content_name: item.item_name,
              content_price: item.price,
              content_type: item.item_category,
              num_items: item.quantity,
            },
          ],
          conversion_id: transactionId, // Used for deduplication
          currency: billedCurrency,
          status: "completed",
          value: prices[newPlan],
        });
        trackIntercomEvent("plan_purchase_success");
        if (oldPlan === Plan.Free) {
          trackIntercomEvent("first_time_payment_success");
        }
        navigate(
          user.isOnboarding
            ? Links.OnboardingPaymentSuccess
            : Links.PaymentSuccess,
        );
      }
    }
    // TODO: check these dependencies
  }, [
    statusQuery,
    navigate,
    discount,
    lang,
    activeUser,
    isAccountant,
    hasFiredEvent,
    user,
    cancelled,
  ]);

  useEffect(() => {
    if (
      statusQuery === CurrentPaymentStatus.Success &&
      !isEmpty(importMetadata)
    ) {
      captureAnalytics(
        paymentAnalyticsKey("success")("import metadata"),
        importMetadata,
      );
    }
  }, [statusQuery, importMetadata, captureAnalytics]);

  if (!user) return null;
  if (!statusQuery) navigate(Links.Plans);

  if (cancelled) {
    // direct users back to subscribe page if they didn't complete purchase
    navigate(Links.Payment);
  }

  return (
    <Page>
      {success ? successComponent : null}
      {pending ? pendingComponent : null}
    </Page>
  );
};

export const PaymentStatusSuccess = () => {
  const { tokens } = useDesign();
  const successLang = useLang().paymentStatus.success;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoToReportButtonClick = () => {
    dispatch(loadUser(LoadUserType.UserUpdate));
    navigate(Links.Report);
  };
  const paywallModalContext = useContext(PaywallModalContext);

  useEffect(() => {
    if (paywallModalContext) {
      paywallModalContext.setSelectedModal(null);
    }
  }, [paywallModalContext]);

  return (
    <Modal>
      <SvgIcon
        style={{
          fontSize: "3.5rem",
          borderRadius: "100%",
          boxShadow: `0 0 0 0.8rem ${tokens.background.accent.green.lowest}`,
          color: tokens.background.accent.green.medium,
        }}
      >
        <SuccessIconRounded />
      </SvgIcon>
      <Typography variant="Metropolis/Header/H4" style={{ marginTop: "2rem" }}>
        {successLang.title}
      </Typography>
      <Typography mt="1rem" mb="1.5rem">
        {successLang.subtitle}
      </Typography>
      <SecondaryButton
        onClick={handleGoToReportButtonClick}
        endIcon={<ArrowForward />}
      >
        {successLang.buttonText}
      </SecondaryButton>
    </Modal>
  );
};

const PaymentStatusPending = () => {
  const pendingLang = useLang().paymentStatus.pending;
  return (
    <Modal>
      <CircularProgress style={{ fontSize: "4rem" }} />
      <Typography variant="Metropolis/Header/H1" style={{ marginTop: "2rem" }}>
        {pendingLang.title}
      </Typography>
      <Typography style={{ marginTop: "1rem" }}>
        {pendingLang.subtitle}
      </Typography>
    </Modal>
  );
};

const Page = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Modal = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.tokens.background.neutral.default};
  width: 30rem;
  height: 20rem;
  padding: 2rem;
  margin: 2rem;
  margin-top: 3rem;
  text-align: center;
  border-radius: 3px;
`;
