import { type Align } from "~/types/enums";
import { type ActionRow, type CurrencyIdentifier } from "~/types/index";

import { type ActionTableGridArea } from "./enums";

export type ActionRowCellProps = {
  hover?: boolean;
  row: ActionRow;
  disablePointerEvents?: boolean;
  align?: Align;
  gridAreaOverride?: ActionTableGridArea;
};

export const MIN_FIAT_VALUE = 0.01;

export type ActionRowFeeType = {
  id: string; // Mongo ID
  value: number;
  quantity: number;
  currency: CurrencyIdentifier;
  timestamp: string;
  isEmbedded: boolean;
  exchange: string;
  displayName: string;
  groupById: string;
  blockchain: string | undefined;
};
