export enum Scope {
  Wallet = "wallet",
  Api = "api",
  Csv = "csv",
  HeartBeat = "heartbeat",
  ReportDownloadPdf = "reportDownloadPdf",
  ReportDownloadCsv = "reportDownloadCsv",
  ReportDownloadTxf = "reportDownloadTxf",
  ReportDownloadXml = "reportDownloadXml",
  Report = "report",
  Portfolio = "portfolio",
  PortfolioV2 = "portfolioV2",
  InflowOutflowPortfolio = "inflowOutflowPortfolio",
  Navigation = "navigation", // if the user is navigating with the page, e.g. pagination, filter, sort etc.
  Collaboration = "collaboration", // for syncing client/accountant
  PersonalPaidPlan = "personalPaidPlan",
  Erp = "erp",
  ErpSync = "erpSync",
  RelatedWalletsFound = "relatedWalletsFound",
  RelatedWalletImported = "relatedWalletImported", // imports uplift v2, this is for when we force import a related wallet
  Renewal = "renewal",
  EmailVerified = "emailVerified",
  Message = "message", // If the backend wants to send a message to the user.
  Reconciliation = "tips",
  Action = "action",
  Rules = "rules",
  /** Send progress updates for async processes (e.g. report refresh) */
  Progress = "progress",
}
