import isNil from "lodash/isNil";
import { type FieldNamesMarkedBoolean } from "react-hook-form";

import { type TxAmount } from "~/components/transactions/edit/EditActionTxAmount";
import { invariant } from "~/lib/invariant";
import { type PutTransactionPayload } from "~/services/actions";
import { Warning } from "~/types/enums";

/**
 * Takes the dirty fields from the form and uses that to decide


/**
 * Takes the dirty fields from the form and uses that to decide
 * what fields get sent to the backend as part of the update
 */
export function createTxAmountPatch({
  dirty,
  price,
  txId,
  quantity,
  priceValueCurrency,
  currency,
  markIgnoreMissingPrice,
  groupById,
  updateMap,
}: Omit<TxAmount, "timestamp"> & {
  dirty: FieldNamesMarkedBoolean<TxAmount>;
  updateMap: Map<string, PutTransactionPayload>;
}) {
  const update = (id: string, update: PutTransactionPayload) => {
    if (!updateMap.has(id)) {
      updateMap.set(id, { _id: id });
    }
    const value = updateMap.get(id);
    updateMap.set(id, { ...value, ...update });
  };

  if (dirty?.price && !isNil(price)) {
    update(txId, { price: Number(price) });
  }

  if (dirty?.quantity && !isNil(quantity)) {
    update(txId, { quantity: Number(quantity) });
  }

  if (dirty?.markIgnoreMissingPrice && markIgnoreMissingPrice) {
    update(txId, {
      ignoreWarning: { [Warning.MissingPrice]: true },
    });
  }

  if (dirty?.priceValueCurrency) {
    update(txId, { priceValueCurrency });
  }

  if (dirty?.currency && currency) {
    invariant(typeof currency !== "string", "Currency cannot be a string");

    update(txId, {
      currencyIdentifier: currency,
    });
  }

  if (dirty?.groupById) {
    update(txId, { groupById });
  }
}
