import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { LocalStorageKey } from "~/constants/enums";

export function EditorIntegration() {
  const [editor, setEditor] = useState("cursor");

  useEffect(() => {
    const storedEditor = localStorage.getItem(LocalStorageKey.Editor);
    setEditor(storedEditor || "cursor");
  }, []);

  useEffect(() => {
    localStorage.setItem(LocalStorageKey.Editor, editor);
  }, [editor]);

  return (
    <Box
      display="flex"
      gap="0.5rem"
      flexDirection="column"
      component="form"
      px={{ xs: 2, sm: 0 }}
      mt={4}
    >
      <Typography variant="Metropolis/Header/H4">Jump to Code</Typography>
      <Typography variant="Metropolis/Body/Bold">
        Opt + Click any element to open in editor
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">Editor</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={editor}
            onChange={(e) => {
              setEditor(e.target.value);
            }}
          >
            <FormControlLabel
              value="vscode"
              control={<Radio />}
              label="VS Code"
            />
            <FormControlLabel
              value="cursor"
              control={<Radio />}
              label="Cursor"
            />
            <FormControlLabel
              value="vscode-insiders"
              control={<Radio />}
              label="VS Code Insiders"
            />
            <FormControlLabel
              value="webstorm"
              control={<Radio />}
              label="WebStorm"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
