import { Blockchain } from "@ctc/types";
import WAValidator from "@swyftx/api-crypto-address-validator/dist/wallet-address-validator.min";
import { BigNumber } from "bignumber.js";
import { decode } from "bs58";
import TonWeb from "tonweb";

import {
  isValidBtcAddress,
  isValidDogeAddress,
  isValidLtcAddress,
} from "~/services/wallet/isValidBtcWalletAddress";
import {
  isEVMBlockchain,
  isPolkadotCompatibleChain,
  PolkadotChainsConfig,
  type PolkadotCompatibleChain,
} from "~/types/index";
import { WAVNetwork } from "~/types/wallet-address-validator";

export function isValidCardanoWalletAddress(address: string): boolean {
  // Ideally users only upload keys starting with stake1, but old byron era wallet addresses start with Ddz or Ae2
  return (
    address.startsWith("stake1") ||
    address.startsWith("addr1") ||
    address.startsWith("Ddz") ||
    address.startsWith("Ae2")
  );
}

export function isValidPolkadotChainAddress(
  address: string,
  chain: PolkadotCompatibleChain,
): boolean {
  const prefix = PolkadotChainsConfig[chain].prefix;
  try {
    const decoded = decode(address);
    if (!prefix) {
      return true;
    } else if (prefix < 64 && decoded[0] === prefix) {
      return true;
    } else if (prefix > 64 && decoded.length > 2) {
      // For addresses greater that 64, the start of the buffer must be 01, followed by 14 bytes of the prefix in little endian form.
      // The 2 missing higher order bits are assumed to be zero and the low-order byte is split across two bytes.
      // The bytes 01LLLLLL MMHHHHHH are interpreted as 00HHHHHH LLLLLLMM
      const highByte = decoded[1] & 0b00111111;
      const lowByte = ((decoded[0] & 0b00111111) << 2) + (decoded[1] >> 6);
      const potentialPrefix = (highByte << 8) + lowByte;
      return potentialPrefix === prefix;
    }
    return false;
  } catch (error) {
    return false;
  }
}

function removeHexPrefix(hex: string): string {
  return hex.replace(/^0x/i, "");
}

function addHexPrefix(hex: string): string {
  return `0x${removeHexPrefix(hex)}`;
}

function toHex(value: string): string {
  return addHexPrefix(BigInt(value).toString(16));
}

export function addAddressPadding(address: string): string {
  const hex = toHex(addHexPrefix(address));
  const padded = removeHexPrefix(hex).padStart(64, "0");
  return addHexPrefix(padded);
}

/**
 * @see https://github.com/starknet-io/starknet.js/blob/b15fe2d6aa9a5494c510fcac3d6d34fd7e41bf5c/src/utils/address.ts#L41
 */
export function isValidStarknetAddress(address: string): boolean {
  try {
    const result = addAddressPadding(address);

    if (!/^(0x)?[0-9a-fA-F]{64}$/.exec(result)) {
      return false;
    }

    const lowerBound = new BigNumber(0);
    const MAX_STORAGE_ITEM_SIZE = new BigNumber(256);
    const upperBound = new BigNumber(2)
      .exponentiatedBy(251)
      .minus(MAX_STORAGE_ITEM_SIZE);
    const validAddressLength = 66;

    // Convert `result` to a BigNumber
    const addressInBigInt = new BigNumber(result);

    if (
      !(
        addressInBigInt.isGreaterThanOrEqualTo(lowerBound) &&
        addressInBigInt.isLessThanOrEqualTo(upperBound) &&
        address.length === validAddressLength
      )
    ) {
      return false;
    }

    return true;
  } catch (e) {
    return false;
  }
}

const isValidSolanaAddress = (address: string) => {
  return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address);
};

const isValidHederaAddress = (address: string) => {
  // Hedera has two address types, one is EVM compatible and the account id eg. "0.0.8252957".
  // We can use the EVM validator to check if the address is valid.
  return (
    (WAValidator.validate(address, WAVNetwork.ETH) as boolean) ||
    /^0\.0\.[1-9]\d*$/.test(address)
  );
};

const isValidAptosAddress = (address: string) => {
  if (typeof address !== "string") return false;

  if (address.startsWith("0x")) {
    address = address.slice(2);
  }

  if (address.length > 64 || !/^[0-9a-fA-F]+$/.test(address)) {
    return false;
  }

  const numValue = BigInt(`0x${address}`);
  if (numValue <= BigInt(10)) {
    return true;
  }

  return address.length === 64 || /^[0]+[1-9a-fA-F]/.test(address);
};

const isValidWalletAddressMap: Record<string, (address: string) => boolean> = {
  // Chains that use WAValidator
  [Blockchain.Hyperliquid]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.ETH]: (address) => WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.ARB]: (address) => WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.OPT]: (address) => WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.BSC]: (address) => WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Cronos]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.PulseChain]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Linea]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Mantle]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.PolygonZkEvm]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.MantaPacific]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.EthereumClassic]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETC),
  [Blockchain.Immutable]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.ArbitrumNova]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Scroll]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Berachain]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Unichain]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Neo]: (address) => WAValidator.validate(address, WAVNetwork.NEO),
  [Blockchain.Polygon]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Moonbeam]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Moonriver]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Aurora]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Boba]: (address) => WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Celo]: (address) => WAValidator.validate(address, WAVNetwork.CLO),
  [Blockchain.BitTorrentChain]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.CLVChain]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Metis]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Canto]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.ZkSync]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Kava]: (address) => WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Fantom]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Vechain]: (address) =>
    WAValidator.validate(address, WAVNetwork.VET),
  [Blockchain.Zora]: (address) => WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Flare]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Velas]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.ZetaChain]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Blast]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Taiko]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Xdai]: (address) => WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Mode]: (address) => WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Sonic]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Abstract]: (address) =>
    WAValidator.validate(address, WAVNetwork.ETH),
  [Blockchain.Stellar]: (address) =>
    WAValidator.validate(address, WAVNetwork.XLM),
  [Blockchain.Algorand]: (address) =>
    WAValidator.validate(address, WAVNetwork.ALGO),
  [Blockchain.Ripple]: (address) =>
    WAValidator.validate(address, WAVNetwork.XRP),

  // Bitcoin and its forks (using specialized validators)
  [Blockchain.BTC]: isValidBtcAddress,
  [Blockchain.LTC]: isValidLtcAddress,
  [Blockchain.DOGE]: (address) => {
    if (address.startsWith("Ddz")) return false;
    return isValidDogeAddress(address);
  },
  [Blockchain.BSV]: isValidBtcAddress,
  [Blockchain.DASH]: (address) =>
    WAValidator.validate(address, WAVNetwork.DASH),
  [Blockchain.GRS]: (address) => address.startsWith("F"),
  [Blockchain.ZEC]: (address) => WAValidator.validate(address, WAVNetwork.ZEC),

  // Cosmos ecosystem (using specialized validators)
  [Blockchain.Juno]: (address) => address.startsWith("juno1"),
  [Blockchain.CosmosHub]: (address) => address.startsWith("cosmos1"),
  [Blockchain.FetchAi]: (address) => address.startsWith("fetch1"),
  [Blockchain.BitSong]: (address) => address.startsWith("bitsong1"),
  [Blockchain.Sentinel]: (address) => address.startsWith("sent1"),
  [Blockchain.Chihuahua]: (address) => address.startsWith("chihuahua1"),
  [Blockchain.Agoric]: (address) => address.startsWith("agoric1"),
  [Blockchain.Evmos]: (address) => address.startsWith("evmos1"),
  [Blockchain.Kujira]: (address) => address.startsWith("kujira1"),
  [Blockchain.Stargaze]: (address) => address.startsWith("stars1"),
  [Blockchain.Osmosis]: (address) => address.startsWith("osmo1"),
  [Blockchain.Iotex]: (address) => address.startsWith("io1"),
  [Blockchain.Celestia]: (address) => address.startsWith("celestia1"),
  [Blockchain.Injective]: (address) => address.startsWith("inj1"),
  [Blockchain.Dymension]: (address) => address.startsWith("dym1"),
  [Blockchain.Dydx4]: (address) => address.startsWith("dydx"),
  [Blockchain.Akash]: (address) => address.startsWith("akash1"),
  [Blockchain.Archway]: (address) => address.startsWith("archway1"),
  [Blockchain.Kyve]: (address) => address.startsWith("kyve1"),
  [Blockchain.Nolus]: (address) => address.startsWith("nolus1"),
  [Blockchain.Neutron]: (address) => address.startsWith("neutron1"),
  [Blockchain.Regen]: (address) => address.startsWith("regen1"),
  [Blockchain.Saga]: (address) => address.startsWith("saga1"),
  [Blockchain.Thorchain]: (address) => address.startsWith("thor1"),
  [Blockchain.Sei]: (address) => address.startsWith("sei1"),

  // Other chains with unique prefixes
  [Blockchain.Tron]: (address) => WAValidator.validate(address, WAVNetwork.TRX),
  [Blockchain.TerraClassic]: (address) => address.startsWith("terra1"),
  [Blockchain.Terra2]: (address) => address.startsWith("terra1"),
  [Blockchain.BinanceChain]: (address) => address.startsWith("bnb"),
  [Blockchain.Avalanche]: (address) => address.startsWith("avax1"),
  [Blockchain.TON]: (address) => {
    const tonweb = new TonWeb();
    return (
      address.endsWith(".ton") ||
      address.endsWith(".t.me") ||
      tonweb.utils.Address.isValid(address)
    );
  },
  [Blockchain.Kaspa]: (address) => address.startsWith("kaspa:"),
  [Blockchain.Near]: (address) => address.endsWith(".near"),
  [Blockchain.Stacks]: (address) => address.startsWith("ST"),
  [Blockchain.Sui]: (address) => {
    // SUI addresses are 0x followed by 64 hex characters
    return /^0x[0-9a-fA-F]{64}$/.test(address);
  },

  // Chains that need special validation
  [Blockchain.Cardano]: isValidCardanoWalletAddress,
  [Blockchain.Solana]: isValidSolanaAddress,
  [Blockchain.SolanaV2]: isValidSolanaAddress,
  [Blockchain.Hedera]: isValidHederaAddress,
  [Blockchain.Starknet]: isValidStarknetAddress,
  [Blockchain.Aptos]: isValidAptosAddress,

  // Polkadot ecosystem (using PolkadotChainAddress validation)
  [Blockchain.Polkadot]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Polkadot),
  [Blockchain.Acala]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Acala),
  [Blockchain.Ajuna]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Ajuna),
  [Blockchain.PolkadotAstar]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.PolkadotAstar),
  [Blockchain.Aventus]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Aventus),
  [Blockchain.BifrostDot]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.BifrostDot),
  [Blockchain.Bitgreen]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Bitgreen),
  [Blockchain.Centrifuge]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Centrifuge),
  [Blockchain.PolkadotClover]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.PolkadotClover),
  [Blockchain.Collectives]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Collectives),
  [Blockchain.Composable]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Composable),
  [Blockchain.Crust]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Crust),
  [Blockchain.Darwinia]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Darwinia),
  [Blockchain.Equilibrium]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Equilibrium),
  [Blockchain.Frequency]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Frequency),
  [Blockchain.Hashed]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Hashed),
  [Blockchain.Hydradx]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Hydradx),
  [Blockchain.IntegriteeShell]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.IntegriteeShell),
  [Blockchain.Interlay]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Interlay),
  [Blockchain.Kapex]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Kapex),
  [Blockchain.Kilt]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Kilt),
  [Blockchain.Kylin]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Kylin),
  [Blockchain.Litentry]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Litentry),
  [Blockchain.Manta]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Manta),
  [Blockchain.Nodle]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Nodle),
  [Blockchain.Origintrail]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Origintrail),
  [Blockchain.Parallel]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Parallel),
  [Blockchain.Pendulum]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Pendulum),
  [Blockchain.Phala]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Phala),
  [Blockchain.Statemint]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Statemint),
  [Blockchain.T3rn]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.T3rn),
  [Blockchain.Unique]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Unique),

  // Kusama ecosystem (using PolkadotChainAddress validation)
  [Blockchain.Kusama]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Kusama),
  [Blockchain.Altair]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Altair),
  [Blockchain.Amplitude]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Amplitude),
  [Blockchain.Bajun]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Bajun),
  [Blockchain.Basilisk]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Basilisk),
  [Blockchain.BifrostKsm]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.BifrostKsm),
  [Blockchain.Bitcountrypioneer]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Bitcountrypioneer),
  [Blockchain.Bridgehub]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Bridgehub),
  [Blockchain.Calamari]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Calamari),
  [Blockchain.Crab]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Crab),
  [Blockchain.Daoipci]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Daoipci),
  [Blockchain.Encointer]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Encointer),
  [Blockchain.Genshiro]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Genshiro),
  [Blockchain.Gm]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Gm),
  [Blockchain.Imbue]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Imbue),
  [Blockchain.Integritee]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Integritee),
  [Blockchain.Kabocha]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Kabocha),
  [Blockchain.Karura]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Karura),
  [Blockchain.Khala]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Khala),
  [Blockchain.Kintsugi]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Kintsugi),
  [Blockchain.Krest]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Krest),
  [Blockchain.Litmus]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Litmus),
  [Blockchain.Mangatax]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Mangatax),
  [Blockchain.ParallelHeiko]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.ParallelHeiko),
  [Blockchain.Picasso]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Picasso),
  [Blockchain.Quartz]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Quartz),
  [Blockchain.Robonomics]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Robonomics),
  [Blockchain.Shadow]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Shadow),
  [Blockchain.KusamaShiden]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.KusamaShiden),
  [Blockchain.Sora]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Sora),
  [Blockchain.Statemine]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Statemine),
  [Blockchain.Subsocialx]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Subsocialx),
  [Blockchain.Subzero]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Subzero),
  [Blockchain.Tinkernet]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Tinkernet),
  [Blockchain.Turing]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Turing),
  [Blockchain.Zeitgeist]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Zeitgeist),

  // Shibuya ecosystem
  [Blockchain.Shibuya]: (address) =>
    isValidPolkadotChainAddress(address, Blockchain.Shibuya),
};

export function isValidWalletAddress(
  address: string,
  blockchain: Blockchain,
  isBtcAltNetwork: boolean,
): boolean {
  try {
    if (isBtcAltNetwork) {
      return true;
    }

    if (isEVMBlockchain(blockchain)) {
      return WAValidator.validate(address, WAVNetwork.ETH) as boolean;
    }

    if (isPolkadotCompatibleChain(blockchain)) {
      return isValidPolkadotChainAddress(address, blockchain);
    }

    // Match specific handlers.
    const handler = isValidWalletAddressMap[blockchain];
    if (handler) {
      const result = handler(address);
      return result;
    }

    // Remove versioning from blockchains for check
    const blockchainParsed = blockchain.toLowerCase().replace(/-V\d+$/, "");
    if (Object.values(WAVNetwork).includes(blockchainParsed as WAVNetwork)) {
      return WAValidator.validate(address, blockchainParsed);
    }

    return true;
  } catch (err) {
    return false;
  }
}
