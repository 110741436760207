import { SaveAlt } from "@mui/icons-material";
import { Box, Dialog, Typography } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";

import {
  getActionRowCounterParty,
  getAssociatedTxFilterQuery,
} from "~/components/transactions/action-row/helpers";
import { FilterProvider } from "~/components/transactions/filter-bar/FilterContext";
import { getAddressLink } from "~/components/transactions/helpers";
import { WalletImage } from "~/components/transactions/ManageEntityDialog";
import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { ErrorFallback } from "~/components/ui/error-boundary/ErrorBoundaryWrapper";
import { ExternalAddressLink } from "~/components/ui/ExternalAddressLink";
import { TransactionTableModalInner } from "~/components/ui/TransactionTableModal";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import { middleTrim } from "~/lib/index";
import { useIsAddressLike } from "~/redux/imports";
import { useLang } from "~/redux/lang";
import { type PartyDetails } from "~/services/transactions";
import { useGetActionsQuery } from "~/state/actions";
import { Links } from "~/types/enums";
import { type ActionRow, type FilterQuery } from "~/types/index";

function getCounterPartyTypeLang(
  lang: Translation,
  counterParty: PartyDetails,
  isCounterPartyAnAddress: boolean,
) {
  if (counterParty?.isSmartContract) {
    return lang.txTable.viewAssociatedTransactionsModal.smartContract;
  }

  if (isCounterPartyAnAddress) {
    return lang.txTable.viewAssociatedTransactionsModal.wallet;
  }

  return lang.txTable.viewAssociatedTransactionsModal.exchange;
}

function ViewAssociatedTransactionsModalContents({
  isOpen,
  setIsOpen,
  row,
  filter,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  row: ActionRow;
  filter: FilterQuery;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  const actionsQuery = useGetActionsQuery();
  const associatedTransactionsCount = actionsQuery.data?.total;

  const counterParty = getActionRowCounterParty(row);

  const counterPartyIsAnAddress = useIsAddressLike(
    counterParty?.exchange.trim() ?? "",
  );

  const addressLink = counterParty
    ? getAddressLink(counterParty.exchange, undefined, counterParty.blockchain)
    : undefined;

  if (!counterParty) return null;

  const counterPartyTypeLang = getCounterPartyTypeLang(
    lang,
    counterParty,
    !!counterPartyIsAnAddress,
  );

  return (
    <TransactionTableModalInner
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      filter={filter}
      title={
        <Box display="flex" alignItems="center" gap="0.5rem" flexWrap="wrap">
          <WalletImage
            margin="0"
            address={counterParty.exchange}
            blockchain={counterParty.blockchain}
            width={24}
            height={24}
          />
          <Typography variant="Metropolis/Header/H4">
            {middleTrim(counterParty.displayName)}
          </Typography>
          {addressLink ? (
            <ExternalAddressLink
              link={addressLink}
              color={tokens.text.default}
              width={14}
              noPadding
            />
          ) : null}
          <CopyIconButton
            contentToCopy={counterParty.exchange}
            color={tokens.text.default}
            width={14}
            noPadding
          />
          {/* Don't show the option to import if the counter party is a smart contract */}
          {!counterParty.isSmartContract ? (
            <Box gap="0.25rem" display="flex">
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                color={tokens.text.low}
              >
                {lang.txTable.viewAssociatedTransactionsModal.import({
                  importType: counterPartyTypeLang.toLowerCase(),
                })}
              </Typography>

              <a href={Links.Imports} target="_blank" rel="noopener noreferrer">
                <Box
                  display="flex"
                  alignItems="center"
                  color={tokens.text.brand}
                  gap="0.125rem"
                >
                  <Typography
                    variant="Metropolis/Caption/Medium/Regular"
                    color={tokens.text.brand}
                  >
                    {lang.contacts.unidentifiedTable.modal.importCTA}
                  </Typography>
                  <SaveAlt sx={{ fontSize: "1rem" }} />
                </Box>
              </a>
            </Box>
          ) : null}
        </Box>
      }
      chips={[
        {
          key: "type",
          title: lang.txTable.viewAssociatedTransactionsModal.type,
          value: (
            <Typography variant="Metropolis/Caption/Medium/Regular">
              {counterPartyTypeLang}
            </Typography>
          ),
        },
        {
          key: "txs",
          title: lang.txTable.viewAssociatedTransactionsModal.transactions,
          value: (
            <Typography variant="Metropolis/Caption/Medium/Regular">
              {associatedTransactionsCount}
            </Typography>
          ),
        },
      ]}
    />
  );
}

export function ViewAssociatedTransactionsModal({
  isOpen,
  setIsOpen,
  row,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  row: ActionRow;
}) {
  const filter = getAssociatedTxFilterQuery(row);
  return (
    <Dialog
      open={isOpen}
      maxWidth="xl"
      onClose={() => {
        setIsOpen(false);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <FilterProvider initialState={{ filter }}>
          <ViewAssociatedTransactionsModalContents
            row={row}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            filter={filter}
          />
        </FilterProvider>
      </ErrorBoundary>
    </Dialog>
  );
}
