import { type Plan } from "@ctc/types";
import { Close, OpenInNew } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Dialog,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AnalyticEvent } from "~/analytics/analyticsProperties";
import { useCaptureAnalyticEvents } from "~/analytics/captureAnalyticEvents";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { Calco } from "~/components/ui/calco/Calco";
import { DownloadIcon } from "~/components/ui/Icons";
import { devices } from "~/components/ui/theme/legacy";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import {
  inventoryMethodForCryptocurrencyLink,
  taxLossHarvestingBlogLink,
} from "~/constants/constants";
import { PaywallModalType } from "~/contexts/paywall-modal-context/enums";
import {
  PaywallModalContext,
  type SampleReportDownload,
} from "~/contexts/paywall-modal-context/PaywallModalContext";
import { usePaywallContent } from "~/contexts/paywall-modal-context/usePaywallContent";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useDesign } from "~/hooks/useTheme";
import { useCountry, useIsManagingClients } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { usePlans } from "~/state/plans";
import { type Features } from "~/types/enums";
import { Links, NormalReportType } from "~/types/enums";
import { type PlansRes, shouldReplaceCGTWithPNL } from "~/types/index";

export const findNextPlanWithFeature = (
  feature: Features,
  plans?: PlansRes,
) => {
  if (!plans) return;

  return Object.entries(plans).find(
    // need to ignore this one manually because when you try to do it via the eslint config it errors
    // https://github.com/typescript-eslint/typescript-eslint/issues/4691

    ([_plan, planConfig]) => planConfig.features[feature],
  );
};

const ListItem = ({ text }: { text: string }) => {
  const { tokens } = useDesign();
  return (
    <Box display="flex" gap="0.5rem">
      <CheckIcon color="success" />
      <Typography
        fontWeight="600"
        fontSize="0.875rem"
        color={tokens.text.default}
      >
        {text}
      </Typography>
    </Box>
  );
};

export function PaywallModal({
  selectedModal,
}: {
  selectedModal?: PaywallModalType;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const isTablet = useMediaQuery(devices.tablet);
  const captureAnalytics = useCaptureAnalytics();
  const paywallModalContext = useContext(PaywallModalContext);
  const paywallContent = usePaywallContent();
  const isManagingClients = useIsManagingClients();
  const navigate = useNavigate();
  const country = useCountry();
  const plans = usePlans();
  const isMobile = useIsMobile();
  const captureAnalyticsEvent = useCaptureAnalyticEvents();

  if (!paywallModalContext || !selectedModal) return null;

  const { title, subtitle, prompt, benefits, calco, banner, feature } =
    paywallContent[selectedModal];

  const { sampleName, downloadSampleFunc } =
    paywallModalContext.sampleReportDownload || {};

  const planEntry = feature
    ? findNextPlanWithFeature(feature, plans?.data)
    : undefined;
  const requiredPlan = planEntry ? (planEntry[0] as Plan) : undefined;

  const modalSampleReportText = ((info: SampleReportDownload | null) => {
    if (!info || info.sampleName.length === 0)
      return lang.paywallModal.downloadSample;

    if (info.sampleName.length > 1) {
      return lang.paywallModal.modalText.pack;
    }

    // @todo: this code is ripped from lang.ts, we should find a better way to do this
    // to langify the modal
    if (
      info.sampleName[0] === NormalReportType.CapitalGains &&
      shouldReplaceCGTWithPNL(country)
    ) {
      // There must be a better way than this
      return lang.paywallModal.modalText["capital-gains-nz"];
    }

    if (sampleName) {
      return lang.paywallModal.modalText[info.sampleName[0]];
    }
    return lang.paywallModal.downloadSample;
  })(paywallModalContext.sampleReportDownload);

  const closeButton = (
    <TextIconButton
      aria-label="close"
      onClick={() => {
        paywallModalContext.setSelectedModal(null);
        captureAnalyticsEvent(AnalyticEvent.PAYWALL_MODAL.CLOSED, {
          showTaxSeasonCountdown: false,
          variant: "control",
        });
      }}
      size="small"
      sx={{ ml: "0.5rem" }}
    >
      <Close />
    </TextIconButton>
  );

  return (
    <Dialog
      open
      maxWidth={isTablet ? undefined : "md"}
      sx={{
        backdropFilter: "blur(2px)",
        ".MuiDialog-paper": {
          backgroundColor: tokens.background.neutral.lowest.default,
          backgroundImage: "none",
          maxWidth: "none",
        },
      }}
    >
      <Box
        padding="1rem 1.5rem 1rem 1.5rem"
        display="flex"
        flexDirection="column"
        maxWidth="42rem"
        gap="1rem"
      >
        {banner ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {banner}
            {closeButton}
          </Box>
        ) : null}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography
              fontWeight="600"
              fontSize="1.5rem"
              color={tokens.text.brand}
              sx={{
                whiteSpace: isTablet ? "nowrap" : undefined,
              }}
            >
              {title}
            </Typography>
            <Box maxWidth={calco ? "80%" : undefined}>
              {typeof subtitle === "string" ? (
                <Typography
                  display="inline"
                  fontWeight="600"
                  fontSize="1.25rem"
                  maxWidth={benefits?.length === 0 ? "70%" : "90%"}
                >
                  {subtitle}
                </Typography>
              ) : (
                subtitle
              )}

              <Typography
                fontWeight="600"
                fontSize="1rem"
                color={tokens.text.default}
                paddingTop="0.5rem"
                maxWidth={benefits?.length === 0 ? "70%" : "90%"}
                paddingBottom="0.5rem"
              >
                {prompt}
              </Typography>
              <Box
                display="grid"
                sx={{
                  "--grid-layout-gap": "1rem",
                  "--grid-column-count": "2",
                  "--grid-item--min-width": "15rem",
                  /**
                   * Calculated values.
                   */
                  "--gap-count": `calc(var(--grid-column-count) - 1)`,
                  "--total-gap-width": `calc(var(--gap-count) * var(--grid-layout-gap))`,
                  "--grid-item--max-width": `calc((100% - var(--total-gap-width)) / var(--grid-column-count))`,
                  gridTemplateColumns: `repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr))`,
                  gridGap: "var(--grid-layout-gap)",
                }}
              >
                {benefits &&
                  benefits.map((benefit) => (
                    <ListItem key={benefit} text={benefit} />
                  ))}
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent={calco ? undefined : "flex-start"}
              alignContent="flex-end"
              paddingBottom="1rem"
              paddingTop="1rem"
            >
              {downloadSampleFunc && (
                <TertiaryButton
                  sx={{ marginRight: "0.5rem" }}
                  onClick={() => {
                    downloadSampleFunc();
                    paywallModalContext.setSelectedModal(null);
                    paywallModalContext.setSampleReportDownload(null);
                  }}
                >
                  <Box gap="0.25rem" display="flex" alignItems="center">
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                      {modalSampleReportText}
                    </Typography>
                    <DownloadIcon color={tokens.icon.default} />
                  </Box>
                </TertiaryButton>
              )}
              {isManagingClients ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    captureAnalytics(
                      "paywall_modal_upgrade_button_clicked_accountant",
                    );
                    paywallModalContext?.setUpgradeClientDialogOpen(
                      requiredPlan ?? true,
                    );
                    paywallModalContext.setSelectedModal(null);
                  }}
                >
                  {lang.paywallModal.upgradeNow}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    captureAnalytics(
                      "paywall_modal_upgrade_button_clicked_user",
                    );
                    captureAnalyticsEvent(
                      AnalyticEvent.PAYWALL_MODAL.VIEW_PLANS_BUTTON_CLICKED,
                      {
                        showTaxSeasonCountdown: false,
                        variant: "control",
                      },
                    );
                    paywallModalContext.setSelectedModal(null);
                    navigate(Links.Plans);
                  }}
                >
                  {lang.paywallModal.upgradeNow}
                </Button>
              )}
            </Box>
            {selectedModal === PaywallModalType.AdvancedInventory ||
            selectedModal === PaywallModalType.TaxLossHarvesting ? (
              <Box display="flex" gap="0.5rem">
                {selectedModal === PaywallModalType.TaxLossHarvesting &&
                !isMobile ? (
                  <Link
                    href={taxLossHarvestingBlogLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ textDecoration: "none" }}
                  >
                    <TertiaryButton
                      sx={{
                        display: "flex",
                        gap: "0.25rem",
                        padding: ".375rem .75rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          color: tokens.text.low,
                        }}
                      >
                        {
                          lang.taxLossHarvesting.paywallModal
                            .aboutTaxLossHarvesting
                        }
                      </Typography>
                      <OpenInNew />
                    </TertiaryButton>
                  </Link>
                ) : null}
                {!isMobile ? (
                  <Link
                    href={inventoryMethodForCryptocurrencyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ textDecoration: "none" }}
                  >
                    <TertiaryButton
                      sx={{
                        display: "flex",
                        gap: "0.25rem",
                        padding: ".375rem .75rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          color: tokens.text.low,
                        }}
                      >
                        {lang.taxLossHarvesting.paywallModal.aboutLTFO}
                      </Typography>
                      <OpenInNew />
                    </TertiaryButton>
                  </Link>
                ) : null}
              </Box>
            ) : null}
          </Box>

          {banner ? null : closeButton}

          {calco ? (
            <Box position="absolute" bottom="0" right="1.5rem">
              {/* There's a bug here where rem's are coming through as px's, switched to px's to resolve temporarily */}
              <Calco type="moon" height={isTablet ? "160px" : "80px"} />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Dialog>
  );
}
