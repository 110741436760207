import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  InputAdornment,
  Popover,
  Skeleton,
  type SxProps,
  TextField,
  type Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import partition from "lodash/partition";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import posthog from "posthog-js";
import * as React from "react";
import { createContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { css } from "styled-components";
import styled from "styled-components/macro";

import {
  importTableKey,
  useCaptureImportAnalytics,
} from "~/components/imports/AnalyticsHelpers";
import { getSyncTitle } from "~/components/imports/helpers";
import { ImportBody } from "~/components/imports/ImportBody";
import { useImportViewMode } from "~/components/imports/ImportViewMode";
import {
  BlockchainStack,
  BlockieStack,
} from "~/components/imports-v2/BlockchainStack";
import { CurrencyTable } from "~/components/imports-v2/CurrencyTable";
import { DeleteImportConfirmationDialog } from "~/components/imports-v2/DeleteImportConfirmationDialog";
import { TableHeaders } from "~/components/imports-v2/enums";
import {
  closeDrawer,
  createManualImportOption,
  getName,
  getSavedAccountFilterRules,
  getTrimmedStack,
  hasAvailableSyncMethods,
  isGroupedCurrencyIdentifier,
  isSyncPendingOrQueued,
  onlyHasImportedFiles,
  shouldCensorIntegrationName,
  sortByField,
} from "~/components/imports-v2/helpers";
import { HoldingViewToggleButton } from "~/components/imports-v2/HoldingViewToggleButton";
import { MoreOptionsImportOption } from "~/components/imports-v2/MoreOptionsDialog";
import { NftTable } from "~/components/imports-v2/NftTable";
import { SyncChip } from "~/components/imports-v2/SyncChip";
import { type SortType } from "~/components/imports-v2/types";
import { useBulkSyncContext } from "~/components/re-onboarding-flow/BulkSyncContext";
import { CurrencyStack } from "~/components/reconciliation/components/CurrencyStack";
import { useFormatPeriodDate } from "~/components/settings-modal/views/lock-period/useFormatPeriodDate";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { Calco } from "~/components/ui/calco/Calco";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { useIsMobile } from "~/components/ui/hooks";

import { devices } from "~/components/ui/theme/legacy";
import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { PrimaryButtonLink } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { LocalStorageKey } from "~/constants/enums";
import {
  LocalStorageUserKeyGenerator,
  useLocalStorage,
} from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue, safeFloat } from "~/lib/index";
import { search } from "~/lib/search";
import {
  useBestUser,
  useCountry,
  useLocalCurrency,
  useUser,
} from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { updateIntercomUser } from "~/services/intercom";
import { useEntitiesQuery } from "~/state/entities";
import { useAvailableImportOptionsLookup } from "~/state/imports";
import {
  useBulkSyncMutation,
  useFetchSavedAccounts,
  useGetHoldings,
  useGetSavedAccounts,
} from "~/state/importsV2";
import { useLockPeriodEndDate } from "~/state/period";
import {
  DeprecatedAPIs,
  FilterOperators,
  HoldingViewMode,
  ImportViewMode,
  Links,
} from "~/types/enums";
import {
  isBlockchainImport,
  isCustomImport,
  type SavedImportOptionByAccount,
} from "~/types/index";

interface AccountsTableContextType {
  closeDrawer: () => void;
  setImportOptionToDelete: (
    importOption: SavedImportOptionByAccount | null,
  ) => void;
}

export const AccountsTableContext = createContext<
  AccountsTableContextType | undefined
>(undefined);

const TableLayout = css`
  display: grid;
  flex-direction: row;
  color: ${({ theme }) => theme.tokens.text.low};
  border-bottom: 1px solid ${({ theme }) => theme.tokens.border.neutral.default}; // fix bottom border
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default}; // fix bottom border

  grid-template-areas: "account synced balance spacer";
  grid-template-columns: minmax(0, 1.2fr) 4rem minmax(0, 1fr) 2rem;

  @media ${devices.tabletS} {
    grid-template-areas: "account synced tx balance spacer";
    grid-template-columns: minmax(0, 2fr) 8.5rem 4rem minmax(0, 1fr) 2.25rem;
  }

  @media ${devices.tablet} {
    grid-template-areas: "account synced type tx  balance spacer";
    grid-template-columns: minmax(0, 2fr) 8.5rem 5.375rem 4rem minmax(0, 1fr) 2.25rem;
  }
  @media ${devices.laptop} {
    grid-template-areas: "account synced type tx assets balance spacer";
    grid-template-columns:
      minmax(0, 2fr) 9.5rem 5.375rem 4rem 9rem minmax(0, 1fr)
      2.25rem;
  }
`;

const AccTableHeader = styled(Box)`
  ${TableLayout}
  background: ${({ theme }) => theme.tokens.background.neutral.lowest.default};
  border-radius: 0.25rem 0.25rem 0 0;
  overflow: hidden;
`;

const AccTableRow = styled(({ last, expanded, ...props }) => (
  <Box {...props} />
))`
  ${TableLayout}
  background: ${({ theme, expanded }) =>
    expanded
      ? theme.tokens.background.neutral.lowest.pressed
      : theme.tokens.background.neutral.lowest.default};
  align-items: center;
  margin-top: -1px;
  margin-bottom: -1px;
  border-radius: ${({ last }) => (last ? "0 0 0.25rem 0.25rem" : "0 0 0 0")};
  position: relative;
  &&:hover {
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.hover};
  }
`;

const AccTableCell = styled((props) => <Box {...props} />)`
  padding: ${({ isMobile }) => (isMobile ? "0rem" : "0.5rem 0.75rem")};
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 0;
`;

const BalanceSkeletonContainer = styled(Box)`
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: flex-end;
`;

const AssetSkeletonContainer = styled(Box)`
  padding: 0.5rem 0 0.5rem 0.75rem;
  display: flex;
  justify-content: center;
`;

export const RemainingStackChip = styled(Box)`
  display: flex;
  padding: 0.125rem 0.25rem;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background: ${({ theme }) => theme.tokens.elevation.highest};
  color: ${({ theme }) => theme.tokens.text.low};
  text-align: center;
  font-family: ${({ theme }) => theme.tokens.fontFamilies.numeric};
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem;
  z-index: 6;
`;

const AccTableHeaderCell = ({
  text,
  sx,
  setSortOrder,
  activeSort,
  header,
}: {
  text: string;
  sx?: SxProps<Theme>;
  setSortOrder?: (sort: SortType) => void;
  activeSort: SortType;
  header: TableHeaders;
}) => {
  const { tokens } = useDesign();
  const { field, ascending } = activeSort;
  const isActive = field === header;
  const captureAnalytics = useCaptureImportAnalytics();

  const handleChange = () => {
    if (setSortOrder) {
      setSortOrder({
        ascending: isActive ? !ascending : false,
        field: header,
      });
    }
  };

  const handleChangeWrapped = () => {
    handleChange();
    captureAnalytics(importTableKey("sort"), { header });
  };

  return (
    <AccTableCell sx={sx} onClick={handleChangeWrapped}>
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        color={tokens.text.low}
        sx={{
          color: isActive ? tokens.text.default : tokens.text.low,
          "&:hover": {
            color: tokens.text.default,
          },
          cursor: "pointer",
        }}
        data-uncensored="true"
      >
        {text}
      </Typography>
      {setSortOrder ? (
        <Box
          display="flex"
          alignItems="center"
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          {isActive && ascending ? (
            <KeyboardArrowUp
              style={{
                color: tokens.text.default,
              }}
            />
          ) : (
            <KeyboardArrowDown
              style={{
                color: isActive ? tokens.text.default : tokens.text.disabled,
              }}
              visibility={isActive ? "visible" : "hidden"}
            />
          )}
        </Box>
      ) : null}
    </AccTableCell>
  );
};

const AccountsTableRow = ({
  importOption,
  last,
}: {
  importOption: SavedImportOptionByAccount;
  last: boolean;
}) => {
  const allImportsLookup = useAvailableImportOptionsLookup();
  const { tokens } = useDesign();
  const [open, setOpen] = useState(false);
  const [renderBody, setRenderBody] = useState(false);

  const handleCollapseChange = () => {
    const newOpen = !open;
    setOpen(newOpen);
    if (newOpen) {
      // closing of rendered body is handled by the collapse component exited event to avoid hiding the body before the animation is finished
      setRenderBody(true);
    }
  };

  return (
    <Box key={importOption.id}>
      <Box onClick={handleCollapseChange}>
        <TableRow importOption={importOption} last={last} open={open} />
      </Box>
      {allImportsLookup[importOption.id] || isCustomImport(importOption) ? (
        <Collapse
          in={open}
          onExited={() => {
            if (!open) setRenderBody(false);
          }}
        >
          {renderBody ? (
            <Box
              sx={{ padding: "1.5rem", background: tokens.elevation.low }}
              borderRadius={last ? "0 0 0.25rem 0.25rem" : "0 0 0 0"}
            >
              <ImportBody
                importOption={
                  allImportsLookup[importOption.id] ||
                  createManualImportOption(importOption)
                }
              />
            </Box>
          ) : null}
        </Collapse>
      ) : null}
    </Box>
  );
};

export const AccountsTable = () => {
  const { tokens, mui } = useDesign();
  const lang = useLang();
  const user = useUser();
  const navigate = useNavigate();
  const { isLoading: isLoadingSavedImportOptions } = useFetchSavedAccounts();
  const lookup = useAvailableImportOptionsLookup();
  const allImports = useGetSavedAccounts();
  const { import: importArg } = useParams<{ import: string | undefined }>();
  const isLaptop = useMediaQuery(devices.laptop);
  const isTablet = useMediaQuery(devices.tablet);
  const isTabletS = useMediaQuery(devices.tabletS);
  const [filteredItems, setFilteredItems] = useState(allImports);
  const [text, setText] = useState<string | null>(null);
  const activeUser = useBestUser();

  // Add the users current imports to their intercom profile
  useEffect(() => {
    if (allImports && allImports.length > 0) {
      const allImportsString = allImports
        .map((a) => a.id)
        .sort()
        .join(",");
      updateIntercomUser({
        integrations: `,${allImportsString},`,
      });
    }
  }, [allImports]);

  // Add this effect to update PostHog with NFT holdings status
  useEffect(() => {
    if (allImports && allImports.length > 0) {
      // Initialize NFT counter
      let nftCount = 0;

      // Loop through all imports to count NFTs
      allImports.forEach((importItem) => {
        // Check if this import has any assets that are NFTs
        importItem.assets?.forEach((asset) => {
          // Check if this is a grouped currency identifier with nftIds
          if (
            isGroupedCurrencyIdentifier(asset) &&
            asset.nftIds &&
            asset.nftIds.length > 0
          ) {
            nftCount += asset.nftIds.length;
          }
          // Count single nftId as one NFT if not already counted in nftIds
          else if (asset.nftId) {
            nftCount += 1;
          }
          // Count assets marked as NFT without specific IDs
          else if (asset.isMarkedAsNFT) {
            nftCount += 1;
          }
        });
      });

      // Update the PostHog user properties
      posthog.setPersonProperties({
        nftCount,
      });
    }
  }, [allImports]);

  // whenever the allImports change, copy the result into filteredItems
  // so that we can filter it when the user types
  const [prevItems, setPrevItems] = useState(allImports);
  if (allImports !== prevItems) {
    setPrevItems(allImports);
    setFilteredItems(allImports);
  }

  const allImportsWithTerms = React.useMemo(
    () =>
      allImports.map((a) => {
        return { ...a, searchTerms: lookup[a.id]?.searchTerms || [] };
      }),
    [allImports, lookup],
  );

  const handleSearch = search({
    keys: ["name", "searchTerms", "wallets.address"],
    data: allImportsWithTerms,
    callback: (result: typeof allImports) => {
      setFilteredItems(result);
    },
  });

  const [sortOrder, setSortOrder] = useLocalStorage<SortType>(
    LocalStorageUserKeyGenerator(user?.uid, LocalStorageKey.AccountSortOption),
    {
      field: TableHeaders.Balance,
      ascending: false,
    },
  );

  /**
   * Update the text input and filter the table
   */
  function handleUpdateText(input: string) {
    setText(input);
    handleSearch(input);
  }

  const [importOptionToDelete, setImportOptionToDelete] =
    useState<SavedImportOptionByAccount | null>(null);

  // const { dispatch } = useTransactionFilter();
  // const entities = useEntitiesQuery()?.data?.entities;

  const sortedImports = filteredItems.sort((a, b) =>
    sortByField(sortOrder, a, b, activeUser?.showSpamTransactions),
  );

  // const setFilter = useCallback(
  //   (importOption: SavedImportOptionByAccount) => {
  //     // Some import options (eg: blockchain) cannot be filtered by a source ID.
  //     const isAccountImportOption = [
  //       IntegrationCategory.CEX,
  //       IntegrationCategory.Wallet,
  //     ].includes(importOption.category);

  //     const importOptionResolved = isAccountImportOption
  //       ? getEntityOrAccountSource(importOption, entities || [])
  //       : importOption;

  //     const rules: FilterQuery[] = [
  //       {
  //         type: FilterOperators.Source,
  //         value: [
  //           isAccountImportOption ? importOptionResolved.id : "",
  //           ...importOptionResolved.wallets.map((c) => c.id),
  //         ].filter(Boolean),
  //       },
  //     ];

  //     dispatch({
  //       type: FilterActionType.SetFilter,
  //       filter: {
  //         type: FilterOperators.And,
  //         rules,
  //       },
  //     });
  //   },

  //   [dispatch, entities],
  // );

  // useEffect(() => {
  //   if (allImports.length && importArg) {
  //     const importOption = allImports.find((i) => i.id === importArg);

  //     if (importOption) setFilter(importOption);
  //   }
  // }, [allImports, importArg, setFilter]);

  return (
    <AccountsTableContext.Provider
      value={{
        closeDrawer: () => {
          closeDrawer(navigate);
        },
        setImportOptionToDelete,
      }}
    >
      {importOptionToDelete ? (
        <DeleteImportConfirmationDialog
          importOption={importOptionToDelete}
          open={!!importOptionToDelete}
          close={() => {
            setImportOptionToDelete(null);
          }}
        />
      ) : null}
      <AccTableHeader>
        <Box
          display="flex"
          flexDirection={isTabletS ? "row" : "column"}
          alignItems={isTabletS ? "center" : "flex-start"}
          paddingBottom={isTabletS ? 0 : "0.5rem"}
        >
          <AccTableHeaderCell
            sx={{ justifySelf: "start" }}
            text={lang.imports.accountsTableHeadings.account}
            setSortOrder={setSortOrder}
            header={TableHeaders.Account}
            activeSort={sortOrder}
          />
          <Box display="flex" gap="0.25rem">
            <TextField
              placeholder={lang.txTable.search}
              variant="outlined"
              type="search"
              size="small"
              value={text}
              onChange={(e) => {
                handleUpdateText(e.target.value);
              }}
              InputProps={{
                sx: {
                  ...mui.typography['Metropolis/Caption/Medium/Regular'],
                  color: tokens.text.low,
                  marginLeft: isTabletS ? "-0.5rem" : "0.75rem",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ fontSize: "1rem", color: tokens.text.low }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                bgcolor: tokens.background.neutral.lowest.default,
                ".MuiOutlinedInput-root": {
                  padding: "0.375rem",
                  height: "1.75rem",
                },
                ".MuiOutlinedInput-input": {
                  padding: 0,
                },
                ".MuiInputAdornment-root": {
                  marginRight: "0.3125rem",
                },
              }}
            />
            {text && (
              <TextButton
                size="small"
                onClick={() => {
                  handleUpdateText("");
                }}
                sx={{
                  whiteSpace: "nowrap",
                  padding: "0.25rem 0.75rem !important",
                  minWidth: "0 !important",
                }}
              >
                <Typography variant="Metropolis/Caption/Medium/Regular" color="inherit">
                  {lang.clear}
                </Typography>
              </TextButton>
            )}
          </Box>
        </Box>

        <AccTableHeaderCell
          sx={{ alignSelf: isTabletS ? "center" : "start" }}
          text={lang.imports.accountsTableHeadings.synced}
          header={TableHeaders.Synced}
          setSortOrder={setSortOrder}
          activeSort={sortOrder}
        />
        {isTablet && (
          <AccTableHeaderCell
            text={lang.imports.accountsTableHeadings.type}
            header={TableHeaders.Type}
            setSortOrder={setSortOrder}
            activeSort={sortOrder}
          />
        )}
        {isTabletS && (
          <AccTableHeaderCell
            text={lang.imports.accountsTableHeadings.tx}
            header={TableHeaders.Tx}
            setSortOrder={setSortOrder}
            activeSort={sortOrder}
          />
        )}
        {isLaptop && (
          <AccTableHeaderCell
            text={lang.imports.accountsTableHeadings.assets}
            header={TableHeaders.Assets}
            setSortOrder={setSortOrder}
            activeSort={sortOrder}
          />
        )}
        <AccTableHeaderCell
          sx={{ justifySelf: "end", alignSelf: isTabletS ? "center" : "start" }}
          text={lang.imports.accountsTableHeadings.balance}
          header={TableHeaders.Balance}
          setSortOrder={setSortOrder}
          activeSort={sortOrder}
        />
      </AccTableHeader>
      <Box>
        {isLoadingSavedImportOptions ? (
          Array.from({ length: 5 }, (_, index) => index).map((i) => (
            <TableDummyRow key={i} last={i === 4} />
          ))
        ) : sortedImports.length > 0 ? (
          sortedImports.map((importOption, index) => {
            return (
              <AccountsTableRow
                key={importOption.id}
                importOption={importOption}
                last={index === sortedImports.length - 1}
              />
            );
          })
        ) : (
          <Box
            minHeight="20rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap="0.5rem"
            p="3rem 1rem"
          >
            <Calco
              type="zen"
              width="150px"
              style={{
                alignSelf: "center",
              }}
            />

            <Typography variant="Metropolis/Header/H4" textAlign="center">
              {allImports.length
                ? lang.txTable.filter.noResults
                : lang.imports.addYourFirst}
            </Typography>
            <PrimaryButtonLink
              to={Links.ImportSearch}
              data-ctc="add-integration-button"
            >
              {lang.imports.addIntegrations}
            </PrimaryButtonLink>
          </Box>
        )}
      </Box>
    </AccountsTableContext.Provider>
  );
};

const TableRow = ({
  importOption,
  last = false,
  open,
}: {
  importOption: SavedImportOptionByAccount;
  last?: boolean;
  open: boolean;
}) => {
  const { viewMode } = useImportViewMode();
  const isLaptop = useMediaQuery(devices.laptop);
  const isTablet = useMediaQuery(devices.tablet);
  const isTabletS = useMediaQuery(devices.tabletS);

  return (
    <AccTableRow
      data-ctc={importOption.name.replaceAll(" ", "-")}
      sx={{ cursor: "pointer" }}
      expanded={open}
      last={last && !open}
    >
      <AccountCell importOption={importOption} viewMode={viewMode} />
      <SyncCell importOption={importOption} />
      {isTablet && <TypeCell importOption={importOption} />}
      {isTabletS && <TxCell importOption={importOption} />}
      {isLaptop && <AssetCell importOption={importOption} />}
      <BalanceCell importOption={importOption} />
      <Box gridArea={TableHeaders.Spacer}>
        <MoreOptionsImportOption importOption={importOption} />
      </Box>
    </AccTableRow>
  );
};

const TableDummyRow = ({ last = false }: { last?: boolean }) => {
  const isLaptop = useMediaQuery(devices.laptop);
  const isTablet = useMediaQuery(devices.tablet);
  const isTabletS = useMediaQuery(devices.tabletS);
  return (
    <AccTableRow last={last}>
      <AccTableCell gridArea={TableHeaders.Account}>
        <Skeleton width="100%" height="2.7rem" />
      </AccTableCell>
      <AccTableCell gridArea={TableHeaders.Synced}>
        <Skeleton width="100%" height="2.7rem" />
      </AccTableCell>
      {isTablet && (
        <AccTableCell gridArea={TableHeaders.Type}>
          <Skeleton width="100%" height="2.7rem" />
        </AccTableCell>
      )}
      {isTabletS && (
        <AccTableCell gridArea={TableHeaders.Tx}>
          <Skeleton width="100%" height="2.7rem" />
        </AccTableCell>
      )}
      {isLaptop && (
        <AccTableCell gridArea={TableHeaders.Assets}>
          <Skeleton width="100%" height="2.7rem" />
        </AccTableCell>
      )}
      <AccTableCell gridArea={TableHeaders.Balance}>
        <Skeleton width="100%" height="2.7rem" />
      </AccTableCell>
      <Box gridArea={TableHeaders.Spacer} />
    </AccTableRow>
  );
};

const AccountCell = ({
  importOption,
  viewMode,
}: {
  importOption: SavedImportOptionByAccount;
  viewMode: ImportViewMode;
}) => {
  const isMobile = useIsMobile();

  return (
    <AccTableCell
      gridArea={TableHeaders.Account}
      justifySelf="start"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      isMobile={isMobile}
    >
      <Box display="flex" alignItems="center" minWidth={0}>
        <ExchangeLogo
          name={importOption.id}
          currencySymbol={importOption.wallets?.[0]?.blockchain}
          useFileIconForExchange={isCustomImport(importOption)}
        />

        <Box gap="0.25rem" display="flex" alignItems="center" minWidth={0}>
          <Typography
            variant={"Metropolis/Header/H5"}
            fontSize="0.875rem"
            data-uncensored={
              !shouldCensorIntegrationName(importOption) && "true"
            }
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              minWidth: 0,
            }}
          >
            {getName(importOption, isMobile, Infinity)}
          </Typography>
          {isBlockchainImport(importOption) ? (
            viewMode === ImportViewMode.ByAddress ? (
              <>
                <CopyIconButton
                  width={12}
                  contentToCopy={importOption.id}
                  fontSize="0.75rem"
                />
                <BlockchainStack
                  blockchains={importOption.wallets.map((c) => c.blockchain)}
                />
              </>
            ) : (
              <BlockieStack
                wallets={importOption.wallets.sort((a, b) =>
                  a.address.localeCompare(b.address),
                )}
              />
            )
          ) : null}
        </Box>
      </Box>
    </AccTableCell>
  );
};

const SyncCell = ({
  importOption,
}: {
  importOption: SavedImportOptionByAccount;
}) => {
  return (
    <AccTableCell gridArea={TableHeaders.Synced} justifySelf="start">
      <SyncButtonChip importOption={importOption} />
    </AccTableCell>
  );
};

export function SyncButtonChip({
  importOption,
}: {
  importOption: SavedImportOptionByAccount;
}) {
  const { shouldBulkSync, selected } = useBulkSyncContext();

  const bulkSyncMutation = useBulkSyncMutation(importOption);
  const hasSyncOption = hasAvailableSyncMethods(importOption);
  const lockPeriodEndDate = useLockPeriodEndDate();
  const lang = useLang();
  const formatPeriodDate = useFormatPeriodDate();
  const timezone = useTimezone();
  const country = useCountry();

  const isCsvOnly = onlyHasImportedFiles(importOption);

  // Deprecated APIs are not supported, so we don't let users sync them
  // See DeprecatedAPIs for the list of deprecated APIs
  const apiIsDeprecated = Object.values(DeprecatedAPIs).includes(
    importOption.id as DeprecatedAPIs,
  );

  const title = apiIsDeprecated
    ? lang.imports.bulkSyncTooltip.deprecatedTooltip({
        name: importOption.name,
      })
    : getSyncTitle({
        lang,
        savedImport: importOption,
        timezone,
        country,
        hasSyncOption,
        isCsvOnly,
        lockPeriodEndDate,
        formatPeriodDate,
      });

  // determines if the user can trigger a sync/resync for the currently connected accounts
  const canSync = hasSyncOption && !isCsvOnly && !apiIsDeprecated;

  // this is for bulk sync all import option
  useEffect(() => {
    if (shouldBulkSync && selected.includes(importOption)) {
      bulkSyncMutation.mutate({ hardSync: false, showMessage: false });
    }
  }, [shouldBulkSync, bulkSyncMutation, selected, importOption]);

  return (
    <Tooltip title={title}>
      <span style={{ minWidth: 0 }}>
        <Box
          data-ctc={`sync-status-${importOption.syncStatus}`}
          sx={{ cursor: canSync ? "pointer" : undefined }}
          onClick={(e) => {
            e.stopPropagation();
            if (isSyncPendingOrQueued(importOption) || !canSync) {
              return;
            }

            bulkSyncMutation.mutate({ hardSync: false });
          }}
        >
          <SyncChip
            syncStatus={importOption.syncStatus}
            lastSyncTime={importOption.lastSyncComplete} // field might change in the future
            isCsv={onlyHasImportedFiles(importOption)}
          />
        </Box>
      </span>
    </Tooltip>
  );
}

const TypeCell = ({
  importOption,
}: {
  importOption: SavedImportOptionByAccount;
}) => {
  const lang = useLang();

  return (
    <AccTableCell gridArea={TableHeaders.Type}>
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        fontSize="0.75rem"
        data-uncensored="true"
      >
        {isBlockchainImport(importOption)
          ? lang.imports.accountTableTypes.blockchain
          : lang.imports.accountTableTypes.exchange}
      </Typography>
    </AccTableCell>
  );
};

const TxCell = ({
  importOption,
}: {
  importOption: SavedImportOptionByAccount;
}) => {
  const { tokens } = useDesign();
  const isLoading = isSyncPendingOrQueued(importOption);
  const entities = useEntitiesQuery()?.data?.entities ?? [];
  const { totalTxCount = 0, spamTxCount = 0 } = importOption;

  const user = useBestUser();

  const hasTxCount = totalTxCount && totalTxCount > 0;

  const txCount = user?.showSpamTransactions
    ? totalTxCount
    : totalTxCount - spamTxCount;

  const link = getTransactionPageLink({
    state: {
      filter: {
        type: FilterOperators.And,
        rules: getSavedAccountFilterRules(importOption, entities),
      },
    },
  });

  return (
    <AccTableCell gridArea={TableHeaders.Tx}>
      {isLoading ? (
        <Skeleton width="100%" height="2.7rem" />
      ) : (
        <Typography
          component={hasTxCount ? Link : Typography}
          to={link}
          variant="Metropolis/Caption/Medium/Regular"
          fontSize="0.75rem"
          sx={{
            textDecoration: "none",
            "&:hover": {
              textDecoration: hasTxCount ? "underline" : "none",
            },
          }}
          color={hasTxCount ? tokens.text.brand : tokens.text.default}
          data-uncensored="true"
        >
          {txCount}
        </Typography>
      )}
    </AccTableCell>
  );
};

const AssetCell = ({
  importOption,
}: {
  importOption: SavedImportOptionByAccount;
}) => {
  const isLoading = isSyncPendingOrQueued(importOption);

  if (isLoading) {
    return (
      <AssetSkeletonContainer>
        <Skeleton width="100%" height="2.7rem" />
      </AssetSkeletonContainer>
    );
  }

  const { shownItems, otherItemsCount } = getTrimmedStack(importOption.assets);

  return (
    <AccTableCell gridArea={TableHeaders.Assets}>
      <CurrencyStack currencies={shownItems} showTooltip />
      {otherItemsCount ? (
        <RemainingStackChip data-uncensored="true">{`+${otherItemsCount}`}</RemainingStackChip>
      ) : null}
    </AccTableCell>
  );
};

const BalanceCell = ({
  importOption,
}: {
  importOption: SavedImportOptionByAccount;
}) => {
  const localCurrency = useLocalCurrency();
  const langPref = useLanguagePreference();
  const isMobile = useIsMobile();
  const isLoading = isSyncPendingOrQueued(importOption);

  const [holdingView, setHoldingView] = useState<HoldingViewMode>(
    HoldingViewMode.Currencies,
  );
  const popupState = usePopupState({
    variant: "popover",
    popupId: "currency-balance-breakdown",
  });

  if (isLoading) {
    return (
      <BalanceSkeletonContainer>
        <Skeleton width="95%" height="2.7rem" />
      </BalanceSkeletonContainer>
    );
  }

  return (
    <AccTableCell
      gridArea={TableHeaders.Balance}
      justifySelf="end"
      isMobile={isMobile}
      width="100%"
    >
      <>
        <StyledTertiaryButton
          {...bindTrigger(popupState)}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            popupState.toggle(e);
          }}
          endIcon={<KeyboardArrowDown fontSize="small" />}
        >
          <Box pl={1} display="flex" alignItems="center" minWidth={0}>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <Box
                component="span"
                mr={2}
                display="inline-flex"
                alignItems="center"
                justifyContent="space-between"
                minWidth={0}
              >
                <Typography
                  variant={"IBM Plex Mono/Caption/Small/Regular"}
                  fontWeight={600}
                  fontSize="0.75rem"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    minWidth: "10ch",
                  }}
                >
                  {displayFiatValue({
                    value: safeFloat(importOption.value),
                    localCurrency,
                    locale: langPref,
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </StyledTertiaryButton>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: -8,
            horizontal: "right",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <HoldingPopoverContent
            importOption={importOption}
            holdingView={holdingView}
            setHoldingView={setHoldingView}
          />
        </Popover>
      </>
    </AccTableCell>
  );
};

const HoldingPopoverContent = ({
  holdingView,
  setHoldingView,
  importOption,
}: {
  importOption: SavedImportOptionByAccount;
  holdingView: HoldingViewMode;
  setHoldingView: (holdingView: HoldingViewMode) => void;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const { holdings, isLoading: loadingHoldings } = useGetHoldings(
    importOption.id,
    "all",
  );
  const entities = useEntitiesQuery()?.data?.entities || [];

  const isBlockchain = isBlockchainImport(importOption);
  const [currencies, nfts] = holdings
    ? partition(
        holdings.holdings,
        (h) =>
          !h.currencyIdentifier.nftId && !h.currencyIdentifier.isMarkedAsNFT,
      )
    : [[], []];

  const sourceQuery = getSavedAccountFilterRules(importOption, entities);

  const noHolding = (
    <Box minWidth="42.5rem" p="1rem">
      <Typography variant="Metropolis/Body/Bold" sx={{ color: tokens.text.low }}>
        {lang.holdingsDropdown.noHoldings}
      </Typography>
    </Box>
  );

  return (
    <Box
      bgcolor={tokens.elevation.low}
      style={{
        overflowX: "auto",
        overflowY: "hidden",
      }}
    >
      {!isBlockchain && !nfts.length ? null : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="0.5rem 1rem"
        >
          <HoldingViewToggleButton
            holdingView={holdingView}
            setHoldingView={setHoldingView}
          />
        </Box>
      )}

      {holdingView === HoldingViewMode.Currencies ? (
        <CurrencyTable
          holdings={currencies}
          sourceQuery={sourceQuery}
          width="42.5rem"
          isLoading={loadingHoldings}
          importOption={importOption}
        />
      ) : holdingView === HoldingViewMode.Nfts ? (
        <NftTable
          holdings={nfts}
          referenceCurrency={holdings?.referenceCurrency || null}
          width="42.5rem"
          stackSize={4}
          isLoading={loadingHoldings}
        />
      ) : (
        // shouldn't get here
        noHolding
      )}
    </Box>
  );
};

const StyledTertiaryButton = styled(TertiaryButton)`
  && {
    justify-content: space-between;
    padding: 0;
    text-align: left;
    padding-right: 0.5rem;
    min-height: 2rem;
    width: 100%;
    min-width: 0;
  }
`;
