import { HelpOutline, KeyboardArrowDown, Logout } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Container,
  Menu,
  MenuItem,
  Tab,
  type TabProps,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import React, {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { useCaptureAnalytics } from "~/analytics/posthog";
import poweredByCtcDark from "~/assets/powered-by-ctc-header-dark.svg";
import poweredByCtcLight from "~/assets/powered-by-ctc-header-light.svg";
import { AccountMenu } from "~/components/nav/AccountMenu";
import { StepItemType } from "~/components/nav/enum";
import { useAccountItems } from "~/components/nav/hooks/useAccountItems";
import { useNavTabs } from "~/components/nav/hooks/useNavTabs";
import { type LinkItemDetails } from "~/components/nav/types";
import { stripePromise } from "~/components/payment/StripePayment";
import { Chip } from "~/components/ui/Chips";
import { SyncStatusChip } from "~/components/ui/SyncStatusBadge";
import { useDesign, useResolvedTheme } from "~/hooks/useTheme";
import { HelpItem } from "~/components/nav/HelpItem";
import { useLogout, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { getUserPaidPlan } from "~/services/user";
import { Align, BrandStyle, Links, Theme } from "~/types/enums";

export const embeddedHorizontalNavHeight = "3rem";

const HeaderMasterStyled = styled.div`
  display: flex;
  height: ${embeddedHorizontalNavHeight};
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: transparent;
`;
export function HeaderMaster(props: PropsWithChildren) {
  return <HeaderMasterStyled {...props} />;
}

export const NewAppBar = styled(AppBar)`
  border-color: ${({ theme }) => theme.tokens.border.neutral.medium};
`;

/**
 * Returns a function that checks if a given path is part of the current route's pathname
 * @returns Function that takes a path and returns boolean indicating if the path is included in the current pathname
 */
const useIsCurrentPath = (): ((path: string) => boolean) => {
  const { pathname } = useLocation();
  return useCallback((path: string) => pathname.includes(path), [pathname]);
};

/** Props for the TabLabel component */
type TabLabelProps = {
  /** The text to display in the tab */
  label: React.ReactNode;
  /** Whether the tab is currently selected */
  selected: boolean;
  /** Whether the tab is disabled */
  disabled?: boolean;
};

/** Component for rendering consistent tab labels */
const TabLabel = ({ label, selected, disabled }: TabLabelProps) => {
  const { tokens } = useDesign();

  // Determine the text color based on disabled and selected states
  let textColor = tokens.text.high; // Default color
  if (disabled) {
    textColor = tokens.text.disabled;
  } else if (selected) {
    textColor = tokens.text.brand;
  }

  return (
    <Typography variant="Metropolis/Header/H5" color={textColor}>
      {label}
    </Typography>
  );
};

/**
 * Tab item configuration
 */
export type TabItem = {
  /** Display label for the tab */
  label: string;
  /** Navigation destination */
  to: string;
  /** Whether this tab is currently active */
  current: boolean;
  /** Whether this tab is disabled */
  disabled?: boolean;
  /** Optional tooltip text to display */
  tooltip?: NonNullable<React.ReactNode>;
};

const StyledTab = styled(
  ({
    tab,
    ...props
  }: TabProps & {
    tab: TabItem;
  }) => {
    const tabLabel = (
      <TabLabel
        label={tab.label}
        selected={tab.current}
        disabled={tab.disabled}
      />
    );

    // Create the Tab component
    const tabComponent = (
      <Tab
        label={tabLabel}
        disabled={false} // Never disable the tab to allow clicks
        {...props}
      />
    );

    // If there's a tooltip, wrap the Tab in a Tooltip
    if (tab.tooltip) {
      return (
        <Tooltip title={tab.tooltip} placement="top" arrow>
          <Box component="span" sx={{ display: "inline-block" }}>
            {tabComponent}
          </Box>
        </Tooltip>
      );
    }

    // Otherwise, just return the Tab
    return tabComponent;
  },
)(({ disabled }) => ({
  cursor: disabled ? "initial" : "pointer",
  opacity: disabled ? 0.5 : 1,
}));

/**
 * Action item details type
 */
export type ActionItemDetails = {
  /** Name of the action item */
  name: string;
  /** Action to perform when clicked */
  action: any;
  /** Optional icon for the action item */
  icon?: React.ReactNode;
};

export function TabNav({ children }: React.PropsWithChildren) {
  const lang = useLang();
  const captureAnalytics = useCaptureAnalytics();
  const stepItems = useNavTabs();
  const navigate = useNavigate();
  const user = useUser();
  const plan = getUserPaidPlan(user);
  const { tokens, brandStyle } = useDesign();
  const theme = useResolvedTheme();
  const reconciliationStep = stepItems.find(
    (step) => step.type === StepItemType.Link && step.id === "reconciliation",
  ) as LinkItemDetails;
  const accountItems = useAccountItems();
  const logout = useLogout();

  const logoutItem: ActionItemDetails = {
    name: lang.nav.logout,
    action: () => {
      logout();
      captureAnalytics("logout_clicked");
    },
    icon: <Logout fontSize="small" />,
  };
  const isCurrentPath = useIsCurrentPath();

  const tabs = React.useMemo(
    () => [
      {
        label: lang.tabNav.summary,
        to: Links.Dashboard,
        current: isCurrentPath(Links.Dashboard),
      },
      {
        label: lang.tabNav.connectedAccounts,
        to: Links.Imports,
        current: isCurrentPath(Links.Imports),
      },
      {
        label: lang.tabNav.transactions,
        to: Links.Transactions,
        current: isCurrentPath(Links.Transactions),
      },
      {
        label: lang.tabNav.review,
        to: reconciliationStep?.to,
        current: isCurrentPath(Links.Reconciliation),
        disabled: reconciliationStep?.disabled,
        tooltip: reconciliationStep?.tooltip,
      },
      {
        label: lang.tabNav.report,
        to: Links.Report,
        current: isCurrentPath(Links.Report),
      },
      {
        label: lang.tabNav.settings,
        to: "?settings=account",
        current: false,
      },
    ],
    [lang, reconciliationStep, isCurrentPath],
  );

  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);

  // Use refs to store tab width information - avoids state updates and re-renders
  const tabWidthRef = useRef<number>(7.5); // 7.5rem (~120px)
  const moreTabWidthRef = useRef<number>(7.5); // 7.5rem (~120px)

  // Use a ref to track our current visibility arrangement
  const tabVisibilityRef = useRef<{
    visible: typeof tabs;
    hidden: typeof tabs;
  }>({
    visible: [],
    hidden: [],
  });

  // State for rendering only, doesn't trigger recalculations
  const [visibleTabs, setVisibleTabs] = useState<typeof tabs>(tabs);
  const [hiddenTabs, setHiddenTabs] = useState<typeof tabs>([]);
  const [isReady, setIsReady] = useState(false);

  // Function to calculate which tabs should be visible - doesn't directly set state
  const calculateTabVisibility = useCallback(() => {
    if (!tabsContainerRef.current) return;

    const containerWidth = tabsContainerRef.current.offsetWidth;
    const tabWidth = tabWidthRef.current * 16; // Convert rem to px (approximate)
    const moreTabWidth = moreTabWidthRef.current * 16; // Convert rem to px (approximate)
    const maxTabs = Math.floor((containerWidth - moreTabWidth) / tabWidth);

    // Always keep Summary tab visible
    const summaryTab = tabs[0];
    const remainingTabs = tabs.slice(1);

    let newVisible: typeof tabs;
    let newHidden: typeof tabs;

    if (maxTabs <= 1) {
      newVisible = [summaryTab];
      newHidden = remainingTabs;
    } else {
      newVisible = [summaryTab, ...remainingTabs.slice(0, maxTabs - 1)];
      newHidden = remainingTabs.slice(maxTabs - 1);
    }

    // Only update state if the arrangement has changed
    const visibleChanged =
      newVisible.length !== tabVisibilityRef.current.visible.length ||
      newVisible.some(
        (tab, i) => tab.label !== tabVisibilityRef.current.visible[i]?.label,
      );

    const hiddenChanged =
      newHidden.length !== tabVisibilityRef.current.hidden.length ||
      newHidden.some(
        (tab, i) => tab.label !== tabVisibilityRef.current.hidden[i]?.label,
      );

    // If changes detected, update the ref and state
    if (visibleChanged || hiddenChanged) {
      tabVisibilityRef.current = {
        visible: newVisible,
        hidden: newHidden,
      };

      // Update state, but only as a side effect for rendering
      setVisibleTabs(newVisible);
      setHiddenTabs(newHidden);
    }

    // If we haven't marked the component as ready yet, do so now
    if (!isReady) {
      setIsReady(true);
    }
  }, [tabs, isReady]);

  // Set up ResizeObserver to handle window/container resizing
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      // Debounce the calculation for better performance
      requestAnimationFrame(() => {
        calculateTabVisibility();
      });
    });

    if (tabsContainerRef.current) {
      resizeObserver.observe(tabsContainerRef.current);
      // Initial calculation
      calculateTabVisibility();
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [calculateTabVisibility]);

  // Recalculate when tabs change
  useEffect(() => {
    calculateTabVisibility();
  }, [tabs, calculateTabVisibility]);

  // Memoize the selected tab index calculation to avoid recalculating on every render
  const selectedTabIndex = useMemo(() => {
    const selectedTabIndex = tabs.findIndex((tab) => tab.current);
    if (selectedTabIndex === -1) return -1;

    if (selectedTabIndex < visibleTabs.length) return selectedTabIndex;

    // If the selected tab is in the hidden portion of the array, return the index of the more tab
    return visibleTabs.length;
  }, [visibleTabs, tabs]);

  const borderBottom = brandStyle === BrandStyle.Binance ? 0 : 1;

  return (
    <Box display="flex">
      <NewAppBar
        position="fixed"
        color="transparent"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          borderTop: 0,
          borderBottom,
          backgroundColor: tokens.elevation.default,
        }}
      >
        <HeaderMaster data-ctc="header">
          <Box flexGrow={1} ref={tabsContainerRef}>
            {isReady ? (
              <Tabs
                value={selectedTabIndex}
                onChange={(_, index) => {
                  // Only navigate if it's not the More tab
                  if (index < visibleTabs.length) {
                    const selectedTab = visibleTabs[index];
                    // If tab is disabled, navigate to payments page instead
                    if (selectedTab.disabled) {
                      navigate(Links.Payment);
                    } else {
                      navigate(selectedTab.to);
                    }
                  }
                }}
                textColor="primary"
                indicatorColor="primary"
                sx={{
                  borderBottom: "none",
                }}
              >
                {visibleTabs.map((_tab, index) => {
                  // Need to pull the actual tab from the tabs array to get
                  // the correct current state
                  const tab = tabs[index];
                  return <StyledTab key={tab.label} tab={tab} />;
                })}
                {hiddenTabs.length > 0 && (
                  <StyledTab
                    tab={{
                      label: lang.tabNav.more,
                      to: "",
                      current: false,
                    }}
                    icon={
                      <KeyboardArrowDown
                        sx={{
                          transform: moreAnchorEl
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "transform 0.2s",
                        }}
                      />
                    }
                    iconPosition="end"
                    aria-label="more"
                    aria-controls={moreAnchorEl ? "more-menu" : undefined}
                    aria-expanded={Boolean(moreAnchorEl)}
                    aria-haspopup="true"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setMoreAnchorEl(e.currentTarget);
                    }}
                    sx={{
                      minHeight: "inherit",
                    }}
                  />
                )}
              </Tabs>
            ) : (
              <Box sx={{ height: "3rem" }} />
            )}
            <Menu
              id="more-menu"
              anchorEl={moreAnchorEl}
              open={Boolean(moreAnchorEl)}
              onClose={() => {
                setMoreAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {hiddenTabs.map((tab) => (
                <Tooltip
                  key={tab.label}
                  title={tab.tooltip ?? ""}
                  placement="right"
                >
                  <span>
                    <MenuItem
                      onClick={() => {
                        navigate(tab.to);
                        setMoreAnchorEl(null);
                      }}
                      disabled={tab.disabled}
                      sx={{
                        minWidth: "8rem",
                      }}
                    >
                      {tab.label}
                    </MenuItem>
                  </span>
                </Tooltip>
              ))}
            </Menu>
          </Box>
          <Box
            justifyContent="flex-end"
            gap="1rem"
            paddingX="1rem"
            display="flex"
            alignItems="center"
            flexShrink={0}
          >
            <Chip
              sx={{
                backgroundColor: tokens.background.brand.default,
                "&:hover": {
                  backgroundColor: tokens.background.brand.hover,
                },
              }}
              onClick={() => {
                navigate(Links.Payment);
              }}
              pointer
            >
              {lang.payment.base.copy.plans[plan].title} {lang.tabNav.plan}
            </Chip>
            <HelpItem />
            <SyncStatusChip />
            <Elements stripe={stripePromise}>
              <AccountMenu
                align={Align.Left}
                accountItems={accountItems}
                logoutItem={logoutItem}
              />
            </Elements>
            <Box
              borderLeft={`1px solid ${tokens.border.neutral.default}`}
              pl="1rem"
              display="flex"
              alignItems="center"
              minWidth="fit-content"
            >
              <img
                src={
                  theme === Theme.Dark ? poweredByCtcDark : poweredByCtcLight
                }
                alt="Powered by CTC"
                style={{
                  height: "2rem",
                  width: "auto",
                  display: "block",
                  minWidth: "max-content",
                  flexShrink: 0,
                }}
              />
            </Box>
          </Box>
        </HeaderMaster>
      </NewAppBar>

      <Box component="main" flexGrow={1} width="100%">
        <HeaderMaster />
        <Container maxWidth="xl" sx={{ marginTop: 8, paddingX: "1rem" }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
