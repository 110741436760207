import { Plan } from "@ctc/types";
import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";

import { AnalyticEvent } from "~/analytics/analyticsProperties";
import { useCaptureAnalyticEvents } from "~/analytics/captureAnalyticEvents";
import { usePlanPricing } from "~/components/payment/helpers";
import { REFERRER_CONFIG } from "~/components/payment/referrer-config";
import { useIsMobile } from "~/components/ui/hooks";
import metamaskLight from "~/components/ui/images/SVG_MetaMask_Horizontal_black.svg";
import metamaskDark from "~/components/ui/images/SVG_MetaMask_Horizontal_White.svg";
import { Partnership } from "~/components/ui/Partnership";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import {
  PaymentProvider,
  usePayment,
} from "~/contexts/payment-context/PaymentContext";
import { AnimationPanel } from "~/contexts/paywall-modal-context/irs-paywall-modal/AnimationPanel";
import {
  FeatureCard,
  StyledLink,
} from "~/contexts/paywall-modal-context/irs-paywall-modal/helpers";
import { IrsPaywallModalBase } from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModalBase";
import { type PaywallModalProps } from "~/contexts/paywall-modal-context/irs-paywall-modal/types";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useIsMetamaskEmbedded } from "~/hooks/useIsMetamaskEmbedded";
import { useDesign } from "~/hooks/useTheme";
import { useTransactionsBilledCount } from "~/hooks/useTransactions";
import { displayFiatValue } from "~/lib";
import { useLocalCurrency, useUser } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { CouponService } from "~/services/coupon";
import { useBestCoupons } from "~/state/billing";
import { usePlans } from "~/state/plans";
import { Links, Theme } from "~/types/enums";

export const PAYWALL_MODAL_VARIANT = "plan_selected" as const;

export function IrsPaywallModalPlanSelected({
  open,
  onClose,
  showCountdown = false,
}: PaywallModalProps) {
  const lang = useLang();
  const { tokens } = useDesign();
  const isMobile = useIsMobile();

  return (
    <IrsPaywallModalBase
      open={open}
      variant={PAYWALL_MODAL_VARIANT}
      onClose={onClose}
      showCountdown={showCountdown}
    >
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        gap="1.5rem"
        width="100%"
        height="100%"
        p={!isMobile ? "1rem" : undefined}
      >
        <Box
          width={isMobile ? "100%" : "11.625rem"}
          pr={isMobile && !showCountdown ? "2.5rem" : undefined}
        >
          <AnimationPanel
            topSection={<AnimationTopSection />}
            disableAnimation={isMobile}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="1rem"
          height={isMobile ? "100%" : undefined}
        >
          <Box display="flex" flexDirection="column" gap="1rem">
            <Typography variant="Metropolis/Header/H2" color={tokens.text.high}>
              {lang.paywallModal.irsVariantPreSelectedPlan.title}
            </Typography>
            <Box display="flex" flexDirection="column" gap="0.75rem">
              {lang.paywallModal.irsVariantPreSelectedPlan.features.map(
                ({ title, description }, index) => (
                  <FeatureCard
                    key={index}
                    title={title}
                    description={description}
                  />
                ),
              )}
            </Box>
          </Box>
          <PaymentProvider>
            <PaymentSection
              showTaxSeasonCountdown={showCountdown}
              onClose={onClose}
            />
          </PaymentProvider>
        </Box>
      </Box>
    </IrsPaywallModalBase>
  );
}

function AnimationTopSection() {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  const isEmbedded = useIsEmbedded();
  const isMetamaskEmbedded = useIsMetamaskEmbedded();
  const couponData = useBestCoupons();
  const localCurrency = useLocalCurrency();
  const locale = useLanguagePreference();
  const plan = useBestPlan();
  const isMobile = useIsMobile();

  if (isEmbedded && isMetamaskEmbedded) {
    return (
      <Box
        display="flex"
        justifyContent={isMobile ? "center" : "flex-start"}
        mx="0.5rem"
        mt={isMobile ? "1rem" : "0.5rem"}
        mb={isMobile ? "1rem" : "0"}
      >
        <Partnership
          partnerSrcLight={metamaskLight}
          partnerSrcDark={metamaskDark}
          partnerAlt="Metamask"
          disableHeader
          themeOverride={Theme.Dark}
        />
      </Box>
    );
  }

  const isInPromo = CouponService.getUserCouponCodes(user).length > 0;
  const couponCode = couponData.data?.couponsByPlan[plan]?.couponCode;

  const coupon = couponCode
    ? CouponService.getCouponConfig(couponCode)
    : undefined;
  const descriptions = user
    ? coupon?.descriptions({
        user,
        localCurrency,
        locale,
        lang,
      }) ?? []
    : [];
  const referrerConfig = coupon?.referrerSource
    ? REFERRER_CONFIG[coupon.referrerSource]
    : undefined;

  if (isInPromo && referrerConfig) {
    return (
      <Box display="flex" flexDirection="column" pl="0.5rem" pb="1rem">
        <Box
          p="0.13rem 0.5rem"
          borderRadius="0rem 0.5rem 0rem 0.5rem"
          bgcolor={tokens.background.accent.purple.medium}
          alignSelf="flex-end"
        >
          <Typography
            variant="Metropolis/Caption/Small/Regular"
            color={tokens.text.white}
          >
            {lang.paywallModal.irsVariantPreSelectedPlan.discountApplied}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column">
          <Box maxWidth="50%" mt="0.25rem" mb="1rem">
            {referrerConfig.logo()}
          </Box>

          <Box display="flex" flexDirection="column" gap="0.25rem">
            {descriptions.map((feature, index) => (
              <Typography
                key={index}
                variant="Metropolis/Body/Bold"
                color={tokens.text.white}
              >
                {feature}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
}

/**
 * Returns the investor plan if the user has less than the investor plans limit,
 * returns the trader plan otherwise
 */
function useBestPlan() {
  const txCount = useTransactionsBilledCount();
  const plans = usePlans().data;

  if (!plans) {
    return Plan.Trader;
  }

  const investorPlan = plans[Plan.Investor];

  if (!investorPlan) {
    return Plan.Trader;
  }

  if (txCount < investorPlan.paywallLimits.txCountLimit) {
    return Plan.Investor;
  }
  return Plan.Trader;
}

function PaymentSection({
  showTaxSeasonCountdown,
  onClose,
}: {
  showTaxSeasonCountdown: boolean;
  onClose: () => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  const localCurrency = useLocalCurrency();
  const locale = useLanguagePreference();
  const currentPlanType = user?.paidPlan;
  const plan = useBestPlan();
  const { isRedirecting, handlePlanChange } = usePayment();
  const couponData = useBestCoupons();
  const captureAnalytics = useCaptureAnalyticEvents();

  const { price, priceDiscounted, planCurrency, loadingCouponDetails } =
    usePlanPricing({
      planType: plan,
    });

  const hasPossibleCoupons = CouponService.getUserCouponCodes(user).length > 0;
  const isUnpaid = currentPlanType === Plan.Free;

  const hasDiscountedPrice =
    priceDiscounted !== null &&
    priceDiscounted !== undefined &&
    priceDiscounted >= 0;

  /** Shows the was price */
  const showDiscountedPrice = isUnpaid && hasDiscountedPrice;

  const priceDisplay = showDiscountedPrice ? priceDiscounted : price;

  const couponLoading = !!(
    isUnpaid &&
    hasPossibleCoupons &&
    loadingCouponDetails
  );

  const isDiscounted = !!priceDiscounted;
  const discountPercentage = priceDiscounted
    ? Math.round(100 - (priceDiscounted / price) * 100)
    : 0;

  const stripeCouponCode =
    couponData.data?.couponsByPlan[plan]?.stripeCouponCode;

  const onClick = () => {
    handlePlanChange(plan, true, stripeCouponCode);
    captureAnalytics(AnalyticEvent.PAYWALL_MODAL.PURCHASE_PLAN_BUTTON_CLICKED, {
      plan,
      showTaxSeasonCountdown,
      variant: PAYWALL_MODAL_VARIANT,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="0.5rem"
      width="100%"
    >
      <PrimaryButton
        onClick={onClick}
        disabled={isRedirecting}
        sx={{ width: "100%" }}
        endIcon={
          isRedirecting ? (
            <CircularProgress
              size="1rem"
              sx={{ color: tokens.text.disabled }}
            />
          ) : null
        }
      >
        {lang.paywallModal.irsVariantPreSelectedPlan.cta}
      </PrimaryButton>
      <Box display="flex" flexDirection="column" gap="0.25rem">
        {couponLoading ? (
          <Skeleton width="10rem" height="1rem" />
        ) : (
          <Box display="flex" gap="0.25rem" justifyContent="center">
            <Typography variant="Metropolis/Caption/Medium/Regular">
              {lang.paywallModal.irsVariantPreSelectedPlan.price({
                price: displayFiatValue({
                  value: priceDisplay,
                  localCurrency: planCurrency ?? localCurrency,
                  locale,
                  roundUp: true,
                }),
                discount: discountPercentage,
              })}
            </Typography>
            <StyledLink
              to={Links.Plans}
              onClick={() => {
                captureAnalytics(
                  AnalyticEvent.PAYWALL_MODAL.VIEW_PLANS_BUTTON_CLICKED,
                  {
                    showTaxSeasonCountdown,
                    variant: PAYWALL_MODAL_VARIANT,
                  },
                );
                onClose();
              }}
            >
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                color={tokens.text.brand}
                sx={{ textDecoration: "none" }}
              >
                {lang.paywallModal.irsVariantPreSelectedPlan.link}
              </Typography>
            </StyledLink>
          </Box>
        )}
        <Box textAlign="center">
          <Typography
            variant="Metropolis/Caption/Small/Regular"
            color={tokens.text.low}
          >
            {lang.paywallModal.irsVariantPreSelectedPlan.disclaimerOne}
          </Typography>
          {isDiscounted ? (
            <Typography
              variant="Metropolis/Caption/Small/Regular"
              color={tokens.text.low}
            >
              {lang.paywallModal.irsVariantPreSelectedPlan.disclaimerTwo}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
