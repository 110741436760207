import { Plan } from "@ctc/types";

import { PlanRank } from "~/constants/constants";
import { type AuthType } from "~/constants/enums";
import { get, post } from "~/services/core";
import {
  type Code2FADetails,
  type ForgottenPasswordDetails,
  type LoginDetails,
  type ResetDetails,
  type SignupDetails,
  type TokenLoginDetails,
  type UnlockDetails,
  type UpdatePasswordDetails,
  type UserDetails,
  type VerifyEmailDetails,
} from "~/types/index";

export function googleOauth() {
  const path = "/auth/google";
  return get<void>(path);
}

export function signup(input: SignupDetails) {
  const path = "/auth/signup";
  return post<true>(path, input);
}

export function getEmbeddedJwt() {
  const path = "/auth/jwt";
  return post<{ jwt: string }>(path, {});
}

export function requestMessage(input: { address: string; chain: number }) {
  const path = "/auth/wallet/request-message";
  return post<{ message: string }>(path, input);
}

export function verifyMessage(input: { message: string; signature: string }) {
  const path = "/auth/wallet/verify";
  return post<{ authType: AuthType }>(path, input);
}

export function reset(input: ResetDetails) {
  const path = "/auth/reset";
  return post<void>(path, input);
}

export function verifyEmail(input: VerifyEmailDetails) {
  const path = "/auth/verify-email";
  return post<void>(path, input);
}

export function unlock(input: UnlockDetails) {
  const path = "/auth/unlock";
  return post<void>(path, input);
}

export function forgottenPassword(input: ForgottenPasswordDetails) {
  const path = "/auth/forgot";
  return post<void>(path, input);
}

export function logout() {
  const path = "/auth/logout";
  return post<true>(path, {});
}

export function login(input: LoginDetails) {
  const path = "/auth/login";
  return post<{ codeRequired: boolean }>(path, input);
}

export function tokenLogin(input: TokenLoginDetails) {
  const path = "/auth/jwt-login";
  return post<{ codeRequired: boolean }>(path, input);
}

export function verify2FA(input: Code2FADetails) {
  const path = "/auth/login/2fa";
  return post<{ success: boolean }>(path, input);
}

export function enterCode(code: string) {
  const path = "/auth/code";
  return post<void>(path, { code });
}

export function updatePassword(input: UpdatePasswordDetails) {
  const path = "/auth/update-password";
  return post<void>(path, input);
}

export function oauthToPassword(input: { newPassword: string }) {
  const path = "/auth/oauth-to-password";
  return post<void>(path, input);
}

export function setPassword(newPassword: string) {
  const path = "/auth/password";
  return post<void>(path, { newPassword });
}

export function ping() {
  const path = "/ping";
  return get<"pong">(path);
}

export function isPaidPlan(plan?: Plan): boolean {
  if (!plan) {
    return false;
  }
  const paidPlans = [
    Plan.Rookie,
    Plan.Rookie_DEPRECATED,
    Plan.Hobbyist_DEPRECATED,
    Plan.Hobbyist,
    Plan.Investor,
    Plan.InvestorUs,
    Plan.InvestorUsPlus,
    Plan.Trader,
    Plan.TraderUs,
    Plan.TraderUsPlus,
    Plan.Accountant,
    Plan.Collaborator,
    Plan.Enterprise,
  ];
  return paidPlans.includes(plan);
}

/**
 * given the full user doc with all the profiles, return the best one to use for the purposes of determining
 * if a feature is paywalled or not
 */
export function getHighestPlanRankCurrentUser(user?: UserDetails | null) {
  if (!user) {
    return undefined;
  }
  // reduce the active child and parent profiles to the one with the highest plan rank
  return [
    user.activeProfileDetails,
    user.childProfileDetails,
    user.parentProfileDetails,
  ].reduce((best, profile) => {
    if (!profile) {
      return best;
    }
    if (!best) {
      return profile;
    }
    if (PlanRank[best.paidPlan] > PlanRank[profile.paidPlan]) {
      return best;
    }
    return profile;
  });
}

export function isAccountantView(user: UserDetails) {
  return user.paidPlan === Plan.Accountant;
}

export function isCollaboratorView(user: UserDetails) {
  return user.paidPlan === Plan.Collaborator;
}

export function isClientView(user: UserDetails) {
  return !isAccountantView(user) && !isCollaboratorView(user);
}

export function getUserPaidPlan(user: UserDetails | null | undefined): Plan {
  // Gets the paid plan of the underlying user (base user or child user if
  // on an accountant or collaborator view)

  return !user
    ? Plan.Free
    : user.activeProfileDetails.clientBridge && user.childProfileDetails
      ? user.childProfileDetails?.paidPlan || Plan.Free
      : user.paidPlan;
}
