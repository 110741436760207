import { type LocalCurrency, type SupportedLang } from "@ctc/types";

import { useCanSeeTimeframe } from "~/hooks/useCanSeeTimeframe";
import { displayFiatValue } from "~/lib/index";
import {
  useHighestRankingPlanUser,
  useLocalCurrency,
  useUser,
} from "~/redux/auth";
import { useLanguagePreference } from "~/redux/lang";

/**
 * Returns whether to show the mock report or not along with the paywall reason
 */
export const useScreenedFiatValue = () => {
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const user = useUser();
  const highestRankingPlanUser = useHighestRankingPlanUser();
  const canSeeTimeframe = useCanSeeTimeframe();

  return (value: number) => {
    if (!user || !highestRankingPlanUser) return "•";

    if (canSeeTimeframe) {
      return displayFiatValue({
        value,
        localCurrency,
        locale: languagePreference,
      });
    }

    /** USER HAS NOT PAID AND WE SCREEN */
    return screenData(value, localCurrency, languagePreference);
  };
};

export const screenData = (
  value: number,
  localCurrency: LocalCurrency | undefined,
  languagePreference: SupportedLang,
) => {
  const fiatValue = displayFiatValue({
    value,
    localCurrency,
    locale: languagePreference,
    fallbackMinFractionDigits: undefined,
    disableCurrencySymbol: undefined,
  });

  // if the number of numeric digits is one, replace it with a dot and return
  if (fiatValue.match(/[0-9]/g)?.length === 1) {
    return fiatValue.replace(/[0-9]/g, "•");
  }

  // find the index of the first number in fiatValue
  const firstNumberIndex = fiatValue.search(/[0-9]/);

  // split the string on the first number
  const [firstSlice, secondSlice] = [
    fiatValue.slice(0, firstNumberIndex + 1),
    fiatValue.slice(firstNumberIndex + 1),
  ];

  // edit the second half of the string (secondSlice) and replace all numbers with dots
  const censoredSlice = secondSlice.replaceAll(/[0-9]/g, "•");

  // combine the first and second slices
  const combinedSlice = firstSlice + censoredSlice;

  return combinedSlice;
};
