import { Box, Divider, Typography } from "@mui/material";
import type * as React from "react";

import { BoxSpinner } from "~/components/ui/Spinner";
/**
 * Used as a template panel for the settings / billings page
 */
export const ConfigurationPanel = ({
  title,
  isLoading,
  children,
  actionButtons,
}: {
  title: string;
  isLoading: boolean;
  children: React.ReactNode;
  actionButtons?: React.ReactNode;
}) => {
  return (
    <Box mt={2} mb={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        mb={1}
      >
        <Typography variant="Metropolis/Header/H5">{title}</Typography>
        <Box>{actionButtons}</Box>
      </Box>
      <Divider />
      <Box mt={2}>{isLoading ? <BoxSpinner size="1rem" /> : children}</Box>
    </Box>
  );
};
