import { SyncStatusPlatform } from "@ctc/types";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Skeleton,
  type SxProps,
  type Theme,
  Tooltip,
  Typography,
} from "@mui/material";

import { type GainLossEntryType } from "~/components/report/components/summary-box/helpers/getGainLossData";
import { useScreenedFiatValue } from "~/hooks/useScreenedFiatValue";
import { useDesign } from "~/hooks/useTheme";
import { useUserSyncStatus } from "~/state/sync";

export const GainLossEntry = ({
  data,
  sxMain,
  sxLeftText,
  sxRightText,
}: {
  data: GainLossEntryType;
  sxMain?: SxProps<Theme>;
  sxLeftText?: SxProps<Theme>;
  sxRightText?: SxProps<Theme>;
}) => {
  const { tokens } = useDesign();
  const displayScreenedFiatValue = useScreenedFiatValue();
  const syncStatus = useUserSyncStatus();
  const isReportRefreshing = syncStatus === SyncStatusPlatform.Pending;

  return (
    <Box
      key={data?.title}
      display="flex"
      justifyContent="space-between"
      marginBottom="0.25rem"
      gap="1rem"
      sx={sxMain}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="Metropolis/Body/Regular" sx={sxLeftText}>
          {data.title}
        </Typography>
        {data.tooltip ? (
          <Tooltip title={data.tooltip} placement="top">
            <Box component="span" height="1.25rem" ml={1} fontSize="1.125rem">
              <InfoOutlined
                style={{ color: tokens.icon.low }}
                fontSize="inherit"
              />
            </Box>
          </Tooltip>
        ) : null}
      </Box>
      <Typography
        sx={{ fontFamily: tokens.fontFamilies.numeric, ...sxRightText }}
        variant="Metropolis/Body/Regular"
      >
        {isReportRefreshing || data.total === null ? (
          <Skeleton sx={{ flex: 1, minWidth: "5rem" }} />
        ) : (
          displayScreenedFiatValue(data.total)
        )}
      </Typography>
    </Box>
  );
};
