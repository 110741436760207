import { Trade } from "@ctc/types";
import { Add } from "@mui/icons-material";
import { Box, Collapse } from "@mui/material";
import type * as React from "react";
import { useCallback, useState } from "react";
import { type css } from "styled-components";
import styled from "styled-components/macro";

import { useFeatureFlag } from "~/analytics/posthog";
import { removeOneSecondFromDate } from "~/components/balance-ledger/helpers";
import { useOnboardingContext } from "~/components/onboarding-v2/context/onboarding";
import { UncategorisedTableCalcoTour } from "~/components/reconciliation/components/UncategorisedTableCalcoTour";
import { ActionRowActions } from "~/components/transactions/action-row/ActionRowActions";
import {
  ActionRowAccountBalance,
  ActionRowOverallBalance,
} from "~/components/transactions/action-row/ActionRowBalance";
import { ActionRowDetails } from "~/components/transactions/action-row/actionRowDetails";
import {
  ActionRowFee as ActionRowFee2,
  LedgerRowFee,
} from "~/components/transactions/action-row/ActionRowFee";
import { ActionRowFrom } from "~/components/transactions/action-row/ActionRowFrom";
import { ActionRowGain } from "~/components/transactions/action-row/ActionRowGain";
import { ActionRowTime } from "~/components/transactions/action-row/ActionRowTime";
import { ActionRowTo } from "~/components/transactions/action-row/ActionRowTo";
import { ActionRowType } from "~/components/transactions/action-row/ActionRowType";
import { ActionRowValue } from "~/components/transactions/action-row/ActionRowValue";
import {
  ActionRowDefaultActions,
  ActionTableGridArea,
} from "~/components/transactions/action-row/enums";
import { transactionActionRowLayoutStyles } from "~/components/transactions/action-row/TransactionActionRowLayout";
import { useBreakdownsStore } from "~/components/transactions/BreakdownContext";
import { BreakdownTab } from "~/components/transactions/enums";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { AddManualTransactionDrawer } from "~/components/transactions/manual/AddManualTransactionDrawer";
import { useMinScreenWidthForTableContent } from "~/components/transactions/useTableStopsScrollingPx";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { type Tokens } from "~/components/ui/theme/tokens";
import { ActionRowContextProvider } from "~/contexts/ActionRowContext";
import { useHover } from "~/hooks/useHover";
import { useDesign } from "~/hooks/useTheme";
import {
  reconUncategorisedActionRowColumns,
  transactionActionRowColumns,
} from "~/hooks/useTransactionActionRowColumns";
import { useSelectedIds } from "~/hooks/useTransactions";
import { getActionRowKey } from "~/lib/getActionRowKey";
import { useLang } from "~/redux/lang";
import { useErpSettingsQuery } from "~/state/erp";
import { FeatureFlag, TradeDirection } from "~/types/enums";
import { type ActionRow, type CurrencyIdentifier } from "~/types/index";

export type TransactionActionRowProps = {
  row: ActionRow;
  disableRowExpansion?: boolean;
  disableHighlight?: boolean;
  layout?: ReturnType<typeof css>;
  isActive?: boolean;
};

const defaultBreakdownStatus = {
  isOpen: false,
  tab: BreakdownTab.Transactions,
};

const TransactionActionRowLayout = styled(Box)`
  ${transactionActionRowLayoutStyles}
`;

// Handles the general styling and the row breakdown, needs a row layout passed in as children
export function ActionRowWrapper({
  row,
  disableRowExpansion = false,
  disableHighlight = false,
  children,
}: React.PropsWithChildren<TransactionActionRowProps>) {
  const transactionFilters = useTransactionFilter();
  const selectedIds = useSelectedIds();

  const breakdownDefaultTab = useBreakdownsStore(
    (state) => state.breakdownDefaultTab ?? BreakdownTab.Transactions,
  );

  const setBreakdownDefaultTab = useBreakdownsStore(
    (state) => state.setBreakdownDefaultTab,
  );

  const breakdownOpenAndTabStatus = useBreakdownsStore(
    (state) => state.breakdowns[getActionRowKey(row)] ?? defaultBreakdownStatus,
  );
  const setBreakdownOpenAndTabStatus = useBreakdownsStore(
    (state) => state.setBreakdownStatus,
  );
  const toggleBreakdownOpenStatus = useBreakdownsStore(
    (state) => state.toggleBreakdown,
  );

  const { tokens } = useDesign();
  const { incoming, outgoing, fees } = row;
  const txIds = [...incoming, ...outgoing, ...fees].map((tx) => tx._id);

  const { highlight } = transactionFilters.state;
  const isHighlighted =
    highlight === row._id || txIds.includes(highlight as string);
  const isSelected = selectedIds.includes(row._id);

  const { handleShiftSelect } = useTransactionCheckbox();

  const rowId = row._id;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      if (event.shiftKey) {
        return;
      }
      if (!disableRowExpansion) {
        // If Shift key is not pressed and row expansion is not disabled, toggle the breakdown
        toggleBreakdownOpenStatus(getActionRowKey(row), breakdownDefaultTab);
      }
    },
    [breakdownDefaultTab, disableRowExpansion, row, toggleBreakdownOpenStatus],
  );
  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      if (!event.shiftKey) {
        return;
      }
      // Disable text selection
      // This stops it selection text since you are holding shift
      document.body.style.userSelect = "none";
      // Check the checkboxes
      handleShiftSelect(rowId);
    },
    [handleShiftSelect, rowId],
  );

  /** Reenable text selection on mouse up */
  const handleMouseUp = useCallback(() => {
    if (document.body.style.userSelect === "none") {
      document.body.style.userSelect = "";
    }
  }, []);

  return (
    <ActionRowContextProvider row={row}>
      <StyledRow
        isOpen={breakdownOpenAndTabStatus.isOpen}
        isLocked={row.isLocked}
        isHighlighted={!disableHighlight && (isHighlighted || isSelected)}
        disableRowExpansion={disableRowExpansion}
        onMouseDown={handleOpen}
        onClick={handleClick}
        onMouseUp={handleMouseUp}
      >
        {children}
      </StyledRow>
      <Collapse
        in={breakdownOpenAndTabStatus.isOpen}
        timeout="auto"
        unmountOnExit
      >
        <Box
          bgcolor={
            isHighlighted
              ? tokens.background.brand.default
              : tokens.elevation.low
          }
          borderTop={
            isHighlighted ? `1px solid ${tokens.border.neutral.low}` : "none"
          }
        >
          <ActionRowDetails
            row={row}
            tab={breakdownOpenAndTabStatus.tab}
            onTabChanged={(newTab) => {
              setBreakdownDefaultTab(newTab);
              setBreakdownOpenAndTabStatus(getActionRowKey(row), {
                isOpen: true,
                tab: newTab,
              });
            }}
          />
        </Box>
      </Collapse>
    </ActionRowContextProvider>
  );
}

function getCurrencies(row: ActionRow) {
  const inCurrencies = new Map<string, CurrencyIdentifier>();
  const outCurrencies = new Map<string, CurrencyIdentifier>();
  const allCurrencies = new Map<string, CurrencyIdentifier>();

  row.incoming.forEach((tx) => {
    inCurrencies.set(tx.currencyIdentifier.id, tx.currencyIdentifier);
    allCurrencies.set(tx.currencyIdentifier.id, tx.currencyIdentifier);
  });

  [...row.outgoing, ...row.fees].forEach((tx) => {
    outCurrencies.set(tx.currencyIdentifier.id, tx.currencyIdentifier);
    allCurrencies.set(tx.currencyIdentifier.id, tx.currencyIdentifier);
  });

  return {
    inCurrencies: Array.from(inCurrencies.values()),
    outCurrencies: Array.from(outCurrencies.values()),
    allCurrencies: Array.from(allCurrencies.values()),
  };
}

export const useActionRowActionWidthOverride = () => {
  const erpQuery = useErpSettingsQuery();
  const isExplainEnabled = useFeatureFlag(FeatureFlag.AiExplain);

  return !!erpQuery.data || isExplainEnabled
    ? { [ActionTableGridArea.Actions]: "8.5rem" }
    : undefined;
};

export function TransactionActionRowV2({ row }: TransactionActionRowProps) {
  const hover = useHover();
  const columns = transactionActionRowColumns();
  const defaultSizeOverrides = useActionRowActionWidthOverride();
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  return (
    <ActionRowWrapper row={row}>
      <TransactionActionRowLayout
        ref={hover.ref}
        columns={columns}
        defaultSizeOverrides={defaultSizeOverrides}
        minScreenSizeForContent={minScreenSizeForContent}
      >
        <ActionRowType hover={hover.value} row={row} />
        <ActionRowTime hover={hover.value} row={row} openInNewTab={false} />
        <ActionRowFrom hover={hover.value} row={row} />
        <ActionRowTo hover={hover.value} row={row} />
        <ActionRowValue hover={hover.value} row={row} />
        <ActionRowFee2 hover={hover.value} row={row} />
        <ActionRowGain hover={hover.value} row={row} />
        <ActionRowActions row={row} />
      </TransactionActionRowLayout>
    </ActionRowWrapper>
  );
}

export function TransactionPageLedgerRow({ row }: TransactionActionRowProps) {
  const hover = useHover();
  const currencies = getCurrencies(row);
  const defaultSizeOverrides = useActionRowActionWidthOverride();
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  return (
    <ActionRowWrapper row={row}>
      <TransactionActionRowLayout
        ref={hover.ref}
        columns={LedgerActionRowColumns}
        defaultSizeOverrides={defaultSizeOverrides}
        minScreenSizeForContent={minScreenSizeForContent}
      >
        <ActionRowType hover={hover.value} row={row} />
        <ActionRowTime hover={hover.value} row={row} openInNewTab={false} />
        <ActionRowFrom hover={hover.value} row={row} />
        <ActionRowTo hover={hover.value} row={row} />
        <LedgerRowFee hover={hover.value} row={row} />
        <ActionRowAccountBalance
          direction={TradeDirection.Out}
          hover={hover.value}
          row={row}
        />
        <ActionRowAccountBalance
          direction={TradeDirection.In}
          hover={hover.value}
          row={row}
        />
        <ActionRowOverallBalance
          hover={hover.value}
          row={row}
          currencyIdentifier={currencies.allCurrencies}
        />

        <ActionRowActions row={row} />
      </TransactionActionRowLayout>
    </ActionRowWrapper>
  );
}

export function ReconciliationActionRowV2({ row }: TransactionActionRowProps) {
  const hover = useHover();
  const columns = transactionActionRowColumns();
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  return (
    <ActionRowWrapper row={row}>
      <TransactionActionRowLayout
        ref={hover.ref}
        columns={columns}
        minScreenSizeForContent={minScreenSizeForContent}
      >
        <ActionRowType
          hover={hover.value}
          row={row}
          isCheckboxEnabled={false}
        />
        <ActionRowTime hover={hover.value} row={row} />
        <ActionRowFrom hover={hover.value} row={row} />
        <ActionRowTo hover={hover.value} row={row} />
        <ActionRowFee2 hover={hover.value} row={row} />
        <ActionRowValue hover={hover.value} row={row} />
        <ActionRowGain hover={hover.value} row={row} />
        <ActionRowActions row={row} />
      </TransactionActionRowLayout>
    </ActionRowWrapper>
  );
}

export function ReconUncategorisedActionRow({
  row,
}: TransactionActionRowProps) {
  const hover = useHover();
  const columns = reconUncategorisedActionRowColumns();
  const defaultSizeOverrides = useActionRowActionWidthOverride();
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  // Since user already in context mode, no need show view in context in the action dropdown
  const actionRowOptionConfig = Object.values(ActionRowDefaultActions).reduce(
    (acc, action) =>
      (
        // true for everything, only false for viewInContext
        (acc[action] = action !== ActionRowDefaultActions.ViewInContext), acc
      ),
    {} as Record<ActionRowDefaultActions, boolean>,
  );

  return (
    <ActionRowWrapper row={row}>
      <TransactionActionRowLayout
        ref={hover.ref}
        columns={columns}
        defaultSizeOverrides={defaultSizeOverrides}
        minScreenSizeForContent={minScreenSizeForContent}
      >
        <ActionRowType hover={hover.value} row={row} />
        <ActionRowTime hover={hover.value} row={row} />
        <ActionRowFrom hover={hover.value} row={row} />
        <ActionRowTo hover={hover.value} row={row} />
        <ActionRowValue hover={hover.value} row={row} />
        <ActionRowFee2 hover={hover.value} row={row} />
        <ActionRowActions row={row} defaultOptions={actionRowOptionConfig} />
      </TransactionActionRowLayout>
    </ActionRowWrapper>
  );
}

export function OnboardingReconUncategorisedActionRow({
  row,
  isActive,
}: TransactionActionRowProps) {
  const hover = useHover();
  const columns = reconUncategorisedActionRowColumns();
  const defaultSizeOverrides = useActionRowActionWidthOverride();
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  const lang = useLang();
  const {
    state: { seenLetsReviewTxModal },
  } = useOnboardingContext();

  // Many of the function is not needed for user in context mode
  const actionRowOptionConfig: Record<ActionRowDefaultActions, boolean> = {
    [ActionRowDefaultActions.Edit]: true,
    [ActionRowDefaultActions.Spam]: true,
    [ActionRowDefaultActions.ViewAssociatedTransactions]: true,
    [ActionRowDefaultActions.ViewInContext]: false,
    [ActionRowDefaultActions.Copy]: false,
    [ActionRowDefaultActions.Duplicate]: false,
    [ActionRowDefaultActions.Ungroup]: false,
    [ActionRowDefaultActions.Ignore]: true,
    [ActionRowDefaultActions.Delete]: false,
    [ActionRowDefaultActions.GenerateTransfer]: true,
  };
  return (
    <ActionRowWrapper row={row}>
      <UncategorisedTableCalcoTour
        title={lang.calcoTour.reconciliation.uncategorise.exampleTx}
        stepNumber={seenLetsReviewTxModal ? 0 : -1} // use -1 to hide the tooltip
        row={row}
        placement="bottom"
      >
        <TransactionActionRowLayout
          ref={hover.ref}
          columns={columns}
          defaultSizeOverrides={defaultSizeOverrides}
          minScreenSizeForContent={minScreenSizeForContent}
        >
          <UncategorisedTableCalcoTour
            title={
              // Note: Assumes the query only returns Incoming / Outgoing
              row.type === Trade.Out
                ? lang.calcoTour.reconciliation.uncategorise
                    .typeSelectorOutgoing
                : lang.calcoTour.reconciliation.uncategorise
                    .typeSelectorIncoming
            }
            stepNumber={1}
            row={row}
            placement="bottom"
          >
            <ActionRowType
              hover={hover.value}
              row={row}
              disablePointerEvents
              isCheckboxEnabled={false}
            />
          </UncategorisedTableCalcoTour>
          <ActionRowTime
            hover={hover.value}
            row={row}
            openInNewTab={false}
            disablePointerEvents
          />
          <UncategorisedTableCalcoTour
            title={lang.calcoTour.reconciliation.uncategorise.outgoingWallet}
            stepNumber={2}
            row={row}
            placement="bottom-start"
          >
            <ActionRowFrom hover={hover.value} row={row} disablePointerEvents />
          </UncategorisedTableCalcoTour>
          <UncategorisedTableCalcoTour
            title={lang.calcoTour.reconciliation.uncategorise.incomingWallet}
            stepNumber={3}
            row={row}
            placement="bottom-start"
          >
            <ActionRowTo hover={hover.value} row={row} disablePointerEvents />
          </UncategorisedTableCalcoTour>
          <ActionRowValue hover={hover.value} row={row} disablePointerEvents />
          <ActionRowFee2 hover={hover.value} row={row} disablePointerEvents />
          {isActive ? (
            <ActionRowActions
              row={row}
              hideReview
              defaultOptions={actionRowOptionConfig}
            />
          ) : null}
        </TransactionActionRowLayout>
      </UncategorisedTableCalcoTour>
    </ActionRowWrapper>
  );
}

export const LedgerActionRowColumns = [
  ActionTableGridArea.Type,
  ActionTableGridArea.Time,
  ActionTableGridArea.From,
  ActionTableGridArea.To,
  ActionTableGridArea.LedgerFee,
  ActionTableGridArea.AccountBalanceOut,
  ActionTableGridArea.AccountBalanceIn,
  ActionTableGridArea.OverallBalance,
  ActionTableGridArea.Actions,
];

export function LedgerActionRow({
  row,
  currencyIdentifier,
}: {
  row: ActionRow;
  currencyIdentifier: CurrencyIdentifier;
}) {
  const lang = useLang();
  const hover = useHover();
  const defaultSizeOverrides = useActionRowActionWidthOverride();

  const [isManualDrawerOpen, setIsManualDrawerOpen] = useState(false);
  const [addTransactionDisabled, setAddTransactionDisabled] = useState(true);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const { outgoing, incoming, fees } = row;

  const txs = [...outgoing, ...incoming, ...fees];

  const ledgerTx = txs.find(
    (tx) => tx.currencyIdentifier.id === currencyIdentifier?.id,
  );

  const handleCloseDrawer = (isSaving: boolean) => {
    if (isSaving) {
      setIsManualDrawerOpen(false);
      setIsSaveDialogOpen(false);
      return;
    }

    if (!addTransactionDisabled) {
      setIsSaveDialogOpen(true);
      return;
    }
    // If we are not saving and no changes have been made
    // we can just close the modal
    setIsManualDrawerOpen(false);
  };
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  return (
    <ActionRowWrapper row={row}>
      <>
        {ledgerTx ? (
          <AddManualTransactionDrawer
            open={isManualDrawerOpen}
            handleClose={handleCloseDrawer}
            setDisabled={setAddTransactionDisabled}
            // Set new date transaction time
            transactionTime={removeOneSecondFromDate(ledgerTx.timestamp)}
            // Prefill currency
            currencyIdentifier={ledgerTx.currencyIdentifier}
            noRedirect
          />
        ) : null}
        <ConfirmationDialog
          isOpen={isSaveDialogOpen}
          title={lang.transactionEdit.dialog.title}
          text={lang.transactionEdit.dialog.body}
          actionText={lang.transactionEdit.dialog.actionButton}
          cancelText={lang.transactionEdit.dialog.cancel}
          handleClose={() => {
            setIsSaveDialogOpen(false);
          }}
          handleAction={async () => {
            setIsSaveDialogOpen(false);
            setAddTransactionDisabled(true);
            setIsManualDrawerOpen(false);
          }}
          critical
          stopPropagation
        />
      </>
      <TransactionActionRowLayout
        ref={hover.ref}
        columns={LedgerActionRowColumns}
        defaultSizeOverrides={defaultSizeOverrides}
        minScreenSizeForContent={minScreenSizeForContent}
      >
        <ActionRowType hover={hover.value} row={row} />
        <ActionRowTime hover={hover.value} row={row} openInNewTab={false} />
        <ActionRowFrom hover={hover.value} row={row} />
        <ActionRowTo hover={hover.value} row={row} />
        <LedgerRowFee hover={hover.value} row={row} />
        <ActionRowAccountBalance
          direction={TradeDirection.Out}
          hover={hover.value}
          row={row}
          currencyIdentifier={[currencyIdentifier]}
        />
        <ActionRowAccountBalance
          direction={TradeDirection.In}
          hover={hover.value}
          row={row}
          currencyIdentifier={[currencyIdentifier]}
        />
        <ActionRowOverallBalance
          hover={hover.value}
          row={row}
          currencyIdentifier={[currencyIdentifier]}
        />
        <ActionRowActions
          row={row}
          addedOptions={[
            {
              handler: () => {
                setIsManualDrawerOpen(true);
              },
              label: lang.createTransactionBefore,
              icon: <Add style={{ fontSize: "1rem" }} />,
            },
          ]}
        />
      </TransactionActionRowLayout>
    </ActionRowWrapper>
  );
}

const getHoverBackgroundColor = ({
  theme,
  isHighlighted,
  isLocked,
}: {
  theme: { tokens: Tokens };
  isHighlighted: boolean;
  isLocked: boolean;
}) => {
  if (isLocked) {
    return theme.tokens.background.neutral.lowest.hover;
  }
  if (isHighlighted) {
    return theme.tokens.background.brand.hover;
  }
  return theme.tokens.background.neutral.lowest.hover;
};

const getBackgroundColor = ({
  theme,
  isHighlighted,
  isOpen,
}: {
  theme: { tokens: Tokens };
  isHighlighted: boolean;
  isOpen: boolean;
}) => {
  if (isHighlighted && isOpen) {
    return theme.tokens.background.brand.pressed;
  }
  if (isHighlighted && !isOpen) {
    return theme.tokens.background.brand.default;
  }
  if (isOpen) {
    return theme.tokens.background.neutral.lowest.pressed;
  }
  return theme.tokens.background.neutral.lowest.default;
};

const StyledRow = styled.div<{
  isOpen: boolean;
  isLocked: boolean;
  isHighlighted: boolean;
  disableRowExpansion: boolean;
}>`
  padding: 0.5rem 0.75rem;
  background-color: ${getBackgroundColor};

  .overflower:before {
    background-image: -webkit-linear-gradient(
      left,
      rgba(0, 0, 0, 0),
      ${getBackgroundColor}
    );
  }

  &:hover {
    .overflower:before {
      background-image: -webkit-linear-gradient(
        left,
        rgba(0, 0, 0, 0),
        ${getHoverBackgroundColor}
      );
    }

    background-color: ${getHoverBackgroundColor};
    ${({ disableRowExpansion }) =>
      !disableRowExpansion ? "cursor: pointer;" : ""}
  }
`;
