export enum AddressError {
  alreadyExists = "addressAlreadyExistsError",
  invalid = "invalidAddressError",
  tooManyWallets = "tooManyWalletsError",
  invalidEns = "invalidEnsError",
  invalidSns = "invalidSnsError",
}
export enum ActionType {
  SetStartedSync = "setStartedSync",
}
