import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { type ComponentPropsWithRef } from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import styled from "styled-components/macro";

export const baseTertiaryButtonColorStyles = css`
  && {
    color: ${({ theme }) => theme.tokens.text.default};
    background-color: ${({ theme }) => theme.tokens.button.neutral.default};
    border: 1px ${({ theme }) => theme.tokens.border.neutral.default} solid;
    border-radius: ${({ theme }) => theme.tokens.buttons.tertiary.borderRadius};
    justify-content: ${({ theme }) => theme.tokens.buttons.tertiary.alignment};
  }

  &:hover {
    background-color: ${({ theme }) => theme.tokens.button.neutral.hover};
    border: 1px ${({ theme }) => theme.tokens.border.neutral.highest} solid;
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.text.disabled};
    background-color: ${({ theme }) => theme.tokens.button.disabled};
  }
`;

const styling = css<{
  error?: boolean;
  highlighted?: boolean;
  active?: boolean;
  "aria-controls"?: string | undefined;
}>`
  transition: all 0.5s ease;
  && {
    color: ${({ theme, highlighted }) =>
      highlighted ? theme.tokens.text.brand : theme.tokens.text.default};
    background-color: ${({ theme }) => theme.tokens.button.neutral.default};
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px
      ${({ error, highlighted, theme }) =>
        error
          ? theme.tokens.border.danger
          : highlighted
            ? theme.tokens.border.brand
            : theme.tokens.border.neutral.default}
      solid;

    ${(props) =>
      // If this button is active or has an aria-controls attribute
      // this means its controlling some menu thats open, so we need to
      // show it in the active state
      Boolean(props["aria-controls"]) || props.active
        ? css`
            background-color: ${props.theme.tokens.button.neutral.pressed};
            border: 1px solid ${props.theme.tokens.border.neutral.highest};
            box-shadow:
              0px 1px 1px rgba(0, 0, 0, 0.08),
              0px 2px 5px rgba(31, 41, 55, 0.12),
              inset 0px -1px 1px rgba(0, 0, 0, 0.12);
          `
        : ""}
  }

  & .MuiButton-sizeMedium {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  & .MuiButton-sizeSmall {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.tokens.button.neutral.hover};
    border: 1px ${({ theme }) => theme.tokens.border.neutral.highest} solid;
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.08),
      0 2px 5px rgba(31, 41, 55, 0.12),
      inset 0 -1px 1px rgba(0, 0, 0, 0.12);
  }

  &.Mui-focusVisible {
    border: 1px ${({ theme }) => theme.tokens.border.neutral.highest} solid;
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.pressed};
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.08),
      0 2px 5px rgba(31, 41, 55, 0.12),
      inset 0 -1px 1px rgba(0, 0, 0, 0.12);
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.text.disabled};
    background-color: ${({ theme }) => theme.tokens.button.disabled};
    border: 1px rgba(0, 0, 0, 0) solid;
  }
`;

export const TertiaryButton = styled(Button)<{
  error?: boolean;
  highlighted?: boolean;
  active?: boolean;
}>`
  ${styling}
`;

export const TertiaryLoadingButton = styled(LoadingButton)<{
  error?: boolean;
  highlighted?: boolean;
  active?: boolean;
}>`
  ${styling}
`;

export const TertiaryLoadingButtonLink = styled(
  (
    props: ComponentPropsWithRef<typeof Link> &
      ComponentPropsWithRef<typeof LoadingButton>,
  ) => <LoadingButton component={Link} {...props} />,
)`
  ${styling}
`;

export const TertiaryButtonLink = styled(
  (
    props: ComponentPropsWithRef<typeof Link> &
      ComponentPropsWithRef<typeof Button>,
  ) => <Button component={Link} {...props} />,
)`
  ${styling}
`;
