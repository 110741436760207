import { type LocalCurrency, type Plan, type SupportedLang } from "@ctc/types";

import { type PlanContainerData } from "~/components/plans/PlanContainer";
import { CENTS_PER_DOLLAR } from "~/constants/constants";
import { type Translation } from "~/lang/index";
import { displayFiatValue } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useBusinessPlans, usePlans } from "~/state/plans";
import { type RetailPlanData } from "~/types/index";

export type PriceDataType = {
  planType: Plan;
  planName: string;
  /**
   * The $ value plan price after being converted to the users local currency
   * @example - "$2,390"
   **/
  planPriceInLocalCurrencyFormat: string;
};

function transformPlanPriceData({
  lang,
  locale,
  localCurrency,
  planType,
  amount,
  currency,
}: {
  lang: Translation;
  locale: SupportedLang;
  localCurrency: LocalCurrency;
  planType: Plan;
  amount: number;
  currency: LocalCurrency;
}): PriceDataType {
  const langPayment = lang.payment;

  const price = Math.floor(amount / CENTS_PER_DOLLAR);

  return {
    planType,
    planName: langPayment.base.copy.plans[planType].title,
    planPriceInLocalCurrencyFormat: displayFiatValue({
      value: price,
      localCurrency: currency ?? localCurrency,
      locale,
      roundUp: true,
      fractionDigits: 0,
    }),
  };
}

export function useBusinessPlanPriceData(): {
  priceData: PlanContainerData<RetailPlanData>[];
  getSelectedPlanData: (
    selectedPlanType: Plan,
  ) => PlanContainerData<RetailPlanData> | undefined;
} {
  const businessPlans = useBusinessPlans();
  const lang = useLang();
  const locale = useLanguagePreference();
  const localCurrency = useLocalCurrency();

  if (!localCurrency) {
    throw new Error("Local currency not found");
  }

  if (!businessPlans.data) {
    return { priceData: [], getSelectedPlanData: () => undefined };
  }

  const priceData = Object.entries(businessPlans.data).map(
    ([planType, planData]) => {
      const { amount, currency } = planData;
      return {
        ...transformPlanPriceData({
          lang,
          locale,
          localCurrency,
          planType: planType as Plan,
          amount,
          currency,
        }),
        data: planData,
      };
    },
  );

  const getSelectedPlanData = (selectedPlanType: Plan) =>
    priceData.find((priceData) => priceData.planType === selectedPlanType);

  return { priceData, getSelectedPlanData };
}

export function useRetailPlanPriceData(): {
  priceData: PlanContainerData<RetailPlanData>[];
  getSelectedPlanData: (
    selectedPlanType: Plan,
  ) => PlanContainerData<RetailPlanData> | undefined;
} {
  const retailPlans = usePlans();
  const lang = useLang();
  const locale = useLanguagePreference();
  const localCurrency = useLocalCurrency();

  if (!localCurrency) {
    throw new Error("Local currency not found");
  }

  if (!retailPlans.data) {
    return { priceData: [], getSelectedPlanData: () => undefined };
  }

  const priceData = Object.entries(retailPlans.data).map(
    ([planType, planData]) => {
      const { amount, currency } = planData;

      return {
        ...transformPlanPriceData({
          lang,
          locale,
          localCurrency,
          planType: planType as Plan,
          amount,
          currency,
        }),
        data: planData,
      };
    },
  );

  const getSelectedPlanData = (selectedPlanType: Plan) =>
    priceData.find((priceData) => priceData.planType === selectedPlanType);

  return { priceData, getSelectedPlanData };
}
