import { CommandPaletteChip } from "~/components/transactions/command-palette/CommandPaletteTitle";
import { type BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { BulkEditTypeIcons } from "~/components/transactions/command-palette/views/BulkEditTypeIcons";
import { invariant } from "~/lib/invariant";
import { useLang } from "~/redux/lang";

export const BulkEditOptionChip = ({
  type,
}: {
  type: BulkEditOptions;
}): JSX.Element | null => {
  const lang = useLang();
  const icon = BulkEditTypeIcons[type];
  invariant(icon, "Cannot find icon for type");
  const label = lang.txTable.commandPallet.bulkEditOptions[type];
  return <CommandPaletteChip icon={icon} label={label} size="small" />;
};
