import { type Blockchain } from "@ctc/types";
import { Add } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import merge from "lodash/merge";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { contactsIntegrationsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { FormAutocomplete } from "~/components/contacts/FormAutocomplete";
import { FormAutocompleteCreatable } from "~/components/contacts/FormAutocompleteCreatable";
import { useGetUnidentifiedAddresses } from "~/components/contacts/unidentifiedAddresses";
import { useSuccessButton } from "~/components/reconciliation/hooks";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useDesign } from "~/hooks/useTheme";
import { hashExchangeId } from "~/lib/hashExchangeId";
import { invariant } from "~/lib/invariant";
import { useLang } from "~/redux/lang";
import { useUpdateEntityMutation } from "~/state/entities";
import { BlockchainName, type Entity } from "~/types/index";

export const ContactsAddAddress = ({ entity }: { entity: Entity }) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const updateEntityMutation = useUpdateEntityMutation();
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const { handleSubmit, reset, control, getValues } = useForm<Partial<Entity>>({
    defaultValues: entity,
  });
  useEffect(() => {
    // If the entity updates, reset the form state
    reset(entity);
  }, [entity, reset]);
  const { getButtonProps } = useSuccessButton(updateEntityMutation);
  const onSubmit = (formResult: Partial<Entity>) => {
    invariant(entity, "Entity must exist");
    const updatedEntity = merge(entity, formResult);
    updateEntityMutation.mutate(
      {
        entity: updatedEntity,
      },
      {
        onSuccess() {
          setIsAddingAddress(false);
          reset();
          updateEntityMutation.reset();
        },
      },
    );
  };

  const {
    isLoading: isLoadingUnidentifiedAddresses,
    data: uniqueInteractedAddressed,
  } = useGetUnidentifiedAddresses();
  const analyticsKey = contactsIntegrationsAnalyticsKey("identified");
  const captureAnalytics = useCaptureAnalytics();

  return (
    <>
      {!isAddingAddress && (
        <TextButton
          onClick={() => {
            setIsAddingAddress(true);
            captureAnalytics(analyticsKey("add address clicked"));
          }}
          startIcon={<Add />}
        >
          {lang.contacts.addAddress.addAddress}
        </TextButton>
      )}

      {isAddingAddress && (
        <Stack direction="column" gap="0.5rem" paddingTop="1rem">
          <Typography variant="Metropolis/Caption/Medium/Regular" color={tokens.text.low}>
            {lang.contacts.addAddress.title}
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" mt="-0.5rem">
              <FormAutocompleteCreatable
                loading={isLoadingUnidentifiedAddresses}
                control={control}
                name={`addresses.${entity.addresses.length}.address`}
                label={lang.contacts.addAddress.address}
                autoSelect
                rules={{
                  required: true,
                  validate: () => {
                    const addressesPath = "addresses";
                    const index = entity.addresses.length;
                    // get the value of the blockchain and address for this item in the field array
                    const [address, blockchain, allAddresses] = getValues([
                      `${addressesPath}.${index}.address`,
                      `${addressesPath}.${index}.blockchain`,
                      addressesPath,
                    ]);
                    // check the value doesnt exist already in the contact
                    const hash = hashExchangeId({
                      exchange: address,
                      blockchain,
                    });
                    const existsAlready = allAddresses?.find(
                      (existingAddress, fIndex) =>
                        fIndex !== index &&
                        hashExchangeId({
                          exchange: existingAddress.address,
                          blockchain: existingAddress.blockchain,
                        }) === hash,
                    );
                    if (!existsAlready) {
                      return undefined;
                    }
                    return lang.contacts.create.duplicateAddress;
                  },
                }}
                options={uniqueInteractedAddressed.map(
                  (exchangeOption) => exchangeOption.label,
                )}
                getAddOption={(inputValue) => inputValue}
              />
              <Box mt="-0.5rem">
                <FormAutocomplete
                  control={control}
                  name={`addresses.${entity.addresses.length}.blockchain`}
                  label={lang.contacts.addAddress.blockchain}
                  options={Object.entries(BlockchainName).map(
                    ([blockchain]) => blockchain as Blockchain,
                  )}
                  getOptionLabel={(option: Blockchain) =>
                    BlockchainName[option]
                  }
                />
              </Box>
            </Stack>
            <Box display="flex" gap="0.5rem">
              <TertiaryButton
                onClick={() => {
                  setIsAddingAddress(false);
                }}
              >
                {lang.contacts.addAddress.buttons.cancel}
              </TertiaryButton>
              <PrimaryButton type="submit" {...getButtonProps()}>
                {lang.contacts.addAddress.buttons.add}
              </PrimaryButton>
            </Box>
          </form>
        </Stack>
      )}
    </>
  );
};
