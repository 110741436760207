import { Edit } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import type * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { CensoredTypography } from "~/components/ui/CensoredComponents";
import { devices } from "~/components/ui/theme/legacy";
import { COMMENT_CHARACTER_LIMIT } from "~/constants/constants";
import { useActionRow } from "~/contexts/ActionRowContext";
import { useActiveClient } from "~/hooks/useActiveClient";
import { useDesign } from "~/hooks/useTheme";
import { useIsManagingClients, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import {
  useActionCommentsQuery,
  useCreateActionCommentMutation,
  useDeleteActionCommentMutation,
  useUpdateActionCommentMutation,
} from "~/state/actions";
import { type ActionRow, type TransactionComment } from "~/types/index";

export function CommentsBox({ row }: { row: ActionRow }) {
  const { tokens } = useDesign();
  const res = useActionCommentsQuery(row._id);
  const addCommentMutation = useCreateActionCommentMutation();
  const lang = useLang();
  const [text, setText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const comments = res.data;
  const isLoading = res.isPending;

  const inputRef = useRef<HTMLInputElement>(null);

  // Focus the input when the component mounts so its easier for the user to start typing
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSubmit = async () => {
    const value = text.trim();
    if (value.length === 0 || value.length > COMMENT_CHARACTER_LIMIT) return;
    await addCommentMutation.mutateAsync({
      actionId: row._id,
      comment: value,
      shouldInvalidateActions: comments?.length === 0, // only invalidate when adding 1 comment
    });
    setText("");
  };

  const commentList = comments ? [...comments] : [];

  return (
    <div>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "0.875rem",
          color: tokens.text.default,
          marginBottom: "0.25rem",
        }}
      >
        {lang.txTable.expansionPanel.comments.comments({
          count: commentList.length,
        })}
      </Typography>
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          event.stopPropagation();
          await handleSubmit();
          setIsFocused(false);
        }}
      >
        <div
          css={`
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            @media ${devices.laptop} {
              // Switch to two column layout
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
              // set the widths of the two columns
              & > div:first-child {
                width: 38%;
              }
              & > div:nth-child(2) {
                width: 60%;
              }
            }
          `}
        >
          <Box>
            <StyledBox isFocused={isFocused}>
              <StyledTextField
                size="small"
                isFocused={isFocused}
                placeholder={lang.txTable.expansionPanel.comments.placeholder}
                value={text}
                onChange={handleChange}
                onKeyPress={async (
                  e: React.KeyboardEvent<HTMLImageElement>,
                ) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    await handleSubmit();
                  }
                }}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  setIsFocused(!!text);
                }}
                inputRef={inputRef}
                inputProps={{ sx: { fontSize: "0.875rem" } }}
                disabled={addCommentMutation.isPending}
                multiline
                fullWidth
              />
              {isFocused && (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Box ml="0.5rem" mb="0.5rem">
                    <Typography
                      sx={{
                        fontSize: "0.75rem",
                        fontWeight: 500,
                        color:
                          text.length > COMMENT_CHARACTER_LIMIT
                            ? tokens.text.danger
                            : tokens.text.low,
                      }}
                    >
                      {text.length}/{COMMENT_CHARACTER_LIMIT}
                    </Typography>
                  </Box>
                  <PrimaryButton
                    onClick={async () => {
                      await handleSubmit();
                      setIsFocused(false);
                    }}
                    disabled={
                      row.isLocked ||
                      !text ||
                      text.length > COMMENT_CHARACTER_LIMIT ||
                      addCommentMutation.isPending
                    }
                    sx={{ margin: "0.5rem" }}
                    endIcon={
                      addCommentMutation.isPending ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <ArrowForwardIcon />
                      )
                    }
                  >
                    {lang.txTable.expansionPanel.comments.button}
                  </PrimaryButton>
                </Box>
              )}
            </StyledBox>
          </Box>
          {!isLoading ? (
            <Box
              display="flex"
              flexDirection="column"
              bgcolor={tokens.elevation.low}
              border={`1px solid ${tokens.border.neutral.default}`}
              borderRadius="4px"
              py="0.5rem"
              px="1rem"
              maxHeight="18.75rem"
              overflow="auto"
            >
              {commentList.length !== 0 ? (
                commentList.map(
                  (comment: TransactionComment, index: number) => {
                    return (
                      <Box
                        mb={index === commentList.length - 1 ? "0" : "0.5rem"}
                        key={index}
                      >
                        <Comment
                          actionId={row._id}
                          comment={comment}
                          commentListLength={commentList.length}
                        />
                      </Box>
                    );
                  },
                )
              ) : (
                <Box
                  display="flex"
                  alignItems="flex-start"
                  height="3.25rem"
                  pt="0.25rem"
                >
                  <Box mr="0.5rem">
                    <Edit sx={{ color: tokens.text.low }} />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "0.875rem",
                        color: tokens.text.low,
                        paddingTop: "0.2rem",
                      }}
                    >
                      {lang.txTable.expansionPanel.comments.noComments}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={tokens.elevation.low}
              border={`1px solid ${tokens.border.neutral.default}`}
              borderRadius="4px"
              height="4.2rem"
              width="60%"
            >
              <CircularProgress size="1.5rem" />
            </Box>
          )}
        </div>
      </form>
    </div>
  );
}

/**
 * We want to show the relative date if its <30 days old, or the d/m/y if its older
 */
function getDateString(commentTime: Date, timezone: string): string {
  const now = new Date();
  const comment = new Date(commentTime);
  const diffTime = Math.abs(now.getTime() - comment.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays <= 30) {
    return moment.tz(commentTime, timezone).fromNow();
  }

  return moment.tz(commentTime, timezone).format("D MMM, YYYY"); // "Jan 1, 2020"
}

export function Comment({
  actionId,
  comment,
  commentListLength,
  editControls = true,
  lineClamp = false,
}: {
  actionId: string;
  comment: TransactionComment;
  commentListLength: number;
  editControls?: boolean;
  lineClamp?: boolean;
}) {
  const row = useActionRow();
  const { tokens } = useDesign();
  const editCommentMutation = useUpdateActionCommentMutation();
  const deleteCommentMutation = useDeleteActionCommentMutation();
  const user = useUser();
  const lang = useLang();
  const timezone = useTimezone();
  const activeClient = useActiveClient();
  const isManagingClients = useIsManagingClients();
  const [edit, setEdit] = useState(false);
  const [editText, setEditText] = useState<string>("");

  const time = getDateString(comment.timestamp, timezone);
  const lastEditedTime =
    comment.lastEdited && getDateString(comment.lastEdited, timezone);

  const getName = () => {
    // If we give the name, use the name
    if (comment.authorName) return comment.authorName;

    // If the comment uid matches the logged in user, return "you"
    if (comment.author === user?.uid)
      return lang.txTable.expansionPanel.comments.you;

    // If the user is not an accountant and its not their UID, return "accountant"
    if (!isManagingClients)
      return lang.txTable.expansionPanel.comments.accountant;

    // If you are an accountant, and the user is not the client, return "collaborator"
    if (
      activeClient &&
      ![activeClient.uuid, activeClient.childProfile].includes(comment.author)
    )
      return lang.txTable.expansionPanel.comments.collaborator;

    // If the user is the client, and we have the client name, return the client name
    if (activeClient?.name) return activeClient.name;

    // If we don't have the client name, return "client"
    return lang.txTable.expansionPanel.comments.client;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditText(event.target.value);
  };

  const handleSubmit = async () => {
    const value = editText.trim();
    if (value.length === 0 || value.length > COMMENT_CHARACTER_LIMIT) return;
    if (value !== comment.comment) {
      await editCommentMutation.mutateAsync({
        actionId,
        commentId: comment._id,
        comment: value,
      });
    }
    setEdit(false);
  };

  const handleDelete = () => {
    deleteCommentMutation.mutateAsync({
      actionId,
      commentId: comment._id,
      shouldInvalidateActions: commentListLength === 1, // only validate when deleting the last comment
    });
  };

  const Header = () => {
    const { tokens } = useDesign();
    return (
      <Box display="flex">
        <CensoredTypography
          sx={{
            fontWeight: 600,
            fontSize: "0.75rem",
            color: tokens.text.default,
            marginRight: "0.5rem",
          }}
        >
          {getName()}
        </CensoredTypography>
        <Typography
          sx={{
            fontWeight: 300,
            fontSize: "0.75rem",
            color: tokens.text.low,
          }}
        >
          {time}
        </Typography>
        {comment.lastEdited ? (
          <Tooltip
            title={lang.txTable.expansionPanel.comments.editedAt({
              time: lastEditedTime || "",
            })}
          >
            <span>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "0.75rem",
                  color: tokens.text.low,
                  marginLeft: "0.25rem",
                }}
              >
                {lang.txTable.expansionPanel.comments.edited}
              </Typography>
            </span>
          </Tooltip>
        ) : null}
      </Box>
    );
  };

  if (edit) {
    return (
      <Box>
        <Header />
        <Box my="0.25rem" sx={{ wordWrap: "break-word" }}>
          <TextField
            size="small"
            placeholder={lang.txTable.expansionPanel.comments.placeholder}
            value={editText}
            onChange={handleChange}
            onKeyPress={async (e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                await handleSubmit();
              }
            }}
            inputProps={{
              sx: { fontSize: "0.875rem" },
            }}
            disabled={editCommentMutation.isPending}
            multiline
            fullWidth
          />
        </Box>
        <Box
          my="0.5rem"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box>
            <PrimaryButton
              size="small"
              onClick={async () => {
                await handleSubmit();
              }}
              disabled={
                !editText ||
                editText.length > COMMENT_CHARACTER_LIMIT ||
                editCommentMutation.isPending ||
                row.isLocked
              }
              sx={{ marginRight: "0.5rem", fontSize: "0.75rem" }}
              endIcon={
                editCommentMutation.isPending ? (
                  <CircularProgress size="1rem" />
                ) : null
              }
            >
              {lang.txTable.expansionPanel.comments.saveChanges}
            </PrimaryButton>
            <TertiaryButton
              size="small"
              onClick={() => {
                setEdit(false);
              }}
              sx={{ fontSize: "0.75rem" }}
            >
              {lang.cancel}
            </TertiaryButton>
          </Box>
          <Box>
            <Box mr="0.5rem" mb="0.5rem">
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: 500,
                  color:
                    editText.length > COMMENT_CHARACTER_LIMIT
                      ? tokens.text.danger
                      : tokens.text.low,
                }}
              >
                {editText.length}/{COMMENT_CHARACTER_LIMIT}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Header />
      <Box my="0.25rem" sx={{ wordWrap: "break-word" }}>
        <CensoredTypography
          sx={{
            fontWeight: 500,
            fontSize: "0.875rem",
            color: tokens.text.default,
            whiteSpace: "pre-line",
            ...(lineClamp
              ? {
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "2",
                  "-webkit-box-orient": "vertical",
                }
              : {}),
          }}
        >
          {comment.comment}
        </CensoredTypography>
      </Box>
      {editControls && user && user.uid === comment.author && !!comment._id ? (
        <Box mb="0.25rem" display="flex" alignItems="center" gap="0.5rem">
          <TextButton
            onClick={
              row.isLocked
                ? undefined
                : () => {
                    setEdit(true);
                    setEditText(comment.comment);
                  }
            }
          >
            {lang.edit}
          </TextButton>
          <TextButton onClick={row.isLocked ? undefined : handleDelete}>
            {lang.delete}
          </TextButton>
          {deleteCommentMutation.isPending ? (
            <CircularProgress size="0.5rem" />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}

const TextButton = styled(Typography)`
  && {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.tokens.text.low};
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.tokens.text.brand};
  }
`;

const StyledTextField = styled(({ ...params }) => <TextField {...params} />)`
  && {
    max-height: 15.25rem;
    overflow: auto;
    border-radius: 0.25rem;
    ${({ isFocused }) => isFocused && `min-height:  5.5rem;`}
  }

  & .MuiOutlinedInput-input {
    ${({ isFocused }) => isFocused && `min-height:  5.5rem;`}
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`;

const StyledBox = styled(({ ...params }) => <Box {...params} />)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
    border-radius: 4px;
    ${({ isFocused }) => isFocused && `min-height:  10rem;`}
  }
`;
