import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { displayMessage } from "~/components/ui/Toaster";
import { invariant } from "~/lib/invariant";
import { useTaxSettings } from "~/redux/report";
import { HttpError } from "~/services/core";
import * as periodService from "~/services/period";
import { type TaxPeriodDTO } from "~/services/period";
import { actionKeys } from "~/state/actions";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import { DisplayMessage } from "~/types/enums";

export const periodKeys = {
  all: () => ["period"] as const,
  lists: () => [...periodKeys.all(), "lists"] as const,
};

export const useGetAllTaxPeriodsQuery = () => {
  return useQuery({
    queryKey: periodKeys.lists(),
    queryFn: async () => {
      const res = await periodService.getAllTaxPeriods();
      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["getAllTaxPeriods"]);
      }
      return res.data;
    },
  });
};

export const useCreateNewPeriodMutation = () => {
  const queryClient = useQueryClient();
  const taxSettings = useTaxSettings();
  const lastStartDate = useLockPeriodEndDate();

  return useMutation({
    mutationFn: async ({
      endDate,
      isLocked,
    }: {
      endDate: Date;
      isLocked: boolean;
    }) => {
      const res = await periodService.createNewPeriod({ endDate, isLocked });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["createNewPeriod"]);
      }

      return;
    },
    onMutate: async (variables: { endDate: Date; isLocked: boolean }) => {
      invariant(taxSettings, "Tax settings not found");
      const previous = queryClient.getQueryData<TaxPeriodDTO[]>(
        periodKeys.lists(),
      );

      // Cancel outgoing refetch (so they don't overwrite optimistic update).
      await queryClient.cancelQueries({ queryKey: periodKeys.lists() });

      queryClient.setQueryData<TaxPeriodDTO[]>(periodKeys.lists(), (old) => {
        if (!old) return old;

        // Optimistically lock all prior periods if new period is locked
        const prior = variables.isLocked
          ? old.map((period) => ({ ...period, isLocked: true }))
          : old;

        return [
          ...prior,
          {
            id: "-1",
            startDate: lastStartDate,
            endDate: variables.endDate,
            isComplete: false,
            inventoryMethod: taxSettings.inventoryMethod,
            specificInventoryByExchange:
              taxSettings.specificInventoryByExchange,
            isLocked: variables.isLocked,
            costBasisRedistributionMethod:
              taxSettings.costBasisRedistributionMethod,
            taxSettings,
          },
        ];
      });

      return { previous };
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(periodKeys.lists(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
      return queryClient.invalidateQueries({ queryKey: periodKeys.all() });
    },
  });
};

export const useDeletePeriodMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const res = await periodService.deletePeriod({ id });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["deletePeriod"]);
      }

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
      return queryClient.invalidateQueries({ queryKey: periodKeys.all() });
    },
  });
};

export const useLockPeriodEndDate = (): Date => {
  return useGetLockPeriodEndDateIfExists() ?? new Date(0);
};

/**
 * Custom hook to retrieve the end date of the most recent locked period.
 *
 * @returns {Date | undefined} The end date of the most recent locked period,
 * or undefined if no locked periods exist.
 */
export const useGetLockPeriodEndDateIfExists = () => {
  const periods = useGetAllTaxPeriodsQuery();

  const latest = useMemo(() => {
    return periods.data?.reduce((acc, period) => {
      if (new Date(period.endDate) > new Date(acc)) {
        return new Date(period.endDate);
      }
      return acc;
    }, new Date(0));
  }, [periods.data]);

  if (periods.data?.length === 0) {
    return undefined;
  }

  return latest;
};

export const useUpdatePeriodMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      update,
    }: {
      id: string;
      update: { isLocked: boolean };
    }) => {
      const res = await periodService.updatePeriod({ id, update });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["updatePeriod"]);
      }

      return res.data;
    },
    onMutate: async (variables: {
      id: string;
      update: { isLocked: boolean };
    }) => {
      const previous = queryClient.getQueryData<TaxPeriodDTO[]>(
        periodKeys.lists(),
      );

      // Cancel outgoing refetch (so they don't overwrite optimistic update).
      await queryClient.cancelQueries({ queryKey: periodKeys.lists() });

      queryClient.setQueryData<TaxPeriodDTO[]>(periodKeys.lists(), (old) => {
        if (!old) return old;

        const updatedPeriod = old.find((period) => period.id === variables.id);
        if (!updatedPeriod) return old;

        return old.map((period) => {
          if (period.id === variables.id) {
            return { ...period, ...variables.update };
          }

          // Optimistically lock all prior periods too
          if (
            variables.update.isLocked &&
            period.endDate < updatedPeriod.endDate
          ) {
            return { ...period, isLocked: true };
          }

          return period;
        });
      });

      return { previous };
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(periodKeys.lists(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
      return queryClient.invalidateQueries({ queryKey: periodKeys.all() });
    },
  });
};
