import { OAuthProvider } from "@ctc/types";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import styled from "styled-components/macro";

import coinbaseLogo from "~/assets/coinbase.svg";
import bitcoinDotComAuLogo from "~/assets/exchange-logos/bitcoindotcomau.png";
import independentReserveLogo from "~/assets/exchange-logos/independentreserve.png";
import metamaskLogo from "~/assets/exchange-logos/metamask.svg";
import googleLogo from "~/assets/google.svg";
import xeroLogo from "~/assets/xero.svg";
import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign } from "~/hooks/useTheme";
import { CORE } from "~/services/uri";

const StyledLoadingButton = styled(LoadingButton)`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 42px;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.tokens.background.disabled
      : theme.tokens.background.neutral.lowest.default};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.low};
  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .btn-box {
    width: 100%;
  }
  .btn-text {
    line-height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
  &:hover {
    background-color: ${({ disabled, theme }) =>
      disabled ? undefined : theme.tokens.background.neutral.hover};
    border-color: ${({ disabled, theme }) =>
      disabled ? undefined : theme.tokens.border.neutral.high};
    box-shadow: ${({ disabled, theme }) =>
      disabled ? "none" : `0 0 0.375rem ${theme.tokens.border.brand}`};
  }
`;

const DarkStyledLoadingButton = styled(StyledLoadingButton)`
  background-color: ${({ theme }) => theme.tokens.elevation.low};
  border-color: ${({ theme }) => theme.tokens.border.neutral.default};
  color: ${({ theme }) => theme.tokens.text.default};
  border-radius: 0.5rem;
  &:hover {
    box-shadow: none;
  }
`;

function getInfo(oAuthProvider: OAuthProvider): {
  logo: string;
  authUrlPath?: string;
} {
  switch (oAuthProvider) {
    case OAuthProvider.Google:
      return { logo: googleLogo, authUrlPath: "/auth/google" };
    case OAuthProvider.Xero:
      return { logo: xeroLogo, authUrlPath: "/auth/xero" };
    case OAuthProvider.Coinbase:
      return {
        logo: coinbaseLogo,
        authUrlPath: "/auth/coinbase",
      };
    case OAuthProvider.Moralis:
      return {
        logo: metamaskLogo,
      };
    case OAuthProvider.IndependentReserve:
      return {
        logo: independentReserveLogo,
      };
    case OAuthProvider.BitcoinDotComAu:
      return {
        logo: bitcoinDotComAuLogo,
      };
  }
}

export function OAuthLoginButton({
  text,
  oAuthProvider,
  action,
  loading = false,
  disabled = false,
  rightIcon = false,
}: {
  text: string;
  oAuthProvider: OAuthProvider;
  action?: any;
  loading?: boolean;
  disabled?: boolean;
  rightIcon?: boolean;
}) {
  const info = getInfo(oAuthProvider);
  const { tokens } = useDesign();
  const isEmbedded = useIsEmbedded();
  function handleSubmit() {
    if (action && !info.authUrlPath) {
      action();
      return;
    }
    window.location.href = `${CORE}${info.authUrlPath}`;
  }

  if (isEmbedded) {
    return (
      <PrimaryLoadingButton
        onClick={handleSubmit}
        role="button"
        tabIndex={0}
        loading={loading}
        disabled={disabled || loading}
        sx={{
          width: "100%",
          height: "2.25rem",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="center"
          gap="0.5rem"
        >
          {!rightIcon ? (
            <img
              className="icon"
              src={info.logo}
              alt={oAuthProvider}
              style={{ width: "1.125rem", height: "1.125rem" }}
            />
          ) : null}
          <Typography variant="Metropolis/Body/Regular" color="inherit">
            {text}
          </Typography>
          {rightIcon ? (
            <img
              className="icon"
              src={info.logo}
              alt={oAuthProvider}
              style={{ width: "1.125rem", height: "1.125rem" }}
            />
          ) : null}
        </Box>
      </PrimaryLoadingButton>
    );
  }

  return (
    <DarkStyledLoadingButton
      onClick={handleSubmit}
      role="button"
      tabIndex={0}
      loading={loading}
      disabled={disabled || loading}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        {!rightIcon ? (
          <img className="icon" src={info.logo} alt={oAuthProvider} />
        ) : null}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Typography
            variant="Metropolis/Body/Regular"
            color={tokens.text.default}
          >
            {text}
          </Typography>
        </Box>
        {rightIcon ? (
          <img className="icon" src={info.logo} alt={oAuthProvider} />
        ) : null}
      </Box>
    </DarkStyledLoadingButton>
  );
}
