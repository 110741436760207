import { Box, CircularProgress, Typography } from "@mui/material";

import { EntityAddresses } from "~/components/ui/EntityAddresses";
import { useDesign } from "~/hooks/useTheme";
import { useEntitiesQuery } from "~/state/entities";

export function Entities() {
  const { tokens } = useDesign();
  const entitiesQuery = useEntitiesQuery();

  return (
    <Box
      display="flex"
      gap="0.5rem"
      flexDirection="column"
      component="form"
      px={{ xs: 2, sm: 0 }}
      mt={4}
    >
      <Typography variant="Metropolis/Header/H4">Manage Entities</Typography>
      <Typography variant="Metropolis/Body/Bold">
        Manage entities for the current user
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {entitiesQuery.isPending ? (
          <CircularProgress />
        ) : entitiesQuery.data ? (
          entitiesQuery.data.entities.map((entity) => {
            return (
              <Box my="1rem" key={entity._id} color={tokens.text.default}>
                <EntityAddresses entity={entity} isTreeView />
              </Box>
            );
          })
        ) : null}
      </Box>
    </Box>
  );
}
