import { Button, IconButton } from "@mui/material";
import styled from "styled-components/macro";

export const CommandPaletteIconButton = styled(IconButton)`
  height: 1.5rem;
  width: 1.5rem;
  background-color: ${({ theme }) =>
    theme.tokens.background.accent.neutral.lowest};
  color: ${({ theme }) => theme.tokens.text.low};
  border-radius: 4px;
  :hover {
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.neutral.low};
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
    color: ${({ theme }) => theme.tokens.text.high};
  }
  &.Mui-focusVisible {
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.neutral.medium};
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
    color: ${({ theme }) => theme.tokens.text.high};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.text.disabled};
    border: 1px solid ${({ theme }) => theme.tokens.border.disabled};
    background-color: ${({ theme }) => theme.tokens.button.disabled};
  }
`;

export const CommandPaletteTextButton = styled(Button)`
  background-color: transparent;
  border-radius: 0.25rem;
  gap: 0.5rem;

  & .MuiTypography-root {
    color: ${({ theme }) => theme.tokens.text.low};
  }

  & .MuiBox-root {
    height: 1.5rem;
    width: 1.5rem;
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.neutral.lowest};
    color: ${({ theme }) => theme.tokens.text.low};
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :hover {
    background-color: ${({ theme }) => theme.tokens.background.neutral.hover};
    & .MuiTypography-root {
      color: ${({ theme }) => theme.tokens.text.high};
    }
    & .MuiBox-root {
      background-color: ${({ theme }) => theme.tokens.elevation.highest};
    }
  }

  &.Mui-focusVisible {
    background-color: ${({ theme }) => theme.tokens.background.neutral.pressed};
    & .MuiTypography-root {
      color: ${({ theme }) => theme.tokens.text.high};
    }

    & .MuiBox-root {
      color: ${({ theme }) => theme.tokens.text.high};
      background-color: ${({ theme }) => theme.tokens.elevation.highest};
    }
  }

  &.Mui-disabled {
    & .MuiTypography-root {
      color: ${({ theme }) => theme.tokens.text.disabled};
    }
    & .MuiBox-root {
      color: ${({ theme }) => theme.tokens.text.disabled};
      background-color: ${({ theme }) => theme.tokens.elevation.highest};
    }
    background-color: ${({ theme }) => theme.tokens.background.neutral.hover};
  }
`;
