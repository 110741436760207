import { Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getInvitationDescription } from "~/components/settings-modal/views/permissions/getInvitationDescription";
import { getNextStatusOptions } from "~/components/settings-modal/views/permissions/getNextStatusOption";
import { NewInvitationDialog } from "~/components/settings-modal/views/permissions/NewInvitationDialog";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import {
  getInvitationStatusIcon,
  useInvitationTitle,
} from "~/components/ui/InvitationStatusDetails";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { loadUser, useUser } from "~/redux/auth";
import { CollaborationSyncEvent, LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import { Analytics } from "~/segment/index";
import * as invitationsAPI from "~/services/invitations";
import { invitationKeys, useBestInvitation } from "~/state/invitations";
import { useShouldCollaborationSync } from "~/state/sync";
import { InvitationStatus, InvitationType } from "~/types/enums";

import { ConfirmationDialog } from "../../../ui/ConfirmationDialog";
import { TextConfirmationModal } from "../../../ui/TextConfirmationModal";

const useContent = () => {
  const { data } = useBestInvitation();
  const { invitation, type } = data ?? {
    invitation: null,
    type: null,
  };
  const { invitation: invitationLang, settings } = useLang();
  const { tokens } = useDesign();
  const user = useUser();
  const invitationTitle = useInvitationTitle(invitation, type);

  if (invitation)
    return {
      title: invitationTitle,
      statusIcon: getInvitationStatusIcon(invitation, type, tokens),
      description: getInvitationDescription(invitation, type),
      actions: getNextStatusOptions(invitation, type) || [],
    };

  if (user && user.parentProfile && user.accountant) {
    return {
      title: settings.clientData.invitation.status.accepted,
      description: user.accountant.email,
      actions: [InvitationStatus.RevokedLink],
    };
  }

  return {
    title: invitationLang.receivedInvitations,
    description: invitationLang.noInvites,
    actions: [],
  };
};

export const AccountantInvite = () => {
  const queryClient = useQueryClient();
  const { tokens } = useDesign();
  const dispatch = useDispatch();
  const lang = useLang();
  const { invitation: invitationLang } = lang;
  const user = useUser();
  const { data: bestInvitation } = useBestInvitation();
  const [pending, setPending] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [newStatus, setNewStatus] = React.useState<InvitationStatus | null>(
    null,
  );
  const { shouldCollabSync, consumeCollabEvent } = useShouldCollaborationSync([
    CollaborationSyncEvent.InvitePending,
  ]);

  const { title, statusIcon, description, actions } = useContent();

  useEffect(() => {
    setNewStatus(null);
  }, [bestInvitation?.invitation]);

  useEffect(() => {
    if (shouldCollabSync) {
      void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
      consumeCollabEvent();
    }
  }, [shouldCollabSync, consumeCollabEvent, queryClient]);

  const handleDialogClose = () => {
    setOpen(false);
  };

  async function handleUpdate() {
    if (!bestInvitation?.invitation || !newStatus) {
      return;
    }
    const {
      invitation: { _id, fromEmail },
    } = bestInvitation;
    setPending(true);
    const res = await invitationsAPI.updateInvitation(_id, {
      status: newStatus,
    });
    setPending(false);
    if (res.error) {
      // TODO: handle error
    } else {
      // When accepting or revoking an invitation, this causes the active profile to change in the backend
      // meaning that the redux state.auth needs to refreshed based on the new profile by relogin in.
      dispatch(loadUser(LoadUserType.Login));
      void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
      handleDialogClose();

      const analytics = await Analytics.getInstance();
      if (newStatus === InvitationStatus.Accepted) {
        analytics.track({
          event: "accountant_invite_accepted",
          user,
          props: {
            invitation_id: _id,
            accountant_email: fromEmail,
          },
        });
      } else if (newStatus === InvitationStatus.Revoked) {
        analytics.track({
          event: "accountant_access_revoked",
          user,
          props: {
            invitation_id: _id,
            accountant_email: fromEmail,
          },
        });
      }
    }
  }

  async function handleDelete() {
    if (!bestInvitation?.invitation) {
      return;
    }
    const {
      invitation: { _id },
    } = bestInvitation;
    setPending(true);
    const res = await invitationsAPI.deleteInvitation(_id);
    setPending(false);
    if (res.error) {
      alert(invitationLang.warning);
    } else {
      void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
      handleDialogClose();
    }
  }

  function handleButtonClick(status: InvitationStatus) {
    setNewStatus(status);
    setOpen(true);
  }

  async function handleRevokeLink() {
    setPending(true);
    await invitationsAPI.revokeInvitationDirectly();
    setPending(false);
    void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
    dispatch(loadUser(LoadUserType.Login));
    handleDialogClose();
  }

  async function handleConfirmAction() {
    if (!newStatus) return;
    if (newStatus === InvitationStatus.Deleted) return handleDelete();
    if (newStatus === InvitationStatus.RevokedLink) return handleRevokeLink();

    return handleUpdate();
  }

  const renderDialog = () => {
    if (
      !bestInvitation?.invitation &&
      newStatus !== InvitationStatus.RevokedLink
    ) {
      return (
        <NewInvitationDialog
          open={open}
          handleClose={handleDialogClose}
          invitationType={InvitationType.Accountant}
        />
      );
    }

    const statusToUse =
      newStatus === InvitationStatus.RevokedLink
        ? InvitationStatus.Revoked
        : newStatus;

    if (statusToUse) {
      const dialogProps =
        invitationLang.confirmationDialog[
          bestInvitation?.invitation?.invitationType ===
          InvitationType.Collaborator
            ? "collaborator"
            : "other"
        ][statusToUse];

      if (statusToUse === InvitationStatus.Revoked) {
        return (
          <TextConfirmationModal
            isOpen={open}
            handleSubmit={handleConfirmAction}
            handleClose={handleDialogClose}
            pending={pending}
            confirmationText={
              lang.invitation.confirmationDialog.collaborator.revoked
                .confirmationText
            }
            confirmationPrompt={
              lang.invitation.confirmationDialog.collaborator.revoked
                .confirmationPrompt
            }
            {...dialogProps}
          />
        );
      }
      return (
        <ConfirmationDialog
          isOpen={open}
          handleAction={handleConfirmAction}
          handleClose={handleDialogClose}
          pending={pending}
          {...dialogProps}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderDialog()}
      <SettingsBox title={lang.settings.tabs.permissions}>
        <Box display="flex" flexDirection="column">
          <Box display="inline-flex">
            <Typography variant="Metropolis/Body/Regular" mb={1}>
              {title}
            </Typography>
            <Box pl="0.25rem">{statusIcon}</Box>
          </Box>
          <Box>
            <Typography
              variant="Metropolis/Body/Regular"
              color={tokens.text.low}
            >
              {description}
            </Typography>
          </Box>
        </Box>
        <Box mt="0.5rem">
          {actions.map((option) => (
            <Box key={option} component="span" mr={1} display="inline">
              <TertiaryButton
                variant="outlined"
                onClick={() => {
                  handleButtonClick(option);
                }}
              >
                {invitationLang.buttonText[option]}
              </TertiaryButton>
            </Box>
          ))}
        </Box>
      </SettingsBox>
    </>
  );
};
