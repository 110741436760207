import {
  type ApiCredentials,
  type ArchiveStatus,
  Blockchain,
  type CaptchaProvider,
  type CostBasisRedistributionMethod,
  Country,
  type CurrencySourcePlatform,
  type DiscountType,
  type EmailVerificationStatus,
  type EntityAddress,
  type EntityAddressQuery,
  type InventoryMethod,
  LocalCurrency,
  type OAuthProvider,
  Plan,
  type PlanVersion,
  type ReferrerSource,
  SupportedLang,
  type SyncErrorIssue,
  type SyncStatusPlatform,
  type TaxRule,
  type Trade,
} from "@ctc/types";
import { type ReactNode } from "react";

import { type TimezoneOption } from "~/components/imports/TimezoneOverride";
import {
  type ConnectWalletConfigHandler,
  type ConnectWalletConfigKey,
} from "~/components/imports/wallet/types";
import { type CapitalGainsCategories } from "~/components/reconciliation/enums";
import { type FinancialYear } from "~/contexts/FYContext";
import { type GroupingCriteria } from "~/lib/enums";
import {
  type AssetType,
  type AuthMethodType,
  type BalanceError,
  type BalanceType,
  BulkOperations,
  type CardBrand,
  type CategoryBucket,
  type CommentsFilter,
  CustomExchanges,
  type DisplayMessage,
  type EnterpriseReportType,
  EntityType,
  ERPAccountType,
  ErpSyncStatus,
  type Features,
  FilterOperators,
  type FrenchReportType,
  type GermanReportType,
  type GroupedActionRatio,
  type GroupedTrade,
  type GroupRecategorisationType,
  ImportMethod,
  type ImportType,
  IntegrationCategory,
  type InvitationStatus,
  type InvitationType,
  type IrsReportDownloadType,
  type IrsReportType,
  type LockPeriodTxStatus,
  type ManualTransactionType,
  NormalReportType,
  type NotificationType,
  OtherReportType,
  type PaidBy,
  type PartyToAction,
  type PaywallReason,
  type PriceSourceType,
  type ReconciliationIssues,
  type Region,
  Sort,
  type SpanishReportType,
  type TaxActReportType,
  type TaxDivision,
  type TaxOutcomeType,
  type TradeDirection,
  type TurboTaxReportType,
  type UpdateReason,
  type UserFacingParseError,
  Warning,
  type WhoIsPaying,
} from "~/types/enums";
import { btcAltCurrencies, networks as btcAltNetworks } from "~/types/networks";
import { type PartialBy } from "~/types/utils";

/**
 * A string representation of a mongoDB ObjectId
 * @see https://docs.mongodb.com/manual/reference/method/ObjectId/
 */
export type ObjectIdString = string;

export type SignupDetails = {
  email: string;
  password: string;
  captcha: string | null;
  captchaProvider?: CaptchaProvider;
  paidPlan: string;
};

export type SignupDetailsAccountant = SignupDetails & {
  accountantCompanyName: string;
  accountantContactName: string;
  accountantPhoneNumber: string;
};

export type LoginDetails = {
  email: string;
  password: string;
  captcha: string | null;
  captchaProvider?: CaptchaProvider;
  connectProviderToken?: string;
};

export type TokenLoginDetails = {
  loginToken: string;
};

export type Code2FADetails = {
  code: string;
  staySignedIn?: boolean;
  connectProviderToken?: string;
};

export type ResetDetails = {
  password: string;
  token: string;
  invitation?: string;
};

export type VerifyEmailDetails = {
  emailVerificationToken: string;
};

export type ForgottenPasswordDetails = {
  email: string;
};

export type UpdatePasswordDetails = {
  oldPassword: string;
  newPassword: string;
  code?: string;
};

// this is what comes back from server
// TODO: what do we need to actually return?
export type KeyCredentials = {
  id: string;
  createdAt: string; // ISO datetime string
  apiKey: string;
  exchangeName: string;
  tickers?: string[];
  name?: string;
  isOauth?: boolean;
  isDeprecatedKey?: boolean;
  errorIssue?: SyncErrorIssue;
} & SyncState &
  Pick<SavedImportBase, "error" | "status" | "errorIssue">;

export function isImportMethod(value?: string | null): value is ImportMethod {
  return Object.values(ImportMethod).includes(value as ImportMethod);
}

export type ExchangeNames = Record<string, string>;

export function ctcCSVUploadOption(inputValue: string): ImportOptionV2 {
  return {
    id: inputValue.toLowerCase(),
    name: inputValue,
    manual: true,
    category: IntegrationCategory.Manual,
    importMethods: [
      { type: ImportMethod.CSV, etaMs: null, avgMs: null, p95Ms: null },
    ],
  };
}

export type UpdateDetails = {
  count?: number;
};

export type ExchangeTickers = string[];

export type KeyDetails = {
  id?: ObjectIdString;
  /** optional nickname */
  name?: string;
  /** the name of the exchange */
  exchangeName?: string;
  /** the api key */
  apiKey?: string;
  /** the secret */
  secret?: string;
  /** an optional identifier for the apiKey & secret */
  uid?: string;
  /** an optional indentifier for the apiKey & secret */
  password?: string;
  /** the tickers that the key is valid for */
  tickers?: ExchangeTickers;
  /** whether the key should be refreshed */
  refreshToken?: boolean;
  /** whether the key is valid for auth only */
  auth?: boolean;
  /** the timestamp to start the sync from */
  importFromTimestamp?: number;
  /** whether the key is deprecated */
  isDeprecatedKey?: boolean;
};
export type KeyUpdateDetails = {
  apiKey?: string;
  secret?: string;
  uid?: string;
  password?: string;
  tickers?: string[];
  name?: string;
};

export type TaxEvent = {
  buyUid: string | null;
  sellUid: string;
  currencyIdentifier: CurrencyIdentifier;
  buyTime: string;
  sellTime: string;
  quantity: number;
  cost: number;
  fee: number;
  proceeds: number;
  gain: number;
  isLongTerm: boolean;
  error?: TaxError;
  inTrade: Trade;
  outTrade: Trade;
};

export type IncomeEvent = {
  id: string;
  currencyIdentifier: CurrencyIdentifier;
  quantity: number;
  receivedTime: string;
  income: number;
  tradeType: Income;
  error?: IncomeError;
};

export type ExpenseEvent = {
  id: string;
  currencyIdentifier: CurrencyIdentifier;
  quantity: number;
  incurredTime: string;
  income: number;
  tradeType: Expense;
  error?: ExpenseError;
};

export type ReportData =
  | TaxEvent[]
  | IncomeEvent[]
  | ExpenseEvent[]
  | TransactionExtendedDetails[]
  | InventoryReportRow[]
  | TradingStockAuRow[]
  | YearSummaryByCurrency[];

interface IncomeError {
  id: string;
  message: IncomeErrorMessage;
}

enum IncomeErrorMessage {
  Unknown = "unknown",
  ZeroCostBuy = "zcb",
}

interface ExpenseError {
  id: string;
  message: ExpenseErrorMessage;
}

enum ExpenseErrorMessage {
  Unknown = "unknown",
}

interface TaxError {
  id: string;
  message: TaxErrorMessage;
}

enum TaxErrorMessage {
  SoldOut = "soldOut", // not enough assets in bank
  Uncategorized = "uncategorized", // uncategorized
}

export const dateSorts: Sort[] = [Sort.DateAscending, Sort.DateDescending];

export const sorts: Sort[] = Object.values(Sort);

// start reports

type ReportSummarySectionData<T extends string> = {
  amount: number;
  categories: Partial<Record<T, SummarySectionData | EmptySummarySectionData>>;
};

export type SummarySectionData = {
  category?: CapitalGainsCategories;
  reportType?: NormalReportType;
  amount: number;
  tradeTypes: {
    type: ActionType;
    count: number;
    amount: number;
  }[];
};

export type EmptySummarySectionData = PartialBy<SummarySectionData, "amount">;

// used by report summary on left hand side
export type ReportSummary = {
  totalProfit: number; // this is net capital gains + net income - net expenses
  absoluteTotalGains: number; // this takes into account the capitalGains (including losses) + income
  capitalGains: {
    totalGains: number; // this is shortTermGains + longTermGains
    shortTermGains: number;
    longTermGains: number;
    shortTermLosses: number;
    longTermLosses: number;
    totalLosses: number; // this is shortTermLosses + longTermLosses
    numberDisposals: number;
    disposalProceeds: number;
    allowableCost: number;
  };
  incomeEarnt: IncomeEarnt;
  totalOrdinaryIncome: number;
  totalDerivativeIncome: number;
  openingValue: number; // Used for netherlands report.
  expenses: Expenses;
  zeroCostBuys: PayloadZeroCostBasis;
  summarySections: {
    [SummarySectionHeaders.TotalCapitalGains]: ReportSummarySectionData<CapitalGainsCategories>;
    [SummarySectionHeaders.TotalIncome]: ReportSummarySectionData<IncomeReportType>;
    [SummarySectionHeaders.TotalExpenses]: ReportSummarySectionData<NormalReportType.Expense>;
  };
};

export type SummarySectionTypeData = PartialBy<
  ReportSummary["summarySections"][keyof ReportSummary["summarySections"]],
  "amount"
>;

enum SummarySectionHeaders {
  TotalCapitalGains = "totalCapitalGains",
  TotalIncome = "totalIncome",
  TotalExpenses = "totalExpenses",
}

// used by report summary
export type YearSummaryByCurrency = {
  startBalance: number;
  eoyBalance: number;
  currencyIdentifier: CurrencyIdentifier;
  quantity: number;
  fee: number;
  cost: number;
  proceeds: number;
  capitalGain: number;
  overallGain: number;
  income: number;
};

// used by pie chart
export type EoyPortfolio = {
  currencyIdentifier: CurrencyIdentifier;
  value: number;
  quantity: number;
};

type IncomeEarnt = Record<Income, number>;
type Expenses = Record<Expense, number>;

export type InventoryReportRow = {
  currencyIdentifier: CurrencyIdentifier;
  startBalance: number;
  startCostBasis: number;
  startValue: number;
  endBalance: number;
  endCostBasis: number;
  endValue: number;
  quantity: number;
};

export const IncomeReports = [
  NormalReportType.Income,
  NormalReportType.TradingPnl,
] as const;

export type IncomeReportType = (typeof IncomeReports)[number];

export const AdvancedReports: (NormalReportType | OtherReportType.BGL)[] = [
  NormalReportType.Expense,
  NormalReportType.TradingStockAu,
  NormalReportType.Inventory,
  NormalReportType.Audit,
  NormalReportType.TradingPnl,
  NormalReportType.GiftAndLost,
  OtherReportType.BGL,
];

export type ReportType =
  | IrsReportType
  | NormalReportType
  | TaxActReportType
  | TurboTaxReportType
  | GermanReportType
  | EnterpriseReportType
  | OtherReportType
  | FrenchReportType
  | SpanishReportType;

export type ReportDownloadType =
  | NormalReportType
  | TaxActReportType
  | TurboTaxReportType
  | IrsReportDownloadType
  | GermanReportType
  | OtherReportType.BGL
  | FrenchReportType
  | SpanishReportType
  | OtherReportType.TaxLots;

export type TimeSeriesData = {
  x: number; // unix timestamp (ms)
  y: number; // value
};

export type PriceData = {
  x: Date;
  y: number;
};

export type PortfolioChangeInfo = {
  loading: boolean;
  error: boolean;
  priceData: PriceData[] | undefined;
};
export type InflowOutflowData = {
  slice: number; // timestamp
  inflow: number;
  outflow: number;
  fees: number;
};

export type OrganisationDetails = {
  _id: string;
  name: string;
  clientCount: number;
  collaboratorCount: number;
  teams: OrganisationTeam[];
};

export type OrganisationUpcomingInvoice = {
  month: number;
  year: number;
  amount: string;
};

export type OrganisationTeam = {
  name: string;
  region: string;
  owner: string;
  clientCount: number;
  collaboratorCount: number;
  invitation: {
    status: InvitationStatus;
  };
  createdAt: string;
  billing?: number;
};

export type BreakdownByExchange = ReportedBreakdownByExchange & {
  exchangeId: string;
  balance: number;
  cost: number;
};

type ReportedBreakdownByExchange = {
  reportedBalance?: number | null;
  reportedCost?: number | null;
  reportedMarketPrice?: number | null;
  reportedMarketValue?: number | null;
};

export type CurrencyHoldings = {
  positionSummary: {
    totalValue: number;
    totalCost: number;
    unrealizedGain: number;
  };
  currencyHoldings: PricedCurrencyHoldings[];
};

export type PricedCurrencyHoldings = {
  currencyIdentifier: CurrencyIdentifier;
  holdings: (PricedHoldingsDetails & { exchangeId: string })[];
  summary: CurrencyHoldingsSummary & PricedCurrencyHoldingsSummary;
};

type PricedCurrencyHoldingsSummary = {
  marketPrice: number | null;
  marketValue: number | null;
  unrealizedGain: number | null;
  roi: number | null;
  canHaveValue: boolean;
};

export type PricedHoldingsDetails = HoldingsBalance & {
  marketPrice: number;
  totalValue: number;
};

export type CurrencyHoldingsSummary = {
  totalBalance: number;
  balanceError: BalanceError | null;
  cost: number;
};

export type HoldingsBalance = {
  calculated: number;
  reported: number | null;
  balance: number;
  balanceType: BalanceType;
  balanceError: BalanceError | null;
  cost: number;
};

type Income =
  | Trade.StakingReward
  | Trade.Airdrop
  | Trade.Interest
  | Trade.Income
  | Trade.RealisedProfit
  | Trade.RealisedLoss
  | Trade.MarginFee
  | Trade.Mining
  | Trade.Royalty;

// Duplicated from the same Expense type in backend
// The typescript way to stay DRY with both a const array and a union type is to
// Define the array first, then derive the union type from the array.
type Expense = Trade;
export interface CountryType {
  code: Country;
  label: string;
  isoString?: string;
  region: Region;
}

export const BusinessPaidPlans = [
  Plan.BusinessStarter,
  Plan.BusinessPro,
  Plan.BusinessTrial,
] as const;

export type BusinessPaidPlanType = (typeof BusinessPaidPlans)[number];

export function isBusinessPaidPlanType(
  planType: string,
): planType is BusinessPaidPlanType {
  return BusinessPaidPlans.includes(planType as BusinessPaidPlanType);
}

export const BusinessPlan = [
  Plan.Business,
  Plan.BusinessStarter,
  Plan.BusinessPro,
  Plan.BusinessPackage,
  Plan.BusinessTrial,
] as const;

export type BusinessPlanType = (typeof BusinessPlan)[number];

export function isBusinessPlanType(
  planType: string,
): planType is BusinessPlanType {
  return BusinessPlan.includes(planType as BusinessPlanType);
}

export const PersonalPaidPlans = [
  Plan.Rookie,
  Plan.Hobbyist,
  Plan.Investor,
  Plan.Trader,
];
export type PersonalPlanType = (typeof PersonalPaidPlans)[number];

export type CheckoutSession = {
  url: string;
  id: string;
};

export type ActionType = Trade | GroupedTrade;

export type ManualTransactionFormValues = {
  // for confirming the form options
  txnCategory: ManualTransactionType | "";
  // actual form values of use
  timestamp: string;
  trade: Trade | "";
  baseCurrency: CurrencyIdentifier | string;
  baseQuantity: string;
  quoteCurrency: CurrencyIdentifier | string;
  quoteQuantity: string;
  feeCurrency: CurrencyIdentifier | string;
  feeQuantity: string;
  from: ExchangeOption;
  to: ExchangeOption;
  exchangeName: ExchangeOption;
  // for the referencePrice
  pricePerUnit: string; // always in localCurrency
  totalValue: string; // always in localCurrency
};

export type ActionPreviousVersion = {
  // stores if this is the first version the transactions
  original: boolean;
  updatedAt?: string | undefined;
  createdAt?: string | undefined;
  importType?: ImportType | undefined;
  updateReason?: UpdateReason | undefined;
  transactions: TransactionDetails[];
};

export type ManualTransactionEntry = ManualTransactionFormValues & {
  baseCurrencyIdentifier: CurrencyIdentifier | undefined;
  quoteCurrency: string | undefined;
  quoteCurrencyIdentifier: CurrencyIdentifier | undefined;
  feeCurrencyIdentifier: CurrencyIdentifier | undefined;
  priceCurrency: LocalCurrency | undefined;
};

export type SyncDetails = Record<string, SyncStatusPlatform>;

export type EnabledReconciliationIssues = Exclude<
  ReconciliationIssues,
  | ReconciliationIssues.UnknownAddress
  | ReconciliationIssues.SuspectedMissingImport
>;

export type ReconciliationMetadata = {
  hasGeneratedAnyReconciliationIssues: boolean;
};

type SuspectedTradeTransactionIssue = {
  value: number;
  transactionCount: number;
  ids: string[];
  actionIds: string[];
  currency: CurrencyIdentifier;
};

export type SuggestionTrades = Trade.Airdrop | Trade.Burn;

type UncategorisedTransactionIssue = {
  transactionCount: number;
  currencies: CurrencyIdentifier[];
};

export type NegativeBalanceIssue = {
  amountNegative: number;
  currency: CurrencyIdentifier;
  currencyId: string;
  taxImpact: number;
  transactionCount: number;
};

export type MissingPriceIssue = {
  currency: CurrencyIdentifier;
  currencyId: string;
  transactionCount: number;
  gains: number;
};

export type MissingBlockchainIssue = {
  transactionSource: string;
  transactionCount: number;
  transferBalance: number;
  currencies: CurrencyIdentifier[];
};

export type UnknownAddressIssue = {
  address: string;
  transactionCount: number;
  transferAmount: number;
  currencies: CurrencyIdentifier[];
  chains: Blockchain[];
  firstTxDate: Date;
  lastTxDate: Date;
};

export type UnmatchedTransferIssue = {
  counterparty: {
    address: string;
    blockchain?: Blockchain;
  };
  transactionCount: number;
  currencies: CurrencyIdentifier[];
  transferVolume: number;
};

export type SuspectedMissingImportIssue = {
  exchange: string;
  transactionCount: number;
  transferAmount: number;
  currencies: CurrencyIdentifier[];
  firstTxDate: Date;
  lastTxDate: Date;
  /** the _id of the entity, an array because it can be the global or extension
   * included so that we can filter by these on the FE
   */
  accounts: string[];
};

export type TradeSuggestionIssue = SuspectedTradeTransactionIssue;

export type IssueTypeToIssuePayload<T extends ReconciliationIssues> = {
  [ReconciliationIssues.MissingBlockchain]: MissingBlockchainIssue;
  [ReconciliationIssues.NegativeBalances]: NegativeBalanceIssue;
  [ReconciliationIssues.MissingPrices]: MissingPriceIssue;
  [ReconciliationIssues.UncategorisedTransactions]: UncategorisedTransactionIssue;
  [ReconciliationIssues.UnknownAddress]: UnknownAddressIssue;
  [ReconciliationIssues.UnmatchedTransfer]: UnmatchedTransferIssue;
  [ReconciliationIssues.SuspectedMissingImport]: SuspectedMissingImportIssue;
}[T];

export type ReconciliationIssuesPayload<T extends ReconciliationIssues> = {
  issueCount: number;
  cursor?: string;
  issues: IssueTypeToIssuePayload<T>[];
};

/**
 * Note: used in the models for the syncing (wallets, key, auth)
 */
export type SyncState = {
  syncStatus?: SyncStatusPlatform; // if this is not set it is a legacy
  syncDisplayError?: {
    status?: number;
    message?: string;
  };
  lastSyncComplete?: Date; // ISO datetime string - what is the date that the last sync was completed? - regardless of completion status (e.g. can be fail or success)
  lastImportedTxTimestamp: Date | null;
  importFromDate?: Date; // ISO datetime string - import data from this date
};

export type WalletDetails = {
  _id: string;
  uid: string;
  type: Blockchain;
  address: string;
  name?: string | null;
  importFromTimestamp: number | undefined;
} & SyncState;

export function isBlockchain(value: string): value is Blockchain {
  return Object.values(Blockchain).includes(value as Blockchain);
}

/**
 * The base (native) currencies for the underlying blockchain
 */
export const DefaultBlockchainCurrency: Record<Blockchain, string> = {
  [Blockchain.Blast]: "ETH",
  [Blockchain.Hyperliquid]: "HYPE",
  [Blockchain.ZetaChain]: "ZETA",
  [Blockchain.Avalanche]: "AVAX",
  [Blockchain.ArbitrumNova]: "ETH",
  [Blockchain.Base]: "BASE",
  [Blockchain.Linea]: "ETH",
  [Blockchain.Mantle]: "MNT",
  [Blockchain.MantaPacific]: "ETH",
  [Blockchain.EthereumClassic]: "ETC",
  [Blockchain.Immutable]: "IMX",
  [Blockchain.Scroll]: "ETH",
  [Blockchain.Cronos]: "CRO",
  [Blockchain.Fantom]: "FTM",
  [Blockchain.Vechain]: "VTHO",
  [Blockchain.Tron]: "TRX",
  [Blockchain.ETH]: "ETH",
  [Blockchain.OPT]: "ETH",
  [Blockchain.ARB]: "ETH",
  [Blockchain.BSC]: "BNB",
  [Blockchain.BinanceChain]: "BNB",
  [Blockchain.BTC]: "BTC",
  [Blockchain.BCH]: "BCH",
  [Blockchain.LTC]: "LTC",
  [Blockchain.BSV]: "BSV",
  [Blockchain.DOGE]: "DOGE",
  [Blockchain.DASH]: "DASH",
  [Blockchain.GRS]: "GRS",
  [Blockchain.ZEC]: "ZEC",
  [Blockchain.Stellar]: "XLM",
  [Blockchain.Ripple]: "XRP",
  [Blockchain.Cardano]: "ADA",
  [Blockchain.Theta]: "THETA",
  [Blockchain.Neo]: "NEO",
  [Blockchain.Xdai]: "XDAI",
  [Blockchain.Polygon]: "MATIC",
  [Blockchain.PulseChain]: "PLS",
  [Blockchain.Solana]: "SOL",
  [Blockchain.SolanaV2]: "SOL",
  [Blockchain.TerraClassic]: "LUNC",
  [Blockchain.Terra2]: "LUNA",
  [Blockchain.Osmosis]: "OSMO",
  [Blockchain.Moonbeam]: "GLMR",
  [Blockchain.Moonriver]: "MOVR",
  [Blockchain.Aurora]: "ETH",
  [Blockchain.Boba]: "ETH",
  [Blockchain.Celo]: "CELO",
  [Blockchain.BitTorrentChain]: "BTT",
  [Blockchain.CLVChain]: "CLV",
  [Blockchain.Metis]: "METIS",
  [Blockchain.Canto]: "CANTO",
  [Blockchain.ZkSync]: "ETH",
  [Blockchain.Kava]: "KAVA",
  [Blockchain.Juno]: "JUNO",
  [Blockchain.CosmosHub]: "ATOM",
  [Blockchain.Algorand]: "ALGO",
  [Blockchain.FetchAi]: "FET",
  [Blockchain.BitSong]: "BTSG",
  [Blockchain.Sentinel]: "DVPN",
  [Blockchain.Chihuahua]: "HUAHUA",
  [Blockchain.Agoric]: "BLD",
  [Blockchain.Evmos]: "EVMOS",
  [Blockchain.Kujira]: "KUJI",
  [Blockchain.Stargaze]: "STARS",
  [Blockchain.Iotex]: "IOTX",
  [Blockchain.Celestia]: "TIA",
  [Blockchain.Dymension]: "DYM",
  [Blockchain.Injective]: "INJ",
  [Blockchain.Zora]: "ETH",
  [Blockchain.PolygonZkEvm]: "ETH",
  [Blockchain.Dydx4]: "USDC",
  [Blockchain.Flare]: "FLR",
  [Blockchain.Velas]: "VLX",
  [Blockchain.Near]: "NEAR",
  [Blockchain.Stacks]: "STX",
  [Blockchain.TON]: "TON",
  [Blockchain.Taiko]: "ETH",
  [Blockchain.Akash]: "AKT",
  [Blockchain.Archway]: "ARCH",
  [Blockchain.Kyve]: "KYVE",
  [Blockchain.Nolus]: "NOLUS",
  [Blockchain.Neutron]: "NTRN",
  [Blockchain.Regen]: "REGEN",
  [Blockchain.Saga]: "SAGA",
  [Blockchain.Sui]: "SUI",
  [Blockchain.Mode]: "ETH",
  [Blockchain.Sonic]: "S",
  // Polkadot ecosystem
  [Blockchain.Polkadot]: "DOT",
  [Blockchain.Acala]: "ACA",
  [Blockchain.Ajuna]: "AJUN",
  [Blockchain.PolkadotAstar]: "ASTR",
  [Blockchain.Aventus]: "AVT",
  [Blockchain.BifrostDot]: "BNC",
  [Blockchain.Bitgreen]: "BBB",
  [Blockchain.Centrifuge]: "CFG",
  [Blockchain.PolkadotClover]: "CLV",
  [Blockchain.Collectives]: "DOT",
  [Blockchain.Composable]: "LAYR",
  [Blockchain.Crust]: "CRU",
  [Blockchain.Darwinia]: "RING",
  [Blockchain.Equilibrium]: "EQ",
  [Blockchain.Frequency]: "FRQCY",
  [Blockchain.Hashed]: "HASH",
  [Blockchain.Hydradx]: "HDX",
  [Blockchain.IntegriteeShell]: "TEER",
  [Blockchain.Interlay]: "INTR",
  [Blockchain.Kapex]: "KPX",
  [Blockchain.Kilt]: "KILT",
  [Blockchain.Kylin]: "KYL",
  [Blockchain.Litentry]: "LIT",
  [Blockchain.Manta]: "MANTA",
  [Blockchain.Nodle]: "NODL",
  [Blockchain.Origintrail]: "OTP",
  [Blockchain.Parallel]: "PARA",
  [Blockchain.Pendulum]: "PEN",
  [Blockchain.Phala]: "PHA",
  [Blockchain.Statemint]: "DOT",
  [Blockchain.T3rn]: "TRN",
  [Blockchain.Unique]: "UNQ",

  // Kusama ecosystem
  [Blockchain.Kusama]: "KSM",
  [Blockchain.Altair]: "AIR",
  [Blockchain.Amplitude]: "AMPE",
  [Blockchain.Bajun]: "BAJU",
  [Blockchain.Basilisk]: "BSX",
  [Blockchain.BifrostKsm]: "BNC",
  [Blockchain.Bitcountrypioneer]: "NEER",
  [Blockchain.Bridgehub]: "KSM",
  [Blockchain.Calamari]: "KMA",
  [Blockchain.Crab]: "RING",
  [Blockchain.Daoipci]: "MITO",
  [Blockchain.Encointer]: "NODL",
  [Blockchain.Genshiro]: "GENS",
  [Blockchain.Gm]: "FREN",
  [Blockchain.Imbue]: "IMBU",
  [Blockchain.Integritee]: "TEER",
  [Blockchain.Kabocha]: "KAB",
  [Blockchain.Karura]: "KAR",
  [Blockchain.Khala]: "PHA",
  [Blockchain.Kintsugi]: "KINT",
  [Blockchain.Krest]: "KREST",
  [Blockchain.Litmus]: "LIT",
  [Blockchain.Mangatax]: "MGX",
  [Blockchain.ParallelHeiko]: "HKO",
  [Blockchain.Picasso]: "PICA",
  [Blockchain.Quartz]: "QTZ",
  [Blockchain.Robonomics]: "XRT",
  [Blockchain.Shadow]: "CSM",
  [Blockchain.KusamaShiden]: "SDN",
  [Blockchain.Sora]: "XOR",
  [Blockchain.Statemine]: "KSM",
  [Blockchain.Subsocialx]: "SUB",
  [Blockchain.Subzero]: "ZERO",
  [Blockchain.Tinkernet]: "TNKR",
  [Blockchain.Turing]: "TUR",
  [Blockchain.Zeitgeist]: "ZTG",

  // Shibuya ecosystem
  [Blockchain.Shibuya]: "SBY",

  // Thorchain ecosystem
  [Blockchain.Thorchain]: "RUNE",

  [Blockchain.Starknet]: "STRK",
  // Hedera ecosystem
  [Blockchain.Hedera]: "HBAR",

  [Blockchain.Berachain]: "BERA",

  [Blockchain.Unichain]: "UNI",
  [Blockchain.Aptos]: "APT",

  [Blockchain.Kaspa]: "KAS",
  [Blockchain.Abstract]: "ETH",

  [Blockchain.Sei]: "SEI",

  ...btcAltCurrencies,
};

export const BlockchainName: Record<Blockchain, string> = {
  [Blockchain.Blast]: "Blast",
  [Blockchain.ZetaChain]: "ZetaChain",
  [Blockchain.Hyperliquid]: "Hyperliquid",
  [Blockchain.Avalanche]: "Avalanche",
  [Blockchain.ArbitrumNova]: "Arbitrum Nova",
  [Blockchain.Base]: "Base",
  [Blockchain.Berachain]: "Berachain",
  [Blockchain.Cronos]: "Cronos",
  [Blockchain.Fantom]: "Fantom",
  [Blockchain.Vechain]: "Vechain",
  [Blockchain.Tron]: "Tron",
  [Blockchain.Unichain]: "Unichain",
  [Blockchain.Neo]: "Neo",
  [Blockchain.BSC]: "Binance Smart Chain",
  [Blockchain.BinanceChain]: "Binance Chain",
  [Blockchain.OPT]: "Optimism",
  [Blockchain.Linea]: "Linea",
  [Blockchain.Mantle]: "Mantle",
  [Blockchain.PolygonZkEvm]: "Polygon zkEVM",
  [Blockchain.MantaPacific]: "Manta Pacific (EVM)",
  [Blockchain.EthereumClassic]: "Ethereum Classic",
  [Blockchain.Immutable]: "Immutable X",
  [Blockchain.ARB]: "Arbitrum",
  [Blockchain.ETH]: "Ethereum",
  [Blockchain.BTC]: "Bitcoin",
  [Blockchain.BCH]: "Bitcoin Cash",
  [Blockchain.LTC]: "Litecoin",
  [Blockchain.BSV]: "Bitcoin SV",
  [Blockchain.DOGE]: "Dogecoin",
  [Blockchain.DASH]: "Dash",
  [Blockchain.GRS]: "Groestlcoin",
  [Blockchain.ZEC]: "Zcash",
  [Blockchain.Stellar]: "Stellar",
  [Blockchain.Cardano]: "Cardano",
  [Blockchain.Theta]: "Theta",
  [Blockchain.Ripple]: "XRP",
  [Blockchain.Xdai]: "Gnosis",
  [Blockchain.Polygon]: "Polygon (Matic)",
  [Blockchain.PulseChain]: "PulseChain",
  [Blockchain.Solana]: "Solana - Deprecated",
  [Blockchain.SolanaV2]: "Solana",
  [Blockchain.TerraClassic]: "Terra Classic",
  [Blockchain.Terra2]: "Terra 2.0",
  [Blockchain.Osmosis]: "Osmosis",
  [Blockchain.Moonbeam]: "Moonbeam",
  [Blockchain.Moonriver]: "Moonriver",
  [Blockchain.Aurora]: "Aurora",
  [Blockchain.Boba]: "Boba",
  [Blockchain.Celo]: "Celo",
  [Blockchain.BitTorrentChain]: "BitTorrent Chain",
  [Blockchain.CLVChain]: "CLV Chain (P-Chain)",
  [Blockchain.Metis]: "Metis",
  [Blockchain.Canto]: "Canto",
  [Blockchain.ZkSync]: "zkSync",
  [Blockchain.Kava]: "Kava (EVM)",
  [Blockchain.Juno]: "Juno",
  [Blockchain.CosmosHub]: "Cosmos Hub",
  [Blockchain.Algorand]: "Algorand",
  [Blockchain.FetchAi]: "Fetch.ai",
  [Blockchain.BitSong]: "BitSong",
  [Blockchain.Sentinel]: "Sentinel",
  [Blockchain.Chihuahua]: "Chihuahua",
  [Blockchain.Agoric]: "Agoric",
  [Blockchain.Evmos]: "Evmos",
  [Blockchain.Kujira]: "Kujira",
  [Blockchain.Stargaze]: "Stargaze",
  [Blockchain.Iotex]: "IoTeX",
  [Blockchain.Celestia]: "Celestia",
  [Blockchain.Dymension]: "Dymension",
  [Blockchain.Injective]: "Injective",
  [Blockchain.Zora]: "Zora",
  [Blockchain.Dydx4]: "DYDX4",
  [Blockchain.Flare]: "Flare",
  [Blockchain.Velas]: "Velas",
  [Blockchain.Scroll]: "Scroll",
  [Blockchain.Near]: "Near",
  [Blockchain.Stacks]: "Stacks",
  [Blockchain.TON]: "TON",
  [Blockchain.Taiko]: "Taiko",
  [Blockchain.Akash]: "Akash",
  [Blockchain.Archway]: "Archway",
  [Blockchain.Kyve]: "Kyve",
  [Blockchain.Nolus]: "Nolus",
  [Blockchain.Neutron]: "Neutron",
  [Blockchain.Regen]: "Regen",
  [Blockchain.Saga]: "Saga",
  [Blockchain.Sui]: "Sui",
  [Blockchain.Mode]: "Mode",
  [Blockchain.Sonic]: "Sonic",
  // Polkadot ecosystem
  [Blockchain.Polkadot]: "Polkadot (Relay Chain)",
  [Blockchain.Acala]: "Acala",
  [Blockchain.Ajuna]: "Ajuna",
  [Blockchain.PolkadotAstar]: "Astar",
  [Blockchain.Aventus]: "Aventus",
  [Blockchain.BifrostDot]: "Bifrost (Polkadot)",
  [Blockchain.Bitgreen]: "Bitgreen",
  [Blockchain.Centrifuge]: "Centrifuge",
  [Blockchain.PolkadotClover]: "Clover",
  [Blockchain.Collectives]: "Collectives",
  [Blockchain.Composable]: "Composable Finance",
  [Blockchain.Crust]: "Crust",
  [Blockchain.Darwinia]: "Darwinia",
  [Blockchain.Equilibrium]: "Equilibrium",
  [Blockchain.Frequency]: "Frequency",
  [Blockchain.Hashed]: "Hashed Network",
  [Blockchain.Hydradx]: "HydraDX",
  [Blockchain.IntegriteeShell]: "Integritee Shell",
  [Blockchain.Interlay]: "Interlay",
  [Blockchain.Kapex]: "Kapex",
  [Blockchain.Kilt]: "Kilt Spiritnet",
  [Blockchain.Kylin]: "Kylin",
  [Blockchain.Litentry]: "Litentry",
  [Blockchain.Manta]: "Manta",
  [Blockchain.Nodle]: "Nodle",
  [Blockchain.Origintrail]: "Origin Trail",
  [Blockchain.Parallel]: "Parallel",
  [Blockchain.Pendulum]: "Pendulum",
  [Blockchain.Phala]: "Phala",
  [Blockchain.Statemint]: "AssetHub (Polkadot)",
  [Blockchain.T3rn]: "T3rn",
  [Blockchain.Unique]: "Unique",

  // Kusama ecosystem
  [Blockchain.Kusama]: "Kusama",
  [Blockchain.Altair]: "Altair",
  [Blockchain.Amplitude]: "Amplitude",
  [Blockchain.Bajun]: "Bajun Network",
  [Blockchain.Basilisk]: "Basilisk",
  [Blockchain.BifrostKsm]: "Bifrost (Kusama)",
  [Blockchain.Bitcountrypioneer]: "Bit.country Pioneer",
  [Blockchain.Bridgehub]: "Bridgehub",
  [Blockchain.Calamari]: "Calamari",
  [Blockchain.Crab]: "Darwinia Crab",
  [Blockchain.Daoipci]: "DAO IPCI",
  [Blockchain.Encointer]: "Encointer",
  [Blockchain.Genshiro]: "Genshiro",
  [Blockchain.Gm]: "GM Parachain",
  [Blockchain.Imbue]: "Imbue Network",
  [Blockchain.Integritee]: "Integritee",
  [Blockchain.Kabocha]: "Kabocha",
  [Blockchain.Karura]: "Karura",
  [Blockchain.Khala]: "Khala",
  [Blockchain.Kintsugi]: "Kintsugi",
  [Blockchain.Krest]: "Krest",
  [Blockchain.Litmus]: "Litmus",
  [Blockchain.Mangatax]: "Mangatax",
  [Blockchain.ParallelHeiko]: "Parallel Heiko",
  [Blockchain.Picasso]: "Picasso",
  [Blockchain.Quartz]: "Quartz",
  [Blockchain.Robonomics]: "Robonomics",
  [Blockchain.Shadow]: "Crust Shadow",
  [Blockchain.KusamaShiden]: "Shiden",
  [Blockchain.Sora]: "Sora",
  [Blockchain.Statemine]: "AssetHub (Kusama)",
  [Blockchain.Subsocialx]: "Subsocialx",
  [Blockchain.Subzero]: "Subzero",
  [Blockchain.Tinkernet]: "InvArch Tinkernet",
  [Blockchain.Turing]: "Turing",
  [Blockchain.Zeitgeist]: "Zeitgeist",

  // Shibuya ecosystem
  [Blockchain.Shibuya]: "Shibuya",

  // Thorchain ecosystem
  [Blockchain.Thorchain]: "THORChain",

  [Blockchain.Starknet]: "Starknet",
  // Hedera ecosystem
  [Blockchain.Hedera]: "Hedera",

  // Aptos
  [Blockchain.Aptos]: "Aptos",
  [Blockchain.Kaspa]: "Kaspa",
  [Blockchain.Abstract]: "Abstract",

  [Blockchain.Sei]: "Sei",

  ...Object.fromEntries(
    Object.entries(btcAltNetworks).map(([key, { name }]) => [key, name]),
  ),
};

export const EvmBlockchains = [
  Blockchain.ETH,
  Blockchain.OPT,
  Blockchain.ARB,
  Blockchain.BSC,
  Blockchain.Xdai,
  Blockchain.Polygon,
  Blockchain.PulseChain,
  Blockchain.Fantom,
  Blockchain.Avalanche,
  Blockchain.ArbitrumNova,
  Blockchain.Base,
  Blockchain.Cronos,
  Blockchain.Moonbeam,
  Blockchain.Moonriver,
  Blockchain.Aurora,
  Blockchain.Boba,
  Blockchain.Celo,
  Blockchain.BitTorrentChain,
  Blockchain.CLVChain,
  Blockchain.Metis,
  Blockchain.Canto,
  Blockchain.ZkSync,
  Blockchain.Kava,
  Blockchain.Linea,
  Blockchain.Zora,
  Blockchain.Mantle,
  Blockchain.PolygonZkEvm,
  Blockchain.MantaPacific,
  Blockchain.EthereumClassic,
  Blockchain.Immutable,
  Blockchain.Flare,
  Blockchain.Velas,
  Blockchain.Scroll,
  Blockchain.ZetaChain,
  Blockchain.Blast,
  Blockchain.Taiko,
  Blockchain.Mode,
  Blockchain.Sonic,
  Blockchain.Berachain,
  Blockchain.Unichain,
  Blockchain.Abstract,
] as const;

export function isEVMBlockchain(
  blockchain: string,
): blockchain is EVMBlockchain {
  return EvmBlockchains.includes(blockchain as any);
}

export type EVMBlockchain = (typeof EvmBlockchains)[number];

// Keep inline with BE at `src/services/integrations-blockchain/defi/types.ts`
export const HighSpamProbabilityEVMs: EVMBlockchain[] = [
  Blockchain.Polygon,
  Blockchain.PulseChain,
];

const mintscanBlockchains = [
  Blockchain.Juno,
  Blockchain.Osmosis,
  Blockchain.CosmosHub,
  Blockchain.FetchAi,
  Blockchain.BitSong,
  Blockchain.Sentinel,
  Blockchain.Stargaze,
  Blockchain.Celestia,
  Blockchain.Dymension,
  Blockchain.Injective,
  Blockchain.Evmos,
  Blockchain.Chihuahua,
  Blockchain.Kujira,
  Blockchain.Akash,
  Blockchain.Archway,
  Blockchain.Kyve,
  Blockchain.Neutron,
  Blockchain.Regen,
  Blockchain.Saga,
  Blockchain.Sei,
] as const;

export type MintscanBlockchain = (typeof mintscanBlockchains)[number];

export function isMintscanBlockchain(
  blockchain?: string,
): blockchain is MintscanBlockchain {
  return mintscanBlockchains.includes(blockchain as any);
}

export const xpubBlockchains = [
  Blockchain.BTC,
  Blockchain.BCH,
  Blockchain.BSV,
  Blockchain.DOGE,
  Blockchain.DASH,
  Blockchain.GRS,
  Blockchain.LTC,
];

export const blockchainsWithoutAddressValidation = [
  Blockchain.BCH,
  Blockchain.BSV,
  Blockchain.DASH,
  Blockchain.GRS,
  Blockchain.Stellar,
  Blockchain.Algorand,
  Blockchain.Near,
  Blockchain.Stacks,
];

export const polkadotCompatibleBlockchain = [
  Blockchain.Polkadot,
  Blockchain.Acala,
  Blockchain.Ajuna,
  Blockchain.PolkadotAstar,
  Blockchain.Aventus,
  Blockchain.BifrostDot,
  Blockchain.Bitgreen,
  Blockchain.Centrifuge,
  Blockchain.PolkadotClover,
  Blockchain.Collectives,
  Blockchain.Composable,
  Blockchain.Crust,
  Blockchain.Darwinia,
  Blockchain.Equilibrium,
  Blockchain.Frequency,
  Blockchain.Hashed,
  Blockchain.Hydradx,
  Blockchain.IntegriteeShell,
  Blockchain.Interlay,
  Blockchain.Kapex,
  Blockchain.Kilt,
  Blockchain.Kylin,
  Blockchain.Litentry,
  Blockchain.Manta,
  Blockchain.Nodle,
  Blockchain.Origintrail,
  Blockchain.Parallel,
  Blockchain.Pendulum,
  Blockchain.Phala,
  Blockchain.Statemint,
  Blockchain.T3rn,
  Blockchain.Unique,
  Blockchain.Kusama,
  Blockchain.Altair,
  Blockchain.Amplitude,
  Blockchain.Bajun,
  Blockchain.Basilisk,
  Blockchain.BifrostKsm,
  Blockchain.Bitcountrypioneer,
  Blockchain.Bridgehub,
  Blockchain.Calamari,
  Blockchain.Crab,
  Blockchain.Daoipci,
  Blockchain.Encointer,
  Blockchain.Genshiro,
  Blockchain.Gm,
  Blockchain.Imbue,
  Blockchain.Integritee,
  Blockchain.Kabocha,
  Blockchain.Karura,
  Blockchain.Khala,
  Blockchain.Kintsugi,
  Blockchain.Krest,
  Blockchain.Litmus,
  Blockchain.Mangatax,
  Blockchain.ParallelHeiko,
  Blockchain.Picasso,
  Blockchain.Quartz,
  Blockchain.Robonomics,
  Blockchain.Shadow,
  Blockchain.KusamaShiden,
  Blockchain.Sora,
  Blockchain.Statemine,
  Blockchain.Subsocialx,
  Blockchain.Subzero,
  Blockchain.Tinkernet,
  Blockchain.Turing,
  Blockchain.Zeitgeist,
  Blockchain.Shibuya,
] as const;

export type PolkadotCompatibleChain =
  (typeof polkadotCompatibleBlockchain)[number];

export const PolkadotChainsConfig: Record<
  PolkadotCompatibleChain,
  { prefix: number | null } & (
    | { explorer: "subscan"; explorerURL: string }
    | { explorer: "polkaholic" }
  )
> = {
  [Blockchain.Polkadot]: {
    prefix: 0,
    explorer: "subscan",
    explorerURL: "https://polkadot.subscan.io",
  },
  [Blockchain.Acala]: {
    prefix: 10,
    explorer: "subscan",
    explorerURL: "https://acala.subscan.io",
  },
  [Blockchain.Ajuna]: {
    prefix: 1328,
    explorer: "polkaholic",
  },
  [Blockchain.PolkadotAstar]: {
    prefix: 5,
    explorer: "subscan",
    explorerURL: "https://astar.subscan.io",
  },
  [Blockchain.Aventus]: {
    prefix: 42,
    explorer: "polkaholic",
  },
  [Blockchain.BifrostDot]: {
    prefix: 6,
    explorer: "subscan",
    explorerURL: "https://bifrost.subscan.io",
  },
  [Blockchain.Bitgreen]: {
    prefix: 2106,
    explorer: "polkaholic",
  },
  [Blockchain.Centrifuge]: {
    prefix: 36,
    explorer: "subscan",
    explorerURL: "https://centrifuge.subscan.io",
  },
  [Blockchain.PolkadotClover]: {
    prefix: 128,
    explorer: "subscan",
    explorerURL: "https://clover.subscan.io",
  },
  [Blockchain.Collectives]: {
    prefix: 0,
    explorer: "polkaholic",
  },
  [Blockchain.Composable]: {
    prefix: 50,
    explorer: "subscan",
    explorerURL: "https://composable.subscan.io",
  },
  [Blockchain.Crust]: {
    prefix: 66,
    explorer: "subscan",
    explorerURL: "https://crust.subscan.io",
  },
  [Blockchain.Darwinia]: {
    prefix: 18,
    explorer: "subscan",
    explorerURL: "https://darwinia.subscan.io",
  },
  [Blockchain.Equilibrium]: {
    prefix: 68,
    explorer: "subscan",
    explorerURL: "https://equilibrium.subscan.io",
  },
  [Blockchain.Frequency]: {
    prefix: 90,
    explorer: "polkaholic",
  },
  [Blockchain.Hashed]: {
    prefix: 9072,
    explorer: "polkaholic",
  },
  [Blockchain.Hydradx]: {
    prefix: 63,
    explorer: "subscan",
    explorerURL: "https://hydration.subscan.io",
  },
  [Blockchain.IntegriteeShell]: {
    prefix: 13,
    explorer: "polkaholic",
  },
  [Blockchain.Interlay]: {
    prefix: 2032,
    explorer: "subscan",
    explorerURL: "https://interlay.subscan.io",
  },
  [Blockchain.Kapex]: {
    prefix: 2007,
    explorer: "polkaholic",
  },
  [Blockchain.Kilt]: {
    prefix: 38,
    explorer: "polkaholic",
  },
  [Blockchain.Kylin]: {
    prefix: null,
    explorer: "polkaholic",
  },
  [Blockchain.Litentry]: {
    prefix: 31,
    explorer: "polkaholic",
  },
  [Blockchain.Manta]: {
    prefix: 77,
    explorer: "polkaholic",
  },
  [Blockchain.Nodle]: {
    prefix: 37,
    explorer: "subscan",
    explorerURL: "https://nodle.subscan.io",
  },
  [Blockchain.Origintrail]: {
    prefix: 101,
    explorer: "subscan",
    explorerURL: "https://origintrail.subscan.io",
  },
  [Blockchain.Parallel]: {
    prefix: 172,
    explorer: "subscan",
    explorerURL: "https://parallel.subscan.io",
  },
  [Blockchain.Pendulum]: {
    prefix: 56,
    explorer: "polkaholic",
  },
  [Blockchain.Phala]: {
    prefix: 30,
    explorer: "subscan",
    explorerURL: "https://phala.subscan.io",
  },
  [Blockchain.Statemint]: {
    prefix: 0,
    explorer: "subscan",
    explorerURL: "https://assethub-polkadot.subscan.io",
  },
  [Blockchain.T3rn]: {
    prefix: null,
    explorer: "polkaholic",
  },
  [Blockchain.Unique]: {
    prefix: 7391,
    explorer: "subscan",
    explorerURL: "https://unique.subscan.io",
  },
  [Blockchain.Altair]: {
    prefix: 136,
    explorer: "subscan",
    explorerURL: "https://altair.subscan.io",
  },
  [Blockchain.Amplitude]: {
    prefix: 57,
    explorer: "polkaholic",
  },
  [Blockchain.Bajun]: {
    prefix: 1337,
    explorer: "subscan",
    explorerURL: "https://bajun.subscan.io",
  },
  [Blockchain.Basilisk]: {
    prefix: 10041,
    explorer: "subscan",
    explorerURL: "https://basilisk.subscan.io",
  },
  [Blockchain.BifrostKsm]: {
    prefix: 6,
    explorer: "subscan",
    explorerURL: "https://bifrost-kusama.subscan.io",
  },
  [Blockchain.Bitcountrypioneer]: {
    prefix: 268,
    explorer: "polkaholic",
  },
  [Blockchain.Bridgehub]: {
    prefix: 2,
    explorer: "polkaholic",
  },
  [Blockchain.Calamari]: {
    prefix: 78,
    explorer: "subscan",
    explorerURL: "https://calamari.subscan.io",
  },
  [Blockchain.Crab]: {
    prefix: 18,
    explorer: "subscan",
    explorerURL: "https://crab.subscan.io",
  },
  [Blockchain.Daoipci]: {
    prefix: 32,
    explorer: "polkaholic",
  },
  [Blockchain.Encointer]: {
    prefix: 2,
    explorer: "subscan",
    explorerURL: "https://encointer.subscan.io",
  },
  [Blockchain.Genshiro]: {
    prefix: 67,
    explorer: "subscan",
    explorerURL: "https://genshiro.subscan.io",
  },
  [Blockchain.Gm]: {
    prefix: 7013,
    explorer: "polkaholic",
  },
  [Blockchain.Imbue]: {
    prefix: 42,
    explorer: "polkaholic",
  },
  [Blockchain.Integritee]: {
    prefix: 13,
    explorer: "subscan",
    explorerURL: "https://integritee.subscan.io",
  },
  [Blockchain.Kabocha]: {
    prefix: 27,
    explorer: "polkaholic",
  },
  [Blockchain.Karura]: {
    prefix: 8,
    explorer: "subscan",
    explorerURL: "https://karura.subscan.io",
  },
  [Blockchain.Khala]: {
    prefix: 30,
    explorer: "subscan",
    explorerURL: "https://khala.subscan.io",
  },
  [Blockchain.Kintsugi]: {
    prefix: 2092,
    explorer: "subscan",
    explorerURL: "https://kintsugi.subscan.io",
  },
  [Blockchain.Krest]: {
    prefix: 42,
    explorer: "subscan",
    explorerURL: "https://krest.subscan.io",
  },
  [Blockchain.Kusama]: {
    prefix: 2,
    explorer: "subscan",
    explorerURL: "https://kusama.subscan.io",
  },
  [Blockchain.Litmus]: {
    prefix: 131,
    explorer: "subscan",
    explorerURL: "https://litmus.subscan.io",
  },
  [Blockchain.Mangatax]: {
    prefix: 42,
    explorer: "subscan",
    explorerURL: "https://mangatax.subscan.io",
  },
  [Blockchain.ParallelHeiko]: {
    prefix: 110,
    explorer: "subscan",
    explorerURL: "https://parallel-heiko.subscan.io",
  },
  [Blockchain.Picasso]: {
    prefix: 49,
    explorer: "subscan",
    explorerURL: "https://picasso.subscan.io",
  },
  [Blockchain.Quartz]: {
    prefix: 255,
    explorer: "subscan",
    explorerURL: "https://quartz.subscan.io",
  },
  [Blockchain.Robonomics]: {
    prefix: 32,
    explorer: "subscan",
    explorerURL: "https://robonomics.subscan.io",
  },
  [Blockchain.Shadow]: {
    prefix: 66,
    explorer: "polkaholic",
  },
  [Blockchain.KusamaShiden]: {
    prefix: 5,
    explorer: "subscan",
    explorerURL: "https://shiden.subscan.io",
  },
  [Blockchain.Sora]: {
    prefix: 69,
    explorer: "subscan",
    explorerURL: "https://sora.subscan.io",
  },
  [Blockchain.Statemine]: {
    prefix: 2,
    explorer: "subscan",
    explorerURL: "https://assethub-kusama.subscan.io",
  },
  [Blockchain.Subsocialx]: {
    prefix: 28,
    explorer: "polkaholic",
  },
  [Blockchain.Subzero]: {
    prefix: 42,
    explorer: "polkaholic",
  },
  [Blockchain.Tinkernet]: {
    prefix: 117,
    explorer: "polkaholic",
  },
  [Blockchain.Turing]: {
    prefix: 51,
    explorer: "subscan",
    explorerURL: "https://turing.subscan.io",
  },
  [Blockchain.Zeitgeist]: {
    prefix: 73,
    explorer: "subscan",
    explorerURL: "https://zeitgeist.subscan.io",
  },
  [Blockchain.Shibuya]: {
    prefix: 5,
    explorer: "subscan",
    explorerURL: "https://shibuya.subscan.io",
  },
};

export function isPolkadotCompatibleChain(
  blockchain: string,
): blockchain is PolkadotCompatibleChain {
  return polkadotCompatibleBlockchain.includes(blockchain as any);
}

export type PayloadZeroCostBasis = {
  _id: string;
  symbol: string;
  gain: number;
  name: string;
}[];

export interface AuthStatus {
  loggedIn: boolean;
}

/**
 * Branded type for Stripe coupon codes to ensure type safety
 * and prevent mixing with other string types
 */
export type StripeCouponCode = string & { readonly __brand: unique symbol };

export type StripeCouponDetails = {
  id: StripeCouponCode;
  percent_off: number;
  amount_off: number;
  redeem_by: number;
  object: DiscountType;
};

export type OAuthParams = {
  baseUri: string;
  clientId: string;
  scope: string;
};

// Maps every supported language to a locale used for momentJS and recaptcha
// https://developers.google.com/recaptcha/docs/language
export const LanguageLocales: Record<SupportedLang, string> = {
  [SupportedLang.Xx]: "de",
  [SupportedLang.XxShort]: "en",
  [SupportedLang.XxLong]: "en",
  [SupportedLang.En]: "en",
  [SupportedLang.De]: "de",
  [SupportedLang.Es]: "es",
  [SupportedLang.Fr]: "fr",
  [SupportedLang.It]: "it",
};

// BCP 47 language tags https://www.techonthenet.com/js/language_tags.php
export const BrowserLanguageMap: Record<string, SupportedLang> = {
  en: SupportedLang.En,
  de: SupportedLang.De,
  es: SupportedLang.Es,
  fr: SupportedLang.Fr,
  it: SupportedLang.It,
  "de-DE": SupportedLang.De,
  "de-AT": SupportedLang.De,
  "de-CH": SupportedLang.De,
};

// iterate through Transactions once

export type BalanceSnapshot = {
  balanceTotal: number;
  balanceInFlight: number;
  taxEngineOverallBalance: number;
  toTotal: number;
  fromTotal: number;
  toDisplayName: string;
  fromDisplayName: string;
  isInTrade: boolean;
  from: string;
  to: string;
};

type CurrencyIdentifierBase = {
  id: string; // a unique identifier for the currency (e.g. 'ethereum', 'bitcoin') that is associated with the currencyIdSource
  symbol: string; // the actual symbol (ticker) of the currency
  source: CurrencySourcePlatform; // a unique identifier for the currency source (e.g. 'coingecko') where the 'id' has come from
  name: string; // the user friendly name of the currency
  contractAddress?: string; // the address of the underlying contract
  assetPlatformId?: string; // what platform does this asset sit on (e.g. "ethereum", "binance-smart-chain" for ERC-20 tokens and BEP-20 tokens respectively), must be present if contract_address exists
  nftId?: string; // the id of the NFT token if it exists - not necessarily an NFT if this field is set. e.g. ERC-1155 has a tokenId that is placed here, but the token could be fungible
  isMarkedAsNFT?: boolean; // A manual boolean toggle that a user can set to override the currency's treatment as an NFT for tax purposes.
  externalImageUrl?: string; // if we have an external image url, use this
  isUserMarkedSpam?: boolean;
  platforms?: Platform[];
  assetType?: AssetType;
};

export type Platform = {
  assetPlatformId: string;
  contractAddress: string;
};

export type CurrencyIdentifierDerivative = CurrencyIdentifierBase & {
  assetType: AssetType.Derivative;
};

export type CurrencyIdentifierToken = CurrencyIdentifierBase & {
  assetType?: AssetType.Token;
};

export type CurrencyIdentifier =
  | CurrencyIdentifierDerivative
  | CurrencyIdentifierToken;

export type CurrencyIdentifierWithContractAddresses = CurrencyIdentifier & {
  contractAddresses: string[];
};

interface BaseEntity {
  _id: string;
  addresses: EntityAddress[];
  displayName: string;
  isEscrow?: boolean;
}

export type ExchangeEntity = BaseEntity & {
  type: EntityType.Exchange | EntityType.Extension;
  ref: string;
  globalAddresses: EntityAddress[];
  globalIsEscrow: boolean;
  manualEntityId?: string;
  imageUrl?: string;
};

export function isExchangeEntity(entity: Entity): entity is ExchangeEntity {
  return (
    entity.type === EntityType.Exchange || entity.type === EntityType.Extension
  );
}

type ManualEntity = BaseEntity & {
  type: EntityType.Manual;
  manualEntityId?: string;
  uid: string;
};

export type Entity = ExchangeEntity | ManualEntity;

export type Label = {
  _id: string;
  text: string;
  color: string; // todo add color
  uid: string;
  description?: string;
};
export type NewLabel = Pick<Label, "text" | "color" | "description">;

// https://github.com/microsoft/TypeScript/issues/39556#issuecomment-1109208716
// Omit does not work with distributed unions so using this instead
type OmitDistributive<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;
export type NewEntity = OmitDistributive<
  Entity,
  "_id" | "uid" | "addresses"
> & {
  addresses: EntityAddressQuery[];
};

export type PriceMetadata = {
  sourceType: PriceSourceType;
  windowTxId?: string;
};

export type TransactionAppliedRules = {
  ruleId: string;
  operator: BulkOperations;
  createdAt: Date;
  updatedAt: Date;
};

export type TransactionDetails = {
  _id: string;
  id: string;
  timestamp: string; // TODO this is actually a string
  currencyIdentifier: CurrencyIdentifier; // This is now required
  trade: Trade;
  from: string;
  to: string;
  isFromSmartContract?: boolean;
  isToSmartContract?: boolean;
  fromDisplayName: string;
  toDisplayName: string;
  quantity: number;
  price: number;
  priceMetadata?: PriceMetadata;
  total: number;
  fee: number;
  feeQuantity?: number;
  feeCurrencyIdentifier?: CurrencyIdentifier;
  importType: ImportType;
  source: string;
  uid: string;
  description?: string;
  functionName?: string;
  blockchain?: string;
  balanceRemaining?: BalanceSnapshot;
  errors: Warning[];
  ignoreWarning?: Partial<Record<IgnorableWarning, boolean>>;
  highlight?: boolean; // should this row be highlighted?
  ignoreGrouping?: boolean;
  dontGroup?: boolean;
  comments?: TransactionComment[];
  reviewed?: boolean;
  // links a tx, with a fee tx
  groupById?: string;
  erp: {
    accountMappings: ErpAccountMappings;
    syncStatus: ErpSyncStatus;
    journalId?: string;
  };
  appliedRules?: TransactionAppliedRules[];
};
export type ErpAccountMappings = Record<ERPAccountType, string | undefined>;

export type TransactionComment = {
  _id: string;
  author: string; // _id of author of the comment, used to highlight whether the accountant or client made the comment
  authorName?: string;
  comment: string; // the comment itself
  timestamp: Date; // time the comment was left
  lastEdited?: Date; // time the comment was last edited
};

export type TransactionExtendedDetails = Omit<
  TransactionDetails,
  "balanceRemaining" | "taxEvents" | "fee"
> & {
  total: number;
  fromDisplayName: string;
  toDisplayName: string;
  sourceDisplayName: string;
};

export type TradingStockAuRow = {
  currencyIdentifier: CurrencyIdentifier;
  startingStock: number;
  startingPrice: number;
  startingValue: number;
  acquiredStockQuantity: number;
  acquiredTotalValue: number;
  disposedStockQuantity: number;
  disposedTotalValue: number;
  endingStock: number;
  endingPrice: number;
  endingValue: number;
  netProfit: number;
};

export type TransactionDetailTotal = {
  costExFee: number | null;
  fee: number | null;
  proceeds: number | null;
  gain: number | null;
};

export type SourceFilterOption = {
  id: string;
  name: string;
  nickname?: string;
  blockchain?: Blockchain;
  blockchainDisplayName?: string | undefined;
  type?: EntityType;
};

export type SyncFilterOption = {
  syncId: string;
  exchangeOrAddress: string;
  blockchain?: Blockchain;
  dateMs: number;
  nickname?: string;
  importType: ImportType;
  txCount: number;
  importId: string;
};

export type TransactionFilterOptions = {
  trade: Trade[];
  currency: CurrencyIdentifierWithContractAddresses[];
  importType: ImportType[];
  source: SourceFilterOption[];
  from: ExchangeOption[];
  to: ExchangeOption[];
  // importSource: SourceFilterOption[]; // TODO: i dont think this exists
  txFunctionName: string[];
  txMethodId: string[];
  importId: SpecificExchangeImportIdentifier[];
  preset: FinancialYear[];
  blockchain: Blockchain[];
  nftCollection: CurrencyIdentifier[];
  sync: SyncFilterOption[];
  feeCurrency: CurrencyIdentifierWithContractAddresses[];
  txCurrency: CurrencyIdentifierWithContractAddresses[];
  isSmartContractInteraction: boolean[];
  integrations: { label: string; value: string }[];
};

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type CategoryFilter = {
  type: FilterOperators.TxTrade;
  value: ActionType[];
};

export type CurrencyFilter = {
  type: FilterOperators.Currency;
  value: string[];
};

export type FeeCurrencyFilter = {
  type: FilterOperators.FeeCurrency;
  value: string[];
};

export type TxCurrencyFilter = {
  type: FilterOperators.TxCurrency;
  value: string[];
};

export type AndFilter = {
  type: FilterOperators.And;
  rules: FilterQuery[];
};
type OrFilter = {
  type: FilterOperators.Or;
  rules: FilterQuery[];
};
type NotFilter = {
  type: FilterOperators.Not;
  rule: FilterQuery;
};
export type AfterFilter = {
  type: FilterOperators.After;
  value: number;
};
export type BeforeFilter = {
  type: FilterOperators.Before;
  value: number;
};
export type DateFilter = {
  type: FilterOperators.Date;
  value: number[];
};
export type TxIdFilter = { type: FilterOperators.TxHash; value: string[] };
export type DescriptionFilter = {
  type: FilterOperators.Description;
  value: string[];
};
export type SourceFilter = { type: FilterOperators.Source; value: string[] };

export type CommentContentFilter = {
  type: FilterOperators.CommentContains;
  value: string[];
};

export type FilterQuery =
  | {
      type: FilterOperators.ActionId;
      value: string[];
    }
  | {
      type: FilterOperators.TaxOutcomeType;
      value: TaxOutcomeType[];
    }
  | CategoryFilter
  | {
      type: FilterOperators.ActionTrade;
      value: ActionType[];
    }
  | CurrencyFilter
  | FeeCurrencyFilter
  | TxCurrencyFilter
  | {
      type: FilterOperators.ImportType;
      value: ImportType[];
    }
  | SourceFilter
  | { type: FilterOperators.ImportId; value: string[] }
  | TxIdFilter
  | { type: FilterOperators.Warning; value: Warning[] }
  | AfterFilter
  | BeforeFilter
  | DateFilter
  | {
      type: FilterOperators.TransactionId;
      value: string[];
      showAssociated: 0 | 1;
    }
  | { type: FilterOperators.From; value: string[] }
  | { type: FilterOperators.To; value: string[] }
  | {
      type: FilterOperators.HasComments;
      value: CommentsFilter;
    }
  | {
      type: FilterOperators.TxFunctionName;
      value: string[];
    }
  | {
      type: FilterOperators.TxFunctionSignature;
      value: string[];
    }
  | {
      type: FilterOperators.Reconciliation;
      value: string;
    }
  | {
      type: FilterOperators.NegativeBalance;
      value: string;
    }
  | {
      type: FilterOperators.MissingPrice;
      value: string;
    }
  | { type: FilterOperators.Blockchain; value: Blockchain[] }
  | AndFilter
  | OrFilter
  | NotFilter
  | {
      type: FilterOperators.Reviewed;
      value: boolean;
    }
  | {
      type: FilterOperators.Suggestion;
      value: boolean;
    }
  | {
      type: FilterOperators.NFTCollection;
      value: string[]; // Array of NFT contract addresses
    }
  | {
      type: FilterOperators.Sync;
      value: string[]; // Array of syncIds
    }
  | DescriptionFilter
  | CommentContentFilter
  | { type: FilterOperators.ErpAssetAccount; value: string[] }
  | { type: FilterOperators.ErpCashAccount; value: string[] }
  | { type: FilterOperators.ErpPnlAccount; value: string[] }
  | { type: FilterOperators.ErpGainsAccount; value: string[] }
  | { type: FilterOperators.ErpLoanAccount; value: string[] }
  | { type: FilterOperators.ErpSyncStatus; value: ErpSyncStatus[] }
  | { type: FilterOperators.LockPeriod; value: LockPeriodTxStatus }
  | { type: FilterOperators.IsSmartContractInteraction; value: boolean }
  | { type: FilterOperators.IncludesFee; value: boolean }
  | {
      type: FilterOperators.HasRule;
      value: boolean;
    }
  | {
      type: FilterOperators.Rule;
      value: string[];
    }
  | {
      type: FilterOperators.RuleOperator;
      value: BulkOperations[];
    }
  | {
      type: FilterOperators.Integration;
      value: string[];
    };

export function isAndFilter(filter: FilterQuery): filter is AndFilter {
  return filter.type === FilterOperators.And;
}

export function isOrFilter(filter: FilterQuery): filter is OrFilter {
  return filter.type === FilterOperators.Or;
}

// warnings which the user can ignore
export type IgnorableWarning =
  | Warning.Uncategorised
  | Warning.MissingPrice
  | Warning.NegativeBalance
  | Warning.SuspectedMissingImport
  | Warning.SuspectedAirdropTransaction
  | Warning.SuspectedBurnTransaction
  | Warning.MissingBlockchain
  | Warning.UnmatchedTransfer;

export type OnRowWarning =
  | Warning.Uncategorised
  | Warning.MissingPrice
  | Warning.NegativeBalance
  | Warning.SuspectedMissingImport
  | Warning.MissingBlockchain
  | Warning.UnmatchedTransfer;

// TODO: update this to include more warnings
export type BulkIgnorableWarning =
  | Warning.Uncategorised
  | Warning.MissingPrice
  | Warning.MissingBlockchain
  | Warning.NegativeBalance
  | Warning.UnmatchedTransfer
  | Warning.SuspectedMissingImport;

export const bulkIgnorableWarnings: BulkIgnorableWarning[] = [
  Warning.Uncategorised,
  Warning.MissingPrice,
  Warning.MissingBlockchain,
  Warning.NegativeBalance,
  Warning.UnmatchedTransfer,
  Warning.SuspectedMissingImport,
];

export type ExtraCsvParams = {
  currency?: string;
  address?: string;
  tickers?: Pick<CurrencyIdentifier, "id" | "symbol">[];
  dateFormat?: string;
  importFromDate?: Date;
};

export type BaseUserDetails = {
  uid: string;
  paidPlan: Plan;
  planCurrency?: LocalCurrency; // what currency the user is paying in
  isOAuth: boolean;
  oAuthProvider?: OAuthProvider;
  oAuthId?: string;
  paidPlanTrial?: boolean;
  paidPlanTrialUntil?: string;
  email?: string;
  inviteCode?: string;
  parentProfile?: string;
  inventoryMethod?: InventoryMethod; // TODO why is this optional?
  isInAlpha: boolean;
  country: Country;
  language: SupportedLang;
  localCurrency?: LocalCurrency;
  name?: string;
  reportRefreshStatus?: SyncStatusPlatform;
  reportRefreshUpdatedAt?: string;
  ownsChildProfileData?: boolean;
  clientHasRevoked?: boolean;
  cancelAtPeriodEnd?: boolean;
  clientBridge?: boolean;
  clientBridgeInitial?: boolean;
  discount?: string;
  xeroEnabled?: boolean; // Temporary feature flag.
  importRelatedWallets?: boolean;
  showSpamTransactions?: boolean; // Whether we show spam + ignore transactions in the users transactions table
  paymentDue?: Date;
  intercomHash: string; // used for intercom verification security
  shouldShowCancellationSurvey: boolean; // Used to flag we should show the downgrade survey to the user
  bypassTXPaywall?: boolean; // Used to bypass the TX paywall
  notifications?: Notifications;
  // The features a user can access when not paywalled
  features: Partial<Record<Features, true>>;
  planVersion: PlanVersion;
  emailVerificationStatus: EmailVerificationStatus;
  createdAt: number;
  lastTxDate?: string;
  emailReports?: boolean;
  shouldShowEmailReportsModal?: boolean;
  is2faEnabled?: boolean;
  isOnboarding?: boolean;
  referrerSource?: ReferrerSource; // The source of the user's referral, e.g coinbase
  isReOnboarding?: boolean;
  lastLogin?: Date;
  partnerEmployeeDetails?: PartnerEmployeeDetails;
};

export type PartnerEmployeeDetails = {
  partner?: string;
  workEmail?: string;
};

export type NotificationKey = keyof TaxSettings | NotificationType;

export type Notifications = Partial<Record<NotificationKey, boolean>>;

export interface RenewalErrorInfo {
  renewalErrorState?: null | "unpaid" | "past_due";
  renewalErrorMsg?: string;
}

export type BaseUserBillingDetails = {
  isManagingOwnBilling: boolean;
} & RenewalErrorInfo;

export type DataSource = {
  isRetailUser: boolean; // Not an accountant or client or collaborator
  country?: Country;
  localCurrency?: LocalCurrency;
  paidPlan: Plan;
  paidPlanTrial?: boolean; // used to identify users that have been manually put on a free trial.
  bypassTXPaywall?: boolean; // for whether you are not paid plan limited
  paidPlanTrialUntil?: Date;
  renewalErrorState?: string;
  clientBridge?: boolean;
  clientBridgeInitial?: boolean;
  childProfile?: string; //   Accountant.childProfile =>   Bridge.childProfile => Client
  parentProfile?: string; //  Client.parentProfile =>   Bridge.parentProfile   => Accountant
  inventoryMethod?: InventoryMethod;
  isInAlpha?: boolean;
  isWithinEnterprise: boolean;
  name?: string;
  email: string;
  updateEmailAttempts?: number;
  lastUpdateEmailAttempt?: Date;
  notifications?: Record<string, boolean>;
  ownsChildProfileData?: boolean;
  clientHasRevoked?: boolean;
  cancelAtPeriodEnd?: boolean;
  reportRefreshStatus?: SyncStatusPlatform;
  reportRefreshUpdatedAt?: string;
  showSpamTransactions?: boolean;
  hasGeneratedAnyReconciliationIssues?: boolean;
  taxSettings: TaxSettings;
  txGroupCount: number;
  txBilledCount: number;
  txRealCount: number;
  txFreeCount: number;
  version?: string;
  uid: string;
  createdAt: Date;
  archiveStatus: ArchiveStatus;
  usExperimentPlan?: Plan;

  // Virtuals
  onPaidPlanTrial: boolean;
  isAccountantOrCollaborator: boolean;
  paidBy: PaidBy;
  hasUnpaidError: boolean;
  inviteCode?: string;
};

export type UserInfo = UserDetails & {
  _id: string;
  activeDataSource: DataSource;
  paymentUserId: string; // The user id of the user that is used for billing purposes
  country?: Country;
  countryHint?: Country;
  language?: SupportedLang;
  // The collaborator's accountant, the accountant themselves, or undefined for a regular user
  accountant?: UserDetails;
  activeUser: UserDetails; // Selected Client, always the user that is emailable (the whole reason is to be able to email them) - Always fallsback to a user, so is never undefined
  timezone?: string;
  authMethods: AuthMethod[];
};

export type UserDetails = BaseUserDetails &
  BaseUserBillingDetails & {
    // this is always defined
    activeProfileDetails: BaseUserDetails & RenewalErrorInfo;
    // this is defined for a 'client' who has a 'user' as a childProfile
    childProfileDetails?: BaseUserDetails & RenewalErrorInfo;
    // this is defined for a collaborator
    adminProfileDetails?: {
      adminProfile: string; // this
    };
    // defined for a user who is being managed by a client
    parentProfileDetails?: BaseUserDetails & RenewalErrorInfo;
    // subscription versions found in Stripe.
    subscriptionVersions?: number[];
    // check if user have stripe customer account
    isStripeEnabled: boolean;
    // true if we are an accountant or collaborator that belongs to an enterprise
    isWithinEnterprise: boolean;
    // optional, max number of clients the accountant can have
    accountantMaxClientLimit?: number;
    // the stripe customer id
    stripeId?: string;
    // Whether the user is paywalled or not
    paywallInfo: PaywallType;
    // Determines if the user has had V2 subscriptions in the past / existing
    hasV2Subscriptions: boolean;
    // The plan that the user is on for the US experiment `undefined` if not on an experiment plan
    usExperimentPlan?: Plan;
    // Feature flags for the user
    featureFlags?: Record<string, string>;
  };

export type PaywallLimits = {
  txCountLimit: number;
  txCountHardLimit: number;
  importLimit?: number;
  complexTransactions: boolean;
  alwaysAllowPurchase?: boolean;
};

export type PlanFeaturesRes = Record<
  string,
  {
    name: string;
    features: Record<
      string,
      {
        name: string;
        includedItems: Record<Plan, string>;
      }
    >;
  }
>;

export type Price = {
  plan: Plan;
  amount: number;
  currency: LocalCurrency;
  credits?: number;
};

export type PlanDataBase = {
  paywallLimits: PaywallLimits;
  features: Partial<Record<Features, boolean>>;
  willRemovePaywall: boolean;
};

export type RetailPlanData = {
  amount: number;
  currency: LocalCurrency;
  credits?: number;
  upgradeAmount: number;
} & PlanDataBase;

export type BusinessPlanData = {
  prices: Price;
} & PlanDataBase;

export type PlansRes = Partial<Record<Plan, RetailPlanData>>;

export type BusinessPlansRes = Record<BusinessPaidPlanType, BusinessPlanData>;

export type UpdateCountry = {
  country: Country;
  localCurrency: LocalCurrency;
};

export type UpdateLanguage = {
  language: SupportedLang;
};

export type UpdateAlpha = {
  isInAlpha: boolean;
};

export type UpdateEmailReports = {
  emailReports?: boolean;
  shouldShowEmailReportsModal?: boolean;
};

export type UpdateName = {
  name: string;
};

export type InviteCodeDetails = {
  name: string;
  email: string;
  country: Country;
};

export type UpdateInviteCode = {
  inviteCode: string;
};

export type AcceptInviteCode = {
  inviteCode: string;
};
export type UpdateEmail = {
  email?: string;
};

export type DisableOAuth = {
  oAuthProvider: undefined;
  isOAuth: false;
};

export type UpdatePayment = {
  paidPlan: Plan;
};

export type UpdateIs2faEnabled = {
  is2faEnabled: boolean;
};

export type UpdateOnboarding = {
  isOnboarding: boolean;
};

export type UpdateReOnboarding = {
  isReOnboarding: boolean;
};

export type UpdateRenewalErrorInfo = RenewalErrorInfo;

type PaywallReasonAndParty = {
  reasons: PaywallReason[];
  partyResponsibleToAction: PartyToAction;
};

type NotPaywalled = {
  isPaywalled: false;
};

type Paywalled = {
  isPaywalled: true;
  paywallReason: PaywallReasonAndParty;
};

export type PaywallType = NotPaywalled | Paywalled;

export type UpdateSubscriptionVersions = {
  subscriptionVersions: number[];
};

export type UpdateShowIgnored = {
  showSpamTransactions: boolean;
};

export type UpdateOwnsChildProfileData = {
  ownsChildProfileData: boolean;
};

export type UnlockDetails = {
  token: string;
};

export type TaxSettings = Readonly<{
  ignoreFiat: boolean; // ignore capital gains on fiat
  inventoryMethod: InventoryMethod;
  localCurrency: LocalCurrency;
  timezone: string;
  defaultLongTermThreshold: number;
  longTermThreshold: number;
  separateShortTermLosses: boolean;
  ignoreBalanceRemainingValueThreshold: number;
  miningAsIncome: boolean;
  personalUseAsNonTaxable: boolean;
  airdropsAsIncome: boolean;
  outgoingGiftAsNonTaxable: boolean;
  stakingAsIncome: boolean;
  royaltiesAsIncome: boolean;
  stakingDepositWithdrawalNonTaxable: boolean;
  liquidityAddRemovalNonTaxable: boolean;
  collateralAsNonTaxable: boolean;
  lostStolenAsNonTaxable: boolean;
  showFiatHoldings: boolean;
  grandfatheredHMRC: boolean;
  useLegacyHMRCCalcs: boolean;
  specificInventoryByExchange: boolean;
  bridgeAsNonTaxable: boolean;
  priceWindow: number;
  cryptoToCryptoNonTaxable: boolean;
  cryptoToCryptoNonTaxableFiatCurrencies: string[];
  cryptoToCryptoNonTaxableStartDate: Date;
  nftIsDifferentTypeOfCrypto: boolean;
  uncategorisedInAsTaxable: boolean;
  uncategorisedOutAsTaxable: boolean;
  assumeTransferCounterparts: boolean;
  showAllAccountsOnPortfolio: boolean;
  splitFeesEvenlyAcrossGroupedMints: boolean;
  allowBridgeFromUncategorised: boolean;
  allowGenerateFeeOnGroupedQuantityDiff: boolean;
  holdingsBalanceType: BalanceType.Reported | BalanceType.Calculated;
  costBasisRedistributionMethod: CostBasisRedistributionMethod;
}>;

export enum RenewalStateError {
  Unpaid = "unpaid",
  PastDue = "past_due",
}

export type ClientDetails = {
  uuid: string; // the ._id of the UserDocument related to this client
  dateCreated: string;
  name: string; // na
  email?: string; // what is the email of the client
  childProfile?: string;
  clientHasRevoked?: string;
  cancelAtPeriodEnd?: boolean;
  isActive: boolean; // is this profile the current active profile
  isDefaultAccount: boolean; // is this the default account
  isInvited: boolean; // is this client just an invited client
  isInitial: boolean; // is client purchased at initial subscription time.
  invitationDetails?: InvitationDetails;
  accountantOwnedData?: boolean; // does the accountant own the data for this client (readonly)
  reportRefreshUpdatedAt?: Date;
  reportRefreshStatus?: SyncStatusPlatform;
  version?: number; // subscription version.
  paidPlan: Plan; // the paid plan of the client
  childPaidPlan?: Plan;
  paymentDue?: string; // date string for next payment.
  xeroEnabled?: boolean; // Temporary xero feature flag.
  importRelatedWallets?: boolean;
  renewalErrorState?: null | RenewalStateError;
  hasPaidAtLeastOnce?: boolean;
  whoIsPaying?: WhoIsPaying; // who is paying for the account
  country: Country;
  txCount: number;
};

export type ClientPayload = {
  name: string;
  email?: string;
  country: Country;
  uuidToClone?: string;
  setActiveClient: (uid: string) => void;
  planOverride?: Plan;
};

export type ExchangeOption = {
  label: string;
  name: string;
  currency?: string;
  blockchain?: string | Blockchain;
  isManual?: boolean;
  isWallet?: boolean;
  inputValue?: string;
  entity?: Entity;
};

type SpecificExchangeImportIdentifier = {
  importId: string;
  importType: ImportType;
  exchangeName: string;
  name: string; // filename || api key
  nickname?: string;
};

enum BillingHistoryStatus {
  Cancelled = "canceled",
  Processing = "processing",
  RequiresAction = "requires_action",
  RequiresCapture = "requires_capture",
  RequiresConfirmation = "requires_confirmation",
  RequiresPaymentMethod = "requires_payment_method",
  Succeeded = "succeeded",
}
export type BillingHistory = {
  id: string; // the id of the paymentIntent
  created: number; // unix timestamp in ms
  latestChargeCreated: number; // unix timestamp in ms
  succeeded: boolean;
  description: string | null;
  amount: number;
  currency: string; // three letter iso code
  status: BillingHistoryStatus;
  errorMessage: string | null;
};

export type PaymentMethod = {
  id: string; // the id of the payment method
  isDefault: boolean; // if this is the default payment method
  created: number; // timestamp (in ms) the payment method was created
  card: {
    brand: CardBrand;
    brandName: string;
    last4: string; // the last 4 card digits
    expiryMonth: number;
    expiryYear: number;
    isExpired: boolean; // is the card expired
    // cvcCheck: "pass" | "fail" | "unavailable" | "unchecked" | null;
  };
};

export type InvitationDetails = {
  _id: string; // the mongodb id of the document
  fromName?: string; // the name of the user who is sending invite
  toName?: string; // the name of the user who is being invited
  fromEmail: string; // invite sent from email
  toEmail: string; // invite sent to email
  status: InvitationStatus;
  invitationType: InvitationType; // the type of the toProfile (i.e. who was invited)
  createdAt: string; // ISO timestamp when was the invitation created
  declinedAt?: string; // ISO timestamp when the invitation was declined
  acceptedAt?: string; // ISO timestamp only if the status is accepted
  description?: string; // a short description of the invitation
};

export type IndividualInvitations = {
  sent: InvitationDetails[];
  received: InvitationDetails[];
  support: InvitationDetails[];
};

export type TeamInvitationDetails = InvitationDetails & {
  uid?: string;
} & Pick<UserDetails, "name" | "lastLogin">;

export type CreateInvitationPayload = Pick<
  InvitationDetails,
  "fromName" | "toName" | "toEmail" | "invitationType"
> & { clientProfile?: string; replacingInvitationId?: string };

export type UpdateInvitationPayload = Pick<InvitationDetails, "status">;

export type CreateOrganisationTeamPayload = Pick<
  OrganisationTeam,
  "name" | "region"
> & { email: string };

export type SyncReturnPayload = {
  tradeHistory: SyncStatusPlatform;
  balances: SyncStatusPlatform;
};

type BaseTradeInfo = {
  // Trade exists as an option when creating a manual transaction.
  isManual?: boolean;
  // Trade on a TX cannot be changed to this type, eg: unknown.
  isEditBlocked?: boolean;
  // Trade on a TX cannot be seen on filters, defaults as true.
  isFilterable?: boolean;
  // Trade cannot be seen in the UI, eg: zero cost buy.
  isHidden?: boolean;
  // Trade is no longer used and should be re-categorised by the user.
  isOutdated?: boolean;
  // Used to group transactions in the trade type dropdown.
  taxDivision: (taxSettings: TaxSettings) => TaxDivision;
  // The opposite tradetype to current type i.e. Buy.negativeValueTrade === Sell
  negativeValueTrade?: Trade;
  // True for trades that CANNOT have an attatched separate feeTx
  noFeeTx?: boolean;
  // Terms the user can search for to retrieve this trade type
  searchTags?: string[];
  isPartOfGroup?: boolean;
  // New method to group transactions in the trade type menu
  // from: https://docs.google.com/spreadsheets/d/1EMchH6F03Oj_XUWtmr_HgbABJqR7kyHM48XAouKRXDg/edit#gid=0
  bucket?: (taxSettings: TaxSettings) => CategoryBucket;
};

type IncomingTrade = BaseTradeInfo & {
  direction: TradeDirection.In;
};

type OutgoingTrade = BaseTradeInfo & {
  direction: TradeDirection.Out;
};

type UnknownTrade = BaseTradeInfo & {
  direction: TradeDirection.Unknown;
};

export type TradeData = IncomingTrade | OutgoingTrade | UnknownTrade;
export type ActionData = {
  subActionTypes: Trade[];
  outTrade?: Trade;
  inTrade?: Trade;
  isTransferLike?: boolean;
  groupRecategorisationType: GroupRecategorisationType;
  groupRatio: GroupedActionRatio;
  isFilterable: boolean;
  isMultichain?: boolean;
  groupCriteria: GroupingCriteria[];
  excludeFromRecategorisation?: boolean; // true if this action type should not be shown in the recategorisation dropdown
};

export type DuplicateActionResponse = {
  id: string;
  txIdMap: Record<string, string>;
  actionId: string;
};

export type ActionTxGroupSummaries = {
  summaries: ActionTxGroupSummary[];
  totalValue: number;
};

export type ActionTxGroupSummary = {
  errors: Warning[];
  currencyIdentifier: CurrencyIdentifier;
  blockchain?: string;
  quantity: number;
  price: number;
  trade: Trade;
  txIds: string[];
};

export type ActionRow = {
  // All the properties from the action document we wish to display at the
  // action level
  _id: string;
  uuid: string;
  ids: string[];
  timestamp: Date;
  type: ActionType;
  sortPriority: number;
  comments?: TransactionComment[];

  // Embedded transactions - displaying at the transaction level
  incoming: TransactionDetails[];
  outgoing: TransactionDetails[];
  fees: TransactionDetails[];

  // Computed properties - properties that must be derived from all sub
  // transactions, to be displayed at the action level
  incomingGroupSummaries: ActionTxGroupSummaries;
  outgoingGroupSummaries: ActionTxGroupSummaries;
  functionNames: string[];
  hasComments?: boolean;
  reviewed?: boolean;
  suggested?: boolean; // The current trade type was assigned by the smart suggestions engine
  assumedTransfer?: boolean;
  warnings?: Warning[];
  // hasBeenManuallyEdited?: boolean

  // the total gain across all txs
  overallGain?: number;

  uncategorisedGroupData:
    | {
        groupSize: number;
        groupValue: number;
      }
    | undefined;

  // for erp system
  erpSyncStatus?: ErpSyncStatus;
  isLocked: boolean;
};
export type ActionTradeRow = Omit<ActionRow, "type"> & { type: Trade };

export type LedgerData = {
  txCount: number;
  amountMissing: number;
};

export type PageRowSize = 10 | 25 | 50 | 100;

export function isValidPageRowSize(n: number): n is PageRowSize {
  return [10, 25, 50, 100].includes(n);
}

export type WalletFormValues = {
  id: string;
  address: string;
  name: string | undefined;
  importFromTimeMillis: number | undefined;
};

export type APIFormValues = {
  name?: string;
  apiKey: string;
  secret: string;
  password?: string;
  uid?: string;
  refreshToken?: boolean;
  tickers: string[];
  importFromTimeMillis: number | undefined;
};

export type BreakdownRow = {
  id?: string; // This can be undefined for generated ZCBs, etc
  type: Trade;
  currency: CurrencyIdentifier;
  date: string;
  amount: number;
  price: number;
  priceMetadata: PriceMetadata;
  value: number;
  cost?: number;
  fee?: number;
  proceeds?: number;
  gain?: number;
  moreInfo?: TaxRule;
  showFeeForwardedWarning?: boolean;
  children?: BreakdownRow[];
  source?: string;
  rolloverOriginTx?: {
    id: string;
    currencyIdentifier: CurrencyIdentifier;
  };
};

export type ActionBalancesResponse = {
  from: string;
  fromDisplayName: string;
  fromBlockchain?: Blockchain;
  to: string;
  toDisplayName: string;
  toBlockchain?: Blockchain;
  isInFlightEnabled: boolean;
  hasBalanceErrors: boolean;
  rows: BalancesRow[];
};

type BalanceErrors = {
  startingBalance: boolean;
  finalBalance: boolean;
  // @todo Rename to `overallBalance`
  overall: boolean;
};

export type BalancesRow = {
  blockchain?: Blockchain;
  account?: string;
  displayName?: string;
  currency: CurrencyIdentifier;
  type: Trade;
  startingBalance: number;
  finalBalance: number;
  overallBalance: number;
  change: number;
  balanceErrors?: BalanceErrors;
};

type AdvancedCSVOptionValues = {
  currencies: CurrencyIdentifier[] | null;
  dateFormat: string;
  timezone: TimezoneOption | null;
  importFromDate?: Date | null;
  files: File[];
};

export type CSVFormData = AdvancedCSVOptionValues & {
  manualName: string;
};

// Countries where the words 'Capital Gains' are replaced with 'Profits/Losses'
const CGT_TO_PNL_COUNTRIES = [Country.NewZealand];
export const shouldReplaceCGTWithPNL = (country?: Country): boolean => {
  return CGT_TO_PNL_COUNTRIES.includes(country as Country);
};

export const shouldReplaceCGTWithPortfolioValue = (
  country?: Country,
): boolean => {
  return country === Country.Netherlands;
};

export const nonEntityableAddresses = [
  CustomExchanges.Bank,
  CustomExchanges.Unknown,
] as const;

type BaseOptions = {
  preferred?: boolean; // override the default of OAuth, API, CSV
};

type ApiOptions = BaseOptions & {
  tickers?: CurrencyIdentifier[];
};

type CsvOptions = BaseOptions & { manual?: boolean };
export type CsvRequirements = {
  address?: boolean;
  currency?: boolean;
};
type OAuthOptions = BaseOptions;

// todo: add networks
export type WalletOptions = BaseOptions & {
  isBtcAltNetwork?: boolean;
  isUnspecifiedBlockchainAllowed?: boolean;
};

type BaseImportMethod = {
  type: ImportMethod;
  options?: BaseOptions;
  /**
   * The ETA in milliseconds for the import method.
   * This is calculated based on the sync metrics for the integration.
   */
  etaMs: number | null;
  /**
   * The average duration in milliseconds to complete the import.
   */
  avgMs: number | null;
  /**
   * The 95th percentile duration in milliseconds to complete the import.
   */
  p95Ms: number | null;
};

type ApiImportOptions = Omit<ApiOptions, "tickers"> & {
  tickers?: CurrencyIdentifier[];
};
export type ApiImportMethod = BaseImportMethod & {
  type: ImportMethod.API;
  credentials: ApiCredentials;
  options?: ApiImportOptions;
  hasValidate?: boolean;
};

export type CsvImportMethod = BaseImportMethod & {
  type: ImportMethod.CSV;
  requirements?: CsvRequirements;
  options?: CsvOptions;
};

export type OAuthImportMethod = BaseImportMethod & {
  type: ImportMethod.OAuth;
  options?: OAuthOptions;
};

export type WalletImportMethod = BaseImportMethod & {
  type: ImportMethod.Wallet;
  blockchains: Blockchain[];
  options?: WalletOptions;
  hasValidate?: boolean;
};

export type ConnectWalletImportMethod = BaseImportMethod & {
  type: ImportMethod.ConnectWallet;
  blockchains: Blockchain[];
  walletConnectOrConfig: ConnectWalletConfigKey | ConnectWalletConfigHandler;
  options?: WalletOptions;
};

export type BulkWalletImportMethod = BaseImportMethod & {
  type: ImportMethod.BulkWallet;
  regex: string; // regex to transform a given bulk string into key value pairs of chain and address
  keyMapping: Record<string, Blockchain>; // Mapping of the data sources chain shorthands to our blockchain type
};

export type ImportMethodV2 =
  | ApiImportMethod
  | CsvImportMethod
  | WalletImportMethod
  | ConnectWalletImportMethod
  | OAuthImportMethod
  | BulkWalletImportMethod;

export type ImportOptionV2 = {
  id: string;
  name: string;
  category: IntegrationCategory;
  searchTerms?: string[];
  importMethods: ImportMethodV2[];
  isDeprecated?: boolean;
  // This is a legacy variable set by the frontend when a user adds a new manual integration
  // We show it in the available imports with manual set to true
  manual?: boolean;
  isBeta?: boolean;
};

export type SavedImportBase = {
  id: string; // importId - a MongoDB _id.toHexString()
  name?: string; // file name or individual nickname
  status: SyncStatusPlatform;
  completedAt: string | null; // null if no syncs
  createdAt: string;
  error?: SyncError;
  errorIssue?: SyncErrorIssue;
  // is this pulled from the syncState? or on the doc itself?
  importType: ImportType;
  importFromDate?: Date;
  importedTxCount?: number;
  lastSyncNewTxCount?: number; // this is purely to show the number on the import toast
  lastImportedTxTimestamp: Date | null;
};
export type ParseErrorRecord = {
  type: UserFacingParseError;
  count: number;
};

export type SavedFileImport = SavedImportBase & {
  exchangeName: string;
  numTxsInserted: number;
  numRowsParsed: number;
  firstTxDate?: Date;
  lastTxDate?: Date;
  isDuplicate: boolean;
  parseErrors: ParseErrorRecord[];
  /**
   * Whether the raw csv is stored remotely and can be re-downloaded.
   */
  isDownloadable: boolean;
};

export type SavedOAuthImport = SavedImportBase;
export type SavedKeyImport = SavedImportBase & {
  key: string;
};
export type SavedWalletImport = SavedImportBase & {
  blockchain: Blockchain;
  address: string;
  importSource: string | null;
};

export function isSavedChainImport(s: SavedImport): s is SavedWalletImport {
  const key: keyof SavedWalletImport = "address";
  const key2: keyof SavedWalletImport = "blockchain";
  return key in s && key2 in s;
}

export type SavedImport =
  | SavedFileImport
  | SavedOAuthImport
  | SavedKeyImport
  | SavedWalletImport;

export type SyncError = {
  status: number; // Our status code for error lookup
  message: string; // message to show to the user
};

export type SavedImportSync = {
  syncId: string;
  requestedAt: string;
  completedAt?: string;
  startedAt?: string;
  queuedAt?: string;
  importType: ImportType;
  importedTxCount: number | undefined;
  importId: string;
  status: SyncStatusPlatform;
  name: string;
  isHardSync: boolean;
};

export type SavedImportOptionByAccount = {
  id: string; // exchange enum name or blockchain address
  name: string; // name of integration or nickname of account
  category: IntegrationCategory;
  assets: CurrencyIdentifier[];
  value: number;
  totalTxCount: number | undefined;
  spamTxCount: number | undefined;
  lastSyncComplete: string | null; // null if no successful syncs
  lastImportedTxTimestamp: Date | null;
  /**
   * The current sync status of the import.
   * If the import has never been synced, this will be undefined.
   */
  syncStatus: SyncStatusPlatform | undefined;
  files: SavedFileImport[];
  keys: SavedKeyImport[];
  oauths: SavedOAuthImport[];
  wallets: SavedWalletImport[];
  availableImportMethods: ImportMethod[];
};

export type SavedImportByIntegration = {
  id: string;
  name: string;
  category: IntegrationCategory;
  files: SavedFileImport[];
  keys: SavedKeyImport[];
  oauths: SavedOAuthImport[];
  wallets: SavedWalletImport[];
};

export type SavedImportType<T> = T extends Map<any, infer V> ? V : never;

export type AllSavedImports = {
  all: SavedImportOptionByAccount[];
  /** used for the "integrations page" where we may want to group imports by different methods. Currently key is account id (address for address view, blockchain for blockchain view) */
  importsByAccountMap: Record<string, SavedImportOptionByAccount>;
  /** used for the "add integration" screen where we want to know what data has been uploaded to a specific integration */
  importsByIntegration: Record<string, SavedImportByIntegration>;
};

export function isCustomImport(
  importOption:
    | SavedImportOptionByAccount
    | SavedImportByIntegration
    | ImportOptionV2,
): boolean {
  return (
    importOption.category === IntegrationCategory.Manual ||
    !!(importOption as ImportOptionV2)?.manual
  );
}

export function isBlockchainImport(
  importOption: SavedImportOptionByAccount | ImportOptionV2,
) {
  return importOption.category === IntegrationCategory.Blockchain;
}

export type GroupedCurrencyIdentifier = CurrencyIdentifier & {
  imageURLs: string[] | undefined;
  nftIds?: string[];
};

export type PricedAccountHoldings = {
  accountId: string;
  assets: GroupedCurrencyIdentifier[];
  value: number;
  status: SyncStatusPlatform | undefined;
  lastSyncComplete: Date | undefined;
  lastSyncSuccess: Date | undefined;
  referenceCurrency: CurrencyIdentifier | null;
  // Info needed to display the overall account summary
  holdings: (PricedHoldingsDetails & {
    currencyIdentifier: GroupedCurrencyIdentifier;
    referencePrice: number | null;
    referenceCurrency: CurrencyIdentifier | null;
  })[];
};

// Info needed for the per-import summary.
export type PricedEntityHoldings = {
  accountViewKey: string;
  blockchainViewKey: string;
  entityId: string;
  exchangeOrBlockchainAddressHash: string; // The exchange or the address__blockchainHash
  // Info needed to display the account on the imports table
  value: number;
  status: SyncStatusPlatform | undefined;
  lastSyncComplete: Date | undefined;
  lastSyncSuccess: Date | undefined;
  referenceCurrency: CurrencyIdentifier | null;
  // Info needed to display the overall account summary
  holdings: (PricedHoldingsDetails & {
    currencyIdentifier: GroupedCurrencyIdentifier;
    referencePrice: number | null;
  })[];
};

export type AccountSummary = {
  accountId: string;
  txCount: number | null;
  tradeVolume: number | null;
  feeVolume: number | null;
  accountHoldings?: PricedAccountHoldings;
  entitySummaries: Record<string, EntitySummary>; // key is exchange or blockchainAddressHash
};

export type EntitySummary = {
  exchangeOrBlockchainAddressHash: string;
  txCount: number | null;
  tradeVolume: number | null;
  feeVolume: number | null;
  holdings: PricedEntityHoldings | null;
  accountBalancePercentage: number;
};

export const syncableERPStatuses = [
  ErpSyncStatus.SyncNotRequested,
  ErpSyncStatus.SyncFailed,
  ErpSyncStatus.SyncOutOfDate,
];

// Note: This is taken from evmIntegrations object in core
export const ChainIdToBlockchain: Record<string, Blockchain | undefined> = {
  "81457": Blockchain.Blast,
  "7000": Blockchain.ZetaChain,
  "42161": Blockchain.ARB,
  "42170": Blockchain.ArbitrumNova,
  "1313161554": Blockchain.Aurora,
  "43114": Blockchain.Avalanche,
  "8453": Blockchain.Base,
  "199": Blockchain.BitTorrentChain,
  "288": Blockchain.Boba,
  "56": Blockchain.BSC,
  "7700": Blockchain.Canto,
  "42220": Blockchain.Celo,
  "1024": Blockchain.CLVChain,
  "25": Blockchain.Cronos,
  "1": Blockchain.ETH,
  "250": Blockchain.Fantom,
  "2222": Blockchain.Kava,
  "1088": Blockchain.Metis,
  "1284": Blockchain.Moonbeam,
  "1285": Blockchain.Moonriver,
  "10": Blockchain.OPT,
  "137": Blockchain.Polygon,
  "369": Blockchain.PulseChain,
  "100": Blockchain.Xdai,
  "59144": Blockchain.Linea,
  "7777777": Blockchain.Zora,
  "5000": Blockchain.Mantle,
  "324": Blockchain.ZkSync,
  "1101": Blockchain.PolygonZkEvm,
  "534352": Blockchain.Scroll,
  "169": Blockchain.MantaPacific,
  "61": Blockchain.EthereumClassic,
  "13371": Blockchain.Immutable,
  "14": Blockchain.Flare,
  "106": Blockchain.Velas,
  "167000": Blockchain.Taiko,
  "34443": Blockchain.Mode,
  "146": Blockchain.Sonic,
};

export function getChainIdByBlockchain(
  blockchain: Blockchain,
): string | undefined {
  return Object.keys(ChainIdToBlockchain).find(
    (key) => ChainIdToBlockchain[key] === blockchain,
  );
}

export const ruleOperationToErpKey = {
  [BulkOperations.ChangeErpAssetAccount]: ERPAccountType.Asset,
  [BulkOperations.ChangeErpCashAccount]: ERPAccountType.Cash,
  [BulkOperations.ChangeErpGainsAccount]: ERPAccountType.Gain,
  [BulkOperations.ChangeErpPnLAccount]: ERPAccountType.PL,
  [BulkOperations.ChangeErpLoanAccount]: ERPAccountType.Loan,
};

export type ToastAction = {
  label: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  actionButtonStyle?: React.CSSProperties;
};

export type DisplayMessageDetails = {
  message: string | Exclude<ReactNode, undefined | null>;
  type: DisplayMessage;
  id?: number | string;
  action?: ToastAction | React.ReactNode;
};

export type ImportTypeInstruction = {
  title?: string;
  link?: string;
  items?: string[];
};

export type ImportTypeInstructionWithImages = {
  title?: string;
  link?: string;
  items?:
    | {
        text: string;
        fileName?: string;
      }[]
    | string[];
};

export type ImportTypeInstructions = {
  step?: ImportTypeInstruction[];
  video?: ImportTypeInstruction[];
  note?: ImportTypeInstruction[];
  critical?: ImportTypeInstruction[];
};

export type ImportTypeInstructionsWithImages = {
  step?: ImportTypeInstructionWithImages[];
  video?: ImportTypeInstruction[];
  note?: ImportTypeInstruction[];
  critical?: ImportTypeInstruction[];
};

export type IntegrationModalSavedIntegrationWallet = {
  type: ImportMethod.Wallet | ImportMethod.ConnectWallet;
  addresses: string[];
  blockchain: Blockchain;
  name?: string | undefined;
  importFromTimeMillis?: number;
  importSource?: string;
};

export type IntegrationModalSavedIntegrationApi = {
  type: ImportMethod.API;
};

export type IntegrationModalSavedIntegrationCsv = {
  type: ImportMethod.CSV;
};

export type IntegrationModalSavedIntegration =
  | IntegrationModalSavedIntegrationWallet
  | IntegrationModalSavedIntegrationApi
  | IntegrationModalSavedIntegrationCsv;

// TODO: Move AuthMethod to the types package.
export type AuthMethod =
  | {
      type: AuthMethodType.Password;
    }
  | {
      type: AuthMethodType.Oauth;
      provider: OAuthProvider;
    };

export type SuspectedMissingImport = {
  importOption: ImportOptionV2;
  issue: SuspectedMissingImportIssue;
};

/**
 * The currencies that we are able to bill users in.
 */
export const BILLABLE_CURRENCIES = [
  LocalCurrency.AUD,
  LocalCurrency.CAD,
  LocalCurrency.CHF,
  LocalCurrency.EUR,
  LocalCurrency.GBP,
  LocalCurrency.INR,
  LocalCurrency.NZD,
  LocalCurrency.USD,
] as const;

export type BillableCurrency = (typeof BILLABLE_CURRENCIES)[number];

/**
 * Response from the CSV download endpoint
 */
export type CsvDownloadResponse = {
  /** The signed URL for downloading the file */
  url: string;
  /** The original filename of the CSV */
  filename: string;
};
