import { Logout } from "@mui/icons-material";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { useAccountItems } from "~/components/nav/hooks/useAccountItems";
import { useNavTabs } from "~/components/nav/hooks/useNavTabs";
import { SideNav } from "~/components/nav/SideNav";
import { TabNav } from "~/components/nav/TabNav";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useIsEnterprise, useLogout } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useGetSavedAccounts } from "~/state/importsV2";

export type ActionItemDetails = {
  name: string;
  action: any;
  icon?: React.ReactNode;
};

export function Layout({ children }: React.PropsWithChildren) {
  const lang = useLang();
  const isEnterprise = useIsEnterprise();
  const savedImports = useGetSavedAccounts();
  const captureAnalytics = useCaptureAnalytics();
  const logout = useLogout();
  const stepItems = useNavTabs();
  const accountItems = useAccountItems();
  const isEmbedded = useIsEmbedded();

  const logoutItem: ActionItemDetails = {
    name: lang.nav.logout,
    action: () => {
      logout();
      captureAnalytics("logout_clicked");
    },
    icon: <Logout fontSize="small" />,
  };

  if (isEmbedded) {
    return <TabNav>{children}</TabNav>;
  }

  return (
    <SideNav
      stepItems={stepItems}
      accountItems={accountItems}
      logoutItem={logoutItem}
      isEnterprise={isEnterprise}
      hasImportedData={savedImports.length > 0}
    >
      {children}
    </SideNav>
  );
}
