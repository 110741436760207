import { SyncStatusPlatform } from "@ctc/types";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useFeatureFlag } from "~/analytics/posthog";
import { displayMessage } from "~/components/ui/Toaster";
import { type FinancialYear } from "~/contexts/FYContext";
import { invariant } from "~/lib/invariant";
import { useTimezone } from "~/redux/report";
import { HttpError } from "~/services/core";
import * as reportApi from "~/services/report";
import { type ReportParams } from "~/services/report";
import { getFilteredTxReportSyncType, useSetSyncMutation } from "~/state/sync";
import { DisplayMessage } from "~/types/enums";
import {
  FeatureFlag,
  type HoldingsBalanceType,
  type NormalReportType,
  type PortfolioTimeframe,
  type Sort,
} from "~/types/enums";
import { type FilterQuery } from "~/types/index";

export const reportKeys = {
  all: () => ["report"] as const,
  matrix: ({ timeframe }: { timeframe: PortfolioTimeframe }) =>
    [...reportKeys.all(), "matrix", timeframe] as const,
  fiatSummary: () => [...reportKeys.all(), "fiatSummary"] as const,
  treasurySummary: (query: { startTs: number; endTs: number }) =>
    [...reportKeys.all(), "treasurySummary", query] as const,
  reportSummaryData: (timeframe?: FinancialYear) => [
    ...reportKeys.all(),
    "reportSummary",
    timeframe,
  ],
  reportTableContent: (reportType: NormalReportType, params: ReportParams) =>
    [...reportKeys.all(), "query", reportType, params] as const,
  reportTableTotalCount: (
    reportType: NormalReportType,
    query: ReportParams,
    timeframe?: FinancialYear,
  ) =>
    [
      ...reportKeys.all(),
      "query",
      reportType,
      query,
      timeframe,
      "count",
    ] as const,
};

export const useGetPortfolioMatrixQuery = (
  timeframe: PortfolioTimeframe,
  type: HoldingsBalanceType,
  enabled: boolean,
) => {
  const featureFlagEnabled = useFeatureFlag(FeatureFlag.Chartmander);

  return useQuery({
    queryKey: reportKeys.matrix({ timeframe }),
    queryFn: async () => {
      const res = await reportApi.getPorfolioMatrix(timeframe, type);

      if (res.error) {
        const keys = reportKeys.matrix({ timeframe });
        throw new HttpError(res, Array.from(keys));
      }

      return res.data;
    },
    staleTime: Infinity,
    enabled: featureFlagEnabled && !!timeframe && !!type && enabled,
  });
};

export const useGetFiatSummary = () => {
  return useQuery({
    queryKey: reportKeys.fiatSummary(),
    queryFn: async () => {
      const res = await reportApi.getFiatSummary();

      if (res.error) {
        const keys = reportKeys.fiatSummary();
        throw new HttpError(res, Array.from(keys));
      }

      return res.data;
    },
  });
};

export const useGetTreasurySummary = (data: {
  startDate: Date;
  endDate: Date;
}) => {
  return useQuery({
    queryKey: reportKeys.treasurySummary({
      startTs: data.startDate.valueOf(),
      endTs: data.endDate.valueOf(),
    }),
    queryFn: async () => {
      const res = await reportApi.getTreasurySummary(data);

      if (res.error) {
        throw new HttpError(res, ["treasurySummary"]);
      }

      return res.data;
    },
  });
};

export const useGetReportTable = (
  reportType: NormalReportType,
  params: ReportParams,
) => {
  return useQuery({
    queryKey: reportKeys.reportTableContent(reportType, params),
    queryFn: async () => {
      const res = await reportApi.getReportTable(reportType, params);
      if (res.error) {
        throw new HttpError(res, ["getReportTable"]);
      }

      return res.data;
    },
    staleTime: Infinity,
  });
};

export const useGetReportSummary = (timeframe?: FinancialYear) => {
  return useQuery({
    queryKey: reportKeys.reportSummaryData(timeframe),
    queryFn: async () => {
      invariant(timeframe, "Timeframe is required");
      const res = await reportApi.getReportSummary(timeframe);
      if (res.error) {
        throw new HttpError(res, ["getReportSummary"]);
      }

      return res.data;
    },
    staleTime: Infinity,
    enabled: !!timeframe,
  });
};

export const useGetReportTableCount = (
  reportType: NormalReportType,
  params: Pick<ReportParams, "tradeFilter" | "categoryFilter">,
  timeframe?: FinancialYear,
) => {
  return useQuery({
    queryKey: reportKeys.reportTableTotalCount(reportType, params, timeframe),
    queryFn: async () => {
      if (!timeframe) {
        return 0;
      }
      const res = await reportApi.getReportTotalCount(
        reportType,
        timeframe as FinancialYear,
        params,
      );
      if (res.error) {
        throw new HttpError(res, ["getReportCount"]);
      }

      return res.data;
    },
    staleTime: Infinity,
  });
};

export const useDownloadFilteredTxReportCSVMutation = () => {
  const timezone = useTimezone();
  const syncSet = useSetSyncMutation();
  const scope = getFilteredTxReportSyncType();
  return useMutation({
    mutationFn: async ({
      filter,
      count,
      sort,
      page,
    }: {
      filter?: FilterQuery;
      count: number;
      sort: Sort;
      page: number;
    }) => {
      syncSet.mutate({ scope, status: SyncStatusPlatform.Pending });
      const res = await reportApi.generateFilteredTxReport({
        filter,
        count,
        sort,
        page,
        timezone,
      });

      if (res.error) {
        displayMessage({
          type: DisplayMessage.Error,
          message: res.message || "",
        });
      }
      return;
    },
  });
};
