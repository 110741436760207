import { Box } from "@mui/material";
import { css } from "styled-components";
import styled from "styled-components/macro";

import { ActionRowHover } from "~/components/transactions/action-row/ActionRowHover";

export const Overflower = styled.div.attrs(() => ({
  className: "overflower",
}))`
  overflow: hidden;
  max-width: 100%;
  position: relative;
  padding-right: 20px;
  white-space: nowrap;
  line-height: 1rem;

  &:hover {
    overflow: visible;
    max-width: unset;
    z-index: 2;
    padding-right: 0;
  }

  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 20px;
  }
  &:hover:before {
    display: none;
  }
`;

export const ActionRowHoverStyled = styled(({ ...props }) => (
  <ActionRowHover {...props} />
))`
  min-height: 1.25rem;
  line-height: 1rem;
  padding: 0 0.25rem;

  &:hover {
    .action-row-account__account {
      color: ${(props) => props.theme.tokens.text.high};
    }
  }
  .action-row-account__account {
    color: ${(props) => props.theme.tokens.text.low};
  }
  .action-row-account__actions {
    visibility: hidden;
  }

  &:hover .action-row-account__actions {
    visibility: visible;
  }
`;

export const ActionRowFirstLineStyle = css`
  min-height: 1.75rem;
  display: flex;
  align-items: center;
`;

export const ActionRowSecondLineStyle = css`
  min-height: 1.25rem;
  display: flex;
  align-items: center;
  line-height: 1.25rem;
`;

export const ActionRowFirstLineBox = styled(Box)`
  ${ActionRowFirstLineStyle}
`;

export const ActionRowSecondLineBox = styled(Box)`
  ${ActionRowSecondLineStyle}
`;
