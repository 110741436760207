import { Box, Typography } from "@mui/material";

import { Calco } from "~/components/ui/calco/Calco";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function ReferAFriendLogo() {
  const { tokens } = useDesign();
  const lang = useLang();
  return (
    <Box display="flex" gap="0.5rem" alignItems="center">
      <Calco type="captain" />
      <Typography variant="Metropolis/Header/H4" color={tokens.text.high}>
        {lang.navbar.referAFriend}
      </Typography>
    </Box>
  );
}
