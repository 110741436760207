import { Box } from "@mui/material";
import styled from "styled-components/macro";

export const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.tokens.elevation.default};
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  border-radius: 0.5rem;
  display: flex;
  width: 25rem;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
`;
