import { useCallback, useEffect, useRef, useState } from "react";

export function useHover<T extends HTMLElement = HTMLDivElement>() {
  const [value, setValue] = useState(false);
  const ref = useRef<T>(null);

  const handleMouse = useCallback(() => {
    const node = ref.current;
    if (node && node.matches(":hover")) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [ref]);

  useEffect(
    () => {
      const node = ref.current;

      if (node) {
        // Debounce helps smooth on/off toggle when hovering over child elems.
        node.addEventListener("mouseenter", handleMouse);
        node.addEventListener("mouseleave", handleMouse);
        return () => {
          node.removeEventListener("mouseenter", handleMouse);
          node.removeEventListener("mouseleave", handleMouse);
        };
      }
    },
    [ref, handleMouse], // Recall only if ref changes
  );

  return { ref, value };
}
