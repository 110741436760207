import { Box, Grid } from "@mui/material";
import {
  type Control,
  Controller,
  type UseFormSetValue,
} from "react-hook-form";

import { isFeatureEnabled } from "~/analytics/posthog";
import { AdvancedImportOptions } from "~/components/imports/advancedImportOptions";
import { DateFormatOverride } from "~/components/imports/DateFormatOverride";
import { ImportFromDateOverride } from "~/components/imports/ImportFromDateOverride";
import { TickerOverride } from "~/components/imports/TickerOverride";
import { TimezoneOverride } from "~/components/imports/TimezoneOverride";
import { FeatureFlag, ImportMethod } from "~/types/enums";
import { type CSVFormData, type ImportOptionV2 } from "~/types/index";

export const AdvancedCSVOptions = ({
  importOption,
  control,
  setValue,
}: {
  importOption: ImportOptionV2;
  control: Control<CSVFormData, any>;
  setValue: UseFormSetValue<CSVFormData>;
}) => {
  const isImportFromDateEnabled = isFeatureEnabled(FeatureFlag.ImportFromDate);

  return (
    <AdvancedImportOptions
      importOption={importOption}
      importMethod={ImportMethod.CSV}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mt="1rem">
            <Controller
              control={control}
              name="currencies"
              render={({ field: { value } }) => (
                <TickerOverride
                  value={value}
                  setValue={(value) => {
                    setValue("currencies", value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                />
              )}
            />
          </Box>
          <Box mt="1rem">
            <Controller
              control={control}
              name="dateFormat"
              render={({ field: { value } }) => (
                <DateFormatOverride
                  value={value}
                  setValue={(value) => {
                    setValue("dateFormat", value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                />
              )}
            />
          </Box>
          <Box mt="1rem">
            <Controller
              control={control}
              name="timezone"
              render={({ field: { value } }) => (
                <TimezoneOverride
                  value={value}
                  setValue={(value) => {
                    setValue("timezone", value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                />
              )}
            />
          </Box>
          {isImportFromDateEnabled && (
            <Box mt="1rem">
              <Controller
                name="importFromDate"
                control={control}
                render={({ field: { value } }) => (
                  <ImportFromDateOverride
                    value={value ?? null}
                    setValue={(value) => {
                      setValue("importFromDate", value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </AdvancedImportOptions>
  );
};
