import { SyncStatusPlatform } from "@ctc/types";
import { ArrowForward, InfoOutlined } from "@mui/icons-material";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import { useContext } from "react";

import { AnalyticEvent } from "~/analytics/analyticsProperties";
import { useCaptureAnalyticEvents } from "~/analytics/captureAnalyticEvents";
import { useTaxMinimisationExperimentCopy } from "~/components/report/components/summary-box/helpers/useTaxMinimisationExperimentCopy";
import { TextButtonLink } from "~/components/ui/ui-buttons/TextButton";
import { PaywallModalType } from "~/contexts/paywall-modal-context/enums";
import { PaywallModalContext } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib/index";
import { useHighestRankingPlanUser, useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useReconciliationLink } from "~/state/reconciliation";
import { useUserSyncStatus } from "~/state/sync";
import { Features } from "~/types/enums";
import { shouldReplaceCGTWithPortfolioValue } from "~/types/index";

export const TipsBanner = ({
  isLoading,
  savingsOpportunityValue,
}: {
  isLoading: boolean;
  savingsOpportunityValue: number;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const taxMinimisationCopy = useTaxMinimisationExperimentCopy();

  const highestRankingPlanUser = useHighestRankingPlanUser();
  const syncStatus = useUserSyncStatus();
  const isReportRefreshing = syncStatus === SyncStatusPlatform.Pending;
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();

  const hideSavingsOpportunity = shouldReplaceCGTWithPortfolioValue(
    highestRankingPlanUser?.country,
  );

  return (
    <Box
      bgcolor={tokens.elevation.medium}
      border={`1px solid ${tokens.border.brand}`}
      borderRadius="0.25rem"
      p="0.5rem 0.5rem 0.5rem 1rem"
      display="flex"
      flexDirection={hideSavingsOpportunity ? "row" : "column"}
      mb="0.5rem"
      gap="0.25rem"
      justifyContent="space-between"
    >
      <Box display="flex">
        <Typography variant="Metropolis/Header/H5" color={tokens.text.brand}>
          {lang.report.summary.reviewTips.title}
        </Typography>
      </Box>
      {hideSavingsOpportunity ? (
        <ReviewButton />
      ) : (
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box
            display="flex"
            justifyContent="space-between"
            gap="0.5rem"
            alignItems="center"
          >
            <Typography variant="Metropolis/Header/H5" color={tokens.text.default}>
              {typeof taxMinimisationCopy === "string"
                ? taxMinimisationCopy
                : taxMinimisationCopy.prefix}
              <span
                style={{
                  fontFamily: tokens.fontFamilies.numeric,
                  color: tokens.text.success,
                }}
              >
                {isLoading || isReportRefreshing
                  ? null
                  : displayFiatValue({
                      value: savingsOpportunityValue,
                      localCurrency,
                      locale: languagePreference,
                    })}
              </span>
              {typeof taxMinimisationCopy === "object"
                ? taxMinimisationCopy.suffix
                : null}
            </Typography>
            {isLoading || isReportRefreshing ? (
              <Skeleton sx={{ width: "3rem" }} />
            ) : null}
            <Tooltip
              placement="top"
              title={lang.report.summary.reviewTips.tooltip}
            >
              <Box display="flex">
                <InfoOutlined
                  style={{ color: tokens.text.low, fontSize: "1rem" }}
                />
              </Box>
            </Tooltip>
          </Box>

          <ReviewButton />
        </Box>
      )}
    </Box>
  );
};

const ReviewButton = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const reconciliationLink = useReconciliationLink();
  const paywallContext = useContext(PaywallModalContext);
  const bestUser = useHighestRankingPlanUser();
  const captureAnalyticsEvent = useCaptureAnalyticEvents();

  const canSeeTips = bestUser?.features[Features.Tips];
  return (
    <TextButtonLink
      size="small"
      endIcon={<ArrowForward style={{ fontSize: "1rem" }} />}
      to={canSeeTips ? reconciliationLink : "#"}
      onClick={() => {
        captureAnalyticsEvent(
          AnalyticEvent.REPORT_PAGE.REVIEW_TRANSACTIONS_TO_SAVE_CLICKED,
        );
        if (!canSeeTips) {
          paywallContext?.setSelectedModal(PaywallModalType.Tips);
        }
      }}
      sx={{
        padding: "0.38rem 0.75rem",
      }}
    >
      <Typography variant="Metropolis/Caption/Medium/Regular" color={tokens.text.brand}>
        {lang.report.summary.reviewTips.button}
      </Typography>
    </TextButtonLink>
  );
};
