import { Box, type BoxProps } from "@mui/material";
import styled from "styled-components/macro";

import { useActionRow } from "~/contexts/ActionRowContext";

const ActionRowHoverBox = styled(
  ({ disabled, ...props }: BoxProps & { disabled?: boolean }) => (
    <Box {...props} />
  ),
)`
  padding: 0.25rem;
  border-radius: 4px;
  ${(props) => (props.disabled ? "" : "cursor: pointer;")}
  display: inline-flex;
  align-items: center;

  &:hover {
    background: ${(props) =>
      props.disabled
        ? props.theme.tokens.background.disabled
        : props.theme.tokens.background.brand.hover};
  }
`;

export const ActionRowHover = (props: BoxProps) => {
  const { isLocked: disabled } = useActionRow();
  const { onClick, children } = props;

  return (
    <ActionRowHoverBox
      disabled={disabled}
      {...props}
      onClick={
        disabled
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();
            }
          : onClick
      }
    >
      {children}
    </ActionRowHoverBox>
  );
};
