import { Stack, Typography } from "@mui/material";

import { TradeIcons } from "~/components/ui/TradeIcons";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { useLang } from "~/redux/lang";
import { type ActionType } from "~/types/index";

export const TradeTypeTitle = ({ trade }: { trade: ActionType }) => {
  const lang = useLang();
  const Icon = TradeIcons[trade];

  return (
    <Stack direction="row" alignItems="center" gap="0.5rem">
      <Stack
        alignItems="center"
        justifyContent="center"
        height="1.5rem"
        width="1.5rem"
        flexShrink={0}
      >
        <Icon />
      </Stack>

      <Typography variant="Metropolis/Caption/Large/Bold">
        {getActionTypeName({ actionType: trade, lang })}
      </Typography>
    </Stack>
  );
};
