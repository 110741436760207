import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { Subheading } from "~/components/settings-modal/views/profile/Profile";
import {
  GeneralDialog,
  type GeneralDialogProps,
} from "~/components/ui/GeneralDialog";
import { useIsMobile } from "~/components/ui/hooks";
import { PasswordTextField } from "~/components/ui/PasswordTextField";
import { Spinner } from "~/components/ui/Spinner";
import { displayMessage } from "~/components/ui/Toaster";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { AlertWarningIcon } from "~/components/user/SignupForm";
import { useDesign } from "~/hooks/useTheme";
import { passwordChecker } from "~/lib/passwordChecker";
import {
  setUpdatingPassword,
  useIsUpdatingPassword,
  useUser,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import * as userApi from "~/services/user";
import { DisplayMessage } from "~/types/enums";

export function UpdatePassword() {
  const dispatch = useDispatch();
  const lang = useLang();
  const isUpdatingPassword = useIsUpdatingPassword();
  const user = useUser();
  const [open, setOpen] = React.useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = React.useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const isMobile = useIsMobile();

  if (!user) return null;
  const is2faEnabled = !!user.is2faEnabled;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setCode("");
    setPasswordError("");
    setConfirmError("");
    setOpen(false);
  };

  const handleNewPassword = (newPassword: string) => {
    setNewPassword(newPassword);

    const validPassword = passwordChecker(newPassword);

    if (validPassword.error) {
      setPasswordError(lang.password[validPassword.msg]);
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);

    if (confirmPassword && confirmPassword !== newPassword) {
      setConfirmError(lang.settings.profile.password.dontMatch);
    } else {
      setConfirmError("");
    }
  };

  const handleCode = (code: string) => {
    setCode(code);
  };

  const handleSubmit = async () => {
    dispatch(setUpdatingPassword(true));
    const authLang = lang.auth;
    const res = await userApi.updatePassword({
      oldPassword,
      newPassword,
      code,
    });
    dispatch(setUpdatingPassword(false));
    if (!res.error) {
      handleClose();
      displayMessage({
        message: authLang.passwordUpdatedSuccessfully,
        type: DisplayMessage.Success,
      });
    } else {
      displayMessage({
        message: authLang.passwordResetFailed,
        type: DisplayMessage.Error,
      });
    }
  };

  return (
    <>
      <ResetPasswordDialog
        text={lang.settings.profile.password.resetPassword}
        title={lang.settings.profile.password.changePassword}
        actionText={lang.settings.profile.password.saveNewPassword}
        isOpen={open}
        passwordError={passwordError}
        confirmError={confirmError}
        setOldPassword={setOldPassword}
        handleNewPassword={handleNewPassword}
        handleConfirmPassword={handleConfirmPassword}
        handleCode={handleCode}
        handleClose={handleClose}
        handleAction={handleSubmit}
        isSubmitting={isUpdatingPassword}
        is2faEnabled={is2faEnabled}
        disableButton={
          !oldPassword ||
          !newPassword ||
          !confirmPassword ||
          !!passwordError ||
          !!confirmError ||
          (is2faEnabled && !code)
        }
      />
      <Box display="flex" flexDirection="column" mt="0.5rem">
        <Typography variant="Metropolis/Body/Light" display="inline">
          <Subheading>
            {lang.settings.profile.password.accountPassword}
          </Subheading>
        </Typography>
        <TertiaryButton
          variant="outlined"
          size="small"
          onClick={handleOpen}
          disabled={isUpdatingPassword}
          startIcon={
            isUpdatingPassword ? <CircularProgress size="1.125rem" /> : null
          }
          sx={{
            width: isMobile
              ? "100%"
              : isUpdatingPassword
                ? "10.625rem"
                : "9.688rem",
            marginTop: "0.25rem",
          }}
        >
          {lang.settings.profile.password.changePassword}
        </TertiaryButton>
      </Box>
    </>
  );
}

const ResetPasswordDialog = ({
  text,
  passwordError,
  confirmError,
  setOldPassword,
  handleNewPassword,
  handleConfirmPassword,
  handleCode,
  is2faEnabled,
  disableButton = false,
  isSubmitting,
  ...rest
}: {
  text: string;
  passwordError: string;
  confirmError: string;
  setOldPassword: (value: string) => void;
  handleNewPassword: (value: string) => void;
  handleConfirmPassword: (value: string) => void;
  handleCode: (value: string) => void;
  isSubmitting: boolean;
  is2faEnabled: boolean;
  disableButton?: boolean;
} & Omit<GeneralDialogProps, "children">) => {
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <GeneralDialog
      fullWidth
      disableAction={disableButton}
      closeOnClickAway
      {...rest}
    >
      {isSubmitting && <Spinner />}
      <Box width="100%">
        <form>
          <Box display="flex" flexDirection="column" gap="1rem" mt="0.5rem">
            <PasswordTextField
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              label={lang.settings.profile.password.oldPassword}
            />
            <PasswordTextField
              onChange={(e) => {
                handleNewPassword(e.target.value);
              }}
              label={lang.settings.profile.password.newPassword}
              error={!!passwordError}
            />
            {passwordError && (
              <Box display="flex" alignItems="center">
                <AlertWarningIcon />
                <Typography
                  color={tokens.text.danger}
                  variant="Metropolis/Caption/Medium/Regular"
                  sx={{ marginTop: "0.125rerm" }}
                >
                  {passwordError}
                </Typography>
              </Box>
            )}
            <PasswordTextField
              onChange={(e) => {
                handleConfirmPassword(e.target.value);
              }}
              label={lang.settings.profile.password.confirmNewPassword}
              error={!!confirmError}
            />
            {confirmError && (
              <Box display="flex" alignItems="center">
                <AlertWarningIcon />
                <Typography
                  color={tokens.text.danger}
                  variant="Metropolis/Caption/Medium/Regular"
                  sx={{ marginTop: "0.125rerm" }}
                >
                  {confirmError}
                </Typography>
              </Box>
            )}
            {is2faEnabled && (
              <>
                <Typography
                  sx={{
                    marginTop: "1rem",
                  }}
                  variant="Metropolis/Body/Regular"
                >
                  {lang.twoFactorAuth.enterCodeToChangePassword}
                </Typography>
                <TextField
                  fullWidth
                  label={lang.twoFactorAuth.authenticationCode}
                  variant="outlined"
                  sx={{
                    marginTop: "1rem",
                  }}
                  // error={!!error}
                  helperText={
                    lang.twoFactorAuth.enterCodeOrRecoveryCodeHelperText
                  }
                  onChange={(e) => {
                    handleCode(e.target.value);
                  }}
                />
              </>
            )}
          </Box>
        </form>
      </Box>
    </GeneralDialog>
  );
};
