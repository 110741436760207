import { useCallback } from "react";

import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { getSelectedRows } from "~/components/transactions/command-palette/helpers/getSelectedRows";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useGetActionsQuery, useNewBulkOpMutation } from "~/state/actions";
import { BulkOperations } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

export function useBulkEditUngroup() {
  const bulkOpMutation = useNewBulkOpMutation();
  const onUngroupSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.Ungroup,
  });
  const onRegroupSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.AllowGrouping,
  });
  const onConfirm = useCallback(
    ({ ungroup, filter }: { ungroup: boolean; filter: FilterQuery }) => {
      bulkOpMutation.mutate(
        {
          filter,
          operation: {
            type: BulkOperations.Patch,
            patch: {
              dontGroup: ungroup,
            },
          },
        },
        {
          onSuccess: ungroup ? onUngroupSuccess : onRegroupSuccess,
        },
      );
    },
    [bulkOpMutation, onUngroupSuccess, onRegroupSuccess],
  );

  return { onConfirm, isLoading: bulkOpMutation.isPending };
}

export function useSelectedRowGroupStatus({ filter }: { filter: FilterQuery }) {
  const actionsQuery = useGetActionsQuery();
  const selectedRows = getSelectedRows({ filter, actionsQuery });

  const showAllowGroupingOption = selectedRows.some((action) =>
    [...action.incoming, ...action.outgoing].some((tx) => tx.dontGroup),
  );
  const showUngroupOption = selectedRows.some((action) =>
    [...action.incoming, ...action.outgoing].some((tx) => !tx.dontGroup),
  );

  return {
    showAllowGroupingOption,
    showUngroupOption,
  };
}
