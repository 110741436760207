import { BaseError as ViemError } from "viem";
import { BaseError as WagmiError } from "wagmi";

import { displayMessage } from "~/components/ui/Toaster";
import { store } from "~/redux/store";
import { HttpError } from "~/services/core";
import { DisplayMessage } from "~/types/enums";

// Global error handler for react query
// Shows the snackbar whenever an error occurs

export const queryRetryHandler = (failureCount: number, error: unknown) => {
  // 3 retries as `failureCount` is zero indexed.
  if (failureCount > 2) return false;

  if (error instanceof HttpError) {
    if (error.data.status === 502) {
      const count = failureCount + 1;

      console.info(`HttpError(Retry ${count}): ${error.log}`);
      return true;
    }
  }

  return false;
};

export const queryErrorHandler = (error: unknown) => {
  // wagmi/viem also use react-query for their hook mutations.
  // RainbowKit calls the mutations without exposing the onError and so any error comes up in the default handler
  // these are just users actions are already displayed on the login/signup page
  if (error instanceof ViemError || error instanceof WagmiError) {
    return;
  }
  if (error instanceof Error) {
    const errorMessage = error.message;
    const genericErrorMessage = store.getState().lang.map.errorMessageGeneric;
    displayMessage({
      message: errorMessage || genericErrorMessage,
      type: DisplayMessage.Error,
    });
  }
};
