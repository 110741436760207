import { Link as ExternalLink } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const StyledLink = styled(Link)`
  && {
    display: flex;
    align-items: center;

    text-decoration: none;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const StyledExternalLink = styled(ExternalLink)`
  && {
    display: flex;
    align-items: center;
    width: fit-content;

    text-decoration: none;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
