import { ArrowDropDown, Cached } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { useRef, useState } from "react";
import styled from "styled-components/macro";

import { accountingIntegrationsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { RuleDrawerById } from "~/components/rules/DisplayRulesDrawer";
import { useCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import {
  ModalProvider,
  useModal,
} from "~/components/transactions/command-palette/ModalProvider";
import { NavControllerProvider } from "~/components/transactions/command-palette/NavController";
import { SmallCommandPaletteMenu } from "~/components/transactions/command-palette/SmallCommandPaletteMenu";
import { SmallCommandPaletteModal } from "~/components/transactions/command-palette/SmallCommandPaletteModal";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { invariant } from "~/lib/invariant";
import { useErpLang, useLang } from "~/redux/lang";
import { type ErpAccount } from "~/redux/types";
import {
  useErpAvailableAccountsQuery,
  useErpSettingsQuery,
  useRefreshAccountListFromSourceMutation,
} from "~/state/erp";
import { type ERPAccountType, Size } from "~/types/enums";

type AccountSelectorProps = {
  title: string | undefined;
  tooltip: string | undefined;
  value: ErpAccount | null;
  handleChange: (e: any, value: ErpAccount | null) => void;
  size?: Size;
  disabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  error?: boolean;
  ruleId?: string;
  accountType?: ERPAccountType;
};

export function AccountSelector(props: AccountSelectorProps) {
  return (
    <NavControllerProvider>
      <ModalProvider variant="popover" popupId="filter-builder-selector-">
        <AccountSelectorInner {...props} />
      </ModalProvider>
    </NavControllerProvider>
  );
}

function RefreshAccountsButton() {
  const { tokens } = useDesign();
  const erpQuery = useErpSettingsQuery();
  const erp = erpQuery.data;
  const lang = useErpLang(erp?.erp);
  const refreshListMutation = useRefreshAccountListFromSourceMutation();

  return (
    <Tooltip title={lang.buttons.refreshList.done} placement="top">
      <TextIconButton
        size="small"
        disabled={refreshListMutation.isPending}
        onClick={() => {
          refreshListMutation.mutate();
        }}
        sx={{ fontSize: "1rem" }}
      >
        {refreshListMutation.isPending ? (
          <CircularProgress size="1rem" sx={{ color: tokens.text.disabled }} />
        ) : (
          <Cached sx={{ fontSize: "inherit", color: tokens.text.low }} />
        )}
      </TextIconButton>
    </Tooltip>
  );
}

function AccountSelectorMenu({
  value,
  handleChange,
  onClose,
}: {
  value: ErpAccount | null;
  handleChange: (e: any, value: ErpAccount | null) => void;
  onClose: () => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const erpAvailableAccountsQuery = useErpAvailableAccountsQuery();

  const analyticsKey = accountingIntegrationsAnalyticsKey("accounts");
  const captureAnalytics = useCaptureAnalytics();

  const erpQuery = useErpSettingsQuery();
  const erp = erpQuery.data;
  invariant(erp, "erp data not found");

  return (
    <SmallCommandPaletteMenu
      options={
        erpAvailableAccountsQuery.data?.map((account) => ({
          label: getLabelFromAccount(account),
          account,
        })) ?? []
      }
      selectedOptionLabel={value ? getLabelFromAccount(value) : undefined}
      placeholder={lang.txTable.expansionPanel.chartOfAccounts.dropdown}
      onSelection={({ account }) => {
        handleChange(undefined, account);
        captureAnalytics(analyticsKey("set account"), {
          erp: erp.erp,
        });
        onClose();
      }}
      searchAreaRightButton={<RefreshAccountsButton />}
      footerSection={
        <Typography color={tokens.text.low} variant="Metropolis/Caption/Medium/Regular">
          {lang.txTable.expansionPanel.chartOfAccounts.hiddenAccounts}
        </Typography>
      }
    />
  );
}

function AccountSelectorInner({
  title,
  tooltip,
  value,
  handleChange,
  disabled = false,
  error = false,
  ruleId,
  accountType,
  size = Size.Medium,
  isLoading = false,
}: AccountSelectorProps) {
  const modal = useModal();
  const lang = useLang();
  const { open, close } = useCommandPalette();
  const anchor = useRef<HTMLDivElement>(null);
  const [isRuleDrawerOpen, setIsRuleDrawerOpen] = useState(false);
  const { tokens } = useDesign();

  const erpQuery = useErpSettingsQuery();
  const erp = erpQuery.data;
  invariant(erp, "erp data not found");

  const root = (
    <AccountSelectorMenu
      value={value}
      handleChange={handleChange}
      onClose={close}
    />
  );

  return (
    <>
      {ruleId ? (
        <RuleDrawerById
          isOpen={isRuleDrawerOpen}
          setIsOpen={setIsRuleDrawerOpen}
          ruleIds={[ruleId]}
        />
      ) : null}

      <Stack gap="0.5rem">
        {title ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-left",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Typography variant="Metropolis/Header/H5">{title}</Typography>

            {tooltip ? (
              <Tooltip title={tooltip}>
                <InfoOutlinedIcon
                  style={{ color: tokens.text.low, marginLeft: "0.25rem" }}
                  fontSize="inherit"
                />
              </Tooltip>
            ) : null}
          </div>
        ) : null}

        <SelectorBox ref={anchor}>
          {accountType ? (
            <Box>
              <AccountType size={size}>
                {
                  lang.txTable.expansionPanel.chartOfAccounts.accountTypeLabel[
                    accountType
                  ]
                }
              </AccountType>
            </Box>
          ) : null}

          <Box width="100%" overflow="hidden">
            <CustomSelectorButton
              {...bindTrigger(modal)}
              size={size}
              disabled={disabled}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.currentTarget.blur();
                open(root, e);
              }}
              style={{
                justifyContent: "space-between",
                width: "100%",
                color: value ? tokens.text.default : tokens.text.low,
                boxShadow: "none",
              }}
              endIcon={
                isLoading ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <ArrowDropDown
                    sx={{
                      width: "2rem",
                      color: tokens.text.low,
                    }}
                  />
                )
              }
            >
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {value
                  ? getLabelFromAccount(value)
                  : lang.rules.operatorSelection.accountPlaceholder}
              </div>
            </CustomSelectorButton>
          </Box>
          {error ? (
            <Box>
              <Tooltip
                title={lang.txTable.expansionPanel.chartOfAccounts.accountNotFound(
                  { erp: erp.erp },
                )}
                placement="top"
              >
                <StyledIconButton
                  sx={{ cursor: "default" }}
                  disabled={disabled}
                  size={size}
                >
                  <WarningAmberIcon sx={{ color: tokens.text.warning }} />
                </StyledIconButton>
              </Tooltip>
            </Box>
          ) : null}
          {ruleId && !error ? (
            <Box>
              <StyledIconButton
                disabled={disabled}
                onClick={() => {
                  setIsRuleDrawerOpen(true);
                }}
                size={size}
              >
                <InsertLinkIcon sx={{ color: tokens.text.brand }} />
              </StyledIconButton>
            </Box>
          ) : null}
        </SelectorBox>
        <SmallCommandPaletteModal modal={modal} anchor={anchor} />
      </Stack>
    </>
  );
}

/**
 * Converts a ERP account to a label for the dropdown
 */
function getLabelFromAccount(account: ErpAccount): string {
  return `(${account.code}) ${account.name}`;
}

const AccountType = styled(TertiaryButton)`
  && {
    max-width: 2.8rem;
    min-width: 0;
    background: ${({ theme }) => theme.tokens.button.disabled};
    color: ${({ theme }) => theme.tokens.text.disabled};
    pointer-events: none;
  }
`;

const StyledIconButton = styled(TertiaryButton)`
  && {
    min-width: 0;
    padding: 0.25rem;
    height: 2.047rem;
  }
`;

const CustomSelectorButton = styled(TertiaryButton)`
  &.MuiButton-sizeSmall {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    min-width: 0;
  }
`;

const SelectorBox = styled(Box)`
  display: flex;
  align-items: center;

  // everything except the first and last
  & > div:not(:first-child):not(:last-child) .MuiButton-root {
    border-radius: 0;
  }
  // everything except the last
  & > div:not(:last-child) .MuiButton-root {
    border-right: none;
  }
  // The one that is next to the hovered button that is not disabled
  & > div:hover:not(:has(.Mui-disabled)) + div .MuiButton-root,
  // This is for the page selector that is focused
  & > div:has(.page-selector-focused) + div > .MuiButton-root {
    border-left: 1px ${({ theme }) => theme.tokens.border.neutral.highest} solid;
  }
  // the first one
  & > div:first-child .MuiButton-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  // the last one
  & > div:last-child .MuiButton-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // when there is only one child
  & > div:only-child .MuiButton-root {
    border-radius: 0.25rem;
  }
`;
