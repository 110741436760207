import { Box, Link, Tooltip, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { capitalizeName } from "~/components/report/helpers/capitalizeName";
import { Variation } from "~/components/settings-modal/views/enums";
import { DeleteAccountConfirmation } from "~/components/settings-modal/views/profile/DeleteAccountConfirmation";
import { ExitSurvey } from "~/components/settings-modal/views/profile/ExitSurvey";
import { TextConfirmationModal } from "~/components/ui/TextConfirmationModal";
import { displayMessage } from "~/components/ui/Toaster";
import { WarningButton } from "~/components/ui/ui-buttons/WarningButton";
import { useActiveClient } from "~/hooks/useActiveClient";
import { useDesign } from "~/hooks/useTheme";
import {
  useActiveUser,
  useIsClientManagingChildProfile,
  useIsDeleteAccountDisabled,
  useIsManagingClients,
  useUser,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import * as settingsAPI from "~/services/settings";
import { useResetSavedImports } from "~/state/importsV2";
import { useResetReconciliationPayload } from "~/state/reconciliation";
import { DisplayMessage } from "~/types/enums";

enum DeleteFlowState {
  None = "None",
  ExitSurvey = "ExitSurvey",
  Confirmation = "Confirmation",
}

export const DeleteAccount = () => {
  const { tokens } = useDesign();
  const user = useUser();
  const activeUser = useActiveUser();
  const isManagingClients = useIsManagingClients();
  const isDeleteAccountDisabled = useIsDeleteAccountDisabled();
  const lang = useLang();
  const captureAnalytics = useCaptureAnalytics();
  const isClientData = isManagingClients && user?.uid !== activeUser?.uid;
  const activeClient = useActiveClient();

  const [deleteFlowState, setDeleteFlowState] = useState<DeleteFlowState>(
    DeleteFlowState.None,
  );
  const resetDeleteFlowState = () => {
    setDeleteFlowState(DeleteFlowState.None);
  };
  const advanceDeleteFlowState = () => {
    if (deleteFlowState === DeleteFlowState.None) {
      // Accountants don't get the exit survey
      if (isClientData) {
        setDeleteFlowState(DeleteFlowState.Confirmation);
      } else {
        setDeleteFlowState(DeleteFlowState.ExitSurvey);
      }
    } else if (deleteFlowState === DeleteFlowState.ExitSurvey) {
      setDeleteFlowState(DeleteFlowState.Confirmation);
    }
  };

  const titleText = (() => {
    if (!isClientData) return lang.settings.manageData.titleSelf;

    if (activeUser && activeUser?.name) {
      return lang.settings.manageData.titleClient({
        clientName: capitalizeName(activeUser.name),
      });
    }
    return lang.settings.manageData.titleClientFallback;
  })();

  return (
    <>
      <ExitSurvey
        isOpen={deleteFlowState === DeleteFlowState.ExitSurvey}
        handleClose={resetDeleteFlowState}
        handleSubmit={advanceDeleteFlowState}
        variation={Variation.DeleteAccount}
      />
      <DeleteAccountConfirmation
        isOpen={deleteFlowState === DeleteFlowState.Confirmation}
        handleClose={resetDeleteFlowState}
        clientToDelete={activeClient}
      />

      <Box display="flex" flexDirection="column" gap="0.5rem">
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <Typography variant="Metropolis/Body/Regular">{titleText}</Typography>
          <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
            {lang.settings.manageData.description({
              isAccountant: isClientData,
            })}
          </Typography>
        </Box>
        <Box>
          <Tooltip
            title={
              isDeleteAccountDisabled ? lang.support.deleteAccountDisabled : ""
            }
          >
            <span>
              <WarningButton
                onClick={() => {
                  advanceDeleteFlowState();
                  captureAnalytics("close_account_clicked");
                }}
                disabled={isDeleteAccountDisabled}
              >
                {lang.settings.manageData.buttonText}
              </WarningButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

const ClearData = () => {
  const { tokens } = useDesign();
  const user = useUser();
  const activeUser = useActiveUser();
  const isManagingClients = useIsManagingClients();
  const isClientManagingChildProfile = useIsClientManagingChildProfile();
  const lang = useLang();
  const resetReconciliation = useResetReconciliationPayload();
  const resetSavedImports = useResetSavedImports();
  const captureAnalytics = useCaptureAnalytics();
  const [open, setOpen] = useState(false);

  const isClientData = isManagingClients && user?.uid !== activeUser?.uid;

  const clearDataMutation = useMutation({
    mutationFn: settingsAPI.deleteUserData,
    onSuccess: () => {
      resetReconciliation();
      resetSavedImports();
      displayMessage({
        message: lang.settings.dataCleared,
        type: DisplayMessage.Success,
      });
      setOpen(false);
      captureAnalytics("clear_data_success");
    },
    onError: () => {
      displayMessage({
        message: lang.settings.somethingWentWrongContact,
        type: DisplayMessage.Error,
      });
    },
  });

  const clearDataTitle = (() => {
    if (!isClientData) return lang.settings.clearData.titleSelf;

    if (activeUser && activeUser?.name) {
      return lang.settings.clearData.titleClient({
        clientName: capitalizeName(activeUser.name),
      });
    }
    return lang.settings.clearData.titleClientFallback;
  })();

  return (
    <Box mb={2} display="flex" flexDirection="column">
      <Box mb={1}>
        <Box>
          <Typography variant="Metropolis/Body/Regular">
            {clearDataTitle}
          </Typography>
          <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
            {lang.settings.clearData.description({
              isAccountant: isClientData,
            })}
          </Typography>
        </Box>
        <TextConfirmationModal
          isOpen={open}
          handleClose={() => {
            setOpen(false);
          }}
          handleSubmit={() => {
            clearDataMutation.mutate();
          }}
          title={clearDataTitle}
          actionText={lang.settings.manageData.clear}
          pending={clearDataMutation.isPending}
          critical
          text={lang.settings.clearData.text({ isAccountant: isClientData })}
          confirmationText={lang.settings.manageData.confirmClearData}
          confirmationPrompt={lang.settings.manageData.confirmClearDataPrompt}
        />
      </Box>
      <span>
        <WarningButton
          onClick={() => {
            setOpen(true);
            captureAnalytics("clear_data_clicked");
          }}
          disabled={isClientManagingChildProfile}
        >
          {lang.settings.clearData.buttonText}
        </WarningButton>
      </span>
    </Box>
  );
};

export const ManageData = () => {
  const activeClient = useActiveClient();
  const isMigratedAccountant = activeClient?.isDefaultAccount;
  const lang = useLang();
  return (
    <>
      {!isMigratedAccountant && <ClearData />}
      <DeleteAccount />
      <Box textAlign="center" mt={2}>
        <Typography variant="Metropolis/Caption/Medium/Regular">
          {lang.settings.privacy.part1}
          <Link
            href="https://cryptotaxcalculator.io/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {lang.settings.privacy.part2}
          </Link>
          .
        </Typography>
      </Box>
    </>
  );
};
