import { ReferrerSource } from "@ctc/types";
import { Box } from "@mui/material";
import qs from "query-string";

import { MetamaskOfferIntro } from "~/components/onboarding-v2/MetamaskOnboardingOfferIntro";
import { BootstrapQueryParameter } from "~/constants/enums";
import { BrandStyle } from "~/types/enums";

export function EmbeddedNonChromiumBlock() {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <MetamaskOfferIntro
        onConfirm={() => {
          const iframeUrl = window.location.origin;
          const queryParams = {
            referrerSource: ReferrerSource.MetamaskEmbedded,
            via: ReferrerSource.MetamaskEmbedded,
            [BootstrapQueryParameter.BrandStyles]: BrandStyle.MetaMask,
          };
          const queryString = qs.stringify(queryParams);
          const redirectUrl = `${iframeUrl}?${queryString}`;
          window.open(redirectUrl, "_blank");
        }}
      />
    </Box>
  );
}
