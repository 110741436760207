import {
  Autocomplete,
  type AutocompleteProps,
  TextField,
  type TextFieldProps,
} from "@mui/material";
import { matchSorter } from "match-sorter";
import {
  Controller,
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
} from "react-hook-form";

import { getErrorHelperText } from "~/components/contacts/getErrorHelperText";
import { useLang } from "~/redux/lang";

export function FormAutocompleteCreatable<
  TValue,
  // copied from UseControllerProps
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  // Autocomplete
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  label,
  options,
  disabled,
  loading,
  onInputChange,
  getAddOption = (inputValue: string) => inputValue as unknown as TValue,
  getOptionLabel = (value: TValue) => `${value}`,
  autoSelect,
  renderOption,
  onAfterChange,
  ...controllerProps
}: UseControllerProps<TFieldValues, TName> & {
  loading?: boolean;
  label: string;
  options: TValue[];
  onAfterChange?: (option: TValue | null) => void; // lets you trigger other stuff after the change happens
  getOptionLabel?: (value: TValue) => string;
  getAddOption?: (inputValue: string) => TValue;
  onInputChange?: (event: any, newInputValue: string) => void;
} & Pick<TextFieldProps, "disabled"> &
  Pick<
    AutocompleteProps<TValue, Multiple, DisableClearable, FreeSolo>,
    "autoSelect" | "renderOption"
  >) {
  const lang = useLang();
  return (
    <Controller
      {...controllerProps}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Autocomplete
            onInputChange={onInputChange}
            // creatable props
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            autoSelect={autoSelect}
            // rest
            loading={loading}
            getOptionLabel={getOptionLabel}
            autoHighlight // highlight the first option automatically
            value={value}
            multiple={false}
            disabled={disabled}
            renderOption={renderOption}
            onChange={(e, option) => {
              onChange(option);
              onAfterChange?.(option as TValue);
            }}
            options={options}
            filterOptions={(options, params) => {
              const { inputValue } = params;
              const filtered = matchSorter(options, inputValue, {
                keys: [getOptionLabel],
              });
              // https://mui.com/material-ui/react-autocomplete/#creatable
              // Suggest the creation of a new value
              // anytime there isn't an exact match
              const isExisting = options.some(
                (option) =>
                  inputValue.toLowerCase() ===
                  getOptionLabel(option).toLowerCase(),
              );
              if (!loading && inputValue !== "" && !isExisting) {
                const addOption = getAddOption(inputValue);
                filtered.push(addOption);
              }
              return filtered;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                helperText={getErrorHelperText(error, lang)}
                size="small"
                error={!!error}
                label={label}
                variant="outlined"
                style={{ margin: "0.5rem 0" }}
              />
            )}
          />
        );
      }}
    />
  );
}
