/**
 * @deprecated
 * This file contains hardcoded pricing records that were migrated from payment lang file.
 * It should not be extended or modified as we are moving towards dynamic pricing from Stripe.
 *
 * The pricing records and discount logic here are only used by the deprecated useUserDiscountDEPRECATED hook.
 * All new code should use the Stripe-based pricing and use the useApplyDiscount hook instead.
 *
 */

import { type Country, LocalCurrency, Plan } from "@ctc/types";

import { getBilledCurrencyForCountry } from "~/components/payment/helpers";
import {
  PriceAUD,
  PriceCAD,
  PriceEUR,
  PriceGBP,
  PriceINR,
  PriceNZD,
  PriceUSD,
} from "~/constants/enums";
import { useAuth } from "~/redux/auth";
import type { BillableCurrency } from "~/types";

/**
 * The pricing records are from the payment lang file.
 * They are used to get the prices for the given country.
 * The prices are hardcoded and not fetched from the server.
 */

const PlanPricesUSD: Record<Plan, number> = {
  [Plan.Free]: PriceUSD.Free,
  [Plan.Rookie]: PriceUSD.Rookie100,
  [Plan.Rookie_DEPRECATED]: PriceUSD.Rookie_DEPRECATED,
  [Plan.Hobbyist_DEPRECATED]: PriceUSD.Standard_DEPRECATED,
  [Plan.Hobbyist]: PriceUSD.Standard100,
  [Plan.Investor]: PriceUSD.Advanced,
  [Plan.Trader]: PriceUSD.Expert,
  [Plan.InvestorUs]: PriceUSD.Standard100,
  [Plan.InvestorUsPlus]: PriceUSD.InvestorUsPlus,
  [Plan.TraderUs]: PriceUSD.TraderUs,
  [Plan.TraderUsPlus]: PriceUSD.TraderUsPlus,
  [Plan.Accountant]: PriceUSD.Accountant,
  [Plan.Collaborator]: PriceUSD.Accountant,
  [Plan.Enterprise]: PriceUSD.Accountant,
  [Plan.Business]: PriceUSD.Business, // fetch from BE
  [Plan.BusinessPackage]: 0, // TBC
  [Plan.BusinessPro]: 0, // fetch from BE
  [Plan.BusinessStarter]: 0, // fetch from BE
  [Plan.BusinessTrial]: 0, // fetch from BE
  [Plan.Pro]: 0, // fetch from BE
};

const PlanPricesAUD: Record<Plan, number> = {
  [Plan.Free]: PriceAUD.Free,
  [Plan.Rookie_DEPRECATED]: PriceAUD.Rookie_DEPRECATED,
  [Plan.Rookie]: PriceAUD.Rookie100,
  [Plan.Hobbyist_DEPRECATED]: PriceAUD.Standard_DEPRECATED,
  [Plan.Hobbyist]: PriceAUD.Standard100,
  [Plan.Investor]: PriceAUD.Advanced,
  [Plan.Trader]: PriceAUD.Expert,
  [Plan.Accountant]: PriceAUD.Accountant,
  [Plan.Collaborator]: PriceAUD.Accountant,
  [Plan.Enterprise]: PriceAUD.Accountant,
  [Plan.Business]: PriceAUD.Business, // TBC
  // only available for us
  [Plan.InvestorUs]: 0,
  [Plan.InvestorUsPlus]: 0,
  [Plan.TraderUs]: 0,
  [Plan.TraderUsPlus]: 0,
  // getting the price directly from stripe
  [Plan.BusinessPackage]: 0, // TBC
  [Plan.BusinessPro]: 0,
  [Plan.BusinessStarter]: 0,
  [Plan.BusinessTrial]: 0,
  [Plan.Pro]: 0, // fetch from BE
};

const PlanPricesGBP: Record<Plan, number> = {
  [Plan.Free]: PriceUSD.Free,
  [Plan.Rookie]: PriceGBP.Rookie100,
  [Plan.Rookie_DEPRECATED]: PriceGBP.Rookie_DEPRECATED,
  [Plan.Hobbyist_DEPRECATED]: PriceGBP.Standard_DEPRECATED,
  [Plan.Hobbyist]: PriceGBP.Standard100,
  [Plan.Investor]: PriceGBP.Advanced,
  [Plan.Trader]: PriceGBP.Expert,
  [Plan.Accountant]: PriceGBP.Accountant,
  [Plan.Collaborator]: PriceGBP.Accountant,
  [Plan.Enterprise]: PriceGBP.Accountant,
  [Plan.Business]: PriceGBP.Business, // TBC

  // only available for us
  [Plan.InvestorUs]: 0,
  [Plan.InvestorUsPlus]: 0,
  [Plan.TraderUs]: 0,
  [Plan.TraderUsPlus]: 0,

  // getting the price directly from stripe
  [Plan.BusinessPackage]: 0, // TBC
  [Plan.BusinessPro]: 0,
  [Plan.BusinessStarter]: 0,
  [Plan.BusinessTrial]: 0,
  [Plan.Pro]: 0, // fetch from BE
};

const PlanPricesEUR: Record<Plan, number> = {
  [Plan.Free]: PriceUSD.Free,
  [Plan.Rookie]: PriceEUR.Rookie100,
  [Plan.Rookie_DEPRECATED]: PriceEUR.Rookie_DEPRECATED,
  [Plan.Hobbyist_DEPRECATED]: PriceEUR.Standard_DEPRECATED,
  [Plan.Hobbyist]: PriceEUR.Standard100,
  [Plan.Investor]: PriceEUR.Advanced,
  [Plan.Trader]: PriceEUR.Expert,
  [Plan.Accountant]: PriceEUR.Accountant,
  [Plan.Collaborator]: PriceEUR.Accountant,
  [Plan.Enterprise]: PriceEUR.Accountant,
  [Plan.Business]: PriceEUR.Business, // TBC

  // only available for us
  [Plan.InvestorUs]: 0,
  [Plan.InvestorUsPlus]: 0,
  [Plan.TraderUs]: 0,
  [Plan.TraderUsPlus]: 0,

  // getting the price directly from stripe
  [Plan.BusinessPackage]: 0, // TBC
  [Plan.BusinessPro]: 0,
  [Plan.BusinessStarter]: 0,
  [Plan.BusinessTrial]: 0,
  [Plan.Pro]: 0, // fetch from BE
};

const PlanPricesCH: Record<Plan, number> = {
  [Plan.Free]: PriceUSD.Free,
  [Plan.Rookie]: PriceEUR.Rookie100,
  [Plan.Rookie_DEPRECATED]: PriceEUR.Rookie_DEPRECATED,
  [Plan.Hobbyist_DEPRECATED]: PriceEUR.Standard_DEPRECATED,
  [Plan.Hobbyist]: PriceEUR.Standard100,
  [Plan.Investor]: PriceEUR.Advanced,
  [Plan.Trader]: PriceEUR.Expert,
  [Plan.Accountant]: PriceEUR.Accountant,
  [Plan.Collaborator]: PriceEUR.Accountant,
  [Plan.Enterprise]: PriceEUR.Accountant,
  [Plan.Business]: PriceEUR.Business, // TBC

  // only available for us
  [Plan.InvestorUs]: 0,
  [Plan.InvestorUsPlus]: 0,
  [Plan.TraderUs]: 0,
  [Plan.TraderUsPlus]: 0,

  // getting the price directly from stripe
  [Plan.BusinessPackage]: 0, // TBC
  [Plan.BusinessPro]: 0,
  [Plan.BusinessStarter]: 0,
  [Plan.BusinessTrial]: 0,
  [Plan.Pro]: 0, // fetch from BE
};

const PlanPricesNZD: Record<Plan, number> = {
  [Plan.Free]: PriceUSD.Free,
  [Plan.Rookie]: PriceNZD.Rookie100,
  [Plan.Rookie_DEPRECATED]: PriceNZD.Rookie_DEPRECATED,
  [Plan.Hobbyist_DEPRECATED]: PriceNZD.Standard_DEPRECATED,
  [Plan.Hobbyist]: PriceNZD.Standard100,
  [Plan.Investor]: PriceNZD.Advanced,
  [Plan.Trader]: PriceNZD.Expert,
  [Plan.Accountant]: PriceNZD.Accountant,
  [Plan.Collaborator]: PriceNZD.Accountant,
  [Plan.Enterprise]: PriceNZD.Accountant,
  [Plan.Business]: PriceNZD.Business, // TBC

  // only available for us
  [Plan.InvestorUs]: 0,
  [Plan.InvestorUsPlus]: 0,
  [Plan.TraderUs]: 0,
  [Plan.TraderUsPlus]: 0,

  // getting the price directly from stripe
  [Plan.BusinessPackage]: 0, // TBC
  [Plan.BusinessPro]: 0,
  [Plan.BusinessStarter]: 0,
  [Plan.BusinessTrial]: 0,
  [Plan.Pro]: 0, // fetch from BE
};

const PlanPricesCAD: Record<Plan, number> = {
  [Plan.Free]: PriceUSD.Free,
  [Plan.Rookie]: PriceCAD.Rookie100,
  [Plan.Rookie_DEPRECATED]: PriceCAD.Rookie_DEPRECATED,
  [Plan.Hobbyist_DEPRECATED]: PriceCAD.Standard_DEPRECATED,
  [Plan.Hobbyist]: PriceCAD.Standard100,
  [Plan.Investor]: PriceCAD.Advanced,
  [Plan.Trader]: PriceCAD.Expert,
  [Plan.Accountant]: PriceCAD.Accountant,
  [Plan.Collaborator]: PriceCAD.Accountant,
  [Plan.Enterprise]: PriceCAD.Accountant,
  [Plan.Business]: PriceCAD.Business, // TBC

  // only available for us
  [Plan.InvestorUs]: 0,
  [Plan.InvestorUsPlus]: 0,
  [Plan.TraderUs]: 0,
  [Plan.TraderUsPlus]: 0,

  // getting the price directly from stripe
  [Plan.BusinessPackage]: 0, // TBC
  [Plan.BusinessPro]: 0,
  [Plan.BusinessStarter]: 0,
  [Plan.BusinessTrial]: 0,
  [Plan.Pro]: 0, // fetch from BE
};

const PlanPricesINR: Record<Plan, number> = {
  [Plan.Free]: PriceINR.Free,
  [Plan.Rookie]: PriceINR.Rookie100,
  [Plan.Hobbyist]: PriceINR.Standard100,
  [Plan.Investor]: PriceINR.Advanced,
  [Plan.Trader]: PriceINR.Expert,
  [Plan.Enterprise]: 0, // Enterprise plan is not currently available to India
  [Plan.Business]: 0, // business plan is not currently available to India

  // no deprecated india users
  [Plan.Accountant]: 0, // deprecated account plan, no longer exists
  [Plan.Collaborator]: 0, // deprecated account plan, no longer exists
  [Plan.Rookie_DEPRECATED]: 0,
  [Plan.Hobbyist_DEPRECATED]: 0,

  // only available for us
  [Plan.InvestorUs]: 0,
  [Plan.InvestorUsPlus]: 0,
  [Plan.TraderUs]: 0,
  [Plan.TraderUsPlus]: 0,

  // getting the price directly from stripe
  [Plan.BusinessPackage]: 0, // TBC
  [Plan.BusinessPro]: 0,
  [Plan.BusinessStarter]: 0,
  [Plan.BusinessTrial]: 0,
  [Plan.Pro]: 0, // fetch from BE
};

const billedCurrencyToPlanPrices: Record<
  BillableCurrency,
  Record<Plan, number>
> = {
  [LocalCurrency.AUD]: PlanPricesAUD,
  [LocalCurrency.CAD]: PlanPricesCAD,
  [LocalCurrency.CHF]: PlanPricesCH,
  [LocalCurrency.EUR]: PlanPricesEUR,
  [LocalCurrency.GBP]: PlanPricesGBP,
  [LocalCurrency.INR]: PlanPricesINR,
  [LocalCurrency.NZD]: PlanPricesNZD,
  [LocalCurrency.USD]: PlanPricesUSD,
};

/**
 * this has been deprecated as it pulls from the enums rather than stripe dynamically
 * use useApplyDiscount() instead
 * @deprecated use useApplyDiscount() instead
 **/

export function useUserDiscountDEPRECATED(percentOff?: number) {
  const auth = useAuth();
  const userDiscount = auth.user?.discount
    ? Number(auth.user.discount)
    : undefined;
  const discount = percentOff ? percentOff : userDiscount;

  const country = auth.user?.country;
  const prices = getCountryPricesDEPRECATED(country);

  const priceList = { ...prices } as Record<Plan, number>;
  if (discount && prices && priceList) {
    Object.keys(priceList).forEach((plan) => {
      const percentOff = discount as number;
      const planType = plan as Plan;
      const price = priceList[planType] as number;
      const priceDiscounted = price * (1 - percentOff / 100);
      priceList[planType] = priceDiscounted;
    });
  }

  return { priceList, discount };
}

/**
 * Get the prices for a given country
 * @returns The prices for the given country
 * @deprecated This function is deprecated as it pulls from the enums rather than stripe dynamically
 */
export function getCountryPricesDEPRECATED(
  country: Country | undefined,
): Record<Plan, number> {
  if (!country) {
    return PlanPricesUSD;
  }

  const billedCurrency = getBilledCurrencyForCountry(country);

  return billedCurrencyToPlanPrices[billedCurrency];
}
