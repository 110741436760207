import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { displayMessage } from "~/components/ui/Toaster";
import { setUpdate } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { HttpError } from "~/services/core";
import * as settingsApi from "~/services/settings";
import { DisplayMessage } from "~/types/enums";

export const secKeys = {
  all: () => ["secKeys"],
  qrCode: () => [...secKeys.all(), "qrCode"],
};

export function useQrCode() {
  return useQuery({
    queryKey: secKeys.qrCode(),
    queryFn: async () => {
      const res = await settingsApi.get2faQrCode();
      if (res.error) {
        throw new HttpError(res, ["get2faQrCode"]);
      }
      return res.data;
    },
  });
}

export function useEnableTwoFactorMutation() {
  const lang = useLang();
  const reduxDispatch = useDispatch();

  return useMutation({
    mutationFn: async ({
      code,
      password,
    }: {
      code: string;
      password: string;
    }) => {
      const res = await settingsApi.enable2fa(code, password);

      if (res.error) {
        displayMessage({
          message: lang.twoFactorAuth.enableError,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["enable2fa"]);
      }

      return res.data;
    },
    onSuccess: (data) => {
      reduxDispatch(setUpdate({ is2faEnabled: true }));
      displayMessage({
        message: lang.twoFactorAuth.enableSuccess,
        type: DisplayMessage.Success,
      });
      return data.recoveryCodes;
    },
  });
}

export function useDisableTwoFactorMutation() {
  const lang = useLang();
  const reduxDispatch = useDispatch();

  return useMutation({
    mutationFn: async ({
      code,
      password,
    }: {
      code: string;
      password: string;
    }) => {
      const res = await settingsApi.disable2fa(code, password);

      if (res.error) {
        displayMessage({
          message: lang.twoFactorAuth.disableError,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["disable2fa"]);
      }

      return res.data;
    },
    onSuccess: () => {
      reduxDispatch(setUpdate({ is2faEnabled: false }));
      displayMessage({
        message: lang.twoFactorAuth.disableSuccess,
        type: DisplayMessage.Success,
      });
    },
  });
}

export function useRegenerateRecoveryCodesMutation() {
  const lang = useLang();

  return useMutation({
    mutationFn: async ({
      code,
      password,
    }: {
      code: string;
      password: string;
    }) => {
      const res = await settingsApi.regenerateRecoveryCodes(code, password);

      if (res.error) {
        displayMessage({
          message: lang.twoFactorAuth.regenerateError,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["regenerateRecoveryCodes"]);
      }

      return res.data;
    },
    onSuccess: (data) => {
      displayMessage({
        message: lang.twoFactorAuth.regenerateSuccess,
        type: DisplayMessage.Success,
      });
      return data.recoveryCodes;
    },
  });
}
