import { Box, Skeleton, Typography } from "@mui/material";

import { captureAnalytics } from "~/analytics/posthog";
import { getReferralLink } from "~/components/refer-a-friend/helpers";
import { CopyButton } from "~/components/ui/ui-buttons/CopyButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useDesign } from "~/hooks/useTheme";
import { useGetReferralCode } from "~/state/referral";
import { type ReferralProgramType } from "~/types/enums";

export function LinkBox({ programType }: { programType: ReferralProgramType }) {
  const { tokens } = useDesign();

  const { data, isPending } = useGetReferralCode(programType);

  // TODO: update to each env
  const link = getReferralLink(data?.code);

  return (
    <Box
      display="flex"
      padding="0.375rem 0.5rem"
      gap="0.5rem"
      alignItems="center"
      border={`1px solid ${tokens.border.neutral.high}`}
      bgcolor={tokens.elevation.high}
    >
      {isPending ? (
        <Skeleton height="1.5rem" width="20rem" />
      ) : (
        <>
          <Typography
            variant="Metropolis/Body/Regular"
            color={tokens.text.high}
          >
            {link}
          </Typography>
          <CopyButton
            ButtonComponent={TextButton}
            contentToCopy={link}
            handleClick={() => {
              captureAnalytics("raf_link_copied");
            }}
            color={tokens.text.brand}
          />
        </>
      )}
    </Box>
  );
}
