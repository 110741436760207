import { type Blockchain } from "@ctc/types";
import { Box, Typography } from "@mui/material";
import {
  type AnchorHTMLAttributes,
  type DetailedHTMLProps,
  Fragment,
} from "react";
import Markdown from "react-markdown";
import styled from "styled-components";

import {
  AdditionalNotes,
  requiredIntegrationsOnboardingNote,
} from "~/components/imports/AdditionalNotes";
import { getImportInstructionKey } from "~/components/imports/helpers";
import instructions from "~/components/imports/import-instructions-experiment/instructions-simplified.json";
import { ImportVideo } from "~/components/imports/ImportVideo";
import { useInImportInstructionsSimplifiedExperiment } from "~/components/onboarding-v2/hooks/useInImportInstructionsWithImagesExperiment";
import { useDesign } from "~/hooks/useTheme";
import { useUser } from "~/redux/auth";
import { useLang, useNotice } from "~/redux/lang";
import { useAvailableImportOption } from "~/state/imports";
import { ImportType, IntegrationCategory } from "~/types/enums";
import {
  BlockchainName,
  type ImportMethodV2,
  type ImportTypeInstructions,
  type WalletOptions,
} from "~/types/index";

export function InstructionsSimplified({
  exchangeLabel,
  importType,
  importMethod,
  selectedBlockchain,
}: {
  exchangeLabel: string;
  importType: ImportType;
  importMethod: ImportMethodV2;
  selectedBlockchain: Blockchain | undefined;
}) {
  const lang = useLang();
  const inImportInstructionsSimplifiedExperiment =
    useInImportInstructionsSimplifiedExperiment();
  const importOption = useAvailableImportOption(exchangeLabel);
  const potentialNote = useNotice(exchangeLabel);
  const user = useUser();

  const exchangeLabelTyped =
    exchangeLabel as keyof typeof instructions.importInstructions;
  const simplifiedInstructions =
    instructions.importInstructions[exchangeLabelTyped];
  const importTypeTyped = importType as keyof typeof simplifiedInstructions;
  const resolvedSteps = simplifiedInstructions?.[
    importTypeTyped
  ] as ImportTypeInstructions;
  const isUnspecifiedBlockchainAllowed = !!(
    importMethod.options as WalletOptions | undefined
  )?.isUnspecifiedBlockchainAllowed;

  if (!inImportInstructionsSimplifiedExperiment || !resolvedSteps) {
    return null;
  }

  const dontShowOnboardingNote = !!(
    user?.isOnboarding &&
    !requiredIntegrationsOnboardingNote[importOption?.id ?? ""]?.includes(
      importType as ImportType,
    )
  );

  const notice =
    importOption?.category === IntegrationCategory.Wallet &&
    (importOption?.category.length === 1 || isUnspecifiedBlockchainAllowed) &&
    selectedBlockchain
      ? lang.wallet.equivWallet({ name: BlockchainName[selectedBlockchain] })
      : potentialNote;

  const isImportingCSV =
    importType === ImportType.CSV || importType === ImportType.ManualCSV;

  if (!resolvedSteps) {
    return null;
  }

  const { video = [], step = [], note = [] } = resolvedSteps;

  return (
    <StyledInstructionContainer>
      {video.length > 0 ? (
        <Box display="flex" flexDirection="column" gap="0.5rem" width="100%">
          {video.map((videoLinkInstr) => (
            <ImportVideo key={videoLinkInstr.link} note={videoLinkInstr} />
          ))}
        </Box>
      ) : null}
      {step.map((stepInstr, index) => (
        <Fragment key={getImportInstructionKey(stepInstr, index)}>
          {/* {stepInstr.title ? (
            <Typography
              variant="Metropolis/Body/Light"
              style={{ fontWeight: 500 }}
              gutterBottom
            >
              <span>{stepInstr.title}</span>
            </Typography>
          ) : null} */}
          {stepInstr.items && stepInstr.items?.length > 0 ? (
            <StepList steps={stepInstr.items} />
          ) : null}
        </Fragment>
      ))}
      {notice && (
        <Box>
          <Typography variant="Metropolis/Body/Light">{lang.imports.note}:</Typography>
          <Typography variant="Metropolis/Body/Light">{notice}</Typography>
        </Box>
      )}
      <AdditionalNotes
        noteSteps={note}
        isManualCSVOnly={false}
        isImportingCSV={isImportingCSV}
        dontShowOnboardingNote={dontShowOnboardingNote}
      />
    </StyledInstructionContainer>
  );
}

export function StepList({ steps }: { steps: string[] }) {
  return (
    <Box display="flex" flexDirection="column" gap="0.5rem" alignSelf="stretch">
      {steps.map((step, i) => (
        <StepItem
          key={step ?? i}
          item={step}
          index={i}
          totalSteps={steps.length}
        />
      ))}
    </Box>
  );
}

function NewTabLink({
  href,
  children,
}: DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

function StepItem({
  item,
  index,
  totalSteps,
  noNumber,
}: {
  item: string;
  index: number;
  totalSteps: number;
  noNumber?: boolean;
}) {
  const { tokens } = useDesign();
  const isOnboarding = !!useUser()?.isOnboarding;
  if (totalSteps === 1 || noNumber) {
    return (
      <Typography variant="Metropolis/Body/Light" flex="1 0 0" fontWeight={500}>
        <Markdown components={{ a: NewTabLink }}>{item}</Markdown>
      </Typography>
    );
  }
  return (
    <Box display="flex" gap="0.375rem" alignItems="stretch">
      <Box
        width="1.25rem"
        minWidth="1.25rem"
        height="1.25rem"
        borderRadius="1rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={
          isOnboarding ? undefined : tokens.background.accent.neutral.low
        }
        border={
          isOnboarding
            ? undefined
            : `1px solid ${tokens.border.neutral.default}`
        }
        pt={isOnboarding ? undefined : "1px"}
      >
        <Typography variant="Metropolis/Caption/Medium/Regular" color={tokens.text.high}>
          {index + 1}
        </Typography>
      </Box>
      <Typography
        variant="Metropolis/Body/Light"
        flex="1 0 0"
        overflow="auto"
        fontWeight={500}
      >
        <Markdown components={{ a: NewTabLink }}>{item}</Markdown>
      </Typography>
    </Box>
  );
}

const StyledInstructionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  min-width: 15rem;
  flex: 1 0 0;
`;
