import { CaptchaProvider, OAuthProvider } from "@ctc/types";
import { Alert, Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { Breaker } from "~/components/ui/Breaker";
import { useIsMobile } from "~/components/ui/hooks";
import { StyledNoLineLink } from "~/components/ui/StyledLink";

import { displayMessage } from "~/components/ui/Toaster";
import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { Verify2FAInput } from "~/components/ui/Verify2FAInput";
import { OAuthLoginButton } from "~/components/user/OAuthLoginButton";
import { Recaptcha } from "~/components/user/Recaptcha";
import { Turnstile } from "~/components/user/Turnstile";
import { BackgroundBox, UserDetailsPage } from "~/components/user/UserDetails";
import {
  formatEmail,
  isValidEmail,
  stripSpacesOnKeyDown,
} from "~/components/user/validators";
import { useCaptcha } from "~/hooks/useCaptchaVerification";
import { useDesign } from "~/hooks/useTheme";
import {
  resetError,
  useAuth,
  useLogin,
  useWalletOAuthLogin,
  verify2fa,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { DisplayMessage, Links } from "~/types/enums";

export const LoginForm: React.FC = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { error, user } = useAuth();
  const [incorrectMethod] = useQueryParam("incorrectMethod", StringParam);
  const login = useLogin("login");
  const [isCodeRequired, setIsCodeRequired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [code, setCode] = useState("");
  const isMobile = useIsMobile();
  const { isLoading: isLoadingWalletAuth, handleWalletConnect } =
    useWalletOAuthLogin();
  // Check if our form is valid
  const isFormValid = isValidEmail(email) && password.length > 0;

  const {
    recaptchaRef,
    setTurnstileCaptcha,
    getCaptchaToken,
    captchaProvider,
    turnstileCaptcha,
  } = useCaptcha();

  useEffect(() => {
    if (incorrectMethod) {
      displayMessage({
        message: lang.auth.differentSignUpMethod,
        type: DisplayMessage.Error,
      });
    }
  }, [incorrectMethod, lang.auth.differentSignUpMethod]);

  const removeAuthWarning = () => dispatch(resetError());

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const captcha = await getCaptchaToken({ login: true });
    if (!captcha) {
      return; // Error already displayed by the hook
    }

    await login({
      email,
      password,
      captcha,
      captchaProvider,
      isCodeRequired,
      setIsCodeRequired,
      setIsSubmitting,
      removeAuthWarning,
    });
  };

  const handle2faSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    removeAuthWarning();

    dispatch(verify2fa({ code }, setIsSubmitting));
  };

  // todo: when BE supports it, add this back
  // const handleStaySignedInCheckChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   setStaySignedIn(event.target.checked);
  // };

  if (isCodeRequired) {
    return (
      <BackgroundBox isLogoExperiment>
        <Box
          component="form"
          onSubmit={handle2faSubmit}
          sx={{
            backgroundColor: tokens.elevation.default,
            flexDirection: "column",
            gap: "1rem",
            display: "flex",
            ...(isMobile
              ? {
                  width: "100%",
                }
              : {
                  borderRadius: "0.25rem",
                  border: `1px solid ${tokens.border.neutral.default}`,
                }),
          }}
        >
          <Verify2FAInput error={error} setCode={setCode} />
          <Box
            sx={{
              display: "flex",
              gap: "0.75rem",
              justifyContent: "flex-end",
              padding: "1rem",
              marginTop: "1rem",
              width: "100%",
              ...(isMobile
                ? {}
                : {
                    backgroundColor: tokens.elevation.low,
                    borderTop: `1px solid ${tokens.border.neutral.default}`,
                  }),
            }}
          >
            <TertiaryButton
              variant="outlined"
              onClick={() => {
                setIsCodeRequired(false);
                setCode("");
                setIsSubmitting(false);
              }}
            >
              {lang.cancel}
            </TertiaryButton>
            <PrimaryLoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              {lang.twoFactorAuth.submit}
            </PrimaryLoadingButton>
          </Box>
        </Box>
      </BackgroundBox>
    );
  }

  return (
    <UserDetailsPage
      formText={{
        title: lang.auth.loginPage.title,
      }}
    >
      <Box>
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <OAuthLoginButton
            text={lang.auth.loginPage.oauth.metamask}
            action={handleWalletConnect}
            oAuthProvider={OAuthProvider.Moralis}
            loading={isLoadingWalletAuth}
          />

          <OAuthLoginButton
            text={lang.auth.loginPage.oauth.google}
            oAuthProvider={OAuthProvider.Google}
            disabled={isLoadingWalletAuth}
          />
          <OAuthLoginButton
            text={lang.auth.loginPage.oauth.coinbase}
            oAuthProvider={OAuthProvider.Coinbase}
            disabled={isLoadingWalletAuth}
          />
        </Box>
        <Breaker
          text={lang.auth.breaker}
          background={tokens.elevation.default}
        />
        <form onSubmit={handleSubmit}>
          {error !== undefined ? (
            <Alert severity="error" sx={{ mb: "2rem" }}>
              {error}
            </Alert>
          ) : null}
          <TextField
            style={{ marginBottom: "1rem" }}
            variant="outlined"
            name="email"
            type="email"
            label={lang.auth.email}
            required
            fullWidth
            onKeyDown={stripSpacesOnKeyDown}
            onChange={(e) => {
              setEmail(formatEmail(e.target.value));
            }}
            value={email}
          />
          <TextField
            style={{ marginBottom: "0.5rem" }}
            className="fs-exclude"
            variant="outlined"
            name="password"
            type="password"
            label={lang.auth.password}
            autoComplete="current-password"
            required
            fullWidth
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
          />
          <Box display="flex" flexDirection="row-reverse">
            <Typography variant="Metropolis/Caption/Medium/Regular">
              <Link
                to={Links.Forgot}
                style={{ textDecoration: "none", color: tokens.text.brand }}
              >
                {lang.auth.forgot}
              </Link>
            </Typography>
          </Box>
          {captchaProvider === CaptchaProvider.Turnstile ? (
            <Box
              display="flex"
              justifyContent="center"
              padding="0.0625rem"
              marginTop="1rem"
            >
              <Turnstile onVerify={setTurnstileCaptcha} />
            </Box>
          ) : null}
          <Box mt="1rem">
            <PrimaryLoadingButton
              type="submit"
              disabled={
                isSubmitting ||
                !isFormValid ||
                (captchaProvider === CaptchaProvider.Turnstile &&
                  !turnstileCaptcha)
              }
              fullWidth
              loading={isSubmitting}
              style={{ height: "3.5rem" }}
            >
              <Typography
                fontWeight={500}
                fontSize={16}
                color={tokens.text.white}
              >
                {lang.auth.signin}
              </Typography>
            </PrimaryLoadingButton>
          </Box>
        </form>
        <Box textAlign="center" padding="0.5rem" pt="1rem">
          <Typography variant="Metropolis/Caption/Medium/Regular">
            {`${lang.auth.noAccount} `}
            <StyledNoLineLink to={Links.Signup} onClick={removeAuthWarning}>
              {lang.auth.signup}
            </StyledNoLineLink>
          </Typography>
        </Box>
        {captchaProvider === CaptchaProvider.Recaptcha ? (
          <Box display="flex" justifyContent="center">
            <Recaptcha recaptchaRef={recaptchaRef} />
          </Box>
        ) : null}
      </Box>
    </UserDetailsPage>
  );
};
