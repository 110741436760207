import { type Trade } from "@ctc/types";

import { TradeIcons } from "~/components/ui/TradeIcons";
import { type Translation } from "~/lang/index";
import { getActionTypeName } from "~/lib/getActionTypeName";

export function getOptionsFromTradeTypes(
  tradeTypes: Trade[],
  lang: Translation,
) {
  return tradeTypes?.map((tradeType) => {
    const Icon = TradeIcons[tradeType];
    return {
      label: getActionTypeName({ actionType: tradeType, lang }),
      icon: <Icon />,
      tradeType,
      chevron: true,
    };
  });
}
