import { Country } from "@ctc/types";

import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import {
  useIsManagedClient,
  useIsManagingClients,
  useUser,
} from "~/redux/auth";

export function useIsReferEnabled() {
  const isManagingClients = useIsManagingClients();
  const isManagedClient = useIsManagedClient();
  const user = useUser();
  const isEmbedded = useIsEmbedded();
  if (isEmbedded) {
    return false;
  }
  const isMissingEmail = !user?.email;

  // Disabled as refer a friend currently doesnt make sense for low valued countries
  // it currently only removes 40 in their currency off the price of the plan
  const isDisabledCountry =
    user?.country &&
    [Country.India, Country.Japan, Country.SouthAfrica].includes(user?.country);

  // disabled for accountants, clients and users without emails
  // emails required for FP to create promoter
  if (
    isManagingClients ||
    isMissingEmail ||
    isManagedClient ||
    isDisabledCountry
  ) {
    return false;
  }

  return true;
}
