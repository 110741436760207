import {
  Country,
  isBypassComplexTxPaywallReferrerSource,
  LocalCurrency,
  Plan,
  type SupportedLang,
} from "@ctc/types";
import { useCallback } from "react";

import { useExperimentVariant } from "~/analytics/posthog";
import { type Actions } from "~/components/payment/types";
import { CENTS_PER_DOLLAR } from "~/constants/constants";
import { useTransactionsBilledCount } from "~/hooks/useTransactions";
import { displayFiatValue } from "~/lib/index";
import { usePaywallInfo, useUser, useUserHasPaid } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { getHighestPlanRankCurrentUser } from "~/services/user";
import { useBestCoupons } from "~/state/billing";
import { useBusinessPlans, usePlans } from "~/state/plans";
import { FeatureFlag, PaywallReason } from "~/types/enums";
import {
  type BillableCurrency,
  isBusinessPaidPlanType,
  type RetailPlanData,
  type UserInfo,
} from "~/types/index";

/**
 * We don't need to call the plans api again
 * since the features are the same between investor us plus and old hobbyist
 * only difference is the tx limit
 */
export const INVESTOR_US_PLUS_TX_LIMIT = 3000;

export const useCurrentPlan = () => {
  const user = useUser();
  return getHighestPlanRankCurrentUser(user)?.paidPlan;
};

export const usePlanVisibility = () => {
  const plans = usePlans();
  const businessPlans = useBusinessPlans();
  const userPaidPlan = useCurrentPlan();

  const isPurchasable = (plan: Plan) => {
    if (plan === userPaidPlan) return false;

    const planData = isBusinessPaidPlanType(plan)
      ? businessPlans?.data?.[plan]
      : plans?.data?.[plan];
    if (!planData) return false;

    const userPlanIndex = Object.values(Plan).findIndex(
      (planType) => planType === userPaidPlan,
    );

    const planIndex = Object.values(Plan).findIndex((p) => plan === p);

    if (userPlanIndex && planIndex && userPlanIndex > planIndex) {
      // No downgrades.
      return false;
    }

    if (planData.paywallLimits?.alwaysAllowPurchase) return true;

    return planData.willRemovePaywall;
  };

  const isViewable = (plan: Plan) => {
    if (plan === userPaidPlan) return true;
    return isPurchasable(plan);
  };

  return { isPurchasable, isViewable };
};

export const usePopularPlan = () => {
  const { isPurchasable } = usePlanVisibility();

  const getPopularPlan = () => {
    if (isPurchasable(Plan.Investor)) return Plan.Investor;
  };

  return {
    getPopularPlan: useCallback(getPopularPlan, [isPurchasable]),
  };
};

export const useExperimentPopularPlan = () => {
  const { isPurchasable } = usePlanVisibility();

  const getPopularPlan = () => {
    if (isPurchasable(Plan.Investor)) return Plan.Investor;
  };

  return {
    getPopularPlan: useCallback(getPopularPlan, [isPurchasable]),
  };
};

export function isLegacyUsInvestorUsPlusPlan(
  user: UserInfo | null | undefined,
  planType: Plan,
) {
  return (
    (user?.usExperimentPlan === Plan.InvestorUsPlus ||
      // for accountants
      user?.activeDataSource?.usExperimentPlan === Plan.InvestorUsPlus) &&
    planType === Plan.Hobbyist
  );
}

export function useIsLegacyUsInvestorUsPlusPlan(planType: Plan) {
  const user = useUser();
  return isLegacyUsInvestorUsPlusPlan(user, planType);
}

export const useLimitExceeded = () => {
  const billedCount = useTransactionsBilledCount();
  const plans = usePlans();
  const user = useUser();

  const isLimitedExceeded = (plan: Plan) => {
    const planData = plans?.data?.[plan];

    if (!planData) return false;
    const txLimit = isLegacyUsInvestorUsPlusPlan(user, plan)
      ? INVESTOR_US_PLUS_TX_LIMIT
      : planData.paywallLimits.txCountLimit || 0;

    return billedCount > txLimit;
  };

  return { isLimitedExceeded };
};

export const useHaveSmartContractTx = () => {
  const plans = usePlans();
  const paywallInfo = usePaywallInfo();
  const user = useUser();
  const referrerSource = user?.referrerSource;
  const canBypassComplexTxPaywall = useExperimentVariant(
    FeatureFlag.TaxhubEnableRookiePlan,
    "test",
  );

  const isSmartContractTxExist = (plan: Plan) => {
    if (
      canBypassComplexTxPaywall &&
      referrerSource &&
      isBypassComplexTxPaywallReferrerSource(referrerSource)
    ) {
      return false;
    }

    const planData = plans?.data?.[plan];
    if (!planData || !paywallInfo) return false;
    // Check if selected plan allow smart contract tx
    const allowComplexTransactions = planData.paywallLimits.complexTransactions;

    // Check if user have smart contract tx
    const haveSmartContractTx =
      paywallInfo.isPaywalled &&
      paywallInfo.paywallReason.reasons.includes(PaywallReason.tradeType);

    return !allowComplexTransactions && haveSmartContractTx;
  };
  return { isSmartContractTxExist };
};

const getButtonText = (
  actions: Actions,
  planData: RetailPlanData,
  planTitle: string,
  isPaidPlan?: boolean,
  isCurrentPlan?: boolean,
  discountedPrice?: number,
  languagePreference?: SupportedLang,
) => {
  if (!actions || !planData) return null;
  if (!isPaidPlan) return `${actions.buy.text} ${planTitle}`;
  if (isCurrentPlan) return actions.current;

  if (discountedPrice) {
    const upgradePriceString = displayFiatValue({
      value: discountedPrice,
      localCurrency: planData.currency,
      locale: languagePreference,
      roundUp: true,
    });
    return actions.upgradeFor({ upgradePrice: upgradePriceString });
  }
  return `${actions.upgrade.text} ${planTitle}`;
};

export const usePlanPricing = ({ planType }: { planType: Plan }) => {
  const lang = useLang();
  const user = useUser();
  const isPaidPlan = useUserHasPaid();
  const bestCoupons = useBestCoupons();

  // Get the best coupon for the plan
  const bestCouponForPlan = bestCoupons.data?.couponsByPlan[planType];

  const plans = usePlans();
  const { isViewable } = usePlanVisibility();
  const languagePreference = useLanguagePreference();

  const isCurrentPlan = user?.paidPlan === planType;

  if (!plans?.data || !plans.data[planType]) {
    return {
      price: 0,
      buttonText: "",
      planCurrency: null,
      priceDiscounted: null,
    };
  }
  const planData = plans.data[planType] as RetailPlanData;
  const langPayment = lang.payment;

  const price = Math.floor(planData.amount / CENTS_PER_DOLLAR);

  const priceWithCredit =
    (planData.amount - (planData.credits || 0)) / CENTS_PER_DOLLAR;

  const getDiscountedPrice = () => {
    // current plan is "viewable", so need extra check here
    const canUpgradeTo = isViewable(planType) && !isCurrentPlan;

    // no current plan and no coupon there is no discount
    if (!isPaidPlan && !bestCouponForPlan) {
      return planData.credits ? priceWithCredit : undefined;
    }

    // has a paid plan show upgrade price stripe should already persist the coupon from original sale
    if (isPaidPlan && canUpgradeTo) {
      return planData.upgradeAmount / CENTS_PER_DOLLAR;
    }
    if (!bestCouponForPlan) {
      return undefined;
    }
    return priceWithCredit - bestCouponForPlan.discountInCents / 100;
  };

  const buttonText = getButtonText(
    langPayment.base.copy.actions,
    planData,
    langPayment.base.copy.plans[planType].title,
    isPaidPlan,
    isCurrentPlan,
    getDiscountedPrice(),
    languagePreference,
  );

  return {
    loadingCouponDetails: bestCoupons.isLoading,
    priceDiscounted: getDiscountedPrice(),
    price,
    buttonText: isViewable(planType)
      ? buttonText
      : langPayment.base.copy.plans[planType].title,
    planCurrency: planData.currency,
  };
};

export function useGetPlusPlanData(planType: Plan) {
  const plans = usePlans();

  const plusPlanMapping: Record<
    string,
    Plan.InvestorUsPlus | Plan.TraderUsPlus
  > = {
    [Plan.InvestorUs]: Plan.InvestorUsPlus,
    [Plan.TraderUs]: Plan.TraderUsPlus,
  };
  const plusPlanName = plusPlanMapping[planType] as Plan | undefined;

  return {
    plusPlanName,
    plusPlanData: plusPlanName ? plans.data?.[plusPlanName] : undefined,
  };
}

export function getParentPlusPlan(planType: Plan) {
  switch (planType) {
    case Plan.InvestorUsPlus:
      return Plan.InvestorUs;
    case Plan.TraderUsPlus:
      return Plan.TraderUs;
    default:
      return planType;
  }
}

/**
 * Get the billable currency for a given country. This is the currency we get stripe to bill the customers in.
 * todo: This could be shared with core and made into a well defined record
 * @param country The country to get the billable currency for
 * @returns The billable currency for the given country
 */
export function getBilledCurrencyForCountry(
  country: Country,
): BillableCurrency {
  switch (country) {
    case Country.Australia:
      return LocalCurrency.AUD;

    case Country.UK:
      return LocalCurrency.GBP;

    case Country.NewZealand:
      return LocalCurrency.NZD;

    case Country.Canada:
      return LocalCurrency.CAD;

    case Country.Switzerland:
      return LocalCurrency.CHF;

    case Country.India:
      return LocalCurrency.INR;

    case Country.USA:
    case Country.SouthAfrica:
    case Country.Japan:
    case Country.Singapore:
      return LocalCurrency.USD;

    case Country.Ireland:
    case Country.Austria:
    case Country.Belgium:
    case Country.Finland:
    case Country.France:
    case Country.Germany:
    case Country.Greece:
    case Country.Italy:
    case Country.Netherlands:
    case Country.Portugal:
    case Country.Spain:
    case Country.Bulgaria:
    case Country.Croatia:
    case Country.Cyprus:
    case Country.CzechRepublic:
    case Country.Denmark:
    case Country.Estonia:
    case Country.Hungary:
    case Country.Latvia:
    case Country.Lithuania:
    case Country.Luxembourg:
    case Country.Malta:
    case Country.Poland:
    case Country.Romania:
    case Country.Slovakia:
    case Country.Slovenia:
    case Country.Sweden:
    case Country.Norway:
      return LocalCurrency.EUR;
    default:
      return LocalCurrency.USD;
  }
}
