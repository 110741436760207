import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { displayCurrencyName } from "~/lib/index";
import { type CurrencyIdentifier } from "~/types/index";

export function getOptionsFromCurrencies(
  currencies: CurrencyIdentifier[],
  excludeCurrencyId?: string,
) {
  return (
    currencies?.flatMap((c) => {
      if (c.id === excludeCurrencyId) {
        // dont show the from currency
        return [];
      }
      return [
        {
          label: displayCurrencyName(c, false, 32, 28),
          icon: (
            <CurrencyLogo
              currency={c}
              width={20}
              height={20}
              margin="0"
              style={{
                borderRadius: "100%",
                background: "transparent",
              }}
            />
          ),
          currency: c,
          chevron: true,
        },
      ];
    }) ?? []
  );
}
