import { ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { Calco } from "~/components/ui/calco/Calco";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type ExchangeOption } from "~/types/index";

/**
 * validates that two exchange options
 * don't have different blockchains
 * @param value
 * @param otherValue
 */
export function isSameExchangeBlockchain(
  value: ExchangeOption,
  otherValue: ExchangeOption,
): boolean | string {
  return (
    !value.blockchain ||
    !otherValue.blockchain ||
    value.blockchain === otherValue.blockchain
  );
}

export function exchangeSourceValidator(value: ExchangeOption) {
  return !!value.label && !!value.name;
}

export function LockedTxEditFallback() {
  const { tokens } = useDesign();
  const lang = useLang();
  const { setActive } = useSettingsModal();

  return (
    <Box textAlign="center" p="1rem">
      <Calco type="osht" height="150px" />
      <Typography>{lang.editTx.cantEditLocked}</Typography>
      <TextButton
        sx={{ color: tokens.text.brand }}
        onClick={() => {
          setActive(SettingsTabs.LockPeriod);
        }}
        endIcon={<ArrowForward />}
      >
        {lang.editTx.cantEditLockedButton}
      </TextButton>
    </Box>
  );
}
