import { type Trade } from "@ctc/types";

import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { getOptionsFromErpAccounts } from "~/components/transactions/command-palette/helpers/getOptionsFromErpAccounts";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { Confirmation } from "~/components/transactions/command-palette/views/change-loan-account/Confirmation";
import { LoanAccountTopSection } from "~/components/transactions/command-palette/views/change-loan-account/LoanAccountTopSection";
import { useLang } from "~/redux/lang";
import { useErpAvailableAccountsQuery } from "~/state/erp";

export const SelectLoanAccount = ({ tradeType }: { tradeType: Trade }) => {
  const { push } = useNavController();
  const lang = useLang();
  const availableAccounts = useErpAvailableAccountsQuery().data ?? [];
  const options = getOptionsFromErpAccounts(availableAccounts);
  return (
    <CommandPaletteMenu
      topArea={<LoanAccountTopSection tradeType={tradeType} />}
      placeholder={lang.txTable.commandPallet.placeholders.changeTo}
      options={options}
      onSelection={({ erpAccount }) => {
        push(
          <Confirmation tradeType={tradeType} erpLoanAccount={erpAccount} />,
        );
      }}
    />
  );
};
