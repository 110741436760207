import { ReferrerSource } from "@ctc/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

import { useDesign } from "~/hooks/useTheme";
import {
  useTransactionFreeCount,
  useTransactionsBilledCount,
  useTransactionsRealCount,
  useTransactionsTotalCount,
} from "~/hooks/useTransactions";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";

export const PageHeader = () => {
  const lang = useLang();
  const user = useUser();
  const langPaymentCopy = lang.payment.base.copy;
  const { tokens } = useDesign();
  const totalCount = useTransactionsTotalCount();
  const billedCount = useTransactionsBilledCount();
  const realCount = useTransactionsRealCount();
  const freeTxCount = useTransactionFreeCount();
  const ignoredOrSpamTxs = totalCount - realCount;

  const getFreeTxText = () => {
    const referrerSource = user?.referrerSource;

    switch (referrerSource) {
      case ReferrerSource.Coinjar:
      case ReferrerSource.Coinbase:
      case ReferrerSource.Phantom:
        return lang.payment.freeTx[referrerSource]({
          freeTxCount,
        });

      default:
        return lang.payment.freeTx.default({
          freeTxCount,
        });
    }
  };

  return (
    <Stack
      direction="column"
      gap="0.5rem"
      sx={{
        s: {
          color: tokens.text.brand,
        },
        span: {
          color: tokens.text.high,
        },
      }}
    >
      <Typography
        variant="Metropolis/Header/H1"
        style={{ color: tokens.text.high, fontWeight: 700 }}
      >
        {langPaymentCopy.title}
      </Typography>
      <Typography
        variant="Metropolis/Header/H5"
        style={{ color: tokens.text.default, fontWeight: 700 }}
      >
        {langPaymentCopy.subtitle}
      </Typography>
      {ignoredOrSpamTxs !== 0 || freeTxCount ? (
        <Tooltip
          placement="top"
          title={
            <span>
              {lang.payment.excludes}
              <ul style={{ margin: "0 0 0 1rem", padding: 0 }}>
                {ignoredOrSpamTxs ? (
                  <li>
                    {lang.payment.ignoredTx({
                      ignoredTxCount: ignoredOrSpamTxs,
                    })}
                  </li>
                ) : null}
                {freeTxCount ? <li>{getFreeTxText()}</li> : null}
              </ul>
            </span>
          }
        >
          <Box
            display="flex"
            gap="0.25rem"
            alignItems="center"
            width="max-content"
          >
            <Typography variant="Metropolis/Body/Bold" style={{ color: tokens.text.low }}>
              {lang.payment.txCount.prefix}{" "}
              {freeTxCount ? (
                <Typography
                  display="inline"
                  variant="inherit"
                  style={{
                    color: tokens.text.low,
                    textDecoration: "line-through",
                  }}
                >
                  {realCount}
                </Typography>
              ) : (
                ""
              )}{" "}
              {billedCount} {lang.payment.txCount.suffix}
            </Typography>

            <InfoOutlinedIcon
              style={{ fontSize: "1.125rem", color: tokens.icon.low }}
            />
          </Box>
        </Tooltip>
      ) : (
        <Typography variant="Metropolis/Body/Bold" style={{ color: tokens.text.low }}>
          {lang.payment.txCount.prefix} {billedCount}{" "}
          {lang.payment.txCount.suffix}
        </Typography>
      )}
    </Stack>
  );
};
