import {
  type SyncErrorIssue,
  SyncErrorReason,
  SyncStatusPlatform,
} from "@ctc/types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "styled-components/macro";

import { useInImportErrorReportExperiment } from "~/components/imports/useImportErrorReportExperiment";
import {
  type ButtonGroupActions,
  ImportErrorActionButtonGroup,
} from "~/components/ui/ui-buttons/ButtonGroup";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { showIntercom } from "~/services/intercom";
import { useLoadingSavedImports } from "~/state/imports";
import { ImportType } from "~/types/enums";

type ImportErrorReportingBoxProps = {
  issue?: SyncErrorIssue;
  handleSoftSync: () => void;
  handleUpdate: () => void;
  importType: ImportType;
  exchangeName: string;
  syncStatus?: SyncStatusPlatform;
};

function useGetSyncErrorMessageAndCTA(
  exchangeName: string,
  issue?: SyncErrorIssue,
): {
  message: string;
  ctaType: string;
} {
  const lang = useLang();
  const syncErrorReason = issue ? lang.syncErrorReasons[issue.reason] : null;

  if (!syncErrorReason) return { message: "", ctaType: "" };

  return {
    message:
      typeof syncErrorReason.message === "function"
        ? syncErrorReason.message({ exchangeName })
        : syncErrorReason?.message,
    ctaType: syncErrorReason?.ctaType,
  };
}

function useButtonActions({
  issue,
  handleSoftSync,
  handleUpdate,
  importType,
}: ImportErrorReportingBoxProps): ButtonGroupActions[] {
  const lang = useLang();
  const retry = {
    text: lang.errorReporting.actionButtonText.retrySync,
    onClick: handleSoftSync,
  };
  const contactSupport = {
    text: lang.errorReporting.actionButtonText.contactSupport,
    onClick: () => {
      showIntercom();
    },
  };
  const updateApiKey = {
    text:
      importType === ImportType.Wallet
        ? lang.errorReporting.actionButtonText.updateAddress
        : lang.errorReporting.actionButtonText.updateApiKey,
    onClick: handleUpdate,
  };

  if (!issue) {
    return [];
  }

  switch (issue.reason) {
    case SyncErrorReason.RateLimitFailed:
    case SyncErrorReason.Timeout:
      return [retry];
    case SyncErrorReason.InvalidUserDefinedField:
    case SyncErrorReason.InvalidAPIKey:
    case SyncErrorReason.InvalidPermissions: // we want them to update the API key
    case SyncErrorReason.ExpiredAPIKey:
    case SyncErrorReason.APIUnavailable:
    // @ts-ignore
    case SyncErrorReason.InvalidWalletAddress:
      return [updateApiKey];
    case SyncErrorReason.UnsupportedDataFormat:
    case SyncErrorReason.UnknownError:
    case SyncErrorReason.AppError:
    case SyncErrorReason.InvalidCSV:
    case SyncErrorReason.InvalidCSVHeaders:
    case SyncErrorReason.CSVInvalidColumn:
      return [contactSupport];
    case SyncErrorReason.CSVNotRequired:
    case SyncErrorReason.NoTransactions:
    case SyncErrorReason.TooManyTransactions:
    default:
      return [];
  }
}

export function ImportErrorReportingBox({
  issue,
  handleSoftSync,
  handleUpdate,
  importType,
  exchangeName,
  syncStatus,
}: ImportErrorReportingBoxProps) {
  const isInImportErrorExperiment = useInImportErrorReportExperiment();
  const isLoading = useLoadingSavedImports();
  const shouldNotShowErrorBox =
    !isInImportErrorExperiment ||
    !issue ||
    isLoading ||
    syncStatus === SyncStatusPlatform.Pending;
  const actions = useButtonActions({
    issue,
    handleSoftSync,
    handleUpdate,
    importType,
    exchangeName,
  });
  const showActions = actions.length > 0;

  if (shouldNotShowErrorBox) {
    return null;
  }

  return (
    <>
      <StyledImportErrorContainer>
        <ImportErrorReport issue={issue} exchangeName={exchangeName} />
      </StyledImportErrorContainer>
      {showActions ? (
        <StyledImportErrorContainer
          sx={{
            borderRadius: "0rem 0rem 0.25rem 0.25rem",
          }}
        >
          <ImportErrorActionBox actions={actions} />
        </StyledImportErrorContainer>
      ) : null}
    </>
  );
}

export function ImportErrorReport({
  issue,
  exchangeName,
}: {
  issue?: SyncErrorIssue;
  exchangeName: string;
}) {
  const { tokens } = useDesign();
  const isInImportErrorExperiment = useInImportErrorReportExperiment();
  const { message, ctaType } = useGetSyncErrorMessageAndCTA(
    exchangeName,
    issue,
  );

  // Used for the API key form
  if (!isInImportErrorExperiment || !issue) {
    return null;
  }

  return (
    <StyledImportErrorBox>
      <Typography
        variant="Metropolis/Body/Regular"
        sx={{
          color: tokens.text.danger,
        }}
      >
        {message}
      </Typography>
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        sx={{
          color: tokens.text.low,
        }}
      >
        {ctaType}
      </Typography>
    </StyledImportErrorBox>
  );
}

export function ImportErrorActionBox({
  actions,
}: {
  actions: ButtonGroupActions[];
}) {
  if (!actions.length) {
    return null;
  }

  return (
    <StyledImportErrorActionBox>
      <ImportErrorActionButtonGroup actions={actions} />
    </StyledImportErrorActionBox>
  );
}

const StyledImportErrorContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: ${({ theme }) => theme.tokens.elevation.low};
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.medium};
  align-self: stretch;
  padding: 0.5rem;
`;

const StyledImportErrorActionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral};
`;

const StyledImportErrorBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 0.25rem 0rem;
  align-items: stretch;
  height: auto;
  color: ${({ theme }) => theme.tokens.text.danger};
  border-radius: 0.25rem;
`;
