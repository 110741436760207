import {
  type Country,
  ReferrerSource,
  isValidReferrerSource,
} from "@ctc/types";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { displayMessage } from "~/components/ui/Toaster";
import { CookieKey, LocalStorageKey } from "~/constants/enums";
import {
  loadUser,
  setNotification,
  setNotificationAuthUser,
  setUpdate,
  setUpdateBestActiveUser,
  userActivated,
} from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import { loadTaxSettings } from "~/redux/report";
import { HttpError } from "~/services/core";
import { updateIntercomUser } from "~/services/intercom";
import * as settingsAPI from "~/services/settings";
import { postReferrer } from "~/services/settings";
import { DisplayMessage, Links, type NotificationType } from "~/types/enums";
import { type Notifications } from "~/types/index";

export const settingsKeys = {
  notifications: () => ["notifications"] as const,
};

/**
 * Clear the referrerSource from local storage and cookies
 * After the user is updated with their referrer source,
 * we need to clear it from local storage and cookies
 */
function clearReferrerSource() {
  localStorage.removeItem(LocalStorageKey.ReferrerSource);
  Cookies.remove(CookieKey.ReferrerSource, {
    domain: import.meta.env.VITE_APP_DOMAIN,
    path: "/",
  });
}

/**
 * Custom hook to handle updating a user's email
 * @param options - Optional callbacks for success and error cases
 */
export const useUpdateEmailMutation = (options?: {
  /**
   * Custom callback to execute after successfully updating the email
   */
  onSuccessCallback?: (data: any) => void;
  /**
   * Custom error callback to execute when the email update fails
   */
  onErrorCallback?: (error: unknown) => void;
}) => {
  const dispatch = useDispatch();
  
  const { 
    onSuccessCallback,
    onErrorCallback
  } = options || {};
  
  return useMutation({
    mutationFn: async (email: string) => {
      // Update the user's email in the system
      const res = await settingsAPI.updateEmail(email);

      if (res.error) {
        throw new HttpError(res, ["updateEmail"]);
      }

      return res.data;
    },
    onSuccess: (data) => {
      // Update user data in Redux
      dispatch(loadUser(LoadUserType.UserUpdate));
      dispatch(setUpdate(data));
      
      // Call custom success callback if provided
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (error) => {
      // Call custom error callback if provided
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    },
  });
};

export const useSetReferrerMutation = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async () => {
      const referrerSource = localStorage.getItem(
        LocalStorageKey.ReferrerSource,
      );

      if (!referrerSource) {
        // no referrer in local storage,  so do nothing
        return undefined;
      }
      if (
        !Object.values(ReferrerSource).includes(
          referrerSource as ReferrerSource,
        )
      ) {
        // a value not in our enum, so not valid
        clearReferrerSource();
        return undefined;
      }
      const res = await postReferrer(referrerSource);
      if (res.error) {
        if (res.status === 400) {
          // will be a zod error, an invalid referrerSource
          // so remove it from local storage
          clearReferrerSource();
          return undefined;
        }
        // could be a 500, we dont remove it from local storage
        // we dont display any errors
        // we can retry
        return undefined;
      }
      await dispatch(loadUser(LoadUserType.UserUpdate));
      // success to remove it
      clearReferrerSource();

      // Add referrerSource to Intercom
      updateIntercomUser({
        referrerSource: isValidReferrerSource(referrerSource)
          ? referrerSource
          : undefined,
      });
      return res.data;
    },
  });
};

export const useSetUserNotificationMutation = ({
  authUser,
}: {
  authUser: boolean;
}) => {
  const lang = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mutationFunction = authUser
    ? settingsAPI.putNotificationsAuthUser
    : settingsAPI.putNotifications;
  const optimisticUpdateFunction = authUser
    ? setNotificationAuthUser
    : setNotification;

  return useMutation({
    mutationFn: async (update: Notifications) => {
      const res = await mutationFunction(update);

      // If the mutation fails, show an error message asking the user to reach out to support
      if (res.error) {
        displayMessage({
          message: res.msg || lang.settings.notifications.errors.generic,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, [mutationFunction.name]);
      }

      return res.data;
    },
    onError: async (err) => {
      // If the mutation fails, show an error message asking the user to reach out to support
      if (err instanceof Error) {
        displayMessage({
          message: err.message || lang.settings.notifications.errors.generic,
          type: DisplayMessage.Error,
        });
      }
    },
    onSuccess: async (update: Notifications) => {
      Object.entries(update).forEach(([key, value]) => {
        dispatch(
          optimisticUpdateFunction(key as NotificationType, value as boolean),
        );
      });

      // Redirect to the settings page and the 'Grant Support Access' button will be revealed at the bottom of the page
      // todo this feels like a hack and probably should not be in here
      if (authUser) {
        navigate(`${Links.Imports}?settings=account`);
      }
    },
  });
};

/**
 * Custom hook to handle country update functionality
 * Encapsulates API call, error handling, and state updates
 */
export const useSetCountryMutation = () => {
  const dispatch = useDispatch();
  const lang = useLang();

  return useMutation({
    mutationFn: async (countryCode: Country) => {
      const response = await settingsAPI.updateCountry(countryCode);

      if (response.error) {
        throw new HttpError(response, ["updateCountry"]);
      }

      return response.data;
    },
    onSuccess: (data) => {
      // Update user data in Redux
      dispatch(loadUser(LoadUserType.UserUpdate));
      dispatch(setUpdate(data));
      dispatch(setUpdateBestActiveUser(data));
      dispatch(userActivated());
      dispatch(loadTaxSettings());
    },
    onError: (error) => {
      displayMessage({
        message:
          lang.errorMessageGeneric +
          (error instanceof HttpError ? `: ${error.message}` : ""),
        type: DisplayMessage.Error,
      });
    },
  });
};
