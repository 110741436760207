import { Box, type BoxProps, Typography } from "@mui/material";
import { useState } from "react";
import styled from "styled-components/macro";

import { Calco, type CalcoVariants } from "~/components/ui/calco/Calco";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { ClientType } from "~/types/enums";

export const SelectClientType = ({
  selectedClientType,
  setSelectedClientType,
}: {
  selectedClientType: ClientType;
  setSelectedClientType: (clientType: ClientType) => void;
}) => {
  const lang = useLang();

  return (
    <Box display="flex" gap="1rem" width="100%">
      {Object.values(ClientType).map((clientType) => (
        <ClientTypeButton
          key={clientType}
          active={clientType === selectedClientType}
          type={clientType}
          onClick={() => {
            setSelectedClientType(clientType);
          }}
        />
      ))}
    </Box>
  );
};

function ClientTypeButton({
  type,
  active = false,
  ...props
}: { type: ClientType; active: boolean } & BoxProps) {
  const [hover, setHover] = useState<boolean>(false);
  const { tokens } = useDesign();
  const lang = useLang().accountant.newClient.clientType;

  const typeToCalcoVariant: Record<ClientType, CalcoVariants> = {
    [ClientType.Individual]: "zen",
    [ClientType.Business]: "unicorn",
  };

  return (
    <ClientButton
      active={active}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      {...props}
    >
      {type === ClientType.Business ? (
        <StyledChip>
          <Typography
            variant="Metropolis/Caption/Medium/Bold"
            fontStyle="italic"
            color={tokens.icon.white}
            fontWeight={700}
          >
            {lang.new}
          </Typography>
        </StyledChip>
      ) : null}
      <Calco type={typeToCalcoVariant[type]} height="48px" />
      <Typography
        color={
          active
            ? tokens.text.brand
            : hover
              ? tokens.text.high
              : tokens.text.default
        }
      >
        {lang.type[type]}
      </Typography>
    </ClientButton>
  );
}

const StyledChip = styled(Box)`
  && {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.purple.medium};
    border-radius: 0.25rem;
    padding: 0.125rem 0.5rem;
  }
`;

const ClientButton = styled(
  ({ active, ...rest }: { active: boolean } & BoxProps) => <Box {...rest} />,
)`
  && {
    position: relative;
    width: 100%;
    height: 6.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
    background-color: ${({ theme }) => theme.tokens.button.neutral.default};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 0.5rem;
    ${({ active, theme }) =>
      active
        ? `
          background-color: ${theme.tokens.button.neutral.pressed};
          border: 1px solid ${theme.tokens.border.brand};
          `
        : null};
  }

  ${({ active, theme }) =>
    !active
      ? `
        &:hover {
          color: ${theme.tokens.text.default};
          background-color: ${theme.tokens.button.neutral.hover};
          border: 1px solid ${theme.tokens.border.neutral.default};
      cursor: pointer;
    }
    `
      : null}
`;
