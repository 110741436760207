import {
  FilterAltOutlined,
  InfoOutlined,
  OpenInNew,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useState } from "react";
import styled from "styled-components/macro";

import { rulesIntegrationsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { DrawerRulesManagementForEntity } from "~/components/contacts/ContactsEdit";
import { DrawerInnerContent } from "~/components/contacts/DrawerInnerContent";
import {
  importAccountKey,
  useCaptureImportAnalytics,
} from "~/components/imports/AnalyticsHelpers";
import { StyledImportTitleTypography } from "~/components/imports/api/index";
import { ImportListItemDetails } from "~/components/imports/ImportListItemWrapper";
import { useInImportErrorReportExperiment } from "~/components/imports/useImportErrorReportExperiment";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { Chip } from "~/components/ui/Chips";
import {
  Drawer,
  DrawerTitle,
  StyledHeaderContent,
} from "~/components/ui/Drawer";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useDesign } from "~/hooks/useTheme";
import { useCanAccessFeature, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { useFindEntityQuery } from "~/state/entities";
import { useLoadingSavedImports } from "~/state/imports";
import { EntityType, Features } from "~/types/enums";
import { type ParseErrorRecord } from "~/types/index";

export const SyncedFrom = ({ importFromDate }: { importFromDate?: Date }) => {
  const lang = useLang();
  const timezone = useTimezone();
  if (!importFromDate) return null;
  const syncFrom = moment.tz(importFromDate, timezone).format("ll");

  return (
    <OuterBox>
      <InnerBox>
        <StyledTypography>
          {`${lang.imports.syncedFrom} ${syncFrom}`}
        </StyledTypography>
      </InnerBox>
    </OuterBox>
  );
};

export const AdditionalFileMetadata = ({
  name,
  firstTxDate,
  lastTxDate,
  parseErrors,
}: {
  name: string | undefined;
  firstTxDate: Date | undefined;
  lastTxDate: Date | undefined;
  parseErrors: ParseErrorRecord[];
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const timezone = useTimezone();
  const [csvErrorsOpen, setCSVErrorsOpen] = useState(false);
  const captureAnalytics = useCaptureImportAnalytics();
  const analyticsKey = importAccountKey;
  const user = useUser();
  const shouldShowSkippedRows = !user?.isOnboarding && parseErrors.length > 0;
  const isInImportErrorExperiment = useInImportErrorReportExperiment();

  const firstTxDateString = firstTxDate
    ? moment.tz(firstTxDate, timezone).format("ll")
    : lang.na;
  const lastTxDateString = lastTxDate
    ? moment.tz(lastTxDate, timezone).format("ll")
    : lang.na;

  const totalErrors = parseErrors.reduce((acc, curr) => acc + curr.count, 0);
  const isLoading = useLoadingSavedImports();

  const openCsvErrors = () => {
    setCSVErrorsOpen(true);
    const errorsByType = parseErrors.reduce(
      (acc: Record<string, number>, curr) => {
        acc[`csvError_${curr.type}`] = curr.count;
        return acc;
      },
      {},
    );
    captureAnalytics(analyticsKey("view csv errors"), {
      totalCSVErrors: totalErrors,
      ...errorsByType,
    });
  };

  if (isInImportErrorExperiment) {
    return null;
  }

  if (isLoading) {
    return (
      <ImportListItemDetails>
        <div />
      </ImportListItemDetails>
    );
  }

  return (
    <>
      <GeneralDialog
        isOpen={csvErrorsOpen}
        title={
          <Box maxWidth="calc(100% - 2.5rem)">
            <Typography variant="Metropolis/Header/H4">
              <span
                style={{ wordBreak: "break-all" }}
              >{`${name || "CSV"}`}</span>{" "}
              {lang.imports.skippedRows.header}
            </Typography>
          </Box>
        }
        handleClose={() => {
          setCSVErrorsOpen(false);
        }}
        cancelText={lang.done}
        closeOnClickAway
        stopPropagation
      >
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <Box
            display="flex"
            alignItems="center"
            p="0.5rem"
            gap="0.5rem"
            borderRadius="0.25rem"
            border="1px solid"
            borderColor={tokens.border.neutral.default}
            bgcolor={tokens.background.neutral.default}
          >
            <InfoOutlined sx={{ color: tokens.text.low, fontSize: "1rem" }} />
            <Typography variant="Metropolis/Caption/Medium/Regular">
              {lang.imports.skippedRows.info({ count: totalErrors })}
            </Typography>
          </Box>
          {parseErrors
            .sort((a, b) => b.count - a.count)
            .map((error) => (
              <Box
                key={error.type}
                css={`
                  width: 100%;
                  display: grid;
                  column-gap: 0.5rem;
                  grid-template-rows: auto;
                  flex-direction: row;
                  grid-template-areas: "count text";
                  grid-template-columns: 3rem 1fr;
                `}
              >
                <Box gridArea="count">
                  <Chip bgcolor={tokens.background.accent.neutral.medium}>
                    {error.count}
                  </Chip>
                </Box>
                <Typography gridArea="text" variant="Metropolis/Body/Regular">
                  {lang.imports.skippedRows.errors[error.type] ?? error.type}
                </Typography>
              </Box>
            ))}
        </Box>
      </GeneralDialog>
      <OuterBox>
        <InnerBox>
          <StyledTypography>
            {lang.imports.firstTx} {firstTxDateString}
          </StyledTypography>
        </InnerBox>
        <InnerBox>
          <StyledTypography>
            {lang.imports.lastTx} {lastTxDateString}
          </StyledTypography>
        </InnerBox>
        {shouldShowSkippedRows ? (
          <InnerBox
            display="flex"
            alignItems="center"
            gap="0.25rem"
            sx={{
              color: tokens.text.default,
              ":hover": {
                color: tokens.text.high,
                cursor: "pointer",
              },
            }}
            onClick={openCsvErrors}
          >
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              color="inherit"
            >
              {lang.imports.skippedRows.cta({ count: totalErrors })}
            </Typography>
            <OpenInNew
              sx={{
                fontSize: "0.875rem",
              }}
            />
          </InnerBox>
        ) : null}
      </OuterBox>
    </>
  );
};

function useEntityForImportId(id: string) {
  const entityQuery = useFindEntityQuery((entity) => {
    return (
      (entity.type === EntityType.Exchange ||
        entity.type === EntityType.Extension) &&
      entity.ref === id
    );
  });
  const entity = entityQuery.data.entity;
  return entity;
}

export function SetRules({ savedImportId }: { savedImportId: string }) {
  const lang = useLang();
  const [isOpen, setIsOpen] = useState(false);
  const entity = useEntityForImportId(savedImportId);
  const isRulesEnabled = useCanAccessFeature(Features.Rules);

  const analyticsKey = rulesIntegrationsAnalyticsKey("imports list");
  const captureAnalytics = useCaptureAnalytics();

  if (!isRulesEnabled) {
    return null;
  }

  return (
    <>
      <ManageImportsRulesDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={entity?._id ?? savedImportId}
      />
      <OuterBox>
        <InnerBox onClick={() => {}}>
          <TertiaryButton
            size="small"
            startIcon={<FilterAltOutlined />}
            onClick={() => {
              setIsOpen(true);
              captureAnalytics(analyticsKey("view"), { context: "wallet" });
            }}
          >
            {lang.contacts.rules.rulesImportsButton}
          </TertiaryButton>
        </InnerBox>
      </OuterBox>
    </>
  );
}

export function ManageRulesSection({
  savedImportId,
}: {
  savedImportId: string;
}) {
  const lang = useLang();
  const [isOpen, setIsOpen] = useState(false);
  const entity = useEntityForImportId(savedImportId);

  const isRulesEnabled = useCanAccessFeature(Features.Rules);

  const analyticsKey = rulesIntegrationsAnalyticsKey("imports list");
  const captureAnalytics = useCaptureAnalytics();

  if (!isRulesEnabled) {
    return null;
  }

  return (
    <>
      <ManageImportsRulesDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={entity?._id ?? savedImportId}
      />
      <Box>
        <StyledImportTitleTypography>
          {lang.contacts.rules.importsDrawerTitle}
        </StyledImportTitleTypography>
        <Box mt="0.5rem" mb="1rem">
          <TertiaryButton
            size="small"
            startIcon={<FilterAltOutlined />}
            onClick={() => {
              setIsOpen(true);
              captureAnalytics(analyticsKey("view"), { context: "non-wallet" });
            }}
          >
            {lang.contacts.rules.rulesImportsButton}
          </TertiaryButton>
        </Box>
      </Box>
    </>
  );
}

function ManageImportsRulesDrawer({
  isOpen,
  setIsOpen,
  id,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  id: string;
}) {
  const lang = useLang();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      style={{ zIndex: 1000000 }}
    >
      <StyledHeaderContent>
        <DrawerTitle title={lang.contacts.rules.importsDrawerTitle} />
      </StyledHeaderContent>
      <DrawerInnerContent>
        <DrawerRulesManagementForEntity parentId={id} />
      </DrawerInnerContent>
    </Drawer>
  );
}

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.tokens.text.default};
  font-size: 0.75rem;
  font-weight: 500;
`;

const OuterBox = styled(Box)`
  && {
    border-left: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
    border-right: 1px solid
      ${({ theme }) => theme.tokens.border.neutral.default};
    border-bottom: 1px solid
      ${({ theme }) => theme.tokens.border.neutral.default};
    border-radius: 0 0 0.25rem 0.25rem;
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.default};
  }
`;

const InnerBox = styled(Box)`
  && {
    padding: 0.5rem 0.75rem;
    &:not(:first-child) {
      border-top: 1px solid
        ${({ theme }) => theme.tokens.border.neutral.default};
    }
    background-color: transparent;
  }
`;
