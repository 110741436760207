import { Box } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { AccountSelector } from "~/components/settings-modal/views/integrations/AccountSelector";
import { useActionRow } from "~/contexts/ActionRowContext";
import { invariant } from "~/lib/invariant";
import { type ErpAccount } from "~/redux/types";
import { useAtomicUpdateTransactions } from "~/state/actions";
import { useErpAvailableAccountsQuery } from "~/state/erp";
import { Size } from "~/types/enums";
import { type ERPAccountType } from "~/types/enums";
import { type TransactionDetails } from "~/types/index";

type FormData = {
  account: ErpAccount | null;
};

export function TransactionAccountSelector({
  tx,
  accountType,
  disabled,
  onEdit,
}: {
  tx: TransactionDetails;
  accountType: ERPAccountType;
  disabled: boolean;
  onEdit: (isEditing: boolean) => void;
}) {
  const actionRow = useActionRow();
  const availableAccounts = useErpAvailableAccountsQuery().data ?? [];
  const updateRowTransactionMutation = useAtomicUpdateTransactions();
  const currentAccount = tx?.erp.accountMappings?.[accountType] || null;
  const appliedRule = tx?.appliedRules?.find((rule) =>
    rule.operator.toLowerCase().includes(accountType),
  );

  const currentAccountOption =
    availableAccounts.find((account) => account.code === currentAccount) ||
    null;

  // If currentAccount is a number but we can't find it in the availableAccounts
  // It means that the account has been deleted from ERP
  const mappedAccountIsDeleted =
    currentAccount && !isNaN(+currentAccount) && !currentAccountOption;

  const { handleSubmit, control, reset } = useForm<FormData>({
    defaultValues: {
      account: currentAccountOption,
    },
  });

  useEffect(() => {
    reset({
      account: currentAccountOption,
    });
  }, [currentAccountOption, reset]);

  const onSubmit = async (value: FormData) => {
    invariant(value.account, "ERP account must be set");
    const accountCode = value.account.code;
    onEdit(true);

    updateRowTransactionMutation.mutate(
      {
        actionId: actionRow._id,
        update: {
          updateTx: {
            payload: [
              {
                _id: tx._id,
                erpAccountMappings: {
                  [accountType]: accountCode,
                },
              },
            ],
            applySts: false,
            createCategoryRule: false,
          },
        },
      },
      {
        onSettled: () => {
          onEdit(false);
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box minWidth="12rem">
        <Controller
          control={control}
          name="account"
          render={({ field: { onChange, value } }) => (
            <AccountSelector
              isLoading={updateRowTransactionMutation.isPending}
              title={undefined}
              tooltip={undefined}
              value={value}
              size={Size.Small}
              disabled={
                disabled ||
                actionRow.isLocked ||
                updateRowTransactionMutation.isPending
              }
              error={!!mappedAccountIsDeleted}
              ruleId={appliedRule?.ruleId}
              accountType={accountType}
              handleChange={(_e, value) => {
                _e?.preventDefault();
                _e?.stopPropagation();
                onChange(value);
                // auto-submit every change
                handleSubmit(onSubmit)();
              }}
            />
          )}
        />
      </Box>
    </form>
  );
}
