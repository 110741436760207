import deImportInstructionsTyped from "~/lang/translations/de.import-instructions.typed";
import deAppTyped from "~/lang/translations/de.typed";
import enImportInstructionsTyped from "~/lang/translations/en.import-instructions.typed";
import enAppTyped from "~/lang/translations/en.typed";
import esImportInstructionsTyped from "~/lang/translations/es.import-instructions.typed";
import esAppTyped from "~/lang/translations/es.typed";
import frImportInstructionsTyped from "~/lang/translations/fr.import-instructions.typed";
import frAppTyped from "~/lang/translations/fr.typed";
import itImportInstructionsTyped from "~/lang/translations/it.import-instructions.typed";
import itAppTyped from "~/lang/translations/it.typed";

export const deTyped = {
  ...deImportInstructionsTyped,
  ...deAppTyped,
};

export const enTyped = {
  ...enImportInstructionsTyped,
  ...enAppTyped,
};

export const esTyped = {
  ...esImportInstructionsTyped,
  ...esAppTyped,
};

export const frTyped = {
  ...frImportInstructionsTyped,
  ...frAppTyped,
};

export const itTyped = {
  ...itImportInstructionsTyped,
  ...itAppTyped,
};

// this is the raw translations types from the compiled json files

export type LangTyped =
  | typeof enTyped
  | typeof deTyped
  | typeof esTyped
  | typeof frTyped
  | typeof itTyped;
