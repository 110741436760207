import { ReferrerSource } from "@ctc/types";
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  Lock,
  MenuOpen,
  Settings,
  SupervisorAccount,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import React, {
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import useLocalStorageState from "use-local-storage-state";

import { navButtonAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import enterpriseLogo from "~/assets/enterprise-logo.svg";
import enterpriseLogoDark from "~/assets/hr-block-inverted.svg";
import logoLightMode from "~/assets/logo.png";
import logoDarkMode from "~/assets/logo-dark-mode.png";
import { AccountMenu } from "~/components/nav/AccountMenu";
import { ActiveClient } from "~/components/nav/ActiveClient";
import { StepItemType } from "~/components/nav/enum";
import { HelpItem } from "~/components/nav/HelpItem";
import { useIsUrlMatch } from "~/components/nav/hooks/useIsUrlMatch";
import { type ActionItemDetails } from "~/components/nav/NavBar";
import { NewTaxSettingBanner } from "~/components/nav/NewTaxSettingBanner";
import {
  type ButtonItemDetails,
  isDividerItemDetails,
  isLinkItemDetails,
  isSubheadingItemDetails,
  type LinkItemDetails,
  type StepItemDetails,
  type SubheadingItemDetails,
} from "~/components/nav/types";
import { REFERRER_CONFIG } from "~/components/payment/referrer-config";
import { stripePromise } from "~/components/payment/StripePayment";
import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { Calco } from "~/components/ui/calco/Calco";
import { useIsMobile } from "~/components/ui/hooks";
import { SyncStatusChip } from "~/components/ui/SyncStatusBadge";
import { devices } from "~/components/ui/theme/legacy";
import { TertiaryIconButton } from "~/components/ui/ui-buttons/icon-buttons/TertiaryIconButton";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { baseTertiaryButtonColorStyles } from "~/components/ui/ui-buttons/TertiaryButton";
import { REFERRAL_REWARD } from "~/constants/constants";
import { LocalStorageKey } from "~/constants/enums";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { useSetActiveClient } from "~/hooks/useActiveClient";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useIsReferEnabled } from "~/hooks/useIsReferEnabled";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useDesign, useResolvedTheme } from "~/hooks/useTheme";
import { useTransactionsBilledCount } from "~/hooks/useTransactions";
import { displayFiatValue } from "~/lib";
import {
  useIsAccountant,
  useIsManagingClients,
  useUser,
  useUserHasPaid,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Align, Links, Theme } from "~/types/enums";

export const drawerWidth = 240;
export const narrowDrawerWidth = 60;
const transitionDuration = "0.25s";

/**
 * Custom hook for managing the accordion state of navigation tabs
 * @returns [accordionState, setAccordionState] - The current accordion state and function to update it
 */
export function useNavTabAccordionState(): [
  Record<string, boolean>,
  (val: Record<string, boolean>) => void,
] {
  const [accordionState, setAccordionState] = useLocalStorageState<
    Record<string, boolean>
  >(LocalStorageKey.NavTabsAccordionState, { defaultValue: {} });

  return [accordionState, setAccordionState];
}

/**
 * Custom hook for managing the collapsed state of the side navigation
 * @returns [isCollapsed, setIsCollapsed] - Whether the navigation is collapsed and function to update it
 */
export function usePersistedSideNavState(): [
  boolean,
  (isCollapsed: boolean) => void,
] {
  const [isCollapsed, setIsCollapsed] = useLocalStorageState<boolean>(
    LocalStorageKey.SideNavCollapsed,
    { defaultValue: false },
  );

  return [isCollapsed, setIsCollapsed];
}

/**
 * Returns the width of the side nav in pixels.
 * @returns
 */
export const useSideNavWidth = () => {
  const isEmbedded = useIsEmbedded();
  const [isOpen] = usePersistedSideNavOpenState();
  const [isCollapsed] = usePersistedSideNavState();

  if (isEmbedded) {
    return 0;
  }

  const sideNavWidth = isOpen
    ? isCollapsed
      ? narrowDrawerWidth
      : drawerWidth
    : 0;

  return sideNavWidth;
};

export function usePersistedSideNavOpenState(): [
  boolean,
  (isOpen: boolean) => void,
] {
  const isDocked = useMediaQuery(devices.laptop);

  const [isOpen, setIsOpen] = useLocalStorage(
    LocalStorageKey.SideNavOpen,
    isDocked,
  );
  return [isOpen, setIsOpen];
}

const MenuItemText = styled(Typography).attrs({ variant: "Metropolis/Body/Regular" })`
  color: ${(props) => props.theme.tokens.text.default};
`;

const MenuItemEndAdornment = ({ children }: PropsWithChildren) => {
  return <>{children}</>;
};

const MenuItemIcon = styled.div`
  width: 1.125rem;
  height: 1.125rem;
  svg {
    width: 1.125rem;
    height: 1.125rem;
    fill: ${(props) => props.theme.tokens.text.low};
  }
`;

const menuItemCss = css<{
  active: boolean;
  disabled: boolean;
  collapsed: boolean;
}>`
  display: flex;
  padding: 0.5rem;
  padding-right: 0;
  align-items: center;
  width: 100%;

  justify-content: space-between;
  gap: 0.5rem;
  flex: 1 0 0;

  text-decoration: none;
  border-radius: 0.25rem;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
    background: ${({ theme }) => theme.tokens.button.neutral.hover};

    ${MenuItemText} {
      color: ${({ theme }) => theme.tokens.text.high};
    }
    ${MenuItemIcon} svg {
      fill: ${({ theme }) => theme.tokens.text.high};
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.8;
    pointer-events: none;
  `}

  ${({ active, theme }) =>
    active &&
    `
  border-radius: 0.5rem;
  border: 1px solid ${theme.tokens.border.neutral.default};
  background: ${theme.tokens.button.neutral.pressed};

  ${MenuItemText} {
    color: ${theme.tokens.text.high};
  }
  ${MenuItemIcon} svg {
    fill: ${theme.tokens.text.high};
  }

  `}
  ${({ collapsed }) =>
    collapsed &&
    `justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;`}
`;

const MenuItemLinkStyled = styled(Link)<{
  active: boolean;
  disabled: boolean;
  collapsed: boolean;
}>`
  ${menuItemCss}
`;

const MenuItemButtonStyled = styled(Button)<{
  active: boolean;
  disabled: boolean;
  collapsed: boolean;
}>`
  ${menuItemCss}
`;

const MenuItemIconButtonStyled = styled(IconButton)<{
  active: boolean;
  disabled: boolean;
  collapsed: boolean;
}>`
  ${menuItemCss}
`;

const DrawerInner = styled.div<{ collapsed: boolean }>`
  ${(props) =>
    props.collapsed ? `padding: 1rem 0.25rem;` : `padding: 1rem 0.5rem;`}
`;
const MenuList = styled.div`
  display: flex;

  flex-direction: column;
  gap: 0.5rem;
`;
const MenuClientWrapper = styled.div<{ collapsed?: boolean }>`
  border-radius: 0.5rem;
  border: 1px solid ${(props: any) => props.theme.tokens.border.neutral.default};
  padding: 0.25rem;
`;

function MenuItem({
  to,
  onClick,
  children,
  disabled,
  collapsed,
  active,
  className,
}: PropsWithChildren<{
  to?: string;
  onClick?: () => void;
  disabled?: boolean;
  collapsed: boolean;
  active?: boolean;
  className?: string;
}>) {
  const match = useIsUrlMatch(to ?? "");

  if (onClick) {
    if (collapsed) {
      return (
        <MenuItemIconButtonStyled
          onClick={onClick}
          active={active ?? false}
          disabled={!!disabled}
          collapsed={collapsed}
          className={className}
        >
          <Box mt="-0.5rem" pb="0.5rem">
            <MenuItemContents>{children}</MenuItemContents>
          </Box>
        </MenuItemIconButtonStyled>
      );
    }

    return (
      <MenuItemButtonStyled
        onClick={onClick}
        active={active ?? false}
        disabled={!!disabled}
        collapsed={collapsed}
        className={className}
      >
        <MenuItemContents>{children}</MenuItemContents>
      </MenuItemButtonStyled>
    );
  }

  if (!to) {
    return null;
  }

  return (
    <MenuItemLinkStyled
      to={to}
      active={active ?? !!match}
      disabled={!!disabled}
      collapsed={collapsed}
      className={className}
    >
      <MenuItemContents>{children}</MenuItemContents>
    </MenuItemLinkStyled>
  );
}

function MenuItemContents({ children }: PropsWithChildren) {
  const iconAndText: ReactNode[] = [];
  let endAdornment: ReactNode | undefined;
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === MenuItemEndAdornment) {
        endAdornment = child;
      } else {
        iconAndText.push(child);
      }
    }
  });
  return (
    <>
      <Stack direction="row" gap="0.5rem" alignItems="center">
        {iconAndText}
      </Stack>
      {endAdornment ? <Box pr="0.5rem">{endAdornment}</Box> : null}
    </>
  );
}

MenuItem.Icon = MenuItemIcon;
MenuItem.Text = MenuItemText;
MenuItem.EndAdornment = MenuItemEndAdornment;

export const ToolbarButton = styled(TextIconButton)`
  display: flex;
  padding: 0.25rem;
  & svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

function SideMenuItem({
  stepItem,
  isCollapsed,
  active,
}: {
  stepItem: StepItemDetails;
  isCollapsed: boolean;
  active?: boolean;
}) {
  switch (stepItem.type) {
    case StepItemType.Link:
      return (
        <LinkItem
          key={stepItem.name}
          item={stepItem}
          collapsed={isCollapsed}
          active={active}
        />
      );
    case StepItemType.Button:
      return (
        <ButtonItem
          key={stepItem.name}
          item={stepItem}
          collapsed={isCollapsed}
          active={active}
        />
      );
    case StepItemType.Subheading:
      return (
        <SubheadingItem
          key={stepItem.name}
          item={stepItem}
          collapsed={isCollapsed}
        />
      );
    case StepItemType.Divider:
      return <DividerItem key={stepItem.name} />;
    default: {
      const exhaustiveCheck: never = stepItem;
      throw new Error(`unhandled nav item ${exhaustiveCheck}`);
    }
  }
}

function AccordionItem({
  stepItem,
  isCollapsed,
}: {
  stepItem: StepItemDetails;
  isCollapsed: boolean;
}) {
  const [accordionState, setAccordionState] = useNavTabAccordionState();
  const hasChildren = (stepItem.subItems?.length ?? 0) > 0;
  const active = useIsUrlMatch(isLinkItemDetails(stepItem) ? stepItem.to : "");

  if (
    !hasChildren ||
    isDividerItemDetails(stepItem) ||
    isSubheadingItemDetails(stepItem)
  ) {
    return <SideMenuItem stepItem={stepItem} isCollapsed={isCollapsed} />;
  }

  const isExpanded = accordionState[stepItem.id] ?? false;
  const setIsExpanded = (val: boolean) => {
    setAccordionState({
      ...accordionState,
      [stepItem.id]: val,
    });
  };
  const { subItems } = stepItem;

  const AccordionButton = (
    <TextIconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsExpanded(!isExpanded);
      }}
      sx={{
        width: "1.5rem",
        height: "1.5rem",
      }}
    >
      <KeyboardArrowDown
        sx={{
          transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.25s",
          fontSize: "1rem",
        }}
      />
    </TextIconButton>
  );

  return (
    <StyledAccordion
      key={stepItem.name}
      expanded={isExpanded}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
      disableGutters
    >
      <StyledAccordionSummary
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          // If the tab is not active always expand
          if (!active) {
            setIsExpanded(true);
            return;
          }

          // If the tab is active, toggle the accordion
          setIsExpanded(!isExpanded);
        }}
      >
        <SideMenuItem
          stepItem={{ ...stepItem, endAdornment: AccordionButton }}
          isCollapsed={isCollapsed}
        />
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Box width="100%" display="flex" flexDirection="column" gap="0.25rem">
          {subItems?.map((subItem) => (
            <SideMenuItem
              key={stepItem.name}
              stepItem={subItem}
              isCollapsed={isCollapsed}
            />
          ))}
        </Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

export function Menu({
  stepItems,
  isCollapsed,
}: {
  stepItems: StepItemDetails[];
  isCollapsed: boolean;
}) {
  const isManagingClients = useIsManagingClients();
  const isAccountant = useIsAccountant();
  const MenuWrapper = isManagingClients ? MenuClientWrapper : "div";
  const lang = useLang();
  const { setActive } = useSettingsModal();
  const setActiveClient = useSetActiveClient();

  return (
    <Box display="flex" flexDirection="column" height="100%" data-ctc="sidebar">
      <HeaderMaster />
      <Divider />

      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 auto"
        overflow="auto"
      >
        <DrawerInner collapsed={isCollapsed}>
          <Box display="flex" flexDirection="column" gap="0.5rem">
            <MenuWrapper collapsed={isCollapsed}>
              <MenuList>
                {isManagingClients ? (
                  <ActiveClient collapsed={isCollapsed} />
                ) : null}
                {stepItems.map((stepItem) => {
                  return (
                    <AccordionItem
                      key={stepItem.name}
                      stepItem={stepItem}
                      isCollapsed={isCollapsed}
                    />
                  );
                })}
              </MenuList>
            </MenuWrapper>
            <MenuList>
              {isManagingClients ? (
                <>
                  <LinkItem
                    item={{
                      id: "clients",
                      type: StepItemType.Link,
                      to: Links.Clients,
                      name: lang.navbar.myClients,
                      icon: <SupervisorAccount />,
                    }}
                    collapsed={isCollapsed}
                  />
                  {isAccountant ? (
                    <ButtonItem
                      item={{
                        id: "team",
                        type: StepItemType.Button,
                        onClick: () => {
                          setActiveClient("");
                          setActive(SettingsTabs.Team);
                        },
                        name: lang.navbar.myTeam,
                        icon: <SupervisorAccountOutlined />,
                      }}
                      collapsed={isCollapsed}
                    />
                  ) : null}
                  <ButtonItem
                    item={{
                      id: "workspaceSettings",
                      type: StepItemType.Button,
                      onClick: () => {
                        setActiveClient("");
                        setActive(SettingsTabs.Loading);
                      },
                      name: lang.navbar.mySettings,
                      icon: <Settings />,
                    }}
                    collapsed={isCollapsed}
                  />
                </>
              ) : null}
            </MenuList>
          </Box>
        </DrawerInner>
      </Box>
      {!isCollapsed ? <SideNavFooter /> : null}
    </Box>
  );
}

function LinkItem({
  item,
  collapsed,
  active,
}: {
  item: LinkItemDetails;
  collapsed: boolean;
  active?: boolean;
}) {
  const tooltipTitle = collapsed ? item.name : "";

  const captureAnalytics = useCaptureAnalytics();
  const [isOpen, setIsOpen] = usePersistedSideNavOpenState();
  const isMobile = useIsMobile();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const showMobileTooltip = !!(isMobile && item.disabled && item.tooltip);

  // this component doesn't unmount but is hidden when sideBar is closed
  // so need this useEffect to reset the state when the sideBar is closed
  useEffect(() => {
    if (!isOpen) {
      setIsTooltipOpen(false);
    }
  }, [isOpen]);

  function closeSideNav() {
    setIsOpen(false);
  }

  function handleClick() {
    captureAnalytics(navButtonAnalyticsKey, {
      button_name: item.id,
    });

    if (showMobileTooltip) {
      setIsTooltipOpen(!isTooltipOpen);
      return;
    }
    if (isMobile) {
      closeSideNav();
    }
  }
  return (
    <Tooltip
      title={item.tooltip ?? tooltipTitle}
      placement={showMobileTooltip ? "top" : "right"}
      arrow={showMobileTooltip}
      enterDelay={item.tooltip ? undefined : 500}
      open={showMobileTooltip ? isTooltipOpen : undefined}
    >
      <span onClick={handleClick} style={{ width: "100%" }}>
        <MenuItem
          to={item.to}
          disabled={item.disabled}
          collapsed={collapsed}
          className={item.className}
          active={active}
        >
          <MenuItem.Icon>{item.icon}</MenuItem.Icon>
          {collapsed ? null : (
            <MenuItem.Text textAlign="left" sx={{ minWidth: "8rem" }}>
              {item.name}
            </MenuItem.Text>
          )}
          {item.endAdornment && !collapsed ? (
            <MenuItem.EndAdornment>{item.endAdornment}</MenuItem.EndAdornment>
          ) : null}
        </MenuItem>
      </span>
    </Tooltip>
  );
}

function ButtonItem({
  item,
  collapsed,
  active,
}: {
  item: ButtonItemDetails;
  collapsed: boolean;
  active?: boolean;
}) {
  const tooltipTitle = collapsed ? item.name : "";

  const captureAnalytics = useCaptureAnalytics();
  const onClick = () => {
    item.onClick();
    captureAnalytics(navButtonAnalyticsKey, {
      button_name: item.id,
    });
  };

  return (
    <Tooltip title={tooltipTitle} placement="right" enterDelay={500}>
      <span>
        <MenuItem onClick={onClick} collapsed={collapsed} active={active}>
          <MenuItem.Icon>{item.icon}</MenuItem.Icon>
          {collapsed ? null : (
            <MenuItem.Text textAlign="left" sx={{ minWidth: "8rem" }}>
              {item.name}
            </MenuItem.Text>
          )}
        </MenuItem>
      </span>
    </Tooltip>
  );
}

function SubheadingItem({
  item,
  collapsed,
}: {
  item: SubheadingItemDetails;
  collapsed: boolean;
}) {
  const { tokens } = useDesign();

  if (collapsed) {
    return null;
  }

  return (
    <Typography
      variant="Metropolis/Caption/Medium/Bold"
      color={tokens.text.low}
      textAlign="left"
      sx={{ mt: "0.5rem", ml: "0.5rem", minWidth: "8rem" }}
    >
      {item.name}
    </Typography>
  );
}

function DividerItem() {
  const { tokens } = useDesign();
  return (
    <Divider
      sx={{ mx: "0.25rem", borderColor: tokens.border.neutral.default }}
    />
  );
}

export const NewAppBar = styled(AppBar)`
  border-bottom: 1px solid
    ${(props) => props.theme.tokens.border.neutral.medium};
  background: ${(props) => props.theme.tokens.elevation.low};
  box-shadow: none;
`;

export const horizontalNavHeight = "2.5rem";

const HeaderMasterStyled = styled.div`
  display: flex;
  height: ${horizontalNavHeight};
  padding: 0.5rem 1.0625rem 0.5rem 1.125rem;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
`;
export function HeaderMaster(props: PropsWithChildren) {
  return <HeaderMasterStyled {...props} />;
}

const LeftActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
const RightActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
`;

/**
 * Styled component for the refer-a-friend button
 */
const ReferButton = styled(Button)<{ isMobile: boolean }>`
  background-color: ${({ theme }) =>
    theme.tokens.button.subtle.default} !important;
  min-width: 0;

  ${({ isMobile, theme }) =>
    isMobile
      ? `
    padding: 0.125rem 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
  `
      : `
    padding: 0.125rem 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    transition: width 2s ease;
    width: auto;
    
    &:hover {
      background-color: ${theme.tokens.button.subtle.hover} !important;
    }
  `}
`;

/**
 * Styled component for the animated text in the refer button
 */
const ReferButtonText = styled(Typography)`
  max-width: 0;
  opacity: 0;
  margin-left: 0;
  animation: expandText 2.25s ease forwards;

  @keyframes expandText {
    0% {
      max-width: 0;
      opacity: 0;
      margin-left: 0;
    }
    20% {
      max-width: 0;
      opacity: 0;
      margin-left: 0;
    }
    30% {
      opacity: 1;
      margin-left: 0.125rem;
    }
    100% {
      max-width: 200px;
      opacity: 1;
      margin-left: 0.125rem;
    }
  }
`;

export function SideNav({
  stepItems,
  accountItems,
  logoutItem,
  isEnterprise,
  hasImportedData,
  children,
}: PropsWithChildren<{
  stepItems: StepItemDetails[];
  accountItems: StepItemDetails[];
  logoutItem: ActionItemDetails;
  isEnterprise: boolean;
  hasImportedData: boolean;
}>) {
  const theme = useResolvedTheme();
  const lang = useLang();
  const captureAnalytics = useCaptureAnalytics();
  const navigate = useNavigate();
  const isReferAFriendEnabled = useIsReferEnabled();
  const { tokens } = useDesign();
  const user = useUser();

  // Docked on laptop+, overlay on mobile
  const isDocked = useMediaQuery(devices.laptop);
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = usePersistedSideNavOpenState();

  const [isCollapsed, setIsCollapsed] = usePersistedSideNavState();

  const drawerSize = useMemo(() => {
    if (isDocked && isCollapsed) {
      return narrowDrawerWidth;
    }
    return drawerWidth;
  }, [isDocked, isCollapsed]);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  if (!user) {
    return null;
  }

  const logo = theme === Theme.Dark ? logoDarkMode : logoLightMode;
  return (
    <Box sx={{ display: "flex" }}>
      <NewAppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <HeaderMaster data-ctc="header">
          <LeftActions>
            <ToolbarButton onClick={handleDrawerToggle}>
              {isOpen ? <MenuOpen /> : <MenuOutlinedIcon />}
            </ToolbarButton>
            <Typography variant="Metropolis/Body/Regular">
              <Link
                to={
                  isEnterprise || hasImportedData
                    ? Links.Dashboard
                    : Links.Imports
                }
                css={`
                  display: flex;
                  align-items: center;
                  text-decoration: none;
                  color: inherit;
                `}
              >
                <img
                  src={logo}
                  alt="Crypto Tax Calculator Logo"
                  css={`
                    width: 6.9375rem;
                    height: 1.75rem;
                  `}
                />
              </Link>
            </Typography>
            {isEnterprise && (
              <div
                css={`
                  border-left: 1px solid
                    ${(props: any) => props.theme.tokens.border.neutral.default};
                  padding-left: 0.5rem;
                  height: 1.25rem;
                  display: flex;
                  align-items: center;
                `}
              >
                <img
                  src={
                    theme === Theme.Light ? enterpriseLogo : enterpriseLogoDark
                  }
                  alt="H&R Logo"
                  css={`
                    width: 5.3125rem;
                    height: 0.92794rem;
                  `}
                />
              </div>
            )}
          </LeftActions>
          <RightActions>
            {isReferAFriendEnabled && !isMobile ? (
              <ReferButton
                isMobile={isMobile}
                onClick={() => {
                  captureAnalytics(navButtonAnalyticsKey, {
                    button_name: "refer_friend",
                  });
                  navigate(Links.ReferAFriend);
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Calco
                    type="captain"
                    eagerLoad
                    style={{ width: 20, height: 20 }}
                  />
                  {!isMobile ? (
                    <ReferButtonText
                      variant="Metropolis/Caption/Medium/Regular"
                      color={tokens.text.brand}
                    >
                      {lang.navbar.referGetHundredCash({
                        reward: displayFiatValue({
                          value: REFERRAL_REWARD,
                          localCurrency: user?.planCurrency,
                          fractionDigits: 0,
                        }),
                      })}
                    </ReferButtonText>
                  ) : null}
                </Box>
              </ReferButton>
            ) : null}
            <HelpItem />
            <div>
              <SyncStatusChip />
            </div>
            <Elements stripe={stripePromise}>
              <AccountMenu
                align={Align.Left}
                accountItems={accountItems}
                logoutItem={logoutItem}
              />
            </Elements>
          </RightActions>
        </HeaderMaster>
        {isDocked ? (
          <Box
            position="absolute"
            top="3.0625rem"
            left={isOpen ? drawerSize - 10 : -20}
            height="0"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              transition: `left ${transitionDuration} ease-in-out`,
            }}
          >
            <StyledIconButton
              size="small"
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
              sx={{
                borderRadius: "100%",
              }}
            >
              {isCollapsed ? (
                <ChevronRight sx={{ fontSize: "1rem" }} />
              ) : (
                <ChevronLeft sx={{ fontSize: "1rem" }} />
              )}
            </StyledIconButton>
          </Box>
        ) : null}
      </NewAppBar>

      <Box
        component="nav"
        width={isDocked && isOpen ? drawerSize : 0}
        sx={{
          transition: `width ${transitionDuration} ease-in-out`,
        }}
        aria-label="nav"
      >
        {/* Mobile Drawer */}
        {!isDocked && (
          <Drawer
            variant="temporary"
            open={isOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerSize,
                backgroundColor: tokens.elevation.low,
                backgroundImage: "none",
              },
            }}
          >
            <Menu stepItems={stepItems} isCollapsed={false} />
          </Drawer>
        )}

        {/* Desktop Drawer */}
        {isDocked && (
          <Drawer
            variant="persistent"
            open={isOpen}
            anchor="left"
            onClose={handleDrawerToggle}
            sx={{
              width: drawerSize,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerSize,
                boxSizing: "border-box",
                backgroundColor: tokens.elevation.low,
                backgroundImage: "none",
                borderRight: `1px solid ${tokens.border.neutral.medium}`,
                height: "100vh",
              },

              "& .MuiPaper-root": {
                transition: `width ${transitionDuration} ease-in-out`,
              },
            }}
          >
            <Menu stepItems={stepItems} isCollapsed={isCollapsed} />
          </Drawer>
        )}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: isDocked ? `calc(100% - ${drawerSize}px)` : "100%",
          transition: `width ${transitionDuration} ease-in-out`,
        }}
      >
        <HeaderMaster />
        <NewTaxSettingBanner />
        <Container
          maxWidth="xl"
          sx={{
            padding: {
              xs: "2.5rem 1rem !important",
              sm: "2.5rem 2rem !important",
            },
          }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
}

/**
 * Footer component for the side navigation that displays referrer information
 * and a call-to-action button for tax reports
 */
export const SideNavFooter = () => {
  const lang = useLang();
  const navBarFooterLang = lang.navbar.footer;
  const navigate = useNavigate();
  const { tokens } = useDesign();
  const user = useUser();
  const billedCount = useTransactionsBilledCount();
  const userHasPaid = useUserHasPaid();

  const getReferrerText = (): string => {
    if (!user?.referrerSource) {
      if (billedCount > 0) {
        return navBarFooterLang.youHaveXTransactions({ count: billedCount });
      }
    }

    const referrerConfig = user?.referrerSource
      ? REFERRER_CONFIG[user.referrerSource]
      : undefined;
    if (referrerConfig?.sideNavPromoText) {
      return referrerConfig.sideNavPromoText(lang);
    }

    // Ultimate fallback
    return "";
  };

  const getReferrerLogo = () => {
    if (!user?.referrerSource) {
      return null;
    }

    // Get the referrer config for the current user
    const referrerConfig = REFERRER_CONFIG[user.referrerSource];

    if (referrerConfig) {
      // Return the logo component from the referrer config
      // Wrap it in a div with the appropriate height
      return (
        <Box
          sx={{
            height:
              // MetaMask and Metamask Embedded have a vertical logo
              // so we need to increase the height
              user.referrerSource === ReferrerSource.MetaMask ||
              user.referrerSource === ReferrerSource.MetamaskEmbedded
                ? "1.5rem"
                : "1.25rem",
            display: "flex",
            alignItems: "center",
            "& img, & svg": {
              maxHeight: "100%",
              maxWidth: "100%",
              width: "auto",
              height: "auto",
              objectFit: "contain",
            },
          }}
        >
          {referrerConfig.logo()}
        </Box>
      );
    }

    return null;
  };

  if (userHasPaid) {
    return null;
  }

  return (
    <Box padding="0.5rem" mt="auto" flexShrink={0} boxSizing="border-box">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          borderRadius: "0.5rem",
          border: "1px solid",
          borderColor: tokens.border.neutral.default,
          backgroundColor: tokens.elevation.medium,
          padding: "0.5rem",
          boxSizing: "border-box",
        }}
      >
        {user?.referrerSource && REFERRER_CONFIG[user.referrerSource] ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {getReferrerLogo()}
          </Box>
        ) : null}
        <Typography
          textAlign="center"
          variant="Metropolis/Body/Bold"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {getReferrerText()}
        </Typography>
        <PrimaryButton
          onClick={() => {
            navigate(Links.Plans);
          }}
          startIcon={<Lock />}
          sx={{
            width: "100%",
          }}
        >
          {navBarFooterLang.buttonCta}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

const StyledIconButton = styled(TertiaryIconButton)`
  ${baseTertiaryButtonColorStyles}
  && {
    border-radius: 100%;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const StyledAccordion = styled(Accordion)<{
  expanded: boolean;
}>`
  && {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: none;
    border: 1px solid
      ${({ theme, expanded }) =>
        expanded ? `${theme.tokens.border.neutral.default}` : "transparent"};
  }

  &.MuiAccordion-root {
    background-color: ${({ theme, expanded }) =>
      expanded ? theme.tokens.elevation.medium : "transparent"};
    background-image: none !important;
  }

  &::before {
    display: none;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    width: 100%;
    padding: 0;
    margin: -0.375rem 0;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    margin-top: 0.125rem;
    width: 100%;
    padding: 0.25rem;
  }
`;
