export enum SettingsTabs {
  Profile = "account",
  General = "general",
  Permissions = "permissions",
  IntegrationsRedirect = "integrations", // Here purely for a redirect for now
  IntegrationsDeprecated = "integrations-deprecated", // Here only to show to old users
  Billing = "billing",
  Loading = "loading",
  Alpha = "alpha",
  LockPeriod = "lock-period",
  Team = "team",
  Plan = "plan",
  Portfolio = "portfolio",
}
export enum Variation {
  DeleteAccount = "DeleteAccount",
  CancelPlan = "CancelPlan",
}
export enum InviteLinkVariant {
  EDIT = "edit",
  READ = "read",
  WIDE = "wide",
}
export enum ReportSettingsActionType {
  SetTaxSettings = "SetTaxSettings",
}
export enum ERPProvider {
  Xero = "xero",
  Qbo = "qbo",
}
