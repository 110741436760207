import { isValidReferrerSource, ReferrerSource } from "@ctc/types";
import Cookies from "js-cookie";
import { type ReactNode } from "react";

import { BitcoinDotComAuLogo } from "~/components/payment/referrer-logos/BitcoinDotComAuLogo";
import { CoinbaseLogo } from "~/components/payment/referrer-logos/CoinbaseLogo";
import { CoinbaseOneLogo } from "~/components/payment/referrer-logos/CoinbaseOneLogo";
import { CoinjarLogo } from "~/components/payment/referrer-logos/CoinjarLogo";
import { CoinstatsLogo } from "~/components/payment/referrer-logos/CoinstatsLogo";
import { IndependentReserveLogo } from "~/components/payment/referrer-logos/IndependentReserveLogo";
import { MetaMaskLogo } from "~/components/payment/referrer-logos/MetaMaskLogo";
import { PhantomLogo } from "~/components/payment/referrer-logos/PhantomLogo";
import { ReferAFriendLogo } from "~/components/payment/referrer-logos/ReferAFriend";
import { BootstrapQueryParameter, CookieKey } from "~/constants/enums";
import { type Translation } from "~/lang";

import { Theme } from "../../types/enums";

/**
 * Get the initial referrer source from the URL params or cookies
 * @returns The referrer source or undefined if it is not valid
 */
export function getInitialReferrerSource() {
  const urlParams = new URLSearchParams(window.location.search);
  const referrerSource =
    urlParams.get(BootstrapQueryParameter.ReferrerSource) ||
    Cookies.get(CookieKey.ReferrerSource); // cookie should be last since it's the oldest source

  if (!referrerSource || !isValidReferrerSource(referrerSource)) {
    return;
  }
  return referrerSource;
}

type ReferrerConfig = {
  /** Shown on the plans page */
  logo: (params?: { fillOverride?: string }) => ReactNode;
  /** Shown above the sign up button on the sign up page */
  signupPromoText: (lang: Translation) => string;
  /** Shown on the side nav */
  sideNavPromoText?: (lang: Translation) => string;
  /** Shown on the sign up page as a partner */
  partnerLogo: (params?: {
    fillOverride?: string;
    themeOverride?: Theme;
    customColor?: string;
  }) => ReactNode;
  /** Name of the partner */
  label: string;
  /** Whether this is a referral offer */
  isReferralOffer?: boolean;
};

export const REFERRER_CONFIG: Record<
  ReferrerSource,
  ReferrerConfig | undefined
> = {
  [ReferrerSource.Coinbase]: {
    signupPromoText: (lang) => lang.auth.coinbase30Banner,
    sideNavPromoText: (lang) =>
      lang.navbar.footer.xPercentOffAllPlans({ discount: "30" }),
    logo: () => <CoinbaseLogo />,
    partnerLogo: () => <CoinbaseLogo />,
    label: "Coinbase",
  },
  [ReferrerSource.MetaMask]: {
    signupPromoText: (lang) => lang.auth.metamask30Banner,
    sideNavPromoText: (lang) =>
      lang.navbar.footer.xPercentOffAllPlans({ discount: "30" }),
    logo: () => <MetaMaskLogo />,
    partnerLogo: ({ customColor } = {}) => (
      <MetaMaskLogo themeOverride={Theme.Dark} customColor={customColor} />
    ),
    label: "MetaMask",
  },
  [ReferrerSource.MetamaskEmbedded]: {
    signupPromoText: (lang) => lang.auth.metamask30Banner,
    sideNavPromoText: (lang) =>
      lang.navbar.footer.xPercentOffAllPlans({ discount: "30" }),
    logo: () => <MetaMaskLogo />,
    partnerLogo: ({ customColor } = {}) => (
      <MetaMaskLogo themeOverride={Theme.Dark} customColor={customColor} />
    ),
    label: "MetaMask",
  },
  [ReferrerSource.CoinbaseOne]: {
    signupPromoText: (lang) => {
      return lang.auth.coinbaseOne1DollarBannerNoCurrency;
    },
    logo: () => <CoinbaseOneLogo />,
    partnerLogo: () => <CoinbaseLogo />,
    label: "Coinbase One",
  },
  [ReferrerSource.CoinbaseOnePremium]: {
    signupPromoText: (lang) => lang.auth.coinbaseOnePremium40Banner,
    logo: () => <CoinbaseOneLogo />,
    partnerLogo: () => <CoinbaseLogo />,
    label: "Coinbase One",
  },
  [ReferrerSource.Coinstats]: {
    signupPromoText: (lang) => lang.auth.coinstatsBanner,
    logo: ({ fillOverride } = {}) => (
      <CoinstatsLogo fillOverride={fillOverride} />
    ),
    partnerLogo: ({ fillOverride } = {}) => (
      <CoinstatsLogo fillOverride={fillOverride} />
    ),
    label: "CoinStats",
  },
  [ReferrerSource.Coinjar]: {
    signupPromoText: (lang) => lang.auth.coinjar30Banner,
    sideNavPromoText: (lang) =>
      lang.navbar.footer.xPercentOffAllPlans({ discount: "30" }),
    logo: ({ fillOverride } = {}) => (
      <CoinjarLogo fillOverride={fillOverride} />
    ),
    partnerLogo: ({ fillOverride } = {}) => (
      <CoinjarLogo fillOverride={fillOverride} />
    ),
    label: "CoinJar",
  },
  [ReferrerSource.IndependentReserve]: {
    signupPromoText: (lang) => lang.auth.independentReserve30Banner,
    sideNavPromoText: (lang) =>
      lang.navbar.footer.xPercentOffAllPlans({ discount: "30" }),
    // IR Logo is a bit small so we force a larger height to make it more readable
    logo: () => <IndependentReserveLogo height={30} />,
    // IR logo is in dark section of signup page, so show white version
    partnerLogo: () => <IndependentReserveLogo themeOverride={Theme.Dark} />,
    label: "Independent Reserve",
  },
  [ReferrerSource.BitcoinDotComAu]: {
    signupPromoText: (lang) => lang.auth.bitcoinDotComAu30Banner,
    sideNavPromoText: (lang) =>
      lang.navbar.footer.xPercentOffAllPlans({ discount: "30" }),
    logo: () => <BitcoinDotComAuLogo />,
    // BTC logo is in dark section of signup page, so show white version
    partnerLogo: () => <BitcoinDotComAuLogo themeOverride={Theme.Dark} />,
    label: "Bitcoin.com.au",
  },
  [ReferrerSource.ReferAFriend]: {
    logo: () => <ReferAFriendLogo />,
    signupPromoText: () => "",
    sideNavPromoText: (lang) =>
      lang.navbar.footer.referralXOffAllPlans({ discount: "20" }),
    partnerLogo: () => <ReferAFriendLogo />,
    isReferralOffer: true,
    label: "",
  },
  [ReferrerSource.CoinbaseEmbedded]: undefined,
  [ReferrerSource.KrakenEmbedded]: undefined,
  [ReferrerSource.BinanceEmbedded]: undefined,
  [ReferrerSource.Phantom]: {
    logo: () => <PhantomLogo />,
    signupPromoText: (lang) => lang.auth.signupPromoText.phantom,
    partnerLogo: () => <PhantomLogo />,
    label: "Phantom",
  },
  [ReferrerSource.CTC]: undefined,
} as const;
