import { Box, Switch, Tooltip, Typography } from "@mui/material";
import type * as React from "react";

type ImportToggleProps = {
  title: string;
  checked: boolean;
  tooltipOn: string;
  tooltipOff: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export function ImportToggleSwitch({
  title,
  checked,
  tooltipOn,
  tooltipOff,
  onChange,
}: ImportToggleProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="auto"
      justifyContent="flex-start"
    >
      <Tooltip title={checked ? tooltipOn : tooltipOff}>
        <Switch
          data-ctc="import-toggle-switch"
          checked={checked}
          onChange={onChange}
          color="primary"
          inputProps={{
            "aria-label": title,
          }}
        />
      </Tooltip>
      <Typography variant="Metropolis/Header/H5">{title}</Typography>
    </Box>
  );
}
