import { type SupportedLang } from "@ctc/types";
import { Translate } from "@mui/icons-material";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { type ChangeEvent } from "react";
import { useDispatch } from "react-redux";

import { useDesign } from "~/hooks/useTheme";
import {
  updateLanguage,
  useLang,
  useLanguageOptions,
  useLanguagePreference,
} from "~/redux/lang";

export function LanguageSelector() {
  const { tokens } = useDesign();
  const lang = useLang();
  const languagePreference = useLanguagePreference();
  const languageOptions = useLanguageOptions() || [];
  const dispatch = useDispatch();

  return (
    <TextField
      select
      variant="outlined"
      onChange={(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        const language = event.target.value as SupportedLang;
        dispatch(updateLanguage(language));
      }}
      value={languagePreference}
      size="small"
      InputLabelProps={{
        shrink: false,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Translate sx={{ fontSize: "1rem", color: tokens.text.default }} />
          </InputAdornment>
        ),
        style: {
          fontSize: "0.875rem",
          color: tokens.text.default,
        },
      }}
    >
      {languageOptions.map((language: SupportedLang) => {
        return (
          <MenuItem key={lang.supportedLanguages[language]} value={language}>
            {lang.supportedLanguages[language]}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
