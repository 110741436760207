import { Trade } from "@ctc/types";

import { type Translation } from "~/lang/index";
import { GroupedTrade } from "~/types/enums";
import { type ActionType, type TransactionDetails } from "~/types/index";

/**
 * Helper to get the label for an action/trade/action type
 *
 * @param actionType the type you want the label of
 * @param lang the language object
 * @param txs if getting the label for an action type, try pass in the txs where
 * possible so we can make any adjustments based on the action contents
 */
export function getActionTypeName({
  actionType,
  lang,
  txs = [],
}: {
  actionType: ActionType;
  lang: Translation;
  txs?: TransactionDetails[];
}) {
  if (actionType === GroupedTrade.Uncategorised && txs?.length > 0) {
    if (txs.every((tx) => tx.trade === Trade.In)) {
      return lang.tradeType[Trade.In];
    }
    if (txs.every((tx) => tx.trade === Trade.Out)) {
      return lang.tradeType[Trade.Out];
    }
  }
  return lang.tradeType[actionType];
}
