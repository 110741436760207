import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components/macro";

import { RECAPTCHA_SITE_KEY } from "~/constants/constants";
import { useLocale } from "~/redux/lang";

export const Recaptcha = ({
  recaptchaRef,
}: {
  recaptchaRef: React.RefObject<ReCAPTCHA>;
}) => {
  const locale = useLocale();

  return (
    <Placement>
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        size="invisible"
        ref={recaptchaRef}
        badge="bottomright"
        hl={locale}
        key={locale}
      />
    </Placement>
  );
};

// This is required to hide the recaptcha badge
// See: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
const Placement = styled.div`
  .grecaptcha-badge {
    visibility: hidden;
  }
`;
