// TODO: add tooltips, add filter based on chip (not just all), fix text overflow, transition for show/hide more

import { Blockchain } from "@ctc/types";
import {
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from "@mui/icons-material";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { type ReactNode, useRef } from "react";
import { css } from "styled-components";
import styled from "styled-components/macro";

import { generateWarnings } from "~/components/dashboard/ExchangeBreakdown";
import {
  type BalanceWarning,
  BalanceWarningIcon,
  NegativeBalanceWarning,
} from "~/components/dashboard/HoldingsBreakdownDropdown";
import {
  importDrawerKey,
  useCaptureImportAnalytics,
} from "~/components/imports/AnalyticsHelpers";
import { MoreOptionsDropdown } from "~/components/imports-v2/MoreOptionsDialog";
import { type DrawerOptionsType } from "~/components/imports-v2/types";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";

import { devices } from "~/components/ui/theme/legacy";
import { DEFAULT_IGNORE_BALANCE_THRESHOLD } from "~/constants/constants";
import { LocalStorageKey } from "~/constants/enums";
import {
  LocalStorageUserKeyGenerator,
  useLocalStorage,
} from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import {
  displayCryptoQuantity,
  displayCurrency,
  displayFiatValue,
} from "~/lib/index";
import { useLocalCurrency, useUser } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import {
  BalanceError,
  BalanceType,
  FilterOperators,
  Links,
} from "~/types/enums";
import {
  type CurrencyIdentifier,
  type FilterQuery,
  isBlockchainImport,
  type PricedHoldingsDetails,
  type SavedImportOptionByAccount,
} from "~/types/index";

import { useIsEmbedded } from "../../hooks/useIsEmbedded";

type SortType = {
  field: TableHeaders;
  ascending: boolean;
};

enum TableHeaders {
  Title = "title",
  Price = "price",
  Amount = "amount",
  Value = "value",
  Options = "options",
}

const TableLayout = css`
  display: grid;
  color: ${({ theme }) => theme.tokens.text.low} !important;
  background: ${({ theme }) => theme.tokens.background.neutral.lowest.default};
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  grid-template-rows: auto;
  grid-template-areas: "title price amount value options";
  grid-template-columns: 16.25rem repeat(3, 1fr) 2.25rem;
  gap: 0.5rem;

  &:first-child {
    border-top: none;
  }

  @media ${devices.tablet} {
    gap: 1rem;
  }
`;

const AccTableHeader = styled(Box)`
  ${TableLayout}
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0.75rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  @media ${devices.tablet} {
    padding: 0.75rem 0rem 0.75rem 1.25rem;
  }

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }
`;

const AccTableRow = styled(Box)`
  ${TableLayout}
  align-items: center;
  padding: 0.75rem 0.5rem;
  gap: 0.5rem;
  &&:hover {
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.hover};

    & .MuiTypography-captionTextRegular {
      color: ${({ theme }) => theme.tokens.text.high};
    }
  }

  & .MuiTypography-captionTextRegular {
    color: ${({ theme }) => theme.tokens.text.low};
  }

  @media ${devices.tablet} {
    padding: 1rem 0rem 1rem 1rem;
    height: 3.5rem;
  }
`;

const AccTableCell = styled(Box)`
  && {
    align-items: center;
    overflow: hidden;
  }
`;

const AccTableHeaderCell = ({
  text,
  align,
  setSortOrder,
  activeSort,
  header,
  info,
}: {
  text: string;
  align?: string;
  setSortOrder?: (sort: SortType) => void;
  activeSort: SortType;
  header: TableHeaders;
  info?: string;
}) => {
  const { tokens } = useDesign();
  const captureAnalyics = useCaptureImportAnalytics();
  const { field, ascending } = activeSort;
  const isActive = field === header;

  const handleChange = () => {
    if (setSortOrder) {
      setSortOrder({
        ascending: !ascending,
        field: header,
      });
      captureAnalyics(importDrawerKey("currencies_sort"), { header });
    }
  };

  return (
    <Box
      justifySelf={align}
      position="relative"
      onClick={handleChange}
      gridArea={header}
      display="flex"
      alignItems="center"
    >
      {info ? (
        <Tooltip title={info}>
          <Box display="flex" mr="0.25rem" mt="-0.125rem">
            <InfoOutlined style={{ fontSize: "1.125rem" }} />
          </Box>
        </Tooltip>
      ) : null}

      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        sx={{
          color: isActive ? tokens.text.default : tokens.text.low,
          "&:hover": {
            color: tokens.text.default,
          },
          cursor: "pointer",
        }}
        data-uncensored="true"
      >
        {text}
      </Typography>
      {setSortOrder && (
        <>
          {isActive && ascending ? (
            <KeyboardArrowUp
              style={{
                color: tokens.text.default,
                position: "absolute",
                right: "-1.75rem",
              }}
            />
          ) : (
            <KeyboardArrowDown
              style={{
                color: isActive ? tokens.text.default : tokens.text.disabled,
                position: "absolute",
                right: "-1.75rem",
              }}
              visibility={isActive ? "visible" : "hidden"}
            />
          )}
        </>
      )}
    </Box>
  );
};

const sortByField = (
  sortOptions: SortType,
  a: PricedHoldingsDetails & { currencyIdentifier: CurrencyIdentifier },
  b: PricedHoldingsDetails & { currencyIdentifier: CurrencyIdentifier },
): number => {
  const { ascending, field } = sortOptions;
  const sortOrder = ascending ? 1 : -1;

  switch (field) {
    case TableHeaders.Title:
      return (
        a.currencyIdentifier.name.localeCompare(b.currencyIdentifier.name) *
        sortOrder
      );
    case TableHeaders.Price:
      return (a.marketPrice - b.marketPrice) * sortOrder;
    case TableHeaders.Amount:
      return (a.balance - b.balance) * sortOrder;
    case TableHeaders.Value:
      return (a.totalValue - b.totalValue) * sortOrder;
  }

  return 0;
};

const TableDummyRow = () => {
  return (
    <AccTableRow>
      {Object.values(TableHeaders)
        .filter((h) => h !== TableHeaders.Options)
        .map((header) => (
          <AccTableCell key={header} gridArea={header}>
            <Skeleton width="100%" height="2.7rem" />
          </AccTableCell>
        ))}
    </AccTableRow>
  );
};

export const CurrencyTable = ({
  holdings,
  importOption,
  sourceQuery,
  importId,
  width = "auto",
  isLoading,
}: {
  importOption: SavedImportOptionByAccount;
  importId?: string;
  sourceQuery: FilterQuery[];
  holdings: (PricedHoldingsDetails & {
    currencyIdentifier: CurrencyIdentifier;
  })[];
  width?: string;
  isLoading?: boolean;
}) => {
  const user = useUser();
  const lang = useLang();
  const { tokens } = useDesign();
  const headingLang = lang.imports.drawer.currencyTableHeadings;
  const [sortOrder, setSortOrder] = useLocalStorage<SortType>(
    LocalStorageUserKeyGenerator(
      user?.uid,
      LocalStorageKey.DrawerCurrencySortOption,
    ),
    {
      field: TableHeaders.Value,
      ascending: false,
    },
  );
  const taxSettings = useTaxSettings();
  const ignoreThreshold =
    taxSettings?.ignoreBalanceRemainingValueThreshold ||
    DEFAULT_IGNORE_BALANCE_THRESHOLD;
  const holdingsBalanceType =
    taxSettings?.holdingsBalanceType || BalanceType.Reported;

  const sortedHoldings = [...holdings].sort((a, b) =>
    sortByField(sortOrder, a, b),
  );

  const sortedHoldingsWithWarnings = sortedHoldings.map((holding) => {
    return {
      ...holding,
      warning: generateWarnings(holding, ignoreThreshold, holdingsBalanceType),
    };
  });

  // virtualiser setup
  const itemHeightPx = 56;
  const rowsToShow = Math.min(5, sortedHoldingsWithWarnings.length);
  const headerHeightPx = 41;
  const boxLoadedHeight = headerHeightPx + rowsToShow * itemHeightPx;
  const boxLoadingHeight = headerHeightPx + itemHeightPx * 3;
  const boxHeight = isLoading ? boxLoadingHeight : boxLoadedHeight;
  const parentRef = useRef(null);
  const rowVirtualizer = useVirtualizer({
    count: sortedHoldingsWithWarnings.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemHeightPx,
    overscan: 10,
  });

  if (!holdings.length && !isLoading) {
    return (
      <Box minWidth="42.5rem" p="1rem">
        <Typography variant="Metropolis/Body/Bold" sx={{ color: tokens.text.low }}>
          {lang.holdingsDropdown.noHoldings}
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Box
        sx={{
          height: boxHeight,
          width,
        }}
      >
        <AccTableHeader>
          <AccTableHeaderCell
            header={TableHeaders.Title}
            text={`${headingLang.currencies} ${
              isLoading ? "" : `(${holdings.length})`
            }`}
            activeSort={sortOrder}
            setSortOrder={setSortOrder}
            align="start"
          />
          <AccTableHeaderCell
            header={TableHeaders.Price}
            text={headingLang.price}
            activeSort={sortOrder}
            setSortOrder={setSortOrder}
            align="end"
          />
          <AccTableHeaderCell
            header={TableHeaders.Amount}
            text={headingLang.amount}
            activeSort={sortOrder}
            setSortOrder={setSortOrder}
            align="end"
            info={lang.holdingsDropdown.valueTooltip}
          />
          <AccTableHeaderCell
            header={TableHeaders.Value}
            text={headingLang.value}
            activeSort={sortOrder}
            setSortOrder={setSortOrder}
            align="end"
          />
          <AccTableCell />
        </AccTableHeader>
        {isLoading ? (
          Array.from({ length: 3 }, (_, index) => index).map((i) => (
            <TableDummyRow key={i} />
          ))
        ) : (
          <div
            ref={parentRef}
            style={{
              height: `${rowsToShow * itemHeightPx}px`,
              width,
              overflow: "auto",
            }}
          >
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const holding = sortedHoldingsWithWarnings[virtualRow.index];
                const getAmountWarningIcon = (warning?: BalanceWarning) => {
                  switch (warning?.type) {
                    case BalanceError.Inconsistent:
                      return (
                        <BalanceWarningIcon
                          warning={warning}
                          iconOverride={
                            <InfoOutlined
                              style={{
                                height: "1rem",
                                width: "1rem",
                                marginLeft: "0.25rem",
                                marginBottom: "0.15rem",
                              }}
                            />
                          }
                        />
                      );
                    case BalanceError.Negative:
                      return (
                        <NegativeBalanceWarning
                          iconOverride={
                            <InfoOutlined
                              style={{
                                height: "1rem",
                                width: "1rem",
                                marginLeft: "0.25rem",
                              }}
                            />
                          }
                        />
                      );
                    default:
                      return null;
                  }
                };
                return (
                  <div
                    key={virtualRow.index}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <AccTableRow key={holding.currencyIdentifier.id}>
                      <CurrencyCell
                        currencyIdentifier={holding.currencyIdentifier}
                        importOption={importOption}
                      />
                      <NumberCell
                        gridArea={TableHeaders.Price}
                        value={holding.marketPrice}
                        isFiat
                      />
                      <NumberCell
                        gridArea={TableHeaders.Amount}
                        value={holding.balance}
                        postfixIcon={getAmountWarningIcon(holding.warning)}
                      />
                      <NumberCell
                        gridArea={TableHeaders.Value}
                        value={holding.totalValue}
                        isFiat
                      />
                      <MoreOptions
                        currencyIdentifier={holding.currencyIdentifier}
                        sourceQuery={sourceQuery}
                        importId={importId}
                      />
                    </AccTableRow>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
};

const CurrencyCell = ({
  currencyIdentifier,
  importOption,
}: {
  currencyIdentifier: CurrencyIdentifier;
  importOption: SavedImportOptionByAccount;
}) => {
  const lang = useLang();
  const { id, name, symbol } = currencyIdentifier;

  // We want to show a special message for SOL on the Solana chain.
  const isSolanaOnSolanaChain: boolean =
    id === "solana" &&
    isBlockchainImport(importOption) &&
    [Blockchain.Solana, Blockchain.SolanaV2].includes(
      importOption.id as Blockchain,
    );

  const currencyName = (
    <Typography
      variant="Metropolis/Caption/Medium/Regular"
      whiteSpace="nowrap"
      data-uncensored="true"
    >
      {displayCurrency(name, 24, 20)}
    </Typography>
  );

  const content = (
    <AccTableCell gridArea="title" gap="0.5rem" display="flex">
      <CurrencyLogo
        currency={currencyIdentifier}
        width={24}
        height={24}
        margin="0"
      />
      {isSolanaOnSolanaChain ? (
        <Tooltip title={<span>{`${name} (${symbol})`}</span>} placement="top">
          {currencyName}
        </Tooltip>
      ) : (
        currencyName
      )}
      <Typography
        whiteSpace="nowrap"
        variant="Metropolis/Caption/Medium/Regular"
        data-uncensored="true"
      >{`(${displayCurrency(symbol, 8, 3)})`}</Typography>
      {isSolanaOnSolanaChain && (
        <Tooltip title={lang.imports.solanaBalanceWarning} placement="top">
          <InfoOutlined
            style={{
              height: "1rem",
              width: "1rem",
              marginLeft: "0.25rem",
              marginBottom: "0.15rem",
            }}
          />
        </Tooltip>
      )}
    </AccTableCell>
  );
  return isSolanaOnSolanaChain ? (
    content
  ) : (
    <Tooltip title={<span>{`${name} (${symbol})`}</span>} placement="top-start">
      {content}
    </Tooltip>
  );
};

const NumberCell = ({
  value,
  isFiat,
  gridArea,
  postfixIcon,
}: {
  isFiat?: boolean;
  value: number;
  gridArea: TableHeaders;
  postfixIcon?: ReactNode;
}) => {
  const langPref = useLanguagePreference();
  const localCurrency = useLocalCurrency();

  return (
    <AccTableCell justifySelf="end" gridArea={gridArea}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Tooltip title={value.toString()}>
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            fontWeight={600}
          >
            {isFiat
              ? displayFiatValue({ value, localCurrency, locale: langPref })
              : displayCryptoQuantity({
                  quantity: value,
                  locale: langPref,
                  precision: 6,
                })}
          </Typography>
        </Tooltip>
        {postfixIcon ? postfixIcon : null}
      </Box>
    </AccTableCell>
  );
};

function MoreOptions({
  currencyIdentifier,
  sourceQuery,
  importId,
}: {
  currencyIdentifier: CurrencyIdentifier;
  sourceQuery: FilterQuery[];
  importId?: string;
}) {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  if (importId) {
    throw new Error("handle importId");
  }

  const viewLedgerHandler = () => {
    const link = `${Links.Ledger}/${currencyIdentifier.id}`;

    window.open(link, isEmbedded ? "_self" : "_blank", "noopener,noreferrer");
  };

  const viewTxsHandler = () => {
    const link = getTransactionPageLink({
      state: {
        filter: {
          type: FilterOperators.And,
          rules: [
            {
              type: FilterOperators.Currency,
              value: [currencyIdentifier.id],
            },
            ...sourceQuery,
          ],
        },
      },
    });

    window.open(link, isEmbedded ? "_self" : "_blank", "noopener,noreferrer");
  };

  const options: DrawerOptionsType[] = [
    {
      id: "view_transactions",
      handler: viewTxsHandler,
      label: lang.imports.drawer.viewTxs,
      icon: <OpenInNew fontSize="small" />,
    },
    {
      id: "view_ledger",
      handler: viewLedgerHandler,
      label: lang.imports.drawer.viewLedger,
      icon: <OpenInNew fontSize="small" />,
    },
  ];

  return (
    <AccTableCell gridArea={TableHeaders.Options}>
      <MoreOptionsDropdown options={options} />
    </AccTableCell>
  );
}
