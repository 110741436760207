import {
  Country,
  InventoryMethod,
  ReportFormat,
  ReportPackTypePlatform,
} from "@ctc/types";
import moment from "moment-timezone";
import { useContext } from "react";

import { type TradingStockValuationMethod } from "~/components/reconciliation/enums";
import { displayReportWarning } from "~/components/report/ReportWarningMessage";
import { LOCK_PERIOD_DISABLED_INVENTORY_METHODS } from "~/components/settings-modal/views/lock-period/AddLockPeriod";
import { displayMessage } from "~/components/ui/Toaster";
import { type FinancialYear, FYContext } from "~/contexts/FYContext";
import { ReportModalsContext } from "~/contexts/ReportModalsContext";
import {
  type AuthUser,
  useBestUser,
  useCanAccessFeature,
  useCountry,
} from "~/redux/auth";
import { type AppThunk, type ReportState as State } from "~/redux/types";
import { useSelector } from "~/redux/useSelector";
import { trackIntercomEvent } from "~/services/intercom";
import * as reportAPI from "~/services/report";
import { useGetAllTaxPeriodsQuery } from "~/state/period";
import {
  DisplayMessage,
  Features,
  IrsReportDownloadType,
  IrsReportType,
  NormalReportType,
  OtherReportType,
  TurboTaxReportType,
} from "~/types/enums";
import {
  type ReportDownloadType,
  type ReportType,
  type TaxSettings,
} from "~/types/index";

enum Report {
  Loading = "REPORT_LOADING",
  SetIsLoadingTaxSettings = "SET_IS_LOADING_TAX_SETTINGS",
  SetFullReport = "SET_FULL_REPORT",
  SetTaxSettings = "SET_TAX_SETTINGS",
  DismissDisclaimer = "DISMISS_DISCLAIMER",
  DismissWarningBanner = "DISMISS_WARNING_BANNER",
  DismissWarningZeroCost = "DISMISS_WARNING_ZERO_COST",
  DismissAllWarnings = "DISMISS_ALL_WARNINGS",
  Reset = "REPORT_RESET",
  RefreshReport = "report/refreshReport",
  RefreshReportRequest = "report/refreshReport/request",
  RefreshReportSuccess = "report/refreshReport/success",
  RefreshReportFailure = "report/refreshReport/failure",
  DownloadPack = "report/downloadPack/request",
  DownloadPackComplete = "report/downloadPack/complete",
}

type Action =
  | { type: Report.SetTaxSettings; taxSettings: TaxSettings }
  | { type: Report.SetIsLoadingTaxSettings; isLoadingTaxSettings: boolean }
  | { type: Report.DismissDisclaimer }
  | { type: Report.DismissWarningBanner }
  | { type: Report.DismissWarningZeroCost }
  | { type: Report.DismissAllWarnings }
  | { type: Report.Reset }
  | { type: Report.RefreshReportRequest }
  | { type: Report.RefreshReportSuccess }
  | { type: Report.RefreshReportFailure }
  | { type: Report.DownloadPack; packId: string; reportTypes: ReportType[] }
  | { type: Report.DownloadPackComplete; packId: string }
  | AuthUser;

const initialState: State = {
  loading: false,
  isLoadingTaxSettings: false,
  showDisclaimer: true,
  showWarningBanner: true,
  showWarningZeroCost: true,
  packDownloading: {},
};

export function reportReducer(state = initialState, action: Action): State {
  switch (action.type) {
    case Report.DismissDisclaimer:
      return {
        ...state,
        showDisclaimer: false,
      };
    case Report.DismissWarningBanner:
      return {
        ...state,
        showWarningBanner: false,
      };
    case Report.DismissWarningZeroCost:
      return {
        ...state,
        showWarningZeroCost: false,
      };
    case Report.DismissAllWarnings:
      return {
        ...state,
        showDisclaimer: false,
        showWarningBanner: false,
        showWarningZeroCost: false,
      };
    case Report.SetTaxSettings:
      return {
        ...state,
        taxSettings: action.taxSettings,
        isLoadingTaxSettings: false,
      };
    case Report.SetIsLoadingTaxSettings:
      return {
        ...state,
        isLoadingTaxSettings: action.isLoadingTaxSettings,
      };
    case Report.Reset: {
      return initialState;
    }
    case Report.DownloadPack: {
      return {
        ...state,
        packDownloading: {
          ...state.packDownloading,
          [action.packId]: action.reportTypes,
        },
      };
    }
    case Report.DownloadPackComplete:
      return {
        ...state,
        packDownloading: {
          ...state.packDownloading,
          [action.packId]: [],
        },
      };
    default:
      return state;
  }
}

export function resetReport() {
  return {
    type: Report.Reset,
  };
}

function refreshReportRequest(): Action {
  return {
    type: Report.RefreshReportRequest,
  };
}
function refreshReportFailure(): Action {
  return {
    type: Report.RefreshReportFailure,
  };
}
function refreshReportSuccess(): Action {
  return {
    type: Report.RefreshReportSuccess,
  };
}

export function downloadPackRequest(
  packId: string,
  reportTypes: ReportType[],
): Action {
  return { type: Report.DownloadPack, packId, reportTypes };
}

export function downloadPackComplete(packId: string): Action {
  return { type: Report.DownloadPackComplete, packId };
}

export const useIsDownloadingPack = () =>
  useSelector((state) => state.report.packDownloading);

export const refreshReport = (): AppThunk => async (dispatch, getState) => {
  dispatch(refreshReportRequest());
  const res = await reportAPI.refreshReport();
  if (res.error) {
    displayMessage({
      message: res.msg || getState().lang.map.report.wentWrongRefreshing,
      type: DisplayMessage.Error,
    });
    dispatch(refreshReportFailure());
  } else {
    dispatch(refreshReportSuccess());
  }
};

export function setTaxSettings(taxSettings: TaxSettings) {
  return {
    type: Report.SetTaxSettings,
    taxSettings: {
      ...taxSettings,
      cryptoToCryptoNonTaxableStartDate:
        taxSettings.cryptoToCryptoNonTaxableStartDate
          ? new Date(taxSettings.cryptoToCryptoNonTaxableStartDate)
          : new Date(0),
      cryptoToCryptoNonTaxableFiatCurrencies:
        taxSettings.cryptoToCryptoNonTaxableFiatCurrencies || [],
    },
  };
}

function setIsLoadingTaxSettings(isLoadingTaxSettings: boolean) {
  return {
    type: Report.SetIsLoadingTaxSettings,
    isLoadingTaxSettings,
  };
}

export function dismissDisclaimer() {
  return {
    type: Report.DismissDisclaimer,
  };
}

export function dismissWarningZeroCost() {
  return {
    type: Report.DismissWarningZeroCost,
  };
}

export function dismissAllWarnings() {
  return {
    type: Report.DismissAllWarnings,
  };
}

export const loadTaxSettings =
  (setLoading = false): AppThunk =>
  async (dispatch) => {
    if (setLoading) {
      dispatch(setIsLoadingTaxSettings(true));
    }
    const res = await reportAPI.getTaxSettings();
    if (res.error) {
      console.error(res);
    } else {
      moment.tz.setDefault(res.data.timezone || moment.tz.guess());
      dispatch(setTaxSettings(res.data));
    }
    dispatch(setIsLoadingTaxSettings(false));
  };

export const updateTaxSettings =
  (settings: Partial<TaxSettings>): AppThunk<Promise<{ error: boolean }>> =>
  async (dispatch, getState) => {
    const res = await reportAPI.setTaxSettings(settings);
    const lang = getState().lang.map;
    if (res.error) {
      console.error(res);
      displayMessage({
        message: lang.settings.report.updatedTaxSettingsError,
        type: DisplayMessage.Error,
      });
    } else {
      moment.tz.setDefault(res.data.timezone);
      dispatch(setTaxSettings(res.data));
      displayMessage({
        message: lang.settings.report.updatedTaxSettingsSuccess,
        type: DisplayMessage.Success,
      });
    }
    return res;
  };

export const downloadReportPDFs =
  ({
    reportTypes,
    timeframe,
    timezone,
    userData,
    tradingStockValuationMethod,
    queryParams,
    packId,
    packType = ReportPackTypePlatform.Custom,
  }: {
    reportTypes: ReportType[];
    timeframe: FinancialYear;
    timezone: string;
    userData?: { name: string; social: string };
    tradingStockValuationMethod?: TradingStockValuationMethod;
    queryParams?: Record<string, string>;
    packId?: string;
    packType?: string;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (packId) dispatch(downloadPackRequest(packId, reportTypes));
    const res = await reportAPI.generateReportPDFs(
      reportTypes,
      timeframe,
      timezone,
      packType,
      userData,
      queryParams,
      packId,
      tradingStockValuationMethod,
    );
    if (res.error) {
      displayMessage({
        message: res.msg || getState().lang.map.report.wentWrongDownloadingPDF,
        type: DisplayMessage.Error,
      });
    }
    if (!res.error) {
      sessionStorage.setItem(res.data, "true");
    }
  };

export const downloadReportCSVs =
  ({
    reportTypes,
    timeframe,
    timezone,
    tradingStockValuationMethod,
    queryParams,
    packId,
    packType = ReportPackTypePlatform.Custom,
  }: {
    reportTypes: ReportType[];
    timeframe: FinancialYear;
    timezone: string;
    tradingStockValuationMethod?: TradingStockValuationMethod;
    queryParams?: Record<string, string>;
    packId?: string;
    packType?: string;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (packId) dispatch(downloadPackRequest(packId, reportTypes));
    const res = await reportAPI.generateReportCSVs(
      reportTypes,
      timeframe,
      timezone,
      packType,
      queryParams,
      tradingStockValuationMethod,
    );
    if (packId) dispatch(downloadPackComplete(packId));
    if (res.error) {
      displayMessage({
        message:
          res.message || getState().lang.map.report.wentWrongDownloadingCSV,
        type: DisplayMessage.Error,
      });
    }
  };

export const downloadReportTurboTaxTxf =
  (timeframe: FinancialYear, timezone: string, packId?: string): AppThunk =>
  async (dispatch, getState) => {
    if (packId)
      dispatch(downloadPackRequest(packId, [TurboTaxReportType.TurboTaxTxf]));
    const res = await reportAPI.generateReportTurboTaxTxf(timeframe, timezone);
    if (packId) dispatch(downloadPackComplete(packId));
    if (res.error) {
      displayMessage({
        message:
          res.message || getState().lang.map.report.wentWrongDownloadingTXF,
        type: DisplayMessage.Error,
      });
    }
  };

export const downloadReportBglXml =
  (
    timeframe: FinancialYear,
    timezone: string,
    packId?: string,
    allowWarnings?: boolean,
  ): AppThunk =>
  async (dispatch, getState) => {
    // Not actually included in the report pack, I'm just copying what we did for TurboTax TXF files.
    if (packId) {
      dispatch(downloadPackRequest(packId, [OtherReportType.BGL]));
    }
    const res = await reportAPI.generateReportBglXml(
      timeframe,
      timezone,
      !!allowWarnings,
    );
    if (packId) dispatch(downloadPackComplete(packId));

    const hasWarnings = "warnings" in res && res.warnings?.length;

    const state = getState();

    const message =
      res.message ?? state.lang.map.report.wentWrongDownloadingBGL;

    const title = state.lang.map.report.reportDetails;

    if (hasWarnings) {
      displayReportWarning({
        title,
        message,
        warnings: res.warnings ?? [],
        action: {
          label: state.lang.map.report.downloadReports.downloadReportAnyway,
          onClick: () => {
            dispatch(downloadReportBglXml(timeframe, timezone, packId, true));
          },
        },
      });
    } else if (res.error) {
      displayMessage({
        message,
        type: DisplayMessage.Error,
      });
    }
  };

export const useDownloadReports = () => {
  const hasTradingStockReportFeature = useCanAccessFeature(
    Features.TradingStockReport,
  );
  const reportModalsContext = useContext(ReportModalsContext);
  const { timeframe } = useContext(FYContext);
  const timezone = useTimezone();
  const bestUser = useBestUser();

  return ({
      reportFormat,
      reportDownloadTypes,
      queryParams,
      packId,
      packType,
    }: {
      reportFormat: ReportFormat;
      reportDownloadTypes: ReportDownloadType[];
      queryParams?: Record<string, string>;
      packId?: string;
      packType: string;
    }): AppThunk =>
    async (dispatch) => {
      if (!timeframe) {
        return;
      }

      // Intercom event tracking
      trackIntercomEvent("download_report_clicked", {
        reportFormat,
        reportType: `${reportDownloadTypes.join(",")}`,
        selectedYear: timeframe,
      });

      const reportTypes = reportDownloadTypes.flatMap(
        (reportType): ReportType[] => {
          if (reportType === IrsReportDownloadType.IRS_8949) {
            return [IrsReportType.IRS_8949_SHORT, IrsReportType.IRS_8949_LONG];
          } else if (
            reportType === IrsReportDownloadType.IRS_8949_CONSOLIDATED
          ) {
            return [
              IrsReportType.IRS_8949_SHORT_CONSOLIDATED,
              IrsReportType.IRS_8949_LONG_CONSOLIDATED,
            ];
          } else if (reportType === IrsReportDownloadType.IRS_1040S1) {
            return [IrsReportType.IRS_1040S1];
          } else if (reportType === IrsReportDownloadType.IRS_1040SD) {
            return [IrsReportType.IRS_1040SD];
          }
          return [reportType];
        },
      );

      const shouldShowIRSCustomDialog = reportDownloadTypes.some((report) =>
        (
          [
            IrsReportDownloadType.IRS_8949,
            IrsReportDownloadType.IRS_8949_CONSOLIDATED,
            IrsReportDownloadType.IRS_1040S1,
            IrsReportDownloadType.IRS_1040SD,
          ] as ReportDownloadType[]
        ).includes(report),
      );
      if (shouldShowIRSCustomDialog) {
        reportModalsContext
          ?.setIsOpenIRSCustomNameSSNDialog(true)
          .then((userData) => {
            dispatch(
              downloadReportPDFs({
                reportTypes,
                timeframe,
                timezone,
                packId,
                packType,
                queryParams,
                userData,
              }),
            );
          });
        return;
      }

      // we don't actually have instructions for this. So dont show for now
      // if (reportDownloadTypes.includes(TurboTaxReportType.TurboTaxTxf)) {
      //   reportModalsContext?.setTurboTaxInformationalDialogType(
      //     TurboTaxInformationalDialogType.Desktop,
      //   );
      // }

      // this is commented while we test out an intercom popup instead
      // they shouldn't be able to download both TXF and CSV together, but if we ever allow this,
      // we want to just show the TurboTax online dialog
      // if (reportDownloadTypes.includes(TurboTaxReportType.TurboTaxCsv)) {
      //   reportModalsContext?.setTurboTaxInformationalDialogType(
      //     TurboTaxInformationalDialogType.Online,
      //   );
      // }

      // Check if the reports being download includes a trading stock report
      const hasTradingStockReport = reportDownloadTypes.some((report) =>
        ([NormalReportType.TradingStockAu] as ReportDownloadType[]).includes(
          report,
        ),
      );

      // Don't show the valuation modal if the user is paywalled
      if (hasTradingStockReport && hasTradingStockReportFeature) {
        reportModalsContext
          ?.setIsOpenTradingStockInventorySelectorDialog(true)
          .then((tradingStockValuationMethod) => {
            if (reportFormat === ReportFormat.PDF)
              dispatch(
                downloadReportPDFs({
                  reportTypes,
                  timeframe,
                  timezone,
                  packId,
                  packType,
                  queryParams,
                  tradingStockValuationMethod,
                }),
              );

            if (reportFormat === ReportFormat.CSV)
              dispatch(
                downloadReportCSVs({
                  reportTypes,
                  timeframe,
                  timezone,
                  packId,
                  packType,
                  queryParams,
                  tradingStockValuationMethod,
                }),
              );
          });
        return;
      }

      if (reportFormat === ReportFormat.PDF) {
        dispatch(
          downloadReportPDFs({
            reportTypes,
            timeframe,
            timezone,
            packId,
            packType,
            queryParams,
          }),
        );
      } else if (reportFormat === ReportFormat.CSV) {
        dispatch(
          downloadReportCSVs({
            reportTypes,
            timeframe,
            timezone,
            packId,
            packType,
            queryParams,
          }),
        );
      } else if (reportFormat === ReportFormat.TXF) {
        dispatch(downloadReportTurboTaxTxf(timeframe, timezone, packId));
      } else if (
        reportFormat === ReportFormat.XML &&
        // @todo - this should be the active data source user
        bestUser?.country === Country.Australia
      ) {
        dispatch(downloadReportBglXml(timeframe, timezone, packId));
      }
    };
};

export const useInventoryMethod = () => useTaxSettings()?.inventoryMethod;

export const useTimezone = () =>
  useTaxSettings()?.timezone || moment.tz.guess();

export const useTaxSettings = () =>
  useSelector((state) => state.report.taxSettings);

export const useIsLoadingTaxSettings = () =>
  useSelector((state) => state.report.isLoadingTaxSettings);

// Dirty fix because we have the same logic on the backend but we need this for now
export function getDefaultInventoryMethodByCountry(
  country: Country | undefined,
): InventoryMethod {
  switch (country) {
    case Country.UK:
      return InventoryMethod.HMRC;
    case Country.Canada:
      return InventoryMethod.ACB;
    case Country.France:
      return InventoryMethod.PFU;
    case Country.Italy:
      return InventoryMethod.LIFO;
    default:
      return InventoryMethod.FIFO;
  }
}

function getAvailableInventoryMethods({
  country,
  taxSettings,
  hasLockedPeriods,
}: {
  country?: Country;
  taxSettings?: TaxSettings;
  hasLockedPeriods: boolean;
}) {
  const { inventoryMethod } = taxSettings || {};

  const getMethodsByCountry = (country?: Country) => {
    const defaultOptions = [
      InventoryMethod.FIFO,
      InventoryMethod.LIFO,
      InventoryMethod.HIFO,
      InventoryMethod.AVCO,
      InventoryMethod.LTFO,
    ];
    const optionsUK = [
      InventoryMethod.FIFO,
      InventoryMethod.AVCO,
      InventoryMethod.HMRC,
    ];
    const optionsCanada = [
      InventoryMethod.FIFO,
      InventoryMethod.AVCO,
      InventoryMethod.ACB,
    ];
    const optionsNZ = [InventoryMethod.FIFO, InventoryMethod.AVCO];
    const optionsFRA = [InventoryMethod.PFU, ...defaultOptions];
    const optionsItaly = [InventoryMethod.LIFO];

    switch (country) {
      case Country.UK:
        return optionsUK;
      case Country.NewZealand:
        return optionsNZ;
      case Country.Canada:
        return optionsCanada;
      case Country.France:
        return optionsFRA;
      case Country.Italy:
        return optionsItaly;
      default:
        return defaultOptions;
    }
  };

  const availableMethods = getMethodsByCountry(country);

  if (inventoryMethod && !availableMethods.includes(inventoryMethod)) {
    availableMethods.push(inventoryMethod);
  }

  return availableMethods.filter(
    (method) =>
      !hasLockedPeriods ||
      !LOCK_PERIOD_DISABLED_INVENTORY_METHODS.includes(method),
  );
}

// Copied from backend Inventory class.
export function isSpecificInventoryByExchangeSupported(
  inventoryMethod?: InventoryMethod,
): boolean {
  // WARNING: Before adding ACB, HMRC fix the calls to inventory methods with "todo".
  return [
    InventoryMethod.AVCO,
    InventoryMethod.FIFO,
    InventoryMethod.LIFO,
    InventoryMethod.HIFO,
    InventoryMethod.LTFO,
  ].includes(inventoryMethod as InventoryMethod);
}

export const useAvailableInventoryMethods = () => {
  const country = useCountry();
  const taxSettings = useTaxSettings();
  const lockPeriodQuery = useGetAllTaxPeriodsQuery();
  const hasLockedPeriods = !!lockPeriodQuery.data?.length;
  return getAvailableInventoryMethods({
    country,
    taxSettings,
    hasLockedPeriods,
  });
};

export const useShowDisclaimer = () =>
  useSelector((state) => state.report.showDisclaimer);

export const useShowWarningBanner = () =>
  useSelector((state) => state.report.showWarningBanner);

export const useShowWarningZeroCost = () =>
  useSelector((state) => state.report.showWarningZeroCost);
