import { type Blockchain, SyncStatusPlatform } from "@ctc/types";
import {
  type QueryClient,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { StringParam, useQueryParam } from "use-query-params";

import { FORM_IMPORT_SUBMITTED_EVENT } from "~/analytics/analytics";
import { importNewAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import {
  importAccountKey,
  useCaptureImportAnalytics,
} from "~/components/imports/AnalyticsHelpers";
import { queryClient } from "~/components/queryClient";
import { displayMessage } from "~/components/ui/Toaster";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  fireAnalyticsOnDelete,
  fireAnalyticsOnUpload,
} from "~/segment/importAnalytics";
import { HttpError } from "~/services/core";
import * as importsV2 from "~/services/imports";
import * as walletAPI from "~/services/wallets";
import {
  formatWalletAddress,
  getRelatedChains,
  resolveNameServiceName,
} from "~/services/wallets";
import { entityKeys } from "~/state/entities";
import { accountHasImports, savedImports } from "~/state/importsV2";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import {
  useRemoveSyncMutation,
  useSetSyncMutation,
  useUploadPendingHandler,
} from "~/state/sync";
import {
  DisplayMessage,
  type ImportMethod,
  ImportType,
  ImportViewMode,
  IntegrationCategory,
  RelatedWalletsAction,
} from "~/types/enums";
import {
  type AllSavedImports,
  BlockchainName,
  type ImportOptionV2,
  type SavedImportByIntegration,
  type SavedImportOptionByAccount,
  type SavedWalletImport,
} from "~/types/index";

type PreviousData = Record<ImportViewMode, AllSavedImports | undefined>;

type GetUpdatesFunc = ({
  addressParsed,
  isBlockchainView,
  accountId,
  prevByAccount,
  prevByIntegration,
}: {
  addressParsed: string;
  isBlockchainView: boolean;
  accountId: string;
  prevByAccount: SavedImportOptionByAccount | undefined;
  prevByIntegration: SavedImportByIntegration | undefined;
}) => {
  newByAccount: SavedImportOptionByAccount | null;
  newByIntegration: SavedImportByIntegration | null;
};

type GetAllRelatedChainsData = {
  address: string;
  blockchain: Blockchain;
  relatedChains: readonly Blockchain[];
};

export const walletsKeys = {
  all: () => ["wallets"] as const,
  resolveName: (blockchain: Blockchain, name: string) =>
    [...walletsKeys.all(), "resolve-name", blockchain, name] as const,
  related: (address: string, blockchain: Blockchain) => [
    ...walletsKeys.all(),
    "related",
    address,
    blockchain,
  ],
};

function defaultOnError(
  err: unknown,
  _: any,
  context:
    | {
        previousData?: PreviousData;
      }
    | undefined,
) {
  const previousData = context?.previousData;
  queryClient.setQueryData(
    savedImports.list(ImportViewMode.ByAddress),
    previousData?.byAddress,
  );
  queryClient.setQueryData(
    savedImports.list(ImportViewMode.ByBlockchain),
    previousData?.byBlockchain,
  );
  queryErrorHandler(err);
}

async function optimisticallyUpdateWallet({
  queryClient,
  address,
  blockchain,
  getUpdates,
}: {
  queryClient: QueryClient;
  address: string;
  blockchain: Blockchain;
  getUpdates: GetUpdatesFunc;
}): Promise<{
  previousData?: PreviousData;
}> {
  // Lower-cased as BE will return this data as lower-cased address.
  const addressParsed = formatWalletAddress(address, blockchain);
  await queryClient.cancelQueries({ queryKey: savedImports.all() });

  const previousData = [
    ImportViewMode.ByAddress,
    ImportViewMode.ByBlockchain,
  ].reduce(
    (acc, key) => {
      const previousData = queryClient.getQueryData<AllSavedImports>(
        savedImports.list(key),
      );
      const isBlockchainView = key === ImportViewMode.ByBlockchain;

      const accountId = isBlockchainView ? blockchain : addressParsed;

      const previousSavedAccountImport =
        previousData?.importsByAccountMap[accountId];
      const previousSavedIntegrationDetails =
        previousData?.importsByIntegration[blockchain];

      const { newByAccount, newByIntegration } = getUpdates({
        addressParsed,
        isBlockchainView,
        accountId,
        prevByAccount: previousSavedAccountImport,
        prevByIntegration: previousSavedIntegrationDetails,
      });

      let newAll: SavedImportOptionByAccount[] = [];
      const newImportsByAccountMap = { ...previousData?.importsByAccountMap };
      const newImportsByIntegration = { ...previousData?.importsByIntegration };

      if (newByAccount) {
        newAll =
          previousData?.all
            .filter((s) => s.id !== accountId)
            .concat([newByAccount]) || [];
        newImportsByAccountMap[accountId] = newByAccount;
      } else {
        delete newImportsByAccountMap[accountId];
        newAll = previousData?.all.filter((s) => s.id !== accountId) || [];
      }

      if (newByIntegration) {
        newImportsByIntegration[blockchain] = newByIntegration;
      } else {
        delete newImportsByIntegration[blockchain];
      }

      const newData: AllSavedImports = {
        all: newAll,
        importsByAccountMap: newImportsByAccountMap,
        importsByIntegration: newImportsByIntegration,
      };

      queryClient.setQueryData(savedImports.list(key), newData);
      acc[key] = previousData;
      return acc;
    },
    {} as Record<ImportViewMode, AllSavedImports | undefined>,
  );

  return {
    previousData,
  };
}

export const useAddWalletMutation = ({
  isUsingImportForm,
}: {
  isUsingImportForm: boolean;
}) => {
  const queryClient = useQueryClient();
  const syncWallet = useSyncWalletMutation();
  const captureAnalytics = useCaptureAnalytics();
  const user = useUser();
  const setSyncStatus = useSetSyncMutation();
  const [suspectedMissingImport] = useQueryParam(
    "suspectedMissingImport",
    StringParam,
  );
  const isSuspectedMissingImport = suspectedMissingImport === "true";

  return useMutation({
    retry: 3,
    mutationFn: async ({
      address,
      type,
      name,
      importFromTimeMillis,
      relatedWalletsAction,
      isUnspecifiedBlockchain,
      relatedChains,
      showMessage,
      notifyUser,
      importSource,
      importMethod,
    }: {
      address: string;
      type: Blockchain;
      name?: string;
      importFromTimeMillis?: number;
      relatedWalletsAction: RelatedWalletsAction;
      isUnspecifiedBlockchain?: boolean;
      blockchainImportOption?: ImportOptionV2;
      relatedChains?: Blockchain[];
      showMessage?: boolean;
      notifyUser?: boolean;
      importSource?: string;
      importMethod?: ImportMethod;
    }) => {
      setSyncStatus.mutate({
        scope: `${type}_${address}`,
        status: SyncStatusPlatform.Pending,
      });
      if (showMessage) {
        displayMessage({
          message: `Syncing ${BlockchainName[type]} wallet`,
          type: DisplayMessage.Info,
        });
      }

      captureAnalytics("exchange_imported", {
        integration: importSource || type,
        exchangeName: type,
        importType: ImportType.Wallet,
        importMethod,
        isNicknamed: !!name,
        isSuspectedMissingImport,
      });
      if (isUsingImportForm) {
        captureAnalytics(importNewAnalyticsKey(FORM_IMPORT_SUBMITTED_EVENT), {
          integration: importSource || type,
          exchangeName: type,
          importType: ImportType.Wallet,
          importMethod,
          isNicknamed: !!name,
        });
      }
      const res = await walletAPI.storeWallet({
        type,
        address,
        name,
        importFromTimestamp: importFromTimeMillis,
        relatedWalletsAction,
        relatedChains,
        isUnspecifiedBlockchain,
        notifyUser,
        importSource,
      });

      if (res.error) {
        throw new HttpError(res, ["addWallet"]);
      }

      await fireAnalyticsOnUpload(user, {
        blockchain: type,
        address,
        name,
        importType: ImportType.Wallet,
      });

      const { _id: id, address: resAddress, type: blockchain } = res.data;

      syncWallet.mutate({
        wallet: {
          id,
          address: resAddress,
          blockchain,
        },
        isHardSync: true,
        relatedWalletsAction: RelatedWalletsAction.Ignore, // We don't want to import related wallets on initial sync.
      });

      return res.data;
    },
    onMutate: async ({
      address,
      type: blockchain,
      name,
      importFromTimeMillis,
    }) => {
      const getUpdates: GetUpdatesFunc = ({
        addressParsed,
        isBlockchainView,
        accountId,
        prevByAccount,
        prevByIntegration,
      }) => {
        const dummyChain: SavedWalletImport = {
          id: `${addressParsed}__${blockchain}`,
          name,
          blockchain,
          address: addressParsed,
          status: SyncStatusPlatform.Pending,
          createdAt: new Date().toISOString(),
          completedAt: null,
          lastImportedTxTimestamp: null,
          importType: ImportType.Wallet,
          importFromDate: importFromTimeMillis
            ? new Date(importFromTimeMillis)
            : undefined,
          importSource: null,
        };

        const newByAccount: SavedImportOptionByAccount = {
          id: accountId,
          name:
            name || isBlockchainView
              ? BlockchainName[blockchain]
              : addressParsed,
          category: IntegrationCategory.Blockchain,
          availableImportMethods: [],
          assets: [],
          value: 0,
          totalTxCount: undefined,
          spamTxCount: undefined,
          lastImportedTxTimestamp: null,
          lastSyncComplete: null,
          oauths: [],
          keys: [],
          files: [],
          ...prevByAccount,
          syncStatus: SyncStatusPlatform.Pending,
          wallets: [...(prevByAccount?.wallets || []), dummyChain],
        };

        const newByIntegration: SavedImportByIntegration = {
          id: blockchain,
          name: name || BlockchainName[blockchain],
          category: IntegrationCategory.Blockchain,
          files: [],
          oauths: [],
          keys: [],
          ...prevByIntegration,
          wallets: [...(prevByIntegration?.wallets || []), dummyChain],
        };

        return {
          newByAccount,
          newByIntegration,
        };
      };

      const { previousData } = await optimisticallyUpdateWallet({
        queryClient,
        address,
        blockchain,
        getUpdates,
      });

      return {
        previousData,
      };
    },
    onError: defaultOnError,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
      queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const useEditWalletMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (wallet: SavedWalletImport) => {
      if (!wallet.id) return;

      const res = await walletAPI.updateWallet(wallet.id, wallet);

      if (res.error) {
        throw new HttpError(res, ["editWallet"]);
      }
    },
    onMutate: async ({ id, name, address, blockchain }) => {
      const getUpdates: GetUpdatesFunc = ({
        addressParsed,
        isBlockchainView,
        accountId,
        prevByAccount,
        prevByIntegration,
      }) => {
        const newByAccount: SavedImportOptionByAccount = {
          id: accountId,
          name: isBlockchainView ? BlockchainName[blockchain] : addressParsed,
          category: IntegrationCategory.Blockchain,
          availableImportMethods: [],
          assets: [],
          value: 0,
          totalTxCount: undefined,
          spamTxCount: undefined,
          syncStatus: undefined,
          lastImportedTxTimestamp: null,
          lastSyncComplete: null,
          oauths: [],
          keys: [],
          files: [],
          ...prevByAccount,
          wallets: (prevByAccount?.wallets || []).map((w) =>
            w.id === id ? { ...w, name } : w,
          ),
        };

        const newByIntegration: SavedImportByIntegration = {
          id: blockchain,
          name: BlockchainName[blockchain],
          category: IntegrationCategory.Blockchain,
          files: [],
          oauths: [],
          keys: [],
          ...prevByIntegration,
          wallets: (prevByIntegration?.wallets || []).map((w) =>
            w.id === id ? { ...w, name } : w,
          ),
        };

        return {
          newByAccount,
          newByIntegration,
        };
      };

      const { previousData } = await optimisticallyUpdateWallet({
        queryClient,
        address,
        blockchain,
        getUpdates,
      });

      return {
        previousData,
      };
    },
    onError: defaultOnError,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: entityKeys.all() });
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
    },
  });
};

export const useDeleteWalletMutation = () => {
  const syncRemove = useRemoveSyncMutation();
  const queryClient = useQueryClient();
  const user = useUser();

  return useMutation({
    mutationFn: async (
      wallet: Pick<SavedWalletImport, "address" | "blockchain" | "id" | "name">,
    ) => {
      if (!wallet.id) {
        return;
      }

      syncRemove.mutate({ scope: wallet.blockchain });
      syncRemove.mutate({ scope: `${wallet.blockchain}_${wallet.address}` });
      const res = await importsV2.deleteImportByIdAndType({
        importType: ImportType.Wallet,
        importId: wallet.id,
      });

      if (res.error) {
        throw new HttpError(res, ["deleteWallet"]);
      }

      await fireAnalyticsOnDelete(user, {
        blockchain: wallet.blockchain,
        address: wallet.address,
        name: wallet.name,
        importType: ImportType.Wallet,
      });
    },
    onMutate: async ({
      address,
      blockchain,
      id,
    }: Pick<SavedWalletImport, "address" | "blockchain" | "id">) => {
      const getUpdates: GetUpdatesFunc = ({
        prevByAccount,
        prevByIntegration,
      }) => {
        const updatedByAccount: SavedImportOptionByAccount | undefined =
          prevByAccount
            ? {
                ...prevByAccount,
                wallets: prevByAccount.wallets.filter((w) => w.id !== id),
              }
            : undefined;
        const updatedByIntegration: SavedImportByIntegration | undefined =
          prevByIntegration
            ? {
                ...prevByIntegration,
                wallets: prevByIntegration.wallets.filter((w) => w.id !== id),
              }
            : undefined;

        return {
          newByAccount:
            updatedByAccount && accountHasImports(updatedByAccount)
              ? updatedByAccount
              : null,
          newByIntegration:
            updatedByIntegration && accountHasImports(updatedByIntegration)
              ? updatedByIntegration
              : null,
        };
      };

      const { previousData } = await optimisticallyUpdateWallet({
        address,
        blockchain,
        queryClient,
        getUpdates,
      });

      return { previousData };
    },
    onError: defaultOnError,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
      queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const useSyncWalletMutation = () => {
  const lang = useLang();
  const uploadPending = useUploadPendingHandler();
  const captureAnalytics = useCaptureImportAnalytics();

  return useMutation({
    retry: 3,
    mutationFn: async ({
      wallet,
      isHardSync,
      relatedWalletsAction,
    }: {
      wallet: Pick<SavedWalletImport, "id" | "address" | "blockchain">;
      /**
       * A hard sync is when we pull transactions from the beginning of time
       * OR the user defined 'start import from' time.
       * An initial wallet sync is always a hard sync
       */
      isHardSync: boolean;
      relatedWalletsAction?:
        | RelatedWalletsAction.Notify
        | RelatedWalletsAction.Ignore;
    }) => {
      const { id, address, blockchain } = wallet;

      captureAnalytics(importAccountKey("resync"), {
        isHardSync,
        exchange: id,
        type: "wallet",
      });

      const res = await walletAPI.syncWallet(
        id,
        isHardSync,
        relatedWalletsAction,
      );
      if (res.error) {
        throw new Error(lang.wallet.wentWrongSyncingWalletData);
      } else {
        uploadPending({
          name: address,
          id: `${blockchain}_${address}`,
        });
      }
    },
    onMutate: async ({ wallet: { id, address, blockchain } }) => {
      const getUpdates: GetUpdatesFunc = ({
        addressParsed,
        isBlockchainView,
        accountId,
        prevByAccount,
        prevByIntegration,
      }) => {
        const newByAccount: SavedImportOptionByAccount = {
          id: accountId,
          name: isBlockchainView ? BlockchainName[blockchain] : addressParsed,
          category: IntegrationCategory.Blockchain,
          availableImportMethods: [],
          assets: [],
          value: 0,
          totalTxCount: undefined,
          spamTxCount: undefined,
          lastImportedTxTimestamp: null,
          lastSyncComplete: null,
          oauths: [],
          keys: [],
          files: [],
          ...prevByAccount,
          syncStatus: SyncStatusPlatform.Pending,
          wallets: (prevByAccount?.wallets || []).map((w) =>
            w.id === id ? { ...w, status: SyncStatusPlatform.Pending } : w,
          ),
        };

        const newByIntegration: SavedImportByIntegration = {
          id: blockchain,
          name: BlockchainName[blockchain],
          category: IntegrationCategory.Blockchain,
          files: [],
          oauths: [],
          keys: [],
          ...prevByIntegration,
          wallets: (prevByIntegration?.wallets || []).map((w) =>
            w.id === id ? { ...w, status: SyncStatusPlatform.Pending } : w,
          ),
        };

        return {
          newByAccount,
          newByIntegration,
        };
      };

      const { previousData } = await optimisticallyUpdateWallet({
        queryClient,
        address,
        blockchain,
        getUpdates,
      });

      return {
        previousData,
      };
    },
    onError: defaultOnError,
  });
};

export const useUpdateWalletsNameMutation = () => {
  const lang = useLang();

  return useMutation({
    mutationFn: async ({
      address,
      name,
    }: {
      address: string;
      name: string;
    }) => {
      const res = await walletAPI.updateWalletsName(address, name);

      if (res.error) {
        throw new Error(lang.wallet.wentWrongSyncingWalletData);
      }

      displayMessage({
        type: DisplayMessage.Success,
        message: lang.wallet.walletNicknameUpdate,
      });

      return res;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
    },
  });
};

export function useGetAllRelatedChains(addresses: GetAllRelatedChainsData[]) {
  const user = useUser();
  return useQueries({
    queries: addresses.map(({ address, blockchain, relatedChains }) => ({
      retry: false,
      queryKey: walletsKeys.related(address, blockchain),
      queryFn: async () => {
        const data = await getRelatedChains(address, blockchain, {
          relatedChains,
        });
        if (data.error) {
          throw new HttpError(data, ["useGetRelatedWallets"]);
        }
        return data.data || [];
      },
      staleTime: Infinity,
      enabled: !!user && !!user.country,
    })),
    combine: (data) => {
      const allChainData = data.reduce((allChainData, chainData, i) => {
        if (chainData.data?.length) {
          allChainData.set(addresses[i].address, chainData.data);
        }
        return allChainData;
      }, new Map<string, Blockchain[]>());
      const isFetched = data.every((d) => d.isFetched);
      const isLoading = data.some((d) => d.isLoading);
      return {
        data: allChainData,
        isFetched,
        isLoading,
      };
    },
  });
}

export const useNameServiceNameResolve = (
  blockchain: Blockchain,
  domain: string,
  suffix: string,
) => {
  return useQuery({
    queryKey: walletsKeys.resolveName(blockchain, domain),
    queryFn: async () => {
      const res = await resolveNameServiceName(blockchain, domain);
      if (res.error) {
        throw new HttpError(res, [`resolve${blockchain}NameServiceName`]);
      }
      return res.data.address;
    },
    enabled: !!domain && domain.endsWith(suffix),
  });
};
