import { SyncStatusPlatform } from "@ctc/types";
import { Check, Close } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  Drawer,
  Fade,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { onboardingAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import purpleArrowLeft from "~/assets/purple-arrow-left.svg";
import { BlockchainStack } from "~/components/imports-v2/BlockchainStack";
import { getName } from "~/components/imports-v2/helpers";
import { useOnboardingContext } from "~/components/onboarding-v2/context/onboarding";
import { useAccountListMediaQuery } from "~/components/onboarding-v2/helpers";
import { ImportedAllConfirmationBox } from "~/components/onboarding-v2/ui/ImportedAllConfirmationBox";
import { DrawerContent } from "~/components/ui/Drawer";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { sizes } from "~/components/ui/theme/legacy";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useFetchSavedAccountsForView } from "~/state/importsV2";
import { ImportViewMode, Links } from "~/types/enums";
import {
  DefaultBlockchainCurrency,
  type SavedImportOptionByAccount,
} from "~/types/index";

// This is the account list that show up on the top left corner after the user has connected their exchange account
export function AccountList() {
  const {
    state: { showFinishImportConfirmationModal },
  } = useOnboardingContext();
  const savedAccounts = useFetchSavedAccountsForView(ImportViewMode.ByAddress)
    ?.data?.all;
  const { pathname } = useLocation();
  const { tokens } = useDesign();
  const navigate = useNavigate();
  const captureAnalytics = useCaptureAnalytics();
  const analyticsKey = onboardingAnalyticsKey("account list");

  const showCompactAccountList = [
    Links.OnboardingReconUncategorisedTransactions,
    Links.OnboardingImportsNew,
    Links.OnboardingImportsCustom,
    Links.OnboardingReconUncategorisedTransactions,
    Links.OnboardingPayment,
  ].some((link) => pathname.includes(link));

  const truncateMode = useAccountListMediaQuery() || showCompactAccountList;
  const lang = useLang();
  const [openDrawer, setOpenDrawer] = useState(false);
  const showConfirmationBoxOverlay =
    showFinishImportConfirmationModal && !truncateMode;

  if (!savedAccounts || savedAccounts.length === 0) {
    return null;
  }

  const accounts = savedAccounts.map((account) => {
    return <Account account={account} key={account.id} />;
  });

  const accountList = (
    <AccountListBox truncateMode={truncateMode}>
      {truncateMode ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => {
            setOpenDrawer(true);
          }}
          pl="0.75rem"
          gap="0.5rem"
        >
          <Typography variant="Metropolis/Caption/Medium/Regular">
            {lang.onboardingV2.accountList.yourAccounts}
          </Typography>
          <Chip
            label={savedAccounts.length}
            size="small"
            sx={{
              background: tokens.background.brand.default,
            }}
          />
        </Stack>
      ) : (
        accounts
      )}
    </AccountListBox>
  );

  return (
    <>
      <Fade in={showConfirmationBoxOverlay} timeout={250}>
        <Overlay />
      </Fade>
      <StyledDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <StyledDrawerContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0.5rem 0",
            }}
          >
            <Typography
              variant="Metropolis/Body/Regular"
              sx={{ fontSize: "1rem", color: tokens.text.low }}
            >
              {lang.onboardingV2.accountList.yourAccounts}
            </Typography>
            <TextIconButton
              sx={{ padding: "0 0.25rem 0 0" }}
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <Close sx={{ fontSize: "1.125rem", color: tokens.text.low }} />
            </TextIconButton>
          </Box>
          {accounts}
          <TertiaryButton
            onClick={() => {
              captureAnalytics(analyticsKey("clicked import another account"));
              setOpenDrawer(false);
              navigate(Links.OnboardingImportsSearch);
            }}
          >
            {lang.onboardingV2.accountList.addAnotherAccount}
          </TertiaryButton>
        </StyledDrawerContent>
      </StyledDrawer>
      <Dialog
        open={!!showFinishImportConfirmationModal && truncateMode}
        PaperProps={{
          sx: {
            borderStyle: "none !important",
            background: "transparent",
            margin: 0,
          },
        }}
      >
        <Box p="0.5rem">
          <ImportedAllConfirmationBox accountNum={savedAccounts.length} />
        </Box>
      </Dialog>
      <AccountListContainer
        showOverlay={showConfirmationBoxOverlay}
        truncateMode={truncateMode}
      >
        {savedAccounts.length === 1 ? (
          <Slide in direction="right" timeout={250}>
            {accountList}
          </Slide>
        ) : (
          accountList
        )}
        {showConfirmationBoxOverlay ? (
          <Fade in timeout={250}>
            <Stack flexDirection="row" gap="0.5rem" alignItems="flex-start">
              <img style={{ marginTop: "1rem" }} src={purpleArrowLeft} />
              <ImportedAllConfirmationBox accountNum={savedAccounts.length} />
            </Stack>
          </Fade>
        ) : null}
      </AccountListContainer>
    </>
  );
}

function Account({ account }: { account: SavedImportOptionByAccount }) {
  const blockchain = account.wallets[0]?.blockchain;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p="0.25rem 0.25rem 0.25rem 0.5rem"
    >
      <Stack direction="row" gap="0.5rem" alignItems="center" key={account.id}>
        <ExchangeLogo
          name={account.id}
          walletAddress={account.name}
          margin="0"
          height={16}
          width={16}
          blockchain={blockchain}
          currencySymbol={
            blockchain ? DefaultBlockchainCurrency[blockchain] : undefined
          }
        />

        <Typography variant="Metropolis/Caption/Medium/Regular">
          {getName(account, true, 25)}
        </Typography>
        <BlockchainStack
          blockchains={account.wallets.map((c) => c.blockchain)}
          stackSize={3}
          showOnMobile
        />
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SyncIcon status={account.syncStatus} />
      </Box>
    </Stack>
  );
}

function SyncIcon({ status }: { status: SyncStatusPlatform | undefined }) {
  const { tokens } = useDesign();

  if (
    !status ||
    status === SyncStatusPlatform.Pending ||
    status === SyncStatusPlatform.Queued
  ) {
    return <CircularProgress size="1rem" sx={{ color: tokens.icon.warning }} />;
  }
  if (status === SyncStatusPlatform.Success) {
    return <Check sx={{ color: tokens.text.success, fontSize: "1rem" }} />;
  }
  return null;
}

const AccountListContainer = styled(Box)<{
  showOverlay?: boolean;
  truncateMode?: boolean;
}>`
  z-index: 1;
  background: ${(props) =>
    props.showOverlay
      ? props.theme.tokens.background.neutral.lowest.default
      : "none"};
  padding: 0.25rem;
  border-radius: 0.75rem;
  align-self: flex-start;

  ${(props) =>
    !props.truncateMode &&
    `
    position: absolute;
    left: 2.5rem;
    top: 2rem;
    display: flex;
    gap: 1rem;
  `}
`;

const AccountListBox = styled(Box)<{
  truncateMode?: boolean;
}>`
  display: flex;
  width: 14rem;
  padding: 0.5rem;
  border-radius: 0.625rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background: ${({ theme }) => theme.tokens.elevation.medium};
  flex-direction: column;
  gap: 0.5rem;
  align-self: start;

  @media (max-width: ${sizes.tabletS}) {
    width: auto;
    margin-left: 0rem;
  }

  ${(props) =>
    props.truncateMode &&
    `
    cursor: pointer;
    margin-left: -0.75rem;
    :hover {
      color: ${props.theme.tokens.text.high}; 
      border: 1px solid ${props.theme.tokens.border.neutral.highest};
    }
  `}
`;

const StyledDrawerContent = styled(DrawerContent)`
  background: ${({ theme }) => theme.tokens.elevation.medium};
  flex-direction: column;
  gap: 0.5rem;
  display: flex;
  padding: 0.5rem;
  height: 100%;
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 18.75rem;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: ${({ theme }) => theme.tokens.background.overlay};
`;
