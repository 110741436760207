import { CircularProgress } from "@mui/material";
import styled from "styled-components/macro";

export const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.75rem;
  margin-left: -0.75rem;
`;
