import { Box } from "@mui/material";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import isEqual from "react-fast-compare";

import { ActionType } from "~/components/imports/enums";
import { ImportBody } from "~/components/imports/ImportBody";
import { type ImportMethod } from "~/types/enums";
import {
  type ImportOptionV2,
  type IntegrationModalSavedIntegration,
} from "~/types/index";

/**
 * The SetStartedSync action minus the type
 */
type SetStartedSyncArgs = Omit<
  Extract<
    Action,
    {
      type: ActionType.SetStartedSync;
    }
  >,
  "type"
> & {
  savedIntegration: IntegrationModalSavedIntegration;
};

const ImportContext = createContext<{
  state: State;
  setSyncStarted: (arg: SetStartedSyncArgs) => void;
  onImportDeleted?: () => void;
}>({
  state: { startedSync: false, isImportInDialog: false },
  setSyncStarted: () => {},
});

type State = {
  startedSync: boolean;
  accountId?: string;
  isImportInDialog?: boolean; // use for imports uplift ui v2
};

export const useImportContext = () => {
  return useContext(ImportContext);
};

type Action = {
  type: ActionType.SetStartedSync;
  value: boolean;
  accountId?: string;
};

const importReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SetStartedSync:
      return { ...state, isOnboarding: false, startedSync: action.value };
    default:
      return state;
  }
};

export const ImportItemV2 = memo(ImportItemMemoV2, isEqual);

function ImportItemMemoV2({
  importOption,
  presetImportMethod,
  onImport,
  onImportDeleted,
}: {
  importOption: ImportOptionV2;
  presetImportMethod?: ImportMethod;
  onImport?: (savedIntegration: IntegrationModalSavedIntegration) => void;
  onImportDeleted?: () => void;
}) {
  const [state, dispatch] = useReducer(importReducer, {
    startedSync: false,
    isImportInDialog: true,
  });

  const setSyncStarted = useCallback(
    function setSyncStarted(args: SetStartedSyncArgs) {
      dispatch({ type: ActionType.SetStartedSync, ...args });
      onImport?.(args.savedIntegration);
    },
    [onImport],
  );

  const value = useMemo(
    () => ({ state, setSyncStarted, onImportDeleted }),
    [state, setSyncStarted, onImportDeleted],
  );

  return (
    <ImportContext.Provider value={value}>
      <Box>
        <ImportBody
          importOption={importOption}
          isNew
          presetImportMethod={presetImportMethod}
        />
      </Box>
    </ImportContext.Provider>
  );
}
