import { Trade } from "@ctc/types";
import { type Blockchain } from "@ctc/types";
import {
  Close as CloseIcon,
  KeyboardArrowDown,
  OpenInNew,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  Link,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { bindPopover, bindTrigger } from "material-ui-popup-state/hooks";
import type * as React from "react";
import {
  type MouseEventHandler,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import styled from "styled-components/macro";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { Severity } from "~/components/transactions/action-row/enums";
import { ActionRowFirstLineStyle } from "~/components/transactions/action-row/Overflower";
import { Warnings } from "~/components/transactions/action-row/Warnings";
import { useCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import { useModal } from "~/components/transactions/command-palette/ModalProvider";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { ActionCategorisationModal } from "~/components/transactions/command-palette/views/recategorise/ActionCategorisationModal";
import { GroupedCategorySelector } from "~/components/transactions/command-palette/views/recategorise/GroupedCategorySelector";
import { CategoryBucketSelector } from "~/components/transactions/command-palette/views/recategorise/TopLevelTxCategory";
import {
  type EditActionAmountsValues,
  getUpdatedMarkAsNFTCurrencyIds,
  getValues as getAmountValues,
} from "~/components/transactions/edit/EditActionAmounts";
import {
  applyUpdates,
  type EditActionDrawerTradeValues,
  getValues,
  type TxTrade,
  useRowTradeFromForm,
} from "~/components/transactions/edit/EditActionTrade";
import { LockedTxEditFallback } from "~/components/transactions/edit/helper";
import { ActionTypeSelectorButtonType } from "~/components/transactions/enums";
import {
  formatDisplayAddress,
  getAddressLink,
} from "~/components/transactions/helpers";
import { SmartSuggestionIcon } from "~/components/ui/Icons";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { displayFiatValue } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { type PutTransactionPayload } from "~/services/actions";
import {
  getActionFaux,
  isGroupedTrade,
  isTrade,
  isUncategorisedTrade,
} from "~/services/transactions";
import { useAtomicUpdateTransactions } from "~/state/actions";
import { useEntityLookup } from "~/state/entities";
import { Warning } from "~/types/enums";
import {
  type ActionRow,
  type ActionType,
  type TransactionDetails,
} from "~/types/index";

const StyledTextButton = styled(TextButton)<{ rowIsLocked?: boolean }>`
  display: flex;
  justify-content: left;
  width: 100%;
  text-align: left;
  transition: inherit;
  font-family: ${({ theme }) =>
    theme.tokens.fontFamilies.numeric}; // Use the font-family from tokens
  font-size: 0.75rem;
  line-height: 1;
  ${ActionRowFirstLineStyle}

  &&& {
    word-break: normal;
    padding: 0 0.25rem;
    min-width: 0;
    color: ${({ theme, rowIsLocked }) =>
      rowIsLocked ? theme.tokens.text.low : "undefined"};
  }
`;

const StyledTertiaryButton = styled(TertiaryButton)`
  display: flex;
  justify-content: left;
  text-align: left;
  font-family: ${({ theme }) =>
    theme.tokens.fontFamilies.numeric}; // Use the font-family from tokens
  ${ActionRowFirstLineStyle}

  &&& {
    word-break: normal;
    padding-top: 0.125rem;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
    min-width: 0;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  ${ActionRowFirstLineStyle}

  &&& {
    word-break: normal;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
    min-width: 0;
  }
`;

/**
 * Gets the displayed text for the trade type selected
 * @param lang
 * @param isLocalCurrency
 * @param tradeType
 * @param uncatLabelOverride
 * @returns
 */
export function getDisplayText({
  lang,
  isLocalCurrency,
  tradeType,
  uncatLabelOverride,
  txs,
  labelOverride,
  isPending,
}: {
  lang: Translation;
  isLocalCurrency: boolean;
  tradeType: ActionType;
  uncatLabelOverride: string | undefined;
  txs: TransactionDetails[];
  labelOverride: ReactNode | undefined;
  isPending: boolean | undefined;
}) {
  if (uncatLabelOverride && isUncategorisedTrade(tradeType)) {
    return uncatLabelOverride;
  }

  // If there are no updates happening, show the labelOverride
  // For example, if its uncategorised, and the user chooses 'Buy' we show:
  // "Buy (Loading)"
  // then after it finishes loading, we show the labelOverride:
  // "Categorized! Good job!"
  if (labelOverride && !isPending) {
    return labelOverride;
  }

  if (isLocalCurrency) {
    switch (tradeType) {
      case Trade.In:
        return lang.txTable.fiatTradeLabel.deposit;
      case Trade.Out:
        return lang.txTable.fiatTradeLabel.withdrawal;
      default:
        return getActionTypeName({
          actionType: tradeType,
          lang,
          txs,
        });
    }
  }
  return getActionTypeName({
    actionType: tradeType,
    lang,
    txs,
  });
}

export function ActionTypeSelector({
  row,
  trigger = ActionTypeSelectorButtonType.Text,
  uncatLabelOverride,
  warningSeverity = Severity.Warn,
  chipOverride,
  labelOverride,
}: {
  row: ActionRow;
  trigger?: ActionTypeSelectorButtonType;
  uncatLabelOverride?: string;
  warningSeverity?: Severity;
  chipOverride?: React.ReactNode;
  // Shows this label instead of the category (only if there are no changes in
  // progress)
  labelOverride?: ReactNode;
}) {
  const modal = useModal();
  const { suggested, assumedTransfer } = row;
  const localCurrency = useLocalCurrency();
  const language = useLanguagePreference();
  const updateRowTransactionMutation = useAtomicUpdateTransactions();
  const analyticsKey = transactionAnalyticsKey("transferSmartContractWarning");
  const form = useForm<EditActionAmountsValues & EditActionDrawerTradeValues>({
    mode: "onBlur",
    defaultValues: {
      ...getAmountValues({ row }),
      ...getValues({ row }),
    },
  });
  const captureAnalytics = useCaptureAnalytics();
  const { formState, control, setValue, watch } = form;
  const { isValid, dirtyFields } = formState;
  const [smartContractWarningData, setSmartContractWarningData] = useState<{
    account: string | undefined;
    tx: TransactionDetails | undefined;
  }>({ account: undefined, tx: undefined });

  const lang = useLang();
  const anchor = useRef<HTMLDivElement>(null);
  const { tokens } = useDesign();

  const [subComponentTrades] = watch(["subComponentTrades"]);
  const tradeValue = useRowTradeFromForm(row, subComponentTrades);

  const warningSmartContractTransfer = useWarningSmartContractTransfer({
    row,
  });
  const isGrouped = isGroupedTrade(tradeValue);

  const taxSettings = useTaxSettings();
  const { open, close } = useCommandPalette();
  const { current, views, pop } = useNavController();

  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);

  const txs = [...row.outgoing, ...row.incoming];
  const currencies = Array.from(
    new Set(txs.map((tx) => tx.currencyIdentifier.id)),
  );

  const isLocalCurrency =
    currencies.includes(localCurrency || "") && !isGrouped;

  // Trade is further derived from the parent component, and cannot be used
  // directly from `row`. eg: parent component may alter the trade to `transfer`.
  const tradeFaux = getActionFaux(row, tradeValue);

  const handleClose = useCallback(
    // union is coming from mui
    (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
      // if you are loading, you cant close it
      if (modal.loading) {
        return;
      }
      // at the root page, so escape gets you out
      if (reason === "backdropClick" || views.length === 1) {
        close();
        return;
      }
      // not at the root page, so go back 1 view
      pop();
    },
    [close, modal.loading, pop, views.length],
  );

  const handleSubmit = async (
    formData: EditActionDrawerTradeValues & EditActionAmountsValues,
  ) => {
    const isDirty = dirtyFields.subComponentTrades;
    if (!isDirty || !isValid) return;

    close();

    const updateMap = new Map<string, PutTransactionPayload>();
    applyUpdates(row, form, formData, updateMap);
    const payload = Array.from(updateMap.values());

    const dirtyCurrencyIds = getUpdatedMarkAsNFTCurrencyIds({
      formState: form.formState,
      formData,
    });
    const markAsNFTUpdates = dirtyCurrencyIds.reduce(
      (updatesObj: Record<string, boolean>, currencyId) => {
        const isMarkedAsNFT = formData.updateMarkAsNFT[currencyId];
        if (isMarkedAsNFT === undefined) {
          return updatesObj; // This shouldn't happen.
        }
        updatesObj[currencyId] = isMarkedAsNFT;
        return updatesObj;
      },
      {},
    );

    updateRowTransactionMutation.mutate({
      actionId: row._id,
      update: {
        updateTx: {
          payload,
          applySts: formData.applySts,
          createCategoryRule: formData.createCategoryRule,
        },
      },
      markAsNFT: markAsNFTUpdates,
    });
  };

  useEffect(() => {
    // Allow trade to update after an outside state change, eg: report refresh.
    form.reset({
      ...getAmountValues({ row }),
      ...getValues({ row }),
    });
  }, [form, row]);

  if (!taxSettings || !localCurrency) return null;

  // todo remove all references to smart suggestion star
  const showStar = false; // suggested || assumedTransfer;
  const starToolTip = suggested
    ? lang.txTable.smartSuggestion
    : assumedTransfer
      ? lang.txTable.assumedTransfer
      : "";

  const dropdownOnSubmit = (txUpdates: TxTrade[]) => {
    setValue("subComponentTrades", txUpdates, {
      shouldValidate: true,
      shouldDirty: true,
    });
    form.handleSubmit(handleSubmit)();
  };

  const groupedOnSubmit = (txUpdates: TxTrade[]) => {
    for (const update of txUpdates) {
      const { account, tx } = warningSmartContractTransfer.isActive(
        update.txId,
        update.trade,
      );
      if (account && tx) {
        setSmartContractWarningData({ account, tx });
        warningSmartContractTransfer.setOpen(true);
        setValue("subComponentTrades", txUpdates, {
          shouldValidate: true,
          shouldDirty: true,
        });
        return;
      }
    }

    dropdownOnSubmit(txUpdates);
  };

  const root = row.isLocked ? (
    <LockedTxEditFallback />
  ) : isGrouped ? (
    <GroupedCategorySelector
      row={row}
      groupedTradeType={tradeValue}
      onSubmit={groupedOnSubmit}
      openMoreOptions={() => {
        close();
        setIsMoreOptionsOpen(true);
      }}
    />
  ) : (
    <CategoryBucketSelector
      row={row}
      selectedFromTrade={tradeValue}
      taxSettings={taxSettings}
      onSubmit={(trade) => {
        if (!trade || !isTrade(trade)) return;

        const txs = [...row.incoming, ...row.outgoing];
        const tx = txs[0];
        if (!tx) return;

        groupedOnSubmit([{ txId: tx._id, trade }]);
      }}
    />
  );

  const active = modal.isOpen || isMoreOptionsOpen;

  return (
    <FormProvider {...form}>
      <ActionCategorisationModal
        isOpen={isMoreOptionsOpen}
        onClose={() => {
          setIsMoreOptionsOpen(false);
        }}
        action={row}
        onSubmit={groupedOnSubmit}
      />
      {/**
       * Note: do not add a <form> element here
       * For some (currently unknown) reason, this results in a memory leak
       * when utilised within the virtualiser. Instead we just have the <div>
       * and pass the form to the Controller component and trigger onSubmit as required
       */}
      <div>
        <Controller
          control={control}
          name="trade"
          rules={{
            required: lang.editTx.mustCategory,
          }}
          render={() => (
            <div ref={anchor}>
              <TransferSmartContractWarningModal
                account={smartContractWarningData.account}
                tx={smartContractWarningData.tx}
                isOpen={warningSmartContractTransfer.open}
                onChangeClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  captureAnalytics(analyticsKey("change"), {
                    account: smartContractWarningData.account,
                    tx: smartContractWarningData.tx,
                    direction:
                      smartContractWarningData.account ===
                      smartContractWarningData.tx?.from
                        ? "from"
                        : "to",
                  });
                  form.reset();
                  warningSmartContractTransfer.setOpen(false);
                }}
                onIgnoreClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  captureAnalytics(analyticsKey("leave"), {
                    account: smartContractWarningData.account,
                    tx: smartContractWarningData.tx,
                    direction:
                      smartContractWarningData.account ===
                      smartContractWarningData.tx?.from
                        ? "from"
                        : "to",
                  });
                  warningSmartContractTransfer.setOpen(false);
                  warningSmartContractTransfer.setConfirm(true);
                  dropdownOnSubmit(subComponentTrades);
                  close();
                }}
              />
              <Popover
                {...bindPopover(modal)}
                anchorEl={anchor.current}
                PaperProps={{
                  sx: {
                    maxWidth: "22.5rem",
                    width: "100%",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: -8,
                  horizontal: "left",
                }}
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                onClose={handleClose}
              >
                <Box
                  sx={{
                    backgroundColor: tokens.elevation.medium,
                  }}
                >
                  {current}
                </Box>
              </Popover>
              {trigger === ActionTypeSelectorButtonType.Text ? (
                <Tooltip title={starToolTip}>
                  <StyledTextButton
                    rowIsLocked={row.isLocked}
                    endIcon={
                      updateRowTransactionMutation.isPending ? (
                        <CircularProgress size="1rem" />
                      ) : showStar ? (
                        <SmartSuggestionIcon
                          sx={{
                            marginLeft: "0.5rem",
                            width: "1rem",
                            color: tokens.text.brand,
                          }}
                        />
                      ) : null
                    }
                    {...bindTrigger(modal)}
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                      e.preventDefault();
                      e.stopPropagation();
                      e.currentTarget.blur();

                      open(root, e);
                    }}
                    disabled={
                      updateRowTransactionMutation.isPending || row.isLocked
                    }
                    active={active}
                  >
                    {getDisplayText({
                      lang,
                      isLocalCurrency,
                      tradeType: tradeFaux,
                      uncatLabelOverride,
                      labelOverride,
                      isPending: updateRowTransactionMutation.isPending,
                      txs,
                    })}
                    <Warnings
                      row={row}
                      warnings={[Warning.Uncategorised]}
                      severity={warningSeverity}
                    />
                  </StyledTextButton>
                </Tooltip>
              ) : trigger === ActionTypeSelectorButtonType.Secondary ? (
                <StyledSecondaryButton
                  size="small"
                  endIcon={
                    updateRowTransactionMutation.isPending ? (
                      <CircularProgress size="1rem" />
                    ) : null
                  }
                  {...bindTrigger(modal)}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.blur();
                    open(root, e);
                  }}
                  disabled={
                    updateRowTransactionMutation.isPending || row.isLocked
                  }
                  sx={{ ml: "0.5rem" }}
                >
                  {getDisplayText({
                    lang,
                    isLocalCurrency,
                    tradeType: tradeFaux,
                    uncatLabelOverride,
                    labelOverride,
                    isPending: updateRowTransactionMutation.isPending,
                    txs,
                  })}
                  {chipOverride === undefined ? (
                    <Warnings
                      row={row}
                      warnings={[Warning.Uncategorised]}
                      severity={warningSeverity}
                    />
                  ) : (
                    <Box ml="0.5rem">{chipOverride}</Box>
                  )}
                </StyledSecondaryButton>
              ) : trigger === ActionTypeSelectorButtonType.Primary ? (
                <PrimaryButton
                  size="small"
                  endIcon={
                    updateRowTransactionMutation.isPending ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <KeyboardArrowDown />
                    )
                  }
                  {...bindTrigger(modal)}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.blur();
                    open(root, e);
                  }}
                  disabled={
                    updateRowTransactionMutation.isPending || row.isLocked
                  }
                >
                  {getDisplayText({
                    lang,
                    isLocalCurrency,
                    tradeType: tradeFaux,
                    uncatLabelOverride,
                    labelOverride,
                    isPending: updateRowTransactionMutation.isPending,
                    txs,
                  })}
                </PrimaryButton>
              ) : (
                <Tooltip title={starToolTip}>
                  <StyledTertiaryButton
                    size="small"
                    endIcon={
                      updateRowTransactionMutation.isPending ? (
                        <CircularProgress size="1rem" />
                      ) : showStar ? (
                        <SmartSuggestionIcon
                          sx={{
                            marginLeft: "0.5rem",
                            width: "1rem",
                            color: tokens.text.brand,
                          }}
                        />
                      ) : null
                    }
                    {...bindTrigger(modal)}
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                      e.preventDefault();
                      e.stopPropagation();
                      e.currentTarget.blur();
                      open(root, e);
                    }}
                    disabled={
                      updateRowTransactionMutation.isPending || row.isLocked
                    }
                    active={active}
                  >
                    {getDisplayText({
                      lang,
                      isLocalCurrency,
                      tradeType: tradeFaux,
                      uncatLabelOverride,
                      labelOverride,
                      isPending: updateRowTransactionMutation.isPending,
                      txs,
                    })}
                    <Warnings
                      row={row}
                      warnings={[Warning.Uncategorised]}
                      severity={warningSeverity}
                      tooltipOverride={{
                        [Warning.Uncategorised]:
                          lang.txTable.commandPallet.placeholders.tradeTypeSearch.uncategorisedTooltipOverride(
                            {
                              value: displayFiatValue({
                                value:
                                  row.uncategorisedGroupData?.groupValue || 0,
                                localCurrency,
                                locale: language,
                              }),
                            },
                          ),
                      }}
                    />
                  </StyledTertiaryButton>
                </Tooltip>
              )}
            </div>
          )}
        />
      </div>
    </FormProvider>
  );
}

export const useWarningSmartContractTransfer = ({
  row,
}: {
  row: ActionRow;
}) => {
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const nullEntity = useEntityLookup("null-address");
  const isTransferLike = (trade: ActionType) =>
    [Trade.Deposit, Trade.Withdrawal].includes(trade as Trade);

  const burnAddresses =
    nullEntity && "globalAddresses" in nullEntity
      ? nullEntity.globalAddresses.map((addr) => addr.address)
      : [];

  const records: Record<string, { account: string; tx: TransactionDetails }> =
    [...row.incoming, ...row.outgoing].reduce(
      (
        acc: Record<string, { account: string; tx: TransactionDetails }>,
        tx,
      ) => {
        if (burnAddresses.includes(tx.from))
          return { ...acc, [tx._id]: { account: tx.from, tx } };
        if (burnAddresses.includes(tx.to))
          return { ...acc, [tx._id]: { account: tx.to, tx } };
        if (tx.isFromSmartContract)
          return { ...acc, [tx._id]: { account: tx.from, tx } };
        if (tx.isToSmartContract)
          return { ...acc, [tx._id]: { account: tx.to, tx } };

        return acc;
      },
      {},
    ) || [];

  return {
    confirm,
    setConfirm,
    open,
    setOpen,
    isActive: (txId: string, trade: ActionType) => {
      const { account, tx } = records[txId] || {
        account: undefined,
        tx: undefined,
      };

      if (account && tx && isTransferLike(trade) && !confirm) {
        return { account, tx };
      }
      return { account: undefined, tx: undefined };
    },
  };
};

export const TransferSmartContractWarningModal = ({
  account,
  tx,
  isOpen,
  onChangeClick,
  onIgnoreClick,
}: {
  account?: string;
  tx?: TransactionDetails;
  isOpen: boolean;
  onChangeClick: MouseEventHandler<HTMLElement>;
  onIgnoreClick: MouseEventHandler<HTMLElement>;
}) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const { transferSmartContract } = lang.txTable.typeUpdateWarningModal;
  const isFromContract = account === tx?.from;
  const entity = useEntityLookup(account, tx?.blockchain as Blockchain);

  if (!tx || !account) return null;

  const displayName = entity
    ? `${entity.displayName} (${formatDisplayAddress(account, false, true)})`
    : account;

  const addressLink = getAddressLink(
    account,
    tx.currencyIdentifier.symbol,
    tx.blockchain,
  );

  return (
    <Dialog
      onBackdropClick={onChangeClick}
      open={isOpen}
      onClose={onChangeClick}
      PaperProps={{
        sx: {
          background: tokens.elevation.medium,
          border: `1px solid ${tokens.border.neutral.default}`,
        },
      }}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          padding: "1.5rem",
          background: tokens.background.neutral.default,
        }}
      >
        <Stack direction="column" gap="1rem">
          <Stack direction="row" alignItems="center">
            <Typography variant="Metropolis/Header/H4">
              {isFromContract
                ? transferSmartContract.receiveFromTitle
                : transferSmartContract.sendToTitle}
            </Typography>
            <TextIconButton
              aria-label="close"
              color="default"
              onClick={onChangeClick}
              size="small"
              sx={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </TextIconButton>
          </Stack>

          <Box
            style={{
              border: `1px solid ${tokens.border.neutral.default}`,
              padding: "0.35rem 0.5rem",
              borderRadius: "0.25rem",
            }}
          >
            <Box
              style={{
                fontSize: "0.625rem",
                fontWeight: 500,
                color: tokens.text.low,
              }}
            >
              {transferSmartContract.contract}:
            </Box>
            <Typography variant="Metropolis/Body/Regular">
              {addressLink ? (
                <Link
                  href={addressLink}
                  target="__blank"
                  sx={{
                    textDecoration: "none",
                    "*": { verticalAlign: "middle" },
                  }}
                >
                  {displayName}{" "}
                  <OpenInNew
                    fontSize="small"
                    sx={{ position: "relative", top: "-1px" }}
                  />
                </Link>
              ) : (
                displayName
              )}
            </Typography>
          </Box>
          <Typography variant="Metropolis/Body/Regular">
            {isFromContract
              ? transferSmartContract.receiveFromInfo
              : transferSmartContract.sendToInfo}
          </Typography>
          <Typography variant="Metropolis/Body/Regular">
            {isFromContract
              ? transferSmartContract.receiveFromAction
              : transferSmartContract.sendToAction}
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          padding: "1.25rem 1.5rem",
          borderTop: `1px solid ${tokens.border.neutral.default}`,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="1rem"
        >
          <TertiaryButton onClick={onIgnoreClick}>
            {transferSmartContract.ignore}
          </TertiaryButton>
          <PrimaryButton
            onClick={onChangeClick}
            endIcon={<KeyboardArrowDown />}
          >
            {transferSmartContract.change}
          </PrimaryButton>
        </Box>
      </Box>
    </Dialog>
  );
};
