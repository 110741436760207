import { FiatValueDisplay } from "~/components/ui/FiatValueDisplay";
import { usePaywallInfo } from "~/redux/auth";

export function ValueChangeBox({
  value,
  screen,
  ...props
}: {
  value: number;
  isPercentage?: boolean;
  screen?: boolean;
  showDirectionIcon?: boolean;
  disableCurrencySymbol?: boolean;
}) {
  const paywallInfo = usePaywallInfo();
  const screenValue = !!(paywallInfo?.isPaywalled && screen);

  return (
    <FiatValueDisplay value={value} screenValue={screenValue} {...props} />
  );
}
