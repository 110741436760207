import { queryClient } from "~/components/queryClient";

export const taxLossHarvestingQueryKeys = {
  all: () => ["tax-loss-harvesting"] as const,
  summary: () => ["tax-loss-harvesting", "summary"] as const,
  holdings: () => ["tax-loss-harvesting", "holdings"] as const,
  missingPrices: () => ["tax-loss-harvesting", "missingPrices"] as const,
};

export const refreshAllTLHData = async () => {
  queryClient.invalidateQueries({ queryKey: taxLossHarvestingQueryKeys.all() });
};

export const refreshTaxLossHarvestingHoldingsPayload = async () => {
  queryClient.invalidateQueries({
    queryKey: taxLossHarvestingQueryKeys.holdings(),
  });
};
