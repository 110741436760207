import { type LocalCurrency, type SupportedLang } from "@ctc/types";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { getCompactFiatValue } from "~/components/transactions/action-row/getCompactFiatValue";
import { GainLossVariant } from "~/components/transactions/enums";
import { SquareChip } from "~/components/ui/SquareChip";
import { displayFiatCompactValue, displayFiatValue } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";

import { getCompactFormattedValue } from "./action-row/helpers";

// Helper function to encapsulate the ternary logic of displaying the gain/loss value
const getDisplayValue = (
  totalGain: number,
  cryptocurrencySymbol?: string,
  showCurrencySymbol?: boolean,
  compactValue?: string,
  localCurrency?: string,
  languagePreference?: string,
) => {
  if (cryptocurrencySymbol) {
    return `${totalGain} ${cryptocurrencySymbol}`;
  }
  if (showCurrencySymbol) {
    return displayFiatValue({
      value: totalGain,
      localCurrency: localCurrency as LocalCurrency,
      locale: languagePreference as SupportedLang,
    });
  }
  return compactValue || getCompactFiatValue(totalGain, totalGain < 1 ? 4 : 2);
};

export const GainLossBox = ({
  totalGain,
  isGrey = false,
  isLoading = false,
  cryptocurrencySymbol,
  variant = GainLossVariant.Box,
  showCurrencySymbol = true,
  showArrow = true,
}: {
  totalGain: number | null;
  isGrey?: boolean;
  isLoading?: boolean;
  cryptocurrencySymbol?: string;
  // Just the plain text, or the background box
  variant?: GainLossVariant;
  showCurrencySymbol?: boolean;
  showArrow?: boolean;
}) => {
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const lang = useLang();

  if (!localCurrency) return null;

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Skeleton
          variant="rectangular"
          width="4rem"
          height="1.5rem"
          sx={{ borderRadius: "0.25rem" }}
        />
      </Box>
    );
  }

  if (Number.isNaN(totalGain) || !totalGain || isGrey) {
    const label =
      totalGain === null
        ? lang.na
        : cryptocurrencySymbol
          ? `${totalGain} ${cryptocurrencySymbol}`
          : showCurrencySymbol
            ? displayFiatValue({
                value: totalGain,
                localCurrency,
                locale: languagePreference,
              })
            : displayFiatCompactValue(totalGain, undefined);

    if (variant === GainLossVariant.Text) {
      return (
        <StyledTypography isGrey={isGrey} variant={variant}>
          {label}
        </StyledTypography>
      );
    }

    return <SquareChip label={label} fontSize="0.813rem" fontWeight={600} />;
  }

  const displayValue = displayFiatValue({
    value: Math.abs(totalGain),
    localCurrency,
    locale: languagePreference,
    fallbackMinFractionDigits: 6,
  });

  const toolTipTitle =
    totalGain > 0
      ? lang.txTable.tooltipGain({ totalGain: displayValue })
      : lang.txTable.tooltipLoss({ totalLoss: displayValue });

  const compactValue = getCompactFormattedValue(
    languagePreference,
    totalGain,
    localCurrency as LocalCurrency,
    getCompactFiatValue(totalGain, totalGain < 1 ? 4 : 2),
  );

  return (
    <Tooltip title={toolTipTitle} placement="top">
      <Box>
        <StyledBox gain={totalGain > 0} variant={variant}>
          <StyledTypography gain={totalGain > 0} variant={variant}>
            {showArrow ? (
              totalGain > 0 ? (
                <ArrowDropUp style={{ height: "1rem" }} />
              ) : (
                <ArrowDropDown style={{ height: "1rem" }} />
              )
            ) : null}
            {getDisplayValue(
              totalGain,
              cryptocurrencySymbol,
              showCurrencySymbol,
              compactValue,
              localCurrency,
              languagePreference,
            )}
          </StyledTypography>
        </StyledBox>
      </Box>
    </Tooltip>
  );
};

const StyledBox = styled(({ variant, gain, ...rest }) => <Box {...rest} />)`
  && {
    white-space: nowrap;
    padding: ${({ variant }) =>
      variant === GainLossVariant.Box ? "0.125rem 0.25rem" : "0"};
    background-color: ${({ variant, gain = true, theme }) =>
      variant === GainLossVariant.Box
        ? gain
          ? theme.tokens.background.success.default
          : theme.tokens.background.danger.default
        : "transparent"};
    border-radius: 0.25rem;
    vertical-align: middle;
  }

  * {
    vertical-align: middle;
  }

  svg {
    margin: -2px -4px 0 -8px;
  }
`;

const StyledTypography = styled(({ gain, isGrey, variant, ...rest }) => (
  <Typography {...rest} />
))`
  && {
    font-family: ${({ theme }) => theme.tokens.fontFamilies.numeric};
    font-size: 0.75rem;
    color: ${({ isGrey, gain = true, theme }) =>
      isGrey
        ? theme.tokens.text.disabled
        : gain
          ? theme.tokens.text.success
          : theme.tokens.text.danger};
    font-weight: ${({ variant }) =>
      variant === GainLossVariant.Box ? 600 : 500};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
