import { Box } from "@mui/material";
import { Fragment } from "react";

import { ImportInstructionsAccordion } from "~/components/imports/ImportInstructionsAccordion";
import { ManualCSVImportNote } from "~/components/imports/ManualCSVInstructions";
import { useLang } from "~/redux/lang";
import { ImportType } from "~/types/enums";
import { type ImportTypeInstruction } from "~/types/index";

export const requiredIntegrationsOnboardingNote: Record<
  string,
  ImportType[] | undefined
> = {
  btc: [ImportType.WalletAPI],
  binance: [ImportType.API, ImportType.CSV],
  bybit: [ImportType.API],
};

export function AdditionalNotes({
  noteSteps,
  isManualCSVOnly,
  isImportingCSV,
  dontShowOnboardingNote,
}: {
  noteSteps: ImportTypeInstruction[];
  isManualCSVOnly: boolean;
  isImportingCSV: boolean;
  dontShowOnboardingNote: boolean;
}) {
  const lang = useLang();

  if (dontShowOnboardingNote) {
    return null;
  }

  if (!(noteSteps.length > 0 || (!isManualCSVOnly && isImportingCSV))) {
    return null;
  }

  return (
    <Box sx={{ overflowWrap: "break-word", width: "100%" }}>
      {noteSteps.map((note, index) => (
        <Fragment key={`${note.title || index}`}>
          <ImportInstructionsAccordion
            instruction={note}
            titleDefault={lang.imports.additionalNotes}
          />
        </Fragment>
      ))}
      {!isManualCSVOnly && isImportingCSV ? <ManualCSVImportNote /> : null}
    </Box>
  );
}
