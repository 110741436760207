import { type RecordSurveyRequestSchema } from "@ctc/contracts";
import { type z } from "zod";

import { client } from "./tsRestClient";

export function submitSurveyResponse(
  body: z.infer<typeof RecordSurveyRequestSchema>,
) {
  return client.survey.recordSurveyResponse({
    body,
  });
}
