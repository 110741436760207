import { type Blockchain } from "@ctc/types";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import type * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  type Control,
  Controller,
  useForm,
  type UseFormReturn,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";

import { importAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { AdvancedImportOptions } from "~/components/imports/advancedImportOptions";
import { MuiImportGrid } from "~/components/imports/api/index";
import { ImportFormContext } from "~/components/imports/context/index";
import { AddressError } from "~/components/imports/enums";
import { ImportFromSelector } from "~/components/imports/ImportFromSelector";
import { useImportContext } from "~/components/imports/ImportItem";
import { ImportTypeEtaBox } from "~/components/imports/ImportTypeEtaBox";
import { OnboardingNavBar } from "~/components/imports/OnboardingNavBar";
import { nameServiceNameSuffixes } from "~/components/imports/wallet/config";
import { ImportToggleSwitch } from "~/components/imports/wallet/ImportToggleSwitch";
import { NameServiceAwareAddressChip } from "~/components/imports/wallet/NameServiceAwareAddressChip";
import { BTC_PUB_ADDRESS_REGEX } from "~/components/imports/wallet/WalletItem";
import { WalletWarningWrapper } from "~/components/imports/wallet/WalletWarningWrapper";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useIsOnboardingMobileImportExperiment } from "~/hooks/useIsOnboardingMobileImportExperiment";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { isValidWalletAddress } from "~/services/wallet/isValidWalletAddress";
import { formatWalletAddress } from "~/services/wallets";
import {
  useEntityLookupAsync,
  useRemoveAddressFromEntityMutation,
} from "~/state/entities";
import { useAvailableImportOption } from "~/state/imports";
import { useGetSavedImportsByIntegration } from "~/state/importsV2";
import {
  useAddWalletMutation,
  useDeleteWalletMutation,
  useEditWalletMutation,
} from "~/state/wallets";
import { ImportMethod, RelatedWalletsAction } from "~/types/enums";
import {
  BlockchainName,
  EvmBlockchains,
  HighSpamProbabilityEVMs,
  type ImportOptionV2,
  type IntegrationModalSavedIntegrationWallet,
  isBlockchainImport,
  isEVMBlockchain,
  type SavedWalletImport,
  type WalletFormValues,
  type WalletImportMethod,
} from "~/types/index";

const MAX_WALLET_IMPORTS = 20;

export type WalletInputController = {
  formController: UseFormReturn<WalletFormValues>;
  importFromTime: Date | undefined;
  setImportFromTime: (date: Date | undefined) => void;
  editingWalletAddress: string | undefined;
  setEditingWalletAddress: (address: string | undefined) => void;
  handleEdit: (
    wallet: SavedWalletImport & { importFromTimestamp?: number },
  ) => void;
  handleCancelEdit: () => void;
  handleDelete: (wallet: SavedWalletImport) => void;
};

// Create state separate from Wallet so external component can control it
export function CreateWalletInputController(
  importOption: ImportOptionV2,
): WalletInputController {
  const deleteWallet = useDeleteWalletMutation();
  const context = useContext(ImportFormContext);
  const { onImportDeleted } = useImportContext();

  const formController = useForm<WalletFormValues>({
    mode: "onBlur",
    defaultValues: {
      id: "",
      address: "",
      name: "",
    },
  });
  const [importFromTime, setImportFromTime] = useState<Date | undefined>(
    undefined,
  );
  const [editingWalletAddress, setEditingWalletAddress] = useState<
    string | undefined
  >(undefined);

  const { setValue, clearErrors, reset } = formController;
  const walletImportMethod = importOption.importMethods.find((method) =>
    [ImportMethod.Wallet, ImportMethod.BulkWallet].includes(method.type),
  );

  const handleEdit = (
    wallet: SavedWalletImport & { importFromTimestamp?: number },
  ) => {
    if (!walletImportMethod) {
      return;
    }
    setValue("address", wallet.address);
    setValue("name", wallet.name || undefined);
    setValue("id", wallet.id);
    setValue("importFromTimeMillis", wallet.importFromTimestamp);
    setImportFromTime(wallet.importFromDate);
    clearErrors();
    setEditingWalletAddress(wallet.address);
    context?.setImportIssue(wallet.errorIssue);
    context?.setImportMethod(walletImportMethod);
    context?.setShowImportForm(true);
  };

  const handleCancelEdit = () => {
    reset();
    context?.setImportIssue(undefined);
    setEditingWalletAddress(undefined);
    setImportFromTime(undefined);
    context?.setShowImportForm(false);
  };

  const handleDelete = (wallet: SavedWalletImport) => {
    deleteWallet.mutate(wallet);
    handleCancelEdit();
    onImportDeleted?.();
  };

  return {
    formController,
    importFromTime,
    setImportFromTime,
    editingWalletAddress,
    setEditingWalletAddress,
    handleEdit,
    handleCancelEdit,
    handleDelete,
  };
}

export function Wallet(props: {
  importOption: ImportOptionV2;
  blockchain: Blockchain;
  importMethod: WalletImportMethod;
  controller: WalletInputController;
}) {
  return <WalletBody {...props} />;
}

function WalletBody({
  importOption,
  blockchain,
  importMethod,
  controller,
}: {
  importOption: ImportOptionV2;
  blockchain: Blockchain;
  importMethod: WalletImportMethod;
  controller: WalletInputController;
}) {
  const { tokens } = useDesign();
  const [addressFromQuery] = useQueryParam("address", StringParam);
  const { sourceId: addressFromParams } = useParams<{ sourceId: string }>(); // From inside reconciliation views.
  const captureAnalytics = useCaptureAnalytics();
  const advancedOptionsKey = importAnalyticsKey("advanced options");
  const lang = useLang();
  const [importRelatedWallets, setImportRelatedWallets] = useState(true);
  const { getEntityForAddress } = useEntityLookupAsync();
  const removeAddressFromEntityMutation = useRemoveAddressFromEntityMutation();
  const { setSyncStarted } = useImportContext();
  const savedImport = useGetSavedImportsByIntegration(blockchain);
  const addWallet = useAddWalletMutation({ isUsingImportForm: true });
  const editWallet = useEditWalletMutation();
  const [importFromModal, setImportFromModal] = useState(false);
  const importFormContext = useContext(ImportFormContext);
  const blockchainImportOption = useAvailableImportOption(blockchain);
  const isUnspecifiedBlockchainAllowed =
    !!importMethod.options?.isUnspecifiedBlockchainAllowed;
  const isBlockchain = isBlockchainImport(importOption);
  const [addressToNameServiceNameMap, setAddressToNameServiceNameMap] =
    useState<Map<string, string>>(new Map());
  const [loading, setLoading] = useState(false);
  const isOnboardingMobileImportExperiment =
    useIsOnboardingMobileImportExperiment();

  useEffect(() => {
    const addressFromURL = addressFromParams || addressFromQuery;
    if (!controller.formController.getValues().address && addressFromURL) {
      controller.formController.setValue("address", addressFromURL);
    }
  }, [addressFromParams, addressFromQuery]);

  const {
    formController,
    handleCancelEdit,
    setEditingWalletAddress,
    setImportFromTime,
    editingWalletAddress,
    importFromTime,
  } = controller;

  const { handleSubmit, setValue, reset, control } = formController;

  const [addresses, setAddresses] = useQueryParam("addresses", ArrayParam);

  const allNameServiceNamesResolved =
    addresses
      ?.map(
        (addr) =>
          !nameServiceNameSuffixes.some((suffix) => addr?.endsWith(suffix)),
      )
      .every(Boolean) ?? true;

  const walletImport = importOption.importMethods.find(
    (item) => item.type === ImportMethod.Wallet,
  ) as WalletImportMethod;

  const selectedBlockchain = isUnspecifiedBlockchainAllowed
    ? walletImport?.blockchains[0]
    : blockchain;

  // Update the address to ens name map and convert any ens name to their addresses in the addresses state
  const updateAddressToNameServiceNameMap = (
    address: string,
    ensName: string,
  ) => {
    setAddressToNameServiceNameMap((prev) => {
      prev.set(address, ensName);
      return prev;
    });

    // convert any ens names to their resolved addresses
    const resolvedAddresses = addresses?.map((a) => {
      if (a === ensName) {
        return address;
      }
      return a;
    });
    setAddresses(resolvedAddresses);
  };

  // Get address from nameServiceName if mapping already exists, otherwise return the nameServiceName
  const resolveAddress = (nameServiceName: string) => {
    return addressToNameServiceNameMap
      ? Array.from(addressToNameServiceNameMap.entries()).find(
          (entry) => entry[1] === nameServiceName,
        )?.[0] || nameServiceName
      : nameServiceName;
  };

  // Handle unmounting of component, clear addresses here when the component
  //  is closed by ImportForm further up the chain
  useEffect(() => {
    // This function does nothing when the component mounts
    return () => {
      // Clear the addresses state when the component unmounts
      setAddresses([]);
    };
  }, []);

  useEffect(() => {
    const name = formController.getValues().name?.trim() ?? "";
    const nameServiceName = addressToNameServiceNameMap.get(
      addresses?.[0] ?? "",
    );
    const firstAddress = addresses?.[0] ?? "";
    if (
      firstAddress &&
      addresses?.length === 1 &&
      (!name ||
        nameServiceNameSuffixes.some((suffix) => name.endsWith(suffix))) &&
      nameServiceName
    ) {
      setValue("name", nameServiceName);
    }
  }, [addresses, addressToNameServiceNameMap]);

  async function onSubmit(formData: {
    id: string;
    address: string;
    name: string | undefined;
    importFromTimeMillis?: number;
  }) {
    setLoading(true);
    try {
      if (addresses) {
        const name = addresses.length > 1 ? undefined : formData.name;

        // Iterate over each address in the addresses state
        for (const rawAddress of addresses) {
          // The rest of your submission logic here, applied to each address
          if (rawAddress !== null) {
            const nickname =
              name ?? addressToNameServiceNameMap.get(rawAddress);

            const savedIntegration: IntegrationModalSavedIntegrationWallet = {
              type: ImportMethod.Wallet,
              addresses: addresses.filter(Boolean) as string[],
              blockchain: selectedBlockchain ?? blockchain,
              name: nickname,
              importFromTimeMillis: formData.importFromTimeMillis,
              importSource: formData.id,
            };

            await submitAddress(
              rawAddress,
              { ...formData, name: nickname },
              savedIntegration,
            );
          }
        }
      } else {
        const savedIntegration: IntegrationModalSavedIntegrationWallet = {
          type: ImportMethod.Wallet,
          addresses: [formData.address],
          blockchain: selectedBlockchain ?? blockchain,
          name: formData.name,
          importFromTimeMillis: formData.importFromTimeMillis,
          importSource: formData.id,
        };

        // Fallback to the existing logic if the feature flag is off
        await submitAddress(formData.address, formData, savedIntegration);
      }

      // After submission tasks
      reset();
      setEditingWalletAddress(undefined);
      setImportFromTime(undefined);
      setImportFromModal(false);
      importFormContext?.setShowImportForm(false);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  }

  // Refactored submission logic for a single address into a reusable function
  async function submitAddress(
    rawAddress: string,
    formData: {
      id: string;
      address: string;
      name: string | undefined;
      importFromTimeMillis?: number;
    },
    savedIntegration: IntegrationModalSavedIntegrationWallet,
  ) {
    const parsedAddress = formatWalletAddress(rawAddress, selectedBlockchain);

    setTimeout(() => {
      setSyncStarted({
        value: true,
        accountId: parsedAddress,
        savedIntegration,
      });
    }, 0);

    if (isEditing) {
      const oldWallet = savedImport?.wallets.find(
        ({ id }) => formData.id === id,
      );
      if (oldWallet) {
        editWallet.mutate({
          ...oldWallet,
          name: formData.name,
        });
      }
    } else {
      const savedAddress = getEntityForAddress(rawAddress);
      if (formData.importFromTimeMillis || !importRelatedWallets) {
        captureAnalytics(advancedOptionsKey("modified"), {
          integration: importOption.id,
          importMethod: ImportMethod.Wallet,
          blockchain,
          importRelatedWallets,
        });
      }

      addWallet.mutate({
        address: parsedAddress,
        type: selectedBlockchain,
        name: formData.name,
        importFromTimeMillis: formData.importFromTimeMillis,
        relatedWalletsAction: importRelatedWallets
          ? RelatedWalletsAction.Notify
          : RelatedWalletsAction.Ignore,
        isUnspecifiedBlockchain: isUnspecifiedBlockchainAllowed,
        blockchainImportOption,
        importSource: importOption.id,
        importMethod: ImportMethod.Wallet,
      });
      if (savedAddress) {
        removeAddressFromEntityMutation.mutate({
          entity: savedAddress,
          removedAddress: {
            address: rawAddress,
            blockchain: selectedBlockchain,
          },
        });
      }
    }
  }

  function checkModal(formData: {
    id: string;
    address: string;
    name: string | undefined;
    importFromTimeMillis?: number;
  }) {
    if (formData.importFromTimeMillis) {
      setImportFromModal(true);
      return;
    }
    onSubmit(formData);
  }

  // Check an address to see if it's valid and is not already imported (ie. both the 'exists' and 'invalid' controller checks)
  // This is done to ensure we're showing an error state for chips when there's an error in the input so everything aligns
  function checkChipForErrors(address: string, index: number) {
    if (existingAddressesLowerCase.includes(address.toLowerCase())) {
      return AddressError.alreadyExists;
    }

    if (
      !isValidWalletAddress(
        address,
        blockchain,
        !!importMethod.options?.isBtcAltNetwork,
      )
    ) {
      return AddressError.invalid;
    }

    if (index >= MAX_WALLET_IMPORTS) {
      return AddressError.tooManyWallets;
    }

    return undefined;
  }

  const handleImportRelatedWalletsChange = async (
    _e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setImportRelatedWallets(checked);
  };

  const existingAddressesLowerCase =
    savedImport?.wallets.map(({ address }) => address.toLowerCase()) || [];
  const existing = existingAddressesLowerCase.length > 0;
  const isEditing = Boolean(editingWalletAddress);
  const isEvmChain = isEVMBlockchain(blockchain);

  const onAddressChange = (address: string) => {
    setValue("address", address, {
      shouldValidate: true,
      shouldDirty: true,
    });

    const entity = getEntityForAddress(address);

    if (entity) {
      setValue("name", entity.displayName, {
        shouldValidate: true,
      });
    }
  };

  const handleImportFromChange = (date: string) => {
    const value = date ? moment(date).valueOf() : undefined;
    setValue("importFromTimeMillis", value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  function handleAddressesPaste(event: React.ClipboardEvent<HTMLDivElement>) {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text from the clipboard
    const pasteText = event.clipboardData.getData("text");

    // Split the pasted text based on commas and whitespace, then filter out empty strings
    const newAddresses = pasteText.split(/[\s,]+/).filter((text) => text);

    // Resolve any name service names to their addresses using the addressToNameServiceNameMap
    const resolvedAddresses = newAddresses.map(resolveAddress);

    // Ensure addresses is always treated as an array, defaulting to an empty array if it's null or undefined
    const currentAddresses = addresses ?? [];

    // Merge with existing addresses and remove duplicates
    const updatedAddresses = Array.from(
      new Set([...currentAddresses, ...resolvedAddresses]),
    );

    // Update the addresses state
    setAddresses(updatedAddresses);
  }

  // A function to take in the current input value of a TextField, add it to the addresses state, and clear the input field
  function addInputToStateAndClear(
    value: string,
    params: AutocompleteRenderInputParams,
  ) {
    // Access the current input value directly from the event target
    const inputValue = value.trim();

    if (inputValue) {
      // Split the inputValue based on commas and whitespace, filter out any empty strings
      const splitValues = inputValue.split(/[\s,]+/).filter(Boolean);

      // Resolve any name service names to their addresses using the addressToNameServiceMap
      const resolvedAddresses = splitValues.map(resolveAddress);

      // Ensure addresses is always treated as an array, defaulting to an empty array if it's null or undefined
      const currentAddresses = addresses ?? [];

      // Merge with existing addresses and remove duplicates
      const updatedAddresses = Array.from(
        new Set([...currentAddresses, ...resolvedAddresses]),
      );

      setAddresses(updatedAddresses);

      // Reset the input field
      // Creating a more compliant synthetic event for onChange
      const changeEvent = {
        target: {
          value: "",
          name: params.inputProps.content,
        } as EventTarget & HTMLInputElement,
        preventDefault: () => {},
        stopPropagation: () => {},
      } as React.ChangeEvent<HTMLInputElement>;

      // Safely call onChange if it exists
      params.inputProps.onChange?.(changeEvent);
    }
  }

  const sourceLabel = isBlockchain
    ? BlockchainName[blockchain]
    : importOption.name.toLowerCase().endsWith("wallet")
      ? importOption.name.slice(0, -6)
      : importOption.name;

  const showRelatedWalletsOption =
    isEvmChain && // only EVM chains have related wallets at this time
    !isEditing && // can't edit this
    !isUnspecifiedBlockchainAllowed; // don't show the option for softwallets that contain all related wallets

  const blockchainImportOptionWallet =
    blockchainImportOption?.importMethods?.find(
      (method) => method.type === ImportMethod.Wallet,
    );

  if (isOnboardingMobileImportExperiment) {
    return (
      <form onSubmit={handleSubmit(checkModal)}>
        <Controller
          control={control}
          name="address"
          rules={{
            required:
              !!addresses && addresses.length > 0
                ? false
                : lang.wallet.importRules.bulkEmpty({
                    name: importOption.name,
                  }),

            validate: {
              exists: () =>
                addresses?.some(
                  (address) =>
                    !!address &&
                    existingAddressesLowerCase.includes(address.toLowerCase()),
                )
                  ? lang.wallet.importRules.bulkExisting({
                      name: sourceLabel,
                    })
                  : true,
              invalid: () =>
                !!addresses &&
                addresses.some(
                  (address) =>
                    !!address &&
                    !isValidWalletAddress(
                      address,
                      blockchain,
                      !!importMethod.options?.isBtcAltNetwork,
                    ),
                )
                  ? lang.wallet.importRules.bulkInvalid({
                      name: sourceLabel,
                    })
                  : !!addresses && addresses.length > MAX_WALLET_IMPORTS
                    ? lang.wallet.importRules.tooManyWallets({
                        maxWalletImports: MAX_WALLET_IMPORTS,
                      })
                    : true,
            },
          }}
          render={({ field, fieldState: { invalid, error } }) => (
            <Autocomplete
              clearIcon={false}
              value={addresses || []}
              options={[]}
              freeSolo
              multiple
              // TODO: Fix up value being an any type
              onChange={(event, value: any) => {
                setAddresses(value);
              }}
              renderTags={(value, getTagProps) => {
                return (
                  <Stack direction="row" flexWrap="wrap" gap="0.25rem">
                    {value.map((option, index) => (
                      <NameServiceAwareAddressChip
                        address={option}
                        blockchain={blockchain}
                        {...getTagProps({ index })}
                        key={option + index}
                        enableDelete
                        addressError={checkChipForErrors(option, index)}
                        updateAddressToNameServiceNameMap={
                          updateAddressToNameServiceNameMap
                        }
                        addressToNameServiceNameMap={
                          addressToNameServiceNameMap
                        }
                      />
                    ))}
                  </Stack>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...field}
                  onBlur={(event) => {
                    addInputToStateAndClear(event.target.value, params);
                  }}
                  onKeyDown={(event) => {
                    const inputValue = (
                      event.target as HTMLInputElement
                    ).value.trim();
                    if (event.key === " " || event.key === ",") {
                      event.preventDefault();
                      addInputToStateAndClear(inputValue, params);
                      return;
                    }
                    if (event.key) {
                      // Check each suffix to see if we're about to complete it
                      const matchingSuffix = nameServiceNameSuffixes.find(
                        (suffix) => {
                          const incompleteSuffix = suffix.slice(0, -1);
                          return (
                            inputValue.endsWith(incompleteSuffix) &&
                            suffix.endsWith(event.key)
                          );
                        },
                      );

                      if (matchingSuffix) {
                        event.preventDefault();
                        addInputToStateAndClear(
                          `${inputValue}${event.key}`,
                          params,
                        );
                      }
                    }
                  }}
                  onPaste={handleAddressesPaste}
                  margin="normal"
                  variant="outlined"
                  type="text"
                  label={lang.wallet.address({
                    source: sourceLabel,
                  })}
                  helperText={error?.message}
                  error={invalid}
                  {...params}
                  sx={{
                    backgroundColor: "transparent",
                    ".MuiOutlinedInput-root": {
                      backgroundColor: tokens.background.input.default,
                    },
                    "& .Mui-error": {
                      marginLeft: 0,
                    },
                  }}
                />
              )}
            />
          )}
        />
        {/** TODO: Extract this into a component that can be shared. */}
        {isOnboardingMobileImportExperiment ? (
          <OnboardingNavBar
            importOption={importOption}
            importMethod={importMethod}
            handleSubmit={handleSubmit(onSubmit)}
          />
        ) : null}
      </form>
    );
  }

  return (
    <>
      <ConfirmationDialog
        isOpen={importFromModal}
        title={lang.imports.importFrom.modal.title}
        text={lang.imports.importFrom.modal.text}
        actionText={lang.imports.importFrom.modal.actionText}
        handleClose={() => {
          setImportFromModal(false);
        }}
        handleAction={handleSubmit(onSubmit)}
        critical
      />
      <Grid container direction="column" spacing={1}>
        <MuiImportGrid item xs={12}>
          <form onSubmit={handleSubmit(checkModal)}>
            <Controller
              control={control}
              name="address"
              // TODO: Clean up ternary hell here when we remove the isMultipleWalletImportsEnabled feature flag
              rules={{
                required:
                  !!addresses && addresses.length > 0
                    ? false
                    : lang.wallet.importRules.bulkEmpty({
                        name: importOption.name,
                      }),
                validate: {
                  exists: () =>
                    addresses?.some(
                      (address) =>
                        !!address &&
                        existingAddressesLowerCase.includes(
                          address.toLowerCase(),
                        ),
                    )
                      ? lang.wallet.importRules.bulkExisting({
                          name: sourceLabel,
                        })
                      : true,
                  invalid: () =>
                    !!addresses &&
                    addresses.some(
                      (address) =>
                        !!address &&
                        !isValidWalletAddress(
                          address,
                          blockchain,
                          !!importMethod.options?.isBtcAltNetwork,
                        ),
                    )
                      ? lang.wallet.importRules.bulkInvalid({
                          name: sourceLabel,
                        })
                      : !!addresses && addresses.length > MAX_WALLET_IMPORTS
                        ? lang.wallet.importRules.tooManyWallets({
                            maxWalletImports: MAX_WALLET_IMPORTS,
                          })
                        : true,
                },
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <>
                  <Autocomplete
                    clearIcon={false}
                    value={addresses || []}
                    options={[]}
                    freeSolo
                    multiple
                    // TODO: Fix up value being an any type
                    onChange={(event, value: any) => {
                      setAddresses(value);
                    }}
                    renderTags={(value, getTagProps) => {
                      return (
                        <Stack direction="row" flexWrap="wrap" gap="0.25rem">
                          {value.map((option, index) => (
                            <NameServiceAwareAddressChip
                              address={option}
                              blockchain={blockchain}
                              {...getTagProps({ index })}
                              key={option + index}
                              enableDelete
                              addressError={checkChipForErrors(option, index)}
                              updateAddressToNameServiceNameMap={
                                updateAddressToNameServiceNameMap
                              }
                              addressToNameServiceNameMap={
                                addressToNameServiceNameMap
                              }
                            />
                          ))}
                        </Stack>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        onBlur={(event) => {
                          addInputToStateAndClear(event.target.value, params);
                        }}
                        onKeyDown={(event) => {
                          const inputValue = (
                            event.target as HTMLInputElement
                          ).value.trim();
                          if (event.key === " " || event.key === ",") {
                            event.preventDefault();
                            addInputToStateAndClear(inputValue, params);
                            return;
                          }
                          if (event.key) {
                            // Check each suffix to see if we're about to complete it
                            const matchingSuffix = nameServiceNameSuffixes.find(
                              (suffix) => {
                                const incompleteSuffix = suffix.slice(0, -1);
                                return (
                                  inputValue.endsWith(incompleteSuffix) &&
                                  suffix.endsWith(event.key)
                                );
                              },
                            );

                            if (matchingSuffix) {
                              event.preventDefault();
                              addInputToStateAndClear(
                                `${inputValue}${event.key}`,
                                params,
                              );
                            }
                          }
                        }}
                        onPaste={handleAddressesPaste}
                        margin="normal"
                        variant="outlined"
                        type="text"
                        label={lang.wallet.address({
                          source: sourceLabel,
                        })}
                        helperText={error?.message}
                        error={invalid}
                        {...params}
                        sx={{
                          backgroundColor: "transparent",
                          ".MuiOutlinedInput-root": {
                            backgroundColor: tokens.background.input.default,
                          },
                          "& .Mui-error": {
                            marginLeft: 0,
                          },
                        }}
                      />
                    )}
                  />

                  {field.value ? (
                    <WalletWarningWrapper
                      hasInvalidWalletError={
                        !BTC_PUB_ADDRESS_REGEX.test(field.value)
                      } // for btc wallet only
                      blockchain={blockchain}
                    />
                  ) : null}
                </>
              )}
            />
            {!addresses || addresses.length <= 1 ? (
              <ControllerWalletName control={control} />
            ) : (
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                mb="0.75rem"
              >
                {lang.wallet.nicknameCanBeAddedLater}
              </Typography>
            )}
            <ImportTypeEtaBox
              avgMs={
                importMethod.avgMs ??
                blockchainImportOptionWallet?.avgMs ??
                null
              }
              p95Ms={
                importMethod.p95Ms ??
                blockchainImportOptionWallet?.p95Ms ??
                null
              }
            />
            <AdvancedImportOptions
              importOption={importOption}
              importMethod={ImportMethod.Wallet}
            >
              <ImportFromSelector
                control={control}
                handleChange={handleImportFromChange}
                disabled={isEditing}
                importFromTime={importFromTime}
              />

              {showRelatedWalletsOption ? (
                <Box mr="1rem" display="flex" flexDirection="column">
                  <ImportToggleSwitch
                    title={lang.wallet.importRelatedWallets.title}
                    checked={importRelatedWallets}
                    tooltipOn={lang.wallet.importRelatedWallets.tooltipOn}
                    tooltipOff={lang.wallet.importRelatedWallets.tooltipOff}
                    onChange={handleImportRelatedWalletsChange}
                  />
                  <Box mt="0.5rem">
                    <Typography
                      variant="Metropolis/Body/Bold"
                      pt="0.75rem"
                      px="0.75rem"
                      fontWeight={700}
                      color={tokens.text.high}
                    >
                      {lang.wallet.relatedChains.title}
                    </Typography>
                    <Typography
                      variant="Metropolis/Body/Regular"
                      px="0.75rem"
                      pb="0.75rem"
                      pt="0.5rem"
                    >
                      {lang.wallet.relatedChains.body({
                        chains: EvmBlockchains.filter(
                          (c) =>
                            c !== blockchain &&
                            !HighSpamProbabilityEVMs.includes(c),
                        )
                          .map((c) => BlockchainName[c])
                          .sort()
                          .join(", "),
                      })}
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </AdvancedImportOptions>
            <SecureConnectWalletNote />
            <Box
              display="flex"
              justifyContent="flex-start"
              flexGrow={1}
              flexShrink={0}
              alignItems="baseline"
              marginTop="0.75rem"
            >
              {isEditing && (
                <Box mx="1rem">
                  <TertiaryButton onClick={handleCancelEdit}>
                    {lang.cancel}
                  </TertiaryButton>
                </Box>
              )}
              <PrimaryButton
                type="submit"
                variant="contained"
                disabled={!allNameServiceNamesResolved || loading}
              >
                <ButtonContent
                  isEditing={isEditing}
                  existing={existing}
                  sourceLabel={sourceLabel}
                  loading={loading}
                />
              </PrimaryButton>
            </Box>
          </form>
        </MuiImportGrid>
      </Grid>
    </>
  );
}

function ButtonContent({
  isEditing,
  existing,
  sourceLabel,
  loading,
}: {
  isEditing: boolean;
  existing: boolean;
  sourceLabel: string;
  loading?: boolean;
}) {
  const lang = useLang();
  const { tokens } = useDesign();

  return (
    <>
      {isEditing
        ? lang.wallet.editingSubmit
        : existing
          ? lang.wallet.existingSubmit({
              source: sourceLabel,
            })
          : lang.wallet.submit({
              source: sourceLabel,
            })}
    </>
  );
}

function ControllerWalletName({ control }: { control: Control<any> }) {
  const lang = useLang();
  const { tokens } = useDesign();
  return (
    <Controller
      control={control}
      name="name"
      rules={{
        validate: {
          invalid: (value) =>
            value?.match(/[$;"'`]|(\.\.)/g) ? lang.wallet.invalid : true,
        },
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <TextField
          {...field}
          margin="normal"
          variant="outlined"
          type="text"
          error={invalid}
          helperText={error?.message}
          label={lang.wallet.name}
          fullWidth
          sx={{
            backgroundColor: "transparent",
            ".MuiOutlinedInput-input": {
              backgroundColor: tokens.background.input.default,
            },
          }}
        />
      )}
    />
  );
}

export function SecureConnectWalletNote() {
  const lang = useLang();
  const { tokens } = useDesign();
  return (
    <InfoBox>
      <TipsAndUpdatesIcon
        sx={{ fontSize: "1rem", color: tokens.text.default }}
      />
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        color={tokens.text.low}
      >
        {lang.wallet.safetyInfo.frontPart}
        <span style={{ color: tokens.text.high }}>
          {lang.wallet.safetyInfo.highlightPart}
        </span>
        {lang.wallet.safetyInfo.lastPart}
      </Typography>
    </InfoBox>
  );
}

const InfoBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  align-self: stretch;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.high};
  background-color: ${({ theme }) => theme.tokens.elevation.highest};
  border-radius: 0.25rem;
  margin-top: 1rem;
`;
