import { OpenInNew } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";

export const ExternalAddressLink = ({
  link,
  color,
  tooltip = undefined,
  width,
  noPadding,
}: {
  link: string;
  color?: string;
  tooltip?: string;
  width?: number;
  noPadding?: boolean;
}) => {
  const { tokens } = useDesign();
  const externalLink = (
    <span>
      <StyledIconButton
        href={link}
        target="_blank" // todo: Fix when we move to new tab logic component
        rel="noopener noreferrer"
        size="small"
        style={{ padding: noPadding ? "0" : undefined }}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
        }}
      >
        <OpenInNew
          fontSize="inherit"
          style={{
            color: color ?? tokens.text.default,
            width,
          }}
        />
      </StyledIconButton>
    </span>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{externalLink}</Tooltip>;
  }
  return externalLink;
};

const StyledIconButton = styled((props: any) => (
  <TextIconButton component="a" {...props} />
))``;
