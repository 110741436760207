export function lazy<T>(fn: () => T) {
  let value: T;
  let isResolved = false;
  return {
    get value(): T {
      if (isResolved) return value;
      value = fn();
      isResolved = true;
      return value;
    },
  };
}
