import { SwapHoriz } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import {
  Box,
  Slide,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "styled-components/macro";

import { bulkEditAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { bulkEditHotkeys } from "~/components/transactions/command-palette/CommandPaletteModal";
import { useBulkFilterAllEnabled } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { useBulkEditCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import { useNumSelectedOrFiltered } from "~/components/transactions/command-palette/hooks/useNumSelectedOrFiltered";
import { ToCategory } from "~/components/transactions/command-palette/views/recategorise/ToCategory";
import { Root } from "~/components/transactions/command-palette/views/Root";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import { CheckboxActionType } from "~/components/transactions/filter-bar/enums";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useDesign } from "~/hooks/useTheme";
import { getIsMacOs } from "~/lib/index";
import { useLang } from "~/redux/lang";

export function CommandPaletteBar({
  selectAllDisabled = false,
  commandKDisabled = false,
  categoriseShortcutDisabled = false,
}: {
  selectAllDisabled?: boolean;
  commandKDisabled?: boolean;
  categoriseShortcutDisabled?: boolean;
}) {
  const { tokens } = useDesign();
  const { state, dispatch } = useTransactionCheckbox();
  const lang = useLang();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isBulkFilterAllEnabled =
    useBulkFilterAllEnabled() && !selectAllDisabled;
  const { numSelected = 0, isManualSelection } = useNumSelectedOrFiltered();
  const { open, close } = useBulkEditCommandPalette();
  const captureAnalytics = useCaptureAnalytics();
  const onClose = () => {
    close();
  };
  const closeButton = (
    <Tooltip
      placement="top"
      title={
        <Stack direction="row" gap="0.5rem">
          <Typography variant="Metropolis/Caption/Medium/Regular">
            {lang.txTable.bulkEditBar.close}
          </Typography>
          <Stack direction="row" gap="0.25rem">
            <KeyboardKeyBox sx={{ width: "1.75rem !important" }}>
              {bulkEditHotkeys.escape.label}
            </KeyboardKeyBox>
          </Stack>
        </Stack>
      }
    >
      <TextIconButton
        size="small"
        onClick={onClose}
        sx={{ alignSelf: "center" }}
      >
        <ClearIcon fontSize="small" />
      </TextIconButton>
    </Tooltip>
  );

  return (
    <Slide direction="up" in={Boolean(numSelected)} mountOnEnter unmountOnExit>
      <Box
        position="fixed"
        left={0}
        bottom={isMobile ? "0" : "2.5rem"}
        display="flex"
        alignItems="center"
        width={isMobile ? "100vw" : "100%"}
        justifyContent="center"
        style={{
          zIndex: 1000,
          pointerEvents: "none",
        }}
      >
        <Box
          py="0.75rem"
          borderRadius="0.25rem"
          display="flex"
          alignItems="center"
          justifyContent={isMobile ? "space-between" : "center"}
          px="1rem"
          width={isMobile ? "100%" : "auto"}
          style={{
            pointerEvents: "auto",
            backgroundColor: tokens.elevation.high,
            border: `1px solid ${tokens.border.neutral.high}`,
            boxShadow:
              "0px 7px 14px 0px rgba(0, 0, 0, 0.12), 0px 3px 6px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Stack
            alignItems={isMobile ? "flex-start" : "center"}
            gap={isMobile ? "0.5rem" : "1rem"}
            direction={isMobile ? "column" : "row"}
            width="100%"
          >
            <Stack
              direction="row"
              alignItems="center"
              gap="0.5rem"
              justifyContent="space-between"
              width="100%"
            >
              <Typography
                variant="Metropolis/Body/Regular"
                display="inline"
                sx={{ fontSize: "0.75rem" }}
                color={tokens.text.low}
              >
                {state.selectAll
                  ? lang.txTable.bulkEditBar.numSelectedAll({ numSelected })
                  : isManualSelection
                    ? lang.txTable.bulkEditBar.numSelected({ numSelected })
                    : lang.txTable.bulkEditBar.txCount({ numSelected })}
              </Typography>
              {isBulkFilterAllEnabled ? (
                <TextButton
                  sx={{ fontSize: "0.75rem" }}
                  onClick={() => {
                    if (state.selectAll) {
                      dispatch({ type: CheckboxActionType.ResetSelectAll });
                      captureAnalytics(bulkEditAnalyticsKey("unselect all"));
                    } else {
                      dispatch({ type: CheckboxActionType.SetSelectAll });
                      captureAnalytics(bulkEditAnalyticsKey("select all"));
                    }
                  }}
                >
                  {state.selectAll
                    ? lang.txTable.bulkEditBar.clearSelection
                    : lang.txTable.bulkEditBar.selectAll}
                </TextButton>
              ) : null}
              {isMobile ? closeButton : null}
            </Stack>
            <Stack direction="row" gap="0.5rem">
              {!categoriseShortcutDisabled ? (
                <Tooltip
                  placement="top"
                  title={
                    <Stack direction="row" gap="0.5rem">
                      <Typography variant="Metropolis/Caption/Medium/Regular">
                        {lang.txTable.commandPallet.bulkEditOptions.categorize}
                      </Typography>
                      <KeyboardKeyBox>
                        <Typography variant="Metropolis/Caption/Medium/Regular">
                          {bulkEditHotkeys.C.label}
                        </Typography>
                      </KeyboardKeyBox>
                    </Stack>
                  }
                >
                  <TertiaryButton
                    sx={{ fontSize: "0.75rem" }}
                    startIcon={<SwapHoriz sx={{ mt: "-0.125rem" }} />}
                    onClick={(e) => {
                      open(<ToCategory />, e);
                      captureAnalytics(
                        bulkEditAnalyticsKey("quick categorize open"),
                      );
                    }}
                  >
                    {lang.txTable.commandPallet.bulkEditOptions.categorize}
                  </TertiaryButton>
                </Tooltip>
              ) : null}
              {!commandKDisabled ? (
                <Tooltip
                  placement="top"
                  title={
                    <Stack direction="row" gap="0.5rem">
                      <Typography variant="Metropolis/Caption/Medium/Regular">
                        {lang.txTable.bulkEditBar.open}
                      </Typography>
                      <Stack direction="row" gap="0.25rem">
                        {getIsMacOs() ? (
                          <KeyboardKeyBox>
                            <KeyboardCommandKeyIcon
                              sx={{ height: "0.75rem", width: "0.75rem" }}
                            />
                          </KeyboardKeyBox>
                        ) : (
                          <KeyboardKeyBox sx={{ width: "1.75rem !important" }}>
                            Ctrl
                          </KeyboardKeyBox>
                        )}
                        <KeyboardKeyBox>
                          <Typography variant="Metropolis/Caption/Medium/Regular">
                            {bulkEditHotkeys.CommandK.label}
                          </Typography>
                        </KeyboardKeyBox>
                      </Stack>
                    </Stack>
                  }
                >
                  <TertiaryButton
                    sx={{
                      fontSize: "0.75rem",
                      whiteSpace: "nowrap",
                      color: `${tokens.text.brand} !important`,
                    }}
                    startIcon={
                      <KeyboardCommandKeyIcon
                        sx={{ height: "1rem", width: "1rem", mt: "-0.125rem" }}
                      />
                    }
                    onClick={(e) => {
                      open(<Root />, e);
                    }}
                  >
                    {lang.txTable.bulkEditBar.command}
                  </TertiaryButton>
                </Tooltip>
              ) : null}
            </Stack>

            {isMobile ? null : closeButton}
          </Stack>
        </Box>
      </Box>
    </Slide>
  );
}

const KeyboardKeyBox = styled(Box)`
  background-color: ${({ theme }) => theme.tokens.elevation.low};
  padding: 0.1rem;
  border-radius: 0.25rem;
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
