import { Trade } from "@ctc/types";

import { useSelectedIds } from "~/hooks/useTransactions";
import { useGetActionsQuery } from "~/state/actions";
import { type CurrencyIdentifier } from "~/types/index";

/**
 * Helper function to extract unique currencies from transactions
 */
function getUniqueCurrencies(
  transactions: { currencyIdentifier: CurrencyIdentifier }[],
) {
  const uniqueCurrenciesMap = new Map<string, CurrencyIdentifier>();
  transactions.forEach((transaction) => {
    uniqueCurrenciesMap.set(
      transaction.currencyIdentifier.id,
      transaction.currencyIdentifier,
    );
  });
  return Array.from(uniqueCurrenciesMap.values());
}

/**
 * Gets the unique set of currencies the user has selected
 * @param includeFees Include the fee currencies
 * @param onlyFees Only return the fee currencies
 * @returns The set of currencies
 */
export function useSelectedCurrencies({
  includeFees,
  onlyFees,
}: {
  includeFees: boolean;
  onlyFees?: boolean;
}) {
  const selectedIds = useSelectedIds();
  const query = useGetActionsQuery();
  const relevantTransactions =
    query.data?.transactions.flatMap((action) => {
      if (selectedIds.length && !selectedIds.includes(action._id)) {
        return [];
      }

      if (onlyFees) {
        const feeTxs = action.outgoing.filter(
          (tx) => tx.trade === Trade.Fee,
        );
        return [...action.fees, ...feeTxs];
      }

      const transactions = [...action.outgoing, ...action.incoming];
      if (includeFees) {
        transactions.push(...action.fees);
      }

      return transactions;
    }) ?? [];

  return getUniqueCurrencies(relevantTransactions);
}
