import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import { useUser } from "~/redux/auth";
import { useGetActionsCountQuery, useGetActionsQuery } from "~/state/actions";

export const useTransactionsTotalCount = () => {
  const user = useUser();
  const count = useGetActionsCountQuery({
    // Only enable this query if the user is logged in, otherwise will repeatedly request on login page
    enabled: Boolean(user),
  });

  return count.data?.txGroupCount ?? 0;
};

// Billed count use real count - free count - spam/ignored count
export const useTransactionsBilledCount = () => {
  const newCount = useGetActionsCountQuery();

  return newCount.data?.txBilledCount ?? 0;
};

// This is the count of all transactions that include free tx, but exclude spam/ignored
export const useTransactionsRealCount = () => {
  const newCount = useGetActionsCountQuery();

  return newCount.data?.txRealCount ?? 0;
};

// This is the count of free tx that the user have based on the promotion we are running
// e.g coinbase user can have 10k free coinbase tx
export const useTransactionFreeCount = () => {
  const newCount = useGetActionsCountQuery();

  return newCount.data?.txFreeCount ?? 0;
};

export const useTransactionsTotalFilteredCount = () => {
  const newCount = useGetActionsQuery();

  return newCount.data?.total ?? 0;
};

export const useSelectedIds = () => {
  const { state } = useTransactionCheckbox();
  const contextIds = state.selectedIds;
  return contextIds;
};

export const useSelectedAll = () => {
  const { state } = useTransactionCheckbox();
  return state.selectAll;
};

export const useIsSelectedById = (id: string) => {
  const selectedIds = useSelectedIds();
  return selectedIds.includes(id);
};
