import { useEffect, useState } from "react";

/**
 * Hook to detect if the current browser is Safari with version 18.4 or greater
 * @returns {boolean} True if the browser is Safari 18.4+, false otherwise
 */
export function useIsSafari184OrGreater() {
  const [isSafari184OrGreater, setIsSafari184OrGreater] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    // Check for Safari (contains 'safari' but not 'chrome', 'chromium', etc.)
    const isSafari = /safari/i.test(userAgent) && 
                    !/chrome|chromium|crios|edg|opera/i.test(userAgent);
    
    if (isSafari) {
      // Extract Safari version
      const versionMatch = userAgent.match(/version\/(\d+\.\d+)/i);
      if (versionMatch && versionMatch[1]) {
        const safariVersion = parseFloat(versionMatch[1]);
        setIsSafari184OrGreater(safariVersion >= 18.4);
      }
    }
  }, []);

  return isSafari184OrGreater;
} 