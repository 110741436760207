import { Checkbox as MuiCheckbox, type CheckboxProps } from "@mui/material";
import styled from "styled-components/macro";

export const Checkbox = styled(({ color, ...props }: CheckboxProps) => (
  <MuiCheckbox color={color || "default"} {...props} />
))`
  color: ${({ theme }) => theme.tokens.text.low};

  ${({ theme, color }) =>
    color !== "primary" &&
    `
    &:hover {
      color: ${theme.tokens.text.high};
    }
    &.Mui-checked {
      color: ${theme.tokens.text.high};
    }
  `}
`;
