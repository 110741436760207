import { KeyboardArrowDown } from "@mui/icons-material";
import { Popover, Tooltip, Typography } from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import styled from "styled-components/macro";

import { Severity } from "~/components/dashboard/enums";
import {
  type BalanceWarning,
  HoldingsBreakdownDropdown,
  type HoldingsBreakdownRowProps,
} from "~/components/dashboard/HoldingsBreakdownDropdown";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";

import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { DEFAULT_IGNORE_BALANCE_THRESHOLD } from "~/constants/constants";
import { getTitleByExchangeId, getWallet } from "~/lib/holdings";
import {
  displayQuantity,
  geIdAndBlockchain,
  middleTrim,
  minus,
  multiply,
} from "~/lib/index";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { useEntityLookupAsync } from "~/state/entities";
import { useLoadExchangeNames } from "~/state/exchanges";
import { useGetSavedAccounts } from "~/state/importsV2";
import { BalanceError, BalanceType, FilterOperators } from "~/types/enums";
import {
  type CurrencyIdentifier,
  type ExchangeNames,
  type FilterQuery,
  type PricedCurrencyHoldings,
  type PricedHoldingsDetails,
  type SavedWalletImport,
  type TaxSettings,
} from "~/types/index";

const StyledButton = styled(({ active, ...props }) => (
  <TertiaryButton {...props} />
))`
  && {
    justify-content: space-between;
    ${({ active, theme }) =>
      active
        ? `background-color: ${theme.tokens.background.neutral.lowest.pressed};`
        : null}
  }
`;

type ExchangeBreakdownProps = {
  balance: number;
  breakdown?: PricedCurrencyHoldings["holdings"];
  currencyIdentifier: CurrencyIdentifier;
  marketPrice: number | null;
  isReported?: boolean;
};

export function generateWarnings(
  item: PricedHoldingsDetails,
  ignoreThreshold: number,
  holdingsBalanceType: TaxSettings["holdingsBalanceType"],
): BalanceWarning | undefined {
  if (item.balanceError === BalanceError.Inconsistent) {
    const cryptoDiff = minus(item.calculated, item.reported || 0);
    if (item.marketPrice) {
      const valueDifference = minus(
        multiply(item.calculated, item.marketPrice),
        multiply(item.reported || 0, item.marketPrice),
      );
      if (Math.abs(valueDifference) > ignoreThreshold) {
        return {
          severity: Severity.Medium,
          cryptoDiff,
          fiatDiff: valueDifference,
          type: BalanceError.Inconsistent,
        };
      }
      return {
        severity: Severity.Low,
        fiatDiff: valueDifference,
        cryptoDiff,
        type: BalanceError.Inconsistent,
      };
    }
    return {
      severity: Severity.Medium,
      cryptoDiff,
      type: BalanceError.Inconsistent,
    };
  } else if (item.balanceError === BalanceError.Negative) {
    return {
      severity: Severity.High,
      cryptoDiff:
        holdingsBalanceType === BalanceType.Calculated
          ? item.calculated
          : item.reported || 0,
      type: BalanceError.Negative,
    };
  }
}

function generateRows(
  currencyIdentifier: CurrencyIdentifier,
  exchangeNames: ExchangeNames,
  marketPrice: number | null,
  wallets: SavedWalletImport[],
  ignoreThreshold: number,
  holdingsBalanceType: TaxSettings["holdingsBalanceType"],
  breakdown?: PricedCurrencyHoldings["holdings"],
  getEntityForAddress?: ReturnType<
    typeof useEntityLookupAsync
  >["getEntityForAddress"],
  getEntityById?: ReturnType<typeof useEntityLookupAsync>["getEntityById"],
): HoldingsBreakdownRowProps[] {
  if (!breakdown) {
    return [];
  }

  return breakdown.map((item) => {
    const [exchangeId, exchangeBlockchain] = geIdAndBlockchain(item.exchangeId);
    const wallet = getWallet(wallets, exchangeId, exchangeBlockchain);
    const entity =
      getEntityForAddress?.(exchangeId) || getEntityById?.(item.exchangeId);
    const exchangeIdIsEntityId = !!getEntityById?.(item.exchangeId);
    // if there is an entity, then the exchangeId must be an address if it is not an entity id
    const name = getTitleByExchangeId(
      item.exchangeId,
      exchangeNames,
      wallet,
      entity,
    );

    const subName =
      entity && !exchangeIdIsEntityId
        ? middleTrim(exchangeId, undefined, 4)
        : wallet && name !== middleTrim(wallet.address, undefined, 4)
          ? middleTrim(wallet.address)
          : undefined;

    const currencyQuery: FilterQuery =
      currencyIdentifier.nftId && currencyIdentifier.contractAddress
        ? {
            type: FilterOperators.NFTCollection,
            value: [currencyIdentifier.contractAddress],
          }
        : {
            type: FilterOperators.Currency,
            value: [currencyIdentifier.id],
          };
    const link = getTransactionPageLink({
      state: {
        filter: {
          type: FilterOperators.And,
          rules: [
            currencyQuery,
            {
              type: FilterOperators.Source,
              value: [exchangeId],
            },
          ],
        },
      },
    });
    const row: HoldingsBreakdownRowProps = {
      id: exchangeId,
      name,
      image: (
        <ExchangeLogo
          name={exchangeId}
          walletAddress={wallet?.address}
          blockchain={exchangeBlockchain || wallet?.blockchain}
          currencySymbol={currencyIdentifier.symbol}
          showBlockchainSymbol={!!(exchangeBlockchain || wallet?.blockchain)}
          entity={entity}
        />
      ),
      subName,
      price: marketPrice || item.marketPrice || undefined,
      value: item.totalValue,
      balance: item.balance,
      balanceType: item.balanceType,
      warning: generateWarnings(item, ignoreThreshold, holdingsBalanceType),
      link,
      source: wallet ? wallet.blockchain : exchangeId,
      walletDetails: wallet
        ? {
            blockchain: wallet?.blockchain,
          }
        : undefined,
      currencyIdentifier,
    };

    return row;
  });
}

export function ExchangeBreakdown({
  balance,
  breakdown,
  currencyIdentifier,
  marketPrice,
}: ExchangeBreakdownProps) {
  const lang = useLang();
  const taxSettings = useTaxSettings();
  const ignoreThreshold =
    taxSettings?.ignoreBalanceRemainingValueThreshold ||
    DEFAULT_IGNORE_BALANCE_THRESHOLD;
  const holdingsBalanceType =
    taxSettings?.holdingsBalanceType || BalanceType.Reported;
  const languagePreference = useLanguagePreference();
  const wallets = useGetSavedAccounts().flatMap((s) => s.wallets);
  const exchangeNames = useLoadExchangeNames()?.data ?? {};
  const popupState = usePopupState({
    variant: "popover",
    popupId: `exchange-breakdown-${currencyIdentifier.id}`,
  });

  const { getEntityForAddress, getEntityById } = useEntityLookupAsync();

  const rows = generateRows(
    currencyIdentifier,
    exchangeNames,
    marketPrice,
    wallets,
    ignoreThreshold,
    holdingsBalanceType,
    breakdown,
    getEntityForAddress,
    getEntityById,
  );

  const enableSort = !!rows.find((r) => !!r.price && !!r.value);

  return (
    <>
      <Tooltip
        title={
          balance < 0
            ? lang.dashboard.negativeBalanceWarning({
                currencySymbol: currencyIdentifier.symbol,
              })
            : ""
        }
      >
        {breakdown && breakdown.length > 0 ? (
          <StyledButton
            fullWidth
            {...bindTrigger(popupState)}
            endIcon={<KeyboardArrowDown fontSize="small" />}
            active={popupState.isOpen}
          >
            <Typography
              variant="IBM Plex Mono/Caption/Medium/Regular"
            >
              {displayQuantity(balance, languagePreference)}
            </Typography>
          </StyledButton>
        ) : (
          <Typography
            variant="IBM Plex Mono/Caption/Medium/Regular"
          >
            {displayQuantity(balance, languagePreference)}
          </Typography>
        )}
      </Tooltip>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "left",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {!!breakdown?.length && (
          <HoldingsBreakdownDropdown
            firstColumnTitle={lang.holdingsDropdown.wallets}
            rows={rows}
            enableSort={enableSort}
          />
        )}
      </Popover>
    </>
  );
}
