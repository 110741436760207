import isEmpty from "lodash/isEmpty";
import LZString from "lz-string";
import { stringify } from "query-string";
import * as React from "react";
import {
  BooleanParam,
  decodeJson,
  encodeJson,
  encodeQueryParams,
  NumberParam,
  type QueryParamConfig,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { CheckboxProvider } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  ContextIdType,
  FilterActionType,
  type FilterContextKey,
} from "~/components/transactions/filter-bar/enums";
import { TRANSACTIONS_ROWS_PER_PAGE } from "~/constants/constants";
import { LocalStorageKey } from "~/constants/enums";
import { LocalStorageUserKeyGenerator } from "~/hooks/useLocalStorage";
import { useBestUser } from "~/redux/auth";
import { clearFilterSession } from "~/state/filterSession";
import {
  FilterOperators,
  Links,
  LockPeriodTxStatus,
  Sort,
} from "~/types/enums";
import { type FilterQuery } from "~/types/index";

/**
 * Converts the object to a json, then compresses, then base64s
 */
const Base64JsonParam: any = {
  encode: (myObject: any | null | undefined): string | null | undefined => {
    const json = encodeJson(myObject);
    if (!json) {
      return json;
    }
    return LZString.compressToEncodedURIComponent(json);
  },

  decode: (encodedObject: string | null | undefined): any | undefined => {
    if (!encodedObject) {
      return encodedObject;
    }
    return decodeJson(
      LZString.decompressFromEncodedURIComponent(encodedObject),
    );
  },
};

type Action =
  | {
      type: FilterActionType.SetReconciliationViewOneByOneFilter;
      transactionId: TransactionId;
    }
  | { type: FilterActionType.SetFilter; filter: FilterQuery }
  | { type: FilterActionType.ResetFilter }
  | { type: FilterActionType.GoToPage; page: number }
  | { type: FilterActionType.SetCount; count: number }
  | { type: FilterActionType.SetSort; sort: Sort }
  | { type: FilterActionType.SetHighLight; highlight: ActionId }
  | { type: FilterActionType.SetLedger; ledger: boolean }
  | { type: FilterActionType.SetHideLocked; hideLocked: boolean };

type Dispatch = (action: Action) => void;

type ActionId = string;
type TransactionId = string;

type State = {
  filter: FilterQuery | undefined;
  count: number;
  viewInContextId?: string; // takes precendence over page
  contextIdType?: ContextIdType;
  page: number;
  sort: Sort;
  highlight?: ActionId;
  ledger?: boolean;
  rowsPerPageOptions: number[];
  hideLocked?: boolean;
};

type FilterProviderProps = {
  children: React.ReactNode;
  initialState?: Partial<State>;
  persist?: FilterContextKey;
  enableFilterSessions?: boolean;
};

// how all the state should be serialised to the url
const queryParamConfigMap: { [key in keyof Required<State>]: any } = {
  filter: Base64JsonParam,
  count: NumberParam,
  page: NumberParam,
  sort: StringParam,
  highlight: StringParam,
  viewInContextId: StringParam,
  contextIdType: StringParam,
  ledger: BooleanParam,
  rowsPerPageOptions: Base64JsonParam,
  hideLocked: BooleanParam,
};

export function getTransactionPageLink(config: { state: Partial<State> }) {
  const { state } = config;
  const encoded = encodeQueryParams(queryParamConfigMap, state);
  return `${Links.Transactions}?${stringify(encoded)}`;
}

export function getViewInContextPageLink(
  id: string,

  type: ContextIdType,
  sort?: Sort,
) {
  // View in context default to sorting either direciton by date only
  const applicableSort =
    sort && sort === Sort.DateAscending ? sort : Sort.DateDescending;

  return getTransactionPageLink({
    state: {
      viewInContextId: id,
      highlight: id,
      sort: applicableSort,
      contextIdType: type,
    },
  });
}

const FilterStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function filterReducer(state: State, action: Action): State {
  switch (action.type) {
    case FilterActionType.SetReconciliationViewOneByOneFilter: {
      return {
        ...state,
        filter: undefined,
        viewInContextId: action.transactionId,
        contextIdType: ContextIdType.TxId,
        highlight: action.transactionId,
        sort: Sort.DateAscending,
        count: 10,
      };
    }
    case FilterActionType.SetFilter: {
      return { ...state, filter: action.filter, page: 1 };
    }
    case FilterActionType.ResetFilter: {
      return { ...state, filter: undefined, page: 1 };
    }
    case FilterActionType.GoToPage: {
      return {
        ...state,
        page: action.page,
        viewInContextId: undefined,
        contextIdType: undefined,
      };
    }
    case FilterActionType.SetSort: {
      return {
        ...state,
        sort: action.sort,
        page: 1,
      };
    }
    case FilterActionType.SetCount: {
      return {
        ...state,
        count: action.count,
        page: 1,
      };
    }
    case FilterActionType.SetHighLight: {
      return {
        ...state,
        highlight: action.highlight,
      };
    }
    case FilterActionType.SetLedger: {
      return {
        ...state,
        ledger: action.ledger,
      };
    }
    case FilterActionType.SetHideLocked: {
      return {
        ...state,
        hideLocked: action.hideLocked,
      };
    }
  }
}

/**
 * Gets the default value from local storage
 * @param param The QueryParam config type
 * @param persistInLocalStorage Should we use local storage
 * @param localStorageKey The key in local storage
 * @param localStorageParser Convert string to Query Param type
 * @param defaultValue If we aren't using local storage the default value
 * @returns
 */
export function withDefaultFromLocalStorage<
  TQueryParamConfig,
  TDefaultValue extends TQueryParamConfig,
  TLocalStorageType extends TQueryParamConfig,
>(
  param: QueryParamConfig<TQueryParamConfig>,
  persistInLocalStorage: boolean,
  localStorageKey: ReturnType<typeof LocalStorageUserKeyGenerator>,
  localStorageParser: (storageValue: string) => TLocalStorageType,
  defaultValue: TDefaultValue,
) {
  const storage =
    persistInLocalStorage && localStorage.getItem(localStorageKey);
  const value = storage ? localStorageParser(storage) : undefined;
  return withDefault(param, value ?? defaultValue);
}

function useQueryParamsReducer(
  reducer: (state: State, action: Action) => State,
  initState: State,
  persistInLocalStorage: FilterContextKey | undefined,
  enableFilterSessions: boolean,
) {
  const user = useBestUser();
  const captureAnalytics = useCaptureAnalytics();
  const analyticsKey = transactionAnalyticsKey("filters");
  // how all the state should be serialised to the url
  // we take the defaults to be what the users has passed in initState
  const queryParamConfigMap: { [key in keyof Required<State>]: any } =
    React.useMemo(() => {
      if (enableFilterSessions) {
        // starts the filter
        clearFilterSession();
      }
      return {
        // stored in local storage
        count: withDefaultFromLocalStorage(
          NumberParam,
          !!persistInLocalStorage,
          LocalStorageUserKeyGenerator(
            user?.uid,
            LocalStorageKey.RowsPerPage,
            persistInLocalStorage,
          ),
          Number,
          initState.count,
        ),
        sort: withDefaultFromLocalStorage(
          StringParam,
          !!persistInLocalStorage,
          LocalStorageUserKeyGenerator(
            user?.uid,
            LocalStorageKey.SortOrder,
            persistInLocalStorage,
          ),
          String,
          initState.sort,
        ),

        // the rest
        filter: withDefault(Base64JsonParam, initState.filter),
        page: withDefault(NumberParam, initState.page),
        highlight: withDefault(StringParam, initState.highlight),
        viewInContextId: withDefault(StringParam, initState.viewInContextId),
        contextIdType: withDefault(StringParam, initState.contextIdType),
        ledger: withDefaultFromLocalStorage(
          BooleanParam,
          !!persistInLocalStorage,
          LocalStorageUserKeyGenerator(
            user?.uid,
            LocalStorageKey.Ledger,
            persistInLocalStorage,
          ),
          (storage) => storage === "true",
          initState.ledger,
        ),
        rowsPerPageOptions: withDefault(
          Base64JsonParam,
          initState.rowsPerPageOptions,
        ),
        hideLocked: withDefaultFromLocalStorage(
          BooleanParam,
          !!persistInLocalStorage,
          LocalStorageUserKeyGenerator(
            user?.uid,
            LocalStorageKey.HideLockedPeriod,
            persistInLocalStorage,
          ),
          (storage) => storage === "true",
          initState.hideLocked,
        ),
      };
      // we really only want to do this when the component mounts
      // we dont care if init filters is updated after that
    }, []);

  // based on if we are using the url/local storage or not
  // we decide which method to use
  // query params (transactions table)
  // vs local state (thats what reconciliation uses)
  const [queryUrl, setQueryUrl] = useQueryParams(queryParamConfigMap);
  const [queryState, setQueryState] = React.useState(initState);
  let query = persistInLocalStorage ? queryUrl : queryState;
  query = addFilterPreferencesFromLocalStorage(user, query);
  const setQuery = persistInLocalStorage ? setQueryUrl : setQueryState;

  const dispatch = React.useCallback(
    (action: Action) => {
      if (
        enableFilterSessions &&
        (action.type === FilterActionType.ResetFilter ||
          action.type === FilterActionType.SetFilter)
      ) {
        clearFilterSession();
      }
      if (action.type === FilterActionType.SetFilter) {
        captureAnalytics(analyticsKey("filter set"));
      }
      const nextState = reducer(query, action);

      if (persistInLocalStorage && nextState.count !== query.count) {
        localStorage.setItem(
          LocalStorageUserKeyGenerator(
            user?.uid,
            LocalStorageKey.RowsPerPage,
            persistInLocalStorage,
          ),
          `${nextState.count}`,
        );
      }
      if (persistInLocalStorage && nextState.sort !== query.sort) {
        localStorage.setItem(
          LocalStorageUserKeyGenerator(
            user?.uid,
            LocalStorageKey.SortOrder,
            persistInLocalStorage,
          ),
          `${nextState.sort}`,
        );
      }
      if (persistInLocalStorage && nextState.ledger !== query.ledger) {
        localStorage.setItem(
          LocalStorageUserKeyGenerator(
            user?.uid,
            LocalStorageKey.Ledger,
            persistInLocalStorage,
          ),
          String(nextState.ledger),
        );
      }
      if (persistInLocalStorage && nextState.hideLocked !== query.hideLocked) {
        localStorage.setItem(
          LocalStorageUserKeyGenerator(
            user?.uid,
            LocalStorageKey.HideLockedPeriod,
            persistInLocalStorage,
          ),
          String(nextState.hideLocked),
        );
      }

      setQuery(nextState);
    },
    [
      enableFilterSessions,
      reducer,
      query,
      persistInLocalStorage,
      setQuery,
      captureAnalytics,
      analyticsKey,
      user?.uid,
    ],
  );
  return [query, dispatch] as const;
}

function addFilterPreferencesFromLocalStorage(
  user: ReturnType<typeof useBestUser>,
  query: State,
): State {
  // the only thing we support adding to the filter from local storage preference
  // is the lock period toggle
  // more can be added later
  const hideLockedPeriodLocalStorageKey = LocalStorageUserKeyGenerator(
    user?.uid,
    LocalStorageKey.HideLockedPeriod,
  );
  const shouldHideLockedPeriodLocalStorage = localStorage.getItem(
    hideLockedPeriodLocalStorageKey,
  );
  if (!shouldHideLockedPeriodLocalStorage) {
    return query;
  }
  if (!query.filter) {
    return {
      ...query,
      filter: {
        type: FilterOperators.And,
        rules: [
          {
            type: FilterOperators.LockPeriod,
            value: LockPeriodTxStatus.NotLocked,
          },
        ],
      },
    };
  }
  if (query.filter.type !== FilterOperators.And) {
    // we dont support adding it into OR queries currently
    // future work
    return query;
  }
  // remove it if it already exists
  const existingRules = query.filter.rules.filter(
    (rule) => rule.type !== FilterOperators.LockPeriod,
  );
  // add the lock period filter
  return {
    ...query,
    filter: {
      ...query.filter,
      rules: [
        ...existingRules,
        {
          type: FilterOperators.LockPeriod,
          value: LockPeriodTxStatus.NotLocked,
        },
      ],
    },
  };
}

/** A wrapper for both the filter provider and checkbox provider, as you will
 *  never have one without the other */
export function FilterProvider({
  initialState,
  children,
  persist,
  enableFilterSessions,
}: FilterProviderProps) {
  return (
    <FilterStorageProvider
      initialState={initialState}
      persist={persist}
      enableFilterSessions={enableFilterSessions}
    >
      <CheckboxProvider>{children}</CheckboxProvider>
    </FilterStorageProvider>
  );
}

function FilterStorageProvider({
  initialState,
  children,
  persist,
  enableFilterSessions = true,
}: FilterProviderProps) {
  const [state, dispatch] = useQueryParamsReducer(
    filterReducer,
    {
      filter: undefined,
      count: TRANSACTIONS_ROWS_PER_PAGE,
      page: 1,
      sort: Sort.DateDescending,
      rowsPerPageOptions: [25, 50, 100, 250],
      ...initialState,
    },
    persist,
    Boolean(enableFilterSessions),
  );

  const value = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <FilterStateContext.Provider value={value}>
      {children}
    </FilterStateContext.Provider>
  );
}

export function useTransactionFilter() {
  const context = React.useContext(FilterStateContext);
  if (context === undefined) {
    throw new Error(
      "useTransactionFilter must be used within a FilterProvider",
    );
  }
  return context;
}

export function isFilterEmpty(filter: FilterQuery | undefined) {
  if (!filter) return true;

  if (filter.type === FilterOperators.And && isEmpty(filter.rules)) {
    return true;
  }

  if (filter.type === FilterOperators.Or && isEmpty(filter.rules)) {
    return true;
  }

  return false;
}
