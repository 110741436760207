import { Country } from "@ctc/types";
import moment from "moment-timezone";

// NOTE: After tax settings load, the default `moment` timezone is set to the
// profile timezone. When using `moment()`, it should automatically use the
// profile/default timezone, make sure you import with "moment-timezone".

/**
 * Other then USA, all other countries use DD/MM/YYYY format.
 * @returns
 */
export const getUserCountryDateFormat = (country?: Country) => {
  return country === Country.USA ? "MM/DD/YYYY" : "DD/MM/YYYY";
};

// Date input (eg: `moment(value)`) will convert from browser time to profile
// time first, this transforms the given input to the profile timezone without
// that conversion.
export const transformInputToTz = ({
  value,
  timezone,
  format,
}: {
  value: string;
  timezone?: string | null;
  format: string;
}) => {
  const dates = [
    // try the users format first
    moment
      // If the value is not the same length as the format, it's probably not
      // valid, so dont even try, as trying was causing weird bugs where
      // sometimes invalid dates were being returned as valid. The +5 is
      // arbitrary wiggle room for variable length date formats such as
      // D MMM Y etc
      .utc(value.length > format.length + 5 ? null : value, format)
      .tz(timezone || moment.tz.guess(), true),
    // otherwise try whatever
    moment.utc(value).tz(timezone || moment.tz.guess(), true),
    // take the first one that works
  ];
  const validDates = dates.filter((m) => m.isValid());
  if (!validDates.length) {
    // neither of the dates are valid, just return the first one
    return dates[0];
  }
  return validDates[0];
};

// Transforms timezone to browser/local timezone.
// eg: 12am EST to 12am AEST.
export const transformDateTzToBrowser = (date: Date) => {
  return moment(date).tz(moment.tz.guess(), true).toDate();
};

// Transforms date timezone to profile timezone.
// eg: 12am EST to 12am AEST.
export const transformDateTzToProfile = (date: Date) => {
  return moment()
    .year(date.getFullYear())
    .month(date.getMonth())
    .date(date.getDate())
    .hours(date.getHours())
    .minutes(date.getMinutes())
    .seconds(date.getSeconds())
    .milliseconds(date.getMilliseconds())
    .toDate();
};
