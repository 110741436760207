import { CaptchaProvider } from "@ctc/types";
import { ArrowForward } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQueryParam } from "use-query-params";

import { getProviderConfig } from "~/components/onboarding-v2/provider/getProviderConfig";
import { type Provider } from "~/components/onboarding-v2/provider/types";
import { OnboardingPageContainer } from "~/components/onboarding-v2/ui/OnboardingPageContainer";
import { PasswordTextField } from "~/components/ui/PasswordTextField";
import { ProviderOnboardingBox } from "~/components/ui/ProviderOnboardingBox";
import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { Verify2FAInput } from "~/components/ui/Verify2FAInput";
import { Recaptcha } from "~/components/user/Recaptcha";
import { Turnstile } from "~/components/user/Turnstile";
import { useCaptcha } from "~/hooks/useCaptchaVerification";
import { useDesign } from "~/hooks/useTheme";
import { resetError, useAuth, useLogin, verify2fa } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

export const ConnectProvider = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const { error } = useAuth();
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [isCodeRequired, setIsCodeRequired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [provider] = useQueryParam<string>("provider");
  const [email] = useQueryParam<string>("email");
  const [connectProviderToken] = useQueryParam<string>("token");
  const providerConfig = getProviderConfig(provider as Provider);
  const login = useLogin("connect-provider");
  const connectLang = lang.onboardingV2.provider.connect;

  const {
    recaptchaRef,
    setTurnstileCaptcha,
    getCaptchaToken,
    captchaProvider,
  } = useCaptcha();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const removeAuthWarning = () => dispatch(resetError());

  const handleSubmit = async (e: React.FormEvent<unknown>) => {
    e.preventDefault();

    const captcha = await getCaptchaToken({ login: true });
    if (!captcha) {
      return; // Error already displayed by the hook
    }

    await login({
      email,
      password,
      captcha,
      captchaProvider,
      isCodeRequired,
      setIsCodeRequired,
      setIsSubmitting,
      removeAuthWarning,
      connectProviderToken,
    });
  };

  const handle2faSubmit = async (e: React.FormEvent<unknown>) => {
    e.preventDefault();
    removeAuthWarning();

    dispatch(verify2fa({ code, connectProviderToken }, setIsSubmitting));
  };

  if (!providerConfig) {
    navigate(Links.Login);
    return null;
  }

  // Otherwise show the regular forgot password form
  return (
    <OnboardingPageContainer>
      <ProviderOnboardingBox
        providerConfig={providerConfig}
        title={isCodeRequired ? connectLang["2fa"] : connectLang.title}
      >
        <Typography variant="Metropolis/Body/Regular" mb="0.5rem">
          {connectLang.subtitle}
        </Typography>
        <Typography
          variant="Metropolis/Body/Regular"
          mb="1rem"
          color={tokens.text.low}
        >
          {connectLang.disclaimer({ provider: providerConfig.name })}
        </Typography>
        {isCodeRequired ? (
          <Verify2FAInput
            error={error}
            setCode={setCode}
            sx={{ padding: 0, paddingBottom: "1rem" }}
          />
        ) : (
          <>
            <TextField
              fullWidth
              disabled
              value={email}
              variant="outlined"
              sx={{ mb: "0.5rem" }}
              label={connectLang.email}
            />
            <PasswordTextField
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              sx={{ mb: "1rem" }}
              placeholder={connectLang.password}
            />
          </>
        )}
        <PrimaryLoadingButton
          endIcon={<ArrowForward />}
          onClick={isCodeRequired ? handle2faSubmit : handleSubmit}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {connectLang.cta}
        </PrimaryLoadingButton>
        {!isCodeRequired ? (
          captchaProvider === CaptchaProvider.Recaptcha ? (
            <Recaptcha recaptchaRef={recaptchaRef} />
          ) : (
            <Turnstile onVerify={setTurnstileCaptcha} />
          )
        ) : null}
      </ProviderOnboardingBox>
    </OnboardingPageContainer>
  );
};
