import { Box, Typography } from "@mui/material";

import { ClientInviteStatus } from "~/components/clients/ClientInviteStatus";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { useActiveClient } from "~/hooks/useActiveClient";
import { useLang } from "~/redux/lang";

export const ClientInviteData = () => {
  const lang = useLang();
  const activeClient = useActiveClient();

  if (
    !activeClient ||
    activeClient.isDefaultAccount ||
    !!activeClient.childProfile
  ) {
    return null;
  }

  return (
    <SettingsBox>
      <Typography variant="Metropolis/Header/H5">
        {lang.settings.clientData.invitationStatus}
      </Typography>
      <Box mb={2}>
        <Typography variant="Metropolis/Body/Light">
          {lang.settings.clientData.invitationBody}
        </Typography>
      </Box>
      <ClientInviteStatus client={activeClient} verbose />
    </SettingsBox>
  );
};
