import { Box, Switch, Typography } from "@mui/material";

import { LocalStorageKey } from "~/constants/enums";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useLang } from "~/redux/lang";

export function SessionRecordingSettings() {
  const lang = useLang();
  const [isCensoringEnabled, setIsCensoringEnabled] = useLocalStorage<boolean>(
    LocalStorageKey.LocalCensoringEnabled,
    false,
  );

  const handleToggle = () => {
    setIsCensoringEnabled(!isCensoringEnabled);
    window.location.reload();
  };

  return (
    <Box
      display="flex"
      gap="0.5rem"
      flexDirection="column"
      component="form"
      px={{ xs: 2, sm: 0 }}
      mt={4}
    >
      <Typography variant="Metropolis/Header/H4">Session Recording Settings</Typography>
      <Typography variant="Metropolis/Body/Bold">
        See how PostHog will censor in app data locally
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="start"
        alignItems="center"
      >
        <Switch checked={isCensoringEnabled} onChange={handleToggle} />
        <Typography variant="Metropolis/Body/Bold">
          {lang.developer.enableLocalCensoring}
        </Typography>
      </Box>
    </Box>
  );
}
