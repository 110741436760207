import { Box, Typography } from "@mui/material";

import { StatusType } from "~/components/ui/enums";
import { ErrorIcon, WarningIcon } from "~/components/ui/Icons";
import { useDesign } from "~/hooks/useTheme";

const StatusIcon = ({ type }: { type: StatusType }) => {
  switch (type) {
    case StatusType.Warning:
      return <WarningIcon />;
    case StatusType.Error:
      return <ErrorIcon />;
    default:
      return null;
  }
};

export const StatusNotification = ({
  type,
  title,
  description,
}: {
  type: StatusType;
  title: string;
  description: string;
}) => {
  const { tokens } = useDesign();
  return (
    <Box
      bgcolor={
        type === "warning"
          ? tokens.background.warning.default
          : tokens.background.danger.default
      }
      display="flex"
      p={1}
      borderRadius="4px"
    >
      <Box mr={1}>
        <StatusIcon type={type} />
      </Box>
      <Box>
        <Typography variant="Metropolis/Body/Regular">{title}</Typography>
        <Typography variant="Metropolis/Body/Light">{description}</Typography>
      </Box>
    </Box>
  );
};
