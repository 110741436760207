import {
  DateTimePicker as MuiDateTimePicker,
  LocalizationProvider,
} from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import {
  Box,
  ClickAwayListener,
  TextField,
  type TextFieldProps,
} from "@mui/material";
import moment from "moment-timezone";
import { useState } from "react";

import { useDateTimeMomentFormat, useDateTimePlaceholder } from "~/redux/auth";

export const DateTimePicker = ({
  label,
  value,
  handleChange,
  inputProps,
  onBlur,
  error,
  helperText,
  required,
  size,
  disabled,
}: {
  label: string;
  value: string;
  handleChange: (date: string) => void;
  inputProps?: TextFieldProps["inputProps"];
  disabled?: boolean;
} & Pick<
  TextFieldProps,
  "error" | "helperText" | "required" | "onBlur" | "size"
>) => {
  const dateTimePlaceholder = useDateTimePlaceholder();
  const dateTimeFormat = useDateTimeMomentFormat();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
        <Box style={{ position: "relative" }}>
          <MuiDateTimePicker
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={handleClose}
            clearable
            DialogProps={{
              disablePortal: true,
            }}
            PopperProps={{
              disablePortal: true,
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                onBlur={onBlur}
                error={error}
                name="timestamp"
                fullWidth
                variant="outlined"
                label={label}
                placeholder={dateTimePlaceholder}
                size={size ? size : "small"}
                required={required}
                inputProps={{ ...inputProps, ...props.inputProps }}
                helperText={helperText}
              />
            )}
            label={label}
            inputFormat={dateTimeFormat}
            mask="____-__-__ __:__:__"
            value={value || null}
            onChange={(date) => {
              const dateTime = date?.toISOString();
              if (dateTime) {
                handleChange(dateTime);
              }
            }}
            disableFuture
            minDate={moment("2009-01-09")}
            maxDate={moment()}
            OpenPickerButtonProps={{
              "aria-label": "change date",
            }}
            disabled={disabled}
          />
        </Box>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};
