import { displayMessage } from "~/components/ui/Toaster";
import { createLoadingSelector } from "~/redux/api/selectors";
import { type AppThunk, type OrganisationState as State } from "~/redux/types";
import { useSelector } from "~/redux/useSelector";
import * as organisationAPI from "~/services/organisation";
import { DisplayMessage } from "~/types/enums";
import {
  type OrganisationDetails,
  type OrganisationUpcomingInvoice,
} from "~/types/index";

enum Organisation {
  GetOrganisation = "organisation/getOrganisation",
  GetOrganisationRequest = "organisation/getOrganisation/request",
  GetOrganisationSuccess = "organisation/getOrganisation/success",
  GetOrganisationFailure = "organisation/getOrganisation/failure",

  GetUpcomingInvoice = "organisation/getOrganisationUpcomingInvoice",
  GetUpcomingInvoiceRequest = "organisation/getOrganisationUpcomingInvoice/request",
  GetUpcomingInvoiceSuccess = "organisation/getOrganisationUpcomingInvoice/success",
  GetUpcomingInvoiceFailure = "organisation/getOrganisationUpcomingInvoice/failure",
}

const initialState: State = {
  organisation: null,
  upcomingInvoice: null,
};

type OrganisationAction =
  | {
      type: Organisation.GetOrganisationRequest;
    }
  | { type: Organisation.GetOrganisationFailure }
  | {
      type: Organisation.GetOrganisationSuccess;
      payload: { organisation: OrganisationDetails };
    }
  | { type: Organisation.GetUpcomingInvoice }
  | {
      type: Organisation.GetUpcomingInvoiceRequest;
    }
  | { type: Organisation.GetUpcomingInvoiceFailure }
  | {
      type: Organisation.GetUpcomingInvoiceSuccess;
      payload: { upcomingInvoice: OrganisationUpcomingInvoice };
    };

export function organisationReducer(
  state = initialState,
  action: OrganisationAction,
): State {
  switch (action.type) {
    case Organisation.GetOrganisationSuccess:
      return { ...state, organisation: action.payload.organisation };
    case Organisation.GetUpcomingInvoiceSuccess:
      return {
        ...state,
        upcomingInvoice: action.payload.upcomingInvoice,
      };
    default:
      return state;
  }
}

const getOrganisationRequest = (): OrganisationAction => {
  return {
    type: Organisation.GetOrganisationRequest,
  };
};

function getOrganisationFailure(): OrganisationAction {
  return {
    type: Organisation.GetOrganisationFailure,
  };
}

function getOrganisationSuccess(organisation: any): OrganisationAction {
  return {
    type: Organisation.GetOrganisationSuccess,
    payload: { organisation },
  };
}

const getUpcomingInvoiceRequest = (): OrganisationAction => {
  return {
    type: Organisation.GetUpcomingInvoiceRequest,
  };
};

const getUpcomingInvoiceFailure = (): OrganisationAction => {
  return {
    type: Organisation.GetUpcomingInvoiceFailure,
  };
};

const getUpcomingInvoiceSuccess = (
  upcomingInvoice: OrganisationUpcomingInvoice,
): OrganisationAction => {
  return {
    type: Organisation.GetUpcomingInvoiceSuccess,
    payload: { upcomingInvoice },
  };
};

export const useOrganisation = () =>
  useSelector((state) => state.organisation.organisation);

export const useUpcomingInvoice = () =>
  useSelector((state) => state.organisation.upcomingInvoice);

export const useOrganisationTeams = () => useOrganisation()?.teams || [];

const loadingOrganisationSelector = createLoadingSelector([
  Organisation.GetOrganisation,
]);

const loadingUpcomingInvoiceSelector = createLoadingSelector([
  Organisation.GetUpcomingInvoice,
]);

export const useIsLoadingOrganisation = () =>
  useSelector(loadingOrganisationSelector);

export const useIsLoadingUpcomingInvoice = () =>
  useSelector(loadingUpcomingInvoiceSelector);

export const loadOrganisation = (): AppThunk => async (dispatch, getState) => {
  if (loadingOrganisationSelector(getState())) {
    return;
  }

  dispatch(getOrganisationRequest());

  const res = await organisationAPI.getOrganisation();

  if (res.error) {
    displayMessage({
      message: getState().lang.map.organisation.wentWrongGettingDashboard,
      type: DisplayMessage.Error,
    });
    dispatch(getOrganisationFailure());
  } else {
    const { data } = res;
    dispatch(getOrganisationSuccess(data));
  }
};

export const loadUpcomingInvoice =
  (): AppThunk => async (dispatch, getState) => {
    if (loadingUpcomingInvoiceSelector(getState())) {
      return;
    }

    dispatch(getUpcomingInvoiceRequest());

    const res = await organisationAPI.getUpcomingInvoice();

    if (res.error) {
      displayMessage({
        message: getState().lang.map.organisation.wentWrongGettingDashboard,
        type: DisplayMessage.Error,
      });
      dispatch(getUpcomingInvoiceFailure());
    } else {
      const { data } = res;
      dispatch(getUpcomingInvoiceSuccess(data));
    }
  };
