import { FileDownloadOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useState } from "react";
import styled from "styled-components/macro";

import { CsvDownloadModal } from "~/components/transactions/filter-bar/CsvDownloadModal";
import { FilterDate } from "~/components/transactions/filter-bar/FilterDate";
import { FilterOther } from "~/components/transactions/filter-bar/FilterOther";
import { FilterPagination } from "~/components/transactions/filter-bar/FilterPagination";
import { FilterQueryBuilder } from "~/components/transactions/filter-bar/FilterQueryBuilder";
import { FilterReset } from "~/components/transactions/filter-bar/FilterReset";
import { FilterSearch } from "~/components/transactions/filter-bar/FilterSearch";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { sizes } from "~/components/ui/theme/legacy";
import { Align } from "~/types/enums";

export function FilterBar({ showCsvDownload }: { showCsvDownload: boolean }) {
  const [openCsvDownloadModal, setOpenCsvDownloadModal] = useState(false);

  return (
    <>
      {showCsvDownload ? (
        <CsvDownloadModal
          isOpen={openCsvDownloadModal}
          handleClose={() => {
            setOpenCsvDownloadModal(false);
          }}
        />
      ) : null}
      <FilterBarContents>
        <div
          css={`
            grid-area: search;
            z-index: 500;
            display: flex;
            gap: 0.5rem;
          `}
        >
          <Box
            sx={{
              maxHeight: "1.75rem",
              width: "100%",
            }}
          >
            <FilterSearch />
          </Box>
        </div>
        <div
          css={`
            grid-area: buttons;
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;
          `}
        >
          <FilterDate />
          <FilterQueryBuilder />
          <FilterOther />
          <FilterReset />
        </div>
        <div
          css={`
            grid-area: pagination;
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;
          `}
        >
          <FilterPagination isUrlDriven align={Align.Left} />
          {showCsvDownload ? (
            <StyledButton
              onClick={() => {
                setOpenCsvDownloadModal(true);
              }}
            >
              <FileDownloadOutlined
                sx={{ width: "1.125rem", height: "1.125rem" }}
              />
            </StyledButton>
          ) : null}
        </div>
      </FilterBarContents>
    </>
  );
}

const StyledButton = styled(TertiaryButton)`
  && {
    padding: 0.25rem;
    min-width: 0;
  }
`;

const FilterBarContents = styled.div`
  width: 100%;
  display: grid;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.tokens.elevation.default};
  align-items: flex-start;
  grid-template-columns: auto;

  @media (max-width: ${sizes.tabletS}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "search search"
      "buttons buttons"
      "pagination pagination";
  }
  @media (min-width: ${sizes.tabletS}) and (max-width: ${sizes.tablet}) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "search search search"
      "buttons buttons buttons"
      "pagination pagination pagination";
  }
  @media (min-width: ${sizes.tablet}) and (max-width: 1100px) {
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "search search search "
      "buttons buttons pagination";
  }
  @media (min-width: 1100px) {
    grid-template-columns: minmax(0, auto) minmax(24rem, 1fr) auto;
    grid-template-areas: "search buttons pagination";
  }
`;
