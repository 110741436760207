import { Box, RadioGroup } from "@mui/material";

import { RadioButton } from "~/components/settings-modal/views/RadioButton";
import { useLang } from "~/redux/lang";

export function EmailSettings({
  emailReports,
  onChange,
}: {
  emailReports: boolean;
  onChange: (value: boolean) => void;
}) {
  const lang = useLang().settings.report.emailReports;
  return (
    <Box mt="0.75rem">
      <RadioGroup
        aria-label="emailReports"
        value={emailReports}
        onChange={(_, value) => {
          onChange(value === "true");
        }}
      >
        <RadioButton
          value={false}
          title={lang.options.off.title}
          description={lang.options.off.description}
        />
        <RadioButton
          value
          title={lang.options.on.title}
          description={lang.options.on.description}
        />
      </RadioGroup>
    </Box>
  );
}
