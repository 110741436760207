import {
  Intercom,
  show,
  shutdown,
  trackEvent,
  update,
} from "@intercom/messenger-js-sdk";

import { type UserInfo } from "~/types";
import { type IntercomCustomAttributes } from "~/types/intercom";

// Track initialisation state
let isIntercomInitialised = false;

/**
 * Initialises the Intercom service anonymously
 *
 * This function boots Intercom in anonymous mode, which:
 * - Creates an anonymous visitor record using browser cookies
 * - Allows tracking activity before a user is identified
 * - Enables the Messenger for anonymous users
 * - Sets up the foundation for later merging with an identified user
 *
 * Intercom automatically assigns a random ID to anonymous visitors
 * and maintains their conversation history using browser cookies.
 *
 * @see https://www.intercom.com/help/en/articles/168-install-intercom-for-users-on-web
 */
export const initIntercom = () => {
  Intercom({
    app_id: import.meta.env.VITE_APP_INTERCOM_WORKSPACE_ID,
  });

  isIntercomInitialised = true;
};

/**
 * Identifies a user in Intercom and merges with previous anonymous activity
 *
 * When a previously anonymous user is identified with this function:
 * - Intercom automatically links their previous anonymous activity to their profile
 * - All previous conversations are preserved and associated with the user
 * - Their anonymous cookie is upgraded to an identified user session
 *
 * This pattern is similar to PostHog's identity resolution, where initial
 * anonymous activity is later connected to a specific user profile.
 *
 * @param user - The user information to identify in Intercom
 * @see https://www.intercom.com/help/en/articles/183-enable-identity-verification-for-web-and-mobile
 */
export const identifyIntercomUser = (user: UserInfo) => {
  if (!user) {
    return;
  }

  update({
    // Intercom built-in attributes
    email: user.email, // prefer the accountant email (parent profile) over the client email
    user_id: user.uid,
    user_hash: user.intercomHash, // insert HMAC value

    // Custom attributes (must be defined in Intercom workspace UI)
    // Basic user information
    active_profile: user.activeProfileDetails?.uid,
    plan: user.paidPlan,
    country: user.country,
    language: user.language,
    local_currency: user.localCurrency,
    name: user.name,

    // Authentication and account status
    is_oauth: user.isOAuth,
    oauth_provider: user.oAuthProvider,
    is_on_trial: user.paidPlanTrial,
    trial_end_date: user.paidPlanTrialUntil,
    email_verification_status: user.emailVerificationStatus,
    is_2fa_enabled: user.is2faEnabled,
    created_at: user.createdAt,
    last_login_date: user.lastLogin,

    // User preferences and settings
    show_spam_transactions: user.showSpamTransactions,
    inventory_method: user.activeDataSource?.inventoryMethod,
    holdingsBalanceType:
      user.activeDataSource?.taxSettings?.holdingsBalanceType,
    email_reports_enabled: user.emailReports,

    // Billing and plan information
    payment_due_date: user.paymentDue,
    plan_version: user.planVersion,
    is_managing_own_billing: user.isManagingOwnBilling,
    renewal_error_state: user.renewalErrorState,
    is_stripe_enabled: user.isStripeEnabled,
    has_v2_subscriptions: user.hasV2Subscriptions,

    // User journey information
    is_onboarding: user.isOnboarding,
    is_re_onboarding: user.isReOnboarding,
    should_show_cancellation_survey: user.shouldShowCancellationSurvey,
    bypass_tx_paywall: user.bypassTXPaywall,
    referrerSource: user.referrerSource,

    // Usage statistics
    transaction_count: user.activeDataSource?.txGroupCount ?? -1,
    tx_group_count: user.activeDataSource?.txGroupCount ?? -1,
    tx_billed_count: user.activeDataSource?.txBilledCount ?? -1,
    tx_real_count: user.activeDataSource?.txRealCount ?? -1,
    tx_free_count: user.activeDataSource?.txFreeCount ?? -1,
    last_tx_date: user.lastTxDate,

    // Organization and team information
    is_within_enterprise: user.isWithinEnterprise,
    accountant_max_client_limit: user.accountantMaxClientLimit ?? -1,

    has_reconciliation_issues:
      user.activeDataSource?.hasGeneratedAnyReconciliationIssues,
  } as IntercomCustomAttributes);

  isIntercomInitialised = true;
};

/**
 * Resets Intercom to its initial state
 * Clears all user data, conversations, and cookies associated with the current user
 * Should be called when user logs out
 *
 * @see https://developers.intercom.com/installing-intercom/web/methods#intercomshutdown
 */
export const resetIntercom = () => {
  if (!isIntercomInitialised) {
    return;
  }

  shutdown();
  isIntercomInitialised = false;
};

/**
 * Updates Intercom user attributes
 *
 * Use this to update user properties after identification. Intercom stores
 * these attributes with the user profile for segmentation and personalization.
 *
 * Note: All updates happen on the identified user if one exists, or the anonymous
 * visitor if the user hasn't been identified yet.
 *
 * @param attributes - Key-value pairs of user attributes to update
 * @see https://developers.intercom.com/installing-intercom/web/attributes-objects
 */
export const updateIntercomUser = (
  attributes: Partial<IntercomCustomAttributes>,
) => {
  if (!isIntercomInitialised) {
    return;
  }

  update(attributes);
};

/**
 * Shows the Intercom messenger
 *
 * This displays the messenger widget to the current user (identified or anonymous).
 */
export const showIntercom = () => {
  if (!isIntercomInitialised) {
    return;
  }

  show();
};

/**
 * Tracks a custom event in Intercom
 *
 * Events are associated with the current user (anonymous or identified)
 * and will be merged if an anonymous user is later identified.
 *
 * @param eventName - Name of the event to track
 * @param metadata - Additional data to associate with the event
 * @see https://developers.intercom.com/installing-intercom/docs/javascript-api-events
 */
export const trackIntercomEvent = (
  eventName: string,
  metadata?: Record<string, any>,
) => {
  if (!isIntercomInitialised) {
    return;
  }

  trackEvent(eventName, metadata);
};
