export const EMAIL_VALIDATION_REGEX = /\S+@\S+\.\S+/i;

export const isValidEmail = (email: string) => {
  return EMAIL_VALIDATION_REGEX.test(email);
};

export const stripSpacesOnKeyDown = (
  e: React.KeyboardEvent<HTMLDivElement>,
) => {
  if (e.code === "Space") e.preventDefault();
};

export const formatEmail = (inputEmail: string) => {
  const emailNoWhitespace = inputEmail.replace(/\s/g, "");
  return emailNoWhitespace.toLowerCase();
};
