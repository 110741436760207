import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { getOptionsFromTradeTypes } from "~/components/transactions/command-palette/helpers/getOptionsFromTradeTypes";
import { useSelectedTradeTypes } from "~/components/transactions/command-palette/helpers/useSelectedTradeTypes";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { PnLAccountTopSection } from "~/components/transactions/command-palette/views/change-pnl-account/PnLAccountTopSection";
import { SelectPnLAccount } from "~/components/transactions/command-palette/views/change-pnl-account/SelectPnLAccount";
import { useLang } from "~/redux/lang";

/**
 * Select which trade type the user wants to map a pnl account
 * If there is only one, we skip this screen
 */
export const SelectTradeType = () => {
  const { push } = useNavController();
  const lang = useLang();

  const tradeTypes = useSelectedTradeTypes();
  // Only one trade type, so we dont even need to ask
  if (tradeTypes.length === 1) {
    return <SelectPnLAccount tradeType={tradeTypes[0]} />;
  }
  // get trade type options
  const options = getOptionsFromTradeTypes(tradeTypes, lang);
  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangePnLAccount} />
          <PnLAccountTopSection />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.tradeType}
      options={options}
      onSelection={({ tradeType }) => {
        push(<SelectPnLAccount tradeType={tradeType} />);
      }}
    />
  );
};
