import { TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useCreateSeedDataSetMutation } from "~/state/developer";

export const CreateSeedDataModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const mutation = useCreateSeedDataSetMutation();

  const { control, getValues, reset } = useForm();

  const onSubmit = () => {
    const values = getValues();
    mutation
      .mutateAsync({
        name: values.name,
        description: values.description,
      })
      .then(reset)
      .then(handleClose);
  };

  return (
    <GeneralDialog
      isOpen={isOpen}
      title="Create Seed Data Set"
      handleClose={handleClose}
      actionText="Create"
      handleAction={() => {
        onSubmit();
      }}
      pending={mutation.isPending}
    >
      <Typography mb={1}>
        This will create a new seed data set from the data currently in your
        account, visible to all CTC devs.
      </Typography>
      <Typography mb={1}>
        A snapshot will be taken, so changes made to your account moving forward
        will not be reflected in the seed data set.
      </Typography>
      <Typography mb={1}>
        Please keep seed data sets to minimal examples: Think about deleting
        non-relevant transactions before creating a snapshot.
      </Typography>
      <form>
        <Controller
          control={control}
          name="name"
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              type="text"
              label="Name"
              placeholder="My / New / Dataset"
              InputLabelProps={{ required: true }}
              error={invalid}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          rules={{ required: "Description is required" }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              type="text"
              label="Description"
              placeholder="A helpful description"
              InputLabelProps={{ required: true }}
              error={invalid}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </form>
    </GeneralDialog>
  );
};
