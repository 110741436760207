import { Box, type BoxProps } from "@mui/material";
import { type ReactNode } from "react";
import styled from "styled-components/macro";

import { useExperimentVariant } from "~/analytics/posthog";
import { useIsMobile } from "~/components/ui/hooks";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign, useResolvedTheme } from "~/hooks/useTheme";
import { FeatureFlag, Theme } from "~/types/enums";

import { useHideIntercom } from "../../../hooks/useHideIntercom";

// Utility function for opacity
const getOpacity = (isDarkTheme: boolean) => (isDarkTheme ? 0.5 : 0.3);

export function OnBoardingWindow({
  children,
  footer,
  maxWidth = "37.5em",
  bgImage,
  isBigComponent = false,
  containerProps = {},
  isSimple = false,
  ...props
}: {
  containerProps?: BoxProps;
  children: ReactNode;
  footer?: ReactNode;
  isBigComponent?: boolean;
  bgImage?: string;
  isSimple?: boolean;
} & BoxProps) {
  const theme = useResolvedTheme();
  const isMobile = useIsMobile();
  const isEmbedded = useIsEmbedded();
  const { tokens } = useDesign();
  const isInCoinbaseThemeExperiment = useExperimentVariant(
    FeatureFlag.CoinbaseTheme,
    "test",
  );

  useHideIntercom(true);

  if (isSimple) {
    return (
      <SimpleMobileOnBoardingWindow
        children={children}
        footer={footer}
        containerProps={containerProps}
        maxWidth={maxWidth}
        {...props}
      />
    );
  }

  const isDarkTheme = theme === Theme.Dark;

  // When the component is big, we want to make the background color more opaque
  // so the bg gradient doesn't pop too much
  const bgOpacity = isBigComponent ? "0.8" : "0.6";

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      maxWidth={maxWidth}
      width="95vw"
      bgcolor={
        isMobile
          ? "transparent"
          : isDarkTheme
            ? `rgba(13, 13, 13, ${bgOpacity})`
            : `rgba(250, 250, 250, ${bgOpacity})`
      }
      borderRadius="1.5rem"
      border={
        isMobile
          ? "none"
          : `1px solid ${
              isEmbedded
                ? tokens.border.neutral.default
                : isDarkTheme
                  ? "rgba(183, 137, 244, 0.20)"
                  : "rgba(183, 137, 244, 0.40)"
            }`
      }
      p={{ xs: "1rem", md: "1.5rem" }}
      sx={{
        ...(bgImage
          ? {
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }
          : {}),
      }}
      {...containerProps}
    >
      <Box display="flex" width="100%" {...props}>
        {children}
      </Box>
      {footer ? <Box width="100%">{footer}</Box> : null}

      {isMobile || isInCoinbaseThemeExperiment ? null : (
        <>
          <TopLeftBG isDarkTheme={isDarkTheme} isEmbedded={isEmbedded} />
          <TopRightBG
            isDarkTheme={isDarkTheme}
            isMobile={isMobile}
            isEmbedded={isEmbedded}
          />
          <BottomLeftBG isDarkTheme={isDarkTheme} isEmbedded={isEmbedded} />
          <BottomRightBG
            isDarkTheme={isDarkTheme}
            isMobile={isMobile}
            isEmbedded={isEmbedded}
          />
        </>
      )}
    </Box>
  );
}

// Used for mobile experiment.
export function SimpleMobileOnBoardingWindow({
  children,
  footer,
  maxWidth,
  containerProps = {},
  ...props
}: {
  containerProps?: BoxProps;
  children: ReactNode;
  footer?: ReactNode;
} & BoxProps) {
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      maxWidth={maxWidth}
      width="95vw"
      borderRadius="1.5rem"
      p={{ xs: "1rem", md: "1.5rem" }}
      {...containerProps}
    >
      <Box display="flex" width="100%" {...props}>
        {children}
      </Box>
      {footer ? <Box width="100%">{footer}</Box> : null}
    </Box>
  );
}

const TopLeftBG = styled(Box)<{ isDarkTheme: boolean; isEmbedded: boolean }>`
  &:before {
    content: "";
    border-radius: 21.25rem;
    ${({ isEmbedded }) =>
      isEmbedded ? "" : `background: #6319db; filter: blur(118px);`}
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 60%;
    z-index: -1;
    opacity: ${({ isDarkTheme }) => getOpacity(isDarkTheme)};
  }
`;

const TopRightBG = styled(Box)<{
  isDarkTheme: boolean;
  isMobile: boolean;
  isEmbedded: boolean;
}>`
  &:before {
    content: "";
    border-radius: 21.25rem;
    ${({ isEmbedded }) =>
      isEmbedded ? "" : `background: #ab3aa7; filter: blur(110px);`}
    position: absolute;
    top: 0;
    left: 50%;
    width: ${({ isMobile }) =>
      isMobile ? "30%" : "50%"}; // prevent overflow on mobile
    height: 60%;
    z-index: -1;
    opacity: ${({ isDarkTheme }) => getOpacity(isDarkTheme)};
  }
`;

const BottomLeftBG = styled(Box)<{ isDarkTheme: boolean; isEmbedded: boolean }>`
  &:before {
    content: "";
    border-radius: 21.25rem;
    ${({ isEmbedded }) =>
      isEmbedded ? "" : `background: #8f31d9; filter: blur(118px);`}
    position: absolute;
    top: 50%;
    left: 0;
    width: 60%;
    height: 50%;
    z-index: -1;
    opacity: ${({ isDarkTheme }) => getOpacity(isDarkTheme)};
  }
`;

const BottomRightBG = styled(Box)<{
  isDarkTheme: boolean;
  isMobile: boolean;
  isEmbedded: boolean;
}>`
  &:before {
    content: "";
    border-radius: 21.25rem;
    ${({ isEmbedded }) =>
      isEmbedded ? "" : `background: #4d209c; filter: blur(106px);`}
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ isMobile }) =>
      isMobile ? "30%" : "50%"}; // prevent overflow on mobile
    height: 50%;
    z-index: -1;
    opacity: ${({ isDarkTheme }) => getOpacity(isDarkTheme)};
  }
`;
