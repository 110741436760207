import {
  Provider,
  type ProviderConfig as ProviderConfigType,
} from "~/components/onboarding-v2/provider/types";
import { ProviderConfig } from "~/components/onboarding-v2/provider/config";

export function getProviderConfig(
  providerId?: Provider,
): ProviderConfigType | null {
  if (!providerId) {
    return null;
  }
  const config = ProviderConfig[providerId];
  if (config) {
    return config;
  }
  return null;
}
