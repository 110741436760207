import {
  Trade,
  type AiModel,
  type AiProvider,
  type Blockchain,
} from "@ctc/types";
import multiply from "lodash/multiply";

import { type ERPProvider } from "~/components/settings-modal/views/enums";
import { type ActionRowFeeType } from "~/components/transactions/action-row/types";
import { type ContextIdType } from "~/components/transactions/filter-bar/enums";
import { deleteRequest, get, post, put } from "~/services/core";
import { type RuleBulkOperation } from "~/services/rules";
import { Warning } from "~/types/enums";
import { type BulkOperations, type BulkUpdateStatus } from "~/types/enums";
import {
  type ActionBalancesResponse,
  type ActionPreviousVersion,
  type ActionRow,
  type BreakdownRow,
  type BulkIgnorableWarning,
  type CurrencyIdentifier,
  type ErpAccountMappings,
  type FilterQuery,
  type IgnorableWarning,
  type ManualTransactionEntry,
  type OnRowWarning,
  type TransactionComment,
  type TransactionDetails,
} from "~/types/index";

export type PutTransactionPayload = Partial<{
  _id: string;
  timestamp: string;
  trade: Trade;
  currency: string;
  currencyIdentifier: CurrencyIdentifier;
  quantity: number;
  price: number;
  feeQuantity: number;
  fee: number;
  feeCurrencyIdentifier: CurrencyIdentifier | null;
  description: string;
  blockchain: string | null;
  to: string;
  from: string;
  ignoreWarning: TransactionDetails["ignoreWarning"];
  priceValueCurrency: string;
  reviewed: boolean;
  groupById: string | null;
  dontGroup: boolean;
  // note erpAccountMappings has to be like this (not erp.accountMappings) since on the BE we have
  // the bulk update payload and this PUT payload uses that same type as the bulk update
  erpAccountMappings: Partial<ErpAccountMappings>;
}>;

export type PostActionsQueryResponse = {
  transactions: ActionRow[];
  total: number;
  // this is useful for view in context, we request a actionId and this
  // is the page number its found on, so we can update ethe context
  pageNumber: number;
};

export function postActionsQuery(
  {
    filter,
    sort,
    count,
    page,
    viewInContextId,
    contextIdType,
  }: {
    filter: FilterQuery | undefined;
    sort: string | null | undefined;
    count: number | null | undefined;
    page?: number | null | undefined;
    viewInContextId?: string | null | undefined;
    contextIdType?: ContextIdType;
  },
  { signal }: { signal?: AbortSignal } = {},
) {
  const path = "/v2/actions/query";
  const body = {
    filter,
    sort,
    count,
    page: viewInContextId
      ? {
          viewInContextId,
          contextIdType,
        }
      : page,
  };
  return post<PostActionsQueryResponse>(path, body, undefined, signal);
}

/**
 * Get the total (grouped) transactions count
 */
export function getActionsCount() {
  const path = "/v2/actions/count";
  return post<{
    txGroupCount: number;
    txBilledCount: number;
    txRealCount: number;
    txFreeCount: number;
  }>(path, {});
}

/**
 * For a send/receive transaction, generates a matching tx
 * @param actionId Action ID for a send/receive transaction
 * @param transactionId Transaction ID for a send/receive transaction
 * @returns
 */
export async function generateTransferForAction(
  actionId: string,
  transactionId: string,
) {
  return post<{
    rows: ActionRow[];
  }>(`/v2/actions/${actionId}/generateTransfer`, { transactionId });
}

export function updateActionReviewed(
  id: string,
  payload: { reviewed: boolean },
) {
  const path = `/actions/${id}/reviewed`;
  return put(path, payload);
}

export function deleteAction(id: string) {
  const path = `/actions/${id}`;
  return deleteRequest(path);
}

export function bulkUngroupActions(ids: string[]) {
  const path = "/v2/actions/bulk/ungroup";
  return post<string[]>(path, { ids });
}

type Party = {
  exchangeId: string;
  blockchain: Blockchain | string | undefined;
};

export type BulkOperation =
  | {
      type: BulkOperations.ChangeSourceAccount;
      fromParty: Party;
      toParty: Party;
    }
  | {
      type: BulkOperations.ChangeDestinationAccount;
      fromParty: Party;
      toParty: Party;
    }
  | {
      type: BulkOperations.Patch;
      patch: PutTransactionPayload;
    }
  | {
      type: BulkOperations.MarkAsSpam;
    }
  | {
      type: BulkOperations.ChangeCurrency;
      fromCurrency: CurrencyIdentifier | undefined;
      toCurrency: CurrencyIdentifier;
    }
  | {
      type: BulkOperations.ChangeFeeCurrency;
      fromCurrency: CurrencyIdentifier | undefined;
      toCurrency: CurrencyIdentifier;
    }
  | {
      type: BulkOperations.AddComment;
      comment: string;
    }
  | {
      type: BulkOperations.Ignore;
    }
  | {
      type: BulkOperations.Delete;
    }
  | {
      type: BulkOperations.LookUpMarketPrice;
    }
  | {
      type: BulkOperations.IgnoreWarnings;
      warnings: BulkIgnorableWarning[];
    }
  | {
      type: BulkOperations.SyncToErp;
      erpType: ERPProvider | undefined;
    }
  | {
      type: BulkOperations.ChangePrice;
      currency: CurrencyIdentifier;
      price: number;
    }
  | {
      type: BulkOperations.TxRewind;
      timestamp: Date;
    }
  | RuleBulkOperation;

export function bulkOp(payload: {
  filter: FilterQuery;
  operation: BulkOperation;
}) {
  const path = "/v2/actions/bulk";
  return post<{
    result: undefined | BulkUpdateStatus.AcceptedForProcessing;
    bulkEditId: string | undefined;
  }>(path, payload);
}

export function bulkUndo(payload: { bulkEditId: string }) {
  const path = "/v2/actions/bulk/undo";
  return post<void>(path, payload);
}

// Posts a comment on an action
export function postActionComment(actionId: string, comment: string) {
  const path = `/actions/${actionId}/comments`;
  return post(path, { comment });
}

// Edits a comment on an action
export function editActionComment(
  actionId: string,
  commentId: string,
  comment: string,
) {
  const path = `/actions/${actionId}/comments/${commentId}`;
  return put(path, { comment });
}

// deletes a comment on an action
export function deleteActionComment(actionId: string, commentId: string) {
  const path = `/actions/${actionId}/comments/${commentId}`;
  return deleteRequest(path);
}

export function duplicateAction(actionId: string) {
  const path = `/actions/${actionId}/duplicate`;
  return post<{
    rows: ActionRow[];
  }>(path, {});
}

/**
 * Removes the dontGroup flag from a transaction
 * @param id The _id of the transactions
 */
export function regroupActions(id: string) {
  const path = `/actions/${id}/regroup`;
  return put<ActionRow[]>(path, {});
}

export function createManualAction(transaction: ManualTransactionEntry) {
  const path = "/actions/manual";
  return post<string[]>(path, transaction);
}

/**
 * Given an _id of an action, returns the breakdown of the transactions making
 * up the action
 *
 * null if the actionId doesnt exist
 * @return null if the action requested doesnt exist
 */
export function getActionBreakdown(id: string) {
  const path = `/actions/${id}/breakdown`;
  return get<BreakdownRow[] | null>(path);
}

export function previousVersions(actionId: string) {
  const path = `/v2/actions/${actionId}/previous-versions`;
  return get<ActionPreviousVersion[]>(path, {});
}

/**
 * @param id: action ID
 * @returns null if the action requested doesnt exist
 */
export function getActionBalances(id: string) {
  const path = `/actions/${id}/balances`;
  return get<ActionBalancesResponse | null>(path);
}

/**
 * @param id: action ID
 * @returns null if the action requested doesnt exist
 */
export function getActionComments(id: string) {
  const path = `/actions/${id}/comments`;
  return get<TransactionComment[] | null>(path);
}

// https://bit.ly/3GUb4dZ
export function isOnRowWarning(warning: Warning): warning is IgnorableWarning {
  const ignorable: OnRowWarning[] = [
    Warning.Uncategorised,
    Warning.MissingPrice,
    Warning.NegativeBalance,
    Warning.SuspectedMissingImport,
    Warning.MissingBlockchain,
    Warning.UnmatchedTransfer,
  ];

  return ignorable.includes(warning as OnRowWarning);
}

export function hasUnignoredErrors(action: ActionRow) {
  return !![...action.incoming, ...action.outgoing, ...action.fees].find(
    (tx) => {
      const { errors, ignoreWarning = {} } = tx;
      return errors.find((error) => {
        if (isOnRowWarning(error)) {
          return !ignoreWarning[error];
        }
      });
    },
  );
}

/**
 * Check if the action have missing price warning
 *
 * @return boolean
 */
export const getMissingPrice = (
  errors: Warning[],
  ignoreWarnings?: Partial<Record<IgnorableWarning, boolean>>,
  price?: number,
): boolean => {
  return (
    !ignoreWarnings?.missingPrice &&
    errors?.includes(Warning.MissingPrice) &&
    !price
  );
};

/**
 * Check if the action have negative balance warning
 *
 * @return boolean
 */
export const getNegativeBalance = (
  errors: Warning[],
  ignoreWarnings?: Partial<Record<IgnorableWarning, boolean>>,
): boolean => {
  return (
    !ignoreWarnings?.negativeBalance &&
    errors?.includes(Warning.NegativeBalance) &&
    errors?.includes(Warning.ZeroCostBuy)
  );
};

/**
 * Check if the action have missing blockchain warning
 *
 * @return boolean
 */
export const getMissingBlockchain = (
  errors: Warning[],
  ignoreWarnings?: Partial<Record<IgnorableWarning, boolean>>,
  blockchain?: string,
): boolean => {
  return (
    errors &&
    errors.includes(Warning.MissingBlockchain) &&
    !ignoreWarnings?.missingBlockchain &&
    !blockchain
  );
};

export function atomicUpdateTransaction({
  actionId,
  update,
}: {
  actionId: string;
  update: {
    createFeeTx?: {
      payload: PutTransactionPayload[];
    };
    updateTx: {
      payload: PutTransactionPayload[];
      applySts: boolean;
      createCategoryRule: boolean;
    };
    deleteTx?: {
      payload: string[];
    };
  };
}) {
  const path = `/v2/actions/${actionId}/atomic-update`;
  return put<{
    rows: ActionRow[];
    stsRes?: {
      count: number;
      bulkEditId: string | undefined;
    };
  }>(path, { update });
}

/**
 * Get the fees from an action row, can be either embedded or stand alone
 * Stand alone fee tx take priority, if none exist, use embedded fees
 *
 * @return ActionRowFeeType[]
 */
export function getFeesFromActionRow(actionRow: ActionRow) {
  const actionRowFee: ActionRowFeeType[] = [];

  // Stand alone fee tx
  actionRow.fees.forEach((tx) => {
    actionRowFee.push({
      id: tx._id,
      quantity: tx.quantity,
      value: multiply(tx.quantity, tx.price),
      currency: tx.currencyIdentifier,
      timestamp: tx.timestamp,
      isEmbedded: false,
      exchange: tx.from,
      displayName: tx.fromDisplayName,
      // this is a hack to stop the UI erroring
      // when users have old style fees that dont have a groupById
      // they should be able to still edit the fee amounts (provided it re-groups)
      // with the action, but they won't be able to add/remove fee
      // because it won't find a tx with a matching groupById
      groupById: tx.groupById ?? "",
      blockchain: tx.blockchain,
    });
  });

  return actionRowFee;
}

export type StsDataResponse = {
  count: number | undefined;
  filter: FilterQuery;
};

export function getSimilarTransactionData(actionId: string) {
  const path = `/actions/${actionId}/sts`;
  return get<StsDataResponse>(path);
}

export function syncToErp(actionId: string, erp: ERPProvider) {
  const path = `/v2/actions/${actionId}/erp/${erp}/sync`;
  return put<void>(path, {});
}

export function getActionCountFromFilter(filter: FilterQuery) {
  const path = `/v2/actions/count`;
  return post<{ count: number }>(path, { filter });
}

export type ExplainActionResponse = {
  explanation: string;
  suggestions: Record<string, Trade>;
  confidence: number;
  systemMessage: string;
};

export function explainAction({
  actionId,
  config,
  userPrompt,
  systemPrompt,
}: {
  actionId: string;
  config: {
    provider: AiProvider;
    model: AiModel;
  };
  userPrompt: string;
  systemPrompt: string;
}) {
  const path = `/actions/${actionId}/ai-explain`;
  return post<ExplainActionResponse>(path, {
    config,
    userPrompt,
    systemPrompt,
  });
}
