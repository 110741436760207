import { Trade } from "@ctc/types";
import { ArrowDropDown } from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useMemo } from "react";

import { QueryBuilderGeneral } from "~/components/transactions/filter-bar/FilterQueryBuilder";
import { TertiaryButtonGroup } from "~/components/ui/ui-buttons/ButtonGroup";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { useDesign } from "~/hooks/useTheme";
import { filterOperatorConfig } from "~/lib/filterOperatorConfig";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { useLang } from "~/redux/lang";
import { FilterOperators } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

function getAndOrFilterRecursiveDepth(filter: FilterQuery): number {
  if (
    filter.type === FilterOperators.And ||
    filter.type === FilterOperators.Or
  ) {
    return 1 + Math.max(...filter.rules.map(getAndOrFilterRecursiveDepth));
  }
  return 0;
}

function OuterFilterDisplay({
  filter,
  setFilter,
}: {
  filter: FilterQuery;
  setFilter: (filter: FilterQuery) => void;
}) {
  const lang = useLang();

  switch (filter.type) {
    case FilterOperators.TxTrade: {
      const switchCategory = (trade: Trade.In | Trade.Out) => {
        const newFilter = { ...filter, value: [trade] };
        return () => {
          setFilter(newFilter);
        };
      };

      const InIcon = TradeIcons[Trade.In];
      const OutIcon = TradeIcons[Trade.Out];

      return (
        <Box display="flex" width="100%" alignItems="center" gap="0.75rem">
          <TertiaryButton
            disabled
            endIcon={
              <ArrowDropDown sx={{ fontSize: "1.5rem", color: "inherit" }} />
            }
            sx={{ paddingRight: "0" }}
          >
            <Typography variant="Metropolis/Body/Bold" color="inherit">
              {lang.txTable.filter.operators[filter.type]}
            </Typography>
          </TertiaryButton>
          <TertiaryButtonGroup
            actions={[
              {
                text: getActionTypeName({ actionType: Trade.In, lang }),
                onClick: switchCategory(Trade.In),
                startIcon: (
                  <InIcon style={{ fontSize: "1rem", color: "inherit" }} />
                ),
              },
              {
                text: getActionTypeName({ actionType: Trade.Out, lang }),
                onClick: switchCategory(Trade.Out),
                startIcon: (
                  <OutIcon style={{ fontSize: "1rem", color: "inherit" }} />
                ),
              },
            ]}
            selectedIndex={filter.value.includes(Trade.In) ? 0 : 1}
          />
        </Box>
      );
    }
    default: {
      return null;
    }
  }
}

function OuterFilters({
  filters,
  setFilters,
}: {
  filters: FilterQuery[];
  setFilters: (filters: FilterQuery[]) => void;
}) {
  const { tokens } = useDesign();

  return (
    <>
      <Stack gap="0.5rem">
        {filters.map((filter, index) => {
          const setFilter = (newFilter: FilterQuery) => {
            const newFilters = [...filters];
            newFilters[index] = newFilter;
            setFilters(newFilters);
          };

          return (
            <OuterFilterDisplay
              key={filter.type}
              filter={filter}
              setFilter={setFilter}
            />
          );
        })}
      </Stack>
      <Divider sx={{ borderColor: tokens.border.neutral.default }} />
    </>
  );
}

export function getInnerOuterFilter<T extends FilterQuery | undefined>(
  filter: T,
): {
  innerFilter: T | FilterQuery;
  outerFilters: FilterQuery[];
} {
  const defaultEmptyFilter: FilterQuery = {
    type: FilterOperators.And,
    rules: [],
  };

  if (!filter) return { innerFilter: filter, outerFilters: [] };

  const recursiveDepth = getAndOrFilterRecursiveDepth(filter);

  if (recursiveDepth > 1 && filter.type === FilterOperators.And) {
    const rules = filter.rules;
    const innerFilter =
      rules.find((f) => f.type === FilterOperators.And) ?? defaultEmptyFilter;
    const outerFilters = rules.filter((f) => f.type !== FilterOperators.And);
    return { innerFilter, outerFilters };
  }

  return { innerFilter: filter, outerFilters: [] };
}

export function RuleCardFilterSection({
  innerFilter,
  outerFilters,
  setInnerFilter,
  setOuterFilters,
}: {
  innerFilter: FilterQuery | undefined;
  outerFilters: FilterQuery[];
  setInnerFilter: (filter: FilterQuery | undefined) => void;
  setOuterFilters: (filters: FilterQuery[]) => void;
}) {
  const lang = useLang();

  const defaultEmptyFilter: FilterQuery = {
    type: FilterOperators.And,
    rules: [],
  };

  // Initialize filter options
  const enabledOperators = Object.values(FilterOperators).filter(
    (operator) => filterOperatorConfig[operator].isRulesEnabled,
  );

  const filterControl = useMemo(() => {
    return enabledOperators.map((operator) => ({
      field: operator,
      label:
        lang.txTable.filter.filterBuilderOperatorOverrides[
          operator as keyof typeof lang.txTable.filter.filterBuilderOperatorOverrides
        ] ?? lang.txTable.filter.operators[operator],
    }));
  }, [enabledOperators, lang]);

  return (
    <Stack gap="0.5rem">
      {outerFilters.length ? (
        <OuterFilters filters={outerFilters} setFilters={setOuterFilters} />
      ) : null}
      <QueryBuilderGeneral
        disableDivider
        query={innerFilter ?? defaultEmptyFilter}
        onQueryChange={(newQuery) => {
          setInnerFilter(newQuery);
        }}
        selectableFilterOptions={filterControl}
        disableAddFullWidth
        addText={lang.contacts.ruleCard.addCondition}
      />
    </Stack>
  );
}
