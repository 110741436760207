import { bindTrigger } from "material-ui-popup-state";
import { type ComponentProps, type ReactNode, useRef } from "react";

import { useCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import {
  ModalProvider,
  useModal,
} from "~/components/transactions/command-palette/ModalProvider";
import { NavControllerProvider } from "~/components/transactions/command-palette/NavController";
import { SmallCommandPaletteModal } from "~/components/transactions/command-palette/SmallCommandPaletteModal";

export function SmallCommandPaletteDropdown({
  popupId,
  ...props
}: ComponentProps<typeof SmallCommandPaletteDropdownInner> & {
  popupId: string;
}) {
  return (
    <NavControllerProvider>
      <ModalProvider variant="popover" popupId={popupId}>
        <SmallCommandPaletteDropdownInner {...props} />
      </ModalProvider>
    </NavControllerProvider>
  );
}
function SmallCommandPaletteDropdownInner({
  button,
  palette,
}: {
  button: (buttonProps: any) => ReactNode;
  palette: (close: () => void) => ReactNode;
}) {
  const modal = useModal();
  const { open, close } = useCommandPalette();
  const anchor = useRef<HTMLDivElement>(null);

  const buttonProps = {
    ref: anchor,
    ...bindTrigger(modal),
    onClick: (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.blur();
      open(palette(close), e);
    },
  };
  return (
    <>
      <div ref={anchor}>{button(buttonProps)}</div>
      <SmallCommandPaletteModal modal={modal} anchor={anchor} />
    </>
  );
}
