import { Button } from "@mui/material";
import styled from "styled-components/macro";

export const WarningButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.tokens.text.inverse};
    background-color: ${({ theme }) => theme.tokens.button.danger.default};
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.08),
      0 2px 5px rgba(31, 41, 55, 0.12),
      inset 0 -1px 1px rgba(0, 0, 0, 0.12);
  }

  & .MuiButton-sizeMedium {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  & .MuiButton-sizeSmall {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.tokens.button.danger.hover};
  }

  &.Mui-focusVisible {
    background-color: ${({ theme }) => theme.tokens.button.danger.pressed};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.text.disabled};
    background-color: ${({ theme }) => theme.tokens.button.disabled};
    box-shadow: 0 0;
  }
`;
