import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { type ComponentProps } from "react";

import { bulkEditAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { useLang } from "~/redux/lang";

export const ConfirmationUI = ({
  topArea,
  onConfirm,
  loading,
}: {
  topArea?: ComponentProps<typeof CommandPaletteMenu>["topArea"];
  onConfirm: () => void;
  loading: boolean;
}) => {
  const { pop } = useNavController();
  const lang = useLang();
  const captureAnalytics = useCaptureAnalytics();
  const options = [
    {
      label: lang.txTable.commandPallet.actions.confirm,
      icon: <CheckIcon />,
    },
    {
      label: lang.txTable.commandPallet.actions.goBack,
      icon: <CloseIcon />,
    },
  ];

  return (
    <CommandPaletteMenu
      topArea={topArea}
      loading={loading}
      disabled={loading}
      placeholder={`${lang.txTable.commandPallet.actions.confirm}...`}
      options={options}
      onSelection={({ label }) => {
        if (label === lang.txTable.commandPallet.actions.confirm) {
          onConfirm();
        } else if (label === lang.txTable.commandPallet.actions.goBack) {
          pop();
          captureAnalytics(bulkEditAnalyticsKey("cancel"));
        }
      }}
    />
  );
};
