import {
  AccountBalanceWalletOutlined,
  CheckCircleOutlineOutlined,
  DescriptionOutlined,
  Favorite,
  FilterAltOutlined,
  ImportContactsOutlined,
  Lan,
  Mediation,
  SavingsOutlined,
  Settings,
  TerminalOutlined,
} from "@mui/icons-material";

import { CountChip } from "~/components/nav/CountChip";
import { StepItemType } from "~/components/nav/enum";
import { useTransactionsTab } from "~/components/nav/hooks/useTransactionsTab";
import { TipsPaywallTooltip } from "~/components/nav/TipsPaywallTooltip";
import {
  type LinkItemDetails,
  type StepItemDetails,
} from "~/components/nav/types";
import { REFERRAL_REWARD } from "~/constants/constants";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { useActiveClient } from "~/hooks/useActiveClient";
import { useIsReferEnabled } from "~/hooks/useIsReferEnabled";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib";
import {
  useHighestRankingPlanUser,
  useIsAccountant,
  useIsEnterprise,
  useIsManagingClients,
  useUser,
} from "~/redux/auth";
import { isDeveloperEnabled } from "~/redux/developer";
import { useLang } from "~/redux/lang";
import {
  useReconciliationLink,
  useReconciliationSteps,
} from "~/state/reconciliation";
import { Features, Links } from "~/types/enums";
import { isBusinessPlanType } from "~/types/index";

// Return the nav tabs for the current state
export function useNavTabs(): StepItemDetails[] {
  const lang = useLang();
  const { tokens } = useDesign();
  const highestRankingPlanUser = useHighestRankingPlanUser();
  const reconciliationLink = useReconciliationLink();
  const reconciliationSteps = useReconciliationSteps({
    includeCurrentlyHiddenSteps: false,
  });
  const isEnterprise = useIsEnterprise();
  const isAccountant = useIsAccountant();
  const isManagingClients = useIsManagingClients();
  const activeClient = useActiveClient();
  const { setOpen: setSettingsModalOpen } = useSettingsModal();
  const transactionsTab = useTransactionsTab();
  const isReferAFriendEnabled = useIsReferEnabled();
  const user = useUser();

  const getTabs = (): StepItemDetails[] => {
    // Accountant viewing self
    if (isManagingClients && !activeClient) {
      return [];
    }

    const shouldSeeTreasury =
      highestRankingPlanUser?.features[Features.Treasury];
    const canSeeTips = highestRankingPlanUser?.features[Features.Tips];
    const isBusiness = highestRankingPlanUser?.paidPlan
      ? isBusinessPlanType(highestRankingPlanUser?.paidPlan)
      : false;

    const portfolioTab: LinkItemDetails = shouldSeeTreasury
      ? {
          id: "treasury",
          type: StepItemType.Link,
          name: lang.dashboard.treasury,
          to: Links.Dashboard,
          icon: <SavingsOutlined />,
        }
      : {
          id: "portfolio",
          type: StepItemType.Link,
          name: lang.navbar.portfolio,
          to: Links.Dashboard,
          icon: <AccountBalanceWalletOutlined />,
        };

    // Enterprise (H&R Block)
    if (isEnterprise) {
      return [portfolioTab];
    }

    const integrationsTab: LinkItemDetails = {
      id: "imports",
      type: StepItemType.Link,
      name: lang.navbar.integrations,
      to: Links.Imports,
      icon: <Lan />,
      className: "tour-integrations",
    };

    const reviewTab: LinkItemDetails = {
      id: "reconciliation",
      type: StepItemType.Link,
      name: lang.navbar.review,
      to: reconciliationLink,
      endAdornment: reconciliationSteps > 0 && (
        <CountChip chip={reconciliationSteps} disabled={!canSeeTips} />
      ),
      disabled: !canSeeTips,
      tooltip: !canSeeTips ? <TipsPaywallTooltip /> : undefined,
      icon: <CheckCircleOutlineOutlined />,
    };

    const reportTab: LinkItemDetails = {
      id: "reports",
      type: StepItemType.Link,
      name: lang.navbar.report,
      to: Links.Report,
      icon: <DescriptionOutlined />,
    };

    const accountingIntegrationsTab: LinkItemDetails = {
      id: "accountingIntegrations",
      type: StepItemType.Link,
      name: lang.navbar.accountingIntegrations,
      to: Links.AccountingIntegrations,
      icon: <Mediation />,
    };

    const addressBookTab: LinkItemDetails = {
      id: "contacts",
      type: StepItemType.Link,
      name: lang.navbar.addressBook,
      to: Links.ContactsIdentified,
      icon: <ImportContactsOutlined />,
    };

    const rulesTab: LinkItemDetails = {
      id: "rules",
      type: StepItemType.Link,
      name: lang.navbar.rules,
      to: Links.Rules,
      icon: <FilterAltOutlined />,
    };

    // Accountant viewing business client OR business user
    if (isBusiness) {
      return [
        portfolioTab,
        {
          type: StepItemType.Subheading,
          name: lang.navbar.subheadings.integrations,
        },
        { ...integrationsTab, name: lang.navbar.cryptoIntegrations },
        accountingIntegrationsTab,
        {
          type: StepItemType.Subheading,
          name: lang.navbar.subheadings.reconciliation,
        },
        addressBookTab,
        rulesTab,
        transactionsTab,
        reviewTab,
        {
          type: StepItemType.Subheading,
          name: lang.navbar.subheadings.reports,
        },
        reportTab,
      ];
    }

    // Retail user or Accountant viewing retail client
    return [
      portfolioTab,
      integrationsTab,
      transactionsTab,
      reviewTab,
      reportTab,
    ];
  };

  const stepItems = getTabs();

  const isClientSelected = !!activeClient;
  // If the sidenav is enabled, and either its a single user, or an accountant viewing a client
  if ((isAccountant && isClientSelected) || !isAccountant) {
    stepItems.push(
      {
        type: StepItemType.Divider,
        name: "settingsDivider",
      },
      ...(isReferAFriendEnabled
        ? [
            {
              id: "referAFriend",
              type: StepItemType.Link,
              name: lang.navbar.referFriendsV2({
                reward: displayFiatValue({
                  value: REFERRAL_REWARD,
                  localCurrency: user?.planCurrency,
                  fractionDigits: 0,
                }),
              }),
              to: Links.ReferAFriend,
              icon: (
                <Favorite sx={{ fill: `${tokens.text.brand} !important` }} />
              ),
            } as LinkItemDetails,
          ]
        : []),
      {
        id: "clientSettings",
        type: StepItemType.Button,
        name: isManagingClients
          ? lang.navbar.clientSettings
          : lang.navbar.settings,
        onClick: () => {
          setSettingsModalOpen(true);
        },
        icon: <Settings />,
      },
    );
  }

  if (isDeveloperEnabled) {
    stepItems.push({
      id: "dev",
      type: StepItemType.Link,
      name: "Dev",
      to: Links.Developer,
      icon: <TerminalOutlined />,
    });
  }

  return stepItems;
}
