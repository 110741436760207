import { useEffect } from "react";

import { censorTextForPosthog } from "~/analytics/posthog";
import { LocalStorageKey } from "~/constants/enums";
import { useLocalStorage } from "~/hooks/useLocalStorage";

export function LocalPosthogCensor() {
  const [isCensoringEnabled] = useLocalStorage<boolean>(
    LocalStorageKey.LocalCensoringEnabled,
    false,
  );
  useEffect(() => {
    if (!isCensoringEnabled) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach(censorTextNode);
        } else if (mutation.type === "characterData") {
          censorTextNode(mutation.target);
        }
      });
    });

    // mask all the text nodes in the document & observe for changes
    censorTextNode(document.body);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [isCensoringEnabled]);

  return null;
}

function censorTextNode(node: Node | Element) {
  if (
    node.nodeType === Node.TEXT_NODE &&
    node.textContent &&
    node.textContent.trim() !== ""
  ) {
    const originalText = node.textContent;
    const parentElement = node.parentElement;
    const censoredText = censorTextForPosthog(originalText, parentElement);
    if (originalText !== censoredText) {
      node.textContent = censoredText;
    }
  } else if (
    node.nodeType === Node.ELEMENT_NODE &&
    "classList" in node &&
    !node.classList.contains("censored")
  ) {
    node.classList.add("censored");
    Array.from(node.childNodes).forEach(censorTextNode);
  }
}
