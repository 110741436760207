import { useExperimentVariant } from "~/analytics/posthog";
import { useLang } from "~/redux/lang";
import { FeatureFlag } from "~/types/enums";

/**
 * Returns the copy for the tax minimisation copy experiment depending on the variant
 * @returns {string | {prefix: string, suffix: string}} The copy for the tax minimisation copy experiment depending on the amount/variant
 */
export function useTaxMinimisationExperimentCopy() {
  const lang = useLang();
  const inYouCouldSaveXyzInTaxesExperiment = useExperimentVariant(
    FeatureFlag.TaxMinimisationCopy,
    "you-could-save-xyz-in-taxes",
  );

  const inYouCouldMinimiseYourTaxLiabilityByExperiment = useExperimentVariant(
    FeatureFlag.TaxMinimisationCopy,
    "you-could-minimise-your-tax-liability-by",
  );

  const inOurTaxMinimisationAlgorithmCouldSaveYouExperiment =
    useExperimentVariant(
      FeatureFlag.TaxMinimisationCopy,
      "our-tax-minimisation-algorithm-could-save-you",
    );

  // Return lang based on the experiment variant
  if (inYouCouldSaveXyzInTaxesExperiment) {
    return lang.report.summary.reviewTips.subTitle.youCouldSaveXyzInTaxes;
  }

  if (inYouCouldMinimiseYourTaxLiabilityByExperiment) {
    return lang.report.summary.reviewTips.subTitle
      .youCouldMinimiseYourTaxLiabilityBy;
  }

  if (inOurTaxMinimisationAlgorithmCouldSaveYouExperiment) {
    return lang.report.summary.reviewTips.subTitle
      .ourTaxMinimisationAlgorithmCouldSaveYou;
  }

  return lang.report.summary.reviewTips.subTitle.control;
}
