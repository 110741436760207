import { useState } from "react";
import TurnstileComponent, { useTurnstile } from "react-turnstile";
import styled from "styled-components/macro";

import { TURNSTILE_SITE_KEY } from "~/constants/constants";
import { useResolvedTheme } from "~/hooks/useTheme";
import { useLocale } from "~/redux/lang";
import { Theme } from "~/types/enums";

export interface TurnstileProps {
  onVerify: (token: string) => void;
}

export const Turnstile = ({ onVerify }: TurnstileProps) => {
  const turnstile = useTurnstile();
  const locale = useLocale();
  const theme = useResolvedTheme();
  const [isInteractive, setIsInteractive] = useState(false);

  const handleVerify = (token: string) => {
    console.info("[Turnstile] Verification successful, token received", {
      tokenLength: token.length,
    });
    onVerify(token);
  };

  const handleExpire = () => {
    console.info("[Turnstile] Token expired");
    turnstile.reset();
  };

  return (
    <Placement $visible={isInteractive}>
      <TurnstileComponent
        sitekey={TURNSTILE_SITE_KEY}
        onVerify={handleVerify}
        onExpire={handleExpire}
        onBeforeInteractive={() => {
          console.info("[Turnstile] Before interactive event triggered");
          setIsInteractive(true);
        }}
        onError={(error: any) => {
          console.error("[Turnstile] Error occurred", error);
        }}
        onLoad={() => {
          console.info("[Turnstile] Component loaded successfully");
        }}
        onSuccess={handleVerify}
        onUnsupported={() => {
          console.info("[Turnstile] Unsupported browser detected");
        }}
        onAfterInteractive={() => {
          console.info("[Turnstile] After interactive event triggered");
        }}
        theme={theme === Theme.Dark ? "dark" : "light"}
        language={locale}
        appearance="interaction-only"
        refreshExpired="auto"
        fixedSize={false}
        size="flexible"
      />
    </Placement>
  );
};

const Placement = styled.div<{ $visible: boolean }>`
  margin: 0 auto;
  display: flex;
  width: 100%;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
  height: ${({ $visible }) => ($visible ? "auto" : "0")};
  justify-content: center;

  > div {
    width: 100%;
  }

  iframe {
    width: 100%;
  }
`;
