import { Close, OpenInNew } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { type ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { TransactionActionRowV2 } from "~/components/transactions/action-row/actionRow";
import { type ActionTableOptions } from "~/components/transactions/action-row/context/actionTableOptions";
import {
  FilterProvider,
  getTransactionPageLink,
  useTransactionFilter,
} from "~/components/transactions/filter-bar/FilterContext";
import { FilterPagination } from "~/components/transactions/filter-bar/FilterPagination";
import { TransactionsTableWithFilters } from "~/components/transactions/TransactionsTableWithFilters";
import { ErrorFallback } from "~/components/ui/error-boundary/ErrorBoundaryWrapper";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TertiaryButtonLink } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useGetActionsQuery } from "~/state/actions";
import { Align } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

import { useIsEmbedded } from "../../hooks/useIsEmbedded";

type TransactionTableModalProps = {
  filter: FilterQuery | undefined;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  row?: ActionTableOptions["ActionRow"];
  title?: ReactNode;
  headerAction?: ReactNode;
  footerAction?: ReactNode;
  chips?: { key: string; title: string; value: ReactNode }[];
  customEmptyMessage?: string;
};

/**
 * Use this if you already have everything you need to start loading txs, modal,
 *  filter provider and error boundary comes included
 */
export function TransactionTableModal(props: TransactionTableModalProps) {
  const { isOpen, setIsOpen, filter } = props;

  return (
    <Dialog
      open={isOpen}
      maxWidth="xl"
      onClose={() => {
        setIsOpen(false);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <FilterProvider initialState={{ filter }}>
          <TransactionTableModalInner {...props} />
        </FilterProvider>
      </ErrorBoundary>
    </Dialog>
  );
}

/**
 * Use this if you need some extra state before the table can start loading,
 * BYO modal, filter provider and error boundary
 */
export function TransactionTableModalInner(props: TransactionTableModalProps) {
  const { filter, footerAction } = props;
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const { tokens } = useDesign();
  const link = getTransactionPageLink({ state: { filter } });

  return (
    <>
      <TransactionTableModalContents {...props} />
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "0.5rem",
          backgroundColor: tokens.elevation.low,
          padding: "0.5rem 1rem",
          flexWrap: "wrap",
        }}
      >
        <TertiaryButtonLink
          to={link}
          target={isEmbedded ? "_self" : "_blank"}
          rel="noopener noreferrer"
          size="small"
          endIcon={<OpenInNew />}
        >
          {lang.transactionTableModal.viewTxTable}
        </TertiaryButtonLink>
        {footerAction}
      </DialogActions>
    </>
  );
}

function TransactionTableModalContents({
  title,
  headerAction,
  chips,
  row = TransactionActionRowV2,
  setIsOpen,
  customEmptyMessage,
}: TransactionTableModalProps) {
  const { tokens } = useDesign();
  const lang = useLang();

  const {
    state: { count },
  } = useTransactionFilter();
  const query = useGetActionsQuery();
  const totalFilteredCount = query.data?.total;
  const moreThanOnePage = totalFilteredCount && totalFilteredCount > count;

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: tokens.elevation.medium,
          padding: "0.5rem 1rem",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          gap="0.5rem"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            gap="0.5rem"
            flexGrow={1}
          >
            <Box>
              {title ? (
                title
              ) : (
                <Typography
                  variant="Metropolis/Header/H4"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {lang.transactionTableModal.defaultTitle({
                    count: totalFilteredCount ?? 0,
                  })}
                </Typography>
              )}
              {chips ? (
                <Box
                  display="flex"
                  alignItems="center"
                  my="0.25rem"
                  flexWrap="wrap"
                >
                  {chips?.map((chip) => (
                    <Box
                      key={chip.key}
                      display="flex"
                      alignItems="center"
                      gap="0.25rem"
                      mr="1.5rem"
                    >
                      <Typography
                        variant="Metropolis/Caption/Medium/Regular"
                        color={tokens.text.low}
                      >
                        {chip.title}
                      </Typography>
                      {chip.value}
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Box>
            {headerAction}
          </Box>
          <Box
            height="100%"
            display="flex"
            alignItems={{ xs: "flex-start", md: "center" }}
          >
            <TextIconButton
              aria-label="close"
              onClick={handleModalClose}
              size="small"
            >
              <Close />
            </TextIconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
          maxWidth: "100%",
          width: "100vw",
          bgcolor: tokens.elevation.low,
        }}
      >
        <Box
          minHeight="25rem"
          maxHeight="50vh"
          overflow="auto"
          width="100%"
          borderTop={`1px solid ${tokens.border.neutral.default}`}
          borderBottom={`1px solid ${tokens.border.neutral.default}`}
          bgcolor={tokens.elevation.low}
        >
          <TransactionsTableWithFilters
            isCountEnabled={false}
            showFilterBar={false}
            enableVirtualiser={false}
            disablePaginationFooter
            disableBorder
            disableHeader
            row={row}
            disableResetFilters
            customEmptyMessage={customEmptyMessage}
          />
        </Box>
        {moreThanOnePage ? (
          <Box
            py="0.5rem"
            px="0.75rem"
            borderBottom={`1px solid ${tokens.border.neutral.default}`}
          >
            <FilterPagination align={Align.Right} />
          </Box>
        ) : null}
      </DialogContent>
    </>
  );
}
