import { type Plan } from "@ctc/types";

export function hashPlanChangeEvent({
  newPlan,
  oldPlan,
}: {
  newPlan: Plan;
  oldPlan: Plan;
}): string {
  return `${oldPlan}__${newPlan}`;
}

export function unhashPlanChangeEvent(hashed: string): {
  newPlan: Plan;
  oldPlan: Plan;
} {
  const [oldPlan, newPlan] = hashed.split("__");
  return { oldPlan: oldPlan as Plan, newPlan: newPlan as Plan };
}
