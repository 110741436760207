import { useEffect } from "react";

import { BoxSpinner } from "~/components/ui/Spinner";
import { useUser } from "~/redux/auth";
import { useSetUserNotificationMutation } from "~/state/settings";
import { NotificationType } from "~/types/enums";

export function OptInSupportAccess() {
  const user = useUser();

  // Update the logged in user's user.notifications.optInSupportAccess settings upon visiting this page
  const mutation = useSetUserNotificationMutation({ authUser: true });

  useEffect(() => {
    // Once the user is loaded, update their notification settings
    if (user) {
      mutation.mutate({ [NotificationType.OptInSupportAccess]: true });
    }
  }, [user]);

  // User will be redirected to the settings page after the mutation is successful,
  // so just show a spinner while the mutation is in progress
  return <BoxSpinner />;
}
