import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { unlock } from "~/redux/auth";
import { Links } from "~/types/enums";

export const UserUnlockRedirect = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(useLocation().search);
  const token = urlParams.get("token") || "";
  const result = <Navigate to={Links.Login} replace />;
  if (!token) {
    return result;
  }

  dispatch(unlock({ token }));
  return result;
};
