import { Button } from "@mui/material";
import { darken, lighten } from "polished";
import { type ComponentPropsWithRef } from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import styled from "styled-components/macro";

const styling = css<{ active?: boolean; "aria-controls"?: string | undefined }>`
  && {
    color: ${({ theme }) => theme.tokens.text.brand};
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 1rem;
    text-overflow: ellipsis;
    word-break: break-word;

    ${(props) =>
      // If this button is active or has an aria-controls attribute
      // this means its controlling some menu thats open, so we need to
      // show it in the active state
      props.active || props["aria-controls"]
        ? css`
            background: ${props.theme.tokens.background.brand.pressed};
          `
        : ""}
  }

  & .MuiButton-sizeMedium {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  & .MuiButton-sizeSmall {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.tokens.background.brand.hover};
  }

  &.Mui-focusVisible {
    background-color: ${({ theme }) => theme.tokens.background.brand.pressed};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.text.disabled};
  }
`;

export const TextButton = styled(Button)<{ active?: boolean }>`
  ${styling}
`;

export const TextButtonLink = styled(
  (
    props: ComponentPropsWithRef<typeof Link> &
      ComponentPropsWithRef<typeof Button>,
  ) => {
    // This is a hack so it doesnt error
    // Props is both a mix of Link + Button
    // Just changing TextButton to be a button, since
    // It's not correctly passing the types, but it still works.
    const Button1 = TextButton as typeof Button;
    return <Button1 component={Link} {...props} />;
  },
)`
  ${styling}
  &:hover {
    background-color: transparent;
    color: ${({ theme }) => lighten(0.1, theme.tokens.text.brand)};
  }
  &:active {
    color: ${({ theme }) => darken(0.1, theme.tokens.text.brand)};
  }
`;
