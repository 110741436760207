import { CurrencySourcePlatform } from "@ctc/types";
import { Help } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Chip,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import {
  bindPopper,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import type * as React from "react";
import { type SyntheticEvent, useMemo, useRef, useState } from "react";

import {
  ListboxComponent,
  renderGroup,
} from "~/components/transactions/AutocompleteListboxComponent";
import {
  currencyGroupSort,
  filter,
} from "~/components/transactions/CurrencyComboBox";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { useDesign } from "~/hooks/useTheme";
import { displayCurrency } from "~/lib/index";
import { useLang } from "~/redux/lang";
import { useCurrencySearchResults } from "~/state/currencies";
import { type CurrencyIdentifier } from "~/types/index";

export const TickerOverride = ({
  value,
  setValue,
}: {
  value: CurrencyIdentifier[] | null;
  setValue: (value: CurrencyIdentifier[]) => void;
}) => {
  const { tokens } = useDesign();
  const [input, setInput] = useState<string | undefined>("");
  const lang = useLang();
  const containerRef = useRef();

  const currenciesSearchQuery = useCurrencySearchResults(input);
  const {
    data: { currencies },
    isSearching,
  } = currenciesSearchQuery;

  const optionsResolved = useMemo(() => {
    return currencies.sort(currencyGroupSort);
  }, [currencies]);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: CurrencyIdentifier[],
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setValue([...newValue]);
    setInput("");
  };

  const popupState = usePopupState({
    variant: "popper",
    popupId: "tickerInfo",
  });

  return (
    <>
      <Typography
        variant="Metropolis/Body/Light"
        style={{
          marginRight: "0.125rem",
        }}
        display="inline"
      >
        {lang.manualCSV.manualSelectCurrencies}
      </Typography>
      <ClickAwayListener
        onClickAway={() => {
          popupState.close();
        }}
      >
        <Box component="span">
          <TextIconButton
            size="small"
            {...bindTrigger(popupState)}
            color={popupState.isOpen ? "primary" : "default"}
          >
            <Help fontSize="inherit" />
          </TextIconButton>
          <Popper
            {...bindPopper(popupState)}
            container={containerRef.current}
            placement="right-end"
            transition
            style={{ zIndex: 2000 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={250}>
                <Paper>
                  <Box p="1rem" maxWidth="20rem">
                    <Typography variant="Metropolis/Body/Light">
                      {lang.manualCSV.tickerHelper}
                    </Typography>
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
      <Box mt="0.5rem">
        <Autocomplete
          fullWidth
          multiple
          loading={currenciesSearchQuery?.isLoading || isSearching}
          options={optionsResolved}
          noOptionsText={lang.manualCSV.noOptions}
          onChange={handleChange}
          getOptionLabel={(option) => option.id}
          value={value || []}
          inputValue={input}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          ListboxComponent={
            ListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >
          }
          filterOptions={(options, params) => filter(options, params)}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Box display="inline-flex" alignItems="center">
                <Box component="span" pr="2px">
                  <CurrencyLogo currency={option} />
                </Box>
                <Typography variant="Metropolis/Body/Light">{`${displayCurrency(
                  option.name,
                  24,
                  20,
                )} (${displayCurrency(option.symbol, 16, 14)})`}</Typography>
              </Box>
            </Box>
          )}
          groupBy={(option) =>
            [
              CurrencySourcePlatform.CoinGecko,
              CurrencySourcePlatform.Ethereum,
              CurrencySourcePlatform.BinanceSmartChain,
            ].includes(option.source)
              ? lang.manualCSV.cryptoGroup
              : option.source === CurrencySourcePlatform.Fiat
                ? lang.manualCSV.fiatGroup
                : option.source === CurrencySourcePlatform.Manual
                  ? lang.manualCSV.manualGroup
                  : ""
          }
          renderGroup={renderGroup}
          renderTags={(value: CurrencyIdentifier[], getTagProps) => (
            <>
              {value.map((v, index) => (
                <Chip
                  size="small"
                  {...getTagProps({ index })}
                  key={v.id}
                  label={v.symbol}
                  avatar={<CurrencyLogo currency={v} width={18} height={18} />}
                />
              ))}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              InputLabelProps={{
                style: { color: tokens.text.default, fontWeight: 400 },
              }}
              label={lang.manualCSV.selectCurrencies}
              fullWidth
              style={{ backgroundColor: tokens.background.input.default }}
              onChange={(e) => {
                setInput(e.target.value);
              }}
            />
          )}
        />
      </Box>
    </>
  );
};
