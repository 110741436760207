import {
  Trade,
  type AiModel,
  type AiProvider,
  type Blockchain,
} from "@ctc/types";
import {
  type QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";

import { useFeatureFlag } from "~/analytics/posthog";
import { type ERPProvider } from "~/components/settings-modal/views/enums";
import { displayStsSuccessMessage } from "~/components/transactions/edit/STSOptOut";
import { FilterActionType } from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { displayMessage } from "~/components/ui/Toaster";
import { useAiContext } from "~/contexts/AiContext";
import { getActionRowKey } from "~/lib/getActionRowKey";
import { ActionDefinitions, TradeInfo } from "~/lib/tradeTypeDefinitions";
import { setUpdateBestActiveUser, useBestUser, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useSelector } from "~/redux/useSelector";
import * as actionsApi from "~/services/actions";
import {
  atomicUpdateTransaction,
  bulkOp,
  generateTransferForAction,
  postActionsQuery,
  type PostActionsQueryResponse,
  syncToErp,
} from "~/services/actions";
import { HttpError } from "~/services/core";
import { markCurrencyAsNFT } from "~/services/currencies";
import { useReconciliationItems } from "~/services/reconciliation";
import { updateShowSpamTransactions } from "~/services/settings";
import {
  getTransactionFilterOptions,
  groupingCheck,
  isGroupedTrade,
} from "~/services/transactions";
import { fetchEntitiesQuery, getEntityByAddress } from "~/state/entities";
import { fetchErpAvailableAccountsQuery } from "~/state/erp";
import {
  getFilterSessionTransactionIds,
  useBulkUpdateFilterSessionTransactionIds,
  useUpdateFilterSessionTransactionIds,
} from "~/state/filterSession";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import {
  reconciliationIssueQueryKeys,
  refreshReconciliationPayload,
} from "~/state/reconciliation";
import { fetchEnabledRulesQuery, ruleKeys } from "~/state/rules";
import {
  BulkOperations,
  DisplayMessage,
  ErpSyncStatus,
  FeatureFlag,
  Features,
  FilterOperators,
  GroupedTrade,
  LockPeriodTxStatus,
  Sort,
  TaxOutcomeType,
} from "~/types/enums";
import {
  type ActionRow,
  type ActionType,
  type BulkIgnorableWarning,
  type CurrencyIdentifier,
  type Entity,
  type Entries,
  type ExchangeEntity,
  type ExchangeOption,
  type FilterQuery,
  type ManualTransactionEntry,
  type SourceFilterOption,
} from "~/types/index";

type ListQueryOptions = {
  sort?: Sort;
  page?: number;
  count?: number;
  id?: string;
  showAssociated?: boolean;
};
export const actionKeys = {
  all: () => ["actions"] as const,

  // lists
  lists: () => [...actionKeys.all(), "lists"] as const,
  list: ({ sort, page, count, id, showAssociated }: ListQueryOptions) =>
    [...actionKeys.lists(), { sort, page, count, id, showAssociated }] as const,
  query: (query: {
    sort?: string | null | undefined;
    count?: number | null | undefined;
    page?: number | null | undefined;
    filter?: FilterQuery | undefined;
    viewInContextId?: string | null | undefined;
    showSpamTransactions: boolean | undefined;
    hideLocked?: boolean | undefined;
  }) => [...actionKeys.lists(), "query", query] as const,
  actionsCount: () => [...actionKeys.lists(), "actionsCount"],
  groupedActionsCount: () => [...actionKeys.lists(), "groupedActionsCount"],
  count: (filter?: FilterQuery) =>
    [...actionKeys.lists(), "count", filter] as const,

  // individual actions
  action: (actionId: string) =>
    [...actionKeys.all(), "single", actionId] as const,
  breakdown: (actionId: string) =>
    [...actionKeys.action(actionId), "breakdown"] as const,
  previousVersions: (actionId: string) =>
    [...actionKeys.action(actionId), "history"] as const,
  balances: (actionId: string) =>
    [...actionKeys.action(actionId), "balances"] as const,
  comments: (actionId: string) =>
    [...actionKeys.action(actionId), "comments"] as const,
  transactions: (actionId: string) =>
    [...actionKeys.action(actionId), "transactions"] as const,
  similar: (actionId: string) =>
    [...actionKeys.action(actionId), "similar"] as const,
  groupingCheck: (actionIds: string[]) =>
    [...actionKeys.all(), "groupingCheck", actionIds.join("-")] as const,
  explain: ({
    actionId,
    provider,
    model,
    userPrompt,
    systemPrompt,
  }: {
    actionId: string;
    provider: AiProvider;
    model: AiModel;
    userPrompt: string;
    systemPrompt: string;
  }) =>
    [
      ...actionKeys.action(actionId),
      provider,
      model,
      userPrompt,
      systemPrompt,
      "explain",
    ] as const,
};

export const useGetActionsCountQuery = (options?: { enabled: boolean }) => {
  const user = useUser();
  return useQuery({
    queryKey: actionKeys.actionsCount(),
    queryFn: async () => {
      const res = await actionsApi.getActionsCount();

      if (res.error) {
        const keys = actionKeys.actionsCount();
        throw new HttpError(res, keys);
      }

      return res.data;
    },
    staleTime: Infinity,
    enabled: !!user?.country,
    ...options,
  });
};

export const useGetCurrentQueryKey = () => {
  const user = useBestUser();
  const {
    state: { filter, sort, count, page, viewInContextId, hideLocked },
  } = useTransactionFilter();
  const actionsQuery = {
    sort,
    count,
    page,
    viewInContextId,
    filter,
    showSpamTransactions: user?.showSpamTransactions,
    hideLocked,
  };
  return actionKeys.query(actionsQuery);
};

function addFilterSessionTransactionIds(
  filter: FilterQuery | undefined,
): FilterQuery | undefined {
  // Add filter session txs
  const filterEditSessionTransactionIds =
    getFilterSessionTransactionIds(filter);
  return filterEditSessionTransactionIds?.length
    ? {
        type: FilterOperators.Or,
        rules: [
          {
            type: FilterOperators.TransactionId,
            value: filterEditSessionTransactionIds,
            showAssociated: 0,
          },
          ...(filter ? [filter] : []),
        ],
      }
    : filter;
}

function addFilterSettings({
  filter,
  hideLocked,
}: {
  filter: FilterQuery | undefined;
  hideLocked?: boolean;
}): FilterQuery | undefined {
  if (!hideLocked) {
    return filter;
  }

  // Hide locked txs
  return {
    type: FilterOperators.And,
    rules: [
      {
        type: FilterOperators.LockPeriod,
        value: LockPeriodTxStatus.NotLocked,
      },
      ...(filter ? [filter] : []),
    ],
  };
}

/**
 * Used by the in-context uncategorised reconciliation
 * Gets the first 250 uncategorised transactions in asc orders
 */
export const useGetActionsQueryNoContext = (
  actionsQuery: Parameters<typeof postActionsQuery>[0] & {
    // used in the query key
    showSpamTransactions: boolean | undefined;
  },
) => {
  const queryKey = actionKeys.query(actionsQuery);

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await postActionsQuery(actionsQuery);
      if (res.error) {
        throw new HttpError(res, ["GetUncategorisedActionsQuery"]);
      }
      return res;
    },
  });
  return query;
};

export const useGetActionsQuery = () => {
  const actionsQueryKey = useGetCurrentQueryKey();
  const {
    dispatch,
    state: {
      filter: unProcessedFilter,
      sort,
      count,
      page,
      viewInContextId,
      contextIdType,
      hideLocked,
    },
  } = useTransactionFilter();
  const queryClient = useQueryClient();
  const user = useBestUser();
  // get the filters from the context

  const sortOrderRef = useRef<Record<string, Record<string, string[]>>>({});

  useEffect(() => {
    return () => {
      sortOrderRef.current = {};
    };
  }, []);

  const query = useQuery({
    queryKey: actionsQueryKey,
    queryFn: async () => {
      const filter = addFilterSettings({
        filter: addFilterSessionTransactionIds(unProcessedFilter),
        hideLocked,
      });
      const res = await postActionsQuery({
        filter,
        sort,
        count,
        page,
        viewInContextId,
        contextIdType,
      });

      if (res.error) {
        throw new HttpError(res, ["getActions"]);
      }

      if (sort !== Sort.DateAscending && sort !== Sort.DateDescending) {
        // not sorted by timestamp, dont worry about persistent sort
        return res.data;
      }

      const serialisedActionsQueryKey = JSON.stringify(actionsQueryKey);

      // store the sort order of every action grouped by timestamp
      const tempSortOrder: Record<string, string[]> =
        sortOrderRef.current[serialisedActionsQueryKey] ?? {};
      res.data?.transactions.forEach((action) => {
        const timestamp = action.timestamp as unknown as string;
        const existingSortOrderForTimestamp = tempSortOrder[timestamp] ?? [];
        const stableActionId = getActionRowKey(action);
        if (existingSortOrderForTimestamp.includes(stableActionId)) {
          // we already have a sort for this, dont update it, keep it stable
          return;
        }
        tempSortOrder[timestamp] = [
          ...existingSortOrderForTimestamp,
          stableActionId,
        ];
      });

      sortOrderRef.current = {
        ...sortOrderRef.current,
        [serialisedActionsQueryKey]: tempSortOrder,
      };

      return {
        ...res.data,

        // maintain sort
        transactions: res.data.transactions.sort((actionA, actionB) => {
          const timestampA = actionA.timestamp as unknown as string;
          const timestampB = actionB.timestamp as unknown as string;
          if (timestampA !== timestampB) {
            // not the same timestamp, default sorting
            return 0;
          }
          // same timestamp, use prev sorting
          const prevOrder = sortOrderRef.current?.[serialisedActionsQueryKey];
          if (!prevOrder) {
            // should never get here
            return 0;
          }
          const orderedStableActionIds = prevOrder[timestampA];
          if (!orderedStableActionIds) {
            // should never get here
            return 0;
          }
          const positionA = orderedStableActionIds.indexOf(
            getActionRowKey(actionA),
          );
          const positionB = orderedStableActionIds.indexOf(
            getActionRowKey(actionB),
          );
          return positionA - positionB;
        }),
      };
    },
    staleTime: Infinity,
    notifyOnChangeProps: "all",
  });

  useEffect(() => {
    if (query.data) {
      const { pageNumber } = query.data;
      if (!viewInContextId || !pageNumber) {
        // the response matches the page we requested
        return;
      }
      // its a view in context result, which tells us we requested
      // acitonId `1234` and the page its on is in the response
      // so we can cache this response this as if the requested that page
      const actionsQuery = {
        sort,
        count,
        page: pageNumber,
        viewInContextId: undefined,
        filter: unProcessedFilter,
        showSpamTransactions: user?.showSpamTransactions,
      };
      // add to query cache
      queryClient.setQueryData(actionKeys.query(actionsQuery), query.data);
      // change it so we are on the correct page
      dispatch({
        type: FilterActionType.GoToPage,
        page: pageNumber,
      });
    }
  }, [query.data]);

  return query;
};

function entries<T extends Record<string, unknown>>(object: T) {
  return Object.entries(object) as Entries<typeof object>;
}

/**
 * Merges entities into user filter where the options are source options,
 * eg: "source".
 */
function getEntityMergedSourceFilterOptions(
  options: SourceFilterOption[] | undefined,
  entities: Entity[],
): SourceFilterOption[] {
  if (!options) {
    return [];
  }
  const entitiesUnmapped = entities.reduce((acc, entity) => {
    return new Map(acc).set(entity._id, entity);
  }, new Map<string, Entity>());

  const sources = options.map((source) => {
    const entity = entities.find((entity) => {
      const exchange = entity as ExchangeEntity;
      if (exchange.ref === source.id) {
        entitiesUnmapped.delete(exchange._id);
        return entity;
      }
    });

    if (entity) {
      return {
        id: entity._id,
        name: entity.displayName,
        type: entity.type,
      };
    }

    return source;
  });

  return [
    ...sources,
    ...Array.from(entitiesUnmapped.values()).map((entity) => ({
      id: entity._id,
      name: entity.displayName,
      type: entity.type,
    })),
  ];
}

/**
 * Merges entities into user filter where the options are exchange options,
 * eg: "to", "from".
 */
function getEntityMergedExchangeOptions(
  options: ExchangeOption[] | undefined,
  entities: Entity[],
): ExchangeOption[] {
  if (!options) {
    return [];
  }

  const usedEntities = new Set<Entity>();

  const exchanges = options.map((source) => {
    const entity = getEntityByAddress(
      source.label,
      source.blockchain as Blockchain,
      entities,
    );

    // Case where we have an address which belongs to an entity
    if (entity && source.blockchain) {
      usedEntities.add(entity);
      return {
        ...source,
        entity,
      };
    }

    // Case where we have an exchange or exchange-like item that maps to an entity
    if (entity) {
      return {
        label: entity._id,
        name: entity.displayName,
        entity,
      };
    }

    return source;
  });

  const entitiesFormatted = Array.from(usedEntities).flatMap((entity) => {
    if (
      !exchanges.find(
        (exchange) =>
          exchange.label === entity._id && exchange.name === entity.displayName,
      )
    ) {
      return {
        label: entity._id,
        name: entity.displayName,
        entity,
      };
    }

    return [];
  });

  return [...exchanges, ...entitiesFormatted];
}

export function useGetFilterOptionsQuery() {
  const queryClient = useQueryClient();

  const bestUser = useBestUser();
  const recOptions = useReconciliationItems();
  const chartOfAccountsEnabled = bestUser?.features[Features.ERP];
  const rulesEnabled = bestUser?.features[Features.Rules];

  return useQuery({
    queryKey: filterOptionKeys.list(),
    queryFn: async () => {
      // load all the filters (source, to, from etc)
      const resFilterOptions = await getTransactionFilterOptions();

      // load the entities using async method
      const { entities } = await fetchEntitiesQuery({ queryClient });
      const erpAvailableAccounts = chartOfAccountsEnabled
        ? (await fetchErpAvailableAccountsQuery({
            queryClient,
          })) ?? []
        : [];

      const rules = rulesEnabled
        ? await fetchEnabledRulesQuery({ queryClient })
        : [];

      if (resFilterOptions.error) {
        throw new Error(resFilterOptions.msg);
      }

      // get out all the filters except source, we want to shove entities
      // into the source
      const { source, from, to, txMethodId, ...filterOptions } =
        resFilterOptions.data;

      const category = [
        ...Object.values(Trade).filter((category) => {
          const { isFilterable = true, isOutdated } = TradeInfo[category];
          return isFilterable && !isOutdated;
        }),
        ...Object.values(GroupedTrade).filter(
          (category) => ActionDefinitions[category].isFilterable,
        ),
      ];

      const warningsFromEnabledRecOptions = Object.values(recOptions)
        .filter((issueType) => !issueType.hidden)
        .flatMap((issueType) =>
          issueType.warning ? { type: issueType.warning } : [],
        );

      // @todo - types are implicit here not explicit
      return {
        // all the filter options except the ones that require post processing
        ...filterOptions,
        // Filter out hidden grouped trade types
        category,
        // merge entities into to/from.
        from: getEntityMergedExchangeOptions(from, entities),
        to: getEntityMergedExchangeOptions(to, entities),
        // merge entities into source.
        source: getEntityMergedSourceFilterOptions(source, entities),
        taxOutcomeType: Object.values(TaxOutcomeType),
        // add in the warnings from the alerts
        warning: warningsFromEnabledRecOptions,
        txFunctionSignature: txMethodId,
        // these are behind a feature flag
        ...(chartOfAccountsEnabled && {
          erpAssetAccount: erpAvailableAccounts,
          erpGainsAccount: erpAvailableAccounts,
          erpLoanAccount: erpAvailableAccounts,
          erpPnlAccount: erpAvailableAccounts,
          erpCashAccount: erpAvailableAccounts,
          erpSyncStatus: Object.values(ErpSyncStatus).filter(
            (status) => status !== ErpSyncStatus.SyncNoOp,
          ),
        }),
        ...(rulesEnabled && {
          rule: rules,
        }),
        // Allow users to find all actions that have (or don't have) fees
        includesFee: [true, false],
      };
    },
    staleTime: Infinity,
  });
}

function getTxIdsFromActionRow(actionRow: ActionRow) {
  return [...actionRow.incoming, ...actionRow.outgoing, ...actionRow.fees].map(
    (tx) => tx._id,
  );
}
function optimisticReplaceActionRow({
  originalActionRowId,
  actionRows,
  key,
  queryClient,
}: {
  originalActionRowId: string;
  actionRows: ActionRow[];
  key: ReturnType<(typeof actionKeys)["query"]>;
  queryClient: QueryClient;
}) {
  // get all the tx ids in the new action rows
  const newActionsTxIdSet = new Set(actionRows.flatMap(getTxIdsFromActionRow));
  return optimisticUpdateActionRow({
    key,
    update: (cachedActionRow) => {
      if (cachedActionRow._id === originalActionRowId) {
        // replace the row that was edited, with the new action rows
        return actionRows;
      }
      // if we edited a Buy action for example, and now it matches with the Sell action
      // we will have got the new Trade action result from the backend that will contain both of Buy/Sell txids
      // we need to find the Sell action it matched with, and remove it
      const cacheActionRowTxIds = getTxIdsFromActionRow(cachedActionRow);
      if (cacheActionRowTxIds.some((txId) => newActionsTxIdSet.has(txId))) {
        return [];
      }
      return cachedActionRow;
    },
    queryClient,
  });
}
/**
 * Update an action row for a given action key
 *
 * @param key the action row key to update
 * @param update the map function for updating
 * @param queryClient the query client
 * @returns roll back for onError
 */
export const optimisticUpdateActionRow = async ({
  key,
  update,
  queryClient,
}: {
  key: ReturnType<(typeof actionKeys)["query"]>;
  update: (actionRow: ActionRow) => ActionRow | ActionRow[];
  queryClient: QueryClient;
}) => {
  const previous = queryClient.getQueryData<PostActionsQueryResponse>(key);

  // Cancel outgoing refetch (so they don't overwrite optimistic update).
  await queryClient.cancelQueries({ queryKey: key });

  // Optimistically update to the new value
  queryClient.setQueryData<PostActionsQueryResponse>(key, (old) => {
    if (!old) {
      return old;
    }
    return {
      ...old,
      transactions: old.transactions.flatMap((action) => {
        const newAction = update(action);
        if (Array.isArray(newAction)) {
          return newAction;
        }
        return [newAction];
      }),
    };
  });
  return { previous };
};

// @url https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
export const useUpdateActionReviewedMutation = () => {
  const queryClient = useQueryClient();
  const key = useGetCurrentQueryKey();
  const updateFilterSessionTransactionIds =
    useUpdateFilterSessionTransactionIds();

  return useMutation({
    mutationFn: async ({
      actionRow,
      reviewed,
    }: {
      actionRow: ActionRow;
      reviewed: boolean;
    }) => {
      if (actionRow.isLocked) {
        throw new Error("Cannot update a locked action");
      }

      const transactions = [
        ...actionRow.incoming,
        ...actionRow.outgoing,
        ...actionRow.fees,
      ];
      updateFilterSessionTransactionIds(transactions.map((tx) => tx._id));
      const res = await atomicUpdateTransaction({
        actionId: actionRow._id,
        update: {
          updateTx: {
            payload: transactions.map((tx) => ({
              _id: tx._id,
              reviewed,
            })),
            applySts: false,
            createCategoryRule: false,
          },
        },
      });

      if (res.error) {
        throw new HttpError(res, ["updateActionReviewed"]);
      }

      return res.data;
    },
    onMutate: async (variables: {
      actionRow: ActionRow;
      reviewed: boolean;
    }) => {
      const actionId = variables.actionRow._id;
      return optimisticUpdateActionRow({
        key,
        update: (t) =>
          t._id === actionId ? { ...t, reviewed: variables.reviewed } : t,
        queryClient,
      });
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, action, context) => {
      if (context?.previous) {
        queryClient.setQueryData<PostActionsQueryResponse>(
          key,
          context.previous,
        );
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useDeleteActionMutation = () => {
  const queryClient = useQueryClient();
  const isRowLockedByActionId = useIsRowLockedByActionId();

  return useMutation({
    mutationFn: async (payload: { id: string }) => {
      if (isRowLockedByActionId(payload.id)) {
        throw new Error("Cannot update a locked action");
      }

      const res = await actionsApi.deleteAction(payload.id);

      if (res.error) {
        throw new HttpError(res, ["deleteAction"]);
      }

      return res.data;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useNewBulkOpMutation = () => {
  const queryClient = useQueryClient();
  const updateFilterSession = useBulkUpdateFilterSessionTransactionIds();
  return useMutation({
    mutationFn: async (payload: Parameters<typeof bulkOp>[0]) => {
      updateFilterSession();
      const res = await bulkOp(payload);
      if (res.error) {
        throw new HttpError(res, ["bulkOp"]);
      }
      return res.data;
    },
    throwOnError: true,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useNewBulkMarkAsReviewedMutation = () => {
  const queryClient = useQueryClient();
  const key = useGetCurrentQueryKey();
  const updateFilterSession = useBulkUpdateFilterSessionTransactionIds();
  return useMutation({
    mutationFn: async ({
      filter,
      reviewed,
    }: {
      filter: FilterQuery;
      reviewed: boolean;
    }) => {
      updateFilterSession();
      const res = await bulkOp({
        filter,
        operation: {
          type: BulkOperations.Patch,
          patch: {
            reviewed,
          },
        },
      });

      if (res.error) {
        throw new HttpError(res, ["bulkMarkAsReviewed"]);
      }

      return res.data;
    },
    onMutate: async (variables: { filter: FilterQuery; reviewed: boolean }) => {
      if (variables.filter.type !== FilterOperators.ActionId) {
        // TODO: mark as reviewed based on the filter query
        return;
      }
      const ids = new Set(variables.filter.value);
      return optimisticUpdateActionRow({
        key,
        update: (t) =>
          ids.has(t._id) ? { ...t, reviewed: variables.reviewed } : t,
        queryClient,
      });
    },
    onError: (err, action, context) => {
      if (context?.previous) {
        queryClient.setQueryData<PostActionsQueryResponse>(
          key,
          context.previous,
        );
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useNewBulkAddCommentMutation = () => {
  const queryClient = useQueryClient();
  const key = useGetCurrentQueryKey();
  const updateFilterSession = useBulkUpdateFilterSessionTransactionIds();
  return useMutation({
    mutationFn: async ({
      filter,
      comment,
    }: {
      filter: FilterQuery;
      comment: string;
    }) => {
      updateFilterSession();
      const res = await bulkOp({
        filter,
        operation: {
          type: BulkOperations.AddComment,
          comment,
        },
      });

      if (res.error) {
        throw new HttpError(res, ["bulkAddComment"]);
      }

      return res.data;
    },
    onMutate: async (variables: { filter: FilterQuery }) => {
      if (variables.filter.type !== FilterOperators.ActionId) {
        return;
      }
      const ids = new Set(variables.filter.value);
      return optimisticUpdateActionRow({
        key,
        update: (t) => (ids.has(t._id) ? { ...t, hasComments: true } : t),
        queryClient,
      });
    },
    onError: (err, action, context) => {
      if (context?.previous) {
        queryClient.setQueryData<PostActionsQueryResponse>(
          key,
          context.previous,
        );
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useNewBulkDeleteActionsMutation = () => {
  const queryClient = useQueryClient();
  const updateFilterSession = useBulkUpdateFilterSessionTransactionIds();
  return useMutation({
    mutationFn: async ({ filter }: { filter: FilterQuery }) => {
      updateFilterSession();
      const res = await bulkOp({
        filter,
        operation: {
          type: BulkOperations.Delete,
        },
      });

      if (res.error) {
        throw new HttpError(res, ["bulkDeleteActions"]);
      }

      return res.data;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useBulkUngroupActionsMutation = () => {
  const queryClient = useQueryClient();
  const isRowLockedByActionId = useIsRowLockedByActionId();

  return useMutation({
    mutationFn: async (payload: { ids: string[] }) => {
      if (payload.ids.some((id) => isRowLockedByActionId(id))) {
        throw new Error("Cannot update a locked action");
      }

      const res = await actionsApi.bulkUngroupActions(payload.ids);

      if (res.error) {
        throw new HttpError(res, ["bulkUngroupActions"]);
      }

      return res.data;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useBulkUndoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: { bulkEditId: string }) => {
      const res = await actionsApi.bulkUndo(payload);

      if (res.error) {
        throw new HttpError(res, ["bulkUndoActions"]);
      }

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reconciliationIssueQueryKeys.all(),
      });
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useNewBulkIgnoreActionsMutation = () => {
  const queryClient = useQueryClient();
  const updateFilterSession = useBulkUpdateFilterSessionTransactionIds();
  return useMutation({
    mutationFn: async ({ filter }: { filter: FilterQuery }) => {
      updateFilterSession();
      const res = await bulkOp({
        filter,
        operation: {
          type: BulkOperations.Ignore,
        },
      });

      if (res.error) {
        throw new HttpError(res, ["bulkIgnoreActions"]);
      }

      return res.data;
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useNewBulkRecategoriseActionsMutation = () => {
  const queryClient = useQueryClient();
  const updateFilterSession = useBulkUpdateFilterSessionTransactionIds();
  return useMutation({
    mutationFn: async ({
      filter,
      toActionType,
    }: {
      filter: FilterQuery;
      toActionType: ActionType;
    }) => {
      updateFilterSession();
      const res = await bulkOp({
        filter,
        operation: {
          type: BulkOperations.Recategorise,
          toActionType,
        },
      });
      if (res.error) {
        throw new HttpError(res, ["bulkRecategoriseActions"]);
      }
      return res.data;
    },
    onSuccess() {
      refreshReconciliationPayload(queryClient);
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useCreateActionCommentMutation = () => {
  const queryClient = useQueryClient();
  const key = useGetCurrentQueryKey();
  const isRowLockedByActionId = useIsRowLockedByActionId();

  return useMutation({
    mutationFn: async (payload: {
      actionId: string;
      comment: string;
      shouldInvalidateActions: boolean;
    }) => {
      if (isRowLockedByActionId(payload.actionId)) {
        throw new Error("Cannot update a locked action");
      }

      const res = await actionsApi.postActionComment(
        payload.actionId,
        payload.comment,
      );

      if (res.error) {
        throw new HttpError(res, ["createActionComment"]);
      }

      return res.data;
    },
    onMutate: async (variables) => {
      return optimisticUpdateActionRow({
        key,
        update: (t) =>
          t._id === variables.actionId ? { ...t, hasComments: true } : t,
        queryClient,
      });
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (
      err,
      action,
      context:
        | {
            previous: actionsApi.PostActionsQueryResponse | undefined;
          }
        | undefined,
    ) => {
      if (context?.previous) {
        queryClient.setQueryData<PostActionsQueryResponse>(
          key,
          context.previous,
        );
      }
      queryErrorHandler(err);
    },
    onSuccess(data, variables) {
      if (variables.shouldInvalidateActions) {
        queryClient.invalidateQueries({ queryKey: actionKeys.lists() });
      }
      queryClient.invalidateQueries({
        queryKey: actionKeys.action(variables.actionId),
      });
    },
  });
};

export const useUpdateActionCommentMutation = () => {
  const queryClient = useQueryClient();
  const isRowLockedByActionId = useIsRowLockedByActionId();
  return useMutation({
    mutationFn: async ({
      actionId,
      commentId,
      comment,
    }: {
      actionId: string;
      commentId: string;
      comment: string;
    }) => {
      if (isRowLockedByActionId(actionId)) {
        throw new Error("Cannot update a locked action");
      }
      const res = await actionsApi.editActionComment(
        actionId,
        commentId,
        comment,
      );

      if (res.error) {
        throw new HttpError(res, ["editActionComment"]);
      }

      return res.data;
    },
    onSuccess(data, variables) {
      return queryClient.invalidateQueries({
        queryKey: actionKeys.comments(variables.actionId),
      });
    },
  });
};

export const useDeleteActionCommentMutation = () => {
  const queryClient = useQueryClient();
  const key = useGetCurrentQueryKey();
  const isRowLockedByActionId = useIsRowLockedByActionId();

  return useMutation({
    mutationFn: async (payload: {
      actionId: string;
      commentId: string;
      shouldInvalidateActions: boolean;
    }) => {
      if (isRowLockedByActionId(payload.actionId)) {
        throw new Error("Cannot update a locked action");
      }
      const res = await actionsApi.deleteActionComment(
        payload.actionId,
        payload.commentId,
      );

      if (res.error) {
        throw new HttpError(res, ["deleteActionComment"]);
      }

      return res.data;
    },
    onMutate: async (variables) => {
      return optimisticUpdateActionRow({
        key,
        update: (t) =>
          t._id === variables.actionId ? { ...t, hasComments: false } : t,
        queryClient,
      });
    }, // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (
      err,
      action,
      context:
        | {
            previous: actionsApi.PostActionsQueryResponse | undefined;
          }
        | undefined,
    ) => {
      if (context?.previous) {
        queryClient.setQueryData<PostActionsQueryResponse>(
          key,
          context.previous,
        );
      }
      queryErrorHandler(err);
    },
    onSuccess(data, variables) {
      if (variables.shouldInvalidateActions) {
        queryClient.invalidateQueries({ queryKey: actionKeys.lists() });
      }
      queryClient.invalidateQueries({
        queryKey: actionKeys.action(variables.actionId),
      });
    },
  });
};

export const useUpdateActionRegroupMutation = () => {
  const queryClient = useQueryClient();
  const isRowLockedByActionId = useIsRowLockedByActionId();
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      if (isRowLockedByActionId(id)) {
        throw new Error("Cannot update a locked action");
      }
      const res = await actionsApi.regroupActions(id);

      if (res.error) {
        throw new HttpError(res, ["updateActionRegroup"]);
      }

      return res.data;
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useDuplicateActionMutation = () => {
  const queryClient = useQueryClient();
  const key = useGetCurrentQueryKey();
  const isRowLockedByActionId = useIsRowLockedByActionId();
  return useMutation({
    mutationFn: async (payload: { actionId: string }) => {
      if (isRowLockedByActionId(payload.actionId)) {
        throw new Error("Cannot update a locked action");
      }
      const res = await actionsApi.duplicateAction(payload.actionId);

      if (res.error) {
        throw new HttpError(res, ["duplicateActionMutation"]);
      }

      return res.data;
    },
    onSuccess(data, variables) {
      const { rows } = data;
      // Update the action lists, with the new action rows.
      optimisticReplaceActionRow({
        originalActionRowId: variables.actionId,
        actionRows: rows,
        key,
        queryClient,
      });
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useNewBulkUpdateCurrencyActionsMutation = () => {
  const queryClient = useQueryClient();
  const updateFilterSession = useBulkUpdateFilterSessionTransactionIds();
  return useMutation({
    mutationFn: async ({
      filter,
      fromCurrency,
      toCurrency,
      type,
    }: {
      filter: FilterQuery;
      fromCurrency?: CurrencyIdentifier | undefined;
      toCurrency: CurrencyIdentifier;
      type: BulkOperations.ChangeCurrency | BulkOperations.ChangeFeeCurrency;
    }) => {
      updateFilterSession();
      const res = await bulkOp({
        filter,
        operation: {
          type,
          fromCurrency,
          toCurrency,
        },
      });

      if (res.error) {
        throw new HttpError(res, ["bulkUpdateCurrencyActions"]);
      }

      return res.data;
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useNewBulkIgnoreWarningsMutation = () => {
  const queryClient = useQueryClient();
  const updateFilterSession = useBulkUpdateFilterSessionTransactionIds();
  return useMutation({
    mutationFn: async ({
      filter,
      warnings,
    }: {
      filter: FilterQuery;
      warnings: BulkIgnorableWarning[];
    }) => {
      updateFilterSession();
      const res = await bulkOp({
        filter,
        operation: {
          type: BulkOperations.IgnoreWarnings,
          warnings,
        },
      });

      if (res.error) {
        throw new HttpError(res, ["bulkIgnoreWarnings"]);
      }

      return res.data;
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useNewBulkLookupMarketPriceActionsMutation = () => {
  const queryClient = useQueryClient();
  const updateFilterSession = useBulkUpdateFilterSessionTransactionIds();
  return useMutation({
    mutationFn: async ({ filter }: { filter: FilterQuery }) => {
      updateFilterSession();
      const res = await bulkOp({
        filter,
        operation: {
          type: BulkOperations.LookUpMarketPrice,
        },
      });

      if (res.error) {
        throw new HttpError(res, ["bulkLookupMarketPriceActions"]);
      }

      return res.data;
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useCreateManualActionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: ManualTransactionEntry) => {
      const res = await actionsApi.createManualAction(payload);

      if (res.error) {
        throw new HttpError(res, ["bulkLookupMarketPriceActions"]);
      }

      return res.data;
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useActionBreakdownQuery = (id: string) => {
  return useQuery({
    queryKey: actionKeys.breakdown(id),
    queryFn: async () => {
      const res = await getActionBreakdown(id);
      return res;
    },
  });
};

async function getActionBreakdown(id: string) {
  const res = await actionsApi.getActionBreakdown(id);

  if (res.error) {
    throw new HttpError(res, ["getActionBreakdown"]);
  }

  return res.data;
}

export function useGetActionPreviousVersions(actionId: string) {
  return useQuery({
    queryKey: actionKeys.previousVersions(actionId),
    queryFn: async () => {
      const res = await actionsApi.previousVersions(actionId);
      if (res.error) {
        throw new HttpError(res, ["getActionHistory"]);
      }
      return res.data;
    },
  });
}

export const useActionBalancesQuery = (id: string) => {
  return useQuery({
    queryKey: actionKeys.balances(id),
    queryFn: async () => {
      const res = await getActionBalances(id);
      return res;
    },
  });
};

async function getActionBalances(id: string) {
  const res = await actionsApi.getActionBalances(id);

  if (res.error) {
    throw new HttpError(res, ["actionBalances"]);
  }

  return res.data;
}

export const useActionCommentsQuery = (id: string) => {
  return useQuery({
    queryKey: actionKeys.comments(id),
    queryFn: async () => {
      const res = await actionsApi.getActionComments(id);

      if (res.error) {
        throw new HttpError(res, ["actionComments"]);
      }

      return res.data;
    },
  });
};

export const useActionGroupingCheckQuery = (ids: string[]) => {
  return useQuery({
    queryKey: actionKeys.groupingCheck(ids.sort()),
    queryFn: async () => {
      const res = await groupingCheck(ids);

      if (res.error) {
        throw new HttpError(res, ["actionComments"]);
      }

      return res.data;
    },
  });
};

const filterOptionKeys = {
  all: () => [...actionKeys.all(), "filterOptions"] as const,
  list: () => [...filterOptionKeys.all(), "list"] as const,
};

export function useSelectableActionRows() {
  const actions = useGetActionsQuery();
  return (
    actions.data?.transactions.filter((row) => {
      return !isGroupedTrade(row.type);
    }) ?? []
  );
}

export const useGenerateTransferForActionMutation = () => {
  const queryClient = useQueryClient();
  const errorMessage = useSelector(
    (state) => state.lang.map.reconciliation.api.generateTransferError,
  );
  const key = useGetCurrentQueryKey();
  const isRowLockedByActionId = useIsRowLockedByActionId();
  const mutation = useMutation({
    mutationFn: async ({
      transactionId,
      actionId,
    }: {
      actionId: string;
      transactionId: string;
    }) => {
      if (isRowLockedByActionId(actionId)) {
        throw new Error("Cannot update a locked action");
      }
      const res = await generateTransferForAction(actionId, transactionId);

      if (res.error) {
        throw new HttpError(
          {
            ...res,
            msg: res.msg ?? errorMessage,
          },
          ["generateTransfer"],
        );
      }
      return res.data;
    },
    onSuccess(data, variables) {
      const { rows } = data;
      // Update the action lists, with the new action rows.
      optimisticReplaceActionRow({
        originalActionRowId: variables.actionId,
        actionRows: rows,
        key,
        queryClient,
      });
      refreshReconciliationPayload(queryClient);
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
  return mutation;
};

export const useAtomicUpdateTransactions = () => {
  const lang = useLang();
  const queryClient = useQueryClient();
  const isRowLockedByActionId = useIsRowLockedByActionId();

  const updateFilterSessionTransactionIds =
    useUpdateFilterSessionTransactionIds();

  return useMutation({
    mutationFn: async ({
      actionId,
      update,
      markAsNFT,
    }: Parameters<typeof atomicUpdateTransaction>[0] & {
      markAsNFT?: Record<string, boolean>;
    }) => {
      if (isRowLockedByActionId(actionId)) {
        throw new Error("Cannot update a locked action");
      }
      // keep displaying anything we are editing
      const keepDisplayingTxIds = update.updateTx.payload
        .map((payload) => payload._id)
        .filter(Boolean) as string[];
      updateFilterSessionTransactionIds(keepDisplayingTxIds);

      const nftUpdates = Object.entries(markAsNFT ?? {});

      const shouldCallAtomicUpdate =
        update.createFeeTx?.payload.length ||
        update.updateTx.payload.length ||
        update.deleteTx?.payload.length;

      // Trigger all promises.
      const updateTxPromise = shouldCallAtomicUpdate
        ? atomicUpdateTransaction({ actionId, update })
        : undefined;
      const updateNFTPromises = nftUpdates.map(([currencyId, isMarkedAsNFT]) =>
        markCurrencyAsNFT(currencyId, isMarkedAsNFT),
      );

      const txUpdate = await updateTxPromise;

      try {
        await Promise.all(updateNFTPromises);
      } catch (e) {
        console.error(
          `Unable to update markAsNFT for payloads: ${JSON.stringify(markAsNFT)}`,
        );
      }

      if (txUpdate?.error) {
        throw new HttpError(txUpdate, ["updateRowTransaction"]);
      }

      return txUpdate?.data;
    },
    onSuccess(data) {
      if (data?.stsRes) {
        if (data.stsRes.bulkEditId) {
          displayStsSuccessMessage({
            bulkEditId: data.stsRes.bulkEditId,
            message: lang.similarTransactionsSuggestion.successToast({
              count: data.stsRes.count,
            }),
          });
        } else {
          displayMessage({
            message: lang.similarTransactionsSuggestion.successToast({
              count: data.stsRes.count,
            }),
            type: DisplayMessage.Success,
          });
        }
      }

      refreshReconciliationPayload(queryClient);
      queryClient.invalidateQueries({ queryKey: ruleKeys.all() });
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

export const useGetSimilarTransactionDetails = (actionId: string) => {
  return useQuery({
    queryKey: actionKeys.similar(actionId),
    queryFn: async () => {
      const res = await actionsApi.getSimilarTransactionData(actionId);

      if (res.error) {
        throw new HttpError(res, ["getSimilarTransactionData"]);
      }

      return res.data;
    },
  });
};

export function useSyncToErpMutation() {
  const queryClient = useQueryClient();
  const key = useGetCurrentQueryKey();
  return useMutation({
    mutationFn: async (payload: { actionId: string; erp: ERPProvider }) => {
      const res = await syncToErp(payload.actionId, payload.erp);

      if (res.error) {
        throw new HttpError(res, ["syncToErp"]);
      }

      return res.data;
    },
    onMutate: async (variables: { actionId: string; erp: string }) => {
      return optimisticUpdateActionRow({
        key,
        update: (t) =>
          t._id === variables.actionId
            ? { ...t, erpSyncStatus: ErpSyncStatus.SyncInProgress }
            : t,
        queryClient,
      });
    },
    onError: (err, variables, context) => {
      if (context?.previous) {
        queryClient.setQueryData(key, context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: actionKeys.action(variables.actionId),
      });
    },
  });
}

export const useSelectableRows = () => {
  const query = useGetActionsQuery();

  return useMemo(() => {
    return query.data?.transactions.filter((row) => !row.isLocked) ?? [];
  }, [query.data]);
};

const useIsRowLockedByActionId = () => {
  const queryClient = useQueryClient();
  const key = useGetCurrentQueryKey();

  return (actionId: string) => {
    const rows =
      queryClient.getQueryData<PostActionsQueryResponse>(key)?.transactions ??
      [];

    return rows.find((row) => row._id === actionId)?.isLocked ?? false;
  };
};

export const useGetActionsCountFromFilterQuery = ({
  filter,
  disabled = false,
}: {
  filter?: FilterQuery;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: actionKeys.count(filter),
    queryFn: async () => {
      if (!filter) return null;

      const res = await actionsApi.getActionCountFromFilter(filter);

      if (res.error) {
        const keys = actionKeys.actionsCount();
        throw new HttpError(res, keys);
      }

      return res.data.count;
    },
    staleTime: Infinity,
    enabled: !disabled,
  });
};

/**
 * Updates the users spam display preference
 */
export function useSpamMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async ({
      showSpamTransactions,
    }: {
      showSpamTransactions: boolean;
    }) => {
      const res = await updateShowSpamTransactions(showSpamTransactions);
      if (res.error) {
        throw new Error(res.msg);
      }
      return res.data;
    },
    onSuccess(data) {
      // update the redux store user
      dispatch(
        setUpdateBestActiveUser({
          showSpamTransactions: data.showSpamTransactions,
        }),
      );
      // refetch the actions
      return queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
}

export function useExplainActionQuery(actionId: string) {
  const {
    explainPrompt: userPrompt,
    explainSystemPrompt: systemPrompt,
    model,
    provider,
  } = useAiContext();
  const isExplainActionEnabled = useFeatureFlag(FeatureFlag.AiExplain);

  return useQuery({
    queryKey: actionKeys.explain({
      actionId,
      provider,
      model,
      userPrompt,
      systemPrompt,
    }),
    queryFn: async () => {
      const res = await actionsApi.explainAction({
        actionId,
        userPrompt,
        systemPrompt,
        config: { provider, model },
      });

      if (res.error) {
        throw new HttpError(res, ["explainAction"]);
      }

      return res.data;
    },
    enabled: isExplainActionEnabled,
  });
}
