import { Plan, ReportFormat, SyncStatusPlatform } from "@ctc/types";
import {
  type QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import moment from "moment-timezone";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import socketIOClient, { type Socket } from "socket.io-client";

import { captureGoogleAnalytics } from "~/analytics/google";
import { hashPlanChangeEvent } from "~/components/payment-status/helpers";
import { ERPProvider } from "~/components/settings-modal/views/enums";
import { formatDisplayAddress } from "~/components/transactions/helpers";
import { displayMessage } from "~/components/ui/Toaster";
import { LocalStorageKey } from "~/constants/enums";
import { type FinancialYear } from "~/contexts/FYContext";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import {
  LocalStorageUserKeyGenerator,
  useLocalStorage,
} from "~/hooks/useLocalStorage";
import { middleTrim } from "~/lib/index";
import {
  loadUser,
  setUpdate,
  setUpdateBestActiveUser,
  useIsManagingClients,
  useUser,
} from "~/redux/auth";
import { CollaborationSyncEvent, LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import {
  getPortfolioFailure,
  getPortfolioPartialValue,
  getPortfolioSuccess,
  resetPortfolio,
} from "~/redux/portfolio";
import { downloadPackComplete, loadTaxSettings } from "~/redux/report";
import {
  type ClusterRelatedWalletDetails,
  type CollaborationSyncStatus,
  type EvmRelatedWalletDetails,
  type RelatedWalletDetails,
} from "~/redux/types";
import { Analytics } from "~/segment/index";
import { URI_DOMAIN, URI_PATH } from "~/services/uri";
import { actionKeys } from "~/state/actions";
import { currencyKeys } from "~/state/currencies";
import { holdingKeys } from "~/state/dashboard";
import { entityKeys } from "~/state/entities";
import { Scope } from "~/state/enums";
import { erpKeys, useErpSettingsQuery } from "~/state/erp";
import {
  fetchSavedAccount,
  fetchSavedAccounts,
  savedImports,
  useFetchSavedAccountsForView,
  useGetSavedAccounts,
} from "~/state/importsV2";
import { refreshLedgerDataPayload } from "~/state/ledger";
import { plansQueryKeys } from "~/state/plans";
import {
  progressKeys,
  ProgressType,
  type ProgressValue,
} from "~/state/progress";
import { refreshReconciliationPayload } from "~/state/reconciliation";
import { reportKeys } from "~/state/report";
import {
  addToRelatedWallets,
  areAllClusterWalletsImported,
  createImportedWalletSet,
  getUnaddedChains,
  isClusterDuplicate,
  isClusterRelatedWallet,
  isEvmRelatedWallet,
  isEvmWalletDuplicate,
  performIgnoredClusterChainsAction,
  shouldHandleIgnoredClusterRelatedChains,
  syncRelatedWalletsKey,
  updateWalletChains,
  walletsKeys,
} from "~/state/syncHelpers";
import { refreshAllTLHData } from "~/state/taxLossHarvesting";
import {
  DisplayMessage,
  ImportType,
  ImportViewMode,
  Links,
  OtherReportType,
  type PortfolioTimeframe,
  RefreshReason,
} from "~/types/enums";
import {
  BlockchainName,
  type RenewalErrorInfo,
  type ReportType,
  type SavedFileImport,
  type SavedImportOptionByAccount,
  type SyncDetails,
  type TimeSeriesData,
  type UserInfo,
} from "~/types/index";

import { useTrackImportFailedIntercomEvent } from "../hooks/useTrackIntercomEvent";

export const syncKeys = {
  all: () => ["sync"] as const,

  connected: () => [...syncKeys.all(), "connected"] as const,
  lists: () => [...syncKeys.all(), "lists"] as const,
  state: (scope: string) => [...syncKeys.lists(), scope] as const,

  report: () => [...syncKeys.all(), "report"] as const,
  collaboration: () => [...syncKeys.all(), "collaboration"] as const,
  relatedWallets: () => syncRelatedWalletsKey(),
};

export { walletsKeys };

type ApiInfo = {
  id: string; // the key or oauth ObjectId string
};

type CsvInfoWorker = {
  id: string; // the file ObjectId string
};

type WalletInfo = {
  id: string; // the wallet ObjectId string
};

type ReportDownloadPdfInfo = {
  fileUrl: string;
  year: number;
  fromDate: string;
  toDate: string;
  filename: string;
  reportType: ReportType;
  fileKey: string;
  packId?: string;
};

type ReportDownloadCsvInfo = {
  reportType: ReportType | OtherReportType.FilteredTransaction;
  year: number;
  fromDate: string;
  toDate: string;
};

type ReportDownloadTxfInfo = {
  reportType: ReportType;
  year: number;
  fromDate: string;
  toDate: string;
};

type ReportEvent = {
  reason: RefreshReason | undefined;
  totalTransactionCount: number;
  billedTransactionCount: number;
};

type ReportInfo = ReportEvent & {
  reportStatus: SyncStatusPlatform;
  reportUpdatedAt: number;
};

type PortfolioInfo = {
  timeframe: PortfolioTimeframe;
  value: TimeSeriesData[];
  fiatInvested: TimeSeriesData[];
  partialResult?: true;
};

type CollaborationInfo = {
  collaborationSyncStatus: CollaborationSyncStatus;
  invokerUuid?: string;
};

type PersonalPaidPlanInfo = {
  paidPlan?: Plan;
};

type Event<Info> = {
  scope: Scope;
  status: SyncStatusPlatform;
  message?: string;
  info?: Info;
};

const useScreenEvent = () => {
  const user = useUser();

  const callback = useCallback(
    (event: Event<any>, callback: () => any) => {
      if (!user || !user.activeDataSource) {
        return;
      }
      const {
        _id: uid,
        activeDataSource: { uid: expectedClientUid },
      } = user;

      // Normal User - sentFromUid is only added when intending to send event to acct/collaborator
      if (!event.info?.sentFromUid) return callback();

      const {
        info: { sentFromUid },
      } = event;

      // Accountant/Collab user
      if (sentFromUid === uid || sentFromUid === expectedClientUid) {
        return callback();
      }
    },
    [user],
  );
  return callback;
};

// Initialize handlers that use hooks (a lot of this is legacy redux stuff yet to be migrated e.g user invalidation)
const useHandlers = () => {
  const handleReportRefresh = useHandleReportRefresh();
  const handleApi = useHandleApi();
  const handleEmailVerified = useHandleEmailVerified();
  const handleRenewalEvent = useHandleRenewal();
  const handleErp = useHandleErp();
  const handleErpSync = useHandleErpSync();
  const handlePersonalPaidPlan = useHandlePersonalPaidPlan();
  const handleCollab = useHandleCollab();
  const handlePortfolio = useHandlePortfolio();
  const handleTips = useHandleTips();
  const handleRules = useHandleRules();
  const handleWallet = useHandleWallet();
  const handleCsv = useHandleCsv();
  const handleRelatedWalletsFound = useHandleRelatedWalletsFound();
  const handleReportDownloadPdf = useHandleReportDownloadPdf();
  const handleReportDownloadCsv = useHandleReportDownloadCsv();
  const handleReportDownloadTxf = useHandleReportDownloadTxf();

  return {
    handleReportRefresh,
    handleApi,
    handleEmailVerified,
    handleRenewalEvent,
    handleErp,
    handlePersonalPaidPlan,
    handleCollab,
    handlePortfolio,
    handleTips,
    handleRules,
    handleWallet,
    handleCsv,
    handleRelatedWalletsFound,
    handleReportDownloadPdf,
    handleReportDownloadCsv,
    handleReportDownloadTxf,
    handleErpSync,
  };
};

export const useReactQuerySubscription = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const queryClient = useQueryClient();
  const screenEvent = useScreenEvent();
  const hackyMutate = useHackyMutate();
  const user = useUser();

  const handlers = useHandlers();

  // Handle closing and reopening the connection when logging in/out
  useEffect(() => {
    // when you change clients we reset the query client, so we need to
    // say the ws connection is still connected for the portfolio chart to work
    if (socket) {
      hackyMutate(syncKeys.connected(), socket?.connected || false);
    }

    if (user && !socket) {
      console.log(`URI_DOMAIN: ${URI_DOMAIN}; URI_PATH: ${URI_PATH}`);
      setSocket(
        socketIOClient(URI_DOMAIN, {
          secure: true,
          transports: ["websocket"],
          upgrade: false,
          ...(URI_PATH ? { path: `${URI_PATH}/socket.io` } : {}),
        }),
      );
    } else if (!user && socket) {
      socket.close();
      setSocket(null);
    }
  }, [user, socket]);

  // Handle subscribing to events
  useEffect(() => {
    if (socket) {
      const setIsConnectedCallback = () => {
        hackyMutate(syncKeys.connected(), socket?.connected || false);
      };
      socket.on(Scope.HeartBeat, () => {});
      socket.on("connect", setIsConnectedCallback);
      socket.on("disconnect", setIsConnectedCallback);

      // Report data
      socket.on(Scope.Report, (event: Event<ReportEvent>) =>
        screenEvent(event, () => {
          handlers.handleReportRefresh(event);
        }),
      );
      socket.on(Scope.Reconciliation, (event: Event<never>) => {
        screenEvent(event, () => {
          handlers.handleTips(event);
        });
      });
      socket.on(Scope.Rules, (event: Event<never>) => {
        screenEvent(event, () => {
          handlers.handleRules(event);
        });
      });

      // Imports
      socket.on(Scope.Api, (event: Event<ApiInfo>) =>
        screenEvent(event, () => {
          handlers.handleApi(event);
        }),
      );
      socket.on(Scope.Csv, (event: Event<CsvInfoWorker>) => {
        screenEvent(event, () => {
          handlers.handleCsv(event);
        });
      });
      socket.on(Scope.Wallet, (event: Event<WalletInfo>) => {
        screenEvent(event, () => handlers.handleWallet(event));
      });

      // Related Wallets
      socket.on(
        Scope.RelatedWalletsFound,
        (event: Event<RelatedWalletDetails>) => {
          screenEvent(event, () => {
            handlers.handleRelatedWalletsFound(event);
          });
        },
      );
      socket.on(Scope.RelatedWalletImported, (event: Event<never>) => {
        screenEvent(event, () => {
          handleMessage(event);
        });
      });

      // Portfolio
      socket.on(Scope.Portfolio, (event: Event<PortfolioInfo>) => {
        screenEvent(event, () => {
          handlers.handlePortfolio(event);
        });
      });

      // Report download
      socket.on(
        Scope.ReportDownloadPdf,
        (event: Event<ReportDownloadPdfInfo>) => {
          screenEvent(event, () => handlers.handleReportDownloadPdf(event));
        },
      );
      socket.on(
        Scope.ReportDownloadCsv,
        (event: Event<ReportDownloadCsvInfo>) => {
          screenEvent(event, () => {
            handlers.handleReportDownloadCsv(event);
          });
        },
      );
      socket.on(
        Scope.ReportDownloadTxf,
        (event: Event<ReportDownloadTxfInfo>) => {
          screenEvent(event, () => {
            handlers.handleReportDownloadTxf(event);
          });
        },
      );

      // Accountant
      socket.on(Scope.Collaboration, (event: Event<CollaborationInfo>) => {
        screenEvent(event, () => {
          handlers.handleCollab(event);
        });
      });

      // Account
      socket.on(
        Scope.PersonalPaidPlan,
        (event: Event<PersonalPaidPlanInfo>) => {
          screenEvent(event, () => {
            handlers.handlePersonalPaidPlan(event);
          });
        },
      );
      socket.on(Scope.Renewal, (event: Event<RenewalErrorInfo>) => {
        screenEvent(event, () => {
          handlers.handleRenewalEvent(event);
        });
      });
      socket.on(Scope.EmailVerified, (event: Event<never>) => {
        screenEvent(event, () => {
          handlers.handleEmailVerified(event);
        });
      });
      socket.on(Scope.Erp, (event: Event<never>) => {
        screenEvent(event, () => {
          handlers.handleErp(event);
        });
      });
      socket.on(Scope.ErpSync, (event: Event<never>) => {
        screenEvent(event, () => {
          handlers.handleErpSync(event);
        });
      });
      socket.on(Scope.Message, (event: Event<never>) => {
        screenEvent(event, () => {
          handleMessage(event);
        });
      });
      socket.on(Scope.Progress, (event: Event<ProgressValue>) => {
        // Add the event data to react query
        const key = progressKeys.type(ProgressType.ReportRefresh);
        if (!event.info) {
          console.error("Progress event is missing info");
          return;
        }
        const { value, max } = event.info;
        queryClient.setQueryData(key, { value, max });
      });
    }

    return () => {
      Object.values(Scope).forEach((scope) => socket?.off(scope));
    };
  }, [queryClient, screenEvent, handlers, socket, hackyMutate]);
};

/**
 * Ideally we dont do partial data updates but rather invalidate the whole
 * queries, however pretty much all of the existing ws stuff works like this
 * and I dont wanna update it all now, so this is a hacky way to do it in the
 * mean time, but in future don't use this and just invalidate the whole query
 * @returns
 */
const useHackyMutate = () => {
  const queryClient = useQueryClient();

  return <T>(queryKey: QueryKey, newData: T) => {
    queryClient.setQueryData<T>(
      queryKey,

      (oldData) => newData,
    );
    queryClient.invalidateQueries({ queryKey });

    // To ensure allStatusUpdates is kept up to date, invalidate all the
    // lists parent key when one is mutated
    if (queryKey.includes("lists")) {
      queryClient.invalidateQueries({
        queryKey: syncKeys.lists(),
        exact: true,
      });
    }
  };
};

export const useSocketConnected = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: syncKeys.connected(),
    queryFn: () =>
      queryClient.getQueryData<boolean>(syncKeys.connected()) || false,
  }).data;
};

export const useReportStatus = (): ReportInfo | undefined => {
  const user = useUser();
  const queryClient = useQueryClient();
  const reportStatus = useQuery({
    queryKey: syncKeys.report(),
    queryFn: () =>
      queryClient.getQueryData<ReportInfo>(syncKeys.report()) || null,
  }).data;

  const fallback: ReportInfo | undefined = user?.activeDataSource
    ? {
        reason: undefined,
        totalTransactionCount: user.activeDataSource.txGroupCount,
        billedTransactionCount: user.activeDataSource.txBilledCount,
        reportStatus:
          user.activeDataSource.reportRefreshStatus ||
          SyncStatusPlatform.Success,
        reportUpdatedAt: new Date(
          user.activeDataSource.reportRefreshUpdatedAt || "",
        ).valueOf(),
      }
    : undefined;

  return reportStatus || fallback;
};

const useCollaboration = () => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: syncKeys.collaboration(),
    queryFn: () =>
      queryClient.getQueryData<{
        status: CollaborationSyncStatus | null;
        invokerUuid: string | null;
        updatedAt: number;
      }>(syncKeys.collaboration()) ?? null,
  }).data;
};

export const useSyncStatus = (scope: string) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: syncKeys.state(scope),
    queryFn: () =>
      queryClient.getQueryData<SyncStatusPlatform>(syncKeys.state(scope)) ||
      SyncStatusPlatform.Success,
  }).data;
};

export const useGetSyncStatus = () => {
  const queryClient = useQueryClient();
  return (scope: string) => {
    const syncStatus = queryClient.getQueryData<SyncStatusPlatform>(
      syncKeys.state(scope),
    );

    return syncStatus;
  };
};

const useRelatedWallets = () => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: syncKeys.relatedWallets(),
    queryFn: () =>
      queryClient.getQueryData<RelatedWalletDetails[]>(
        syncKeys.relatedWallets(),
      ) ?? [],
  }).data;
};

// Get the first item from the queue
export const useFirstRelatedWallet = () => {
  const relatedWallets = useRelatedWallets();
  return relatedWallets?.[0];
};

export const useDequeueRelatedWalletMutation = () => {
  const queryClient = useQueryClient();
  const hackyMutate = useHackyMutate();

  return useMutation({
    mutationFn: async () => {
      const relatedWallets =
        queryClient.getQueryData<RelatedWalletDetails[]>(
          syncKeys.relatedWallets(),
        ) ?? [];
      const sliced = relatedWallets.slice(1);
      hackyMutate(syncKeys.relatedWallets(), sliced);

      return sliced;
    },
    onSuccess(data) {
      return data;
    },
  });
};

export const useSetSyncMutation = () => {
  const hackyMutate = useHackyMutate();

  return useMutation({
    mutationFn: async ({
      scope,
      status,
    }: {
      scope: string;
      status: SyncStatusPlatform;
    }) => {
      hackyMutate(syncKeys.state(scope), status);
    },

    onSuccess: () => {
      return true;
    },
  });
};

export const useRemoveSyncMutation = () => {
  const hackyMutate = useHackyMutate();

  return useMutation({
    mutationFn: async ({ scope }: { scope: string }) => {
      hackyMutate(syncKeys.state(scope), null);
    },
    onSuccess: () => {
      return true;
    },
  });
};

export const useUserSyncStatus = (): SyncStatusPlatform | null => {
  const reportStatus = useReportStatus();
  const user = useUser();
  const { activeDataSource: activeProfile } = user || {
    activeDataSource: undefined,
  };
  const isManagingClients = useIsManagingClients();

  let syncStatus = null;

  // Set status for non-accountant users.
  if (!isManagingClients && user?.reportRefreshStatus) {
    syncStatus = user?.reportRefreshStatus;
  }
  // Set status for accountant users.
  if (isManagingClients && activeProfile?.reportRefreshStatus) {
    syncStatus = activeProfile.reportRefreshStatus;
  }
  // Prefer status from local state if available.
  if (reportStatus) {
    syncStatus = reportStatus.reportStatus;
  }

  return syncStatus;
};

export const useHasImportPending = (
  importViewMode = ImportViewMode.ByBlockchain,
): boolean => {
  const allSavedImports =
    useFetchSavedAccountsForView(importViewMode)?.data?.all ?? [];

  const filtered = allSavedImports.filter((savedImport) =>
    savedImport.syncStatus
      ? [SyncStatusPlatform.Pending, SyncStatusPlatform.Queued].includes(
          savedImport.syncStatus,
        )
      : false,
  );

  return filtered.length > 0;
};

const handleMessage = (event: Event<never>) => {
  const { message } = event;
  if (!message) return;

  if (event.message) {
    displayMessage({ message, type: DisplayMessage.Info });
  }
};

const useHandleEmailVerified = () => {
  const dispatch = useDispatch();
  return (event: Event<never>) => {
    if (event.status === SyncStatusPlatform.Success) {
      // refetch auth user
      dispatch(loadUser(LoadUserType.Login));
    }
  };
};

const useHandleRenewal = () => {
  const dispatch = useDispatch();
  return (event: Event<RenewalErrorInfo>) => {
    if (event.info) dispatch(setUpdateBestActiveUser(event.info));
    dispatch(loadUser(LoadUserType.UserUpdate));
  };
};

const useHandleErp = () => {
  const lang = useLang();
  const queryClient = useQueryClient();

  return (event: Event<never>) => {
    if (event.status === SyncStatusPlatform.Fail) {
      displayMessage({
        message: event.message || lang.sync.xeroSyncFailed,
        type: DisplayMessage.Error,
      });
    }
    queryClient.invalidateQueries({ queryKey: erpKeys.settings() });
  };
};

const useHandleErpSync = () => {
  const lang = useLang();
  const queryClient = useQueryClient();
  const erpQuery = useErpSettingsQuery();
  const erpProvider = erpQuery?.data?.erp || ERPProvider.Xero;

  return (event: Event<never>) => {
    if (event.status === SyncStatusPlatform.Fail) {
      displayMessage({
        message:
          event.message || lang.sync.actionErpSyncFailed({ erpProvider }),
        type: DisplayMessage.Error,
      });
    }
    queryClient.invalidateQueries({ queryKey: actionKeys.all() });
  };
};

async function trackCancelledPlan(user: UserInfo, prevPlan: Plan | undefined) {
  const analytics = await Analytics.getInstance();
  analytics.track({
    event: "plan_cancelled",
    user,
    props: {
      prev_plan: prevPlan,
    },
  });
  captureGoogleAnalytics({
    category: "payment",
    action: `cancelled ${prevPlan} plan`,
    label: "submit",
  });
}

const useHandlePersonalPaidPlan = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const isEmbedded = useIsEmbedded();

  return (event: Event<PersonalPaidPlanInfo>) => {
    const paidPlan = event.info?.paidPlan;
    if (paidPlan && user) {
      const prevPlan = user.paidPlan;
      dispatch(setUpdate({ paidPlan }));
      if (paidPlan === Plan.Free) {
        trackCancelledPlan(user, prevPlan);
        window.location.href = Links.Plans;
      } else {
        localStorage.setItem(
          LocalStorageKey.PlanUpgradeEvent,
          hashPlanChangeEvent({ newPlan: paidPlan, oldPlan: prevPlan }),
        );
        if (isEmbedded) {
          window.location.href = Links.PaymentSuccess;
        }
      }
    }
    dispatch(loadUser(LoadUserType.UserUpdate));
  };
};

const useHandleCollab = () => {
  const dispatch = useDispatch();
  const hackyMutate = useHackyMutate();

  return (event: Event<CollaborationInfo>) => {
    hackyMutate(syncKeys.collaboration(), {
      status: event.info?.collaborationSyncStatus || null,
      invokerUuid: event.info?.invokerUuid || null,
      updatedAt: moment().unix(),
    });

    if (event.message) {
      displayMessage({
        message: event.message,
        type: DisplayMessage.Info,
      });
    }
    if (
      event.info?.collaborationSyncStatus ===
      CollaborationSyncEvent.PlanUpgraded
    ) {
      dispatch(loadUser(LoadUserType.Login));
    }
  };
};

const getReportInfo = (event: Event<ReportEvent>): ReportInfo | undefined => {
  if (!event.info) return undefined;

  return {
    ...event.info,
    reportStatus: event.status,
    reportUpdatedAt: new Date().valueOf(),
  };
};

const useHandleReportRefresh = () => {
  const queryClient = useQueryClient();
  const syncSet = useSetSyncMutation();
  const hackyMutate = useHackyMutate();
  const dispatch = useDispatch();
  const lang = useLang();

  return (event: Event<ReportEvent>) => {
    const { status } = event;

    switch (status) {
      case SyncStatusPlatform.Fail: {
        displayMessage({
          message: event.message
            ? event.message
            : lang.sync.reportRefreshFailed,
          type: DisplayMessage.Error,
        });
        break;
      }
      case SyncStatusPlatform.Queued:
      case SyncStatusPlatform.Pending: {
        if (event.message) {
          displayMessage({
            message: event.message,
            type: DisplayMessage.Info,
          });
        }
        // get the new tax settings
        if (event.info?.reason === RefreshReason.TaxSettings) {
          dispatch(loadTaxSettings());
        }
        // set the rules to pending (theyre always refreshed)
        syncSet.mutate({
          scope: Scope.Rules,
          status: SyncStatusPlatform.Pending,
        });
        break;
      }
      case SyncStatusPlatform.Success: {
        refreshAllTLHData();
        refreshLedgerDataPayload(queryClient);

        queryClient.invalidateQueries({ queryKey: savedImports.all() });
        queryClient.invalidateQueries({ queryKey: actionKeys.all() });
        queryClient.invalidateQueries({ queryKey: entityKeys.all() });
        queryClient.invalidateQueries({ queryKey: currencyKeys.user() });
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.all() });
        queryClient.invalidateQueries({ queryKey: reportKeys.all() });
        queryClient.invalidateQueries({ queryKey: holdingKeys.all() });

        if (event.message) {
          displayMessage({
            message: event.message,
            type: DisplayMessage.Success,
            id: event.message,
          });
        }
        dispatch(resetPortfolio());
        dispatch(loadUser(LoadUserType.UserUpdate));
        break;
      }
      default: {
        const exhaustiveCheck: never = status;
        throw new Error(`Unhandled case: ${exhaustiveCheck}`);
      }
    }
    const reportInfo = getReportInfo(event);
    syncSet.mutate({ scope: Scope.Report, status: event.status });
    hackyMutate(syncKeys.report(), reportInfo);
  };
};

const useHandleApi = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const lang = useLang();
  const trackImportFailed = useTrackImportFailedIntercomEvent();

  //@todo - combine this with useHandleWallet
  return async (event: Event<ApiInfo>) => {
    queryClient.invalidateQueries({ queryKey: savedImports.all() });

    const id = event.info?.id;
    if (!id) return;
    const allSavedImports = await fetchSavedAccounts({ queryClient });
    if (!allSavedImports.length) return;
    const savedImport = allSavedImports.find(
      (api) =>
        api.keys.some((k) => k.id === id.toString()) ||
        api.oauths.some((o) => o.id === id.toString()),
    );

    if (!savedImport) return;
    const exchangeName = savedImport.name;
    const api = [...savedImport.keys, ...savedImport.oauths].find(
      (a) => a.id === id.toString(),
    );

    if (!api) return;
    switch (event.status) {
      case SyncStatusPlatform.Queued:
      case SyncStatusPlatform.Pending: {
        if (event.message) {
          displayMessage({
            message: event.message,
            type: DisplayMessage.Info,
          });
        }
        break;
      }
      case SyncStatusPlatform.Success: {
        const message =
          api.lastSyncNewTxCount === 0
            ? lang.imports.noTxFound({
                exchangeDisplayName: savedImport.name,
                showNew: !!api.importedTxCount, // if the exchange has transactions from a prior sync
              })
            : lang.imports.importedTransactions({
                count: api.lastSyncNewTxCount ?? 0,
                exchangeDisplayName: savedImport.name,
              });

        displayMessage({
          message,
          type: DisplayMessage.Success,
        });
        // todo move all analytics to the backend
        captureGoogleAnalytics({
          category: "import",
          action: `save-api: ${exchangeName}`,
          label: "upload",
        });

        break;
      }
      case SyncStatusPlatform.Fail: {
        const { message, status } = api.error || {};
        displayMessage({
          message: lang.imports.error({
            blockchain: false,
            name: savedImport.name,
            showError: !!message,
            error: message ?? "",
            showCode: !!status,
            code: status ?? 0,
          }),
          type: DisplayMessage.Info,
        });
        // todo move all analytics to the backend
        captureGoogleAnalytics({
          category: "ERROR",
          action: `save-api-fail: ${exchangeName}`,
          label: "fail-import-api",
        });
        if (user) {
          Analytics.getInstance().then((analytics) => {
            analytics.track({
              event: "import_api_failed",
              user,
              props: {
                exchange_name: exchangeName,
              },
            });
          });
        }

        trackImportFailed({
          name: exchangeName,
          importType: ImportType.API,
          errorMessage: message ?? "",
        });
        break;
      }
      default: {
        const exhaustiveCheck: never = event.status;
        throw new Error(`Unhandled case: ${exhaustiveCheck}`);
      }
    }
  };
};

const useHandleWallet = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const lang = useLang();
  const trackImportFailed = useTrackImportFailedIntercomEvent();

  // @todo combine this with use handleApi
  return async (event: Event<WalletInfo>) => {
    const importId = event.info?.id;
    if (!importId) return;

    // These are simple events that should be handled by optimistic updates.
    // The early exit is done to prevent additional queries that aren't required.
    if (
      [SyncStatusPlatform.Queued, SyncStatusPlatform.Pending].includes(
        event.status,
      )
    ) {
      if (event.message) {
        displayMessage({
          message: event.message,
          type: DisplayMessage.Info,
        });
      }

      return;
    }

    // On certain events, eg: fail or success - the imports need to be fetched.
    await queryClient.invalidateQueries({
      queryKey: savedImports.import(importId, ImportViewMode.ByBlockchain),
    });

    const allSavedImports = await fetchSavedAccount({ importId, queryClient });

    if (!allSavedImports.length) return;

    const wallet = allSavedImports
      .flatMap((s) => s.wallets)
      .find((w) => w.id === importId.toString());

    if (!wallet) return;

    switch (event.status) {
      case SyncStatusPlatform.Success: {
        const message =
          wallet.lastSyncNewTxCount === 0
            ? lang.imports.noTxFoundWallet({
                blockchain: BlockchainName[wallet.blockchain],
                address: wallet.address,
                showNew: !!wallet.importedTxCount, // if the wallet has transactions from a prior sync
              })
            : lang.imports.successImportWallet({
                blockchain: BlockchainName[wallet.blockchain],
                address: middleTrim(wallet.address, 20, 3),
              });

        displayMessage({
          message,
          type: DisplayMessage.Success,
        });

        break;
      }
      case SyncStatusPlatform.Fail: {
        const { message, status } = wallet.error || {};
        displayMessage({
          message: lang.imports.error({
            blockchain: true,
            name: formatDisplayAddress(wallet.address, false, true),
            showError: !!message,
            error: message ?? "",
            showCode: !!status,
            code: status ?? 0,
          }),
          type: DisplayMessage.Info,
        });

        trackImportFailed({
          name: formatDisplayAddress(wallet.address, false, true),
          importType: ImportType.WalletAPI,
          blockchain: BlockchainName[wallet.blockchain],
          errorMessage: message ?? "",
        });

        break;
      }
      default: {
        const exhaustiveCheck = event.status;
        throw new Error(`Unhandled case: ${exhaustiveCheck}`);
      }
    }
    // todo send this from the backend directly to ortto to cut out the costly middleman Segment
    if (event.status === SyncStatusPlatform.Fail && user) {
      Analytics.getInstance().then((analytics) => {
        analytics.track({
          event: "import_wallet_failed",
          user,
          props: {
            wallet_type: wallet.blockchain,
          },
        });
      });
    }
  };
};

const useHandleCsvSuccess = () => {
  return (exchangeName: string) => {
    captureGoogleAnalytics({
      category: "import",
      action: `upload-csv: ${exchangeName}`,
      label: "upload",
    });
  };
};

const useHandleCsvFailed = () => {
  const lang = useLang();
  const user = useUser();
  const trackImportFailed = useTrackImportFailedIntercomEvent();

  return (
    exchangeName: string,
    syncError?: {
      savedImport: SavedImportOptionByAccount;
      file: SavedFileImport;
    },
  ) => {
    captureGoogleAnalytics({
      category: "ERROR",
      action: `upload-csv-fail: ${exchangeName}`,
      label: "fail-import-csv",
    });

    if (user) {
      Analytics.getInstance().then((analytics) => {
        analytics.track({
          event: "import_csv_failed",
          user,
          props: {
            exchange_name: exchangeName,
          },
        });
      });
    }

    if (syncError) {
      const { message, status } = syncError.file.error || {};

      displayMessage({
        message: lang.imports.error({
          blockchain: false,
          name: syncError.savedImport.name,
          showError: !!message,
          error: message ?? "",
          showCode: !!status,
          code: status ?? 0,
        }),
        type: DisplayMessage.Info,
      });

      trackImportFailed({
        name: syncError.savedImport.name,
        importType: ImportType.CSV,
        errorMessage: message ?? "",
      });
    }
  };
};

const useHandleCsv = () => {
  const queryClient = useQueryClient();
  const handleCsvFailed = useHandleCsvFailed();
  const handleCsvSuccess = useHandleCsvSuccess();

  return async (event: Event<CsvInfoWorker>) => {
    queryClient.invalidateQueries({ queryKey: savedImports.all() });

    if (event.message) {
      displayMessage({
        message: event.message,
        type: DisplayMessage.Info,
      });
    }

    if (!event.info) return;

    const { id } = event.info;
    const allSavedImports = await fetchSavedAccounts({ queryClient });

    const savedImport = allSavedImports.find((api) =>
      api.files.some((k) => k.id === id.toString()),
    );

    if (!savedImport) return;

    const file = [...savedImport.files].find((a) => a.id === id.toString());

    if (!file) return;

    if (event.status === SyncStatusPlatform.Fail) {
      handleCsvFailed(savedImport.name, {
        savedImport,
        file,
      });
    } else if (event.status === SyncStatusPlatform.Success) {
      handleCsvSuccess(savedImport.name);
    }
  };
};

const useHandlePortfolio = () => {
  const dispatch = useDispatch();
  const syncSet = useSetSyncMutation();

  return (event: Event<PortfolioInfo>) => {
    const { status } = event;
    syncSet.mutate({ scope: Scope.Portfolio, status });

    switch (status) {
      case SyncStatusPlatform.Queued:
      case SyncStatusPlatform.Pending: {
        if (
          event.info?.partialResult &&
          event.info.timeframe &&
          event.info.value &&
          event.info.fiatInvested
        ) {
          dispatch(
            getPortfolioPartialValue(
              event.info.timeframe,
              event.info.value,
              event.info.fiatInvested,
            ),
          );
        }
        break;
      }
      case SyncStatusPlatform.Success: {
        if (
          event.info?.timeframe &&
          event.info.value &&
          event.info.fiatInvested
        ) {
          // this is a success
          dispatch(
            getPortfolioSuccess(
              event.info.timeframe,
              event.info.value,
              event.info.fiatInvested,
            ),
          );
        }
        break;
      }
      case SyncStatusPlatform.Fail: {
        if (event.info?.timeframe) {
          dispatch(getPortfolioFailure(event.info.timeframe));
          if (event.message) {
            displayMessage({
              message: event.message,
              type: DisplayMessage.Error,
            });
          }
        }
        break;
      }
      default: {
        const exhaustiveCheck: never = status;
        throw new Error(`Unhandled case: ${exhaustiveCheck}`);
      }
    }
  };
};

const useHandleTips = () => {
  const syncSet = useSetSyncMutation();
  const queryClient = useQueryClient();
  return (event: Event<never>) => {
    const { status } = event;

    syncSet.mutate({ scope: Scope.Reconciliation, status });

    if (status === SyncStatusPlatform.Success) {
      refreshReconciliationPayload(queryClient);
    }
  };
};

const useHandleRules = () => {
  const queryClient = useQueryClient();
  const syncSet = useSetSyncMutation();

  return (event: Event<never>) => {
    const { status } = event;

    syncSet.mutate({ scope: Scope.Rules, status });

    if (status === SyncStatusPlatform.Success) {
      // Invalidate the actions to refetch the counts on the rules page
      queryClient.invalidateQueries({ queryKey: actionKeys.lists() });
    }
  };
};

/**
 * Handles related wallets found events by processing EVM and cluster wallet relationships.
 * This hook sets up an event handler that determines the type of related wallet event
 * (EVM or Cluster) and delegates the processing to specialized internal functions.
 * @returns {function(event: Event<RelatedWalletDetails>): void} Event handler function for related wallet events.
 */
export const useHandleRelatedWalletsFound = () => {
  const existingRelatedWallets = useRelatedWallets() ?? [];
  const hackyMutate = useHackyMutate();
  const allImports = useGetSavedAccounts();
  const user = useUser();
  const [ignoredClusterNames] = useLocalStorage<string[]>(
    LocalStorageUserKeyGenerator(
      user?.uid,
      LocalStorageKey.IgnoredClusterNames,
    ),
    [],
  );
  const importedSet = createImportedWalletSet(allImports);

  /**
   * Processes an EVM-related wallet event.
   * Checks for duplicates before adding the wallet and updating its chains.
   * @param {EvmRelatedWalletDetails} info - The details of the EVM wallet event.
   */
  const handleEvmWalletInternal = (info: EvmRelatedWalletDetails): void => {
    // Skip duplicates
    if (isEvmWalletDuplicate(info, existingRelatedWallets)) {
      return;
    }

    // Add to related wallets and update available chains
    addToRelatedWallets(hackyMutate, info, existingRelatedWallets);
    updateWalletChains(
      hackyMutate,
      info.address,
      info.originalBlockchain,
      info.chains,
    );
  };

  /**
   * Processes a cluster-related wallet event.
   * Handles ignored clusters, checks for duplicates, and ensures not all wallets are already imported before adding.
   * @param {ClusterRelatedWalletDetails} info - The details of the cluster wallet event.
   */
  const handleClusterWalletInternal = (
    info: ClusterRelatedWalletDetails,
  ): void => {
    // Handle explicitly ignored clusters first
    if (ignoredClusterNames.includes(info.clusterName)) {
      const firstWallet = info.wallets[0];
      const unAddedChains = getUnaddedChains(firstWallet, importedSet);

      // Handle special case for ignored clusters with unimported related chains
      if (
        shouldHandleIgnoredClusterRelatedChains(
          firstWallet,
          unAddedChains,
          importedSet,
        )
      ) {
        performIgnoredClusterChainsAction(
          hackyMutate,
          info,
          firstWallet,
          unAddedChains,
          existingRelatedWallets,
        );
        // Action performed, nothing more to do for this ignored cluster
        return;
      }

      // Otherwise skip this explicitly ignored cluster entirely
      return;
    }

    // Handle non-ignored clusters: check for duplicates and existing imports
    if (isClusterDuplicate(info, existingRelatedWallets)) {
      return; // Skip duplicate clusters
    }

    if (areAllClusterWalletsImported(info, importedSet)) {
      return; // Skip if all wallets in the cluster are already imported
    }

    // Add the valid, non-duplicate, not-fully-imported cluster to related wallets
    addToRelatedWallets(hackyMutate, info, existingRelatedWallets);
  };

  /**
   * Event handler function returned by the hook.
   * Determines the wallet type and calls the appropriate internal handler.
   * @param {Event<RelatedWalletDetails>} event - The socket event containing related wallet details.
   */
  const eventHandler = (event: Event<RelatedWalletDetails>): void => {
    if (!event.info) {
      console.warn("Received RelatedWalletsFound event with no info.");
      return;
    }
    const info = event.info;

    // Use type guards to determine the type of wallet and delegate
    if (isEvmRelatedWallet(info)) {
      handleEvmWalletInternal(info);
    } else if (isClusterRelatedWallet(info)) {
      handleClusterWalletInternal(info);
    } else {
      // Log if the type is neither EVM nor Cluster (should ideally not happen with proper typing)
      console.warn(
        "Received RelatedWalletsFound event with unknown info type:",
        info,
      );
    }
  };

  return eventHandler;
};

export function getReportDownloadSyncType(
  reportType: ReportType,
  timeframe: FinancialYear,
  type: ReportFormat,
) {
  return `report_${reportType}_${timeframe.start.toISOString()}_${timeframe.end.toISOString()}_${type}`;
}

export function getFilteredTxReportSyncType() {
  return `report_${OtherReportType.FilteredTransaction}`;
}

export function getTaxLotsReportSyncType() {
  return `report_${OtherReportType.TaxLots}`;
}

function getReportSyncTypeFromEvent(info: ReportDownloadCsvInfo) {
  if (info.reportType === OtherReportType.FilteredTransaction) {
    return getFilteredTxReportSyncType();
  }
  const timeframe = createTimeframeFromInfo(info);
  const { reportType } = info;
  return getReportDownloadSyncType(reportType, timeframe, ReportFormat.CSV);
}

const createTimeframeFromInfo = (info: {
  year?: number;
  fromDate?: string;
  toDate?: string;
}): FinancialYear => {
  const start = new Date(info?.fromDate || "");
  const end = new Date(info?.toDate || "");

  return {
    start,
    end,
    year: info.year || start.getFullYear(),
  };
};

async function download(dataurl: string, filename: string) {
  const response = await fetch(dataurl);
  const blob = await response.blob();
  const linkURL = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = linkURL;
  link.download = filename;
  link.target = "_blank";

  document.body.appendChild(link);
  link.click();
  link.remove();

  URL.revokeObjectURL(linkURL);
}

const useHandleReportDownloadPdf = () => {
  const dispatch = useDispatch();
  const syncSet = useSetSyncMutation();

  return async (event: Event<ReportDownloadPdfInfo>) => {
    const packId = event?.info?.packId;
    if (packId) dispatch(downloadPackComplete(packId));

    if (event.status && event.info?.reportType) {
      const timeframe = createTimeframeFromInfo(event.info);
      const { reportType } = event.info;
      const syncType = getReportDownloadSyncType(
        reportType,
        timeframe,
        ReportFormat.PDF,
      );

      if (event.status !== SyncStatusPlatform.Success) {
        // We dispatch this async below
        syncSet.mutate({ scope: syncType, status: event.status });
      }

      if (event.status === SyncStatusPlatform.Fail && event.message) {
        displayMessage({
          message: event.message,
          type: DisplayMessage.Error,
        });
        return;
      } else if (event.status === SyncStatusPlatform.Success) {
        const { fileUrl, filename } = event.info;
        if (!fileUrl || !filename) {
          return;
        }
        if (event.message) {
          displayMessage({
            message: event.message,
            type: DisplayMessage.Info,
          });
        }
        if (event.info.fileKey && sessionStorage.getItem(event.info.fileKey)) {
          sessionStorage.removeItem(event.info.fileKey);
          await download(fileUrl, filename);
        }
        syncSet.mutate({ scope: syncType, status: event.status });
      }
    }
  };
};

const useHandleReportDownloadCsv = () => {
  const syncSet = useSetSyncMutation();

  return (event: Event<ReportDownloadCsvInfo>) => {
    if (event.status && event.info?.reportType) {
      const syncType = getReportSyncTypeFromEvent(event.info);
      syncSet.mutate({ scope: syncType, status: event.status });
      if (event.status === SyncStatusPlatform.Fail && event.message) {
        displayMessage({
          message: event.message,
          type: DisplayMessage.Error,
        });
      }
    }
  };
};

const useHandleReportDownloadTxf = () => {
  const syncSet = useSetSyncMutation();

  return (event: Event<ReportDownloadTxfInfo>) => {
    if (event.status && event.info?.reportType) {
      const timeframe = createTimeframeFromInfo(event.info);
      const { reportType } = event.info;
      const syncType = getReportDownloadSyncType(
        reportType,
        timeframe,
        ReportFormat.TXF,
      );
      syncSet.mutate({ scope: syncType, status: event.status });
      if (event.status === SyncStatusPlatform.Fail && event.message) {
        displayMessage({
          message: event.message,
          type: DisplayMessage.Error,
        });
      }
    }
  };
};

export const useAllStatusesUpdate = (): SyncDetails => {
  const queryClient = useQueryClient();
  return (
    useQuery({
      queryKey: syncKeys.lists(),
      queryFn: () => {
        const data = queryClient.getQueriesData<SyncStatusPlatform>({
          queryKey: syncKeys.lists(),
        });
        const res: SyncDetails = data.reduce((acc, curr) => {
          const [queryKey, value] = curr;
          const length = queryKey.length;
          const key = queryKey[length - 1] as string;

          // Hacky - was appending the lists itself to the lists each time causing
          // infinite depth, this just stops that
          if (length === 2) {
            return acc;
          }

          return {
            ...acc,
            [key]: value,
          };
        }, {});

        return res;
      },
    }).data || {}
  );
};

/**
 * Should be called by a component to check if it should sync with a collaborator/accountant/client
 * When this function is called by a component it records the time the component is mounted
 * to ensure that syncing is only allowed if the component was mounted before the sync event was
 * received. The user should call the returned function to consume the event which updates
 * the recorded time to the current time to ensure that it does not re-sync on the same event
 * @param relevantCollabEvents - the events that the component should update against
 * @param options.activeClientShouldMatch - if the component should update only when the active
 * client and the invoker of the event match or not (only relevant to an accountant/collaborator)
 * @returns
 */
export const useShouldCollaborationSync = (
  relevantCollabEvents: CollaborationSyncEvent[],
  options?: {
    activeClientShouldMatch?: boolean;
  },
) => {
  const now = () => moment().unix();
  const updatedAtRef = useRef(now());
  const consumeCollabEvent = useCallback(() => {
    updatedAtRef.current = now();
  }, []);
  const user = useUser();
  const { activeDataSource: activeClient } = user ?? {
    activeDataSource: undefined,
  };
  const collaboration = useCollaboration();

  const status = collaboration?.status;
  let shouldCollabSync = false;
  if (status && updatedAtRef.current < collaboration.updatedAt) {
    const invokerUuid = collaboration?.invokerUuid;
    const isEventRelevant = relevantCollabEvents.includes(status);
    const isAffectingActiveClient = invokerUuid === activeClient?.uid;
    shouldCollabSync =
      isEventRelevant &&
      (options?.activeClientShouldMatch ? isAffectingActiveClient : true);
  }
  return { shouldCollabSync, consumeCollabEvent };
};

export const useUploadFailHandler = () => {
  const lang = useLang();
  const setSyncStatus = useSetSyncMutation();
  const getSyncStatus = useGetSyncStatus();

  return (
    {
      name,
      id,
    }: {
      name: string; // display name
      id: string;
    },
    message?: string,
  ) => {
    const currentStatus = getSyncStatus(id);

    if (currentStatus !== SyncStatusPlatform.Pending) {
      // prevent recursive loop
      return;
    }
    // end pending
    setSyncStatus.mutate({ scope: id, status: SyncStatusPlatform.Fail });

    displayMessage({
      message: message || lang.sync.uploadFail({ name }),
      type: DisplayMessage.Info,
    });
  };
};

export const useUploadPendingHandler = () => {
  const lang = useLang();
  const getSyncStatus = useGetSyncStatus();
  const setSyncStatus = useSetSyncMutation();

  return ({
    name,
    id,
    message,
    showMessage,
  }: {
    name: string; // display name
    id: string;
    showMessage?: boolean;
    message?: string;
  }) => {
    const currentStatus = getSyncStatus(id);

    if (currentStatus === SyncStatusPlatform.Pending) {
      // prevent recursive loop
      return;
    }

    // set pending
    setSyncStatus.mutate({ scope: id, status: SyncStatusPlatform.Pending });
    if (showMessage) {
      displayMessage({
        message: message || lang.sync.uploadingFiveMins({ name }),
        type: DisplayMessage.Info,
      });
    }
  };
};

export const useUploadDeprecatedHandler = () => {
  const lang = useLang();
  const getSyncStatus = useGetSyncStatus();

  return ({ id }: { id: string }) => {
    const currentStatus = getSyncStatus(id);

    if (currentStatus === SyncStatusPlatform.Pending) {
      // prevent recursive loop
      return;
    }

    const message =
      (lang.sync.uploadDeprecated as any)[id] ||
      lang.sync.uploadDeprecated.generic;

    displayMessage({
      message,
      type: DisplayMessage.Info,
    });
  };
};

// Export the download function
export { download };
