import { type Blockchain } from "@ctc/types";
import { InfoOutlined } from "@mui/icons-material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Box, Popover, Stack, Tooltip, Typography } from "@mui/material";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useState } from "react";

import { getTrimmedStack } from "~/components/imports-v2/helpers";
import { RuleDrawerById } from "~/components/rules/DisplayRulesDrawer";
import { ActionRowHover } from "~/components/transactions/action-row/ActionRowHover";
import { TransactionRowId } from "~/components/transactions/action-row/TransactionId";
import { Ellipsis } from "~/components/transactions/Ellipsis";
import { getAddressLink, getIdChips } from "~/components/transactions/helpers";
import {
  RowWarning,
  WarningInfo,
} from "~/components/transactions/TransactionRowWarning";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { Chip } from "~/components/ui/Chips";
import { DisplayExchange } from "~/components/ui/DisplayExchange";
import { DisplayExchangeActionItems } from "~/components/ui/DisplayExchangeActionItems";
import { useDesign } from "~/hooks/useTheme";
import { useLocalCurrency } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { formatFunctionName, getPartyDetails } from "~/services/transactions";
import { useUpdateActionRegroupMutation } from "~/state/actions";
import { Side, Size } from "~/types/enums";
import { type ActionRow, type TransactionDetails } from "~/types/index";

const MAX_RENDERED_IDS = 2;
const MAX_RENDERED_NOTES = 1;

function ActionRowCounterpartyCell({
  transaction,
  side,
}: {
  transaction: TransactionDetails;
  side: Side;
}) {
  const {
    currencyIdentifier: currency,
    blockchain,
    importType,
    trade,
  } = transaction;

  const { exchange, displayName, isSmartContract } = getPartyDetails(
    transaction,
    side,
  );

  const { tokens } = useDesign();

  return (
    <Stack direction="row" alignItems="center">
      <Box
        sx={{
          padding: "0.25rem",
          paddingRight: "0.5rem",
          "&:hover": {
            backgroundColor: tokens.background.brand.hover,
            borderRadius: "0.25rem",
          },
        }}
      >
        <DisplayExchange
          exchange={exchange}
          displayName={displayName}
          currencySymbol={currency.symbol}
          blockchain={blockchain as Blockchain}
          side={side}
          isSmartContract={isSmartContract}
          importType={importType}
          trade={trade}
          size={Size.Small}
          enableActions={false}
        />
      </Box>
      <div className="actionRowCounterPartyButtons">
        <DisplayExchangeActionItems
          exchange={exchange}
          addressLink={getAddressLink(exchange, currency.symbol, blockchain)}
        />
      </div>
    </Stack>
  );
}

export function TransactionDetailsInfoPanel({ row }: { row: ActionRow }) {
  const { tokens } = useDesign();
  const lang = useLang();
  const regroupMutation = useUpdateActionRegroupMutation();
  const [isRuleDrawerOpen, setIsRuleDrawerOpen] = useState(false);
  const txs = [...row.outgoing, ...row.incoming, ...row.fees];

  const getDescriptions = () => {
    const descriptions = new Set<string>();

    txs.forEach((tx) => {
      if (tx.description) {
        descriptions.add(
          lang.txTable.expansionPanel.notes({ notes: tx.description }),
        );
      }
    });
    return Array.from(descriptions);
  };

  const txRulesIds = txs.flatMap(
    (tx) => tx.appliedRules?.map((rule) => rule.ruleId) || [],
  );
  const appliedRuleIds = Array.from(new Set(txRulesIds));

  const localCurrency = useLocalCurrency();
  if (!localCurrency) {
    return null;
  }

  const notes = getDescriptions();

  const idChips = getIdChips(row);

  const { shownItems: shownChips, otherItemsCount: remainingIdChips } =
    getTrimmedStack(idChips, MAX_RENDERED_IDS);

  const renderedIdChips = shownChips.map((idChip) => (
    <TransactionRowId key={idChip.id} idChip={idChip} />
  ));

  if (remainingIdChips !== 0) {
    const overflowChips = idChips.slice(MAX_RENDERED_IDS);
    renderedIdChips.push(
      <DetailsOverflowCell
        displayElement={
          <Typography variant="Metropolis/Caption/Medium/Regular">
            {lang.txTable.plusMore({ count: overflowChips.length })}
          </Typography>
        }
        id={`$chips-${row._id}`}
        stackElements={overflowChips.map((idChip) => (
          <TransactionRowId key={idChip.id} idChip={idChip} />
        ))}
      />,
    );
  }

  const { shownItems: shownNotes, otherItemsCount: remainingNotes } =
    getTrimmedStack(notes, MAX_RENDERED_NOTES);

  const renderedNotes = shownNotes.map((note) => (
    <Typography variant="Metropolis/Caption/Medium/Regular" key={note}>
      {note}
    </Typography>
  ));

  if (remainingNotes !== 0) {
    const overflowNotes = notes.slice(MAX_RENDERED_NOTES);
    renderedNotes.push(
      <DetailsOverflowCell
        displayElement={
          <Typography variant="Metropolis/Caption/Medium/Regular">
            {lang.txTable.plusMore({ count: overflowNotes.length })}
          </Typography>
        }
        id={`$notes-${row._id}`}
        stackElements={overflowNotes.map((note) => (
          <Typography variant="Metropolis/Caption/Medium/Regular" key={note}>
            {note}
          </Typography>
        ))}
      />,
    );
  }

  let sentBy = null;
  let receivedFrom = null;
  let tradedOn = null;

  if (row.outgoing.length === 0 && row.incoming.length > 0) {
    const tx = row.incoming[0];
    receivedFrom = (
      <ActionRowCounterpartyCell
        transaction={tx}
        side={Side.From}
        key={tx._id}
      />
    );
  } else if (row.incoming.length === 0 && row.outgoing.length > 0) {
    const tx = row.outgoing[0];
    sentBy = (
      <ActionRowCounterpartyCell transaction={tx} side={Side.To} key={tx._id} />
    );
  } else if (row.incoming.length > 0 && row.outgoing.length > 0) {
    const tx = row.incoming[0];
    tradedOn = (
      <ActionRowCounterpartyCell
        transaction={tx}
        side={Side.From}
        key={tx._id}
      />
    );
  }

  const dontGroup = [...row.outgoing, ...row.incoming].some(
    (tx) => tx.dontGroup,
  );

  return (
    <Box
      p="0.3rem 1.5rem"
      borderBottom={`1px solid ${tokens.border.neutral.default}`}
      minHeight="2.8rem"
    >
      <Stack
        alignItems="center"
        gap="1rem"
        minHeight="2rem"
        flexDirection="row"
      >
        {row.functionNames.length ? (
          <Stack
            direction="row"
            alignItems="center"
            gap="0.5rem"
            flexShrink={0}
          >
            <Box>
              <Typography variant="Metropolis/Caption/Medium/Regular" flexShrink={0}>
                {lang.txTable.expansionPanel.functionNameLabel}:
              </Typography>
            </Box>
            {row.functionNames.map((functionName, index) => {
              const formattedFunctionName = formatFunctionName(functionName);
              return (
                <Tooltip
                  title={formattedFunctionName}
                  key={index}
                  placement="top-start"
                >
                  <div>
                    <Chip>
                      <Ellipsis maxWidth={140}>
                        {formattedFunctionName}
                      </Ellipsis>
                    </Chip>
                  </div>
                </Tooltip>
              );
            })}
          </Stack>
        ) : null}
        <Stack direction="row" alignItems="center" gap="0.25rem">
          <Typography variant="Metropolis/Caption/Medium/Regular" flexShrink={0}>
            {lang.txTable.expansionPanel.transactionId({
              count: idChips.length,
            })}
            :
          </Typography>
          {renderedIdChips}
        </Stack>
        <Stack direction="row" alignItems="center" gap="0.5rem">
          <Typography variant="Metropolis/Caption/Medium/Regular" flexShrink={0}>
            {sentBy
              ? lang.txTable.expansionPanel.sentBy
              : receivedFrom
                ? lang.txTable.expansionPanel.receivedFrom
                : lang.txTable.expansionPanel.tradedOn}
            :
          </Typography>
          {sentBy}
          {receivedFrom}
          {tradedOn}
        </Stack>
        <Stack direction="row" alignItems="center" gap="0.5rem">
          {renderedNotes}
        </Stack>

        <Box flexGrow={1} />

        {dontGroup ? (
          <RowWarning
            warning={lang.txTable.expansionPanel.ungroup.title}
            warningIcon={<InfoOutlined style={{ fontSize: "16" }} />}
            info={
              <WarningInfo
                title={lang.txTable.expansionPanel.ungroup.infoTitle}
                icon={<InfoOutlined style={{ fontSize: "16" }} />}
                message={lang.txTable.expansionPanel.ungroup.infoBody}
                actionButtonText={
                  lang.txTable.expansionPanel.ungroup.buttonText
                }
                onActionButtonClick={() => {
                  regroupMutation.mutateAsync({ id: row._id });
                }}
                actionButtonLoading={regroupMutation.isPending}
              />
            }
          />
        ) : null}
        {appliedRuleIds.length > 0 ? (
          <>
            <RuleDrawerById
              isOpen={isRuleDrawerOpen}
              setIsOpen={setIsRuleDrawerOpen}
              ruleIds={appliedRuleIds}
            />
            <Box>
              <TextButton
                size="small"
                startIcon={<InsertLinkIcon sx={{ color: tokens.text.brand }} />}
                onClick={() => {
                  setIsRuleDrawerOpen(true);
                }}
              >
                <Typography
                  variant="Metropolis/Caption/Medium/Regular"
                  sx={{ color: tokens.text.brand }}
                >
                  {lang.txTable.expansionPanel.ruleApplied}
                </Typography>
              </TextButton>
            </Box>
          </>
        ) : null}
      </Stack>
    </Box>
  );
}

export function DetailsOverflowCell({
  id,
  stackElements,
  displayElement,
}: {
  id: string;
  stackElements: JSX.Element[];
  displayElement: JSX.Element;
}) {
  const { tokens } = useDesign();

  const popupState = usePopupState({
    variant: "popover",
    popupId: `details-overflow-${id}`,
  });

  return (
    <>
      <Box
        display="flex"
        gap="0.5rem"
        alignItems="flex-start"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ActionRowHover
          {...bindTrigger(popupState)}
          display="flex"
          flexWrap="nowrap"
          gap="0.5rem"
          sx={{
            cursor: "pointer",
            borderRadius: "0.25rem",
          }}
        >
          {displayElement}
        </ActionRowHover>
      </Box>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Stack
          maxHeight="20rem"
          overflow="auto"
          bgcolor={tokens.background.neutral.lowest.default}
          p="0.25rem"
        >
          {stackElements}
        </Stack>
      </Popover>
    </>
  );
}
