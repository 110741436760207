export type TextSegment =
  | {
      text: string;
    }
  | {
      text: string;
      key: string;
    };

export function getTextWithLinks(text: string): TextSegment[] {
  const segments: TextSegment[] = [];
  // Matches for occurrences of [link text](key)
  const regex = /\[(.*?)\]\s*\(\s*(.*?)\s*\)/g;
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  // While we still have matches left over in the string
  while ((match = regex.exec(text)) !== null) {
    const [, linkText, key] = match;
    // Grab the text, key and link text from the match
    // slice, and push to the segments

    const prefix = text.slice(lastIndex, match.index);
    lastIndex = regex.lastIndex;

    if (prefix) {
      segments.push({ text: prefix });
    }

    segments.push({ text: linkText, key });

    if (regex.lastIndex >= text.length) {
      break;
    }
  }

  // Grab the last bit of text after the last match and push to the segments
  const suffix = text.slice(lastIndex);
  if (suffix) {
    segments.push({ text: suffix });
  }

  return segments;
}
