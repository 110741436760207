import { type SyncStatusPlatform } from "@ctc/types";

import { type ERPProvider } from "~/components/settings-modal/views/enums";
import { type ErpAccount, type ErpUserAccountsMap } from "~/redux/types";
import { type Data, get, post } from "~/services/core";
import { type RollupPeriod } from "~/services/enums";
import { type ErpUserAccounts } from "~/types/enums";

export type ERPTenant = { tenantId: string; tenantName: string };
export type ERPSettings = SettingsPayload & { erp: ERPProvider };
export type ERPSettingsUpdate = Omit<SettingsPayload, "rollupPeriod"> & {
  erp: ERPProvider;
} & {
  rollupPeriod: RollupPeriod | null; // so this can be null
};

export type SettingsPayload = {
  connectedAt?: number;
  lastSyncCompleted?: number;
  syncStatus: SyncStatusPlatform;
  accounts: Record<
    ErpUserAccounts,
    { accountCode: string | null; type: string }
  >;
  isTokenExpired: boolean;
  tenantId?: string;
  erpType: ERPProvider;
  rollupPeriod?: RollupPeriod;
  txWithWarningsNotReadyToSync: boolean;
};

export function getErpSettings(
  erp: ERPProvider,
): Promise<Data<SettingsPayload>> {
  const link = `/erp/${erp}/settings`;
  return get<SettingsPayload>(link);
}

export function getErpTenants(erp: ERPProvider): Promise<Data<ERPTenant[]>> {
  const link = `/erp/${erp}/tenants`;
  return get<ERPTenant[]>(link);
}

export function getErpAvailableAccounts(
  erp: ERPProvider,
  { refetchFromErp }: { refetchFromErp: boolean },
): Promise<Data<ErpAccount[]>> {
  const link = `/erp/${erp}/accounts?refetchFromErp=${refetchFromErp}`;
  return get<ErpAccount[]>(link);
}

export function revokeErp(erp: ERPProvider) {
  const link = `/erp/${erp}/revoke`;
  return post<void>(link, {});
}

export function updateErpSettings(
  erp: ERPProvider,
  account: Partial<ErpUserAccountsMap>,
) {
  const link = `/erp/${erp}/settings`;
  return post<void>(link, account);
}

export function updateErpRollup(
  erp: ERPProvider,
  update: Partial<ERPSettingsUpdate>,
) {
  const link = `/erp/${erp}/rollup`;
  return post<void>(link, update);
}

export function updateErpTenant(erp: ERPProvider, data: { tenantId: string }) {
  const link = `/erp/${erp}/tenant`;
  return post<void>(link, data);
}

export function updateErpWarningsNotReadyToSync(
  erp: ERPProvider,
  txWithWarningsNotReadyToSync: boolean,
) {
  const link = `/erp/${erp}/txWithWarningsNotReadyToSync`;
  return post<void>(link, { txWithWarningsNotReadyToSync });
}
