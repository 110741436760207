import { Check, Edit, RestartAlt } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { type GridRenderCellParams } from "@mui/x-data-grid";
import { useContext, useState } from "react";
import styled from "styled-components/macro";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TaxLossHarvestingContext } from "~/contexts/TaxLossHarvestingContext";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue, safeFloat } from "~/lib/index";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";

export function PriceCell({ id, value: existingValue }: GridRenderCellParams) {
  const { tokens } = useDesign();
  const tlhContext = useContext(TaxLossHarvestingContext);
  const user = useUser();
  const [isHoveredOver, setIsHoveredOver] = useState<boolean>(false);
  const captureAnalytics = useCaptureAnalytics();

  const lang = useLang();

  const [newValue, setNewValue] = useState<number | undefined>();
  if (!tlhContext || !user) return null;

  const isModified = tlhContext.modifiedPrices.has(id as string);

  const isEditing = tlhContext.isEditing === id;

  const toggleEdit = () => {
    if (!isEditing) {
      tlhContext.updateIsEditing(id);
      return;
    }

    if (newValue && newValue != existingValue)
      tlhContext.updateModifiedPrices(
        new Map(tlhContext.modifiedPrices).set(
          id as string,
          safeFloat(newValue),
        ),
      );
    tlhContext.updateIsEditing();

    //clear the newValue so that next time they open this they will be editing the current price
    setNewValue(undefined);

    captureAnalytics("tlh_price_edited", {
      price: newValue,
      currencyId: id,
    });
  };

  const resetPrice = () => {
    const newModifiedPrices = new Map(tlhContext.modifiedPrices);
    newModifiedPrices.delete(id as string);
    tlhContext.updateModifiedPrices(newModifiedPrices);

    setNewValue(undefined);
  };

  const showResetPriceButton =
    isModified || (existingValue !== newValue && newValue !== undefined);

  return (
    <div
      onMouseEnter={() => {
        setIsHoveredOver(true);
      }}
      onMouseLeave={() => {
        setIsHoveredOver(false);
      }}
    >
      <Box display="flex" alignItems="center">
        {isEditing ? (
          <InputNoArrows
            variant="outlined"
            size="small"
            InputProps={{
              sx: {
                fontSize: "0.875rem",
                fontWeight: 500,
                paddingRight: 0,
                fontFamily: tokens.fontFamilies.numeric,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {showResetPriceButton ? (
                    <Tooltip
                      title={lang.taxLossHarvesting.iconTooltips.resetPrice}
                    >
                      <TextIconButton
                        size="small"
                        onClick={resetPrice}
                        sx={{
                          svg: {
                            width: "1.25rem",
                            height: "1.25rem",
                          },
                        }}
                      >
                        <RestartAlt color="inherit" />
                      </TextIconButton>
                    </Tooltip>
                  ) : null}
                </InputAdornment>
              ),
            }}
            // see link as to why there are 2 inputProps
            // https://stackoverflow.com/a/69872110
            inputProps={{
              min: 0,
              step: "any",
            }}
            onChange={(e) => {
              setNewValue(parseFloat(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") toggleEdit();
            }}
            type="number"
            value={newValue === undefined ? existingValue : newValue}
          />
        ) : (
          <Typography
            sx={{
              color: !isModified ? tokens.text.default : tokens.text.warning,
              fontSize: "0.875rem",
              fontWeight: "bold",
              fontFamily: tokens.fontFamilies.numeric,
            }}
          >
            {displayFiatValue({
              value: existingValue,
              localCurrency: user?.localCurrency,
              locale: user?.language,
            })}
          </Typography>
        )}
        <Tooltip
          title={
            isEditing
              ? lang.taxLossHarvesting.iconTooltips.savePrice
              : lang.taxLossHarvesting.iconTooltips.editPrice
          }
        >
          <TextIconButton
            size="small"
            onClick={toggleEdit}
            sx={{
              marginLeft: "0.5rem",
              cursor: "pointer",
              visibility: isHoveredOver || isEditing ? "visible" : "hidden",

              svg: {
                width: "1.25rem",
                height: "1.25rem",
              },
            }}
          >
            {isEditing ? <Check /> : <Edit />}
          </TextIconButton>
        </Tooltip>
      </Box>
    </div>
  );
}

// hide arrows in number input
const InputNoArrows = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
