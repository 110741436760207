import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

// This component is used to set the default page title
// The title will be shown in the browser tab e.g. "Signup - Crypto Tax Calculator"
// Note: Dynamic links (e.g. /import/:exchange) will need to be handled in the component
// If it's not handled in the component, it will use a default title instead
export const DefaultPageTitle = () => {
  const path = useLocation().pathname;
  const lang = useLang();

  const titleName: Record<Links, string> = {
    [Links.Signup]: lang.pageTitle.Signup,
    [Links.Invite]: lang.pageTitle.Invite,
    [Links.SignupAccountant]: lang.pageTitle.SignupAccountant,
    [Links.Login]: lang.pageTitle.Login,
    [Links.TokenLogin]: lang.pageTitle.TokenLogin,
    [Links.Dashboard]: lang.pageTitle.Dashboard,
    [Links.Imports]: lang.pageTitle.Imports,
    [Links.ImportNew]: lang.pageTitle.ImportNew,
    [Links.ImportSearch]: lang.pageTitle.ImportSearch,
    [Links.ImportCustom]: lang.pageTitle.ImportCustom,
    [Links.BalanceLedger]: lang.pageTitle.BalanceLedger,
    [Links.Transactions]: lang.pageTitle.Transactions,
    [Links.TransactionsUncategorised]: lang.pageTitle.TransactionsUncategorised,
    [Links.TransactionsNegativeBalance]:
      lang.pageTitle.TransactionsNegativeBalance,
    [Links.TransactionsMissingPrice]: lang.pageTitle.TransactionsMissingPrice,
    [Links.TransactionsSearch]: lang.pageTitle.TransactionsSearch,
    [Links.TransactionsAdd]: lang.pageTitle.TransactionsAdd,
    [Links.Wallets]: lang.pageTitle.Wallets,
    [Links.Report]: lang.pageTitle.Report,
    [Links.PaymentSuccess]: lang.pageTitle.PaymentSuccess,
    [Links.Reset]: lang.pageTitle.Reset,
    [Links.Forgot]: lang.pageTitle.Forgot,
    [Links.Billing]: lang.pageTitle.Billing,
    [Links.Plans]: lang.pageTitle.Plans,
    [Links.Payment]: lang.pageTitle.Payment,
    [Links.Clients]: lang.pageTitle.Clients,
    [Links.ClientsAdd]: lang.pageTitle.ClientsAdd,
    [Links.Unlock]: lang.pageTitle.Unlock,
    [Links.Reconciliation]: lang.pageTitle.Reconciliation,
    [Links.ReconciliationUncategorisedTransaction]:
      lang.pageTitle.ReconciliationUncategorisedTransaction,
    [Links.ReconciliationMissingPriceIssues]:
      lang.pageTitle.ReconciliationMissingPriceIssues,
    [Links.ReconciliationNegativeBalanceIssues]:
      lang.pageTitle.ReconciliationNegativeBalanceIssues,
    [Links.ReconciliationMissingBlockchain]:
      lang.pageTitle.ReconciliationMissingBlockchain,
    [Links.ReconciliationUnmatchedTransfer]:
      lang.pageTitle.ReconciliationUnmatchedTransfer,
    [Links.PaymentStatus]: lang.pageTitle.PaymentStatus,
    [Links.PaymentPending]: lang.pageTitle.PaymentPending,
    [Links.Developer]: lang.pageTitle.Developer,
    [Links.DeveloperSeedData]: lang.pageTitle.DeveloperSeedData,
    [Links.DeveloperEditorIntegration]:
      lang.pageTitle.DeveloperEditorIntegration,
    [Links.DeveloperEntities]: lang.pageTitle.DeveloperEntities,
    [Links.DeveloperReport]: lang.pageTitle.DeveloperReport,
    [Links.DeveloperSessionRecording]: lang.pageTitle.DeveloperSessionRecording,
    [Links.DeveloperUserData]: lang.pageTitle.DeveloperUserData,
    [Links.Alpha]: lang.pageTitle.Alpha,
    [Links.OptInSupportAccess]: lang.pageTitle.OptInSupportAccess,
    [Links.Ledger]: lang.pageTitle.Ledger,
    [Links.Onboarding]: lang.pageTitle.Onboarding,
    [Links.OnboardingSelectType]: lang.pageTitle.OnboardingSelectType,
    [Links.OnboardingSelectTheme]: lang.pageTitle.OnboardingSelectTheme,
    [Links.OnboardingSelectCountry]: lang.pageTitle.OnboardingSelectCountry,
    [Links.OnboardingWallets]: lang.pageTitle.OnboardingWallets,
    [Links.OnboardingImports]: lang.pageTitle.OnboardingImports,
    [Links.OnboardingImportsSearch]: lang.pageTitle.OnboardingImportsSearch,
    [Links.OnboardingImportsNew]: lang.pageTitle.OnboardingImportsNew,
    [Links.OnboardingImportsCustom]: lang.pageTitle.OnboardingImportsCustom,
    [Links.OnboardingPlatformTypes]: lang.pageTitle.OnboardingPlatformTypes,
    [Links.OnboardingTradingTypes]: lang.pageTitle.OnboardingTradingTypes,
    [Links.OnboardingAccuracy]: lang.pageTitle.OnboardingAccuracy,
    [Links.OnboardingTaxableTrades]: lang.pageTitle.OnboardingTaxableTrades,
    [Links.OnboardingTaxableStaking]: lang.pageTitle.OnboardingTaxableStaking,
    [Links.OnboardingImportsOauth]: lang.pageTitle.OnboardingImportsOauth,
    [Links.OnboardingAreYouSure]: lang.pageTitle.OnboardingAreYouSure,
    [Links.OnboardingImportCEXReminder]:
      lang.pageTitle.OnboardingImportCEXReminder,
    [Links.OnboardingImportsCalculating]:
      lang.pageTitle.OnboardingImportsCalculating,
    [Links.OnboardingReconUncategorisedTransactions]:
      lang.pageTitle.OnboardingReconUncategorisedTransactions,
    [Links.OnboardingOnYourWay]: lang.pageTitle.OnboardingOnYourWay,
    [Links.OnboardingPayment]: lang.pageTitle.OnboardingPayment,
    [Links.OnboardingPaymentStatus]: lang.pageTitle.OnboardingPaymentStatus,
    [Links.OnboardingPaymentSuccess]: lang.pageTitle.OnboardingPaymentSuccess,
    [Links.ContactsIdentified]: lang.pageTitle.ContactsIdentified,
    [Links.ContactsUnidentified]: lang.pageTitle.ContactsUnidentified,
    [Links.Contacts]: lang.pageTitle.Contacts,
    [Links.Rules]: lang.pageTitle.Rules,
    [Links.AccountingIntegrations]: lang.pageTitle.AccountingIntegrations,
    [Links.ReOnboarding]: lang.pageTitle.ReOnboarding,
    [Links.ReOnboardingWelcomeBack]: lang.pageTitle.ReOnboardingWelcomeBack,
    [Links.ReOnboardingAccuracy]: lang.pageTitle.ReOnboardingAccuracy,
    [Links.ReOnboardingTaxableTrades]: lang.pageTitle.ReOnboardingTaxableTrades,
    [Links.ReOnboardingTaxableStaking]:
      lang.pageTitle.ReOnboardingTaxableStaking,
    [Links.ReferAFriend]: lang.pageTitle.ReferAFriend,
    [Links.RestoreUserData]: lang.pageTitle.RestoreUserData,
    [Links.Close]: lang.pageTitle.Close,
    [Links.OnboardingProviderWelcome]: lang.pageTitle.OnboardingProviderWelcome,
    [Links.OnboardingProviderSetPassword]:
      lang.pageTitle.OnboardingProviderSetPassword,
    [Links.OnboardingProviderPasswordSetSuccess]:
      lang.pageTitle.OnboardingProviderPasswordSetSuccess,
    [Links.OnboardingProviderSyncing]: lang.pageTitle.OnboardingProviderSyncing,
    [Links.OnboardingEmbededOfferIntro]:
      lang.pageTitle.OnboardingEmbededOfferIntro,
    [Links.ConnectProvider]: lang.pageTitle.ConnectProvider,
    [Links.PartnerBenefit]: lang.pageTitle.PartnerBenefit,
  };

  const pageTitle = titleName[path as Links];

  if (!pageTitle) {
    return null;
  }

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

// For dynamic link, add this function to the component to set dynamic page title
// e.g.for link /import/:exchange, pass in the exchange as title
export const DynamicPageTitle = ({ title }: { title: string }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
