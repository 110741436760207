import { Chip } from "~/components/ui/Chips";
import { type ErpAccount } from "~/redux/types";

/**
 * Get bulk options for selecting an account
 * @param accounts
 * @returns
 */
export function getOptionsFromErpAccounts(accounts: ErpAccount[]) {
  return (
    accounts.map((erpAccount) => ({
      label: erpAccount.name,
      icon: <Chip>{erpAccount.code}</Chip>,
      erpAccount,
      chevron: true,
    })) ?? []
  );
}
