import { type ReactNode } from "react";

import { StepItemType } from "~/components/nav/enum";

type StepItemBase = {
  name: string;
  icon?: ReactNode;
  subItems?: StepItemDetails[];
};

type InteractableStepItemBase = StepItemBase & {
  id: string;
  disabled?: boolean;
  endAdornment?: ReactNode; // Icon to display on the right side of the button i.e. manage subscription
};

export type LinkItemDetails = InteractableStepItemBase & {
  type: StepItemType.Link;
  to: string;
  position?: number;
  tooltip?: NonNullable<React.ReactNode>;
  className?: string;
};

export type ButtonItemDetails = InteractableStepItemBase & {
  type: StepItemType.Button;
  onClick: () => void;
};

export type SubheadingItemDetails = StepItemBase & {
  type: StepItemType.Subheading;
};

type DividerItemDetails = StepItemBase & {
  type: StepItemType.Divider;
};

export type StepItemDetails =
  | LinkItemDetails
  | ButtonItemDetails
  | SubheadingItemDetails
  | DividerItemDetails;

export function isLinkItemDetails(
  item: StepItemDetails,
): item is LinkItemDetails {
  return item.type === StepItemType.Link;
}

export function isButtonItemDetails(
  item: StepItemDetails,
): item is ButtonItemDetails {
  return item.type === StepItemType.Button;
}

export function isSubheadingItemDetails(
  item: StepItemDetails,
): item is SubheadingItemDetails {
  return item.type === StepItemType.Subheading;
}

export function isDividerItemDetails(
  item: StepItemDetails,
): item is DividerItemDetails {
  return item.type === StepItemType.Divider;
}
