import type * as React from "react";

import { AiContextProvider } from "~/contexts/AiContext";
import { EmailVerificationContextProvider } from "~/contexts/EmailVerificationContext";
import { FYContextProvider } from "~/contexts/FYContext";
import { PaywallModalContextProvider } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { ReportDownloadContextProvider } from "~/contexts/ReportDownloadContext";
import { ReportModalsContextProvider } from "~/contexts/ReportModalsContext";
import { TaxLossHarvestingContextProvider } from "~/contexts/TaxLossHarvestingContext";

// these are applied top down
// so if a context needs another context it should appear below it in the array
const providers = [
  FYContextProvider,
  TaxLossHarvestingContextProvider, // needs FY context
  EmailVerificationContextProvider,
  ReportDownloadContextProvider,
  PaywallModalContextProvider,
  ReportModalsContextProvider, // needs report download apparently
  AiContextProvider,
];

export const ContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return providers.reduceRight((acc, Provider) => {
    return <Provider>{acc}</Provider>;
  }, children) as JSX.Element;
};
