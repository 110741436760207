import { type Trade } from "@ctc/types";
import qs from "query-string";

import { deleteRequest, get, post, put } from "~/services/core";
import { type BulkOperations } from "~/types/enums";
import {
  type ActionType,
  type CurrencyIdentifier,
  type FilterQuery,
} from "~/types/index";

type ErpAccountCode = string;
export type RuleBulkOperation =
  | {
      type: BulkOperations.ChangeErpAssetAccount;
      erpAccountCode: ErpAccountCode;
      assetCurrency?: CurrencyIdentifier;
    }
  | {
      type: BulkOperations.ChangeErpCashAccount;
      erpAccountCode: ErpAccountCode;
      account?: string;
    }
  | {
      type: BulkOperations.ChangeErpPnLAccount;
      erpAccountCode: ErpAccountCode;
      tradeType?: Trade;
    }
  | {
      type: BulkOperations.ChangeErpGainsAccount;
      erpAccountCode: ErpAccountCode;
      tradeType?: Trade;
    }
  | {
      type: BulkOperations.ChangeErpLoanAccount;
      erpAccountCode: ErpAccountCode;
      tradeType?: Trade;
    }
  | {
      type: BulkOperations.Recategorise;
      toActionType: ActionType;
    };

export type ErpRuleBulkOperation = Exclude<
  RuleBulkOperation,
  {
    type: BulkOperations.Recategorise;
  }
>;
export type RuleDTO = {
  id: string;
  name: string;
  filter: FilterQuery; // the filter query for the rule
  operations: RuleBulkOperation[]; // the list of bulk operations for the rule
  isDisabled: boolean; // is the rule disabled
};

export function getRules({ disabled = false }: { disabled?: boolean }) {
  const path = `/rule?${qs.stringify({ disabled })}`;
  return get<RuleDTO[]>(path);
}

export function createNewRule({
  name,
  filter,
  operations,
}: {
  name: string;
  filter: FilterQuery;
  operations: RuleBulkOperation[];
}) {
  const path = "/rule";
  return post<RuleDTO>(path, { name, filter, operations });
}

export function deleteRule({ id }: { id: string }) {
  const path = `/rule/${id}`;
  return deleteRequest(path);
}

export function reorderRule({
  id,
  newIndex,
}: {
  id: string;
  newIndex: number;
}) {
  const path = `/rule/${id}/reorder`;
  return put(path, { newIndex });
}

export function updateRule({
  id,
  update,
}: {
  id: string;
  update: { name?: string; isDisabled?: boolean };
}) {
  const path = `/rule/${id}`;
  return put(path, update);
}

export function getRulesByIds({ ids }: { ids: string[] }) {
  const path = `/rule/rules?${qs.stringify({ ids })}`;
  return get<RuleDTO[]>(path);
}

export function editRule({
  id,
  name,
  filter,
  operations,
}: {
  id: string;
  name: string;
  filter: FilterQuery;
  operations: RuleBulkOperation[];
}) {
  const path = `/rule/${id}`;
  return post<RuleDTO>(path, { name, filter, operations });
}
