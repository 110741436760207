import { type PartnerWithEmployeeBenefit } from "@ctc/types";
import { Box, Stack, Typography } from "@mui/material";

import { REFERRER_CONFIG } from "~/components/payment/referrer-config";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

import { StyledChip } from "./StyledChip";
import { StyledContainer } from "./StyledContainer";

export function Header({ partner }: { partner: PartnerWithEmployeeBenefit }) {
  const lang = useLang();
  const { tokens } = useDesign();
  const partnerLabel = REFERRER_CONFIG[partner]?.label ?? partner;
  return (
    <StyledContainer sx={{ marginTop: "8rem" }}>
      {REFERRER_CONFIG[partner]?.partnerLogo()}

      <Box>
        <Typography variant="Metropolis/Header/H1">
          {lang.auth.signupPage.accurateCryptoTaxes}
        </Typography>

        <Typography variant="Metropolis/Header/H1">{lang.auth.signupPage.noGuesswork}</Typography>
      </Box>

      <Stack direction="column" gap="0.5rem" sx={{ maxWidth: "23rem" }}>
        <Typography variant="Metropolis/Header/H5">
          {lang.partnerEmployeeBenefits.discount.title({
            partner: partnerLabel,
          })}
        </Typography>
        <StyledChip>
          <Typography variant="Metropolis/Header/H4" color={tokens.text.success}>
            {lang.partnerEmployeeBenefits.discount.description}
          </Typography>
        </StyledChip>
      </Stack>
    </StyledContainer>
  );
}
