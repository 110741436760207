import { useCallback } from "react";

import { importAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { useImportViewMode } from "~/components/imports/ImportViewMode";
import { TableHeaders } from "~/components/imports-v2/enums";
import { LocalStorageKey } from "~/constants/enums";
import {
  LocalStorageUserKeyGenerator,
  useLocalStorage,
} from "~/hooks/useLocalStorage";
import { useUser } from "~/redux/auth";

export const useCaptureImportAnalytics = () => {
  const user = useUser();
  const captureAnalytics = useCaptureAnalytics();
  const importViewMode = useImportViewMode();
  const [sortOrder] = useLocalStorage(
    LocalStorageUserKeyGenerator(user?.uid, LocalStorageKey.AccountSortOption),
    {
      field: TableHeaders.Balance,
      ascending: false,
    },
  );

  const callback = useCallback(
    (event: string, properties: Record<string, unknown> = {}) => {
      const importPageProps = {
        importViewMode: importViewMode.viewMode,
        accountsTableSort: sortOrder.field,
      };
      captureAnalytics(event, { ...properties, ...importPageProps });
    },
    [captureAnalytics, importViewMode, sortOrder],
  );
  return callback;
};

export const importGlobalKey = importAnalyticsKey("global");
export const importDrawerKey = importAnalyticsKey("drawer");
export const importTableKey = importAnalyticsKey("table");
export const importAccountKey = importAnalyticsKey("account");

export const importAddIntegrationKey = importGlobalKey(
  "add integration clicked",
);
