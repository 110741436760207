import type * as React from "react";
import { createContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LocalStorageKey } from "~/constants/enums";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useLocalStorage } from "~/hooks/useLocalStorage";

interface LocationState {
  from?: Location;
}

type LoginRedirectContextType = {
  redirect?: string;
};

const initialContext = {
  redirect: undefined,
};

export const LoginRedirectContext =
  createContext<LoginRedirectContextType>(initialContext);

export const LoginRedirectContextProvider = ({
  children,
  isRedirector,
}: {
  children: React.ReactNode;
  isRedirector: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isEmbedded = useIsEmbedded();
  const [redirect, setRedirect, unsetRedirect] = useLocalStorage(
    LocalStorageKey.LoginRedirect,
    { redirect: "" },
  );

  useEffect(() => {
    if (isRedirector && redirect.redirect) {
      const pathname = redirect.redirect;
      unsetRedirect();
      navigate(pathname, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirector, redirect]);

  useEffect(() => {
    if (isRedirector || isEmbedded) {
      return;
    }

    const state = location.state as LocationState | undefined;
    const from = state?.from;
    if (from) {
      const { pathname = "", search = "" } = from;
      const newRedirect = pathname + search;
      if (
        newRedirect &&
        newRedirect !== redirect.redirect &&
        newRedirect !== "/"
      ) {
        setRedirect({ redirect: newRedirect });
      } else if (newRedirect === "/") {
        unsetRedirect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirector, location.state, redirect.redirect, isEmbedded]);

  return (
    <LoginRedirectContext.Provider value={redirect}>
      {children}
    </LoginRedirectContext.Provider>
  );
};
