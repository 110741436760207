import { Blockchain } from "@ctc/types";

import { HttpError } from "~/services/core";

import { type ConnectWalletConfigHandler } from "../types";

const isInstalled =
  !!window?.LeatherProvider &&
  window.LeatherProvider?.isLeather &&
  typeof window?.LeatherProvider?.request === "function";

export const leatherConfig: ConnectWalletConfigHandler = {
  walletConnect: async () => {
    const resp = await window.LeatherProvider.request("getAddresses");

    if (resp.error) {
      throw new HttpError(
        {
          status: 500,
          error: true,
          msg: resp.error.message,
        },
        ["walletConnect"],
      );
    }

    const initialState: Record<Blockchain.BTC | Blockchain.Stacks, string[]> = {
      [Blockchain.BTC]: [],
      [Blockchain.Stacks]: [],
    };

    return resp.result.addresses.reduce((acc, { symbol, address }) => {
      if (symbol === "BTC") {
        acc[Blockchain.BTC].push(address);
      } else if (symbol === "STX") {
        acc[Blockchain.Stacks].push(address);
      }

      return acc;
    }, initialState);
  },
  shouldIgnoreBlockchainSelector: true,
  isInstalled,
};
