import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import {
  Box,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { captureAnalytics } from "~/analytics/posthog";
import { getReferralLink } from "~/components/refer-a-friend/helpers";
import { LinkBox } from "~/components/refer-a-friend/LinkBox";
import { Calco } from "~/components/ui/calco/Calco";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import {
  PrimaryButton,
  PrimaryLoadingButton,
} from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryLoadingButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { REFERRAL_REWARD } from "~/constants/constants";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useIsReferEnabled } from "~/hooks/useIsReferEnabled";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib";
import { HyperlinkTextContent } from "~/lib/HyperlinkTextContent";
import { useLocalCurrency, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  useGetPromoterDetails,
  useGetReferralCode,
  useUpdatePaypal,
} from "~/state/referral";
import { ReferralProgramType } from "~/types/enums";

export function ReferAFriend() {
  const lang = useLang().referAFriend;
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const { data } = useGetReferralCode(ReferralProgramType.InAppAmbassadorCash);
  const user = useUser();

  const isFeatureEnabled = useIsReferEnabled();
  const navigate = useNavigate();

  if (!isFeatureEnabled) {
    navigate("/");
  }

  return (
    <Box>
      <ReferralBox>
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            maxWidth="40.25rem"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="Metropolis/Header/H1" color={tokens.text.high}>
                {lang.title({
                  reward: displayFiatValue({
                    value: REFERRAL_REWARD,
                    localCurrency: user?.planCurrency,
                    fractionDigits: 0,
                  }),
                })}
              </Typography>
            </Box>
            <Typography variant="Metropolis/Body/Regular">
              {lang.subtext({
                reward: displayFiatValue({
                  value: REFERRAL_REWARD,
                  localCurrency: user?.planCurrency,
                  fractionDigits: 0,
                }),
              })}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap="0.375rem"
              alignItems="flex-start"
            >
              <LinkBox programType={ReferralProgramType.InAppAmbassadorCash} />
            </Box>
            <SocialLinks link={getReferralLink(data?.code)} />
            <Typography variant="Metropolis/Body/Regular">
              {lang.workWithUs}
            </Typography>
            <Typography variant="Metropolis/Body/Regular" marginTop="-1rem">
              <HyperlinkTextContent
                text={lang.messageUs({ linkTextKey: "linkTextKey" })}
                actions={{
                  linkTextKey: {
                    linkTo: "https://twitter.com/CryptoTaxHQ",
                    onClickAction: () => {
                      captureAnalytics("raf_message_us_clicked");
                    },
                  },
                }}
              />
            </Typography>
          </Box>
          {!isMobile && (
            <Box alignSelf="center">
              <Calco type="cowboy" style={{ width: "10.875rem" }} />
            </Box>
          )}
        </Box>
      </ReferralBox>
      <PaymentSection />
      <Box maxWidth="57.5rem" justifySelf="center" width="100%">
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.low}
        >
          {lang.disclaimer({
            reward: displayFiatValue({
              value: REFERRAL_REWARD,
              localCurrency: user?.planCurrency,
              fractionDigits: 0,
            }),
          })}
        </Typography>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.low}
        >
          <HyperlinkTextContent
            text={lang.moreDetails({ linkTextKey: "linkTextKey" })}
            actions={{
              linkTextKey: {
                linkTo:
                  "https://help.cryptotaxcalculator.io/en/articles/10833144-refer-a-friend-score-100-cash-offer",
                onClickAction: () => {
                  captureAnalytics("raf_more_details_clicked");
                },
              },
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}

function PaymentSection() {
  const { tokens } = useDesign();
  const lang = useLang().referAFriend.paypalSection;
  const [isOpen, setIsOpen] = useState(false);
  const [paypalEmail, setPaypalEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const localCurrency = useLocalCurrency();
  const isMobile = useIsMobile();
  const { mutate: updatePaypal, isPending: isUpdatingPaypal } = useUpdatePaypal(
    ReferralProgramType.InAppAmbassadorCash,
  );

  const { data: promoterDetails, isLoading } = useGetPromoterDetails(
    ReferralProgramType.InAppAmbassadorCash,
  );

  const isConnected = !!promoterDetails?.paypalEmail;

  /**
   * Validates if the provided string is a valid email format
   */
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  /**
   * Checks if the current email input is valid for submission
   */
  const isEmailValid = useMemo(() => {
    if (!paypalEmail) return false;
    return validateEmail(paypalEmail);
  }, [paypalEmail]);

  /**
   * Handle email input change with validation
   */
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPaypalEmail(value);

    if (value && !validateEmail(value)) {
      setEmailError(lang.error);
    } else {
      setEmailError(null);
    }
  };

  return (
    <>
      <ReferralBox>
        <Typography variant="Metropolis/Header/H4">{lang.title}</Typography>
        <Box
          display="flex"
          justifyContent={isMobile ? "flex-start" : "space-between"}
          alignItems={isMobile ? "flex-start" : "center"}
          flexDirection={isMobile ? "column" : "row"}
          gap="1rem"
        >
          <Typography variant="Metropolis/Caption/Large/Regular">
            {isConnected ? lang.connectedSubtext : lang.subtext}
          </Typography>
          {isMobile ? (
            <Box
              display="flex"
              gap="0.5rem"
              justifyContent="flex-start"
              alignItems="center"
            >
              <TertiaryLoadingButton
                loading={isLoading}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {isConnected ? lang.modal.updateCTA : lang.connectCTA}
              </TertiaryLoadingButton>
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                color={tokens.text.low}
              >
                {promoterDetails?.paypalEmail}
              </Typography>
            </Box>
          ) : (
            <PrimaryLoadingButton
              loading={isLoading}
              onClick={() => {
                setIsOpen(true);
              }}
              size="small"
            >
              {isConnected ? lang.modal.updateCTA : lang.connectCTA}
            </PrimaryLoadingButton>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          gap="1rem"
          mt="1rem"
          flexDirection={isMobile ? "column" : "row"}
        >
          <ReferralMetric
            title={lang.plansPurchased}
            tooltip={lang.plansPurchasedTooltip}
            value={(promoterDetails?.customers ?? 0).toString()}
            pending={isLoading}
          />
          <ReferralMetric
            title={lang.available}
            tooltip={lang.availableTooltip}
            value={displayFiatValue({
              value: promoterDetails?.currentBalance ?? 0,
              localCurrency,
            })}
            pending={isLoading}
            cta={{
              label: lang.claimCTA,
              onClick: () => {},
              // TODO: undisable this
              disabled: true,
              tooltip: lang.claimCTATooltip,
            }}
          />
          <ReferralMetric
            title={lang.lifetimeEarnings}
            tooltip={lang.lifetimeEarningsTooltip}
            value={displayFiatValue({
              value: promoterDetails?.paidBalance ?? 0,
              localCurrency,
            })}
            pending={isLoading}
          />
        </Box>
      </ReferralBox>

      <GeneralDialog
        title={isConnected ? lang.modal.updateTitle : lang.modal.connectTitle}
        actionText={isConnected ? lang.modal.updateCTA : lang.modal.connectCTA}
        isOpen={isOpen}
        pending={isUpdatingPaypal}
        handleClose={() => {
          setIsOpen(false);
          setPaypalEmail("");
          setEmailError(null);
        }}
        handleAction={() => {
          updatePaypal(paypalEmail);
        }}
        disableAction={!isEmailValid}
      >
        <Box display="flex" flexDirection="column" gap="1rem" minWidth="20rem">
          <Typography variant="Metropolis/Body/Regular">
            {isConnected && promoterDetails?.paypalEmail
              ? lang.modal.updateSubtext({
                  username: promoterDetails.paypalEmail,
                })
              : lang.modal.connectSubtext}
          </Typography>
          <TextField
            fullWidth
            value={paypalEmail}
            onChange={handleEmailChange}
            sx={{ width: "100%" }}
            placeholder={
              isConnected
                ? lang.modal.updatePlaceholder
                : lang.modal.connectPlaceholder
            }
            error={!!emailError}
            helperText={emailError}
            type="email"
          />
        </Box>
      </GeneralDialog>
    </>
  );
}

function SocialLinks({ link }: { link: string }) {
  const lang = useLang();
  const telegramLink = `https://t.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(lang.referAFriend.socialLinks.telegram({ link }))}`;

  const xLink = `https://twitter.com/intent/post?text=${encodeURIComponent(lang.referAFriend.socialLinks.x({ link }))}`;

  const emailSubject = lang.referAFriend.socialLinks.emailSubject;
  const emailLink = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(lang.referAFriend.socialLinks.email({ link }))}`;

  return (
    <Box display="flex" gap="0.5rem">
      <IconButton
        target="_blank"
        onClick={() => {
          captureAnalytics("raf_x_clicked");
        }}
        href={xLink}
      >
        <XIcon />
      </IconButton>
      <IconButton
        target="_blank"
        onClick={() => {
          captureAnalytics("raf_telegram_clicked");
        }}
        href={telegramLink}
      >
        <TelegramIcon />
      </IconButton>
      <IconButton
        target="_blank"
        onClick={() => {
          captureAnalytics("raf_email_clicked");
        }}
        href={emailLink}
      >
        <EmailOutlinedIcon />
      </IconButton>
    </Box>
  );
}

export function ReferralMetric({
  title,
  tooltip,
  value,
  cta,
  pending,
}: {
  title: string;
  tooltip: string;
  value: string;
  cta?: {
    label: string;
    disabled?: boolean;
    onClick: () => void;
    tooltip?: string;
  };
  pending?: boolean;
}) {
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  return (
    <ReferralMetricWrapper>
      <Box display="flex">
        <Typography variant="Metropolis/Caption/Medium/Regular">
          {title}
        </Typography>
        <Tooltip title={tooltip} placement="top">
          <InfoOutlinedIcon
            sx={{ color: tokens.text.low, fontSize: "1rem", ml: "0.5rem" }}
          />
        </Tooltip>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? "column" : "row"}
        gap="0.5rem"
      >
        {pending ? (
          <Skeleton variant="text" width="4rem" height="3rem" />
        ) : (
          <Typography variant="IBM Plex Mono/Header/H3">{value}</Typography>
        )}
        {cta && (
          <Tooltip title={cta.tooltip ?? ""} placement="top">
            <span>
              <PrimaryButton
                disabled={cta.disabled}
                onClick={cta.onClick}
                sx={{ width: isMobile ? "100%" : undefined }}
                size="small"
              >
                {cta.label}
              </PrimaryButton>
            </span>
          </Tooltip>
        )}
      </Box>
    </ReferralMetricWrapper>
  );
}

export const ReferralMetricWrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background-color: ${({ theme }) => theme.tokens.elevation.low};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

const ReferralBox = styled(Box)`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background-color: ${({ theme }) => theme.tokens.elevation.low};
  border-radius: 0.5rem;
  max-width: 57.5rem;
  justify-self: center;
  width: 100%;
`;
