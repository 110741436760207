import { useDispatch } from "react-redux";

import { Variation } from "~/components/settings-modal/views/enums";
import { ExitSurvey } from "~/components/settings-modal/views/profile/ExitSurvey";
import {
  cancellationSurveyCompleted,
  useCheckUserCancellationSurveyOnFocus,
  useUser,
} from "~/redux/auth";

export const CancellationSurvey = () => {
  const user = useUser();
  const dispatch = useDispatch();

  useCheckUserCancellationSurveyOnFocus();

  return (
    <ExitSurvey
      variation={Variation.CancelPlan}
      isOpen={!!user?.shouldShowCancellationSurvey}
      handleSubmit={() => dispatch(cancellationSurveyCompleted())}
    />
  );
};
