import {
  FirstPage,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import { Box, FormControl, MenuItem, Popover, Stack } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  bindPopover,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { HARD_GROUPED_TX_IMPORT_LIMIT } from "~/constants/constants";
import { useTransactionsRealCount } from "~/hooks/useTransactions";
import { useLang } from "~/redux/lang";
import { Align } from "~/types/enums";

const StyledPaginationBox = styled(Box)`
  display: flex;
  align-items: center;

  // everything except the first and last
  & > div:not(:first-child):not(:last-child) .MuiButton-root {
    border-radius: 0;
  }
  // everything except the last
  & > div:not(:last-child) .MuiButton-root {
    border-right: none;
  }
  // The one that is next to the hovered button that is not disabled
  & > div:hover:not(:has(.Mui-disabled)) + div .MuiButton-root,
  // This is for the page selector that is focused
  & > div:has(.page-selector-focused) + div > .MuiButton-root {
    border-left: 1px ${({ theme }) => theme.tokens.border.neutral.highest} solid;
  }
  // the first one
  & > div:first-child .MuiButton-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  // the last one
  & > div:last-child .MuiButton-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const Pagination = ({
  count,
  rowsPerPage,
  rowsPerPageOptions,
  page,
  onPageChange,
  onRowsPerPageChange,
  isLoading = false,
  disabled = false,
  align = Align.Right,
  nextLink,
  prevLink,
  firstLink,
  lastLink,
  onAfterLinkClicked,
}: {
  count: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  page: number;
  onPageChange: (e: any, page: number) => void;
  onRowsPerPageChange: (rows: number) => void;
  align?: Align;
  isLoading?: boolean;
  disabled?: boolean;
  nextLink?: string;
  prevLink?: string;
  firstLink?: string;
  lastLink?: string;
  onAfterLinkClicked?: () => void;
}) => {
  const lang = useLang();
  const [pageNum, setPageNum] = useState(page); // for optimistic update
  const totalPages = Math.ceil(count / rowsPerPage);
  const onChange = (e: any, p: number) => {
    setPageNum(p);
    onPageChange(e, p);
  };

  useEffect(() => {
    setPageNum(page);
  }, [setPageNum, page]);

  // The page numbers to display in the dropdown
  const pageItems = useMemo(
    () => Array.from({ length: totalPages }, (_, i) => i + 1),
    [totalPages],
  );

  const totalCount = useTransactionsRealCount();
  // `go to page` is really non-performant at high page counts
  const tooManyTxsToAllowGoToPage = totalCount > HARD_GROUPED_TX_IMPORT_LIMIT;

  return (
    <Stack
      direction="row"
      spacing="0.5rem"
      justifyContent={align === Align.Left ? "flex-start" : "flex-end"}
    >
      <FormControl variant="standard">
        <TertiarySelect
          value={rowsPerPage}
          data={rowsPerPageOptions}
          onChange={(e: any, rows: number) => {
            onRowsPerPageChange(rows);
            setPageNum(1);
          }}
          isLoading={isLoading}
        />
      </FormControl>

      <StyledPaginationBox>
        {firstLink ? (
          <Box>
            <StyledIconButton
              component={Link}
              to={firstLink}
              disabled={pageNum === 1 || isLoading || disabled}
              onClick={() => onAfterLinkClicked?.()}
            >
              <FirstPage fontSize="small" />
            </StyledIconButton>
          </Box>
        ) : (
          <Box>
            <StyledIconButton
              onClick={(e: any) => {
                onChange(e, 1);
              }}
              disabled={pageNum === 1 || isLoading || disabled}
              aria-label="first page"
            >
              <FirstPage fontSize="small" />
            </StyledIconButton>
          </Box>
        )}
        {prevLink ? (
          <Box>
            <StyledIconButton
              component={Link}
              to={prevLink}
              disabled={pageNum === 1 || isLoading || disabled}
              onClick={() => onAfterLinkClicked?.()}
            >
              <KeyboardArrowLeft fontSize="small" />
            </StyledIconButton>
          </Box>
        ) : (
          <Box>
            <StyledIconButton
              onClick={(e: any) => {
                onChange(e, pageNum - 1);
              }}
              disabled={pageNum === 1 || isLoading || disabled}
              aria-label="previous page"
            >
              <KeyboardArrowLeft fontSize="small" />
            </StyledIconButton>
          </Box>
        )}
        <Box>
          <TertiarySelect
            value={pageNum}
            displayValue={lang.txTable.pagination.pageXofY({
              pageNum,
              totalPages: totalPages || 1,
            })}
            data={pageItems}
            onChange={onChange}
            isLoading={isLoading}
            disabled={disabled || tooManyTxsToAllowGoToPage}
          />
        </Box>
        {nextLink ? (
          <Box>
            <StyledIconButton
              component={Link}
              to={nextLink}
              disabled={pageNum >= totalPages || isLoading || disabled}
              onClick={() => onAfterLinkClicked?.()}
            >
              <KeyboardArrowRight fontSize="small" />
            </StyledIconButton>
          </Box>
        ) : (
          <Box>
            <StyledIconButton
              onClick={(e: any) => {
                onChange(e, pageNum + 1);
              }}
              disabled={pageNum >= totalPages || isLoading || disabled}
              aria-label="next page"
            >
              <KeyboardArrowRight fontSize="small" />
            </StyledIconButton>
          </Box>
        )}
        {lastLink ? (
          <Box>
            <StyledIconButton
              component={Link}
              to={lastLink}
              disabled={pageNum >= totalPages || isLoading || disabled}
              onClick={() => onAfterLinkClicked?.()}
            >
              <LastPage fontSize="small" />
            </StyledIconButton>
          </Box>
        ) : (
          <Box>
            <StyledIconButton
              onClick={(e: any) => {
                onChange(e, Math.max(0, totalPages));
              }}
              disabled={pageNum >= totalPages || isLoading || disabled}
              aria-label="last page"
            >
              <LastPage fontSize="small" />
            </StyledIconButton>
          </Box>
        )}
      </StyledPaginationBox>
    </Stack>
  );
};

function TertiarySelect({
  value,
  displayValue,
  data,
  onChange,
  isLoading = false,
  disabled = false,
}: {
  value: string | number;
  data: number[];
  onChange: (e: any, value: number) => void;
  displayValue?: string;
  isLoading?: boolean;
  disabled?: boolean;
}) {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "tertiary-popup",
  });

  return (
    <div>
      <StyledButton
        className={popupState.isOpen ? "page-selector-focused" : undefined}
        sx={{ border: "1px solid black" }}
        endIcon={disabled ? null : <KeyboardArrowDown />}
        {...bindToggle(popupState)}
        disabled={disabled}
        data-uncensored="true"
      >
        {displayValue ? displayValue : value}
      </StyledButton>
      <Popover
        {...bindPopover(popupState)}
        onClose={() => {
          popupState.close();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: "left",
        }}
        keepMounted
      >
        <PageItemsVirtualized
          isLoading={isLoading}
          disabled={disabled}
          pageItems={data}
          onChange={(e, item) => {
            popupState.close();
            onChange(e, item);
          }}
          selectedPageValue={value}
        />
      </Popover>
    </div>
  );
}

/**
 * The virtualised list of page items the user can click on
 */
function PageItemsVirtualized({
  pageItems,
  onChange,
  selectedPageValue,
  isLoading,
  disabled,
}: {
  pageItems: number[];
  onChange: (e: any, value: number) => void;
  selectedPageValue: number | string;
  isLoading: boolean;
  disabled: boolean;
}) {
  const parentRef = useRef(null);

  const itemHeightPx = 35;
  const itemWidthPx = 100;
  const rowsToShow = 4;
  const rowVirtualizer = useVirtualizer({
    count: pageItems.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemHeightPx,
    overscan: 5,
  });

  return (
    <div
      ref={parentRef}
      className="List"
      style={{
        height: `${rowsToShow * itemHeightPx}px`,
        width: `${itemWidthPx}px`,
        overflow: "auto",
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
          <StyledMenuItem
            key={virtualRow.index}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
            }}
            onMouseDown={(e) => {
              onChange(e, pageItems[virtualRow.index]);
            }}
            selected={pageItems[virtualRow.index] == selectedPageValue}
            disabled={isLoading || disabled}
            data-uncensored="true"
          >
            {pageItems[virtualRow.index]}
          </StyledMenuItem>
        ))}
      </div>
    </div>
  );
}

const StyledIconButton = styled((props: any) => <TertiaryButton {...props} />)`
  && {
    height: 1.75rem;
    padding: 0.375rem;
    min-width: 0;
    white-space: nowrap;
  }
`;

const StyledButton = styled(TertiaryButton)`
  && {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    white-space: nowrap;
    height: 1.75rem;
    padding: 0.375rem 0.5rem;
    min-width: 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;

  :hover {
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.purple.lowest};
    color: ${({ theme }) => theme.tokens.text.brand};
  }
  & .Mui-selected {
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.purple.lowest};
    color: ${({ theme }) => theme.tokens.text.brand};
  }
`;

export function scrollIntoElement(id: string) {
  const element = document.getElementById(id);

  setTimeout(() => {
    if (element) {
      element.scrollIntoView();
    }
  }, 0);
}
