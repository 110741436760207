import { type Blockchain } from "@ctc/types";
import qs from "query-string";

import { Impact } from "~/components/reconciliation/enums";
import { get, post } from "~/services/core";
import {
  Links,
  ReconciliationIssues,
  ReconciliationSteps,
  Warning,
} from "~/types/enums";
import {
  type ReconciliationIssuesPayload,
  type ReconciliationMetadata,
} from "~/types/index";

import { useBestUser } from "../redux/auth";

export async function getMetadata() {
  return get<ReconciliationMetadata>("/reconciliation/metadata");
}

export async function getReconciliationIssuesForType<
  T extends ReconciliationIssues,
>(
  type: T,
  query?: {
    cursor?: string;
    noPagination?: boolean;
  },
) {
  const queryString = qs.stringify(query ?? {});
  return get<ReconciliationIssuesPayload<T>>(
    `/reconciliation-issues/${type}?${queryString}`,
  );
}

export async function ignoreMissingPrice(currencyId: string) {
  return post<any>("/reconciliation/ignore-missing-price", { currencyId });
}

export async function ignoreAllMissingPrice() {
  return post<any>("/reconciliation/ignore-all-missing-price", {});
}

type BulkLookupData = {
  updatedCurrencies: string[];
  updatedCount: number;
};

export async function bulkLookupMarketPrice() {
  return post<BulkLookupData>("/reconciliation/bulk-lookup-market-price", {});
}

export async function assignBlockchainSource(
  source: string,
  blockchain: Blockchain,
) {
  return post<any>("/reconciliation/assign-blockchain-source", {
    source,
    blockchain,
  });
}

export const useReconciliationItems = () => {
  const user = useBestUser();
  const reconciliationItems: Record<
    ReconciliationIssues,
    {
      impact: Impact;
      step: ReconciliationSteps;
      path: Links;
      /** Whether this rec issue is one that appears on the rec page (used for loading the whole rec page) */
      isOnReconciliationPage: boolean;
      /** Prevents the bucket from being shown in the UI and fetched from the BE */
      hidden?: boolean;
      /** The warning this reconciliation issue correlates to */
      warning?: Warning;
    }
  > = {
    [ReconciliationIssues.UncategorisedTransactions]: {
      impact: Impact.Medium,
      path: Links.ReconciliationUncategorisedTransaction,
      step: ReconciliationSteps.Categorise,
      warning: Warning.Uncategorised,
      isOnReconciliationPage: true,
    },
    [ReconciliationIssues.MissingBlockchain]: {
      impact: Impact.High,
      path: Links.ReconciliationMissingBlockchain,
      step: ReconciliationSteps.Prepare,
      warning: Warning.MissingBlockchain,
      isOnReconciliationPage: true,
    },
    [ReconciliationIssues.MissingPrices]: {
      impact: Impact.Low,
      path: Links.ReconciliationMissingPriceIssues,
      step: ReconciliationSteps.Resolve,
      warning: Warning.MissingPrice,
      isOnReconciliationPage: true,
    },
    [ReconciliationIssues.NegativeBalances]: {
      impact: Impact.Medium,
      path: Links.ReconciliationNegativeBalanceIssues,
      step: ReconciliationSteps.Resolve,
      warning: Warning.NegativeBalance,
      isOnReconciliationPage: true,
    },
    [ReconciliationIssues.UnknownAddress]: {
      impact: Impact.High,
      path: Links.Reconciliation,
      step: ReconciliationSteps.Import,
      hidden: true,
      isOnReconciliationPage: false,
    },
    [ReconciliationIssues.UnmatchedTransfer]: {
      impact: Impact.High,
      path: Links.ReconciliationUnmatchedTransfer,
      step: ReconciliationSteps.Resolve,
      hidden: !user?.notifications?.showUnmatchedTransfers,
      warning: Warning.UnmatchedTransfer,
      isOnReconciliationPage: true,
    },
    [ReconciliationIssues.SuspectedMissingImport]: {
      impact: Impact.High,
      path: Links.Reconciliation,
      step: ReconciliationSteps.Import,
      hidden: true,
      isOnReconciliationPage: false,
    },
  };

  return reconciliationItems;
};
