import { type Trade } from "@ctc/types";
import ArticleIcon from "@mui/icons-material/Article";

import { DualChip } from "~/components/transactions/command-palette/views/DualChip";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { useLang } from "~/redux/lang";
import { type ErpAccount } from "~/redux/types";

/**
 * The chips at the top of bulk change asset account
 */
export const PnLAccountTopSection = ({
  tradeType,
  pnlAccount,
}: {
  tradeType?: Trade;
  pnlAccount?: ErpAccount;
}) => {
  const lang = useLang();
  const Icon = tradeType ? TradeIcons[tradeType] : null;
  return (
    <DualChip
      leftIcon={tradeType && Icon ? <Icon /> : undefined}
      leftLabel={
        tradeType
          ? getActionTypeName({ actionType: tradeType, lang })
          : lang.txTable.commandPallet.chips.type
      }
      rightLabel={
        pnlAccount
          ? pnlAccount.name
          : lang.txTable.expansionPanel.chartOfAccounts.plAccount
      }
      rightIcon={
        pnlAccount ? (
          <ArticleIcon sx={{ height: "1rem", width: "1rem" }} />
        ) : undefined
      }
    />
  );
};
