import { SyncStatusPlatform } from "@ctc/types";
import {
  type QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useMemo } from "react";

import { REQUIRED_ACCOUNTS_IN_SELECTION_ORDER } from "~/components/accounting-integrations/DefaultsCard";
import { ERPProvider } from "~/components/settings-modal/views/enums";
import { displayMessage } from "~/components/ui/Toaster";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { type ErpAccount, type ErpUserAccountsMap } from "~/redux/types";
import { HttpError } from "~/services/core";
import * as erpAPI from "~/services/erp";
import { type ERPSettings, type ERPSettingsUpdate } from "~/services/erp";
import { actionKeys } from "~/state/actions";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import { ruleKeys } from "~/state/rules";
import { DisplayMessage } from "~/types/enums";
import { type ErpUserAccounts } from "~/types/enums";

export const erpKeys = {
  all: () => ["erp"] as const,

  settings: () => [...erpKeys.all(), "settings"] as const,
  availableAccounts: () => [...erpKeys.all(), "availableAccounts"] as const,
  tenants: () => [...erpKeys.all(), "tenants"] as const,
};

export const useErpSettingsQuery = () => {
  const user = useUser();
  return useQuery({
    queryKey: erpKeys.settings(),
    queryFn: async () => {
      async function betOnBothSides(erp: ERPProvider) {
        const settingsRes = await erpAPI.getErpSettings(erp);
        if (settingsRes.error || !settingsRes.data) {
          return;
        }

        return { erp, ...settingsRes.data };
      }

      const results = await Promise.all(
        Object.values(ERPProvider).map(betOnBothSides),
      );
      const erpSettings = results.find((res) => !!res);

      if (!erpSettings) {
        return null; // Thus if null there is no erp connected
      }

      return erpSettings;
    },
    enabled: !!user,
  });
};

async function getERPAvailableAccounts(erp: ERPProvider) {
  const res = await erpAPI.getErpAvailableAccounts(erp, {
    refetchFromErp: false,
  });

  if (res.error) {
    throw new HttpError(res, ["erpAvailableAccounts"]);
  }

  return res.data;
}

export const useErpAvailableAccountsQuery = () => {
  const erpSettings = useErpSettingsQuery();

  return useQuery({
    queryKey: erpKeys.availableAccounts(),
    queryFn: async () => {
      if (!erpSettings.data) {
        // Should never get here, query isnt enabled if ERP isnt connected
        throw new Error("No erp connected");
      }

      const { erp } = erpSettings.data;
      return getERPAvailableAccounts(erp);
    },
    enabled: !!erpSettings.data?.erp,
  });
};

/**
 * 99% of the time you want to use the useErpAvailableAccountsQuery hook instead
 * But if you want to need to run it as an async method you can use this
 * @param queryClient Get from useQueryClient if possible
 * @returns
 */
export const fetchErpAvailableAccountsQuery = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}) => {
  const erpSettings = queryClient.getQueryData<ERPSettings | null>(
    erpKeys.settings(),
  );
  if (!erpSettings) {
    return null;
  }

  const erp = erpSettings.erp;

  return queryClient.fetchQuery({
    queryKey: erpKeys.availableAccounts(),
    queryFn: () => {
      return getERPAvailableAccounts(erp);
    },
  });
};

export const useErpTenantsQuery = () => {
  const erpSettingsQuery = useErpSettingsQuery();
  const lang = useLang();

  return useQuery({
    queryKey: erpKeys.tenants(),
    queryFn: async () => {
      if (!erpSettingsQuery.data) {
        // Should never get here, query isnt enabled if ERP isnt connected
        throw new Error("No erp connected");
      }

      const { erp } = erpSettingsQuery.data;

      const res = await erpAPI.getErpTenants(erp);

      if (res.error) {
        displayMessage({
          message: res.msg || lang.xero.errors.fetchingTenants,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["erpTenants"]);
      }

      return res.data;
    },
    enabled: !!erpSettingsQuery.data?.erp,
  });
};

export const useErpUserAccounts = () => {
  const erpSettingsQuery = useErpSettingsQuery();
  const availableAccountsQuery = useErpAvailableAccountsQuery();

  return useMemo(() => {
    if (!erpSettingsQuery.data) {
      return null;
    }

    const { accounts: existingAccounts } = erpSettingsQuery.data;
    const availableAccounts = availableAccountsQuery.data || [];

    const userAccounts: ErpUserAccountsMap = Object.entries(
      existingAccounts,
    ).reduce((acc, [account, obj]) => {
      const matchingAccount = availableAccounts.find(
        ({ code }) => code === obj.accountCode,
      );
      return {
        ...acc,
        [account]: matchingAccount || null,
      };
    }, {} as ErpUserAccountsMap);
    return userAccounts;
  }, [erpSettingsQuery.data, availableAccountsQuery.data]);
};

const erpSettingsOptimisticUpdate = async (
  queryClient: QueryClient,
  update: Partial<ERPSettingsUpdate>,
) => {
  const previous = queryClient.getQueryData<ERPSettingsUpdate>(
    erpKeys.settings(),
  );

  // optimistic update sync state (and time?)
  // Cancel outgoing refetch (so they don't overwrite optimistic update).
  await queryClient.cancelQueries({ queryKey: erpKeys.settings() });

  // Optimistically update to the new value
  queryClient.setQueryData<ERPSettingsUpdate>(erpKeys.settings(), (old) => {
    if (!old) {
      return old;
    }
    const accountsUpdate = update.accounts
      ? { ...old.accounts, ...update.accounts }
      : undefined;
    return {
      ...old,
      ...update,
      ...{ ...(accountsUpdate ? { accounts: accountsUpdate } : {}) },
    };
  });

  return { previous };
};

export const useUpdateTenantMutation = () => {
  const queryClient = useQueryClient();
  const erpSettingsQuery = useErpSettingsQuery();
  const lang = useLang();

  return useMutation({
    mutationFn: async ({ tenantId }: { tenantId: string }) => {
      if (!erpSettingsQuery.data) {
        // Should never get here, query isnt enabled if ERP isnt connected
        throw new Error("No erp connected");
      }

      const { erp } = erpSettingsQuery.data;

      const res = await erpAPI.updateErpTenant(erp, {
        tenantId,
      });

      if (res.error) {
        displayMessage({
          message: res.msg || lang.xero.errors.updatingTenant,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["updateXeroTenant"]);
      }
      return res.data;
    },
    onMutate: async (variables: { tenantId: string }) => {
      const { tenantId } = variables;
      const { previous } = await erpSettingsOptimisticUpdate(queryClient, {
        tenantId,
      });

      return { previous };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(erpKeys.settings(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: erpKeys.availableAccounts() });
      queryClient.invalidateQueries({ queryKey: erpKeys.settings() });
      return;
    },
  });
};

export const useDisconnectErpMutation = () => {
  const queryClient = useQueryClient();
  const erpSettingsQuery = useErpSettingsQuery();
  const lang = useLang();

  return useMutation({
    mutationFn: async () => {
      if (!erpSettingsQuery.data) {
        // Should never get here, query isnt enabled if ERP isnt connected
        throw new Error("No erp connected");
      }

      const { erp } = erpSettingsQuery.data;

      const res = await erpAPI.revokeErp(erp);

      if (res.error) {
        displayMessage({
          message: res.msg || lang.xero.errors.disconnecting,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["revokeXero"]);
      }
      return res.data;
    },
    onMutate: async () => {
      const previous = queryClient.getQueryData<ERPSettings>(
        erpKeys.settings(),
      );

      // optimistic update sync state (and time?)
      // Cancel outgoing refetch (so they don't overwrite optimistic update).
      await queryClient.cancelQueries({ queryKey: erpKeys.settings() });

      // Optimistically update to the new value
      queryClient.setQueryData(erpKeys.settings(), null);

      return { previous };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(erpKeys.settings(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess() {
      queryClient.setQueryData(erpKeys.availableAccounts(), null);
      queryClient.invalidateQueries({ queryKey: ruleKeys.all() });
      return queryClient.invalidateQueries({ queryKey: erpKeys.all() });
    },
  });
};

export const useUpdateErpAccountMutation = () => {
  const queryClient = useQueryClient();
  const erpSettingsQuery = useErpSettingsQuery();
  const lang = useLang();

  return useMutation({
    mutationFn: async ({
      account,
      value,
    }: {
      account: ErpUserAccounts;
      value: ErpAccount;
    }) => {
      if (!erpSettingsQuery.data) {
        // Should never get here, query isnt enabled if ERP isnt connected
        throw new Error("No erp connected");
      }

      const { erp } = erpSettingsQuery.data;

      const res = await erpAPI.updateErpSettings(erp, {
        [account]: value.code,
      });

      if (res.error) {
        displayMessage({
          message: res.msg || lang.xero.errors.disconnecting,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["updateXeroSettings"]);
      }

      return res.data;
    },
    onMutate: async (variables: {
      account: ErpUserAccounts;
      value: ErpAccount;
    }) => {
      const { account, value } = variables;
      const { previous } = await erpSettingsOptimisticUpdate(queryClient, {
        accounts: {
          [account]: { accountCode: value.code, type: value.accountType },
        } as Record<ErpUserAccounts, { accountCode: string; type: string }>,
      });

      return { previous };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(erpKeys.settings(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess() {
      // need to invalidate the all the actions as the accounts have changed
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
      return queryClient.invalidateQueries({ queryKey: erpKeys.settings() });
    },
  });
};

export const useUpdateErpRollupMutation = () => {
  const queryClient = useQueryClient();
  const erpSettingsQuery = useErpSettingsQuery();
  const lang = useLang();

  return useMutation({
    mutationFn: async (update: Partial<ERPSettingsUpdate>) => {
      if (!erpSettingsQuery.data) {
        // Should never get here, query isnt enabled if ERP isnt connected
        throw new Error("No erp connected");
      }

      const { erp, rollupPeriod } = erpSettingsQuery.data;
      if (
        Object.keys(update).length === 1 &&
        update?.rollupPeriod === rollupPeriod
      ) {
        return;
      }

      const res = await erpAPI.updateErpRollup(erp, update);

      if (res.error) {
        displayMessage({
          message: res.msg || lang.xero.errors.disconnecting,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["updateXeroSettings"]);
      }

      return res.data;
    },
    onMutate: async (update: Partial<ERPSettingsUpdate>) => {
      const { previous } = await erpSettingsOptimisticUpdate(
        queryClient,
        update,
      );

      return { previous };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(erpKeys.settings(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: erpKeys.settings() });
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};

// TODO - think how to handle this better, current solution means 2 requests :/
export const useRefreshAccountListFromSourceMutation = () => {
  const queryClient = useQueryClient();
  const erpSettingsQuery = useErpSettingsQuery();
  const lang = useLang();

  return useMutation({
    mutationFn: async () => {
      if (!erpSettingsQuery.data) {
        // Should never get here, query isnt enabled if ERP isnt connected
        throw new Error("No erp connected");
      }

      const { erp } = erpSettingsQuery.data;

      const res = await erpAPI.getErpAvailableAccounts(erp, {
        refetchFromErp: true,
      });

      if (res.error) {
        displayMessage({
          message: res.msg || lang.xero.errors.fetchingSettings,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["erpAvailableAccounts"]);
      }

      return res.data;
    },
    onSuccess() {
      const erpProvider = erpSettingsQuery.data?.erp;
      if (erpProvider) {
        displayMessage({
          message: lang[erpProvider].buttons.refreshList.completed,
          type: DisplayMessage.Success,
        });
      }
      return queryClient.invalidateQueries({
        queryKey: erpKeys.availableAccounts(),
      });
    },
  });
};

export const useIsErpReadyToSync = () => {
  const erp = useErpSettingsQuery().data;
  const erpAvailableAccounts = useErpAvailableAccountsQuery();
  const userAccounts = useErpUserAccounts();

  return (
    erp &&
    erp.syncStatus !== SyncStatusPlatform.Pending &&
    !erpAvailableAccounts.isFetching &&
    userAccounts &&
    Object.entries(userAccounts).every(
      ([account, value]) =>
        !REQUIRED_ACCOUNTS_IN_SELECTION_ORDER.includes(
          account as ErpUserAccounts,
        ) || value !== null,
    )
  );
};

export const useUpdateErpWarningsNotReadyToSync = () => {
  const queryClient = useQueryClient();
  const erpSettingsQuery = useErpSettingsQuery();
  const lang = useLang();

  return useMutation({
    mutationFn: async (update: { txWithWarningsNotReadyToSync: boolean }) => {
      const { txWithWarningsNotReadyToSync } = update;
      if (!erpSettingsQuery.data) {
        // Should never get here, query isnt enabled if ERP isnt connected
        throw new Error("No erp connected");
      }

      const { erp } = erpSettingsQuery.data;
      const res = await erpAPI.updateErpWarningsNotReadyToSync(
        erp,
        txWithWarningsNotReadyToSync,
      );

      if (res.error) {
        displayMessage({
          message: res.msg || lang.xero.errors.disconnecting,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["updateXeroSettings"]);
      }

      return res.data;
    },
    onMutate: async (update: Partial<ERPSettingsUpdate>) => {
      const { previous } = await erpSettingsOptimisticUpdate(
        queryClient,
        update,
      );

      return { previous };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(erpKeys.settings(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: erpKeys.settings() });
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
};
