import { InfoOutlined, OpenInNew, SaveAlt } from "@mui/icons-material";
import {
  AccordionActions,
  Box,
  Button,
  Link,
  Typography,
  type TypographyProps,
  useMediaQuery,
} from "@mui/material";
import styled from "styled-components";

import { ImportNoteCritical } from "~/components/imports/ImportBody";
import { ImportInstructionsAccordion } from "~/components/imports/ImportInstructionsAccordion";
import { StyledExternalLink } from "~/components/reconciliation/StyledLink";
import { StyledOpenInNew } from "~/components/reconciliation/StyledOpenInNew";
import { devices } from "~/components/ui/theme/legacy";
import {
  ADVANCED_CSV_DOWNLOAD,
  ADVANCED_XLSX_DOWNLOAD,
  SHEET_ADVANCED_CSV_TEMPLATE,
} from "~/constants/constants";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { ImportStepType } from "~/types/enums";

// Used when manual CSV is the only supported CSV method.
export function ManualCSVActions() {
  const lang = useLang().imports.manualCSV;
  return (
    <>
      <Typography variant="Metropolis/Body/Light" mt="1rem">
        {lang.seeAdvancedGuide.preLinkText}{" "}
        <Link
          href={lang.seeAdvancedGuide.link}
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
        >
          {lang.seeAdvancedGuide.linkText}
        </Link>{" "}
        {lang.seeAdvancedGuide.postLinkText}
      </Typography>
      <ManualCSVActionsDownloadBox />
      <ExternalLink sx={{ mt: "1rem" }} />
    </>
  );
}

export function ExternalLink({ sx }: { sx: TypographyProps["sx"] }) {
  const lang = useLang().imports.manualCSV;
  return (
    <StyledExternalLink
      target="_blank"
      rel="noreferrer"
      href={lang.learnMore.link}
      fontSize="0.75rem"
      fontWeight={500}
      sx={{ mt: "1rem", ...sx }}
    >
      <span>{lang.learnMore.text}</span>
      <StyledOpenInNew
        style={{
          fontSize: "1rem",
        }}
      />
    </StyledExternalLink>
  );
}

// Used when the exchange has its own CSV format
export function ManualCSVImportNote({
  customTitle,
  customPrelinkText,
  type = ImportStepType.Note,
}: {
  customTitle?: string;
  customPrelinkText?: string;
  type?: ImportStepType.Note | ImportStepType.Critical;
}) {
  const lang = useLang().imports.manualCSV;
  const isDesktop = useMediaQuery(devices.laptop);
  const manualNote = {
    title: customTitle || lang.customCSVToExchange.title,
    plainText: (
      <Typography variant="Metropolis/Body/Light">
        {customPrelinkText || lang.customCSVToExchange.description.preLinkText}{" "}
        <Link
          href={lang.customCSVToExchange.description.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {lang.customCSVToExchange.description.linkText}
        </Link>{" "}
      </Typography>
    ),
    items: lang.steps,
    type,
  } as const;
  const actions = (
    <AccordionActions
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}
    >
      <Button
        color="primary"
        endIcon={<SaveAlt fontSize="small" sx={{ mt: "-0.25rem" }} />}
        sx={{
          fontSize: "0.75rem",
        }}
        href={ADVANCED_XLSX_DOWNLOAD}
      >
        {lang.excelText}
      </Button>
      <Button
        color="primary"
        endIcon={<OpenInNew fontSize="small" />}
        sx={{
          ml: "0.75rem",
          fontSize: "0.75rem",
          whiteSpace: isDesktop ? "nowrap" : "normal",
        }}
        href={SHEET_ADVANCED_CSV_TEMPLATE}
        rel="noopener noreferrer"
        target="_blank"
      >
        {lang.googleSheetsText}
      </Button>
      <Button
        color="primary"
        endIcon={<SaveAlt fontSize="small" sx={{ mt: "-0.25rem" }} />}
        sx={{
          ml: "0.75rem",
          fontSize: "0.75rem",
        }}
        href={ADVANCED_CSV_DOWNLOAD}
      >
        {lang.csvText}
      </Button>
    </AccordionActions>
  );
  return type === ImportStepType.Note ? (
    <ImportInstructionsAccordion
      instruction={manualNote}
      listItems
      actions={actions}
    />
  ) : (
    <ImportNoteCritical note={manualNote} listItems actions={actions} />
  );
}

const StyledDownloadButton = styled(({ ...props }) => <Button {...props} />)`
  background-color: ${({ theme }) => theme.tokens.button.neutral.default};
  border-color: ${({ theme }) => theme.tokens.border.neutral.default};
  margin: 0.25rem;
  font-size: 0.85rem;
  font-weight: 500;
`;

function ManualCSVActionsDownloadBox() {
  const lang = useLang().imports.manualCSV;
  const { tokens } = useDesign();
  return (
    <Box
      bgcolor={tokens.background.accent.neutral.lowest}
      borderLeft={`4px solid ${tokens.border.brand}`}
      p="1rem"
      display="flex"
      borderRadius="4px"
      mt="0.5rem"
    >
      <InfoOutlined fontSize="medium" sx={{ color: tokens.text.brand }} />
      <Box>
        <Typography variant="Metropolis/Body/Light" pl="0.75rem">
          {lang.warningText}
        </Typography>
        <Box
          display="flex"
          mt="1rem"
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          <StyledDownloadButton
            variant="outlined"
            color="secondary"
            endIcon={<SaveAlt fontSize="small" sx={{ mt: "-0.25rem" }} />}
            href={ADVANCED_XLSX_DOWNLOAD}
          >
            {lang.excelText}
          </StyledDownloadButton>
          <StyledDownloadButton
            variant="outlined"
            color="secondary"
            endIcon={<OpenInNew fontSize="small" />}
            href={SHEET_ADVANCED_CSV_TEMPLATE}
            rel="noopener noreferrer"
            target="_blank"
          >
            {lang.googleSheetsText}
          </StyledDownloadButton>
          <StyledDownloadButton
            variant="outlined"
            color="secondary"
            endIcon={<SaveAlt fontSize="small" sx={{ mt: "-0.25rem" }} />}
            href={ADVANCED_CSV_DOWNLOAD}
          >
            {lang.csvText}
          </StyledDownloadButton>
        </Box>
      </Box>
    </Box>
  );
}

function ManualCSVActionsDownloadBoxV2() {
  const lang = useLang().imports.manualCSV;
  const { tokens } = useDesign();
  return (
    <Box
      bgcolor={tokens.background.accent.neutral.lowest}
      border={`1px solid ${tokens.border.neutral.high}`}
      p="1rem"
      display="flex"
      borderRadius="4px"
      mt="0.5rem"
    >
      <Box>
        <Typography variant="Metropolis/Body/Light" pl="0.75rem">
          {lang.warningText}
        </Typography>
        <Box
          display="flex"
          mt="1rem"
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          <StyledDownloadButton
            variant="outlined"
            color="secondary"
            endIcon={<SaveAlt fontSize="small" sx={{ mt: "-0.25rem" }} />}
            href={ADVANCED_XLSX_DOWNLOAD}
          >
            {lang.excelText}
          </StyledDownloadButton>
          <StyledDownloadButton
            variant="outlined"
            color="secondary"
            endIcon={<OpenInNew fontSize="small" />}
            href={SHEET_ADVANCED_CSV_TEMPLATE}
            rel="noopener noreferrer"
            target="_blank"
          >
            {lang.googleSheetsText}
          </StyledDownloadButton>
          <StyledDownloadButton
            variant="outlined"
            color="secondary"
            endIcon={<SaveAlt fontSize="small" sx={{ mt: "-0.25rem" }} />}
            href={ADVANCED_CSV_DOWNLOAD}
          >
            {lang.csvText}
          </StyledDownloadButton>
        </Box>
        <ExternalLink
          sx={{
            mt: "0.5rem",
            pl: "0.5rem",
            fontFamily: "Metropolis",
            lineHeight: "1rem",
          }}
        />
      </Box>
    </Box>
  );
}
