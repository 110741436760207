import qs from "query-string";

import { get } from "~/services/core";
import { type CurrencyIdentifier } from "~/types/index";

export function getMarketPrice(
  currencyIdentifier: CurrencyIdentifier,
  timestamp: string,
) {
  const { id, source } = currencyIdentifier;
  const query = qs.stringify({ id, source, timestamp });
  const path = `/price?${query}`;
  return get<number>(path);
}
