import { Plan } from "@ctc/types";
import { Check } from "@mui/icons-material";
import { Box, Radio, Skeleton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { AnalyticEvent } from "~/analytics/analyticsProperties";
import { useCaptureAnalyticEvents } from "~/analytics/captureAnalyticEvents";
import { usePlanPricing } from "~/components/payment/helpers";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue, displayQuantity } from "~/lib";
import { invariant } from "~/lib/invariant";
import { useLocalCurrency, useUser } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { CouponService } from "~/services/coupon";
import { usePlans } from "~/state/plans";

export function FeatureCard({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  const { tokens } = useDesign();

  return (
    <Box display="flex" gap="0.5rem">
      <Box>
        <Check sx={{ fontSize: "1.25rem", color: tokens.icon.brand }} />
      </Box>
      <Box>
        <Typography variant="Metropolis/Header/H5" color={tokens.text.high}>
          {title}
        </Typography>
        <Typography
          variant="Metropolis/Caption/Large/Regular"
          color={tokens.text.low}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

export function PlanCard({
  selected,
  setSelected,
  plan,
  showTaxSeasonCountdown,
  variant,
}: {
  selected: boolean;
  setSelected: (plan: Plan) => void;
  plan: Plan;
  showTaxSeasonCountdown: boolean;
  variant: string;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  const localCurrency = useLocalCurrency();
  const locale = useLanguagePreference();
  const currentPlanType = user?.paidPlan;
  const plans = usePlans().data;
  const captureAnalytics = useCaptureAnalyticEvents();

  const { price, priceDiscounted, planCurrency, loadingCouponDetails } =
    usePlanPricing({
      planType: plan,
    });

  const hasPossibleCoupons = CouponService.getUserCouponCodes(user).length > 0;
  const isUnpaid = currentPlanType === Plan.Free;

  const hasDiscountedPrice =
    priceDiscounted !== null &&
    priceDiscounted !== undefined &&
    priceDiscounted >= 0;

  /** Shows the was price */
  const showDiscountedPrice = isUnpaid && hasDiscountedPrice;

  const priceDisplay = showDiscountedPrice ? priceDiscounted : price;

  const couponLoading = !!(
    isUnpaid &&
    hasPossibleCoupons &&
    loadingCouponDetails
  );

  const isDiscounted = !!priceDiscounted;
  const discountPercentage = priceDiscounted
    ? Math.round(100 - (priceDiscounted / price) * 100)
    : 0;

  const planData = plans?.[plan];

  invariant(planData, "Plan data not found");

  return (
    <Box
      p="1rem 1rem 1.5rem 1rem"
      borderRadius="0.5rem"
      border={`1px solid ${tokens.border.neutral.default}`}
      bgcolor={tokens.background.neutral.default}
      display="flex"
      gap="0.5rem"
      onClick={() => {
        setSelected(plan);
        captureAnalytics(AnalyticEvent.PAYWALL_MODAL.PLAN_SELECTED, {
          plan,
          showTaxSeasonCountdown,
          variant,
        });
      }}
      sx={{
        cursor: "pointer",
        "&:hover": { bgcolor: tokens.background.neutral.hover },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="0.125rem"
        justifyContent="space-between"
        width="100%"
      >
        {isDiscounted ? (
          <Box
            p="0.125rem 0.5rem"
            borderRadius="1rem"
            bgcolor={tokens.background.accent.purple.low}
            width="fit-content"
          >
            <Typography
              variant="Metropolis/Caption/Small/Regular"
              color={tokens.text.brand}
            >
              {lang.paywallModal.irsVariantPreSelectedPlan.discountApplied}
            </Typography>
          </Box>
        ) : null}
        <Typography variant="Metropolis/Caption/Large/Bold">
          {lang.payment.base.copy.plans[plan].title}
        </Typography>
        <Typography variant="Metropolis/Caption/Medium/Regular">
          {lang.paywallModal.irsVariantChoosePlan.txSubtitle({
            txCount: displayQuantity(
              planData.paywallLimits.txCountLimit,
              locale,
            ),
          })}
        </Typography>

        {couponLoading ? (
          <Skeleton variant="text" width="10rem" height="1rem" />
        ) : isDiscounted ? (
          <Box display="flex" gap="0.25rem" alignItems="center">
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              color={tokens.text.low}
              sx={{ textDecoration: "line-through" }}
            >
              {displayFiatValue({
                value: price,
                localCurrency: planCurrency ?? localCurrency,
                locale,
                roundUp: true,
              })}
            </Typography>
            <Typography variant="Metropolis/Caption/Medium/Regular">
              {lang.paywallModal.irsVariantPreSelectedPlan.price({
                price: displayFiatValue({
                  value: priceDisplay,
                  localCurrency: planCurrency ?? localCurrency,
                  locale,
                  roundUp: true,
                }),
                discount: discountPercentage,
              })}
            </Typography>
          </Box>
        ) : (
          <Typography variant="Metropolis/Caption/Medium/Regular">
            {displayFiatValue({
              value: price,
              localCurrency: planCurrency ?? localCurrency,
              locale,
              roundUp: true,
            })}
          </Typography>
        )}
      </Box>
      <Box height="100%" display="flex" alignItems="center">
        <Radio
          checked={selected}
          onChange={() => {
            setSelected(plan);
          }}
        />
      </Box>
    </Box>
  );
}

export const StyledLink = styled(Link)`
  && {
    text-decoration: none;
  }
`;
