import { Close, OpenInNew } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@mui/material";

import { StyledOpenInNew } from "~/components/reconciliation/StyledOpenInNew";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useLang } from "~/redux/lang";

const TURBOTAX_ONLINE_URL = "https://turbotax.intuit.com/";
const TURBOTAX_ONLINE_INSTRUCTIONS =
  "https://help.cryptotaxcalculator.io/en/articles/5944129-us-report-guide-how-to-submit-your-cryptocurrency-report-using-turbotax";

export enum TurboTaxInformationalDialogType {
  Desktop = "desktop",
  Online = "online",
}

export function TurboTaxInformationalDialog({
  type,
  setIsOpen,
}: {
  type: TurboTaxInformationalDialogType | undefined;
  setIsOpen: (value: TurboTaxInformationalDialogType | undefined) => void;
}) {
  const handleClose = () => {
    setIsOpen(undefined);
  };

  const desktop = type === TurboTaxInformationalDialogType.Desktop;
  const lang = useLang().report.download.turbotaxModal;

  return (
    <Dialog open={!!type} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="0.5rem"
        >
          <Typography variant="Metropolis/Header/H4">{lang.title}</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <Typography>{lang.subtitle}</Typography>
          <Link
            href={TURBOTAX_ONLINE_INSTRUCTIONS}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <TertiaryButton
              sx={{
                display: "flex",
                gap: "0.25rem",
                padding: ".375rem .75rem",
              }}
            >
              <Typography>{lang.helpLinkText}</Typography>
              <OpenInNew />
            </TertiaryButton>
          </Link>
        </Box>
      </DialogContent>
      <DialogContent dividers>
        {!desktop && (
          <Box display="flex" justifyContent="flex-end">
            <TertiaryButton
              onClick={() => {
                handleClose();
                window.open(TURBOTAX_ONLINE_URL, "_blank");
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                gap="0.5rem"
                padding="0.25rem 0.75rem"
                borderRadius="0.25rem"
                width="fit-content"
              >
                <Typography>{lang.buttonText}</Typography>
                <StyledOpenInNew />
              </Box>
            </TertiaryButton>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
