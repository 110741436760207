import { type Blockchain } from "@ctc/types";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  type OutlinedInputProps,
  Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { type FilterOptionsState } from "@mui/material/useAutocomplete";
import type * as React from "react";

import { shouldShowExchangeOptionSubLabel } from "~/components/filter/helpers/shouldShowSubLabel";
import {
  ListboxComponent,
  renderGroup,
} from "~/components/transactions/AutocompleteListboxComponent";
import { Ellipsis } from "~/components/transactions/Ellipsis";
import { CensoredBox } from "~/components/ui/CensoredComponents";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { useDesign } from "~/hooks/useTheme";
import { middleTrim } from "~/lib/index";
import { useLang } from "~/redux/lang";
import {
  getEntityExchangeLogoName,
  useEntityLookup,
  useEntityLookupAsync,
} from "~/state/entities";
import { useLoadExchangeOptions } from "~/state/exchanges";
import { type ExchangeOption } from "~/types/index";

const filter = createFilterOptions<ExchangeOption>({
  stringify: (option) => `${option.label}_${option.name}`,
});

export const ExchangeComboBox = ({
  id,
  value,
  helperText,
  disabled = false,
  setValue,
  ...outlinedInputProps
}: {
  id: string;
  value: ExchangeOption | string | null;
  helperText?: string;
  disabled?: boolean;
  setValue: (value: ExchangeOption | null) => void;
} & OutlinedInputProps) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const { data: exchangeOptions, isLoading } = useLoadExchangeOptions();

  const entity = useEntityLookup(
    typeof value === "string" ? value : value?.name,
  );

  // Mask over the default label of value if a saved blockchain address exists
  // Note: doesn't edit the underlying data, just modifies the label of the selected value
  let namedValue = value;
  if (!!value && typeof value !== "string") {
    const savedName = entity?.displayName;
    const newName = savedName ? `${savedName} (${value.name})` : value.name;
    namedValue = value ? { ...value, name: newName } : null;
  }
  const { getEntityForAddress } = useEntityLookupAsync();

  return (
    <Autocomplete
      disabled={disabled}
      loading={isLoading}
      fullWidth
      value={namedValue}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            label: newValue,
            name: newValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(
        options: ExchangeOption[],
        params: FilterOptionsState<ExchangeOption>,
      ) => {
        let hasExactMatch = false;
        // Allow searching by saved names
        const namedOptions = options.map((option) => {
          const entity = getEntityForAddress(
            option.name,
            option.blockchain as Blockchain,
          );

          // using the same loop here but this is completely separate to adding saved names
          if (option.name.toLowerCase() === params.inputValue.toLowerCase()) {
            hasExactMatch = true;
          }

          return { ...option, name: entity?.displayName ?? option.name };
        });
        const filtered = filter(namedOptions, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "" && !hasExactMatch) {
          filtered.push({
            inputValue: params.inputValue,
            label: params.inputValue,
            name: `${lang.transactions.add} "${params.inputValue}"`,
            manual: true,
          } as ExchangeOption);
        }
        return filtered;
      }}
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={id}
      options={exchangeOptions ?? []}
      isOptionEqualToValue={(
        option,
        value: ExchangeOption | string,
      ): boolean => {
        return typeof value === "string"
          ? option.label === value
          : option.label === value.label;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => {
        const entity = getEntityForAddress(
          option.label,
          option.blockchain as Blockchain,
        );

        return (
          <Box component="li" {...props}>
            <Box display="inline-flex" alignItems="center">
              <ExchangeLogo
                name={
                  getEntityExchangeLogoName(entity) ??
                  (option.isManual ? "" : option.label)
                }
                backupName={option.isManual ? "" : option.name}
                currencySymbol={option.currency}
                blockchain={option.blockchain}
                showBlockchainSymbol
              />
              <CensoredBox display="flex" flexDirection="column">
                <Ellipsis maxWidth={160}>
                  {middleTrim(entity?.displayName ?? option.name, 20, 5)}
                </Ellipsis>
                {shouldShowExchangeOptionSubLabel(option, entity) ? (
                  <Typography
                    variant="Metropolis/Caption/Medium/Regular"
                    sx={{ color: tokens.text.low }}
                  >
                    {middleTrim(option.label)}
                  </Typography>
                ) : null}
              </CensoredBox>
            </Box>
          </Box>
        );
      }}
      freeSolo
      renderGroup={renderGroup}
      renderInput={(params) => {
        const { InputProps, InputLabelProps, ...otherParams } = params;
        return (
          <FormControl
            fullWidth
            error={outlinedInputProps.error}
            required={outlinedInputProps.required}
          >
            <InputLabel variant="outlined" {...InputLabelProps}>
              {outlinedInputProps.label}
            </InputLabel>
            <OutlinedInput
              type="search"
              {...otherParams}
              {...outlinedInputProps}
              {...InputProps}
              required={outlinedInputProps.required}
              {...(value &&
                typeof value !== "string" &&
                value.label && {
                  startAdornment: (
                    <ExchangeLogo
                      name={getEntityExchangeLogoName(entity) ?? value.label}
                      backupName={value.name}
                      currencySymbol={value.currency}
                      blockchain={value.blockchain}
                      showBlockchainSymbol
                    />
                  ),
                })}
              inputProps={{
                ...otherParams.inputProps,
                ...outlinedInputProps.inputProps,
              }}
              sx={{ backgroundColor: tokens.background.input.default }}
            />
            {helperText ? (
              <FormHelperText error={outlinedInputProps.error}>
                {helperText}
              </FormHelperText>
            ) : null}
          </FormControl>
        );
      }}
    />
  );
};
