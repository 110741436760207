import { type Blockchain, type SyncErrorIssue } from "@ctc/types";
import { Add, ArrowForward, Close, ErrorOutline } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { EntityAddressList } from "~/components/imports/api/EntityAddressList";
import { CreateAPIImportInputController } from "~/components/imports/api/index";
import { ImportFormContext } from "~/components/imports/context/index";
import { getImportInstructionKey } from "~/components/imports/helpers";
import {
  type ImportControllers,
  ImportForm,
} from "~/components/imports/ImportForm";
import { useImportContext } from "~/components/imports/ImportItem";
import { ImportItemList } from "~/components/imports/ImportItemList";
import { ManageRulesSection } from "~/components/imports/ImportMetadata";
import { defaultImportMethodPriorities } from "~/components/imports/ImportSourceToggle";
import {
  ImportInstructions,
  StepItem,
  StepList,
} from "~/components/imports/ImportSteps";
import { OnboardingNavBarSpacing } from "~/components/imports/OnboardingNavBar";
import { useInImportErrorReportExperiment } from "~/components/imports/useImportErrorReportExperiment";
import { CreateWalletInputController } from "~/components/imports/wallet/index";
import { useInImportInstructionsWithImagesExperiment } from "~/components/onboarding-v2/hooks/useInImportInstructionsWithImagesExperiment";
import { sizes } from "~/components/ui/theme/legacy";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useComponentSize } from "~/hooks/useComponentSize";
import { useDesign } from "~/hooks/useTheme";
import { useLang, useSteps } from "~/redux/lang";
import { useGetSavedImportsByIntegration } from "~/state/importsV2";
import {
  ImportMethod,
  ImportType,
  IntegrationCategory,
  Links,
} from "~/types/enums";
import {
  type ImportMethodV2,
  type ImportOptionV2,
  type ImportTypeInstruction,
  type SavedImportByIntegration,
} from "~/types/index";

import {
  useIsOnboardingMobileHiddenInstructionsExperiment,
  useIsOnboardingMobileImportExperiment,
} from "../../hooks/useIsOnboardingMobileImportExperiment";

function getInitialImportMethod({
  importOption,
  savedImport,
  presetImportMethod,
}: {
  importOption: ImportOptionV2;
  savedImport?: SavedImportByIntegration;
  presetImportMethod?: ImportMethod;
}): ImportMethodV2 {
  const hasSavedOauth = savedImport?.oauths.length;

  if (presetImportMethod ?? savedImport) {
    const oauthImportMethod = importOption.importMethods.find(
      (method) => method.type === ImportMethod.OAuth,
    );
    const walletImportMethod = importOption.importMethods.find(
      (method) => method.type === ImportMethod.Wallet,
    );
    const connectWalletImportMethod = importOption.importMethods.find(
      (method) => method.type === ImportMethod.ConnectWallet,
    );
    const keyImportMethod = importOption.importMethods.find(
      (method) => method.type === ImportMethod.API,
    );
    const fileImportMethod = importOption.importMethods.find(
      (method) => method.type === ImportMethod.CSV,
    );
    const bulkWalletImportMethod = importOption.importMethods.find(
      (method) => method.type === ImportMethod.BulkWallet,
    );

    if (presetImportMethod) {
      if (
        presetImportMethod === ImportMethod.OAuth &&
        oauthImportMethod &&
        !hasSavedOauth
      ) {
        return oauthImportMethod;
      }

      if (
        [ImportMethod.Wallet, ImportMethod.SoftWallet].includes(
          presetImportMethod,
        ) &&
        walletImportMethod
      ) {
        return walletImportMethod;
      }

      if (
        presetImportMethod === ImportMethod.ConnectWallet &&
        connectWalletImportMethod
      ) {
        return connectWalletImportMethod;
      }

      if (presetImportMethod === ImportMethod.API && keyImportMethod) {
        return keyImportMethod;
      }
      if (presetImportMethod === ImportMethod.CSV && fileImportMethod) {
        return fileImportMethod;
      }
    }
    if (savedImport) {
      if (hasSavedOauth && oauthImportMethod) {
        return oauthImportMethod;
      }
      if (savedImport?.wallets.length && walletImportMethod) {
        return walletImportMethod;
      }
      if (savedImport?.keys.length && keyImportMethod) {
        return keyImportMethod;
      }
      if (savedImport?.files.length && fileImportMethod) {
        return fileImportMethod;
      }
    }
  }

  const preferredImportMethod = importOption.importMethods.find(
    (method) => method.options?.preferred,
  );
  if (preferredImportMethod) {
    return preferredImportMethod;
  }

  const defaultImportMethod = defaultImportMethodPriorities.reduce<
    ImportMethodV2 | undefined
  >((highestPriorityMethod, priority) => {
    if (hasSavedOauth && priority === ImportMethod.OAuth) {
      return highestPriorityMethod;
    }
    return (
      highestPriorityMethod ||
      importOption.importMethods.find((method) => method.type === priority)
    );
  }, undefined);

  if (defaultImportMethod) {
    return defaultImportMethod;
  }
  // default provide csv option
  // todo maybe set the manual flag here?
  return {
    type: ImportMethod.CSV,
    etaMs: null,
    avgMs: null,
    p95Ms: null,
  };
}

function getLangImportType(
  importOption: ImportOptionV2,
  importMethod: ImportMethodV2,
): ImportType | ImportMethod {
  const { manual } = importOption;
  switch (importMethod.type) {
    case ImportMethod.CSV:
      return manual ? ImportType.ManualCSV : ImportType.CSV;
    case ImportMethod.Wallet:
      return importOption.category === IntegrationCategory.Blockchain
        ? ImportType.WalletAPI
        : ImportType.SoftWallet;
    // For now we group them under an "API" button as they are mutually exclusive.
    case ImportMethod.API:
      return ImportType.API;
    case ImportMethod.OAuth:
      return ImportType.OAuth;
    case ImportMethod.BulkWallet:
      return ImportMethod.BulkWallet;
    case ImportMethod.ConnectWallet:
      return ImportMethod.ConnectWallet;
  }
}

function useImportMethod({
  importOption,
  savedImport,
  presetImportMethod,
}: {
  importOption: ImportOptionV2;
  savedImport?: SavedImportByIntegration;
  presetImportMethod?: ImportMethod;
}) {
  const [importMethod, setImportMethod] = useState<ImportMethodV2>(() =>
    getInitialImportMethod({
      importOption,
      savedImport,
      presetImportMethod,
    }),
  );

  useEffect(() => {
    setImportMethod(
      getInitialImportMethod({
        importOption,
        savedImport,
        presetImportMethod,
      }),
    );
  }, [importOption, savedImport, presetImportMethod]);

  const updateImportMethod = React.useCallback((newMethod: ImportMethodV2) => {
    setImportMethod(newMethod);
  }, []);

  return [importMethod, updateImportMethod] as const;
}

export const ImportBody = ({
  importOption,
  isNew,
  presetImportMethod,
}: {
  importOption: ImportOptionV2;
  isNew?: boolean;
  presetImportMethod?: ImportMethod;
}) => {
  const [showImportForm, setShowImportForm] = useState(!!isNew);
  const [selectedBlockchain, setSelectedBlockchain] = useState<Blockchain>();
  const isOnboardingMobileImportExperiment =
    useIsOnboardingMobileImportExperiment();
  const [showInstructions, setShowInstructions] = useState<boolean>(
    !isOnboardingMobileImportExperiment,
  );
  const [importIssue, setImportIssue] = useState<SyncErrorIssue>();
  const savedImport = useGetSavedImportsByIntegration(importOption.id);
  const [importMethod, setImportMethod] = useImportMethod({
    importOption,
    savedImport,
    presetImportMethod,
  });

  return (
    <ImportFormContext.Provider
      value={{
        importMethod,
        setImportMethod,
        showImportForm,
        setShowImportForm,
        selectedBlockchain,
        setSelectedBlockchain,
        setImportIssue,
        importIssue,
        showInstructions,
        setShowInstructions,
      }}
    >
      <ImportBodyNoContext
        importOption={importOption}
        importMethod={importMethod}
        isNew={isNew}
        selectedBlockchain={selectedBlockchain}
      />
      {/* toolbar has fixed position so its above the content, add spacing to offset it */}
      <OnboardingNavBarSpacing importMethod={importMethod} />
    </ImportFormContext.Provider>
  );
};

const ImportBodyNoContext = ({
  importOption,
  importMethod,
  isNew,
  selectedBlockchain,
}: {
  importOption: ImportOptionV2;
  importMethod: ImportMethodV2;
  isNew?: boolean;
  selectedBlockchain: Blockchain | undefined;
}) => {
  const lang = useLang();
  const importType = getLangImportType(importOption, importMethod);
  const isSmall = useMediaQuery("xs");
  const steps = useSteps({
    exchangeLabel: importOption.id,
    importType,
    importMethod,
    exchangeDisplayName: importOption.name,
    selectedBlockchain,
  });
  const context = useContext(ImportFormContext);
  const inImportInstructionsWithImagesExperiment =
    useInImportInstructionsWithImagesExperiment();
  const inImportErrorReportingExperiment = useInImportErrorReportExperiment();
  const isHiddenMobileInstructionsExperiment =
    useIsOnboardingMobileHiddenInstructionsExperiment();
  const isOnboardingMobileImportExperiment =
    useIsOnboardingMobileImportExperiment();

  const isDeprecated = importOption.isDeprecated ?? false;
  const deprecatedLang = (
    lang.importInstructions as unknown as Record<
      string,
      { deprecated: { title?: string; items?: string[] } }
    >
  )[importOption.id]?.deprecated;
  const deprecatedNoteTitle =
    deprecatedLang?.title ?? lang.imports.deprecation.title;
  const deprecatedNoteItems = deprecatedLang?.items ?? [
    lang.imports.deprecation.description,
  ];
  const { ref, size } = useComponentSize();
  const importControllers: ImportControllers = {
    walletInputController: CreateWalletInputController(importOption),
    bulkWalletInputController: CreateWalletInputController(importOption),
    apiInputController: CreateAPIImportInputController(
      importOption,
      importMethod,
    ),
  };
  const [newLabel, setLabel] = useState(importOption.id);

  const { state } = useImportContext();
  const disabled = !state.startedSync;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(Links.Imports);
  };

  const handleAddSource = () => {
    context?.setShowImportForm(true);
  };

  if (!context) return null;
  const updatedImportOption = { ...importOption, id: newLabel };

  const showManageRules =
    !isNew && importOption.id && importMethod.type !== ImportMethod.Wallet;
  const showEntityAddressList = importOption.id;

  const hasInstructions = !!steps?.step?.length;

  return (
    <FormControl fullWidth>
      <Grid container spacing="1rem" flexWrap="wrap">
        <Grid
          item
          xs={12}
          sm={
            isOnboardingMobileImportExperiment
              ? 12
              : inImportInstructionsWithImagesExperiment
                ? 5
                : 6
          }
        >
          <Stack direction="column" gap="1rem" ref={ref}>
            {steps?.critical ? (
              <Box sx={{ marginBottom: "0.5rem" }}>
                {steps.critical.map((step, index) => {
                  return (
                    <Box key={getImportInstructionKey(step, index)}>
                      <ImportNoteCritical note={step} />
                    </Box>
                  );
                })}
              </Box>
            ) : null}
            {isDeprecated && (
              <Box sx={{ marginBottom: "0.5rem" }}>
                <ImportNoteCritical
                  note={{
                    title: deprecatedNoteTitle,
                    items: deprecatedNoteItems,
                  }}
                />
              </Box>
            )}
            <ImportItemList
              importOption={updatedImportOption}
              importMethod={importMethod}
              controllers={importControllers}
            />
            {inImportErrorReportingExperiment ? (
              <div>
                {context.showImportForm ? (
                  <ImportForm
                    importOption={updatedImportOption}
                    importMethod={importMethod}
                    controllers={importControllers}
                    updateSelectedLabel={setLabel}
                  />
                ) : (
                  <TextButton onClick={handleAddSource} endIcon={<Add />}>
                    {lang.imports.addSource({ source: importOption.name })}
                  </TextButton>
                )}
              </div>
            ) : null}
            {showEntityAddressList ? (
              <EntityAddressList
                exchangeName={importOption.name}
                exchangeId={importOption.id}
              />
            ) : null}
            {showManageRules ? (
              <ManageRulesSection savedImportId={importOption.id} />
            ) : null}
            {inImportErrorReportingExperiment ? null : (
              <StyledImportFormContainer>
                {context.showImportForm ? (
                  <ImportForm
                    importOption={updatedImportOption}
                    importMethod={importMethod}
                    controllers={importControllers}
                    updateSelectedLabel={setLabel}
                  />
                ) : (
                  <TextButton onClick={handleAddSource} endIcon={<Add />}>
                    {lang.imports.addSource({ source: importOption.name })}
                  </TextButton>
                )}
              </StyledImportFormContainer>
            )}
            {isNew && !disabled && !context.showImportForm ? (
              <Box>
                <PrimaryButton onClick={handleClick} endIcon={<ArrowForward />}>
                  {lang.imports.continue}
                </PrimaryButton>
              </Box>
            ) : null}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            justifyContent={isSmall ? "center" : "flex-start"}
            direction={isSmall ? "column-reverse" : "row"}
          >
            <Grid item xs={12}>
              {!hasInstructions ? null : isHiddenMobileInstructionsExperiment ? (
                <Box display="flex" justifyContent="flex-end" width="100%">
                  <TextButton
                    onClick={() => {
                      context.setShowInstructions(true);
                    }}
                  >
                    {lang.imports.viewGuide}
                  </TextButton>
                </Box>
              ) : (
                <ImportInstructions
                  exchangeLabel={importOption.id}
                  importType={importType}
                  exchangeDisplayName={importOption.name}
                  importMethod={importMethod}
                  selectedBlockchain={context.selectedBlockchain}
                  size={size}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        {hasInstructions ? (
          <ImportInstructionsDrawer
            importOption={importOption}
            importMethod={importMethod}
            importType={importType}
            size={size}
          />
        ) : null}
      </Grid>
    </FormControl>
  );
};

function ImportInstructionsDrawer({
  importOption,
  importMethod,
  importType,
  size,
}: {
  importOption: ImportOptionV2;
  importMethod: ImportMethodV2;
  importType: ImportType | ImportMethod;
  size: { width: number; height: number };
}) {
  const { tokens } = useDesign();
  const context = useContext(ImportFormContext);
  const showInstructions = context?.showInstructions !== false; // if showInstructions is undefined, we still want to show instructions.

  const isHiddenMobileInstructionsExperiment =
    useIsOnboardingMobileHiddenInstructionsExperiment();

  if (!isHiddenMobileInstructionsExperiment) {
    return null;
  }

  return (
    <Drawer
      anchor="bottom"
      open={showInstructions}
      onClose={() => context?.setShowInstructions(false)}
      PaperProps={{
        sx: {
          maxHeight: "80vh",
          backgroundColor: tokens.elevation.low,
          borderTopLeftRadius: "1.5rem",
          borderTopRightRadius: "1.5rem",
          borderTop: `1px solid ${tokens.border.neutral.default}`,
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p="1rem"
        bgcolor="inherit"
        alignItems="center"
      >
        <Typography variant="Metropolis/Header/H3" color={tokens.text.high}>
          Import Guide
        </Typography>
        <TextIconButton
          onClick={() => {
            context?.setShowInstructions(false);
          }}
          size="small"
        >
          <Close sx={{ width: "1.5rem", color: tokens.text.low }} />
        </TextIconButton>
      </Box>
      <Divider sx={{ borderColor: tokens.border.neutral.default }} />
      <Box p="1rem 1rem 2.5rem 1rem" overflow="auto" bgcolor="inherit">
        <ImportInstructions
          exchangeLabel={importOption.id}
          importType={importType}
          exchangeDisplayName={importOption.name}
          importMethod={importMethod}
          selectedBlockchain={context?.selectedBlockchain}
          size={size}
        />
      </Box>
    </Drawer>
  );
}

export function ImportNoteCritical({
  note,
  listItems,
  actions,
}: {
  note: ImportTypeInstruction;
  listItems?: boolean;
  actions?: React.ReactNode;
}) {
  const { tokens } = useDesign();
  const isMobile = useMediaQuery(`(max-width: ${sizes.tablet})`);
  const { items = [] } = note;
  return (
    <CriticalBox>
      <Box height="100%" mr={isMobile ? "2rem" : "1rem"} width="5%">
        <ErrorOutline
          style={{ fontSize: "1.5rem", color: tokens.button.brand.default }}
        />
      </Box>
      <Box width="100%" gap="0.25rem">
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: tokens.text.high,
          }}
        >
          {note.title}
        </Typography>
        {listItems ? (
          <StepList steps={items} />
        ) : (
          items.map((item: string, index) => (
            <Box key={item}>
              <StepItem
                item={item}
                index={index}
                totalSteps={items.length}
                noNumber
              />
            </Box>
          ))
        )}
      </Box>
      {actions && <Box ml="1rem">{actions}</Box>}
    </CriticalBox>
  );
}

const StyledImportFormContainer = styled(Box)`
  div:has(div) + & {
    margin-top: 1rem;
  }
`;

const CriticalBox = styled(Box)`
  && {
    display: flex;
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.neutral.low};
    color: ${({ theme }) => theme.tokens.text.low};
    padding: 1rem;
    border-radius: 4px;
    border: 0.063rem ${({ theme }) => theme.tokens.border.neutral.high} solid;
  }
`;
