import { type Blockchain } from "@ctc/types";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LensIcon from "@mui/icons-material/Lens";
import {
  Box,
  type BoxProps,
  Fade,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import {
  bindHover,
  bindPopper,
  usePopupState,
} from "material-ui-popup-state/hooks";
import type * as React from "react";
import { type ReactNode, useState } from "react";
import styled from "styled-components/macro";

import { Severity } from "~/components/transactions/action-row/enums";
import { EditActionAmountsDialog } from "~/components/transactions/edit/EditActionAmounts";
import { EditActionFee } from "~/components/transactions/edit/EditActionFee";
import { EllipsisWithTooltip } from "~/components/transactions/Ellipsis";
import { UpdateBlockchainActionDialog } from "~/components/transactions/UpdateBlockchainDialog";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import {
  TextButton,
  TextButtonLink,
} from "~/components/ui/ui-buttons/TextButton";
import { useDesign } from "~/hooks/useTheme";
import { useHighestRankingPlanUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  getMissingBlockchain,
  getMissingPrice,
  getNegativeBalance,
} from "~/services/actions";
import { isUncategorisedTrade } from "~/services/transactions";
import { Features, Links, Warning } from "~/types/enums";
import { type ActionRow, type TransactionDetails } from "~/types/index";

import { useIsEmbedded } from "../../../hooks/useIsEmbedded";

function WarningLine({
  row,
  tx,
  warning,
}: {
  row: ActionRow;
  tx?: TransactionDetails;
  warning: Warning;
}) {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const design = useDesign();
  const [isBlockchainDialogOpen, setIsBlockchainDialogOpen] = useState(false);
  const [isValueDialogueOpen, setIsValueDialogueOpen] = useState(false);
  const displayName =
    lang.warningDisplayName[warning as keyof typeof lang.warningDisplayName];

  const handleValueOpen: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsValueDialogueOpen(true);
  };

  const handleValueClose = () => {
    setIsValueDialogueOpen(false);
  };

  const handleBlockchainOpen: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setIsBlockchainDialogOpen(true);
  };

  const handleBlockchainClose = () => {
    setIsBlockchainDialogOpen(false);
  };

  const actionMap: Record<string, ReactNode> = {
    [Warning.NegativeBalance]: tx ? (
      <TextButtonLink
        target={isEmbedded ? "_self" : "_blank"}
        rel="noopener noreferrer"
        to={`${Links.Ledger}/${tx.currencyIdentifier.id}`}
        size="small"
        style={{ fontWeight: 500, fontSize: "0.75rem" }}
        endIcon={<ArrowForwardIcon fontSize="small" />}
      >
        {lang.txTable.viewLedger}
      </TextButtonLink>
    ) : null,
    [Warning.MissingPrice]: (
      <TextButton
        onClick={handleValueOpen}
        size="small"
        style={{ fontWeight: 500, fontSize: "0.75rem" }}
        endIcon={<AddIcon fontSize="small" />}
      >
        {lang.txTable.addPrice}
      </TextButton>
    ),
    [Warning.MissingBlockchain]: (
      <TextButton
        onClick={handleBlockchainOpen}
        size="small"
        style={{ fontWeight: 500, fontSize: "0.75rem" }}
        endIcon={<LensIcon fontSize="small" style={{ fontSize: "0.5rem" }} />}
      >
        {lang.txTable.assignMissingBlockchain}
      </TextButton>
    ),
    [Warning.Uncategorised]: (
      <Typography variant="Metropolis/Caption/Medium/Regular">
        {lang.txTable.warnings.uncategorizedReview}
      </Typography>
    ),
  };

  // Just in case.
  if (!displayName || !actionMap[warning]) return null;

  let editComponent = null;

  // Determines if the warning was derived from a fee.
  const isFeeWarning = tx && row.fees.some((feeTx) => feeTx._id === tx._id);

  if (tx && isFeeWarning) {
    editComponent = (
      <EditActionFee
        isOpen={isValueDialogueOpen}
        handleClose={handleValueClose}
        row={row}
      />
    );
  } else if (tx) {
    editComponent = (
      <EditActionAmountsDialog
        row={row}
        isOpen={isValueDialogueOpen}
        handleClose={handleValueClose}
      />
    );
  }

  return (
    <>
      {editComponent}
      {tx ? (
        <UpdateBlockchainActionDialog
          txId={tx._id}
          isOpen={isBlockchainDialogOpen}
          handleClose={handleBlockchainClose}
          rowId={row._id}
        />
      ) : null}
      {tx ? (
        <Stack direction="row" alignItems="center" spacing="0.75rem">
          <Stack direction="row" alignItems="center" spacing="0.5rem">
            <CurrencyLogo
              currency={tx.currencyIdentifier}
              width={20}
              height={20}
              margin="0"
              style={{
                borderRadius: "100%",
                background: "transparent",
              }}
              blockchain={tx.blockchain as Blockchain}
            />

            <Typography variant="Metropolis/Caption/Medium/Bold">
              <EllipsisWithTooltip
                maxWidth={100}
                text={tx.currencyIdentifier.symbol}
              />
            </Typography>
          </Stack>
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            style={{
              background: design.tokens.background.warning.default,
              color: design.tokens.text.warning,
              padding: "0.25rem 0.5rem",
              borderRadius: "4rem",
            }}
          >
            {displayName}
          </Typography>
          <Box style={{ marginLeft: "0.25rem" }}>{actionMap[warning]}</Box>
        </Stack>
      ) : (
        actionMap[warning]
      )}
    </>
  );
}

export function Warnings({
  row,
  warnings,
  txIds,
  severity = Severity.Warn,
  tooltipOverride = {},
}: {
  row: ActionRow;
  warnings?: Warning[];
  txIds?: string[];
  severity?: Severity;
  tooltipOverride?: Record<string, string>;
}) {
  const design = useDesign();
  const highestRankingPlanUser = useHighestRankingPlanUser();

  const canSeeTips = highestRankingPlanUser?.features[Features.Tips];

  const popupState = usePopupState({
    variant: "popper",
    popupId: "warnings",
  });

  const menu: ReactNode[] = [];
  const txs = [...row.incoming, ...row.outgoing, ...row.fees];

  // If no TX IDs defined, use all TX.
  const txEnabled = txIds ? txIds : txs.map((tx) => tx._id);

  // If not warnings defined, use all available.
  const warningsEnabled = warnings
    ? warnings
    : [Warning.NegativeBalance, Warning.MissingPrice, Warning.Uncategorised];

  if (warningsEnabled.includes(Warning.Uncategorised)) {
    // Hack to get it to run once
    txs.some((tx) => {
      if (!txEnabled.includes(tx._id)) return false;
      if (!tx.ignoreWarning?.uncategorised && isUncategorisedTrade(tx.trade)) {
        if (tooltipOverride[Warning.Uncategorised]) {
          menu.push(
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              key={Warning.Uncategorised + tx._id}
            >
              {tooltipOverride[Warning.Uncategorised]}
            </Typography>,
          );
          return true;
        }
        menu.push(
          <WarningLine
            row={row}
            warning={Warning.Uncategorised}
            key={Warning.Uncategorised + tx._id}
          />,
        );
        return true;
      }
    });
  }

  if (warningsEnabled.includes(Warning.NegativeBalance)) {
    txs.forEach((tx) => {
      if (!txEnabled.includes(tx._id)) return;
      if (getNegativeBalance(tx.errors, tx.ignoreWarning)) {
        if (tooltipOverride[Warning.NegativeBalance]) {
          menu.push(
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              key={Warning.NegativeBalance + tx._id}
            >
              {tooltipOverride[Warning.NegativeBalance]}
            </Typography>,
          );
        } else {
          menu.push(
            <WarningLine
              row={row}
              tx={tx}
              warning={Warning.NegativeBalance}
              key={Warning.NegativeBalance + tx._id}
            />,
          );
        }
      }
    });
  }

  if (warningsEnabled.includes(Warning.MissingPrice)) {
    txs.forEach((tx) => {
      if (!txEnabled.includes(tx._id)) return;
      if (getMissingPrice(tx.errors, tx.ignoreWarning, tx.price)) {
        if (tooltipOverride[Warning.MissingPrice]) {
          menu.push(
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              key={Warning.MissingPrice + tx._id}
            >
              {tooltipOverride[Warning.MissingPrice]}
            </Typography>,
          );
        } else {
          menu.push(
            <WarningLine
              row={row}
              tx={tx}
              warning={Warning.MissingPrice}
              key={Warning.MissingPrice + tx._id}
            />,
          );
        }
      }
    });
  }

  if (warningsEnabled.includes(Warning.MissingBlockchain)) {
    txs.forEach((tx) => {
      if (!txEnabled.includes(tx._id)) return;
      if (getMissingBlockchain(tx.errors, tx.ignoreWarning, tx.blockchain)) {
        if (tooltipOverride[Warning.MissingBlockchain]) {
          menu.push(
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              key={Warning.MissingBlockchain + tx._id}
            >
              {tooltipOverride[Warning.MissingBlockchain]}
            </Typography>,
          );
        } else {
          menu.push(
            <WarningLine
              row={row}
              tx={tx}
              warning={Warning.MissingBlockchain}
              key={Warning.MissingBlockchain + tx._id}
            />,
          );
        }
      }
    });
  }

  if (!menu.length || !canSeeTips) return null;

  return (
    <>
      <Box>
        <Box
          {...bindHover(popupState)}
          style={{
            padding: "0.5rem",
            position: "relative",
          }}
        >
          <WarningDot severity={severity} />
        </Box>
      </Box>
      <Popper
        {...bindPopper(popupState)}
        placement="top"
        transition
        style={{ zIndex: 200, marginTop: "2px" }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              onMouseLeave={() => {
                popupState.close();
              }}
              style={{
                background: design.tokens.elevation.low,
                border: `1px solid ${design.tokens.border.neutral.medium}`,
                padding: "0.5rem",
                borderRadius: "4px",
                marginTop: "0.25rem",
              }}
            >
              <Stack
                spacing="0.25rem"
                flexWrap="nowrap"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {menu}
              </Stack>
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export const WarningDot = styled(
  ({ severity, ...props }: { severity: Severity } & BoxProps) => (
    <Box {...props} />
  ),
)`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-top: 0.125rem;
  background: ${({ severity, theme }) =>
    severity === Severity.Error
      ? theme.tokens.button.danger.default
      : theme.tokens.button.warning.default};
`;
