import { type Plan } from "@ctc/types";
import { Country, Env } from "@ctc/types";
import { CheckCircle, Error } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  type Stripe,
  type StripeElements,
} from "@stripe/stripe-js";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { type Dispatch } from "redux";
import styled from "styled-components/macro";

import { captureGoogleAnalytics } from "~/analytics/google";
import { getBilledCurrencyForCountry } from "~/components/payment/helpers";
import { StripeTextField } from "~/components/payment/StripeTextField";
import { CardLogo } from "~/components/settings-modal/views/billing/PaymentMethodItem";
import { ButtonProgress } from "~/components/ui/ButtonProgress";
import { displayMessage } from "~/components/ui/Toaster";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { CENTS_PER_DOLLAR } from "~/constants/constants";
import { Constants } from "~/constants/enums";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import { displayFiatValue } from "~/lib/index";
import {
  SetIsProcessingPayment,
  useIsProcessingPayment,
} from "~/redux/accountant";
import { loadUser, useUser } from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import { Analytics } from "~/segment/index";
import { type Data } from "~/services/core";
import { CouponService } from "~/services/coupon";
import * as subscriptionAPI from "~/services/subscription";
import { useAllPlans } from "~/state/plans";
import { CardBrand, DisplayMessage } from "~/types/enums";
import { type StripeCouponDetails, type UserInfo } from "~/types/index";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
export const stripePromise = loadStripe(
  import.meta.env.VITE_APP_ENV === Env.Prod
    ? Constants.Stripe
    : Constants.StripeTest,
);

type PaymentFormProps = {
  plan: Plan;
  percentOff: number;
  setPercentOff: (percent: number) => void;
  couponsDisabled?: boolean;
  handleSuccess: () => void;
  handleClose: () => void;
  coupon?: string;
  setCoupon: (coupon: string | undefined) => void;
};

export function PaymentInput(props: PaymentFormProps) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentInputWithoutElements {...props} />
    </Elements>
  );
}

// payment for first client
function PaymentInputWithoutElements({
  plan,
  percentOff,
  setPercentOff,
  couponsDisabled,
  handleSuccess,
  coupon,
  setCoupon,
}: PaymentFormProps) {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const plans = useAllPlans();

  const isProcessingPayment = useIsProcessingPayment();
  const [couponDetails, setCouponDetails] = useState<
    StripeCouponDetails | undefined
  >(undefined);
  const [isLoadingCouponDetails, setIsLoadingCouponDetails] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [isCouponExpired, setIsCouponExpired] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState("");

  const country = user?.country;

  if (!country || plans?.[plan] === undefined) return null;

  const currency = getBilledCurrencyForCountry(country);
  const selectedPlan = plans[plan];

  if (selectedPlan === undefined) return null;

  const { amount } = selectedPlan;
  const fullAmount = amount / CENTS_PER_DOLLAR;

  const applyDiscount = (amount: number) => {
    const userDiscount = user?.discount ? Number(user.discount) : undefined;
    const actualDiscount = percentOff ? percentOff : userDiscount;

    return actualDiscount ? amount * (1 - actualDiscount / 100) : amount;
  };

  const priceToPay = applyDiscount(fullAmount);

  const applyCoupon = async () => {
    if (couponsDisabled) return;
    setIsApplied(true);
    setIsLoadingCouponDetails(true);
    if (coupon) {
      const analytics = await Analytics.getInstance();
      analytics.track({
        event: "apply_coupon_clicked",
        user,
        props: { current_plan: user?.paidPlan, upgrade_plan: plan, coupon },
      });
      const couponDetails = await subscriptionAPI.getCouponDetails(coupon);
      if (!couponDetails.error) {
        analytics.track({
          event: "apply_coupon_succeeded",
          user,
          props: { current_plan: user?.paidPlan, upgrade_plan: plan, coupon },
        });
        setCouponDetails(couponDetails.data);
      } else {
        analytics.track({
          event: "apply_coupon_failed",
          user,
          props: { current_plan: user?.paidPlan, upgrade_plan: plan, coupon },
        });
        setCouponDetails(undefined);
      }
      setIsLoadingCouponDetails(false);
    }
  };

  const formattedCoupon = async (coupon: string) => {
    setCoupon(coupon.trim());
  };

  useEffect(() => {
    if (
      !isLoadingCouponDetails &&
      isApplied &&
      couponDetails &&
      !couponsDisabled
    ) {
      const isCouponExpired =
        CouponService.getIsStripeCouponExpired(couponDetails);
      setIsCouponExpired(isCouponExpired);
      if (!isCouponExpired) {
        setPercentOff(couponDetails.percent_off);
        setIsValidCoupon(couponDetails.percent_off !== 0);
        return;
      }
    }
    setIsValidCoupon(false);
  }, [
    isLoadingCouponDetails,
    isApplied,
    couponDetails,
    setPercentOff,
    setIsValidCoupon,
    setIsCouponExpired,
    couponsDisabled,
  ]);

  const memoCardInput = useMemo(() => {
    return (
      <StripeTextField
        variant="outlined"
        stripeElement={CardElement}
        inputProps={{
          options: {
            hidePostalCode: user?.country !== Country.USA, // Disable all except for the US
          },
        }}
      />
    );
  }, [user]);

  if (!user || !stripe || !elements || !country) return null;
  const handlePayment = (event: any) => {
    event.preventDefault();
    handleFirstClientPayment({
      plan,
      user,
      setStripeErrorMessage,
      percentOff,
      stripe,
      elements,
      lang,
      dispatch,
      coupon: isValidCoupon ? coupon : undefined,
      priceToPay,
      handleSuccess,
    });
  };

  const discountPercent = percentOff !== 0 ? `${percentOff}%` : "";

  const cardBrands = [CardBrand.MasterCard, CardBrand.Visa, CardBrand.Amex];

  return (
    <>
      {!couponsDisabled && (
        <>
          <Typography variant="Metropolis/Header/H5" style={{ textAlign: "left" }}>
            {lang.coupon.label}
          </Typography>
          <StyledTextField
            variant="outlined"
            placeholder={lang.coupon.placeholder}
            type="coupon"
            fullWidth
            onChange={(e) => {
              formattedCoupon(e.target.value);
              setIsApplied(false);
            }}
            value={coupon}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    style={{ width: "5rem" }}
                    variant="outlined"
                    color="primary"
                    onClick={
                      coupon && !isValidCoupon && isApplied
                        ? () => {
                            setCoupon("");
                            setIsApplied(false);
                          }
                        : applyCoupon
                    }
                    disabled={Boolean(
                      !coupon || isValidCoupon || isLoadingCouponDetails,
                    )}
                  >
                    {isValidCoupon
                      ? lang.billing.stripe.applied
                      : coupon &&
                          !isValidCoupon &&
                          isApplied &&
                          !isLoadingCouponDetails
                        ? lang.billing.stripe.clear
                        : lang.billing.stripe.apply}
                    {isLoadingCouponDetails && <ButtonProgress size={24} />}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {!isLoadingCouponDetails &&
          ((isApplied && !isValidCoupon) ||
            isValidCoupon ||
            (isApplied && couponDetails && isCouponExpired)) ? (
            <CaptionBox>
              {!isLoadingCouponDetails && isApplied && !isValidCoupon && (
                <Typography variant="Metropolis/Caption/Medium/Regular" color="error">
                  {lang.billing.stripe.couponNotValid({ name: coupon || "" })}
                </Typography>
              )}
              {!isLoadingCouponDetails && isValidCoupon && (
                <>
                  <Typography variant="Metropolis/Caption/Medium/Regular">
                    {lang.billing.stripe.discountApplied({
                      percent: discountPercent,
                    })}
                  </Typography>
                  <CheckCircle
                    fontSize="small"
                    style={{ color: tokens.icon.success }}
                  />
                </>
              )}
              {!isLoadingCouponDetails &&
                isApplied &&
                couponDetails &&
                isCouponExpired && (
                  <Typography variant="Metropolis/Caption/Medium/Regular" color="error">
                    {lang.billing.stripe.couponExpired({ name: coupon || "" })}
                  </Typography>
                )}
            </CaptionBox>
          ) : null}
        </>
      )}
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap="0.25rem"
      >
        {cardBrands.map((brand) => (
          <CardLogo brand={brand} />
        ))}
        <Typography variant="Metropolis/Header/H5" style={{ textAlign: "left" }}>
          {lang.paymentPortal.cardDetailsLabel}
        </Typography>
      </Box>
      {memoCardInput}
      {stripeErrorMessage ? (
        <CaptionBox>
          <>
            <Typography variant="Metropolis/Caption/Medium/Regular" color="error">
              {lang.paymentPortal.paymentError}
            </Typography>
            <Tooltip title={stripeErrorMessage}>
              <Error fontSize="inherit" color="error" />
            </Tooltip>
          </>
        </CaptionBox>
      ) : null}

      <PrimaryButton
        onClick={(e) => {
          e.preventDefault();
          handlePayment(e);
        }}
        disabled={isProcessingPayment}
        endIcon={isProcessingPayment ? <CircularProgress size="1rem" /> : null}
      >
        {lang.accountant.upgradeClient.payAmountAndUpgrade({
          amount: displayFiatValue({
            value: priceToPay,
            localCurrency: currency,
          }),
        })}
      </PrimaryButton>

      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        color={tokens.text.low}
      >
        {lang.payment.billing.payment.start}
        <Box component="span" color={tokens.text.high}>
          {displayFiatValue({
            value: priceToPay,
            localCurrency: currency,
          })}
        </Box>
        {lang.payment.billing.payment.end}
      </Typography>
    </>
  );
}

const StyledTextField = styled(TextField)`
  && {
    .MuiFormLabel-root {
      color: ${({ theme }) => theme.tokens.text.low};
    }
    .MuiInputLabel-shrink {
      color: inherit;
    }
  }
`;

const CaptionBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

// successful payment analytics tracking assumed to be
// handled by handleSuccess
const handleFirstClientPayment = async ({
  plan,
  user,
  setStripeErrorMessage,
  percentOff,
  stripe,
  elements,
  lang,
  dispatch,
  coupon,
  handleSuccess,
}: {
  plan: Plan;
  user: UserInfo;
  setStripeErrorMessage: (error: string) => void;
  percentOff: number;
  stripe: Stripe;
  elements: StripeElements;
  lang: Translation;
  dispatch: Dispatch<any>;
  coupon?: string;
  priceToPay: number;
  handleSuccess: () => void;
}) => {
  dispatch(SetIsProcessingPayment(true));
  setStripeErrorMessage("");
  const analytics = await Analytics.getInstance();
  analytics.track({
    event: "plan_payment_submitted",
    user,
    props: {
      current_plan: user.paidPlan,
      upgrade_plan: plan,
      percent_off: percentOff,
    },
  });
  captureGoogleAnalytics({
    category: "payment",
    action: `submit payment for ${plan}`,
    label: "submit",
  });

  const card = elements.getElement(CardElement);
  if (!card) {
    const code = "45732";
    console.error(lang.billing[code]);
    displayMessage({
      message: lang.billing.paymentFailedCode({ code }),
      type: DisplayMessage.Error,
    });
    setStripeErrorMessage(lang.billing.paymentFailedCode({ code }));
    dispatch(SetIsProcessingPayment(false));
    return;
  }
  const result = await stripe.createPaymentMethod({
    type: "card",
    card,
    billing_details: {
      email: user.email,
    },
  });

  if (result.error || !result.paymentMethod) {
    analytics.track({
      event: "plan_payment_failed",
      user,
      props: { current_plan: user.paidPlan, upgrade_plan: plan },
    });
    const code = "44359";
    console.error(lang.billing[code], result.paymentMethod, result.error);
    displayMessage({
      message:
        result.error?.message || lang.billing.paymentFailedCode({ code }),
      type: DisplayMessage.Error,
    });
    setStripeErrorMessage(lang.billing.paymentFailedCode({ code }));
    dispatch(SetIsProcessingPayment(false));
    return;
  }

  const res: Data<Plan> = await subscriptionAPI.payForFirstClient({
    plan,
    payment_method: result.paymentMethod.id,
    discount: coupon,
  });

  if (res.error) {
    console.error(res);
    captureGoogleAnalytics({
      category: "payment",
      action: `payment fail for ${plan}`,
      label: "fail",
    });
    displayMessage({
      message: res.msg || lang.billing.somethingWentWrong,
      type: DisplayMessage.Error,
    });
    setStripeErrorMessage(lang.billing.paymentFailed);
    dispatch(SetIsProcessingPayment(false));
  } else {
    dispatch(SetIsProcessingPayment(false));
    dispatch(loadUser(LoadUserType.Login));
    handleSuccess();
  }
};
