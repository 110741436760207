import "react-datepicker/dist/react-datepicker.css";
import "~/lang/index";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  ClickAwayListener,
  Grid,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import range from "lodash/range";
import moment from "moment-timezone";
import { transparentize } from "polished";
import { type ReactNode, type SyntheticEvent, useState } from "react";
import * as React from "react";
import DatePicker from "react-datepicker";
import { css } from "styled-components";
import styled from "styled-components/macro";

import { DateFormat, DateTime } from "~/components/ui/enums";
import { useIsMobile } from "~/components/ui/hooks";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { displayTimeframe, type FinancialYear } from "~/contexts/FYContext";
import { useDesign } from "~/hooks/useTheme";
import { transformDateTzToBrowser } from "~/lib/date";
import { useLang, useLocale } from "~/redux/lang";

type DatePickerProps = {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (
    date: [Date | null, Date | null],
    event: SyntheticEvent<any, Event> | undefined,
  ) => void;
  presets: FinancialYear[] | undefined; // the presence of presets dictates whether the panel is shown
  showFooter: boolean;
  showInputs: boolean;
  setStartDate: (d: Date | null) => void;
  setEndDate: (d: Date | null) => void;
  onClose: () => void;
  onApply: () => void;
};

function BasicMenu(props: { buttonText: ReactNode; content: ReactNode }) {
  const { buttonText, content } = props;
  const { tokens } = useDesign();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <StyledTextButton
        aria-describedby={id}
        onClick={handleClick}
        size="small"
        data-uncensored="true"
      >
        {buttonText}
      </StyledTextButton>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            maxHeight: "12rem",
            backgroundColor: tokens.background.neutral.default,
            backgroundImage: "none",
          },
        }}
      >
        {content}
      </Menu>
    </div>
  );
}

const StyledTextButton = styled(TextButton)`
  && {
    height: 1.5rem;
    color: ${({ theme }) => theme.tokens.text.default};
    font-family: "Metropolis";
    font-weight: 600;
    font-size: 14px;
    padding: 2px 4px;
    text-transform: none;
    min-width: auto;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  :hover {
    background-color: ${({ theme }) => theme.tokens.background.neutral.hover};
    color: ${({ theme }) => theme.tokens.text.default};
  }
  &.Mui-selected {
    background-color: ${({ theme }) => theme.tokens.background.brand.pressed};
    color: ${({ theme }) => theme.tokens.icon.brand};
  }
`;

const CustomHeader = ({
  monthDate,
  date,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  changeYear,
  changeMonth,
  locale,
  isMobile,
}: {
  monthDate: Date;
  date: Date;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  locale: string;
  isMobile: boolean;
}) => {
  moment.locale(locale);
  const years = range(2008, new Date().getFullYear() + 1, 1);
  const months = moment.months();
  const localMonth = monthDate.toLocaleString(locale, {
    month: "long",
  });
  const localYear = monthDate.getFullYear();

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <TextIconButton
        aria-label="Previous Month"
        style={customHeaderCount === 1 ? { visibility: "hidden" } : undefined}
        onClick={decreaseMonth}
        size="small"
      >
        <KeyboardArrowLeftIcon />
      </TextIconButton>
      <Stack direction="row">
        <BasicMenu
          buttonText={localMonth}
          content={months.map((option) => (
            <StyledMenuItem
              value={option}
              key={option}
              selected={option === localMonth}
              onClick={() => {
                changeMonth(
                  date.getMonth() === monthDate.getMonth()
                    ? months.indexOf(option)
                    : months.indexOf(option) - 1,
                );
              }}
              data-uncensored="true"
            >
              {option}
            </StyledMenuItem>
          ))}
        />

        <BasicMenu
          buttonText={localYear}
          content={years.map((option) => (
            <StyledMenuItem
              value={option}
              key={option}
              selected={option === localYear}
              onClick={() => {
                changeYear(
                  date.getFullYear() === localYear ? option : option - 1,
                );
              }}
              data-uncensored="true"
            >
              {option}
            </StyledMenuItem>
          ))}
        />
      </Stack>
      <TextIconButton
        aria-label="Next Month"
        style={
          isMobile
            ? undefined
            : customHeaderCount === 0
              ? { visibility: "hidden" }
              : undefined
        }
        onClick={increaseMonth}
        size="small"
      >
        <KeyboardArrowRightIcon />
      </TextIconButton>
    </Stack>
  );
};

export const DatePickerComponent = ({
  startDate,
  endDate,
  onChange,
  presets,
  showFooter,
  showInputs,
  setStartDate,
  setEndDate,
  onClose,
  onApply,
}: DatePickerProps) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const locale = useLocale();
  const [disabledApply, setDisabledApply] = useState(true);
  const [startDateInput, setStartDateInput] = useState(
    startDate ? filterDateFormatter(startDate, DateFormat.String) : "",
  );

  const [endDateInput, setEndDateInput] = useState(
    endDate ? filterDateFormatter(endDate, DateFormat.String) : "",
  );

  const isMobile = useIsMobile();
  const [prevStartDate, setPrevStartDate] = useState(startDate);
  const [prevEndDate, setPrevEndDate] = useState(endDate);

  const [selectedPreset, setSelectedPreset] = useState<number>();
  if (startDate && prevStartDate !== startDate) {
    setPrevStartDate(startDate);
    setStartDateInput(filterDateFormatter(startDate, DateFormat.String));
    setDisabledApply(false);
  }

  const isEndDateDiff = endDate && prevEndDate !== endDate;
  const isEndDateReset = !endDate && prevEndDate;
  if (isEndDateDiff || isEndDateReset) {
    setPrevEndDate(endDate);
    setEndDateInput(
      endDate ? filterDateFormatter(endDate, DateFormat.String) : "",
    );
  }

  const onReset = () => {
    setStartDate(null);
    setEndDate(null);
    setStartDateInput("0000-00-00"); // default date input when empty is 0000-00-00
    setEndDateInput("0000-00-00");
    onChange([null, null], undefined);
    if (prevStartDate || prevEndDate) {
      setDisabledApply(false);
    } else {
      setDisabledApply(true);
    }
  };

  const selectPreset = (preset: FinancialYear) => {
    const startDate = new Date(preset.start);
    const endDate = new Date(preset.end);
    setStartDate(startDate);
    setEndDate(endDate);
    setStartDateInput(filterDateFormatter(startDate, DateFormat.String));
    setEndDateInput(filterDateFormatter(endDate, DateFormat.String));
  };

  return (
    <ClickAwayListener onClickAway={onApply}>
      <Stack direction="column" alignItems="center">
        <Stack
          direction={isMobile ? "column" : "row"}
          style={{
            backgroundColor: tokens.background.input.default,
            borderRadius: "0.25rem 0.25rem 0 0",
          }}
        >
          {presets && presets.length > 0 ? (
            <Stack
              direction={isMobile ? "row" : "column"}
              sx={
                isMobile
                  ? {
                      borderBottom: `${tokens.border.neutral.default} 1px solid`,
                      overflow: "auto",
                      width: "19rem",
                    }
                  : {
                      borderRight: `${tokens.border.neutral.default} 1px solid`,
                      overflow: "auto",
                      paddingTop: "0.75rem",
                      height: "23rem",
                    }
              }
            >
              <Box
                sx={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "0.875rem",
                  whiteSpace: "nowrap",
                  padding: isMobile ? "0.875rem 0.75rem" : "0.375rem 1rem",
                }}
              >
                {lang.report.financialYear}
                {isMobile ? ":" : ""}
              </Box>
              {presets.map((option: FinancialYear) => (
                <StyledMenuItem
                  key={option.year}
                  onClick={() => {
                    selectPreset(option);
                    setSelectedPreset(option.year);
                  }}
                  selected={option.year === selectedPreset}
                  data-uncensored="true"
                >
                  {displayTimeframe(option, lang)}
                </StyledMenuItem>
              ))}
            </Stack>
          ) : null}
          <Stack
            direction="column"
            alignItems="center"
            sx={{ paddingTop: "0.938rem", px: isMobile ? "1.2rem" : "0.75rem" }}
          >
            {showInputs ? (
              <Stack
                direction={isMobile ? "column" : "row"}
                alignItems="center"
                spacing="0.75rem"
              >
                <TextField
                  variant="outlined"
                  label="Start"
                  type="date"
                  value={startDateInput}
                  sx={{ width: "13.125rem" }}
                  onBlur={() => {
                    if (moment(startDateInput).isValid()) {
                      const date = moment(startDateInput).toDate();
                      setStartDate(date);
                      setDisabledApply(false);
                    } else {
                      setDisabledApply(true);
                    }
                  }}
                  onChange={(e) => {
                    setStartDateInput(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (moment(startDateInput).isValid()) {
                        const date = moment(startDateInput).toDate();
                        setStartDate(date);
                        setDisabledApply(false);
                      } else {
                        setDisabledApply(true);
                      }
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                {isMobile ? null : <Typography>to</Typography>}

                <TextField
                  variant="outlined"
                  label="End"
                  type="date"
                  value={endDateInput}
                  sx={{ width: "13.125rem" }}
                  onBlur={() => {
                    if (moment(endDateInput).isValid()) {
                      const date = moment(endDateInput).toDate();
                      setEndDate(date);
                    }
                  }}
                  onChange={(e) => {
                    setEndDateInput(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (moment(endDateInput).isValid()) {
                        const date = moment(endDateInput).toDate();
                        setEndDate(date);
                      }
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
            ) : null}
            <div css={datePickerCSS}>
              <DatePicker
                selected={
                  startDate ? transformDateTzToBrowser(startDate) : undefined
                }
                onChange={onChange}
                startDate={
                  startDate ? transformDateTzToBrowser(startDate) : undefined
                }
                endDate={
                  endDate ? transformDateTzToBrowser(endDate) : undefined
                }
                monthsShown={isMobile ? 1 : 2}
                selectsRange
                inline
                locale={locale}
                renderDayContents={(date) => {
                  return <span data-uncensored="true">{date}</span>;
                }}
                renderCustomHeader={(params) =>
                  CustomHeader({
                    ...params,
                    locale,
                    isMobile,
                  })
                }
                dayClassName={(date) => {
                  return (startDate &&
                    startDate.getTime() === date.getTime()) ||
                    (endDate && endDate.getTime() === date.getTime())
                    ? "react-datepicker__day--selected"
                    : null;
                }}
                disabledKeyboardNavigation
              />
            </div>
          </Stack>
        </Stack>
        {showFooter ? (
          <DatePickerFooter
            onApply={onApply}
            onReset={onReset}
            onClose={onClose}
            disabled={disabledApply}
          />
        ) : null}
      </Stack>
    </ClickAwayListener>
  );
};

const DatePickerFooter = ({
  onApply,
  onReset,
  onClose,
  disabled,
}: {
  onApply: () => void;
  onReset: () => void;
  onClose: () => void;
  disabled: boolean;
}) => {
  const lang = useLang();

  const applyButton = () => {
    onApply();
    onClose();
  };

  return (
    <StyledBox>
      <TextButton size="small" onClick={onReset}>
        {lang.clear}
      </TextButton>
      <Grid container direction="row" justifyContent="flex-end" flex="1">
        <TertiaryButton size="small" onClick={onClose}>
          {lang.cancel}
        </TertiaryButton>

        <Box ml={1}>
          <PrimaryButton size="small" onClick={applyButton} disabled={disabled}>
            {lang.apply}
          </PrimaryButton>
        </Box>
      </Grid>
    </StyledBox>
  );
};

// Overload
export function filterDateFormatter(
  date: string | number | Date,
  format: DateFormat.Date,
  dateTime?: DateTime,
): Date;
export function filterDateFormatter(
  date: string | number | Date,
  format: DateFormat.Number,
  dateTime?: DateTime,
): number;
export function filterDateFormatter(
  date: string | number | Date,
  format: DateFormat.String,
  dateTime?: DateTime,
): string;
// Function
export function filterDateFormatter(
  date: string | number | Date,
  format: DateFormat,
  dateTime?: DateTime,
): string | number | Date {
  const ISOFormat = "yyyy-MM-DD";

  switch (dateTime) {
    case DateTime.Start: {
      switch (format) {
        case DateFormat.Date:
          return moment(date).startOf("day").toDate();
        case DateFormat.Number:
          return moment(date).startOf("day").valueOf();
        case DateFormat.String:
          return moment(date).startOf("day").format(ISOFormat);
        default: {
          const exhaustiveCheck: never = format;
          throw new Error(`Unhandled case: ${exhaustiveCheck}`);
        }
      }
    }
    case DateTime.End: {
      switch (format) {
        case DateFormat.Date:
          return moment(date).endOf("day").toDate();
        case DateFormat.Number:
          return moment(date).endOf("day").valueOf();
        case DateFormat.String:
          return moment(date).endOf("day").format(ISOFormat);
        default: {
          const exhaustiveCheck: never = format;
          throw new Error(`Unhandled case: ${exhaustiveCheck}`);
        }
      }
    }
    default: {
      switch (format) {
        case DateFormat.Date:
          return moment(date).toDate();
        case DateFormat.Number:
          return moment(date).valueOf();
        case DateFormat.String:
          return moment(date).format(ISOFormat);
        default: {
          const exhaustiveCheck: never = format;
          throw new Error(`Unhandled case: ${exhaustiveCheck}`);
        }
      }
    }
  }
}

const StyledBox = styled(Box)`
  && {
    border-top: ${({ theme }) => theme.tokens.border.neutral.default} 1px solid;
    width: 100%;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.tokens.elevation.low};
    border-radius: 0 0 0.25rem 0.25rem;
  }
`;

const datePickerCSS = css`
  /* don't allow any of the text to be selected */
  user-select: none;

  & .react-datepicker {
    border-radius: none;
    border: 0px;
    background-color: ${({ theme }) =>
      theme.tokens.background.input.default} !important;
  }

  & .react-datepicker__day {
    color: ${({ theme }) => theme.tokens.text.default};
    font-family: "Metropolis";
    font-weight: 500;
    border-radius: 0.3rem;
  }

  & .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.tokens.text.disabled};
  }

  & .react-datepicker__day--selected,
  & .react-datepicker__day--selected.react-datepicker__day--in-range,
  & .react-datepicker__day--range-end {
    background-color: ${({ theme }) =>
      theme.tokens.button.brand.default} !important;
    color: ${({ theme }) => theme.tokens.text.inverse} !important;
  }
  & .react-datepicker__day--selected:hover,
  & .react-datepicker__day--selected.react-datepicker__day--in-range:hover,
  & .react-datepicker__day--range-end:hover {
    background-color: ${({ theme }) =>
      theme.tokens.button.brand.hover} !important;
    color: ${({ theme }) => theme.tokens.text.inverse};
  }
  &
    .react-datepicker__day:hover:not(
      & .react-datepicker__day--selected,
      & .react-datepicker__day--range-end
    ) {
    background-color: ${({ theme }) => theme.tokens.background.brand.pressed};
    color: ${({ theme }) => theme.tokens.text.default};
    outline: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }

  & .react-datepicker__day--in-range,
  &
    .react-datepicker__day--in-selecting-range:not(
      & .react-datepicker__day--selected
    ) {
    color: ${({ theme }) => theme.tokens.text.default};
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.purple.low};
  }

  & .react-datepicker__day-names {
    background: ${({ theme }) => theme.tokens.background.input.default};
    font-family: "Roboto";
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
  }

  & .react-datepicker__day-name {
    color: ${({ theme }) => transparentize(0.5, theme.tokens.text.default)};
  }

  & .react-datepicker__header {
    background-color: ${({ theme }) => theme.tokens.background.input.default};
    border: none;
  }

  & .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.tokens.background.brand.pressed};
    color: ${({ theme }) => theme.tokens.icon.default};
    outline: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }

  & .react-datepicker__month {
    background-color: ${({ theme }) =>
      theme.tokens.background.input.default} !important;
  }

  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  [type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
