export enum FilterCountType {
  All = "all",
  Query = "query",
  Other = "other",
}
export enum GroupingCriteria {
  // FE Check
  SameCurrency = "sameCurrency", // Does it require both sides of the tx to have the same currency
  DifferentCurrency = "differentCurrency", // Does it require both sides of the tx to have different currencies
  SameValueFeeExclusive = "sameValueFeeExclusive", // Does it require both sides of the tx to have the same value exclusive of fee
  SameValueFeeInclusive = "sameValueFeeInclusive", // Does it require both sides of the tx to have the same value inclusive or exclusive of fee
  SimilarQuantity = "similarQuantity", // Does it have similar quantities on both sides
  FiatCurrency = "fiatCurrency", // Does it have fiat currency

  // BE Check only
  DontGroup = "dontGroup", // Does it have the dontGroup flag set
  SameTimestamp = "sameTimestamp", // Does it have the same timestamp
  SameTxId = "sameTxId", // Does it have the same txId
  SimilarTimestamp = "similarTimestamp", // Does it have a similar timestamp
  DifferentSource = "differentSource", // Does it have different import sources
  SameFromTo = "sameFromTo", // The from and the to are the same
  SameAccount = "sameAccount", // The account relevant to the user is the same
  DifferentAssetType = "differentAssetType",
}
