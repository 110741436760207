import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useQueryParam } from "use-query-params";

import { Calco } from "~/components/ui/calco/Calco";
import { AUTO_CLOSE_MESSAGE } from "~/constants/constants";
import { BroadcastChannels } from "~/hooks/enums";
import { useLang } from "~/redux/lang";

export function AutoClose() {
  const [channel] = useQueryParam<string>("channel");
  const [messageFromQuery] = useQueryParam<string>("message");
  const lang = useLang();
  useEffect(() => {
    if (
      Object.values(BroadcastChannels).includes(channel as BroadcastChannels)
    ) {
      // Some channels like payments want to pass a message to the parent window
      // Others just want to send the window can be closed
      const message = messageFromQuery ? messageFromQuery : AUTO_CLOSE_MESSAGE;
      const broadcastChannel = new BroadcastChannel(channel);
      // Send message to parent window indicating auto close completion
      broadcastChannel.postMessage(message);
      broadcastChannel.close();

      // Add small delay before closing window to ensure message is sent
      setTimeout(() => {
        window.close();
      }, 100);
    }
  }, [channel, messageFromQuery]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Calco type="wizard" width={300} />
      <Typography variant="Metropolis/Header/H5" textAlign="center">
        {lang.autoClose.title}
      </Typography>
    </Box>
  );
}
