import { type LocalCurrency, type SupportedLang } from "@ctc/types";
import { Box, Tooltip } from "@mui/material";
import { DataGrid, type GridColDef } from "@mui/x-data-grid";
import { useContext } from "react";
import styled from "styled-components/macro";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { CalcoNoRows } from "~/components/report/tax-loss-harvesting-modal/components/CalcoNoRows";
import { GridCustomToolbar } from "~/components/report/tax-loss-harvesting-modal/components/GridCustomToolbar";
import { NumericCell } from "~/components/report/tax-loss-harvesting-modal/components/NumericCell";
import { PriceCell } from "~/components/report/tax-loss-harvesting-modal/components/PriceCell";
import { PriceHeader } from "~/components/report/tax-loss-harvesting-modal/components/PriceHeader";
import { TableColumns } from "~/components/report/tax-loss-harvesting-modal/enums";
import { useTLHTableRows } from "~/components/report/tax-loss-harvesting-modal/useTLHTableRows";
import { Checkbox } from "~/components/ui/Checkbox";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { useIsMobile } from "~/components/ui/hooks";
import { ValueChangeBox } from "~/components/ui/ValueChangeBox";
import { TaxLossHarvestingContext } from "~/contexts/TaxLossHarvestingContext";
import { useHideIntercom } from "~/hooks/useHideIntercom";
import { type Translation } from "~/lang/index";
import { displayCurrencyName, displayFiatValue } from "~/lib/index";
import { useUser } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { type CurrencyIdentifier } from "~/types/index";
// required because the table crashes with whitespace in the currency id for some reason
export const cleanCurrencyId = (currencyId: string) => currencyId.trim();

const StyledDataGridPro = styled(DataGrid)`
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  
  & .MuiDataGrid-columnHeaders {
    border-bottom: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
    background-color: ${({ theme }) => theme.tokens.elevation.low};
  }
  
  & .MuiDataGrid-footerContainer {
    border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }

  & .MuiDataGrid-iconSeparator {
    display: none;
  }

  & .MuiDataGrid-columnHeader {
    border-top: 1px solid;
    border-color: ${({ theme }) => theme.tokens.border.neutral.default}};
  }

  & .MuiDataGrid-cellContent {
    font-weight: 500;
  }

  & .MuiDataGrid-cell {
    outline: none !important;
    border-bottom: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }

  & .MuiDataGrid-toolbarContainer {
    background-color: ${({ theme }) => theme.tokens.elevation.low};
    min-height: 3.25rem;
    padding: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }
  
  & .MuiDataGrid-columnHeadersInner {
    background-color: ${({ theme }) => theme.tokens.elevation.low};
  }
`;

const defaultFormatter =
  (localCurrency: LocalCurrency, languagePreference: SupportedLang) =>
  (params: { value: number }) => {
    return params.value
      ? displayFiatValue({
          value: params.value,
          localCurrency,
          locale: languagePreference,
        })
      : "-";
  };

const nonFiatNumberFormatter = (params: { value: number }) => {
  return params.value ? params.value?.toLocaleString() : "-";
};

const numberOrUndefinedComparator = (a: number, b: number) => a - b;

const getColumns = (
  lang: Translation,
  localCurrency: LocalCurrency,
  languagePreference: SupportedLang,
): GridColDef[] => [
  {
    field: "HACK_IN_ORDER_TO_FILTER_BY_CURRENCY",
    hide: true,
    filterable: true,
  },
  {
    field: TableColumns.Currency,
    headerName: lang.taxLossHarvesting.tableHeaders.asset,
    renderCell: (params: any) => {
      return (
        <Box display="flex" alignItems="center">
          <CurrencyLogo currency={params.value} />
          {displayCurrencyName(params.value, true)}
        </Box>
      );
    },
    sortComparator: (a: CurrencyIdentifier, b: CurrencyIdentifier) => {
      return a.name.localeCompare(b.name);
    },
    flex: 1,
    minWidth: 200,
  },
  {
    field: TableColumns.Price,
    headerName: lang.taxLossHarvesting.tableHeaders.price,
    renderHeader: PriceHeader,
    type: "number",
    renderCell: (params) => <PriceCell {...params} />,
    flex: 1,
    sortComparator: numberOrUndefinedComparator,
    maxWidth: 250,
    minWidth: 200,
    editable: false, // we don't edit this via the table
    align: "right",
    filterable: true,
  },
  {
    field: TableColumns.Balance,
    headerName: lang.taxLossHarvesting.tableHeaders.amountHeld,
    flex: 1,
    maxWidth: 150,
    minWidth: 150,
    type: "number",
    sortComparator: numberOrUndefinedComparator,
    align: "right",
    filterable: false,
    renderCell: (params) => (
      <NumericCell params={params} formatter={nonFiatNumberFormatter} />
    ),
  },
  {
    field: TableColumns.Value,
    headerName: lang.taxLossHarvesting.tableHeaders.currentMarketValue,
    flex: 1,
    sortComparator: numberOrUndefinedComparator,
    maxWidth: 150,
    minWidth: 150,
    align: "right",
    type: "number",
    filterable: false,
    renderCell: (params) => (
      <NumericCell
        params={params}
        formatter={defaultFormatter(localCurrency, languagePreference)}
      />
    ),
  },
  {
    field: TableColumns.Cost,
    headerName: lang.taxLossHarvesting.tableHeaders.costBasis,
    flex: 1,
    sortComparator: numberOrUndefinedComparator,
    maxWidth: 150,
    minWidth: 150,
    align: "right",
    type: "number",
    filterable: false,
    renderCell: (params) => (
      <NumericCell
        params={params}
        formatter={defaultFormatter(localCurrency, languagePreference)}
      />
    ),
  },
  {
    field: TableColumns.Gain,
    headerName: lang.taxLossHarvesting.tableHeaders.unrealizedGainsLosses,
    description: lang.taxLossHarvesting.tooltips.unrealizedGains,
    flex: 1,
    renderCell: (params: any) => {
      return <ValueChangeBox value={parseFloat(params.value)} />;
    },
    sortComparator: numberOrUndefinedComparator,
    maxWidth: 300,
    minWidth: 250,
    align: "right",
    type: "number",
    valueFormatter: defaultFormatter(localCurrency, languagePreference),
    filterable: false,
  },
  {
    field: TableColumns.AmountToSell,
    headerName: lang.taxLossHarvesting.tableHeaders.amountToSell,
    flex: 1,
    sortComparator: numberOrUndefinedComparator,
    maxWidth: 175,
    minWidth: 175,
    align: "right",
    type: "number",
    renderCell: (params) => (
      <NumericCell params={params} formatter={nonFiatNumberFormatter} />
    ),
    filterable: false,
  },
];

export const TLHTable = () => {
  const lang = useLang();
  const isMobile = useIsMobile();
  const user = useUser();
  const languagePreference = useLanguagePreference();
  const rows = useTLHTableRows();
  const tlhContext = useContext(TaxLossHarvestingContext);
  const captureAnalytics = useCaptureAnalytics();

  // intercom icon sometimes covers the table, so we hide it for now
  useHideIntercom(true);

  if (!user || !user.localCurrency || !tlhContext) return null; // Theoretical, this never occurs and is just type wrangling

  return (
    <Box
      pb={isMobile ? "0.5rem" : "1.5rem"}
      px={isMobile ? "0.5rem" : "2rem"}
      mt={isMobile ? "0.5rem" : "1.5rem"}
      height="100%"
      minHeight="30rem"
    >
      <StyledDataGridPro
        columns={getColumns(lang, user.localCurrency, languagePreference)}
        rows={rows || []}
        experimentalFeatures={{ newEditingApi: true }} // allows editing of cells :rocket:
        disableColumnSelector // column selector allows user to hide/ show columns
        disableColumnMenu // column menu allows user to sort columns
        initialState={{
          sorting: {
            sortModel: [
              {
                field: TableColumns.Gain,
                sort: "asc",
              },
            ],
          },
        }}
        components={{
          Toolbar: GridCustomToolbar,
          NoRowsOverlay: () => <CalcoNoRows isLoading={rows === null} />,
          NoResultsOverlay: () => <CalcoNoRows isLoading={rows === null} />,
          ColumnFilteredIcon: () => <></>,
          FilterPanel: () => <></>,
          BaseCheckbox: (props) => {
            const { disabled } = props;
            return (
              <Tooltip
                title={
                  disabled
                    ? lang.taxLossHarvesting.table.rowSelectionDisabled
                    : ""
                }
                placement="right-end"
              >
                <div>
                  <Checkbox {...props} />
                </div>
              </Tooltip>
            );
          },
        }}
        checkboxSelection
        pagination={undefined}
        isRowSelectable={(params) => params.row.gain < 0} // can only select rows with losses
        selectionModel={Array.from(tlhContext.lossesToHarvest.values())}
        onSelectionModelChange={(newSelection) => {
          tlhContext.updateLossesToHarvest(new Set(newSelection as string[]));
          captureAnalytics("tlh_currency_selection_changed", {
            tableRowsSelected: newSelection,
          });
        }}
      />
    </Box>
  );
};
