import { get } from "../src/services/core";

/**
 * In-memory storage for CSRF token
 */
let token: string | null = null;

/**
 * Tracks the pending token refresh promise to prevent multiple simultaneous requests
 */
let pendingTokenRefresh: Promise<string | null> | null = null;

/**
 * CSRF token manager object that handles token storage and operations
 */
export const csrfTokenManager = {
  /**
   * Get the stored CSRF token
   * @returns The current CSRF token or null if not set
   */
  getStoredToken(): string | null {
    return token;
  },

  /**
   * Store a new CSRF token
   * @param newToken - The token to store
   */
  setStoredToken(newToken: string | null): void {
    token = newToken;
  },

  /**
   * Clear the stored CSRF token
   */
  clearStoredToken(): void {
    token = null;
  },

  /**
   * Fetch a fresh CSRF token from the server and store it
   * @returns Promise<string | null> The new token or null if the request failed
   */
  async refreshCsrfToken(): Promise<string | null> {
    const response = await get<string>("/csrf-token");
    if (!response.error && response.data) {
      this.setStoredToken(response.data);
      return response.data;
    }
    return null;
  },

  /**
   * Initialize CSRF token if not already stored
   * If multiple calls happen simultaneously, subsequent calls will wait for the first refresh to complete
   * @returns Promise<string | null> The current token or null if initialization failed
   */
  async initializeCsrfToken(): Promise<string | null> {
    if (token) {
      return token;
    }

    if (pendingTokenRefresh) {
      return pendingTokenRefresh;
    }

    pendingTokenRefresh = this.refreshCsrfToken();

    try {
      return await pendingTokenRefresh;
    } finally {
      pendingTokenRefresh = null;
    }
  },

  /**
   * A token we know is staled is passed in, if it matches the token in the manager
   * we can clear the token in the manager
   * @param staleToken - The token that was rejected by the server
   */
  clearStaleToken(staleToken: string | null) {
    if (staleToken === this.getStoredToken()) {
      this.clearStoredToken();
    }
  },
};
