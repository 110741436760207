import { type Blockchain } from "@ctc/types";
import { Box, Stack } from "@mui/material";
import type * as React from "react";

import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { AddressVariant } from "~/components/ui/enums";
import { ExternalAddressLink } from "~/components/ui/ExternalAddressLink";
import { useDesign } from "~/hooks/useTheme";
import { middleTrim } from "~/lib/index";
import { useLang } from "~/redux/lang";
import { Size } from "~/types/enums";

export type AddressProps = {
  address: string;
  addressLabel?: string;
  blockchain?: Blockchain;
  addressIcon?: React.ReactNode;
  addressLinkExternal?: string | undefined | null;
  variant?: AddressVariant;
  size?: Size;
  color?: string;
  enableTooltip?: boolean;
};

export function Address({
  address,
  addressLabel,
  blockchain,
  addressLinkExternal,
  addressIcon,
  variant = AddressVariant.Contained,
  size = Size.Medium,
  color,
  enableTooltip = true,
}: AddressProps) {
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <Box
      sx={
        variant === AddressVariant.Contained
          ? {
              background: tokens.elevation.low,
              border: `1px solid ${tokens.border.neutral.default}`,
              padding: "0.5rem",
              borderRadius: "0.25rem",
              fontSize: "0.75rem",
              fontWeight: 500,
            }
          : undefined
      }
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing="0.25rem"
      >
        <Stack
          sx={{ marginRight: "auto" }}
          direction="row"
          alignItems="center"
          spacing="0.5rem"
        >
          {!!addressIcon && addressIcon}
          <Stack direction="column">
            <Box
              sx={{
                minWidth: 0,
                overflowWrap: "anywhere",
              }}
            >
              {addressLabel || middleTrim(address, 42, 16)}
            </Box>
            {blockchain ? (
              <Box sx={{ fontSize: "0.6rem", color: tokens.text.low }}>
                {blockchain.toUpperCase()}
              </Box>
            ) : null}
          </Stack>
        </Stack>
        <CopyIconButton
          contentToCopy={address}
          color={color || tokens.icon.default}
          width={size === Size.Small ? 12 : 16}
          enableTooltip={enableTooltip}
          noPadding
        />
        {addressLinkExternal && (
          <ExternalAddressLink
            link={addressLinkExternal}
            tooltip={enableTooltip ? lang.txTable.viewAddress : undefined}
            color={color || tokens.icon.default}
            width={size === Size.Small ? 12 : 16}
            noPadding
          />
        )}
      </Stack>
    </Box>
  );
}
