import { SyncStatusPlatform } from "@ctc/types";
import {
  CheckCircleOutline,
  Close,
  FileDownloadOutlined,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { StyledDialogActions } from "~/components/ui/StyledDialogActions";
import { PaywallModalContext } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { useDesign } from "~/hooks/useTheme";
import { useTransactionsTotalFilteredCount } from "~/hooks/useTransactions";
import { useIsManagingClients, usePaywallInfo } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useGetActionsQuery } from "~/state/actions";
import { useDownloadFilteredTxReportCSVMutation } from "~/state/report";
import {
  getFilteredTxReportSyncType,
  useSyncStatus,
  useUserSyncStatus,
} from "~/state/sync";
import { Links } from "~/types/enums";

enum Options {
  Filter = "filter",
  Page = "page",
}

export function CsvDownloadModal({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) {
  const lang = useLang();
  const { tokens } = useDesign();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ backgroundColor: tokens.elevation.low }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="Metropolis/Header/H4"
            color={tokens.text.default}
          >
            {lang.txTable.exportCSV.title}
          </Typography>
          <TextIconButton onClick={handleClose}>
            <Close sx={{ color: tokens.text.low, fontSize: "1rem" }} />
          </TextIconButton>
        </Box>
      </DialogTitle>
      <DialogContents handleClose={handleClose} />
    </Dialog>
  );
}

function DialogContents({ handleClose }: { handleClose: () => void }) {
  const lang = useLang();
  const { tokens } = useDesign();
  const { isPaywalled } = usePaywallInfo();
  const setMinWidth = useMediaQuery("(min-width: 27rem)");
  const totalFilteredCount = useTransactionsTotalFilteredCount();
  const {
    mutate,
    isPending: mutationPending,
    isSuccess,
  } = useDownloadFilteredTxReportCSVMutation();
  const [selectedOption, setSelectedOption] = useState<Options>(Options.Filter);
  const {
    state: { filter, count, page, sort },
  } = useTransactionFilter();
  const scope = getFilteredTxReportSyncType();
  const alreadyFetching = useSyncStatus(scope) === SyncStatusPlatform.Pending;
  const pageCount = totalFilteredCount < count ? totalFilteredCount : count;

  const showLoadingScreen = alreadyFetching || mutationPending;
  const submitButtonCount =
    selectedOption === Options.Page ? pageCount : totalFilteredCount;
  const showActions = !showLoadingScreen && !isSuccess;

  const submit = () => {
    mutate({ filter, count: submitButtonCount, page, sort });
  };

  const reportStatus = useUserSyncStatus();
  const reportLoading = reportStatus === SyncStatusPlatform.Pending;

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          minWidth: setMinWidth ? "27rem" : 0,
          backgroundColor: tokens.elevation.low,
        }}
      >
        {isPaywalled ? <PaywallInfoBox /> : null}
        {showLoadingScreen ? (
          <GeneratingReport />
        ) : isSuccess ? (
          <Success submit={submit} />
        ) : (
          <FormOptions
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
          />
        )}
      </DialogContent>
      {showActions ? (
        <StyledDialogActions>
          <TertiaryButton onClick={handleClose}>
            <Typography variant="Metropolis/Caption/Medium/Regular">
              {lang.cancel}
            </Typography>
          </TertiaryButton>
          <Tooltip title={reportLoading ? lang.syncStatus.updating : ""}>
            <PrimaryButton
              onClick={submit}
              disabled={reportLoading || isPaywalled}
              endIcon={
                reportLoading ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <FileDownloadOutlined
                    sx={{ width: "1rem", height: "1rem" }}
                  />
                )
              }
              sx={{ padding: "0.375rem" }}
            >
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                color={
                  isPaywalled || reportLoading
                    ? tokens.text.disabled
                    : tokens.text.white
                }
              >
                {lang.txTable.exportCSV.submitButton({
                  count: submitButtonCount,
                })}
              </Typography>
            </PrimaryButton>
          </Tooltip>
        </StyledDialogActions>
      ) : null}
    </>
  );
}

function GeneratingReport() {
  const { tokens } = useDesign();
  const lang = useLang();
  return (
    <StyledBox>
      <CircularProgress size="2.5rem" />
      <Typography variant="Metropolis/Body/Regular" color={tokens.text.high}>
        {lang.txTable.exportCSV.pending.title}
      </Typography>
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        color={tokens.text.low}
        width="19.375rem"
        textAlign="center"
      >
        {lang.txTable.exportCSV.pending.captionPt1}
        <br />
        {lang.txTable.exportCSV.pending.captionPt2}
      </Typography>
    </StyledBox>
  );
}

function Success({ submit }: { submit: () => void }) {
  const { tokens } = useDesign();
  const lang = useLang();
  return (
    <StyledBox>
      <CheckCircleOutline
        sx={{ fontSize: "2.5rem", color: tokens.text.success }}
      />
      <Typography variant="Metropolis/Body/Regular" color={tokens.text.high}>
        {lang.txTable.exportCSV.success.title}
      </Typography>
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        color={tokens.text.low}
      >
        {lang.txTable.exportCSV.success.captionPt1}
        <StyledSpan onClick={submit}>
          {lang.txTable.exportCSV.success.captionPt2}
        </StyledSpan>
      </Typography>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.tokens.text.brand};
  &:hover {
    cursor: pointer;
  }
`;

function FormOptions({
  selectedOption,
  setSelectedOption,
}: {
  selectedOption: Options;
  setSelectedOption: (option: Options) => void;
}) {
  const { isPaywalled } = usePaywallInfo();
  const lang = useLang();

  const totalFilteredCount = useTransactionsTotalFilteredCount();
  const {
    state: { count },
  } = useTransactionFilter();

  const pageCount = totalFilteredCount < count ? totalFilteredCount : count;

  return (
    <FormControl fullWidth disabled={isPaywalled}>
      <RadioGroup
        value={selectedOption}
        onChange={(e) => {
          setSelectedOption(e.target.value as Options);
        }}
      >
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <FormControlLabel
            value={Options.Filter}
            control={
              <Radio
                size="small"
                disableRipple
                sx={{ width: "1.5rem", height: "1.5rem" }}
              />
            }
            label={
              <RadioLabel
                title={lang.txTable.exportCSV.filterOptionTitle}
                caption={lang.txTable.exportCSV.filterOptionCaption}
                count={totalFilteredCount}
              />
            }
            sx={{
              gap: "0.5rem",
              alignItems: "flex-start",
              marginLeft: 0,
            }}
          />
          <FormControlLabel
            value={Options.Page}
            control={
              <Radio
                disableRipple
                size="small"
                sx={{ width: "1.5rem", height: "1.5rem" }}
              />
            }
            label={
              <RadioLabel
                title={lang.txTable.exportCSV.pageOptionTitle}
                caption={lang.txTable.exportCSV.pageOptionCaption}
                count={pageCount}
              />
            }
            sx={{
              gap: "0.5rem",
              alignItems: "flex-start",
              marginLeft: 0,
            }}
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
}

function PaywallInfoBox() {
  const { tokens } = useDesign();
  const lang = useLang();
  const paywallContext = useContext(PaywallModalContext);
  const isAccountant = useIsManagingClients();
  const navigate = useNavigate();

  const onClick = () => {
    if (isAccountant) {
      paywallContext?.setUpgradeClientDialogOpen(true);
      return;
    }
    navigate(Links.Plans);
  };

  return (
    <Box
      display="flex"
      gap="0.5rem"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={tokens.elevation.high}
      border={`1px solid ${tokens.border.neutral.default}`}
      borderRadius="0.5rem"
      p="0.5rem"
    >
      <Typography variant="Metropolis/Body/Regular" color={tokens.text.high}>
        {lang.txTable.exportCSV.paywall.text}
      </Typography>
      <PrimaryButton onClick={onClick}>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.white}
        >
          {lang.txTable.exportCSV.paywall.button}
        </Typography>
      </PrimaryButton>
    </Box>
  );
}

function RadioLabel({
  title,
  caption,
  count,
}: {
  title: string;
  caption: string;
  count: number;
}) {
  const { tokens } = useDesign();
  const { isLoading: isLoadingCount } = useGetActionsQuery();

  return (
    <Box display="flex" flexDirection="column" gap="0.25rem">
      <Typography variant="Metropolis/Body/Regular" color={tokens.text.high}>
        {title}
      </Typography>
      {isLoadingCount ? (
        <Box display="flex" gap="0.375rem" alignItems="center">
          <Skeleton variant="text" width="3rem" />
          <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
            {caption}
          </Typography>
        </Box>
      ) : (
        <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
          {`${count} ${caption}`}
        </Typography>
      )}
    </Box>
  );
}
