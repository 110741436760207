import { CaptchaProvider } from "@ctc/types";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import type * as React from "react";
import { useEffect } from "react";
import styled from "styled-components/macro";
import { StringParam, useQueryParam } from "use-query-params";

import { useExperimentVariant } from "~/analytics/posthog";
import background from "~/assets/ctc-tile-background.svg";
import logo from "~/assets/logo.svg";
import logoDark from "~/assets/logo-reversed.svg";
import { LanguageButton } from "~/components/nav/LanguageButton";
import { REFERRER_CONFIG } from "~/components/payment/referrer-config";

// TODO: investigate why this is using dark coded colors
import { devices } from "~/components/ui/theme/legacy";
import { isReferrerSource } from "~/constants/constants";
import { LocalStorageKey } from "~/constants/enums";
import { useCaptchaProvider } from "~/hooks/useCaptchaProvider";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useDesign, useResolvedTheme } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { FeatureFlag, Theme } from "~/types/enums";

import {
  GlowBottomLeft,
  GlowBottomRight,
  GlowTopLeft,
  GlowTopRight,
} from "./sign-up/Glow";
import binanceLogo from "./sign-up/large-logos-left-box/binance-logo.svg";
import coinbaseLogo from "./sign-up/large-logos-left-box/coinbase-logo.svg";
// TODO: investigate why this is using dark coded colors
import metamaskLogoDark from "./sign-up/large-logos-left-box/metamask-logo.svg";
import metamaskLogoLight from "./sign-up/large-logos-left-box/metamask-logo-light-mode.svg";
import trustpilotLogoDark from "./sign-up/large-logos-left-box/trust-pilot-logo.svg";
import trustpilotLogoLight from "./sign-up/large-logos-left-box/trust-pilot-logo-light-mode.svg";
import trustpilotStars from "./sign-up/large-logos-left-box/trust-pilot-stars.svg";
import { TrustPilotLink } from "./sign-up/large-logos-left-box/TrustPilotLink";

type Props = {
  formText: {
    title: string;
    subTitle?: string;
  };
  formComponent?: React.FC;
  isSignUp?: boolean;
  children?: React.ReactNode;
};

export const Logo = ({ width = "11.25rem" }: { width?: string }) => {
  const theme = useResolvedTheme();
  return (
    <Box position="relative">
      <img
        style={{ width }}
        src={theme === Theme.Light ? logo : logoDark}
        alt="Crypto Tax Calculator Logo"
      />
    </Box>
  );
};

export const UserDetailsPage = ({
  formText,
  formComponent: FormComponent,
  isSignUp = false,
  children,
}: Props) => {
  const { title, subTitle } = formText;
  const theme = useResolvedTheme();
  const lang = useLang();
  const { captchaProvider } = useCaptchaProvider();
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const isInCoinbaseThemeExperiment = useExperimentVariant(
    FeatureFlag.CoinbaseTheme,
    "test",
  );
  const [sourceQuery] = useQueryParam("source", StringParam);
  const source =
    localStorage.getItem(LocalStorageKey.ReferrerSource) || sourceQuery;

  const partnerLogo = isReferrerSource(source)
    ? REFERRER_CONFIG[source]?.partnerLogo()
    : null;

  useEffect(() => {
    if (sourceQuery) {
      localStorage.setItem(LocalStorageKey.ReferrerSource, sourceQuery);
    }
  }, [sourceQuery]);

  if (isSignUp) {
    return (
      <Box
        position="relative"
        padding={{
          xs: "1rem 0.5rem",
          md: "1rem 1.5rem",
        }}
        display="flex"
        gap={{
          xs: isMobile ? "0" : "1.5rem",
          md: "2.5rem",
        }}
        flexDirection="column"
        justifyContent="space-between"
        alignSelf="stretch"
        flex="1 0 0"
        minHeight="100vh"
      >
        <Box
          padding={{ xs: "0 1.5rem", md: "0" }}
          display="flex"
          flexDirection="row"
        >
          <Logo width="6.8125rem" />
          <Box marginLeft="auto">
            <LanguageButton />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          margin="auto"
          maxWidth="100%"
          sx={{
            gap: {
              xs: "4rem",
              md: "7rem",
            },
          }}
        >
          <Box
            position="relative"
            sx={{
              borderRadius: "1rem",
              border: isMobile
                ? null
                : `1px solid ${tokens.border.neutral.medium}`,
              backgroundColor: tokens.elevation.default,
              padding: "1.5rem",
              width: {
                xs: "28.125rem",
              },
            }}
          >
            {isMobile || isInCoinbaseThemeExperiment ? null : (
              <>
                <GlowTopLeft />
                <GlowTopRight />
                <GlowBottomLeft />
                <GlowBottomRight />
              </>
            )}
            <Box
              textAlign="left"
              mb="1rem"
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                <Typography
                  variant={
                    isMobile ? "Metropolis/Header/H4" : "Metropolis/Header/H3"
                  }
                  color={tokens.text.high}
                >
                  {title}
                </Typography>
              </Box>
              {subTitle ? (
                <Box>
                  <Typography
                    variant={
                      isMobile
                        ? "Metropolis/Header/H5"
                        : "Metropolis/Header/H4"
                    }
                  >
                    {subTitle}
                  </Typography>
                </Box>
              ) : null}
            </Box>

            {FormComponent && <FormComponent />}
            {children}
          </Box>
          <Box
            flexDirection="column"
            gap="2rem"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={{
              display: {
                xs: "none",
                lg: "flex",
              },
            }}
          >
            <Stack direction="column">
              <Typography
                color={tokens.text.default}
                variant="Metropolis/Header/H4"
              >
                {lang.auth.signupPage.accurateCryptoTaxes}
              </Typography>
              <Typography
                color={tokens.text.default}
                variant="Metropolis/Header/H4"
              >
                {lang.auth.signupPage.noGuesswork}
              </Typography>
            </Stack>
            <Stack direction="column" gap="0.5rem">
              <Typography
                color={tokens.text.default}
                fontWeight="600"
                fontSize="0.75rem"
                variant="Metropolis/Body/Bold"
              >
                {partnerLogo
                  ? lang.auth.officialPartner
                  : lang.auth.officialPartners}
              </Typography>
              <Divider sx={{ width: "6.5625rem", margin: "0 auto" }} />
              <Box
                display="flex"
                flexDirection="column"
                gap="0.75rem"
                justifyContent="center"
                alignItems="center"
              >
                {partnerLogo ? (
                  partnerLogo
                ) : (
                  <>
                    <img
                      src={coinbaseLogo}
                      alt="Coinbase"
                      style={{
                        width: "6.75rem",
                        height: "1.25rem",
                      }}
                    />
                    <img
                      src={binanceLogo}
                      alt="Binance"
                      style={{
                        width: "7.625rem",
                        height: "1.5rem",
                      }}
                    />
                    <img
                      src={
                        theme === Theme.Light
                          ? metamaskLogoLight
                          : metamaskLogoDark
                      }
                      alt="MetaMask"
                      style={{
                        width: "10rem",
                        height: "1.75rem",
                      }}
                    />
                  </>
                )}
              </Box>
            </Stack>
            <Stack direction="column" gap="0.25rem" width="9.375rem">
              <TrustPilotLink>
                <img
                  src={trustpilotStars}
                  alt="Trustpilot 5 star reviews"
                  style={{
                    width: "15rem",
                    height: "2.5rem",
                  }}
                />
              </TrustPilotLink>
              <Typography
                color={tokens.text.high}
                variant="Metropolis/Caption/Medium/Regular"
              >
                {lang.auth.signupPage.trustpilot.title}{" "}
                <TrustPilotLink
                  style={{
                    color: tokens.text.high,
                    fontWeight: "700",
                  }}
                >
                  {lang.auth.signupPage.trustpilot.reviews}
                </TrustPilotLink>
              </Typography>
              <TrustPilotLink style={{ margin: "auto" }}>
                <img
                  src={
                    theme === Theme.Light
                      ? trustpilotLogoLight
                      : trustpilotLogoDark
                  }
                  alt="Trustpilot"
                  style={{
                    width: "6.21rem",
                    height: "1.625rem",
                  }}
                />
              </TrustPilotLink>
            </Stack>
          </Box>
        </Box>
        {captchaProvider === CaptchaProvider.Recaptcha ? (
          <Box>
            <Typography
              textAlign="center"
              color={tokens.text.low}
              variant="Metropolis/Caption/Medium/Regular"
            >
              {lang.auth.recaptcha.part1}{" "}
              <Link
                href="https://policies.google.com/privacy"
                color={tokens.text.brand}
                variant="Metropolis/Caption/Medium/Regular"
                underline="none"
              >
                {lang.auth.privacyPolicy}
              </Link>{" "}
              {lang.auth.recaptcha.part2}{" "}
              <Link
                href="https://policies.google.com/terms"
                color={tokens.text.brand}
                variant="Metropolis/Caption/Medium/Regular"
                underline="none"
              >
                {lang.auth.termsAndConditions}
              </Link>{" "}
              {lang.auth.recaptcha.part3}
            </Typography>
          </Box>
        ) : null}
      </Box>
    );
  }
  return (
    <Box
      position="relative"
      padding={{
        xs: "1rem 0.5rem",
        md: "1rem 1.5rem",
      }}
      display="flex"
      gap={{
        xs: isMobile ? "0" : "1.5rem",
        md: "2.5rem",
      }}
      flexDirection="column"
      justifyContent="space-between"
      alignSelf="stretch"
      flex="1 0 0"
      minHeight="100vh"
    >
      <Box
        padding={{ xs: "0 1.5rem", md: "0" }}
        display="flex"
        flexDirection="row"
      >
        <Logo width="6.8125rem" />
        <Box marginLeft="auto">
          <LanguageButton />
        </Box>
      </Box>
      <Box
        position="relative"
        sx={{
          gap: {
            xs: "4rem",
            md: "7rem",
          },
        }}
        margin="auto"
        maxWidth="100%"
      >
        {isMobile || isInCoinbaseThemeExperiment ? null : (
          <>
            <GlowTopLeft />
            <GlowTopRight />
            <GlowBottomLeft />
            <GlowBottomRight />
          </>
        )}
        <Box
          sx={{
            borderRadius: "1rem",
            border: isMobile
              ? null
              : `1px solid ${tokens.border.neutral.medium}`,
            backgroundColor: tokens.elevation.default,
            padding: "1.5rem",
          }}
        >
          <Box
            textAlign="left"
            mb="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography
              variant={
                isMobile ? "Metropolis/Header/H4" : "Metropolis/Header/H3"
              }
              color={tokens.text.high}
            >
              {title}
            </Typography>
            <Typography variant="Metropolis/Body/Regular">
              {subTitle}
            </Typography>
          </Box>
          {FormComponent && <FormComponent />}
          {children}
        </Box>
      </Box>
      {captchaProvider === CaptchaProvider.Recaptcha ? (
        <Box>
          <Typography
            textAlign="center"
            color={tokens.text.low}
            variant="Metropolis/Caption/Medium/Regular"
          >
            {lang.auth.recaptcha.part1}{" "}
            <Link
              href="https://policies.google.com/privacy"
              color={tokens.text.brand}
              variant="Metropolis/Caption/Medium/Regular"
            >
              {lang.auth.privacyPolicy}
            </Link>{" "}
            {lang.auth.recaptcha.part2}{" "}
            <Link
              href="https://policies.google.com/terms"
              color={tokens.text.brand}
              variant="Metropolis/Caption/Medium/Regular"
            >
              {lang.auth.termsAndConditions}
            </Link>{" "}
            {lang.auth.recaptcha.part3}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export const BackgroundBox = styled(Box)<{
  isLogoExperiment: boolean | undefined;
}>`
  display: flex;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.tokens.background.neutral.default};
  @media ${devices.phone} {
    ${({ theme, isLogoExperiment }) =>
      isLogoExperiment
        ? "background-color: transparent;"
        : `
    background-color: ${theme.tokens.special.lowest};
    background-image: url(${background});
    background-position: bottom right;
    background-repeat: no-repeat;
  `}
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
