import { TableRow as MaterialRow, type TableRowProps } from "@mui/material";
import { type ReactNode } from "react";
import styled from "styled-components/macro";

export type OptionsType = {
  handler: () => void;
  label: string;
  icon: ReactNode | undefined;
  pending?: boolean;
  disabled?: boolean;
};

const HoverableWrapper = styled.span<{ open: boolean }>`
  height: 1.5rem;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
`;

export const TableRow = styled(
  ({
    open,
    disablePointer,
    disableBackground,
    ...props
  }: {
    open?: boolean;
    disablePointer?: boolean;
    disableBackground?: boolean;
  } & TableRowProps) => <MaterialRow {...props} />,
)`
  text-align: right;
  cursor: ${({ disablePointer }) => !disablePointer && "pointer"};
  position: relative;

  .rotate {
    transform: rotate(180deg);
  }
  .large {
    font-size: 1rem;
  }
  .date {
    font-weight: 500;
  }
  .timestamp {
    text-align: right;
    white-space: nowrap;
  }
  .base {
    text-align: right;
  }
  .other {
    text-align: left;
  }
  .category {
    padding-left: 1rem;
    text-align: left;
    .MuiSelect-root {
      padding: 0.25rem 0.5rem !important;
    }
  }
  .price {
    text-align: right;
  }
  .gain {
    text-align: right;
  }
  .more {
    text-align: right;
  }
  .currency {
    font-weight: 600;
  }
  .currency-logo {
    display: inline-block;
  }
  .trade-details {
    display: inline-block;
  }

  &.is-open,
  &:hover,
  &.hovered,
  &.is-striped.hovered,
  &.is-striped:hover {
    ${HoverableWrapper} {
      visibility: visible;
    }
  }

  &:hover .warning-row-hover,
  &.hovered .warning-row-hover {
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(100% + 3.5rem);
    width: 100%;
    z-index: 5;
  }

  &.is-highlighted,
  &.is-highlighted.is-open,
  &.is-highlighted:hover,
  &.is-highlighted.hovered,
  &.is-highlighted:hover .warning-row-hover,
  td {
    z-index: 20;
    position: relative;
  }
`;
