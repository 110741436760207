import { ChevronRight, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { type PropsWithChildren, useState } from "react";
import styled from "styled-components";

import { importAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type ImportMethod } from "~/types/enums";
import { type ImportOptionV2 } from "~/types/index";

export const AdvancedImportOptions = ({
  children,
  importOption,
  importMethod,
}: PropsWithChildren & {
  importOption: ImportOptionV2;
  importMethod: ImportMethod;
}) => {
  const { tokens } = useDesign();
  const captureAnalytics = useCaptureAnalytics();
  const analyticsKey = importAnalyticsKey("advanced options");
  const lang = useLang();
  const [advanced, showAdvanced] = useState(false);

  return (
    <Box display="block">
      <Box display="flex" justifyContent="flex-start" sx={{ my: "0.25rem" }}>
        <TextButton
          endIcon={advanced ? <ExpandLess /> : <ExpandMore />}
          size="small"
          onClick={() => {
            if (!advanced) {
              captureAnalytics(analyticsKey("expanded"), {
                integration: importOption.id,
                importType: importMethod,
              });
            }
            showAdvanced(!advanced);
          }}
          sx={{
            color: `${tokens.text.default} !important`, //need important as TextButton default overrides colour property
          }}
        >
          <Typography
            variant="Metropolis/Body/Regular"
            sx={{
              color: tokens.text.default,
            }}
          >
            {lang.imports.advancedOptions}
          </Typography>
        </TextButton>
      </Box>
      <Box
        display={advanced ? "block" : "none"}
        sx={{
          backgroundColor: tokens.elevation.high,
          p: "1rem",
          border: `1px solid ${tokens.border.neutral.default}`,
          borderRadius: "0.25rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const AdvancedImportOptionsV2 = ({
  children,
  importOption,
  importMethod,
}: PropsWithChildren & {
  importOption: ImportOptionV2;
  importMethod: ImportMethod;
}) => {
  const { tokens } = useDesign();
  const captureAnalytics = useCaptureAnalytics();
  const analyticsKey = importAnalyticsKey("advanced options");
  const lang = useLang();
  const [advanced, showAdvanced] = useState(false);

  return (
    <Box display="block" mt="1rem">
      <Box display="flex" justifyContent="flex-start" sx={{ mt: "0.25rem" }}>
        <StyledButton
          startIcon={advanced ? <ExpandMore /> : <ChevronRight />}
          size="small"
          fullWidth
          disableRipple
          onClick={() => {
            if (!advanced) {
              captureAnalytics(analyticsKey("expanded"), {
                integration: importOption.id,
                importType: importMethod,
              });
            }
            showAdvanced(!advanced);
          }}
          advanced={advanced}
        >
          <Typography
            variant={"Metropolis/Caption/Medium/Regular"}
            sx={{
              color: advanced ? tokens.text.low : tokens.text.disabled,
              marginLeft: "0.75rem",
            }}
          >
            {lang.imports.advancedOptions}
          </Typography>
        </StyledButton>
      </Box>
      <Box
        display={advanced ? "block" : "none"}
        sx={{
          p: "1rem",
          borderRadius: "0.25rem",
          background: tokens.background.neutral.default,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const StyledButton = styled(({ advanced, ...props }) => <Button {...props} />)`
  justify-content: flex-start;
  background: ${({ theme, advanced }) => (advanced ? theme.tokens.background.neutral.default : theme.tokens.background.neutral.low)};
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.medium};
  color: ${({ theme, advanced }) => (advanced ? theme.tokens.text.low : theme.tokens.text.disabled)} !important;
  padding: 0.5rem;
  height: 2.25rem;

  & .MuiButton-startIcon {
    color: ${({ theme, advanced }) => (advanced ? theme.tokens.text.low : theme.tokens.text.disabled)};
  }
  
  &:hover {
    background: ${({ theme }) => theme.tokens.background.neutral.default};
    
    & .MuiButton-startIcon {
      color: ${({ theme }) => theme.tokens.text.high};
    }
    
    & .MuiTypography-root {
      color: ${({ theme }) => theme.tokens.text.high};
    },
  }
`;
