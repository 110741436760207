import { number, plural, select } from "@messageformat/runtime";
import { de } from "@messageformat/runtime/lib/cardinals";
export default {
  accept: () => "Akzeptieren",
  acceptTest: () => "Test akzeptieren",
  accountant: {
    addClient: () => "Neuer Kunde",
    addClientError: () =>
      "Es konnte kein neuer Kunde hinzugefügt werden. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Support.",
    billingOwner: {
      accountant: () => "Buchhalter",
      client: () => "Kunde",
    },
    billingPage: {
      failed: () => "Fehlgeschlagen",
      paid: () => "Bezahlt",
    },
    clientDefault: () => "Mein erster Kunde",
    clientInListNotFound: () =>
      "Der Kunde konnte in der Kundenliste nicht gefunden werden.",
    clientListHeader: {
      billingDate: () => "Rechnungsdatum",
      dateAdded: () => "Datum hinzugefügt",
      email: () => "E-Mail",
      name: () => "Name",
      plan: () => "planen",
      status: () => "Status",
    },
    clientTable: {
      billingTooltip: () =>
        "Der Plan wurde storniert und bleibt bis zum nächsten Abrechnungsdatum aktiv",
      clearFilters: () => "Filter löschen",
      headers: {
        billingDate: () => "Rechnungsdatum",
        dateAdded: () => "Datum hinzugefügt",
        name: () => "Name",
        plan: () => "Planen",
        subscriber: () => "Teilnehmer",
        transaction: () => "Transaktion",
      },
      invitePending: () => "Einladung ausstehend:",
      invited: () => "Eingeladen",
      newClient: () => "Neuer Kunde",
      noClients: () => "Ihre Kunden erscheinen hier",
      noClientsMatchFilter: () => "Keine Clients, die dem Filter entsprechen ",
      view: () => "Sicht",
    },
    clientVersionNotFound: () =>
      "Die Abonnementversion konnte nicht ermittelt werden. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Support.",
    defaultAccount: () => "Standardkonto",
    emptyState: {
      body: () => "Beginnen Sie mit dem Hinzufügen Ihres ersten Kunden.",
      title: () => "Sie haben noch keine Kunden",
    },
    errorLoadingClient: () => "Fehler beim Laden des Clients",
    invite: {
      emailInvalid: () => "Ungültige E-Mail-Adresse",
      emailRequired: () => "E-Mail erforderlich",
      fromName: () => "Ihr Name",
      fromNamePlaceholder: () => "Ihr vollständiger Name",
      invite: () => "Einladen",
      inviteAccountant: () => "Buchhalter einladen",
      inviteWarning: () =>
        "Wenn der Buchhalter die Einladung annimmt, werden seine Daten durch Ihre ersetzt.",
      nameInvalid: () => "Es sind nur alphanumerische Zeichen zulässig.",
      nameRequired: () => "Name erforderlich",
      toEmail: () => "E-Mail des Buchhalters",
      toEmailPlaceholder: () => "Die E-Mail Ihres Buchhalters",
    },
    loadingClients: () => "Laden...",
    maxClientLimit: {
      text: () => "Sie haben das maximale Kundenlimit für Ihr Konto erreicht.",
      title: () => "Client-Limit erreicht",
    },
    mostPopularPlanText: () => "Am beliebtesten",
    moveAccountSettings: {
      body: () =>
        "Deine Accountdaten und Einstellungen findest Du nun in der Navigation unter „Konto“.",
      title: () => "Wir haben die Kontoeinstellungen verschoben",
    },
    newClient: {
      buttonText: (d) =>
        select(d.isSaving, {
          true: " Client hinzufügen ",
          other: " Client hinzufügen ",
        }),
      clientType: {
        new: () => "Beta",
        title: () => "Clienttyp:",
        type: {
          business: () => "Geschäft",
          individual: () => "Person",
        },
      },
      error: {
        invalidName: (d) =>
          "Ungültiger Name " +
          d.name +
          " . Nur alphanumerische Zeichen sind zulässig.",
        missingCountry: () => "Sie müssen ein Land auswählen",
        missingName: () => "Sie müssen einen Namen für Ihren Client eingeben",
      },
      form: {
        email: {
          helperText: () =>
            "Fügen Sie eine E-Mail-Adresse hinzu, um den Kunden einzuladen und ihm den Zugriff zu ermöglichen.",
          label: () => "E-Mail (optional)",
        },
        name: {
          business: {
            helperText: () => "Dieser Name wird in der Kundenliste angezeigt.",
            label: () => "Name der Organisation oder des Kunden",
          },
          individual: {
            helperText: () => "Dieser Name wird in der Kundenliste angezeigt.",
            label: () => "Kundenname",
          },
        },
      },
      planCard: {
        aYear: () => " pro Jahr",
        creditsAvailable: (d) =>
          d.credits +
          " " +
          select(d.singleCredit, { true: " Credit ", other: " Credits " }) +
          " verfügbar",
        free: () => "Frei",
        nextYear: (d) => "Dann " + d.price + " für die Folgejahre.",
      },
      section1: {
        field1: () => "Spitzname des Clients",
        field2: () => "E-Mail (optional)",
        field3: () => "Land",
        title: () => "Kundeninformationen hinzufügen",
      },
      section2: {
        title: () => "Wähle einen Plan aus",
      },
      section3: {
        body1: () => "Die fällige Zahlung ist ",
        body2: () => " abgerechnet am ",
        title: () => "Zahlung",
      },
      success: () => "Neuer Kunde hinzugefügt",
      title: () => "Neuen Kunden hinzufügen",
    },
    noClientFound: () => "Keine Kunden gefunden",
    noClientSelected: () => "Kein Kunde ausgewählt",
    or: () => "Oder",
    savingClient: () => "Client hinzufügen",
    subscriber: {
      accountant: () => "Buchhalter",
      both: () => "Beide",
      bothCTA: () =>
        "Mögliche Doppelzahlung. Bitte wenden Sie sich an den Support.",
      client: () => "Kunde",
      free: () => "Frei",
      header: () => "Teilnehmer",
      nobody: () => "—",
      tooltip: () => "Zahler des Kontos.",
    },
    upgradeClient: {
      cancel: () => "Stornieren",
      clientName: () => "Kundenname: ",
      failMsg: () => "Upgrade des Clients fehlgeschlagen",
      nextStepButtonText: () => "Nächste",
      payAmountAndUpgrade: (d) =>
        "Zahlen Sie " + d.amount + " und führen Sie ein Upgrade durch",
      payAndUpgrade: () => "Bezahlen und upgraden",
      payment: {
        defaultErrorMsg: () =>
          "Bitte wenden Sie sich an den Support, wenn das Problem weiterhin besteht.",
        detail1: () => "Zahlung von ",
        detail2: () => " wird abgerechnet am ",
        noneRequired: () => "Keine Zahlung erforderlich",
        title: () => "Zahlung",
      },
      renewalErrorBanner: {
        body: () =>
          "Sie haben Rechnungen im Rückstand. Sie können Kunden nicht upgraden, solange Rechnungen unbezahlt sind",
        buttonText: () => "Unbezahlte Rechnungen anzeigen",
        title: () => "Frühere Rechnungen sind im Rückstand",
      },
      selectAPlan: () => "Wähle einen Plan aus",
      stepLabels: {
        payment: () => "Zahlung",
        selectPlan: () => "Wähle einen Plan aus",
      },
      title: () => "Upgrade-Client",
      upgrade: () => "Aktualisierung",
    },
  },
  accountantPortal: {
    createAccount: () => "Benutzerkonto erstellen",
    dialogLabel: () => "Onboarding von Buchhaltern",
    errorMessage: () =>
      "Beim Upgraden der Pläne ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    paymentCtaPrefix: () => "Upgrade für ",
    steps: {
      accountantInfo: {
        accountantCompanyDescription: () =>
          "Der Name Ihrer Buchhaltungspraxis.",
        continue: () => "Weitermachen",
        nameDescription: () => "Name, den Ihre Kunden sehen werden.",
        required: () => "*Pflichtfelder",
      },
      payment: {
        cancelAnyTimeText: () => " Sie können jederzeit kündigen.",
        cardDetailsLabel: () => "Kartendetails",
        couponText: () => "Haben Sie einen Gutscheincode?",
        discountAppliedText: () => "Rabatt angewendet",
        discountDescription1: () => "Ihnen wird berechnet ",
        discountDescription2: () => " für das erste Jahr und ",
        discountDescription3: () => " für die Folgejahre.",
        label: () => "Zahlung",
        paymentDescription1: () => "Ihnen wird berechnet ",
        paymentDescription2: () =>
          " jährlich, bis Sie Ihr Abonnement kündigen.",
        planTextSuffix: () => " planen",
        submitButtonSuffix: () => " und Fertig",
        subtotalText: () => "Zwischensumme",
        title: () => "Zahlungsübersicht",
        yearlySubscriptionText: () => "Jahresabonnement",
      },
      welcome: {
        info: {
          switching: {
            subtitle: () =>
              "Wenn Sie zum Accountant Portal wechseln, können Sie die Aktion nicht rückgängig machen. Sie können Ihr Konto über die Einstellungen verwalten und dort auch die E-Mail-Adresse ändern.",
            title: () => "Konten wechseln",
          },
          transactions: {
            subtitle: () =>
              "Ihre Transaktionen und Zahlungspläne werden auf Ihr neues Accountant Portal übertragen und Ihre Daten erscheinen in der Kundenliste als erster Kunde. Sie können Ihre Transaktionen wie bisher weiter verwalten.",
            title: () => "Transaktionen und Datenmigration",
          },
        },
      },
    },
    subtitle: () =>
      "Verwalten und berechnen Sie die Steuern Ihres Kunden an einem Ort.",
    title: () => "Willkommen beim Buchhalter-Portal",
  },
  accountingIntegrations: {
    connectAccountingSoftware: () => "Buchhaltungssoftware verbinden",
    connectIntegration: {
      connected: {
        connected: () => "Verbunden",
        connectedAt: (d) => "Verbunden um " + d.time,
        disconnect: () => "Verbindung entfernen",
        disconnectWarning: {
          actionText: () => "Trennen",
          alert: () =>
            "Kontozuordnungen werden entfernt. Sie müssen sie bei der erneuten Verbindung neu zuordnen.",
          body: (d) =>
            "Dadurch wird die Verbindung Ihres Kontos zu " +
            d.erp +
            " getrennt und Sie können Ihre Transaktionen nicht mehr mit " +
            d.erp +
            " synchronisieren.",
          title: (d) => "Trennen Sie " + d.erp,
        },
        selectTenant: () => "Unternehmen auswählen",
      },
      disconnected: {
        title: () => "Connect-Integration",
      },
    },
    defaults: {
      accountMapping: {
        asset: {
          title: () => "Vermögenskonto",
          tooltip: () =>
            "Konto, das den Wert der Vermögenswerte im Portfolio des Benutzers darstellt",
        },
        cash: {
          title: () => "Kassenkonto",
          tooltip: () =>
            "Konto, das Bargeldbestände darstellt. Wenn Sie Krypto verkaufen, stellen wir den Barerlös hier dar und umgekehrt, wenn Sie Krypto kaufen. Krypto-zu-Krypto-Transaktionen haben keine Auswirkungen auf dieses Konto.",
        },
        expense: {
          title: () => "Aufwandskonto",
          tooltip: () => "Konto, das Kryptoausgaben darstellt",
        },
        fee: {
          title: () => "Gebührenkonto",
          tooltip: () =>
            "Konto, auf dem der gezahlte Gebührenbetrag angegeben ist",
        },
        income: {
          title: () => "Ertragskonto",
          tooltip: () => "Konto, das Einkünfte aus Kryptowährungen darstellt",
        },
        loan: {
          title: () => "Darlehenskonto",
          tooltip: () => "Konto für Kryptokredite und -rückzahlungen",
        },
        loss: {
          title: () => "Verlustrechnung",
          tooltip: () => "Konto, das den verlorenen Kryptobetrag darstellt",
        },
        "realised-gain": {
          title: () => "Realisiertes Gewinnkonto",
          tooltip: () =>
            "Konto, das Gewinne aus der Veräußerung von Krypto-Assets darstellt",
        },
        "realised-loss": {
          title: () => "Realisiertes Verlustkonto",
          tooltip: () =>
            "Konto, das Verluste darstellt, die durch die Veräußerung von Krypto-Assets entstanden sind",
        },
        "zero-cost-buy": {
          title: () => "Fehlendes Kaufhistorie-Konto",
          tooltip: () =>
            "Wenn ein Vermögenswert gekauft oder erworben wird und keine Zahlung dafür geleistet wird, betrachten wir die Kostenbasis des Vermögenswerts als Marktwert und dieses Konto wird verwendet, um die andere Seite des Journals auszugleichen.",
        },
      },
      empty: () =>
        "Beginnen Sie mit der Anbindung Ihrer Buchhaltungsintegration",
      mapped: () => "Kartiert",
      required: () => "Erforderlich",
      selectorPlaceholder: (d) => "Wählen Sie " + d.account + " in " + d.erp,
      title: () => "Standardkonten",
      unmapped: () => "Nicht zugeordnet",
    },
    integrations: () => "Standardzuordnung",
    rollupSettings: {
      options: {
        day: () => "Tag",
        month: () => "Monat",
        none: () => "Kein Rollup",
      },
      placeholder: () => "Rollup-Zeitraum auswählen",
      subtitle: () =>
        "Alle Transaktionen im ausgewählten Zeitraum werden in einem einzigen Journaleintrag zusammengefasst.",
      title: () => "Rollup-Einstellungen",
    },
    rules: () => "Benutzerdefinierte Regeln",
    subtitle: (d) =>
      "Ordnen Sie Kategorien in CTC Ihren Zeitschriften in " + d.erp + " zu.",
    subtitleWithoutERP: () =>
      "Ordnen Sie Kategorien in CTC Ihrer Buchhaltungssoftware zu.",
    title: () => "Buchhaltungsintegrationen",
    warningSettings: {
      label: () => "Synchronisierung von Transaktionen mit Warnungen zulassen",
      subtitle: (d) =>
        "Wenn diese Option deaktiviert ist, müssen alle Warnungen für eine bestimmte Transaktion behoben werden, bevor sie mit " +
        d.erp +
        " synchronisiert werden kann.",
      title: () => "Synchronisierung von Transaktionen mit Warnungen zulassen",
    },
  },
  accountingSteps: {
    body: () => "Alles, was Sie zur Verwaltung Ihrer Kunden benötigen",
    steps: {
      0: {
        body: () =>
          "Über unser Buchhalterportal können Sie Kunden über ein einziges benutzerfreundliches Dashboard einladen und verwalten.",
        title: () => "Verwalten Sie alle Ihre Kunden an einem Ort",
      },
      1: {
        body: () =>
          "Wir unterstützen direkt Hunderte von Börsen, Blockchains und Diensten. Fügen Sie einfach API-Schlüssel und Wallet-Adressen hinzu.",
        title: () =>
          "Einfache Beschaffung von Kundendaten durch Direktaustausch und Blockchain-Integrationen",
      },
    },
    title: () => "Buchhaltungstools und Demo",
  },
  add: () => "Hinzufügen",
  addManualTX: {
    currencyPaidIn: () => "Währung, in der Sie die Gebühr bezahlt haben",
    currencyPlaceholder: () => "zB BTC, ETH, XRP",
    error: () => "Beim Hinzufügen der Transaktion ist ein Fehler aufgetreten.",
    exchangeName: () => "Austauschname",
    feePaidIn: () => "Gezahlter Gebührenbetrag in der Gebührenwährung",
    in: () => "In",
    mustDestination: () => "Sie müssen ein Ziel eingeben",
    mustEnterExchange: () => "Sie müssen einen Börsennamen eingeben",
    mustSource: () => "Sie müssen eine Quelle eingeben",
    optional: () => "Optional",
    paidInFees: () => "an Gebühren bezahlt",
    priceForOne: () => "Preis für 1",
    quotePlaceholder: () => "zB TUSD, BTC, ETH, XRP",
    saving: () => "Speichern",
    success: () => "Manuelle Transaktion erstellt.",
    totalQuantityOf: () => "Gesamtmenge an",
    totalValueIn: () => "Gesamtwert in",
    viewTransaction: () => "Sicht",
    whereDestination: () => "Wo ist das Ziel Ihrer Transaktion",
    whereFrom: () => "Von wo überweisen Sie?",
    whereSource: () => "Wo ist die Quelle Ihrer Transaktion",
    whereTo: () => "Wohin wechseln Sie?",
    whichCategory: () => "Welche Kategorie soll ich wählen?",
  },
  adjustedPrice: {
    considersWashSalesDescription: () =>
      " und unter Berücksichtigung der Wash-Sale-Regeln",
    description: () =>
      "Dies ist der angepasste Preis nach Anwendung des durchschnittlichen Poolpreises",
  },
  advanced: () => "Fortschrittlich",
  advancedTransactionOptions: {
    findCategory: () => "Kategorie suchen",
    grouped: {
      description: () =>
        "Es ist wirklich wichtig, dass Sie sicherstellen, dass Sie alle Ihre Daten aus allen möglichen Quellen importiert haben.",
      title: () => "Gruppierte Transaktionen",
    },
    in: {
      categoryGuide: () => "Leitfaden zu Transaktionskategorien",
      description: () =>
        "Es ist wirklich wichtig, dass Sie sicherstellen, dass Sie alle Ihre Daten aus allen möglichen Quellen importiert haben.",
      incorrectAction: () => "Ausgehende Kategorien anzeigen",
      incorrectPrefix: () => "Keine eingehende Transaktion?",
      inputGroup: () => "Eingehende Kategorien",
      title: () => "Eingehende Transaktionen",
    },
    out: {
      categoryGuide: () => "Leitfaden zu Transaktionskategorien",
      description: () =>
        "Es ist wirklich wichtig, dass Sie sicherstellen, dass Sie alle Ihre Daten aus allen möglichen Quellen importiert haben.",
      incorrectAction: () => "Eingehende Kategorien anzeigen",
      incorrectPrefix: () => "Keine ausgehende Transaktion?",
      inputGroup: () => "Ausgehende Kategorien",
      title: () => "Ausgehende Transaktionen",
    },
  },
  alerts: {
    customCSV: () =>
      "Stellen Sie sicher, dass Ihre Daten richtig vorbereitet sind und im CSV-Dateiformat vorliegen",
    noCookie: () =>
      "Bitte aktivieren Sie Cookies, sonst können Sie sich nicht anmelden! Wenn Sie den Brave-Browser verwenden, müssen Sie auf den Löwen neben der Navigationsleiste klicken und die Schilder für diese Site deaktivieren.",
  },
  all: () => "Alle",
  allPlans: () => "Alle Pläne haben eine 30-tägige Geld-zurück-Garantie",
  api: {
    addressesIdentified: (d) => "Adressen identifiziert als " + d.exchangeName,
    apiKeyLabel: () => "API-Schlüssel",
    connect: () => "Verbinden",
    delete: () => "API löschen",
    deleteConfirmationText: (d) =>
      "Möchten Sie den " +
      d.exchangeName +
      ' -API-Schlüssel " ' +
      d.api +
      ' " wirklich löschen? Dadurch werden auch ALLE von diesem API-Schlüssel importierten Transaktionen gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.',
    disabledExplanation: () =>
      "Sie können nicht über OAuth synchronisieren, wenn Sie bereits über einen API-Schlüssel synchronisiert haben",
    edit: () => "API-Details bearbeiten",
    editNickname: () => "API-Namen bearbeiten",
    editingSubmit: () => "API aktualisieren",
    exchanges: {
      binance: {
        crossMarginCurrencies: () => "Cross-Margin-Währungen",
        syncMarginTickers: () => "Kreuzmarge synchronisieren",
        syncMarginTickersExplanation: () =>
          "Bitte wählen Sie die von Ihnen gehandelten Cross-Margin-Währungen aus. Dies ist erforderlich, um die Leistung zu verbessern, da die API von Binance beim Abrufen Ihrer Daten Einschränkungen auferlegt.",
      },
    },
    existingSubmit: (d) => "Zusätzliche " + d.source + " -API hinzufügen",
    hardSync: {
      confirmationAllAPIs: (d) => d.name + " -APIs hart synchronisieren?",
      confirmationOneAPI: () => "Hard-Sync-API?",
      textOneAPI: (d) =>
        "Bei der harten Synchronisierung werden alle Transaktionen für die API " +
        d.name +
        " ab " +
        d.date +
        " importiert. Dies kann manchmal zu doppelten Transaktionen führen, die manuell verwaltet werden müssen, falls sich die Integration seit Ihrer letzten Synchronisierung geändert hat.",
      titleAllChipAPIs: () =>
        "Synchronisieren Sie alle Daten erneut. Dadurch werden alle gelöschten Transaktionen für diese Integration zurückgegeben.",
      titleOneAPI: (d) =>
        "Alle Transaktionen vom " +
        d.date +
        " für " +
        d.name +
        " -API erneut synchronisieren",
    },
    import: () => "Import",
    importRules: {
      existingApi: () => "API-Schlüssel existiert bereits.",
      missingApi: () => "Sie müssen einen API-Schlüssel eingeben",
      missingPassphrase: () => "Sie müssen eine Passphrase eingeben",
      missingSecret: () => "Sie müssen das Geheimnis eingeben",
      missingUid: () => "Sie müssen eine UID eingeben",
      multipleKeyError: (d) =>
        "Für " +
        d.name +
        " können nicht mehrere API-Schlüssel hinzugefügt werden",
      uidHint: () =>
        "Hinweis: Dies kann manchmal der Name oder das „Memo“ sein, das Sie der API gegeben haben",
    },
    maxTickerLabel: () =>
      "Sie haben die maximale Anzahl von Märkten erreicht, die die API zulässt. Wenn Sie weitere Märkte hinzufügen möchten, importieren Sie diese bitte per CSV.",
    missingTickerAlert: (d) =>
      d.exchangeName +
      " erfordert die Angabe aller Ticker, wenn Handelshistorien über die API angefordert werden. Bitte fügen Sie ALLE Ticker hinzu, die Sie gehandelt haben, um genaue Steuerberichte zurückzugeben. Alternativ können Sie mit der CSV-Option importieren.",
    name: () => "API-Name (optional)",
    nickname: () => "Spitzname",
    passwordLabel: () => "Passphrase",
    reauthorize: () => "Erneut autorisieren",
    resync: () => "Neu synchronisieren",
    secretLabel: () => "Geheimnis",
    softSync: (d) => "Synchronisieren Sie " + d.name + " API-Transaktionen von",
    submit: (d) => d.source + " -API hinzufügen",
    sync: () => "Synchronisieren",
    syncing: () => "Synchronisieren",
    tickerLabel: () => "Alle verwendeten Ticker auswählen",
    uidLabel: () => "Benutzerkennung",
    update: () => "API-Details aktualisieren",
  },
  apply: () => "Anwenden",
  auth: {
    acceptTermsToContinue: () =>
      "Sie müssen die Servicebedingungen und die Datenschutzrichtlinie akzeptieren, um fortzufahren",
    accountantClient: () => "Kunden mit Krypto?",
    accountantCompanyName: () => "Name der Firma",
    accountantContactName: () => "Kontaktname",
    accountantSignUp: () => "als Buchhalter anmelden",
    accountantSignupSubTitle: () =>
      "Die Anmeldung dauert weniger als eine Minute – keine Kreditkarte erforderlich",
    accountantSignupTitle: () => "Buchhalter anmelden",
    accountantWorkEmail: () => "Geschäftliche E-Mail",
    alreadyAccount: () => "Haben Sie bereits einen Account?",
    and: () => "Und",
    areAccountant: () => "Sind Sie Buchhalter?",
    backTo: () => "Zurück zu",
    bitcoinDotComAu30Banner: () =>
      "30% Rabatt auf kostenpflichtige Pläne angewendet",
    breaker: () => "ODER",
    captcha: {
      and: () => "Und",
      apply: () => "anwenden.",
      privacyPolicy: () => "Datenschutzrichtlinie",
      protectedBy: () => "Diese Website ist durch reCAPTCHA und die Google ",
      termsOfService: () => "Servicebedingungen",
      verificationFailed: () =>
        "Die Überprüfung ist fehlgeschlagen. Versuchen Sie es bitte erneut.",
    },
    coinbase: () => "Coinbase",
    coinbase30Banner: () =>
      "10.000 kostenlose Coinbase-Transaktionen und 30 % Rabatt auf kostenpflichtige Pläne",
    coinbaseOne1DollarBanner: (d) =>
      d.rookiePlanPrice +
      " Rookie-Plan. 40 % Rabatt auf alle zusätzlichen Pläne",
    coinbaseOne1DollarBannerNoCurrency: () =>
      "Exklusives Angebot für Coinbase-Mitglieder angewendet",
    coinbaseOne40Banner: () =>
      "40 % Rabatt auf kostenpflichtige Pläne bei der Anmeldung, exklusiv für Coinbase One-Mitglieder",
    coinbaseOnePremium40Banner: () =>
      "50 % Rabatt auf die Premiumstufe von CTC, Trader",
    coinjar30Banner: () =>
      "1.000 kostenlose CoinJar-Transaktionen und 30 % Rabatt auf kostenpflichtige Pläne bei der Anmeldung",
    coinstatsBanner: () =>
      "30 $ Rabatt auf kostenpflichtige Pläne bei der Anmeldung",
    compliance: () =>
      "Mit dem Krypto-Steuerrechner wird die Einhaltung von Vorschriften zum Kinderspiel",
    countryWarningBody: () =>
      "Sie können keine Einladung von einem Buchhalter aus einem anderen Land annehmen. Wenden Sie sich bitte an den Support.",
    countryWarningTitle: () =>
      "Ihr Land ist nicht dasselbe wie das Ihres Buchhalters",
    customerNum: () => "200.000+ Kunden",
    differentSignUpMethod: () =>
      "Ihre E-Mail-Adresse ist bereits für eine andere Anmeldemethode gültig. Bitte versuchen Sie es erneut mit Ihrer ursprünglichen Methode.",
    email: () => "E-Mail",
    embedded: {
      connectWith: (d) => "Mit " + d.provider + " verbinden",
      signInWith: (d) => "Mit " + d.provider + " anmelden",
      tagline: () => "Transparente, genaue Steuerberichte",
      title: () => "Erste Schritte mit Tax Hub",
    },
    errors: {
      invalidCode: () => "Ungültiger 2FA-Code. Bitte versuchen Sie es erneut.",
      invalidCredentials: () =>
        "Ungültige E-Mail oder ungültiges Passwort. Bitte versuchen Sie es erneut.",
      invalidSignup: () =>
        "Ungültige Anmeldung. Bitte versuchen Sie es erneut.",
    },
    forgot: () => "Passwort vergessen?",
    forgotPassword: () => "Passwort zurücksetzen",
    goBack: () => "Geh zurück",
    google: () => "Google",
    iAgree: () => "Mit der Erstellung eines Kontos stimmen Sie den",
    independentReserve30Banner: () =>
      "30% Rabatt auf kostenpflichtige Pläne angewendet",
    invalidCombination: () => "Ungültige E-Mail/Passwort-Kombination",
    invalidToken: () => "Ungültiges Token oder ungültige E-Mail",
    inviteReceived: () => "Einladung erhalten von:",
    login: () => "Einloggen",
    loginPage: {
      oauth: {
        coinbase: () => "Mit Coinbase anmelden",
        google: () => "Mit Google anmelden",
        metamask: () => "Mit MetaMask anmelden",
      },
      title: () => "Willkommen zurück",
    },
    loginTitle: () => "Willkommen zurück!",
    loginTitleEmbedded: () => "Melden Sie sich beim Tax Hub an",
    metamask: () => "MetaMask",
    metamask30Banner: () =>
      "30 % Rabatt auf kostenpflichtige Pläne bei der Anmeldung",
    missingEmail: () =>
      "Um diese Funktion zu nutzen, geben Sie bitte auf der Einstellungsseite eine E-Mail-Adresse ein.",
    noAccount: () => "Sie haben noch kein Konto?",
    notAccountant: () => "Kein Buchhalter?",
    officialPartner: () => "OFFIZIELLER PARTNER VON",
    officialPartners: () => "Offizielle Partner",
    orLogin: () => "Oder melden Sie sich mit Ihrer E-Mail-Adresse an",
    orSignup: () => "Oder melde Dich mit E-Mail an",
    orSwitchTo: () => "oder",
    password: () => "Passwort",
    passwordCondition: () =>
      "Ihr Passwort muss mindestens 8 Zeichen lang sein. Es muss außerdem eine Zahl (0-9) oder ein Sonderzeichen (!@#$%^&*) enthalten.",
    passwordResetButton: () => "Passwort zurücksetzen",
    passwordResetFailed: () => "Kennwortzurücksetzung fehlgeschlagen",
    passwordResetInstructions: () =>
      "Geben Sie unten Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts:",
    passwordResetSuccessfully: () => "Passwort erfolgreich zurückgesetzt",
    passwordUpdatedSuccessfully: () => "Passwort erfolgreich aktualisiert",
    privacyPolicy: () => "Datenschutzrichtlinie",
    recaptcha: {
      part1: () => "Diese Seite ist durch reCAPTCHA und die Google",
      part2: () => "und",
      part3: () => "geschützt.",
    },
    recogniseBody: () => "Ihre Daten werden an diese weitergegeben",
    recogniseTitle: () =>
      "Stellen Sie sicher, dass Sie die E-Mail-Adresse Ihres Buchhalters kennen!",
    resetEmailSent: (d) =>
      "Wenn die angegebene E-Mail mit einem Konto verknüpft ist, wurde eine E-Mail zum Zurücksetzen an " +
      d.email +
      " gesendet.",
    resetPasswordSubTitle: () =>
      "Geben Sie ein neues Passwort für Ihr Konto ein",
    resetPasswordTitle: () => "Setzen Sie Ihr Passwort zurück",
    setPasswordTitle: () => "Legen Sie Ihr Passwort fest",
    signInWith: () => "Einloggen mit:",
    signin: () => "Einloggen",
    signup: () => "Melden Sie sich an",
    signupPage: {
      accurateCryptoTaxes: () => "Präzise Krypto-Steuern.",
      mobileTitle: () => "Kostenlos starten!",
      noGuesswork: () => "Keine Mutmaßungen.",
      oauth: {
        coinbase: () => "Mit Coinbase registrieren",
        google: () => "Mit Google registrieren",
        metamask: () => "Mit MetaMask registrieren",
      },
      terms: {
        and: () => "und",
        body: () => "Ich stimme den",
        privacyPolicy: () => "Datenschutzbestimmungen",
        termsNotAccepted: () =>
          "Bitte akzeptieren Sie die Nutzungsbedingungen und Datenschutzbestimmungen, um fortzufahren",
        termsOfService: () => "Nutzungsbedingungen",
      },
      text: () => "Registrieren",
      title: () => "Registrieren und kostenlos starten",
      trustpilot: {
        reviews: () => "500+ Bewertungen",
        title: () => "Basierend auf",
      },
    },
    signupPromoText: {
      phantom: () =>
        "Erhalten Sie 200 kostenlose Phantom Wallet-Transaktionen und 20% Rabatt auf Ihren CTC-Tarif",
    },
    signupSubTitle: () => "Die Anmeldung dauert weniger als eine Minute",
    signupTitle: () => "Jetzt kostenloses Konto erstellen",
    somethingWentWrong: () =>
      "Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.",
    strongPassword: () => "Super. Ihr Passwort erfüllt die Anforderungen.",
    termsAndConditions: () => "Servicebedingungen",
    toSignUp: () => "um sich beim Crypto Tax Calculator anzumelden!",
    tooManyPasswordResetAttempts: () =>
      "Zu viele Versuche zum Zurücksetzen des Passworts. Bitte versuchen Sie es später erneut.",
    transparentReports: () => "Transparente, genaue Steuerberichte",
    trustPilotExcellent: () => "Exzellent",
    trustedBy: () => "Vertrauen von",
    unableToResetEmail: (d) =>
      "Kennwort für E-Mail kann nicht zurückgesetzt werden: " + d.email,
    unlock: () => "Konto entsperrt",
    userSignUp: () => "als Benutzer anmelden",
    worldwide: () => "weltweit.",
    xero: () => "Xero",
    yourAccountant: () => "Ihr Buchhalter",
    youreInvited: () => "Sie sind eingeladen von",
  },
  autoClose: {
    title: () =>
      "Diese Registerkarte sollte automatisch geschlossen werden. Sie können sie aber gerne jetzt schließen.",
  },
  back: () => "Zurück",
  billing: {
    44359: () =>
      "44359: Beim Erstellen der Zahlungsmethode ist ein Fehler aufgetreten.",
    45732: () => "45732: Beim Suchen der Karte ist ein Fehler aufgetreten",
    addCard: {
      cancel: () => "Stornieren",
      description: () => "Diese neue Karte wird Ihre Standardzahlungsmethode",
      loading: () => "Karte hinzufügen",
      submit: () => "Karte hinzufügen",
      title: () => "Karte hinzufügen",
    },
    billingHistory: {
      table: {
        amount: () => "Menge",
        date: () => "Datum",
        description: () => "Beschreibung",
      },
      title: () => "Abrechnungsverlauf",
    },
    paymentFailed: () =>
      "Zahlung fehlgeschlagen. Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support, wenn das Problem weiterhin besteht.",
    paymentFailedCode: (d) =>
      "Zahlung fehlgeschlagen. Bei unserem Zahlungsprozessor ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich mit dem Fehlercode " +
      d.code +
      " an den Support.",
    paymentMethod: {
      button: () => "Karte hinzufügen",
      ending: () => "Ende",
      expired: () => "Abgelaufen",
      expires: () => "Läuft ab",
      inUse: () => "Im Einsatz",
      title: () => "Aktive Karte",
    },
    paymentStatus: {
      failed: {
        description: () =>
          "Bitte aktualisieren Sie Ihre Karteninformationen, um weiterhin alle Funktionen der App nutzen zu können.",
        title: () => "Zahlung fehlgeschlagen",
      },
    },
    somethingWentWrong: () => "Da ist etwas schiefgelaufen",
    stripe: {
      44359: () =>
        "44359: Beim Erstellen der Zahlungsmethode ist ein Fehler aufgetreten.",
      45732: () =>
        "45732: Beim Suchen des Kartenelements ist ein Fehler aufgetreten",
      applied: () => "Angewandt",
      apply: () => "Anwenden",
      cardSuccessfullyAdded: () => "Karte erfolgreich hinzugefügt.",
      clear: () => "Löschen",
      couponExpired: (d) => "Gutschein " + d.name + " ist abgelaufen",
      couponNotValid: (d) => "Der Gutschein " + d.name + " ist ungültig",
      discountApplied: (d) =>
        "Im ersten Jahr wird ein Rabatt von " + d.percent + " gewährt!",
      failedToAddTheCard: () => "Karte konnte nicht hinzugefügt werden",
      noPortal: () =>
        "Der Zugriff auf das Stripe-Portal ist fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.",
      noSession: () =>
        "Es konnte keine Stripe-Sitzung erstellt werden. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.",
      noStripe: () =>
        "Beim Zugriff auf Stripe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.",
    },
    title: () => "Abrechnung",
    wentWrongBillingHistory: () =>
      "Beim Abrufen des Rechnungsverlaufs ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    wentWrongCoupon: () =>
      "Beim Abrufen der Coupondetails ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    wentWrongPaymentMethods: () =>
      "Beim Abrufen der Zahlungsmethoden ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  },
  bulkSelect: {
    moreActions: () => "Weitere Aktionen",
    recategoriseAs: () => "Umkategorisieren als",
  },
  button: {
    accept: () => "Akzeptieren",
    decline: () => "Abfall",
    pending: () => "Ausstehend",
  },
  calcoTour: {
    buttons: {
      back: () => "Zurück",
      next: () => "Nächste",
    },
    reconciliation: {
      uncategorise: {
        CTA: () => "Beginnen Sie mit der Kategorisierung",
        addAnotherAccount: () =>
          "Wenn Sie feststellen, dass Sie vergessen haben, ein Konto zu importieren, können Sie hier ein weiteres hinzufügen",
        associateTx: (d) =>
          "Um Ihnen bei der Identifizierung und Kategorisierung dieser Transaktion zu helfen, sehen Sie sich alle Ihre anderen Transaktionen mit " +
          d.counterParty +
          " an.",
        categoriesButton: () =>
          "Wenn Sie wissen, um welche Transaktion es sich handelt, kategorisieren Sie sie und wir wenden die Kategorie automatisch auf andere, ähnliche Transaktionen an.",
        exampleTx: () =>
          "Hier ist ein Beispiel für eine Transaktion, die Sie kategorisieren sollten.",
        incomingWallet: () =>
          "Dies ist die Brieftasche, die den Vermögenswert erhalten hat.",
        outgoingWallet: () =>
          "Dies ist die Brieftasche, die den Vermögenswert gesendet hat.",
        typeSelectorIncoming: () =>
          "Dieser wird als „eingehend“ gekennzeichnet, da Sie einen Vermögenswert erhalten haben, zur Ermittlung des Steuerergebnisses ist jedoch eine spezifischere Kategorie erforderlich.",
        typeSelectorOutgoing: () =>
          "Dieser wird als „ausgehend“ gekennzeichnet, da Sie einen Vermögenswert gesendet haben, zur Ermittlung des Steuerergebnisses ist jedoch eine spezifischere Kategorie erforderlich.",
      },
    },
  },
  cancel: () => "Beenden",
  changeLang: {
    changeLang: () => "Sprache ändern",
    error: () => "Fehler beim Speichern der Spracheinstellung",
    language: () => "Sprache",
    languages: () => "Sprachen",
    pleaseSelect: () => "Bitte wählen Sie Ihre Sprache",
  },
  clear: () => "Klar",
  clientPays: {
    bannerText: () =>
      "Damit Ihr Buchhalter Ihre Steuerberichte erstellen kann, müssen Sie Ihr Abonnement aktualisieren.",
    bannerTitle: () => "Upgrade zum Generieren von Steuerberichten",
    buttonText: () => "Abonnement upgraden",
  },
  clients: {
    cancelSub: {
      areYouSure: () =>
        "Sobald Ihr aktueller Abrechnungszeitraum endet, wird dieser Client gekündigt. Alle Daten und Links zum Client bleiben erhalten. Möchten Sie das Abonnement wirklich kündigen?",
      buttonText: () => "Abonnement kündigen",
      exit: () => "Ausfahrt",
    },
    clone: {
      clone: () => "Klon",
      cloneClient: () => "Client klonen",
      clonesAccountantData: () =>
        "Dadurch werden die Daten geklont, die Sie für diesen Kunden hochgeladen haben (also nicht die Daten Ihres Kunden, wenn Sie dessen Konto anzeigen). Dies hat keine Auswirkungen auf das bestehende Konto. Das geklonte Konto ist ein kostenloses Konto, kann aber aktualisiert werden.",
    },
    delete: {
      accessOutcome: () =>
        "Die Daten sind dann für Sie und Ihre Mitarbeiter nicht mehr zugänglich.",
      areYouSure: (d) =>
        select(d.name, {
          unknown: " Möchten Sie diesen Client wirklich löschen? ",
          other:
            ' Möchten Sie den Client " ' + d.name + ' " wirklich löschen? ',
        }),
      cannotBeUndone: () =>
        "Diese Aktion kann nicht rückgängig gemacht werden.",
      clientAccessOutcome: (d) =>
        select(d.accountantOwnedData, {
          true: " Ihr Kunde verliert ebenfalls den Zugriff auf seine Daten. ",
          other: " Ihr Kunde hat weiterhin Zugriff auf seine Daten. ",
        }),
      deleteButton: () => "Löschen",
      deleteClient: () => "Client löschen",
    },
    invite: {
      emailRequired: () => "E-Mail erforderlich",
      fromName: () => "Ihr Name",
      fromNamePlaceholder: () => "Ihr vollständiger Name",
      invite: () => "Einladen",
      inviteClient: () => "Kunden einladen",
      inviteWarning: () =>
        "Wenn der Kunde die Einladung annimmt, sieht er standardmäßig die Daten, die Sie in seinem Namen hochgeladen haben. Wenn der Kunde die ursprünglichen Daten sehen möchte, die er hochgeladen hat, hat er diese Option in seinen Einstellungen.",
      nameRequired: () => "Name erforderlich",
      toEmail: () => "E-Mail des Kunden",
      toEmailPlaceholder: () => "Die E-Mail Ihres Kunden",
    },
    migrate: {
      settingsBody: () =>
        "Ab dem 8. September 2022 wurden alle Demokonten stillgelegt. Alle zuvor importierten Transaktionen wurden auf einen neuen Client migriert, der jederzeit gelöscht werden kann.",
      settingsTitle: () => "Keine Demokonten mehr",
    },
    newAccountInvite: {
      acceptFailed: () =>
        "Die Einladung konnte nicht angenommen werden. Bitte versuchen Sie es erneut.",
      declineFailed: () =>
        "Die Einladung konnte nicht abgelehnt werden. Bitte versuchen Sie es erneut.",
      isNotActionable: () =>
        "Diese Einladung ist nicht mehr gültig. Wenden Sie sich für Hilfe bitte an den Support.",
      textAccountantOwns: (d) =>
        "Ihr Buchhalter ( " +
        d.email +
        " ) hat Sie zur Zusammenarbeit eingeladen. Wenn Sie diese Einladung annehmen, können Sie die Daten anzeigen und bearbeiten, die Ihr Buchhalter in Ihrem Namen hochgeladen hat. Sie können den Zugriff jederzeit widerrufen, die Daten verbleiben jedoch bei Ihrem Buchhalter. Möchten Sie diese Einladung wirklich annehmen?",
      textClientOwns: (d) =>
        "Ihr Buchhalter ( " +
        d.email +
        " ) hat Sie zur Zusammenarbeit eingeladen. Wenn Sie diese Einladung annehmen, kann Ihr Buchhalter Ihre Daten anzeigen und bearbeiten. Sie können den Zugriff jederzeit widerrufen. Möchten Sie diese Einladung wirklich annehmen?",
      title: () => "Sie wurden von Ihrem Buchhalter eingeladen",
    },
    reinvite: {
      emailRequired: () => "E-Mail erforderlich",
      fromName: () => "Ihr Name",
      fromNamePlaceholder: () => "Ihr vollständiger Name",
      nameRequired: () => "Name erforderlich",
      reinvite: () => "Erneut einladen",
      reinviteClient: () => "Kunden erneut einladen",
      reinviteWarning: () =>
        "Sobald der Kunde die Einladung angenommen hat, kann er das freigegebene Konto einsehen.",
      toEmail: () => "E-Mail des Kunden",
      toEmailPlaceholder: () => "Die E-Mail Ihres Kunden",
    },
    settings: () => "Client-Einstellungen",
    uncancelSub: {
      areYouSure: () =>
        "Dieser Kunde wird nicht mehr gekündigt und Ihnen wird eine Rechnung gestellt, wenn der neue Abrechnungszeitraum beginnt. Möchten Sie das Abonnement wirklich kündigen?",
      buttonText: () => "Abonnement kündigen",
      exit: () => "Ausfahrt",
    },
  },
  close: () => "Schließen",
  collaborators: {
    cancelInvitation: () => "Einladung abbrechen",
    cancelInvitationGenericError: () =>
      "Beim Löschen der Einladung ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    cancelInvitationSuccess: () => "Einladung erfolgreich storniert.",
    email: () => "E-Mail",
    invitationSent: () => "Einladung verschickt!",
    inviteTeamMember: () => "Teammitglied einladen",
    inviteTeamMembers: {
      linkText: () => "Mitglieder einladen",
      postLinkText: () => "zur Zusammenarbeit und zum Teilen von Kunden",
    },
    name: () => "Name",
    pending: () => "Ausstehend",
    reinvite: () => "Teammitglied erneut einladen",
    reinviteSuccess: () => "Teammitglied erfolgreich erneut eingeladen.",
    revokeAccess: () => "Zugriff widerrufen",
    revokeAccessSuccess: () => "Zugriff erfolgreich widerrufen.",
    send: () => "Schicken",
    team: () => "Team",
    teamMemberEmail: () => "E-Mail-Adresse des Teammitglieds",
    teamMemberName: () => "Vollständiger Name des Teammitglieds",
    teamMembers: () => "Teammitglieder",
    teamMembersEmpty: () => "Keine Teammitglieder eingeladen",
  },
  confirm: () => "Bestätigen",
  contacts: {
    addAddress: {
      addAddress: () => "Adresse hinzufügen",
      address: () => "Adresse",
      blockchain: () => "Blockchain",
      buttons: {
        add: () => "Hinzufügen",
        cancel: () => "Stornieren",
      },
      duplicateAddress: () => "Doppelte Adresse",
      title: () => "Neue Adresse hinzufügen",
    },
    addContact: () => "Kontakt hinzufügen",
    addressBook: () => "Kontakte",
    contact: () => "Name des Ansprechpartners",
    create: {
      addAddress: () => "Adresse hinzufügen",
      address: () => "Adresse",
      alreadyExists: () => "Kontakt existiert bereits",
      associatedAddresses: () => "Zugehörige Adressen",
      blockchain: () => "Blockchain",
      cancel: () => "Stornieren",
      createContact: () => "Kontakt erstellen",
      duplicateAddress: () => "Doppelte Adresse",
      name: () => "Name des Ansprechpartners",
      nameTitle: () => "Name",
      title: () => "Neuer Kontakt",
    },
    csv: {
      errorFileNotCsv: () => "Muss eine CSV-Datei sein",
      exportCsv: () => "Nicht identifizierte Adressen als CSV exportieren",
      importCsv: () => "CSV importieren",
      success: (d) => d.number + " Kontakte aktualisiert",
    },
    edit: {
      associatedAddresses: () => "Zugehörige Adressen",
      rules: () => "Regeln",
    },
    entityType: {
      contract: () => "Smart Contract",
      imported: () => "Importierte Brieftasche",
      manual: () => "Manueller Kontakt",
    },
    error: () => "Fehler",
    findAddress: () => "Adresse finden",
    identified: () => "Identifiziert",
    identifiedTable: {
      allTooltip: () =>
        "Einige Adressen in diesem Kontakt wurden in allen Netzwerken identifiziert",
      asterisk: () => "*",
      networks: () => "Netzwerke",
      rules: () => "Regeln",
      rulesApplied: (d) =>
        plural(d.count, 0, de, {
          one: " 1 Regel ",
          other: number("de", d.count, 0) + " Regeln ",
        }) + " angewendet",
      setRule: () => "Regel festlegen",
      transactions: () => "Transaktionen",
    },
    newContact: () => "Neuen Kontakt hinzufügen",
    noContacts: () =>
      "Klicken Sie auf „Kontakt hinzufügen“, um Ihren ersten Kontakt hinzuzufügen.",
    noResults: () => "Keine Suchergebnisse",
    noUnidentified: () =>
      "Alle Adressen identifiziert. Hier gibt es nichts zu sehen.",
    required: () => "Erforderlich",
    ruleCard: {
      active: () => "Aktiv",
      addCondition: () => "Bedingung hinzufügen",
      appliesTo: (d) =>
        "Diese Regel gilt für " +
        plural(d.count, 0, de, {
          one: " 1 Transaktion ",
          other: number("de", d.count, 0) + " Transaktionen ",
        }),
      cancel: () => "Stornieren",
      createMoreRules: () => "Erstellen einer weiteren Regel",
      createRule: () => "Regel erstellen",
      deleteRule: () => "Regel löschen",
      disableRule: () => "Regel deaktivieren",
      disabled: () => "Deaktiviert",
      disabledTooltip: () =>
        "Die Regel wird nicht auf neue Transaktionen angewendet. Bestehende Transaktionen sind weiterhin mit dieser Regel verknüpft und ihre Auswirkungen werden nicht durch andere Regeln überschrieben.",
      editRule: () => "Bearbeiten",
      enableRule: () => "Regel aktivieren",
      enabledTooltip: () =>
        "Diese Regel ist aktiv und gilt für neue und bestehende Transaktionen",
      if: () => "WENN",
      newRule: (d) => "Regel " + d.number,
      save: () => "Speichern",
      setAccount: () => "Vorgang hinzufügen",
      then: () => "DANN",
      tooltip: () =>
        "Bitte verbinden Sie Ihre Buchhaltungssoftware, um Regeln zu erstellen",
      view: () => "Sicht",
      willApplyTo: (d) =>
        "Diese Regel gilt für " +
        plural(d.count, 0, de, {
          one: " 1 Transaktion ",
          other: number("de", d.count, 0) + " Transaktionen ",
        }),
    },
    rules: {
      importsDrawerTitle: () => "Regeln verwalten",
      operators: {
        changeErpAssetAccount: () => "Vermögenskonto",
        changeErpCashAccount: () => "Kassenkonto",
        changeErpGainsAccount: () => "Gewinne-Konto",
        changeErpLoanAccount: () => "Darlehenskonto",
        changeErpPnLAccount: () => "Gewinn- und Verlustrechnung",
        recategorise: () => "Kategorie",
      },
      ruleNotFound: () => "Regel nicht gefunden",
      rulesImportsButton: () => "Regeln",
    },
    table: {
      address: () => "Adresse",
      addresses: () => "Adressen",
      name: () => "Name",
      transactions: () => "Transaktionen",
      type: () => "Typ",
    },
    unidentified: () => "Unbekannt",
    unidentifiedTable: {
      assets: () => "Gesendete/empfangene Vermögenswerte",
      chains: () => "Ketten",
      firstDate: () => "Erstes Sendedatum",
      lastDate: () => "Letztes Sendedatum",
      modal: {
        assets: () => "Vermögenswerte:",
        buttonCTA: () => "Adresse ermitteln",
        importCTA: () => "Importieren",
        importLabel: () => "Ist das Ihre Brieftasche?",
        transferAmount: () => "Überweisungsbetrag:",
        txs: () => "Transaktionen:",
      },
      transferAmount: () => "Überweisungsbetrag",
      txs: () => "Transaktionen",
    },
  },
  context: () => "Im Kontext anzeigen",
  copied: () => "Kopiert",
  copy: () => "Kopie",
  copyAddress: () => "Adresse kopieren",
  copyTxLink: () => "Link kopieren",
  copyTxLinkSuccess: () => "Transaktionslink in die Zwischenablage kopiert",
  countries: {
    AD: () => "Andorra",
    AE: () => "Vereinigte Arabische Emirate",
    AF: () => "Afghanistan",
    AG: () => "Antigua und Barbuda",
    AI: () => "Anguilla",
    AL: () => "Albanien",
    AM: () => "Armenien",
    AO: () => "Angola",
    AQ: () => "Antarktis",
    AR: () => "Argentinien",
    AS: () => "Amerikanisch-Samoa",
    AT: () => "Österreich",
    AU: () => "Australien",
    AW: () => "Aruba",
    AZ: () => "Aserbaidschan",
    BA: () => "Bosnien und Herzegowina",
    BB: () => "Barbados",
    BD: () => "Bangladesch",
    BE: () => "Belgien",
    BF: () => "Burkina Faso",
    BG: () => "Bulgarien",
    BH: () => "Bahrain",
    BI: () => "Burundi",
    BJ: () => "Benin",
    BL: () => "Saint-Barthélemy",
    BM: () => "Bermuda",
    BN: () => "Brunei Darussalam",
    BO: () => "Bolivien",
    BQ: () => "Bonaire",
    BR: () => "Brasilien",
    BS: () => "Bahamas",
    BT: () => "Bhutan",
    BV: () => "Bouvetinsel",
    BW: () => "Botswana",
    BY: () => "Weißrussland",
    BZ: () => "Belize",
    CA: () => "Kanada",
    CC: () => "Kokosinseln",
    CD: () => "Demokratische Republik Kongo",
    CF: () => "Zentralafrikanische Republik",
    CG: () => "Kongo",
    CH: () => "Schweiz",
    CI: () => "Elfenbeinküste",
    CK: () => "Cookinseln",
    CL: () => "Chile",
    CM: () => "Kamerun",
    CN: () => "China",
    CO: () => "Kolumbien",
    CR: () => "Costa Rica",
    CU: () => "Kuba",
    CV: () => "Kap Verde",
    CW: () => "Curacao",
    CX: () => "Weihnachtsinsel",
    CY: () => "Zypern",
    CZ: () => "Tschechische Republik",
    DE: () => "Deutschland",
    DJ: () => "Dschibuti",
    DK: () => "Dänemark",
    DM: () => "Dominica",
    DO: () => "Dominikanische Republik",
    DZ: () => "Algerien",
    EC: () => "Ecuador",
    EE: () => "Estland",
    EG: () => "Ägypten",
    EH: () => "Westsahara",
    ER: () => "Eritrea",
    ES: () => "Spanien",
    ET: () => "Äthiopien",
    FI: () => "Finnland",
    FJ: () => "Fidschi",
    FK: () => "Falklandinseln",
    FM: () => "Mikronesien",
    FO: () => "Färöer Inseln",
    FR: () => "Frankreich",
    GA: () => "Gabun",
    GD: () => "Grenada",
    GE: () => "Georgia",
    GF: () => "Französisch-Guayana",
    GG: () => "Guernsey",
    GH: () => "Ghana",
    GI: () => "Gibraltar",
    GL: () => "Grönland",
    GN: () => "Guinea",
    GP: () => "Guadeloupe",
    GQ: () => "Äquatorialguinea",
    GR: () => "Griechenland",
    GS: () => "Südgeorgien und die Südlichen Sandwichinseln",
    GT: () => "Guatemala",
    GU: () => "Guam",
    GW: () => "Guinea-Bissau",
    GY: () => "Guyana",
    HK: () => "Hongkong",
    HM: () => "Heard- und McDonaldinseln",
    HN: () => "Honduras",
    HR: () => "Kroatien",
    HT: () => "Haiti",
    HU: () => "Ungarn",
    ID: () => "Indonesien",
    IE: () => "Irland",
    IL: () => "Israel",
    IM: () => "Isle of Man",
    IN: () => "Indien",
    IQ: () => "Irak",
    IR: () => "Iran",
    IS: () => "Island",
    IT: () => "Italien",
    JE: () => "Jersey",
    JM: () => "Jamaika",
    JO: () => "Jordanien",
    JP: () => "Japan",
    KE: () => "Kenia",
    KG: () => "Kirgisistan",
    KI: () => "Kiribati",
    KN: () => "St. Kitts und Nevis",
    KP: () => "Nordkorea",
    KR: () => "Südkorea",
    KW: () => "Kuwait",
    KY: () => "Cayman-Inseln",
    KZ: () => "Kasachstan",
    LA: () => "Demokratische Volksrepublik Laos",
    LB: () => "Libanon",
    LC: () => "St. Lucia",
    LI: () => "Liechtenstein",
    LK: () => "Sri Lanka",
    LR: () => "Liberia",
    LS: () => "Lesotho",
    LT: () => "Litauen",
    LU: () => "Luxemburg",
    LV: () => "Lettland",
    LY: () => "Libyen",
    MA: () => "Marokko",
    MC: () => "Monaco",
    MD: () => "Moldawien",
    ME: () => "Montenegro",
    MF: () => "Saint Martin",
    MG: () => "Madagaskar",
    MH: () => "Marshallinseln",
    MK: () => "Nordmazedonien",
    ML: () => "Mali",
    MM: () => "Myanmar",
    MN: () => "Mongolei",
    MO: () => "Macau",
    MP: () => "Nördliche Marianen",
    MQ: () => "Martinique",
    MR: () => "Mauretanien",
    MS: () => "Montserrat",
    MT: () => "Malta",
    MU: () => "Mauritius",
    MV: () => "Malediven",
    MW: () => "Malawi",
    MX: () => "Mexiko",
    MY: () => "Malaysia",
    MZ: () => "Mosambik",
    NA: () => "Namibia",
    NC: () => "Neukaledonien",
    NE: () => "Niger",
    NF: () => "Norfolkinsel",
    NG: () => "Nigeria",
    NI: () => "Nicaragua",
    NL: () => "Niederlande",
    NO: () => "Norwegen",
    NP: () => "Nepal",
    NR: () => "Nauru",
    NU: () => "Niue",
    NZ: () => "Neuseeland",
    OM: () => "Oman",
    PA: () => "Panama",
    PE: () => "Peru",
    PG: () => "Papua-Neuguinea",
    PH: () => "Philippinen",
    PK: () => "Pakistan",
    PL: () => "Polen",
    PM: () => "Saint-Pierre und Miquelon",
    PN: () => "Pitcairn",
    PR: () => "Puerto Rico",
    PS: () => "Palästina",
    PT: () => "Portugal",
    PW: () => "Palau",
    PY: () => "Paraguay",
    QA: () => "Katar",
    RE: () => "Wiedervereinigung",
    RO: () => "Rumänien",
    RS: () => "Serbien",
    RU: () => "Russische Föderation",
    RW: () => "Ruanda",
    SA: () => "Saudi-Arabien",
    SB: () => "Salomon-Inseln",
    SC: () => "Seychellen",
    SD: () => "Sudan",
    SE: () => "Schweden",
    SG: () => "Singapur",
    SH: () => "St. Helena",
    SI: () => "Slowenien",
    SJ: () => "Spitzbergen und Jan Mayen",
    SK: () => "Slowakei",
    SL: () => "Sierra Leone",
    SM: () => "San Marino",
    SN: () => "Senegal",
    SO: () => "Somalia",
    SR: () => "Suriname",
    SS: () => "Südsudan",
    ST: () => "São Tomé und Príncipe",
    SV: () => "El Salvador",
    SX: () => "Sint Maarten",
    SY: () => "Arabische Republik Syrien",
    TC: () => "Turks- und Caicosinseln",
    TG: () => "Togo",
    TH: () => "Thailand",
    TJ: () => "Tadschikistan",
    TK: () => "Tokelau",
    TL: () => "Osttimor",
    TM: () => "Turkmenistan",
    TN: () => "Tunesien",
    TO: () => "Tonga",
    TR: () => "Truthahn",
    TT: () => "Trinidad und Tobago",
    TV: () => "Tuvalu",
    TW: () => "Taiwan",
    TZ: () => "Tansania",
    UA: () => "Ukraine",
    UG: () => "Uganda",
    UK: () => "Vereinigtes Königreich",
    US: () => "Vereinigte Staaten",
    UY: () => "Uruguay",
    UZ: () => "Usbekistan",
    VA: () => "Vatikanstadt",
    VC: () => "St. Vincent und die Grenadinen",
    VE: () => "Venezuela",
    VG: () => "Britische Jungferninseln",
    VI: () => "Jungferninseln (USA)",
    VN: () => "Vietnam",
    VU: () => "Vanuatu",
    WF: () => "Wallis und Futuna",
    WS: () => "Samoa",
    YE: () => "Jemen",
    YT: () => "Mayotte",
    ZA: () => "Südafrika",
    ZM: () => "Sambia",
    ZW: () => "Simbabwe",
  },
  countryAbbreviations: {
    AD: () => "UND",
    AE: () => "Vereinigte Arabische Emirate",
    AF: () => "AFG",
    AG: () => "AGB",
    AI: () => "AGL",
    AL: () => "ALB",
    AM: () => "ARM",
    AO: () => "ANG",
    AQ: () => "AMEISE",
    AR: () => "ARG",
    AS: () => "AMS",
    AT: () => "AUT",
    AU: () => "AUS",
    AW: () => "ARU",
    AZ: () => "AZE",
    BA: () => "BOS",
    BB: () => "BAR",
    BD: () => "VERBOT",
    BE: () => "BEL",
    BF: () => "BUR",
    BG: () => "BUL",
    BH: () => "BHR",
    BI: () => "BDI",
    BJ: () => "BEN",
    BL: () => "STB",
    BM: () => "BER",
    BN: () => "BRU",
    BO: () => "BOL",
    BQ: () => "BON",
    BR: () => "BH",
    BS: () => "BAH",
    BT: () => "BHU",
    BV: () => "BVT",
    BW: () => "BOT",
    BY: () => "BLR",
    BZ: () => "BLZ",
    CA: () => "DÜRFEN",
    CC: () => "CCK",
    CD: () => "KABELJAU",
    CF: () => "CAF",
    CG: () => "ZAHN",
    CH: () => "Schweiz",
    CI: () => "KINDERBETT",
    CK: () => "CKI",
    CL: () => "CHI",
    CM: () => "NOCKEN",
    CN: () => "CHN",
    CO: () => "COL",
    CR: () => "CRI",
    CU: () => "CUB",
    CV: () => "CAB",
    CW: () => "CUR",
    CX: () => "CHR",
    CY: () => "CYP",
    CZ: () => "CZE",
    DE: () => "GER",
    DJ: () => "DJI",
    DK: () => "DEN",
    DM: () => "DMA",
    DO: () => "DOM",
    DZ: () => "ALG",
    EC: () => "ECU",
    EE: () => "EST",
    EG: () => "EGY",
    EH: () => "ESH",
    ER: () => "ERI",
    ES: () => "ESP",
    ET: () => "ETH",
    FI: () => "FLOSSE",
    FJ: () => "Fidschi",
    FK: () => "FLK",
    FM: () => "FSM",
    FO: () => "FÜR",
    FR: () => "FRA",
    GA: () => "GAB",
    GD: () => "GRD",
    GE: () => "GEO",
    GF: () => "BRD",
    GG: () => "GGY",
    GH: () => "GHA",
    GI: () => "GIB",
    GL: () => "GRL",
    GN: () => "GIN",
    GP: () => "GLP",
    GQ: () => "EQU",
    GR: () => "GRE",
    GS: () => "SGS",
    GT: () => "GTM",
    GU: () => "GUMMI",
    GW: () => "GNB",
    GY: () => "KERL",
    HK: () => "Hongkong",
    HM: () => "HMD",
    HN: () => "HON",
    HR: () => "CRO",
    HT: () => "HTI",
    HU: () => "HUNNE",
    ID: () => "IDN",
    IE: () => "IR",
    IL: () => "ISR",
    IM: () => "IMN",
    IN: () => "Indien",
    IQ: () => "IRQ",
    IR: () => "IRA",
    IS: () => "EIS",
    IT: () => "Italien",
    JE: () => "JEY",
    JM: () => "MARMELADE",
    JO: () => "JOR",
    JP: () => "JAP",
    KE: () => "KEN",
    KG: () => "KGZ",
    KI: () => "KIR",
    KN: () => "STK",
    KP: () => "PRK",
    KR: () => "KOR",
    KW: () => "KUW",
    KY: () => "CAY",
    KZ: () => "KAZ",
    LA: () => "LAO",
    LB: () => "LEB",
    LC: () => "STL",
    LI: () => "LÜGE",
    LK: () => "SRI",
    LR: () => "LIB",
    LS: () => "LES",
    LT: () => "LTU",
    LU: () => "LUX",
    LV: () => "LAT",
    LY: () => "LBY",
    MA: () => "MOC",
    MC: () => "MO",
    MD: () => "MDA",
    ME: () => "MNE",
    MF: () => "STM",
    MG: () => "VERRÜCKT",
    MH: () => "MHL",
    MK: () => "NMK",
    ML: () => "MLI",
    MM: () => "MMR",
    MN: () => "MGL",
    MO: () => "MAC",
    MP: () => "NMI",
    MQ: () => "MTQ",
    MR: () => "MRT",
    MS: () => "MSR",
    MT: () => "MLT",
    MU: () => "MUS",
    MV: () => "MDV",
    MW: () => "RACHEN",
    MX: () => "MEX",
    MY: () => "MAS",
    MZ: () => "MOZ",
    NA: () => "NAM",
    NC: () => "NCL",
    NE: () => "NER",
    NF: () => "NFK",
    NG: () => "NIG",
    NI: () => "Netzwerkkarte",
    NL: () => "NED",
    NO: () => "NOCH",
    NP: () => "Nationale Fußballliga",
    NR: () => "NRU",
    NU: () => "NIU",
    NZ: () => "NZL",
    OM: () => "OMA",
    PA: () => "PFANNE",
    PE: () => "PRO",
    PG: () => "PNG",
    PH: () => "PHI",
    PK: () => "PAK",
    PL: () => "POL",
    PM: () => "STP",
    PN: () => "PCN",
    PR: () => "PRI",
    PS: () => "PSE",
    PT: () => "POR",
    PW: () => "PLW",
    PY: () => "PAR",
    QA: () => "QAT",
    RE: () => "REU",
    RO: () => "ROM",
    RS: () => "SER",
    RU: () => "Russland",
    RW: () => "RWA",
    SA: () => "Selbstfahrlafetten",
    SB: () => "SOL",
    SC: () => "SEY",
    SD: () => "SDN",
    SE: () => "SWE",
    SG: () => "SÜNDE",
    SH: () => "etw.",
    SI: () => "SVN",
    SJ: () => "SJM",
    SK: () => "SVK",
    SL: () => "SLE",
    SM: () => "SMR",
    SN: () => "SEN",
    SO: () => "SOM",
    SR: () => "SUR",
    SS: () => "SSD",
    ST: () => "SAO",
    SV: () => "ELS",
    SX: () => "SMX",
    SY: () => "SYR",
    TC: () => "TCA",
    TG: () => "TOG",
    TH: () => "THA",
    TJ: () => "TJK",
    TK: () => "TKL",
    TL: () => "TLS",
    TM: () => "TKM",
    TN: () => "TUN",
    TO: () => "TONNE",
    TR: () => "TUR",
    TT: () => "TTO",
    TV: () => "TÜV",
    TW: () => "TWN",
    TZ: () => "BRÄUNEN",
    UA: () => "UKR",
    UG: () => "UGA",
    UK: () => "Vereinigtes Königreich",
    US: () => "UNS",
    UY: () => "URU",
    UZ: () => "UZB",
    VA: () => "Umsatzsteuer",
    VC: () => "STV",
    VE: () => "VEN",
    VG: () => "VGB",
    VI: () => "VIR",
    VN: () => "WETTEIFERN",
    VU: () => "VUT",
    WF: () => "WLF",
    WS: () => "SAM",
    YE: () => "YEM",
    YT: () => "MYT",
    ZA: () => "RSA",
    ZM: () => "ZAM",
    ZW: () => "ZWE",
  },
  coupon: {
    label: () => "Promo-Code eingeben",
    placeholder: () => "Aktionscode",
  },
  couponIntegration: {
    CB140: () => "Coinbase",
    COINBASE30: () => "Coinbase",
    COINJARUK: () => "CoinJar",
    COINSTATS: () => "CoinStats",
    CTCFRIEND20: () => "Freund empfehlen",
    METAMASK10: () => "Metamask",
    MMCTC30: () => "MetaMask",
    bitcoincomau30: () => "bitcoin.com.au",
    cb1rookie: () => "Coinbase",
    coinbase140: () => "Coinbase",
    coinbase140p: () => "Coinbase",
    coinbase1p: () => "Coinbase",
    ir30: () => "Independent Reserve",
    metamask30: () => "MetaMask",
  },
  createTransactionBefore: () => "Transaktion erstellen vor",
  ctcAutomations: () => "✨ CTC-Magie ✨",
  currency: {
    errorLoadingAllCurrencies: () => "Fehler beim Laden aller Währungen",
    errorLoadingUserCurrencies: () => "Fehler beim Laden der Benutzerwährungen",
    unableToFindPrice: (d) =>
      "Marktpreis für " + d.name + " konnte nicht gefunden werden",
    wentWrongFetchingPrice: (d) =>
      "Beim Abrufen des Preises für " + d.name + " ist ein Fehler aufgetreten.",
  },
  currentPlan: () => "Aktueller Plan",
  darkModeBanner: {
    actionButton: () => "Probieren Sie den Dunkelmodus aus",
    cancelButton: () => "Nein danke",
    description: () =>
      "Wechseln Sie in Ihrem Profilmenü zwischen dem Dunkel- und dem Hellmodus.",
    title: () => "Der Dunkelmodus ist da!",
  },
  dashboard: {
    allAssets: () => "Alle Vermögenswerte",
    balance: () => "Gleichgewicht",
    carousel: {
      moneyBack: {
        description: () => "Risikofrei testen",
        subtitle: () =>
          "Wir bieten eine 30-tägige Geld-zurück-Garantie für alle Tarife.",
        title: () => "Geld-zurück-Garantie",
      },
      partnerOffer: {
        description: () => "30% Rabatt",
        subtitle: () => "Wird automatisch beim Checkout angewendet",
        title: () => "Spezielles Partnerangebot",
      },
    },
    chartCalculationDialog: {
      body1: () =>
        "Um ein genaues Ergebnis zu erhalten, importieren Sie alle Ihre Transaktionen ",
      note: () =>
        "Hinweis: Wenn der Preis einer Währung während der Zeit, in der sie gehalten wurde, nicht bestimmt werden kann, wird sie ausgeschlossen.",
      title: () => "Erläuterung der Diagrammberechnungen",
    },
    costBasis: () => "Kostenbasis",
    costBasisTooltip: () =>
      "Kombinierte Kostenbasis (einschließlich Gebühren) von Vermögenswerten, die einen aktuellen Marktwert haben",
    currentBalance: () => "Aktueller Kontostand",
    embedded: {
      accuracyChecks: () => "Genauigkeitsprüfungen",
      accuracyComplete: () =>
        "Ihre Genauigkeitsaufgaben sind alle abgeschlossen.",
      accuracyTasks: () => "Genauigkeitsaufgaben",
      accuracyTasksAction: () => "Überprüfung starten",
      accuracyTasksDescription: () =>
        "Es müssen Genauigkeitsaufgaben erledigt werden, die dazu beitragen, dass Ihre Gewinne richtig und konform sind (Plan erforderlich).",
      countdownBanner: {
        title: () => "Verbleibende Zeit zur Einreichung Ihrer Steuern beim IRS",
        tooltip: () =>
          "Abgabefrist für die Steuererklärung ist Dienstag, 15. April 2025",
      },
      estimatedGains: () => "Geschätzte Kapitalgewinne",
      estimatedGainsTooltip: () =>
        "Der Kapitalgewinn ist eine Schätzung basierend auf den bereitgestellten Informationen.",
      fy: () => "GJ",
      getTaxReports: () => "Erhalten Sie Steuerberichte",
      missingAccounts: () => "Fehlende Konten",
      missingAccountsAction: () => "Konten hinzufügen",
      missingAccountsDescription: () =>
        "Einige Konten sind nicht verknüpft, was zu fehlenden Transaktionen führt. Dies kann dazu führen, dass Ihre Gewinne überschätzt werden.",
      remindMeLater: {
        title: () =>
          "Erhalten Sie eine Steuersaison-Erinnerung und sichern Sie sich zusätzlich 5% Rabatt",
        description: () =>
          "Möchten Sie Ihre Steuern lieber später erledigen? Wir verstehen das. Melden Sie sich an und wir gewähren Ihnen zusätzlich 5% Rabatt beim Kauf eines Plans.",
        buttonText: () => "Erinnere mich",
        modals: {
          reminder: {
            chipText: () => "E-Mail-Erinnerung einstellen",
            title: () => "Erhalten Sie zusätzlich 5% Rabatt auf alle Tarife!",
            description: () =>
              "Geben Sie Ihre E-Mail-Adresse an und erhalten Sie insgesamt 35% Rabatt auf Ihren Steuerbericht, nur weil Sie MetaMask-Nutzer sind.",
            emailLabel: () => "Geben Sie Ihre E-Mail-Adresse ein",
            emailError: () => "Bitte geben Sie eine gültige E-Mail-Adresse ein",
            buttonText: () => "Erinnere mich",
            privacyNotice: () =>
              "Durch Fortfahren stimmen Sie zu, gelegentlich E-Mail-Marketing zu erhalten.",
          },
          success: {
            chipText: () => "Erinnerung gesetzt!",
            title: () => "Zusätzliche 5% Rabatt angewendet.",
            description: () =>
              "Sie erhalten 35% Rabatt auf Ihren Steuerbericht, weil Sie MetaMask-Nutzer sind.",
            buttonText: () => "Holen Sie sich jetzt Ihren Steuerbericht",
          },
        },
      },
      savingsOpportunity: () => "Ihre Sparmöglichkeit:",
      savingsOpportunityTooltip: () =>
        "Die Einsparmöglichkeit ist der geschätzte Betrag, um den Sie Ihre Kapitalgewinne reduzieren könnten, wenn Sie alle Ihre Konten verbinden und Compliance-Aufgaben überprüfen",
    },
    error: () =>
      "Beim Abrufen Ihrer Dashboard-Bestände ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    feesPaid: () => "Bezahlte Gebühren",
    feesPaidTooltip: () =>
      "Der gesamte Fiatwert der von Ihnen gezahlten Gebühren.",
    fiatInvested: () => "Fiat investierte",
    fiatProceeds: () => "Fiat-Erlöse",
    fiatProceedsTooltip: () =>
      "Der Betrag an Fiatgeld, den Sie durch den Verkauf Ihrer Kryptowährung erhalten haben",
    heading: () => "Armaturenbrett",
    holdings: {
      balanceSyncTimeExplainer: () =>
        "Die Zeitsalden wurden zuletzt für diesen Import synchronisiert",
      balances: {
        subheading: () =>
          "Wechseln Sie zwischen der Anzeige der vom Crypto Tax Calculator berechneten Salden und dem tatsächlichen Saldo an der Börse. Eventuelle Unterschiede können auf nicht abgeglichene oder fehlende Transaktionen zurückzuführen sein.",
        title: () => "Verbleibende Guthaben",
      },
      balancesSubheading: () =>
        "Dies ist ein Erklärungstext zur Berechnung von Guthaben und zum Wechseln zwischen dem App- und dem Börsenguthaben.",
      balancesTitle: () => "Verbleibende Guthaben",
      breakdownListNegative: () =>
        "Die aktuellen Bestände sind negativ. Dies kann an nicht kategorisierten oder fehlenden Daten liegen.",
      breakdownListTitle: () => "Kryptowährungsbestände",
      breakdownListTooltip: {
        marketPrice: (d) => "Marktpreis von " + d.symbol + " in " + d.currency,
        positiveBalance: (d) =>
          "Der Kontostand entspricht den Beständen auf " + d.exchangeName,
        review: {
          access: () =>
            "um auf unser Überprüfungstool zuzugreifen und mehr zu erfahren.",
          subscribe: () => "Abonnieren",
          tips: () => "Rezension",
          toLearnMore: () => "Ihre Transaktionen, um mehr zu erfahren.",
        },
        warningBalanceLessThan: (d) =>
          "Wir haben " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " ( " +
          d.fiatDifference +
          " ) weniger berechnet als von Ihrer Börse gemeldet. Dies kann durch nicht kategorisierte oder fehlende Daten verursacht werden.",
        warningBalanceMoreThan: (d) =>
          "Wir haben " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " ( " +
          d.fiatDifference +
          " ) mehr berechnet als von Ihrer Börse gemeldet wurde. Dies kann durch nicht kategorisierte oder fehlende Daten verursacht werden.",
        warningBalanceNoMarketPriceLessThan: (d) =>
          "Wir haben " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " weniger berechnet als von Ihrer Börse gemeldet. Dies kann durch nicht kategorisierte oder fehlende Daten verursacht werden.",
        warningBalanceNoMarketPriceMoreThan: (d) =>
          "Wir haben " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " mehr berechnet als von Ihrer Börse gemeldet wurde. Dies kann durch nicht kategorisierte oder fehlende Daten verursacht werden.",
      },
      cost: () => "Kosten",
      costTooltip: () => "Gesamtkostenbasis",
      costTooltipTitle: () => "Gesamtkostenbasis",
      currency: () => "Währung",
      empty: {
        title: () => "Keine Daten hinzugefügt",
      },
      marketPrice: () => "Preis",
      marketPriceTooltip: () => "Aktueller Marktpreis",
      marketValue: () => "Wert",
      marketValueTooltip: () => "Aktueller Marktwert",
      moreThan: () => "Mehr als",
      of: () => "von",
      quantity: () => "Menge",
      roi: () => "ROI",
      roiTooltip: () => "Kapitalrendite",
      rowsPerPage: () => "Zeilen pro Seite",
      showAppBalances: () => "In der App Guthaben anzeigen",
      syncStatus: {
        fail: () => "Synchronisierung fehlgeschlagen",
        noSync: () => "Keine letzte Synchronisierung",
        success: () => "Synchronisiert",
      },
      title: () => "Vermögenswerte",
      tooltip: {
        off: () => "Anzeige der aus der Quell-API importierten Salden",
        on: () => "Anzeige der aus der Anwendung berechneten Salden",
        sort: () => "Klicken Sie, um nach dem Gesamtmarktwert zu sortieren",
      },
      unrealizedGain: () => "Nicht realisierter Gewinn",
      unrealizedGainTooltip: () => "Nicht realisierter Gewinn",
    },
    holdingsTypes: {
      calculatedBody: () =>
        "Zeigt Ihre von CTC berechneten Salden basierend auf Ihren importierten Wallets und Börsen. Die Genauigkeit hängt von der korrekten Abstimmung der Transaktionen ab.",
      calculatedSwitch: () => "Berechnet",
      calculatedTitle: () => "Historische Bilanz",
      done: () => "Erledigt",
      reportedBody: () =>
        "Zeigt eine Live-Ansicht Ihrer von Ihren Börsen und Wallets gemeldeten Guthaben. Guthaben, die sich nicht in Ihren Wallets befinden, werden nicht angezeigt. Bitte beachten Sie, dass CSV-Importe sowie einige Börsen und Wallets keine Live-Berichterstattung von Guthaben unterstützen. In diesen Fällen werden die von CTC berechneten Bestände verwendet.",
      reportedSwitch: () => "Berichtet",
      reportedTitle: () => "Live-Guthaben",
      title: () => "Saldentyp",
    },
    howThisChartIsCalculated: () => "Wie dieses Diagramm berechnet wird",
    inflow: () => "Zufluss",
    inflowOutflowChart: {
      header: () => "Zufluss/Abfluss",
      legend: {
        fees: () => "Gebühren",
        inflow: () => "Zufluss",
        net: () => "Nettofluss",
        outflow: () => "Abfluss",
      },
      linear: () => "Wechseln Sie zu linear",
      log: () => "Wechseln Sie zu logarithmisch",
      timeframeSelector: {
        month: () => "Monat",
        placeholder: () => "Zeitraum auswählen",
        quarterToDate: () => "Bisheriges Quartal",
        year: () => "Jahr",
        ytd: () => "Der bisherige Jahresverlauf",
      },
      tooltip: {
        fees: () => "Gebühren:",
        inflow: () => "Zufluss:",
        net: () => "Nettofluss:",
        outflow: () => "Abfluss:",
      },
    },
    inflowTooltip: () =>
      "Der Wert der Vermögenswerte, die auf Ihren verbundenen Konten eingehen. Gewinne/Verluste aus Handelsgeschäften sind ausgeschlossen.",
    losers: () => "Verlierer",
    missingImportsWarning: {
      actionButton: () => "Einstellungen verwalten",
      description: () =>
        "Ohne alle Datenquellen können wir Ihren Gesamtsaldo nicht genau wiedergeben. Sie können in den Einstellungen unter „Saldoeinstellungen“ festlegen, welche Daten angezeigt werden sollen.",
      importsTitle: () => "Importierte Assets",
      nextStepButton: () => "Daten importieren",
      overallSubtitle: () =>
        "Hierbei handelt es sich um Vermögenswerte, die Sie in Wallets oder Börsen gefunden haben und die Sie noch nicht importiert haben.",
      overallTitle: () => "Erkannte Assets",
      title: () => "Verwalten importierter Daten",
    },
    negativeBalanceWarning: (d) =>
      "Der Saldo Ihrer " +
      d.currencySymbol +
      " -Bestände ist negativ. Bitte überprüfen Sie Ihre Transaktionen, um diesen Fehler zu beheben.",
    netFiatInvested: () => "Netto-Fiat-Investitionen",
    netFiatInvestedTooltip: () =>
      "Der Betrag an Fiatgeld, den Sie zum Kauf von Kryptowährungen verwendet haben",
    nfts: {
      filter: {
        allChains: () => "Alle Chains",
        chainPlaceholder: () => "Nach Chain suchen",
      },
      imageNotFound: () => "Bild nicht gefunden",
      noNFTs: () => "Keine NFTs gefunden",
      noNFTsCTA: (d) =>
        "Ihre NFTs werden hier angezeigt. Um zu beginnen, [importieren Sie Daten.](" +
        d.linkTextKey +
        ")",
    },
    noAssetAllocation: {
      body: () =>
        "Ihre wichtigsten Vermögenswerte werden hier angezeigt. So legen Sie los",
      importData: () => "Daten importieren",
      title: () => "Keine Daten hinzugefügt",
    },
    noHoldings: {
      body: () =>
        "Alle Ihre importierten Bestände werden hier angezeigt. So legen Sie los",
      importData: () => "Daten importieren",
      title: () => "Keine Daten hinzugefügt",
    },
    noWinnersLosers: {
      body: () =>
        "Ihre größten Gewinne und Verluste werden hier angezeigt. Um zu beginnen",
      importData: () => "Daten importieren",
      title: () => "Keine Daten hinzugefügt",
    },
    noWinnersLosersToDisplay: (d) =>
      "Keine " + d.direction + " zum Anzeigen...",
    notApplicable: () => "N / A",
    other: () => "Andere",
    outflow: () => "Abfluss",
    outflowTooltip: () =>
      "Der Wert der von Ihren verbundenen Konten gesendeten Vermögenswerte. Gewinne/Verluste aus Trades sind ausgeschlossen",
    pageHeading: () => "Portfolio",
    percentageTooltipTitle: () =>
      "Relative Veränderung der Gesamtbilanz gegenüber der Kostenbasis",
    portfolioChart: {
      error: () =>
        "Fehler beim Laden der Portfolio-Performance. Bitte versuchen Sie es später erneut.",
      loading: () =>
        "Historische Daten werden generiert, dies kann einige Zeit dauern ...",
      noData: {
        body: () =>
          "Die Performance Ihres Portfolios wird hier angezeigt. So legen Sie los",
        importData: () => "Daten importieren",
        title: () => "Keine Daten hinzugefügt",
      },
      totalValue: () => "Gesamtwert",
    },
    portfolioPerformance: () => "Portfolio-Performance",
    portfolioPerformanceTooltip: () =>
      "Die Performance Ihres Portfolios im letzten Jahr. Alle negativen Bestände oder Nullsalden werden ignoriert.",
    portfolioTimeframe: {
      all: () => "Alle",
      day: () => "1D",
      month: () => "1 Mio.",
      week: () => "1W",
      year: () => "1 Jahr",
      ytd: () => "Jahresverlauf",
    },
    portfolioTooltip: {
      accuracyNote: () =>
        "Die Leistung basiert auf Ihren berechneten Salden im Crypto Tax Calculator. Die Genauigkeit hängt von der korrekten Abstimmung der Transaktionen ab.",
      all: () => "Die Performance Ihres Portfolios ab Ihrem ersten Kauf.",
      day: () => "Die Performance Ihres Portfolios im Laufe des letzten Tages.",
      ignoreNegativeHoldings: () =>
        "Eventuelle negative Bestände oder Nullsalden werden ignoriert.",
      month: () => "Die Performance Ihres Portfolios im letzten Monat.",
      week: () => "Die Performance Ihres Portfolios in der letzten Woche.",
      year: () => "Die Performance Ihres Portfolios im vergangenen Jahr.",
    },
    somethingWentWrong: () =>
      "Etwas ist schiefgelaufen. Versuchen Sie bitte, die Seite neu zu laden.",
    step1: {
      content: () =>
        "Importieren Sie Ihren gesamten Transaktionsverlauf, indem Sie Ihre Börse auswählen und den zugehörigen Anweisungen folgen. Sie können per CSV, API oder durch direkte Verbindung mit Ihrem Wallet importieren.",
      detailedGuide: () => "Detaillierte Anleitung",
      link: () => "/imports",
      title: () => "1. Daten importieren",
    },
    step2: {
      content: () =>
        "Stellen Sie sicher, dass Sie Ihre Transaktionen von allen Ihren Börsen importiert und sie auf der Transaktionsseite richtig kategorisiert haben.",
      link: () => "/Transaktionen",
      title: () => "2. Transaktionen überprüfen",
    },
    step3: {
      content: () =>
        "Gehen Sie zur Berichtsseite, um Ihre Steuerberichtsdateien für das aktuelle und alle vorherigen Steuerjahre anzuzeigen und herunterzuladen. Sie können diese Berichte dann an Ihren Buchhalter weitergeben.",
      link: () => "/report",
      title: () => "3. Bericht abrufen",
    },
    steps: {
      getStarted: () => "Erste Schritte",
      gettingStarted: () =>
        "Befolgen Sie einfach die folgenden Schritte, um loszulegen:",
    },
    tabs: {
      nfts: () => "NFTs",
      overview: () => "Überblick",
    },
    title: () => "Sie sind kurz davor, Ihre Steuern zu regeln!",
    topAssets: () => "Token-Zuteilung",
    totalReturn: () => "Gesamtrendite",
    totalReturnTooltip: () =>
      "Ihre Gesamtrendite ist Ihre bisherige Performance aus Investitionen in Kryptowährungen. Berechnet als Summe Ihres Portfoliowerts und des Nettoerlöses aus Fiatgeld nach Abzug des Nettoinvestments aus Fiatgeld.",
    treasury: () => "Schatzkammer",
    treasuryTimeframe: {
      lastMonth: () => "Letzter Monat",
      lastYear: () => "Letztes Jahr",
      monthToDate: () => "Diesen Monat",
      yearToDate: () => "Der bisherige Jahresverlauf",
    },
    unrealizedPosition: () => "Nicht realisierte Gewinne",
    unrealizedPositionTooltip: () =>
      "Die Differenz zwischen dem Nettovermögen Ihres aktuellen Portfolios und den Kosten für den Erwerb der Vermögenswerte, aus denen Ihr Portfolio besteht.",
    valueOfHoldings: () => "Wert der Beteiligungen",
    valueOfHoldingsTooltip: () =>
      "Die Differenz zwischen Ihrer Kostenbasis und dem aktuellen Wert aller Ihrer Vermögenswerte",
    valueOfHoldingsTooltipTitle: () => "Nicht realisierter Gewinn:",
    winners: () => "Gewinner",
    winnersAndLosers: {
      help: () =>
        "Verbessern Sie die Genauigkeit durch Kategorisierung von Transaktionen",
      importData: () => "Daten importieren",
      noData: () =>
        "In Ihren Berechnungen wurden keine Gewinner und Verlierer ermittelt. Fahren Sie mit dem Importieren Ihrer Transaktionen fort, um die Genauigkeit zu verbessern.",
      noDataBody: () =>
        "Hier werden Ihre Vermögenswerte mit der besten und schlechtesten Performance angezeigt. So legen Sie los",
      noDataTitle: () => "Keine Daten hinzugefügt",
      title: () => "Gewinner und Verlierer",
    },
  },
  dataSource: {
    radioButtonLabels: {
      accountant: () => "Buchhalterdaten",
      client: (d) =>
        select(d.accountant, { true: " Client ", other: " My " }) +
        " importierte Daten",
    },
    subtitle: () =>
      "Wählen Sie eine gemeinsame Datenquelle für Sie und Ihren Buchhalter aus",
    title: () => "Arbeitsdaten",
  },
  delete: () => "Löschen",
  deny: () => "Leugnen",
  developer: {
    activeFlags: () => "Aktive Flaggen",
    addFlag: () => "Flagge hinzufügen",
    clearFlagOverrides: () => "Überschreibungen löschen",
    enableLocalCensoring: () => "Lokale Zensur aktivieren",
    noActiveFlags: () => "Keine aktiven Flags",
    tabs: {
      currencies: () => "Währungen",
      editorIntegration: () => "Zum Code springen",
      entities: () => "Entitäten",
      featureFlags: () => "Funktionsflags",
      reconciliation: () => "Versöhnung",
      reportRefresh: () => "Bericht aktualisieren",
      rules: () => "Regeln für die Integration von Buchhaltungssoftware",
      seedData: () => "Seed-Daten",
      sessionRecording: () => "Sitzungsaufzeichnungen",
      userData: () => "Benutzerdaten",
    },
    title: () => "Entwickler",
  },
  done: () => "Erledigt",
  duplicate: () => "Duplikat",
  edit: () => "Bearbeiten",
  editTx: {
    accounts: () => "Konten",
    addFee: () => "Gebühr hinzufügen",
    advanced: () => "Fortschrittlich",
    baseCurrency: () => "Basiswährung",
    blockchain: () => "Blockchain",
    blockchainFrom: () => "Quelle Blockchain",
    blockchainPlaceholder: () => "zB ETH, Avalanche usw",
    blockchainTo: () => "Destination Blockchain",
    buyCurrency: () => "Währung kaufen",
    buyFee: () => "Kaufgebühr",
    buyFeeFor: () => "Kaufgebühr für Transaktion in",
    buyPrice: () => "Kaufpreis",
    buyQuantity: () => "Menge kaufen",
    cantEditLocked: () =>
      "Sie können eine gesperrte Transaktion nicht bearbeiten",
    cantEditLockedButton: () => "Einstellungen für Sperrtransaktionen anzeigen",
    cantMixBlockchain: () => "Kann nicht zu und von Blockchains mischen",
    currency: () => "Währung",
    currencyPaidIn: () =>
      "Währung, in der Sie die Gebühr bezahlt haben (sofern vorhanden)",
    destinationOfTransaction: () => "Ziel der Transaktion",
    editFee: () => "Gebühr bearbeiten",
    editPriceCurrencyValue: () => "Preis, Währung und Wert bearbeiten",
    feeForIncoming: () => "Gebühr für eingehende Transaktion in",
    feeForOutgoing: () => "Gebühr für ausgehende Transaktion in",
    feeQuantityPaidIn: () => "Gezahlter Gebührenbetrag in der Gebührenwährung",
    feeValue: () => "Gebührenwert",
    in: () => "In",
    inFee: () => "In Gebühr",
    inPrice: () => "Im Preis",
    inQuantity: () => "In Menge",
    inTime: () => "Rechtzeitig",
    linkValueTooltipTradeLike: () =>
      "Stellen Sie sicher, dass die Gesamtwerte beider Seiten gleich bleiben, indem Sie die Transaktionswerte anpassen.",
    linkValueTooltipTransferLike: () =>
      "Stellen Sie sicher, dass die Gesamtmenge auf beiden Seiten gleich bleibt, indem Sie die Transaktionsmengen anpassen.",
    lookupPrice: () => "Marktpreis nachschlagen",
    mustBeLess: () => "Muss weniger als 256 Zeichen lang sein",
    mustBuy: () => "Sie müssen einen Kaufpreis eingeben",
    mustCategory: () => "Sie müssen eine Kategorie eingeben",
    mustCurrency: () => "Sie müssen eine Währung eingeben",
    mustDestination: () => "Sie müssen ein Ziel eingeben",
    mustFee: () => "Sie müssen einen Gebührenwert eingeben",
    mustHaveBlockchain: () =>
      "Sie müssen dieser Transaktion eine Blockchain zuweisen",
    mustOutTime: () => "Sie müssen eine gültige Auszeit eingeben",
    mustPrice: () => "Sie müssen einen Preis eingeben",
    mustQuantity: () => "Sie müssen eine Menge eingeben",
    mustSell: () => "Sie müssen einen Verkaufspreis eingeben",
    mustSource: () => "Sie müssen eine Quelle eingeben",
    mustTime: () => "Sie müssen eine gültige Zeit eingeben",
    mustValue: () => "Sie müssen einen Wert eingeben",
    noPriceFound: (d) =>
      "Für " +
      d.currency +
      " wurde kein Preis gefunden. Preis manuell eingeben oder ",
    notes: () => "Hinweise",
    optionalNotes: () => "Optionale Hinweise für diese Transaktion",
    outFee: () => "Ausstiegsgebühr",
    outPrice: () => "Preis",
    outQuantity: () => "Aus Menge",
    outTime: () => "Auszeit",
    paidInFees: () => "an Gebühren bezahlt",
    price: () => "Preis",
    priceForOne: () => "Preis für 1",
    quantity: () => "Menge",
    removeFee: () => "Gebühr entfernen",
    sellCurrency: () => "Währung verkaufen",
    sellFee: () => "Verkaufsgebühr",
    sellFeeFor: () => "Verkaufsgebühr für Transaktion in",
    sellPrice: () => "Verkaufspreis",
    sellQuantity: () => "Verkaufsmenge",
    somethingWentWrong: () =>
      "Beim Aktualisieren der Transaktion ist ein Fehler aufgetreten.",
    sourceOfTransaction: () => "Quelle der Transaktion",
    swapIconIncoming: () => "Zur eingehenden Transaktion wechseln",
    swapIconOutgoing: () => "Zur ausgehenden Transaktion wechseln",
    totalFeeValueIn: () => "Gesamtbetrag der Gebühren in",
    totalQuantityOf: () => "Gesamtmenge an",
    totalValue: (d) => "Gesamtwert: " + d.amount,
    treatAsNFT: (d) => d.symbol + " als NFT behandeln?",
    treatAsNFTTooltip: () =>
      "Wenn Sie dies ändern, werden alle Transaktionen mit dieser Währung aktualisiert.",
    ungroupCriteria: {
      differentAssetType: () =>
        "Die Währungen erfüllen nicht die Anforderungen an den Anlagetyp",
      differentCurrency: () => "Die Währungen sind gleich",
      differentSource: () =>
        "Die Transaktionen stammen nicht aus getrennten Quellen (keine internen Überweisungen)",
      dontGroup: () => "Das Flag „Nicht gruppieren“ ist gesetzt",
      fiatCurrency: () =>
        "Bei den Transaktionen muss es sich um Fiat-Währungstransaktionen handeln",
      sameAccount: () =>
        "Die Transaktionen haben keine übereinstimmenden Konten",
      sameCurrency: () => "Die Währungen sind unterschiedlich",
      sameFromTo: () =>
        "Die Transaktionen haben keine übereinstimmenden Von- und Bis-Werte",
      sameTimestamp: () => "Die Zeitstempel sind unterschiedlich",
      sameTxId: () => "Die TX-IDs sind unterschiedlich",
      sameValueFeeExclusive: () =>
        "Die Werte beider Seiten sind nicht ähnlich genug",
      sameValueFeeInclusive: () =>
        "Die Werte beider Seiten sind nicht ähnlich genug",
      similarQuantity: () =>
        "Die Mengen auf beiden Seiten sind nicht ähnlich genug",
      similarTimestamp: () => " Die Zeitstempel sind zu unterschiedlich",
    },
    ungroupWarning: (d) =>
      "Die von Ihnen vorgenommenen Änderungen können dazu führen, dass die Gruppierung dieser Transaktionen aus den folgenden " +
      plural(d.count, 0, de, { 1: " Grund ", other: " Gründen " }) +
      " aufgehoben wird:",
    unlock: () => "Ändern",
    unlockBlockchainBody: () =>
      "Die Auswahl einer neuen Blockchain kann dazu führen, dass Ihre bisherigen Transaktionen auf dieser Kette nicht mehr korrekt übereinstimmen",
    unlockBlockchainTitle: () => "Möchten Sie die Blockchain wirklich ändern?",
    validTime: () => "Sie müssen eine gültige Zeit eingeben",
    validatePositiveFeeValue: () => "Die Gebühr muss größer als Null sein",
    validatePositiveFiatValue: () => "Der Fiat-Wert muss größer als Null sein",
    validatePositivePriceValue: () => "Der Preis muss größer als Null sein",
    validatePositiveQuantityValue: () => "Die Menge muss größer als Null sein",
    validatePositiveValue: () => "Der Wert muss größer als Null sein",
    value: () => "Wert",
    valueLockLabel: () => "Verknüpfen Sie den Wert jeder Seite der Transaktion",
    willSetBlockchain: () =>
      "Die Blockchain, in der sich die Adresse befindet.",
  },
  enforceUpgrade: {
    tooltip: () =>
      "Ihre Transaktionsanzahl hat das Limit für diesen Plan überschritten",
  },
  enterprise: {
    getReport: () => "Bericht abrufen",
    incomingInvoicesDownloadModal: {
      billingPeriod: () => "Abrechnungszeitraum",
      customDateRange: () => "Benutzerdefinierter Datumsbereich",
      downloadCsv: () => "CSV herunterladen",
      downloadPdf: () => "PDF herunterladen",
      endDate: () => "Enddatum",
      startDate: () => "Startdatum",
      startDateBeforeEndDateError: () =>
        "Das Startdatum muss vor dem Enddatum liegen",
      title: () => "Bericht herunterladen",
    },
    office: {
      addNew: {
        button: () => "Neues Büro hinzufügen",
        dialog: {
          accept: () => "Büro hinzufügen",
          title: () => "Neues Büro hinzufügen?",
        },
      },
      addNewButton: () => "Neues Büro hinzufügen",
      addNewOffice: () => "Neues Büro hinzufügen",
      all: () => "Alle",
      allOffices: () => "Alle Büros",
      offices: () => "Standorte",
    },
    valueCards: {
      numberOfOffices: () => "Anzahl der Niederlassungen",
      totalAccountants: () => "Buchhalter insgesamt",
      totalClients: () => "Kunden insgesamt",
      upcomingInvoices: () => "Bevorstehende Rechnung",
    },
  },
  error: () => "Fehler",
  errorBoundaries: {
    debugInfo: () => "Debug-Informationen",
    error: () => "Fehler",
    oopsSomethingWentWrong: () => "Hoppla! Da ist etwas schiefgelaufen :(",
    weCantProcess: () =>
      "Wir können Ihre Anfrage nicht bearbeiten. Bitte versuchen Sie, die Seite zu aktualisieren. Wenn das Problem weiterhin besteht, wenden Sie sich an unser Supportteam.",
  },
  errorMessageGeneric: () => "Da ist etwas schiefgelaufen",
  errorReporting: {
    actionButtonText: {
      contactSupport: () => "Support kontaktieren",
      retrySync: () => "Synchronisierung erneut versuchen",
      updateAddress: () => "Adresse aktualisieren",
      updateApiKey: () => "API-Schlüssel aktualisieren",
      viewDetails: () => "Details anzeigen",
    },
    csvErrorDetails: {
      description: () =>
        "Die folgenden Zeilen in der CSV wurden aufgrund von Problemen in der CSV übersprungen.",
      title: (d) => d.exchangeName + " übersprungene Transaktionen",
    },
  },
  etaTime: {
    momentHumanizeEquivalent: {
      "a few seconds": () => "ein paar Sekunden",
      day: () => "Tag",
      days: () => "Tage",
      hour: () => "Stunde",
      hours: () => "Std.",
      minute: () => "Minute",
      minutes: () => "Minuten",
      month: () => "Monat",
      months: () => "Monate",
      second: () => "zweite",
      seconds: () => "Sekunden",
      week: () => "Woche",
      weeks: () => "Wochen",
      year: () => "Jahr",
    },
    tooltip: (d) =>
      "Die Synchronisierung dauert normalerweise " +
      d.avgHumanizeTime +
      ", kann aber bis zu " +
      d.p95HumanizeTime +
      " dauern",
    tooltipSameTime: (d) =>
      "Die Synchronisierung dauert normalerweise " + d.avgHumanizeTime,
  },
  existing: {
    cancel: () => "Plan stornieren",
    goBack: () => "Geh zurück",
    pricingUrl: () => "https://cryptotaxcalculator.io/pricing/personal/",
    title: () => "Aktueller Plan",
    upgrade: () => "Aktualisierung",
    upgradePlan: () => "Upgrade-Plan",
    viewPricingInfo: () => "Preisinformationen anzeigen",
  },
  existingImports: {
    title: () => "Vorhandene Importe",
  },
  faqAccountant: {
    description: () => "Häufig gestellte Fragen",
    questions: {
      0: {
        answer: () =>
          "Abhängig von der Art der Kryptowährungstransaktion und Ihren individuellen Umständen können Sie sowohl der Kapitalertrags- als auch der Einkommensteuer unterliegen. Beispielsweise müssen Sie möglicherweise Kapitalertragssteuer auf Gewinne aus dem Kauf und Verkauf von Kryptowährung zahlen oder Einkommenssteuer auf Zinsen, die Sie beim Besitz von Kryptowährungen verdienen.",
        question: () => "Wie wird die Kryptosteuer berechnet?",
      },
      1: {
        answer: () =>
          "Die Art und Weise, wie Kryptowährungen in den meisten Ländern besteuert werden, bedeutet, dass Anleger möglicherweise trotzdem Steuern zahlen müssen, unabhängig davon, ob sie insgesamt einen Gewinn oder Verlust gemacht haben. Abhängig von Ihren Umständen werden Steuern normalerweise zum Zeitpunkt der Transaktion und nicht auf den Gesamtbestand am Ende des Geschäftsjahres erhoben.",
        question: () =>
          "Ich habe beim Handel mit Kryptowährungen Geld verloren. Muss ich trotzdem Steuern zahlen?",
      },
      2: {
        answer: () =>
          "In den meisten Ländern müssen Sie den Wert der Kryptowährung zum Zeitpunkt der Transaktion in Ihrer Landeswährung aufzeichnen. Dies kann sehr zeitaufwändig sein, wenn Sie es manuell tun, da die meisten Umtauschaufzeichnungen keinen Referenzpreispunkt haben und Aufzeichnungen zwischen Umtauschbörsen nicht leicht kompatibel sind.",
        question: () =>
          "Wie berechne ich die Steuer auf Krypto-zu-Krypto-Transaktionen?",
      },
      3: {
        answer: () =>
          "Sie müssen nur Ihren Transaktionsverlauf importieren und wir helfen Ihnen, Ihre Transaktionen zu kategorisieren und den realisierten Gewinn und Ertrag zu berechnen. Anschließend können Sie die entsprechenden Berichte erstellen, um sie an Ihren Buchhalter zu senden und detaillierte Aufzeichnungen für Prüfungszwecke griffbereit zu halten.",
        question: () =>
          "Wie kann der Crypto Tax Calculator bei Kryptosteuern helfen?",
      },
      4: {
        answer: () =>
          "Wir empfehlen Ihnen immer, Ihre Unterlagen gemeinsam mit Ihrem Buchhalter zu prüfen. Die manuelle Erstellung Ihrer Bücher kann jedoch äußerst zeitaufwändig sein, und die meisten Buchhalter verwenden hierfür einfach Software wie den Crypto Tax Calculator, die einen hohen Aufpreis verlangt.",
        question: () =>
          "Kann ich das nicht einfach von meinem Buchhalter erledigen lassen?",
      },
      5: {
        answer: () =>
          "Wir kümmern uns um alle Aktivitäten außerhalb der Börse, wie Onchain-Transaktionen wie Airdrops, Staking, Mining, ICOs und andere DeFi-Aktivitäten. Egal, welche Aktivitäten Sie im Bereich Kryptowährungen durchgeführt haben, wir haben Sie mit unserer benutzerfreundlichen Kategorisierungsfunktion abgedeckt, die Expensify ähnelt.",
        question: () =>
          "Beschäftigen Sie sich mit Aktivitäten, die nichts mit dem Austausch zu tun haben?",
      },
    },
    title: () => "FAQ Buchhalter",
  },
  faqPricing: {
    description: () => "Häufig gestellte Fragen",
    questions: {
      0: {
        answer: () =>
          "Abhängig von der Art der Kryptowährungstransaktion und Ihren individuellen Umständen können Sie sowohl der Kapitalertrags- als auch der Einkommensteuer unterliegen. Beispielsweise müssen Sie möglicherweise Kapitalertragssteuer auf Gewinne aus dem Kauf und Verkauf von Kryptowährung zahlen oder Einkommenssteuer auf Zinsen, die Sie beim Besitz von Kryptowährungen verdienen.",
        question: () => "Wie wird die Kryptosteuer berechnet?",
      },
      1: {
        answer: () =>
          "Die Art und Weise, wie Kryptowährungen in den meisten Ländern besteuert werden, bedeutet, dass Anleger möglicherweise trotzdem Steuern zahlen müssen, unabhängig davon, ob sie insgesamt einen Gewinn oder Verlust gemacht haben. Abhängig von Ihren Umständen werden Steuern normalerweise zum Zeitpunkt der Transaktion und nicht auf den Gesamtbestand am Ende des Geschäftsjahres erhoben.",
        question: () =>
          "Ich habe beim Handel mit Kryptowährungen Geld verloren. Muss ich trotzdem Steuern zahlen?",
      },
      2: {
        answer: () =>
          "In den meisten Ländern müssen Sie den Wert der Kryptowährung zum Zeitpunkt der Transaktion in Ihrer Landeswährung aufzeichnen. Dies kann sehr zeitaufwändig sein, wenn Sie es manuell tun, da die meisten Umtauschaufzeichnungen keinen Referenzpreispunkt haben und Aufzeichnungen zwischen Umtauschbörsen nicht leicht kompatibel sind.",
        question: () =>
          "Wie berechne ich die Steuer auf Krypto-zu-Krypto-Transaktionen?",
      },
      3: {
        answer: () =>
          "Sie müssen nur Ihren Transaktionsverlauf importieren und wir helfen Ihnen, Ihre Transaktionen zu kategorisieren und den realisierten Gewinn und Ertrag zu berechnen. Anschließend können Sie die entsprechenden Berichte erstellen, um sie an Ihren Buchhalter zu senden und detaillierte Aufzeichnungen für Prüfungszwecke griffbereit zu halten.",
        question: () =>
          "Wie kann der Crypto Tax Calculator bei Kryptosteuern helfen?",
      },
      4: {
        answer: () =>
          "Wir empfehlen Ihnen immer, Ihre Unterlagen gemeinsam mit Ihrem Buchhalter zu prüfen. Die manuelle Erstellung Ihrer Bücher kann jedoch äußerst zeitaufwändig sein, und die meisten Buchhalter verwenden hierfür einfach Software wie den Crypto Tax Calculator, die einen hohen Aufpreis verlangt.",
        question: () =>
          "Kann ich das nicht einfach von meinem Buchhalter erledigen lassen?",
      },
      5: {
        answer: () =>
          "Wir kümmern uns um alle Aktivitäten außerhalb der Börse, wie Onchain-Transaktionen wie Airdrops, Staking, Mining, ICOs und andere DeFi-Aktivitäten. Egal, welche Aktivitäten Sie im Bereich Kryptowährungen durchgeführt haben, wir haben Sie mit unserer benutzerfreundlichen Kategorisierungsfunktion abgedeckt, die Expensify ähnelt.",
        question: () =>
          "Beschäftigen Sie sich mit Aktivitäten, die nichts mit dem Austausch zu tun haben?",
      },
    },
    title: () => "Häufig gestellte Fragen",
  },
  faqs: {
    capitalGainsWrong: {
      ans: {
        0: () =>
          "Dafür gibt es zwei Hauptgründe. Entweder haben Sie einige Transaktionen zwischen Wallets als Kauf/Verkauf klassifiziert, obwohl Sie nur Kryptowährung verschoben haben (Einzahlung/Abhebung). Andernfalls haben Sie möglicherweise vergessen, einige Transaktionen oder Wallet-IDs hinzuzufügen, was einige Ihrer Kapitalgewinne ausgleichen könnte.",
      },
      q: () => "Was ist, wenn meine Kapitalertragsangaben falsch erscheinen?",
    },
    ensureAccuracy: {
      ans: {
        0: () =>
          "Um sicherzustellen, dass Ihre Steuererklärung so genau wie möglich ist, sind zwei Schritte erforderlich:",
        1: () => "Importieren Sie alle Ihre Daten",
        2: () =>
          "Dazu gehören Transaktionen von Fiat zu Kryptowährung, von Kryptowährung zu Kryptowährung, von Kryptowährung zu Fiat, DeFi-Transaktionen, Staking, Airdrops, Transaktionen aus diesem Jahr, aber auch Transaktionen seit Ihrem ersten Krypto-Kauf. Alles muss enthalten sein, um einen genauen Bericht zu erstellen.",
        3: () => "Jede Transaktion prüfen/klassifizieren",
        4: () =>
          "Es kann schwierig sein, sich jede Transaktion zu merken, aber das ist auch zwingend erforderlich. Wenn Sie eine Transaktion als Einzahlung/Abhebung kennzeichnen, hat das keine Auswirkungen auf Ihre Steuern, ein Kauf/Verkauf jedoch schon. Wenn Sie eine Transaktion als Airdrop, Staking, Zinsen usw. klassifizieren, wird sie als Einkommen und nicht als Kapitalgewinn eingestuft. Der Punkt ist, dass es darauf ankommt, wie Sie jede Transaktion kennzeichnen, also müssen Sie bei der Kennzeichnung genau sein.",
        5: () =>
          "Korrigieren Sie Warnungen bezüglich des verbleibenden Guthabens",
        6: () =>
          "Wenn Sie Kryptowährungen verkaufen, aber nicht genügend Guthaben haben, wird in der App eine Warnmeldung zum verbleibenden Guthaben angezeigt. Sie sollten Ihre Benachrichtigungen überprüfen, um sicherzustellen, dass keine Warnungen zum verbleibenden Guthaben vorliegen. Wenn Sie diese Warnungen haben, können Sie sie beheben, indem Sie die Schritte in der Anleitung hier befolgen: https://cryptotaxcalculator.io/guides/negative-balance-remaining-warnings ",
      },
      q: () => "Wie stelle ich sicher, dass dieser Bericht korrekt ist?",
    },
    howToUseCTC: {
      ans: {
        0: () =>
          "Navigieren Sie zunächst zur Importseite und laden Sie ALLE Ihre Transaktionsdaten von Börsenkonten und Wallet-Transaktionen hoch. Für jedes Börsenkonto oder Wallet gibt es Importanweisungen, die das Hochladen von CSV-Daten, das Einrichten einer API oder die Eingabe Ihrer Wallet-Details beinhalten.",
        1: () =>
          "Sobald alles hochgeladen wurde, überprüfen Sie Ihren Transaktionsverlauf. Dazu müssen Sie jede Transaktion nach Typ klassifizieren. Achten Sie auf Salden, die nicht übereinstimmen. CTC wird beispielsweise hervorheben, wenn Ihr Konto einen fehlenden Kaufverlauf aufweist, was bedeutet, dass Ihnen einige Transaktionen fehlen.",
        2: () =>
          "Gehen Sie abschließend auf die Seite mit dem Steuerbericht und überprüfen Sie kurz, ob die Zahlen und die Portfolioaufteilung plausibel klingen. Anschließend können Sie Ihren Steuerbericht sicher herunterladen.",
        3: () =>
          "Für detailliertere Informationen lesen Sie bitte unseren Leitfaden hier: https://cryptotaxcalculator.io/guides/how-to-use-crypto-tax-calculator",
      },
      q: () => "Wie verwende ich den Krypto-Steuerrechner?",
    },
    missingIncome: {
      ans: {
        0: () =>
          "Ja, die langfristigen Kapitalgewinne und die kurzfristigen Kapitalgewinne werden getrennt gemeldet.",
        1: () =>
          "Gewinne aus Vermögenswerten, die ein Jahr oder weniger gehalten wurden, werden als kurzfristige Gewinne klassifiziert, während Gewinne aus Vermögenswerten, die länger als ein Jahr gehalten wurden, als langfristige Gewinne klassifiziert werden.",
        2: () =>
          "Abhängig von Ihrem Rechtsgebiet haben Sie möglicherweise Anspruch auf einen Steuerrabatt auf langfristige Gewinne.",
      },
      q: () =>
        "Werden langfristige und kurzfristige Gewinne getrennt ausgewiesen?",
    },
  },
  for: () => "für",
  forceSyncErp: (d) => "Erzwinge erneute Synchronisierung mit " + d.erp,
  gainsToggles: () => "Optionen für die Kategorie „Kapitalgewinne“",
  generic: {
    isAccountant: () => "Sind Sie Buchhalter? Klicken Sie hier",
    notAccountant: () => "Zurück zur Einzelpreisliste",
    select: () => "Wählen",
    yourPlan: () => "Ihr Plan",
  },
  groupingWizard: {
    actionButton: () => "Schließen",
    backPage: () => "Zurück",
    matchCountPage: {
      description: () =>
        "Um Transaktionen zu gruppieren, müssen Sie über die richtige Anzahl an ausgehenden und eingehenden Transaktionen verfügen, um die gewünschte Gruppe zu bilden.",
      ratios: {
        NToOne: (d) =>
          d.type +
          " -Gruppen erfordern mindestens eine eingehende und eine ausgehende Transaktion, um eine Gruppe zu bilden.",
        nToM: (d) =>
          d.type +
          " -Gruppen erfordern mindestens eine eingehende und mindestens eine ausgehende Transaktion, um eine Gruppe zu bilden, und die Anzahl der eingehenden Transaktionen muss mit der Anzahl der ausgehenden Transaktionen übereinstimmen.",
        nToN: (d) =>
          d.type +
          " -Gruppen erfordern mindestens eine eingehende Transaktion und mindestens eine ausgehende Transaktion, um eine Gruppe zu bilden.",
        oneToN: (d) =>
          d.type +
          " -Gruppen erfordern eine eingehende Transaktion und mindestens eine ausgehende Transaktion, um eine Gruppe zu bilden.",
        oneToOne: (d) =>
          d.type +
          " -Gruppen erfordern eine eingehende und eine ausgehende Transaktion, um eine Gruppe zu bilden.",
      },
      selected: (d) =>
        "Sie haben " +
        plural(d.inCount, 0, de, {
          one: " 1 Eingangstransaktion ",
          other: number("de", d.inCount, 0) + " Eingangstransaktionen ",
        }) +
        " und " +
        plural(d.outCount, 0, de, {
          one: " 1 Ausgangstransaktion ",
          other: number("de", d.outCount, 0) + " Ausgangstransaktionen ",
        }) +
        " ausgewählt.",
      title: (d) =>
        "Haben Sie die richtige Anzahl an Transaktionen ausgewählt, um eine " +
        d.type +
        " -Gruppe zu erstellen?",
    },
    matchCriteriaPage: {
      description: () =>
        "Damit Transaktionen gruppiert werden können, müssen die Daten eine Reihe von Kriterien erfüllen, damit erfolgreich eine Gruppe erstellt werden kann.",
      differentAssetType: {
        cta: () =>
          "Die Währungen in einem Derivat müssen unterschiedliche Anlagetypen sein, Sie müssen genau einen Positions-Token haben.",
        title: () => "Währungen:",
      },
      differentCurrency: {
        cta: () =>
          "Die Währungen in einem Handel müssen unterschiedlich sein. Sie können nicht eine Währung verkaufen, um die gleiche Währung zu kaufen.",
        title: () => "Währungen:",
      },
      differentSources: {
        cta: () =>
          "Für einen Handel müssen sowohl die Kauf- als auch die Verkaufsseite aus demselben Import stammen. Ein Handel mit verschiedenen Importen ist nicht möglich.",
        title: () => "Importquellen:",
      },
      dontGroup: {
        cta: () =>
          "Bei einer oder mehreren der von Ihnen ausgewählten Transaktionen ist das Flag „Nicht gruppieren“ gesetzt. Dies wird gesetzt, wenn Sie die Funktion „Gruppierung aufheben“ verwenden, um die Gruppierung von Transaktionen zu verhindern. Wenn Sie möchten, dass diese Transaktionen gruppiert werden, deaktivieren Sie bitte das Flag „Nicht gruppieren“.",
        enableGrouping: () => "Gruppierung aktivieren",
        title: () => "Gruppierungsflagge aufheben:",
      },
      fiatCurrency: {
        cta: () =>
          "Bei den Transaktionen muss es sich um Fiat-Währungstransaktionen handeln",
        title: () => "Fiat-Währung:",
      },
      noIssues: {
        cta: () =>
          "Für die ausgewählten Transaktionen wurden keine Gruppierungsprobleme gefunden. Versuchen Sie eine manuelle Neuberechnung, um diese Transaktionen zu gruppieren. Wenn die Transaktionen nach einer manuellen Neuberechnung immer noch ungruppiert sind, wenden Sie sich bitte an den Support.",
        title: () => "Keine Gruppierungsprobleme gefunden",
      },
      quantity: {
        cta: () =>
          "Die in den Eingangstransaktionen abgewickelten Mengen stimmen nicht mit den in den Ausgangstransaktionen abgewickelten Mengen überein. Um diese Transaktionen zu gruppieren, wählen Sie bitte die Nebenmenge aus, die Sie für die Gruppe verwenden möchten.",
        title: () => "Währungsmenge:",
      },
      sameAccount: {
        cta: () =>
          "Die Benutzerkonten in den Transaktionen müssen übereinstimmen. Um diese Transaktionen zu gruppieren, stellen Sie bitte sicher, dass sie alle vom selben Konto stammen.",
        title: () => "Konten:",
      },
      sameCurrency: {
        cta: () =>
          "Die gesendete Währung stimmt nicht mit der empfangenen Währung überein. Um diese Transaktionen zu gruppieren, bearbeiten Sie die Txs bitte in der Währung, die Sie für die Gruppe verwenden möchten.",
        title: () => "Währungen:",
      },
      sameFromTo: {
        cta: () =>
          "Die Absender- und Empfängeradresse müssen übereinstimmen. Um diese Transaktionen zu gruppieren, bearbeiten Sie die Txs bitte in das Absender-/Empfängerpaar, das Sie für die Gruppe verwenden möchten.",
        title: () => "Von und nach:",
      },
      timestamp: {
        cta: () =>
          "Die ausgewählten Transaktionen liegen nicht innerhalb des erforderlichen Zeitstempelfensters, um gruppiert zu werden. Um diese Transaktionen zu gruppieren, bearbeiten Sie die Txs bitte mit dem Zeitstempel, den Sie für die Gruppe verwenden möchten",
        title: () => "Zeitstempel:",
      },
      title: (d) =>
        "Haben Sie Transaktionen ausgewählt, die alle Kriterien einer " +
        d.type +
        " -Gruppe erfüllen?",
      txId: {
        cta: () =>
          "Die ausgewählten Transaktionen haben nicht die gleiche Transaktions-ID. Die txId kann derzeit nicht bearbeitet werden",
        title: () => "Transaktions-ID:",
      },
      value: {
        cta: () =>
          "Die Werte der Eingänge für diese Transaktion stimmen nicht mit den Werten der Ausgänge überein. Um diese Transaktionen zu gruppieren, bearbeiten Sie die Txs bitte auf den Nebenwert, den Sie für die Gruppe verwenden möchten.",
        title: () => "Transaktionswert:",
      },
    },
    matchTypePage: {
      allowed: () => "Erlaubte Gruppentypen:",
      description: () =>
        "Damit Transaktionen gruppiert werden können, müssen ihre Kategorien mit den Untertypen einer unserer gruppierten Kategorien übereinstimmen.",
      selected: () => "Von Ihnen ausgewählte Kategorien:",
      title: () =>
        "Passen die ausgewählten Transaktionsarten in eine beliebige Gruppe?",
    },
    modalTitle: () => "Gruppierungsassistent",
    nextPage: () => "Nächste",
  },
  help: () => "Helfen",
  holdingsDropdown: {
    assets: () => "Vermögenswerte",
    balances: () => "Guthaben",
    calcBal: () => "Berechneter Saldo",
    calculatedTooltip: () => "Der vom Crypto Tax Calculator berechnete Saldo",
    currentPrice: () => "Aktueller Preis",
    holdings: () => "Positionen",
    noData: () => "N / A",
    noHoldings: () => "Keine Beteiligungen",
    price: () => "Preis",
    quantity: () => "Menge",
    quantityTooltip: () =>
      "Der von der Börse oder Wallet gemeldete Betrag oder, falls kein Betrag gemeldet wird, der vom Crypto Tax Calculator berechnete Betrag",
    repBal: () => "Gemeldeter Saldo",
    reportedTooltip: () =>
      "Der von der Börse oder dem Wallet gemeldete Kontostand",
    value: () => "Wert",
    valueTooltip: () =>
      "Der von der Börse oder Wallet gemeldete Wert oder, wenn kein Betrag gemeldet wird, der vom Crypto Tax Calculator berechnete Wert",
    wallets: () => "Vermögenswerte",
  },
  ignore: () => "Ignorieren",
  ignoreIssue: () => "Problem ignorieren",
  importStepType: {
    critical: () => "kritisch",
    note: () => "Notiz",
    step: () => "Schritt",
    video: () => "Video",
  },
  imports: {
    accountTableTypes: {
      blockchain: () => "Blockchain",
      exchange: () => "Austausch",
    },
    accounts: () => "Konten",
    accountsTableHeadings: {
      account: () => "Konto",
      assets: () => "Vermögenswerte",
      balance: () => "Gleichgewicht",
      synced: () => "Synchronisiert",
      tx: () => "Tx",
      type: () => "Typ",
    },
    addIntegrationTitle: () => "Konto hinzufügen",
    addIntegrations: () => "Konto hinzufügen",
    addSource: (d) => "Zusätzliche " + d.source + " -Quelle hinzufügen",
    addYourFirst: () => "Fügen Sie Ihr erstes Konto hinzu, um loszulegen",
    additionalNotes: () => "Zusätzliche Hinweise",
    advancedOptions: () => "Erweiterte Optionen",
    api: () => "API",
    apiDisabled: (d) =>
      select(d.isNew, {
        true: " Die Option „Automatische Synchronisierung über API“ ist nicht verfügbar ",
        other:
          " Die Option „Automatische Synchronisierung über API“ ist nicht verfügbar, da die Daten bereits über den Datei-Upload importiert wurden ",
      }),
    apiStillSyncing: () =>
      "Die API wird noch synchronisiert, bitte warten Sie einige Minuten.",
    apiWarning: () =>
      "Fügen Sie niemals API-Schlüssel mit privaten Berechtigungen hinzu, die den Handel ermöglichen!",
    backToExistingImports: () => "Zurück zu bestehenden Importen",
    backToImport: () => "Zurück zur Importsuche",
    backToImportTypeSelector: () => "Zurück zur Importtyp-Auswahl",
    backToIntegrations: () => "Zurück zu den Konten",
    backToIntegrationsSearch: () => "Zurück zur Kontensuche",
    beta: () => "Beta",
    betaTooltipDefault: () =>
      "Diese Integration befindet sich in der Betaphase und kann einige Probleme aufweisen. Bitte melden Sie etwaige Probleme unserem Supportteam.",
    breakdownTooltip: () => "Klicken zum Sortieren",
    browse: () => "Hochladen",
    bulkSyncTooltip: {
      api: (d) =>
        "Synchronisieren Sie Ihre " +
        d.name +
        " -APIs ab " +
        d.dateTime +
        " erneut",
      deprecatedTooltip: (d) =>
        d.name +
        " -API ist nicht mehr betriebsbereit. Bitte importieren Sie sie über CSV.",
      failed: () =>
        "Synchronisierung fehlgeschlagen. Klicken Sie hier, um erneut zu synchronisieren.",
      lastSyncTime: () => "letzte Synchronisierungszeit",
      lockedPeriod: (d) =>
        "Erneute Synchronisierung ab Ende des letzten Sperrzeitraums: " +
        d.dateTime,
      noSync: () => "Nichts zum erneuten Synchronisieren",
      pending: () => "Synchronisieren",
      wallet: (d) =>
        "Synchronisieren Sie Ihre " +
        d.name +
        " -Wallets ab " +
        d.dateTime +
        " erneut",
    },
    chainDisabled: (d) => d.name + " deaktiviert",
    chips: {
      api: () => "API",
      csv: () => "CSV",
      service: () => "Service",
      wallet: (d) =>
        plural(d.walletCount, 0, de, {
          0: " Wallet ",
          one: " 1 Wallet ",
          other: number("de", d.walletCount, 0) + " Wallets ",
        }),
    },
    clickHere: () => "Klicken Sie hier",
    connectWallet: () => "Wallets verbinden",
    connectWalletErrors: {
      duplicateWallet: (d) =>
        "Dieses Wallet " + d.address + " wurde bereits verbunden",
      duplicateWallets: (d) =>
        "Diese Wallets " + d.addresses + " wurden bereits verbunden",
      maxWallets: (d) =>
        "Sie können nur " + d.max + " Wallets gleichzeitig verbinden",
      walletNotInstalled: (d) =>
        "Wallet " +
        d.wallet +
        " ist nicht installiert. Bitte installieren Sie " +
        d.wallet +
        " von der offiziellen Website oder als Erweiterung, um die Verbindung zu Ihrem Wallet fortzusetzen.",
    },
    connected: () => "Verbunden",
    connectionSuccess: () => "Verbindung erfolgreich!",
    continue: () => "Weitermachen",
    cryptoHoldings: () => "Krypto-Bestände",
    csv: () => "csv",
    csvAlerts: {
      fileSizeTooBig: () =>
        "Maximale Größe von 20 MB überschritten. Bitte entfernen Sie Dateien oder stellen Sie kleinere Dateien bereit.",
      fileTypeNotSupported: () =>
        "Wir unterstützen nur CSV-, XLS- oder XLSX-Dateien.",
      invalidFileName: (d) =>
        "Dateiname(n) " + d.fileNames + " enthalten ungültige Symbole.",
      missingAddress: () =>
        "Sie müssen die mit dieser CSV-Datei verknüpfte Adresse eingeben",
      missingCurrencySymbols: () =>
        "Sie müssen die Währung eingeben, da diese zwischen den CSV-Dateien variiert",
      noFiles: () => "Keine Datei ausgewählt!",
      onlyOneFile: () => "Bitte laden Sie nur eine Datei hoch.",
    },
    csvCaps: () => "CSV",
    csvDisabled: (d) =>
      select(d.isNew, {
        true: " Die Option zum Hochladen von Dateien ist nicht verfügbar ",
        other:
          " Die Option zum Hochladen von Dateien ist nicht verfügbar, da die Daten bereits per Autosync importiert wurden ",
      }),
    csvExtraInfo: {
      address: () => "Adresse",
      addressHelper: () =>
        "Geben Sie die mit dieser CSV verknüpfte Wallet-Adresse ein",
      addressPlaceholder: () => "Geben Sie die Wallet-Adresse ein",
      currencyHelper: () => "Bitte geben Sie die Währung in dieser CSV ein",
      currencySymbol: () => "Währungssymbol",
      fiatCurrency: () =>
        "Bestätigen Sie die Fiat-Währung dieser CSV, z. B. NZD oder AUD",
      tickerPlaceHolder: () => "Geben Sie den Tickerwert ein, z. B. BTC",
    },
    csvWarning: () => "Wir empfehlen, wenn möglich CSV-Importe zu verwenden",
    customCSV: () => "Benutzerdefiniertes CSV importieren",
    customCsvButton: () =>
      "Sie können Ihre Börse nicht finden? Klicken Sie hier, um eine benutzerdefinierte CSV hinzuzufügen",
    customCsvLabel: () => "Austauschname",
    customCsvPlaceholder: () => "zB Binance, Coinbase",
    deleteApiKeyConfirmTitle: () => "API-Schlüssel löschen",
    deleteImportExchangeConfirmation: {
      body: (d) =>
        "Möchten Sie wirklich alle Daten aus " +
        d.name +
        " entfernen? Dadurch werden alle mit diesem Import verbundenen Transaktionen gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden.",
      title: () => "Import löschen",
    },
    deleteImportedFileConfirmation: {
      body: () =>
        "Möchten Sie die Datei wirklich löschen? Dadurch werden auch ALLE von dieser Datei importierten Transaktionen gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
      title: () => "Datei löschen",
    },
    deleteOauthKeyConfirmText: (d) =>
      "Möchten Sie Ihr " +
      d.exchangeName +
      " -Konto wirklich trennen? Dadurch werden ALLE von Ihrem verbundenen " +
      d.exchangeName +
      " -Konto importierten Transaktionen gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
    deleteOauthKeyConfirmTitle: (d) => "Trennen Sie " + d.exchangeName,
    deleteWalletConfirmTitle: () => "Wallet löschen",
    deprecated: () => "Veraltet",
    deprecatedTooltipDefault: () =>
      "Diese Integration ist veraltet und wird nicht mehr aktiv gepflegt.",
    deprecation: {
      description: () =>
        "Diese Importquelle wurde außer Betrieb genommen und wird nicht mehr gepflegt. Importieren Sie Daten mit unserem manuellen CSV-Format. https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
      title: () => "Ausrangierte Quelle",
    },
    dialogOptions: {
      addApi: () => "API hinzufügen",
      addCsv: () => "CSV hinzufügen",
      addWallet: () => "Wallet-Adresse hinzufügen",
      copyAddress: () => "Adresse kopieren",
      delete: () => "Löschen",
      deleteAll: () => "Alle löschen",
      downloadCsv: () => "CSV herunterladen",
      editApi: () => "API bearbeiten",
      editNickname: () => "Spitznamen bearbeiten",
      hardSync: () => "Harte Synchronisierung",
      ignoreChain: () => "Kette ignorieren",
      sync: () => "Synchronisieren",
      viewExplorer: () => "Im Block-Explorer anzeigen",
      viewLedger: () => "Währungsbuch",
      viewTransactions: () => "Transaktionen anzeigen",
    },
    done: () => "Erledigt",
    downloadCsvDisplayMessage: {
      info: () => "CSV-Download wird vorbereitet...",
      success: () => "CSV-Download bereit!",
    },
    drawer: {
      accountMetricsHeading: {
        balance: () => "Gleichgewicht",
        feesPaid: () => "Bezahlte Gebühren",
        tradeVolume: () => "Handelsvolumen",
        transactions: () => "Transaktionen",
      },
      addApiKey: () => "API-Schlüssel hinzufügen",
      addCsv: () => "CSV hinzufügen",
      addWallet: () => "Wallet-Adresse hinzufügen",
      currencyTableHeadings: {
        amount: () => "Menge",
        currencies: () => "Währungen",
        price: () => "Preis",
        value: () => "Wert",
      },
      deleteApiKey: {
        body: (d) =>
          "Möchten Sie diesen API-Schlüssel " +
          d.key +
          " wirklich löschen? Dadurch werden ALLE mit diesem Schlüssel importierten Transaktionen gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
        title: () => "API-Schlüssel löschen",
      },
      deleteBlockchain: {
        body: (d) =>
          "Möchten Sie " +
          d.address +
          " auf " +
          d.blockchain +
          " wirklich löschen? Dadurch werden ALLE von dieser Adresse auf der Blockchain importierten Transaktionen gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
        title: () => "Blockchain löschen",
      },
      deleteFile: {
        body: (d) =>
          "Möchten Sie die Datei " +
          d.name +
          " wirklich löschen? Dadurch werden ALLE von dieser Datei importierten Transaktionen gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
        title: () => "Datei löschen",
      },
      deleteOauth: {
        body: (d) =>
          "Möchten Sie Ihr " +
          d.exchangeName +
          " -Konto wirklich trennen? Dadurch werden ALLE von Ihrem verbundenen " +
          d.exchangeName +
          " -Konto importierten Transaktionen gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
        title: (d) => "Trennen Sie " + d.exchangeName,
      },
      nftTableHeadings: {
        collections: () => "Sammlungen",
        combinedCost: () => "Kombinierte Kosten",
        ntfs: () => "NFTs",
        totalSpend: () => "Gesamtausgaben",
      },
      overview: () => "Überblick",
      showLess: () => "Weniger Währungen anzeigen",
      showLessNft: () => "Weniger NFTs anzeigen",
      showMore: () => "Weitere Währungen anzeigen",
      showMoreNft: () => "Mehr NFTs anzeigen",
      statusBanner: {
        accountHasNoTransactions: () =>
          "Das Konto weist keine Transaktionen auf",
        and: () => "Und",
        chainSyncFailed: (d) => d.chains + " -Synchronisierung fehlgeschlagen",
        generalImportsFailed: (d) =>
          select(d.all, { true: " Alle ", other: " Einige " }) +
          " " +
          d.integration +
          " -Importe sind fehlgeschlagen",
        generalSyncsFailed: (d) =>
          select(d.all, { true: " Alle ", other: " Einige " }) +
          " " +
          d.integration +
          " -Importe konnten nicht synchronisiert werden",
        selectedImportFailed: (d) =>
          d.name +
          " -Import fehlgeschlagen " +
          select(d.reason, { undefined: "", other: " : " + d.reason }) +
          select(d.code, { undefined: "", other: " - code: " + d.code }),
        selectedImportHasNoTransactions: (d) =>
          d.name + " -Import hat keine Transaktionen",
        selectedSyncFailed: (d) =>
          d.name +
          " Synchronisierung fehlgeschlagen " +
          select(d.reason, { undefined: "", other: " : " + d.reason }) +
          select(d.code, { undefined: "", other: " - code: " + d.code }),
      },
      viewCollection: () => "Kollektion anzeigen",
      viewLedger: () => "Währungsbuch",
      viewOnTxTable: () => "Ansicht in der Transaktionstabelle",
      viewTxs: () => "Transaktionen anzeigen",
    },
    dropFiles: () => "Legen Sie Ihre Datei(en) hier ab",
    editSource: (d) => d.source + " Quelle bearbeiten",
    emptyHoldings: () => "Keine Kryptowährungsbestände …",
    enable: () => "Aktivieren",
    enableImportChainTransactions: () =>
      "Aktivieren, um Transaktionen aus dieser Kette zu importieren",
    error: (d) =>
      "Fehler beim Importieren von " +
      select(d.blockchain, { true: "address " + d.name, other: d.name }) +
      select(d.showError, { true: ": " + d.error, other: "" }) +
      select(d.showCode, { true: " - code: " + d.code, other: "" }),
    exchangeGridTitle: {
      all: () => "Alle Börsen und Wallets",
      allBlockchains: () => "Alle Blockchains und Wallets",
      popular: () => "Am beliebtesten",
      results: () => "Ergebnisse",
    },
    existingOptions: () => "Importierte Wallets und Börsen",
    findAccount: () => "Konto suchen",
    firstTx: () => "Erste importierte Transaktion:",
    goBack: () => "Geh zurück",
    hardSync: () => "Harte Synchronisierung",
    import: () => "Import",
    importBannerMessages: {
      connectingToIntegration: (d) =>
        "Verbindung wird hergestellt zu " + d.name,
      success: () => "Import erfolgreich",
    },
    importByManualCSV: (d) =>
      "Importieren Sie CTC-Vorlagen-CSV für „ " + d.exchange + " “",
    importCSV: (d) => d.source + " CSV importieren",
    importDataTitle: () => "Konto hinzufügen",
    importExchange: (d) => d.exchangeName + " importieren",
    importFailed: () => "Import fehlgeschlagen",
    importFileDuplicateWarning: () =>
      "Alle Transaktionen in dieser Datei wurden als Duplikate identifiziert und nicht importiert",
    importFrom: {
      chip: {
        notRecommended: () => "Nicht empfohlen",
        recommended: () => "Empfohlen",
      },
      modal: {
        actionText: () => "Weiter mit Importieren",
        text: () =>
          "Die Verwendung der Funktion „Import ab Datum“ verhindert den Import von Transaktionen vor dem ausgewählten Datum. Dies kann zu Fehlern in der Kaufhistorie führen, die durch eine „manuelle“ Transaktion korrigiert werden können, um den Anfangssaldo des Imports vor dem Import ab Datum bereitzustellen.",
        title: () => "Beim Importieren ab Datum können Daten fehlen",
      },
      selector: {
        beginning: () => "Anfang",
        custom: () => "Benutzerdefiniertes Datum",
        title: () => "Import starten von:",
      },
    },
    importInstructionsAccordionTitle: () => "Schritt für Schritt Anleitung",
    importTypeModal: {
      buttonLabel: {
        csv: {
          active: () => "CSV hochladen",
          default: () => "Hochladen",
        },
      },
      description: () => "Wie möchten Sie Ihre Daten importieren?",
      importMethod: {
        api: {
          description: () =>
            "Nur Lesezugriff. Die Verbindung ist vertraulich und sicher verschlüsselt.",
          title: () => "Verbindung über API herstellen",
        },
        "bulk-wallet": {
          description: () =>
            "Importieren Sie Transaktionen, indem Sie Ihre öffentlichen Adressen aus Ihrer Coinbase Wallet-App oder -Erweiterung einfügen",
          title: () => "Öffentliche Adressen importieren",
        },
        "connect-wallet": {
          description: () =>
            "Sicherer Import von Transaktionen aus der Blockchain",
          title: () => "Wallets verbinden",
        },
        csv: {
          description: () => "Laden Sie eine Transaktionsverlaufsdatei hoch ",
          title: () => "Import per CSV-Datei",
        },
        oauth: {
          description: () => "Transaktionen automatisch über OAuth importieren",
          title: () => "Automatische Synchronisierung",
        },
        wallet: {
          description: () =>
            "Importieren Sie Transaktionen automatisch aus der Blockchain, indem Sie Ihre Wallet-Adresse einfügen",
          title: () => "Adresssynchronisierung",
        },
      },
      instructionTitles: {
        api: () => "Anweisungen zur API-Synchronisierung",
        "bulk-wallet": () =>
          "Anweisungen zur Massensynchronisierung von Wallets",
        "connect-wallet": () => "Anweisungen zum Verbinden der Brieftasche",
        csv: () => "Anweisungen zum Hochladen der Datei",
        oauth: (d) => "Connect " + d.exchange + " -Anweisungen",
        wallet: () => "Anweisungen zur Wallet-Synchronisierung",
      },
      methods: {
        api: () => "Geben Sie die API-Details ein",
        "bulk-wallet": () => "Wallet-Adressen eingeben",
        wallet: () => "Wallet-Adresse eingeben",
      },
      title: (d) => d.name + " importieren",
    },
    imported: () => "Importiert",
    importedFileWarning: () =>
      "Aus dieser Datei wurden keine Transaktionen importiert",
    importedTransactions: (d) =>
      d.count +
      " " +
      plural(d.count, 0, de, {
        one: " Transaktion ",
        other: " Transaktionen ",
      }) +
      " aus " +
      d.exchangeDisplayName +
      " importiert.",
    imports: () => "Importe",
    integrationChips: {
      all: () => "Alle",
      blockchains: () => "Blockchains",
      exchanges: () => "Börsen",
      services: () => "Dienstleistungen",
      wallets: () => "Wallets",
    },
    integrationSuccessful: () => "Integration erfolgreich",
    integrations: () => "Integrationen",
    label: () => "Wählen Sie Ihre Börse oder Ihr Wallet aus",
    lastLockedSyncWarning: {
      action: () => "Gesperrte Zeiträume anzeigen",
      body: (d) =>
        "Transaktionen vor dem " + d.date + " werden nicht importiert",
      title: () => "Sperrzeiten:",
    },
    lastSynced: () => "Zuletzt synchronisiert",
    lastTx: () => "Letzte importierte Transaktion:",
    manualCSV: {
      csvText: () => "CSV",
      customCSVToExchange: {
        description: {
          link: () =>
            "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
          linkText: () => " benutzerdefiniertes CSV-Format.",
          preLinkText: () =>
            "Wenn Sie benutzerdefinierte Transaktionen in dieses Konto importieren möchten, folgen Sie den untenstehenden Schritten mit unserem",
        },
        title: () => "Importieren benutzerdefinierter Transaktionen",
      },
      excelText: () => "Excel-Vorlage",
      finishAdding: () =>
        "Beenden Sie das Hinzufügen der Dateien und fahren Sie fort",
      googleSheetsText: () => "Google Sheet-Vorlage",
      learnMore: {
        link: () =>
          "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
        text: () => "Erfahren Sie mehr über CSV-Importe",
      },
      seeAdvancedGuide: {
        link: () =>
          "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
        linkText: () => "Erweiterter CSV-Leitfaden",
        postLinkText: () =>
          "für Trades, bei denen die Gebühren noch mit den Handelsdaten berechnet werden müssen.",
        preLinkText: () => "Entdecken Sie unsere",
      },
      steps: {
        0: () =>
          "Vervollständigen Sie Ihre Transaktionsdetails in der Excel-, CSV- oder Google Sheets-Vorlage.",
        1: () =>
          "Stellen Sie sicher, dass alle Felder ausgefüllt sind, um Upload-Fehler zu vermeiden.",
        2: () =>
          "Exportieren Sie Ihr Dokument als CSV und klicken Sie auf „Hochladen“.",
      },
      warningText: () =>
        "Stellen Sie sicher, dass Ihre Daten korrekt und im benutzerdefinierten CSV-Dateiformat des Crypto Tax Calculators vorbereitet sind",
    },
    manualCSVImport: () => "Verwenden Sie zum Importieren die CTC-CSV-Vorlage",
    manualImportTooltip: () =>
      "Wenn Sie Ihr Wallet oder Ihre Börse nicht finden können, können Sie Ihre Daten mithilfe der CTC-CSV-Vorlage importieren.",
    marketValue: (d) => "Marktwert in " + d.localCurrency,
    nameOfSource: () => "Name der Quelle",
    noExchange: () => "Sie können Ihre Börse nicht finden?",
    noImports: () =>
      "Keine importierten Börsen, Blockchains oder Wallets gefunden!",
    noOptions: () => "Keine Optionen",
    noSync: () => "Keine letzte Synchronisierung",
    noTxFound: (d) =>
      "Für " +
      d.exchangeDisplayName +
      " wurden keine " +
      select(d.showNew, { true: " new ", other: " " }) +
      " Transaktionen gefunden.",
    noTxFoundWallet: (d) =>
      "Für " +
      d.blockchain +
      " Wallet " +
      d.address +
      " wurden keine " +
      select(d.showNew, { true: " new ", other: " " }) +
      " Transaktionen gefunden.",
    note: () => "Notiz",
    notifications: {
      kucoinfutures: {
        buttonLabel: () => "Zurückweisen",
        text: () =>
          "Ihre Kucoin Futures-Daten wurden in die Kucoin-Integration konsolidiert",
        title: () => "Kucoin Futures entfernt",
      },
    },
    oauth: {
      connect: (d) => "Mit " + d.exchange + " verbinden",
      description: (d) =>
        "Sie werden aufgefordert, sich bei " +
        d.exchange +
        " anzumelden und Ihrem Konto schreibgeschützten Zugriff zu gewähren. Dadurch erhalten wir keinen Zugriff, um Ihr Geld zu überweisen.",
    },
    onboarding: {
      expect: () => "Das erwartet Sie",
      getStarted: () => "Erste Schritte",
      steps: {
        0: () => "Wählen Sie Ihr Land",
        1: () => "Integrieren Sie Ihre Börsen, Wallets und Blockchains",
        2: () =>
          "Holen Sie sich Ihren Steuerbericht und überprüfen Sie die Richtigkeit Ihrer Daten",
      },
      welcome: () => "Willkommen beim Krypto-Steuerrechner",
    },
    options: {
      api: () => "Synchronisation über API",
      "bulk-wallet": () => "Großes Wallet",
      connect: (d) => "Verbinden " + d.exchange,
      csv: () => "Datei hochladen",
      oauth: (d) => "Verbinden " + d.exchange,
      "single-import": () => "Einzelimport",
    },
    prompt: () => "ZB Coinbase, Bitcoin",
    relatedChains: {
      account: () => "Konto",
      addBlockchain: () => "Blockchain hinzufügen",
      cluster: {
        dontImport: () => "Nicht importieren",
        ignore: () => "Nicht erneut nachfragen",
        import: () => "Import",
        text: () =>
          "Möchten Sie die restlichen Wallets in diesem Cluster importieren?",
        title: () =>
          "Wir haben festgestellt, dass Ihre Adresse zu einem Cluster gehört",
      },
      dontImport: () => "Nicht importieren",
      import: () => "Import",
      importTransactions: () =>
        "Für dieses Konto Transaktionen auf den folgenden Ketten importieren?",
      scanning: (d) =>
        "EVM-Ketten nach Transaktionen auf " + d.wallet + " scannen",
      scanningNoResults: (d) =>
        "Wir haben keine anderen Blockchains unter " + d.address + " gefunden",
      scanningSuccess: () =>
        "Transaktionen auf anderen EVM-Ketten gefunden für",
      stepProgress: (d) =>
        "Brieftasche " + d.step + " von " + d.totalSteps + " :",
      stillScanning: () => "Scanne immer noch...",
      syncingOnChains: (d) =>
        d.wallet + " wird auf Chains " + d.chains + " synchronisiert",
      title: () => "Transaktionen auf zugehörigen Ketten erkannt",
    },
    requestAnIntegration: () => "Fordern Sie eine Integration an",
    resultNotFound: () => "Ergebnis nicht gefunden",
    searchPrompt: () => "Suchen Sie nach Ihrer Wallet, Börse oder Blockchain",
    secureConnect: () => "Sichere Verbindung",
    secureConnectNote: () =>
      "Wir fordern lediglich Anzeigeberechtigungen an. Wir werden niemals private Schlüssel anfordern und können keine Geldmittel überweisen.",
    secureImport: (d) =>
      "Importieren Sie Ihren " +
      d.exchangeName +
      " -Transaktionsverlauf sicher:",
    selectCountry: {
      chooseJurisdiction: () => "Wählen Sie Ihre Steuerjurisdiktion",
      findCountry: () => "Finden Sie Ihr Land",
      pleaseSelect: () => "Bitte wählen Sie ein Land aus, um fortzufahren",
      search: () => "Suchen",
      somethingWentWrong: (d) =>
        "Beim Festlegen des Landes ist ein Fehler aufgetreten: $ " + d.message,
      somethingWentWrongSupport: () =>
        "Etwas ist schiefgelaufen. Wenden Sie sich an den Support.",
    },
    selectFile: () => "oder ziehen Sie Ihre Datei hierher",
    selectFiles: () => "oder ziehen Sie Ihre Dateien hierher",
    skippedRows: {
      cta: (d) =>
        plural(d.count, 0, de, {
          1: " 1 übersprungene Zeile ",
          other: number("de", d.count, 0) + " übersprungene Zeilen ",
        }),
      errors: {
        apiError: () =>
          "Bei der Interaktion mit einer externen API ist ein Fehler aufgetreten.",
        genericError: () => "Ein unerwarteter Fehler ist aufgetreten.",
        lockPeriod: () =>
          "Die Zeile wurde übersprungen, da sie eine Transaktion in einem gesperrten Zeitraum erzeugt hätte.",
        noOp: () =>
          "Die Zeile wurde absichtlich übersprungen, da die Daten entweder nicht relevant sind oder aus einer anderen CSV-Datei abgerufen werden.",
        noTransactions: () => "In der CSV wurden keine Transaktionen gefunden.",
        unhandledFields: () =>
          "In der Zeile wurden unerwartete Felder gefunden.",
        unhandledTimestamp: () =>
          "Der Zeitstempel für die Zeile hatte nicht das erwartete Format.",
        unhandledTrade: () =>
          "Die Zeile hatte einen nicht unterstützten Handelstyp.",
        userSpecifiedCurrencyIssue: () =>
          "Es ist ein Fehler mit der vom Benutzer angegebenen Währung aufgetreten.",
      },
      header: () => "übersprungene Zeilen",
      info: (d) =>
        plural(d.count, 0, de, {
          1: " 1 Zeile in der CSV wurde aus folgendem Grund übersprungen ",
          other:
            number("de", d.count, 0) +
            " Zeilen in der CSV wurden aus folgenden Gründen übersprungen ",
        }),
    },
    softWalletHeading: (d) =>
      "Wir importieren Ihre " +
      d.name +
      " -Daten direkt aus der Blockchain. Wählen Sie unten das Blockchain-Netzwerk Ihres Wallets aus.",
    softWalletNetworkSelect: () => "Betreten Sie Ihr Wallet-Netzwerk",
    softWalletPrompt: () => "ZB Bitcoin, Ethereum",
    solanaBalanceWarning: () =>
      "Der Saldo umfasst eingesetzte SOL und SOL, die in Token-Konten gehalten werden. Einige Explorer schließen diese möglicherweise nicht in den Gesamtsaldo ein.",
    sortImports: {
      aToZ: () => "Name AZ",
      mostTransactions: () => "Die meisten Transaktionen",
      zToA: () => "Name ZA",
    },
    successImportWallet: (d) =>
      "Transaktionen erfolgreich aus " +
      d.blockchain +
      " Wallet " +
      d.address +
      " importiert",
    suspectedMissingImports: {
      addAccount: () => "Konto hinzufügen",
      chips: {
        amount: () => "Überweisungsbetrag:",
        count: () => "Transaktionen:",
        currencies: () => "Währungen:",
      },
      explainer: (d) =>
        "Es sieht so aus, als hätten Sie " +
        plural(d.count, 0, de, {
          1: " 1 Transaktion ",
          other: number("de", d.count, 0) + " Transaktionen ",
        }) +
        " mit " +
        d.symbol1 +
        plural(d.additionalCurrencyCount, 0, de, {
          0: select(d.symbol2, { undefined: "", other: " und " + d.symbol2 }),
          1: select(d.symbol2, {
            undefined: "",
            other: ", " + d.symbol2 + " und 1 andere Währung ",
          }),
          other: select(d.symbol2, {
            undefined: "",
            other:
              ", " +
              d.symbol2 +
              " und " +
              number("de", d.additionalCurrencyCount, 0) +
              " andere Währungen ",
          }),
        }) +
        " auf " +
        d.exchange +
        " " +
        select(d.startDate, {
          undefined: " in ",
          other: " zwischen " + d.startDate + " und ",
        }) +
        " " +
        d.endDate +
        ".",
      title: () => "Empfohlene Konten",
      toastBody: () => "Sie können dieses Konto jederzeit hinzufügen",
      toastHeading: () => "Empfehlung abgelehnt",
      tooltip: () =>
        "Wir haben Interaktionen mit diesen Konten festgestellt. Durch die Verknüpfung aller Ihrer Konten wird die Genauigkeit Ihres Steuerberichts verbessert.",
      viewTransactions: (d) =>
        plural(d.count, 0, de, {
          1: " Transaktion ",
          other: " Transaktionen ",
        }) + " anzeigen.",
      whyThis: () => "Warum dieses Konto?",
    },
    sync: () => "Synchronisieren",
    syncAll: () => "Alle synchronisieren",
    syncFailed: () => "Synchronisierung fehlgeschlagen",
    syncStatus: {
      failed: (d) =>
        select(d.isCsv, {
          true: " Import fehlgeschlagen ",
          other: " Synchronisierung fehlgeschlagen ",
        }),
      noSync: () => "Keine Synchronisierung",
      pending: (d) =>
        select(d.isCsv, { true: " Importieren ", other: " Synchronisieren " }),
      queued: (d) =>
        select(d.isCsv, { true: " Importieren ", other: " In Warteschlange " }),
      success: (d) =>
        select(d.isCsv, { true: " Importiert ", other: " Synchronisiert " }),
    },
    syncedFrom: () => "Synchronisiert von:",
    syncing: () => "Synchronisieren...",
    syncingWallet: (d) => d.name + " -Wallet wird synchronisiert",
    title: () => "Konto hinzufügen",
    transactionCount: {
      noTransactions: () => "Keine Transaktionen",
      noTxs: () => "Keine TXs",
      transaction: () => "Transaktion",
      transactions: () => "Transaktionen",
      tx: () => "TX",
      txs: () => "TXs",
    },
    transactionsImported: () => "Importierte Transaktionen",
    updatedAt: (d) => "Aktualisiert " + d.time,
    uploaded: (d) => "Hochgeladen " + d.time,
    verifying: () => "Überprüfen",
    view: () => "Sicht",
    viewGuide: () => "Anleitung anzeigen",
    viewMode: {
      byAddress: () => "Von Wallet",
      byBlockchain: () => "Von Blockchain",
    },
    viewSBSInstructions: () => "Schritt-für-Schritt-Anleitung anzeigen",
    wentWrongAvailableImports: () =>
      "Beim Abrufen der verfügbaren Importe ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    wentWrongCurrentHoldings: () =>
      "Beim Abrufen der aktuellen Bestände ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    wentWrongDeleteFile: () =>
      "Beim Löschen der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    wentWrongDeletingCSV: (d) =>
      "Beim Löschen der CSV-Datei " + d.name + " ist ein Fehler aufgetreten.",
    wentWrongDownloadFile: () => "Datei konnte nicht heruntergeladen werden",
    wentWrongExistingSaved: () =>
      "Beim Anfordern vorhandener gespeicherter Importe ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut",
  },
  inTxCategoryOptionsTitle: () => "Eingehende Transaktionen",
  incomeToggles: () => "Optionen für Einkommenskategorien",
  info: () => "Information",
  instructions: () => "Anweisungen",
  integrationWarnings: {
    btc: {
      invalidWallet: {
        description: () =>
          "Wir empfehlen die Verwendung Ihrer xPub-, yPub- oder zPub-Adresse, da Bitcoin-Wallets mehrere Adressen unter einem Hauptkonto generieren",
        noTxs: () =>
          "Es wurden keine Transaktionen importiert, da die verwendete Wallet-Adresse keine xPub-, yPub- oder zPub-Adresse ist. Wir empfehlen, Ihre xPub-, yPub- oder zPub-Adresse zu importieren.",
        title: () => "Bitte geben Sie eine xPub-, yPub- oder zPub-Adresse ein",
        wrongFormat: () =>
          "Die verwendete Wallet-Adresse ist keine xPub-, yPub- oder zPub-Adresse. Wir empfehlen, Ihre xPub-, yPub- oder zPub-Adresse zu importieren, um sicherzustellen, dass alle Ihre Bitcoin-Transaktionen importiert werden.",
      },
    },
    default: {
      noTxs: () =>
        "Für dieses importierte Wallet wurden keine Transaktionen erkannt. Dies kann daran liegen, dass die Wallet-Adresse falsch eingegeben wurde oder dass das Wallet keine Transaktionen auf der ausgewählten Blockchain aufweist. Bitte überprüfen Sie Ihre Wallet-Details und die Blockchain-Auswahl.",
    },
  },
  invitation: {
    buttonText: {
      accepted: () => "Akzeptieren",
      declined: () => "Abfall",
      deleted: () => "Löschen",
      pending: () => "Einladen",
      revoked: () => "Zugriff widerrufen",
      revokedLink: () => "Zugriff widerrufen",
    },
    confirmationDialog: {
      collaborator: {
        accepted: {
          actionText: () => "Einladung annehmen",
          cancelText: () => "Zurück",
          text: () =>
            "Möchten Sie diese Einladung wirklich annehmen? Dadurch werden Sie als Teammitglied der Organisation des Buchhalters hinzugefügt und können dessen Kunden verwalten.",
          title: () => "Einladung annehmen?",
        },
        declined: {
          actionText: () => "Einladung ablehnen",
          cancelText: () => "Zurück",
          text: () =>
            "Möchten Sie diese Einladung wirklich ablehnen? Sie werden nicht als Teammitglied der Organisation des Buchhalters hinzugefügt.",
          title: () => "Einladung ablehnen?",
        },
        deleted: {
          actionText: () => "Löschen",
          cancelText: () => "Zurück",
          text: () => "Möchten Sie diese Einladung wirklich löschen?",
          title: () => "Einladung löschen?",
        },
        pending: {
          actionText: () => "Stornieren",
          cancelText: () => "Zurück",
          text: () => "Dies ist eine ausstehende Einladung",
          title: () => "Ausstehende Einladung",
        },
        revoked: {
          actionText: () => "Zugriff widerrufen",
          cancelText: () => "Zurück",
          confirmationPrompt: () =>
            "Geben Sie 'revoke-accountant-access-forever' ein, um den Zugriff zu widerrufen.",
          confirmationText: () => "revoke-accountant-access-forever",
          text: () =>
            "Sind Sie sicher, dass Sie den Zugriff auf das Profil Ihres Steuerberaters widerrufen möchten? Dies wird Ihre Möglichkeit entfernen, Daten einzusehen oder zu bearbeiten, die über den Datensatz Ihres Steuerberaters hochgeladen wurden. Selbst wenn Sie selbst Daten hochgeladen haben, kann das Eigentum immer noch bei Ihrem Steuerberater liegen.",
          title: () => "Zugriff widerrufen?",
        },
      },
      other: {
        accepted: {
          actionText: () => "Einladung annehmen",
          cancelText: () => "Zurück",
          text: () =>
            "Nachdem Sie die Einladung angenommen haben, können Sie wählen, ob Sie die von Ihrem Buchhalter hochgeladenen Daten oder die von Ihrem eigenen Konto hochgeladenen Daten anzeigen möchten. Wenn Ihr Buchhalter keine Ihrer Daten hochgeladen hat, empfehlen wir Ihnen, die Daten in diesem Konto anzuzeigen. Wenn Sie möchten, können Sie diesen Zugriff jederzeit widerrufen. Die Daten verbleiben jedoch bei Ihrem Buchhalter und Sie werden auf Ihre ursprünglichen Daten zurückgesetzt. Möchten Sie diese Einladung wirklich annehmen?",
          title: () => "Einladung annehmen?",
        },
        declined: {
          actionText: () => "Einladung ablehnen",
          cancelText: () => "Zurück",
          text: () => "Möchten Sie diese Einladung wirklich ablehnen?",
          title: () => "Einladung ablehnen?",
        },
        deleted: {
          actionText: () => "Löschen",
          cancelText: () => "Zurück",
          text: () => "Möchten Sie diese Einladung wirklich löschen?",
          title: () => "Einladung löschen?",
        },
        pending: {
          actionText: () => "Stornieren",
          cancelText: () => "Zurück",
          text: () => "Dies ist eine ausstehende Einladung",
          title: () => "Ausstehende Einladung",
        },
        revoked: {
          actionText: () => "Zugriff widerrufen",
          cancelText: () => "Zurück",
          text: () =>
            "Möchten Sie Ihren Zugriff auf das Profil Ihres Buchhalters wirklich widerrufen? Sie können die Daten, die Ihr Buchhalter in Ihrem Namen hochgeladen hat, nicht mehr lesen oder bearbeiten.",
          title: () => "Zugriff widerrufen?",
        },
      },
    },
    noInvites: () =>
      "Sie haben noch keine Einladungen von Buchhaltern erhalten",
    receivedInvitations: () => "Erhaltene Einladungen",
    warning: () =>
      "Etwas ist schiefgelaufen! Bitte wenden Sie sich an den Support, wenn das Problem weiterhin besteht",
    wentWrongGettingInvitations: () =>
      "Beim Abrufen der Einladungen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  },
  invitationStatus: {
    accepted: () => "Akzeptiert",
    declined: () => "Abgelehnt",
    deleted: () => "Gelöscht",
    notInvited: () => "Nicht eingeladen",
    pending: () => "Ausstehend",
    revoked: () => "Widerrufen",
    revokedLink: () => "Widerrufen",
  },
  invite: () => "Kunden einladen",
  keys: {
    deprecated: () => "Veraltet",
    deprecatedInfo: (d) =>
      d.exchange +
      " hat diese Authentifizierungsmethode verworfen. Wir unterstützen sie noch, empfehlen aber, Ihre Schlüssel gemäß den neuen Anweisungen zu aktualisieren. [Weitere Informationen]( " +
      d.linkTextKey +
      " )",
    deprecatedInfoByExchange: {
      coinbase: (d) =>
        d.exchange +
        " hat diese Authentifizierungsmethode verworfen. Bitte aktualisieren Sie Ihre Schlüssel gemäß den neuen Anweisungen. [Weitere Informationen]( " +
        d.linkTextKey +
        " )",
    },
    wentWrongDeleteExchangeAPI: (d) =>
      "Beim Löschen der API-Schlüssel für " +
      d.name +
      " ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    wentWrongDeletingAPI: () =>
      "Beim Löschen des API-Schlüssels ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    wentWrongSavingAPI: (d) =>
      "Beim Speichern " + d.name + " API-Schlüssel ist ein Fehler aufgetreten.",
    wentWrongUpdatingAPI: (d) =>
      "Beim Aktualisieren " +
      d.name +
      " -API-Schlüssel ist ein Fehler aufgetreten.",
  },
  labels: {
    addExistingLabels: () => "Vorhandene Labels hinzufügen",
    addLabel: () => "Etikett hinzufügen",
    cancel: () => "Stornieren",
    createNewLabel: () => "Neues Etikett erstellen",
    labelColor: () => "Etikettenfarbe",
    labelDescription: () => "Bezeichnung Beschreibung (optional)",
    labelName: () => "Etikettenname",
    noLabels: () => "Keine Labels erstellt",
    saveAndAdd: () => "Speichern und hinzufügen",
  },
  language: () => "Sprache",
  learnMore: () => "Weitere Informationen finden Sie im Hilfecenter",
  ledger: {
    banner: {
      text: () =>
        "Fehlende Kaufhistorie tritt auf, wenn mehr Krypto-Assets verkauft wurden, als ursprünglich gekauft wurden. Dies deutet darauf hin, dass einige Erwerbsaufzeichnungen möglicherweise fehlen, nicht kategorisiert oder falsch kategorisiert sind.",
      title: () => "dies ist das Ledger-Banner",
    },
    confirmations: {
      deleteTransactionConfirmation: {
        text: () =>
          "Achtung! Diese Aktion kann nicht rückgängig gemacht werden. Möchten Sie diese Transaktion wirklich löschen?",
        title: () => "Transaktion löschen",
      },
      ignoreTransactionConfirmation: {
        text: () =>
          "Möchten Sie diese Transaktion wirklich ignorieren? Wenn Sie diese Aktion ausführen, wird diese Transaktion bei allen Steuerberechnungen ignoriert. Ignorierte Transaktionen können in den erweiterten Filtern aus der Transaktionstabelle ausgeblendet werden.",
        title: () => "Transaktion ignorieren",
      },
    },
    deposit: () => "Kaution",
    descriptions: {
      amountAcquired: (d) =>
        "Die Gesamtmenge an " +
        d.ticker +
        " die Sie erworben und in der App aufgezeichnet haben.",
      amountDisposed: (d) =>
        "Die Gesamtmenge an " +
        d.ticker +
        " die Sie verkauft oder veräußert haben, wie in der App aufgezeichnet.",
      amountMissing: (d) =>
        "Die Menge an " +
        d.ticker +
        ", die in Ihren Transaktionen nicht berücksichtigt wurden. Dies kann auf nicht kategorisierte Transaktionen oder fehlende Daten zurückzuführen sein.",
      amountUncategorised: (d) =>
        "Anzahl der " +
        d.ticker +
        " die noch nicht kategorisiert wurden. Für genaue Steuerberichte und -berechnungen ordnen Sie diesen Transaktionen bitte Kategorien zu.",
    },
    errors: {
      unableLoadRecon: () => "Hauptbuch kann nicht geladen werden",
    },
    filters: {
      onlyShowNegativeBalance: () => "Nur fehlende Kaufhistorie anzeigen",
    },
    goBack: () => "Geh zurück",
    headings: {
      amountNegative: () => "Negativer Betrag:",
      holdings: () => "Beteiligungen:",
      taxImpact: () => "Steuerliche Auswirkungen:",
      transactions: () => "Transaktionen:",
      value: () => "Wert:",
    },
    headings2: {
      amountAcquired: () => "Gesamter erworbener Betrag",
      amountDisposed: () => "Gesamtmenge entsorgt",
      amountMissing: () => "Fehlender Betrag",
      amountUncategorised: () => "Betrag Nicht kategorisiert",
      transactions: () => "Transaktionen",
    },
    ledger: () => "Hauptbuch",
    ledgerSourceFilter: {
      accounts: () => "Konten",
      noOptions: () => "Keine Optionen",
      placeholder: () => "Alle Konten anzeigen",
      selectAll: () => "Alles auswählen",
    },
    loading: () => "Laden",
    negativeBalanceOverallTooltip: () =>
      "Für diesen Vermögenswert fehlt die Kaufhistorie.",
    negativeBalanceTooltip: (d) =>
      "Es gab mehr " +
      d.ticker +
      " -Verkaufstransaktionen als Käufe. Überprüfen Sie eingehende Transaktionen.",
    newest: () => "Neueste",
    notFound: () => "Nicht gefunden",
    oldest: () => "Älteste",
    pageTitle: (d) => d.currencyName + " -Hauptbuch",
    tableHeader: {
      account: () => "Konto",
      amount: () => "Menge",
      dateAndType: () => "Datum und Typ",
      overallBalance: () => "Gesamtbilanz",
      tooltips: {
        account: () =>
          "Wallets, Börsen, importierte Daten oder Ähnliches und der Kontostand dieses Kontos unmittelbar nach der Transaktion.",
        overallBalance: () =>
          "Der Gesamtsaldo aller Ihrer Konten unmittelbar nach der Transaktion.",
      },
    },
    uncategorisedTooltip: () =>
      "Sie müssen diese Transaktion kategorisieren, damit sie zum Hauptbuchsaldo zählt.",
    unmatchedTooltip: () =>
      "Diese Transaktion wird nicht zum Bilanzsaldo hinzugerechnet, da sie einseitig ist.",
    withdrawal: () => "Rückzug",
  },
  legacyPlanTooltip: () =>
    "Sie nutzen einen Legacy-Plan mit 3000 Transaktionen",
  manual: {
    Date: () => "Datum",
    addNewTransaction: () => "Neue Transaktion hinzufügen",
    addTransaction: () => "Transaktion hinzufügen",
    currency: () => "Währung",
    feeCurrency: () => "Gebührenwährung",
    feeQuantity: () => "Gebührenmenge",
    from: () => "Aus",
    price: () => "Preis",
    quantity: () => "Menge",
    quoteCurrency: () => "Kurswährung",
    quoteQuantity: () => "Angebotsmenge",
    receiveCurrency: () => "Währung kaufen",
    receiveQuantity: () => "Menge kaufen",
    requiredText: {
      currency: () => "Sie müssen eine Währung eingeben",
      feeCurrency: () => "Sie müssen eine Gebührenwährung eingeben",
      feeQuantity: () => "Sie müssen einen Gebührenbetrag eingeben",
      notInLockedPeriod: () => "Das Datum darf nicht im Sperrzeitraum liegen",
      price: () => "Sie müssen einen Preis eingeben",
      quantity: () => "Sie müssen eine Menge eingeben",
      time: (d) =>
        "Sie müssen eine gültige Zeit im Format " + d.timeFormat + " eingeben",
      value: () => "Sie müssen einen Wert eingeben",
    },
    sellCurrency: () => "Währung verkaufen",
    sellQuantity: () => "Verkaufsmenge",
    stepTitle: {
      category: () => "Transaktionskategorie",
      details: (d) =>
        select(d.category, {
          trade: " Handelsdetails ",
          transfer: " Überweisungsdetails ",
          other: " Transaktionsdetails ",
        }),
      fees: () => "Gebühren",
      sourceAndDest: () => "Quelle & Ziel",
    },
    time: () => "Zeit",
    to: () => "Zu",
    trade: () => "Kategorie",
    transactionType: {
      other: () => "Andere",
      trade: () => "Handel",
      transfer: () => "Überweisen",
    },
    transactionTypeDescription: {
      other: () => "Alle anderen Transaktionen",
      trade: () => "Hier verkaufen Sie eine Währung gegen eine andere",
      transfer: () =>
        "Hier transferieren Sie zwischen Ihren eigenen Wallets/Börsen",
    },
    value: () => "Wert",
  },
  manualCSV: {
    addCustomCSV: () => "Benutzerdefinierte CSV hinzufügen",
    advanced: {
      link: () =>
        "https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-manual-csv-import/",
      part1: () =>
        "Für fortgeschrittene Trades, bei denen die Gebühren noch mit den Handelsdaten angewendet werden müssen, siehe unsere ",
      part2: () => "Erweiterter CSV-Leitfaden.",
    },
    advancedOptions: () => "Erweiterte Optionen",
    cryptoGroup: () => "Krypto",
    dateFormatHelper: () =>
      "Wählen Sie das Datumsformat aus, das in Ihrer CSV-Datei angezeigt wird. Wenn Sie keine Angaben machen, wird standardmäßig das Datumsformat JJJJ/MM/TT HH:mm:ss verwendet.",
    example: () => "Laden Sie ein Beispiel für das CSV-Format herunter",
    exchange: () => "Austausch",
    exchangeRule: () => "Sie müssen einen Austausch eingeben",
    fiatGroup: () => "Fiat",
    here: () => "Hier",
    importFromDateHelper: () =>
      "Wählen Sie das Datum aus, ab dem Sie mit dem Import von Transaktionen beginnen möchten. Wenn nicht angegeben, werden alle Transaktionen importiert.",
    importHelper: {
      helpText: () => "Benötigen Sie Hilfe beim Datenimport?",
      link: () =>
        "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
    },
    manualGroup: () => "Handbuch",
    manualSelectCurrencies: () => "Währungen manuell zuweisen",
    manualSelectDateFormat: () => "Datumsformat manuell auswählen",
    manualSelectImportFromDate: () => "Importdatum manuell auswählen",
    manualSelectTimezone: () => "Zeitzone manuell auswählen",
    nameExists: () =>
      "Der Name existiert bereits. Um ihn in diese Börse hochzuladen, wählen Sie ihn bitte auf der Importseite aus.",
    nameTooLong: () => "Name zu lang",
    noOptions: () => "Keine Optionen",
    patternRule: () => "Nur alphanumerische Zeichen und Leerzeichen erlaubt",
    pendingUploadMessage: {
      manyFiles: (d) => d.numFiles + " hochzuladende Dateien",
      singleFile: (d) => "1 Datei ( " + d.filename + " ) zum Hochladen",
    },
    preparationWarning: () =>
      "CSV-Daten müssen vor dem Hochladen vorbereitet werden",
    selectCurrencies: () => "Währungen auswählen",
    selectDateFormat: () => "Datumsformat auswählen",
    simple: {
      link: () => "https://cryptotaxcalculator.io/advanced.csv",
      part1: () => "Laden Sie eine Beispiel-CSV herunter",
      part2: () => " um ein Beispiel des erforderlichen Formats anzuzeigen.",
    },
    tickerHelper: () =>
      "Wählen Sie die Ticker aus, die in Ihrer CSV-Datei erscheinen und die Sie einer bestimmten Währung zuordnen möchten. Wenn Sie beispielsweise den Ticker „BTC“ „Bitcoin“ zuordnen möchten, wählen Sie „Bitcoin (BTC)“ im Dropdown-Menü unten. Alle nicht angegebenen Ticker werden, soweit möglich, nach ihrem Marktkapitalisierungsranking zugeordnet.",
    timezoneHelper: () =>
      "Wählen Sie die Zeitzone aus, in der die Transaktionen stattgefunden haben. Wenn Sie keine Angabe machen, wird die Zeitzone automatisch erkannt.",
    title: () => "Daten per CSV importieren",
    uploadFileWarning: () => "Bitte laden Sie eine CSV-Datei hoch",
  },
  markAsReviewed: () => "Als überprüft markieren",
  markAsUnreviewed: () => "Als nicht überprüft markieren",
  mobileDemandTest: {
    buttonText: () => "App herunterladen",
    chip: () => "Für BETA registrieren",
    description: () =>
      "Registrieren Sie Ihr Interesse und gehören Sie zu den Ersten, die frühzeitigen Zugang zur CTC App erhalten – damit wird die Krypto-Steuerberechnung einfacher als je zuvor.",
    noThanks: () => "Nein danke",
    registerInterest: () => "Interesse anmelden",
    title: () => "Gehören Sie zu den Ersten, die die App erhalten",
    toast: {
      description: () => "Behalten Sie Ihren Posteingang im Auge",
      title: () => "Sie haben Interesse angemeldet",
    },
  },
  mockReport: {
    disabled: () => "Für Testdaten deaktiviert",
    infoBanner: {
      button: () => "Beispieldaten anzeigen",
      subtext: () =>
        "Sie können Testdaten anzeigen, bevor Sie einen Preisplan auswählen.",
      title: () => "Beispieldaten anzeigen",
    },
    signup: {
      button: (d) =>
        select(d.isAccountant, {
          true: " Upgrade-Client ",
          other: " Erste Schritte ",
        }),
      errorMessage: () =>
        "Beim Upgrade des Clients ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      subtext: (d) =>
        select(d.isAccountant, {
          true: select(d.txCount, {
            0: "Dieser Kunde hat derzeit keinen kostenpflichtigen Plan. Um einen Steuerbericht zu erstellen, müssen Sie einen Plan erwerben 👉",
            other:
              "Dieser Kunde hat derzeit " +
              d.txCount +
              " importierte " +
              plural(d.txCount, 0, de, {
                1: "transaction",
                other: "transactions",
              }) +
              ". Um einen Steuerbericht zu erstellen, müssen Sie diesen Kunden auf den Plan " +
              d.suggestedPlan +
              " upgraden 👉",
          }),
          other:
            "Wählen Sie einen unserer Pläne aus, um Ihre Daten anzuzeigen und Steuerberichte herunterzuladen.",
        }),
      title: (d) =>
        select(d.isAccountant, {
          true:
            " Möchten Sie einen Steuerbericht für " +
            d.clientName +
            " erstellen? ",
          other: " Erstellen Sie Ihren Steuerbericht ",
        }),
    },
    testData: () => "Testdaten",
  },
  na: () => "N / A",
  name: () => "Name",
  nav: {
    billing: () => "Abrechnung",
    contacts: () => "Kontakte",
    developer: () => "Entwickler",
    imports: () => "Exchange-Daten importieren",
    logout: () => "Ausloggen",
    manageSubscription: () => "Abonnement verwalten",
    manual: () => "Manuelles CSV importieren",
    menu: () => "Speisekarte",
    plans: () => "Mitgliederschaft",
    report: () => "Steuerbericht anzeigen",
    settings: () => "Einstellungen",
    transactions: () => "Transaktionen überprüfen",
    wallet: () => "Wallet-Daten importieren",
    warnings: () => "Warnhinweise",
  },
  navbar: {
    accountingIntegrations: () => "Buchhaltungsintegrationen",
    addressBook: () => "Adressbuch",
    clientSettings: () => "Clienteinstellungen",
    clients: () => "Kunden",
    cryptoIntegrations: () => "Krypto-Integrationen",
    dashboard: () => "Armaturenbrett",
    followUsOnX: () => "Folgen Sie uns auf X",
    footer: {
      buttonCta: () => "Steuerersparnisse freischalten",
      referralXOffAllPlans: (d) =>
        "Empfehlung " + d.discount + "% Rabatt auf alle Tarife",
      xPercentOffAllPlans: (d) => d.discount + "% Rabatt auf alle Tarife",
      youHaveXTransactions: (d) => "Sie haben " + d.count + " Transaktionen",
    },
    help: () => "Zum Hilfecenter",
    helpCenter: () => "Hilfe-Center",
    imports: () => "Importe",
    integrations: () => "Konten",
    more: () => "Mehr",
    myClients: () => "Meine Kunden",
    mySettings: () => "Arbeitsbereichseinstellungen",
    myTeam: () => "Mein Team",
    portfolio: () => "Portfolio",
    reconciliation: () => "Versöhnung",
    referAFriend: () => "Empfehlen Sie einen Freund",
    referFriends: () => "Laden Sie einen Freund ein - Erhalten Sie 40 €",
    referFriendsV2: (d) => "Empfehlen & " + d.reward + " erhalten",
    referGetHundredCash: (d) => d.reward + " Bargeld erhalten",
    report: () => "Berichte",
    review: () => "Rezension",
    rules: () => "Regeln",
    selectClient: () => "Kunden auswählen",
    settings: () => "Einstellungen",
    subheadings: {
      integrations: () => "Konten",
      reconciliation: () => "Versöhnung",
      reports: () => "Berichte",
    },
    subscribe: () => "Abonnieren",
    support: () => "Chat mit dem Support",
    tips: () => "Tipps",
    title: () => "CTC",
    tooltip: (d) =>
      "[Abonnieren Sie]( " +
      d.linkTextKey +
      " ), um Tipps zum Sparen bei Ihrer Steuererklärung anzuzeigen.",
    tooltipReview: (d) =>
      "[Abonnieren Sie]( " +
      d.linkTextKey +
      " ), um Ihre Transaktionen zu überprüfen und bei Ihrer Steuererklärung zu sparen",
    transactionSubItems: {
      failed: () => "Fehlgeschlagen",
      notReadyToSync: () => "Nicht bereit zur Synchronisierung",
      outOfDate: () => "Veraltet",
      readyToSync: () => "Bereit zur Synchronisierung",
      synced: () => "Synchronisiert",
    },
    transactions: () => "Transaktionen",
  },
  new: () => "Neu",
  newTxModal: {
    actionButton: () => "Hör zu!",
    back: () => "Zurück",
    description: {
      0: () =>
        "Die Transaktionstabelle wurde verbessert, um ein schnelles und einfaches Verständnis der Daten zu ermöglichen",
      1: () =>
        "Wenn Sie eine Transaktion kategorisieren, identifizieren wir jetzt ähnliche Transaktionen, um sie in großen Mengen anzuwenden, sodass Sie sich nicht wiederholen müssen",
      2: () =>
        "Wir haben die Abstimmungsseite durch eine neue, optimierte Version namens „Tipps“ ersetzt, die für aktive Abonnenten verfügbar ist.",
      3: () =>
        "Unser Automatisierungsalgorithmus wurde aktualisiert, sodass er nun komplexe On-Chain-Transaktionen intelligenter kategorisieren kann",
    },
    nextPage: () => "Nächste",
    title: () => "Neue Transaktionstabelle und mehr!",
  },
  next: () => "Nächste",
  no: () => "NEIN",
  noResults: () => "Keine Ergebnisse",
  none: () => "Keiner",
  notifications: {
    missingBlockchain: {
      blogLink: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6388387-how-to-resolve-missing-blockchain-warnings",
      cta: () => "Lösen",
      link: () => "/Transaktionen?Warnung=fehltBlockchain",
      primary: () => "Fehlende Blockchain",
      secondary: () =>
        "Überprüfen Sie Transaktionen, bei denen keine Blockchain gefunden wurde.",
    },
    missingPrice: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/missing-market-prices",
      cta: () => "Lösen",
      link: () => "/transactions?warning=missingPrice",
      primary: () => "Fehlende Marktpreise",
      secondary: () =>
        "Überprüfen und aktualisieren Sie Transaktionen, für die kein Marktpreis gefunden wurde.",
    },
    negativeBalance: {
      blogLink: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
      cta: () => "Lösen",
      link: () => "/transactions?warning=negativeBalance",
      primary: () => "Fehlende Kaufhistorie",
      secondary: () =>
        "Überprüfen Sie Transaktionen, die dazu führen, dass Ihr Saldo ins Minus gerät.",
    },
    uncategorised: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/cryptocurrency-tax-categories",
      cta: () => "Lösen",
      link: () => "/transactions?warning=uncategorised",
      primary: () => "Nicht kategorisierte Transaktionen",
      secondary: () =>
        "Ihre Transaktionen wurden neu kategorisiert, um die Genauigkeit des Rechners zu verbessern.",
    },
    zeroCostBuy: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/negative-balance-remaining-warnings",
      blogLink2: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
      cta: () => "Lösen",
      link: () => "/transactions?warning=negativeBalance",
      primary: () => "Kostenlose Käufe",
      secondary: () =>
        "Überprüfen Sie Verkaufstransaktionen, bei denen Ihre Kostenbasis aufgrund einer nicht geklärten vorherigen Kaufhistorie Null beträgt. Dies kann dazu führen, dass die gemeldeten Gewinne höher ausfallen als sie sein sollten.",
    },
  },
  onboardingV2: {
    LetsReviewTx: {
      body1: () => "Sollte dies nicht automatisiert werden, sagen Sie?",
      body2: () =>
        "Ja, die meisten Ihrer Transaktionen werden automatisch kategorisiert, in manchen Fällen kann die Kategorie jedoch nicht bestimmt werden. Um die Genauigkeit zu gewährleisten, kennzeichnen wir diese Fälle zur Klärung.",
      cta: () => "Weitermachen",
      title: () => "OK, lassen Sie uns einige Ihrer Transaktionen überprüfen",
    },
    accountConfirmation: {
      CTA: () => "Import fortsetzen",
      nonCTA: () => "Weiter zum nächsten Schritt",
      subtitle: () =>
        "Um sicherzustellen, dass die Zahlen korrekt sind, ist ein vollständiger Transaktionsverlauf erforderlich.",
      title: (d) =>
        "Sie haben " +
        plural(d.count, 0, de, {
          one: " nur 1 Konto hinzugefügt ",
          other: " " + number("de", d.count, 0) + " Konten hinzugefügt ",
        }) +
        " !",
      title2: () => "Sind Sie sicher, dass Sie alles importiert haben?",
    },
    accountCountry: {
      plusMore: (d) => "+ " + d.count + " mehr",
      showLess: () => "Weniger anzeigen",
      showMore: () => "Mehr anzeigen",
      subtitle: () =>
        "Dadurch werden die Standardsteuerregeln und die lokale Währung bestimmt.",
      title: () => "Wählen Sie Ihre Steuerjurisdiktion",
    },
    accountList: {
      addAnotherAccount: () => "Ein weiteres Konto hinzufügen",
      yourAccounts: () => "Ihre Konten",
    },
    accountTheme: {
      footerButton: () => "Nächste",
      subtitle: () => "Sie können es später immer noch ändern.",
      themes: {
        dark: () => "Dunkler Modus",
        light: () => "Lichtmodus",
        system: () => "Systemmodus",
      },
      title: () => "Welches Thema bevorzugen Sie?",
    },
    accountType: {
      footerButton: () => "Nächste",
      securityCta: () => "Datensicherheit und Datenschutz",
      subtitle: () => "Welcher Benutzertyp sind Sie?",
      title: () => "Willkommen beim Krypto-Steuerrechner 👋",
      types: {
        accountant: () => "Buchhalter",
        individual: () => "Person",
      },
    },
    accuracy: {
      content: {
        accounts: () => "Konten",
        categorise: () => "Kategorisieren Sie Ihre Transaktionen",
        complete: () =>
          "Sie sollten einen vollständigen Verlauf aller Ihrer Transaktionen angeben. Dadurch kann unser Steuerberechnungsalgorithmus Transaktionen auf Ihren Konten automatisch abgleichen, wodurch Ihr Arbeitsaufwand minimiert wird und sichergestellt wird, dass die berechneten Gewinne korrekt sind.",
        considerations: () => "Wichtige Überlegungen:",
        costBase: () => "Kostenbasis",
        determine: () => "Um Ihre",
        ensure: () =>
          "Die meisten Transaktionen werden automatisch kategorisiert. In manchen Fällen kann die richtige Kategorie jedoch nicht bestimmt werden. Durch die Kategorisierung Ihrer Transaktionen wird sichergestellt, dass das richtige Steuerergebnis angewendet wird.",
        import: () => "Importieren Sie alle Ihre ",
        inactive: () => " sogar solche, die Sie nicht mehr aktiv verwenden.",
        noAccess: () =>
          "Beachten Sie, dass CTC niemals Zugriff auf Benutzergelder hat. Importe sind streng auf Transaktionsinformationen beschränkt, die zur Berechnung von Steuern erforderlich sind.",
      },
      footerBack: () => "Zurück",
      footerNext: () => "Nächste",
      subtitle: () => "Erstellen genauer Steuerberichte",
      title: () => "So erhalten Sie genaue Steuerberichte",
    },
    areYouSure: {
      body1: {
        costBasis: () => "Kostenbasis",
        costBasisTooltip: () =>
          "Der ursprüngliche Preis, den Sie für den Vermögenswert bezahlt haben, zuzüglich Transaktionsgebühren",
        frontPart: () =>
          "Eine vollständige Historie Ihrer Transaktionen verbessert die Genauigkeit der Zahlen erheblich. So können Sie Ihre ",
        lastPart: () => " über alle Ihre Vermögenswerte.",
      },
      body2: {
        accounts: () => "Konten,",
        accountsTooltip: () =>
          "Wallets und Börsen, die Sie für Kryptowährungstransaktionen verwenden.",
        frontPart: () =>
          "Dies ist insbesondere wichtig bei der Übertragung von Vermögenswerten zwischen ",
        lastPart: () =>
          " zum Beispiel beim Senden von Kryptowährungen von einer Börse an eine andere Wallet. Wenn diese Börse nicht importiert wurde, können wir die Kostenbasis für diesen Vermögenswert nicht ermitteln.",
      },
      buttons: {
        doneImport: () => "Ich bin sicher, dass ich alles importiert habe",
        importMore: () => "Lassen Sie mich noch ein paar importieren",
      },
      footer: () =>
        "Sie können später immer noch weitere Wallets hinzufügen, aber wir empfehlen, dies jetzt zu tun, um ein reibungsloses Erlebnis zu gewährleisten",
      title: () =>
        "Sind Sie sicher, dass Sie alle Ihre Konten importiert haben?",
    },
    calculating: {
      autoSaved: () => "Der Fortschritt wird automatisch gespeichert",
      buttons: {
        continue: () => "Weitermachen",
        finish: () => "Beenden",
        goBack: () => "Geh zurück",
        importMore: () => "Mehr importieren",
        skip: () => "App eingeben",
      },
      loading10Minutes: () =>
        "Wow, es sieht so aus, als hätten Sie viele Transaktionen! Dies kann einige Zeit dauern, aber wir kümmern uns darum. Wir senden Ihnen eine E-Mail, sobald der Import abgeschlossen ist.",
      loading3Minutes: () =>
        "Das Importieren Ihrer Transaktionen kann einige Zeit dauern. Fahren Sie in der Zwischenzeit mit der Einrichtung Ihres Kontos fort.",
      loadingETA: () => "Dies dauert normalerweise 2 bis 3 Minuten",
      loadingETAFake: () => "Das wird nicht lange dauern",
      loadingTakingLongerThanUsual: () =>
        "Das dauert länger als sonst. Ich bin immer noch am Zahlenrechnen.",
      loadingText: {
        calculatingTheCostBaseForEachAsset: () =>
          "Berechnung der Kostenbasis für jedes Anlagegut",
        categorisingYourTransactions: () =>
          "Kategorisieren Sie Ihre Transaktionen",
        categorizingBridges: () => "Bridge erkennen",
        categorizingStaking: () => "Erkennen von Staking",
        categorizingSwaps: () => "Erkennen von Swaps",
        categorizingTrades: () => "Erkennen von Trades",
        categorizingTransfers: () => "Übertragungen erkennen",
        computingGainsForEachTransaction: () =>
          "Berechnung der Gewinne für jede Transaktion",
        detectingAnyCryptoIncome: () => "Erkennen jeglicher Krypto-Einnahmen",
        finalizingInitialTaxReports: () =>
          "Fertigstellung der ersten Steuerberichte",
        importingTransactionData: () => "Transaktionsdaten importieren",
        summarizingCapitalGainByTaxYear: () =>
          "Zusammenfassung der Kapitalgewinne nach Steuerjahr",
      },
      titleCalculationsComplete: () => "Berechnungen abgeschlossen!",
    },
    cexImportReminder: {
      bodyText: () =>
        "Es ist wichtig, alle Ihre Wallets zu importieren, einschließlich aller von Ihnen verwendeten zentralisierten Börsen. Wenn wir nicht alle Ihre Handelsaktivitäten haben, sind die Zahlen nicht korrekt.",
      modalText: () =>
        "Einige Börsen, die Sie möglicherweise verwendet haben und importieren müssen, sind beispielsweise:",
      primaryButton: () => "Importieren einer Börse",
      secondaryButton: () => "Fortfahren ohne Importieren",
      title: () =>
        "Vergessen Sie nicht, Ihre zentralisierten Börsen zu importieren",
    },
    coinbaseOauth: {
      import: {
        connectCoinbase: () => "Coinbase verbinden",
        continue: () => "Weitermachen",
        importedSubtitle: () =>
          "Fahren Sie mit der Einrichtung Ihres Kontos fort.",
        importedTitle: () => "Coinbase-Transaktionen importiert",
        importingBanner: () =>
          "Das Importieren Ihrer Coinbase-Transaktionen kann einige Zeit dauern. Warten Sie,",
        importingBannerLink: () =>
          "Fahren Sie mit der Einrichtung Ihres Kontos fort",
        importingSubtitle: () =>
          "Lassen Sie uns in der Zwischenzeit mit der Einrichtung Ihres Kontos fortfahren.",
        importingTitle: () => "Wir importieren Ihre Transaktionen",
        skipConnectCoinbase: () => "Sie haben kein Coinbase-Konto?",
        skipConnectCoinbaseCTA: () => "Weiter ohne Verbindung mit Coinbase",
        subtitle: () => "Lassen Sie uns Ihr Konto verbinden",
        title: () => "Verbinden Sie Ihr Coinbase-Konto",
      },
      welcomeTitle: () => "Willkommen, Coinbase-Benutzer 👋",
    },
    competitorSurvey: {
      options: {
        no: () => "NEIN",
        yes: () => "Ja",
      },
      title: () =>
        "Haben Sie zuvor eine andere Krypto-Steuersoftware verwendet?",
    },
    example: () => "Beispiel",
    import: {
      autoImported: () => "Automatisch importiert",
      footer: () => "+ über 2.900 weitere Integrationen.",
      footerCTA: () => "Suchen Sie nach Ihrem",
      hasSavedAccounts: {
        finishImport: () => "Ich habe alle meine Konten hinzugefügt",
        keepAdding: () => "Fügen Sie weiterhin Ihre Konten hinzu",
        or: () => "oder",
        subTitle: () =>
          "Stellen Sie sicher, dass Sie alle Ihre Börsen und Wallets hinzufügen, damit die Zahlen stimmen!",
        title: () => "👉 Fügen Sie Ihr nächstes Konto hinzu",
      },
      importManualCSV: () => "Manuelles CSV importieren",
      navBar: {
        importType: {
          api: () => "API",
          "bulk-wallet": () => "Bulk Wallet",
          "connect-wallet": () => "Wallet verbinden",
          csv: () => "CSV",
          oauth: () => "Verbinden",
          "soft-wallet": () => "Verbinden",
          wallet: () => "API",
        },
      },
      searchBar: {
        importWallet: () => "Brieftasche importieren",
        placeholder: () =>
          "Suchen Sie nach Ihrer Börse oder fügen Sie Ihr Wallet ein",
        section: {
          allOthers: () => "Alle Börsen und Wallets",
          manual: () => "Handbuch",
          popular: () => "Beliebt",
        },
      },
      subTitle: () =>
        "Suchen Sie nach Ihrer Börse oder fügen Sie Ihre Wallet-Adresse ein 👇 ",
      title: () => "Fügen Sie Ihr erstes Konto hinzu",
    },
    metamask: {
      accurateCpaEndorsed: () =>
        "Präzise, vom CPA (Certified Public Accountant) geprüfte Steuerberichte, ohne Rätselraten",
      accurateCryptoTaxes: () => "Präzise Krypto-Steuern.\nKeine Schätzungen.",
      continueWith: (d) => "Mit " + d.provider + " fortfahren",
      cryptoTaxCalculator: () => "Krypto-Steuerrechner",
      cta: () => "Weitermachen",
      disclaimer: () =>
        "*Nur für neue Kunden des Crypto Tax Calculator. Der Rabatt gilt nur für das erste Jahr.",
      fullSupportIRS: () =>
        "Vollständige Unterstützung der IRS-Regeln. Importieren Sie direkt in TurboTax oder H&R Block oder geben Sie die Daten sicher an Ihren Buchhalter weiter.",
      manageCryptoTaxes: () =>
        "Verwalten Sie Ihre Krypto-Steuern direkt von Ihrem Handelsplatz aus.",
      newCustomersDiscount: () => "Neukunden erhalten 30% Rabatt*",
      partnerAlt: () => "Offizieller Partner CryptoTaxCalculator x Metamask",
      previewAlt: () => "Tax Hub-Vorschau",
      skip: () => "Oder E-Mail und Passwort verwenden",
      supportsAllWallets: () =>
        "Unterstützt alle Ihre Wallets, Börsen und Onchain-Aktivitäten.",
      taxhubPoweredBy: () => "Tax Hub unterstützt von",
    },
    onMobileBanner: {
      title: () =>
        "Sieht aus, als ob Sie mobil sind. Für das optimale Erlebnis melden Sie sich von Ihrem Desktop aus an!",
    },
    onYourWay: {
      title: () => "Schön, Sie sind auf dem Weg zu Ihrer Steuererklärung!",
      viewPlans: () => "Pläne anzeigen",
      viewReports: () => "Berichte anzeigen",
    },
    platformTypes: {
      cex: () => "Zentralisierter Krypto-Austausch",
      count: (d) => "+ " + d.count + " mehr",
      defi: () => "On-Chain-Aktivität (DeFi & NFTs)",
      footerBack: () => "Zurück",
      footerNext: () => "Nächste",
      footerSkip: () => "Überspringen",
      subtitle: () =>
        "Personalisieren Sie Ihre Erfahrung basierend auf der Art des Krypto-Händlers, der Sie sind:",
      title: () => "Auf welchen Arten von Plattformen haben Sie gehandelt?",
    },
    progressBar: {
      confirmCountry: () => "Land bestätigen",
      freeTaxPreview: () => "Kostenlose Steuervorschau",
      importData: () => "Daten importieren",
      selectIntegration: () => "Integration auswählen",
    },
    provider: {
      connect: {
        "2fa": () => "Geben Sie Ihren 2FA-Code ein",
        cta: () => "Einloggen und Konto verknüpfen",
        disclaimer: (d) =>
          "Durch die Anmeldung verknüpfen Sie Ihr " +
          d.provider +
          "-Konto mit Crypto Tax Calculator, was Ihnen ermöglicht, sich mit einem Klick von Independent Reserve aus einzuloggen.",
        email: () => "E-Mail",
        password: () => "Passwort",
        subtitle: () =>
          "Sieht so aus, als hätten Sie unter dieser E-Mail bereits ein Crypto Tax Calculator-Konto.",
        title: () => "Melden Sie sich an, um fortzufahren",
      },
      setPassword: {
        cta: () => "Passwort speichern",
        email: () => "E-Mail",
        password: () => "Passwort",
        passwordsDoNotMatch: () => "Passwörter stimmen nicht überein",
        retypePassword: () => "Passwort wiederholen",
        title: () => "Legen Sie Ihr Passwort fest",
      },
      setPasswordSuccess: {
        cta: () => "Weitermachen",
        description: () =>
          "Verwenden Sie dieses Passwort, um sich bei cryptotaxcalculator.io anzumelden",
        providerLoginNote: (d) =>
          "Hinweis: Sie können sich auch jederzeit mit 1 Klick von Ihrem " +
          d.provider +
          " -Konto aus anmelden.",
        title: () => "Passwort festgelegt!",
      },
      syncing: {
        bannerCTA: () => "Weiter mit der Einrichtung Ihres Kontos",
        bannerText: (d) =>
          "Das Importieren Ihrer " +
          d.provider +
          " -Transaktionen kann einige Zeit in Anspruch nehmen. Warten Sie,",
        completeTitle: () => "Importierte Transaktionen",
        getTaxReportCta: () => "Steuerbericht abrufen",
        inProgressTitle: () => "Wir importieren Ihre Transaktionen",
        viewTaxSummaryCta: () => "Steuerübersicht anzeigen",
      },
      welcome: {
        cta: () => "Weitermachen",
        description: () =>
          "Crypto Tax Calculator ist eine Komplettplattform zur Berechnung Ihrer Kryptosteuern. Wir erstellen Steuerberichte, die Folgendes beinhalten:",
        items: {
          0: () => "Langfristige Gewinne",
          1: () => "Kurzfristige Gewinne",
          2: () => "Einkommen",
          3: () => "Kosten",
          4: () => "Abzüge",
        },
        title: () => "Willkommen beim Krypto-Steuerrechner",
      },
    },
    security: {
      buttonText: () => "Datensicherheit und Datenschutz",
      privacy: {
        description: () =>
          "Wir werden Sie niemals nach den privaten Schlüsseln Ihrer Krypto-Wallets fragen. Unsere Integrationen erfordern nur Lesezugriff auf Ihre Daten und alle API-Schlüssel werden während der Übertragung und im Ruhezustand sicher gespeichert und verschlüsselt.",
        title: () => "Vollständiger Datenschutz",
      },
      soc2: {
        description: () =>
          "Wir sind nach SOC 2 Typ 2 zertifiziert und verfügen über sichere Mechanismen, um die Sicherheit Ihrer Daten zu gewährleisten.",
        title: () => "SOC 2 Typ 2 zertifiziert",
      },
      subtitle: () => "Wir nehmen die Datensicherheit sehr ernst",
      title: () => "Ihre Daten sind sicher",
    },
    skipAndEnterApp: () => "App überspringen und öffnen",
    taxCalculator: {
      buttons: {
        addAnotherAccount: () => "Ein weiteres Konto hinzufügen",
        back: () => "Zurück",
        getTaxReport: () => "Steuerbericht abrufen",
      },
      disclaimer: () =>
        "Dies ist eine Schätzung basierend auf den bisher importierten Daten. Für ein genaues Ergebnis ist ein vollständiges Bild aller Ihrer Transaktionen seit Ihrem ersten Krypto-Kauf erforderlich",
      estimatedGain: () => "Geschätzter Gewinn",
      heading: () => "Schätzung der Kapitalgewinne",
      income: () => "Einkommen",
      tooltips: {
        estimatedGain: () =>
          "Der geschätzte Gewinn stellt Ihren Gesamtertrag aus Kryptowährungen dar und wird als Kapitalgewinn + Einkommen – Kapitalverluste berechnet.",
        gains: () =>
          "Der Gewinn, den Sie aus Geschäften gemacht haben, die zu einem Gewinn geführt haben. (Geschäfte, mit denen Sie Geld verdient haben)",
        income: () =>
          "Einnahmen aus Kryptoaktivitäten, in der Regel aus Staking-Belohnungen",
        losses: () =>
          "Die Verluste, die Sie durch Trades gemacht haben. (Trades, bei denen Sie Geld verloren haben)",
        txCount: () => "Die Anzahl der Transaktionen auf Ihren Konten",
      },
      tradingGains: () => "Handelsgewinne",
      tradingLosses: () => "Handelsverluste",
      transactions: (d) => d.count + " Transaktionen",
    },
    taxableStaking: {
      content: {
        added: () => "Die 98,34 müssen dann zu Ihrem",
        assessableIncome: () => "zu versteuerndes Einkommen",
        at: () => "bei",
        breakdown: () => "Im Einzelnen:",
        rewards: () => "Der Vertrag belohnt Sie jeden Monat mit 0,001 ETH",
        value: () =>
          "Der Wert der Belohnung wird durch den Marktwert des Vermögenswerts zum Zeitpunkt des Erhalts bestimmt.",
        year: () => "für das Jahr",
        youStake: () => "Sie investieren 1 Ethereum in einen Staking-Vertrag",
      },
      footerBack: () => "Zurück",
      footerNext: () => "Nächste",
      headings: {
        assessableIncome: () => "Zu versteuerndes Einkommen",
        stakingContract: () => "Staking-Vertrag",
        stakingRewards: () => "Staking-Belohnungen",
        yourSalary: () => "Ihr Gehalt",
      },
      subtitle: () => "Krypto-Steuerleitfaden für:",
      title: () => "Staking-Belohnungen sind Einkommen",
    },
    taxableTrades: {
      content: {
        andYour: () => "Und Ihr",
        breakdown: () => "Im Einzelnen:",
        capitalGain: () => "Kapitalgewinn",
        costBase: () => "Kostenbasis",
        for: () =>
          "für die 5 Ethereum, die Sie erworben haben, sind es jetzt 20.000 $",
        is15k: () => "beträgt 15.000 US-Dollar",
        is5k: () => "beträgt 5.000 US-Dollar",
        marketValue: () => "Marktwert",
        the: () => "Der",
        youTrade: () => "Sie tauschen 1 Bitcoin gegen 5 Ethereum",
        your: () => "Dein",
        yourBitcoin: () => "für Ihren 1 Bitcoin sind es $20.000 und die",
      },
      footerBack: () => "Zurück",
      footerNext: () => "Nächste",
      headings: {
        capitalGain: () => "Kapitalgewinn",
        costBase: () => "Kostenbasis",
        marketValue: () => "Marktwert",
      },
      subtitle: () => "Krypto-Steuerleitfaden für:",
      title: () => "Krypto-zu-Krypto-Handel ist steuerpflichtig",
    },
    tooltip: {
      accounts: () =>
        "Wallets und Börsen, die Sie für Kryptowährungstransaktionen verwenden.",
      assessableIncome: () =>
        "Erwirtschaftetes Einkommen, das steuerpflichtig ist. Z. B. Ihr Gehalt.",
      capitalGain: () =>
        "Der Betrag, der nach dem Verkauf eines Vermögenswerts und abzüglich der Kostenbasis erzielt wird. ",
      costBase: () =>
        "Der ursprüngliche Preis, den Sie für den Vermögenswert bezahlt haben, zuzüglich Transaktionsgebühren",
      marketValue: () => "Der Echtzeitwert des Vermögenswerts.",
    },
    tradingTypes: {
      footerBack: () => "Zurück",
      footerNext: () => "Nächste",
      footerSkip: () => "Überspringen",
      subtitle: () =>
        "Personalisieren Sie Ihre Erfahrung basierend auf der Art des Krypto-Händlers, der Sie sind:",
      title: () =>
        "Welche Arten von Handelsaktivitäten haben Sie durchgeführt?",
      types: {
        airdrop: () => "Luftabwürfe",
        liquidityPool: () => "Liquiditätspools",
        nft: () => "NFTs",
        other: () => "Andere",
        staking: () => "Abstecken",
        trading: () => "Handel",
      },
    },
    userValueSurvey: {
      options: {
        accurateReport: () => "Einen genauen Bericht erhalten",
        auditProtection: () => "Schutz vor einer Betriebsprüfung",
        minimiseTax: () => "Minimierung meiner Steuerlast",
        other: () => "Andere",
        simplifyTax: () => "Vereinfachung der Krypto-Steuern",
        timeSave: () => "Zeitersparnis bei der Steuervorbereitung",
      },
      title: () =>
        "Welches Ergebnis ist Ihnen bei der Verwendung einer Kryptosteuerlösung am wichtigsten?",
    },
    viewAssociatedTransaction: (d) =>
      "Zeigen Sie zugehörige " +
      plural(d.txCount, 0, de, {
        0: " Transaktionen ",
        one: " Transaktion ( " + number("de", d.txCount, 0) + " ) ",
        other: " Transaktionen ( " + number("de", d.txCount, 0) + " ) ",
      }) +
      " an.",
    wallets: {
      address: () => "Adresse",
      blockchain: () => "Blockchain",
      continue: () => "Weitermachen",
      importTransactions: () => "Transaktionen importieren",
      subtitle: () =>
        "Wir werden Transaktionen auf den folgenden Wallets importieren",
      title: () => "Ahoi, MetaMask-Benutzer",
    },
  },
  organisation: {
    wentWrongGettingDashboard: () =>
      "Beim Abrufen Ihres Dashboards ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  },
  outTxCategoryOptionsTitle: () => "Ausgehende Transaktionen",
  pageFailed: () =>
    "Etwas ist schiefgelaufen. Versuchen Sie bitte, die Seite neu zu laden.",
  pageTitle: {
    AccountingIntegrations: () => "Buchhaltungsintegrationen",
    Alpha: () => "Alpha-Funktionen",
    BalanceLedger: () => "Bilanzbuch",
    Billing: () => "Abrechnung",
    Clients: () => "Kunden",
    ClientsAdd: () => "Kunden hinzufügen",
    Close: () => "Schließen",
    ConnectProvider: () => "Anbieter verbinden",
    Contacts: () => "Kontakte",
    ContactsIdentified: () => "Identifizierte Kontakte",
    ContactsUnidentified: () => "Unbekannte Kontakte",
    Dashboard: () => "Portfolio",
    Developer: () => "Funktionsflags",
    DeveloperEditorIntegration: () => "Zum Code springen",
    DeveloperEntities: () => "Entitäten",
    DeveloperReport: () => "Bericht aktualisieren",
    DeveloperSeedData: () => "Seed-Daten",
    DeveloperSessionRecording: () => "Sitzungsaufzeichnungen",
    DeveloperUserData: () => "Benutzerdaten",
    Forgot: () => "Passwort vergessen",
    ImportCustom: () => "Benutzerdefiniertes Konto",
    ImportNew: () => "Konto hinzufügen",
    ImportSearch: () => "Konten suchen",
    Imports: () => "Konten",
    Invite: () => "Einladen",
    Ledger: () => "Hauptbuch",
    Login: () => "Login",
    Onboarding: () => "Einarbeitung",
    OnboardingAccuracy: () => "Genauigkeit",
    OnboardingAreYouSure: () => "Bist du sicher",
    OnboardingCompetitorSurvey: () => "Andere Steuersoftware",
    OnboardingEmbededOfferIntro: () => "Willkommen",
    OnboardingImportCEXReminder: () => "Zentrale Austauscherinnerung",
    OnboardingImports: () => "Konten",
    OnboardingImportsCalculating: () => "Import berechnen",
    OnboardingImportsCustom: () => "Benutzerdefinierte Konten",
    OnboardingImportsNew: () => "Neues Konto",
    OnboardingImportsOauth: () => "OAuth-Konten",
    OnboardingImportsSearch: () => "Konten suchen",
    OnboardingOnYourWay: () => "Auf dem Weg",
    OnboardingPayment: () => "Zahlung",
    OnboardingPaymentStatus: () => "Zahlungsstatus",
    OnboardingPaymentSuccess: () => "Zahlung erfolgreich",
    OnboardingPlatformTypes: () => "Plattformtypen",
    OnboardingProviderPasswordSetSuccess: () => "Passwort festgelegt",
    OnboardingProviderSetPassword: () => "Passwort festlegen",
    OnboardingProviderSyncing: () => "Verbinden",
    OnboardingProviderWelcome: () => "Willkommen",
    OnboardingReconUncategorisedTransactions: () =>
      "Nicht kategorisierte Transaktionen",
    OnboardingSelectCountry: () => "Länderauswahl",
    OnboardingSelectTheme: () => "Farbthema auswählen",
    OnboardingSelectType: () => "Kontotyp auswählen",
    OnboardingTaxSettings: () => "ATO-Steuereinstellungen",
    OnboardingTaxableStaking: () => "Steuerpflichtiges Staking",
    OnboardingTaxableTrades: () => "Steuerpflichtige Transaktionen",
    OnboardingTradingTypes: () => "Handelsarten",
    OnboardingUserValueSurvey: () => "Wichtig für Sie",
    OnboardingWallets: () => "Vermögenswerte",
    OptInSupportAccess: () => "Opt-in-Supportzugang",
    PartnerBenefit: () => "Partnervorteil",
    Payment: () => "Zahlung",
    PaymentPending: () => "Zahlung ausstehend",
    PaymentStatus: () => "Zahlungsstatus",
    PaymentSuccess: () => "Zahlung erfolgreich",
    Plans: () => "Baupläne",
    ReOnboarding: () => "Neu-Onboarding",
    ReOnboardingAccuracy: () => "Genauigkeit",
    ReOnboardingTaxableStaking: () => "Steuerpflichtiges Staking",
    ReOnboardingTaxableTrades: () => "Steuerpflichtige Transaktionen",
    ReOnboardingWelcomeBack: () => "Willkommen zurück",
    Reconciliation: () => "Versöhnung",
    ReconciliationMissingBlockchain: () => "Fehlende Blockchain",
    ReconciliationMissingPriceIssues: () => "Fehlende Preisprobleme",
    ReconciliationNegativeBalanceIssues: () =>
      "Probleme mit dem negativen Kontostand",
    ReconciliationUncategorisedTransaction: () =>
      "Nicht kategorisierte Transaktionen",
    ReconciliationUnmatchedTransfer: () => "Nicht abgeglichene Transfers",
    ReferAFriend: () => "Empfehlen Sie einen Freund",
    Report: () => "Bericht",
    Reset: () => "Passwort zurücksetzen",
    RestoreUserData: () => "Daten wiederherstellen",
    Rules: () => "Regeln",
    Signup: () => "Melden Sie sich an",
    SignupAccountant: () => "Buchhalter anmelden",
    TokenLogin: () => "Token-Anmeldung",
    Transactions: () => "Transaktionen",
    TransactionsAdd: () => "Transaktion hinzufügen",
    TransactionsMissingPrice: () => "Fehlende Preistransaktionen",
    TransactionsNegativeBalance: () => "Transaktionen mit negativem Saldo",
    TransactionsSearch: () => "Transaktionen suchen",
    TransactionsUncategorised: () => "Nicht kategorisierte Transaktionen",
    Unlock: () => "Konto entsperren",
    Wallets: () => "Vermögenswerte",
  },
  partnerEmployeeBenefits: {
    discount: {
      cta: () => "Rabatt beanspruchen",
      description: () => "100% Rabatt auf alle Tarife",
      title: (d) =>
        "Als " +
        d.partner +
        "-Mitarbeiter erhalten Sie 100% Rabatt auf Ihr CTC-Abonnement für ein Jahr",
    },
    discountApplied: {
      cta: () => "Pläne anzeigen",
      description: () =>
        "Wir haben diesen Gutschein automatisch auf Ihr Konto angewendet. Fahren Sie mit dem Checkout fort, um diesen Rabatt einzulösen.",
      title: () => "100% Rabatt auf alle Pläne angewendet",
    },
    verifyEmail: {
      confirmEmail: () => "Überprüfen Sie Ihre E-Mail",
      cta: () => "Absenden",
      error400: () =>
        "Es scheint, dass diese E-Mail bereits einen Rabattcode beansprucht hat",
      error403: (d) =>
        "Bitte geben Sie eine gültige " + d.partner + " Arbeits-E-Mail ein",
      error500: () =>
        "Etwas ist schiefgelaufen, bitte kontaktieren Sie den Support",
      helperText: (d) =>
        "Sie benötigen eine gültige " +
        d.partner +
        " E-Mail-Adresse, um den Anspruch geltend zu machen",
      placeholder: (d) => d.partner + " Arbeits-E-Mail eingeben",
      reEnterEmail: () => "E-Mail-Adresse erneut eingeben",
      success: () =>
        "Wir haben Ihnen eine E-Mail mit einem Link gesendet, der Ihren Gutscheincode automatisch anwendet",
    },
  },
  partnership: {
    officialPartner: () => "OFFIZIELLE PARTNER",
  },
  password: {
    case: () => "Ihr Passwort muss mindestens 1 Großbuchstaben enthalten",
    long: () => "Ihr Passwort darf nicht länger als 64 Zeichen sein.",
    number: () =>
      "Ihr Passwort muss mindestens 1 Zahl oder Sonderzeichen enthalten.",
    short: () =>
      "Ihr Passwort ist schwach. Muss mindestens 8 Zeichen enthalten.",
  },
  payment: {
    accountantPackage: {
      clientDefault: () => "Erste Schritte",
      color: () => "blue",
      cta: () => "Erste Schritte",
      description: () =>
        "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
      details: {
        0: {
          icon: () => "CheckCircleIcon",
          text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
        },
      },
      points: {
        0: () => "Unbegrenzter Austausch",
        1: () => "Zugriff auf alle Berichte",
        2: () => "Deckt die Geschäftsjahre 2013–2023 ab",
      },
      pointsByCountry: {
        AU: {
          0: () => "Unbegrenzte Börsen",
          1: () => "Zugriff auf alle Berichte",
          2: () => "Deckt GJ2013-2023 ab",
          3: () => "Support mit Sitz in Australien",
        },
      },
      subtitle: () =>
        "Für Buchhalter und Buchführer, die Kundendaten verwalten",
      title: () => "Buchhalter",
    },
    backToTop: () => "Zurück nach oben",
    base: {
      allTicks: {
        0: () => "Alle Pläne haben eine 30-tägige Geld-zurück-Garantie.",
        1: () =>
          "Live-Chat-Support. Schreiben Sie uns, wenn Sie Hilfe brauchen!",
        2: () => "Der Jahresbeitrag gilt für alle Geschäftsjahre seit 2013.",
        3: () =>
          "Keine Zusatzkosten für die Bearbeitung von Steuerjahren zuvor.",
      },
      cancel: () => "Stornieren",
      cancelAnytime: () => "Jederzeit kündbar",
      compare: {
        subtitle: () =>
          "Wählen Sie den Plan, der Ihren Anforderungen am besten entspricht.",
        title: () => "Funktionsvergleich",
      },
      copy: {
        actions: {
          buy: {
            text: () => "Kaufen",
          },
          current: () => "Aktueller Plan",
          getStarted: {
            text: () => "Erste Schritte",
          },
          signUp: {
            text: () => "Jetzt kaufen",
          },
          upgrade: {
            text: () => "Upgrade auf",
          },
          upgradeFor: (d) => "Upgrade für " + d.upgradePrice,
        },
        annually: () => "Jährlich",
        costCurrentPlanDeducted: () => "Kosten des aktuellen Plans abgezogen",
        discountAppliedFirstYear: (d) => d.discount + " % Rabatt angewendet",
        features: {
          advancedTaxReports: () => "Erweiterte Steuerberichte",
          auditReport: () => "Prüfbericht",
          integrations: () => "Unbegrenzte Integrationen",
          ltfo: () => "Steuerminimierungsalgorithmus",
          onchain: () => "Automatisierte On-Chain-Transaktionen",
          portfolioTracking: () => "Portfolio-Tracking",
          report: () => "Unbegrenzte Steuerberichte (ALLE Steuerjahre)",
          reportAuthority: (d) =>
            "Unbegrenzte " +
            d.authority +
            " -Steuerberichte (ALLE Steuerjahre)",
          smartContract: () => "Smart-Contract-Interaktionen",
          support: () => "Unterstützung",
          tlh: () => "Instrument zur Steuerverlusternte",
          tsr: () => "Handelsaktienbericht",
          txLimit: (d) => d.txLimit + " Transaktionen",
          xero: () => "Xero-Integration",
        },
        featuresReport: {
          reportAllFinancialYears: () => "Alle Geschäftsjahre",
          reportUnlimited: () => "Unbegrenzte Berichte",
        },
        featuresTableCell: {
          experimentSupportEmailChat: () => "E-Mail + Chat",
          experimentSupportPriority: () => "Priorität + Expertenunterstützung",
          integrations: () => "Unbegrenzt",
          supportEmailChat: () => "E-Mail- und Chat-Support",
          supportPriority: () => "Vorrangiger Support",
        },
        featuresTableHeader: {
          integrations: () => "Integrationen",
          support: () => "Unterstützung",
          txLimit: () => "Transaktionen",
        },
        featuresTooltip: {
          advancedTaxReports: () =>
            "Spesenabrechnung, Inventarbericht, Bericht über Derivatehandel, ausgehende Geschenke und Bericht über verlorene/gestohlene Vermögenswerte",
          auditReport: () =>
            "Ein Bericht, der ein Protokoll der importierten Daten und aller daran vorgenommenen Änderungen enthält, einschließlich der angewendeten Steuereinstellungen.",
          ltfo: () =>
            "Durch die Verwendung unseres „Most Tax Effective“-Algorithmus können Sie möglicherweise Steuern sparen",
          tlh: () =>
            "Ein Tool zur Identifizierung von Verlustaktiva, um diese vor Ende des Steuerjahres zu verkaufen und so den Gesamtkapitalgewinn zu reduzieren.",
        },
        firstYear: (d) =>
          "für das erste Jahr, danach " + d.price + " jährliche Abrechnung",
        freeTransactions: () => "+ 10.000 kostenlose Coinbase-Transaktionen",
        highlightFeatures: {
          "advanced-inventory": () => "Steuerminimierungsalgorithmus",
          "advanced-reports": () => "Erweiterte Steuerberichte",
          allOtherFeatures: () => "+ alles, was im Trader-Plan enthalten ist",
          comingSoon: () => "Demnächst verfügbar",
          contacts: () => "Adressbuch",
          erp: () => "Buchhaltungsintegrationen",
          "lock-tax-year": () => "Steuerjahre sperren",
          "multi-tax-years": () =>
            "Unbegrenzte Steuerberichte für alle Steuerjahre",
          prioritySupport: () => "Vorrangiger Support",
          rules: () => "Verbesserte Regel-Engine",
          sidebar: () => "Seitenleiste",
          "smart-contract-transactions": () => "Smart-Contract-Transaktionen",
          "tax-loss-harvesting": () => "Instrument zur Steuerverlusternte",
          tips: () => "Tipps",
          "trading-stock-report": () => "Handelsaktienbericht",
          treasury: () => "Treasury-Dashboard",
        },
        planCommonDetails: () =>
          "Ein einziger Plan deckt alle Steuerjahre von 2013 bis 2022 ab",
        plans: {
          accountant: {
            clientDataDescription: () =>
              "Wir unterstützen direkt Hunderte von Börsen, Blockchains und Diensten. Fügen Sie einfach API-Schlüssel und Wallet-Adressen hinzu.",
            clientDataTitle: () =>
              "Einfache Beschaffung von Kundendaten durch Direktaustausch und Blockchain-Integrationen",
            clientDefault: () => "Erste Schritte",
            color: () => "blue",
            description: () =>
              "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
            descriptionFooter: () =>
              "Beginnen Sie kostenlos und zahlen Sie erst, wenn Sie die Daten Ihrer Kunden importieren.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
              },
            },
            getStarted: () => "Kostenlos starten",
            manageClientsDescription: () =>
              "Über unser Buchhalterportal können Sie Kunden über ein einziges benutzerfreundliches Dashboard einladen und verwalten.",
            manageClientsTitle: () =>
              "Verwalten Sie alle Ihre Kunden an einem Ort",
            points: {
              0: () =>
                "Laden Sie Kunden ein und verwalten Sie sie über Ihr Kundenverwaltungs-Dashboard",
              1: () => "Unterstützt bis zu 100.000 Transaktionen pro Client",
              2: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
            },
            subtitle: () =>
              "Verwalten Sie die Kryptowährungs-Steuererklärung Ihres Kunden ganz einfach mit unserer professionellen Buchhaltungssuite.",
            title: () => "Buchhalter-Suite",
            toolsDemo: () => "Buchhaltungstools und Demo",
          },
          advanced: {
            color: () => "purple",
            cta: () => "Jetzt kaufen",
            description: () =>
              "Schaltet einen proprietären Algorithmus frei, um langfristige Rabatte und kurzfristige Verluste zu maximieren",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () => "Für aktive Anleger mit komplexen Transaktionen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Deckt DeFi, DEXs, Derivate, Staking und " +
                  d.txLimit +
                  " -Transaktionen ab.",
              },
            },
            points: {
              0: () => "10.000 Transaktionen",
              1: () => "Unbegrenzter Austausch",
              2: () => "Geldbörsenimporte",
              3: () => "Staking, Mining, Airdrops, DeFi",
              4: () => "Long/Short- und CFD-Trades",
              5: () => "Margin- und Futures-Handel",
              6: () => "Einkommensteuerbericht",
              7: () => "Kapitalertragsteuerbericht",
              8: () => "Online-Chat-Support",
              9: () => "Deckt die Geschäftsjahre 2013–2023 ab",
            },
            subtitle: () =>
              "Für Krypto-Investoren, die in die Welt der dezentralen Finanzen eingestiegen sind",
            title: () => "Investor",
          },
          business: {
            color: () => "blue",
            cta: () => "Erste Schritte",
            description: () =>
              "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
              },
            },
            points: {
              0: () => "Zugriff auf alle Berichte",
              1: () => "Deckt die Geschäftsjahre 2013–2023 ab",
            },
            retailPlansPage: {
              cta: () => "Unternehmen kaufen",
              description: () =>
                "Präzises Krypto-Buchhaltungstool für Unternehmen",
              points: {
                0: {
                  description: () => "Integriert mit Xero und Quickbooks",
                  feature: () => "Buchhaltungsintegrationen",
                },
                1: {
                  description: () =>
                    "Behalten Sie den Überblick und verwalten Sie alle Adressen und Entitäten an einem Ort ",
                  feature: () => "Adressbuch",
                },
                2: {
                  description: () =>
                    "Erstellen und verwalten Sie automatisierte Regeln zur Kategorisierung und Zuordnung von Transaktionen beim Import",
                  feature: () => "Regel-Engine",
                },
                3: {
                  feature: () => "+ Alle Funktionen im Trader-Plan",
                },
              },
              subtitle: () => "Automatisieren Sie Ihre Krypto-Buchhaltung",
              title: () => "Geschäftsplan",
            },
            subtitle: () => "Für Web3-Unternehmen",
            title: () => "Geschäft",
          },
          businessPackage: {
            color: () => "blue",
            cta: () => "Erste Schritte",
            description: () =>
              "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
              },
            },
            points: {
              0: () => "Unbegrenzter Austausch",
              1: () => "Zugriff auf alle Berichte",
              2: () => "Deckt die Geschäftsjahre 2013–2023 ab",
            },
            subtitle: () =>
              "Für Buchhalter und Buchführer, die Kundendaten verwalten",
            title: () => "Geschäft",
          },
          business_pro: {
            caption: () =>
              "Mehr als 10.000 Transaktionen? Kontaktieren Sie uns für einen marktführenden Preis.",
            color: () => "blue",
            cta: () => "Erste Schritte",
            description: () =>
              "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
              },
            },
            points: {
              0: () => "Unbegrenzter Austausch",
              1: () => "Zugriff auf alle Berichte",
              2: () => "Deckt die Geschäftsjahre 2013–2023 ab",
            },
            subtitle: () => "Für größere oder etabliertere Unternehmen",
            title: () => "Business Pro",
          },
          business_starter: {
            color: () => "blue",
            cta: () => "Erste Schritte",
            description: () =>
              "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
              },
            },
            points: {
              0: () => "Unbegrenzter Austausch",
              1: () => "Zugriff auf alle Berichte",
              2: () => "Deckt die Geschäftsjahre 2013–2023 ab",
            },
            subtitle: () => "Für Startups und kleine Unternehmen",
            title: () => "Existenzgründer",
          },
          business_trial: {
            color: () => "blue",
            cta: () => "Erste Schritte",
            description: () =>
              "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
              },
            },
            points: {
              0: () => "Unbegrenzter Austausch",
              1: () => "Zugriff auf alle Berichte",
              2: () => "Deckt die Geschäftsjahre 2013–2023 ab",
            },
            subtitle: () => "Für Startups und kleine Unternehmen",
            title: () => "Geschäftsplan",
          },
          client: {
            collaborator: {
              color: () => "blue",
              cta: () => "Erste Schritte",
              description: () =>
                "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
              details: {
                0: {
                  icon: () => "CheckCircleIcon",
                  text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
                },
              },
              points: {
                0: () => "Unbegrenzter Austausch",
                1: () => "Zugriff auf alle Berichte",
                2: () => "Deckt die Geschäftsjahre 2013–2023 ab",
              },
              subtitle: () =>
                "Für Buchhalter und Buchführer, die Kundendaten verwalten",
              title: () => "Buchhalter",
            },
            color: () => "blue",
            cta: () => "Erste Schritte",
            description: () =>
              "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
              },
            },
            enterprise: {
              color: () => "blue",
              cta: () => "Erste Schritte",
              description: () =>
                "Komplettlösung für Krypto-Steuern für Buchhalter und Buchführer.",
              details: {
                0: {
                  icon: () => "CheckCircleIcon",
                  text: () => "Deckt DeFi, DEXs, Derivate und Staking ab",
                },
              },
              points: {
                0: () => "Unbegrenzter Austausch",
                1: () => "Zugriff auf alle Berichte",
                2: () => "Deckt die Geschäftsjahre 2013–2023 ab",
              },
              subtitle: () =>
                "Für Buchhalter und Buchführer, die Kundendaten verwalten",
              title: () => "Buchhalter",
            },
            points: {
              0: () => "Unbegrenzter Austausch",
              1: () => "Zugriff auf alle Berichte",
              2: () => "Deckt die Geschäftsjahre 2013–2023 ab",
            },
            subtitle: () =>
              "Für Buchhalter und Buchführer, die Kundendaten verwalten",
            title: () => "Buchhalter",
          },
          collaborator: {
            subtitle: () => "Mitarbeiter",
            title: () => "Mitarbeiter",
          },
          enterprise: {
            subtitle: () => "Unternehmen",
            title: () => "Unternehmen",
          },
          expert: {
            color: () => "blue",
            cta: () => "Jetzt kaufen",
            description: () => "Hochleistungslösung für aktive Händler\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () => "Für Daytrader mit hohem Transaktionsvolumen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Unterstützt den gesamten Investorenplan und bis zu " +
                  d.txLimit +
                  " Transaktionen.",
              },
            },
            points: {
              0: () => "100.000 Transaktionen",
              1: () => "Alle Investor-Funktionen",
              2: () => "Steuerstrategien vergleichen",
            },
            subtitle: () =>
              "Für Krypto-Händler, die ihren Handel als Geschäft deklarieren möchten",
            title: () => "Händler",
          },
          free: {
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Für Benutzer, die sich im Kryptobereich versuchen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Unterstützt " +
                  d.txLimit +
                  " -Transaktionen, bei denen Vermögenswerte an zentralen Börsen gehalten werden.",
              },
            },
            subtitle: () => "frei",
            title: () => "Frei",
          },
          investorUs: {
            color: () => "grey",
            cta: () => "Jetzt kaufen",
            description: () => "Für erfahrene Krypto-Investoren",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Für Krypto-Enthusiasten, die zentralisierte Börsen nutzen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Unterstützt die Speicherung von Vermögenswerten in Wallets und bis zu " +
                  d.txLimit +
                  " Transaktionen.",
              },
            },
            points: {
              0: () => "1.000 Transaktionen",
              1: () => "Unbegrenzte Integrationen",
              2: () => "Unbegrenzte Steuerberichte für alle Steuerjahre",
              3: () => "TurboTax-Integration",
              4: () => "Portfolio-Dashboard",
              5: () => "Laden Sie Ihren Buchhalter ein",
              6: () => "Automatisierte DeFi-Steuerberechnungen",
              7: () => "Steuerminimierung",
            },
            subtitle: () => "Für erfahrene Krypto-Investoren",
            title: () => "Investor",
          },
          investorUsPlus: {
            color: () => "grey",
            cta: () => "Jetzt kaufen",
            description: () => "Für erfahrene Krypto-Investoren",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Für Krypto-Enthusiasten, die zentralisierte Börsen nutzen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Unterstützt die Speicherung von Vermögenswerten in Wallets und bis zu " +
                  d.txLimit +
                  " Transaktionen.",
              },
            },
            points: {
              0: () => "3.000 Transaktionen",
              1: () => "Unbegrenzte Integrationen",
              2: () => "Unbegrenzte Steuerberichte für alle Steuerjahre",
              3: () => "TurboTax-Integration",
              4: () => "Portfolio-Dashboard",
              5: () => "Laden Sie Ihren Buchhalter ein",
              6: () => "Automatisierte DeFi-Steuerberechnungen",
              7: () => "Steuerminimierung",
            },
            subtitle: () => "Für erfahrene Krypto-Investoren",
            title: () => "Anleger +",
          },
          pro: {
            subtitle: () =>
              "Für Krypto-Händler, die ihren Handel als Geschäft deklarieren möchten",
            title: () => "Pro",
          },
          rookie: {
            color: () => "grey",
            cta: () => "Jetzt kaufen",
            description: () =>
              "Kostengünstige Lösung für Gelegenheits-Krypto-Enthusiasten zur mühelosen Verwaltung von Krypto-Steuern",
            points: {
              0: () => "200 Transaktionen",
              1: () => "Unbegrenzter Austausch",
              2: () => "Einkommensteuerbericht",
              3: () => "Kapitalertragsteuerbericht",
              4: () => "Deckt die Geschäftsjahre 2013–2023 ab",
              5: () => "Online-Chat-Unterstützung",
            },
            subtitle: () =>
              "Für Krypto-Benutzer, die begonnen haben, in die Welt des Handels einzutauchen",
            title: () => "Anfänger",
          },
          rookie_100: {
            color: () => "grey",
            cta: () => "Jetzt kaufen",
            description: () =>
              "Kostengünstige Lösung für Gelegenheits-Krypto-Enthusiasten zur mühelosen Verwaltung von Krypto-Steuern",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Für Benutzer, die sich im Kryptobereich versuchen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Unterstützt " +
                  d.txLimit +
                  " -Transaktionen, bei denen Vermögenswerte an zentralen Börsen gehalten werden.",
              },
            },
            points: {
              0: () => "100 Transaktionen",
              1: () => "Luftabwürfe",
              2: () => "Unbegrenzter Austausch",
              3: () => "Einkommensteuerbericht",
              4: () => "Kapitalertragsteuerbericht",
              5: () => "Deckt die Geschäftsjahre 2013–2023 ab",
              6: () => "Online-Chat-Support",
            },
            subtitle: () =>
              "Für Krypto-Benutzer, die begonnen haben, in die Welt des Handels einzutauchen",
            title: () => "Anfänger",
          },
          standard: {
            color: () => "grey",
            cta: () => "Jetzt kaufen",
            description: () =>
              "Umfassende On-Chain-Unterstützung für DeFi und NFTs mit direkten Smart-Contract-Integrationen",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Für Krypto-Enthusiasten, die zentralisierte Börsen nutzen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Unterstützt die Speicherung von Vermögenswerten in Wallets und bis zu " +
                  d.txLimit +
                  " Transaktionen.",
              },
            },
            points: {
              0: () => "2.500 Transaktionen",
              1: () => "Unbegrenzter Austausch",
              2: () => "Geldbörsenimporte",
              3: () => "Luftabwürfe",
              4: () => "Einkommensteuerbericht",
              5: () => "Kapitalertragsteuerbericht",
              6: () => "Deckt die Geschäftsjahre 2013–2023 ab",
              7: () => "Online-Chat-Support",
            },
            subtitle: () =>
              "Für den Krypto-Enthusiasten, der zentralisierte Börsen nutzt und dazu neigt, zu hodl",
            title: () => "Hobbyist",
          },
          standard_100: {
            color: () => "grey",
            cta: () => "Jetzt kaufen",
            description: () =>
              "Umfassende On-Chain-Unterstützung für DeFi und NFTs mit direkten Smart-Contract-Integrationen",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Für Krypto-Enthusiasten, die zentralisierte Börsen nutzen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Unterstützt die Speicherung von Vermögenswerten in Wallets und bis zu " +
                  d.txLimit +
                  " Transaktionen.",
              },
            },
            points: {
              0: () => "1.000 Transaktionen",
              1: () => "Unbegrenzter Austausch",
              2: () => "Geldbörsenimporte",
              3: () => "Luftabwürfe",
              4: () => "Einkommensteuerbericht",
              5: () => "Kapitalertragsteuerbericht",
              6: () => "Deckt die Geschäftsjahre 2013–2023 ab",
              7: () => "Online-Chat-Unterstützung",
            },
            subtitle: () =>
              "Für den Krypto-Enthusiasten, der zentralisierte Börsen nutzt und dazu neigt, zu hodl",
            title: () => "Hobbyist",
          },
          traderUs: {
            color: () => "blue",
            cta: () => "Jetzt kaufen",
            description: () => "Für erfahrene Krypto-Fanatiker\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () => "Für Daytrader mit hohem Transaktionsvolumen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Unterstützt den gesamten Investorenplan und bis zu " +
                  d.txLimit +
                  " Transaktionen.",
              },
            },
            points: {
              0: () => "10.000 Transaktionen",
              1: () => "Alle Investor+-Funktionen",
              2: () => "Erweiterte Steuerberichterstattung",
              3: () => "Überwachungsprotokoll",
            },
            subtitle: () =>
              "Für Krypto-Händler, die ihren Handel als Geschäft deklarieren möchten",
            title: () => "Händler",
          },
          traderUsPlus: {
            color: () => "blue",
            cta: () => "Jetzt kaufen",
            description: () => "Für erfahrene Krypto-Fanatiker\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () => "Für Daytrader mit hohem Transaktionsvolumen.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Unterstützt den gesamten Investorenplan und bis zu " +
                  d.txLimit +
                  " Transaktionen.",
              },
            },
            points: {
              0: () => "100.000 Transaktionen",
              1: () => "Alle Investor+-Funktionen",
              2: () => "Erweiterte Steuerberichterstattung",
              3: () => "Überwachungsprotokoll",
            },
            subtitle: () =>
              "Für Krypto-Händler, die ihren Handel als Geschäft deklarieren möchten",
            title: () => "Händler +",
          },
        },
        plus: () => "+",
        subtitle: () =>
          "Zahlen Sie mit CTC die niedrigsten Steuern und erhalten Sie die genauesten Berichte.",
        subtitleAccountant: () =>
          "Verwalten Sie die Kryptowährungs-Steuererklärungen Ihrer Kunden ganz einfach mit unserer professionellen Buchhaltungssuite",
        tabTitle: () => "Krypto-Steuerrechner für Unternehmen",
        title: () => "Wählen Sie Ihren Plan",
        titleAccountants: () => "Buchhalter-Suite",
        usExperimentPlanDescription: {
          investorUs: () => "Für erfahrene Krypto-Investoren ",
          investorUsPlus: () => "Für erfahrene Krypto-Investoren ",
          rookie_100: () => "Für Einsteiger",
          traderUs: () => "Für erfahrene Krypto-Fanatiker",
          traderUsPlus: () => "Für erfahrene Krypto-Fanatiker",
        },
        usExperimentPlanTitle: {
          investorUs: () => "Investor",
          investorUsPlus: () => "Anleger +",
          rookie_100: () => "Anfänger",
          traderUs: () => "Händler",
          traderUsPlus: () => "Händler +",
        },
        usExperimentPlanTxCount: {
          investorUs: {
            hasPlusTxs: () => "3.000",
            txCount: () => "1.000",
          },
          rookie_100: {
            hasPlusTxs: () => "0",
            txCount: () => "100",
          },
          traderUs: {
            hasPlusTxs: () => "Über 100.000",
            txCount: () => "10.000",
          },
        },
        usUserExperimentFeatures: {
          accountantInvite: () => "Laden Sie Ihren Buchhalter ein",
          advancedTaxReports: () => "Erweiterte Steuerberichterstattung",
          auditLog: () => "Prüfprotokoll",
          dashboard: () => "Portfolio-Dashboard",
          defiTaxAutomation: () => "Automatisierte DeFi-Steuerberechnungen",
          integrations: () => "Unbegrenzte Integrationen",
          support: () => "Unterstützung",
          taxMinimization: () => "Steuerminimierung",
          taxYearReports: () =>
            "Unbegrenzte Steuerberichte für alle Steuerjahre",
          turboTaxIntegration: () => "TurboTax-Integration",
        },
        usUserExperimentFeaturesTooltip: {
          accountantInvite: () =>
            "Arbeiten Sie mit Ihrem Steuerberater zusammen. Buchhalter erhalten ohne zusätzliche Kosten Zugriff auf CTC.",
          advancedTaxReports: () =>
            "Genaue Berichterstattung für alle Steuerszenarien in den Bereichen Ausgaben, Inventar, Derivatehandel, ausgehende Geschenke und Meldung von verlorenen/gestohlenen Vermögenswerten.",
          auditLog: () =>
            "Reduzieren Sie das Auditrisiko mit einem umfassenden Protokoll der importierten Daten und der daran vorgenommenen Änderungen.",
          dashboard: () =>
            "Gewinnen Sie Vertrauen in die Zahlen mit einer benutzerfreundlichen Ansicht Ihres gesamten Portfolios.",
          defiTaxAutomation: () =>
            "Wir erledigen die harte Arbeit, damit Sie es nicht tun müssen. Automatische Bewertung der steuerlichen Auswirkungen Ihrer On-Chain-Aktivitäten und Smart Contracts.",
          integrations: () =>
            "Importieren Sie alle Ihre Wallets und Quellen in wenigen Minuten.",
          support: () =>
            "Sie müssen das nicht allein machen. Unser Team aus Krypto-Compliance-Experten hilft Ihnen gerne.",
          taxMinimization: () =>
            "Geben Sie nicht zu viel für Steuern aus, mit CTCs proprietärem Algorithmus zur Steuerminimierung, der Möglichkeit, die effizienteste Inventarmethode zu wählen und Steuerverluste auszugleichen.",
          taxYearReports: () =>
            "Keine Zusatzkosten für Vorjahresberichte. *hüstel* anders als bei unseren Mitbewerbern.",
          turboTaxIntegration: () =>
            "CTC lässt sich sowohl mit der Browser- als auch mit der Desktop-Version von TurboTax verbinden, sodass Sie Ihre Steuern problemlos einreichen können.",
        },
        validFirstYear: () => "Gültig für Ihr erstes Jahresabonnement",
        wasPrice: (d) => "war " + d.price,
      },
      currency: () => "USD",
      currencySymbol: () => "$",
      email: () => "E-Mail",
      guarantee: () => "30 Tage Geld-zurück-Garantie",
      loading: () => "Verarbeitung...",
      payNow: () => "Jetzt bezahlen",
      period: () => "Jahr",
      plan: () => "Planen",
      planDisabled: () =>
        "Die Anzahl der Transaktionen dieses Kunden hat das Limit für diesen Plan überschritten.",
      title: () => "Preispläne",
    },
    billedYearly: () => "Jährliche Abrechnung",
    billing: {
      chips: {
        cancelledChip: (d) => "Gekündigt: Läuft ab am " + d.date,
        renewChip: (d) => "Erneuern am: " + d.date,
        trialEndsChip: (d) => "Testversion endet: " + d.date,
      },
      cta: {
        cancelPlan: () => "Plan stornieren",
        reSubscribe: () => "Erneut abonnieren",
      },
      footer: {
        client: {
          cancelledPlanFooter: (d) =>
            "Dieser Plan wird am " + d.date + " nicht verlängert",
          estimatedPaymentFooter: (d) =>
            "Ihre nächste voraussichtliche Zahlung für diesen Kunden beträgt " +
            d.amount +
            " am " +
            d.date,
          estimatedPaymentFooterWithTrial: (d) =>
            "Ihre nächste voraussichtliche Zahlung für diesen Kunden beträgt " +
            d.amount +
            " am " +
            d.date +
            " , nachdem die Testphase endet",
        },
      },
      lineItems: {
        billingCycle: () => "Abrechnungszeitraum:",
        couponWithAmount: (d) =>
          d.couponId + " ( " + d.amountOff + " % Rabatt)",
        planPrice: () => "Planpreis:",
        totalDue: () => "Gesamtbetrag:",
        totalDueDate: (d) => "Gesamtbetrag fällig am " + d.date + " :",
      },
      payment: {
        activeCard: () => "Aktive Karte:",
        changePayment: () => "Zahlungsmethode ändern",
        discount: (d) => d.amount + " % Rabatt",
        end: () =>
          " jährlich, bis Sie Ihr Abonnement kündigen. Sie können jederzeit kündigen.",
        endWithDate: (d) =>
          " am " +
          d.date +
          " und dann jährlich an diesem Datum, bis Sie das Abonnement kündigen. Sie können jederzeit kündigen.",
        start: () => "Ihnen werden Kosten in Rechnung gestellt ",
      },
    },
    cancelled: {
      button: () => "Abgesagt",
      tooltip: (d) =>
        "Der Plan " +
        d.plan +
        " wurde gekündigt. Er bleibt bis zum Ende der aktuellen Abrechnungsperiode aktiv.",
    },
    coupon: {
      unknownCoupon: () => "Exklusives Angebot",
    },
    currentPlan: () => "Der aktuelle Plan des Kunden",
    disclaimer: () =>
      "*Alle Pläne können steuerlich absetzbar sein, wenn sie als Ausgabe im Zusammenhang mit der Verwaltung Ihrer eigenen Steuerangelegenheiten anfallen.",
    downgradeWarning: () =>
      "Dieser Plan ist nicht verfügbar, da Sie während dieser Abrechnungsperiode bereits Funktionen eines höherstufigen Plans genutzt haben.",
    enterprise: {
      demoCTA: {
        badge: () => "Erstes Kundenangebot",
        button: () => "Planen Sie eine Demo",
        newRelease: {
          badge: () => "Neuerscheinungsangebot",
          ctaLink: () => "Vereinbaren Sie einen Termin mit uns",
          ctaText: () => "Wenn Sie Fragen haben oder eine Demo wünschen",
          subtitle: () =>
            "Beginnen Sie kostenlos mit einem Geschäftskunden, ohne Grenzen oder Einschränkungen. Nach 30 Tagen melden wir uns bei Ihnen, um einen Check-in durchzuführen.",
          title: () => "30 Tage gratis!",
        },
        subtitle: () =>
          "Wir helfen Ihnen bei den ersten Schritten mit Ihrem ersten Geschäftskunden. Vereinbaren Sie eine Demo mit unserem Team.",
        title: () => "30 Tage kostenlose Testversion für Ihren ersten Kunden.",
      },
    },
    excludes: () => "Nicht im Preis enthalten:",
    freeTx: {
      coinbase: (d) => d.freeTxCount + " kostenlose Coinbase-Transaktionen",
      coinjar: (d) => d.freeTxCount + " kostenlose CoinJar-Transaktionen",
      default: (d) => d.freeTxCount + " kostenlose Transaktionen",
      phantom: (d) => d.freeTxCount + " kostenlose Phantom-Transaktionen",
    },
    haveSmartContractTx: () => "Sie haben Smart-Contract-Transaktionen.",
    ignoredTx: (d) =>
      d.ignoredTxCount + " Transaktionen als Spam markiert oder ignoriert",
    moneyBackGuarantee: () => "30 Tage Geld-zurück-Garantie",
    moneyBackGuaranteeLower: () => "30 Tage Geld-zurück-Garantie",
    mostPopular: () => "Am beliebtesten",
    paymentCycle: {
      annual: () => "Jährlich",
      monthly: () => "Monatlich",
    },
    paymentCycleShort: {
      annual: () => "pro Jahr",
      monthly: () => "p/m",
    },
    perMonthBilledYearly: () => "Pro Monat, jährliche Abrechnung",
    promoBanner: {
      applied: () => "Angewandt",
      bitcoinDotComAu: {
        0: () => "30% Rabatt auf kostenpflichtige Pläne",
      },
      coinbase: {
        0: () => "10.000 kostenlose Coinbase-Transaktionen",
        1: () => "30 % Rabatt auf kostenpflichtige Pläne",
      },
      coinbaseone: {
        otherPlans: () =>
          "40 % Rabatt auf Hobbyisten-, Investoren- und Händlerpläne",
        rookie: (d) => d.price + " Anfängerplan",
      },
      coinbaseonepremium: {
        0: () => "50 % Rabatt auf die Premiumstufe von CTC, Trader",
        1: () => "40 % Rabatt auf Rookie-, Hobbyist- und Investor-Pläne",
      },
      coinjar: {
        0: () => "1.000 kostenlose CoinJar-Transaktionen",
        1: () => "30 % Rabatt auf kostenpflichtige Pläne",
      },
      coinstats: () => "auf Ihr erstes Jahr bei CTC",
      independentReserve: {
        0: () => "30% Rabatt auf kostenpflichtige Pläne",
      },
      metamask: {
        0: () => "30 % Rabatt im ersten Jahr",
      },
      partnerOffer: () => "Offizielles Partnerangebot",
      phantom20: {
        0: () => "20% Rabatt auf kostenpflichtige Pläne (nur im ersten Jahr)",
        1: () => "200 kostenlose Phantom Wallet-Transaktionen",
      },
      referAFriend: {
        0: () => "20% Rabatt auf alle Einzelhandelspläne",
      },
      referralOffer: () => "Empfehlungsangebot",
    },
    renewFailed: {
      bannerTitle: () => "Oh nein! Wir konnten Ihre Zahlung nicht verarbeiten.",
      renewError: () =>
        "Bitte überprüfen Sie Ihre Standardzahlungsmethode und stellen Sie sicher, dass Sie über ausreichend Guthaben zur Begleichung Ihrer Rechnung verfügen.",
      renewPlan: () => "Plan erneuern",
      renewSuccess: () => "Ihr Abonnement wurde erfolgreich verlängert",
      settingText: () =>
        "Um Berichte zu erstellen, erneuern Sie Ihren Plan. Aktualisieren Sie Ihre Zahlungsdetails, indem Sie mit der Zahlungsverwaltung fortfahren.",
    },
    save20: () => "Sparen Sie 20%",
    taxDeductible: () => "Steuerlich absetzbar*",
    tooManyTx: () => "Sie haben zu viele Transaktionen für diesen Plan",
    totalTxs: () => "Gesamtanzahl Transaktionen",
    txCount: {
      prefix: () => "Du hast",
      suffix: () => "abrechenbare Transaktionen",
    },
    txCountFiltered: (d) =>
      "Sie haben <s> " +
      d.filtered +
      " </s> <span> " +
      d.count +
      " </span> Transaktionen",
    viewAccountant: () => "Buchhalterplan anzeigen",
    viewBusinessPlan: () => "Businessplan anzeigen",
    viewIndividualPlan: () => "Individuelle Pläne anzeigen",
    yourOffers: () => "Ihre Angebote",
  },
  paymentPortal: {
    cardDetailsLabel: () => "Kartendetails*",
    dialogLabel: () => "Zahlung",
    payPrefixText: () => "Zahlen",
    paymentError: () => "Fehler bei der Zahlungsabwicklung",
  },
  paymentStatus: {
    pending: {
      subtitle: () =>
        "Wenn dies zu lange dauert, versuchen Sie, die Seite zu aktualisieren. Wenden Sie sich an den Support, wenn das Problem weiterhin besteht.",
      title: () => "Zahlung wird bearbeitet...",
    },
    success: {
      buttonText: () => "Holen Sie sich Ihren Steuerbericht",
      subtitle: () =>
        "Vielen Dank, dass Sie sich für den Krypto-Steuerrechner entschieden haben.",
      title: () => "Kauf erfolgreich!",
    },
  },
  paywallBanner: {
    cancel: () => "Stornieren",
    implementations: {
      accountant: {
        enforceUpgrade: {
          buttonText: () => "Upgrade-Plan",
          subtitle: () =>
            "Bitten Sie Ihren Kunden, seinen Plan zu aktualisieren, um Steuerberichte herunterladen zu können",
          subtitleMulti: () => "Ihr Bericht enthält:",
          title: (d) =>
            "Ihr Kunde hat das aktuelle Transaktionslimit seines Plans überschritten ( " +
            d.count +
            " von " +
            d.limit +
            " )",
          titleMulti: () =>
            "Ihr Kunde hat die Grenzen seines Plans überschritten, indem er:",
        },
        renewalFailed: {
          buttonText: () => "Abonnement verwalten",
          subtitle: () =>
            "Bitten Sie Ihren Kunden, das Zahlungsproblem zu lösen, um Steuerberichte herunterzuladen.",
          title: () =>
            "Oh nein! Wir konnten die Zahlung Ihres Kunden nicht verarbeiten.",
        },
        signUp: {
          buttonText: () => "Melden Sie sich an",
          subtitle: () =>
            "Um einen Steuerbericht zu erstellen, müssen Sie Ihren Client auf den nächsten Plan upgraden.",
          title: () =>
            "Möchten Sie einen Steuerbericht für Ihren Kunden erstellen?",
        },
      },
      standard: {
        enforceUpgrade: {
          buttonText: () => "Upgrade-Plan",
          subtitle: () =>
            "Aktualisieren Sie Ihren Plan, um Steuerberichte herunterzuladen",
          subtitleMulti: () => "Ihr Bericht enthält:",
          title: (d) =>
            "Sie haben das aktuelle Transaktionslimit Ihres Plans überschritten ( " +
            d.count +
            " von " +
            d.limit +
            " )",
          titleMulti: () =>
            "Sie haben die Grenzen Ihres Plans überschritten, indem Sie:",
        },
        renewalFailed: {
          buttonText: () => "Abonnement verwalten",
          subtitle: () =>
            "Überprüfen Sie Ihre Abonnement- und Zahlungsdetails, um das Problem zu beheben.",
          title: () => "Oh nein! Wir konnten Ihre Zahlung nicht verarbeiten.",
        },
        signUp: {
          buttonText: () => "Upgrade-Plan",
          subtitle: () =>
            "Um einen Steuerbericht zu erstellen, müssen Sie auf den nächsten Plan upgraden.",
          title: () => "Möchten Sie einen Steuerbericht erstellen?",
        },
      },
    },
    paywallReasons: {
      importCount: (d) => "Mehr als " + d.importLimit + " Importe",
      tradeType: () => "Smart-Contract-Transaktionen",
      txCount: (d) => "Mehr als " + d.txLimit + " Transaktionen",
    },
    reportTable: () =>
      "Bitte führen Sie ein Upgrade durch, um auf die Berichtstabelle zuzugreifen",
    upgradeFor: () => "Upgrade für",
    upgradeNow: () => "Jetzt upgraden",
  },
  paywallModal: {
    cancel: () => "Stornieren",
    downloadSample: () => "Beispielbericht herunterladen",
    implementations: {
      advancedReports: {
        prompt: () => "Schalten Sie diese erweiterten Berichte frei: ",
        title: () => "Das ganze Bild sehen",
      },
      allReports: {
        prompt: () => "Schalten Sie diese Berichte frei: ",
        title: () =>
          "Abonnieren Sie, um auf alle Steuerberichte und -formulare zuzugreifen",
      },
      inventory: {
        prompt: () => "Upgrade zum Investorenplan für",
        title: () => "Optimieren Sie Ihre Steuersituation",
      },
      multiTaxYears: {
        subtitle: () =>
          "Aktualisieren Sie auf einen kostenpflichtigen Plan, um auf Berichte für so viele Steuerjahre zuzugreifen, wie Sie benötigen.",
        title: () => "Mehrere Steuerjahre, ein Abonnement",
      },
      paywall: {
        subtitle: () =>
          "Führen Sie ein Upgrade durch, um Zugriff auf Ihre Berichte zu erhalten.",
        title: () => "Sie haben die Leistungen Ihres Plans überschritten",
      },
      smartContractInteraction: {
        banner: {
          subText: () => "Smart-Contract-Transaktionen",
          text: () => "Ihr Bericht enthält:",
          title: () => "Upgrade erforderlich",
        },
        subtitle: () =>
          "Aktualisieren Sie auf den Hobbyistenplan, um Unterstützung für alle Arten von DeFi, komplexe On-Chain-Aktivitäten und unbegrenzte Importe zu erhalten.",
        title: () => "Entfesseln Sie Ihren inneren Degen",
      },
      tips: {
        subtitle: () =>
          "Aktualisieren Sie auf einen kostenpflichtigen Plan, um auf Tipps zuzugreifen, die Ihnen dabei helfen können, die Genauigkeit Ihrer Berichte zu verbessern und bei Ihrer Steuerrechnung zu sparen.",
        subtitleReview: () =>
          "Aktualisieren Sie auf einen kostenpflichtigen Plan, um auf unseren geführten Überprüfungsprozess zuzugreifen, der Ihnen dabei helfen kann, die Genauigkeit Ihrer Berichte zu verbessern und bei Ihrer Steuerrechnung zu sparen.",
        title: () => "Abonnieren Sie für Sparmöglichkeiten",
      },
      tradingStockReport: {
        subtitle: () =>
          "Upgraden Sie auf den Trader-Plan, um Zugriff auf den Trader Stock Report zu erhalten",
        title: () => "Professionelle Berichtsflexibilität",
      },
    },
    irsModal: {
      dateString: (d) =>
        d.days + " Tage : " + d.hours + " Stunden : " + d.minutes + " Min",
      irsTaxSubmissionDeadline: () => "IRS-Steuererklärungsfrist",
    },
    irsVariantChoosePlan: {
      cta: () => "Zur Zahlung fortfahren",
      secondary: () => "Pläne vergleichen",
      title: () => "Upgrade deines Plans.\nEinsparungen freischalten.",
      txCount: (d) =>
        "Du hast " +
        plural(d.txCount, 0, de, {
          one: "1 Transaktion",
          other: number("de", d.txCount, 0) + " Transaktionen",
        }),
      txSubtitle: (d) => "Bis zu " + d.txCount + " Transaktionen",
    },
    irsVariantPreSelectedPlan: {
      cta: () => "Plan kaufen",
      disclaimerOne: () => "30 Tage Geld-zurück-Garantie",
      disclaimerTwo: () => "*Rabatt gilt nur für das erste Jahr",
      discountApplied: () => "Rabatt angewendet",
      features: {
        0: {
          description: () =>
            "Bleiben Sie mit den Steuerbehörden auf der sicheren Seite",
          title: () => "Prüfungssichere Steuerberichte",
        },
        1: {
          description: () =>
            "Erhalten Sie Tipps zur legalen Reduzierung Ihrer Steuerlast",
          title: () => "Steuerersparnisse freischalten",
        },
        2: {
          description: () =>
            "Wir führen Sie durch den Prozess, um die Genauigkeit Ihres Berichts zu gewährleisten",
          title: () => "Nicht alleine durchkämpfen",
        },
      },
      link: () => "Pläne vergleichen",
      price: (d) =>
        d.price +
        plural(d.discount, 0, de, {
          0: "",
          other: " (spare " + number("de", d.discount, 0) + "%)*",
        }),
      title: () =>
        "Upgraden Sie Ihren Plan und schalten Sie alle Funktionen frei",
    },
    irsVariantViewPlans: {
      cta: () => "Pläne anzeigen",
      metamaskOffer: () => "30% Rabatt auf alle Pläne",
      reportsReady: () => "Ihre Berichte sind fertig",
    },
    modalText: {
      "annex-so-other-income": () =>
        "Beispiel für einen deutschen Bericht herunterladen - Anlage So (Sonstige Einkünfte)",
      audit: () => "Beispiel eines Audit-Transaktionsberichts herunterladen",
      bgl: () => "Beispiel BGL Simple Fund 360 XML herunterladen",
      "capital-gains": () =>
        "Beispiel eines Kapitalertragsberichts herunterladen",
      "capital-gains-nz": () =>
        "Beispielbericht zu realisierten Gewinnen/Verlusten herunterladen",
      cerfa2086: () => "Laden Sie das Musterformular Nr. 2086 herunter",
      expense: () => "Muster für die Abrechnung sonstiger Spesen herunterladen",
      "gift-and-lost": () =>
        "Beispielbericht zu ausgehenden Geschenken und verlorenen/gestohlenen Vermögenswerten herunterladen",
      income: () => "Beispiel-Einnahmenbericht herunterladen",
      inventory: () => "Beispiel-Inventarbericht herunterladen",
      "inventory-v2": () => "Beispiel-Inventarbericht herunterladen",
      "irs-1040-s1": () =>
        "Muster-IRS-Formular 1 (Formular 1040) herunterladen",
      "irs-1040-sd": () => "Muster-Formular D (Formular 1040) herunterladen",
      "irs-8949": () => "Muster-IRS-Formular 8949 herunterladen",
      "irs-8949-consolidated": () =>
        "Muster-IRS-Formular 8949 (konsolidiert) herunterladen",
      "irs-8949-long": () => "Musterformular 8949 Langzeit herunterladen",
      "irs-8949-long-consolidated": () =>
        "Musterformular 8949 (langfristig (konsolidiert)) herunterladen",
      "irs-8949-short": () => "Musterformular 8949 Kurzfristig herunterladen",
      "irs-8949-short-consolidated": () =>
        "Laden Sie das Musterformular 8949 herunter. Kurzfristig konsolidiert",
      "modelo-100": () => "Beispielbericht Modelo 100 herunterladen",
      mygov: () => "Laden Sie den MyGov-Beispielbericht herunter",
      "nft-capital-gains": () => "Heruntergeladener Beispiel-NFT-Bericht",
      "nft-report": () => "Beispiel-NFT-Bericht herunterladen",
      pack: () => "Beispielberichte herunterladen",
      "report-summary": () =>
        "Beispiel einer Berichtszusammenfassung herunterladen",
      "start-of-year-holdings": () =>
        "Beispiel-Bestandsbericht zum Jahresbeginn herunterladen",
      "tax-lots": () =>
        "Beispiel eines Inventar-Snapshot-Berichts herunterladen",
      "taxact-csv": () =>
        "Beispiel für TaxAct - Bericht zu Kapitalerträgen herunterladen",
      "trading-pnl": () => "Beispielbericht zum Derivatehandel herunterladen",
      "trading-stock": () => "Beispiel-Aktienhandelsbericht herunterladen",
      transaction: () => "Beispiel-Transaktionsbericht herunterladen",
      "turbotax-canadian-pdf": () => "TurboTax-Beispiel-PDF herunterladen",
      "turbotax-csv": () =>
        "Laden Sie das Beispiel TurboTax - Kapitalertragsbericht herunter",
      "turbotax-txf": () =>
        "Laden Sie das Beispiel TurboTax TXF - Kapitalertragsbericht herunter",
      "wiso-steuer": () =>
        "Laden Sie den Beispiel-CSV-Bericht von WISO Steuer herunter",
    },
    reportTable: () =>
      "Bitte führen Sie ein Upgrade durch, um auf die Berichtstabelle zuzugreifen",
    upgradeFor: () => "Upgrade für",
    upgradeNow: () => "Jetzt upgraden",
  },
  pending: () => "Ausstehend",
  planCard: {
    annualSubscription: () => "Jahresabonnement",
    bestValue: () => "Am beliebtesten",
    billedYearly: () => "jährliche Abrechnung",
    taxDeductible: () => "Steuerlich absetzbar",
  },
  planFeatures: {
    coreFeatures: {
      features: {
        assetHistoryLedgers: () =>
          "Vollständige doppelte Buchführung der Anlagenhistorie",
        deFiDEX: () => "DeFi- und DEX-Integrationen",
        fileImports: () => "Exchange-API und CSV-Importe",
        marginTrades: () => "Margin-Trades und Futures",
        miningStaking: () => "Mining, Staking, Verleihen, Airdrops",
        nftTrading: () => "NFT-Handel",
        smartErrorSuggestions: () =>
          "Intelligente Vorschläge zur Fehlerbehandlung",
        transanctionCategorisation: () =>
          "Algorithmus zur automatischen Transaktionskategorisierung",
        transferMatching: () => "Intelligentes Transfer-Matching",
        walletSync: () => "On-Chain-Wallet-Synchronisierung",
      },
      name: () => "Erweiterte Funktionen",
    },
    dynamic: {
      features: {
        complexTransactions: () => "Komplexe On-Chain-Transaktionen",
        integrations: () => "Integrationen",
        inventoryAdvanced: () => "Erweiterte Inventarisierungsmethoden",
        portfolioTracking: () => "Portfolio-Tracking",
        reportPreview: () => "Kostenlose Berichtsvorschau",
        reportsAdvanced: () => "Erweiterte Steuerberichte",
        reportsPrevYears: () => "Berichte für vorherige Steuerjahre",
        supportEmailChat: () => "E-Mail- und Chat-Support",
        supportPriority: () => "Vorrangiger Support",
        taxLossHarvesting: () => "Tool zur Steuerverlusternte",
        transactions: () => "Transaktionen in Steuerberichten",
      },
      name: () => "Grundfunktionen",
    },
    integrations: {
      features: {
        blockchains: () => "Blockchains",
        centralisedExchanges: () => "Zentralisierter Austausch",
        decentralisedExchanges: () => "Dezentraler Austausch",
        nftMarketplaces: () => "NFT-Marktplätze",
        smartContract: () => "Smart-Contract-Unterstützung",
      },
      name: () => "Integrationen",
    },
    smartSuggestions: {
      features: {
        airdropDetection: () => "Airdrop-Erkennung",
        balanceVerification: () => "Automatische Saldenüberprüfung",
        incorrectCategoristion: () => "Erkennung falscher Kategorisierungen",
        integration: () => "Integrationsvorschläge",
        negativeBalance: () => "Fehlende Kaufverlaufswarnungen",
        spamDetection: () => "Spam-Token-Erkennung",
        transactionCategory: () => "Vorschläge für Transaktionskategorien",
        transferMatching: () => "Passende Vorschläge übertragen",
      },
      name: () => "Kluge Vorschläge",
    },
    support: {
      features: {
        chat: () => "Chat-Unterstützung",
        email: () => "E-Mail-Support",
        priority: () => "Vorrangiger Support",
      },
      name: () => "Unterstützung",
    },
    transactionsManagement: {
      features: {
        advancedFiltering: () => "Erweiterte Filterung",
        bulkEdit: () => "Massenbearbeitungsfunktion",
        noteTaking: () => "Notizen machen",
        protocolIdentification: () =>
          "Protokollidentifikation und -beschriftung",
        quickSearch: () => "Schnellsuche",
      },
      name: () => "Transaktionsverwaltung",
    },
  },
  plans: {
    personal: () => "Persönlich",
    professional: () => "Buchhalter",
  },
  "polygon-zkevm": {
    "wallet-api": {
      0: () =>
        "Geben Sie Ihre Polygon Hermez (zkEVM)-Wallet-Adresse in den Crypto Tax Calculator ein, geben Sie einen optionalen Spitznamen ein und klicken Sie auf „Wallet hinzufügen“.",
      1: {
        items: {
          0: () =>
            "Sie können mehrere Wallets hinzufügen, indem Sie eine weitere Adresse eingeben und auf „Weitere Wallet hinzufügen“ klicken.",
        },
        type: () => "Notiz",
      },
    },
  },
  popup: {
    enablePopups: () => "Bitte aktivieren Sie Popups, um fortzufahren",
  },
  portfolioToggles: () => "Portfolio-Umschalter",
  qbo: {
    accountMapping: {
      accounts: {
        asset: {
          title: () => "Vermögenskonto",
          tooltip: () =>
            "Konto, das den Wert der Vermögenswerte im Portfolio des Benutzers darstellt",
        },
        cash: {
          title: () => "Kassenkonto",
          tooltip: () =>
            "Konto, das Bargeldbestände darstellt. Wenn Sie Krypto verkaufen, stellen wir den Barerlös hier dar und umgekehrt, wenn Sie Krypto kaufen. Krypto-zu-Krypto-Transaktionen haben keine Auswirkungen auf dieses Konto.",
        },
        expense: {
          title: () => "Aufwandskonto",
          tooltip: () => "Konto, das Kryptoausgaben darstellt",
        },
        fee: {
          title: () => "Gebührenkonto",
          tooltip: () =>
            "Konto, auf dem der gezahlte Gebührenbetrag angegeben ist",
        },
        income: {
          title: () => "Ertragskonto",
          tooltip: () => "Konto, das Einkünfte aus Kryptowährungen darstellt",
        },
        loan: {
          title: () => "Darlehenskonto",
          tooltip: () => "Konto für Kryptokredite und -rückzahlungen",
        },
        loss: {
          title: () => "Verlustrechnung",
          tooltip: () => "Konto, das den verlorenen Kryptobetrag darstellt",
        },
        "realised-gain": {
          title: () => "Realisiertes Gewinnkonto",
          tooltip: () =>
            "Konto, das Gewinne aus der Veräußerung von Krypto-Assets darstellt",
        },
        "realised-loss": {
          title: () => "Realisiertes Verlustkonto",
          tooltip: () =>
            "Konto, das Verluste darstellt, die durch die Veräußerung von Krypto-Assets entstanden sind",
        },
        "zero-cost-buy": {
          title: () => "Fehlendes Kaufhistorie-Konto",
          tooltip: () =>
            "Wenn ein Vermögenswert gekauft oder erworben wird und keine Zahlung dafür geleistet wird, betrachten wir die Kostenbasis des Vermögenswerts als Marktwert und dieses Konto wird verwendet, um die andere Seite des Journals auszugleichen.",
        },
      },
      lastSynced: (d) => "Zuletzt synchronisiert am " + d.date,
      refreshInstructions: () =>
        "Wenn Sie bei QuickBooks ein neues Konto erstellt haben und es nicht sehen, aktualisieren Sie die Liste.",
      syncError: () => "Wir konnten die Daten nicht synchronisieren.",
      syncInstructions: () =>
        "Um mit der Synchronisierung zu beginnen, müssen Sie auswählen, welchen Konten die Daten zugeordnet werden. Sie können die Konten jederzeit aktualisieren. Die Synchronisierung kann gestartet werden, wenn die Zuordnung festgelegt ist.",
      title: () => "Kontozuordnung",
    },
    buttons: {
      disconnect: () => "Von QuickBooks trennen",
      refreshList: {
        completed: () => "QuickBooks-Kontenliste aktualisiert",
        done: () => "Kontoliste aktualisieren",
        pending: () => "Liste aktualisieren",
        tooltip: {
          done: () => "Verfügbare Konten aktualisieren",
          pending: () => "Dies kann einige Zeit dauern",
        },
      },
      syncAccounts: {
        done: () => "Konten synchronisieren",
        pending: () => "Konten synchronisieren",
        tooltip: {
          disabled: () =>
            "Stellen Sie sicher, dass jedes Konto ausgewählt ist, um die Synchronisierung zu aktivieren",
          done: () => "Konten mit QuickBooks synchronisieren",
          pending: () => "Dies kann einige Zeit dauern",
        },
      },
    },
    connect: () => "Mit QuickBooks verbinden",
    errors: {
      disconnecting: () =>
        "Beim Trennen der Verbindung zu QuickBooks ist ein Fehler aufgetreten.",
      fetchingAccounts: () =>
        "Beim Abrufen der QuickBooks-Kontenliste ist ein Fehler aufgetreten.",
      fetchingSettings: () =>
        "Beim Abrufen der QuickBooks-Einstellungen ist ein Fehler aufgetreten.",
      fetchingTenants: () =>
        "Beim Abrufen der QuickBooks-Mieterliste ist ein Fehler aufgetreten.",
      updatingAccounts: () =>
        "Beim Aktualisieren der QuickBooks-Konten ist ein Fehler aufgetreten.",
      updatingTenant: () =>
        "Beim Aktualisieren des QuickBooks-Mandanten ist ein Fehler aufgetreten.",
    },
    integrationName: () => "QuickBooks Online",
    integrationShortName: () => "QBO",
    newIntegration: {
      connected: () => " Verbunden",
      connectedAt: (d) => "Zuletzt verbunden " + d.date,
      description: () =>
        "Fassen Sie tägliche Handelsaktivitäten in Buchhaltungsjournalen in QuickBooks zusammen. Dies kann Ihnen dabei helfen, den Überblick über Ihre Finanzen zu behalten und Einnahmequellen, Verluste und Ausgaben zu verstehen. Die Kontenzuordnungen können an Ihre Buchhaltungsgewohnheiten angepasst werden.",
      disconnectConfirmation: () =>
        "Achtung! Möchten Sie Ihr QuickBooks-Konto wirklich trennen? Diese Aktion kann nicht rückgängig gemacht werden. Sie müssen Ihre Konten in Zukunft erneut auswählen. Ihre synchronisierten Journale bleiben in QuickBooks.",
      disconnected: () => "Getrennt",
      status: () => "Status: ",
      title: () => "QuickBooks-Konto verbinden",
    },
    reportFooter: {
      settingsLink: () =>
        "Weitere Informationen finden Sie in den Einstellungen.",
      subTitle: () => "Verbinden Sie den Krypto-Steuerrechner mit QuickBooks",
      title: () => "QuickBooks-Integration",
    },
    rollup: {
      day: () => "Tag",
      description: {
        day: () =>
          "Alle Transaktionen eines Kalendertages werden in einem einzigen Journal zusammengefasst.",
        month: () =>
          "Alle Transaktionen eines Kalendermonats werden in einem einzigen Journal zusammengefasst.",
        none: () =>
          "Alle Transaktionen werden als einzelne Journale exportiert",
      },
      month: () => "Monat",
      none: () => "Kein Rollup",
    },
    syncDialog: {
      confirmation: () =>
        "Möchten Sie wirklich synchronisieren? Bitte stellen Sie sicher, dass Ihre Daten korrekt sind. Änderungen an Transaktionen, die bereits synchronisiert wurden, werden in QuickBooks nicht berücksichtigt.",
      error: () => "Oh nein! Es scheint, als wäre etwas schiefgelaufen....",
      syncing: () =>
        "Wir synchronisieren gerade die Daten, dies kann einige Zeit dauern ...",
    },
    tenantList: {
      ensureCorrectTenant: () =>
        "Stellen Sie sicher, dass für den Datenfeed das richtige Konto ausgewählt ist.",
      exportJournals: () => "Journale exportieren",
      listPlaceholder: () => "Datenfeed auswählen",
      listTitle: () => "QuickBooks-Konto",
    },
  },
  reOnboarding: {
    sync: {
      ExchangeBlockchain: () => "Börse/Blockchain",
      lastSynced: () => "Zuletzt synchronisiert",
      noImport: () =>
        "Keine Börse oder Blockchain erkannt. Fügen Sie Ihr erstes Konto hinzu, um zu beginnen",
    },
    welcomeBack: {
      afterSyncBanner: () =>
        "Die Synchronisierung kann einige Zeit in Anspruch nehmen. Frischen Sie in der Zwischenzeit Ihre Kenntnisse zu den wichtigsten Steuerregeln auf und nutzen Sie die App!",
      brushUp: () =>
        "Frischen Sie Ihre Kenntnisse über die wichtigsten Steuerregeln auf",
      integrationsPage: () => "Integrationsseite",
      letMeIn: () => "Lass mich einfach rein",
      subTitle: () =>
        "Bevor Sie wieder damit beginnen, denken Sie daran, Ihre Konten erneut zu synchronisieren und alle neuen Konten zu importieren. Hier ist eine Liste Ihrer Börsen und Blockchains:",
      sync: () => "Synchronisieren",
      syncFailedBanner: () =>
        "Einige Integrationssynchronisierungen sind fehlgeschlagen. Um diese Probleme zu beheben, fahren Sie mit dem",
      title: () =>
        "Willkommen zurück, mein alter Freund. Es ist wieder Zeit, Steuern zu machen.",
    },
  },
  reconciliation: {
    allTransactions: () => "Alle Transaktionen",
    api: {
      assignBlockchainSource: () =>
        "Bei der Zuordnung der Blockchain ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      bulkLookupMarketPriceError: () =>
        "Bei der Suche nach diesen Währungen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      generateTransferError: () =>
        "Beim Generieren der Überweisungen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      identifyUnknownParty: () =>
        "Beim Ersetzen des unbekannten Teilnehmers ist ein Fehler aufgetreten. Versuchen Sie es erneut.",
      ignoreAllMissingPriceError: () =>
        "Beim Ignorieren aller fehlenden Marktpreisprobleme ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      ignoreError: () =>
        "Beim Ignorieren des Problems ist ein Fehler aufgetreten. Versuchen Sie es erneut.",
      markAirdropError: () =>
        "Beim Markieren dieser Transaktion als Airdrop ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      markAllAirdropError: () =>
        "Beim Markieren dieser Transaktionen als Airdrops ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      markAllSpamCurrencyError: () =>
        "Beim Markieren dieser Transaktionen als Spam ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      markSpamCurrencyError: () =>
        "Beim Markieren dieser Transaktion als Spam ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      refreshError: () =>
        "Beim Aktualisieren der Abstimmungsdaten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    },
    backToTransactions: () => "Zurück zu den Transaktionen",
    completedCountChip: (d) => d.issueCount + " % kategorisiert",
    completedCountEmbeddedChip: (d) => d.issueCount + " % abgeschlossen",
    explainer: {
      consider: () => "Bedenken Sie Folgendes:",
      done: () => "Erledigt",
      usefulLinks: () => "Nützliche Links:",
    },
    fullyReconciledState: {
      ctaText: () => "Steuerbericht abrufen",
      disclaimer: () =>
        "Überprüfen Sie stets doppelt, ob alles richtig aussieht.",
      neutralMessage: () => "Derzeit liegen keine Abstimmungsvorschläge vor.",
      successMessage: () => "Alle Vorschläge wurden gelöst!",
      successSubMessage: () =>
        "Sie haben alle unsere Versöhnungsvorschläge umgesetzt, gute Arbeit!",
    },
    helpArticles: () => "Hilfe-Artikel",
    ignoreIssue: () => "Problem ignorieren",
    ignoreWarning: () => "Warnung ignorieren",
    impactChip: {
      high: () => "Hohe Wirkung",
      low: () => "Geringe Auswirkung",
      medium: () => "Mittlere Auswirkung",
    },
    issueCountChip: (d) =>
      plural(d.issueCount, 0, de, {
        one: " 1 Vorschlag ",
        other: number("de", d.issueCount, 0) + " Vorschläge ",
      }),
    issues: {
      incorrectSendReceives: {
        associatedTransactions: () => "Transaktionen",
        ctaButton: {
          importDestination: () => "Ziel importieren",
          importSource: () => "Importquelle",
          name: () => "Name",
        },
        description: () =>
          "Transaktionen in dieser Kategorie wurden fälschlicherweise als interne Sende- oder Empfangsvorgänge kategorisiert. Senden und Empfangen sollten nur zwischen Wallets verwendet werden, die Ihnen gehören. Diese Transaktionen sollten entweder einer anderen Kategorie zugeordnet werden oder das unbekannte Wallet sollte importiert werden.",
        ignore: () => "Ignorieren",
        ignoreIssue: {
          action: () => "Problem ignorieren",
          description: (d) =>
            "Dadurch werden alle Warnungen bezüglich fehlender Importe bei Transaktionen von oder zu " +
            select(d.source, { missing: "this source", other: d.source }) +
            " ignoriert.",
          title: () => "Vermutlich fehlendes Importproblem ignorieren?",
        },
        importSource: () => "Importquelle",
        imports: {
          address: () => "Adresse",
          bannerText: () =>
            "Das Importieren einer fremden Brieftasche oder einer Vertragsadresse wirkt sich auf Ihre Steuerpflichten aus.",
          bannerTitle: () =>
            "Stellen Sie vor dem Importieren sicher, dass diese Brieftasche Ihnen gehört!",
          goBack: () => "Geh zurück",
          label: () => "Finden Sie Ihre Börse oder Wallet",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
            title: () => "Lösen von Balanceproblemen während des Fluges",
          },
        },
        missingSource: () => "Quelle",
        navButton: () => "Falsche Sende-/Empfangsvorgänge",
        notMyWallet: () => "Nicht mein Geldbeutel",
        steps: {
          0: () =>
            "Gehört die Quelle oder das Ziel Ihnen? Verwenden Sie die Importieren-Schaltfläche und importieren Sie die Börse oder das Wallet.",
          1: () =>
            "Wenn die Quelle oder das Ziel nicht Ihnen gehört, erweitern Sie das Akkordeon und kategorisieren Sie die Transaktionen richtig.",
        },
        tableHeader: {
          assets: () => "Vermögenswerte",
          associatedGainsLaptop: () => "Zugehörige Gewinne",
          destination: () => "Ziel",
          source: () => "Quelle",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transaktionen",
          transferAmount: () => "Menge",
          transferAmountLaptop: () => "Überweisungsbetrag",
        },
        title: () => "Falsche Sende-/Empfangsvorgänge",
        transferBalance: () => "Überweisungsbetrag",
        transferBalanceTooltip: () =>
          "Der Überweisungsbetrag stellt den Fiat-Wert der Kryptowährung dar, die von und zu diesem mutmaßlich fehlenden Import gesendet wird.",
        viewTransactions: () => "Transaktionen anzeigen",
      },
      missingBlockchain: {
        assignBlockchain: () => "Blockchain zuweisen",
        chipTitle: () => "Fehlende Blockchains beheben",
        description: () => "Diesen Transaktionen eine Kette zuweisen",
        headers: {
          amount: () => "Menge",
          assets: () => "Vermögenswerte",
          source: () => "Quelle",
          transactions: () => "Transaktionen",
          transferAmount: () => "Überweisungsbetrag",
          txs: () => "Tx's",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6388387-how-to-resolve-missing-blockchain-warnings",
            title: () => "So beheben Sie fehlende Blockchain-Warnungen",
          },
        },
        navButton: () => "Blockchains zuweisen",
        steps: {
          0: () =>
            "Erkennen Sie die Transaktion und erinnern Sie sich an die Blockchain?",
          1: () =>
            "Welcher Block-Explorer gibt ein Ergebnis für die Transaktions-ID zurück?",
        },
        title: () => "Fehlende Blockchains",
      },
      missingPrice: {
        added: () => "Hinzugefügt",
        bulkLookupButton: {
          failUpdated: () =>
            "Für diese Transaktionen konnten keine Marktpreise gefunden werden",
          label: () => "Preis nachschlagen",
          successUpdated: (d) =>
            "Aktualisierter Marktpreis für " +
            plural(d.count, 0, de, {
              one: " 1 Transaktion ",
              other: number("de", d.count, 0) + " Transaktionen ",
            }),
        },
        chipTitle: () => "Fehlende Preise beheben",
        ctaButton: {
          label: () => "Warnung ignorieren",
          labelLaptop: () => "Warnung ignorieren",
        },
        description: () =>
          "Preise für eine Währung in den folgenden Transaktionen konnten nicht gefunden werden",
        drawer: {
          cta: () => "Warum fehlen Preise?",
          headerTwo: () =>
            "Was ist, wenn das Token mit fehlendem Preis weit verbreitet ist und auf Diensten wie Coingecko gelistet ist?",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5411667-how-to-fix-missing-market-price-warning",
              title: () => "Fehlende Marktpreise",
            },
          },
          linkSectionHeader: () => "Hilfe-Artikel",
          listItems: {
            0: () => "Das Token hat eine extrem geringe Liquidität.",
            1: () =>
              "Das Token ist brandneu und wurde noch nicht vom Preisorakel erfasst.",
            2: () =>
              "Das Token ist an den großen Börsen und Dexems nicht notiert.",
            3: () => "Nicht aufgeführt.",
          },
          listTitle: () =>
            "Einige Szenarien, in denen Informationen zu historischen Marktpreisen fehlen:",
          paragraphOne: () =>
            "Fehlende Preise treten normalerweise auf, wenn ein Preis für ein Token in keiner der von uns verwendeten Preisorakel-Datenbanken vorhanden ist. Dies ist äußerst selten, da wir Preise für über 300.000 Währungen haben.",
          paragraphTwo: () =>
            "Wenn Sie das Token auf Coingecko finden, ist es sehr wahrscheinlich, dass CTC die falsche Instanz des Tokens zuweist. Der Transaktion wurde also ein Token mit demselben Ticker zugewiesen. Sie können das Problem lösen, indem Sie auf die Währung klicken und die Währung im bereitgestellten Menü ändern.",
        },
        ignoreAllIssue: {
          action: () => "Alle ignorieren",
          description: () =>
            "Dadurch werden fehlende Preiswarnungen für alle Transaktionen in Ihrem Konto ignoriert und die Aktion ist unumkehrbar. Sie sollten nur fortfahren, wenn die Preise 0 $ betragen.",
          title: () => "Alle fehlenden Preiswarnungen ignorieren?",
        },
        ignoreIssue: {
          action: () => "Warnungen ignorieren",
          description: (d) =>
            select(d.name, {
              unknown:
                " Dadurch werden alle fehlenden Preiswarnungen bei Transaktionen, bei denen diese Währung verwendet wird, ignoriert. ",
              other:
                " Dadurch werden alle fehlenden Preiswarnungen bei Transaktionen, bei denen " +
                d.name +
                " verwendet wird, ignoriert. ",
            }),
          title: (d) =>
            "Fehlende Preisprobleme für " +
            select(d.name, { unknown: " diese Währung ", other: " name " }) +
            select(d.ticker, { unknown: "", other: " ( " + d.ticker + " ) " }) +
            " ignorieren?",
        },
        missingPrice: () => "Fehlender Preis",
        navButton: () => "Fehlende Preise beheben",
        tableHeader: {
          assets: () => "Vermögenswerte",
          associatedGains: () => "Belohnungen",
          associatedGainsLaptop: () => "Zugehörige Gewinne",
          currency: () => "Währung",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transaktionen",
        },
        title: () => "Transaktionen mit fehlenden Preisen",
        viewTransactions: () => "Transaktionen anzeigen",
      },
      negativeBalance: {
        amountExplanation: () =>
          "Wenn Sie das Problem mit der fehlenden Kaufhistorie nicht beheben, werden Ihre Steuerpflichten so berechnet, als ob Ihre Kostenbasis für alle betroffenen Vermögenswerte null wäre. Dadurch erhöht sich Ihre Steuerschuld.",
        chipTitle: () => "Fehlenden Kaufverlauf beheben",
        ctaButton: {
          label: () => "Währungsbuch",
          labelLaptop: () => "Ansicht in der Transaktionstabelle",
        },
        currency: () => "Währung",
        description: () =>
          "Fehlende Kaufhistorie tritt auf, wenn mehr Krypto-Assets verkauft wurden, als ursprünglich gekauft wurden. Dies deutet darauf hin, dass einige Erwerbsaufzeichnungen möglicherweise fehlen, nicht kategorisiert oder falsch kategorisiert sind.",
        drawer: {
          cta: () => "Was fehlt in der Kaufhistorie?",
          headerTwo: () => "Beheben des fehlenden Kaufverlaufs.",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
              title: () => "Beheben von Problemen mit fehlendem Kaufverlauf",
            },
            1: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5305370-faq-what-is-a-zero-cost-buy",
              title: () => "Was ist ein Nullkostenkauf",
            },
          },
          linkSectionHeader: () => "Hilfe-Artikel",
          listTitle: () =>
            "Wenn Sie definitiv alle Ihre Konten hochgeladen haben, können Warnungen zu fehlenden Kaufverläufen aus einem oder mehreren der folgenden Gründe auftreten:",
          orderedListItems: {
            0: () => "Ein fehlender Kauf oder Handel",
            1: () =>
              "Transaktionen wurden nicht oder falsch kategorisiert. Beispielsweise wurde ein Kauf als Empfang kategorisiert.",
            2: () =>
              "Fehlendes Senden/Empfangen. Senden und Empfangen sollten immer zu einer Übertragung zusammengefasst werden. So muss beispielsweise jedes Senden ein entsprechendes Empfangen haben und umgekehrt.",
          },
          paragraphOne: () =>
            "Fehlende Kaufhistorie tritt auf, wenn mehr Krypto-Assets verkauft wurden, als ursprünglich gekauft wurden. Dies deutet darauf hin, dass einige Erwerbsaufzeichnungen möglicherweise fehlen, nicht kategorisiert oder falsch kategorisiert sind.",
          paragraphThree: () =>
            "In manchen Fällen kann es sein, dass eine Ihrer Börsen die Staking-Belohnungen oder Gebühren nicht korrekt meldet.",
          paragraphTwoBoldSection: () =>
            "Der erste Schritt zum Beheben fehlender Kaufhistorien besteht darin, sicherzustellen, dass Sie ALLE Ihre Wallet- und Exchange-Konten hochgeladen haben.",
          paragraphTwoRegularSection: () =>
            "Dies ist die häufigste Ursache, da es zu erheblichen Lücken in Ihrem Transaktionsverlauf führt.",
        },
        gains: () => "Zugehörige Gewinne",
        grouping: {
          byCurrency: () => "Nach Währung",
          byWallet: () => "Per Import",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
            title: () => "Beheben von Problemen mit fehlendem Kaufverlauf",
          },
          1: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
            title: () => "Lösen von Balanceproblemen während des Fluges",
          },
          2: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5284400-balance-remaining-is-incorrect",
            title: () => "Der verbleibende Saldo ist falsch",
          },
          3: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5305370-handling-zero-cost-buy-transactions",
            title: () => "Abwicklung von kostenlosen Kauftransaktionen",
          },
        },
        navButton: () => "Fehlenden Kaufverlauf beheben",
        steps: {
          0: () =>
            "Stellen Sie sicher, dass Sie alle Transaktionen aller Geschäftsjahre importiert haben, einschließlich OTC-Geschäfte.",
          1: () =>
            "Klicken Sie auf die zugehörigen Transaktionen. Ein neues Fenster mit allen Transaktionen aus dieser bestimmten Quelle wird geöffnet. Stellen Sie sicher, dass alle Transaktionen kategorisiert sind.",
          2: () =>
            "Erstellen Sie eine manuelle Transaktion, um fehlende Daten von Börsen- oder OTC-Transaktionen zu verarbeiten.",
        },
        tableHeader: {
          amountNegative: () => "Menge",
          amountNegativeDesktop: () => "Fehlender Betrag",
          assets: () => "Währungen",
          associatedGains: () => "Belohnungen",
          associatedGainsLaptop: () => "Zugehörige Gewinne",
          currency: () => "Währung",
          source: () => "Quelle",
          taxImpact: () => "Steuerliche Auswirkungen",
          transactions: () => "Tx's",
          transactionsDesktop: () => "Transaktionen",
          transferAmount: () => "Menge",
          transferAmountDesktop: () => "Überweisungsbetrag",
        },
        title: () => "Fehlenden Kaufverlauf überprüfen",
        transactions: () => "Transaktionen",
        viewLedger: () => "Währungsbuch",
        viewTransactions: () => "Transaktionen anzeigen",
      },
      negativeBalanceByWallet: {
        title: () => "Probleme mit fehlender Kaufhistorie",
      },
      smartContractInteractions: {
        categorise: () => "Kategorisieren",
        description: () =>
          "Bei diesen Transaktionen handelt es sich um Interaktionen zwischen Ihren Wallets und Smart Contracts. Untersuchen Sie jede Vertragsadresse, versehen Sie sie bei Bedarf mit einem passenden Namen und kategorisieren Sie die Transaktionen.",
        navButton: () => "Transaktionen kategorisieren",
        tableHeader: {
          addresses: () => "Adressen",
          amount: () => "Menge",
          assets: () => "Vermögenswerte",
          transactions: () => "Transaktionen",
          transferAmount: () => "Überweisungsbetrag",
          txs: () => "Tx's",
        },
        title: () => "Smart-Contract-Interaktionen",
      },
      suspectedAirdropTransaction: {
        description: () =>
          "Zu den mutmaßlichen Airdrops zählen bekannte Quelladressen von Airdrops. Markieren Sie eine Adresse, um zu bestätigen, dass es sich um einen Airdrop handelt.",
        links: {
          0: {
            href: () =>
              "https://cryptotaxcalculator.io/blog/crypto-airdrop-tax",
            title: () => "Wie werden Airdrops besteuert?",
          },
        },
        markAllAsTrade: {
          button: () => "Alle als Airdrops markieren",
          confirm: () => "Bestätigen",
          description: () =>
            "Dadurch wird allen hier aufgeführten Transaktionen die Kategorie „Airdrop“ zugewiesen und diese Probleme behoben.",
          title: () => "Alle Transaktionen als Airdrops markieren?",
        },
        markAsNotTrade: () => "Als „kein Airdrop“ markieren",
        markAsTrade: () => "Als Airdrop markieren",
        markSelectedAsTrade: () => "Ausgewählte als Airdrops markieren",
        navButton: () => "Transaktionen kategorisieren",
        notTrade: () => "Kein Airdrop",
        steps: {
          0: () => "Erkennen Sie den Token-Namen?",
          1: () =>
            "Erinnern Sie sich an die Interaktion mit dem Protokoll zum Empfangen des Tokens?",
        },
        title: () => "Vermutete Luftabwürfe",
        trade: () => "Airdrop",
      },
      suspectedBurnTransaction: {
        description: () =>
          "Wir haben einige Transaktionen erkannt, die Token an eine bekannte Burner-Adresse gesendet haben. Markieren Sie, ob es sich bei diesen Transaktionen um Burns handelt.",
        markAllAsTrade: {
          button: () => "Alle als Brun markieren",
          confirm: () => "Bestätigen",
          description: () =>
            "Dadurch wird allen hier aufgeführten Transaktionen die Kategorie „Burn“ zugewiesen und diese Probleme behoben.",
          title: () => "Alle Transaktionen als „Burn“ markieren?",
        },
        markAsNotTrade: () => "Als 'not burn' markieren",
        markAsTrade: () => "Als Brun markieren",
        markSelectedAsTrade: () => "Auswahl zum Brun markieren",
        navButton: () => "Transaktionen kategorisieren",
        notTrade: () => "Not burn",
        steps: {
          0: () => "Erkennen Sie den Token-Namen?",
          1: () =>
            "Erinnern Sie sich, die Token an eine Burn-Adresse gesendet zu haben?",
        },
        title: () => "Verdächtige Burn-Transaktionen",
        trade: () => "Brun",
      },
      suspectedMissingImports: {
        associatedTransactions: () => "Transaktionen",
        ctaButton: {
          label: () => "Import",
          name: () => "Name",
        },
        description: () =>
          "Bei mutmaßlich fehlenden Importen handelt es sich um Adressen, mit denen Sie interagiert haben und die nicht in den Crypto Tax Calculator importiert wurden. Beheben Sie Warnungen aus dieser Abstimmungskategorie, indem Sie die fehlende Adresse importieren oder bestätigen, dass diese Brieftasche nicht Ihnen gehört.",
        ignore: () => "Ignorieren",
        ignoreIssue: {
          action: () => "Problem ignorieren",
          description: (d) =>
            "Dadurch werden alle Warnungen bezüglich fehlender Importe bei Transaktionen von oder zu " +
            select(d.source, { missing: "this source", other: d.source }) +
            " ignoriert.",
          title: () => "Vermutlich fehlendes Importproblem ignorieren?",
        },
        importSource: () => "Importquelle",
        imports: {
          address: () => "Adresse",
          bannerText: () =>
            "Das Importieren einer fremden Brieftasche oder einer Vertragsadresse wirkt sich auf Ihre Steuerpflichten aus.",
          bannerTitle: () =>
            "Stellen Sie vor dem Importieren sicher, dass diese Brieftasche Ihnen gehört!",
          goBack: () => "Geh zurück",
          label: () => "Finden Sie Ihre Börse oder Wallet",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279670-import-exchange-transactions",
            title: () => "Devisentransaktionen importieren",
          },
          1: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279724-import-wallet-and-defi-transactions",
            title: () => "Wallet- und DeFi-Transaktionen importieren",
          },
          2: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5597463-how-to-add-an-additional-wallet-for-the-same-blockchain",
            title: () =>
              "Wie füge ich ein zusätzliches Wallet für dieselbe Blockchain hinzu?",
          },
        },
        missingSource: () => "Quelle",
        navButton: () => "Vermutlich fehlende Importe",
        notMyWallet: () => "Nicht mein Geldbeutel",
        steps: {
          0: () =>
            "Haben Sie Vermögenswerte zwischen Ihren eigenen Wallets transferiert? Möglicherweise haben Sie vergessen, die Daten aus einem dieser Wallets zu importieren.",
          1: () =>
            "Wenn es sich um eine Transaktion zwischen Ihnen und einem externen Wallet handelt, können Sie die Kategorie auswählen, die diese Transaktionen am besten beschreibt. Wählen Sie nicht „Senden“ oder „Empfangen“, da diese Kategorien nur für Überweisungen zwischen Wallets verwendet werden sollten, die Ihnen gehören.",
        },
        tableHeader: {
          assets: () => "Vermögenswerte",
          associatedGainsLaptop: () => "Zugehörige Gewinne",
          source: () => "Quelle",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transaktionen",
          transferAmount: () => "Menge",
          transferAmountLaptop: () => "Überweisungsbetrag",
        },
        title: () => "Vermutlich fehlende Importe",
        transferBalance: () => "Überweisungsbetrag",
        transferBalanceTooltip: () =>
          "Der Überweisungsbetrag stellt den Fiat-Wert der Kryptowährung dar, die von und zu diesem mutmaßlich vermissten Import gesendet wird.",
        viewImport: () => "Import anzeigen",
        viewTransactions: () => "Transaktionen anzeigen",
      },
      suspectedSpamToken: {
        currency: () => "Währung",
        description: () =>
          "Verdächtige Spam-Token werden dadurch identifiziert, dass sie keine Marktpreisdaten aufweisen, nur eine einzige eingehende Transaktion aufweisen oder von einer bekannten Spam-Quelle stammen. Wenn Sie eine Transaktion als Spam markieren, wird sie ausgeblendet und aus Ihren Kontostandberechnungen ausgeschlossen.",
        headers: {
          asset: () => "Vermögenswert",
          price: () => "Preis",
          source: () => "Quelle",
          transactions: () => "Transaktionen",
          txs: () => "Tx's",
          value: () => "Wert",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6185693-dealing-with-scam-airdrops",
            title: () => "Umgang mit betrügerischen Airdrops",
          },
        },
        markAllAsSpam: {
          button: () => "Alle als Spam markieren",
          confirm: () => "Bestätigen",
          description: () =>
            "Dadurch wird allen hier aufgeführten Transaktionen die Kategorie „Spam“ zugewiesen und diese Probleme behoben.",
          title: () => "Alle Transaktionen als Spam markieren?",
        },
        markAsNotSpam: () => "Als „Kein Spam“ markieren",
        markAsSpam: () => "Als Spam markieren",
        markSelectedAsSpam: () => "Ausgewähltes als Spam markieren",
        navButton: () => "Transaktionen kategorisieren",
        notSpam: () => "Kein Spam",
        spam: () => "Spam",
        steps: {
          0: () => "Erkennen Sie den Token-Namen?",
          1: () =>
            "Erinnern Sie sich an die Interaktion mit dem Protokoll zum Empfangen des Tokens?",
          2: () =>
            "Wenn keiner der oben genannten Punkte zutrifft, handelt es sich bei diesem Token wahrscheinlich um einen Betrugsversuch und er sollte als Spam gekennzeichnet werden.",
        },
        title: () => "Verdächtige Spam-Token",
        transactionSource: () => "Transaktionsquelle",
        transactions: () => "Transaktionen",
      },
      uncategorisedIntraExchangeTransactions: {
        associatedTransactions: () => "Transaktionen",
        ctaButton: {
          label: () => "Kategorisieren",
        },
        description: () =>
          "Intra-Exchange-Transaktionen sind Transaktionen, bei denen Quelle und Ziel dieselbe Börse sind. Transaktionen in dieser Kategorie sind üblicherweise Zinszahlungen, Staking-Belohnungen, Einkommen oder Airdrops.",
        missingSource: () => "Quelle",
        navButton: () => "Transaktionen kategorisieren",
        tableHeader: {
          assets: () => "Vermögenswerte",
          associatedGainsLaptop: () => "Zugehörige Gewinne",
          source: () => "Quelle",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transaktionen",
          transferAmount: () => "Menge",
          transferAmountLaptop: () => "Überweisungsbetrag",
        },
        title: () => "Intra-Börsen-Transaktionen",
        transferBalance: () => "Überweisungsbetrag",
        transferBalanceTooltip: () =>
          "Der Überweisungsbetrag stellt den Fiat-Wert der Kryptowährung dar, die von und zu diesem mutmaßlich vermissten Import gesendet wird.",
        viewTransactions: () => "Transaktionen anzeigen",
      },
      uncategorisedTransactions: {
        button: () => "Kategorisieren",
        chipTitle: () => "Transaktionen kategorisieren",
        description: () =>
          "Wenden Sie die entsprechende Kategorie auf die folgenden Transaktionen an, um die Genauigkeit Ihres Berichts zu verbessern",
        drawer: {
          cta: () => "Mehr über Kategorisierung erfahren",
          headerOne: () => "Warum Kategorisierung wichtig ist",
          headerTwo: () =>
            "Sollte nicht alles automatisch kategorisiert werden?",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5279619-categorize-transactions",
              title: () => "Detaillierte Anleitung für alle Transaktionsarten",
            },
          },
          linkSectionHeader: () => "Hilfe-Artikel",
          paragraphOne: () =>
            "Durch die Kategorisierung kann CTC das entsprechende Steuerergebnis auf die Transaktion anwenden und die Kostenbasis in Ihrem gesamten Transaktionsverlauf genau verfolgen. Nicht kategorisierte Transaktionen werden als „Eingehend“ und „Ausgehend“ gekennzeichnet. Diese werden bei Ihren Steuerberechnungen ignoriert, was aufgrund von Lücken in Ihrer Kostenbasisverfolgung zu höheren Kapitalgewinnen führen kann.",
          paragraphTwo: () =>
            "Wenn Sie alle Ihre Konten importiert haben, auch die, die Sie nicht mehr verwenden, sollten die meisten Ihrer Transaktionen automatisch kategorisiert werden. In einigen Fällen konnte keine geeignete Kategorie für Transaktionen ermittelt werden. Anstatt Annahmen zu treffen, heben wir diese zur Verdeutlichung hervor.",
        },
        inContext: {
          categorised: () => "Kategorisiert!",
          finish: () => "Beenden",
          goToNext: () => "Zur nächsten Transaktion gehen",
          nextTransaction: () => "Nächste Transaktion",
          notSure: () => "Nicht sicher? Zur nächsten Transaktion",
          previousTransaction: () => "Vorherige Transaktion",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279619-categorise-transactions",
            title: () => "Transaktionen kategorisieren",
          },
        },
        navButton: () => "Transaktionen kategorisieren",
        similarChip: (d) => d.count + " ähnlich",
        steps: {
          0: () =>
            "Stellen Sie sicher, dass Sie alle Ihre Daten aus allen möglichen Quellen importiert haben.",
          1: () =>
            "Weisen Sie die Kategorien der unten angezeigten Transaktionen manuell zu.",
        },
        title: () => "Transaktionen klären",
        transactionSource: () => "Transaktionsquelle",
        type: () => "Kategorie",
        uncategorisedTransactions: () => "Nicht kategorisierte Transaktionen",
        viewSwitcher: {
          inContext: () => "Im Kontext",
          onlyUncategorised: () => "Nur unkategorisiert",
        },
        viewTransactions: () => "Transaktionen anzeigen",
      },
      uncategorisedWalletInteractions: {
        associatedTransactions: () => "Transaktionen",
        categorise: () => "Kategorisieren",
        description: () =>
          "Wallet-Interaktionen sind andere Wallet-Adressen, mit denen Ihre importierten Wallets interagiert haben, wie z. B. Ihre eigenen Wallets, Exchange-Wallets oder die Wallets anderer Personen. Beheben Sie diese Warnungen, indem Sie die Interaktionen richtig kategorisieren.",
        navButton: () => "Transaktionen kategorisieren",
        steps: {
          0: () =>
            "Haben Sie Vermögenswerte zwischen Ihren eigenen Wallets transferiert? Wenn ja, sollten ausgehende Transaktionen als „Senden“ und eingehende Transaktionen als „Empfangen“ kategorisiert werden.",
          1: () =>
            "Wenn Sie mit der Brieftasche einer anderen Person interagiert haben, sollten Sie Kategorien wie „Geschenk“, „Eigene Nutzung“, „Verkauf“ oder „Kauf“ in Betracht ziehen.",
        },
        tableHeader: {
          assets: () => "Vermögenswerte",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transaktionen",
          transferAmount: () => "Menge",
          transferAmountLaptop: () => "Überweisungsbetrag",
          wallets: () => "Vermögenswerte",
        },
        title: () => "Wallet-Interaktionen",
        transferBalance: () => "Überweisungsbetrag",
      },
      unknownParty: {
        ctaButton: {
          label: () => "Unbekannte Partei ersetzen",
        },
        description: () =>
          "Eine unbekannte Partei tritt auf, wenn eine Brieftasche/Börse die Quelle oder das Ziel einer Transaktion nicht meldet. Dies bedeutet, dass die eingehende oder ausgehende Vermögensbewegung gemeldet wird, die andere Partei jedoch als „unbekannt“ bleibt. Um dieses Problem zu beheben, muss die unbekannte Quelle/das unbekannte Ziel durch eine bekannte Partei ersetzt werden.",
        modal: {
          actionButton: () => "Unbekannte Partei ersetzen",
          actionButtonMobile: () => "Partei ersetzen",
          cancelButton: () => "Stornieren",
          description: (d) =>
            "Dadurch werden alle unbekannten Partei- " + d.direction + " s auf",
          destinationDirection: () => "Ziel",
          exchangePlaceholder: () => "Bekannte Partei",
          sourceDirection: () => "Quelle",
          title: () => "Partei ersetzen",
        },
        navButton: () => "Partei identifizieren",
        steps: {
          0: () =>
            "Haben Sie alle Ihre Wallets und Börsen importiert? Unser Matching-Algorithmus kann den unbekannten Teilnehmer möglicherweise automatisch erkennen und auflösen.",
          1: () =>
            "Wissen Sie, wohin das Vermögen gesendet oder woher es empfangen wurde? In diesem Fall können Sie den Beteiligten der Transaktion manuell zuordnen, indem Sie auf die Schaltfläche „Unbekannten Beteiligten ersetzen“ klicken.",
        },
        tableHeader: {
          Destination: () => "Ziel",
          assets: () => "Vermögenswerte",
          source: () => "Quelle",
          transactions: () => "Transaktionen",
          transactionsMobile: () => "Txs",
          transferAmount: () => "Überweisungsbetrag",
          transferAmountMobile: () => "Menge",
        },
        title: () => "Unbekannte Parteien",
      },
      unmatchedTransfer: {
        button: () => "Lösen",
        chipTitle: () => "Nicht abgeglichene Überweisungen auflösen",
        ctaButton: {
          import: () => "Gegenpartei importieren",
          recategorise: () => "Neu kategorisieren",
        },
        description: () =>
          "Verknüpfen Sie alle Überweisungen korrekt, um sicherzustellen, dass alle Ihre Kostengrundlagen korrekt berechnet werden.",
        drawer: {
          cta: () => "So lösen Sie nicht abgeglichene Überweisungen",
          headerFive: () =>
            "Um diese Unstimmigkeiten zu beheben, haben Sie folgende Möglichkeiten:",
          headerFour: () => "Auflösen nicht abgeglichener Überweisungen",
          headerOne: () => "Was sind nicht abgeglichene Überweisungen?",
          headerSix: () =>
            "Eine genaue Zuordnung der Überweisungen ist für die korrekte Steuerberechnung und die Vollständigkeit Ihres Krypto-Transaktionsverlaufs von entscheidender Bedeutung.",
          headerThree: () => "Dies geschieht normalerweise in drei Szenarien:",
          headerTwo: () => "Informationen zu nicht abgeglichenen Überweisungen",
          listOneItems: {
            0: () =>
              "Sie haben Kryptowährungen an eine Wallet gesendet oder von einer Wallet empfangen, die nicht in Ihr Konto importiert wurde.",
            1: () =>
              "Eine Transaktion wurde als Senden oder Empfangen kategorisiert, aber wir können das entsprechende Gegenstück nicht finden",
            2: () =>
              "Sie haben eine Bridge verwendet, um Vermögenswerte zwischen verschiedenen Blockchains zu übertragen, und wir können nur eine Seite einer Bridge Transaktion sehen",
          },
          listThreeItems: {
            0: () =>
              "Kategorisieren Sie die Transaktion neu, wenn sie fälschlicherweise als Senden/Empfangen oder Bridge Out/In gekennzeichnet wurde.",
          },
          listTwoItems: {
            0: () =>
              "Importieren Sie die fehlende Gegenpartei-Wallet, um das Transferpaar zu vervollständigen",
          },
          paragraphOne: () =>
            "Nicht abgeglichene Überweisungen treten auf, wenn eine ausgehende Transaktion von Ihrem importierten Wallet an ein unbekanntes Ziel erfolgt oder wenn bei einer Sende-/Empfangstransaktion das entsprechende Überweisungspaar fehlt.",
          paragraphThree: () =>
            "Wenn Sie über eine nicht übereinstimmende Übertragung zwischen zwei Quellen verfügen, die Sie bereits importiert haben, haben Sie zwei Möglichkeiten:",
          paragraphTwo: () =>
            "Wenn Sie Kryptowährungen zwischen Wallets oder Konten senden oder empfangen, sollte die Transaktion immer zwei Seiten haben: das sendende Wallet und das empfangende Wallet. Eine nicht abgeglichene Übertragung bedeutet, dass wir nur eine Seite sehen können, was zu einer ungenauen Nachverfolgung Ihrer Bestände und der damit verbundenen Kostenbasis führen kann.",
          subListOneItems: {
            0: () =>
              "Aktivieren Sie die Einstellung „Überweisungen annehmen“, um Überweisungen zwischen Ihren Konten automatisch abzugleichen",
            1: () =>
              "Generieren Sie manuell eine Überweisung, indem Sie im Optionsmenü der Transaktion die Option „Überweisung generieren“ auswählen.",
          },
        },
        headers: {
          amount: () => "Menge",
          assets: () => "Vermögenswerte",
          source: () => "Gegenpartei",
          transactions: () => "Transaktionen",
          transferAmount: () => "Überweisungsbetrag",
          txs: () => "Tx's",
        },
        navButton: () => "Nicht abgeglichene Überweisungen auflösen",
        steps: {
          0: () =>
            "Stellen Sie sicher, dass Sie alle Ihre Daten aus allen möglichen Quellen importiert haben.",
          1: () =>
            "Ordnen Sie die Kategorien der unten angezeigten Transaktionen manuell neu zu.",
        },
        title: () => "Nicht abgeglichene Überweisungen auflösen",
      },
    },
    loadMore: () => "Mehr laden",
    noRemainingIssues: {
      allCleared: (d) =>
        "Gute Arbeit! Sie haben jetzt alle " + d.issueTitle + " gelöst",
    },
    noTransactionsState: {
      importCTA: () => "Gehe zu Importe",
      instructions: () => "Keine Daten hinzugefügt",
      instructionsSubtext: () =>
        "Importieren Sie Ihre Konten, um mit dem Abgleich zu beginnen.",
    },
    quantity: (d) => "Menge: " + d.amount,
    reconcileCTA: {
      itemsReconciled: () => "Abgeglichene Transaktionen",
      progressBanner: {
        body: () =>
          "Bei einigen Transaktionen müssen noch Probleme behoben werden. Durch die Verbesserung der Integrität Ihrer Daten können Sie Ihre Steuerlast senken.",
        completeText: () =>
          "Es gibt keine Abstimmungsvorschläge, schön! Überprüfen Sie immer noch einmal, ob alles richtig aussieht.",
        title: () => "Aktueller Abstimmungsstatus",
      },
      reconcileItems: (d) =>
        plural(d.issueCount, 0, de, {
          one: " Transaktion abgleichen ",
          other: " Transaktionen abgleichen ",
        }),
      suggestionBanner: {
        body: (d) =>
          "Es verbleiben " +
          plural(d.suggestionNum, 0, de, {
            one: " ist 1 Vorschlag ",
            other: " sind " + number("de", d.suggestionNum, 0) + " Vorschläge ",
          }) +
          " die Ihnen beim Abgleichen Ihrer Transaktionen helfen.",
        cta: () => "Vorschläge überprüfen",
        suggestionChip: (d) =>
          plural(d.suggestionNum, 0, de, {
            one: " 1 Vorschlag ",
            other: number("de", d.suggestionNum, 0) + " Vorschläge ",
          }),
      },
    },
    resolvedChip: {
      resolved: () => "Gelöst",
    },
    sidebar: {
      knowledgeBase: () => "Wissensdatenbank",
      steps: {
        categorise: () => "Kategorisieren",
        correct: () => "Richtig",
        import: () => "Import",
        prepare: () => "Vorbereiten",
        resolve: () => "Lösen",
      },
      suggestions: (d) =>
        plural(d.count, 0, de, {
          one: " 1 Vorschlag ",
          other: number("de", d.count, 0) + " Vorschläge ",
        }),
      tip: {
        message: () =>
          "Um optimale Ergebnisse zu erzielen, gleichen Sie Ihre Transaktionen in der Reihenfolge ab, in der sie von oben nach unten in der Seitenleiste angezeigt werden.",
        title: () => "Profi-Tipp",
      },
    },
    tipsInfoModal: {
      items: {
        0: () =>
          "Alle Transaktionen auf der Blockchain sind für die Steuerbehörde sichtbar und können zu Ihrem KYC-Austausch zurückverfolgt werden.",
        1: () =>
          "Sie müssen ein vollständiges Bild Ihrer gesamten Handelshistorie vorlegen",
        2: () =>
          "Wir helfen Ihnen, etwaige Probleme zu identifizieren, damit Sie ein Audit bestehen",
      },
      subtitle: () =>
        "Wir haben einige Tipps zusammengestellt, mit denen Sie Ihre Steuerschuld senken und die Genauigkeit der Berichte sicherstellen können.",
      subtitleReview: () =>
        "Überprüfen Sie diese Punkte, um Ihre Steuerschuld zu senken und die Richtigkeit der Berichte sicherzustellen.",
      title: (d) => "Sie könnten möglicherweise " + d.amount + " sparen",
    },
    title: () => "Versöhnung",
    tradeSuggestion: {
      currency: () => "Währung",
      headers: {
        amount: () => "Menge",
        asset: () => "Vermögenswert",
        price: () => "Preis",
        transactions: () => "Transaktionen",
        txs: () => "Tx's",
        value: () => "Wert",
      },
      transactionSource: () => "Transaktionsquelle",
      transactions: () => "Transaktionen",
    },
    unignoreIssue: () => "Problem nicht mehr ignorieren",
    viewTransactions: () => "Transaktionen anzeigen",
  },
  referAFriend: {
    copyCTA: () => "Kopie",
    disclaimer: (d) =>
      "*Für jeden Benutzer, der sich bei CTC anmeldet und zum ersten Mal einen Plan mit Ihrem einzigartigen Empfehlungslink kauft, erhalten Sie " +
      d.reward +
      " Bargeld oder den vollen Betrag, den die empfohlene Person für ihren Plan bezahlt, je nachdem, welcher Betrag niedriger ist. Zahlungen werden über PayPal 60 Tage nach Ende des Monats, in dem sie einen Plan abonniert haben, verteilt. Der endgültige Betrag kann variieren, wenn Währungsschwankungen zwischen dem Verdienen der Belohnung und dem Erhalt der Auszahlung auftreten. Alle Zahlungen erfolgen in AUD. Um sich zu qualifizieren, müssen empfohlene Benutzer Ihren persönlichen Empfehlungslink verwenden. Selbstempfehlungen sind nicht berechtigt, Belohnungsvorteile zu erhalten.",
    helpCenterLink: () => "Hilfe-Center",
    messageUs: (d) =>
      "Schreiben Sie uns eine DM [@CryptoTaxHQ](" +
      d.linkTextKey +
      ") und lassen Sie uns erkunden, wie wir zusammenarbeiten können!",
    moreDetails: (d) =>
      "Für weitere Details besuchen Sie bitte unser [Hilfezentrum](" +
      d.linkTextKey +
      ").",
    paypalSection: {
      available: () => "Verfügbar",
      availableTooltip: () =>
        "Der verfügbare Betrag, den Sie als Bargeld beanspruchen können",
      claimCTA: () => "Jetzt beanspruchen",
      claimCTATooltip: () =>
        "Muss 60 Tage warten, bevor eine Auszahlung beansprucht werden kann",
      connectCTA: () => "PayPal verbinden",
      connectedSubtext: () =>
        "Verdienste können beansprucht werden, sobald die empfohlene Person 60 Tage lang einen kostenpflichtigen Tarif genutzt hat",
      error: () => "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      lifetimeEarnings: () => "Gesamtverdienst",
      lifetimeEarningsTooltip: () =>
        "Gesamtbetrag, der verdient und an Sie ausgezahlt wurde",
      modal: {
        connectCTA: () => "Verbinden",
        connectPlaceholder: () => "PayPal-Daten eingeben",
        connectSubtext: () => "Geben Sie Ihre PayPal-E-Mail-Adresse ein",
        connectTitle: () => "PayPal verbinden",
        disconnectCTA: () => "PayPal trennen",
        updateCTA: () => "PayPal ändern",
        updatePlaceholder: () => "Neue PayPal-Daten eingeben",
        updateSubtext: (d) => d.username + " ist derzeit verbunden",
        updateTitle: () => "PayPal aktualisieren",
      },
      plansPurchased: () => "Gekaufte Tarife",
      plansPurchasedTooltip: () =>
        "Die Anzahl der Benutzer, die einen Tarif über Ihren Empfehlungslink gekauft haben",
      subtext: () =>
        "Verbinden Sie Ihr PayPal-Konto und beginnen Sie, Ihren Empfehlungslink zu teilen, um Bargeld zu erhalten.",
      title: () => "Ihre empfehlungsbasierten einnahmen",
      updateCTA: () => "PayPal aktualisieren",
    },
    socialLinks: {
      email: (d) =>
        "Ich benutze Crypto Tax Calculator für meine Krypto-Steuern - es ist schnell, einfach und präzise, außerdem unterstützt es zahlreiche Integrationen.\n\n Falls du deine noch erledigen musst, kannst du meinen Empfehlungslink verwenden und 20% auf deinen Tarif sparen.\n\n " +
        d.link,
      emailSubject: () =>
        "Spare 20% bei Crypto Tax Calculator, wenn du meinen Link verwendest!",
      telegram: (d) =>
        "Für alle, die wie ich fast aufgegeben hätten, ihre Krypto-Steuern zu machen: Schaut euch @CryptoTaxHQ an. Hat meine geistige Gesundheit komplett gerettet. Wenn ihr sie ausprobieren wollt, nutzt meinen Referenzlink und wir sparen beide! " +
        d.link,
      x: (d) =>
        "Für alle, die wie ich fast aufgegeben hätten, ihre Krypto-Steuern zu machen: Schaut euch @CryptoTaxHQ an. Hat meine geistige Gesundheit komplett gerettet. Wenn ihr sie ausprobieren wollt, nutzt meinen Referenzlink und wir sparen beide! " +
        d.link,
    },
    subtext: (d) =>
      "Teile CTC mit deinen Freunden, um bis zu " +
      d.reward +
      " Bargeld für jede Empfehlung zu verdienen. Sie sparen coole 20% auf ihren Tarif, und du erhältst bis zu " +
      d.reward +
      " Bargeld für jede Empfehlung. Das nennt man eine Win-Win-Situation!",
    title: (d) => "Erhalte " + d.reward + " Bargeld* für jede Empfehlung",
    workWithUs: () =>
      "Haben Sie eine Krypto-Community oder viele Follower auf X?",
  },
  referrals: {
    description: () =>
      "Wenn sich ein Freund bei CTC anmeldet und zum ersten Mal einen Plan über Ihren Empfehlungslink kauft, erhalten Sie eine Gutschrift von 40 $ für Ihr Abonnement. Wenn Sie genügend Guthaben sammeln, könnte Ihr nächstes Abonnement kostenlos sein. Um sich zu qualifizieren, muss sich Ihr Freund über Ihren persönlichen Empfehlungslink anmelden.",
    linkText: () => "Ihr Empfehlungslink:",
    subtext: () =>
      "Erhalten Sie 40 $ Rabatt auf CTC für jeden Freund, den Sie werben. Sie erhalten ebenfalls 40 $ Rabatt. Ka-ching 💰",
    title: () => "Geben Sie 40 $ und erhalten Sie 40 $",
  },
  report: {
    ACB: () => "Angepasste Kostenbasis",
    AVCO: () => "Durchschnittliche Kosten",
    FIFO: () => "First In - First Out",
    HIFO: () => "Highest In – First Out",
    HMRC: () => "Britische Steuerbehörde",
    IRS: {
      done: () => "Erledigt",
      downloadIrs8949Long: () =>
        "Laden Sie das IRS-Formular 8949 für langfristige Gewinne herunter",
      downloadIrs8949LongConsolidated: () =>
        "Laden Sie das IRS-Formular 8949 „Langfristige Gewinne (konsolidiert)“ herunter.",
      downloadIrs8949Short: () =>
        "Laden Sie das IRS-Formular 8949 für kurzfristige Gewinne herunter",
      downloadIrs8949ShortConsolidated: () =>
        "Laden Sie das IRS-Formular 8949 „Kurzfristige Gewinne (konsolidiert)“ herunter.",
      downloadIrsForm: () =>
        "Laden Sie IRS Schedule 1 (Formular 1040) herunter",
      downloadIrsScheduleDForm: () =>
        "Laden Sie IRS Schedule D (Formular 1040) herunter",
      provideIrsDetails: () => "Geben Sie Ihre IRS-Daten an",
      stepComplete: () => "Alle Schritte abgeschlossen - Sie sind fertig",
    },
    LIFO: () => "Last In - First Out",
    LTFO: () => "Am steuerlich wirksamsten",
    PFU: () => "PFU / Frankreich",
    acknowledged: () => "Anerkannt",
    airdropIncome: () => "Luftabwürfe",
    allTradeSelector: () => "Alle TXS-Typen",
    amountRemaining: () => "Verbleibender Betrag",
    amountYouCouldSave: () =>
      "Der Betrag, den Sie durch die Verbesserung der Berichtsgenauigkeit sparen könnten",
    approvals: () => "Zulassungen",
    assetSummary: {
      amountRemainingEndFy: () => "Bilanz zum Ende des Geschäftsjahres",
      amountRemainingEndFyTooltip: () =>
        "Gesamtbestand am Ende des Geschäftsjahres.",
      amountRemainingStartFy: () => "Start Geschäftsjahresbilanz",
      capitalGain: () => "Kapital Gewinn/Verlust",
      cost: () => "Kosten",
      currency: () => "Währung",
      fee: () => "Gebühren",
      income: () => "Einkommen",
      longTerm: () => "Langfristig",
      noDisposals: () => "Keine Veräußerungen in diesem Zeitraum.",
      overallGain: () => "Gesamtgewinn/-verlust",
      proceeds: () => "Erlös",
      quantity: () => "Entsorgte Menge",
      quantityTooltip: () =>
        "Gesamtmenge, die während des Geschäftsjahres veräußert wurde.",
      shortTerm: () => "Kurzfristig",
    },
    ausFinancialYear: (d) => "1. Juli " + d.prevYear + " – 30. Juni " + d.year,
    buyTime: () => "Gekauft",
    capitalGainSelector: {
      all: () => "Alle",
      gains: () => "Belohnungen",
      longTermCapitalGains: () => "Langfristige Gewinne",
      longTermCapitalLosses: () => "Langfristige Verluste",
      shortTermCapitalGains: () => "Kurzfristige Gewinne",
      shortTermCapitalLosses: () => "Kurzfristige Verluste",
      totalLosses: () => "Verluste",
    },
    checkWithAgent: () => "Nicht sicher? Fragen Sie Ihren Steuerberater.",
    closed: () => "Geschlossen",
    confirmInventoryMethod: () => "Methode zur Bestandsbestätigung",
    confirmationText: (d) =>
      "Sie haben die Inventarmethode " +
      d.method +
      " ausgewählt, die nicht die Standardoption ist. Konsultieren Sie immer Ihren Steuerberater, bevor Sie die Inventarmethode " +
      d.method +
      " verwenden.",
    cost: () => "Kostenbasis",
    costBasisRedistributionMethod: {
      "largest-wallet-highest-cost": () => "Größtes Wallet, höchste Kosten",
      "largest-wallet-lowest-cost": () => "Größte Wallet Niedrigste Kosten",
      "least-adjustment": () => "Kleinste Anpassung",
      title: () => "Umverteilungsmethode",
      tooltip: () =>
        "Die Methode zur Umverteilung der Kostenbasis der Vermögenswerte beim Wechsel zwischen universellen und nach Wallet- und Exchange-Kostenbasismethoden",
    },
    csv: () => "CSV",
    currency: () => "Währung",
    customDateRange: () => "Daten des Geschäftsjahres",
    customDates: () => "Wählen Sie benutzerdefinierte Daten",
    defaultPaywallBannerText: () =>
      "Abonnieren Sie, um Ihren Bericht anzuzeigen",
    derivativeTradingIncome: () => "Erträge aus dem Derivatehandel",
    disclaimer: (d) =>
      "Sie sollten diese Ergebnisse nur als Schätzung und als Orientierungshilfe verwenden. Obwohl wir versuchen, die Genauigkeit dieser Ergebnisse sicherzustellen, können Fehler auftreten. Stellen Sie sicher, dass Sie die Ergebnisse überprüfen. " +
      select(d.isRegularUser, {
        true: " Wenn Sie unsicher sind, sprechen Sie mit Ihrem Steuerberater. ",
        other: "",
      }),
    disclaimerTitle: () =>
      "Die berechneten Ergebnisse basieren auf den Informationen, die Sie zum Zeitpunkt der Berechnung angegeben haben.",
    download: {
      bglAccountIDModal: {
        buttonText: () => "Erledigt",
        copyId: () => "ID kopieren",
        subtitle: () =>
          "Verwenden Sie diese ID als Ihre Konto-ID, wenn Sie Ihre Daten in BGL importieren:",
        title: () => "BGL-Konto-ID",
      },
      buttonIrs: () => "Formular generieren",
      downloading: () => "Wird heruntergeladen...",
      exportCSV: () => "In CSV-Datei exportieren",
      exportIrs1040s1Pdf: () =>
        "Export nach IRS Schedule 1 (Formular 1040) PDF",
      exportIrs8949Pdf: () => "Exportieren nach IRS-Formular 8949 PDF",
      exportPDF: () => "In PDF-Datei exportieren",
      exportTurboTaxOnline: () => "Nach TurboTax Online exportieren (CSV)",
      exportTurboTaxTXF: () => "Export nach TurboTax Desktop (TXF)",
      irsDownload: {
        customTitle: () => "Angaben zum IRS-Formular (optional)",
        download: () => "Herunterladen",
        name: () => "Bei der Rückgabe angezeigte(r) Name(n)",
        skip: (d) =>
          "Da Sie keine Laufzeitgewinne " +
          d.termLength +
          " haben, können Sie diesen Schritt überspringen.",
        social: () => "SSN oder TIN",
        whyUserData: (d) =>
          "Wenn Sie möchten, dass wir Ihre Daten in " +
          d.formName +
          " automatisch ausfüllen, geben Sie bitte Ihren Namen und eine gültige SSN/TIN ein. Wir respektieren Ihre Privatsphäre, daher werden diese Daten nirgendwo gespeichert und nur zum Generieren der PDFs verwendet.",
        whyUserDataCustom: () =>
          "Wenn Sie möchten, dass wir Ihre Daten automatisch ausfüllen, geben Sie bitte Ihren Namen und eine gültige SSN/TIN ein. Wir respektieren Ihre Privatsphäre, daher werden diese Daten nirgendwo gespeichert und nur zum Generieren der PDFs verwendet.",
      },
      loading: () => "Herunterladen",
      sample: () => "Probe",
      tabCryptoTax: () => "Steuerbericht",
      tabGermanReports: () => "Deutsche Formulare",
      tabIrs: () => "IRS-Formulare",
      tabTaxIntegrations: () => "Softwareintegrationen",
      title: () => "Berichte herunterladen",
      tradingStockReportDownload: {
        buttons: {
          cancel: () => "Stornieren",
          downloadReport: () => "Bericht herunterladen",
        },
        costBasisMethod: {
          description: () =>
            "Die Kostenbasis der Vermögenswerte wird verwendet, um die Eröffnungs- und Schlusswerte Ihres Handelsbestands zu bestimmen. Wenn für einen Vermögenswert ein entsprechender Marktwert vorhanden ist, wird dieser überschrieben.",
          title: () => "Kostenbasismethode verwenden",
        },
        description: () =>
          "Wählen Sie bitte aus, mit welcher Methode Sie die Eröffnungs- und Schlusswerte Ihrer Handelsbestände ermitteln möchten.",
        fallbackMethod: {
          description: () =>
            "Für alle Vermögenswerte werden Marktwerte verwendet. Wenn kein Marktpreis ermittelt werden kann und somit kein Marktwert bestimmt werden kann, wird stattdessen die Kostenbasis verwendet.",
          title: () => "Fallback-Methode verwenden",
        },
        marketValueMethod: {
          description: () =>
            "Für alle Vermögenswerte werden Marktwerte verwendet. Wenn kein Marktpreis abgerufen und somit kein Marktwert ermittelt werden kann, zeigen wir den Wert als „N/A“ (Nicht verfügbar) an. Dies ist die in der Vergangenheit standardmäßig verwendete Methode.",
          title: () => "Marktwertmethode verwenden (Standard)",
        },
        table: {
          defaultValuationMethod: () => "Marktwertmethode (Standard)",
          tooltip: () =>
            "Für diese Tabelle wird die Standardbewertungsmethode verwendet. Dies bedeutet, dass für alle Vermögenswerte Marktwerte verwendet werden. Wenn kein Marktpreis abgerufen und daher kein Marktwert ermittelt werden kann, zeigen wir den Wert als „N/A“ an.",
        },
        title: () => "Handelsaktienbericht",
      },
      turbotaxModal: {
        buttonText: () => "Gehen Sie jetzt zu TurboTax",
        helpLinkText: () => "Online-Anleitung zum TurboTax-Import",
        subtitle: () =>
          "Dieser Bericht kann mithilfe der folgenden Anweisungen online in TurboTax importiert werden.",
        title: () => "TurboTax-Bericht",
      },
    },
    downloadReports: {
      downloadReportAnyway: () => "Bericht trotzdem herunterladen",
      primaryButton: () => "Berichte herunterladen",
      quickDownload: () => "Schnelldownload",
      title: () => "Berichte",
    },
    emailReportsModal: {
      always: () => "Berichte immer per E-Mail versenden",
      body: () =>
        "Dies ist ein umfangreicher Bericht und die Erstellung kann über eine Minute dauern. Möchten Sie diesen Bericht per E-Mail erhalten, sobald er fertig ist?",
      dontShowAgain: () => "Nicht mehr anzeigen",
      emailMe: () => "E-Mail senden",
      illWait: () => "Ich werde warten",
      title: () => "E-Mail-Bericht?",
    },
    errorTitle: () => "Warnmeldungen",
    estimatedGainBanner: {
      title: () => "Geschätzter Gewinn",
      tooltip: () =>
        "Der geschätzte Gewinn stellt Ihren Gesamtertrag aus Kryptowährungen dar. Er wird als Kapitalgewinne + Einkommen - Kapitalverluste berechnet. Diese Schätzung basiert auf den bisher importierten Daten. Für ein genaues Ergebnis ist ein vollständiges Bild aller Ihrer Transaktionen seit Ihrem ersten Kauf von Kryptowährungen erforderlich.",
    },
    expense: () => "Kosten",
    expenses: () => "Kosten",
    fee: () => "Gebühr",
    fees: () => "Gebühren",
    financialYear: () => "Geschäftsjahr",
    form3916ExchangeInfo: {
      address: () => "Adresse",
      company: () => "Bezeichnung der kontoführenden Organisation (*)",
      country: () => "Land",
      title: () => "Erklärung zu ausländischen Konten",
      website: () => "URL der Website der Account-Management-Organisation",
    },
    fyChip: (d) => "Zum " + d.timeframe,
    gain: () => "Gewinn (Verlust)",
    gainNoCGT: () => "Gewinn (Verlust)",
    grid: {
      softwareConnections: () => "Softwareverbindungen",
      taxReports: () => "Steuerberichte",
      txTypes: () => "Transaktionsarten",
    },
    headings: {
      derivativeTradingIncome: () => "Erträge aus dem Derivatehandel",
      expenses: () => "Kosten",
      gains: () => "Kapitalgewinne",
      longTermCapitalGains: () => "Langfristige Kapitalgewinne",
      longTermCapitalLosses: () => "Langfristige Kapitalverluste",
      nonTaxableTransactions: () => "Nicht steuerpflichtige Transaktionen",
      ordinaryIncome: () => "Reguläres Einkommen",
      shortTermCapitalGains: () => "Kurzfristige Kapitalgewinne",
      shortTermCapitalLosses: () => "Kurzfristige Kapitalverluste",
      totalCapitalGains: () => "Nettokapitalgewinne",
      totalExpenses: () => "Nettoaufwendungen",
      totalIncome: () => "Nettoeinkommen",
      totalLosses: () => "Verluste",
    },
    income: () => "Einkommen",
    incurredTime: () => "Angefallen",
    interestIncome: () => "Interesse",
    inventory: {
      endBalance: () => "Schlussbilanz",
      endCostBasis: () => "Abschlusskosten",
      endValue: () => "Schlusskurs",
      startBalance: () => "Eröffnungsbilanz",
      startCostBasis: () => "Eröffnungskosten",
      startValue: () => "Eröffnungsmarktwert",
    },
    inventoryMethod: () => "Inventarisierungsmethode",
    loanRepayments: () => "Kreditrückzahlungen",
    lockedPeriod: {
      all: () => "Dieser Zeitraum ist gesperrt",
      costBasisTracking: () =>
        "Sie können die Einstellung zur Kostenbasisverfolgung nicht ändern, während Sie gesperrte Zeiträume verwenden",
      multipleInventoryMethods: () => "Verschieden",
      none: () => "Sperrfrist",
      some: () => "Dieser Zeitraum enthält gesperrte Transaktionen",
      tooltip: () =>
        "Sie können die Einstellungen für einen gesperrten Zeitraum nicht ändern",
    },
    longShortIncome: () => "Margin-Trading-Einkommen",
    longTermGains: () => "Langfristige Kapitalgewinne",
    longTermGainsNoCGT: () => "Langfristig realisierte Gewinne",
    lookTooltip: (d) =>
      "[Abonnieren]( " + d.linkTextKey + " ), um die Ansicht zu sehen",
    miningRewards: () => "Mining",
    miscellaneousExpenses: () => "Kosten",
    missingPurchase: {
      text: () => "Fehlender Kauf",
      tooltip: (d) =>
        "Der Kauf für diese Veräußerung fehlt. Wir sind von einer Kostenbasis von 0,00 USD ausgegangen. [Fehlende Kaufhistorie auflösen]( " +
        d.linkTextKey +
        " ) um die Kostenbasis korrekt zuzuweisen.",
    },
    modifyTaxSettings: () => "Bearbeiten",
    nav: {
      reportSummary: () => "Berichtszusammenfassung",
      taxReports: () => "Steuerberichte",
    },
    noFrenchReportDataModal: {
      body1: (d) =>
        "Für " +
        d.year +
        " wurden keine Krypto-zu-Fiat-Transaktionen aufgezeichnet, daher ist Forumalaire 2086 nicht erforderlich.",
      body2: (d) =>
        "Wenn Sie für " +
        d.year +
        " einige Krypto-zu-Fiat-Transaktionen erwartet haben, überprüfen Sie noch einmal, ob alle Transaktionen importiert wurden. Prüfen Sie andere Berichte auf potenzielle Einkommenstransaktionen.",
      title: (d) => "Keine Krypto-zu-Fiat-Transaktionen im " + d.year,
    },
    noRecordsFound: () => "Keine relevanten Datensätze gefunden ...",
    nzFinancialYear: (d) => "1. April " + d.prevYear + " – 31. März " + d.year,
    opened: () => "Geöffnet",
    ordinaryIncome: () => "Reguläres Einkommen",
    otherIncome: () => "Einkommen",
    otherReports: () => "Andere Berichte und Integrationen",
    overallGain: () => "Gesamtgewinn",
    packs: {
      customModal: {
        columns: {
          details: () => "Details",
          report: () => "Bericht",
        },
        csv: () => "CSV",
        downloadSelected: (d) =>
          select(d.isFree, {
            true:
              " Beispielberichte herunterladen ( " + d.selectedCount + " ) ",
            other: " Ausgewählte herunterladen ( " + d.selectedCount + " ) ",
          }),
        getSample: () => "Probe anfordern",
        pdf: () => "PDF",
        selectPlanBanner: {
          body: () =>
            "Wählen Sie einen Plan zum Herunterladen Ihrer Berichte aus",
          cta: () => "Wähle einen Plan aus",
        },
        title: () => "Steuerberichte",
        txf: () => "TXF",
        upgrade: (d) =>
          "Aktualisieren Sie auf " +
          d.planTitle +
          " um auf diesen Bericht zuzugreifen",
        xml: () => "XML",
      },
      filters: {
        accountant: () => "Für Buchhalter",
        all: () => "Alle",
        self: () => "Zur Selbstauskunft",
      },
      metadata: {
        8949: {
          description: () => "Verkäufe und andere Veräußerungen",
          title: () => "IRS-Formular 8949",
        },
        accountant: {
          description: () => "An Ihren Buchhalter senden",
          title: () => "Buchhalterpaket",
        },
        custom: {
          description: () => "Alle verfügbaren Berichte anzeigen",
          title: () => "Benutzerdefiniert",
        },
        form3916bis: {
          description: () => "Erklärung zu ausländischen Konten",
          title: () => "Formular 3916 bis",
        },
        fr2086: {
          description: () => "Zur Anmeldung steuerpflichtiger Umsätze",
          title: () => "Formular Nr. 2086",
        },
        mygov: {
          description: () => "Datei über MyGov",
          title: () => "ATO-Bericht (MyTax)",
        },
        scheduleS1AndSD: {
          description: () => "Zusätzliche Einkünfte und Einkommensanpassungen",
          title: () => "IRS 1040 s1 und sD",
        },
        self: {
          description: () =>
            "Für die eigenständige Einreichung Ihrer Steuererklärung",
          title: () => "Selbstberichterstattung",
        },
        taxLots: {
          description: () =>
            "Sehen Sie sich eine Momentaufnahme der Partien an, aus denen Ihr Inventar besteht",
          title: () => "Bestandsaufnahme",
        },
        turbotax: {
          description: () => "Zum Importieren in TurboTax Online",
          title: () => "TurboTax Online",
        },
      },
    },
    paywallBannerFYText: () =>
      "Wählen Sie einen Plan aus, um Berichte aus mehreren Steuerjahren anzuzeigen",
    paywallBannerText: () =>
      "Wählen Sie einen Plan aus, um Ihren Bericht anzuzeigen",
    paywallBannerTextCollaborator: () =>
      "Bitten Sie Ihren Buchhalter, sich anzumelden, um Ihren Bericht anzuzeigen",
    paywallBannerTextMap: {
      accountant: {
        accountantOrCollaboratorView: {
          free: () =>
            "Wählen Sie einen Plan aus, um Gewinne zu sehen und die Berichte Ihres Kunden herunterzuladen",
          importCount: () =>
            "Ihr Kunde hat das Importlimit für diesen Plan erreicht. Aktualisieren Sie, um die Berichte Ihres Kunden anzuzeigen",
          tradeType: () =>
            "Ihr Kunde verfügt über Smart-Contract-Transaktionen, die in diesem Plan nicht unterstützt werden. Führen Sie ein Upgrade durch, um die Berichte Ihres Kunden anzuzeigen.",
          txCount: () =>
            "Ihr Kunde hat das Transaktionslimit für diesen Plan erreicht. Aktualisieren Sie, um die Berichte Ihres Kunden anzuzeigen",
          unpaid: () =>
            "Sie haben ein unbezahltes Abonnement. Bezahlen Sie das ausstehende Abonnement, um die Berichte Ihres Kunden anzuzeigen",
        },
        clientView: {
          free: () =>
            "Bitten Sie Ihren Buchhalter, sich anzumelden, um Ihren Bericht anzuzeigen",
          importCount: () =>
            "Sie haben das Importlimit für Ihren Plan erreicht. Bitten Sie Ihren Buchhalter, sich anzumelden, um Ihren Bericht anzuzeigen",
          tradeType: () =>
            "Sie haben Smart-Contract-Transaktionen, die in Ihrem Plan nicht unterstützt werden. Bitten Sie Ihren Buchhalter, sich anzumelden, um Ihren Bericht anzuzeigen",
          txCount: () =>
            "Sie haben das Transaktionslimit für Ihren Plan erreicht. Bitten Sie Ihren Buchhalter, einen höheren Plan zu abonnieren, um Ihren Bericht anzuzeigen",
          unpaid: () =>
            "Bitten Sie Ihren Buchhalter, sich anzumelden, um Ihren Bericht anzuzeigen",
        },
      },
      both: {
        accountantOrCollaboratorView: {
          free: () =>
            "Wählen Sie einen Plan aus, um Gewinne zu sehen und die Berichte Ihres Kunden herunterzuladen",
          importCount: () =>
            "Ihr Kunde hat das Importlimit für diesen Plan erreicht. Aktualisieren Sie oder bitten Sie Ihren Kunden, zu aktualisieren, um die Berichte Ihres Kunden anzuzeigen.",
          tradeType: () =>
            "Ihr Kunde verfügt über Smart-Contract-Transaktionen, die in diesem Plan nicht unterstützt werden. Führen Sie ein Upgrade durch oder bitten Sie Ihren Kunden, ein Upgrade durchzuführen, um die Berichte Ihres Kunden anzuzeigen.",
          txCount: () =>
            "Ihr Kunde hat das Transaktionslimit für diesen Plan erreicht. Aktualisieren Sie oder bitten Sie Ihren Kunden, zu aktualisieren, um die Berichte Ihres Kunden anzuzeigen.",
          unpaid: () =>
            "Sie haben ein unbezahltes Abonnement. Bezahlen Sie das ausstehende Abonnement, um die Berichte Ihres Kunden anzuzeigen",
        },
        clientView: {
          free: () =>
            "Wählen Sie einen Plan aus oder bitten Sie Ihren Buchhalter, sich anzumelden, um Ihren Bericht anzuzeigen",
          importCount: () =>
            "Sie haben das Importlimit für Ihren Plan erreicht. Wählen Sie einen Plan aus oder bitten Sie Ihren Buchhalter, sich anzumelden, um Ihren Bericht anzuzeigen.",
          tradeType: () =>
            "Sie haben Smart-Contract-Transaktionen, die in Ihrem Plan nicht unterstützt werden. Wählen Sie einen Plan aus oder bitten Sie Ihren Buchhalter, sich anzumelden, um Ihren Bericht anzuzeigen.",
          txCount: () =>
            "Sie haben das Transaktionslimit für Ihren Plan erreicht. Wählen Sie einen Plan aus oder bitten Sie Ihren Buchhalter, einen höheren Plan zu abonnieren, um Ihren Bericht anzuzeigen",
          unpaid: () =>
            "Ihr Abonnement ist noch nicht bezahlt. Wählen Sie einen Plan aus oder bitten Sie Ihren Buchhalter, ein Abonnement abzuschließen, um Ihren Bericht anzuzeigen",
        },
      },
      client: {
        accountantOrCollaboratorView: {
          free: () =>
            "Wählen Sie einen Plan aus, um den Bericht Ihres Kunden anzuzeigen",
          importCount: () =>
            "Ihr Kunde hat das Importlimit für diesen Plan erreicht. Bitten Sie Ihren Kunden, ein Upgrade durchzuführen, um seinen Bericht anzuzeigen",
          tradeType: () =>
            "Ihr Kunde hat Smart-Contract-Transaktionen, die in diesem Plan nicht unterstützt werden. Bitten Sie Ihren Kunden, ein Upgrade durchzuführen, um seinen Bericht anzuzeigen.",
          txCount: () =>
            "Ihr Kunde hat das Transaktionslimit für diesen Plan erreicht. Bitten Sie Ihren Kunden, ein Upgrade durchzuführen, um seinen Bericht anzuzeigen",
          unpaid: () =>
            "Ihr Kunde hat ein unbezahltes Abonnement. Bitten Sie Ihren Kunden, das ausstehende Abonnement zu bezahlen, um seinen Bericht anzuzeigen",
        },
        clientView: {
          free: () => "Wählen Sie einen Plan aus, um Ihren Bericht anzuzeigen",
          importCount: () =>
            "Sie haben das Importlimit für Ihren Plan erreicht. Wählen Sie einen Plan aus, um Ihren Bericht anzuzeigen",
          tradeType: () =>
            "Sie haben Smart-Contract-Transaktionen, die in Ihrem Plan nicht unterstützt werden. Wählen Sie einen Plan aus, um Ihren Bericht anzuzeigen",
          txCount: () =>
            "Sie haben das Transaktionslimit für Ihren Plan erreicht. Abonnieren Sie einen höheren Plan, um Ihren Bericht anzuzeigen",
          unpaid: () =>
            "Ihr Abonnement ist noch nicht bezahlt. Abonnieren Sie, um Ihren Bericht anzuzeigen",
        },
      },
      user: {
        free: () =>
          "Wählen Sie einen Plan zum Herunterladen Ihres Berichts aus",
        importCount: () =>
          "Sie haben das Importlimit für Ihren Plan erreicht. Wählen Sie einen Plan aus, um Ihren Bericht herunterzuladen",
        tradeType: () =>
          "Sie haben Smart-Contract-Transaktionen, die in Ihrem Plan nicht unterstützt werden. Wählen Sie einen Plan aus, um Ihren Bericht herunterzuladen",
        txCount: () =>
          "Sie haben das Transaktionslimit für Ihren Plan erreicht. Abonnieren Sie einen höheren Plan, um Ihren Bericht anzuzeigen",
        unpaid: () =>
          "Ihr Abonnement ist noch nicht bezahlt. Abonnieren Sie, um Ihren Bericht anzuzeigen",
      },
    },
    paywalledNumberTooltip: () => "Abonnieren, um anzuzeigen",
    pdf: () => "PDF",
    proceeds: () => "Erlös",
    quantity: () => "Menge",
    receivedTime: () => "Erhalten",
    reportDetails: () => "Berichtsdetails",
    reportSubTitles: {
      "annex-so-other-income": () =>
        "Deutscher Bericht mit Anlage SO automatisch vervollständigt",
      audit: () =>
        "Listet alle Transaktionen, ihre Quelle und die von Benutzern aufgezeichneten Änderungen auf",
      bgl: () => "Ermöglicht den Import in die BGL Simple Fund 360-Software",
      "capital-gains": () =>
        "Kapitalgewinne aus dem Kauf und Verkauf von Kryptowährungen",
      "capital-gains-nz": () =>
        "Realisierte Gewinn-/Verlustereignisse aus dem Kauf und Verkauf von Kryptowährungen",
      cerfa2086: () => "Zur Anmeldung steuerpflichtiger Umsätze",
      expense: () => "Sonstige Kosten, die beim Krypto-Handel anfallen",
      form3916bis: () => "Erklärung über Auslandskonten",
      "gift-and-lost": () =>
        "Detaillierte Aufzeichnung aller ausgehenden Geschenke und verlorenen/gestohlenen Transaktionen",
      income: () => "Einnahmen aus dem Erhalt von Kryptowährungen",
      inventory: () =>
        "Zusammenfassung der Lagerbestände zu Beginn und Ende des Jahres",
      "inventory-v2": () =>
        "Zusammenfassung der Lagerbestände zu Beginn und Ende des Jahres",
      "irs-1040-s1": () => "Zusätzliche Einkünfte und Einkommensanpassungen",
      "irs-1040-sd": () => "Kapitalgewinne und -verluste",
      "irs-8949": () => "Verkäufe und andere Veräußerungen von Anlagevermögen",
      "irs-8949-consolidated": () =>
        "Verkäufe und andere Veräußerungen von Anlagevermögen (konsolidiert nach Währung)",
      "modelo-100": () =>
        "Formular Modelo 100 automatisch ausgefüllt mit Kapitalerträgen und Einkommen",
      mygov: () => "So melden Sie Ihre Steuern selbst in MyGov",
      "nft-report": () =>
        "Kapitalgewinne und Lizenzgebühren aus dem Kauf und Verkauf von NFTs",
      "report-summary": () =>
        "Übersicht über Ihre gesamte Handelsaktivität auf hoher Ebene",
      "start-of-year-holdings": () =>
        "Übersicht Ihrer Bestände zu Beginn des Jahres",
      "tax-lots": () =>
        "Sehen Sie sich eine Momentaufnahme der Partien an, aus denen Ihr Inventar besteht",
      "taxact-csv": () => "Ermöglicht den Import in die TaxAct Online-Software",
      "trading-pnl": () =>
        "Gewinne oder Verluste aus dem Handel mit Derivaten, Margen und Futures",
      "trading-stock": () => "Wird verwendet, wenn Sie als Unternehmen handeln",
      transaction: () => "Detaillierte Aufzeichnungen jeder Transaktion",
      "turbotax-canadian-pdf": () =>
        "Ermöglicht das Einfügen in TurboTax Online für kanadische Benutzer",
      "turbotax-csv": () =>
        "Ermöglicht den Import in die TurboTax Online-Software",
      "turbotax-txf": () =>
        "Ermöglicht den Import in die TurboTax CD/DVD-Software",
      "wiso-steuer": () => " WISO Steuer formatiertes CSV",
    },
    reportType: {
      "annex-so-other-income": () =>
        "Deutscher Bericht - Anlage So (Sonstige Einkünfte)",
      audit: () => "Transaktionsbericht prüfen",
      bgl: () => "BGL Simple Fund 360 Datenexport",
      "capital-gains": () => "Bericht über Kapitalgewinne",
      "capital-gains-nz": () => "Bericht über realisierte Gewinne/Verluste",
      cerfa2086: () => "Formular Nr. 2086",
      expense: () => "Sonstige Spesenabrechnung",
      form3916bis: () => "Formular 3916 bis",
      "gift-and-lost": () =>
        "Bericht über ausgehende Geschenke und verlorene/gestohlene Vermögenswerte",
      income: () => "Einkommensbericht",
      inventory: () => "Inventarbericht",
      "inventory-v2": () => "Inventarbericht",
      "irs-1040-s1": () => "IRS-Anhang 1 (Formular 1040)",
      "irs-1040-sd": () => "Anhang D (Formular 1040)",
      "irs-8949": () => "IRS-Formular 8949",
      "irs-8949-consolidated": () => "IRS-Formular 8949 (konsolidiert)",
      "irs-8949-long": () => "Formular 8949 Langfristig",
      "irs-8949-long-consolidated": () =>
        "Formular 8949 Langfristig (Konsolidiert)",
      "irs-8949-short": () => "Formular 8949 Kurzfristig",
      "irs-8949-short-consolidated": () =>
        "Formular 8949 Kurzfristig (Konsolidiert)",
      "modelo-100": () => "Modell 100",
      mygov: () => "ATO myTax-Bericht",
      "nft-report": () => "NFT-Bericht",
      "report-summary": () => "Berichtszusammenfassung",
      "start-of-year-holdings": () => "Bestandsbericht zum Jahresbeginn",
      "tax-lots": () => "Bestandsaufnahmebericht",
      "taxact-csv": () => "TaxAct - Bericht über Kapitalerträge",
      "trading-pnl": () => "Derivatehandelsbericht",
      "trading-stock": () => "Handelsbestandsbericht (Händler)",
      transaction: () => "Transaktionsbericht",
      "turbotax-canadian-pdf": () => "TurboTax PDF",
      "turbotax-csv": () => "TurboTax - Bericht über Kapitalerträge",
      "turbotax-txf": () => "TurboTax TXF - Kapitalertragsbericht",
      "wiso-steuer": () => "WISO Steuer CSV Report",
    },
    reportTypeSelector: () => "Berichtstyp",
    rewardsAndInterest: () => "Belohnungen & Zinsen",
    rowsPerPage: () => "Zeilen pro Seite",
    royalties: () => "Lizenzgebühren",
    saFinancialYear: (d) =>
      "1. März " + d.prevYear + " – " + d.febLastDay + " . Februar " + d.year,
    saveMoney: () =>
      "Führen Sie die Überprüfungsschritte aus, um Geld zu sparen!",
    savingsOpportunity: () => "Einsparmöglichkeiten",
    selectPlan: () => "Wähle einen Plan aus",
    sellTime: () => "Verkauft",
    shortTermGains: () => "Kurzfristige Kapitalgewinne",
    shortTermGainsNoCGT: () => "Realisierter Gesamtgewinn",
    stakingIncome: () => "Staking-Belohnungen",
    summary: {
      reviewTips: {
        button: () => "Überprüfen Sie die Transaktionen, um zu sparen",
        subTitle: {
          control: () => "Ihre Sparmöglichkeit ist ",
          ourTaxMinimisationAlgorithmCouldSaveYou: () =>
            "Unser Steuerminimierungsalgorithmus könnte Ihnen sparen ",
          youCouldMinimiseYourTaxLiabilityBy: () =>
            "Sie können Ihre Steuerschuld minimieren, indem Sie ",
          youCouldSaveXyzInTaxes: {
            prefix: () => "Sie könnten sparen ",
            suffix: () => " bei den Steuern",
          },
        },
        title: () => "Zahlen Sie nicht zu viel Steuern! 💸",
        tooltip: () =>
          "Der geschätzte Betrag, um den Sie Ihren Kapitalgewinn reduzieren könnten, indem Sie alle überprüften Probleme kategorisieren und lösen.",
      },
      title: () =>
        "Abonnieren Sie, um Tipps zum Sparen bei Ihrer Steuererklärung zu erhalten",
      txs: (d) => plural(d.count, 0, de, { 1: " tx ", other: " txs " }),
    },
    tablePagination: (d) => d.from + " – " + d.to + " von " + d.count,
    taxReports: () => "Steuerberichte",
    taxSettingWarnings: {
      uncategorisedBothAsTaxable: () =>
        "Nicht kategorisierte ausgehende Transaktionen werden als Marktverkäufe und nicht kategorisierte eingehende Transaktionen als Marktkäufe behandelt.",
      uncategorisedInAsTaxable: () =>
        "Nicht kategorisierte eingehende Transaktionen werden als On-Market-Käufe behandelt",
      uncategorisedOutAsTaxable: () =>
        "Nicht kategorisierte ausgehende Transaktionen, die als On-Market-Verkäufe behandelt werden",
    },
    taxSettings: () => "Steuereinstellungen",
    taxablePortfolioValue: () => "Portfoliowert",
    tooltips: {
      airdrop: () => "Einnahmen aus per Airdrop erzielten Kryptowährungen",
      approvals: () => "Genehmigungsgebühren beim Krypto-Handel",
      capitalLosses: () =>
        "Gesamte realisierte Kapitalverluste während des Geschäftsjahres",
      capitalLossesNoCGT: () => "Gesamtverluste im Geschäftsjahr",
      derivativeTradingIncome: () =>
        "Gewinne oder Verluste aus dem Handel mit Derivaten, Margen und Futures",
      expenses: () => "Sonstige Kosten, die beim Krypto-Handel anfallen",
      fees: () => "Gebühren beim Krypto-Handel",
      income: () =>
        "Sonstige erhaltene Einkünfte, z. B. aus Prämien, Empfehlungen, Boni usw.",
      loan: () =>
        "Für die Aufrechterhaltung einer offenen Kredit- oder Margin-Position gezahlte Zinsen und/oder Tilgung",
      longTermCapitalLosses: (d) =>
        "Gesamte realisierte Kapitalverluste aus Vermögenswerten, die länger als " +
        d.longTermThreshold +
        " Monate gehalten wurden",
      longTermThreshold: (d) =>
        "Gesamte realisierte Kapitalgewinne aus Vermögenswerten, die länger als " +
        d.longTermThreshold +
        " Monate gehalten wurden",
      longTermThresholdNoCGT: (d) =>
        "Gesamtgewinn aus Vermögenswerten, die länger als " +
        d.longTermThreshold +
        " Monate gehalten werden",
      mining: () => "Mining-Belohnungen, die als Einkommen gelten",
      miscellaneousExpenses: () =>
        "Verschiedene während des Geschäftsjahres angefallene Ausgaben",
      miscellaneousIncome: () =>
        "Sonstige während des Geschäftsjahres erzielte Einkünfte",
      noCapitalGains: () =>
        "Gesamte im Geschäftsjahr realisierte Kapitalgewinne",
      noCapitalGainsNoCGT: () => "Gesamter im Geschäftsjahr erzielter Gewinn",
      noLongTermThreshold: () =>
        "Keine langfristige Schwelle, alle Gewinne werden als kurzfristig betrachtet",
      pnl: () =>
        "Realisierter Gewinn aus Handelsmargen, Futures, Perpetuals, Optionen usw.",
      royalty: () => "Einnahmen aus Lizenzgebühren",
      shortTermCapitalLosses: (d) =>
        "Gesamte realisierte Kapitalverluste aus Vermögenswerten, die weniger als " +
        d.longTermThreshold +
        " Monate gehalten wurden",
      staking: () => "Einnahmen aus Zinsen und Staking-Belohnungen",
      totalCapitalGains: (d) =>
        "Gesamte realisierte Kapitalgewinne aus Vermögenswerten, die weniger als " +
        d.longTermThreshold +
        " Monate gehalten wurden",
      totalCapitalGainsNoCGT: (d) =>
        "Gesamter realisierter Gewinn/Verlust aus Vermögenswerten, die weniger als " +
        d.longTermThreshold +
        " Monate gehalten wurden",
      totalGains: () =>
        "Nettokapitalgewinne plus Nettoeinkommen minus Nettoausgaben",
    },
    totalCapitalGains: () => "Gesamtkapitalgewinne",
    totalCapitalGainsNoCGT: () => "Gesamte realisierte Gewinne/Verluste",
    totalGains: () => "Gesamtgewinne",
    totalLosses: () => "Gesamtkapitalverluste",
    totalLossesNoCGT: () => "Gesamte realisierte Verluste",
    tradingPnL: () => "Handels-Gewinn- und Verlustrechnung",
    tradingStockAu: {
      acquiredStockQuantity: () => "Erworbene Menge",
      acquiredTotalValue: () => "Erworbener Wert",
      currency: () => "Währung",
      disposedStockQuantity: () => "Entsorgte Menge",
      disposedTotalValue: () => "Veräußerter Wert",
      endingPrice: () => "Endpreis",
      endingStock: () => "Endbestand",
      endingValue: () => "Endwert",
      netProfit: () => "Reingewinn",
      startingPrice: () => "Startpreis",
      startingStock: () => "Anfangsbestand",
      startingValue: () => "Startwert",
    },
    txf: () => "TXF",
    ukFinancialYear: (d) => "6. April " + d.prevYear + " - 5. April " + d.year,
    unknownGains: () => "Unbekannte Gewinne",
    unknownGainsNoCGT: () => "Unbekannte Gewinne",
    upgradeToPlanTooltip: (d) =>
      "Aktualisieren Sie auf den " +
      d.plan +
      " um auf diesen Bericht zuzugreifen",
    viewAll: () => "Alle",
    viewOtherReports: () => "Andere Berichte anzeigen",
    warning: () =>
      "Warnungen können zu falschen Berechnungen führen. Stellen Sie sicher, dass Sie alle Ihre Daten importiert und Ihre Transaktionen bestätigt haben",
    warningActionText: () => "Transaktionen überprüfen",
    warningTitle: () => "Sie haben Warnungen für ausstehende Transaktionen",
    warningZeroCost: {
      bodyText1: () =>
        "Wir haben Probleme mit Ihren Transaktionen festgestellt ",
      bodyText2: () =>
        "Eine Transaktion auf Nullkostenbasis liegt vor, wenn ein Verkauf erfolgt, aber kein Vermögenswert verfügbar ist. Sie können Ihre Steuern senken, indem Sie Probleme mit fehlenden Vermögenswerten beheben. Sie können diese Warnung auch ignorieren. ",
      bodyValuePostNumber: () => ". ",
      bodyValuePreNumber: () => "insgesamt ",
      breakdownPrefix: () =>
        "Top-Währungen mit ungelösten Problemen und verkauften übernommenen Vermögenswerten",
      buttonText: () => "Alle Währungen anzeigen",
      includedInGains: () => "Zahlen Sie nicht mehr als nötig!",
      innerBodyText: () =>
        "Wir haben einen Preis von Null für verkaufte Vermögenswerte angenommen",
      innerTitle: () => "Alle Währungen mit ungelösten Problemen",
      title: () =>
        "Bewertungen erforderlich – Der realisierte Gewinn kann ungenau sein.",
      titleNoCGT: () =>
        "Bewertungen erforderlich – Der realisierte Gewinn kann ungenau sein.",
    },
    warningZeroCostSummary: {
      body: (d) =>
        "Wir haben einen Preis von null für verkaufte Vermögenswerte (insgesamt " +
        d.total +
        " angenommen. Sie können Ihre Steuern senken, indem Sie Probleme mit fehlenden Vermögenswerten beheben.",
      title: () => "Überprüfung erforderlich",
    },
    wentWrongDownloadingBGL: () =>
      "Beim Exportieren der BGL Simple Fund 360 XML ist ein Fehler aufgetreten.",
    wentWrongDownloadingCSV: () =>
      "Beim Herunterladen der CSV-Datei ist ein Fehler aufgetreten.",
    wentWrongDownloadingPDF: () =>
      "Beim Herunterladen des PDF ist ein Fehler aufgetreten.",
    wentWrongDownloadingTXF: () =>
      "Beim Herunterladen der TurboTax TXF-Datei ist ein Fehler aufgetreten.",
    wentWrongRefreshing: () =>
      "Beim Aktualisieren des Berichts ist ein Fehler aufgetreten.",
    xml: () => "XML",
    ytd: () => "Geschäftsjahr bis heute",
  },
  reportError: {
    button: {
      display: () => "Fehler anzeigen",
      hide: () => "Fehler ausblenden",
    },
    soldOut: () =>
      "Verkaufstransaktionen ohne ausreichendes Restguthaben – angenommene Null-Dollar-Kostenbasis",
    title: () => "Plausibilitätsprüfung",
    tooltip: {
      soldOut: () =>
        "Wir haben für diese Transaktionen eine Kostenbasis von null Dollar angenommen. Dies wird dadurch verursacht, dass Verkaufstransaktionen durchgeführt werden, ohne dass genügend Menge übrig bleibt. Wenn es sich um einen großen Betrag handelt, kann dies durch fehlende Transaktionen verursacht werden. Wenn es sich dagegen um einen kleinen Betrag handelt, kann dies durch Wechselzinsen, Rundungsgebühren usw. verursacht werden. Sie können auf der Seite „Transaktionen überprüfen“ nach Fehlern filtern.",
      uncategorized: () =>
        "Wir haben diese Transaktionen bei der Berechnung ignoriert. Sie können auf den Link klicken, um Ein-/Ausgänge zu kategorisieren.",
    },
    uncategorized: () =>
      "nicht kategorisierte Transaktionen – diese Transaktionen wurden ignoriert",
  },
  reset: () => "Zurücksetzen",
  retry: () => "Wiederholen",
  rules: {
    addRule: () => "Regel hinzufügen",
    categoryRuleWarning: () =>
      "Um eine Kategorieregel zu erstellen, stellen Sie bitte sicher, dass eine Ihrer Bedingungen darin besteht, dass die TX-Kategorie entweder nur eingehend oder nur ausgehend ist.",
    createRule: () => "Regel erstellen",
    deleteDialog: {
      text: {
        checkbox: () =>
          "Erlauben Sie anderen Regeln, zuvor von dieser Regel vorgenommene Änderungen zu überschreiben",
        lineOne: () => "Dies wird",
        lineTwo: () =>
          "Beachten Sie, dass die durch diese Regel vorgenommenen Änderungen bestehen bleiben, sofern sie nicht manuell oder durch eine neue Regel geändert werden.",
        listOne: () =>
          "Trennen Sie diese Regel von den Transaktionen, auf die sie angewendet wurde",
        listTwo: () =>
          "Beenden der Anwendung der Regel auf zukünftige Transaktionen",
      },
      title: () => "Regel löschen",
    },
    disableDialog: {
      button: () => "Deaktivieren",
      text: {
        listOne: () =>
          "Dadurch wird verhindert, dass die Regel auf zukünftige Transaktionen angewendet wird.",
        listThree: () =>
          "Sie können diese Regel später jederzeit wieder aktivieren",
        listTwo: () =>
          "Transaktionen, für die diese Regel aktuell gilt, bleiben davon betroffen. Das bedeutet, dass neue Regeln mit denselben Kriterien diese Regel nicht außer Kraft setzen.",
      },
      title: () => "Regel deaktivieren",
    },
    enableDialog: {
      button: () => "Aktivieren",
      text: {
        listOne: () =>
          "Wenn Sie diese Regel aktivieren, wird sie auf alle Transaktionen angewendet, die ihre Bedingungen erfüllen und auf die noch keine Regel angewendet wurde.",
      },
      title: () => "Regel aktivieren",
    },
    learnAboutRules: {
      button: () => "Erfahren Sie mehr über Regeln",
      content: () =>
        "Mithilfe von Regeln können Sie sowohl die Kategorisierung Ihrer Transaktionen als auch die Zuordnung des Kontenplans für jede Transaktion automatisieren. Dies kann nützlich sein, wenn Sie viele allgemeine Aktionen haben, die CTC für Sie ausführen soll!",
      content2list1: () =>
        "Regeln werden in der Reihenfolge angewendet, in der sie in der Tabelle erscheinen. Das bedeutet, wenn Sie zwei Regeln haben, die beide für eine Transaktion gelten könnten, hat immer die Regel Vorrang, die weiter oben in der Tabelle steht.",
      content2list2: () =>
        "Regeln können nicht teilweise angewendet werden. Das bedeutet, wenn Sie eine Regel mit höherer Priorität haben, die nur das Vermögenskonto festlegt, und dann eine Regel mit niedrigerer Priorität, die sowohl das Vermögens- als auch das Gewinnkonto anwendet, wird aufgrund des ersten Punktes nur die Regel mit höherer Priorität angewendet, und die zweite kann nicht angewendet werden, da das Vermögenskonto bereits durch eine Regel festgelegt wurde.",
      content2list3: () =>
        "Regeln können nach der Anwendung nicht überschrieben werden. Das bedeutet, wenn Sie eine bestehende Regel auf eine Transaktion angewendet haben und dann eine neue Regel erstellen, die auch für diese Transaktion gelten soll, und ihr eine höhere Priorität zuweisen, wird sie trotzdem nicht auf diese Transaktion angewendet, es sei denn, Sie löschen die erste Regel.",
      content2part1: () =>
        "Beim Arbeiten mit Regeln müssen Sie vor allem drei Dinge beachten:",
      content2part2: () =>
        "Indem Sie sich diese Prinzipien merken, können Sie Regeln beherrschen und Ihre Arbeitsabläufe beschleunigen!",
      content3: () =>
        "Sie können Regeln direkt von dieser Seite aus oder auf den Seiten „Adressbuch“ oder „Integrationen“ für Regeln erstellen, die sich auf bestimmte Wallets und Börsen beziehen. Sie können aber auch kontextbezogene Regeln auf der Transaktionsseite erstellen! Wenn Sie Transaktionen kategorisieren oder den Kontenplan für eine Transaktion definieren, wird Ihnen die Option angezeigt, diese Auswahl auf zukünftige Transaktionen anzuwenden. Wenn Sie diese Option auswählen, wird eine Regel basierend auf Ihrer Auswahl und einem Kriterium erstellt, das ähnliche Transaktionen wie die identifiziert, an der Sie gerade arbeiten. Diese Regel wird automatisch am Ende der Hierarchie auf der Regelseite hinzugefügt!",
      subtitle1: () => "Was sind Regeln?",
      subtitle2: () => "Wie funktionieren Regeln?",
      subtitle3: () => "Wo fange ich mit Regeln an?",
      title: () => "Erfahren Sie mehr über Regeln",
    },
    navTabs: {
      rules: () => "Aktive Regeln",
      rulesDisabled: () => "Deaktivierte Regeln",
    },
    operationShorthands: {
      changeErpAssetAccount: () => "Vermögenswert",
      changeErpCashAccount: () => "Kasse",
      changeErpGainsAccount: () => "Belohnungen",
      changeErpLoanAccount: () => "Darlehen",
      changeErpPnLAccount: () => "Gewinn- und Verlustrechnung",
      recategorise: () => "Kategorie",
    },
    operatorSelection: {
      accountPlaceholder: () => "Wählen Sie ein Konto aus",
      categoryPlaceholder: () => "Wählen Sie eine Kategorie",
    },
    pageTitle: () => "Regeln",
    rulesTable: {
      empty: () => "Keine Regeln",
      headers: {
        accountsMapping: () => "Kontenzuordnung",
        conditions: () => "Bedingungen",
        name: () => "Name",
      },
      loadMore: () => "Mehr laden",
      row: {
        options: {
          delete: () => "Regel löschen",
          deleteInfo: () =>
            "Die Regel wird gelöscht. Auf die Transaktionen, bei denen die Regel angewendet wurde, können neue Regeln angewendet werden.",
          disable: () => "Regel deaktivieren",
          disableInfo: () =>
            "Die Regel gilt nicht für neue Transaktionen. Bestehende Transaktionen unterliegen jedoch weiterhin dieser Regel.",
          edit: () => "Bearbeiten",
          enable: () => "Regel aktivieren",
          moveToBottom: () => "Nach unten verschieben",
          moveToTop: () => "Nach oben",
          viewRule: () => "Regel anzeigen",
          viewTxs: () => "Transaktionen anzeigen",
        },
      },
    },
  },
  save: () => "Speichern",
  selectCountry: {
    autoDetectedCountry: {
      applyLatestRules: (d) =>
        "Wir wenden die neuesten Steuervorschriften " + d.authority + " an.",
      countryDetected: (d) =>
        "Sieht so aus, als ob Sie sich in " +
        select(d.prefixCountryWithThe, { true: " dem ", other: "" }) +
        d.countryName +
        " befinden.",
      plusMore: (d) => "+ " + d.count + " mehr",
      selectAnotherCountry: () => "Oder wählen Sie ein anderes Land",
      submitCTA: () => "Lass uns gehen",
    },
    body: () => "Ihr Land muss die Steuerregeln für diesen Rechner festlegen",
    continue: () => "Weitermachen",
    loadingOption: () => "Optionen werden geladen...",
    noOptions: () => "Keine Optionen",
    noResults: () => "Keine Ergebnisse",
    optimizeTaxSettings: {
      AT: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des BMF zu erfüllen.",
      AU: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des ATO zu erfüllen.",
      BE: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des FPSF zu erfüllen.",
      CA: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der CRA zu erfüllen.",
      CH: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der SFTA zu erfüllen.",
      DE: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des BZSt zu erfüllen.",
      ES: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des AEAT zu erfüllen.",
      FI: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der FTA zu erfüllen.",
      FR: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der DGFIP zu erfüllen.",
      GR: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der IAPR zu erfüllen.",
      IE: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der TRC zu erfüllen.",
      IN: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der ITR zu erfüllen.",
      IT: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der ADE zu erfüllen.",
      JP: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der NTA zu erfüllen.",
      NL: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des DTCA zu erfüllen.",
      NO: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der NTA zu erfüllen.",
      NZ: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des IRD zu erfüllen.",
      PT: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der PTCA zu erfüllen.",
      SE: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen der STA zu erfüllen.",
      SG: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des IRAS zu erfüllen.",
      UK: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des HMRC zu erfüllen.",
      US: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des IRS zu erfüllen.",
      ZA: () =>
        "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen des SARS zu erfüllen.",
    },
    optimizeTaxSettingsGeneric: (d) =>
      "Wir optimieren Ihre Steuereinstellungen, um die Anforderungen von " +
      d.taxAuthority +
      " zu erfüllen.",
    pending: () => "Ausstehend",
    reportForms: {
      ATO: () => "ATO",
      Form8949: () => "Formular 8949",
      HMRC: () => "Britische Steuerbehörde",
      IRS: () => "IRS",
      adjustedCostBase: () => "Angepasste Kostenbasis",
      averageCostBase: () => "Durchschnittliche Kostenbasis",
      bedAndBreakfast: () => "30 Tage Übernachtung mit Frühstück",
      capitalGainsReport: () => "Bericht über Kapitalgewinne",
      cerfa2086: () => "Formular Nr. 2086",
      cra: () => "CRA",
      incomeReport: () => "Einkommensbericht",
      myTax: () => "ATO myTax-Bericht",
      reports: () => "Steuerberichte",
      sameDay: () => "Regel für denselben Tag",
      schedule3: () => "Anhang 3",
      scheduleD: () => "Anlage D 1040",
      superficialLoss: () => "Oberflächliche Verlustregel",
      t1Income: () => "T1-Einkommen",
      turboTaxOnline: () => "TurboTaxOnline",
    },
    title: () => "Land auswählen",
    title2: () => "Länderauswahl",
  },
  settings: {
    accounting: {
      dataManagement: () => "Datenmanagement",
      inviteLink: {
        body: () =>
          "Über diesen Link werden Kunden zur Erstellung eines Kontos bei CTC weitergeleitet. Ihre Daten werden automatisch an Sie weitergegeben.",
        bodyWide: () =>
          "Geben Sie diesen Link an Ihre Kunden weiter. Diese werden dann zur Erstellung eines Kontos bei CTC weitergeleitet. Ihre Daten werden automatisch an Sie weitergegeben.",
        copy: () => "Kopie",
        customiseLink: () => "Link anpassen",
        generatingCode: () => "Code wird generiert...",
        new: () => "Neu",
        title: () => "Persönlicher Einladungslink",
        titleCollaborator: () => "Einladungslink für Buchhalter",
        titleWide: () => "Ihr persönlicher Kundeneinladungslink ",
        titleWideCollaborator: () =>
          "Der Kundeneinladungslink Ihres Buchhalters",
      },
      profile: () => "Buchhaltereinstellungen",
      title: () => "Buchhaltung",
    },
    accountingData: {
      updateDataSourceError: () =>
        "Beim Aktualisieren der Datenquelle ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
      updateDataSourceSuccess: () => "Datenquelle erfolgreich aktualisiert",
    },
    billing: {
      description: {
        part1: () => "Verwalten Sie Ihr Abonnement. Anzeigen ",
        part2: () => "Servicebedingungen.",
      },
      manage: () => "Abonnement verwalten",
      nextBilling: () => "Nächster Abrechnungstermin",
      nextBillingDetails: (d) => d.date + " für " + d.amount + " " + d.currency,
      perYear: () => "/Jahr",
      title: () => "Abonnementdetails",
      upgrade: () => "Abonnement upgraden",
    },
    clearData: {
      buttonText: () => "Daten löschen",
      description: (d) =>
        select(d.isAccountant, {
          true: " Löscht alle Kundendaten aus dem Konto, einschließlich Transaktionen und Importquellen. Diese Aktion ist nicht umkehrbar. ",
          other:
            " Löscht alle Daten aus Ihrem Konto. Diese Aktion ist nicht umkehrbar. ",
        }),
      text: (d) =>
        select(d.isAccountant, {
          true: " Achtung! Möchten Sie wirklich alle Daten Ihres Kunden löschen? Diese Aktion kann nicht rückgängig gemacht werden. ",
          other:
            " Achtung! Möchten Sie wirklich alle Ihre Daten löschen? Diese Aktion kann nicht rückgängig gemacht werden. ",
        }),
      titleClient: (d) => "Daten für " + d.clientName + " löschen",
      titleClientFallback: () => "Kundendaten löschen",
      titleSelf: () => "Daten löschen",
    },
    clientAccount: () => "Kundenprofil",
    clientData: {
      dataBody: () =>
        "Gemeinsam genutzte Datenquelle zwischen Ihnen und Ihrem Kunden.",
      invitation: {
        status: {
          accepted: () => "Einladung angenommen",
          declined: () => "Einladung abgelehnt",
          default: () => "Buchhalter einladen",
          pending: (d) => "Einladung " + d.direction,
          revoked: () => "Einladung widerrufen",
        },
        verboseStatus: {
          accepted: () => "Der Kunde hat Ihre Einladung angenommen",
          declined: () => "Sieht so aus, als ob Ihre Einladung abgelehnt wurde",
          deleted: () => "Sieht aus, als ob Ihre Einladung gelöscht wurde",
          pending: () =>
            "Einladung gesendet. Sie erhalten eine E-Mail, wenn Ihr Kunde die Einladung angenommen hat",
          revoked: () => "Der Kunde hat Ihre Einladung widerrufen",
          revokedLink: () => "Der Kunde hat Ihre Einladung widerrufen",
        },
      },
      invitationBody: () =>
        "Fügen Sie die E-Mail-Adresse Ihres Kunden hinzu, um ihn zum Teilen und Verwalten von Daten einzuladen. Nachdem Ihr Kunde die Einladung angenommen hat, können Sie seine E-Mail-Adresse nicht mehr ändern.",
      invitationStatus: () => "Einladungsstatus",
      paymentPlan: () => "Zahlungsplan",
      profile: () => "Kundendetails",
      tooltip: () => "Diese Einstellung kann nur vom Kunden geändert werden",
    },
    dataCleared: () => "Daten gelöscht",
    exitSurvey: {
      cancellationReasonQuestion: (d) =>
        select(d.variation, {
          DeleteAccount:
            " Bevor wir Ihr Konto löschen, was ist der Hauptgrund für die Kündigung Ihres Dienstes? ",
          other:
            " Bevor Sie fortfahren, was war der Hauptgrund für die Kündigung Ihres Plans? ",
        }),
      cancellationReasons: {
        accountant: () => "Einen Buchhalter beauftragen",
        countrySupport: () => "Land wird nicht unterstützt",
        hardToUse: () => "Schwer zu bedienen",
        importSupport: () =>
          "Exchange oder Blockchain werden nicht unterstützt",
        incorrectResults: () => "Falsche Ergebnisse",
        noLongerTradingCrypto: () => "Kein Krypto-Handel mehr",
        timeConsuming: () => "Zeitaufwendig",
        tooExpensive: () => "Zu teuer",
      },
      continue: () => "Weitermachen",
      otherCommentsQuestion: () =>
        "Möchten Sie uns sonst noch etwas mitteilen?",
      title: (d) =>
        select(d.variation, {
          DeleteAccount: " Löschen Sie Ihr Konto ",
          other: " Gekündigter Plan ",
        }),
    },
    hideSpamError: () =>
      "Beim Ändern der Filtereinstellungen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    hmrc: {
      actionText: () => "Anerkannt",
      text: () =>
        "Die alten Berechnungen der britischen Steuerbehörde HM Revenue and Customs sollten nur als Referenz verwendet werden. Bitte verwenden Sie für Ihre Steuererklärung die aktualisierte Methode.",
      title: () =>
        "Bestätigen Sie, dass Sie die alten Berechnungen von HM Revenue and Customs anzeigen möchten",
    },
    lockPeriod: {
      add: {
        firstTransaction: () => "Ihre erste Transaktion",
        from: () => "Aus",
        to: () => "Zu",
      },
      addButton: () => "Sperrzeitraum hinzufügen",
      bannerText: () =>
        "Eine Sperrung ist lediglich für bereits an Ihr Finanzamt übermittelte Vorperioden empfehlenswert.",
      beginningOfTime: () => "Start",
      cancel: () => "Stornieren",
      currentPeriod: {
        tooltip: () =>
          "Klicken Sie oben auf die Schaltfläche „Sperrzeitraum hinzufügen“, um einen neuen Sperrzeitraum zu erstellen.",
      },
      description: () =>
        "Durch das Sperren eines Zeitraums wird verhindert, dass Änderungen an den Transaktionen innerhalb dieses Zeitraums vorgenommen werden. Mit diesem Tool können Sie sicherstellen, dass sich Ihre Steuerberechnungen für vergangene Steuerjahre nicht ändern, nachdem Sie Ihre Steuerberichte eingereicht haben.",
      dialog: {
        action: () => "Freischaltzeitraum",
        checklist: {
          balances: () => "Zu ändernde Guthaben",
          gains: () => "Kalkulierte Gewinne zu ändern",
          reports: () => "Berichte zum Ändern",
        },
        confirm: () =>
          "Bestätigen Sie, dass Sie die oben genannten Risiken kennen, indem Sie die Kontrollkästchen aktivieren, bevor Sie fortfahren",
        text: () =>
          "Das Entsperren dieses Zeitraums kann Folgendes verursachen:",
        title: () => "Freischaltzeitraum?",
        warning: () => "Achtung: Dies kann nicht rückgängig gemacht werden",
        warningParagraph: () =>
          "Um eine Kopie Ihrer aktuellen Berichtswerte aufzubewahren, empfehlen wir Ihnen, Ihre Steuerberichte herunterzuladen, bevor Sie das Steuerjahr freischalten, da sich die Werte ändern können.",
        warningWithSubsequent: () =>
          "Achtung: Alle folgenden Zeiträume werden ebenfalls freigeschaltet. Dies kann nicht rückgängig gemacht werden.",
      },
      disabledCreateLock: () => "Du bist bis heute eingesperrt",
      inventoryMethodDisabled: (d) =>
        "Sie können keine gesperrten Zeiträume hinzufügen, wenn Sie die Inventarmethode " +
        d.inventoryMethod +
        " verwenden",
      inventorySnapshots: {
        closing: {
          description: () =>
            "Eine Momentaufnahme der Lose, aus denen Ihr Inventar am Ende des Zeitraums besteht, bevor etwaige Umverteilungen für den nächsten Zeitraum vorgenommen wurden.",
          title: () => "Schließen der Bestandsaufnahme",
        },
        opening: {
          description: () =>
            "Eine Momentaufnahme der Lose, aus denen Ihr Lagerbestand zu Beginn des Zeitraums besteht, nachdem alle erforderlichen Umverteilungen vorgenommen wurden.",
          title: () => "Öffnen der Bestandsübersicht",
        },
        title: () => "Bestandsaufnahmen",
      },
      issuesModal: {
        cancel: () => "Stornieren",
        currentIssues: () => "Aktuelle Themen",
        description: () =>
          "Um die Genauigkeit sicherzustellen, müssen Sie vor dem Sperren dieses Zeitraums auf einige Elemente achten.",
        ignoreAndLock: () => "Ignorieren und trotzdem sperren",
        newIssueExplainer: () =>
          "Da Sie sich darauf vorbereiten, diesen Zeitraum zu sperren, haben wir nicht abgeglichene Überweisungen markiert, die aufgelöst werden müssen, um Ihre Kostenbasis korrekt zu sperren.",
        title: () => "Vor dem Sperren zu behebende Probleme",
        viewIssues: () => "Probleme anzeigen und lösen",
        warning: () =>
          "Das Sperren jetzt, ohne diese Probleme zu beheben, kann zu nicht lösbaren Datenproblemen führen und Sie dazu zwingen, diesen Zeitraum später zu entsperren.",
      },
      lockPeriodTooltip: () => "Sperrfrist",
      lockUnlockedWarning: {
        action: () => "Trotzdem sperren",
        line1: () =>
          "Bitte stellen Sie sicher, dass Sie alle Transaktionen abgeglichen haben, bevor Sie sie sperren. Andernfalls kann es in Zukunft zu Problemen kommen.",
        line2: () =>
          "Sie können diese Zeiträume später jederzeit wieder freischalten, Änderungen an den Steuereinstellungen bleiben jedoch nicht erhalten.",
        title: () => "Sperrzeiten bestätigen",
        warning: () =>
          "Achtung: Durch das Erstellen einer neuen Sperrperiode werden auch alle nicht freigegebenen Perioden gesperrt.",
        warningLockExisting: () =>
          "Achtung: Wenn Sie diesen Zeitraum sperren, werden auch alle zuvor entsperrten Zeiträume gesperrt.",
      },
      noLockPeriods: () => "Sie haben keine gesperrten Zeiträume",
      now: () => "Jetzt",
      saveButton: () => "Gesperrte Zeit speichern",
      title: () => "Sperrfristen",
      unlockAllSubsequentButtonTooltip: () =>
        "Alle darauffolgenden Zeiträume werden ebenfalls freigeschaltet.",
      unlockButtonTooltip: () => "Freischaltzeitraum",
    },
    longTermThreshold: {
      custom: () => "Brauch",
      default: (d) => "Standard ( " + d.defaultValue + " + Monate)",
      longTermThreshold: () => "Langzeitschwelle",
      months: () => "Monate",
      never: () => "Niemals",
    },
    manageData: {
      buttonText: () => "Konto löschen",
      clear: () => "Klar",
      confirmClearData: () => "Daten für immer löschen",
      confirmClearDataPrompt: () =>
        "Geben Sie „clear-data-forever“ ein, um mit dem Löschen der Daten dieses Kontos fortzufahren.",
      confirmDelete: () => "Dieses Konto für immer löschen",
      confirmDeletePrompt: () =>
        "Geben Sie „delete-this-account-forever“ ein, um mit dem Löschen dieses Kontos fortzufahren.",
      delete: () => "Löschen",
      description: (d) =>
        select(d.isAccountant, {
          true: " Durch das Löschen des Kundenkontos werden sowohl die Daten als auch der Kunde entfernt. Diese Aktion ist nicht umkehrbar. ",
          other:
            " Durch das dauerhafte Löschen Ihres Kontos werden alle Ihre Daten aus unserem System entfernt und Sie werden abgemeldet. ",
        }),
      manageData: () => "Daten verwalten",
      text: (d) =>
        select(d.isAccountant, {
          true: " Achtung! Sie werden von den Daten Ihres Kunden getrennt und diese können gelöscht werden. Ihr Abonnement, falls Sie eines bezahlt haben, wird gekündigt. Diese Aktion kann nicht rückgängig gemacht werden. ",
          other:
            " Achtung! Alle Ihre Daten werden gelöscht. Sobald dies abgeschlossen ist, werden Sie abgemeldet und Ihr Konto wird aus unserem System entfernt. Diese Aktion kann nicht rückgängig gemacht werden. ",
        }),
      titleClient: (d) => d.clientName + " als Client löschen",
      titleClientFallback: () => "Client löschen",
      titleSelf: () => "Konto löschen",
    },
    notifications: {
      errors: {
        generic: () =>
          "Die Aktualisierung Ihrer Benachrichtigungseinstellungen ist fehlgeschlagen. Wenden Sie sich bitte an den Support.",
      },
    },
    pageTitle: (d) => d.tab + " Einstellungen",
    plan: {
      clientOwned: {
        body: () =>
          "Bitte wenden Sie sich direkt an den Kunden und bitten Sie ihn, sein Konto zu aktualisieren, wenn Sie auf gesperrte Funktionen zugreifen oder eine höhere Transaktionszahl wünschen.",
        title: () => "Dieser Kunde verwaltet seine Abrechnung selbst",
      },
      title: () => "Baupläne",
    },
    portfolio: () => "Portfolio",
    portfolioTab: {
      assetTracking: () => "Anlagenverfolgung",
    },
    priceWindow: {
      off: () =>
        "Zur Ermittlung der Marktpreise wird kein Preisvergleichsfenster verwendet",
      on: (d) =>
        "Wenn es keinen genauen Marktpreis für einen Kryptowert gibt, verwenden Sie den nächstgelegenen verfügbaren Preis innerhalb eines " +
        d.value +
        " -Stunden-Zeitraums.",
      title: () => "Preis-Fallback-Fenster",
    },
    privacy: {
      part1: () => "Wir geben Ihre Daten nicht weiter. Lesen Sie unsere ",
      part2: () => "Datenschutzrichtlinie",
    },
    profile: {
      addEmail: () => "E-Mail hinzufügen",
      addInviteCode: () => "Fügen Sie Ihren Einladungscode hinzu",
      addName: () => "Füge deinen Namen hinzu",
      changeEmail: () => "E-Mail ändern",
      changeInviteCode: () => "Ändern Sie Ihren Einladungscode",
      changeName: () => "Namen ändern",
      country: () => "Land",
      debug: {
        title: () => "Unterstützung",
        uid: {
          notification: () => "UID in die Zwischenablage kopiert",
          title: () => "Benutzer-ID",
        },
      },
      duplicateError: () => "Dieser Code wird bereits verwendet",
      edit: () => "Bearbeiten",
      email: () => "E-Mail",
      emailAddressChanged: () => "E-Mail-Adresse geändert",
      emailError: () => "E-Mail-Fehler",
      emailSent: () => "E-Mail gesendet",
      experience: {
        smoothScrolling: {
          title: () => "Sanftes Scrollen (nur dieser Browser)",
          tooltipOff: () =>
            "Das reibungslose Scrollen auf der Transaktionsseite ist für diesen Browser deaktiviert",
          tooltipOn: () =>
            "Für diesen Browser ist das reibungslose Scrollen auf der Transaktionsseite aktiviert.",
        },
        title: () => "Erfahrung",
      },
      inviteCode: () => "Einladungscode",
      inviteCodeChanged: () => "Einladungscode geändert",
      lengthError: () => "Ihr Code muss 4–24 Zeichen lang sein.",
      linkedClientsEmail: () => "E-Mail des Kunden",
      linkedClientsName: () => "Name des Kunden",
      localCurrency: () => "Lokale Währung",
      logout: () => "Ausloggen",
      name: () => "Name",
      nameChanged: () => "Name geändert",
      nameToolTip: () =>
        "Wenn angegeben, wird dieser Name in Ihren generierten Berichten angezeigt",
      noLinkedClient: () => "Kein verknüpfter Client",
      notConnected: () => "Nicht verbunden",
      oauth: {
        areYouSure: (d) =>
          "Möchten Sie " + d.oauth + " OAuth wirklich trennen?",
        cancel: () => "Stornieren",
        connected: () => "Verbunden",
        disconnect: () => "Trennen",
        error: () =>
          "OAuth-Einstellungen konnten nicht aktualisiert werden. Bitte versuchen Sie es später erneut.",
        success: () => "Die Verbindung zu OAuth wurde erfolgreich getrennt.",
        title: (d) => d.oauth + " OAuth",
        walletOauth: () => "Verbunden über Wallet",
        warning: (d) =>
          "Das Trennen der OAuth-Verbindung kann nicht rückgängig gemacht werden. Wenn die Verbindung getrennt wird, müssen Sie sich mit Ihrer E-Mail-Adresse ( " +
          d.email +
          " ) und Ihrem Passwort anmelden.",
      },
      password: {
        accountPassword: () => "Kontokennwort",
        changePassword: () => "Kennwort ändern",
        confirmNewPassword: () => "Neues Passwort erneut eingeben",
        dontMatch: () => "Passwörter stimmen nicht überein",
        newPassword: () => "Neues Passwort",
        oldPassword: () => "Altes Passwort",
        resetPassword: () => "Passwort zurücksetzen",
        saveNewPassword: () => "Neues Passwort speichern",
      },
      resendVerificationEmail: () => "E-Mail erneut senden",
      title: () => "Profil",
      unverified: () => "Nicht verifiziert",
      validationError: () =>
        "Bitte geben Sie einen gültigen URL-sicheren Einladungscode ein",
      verified: () => "Verifiziert",
      verifiedBanner: () => "Ihre E-Mail wurde bestätigt",
      verifyEmail: () => "E-Mail bestätigen",
      wentWrongUpdatingEmail: () =>
        "Beim Aktualisieren der E-Mail ist ein Fehler aufgetreten",
      wentWrongUpdatingInviteCode: () =>
        "Beim Aktualisieren des Einladungscodes ist ein Fehler aufgetreten",
      wentWrongUpdatingName: () =>
        "Beim Aktualisieren des Namens ist ein Fehler aufgetreten",
    },
    report: {
      airdropsAsIncome: {
        title: () => "Behandeln Sie Airdrops als Einkommen",
        tooltip: {
          off: () =>
            "Airdrops werden derzeit als Akquisitionen auf Nullkostenbasis behandelt",
          on: () => "Airdrops werden derzeit als Einkommen behandelt",
        },
      },
      allowBridgeFromUncategorised: {
        title: () => "Bridge-Transaktionen automatisch identifizieren",
        tooltip: {
          off: () =>
            "Überbrückungstransaktionen werden nicht automatisch von nicht kategorisierten Transaktionen unterschieden",
          on: () =>
            "Bridge Transaktionen werden automatisch von nicht kategorisierten Transaktionen unterschieden",
        },
      },
      allowGenerateFeeOnGroupedQuantityDiff: {
        title: () => "Gebühren für Überweisungen automatisch erkennen",
        tooltip: {
          off: () =>
            "Generiert keine automatische Gebühr, wenn es eine Diskrepanz zwischen den gesendeten und empfangenen Beträgen für gruppierte Überweisungen/Bridges gibt",
          on: () =>
            "Automatisches Generieren einer Gebühr, wenn es eine Diskrepanz zwischen den gesendeten und empfangenen Beträgen für gruppierte Überweisungen/Überbrückungen gibt",
        },
      },
      assumeTransferCounterparts: {
        title: () =>
          "Automatisches Generieren der Gegenstücke zu nicht abgeglichenen Überweisungen",
        tooltip: {
          off: () =>
            "CTC wird nicht versuchen, den Gegenwert nicht abgeglichener Überweisungen zu generieren.",
          on: () =>
            "CTC wird versuchen, das Gegenstück zu nicht abgeglichenen Überweisungen zu generieren, wenn dies sicher möglich ist.",
        },
      },
      bridgeAsNonTaxable: {
        title: () => "Bridges als steuerfreie Veräußerung behandeln",
        tooltip: {
          off: () =>
            "Bridges werden derzeit als steuerpflichtige Veräußerungen und Erwerbungen behandelt",
          on: () =>
            "Bridges werden derzeit als Übertragungen der Kostenbasis behandelt",
        },
      },
      collateralAsNonTaxable: {
        title: () =>
          "Behandeln Sie Einlagen und Abhebungen als nicht steuerpflichtig",
        tooltip: {
          off: () =>
            "Einlagen und Abhebungen von Sicherheiten werden derzeit als steuerpflichtige Veräußerungen und Erwerbe behandelt.",
          on: () =>
            "Ein- und Auszahlungen von Sicherheiten werden derzeit als Überweisungen behandelt",
        },
      },
      cryptoToCryptoNonTaxable: {
        title: () =>
          "Behandeln Sie Krypto-zu-Krypto-Transaktionen als nicht steuerpflichtige Veräußerungen",
        tooltip: {
          nftToCryptoTaxable: () =>
            "Der Handel mit NFTs und Kryptowährungen bleibt weiterhin steuerpflichtige Veräußerung und Erwerb",
          off: () =>
            "Krypto-zu-Krypto-Handel werden derzeit als steuerpflichtige Veräußerungen und Erwerbe behandelt",
          on: () =>
            "Krypto-zu-Krypto-Transaktionen werden derzeit als Übertragung der Kostenbasis behandelt",
          onWithDate: (d) =>
            "Krypto-zu-Krypto-Transaktionen ab " +
            d.date +
            " werden als Übertragungen der Kostenbasis behandelt",
        },
      },
      emailReports: {
        options: {
          off: {
            description: () =>
              "Beim Herunterladen von Berichten werden wir diese niemals per E-Mail versenden.",
            title: () => "Senden Sie Berichte niemals per E-Mail",
          },
          on: {
            description: () =>
              "Beim Herunterladen von Berichten senden wir Ihnen auch einen Link zum Herunterladen des Berichts. Dieser Link ist 1 Tag lang gültig.",
            title: () => "Senden Sie Berichte immer per E-Mail",
          },
        },
        title: () => "E-Mail-Berichte",
        wentWrong: () =>
          "Beim Aktualisieren Ihrer E-Mail-Berichtseinstellungen ist ein Fehler aufgetreten.",
      },
      holdingsBalanceType: {
        title: () => "Gemeldete Salden im Portfolio verwenden",
        tooltip: {
          off: () =>
            "Verwenden Sie den berechneten Saldo, der durch den Tax Engine-Algorithmus ermittelt wurde",
          on: () =>
            "Verwenden Sie den gemeldeten Saldo, der durch die Integration",
        },
      },
      ignoreBalanceRemainingValueThreshold: {
        subtext: () =>
          "Wir ignorieren Restguthaben unterhalb eines bestimmten Fiat-Schwellenwerts aufgrund von Rundungsproblemen bei Dezimalstellen. Dies wird normalerweise durch aufgerundete Börsentransaktionsdaten verursacht. Sie können diesen Schwellenwert unten anpassen.",
        title: () => "Anpassen der Balancegenauigkeit",
      },
      ignoreFiat: {
        title: () => "Gewinne aus Devisenumrechnungen ignorieren",
        tooltip: {
          off: () =>
            "Gewinne aus Devisenkonvertierungen werden derzeit berücksichtigt",
          on: () =>
            "Gewinne aus Devisenkonvertierungen werden derzeit ignoriert",
        },
      },
      ignoreFiatLabel: () => "Fiat ignorieren",
      irs2025Warning: {
        chip: () => "Über die bevorstehenden Regeländerungen des IRS",
        content: {
          body1: () =>
            "Keine Sorge – Ihre Steuererklärung für das Geschäftsjahr 2024 ist von diesen Änderungen nicht betroffen und Sie können weiterhin die universelle Bestandsaufnahmemethode verwenden, wenn Sie das bevorzugen.",
          body2: () =>
            "Diese Änderungen gelten nur für Kryptotransaktionen ab dem 1. Januar 2025. Sie müssen die Pro-Wallet-Methode für Ihre Steuererklärung für das Geschäftsjahr 2025 verwenden, die Sie im Jahr 2026 einreichen.",
          header1: () =>
            "Was bedeutet dies für Ihre Steuererklärung für das Geschäftsjahr 2024?",
          header2: () =>
            "Wann müssen Sie auf die Verfolgung pro Wallet umstellen?",
          summary: () =>
            "Ab dem 1. Januar 2025 verlangt das IRS die Verwendung einer Wallet-Methode (auch bekannt als „Spezifische ID nach Wallet und Börse“) zur Nachverfolgung Ihrer Krypto-Kostenbasis.",
        },
        learnMore: () => "Mehr erfahren",
      },
      lostStolenAsNonTaxable: {
        title: () =>
          "Behandeln Sie verlorene oder gestohlene Transaktionen als nicht steuerpflichtige Veräußerungen",
        tooltip: {
          off: () =>
            "Verlorene oder gestohlene Transaktionen werden derzeit als steuerpflichtige Veräußerungen behandelt",
          on: () =>
            "Verlorene oder gestohlene Transaktionen werden derzeit als nicht steuerpflichtige Veräußerungen behandelt",
        },
      },
      miningAsIncome: {
        title: () => "Behandeln Sie das Mining als Einkommen",
        tooltip: {
          off: () =>
            "Mining Transaktionen werden derzeit als Akquisitionen auf Nullkostenbasis behandelt",
          on: () =>
            "Mining-Transaktionen werden derzeit als Einkommen behandelt",
        },
      },
      outgoingGiftAsNonTaxable: {
        title: () =>
          "Ausgehende Schenkung als steuerfreie Veräußerung behandeln",
        tooltip: {
          off: () =>
            "Ausgehende Schenkungstransaktionen werden derzeit als steuerpflichtige Veräußerungen behandelt",
          on: () =>
            "Ausgehende Schenkungstransaktionen werden derzeit als nicht steuerpflichtige Veräußerungen behandelt",
        },
      },
      personalUseAsNonTaxable: {
        title: () =>
          "Den persönlichen Gebrauch als steuerfreie Veräußerung behandeln",
        tooltip: {
          off: () =>
            "Transaktionen zur persönlichen Nutzung werden derzeit als steuerpflichtige Veräußerungen behandelt",
          on: () =>
            "Transaktionen für den persönlichen Gebrauch werden derzeit als nicht steuerpflichtige Veräußerungen behandelt.",
        },
      },
      report: () => "Bericht",
      royaltiesAsIncome: {
        title: () => "Lizenzgebühren als Einkommen behandeln",
        tooltip: {
          off: () =>
            "Lizenztransaktionen werden derzeit als Akquisitionen auf Nullkostenbasis behandelt",
          on: () =>
            "Lizenzgebührentransaktionen werden derzeit als Einkommen behandelt",
        },
      },
      showAllAccountsOnPortfolio: {
        title: () => "Alle Konten im Portfolio anzeigen",
        tooltip: {
          off: () => "Nur importierte Konten werden im Portfolio angezeigt",
          on: () =>
            "Wenn diese Option deaktiviert ist, werden im Portfolio nur importierte Konten angezeigt. Wenn sie aktiviert ist, umfassen Ihre Portfoliobestände auch verdächtige Konten, also Wallets und Börsen, mit denen interagiert wurde, die aber nicht importiert wurden. Hinweis: Diese Option wirkt sich nur auf die Portfolioansicht aus, nicht auf Steuerberechnungen.",
        },
      },
      showFiatHoldings: {
        title: () =>
          "Zeigen Sie native Fiat-Bestände in Berichten und auf dem Dashboard an",
        tooltip: {
          off: () =>
            "Fiat-Bestände werden derzeit nicht in Berichten und auf dem Dashboard angezeigt",
          on: () =>
            "Fiat-Bestände werden derzeit in Berichten und auf dem Dashboard angezeigt",
        },
      },
      specificInventoryByExchange: {
        disabledReason: () =>
          "Bei der aktuellen Bestandsaufnahmemethode nicht zulässig",
        options: {
          mixed: {
            description: () =>
              "Während dieses Zeitraums wird sowohl eine universelle als auch eine Kostenverfolgung nach Wallet- und Börsenbasis verwendet.",
            title: () => "Gemischte Einstellungen",
          },
          off: {
            description: () =>
              "Die Kostenbasis wird universell über alle Wallets und Börsen hinweg verfolgt.",
            title: () => "Universal",
          },
          on: {
            description: () =>
              "Die Kostenbasis wird von Wallet und Börse verfolgt. Das bedeutet, dass es für einen Vermögenswert mehrere Kostenbasen geben kann, basierend auf dem Kaufpreis, zu dem die Währung gekauft wurde.",
            title: () => "Per Wallet und Börse",
          },
        },
        title: () => "Kostenbasisverfolgung",
      },
      splitFeesEvenlyAcrossGroupedMints: {
        title: () =>
          "Gebühren gleichmäßig auf gruppierte Mint-Transaktionen aufteilen",
        tooltip: {
          off: () =>
            "Bei gruppierten Mint-Transaktionen werden die Gebühren derzeit nicht gleichmäßig auf alle geprägten Vermögenswerte aufgeteilt.",
          on: () =>
            "Bei gruppierten Mint-Transaktionen werden die Gebühren derzeit gleichmäßig auf alle geprägten Vermögenswerte aufgeteilt.",
        },
      },
      stakingAsIncome: {
        title: () => "Behandeln Sie Staking-Belohnungen als Einkommen",
        tooltip: {
          off: () =>
            "Staking-Belohnungen werden derzeit als Akquisitionen auf Nullkostenbasis behandelt.",
          on: () =>
            "Staking-Belohnungen werden derzeit als Einkommen behandelt",
        },
      },
      stakingDepositWithdrawalNonTaxable: {
        title: () =>
          "Behandeln Sie Staking-Einzahlungen und -Abhebungen als steuerfrei",
        tooltip: {
          off: () =>
            "Staking-Einzahlungen und -Abhebungen werden derzeit als steuerpflichtige Veräußerungen und Erwerbe behandelt.",
          on: () =>
            "Staking-Einzahlungen und -Abhebungen werden derzeit als Überweisungen behandelt",
        },
      },
      taxSettings: () => "Steuereinstellungen",
      title: () => "Berichtseinstellungen",
      treatLiquidityAddRemovalNonTaxable: {
        title: () =>
          "Behandeln Sie Liquiditätszusätze als nicht steuerpflichtig",
        tooltip: {
          off: () =>
            "Liquiditätstransaktionen werden derzeit als steuerpflichtige Veräußerungen und Erwerbe behandelt",
          on: () =>
            "Liquiditätstransaktionen werden derzeit als Übertragungen der Kostenbasis behandelt",
        },
      },
      uncategorisedInAsTaxable: {
        title: () =>
          "Nicht kategorisierte eingehende Transaktionen als Käufe behandeln",
        tooltip: {
          off: () =>
            "Nicht kategorisierte eingehende Transaktionen werden derzeit als nicht steuerpflichtige Überweisungen behandelt",
          on: () =>
            "Nicht kategorisierte eingehende Transaktionen werden derzeit als Käufe behandelt",
        },
      },
      uncategorisedOutAsTaxable: {
        title: () =>
          "Nicht kategorisierte ausgehende Transaktionen als Verkäufe behandeln",
        tooltip: {
          off: () =>
            "Nicht kategorisierte ausgehende Transaktionen werden derzeit als nicht steuerpflichtige Überweisungen behandelt",
          on: () =>
            "Nicht kategorisierte ausgehende Transaktionen werden derzeit als Verkäufe behandelt",
        },
      },
      updatedTaxSettingsError: () =>
        "Beim Aktualisieren Ihrer Steuereinstellungen ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
      updatedTaxSettingsSuccess: () =>
        "Steuereinstellungen erfolgreich aktualisiert",
    },
    reportActionButton: {
      action: () => "Berechnungen aktualisieren",
      loadingAction: () => "Erfrischend",
      loadingTooltip: () => "Dies kann einige Zeit dauern",
      tooltip: () =>
        "Manuelles Auslösen einer Aktualisierung aller Berechnungen",
    },
    reportSettings: () => "Berichtseinstellungen",
    sidebar: {
      loading: () => "Laden...",
      searchClients: () => "Kunden suchen",
    },
    somethingWentWrong: () =>
      "Etwas ist schiefgelaufen! Bitte wenden Sie sich an den Support",
    somethingWentWrongContact: () =>
      "Etwas ist schiefgelaufen! Bitte wenden Sie sich an den Support, wenn das Problem weiterhin besteht",
    supportAccess: {
      body: {
        accessGrantedAt: (d) => "Zugriff gewährt: " + d.accessGrantedAt,
        button: {
          grantAccess: () => "Zugriff gewähren",
          revokeAccess: () => "Zugriff widerrufen",
        },
        description: () =>
          "Erlauben Sie dem Support den Zugriff auf Ihre Kontodaten, um Probleme zu lösen. Der Zugriff wird für maximal 30 Tage gewährt.",
        title: () => "Supportzugriff gewähren",
      },
      title: () => "Unterstützung",
    },
    tabs: {
      account: () => "Konto",
      alpha: () => "Alpha",
      billing: () => "Abrechnung",
      general: () => "Steuer",
      integrations: () => "Konten",
      "integrations-deprecated": () => "Integrationen",
      loading: () => "Laden",
      "lock-period": () => "Sperrfristen",
      permissions: () => "Berechtigungen",
      plan: () => "Planen",
      portfolio: () => "Portfolio",
      team: () => "Team",
    },
    team: {
      header: {
        email: () => "E-Mail",
        lastLogin: () => "Letzte Anmeldung",
        name: () => "Name",
        search: () => "Suchen",
      },
      invite: () => "Einladen",
      inviteTeamMember: () => "Teammitglied einladen",
      inviteTooltip: (d) => "Eingeladen " + d.date,
      managementAndPermissions: () => "Management",
      noResults: () => "Keine Ergebnisse",
      noTeamMembers: () => "Keine Teammitglieder",
      owner: () => "Eigentümer",
    },
    threshold: () => "Schwelle",
    timezone: () => "Zeitzone",
    title: () => "Einstellungen",
  },
  similarTransactionsSuggestion: {
    accept: () => "Bestätigen",
    applyToFuture: () => "Auf zukünftige Transaktionen anwenden",
    categorizeItAs: () => "Kategorisieren als:",
    deny: () => "Leugnen",
    modal: {
      buttonCTA: () => "Kategorie wählen",
      receivedFrom: () => "Empfangen von:",
      sentTo: () => "Gesendet an:",
      similarTxs: () => "Ähnliche Transaktionen:",
    },
    noSimilar: () => "Keine ähnlichen Transaktionen gefunden",
    notNow: () => "Nicht jetzt",
    operators: {
      actionId: () => "Aktions-ID",
      after: () => "Nach",
      and: () => "Und",
      before: () => "Vor",
      blockchain: () => "Blockchain",
      category: () => "Kategorie",
      comment: () => "Kommentar",
      comments: () => "Kommentare",
      currency: () => "Währung",
      date: () => "Datum",
      description: () => "Beschreibung",
      feeCurrency: () => "Gebührenwährung",
      from: () => "Quelle",
      hasRule: () =>
        "Transaktionen ausblenden, die nicht von Regeln betroffen sind",
      id: () => "AUSWEIS",
      importId: () => "Import-ID",
      importType: () => "Importtyp",
      isSmartContractInteraction: () => "Interaktion",
      missingPrice: () => "Fehlender Preis",
      negativeBalance: () => "Fehlende Kaufhistorie",
      nftCollection: () => "NFT-Sammlung",
      not: () => "Nicht",
      or: () => "Oder",
      party: () => "Party",
      reconciliation: () => "Versöhnung",
      reviewed: () => "Überprüft",
      rule: () => "Regel",
      ruleOperator: () => "Regeloperator",
      source: () => "Konto",
      suggestion: () => "Anregung",
      sync: () => "Synchronisieren",
      to: () => "Ziel",
      trade: () => "Kategorie",
      txCurrency: () => "Tx-Währung",
      txFunctionName: () => "Transaktionssignatur",
      txFunctionSignature: () => "Transaktionsmethoden-ID",
      txId: () => "Transaktions-ID",
      warning: () => "Warnung",
    },
    optOut: {
      button: () => "Sicht",
      loadingSimilar: () => "Suche nach ähnlichen Transaktionen",
      noSimilar: () => "Keine ähnlichen Transaktionen",
      title: (d) =>
        "Wenden Sie diese Änderung auf [ " +
        plural(d.count, 0, de, {
          one: " 1 ähnliche Transaktion ",
          other: number("de", d.count, 0) + " ähnliche Transaktionen ",
        }) +
        " ]( " +
        d.linkTextKey +
        " ) an.",
      tooltip: {
        criteriaFour: () => "Haben die gleiche Transaktionssignatur",
        criteriaOne: () => "Sind in die gleiche Richtung",
        criteriaThree: () => "Dieselbe Währung haben",
        criteriaTwo: () => "Sind zu und von denselben Wallets",
        title: () => "Ähnliche Transaktionen sind solche, die:",
      },
    },
    similarTransactionsFound: (d) =>
      plural(d.count, 0, de, {
        one: " 1 ähnliche Transaktion ",
        other: number("de", d.count, 0) + " ähnliche Transaktionen ",
      }),
    successToast: (d) =>
      "Kategorisiert " +
      d.count +
      " ähnlich " +
      plural(d.count, 0, de, {
        one: " Transaktion ",
        other: " Transaktionen ",
      }),
    viewTransactions: () => "Transaktionen anzeigen",
    weHaveFound: (d) =>
      "Wir haben " +
      plural(d.count, 0, de, {
        one: " 1 ähnliche Transaktion ",
        other: number("de", d.count, 0) + " ähnliche Transaktionen ",
      }) +
      " gefunden. " +
      plural(d.count, 0, de, {
        one: " Diese Transaktion hat ",
        other: " Diese Transaktionen haben ",
      }) +
      " dasselbe:",
  },
  skip: () => "Überspringen",
  spam: () => "Als Spam markieren",
  start: () => "Start",
  support: {
    contact: () => "Bitte wenden Sie sich für Unterstützung an den Support",
    deleteAccountDisabled: () =>
      "Diese Funktion ist für den Buchhalterplan nicht verfügbar. Wenden Sie sich für Unterstützung an den Support.",
  },
  supportedLanguages: {
    de: () => "Deutsch",
    eng: () => "Englisch",
    es: () => "Spanisch",
    fr: () => "Französisch",
    it: () => "Italienisch",
    xx: () => "Xx Debug-Sprache",
    xxl: () => "Xx lang",
    xxs: () => "Xx kurz",
  },
  sync: {
    actionErpSyncFailed: (d) =>
      "Synchronisierung mit " + d.erpProvider + " fehlgeschlagen.",
    apiStillSyncing: () =>
      "Die API wird noch synchronisiert, bitte warten Sie einige Minuten.",
    integrationSyncing: (d) => d.name + " synchronisiert...",
    reportRefreshFailed: () => "Berechnungen konnten nicht aktualisiert werden",
    syncFailed: (d) =>
      "Die Synchronisierung für " +
      d.name +
      " ist fehlgeschlagen. Stellen Sie sicher, dass Sie die Importanweisungen korrekt befolgen. Wenn das Problem weiterhin besteht, wenden Sie sich an den Support.",
    syncOneHour: (d) =>
      "Synchronisierung mit " +
      d.name +
      " -API. Dieser Vorgang kann bis zu 1 Stunde dauern.",
    syncSeveralMinutes: (d) =>
      "Synchronisierung mit " +
      d.name +
      " -API. Dieser Vorgang kann mehrere Minuten dauern.",
    syncingAPIs: (d) => d.name + " APIs synchronisieren",
    syncingBlockchainWallets: (d) => d.name + " -Wallets synchronisieren",
    syncingName: (d) => d.name + " wird synchronisiert",
    uploadDeprecated: {
      coinbase: () =>
        "Sie verwenden einen veralteten Coinbase-API-Schlüssel. Bitte aktualisieren Sie Ihre API gemäß den neuesten Anweisungen.",
      generic: () =>
        "Sie verwenden einen veralteten API-Schlüssel. Bitte aktualisieren Sie Ihre API gemäß den neuesten Anweisungen.",
    },
    uploadFail: (d) =>
      "Beim Importieren " +
      d.name +
      " ist ein Fehler aufgetreten. Wenn dies weiterhin auftritt, wenden Sie sich bitte an den Support.",
    uploadingFiveMins: (d) =>
      d.name +
      " -Daten werden hochgeladen. Dies kann bis zu 5 Minuten dauern. Während dieser Zeit können Sie die App weiterhin nutzen.",
    xeroSyncFailed: () => "Xero-Synchronisierung fehlgeschlagen",
  },
  syncErrorReasons: {
    "api-changed": {
      ctaType: () =>
        "Wenden Sie sich für weitere Unterstützung an den Support.",
      message: () => "Die von der Börse empfangenen Daten haben sich geändert.",
    },
    "api-unavailable": {
      ctaType: () => "Bitte versuchen Sie es später erneut.",
      message: (d) =>
        "Der " + d.exchangeName + " -API-Server ist derzeit nicht verfügbar.",
    },
    "app-error": {
      ctaType: () =>
        "Wenden Sie sich an den Support, wenn das Problem weiterhin besteht.",
      message: () =>
        "In der Anwendung ist ein unerwarteter Fehler aufgetreten.",
    },
    "csv-invalid-column": {
      ctaType: () => "Überprüfen Sie die Datei und versuchen Sie es erneut.",
      message: () => "Die CSV-Datei enthält eine ungültige Spalte.",
    },
    "csv-not-required": {
      ctaType: () =>
        "Stellen Sie sicher, dass Sie die richtige Datei hochgeladen haben.",
      message: () => "Die CSV-Datei wird für diese Integration nicht benötigt.",
    },
    "csv-not-supported": {
      ctaType: () =>
        "Stellen Sie sicher, dass Sie die richtige Datei hochgeladen haben.",
      message: () =>
        "Die CSV-Datei wird für diese Integration nicht unterstützt.",
    },
    "expired-api-key": {
      ctaType: () =>
        "Generieren Sie einen neuen Schlüssel, indem Sie den Integrationsanweisungen Schritt für Schritt folgen.",
      message: () => "Abgelaufener API-Schlüssel.",
    },
    "invalid-api-key": {
      ctaType: () =>
        "Überprüfen Sie die Anweisungen und versuchen Sie es erneut.",
      message: () => "Ungültiger API-Schlüssel.",
    },
    "invalid-csv": {
      ctaType: () => "Überprüfen Sie die Datei und den Inhalt.",
      message: () => "Ungültiges CSV-Format.",
    },
    "invalid-csv-headers": {
      ctaType: () => "Überprüfen Sie das Format und versuchen Sie es erneut.",
      message: () => "Ungültige Spaltennamen.",
    },
    "invalid-permissions": {
      ctaType: () =>
        "Überprüfen Sie, ob IP- oder Zugriffsbeschränkungen richtig konfiguriert sind.",
      message: () => "Fehlende API-Berechtigungen.",
    },
    "invalid-user-defined-field": {
      ctaType: () => "Überprüfen Sie Ihre Eingaben oder Konfiguration.",
      message: () => "Die Anfrage enthält ungültige Felder.",
    },
    "invalid-wallet-address": {
      ctaType: () =>
        "Überprüfen Sie die Anweisungen und versuchen Sie es erneut.",
      message: () => "Ungültige Wallet-Adresse.",
    },
    "no-transactions": {
      ctaType: () => "Überprüfen Sie die Datenquelle oder Eingaben.",
      message: () => "Es wurden keine Transaktionen gefunden.",
    },
    "rate-limit-failed": {
      ctaType: () => "Versuchen Sie es in 5 Minuten erneut.",
      message: () => "Ratenlimit erreicht.",
    },
    timeout: {
      ctaType: () => "Bitte versuchen Sie es später erneut.",
      message: () =>
        "Bei der Anforderung ist eine Zeitüberschreitung aufgetreten. ",
    },
    "too-many-transactions": {
      ctaType: () =>
        "Wenden Sie sich für weitere Unterstützung an den Support.",
      message: () => "Transaktionslimit überschritten",
    },
    "unknown-error": {
      ctaType: () =>
        "Wenden Sie sich für weitere Unterstützung an den Support.",
      message: () => "Ein unbekannter Fehler ist aufgetreten.",
    },
    "unsupported-data-format": {
      ctaType: () =>
        "Wenden Sie sich für weitere Unterstützung an den Support.",
      message: () =>
        "Das von der API empfangene Datenformat wird nicht unterstützt.",
    },
  },
  syncHistory: {
    confirm: {
      text: () =>
        "Möchten Sie wirklich alle Daten aus dieser Synchronisierung entfernen? Dadurch werden alle mit dieser Synchronisierung verknüpften Transaktionen gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden.",
      title: () => "Importsynchronisierung löschen",
      warning: (d) => d.name + " am " + d.date,
    },
    date: () => "Datum",
    delete: () => "Löschen",
    hardSync: () => "Harte Synchronisierung",
    id: () => "AUSWEIS",
    initialSync: () => "Erstsynchronisierung",
    name: () => "Name",
    noneFound: () => "Kein erfolgreicher Synchronisierungsverlauf gefunden.",
    title: () => "Synchronisierungsverlauf",
    transactions: () => "Transaktionen",
    transactionsHeader: () => "Transaktionen",
    viewTx: () => "Transaktionen anzeigen",
  },
  syncStatus: {
    failed: () => "Update fehlgeschlagen",
    reconciliation: () => "Abgleichen von Transaktionen",
    retry: () => "Berechnungen aktualisieren",
    updated: () => "Aktualisiert",
    updating: () => "Update in Bearbeitung",
  },
  tabNav: {
    connectedAccounts: () => "Verbundene Konten",
    more: () => "Mehr",
    nfts: () => "NFTs",
    plan: () => "Planen",
    report: () => "Bericht",
    review: () => "Rezension",
    settings: () => "Einstellungen",
    summary: () => "Zusammenfassung",
    transactions: () => "Transaktionen",
  },
  tag: {
    createNew: () => "Identität schaffen",
    createNewIdentity: () => "Neue Identität erstellen",
    deleteMessage: (d) =>
      d.address + " wird nicht mehr als " + d.entityName + " identifiziert",
    groups: {
      Contract: () => "Verträge",
      Exchange: () => "Börsen",
      Extension: () => "Börsen",
      Manual: () => "Brauch",
    },
    identified: (d) => d.count + " identifiziert",
    identify: () => "Identifizieren",
    identifyAddress: () => "Adresse identifizieren",
    identifyAddressModal: {
      description: () =>
        "Dadurch wird die Adresse bei allen Transaktionen identifiziert.",
      doneButton: () => "Erledigt",
      title: () => "Adresse ermitteln",
    },
    identifyDescription: () =>
      "Dadurch wird die Adresse bei allen Transaktionen identifiziert.",
    manageAddressModal: {
      description: (d) =>
        "Diese Adresse wird als " + d.entityDisplayName + " identifiziert.",
      descriptionContract: (d) =>
        "Dieser Vertrag wird als " + d.entityDisplayName + " identifiziert.",
      doneButton: () => "Erledigt",
      title: () => "Adresse verwalten",
    },
    refAddressModal: {
      description: (d) =>
        "Alle Adressen, die als " +
        d.entityDisplayName +
        " identifiziert sind.",
      doneButton: () => "Erledigt",
      title: (d) => d.entityDisplayName + " verwalten",
    },
    startTypingName: () => "Beginnen Sie mit der Eingabe eines Namens",
    thisAddress: () => "Diese Adresse",
  },
  taxAuthorityByCountry: {
    AD: () => "DT",
    AE: () => "FTA",
    AF: () => "ARCA",
    AG: () => "IRD",
    AI: () => "IRD",
    AL: () => "GDT",
    AM: () => "SRC",
    AO: () => "AGT",
    AR: () => "AFIP",
    AS: () => "ASG-T",
    AT: () => "BMF",
    AU: () => "ATO",
    AW: () => "DIMP",
    AZ: () => "STM",
    BA: () => "ITA",
    BB: () => "BRA",
    BD: () => "NBR",
    BE: () => "FPSF",
    BF: () => "DGI",
    BG: () => "NRA",
    BH: () => "NBR",
    BI: () => "OBR",
    BJ: () => "DGI",
    BM: () => "OTC",
    BN: () => "MOFE",
    BO: () => "SIN",
    BQ: () => "BCN",
    BR: () => "RFB",
    BS: () => "IRD",
    BT: () => "DRC",
    BV: () => "BVT",
    BW: () => "BURS",
    BY: () => "MTC",
    BZ: () => "IRD",
    CA: () => "CRA",
    CF: () => "DGID",
    CG: () => "DGI",
    CH: () => "ESTV",
    CI: () => "DGI",
    CL: () => "SII",
    CM: () => "DGT",
    CN: () => "STA",
    CO: () => "DIAN",
    CR: () => "DGT",
    CU: () => "ONAT",
    CV: () => "DNRE",
    CW: () => "NOB",
    CY: () => "TAD",
    CZ: () => "FSAD",
    DE: () => "BZSt",
    DJ: () => "DGI",
    DK: () => "SKAT",
    DM: () => "IRD",
    DO: () => "DGII",
    DZ: () => "DGI",
    EC: () => "SRI",
    EE: () => "ETCB",
    EG: () => "ETA",
    ER: () => "IRD",
    ES: () => "AEAT",
    ET: () => "ERCA",
    FI: () => "FTA",
    FJ: () => "FRCS",
    FK: () => "FIG",
    FM: () => "DoF",
    FO: () => "TAKS",
    FR: () => "DGFIP",
    GA: () => "DGI",
    GB: () => "HMRC",
    GD: () => "IRD",
    GE: () => "GRS",
    GF: () => "DGFIP",
    GH: () => "GRA",
    GI: () => "ITO",
    GL: () => "TSN",
    GM: () => "GRA",
    GN: () => "DNI",
    GP: () => "DGFIP",
    GQ: () => "DGI",
    GR: () => "IAPR",
    GT: () => "SAT",
    GU: () => "DRT",
    GW: () => "DGI",
    GY: () => "GRA",
    HK: () => "IRD",
    HN: () => "SAR",
    HR: () => "TA",
    HT: () => "DGI",
    HU: () => "NTCA",
    ID: () => "DGT",
    IE: () => "TRC",
    IL: () => "ITA",
    IM: () => "ITD",
    IN: () => "ITR",
    IR: () => "INTA",
    IS: () => "RSK",
    IT: () => "ADE",
    JE: () => "RT",
    JM: () => "TAJ",
    JO: () => "ISTD",
    JP: () => "NTA",
    KE: () => "KRA",
    KG: () => "STS",
    KH: () => "GDT",
    KI: () => "MFED",
    KM: () => "AGID",
    KN: () => "IRD",
    KP: () => "MoF",
    KR: () => "NTS",
    KW: () => "MT",
    KY: () => "DCI",
    KZ: () => "SRC",
    LA: () => "TD",
    LB: () => "MoF",
    LC: () => "IRD",
    LI: () => "STV",
    LK: () => "IRD",
    LR: () => "LRA",
    LS: () => "LRA",
    LT: () => "STI",
    LU: () => "ACD",
    LV: () => "SRS",
    LY: () => "TD",
    MA: () => "DGI",
    MC: () => "DSF",
    MD: () => "STS",
    ME: () => "TA",
    MF: () => "DGFIP",
    MG: () => "DGI",
    MH: () => "MIRC",
    MK: () => "PRO",
    ML: () => "DGI",
    MM: () => "IRD",
    MN: () => "MTA",
    MO: () => "DSF",
    MP: () => "DRT",
    MQ: () => "DGFIP",
    MR: () => "DGI",
    MS: () => "IRD",
    MT: () => "CfR",
    MU: () => "MRA",
    MV: () => "MIRA",
    MW: () => "MRA",
    MX: () => "SAT",
    MY: () => "LHDN",
    MZ: () => "AT",
    NA: () => "IR",
    NC: () => "DSF",
    NE: () => "DGI",
    NG: () => "FIRS",
    NI: () => "DGI",
    NL: () => "DTCA",
    NO: () => "NTA",
    NP: () => "IRD",
    NR: () => "DoF",
    NU: () => "TR",
    NZ: () => "IRD",
    OM: () => "TR",
    PA: () => "DGI",
    PE: () => "SUNAT",
    PF: () => "DICP",
    PG: () => "IRC",
    PH: () => "BIR",
    PK: () => "FBR",
    PL: () => "KAS",
    PM: () => "DGFIP",
    PR: () => "DH",
    PS: () => "ITA",
    PT: () => "PTCA",
    PW: () => "BRT",
    PY: () => "SET",
    QA: () => "GTA",
    RE: () => "DGFIP",
    RO: () => "ANAF",
    RS: () => "PU",
    RU: () => "FTS",
    RW: () => "RRA",
    SA: () => "GAZT",
    SB: () => "IRD",
    SC: () => "SRC",
    SD: () => "SRTD",
    SE: () => "STA",
    SG: () => "IRAS",
    SI: () => "FURS",
    SK: () => "FR",
    SL: () => "NRA",
    SM: () => "SMR",
    SN: () => "DGID",
    SO: () => "MoF",
    SR: () => "DB",
    SS: () => "NRA",
    SV: () => "DGII",
    SX: () => "TD",
    SY: () => "MoF",
    SZ: () => "SRA",
    TC: () => "RD",
    TD: () => "DGI",
    TG: () => "OTR",
    TH: () => "RD",
    TJ: () => "TC",
    TL: () => "DNRT",
    TM: () => "MTT",
    TN: () => "DGI",
    TO: () => "IRD",
    TR: () => "GIB",
    TT: () => "BIR",
    TV: () => "IRD",
    TW: () => "NTBT",
    TZ: () => "TRA",
    UA: () => "STS",
    UG: () => "URA",
    UK: () => "HMRC",
    US: () => "IRS",
    UY: () => "DGI",
    UZ: () => "STC",
    VA: () => "N/A",
    VC: () => "IRD",
    VE: () => "SENIAT",
    VG: () => "IRD",
    VI: () => "IRB",
    VN: () => "GDT",
    VU: () => "DCIR",
    WF: () => "DGFIP",
    WS: () => "MfR",
    YE: () => "TY",
    YT: () => "DGFIP",
    ZA: () => "SARS",
    ZM: () => "ZRA",
    ZW: () => "ZIMRA",
  },
  taxDivision: {
    buy: () => "Kaufen",
    disposal: () => "Veräußerung",
    group: () => "Gruppen",
    hidden: () => "Versteckt",
    ignore: () => "Ignorieren",
    income: () => "Einkommen",
    lastUsed: () => "Zuletzt ausgewählt",
    payment: () => "Zahlung",
    transfer: () => "Überweisen",
    withdrawal: () => "Rückzug",
  },
  taxLossHarvesting: {
    errors: {
      unableToLoad: () =>
        "Das Modal „Loss Harvesting“ konnte nicht geladen werden",
    },
    gain: () => "Belohnungen",
    iconTooltips: {
      editPrice: () => "Preis bearbeiten",
      refreshPrice: () => "Marktpreise aktualisieren",
      resetPrice: () => "Preis auf den aktuellen Marktpreis zurücksetzen",
      savePrice: () => "Preis sparen",
    },
    open: () => "Ernteverluste",
    openWhenNoLosses: () => "Erntewerkzeug anzeigen",
    paywallModal: {
      aboutLTFO: () =>
        "Über unsere steuerlich effektivste Inventarisierungsmethode",
      aboutTaxLossHarvesting: () => "Über Tax Loss Harvesting",
      benefits: {
        lockIn: () =>
          "Potenzielle Sicherung von Kapitalverlusten zur Übertragung auf künftige Jahre",
        lockInNoCGT: () =>
          "Potenzielle Sicherung nicht realisierter Verluste zur Übertragung auf zukünftige Jahre",
        offset: () =>
          "Potenziell alle Kapitalgewinne außerhalb von Krypto mit Krypto-Kapitalverlusten ausgleichen",
        offsetNoCGT: () =>
          "Potenziell alle realisierten Gewinne außerhalb von Krypto mit realisierten Krypto-Verlusten ausgleichen",
        optimize: () =>
          "Optimieren Sie Ihre Steuern mit der steuerlich effektivsten Inventarmethode",
      },
      subtitlePost: () => "zu Verlusten.",
      subtitlePre: () => "Sie könnten ernten",
      title: () => "Reduzieren Sie möglicherweise Ihre Steuerlast.",
      upgradePrompt: () =>
        "Upgrade auf den Investor-Plan oder höher, um die Verlusternte freizuschalten",
    },
    postHarvestCapitalGains: () => "Kapitalgewinne nach der Ernte",
    postHarvestCapitalGainsNoCGT: () => "Nacherntegewinne",
    realisedCapitalGains: () => "Realisierte Kapitalgewinne",
    realisedCapitalGainsNoCGT: () => "Realisierte Gewinne",
    showAssetsWithoutMarketPrice: () =>
      "Vermögenswerte ohne Marktpreis anzeigen",
    summary: {
      netCapitalGainLoss: () => "Nettokapitalgewinn/-verlust",
      netCapitalGainLossNoCGT: () => "Realisierter Nettogewinn/-verlust",
    },
    table: {
      loading: () => "Laden...",
      noHoldings: () => "Keine Bestände anzuzeigen",
      rowSelectionDisabled: () =>
        "Sie können nur Währungen mit nicht realisierten Verlusten auswählen.",
      title: () => "Möglichkeiten zur Steuerersparnis",
    },
    tableHeaders: {
      amountHeld: () => "Einbehaltener Betrag",
      amountToSell: () => "Zu verkaufender Betrag",
      asset: () => "Vermögenswert",
      costBasis: () => "Kostenbasis",
      currentMarketValue: () => "Marktwert",
      price: () => "Preis",
      unrealizedGainsLosses: () => "Nicht realisierte Gewinne/Verluste",
    },
    tableSearch: () => "Asset suchen",
    taxSavingOpportunities: () => "Möglichkeiten zur Steuerersparnis",
    title: () => "Steuerverlusternte",
    tooltips: {
      unrealizedGains: () =>
        "Die Differenz zwischen den Anschaffungskosten eines Vermögenswerts und seinem aktuellen Marktwert",
    },
    viewTaxLosses: () => "Steuerliche Verluste anzeigen",
  },
  taxRule: {
    "bed-and-breakfast": () => "BnB-Regel",
    "fee-forwarded": () => "Weitergeleitete Gebühr",
    "margin-fee": () => "Margin-Gebühr",
    "recalculated-after-bed-and-breakfast": () => "BnB Neuberechnung",
    "same-day": () => "Regel für denselben Tag",
    "superficial-loss-from-buy-back": () => "Oberflächlicher Rückkauf",
    "superficial-loss-from-sell": () => "Oberflächlicher Verkauf",
    "zero-cost-buy": () => "Fehlendes Gleichgewicht",
  },
  taxSettingBanner: {
    affectCalculationsWarning: () =>
      "Bitte beachten Sie, dass diese Umschaltungen Ihre Berechnungen für vergangene Steuerjahre beeinflussen können.",
    cta: () => "Schauen Sie es sich an",
    title: () => "✨ Neue Automatisierung verfügbar!",
  },
  taxToggles: () => "Steuerumschalter",
  testKey: () =>
    "Dies wird JETZT nicht übersetzen fügen Sie zusätzlichen übersetzten Text hinzu",
  theCounterParty: () => "die Gegenpartei",
  theme: {
    betaModal: {
      actionText: () => "Wechseln Sie in den Dunkelmodus",
      content1: () =>
        "Wir arbeiten noch an der Beseitigung einiger Probleme mit den Farben im Dunkelmodus. Einige Komponenten werden möglicherweise nicht richtig angezeigt.",
      content2: () =>
        "Auf Ihre Berechnungen und Daten hat dies keinen Einfluss.",
      title: () => "Der Dunkelmodus befindet sich in der Betaphase",
    },
    short: {
      dark: () => "Dunkel",
      light: () => "Hell",
      system: () => "System",
    },
    toggle: {
      dark: () => "Dunkler Modus",
      light: () => "Lichtmodus",
      system: () => "Systemstandard",
    },
  },
  title: () => "Krypto-Steuerrechner",
  to: () => "Zu",
  tokenLogin: {
    errorSubtitle: () =>
      "Bitte überprüfen Sie Ihren Anmeldelink. Alternativ können Sie versuchen, sich mit Ihrer E-Mail-Adresse und Ihrem Passwort anzumelden.",
    errorTitle: () => "Sie können sich nicht anmelden",
    subtitle: () =>
      "Bitte warten Sie, während wir Sie bei Ihrem Konto anmelden",
    title: () => "Anmeldung bei Ihrem Konto",
    visitLogin: () => "Zur Login-Seite",
  },
  topBanner: {
    coupon: () => "BLACK_FRIDAY_2020",
    msg: () =>
      "Black Friday • 60 % Rabatt auf das erste Jahresabonnement • CODE: ",
  },
  tradeType: {
    addLiquidity: () => "Liquidität hinzufügen",
    airdrop: () => "Airdrop",
    approval: () => "Genehmigung",
    "arbitrary-uncategorised": () => "Kategorisieren",
    borrow: () => "Darlehen",
    bridge: () => "Bridge",
    "bridge-trade": () => "Chross-Chain-Transaktion",
    "bridge-transfer": () => "Bridge",
    bridgeIn: () => "Bridge rein",
    bridgeOut: () => "Bridge raus",
    bridgeTradeIn: () => "Cross Chain Kaufen",
    bridgeTradeOut: () => "Cross-Chain-Verkauf",
    bridgeTransferIn: () => "Überbrückungstransfer rein",
    bridgeTransferOut: () => "Überbrückungstransfer raus",
    burn: () => "Brun",
    buy: () => "Kaufen",
    cashback: () => "Rabatt",
    chainSplit: () => "Chain Split",
    collateral: () => "Kaution",
    collateralWithdrawal: () => "Abhebung von Sicherheiten",
    "decrease-position": () => "Position verringern",
    decreasePositionDeposit: () => "Position verringern",
    deposit: () => "Erhalten",
    derivatives: () => "Handels-Gewinn- und Verlustrechnung",
    expense: () => "Kosten",
    failed: () => "Fehlgeschlagen",
    failedIn: () => "Fehlgeschlagen rein",
    failedOut: () => "Fehlgeschlagen raus",
    fee: () => "Gebühr",
    fiat: () => "Fiat",
    "fiat-transfer": () => "Fiat-Überweisung",
    fiatDeposit: () => "Fiat-Einzahlung",
    fiatWithdrawal: () => "Fiat-Abhebung",
    gift: () => "Geschenk",
    gifts: () => "Geschenke",
    ignoreIn: () => "Ignorieren rein",
    ignoreOut: () => "Ignorieren raus",
    ignored: () => "Ignoriert",
    in: () => "Eingehend",
    income: () => "Einkommen",
    "increase-position": () => "Position erhöhen",
    increasePositionWithdrawal: () => "Position erhöhen",
    interest: () => "Interesse",
    liquidate: () => "Liquidation",
    liquidity: () => "Liquidität",
    loan: () => "Kredite",
    loanRepayment: () => "Kreditrückzahlung",
    long: () => "Lang",
    lost: () => "Verloren",
    marginFee: () => "Margin-Gebühr",
    mining: () => "Mining",
    mint: () => "Mint",
    "multi-mint": () => "Mint",
    "multi-trade": () => "Handel",
    notAvailable: () => "Kostenloser Kauf",
    out: () => "Ausgehend",
    outgoingGift: () => "Ausgehendes Geschenk",
    personalUse: () => "Persönliche Nutzung",
    "provide-liquidity-trade": () => "Liquidität hinzufügen",
    realisedLoss: () => "Realisierter Verlust",
    realisedProfit: () => "Realisierter Gewinn",
    receivePQ: () => "Positionstoken erhalten",
    receivingLiquidityProviderToken: () => "Empfangsbestätigungstoken erhalten",
    "remove-liquidity-trade": () => "Liquidität entfernen",
    removeLiquidity: () => "Liquidität entfernen",
    returningLiquidityProviderToken: () => "Empfangstoken senden",
    reward: () => "Belohnen",
    royalty: () => "Lizenzgebühren",
    sell: () => "Verkaufen",
    sendPQ: () => "Positions-Token senden",
    short: () => "Kurz",
    spamIn: () => "Spam",
    "stake-deposit-group": () => "Staking-Einzahlung",
    "stake-withdrawal-group": () => "Auszahlung des Stakings",
    staking: () => "Staking-Belohnung",
    stakingDeposit: () => "Staking-Einzahlung",
    stakingWithdrawal: () => "Auszahlung des Stakings",
    stakings: () => "Abstecken",
    stolen: () => "Gestohlen",
    swap: () => "Tauschen",
    swapIn: () => "Eintauschen",
    swapOut: () => "Auswechseln",
    swaps: () => "Tauschgeschäfte",
    trade: () => "Handel",
    transfer: () => "Überweisen",
    uncategorised: () => "Unkategorisiert",
    unknown: () => "Unbekannt",
    withdrawal: () => "Schicken",
  },
  tradeTypeCombined: {
    borrow_loanRepayment: () => "Kredit & Kreditrückzahlung",
    bridgeIn_bridgeOut: () => "Bridge rein und raus",
    bridgeTradeIn_bridgeTradeOut: () => "Cross Chain Kaufen & Verkaufen",
    bridgeTransferIn_bridgeTransferOut: () => "Bridge rein und raus",
    buy_sell: () => "Kaufen & Verkaufen",
    collateralWithdrawal_collateral: () =>
      "Einzahlung und Auszahlung von Sicherheiten",
    failedIn_failedOut: () => "Ein- und Ausstieg fehlgeschlagen",
    fiatDeposit_fiatWithdrawal: () => "Fiat-Einzahlung und -Abhebung",
    ignoreIn_ignoreOut: () => "Ein- und Ausgänge ignorieren",
    receive_send: () => "Empfangen & Senden",
    receivingLiquidityProviderToken_stakingDeposit: () =>
      "Staking-Einzahlung und Erhalt von Quittungstoken",
    removeLiquidity_addLiquidity: () => "Liquidität entfernen und hinzufügen",
    returningLiquidityProviderToken_stakingWithdrawal: () =>
      "Staking-Auszahlung und Senden eines Quittungstokens",
    stakingWithdrawal_stakingDeposit: () => "Staking-Auszahlung und Einzahlung",
  },
  tradeTypeGroup: {
    acquisition: {
      description: () =>
        "Sie sind Eigentümer eines Krypto-Vermögenswerts geworden und haben damit die Kostenbasis für den Vermögenswert festgelegt.",
      label: () => "Erwerb",
    },
    derivatives: {
      description: () =>
        "Sie haben durch den Handel mit Derivaten, z. B. Futures/Margin, einen Gewinn erzielt.",
      label: () => "Derivate",
    },
    disposal: {
      description: () =>
        "Sie sind nicht länger Eigentümer eines Krypto-Vermögenswerts, wodurch ein CGT-Ereignis ausgelöst wird.",
      label: () => "Veräußerung",
    },
    expenses: {
      description: () =>
        "Sie haben einen Vermögenswert zur Begleichung einer Ausgabe verwendet.",
      label: () => "Kosten",
    },
    "fiat-transfer": {
      description: () =>
        "Fiat wurde übertragen, das Eigentum blieb jedoch bestehen.",
      label: () => "Fiat-Überweisung",
    },
    ignore: {
      description: () =>
        "Transaktion bei Steuer- und Saldenberechnungen ignorieren.",
      label: () => "Ignorieren",
    },
    income: {
      description: () =>
        "Sie haben Einnahmen in Kryptowährungen erhalten. Sie können auch Provisionen usw. in diese Kategorie einordnen.",
      label: () => "Einkommen",
    },
    loss: {
      description: () =>
        "Sie haben Kryptowährung verloren und möchten die Kostenbasis als Steuerabzug geltend machen.",
      label: () => "Verlust",
    },
    transfer: {
      description: () =>
        "Kryptowährungen wurden übertragen, das Eigentum blieb jedoch erhalten.",
      label: () => "Überweisen",
    },
  },
  tradeTypeTaxDescription: {
    addLiquidity: () =>
      "Sie haben diese Münzen zu einem Liquiditätspool hinzugefügt",
    airdrop: () =>
      "Du hast im Rahmen einer Aktion oder Ähnlichem „kostenlose“ Token erhalten",
    approval: () => "Sie haben der Verwendung eines Smart Contracts zugestimmt",
    "arbitrary-uncategorised": () => "Kategorisieren",
    borrow: () =>
      "Sie haben Krypto oder Fiat als Ergebnis der Bereitstellung von Sicherheiten erhalten",
    "bridge-trade": () =>
      "Sie haben einen Cross-Chain-Swap abgeschlossen, bei dem eine Währung über verschiedene Blockchains hinweg gegen eine andere getauscht wurde.",
    "bridge-transfer": () => "Automatisch gruppierte Bridge ein/aus",
    bridgeIn: () =>
      "Wird verwendet, um die Kostenbasis von einer Blockchain auf eine andere zu übertragen. Hinweis: Muss mit einer anderen Transaktion übereinstimmen, die als „Bridge Out“ kategorisiert ist.",
    bridgeOut: () =>
      "Wird verwendet, um die Kostenbasis von einer Blockchain auf eine andere zu übertragen. Hinweis: Muss mit einer anderen Transaktion übereinstimmen, die als „Bridge In“ kategorisiert ist.",
    bridgeTradeIn: () =>
      "Sie haben Kryptowährungen auf einer Blockchain gehandelt und sie über eine Bridge an eine andere Blockchain gesendet.",
    bridgeTradeOut: () =>
      "Sie haben Kryptowährung auf einer Blockchain aus einem Handel erhalten, der auf einer anderen Blockchain initiiert wurde.",
    bridgeTransferIn: () =>
      "Wird verwendet, um die Kostenbasis von einer Blockchain auf eine andere zu übertragen. Hinweis: Muss mit einer anderen Transaktion übereinstimmen, die als „Bridge Out“ kategorisiert ist.",
    bridgeTransferOut: () =>
      "Wird verwendet, um die Kostenbasis von einer Blockchain auf eine andere zu übertragen. Hinweis: Muss mit einer anderen Transaktion übereinstimmen, die als „Bridge In“ kategorisiert ist.",
    burn: () => "Sie haben Ihre Bestände an eine Wegwerfadresse gesendet.",
    buy: () => "Kein steuerpflichtiger Vorgang",
    cashback: () =>
      "Sie haben eine Rückerstattung für eine Transaktion erhalten, beispielsweise eine Gebührenminderung oder einen Cashback für eine Kreditkartenzahlung usw.",
    chainSplit: () =>
      "Sie haben Kryptowährungen als Ergebnis eines Hard Forks erhalten",
    collateral: () =>
      "Sie haben diese Münzen als Sicherheit für einen Kredit beiseite gelegt. Dies wirkt wie eine Abhebung von Ihrem Konto",
    collateralWithdrawal: () =>
      "Sie haben diese Münzen von einer Kreditplattform abgehoben. Dies wirkt ähnlich wie eine Einzahlung auf Ihr Konto",
    "decrease-position": () =>
      "Wird verwendet, um eine bestehende Position in einem Protokoll zu schließen oder zu reduzieren. Wird für Derivate oder Staking verwendet.",
    decreasePositionDeposit: () =>
      "Sie haben eine Position geschlossen oder reduziert und Erträge auf Ihr Konto erhalten.",
    deposit: () => "Kein steuerpflichtiger Vorgang",
    expense: () =>
      "Dies wirkt ähnlich wie ein Verkauf, Sie möchten dies jedoch als Ausgabe kennzeichnen",
    fee: () => "Sie hatten eine sonstige Ausgabe",
    "fiat-transfer": () =>
      "Fiat wurde übertragen, das Eigentum blieb jedoch bestehen.",
    fiatDeposit: () =>
      "Sie haben Fiatgeld von Ihrem Bankkonto auf eine Börse überwiesen",
    fiatWithdrawal: () =>
      "Sie haben von Ihrem Umtausch hoffentlich mehr Geld abgehoben, als Sie eingezahlt haben",
    gift: () =>
      "Sie haben ein Geschenk von einem Dritten, z. B. einem Familienmitglied, erhalten",
    in: () => "Nicht kategorisierte Transaktion",
    income: () =>
      "Sie haben Einnahmen in Kryptowährungen erhalten. Sie können auch Provisionen usw. in diese Kategorie einordnen",
    "increase-position": () =>
      "Wird verwendet, um eine bestehende Position in einem Protokoll zu eröffnen oder zu erhöhen. Wird für Derivate oder Staking verwendet.",
    increasePositionWithdrawal: () =>
      "Sie haben eine Position eröffnet oder erhöht, indem Sie Kryptowährung von Ihrem Konto investiert haben.",
    interest: () =>
      "Sie haben Zinsen für die Ausleihe Ihrer Kryptowährung erhalten",
    liquidate: () =>
      "Sie haben mit Hebel gehandelt und wurden nachschusspflichtig",
    loanRepayment: () => "Sie haben die Schulden eines Darlehens zurückgezahlt",
    lost: () =>
      "Sie haben Kryptowährung verloren und möchten die Kostenbasis als Steuerabzug geltend machen.",
    marginFee: () => "Sie haben eine Zinszahlung für ein Darlehen geleistet",
    mint: () =>
      "Dies wirkt ähnlich wie ein „Kauf“ und wird häufig angewendet, wenn ein Benutzer NFTs „prägt“.",
    "multi-mint": () =>
      "Dies funktioniert ähnlich wie ein „Kauf“ und wird häufig zum „Prägen“ von NFTs verwendet.",
    "multi-trade": () => "Mehrbranchen",
    out: () => "Nicht kategorisierte Transaktion",
    outgoingGift: () =>
      "Sie haben einem Dritten, z. B. einem Familienmitglied, ein Geschenk gemacht",
    personalUse: () =>
      "Sie haben einen Kauf für den persönlichen Gebrauch getätigt und möchten diesen als steuerfrei geltend machen (Achtung: Fragen Sie vor der Verwendung Ihren Buchhalter, um sicherzustellen, dass Sie die Kriterien erfüllen).",
    "provide-liquidity-trade": () =>
      "Sie haben einem Liquiditätspool Token im Austausch gegen einen Empfangstoken hinzugefügt.",
    realisedLoss: () =>
      "Sie haben beim Handel mit Derivaten, z. B. Futures/Margin, einen Verlust gemacht",
    realisedProfit: () =>
      "Sie haben Gewinn aus dem Handel mit Derivaten, z. B. Futures/Margin, erzielt",
    receivePQ: () =>
      "Sie haben eine Position eröffnet oder erhöht und einen Positionstoken erhalten.",
    receivingLiquidityProviderToken: () =>
      "Sie haben Token für das Hinzufügen von Münzen zu einem Liquiditätspool erhalten",
    "remove-liquidity-trade": () =>
      "Sie haben Ihren Liquiditätstoken zurückgegeben und im Gegenzug Ihr Geld aus dem Liquiditätspool abgerufen.",
    removeLiquidity: () =>
      "Sie haben diese Münzen aus einem Liquiditätspool entfernt",
    returningLiquidityProviderToken: () =>
      "Sie haben Token für das Entfernen von Münzen aus einem Liquiditätspool zurückgegeben",
    royalty: () =>
      "Sie haben Einnahmen aus Sekundärverkäufen erhalten, z. B. NFT-Ersteller",
    sell: () => "Löst ein Kapitalertragsteuerereignis aus",
    sendPQ: () =>
      "Sie haben eine Position geschlossen oder reduziert, indem Sie den Positionstoken zurückgegeben haben.",
    staking: () => "Sie haben Zinsen durch das Staking verdient",
    stakingDeposit: () =>
      "Sie haben diese Münzen in einen Staking-Pool eingezahlt. Dies wirkt ähnlich wie eine Auszahlung",
    stakingWithdrawal: () =>
      "Sie haben diese Münzen aus dem Staking-Pool abgezogen. Dies wirkt ähnlich wie eine Einzahlung",
    stolen: () =>
      "Ihre Kryptowährung wurde Ihnen gestohlen und Sie möchten die Kostenbasis als Steuerabzug geltend machen.",
    swap: () => "Automatisch gruppiertes Ein-/Austauschen",
    trade: () => "Automatisch gruppierter Kauf/Verkauf",
    transfer: () => "Automatisch gruppierte Abhebungen/Einzahlungen",
    withdrawal: () => "Kein steuerpflichtiger Vorgang",
  },
  tradeTypeTaxDescriptionExpanded: {
    addLiquidity: () =>
      "Sie haben Kryptowährung zu einem Liquiditätspool beigetragen.",
    airdrop: () =>
      "Sie haben eine kostenlose Kryptowährungsverteilung direkt in Ihr Wallet erhalten.",
    approval: () =>
      "Sie erteilen Berechtigungen in DeFi-Protokollen, was oft mit bestimmten Kosten verbunden ist.",
    "arbitrary-uncategorised": () => "Kategorisieren",
    borrow: () =>
      "Sie haben Kryptowährungen von einer Plattform geliehen, in der Regel durch Hinterlegung einer Sicherheit.",
    "bridge-trade": () =>
      "Sie haben einen Cross-Chain-Swap abgeschlossen, bei dem eine Währung über verschiedene Blockchains hinweg gegen eine andere getauscht wurde.",
    "bridge-transfer": () =>
      "Sie haben Kryptowährungen von einer Blockchain auf eine andere übertragen.",
    bridgeIn: () =>
      "Sie haben über eine Bridge Kryptowährung von einer anderen Blockchain erhalten.",
    bridgeOut: () =>
      "Sie haben Kryptowährungen über eine Bridge an eine andere Blockchain gesendet.",
    bridgeTradeIn: () =>
      "Sie haben Kryptowährungen auf einer Blockchain gehandelt und sie über eine Bridge an eine andere Blockchain gesendet.",
    bridgeTradeOut: () =>
      "Sie haben Kryptowährung auf einer Blockchain aus einem Handel erhalten, der auf einer anderen Blockchain initiiert wurde.",
    bridgeTransferIn: () =>
      "Sie haben über eine Bridge Kryptowährung von einer anderen Blockchain erhalten.",
    bridgeTransferOut: () =>
      "Sie haben Kryptowährungen über eine Bridge an eine andere Blockchain gesendet.",
    burn: () =>
      "Sie haben Kryptowährungen an eine Burn-Adresse gesendet und sie damit effektiv aus dem Verkehr gezogen.",
    buy: () =>
      "Sie haben Kryptowährungen mit Fiat-Währung oder anderen Kryptowährungen gekauft.",
    cashback: () =>
      "Sie haben Rabatte in Kryptowährungen für Einkäufe oder bestimmte Aktivitäten erhalten.",
    chainSplit: () =>
      "Sie haben aufgrund einer Blockchain-Fork oder -Aufspaltung neue Kryptowährung erhalten.",
    collateral: () =>
      "Sie haben Kryptowährungen als Sicherheit für Kredite oder andere Aktivitäten verwendet.",
    collateralWithdrawal: () =>
      "Sie haben Kryptowährungen zurückgefordert, die zuvor als Sicherheit verwendet wurden.",
    "decrease-position": () =>
      "Wird verwendet, um eine bestehende Position in einem Protokoll zu schließen oder zu reduzieren. Wird für Derivate oder Staking verwendet.",
    decreasePositionDeposit: () =>
      "Sie haben eine Position geschlossen oder reduziert und Erträge auf Ihr Konto erhalten.",
    deposit: () =>
      "Sie haben Kryptowährung von einem anderen Konto erhalten, das Ihnen gehört.",
    expense: () =>
      "Sie haben Kryptowährungen für Dienstleistungen, sonstige oder geschäftsbezogene Kosten verwendet.",
    failedOut: () =>
      "Eine Transaktion, deren Verarbeitung fehlgeschlagen ist oder die nicht abgeschlossen wurde. Ausgeschlossen von Steuer- und Saldenberechnungen.",
    fee: () => "Sie haben Netzwerk- oder Transaktionsgebühren bezahlt.",
    "fiat-transfer": () =>
      "Fiat wurde übertragen, das Eigentum blieb jedoch bestehen.",
    fiatDeposit: () =>
      "Sie haben Fiat-Währung auf einer Börse oder Plattform eingezahlt.",
    fiatWithdrawal: () =>
      "Sie haben Fiat-Währung von einer Börse oder Plattform abgehoben.",
    gift: () =>
      "Sie haben Kryptowährung als Geschenk von einer anderen Partei erhalten.",
    ignoreIn: () =>
      "Eine eingehende Transaktion, die von der Steuer- und Saldenberechnung ausgeschlossen ist.",
    ignoreOut: () =>
      "Eine ausgehende Transaktion, die von der Steuer- und Saldenberechnung ausgeschlossen ist.",
    in: () => "Eine eingehende Transaktion, die nicht kategorisiert wurde",
    income: () =>
      "Sie haben aus verschiedenen Aktivitäten Krypto-Einnahmen erhalten.",
    "increase-position": () =>
      "Wird verwendet, um eine bestehende Position in einem Protokoll zu eröffnen oder zu erhöhen. Wird für Derivate oder Staking verwendet.",
    increasePositionWithdrawal: () =>
      "Sie haben eine Position eröffnet oder erhöht, indem Sie Kryptowährung von Ihrem Konto investiert haben.",
    interest: () =>
      "Sie haben durch das Verleihen oder Halten Ihrer Kryptowährung Zinsen verdient.",
    liquidate: () =>
      "Sie haben Kryptowährungen verkauft, oft mit Verlust, um Schulden oder Nachschussforderungen zu decken.",
    loanRepayment: () =>
      "Sie haben von einer Plattform geliehenes Krypto zurückgezahlt.",
    lost: () =>
      "Sie haben Kryptowährungen aufgrund von Fehlern, vergessenen Schlüsseln oder aus anderen Gründen verloren.",
    marginFee: () =>
      "Ihnen sind Gebühren aus Margin- oder Leveraged-Trading entstanden.",
    mining: () =>
      "Sie haben Krypto verdient, indem Sie Transaktionen validiert oder Aufgaben in einer Blockchain ausgeführt haben.",
    mint: () =>
      "Sie haben neue Kryptowährungen oder NFTs erstellt, die normalerweise auf dezentralen Plattformen ausgeführt werden.",
    "multi-mint": () =>
      "Sie haben neue Kryptowährungen oder NFTs erstellt, die normalerweise auf dezentralen Plattformen ausgeführt werden.",
    "multi-trade": () =>
      "Sie haben zwei Vermögenswerte gehandelt (z. B. Krypto/Fiat oder Krypto/Krypto).",
    out: () => "Eine ausgehende Transaktion, die nicht kategorisiert wurde.",
    outgoingGift: () =>
      "Sie haben Kryptowährungen an Dritte verschenkt, beispielsweise an ein Familienmitglied.",
    personalUse: () =>
      "Sie haben Kryptowährungen für persönliche Ausgaben oder Einkäufe verwendet.",
    "provide-liquidity-trade": () =>
      "Sie haben Kryptowährung zu einem Liquiditätspool hinzugefügt und im Gegenzug einen Quittungstoken erhalten.",
    realisedLoss: () =>
      "Ihnen ist beim Abschluss eines Finanz- oder Derivatevertrags ein Verlust entstanden.",
    realisedProfit: () =>
      "Sie haben durch die Schließung eines Finanz- oder Derivatekontrakts einen Gewinn erzielt.",
    receivePQ: () =>
      "Sie haben eine Position eröffnet oder erhöht und einen Positionstoken erhalten.",
    receivingLiquidityProviderToken: () =>
      "Sie haben Token als Belohnung für die Bereitstellung von Liquidität für einen Pool erhalten.",
    "remove-liquidity-trade": () =>
      "Sie haben Ihr Liquiditätstoken zurückgegeben, um Kryptowährung aus dem Liquiditätspool abzurufen.",
    removeLiquidity: () =>
      "Sie haben Kryptowährung aus einem Liquiditätspool abgehoben.",
    returningLiquidityProviderToken: () =>
      "Sie haben Token zurückgegeben, die Ihren Anteil an einem Liquiditätspool darstellen.",
    royalty: () =>
      "Sie haben Krypto als Ergebnis von Lizenzgebühren aus kryptobasierten Kreationen wie NFTs erhalten.",
    sell: () =>
      "Sie haben Ihre Kryptowährung gegen Fiat-Währung oder andere Kryptowährung verkauft.",
    sendPQ: () =>
      "Sie haben eine Position geschlossen oder reduziert, indem Sie den Positionstoken zurückgegeben haben.",
    spamIn: () =>
      "Sie haben Kryptowährungen ohne Ihr Einverständnis erhalten. Dies ist häufig das Ergebnis von Spam oder Betrug. Von Steuer- und Saldenberechnungen ausgeschlossen.",
    "stake-deposit-group": () =>
      "Sie haben einen Token in einen Staking-Vertrag eingezahlt und einen Quittungstoken erhalten, der Ihre Position darstellt.",
    "stake-withdrawal-group": () =>
      "Sie haben eingesetzte Vermögenswerte aus einem Staking-Vertrag abgezogen und den Quittungstoken zurückgegeben, der die Position darstellt.",
    staking: () =>
      "Sie haben durch das Staking Ihrer Kryptowährung zusätzliche Kryptowährungen oder Belohnungen erhalten.",
    stakingDeposit: () =>
      "Sie haben Kryptowährung in einen Staking-Vertrag oder auf eine Staking-Plattform eingezahlt.",
    stakingWithdrawal: () =>
      "Sie haben Krypto aus einem Staking-Vertrag oder einer Staking-Plattform abgehoben.",
    stolen: () => "Ihre Kryptowährung wurde gestohlen.",
    swap: () =>
      "Sie haben einen nicht steuerpflichtigen Krypto-zu-Krypto-Handel durchgeführt.",
    swapIn: () =>
      "Sie haben Kryptowährungen aus einem nicht steuerpflichtigen Krypto-zu-Krypto-Handel erhalten.",
    swapOut: () =>
      "Sie haben Kryptowährungen aus einem nicht steuerpflichtigen Krypto-zu-Krypto-Handel erworben.",
    trade: () =>
      "Sie haben zwei Vermögenswerte gehandelt (z. B. Krypto/Fiat oder Krypto/Krypto).",
    transfer: () =>
      "Sie haben Kryptowährungen zwischen Ihren Konten übertragen und dabei das Eigentum behalten.",
    withdrawal: () =>
      "Sie haben Kryptowährungen von einem Konto auf ein anderes übertragen, das Ihnen gehört.",
  },
  transactionEdit: {
    dialog: {
      actionButton: () => "Beenden ohne Speichern",
      body: () =>
        "Alle von Ihnen vorgenommenen Änderungen gehen dabei verloren.",
      cancel: () => "Stornieren",
      title: () => "Beenden ohne Speichern?",
    },
    somethingWentWrong: () => "Etwas ist schief gelaufen.",
    trade: () => "Handel bearbeiten",
    transaction: () => "Transaktion bearbeiten",
    transfer: () => "Überweisung bearbeiten",
  },
  transactionTableModal: {
    defaultTitle: (d) =>
      plural(d.count, 0, de, {
        one: " 1 Transaktion ",
        other: number("de", d.count, 0) + " Transaktionen ",
      }),
    viewTxTable: () => "In der TX-Tabelle anzeigen",
  },
  transactions: {
    add: () => "Hinzufügen",
    deletedTheTxs: () => "Die Transaktionen gelöscht.",
    groups: {
      exchanges: () => "Börsen",
      other: () => "Andere",
      wallets: () => "Vermögenswerte",
    },
    partiallyDeleted: () =>
      "Beim Löschen der Transaktion ist ein Fehler aufgetreten. Die Transaktion wurde nur teilweise gelöscht.",
    reconcile: (d) => d.count + " Elemente abgleichen",
    title: () => "Transaktionen",
    ungroup: {
      action: () => "Gruppierung aufheben",
      body1: () =>
        "Möchten Sie die Gruppierung dieser Transaktionen wirklich aufheben?",
      title: () => "Gruppierung von Transaktionen aufheben",
    },
    updateTable: () => "Aktualisieren der Transaktionstabelle",
    wentWrongDeleting: () =>
      "Beim Löschen der Transaktionen ist ein Fehler aufgetreten",
    wentWrongDeletingComment: () =>
      "Beim Löschen des Kommentars ist ein Fehler aufgetreten",
    wentWrongDeletingTx: () =>
      "Beim Löschen der Transaktion ist ein Fehler aufgetreten.",
    wentWrongDuplicatingTx: () =>
      "Beim Duplizieren der Transaktionen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    wentWrongFetching: () =>
      "Beim Abrufen des Marktpreises ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    wentWrongIgnoringWarning: () =>
      "Beim Ignorieren der Warnung ist ein Fehler aufgetreten.",
    wentWrongLeavingNote: () =>
      "Beim Hinterlassen einer Notiz ist ein Fehler aufgetreten",
    wentWrongRecategorizing: () =>
      "Beim Umkategorisieren der Transaktionen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    wentWrongRegrouping: () =>
      "Beim Umgruppieren der Transaktionen ist ein Fehler aufgetreten",
    wentWrongUngrouping: () =>
      "Beim Aufheben der Gruppierung der Transaktionen ist ein Fehler aufgetreten",
    wentWrongUpdateCurrency: () =>
      "Beim Aktualisieren der Transaktionswährung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    wentWrongUpdatingTrade: () =>
      "Beim Aktualisieren der Handelstransaktion ist ein Fehler aufgetreten.",
    wentWrongUpdatingTx: () =>
      "Beim Aktualisieren der Transaktion ist ein Fehler aufgetreten.",
  },
  transferring: () => "Übertragen",
  tryAgain: () => "Versuchen Sie es erneut",
  twoFactorAuth: {
    "2FA": () => "2FA",
    authenticationCode: () => "Authentifizierungscode",
    authy: () => "Authy",
    disableError: () => "2FA konnte nicht deaktiviert werden",
    disableSuccess: () => "2FA erfolgreich deaktiviert",
    disableTwoFactorAuth: () => "2FA deaktivieren",
    done: () => "Erledigt",
    download: () => "Herunterladen",
    enableError: () => "2FA konnte nicht aktiviert werden",
    enableSuccess: () => "2FA erfolgreich aktiviert",
    enableTwoFactorAuth: () => "2FA aktivieren",
    enterCodeLabel: () => "Geben Sie den 6-stelligen Code ein",
    enterCodeOrRecoveryCodeHelperText: () =>
      "6-stelliger Authentifizierungscode oder ein Wiederherstellungscode",
    enterCodeSubText: () =>
      "Geben Sie den 6-stelligen Code aus Ihrer Authentifizierungs-App ein",
    enterCodeToChangePassword: () =>
      "Geben Sie den von Ihrer Authentifizierungs-App generierten 6-stelligen Code oder einen der Wiederherstellungscodes ein, um Ihr Passwort zu ändern",
    enterCodeToDisable: () =>
      "Geben Sie den von Ihrer Authentifizierungs-App generierten 6-stelligen Code ein, um 2FA zu deaktivieren",
    enterCodeToRegenerate: () =>
      "Geben Sie den von Ihrer Authentifizierungs-App generierten 6-stelligen Code ein, um Ihre Wiederherstellungscodes neu zu generieren und anzuzeigen",
    enterYourPassword: () => "1. Geben Sie Ihr Passwort ein",
    googleAuthenticator: () => "Google Authenticator",
    manualCodeEntry: () =>
      "Wenn Sie den Code nicht scannen können, kopieren Sie den Code unten, geben Sie ihn in Ihre Authentifizierungs-App ein und wählen Sie „Zeitbasiert“, wenn ein Dropdown-Menü mit Optionen angezeigt wird.",
    or: () => " oder ",
    password: () => "Passwort",
    passwordRequired: () => "Passwort erforderlich ist",
    qrCodeError: () => "QR-Code konnte nicht generiert werden",
    qrCodeForTwoFactor: () => "QR-Code für 2FA",
    recoveryCodes: () => "Wiederherstellungscodes anzeigen",
    recoveryCodesContent: () =>
      "Wenn Sie Ihr Mobilgerät verlieren, müssen Sie einen dieser Wiederherstellungscodes verwenden, um sich bei Ihrem CTC-Konto anzumelden. Jeder Code kann nur einmal verwendet werden.",
    recoveryCodesInfoBoxContent: () =>
      "Wenn Sie Ihr Authentifizierungsgerät verlieren und keinen Zugriff auf Ihre Wiederherstellungscodes haben, verlieren Sie den Zugriff auf Ihr CTC-Konto!",
    recoveryCodesInfoBoxTitle: () =>
      "Bewahren Sie die Codes an einem sicheren Ort auf",
    regenerate: () => "Regenerieren",
    regenerateError: () =>
      "Wiederherstellungscodes konnten nicht neu generiert werden",
    regenerateRecoveryCodes: () => "Wiederherstellungscodes neu generieren",
    regenerateSuccess: () => "Wiederherstellungscodes erfolgreich regeneriert",
    scanQrCode: () => "2. Scannen Sie diesen QR-Code",
    scanWithApp: () =>
      "Scannen Sie den untenstehenden QR-Code mit einer App wie ",
    staySignedIn: () => "30 Tage lang angemeldet bleiben",
    submit: () => "Einreichen",
    twoStepVerification: () => "Zweistufige Bestätigung",
    verify: () => "Verifizieren",
    verifyAuthenticationCode: () => "3. Authentifizierungscode überprüfen",
    verifyCode: () => "Code überprüfen",
  },
  txLimitExceeded: () => "Tx-Limit überschritten",
  txTable: {
    account: () => "Konto",
    accountBalance: {
      in: () => "Eingehender Saldo",
      out: () => "Ausgehender Saldo",
    },
    add: () => "Transaktion hinzufügen",
    addComment: () => "Einen Kommentar hinzufügen",
    addPrice: () => "Preis hinzufügen",
    all: () => "Alle",
    allTxs: () =>
      "Alle Ihre Transaktionen werden hier angezeigt. So legen Sie los",
    amount: () => "Menge",
    assignMissingBlockchain: () => "Blockchain zuweisen",
    assumedTransfer: () =>
      "Ein Teil dieser Transaktion wurde automatisch von unserer intelligenten Vorschlagsmaschine generiert",
    balanceRemainingInfo: {
      account: () => "Konto",
      change: () => "Änderung",
      currency: () => "Währung",
      endingBalance: () => "Endsaldo",
      overallBalance: () => "Gesamtbilanz",
      startingBalance: () => "Anfangsguthaben",
      tooltips: {
        change: () => "Die Veränderung des Saldos infolge dieser Transaktion",
        endingBalance: () =>
          "Der Saldo auf diesem Konto nach dieser Transaktion",
        overallBalance: () => "Der Saldo aller Konten nach dieser Transaktion.",
        startingBalance: () =>
          "Der Saldo dieses Kontos vor dieser Transaktion.",
      },
      type: () => "Kategorie",
      viewLedger: () => "Währungskonto",
    },
    bulkEditBar: {
      addComment: () => "Kommentar hinzufügen...",
      bulkLookupConfirmationText: () =>
        "Möchten Sie wirklich den Marktpreis für die ausgewählten Transaktionen nachschlagen? Dadurch wird der bestehende Preis dieser Transaktion überschrieben und dieser Vorgang kann nicht rückgängig gemacht werden.",
      changeCurrency: () => "Währung ändern",
      clearSelection: () => "Auswahl aufheben",
      close: () => "Menü „Bearbeiten“ schließen",
      command: () => "Befehl",
      confirm: () => "Bestätigen",
      delete: () => "Löschen",
      deleteConfirmationText: () =>
        "Achtung! Diese Aktion kann nicht rückgängig gemacht werden. Möchten Sie diese Transaktionen wirklich löschen? Wir empfehlen, Transaktionen nach Möglichkeit zu ignorieren.",
      edit: () => "Bearbeiten",
      editAll: () => "Alle bearbeiten",
      groupingWizard: () => "Gruppierungsassistent (Beta)",
      groups: () => "Gruppen",
      ignore: () => "Ignorieren",
      ignoreConfirmationText: () =>
        "Möchten Sie diese Transaktionen wirklich ignorieren? Wenn Sie diese Aktion ausführen, werden diese Transaktionen bei allen Steuerberechnungen ignoriert. Ignorierte Transaktionen können in den erweiterten Filtern aus der Transaktionstabelle ausgeblendet werden.",
      markAsReviewed: () => "Als überprüft markieren",
      markAsUnreviewed: () => "Als „Nicht überprüft“ markieren",
      numSelected: (d) => d.numSelected + " ausgewählt",
      numSelectedAll: (d) =>
        "Alle " +
        d.numSelected +
        " Transaktionen in diesem Filter werden ausgewählt",
      open: () => "Befehlsmenü öffnen",
      recategorizeError: () =>
        "Beim Umkategorisieren der Transaktionen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      selectAll: () =>
        "Wählen Sie alle Transaktionen aus, die diesem Filter entsprechen",
      selected: () => "Ausgewählt",
      spam: () => "Als Spam markieren",
      spamConfirmationText: () =>
        "Möchten Sie diese Transaktionen wirklich als Spam markieren? Wenn Sie diese Aktion ausführen, werden diese Transaktionen bei allen Steuerberechnungen ignoriert. Spam-Transaktionen können in den erweiterten Filtern aus der Transaktionstabelle ausgeblendet werden.",
      txCount: (d) => d.numSelected + " Transaktionen",
    },
    bulkLookupPriceConfirmationTitle: () => "Marktpreis nachschlagen",
    commandPallet: {
      acceptedForProcessing: () =>
        "Wird jetzt aktualisiert, kann einige Minuten dauern ...",
      actions: {
        accept: () => "Akzeptieren",
        add: () => "Hinzufügen",
        confirm: () => "Bestätigen",
        goBack: () => "Geh zurück",
      },
      bulkEditOptionDescriptions: {
        txRewind: () =>
          "Geben Sie bitte das Datum und die Uhrzeit ein, bis zu der Sie die Transaktionsänderungen rückgängig machen möchten. Hinweis: Diese Aktion macht nur Änderungen an Transaktionen rückgängig, die zwischen dem aktuellen Zeitpunkt und dem ausgewählten Zeitstempel vorgenommen wurden. Neu erstellte oder importierte Transaktionen werden dadurch nicht gelöscht und gelöschte Transaktionen werden auch nicht wiederhergestellt. Nur Änderungen, die auf der Registerkarte „Verlauf“ der entsprechenden Transaktionszeilen sichtbar sind, werden rückgängig gemacht.",
      },
      bulkEditOptions: {
        addCommentToTransactions: () =>
          "Kommentare zu Transaktionen hinzufügen",
        allowGrouping: () => "Gruppierung zulassen",
        categorize: () => "Kategorisieren",
        changeAssetAccount: () => "Vermögenskonto ändern",
        changeCashAccount: () => "Geldkonto ändern",
        changeCurrency: () => "Währung ändern",
        changeFeeCurrency: () => "Gebührenwährung ändern",
        changeGainsAccount: () => "Konto für Änderungsgewinne",
        changeLoanAccount: () => "Darlehenskonto ändern",
        changePnLAccount: () => "PnL-Konto ändern",
        changePrice: () => "Preis ändern",
        delete: () => "Löschen",
        destinationAccount: () => "Eingehendes Konto ändern",
        groupingWizard: () => "Gruppierungsassistent (Beta)",
        ignore: () => "Ignorieren",
        ignoreWarnings: () => "Warnungen ignorieren",
        lookUpMarketPrice: () => "Marktpreis nachschlagen",
        markAsNotReviewed: () => "Bewertung aufheben",
        markAsReviewed: () => "Als überprüft markieren",
        markAsSpam: () => "Als Spam markieren",
        sourceAccount: () => "Ausgehendes Konto ändern",
        syncToErp: () =>
          "Synchronisierung mit der Integration von Buchhaltungssoftware",
        txRewind: () => "Transaktionen zurückspulen (Beta)",
        ungroup: () => "Gruppierung aufheben",
      },
      chips: {
        type: () => "Typ",
      },
      currentlySelected: () => "Zurzeit ausgewählt",
      groups: () => "Gruppen",
      ignoreWarningsOptions: {
        all: () => "Alle",
        missingBlockchain: () => "Fehlende Blockchain",
        missingPrice: () => "Fehlender Preis",
        negativeBalance: () => "Fehlende Kaufhistorie",
        uncategorised: () => "Unkategorisiert",
        unmatchedTransfer: () => "Unübertroffene Übertragung",
      },
      loadingOptions: () => "Optionen werden geladen...",
      noOptions: () => "Keine Optionen",
      notAvailableOnSelectAll: () =>
        "Im Modus „Alles auswählen“ nicht verfügbar",
      numSelected: (d) => d.numSelected + " Transaktionen",
      placeholders: {
        account: () => "Welches Konto...",
        category: () => "Welche Kategorie...",
        changeTo: () => "Ändern in...",
        currency: () => "Welche Währung...",
        ignoreWarning: () => "Warnungen ignorieren...",
        incomingAccount: () => "Welches eingehende Konto...",
        outgoingAccount: () => "Welches Ausgangskonto...",
        searchSubType: (d) => "Suche " + d.subType,
        toCategory: () => "Ändern in...",
        toSourceAccount: () => "Ändern in...",
        tradeType: () => "Welche Kategorie...",
        tradeTypeSearch: {
          group: () => "Suche in gruppierten Kategorien",
          hint: () => "Hinweis! Diese Vertragsfunktion ist",
          in: () => "Eingehende Kategorien durchsuchen",
          out: () => "Suche nach ausgehenden Kategorien",
          uncategorisedTooltipOverride: (d) =>
            "Durch die Kategorisierung dieser Transaktion wird " +
            d.value +
            " in nicht kategorisierten Transaktionswarnungen aufgelöst.",
        },
      },
      successMessage: {
        addCommentToTransactions: (d) =>
          "Kommentar hinzugefügt am " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        allowGrouping: (d) =>
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }) + " dürfen gruppiert werden",
        categorize: (d) =>
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }) + " erfolgreich neu kategorisiert",
        changeAssetAccount: (d) =>
          "Vermögenskonto aktualisiert am " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        changeCashAccount: (d) =>
          "Bargeldkonto aktualisiert am " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        changeCurrency: (d) =>
          "Währung erfolgreich geändert für " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        changeFeeCurrency: (d) =>
          "Gebührenwährung geändert am " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        changeGainsAccount: (d) =>
          "Gewinne-Konto aktualisiert am " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        changeLoanAccount: (d) =>
          "Darlehenskonto aktualisiert am " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        changePnLAccount: (d) =>
          "PnL-Konto aktualisiert am " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        changePrice: (d) =>
          "Preis für " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }) +
          " erfolgreich aktualisiert",
        delete: (d) =>
          "Gelöscht " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        destinationAccount: (d) =>
          "Eingehendes Konto geändert am " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        ignore: (d) =>
          "Ignoriert " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        ignoreWarnings: (d) =>
          "Ignorierte " +
          d.warning +
          " Warnungen bei " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        lookUpMarketPrice: (d) =>
          "Marktpreis für " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }) +
          " nachgeschlagen",
        markAsNotReviewed: (d) =>
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }) + " als nicht überprüft markiert",
        markAsReviewed: (d) =>
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }) + " als überprüft markiert",
        markAsSpam: (d) =>
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }) + " als Spam markiert",
        sourceAccount: (d) =>
          "Ausgehendes Konto geändert am " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        syncToErp: (d) =>
          "Synchronisierungsanforderung gesendet für " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        txRewind: (d) =>
          "Zurückgespult " +
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }),
        ungroup: (d) =>
          plural(d.count, 0, de, {
            1: " 1 Transaktion ",
            other: number("de", d.count, 0) + " Transaktionen ",
          }) + " nicht gruppiert",
      },
      syncToErp: (d) => "Mit " + d.erp + " synchronisieren",
      typeSearchCommand: () => "Geben Sie einen Befehl ein oder suchen Sie ...",
      undo: () => "Rückgängig machen",
      undoFailed: () => "Änderungen konnten nicht rückgängig gemacht werden",
      undoLoading: () => "Änderungen werden rückgängig gemacht...",
      undoSuccess: () => "Erfolg!",
    },
    commentLabels: {
      allComments: () => "Alle Kommentare",
      hasComments: () => "Kommentare",
    },
    contractAddress: () => "Vertragsadresse",
    cost: () => "Kostenbasis",
    counterparty: () => "Gegenpartei",
    currency: () => "Währung",
    date: {
      helperFrom: () => "Sie müssen ein Gültigkeitsdatum eingeben",
      helperTo: () => "Sie müssen ein gültiges Bis-Datum eingeben",
    },
    deleteBulkTransactionsConfirmationTitle: (d) =>
      "Löschen Sie " +
      plural(d.txCount, 0, de, {
        one: " 1 Transaktion ",
        other: number("de", d.txCount, 0) + " Transaktionen ",
      }),
    deleteTransactionConfirmationTitle: () => "Transaktion löschen",
    destination: () => "Ziel",
    dialogs: {
      deleteConfirmationText: () =>
        "Achtung! Diese Aktion kann nicht rückgängig gemacht werden. Möchten Sie diese Transaktion wirklich löschen? Wir empfehlen, die Transaktion nach Möglichkeit zu ignorieren.",
      ignoreTransactionText: () =>
        "Möchten Sie diese Transaktion wirklich ignorieren? Wenn Sie diese Aktion ausführen, wird diese Transaktion bei allen Steuerberechnungen ignoriert. Ignorierte Transaktionen können in den erweiterten Filtern aus der Transaktionstabelle ausgeblendet werden.",
      spamTransactionText: () =>
        "Möchten Sie diese Transaktion wirklich als Spam markieren? Wenn Sie diese Aktion ausführen, wird diese Transaktion bei allen Steuerberechnungen ignoriert. Spam-Transaktionen können in den erweiterten Filtern aus der Transaktionstabelle ausgeblendet werden.",
    },
    entities: {
      explainerTooltip: () =>
        "Wenn Sie dieser Entität gestatten, als Treuhänder zu fungieren, kann sie in Ihrem Namen Gelder aufbewahren und Veräußerungen durchführen. Beispiele dafür, wann Sie dies aktivieren möchten, sind Entitäten, die Auktionsverträge, Dollar-Cost-Averaging-Verträge oder jede Art von Interaktion darstellen, bei der Sie Gelder einzahlen und gleichzeitig das Eigentum behalten, bis der Vertrag zu einem späteren Zeitpunkt in Ihrem Namen darüber verfügt.",
      falsePositive: () => "Nicht mehr als Treuhandkonto gekennzeichnet",
      globalEscrowTooltip: () =>
        "Dieses Unternehmen wurde von CTC als Treuhänder gekennzeichnet",
      switchText: () =>
        "Erlauben Sie dieser Entität, als Treuhänder zu fungieren",
      truePositive: () => "Als Treuhandkonto gekennzeichnet",
    },
    expansionPanel: {
      adjPrice: () => "Angepasster Preis",
      amount: () => "Menge",
      balanceError: () => "Salden sind negativ",
      chartOfAccounts: {
        accountMappingNotRequired: () => "Keine Kontozuordnungen erforderlich",
        accountNotFound: (d) =>
          "Das zugeordnete Konto kann nicht im Kontenplan von " +
          d.erp +
          " gefunden werden",
        accountTypeLabel: {
          asset: () => "Vermögenswert",
          cash: () => "Kasse",
          gain: () => "Gewinnen",
          loan: () => "Darlehen",
          pl: () => "Gewinn- und Verlustrechnung",
          "zero-cost-buy": () => "ZCB",
        },
        assetAccount: () => "Vermögenskonto",
        cashAccount: () => "Kassenkonto",
        dropdown: () => "Nach Konto suchen",
        erpAccounts: (d) => d.erp + " Konten",
        from: () => "Aus",
        gainAccount: () => "Konto gewinnen",
        hiddenAccounts: () =>
          "Wir haben diese Liste gefiltert, um Bankkonten und andere Konten, auf die kein Journal geführt werden kann, auszuschließen.",
        loanAccount: () => "Darlehenskonto",
        plAccount: () => "Gewinn- und Verlustrechnung",
        title: () => "Kontenplan",
        to: () => "Zu",
        transaction: () => "Transaktion",
        uncategorised: () =>
          "Bitte legen Sie die Transaktionskategorie fest, um Konten zuzuordnen",
        zeroCostBuyAccount: () => "ZCB-Konto",
      },
      comments: {
        accountant: () => "Buchhalter",
        button: () => "Hinterlasse einen Kommentar",
        client: () => "Kunde",
        collaborator: () => "Mitarbeiter",
        comments: (d) =>
          plural(d.count, 0, de, {
            0: " Kommentare ",
            other: " Kommentare ( " + d.count + " ) ",
          }),
        editError: () =>
          "Beim Bearbeiten des Kommentars ist ein Fehler aufgetreten.",
        edited: () => "(bearbeitet)",
        editedAt: (d) => "Bearbeitet " + d.time,
        noComments: () => "Kommentare erscheinen hier",
        placeholder: () => "Einen Kommentar hinzufügen...",
        saveChanges: () => "Änderungen speichern",
        you: () => "Du",
      },
      cost: () => "Kostenbasis",
      currency: () => "Währung",
      date: () => "Datum",
      delete: {
        body: () =>
          "Sobald Sie den Kommentar löschen, ist er für immer verschwunden.",
        title: () => "Kommentar löschen?",
      },
      events: () => "Mit dieser Transaktion verbundene Ereignisse",
      fee: () => "Gebühren",
      functionName: (d) => "Vertragsfunktion: " + d.functionName,
      functionNameLabel: () => "Vertragsfunktion",
      gain: () => "Gewinnen",
      history: {
        changed: () => "geändert",
        created: () => "manuell erstellt",
        currentVersion: () => "Aktuelle Version",
        deleted: () => "Gelöscht",
        false: () => "NEIN",
        from: () => "aus",
        headings: {
          actor: () => "Akteur",
          date: () => "Datum",
          transaction: () => "Anpassung",
        },
        imported: () => "importiert",
        modalTitle: (d) => "Transaktionen wiederherstellen in " + d.timestamp,
        noChange: () => "Keine Änderung",
        noChangesFromCurrentDetected: () => "Keine Änderungen erkannt",
        notRecorded: () => "Nicht aufgezeichnet",
        notSet: () => "Nicht festgelegt",
        original: () => "Original",
        restore: (d) => "Wiederherstellen auf " + d.time,
        restoreOnly: () => "Wiederherstellen",
        thisVersion: () => "Diese Version",
        thisWillChange: () => "Das wird sich ändern:",
        title: () => "Bearbeitungsverlauf",
        to: () => "Zu",
        transaction: () => "Transaktion",
        transactionKeys: {
          blockchain: () => "Blockchain",
          currencyIdentifier: () => "Währung",
          description: () => "Beschreibung",
          dontGroup: () => "Nicht gruppieren",
          "erp.accountMappings.asset": () =>
            "Überschreibung des Vermögenskontos",
          "erp.accountMappings.cash": () => "Bargeldkonto überschreiben",
          "erp.accountMappings.gain": () => "Kontoüberschreibung erhalten",
          "erp.accountMappings.pl": () => "PnL-Kontoüberschreibung",
          fee: () => "Gebühr",
          feeCurrencyIdentifier: () => "Gebührenwährung",
          feeQuantity: () => "Gebührenmenge",
          from: () => "Von",
          functionName: () => "Tx-Funktion",
          functionSignature: () => "Tx-Signatur",
          price: () => "Preis",
          quantity: () => "Menge",
          reviewed: () => "Überprüft",
          suggestion: () => "Anregung",
          timestamp: () => "Zeitstempel",
          to: () => "An",
          trade: () => "Kategorie",
        },
        true: () => "Ja",
        unableToCompare: () => "Vergleich nicht möglich",
        unableToFindPreviousVersion: () =>
          "Vorherige Version konnte nicht gefunden werden",
        undo: () => "Rückgängig machen",
        undoLatestChange: () => "Diese Änderung rückgängig machen?",
        updateReason: {
          import: () => "CTC-Preis-Oracle",
          migration: () => "CTC-System",
          rule: () => "CTC-Regeldienst",
          system: () => "CTC-System",
          transactionMatching: () => "CTC-Transaktionsabgleich",
          unknown: () => "Unbekannt",
          user: () => "Benutzer",
        },
        updateReasonActorCTCSystem: () => "CTC-System",
        updateReasonActorUnknown: () => "Unbekannt",
        updateReasonActorUser: () => "Benutzer",
      },
      id: () => "AUSWEIS:",
      importSource: (d) => "Importquelle: " + d.sourceDisplayName,
      inflightBalanceError: () =>
        "Ein fehlender Kaufverlauf weist darauf hin, dass einige Ihrer ausgehenden Transaktionen fehlen. Dies kann passieren, wenn Sie nicht alle Ihre Daten importiert haben",
      moreInfo: () => "Weitere Infos",
      moreInfoPopover: {
        importSource: () => "Importquelle:",
        learnAbout: () => "Erfahren Sie mehr über",
        title: () => "Weitere Informationen",
      },
      na: () => "N / A",
      notes: (d) => "Beschreibung: " + d.notes,
      price: () => "Preis",
      proceeds: () => "Erlös",
      receivedFrom: () => "Empfangen von",
      rolloverFrom: (d) => "Rollover von " + d.currency,
      ruleApplied: () => "Regel angewendet",
      sentBy: () => "Gesendet an",
      sourceType: (d) => "Preisquelle: " + d.sourceType,
      sourceTypes: {
        ManualUpdate: () => "Manuelles Update",
        PriceFromAction: () => "Preis ab Aktion",
        PriceWindow: () => "Preis-Fallback-Fenster",
      },
      time: () => "Zeit",
      total: () => "Gesamt",
      tradedOn: () => "Gehandelt",
      transactionId: (d) =>
        plural(d.count, 0, de, { one: " Tx-ID ", other: " Tx-IDs " }),
      transactionIdLabel: () => "Tx-ID",
      transactionsTab: () => "Transaktionen",
      type: () => "Kategorie",
      ungroup: {
        buttonText: () => "Gruppierung zulassen",
        infoBody: () =>
          "Die Gruppierung dieser Transaktion wurde manuell aufgehoben, weshalb sie von unserem Gruppierungsalgorithmus nicht mehr als Teil eines Handels oder einer Überweisung berücksichtigt wird.",
        infoTitle: () => "Nicht gruppierte Transaktion",
        title: () => "Nicht gruppiert",
      },
      updating: () => "Aktualisieren von Transaktionen",
      value: () => "Wert",
      viewAssociated: () => "Zugehörige Transaktionen anzeigen",
      viewBalance: () => "Kontobuch anzeigen",
      viewRelatedTx: () => "Zugehörige Transaktionen anzeigen",
      viewTaxRule: () => "Steuerregel anzeigen",
      viewTx: () => "TX anzeigen",
    },
    exportCSV: {
      filterOptionCaption: () => "Transaktionen entsprechen diesem Filter",
      filterOptionTitle: () => "Transaktionen im Filter",
      pageOptionCaption: () => "Transaktionen derzeit auf Seite",
      pageOptionTitle: () => "Transaktionen auf der Seite",
      paywall: {
        button: () => "Wähle einen Plan aus",
        text: () =>
          "Zum Exportieren von Transaktionen ist ein Plan erforderlich.",
      },
      pending: {
        captionPt1: () =>
          "Der Bericht wird automatisch heruntergeladen, wenn er fertig ist.",
        captionPt2: () => "Sie können dieses Fenster schließen.",
        title: () => "Bericht erstellen",
      },
      submitButton: (d) => d.count + " Transaktionen exportieren",
      success: {
        captionPt1: () => "Wenn Sie Ihre Datei nicht sehen, können Sie ",
        captionPt2: () => "lade es noch einmal herunter",
        title: () => "Ihr Export wurde heruntergeladen",
      },
      title: () => "Transaktionen exportieren CSV",
    },
    fee: () => "Gebühr",
    feeValue: (d) => "Gebührenwert: " + d.amount,
    fiatTradeLabel: {
      deposit: () => "Kaution",
      withdrawal: () => "Rückzug",
    },
    filter: {
      addFilter: () => "Filter hinzufügen",
      advanced: () => "Fortschrittlich",
      advancedFilters: () => "Filter löschen, um zu suchen",
      category: () => "Kategorie",
      clearFilters: () => "Filter löschen",
      comment: () => "Kommentare",
      currency: () => "Währung",
      date: () => "Datum",
      descriptions: {
        actionId: () => "Suchen Sie nach einer Transaktion anhand ihrer ID",
        after: () => "Suche nach Transaktionen nach dem angegebenen Datum",
        and: () =>
          "Suche nach Transaktionen, die ALLEN ausgewählten Filtern entsprechen",
        before: () => "Suche nach Transaktionen vor dem angegebenen Datum",
        blockchain: () =>
          "Suchen Sie nach Transaktionen anhand der Blockchain, auf der sie stattgefunden haben",
        category: () => "Suchen Sie nach Transaktionen anhand ihrer Kategorie",
        comment: () =>
          "Suche nach Transaktionen anhand des Inhalts ihrer Kommentare",
        comments: () =>
          "Suche nach Transaktionen anhand des Inhalts ihrer Kommentare",
        currency: () =>
          "Suche nach Transaktionen anhand der Transaktionswährung",
        date: () => "Suche nach Transaktionen an einem bestimmten Datum",
        description: () =>
          "Suche nach Transaktionen anhand des Inhalts ihrer Beschreibung",
        erpAssetAccount: () =>
          "Suchen Sie nach Transaktionen anhand der Überschreibung der Zuordnung der Anlagenkonten in der Buchhaltungsintegration.",
        erpCashAccount: () =>
          "Suchen Sie nach Transaktionen anhand der Überschreibung der Zuordnung von Bargeldkonten in der Buchhaltungsintegration",
        erpGainsAccount: () =>
          "Suchen Sie nach Transaktionen anhand ihrer Buchhaltungsintegration und erlangen Sie eine Überschreibung der Kontenzuordnung",
        erpLoanAccount: () =>
          "Suchen Sie nach Transaktionen anhand der Überschreibung der Zuordnung von Darlehenskonten in der Buchhaltungsintegration.",
        erpPnlAccount: () =>
          "Suchen Sie nach Transaktionen anhand der Überschreibung der Gewinn- und Verlustkontenzuordnung bei der Buchhaltungsintegration",
        erpSyncStatus: () =>
          "Suchen Sie nach Transaktionen anhand ihres Synchronisierungsstatus für die Buchhaltungsintegration",
        feeCurrency: () =>
          "Suchen Sie nach Transaktionen anhand der Währung, in der die Gebühr bezahlt wurde",
        from: () => 'Suchen Sie nach Transaktionen anhand ihres "Von"-Kontos',
        hasRule: () =>
          "Transaktionen ausblenden, die nicht von Regeln betroffen sind",
        id: () => "Suchen Sie nach einer Transaktion anhand ihrer ID",
        importId: () =>
          "Suche nach Transaktionen anhand der Synchronisierung, bei der sie importiert wurden",
        importType: () =>
          "Suchen Sie nach Transaktionen anhand der Art des Imports, durch den sie eingeführt wurden",
        isSmartContractInteraction: () =>
          "Suche nach Transaktionen mit Smart Contracts",
        ledger: () =>
          "Suche nach Transaktionen mit Bezug zur aktuellen Ledger-Ansicht",
        missingPrice: () => "Suche nach Transaktionen nach",
        negativeBalance: () => "Suche nach Transaktionen nach",
        nftCollection: () =>
          "Suche nach Transaktionen zum Handel mit NFTs aus einer bestimmten Sammlung",
        not: () =>
          "Suche nach Transaktionen, die NICHT dem ausgewählten Filter entsprechen",
        or: () =>
          "Suche nach Transaktionen, die EINIGEN der ausgewählten Filter entsprechen",
        party: () => "Suche nach Transaktionen anhand der Konten, die zwischen",
        reconciliation: () =>
          "Suche nach Transaktionen im Zusammenhang mit einem Trinkgeld",
        reviewed: () => "Suche nach überprüften Transaktionen",
        rule: () =>
          "Suche nach Transaktionen, die durch eine bestimmte Regel manipuliert wurden",
        ruleOperator: () =>
          "Suche nach Transaktionen, die von einem bestimmten Regeloperator manipuliert wurden",
        source: () =>
          "Suche nach Transaktionen anhand der Konten, die zwischen",
        suggestion: () =>
          "Suchen Sie nach Transaktionen, die von unserer intelligenten Vorschlags-Engine kategorisiert wurden",
        sync: () =>
          "Suche nach Transaktionen anhand der Synchronisierung, bei der sie importiert wurden",
        taxOutcomeType: () =>
          "Suche nach Transaktionen anhand ihres Steuerergebnisses",
        to: () => 'Suchen Sie nach Transaktionen anhand ihres "An"-Kontos',
        trade: () => "Suchen Sie nach Transaktionen nach Typ",
        txCurrency: () =>
          "Suche nach Transaktionen anhand der Währungen, in denen Transaktionen getätigt wurden",
        txFunctionName: () =>
          "Suche nach Transaktionen mit der Vertragsfunktion",
        txFunctionSignature: () =>
          "Suche nach Transaktionen anhand der Methoden-ID",
        txId: () => "Suche nach Transaktionen anhand des Blockchain-Hashes",
        warning: () =>
          "Suche nach Transaktionen anhand der Warnungen, die sie haben",
      },
      erpSyncStatus: {
        NOT_READY_TO_SYNC: () => "Nicht bereit zur Synchronisierung",
        SYNC_FAILED: () => "Fehlgeschlagen",
        SYNC_IN_PROGRESS: () => "Im Gange",
        SYNC_NOT_REQUESTED: () => "Bereit zur Synchronisierung",
        SYNC_NO_OP: () => "Kein Op",
        SYNC_OUT_OF_DATE: () => "Veraltet",
        SYNC_SUCCESSFUL: () => "Erfolgreich",
      },
      filter: () => "Filter",
      filterBuilderOperatorOverrides: {
        category: () => "Kategorie",
      },
      filters: () => "Filter",
      hasRule: () =>
        "Transaktionen ausblenden, die nicht von Regeln betroffen sind",
      hideIgnored: () => "Ignorierte/Spam-Transaktionen ausblenden",
      hideLockedTransactions: () => "Gesperrte Transaktionen verbergen",
      hideNonSuggested: () =>
        "Nur intelligent kategorisierte Transaktionen anzeigen",
      hideReviewed: () => "Überprüfte Transaktionen ausblenden",
      importSource: () => "Quelle importieren",
      importType: () => "Importtyp",
      imports: () => "Importe",
      includesFee: {
        doesNotIncludesFee: () => "Keine Gebühren",
        includesFee: () => "Gebühren",
      },
      isSmartContractInteraction: {
        smartContract: () => "Smart Contract",
        wallet: () => "Wallet",
      },
      lockPeriodTooltip: () => "Fügen Sie einen Sperrzeitraum hinzu, um",
      manual: () => "Handbuch",
      moreFilters: () => "Weitere Filter",
      negation: {
        is: () => "Ist",
        isNot: () => "Ist nicht",
      },
      noOptions: () => "Keine Optionen",
      noResults: () => "Keine Ergebnisse",
      noWarnings: () => "Sie haben keine Warnungen",
      operators: {
        actionId: () => "Aktions-ID",
        after: () => "Nach",
        and: () => "Und",
        before: () => "Vor",
        blockchain: () => "Blockchain",
        category: () => "Kategorie",
        comment: () => "Kommentar",
        comments: () => "Kommentare",
        currency: () => "Währung",
        date: () => "Datum",
        description: () => "Beschreibung",
        erpAssetAccount: () => "Vermögenskonto",
        erpCashAccount: () => "Kassenkonto",
        erpGainsAccount: () => "Konto gewinnen",
        erpLoanAccount: () => "Darlehenskonto",
        erpPnlAccount: () => "Gewinn- und Verlustrechnung",
        erpSyncStatus: () =>
          "Synchronisierungsstatus der Buchhaltungsintegration",
        feeCurrency: () => "Gebührenwährung",
        from: () => "Aus",
        hasRule: () =>
          "Transaktionen ausblenden, die nicht von Regeln betroffen sind",
        id: () => "AUSWEIS",
        importId: () => "Import-ID",
        importType: () => "Importtyp",
        includesFee: () => "Gebühren",
        integrations: () => "Integration",
        isSmartContractInteraction: () => "Interaktion",
        ledger: () => "Guthaben anzeigen",
        lockPeriod: () => "Sperrfrist",
        missingPrice: () => "Fehlender Preis",
        negativeBalance: () => "Fehlende Kaufhistorie",
        nftCollection: () => "NFT-Sammlung",
        not: () => "Nicht",
        or: () => "Oder",
        party: () => "Party",
        reconciliation: () => "Versöhnung",
        reviewed: () => "Überprüft",
        rule: () => "Regel",
        ruleOperator: () => "Regeloperator",
        source: () => "Konto",
        suggestion: () => "Anregung",
        sync: () => "Synchronisieren",
        taxOutcomeType: () => "Steuerergebnis",
        to: () => "Zu",
        trade: () => "Kategorie",
        txCurrency: () => "Tx-Währung",
        txFunctionName: () => "Tx-Signatur",
        txFunctionSignature: () => "Tx-Methoden-ID",
        txId: () => "Tx-ID",
        warning: () => "Warnung",
      },
      otherFilter: () => "Ansicht",
      placeholder: () => "Transaktionen suchen",
      present: () => "Gegenwärtig",
      resetFilters: () => "Filter zurücksetzen",
      search: () => "Suchfilteroperatoren",
      selectAll: () => "Alles auswählen",
      settingsFilter: () => "Tisch",
      showIgnored: () => "Ignorierte/Spam-Transaktionen anzeigen",
      sort: () => "Sortieren",
      source: () => "Quelle",
      table: () => "Guthaben",
      taxOutcomeType: {
        income: () => "Einkommen",
        longTermCapitalGain: () => "Langfristiger Kapitalgewinn",
        longTermCapitalLoss: () => "Langfristiger Kapitalverlust",
        shortTermCapitalGain: () => "Kurzfristiger Kapitalgewinn",
        shortTermCapitalLoss: () => "Kurzfristiger Kapitalverlust",
      },
      textMatching: {
        is: () => "Enthält",
        isNot: () => "Enthält nicht",
      },
      transactionId: () => "Geben Sie die Transaktions-ID ein",
      trySearchingFor: () => "Versuchen Sie eine Suche nach...",
      txFunctionName: () => "Tx-Signatur",
      txFunctionSignature: () => "Tx-Methoden-ID",
      txId: () => "Tx-ID",
      txSignature: () => "Unterschrift",
      typeToSearch: () => "Suchbegriff eingeben",
      warningLabel: (d) => d.label + " ( " + d.number + " )",
      warnings: () => "Warnhinweise",
    },
    filteredCount: (d) =>
      d.totalFilteredCount + " von " + d.totalCount + " Txs",
    from: () => "Ausgehend",
    gain: () => "Gewinnen",
    generateReport: () => "Bericht erstellen",
    id: () => "AUSWEIS",
    identifyAddress: () => "Adresse ermitteln",
    ignoreBulkTransactionsConfirmationTitle: () => "Transaktionen ignorieren",
    ignoreTransactionConfirmationTitle: () => "Transaktion ignorieren",
    ignored: () => "Ignoriert",
    ignoredWarningLabels: {
      missingBlockchain: () => "Fehlende Blockchain (ignoriert)",
      negativeBalance: () => "Fehlende Kaufhistorie (ignoriert)",
    },
    importData: () => "Daten importieren",
    importFilterOther: () => "Andere",
    importMoreData: () => "Weitere Daten importieren",
    importType: {
      api: () => "API",
      "assumed-fee": () => "Angenommene Gebühr",
      "bulk-wallet": () => "Massenimport",
      csv: () => "CSV",
      manual: () => "Handbuch",
      "manual-csv": () => "Manuelles CSV",
      oauth: () => "OAuth",
      "soft-transfer": () => "Intelligenter Vorschlag",
      "soft-wallet": () => "soft Wallet",
      wallet: () => "Wallet",
      "wallet-api": () => "Wallet-API",
      "wallet-csv": () => "Brieftaschen-CSV",
    },
    invalidBulkEditTooltip: () =>
      "Gruppierte Transaktionen können nicht stapelweise bearbeitet werden",
    invalidCategoryTradeUncategorised: (d) =>
      "Verbessern Sie die Genauigkeit, indem Sie diese Transaktion kategorisieren. Derzeit wird die Standardkategorie " +
      d.tradeType +
      " verwendet, die möglicherweise einen Kapitalgewinn auslösen kann.",
    labels: () => "Labels",
    missingBlockchainDescription: () =>
      "Dadurch wird die Blockchain festgelegt, aus der diese Adresse stammt.",
    moreThan: () => "Mehr als",
    noComments: () => "Keine Kommentare",
    noData: () => "Keine Daten hinzugefügt",
    noTxFound: () => "Keine Transaktionen gefunden",
    noTxMatching: () =>
      "Es wurden keine Transaktionen gefunden, die dem Filter entsprechen. Bitte versuchen Sie es mit einem anderen Filter.",
    noTxMatchingWithReportRefreshRunning: () =>
      "Wir aktualisieren derzeit Ihre Transaktionen. Danach werden möglicherweise weitere Transaktionen gefunden.",
    none: () => "Keiner",
    of: () => "von",
    openInNewTab: () => "In neuem Tab öffnen",
    overallBalance: () => "Gesamtbilanz",
    pagination: {
      pageXofY: (d) => d.pageNum + " von " + d.totalPages,
    },
    plusMore: (d) => "+ " + d.count + " mehr",
    price: () => "Preis",
    quantity: () => "Menge",
    recentComments: () => "Neueste Kommentare",
    recentCommentsMore: (d) => "+ " + d.count + " mehr",
    remaining: () => "Übrig",
    rowsPerPage: () => "Zeilen pro Seite:",
    savedAddresses: {
      addError: () =>
        "Beim Speichern der Blockchain-Adresse ist ein Fehler aufgetreten",
      body: () =>
        "Dadurch wird die Adresse überall umbenannt, wo eine Transaktion von und zu dieser Adresse erfolgt.",
      deleteError: () =>
        "Beim Löschen der gespeicherten Blockchain-Adresse ist ein Fehler aufgetreten",
      importWallet: () => "Wallet importieren",
      lengthError: () => "Der Name darf nicht länger als 64 Zeichen sein",
      nameAddress: () => "Name Adresse",
      placeholder: () => "Spitzname",
      title: () => "Name Adresse",
      updateError: () =>
        "Beim Aktualisieren der gespeicherten Blockchain-Adresse ist ein Fehler aufgetreten",
    },
    search: () => "Suchen",
    select: () => "Wählen",
    showing: () => "Es wird gezeigt: ",
    similarTxs: () => "Ähnliche Txs",
    smartSuggestion: () =>
      "Diese Transaktion wurde von unserer intelligenten Vorschlagsmaschine automatisch kategorisiert",
    sort: {
      feeAscending: () => "Gebühr - Niedrig bis Hoch",
      feeDescending: () => "Gebühr – absteigend",
      gainAscending: () => "Verstärkung – Niedrig bis Hoch",
      gainDescending: () => "Verstärkung – Hoch bis Niedrig",
      lastModifiedAscending: () => "Nach „Erstbearbeitung“ sortieren",
      lastModifiedDescending: () => "Nach „Zuletzt bearbeitet“ sortieren",
      newestFirst: () => "Neueste",
      oldestFirst: () => "Älteste",
      uncategorisedSizeAscending: () => "nicht kategorisiertGrößeAufsteigend",
      uncategorisedSizeDescending: () => "nicht kategorisiertGrößeAbsteigend",
      uncategorisedValueAscending: () => "nicht kategorisiertValueAscending",
      uncategorisedValueDescending: () => "nicht kategorisiertValueDescending",
      valueAscending: () => "Wert – niedrig bis hoch",
      valueDescending: () => "Wert – Hoch bis Niedrig",
    },
    source: () => "Quelle",
    sourceFilter: {
      exchanges: () => "Börsen",
      other: () => "Andere",
      wallets: () => "Vermögenswerte",
    },
    spamBulkTransactionsConfirmationTitle: () =>
      "Transaktionen als Spam markieren",
    spamTransactionConfirmationTitle: () => "Transaktion als Spam markieren",
    time: () => "Datum",
    title: () => "Transaktionen",
    to: () => "Eingehend",
    tooltipGain: (d) => "Gesamtgewinn von " + d.totalGain,
    tooltipLoss: (d) => "Gesamtverlust von " + d.totalLoss,
    total: () => "Gesamt",
    totalFiltered: () => "Gesamtzahl der gefilterten Transaktionen",
    totalTxCount: (d) => d.totalCount + " Sendungen",
    totalTxs: () => "Gesamtzahl der Transaktionen",
    trade: () => "Kategorie",
    transaction: () => "Transaktion",
    transactionId: () => "Tx-ID",
    transactions: () => "Transaktionen: ",
    txRow: {
      balanceRemaining: (d) => "Verbleibender Saldo ( " + d.displayName + " ):",
      balanceRemainingOverall: () => "Verbleibender Saldo (insgesamt):",
      cost: () => "Kosten",
      erp: {
        categoriseToSync: () =>
          "Kategorisieren Sie diese Transaktion zur Synchronisierung",
        mapAllAccounts: () => "Vervollständigen Sie alle Kontozuordnungen",
        provideDefaultAccountMappings: () =>
          "Bereitstellung von Standardkontozuordnungen",
        reportFailed: () =>
          "Transaktionen können nicht synchronisiert werden, während die Berechnungen fehlgeschlagen sind",
        resolveAllWarnings: () => "Beheben Sie alle Transaktionswarnungen",
        status: {
          NOT_READY_TO_SYNC: (d) =>
            "Nicht bereit zur Synchronisierung mit " + d.erpProvider,
          SYNC_FAILED: (d) =>
            "Synchronisierung mit " + d.erpProvider + " fehlgeschlagen.",
          SYNC_IN_PROGRESS: (d) =>
            "Synchronisierung mit " + d.erpProvider + " läuft",
          SYNC_NOT_REQUESTED: (d) =>
            "Mit " + d.erpProvider + " synchronisieren",
          SYNC_NO_OP: (d) =>
            "Keine Journale zum Synchronisieren mit " + d.erpProvider,
          SYNC_OUT_OF_DATE: (d) =>
            "Erneute Synchronisierung mit " + d.erpProvider,
          SYNC_SUCCESSFUL: (d) => "Mit " + d.erpProvider + " synchronisiert",
        },
        viewJournal: () => "Tagebuch lesen",
        viewPreviousJournal: () => "vorheriges Journal anzeigen",
        waitForCalculationsToFinish: () =>
          "Bitte warten Sie, bis Ihre Berechnungen abgeschlossen sind",
      },
      feeValue: () => "Gebührenwert von",
      lockedRowTooltip: {
        action: () => "Einstellungen für Sperrtransaktionen anzeigen",
        text: () => "Sie können einzelne Transaktionen nicht entsperren",
      },
      moreOptions: {
        generateMatchingTransfer: () => "Matching generieren",
      },
      noFees: () => "Keine Gebühren bezahlt",
      overflowCurrencies: (d) => "+ " + d.count + " mehr...",
      prefix: {
        exchange: () => "Austausch",
        from: () => "Quelle",
        fromTo: () => "Quelle/Ziel",
        to: () => "Ziel",
      },
      similarCount: (d) =>
        plural(d.count, 0, de, {
          one: " 1 tx ",
          other: number("de", d.count, 0) + " txs ",
        }),
      similarValue: (d) => d.symbol + d.value + " gesamt",
      singleSideLabels: {
        from: () => "Quelle:",
        to: () => "Ziel:",
      },
      smartContract: () => "Smart Contract",
      tooltip: () => "Uhrzeit der Transaktion (in Ihrer lokalen Zeitzone)",
      totalTransactionValue: () => "Gesamttransaktionswert",
    },
    type: () => "Kategorie",
    typeUpdateWarningModal: {
      transferSmartContract: {
        change: () => "Kategorie ändern",
        contract: () => "Vertrag",
        ignore: () => "Ignorieren",
        receiveFromAction: () =>
          "Bitte wählen Sie eine aussagekräftigere Kategorie, um Transaktionen mit Smart Contracts richtig zu klassifizieren.",
        receiveFromInfo: () =>
          "Diese Transaktion erfolgt über einen Smart Contract. Die Kategorie „Empfangen“ sollte nur verwendet werden, wenn Sie von einem anderen Wallet empfangen, das Ihnen gehört.",
        receiveFromTitle: () => "Empfangen von einem Smart Contract",
        sendToAction: () =>
          "Bitte wählen Sie eine aussagekräftigere Kategorie, um Transaktionen mit Smart Contracts richtig zu klassifizieren.",
        sendToInfo: () =>
          "Diese Transaktion erfolgt über einen Smart Contract. Die Kategorie „Senden“ sollte nur beim Senden an Ihr eigenes Wallet verwendet werden.",
        sendToTitle: () => "Senden an einen Smart Contract",
      },
    },
    uncategorised: () => "Unkategorisiert",
    unknownAccountWarning: {
      CTA: (d) =>
        "Wenn das Konto, das Sie " +
        select(d.side, {
          from: " Empfangen von ",
          to: " Senden an ",
          other: " Interagieren mit ",
        }) +
        " Ihnen gehört, [importieren Sie es]( " +
        d.linkTextKey +
        " ). Die Transaktion wird dann automatisch korrekt als „Überweisung“ klassifiziert.",
      body: (d) =>
        "Die " +
        select(d.side, {
          from: " Senden ",
          to: " Empfang ",
          other: " unbekannt ",
        }) +
        " Adresse für diese Transaktion wurde nicht von " +
        select(d.isBlockchain, {
          true: " Blockchain ",
          false: " Austausch ",
          other: " Account ",
        }) +
        " bereitgestellt.",
      subTitle: (d) =>
        "Handelt es sich hierbei um eine " +
        select(d.side, {
          from: " von einem anderen ",
          to: " zu einem anderen ",
          other: " mit einem unbekannten ",
        }) +
        " Krypto-Konto, das Sie verwenden?",
      title: (d) =>
        select(d.side, {
          from: " Eingehende Transaktion von einem unbekannten Absender ",
          to: " Ausgehende Transaktion an einen unbekannten Empfänger ",
          other: " Transaktion mit einer unbekannten Partei ",
        }),
      tooltip: (d) =>
        select(d.side, {
          from: " Unbekannter Absender ",
          to: " Unbekannter Empfänger ",
          other: " Unbekannte Partei ",
        }),
    },
    updatePrice: () => "Preis aktualisieren",
    updateValue: () => "Wert aktualisieren",
    value: () => "Wert",
    viewAddress: () => "Adressdetails anzeigen",
    viewAssociatedTransactionsModal: {
      exchange: () => "Austausch",
      import: (d) => "Wenn dies Ihr " + d.importType + " ist",
      smartContract: () => "Smart Contract",
      transactions: () => "Transaktionen:",
      type: () => "Typ:",
      wallet: () => "Wallet",
    },
    viewComments: () => "Kommentare anzeigen",
    viewLedger: () => "Währungsbuch",
    viewTx: () => "Transaktionsdetails anzeigen",
    warningLabels: {
      all: () => "Alle",
      missingBlockchain: () => "Fehlende Blockchain",
      missingPrice: () => "Fehlender Marktpreis",
      negativeBalance: () => "Fehlende Kaufhistorie",
      uncategorised: () => "Erfordert Kategorisierung",
      unknownInOut: () => "Unbekannter Ein-/Ausgang",
      unmatchedTransfer: () => "Unübertroffene Übertragung",
      zeroCostBuy: () => "Kostenloser Kauf",
    },
    warnings: {
      bridgeTransferIn: () =>
        "Dies ist eine einseitige Bridge. Bitte ordnen Sie diese Transaktion der anderen Seite einer Bridge zu.",
      bridgeTransferOut: () =>
        "Dies ist eine einseitige Bridge. Bitte ordnen Sie diese Transaktion der anderen Seite einer Bridge zu.",
      bridgein: () =>
        "Dies ist eine einseitige Bridge. Bitte ordnen Sie diese Transaktion der anderen Seite einer Brücke zu.",
      bridgeout: () =>
        "Dies ist eine einseitige Bridge. Bitte ordnen Sie diese Transaktion der anderen Seite einer Bridge zu.",
      receive: () =>
        "Dieser Empfang stammt aus einer Brieftasche, die nicht importiert wurde, oder es fehlen Daten für die Sendeseite der Transaktion. \n\n Wenn diese Transaktion von einem Dritten stammt, wählen Sie eine andere entsprechende Kategorie aus. \n\n Wenn bei diesem Empfang die Sendeseite fehlt, können Sie nicht abgeglichene Überweisungen auf der Abstimmungsseite abgleichen.",
      send: () =>
        "Dieser Versand erfolgt an eine Brieftasche, die nicht importiert wurde oder für die Daten für die Empfangsseite der Transaktion fehlen. \n\n Wenn es sich um eine Transaktion an einen Dritten handelt, wählen Sie eine andere entsprechende Kategorie aus. \n\n Wenn bei diesem Senden die Empfangsseite fehlt, können Sie nicht abgeglichene Überweisungen auf der Abstimmungsseite abgleichen.",
      uncategorized: (d) =>
        'Befolgen Sie unsere <a href="' +
        d.link +
        '" target="_blank"> Tipps </a> um mehr Kontext zu dieser Transaktion zu erhalten.',
      uncategorizedReview: () =>
        "Kategorisieren Sie diese Transaktion, um die Berichtsgenauigkeit zu verbessern",
    },
  },
  uncategorisedModal: {
    actionTooltip: () =>
      "Transaktionen in der Gruppe müssen einer Kategorie zugeordnet sein",
    cancel: () => "Stornieren",
    moreOptions: () => "Weitere Optionen",
    save: () => "Speichern",
    subTitle: () => "Transaktionen in der Gruppe:",
    title: () => "Gruppe „Kategorisieren“",
  },
  uncategorisedToggles: () => "Nicht kategorisierte Transaktionsbehandlung",
  unknownInOut: {
    url: () =>
      "https://help.cryptotaxcalculator.io/en/articles/5279619-categorise-transactions",
  },
  update: () => "Aktualisieren",
  userArchive: {
    failedToRestore: () =>
      "Wiederherstellung archivierter Daten fehlgeschlagen",
    restoreData: () => "Stellen Sie Ihre Daten wieder her",
    title: () => "Willkommen zurück!",
  },
  viewAssociatedTransactions: () => "Zugehörige Transaktionen anzeigen",
  wallet: {
    address: (d) => "Geben Sie die Wallet-Adresse " + d.source + " ein",
    addresses: () => "Wallet-Adressen",
    bulk: () => "Fügen Sie hier Ihre öffentlichen Adressen ein",
    bulkError: () => "Wallet-Adressen können nicht analysiert werden",
    bulkHelperText: () =>
      "Fügen Sie Ihre Wallet-Adressen genau so ein, wie sie aus der Coinbase Wallet-App exportiert wurden",
    deleteConfirmationText: (d) =>
      "Möchten Sie das " +
      d.wallet +
      ' -Wallet " ' +
      d.walletAddress +
      ' " wirklich entfernen? Dadurch werden auch ALLE mit diesem Wallet verknüpften Transaktionen gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden.',
    editDetails: () => "Wallet-Details bearbeiten",
    editingSubmit: () => "Wallet-Namen aktualisieren",
    equivWallet: (d) =>
      "Dieses Wallet verwendet die " +
      d.name +
      " -Blockchain zur Synchronisierung. Nach der Synchronisierung können Sie Ihre verbundenen Wallets im Bereich „ " +
      d.name +
      " “ auf dieser Seite anzeigen.",
    existingSubmit: (d) => "Zusätzliche " + d.source + " -Wallet hinzufügen",
    hardSync: {
      beginningOfTime: () => "der Beginn der Zeit",
      confirmationOneWallet: () => "Harte Synchronisierung der Brieftasche?",
      lastLockedPeriod: () => "(das Enddatum Ihres letzten Sperrzeitraums)",
      syncedFromDate: () =>
        "(das Datum, ab dem diese Brieftasche synchronisiert wird)",
      textOneWallet: (d) =>
        "Bei der Hard-Synchronisation werden alle Transaktionen für " +
        d.name +
        " -Wallet ab " +
        d.date +
        " importiert. Dies kann manchmal zu doppelten Transaktionen führen, die manuell verwaltet werden müssen, falls sich die Integration seit Ihrer letzten Synchronisierung geändert hat.",
      titleOneWallet: (d) =>
        "Alle Transaktionen für " +
        d.name +
        " -Wallet ab " +
        d.date +
        " erneut synchronisieren",
    },
    hardSyncWarning: (d) =>
      "Die Hard-Synchronisierung von " +
      d.name +
      " -Wallets, die vor dem 5. Mai 2022 erstellt wurden, führt aufgrund der jüngsten Änderungen zu doppelten Transaktionen. Wir empfehlen Benutzern, die vor diesem Datum " +
      d.name +
      " -Wallets erstellt haben, entweder ihr Wallet zu löschen und erneut zu importieren oder die Hard-Synchronisierung ihrer Wallets zu vermeiden.",
    importRelatedWallets: {
      actionTitle: () => "EVM-Ketten scannen",
      actionTooltip: () =>
        "Suchen Sie in allen EVM-kompatiblen Ketten nach Transaktionen, die mit dieser Wallet-Adresse verknüpft sind.",
      noResults: () => "Keine zugehörige Aktivität gefunden.",
      syncError: () => "Fehler beim Abrufen der zugehörigen Wallets",
      syncSuccess: () =>
        "Es wurden Transaktionen auf mehreren Blockchains erkannt. Diese werden nun importiert.",
      title: () => "EVM-Ketten scannen",
      tooltipOff: () =>
        "Derzeit werden Transaktionen auf verwandten Ketten nicht importiert",
      tooltipOn: () =>
        "Derzeit werden Transaktionen auf verwandten Ketten importiert",
    },
    importRules: {
      addressAlreadyExistsError: () =>
        "Diese Wallet-Adresse wurde bereits importiert.",
      bulkEmpty: (d) =>
        "Sie müssen Ihre Liste mit öffentlichen " +
        d.name +
        " -Adressen eingeben.",
      bulkExisting: (d) =>
        "Eine der von Ihnen eingegebenen " +
        d.name +
        " -Adressen wurde bereits hinzugefügt.",
      bulkInvalid: (d) =>
        "Eine der von Ihnen eingegebenen Adressen ist keine gültige " +
        d.name +
        " -Wallet-Adresse.",
      empty: (d) =>
        "Sie müssen Ihre öffentliche " + d.name + " -Wallet-Adresse eingeben.",
      existing: (d) => d.name + " Wallet-Adresse bereits hinzugefügt.",
      invalid: (d) => "Keine gültige " + d.name + " -Wallet-Adresse.",
      invalidAddressError: () =>
        "Dies ist keine gültige Wallet-Adresse für diese Importquelle.",
      invalidBulkBlockchain: (d) =>
        "Ungültiger Blockchain-Wert „ " + d.chain + " “.",
      invalidEnsError: () => "Der ENS-Name konnte nicht aufgelöst werden.",
      invalidSnsError: () => "SNS-Name konnte nicht aufgelöst werden.",
      tooManyWallets: (d) =>
        "Sie können maximal " +
        d.maxWalletImports +
        " Wallets gleichzeitig importieren.",
      tooManyWalletsError: () =>
        "Dieses Wallet überschreitet die maximale Anzahl an Wallets, die Sie gleichzeitig importieren können.",
    },
    invalid: () =>
      "Ungültiger Wallet-Name, bitte verwenden Sie nicht die Zeichen: $ ; \" ' `",
    name: () => "Wallet-Name",
    nameRequired: (d) =>
      "Bitte geben Sie einen Namen für Ihr " + d.walletName + " -Wallet ein",
    nickname: () => "Spitzname (optional)",
    nicknameCanBeAddedLater: () =>
      "Sie können diese Wallets nach dem Import mit Spitznamen versehen",
    relatedChains: {
      body: (d) => d.chains,
      title: () => "Verwandte EVM-Ketten:",
    },
    safetyInfo: {
      frontPart: () => "Wir bitten Sie um ",
      highlightPart: () => "nur öffentliche Wallet-Adresse",
      lastPart: () =>
        ". Wir werden niemals private Schlüssel anfordern und können keine Gelder überweisen.",
    },
    softSync: {
      action: () => "Soft-Sync",
      title: (d) => "Synchronisiere " + d.name + " Wallet-Transaktionen von",
    },
    submit: (d) => "Importieren Sie " + d.source + " -Brieftasche",
    submitMultiple: () => "Wallets importieren",
    title: () => "Wallet-Daten importieren",
    type: () => "Blockchain-Typ",
    walletNicknameUpdate: () => "Wallet-Spitzname erfolgreich aktualisiert",
    wentWrongSavingWalletData: () =>
      "Beim Speichern der Wallet-Daten ist ein Fehler aufgetreten.",
    wentWrongSyncingWalletData: () =>
      "Beim Synchronisieren der Wallet-Daten ist ein Fehler aufgetreten.",
  },
  warningDisplayName: {
    all: () => "Alle",
    missingBlockchain: () => "Fehlende Blockchain",
    missingPrice: () => "Fehlender Marktpreis",
    negativeBalance: () => "Fehlende Kaufhistorie",
    suspectedAirdropTransaction: () => "Verdächtige Airdrop-Transaktion",
    uncategorised: () => "Unkategorisiert",
    unknownInOut: () => "Vermutlich fehlender Import",
    zeroCostBuy: () => "Kostenloser Kauf",
  },
  warnings: {
    missingBlockchain: () =>
      "Wir konnten die Blockchain für diese Transaktion nicht ermitteln. Dies liegt häufig daran, dass dieselbe Wallet-Adresse auf mehreren Blockchains vorhanden ist. \n\n Weisen Sie die Blockchain zu, um diese Warnung zu beheben.",
    missingPrice: (d) =>
      "Wir haben an diesem Datum keinen Marktpreis für das Token " +
      d.symbol +
      " , daher wurde ein Preis von Null angenommen. Navigieren Sie zum Preisdiagramm dieses Tokens und prüfen Sie, wie hoch der Preis an diesem bestimmten Datum war. Bearbeiten Sie dann die Transaktion, um den Preis auf diesen Wert zu aktualisieren. Dies sollte die Warnung beheben.",
    negativeBalance: (d) =>
      select(d.balanceRemaining, {
        true:
          " Durch diese Transaktion wurde Ihr Gesamtsaldo von " +
          d.symbol +
          " negativ, sodass nun ein Restsaldo von " +
          d.balanceTotal +
          " " +
          d.symbol +
          " verbleibt. \n\n Haben Sie " +
          d.symbol +
          " von einer Börse oder einer anderen Quelle gekauft? Dies ist wahrscheinlich das fehlende Teil. Stellen Sie sicher, dass Sie die Transaktionsdaten aus allen Quellen importieren, von denen Sie " +
          d.symbol +
          " gekauft haben. Dies sollte das Problem lösen. ",
        other: "",
      }),
    uncategorised: (d) =>
      "Diese Transaktion verwendet die Kategorie " +
      select(d.trade, {
        in: " Standardkategorie „Unbekannter Eingang“, was zu ungenauen Salden führen kann ",
        out: " Standardkategorie „Unbekannter Ausgang“, was einen Kapitalgewinn auslösen kann ",
        other: " Kategorie „Unbekannt“, die geändert werden muss ",
      }) +
      " . Wenn Sie alle Ihre Börsen und Wallets importieren, sollte unser Algorithmus in der Lage sein, dies automatisch zu kategorisieren. Andernfalls fehlen möglicherweise Daten. In diesem Fall können Sie in der Dropdown-Liste manuell eine Kategorie auswählen.",
    unknownInOut: () =>
      "Vermögenswerte zwischen Ihren eigenen Wallets transferiert? Möglicherweise haben Sie vergessen, die Daten aus einem dieser Wallets zu importieren. \n\n Wenn es sich um eine Transaktion zwischen Ihnen und einem externen Wallet handelt, können Sie in der Dropdown-Liste links die Kategorie auswählen, die diese Transaktion am besten beschreibt (z. B. erhaltenes Geschenk, Staking-Belohnungen, Airdrop usw.). \n\n Sie können diese Adresse auch benennen, um zukünftige Warnungen bezüglich fehlender Importe für diese Adresse zu beheben.",
    zeroCostBuy: () =>
      "Es ist kein Guthaben vorhanden, um diese Transaktion abzudecken. Daher wurde ein Kauf ohne Kosten (was zu einer Kostenbasis von Null führt) angenommen. Dies kann dazu führen, dass Ihre berechneten Gewinne höher sind als sie sollten. Korrigieren Sie alle vorherigen Warnungen zum fehlenden Kaufverlauf, um dieses Problem zu beheben.",
  },
  warningsDropdown: {
    missingImportData: {
      description: () =>
        "Ohne alle Datenquellen können wir Ihren Gesamtsaldo nicht präzise wiedergeben.",
      title: () => "Fehlende Importdaten",
    },
  },
  was: () => "War",
  watchVideo: () => "Video ansehen",
  worksWith: () => "Funktioniert mit",
  xero: {
    accountMapping: {
      accounts: {
        asset: {
          title: () => "Vermögenskonto",
          tooltip: () =>
            "Konto, das den Wert der Vermögenswerte im Portfolio des Benutzers darstellt",
        },
        cash: {
          title: () => "Kassenkonto",
          tooltip: () =>
            "Konto, das Bargeldbestände darstellt. Wenn Sie Krypto verkaufen, stellen wir den Barerlös hier dar und umgekehrt, wenn Sie Krypto kaufen. Krypto-zu-Krypto-Transaktionen haben keine Auswirkungen auf dieses Konto.",
        },
        expense: {
          title: () => "Aufwandskonto",
          tooltip: () => "Konto, das Kryptoausgaben darstellt",
        },
        fee: {
          title: () => "Gebührenkonto",
          tooltip: () =>
            "Konto, auf dem der gezahlte Gebührenbetrag angegeben ist",
        },
        income: {
          title: () => "Ertragskonto",
          tooltip: () => "Konto, das Einkünfte aus Kryptowährungen darstellt",
        },
        loan: {
          title: () => "Darlehenskonto",
          tooltip: () => "Konto für Kryptokredite und -rückzahlungen",
        },
        loss: {
          title: () => "Verlustrechnung",
          tooltip: () => "Konto, das den verlorenen Kryptobetrag darstellt",
        },
        "realised-gain": {
          title: () => "Realisiertes Gewinnkonto",
          tooltip: () =>
            "Konto, das Gewinne aus der Veräußerung von Krypto-Assets darstellt",
        },
        "realised-loss": {
          title: () => "Realisiertes Verlustkonto",
          tooltip: () =>
            "Konto, das Verluste darstellt, die durch die Veräußerung von Krypto-Assets entstanden sind",
        },
        "zero-cost-buy": {
          title: () => "Fehlendes Kaufhistorie-Konto",
          tooltip: () =>
            "Wenn ein Vermögenswert gekauft oder erworben wird und keine Zahlung dafür geleistet wird, betrachten wir die Kostenbasis des Vermögenswerts als Marktwert und dieses Konto wird verwendet, um die andere Seite des Journals auszugleichen.",
        },
      },
      lastSynced: (d) => "Zuletzt synchronisiert am " + d.date,
      refreshInstructions: () =>
        "Wenn Sie ein neues Konto bei Xero erstellt haben und es nicht sehen, aktualisieren Sie die Liste.",
      syncError: () => "Wir konnten die Daten nicht synchronisieren.",
      syncInstructions: () =>
        "Um mit der Synchronisierung zu beginnen, müssen Sie auswählen, welchen Konten die Daten zugeordnet werden. Sie können die Konten jederzeit aktualisieren. Die Synchronisierung kann gestartet werden, wenn die Zuordnung festgelegt ist.",
      title: () => "Kontozuordnung",
    },
    buttons: {
      disconnect: () => "Von Xero trennen",
      refreshList: {
        completed: () => "Xero-Kontenliste aktualisiert",
        done: () => "Kontoliste aktualisieren",
        pending: () => "Liste aktualisieren",
        tooltip: {
          done: () => "Verfügbare Konten aktualisieren",
          pending: () => "Dies kann einige Zeit dauern",
        },
      },
      syncAccounts: {
        done: () => "Konten synchronisieren",
        pending: () => "Konten synchronisieren",
        tooltip: {
          disabled: () =>
            "Stellen Sie sicher, dass jedes Konto ausgewählt ist, um die Synchronisierung zu aktivieren",
          done: () => "Konten mit Xero synchronisieren",
          pending: () => "Dies kann einige Zeit dauern",
        },
      },
    },
    connect: () => "Mit Xero verbinden",
    errors: {
      disconnecting: () =>
        "Beim Trennen der Verbindung zu Xero ist ein Fehler aufgetreten.",
      fetchingAccounts: () =>
        "Beim Abrufen der Xero-Kontenliste ist ein Fehler aufgetreten.",
      fetchingSettings: () =>
        "Beim Abrufen der Xero-Einstellungen ist ein Fehler aufgetreten.",
      fetchingTenants: () =>
        "Beim Abrufen der Xero-Mieterliste ist ein Fehler aufgetreten.",
      updatingAccounts: () =>
        "Beim Aktualisieren der Xero-Konten ist ein Fehler aufgetreten.",
      updatingTenant: () =>
        "Beim Aktualisieren des Xero-Mandanten ist ein Fehler aufgetreten.",
    },
    integrationName: () => "Xero",
    integrationShortName: () => "Xero",
    newIntegration: {
      connected: () => " Verbunden",
      connectedAt: (d) => "Zuletzt verbunden " + d.date,
      description: () =>
        "Fassen Sie tägliche Handelsaktivitäten in Buchhaltungsjournalen in Xero zusammen. Dies kann Ihnen dabei helfen, den Überblick über Ihre Finanzen zu behalten und Einnahmequellen, Verluste und Ausgaben zu verstehen. Die Kontenzuordnungen können an Ihre Buchhaltungsgewohnheiten angepasst werden.",
      disconnectConfirmation: () =>
        "Achtung! Möchten Sie Ihr Xero-Konto wirklich trennen? Diese Aktion kann nicht rückgängig gemacht werden. Sie müssen Ihre Konten in Zukunft erneut auswählen. Ihre synchronisierten Journale bleiben in Xero.",
      disconnected: () => "Getrennt",
      status: () => "Status: ",
      title: () => "Xero-Konto verbinden",
    },
    reportFooter: {
      settingsLink: () =>
        "Weitere Informationen finden Sie in den Einstellungen.",
      subTitle: () => "Verbinden Sie den Krypto-Steuerrechner mit Xero",
      title: () => "Xero-Integration",
    },
    rollup: {
      day: () => "Tag",
      description: {
        day: () =>
          "Alle Transaktionen eines Kalendertages werden in einem einzigen Journal zusammengefasst.",
        month: () =>
          "Alle Transaktionen eines Kalendermonats werden in einem einzigen Journal zusammengefasst.",
        none: () =>
          "Alle Transaktionen werden als einzelne Journale exportiert",
      },
      month: () => "Monat",
      none: () => "Kein Rollup",
    },
    syncDialog: {
      confirmation: () =>
        "Möchten Sie wirklich synchronisieren? Bitte stellen Sie sicher, dass Ihre Daten korrekt sind. Änderungen an Transaktionen, die bereits synchronisiert wurden, werden in Xero nicht angezeigt.",
      error: () => "Oh nein! Es scheint, als wäre etwas schiefgelaufen....",
      syncing: () =>
        "Wir synchronisieren gerade die Daten, dies kann einige Zeit dauern ...",
    },
    tenantList: {
      ensureCorrectTenant: () =>
        "Stellen Sie sicher, dass für den Datenfeed das richtige Konto ausgewählt ist.",
      exportJournals: () => "Journale exportieren",
      listPlaceholder: () => "Datenfeed auswählen",
      listTitle: () => "Xero-Konto",
    },
  },
  yes: () => "Ja",
};
