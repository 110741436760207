import { type Plan } from "@ctc/types";

import { type PlanDataBase } from "~/types/index";

export function getAlwaysAllowPaymentPlanType(
  plans: Partial<Record<Plan, PlanDataBase>>,
) {
  for (const [planType, planData] of Object.entries(plans)) {
    if (planData?.paywallLimits?.alwaysAllowPurchase) {
      return planType as Plan;
    }
  }

  return undefined;
}
