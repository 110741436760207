import { Typography } from "@mui/material";
import styled from "styled-components/macro";



export function Breaker({
  text,
  background,
}: {
  text: string;
  background?: string;
}) {
  return (
    <Container background={background}>
      <span className="text">
        <Typography variant="Metropolis/Caption/Medium/Regular">
          {text}
        </Typography>
      </span>
    </Container>
  );
}

const Container = styled.div<{ background?: string }>`
  position: relative;
  text-align: center;
  width: 100%;
  margin: 0.5rem 0;
  
  &::before {
    content: "";
    position: absolute;
    height: 1px;
    top: 50%;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.tokens.border.neutral.default};
    z-index: 0;
  }
  
  .text {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1rem;
    color: ${({ theme }) => theme.tokens.text.low};
    text-align: center;
    background: ${({ background, theme }) =>
      background || theme.tokens.background.neutral.default};
    padding: 0 0.625rem;
    position: relative;
    z-index: 1;
    display: inline-block;
  }
`;
