import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { type PropsWithChildren, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import background from "~/assets/ctc-tile-background.svg";
import logoDark from "~/assets/logo-reversed.svg";
import { Spinner } from "~/components/ui/Spinner";
import { LocalStorageKey } from "~/constants/enums";
import { useDesign } from "~/hooks/useTheme";
import { invariant } from "~/lib/invariant";
import { useLang } from "~/redux/lang";
import * as invitationsAPI from "~/services/invitations";
import { inviteKeys } from "~/state/invites";
import { Links } from "~/types/enums";

export const getCodeDetails =
  (code: string, onError?: (res: any) => void) => async () => {
    const res = await invitationsAPI.getInviteCodeDetails(code);
    if (res.error) {
      if (onError) {
        onError(res);
        return null;
      }

      throw new Error(res.msg);
    }
    return res.data;
  };

export const InviteLinkPage = () => {
  const { code } = useParams<{ code: string }>();
  // TODO: test if this code must be defined in our app
  invariant(code, "Code must be defined");
  const { tokens } = useDesign();
  const lang = useLang();
  const navigate = useNavigate();

  const codeDetails = useQuery({
    queryKey: inviteKeys.details(),
    queryFn: getCodeDetails(code, (res) => {
      if (res.status === 404) {
        localStorage.removeItem(LocalStorageKey.InviteCode);
        navigate(Links.Signup);
      } else {
        throw new Error(res.msg);
      }
    }),
    retry: 0,
  });

  useEffect(() => {
    if (code) localStorage.setItem(LocalStorageKey.InviteCode, code);
  }, [code]);

  if (codeDetails.isPending) return <Spinner />;

  const accountantName = codeDetails.data?.name;
  return (
    <BackgroundBox sx={{ background: tokens.background.brand }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "80vh",
            height: "80vh",
            maxWidth: 800,
            maxHeight: 800,
            background: "#7D3BED",
            opacity: 0.3,
            filter: "blur(280px)",
          }}
        />
      </Box>

      <Box
        sx={{
          maxWidth: "90vw",
          width: 520,
          height: 750,
          display: "flex",
          flexDirection: "column",
          maxHeight: "90vh",
          padding: "5vh 5vw",
        }}
      >
        <Card>
          <Box>
            <Logo />
            <Typography
              sx={{
                marginTop: 4,
                color: tokens.text.inverse,
                fontWeight: "bold",
                fontSize: "1.25rem",
              }}
            >
              {lang.auth.youreInvited}
              {accountantName ? "" : ` ${lang.auth.yourAccountant}`}
            </Typography>
            {accountantName ? (
              <Typography
                sx={{
                  color: tokens.text.inverse,
                  fontWeight: "bold",
                  fontSize: "1.75rem",
                  overflowWrap: "break-word",
                }}
              >
                {codeDetails.data?.name}
              </Typography>
            ) : null}
            <Typography sx={{ color: tokens.text.inverse, fontSize: "1rem" }}>
              <em>{lang.auth.toSignUp}</em>
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: 7,
              boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.3)",
              background: "rgba(255, 255, 255, 0.1)",
              backgroundBlendMode: "overlay",
              paddingX: 3,
              paddingY: 1.5,
            }}
          >
            <Typography
              sx={{
                color: tokens.text.inverse,
                fontSize: "0.875rem",
                opacity: 0.8,
              }}
            >
              {lang.auth.inviteReceived}
            </Typography>
            <Typography
              sx={{
                color: tokens.text.inverse,
                fontSize: "0.875rem",
                overflowWrap: "break-word",
              }}
            >
              {codeDetails.data?.email || <Skeleton sx={{ width: "5rem" }} />}
            </Typography>
          </Box>
        </Card>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          component={Link}
          style={{
            height: "3.5rem",
            marginTop: "2rem",
            borderRadius: "1.25rem",
          }}
          to={Links.Signup}
        >
          <Typography
            fontWeight={500}
            fontSize={16}
            color={tokens.text.inverse}
          >
            {lang.auth.signup}
          </Typography>
        </Button>
      </Box>
    </BackgroundBox>
  );
};

export const Logo = ({ width = "11.25rem" }: { width?: string }) => {
  return (
    <Box position="relative">
      <img style={{ width }} src={logoDark} alt="Crypto Tax Calculator Logo" />
    </Box>
  );
};

const BackgroundBox = styled(Box)`
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.tokens.special.lowest};
  background-image: url(${background});
  background-position: bottom right;
  background-repeat: no-repeat;
`;

const Card = ({ children }: PropsWithChildren) => {
  const [transform, setTransform] = useState("");
  const [glowBackground, setGlowBackground] = useState("");
  const [lock, setLock] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const rotateToMouse = (e: MouseEvent) => {
      if (!cardRef.current) return;

      const bounds = cardRef.current.getBoundingClientRect();
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const leftX = mouseX - bounds.x;
      const topY = mouseY - bounds.y;
      const center = {
        x: leftX - bounds.width / 2,
        y: topY - bounds.height / 2,
      };
      const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

      setTransform(`
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance) * 2}deg
      )
    `);

      setGlowBackground(`
      radial-gradient(
        circle at
        ${center.x * 2 + bounds.width / 2}px
        ${center.y * 2 + bounds.height / 2}px,
        #ffffff55,
        #0000000f
      )
    `);
    };

    const handleMouseLeave = () => {
      setLock(true);
    };

    const handleMouseEnter = () => {
      setLock(false);
    };

    const current = cardRef.current;

    if (current) {
      current.addEventListener("mouseleave", handleMouseLeave);
      current.addEventListener("mouseenter", handleMouseEnter);
    }

    document.addEventListener("mousemove", rotateToMouse);

    return () => {
      if (current) {
        current.removeEventListener("mouseleave", handleMouseLeave);
        current.removeEventListener("mouseenter", handleMouseEnter);
      }
      document.removeEventListener("mousemove", rotateToMouse);
    };
  }, []);

  return (
    <div
      ref={cardRef}
      style={{
        fontWeight: "bold",
        padding: "1rem",
        color: "#181a1a",
        borderRadius: "1.25rem",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: 1,
        maxHeight: 600,
        boxShadow: transform
          ? "0 5px 20px 5px #00000044"
          : "0 1px 5px #00000099",
        background: "#5F19DB",
        position: "relative",
        transitionDuration: "300ms",
        transitionProperty: "transform, box-shadow",
        transitionTimingFunction: "ease-out",
        transform: lock ? "" : transform,
      }}
    >
      <div
        className="glow"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          backgroundImage:
            glowBackground ||
            "radial-gradient(circle at 50% -20%, #ffffff22, #0000000f)",
        }}
      />
      {children}
    </div>
  );
};
