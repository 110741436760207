import { Country, ReportFormat } from "@ctc/types";
import { useContext } from "react";

import { type FinancialYear, FYContext } from "~/contexts/FYContext";
import { type Translation } from "~/lang/index";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  FrenchReportType,
  GermanReportType,
  IrsReportDownloadType,
  NormalReportType,
  OtherReportType,
  SpanishReportType,
  TaxActReportType,
  TurboTaxReportType,
} from "~/types/enums";
import { type ReportDownloadType } from "~/types/index";

export type ReportItem = {
  type: ReportDownloadType;
  actions: {
    buttonText: string;
    format: ReportFormat;
    menuText?: string;
    disabled?: boolean;
  }[];
  logoName?: string;
  title?: string;
  description?: string;
  queryParams?: Record<string, string>;
};

export function getValidIRSFormsForYear(
  timeframe: FinancialYear,
): IrsReportDownloadType[] {
  const validForms = [];
  if (timeframe.year && timeframe.year >= 2013) {
    validForms.push(
      ...[
        IrsReportDownloadType.IRS_8949,
        IrsReportDownloadType.IRS_8949_CONSOLIDATED,
      ],
    );
  }
  if (timeframe.year && timeframe.year >= 2018) {
    // This form was added in 2018
    validForms.push(IrsReportDownloadType.IRS_1040S1);
  }
  if (timeframe.year && timeframe.year >= 2017) {
    validForms.push(IrsReportDownloadType.IRS_1040SD);
  }
  return validForms;
}

const REPORTS_WITHOUT_CSV = [
  NormalReportType.Audit,
  NormalReportType.MyGov,
  NormalReportType.NftReport,
  NormalReportType.StartOfYearHoldings,
  SpanishReportType.Modelo100,
];
const REPORTS_WITHOUT_PDF: NormalReportType[] = [];

export function getActionsForReportType(
  lang: Translation,
  reportType: NormalReportType,
): ReportItem["actions"] {
  const actions = [];
  if (!REPORTS_WITHOUT_PDF.includes(reportType)) {
    actions.push({
      buttonText: lang.report.pdf,
      format: ReportFormat.PDF,
      menuText: lang.report.pdf,
    });
  }
  if (!REPORTS_WITHOUT_CSV.includes(reportType)) {
    actions.push({
      buttonText: lang.report.csv,
      format: ReportFormat.CSV,
      menuText: lang.report.csv,
    });
  }
  return actions;
}

export function getStandardReports(lang: Translation): ReportItem[] {
  return Object.values(NormalReportType)
    .map((reportType) => ({
      type: reportType,
      actions: getActionsForReportType(lang, reportType),
    }))
    .filter(
      // we add it later
      (reportItem) =>
        ![
          NormalReportType.TradingStockAu,
          NormalReportType.MyGov,
          NormalReportType.NftReport,
        ].includes(reportItem.type),
    );
}

export function getReportsForCountry(
  lang: Translation,
  country: Country,
  timeframe: FinancialYear,
): ReportItem[] {
  const pdfDownloadActions = [
    {
      buttonText: lang.report.pdf,
      format: ReportFormat.PDF,
      menuText: lang.report.pdf,
    },
    {
      buttonText: lang.report.csv,
      format: ReportFormat.CSV,
      menuText: lang.report.csv,
    },
  ];

  const allUserReports: ReportItem[] = [];

  if (country === Country.Australia) {
    // put mygov first
    allUserReports.push({
      type: NormalReportType.MyGov,
      actions: getActionsForReportType(lang, NormalReportType.MyGov),
    });
  }

  if (country === Country.USA) {
    const irsActions = [
      {
        buttonText: lang.report.download.buttonIrs,
        format: ReportFormat.PDF,
      },
    ];
    const reports =
      !timeframe.custom &&
      getValidIRSFormsForYear(timeframe).map((type) => ({
        type,
        format: ReportFormat.PDF,
        actions: irsActions,
      }));

    if (reports && reports.length > 0) {
      allUserReports.push(...reports);
    }

    allUserReports.push(
      ...[
        {
          type: TurboTaxReportType.TurboTaxCsv,
          actions: [
            {
              buttonText: lang.report.csv,
              format: ReportFormat.CSV,
            },
          ],
          logoName: "turbotax",
        },
        {
          type: TurboTaxReportType.TurboTaxTxf,
          actions: [
            {
              buttonText: lang.report.txf,
              format: ReportFormat.TXF,
            },
          ],
          logoName: "turbotax",
        },
        {
          type: TaxActReportType.TaxActCsv,
          actions: [
            {
              buttonText: lang.report.csv,
              format: ReportFormat.CSV,
            },
          ],
        },
      ],
    );
  }

  if (country === Country.Canada) {
    allUserReports.push({
      type: TurboTaxReportType.CanadianTurboTaxPdf,
      actions: [
        {
          buttonText: lang.report.pdf,
          format: ReportFormat.PDF,
        },
      ],
      logoName: "turbotax",
    });
  }

  if (country === Country.Germany) {
    allUserReports.push(
      ...[
        {
          type: GermanReportType.AnlageSoOtherIncome,
          actions: [
            {
              buttonText: lang.report.pdf,
              format: ReportFormat.PDF,
            },
          ],
        },
        {
          type: GermanReportType.WisoSteuer,
          actions: [
            {
              buttonText: lang.report.csv,
              format: ReportFormat.CSV,
            },
          ],
        },
      ],
    );
  }
  if (country === Country.France) {
    const isSupportedYear = timeframe.year >= 2021;

    allUserReports.push({
      type: FrenchReportType.Form2086,
      actions: [
        {
          buttonText: lang.report.csv,
          format: ReportFormat.CSV,
        },
        ...(isSupportedYear
          ? [
              {
                buttonText: lang.report.pdf,
                format: ReportFormat.PDF,
              },
            ]
          : []),
      ],
    });
  }
  if (country === Country.Spain && timeframe.year >= 2022) {
    allUserReports.push({
      type: SpanishReportType.Modelo100,
      actions: [
        {
          buttonText: lang.report.pdf,
          format: ReportFormat.PDF,
        },
      ],
    });
  }

  // add the standard reports last
  allUserReports.push(...getStandardReports(lang));

  // So that the trading stock report is last
  if (country === Country.Australia) {
    allUserReports.push({
      type: NormalReportType.TradingStockAu,
      actions: pdfDownloadActions,
    });
  }

  // So that NFT report is last
  if (country === Country.Italy) {
    allUserReports.push({
      type: NormalReportType.NftReport,
      actions: getActionsForReportType(lang, NormalReportType.NftReport),
    });
  }

  // So that BGL report is last
  if (country === Country.Australia) {
    allUserReports.push({
      type: OtherReportType.BGL,
      actions: [
        {
          buttonText: lang.report.xml,
          format: ReportFormat.XML,
        },
      ],
    });
  }

  return allUserReports;
}

export function useReportsForCountry() {
  const lang = useLang();
  const country = useCountry();
  const { timeframe } = useContext(FYContext);
  if (!country || !timeframe) return [];

  return getReportsForCountry(lang, country, timeframe);
}
