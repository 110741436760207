import { get, post } from "~/services/core";
import { type FilterQuery, type LedgerData } from "~/types/index";

export function getLedgerData(currencyId: string, filter: FilterQuery) {
  const path = `/v2/ledger/${currencyId}`;
  return post<LedgerData>(path, filter);
}
export function getLedgerDataFilterOptions(currencyId: string) {
  const path = `/v2/ledger/${currencyId}/accounts`;
  return get<string[]>(path);
}
