import { type EventHandler, useEffect, useState } from "react";

/**
 * A hook that copies text to the clipboard.
 */
export const useCopyToClipboard = (
  content: string,
  handleClick?: () => void,
) => {
  const TOOLTIP_TIME = 3000; // Time the "copied" tooltip should stay open, in milliseconds
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timeout: number | undefined;
    if (copied) {
      timeout = window.setTimeout(() => {
        setCopied(false);
      }, TOOLTIP_TIME);
    }
    return () => {
      window.clearTimeout(timeout);
    };
  }, [copied]);

  const onClick: EventHandler<any> = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      await navigator.clipboard.writeText(content);
      setCopied(true);

      // handle any additional click events
      if (handleClick) {
        handleClick();
      }
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return {
    onClick,
    copied,
    setCopied,
  };
};
