import { get } from "~/services/core";
import { type ExchangeNames, type ExchangeOption } from "~/types/index";

export function getExchangeNames() {
  const path = "/exchanges/names";
  return get<ExchangeNames>(path);
}

/**
 * Gets all the exchanges which are available
 * the be selected by the user. This includes any to/from 'exchanges'
 * which the user has imported or added manually
 */
export function getAllExchangeOptions() {
  const path = "/exchanges/options";
  return get<ExchangeOption[]>(path);
}
