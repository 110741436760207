import { Box, Typography } from "@mui/material";

import { AnalyticEvent } from "~/analytics/analyticsProperties";
import { useCaptureAnalyticEvents } from "~/analytics/captureAnalyticEvents";
import { BulkEditAction } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { displayMessage } from "~/components/ui/Toaster";
import { useGetSuspectedMissingImports } from "~/hooks/useGetSuspectedMissingImports";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type SuspectedMissingImport } from "~/types";
import { type BulkUpdateStatus, DisplayMessage } from "~/types/enums";

export function useIgnoreSuspectedMissingImportOnSuccess() {
  const { tokens } = useDesign();
  const lang = useLang();
  const captureAnalytics = useCaptureAnalyticEvents();
  const suspectedMissingImports = useGetSuspectedMissingImports();

  return (
    data: {
      result: undefined | BulkUpdateStatus.AcceptedForProcessing;
      bulkEditId: string | undefined;
    },
    variables: {
      suspectedMissingImport: SuspectedMissingImport;
    },
  ) => {
    displayMessage({
      message: (
        <Box display="flex" gap="1rem" alignItems="center">
          <Box>
            <Typography
              variant="Metropolis/Body/Bold"
              color={tokens.text.success}
            >
              {lang.imports.suspectedMissingImports.toastHeading}
            </Typography>
            <Typography
              variant="Metropolis/Body/Regular"
              color={tokens.text.default}
            >
              {lang.imports.suspectedMissingImports.toastBody}
            </Typography>
          </Box>
          <Box>
            <BulkEditAction bulkEditId={data.bulkEditId} showUndoButton />
          </Box>
        </Box>
      ),
      type: DisplayMessage.Success,
      id: data.bulkEditId,
    });
    captureAnalytics(
      AnalyticEvent.IMPORTS.DISMISS_SUSPECTED_MISSING_IMPORT_SUGGESTION,
      {
        importId: variables.suspectedMissingImport.importOption.id,
        importName: variables.suspectedMissingImport.importOption.name,
        suspectedMissingImportsCount: suspectedMissingImports.length,
      },
    );
  };
}
