import qs from "query-string";

import { deleteRequest, get, post, put } from "~/services/core";
import type {
  AcceptInviteCode,
  CreateInvitationPayload,
  IndividualInvitations,
  InviteCodeDetails,
  TeamInvitationDetails,
  UpdateInvitationPayload,
  UpdateInviteCode,
} from "~/types/index";

enum Path {
  Invitations = "/invitations",
}

export function getInvitations() {
  const path = Path.Invitations;
  return get<IndividualInvitations>(path);
}

export function createInvitation(payload: CreateInvitationPayload) {
  const path = Path.Invitations;
  return post<boolean>(path, payload);
}

export function createSupportInvitation() {
  const path = `${Path.Invitations}/support`;
  const payload = {
    duration: 30,
  };
  // Assuming the backend knows to set the duration to 30 days by default
  return post<boolean>(path, payload);
}

export function updateInvitation(id: string, payload: UpdateInvitationPayload) {
  const path = `${Path.Invitations}/${id}`;
  return put<boolean>(path, payload);
}

export function deleteInvitation(id: string) {
  const path = `${Path.Invitations}/${id}`;
  return deleteRequest<boolean>(path);
}

export function revokeInvitationDirectly() {
  const path = `${Path.Invitations}`;
  return deleteRequest<boolean>(path);
}

export function updateInviteCode(inviteCode: string, automated: boolean) {
  const path = `${Path.Invitations}/code/update`;

  return post<UpdateInviteCode & { automated: boolean }>(path, {
    inviteCode,
    automated,
  });
}

export function getInviteCodeDetails(inviteCode: string) {
  const query = qs.stringify({ inviteCode });
  const path = `${Path.Invitations}/code/details?${query}`;

  return get<InviteCodeDetails>(path);
}

export function acceptInviteCode(inviteCode: string) {
  const path = `${Path.Invitations}/code/accept`;

  return post<AcceptInviteCode>(path, { inviteCode });
}

export function getTeam() {
  const path = `${Path.Invitations}/team`;
  return get<TeamInvitationDetails[]>(path);
}
