import type * as React from "react";

import { bulkEditAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { BulkEditAction } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { useBulkEditCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import { useNumSelectedOrFiltered } from "~/components/transactions/command-palette/hooks/useNumSelectedOrFiltered";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { displayMessage } from "~/components/ui/Toaster";
import { invariant } from "~/lib/invariant";
import { useLang } from "~/redux/lang";
import { useNewBulkIgnoreWarningsMutation } from "~/state/actions";
import { BulkUpdateStatus, DisplayMessage, Warning } from "~/types/enums";
import {
  type BulkIgnorableWarning,
  bulkIgnorableWarnings,
} from "~/types/index";

const isValidBulkIgnorableWarnings = (
  warnings: string[],
): warnings is BulkIgnorableWarning[] =>
  warnings.every((value) =>
    bulkIgnorableWarnings.includes(value as BulkIgnorableWarning),
  );

export const Confirmation = ({
  type,
  label,
  options,
}: {
  type: Warning;
  label: string;
  options: { type: Warning; label: string; icon: React.ReactNode }[];
}) => {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");
  const bulkIgnoreWarningsMutation = useNewBulkIgnoreWarningsMutation();
  const { close } = useBulkEditCommandPalette();
  const { numSelected } = useNumSelectedOrFiltered();
  const lang = useLang();
  const successMessage = lang.txTable.commandPallet.successMessage;
  const selectedCount = numSelected ? numSelected : 0;
  const captureAnalytics = useCaptureAnalytics();
  const handleIgnore = () => {
    const warnings = [];

    if (type === Warning.All) {
      options.forEach((warning) => {
        if (warning.type !== Warning.All) {
          warnings.push(warning.type);
        }
      });
    } else {
      warnings.push(type);
    }

    if (!isValidBulkIgnorableWarnings(warnings)) {
      return;
    }

    bulkIgnoreWarningsMutation.mutate(
      {
        filter,
        warnings,
      },
      {
        onSuccess: async (data) => {
          close();

          if (data.result !== BulkUpdateStatus.AcceptedForProcessing) {
            displayMessage({
              message: successMessage[BulkEditOptions.IgnoreWarnings]({
                warning: label.toLowerCase(),
                count: selectedCount,
              }),
              type: DisplayMessage.Success,
              id: data.bulkEditId,
              action: <BulkEditAction bulkEditId={data.bulkEditId} />,
            });
          } else {
            displayMessage({
              message: lang.txTable.commandPallet.acceptedForProcessing,
              type: DisplayMessage.Info,
            });
          }
          captureAnalytics(bulkEditAnalyticsKey("confirmation"), {
            numSelected,
            bulkEditOption: BulkEditOptions.IgnoreWarnings,
          });
        },
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.IgnoreWarnings} />
        </BulkEditChips>
      }
      onConfirm={handleIgnore}
      loading={bulkIgnoreWarningsMutation.isPending}
    />
  );
};
