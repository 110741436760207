import { Country, Plan as PlanType } from "@ctc/types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  Grid,
  Radio,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";

import { AccountantSection } from "~/components/payment/AccountantSection";
import { PlanCardVariant, UserFeature } from "~/components/payment/enums";
import {
  INVESTOR_US_PLUS_TX_LIMIT,
  useHaveSmartContractTx,
  useIsLegacyUsInvestorUsPlusPlan,
  useLimitExceeded,
  usePlanPricing,
  usePlanVisibility,
  usePopularPlan,
} from "~/components/payment/helpers";
import { PageHeader } from "~/components/payment/PageHeader";
import { Partners } from "~/components/payment/Partners";
import { PlanLabel } from "~/components/payment/PlanLabel";
import { PromoHeaderBanner } from "~/components/payment/PromoHeaderBanner";
import { type PlanSectionProps } from "~/components/payment/types";

import { devices } from "~/components/ui/theme/legacy";
import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { SecondaryLoadingButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { PlanRank } from "~/constants/constants";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib/index";
import { useCountry, useLocalCurrency, useUser } from "~/redux/auth";
import { useLang, useLanguagePreference, useLocale } from "~/redux/lang";
import { CouponService } from "~/services/coupon";
import { useBestCoupons } from "~/state/billing";
import { usePlans } from "~/state/plans";
import { FeatureFlag, Features, PlanGroup } from "~/types/enums";
import { type RetailPlanData } from "~/types/index";

import { useCaptureAnalytics } from "../../analytics/posthog";
import { BusinessSection } from "./BusinessSection";

const userFeatureToPlanFeature = {
  [UserFeature.AuditReport]: Features.AdvancedReports,
  [UserFeature.TaxLossHarvesting]: Features.TaxLossHarvesting,
  [UserFeature.LeastTaxFirstOut]: Features.AdvancedInventory,
  [UserFeature.TradingStockReport]: Features.TradingStockReport,
};

const userFeatureToLink: { [K in UserFeature]?: string } = {
  [UserFeature.LeastTaxFirstOut]:
    "https://cryptotaxcalculator.io/au/guides/crypto-tax-accounting-methods/",
  [UserFeature.TaxLossHarvesting]:
    "https://cryptotaxcalculator.io/au/blog/tax-loss-harvesting/",
};

// TODO move this to backend with the plan config
export const useUserFeatures = ({ planType }: { planType: PlanType }) => {
  const country = useCountry();
  const lang = useLang();
  const plans = usePlans();
  const locale = useLocale();
  const isLegacyUsInvestorUsPlusPlan =
    useIsLegacyUsInvestorUsPlusPlan(planType);
  const user = useUser();

  // Being extra safe with the country check here.
  // useCountry is odd for accountants etc. so just sticking to the authed user here.
  const isHobbyistPlusPlan =
    user?.country === Country.USA &&
    user?.featureFlags?.[FeatureFlag.USHobbyistPlusExperiment] ===
      "hobbyist-plus";

  if (!country || !plans?.data?.[planType]) {
    return {
      getUserFeature: () => ({
        enabled: false,
        visible: false,
        label: "",
        labelTableHeader: "",
        labelTableCell: "",
        tooltip: "",
      }),
    };
  }

  const planData = plans.data[planType] as RetailPlanData;
  const langPayment = lang.payment;

  const txLimit = isLegacyUsInvestorUsPlusPlan
    ? INVESTOR_US_PLUS_TX_LIMIT
    : planData.paywallLimits.txCountLimit || 0;

  function getUserFeature(type: UserFeature) {
    switch (type) {
      case UserFeature.TxLimit: {
        return {
          enabled: true,
          visible: true,
          labelTableHeader: langPayment.base.copy.featuresTableHeader.txLimit,
          labelTableCell: txLimit.toLocaleString(locale),
          label: langPayment.base.copy.features.txLimit({
            txLimit: txLimit.toLocaleString(locale),
          }),
        };
      }
      case UserFeature.Integrations: {
        return {
          enabled: true,
          visible: true,
          labelTableHeader: langPayment.base.copy.featuresTableHeader[type],
          labelTableCell: langPayment.base.copy.featuresTableCell[type],
          label: langPayment.base.copy.features[type],
        };
      }
      case UserFeature.PortfolioTracking: {
        return {
          enabled: true,
          visible: true,
          label: langPayment.base.copy.features[type],
        };
      }
      case UserFeature.Report: {
        return {
          enabled: true,
          visible: true,
          label:
            country &&
            lang.taxAuthorityByCountry[
              country as keyof typeof lang.taxAuthorityByCountry
            ]
              ? langPayment.base.copy.features.reportAuthority({
                  authority:
                    lang.taxAuthorityByCountry[
                      country as keyof typeof lang.taxAuthorityByCountry
                    ],
                })
              : langPayment.base.copy.features.report,
        };
      }
      case UserFeature.SmartContract:
      case UserFeature.OnChain: {
        return {
          visible: true,
          enabled:
            planType === PlanType.Hobbyist ||
            planType === PlanType.Investor ||
            planType === PlanType.Trader,
          label: langPayment.base.copy.features[type],
        };
      }
      case UserFeature.AuditReport: {
        const planFeature = userFeatureToPlanFeature[type];
        const enabled = planData?.features[planFeature];
        const tooltip = langPayment.base.copy.featuresTooltip as any;
        const link = (langPayment.base.copy.features as any)[type];
        return {
          enabled,
          visible: true,
          label: langPayment.base.copy.features[type],
          tooltip: tooltip[type],
          link,
        };
      }
      case UserFeature.TaxLossHarvesting:
      case UserFeature.LeastTaxFirstOut: {
        const planFeature = userFeatureToPlanFeature[type];
        const enabled = planData?.features[planFeature];
        const tooltip = langPayment.base.copy.featuresTooltip as any;
        const link = (langPayment.base.copy.features as any)[type];
        return {
          enabled,
          visible: country !== Country.France,
          label: langPayment.base.copy.features[type],
          tooltip: tooltip[type],
          link,
        };
      }
      case UserFeature.TradingStockReport: {
        const planFeature = userFeatureToPlanFeature[type];
        const enabled = planData?.features[planFeature];
        return {
          enabled,
          visible: country === Country.Australia,
          label: langPayment.base.copy.features[type],
        };
      }
      case UserFeature.Support: {
        const label =
          planType === PlanType.Trader
            ? langPayment.base.copy.featuresTableCell.supportPriority
            : langPayment.base.copy.featuresTableCell.supportEmailChat;
        return {
          visible: planType !== PlanType.Trader,
          enabled: planType !== PlanType.Trader,
          label,
          labelTableCell: label,
          labelTableHeader: langPayment.base.copy.featuresTableHeader.support,
        };
      }
      case UserFeature.AdvancedTaxReports: {
        return {
          visible: true,
          enabled:
            [PlanType.Investor, PlanType.Trader].includes(planType) ||
            (planType === PlanType.Hobbyist && isHobbyistPlusPlan),
          label: langPayment.base.copy.features.advancedTaxReports,
          tooltip: langPayment.base.copy.featuresTooltip.advancedTaxReports,
        };
      }
    }
  }

  return {
    getUserFeature,
  };
};

const PlanHeader = ({
  planType,
  onSignUpClick,
  isLoading,
  selectedPlan,
  applyCoupon = true,
}: {
  planType: PlanType;
  onSignUpClick: () => void;
  isLoading?: boolean;
  selectedPlan?: PlanType;
  applyCoupon?: boolean;
}) => {
  const lang = useLang();
  const user = useUser();
  const { tokens } = useDesign();
  const plans = usePlans();
  const { isPurchasable } = usePlanVisibility();
  const { isLimitedExceeded } = useLimitExceeded();
  const { isSmartContractTxExist } = useHaveSmartContractTx();
  const { getPopularPlan } = usePopularPlan();
  const localCurrency = useLocalCurrency();
  const locale = useLanguagePreference();
  const currentPlanType = useUser()?.paidPlan;
  const isMobile = useIsMobile();

  const {
    price,
    buttonText,
    priceDiscounted,
    planCurrency,
    loadingCouponDetails,
  } = usePlanPricing({
    planType,
  });

  if (!plans?.data?.[planType]) {
    return null;
  }

  // Check if the user has the possibility of coupons while data is loading
  // So we know they have coupons, but we don't know they are valid
  // So while we wait, we show a loader
  const hasPossibleCoupons = CouponService.getUserCouponCodes(user).length > 0;
  const isUnpaid = currentPlanType === PlanType.Free;

  /** Shown while we load the coupons */
  const couponLoading = isUnpaid &&
    hasPossibleCoupons &&
    loadingCouponDetails && <Skeleton height="1rem" />;

  const hasDiscountedPrice =
    priceDiscounted !== null &&
    priceDiscounted !== undefined &&
    priceDiscounted >= 0;
  /** Shows the was price */
  const showDiscountedPrice = isUnpaid && hasDiscountedPrice && applyCoupon;

  const discountedPrice = showDiscountedPrice && (
    <Typography
      variant={"Metropolis/Body/Light"}
      sx={{ color: tokens.text.brand }}
    >
      {lang.payment.base.copy.wasPrice({
        price: displayFiatValue({
          value: price,
          localCurrency: planCurrency ?? localCurrency,
          locale,
          roundUp: true,
        }),
      })}
    </Typography>
  );
  /** If other plans have a coupon, but this one doesn't we show a space */
  const couponSpacer = isUnpaid && hasPossibleCoupons && applyCoupon && (
    <div style={{ height: "1rem" }} />
  );

  const priceDisplay = showDiscountedPrice ? priceDiscounted : price;

  const langPayment = lang.payment;
  const isDisabled = !isPurchasable(planType);

  const action =
    planType === getPopularPlan() || isMobile ? (
      <Box>
        <PrimaryLoadingButton
          fullWidth
          loading={isLoading && planType === selectedPlan}
          startIcon={isLimitedExceeded(planType) ? <WarningAmberIcon /> : null}
          onClick={onSignUpClick}
          disabled={isDisabled || isLoading}
          sx={{
            fontSize: "0.875rem",
            fontWeight: 700,
          }}
          data-uncensored="true"
        >
          {buttonText}
        </PrimaryLoadingButton>
      </Box>
    ) : (
      <Box>
        <SecondaryLoadingButton
          fullWidth
          loading={isLoading && planType === selectedPlan}
          startIcon={
            isLimitedExceeded(planType) || isSmartContractTxExist(planType) ? (
              <WarningAmberIcon />
            ) : null
          }
          onClick={onSignUpClick}
          disabled={isDisabled || isLoading}
          sx={{
            fontSize: "0.875rem",
            fontWeight: 700,
          }}
          data-uncensored="true"
        >
          {buttonText}
        </SecondaryLoadingButton>
      </Box>
    );

  return (
    <Box data-ctc={planType}>
      <Stack
        direction="column"
        gap="1rem"
        data-ctc={isDisabled ? "disabled" : "enabled"}
      >
        <Box
          data-ctc={
            priceDiscounted
              ? `${
                  langPayment.base.copy.plans[planType].title
                }-discounted-price`
              : `${langPayment.base.copy.plans[planType].title}-price`
          }
        >
          <Typography variant="Metropolis/Header/H4" style={{ color: tokens.text.low }}>
            {langPayment.base.copy.plans[planType].title}
          </Typography>
          <Typography
            variant="Metropolis/Header/H1"
            style={{ color: tokens.text.default, marginTop: "0.15rem" }}
          >
            {displayFiatValue({
              value: priceDisplay,
              localCurrency: planCurrency ?? localCurrency,
              locale,
              roundUp: true,
            })}
          </Typography>

          {/* If the user has a discount code */}
          {couponLoading || discountedPrice || couponSpacer}

          <Box mt="0.25rem">
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              style={{
                color: tokens.text.default,
                lineHeight: 1.5,
                display: "block",
              }}
            >
              {langPayment.billedYearly}
            </Typography>
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              style={{
                color: tokens.text.low,
                lineHeight: 1.5,
                display: "block",
              }}
            >
              {langPayment.taxDeductible}
            </Typography>
          </Box>
        </Box>
        <Box>
          {isLimitedExceeded(planType) ? (
            <Tooltip title={langPayment.tooManyTx}>{action}</Tooltip>
          ) : isSmartContractTxExist(planType) ? (
            <Tooltip title={langPayment.haveSmartContractTx}>{action}</Tooltip>
          ) : (
            action
          )}
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            style={{
              marginTop: "0.25rem",
              fontSize: "0.625rem",
              color: tokens.text.low,
              display: "block",
              textAlign: "center",
            }}
          >
            {langPayment.moneyBackGuaranteeLower}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

const Plan = ({
  planType,
  userFeature,
  variant,
}: {
  planType: PlanType;
  userFeature: UserFeature;
  variant: PlanCardVariant;
}) => {
  const lang = useLang();
  const plans = usePlans();
  const { getUserFeature } = useUserFeatures({ planType });
  const { tokens } = useDesign();
  const properties = getUserFeature(userFeature);
  const isLegacyUsInvestorUsPlusPlan =
    useIsLegacyUsInvestorUsPlusPlan(planType);

  if (!plans?.data?.[planType]) {
    return null;
  }

  const hasAccessToProPlan = !!plans.data?.[PlanType.Pro];

  // Everything is enabled all tiers except rookie for the pro experiment
  const enabled =
    hasAccessToProPlan && planType !== PlanType.Rookie
      ? true
      : properties.enabled;

  // hack as this feature isnt migrated to the backend yet
  if (hasAccessToProPlan && userFeature === UserFeature.Support) {
    const isProPlan = planType === PlanType.Pro;
    return (
      <>
        {variant === PlanCardVariant.Card ? (
          <CheckIcon
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              fill: tokens.text.success,
            }}
          />
        ) : null}
        <PlanLabel
          label={
            isProPlan
              ? lang.payment.base.copy.featuresTableCell.supportPriority
              : lang.payment.base.copy.featuresTableCell.supportEmailChat
          }
        />
      </>
    );
  }

  if (variant === PlanCardVariant.Table) {
    return properties.labelTableCell ? (
      <PlanLabel
        label={properties.labelTableCell}
        isLegacyPlan={
          isLegacyUsInvestorUsPlusPlan && userFeature === UserFeature.TxLimit
        }
      />
    ) : (
      <>
        {enabled ? (
          <CheckIcon
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              fill: tokens.text.success,
            }}
          />
        ) : (
          <CloseIcon
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              fill: tokens.text.danger,
            }}
          />
        )}
      </>
    );
  }

  if (variant === PlanCardVariant.Card) {
    return (
      <>
        {enabled ? (
          <CheckIcon
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              fill: tokens.text.success,
            }}
          />
        ) : (
          <CloseIcon
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              fill: tokens.text.danger,
            }}
          />
        )}
        <PlanLabel label={properties.label} tooltip={properties.tooltip} />
      </>
    );
  }

  return null;
};

const Plans = ({
  planType,
  variant = PlanCardVariant.Card,
}: {
  planType: PlanType;
  variant?: PlanCardVariant;
}) => {
  const plans = usePlans();
  const { getUserFeature } = useUserFeatures({ planType });
  const hasAccessToProPlan = !!plans.data?.[PlanType.Pro];
  if (!plans?.data?.[planType]) {
    return null;
  }

  return (
    <Stack direction="column" gap="0.25" style={{ marginTop: "-0.5rem" }}>
      {Object.values(UserFeature).map((userFeature) => {
        const properties = getUserFeature(userFeature);

        if (!properties.visible) {
          return null;
        }

        return (
          <Stack
            direction="row"
            alignItems="center"
            key={userFeature}
            gap="0.5rem"
            sx={{ margin: "0.5rem 0" }}
          >
            <Plan
              planType={planType}
              userFeature={userFeature}
              variant={variant}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

const PlanToggle = ({
  toggledPlan,
  onPlanToggle,
}: {
  toggledPlan: PlanType | undefined;
  onPlanToggle: (planType: PlanType) => void;
}) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const user = useUser();
  const plans = usePlans();
  const { isLimitedExceeded } = useLimitExceeded();
  const { isPurchasable, isViewable } = usePlanVisibility();

  useEffect(() => {
    if (!toggledPlan && plans.data) {
      const plan = Object.keys(plans.data).find((plan) =>
        isPurchasable(plan as PlanType),
      );

      if (plan) onPlanToggle(plan as PlanType);
    }
  }, [plans?.data, isPurchasable, onPlanToggle, toggledPlan]);

  const sortedPlans = useMemo(
    () =>
      Object.keys(plans?.data || []).sort(
        (a, b) => PlanRank[a as PlanType] - PlanRank[b as PlanType],
      ),
    [plans?.data],
  );
  return (
    <Box>
      {sortedPlans.map((plan) => (
        <Box
          onClick={() => {
            if (isViewable(plan as PlanType)) {
              onPlanToggle(plan as PlanType);
            }
          }}
          key={plan}
          sx={{
            display: "flex",
            alignItems: "center",
            border:
              (plan as PlanType) === toggledPlan
                ? `1px solid ${tokens.text.brand}`
                : `1px solid ${tokens.border.neutral.default}`,
            padding: "0.25rem 1rem 0.25rem 0.25rem",
            background: tokens.elevation.low,
            gap: "0.25rem",
            cursor: !isViewable(plan as PlanType) ? "not-allowed" : "pointer",
            transition: "all 300ms",
            "&:first-child": {
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
            },
            "&:last-child": {
              borderBottomLeftRadius: "0.5rem",
              borderBottomRightRadius: "0.5rem",
            },
          }}
        >
          <Radio
            checked={(plan as PlanType) === toggledPlan}
            disabled={!isViewable(plan as PlanType)}
          />
          <Typography
            variant="Metropolis/Header/H5"
            sx={{
              color:
                (plan as PlanType) === toggledPlan
                  ? tokens.text.brand
                  : !isViewable(plan as PlanType)
                    ? tokens.text.disabled
                    : tokens.text.low,
            }}
          >
            {lang.payment.base.copy.plans[plan as PlanType].title}
          </Typography>
          {isLimitedExceeded(plan as PlanType) || user?.paidPlan === plan ? (
            <Box
              sx={{
                marginLeft: "auto ",
                background: tokens.background.accent.neutral.low,
                padding: "0.15rem 0.5rem",
                fontSize: "0.75rem",
                fontWeight: 500,
                color: tokens.text.default,
                borderRadius: "1.5rem",
              }}
            >
              {user?.paidPlan === plan
                ? lang.currentPlan
                : lang.txLimitExceeded}
            </Box>
          ) : null}
        </Box>
      ))}
    </Box>
  );
};

export function PlanSection({
  planGroup,
  onPlanGroupChange,
  onPlanChange,
  isRedirecting,
  selectedPlan,
}: PlanSectionProps) {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const [toggledPlan, setToggledPlan] = useState<PlanType | undefined>();
  const isLaptop = useMediaQuery(devices.laptop);
  const plans = usePlans();
  const { tokens } = useDesign();
  const { getPopularPlan } = usePopularPlan();
  const captureAnalytics = useCaptureAnalytics();
  const couponData = useBestCoupons();

  const user = useUser();
  // Can use any non-free plan type, only interested in non-plan specific values,
  // eg: Labels, tooltips, etc.
  const { getUserFeature } = useUserFeatures({
    planType: PlanType.Investor,
  });

  // If they have any coupons that can be used, we'll show the promo header
  const isInPromo = CouponService.getUserCouponCodes(user).length > 0;

  const [applyCoupon, setApplyCoupon] = useState(true);

  if (planGroup === PlanGroup.Professional) {
    return (
      <AccountantSection
        onPlanChange={onPlanChange}
        onPlanGroupChange={onPlanGroupChange}
      />
    );
  }

  if (planGroup === PlanGroup.Business) {
    return (
      <BusinessSection
        onPlanChange={onPlanChange}
        onPlanGroupChange={onPlanGroupChange}
        isRedirecting={isRedirecting}
      />
    );
  }

  const hasAccessToProPlan = !!plans.data?.[PlanType.Pro];

  return (
    <StyledPlanBox>
      {isLaptop ? (
        <>
          {isInPromo ? (
            <PromoHeaderBanner
              applyCoupon={applyCoupon}
              setApplyCoupon={setApplyCoupon}
            />
          ) : null}

          {hasAccessToProPlan ? (
            <PlanSectionWithProPlan
              planGroup={planGroup}
              onPlanGroupChange={onPlanGroupChange}
              onPlanChange={onPlanChange}
              isRedirecting={isRedirecting}
              selectedPlan={selectedPlan}
              applyCoupon={applyCoupon}
            />
          ) : (
            <StyledPlanBox>
              <Grid container columnSpacing="2rem">
                <Grid item md={3.5}>
                  <Box minHeight="11.5rem">
                    <PageHeader />
                  </Box>
                </Grid>
                {Object.keys(plans?.data || [])
                  .sort(
                    (a, b) => PlanRank[a as PlanType] - PlanRank[b as PlanType],
                  )
                  .filter((plan) => plan !== PlanType.Pro)
                  .map((plan) => (
                    <Grid item md={2.125} key={plan}>
                      <Stack
                        direction="column"
                        className={
                          plan === getPopularPlan()
                            ? "most-popular most-popular--first"
                            : undefined
                        }
                      >
                        <Box minHeight="11.5rem" sx={{ position: "relative" }}>
                          {plan === getPopularPlan() ? (
                            <Box
                              sx={{
                                position: "absolute",
                                top: "-1rem",
                                right: "-1rem",
                                borderBottomLeftRadius: "0.5rem",
                                background: tokens.text.brand,
                                color: tokens.text.inverse,
                                fontSize: "0.75rem",
                                fontWeight: 700,
                                padding: "0.25rem 0.5rem",
                              }}
                            >
                              {lang.payment.mostPopular}
                            </Box>
                          ) : null}
                          <Box mb="2rem">
                            <PlanHeader
                              planType={plan as PlanType}
                              onSignUpClick={() => {
                                const stripeCouponCode =
                                  couponData.data?.couponsByPlan[
                                    plan as PlanType
                                  ]?.stripeCouponCode;
                                onPlanChange(
                                  plan as PlanType,
                                  applyCoupon,
                                  stripeCouponCode,
                                );
                              }}
                              isLoading={isRedirecting}
                              selectedPlan={selectedPlan}
                              applyCoupon={applyCoupon}
                            />
                          </Box>
                        </Box>
                      </Stack>
                    </Grid>
                  ))}
                {Object.values(UserFeature).map((userFeature, i) => {
                  // NOTE: Only use non-plan specific values, eg: label.
                  const properties = getUserFeature(userFeature);

                  const link = userFeatureToLink[userFeature];
                  const label = properties.label;
                  const labelTableHeader = properties.labelTableHeader;
                  const tooltip = properties.tooltip;
                  const isLast = i === Object.values(UserFeature).length - 1;

                  if (!properties.visible) {
                    return null;
                  }

                  return (
                    <>
                      <Grid item md={3.5} key={userFeature}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="0.5rem"
                          sx={{ margin: "0.5rem 0" }}
                        >
                          {link ? (
                            <a
                              href={link}
                              target="_blank" // todo: fix when we move to new tab logic component
                              style={{ textDecoration: "none" }}
                              rel="noopener"
                            >
                              <PlanLabel
                                label={labelTableHeader || label}
                                tooltip={tooltip}
                                isLink
                              />
                            </a>
                          ) : (
                            <PlanLabel
                              label={labelTableHeader || label}
                              tooltip={tooltip}
                            />
                          )}
                        </Stack>
                      </Grid>
                      {Object.keys(plans?.data || [])
                        .sort(
                          (a, b) =>
                            PlanRank[a as PlanType] - PlanRank[b as PlanType],
                        )
                        .map((plan) => (
                          <Grid item md={2.125} key={plan}>
                            <Stack
                              direction="column"
                              className={
                                plan === getPopularPlan()
                                  ? [
                                      "most-popular",
                                      isLast ? "most-popular--last" : "",
                                    ]
                                      .filter(Boolean)
                                      .join(" ")
                                  : undefined
                              }
                            >
                              <Plan
                                planType={plan as PlanType}
                                userFeature={userFeature}
                                variant={PlanCardVariant.Table}
                              />
                            </Stack>
                          </Grid>
                        ))}
                    </>
                  );
                })}
              </Grid>
              <Partners />
              <Disclaimer />
              {onPlanGroupChange && !isEmbedded ? (
                <Stack
                  direction="row"
                  gap="1rem"
                  mt="1rem"
                  justifyContent="end"
                >
                  <TextButton
                    onClick={() => {
                      onPlanGroupChange(PlanGroup.Business);
                      captureAnalytics("clicked_view_business_plan");
                    }}
                    endIcon={<ArrowForwardIcon fontSize="small" />}
                  >
                    {lang.payment.viewBusinessPlan}
                  </TextButton>
                  <TextButton
                    onClick={() => {
                      onPlanGroupChange(PlanGroup.Professional);
                    }}
                    endIcon={<ArrowForwardIcon fontSize="small" />}
                  >
                    {lang.payment.viewAccountant}
                  </TextButton>
                </Stack>
              ) : null}
            </StyledPlanBox>
          )}
        </>
      ) : (
        <Stack
          direction="column"
          gap="1rem"
          sx={{
            width: "100%",
            maxWidth: "43.125rem",
            margin: "0 auto",
            padding: "2rem 1rem",
          }}
        >
          {/* If the user is a referred user and has a discount code */}
          {isInPromo ? (
            <PromoHeaderBanner
              applyCoupon={applyCoupon}
              setApplyCoupon={setApplyCoupon}
            />
          ) : null}
          <PageHeader />
          <PlanToggle
            toggledPlan={toggledPlan}
            onPlanToggle={(planType) => {
              setToggledPlan(planType);
            }}
          />
          {toggledPlan ? (
            <Stack
              direction="column"
              gap="1rem"
              sx={{
                border: `1px solid ${tokens.border.neutral.default}`,
                padding: "1rem",
                background: tokens.elevation.low,
                borderRadius: "0.5rem",
              }}
            >
              <PlanHeader
                planType={toggledPlan}
                onSignUpClick={() => {
                  const stripeCouponCode =
                    couponData.data?.couponsByPlan[toggledPlan]
                      ?.stripeCouponCode;

                  onPlanChange(toggledPlan, applyCoupon, stripeCouponCode);
                }}
                isLoading={isRedirecting}
                selectedPlan={selectedPlan}
                applyCoupon={applyCoupon}
              />
              <Plans planType={toggledPlan} />
            </Stack>
          ) : null}
          <Partners />
          <Disclaimer />
          {onPlanGroupChange && !isEmbedded ? (
            <Stack direction="row" gap="1rem" mt="1rem" justifyContent="end">
              <TextButton
                onClick={() => {
                  onPlanGroupChange(PlanGroup.Business);
                  captureAnalytics("clicked_view_business_plan");
                }}
                endIcon={<ArrowForwardIcon fontSize="small" />}
              >
                {lang.payment.viewBusinessPlan}
              </TextButton>
              <TextButton
                onClick={() => {
                  onPlanGroupChange(PlanGroup.Professional);
                }}
                endIcon={<ArrowForwardIcon fontSize="small" />}
              >
                {lang.payment.viewAccountant}
              </TextButton>
            </Stack>
          ) : null}
        </Stack>
      )}
    </StyledPlanBox>
  );
}

function PlanSectionWithProPlan({
  onPlanGroupChange,
  onPlanChange,
  isRedirecting,
  selectedPlan,
  applyCoupon,
}: PlanSectionProps & {
  applyCoupon: boolean;
}) {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const plans = usePlans();
  const { tokens } = useDesign();
  const { getPopularPlan } = usePopularPlan();
  const captureAnalytics = useCaptureAnalytics();
  const couponData = useBestCoupons();

  // Can use any non-free plan type, only interested in non-plan specific values,
  // eg: Labels, tooltips, etc.
  const { getUserFeature } = useUserFeatures({
    planType: PlanType.Investor,
  });

  return (
    <StyledPlanBox hasAccessToProPlan>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${1 + Object.keys(plans?.data || []).length}, 1fr)`,
          gap: "1rem",
        }}
      >
        <Box sx={{ minHeight: "11.5rem" }}>
          <PageHeader />
        </Box>
        {Object.keys(plans?.data || [])
          .sort((a, b) => PlanRank[a as PlanType] - PlanRank[b as PlanType])
          .map((plan) => (
            <Stack
              key={plan}
              direction="column"
              className={
                plan === getPopularPlan()
                  ? "most-popular most-popular--first"
                  : undefined
              }
            >
              <Box minHeight="11.5rem">
                {plan === getPopularPlan() ? (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-1rem",
                      right: "-1px",
                      left: "-1px",
                      borderTopLeftRadius: "0.5rem",
                      borderTopRightRadius: "0.5rem",
                      background: tokens.text.brand,
                      color: tokens.text.inverse,
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      padding: "0.25rem 0.5rem",
                      width: "calc(100% + 2px)",
                      textAlign: "center",
                      border: `1px solid ${tokens.text.brand}`,
                    }}
                  >
                    {lang.payment.mostPopular}
                  </Box>
                ) : null}
                <Box mb="2rem">
                  <PlanHeader
                    planType={plan as PlanType}
                    onSignUpClick={() => {
                      const stripeCouponCode =
                        couponData.data?.couponsByPlan[plan as PlanType]
                          ?.stripeCouponCode;
                      onPlanChange(
                        plan as PlanType,
                        applyCoupon,
                        stripeCouponCode,
                      );
                    }}
                    isLoading={isRedirecting}
                    selectedPlan={selectedPlan}
                    applyCoupon={applyCoupon}
                  />
                </Box>
              </Box>
            </Stack>
          ))}
        {Object.values(UserFeature).map((userFeature, i) => {
          // NOTE: Only use non-plan specific values, eg: label.
          const properties = getUserFeature(userFeature);

          const link = userFeatureToLink[userFeature];
          const label = properties.label;
          const labelTableHeader = properties.labelTableHeader;
          const tooltip = properties.tooltip;
          const isLast = i === Object.values(UserFeature).length - 1;

          if (!properties.visible) {
            return null;
          }

          return (
            <>
              <Stack
                direction="row"
                alignItems="center"
                gap="0.5rem"
                sx={{ margin: "0.5rem 0" }}
                key={userFeature}
              >
                {link ? (
                  <a
                    href={link}
                    target="_blank" // todo: fix when we move to new tab logic component
                    style={{ textDecoration: "none" }}
                    rel="noopener"
                  >
                    <PlanLabel
                      label={labelTableHeader || label}
                      tooltip={tooltip}
                      isLink
                    />
                  </a>
                ) : (
                  <PlanLabel
                    label={labelTableHeader || label}
                    tooltip={tooltip}
                  />
                )}
              </Stack>
              {Object.keys(plans?.data || [])
                .sort(
                  (a, b) => PlanRank[a as PlanType] - PlanRank[b as PlanType],
                )
                .map((plan) => (
                  <Stack
                    key={plan}
                    direction="column"
                    className={
                      plan === getPopularPlan()
                        ? ["most-popular", isLast ? "most-popular--last" : ""]
                            .filter(Boolean)
                            .join(" ")
                        : undefined
                    }
                    sx={{ alignItems: "center" }}
                  >
                    <Plan
                      planType={plan as PlanType}
                      userFeature={userFeature}
                      variant={PlanCardVariant.Table}
                    />
                  </Stack>
                ))}
            </>
          );
        })}
      </Box>
      <Partners />
      <Disclaimer />
      {onPlanGroupChange && !isEmbedded ? (
        <Stack direction="row" gap="1rem" mt="1rem" justifyContent="end">
          <TextButton
            onClick={() => {
              onPlanGroupChange(PlanGroup.Business);
              captureAnalytics("clicked_view_business_plan");
            }}
            endIcon={<ArrowForwardIcon fontSize="small" />}
          >
            {lang.payment.viewBusinessPlan}
          </TextButton>
          <TextButton
            onClick={() => {
              onPlanGroupChange(PlanGroup.Professional);
            }}
            endIcon={<ArrowForwardIcon fontSize="small" />}
          >
            {lang.payment.viewAccountant}
          </TextButton>
        </Stack>
      ) : null}
    </StyledPlanBox>
  );
}

function Disclaimer() {
  const lang = useLang();
  const { tokens } = useDesign();

  return (
    <Box mt="1rem">
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        color={tokens.text.low}
      >
        {lang.payment.disclaimer}
      </Typography>
    </Box>
  );
}

const StyledPlanBox = styled(Box)<{ hasAccessToProPlan?: boolean }>`
  && {
    max-width: 81.125rem;
    margin: 0 auto;
    .most-popular {
      position: relative;
      right: -1rem;
      margin: ${({ hasAccessToProPlan }) =>
        hasAccessToProPlan
          ? "-1rem 0.5rem -1rem -1.5rem"
          : "-1rem 0 -1rem -2rem"};
      padding: 1rem;
      overflow: visible;
      border-left: 1px solid ${({ theme }) => theme.tokens.text.brand};
      border-right: 1px solid ${({ theme }) => theme.tokens.text.brand};
      background: ${({ theme }) => theme.tokens.elevation.low};
    }

    .most-popular--first {
      border-top: 1px solid ${({ theme }) => theme.tokens.text.brand};
      margin-bottom: 0;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    .most-popular--last {
      border-bottom: 1px solid ${({ theme }) => theme.tokens.text.brand};
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    @media ${devices.laptopL} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
