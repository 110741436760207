import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { devices } from "~/components/ui/theme/legacy";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";

type FaqProps = {
  data: Translation["faqPricing"] | Translation["faqAccountant"];
};

const FaqWrapper = styled(Box)`
  padding-top: 1.875rem;
`;

const LayoutComponent = styled(Box)`
  width: 100%;
  margin: 0 auto;
  max-width: 1298px;
  padding: 1.25rem 1.25rem 0;
  @media ${devices.tablet} {
    padding: 0 1.875rem;
  }
  @media ${devices.laptopL} {
    padding: 1.875rem 0;
  }
`;

const FaqItem = styled(Box)`
  max-width: 100%;
  border-top: 2px solid ${({ theme }) => theme.tokens.border.neutral.high};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 1.875rem;
  padding-top: 1.875rem;
  @media ${devices.tablet} {
    margin-bottom: 1.875rem;
  }
  @media ${devices.laptop} {
    margin-bottom: 0.5rem;
    padding: 1.875rem 0 4.375rem;
  }
`;

const FaqGrid = styled(Grid)`
  margin: 0;
  max-width: 100%;
  @media ${devices.tablet} {
    margin: 0 -0.938rem;
  }
`;

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 90%;
`;

export function Faq({ data }: FaqProps) {
  const { tokens } = useDesign();

  return (
    <FaqWrapper id="faq">
      <LayoutComponent maxWidth="xl">
        {data?.description && (
          <Typography variant="Metropolis/Header/H2" mb="2rem" sx={{ fontWeight: 700 }}>
            {data.description}
          </Typography>
        )}
        <FaqGrid container direction="row" justifyContent="center">
          {data.questions.map((item, index) => {
            const numberItem = index < 9 ? `0${index + 1}.` : `${index + 1}.`;
            return (
              <Grid key={item.question} item xs={12} sm={6} md={6}>
                <FaqItem
                  mb={{ xs: "4.375rem" }}
                  mx={{ sm: "0.938rem" }}
                  pt={{ sm: "2rem" }}
                >
                  {numberItem && (
                    <Typography variant="Metropolis/Header/H4" sx={{ fontWeight: 700 }}>
                      {numberItem}
                    </Typography>
                  )}
                  <TextContainer>
                    <Typography
                      variant="Metropolis/Header/H4"
                      sx={{ fontWeight: 700, marginBottom: "1rem" }}
                    >
                      {item.question}
                    </Typography>
                    <Typography
                      variant="Metropolis/Body/Regular"
                      sx={{ color: tokens.text.low }}
                    >
                      {item.answer}
                    </Typography>
                  </TextContainer>
                </FaqItem>
              </Grid>
            );
          })}
        </FaqGrid>
      </LayoutComponent>
    </FaqWrapper>
  );
}
