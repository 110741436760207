import { Box, type BoxProps, CircularProgress } from "@mui/material";

/**
 * Fallback for Suspense components that renders a circular progress indicator
 */
export function SuspenseCircularProgress({
  width = 2.5,
  height = 2.5,
  ...props
}: { width?: number; height?: number } & BoxProps) {
  const widthOffset = width / 2;
  const heightOffset = height / 2;
  return (
    <Box
      position="absolute"
      zIndex="99"
      top="50%"
      left="50%"
      marginTop={`-${heightOffset}rem`}
      marginLeft={`-${widthOffset}rem`}
      width={`${width}rem`}
      height={`${height}rem`}
      {...props}
    >
      <CircularProgress />
    </Box>
  );
}
