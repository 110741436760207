import { SyncStatusPlatform } from "@ctc/types";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";

import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { bulkEditHotkeys } from "~/components/transactions/command-palette/CommandPaletteModal";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import {
  useBulkEditReview,
  useIsSelectedRowERPSyncable,
  useSelectedRowReviewedStatus,
} from "~/components/transactions/command-palette/hooks/useBulkEditReview";
import {
  useBulkEditUngroup,
  useSelectedRowGroupStatus,
} from "~/components/transactions/command-palette/hooks/useBulkEditUngroup";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { HotkeyIcon } from "~/components/transactions/command-palette/HotkeyIcons";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditTypeIcons } from "~/components/transactions/command-palette/views/BulkEditTypeIcons";
import { SelectCurrency } from "~/components/transactions/command-palette/views/change-asset-account/SelectCurrency";
import { SelectAccount } from "~/components/transactions/command-palette/views/change-cash-account/SelectAccount";
import { FromCurrency } from "~/components/transactions/command-palette/views/change-currency/FromCurrency";
import { FromDestinationAccount } from "~/components/transactions/command-palette/views/change-destination-account/FromDestinationAccount";
import { SelectGainsTradeType } from "~/components/transactions/command-palette/views/change-gains-account/SelectGainsTradeType";
import { SelectLoanTradeType } from "~/components/transactions/command-palette/views/change-loan-account/SelectLoanTradeType";
import { SelectTradeType } from "~/components/transactions/command-palette/views/change-pnl-account/SelectTradeType";
import { SelectCurrency as SelectPriceCurrency } from "~/components/transactions/command-palette/views/change-price/SelectCurrency";
import { FromSourceAccount } from "~/components/transactions/command-palette/views/change-source-account/FromSourceAccount";
import { Comment } from "~/components/transactions/command-palette/views/comment/Comment";
import { DeleteConfirmation } from "~/components/transactions/command-palette/views/DeleteConfirmation";
import { IgnoreWarnings } from "~/components/transactions/command-palette/views/ignore-warnings/IgnoreWarnings";
import { IgnoreConfirmation } from "~/components/transactions/command-palette/views/IgnoreConfirmation";
import { LookUpMarketPriceConfirmation } from "~/components/transactions/command-palette/views/LookUpMarketPriceConfirmation";
import { ConfirmationAsSpam } from "~/components/transactions/command-palette/views/mark-as-spam/Confirmation";
import { ToCategory } from "~/components/transactions/command-palette/views/recategorise/ToCategory";
import { SetTimestamp } from "~/components/transactions/command-palette/views/rewind-transactions/SetTimestamp";
import { ConfirmationSyncToErp } from "~/components/transactions/command-palette/views/sync-to-erp/Confirmation";
import { GroupingWizard } from "~/components/transactions/grouping-wizard/index";
import { ctcLightTheme as theme } from "~/components/ui/theme/mui";
import { invariant } from "~/lib/invariant";
import { useCanAccessFeature } from "~/redux/auth";
import { useErpLang, useLang } from "~/redux/lang";
import { useErpSettingsQuery } from "~/state/erp";
import { useUserSyncStatus } from "~/state/sync";
import { Features, FilterOperators } from "~/types/enums";

enum RootOptionSection {
  Popular = "Popular",
  Common = "Common",
  Advanced = "Advanced",
}

export interface BulkEditOption {
  type: BulkEditOptions;
  label: string;
  icon: JSX.Element;
  chevron?: boolean;
  sectionId?: string;
  disabled?: boolean;
  disabledTooltip?: string;
}

export const Root = () => {
  const lang = useLang();
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");
  const erpSettings = useErpSettingsQuery();

  const isCOA = useCanAccessFeature(Features.ERP);
  const { push } = useNavController();
  const [isGroupingWizardOpen, setIsGroupingWizardOpen] = useState(false);
  const { isAllTxSelectedReviewed, isAllTxSelectedUnreviewed } =
    useSelectedRowReviewedStatus({ filter });
  const { showUngroupOption, showAllowGroupingOption } =
    useSelectedRowGroupStatus({ filter });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const erpLang = useErpLang(erpSettings.data?.erpType);
  const reportStatus = useUserSyncStatus();
  const isReportRefreshing = reportStatus === SyncStatusPlatform.Pending;
  const isReportFailed = reportStatus === SyncStatusPlatform.Fail;

  const {
    onConfirm: onBulkEditReview,
    isLoading: bulkEditReviewMutationIsLoading,
  } = useBulkEditReview();
  const {
    onConfirm: onBulkEditUngroup,
    isLoading: bulkEditUngroupMutationIsLoading,
  } = useBulkEditUngroup();

  const bulkEditMutationIsLoading =
    bulkEditReviewMutationIsLoading || bulkEditUngroupMutationIsLoading;

  const isSelectedRowSyncable = useIsSelectedRowERPSyncable({ filter });

  const bulkEditOptionsLabel = lang.txTable.commandPallet.bulkEditOptions;

  const showERPRelatedOptions = isCOA && !!erpSettings.data;

  const bulkEditOptions: BulkEditOption[] = [];

  const rootOptions = [
    {
      type: BulkEditOptions.Categorize,
      chevron: true,
      sectionId: RootOptionSection.Popular,
      endIcon: isMobile ? null : (
        <HotkeyIcon>{bulkEditHotkeys.C.label}</HotkeyIcon>
      ),
    },
    {
      type: BulkEditOptions.ChangeCurrency,
      chevron: true,
      sectionId: RootOptionSection.Popular,
    },
    {
      type: BulkEditOptions.ChangeFeeCurrency,
      sectionId: RootOptionSection.Common,
    },
    {
      type: BulkEditOptions.ChangePrice,
      chevron: true,
      sectionId: RootOptionSection.Popular,
    },
    {
      type: BulkEditOptions.AddCommentToTransactions,
      chevron: true,
      sectionId: RootOptionSection.Common,
    },
    {
      type: BulkEditOptions.MarkAsSpam,
      sectionId: RootOptionSection.Common,
    },
    {
      type: BulkEditOptions.IgnoreWarnings,
      chevron: true,
      sectionId: RootOptionSection.Advanced,
    },
    {
      type: BulkEditOptions.SourceAccount,
      chevron: true,
      sectionId: RootOptionSection.Common,
    },
    {
      type: BulkEditOptions.DestinationAccount,
      chevron: true,
      sectionId: RootOptionSection.Common,
    },
    {
      type: BulkEditOptions.Ignore,
      sectionId: RootOptionSection.Common,
    },
    // The bookkeeping account setting options
    ...(showERPRelatedOptions
      ? [
          {
            type: BulkEditOptions.ChangeAssetAccount,
            chevron: true,
            sectionId: RootOptionSection.Advanced,
          },
          {
            type: BulkEditOptions.ChangePnLAccount,
            chevron: true,
            sectionId: RootOptionSection.Advanced,
          },
          {
            type: BulkEditOptions.ChangeGainsAccount,
            chevron: true,
            sectionId: RootOptionSection.Advanced,
          },
          {
            type: BulkEditOptions.ChangeLoanAccount,
            chevron: true,
            sectionId: RootOptionSection.Advanced,
          },
          {
            type: BulkEditOptions.ChangeCashAccount,
            chevron: true,
            sectionId: RootOptionSection.Advanced,
          },
        ]
      : []),
  ] as const;

  rootOptions.map((op) => {
    bulkEditOptions.push({
      ...op,
      label: bulkEditOptionsLabel[op.type],
      icon: BulkEditTypeIcons[op.type],
    });
  });

  const MarkAsReviewedOption = [
    {
      type: BulkEditOptions.MarkAsReviewed,
      label: bulkEditOptionsLabel[BulkEditOptions.MarkAsReviewed],
      icon: BulkEditTypeIcons[BulkEditOptions.MarkAsReviewed],
      sectionId: RootOptionSection.Common,
      endIcon: isMobile ? null : (
        <HotkeyIcon>{bulkEditHotkeys.R.label}</HotkeyIcon>
      ),
    },
  ];

  const MarkAsNotReviewedOption = [
    {
      type: BulkEditOptions.MarkAsNotReviewed,
      label: bulkEditOptionsLabel[BulkEditOptions.MarkAsNotReviewed],
      icon: BulkEditTypeIcons[BulkEditOptions.MarkAsNotReviewed],
      sectionId: RootOptionSection.Advanced,
    },
  ];

  if (isAllTxSelectedUnreviewed) {
    bulkEditOptions.push(...MarkAsReviewedOption);
  } else if (isAllTxSelectedReviewed) {
    bulkEditOptions.push(...MarkAsNotReviewedOption);
  } else {
    bulkEditOptions.push(...MarkAsReviewedOption, ...MarkAsNotReviewedOption);
  }

  if (showUngroupOption) {
    bulkEditOptions.push({
      type: BulkEditOptions.Ungroup,
      label: bulkEditOptionsLabel[BulkEditOptions.Ungroup],
      icon: BulkEditTypeIcons[BulkEditOptions.Ungroup],
      sectionId: RootOptionSection.Advanced,
    });
  }

  if (showAllowGroupingOption) {
    bulkEditOptions.push({
      type: BulkEditOptions.AllowGrouping,
      label: bulkEditOptionsLabel[BulkEditOptions.AllowGrouping],
      icon: BulkEditTypeIcons[BulkEditOptions.AllowGrouping],
      sectionId: RootOptionSection.Advanced,
    });
  }

  bulkEditOptions.push(
    {
      type: BulkEditOptions.LookUpMarketPrice,
      label: bulkEditOptionsLabel[BulkEditOptions.LookUpMarketPrice],
      icon: BulkEditTypeIcons[BulkEditOptions.LookUpMarketPrice],
      sectionId: RootOptionSection.Common,
      disabled: filter.type !== FilterOperators.ActionId,
    },
    {
      type: BulkEditOptions.Delete,
      label: bulkEditOptionsLabel[BulkEditOptions.Delete],
      icon: BulkEditTypeIcons[BulkEditOptions.Delete],
      sectionId: RootOptionSection.Common,
    },
  );

  if (showERPRelatedOptions && isSelectedRowSyncable) {
    bulkEditOptions.push({
      type: BulkEditOptions.SyncToErp,
      sectionId: RootOptionSection.Advanced,
      icon: BulkEditTypeIcons[BulkEditOptions.SyncToErp],
      label: lang.txTable.commandPallet.syncToErp({
        erp: erpLang.integrationShortName,
      }),
      disabled: isReportRefreshing || isReportFailed,
      disabledTooltip: isReportRefreshing
        ? lang.txTable.txRow.erp.waitForCalculationsToFinish
        : lang.txTable.txRow.erp.reportFailed,
    });
  }

  bulkEditOptions.push({
    type: BulkEditOptions.GroupingWizard,
    label: bulkEditOptionsLabel[BulkEditOptions.GroupingWizard],
    icon: BulkEditTypeIcons[BulkEditOptions.GroupingWizard],
    chevron: true,
    sectionId: RootOptionSection.Advanced,
  });

  bulkEditOptions.push({
    type: BulkEditOptions.TxRewind,
    label: bulkEditOptionsLabel[BulkEditOptions.TxRewind],
    icon: BulkEditTypeIcons[BulkEditOptions.TxRewind],
    sectionId: RootOptionSection.Advanced,
  });

  return (
    <>
      <GroupingWizard
        isOpen={isGroupingWizardOpen}
        onClose={() => {
          setIsGroupingWizardOpen(false);
        }}
      />
      <CommandPaletteMenu
        options={Object.values(RootOptionSection).flatMap((sectionId) => {
          return bulkEditOptions.filter(
            (option) => option.sectionId === sectionId,
          );
        })}
        loading={bulkEditMutationIsLoading}
        disabled={bulkEditMutationIsLoading}
        onSelection={({ type }) => {
          switch (type) {
            case BulkEditOptions.Categorize: {
              push(<ToCategory />);
              break;
            }
            case BulkEditOptions.SourceAccount: {
              push(<FromSourceAccount />);
              break;
            }
            case BulkEditOptions.DestinationAccount: {
              push(<FromDestinationAccount />);
              break;
            }
            case BulkEditOptions.ChangeCurrency: {
              push(
                <FromCurrency
                  bulkEditOption={BulkEditOptions.ChangeCurrency}
                />,
              );
              break;
            }
            case BulkEditOptions.ChangeFeeCurrency: {
              push(
                <FromCurrency
                  bulkEditOption={BulkEditOptions.ChangeFeeCurrency}
                />,
              );
              break;
            }
            case BulkEditOptions.AddCommentToTransactions: {
              push(<Comment />);
              break;
            }
            case BulkEditOptions.IgnoreWarnings: {
              push(<IgnoreWarnings />);
              break;
            }
            case BulkEditOptions.Delete: {
              push(<DeleteConfirmation open />);
              break;
            }
            case BulkEditOptions.GroupingWizard: {
              setIsGroupingWizardOpen(true);

              break;
            }
            case BulkEditOptions.MarkAsReviewed: {
              onBulkEditReview({ reviewed: true, filter });

              break;
            }
            case BulkEditOptions.MarkAsNotReviewed: {
              onBulkEditReview({ reviewed: false, filter });

              break;
            }
            case BulkEditOptions.Ungroup: {
              onBulkEditUngroup({ filter, ungroup: true });
              break;
            }
            case BulkEditOptions.AllowGrouping: {
              onBulkEditUngroup({ filter, ungroup: false });
              break;
            }
            case BulkEditOptions.MarkAsSpam: {
              push(<ConfirmationAsSpam />);
              break;
            }
            case BulkEditOptions.Ignore: {
              push(<IgnoreConfirmation />);
              break;
            }
            case BulkEditOptions.LookUpMarketPrice: {
              push(<LookUpMarketPriceConfirmation />);
              break;
            }
            case BulkEditOptions.ChangeAssetAccount: {
              push(<SelectCurrency />);
              break;
            }
            case BulkEditOptions.ChangePnLAccount: {
              push(<SelectTradeType />);
              break;
            }
            case BulkEditOptions.ChangeGainsAccount: {
              push(<SelectGainsTradeType />);
              break;
            }
            case BulkEditOptions.ChangeLoanAccount: {
              push(<SelectLoanTradeType />);
              break;
            }
            case BulkEditOptions.SyncToErp: {
              push(
                <ConfirmationSyncToErp erpType={erpSettings.data?.erpType} />,
              );
              break;
            }
            case BulkEditOptions.ChangePrice: {
              push(<SelectPriceCurrency />);
              break;
            }
            case BulkEditOptions.TxRewind: {
              push(<SetTimestamp />);
              break;
            }
            case BulkEditOptions.ChangeCashAccount: {
              push(<SelectAccount />);
              break;
            }
            default: {
              const exhaustiveCheck: never = type;
              throw new Error(`Unhandled case: ${exhaustiveCheck}`);
            }
          }
        }}
      />
    </>
  );
};
