import {
  type CaptchaProvider,
  Country,
  type InventoryMethod,
  Plan,
  type ReportFormat,
  SupportedLang,
} from "@ctc/types";
import { useAppKit } from "@reown/appkit/react";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ProviderRpcError } from "viem";
import { useAccount, useConnect, useDisconnect, useSignMessage } from "wagmi";

import { postSignUpUnbounceAnalytics } from "~/analytics/analytics";
import { captureGoogleAnalytics, identifyGoogleUser } from "~/analytics/google";
import {
  identifyPosthogUser,
  resetPosthog,
  useExperimentVariant,
} from "~/analytics/posthog";
import { captureTwitterAnalytics } from "~/analytics/twitter";
import { queryClient } from "~/components/queryClient";
import { displayMessage } from "~/components/ui/Toaster";
import { isValidEmail } from "~/components/user/validators";
import { currencySymbol } from "~/constants/constants";
import { AuthType, LocalStorageKey } from "~/constants/enums";
import { PaywallModalType } from "~/contexts/paywall-modal-context/enums";
import { PaywallModalContext } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { setDatadogUser } from "~/datadog";
import { useCanSeeTimeframe } from "~/hooks/useCanSeeTimeframe";
import { usePreviousWithUseEffect } from "~/hooks/usePrevious";
import { setMomentLocale } from "~/lang/index";
import { LoadUserType } from "~/redux/enums";
import {
  setLanguagePreference,
  setUpdateLogin,
  updateLanguage,
  useLang,
} from "~/redux/lang";
import { loadTaxSettings, useInventoryMethod } from "~/redux/report";
import { resetUserData } from "~/redux/reset";
import { type AppThunk, type AuthState as State } from "~/redux/types";
import { useSelector } from "~/redux/useSelector";
import { Analytics } from "~/segment/index";
import { identifyIntercomUser, resetIntercom } from "~/services/intercom";
import * as settingsAPI from "~/services/settings";
import { getUserSettings } from "~/services/settings";
import { considersWashSales } from "~/services/transactions";
import * as user from "~/services/user";
import {
  getHighestPlanRankCurrentUser,
  isClientView,
  isPaidPlan,
} from "~/services/user";
import { syncKeys } from "~/state/sync";
import {
  AuthMethodType,
  DisplayMessage,
  ErrorType,
  FeatureFlag,
  Features,
  NormalReportType,
  type NotificationType,
  PartyToAction,
  PaywallReason,
} from "~/types/enums";
import {
  AdvancedReports,
  type BaseUserDetails,
  type Code2FADetails,
  type DisableOAuth,
  type ForgottenPasswordDetails,
  type LoginDetails,
  type PaywallType,
  type RenewalErrorInfo,
  type ReportDownloadType,
  type ResetDetails,
  type SignupDetails,
  type SignupDetailsAccountant,
  type TokenLoginDetails,
  type UnlockDetails,
  type UpdateAlpha,
  type UpdateCountry,
  type UpdateEmail,
  type UpdateInviteCode,
  type UpdateIs2faEnabled,
  type UpdateName,
  type UpdateOnboarding,
  type UpdateOwnsChildProfileData,
  type UpdatePayment,
  type UpdateRenewalErrorInfo,
  type UpdateReOnboarding,
  type UpdateShowIgnored,
  type UpdateSubscriptionVersions,
  type UserInfo,
} from "~/types/index";

import { csrfTokenManager } from "../../lib/csrfTokenManager";
import { useGetLockPeriodEndDateIfExists } from "../state/period";

export enum Auth {
  ForgotPassword = "FORGOT_PASSWORD",
  ForgotPasswordSuccess = "FORGOT_PASSWORD_SUCCESS",
  ForgotPasswordFail = "FORGOT_PASSWORD_FAIL",
  Login = "LOGIN",
  LoginSuccess = "LOGIN_SUCCESS",
  LoginFail = "LOGIN_FAIL",
  Logout = "LOGOUT",
  LogoutSuccess = "LOGOUT_SUCCESS",
  LogoutFail = "LOGOUT_FAIL",
  Reset = "RESET",
  ResetSuccess = "RESET_SUCCESS",
  ResetFail = "RESET_FAIL",
  Signup = "SIGNUP",
  SignupSuccess = "SIGNUP_SUCCESS",
  SignupFail = "SIGNUP_FAIL",
  SetUpdate = "SET_UPDATE",
  SetUpdateBestActiveUser = "auth/setUpdateActiveUser",
  CancellationSurveyCompleted = "auth/cancellationSurveyCompleted",
  SetShouldShowCancellationSurvey = "auth/setShouldShowCancellationSurvey",
  UserActivated = "USER_ACTIVATED",
  LoadingUser = "LOADING_USER",
  UserLoaded = "USER_LOADED",
  Unlock = "UNLOCK",
  SetDataSource = "SET_DATA_SOURCE",
  UpdatingPassword = "UPDATING_PASSWORD",
  SetNotification = "SET_NOTIFICATION",
  SetNotificationAuthUser = "SET_NOTIFICATION_AUTH_USER",
  ResetError = "auth/resetError",
  Enter2faCode = "auth/enter2faCode",
}

type AuthActions = AuthEvent | AuthUser | AuthError | AuthUpdateActions;
type AuthEvent = {
  type:
    | Auth.Login
    | Auth.Signup
    | Auth.Logout
    | Auth.LogoutSuccess
    | Auth.Reset
    | Auth.ResetSuccess
    | Auth.ForgotPassword
    | Auth.ForgotPasswordSuccess
    | Auth.UserActivated
    | Auth.LoadingUser
    | Auth.UserLoaded
    | Auth.CancellationSurveyCompleted;
};

export type AuthUser = {
  type: Auth.LoginSuccess | Auth.SignupSuccess;
  user: UserInfo;
};

type AuthError = {
  type:
    | Auth.LoginFail
    | Auth.SignupFail
    | Auth.LogoutFail
    | Auth.ResetFail
    | Auth.ForgotPasswordFail;
  error: string;
};

type AuthUpdateActions =
  | {
      type: Auth.ResetError;
    }
  | {
      type: Auth.SetUpdate;
      payload:
        | UpdateCountry
        | UpdateEmail
        | UpdatePayment
        | UpdateSubscriptionVersions
        | UpdateIs2faEnabled
        | UpdateOnboarding
        | DisableOAuth
        | UpdateReOnboarding;
    }
  | {
      type: Auth.SetShouldShowCancellationSurvey;
      payload: boolean;
    }
  | {
      type: Auth.SetUpdateBestActiveUser;
      payload:
        | UpdatePayment
        | UpdateAlpha
        | UpdateName
        | UpdateInviteCode
        | UpdateCountry
        | UpdateShowIgnored
        | UpdateRenewalErrorInfo
        | UpdateOnboarding
        | UpdateReOnboarding;
    }
  | {
      type: Auth.SetDataSource;
      payload: UpdateOwnsChildProfileData;
    }
  | {
      type: Auth.UpdatingPassword;
      payload: boolean;
    }
  | {
      type: Auth.SetNotification;
      payload: { notification: NotificationType; value: boolean };
    }
  | {
      type: Auth.SetNotificationAuthUser;
      payload: { notification: NotificationType; value: boolean };
    }
  | {
      type: Auth.Enter2faCode;
      payload: boolean;
    };

export function auth(state: State, action: AuthActions): State {
  const initialState: State = {
    loading: true,
    user: null,
    isNewUser: false,
    isUpdatingPassword: false,
  };

  const authState = state || initialState;

  switch (action.type) {
    case Auth.Login:
    case Auth.Signup:
    case Auth.Reset:
    case Auth.LoadingUser:
      return {
        ...authState,
        loading: true,
      };
    case Auth.Enter2faCode:
      return {
        ...authState,
        loading: false,
      };
    case Auth.ResetError:
      return {
        ...authState,
        error: undefined,
      };
    case Auth.UserLoaded:
      return {
        ...authState,
        loading: false,
      };
    case Auth.LoginSuccess:
      return {
        ...authState,
        loading: false,
        error: undefined,
        user: action.user,
      };
    case Auth.SignupSuccess:
      return {
        ...authState,
        loading: false,
        error: undefined,
        user: action.user,
        isNewUser: true,
      };
    case Auth.UserActivated:
      return {
        ...authState,
        isNewUser: false,
      };
    case Auth.CancellationSurveyCompleted:
      return {
        ...authState,
        user: {
          ...authState.user,
          shouldShowCancellationSurvey: false,
        } as UserInfo,
      };
    case Auth.SetShouldShowCancellationSurvey:
      return {
        ...authState,
        user: {
          ...authState.user,
          shouldShowCancellationSurvey: action.payload,
        } as UserInfo,
      };
    case Auth.LoginFail:
    case Auth.SignupFail:
    case Auth.ResetFail:
      return {
        ...authState,
        loading: false,
        user: null,
        error: action.error,
      };
    case Auth.Logout:
      return {
        ...authState,
        loading: true,
        user: null,
      };
    case Auth.LogoutFail:
      return {
        ...authState,
        loading: false,
        error: action.error,
        user: null,
      };
    case Auth.LogoutSuccess:
    case Auth.ResetSuccess:
      return {
        ...authState,
        loading: false,
        user: null,
      };

    case Auth.SetUpdate:
      return {
        ...authState,
        user: {
          ...(authState.user as UserInfo),
          ...action.payload,
        },
      };
    case Auth.SetUpdateBestActiveUser: {
      const uid = authState.user?.uid;
      const activeProfileUid = authState.user?.activeProfileDetails?.uid;
      const isSameBaseAsActive =
        uid && activeProfileUid && uid === activeProfileUid;
      if (
        authState.user?.childProfileDetails &&
        !authState.user?.activeProfileDetails.ownsChildProfileData
      ) {
        return {
          ...authState,
          user: {
            ...authState.user,
            childProfileDetails: {
              ...authState.user.childProfileDetails,
              ...action.payload,
            },
          },
        };
      }
      if (authState.user?.activeProfileDetails) {
        return {
          ...authState,
          user: {
            ...authState.user,
            ...(isSameBaseAsActive ? action.payload : {}),
            activeProfileDetails: {
              ...authState.user.activeProfileDetails,
              ...action.payload,
            },
          },
        };
      }
      return {
        ...authState,
        user: {
          ...(authState.user as UserInfo),
          ...action.payload,
        },
      };
    }
    case Auth.SetDataSource: {
      if (authState.user) {
        return {
          ...authState,
          user: {
            ...authState.user,
            activeProfileDetails: {
              ...authState.user.activeProfileDetails,
              ...action.payload,
            },
          },
        };
      }
      return authState;
    }
    case Auth.UpdatingPassword: {
      return {
        ...authState,
        isUpdatingPassword: action.payload,
      };
    }
    case Auth.SetNotification: {
      const newActiveUser = {
        ...(authState.user?.activeProfileDetails as UserInfo),
        notifications: {
          ...authState.user?.activeProfileDetails?.notifications,
          [action.payload.notification]: action.payload.value,
        },
      };

      return {
        ...authState,
        user: {
          ...(authState.user as UserInfo),
          activeProfileDetails: newActiveUser,
        },
      };
    }
    case Auth.SetNotificationAuthUser: {
      return {
        ...authState,
        user: {
          ...(authState.user as UserInfo),
          notifications: {
            ...authState.user?.notifications,
            [action.payload.notification]: action.payload.value,
          },
        },
      };
    }
    default:
      return authState;
  }
}

export function resetError() {
  return {
    type: Auth.ResetError,
  };
}

export function setAuth(action: AuthActions): AuthActions {
  return action;
}

export function enter2faCode(payload: boolean) {
  return {
    type: Auth.Enter2faCode,
    payload,
  };
}

export function loadingUser() {
  return {
    type: Auth.LoadingUser,
  };
}

export function cancellationSurveyCompleted() {
  return {
    type: Auth.CancellationSurveyCompleted,
  };
}

function setShouldShowCancellationSurvey(payload: boolean) {
  return {
    type: Auth.SetShouldShowCancellationSurvey,
    payload,
  };
}

const checkUserCancellationSurvey =
  (): AppThunk => async (dispatch, getState) => {
    const res = await getUserSettings();
    if (!res.error) {
      if (
        getState().auth.user?.shouldShowCancellationSurvey !==
        res.data.shouldShowCancellationSurvey
      ) {
        dispatch(
          setShouldShowCancellationSurvey(
            res.data.shouldShowCancellationSurvey,
          ),
        );
      }
    }
  };

export const useCheckUserCancellationSurveyOnFocus = () => {
  // When the user comes back from managing their subscription, we refetch the user's
  // shouldShowCancellationSurvey prop in case they've cancelled their plan.
  const dispatch = useDispatch();

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      dispatch(checkUserCancellationSurvey());
    }
  };
  document.addEventListener("webkitvisibilitychange", handleVisibilityChange);

  useEffect(() => {
    return () => {
      document.removeEventListener(
        "webkitvisibilitychange",
        handleVisibilityChange,
      );
    };
  });
};

export const loadUser =
  (type: LoadUserType): AppThunk =>
  async (dispatch, getState) => {
    if (type !== LoadUserType.UserUpdate) {
      dispatch(setAuth({ type: Auth.LoadingUser }));
    }
    const res = await getUserSettings();

    if (!res.error) {
      // Get out the current user from redux before we update it
      const currentUser = getState().auth.user;
      // The new user data
      const newUser = res.data;
      // Check if there are any changes, and send that to posthog
      await identifyPosthogUser(res.data.uid, newUser, currentUser);

      identifyIntercomUser(newUser);

      // Update redux
      dispatch(
        setAuth({
          type:
            type === LoadUserType.Signup
              ? Auth.SignupSuccess
              : Auth.LoginSuccess,
          user: newUser,
        }),
      );
      dispatch(setAuth({ type: Auth.UserLoaded }));

      localStorage.setItem(LocalStorageKey.HasPreviousLogin, "true");

      identifyGoogleUser(newUser.uid);

      queryClient.invalidateQueries({ queryKey: syncKeys.all() });

      /**
       * if the user has updated the language on the login/signup page, we want
       * to update their user document to reflect this change, else, we want to
       * load their preferred language from their user document
       *  */
      const state = getState();
      const langUpdated = state.lang.updated;
      if (langUpdated) {
        const newLang = state.lang.current;
        if (newLang !== res.data.activeProfileDetails.language) {
          dispatch(updateLanguage(newLang));
        }
        dispatch(setUpdateLogin(false));
      } else if (res.data.activeProfileDetails.language) {
        dispatch(setLanguagePreference(res.data.activeProfileDetails.language));
        setMomentLocale(res.data.activeProfileDetails.language);
      } else {
        // If there is no language set, set moment locale to english
        setMomentLocale(SupportedLang.En);
      }

      const analytics = await Analytics.getInstance();
      analytics.identify({ user: res.data });

      if (type === LoadUserType.Login) {
        captureGoogleAnalytics({
          category: "auth",
          action: "login success",
          label: "login",
        });
      }

      if (type === LoadUserType.Signup) {
        postSignUpUnbounceAnalytics();
        captureTwitterAnalytics("Lead");
        captureGoogleAnalytics({
          category: "auth",
          action: "signup success",
          label: "signup",
        });
      }

      if (type === LoadUserType.Login || type === LoadUserType.Signup) {
        setDatadogUser(res.data.uid);
      }

      dispatch(loadTaxSettings());
    } else {
      // user not logged in
      dispatch(setAuth({ type: Auth.UserLoaded }));
    }
  };

export const signup =
  (data: SignupDetails | SignupDetailsAccountant): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setAuth({ type: Auth.Signup }));
    const res = await user.signup(data);

    if (!res.error) {
      dispatch(loadUser(LoadUserType.Signup));
    } else {
      dispatch(
        setAuth({
          type: Auth.SignupFail,
          error: res.msg || getState().lang.map.auth.errors.invalidSignup,
        }),
      );
    }
  };

export const useAuthWithWallet = () => {
  const { signMessageAsync, isPending: isLoadingSign } = useSignMessage();
  const dispatch = useDispatch();
  const lang = useLang();
  const { isPending: isLoadingConnect } = useConnect();
  const { isPending: isLoadingDisconnect } = useDisconnect();
  const [isLoadingMoralis, setIsLoadingMoralis] = useState(false);
  const [isLoadingOverall, setIsLoadingOverall] = useState(false);
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const isLoading =
    isLoadingOverall ||
    isLoadingSign ||
    isLoadingConnect ||
    isLoadingMoralis ||
    isLoadingDisconnect;

  const handleError = (msg: string | undefined) => {
    if (isConnected) {
      disconnect();
    }
    dispatch(
      setAuth({
        type: Auth.SignupFail,
        error: msg || lang.auth.errors.invalidSignup,
      }),
    );
  };

  const authWithWallet = async () => {
    try {
      setIsLoadingOverall(true);
      if (!address) return;

      // moralis limitation with number of chains defaulting to ETH https://docs.moralis.io/supported-chains
      const userData = { address, chain: 1 };

      // request message from BE
      const res = await user.requestMessage(userData);

      if (res.error) {
        handleError(res.msg);
        return;
      }

      const { message } = res.data;

      // get user to sign it via extension
      const signature = await signMessageAsync({ message });

      // verify signature
      // @todo - move into react-query
      setIsLoadingMoralis(true);
      const res2 = await user.verifyMessage({ message, signature });
      setIsLoadingMoralis(false);

      if (res2.error) {
        handleError(res2.msg);
        return;
      }

      const {
        data: { authType },
      } = res2;

      if (authType === AuthType.Signup) {
        dispatch(loadUser(LoadUserType.Signup));
      } else if (authType === AuthType.Login) {
        dispatch(loadUser(LoadUserType.Login));
      } else {
        // unexpected case
        handleError(lang.auth.errors.invalidSignup);
        return;
      }
      disconnect(); // We no longer need the connection after login completes.
    } catch (e) {
      if (isConnected) {
        disconnect();
      }
      if (e instanceof ProviderRpcError) {
        dispatch(
          setAuth({
            type: Auth.SignupFail,
            error: e.shortMessage,
          }),
        );
        return;
      }
      console.error(e);
      dispatch(
        setAuth({
          type: Auth.SignupFail,
          error: lang.auth.errors.invalidSignup,
        }),
      );
    } finally {
      setIsLoadingOverall(false);
    }
  };

  return {
    authWithWallet,
    isLoading,
  };
};

export const useWalletOAuthLogin = () => {
  const { user } = useAuth();
  const { authWithWallet, isLoading: isLoadingWalletAuth } =
    useAuthWithWallet();
  const { isConnected } = useAccount();
  const { open: openAppKitModal } = useAppKit();

  // Only want rising edge of connect event so users can "reset" login on refresh
  const prevConnected = usePreviousWithUseEffect(isConnected);
  useEffect(() => {
    if (
      prevConnected === false &&
      isConnected &&
      !isLoadingWalletAuth &&
      !user
    ) {
      authWithWallet();
    }
  }, [isConnected, isLoadingWalletAuth, authWithWallet, user, prevConnected]);

  const handleWalletConnect = () => {
    if (openAppKitModal) {
      openAppKitModal();
    } else {
      authWithWallet();
    }
  };

  return {
    isLoading: isLoadingWalletAuth,
    handleWalletConnect,
  };
};

export const login =
  (
    payload: LoginDetails,
    isCodeRequired: (value: boolean) => void,
    setIsSubmitting: (value: boolean) => void,
  ): AppThunk =>
  async (dispatch, getState) => {
    const lang = getState().lang.map;
    setIsSubmitting(true);
    const res = await user.login(payload);
    csrfTokenManager.clearStoredToken();
    setIsSubmitting(false);
    if (!res.error) {
      if (res.data.codeRequired) {
        isCodeRequired(true);
        return;
      }
      dispatch(loadUser(LoadUserType.Login));
    } else {
      const message = res.msg;
      if (!message) return;
      displayMessage({
        message,
        type: DisplayMessage.Error,
      });
      dispatch(
        setAuth({
          type: Auth.LoginFail,
          error: lang.auth.errors.invalidCredentials,
        }),
      );
    }
  };

export const tokenLogin =
  (
    payload: TokenLoginDetails,
    isCodeRequired: (value: boolean) => void,
  ): AppThunk =>
  async (dispatch, getState) => {
    const lang = getState().lang.map;
    const res = await user.tokenLogin(payload);
    if (!res.error) {
      if (res.data.codeRequired) {
        isCodeRequired(true);
        return;
      }
      dispatch(loadUser(LoadUserType.Login));
    } else {
      const message = res.msg;
      if (!message) return;
      displayMessage({
        message,
        type: DisplayMessage.Error,
      });
      dispatch(
        setAuth({
          type: Auth.LoginFail,
          error: lang.auth.errors.invalidCredentials,
        }),
      );
    }
  };

export const verify2fa =
  (
    payload: Code2FADetails,
    setIsSubmitting: (value: boolean) => void,
  ): AppThunk =>
  async (dispatch, getState) => {
    const lang = getState().lang.map;
    setIsSubmitting(true);
    const res = await user.verify2FA(payload);
    setIsSubmitting(false);
    if (!res.error) {
      dispatch(loadUser(LoadUserType.Login));
    } else {
      const message = res.msg;
      if (!message) return;
      displayMessage({
        message,
        type: DisplayMessage.Error,
      });
      dispatch(
        setAuth({
          type: Auth.LoginFail,
          error: lang.auth.errors.invalidCode,
        }),
      );
    }
  };

export const useLogin = (flow: "login" | "connect-provider") => {
  const dispatch = useDispatch();
  const lang = useLang();
  return async ({
    email,
    password,
    captcha,
    captchaProvider,
    isCodeRequired,
    setIsCodeRequired,
    setIsSubmitting,
    removeAuthWarning,
    connectProviderToken,
  }: {
    email: string;
    password: string;
    captcha: string | null;
    captchaProvider?: CaptchaProvider;
    isCodeRequired: boolean;
    setIsCodeRequired: (value: boolean) => void;
    setIsSubmitting: (value: boolean) => void;
    removeAuthWarning: () => void;
    connectProviderToken?: string;
  }) => {
    removeAuthWarning();

    if (!isValidEmail(email)) {
      displayMessage({
        message: lang.auth.invalidCombination,
        type: DisplayMessage.Error,
      });
      return;
    }

    const analytics = await Analytics.getInstance();

    // dont trigger login_button_clicked event for submit code button
    if (!isCodeRequired) {
      analytics.track({
        event: "login_button_clicked",
        props: { email, flow },
      });
    }

    dispatch(
      login(
        {
          email,
          password,
          captcha,
          captchaProvider,
          connectProviderToken,
        },
        setIsCodeRequired,
        setIsSubmitting,
      ),
    );
  };
};

const logoutLoading = (): AuthEvent => {
  return { type: Auth.Logout };
};

export const logoutSuccess = (): AuthEvent => {
  return { type: Auth.LogoutSuccess };
};

const logoutFailure = (error: string): AuthError => {
  return { type: Auth.LogoutFail, error };
};

export const useLogout = () => {
  const dispatch = useDispatch();

  return () => {
    dispatch(logout());
  };
};

const logout = (): AppThunk => async (dispatch) => {
  dispatch(setAuth(logoutLoading()));
  const res = await user.logout();
  if (
    !res.error ||
    (res.error && res.errorType === ErrorType.NotAuthenticated)
  ) {
    dispatch(resetUserData());
    dispatch(setAuth(logoutSuccess()));
    resetPosthog();
    resetIntercom();
  } else {
    dispatch(setAuth(logoutFailure("Logout failed")));
  }
};

export const forgot =
  (payload: ForgottenPasswordDetails): AppThunk =>
  async (dispatch, getState) => {
    const lang = getState().lang.map.auth;
    dispatch(setAuth({ type: Auth.ForgotPassword }));
    const res = await user.forgottenPassword(payload);
    if (!res.error) {
      displayMessage({
        message: lang.resetEmailSent({ email: payload.email }),
        type: DisplayMessage.Success,
      });
      dispatch(setAuth({ type: Auth.ForgotPasswordSuccess }));
      return;
    }

    if (res.status === 429) {
      displayMessage({
        message: lang.tooManyPasswordResetAttempts,
        type: DisplayMessage.Error,
      });
    } else {
      displayMessage({
        message: lang.unableToResetEmail({ email: payload.email }),
        type: DisplayMessage.Error,
      });
    }

    console.error(res);
    dispatch(
      setAuth({
        type: Auth.ForgotPasswordFail,
        error: res.msg || "ForgotPasswordFail",
      }),
    );
  };

export const reset =
  (payload: ResetDetails): AppThunk =>
  async (dispatch, getState) => {
    const lang = getState().lang.map.auth;
    dispatch({ type: Auth.Reset });
    const res = await user.reset(payload);
    if (!res.error) {
      displayMessage({
        message: lang.passwordResetSuccessfully,
        type: DisplayMessage.Success,
      });
      dispatch(setAuth({ type: Auth.ResetSuccess }));
    } else {
      console.error(res);
      displayMessage({
        message: lang.invalidToken,
        type: DisplayMessage.Error,
      });
      dispatch(
        setAuth({
          type: Auth.ResetFail,
          error: res.msg || "ResetPasswordFail",
        }),
      );
    }
  };

export const unlock =
  (payload: UnlockDetails): AppThunk =>
  async (dispatch, getState) => {
    const lang = getState().lang.map.auth;
    dispatch({ type: Auth.Unlock });
    await user.unlock(payload);
    // Stating account is unlocked regardless.
    // Same as forgotten password.
    displayMessage({
      message: lang.unlock,
      type: DisplayMessage.Info,
    });
  };

export const updateDataSource =
  (ownsChildProfileData: boolean): AppThunk<Promise<{ error: boolean }>> =>
  async (dispatch, getState) => {
    const res = await settingsAPI.setDataSource(ownsChildProfileData);
    const lang = getState().lang.map;
    if (res.error) {
      console.error(res);
      displayMessage({
        message: lang.settings.accountingData.updateDataSourceError,
        type: DisplayMessage.Error,
      });
    } else {
      dispatch(setUpdateDataSource({ ownsChildProfileData }));
      displayMessage({
        message: lang.settings.accountingData.updateDataSourceSuccess,
        type: DisplayMessage.Success,
      });
      queryClient.clear();
    }
    return res;
  };

export function setUpdate(
  action:
    | UpdateCountry
    | UpdateEmail
    | UpdatePayment
    | UpdateSubscriptionVersions
    | UpdateIs2faEnabled
    | UpdateOnboarding
    | DisableOAuth
    | UpdateReOnboarding,
): AuthActions {
  return {
    type: Auth.SetUpdate,
    payload: action,
  };
}

export function setUpdateBestActiveUser(
  action:
    | UpdatePayment
    | UpdateName
    | UpdateInviteCode
    | UpdateAlpha
    | UpdateCountry
    | UpdateShowIgnored
    | UpdateRenewalErrorInfo
    | UpdateOnboarding
    | UpdateReOnboarding,
): AuthActions {
  return {
    type: Auth.SetUpdateBestActiveUser,
    payload: action,
  };
}

function setUpdateDataSource(action: UpdateOwnsChildProfileData): AuthActions {
  return {
    type: Auth.SetDataSource,
    payload: action,
  };
}

export function setUpdatingPassword(action: boolean): AuthActions {
  return {
    type: Auth.UpdatingPassword,
    payload: action,
  };
}

export function userActivated() {
  return {
    type: Auth.UserActivated,
  };
}

/**
 * Gets the details of the actual logged in user
 */
export const useUser = () => useSelector((state) => state.auth.user);

export const useIsManagingOwnBilling = () =>
  useSelector((state) => state.auth.user?.isManagingOwnBilling);

export const useIsStripeEnabled = () =>
  useSelector((state) => state.auth.user?.isStripeEnabled);

export const useIsMarkAsNFTEnabled = () =>
  useSelector(
    (state) => state.auth.user?.activeDataSource.country === Country.Italy,
  );
export const useIsPasswordSet = () =>
  useSelector((state) =>
    state.auth.user?.authMethods?.some(
      (method) => method.type === AuthMethodType.Password,
    ),
  );

/**
 * Returns whether to show the mock report or not along with the paywall reason
 */
export const usePaywallInfo = (): PaywallType => {
  const user = useUser();
  if (!user)
    return {
      isPaywalled: true,
      paywallReason: {
        reasons: [PaywallReason.free],
        partyResponsibleToAction: PartyToAction.user,
      },
    };
  return user.paywallInfo;
};

export const useApplyDiscount = () => {
  const user = useUser();

  const userDiscount = user?.discount ? Number(user.discount) : undefined;

  return (amount: number) => {
    return userDiscount ? amount * (1 - userDiscount / 100) : amount;
  };
};

/**
 * Returns whether to show the mock report or not along with the paywall reason
 */
export const useShowClientSubscribeBanner = (): boolean => {
  const user = useUser();
  if (!user) return true;
  if (!user.paywallInfo.isPaywalled) return false;

  const isClient = isClientView(user);

  const { paywallReason } = user.paywallInfo;
  return (
    paywallReason.reasons.includes(PaywallReason.free) &&
    paywallReason.partyResponsibleToAction === PartyToAction.both &&
    isClient
  );
};

export const useIsUserRequiredToActionPaywall = (): boolean => {
  const user = useUser();
  const paywallInfo = usePaywallInfo();
  if (!user || !paywallInfo.isPaywalled) return false;
  const clientView = isClientView(user);

  const baseUserToAction =
    clientView &&
    (paywallInfo.paywallReason.partyResponsibleToAction ===
      PartyToAction.user ||
      paywallInfo.paywallReason.partyResponsibleToAction ===
        PartyToAction.client);

  const clientToAction =
    !clientView &&
    paywallInfo.paywallReason.partyResponsibleToAction ===
      PartyToAction.accountant;

  const accountantToAction =
    !!user.activeProfileDetails?.clientBridge &&
    paywallInfo.paywallReason.partyResponsibleToAction === PartyToAction.both;

  return baseUserToAction || clientToAction || accountantToAction;
};

export const useIsEnterprise = () =>
  useSelector(
    (state) => (state.auth.user?.paidPlan as Plan) === Plan.Enterprise,
  );

/**
 * We get the activeProfileDetails, if this doesn't exist
 * then we just use the default user
 */
export const useActiveUser = () =>
  useSelector(
    (state) => state.auth.user?.activeProfileDetails || state.auth.user,
  );

export const useIsActiveUserABridge = () => {
  const activeUser = useActiveUser();
  if (!activeUser) return false;

  const isClientBridge = !!activeUser.clientBridge;
  if (isClientBridge) return true;

  return false;
};

export const useIsAccountant = () =>
  useSelector((state) => state.auth.user?.paidPlan === Plan.Accountant);

export const useIsCollaborator = () =>
  useSelector((state) => state.auth.user?.paidPlan === Plan.Collaborator);

export const useIsManagingClients = () => {
  const isAccountant = useIsAccountant();
  const isCollaborator = useIsCollaborator();
  return isAccountant || isCollaborator;
};

export const useIsManagedClient = () =>
  useSelector((state) => {
    if (!state.auth.user?.activeProfileDetails) {
      return false;
    }
    return (
      state.auth.user?.childProfileDetails ||
      state.auth.user?.parentProfileDetails
    );
  });

export const useIsClientManagingChildProfile = () =>
  useSelector((state) => !!state.auth.user?.childProfileDetails);

export const useBestUser = () =>
  useSelector((state) => {
    if (
      !state.auth.user?.activeProfileDetails?.ownsChildProfileData &&
      state.auth.user?.childProfileDetails
    ) {
      return state.auth.user.childProfileDetails;
    }
    if (state.auth.user?.activeProfileDetails) {
      return state.auth.user.activeProfileDetails;
    }
    return state.auth.user;
  });

export const useHighestRankingPlanUser = () => {
  const user = useUser();
  return useFeatureFlagFeatureAccessUserWrapper(
    getHighestPlanRankCurrentUser(user),
  );
};

export const useCanAccessFeature = (feature: Features) => {
  const highestRankingPlanUser = useHighestRankingPlanUser();
  return highestRankingPlanUser?.features[feature];
};

export const useIsLockPeriodEnabled = () => {
  // if a user has a locked period they should be able to interact with the feature
  // this means if they were free and locked a period while they still had access they should be able to see them
  // if they remove all their periods, they may not be able to see feature again unless they upgrade

  const lockPeriodEndDate = useGetLockPeriodEndDateIfExists();
  const activeDataSource = useBestUser();
  const isNotCAOrUK =
    activeDataSource?.country !== Country.Canada &&
    activeDataSource?.country !== Country.UK;

  return (
    (useCanAccessFeature(Features.LockTaxYear) && isNotCAOrUK) ||
    lockPeriodEndDate
  );
};

const useFeatureFlagFeatureAccessUserWrapper = (
  user: (BaseUserDetails & RenewalErrorInfo) | undefined,
) => {
  const tipsPaywall = useExperimentVariant(FeatureFlag.TipsPaywall, "test");

  if (!user) return user;

  // Tips experiments
  if (tipsPaywall && user.paidPlan === Plan.Free) {
    return {
      ...user,
      features: {
        ...user.features,
        [Features.Tips]: true,
      },
    };
  }

  return user;
};

export const useIsFreePlan = () => {
  const user = useHighestRankingPlanUser();
  return user?.paidPlan === Plan.Free;
};

/**
 * Paywalls the user if they should be paywalled, otherwise execute a passed function
 */
export const useShowPaywallIfRequired = () => {
  const bestUser = useHighestRankingPlanUser();
  const paywallContext = useContext(PaywallModalContext);
  const paywallInfo = usePaywallInfo();
  const canSeeTimeframe = useCanSeeTimeframe();

  return (
    reportTypes: (NormalReportType | ReportDownloadType)[],
    reportFormat: ReportFormat,
    successFunction: () => any,
  ) => {
    if (paywallInfo.isPaywalled) {
      paywallContext?.setSampleReportDownload({
        sampleName: reportTypes,
        sampleType: reportFormat,
        downloadSampleFunc: () => successFunction(),
      });
      const { paywallReason } = paywallInfo;
      switch (paywallReason.reasons[0]) {
        case PaywallReason.tradeType:
          return paywallContext?.setSelectedModal(
            PaywallModalType.SmartContractInteraction,
          );
        default:
          // if trading stock report
          if (reportTypes.includes(NormalReportType.TradingStockAu)) {
            return paywallContext?.setSelectedModal(
              PaywallModalType.TradingStockReport,
            );
          }
          // if advanced report
          if (
            AdvancedReports.some((advancedReportType) =>
              reportTypes.includes(advancedReportType),
            )
          ) {
            return paywallContext?.setSelectedModal(
              PaywallModalType.AdvancedReports,
            );
          }
          // base case
          return paywallContext?.setSelectedModal(PaywallModalType.AllReports);
      }
    }

    if (!canSeeTimeframe) {
      return paywallContext?.setSelectedModal(PaywallModalType.MultiTaxYears);
    }

    if (
      reportTypes.some(
        (reportType) => reportType === NormalReportType.TradingStockAu,
      ) &&
      !bestUser?.features[Features.TradingStockReport]
    ) {
      return paywallContext?.setSelectedModal(
        PaywallModalType.TradingStockReport,
      );
    }

    if (
      reportTypes.some((reportType) =>
        AdvancedReports.find((report) => report === reportType),
      ) &&
      !bestUser?.features[Features.AdvancedReports]
    ) {
      return paywallContext?.setSelectedModal(PaywallModalType.AdvancedReports);
    }

    return successFunction();
  };
};

export const useIsDeleteAccountDisabled = () => {
  const isActiveUserClient = useIsActiveUserABridge();
  const isClientManagingChildProfile = useIsClientManagingChildProfile();
  const isAccountant = useIsAccountant();
  const isEnterprise = useIsEnterprise();
  const isCollaborator = useIsCollaborator();
  if (isAccountant || isEnterprise || isCollaborator) {
    if (isClientManagingChildProfile) {
      return true;
    }
    if (isActiveUserClient) {
      return false;
    }
    return true;
  }
  // prevent client from deleting their account when using the accountant
  // data source (they need to revoke their invitation first)
  if (isActiveUserClient && isClientManagingChildProfile) {
    return true;
  }
  return false;
};

export const useLocalCurrency = () => useBestUser()?.localCurrency;

export const useCountry = () => useBestUser()?.country;

export const useName = () => useBestUser()?.name;

// email should be the linked client's email if applicable
export const useEmail = () => {
  const user = useUser();
  const isActiveClient = useIsActiveUserABridge();
  if (isActiveClient) return user?.childProfileDetails?.email;
  return user?.email;
};

export const useLocalCurrencySymbol = () => {
  const localCurrency = useLocalCurrency();
  if (localCurrency) {
    return currencySymbol[localCurrency];
  }
  return "$";
};

export const useAvailableTimezones = () => {
  const getTimeZoneList = moment.tz.names().map(
    (t) =>
      ({
        label: `(GMT${moment.tz(t).format("Z")}) ${t}`,
        value: t,
      }) as {
        label: string;
        value: string;
      },
  );
  const sortByZone = (a: string, b: string) => {
    const [ahh, amm] = a.split("GMT")[1].split(")")[0].split(":");
    const [bhh, bmm] = b.split("GMT")[1].split(")")[0].split(":");
    return (
      (((+ahh as any) * 60 + amm) as any) - (((+bhh as any) * 60 + bmm) as any)
    );
  };
  return getTimeZoneList.sort((a, b) => sortByZone(a.label, b.label));
};

export const useDateMomentFormat = () => "YYYY-MM-DD";

export const useDateTimeMomentFormat = () => "YYYY-MM-DD HH:mm:ss";

export const useDateTimePlaceholder = () => "YYYY-MM-DD HH:mm:ss";

export const useDatePlaceholder = () => "YYYY-MM-DD";

export const useAuth = () => useSelector((state) => state.auth);

export const usePlan = () =>
  useSelector((state) => state.auth?.user?.paidPlan || null);

export const useConsidersWashSales = () => {
  const inventoryMethod = useInventoryMethod();
  return considersWashSales(inventoryMethod as InventoryMethod);
};

/**
 * Has the user actually paid for the plan
 */
export const useUserHasPaid = () =>
  useSelector(
    (state) =>
      state.auth.user?.paidPlan &&
      ![Plan.Free, Plan.Collaborator].includes(state.auth.user.paidPlan),
  );

export const useUserArchiveStatus = () =>
  useSelector((state) => state.auth.user?.activeDataSource.archiveStatus);

export const useIsLoadingUser = () =>
  useSelector((state) => state.auth.loading);

// @todo replace this with useIsClientPaidByClient
export const useClientPaid = (): boolean =>
  useSelector((state) => {
    if (state.auth.user?.childProfileDetails) {
      return isPaidPlan(state.auth.user.childProfileDetails.paidPlan);
    }
    return false;
  });

// if client calls this
//    returns true if they are paying for plan and linked to accountant
// if accountant/collaborator calls this
//    returns true if viewing client account the linked client is paying for
export const useIsClientPaidByClient = (): boolean => {
  const isManagingClients = useIsManagingClients();
  const isActiveUserClient = useIsActiveUserABridge();
  const user = useUser();
  return useSelector((state) => {
    if (isManagingClients) {
      return (
        isActiveUserClient && isPaidPlan(user?.childProfileDetails?.paidPlan)
      );
    }
    return isActiveUserClient && isPaidPlan(state.auth.user?.paidPlan);
  });
};

// @todo replace this with useIsClientPaidByAccountant
export const useClientAccountantPaid = (): boolean =>
  useSelector((state) => {
    if (
      state.auth.user?.parentProfileDetails &&
      state.auth.user?.childProfileDetails
    ) {
      return isPaidPlan(state.auth.user.parentProfileDetails.paidPlan);
    }
    return false;
  });

/**
 * Get who's working data we should be viewing
 */
export const useOwnsChildProfileData = (): boolean => {
  const ownsChildProfileData = useSelector(
    (state) => state.auth.user?.activeProfileDetails.ownsChildProfileData,
  );
  return Boolean(ownsChildProfileData);
};

export const useIsUpdatingPassword = (): boolean => {
  const isUpdatingPassword = useSelector(
    (state) => state.auth.isUpdatingPassword,
  );
  return Boolean(isUpdatingPassword);
};

export const useIsShowingIgnoredTransactions = (): boolean => {
  const user = useBestUser();
  return user?.showSpamTransactions || false;
};

export const useUserHasEmail = (): boolean => {
  const user = useBestUser();
  return !!user?.email;
};

export const setNotification = (notification: string, value: boolean) => {
  return {
    type: Auth.SetNotification,
    payload: { notification, value },
  };
};

export const setNotificationAuthUser = (
  notification: string,
  value: boolean,
) => {
  return {
    type: Auth.SetNotificationAuthUser,
    payload: { notification, value },
  };
};
