import { Env } from "@ctc/types";
import { useCallback } from "react";

import {
  type AnalyticEvent,
  type EventProperties,
} from "~/analytics/analyticsProperties";
import {
  captureAnalytics,
  type StandardEventProperties,
  useUserEventProps,
} from "~/analytics/posthog";

/**
 * The type of the analytic event e.g. REPORT_PAGE.VIEW or
 * REPORT_PAGE.REPORT_DOWNLOAD_BUTTON_CLICKED
 *
 * Handles nesting up to 3 levels deep
 */
export type AnalyticEventType =
  typeof AnalyticEvent extends Record<string, infer U>
    ? U extends Record<string, infer V>
      ? V extends string
        ? V
        : never
      : U extends string
        ? U
        : never
    : never;

/**
 * The extra properties we include in the event based on the event chosen
 */
type SpecificEventProperties<TAnalyticEventType extends AnalyticEventType> =
  TAnalyticEventType extends keyof EventProperties
    ? EventProperties[TAnalyticEventType]
    : never;
function captureAnalyticsEvent<TAnalyticEventType extends AnalyticEventType>(
  event: TAnalyticEventType,
  properties: StandardEventProperties &
    SpecificEventProperties<TAnalyticEventType>,
) {
  if (import.meta.env.VITE_APP_ENV === Env.Local) {
    // For local development, log the event to the console
    // eslint-disable-next-line no-console
    console.debug("Analytics event fired:", event, properties);
  }
  captureAnalytics(event, properties);
}

/**
 * Capture general analytics event
 * a replacement for/duplicate of useCaptureAnalytics
 * @returns
 */
export function useCaptureAnalyticEvents() {
  const userProps = useUserEventProps();

  const callback = useCallback(
    <TAnalyticEventType extends AnalyticEventType>(
      event: TAnalyticEventType,
      ...args: TAnalyticEventType extends keyof EventProperties
        ? [properties: EventProperties[TAnalyticEventType]]
        : [properties?: undefined]
    ) => {
      const props = (args[0] ?? {}) as any;
      // additional properties overrides default properties
      captureAnalyticsEvent(event, { ...userProps, ...props });
    },
    [userProps],
  );
  return callback;
}
