import { Country, Plan } from "@ctc/types";
import moment from "moment-timezone";
import { css } from "styled-components/macro";

import { TableHeaders } from "~/components/clients/helpers/enums";
import { type SortOption } from "~/components/clients/helpers/types";
import { devices } from "~/components/ui/theme/legacy";
import { PlanRank } from "~/constants/constants";
import { bubbleUndefinedToTheBottom } from "~/lib/bubbleUndefinedToTheBottomSort";
import { WhoIsPaying } from "~/types/enums";
import { type ClientDetails, isBusinessPaidPlanType } from "~/types/index";

export function shouldAllowCancelSub(
  client: ClientDetails,
  allowUncancelSub: boolean,
): boolean {
  // Only allow if the accountant is paying, otherwise it's not relevant
  // as this is the accountant's client menu
  const isAccountantOrBothPaying = [
    WhoIsPaying.ACCOUNTANT,
    WhoIsPaying.BOTH,
  ].includes(client.whoIsPaying as WhoIsPaying);

  // The user must not be on a free plan and have paid at least once
  const isNonFreePlanWithPayment =
    client.paidPlan !== Plan.Free && client.hasPaidAtLeastOnce;

  // If the user is on a business plan, they must have a billing date
  // ie. they must have not already cancelled their plan
  const isBusinessClientWithABillingDate =
    isBusinessPaidPlanType(client.paidPlan) && !!client.paymentDue;

  return (
    (!allowUncancelSub &&
      isNonFreePlanWithPayment &&
      // We need to use || here instead of ?? because we want to check for falsey values
      isAccountantOrBothPaying) || // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
    isBusinessClientWithABillingDate
  );
}

export function sortByField(
  activeSort: SortOption,
  a: ClientDetails,
  b: ClientDetails,
) {
  const { field, isAscending } = activeSort;
  const order = isAscending ? 1 : -1;
  switch (field) {
    case TableHeaders.Name: {
      return a.name.localeCompare(b.name) * order;
    }
    case TableHeaders.TxCount: {
      return (a.txCount - b.txCount) * order;
    }
    case TableHeaders.DateAdded: {
      return (
        (new Date(a.dateCreated).valueOf() -
          new Date(b.dateCreated).valueOf()) *
        order
      );
    }
    case TableHeaders.BillingDate: {
      return (
        (new Date(a.paymentDue ?? 0).valueOf() -
          new Date(b.paymentDue ?? 0).valueOf()) *
        order
      );
    }
    case TableHeaders.Plan: {
      return (PlanRank[a.paidPlan] - PlanRank[b.paidPlan]) * order;
    }
    case TableHeaders.Subscriber: {
      const whoIsPayingSortMap: Record<WhoIsPaying, WhoIsPaying | undefined> = {
        [WhoIsPaying.USER]: WhoIsPaying.USER,
        [WhoIsPaying.ACCOUNTANT]: WhoIsPaying.ACCOUNTANT,
        [WhoIsPaying.BOTH]: WhoIsPaying.BOTH,
        [WhoIsPaying.NOBODY]: undefined,
      };
      return bubbleUndefinedToTheBottom<string>(
        (a, b) => a.localeCompare(b) * order,
      )(
        whoIsPayingSortMap[a.whoIsPaying ?? WhoIsPaying.NOBODY],
        whoIsPayingSortMap[b.whoIsPaying ?? WhoIsPaying.NOBODY],
      );
    }
  }
  return 0;
}

export function formatDate(date?: string, country?: Country) {
  if (!date) {
    return "";
  }

  // If the user logged in is from the USA, format the date in their locale format
  if (country === Country.USA) {
    return moment(date).format("MMM D, YYYY");
  }

  return moment(date).format("DD MMM YYYY");
}

export function isDemoAccount(client: ClientDetails) {
  return client.paidPlan === Plan.Accountant;
}

export const RowStyleGridCss = css`
  min-width: 100%;
  width: fit-content;
  display: grid;
  row-gap: 1rem;
  column-gap: 0.5rem;
  grid-template-rows: auto;
  grid-template-columns: 0.25rem minmax(20rem, 1fr) 6.25rem 6.75rem 6.75rem 8rem 6rem 3rem;
  grid-template-areas: "active name tx date billing plan sub action";

  @media ${devices.laptop} {
    grid-template-columns: 0.25rem minmax(20rem, 1fr) 6.25rem 6.75rem 6.75rem 8rem 6rem 10rem;
  }
`;

export function clientCancelOptions(client: ClientDetails): {
  allowClientCancel: boolean;
  allowClientUncancel: boolean;
} {
  const allowClientUncancel = client.cancelAtPeriodEnd;
  const allowClientCancel = shouldAllowCancelSub(client, !!allowClientUncancel);
  return { allowClientCancel, allowClientUncancel: !!allowClientUncancel };
}
