import "react-lazy-load-image-component/src/effects/blur.css";

import { Blockchain, type EntityAddress } from "@ctc/types";
import { AccountBalance, DescriptionOutlined, Help } from "@mui/icons-material";
import { Box } from "@mui/material";
import Identicon from "@polkadot/react-identicon";
import { memo } from "react";

import fallbackLogo from "~/assets/ctc-logo.png";
import { getBlockie, isValidAddress } from "~/components/transactions/helpers";
import {
  type FallbackSrcs,
  LazyLoadImageWithFallback,
} from "~/components/transactions/ImageWithFallback";
import { BlockchainSymbolPosition } from "~/components/ui/enums";
import { DepositIcon } from "~/components/ui/Icons";
import { useDesign } from "~/hooks/useTheme";
import { getCloudFrontImageUrl, IconType } from "~/lib/getCloudFrontImageUrl";
import { IN_FLIGHT_EXCHANGE_ID } from "~/lib/holdings";
import { isValidPolkadotChainAddress } from "~/services/wallet/isValidWalletAddress";
import { getEntityExchangeLogoName, useEntityLookup } from "~/state/entities";
import {
  DefaultBlockchainCurrency,
  type Entity,
  isExchangeEntity,
  isPolkadotCompatibleChain,
} from "~/types/index";

function formatName(name: string) {
  return name.replace("(bep-20)", "").trim();
}

function getExchangeLogo(name: string, extension: string): string {
  return getCloudFrontImageUrl(
    IconType.ExchangeLogos,
    name.toLowerCase(),
    extension,
  );
}

export function getIntegrationLogoSrc(integration?: string, fileType?: string) {
  if (!integration) {
    return undefined;
  }
  return new URL(
    `../../assets/integrations/${integration.toLowerCase()}.${
      fileType || "png"
    }`,
    import.meta.url,
  ).href;
}

export function getBlockchainLogoSrc(blockchain?: string) {
  const fallbacks = [];

  if (!blockchain) {
    return undefined;
  }

  if (blockchain === "unspecified") {
    fallbacks.push(getExchangeLogo("unspecified", "svg"));
  }

  // if valid exchange name or cryptocurrency name

  fallbacks.push(getExchangeLogo(blockchain, "png"));

  return fallbacks.flat().filter(Boolean) as string[];
}

/**
 * Set of addresses that we use a custom image for
 */
const OverrideBlockie = new Set([
  "0x60ae616a2155ee3d9a68541ba4544862310933d4", // Trader Joe
  "0xe592427a0aece92de3edee1f18e0157c05861564", // UniSwap V3
]);

export function getExchangeLogoSrc(
  name: string,
  backupName?: string,
  currencySymbol?: string,
  blockchain?: string,
) {
  const fallbacks: string[] = [];
  const fName = formatName(name);
  const fBackupName = backupName && formatName(backupName);

  if (name === "unknown") {
    return fallbackLogo;
  }
  if (name === IN_FLIGHT_EXCHANGE_ID) {
    return getExchangeLogo("inflight", "svg");
  }

  // Check if the name is a valid crypto address
  const isValidCryptoAddress =
    currencySymbol && isValidAddress(fName, currencySymbol, blockchain);

  // Only fetch if its not a wallet address or in the OverrideBlockie set
  if (!isValidCryptoAddress || OverrideBlockie.has(fName)) {
    fallbacks.push(
      getExchangeLogo(fName, "png"),
      getExchangeLogo(fName, "svg"),
    );
  }

  // if valid crypto address, add blockie logo
  if (isValidCryptoAddress) {
    const logo = getBlockie(name);
    if (logo) {
      fallbacks.push(logo);
      return fallbacks;
    }
  }

  // look for backup source
  if (fBackupName) {
    const backupPngLogo = getExchangeLogo(fBackupName, `png`);
    const backupSvgLogo = getExchangeLogo(fBackupName, `svg`);
    fallbacks.push(backupPngLogo, backupSvgLogo);
  }

  // if valid backup name
  if (
    currencySymbol &&
    fBackupName &&
    isValidAddress(fBackupName, currencySymbol)
  ) {
    const logo = getBlockie(fBackupName);
    if (logo) {
      fallbacks.push(logo);
      return fallbacks;
    }
  }

  return fallbacks;
}

export function getFallbackImage() {
  return fallbackLogo;
}

function getFallbackEntityExchangeLogoSrc(entity: Entity) {
  const addresses: EntityAddress[] = [];

  if (isExchangeEntity(entity)) {
    const exchangeEntity = entity;
    addresses.push(...exchangeEntity.globalAddresses);
  }
  addresses.push(...entity.addresses);

  if (addresses.length > 0) {
    const address = addresses[0];
    // Global addresses dont have a blockchain, so just use ETH
    const blockchain = address.blockchain ?? Blockchain.ETH;
    return getExchangeLogoSrc(
      address.address,
      undefined,
      DefaultBlockchainCurrency[blockchain],
      blockchain,
    );
  }

  return undefined;
}

// These logos are black
// So we will handle them specially so they show up in dark mode
const blackLogos = [
  "zeroexprotocol",
  "arweave",
  "bitpanda",
  "coinjar",
  "coinjarexchange",
  "elrond",
  "hollaex",
  "ledgerwallet",
  "newton",
  "wealth99",
];

// Add type for the mixBlendMode style
type ImageWrapperStyle = {
  filter?: string;
  mixBlendMode?: "difference";
  display: string;
  height: number;
  width: number;
};

export const ExchangeLogo = memo(function ExchangeLogoRaw({
  name,
  backupName,
  useFileIconForExchange,
  walletAddress,
  currencySymbol,
  blockchain,
  className,
  width = 24,
  height = 24,
  margin = "0.5rem",
  showBlockchainSymbol = false,
  blockchainSymbolPosition = BlockchainSymbolPosition.BottomRight,
  showErrorIcon = false,
  showDepositIcon = false,
  entity,
  background = "transparent",
  disableEntityIcon = false,
}: {
  name: string;
  backupName?: string;
  walletAddress?: string;
  useFileIconForExchange?: boolean;
  currencySymbol?: string;
  blockchain?: string;
  className?: string;
  height?: number;
  width?: number;
  margin?: string;
  showBlockchainSymbol?: boolean;
  blockchainSymbolPosition?: BlockchainSymbolPosition;
  showErrorIcon?: boolean;
  showDepositIcon?: boolean;
  entity?: Entity;
  background?: string;
  disableEntityIcon?: boolean;
}) {
  const { tokens } = useDesign();
  const lookedUpEntity =
    useEntityLookup(name, blockchain as Blockchain) || entity;
  const newName =
    (!disableEntityIcon
      ? getEntityExchangeLogoName(lookedUpEntity)
      : undefined) ?? name;

  const blockchainSrc = getBlockchainLogoSrc(blockchain);

  let src: FallbackSrcs = getExchangeLogoSrc(
    newName,
    backupName,
    currencySymbol,
    blockchain,
  );

  if (!src.length && lookedUpEntity && !disableEntityIcon) {
    src = getFallbackEntityExchangeLogoSrc(lookedUpEntity);
  }

  const position = {
    [BlockchainSymbolPosition.TopRight]: {
      top: -(height / 2),
      right: -(height / 4),
    },
    [BlockchainSymbolPosition.BottomRight]: {
      top: height / 2,
      right: -height / 4,
    },
  };

  const isPolkadotAddress =
    !!blockchain &&
    isPolkadotCompatibleChain(blockchain) &&
    isValidPolkadotChainAddress(name, blockchain);

  const renderLogo = () => {
    if (showErrorIcon) {
      return (
        <Box fontSize={height || 24}>
          <Help style={{ color: tokens.icon.warning }} fontSize="inherit" />
        </Box>
      );
    }

    if (isPolkadotAddress) {
      return (
        <Identicon
          value={walletAddress || name}
          theme="polkadot"
          size={height}
          style={{ cursor: "auto" }}
        />
      );
    }

    if (useFileIconForExchange) {
      return (
        <DescriptionOutlined
          style={{
            color: tokens.icon.default,
            width,
            height,
          }}
        />
      );
    }

    if (newName === "bank") {
      return (
        <AccountBalance
          style={{
            color: tokens.icon.default,
            width,
            height,
          }}
        />
      );
    }

    const imageWrapperStyle: ImageWrapperStyle = blackLogos.includes(newName)
      ? {
          filter: "invert(1)",
          mixBlendMode: "difference",
          display: "inline-block",
          height,
          width,
        }
      : {
          display: "inline-block",
          height,
          width,
        };

    const srcWithFallback = (() => {
      if (typeof src === "string") {
        return [src, getFallbackImage()];
      }
      if (src === undefined) {
        return [getFallbackImage()];
      }
      return src.concat(getFallbackImage());
    })();
    return (
      <Box
        display="flex"
        style={{
          background,
          borderRadius: width / 2,
        }}
      >
        <LazyLoadImageWithFallback
          wrapperProps={{
            style: imageWrapperStyle,
          }}
          height={height}
          width={width}
          src={srcWithFallback}
          alt={`${name}-logo`}
          effect="blur"
          style={{
            borderRadius: width / 2,
          }}
        />
      </Box>
    );
  };

  return (
    <Box
      className={className}
      margin={margin}
      height={height}
      display="inline-flex"
      position="relative"
    >
      {renderLogo()}
      {showBlockchainSymbol && blockchainSrc && (
        <Box position="absolute" {...position[blockchainSymbolPosition]}>
          <LazyLoadImageWithFallback
            height={height / 2}
            width={width / 2}
            src={blockchainSrc as string[]}
            alt={`${blockchain}-logo`}
            effect="blur"
            style={{
              borderRadius: width / 4,
              background: tokens.background.neutral.lowest.default,
            }}
          />
        </Box>
      )}
      {showDepositIcon && (
        <Box position="absolute" top={height / 9} right={-(height / 4)}>
          <DepositIcon />
        </Box>
      )}
    </Box>
  );
});
