import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { type ComponentPropsWithRef } from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import styled from "styled-components/macro";

const styling = css`
  && {
    color: ${({ theme }) => theme.tokens.text.brand};
    background-color: ${({ theme }) => theme.tokens.button.subtle.default};
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px rgba(0, 0, 0, 0) solid;
    border-radius: ${({ theme }) =>
      theme.tokens.buttons.secondary.borderRadius};
    justify-content: ${({ theme }) => theme.tokens.buttons.secondary.alignment};
  }

  & .MuiButton-sizeMedium {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  & .MuiButton-sizeSmall {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &:hover {
    border: 1px ${({ theme }) => theme.tokens.border.brand} solid;
    background-color: ${({ theme }) => theme.tokens.button.subtle.hover};
  }

  &.Mui-focusVisible {
    background-color: ${({ theme }) => theme.tokens.button.subtle.pressed};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.text.disabled};
    background-color: ${({ theme }) => theme.tokens.button.disabled};
  }
`;
export const SecondaryButtonLink = styled(
  (
    props: ComponentPropsWithRef<typeof Link> &
      ComponentPropsWithRef<typeof Button>,
  ) => <Button component={Link} {...props} />,
)`
  ${styling}
`;

export const SecondaryLoadingButton = styled(LoadingButton)`
  ${styling}
`;

export const SecondaryButton = styled(Button)`
  ${styling}
`;
