import { Cached } from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useLang } from "~/redux/lang";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    editButtons: {
      display: "flex",
      marginLeft: spacing(2),
      "& > button": {
        marginLeft: spacing(1),
      },
    },
  }),
);

type ActionButtonsProps = {
  isLoading: boolean;
  onRefreshClick: () => void;
};

export function ActionButtons({
  isLoading,
  onRefreshClick,
}: ActionButtonsProps) {
  const lang = useLang().settings.reportActionButton;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip title={isLoading ? lang.loadingTooltip : lang.tooltip}>
        <span>
          <TertiaryButton
            size="small"
            variant="outlined"
            onClick={onRefreshClick}
            disabled={isLoading}
            sx={{
              fontWeight: 500,
              fontSize: "0.75rem",
            }}
            endIcon={
              isLoading ? (
                <CircularProgress size="1.125rem" />
              ) : (
                <Cached color="inherit" />
              )
            }
          >
            {isLoading ? lang.loadingAction : lang.action}
          </TertiaryButton>
        </span>
      </Tooltip>
    </div>
  );
}
