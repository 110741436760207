export default {
  importInstructions: {
    abra: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Le calculateur de taxes sur les crypto-monnaies ignorera les transferts impliquant des crypto-monnaies « générant des intérêts » car il ne s'agit pas d'événements imposables. Le nombre de lignes importées peut donc être inférieur au nombre total de lignes d'origine dans le fichier CSV.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Veuillez lire et suivre les étapes d'exportation détaillées pour Abra sur leur site Web : https://support.abra.com/hc/en-us/articles/360002528832-How-can-I-obtain-my-full-transaction-history- pour obtenir un fichier CSV par e-mail.",
              1: () =>
                "Une fois que vous avez reçu l'e-mail avec le fichier CSV, téléchargez-le et chargez le fichier CSV ici.",
              2: () =>
                "Si vous avez plusieurs portefeuilles Abra, répétez ces étapes pour chacun d'eux.",
            },
          },
        },
      },
    },
    abstract: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Open your Abstract wallet",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the address into Crypto Tax Calculator, add an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    agoric: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Agorique en début de développement",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Agoric dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    airswap: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () => "Sélectionnez l'échange dans le menu déroulant",
              3: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              4: () => "Conserver les autres paramètres par défaut",
              5: () => "Cliquez sur « Récupérer l'historique »",
              6: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              7: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    akash: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Akash dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    algorand: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () => "Copiez l'adresse de votre portefeuille public Algorand",
              2: () =>
                "Dans CTC, choisissez la blockchain concernée dans la liste déroulante",
              3: () =>
                "Collez l'adresse de votre portefeuille public et cliquez sur « Importer un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    altcointrader: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte AltCoinTrader",
              1: () =>
                "Téléchargez vos fichiers CSV de dépôt, de retrait et de trading depuis AltCoinTrader",
              2: () =>
                "Téléchargez ces fichiers ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    aptos: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille Aptos",
              1: () =>
                "Copiez l'adresse de votre portefeuille public commençant par « 0x... »",
              2: () =>
                "Collez l'adresse dans Crypto Tax Calculator, ajoutez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    arbitrum: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons désormais en charge les jetons ERC-1155. Cependant, nous ne pouvons pas importer vos soldes actuels pour ces jetons. Cela peut entraîner des écarts entre le solde importé depuis l'API source et le solde calculé par l'application.",
            },
            title: () => "Jetons ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille que vous avez utilisé pour trader via Arbitrum (en commençant par 0x)",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    "arbitrum-nova": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille que vous avez utilisé pour trader via Arbitrum (en commençant par 0x)",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    archway: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Archway dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    atani: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Atani est actuellement en version bêta et en cours de développement. Veuillez informer notre équipe d'assistance si vous rencontrez des problèmes d'intégration.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Atani",
              1: () => "Accédez à l'onglet Portfolio",
              2: () =>
                "Recherchez et cliquez sur Télécharger les rapports, puis choisissez Données historiques",
              3: () => "Choisissez votre plage de dates préférée",
              4: () => "Appuyez sur Télécharger pour obtenir votre rapport",
            },
          },
        },
      },
    },
    atomicwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "En raison des limitations du fichier CSV de l'historique des transactions d'Atomic Wallet, nous ne sommes pas en mesure d'importer automatiquement le côté achat d'une transaction dans l'application. Si vous avez effectué des transactions dans Atomic Wallet, veuillez générer le côté acquisition de la transaction via notre bouton « Ajouter une transaction manuelle » ou en important un fichier CSV personnalisé à l'aide des instructions « Importer des transactions personnalisées » ci-dessous.",
            },
            title: () => "Échanges dans l'application",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Dans l’onglet Portefeuille, sélectionnez la pièce pour laquelle vous avez besoin de votre historique.",
              1: () =>
                "Cliquez sur l’icône d’engrenage des paramètres dans le coin supérieur droit.",
              2: () => "Cliquez sur Exporter les transactions.",
              3: () =>
                "Un fichier CSV contenant l'historique de vos transactions pour cette devise doit avoir été téléchargé dans votre dossier Bureau.",
              4: () =>
                "Importez ce fichier dans Crypto Tax Calculator et spécifiez le symbole de la devise. Par exemple, pour un portefeuille Bitcoin, vous devrez coller « BTC »",
            },
          },
          1: {
            title: () =>
              "Remarque : n'utilisez pas la page Historique de la barre de menus pour générer un rapport CSV. Un rapport distinct doit être généré pour chaque devise avec laquelle vous avez interagi.",
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille Atomic.",
              1: () =>
                "Sélectionnez la cryptomonnaie que vous souhaitez importer. Par exemple, Bitcoin ou Ethereum.",
              2: () =>
                "Copiez l'adresse du portefeuille public correspondant à cet actif. Pour la trouver, vous devrez peut-être cliquer sur « Recevoir ».",
              3: () =>
                "Dans CTC, choisissez le réseau blockchain correspondant dans la liste déroulante.",
              4: () =>
                "Collez l'adresse de votre portefeuille public dans CTC et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    aurora: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Nous rencontrons actuellement des problèmes lors de la collecte des transactions de pontage et travaillons à une solution. Si vous rencontrez ce problème entre-temps, essayez plutôt notre processus de téléchargement manuel de fichiers CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Transactions de pont",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Aurora dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    avalanche: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Il peut y avoir des problèmes lors de l'importation de toutes les adresses étant donné l'adresse que vous fournissez, car Avalanche essaie de masquer les relations entre les adresses pour des raisons de sécurité.",
              1: () =>
                "Par conséquent, vous devrez peut-être importer des adresses supplémentaires comme indiqué dans ce guide : https://cryptotaxcalculator.io/integrations/avalanche-tax/#please-note.",
            },
          },
          1: {
            items: {
              0: () =>
                "En raison de problèmes avec notre fournisseur d'API, nous ne sommes pas en mesure de récupérer vos transactions sur la chaîne X et P. Nous recherchons actuellement d'autres fournisseurs pour remédier à ce problème.",
            },
            title: () => "Transactions de chaîne X et P manquantes",
          },
          2: {
            items: {
              0: () =>
                "Nous prenons désormais en charge les jetons ERC-1155. Cependant, nous ne pouvons pas importer vos soldes actuels pour ces jetons. Cela peut entraîner des écarts entre le solde importé depuis l'API source et le solde calculé par l'application.",
            },
            title: () => "Jetons ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Pour importer votre adresse C-Chain (commençant par 0x), saisissez-la simplement dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    bambooRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    base: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille de base dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    bch: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
              1: () =>
                "Nous prenons également en charge les adresses xpub, ypub et zpub, mais xpub est préféré.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Bitcoin Cash dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    berachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons désormais en charge les jetons ERC-1155. Cependant, nous ne pouvons pas importer vos soldes actuels pour ces jetons. Cela peut entraîner des écarts entre le solde importé depuis l'API source et le solde calculé par l'application.",
            },
            title: () => "Jetons ERC-1155",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Berachain dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    bigone: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte BigONE",
              1: () => "Accédez aux paramètres de votre compte",
              2: () =>
                "Sous « Paramètres Exchange », vous pouvez générer une clé API",
              3: () =>
                "Saisissez ici la « clé API » et le « secret API » dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
    },
    binance: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actuellement, nous prenons en charge la synchronisation API des enregistrements historiques pour les opérations suivantes : dépôts et retraits de crypto-monnaies, dépôts et retraits de monnaie fiduciaire, transactions au comptant, conversions et distributions de petits soldes de BNB « Dust »",
              1: () =>
                "L'API Binance présente certaines limitations : les transactions effectuées avec des cryptomonnaies supprimées ne peuvent pas être synchronisées. Si vous avez des transactions de ce type, nous vous recommandons de télécharger votre historique via CSV à la place",
              2: () =>
                "En raison des limitations de l'API Binance, seuls les 90 derniers jours de transactions OTC et de conversions crypto-crypto peuvent être récupérés.",
              3: () =>
                "Depuis septembre 2020, Binance ne fournit plus de point de terminaison API pour obtenir des données historiques pour certains achats de pièces à partir de transactions « Solde de trésorerie ». De plus, Binance ne fournit pas de point de terminaison pour le « Staking verrouillé » (https://dev.binance.vision/t/earn-locked-staking/1453)",
              4: () =>
                "Binance ne fournit pas de point de terminaison API pour récupérer les données historiques pour l'historique des achats Fiat, les jetons à effet de levier Binance (BLVT), le Binance Liquid Swap (Swaps), les intérêts d'épargne Binance, les revenus à terme USD-M, les revenus à terme COIN-M, les transactions sur marge croisée et les transactions sur marge isolée",
              5: () => "Pour ajouter des transactions non prises en charge :",
              6: () => "1. Téléchargez et ouvrez le modèle CSV",
              7: () => "2. Remplissez les détails de votre transaction",
              8: () =>
                "3. Assurez-vous de remplir tous les champs inclus pour éviter les erreurs lors du téléchargement",
              9: () =>
                "4. Exportez votre document au format CSV et téléchargez-le ici",
              10: () => "5. Ajoutez le CSV directement au portefeuille associé",
            },
            title: () => "Synchronisation API – Transactions manquantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Dans votre compte Binance, accédez à « Gestion des API » dans le menu déroulant « Profil ». (https://www.binance.com/en/my/settings/api-management)",
              1: () =>
                "Cliquez sur « Créer une API » et choisissez « Générée par le système » comme type de clé API, puis cliquez sur « Suivant ».",
              2: () =>
                "Définissez une étiquette de votre choix, cliquez sur « Suivant » et terminez l’authentification à deux facteurs.",
              3: () =>
                "Gardez l'accès IP défini sur « Illimité » et assurez-vous que « Activer la lecture » est coché.",
              4: () =>
                "Collez l'API et les clés secrètes dans Crypto Tax Calculator et cliquez sur « Ajouter l'API Binance ».",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/19oc30s0mVM",
            title: () => "Comment importer votre API Binance",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Binance a récemment limité les exportations CSV à une plage maximale de 3 mois d'historique à la fois, avec seulement 6 exportations autorisées par mois. Si vous avez effectué beaucoup de transactions, nous vous suggérons d'utiliser l'option d'importation API",
              1: () =>
                "Actuellement, notre option d'importation CSV prend en charge les dépôts, les retraits, les transactions Fiat, Spot, Coin-Futures, USDT-Futures, les transactions sur marge isolée et les transactions sur marge croisée.",
              2: () =>
                "Si vous avez reçu un dossier « .zip » ou « .tar.gz » de Binance, vous devrez d'abord extraire le contenu du dossier compressé. Vous pouvez utiliser un service tel que https://extract.me/ pour extraire le contenu. Vous pouvez ensuite télécharger le(s) fichier(s) extrait(s) sur Crypto Tax Calculator",
              3: () =>
                "Binance n'inclut pas les frais de trading pour les transactions P2P dans son fichier CSV. Nous vous recommandons d'effectuer l'importation via l'API si vous avez effectué des transactions P2P.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Binance et accédez à Portefeuille > Aperçu.",
              1: () => "Cliquez sur Commandes > Historique des transactions.",
              2: () =>
                "Cliquez sur « Exporter les enregistrements de transaction » en haut à droite.",
              3: () =>
                "Sélectionnez « Personnaliser » pour la plage de temps et définissez les dates de début/fin (jusqu'à 12 mois à la fois).",
              4: () =>
                "Assurez-vous que « Tous » est sélectionné pour le compte, la pièce et le sous-compte.",
              5: () => "Cliquez sur « Générer » et attendez le rapport.",
              6: () =>
                "Répétez l’opération pour l’ensemble de votre historique de trading.",
              7: () =>
                "Si le fichier est « .zip » ou « .tar.gz », extrayez-le avant de le télécharger.",
              8: () =>
                "Téléchargez les fichiers extraits sur Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/N7TNcCW2N7g",
            title: () => "Comment importer vos fichiers CSV Binance",
          },
        },
      },
    },
    "binance-smart-chain": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons désormais en charge les jetons ERC-1155. Cependant, nous ne pouvons pas importer vos soldes actuels pour ces jetons. Cela peut entraîner des écarts entre le solde importé depuis l'API source et le solde calculé par l'application.",
            },
            title: () => "Jetons ERC-1155",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Binance Smart Chain dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    binancechain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Binance a officiellement fermé la chaîne Binance (BNB Beacon Chain). Par conséquent, les données historiques de cette chaîne ne peuvent plus être obtenues via l'API. Les transactions précédemment importées resteront accessibles sur votre compte. Vous pouvez toujours consulter l'historique de vos transactions sur https://explorer.bnbchain.org/ jusqu'au 3 décembre 2024. Pour ajouter de nouvelles transactions, veuillez utiliser l'option d'importation CSV personnalisée. Pour plus de détails sur la fermeture, veuillez consulter https://www.bnbchain.org/en/bnb-chain-fusion.",
            },
            title: () => "Fermeture de la chaîne Binance (BNB Beacon Chain)",
          },
        },
        step: {
          0: {
            title: () =>
              "Binance a officiellement fermé la chaîne Binance (BNB Beacon Chain). Par conséquent, les données historiques de cette chaîne ne peuvent plus être obtenues via l'API.",
          },
        },
      },
    },
    binancedex: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Cela synchronisera les transactions effectuées sur Binance DEX ainsi que toutes les transactions Binance Chain associées à ce portefeuille",
              1: () =>
                "Dans certains cas, il n'y a pas de données disponibles pour la réception de jetons BNB et BEP2 via un pont Cross Chain dans votre portefeuille Binance Chain. Dans ce cas, vous devrez peut-être saisir manuellement les transactions de « réception » respectives dans le calculateur de taxes sur les crypto-monnaies",
              2: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille Binance Chain (BEP2) que vous avez utilisé pour trader via Binance DEX",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    binanceus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actuellement, nous prenons en charge la synchronisation API des enregistrements historiques pour les opérations suivantes : dépôts et retraits de crypto-monnaies, dépôts et retraits de monnaie fiduciaire, transactions au comptant, conversions et distributions de petits soldes de BNB « Dust »",
              1: () =>
                "L'API Binance présente certaines limitations : les transactions effectuées avec des cryptomonnaies supprimées ne peuvent pas être synchronisées. Si vous avez des transactions de ce type, nous vous recommandons de télécharger votre historique via CSV à la place",
              2: () =>
                "En raison des limitations de l'API Binance, seuls les 90 derniers jours de transactions OTC et de conversions crypto-crypto peuvent être récupérés.",
              3: () =>
                "Depuis septembre 2020, Binance ne fournit plus de point de terminaison API pour obtenir des données historiques pour certains achats de pièces à partir de transactions « Solde de trésorerie ». De plus, Binance ne fournit pas de point de terminaison pour le « Staking verrouillé » (https://dev.binance.vision/t/earn-locked-staking/1453)",
              4: () =>
                "Binance ne fournit pas de point de terminaison API pour récupérer les données historiques pour l'historique des achats Fiat, les jetons à effet de levier Binance (BLVT), le Binance Liquid Swap (Swaps), les intérêts d'épargne Binance, les revenus à terme USD-M, les revenus à terme COIN-M, les transactions sur marge croisée et les transactions sur marge isolée",
              5: () => "Pour ajouter des transactions non prises en charge :",
              6: () => "1. Téléchargez et ouvrez le modèle CSV",
              7: () => "2. Remplissez les détails de votre transaction",
              8: () =>
                "3. Assurez-vous de remplir tous les champs inclus pour éviter les erreurs lors du téléchargement",
              9: () =>
                "4. Exportez votre document au format CSV et téléchargez-le ici",
              10: () => "5. Ajoutez le CSV directement au portefeuille associé",
            },
            title: () => "Synchronisation API – Transactions manquantes",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte BinanceUS",
              1: () =>
                "En haut à droite de la barre de navigation, passez la souris sur l'icône « Profil » et cliquez sur « Gestion des API » dans le menu déroulant (https://www.binance.com/en/my/settings/api-management)",
              2: () =>
                "Saisissez le libellé d'une nouvelle API comme « Crypto Tax Calculator » et cliquez sur « Créer une API » (bouton jaune)",
              3: () =>
                "Vous devrez peut-être saisir certaines informations de vérification",
              4: () =>
                "Une fois créée, copiez la « clé API » et la « clé secrète »",
              5: () =>
                "Cliquez sur Modifier les restrictions et décochez la case « Activer le trading au comptant ». Assurez-vous également que la case « Activer les retraits » n'est pas cochée.",
              6: () =>
                "Vérifiez que « Peut lire » est la seule option cochée !",
              7: () =>
                "Laissez la sélection de restriction d'accès IP sur « Illimité »",
              8: () =>
                "Collez la « clé API » et la « clé secrète » copiées ici dans Crypto Tax Calculator et cliquez sur « Ajouter une API »",
              9: () =>
                "La synchronisation de cette API peut prendre environ 20 minutes en raison des restrictions du côté de BinanceUS",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actuellement, notre option d'importation CSV ne prend en charge que les dépôts, les retraits et les transactions de portefeuilles Fiat/Spot. Si vous avez d'autres types d'enregistrements historiques, veuillez les importer via notre option « Configurer la synchronisation automatique ».",
              1: () =>
                "Si vous avez reçu un dossier « .zip » ou « .tar.gz » de BinanceUS, vous devrez d'abord extraire le contenu du dossier compressé. Vous pouvez utiliser un service tel que https://extract.me/ pour extraire le contenu. Vous pouvez ensuite télécharger le(s) fichier(s) extrait(s) sur Crypto Tax Calculator.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte BinanceUS et cliquez sur « Portefeuille » dans la barre de navigation.",
              1: () =>
                "Sélectionnez l'option « Exporter les rapports » en écriture jaune sur le côté droit.",
              2: () =>
                "Sous Rapports personnalisés, sélectionnez Tous pour le type d'actif et de transactions ; pour la plage, sélectionnez la période souhaitée (jusqu'à un an maximum à la fois, voir l'étape 5) et sélectionnez CSV pour le format de fichier.",
              3: () =>
                "Cliquez sur « Générer » et attendez que le rapport soit généré – cela peut prendre un certain temps.",
              4: () =>
                "Répétez l'exportation pour chaque année pendant laquelle vous avez négocié sur BinanceUS.",
              5: () =>
                "Si le fichier est au format « .zip » ou « .tar.gz », vous devrez d'abord extraire le contenu du dossier compressé avant de le télécharger ; veuillez vous référer aux notes.",
              6: () => "Téléchargez les fichiers sur Crypto Tax Calculator.",
            },
            title: () =>
              "Pour les dépôts et les retraits et les transactions sur compte au comptant :",
          },
        },
      },
    },
    bingx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte BingX et accédez à Actifs > Enregistrements d'actifs",
              1: () =>
                "Cliquez sur « Exporter » sur la page de l'historique des transactions ou utilisez ce lien : https://bingx.com/en/transaction-history/?timeKey=ninetyDaysFlag",
              2: () =>
                "Sélectionnez la plage horaire et choisissez tous les comptes : Fonds, Futures perpétuels USD-M, Futures perpétuels Coin-M et Futures standard.",
              3: () =>
                "Définissez le type de fichier sur Excel et le format de données sur Standard, puis cliquez sur « Générer ».",
              4: () =>
                "Attendez que le fichier Excel soit généré et téléchargez-le une fois qu'il est prêt.",
              5: () =>
                "Ouvrez le fichier Excel, qui contiendra quatre feuilles distinctes. Enregistrez chaque feuille en tant que fichier .csv individuel et importez-les une par une dans CTC.",
            },
          },
        },
      },
    },
    bitaroo: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Bitaroo",
              1: () =>
                "Téléchargez vos fichiers CSV de trading, de dépôt et de retrait depuis Bitaroo",
              2: () =>
                "Téléchargez ces fichiers ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitbuy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Le rapport détaillé de Bitbuy sur l'activité de trading n'inclut parfois pas les dépôts ou les retraits vers et depuis Bitbuy. Si vous avez des dépôts et des retraits, veuillez contacter notre support pour obtenir de l'aide sur l'importation de ces transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Bitbuy ici (https://app.bitbuy.ca/sign-in).",
              1: () =>
                "Cliquez sur le menu déroulant à côté de votre nom (en haut à droite) et sélectionnez « Documents ».",
              2: () =>
                "Pour obtenir l'historique de vos transactions avant le 25 mai 2024, accédez à l'onglet « Documents hérités » et téléchargez vos fichiers.",
              3: () =>
                "Pour toute activité après le 25 mai 2024, rendez-vous dans l'onglet « Relevés » et téléchargez les fichiers CSV.",
              4: () =>
                "Téléchargez tous vos fichiers CSV téléchargés ici dans Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    bitcoindotcomau: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Veuillez contacter l'équipe d'assistance de bitcoin.com.au pour générer ou modifier votre clé API",
              1: () => "Copiez et collez les clés dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitfinex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actuellement, nous ne prenons pas en charge les données de trading de produits dérivés Bitfinex. Si vous avez effectué des transactions de produits dérivés via Bitfinex, veuillez contacter le support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Bitfinex.",
              1: () =>
                "Dans le coin supérieur droit, passez la souris sur l'icône représentant une personne et sélectionnez « Clés API » dans la liste déroulante. Cela devrait vous amener à « https://www.bitfinex.com/api ».",
              2: () => "Cliquez sur l'onglet « Créer une nouvelle clé ».",
              3: () =>
                "Assurez-vous que les autorisations par défaut sont inchangées et que toutes les autorisations « Lecture » ont été activées.",
              4: () =>
                "Au bas de la page, saisissez un nom pour la clé API (par exemple, Crypto Tax Calculator) dans le champ « Étiqueter votre clé API », puis cliquez sur le bouton « Générer la clé API ».",
              5: () =>
                "Vous devrez peut-être saisir votre jeton 2FA si vous avez activé la 2FA.",
              6: () =>
                "Une fois que vous avez reçu un e-mail de vérification de Bitfinex, confirmez la nouvelle clé API.",
              7: () =>
                "Copiez la « clé API » et la « clé secrète API » et saisissez-les toutes les deux dans Crypto Tax Calculator.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actuellement, nous ne prenons pas en charge les données de trading de produits dérivés Bitfinex. Si vous avez effectué des transactions de produits dérivés via Bitfinex, veuillez contacter le support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Bitfinex.",
              1: () =>
                "Dans le coin supérieur droit, passez la souris sur « Portefeuille » et sélectionnez « Rapports » dans le menu déroulant. Cela devrait vous amener à « https://report.bitfinex.com ».",
              2: () => "Cliquez sur l'icône du calendrier en haut de la page.",
              3: () =>
                "Sélectionnez une plage de dates personnalisée qui inclut l'intégralité de votre historique de trading.",
              4: () =>
                "Cliquez sur le bouton « Exporter », une fenêtre contextuelle devrait apparaître.",
              5: () =>
                "Dans le champ « Données à exporter », sélectionnez « Grands livres » et « Mouvements » uniquement. Sélectionnez le « Format de date » sur « JJ-MM-AA ». Cochez la case « Afficher les millisecondes »",
              6: () =>
                "Cliquez sur le bouton « Exporter » en bas de la fenêtre contextuelle. Cela générera des fichiers CSV qui seront envoyés à votre adresse e-mail.",
              7: () =>
                "Dans votre client de messagerie, ouvrez l'e-mail et cliquez sur « Télécharger le fichier CSV ». Cela téléchargera un fichier .zip contenant deux fichiers CSV (un pour les registres et un pour les mouvements).",
              8: () =>
                "Extrayez et téléchargez les DEUX CSV vers Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    bitflyer: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Bitflyer",
              1: () => "Accéder à l'API",
              2: () => "Cliquez sur le bouton « Générer une nouvelle clé »",
              3: () =>
                "Copiez la « clé API » et le « secret API » et saisissez-les tous les deux ici dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte BitFlyer",
              1: () => "Téléchargez votre historique de transactions",
              2: () => "Téléchargez le fichier CSV dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitforex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Si les fichiers CSV ne parviennent pas à être téléchargés, essayez de changer l'extension de « .csv » à « .xlsx » et réessayez.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte BitForex",
              1: () =>
                "Contactez le support et demandez-leur vos fichiers CSV de trading",
              2: () =>
                "Téléchargez ces fichiers ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitget: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "Pour récupérer des données plus anciennes, veuillez importer vos transactions via CSV.",
            },
            title: () =>
              "L'API de Bitget fournit uniquement les 90 derniers jours de l'historique des transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Bitget",
              1: () =>
                "Accédez à la gestion des API à partir de la barre de navigation en haut",
              2: () => "Dans le menu déroulant, choisissez Gestion des API",
              3: () => "Cliquez sur Créer une nouvelle API",
              4: () => "Cliquez sur la clé API générée par le système",
              5: () =>
                "Dans la fenêtre contextuelle, créez une phrase secrète pour votre API",
              6: () =>
                "Sous Autorisations, sélectionnez Lecture seule pour toutes les cases à cocher",
              7: () =>
                "Terminez la vérification à deux facteurs et cliquez sur « Suivant ».",
              8: () => "Copiez votre clé API et votre secret",
            },
          },
        },
      },
    },
    bitgo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Assurez-vous que votre rapport couvre toutes les années pendant lesquelles vous avez négocié des crypto-monnaies",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte BitGo sur un navigateur Web",
              1: () =>
                "Accédez à la section « Rapports » à partir de la barre de navigation en haut",
              2: () =>
                "Sélectionnez « Toutes les pièces/jetons » dans le menu déroulant",
              3: () => "Téléchargez votre rapport sous forme de fichier CSV",
              4: () =>
                "Téléchargez le fichier que vous venez de télécharger ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitkeep: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Seules les adresses de portefeuille Solana stockées dans votre portefeuille BitKeep sont actuellement prises en charge",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trouvez l'adresse du portefeuille blockchain Solana que vous avez utilisé pour trader via BitKeep",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    bitmart: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Bitmart.",
              1: () =>
                "Passez la souris sur le logo du compte dans le coin supérieur droit.",
              2: () => "Cliquez sur Compte.",
              3: () => "Cliquez sur Gestion des API.",
              4: () => "Saisissez votre mémo préféré.",
              5: () => "Cliquez sur soumettre.",
              6: () => "Copiez la clé API, le secret et le mémo.",
              7: () => "Collez dans les champs appropriés sur CTC",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "BitMart ne dispose pas d'une API avec l'historique complet des transactions, ni d'un fichier CSV disponible dans son interface utilisateur. Vous devrez leur envoyer un e-mail pour demander l'exportation CSV de votre historique de transactions. Cela peut prendre 5 jours.",
              1: () =>
                "Une fois que vous avez reçu l'exportation de l'historique des transactions, vous pouvez l'importer dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitmax: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "L'API AscendEX ne fournit actuellement que les 30 derniers jours de l'historique des transactions",
              1: () => "Vous devrez créer de nouvelles clés API après 90 jours",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte AscendEX",
              1: () =>
                "Cliquez sur l'icône avatar dans le coin supérieur droit",
              2: () =>
                "Cliquez sur « Paramètres API » et cliquez sur « Nouvelle clé API »",
              3: () =>
                "Vérifiez l'autorisation « Afficher » pour la clé et saisissez votre code de vérification par e-mail et votre code 2FA",
              4: () => "Cliquez sur « Générer la clé API »",
              5: () => "Copiez votre clé API et votre secret",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "AscendEX ne dispose actuellement pas d'un export CSV pour vos dépôts/retraits. Vous devrez l'ajouter manuellement.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte AscendEX",
              1: () =>
                "Sélectionnez « Ma commande » (https://bitmax.io/en/order/cash-order)",
              2: () => "Cliquez sur « Demander l'exportation »",
              3: () =>
                "Vous pouvez ensuite cliquer sur le bouton à droite « Mes fichiers exportés » et télécharger le fichier une fois terminé",
              4: () =>
                "Téléchargez le fichier CSV téléchargé directement ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitmex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Assurez-vous de n’avoir activé aucune autorisation de commande lors de la création de la clé API !",
              1: () =>
                "Si l'API n'importe pas vos données, cela peut être dû au fait que vous n'avez pas respecté la nouvelle politique de vérification KYC de BitMEX. Si cela vous arrive, veuillez terminer leur vérification KYC avant de réessayer.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte BitMEX",
              1: () =>
                "Cliquez sur votre nom d'utilisateur dans le coin supérieur droit",
              2: () =>
                "Accédez à la page du compte en cliquant sur « Compte et sécurité »",
              3: () =>
                "Sur le côté gauche de la page, sélectionnez « Clés API » (https://www.bitmex.com/app/apiKeys)",
              4: () =>
                "Lors de la création de la clé API, assurez-vous que les autorisations de clé sont vides, comme indiqué par « - »",
              5: () => "Vérifiez que vous n’avez activé aucune autorisation",
              6: () =>
                "Saisissez votre 2FA si nécessaire et cliquez sur « Créer une clé API »",
              7: () =>
                "Copiez l'ID et le secret, puis saisissez-les comme « clé API » et « secret API » ici dans Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez enregistrer le fichier CSV pour chaque page de données",
              1: () =>
                "Si le fichier CSV ne s'importe pas, cela peut être dû au fait que vos données ont un format d'horodatage différent de celui attendu. Veuillez contacter notre support si cela se produit.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte BitMEX",
              1: () =>
                "Cliquez sur votre nom d'utilisateur dans le coin supérieur droit",
              2: () => "Accéder au compte et à la sécurité",
              3: () =>
                "Sur le côté gauche de la page, sélectionnez « Portefeuille - > Soldes » (https://www.bitmex.com/app/wallet)",
              4: () =>
                "Dans le coin supérieur droit, cliquez sur « Enregistrer au format CSV »",
              5: () =>
                "Veuillez vous assurer que le nom du fichier CSV que vous venez de télécharger commence par « Historique du portefeuille ». Il existe d'autres types de fichiers CSV téléchargeables que nous ne prenons pas en charge",
              6: () =>
                "Téléchargez le fichier CSV ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitpanda: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Bitpanda et sélectionnez l'icône panda dans le coin supérieur droit.",
              1: () =>
                "Sélectionnez la clé API et entrez un nom pour votre clé API.",
              2: () =>
                "Dans le cadre de la portée, vérifiez la transaction, le solde et les échanges.",
              3: () => "Sélectionnez générer une nouvelle clé API.",
              4: () =>
                "Cliquez sur Confirmer, ce qui générera un e-mail de confirmation.",
              5: () =>
                "Copiez la clé API et saisissez-la comme « clé API » et « secret API » ici dans Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Bitpanda et sélectionnez l'icône panda dans le coin supérieur droit.",
              1: () =>
                "Sélectionnez Historique puis sélectionnez Historique des transactions en haut à droite.",
              2: () =>
                "Exportez le fichier CSV et importez-le dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitrue: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actuellement, notre option d'importation CSV ne prend en charge que les dépôts, les retraits et l'historique des transactions.",
              1: () =>
                "Si vous avez des enregistrements que nous ne prenons pas en charge et que vous souhaitez demander leur ajout, veuillez nous contacter via notre support de chat",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Bitrue",
              1: () =>
                "Passez la souris sur Trade dans le coin supérieur gauche et sélectionnez Spot. Cliquez ensuite sur Historique des commandes. Vous pouvez également essayer ce lien direct (https://www.bitrue.com/order/spot/history-orders)",
              2: () => "Cliquez sur Exporter en haut à droite",
              3: () =>
                "Dans la fenêtre contextuelle modale, sélectionnez « Historique des transactions » comme option de données",
              4: () =>
                "Sélectionnez les dates auxquelles vous avez effectué des transactions et cliquez sur le bouton « OK »",
              5: () =>
                "Bitrue n'autorise que 3 mois à la fois, vous devrez donc peut-être télécharger plusieurs fois pour couvrir l'intégralité de votre historique de trading",
            },
          },
        },
      },
    },
    bitso: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Dans la barre de navigation en haut à droite, cliquez sur l'option déroulante du profil utilisateur et cliquez sur Historique (https://bitso.com/r/history/alltransactions)",
              1: () =>
                "Au bas du tableau des transactions, vous devriez voir « Télécharger vos transactions dans un fichier CSV » suivi de quatre options : dépôts, retraits, conversions et transactions",
              2: () =>
                "Cliquez sur chaque option, puis prenez les quatre fichiers CSV que vous venez de télécharger et importez-les dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitsong: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille BitSong dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    bitstamp: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Répétez les étapes ci-dessus pour ajouter des clés API pour tous les comptes associés.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Mon profil > Paramètres > Paramètres du compte > Accès API",
              1: () => "Cliquez sur « Créer une nouvelle clé API »",
              2: () => "Cliquez sur le bouton « Paramètres avancés »",
              3: () =>
                "Sélectionnez un compte approprié sous « Compte associé »",
              4: () =>
                "Assurez-vous que seules « Autorisations générales > Afficher le solde du compte », « Autorisations générales > Afficher vos transactions » et « Gagner > Afficher et gérer la fonctionnalité Gagner » sont activées et cliquez sur « Créer une nouvelle clé API »",
              5: () =>
                "Saisissez votre code Bitstamp 2FA et cliquez sur « Confirmer »",
              6: () =>
                "Copiez la clé API et le secret dans Crypto Tax Calculator. NE cliquez PAS encore sur « Ajouter l'API Bitstamp ». Notez que le secret n'est disponible que pendant quelques minutes et ne s'affichera plus. Si vous perdez votre secret, vous devrez créer une nouvelle clé API.",
              7: () => "Dans Bitstamp, cliquez sur « Activer la clé API »",
              8: () =>
                "Bitstamp vous enverra un e-mail de confirmation. Cliquez sur le bouton « Activer la clé API » dans cet e-mail.",
              9: () => "Cliquez sur « Ajouter l'API Bitstamp »",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Les transactions des sous-comptes sont déjà incluses dans le fichier CSV téléchargé.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Bitstamp",
              1: () =>
                "Accédez à la page Transactions en cliquant sur l'icône utilisateur dans le coin supérieur droit, puis sur « Activité », puis sur « Historique des transactions » dans la barre latérale gauche (https://www.bitstamp.net/account/transactions)",
              2: () =>
                "Cliquez sur le bouton « Ouvrir les options d’exportation »",
              3: () =>
                "Sélectionnez la « Plage de dates » qui couvre tout votre historique de transactions",
              4: () => "Choisissez « Tout » pour les devises",
              5: () => "Choisissez « Tous » pour les types de transaction",
              6: () => "Choisissez « RFC 4180 (nouveau) » pour le format CSV",
              7: () =>
                "Cliquez sur le bouton « Exporter la sélection » pour télécharger le fichier CSV",
              8: () =>
                "Téléchargez ce CSV ici dans le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                "Cliquez sur « Se connecter à Bitstamp » pour accéder à Bitstamp Exchange",
              1: () => "Connectez-vous à votre compte Bitstamp.",
              2: () =>
                "Si la 2FA est activée sur votre compte, saisissez le code requis. Sinon, ignorez cette étape.",
              3: () => "Sélectionnez le sous-compte approprié",
              4: () => "Cliquez sur le bouton « Accepter »",
            },
          },
        },
      },
    },
    bittorrentchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Nous rencontrons actuellement des problèmes de collecte de jetons ERC-1155 et de pontage des transactions, et nous travaillons sur une solution. Si entre-temps vous rencontrez ce problème, essayez plutôt notre processus de téléchargement manuel de fichiers CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Jetons ERC-1155 et transactions de pont",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille BitTorrent Chain dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    bittrex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Bittrex",
              1: () => "Accéder aux clés API",
              2: () => "Cliquez sur le bouton « Ajouter une nouvelle clé »",
              3: () =>
                "Assurez-vous que l'autorisation « LIRE LES INFORMATIONS » pour la nouvelle clé est activée",
              4: () =>
                "Une fois terminé, cliquez sur le bouton « Enregistrer »",
              5: () => "Copiez la clé API et le secret API",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Pour télécharger votre historique de dépôt/retrait au format CSV, vous devez déposer un ticket d'assistance auprès de Bittrex. Une fois que vous avez reçu ces fichiers CSV, vous pouvez les télécharger sur Crypto Tax Calculator. Pour plus de détails, cliquez ici : https://bittrex.zendesk.com/hc/en-us/articles/360001359006-Where-s-my-Order-History-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Bittrex, accédez à « Commandes » dans le coin supérieur et faites défiler jusqu'à l'historique des commandes.",
              1: () =>
                "Cliquez sur le bouton « Télécharger l'historique » et sélectionnez l'année où vous avez effectué votre trading, puis cliquez sur « Télécharger ».",
              2: () =>
                "Vous ne pouvez télécharger qu'une année à la fois, mais vous devez ajouter chaque année pendant laquelle vous avez effectué des transactions au calculateur d'impôts sur les crypto-monnaies.",
              3: () =>
                "Assurez-vous de ne pas ouvrir ou réenregistrer le fichier avant de l'importer car vous risquez d'affecter le format du CSV.",
            },
            title: () => "Pour l'historique des commandes :",
          },
        },
      },
    },
    bitvavo: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous importons actuellement les dépôts, les retraits et les transactions",
              1: () =>
                "Nous ne pouvons pas importer les revenus de jalonnement car l'API Bitvavo ne le prend actuellement pas en charge",
              2: () =>
                "Si vous utilisez le Staking, il est possible que les soldes des devises mises en jeu que nous avons signalés ne correspondent pas à ceux signalés par Bitvavo, sauf si vous saisissez manuellement les transactions de revenus du Staking.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à l'échange Bitvavo",
              1: () =>
                "Accédez à la page des paramètres de l'API (https://account.bitvavo.com/user/api)",
              2: () =>
                "Cliquez sur le bouton « Demander une nouvelle clé API »",
              3: () =>
                "Générer des clés API en lecture seule qui ne permettent pas les échanges ou les retraits",
              4: () =>
                "Insérez la clé API et le secret ici dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre bourse Bitvavo",
              1: () =>
                "Dans le coin supérieur droit, cliquez sur votre nom de profil",
              2: () => "Cliquez sur « Historique des transactions »",
              3: () =>
                "Cliquez sur le bouton de téléchargement dans le coin supérieur droit",
              4: () =>
                "Téléchargez le fichier CSV ici dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
    },
    bityard: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Bityard",
              1: () => "Pour l'historique du commerce :",
              2: () =>
                "Accédez à la section « Commandes » et sélectionnez « Spot » - « Historique des transactions » dans le menu (https://www.bityard.com/en-US/account/fm/spotHistory/2)",
              3: () =>
                "Cliquez sur le bouton « Exporter » et sélectionnez la période souhaitée pour les données exportées",
              4: () => "Pour l'historique des dépôts et des retraits :",
              5: () =>
                "Accédez à la section « Actifs » et sélectionnez « Mes actifs » - « Historique des fonds » - « Historique des dépôts » ou « Historique des retraits » dans le menu (https://www.bityard.com/en-US/account/fm/fundHistory/1)",
              6: () =>
                "Cliquez sur le bouton « Exporter » et sélectionnez la période souhaitée pour les données exportées",
              7: () =>
                "Téléchargez les deux fichiers CSV ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    blast: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Blast (commençant par 0x) dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    blockchaindotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous ne pouvez importer votre historique de dépôts et de retraits que via l'importation CSV. De plus, le CSV ne fournit pas d'informations sur les frais payés pour ces dépôts/retraits",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre portefeuille (https://login.blockchain.com/#/login) et sélectionnez une devise sur la gauche (par exemple Bitcoin)",
              1: () =>
                "Si vous disposez d'un solde pour la crypto-monnaie sélectionnée, vous verrez un bouton « Télécharger » - cliquez sur ce bouton",
              2: () =>
                "Il vous sera demandé de sélectionner une plage de dates pour lesquelles vous souhaitez télécharger vos transactions. Assurez-vous de sélectionner la période qui couvre l'intégralité de votre historique de transactions pour la cryptomonnaie sélectionnée",
              3: () =>
                "Cliquez sur « Générer le rapport ». Ce bouton se transforme en « Télécharger le rapport » une fois que le rapport est prêt à être téléchargé. Cliquez sur ce bouton pour télécharger votre rapport",
              4: () =>
                "Téléchargez le fichier que vous venez de télécharger ici dans Crypto Tax Calculator",
              5: () =>
                "Assurez-vous de répéter ce processus pour toutes les crypto-monnaies que vous stockez sur notre portefeuille",
            },
          },
        },
      },
    },
    blockearner: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Block Earner et accédez à la page Transactions (https://app.blockearner.com.au/transactions)",
              1: () =>
                "Téléchargez les fichiers nécessaires en fonction de votre historique de trading.",
              2: () =>
                "Le fichier de transaction se trouve en haut à droite de l'écran dans chaque compte respectif : Prêts, DeFi, Achat/Vente de Crypto et Espèces.",
              3: () =>
                "Téléchargez tous les fichiers téléchargés ici dans Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    blockfi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous ignorons le type de transaction « Trade » dans le fichier CSV « Historique des transactions ». À la place, nous utilisons les informations plus précises du fichier CSV « Trading » pour obtenir vos données de trading.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte BlockFi et cliquez sur l'icône de votre profil en haut à droite",
              1: () => "Sélectionnez « Rapports » dans le menu déroulant",
              2: () =>
                "Au bas de la page, vous verrez « Trading » et « Historique des transactions »",
              3: () =>
                "Pour « Trading », sélectionnez la plage de dates que vous souhaitez exporter (ou télécharger toutes les données) et cliquez sur le bouton « Télécharger .csv » - cela téléchargera toutes vos données de trading détaillées",
              4: () =>
                "Pour « Historique des transactions », sélectionnez la plage de dates que vous souhaitez exporter (ou télécharger tout) et cliquez sur le bouton « Télécharger .csv » - cela téléchargera toutes vos données de transaction détaillées telles que les dépôts, les retraits, les paiements d'intérêts, etc.",
              5: () =>
                "Téléchargez les deux fichiers CSV ici sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    boba: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Nous rencontrons actuellement des problèmes lors de la collecte de jetons ERC-1155 et travaillons à une solution. Si vous rencontrez ce problème entre-temps, essayez plutôt notre processus de téléchargement manuel au format CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Jetons ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Boba dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    brave_software: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez Brave Wallet dans le navigateur",
              1: () =>
                "Depuis l'onglet Comptes, cliquez pour copier l'adresse de votre portefeuille public commençant par « 0x... »",
              2: () =>
                "Collez l'adresse de votre portefeuille public et cliquez sur « Importer un portefeuille »",
            },
          },
        },
      },
    },
    bsv: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
              1: () =>
                "Nous prenons également en charge les adresses xpub, ypub et zpub, mais xpub est préféré.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Bitcoin SV dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    btc: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons également en charge les adresses xpub, ypub et zpub. Nous vous recommandons d'utiliser xPub. Lisez ce guide (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator) pour plus de détails.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () =>
                "Copiez l'adresse de votre portefeuille public commençant par « xpub... »",
              2: () =>
                "Collez l'adresse de votre portefeuille public dans CTC et cliquez sur « Importer le portefeuille »",
            },
          },
        },
      },
    },
    btcAltNetwork: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Saisissez l'adresse de votre portefeuille " +
                d.exchangeDisplayName +
                " dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
            },
          },
        },
      },
    },
    btcalpha: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Dans la section Comptes de votre compte, accédez à la section API",
              1: () => "Créer une nouvelle clé API",
              2: () => "Copiez et collez les clés dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    btccomau: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à BTC.com.au",
              1: () =>
                "Accédez à Paramètres > Rapports commerciaux via le menu sur la gauche de votre écran",
              2: () =>
                "Cliquez sur le bouton « Rapport unique » à droite de la ligne juste en dessous du titre « Rapport d'activité »",
              3: () =>
                "Choisissez « Transaction » dans la liste déroulante « Type de rapport »",
              4: () => "Remplissez les dates de début et de fin",
              5: () => "Cliquez sur le bouton « Créer »",
              6: () =>
                "Cliquez sur le bouton « Créer un rapport » dans la fenêtre contextuelle de confirmation",
              7: () =>
                "Après l'invite indiquant que la création du rapport a réussi, vous devrez peut-être actualiser la page pour voir le nouveau rapport.",
              8: () =>
                "Cliquez sur « Télécharger le rapport » dans la dernière ligne sous Rapports disponibles au téléchargement",
              9: () =>
                "Téléchargez le fichier CSV téléchargé ici sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    btcmarkets: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Pour plus d'informations, consultez nos étapes détaillées : https://cryptotaxcalculator.io/integrations/btcmarkets-tax/",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à BTC Markets et accédez à la page « Clé API » (https://app.btcmarkets.net/account/apikey)",
              1: () =>
                "Cliquez sur « Ajouter une nouvelle clé API », assurez-vous que les autorisations sont définies sur « Lecture seule », puis cliquez sur « Soumettre »",
              2: () =>
                "Copiez le secret et collez-le dans « Clé secrète » dans le calculateur de taxes cryptographiques",
              3: () =>
                "Cliquez sur « Soumettre », puis cliquez sur le bouton Copier de la clé API et collez-la dans Crypto Tax Calculator.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous aux marchés BTC et accédez à Achat/Vente > Historique des transactions",
              1: () =>
                "Cliquez sur « Exporter au format CSV » (le fichier devrait être téléchargé sous le nom « AccountTradingReport_XXX.csv »)",
              2: () =>
                "Téléchargez le fichier CSV ici Calculateur de taxes cryptographiques",
            },
            title: () => "Pour les données commerciales :",
          },
          1: {
            items: {
              0: () =>
                "Connectez-vous à BTC Markets et accédez à Compte > Historique des transactions",
              1: () =>
                "Assurez-vous que la devise est « TOUTES » et cliquez sur « Exporter vers CSV » (cela devrait être téléchargé sous le nom « TransactionReport_XXX.csv »).",
              2: () =>
                "Téléchargez le fichier CSV ici sur Crypto Tax Calculator",
            },
            title: () => "Pour les dépôts et les retraits :",
          },
        },
      },
    },
    bybit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Il peut y avoir de légères différences de solde entre vos soldes sur Bybit et les soldes indiqués dans Crypto Tax Calculator. Il s'agit d'un problème du côté de Bybit et il est dû à de petites différences d'arrondi entre les enregistrements PNL de Bybit et leurs enregistrements Wallet. S'il existe des écarts importants, veuillez contacter notre support via notre chat Web.",
              1: () =>
                "L'API Bybit importe uniquement les transactions de conversion des 6 derniers mois. Si vous avez des transactions plus anciennes, veuillez les importer à l'aide d'un fichier CSV",
              2: () =>
                "En raison des limitations imposées par Bybit, la clé API expirera après trois mois à compter de la date de création",
              3: () =>
                "L'API de votre compte principal inclura les données du sous-compte, il n'est donc pas nécessaire d'en créer un deuxième car cela pourrait entraîner des doublons dans votre compte.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Bybit, passez la souris sur votre nom dans le coin supérieur droit et sélectionnez « API » (https://www.bybit.com/app/user/api-management)",
              1: () =>
                "Cliquez sur le bouton « Créer une nouvelle clé ». Si vous n'avez pas encore configuré la 2FA, vous serez invité à le faire.",
              2: () =>
                "Sélectionnez « Clés API générées par le système » comme type de clé API",
              3: () =>
                "Sélectionnez « Transaction API » comme utilisation de la clé API et saisissez le nom de votre choix.",
              4: () =>
                "Modifiez le bouton bascule supérieur sur « Lecture seule » et laissez le champ d'adresse IP vide.",
              5: () => "Cochez les cases « Trading unifié » et « Actifs »",
              6: () =>
                "Cliquez sur « Soumettre », puis copiez et collez la clé API et le secret ici dans CTC",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous vous recommandons FORTEMENT d'importer via l'option « Synchroniser via API » et de vous connecter directement à l'API Bybit, car cela fournit des données plus précises.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Nous vous demandons de télécharger vos fichiers CSV « Historique des P&L fermés des produits dérivés » et « Historique des actifs ».",
              1: () =>
                "Vous pouvez générer le fichier « Historique des P&L fermés des produits dérivés » via ce lien (https://www.bybit.com/user/assets/order/closed-pnl/inverse).",
              2: () =>
                "Accédez à Ordres > Ordre de produits dérivés > Fermer le P&L > Cliquez sur le bouton « Exporter » dans le coin supérieur droit.",
              3: () =>
                "REMARQUE : les traders peuvent exporter jusqu'à deux ans de données tandis que la plage de données maximale exportable en un seul lot est de trois mois.",
              4: () =>
                "Vous pouvez générer votre fichier « Historique des actifs » via ce lien (https://www.bybit.com/user/assets/exportStatment).",
              5: () =>
                "REMARQUE : lorsque vous exportez votre « Relevé de compte » dans votre historique d'actifs, assurez-vous que tous les types de compte sont sélectionnés (Spot, Financement, Trading unifié, Dérivés USDC).",
              6: () =>
                "Il est important de noter que nous n'avons pas besoin de l'historique des transactions au format CSV.",
              7: () =>
                "Le rapport P&L clôturé montre les gains/pertes réalisés grâce au trading de contrats à terme, etc.",
              8: () =>
                "Le rapport sur l'historique des actifs vous permettra d'importer les dépôts/retraits",
            },
          },
        },
      },
    },
    calebandbrown: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous au portail sur app.calebandbrown.com",
              1: () => "Accédez à la page Transactions (menu de gauche)",
              2: () =>
                "En haut à droite du tableau des transactions, se trouve le bouton Exporter le résumé",
              3: () =>
                "Choisissez Résumé de transaction, sélectionnez « Tout le temps » comme période et cliquez sur Exporter",
            },
          },
        },
      },
    },
    canto: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Nous rencontrons actuellement des problèmes de collecte de jetons ERC-1155 et de pontage des transactions, et nous travaillons sur une solution. Si entre-temps vous rencontrez ce problème, essayez plutôt notre processus de téléchargement manuel de fichiers CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Jetons ERC-1155 et transactions de pont",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Canto dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    cardano: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Saisissez uniquement UNE adresse de chaque portefeuille. Nous effectuons une recherche et récupérons automatiquement les données de toutes les autres adresses du portefeuille.",
              1: () =>
                "L'importation de plusieurs adresses à partir du même portefeuille entraînera des transactions en double.",
              2: () =>
                "Actuellement, nous ne prenons pas en charge l'obtention de l'historique complet des transactions des portefeuilles contenant plus de 1 000 adresses individuelles ou lorsque le nombre de transactions est supérieur à 2 000 dans certains cas. Nous travaillons à fournir un support supplémentaire pour ces portefeuilles.",
            },
            title: () => "Plusieurs adresses de portefeuille",
          },
          1: {
            items: {
              0: () =>
                "Si vous importez une adresse commençant par « addr1 », nous la modifions automatiquement par l'adresse du propriétaire du compte commençant par « stake1 ».",
              1: () =>
                "Cela nous permet d'importer toutes les autres adresses que vous pouvez créer dans le même portefeuille et de capturer vos récompenses de jalonnement.",
            },
            title: () => "Pourquoi mon adresse est stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () =>
                "Copiez l'adresse de votre portefeuille public commençant par « addr1... » ou « stake1... »",
              2: () =>
                "Collez l'adresse de votre portefeuille public dans CTC et cliquez sur « Importer le portefeuille »",
            },
          },
        },
      },
    },
    cashapp: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Cash App et accédez à la page d'activité (https://cash.app/account/activity)",
              1: () => "Cliquez sur « Déclarations »",
              2: () => "Cliquez sur « Exporter CSV »",
              3: () =>
                "Vous pouvez désormais télécharger ce CSV sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    celestia: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Celestia dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    celo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Nous rencontrons actuellement des problèmes lors de la collecte de jetons ERC-1155 et travaillons à une solution. Si vous rencontrez ce problème entre-temps, essayez plutôt notre processus de téléchargement manuel au format CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Jetons ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Celo dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    celsius: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Celsius",
              1: () =>
                "Cliquez sur le menu déroulant en haut à droite de la plateforme",
              2: () =>
                "Sélectionnez « API » puis cliquez sur « Créer une nouvelle clé »",
              3: () =>
                "Copiez la « clé API » générée ici dans Crypto Tax Calculator, entrez un surnom facultatif, puis cliquez sur « Ajouter une API » pour synchroniser votre API",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Pour des étapes plus détaillées avec des captures d'écran, consultez la page d'assistance à l'adresse https://support.celsius.network/hc/en-us/articles/360004491118-How-do-I-export-my-transaction-history-as-CSV-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez l'application Celsius et appuyez sur le « logo Celsius ».",
              1: () => "Appuyez sur le bouton « Profil ».",
              2: () =>
                "Appuyez sur « Télécharger l’historique des transactions » dans la section Paramètres.",
              3: () =>
                "Une fois que vous avez appuyé sur le bouton Télécharger l'historique des transactions, vérifiez l'e-mail que vous avez utilisé pour enregistrer votre portefeuille Celsius. Cliquez sur le bouton Télécharger dans l'e-mail.",
              4: () =>
                "Téléchargez le fichier CSV dans Crypto Tax Calculator à l'aide du bouton d'importation.",
            },
          },
        },
      },
    },
    cex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Cex.io",
              1: () =>
                "Accédez à la page d'accès à l'API https://cex.io/trade/profile#/api",
              2: () => "Cliquez sur le bouton « Générer la clé API »",
              3: () =>
                "Accédez aux autorisations pour activer uniquement les autorisations « solde du compte » et « commandes ouvertes »",
              4: () => "Cliquez sur le bouton « Générer la clé »",
              5: () => "Copiez l'ID utilisateur, la clé API et le secret API",
              6: () =>
                "Cliquez sur le bouton « Activer » à côté de votre nouvelle clé API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Cex.io",
              1: () =>
                "Accédez à Finance > Transactions https://cex.io/finance/transactions",
              2: () =>
                "Sélectionnez « Tous les types de transactions » avec des dates couvrant toutes les transactions",
              3: () =>
                "Cliquez sur le bouton « Télécharger au format CSV » avant de télécharger sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    changelly: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à : Mon compte - > Historique https://changelly.com/history",
              1: () => "Appuyez sur « Exporter CSV »",
              2: () => "Téléchargez le fichier CSV ici",
            },
          },
        },
      },
    },
    chihuahua: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Chihuahua dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    chiliz: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Les fichiers doivent suivre le format : Horodatage (UTC), Bourse, Actif négocié, Montant négocié, Actif reçu, Montant reçu",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vous pouvez importer des transactions depuis la bourse Chiliz et les applications associées, par exemple Socios",
            },
          },
        },
      },
    },
    clvchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Nous rencontrons actuellement des problèmes lors de la collecte de jetons ERC-1155 et travaillons à une solution. Si vous rencontrez ce problème entre-temps, essayez plutôt notre processus de téléchargement manuel au format CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Jetons ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille CLV P-Chain dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    coin98: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Seules les adresses de portefeuille Solana stockées dans votre portefeuille Coin98 sont actuellement prises en charge",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trouvez l'adresse du portefeuille blockchain Solana que vous avez utilisé pour trader via Coin98",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    coinbase: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Veuillez vous assurer que votre paramètre de devise Coinbase correspond à votre devise locale sur https://accounts.coinbase.com/profile > Préférences > Devise.",
              1: () =>
                "Il s'agit d'une connexion API en lecture seule. Pour des raisons de sécurité, Crypto Tax Calculator ne prend pas en charge la connexion de clés permettant le trading ou le retrait. Pour en savoir plus, consultez https://cryptotaxcalculator.io/privacy-policy",
              2: () =>
                "Il se peut que certaines transactions manquent en raison de l'utilisation de devises que Coinbase ne prend pas en charge. (https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/lost-assets)",
              3: () =>
                "Si l'importation est réussie via des clés API, n'utilisez pas la méthode « Connecter Coinbase » car cela entraînerait des doublons.",
              4: () =>
                "Les retraits et les dépôts de jalonnement sont ignorés, mais cela n'affecte pas les rapports.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à la plateforme de développement Coinbase (https://portal.cloud.coinbase.com/).",
              1: () => "Accédez aux clés API.",
              2: () =>
                "Dans « Créer une clé API », définissez un surnom, sélectionnez votre portefeuille et choisissez les autorisations « Afficher (lecture seule) ».",
              3: () => "Cliquez sur « Créer et télécharger ».",
              4: () => "Authentification complète à 2 facteurs.",
              5: () =>
                "Copiez le nom et le secret de la clé API et collez-les dans Crypto Tax Calculator.",
              6: () =>
                "Votre clé API secrète n'est affichée qu'une seule fois. Téléchargez le fichier JSON en guise de sauvegarde. En cas de perte, créez une nouvelle clé API dans Coinbase et mettez-la à jour dans Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/7GbjlA9B3bI",
            title: () => "Comment importer votre API Coinbase",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Si vous avez utilisé des transactions de remplissage, les options « Synchroniser via API » ou « Connecter Coinbase » peuvent donner des résultats plus précis.",
              1: () =>
                "Veuillez vous assurer que votre paramètre de devise Coinbase correspond à votre devise locale sur https://accounts.coinbase.com/profile > Préférences > Devise.",
              2: () =>
                "Les retraits et les dépôts de jalonnement sont ignorés, mais cela n'affecte pas les rapports.",
            },
          },
          1: {
            items: {
              0: () =>
                "Les lignes de commerce avancées ne contiennent pas d'informations de tarification complètes.",
              1: () =>
                "Cela peut entraîner des différences mineures entre votre importation CSV et les détails de la transaction avancée affichés dans Coinbase.",
              2: () =>
                "Pour de meilleurs résultats, essayez d'importer via notre intégration API.",
            },
            title: () =>
              "L'API Coinbase est idéale pour les transactions avancées",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Comptes > Relevé (https://accounts.coinbase.com/statements)",
              1: () =>
                "Dans « Générer un relevé personnalisé », conservez les champs définis sur « Historique des transactions », « Tous les actifs », « Toutes les transactions » et « Tout le temps ».",
              2: () => "Sélectionnez « CSV » et cliquez sur « Générer ».",
              3: () => "Téléchargez le rapport.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/nm2-aOO5iI4",
            title: () => "Comment importer vos fichiers CSV Coinbase",
          },
        },
      },
      oauth: {
        note: {
          0: {
            items: {
              0: () =>
                "Veuillez vous assurer que votre paramètre de devise Coinbase correspond à votre devise locale sur https://accounts.coinbase.com/profile > Préférences > Devise.",
              1: () =>
                "Si vous vous êtes connecté à l'aide de cette méthode, vous n'avez pas besoin de mettre à jour vos clés conformément à l'obsolescence des clés héritées de février 2025.",
              2: () =>
                "Si la connexion ne fonctionne pas, veuillez cliquer sur « Réautoriser ».",
              3: () =>
                "Les retraits et les dépôts de jalonnement sont ignorés, mais cela n'affecte pas les rapports.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Cliquez sur « Connecter Coinbase » pour ouvrir un nouvel onglet.",
              1: () => "Connectez-vous à votre compte Coinbase.",
              2: () => "Autoriser l'accès en lecture seule.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/qHL8O_alo8Q",
            title: () => "Comment importer vos données Coinbase via Oauth",
          },
        },
      },
    },
    "coinbase-wallet": {
      "bulk-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez le portefeuille Coinbase sur votre mobile ou dans votre navigateur",
              1: () =>
                "Cliquez sur le nom de votre portefeuille en haut à gauche de l'écran",
              2: () => "Cliquez sur « Ajouter et gérer les portefeuilles »",
              3: () => "Sélectionnez un portefeuille",
              4: () => "Cliquez sur Exporter les adresses publiques",
              5: () => "Cliquez sur Copier",
              6: () =>
                "Collez la liste des portefeuilles dans la zone de texte exactement comme elle a été copiée",
              7: () =>
                "Tous vos portefeuilles dans le portefeuille Coinbase seront connectés",
            },
          },
        },
      },
    },
    coinbaseexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Coinbase Exchange.",
              1: () => "Créez une nouvelle clé API.",
              2: () =>
                "Assurez-vous de définir les autorisations appropriées (lecture seule).",
              3: () =>
                "Une fois créé, collez la clé API et le secret dans Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinbaseprime: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous avons résolu un problème affectant les transactions TWAP importées avant mars 2025. Pour restaurer les transactions manquantes, veuillez supprimer et rajouter vos clés API. L'exécution d'une synchronisation matérielle importera également les transactions manquantes, mais peut entraîner des doublons. Veillez donc à vérifier et à supprimer tous les doublons. Pour plus d'informations, veuillez contacter le support.",
            },
            title: () => "Mise à jour des transactions TWAP",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Coinbase Prime",
              1: () =>
                "Dans le coin inférieur gauche de la page, cliquez sur l'icône des paramètres Gear",
              2: () => "Cliquez sur les API",
              3: () =>
                "À côté de « Clés activées », cliquez sur « Créer une clé API »",
              4: () =>
                "Dans la fenêtre contextuelle, saisissez le « Nom de l'API », la « Date d'expiration » et le « Type d'accès » en lecture seule",
              5: () => "Cliquez sur « Continuer »",
              6: () => "Vérifiez votre identité avec YubiKey",
              7: () => "Attendre les approbations consensuelles",
              8: () => "Sous « Clés en attente », recherchez votre clé API",
              9: () => "Cliquez sur « Activer la clé »",
              10: () =>
                "Cliquez sur « Activer la clé API » et vérifiez à nouveau avec YubiKey",
              11: () =>
                "Copiez et enregistrez la clé API, le secret et la phrase de passe générés. Collez la clé d'accès dans « Clé API », la phrase de passe dans « Phrase de passe » et la clé de signature dans « Secret ».",
              12: () =>
                "Des instructions plus détaillées peuvent être trouvées à l'adresse suivante : https://help.coinbase.com/en/prime/coinbase-prime-api/creating-a-coinbase-prime-api",
            },
          },
        },
      },
    },
    coinbasepro: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Si vous avez plus de 500 transactions OTC, nous vous recommandons de télécharger les transactions OTC à l'aide d'un fichier CSV. Veuillez noter que des transactions OTC en double peuvent survenir lorsque vous combinez API et CSV de cette manière.",
              1: () =>
                "Toutes les transactions OTC importées via l'API contiennent « OTC » dans la description de la transaction, ce qui, combiné à la suppression en masse, peut être utilisé pour supprimer facilement tous les doublons.",
            },
            title: () => "Transactions OTC",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "À compter du 20 novembre 2023, Coinbase Pro et son API sont obsolètes. Si vous n'avez pas importé l'API avant cette date, veuillez passer à « Télécharger un fichier » et suivre les instructions pour télécharger l'historique de vos transactions Coinbase Pro via CSV.",
              1: () =>
                "Si vous avez déjà importé l'API avant cette date et que la synchronisation échoue en raison d'une clé API expirée, importez l'historique de vos transactions Coinbase Pro via CSV à partir de la dernière date de synchronisation de votre API.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Attention : nous ne traitons que les dépôts et les retraits à partir du fichier CSV « Relevé de compte ». Toutes les autres transactions sont extraites du fichier CSV « Remplir le relevé »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Coinbase.",
              1: () =>
                "Cliquez sur l'icône de profil dans le coin supérieur droit.",
              2: () =>
                "Sélectionnez « Rapports » dans le menu déroulant (https://www.coinbase.com/reports).",
              3: () =>
                "Sélectionnez « Afficher les relevés » dans la section « Relevés Coinbase Pro » (https://accounts.coinbase.com/statements/pro).",
              4: () =>
                "Laissez les listes déroulantes Portefeuille, Type de compte et Date avec leurs paramètres respectifs « Tous ».",
              5: () =>
                "Sélectionnez « Compte » comme type de rapport, sélectionnez CSV, puis cliquez sur « Générer » pour télécharger le fichier « account.csv »",
              6: () =>
                "Sélectionnez « Remplissages » comme type de rapport, sélectionnez CSV et cliquez sur Générer pour télécharger le fichier « fills.csv »",
              7: () =>
                "Téléchargez ces deux fichiers CSV dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinberry: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Assurez-vous que votre rapport couvre toutes les années pendant lesquelles vous avez négocié des crypto-monnaies",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Coinberry sur un navigateur Web",
              1: () =>
                "Accédez à votre tableau de bord et cliquez sur « Activité Coinberry » pour télécharger vos données de trading Téléchargement CSV Coinberry",
              2: () =>
                "N'ouvrez pas et ne réenregistrez pas le fichier avant de l'importer, car cela pourrait modifier le format du fichier CSV",
              3: () =>
                "Téléchargez le fichier que vous venez de télécharger ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coincheck: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Coincheck",
              1: () =>
                "Dans le menu de gauche, sélectionnez l'icône d'engrenage, puis cliquez sur « Clé API »",
              2: () =>
                "Terminez la vérification 2FA, puis cliquez sur « Ajouter une autre clé API »",
              3: () =>
                "Dans la fenêtre qui s'ouvre, sous la rubrique « Commande », sélectionnez l'option « Historique de trading »",
              4: () =>
                "Sous la rubrique « Compte », sélectionnez les options « Historique d'envoi » et « Historique des dépôts »",
              5: () =>
                "Sous la rubrique « Retirer JPY », sélectionnez l'option « Historique de retrait JPY »",
              6: () =>
                "Laissez le champ IP vide, entrez votre mot de passe et cliquez sur « OK » pour générer la clé API",
              7: () =>
                "Saisissez ici la « clé API » et le « secret API » dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
    },
    coincorner: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Le fichier doit être un fichier CSV. Veuillez l'exporter au format CSV si vous avez un fichier .xlsx",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vous pouvez importer des transactions à partir d'un fichier CSV exporté par Coincorner",
            },
          },
        },
      },
    },
    coindcx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez l'application mobile CoinDCX et cliquez sur l'icône de profil dans le coin supérieur gauche.",
              1: () => "Sélectionnez « Télécharger les rapports ».",
              2: () =>
                "Cliquez sur « Rapport commercial » dans la section « Télécharger les rapports ».",
              3: () =>
                "Saisissez l'exercice financier et la période, puis cliquez sur « Générer ». Votre rapport sera téléchargé automatiquement.",
              4: () =>
                "Si vos transactions s’étendent sur plusieurs périodes, répétez cette étape si nécessaire.",
              5: () => "Téléchargez les fichiers sur Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte CoinEx",
              1: () =>
                "Développez le menu « Actifs » et cliquez sur « Historique »",
              2: () =>
                "Définissez la plage horaire de vos données de transaction et cliquez sur « Exporter » pour télécharger un fichier « .xlsx »",
              3: () =>
                "Téléchargez votre fichier « .xlsx » dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinexchange: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Veuillez noter que les retraits et les dépôts ne sont pas inclus dans les données de transaction CSV et devront être ajoutés manuellement",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Cet échange a été désactivé, mais vous pouvez toujours télécharger vos données de transaction enregistrées au format CSV",
            },
          },
        },
      },
    },
    coinfalcon: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Accédez à la section Compte de votre compte",
              1: () => "Cliquez sur Accès API",
              2: () =>
                "Créez une nouvelle clé API et copiez les clés dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinfield: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "CoinField ne vous permet de télécharger des rapports que pour un seul marché à la fois, alors assurez-vous de générer des rapports qui englobent tous les marchés sur lesquels vous avez négocié (par exemple BTC/USD, ETH/CAD) et téléchargez-les ici",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte CoinField sur un navigateur Web",
              1: () =>
                "Accédez à votre tableau de bord et cliquez sur Trade > Trade-History dans la barre de navigation sur la gauche",
              2: () =>
                "Téléchargez votre fichier CSV d'historique de transactions",
              3: () =>
                "Assurez-vous de répéter ce processus pour tous les marchés/crypto-monnaies avec lesquels vous avez négocié",
              4: () =>
                "Téléchargez les fichiers que vous venez de télécharger ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinjar: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte CoinJar",
              1: () =>
                "Cliquez sur le bouton ci-dessous pour être redirigé vers CoinJar dans un nouvel onglet",
              2: () =>
                "Activer les autorisations de rapport dans votre compte CoinJar",
              3: () =>
                "Nous synchroniserons ensuite l'historique de vos transactions CoinJar",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "L'option d'importation CSV ne prend pas en charge les transactions groupées, dissociées et liquidées. Si vous avez ces transactions, veuillez les importer via l'option CSV personnalisée",
              1: () =>
                "Si vos retraits étaient destinés à un usage personnel ou à des transactions de vente, vous pouvez les marquer comme tels sur la page suivante",
              2: () =>
                "Si vous avez effectué des transactions via l'échange CoinJar, vous devez également ajouter le fichier CSV « remplir »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à CoinJar",
              1: () =>
                "Dans la barre de navigation supérieure, cliquez sur « Paramètres »",
              2: () =>
                "Dans la barre latérale gauche, cliquez sur « Rapports et relevés »",
              3: () => "Cliquez sur « Historique des transactions »",
              4: () =>
                "Cliquez sur Télécharger le fichier CSV « Relevé de compte »",
              5: () =>
                "Cliquez sur Plage de dates personnalisée et sélectionnez l'intégralité de votre historique de trading comme plage de dates",
              6: () =>
                "Exportez le fichier CSV et téléchargez les deux fichiers CSV dans Crypto Tax Calculator",
              7: () =>
                "Vous devriez recevoir un email avec votre fichier CSV. Téléchargez ce fichier CSV et chargez-le ici.",
            },
          },
        },
      },
    },
    coinjarexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte CoinJar Exchange",
              1: () =>
                "Sous les paramètres de votre compte, accédez à la section API",
              2: () => "Créer une nouvelle clé API",
              3: () =>
                "Assurez-vous que la clé appartient à la portée « lecture seule »",
              4: () =>
                "Copiez le « secret » de votre nouvelle clé API ici dans Crypto Tax Calculator et indiquez un surnom",
            },
          },
        },
      },
    },
    coinlist: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Notre intégration CSV CoinList est actuellement en version bêta. Si vous rencontrez des problèmes ou avez des commentaires, veuillez contacter notre support",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte CoinList",
              1: () =>
                "Cliquez sur le menu déroulant en haut à droite de la plateforme CoinList et accédez à « Portefeuilles »",
              2: () =>
                "Sélectionnez vos portefeuilles et définissez la plage horaire des données de transaction et cliquez sur « Télécharger CSV »",
              3: () =>
                "Télécharger le fichier CSV dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
    },
    coinlistpro: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte CoinList Pro",
              1: () =>
                "Cliquez sur le menu déroulant en haut à droite de la plateforme CoinList Pro",
              2: () =>
                "Sélectionnez « API » puis cliquez sur « Créer une nouvelle clé »",
              3: () =>
                "Générer une clé API unique avec des autorisations « lecture seule »",
              4: () =>
                "Copiez la « clé API » et le « secret API » générés ici dans Crypto Tax Calculator, entrez un surnom facultatif, puis cliquez sur « Ajouter une API » pour synchroniser votre API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte CoinList Pro",
              1: () =>
                "Cliquez sur le menu déroulant en haut à droite de la plateforme CoinList Pro",
              2: () =>
                "Sélectionnez Relevés et définissez le « Type de relevé », la « Date de début » et la « Date de fin » et cliquez sur « Demander un relevé »",
              3: () =>
                "Téléchargez les deux fichiers CSV de remplissage et le fichier CSV de compte dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
    },
    coinmetro: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte CoinMetro sur un navigateur Web",
              1: () =>
                "Accédez à votre tableau de bord et faites défiler jusqu'à votre historique de transactions",
              2: () =>
                "Sélectionnez la plage de dates correspondant au début de votre période de trading ou à la période qui vous intéresse à l'aide du menu déroulant « Plage de dates »",
              3: () =>
                "Sélectionnez « Dépôt et retrait » dans le menu déroulant « Types »",
              4: () =>
                "Cliquez sur « Télécharger CSV » pour télécharger l'historique de vos transactions",
              5: () =>
                "Assurez-vous de répéter cette opération avec tous vos actifs négociés, qui peuvent être sélectionnés dans le menu déroulant « Actif »",
              6: () =>
                "Téléchargez les fichiers que vous venez de télécharger ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinsmart: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Bitbuy en utilisant vos anciennes informations d'identification Coinsmart et contactez l'équipe de support client de Bitbuy pour demander l'historique de vos transactions.",
              1: () =>
                "Téléchargez vos fichiers CSV ici dans Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinspot: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous synchronisons toutes vos données CoinSpot via l'API. Cela comprend les transactions, les parachutages, les transferts de crypto-monnaies, les dépôts/retraits en AUD, les paiements de revenus d'affiliation et les paiements de revenus de référence.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à CoinSpot et accédez à la page « API » (https://www.coinspot.com.au/my/api).",
              1: () => "Cliquez sur « Générer une nouvelle clé API ».",
              2: () =>
                "Nommez la clé et définissez le type de clé API sur « Lecture seule ».",
              3: () =>
                "Saisissez votre code à deux facteurs et cliquez sur « Créer une nouvelle clé ».",
              4: () =>
                "Confirmez la création de la clé API en cliquant sur le lien envoyé à votre email.",
              5: () => "Copiez et collez la « clé API » dans CTC",
              6: () =>
                "Révélez, puis copiez et collez la « clé secrète » dans CTC (vous ne pourrez plus y accéder).",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/Pls-kQAg94U",
            title: () => "Comment importer votre API Coinspot",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Le CSV n'inclut pas d'informations sur les airdrops, les dépôts/retraits AUD ou les paiements d'affiliation/de parrainage, vous devrez soit les ajouter manuellement, soit utiliser la synchronisation API CoinSpot",
            },
            title: () => "Transactions manquantes",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Visitez https://www.coinspot.com.au/my/orders/history",
              1: () =>
                "Cliquez sur « Envois/réceptions CSV » pour télécharger votre historique d'envoi et de réception au format « sendreceives.csv ».",
              2: () =>
                "Cliquez sur « Achats/Ventes CSV » pour télécharger l'historique de vos commandes au format « orderhistory.csv ».",
              3: () =>
                "Téléchargez les deux fichiers sur Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/IXAkAHO2N9k",
            title: () => "Comment importer vos CSV Coinspot",
          },
        },
      },
    },
    coinsquare: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Coinsquare ici (https://app-new.coinsquare.com/sign-in).",
              1: () =>
                "Cliquez sur le menu déroulant à côté de votre nom (en haut à droite) et sélectionnez « Documents ».",
              2: () =>
                "Sous « Relevés », sélectionnez l’année fiscale pour laquelle vous avez besoin de l’historique de vos transactions et cliquez sur « Télécharger ».",
              3: () =>
                "Téléchargez tous vos fichiers CSV téléchargés ici dans Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinstash: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Coinstash",
              1: () =>
                "Accédez à votre page de paramètres (https://coinstash.com.au/settings)",
              2: () =>
                "Faites défiler la page jusqu'à la section « Rapports ». Cliquez sur le bouton « Télécharger » à côté de « Historique des transactions »",
              3: () =>
                "Téléchargez maintenant le fichier CSV dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinswitch: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte CoinSwitch Kuber.",
              1: () =>
                "Appuyez sur l’icône de profil bleue dans le coin supérieur gauche de la page d’accueil.",
              2: () =>
                "Accédez à la section « Rapports » et téléchargez votre relevé de compte.",
              3: () => "Sélectionnez la section « P&L fiscal ».",
              4: () =>
                "Choisissez l'exercice financier, sélectionnez « Tous les trimestres » et appuyez sur « Envoyer le relevé ».",
              5: () => "Téléchargez le fichier sur Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    cointracking: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Une fois importées, les transactions seront réparties dans leurs importations d'échange correspondantes, le cas échéant.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vous pouvez importer tout votre historique de trading depuis Cointracking",
              1: () =>
                "Connectez-vous à votre compte CoinTracking et, dans la barre de navigation, cliquez sur « Entrer des pièces » (https://cointracking.info/enter_coins.php)",
              2: () =>
                "Dans le tableau, vous verrez un bouton « Exporter ». Cliquez sur ce bouton et sélectionnez « CSV » dans la liste déroulante. Un fichier CSV sera téléchargé automatiquement",
              3: () =>
                "Téléchargez le fichier CSV téléchargé ici sur Crypto Tax Calculator.",
            },
            title: () => "Tableau des échanges (recommandé) :",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez également exporter votre solde de clôture du dernier exercice.",
              1: () =>
                "Veuillez vous assurer que votre exportation est dans votre monnaie fiduciaire locale.",
              2: () => "Le format de la date doit être « JJ.MM.AAAA ».",
            },
            title: () => "Solde de clôture :",
          },
        },
      },
    },
    cointree: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Assurez-vous de noter votre secret API dans un endroit sûr.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Cointree",
              1: () =>
                "Cliquez sur l'icône de votre profil dans le coin supérieur droit et accédez à « Paramètres »",
              2: () =>
                "Accédez à « Clés API » (dernière option dans le menu supérieur)",
              3: () =>
                "Saisissez un nom d'affichage pour votre clé API dans le champ « Nom d'affichage de la clé API »",
              4: () =>
                "Choisissez un niveau d'accès pour votre clé API et cliquez sur « Générer la clé API + le secret »",
              5: () =>
                "Copiez et collez votre clé API et votre secret API dans Crypto Tax Calculator",
              6: () =>
                "Indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Cointree",
              1: () => "Accédez à Portefeuille > Transactions",
              2: () =>
                "Cliquez sur le bouton « Exporter » dans le tableau « Toutes les transactions »",
              3: () =>
                "Cliquez sur « Exporter les commandes (csv) » dans le menu déroulant pour télécharger votre historique de trading",
              4: () =>
                "Cliquez sur « Exporter les transactions (csv) » dans le menu déroulant pour télécharger votre historique de dépôt/retrait",
              5: () =>
                "Téléchargez les deux fichiers CSV ici sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "cosmos/ibc": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () =>
                "Accédez au compte que vous souhaitez importer et copiez l'adresse de votre portefeuille public",
              2: () =>
                "Dans CTC, choisissez le réseau blockchain concerné dans la liste déroulante",
              3: () =>
                "Collez l'adresse de votre portefeuille public et cliquez sur « Importer un portefeuille »",
            },
          },
        },
      },
    },
    cosmoshub: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Les données antérieures au 20/02/2021 ne sont pas disponibles en raison d'une limitation de la source de données.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Cosmos Hub dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    coss: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Coss",
              1: () => "Accéder à la page de gestion des API",
              2: () => "Nom du type de clé",
              3: () => "Cliquez sur le bouton « Créer une nouvelle clé »",
              4: () => "Entrez votre code 2FA",
              5: () => "Cliquez sur le bouton « Suivant »",
              6: () =>
                "Vérifiez votre courrier électronique et cliquez sur le lien « Cliquez ici pour créer une clé API »",
              7: () => "Copiez à la fois la clé publique et la clé secrète",
              8: () =>
                "Cliquez sur le bouton « Modifier » pour désactiver l'autorisation de négociation pour cette clé",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Coss",
              1: () => "Accéder à l'historique des échanges",
              2: () =>
                "Cliquez sur le bouton « Exporter l'historique complet des transactions »",
              3: () =>
                "Assurez-vous que les dates sélectionnées correspondent à une plage antérieure à l'ouverture de votre compte et maintenant",
              4: () => "Cliquez sur « Exporter »",
              5: () =>
                "Télécharger un fichier CSV vers le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    crex24: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Lors de la génération des clés API, vérifiez que vous sélectionnez uniquement les options R1 et R3.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à CREX24",
              1: () =>
                "Cliquez sur « Mon compte » dans le coin supérieur droit",
              2: () => "Cliquez sur l'onglet « Clés API »",
              3: () =>
                "Cliquez sur « Nouvelle clé API » et saisissez le code que vous avez reçu par e-mail",
              4: () =>
                "Vous devriez maintenant voir une nouvelle clé API dans le tableau",
              5: () =>
                "Réglez l'onglet « Statut » sur « ON » et cochez les cases R1 et R3",
              6: () =>
                "Copiez la clé API et le secret et collez-les dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    cronos: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons désormais en charge les jetons CRC-20. Si vous avez déjà importé votre compte Cronos, vous devrez peut-être « synchroniser » votre API pour actualiser les données historiques.",
            },
            title: () => "Jetons CRC-20",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille que vous avez utilisé pour trader via Cronos (en commençant par 0x)",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    cryptodotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Le format de date doit être l'un des suivants : « JJ-MM-AA H:mm », « M/J/AA H:mm » ou « AAAA-MM-JJ HH:mm:ss ». Si vous n'avez pas le format de date correct dans votre fichier CSV, veuillez contacter notre équipe d'assistance via le chat Web.",
              1: () =>
                "Si vous importez votre fichier CSV « fiat_transactions_record », vous DEVEZ supprimer toutes les lignes de type de transaction « viban_purchase » et « recurring_buy_order ». Étant donné que ces données sont déjà enregistrées dans le fichier CSV « crypto_transactions_record », l'importation des deux entraînera des erreurs d'importation.",
            },
            title: () => "Formatage CSV",
          },
          1: {
            items: {
              0: () =>
                "Parfois, le fichier CSV exporté est corrompu. Si vous obtenez des erreurs de téléchargement, essayez de réenregistrer le fichier localement au format CSV, puis de le réimporter.",
            },
            title: () => "Erreurs de téléchargement",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez votre application Crypto.com et accédez à l'onglet « Comptes ».",
              1: () =>
                "Cliquez sur « Historique des transactions », puis sur « Exporter ».",
              2: () =>
                "Sélectionnez « Portefeuille cryptographique » sous « Transaction ».",
              3: () =>
                "Sélectionnez une date de début pour toutes les transactions et définissez la date de fin sur aujourd'hui.",
              4: () =>
                "Cliquez sur « Exporter vers CSV » pour télécharger le fichier.",
              5: () => "Téléchargez le fichier CSV sur Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    cryptodotcomexchange: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "La synchronisation de l'API peut prendre jusqu'à 90 minutes, car nous devons effectuer une itération tous les jours et elle est limitée par Crypto.com.",
              1: () =>
                "Parfois, l'API ne parvient pas à se synchroniser en raison de problèmes avec Crypto.com. Si cela vous arrive, essayez plutôt l'importation CSV",
              2: () =>
                "L'API de Crypto.com ne prend en charge que les 6 derniers mois de l'historique des transactions. Si vous avez besoin de données plus anciennes, veuillez utiliser la méthode CSV.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Cliquez sur Paramètres > Clés API (https://crypto.com/exchange/personal/api-management).",
              1: () =>
                "Générer des clés API en lecture seule qui ne permettent pas le trading",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous ne prenons actuellement pas en charge le trading sur marge ou sur produits dérivés en raison de restrictions nationales. Si vous avez négocié ces produits, veuillez contacter notre support par chat intégré à l'application. De plus, le fichier CSV de retrait n'inclut pas les frais. Vous devrez les mettre à jour manuellement.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Crypto.com propose plusieurs formats CSV pour l'exportation de données. Vous aurez besoin de tous ces formats (Transactions, Dépôts et retraits et Historique des transactions) pour importer l'intégralité de votre historique de transactions.",
              1: () =>
                "Veuillez noter que Crypto.com autorise une plage de dates maximale de 180 jours par exportation. Pour couvrir l'intégralité de votre historique de transactions, vous devrez peut-être exporter plusieurs fichiers pour chaque format.",
              2: () => "Connectez-vous à votre compte Crypto.com.",
              3: () =>
                "Changez la langue en anglais (si ce n'est pas déjà le cas).",
              4: () =>
                "Depuis le tableau de bord, sélectionnez Portefeuille dans le menu de gauche.",
              5: () =>
                "Accédez à l'onglet Transactions. Choisissez une plage de dates et cliquez sur Exporter pour télécharger le fichier CSV des transactions.",
              6: () => "Retourner au menu Portefeuille.",
              7: () =>
                "Accédez à l'onglet Dépôt et retrait. Choisissez une plage de dates et cliquez sur Exporter pour télécharger le fichier CSV Dépôt et retrait.",
              8: () =>
                "Depuis le tableau de bord, sélectionnez Commandes dans le menu de gauche.",
              9: () =>
                "Accédez à l'onglet Historique des transactions. Choisissez une plage de dates et cliquez sur Exporter pour télécharger le fichier CSV Historique des transactions.",
              10: () =>
                "Importez les trois fichiers téléchargés (deposit&Withdrawal.csv, OEX_TRANSACTION.csv dans CTC, OEX_TRADE.csv) dans CTC.",
            },
          },
        },
      },
    },
    cryptospend: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Wayex",
              1: () =>
                "Accédez à la page « Compte », faites défiler jusqu'aux transactions, puis appuyez sur « Obtenir les transactions »",
              2: () =>
                "Cela générera un fichier CSV qui sera envoyé à votre adresse e-mail",
              3: () => "Téléchargez le fichier CSV depuis votre e-mail",
              4: () =>
                "Téléchargez ce CSV ici dans le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    cryptotaxcalculatorlegacy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Uniquement pour l'importation de transactions manuelles à partir de legacy.cryptotaxcalculator.io",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Veuillez d'abord nous envoyer un e-mail si vous effectuez une conversion à partir d'une version héritée pour obtenir les transactions manuelles sous forme de fichier CSV",
              1: () =>
                "Téléchargez le fichier CSV ici vers Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    customCSV: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "CSV avancé pour des transactions plus détaillées : https://cryptotaxcalculator.io/guides/advanced-manual-csv-import",
              1: () =>
                "Assurez-vous que vos données sont préparées correctement et dans un format de fichier .csv !",
            },
            title: () =>
              "Si votre échange n'est pas disponible via notre importation automatique, vous pouvez l'importer en utilisant l'un de nos modèles CSV personnalisés :",
          },
        },
      },
    },
    daedalus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Saisissez uniquement UNE adresse de chaque portefeuille. Nous effectuons une recherche et récupérons automatiquement les données de toutes les autres adresses du portefeuille.",
              1: () =>
                "L'importation de plusieurs adresses à partir du même portefeuille entraînera des transactions en double.",
              2: () =>
                "Actuellement, nous ne prenons pas en charge l'obtention de l'historique complet des transactions des portefeuilles où chaque adresse individuelle contient plus de 10 transactions. Nous travaillons à fournir un support supplémentaire pour ces portefeuilles.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez n'importe quelle adresse shelly (addr1..) ou adresse de stake (stake1...) de votre portefeuille Daedalus dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez désormais importer les transactions de votre portefeuille Daedalus directement via la blockchain en vous connectant à l'option « Synchroniser via API ». Cela récupérera toutes les transactions et récompenses de jalonnement pour votre portefeuille Daedalus",
              1: () =>
                "Il semble que le fichier CSV de transaction contienne parfois des données erronées. Il s'agit d'un problème avec Daedalus. Veuillez contacter le support si vous constatez des anomalies.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre portefeuille Daedalus et exportez votre récompense CSV",
              1: () => "Vous pouvez également importer les transactions CSV",
            },
          },
        },
      },
    },
    dash: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
              1: () =>
                "Nous prenons également en charge les adresses xpub, ypub et zpub, mais xpub est préféré.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Dash dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    ddex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () => "Sélectionnez l'échange dans le menu déroulant",
              3: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              4: () => "Conserver les autres paramètres par défaut",
              5: () => "Cliquez sur « Récupérer l'historique »",
              6: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              7: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    decentrex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Sur le côté gauche du menu, développez « Historique des échanges » et cliquez sur Decentrex",
              3: () =>
                "Sous « Types d'opérations », cliquez sur « Transactions + Fonds »",
              4: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              5: () => "Conserver les autres paramètres par défaut",
              6: () => "Cliquez sur « Récupérer l'historique »",
              7: () =>
                "Une fois terminé, vous pouvez exporter le fichier csv par défaut sous « Exporter les transactions » et « Exporter les fonds ».",
              8: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ces fichiers CSV",
            },
          },
        },
      },
    },
    deltaBalances: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () => "Sélectionnez l'échange dans le menu déroulant",
              3: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              4: () => "Conserver les autres paramètres par défaut",
              5: () => "Cliquez sur « Récupérer l'historique »",
              6: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              7: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    deribit: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez la page Clés API sur Deribit et sélectionnez Ajouter une nouvelle clé API",
              1: () =>
                "Sélectionnez l'autorisation de lecture pour le compte, la transaction et le portefeuille",
              2: () => "Copiez la clé et le secret dans Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Deribit et cliquez sur votre nom d'utilisateur dans le coin supérieur droit et sélectionnez « Journal des transactions »",
              1: () =>
                "Sélectionnez les dates auxquelles vous avez effectué des transactions et téléchargez les journaux",
              2: () =>
                "Si vous avez échangé Ethereum, vous devrez également cliquer sur Ethereum dans le coin supérieur gauche et répéter les étapes ci-dessus",
              3: () =>
                "Vous pouvez ensuite importer les fichiers CSV dans l'application pour signaler les bénéfices/pertes générés par le trading.",
            },
          },
        },
      },
    },
    digitalsurge: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Digital Surge",
              1: () =>
                "Cliquez sur le menu hamburger en haut à droite de la page. Dans la fenêtre déroulante, sélectionnez « Clé API » pour accéder à la page Clé API (https://digitalsurge.com.au/account-settings/api-keys)",
              2: () =>
                "En haut à droite de la page, cliquez sur le bouton « Créer une nouvelle clé API »",
              3: () =>
                "Dans la fenêtre contextuelle, assurez-vous de sélectionner « Lecture seule », puis cliquez sur « Suivant »",
              4: () =>
                "Saisissez le code de confirmation 2FA puis cliquez sur « Générer la clé API »",
              5: () =>
                "Copiez la clé API affichée, collez-la dans le champ « Secret » ici sur Crypto Tax Calculator, entrez un surnom facultatif, puis cliquez sur « Ajouter une API » pour synchroniser votre API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Digital Surge",
              1: () =>
                "Cliquez sur le menu hamburger en haut à droite de la page. Dans la fenêtre déroulante, sélectionnez « Historique des transactions » pour accéder à la page Historique des transactions (https://digitalsurge.com.au/account-settings/transactions-history)",
              2: () =>
                "Sur la page Historique des transactions, cliquez sur le bouton « Exporter l'historique » (pour les appareils mobiles, cliquez sur les trois points à droite du titre Historique des transactions)",
              3: () =>
                "Dans la liste déroulante, sélectionnez l'option « Historique des transactions » pour ouvrir la fenêtre contextuelle « Exporter l'historique des transactions ». Dans cette fenêtre contextuelle, laissez la sélection de date vide (cela couvre l'intégralité de votre historique de transactions) et choisissez le format « CSV ». Une fois sélectionné, cliquez sur le bouton « Exporter l'historique » pour télécharger votre fichier « trades.csv »",
              4: () =>
                "Dans la liste déroulante, sélectionnez l'option « Historique des transferts » pour ouvrir la fenêtre contextuelle « Exporter l'historique des transferts ». Dans cette fenêtre contextuelle, laissez la sélection de date vide et choisissez le format « CSV ». Une fois sélectionné, cliquez sur le bouton « Exporter l'historique » pour télécharger votre fichier « transfer.csv »",
              5: () =>
                "Téléchargez les fichiers « trades.csv » et « transfer.csv » ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    doge: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
              1: () =>
                "Nous prenons également en charge les adresses xpub, ypub et zpub, mais xpub est préféré.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Dogecoin dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    dydx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Visitez trade.dYdX.exchange et connectez votre/vos portefeuille(s)",
              1: () => "Cliquez sur « Portfolio » dans la barre de menu",
              2: () => "Sélectionnez « Historique » dans le panneau latéral",
              3: () =>
                "Cliquez sur « Exporter » dans le coin supérieur droit de l'écran",
              4: () =>
                "Téléchargez les 3 fichiers CSV disponibles : Transferts, Échanges, Financement",
              5: () =>
                "Téléchargez les fichiers extraits sur Crypto Tax Calculator",
              6: () =>
                "Remarque : actuellement, vous rencontrez un problème d'historique d'achat manquant en raison de la façon dont dYdX enregistre les transactions à découvert. Nous travaillons à une résolution. Pour l'instant, vous pouvez potentiellement calculer le PnL et utiliser notre fichier CSV manuel pour ajouter une perte/un profit réalisé pour la transaction à découvert.",
            },
          },
        },
      },
    },
    dydx4: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "À ce stade, seules les opérations de trading dérivées telles que l'augmentation/diminution d'une position sont prises en charge.",
            },
            title: () => "Soutien opérationnel limité",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Localisez votre adresse dYdX v4 en accédant à l'application du protocole et en copiant l'adresse depuis le menu en haut à droite",
              1: () =>
                "Saisissez l'adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    dymension: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Dymension dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    easyTrade: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () => "Sélectionnez l'échange dans le menu déroulant",
              3: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              4: () => "Conserver les autres paramètres par défaut",
              5: () => "Cliquez sur « Récupérer l'historique »",
              6: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              7: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    easycrypto: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons actuellement en charge les achats et les ventes sur Easy Crypto. Si vous effectuez des échanges ou d'autres types d'échanges, veuillez contacter le support et envoyer votre fichier CSV à notre équipe de support",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Easy Crypto",
              1: () =>
                "Accédez à « Mon compte » - > « Commandes » (https://easycrypto.ai/au/account/orders)",
              2: () =>
                "Au bas de la page, vous verrez un lien pour « Télécharger un fichier CSV de vos commandes terminées ». Cliquez sur ce lien et un fichier CSV sera téléchargé sur votre ordinateur",
              3: () =>
                "Téléchargez ce fichier téléchargé ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    elbaite: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Elbaite",
              1: () => "Accédez à l'onglet [Métiers]",
              2: () => "Cliquez sur [Exporter au format CSV]",
              3: () =>
                "Téléchargez le fichier CSV ici sur votre compte Crypto Tax Calculator",
            },
          },
        },
      },
    },
    enclaves: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Sur le côté gauche du menu, développez « Historique des échanges » et cliquez sur Enclaves",
              3: () =>
                "Sous « Types d'opérations », cliquez sur « Transactions + Fonds »",
              4: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              5: () => "Conserver les autres paramètres par défaut",
              6: () => "Cliquez sur « Récupérer l'historique »",
              7: () =>
                "Une fois terminé, vous pouvez exporter le fichier csv par défaut sous « Exporter les transactions » et « Exporter les fonds ».",
              8: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ces fichiers CSV",
            },
          },
        },
      },
    },
    enkrypt: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille Enkrypt",
              1: () => "Cliquez sur le réseau que vous souhaitez importer",
              2: () => "Cliquez sur l'icône Copier l'adresse",
              3: () =>
                "Dans Crypto Tax Calculator, sélectionnez le réseau correspondant",
              4: () =>
                "Collez l'adresse et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    ercDex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    eth: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons désormais en charge les jetons ERC-1155. Cependant, nous ne pouvons pas importer vos soldes actuels pour ces jetons. Cela peut entraîner des écarts entre le solde importé depuis l'API source et le solde calculé par l'application.\n Actuellement, les jetons ERC-404 ne sont pas pris en charge, mais nous travaillons à leur ajout. Pour l'instant, vous devrez ajouter manuellement ces transactions dans l'application.",
            },
            title: () => "Remarques",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Entrez l'adresse de votre portefeuille Ethereum",
              1: () =>
                "Ajoutez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    ethen: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Sur le côté gauche du menu, développez « Historique des échanges » et cliquez sur ETHEN",
              3: () =>
                "Sous « Types d'opérations », cliquez sur « Transactions + Fonds »",
              4: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              5: () => "Conserver les autres paramètres par défaut",
              6: () => "Cliquez sur « Récupérer l’historique »",
              7: () =>
                "Une fois terminé, vous pouvez exporter le fichier csv par défaut sous « Exporter les transactions » et « Exporter les fonds ».",
              8: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ces fichiers CSV",
            },
          },
        },
      },
    },
    etherc: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () => "Sélectionnez l'échange dans le menu déroulant",
              3: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              4: () => "Conserver les autres paramètres par défaut",
              5: () => "Cliquez sur « Récupérer l’historique »",
              6: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              7: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    etherdelta: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Sur le côté gauche du menu, développez « Historique des échanges » et cliquez sur EtherDelta",
              3: () =>
                "Sous « Types d'opérations », cliquez sur « Transactions + Fonds »",
              4: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              5: () => "Conserver les autres paramètres par défaut",
              6: () => "Cliquez sur « Récupérer l'historique »",
              7: () =>
                "Une fois terminé, vous pouvez exporter le fichier csv par défaut sous « Exporter les transactions » et « Exporter les fonds ».",
              8: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ces fichiers CSV",
            },
          },
        },
      },
    },
    "ethereum-classic": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
          1: {
            items: {
              0: () =>
                "Il se peut que certaines transactions internes soient manquantes pour certains portefeuilles.",
            },
            title: () => "Transactions internes manquantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Ethereum Classic dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    etherscan: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "La meilleure façon d'importer des données depuis EtherScan est d'importer directement l'adresse de votre portefeuille public",
              1: () =>
                "Collez l'adresse de votre portefeuille public Ethereum dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    ethex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    ethfinex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () => "Sélectionnez l'échange dans le menu déroulant",
              3: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              4: () => "Conserver les autres paramètres par défaut",
              5: () => "Cliquez sur « Récupérer l’historique »",
              6: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              7: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    etoro: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "eToro modifie fréquemment le format de fichier CSV des transactions exportées. Si votre fichier n'est pas importé sur la plateforme, il est possible qu'il ait été à nouveau modifié. Dans ce cas, nous vous recommandons d'utiliser l'importation CSV CTC avancée pour vos transactions eToro.",
              1: () =>
                "Étant donné que Crypto Tax Calculator est conçu pour les transactions cryptographiques, nous ne pouvons pas importer de transactions non cryptographiques. Si votre compte eToro inclut de telles transactions, vous devrez les comptabiliser en externe.",
            },
            title: () => "Remarques :",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte eToro. Vous pouvez accéder au relevé directement ici (https://www.etoro.com/documents/accountstatement) ou suivre les étapes ci-dessous.",
              1: () =>
                "Depuis la barre latérale, cliquez sur « Portfolio » puis accédez à « Historique »",
              2: () =>
                "Développez le menu déroulant en haut à droite et cliquez sur « Relevé de compte »",
              3: () =>
                "Définissez la plage horaire de vos données de transaction et cliquez sur « Créer »",
              4: () =>
                "En haut à droite, cliquez sur l'icône « XLS » pour télécharger un fichier « .xlsx »",
              5: () =>
                "Dans le champ « Symbole de devise » de la zone d'importation, faites correspondre exactement la devise à votre en-tête CSV (par exemple, utilisez USD au lieu de $).",
              6: () =>
                "Téléchargez votre fichier « .xlsx » dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    evmos: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Evmos en début de développement",
              1: () => "Données avant le 28/04/2022 non disponibles",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Evmos dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    exmo: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
              1: () =>
                "Nous ne prenons pas en charge les adresses IP spécifiques sur notre plateforme, veuillez vous assurer que l'option « Accès API à partir d'adresses IP de confiance uniquement » est désactivée lors de la génération d'une clé API",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Exmo et accédez à l'onglet Paramètres",
              1: () =>
                "Cliquez sur l'onglet API et assurez-vous que l'option « Accès API à partir d'adresses IP approuvées uniquement » est désactivée.",
              2: () =>
                "Créez une clé API en cliquant sur « Générer une clé API » et saisissez-la ici dans Crypto Tax Calculator en tant que « Clé API » et « Secret » respectivement",
            },
          },
        },
      },
    },
    exodus: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à la section historique de votre portefeuille (en haut à droite de votre portefeuille Exodus)",
              1: () => "Cliquez sur l'option Exporter toutes les transactions",
              2: () => "Téléchargez le fichier sur Crypto Tax Calculator",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille Exodus.",
              1: () =>
                "Sélectionnez la cryptomonnaie que vous souhaitez importer. Par exemple, Bitcoin ou Ethereum.",
              2: () =>
                "Copiez l’adresse du portefeuille public correspondant à cet actif.",
              3: () =>
                "Dans CTC, choisissez le réseau blockchain correspondant dans la liste déroulante.",
              4: () =>
                "Collez l'adresse de votre portefeuille public dans CTC et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    fantom: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons désormais en charge les jetons ERC-1155. Cependant, nous ne pouvons pas importer vos soldes actuels pour ces jetons. Cela peut entraîner des écarts entre le solde importé depuis l'API source et le solde calculé par l'application.",
            },
            title: () => "Jetons ERC-1155",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Fantom dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    "fearless-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille Fearless",
              1: () =>
                "En haut à droite, sélectionnez le réseau que vous souhaitez importer",
              2: () =>
                "Cliquez sur l'icône de copie d'adresse en haut à gauche",
              3: () =>
                "Dans Crypto Tax Calculator, sélectionnez le réseau correspondant",
              4: () =>
                "Collez l'adresse et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    fetchai: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Fetch.AI dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    flare: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
          1: {
            items: {
              0: () =>
                "Il se peut que certaines transactions internes soient manquantes pour certains portefeuilles.",
            },
            title: () => "Transactions internes manquantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Flare dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    ftx: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Histoire du commerce",
              1: () =>
                "Déclarations PnL des contrats à terme et des contrats perpétuels",
              2: () => "Historique des dépôts/retraits",
            },
            title: () =>
              "FTX n'est plus opérationnel. Veuillez utiliser les fichiers CSV FTX existants ou l'option d'importation CSV personnalisée de Crypto Tax Calculator pour importer vos transactions FTX. Les fichiers CSV FTX existants que nous prenons en charge sont :",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Nous importons uniquement les transactions spot pour le fichier CSV « Historique des transactions », pas les transactions à terme et perpétuelles (par exemple XXX-PERP). Utilisez le fichier CSV PnL « Futures & Perpetuals » pour importer ces données.",
              1: () =>
                "Le fichier CSV « Historique des commandes » n'est pas pris en charge. Utilisez plutôt les fichiers CSV « Historique des transactions » et « Futures et perpétuels » PnL.",
            },
            title: () => "Notes de support FTX CSV préexistantes",
          },
        },
      },
      api: {
        step: {
          0: {
            title: () =>
              "FTX n'est plus opérationnel. Veuillez utiliser l'option d'importation CSV personnalisée pour importer vos transactions FTX.",
          },
        },
      },
    },
    ftxus: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX n'est plus opérationnel et il n'est plus possible d'obtenir des données historiques à partir de cette bourse. Les transactions FTX qui ont été précédemment importées seront toujours accessibles sur votre compte. Les nouvelles importations doivent utiliser les CSV FTX existants ou l'option d'importation CSV personnalisée. Les synchronisations API ne sont plus possibles.",
            },
            title: () => "FTX n'est plus opérationnel",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX n'est plus opérationnel. Veuillez utiliser l'option d'importation CSV personnalisée pour importer vos transactions FTX.",
          },
        },
      },
      csv: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX n'est plus opérationnel et il n'est plus possible d'obtenir des données historiques à partir de cette bourse. Les transactions FTX qui ont été précédemment importées seront toujours accessibles sur votre compte. Les nouvelles importations doivent utiliser les CSV FTX existants ou l'option d'importation CSV personnalisée. Les synchronisations API ne sont plus possibles.",
            },
            title: () => "FTX n'est plus opérationnel",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX n'est plus opérationnel. Veuillez utiliser l'option d'importation CSV personnalisée pour importer vos transactions FTX.",
          },
        },
      },
    },
    gate: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Gate.io",
              1: () => "Accéder à la page Clés API",
              2: () =>
                "Le cas échéant, saisissez le mot de passe du fonds et le TOTP",
              3: () => "Cliquez sur le bouton « Afficher les clés API »",
              4: () => "Copiez à la fois la clé API et le secret API",
              5: () => "Activer la lecture seule pour votre clé API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Gate.io",
              1: () => "Accédez à Portefeuilles > Historique des transactions",
              2: () => "Cliquez sur « TOUS » pour les marchés et les pièces",
              3: () =>
                "Assurez-vous que les dates sélectionnées couvrent tous les échanges",
              4: () =>
                "Cliquez sur le bouton « Télécharger » qui apparaît sous la forme d'une flèche pointant vers le bas",
              5: () =>
                "Télécharger un fichier CSV vers le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    gateio: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Il peut y avoir quelques légères différences de solde dues à des problèmes d'arrondi sur les fichiers CSV eux-mêmes",
            },
            title: () => "Formatage CSV",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Gate.io et accédez à https://www.gate.io/myaccount/mypurselog . Vous pouvez également accéder à cette page à partir du menu latéral > Historique des transactions.",
              1: () =>
                "Sélectionnez « Tous » pour la pièce et « Tous » pour le filtre.",
              2: () => "Cliquez sur « Filtre » > Télécharger",
              3: () =>
                "Vous pouvez également cliquer sur « Demander des données par lot » dans le coin supérieur droit.",
              4: () =>
                "Sélectionnez « Tous » pour Coin et soumettez la demande.",
              5: () =>
                "Vous pouvez télécharger 90 jours d'historique de transactions à chaque fois. Si vous en avez plus, répétez les étapes pour différentes périodes.",
              6: () =>
                "Importez le fichier exporté « mypurselog.csv » dans CTC.",
            },
          },
        },
      },
    },
    gemini: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Crypto Tax Calculator doit être le seul service qui utilise la clé API que vous avez fournie. Vous rencontrerez probablement des problèmes d'importation si vous utilisez une clé API partagée avec un autre service.",
              1: () =>
                "Veuillez noter que les transactions avec des montants inférieurs à 10^-12 (de n'importe quelle devise) ne seront pas importées.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Gemini et accédez à Compte > Paramètres (en haut à droite de l'écran)",
              1: () => "Faites défiler jusqu'à l'onglet API sur le côté gauche",
              2: () =>
                "Cliquez sur créer une nouvelle clé API (n'utilisez pas de clé existante)",
              3: () =>
                "Sélectionnez la portée : « Principale » et cliquez sur « Créer une clé API »",
              4: () =>
                "Cochez la case « Auditeur » dans les paramètres de la clé API. Ne faites RIEN d'autre dans les paramètres de l'API.",
              5: () => "Copiez la clé API et le secret",
              6: () => "Cochez « Oui » dans la case copier-coller",
              7: () => "Cliquez sur confirmer",
              8: () =>
                "Ajoutez la clé API et le secret copiés dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                "Cliquez sur « Se connecter à Gemini » pour être redirigé vers Gemini Exchange",
              1: () => "Connectez-vous à votre compte Gemini.",
              2: () => "Fournissez le code 2FA si nécessaire",
              3: () =>
                "Sélectionnez le compte auquel vous souhaitez vous connecter puis cliquez sur « Continuer »",
              4: () => "Cliquez sur le bouton « Autoriser »",
            },
          },
        },
      },
    },
    "giddy-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez votre application mobile Giddy Wallet et accédez à la section « Portefeuille » (coin inférieur gauche).",
              1: () => "Cliquez sur l'icône QR en haut à gauche.",
              2: () =>
                "Copiez l'adresse du portefeuille public commençant par « 0x... »",
              3: () =>
                "Collez l'adresse et cliquez sur « Importer le portefeuille »",
            },
          },
        },
      },
    },
    graviex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Accédez à la page d'historique de votre compte",
              1: () =>
                "Cliquez sur l'historique des transactions et exportez les données",
              2: () =>
                "Télécharger sur le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    grs: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
              1: () =>
                "Nous prenons également en charge les adresses xpub, ypub et zpub, mais xpub est préféré.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Groestlcoin dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    hedera: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Entrez l'adresse de votre portefeuille Hedera",
              1: () =>
                "Ajoutez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    hitbtc: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous ne prenons actuellement pas en charge les frais d'intérêt sur marge ou les paiements de financement à terme pour l'intégration de l'API, car HitBTC ne propose pas de point de terminaison API pour obtenir ces informations. Nous travaillons avec HitBTC pour résoudre ce problème.",
              1: () =>
                "HitBTC ne renvoie pas l'historique des transactions au comptant pour les pièces retirées de la liste telles que VERI. Cela peut entraîner une non-concordance des soldes si vous avez effectué des transactions historiques sur ces paires.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte HitBTC et accédez à la page Clés API HitBTC - Sous l'onglet Comptes (https://hitbtc.com/settings/api-keys)",
              1: () =>
                "Cliquez sur le bouton « Nouvelle clé API » et saisissez votre code d'authentification à deux facteurs",
              2: () =>
                "Cochez les deux cases : « Carnet de commandes, Historique, Solde au comptant » et « Informations de paiement »",
              3: () =>
                "Copiez la clé API et le secret et saisissez-les dans Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous ne prenons actuellement pas en charge les paiements de financement à terme pour l'intégration CSV. Nous travaillons à résoudre ce problème.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte HitBTC et accédez à la page des rapports HitBTC (https://hitbtc.com/reports/trades)",
              1: () =>
                "Pour les transactions du portefeuille : sélectionnez « Historique des transactions » sous « Portefeuille »",
              2: () =>
                "Pour les transactions au comptant : sélectionnez « Mes transactions » sous « Spot »",
              3: () =>
                "Pour les transactions sur marge : sélectionnez « Mes transactions » et « Intérêts » sous « Marge »",
              4: () =>
                "Cliquez sur Exporter au format .csv et téléchargez le fichier CSV",
              5: () =>
                "Téléchargez votre/vos fichier(s) CSV ici sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    hodlnaut: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Pour des étapes plus détaillées avec des captures d'écran, consultez la page d'assistance de Hodlnaut à l'adresse : https://www.hodlnaut.com/crypto-guides/hodlnaut-revamps-csv-format",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Une fois connecté à Hodlnaut, accédez à « Mon compte » dans la barre de menu supérieure",
              1: () =>
                "Faites défiler jusqu'à « Transactions » et cliquez sur le bouton Exporter dans le coin supérieur droit",
              2: () => "Téléchargez le rapport CSV sur votre appareil",
              3: () =>
                "Téléchargez le fichier CSV dans Crypto Tax Calculator à l'aide du bouton d'importation.",
            },
          },
        },
      },
    },
    honeyswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous catégorisons actuellement automatiquement les swaps et ajoutons/supprimons des liquidités sur Honeyswap",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trouvez l'adresse du portefeuille Gnosis Chain que vous avez utilisé pour trader via Honeyswap",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    hotbit: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Étant donné que l'option d'exportation de téléchargement de l'historique de Hotbit ne vous permet d'exporter que 30 jours de transactions à la fois, vous devrez contacter leur support et demander à obtenir un enregistrement complet de votre historique de transactions.",
              1: () =>
                "Le support Hotbit doit vous envoyer un fichier .xlsx avec les en-têtes suivants : « time », « user_id », « market », « side », « role », « price », « amount », « deal », « fee », « platform », « stock », « deal_stock ». Vérifiez que le fichier reçu contient les en-têtes ci-dessus, ne modifiez pas et ne réenregistrez pas le fichier au cours du processus",
              2: () =>
                "Téléchargez le fichier .xlsx ici sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    huobi: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Huobi",
              1: () => "Accéder à la page de gestion des API",
              2: () => "Entrez une note de votre choix",
              3: () => "Cliquez sur le bouton « Créer »",
              4: () => "Copiez à la fois la clé d'accès et la clé privée",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vous devrez contacter le support et leur demander de vous envoyer par e-mail les fichiers CSV « Historique des transactions », « Historique des retraits » et « Historique des dépôts ».",
              1: () =>
                "Ces derniers peuvent prendre jusqu'à 5 jours pour être envoyés depuis Huobi",
              2: () =>
                "Une fois que vous avez reçu ces fichiers, vous pouvez les télécharger ici sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    hyperliquid: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous ne sommes actuellement pas en mesure d'importer automatiquement les transactions liées aux récompenses de parrainage. Cela peut entraîner des écarts de solde mineurs. Pour garantir l'exactitude de vos enregistrements, nous vous recommandons d'importer manuellement ces transactions, le cas échéant. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () => "Récompenses de parrainage",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Entrez l'adresse de votre portefeuille Hyper",
              1: () =>
                "Ajoutez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    ice3x: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à ice3x",
              1: () =>
                "Accédez à compte > historique > commandes https://ice3x.com/account/history",
              2: () => "Cliquez sur le bouton « Exporter vers Excel »",
              3: () =>
                "Télécharger sur le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    idex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à IDEX http://idex.market",
              1: () => "Accédez à Profil > Adresse du portefeuille",
              2: () => "Copiez l'adresse de votre portefeuille IDEX",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à IDEX",
              1: () =>
                "Accédez à Commandes > Historique des transactions https://idex.market/trades",
              2: () =>
                "Cliquez sur « Télécharger l'historique des transactions »",
              3: () =>
                "Télécharger un fichier CSV vers le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    idt: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    immutable: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Immutable X dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
              1: () =>
                "Veuillez noter : en raison du manque de fiabilité des données d'Immutable X, certains portefeuilles peuvent ne pas parvenir à importer les transactions.",
              2: () =>
                "Jusqu'à ce que nous puissions sécuriser une source de données fiable, vous devrez importer manuellement ces données à l'aide de notre méthode CSV personnalisée.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    independentreserve: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte de la Réserve indépendante",
              1: () =>
                "Dans la barre de navigation supérieure, accédez à la section « Paramètres » (https://app.independentreserve.com/settings)",
              2: () => "Cliquez sur l'onglet « Clés API »",
              3: () =>
                "Cliquez sur le bouton « générer » pour créer une nouvelle clé",
              4: () =>
                "Sélectionnez l'option « Lecture seule (recommandé) » et ajoutez la description de la clé API comme « Calculateur de taxes sur les crypto-monnaies »",
              5: () =>
                "Entrez votre mot de passe et cliquez sur le bouton « enregistrer »",
              6: () =>
                "Copiez la « clé API » et le « secret API » ici dans le calculateur de taxes cryptographiques",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/4-HxdE3BdmU",
            title: () => "Comment importer votre API de réserve indépendante",
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte de la Réserve indépendante",
              1: () =>
                "Dans la barre de navigation supérieure, accédez au menu déroulant « IMPÔTS ET RAPPORTS » et sélectionnez « Rapports »",
            },
          },
          1: {
            items: {
              0: () =>
                "Sous les rapports, cliquez sur le bouton « Rapport sur l'historique des commandes »",
              1: () =>
                "Dans la fenêtre contextuelle, sélectionnez la plage de dates qui couvre l'historique complet de vos commandes pour le compte",
              2: () =>
                "Sélectionnez l'option « Télécharger l'historique des commandes au format CSV », puis cliquez sur « Télécharger » pour télécharger votre historique de commandes au format CSV",
              3: () =>
                "Téléchargez ce fichier CSV « OrderStatement » ici dans Crypto Tax Calculator",
            },
            title: () => "Pour l'historique des commandes :",
          },
          2: {
            items: {
              0: () =>
                "Sous les rapports, cliquez sur le bouton « Rapport sur l'historique des transactions »",
              1: () =>
                "Dans la fenêtre contextuelle, sélectionnez la plage de dates qui couvre l'historique complet de vos commandes pour le compte",
              2: () =>
                "Sélectionnez l'option « Télécharger l'historique des transactions au format CSV », puis cliquez sur « Télécharger » pour télécharger votre historique des transactions au format CSV",
              3: () =>
                "Téléchargez ce fichier CSV « TransactionHistory » ici dans Crypto Tax Calculator",
            },
            title: () => "Pour les dépôts/retraits :",
          },
        },
        video: {
          0: {
            items: {
              0: () =>
                "Nous n'extraireons pas vos « transactions » du fichier CSV « TransactionHistory » mais plutôt du fichier CSV « OrderStatement » car cela est plus précis.",
              1: () =>
                "Actuellement, les seuls formats de date et d'heure que nous prenons en charge sont :",
              2: () => "« JJ/MM/AAAA HH:mm » (par exemple, 12/02/2020 13:09)",
              3: () =>
                "« JJ MMM AAAA HH:mm:ss » (par exemple, 12 févr. 2020 13:09:22)",
            },
            link: () => "https://youtu.be/Qaz6XSJ-jck",
            title: () =>
              "Comment importer vos fichiers CSV de la Réserve indépendante",
          },
        },
      },
    },
    injective: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Injective dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    iotex: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "IoTeX est en phase de développement précoce",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille IoTeX dans Crypto Tax Calculator, indiquez un pseudo facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    "jaxx-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Jaxx Liberty",
              1: () =>
                "Sélectionnez le menu à trois lignes (coin supérieur droit)",
            },
          },
          1: {
            items: {
              0: () => "Sélectionnez Afficher la phrase de sauvegarde",
              1: () =>
                "Cochez la case C'est sûr et cliquez sur Afficher la phrase de sauvegarde",
              2: () => "Accédez à https://iancoleman.io/bip39",
              3: () =>
                "Collez la phrase de sauvegarde Jaxx dans le champ mnémonique BIP39",
              4: () => "Sélectionner une pièce",
              5: () => "Sélectionnez l'onglet BIP44",
              6: () =>
                "Tapez « 0 » dans Externe/Interne pour obtenir xPub pour les adresses de réception",
              7: () => "Copier la clé publique étendue BIP32",
              8: () =>
                "Tapez « 1 » dans Externe/Interne pour obtenir xPub pour modifier les adresses",
              9: () => "Copier la clé publique étendue BIP32",
              10: () =>
                "Ajoutez l'adresse xPub dans CTC et cliquez sur synchroniser",
            },
            title: () =>
              "Pour les portefeuilles Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin et Zcash",
          },
          2: {
            items: {
              0: () => "Sélectionner Afficher les clés privées",
              1: () =>
                "Cochez la case « C'est sûr » et cliquez sur Afficher les clés privées",
              2: () =>
                "Sélectionnez le portefeuille de pièces approprié (peut être répété pour plusieurs portefeuilles)",
              3: () =>
                "Copier les adresses publiques. Ne pas copier les clés privées",
              4: () =>
                "Trouvez les adresses pertinentes pour chaque transaction",
              5: () => "Copier chaque adresse",
              6: () => "Collez dans CTC et cliquez sur synchroniser",
            },
            title: () => "Pour toutes les autres pièces",
          },
        },
      },
    },
    juno: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Juno en début de développement",
              1: () =>
                "Étiquettes de transferts/jalonnement uniquement (les autres apparaissent comme inconnues)",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Juno dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    kaspa: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Enter your Kaspa wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    kava: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Nous rencontrons actuellement des problèmes lors de la collecte de jetons ERC-1155 et de transactions non EVM, et nous travaillons sur une solution. Si entre-temps vous rencontrez ce problème, essayez plutôt notre processus de téléchargement manuel de fichiers CSV. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Jetons ERC-1155 et transactions non EVM",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Kava EVM dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    kraken: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "L'API de Kraken a une limite de débit stricte d'environ 50 transactions toutes les 6 secondes, ce qui peut ralentir la synchronisation d'un grand nombre de transactions. Pour plus de détails, visitez leur [page de limite de débit d'API](https://www.kraken.com/features/api#api-call-rate-limit).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Kraken et accédez à Paramètres > Sécurité > API. (https://pro.kraken.com/app/settings/api)",
              1: () => "Cliquez sur « Créer une clé API »",
              2: () =>
                "Nommez votre clé API et sélectionnez les autorisations : « Requête », « Requête sur les ordres et transactions clôturés » et « Requête sur les écritures du grand livre »",
              3: () =>
                "Laissez toutes les autres autorisations et paramètres tels quels et cliquez sur « Générer la clé »",
              4: () =>
                "Copiez la « Clé API » et la « Clé privée » dans Crypto Tax Calculator en tant que « Clé API » et « Secret ».",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/2WAYzAyPOKQ",
            title: () => "Comment importer votre API Kraken",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actuellement, les seuls formats de date que nous prenons en charge pour Kraken CSV sont MM/JJ/AAAA, AAAA-MM-JJ, JJ/MM/AA HH:mm, AAAA-MM-JJ HH:mm:ss.SSS, AAAA-MM-JJ HH:mm:ss et MM/JJ/AAAA HH:mm",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Kraken, accédez à l'onglet « Historique », puis cliquez sur « Exporter ».",
              1: () =>
                "Sélectionnez « Ledger » comme type d'exportation, choisissez votre période de négociation, cochez « Sélectionner tout » et cliquez sur « Soumettre ».",
              2: () =>
                "Attendez quelques secondes, actualisez si nécessaire et téléchargez le fichier CSV dans un fichier .zip.",
              3: () =>
                "Décompressez le fichier et téléchargez le(s) CSV sur Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/X7v6RyNCe7A",
            title: () => "Comment importer vos CSV Kraken",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                "Cliquez sur « Se connecter à Kraken » pour accéder à Kraken Exchange",
              1: () => "Connectez-vous à votre compte Kraken.",
              2: () =>
                "Si votre compte utilise une clé d'accès ou une authentification à deux facteurs, saisissez le code requis. Sinon, ignorez cette étape.",
              3: () => "Cliquez sur le bouton « Autoriser »",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/Hrn695jsmoA",
            title: () => "Comment importer votre compte Kraken via Oauth",
          },
        },
      },
    },
    kucoin: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "L'API de KuCoin fournit uniquement l'historique des transactions de l'année dernière. Utilisez un fichier CSV pour les données plus anciennes.",
              1: () =>
                "Nous prenons actuellement en charge les dépôts, les retraits, les transactions au comptant et sur marge et les données historiques sur les contrats à terme pour KuCoin. Il convient de noter que les soldes peuvent ne pas être corrects car nous n'obtenons pas l'historique des emprunts/prêts sur marge.",
              2: () =>
                "Kucoin a récemment désactivé son point de terminaison de trading historique. Vous devrez ajouter ces données dans Crypto Tax Calculator séparément à l'aide de l'option d'importation CSV CTC en attendant que ce problème soit résolu (https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import)",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à KuCoin et accédez à Gestion des API > Clés API (https://www.kucoin.com/account/api)",
              1: () =>
                "Cliquez sur « Créer une API » et sélectionnez « Trading basé sur l'API ».",
              2: () => "Entrez un nom et une phrase secrète de votre choix.",
              3: () =>
                "Activer uniquement l'autorisation « Général » ; désactiver les autres.",
              4: () =>
                "Définissez la restriction IP sur « Non », cliquez sur « Suivant » et terminez la vérification de sécurité",
              5: () =>
                "Copiez la phrase secrète, la clé API et le secret API et collez-les dans Crypto Tax Calculator",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/PtORZYAm-gc",
            title: () => "Comment importer votre API Kucoin",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () => "Connectez-vous à KuCoin",
              1: () =>
                "Accédez à Ordres > Historique des transactions au comptant > Historique des transactions",
              2: () =>
                "Cliquez sur « Cliquez ici pour voir les transactions de l'ancienne version »",
              3: () => "Cliquez sur « Exporter vers CSV »",
              4: () =>
                "KuCoin ne permet d'exporter que 100 jours à la fois, alors sélectionnez des intervalles de 100 jours à partir de votre historique de trading de tous les temps",
              5: () =>
                "Une fois le fichier CSV téléchargé, accédez à https://www.kucoin.com/account/download",
              6: () => "Cliquez sur l'icône de téléchargement",
            },
            title: () => "Instructions d'importation CSV version 1",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte KuCoin et accédez au centre de commandes. (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1)",
              1: () => "Cliquez sur « Exporter ».",
              2: () =>
                "Sélectionnez le rapport souhaité : Historique des dépôts/retraits, Ordres au comptant, Ordres sur marge ou Ordres à terme.",
              3: () =>
                "Choisissez la plage de dates (jusqu'à 12 mois par exportation).",
              4: () =>
                "Cochez « Inclut les sous-comptes » si nécessaire (uniquement pour les opérations au comptant, sur marge et à terme).",
              5: () =>
                "Sélectionnez « .csv » et cliquez sur « Confirmer » pour télécharger.",
              6: () =>
                "Téléchargez les fichiers pertinents sur CTC : historique des dépôts, enregistrement des retraits, ordres au comptant - ordres exécutés, ordres sur marge - ordres exécutés - croisés et isolés, ordres sur marge - emprunts - croisés et isolés et PnL réalisé.",
            },
          },
          1: {
            items: {
              0: () =>
                "Suivez les étapes du centre d'aide KuCoin (https://www.kucoin.com/support/14047696578841)",
              1: () =>
                "Connectez-vous à votre compte Kucoin et visitez le centre de commande (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1)",
              2: () => "Cliquez sur le bouton « Exporter »",
              3: () =>
                "Cliquez sur le menu déroulant du marché et sélectionnez Historique des dépôts/retraits, Ordres au comptant, Ordres sur marge, Ordres à terme",
              4: () =>
                "Choisissez la plage de dates que vous souhaitez télécharger (seulement 12 mois par exportation)",
              5: () =>
                "Si vous devez inclure des données provenant de sous-comptes, cochez l'option « Inclut les sous-comptes ». (Seuls les rapports Spot, Margin et Futures sont pris en charge pour les sous-comptes).",
              6: () =>
                "Sélectionnez « .csv » puis cliquez sur « confirmer » pour télécharger les fichiers.",
              7: () =>
                "Téléchargez les fichiers nécessaires sur CTC. Tous les fichiers téléchargés ne sont pas utiles pour CTC, les fichiers pertinents incluent : Historique des dépôts, Enregistrement des retraits, Ordres au comptant - Ordres exécutés, Ordres sur marge - Ordres exécutés - Croisés et isolés, Ordres sur marge - Emprunts - Croisés et isolés, et PnL réalisé",
            },
            title: () => "Histoire du commerce",
          },
          2: {
            title: () => "Historique des dépôts et des retraits",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/81gyRIU1HYM",
            title: () => "Comment importer votre Kucoin CSV",
          },
        },
      },
    },
    kujira: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Kujira en début de développement",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Kujira dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    kyber: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () => "Sélectionnez l'échange dans le menu déroulant",
              3: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              4: () => "Conserver les autres paramètres par défaut",
              5: () => "Cliquez sur « Récupérer l'historique »",
              6: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              7: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    kyve_network: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Kyve dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    latoken: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Vous devrez contacter le support LATOKEN pour demander le rapport d'historique des transactions",
              1: () =>
                "Une fois que vous l'avez reçu, vous pouvez l'importer directement dans la plateforme",
            },
          },
        },
      },
    },
    leather_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Cliquez sur « Connecter le portefeuille » et suivez les instructions.",
            },
          },
        },
      },
    },
    ledger_blue: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Connectez votre Ledger à votre appareil",
              1: () => "Connectez-vous à l'application de bureau Ledger Live",
              2: () =>
                "Ouvrez le portefeuille Ledger pour la pièce de votre choix",
              3: () => "Sélectionnez le compte concerné (côté gauche)",
            },
          },
          1: {
            items: {
              0: () => "Cliquez sur l'icône de la clé (en haut à droite)",
              1: () => "Cliquez sur Journaux avancés",
              2: () =>
                "Copiez l'adresse du portefeuille HD préfixée par « xpub » entre les guillemets",
              3: () => "Coller dans CTC",
            },
            title: () =>
              "Pour les portefeuilles Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin et Zcash",
          },
          2: {
            items: {
              0: () =>
                "Afficher la liste des transactions et des adresses dans le portefeuille",
              1: () => "Copier chaque adresse",
              2: () => "Coller dans CTC",
            },
            title: () => "Pour toutes les autres pièces",
          },
        },
      },
    },
    ledgerwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "L'exportation CSV depuis Ledger Live peut comporter des transactions manquantes, ce qui peut entraîner des soldes calculés par Crypto Tax Calculator incorrects. Dans la mesure du possible, nous vous recommandons de synchroniser directement chacun des portefeuilles blockchain sous-jacents à l'aide de l'option « Synchroniser via API ».",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Cliquez sur Comptes dans le panneau de gauche.",
              1: () =>
                "Cliquez sur le bouton ⋯ Options dans le coin supérieur droit.",
              2: () =>
                "Choisissez l’option Exporter l’historique des opérations.",
              3: () =>
                "Sélectionnez les comptes à inclure dans la fenêtre qui apparaît.",
              4: () => "Cliquez sur Enregistrer.",
              5: () => "Enregistrez le fichier dans un dossier de votre choix.",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à l'application de bureau Ledger Live",
              1: () =>
                "Ouvrez le portefeuille Ledger pour la pièce de votre choix",
              2: () => "Sélectionnez le compte concerné (côté gauche)",
            },
          },
          1: {
            items: {
              0: () => "Cliquez sur l'icône de la clé (en haut à droite)",
              1: () => "Cliquez sur Journaux avancés",
              2: () =>
                "Copiez l'adresse du portefeuille HD préfixée par « xpub » entre les guillemets",
              3: () => "Coller dans CTC",
            },
            title: () =>
              "Pour les portefeuilles Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin et Zcash",
          },
          2: {
            items: {
              0: () =>
                "Afficher la liste des transactions et des adresses dans le portefeuille",
              1: () => "Copier chaque adresse",
              2: () => "Coller dans CTC",
            },
            title: () => "Pour toutes les autres pièces",
          },
        },
      },
    },
    ledn: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Assurez-vous que votre rapport couvre toutes les années pendant lesquelles vous avez négocié des crypto-monnaies",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Ledn sur un navigateur Web",
              1: () =>
                "Accédez à la page « Commerce » à partir du menu de navigation sur la gauche",
              2: () =>
                "Cliquez sur « Télécharger CSV » pour télécharger vos données de trading",
              3: () =>
                "N'ouvrez pas et ne réenregistrez pas le fichier avant de l'importer, car cela pourrait modifier le format du fichier CSV",
              4: () =>
                "Téléchargez le fichier que vous venez de télécharger ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    linea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Linea dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    liqui: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à la section fonds/portefeuille dans le coin supérieur droit",
              1: () =>
                "Sélectionnez la plage de dates et exportez par e-mail (vous devrez peut-être effectuer cette opération plusieurs fois pour toutes vos transactions)",
              2: () =>
                "Une fois l'e-mail arrivé, téléchargez le fichier reçu sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    liquid: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons en charge les retraits Spot, Margin, Infinity, Perpetuals et Cryptocurrency.",
              1: () =>
                "L'API Liquid ne fournit pas d'informations sur les dépôts de crypto-monnaie. Vous devrez les ajouter manuellement si vous souhaitez les suivre.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Liquid et ouvrez le menu Liquid en cliquant sur votre icône d'utilisateur en haut à droite",
              1: () =>
                "Accédez à Jetons API > Clés API https://app.liquid.com/settings/api-tokens",
              2: () => "Cliquez sur le bouton « Créer un jeton API »",
              3: () =>
                "Sélectionnez les autorisations en lecture seule suivantes : « Comptes », « Exécutions », « Ordres », « Positions », « Comptes de trading » et « Retraits »",
              4: () =>
                "Même si les instructions semblent indiquer que l'adresse IP est obligatoire, la clé API peut être générée sans en avoir besoin. Laissez ce champ vide.",
              5: () => "Cliquez sur le bouton « Créer un jeton »",
              6: () =>
                "Copiez l'ID du jeton (clé API) et le secret du jeton (secret API) et saisissez-les ici dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Liquid",
              1: () =>
                "Accédez à Portefeuilles > Transactions > Faites défiler jusqu'au rapport de présentation",
              2: () =>
                "Assurez-vous que « Sélectionner le fuseau horaire » est « UTC+00:00 » et assurez-vous que les dates sélectionnées couvrent toutes les transactions",
              3: () =>
                "Cliquez sur le bouton « Envoyer le rapport par e-mail »",
              4: () =>
                "Une fois que vous avez reçu l'e-mail de Liquid, téléchargez le fichier CSV et chargez-le ici sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    localbitcoins: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte LocalBitcoins",
              1: () =>
                "Dans le coin supérieur droit de la barre de navigation, cliquez sur l'icône de profil et cliquez sur « Paramètres » dans le menu déroulant pour accéder à vos paramètres (https://localbitcoins.com/settings)",
              2: () =>
                "Cliquez sur l'onglet « Sécurité », puis sous « Données personnelles », cliquez sur « Afficher »",
              3: () =>
                "Cliquez sur le bouton bleu « Collecter des données personnelles » pour commencer la génération de votre historique de trading complet",
              4: () =>
                "Une fois le téléchargement prêt, cliquez sur le bouton bleu « Télécharger les données personnelles » pour télécharger vos données dans un fichier .zip",
              5: () =>
                "Extrayez le contenu du fichier .zip et isolez les deux fichiers appelés « contacts.csv » et « transaction_history.csv »",
              6: () =>
                "Enfin, importez ces deux fichiers ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    lros: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Living Room of Satoshi (LROS)",
              1: () =>
                "Dans la barre de navigation supérieure, cliquez sur « Mon compte » pour accéder à la page des factures (https://www.livingroomofsatoshi.com/bills)",
              2: () =>
                "Téléchargez le fichier CSV couvrant l'intégralité de votre historique de paiement",
              3: () =>
                "Téléchargez ce CSV ici dans le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    ltc: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez la clé publique du portefeuille Ltub pour votre portefeuille Litecoin. Pour la trouver, (par exemple) accédez à Ledger Live et saisissez votre compte Litecoin. Cliquez sur l'icône en forme de clé. Développez « Journaux avancés » sous « Modifier le compte ». Copiez l'adresse qui commence par « Ltub ».",
              1: () =>
                "Une fois trouvée, copiez cette clé publique et accédez à un convertisseur Xpub tel que https://jlopp.github.io/xpub-converter/ pour convertir votre adresse « Ltub » en adresse « Xpub ».",
              2: () =>
                "Copiez et collez l'adresse « Xpub » convertie dans le champ d'importation du Crypto Tax Calculator (à gauche) et cliquez sur « Ajouter un portefeuille » pour synchroniser vos transactions.",
            },
          },
        },
      },
    },
    luno: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Luno (https://www.luno.com/en/exchange)",
              1: () =>
                "Dans le coin supérieur droit, cliquez sur Profil et accédez à Sécurité - > Clés API",
              2: () =>
                "Créez la clé API et assurez-vous que l'autorisation de la clé est « Accès en lecture seule »",
              3: () =>
                "Assurez-vous d'autoriser la clé API en suivant le lien qui vous a été envoyé par SMS ou par e-mail avant de l'utiliser",
              4: () =>
                "Saisissez ici la « clé API » et le « secret API » dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Luno (https://www.luno.com/en/exchange)",
              1: () =>
                "Téléchargez l'export CSV de vos transactions en allant dans vos Portefeuilles - > Portefeuille BTC (par exemple) - > Transactions - > Télécharger CSV",
              2: () =>
                "Répétez cette opération pour chacun de vos portefeuilles sur Luno",
              3: () =>
                "Vous pouvez désormais télécharger les fichiers CSV dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    lykke: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Lykke et dans la barre de navigation en haut de l'écran, cliquez sur « FONDS »",
              1: () =>
                "Une fois sur la page Fonds, cliquez sur le bouton « Historique »",
              2: () =>
                "Assurez-vous d'avoir sélectionné « Tous » pour pouvoir visualiser les dépôts, les retraits et les transactions",
              3: () =>
                "Cliquez sur le bouton « EXPORTER AU FORMAT CSV » pour télécharger votre fichier CSV",
              4: () =>
                "Téléchargez le fichier CSV ici pour le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    "manta-pacific": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
          1: {
            items: {
              0: () =>
                "Il se peut que certaines transactions internes soient manquantes pour certains portefeuilles.",
            },
            title: () => "Transactions internes manquantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Manta Pacific dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    mantle: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Mantle (commençant par 0x) dans Crypto Tax Calculator, indiquez un pseudo facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    mercatox: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Mercatox",
              1: () =>
                "Accédez à Portefeuille > Historique des transactions https://mercatox.com/wallet/transactions",
              2: () =>
                "Cliquez sur le bouton « Télécharger CSV » avant de télécharger sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    metamask: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Cliquez sur « Connecter le portefeuille » et suivez les instructions.",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille MetaMask",
              1: () =>
                "Pour chaque compte associé à MetaMask, sélectionnez le compte et passez la souris sur le nom du compte",
              2: () =>
                "Copiez l'adresse du portefeuille public commençant par « 0x... »",
              3: () =>
                "Collez l'adresse du portefeuille public copiée dans CTC et cliquez sur « Importer le portefeuille »",
            },
          },
        },
      },
    },
    metis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Metis dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    mode: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Copiez l'adresse de votre portefeuille public Mode commençant par « 0x... »",
              1: () => "Collez l'adresse de votre portefeuille public dans CTC",
              2: () =>
                "Indiquez un surnom et cliquez sur « Importer un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille en mode supplémentaire »",
            },
          },
        },
      },
    },
    moonbeam: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
          1: {
            items: {
              0: () =>
                "Actuellement, nous ne sommes pas en mesure de récupérer vos récompenses Moonbeam. Nous travaillons activement à une solution à ce problème. En attendant, vous pouvez ajouter vos récompenses via notre processus d'importation CSV manuel",
            },
            title: () => "Récompenses manquantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Moonbeam dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    moonriver: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Moonriver dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
      },
    },
    mudrex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez l'application mobile Mudrex et connectez-vous à votre compte.",
              1: () =>
                "Appuyez sur l’icône de votre profil dans le coin supérieur gauche (elle affiche vos initiales ou un emoji).",
              2: () =>
                "Faites défiler la section profil vers le bas et sélectionnez « Relevé de transaction » sous Rapports et relevés.",
              3: () => "Choisissez l'exercice financier",
              4: () =>
                "Appuyez sur « Demander un relevé » et vous recevrez un e-mail avec le relevé",
              5: () => "Téléchargez le fichier sur Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    multichain: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Ouvrez votre portefeuille " + d.exchangeDisplayName + " .",
              1: () =>
                "Sélectionnez la cryptomonnaie que vous souhaitez importer. Par exemple, Bitcoin ou Ethereum.",
              2: () =>
                "Copiez l'adresse du portefeuille public correspondant à cet actif. Pour la trouver, vous devrez peut-être cliquer sur « Recevoir ».",
              3: () =>
                "Dans CTC, choisissez le réseau blockchain correspondant dans la liste déroulante.",
              4: () =>
                "Collez l'adresse de votre portefeuille public dans CTC et cliquez sur « Importer le portefeuille ».",
            },
          },
        },
      },
    },
    mxc: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "L'API MEXC ne donne actuellement accès qu'à l'historique des transactions des 30 derniers jours. Pour importer l'intégralité de votre historique de transactions, utilisez la méthode d'importation MEXC « télécharger le fichier »",
            },
            title: () => "Limitations de l'API MEXC.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Les clés API générées par MEXC ne sont valables que pendant 90 jours. Une nouvelle clé devra être créée et ajoutée après ce délai.",
              1: () =>
                "L'API MEXC ne donne PAS accès aux dépôts/retraits/airdrops, etc., ceux-ci devront être ajoutés manuellement.",
              2: () =>
                "Nous ne prenons actuellement PAS en charge le trading de produits dérivés ou de marge via MEXC.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte MEXC",
              1: () =>
                "En haut à droite de l'écran, passez la souris sur l'image du compte et sélectionnez « Gestion des API » dans la liste déroulante (https://www.mexc.com/user/openapi)",
              2: () =>
                "Sous la rubrique « Spot », sélectionnez « Compte : Afficher les détails du compte » et « Échange : Afficher les détails de la commande »",
              3: () =>
                "Dans les notes (obligatoire), indiquez un surnom pour la clé API, tel que « Crypto Tax Calculator »",
              4: () =>
                "Cochez la case « J'ai lu et j'accepte les rappels de risques pour les utilisateurs de l'API » et cliquez sur le bouton « Créer »",
              5: () =>
                "Copiez la « clé d'accès » et la « clé secrète » et saisissez-les toutes les deux dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte MEXC. Nous avons besoin de quatre fichiers différents en fonction de votre historique de trading",
              1: () =>
                "Pour les données de trading à terme : dans la barre de menu supérieure, cliquez sur « Ordres » et sélectionnez « Ordres à terme »",
              2: () =>
                "Sélectionnez « Historique des commandes et des transactions » et cliquez sur le bouton « Exporter l'historique des commandes » (https://futures.mexc.com/orders/contract?type=historic)",
              3: () =>
                "Dans la fenêtre contextuelle, sélectionnez la plage horaire que vous souhaitez télécharger, sélectionnez « EXCEL » pour le format d'exportation et appuyez sur le bouton « Exporter » pour exporter vos enregistrements de transaction",
              4: () =>
                "Pour les données de trading au comptant : dans la barre de menu supérieure, cliquez sur « Ordres » et sélectionnez « Ordres au comptant »",
              5: () =>
                "Sélectionnez « Historique des transactions » et cliquez sur le bouton « Exporter » (https://www.mexc.com/orders/spot?tab=deal)",
              6: () =>
                "Dans la fenêtre contextuelle, sélectionnez la plage horaire que vous souhaitez télécharger et appuyez sur le bouton « Générer » pour exporter vos enregistrements de transaction",
              7: () =>
                "Pour les données sur l'historique des dépôts : dans la barre de menu supérieure, cliquez sur « Portefeuilles » et sélectionnez « Historique de financement »",
              8: () =>
                "Sélectionnez « Dépôt » et cliquez sur le bouton « Exporter » (https://www.mexc.com/assets/record)",
              9: () =>
                "Dans la fenêtre contextuelle, sélectionnez la plage horaire que vous souhaitez télécharger, décochez « Crypter le fichier » et appuyez sur le bouton « Générer » pour exporter vos enregistrements de dépôt",
              10: () =>
                "Pour les données d'historique de retrait : dans la barre de menu supérieure, cliquez sur « Portefeuilles » et sélectionnez « Historique de financement »",
              11: () =>
                "Sélectionnez « Retrait » et cliquez sur le bouton « Exporter » (https://www.mexc.com/assets/record)",
              12: () =>
                "Dans la fenêtre contextuelle, sélectionnez la plage horaire que vous souhaitez télécharger, décochez « Crypter le fichier » et appuyez sur le bouton « Générer » pour exporter vos enregistrements de retrait.",
              13: () =>
                "Téléchargez tous les fichiers téléchargés ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    myEtherWallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Pour chaque compte associé à MEW :",
              1: () =>
                "Recherchez l'adresse publique de la blockchain Ethereum du compte et copiez cette adresse dans le presse-papiers. Astuce : si vous utilisez l'application mobile MEW, vous pouvez trouver ces informations sous « Gérer les comptes » sur l'application mobile MEW.",
              2: () =>
                "Collez l'adresse du portefeuille Ethereum copiée dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    myTonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez saisir votre adresse DNS rebondissante, non rebondissante, TON ou au format brut, et nous la traduirons au format brut pour vous.",
            },
            title: () => "Types d'adresses",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez et copiez l'adresse de votre portefeuille TON à partir de l'extension MyTonWallet",
              1: () =>
                "Entrez l'adresse de votre portefeuille dans le calculateur de taxes sur les crypto-monnaies",
              2: () =>
                "Indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    ndax: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à NDAX",
              1: () =>
                "Sur le côté gauche, cliquez sur les rapports et exportez votre CSV « Ledger »",
              2: () =>
                "Vous pouvez ensuite télécharger ceci dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    near: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "La prise en charge des transactions NFT sera bientôt disponible",
            },
            title: () => "Toutes les opérations ne sont pas prises en charge",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Near dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    neo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Notre fournisseur de données pour cette blockchain est actuellement en panne. Nous recherchons un nouveau fournisseur. Cependant, nous vous suggérons pour l'instant d'importer vos données à l'aide de notre modèle CSV personnalisé. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () =>
              "Notre intégration Neo n'est actuellement pas disponible.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Veuillez noter : en raison du GAS non réclamé, il peut y avoir une divergence entre les soldes de GAS calculés et déclarés.",
            },
            title: () => "Écarts de solde",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () => "Copiez l'adresse de votre portefeuille public NEO",
              2: () =>
                "Dans CTC, choisissez la blockchain concernée dans la liste déroulante",
              3: () =>
                "Collez l'adresse de votre portefeuille public et cliquez sur « Importer un portefeuille »",
            },
          },
        },
      },
    },
    netcoins: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Notre intégration Netcoins est actuellement en version bêta. Si vous rencontrez des problèmes, veuillez contacter notre support avec votre fichier CSV de Netcoins.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Netcoins",
              1: () =>
                "Sur votre page de soldes, faites défiler jusqu'à la section Transactions",
              2: () =>
                "Cliquez sur le bouton « Exporter » en haut du tableau des transactions pour télécharger une exportation CSV de vos transactions",
              3: () =>
                "Téléchargez ce CSV ici dans le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    neutron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Neutron dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    newton: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Newton sur un navigateur Web",
              1: () =>
                "Accédez au bouton « Historique complet » à côté de l'en-tête « Activité récente ». Cela vous amènera à la page « Historique du compte »",
              2: () =>
                "Cliquez sur le bouton « Exporter l'historique du compte »",
              3: () =>
                "Exportez le « Rapport pour AAAA » où « AAAA » correspond à l'année que vous souhaitez exporter (par exemple 2020). Cela téléchargera un fichier CSV avec un nom au format « historique-compte-AAAA-Prénom-Nom.csv »",
              4: () =>
                "Téléchargez le fichier que vous venez de télécharger ici dans Crypto Tax Calculator",
              5: () =>
                "Assurez-vous de répéter ce processus pour toutes les années pour lesquelles des rapports sont disponibles au téléchargement.",
            },
          },
        },
      },
    },
    nexo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Veuillez noter que les transactions de « vente » correspondant à chaque « achat » devront être ajoutées manuellement, car le côté « vente » de l'échange n'est pas disponible dans les données. Vous pouvez le faire dans la page « Consulter les transactions ».",
              1: () =>
                "Veuillez vous assurer que la date et l'heure sont au format « AAAA-MM-JJ HH:mm:ss » ou « MM/JJ/AAAA HH:mm »",
              2: () =>
                "Remarque : certaines lignes CSV peuvent ne pas être importées, car elles ne représentent pas l'historique des transactions, mais plutôt les enregistrements internes de Nexo. Par exemple, une ligne d'intérêt avec un montant en USD négatif représente un enregistrement d'intérêts à payer, et non une transaction réelle.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Nexo et cliquez sur l'onglet Transactions (https://platform.nexo.io/transactions)",
              1: () =>
                "Sélectionnez la plage de dates qui couvre tout votre historique de transactions",
              2: () =>
                "Conservez les types de transaction et les actifs par défaut sur « Tous »",
              3: () =>
                "Exportez le fichier CSV et importez-le dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    nftbank: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Les fichiers CSV dans le dossier « only_symbol » n'incluent pas vos identifiants NFT, uniquement le symbole",
              1: () =>
                "Ne téléchargez pas les fichiers CSV à partir du dossier « only_symbol » si vous avez déjà téléchargé le fichier CSV correspondant à partir du dossier racine ou vice versa",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte NFTBank sur un navigateur Web",
              1: () =>
                "Accédez à votre portefeuille et cliquez sur « Déclaration fiscale »",
              2: () =>
                "Sélectionnez l'option « Crypto Tax Calculator » et les portefeuilles que vous souhaitez exporter",
              3: () =>
                "Confirmez votre adresse e-mail et cliquez sur « Soumettre »",
              4: () =>
                "Téléchargez le fichier zip contenant les fichiers CSV envoyés à votre adresse e-mail",
              5: () =>
                "Téléchargez ici les fichiers CSV pertinents contenus dans le fichier zip (pas le fichier zip lui-même)",
            },
          },
        },
      },
    },
    nicehash: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "L'ID de l'organisation se trouve sur la page Clés API et ne sera pas fourni avec la clé et le secret",
              1: () =>
                "Les frais miniers peuvent être manquants pour les anciennes transactions, ce qui peut entraîner des inexactitudes dans les soldes calculés. Si vous rencontrez ce problème, vous pouvez à la place effectuer l'importation via la méthode « Télécharger le fichier », qui inclut généralement ces frais",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte NiceHash",
              1: () =>
                "Dans le coin supérieur droit de la barre de navigation, cliquez sur l'icône de votre profil, puis sur le bouton orange « Mes paramètres » pour accéder à la page des paramètres (https://www.nicehash.com/my/settings/)",
              2: () =>
                "Dans le menu de navigation de gauche, cliquez sur « Clés API » (https://www.nicehash.com/my/settings/keys)",
              3: () =>
                "Cliquez sur « Créer une nouvelle clé API » pour ouvrir la boîte de dialogue de création de clé API",
              4: () =>
                "Dans « Autorisations du portefeuille », autorisez l'autorisation « Afficher les soldes, les activités du portefeuille et les adresses de dépôt »",
              5: () =>
                "Dans « Autorisations de la place de marché », autorisez l'autorisation « Afficher les commandes de puissance de hachage »",
              6: () =>
                "Dans « Autorisations d'échange », autorisez l'autorisation « Afficher les ordres d'échange »",
              7: () =>
                "Dans « Autorisations d'exploitation minière », autorisez l'autorisation « Afficher les données et statistiques d'exploitation minière »",
              8: () =>
                "Vous serez invité à fournir une authentification supplémentaire",
              9: () =>
                "Copiez la clé API, le secret et l'ID d'organisation de votre compte, et collez-les ici dans Crypto Tax Calculator en tant que « clé API », « secret » et « UID » respectivement",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Des rapports distincts devront être générés pour chaque cryptomonnaie pour laquelle vous avez une activité historique. Cela peut être fait en suivant les étapes ci-dessus pour chacune des différentes devises.",
              1: () =>
                "Le calculateur de taxes sur les crypto-monnaies prélève tous les frais miniers directement sur le montant obtenu (à partir du minage) au moment de la réception du paiement",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte NiceHash",
              1: () =>
                "Dans le coin supérieur droit de la barre de navigation, cliquez sur l'icône de votre profil, puis sur le bouton orange « Mes paramètres » pour accéder à la page des paramètres (https://www.nicehash.com/my/settings/)",
              2: () =>
                "Dans le menu de navigation de gauche, cliquez sur « Rapports » (https://www.nicehash.com/my/settings/reports)",
              3: () =>
                "Cliquez sur « Nouveau rapport » pour ouvrir la boîte de dialogue de création de rapport",
              4: () =>
                "Vous devrez maintenant sélectionner la cryptomonnaie pour le rapport, ainsi que la devise « FIAT ». Assurez-vous que la devise « FIAT » est dans votre devise locale et la même que celle que vous avez dans Crypto Tax Calculator. Assurez-vous que l'« agrégation temporelle » est « AUCUNE ». Sélectionnez la plage de dates de/à qui inclut toutes vos activités. Assurez-vous que le fuseau horaire est GMT.",
              5: () =>
                "Une fois le formulaire rempli, cliquez sur « Créer » pour générer le rapport. Le traitement du rapport prendra un certain temps. Une fois traité, le statut passera à « prêt »",
              6: () =>
                "Téléchargez le rapport en cliquant sur les trois points sous « Action » et sélectionnez « Télécharger le rapport » dans le menu déroulant. Le rapport sera alors téléchargé sous forme de fichier CSV.",
              7: () =>
                "Vous pouvez maintenant télécharger ce CSV dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    nolus: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Nolus dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    oasisdex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () => "Sélectionnez l'échange dans le menu déroulant",
              3: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              4: () => "Conserver les autres paramètres par défaut",
              5: () => "Cliquez sur « Récupérer l'historique »",
              6: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              7: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    oceanex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Passez la souris sur « fonds » en haut de la page",
              1: () =>
                "Dans le menu déroulant, sélectionnez l'historique des transactions",
              2: () => "Exporter les résultats de la recherche",
              3: () =>
                "Téléchargez tous les fichiers que vous avez téléchargés sur Crypto Tax Calculator. Assurez-vous d'avoir toutes vos transactions",
            },
          },
        },
      },
    },
    okex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Actuellement, les transactions sur marge et à terme ne sont pas prises en charge. Nous travaillons à ajouter la prise en charge de ces transactions.",
              1: () =>
                "OKX permet uniquement l'exportation des données de l'historique des transactions des 3 derniers mois à partir de son API. Si vous avez des transactions plus anciennes que cela, vous devrez également télécharger un fichier CSV avec des transactions plus anciennes.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à OKX",
              1: () =>
                "Accédez au menu API (https://www.okx.com/account/my-api)",
              2: () => "Cliquez sur « Créer une clé API V5 »",
              3: () =>
                "Saisissez un nom et une phrase secrète, laissez le champ d'adresse IP vide et cochez uniquement la case « Lecture » sous les autorisations",
              4: () =>
                "Vérifiez votre compte avec un code e-mail et un code d'authentification 2FA, puis cliquez sur confirmer",
              5: () =>
                "Entrez la clé API, le secret et la phrase de passe que vous avez créés ici dans Crypto Tax Calculator",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/ZOf5ZjUYsEQ",
            title: () => "Comment importer votre API OKX",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Actuellement, nous ne prenons en charge que les fichiers CSV du type de compte « Unifié ».",
              1: () =>
                "Actuellement, les transactions d'options ne sont pas prises en charge. Nous travaillons à ajouter la prise en charge de ces transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à OKX",
              1: () =>
                "Accédez au centre de rapports (https://www.okx.com/balance/report-center/)",
              2: () =>
                "Pour les transactions, sous « Historique des transactions », cliquez sur Télécharger, sélectionnez « Tous » comme instrument et définissez la plage de dates de manière appropriée pour couvrir toutes vos transactions",
              3: () =>
                "Téléchargez le fichier CSV « Historique des transactions » ici dans le calculateur de taxes sur les crypto-monnaies",
              4: () =>
                "Pour les dépôts et les retraits, sous « Financement de la facture », cliquez sur télécharger, sélectionnez « Financement » comme type de compte et définissez la plage de dates de manière appropriée pour couvrir toutes vos transactions",
              5: () =>
                "Téléchargez le fichier CSV « Historique du compte de financement » ici dans le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/HpNmKgPLu3k",
            title: () => "Comment importer vos fichiers CSV OKX",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () => "Click 'Connect to OKX' to open a new tab.",
              1: () => "Log in to your OKX account.",
              2: () => "Click 'Approve' button",
            },
          },
        },
      },
    },
    openMask: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez saisir votre adresse DNS rebondissante, non rebondissante, TON ou au format brut, et nous la traduirons au format brut pour vous.",
            },
            title: () => "Types d'adresses",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez et copiez l'adresse de votre portefeuille TON dans l'extension OpenMask",
              1: () =>
                "Entrez l'adresse de votre portefeuille dans le calculateur de taxes sur les crypto-monnaies",
              2: () =>
                "Indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    opensea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "In OpenSea, navigate to your profile",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the copied public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    optimism: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille que vous avez utilisé pour trader via Optimism (en commençant par 0x)",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    osmosis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Osmosis dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    pancakeSwap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous catégorisons actuellement automatiquement les transactions d'échange et de liquidité sur PancakeSwap",
              1: () =>
                "Si vous avez effectué des opérations d'agriculture, de pool ou d'IFO, vos transactions seront importées, mais vous devrez peut-être les réviser et les reclasser de manière appropriée.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Trouvez l'adresse du portefeuille Binance Smart Chain que vous avez utilisé pour trader via PancakeSwap",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    paradex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    phantom: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille blockchain Solana que vous avez utilisé pour trader via Phantom",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    phemex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "L'API Phemex ne prend en charge que 3 mois de données historiques, nous utilisons donc plutôt le CSV",
              1: () =>
                "Nous ne prenons actuellement en charge que les transactions au comptant, les dépôts/retraits et les CSV PNL fermés",
            },
          },
          1: {
            items: {
              0: () =>
                "Connectez-vous à Phemex et passez la souris sur le menu « Actif » dans le coin supérieur droit",
              1: () =>
                "Dans le menu déroulant, cliquez sur « Historique des spots » puis sur « Historique des commandes » (https://phemex.com/assets/spot-order-history)",
              2: () =>
                "Vous pouvez ensuite cliquer sur « Exporter l'historique des commandes spot » à droite et sélectionner la période pendant laquelle vous avez effectué des transactions.",
              3: () =>
                "Vous ne pouvez sélectionner que 3 mois à la fois, donc si vous avez utilisé la plateforme plus longtemps que cela, vous devrez la télécharger pour chaque mois",
              4: () =>
                "Assurez-vous d'avoir téléchargé l'intégralité de votre historique de commandes, puis téléchargez les fichiers sur Crypto Tax Calculator",
            },
            title: () => "Transactions au comptant :",
          },
          2: {
            items: {
              0: () =>
                "Connectez-vous à Phemex et passez la souris sur le menu « Actif » dans le coin supérieur droit",
              1: () =>
                "Dans le menu déroulant, cliquez sur le bouton « Dépôt »",
              2: () =>
                "Faites défiler la page jusqu'en bas et cliquez sur « Afficher tout » dans le tableau Historique récent qui vous amènera à la page de dépôt (https://phemex.com/assets/details?tab=Dépôt)",
              3: () => "Cliquez sur « Exporter l'historique des dépôts »",
              4: () =>
                "Vous pouvez maintenant cliquer sur l'onglet « Retrait » (https://phemex.com/assets/details?tab=Retrait) et également télécharger ces données",
              5: () =>
                "Encore une fois, vous n'avez la possibilité de télécharger que 3 mois de données à la fois. Veuillez vous assurer d'avoir téléchargé l'historique complet des dépôts/retraits, puis de télécharger tous les fichiers sur Crypto Tax Calculator",
            },
            title: () => "Dépôt/Retraits :",
          },
          3: {
            items: {
              0: () =>
                "Connectez-vous à Phemex et passez la souris sur le menu « Actif » dans le coin supérieur droit",
              1: () =>
                "Dans le menu déroulant, cliquez sur « Historique du contrat » puis sur « Détails PNL » (https://phemex.com/assets/contract-pnl-details)",
              2: () =>
                "Faites défiler la page jusqu'en bas et cliquez sur « Afficher tout » dans le tableau Historique récent qui vous amènera à la page de dépôt (https://phemex.com/assets/details?tab=Dépôt)",
              3: () =>
                "Cliquez sur « PNL fermé » parmi les options disponibles",
              4: () =>
                "Vous pouvez ensuite cliquer sur « Exporter l'historique des PNL fermés » à droite et sélectionner la période pendant laquelle vous avez effectué des transactions.",
              5: () =>
                "Encore une fois, vous n'avez la possibilité de télécharger que 3 mois de données à la fois. Veuillez vous assurer d'avoir téléchargé tout l'historique PNL fermé, puis de télécharger tous les fichiers sur Crypto Tax Calculator",
            },
            title: () => "PNL fermé :",
          },
        },
      },
    },
    pionex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à l'onglet « Historique des transactions » sur le côté gauche",
              1: () =>
                "Sélectionnez la période de l'historique des transactions que vous souhaitez télécharger et cliquez sur Soumettre",
              2: () =>
                "Une fois les fichiers prêts, vous recevrez un e-mail de Pionex une fois que le fichier sera prêt à être téléchargé",
              3: () =>
                "Retournez sur votre compte Pionex > Historique des transactions pour télécharger le fichier",
              4: () =>
                "Décompressez les fichiers téléchargés et importez « trading.csv » et « depositwithdraw.csv » dans CTC",
              5: () =>
                "Si vous avez d'autres fichiers de transaction, veuillez les importer en utilisant notre fichier CSV personnalisé",
              6: () =>
                "Remarque : il existe une limite de 3 exportations par mois",
            },
          },
        },
      },
    },
    polkadot: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
          1: {
            items: {
              0: () =>
                "Actuellement, les transferts inter-chaînes IBC ne sont pas pris en charge",
            },
            title: () => "Transactions manquantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Polkadot (commençant par 1) dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    polkadotjs: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille Polkadot.js",
              1: () => "Cliquez sur l'icône des paramètres en haut à droite",
              2: () =>
                "Sélectionnez « Afficher le format d'adresse pour » et choisissez le réseau souhaité",
              3: () =>
                "Revenez à la vue de vos comptes et cliquez sur l'icône Copier dans le presse-papiers à côté du compte souhaité",
              4: () =>
                "Dans Crypto Tax Calculator, sélectionnez le réseau correspondant",
              5: () =>
                "Collez l'adresse et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    polkagate: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille Polkagate",
              1: () =>
                "Cliquez sur le menu à 3 points à côté du compte que vous souhaitez importer",
              2: () =>
                "Sélectionnez le réseau souhaité dans la liste déroulante « chaîne »",
              3: () =>
                "Revenez à la vue de vos comptes et cliquez sur l'icône Copier dans le presse-papiers à côté du compte souhaité",
              4: () =>
                "Dans Crypto Tax Calculator, sélectionnez le réseau correspondant",
              5: () =>
                "Collez l'adresse et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    poloniex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Veuillez noter qu'à l'heure actuelle, l'API Poloniex ne synchronisera que les transferts effectués à partir du 22 janvier 2020 et les transactions effectuées à partir du 30 juillet 2021 (https://docs.poloniex.com/#authenticated-endpoints-trades).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Poloniex et cliquez sur l'onglet « Paramètres » dans la barre de navigation",
              1: () =>
                "Accédez à la page « Clés API » (https://poloniex.com/apiKeys)",
              2: () => "Cliquez sur le bouton « Créer une nouvelle clé »",
              3: () =>
                "Le cas échéant, saisissez votre code d'authentification à deux facteurs et/ou les instructions par e-mail",
              4: () =>
                "Une fois que vous avez reçu un e-mail de vérification de Poloniex, confirmez votre nouvelle clé API",
              5: () =>
                "Pour votre nouvelle clé API, décochez les cases « Activer le trading » et « Activer les retraits »",
              6: () => "Définissez la restriction d'accès IP sur Illimité",
              7: () => "Copiez à la fois la clé API et le secret API",
              8: () =>
                "Collez la « clé API » et le « secret API » copiés ici dans Crypto Tax Calculator et cliquez sur « Ajouter une API »",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Veuillez importer toutes vos « activités » pertinentes (par exemple, dépôts, retraits, transactions, etc.) pour lesquelles il existe des données afin que nous puissions catégoriser correctement vos transactions.",
              1: () =>
                "À l'heure actuelle, nous ne prenons pas en charge les importations automatiques pour les types d'activité « Emprunt sur marge » ou « Prêt ».",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à Poloniex et cliquez sur l'onglet « Activité » dans la barre de navigation",
              1: () =>
                "Cela devrait vous diriger vers la page d'historique des « Portefeuilles » (https://poloniex.com/activity/wallet)",
              2: () =>
                "En haut à droite de l'écran, sous la barre de navigation, cliquez sur le bouton « Exporter »",
              3: () =>
                "Dans Exporter l'activité, sélectionnez votre « Type d'activité ». Nous prenons actuellement en charge les éléments suivants : « Dépôts », « Retraits », « Transactions », « Distributions »",
              4: () =>
                "Une fois que vous avez sélectionné votre type d'activité, sélectionnez la plage de dates qui couvre tout son historique",
              5: () =>
                "Cliquez sur le bouton « Télécharger ». Vous devriez recevoir un fichier CSV intitulé « [activityType]-[selectedDateRange].csv », où [activityType] correspond au type d'activité sélectionné et [selectedDateRange] à la plage de dates sélectionnée.",
              6: () =>
                "Importez le(s) fichier(s) CSV ici dans Crypto Tax Calculator sans les réenregistrer ni les ouvrir (cela peut modifier le format sur certains ordinateurs)",
            },
          },
        },
      },
    },
    polygon: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
              1: () =>
                "Les soldes peuvent être retardés jusqu'à quelques heures",
            },
          },
          1: {
            items: {
              0: () =>
                "Nous prenons désormais en charge les jetons ERC-1155. Cependant, nous ne pouvons pas importer vos soldes actuels pour ces jetons. Cela peut entraîner des écarts entre le solde importé depuis l'API source et le solde calculé par l'application.",
            },
            title: () => "Jetons ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Polygon (Matic) dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    "polygon-zkevm": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Polygon Hermez (zkEVM) dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    pooltool: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Étant donné que PoolTool ne détient aucun de vos actifs, aucun avoir en cryptomonnaie ne sera directement associé à cette importation. Au lieu de cela, les récompenses de jalonnement sont associées à votre adresse de jalonnement bech32 elle-même",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accédez à (https://pooltool.io)",
              1: () =>
                "En haut à gauche, cliquez sur « Données de récompense pour les impôts »",
              2: () => "Collez votre adresse de récompense de jalonnement",
              3: () =>
                "en bas à gauche sélectionnez : format - générique ; devise - dollar américain ; période - l'année pour laquelle vous souhaitez exporter vos données de jalonnement",
              4: () =>
                "Cliquez sur « Télécharger les données » pour télécharger vos données sous forme de fichier CSV",
              5: () =>
                "Saisissez votre adresse de participation bech32, puis téléchargez ce fichier CSV ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    probit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "La synchronisation API de Probit donne uniquement accès à votre historique de trading. Vous devez importer manuellement vos dépôts et retraits Probit dans Crypto Tax Calculator séparément. Vous pouvez le faire via notre modèle CSV personnalisé avancé : https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Probit",
              1: () =>
                "En haut à droite de la barre de navigation, passez la souris sur « Ma page » et cliquez sur « Gestion des API » dans le menu déroulant (https://www.probit.com/en-us/user-center/api-management/api-credential)",
              2: () =>
                "Assurez-vous que l'onglet « Gestion des API » est mis en surbrillance, saisissez « Calculateur de taxes sur les crypto-monnaies » comme « Étiquette de clé API » et cliquez sur « Créer une nouvelle clé »",
              3: () =>
                "Vous serez invité à fournir une authentification supplémentaire",
              4: () =>
                "Copiez/enregistrez l'« ID client » et la « Clé secrète du client » dans un endroit sûr",
              5: () =>
                " Une fois authentifié, vous DEVEZ modifier les autorisations de la clé API - cliquez sur le bouton « Modifier », puis décochez la case « Trading », puis cliquez sur « Modification terminée ».",
              6: () =>
                "IMPORTANT : assurez-vous que la seule case à cocher cochée est « Lecture (par défaut) »",
              7: () =>
                "Saisissez l'« ID client » comme clé API et la « Clé secrète du client » comme clé secrète ici dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
    },
    protocol: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Ouvrez le portefeuille que vous avez utilisé pour interagir avec " +
                d.exchangeDisplayName,
              1: () => "Copiez l'adresse de votre portefeuille public",
              2: () =>
                "Dans CTC, choisissez la blockchain concernée dans la liste déroulante",
              3: () =>
                "Collez l'adresse de votre portefeuille public et cliquez sur « Importer un portefeuille »",
              4: (d) =>
                "CTC importera toutes les transactions du menu déroulant, y compris celles associées à " +
                d.exchangeDisplayName,
            },
          },
        },
      },
    },
    pulsechain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
              1: () =>
                "L'intégration est actuellement en version bêta et peut donc échouer de temps à autre. Veuillez réessayer après un certain temps si cela se produit.",
            },
          },
          1: {
            items: {
              0: () =>
                "Nous ne prenons pas en charge les transactions ERC-1155 pour le moment.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille PulseChain dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    quantfury: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "À ce stade, vous devrez contacter le support Quantfury et demander le fichier .xlsx de l'historique des transactions.",
              1: () =>
                "Le format doit être : « Nom, Action, Quantité, Prix, Valeur, Date, Position totale ».",
              2: () =>
                "Une fois que vous avez reçu ce format, vous devrez l'ouvrir et l'exporter dans un format CSV.",
              3: () =>
                "Vous devrez également supprimer toutes les transactions non cryptographiques, car nous ne les traitons pas.",
              4: () =>
                "Nous travaillons avec Quantfury pour améliorer le support de cette fonctionnalité.",
            },
          },
        },
      },
    },
    quickswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous catégorisons actuellement automatiquement les swaps et ajoutons/supprimons des liquidités sur QuickSwap",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille Polygon (Matic) que vous avez utilisé pour effectuer des transactions via QuickSwap",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    "rabby-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Cliquez sur l'extension Rabby dans votre navigateur",
              1: () =>
                "Pour chaque compte associé à Rabby, sélectionnez le compte et cliquez sur l'icône Copier dans le presse-papiers",
              2: () =>
                "Collez l'adresse du portefeuille public copiée (pour le réseau spécifié) dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    radarRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    "rainbow-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Cliquez sur l'extension Rainbow Wallet dans votre navigateur",
              1: () =>
                "Pour chaque compte associé à Rainbow Wallet, sélectionnez le compte et cliquez sur l'icône de copie",
              2: () =>
                "Collez l'adresse du portefeuille public copiée dans CTC et cliquez sur « Importer le portefeuille »",
            },
          },
        },
      },
    },
    relaypay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte RelayPay",
              1: () =>
                "Cliquez sur votre nom d'utilisateur dans le coin supérieur droit et sélectionnez « Mon profil » (https://relaypay.io/profile)",
              2: () => "Accédez à l'onglet « Acheter des crypto-monnaies »",
              3: () => "Cliquez sur « Télécharger tout au format CSV »",
              4: () =>
                "Répétez l'étape 3 pour les onglets « Virement bancaire » et « Paiement de facture »",
              5: () =>
                "Vous pouvez ensuite importer les fichiers CSV ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    revolut: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Revolut.",
              1: () => "Sélectionnez « Crypto » dans le menu du bas.",
              2: () => "Choisissez une crypto-monnaie.",
              3: () => "Appuyez sur « Déclaration ».",
              4: () => "Choisissez une période de temps.",
              5: () => "Sélectionnez « Générer ».",
              6: () =>
                "Répétez les étapes 3 à 6 pour chaque crypto-monnaie, car vous ne pouvez pas toutes les générer en même temps. ",
              7: () => "Téléchargez le relevé dans votre application Revolut.",
            },
          },
        },
      },
    },
    ripple: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
              1: () =>
                "Pour XRP, nous ne prenons actuellement en charge que les transactions de « paiement » XRP. Si vous avez d'autres types de transactions (par exemple, des devises différentes), veuillez nous contacter via notre support par chat.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () => "Copiez l'adresse de votre portefeuille XRP public",
              2: () =>
                "Dans CTC, choisissez la blockchain concernée dans la liste déroulante",
              3: () =>
                "Collez l'adresse de votre portefeuille public et cliquez sur « Importer un portefeuille »",
            },
          },
        },
      },
    },
    robinhood: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Les retraits et les dépôts ne sont pas pris en charge via l'API, veuillez utiliser l'option d'importation CSV",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Robinhood. Cliquez sur « Compte » dans le coin supérieur droit, puis sélectionnez « Paramètres ».",
              1: () =>
                "Accédez à la section « Crypto » et recherchez l'option « Trading API ».",
              2: () =>
                "Suivez https://help.cryptotaxcalculator.io/en/articles/10448126-import-robinhood-transactions-into-crypto-tax-calculator-using-api-keys pour générer une paire de clés base64.",
              3: () =>
                "Ajoutez la « clé publique » générée à votre compte Robinhood.",
              4: () =>
                "Sous Autorisations API, activez toutes les autorisations de lecture. Ignorez la définition des adresses IP autorisées ou de la date d'expiration de la clé API.",
              5: () =>
                "Confirmez la création de la clé API. Robinhood affichera la clé API.",
              6: () =>
                "Dans votre compte CTC, saisissez la clé API dans « Clé API » et la « Clé privée » de l'étape 3 dans « Secret ».",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Contactez le support Robinhood en visitant https://robinhood.com/contact",
              1: () =>
                "Cliquez sur Documents et taxes → Historique des transactions → Contactez-nous",
              2: () =>
                "Choisissez comment vous souhaitez être contacté, par email ou par téléphone",
              3: () =>
                "Demandez une copie de vos transactions pour vos achats/ventes et vos envois/réceptions. Il peut s'agir de deux fichiers",
              4: () =>
                "Une fois que vous avez votre/vos fichier(s), convertissez-les de PDF en CSV si nécessaire",
              5: () =>
                "Reformatez le(s) fichier(s) au format CSV avancé de CTC (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
              6: () => "Téléchargez votre/vos fichier(s) CSV ici",
            },
          },
        },
      },
    },
    robinhood_wallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Ceci est destiné au portefeuille non dépositaire Robinhood Web3. Pour importer votre compte Robinhood Exchange, veuillez vous rendre ici : https://app.cryptotaxcalculator.io/imports/new/robinhood",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez l'application de portefeuille Web3 non dépositaire Robinhood",
              1: () =>
                "Depuis l'écran d'accueil, sélectionnez Menu (en haut à gauche) et choisissez Recevoir",
              2: () => "Copiez votre adresse publique",
              3: () =>
                "Choisissez la blockchain concernée dans la liste déroulante",
              4: () => "Collez l'adresse publique et cliquez sur synchroniser",
            },
          },
        },
      },
    },
    ronin: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Visitez https://nftbank.ai/ et connectez-vous",
              1: () =>
                "Cliquez sur l'onglet « Déclaration fiscale » dans le menu de gauche",
              2: () =>
                "Sélectionnez « Obtenir l'historique de vos transactions pour la déclaration fiscale »",
              3: () =>
                "Sélectionnez « Crypto Tax Calculator » et cliquez sur « Continuer »",
              4: () =>
                "Sélectionnez « Devise d'origine – Format du prestataire de services fiscaux »",
              5: () =>
                "Si vous n'avez pas encore importé votre portefeuille Ronin, cliquez sur « Ajouter un portefeuille » et suivez les instructions de NFTbank",
              6: () =>
                "Une fois importé, sélectionnez votre/vos portefeuille(s) Ronin, cliquez sur « Continuer »",
              7: () =>
                "Entrez votre email où vous recevrez un lien de téléchargement",
              8: () =>
                "Le fichier téléchargé doit être un fichier .zip, ouvrez le fichier pour afficher son contenu",
              9: () =>
                "Dans le nouveau dossier, recherchez le fichier ronin.csv et importez-le dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    saga: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Saga dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    scroll: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Scroll dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    sei: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Enter your Sei wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    sentinel: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Sentinel dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    shakepay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Shakepay.",
              1: () => "Accédez à la page principale « Portefeuilles ».",
              2: () =>
                "Cliquez sur le bouton « Télécharger l’historique » à côté du titre Historique des transactions sur la page principale.",
              3: () =>
                "Importez le fichier CSV dans le calculateur de taxes Crypto.",
              4: () =>
                "Consultez https://help.shakepay.com/en/articles/3336094-where-can-i-see-my-full-transaction-history-monthly-statements-on-shakepay pour plus de détails.",
            },
          },
        },
      },
    },
    sharkRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    singularx: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () => "Sélectionnez l'échange dans le menu déroulant",
              3: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              4: () => "Conserver les autres paramètres par défaut",
              5: () => "Cliquez sur « Récupérer l'historique »",
              6: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              7: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    softWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Si vous ne parvenez pas à trouver votre cryptomonnaie ou votre réseau, cela signifie que Crypto Tax Calculator ne peut pas encore synchroniser les transactions pour celui-ci. Veuillez nous contacter via notre chat en direct afin que nous puissions ajouter une assistance !",
              1: () =>
                "Vous pouvez également saisir ces transactions manuellement via l'option d'importation CSV avancée de CTC : https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import ",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Sélectionnez le réseau",
              1: () =>
                "Copiez votre clé publique xpub, ypub ou zpub. (par exemple, clé xpub BTC)",
              2: () =>
                "Collez l'adresse publique et cliquez sur « Importer le portefeuille ».",
              3: () =>
                "Plus de détails (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator)",
            },
            title: () =>
              "Bitcoin, BitcoinCash, Dogecoin, BSV et autres réseaux avec portefeuilles xpub :",
          },
          1: {
            items: {
              0: () => "Sélectionnez le réseau",
              1: () => "Copiez l'adresse de votre portefeuille public.",
              2: () =>
                "Collez votre adresse publique et cliquez sur « Importer un portefeuille ».",
            },
            title: () =>
              "Ethereum (et jetons ETH), Binance Smart Chain, Cardano, Ripple, Gnosis, Polygon et autres réseaux avec une adresse de portefeuille EVM :",
          },
          2: {
            items: {
              0: () => "Sélectionnez le réseau",
              1: () =>
                "Recherchez votre clé publique Ltub (par exemple, dans Ledger Live sous « Journaux avancés »).",
              2: () =>
                "Convertissez l'adresse Ltub en adresse Xpub ici (https://jlopp.github.io/xpub-converter/).",
              3: () =>
                "Collez l'adresse Xpub et cliquez sur « Importer le portefeuille ».",
            },
            title: () => "Litecoin:",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/g8JcDe6zRZA",
            title: () => "Comment importer votre portefeuille Bitcoin Ledger",
          },
        },
      },
    },
    solana: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
            },
          },
          1: {
            items: {
              0: () =>
                "Si vous téléchargez des transactions via l'importation CSV manuelle, veuillez utiliser « solana-deprecated » ou « sol-deprecated » comme blockchain.",
            },
            title: () => "Utilisation de l'importation CSV manuelle",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Solana dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    "solana-v2": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () => "Copiez l'adresse de votre portefeuille public Solana",
              2: () =>
                "Collez l'adresse de votre portefeuille public dans CTC et cliquez sur « Importer le portefeuille »",
            },
          },
        },
      },
    },
    solflare: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trouvez l'adresse du portefeuille blockchain Solana que vous avez utilisé pour trader via SolFlare",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    sollet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Trouvez l'adresse du portefeuille blockchain Solana que vous avez utilisé pour trader via Sollet",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    sonic: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
          1: {
            items: {
              0: () =>
                "Les soldes en direct des tokens sur Sonic ne sont actuellement pas disponibles. Les détails des transactions et les calculs de taxes ne sont pas affectés",
            },
            title: () => "Soldes en direct",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Copiez l'adresse de votre portefeuille public Sonic commençant par « 0x... »",
              1: () => "Collez l'adresse de votre portefeuille public dans CTC",
              2: () =>
                "Indiquez un surnom et cliquez sur « Importer un portefeuille »",
            },
          },
        },
      },
    },
    spiritswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille blockchain Fantom que vous avez utilisé pour trader via SpiritSwap.",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes cryptographiques, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    spookyswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille blockchain Fantom que vous avez utilisé pour trader via SpookySwap.",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes cryptographiques, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    stacks: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Stacks (commençant par SP) dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    staketax: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse de votre portefeuille sur stake.tax et sélectionnez Crypto Tax Calculator dans l'invite de téléchargement",
              1: () =>
                "Téléchargez le fichier CSV ici dans le calculateur de taxes cryptographiques",
            },
          },
        },
      },
    },
    stargaze: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Observation des étoiles au début du développement",
              1: () =>
                "Étiquettes de transferts/jalonnement uniquement (les autres apparaissent comme inconnues)",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Stargaze dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    starknet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez votre portefeuille Starknet (Argent, Braavos, etc.)",
              1: () =>
                "Copiez l'adresse de votre portefeuille public ou l'adresse de votre contrat commençant par « 0x... »",
              2: () =>
                "Collez l'adresse dans Crypto Tax Calculator, ajoutez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    stellar: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () => "Copiez l'adresse de votre portefeuille public Stellar",
              2: () =>
                "Dans CTC, choisissez la blockchain concernée dans la liste déroulante",
              3: () =>
                "Collez l'adresse de votre portefeuille public et cliquez sur « Importer un portefeuille »",
            },
          },
        },
      },
    },
    stex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à STEX, cliquez sur votre email en haut à droite et sélectionnez profil.",
              1: () =>
                "Sur le côté gauche, cliquez sur « Signaler » (https://app.stex.com/en/profile/report)",
              2: () =>
                "Cliquez sur « Historique utilisateur » et cliquez sur le bouton Exporter",
              3: () =>
                "Vous pouvez maintenant télécharger ce CSV dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    stormrake: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte Stormrake",
              1: () => "Téléchargez votre historique de transactions",
              2: () => "Téléchargez le fichier CSV dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "substrate-chains": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Il se peut que nous ayons des frais manquants sur les transactions de pont, car la blockchain ne fournit pas ces informations dans certains cas.",
              1: () =>
                "De plus, il se peut que certaines transactions de transfert de la blockchain soient manquantes. Si vous rencontrez ce problème, veuillez contacter le support.",
            },
            title: () => "Transactions manquantes",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) =>
                "Saisissez l'adresse de votre portefeuille " +
                d.exchangeDisplayName +
                " dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    subwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille SubWallet",
              1: () =>
                "Choisissez le compte que vous souhaitez importer dans la liste déroulante des comptes supérieurs",
              2: () => "Cliquez sur l'icône Copier l'adresse",
              3: () => "Copiez l'adresse du réseau souhaité",
              4: () =>
                "Dans Crypto Tax Calculator, sélectionnez le réseau correspondant",
              5: () =>
                "Collez l'adresse et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    sui: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille Sui",
              1: () =>
                "Pour chaque compte associé à Sui, sélectionnez le compte et passez la souris sur le nom du compte",
              2: () =>
                "Copiez l'adresse du portefeuille public commençant par « 0x... »",
              3: () =>
                "Collez l'adresse du portefeuille public copiée dans CTC et cliquez sur « Importer le portefeuille »",
            },
          },
        },
      },
    },
    swissborg: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Pour des étapes plus détaillées, SwissBorg a élaboré un guide ici : https://help.swissborg.com/hc/fr-fr/articles/360016499698-Relevé-de-compte",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte SwissBorg via l'application SwissBorg",
              1: () =>
                "Accédez à votre « profil » et appuyez sur « Relevé de compte »",
              2: () =>
                "Sélectionnez la plage de dates qui couvre l'intégralité de votre historique de trading",
              3: () =>
                "Sélectionnez la devise de référence comme votre monnaie fiduciaire locale",
              4: () =>
                "Choisissez le format « Excel » et enregistrez le fichier",
              5: () =>
                "Téléchargez ce fichier ici dans le calculateur de taxes sur les crypto-monnaies",
            },
          },
        },
      },
    },
    switcheo: {
      csv: {
        note: {
          0: {
            items: {
              0: () => "Le format de la date doit être AAAA-MM-JJ HH:mm:ss",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Switcheo Exporter (https://benammann.github.io/switcheo-exporter/#/).",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public et exportez le fichier CSV.",
              2: () =>
                "Cliquez sur le bouton « Télécharger CSV » dans le coin supérieur droit.",
              3: () =>
                "Vous pouvez maintenant télécharger ce CSV dans Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    swyftx: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Nous travaillons actuellement avec Swyftx pour prendre en charge leur nouvelle fonctionnalité de jalonnement. Les dépôts et retraits de jalonnement ne seront pas importés pour le moment",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Visitez https://trade.swyftx.com.au/profile/api/",
              1: () => "Cliquez sur « Créer une nouvelle clé ».",
              2: () =>
                "Cliquez sur « Je comprends » après avoir lu l’avertissement.",
              3: () =>
                "Saisissez une étiquette (par exemple, Crypto Tax Calculator), définissez la « Portée » sur « Lecture seule » et saisissez votre mot de passe Swyftx pour confirmer.",
              4: () =>
                "Copiez la « clé API » et le « jeton d'accès », puis collez-les dans Crypto Tax Calculator en tant que « clé API » et « secret ».",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q9QJesWUiMk",
            title: () => "Comment importer votre API Swyftx",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "L'exportation CSV depuis Swyftx peut présenter des incohérences avec les horodatages de changement d'heure. Pour une meilleure précision, utilisez l'importation API si possible.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Visitez https://trade.swyftx.com/profile/transaction-reports",
              1: () => "Sélectionnez la période de transaction",
              2: () => "Cliquez sur « Télécharger.csv ».",
              3: () =>
                "Téléchargez le fichier CSV dans le calculateur de taxes Crypto.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q_WXPgSRQoQ",
            title: () => "Comment importer vos fichiers CSV Swyftx",
          },
        },
      },
    },
    taiko: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille Taiko supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Copiez l'adresse de votre portefeuille public Taiko commençant par « 0x... »",
              1: () => "Collez l'adresse de votre portefeuille public dans CTC",
              2: () =>
                "Indiquez un surnom et cliquez sur « Importer un portefeuille »",
            },
          },
        },
      },
    },
    talisman: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Ouvrez votre portefeuille Talisman",
              1: () => "Cliquez sur le compte que vous souhaitez importer",
              2: () => "Cliquez sur l'icône Copier l'adresse",
              3: () => "Sélectionnez le réseau souhaité",
              4: () =>
                "Dans Crypto Tax Calculator, sélectionnez le réseau correspondant",
              5: () =>
                "Collez l'adresse et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    terra: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Si vous avez importé vos transactions Terra avant le 18 mai 2022, vous risquez de rencontrer des erreurs de tarification dans l'application en raison du changement de nom de LUNA en LUNC. Nous vous recommandons de supprimer et de réimporter votre portefeuille Terra Classic afin de corriger ces problèmes.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Terra dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    terra2: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Terra 2.0 est en phase de développement précoce",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Terra 2.0 dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    theta: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
              1: () =>
                "Nous ne prenons actuellement pas en charge les jetons TNT721 et vous devrez les ajouter manuellement dans la page « Examiner les transactions ».",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Theta dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    thetaboard: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Avant de télécharger le fichier, assurez-vous de saisir l'adresse de votre portefeuille afin que nous sachions où associer ces transactions.",
              1: () =>
                "Nous ne prenons actuellement pas en charge les jetons TNT721 et vous devrez les ajouter manuellement dans la page « Examiner les transactions ».",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Thetaboard (https://thetaboard.io/wallet/explorer).",
              1: () => "Entrez l'adresse de votre portefeuille public.",
              2: () =>
                "Cliquez sur « Exporter l’historique des transactions » (sous Transactions).",
              3: () =>
                "Vous pouvez maintenant télécharger ce CSV dans Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    thetawallet: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Theta dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Avant de télécharger le fichier, assurez-vous de saisir l'adresse de votre portefeuille afin que nous sachions où associer ces transactions.",
              1: () =>
                "Si vous souhaitez des informations plus précises sur les frais, vous devez plutôt utiliser l'importation « Theta » qui lit à partir de la blockchain",
              2: () =>
                "Nous ne prenons actuellement pas en charge les jetons TNT721 et vous devrez les ajouter manuellement dans la page « Examiner les transactions ».",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Accédez au ThetaScan (http://www.thetascan.io/).",
              1: () => "Entrez l'adresse de votre portefeuille public.",
              2: () =>
                "Cliquez sur « Exporter les transactions » (sous votre solde)",
              3: () =>
                "Vous pouvez maintenant télécharger ce CSV dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    thorchain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous ne prenons actuellement en charge que les types de transaction « Envoyer » et « Échanger ».",
            },
            title: () => "Types pris en charge",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille THORChain dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille THORChain »",
            },
          },
        },
      },
    },
    toast: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Pour Ripple, nous ne prenons actuellement en charge que les transactions de « paiement » XRP. Si vous avez d'autres types de transactions (par exemple, des devises différentes), veuillez nous contacter via notre support par chat.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Pour chaque compte associé à votre portefeuille Toast :",
              1: () => "Recherchez l'adresse blockchain publique du compte.",
              2: () =>
                "Saisissez cette adresse dans le calculateur de taxes cryptographiques, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    tokenJar: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    tokenStore: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Sur le côté gauche du menu, développez « Historique des échanges » et cliquez sur TokenStore",
              3: () =>
                "Sous « Types d'opérations », cliquez sur « Transactions + Fonds »",
              4: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              5: () => "Conserver les autres paramètres par défaut",
              6: () => "Cliquez sur « Récupérer l'historique »",
              7: () =>
                "Une fois terminé, vous pouvez exporter le fichier csv par défaut sous « Exporter les transactions » et « Exporter les fonds ».",
              8: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ces fichiers CSV",
            },
          },
        },
      },
    },
    tokenion: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    ton: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez saisir votre adresse DNS rebondissante, non rebondissante, TON ou au format brut, et nous la traduirons au format brut pour vous.",
            },
            title: () => "Types d'adresses",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille TON ou DNS TON dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    tonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez saisir votre adresse DNS rebondissante, non rebondissante, TON ou au format brut, et nous la traduirons au format brut pour vous.",
            },
            title: () => "Types d'adresses",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse de votre portefeuille TON dans l'application Wallet de Telegram. Pour afficher votre adresse, vous devrez peut-être cliquer sur le bouton « Ajouter une crypto-monnaie », puis sur les options « Portefeuille externe », puis sur « Toncoin », et copier l'adresse à partir de là",
              1: () =>
                "Entrez l'adresse de votre portefeuille dans le calculateur de taxes sur les crypto-monnaies",
              2: () =>
                "Indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
              3: () =>
                "Répétez les étapes ci-dessus pour tous les portefeuilles TON Space que vous possédez, en trouvant l'adresse en cliquant sur le TON Space dans votre portefeuille et en cliquant sur le raccourci d'adresse pour le copier dans votre presse-papiers",
            },
          },
        },
      },
    },
    toncoin: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez saisir votre adresse DNS rebondissante, non rebondissante, TON ou au format brut, et nous la traduirons au format brut pour vous.",
            },
            title: () => "Types d'adresses",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille TON ou DNS TON dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    tonhub: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez saisir votre adresse DNS rebondissante, non rebondissante, TON ou au format brut, et nous la traduirons au format brut pour vous.",
            },
            title: () => "Types d'adresses",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez et copiez l'adresse de votre portefeuille TON depuis l'application Tonhub. Pour afficher votre adresse, vous devrez peut-être cliquer sur le bouton « Recevoir » et copier l'adresse à partir de là",
              1: () =>
                "Entrez l'adresse de votre portefeuille dans le calculateur de taxes sur les crypto-monnaies",
              2: () =>
                "Indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    tonkeeper: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez saisir votre adresse DNS rebondissante, non rebondissante, TON ou au format brut, et nous la traduirons au format brut pour vous.",
            },
            title: () => "Types d'adresses",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse de votre portefeuille TON dans l'application Tonkeeper, soit sur Telegram, soit dans l'application mobile ou de bureau. Pour afficher votre adresse, vous devrez peut-être cliquer sur le bouton « recevoir » et copier l'adresse à partir de là",
              1: () =>
                "Entrez l'adresse de votre portefeuille dans le calculateur de taxes sur les crypto-monnaies",
              2: () =>
                "Indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    tonpay: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez saisir votre adresse DNS rebondissante, non rebondissante, TON ou au format brut, et nous la traduirons au format brut pour vous.",
            },
            title: () => "Types d'adresses",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez et copiez l'adresse de votre portefeuille TON depuis l'application Telegram Tonpay. Vous pouvez afficher l'adresse de votre portefeuille en tapant /start et en sélectionnant l'option « Finance », puis « Portefeuille »",
              1: () =>
                "Entrez l'adresse de votre portefeuille dans le calculateur de taxes sur les crypto-monnaies",
              2: () =>
                "Indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    tradeSatoshi: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à TradeSatoshi",
              1: () =>
                "Accédez à l'historique des commandes https://tradesatoshi.com/Orders",
              2: () =>
                "Cliquez sur le bouton « Exporter » avant de télécharger le fichier CSV vers Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "trader-joe": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille Avalanche C-Chain que vous avez utilisé pour trader via Trader Joe.",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes cryptographiques, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    trezor: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez ajouter le symbole boursier pour chaque devise que vous souhaitez télécharger",
              1: () =>
                "Veuillez vous assurer que la date et l'heure sont au format « JJ/MM/AAAA HH:mm:ss », « AAAA-MM-JJ HH:mm:ss » ou « M/D/AAAA h:mm:ss A »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Vous pouvez suivre les étapes ici pour exporter vos données CSV (https://wiki.trezor.io/User_manual:Transaction_details)",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Connectez votre Trezor à votre appareil",
              1: () => "Visitez wallet.trezor.io",
            },
          },
          1: {
            items: {
              0: () =>
                "Sélectionnez le compte concerné et accédez à l’onglet Basique",
              1: () => "Cliquez sur Afficher les XPUB",
              2: () => "Copier l'adresse xPub",
              3: () => "Coller dans CTC",
            },
            title: () =>
              "Pour les portefeuilles Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin et Zcash",
          },
          2: {
            items: {
              0: () =>
                "Sélectionnez Sélectionnez la pièce concernée et affichez les adresses des portefeuilles publics sous Transactions",
              1: () => "Copier chaque adresse publique",
              2: () => "Coller dans CTC",
            },
            title: () => "Pour toutes les autres pièces",
          },
        },
      },
    },
    tron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire ».",
              1: () =>
                "Actuellement, nous ne prenons en charge que les jetons TRX et TRC10 Tron, toutes les autres transactions devront être importées manuellement dans Crypto Tax Calculator.",
              2: () =>
                "L'importation ne concerne que les transferts. Nous ne gérons pas les interactions en chaîne telles que le déploiement de contrats intelligents à ce stade.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () => "Copiez l'adresse de votre portefeuille public Tron",
              2: () =>
                "Dans CTC, choisissez la blockchain concernée dans la liste déroulante",
              3: () =>
                "Collez l'adresse de votre portefeuille public et cliquez sur « Importer un portefeuille »",
            },
          },
        },
      },
    },
    trustwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez l'application mobile Trust Wallet ou l'extension de navigateur",
              1: () =>
                "Si nécessaire, choisissez votre portefeuille dans Paramètres",
              2: () =>
                "Dans l’onglet Portefeuille, choisissez Recevoir et sélectionnez le jeton",
              3: () => "Copier l'adresse publique ",
              4: () =>
                "Collez dans CTC et appuyez sur synchroniser pour importer vos transactions",
            },
          },
        },
      },
    },
    unichain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Nous prenons désormais en charge les jetons ERC-1155. Cependant, nous ne pouvons pas importer vos soldes actuels pour ces jetons. Cela peut entraîner des écarts entre le solde importé depuis l'API source et le solde calculé par l'application.",
            },
            title: () => "Jetons ERC-1155",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Unichain dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    uniswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Recherchez l'adresse du portefeuille blockchain Ethereum que vous avez utilisé pour trader via Uniswap.",
              1: () =>
                "Saisissez cette adresse dans le calculateur de taxes cryptographiques, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille ».",
            },
          },
        },
      },
    },
    uphold: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Voir plus de détails sur les étapes pour les différentes interfaces Uphold ici : https://support.uphold.com/hc/en-us/articles/360046137012-Comment-puis-je-télécharger-mon-historique-de-transaction-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte Uphold et accédez au tableau de bord des portefeuilles",
              1: () =>
                "À gauche, cliquez sur l'icône « Activité » pour accéder à la page d'activité (https://wallet.uphold.com/dashboard/activity)",
              2: () =>
                "Dans le panneau de gauche, en haut à droite, cliquez sur l'icône Document",
              3: () => "Cliquez sur le bouton vert « Générer le rapport »",
              4: () =>
                "Quelques minutes plus tard, vous recevrez un e-mail avec la possibilité de télécharger l'historique de vos transactions au format CSV",
              5: () =>
                "Téléchargez le fichier CSV téléchargé ici sur Crypto Tax Calculator",
            },
          },
        },
      },
    },
    valr: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte VALR",
              1: () =>
                "Téléchargez votre fichier CSV d'historique de transactions depuis VALR",
              2: () =>
                "Téléchargez ce fichier CSV ici dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    vechain: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à « https://explore.vechain.org/download?address=INSERT_ADDRESS » mais remplacez INSERT_ADDRESS par l'adresse de votre portefeuille public",
              1: () =>
                "Sélectionnez la période pendant laquelle vous préférez que votre historique de transactions s'étende",
              2: () => "Vérifiez que vous êtes un humain",
              3: () => "Cliquez sur « Exporter »",
              4: () => "Téléchargez le fichier CSV ici",
            },
          },
        },
      },
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Ouvrez votre portefeuille " + d.exchangeDisplayName,
              1: () => "Copiez l'adresse de votre portefeuille public Vechain",
              2: () =>
                "Dans CTC, choisissez la blockchain concernée dans la liste déroulante",
              3: () =>
                "Collez l'adresse de votre portefeuille public et cliquez sur « Importer un portefeuille »",
            },
          },
        },
      },
    },
    velas: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
          1: {
            items: {
              0: () =>
                "Il se peut que certaines transactions internes soient manquantes pour certains portefeuilles.",
            },
            title: () => "Transactions internes manquantes",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Velas dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    virgocx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Connectez-vous à votre compte VirgoCX ici (https://virgocx.ca/login).",
              1: () =>
                "Cliquez sur « eDocument » dans le coin supérieur droit sous votre nom.",
              2: () =>
                "Dans la barre de navigation de gauche, cliquez sur « Exportation de données ».",
              3: () =>
                "Sélectionnez « Données de transaction » comme type de rapport et « Crypto-monnaie » comme actif.",
              4: () =>
                "Sous « Date », choisissez la plage qui comprend l'historique complet de vos transactions avec VirgoCX. Vous devrez peut-être télécharger plusieurs fichiers pour garantir une couverture complète.",
              5: () => "Procédez à l’exportation des fichiers CSV.",
              6: () =>
                "Effectuez le même processus, mais sélectionnez « Monnaie fiduciaire » comme actif cette fois-ci.",
              7: () =>
                "Répétez toutes les étapes ci-dessus, mais choisissez maintenant « Données commerciales » au lieu de « Données de transaction ».",
              8: () =>
                "Téléchargez les fichiers CSV dans Crypto Tax Calculator ici.",
            },
          },
        },
      },
    },
    wealth99: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte d'échange Wealth99",
              1: () =>
                "Accédez à Paramètres dans le menu de gauche et sélectionnez l'onglet « Sécurité »",
              2: () => "Cliquez sur « Créer une clé API »",
              3: () =>
                "Assurez-vous d'avoir coché « Activer la lecture des informations »",
              4: () =>
                "Copiez la « clé API » et le « secret API », saisissez-les tous les deux dans le calculateur de taxes cryptographiques ici, puis cliquez sur « Synchroniser l'API » pour synchroniser vos données",
            },
          },
        },
      },
    },
    xdai: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
              1: () =>
                "Comme il n'y a pas de données disponibles pour la réception du xDai via le pont xDai lors de la conversion de DAI en XDAI à partir du réseau Ethereum, vous devrez saisir manuellement ces transactions en tant qu'« achats » dans le calculateur de taxes sur les crypto-monnaies.",
              2: () =>
                "Comme il n'y a pas de données disponibles pour la réception de jetons sur la chaîne xDai via le xDai OmniBridge lors de la connexion au réseau Ethereum, vous devrez saisir manuellement ces transactions en tant qu'« achats » dans le calculateur de taxes sur les crypto-monnaies.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Gnosis dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    xverse_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Cliquez sur « Connecter le portefeuille » et suivez les instructions.",
            },
          },
        },
      },
    },
    yobit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "L'API Yobit obtient uniquement l'historique des transactions d'il y a une semaine",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Sous le nom de votre compte, sélectionnez les clés API",
              1: () => "Créer une nouvelle clé API pour Crypto Tax Calculator",
              2: () =>
                "Collez la clé et la clé secrète dans Crypto Tax Calculator",
            },
          },
        },
      },
    },
    yoroi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez également importer les transactions de votre portefeuille Yoroi directement via la blockchain en vous connectant avec l'option « Synchroniser via API ». Cela récupérera toutes les transactions et récompenses de jalonnement pour votre portefeuille Yoroi",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à Yoroi",
              1: () => "Accéder à la page récapitulative de la transaction",
              2: () => "En haut à droite, vous verrez l'icône d'exportation",
              3: () =>
                "Vous pouvez désormais importer ce CSV dans Crypto Tax Calculator",
            },
          },
        },
      },
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Saisissez uniquement UNE adresse de chaque portefeuille. Nous effectuons une recherche et récupérons automatiquement les données de toutes les autres adresses du portefeuille.",
              1: () =>
                "L'importation de plusieurs adresses à partir du même portefeuille entraînera des transactions en double.",
              2: () =>
                "Actuellement, nous ne prenons pas en charge l'obtention de l'historique complet des transactions des portefeuilles où chaque adresse individuelle contient plus de 10 transactions. Nous travaillons à fournir un support supplémentaire pour ces portefeuilles.",
            },
          },
          1: {
            items: {
              0: () =>
                "Si vous importez une adresse commençant par « addr1 », nous la modifions automatiquement par l'adresse du propriétaire du compte commençant par « stake1 ».",
              1: () =>
                "Cela nous permet d'importer toutes les autres adresses que vous pouvez créer dans le même portefeuille et de capturer vos récompenses de jalonnement.",
            },
            title: () => "Pourquoi mon adresse est stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Ouvrez votre portefeuille Yoroi et cliquez sur Portefeuille > Recevoir",
              1: () =>
                "Cliquez sur « A un solde », puis copiez l'une des adresses publiques commençant par « addr1… »",
              2: () =>
                "Collez l'adresse de votre portefeuille public dans CTC et cliquez sur « Importer le portefeuille »",
            },
          },
        },
      },
    },
    zebpay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Connectez-vous à votre compte ZebPay.",
              1: () =>
                "Cliquez sur l'icône de profil dans le coin supérieur droit.",
              2: () => "Sélectionner les déclarations.",
              3: () => "Choisissez le relevé de compte consolidé.",
              4: () =>
                "Définissez les dates de début et de fin, puis cliquez sur Soumettre.",
              5: () =>
                "Vous recevrez le rapport par e-mail – téléchargez-le à partir de là.",
              6: () => "Téléchargez les fichiers sur Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    zeroExProtocol: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Vous devrez importer les fichiers CSV pour chaque mois où vous avez effectué des transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Accédez à Delta Balances > Historique des échanges > Protocole 0x (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Saisissez l'adresse de votre portefeuille public dans la barre de recherche en haut de la page et cliquez sur l'icône de recherche",
              2: () =>
                "Dans la plage de dates, sélectionnez le mois où vous avez effectué la transaction",
              3: () => "Conserver les autres paramètres par défaut",
              4: () => "Cliquez sur « Récupérer l'historique »",
              5: () =>
                "Une fois terminé, cliquez sur « Exporter les transactions » et sélectionnez « V2 par défaut (CSV) »",
              6: () =>
                "Dans Crypto Tax Calculator, vous pouvez désormais saisir l'adresse de votre portefeuille et télécharger ce CSV",
            },
          },
        },
      },
    },
    zetachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille ZetaChain (commençant par 0x) dans Crypto Tax Calculator, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    zksync: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Notez que les transactions zkSync Era ne seront pas importées tant qu'elles n'auront pas été vérifiées par la blockchain. Cela prend généralement 30 à 60 minutes, mais peut s'étendre jusqu'à 24 heures ou plus. Cela peut également entraîner des écarts de solde temporaires, qui seront résolus une fois les transactions vérifiées.",
            },
            title: () => "Délai de transaction de zkSync Era",
          },
          1: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille zkSync Era (commençant par 0x) dans Crypto Tax Calculator, indiquez un pseudo facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
    zora: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Vous pouvez ajouter plusieurs portefeuilles en saisissant une autre adresse et en cliquant sur « Ajouter un portefeuille supplémentaire »",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Saisissez l'adresse de votre portefeuille Zora (commençant par 0x) dans le calculateur de taxes sur les crypto-monnaies, indiquez un surnom facultatif et cliquez sur « Ajouter un portefeuille »",
            },
          },
        },
      },
    },
  },
  importInstructionsNotice: {
    binance: () =>
      "Binance a annoncé dans son annonce du 06/08/2021 que l'accès à l'API ne sera fourni qu'aux comptes ayant terminé la vérification d'identité. Si votre synchronisation API ne fonctionne pas, nous vous recommandons de terminer la vérification d'identité KYC (intermédiaire) de Binance avant de réessayer.",
    coinsmart: () =>
      "Coinsmart a cessé ses activités à compter d'octobre 2023 et tous les comptes utilisateurs ont été migrés vers Bitbuy. Voir l'annonce : « https://www.coinsmart.com/blog/important-notice-changes-to-your-coinsmart-account/ »",
  },
};
