import { useSelectedIds } from "~/hooks/useTransactions";
import { getPartyDetails, type PartyDetails } from "~/services/transactions";
import { useGetActionsQuery } from "~/state/actions";
import { Side } from "~/types/enums";

/**
 * Gets the unique set of exchange accounts the user has selected
 * @returns The set of parties
 */
export function useSelectedAccounts() {
  const selectedIds = useSelectedIds();
  const query = useGetActionsQuery();

  const uniqueAccountsMap = new Map<string, PartyDetails>();
  query.data?.transactions.forEach((action) => {
    if (selectedIds.length && !selectedIds.includes(action._id)) {
      return [];
    }
    action.incoming.forEach((transaction) => {
      const party = getPartyDetails(transaction, Side.To);
      uniqueAccountsMap.set(party.exchange, party);
    });
    action.outgoing.forEach((transaction) => {
      const party = getPartyDetails(transaction, Side.From);
      uniqueAccountsMap.set(party.exchange, party);
    });
  });
  const uniqueAccountsArray = Array.from(uniqueAccountsMap.values());
  return uniqueAccountsArray;
}
