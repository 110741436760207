import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { invariant } from "~/lib/invariant";
import { useNewBulkOpMutation } from "~/state/actions";
import { BulkOperations } from "~/types/enums";

export const ConfirmationAsSpam = () => {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");
  const bulkMutation = useNewBulkOpMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.MarkAsSpam,
  });

  const onConfirm = () => {
    bulkMutation.mutate(
      {
        operation: {
          type: BulkOperations.MarkAsSpam,
        },
        filter,
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.MarkAsSpam} />
        </BulkEditChips>
      }
      onConfirm={onConfirm}
      loading={bulkMutation.isPending}
    />
  );
};
