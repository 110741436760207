import { SyncStatusPlatform } from "@ctc/types";
import { Refresh } from "@mui/icons-material";
import {
  Button,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
  Typography,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import moment, { type Moment } from "moment-timezone";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useOptionKeyPress } from "~/hooks/useOptionKeyPress";
import { usePrevious } from "~/hooks/usePrevious";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import { useLang, useLocale } from "~/redux/lang";
import { refreshReport } from "~/redux/report";
import { type AppDispatch } from "~/redux/types";
import {
  ProgressType,
  useClearProgress,
  useProgressQuery,
} from "~/state/progress";
import { useIsReconciliationLoading } from "~/state/reconciliation";
import {
  useHasImportPending,
  useReportStatus,
  useUserSyncStatus,
} from "~/state/sync";
import { SyncStatusBadgeVariant } from "~/types/enums";

/**
 * How long it takes to move the progress bar
 * and how long it takes to animate the value
 */
const ANIMATION_TIME_MS = 2000;

const ButtonLink = styled(Button)`
  && {
    color: ${({ theme }) => theme.tokens.text.brand};
    padding: 0;
    background: transparent;
    line-height: 1;
    font-weight: 500;
    margin: 0 0 0 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.tokens.border.brand};
    border-radius: 0;

    &:hover,
    &:focus {
      background: transparent;
    }
  }
`;

const Badge = styled.div<{
  backgroundColor: string;
  iconColor: string;
  isMobile?: boolean;
}>`
  border-radius: 4px;
  color: ${({ theme }) => theme.tokens.text.default};
  display: ${(props) => (props.isMobile ? "inline-block" : "none")};
  font-family: ${(props) => props.theme.mui.typography.fontFamily};
  font-size: 0.875rem;
  padding: 0.4rem 0.5rem;
  line-height: 1;
  vertical-align: middle;
  ${(props) => `background: ${props.backgroundColor};`}

  @media (min-width: 1130px) {
    display: inline-block;
  }

  &::before {
    content: "";
    display: inline-block;
    margin-right: 0.5rem;
    height: 0.625rem;
    width: 0.625rem;
    border-radius: 100%;
    ${(props) => `background: ${props.iconColor};`}
  }
`;

export const ChipBadgeBox = styled.div<{
  backgroundColor: string;
  iconColor: string;
}>`
  border-radius: 4px;
  color: ${({ theme }) => theme.tokens.text.default};
  ${(props) => `background: ${props.backgroundColor};`}
  display: inline-block;
  font-size: 0.875rem;
  padding: 0.4rem 0.5rem;
  line-height: 1;
  vertical-align: middle;
`;

export const ChipBadge = styled.div<{
  colors: {
    backgroundColor: string;
    backgroundColorHover: string;
    borderColor: string | undefined;
    iconColor: string;
  };
  isSmall: boolean;
}>`
  border-radius: 4px;
  border: 1px solid transparent;
  transition:
    border 0.3s ease,
    background-color 0.3s ease;
  color: ${({ theme }) => theme.tokens.text.default};
  ${(props) => `background: ${props.colors.backgroundColor};`}
  display: inline-block;
  position: relative;
  font-size: 0.875rem;
  padding: 0.4rem 0.5rem;
  line-height: 1;
  vertical-align: middle;

  ${({ isSmall }) =>
    isSmall
      ? `
  width: 1.25rem;
  height: 1.25rem;
  `
      : `
  width: 1.625rem;
  height: 1.625rem;
  `}

  &:hover {
    ${(props) => `background: ${props.colors.backgroundColorHover};`}
    ${(props) =>
      props.colors.borderColor &&
      `border: 1px solid ${props.colors.borderColor};`}
  }

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0.625rem;
    width: 0.625rem;
    border-radius: 100%;
    transition: background-color 0.3s ease;
    ${(props) => `background: ${props.colors.iconColor};`}
  }
`;

type Status = {
  status: SyncStatusPlatform | null;
  updatedAt: Moment | null;
};

export function SyncStatusBadge({ isMobile = false }: { isMobile?: boolean }) {
  const lang = useLang();
  const dispatch: AppDispatch = useDispatch();
  const { tokens } = useDesign();

  function getBadgeBackgroundColor(variant: SyncStatusBadgeVariant) {
    switch (variant) {
      case SyncStatusBadgeVariant.Success:
        return tokens.background.success.hover;
      case SyncStatusBadgeVariant.Info:
        return tokens.background.warning.hover;
      case SyncStatusBadgeVariant.Error:
        return tokens.background.danger.hover;
      default:
        return tokens.background.warning.hover;
    }
  }

  function getBadgeIconColor(variant: SyncStatusBadgeVariant) {
    switch (variant) {
      case SyncStatusBadgeVariant.Success:
        return tokens.icon.success;
      case SyncStatusBadgeVariant.Info:
        return tokens.icon.warning;
      case SyncStatusBadgeVariant.Error:
        return tokens.icon.danger;
      default:
        return tokens.icon.warning;
    }
  }

  const isLoadingReconciliation = useIsReconciliationLoading();

  const status: Status = {
    status: useUserSyncStatus(),
    updatedAt: moment(useReportStatus()?.reportUpdatedAt) || null,
  };
  const hasImportPending = useHasImportPending();

  if (!status.status || !status.updatedAt) return null;

  const actions: Record<string, { label: string; onClick: () => void }> = {
    [SyncStatusPlatform.Fail]: {
      label: lang.tryAgain,
      onClick: () => {
        dispatch(refreshReport());
      },
    },
  };

  const statusIncludingPendingSyncs = hasImportPending
    ? SyncStatusPlatform.Pending
    : status.status;

  const labels = {
    [SyncStatusPlatform.Fail]: lang.syncStatus.failed,
    [SyncStatusPlatform.Success]: `${
      lang.syncStatus.updated
    } ${status.updatedAt.fromNow()}`,
    [SyncStatusPlatform.Pending]: lang.syncStatus.updating,
    [SyncStatusPlatform.Queued]: lang.syncStatus.updating,
  };

  const variants = {
    [SyncStatusPlatform.Fail]: SyncStatusBadgeVariant.Error,
    [SyncStatusPlatform.Success]: SyncStatusBadgeVariant.Success,
    [SyncStatusPlatform.Pending]: SyncStatusBadgeVariant.Info,
    [SyncStatusPlatform.Queued]: SyncStatusBadgeVariant.Info,
  };

  const action = actions[statusIncludingPendingSyncs] && (
    <ButtonLink
      variant="text"
      onClick={actions[statusIncludingPendingSyncs].onClick}
    >
      {actions[statusIncludingPendingSyncs].label}
    </ButtonLink>
  );

  const variant = variants[statusIncludingPendingSyncs];
  const label = labels[statusIncludingPendingSyncs];

  // If the report refresh is all good, then we will show the
  // reconciliation regeneration status
  if (
    isLoadingReconciliation &&
    statusIncludingPendingSyncs === SyncStatusPlatform.Success
  ) {
    return (
      <Badge
        backgroundColor={getBadgeBackgroundColor(SyncStatusBadgeVariant.Info)}
        iconColor={getBadgeIconColor(SyncStatusBadgeVariant.Info)}
        isMobile={isMobile}
      >
        {lang.syncStatus.reconciliation}
      </Badge>
    );
  }

  return (
    <Badge
      backgroundColor={getBadgeBackgroundColor(variant)}
      iconColor={getBadgeIconColor(variant)}
      isMobile={isMobile}
    >
      {label} {action}
    </Badge>
  );
}

export function useReportStatusIncludingPendingSyncs() {
  const hasImportPending = useHasImportPending();
  const userSyncStatus = useUserSyncStatus();

  const statusIncludingPendingSyncs = hasImportPending
    ? SyncStatusPlatform.Pending
    : userSyncStatus;

  return statusIncludingPendingSyncs || SyncStatusPlatform.Success;
}

export function useIsLoadingReconciliationAndRRFinished() {
  const isLoadingReconciliation = useIsReconciliationLoading();
  const statusIncludingPendingSyncs = useReportStatusIncludingPendingSyncs();

  return (
    isLoadingReconciliation &&
    statusIncludingPendingSyncs === SyncStatusPlatform.Success
  );
}

export function getSyncStatusLabel(
  lang: Translation,
  statusIncludingPendingSyncs: SyncStatusPlatform,
  isLoadingReconciliationAndRRFinished: boolean,
  updatedAt: Moment,
) {
  const labels = {
    [SyncStatusPlatform.Fail]: lang.syncStatus.failed,
    [SyncStatusPlatform.Success]: `${lang.syncStatus.updated} ${updatedAt.fromNow()}`,
    [SyncStatusPlatform.Pending]: lang.syncStatus.updating,
    [SyncStatusPlatform.Queued]: lang.syncStatus.updating,
  };

  const label = isLoadingReconciliationAndRRFinished
    ? lang.syncStatus.reconciliation
    : labels[statusIncludingPendingSyncs];

  return label;
}

export function SyncStatusChip() {
  const lang = useLang();
  const userSyncStatus = useUserSyncStatus();
  const updatedAt = moment(useReportStatus()?.reportUpdatedAt) || null;
  const isLoadingReconciliationAndRRFinished =
    useIsLoadingReconciliationAndRRFinished();
  const statusIncludingPendingSyncs = useReportStatusIncludingPendingSyncs();
  const { tokens } = useDesign();

  if (!userSyncStatus || !updatedAt) return null;

  const label = getSyncStatusLabel(
    lang,
    statusIncludingPendingSyncs,
    isLoadingReconciliationAndRRFinished,
    updatedAt,
  );

  return (
    <CustomTooltip
      placement="bottom-end"
      title={
        <Stack direction="column" gap="0.5rem">
          <Typography
            variant="Metropolis/Body/Regular"
            color={tokens.text.default}
          >
            {label}
          </Typography>

          <ReportRefreshProgressBar />

          <RerunCalculationsButton />
        </Stack>
      }
    >
      <span>
        <Stack direction="row" alignItems="center" gap="0.5rem">
          <StatusChip
            isLoading={isLoadingReconciliationAndRRFinished}
            status={statusIncludingPendingSyncs}
            isSmall
          />
        </Stack>
      </span>
    </CustomTooltip>
  );
}

/**
 * Styled mui progress bar
 */
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.tokens.background.warning.default,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "0.625rem",
    backgroundColor: theme.tokens.text.warning,
  },
}));

/**
 * Progress bar for the report refresh
 */
function ProgressBar({
  value,
  max,
}: {
  value: number;
  max?: number | undefined;
}) {
  if (!max || value === max) {
    return <BorderLinearProgress variant="indeterminate" />;
  }
  return (
    <BorderLinearProgress variant="determinate" value={(value / max) * 100} />
  );
}

// @ts-ignore
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "95vw",
    padding: "0.5rem",
    borderRadius: "0.5rem",
    backgroundColor: (props: any) => props.theme.tokens.elevation.low,
    border: (props: any) =>
      `1px solid ${props.theme.tokens.border.neutral.default}`,
  },
  [`& .MuiTooltip-arrow`]: {
    color: (props: any) => props.theme.tokens.elevation.low,
  },
});

/**
 * Rerun calculations button
 */
function RerunCalculationsButton() {
  const lang = useLang();
  const dispatch = useDispatch();

  // We disable the retry button after the user clicks it so they cant spam it
  // then after the status changes, we can add the button back
  const statusIncludingPendingSyncs = useReportStatusIncludingPendingSyncs();
  const { clear: clearProgress } = useClearProgress(ProgressType.ReportRefresh);

  const [isReadyToRunReportRefresh, setIsReadyToRunReportRefresh] = useState(
    statusIncludingPendingSyncs !== SyncStatusPlatform.Pending,
  );

  // track when the status changes, so we can re-enable the button
  const previousStatusIncludingPendingSyncs =
    usePrevious(statusIncludingPendingSyncs) ?? statusIncludingPendingSyncs;
  if (
    // the status changed
    previousStatusIncludingPendingSyncs !== statusIncludingPendingSyncs &&
    // and it didnt change to pending
    statusIncludingPendingSyncs !== SyncStatusPlatform.Pending &&
    // and its not already enabled
    !isReadyToRunReportRefresh
  ) {
    // the status has changed, to a non-pending status
    // so the user can click the button again
    setIsReadyToRunReportRefresh(true);
  }

  // allow the option button to override the disabling
  const isOptionKeyPressed = useOptionKeyPress();
  const enabled = isReadyToRunReportRefresh || isOptionKeyPressed;
  return (
    <TertiaryButton
      size="small"
      disabled={!enabled}
      onClick={() => {
        // after the use clicks it so they cant spam it
        clearProgress();
        setIsReadyToRunReportRefresh(false);
        dispatch(refreshReport());
      }}
      style={{
        padding: "0.375rem 0.75rem",
      }}
      startIcon={<Refresh sx={{ fontSize: "0.6875rem" }} />}
    >
      {lang.syncStatus.retry}
    </TertiaryButton>
  );
}

/** Displays a progress bar for the report refresh */
function ReportRefreshProgressBar() {
  const statusIncludingPendingSyncs = useReportStatusIncludingPendingSyncs();
  const progressQuery = useProgressQuery();

  // Only show if its pending
  if (statusIncludingPendingSyncs !== SyncStatusPlatform.Pending) {
    return null;
  }
  const { max, value } = progressQuery.data ?? {
    max: undefined,
    value: undefined,
  };
  if (value === undefined) {
    return null;
  }

  return (
    <Stack direction="row" gap="0.5rem" alignItems="center">
      <div style={{ flexGrow: 1 }}>
        <ProgressBar value={value} max={max} />
      </div>
      <div>
        <AnimatedValue value={value} max={max} />
      </div>
    </Stack>
  );
}

/** Animates the value as it ticks up using the delay */
function AnimatedValue({
  value,
  max,
}: {
  value: number;
  max?: number | undefined;
}) {
  const fromValue = usePrevious(value) ?? 0;
  const locale = useLocale();
  const { number } = useSpring({
    from: { number: fromValue },
    number: value,
    config: { duration: ANIMATION_TIME_MS },
  });

  if (value === 0 && max === undefined) {
    // We dont show the value if its 0 and there is no max
    // a stand alone `0` isnt that useful
    return null;
  }
  return (
    <Typography
      variant="IBM Plex Mono/Caption/Medium/Regular"
      // eslint-disable-next-line no-restricted-syntax
      style={{ position: "relative", top: "-2px" }}
    >
      <animated.span>
        {number.to((n) =>
          n.toLocaleString(locale, { maximumFractionDigits: 0 }),
        )}
      </animated.span>
      {max && (
        <>
          {" / "}
          {max.toLocaleString(locale)}
        </>
      )}
    </Typography>
  );
}

export function StatusChip({
  status,
  isLoading = false,
  isSmall = false,
}: {
  status: SyncStatusPlatform;
  value?: number;
  max?: number;
  isLoading?: boolean;
  isSmall?: boolean;
}) {
  const { tokens } = useDesign();

  function getVariantColors(variant: SyncStatusBadgeVariant) {
    switch (variant) {
      case SyncStatusBadgeVariant.Success:
        return {
          backgroundColor: tokens.background.success.default,
          backgroundColorHover: tokens.background.success.hover,
          borderColor: tokens.border.success,
          iconColor: tokens.icon.success,
        };
      case SyncStatusBadgeVariant.Info:
        return {
          backgroundColor: tokens.background.warning.default,
          backgroundColorHover: tokens.background.warning.hover,
          borderColor: tokens.border.warning,
          iconColor: tokens.icon.warning,
        };
      case SyncStatusBadgeVariant.Error:
        return {
          backgroundColor: tokens.background.danger.default,
          backgroundColorHover: tokens.background.danger.hover,
          borderColor: tokens.border.danger,
          iconColor: tokens.icon.danger,
        };
      default:
        return {
          backgroundColor: tokens.background.warning.default,
          backgroundColorHover: tokens.background.warning.hover,
          borderColor: undefined,
          iconColor: tokens.icon.warning,
        };
    }
  }

  const variants = {
    [SyncStatusPlatform.Fail]: SyncStatusBadgeVariant.Error,
    [SyncStatusPlatform.Success]: SyncStatusBadgeVariant.Success,
    [SyncStatusPlatform.Pending]: SyncStatusBadgeVariant.Info,
    [SyncStatusPlatform.Queued]: SyncStatusBadgeVariant.Info,
  };

  const variant = isLoading ? SyncStatusBadgeVariant.Info : variants[status];

  return (
    <ChipBadge
      colors={getVariantColors(variant)}
      data-ctc={variant}
      isSmall={isSmall}
    />
  );
}
