import { type Blockchain } from "@ctc/types";
import { Box } from "@mui/material";
import { useAppKit } from "@reown/appkit/react";
import { useAppKitWallet } from "@reown/appkit-wallet-button/react";
import isEqual from "lodash/isEqual";
import { memo, useContext, useState } from "react";
import {
  useAccount,
  useAccountEffect,
  useDisconnect,
  WagmiProvider,
} from "wagmi";

import { ImportFormContext } from "~/components/imports/context/index";
import {
  getRelatedWalletsAction,
  isConnectWalletImportMethod,
} from "~/components/imports/helpers";
import { useImportContext } from "~/components/imports/ImportItem";
import { SecureConnectWalletNote } from "~/components/imports/wallet";
import {
  type ConnectWalletConfigHandler,
  type ConnectWalletConfigKey,
} from "~/components/imports/wallet/types";
import { CensoredTypography } from "~/components/ui/CensoredComponents";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang";
import { wagmiAdapter } from "~/lib/appKit";
import { middleTrim } from "~/lib/index";
import { useLang } from "~/redux/lang";
import { formatWalletAddress } from "~/services/wallets";
import {
  useEntityLookupAsync,
  useRemoveAddressFromEntityMutation,
} from "~/state/entities";
import { useAvailableImportOption } from "~/state/imports";
import {
  useGetMapSavedImportsByIntegrations,
  useGetSavedImportsByIntegration,
} from "~/state/importsV2";
import { useAddWalletMutation } from "~/state/wallets";
import { ImportMethod, RelatedWalletsAction } from "~/types/enums";
import {
  type ConnectWalletImportMethod,
  type ImportMethodV2,
  type ImportOptionV2,
  type SavedImportByIntegration,
  type WalletImportMethod,
} from "~/types/index";

const MAX_WALLETS = 10;

export function isConnectWalletConfigHandler(
  walletOrConfigHandler: ConnectWalletConfigKey | ConnectWalletConfigHandler,
): walletOrConfigHandler is ConnectWalletConfigHandler {
  return (
    typeof walletOrConfigHandler === "object" &&
    "walletConnect" in walletOrConfigHandler
  );
}

/**
 * If the wallet uses a config handler and has multiple
 * blockchains and we can get all the addresses for every chain from the connection
 * (if this is possible), we should ignore the blockchain selector.
 *
 * @see https://docs.xverse.app/sats-connect/connecting-to-the-wallet/connect-to-xverse-wallet (Sample Connect Wallet Config)
 */
export function isDerivedBlockchainImportMethod(
  importMethod: ImportMethodV2,
): boolean {
  return (
    isConnectWalletImportMethod(importMethod) &&
    isConnectWalletConfigHandler(importMethod?.walletConnectOrConfig) &&
    !!importMethod?.walletConnectOrConfig?.shouldIgnoreBlockchainSelector
  );
}

function validateAndFilterWalletAddresses(
  savedImport: SavedImportByIntegration | undefined,
  addresses: readonly string[] | string[],
  lang?: Translation,
): {
  duplicateAddresses: string[];
  uniqueAddresses: string[];
  errorMessage: string | null;
} {
  const defaultErrorMessage = "Duplicate addresses found";
  const connectWalletErrorLang = lang?.imports.connectWalletErrors;
  const existingAddressesInChain = new Set(
    savedImport?.wallets.map(({ address }) => address.toLowerCase()),
  );

  const duplicateAddresses = addresses.filter((address) =>
    existingAddressesInChain.has(address.toLowerCase()),
  );

  const uniqueAddresses = addresses.filter(
    (address) => address !== null && !duplicateAddresses.includes(address),
  );

  if (duplicateAddresses.length > 0) {
    const errorMessage = lang
      ? duplicateAddresses.length === 1
        ? connectWalletErrorLang?.duplicateWallet({
            address: middleTrim(duplicateAddresses[0]).toLowerCase(),
          })
        : // typescript is acting strange here
          typeof connectWalletErrorLang?.duplicateWallets === "function"
          ? connectWalletErrorLang?.duplicateWallets({
              addresses: duplicateAddresses
                .map((address) => middleTrim(address))
                .join(", "),
            })
          : connectWalletErrorLang?.duplicateWallets
      : defaultErrorMessage;

    return {
      duplicateAddresses,
      uniqueAddresses,
      errorMessage: errorMessage ?? defaultErrorMessage,
    };
  }

  return {
    duplicateAddresses: [],
    uniqueAddresses: Array.from(addresses),
    errorMessage: null,
  };
}

export const ConnectWallet = memo(ConnectWalletContainer, isEqual);

function ConnectWalletContainer({
  importOption,
  blockchain,
  importMethod,
}: {
  importOption: ImportOptionV2;
  blockchain: Blockchain;
  importMethod: ConnectWalletImportMethod;
}) {
  if (isConnectWalletConfigHandler(importMethod.walletConnectOrConfig)) {
    return (
      <WalletConnectHandlerContainer
        importOption={importOption}
        blockchain={blockchain}
        importMethod={importMethod}
        config={importMethod.walletConnectOrConfig}
      />
    );
  }

  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <ConnectWalletBody
        importOption={importOption}
        blockchain={blockchain}
        importMethod={importMethod}
      />
    </WagmiProvider>
  );
}

function ConnectWalletBody({
  importOption,
  blockchain,
  importMethod,
}: {
  importOption: ImportOptionV2;
  blockchain: Blockchain;
  importMethod: ConnectWalletImportMethod;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const { setSyncStarted } = useImportContext();
  const importFormContext = useContext(ImportFormContext);
  const savedImport = useGetSavedImportsByIntegration(blockchain);
  const { disconnectAsync } = useDisconnect();
  const { isReconnecting } = useAccount();
  const addWallet = useAddWalletMutation({ isUsingImportForm: true });
  const { getEntityForAddress } = useEntityLookupAsync();
  const removeAddressFromEntityMutation = useRemoveAddressFromEntityMutation();
  const blockchainImportOption = useAvailableImportOption(blockchain);
  const [error, setError] = useState<string | null>(null);
  const { isReady: isAppKitReady, connect: connectDirectToProvider } =
    useAppKitWallet();
  const { open: openAppKitModal } = useAppKit();

  const isUnspecifiedBlockchainAllowed =
    !!importMethod.options?.isUnspecifiedBlockchainAllowed;

  const walletImport = importOption.importMethods.find(
    (item) => item.type === ImportMethod.Wallet,
  ) as WalletImportMethod;

  const submitAddress = async (rawAddress: string) => {
    const selectedBlockchain = isUnspecifiedBlockchainAllowed
      ? walletImport?.blockchains[0]
      : blockchain;

    const parsedAddress = formatWalletAddress(rawAddress, selectedBlockchain);
    const savedAddress = getEntityForAddress(rawAddress);

    setTimeout(() => {
      setSyncStarted({
        value: true,
        accountId: parsedAddress,
        savedIntegration: {
          type: ImportMethod.ConnectWallet,
          addresses: [parsedAddress],
          blockchain: selectedBlockchain,
        },
      });
    }, 0);

    addWallet.mutate({
      address: parsedAddress,
      type: selectedBlockchain,
      relatedWalletsAction: RelatedWalletsAction.Notify,
      isUnspecifiedBlockchain: isUnspecifiedBlockchainAllowed,
      blockchainImportOption,
      importSource: importOption.id,
      importMethod: ImportMethod.ConnectWallet,
    });
    if (savedAddress) {
      removeAddressFromEntityMutation.mutate({
        entity: savedAddress,
        removedAddress: {
          address: rawAddress,
          blockchain: selectedBlockchain,
        },
      });
    }
    importFormContext?.setShowImportForm(false);
  };

  const onSubmit = async (addresses: readonly string[]) => {
    // reconnecting triggers when we connect to the wallet before we've
    // rendered this component, so when this component renders
    // the 'connect' event triggers, calling the `onConnect` event below
    if (isReconnecting) {
      return;
    }

    setError(null);

    const { duplicateAddresses, errorMessage, uniqueAddresses } =
      validateAndFilterWalletAddresses(savedImport, addresses, lang);

    // If the user has provided at least 1 new address we still want to import that address
    if (uniqueAddresses.length === 0) {
      setError(errorMessage);
      return;
    }

    if (uniqueAddresses.length > MAX_WALLETS) {
      setError(
        lang.imports.connectWalletErrors.maxWallets({
          max: MAX_WALLETS,
        }),
      );
      return;
    }

    for (const address of uniqueAddresses) {
      await submitAddress(address);
    }
  };

  useAccountEffect({
    onConnect: async (data) => {
      await onSubmit(data.addresses);
      await disconnectAsync();
    },
  });

  const clearError = () => {
    setError(null);
  };

  // For MetaMask we use the WalletButton to bypass all appKit branding in the
  // appKit modal that appears when using `connect()`.
  // The WalletButton opens MetaMask directly, without the appKit modal.
  // https://docs.reown.com/appkit/react/core/components#appkit-wallet-button--
  const metamaskButton = importOption.id === "metamask" && (
    <PrimaryButton
      disabled={!isAppKitReady}
      onClick={() => {
        clearError();
        connectDirectToProvider(
          importMethod.walletConnectOrConfig as ConnectWalletConfigKey,
        );
      }}
      fullWidth
    >
      {lang.imports.connectWallet}
    </PrimaryButton>
  );

  const rainbowButton = openAppKitModal && (
    <PrimaryButton
      onClick={() => {
        clearError();
        openAppKitModal();
      }}
      fullWidth
    >
      {lang.imports.connectWallet}
    </PrimaryButton>
  );

  const errorMessage = error && (
    <CensoredTypography
      variant="Metropolis/Body/Light"
      color={tokens.text.danger}
      textAlign="center"
      mt={1}
    >
      {error}
    </CensoredTypography>
  );

  return (
    <Box mt={1}>
      {metamaskButton || rainbowButton}
      <SecureConnectWalletNote />
      {errorMessage}
    </Box>
  );
}

function WalletConnectHandlerContainer({
  importOption,
  blockchain,
  importMethod,
  config,
}: {
  importOption: ImportOptionV2;
  blockchain: Blockchain;
  importMethod: ConnectWalletImportMethod;
  config: ConnectWalletConfigHandler;
}) {
  const lang = useLang();
  const { getEntityForAddress } = useEntityLookupAsync();
  const { tokens } = useDesign();
  const [error, setError] = useState<string | null>(null);
  const { setSyncStarted } = useImportContext();
  const addWallet = useAddWalletMutation({ isUsingImportForm: true });
  const savedImportsMap = useGetMapSavedImportsByIntegrations(
    importMethod.blockchains,
  );
  const removeAddressFromEntityMutation = useRemoveAddressFromEntityMutation();

  async function connectWalletHandler() {
    if (!config.isInstalled) {
      setError(
        lang.imports.connectWalletErrors.walletNotInstalled({
          wallet: importOption.name,
        }),
      );
      return;
    }

    setError(null);
    const blockchainsWithAddresses = await config.walletConnect({
      importOption,
      importMethod,
      blockchain,
    });

    let errMessage: string | null = null;
    for (const [blockchain, addresses] of Object.entries(
      blockchainsWithAddresses,
    )) {
      const addressesToSubmit: string[] = addresses;
      const savedImport = savedImportsMap[blockchain];

      if (savedImport) {
        const { duplicateAddresses, errorMessage, uniqueAddresses } =
          validateAndFilterWalletAddresses(savedImport, addresses, lang);

        if (duplicateAddresses.length > 0) {
          errMessage = errMessage ?? errorMessage;
          addressesToSubmit.splice(
            0,
            addressesToSubmit.length,
            ...uniqueAddresses,
          );
        }
      }

      for (const address of addressesToSubmit) {
        await submitAddress(
          address,
          blockchain as Blockchain,
          addressesToSubmit.length,
        );
      }
    }
    if (errMessage) {
      setError(errMessage);
    }
  }

  const submitAddress = async (
    rawAddress: string,
    selectedBlockchain: Blockchain,
    totalWalletsCount: number,
  ) => {
    const parsedAddress = formatWalletAddress(rawAddress, selectedBlockchain);
    const savedAddress = getEntityForAddress(rawAddress);
    const isUnspecifiedBlockchainAllowed =
      !!importMethod.options?.isUnspecifiedBlockchainAllowed;

    setTimeout(() => {
      setSyncStarted({
        value: true,
        accountId: parsedAddress,
        savedIntegration: {
          type: ImportMethod.ConnectWallet,
          addresses: [parsedAddress],
          blockchain: selectedBlockchain,
        },
      });
    }, 0);

    addWallet.mutate({
      address: parsedAddress,
      type: selectedBlockchain,
      relatedWalletsAction: getRelatedWalletsAction(totalWalletsCount),
      isUnspecifiedBlockchain: isUnspecifiedBlockchainAllowed,
      blockchainImportOption: undefined,
      importSource: importOption.id,
      importMethod: ImportMethod.ConnectWallet,
    });
    if (savedAddress) {
      removeAddressFromEntityMutation.mutate({
        entity: savedAddress,
        removedAddress: {
          address: rawAddress,
          blockchain: selectedBlockchain,
        },
      });
    }
  };

  const errorMessage = error && (
    <CensoredTypography
      variant="Metropolis/Body/Light"
      color={tokens.text.danger}
      textAlign="center"
      mt={1}
    >
      {error}
    </CensoredTypography>
  );

  return (
    <Box mt={1}>
      <PrimaryButton onClick={connectWalletHandler} fullWidth>
        {lang.imports.connectWallet}
      </PrimaryButton>
      <SecureConnectWalletNote />
      {errorMessage}
    </Box>
  );
}
