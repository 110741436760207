import { InventoryMethod } from "@ctc/types";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { settingsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import {
  useHideNewTaxSettingBanner,
  useHighlightedTaxSettings,
} from "~/components/nav/NewTaxSettingBanner";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { useLocalReportSettings } from "~/components/settings-modal/views/tax/ReportSettingsContext";
import { ToggleSwitchBox } from "~/components/settings-modal/views/tax/ToggleSwitchBox";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useCountries } from "~/hooks/useCountries";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { type TaxSettings } from "~/types/index";

export type ToggleTaxSettings = Pick<
  TaxSettings,
  | "ignoreFiat"
  | "showFiatHoldings"
  | "airdropsAsIncome"
  | "miningAsIncome"
  | "stakingAsIncome"
  | "royaltiesAsIncome"
  | "stakingDepositWithdrawalNonTaxable"
  | "personalUseAsNonTaxable"
  | "outgoingGiftAsNonTaxable"
  | "collateralAsNonTaxable"
  | "lostStolenAsNonTaxable"
  | "bridgeAsNonTaxable"
  | "cryptoToCryptoNonTaxable"
  | "assumeTransferCounterparts"
  | "uncategorisedInAsTaxable"
  | "uncategorisedOutAsTaxable"
  | "splitFeesEvenlyAcrossGroupedMints"
  | "allowGenerateFeeOnGroupedQuantityDiff"
  | "showAllAccountsOnPortfolio"
  | "allowBridgeFromUncategorised"
  | "holdingsBalanceType"
>;

const learnMoreMap: Record<keyof ToggleTaxSettings, string | undefined> = {
  // CTC smarts
  assumeTransferCounterparts: undefined,
  splitFeesEvenlyAcrossGroupedMints: undefined,
  allowGenerateFeeOnGroupedQuantityDiff: undefined,
  allowBridgeFromUncategorised: undefined,

  // Tax toggles
  airdropsAsIncome:
    "https://help.cryptotaxcalculator.io/en/articles/7935555-tax-toggle-treat-airdrops-as-income",
  miningAsIncome:
    "https://help.cryptotaxcalculator.io/en/articles/6058067-tax-toggle-treat-mining-as-income",
  stakingAsIncome:
    "https://help.cryptotaxcalculator.io/en/articles/7940928-tax-toggle-treat-staking-rewards-as-income",
  royaltiesAsIncome:
    "https://help.cryptotaxcalculator.io/en/articles/7940937-tax-toggle-treat-royalties-as-income",
  stakingDepositWithdrawalNonTaxable:
    "https://help.cryptotaxcalculator.io/en/articles/7940388-tax-toggle-treat-staking-deposits-and-withdrawals-as-non-taxable",
  personalUseAsNonTaxable:
    "https://help.cryptotaxcalculator.io/en/articles/7940343-tax-toggle-treat-personal-use-as-a-non-taxable-disposal",
  outgoingGiftAsNonTaxable:
    "https://help.cryptotaxcalculator.io/en/articles/7940299-tax-toggle-treat-outgoing-gift-as-non-taxable-disposal",
  collateralAsNonTaxable:
    "https://help.cryptotaxcalculator.io/en/articles/7940401-tax-toggle-treat-collateral-deposits-and-withdrawals-as-non-taxable",
  bridgeAsNonTaxable:
    "https://help.cryptotaxcalculator.io/en/articles/7935063-tax-toggle-treat-bridges-as-non-taxable-disposal",
  cryptoToCryptoNonTaxable:
    "https://help.cryptotaxcalculator.io/en/articles/7940962-tax-toggle-treat-crypto-to-crypto-trades-as-non-taxable-disposals",
  uncategorisedInAsTaxable: undefined,
  uncategorisedOutAsTaxable: undefined,
  lostStolenAsNonTaxable: undefined,

  // Portfolio Toggles
  showAllAccountsOnPortfolio: undefined,
  ignoreFiat:
    "https://help.cryptotaxcalculator.io/en/articles/6048473-tax-toggle-ignore-gains-on-forex-conversions",
  showFiatHoldings:
    "https://help.cryptotaxcalculator.io/en/articles/7946688-tax-toggle-show-native-fiat-holdings-on-reports-and-dashboard",
  holdingsBalanceType: undefined,
};

const ctcSmarts = [
  "assumeTransferCounterparts",
  "splitFeesEvenlyAcrossGroupedMints",
  "allowGenerateFeeOnGroupedQuantityDiff",
  "allowBridgeFromUncategorised",
] as const;

const incomeToggles = [
  "airdropsAsIncome",
  "miningAsIncome",
  "stakingAsIncome",
  "royaltiesAsIncome",
] as const;

const capitalGainsToggles = [
  "ignoreFiat",
  "stakingDepositWithdrawalNonTaxable",
  "personalUseAsNonTaxable",
  "outgoingGiftAsNonTaxable",
  "collateralAsNonTaxable",
  "lostStolenAsNonTaxable",
  "bridgeAsNonTaxable",
  "cryptoToCryptoNonTaxable",
] as const;

const uncategorisedToggles = [
  "uncategorisedInAsTaxable",
  "uncategorisedOutAsTaxable",
] as const;

type BooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

type BooleanToggleTaxSettingsKeys = BooleanKeys<ToggleTaxSettings>;

export type NonBooleanToggleProps<T extends keyof ToggleTaxSettings> =
  ToggleTaxSettings[T] extends boolean
    ? {
        booleanToTaxSettingValue?: undefined;
        taxSettingsValueToBoolean?: undefined;
      }
    : {
        booleanToTaxSettingValue: (isChecked: boolean) => ToggleTaxSettings[T];
        taxSettingsValueToBoolean: (value: ToggleTaxSettings[T]) => boolean;
      };

function formatDate(date: Date, timezone: string) {
  return moment(date)
    .tz(timezone || "UTC")
    .format("Do MMM YYYY");
}

function getCryptoToCryptoNonTaxableTooltipOn(
  taxSettings: TaxSettings,
  lang: Translation,
): string {
  const { cryptoToCryptoNonTaxableStartDate: startDate, timezone } =
    taxSettings;
  const prefix = startDate
    ? lang.settings.report.cryptoToCryptoNonTaxable.tooltip.onWithDate({
        date: formatDate(startDate, timezone),
      })
    : lang.settings.report.cryptoToCryptoNonTaxable.tooltip.on;

  const nftDisclaimer = taxSettings.nftIsDifferentTypeOfCrypto
    ? lang.settings.report.cryptoToCryptoNonTaxable.tooltip.nftToCryptoTaxable
    : null;

  return `${prefix}${nftDisclaimer ? `. ${nftDisclaimer}` : ""}`;
}

function getToggleTooltipOn<T extends keyof ToggleTaxSettings>(
  property: T,
  taxSettings: TaxSettings,
  lang: Translation,
): string {
  switch (property) {
    case "cryptoToCryptoNonTaxable":
      return getCryptoToCryptoNonTaxableTooltipOn(taxSettings, lang);
    default:
      return lang.settings.report[property].tooltip.on;
  }
}

export function GeneralTaxSettingToggle<T extends keyof ToggleTaxSettings>({
  property,
  setTaxSettings,
  booleanToTaxSettingValue,
  taxSettingsValueToBoolean,
}: {
  property: T;
  setTaxSettings: (update: TaxSettings) => void;
} & NonBooleanToggleProps<T>) {
  const lang = useLang();
  const captureAnalytics = useCaptureAnalytics();
  const highlightedTaxSettings = useHighlightedTaxSettings();
  const {
    state: { taxSettings: localSettings },
  } = useLocalReportSettings();
  const hideBannerFunction = useHideNewTaxSettingBanner(property);

  const highlighted = highlightedTaxSettings.includes(property);

  if (!localSettings) return null;

  const doUpdateWrapped = (checked: boolean) => {
    setTaxSettings({
      ...localSettings,
      [property]: booleanToTaxSettingValue?.(checked) || checked,
    });

    captureAnalytics(settingsAnalyticsKey("taxSettings")("toggle"), {
      setting: property,
      state: checked,
    });

    // Hide the banner
    if (highlighted) {
      hideBannerFunction();
    }
  };

  return (
    <ToggleSwitchBox
      title={lang.settings.report[property].title}
      checked={
        taxSettingsValueToBoolean
          ? taxSettingsValueToBoolean(localSettings?.[property])
          : !!localSettings?.[property]
      }
      tooltipOn={getToggleTooltipOn(property, localSettings, lang)}
      tooltipOff={lang.settings.report[property].tooltip.off}
      onChange={doUpdateWrapped}
      learnMoreLink={learnMoreMap[property]}
      highlighted={highlighted}
    />
  );
}

export function TaxSettingsSections({
  setTaxSettings,
}: {
  setTaxSettings: (update: TaxSettings) => void;
}) {
  const lang = useLang();
  const highlightedRef = useRef<HTMLDivElement>(null);
  const highlightedTaxSettings = useHighlightedTaxSettings();
  const userCountry = useCountry();
  const countries = useCountries();
  const country = userCountry ? countries.getByCode(userCountry) : undefined;

  useEffect(() => {
    if (highlightedRef.current) {
      const windowHeight = window.innerHeight;
      const divOffsetTop = highlightedRef.current.offsetTop;
      const divHeight = highlightedRef.current.offsetHeight;

      // Calculate the desired scroll position to center the div vertically
      const scrollToPosition = divOffsetTop - (windowHeight - divHeight) / 2;

      // Scroll to the desired position with smooth behavior
      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  }, []);

  const firstHighlighted: keyof ToggleTaxSettings | null =
    highlightedTaxSettings.length ? highlightedTaxSettings[0] : null;

  if (!country) return null;

  return (
    <>
      <SettingsBox>
        <ToggleSection
          title={lang.ctcAutomations}
          toggles={ctcSmarts}
          firstHighlighted={firstHighlighted}
          highlightedRef={highlightedRef}
          setTaxSettings={setTaxSettings}
        />
      </SettingsBox>
      <SettingsBox>
        <ToggleSection
          title={lang.incomeToggles}
          toggles={incomeToggles}
          firstHighlighted={firstHighlighted}
          highlightedRef={highlightedRef}
          setTaxSettings={setTaxSettings}
        />
      </SettingsBox>
      <SettingsBox>
        <ToggleSection
          title={lang.gainsToggles}
          toggles={capitalGainsToggles}
          firstHighlighted={firstHighlighted}
          highlightedRef={highlightedRef}
          setTaxSettings={setTaxSettings}
        />
        <LegacyHMRCToggle setTaxSettings={setTaxSettings} />
      </SettingsBox>
      <SettingsBox>
        <ToggleSection
          title={lang.uncategorisedToggles}
          toggles={uncategorisedToggles}
          firstHighlighted={firstHighlighted}
          highlightedRef={highlightedRef}
          setTaxSettings={setTaxSettings}
        />
      </SettingsBox>
    </>
  );
}

function ToggleSection({
  title,
  toggles,
  firstHighlighted,
  highlightedRef,
  setTaxSettings,
  icon,
  children,
}: {
  title: string;
  toggles: Readonly<BooleanToggleTaxSettingsKeys[]>;
  firstHighlighted: keyof ToggleTaxSettings | null;
  highlightedRef: React.RefObject<HTMLDivElement>;
  setTaxSettings: (update: TaxSettings) => void;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  return (
    <>
      <HeaderBox>
        <Box display="flex" alignItems="center" gap="0.5rem">
          {icon ?? null}
          <Typography variant="Metropolis/Header/H5">{title}</Typography>
        </Box>
        <Tooltip title={lang.learnMore} placement="top">
          <TextIconButton
            size="small"
            onClick={() => {
              window.open(
                "https://help.cryptotaxcalculator.io/en/collections/3376646-tax-toggles",
                "_blank",
              );
            }}
          >
            <HelpOutlineOutlinedIcon
              sx={{ color: tokens.icon.default, fontSize: "1rem" }}
            />
          </TextIconButton>
        </Tooltip>
      </HeaderBox>
      {children ?? null}
      <ToggleList
        toggles={toggles}
        firstHighlighted={firstHighlighted}
        highlightedRef={highlightedRef}
        setTaxSettings={setTaxSettings}
      />
    </>
  );
}

function ToggleList({
  toggles,
  firstHighlighted,
  highlightedRef,
  setTaxSettings,
}: {
  toggles: Readonly<BooleanToggleTaxSettingsKeys[]>;
  firstHighlighted: keyof ToggleTaxSettings | null;
  highlightedRef: React.RefObject<HTMLDivElement>;
  setTaxSettings: (update: TaxSettings) => void;
}) {
  return (
    <>
      {toggles.map((taxSetting) => {
        return (
          <Box
            ref={firstHighlighted === taxSetting ? highlightedRef : null}
            key={taxSetting}
          >
            <GeneralTaxSettingToggle
              property={taxSetting}
              setTaxSettings={setTaxSettings}
            />
          </Box>
        );
      })}
    </>
  );
}

function LegacyHMRCToggle({
  setTaxSettings,
}: {
  setTaxSettings: (update: TaxSettings) => void;
}) {
  const lang = useLang();
  const {
    state: { taxSettings: localSettings },
  } = useLocalReportSettings();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

  const showHMRC =
    localSettings?.inventoryMethod === InventoryMethod.HMRC &&
    localSettings?.grandfatheredHMRC;

  if (!showHMRC || !localSettings) return null;

  const handleChangeUseLegacyHMRCCalcs = (checked: boolean) => {
    if (checked) {
      setIsConfirmationOpen(true);
    } else {
      setTaxSettings({ ...localSettings, useLegacyHMRCCalcs: false });
    }
  };

  const handleConfirm = () => {
    setTaxSettings({ ...localSettings, useLegacyHMRCCalcs: true });
    setIsConfirmationOpen(false);
  };
  const handleCancel = () => {
    setIsConfirmationOpen(false);
    setTaxSettings({ ...localSettings, useLegacyHMRCCalcs: false });
  };
  return (
    <>
      <ConfirmationDialog
        title={lang.settings.hmrc.title}
        text={lang.settings.hmrc.text}
        actionText={lang.settings.hmrc.actionText}
        handleAction={handleConfirm}
        isOpen={isConfirmationOpen}
        handleClose={handleCancel}
      />
      <ToggleSwitchBox
        title="Use HMRC Legacy Calculations" //Not in lang as this shouldn't be translated and won't be reused
        checked={localSettings.useLegacyHMRCCalcs}
        tooltipOn="Calculates as previously"
        tooltipOff="Calculates using the new HMRC guidelines"
        onChange={handleChangeUseLegacyHMRCCalcs}
      />
    </>
  );
}

export const HeaderBox = styled(Box)`
  && {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.75rem;
  }
`;
