import { Box, Collapse } from "@mui/material";
import { type ReactNode, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import { Calco } from "~/components/ui/calco/Calco";
import { devices } from "~/components/ui/theme/legacy";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  text-align: center;
  @media ${devices.tablet} {
    padding: 2rem;
  }
`;

export function ErrorFallback({ error }: { error: Error }) {
  const { tokens } = useDesign();
  const [moreInfoOpen, setMoreInfoOpen] = useState<boolean>(false);
  const lang = useLang().errorBoundaries;
  return (
    <ErrorBox role="alert">
      <Calco
        type="osht"
        style={{
          alignSelf: "center",
          width: "70%",
        }}
        wrapperProps={{
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      />

      <Box
        sx={{
          fontSize: "1.5rem",
          fontWeight: 600,
          color: tokens.text.high,
        }}
      >
        {lang.oopsSomethingWentWrong}
      </Box>

      <Box
        sx={{
          maxWidth: "30rem",
          color: tokens.text.default,
        }}
      >
        {lang.weCantProcess}
      </Box>

      <Box>
        <TextButton
          onClick={() => {
            setMoreInfoOpen(!moreInfoOpen);
          }}
        >
          {lang.debugInfo}
        </TextButton>
      </Box>
      <Collapse in={moreInfoOpen}>
        <Box
          sx={{
            backgroundColor: tokens.background.neutral.default,
            padding: "1rem",
            fontFamily: "monospace",
            color: tokens.text.default,
          }}
        >
          {lang.error}:{error.message}
        </Box>
      </Collapse>
    </ErrorBox>
  );
}

const logError = (error: Error, info: { componentStack: string }) => {
  console.error(`ErrorBoundaryWrapper error:`, error, info);
};

/**
 * Returns contents of wrapped in an error boundary
 */
export const ErrorBoundaryWrapper = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      resetKeys={[pathname]}
      onError={logError}
    >
      {children}
    </ErrorBoundary>
  );
};
