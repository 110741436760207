import { type Trade } from "@ctc/types";
import { CategoryOutlined, KeyboardArrowRight } from "@mui/icons-material";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { SmallCommandPaletteMenu } from "~/components/transactions/command-palette/SmallCommandPaletteMenu";
import {
  CategorisationFunctionFooter,
  getChipPairs,
} from "~/components/transactions/edit/CategorisationFooter";
import { type TxTrade } from "~/components/transactions/edit/EditActionTrade";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { useDesign } from "~/hooks/useTheme";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { invariant } from "~/lib/invariant";
import { ActionDefinitions, TradeInfo } from "~/lib/tradeTypeDefinitions";
import { useLang } from "~/redux/lang";
import {
  getTransactionCategoryOptions,
  isGroupedTrade,
} from "~/services/transactions";
import { TradeDirection } from "~/types/enums";
import { type GroupedTrade } from "~/types/enums";
import { type ActionData, type ActionRow } from "~/types/index";

export const GroupedCategorySelector = ({
  row,
  groupedTradeType,
  onSubmit,
  openMoreOptions,
}: {
  row: ActionRow;
  groupedTradeType: GroupedTrade;
  onSubmit: (txUpdates: TxTrade[]) => void;
  openMoreOptions: () => void;
}) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const analyticsKey = transactionAnalyticsKey("categorise");
  const captureAnalytics = useCaptureAnalytics();
  const rawOptions = getTransactionCategoryOptions({
    tradeInput: groupedTradeType,
  }).map((category) => {
    invariant(isGroupedTrade(category), "Must be a grouped action type");
    const label = getActionTypeName({ actionType: category, lang });
    const Icon = TradeIcons[category];
    const description = lang.tradeTypeTaxDescriptionExpanded[category];
    return {
      label,
      icon: <Icon sx={{ color: `${tokens.text.low} !important` }} />,
      description,
      trade: category,
      isOpenMore: false,
    };
  });

  if (!rawOptions.length) {
    openMoreOptions();
    return null;
  }

  const options = [
    ...rawOptions,
    {
      label: lang.uncategorisedModal.moreOptions,
      icon: <CategoryOutlined />,
      isOpenMore: true,
      endIcon: <KeyboardArrowRight style={{ fontSize: "1rem" }} />,
      trade: undefined,
    },
  ];

  const onSubmitWrapped = (trade: GroupedTrade) => {
    const { incoming, outgoing } = row;
    const updates = [...incoming, ...outgoing].map((tx) => ({
      txId: tx._id,
      trade: getNewTradeForGivenGroup(trade, tx.trade),
    }));
    onSubmit(updates);
    captureAnalytics(analyticsKey("confirm"), {
      fromCategory: groupedTradeType,
      toCategory: trade,
    });
  };

  const functionChipPairs = getChipPairs([
    ...row.incoming,
    ...row.outgoing,
    ...row.fees,
  ]);

  return (
    <SmallCommandPaletteMenu
      options={options}
      selectedOptionLabel={getActionTypeName({ actionType: row.type, lang })}
      placeholder={
        lang.txTable.commandPallet.placeholders.tradeTypeSearch.group
      }
      onSelection={({ isOpenMore, trade }) => {
        if (isOpenMore) {
          openMoreOptions();
          return;
        }

        if (trade) {
          onSubmitWrapped(trade);
        }
      }}
      footerSection={
        functionChipPairs.length > 0 ? (
          <CategorisationFunctionFooter chipLinkPairs={functionChipPairs} />
        ) : undefined
      }
    />
  );
};

function getNewTradeForGivenGroup(
  groupType: GroupedTrade,
  oldTrade: Trade,
): Trade {
  const side = TradeInfo[oldTrade].direction;
  // e.g. Grouped, Trade == Buy or Sell based on side
  const propDirection: Extract<keyof ActionData, "inTrade" | "outTrade"> =
    side === TradeDirection.In ? ("inTrade" as const) : ("outTrade" as const);
  const newTrade = ActionDefinitions[groupType][propDirection];

  if (!newTrade) throw new Error("could not find definiton for given group");

  return newTrade;
}
