import {
  createContext,
  type PropsWithChildren,
  useEffect,
  useState,
} from "react";

import { EmailVerificationBanner } from "~/components/user/EmailVerificationBanner";
import { useUser } from "~/redux/auth";
import { verifyEmail } from "~/services/user";

type EmailVerificationContextType = {
  bannerIsOpen: boolean;
  setBannerIsOpen: (newContent: boolean) => void;
  setEmailVerificationToken: (token: string) => void;
  emailVerificationToken: string | undefined;
};

export const EmailVerificationContext =
  createContext<EmailVerificationContextType | null>(null);

export const EmailVerificationContextProvider = ({
  children,
}: PropsWithChildren) => {
  const user = useUser();
  const [bannerIsOpen, setBannerIsOpen] = useState<boolean>(false);
  const [emailVerificationToken, setEmailVerificationToken] =
    useState<string>();

  useEffect(() => {
    async function verifyEmailIfLoggedIn() {
      // if the user is logged in and we have a token, attempt to verify
      if (!user || !emailVerificationToken) return;
      const res = await verifyEmail({
        emailVerificationToken,
      });
      // yeet the token so we don't try it again
      setEmailVerificationToken(undefined);

      if (res.error) {
        console.error("failed to verify token");
      } else {
        setBannerIsOpen(true);
      }
    }
    verifyEmailIfLoggedIn();
    return () => {
      // cleanup
    };
  }, [user, emailVerificationToken]);
  return (
    <EmailVerificationContext.Provider
      value={{
        bannerIsOpen,
        setBannerIsOpen,
        emailVerificationToken,
        setEmailVerificationToken,
      }}
    >
      {bannerIsOpen && <EmailVerificationBanner />}
      {children}
    </EmailVerificationContext.Provider>
  );
};
