import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { toast, Toaster } from "sonner";
import { createGlobalStyle } from "styled-components";

import { useIsMobile } from "~/components/ui/hooks";
import { assertUnreachable } from "~/lib/assertUnreachable";
import { DisplayMessage } from "~/types/enums";
import { type DisplayMessageDetails } from "~/types/index";

const ToastStyles = createGlobalStyle`
  .toast-background {
    background-color: ${({ theme }) =>
      theme.tokens.background.accent.neutral.low};
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
    gap:0.5rem;
    padding:1rem 1.75rem 1rem 1rem
  }

  .default-toast{
    color: ${({ theme }) => theme.tokens.text.high};
    ${({ theme }) => ({ ...theme.mui.typography["Metropolis/Body/Bold"] })};
  }

  .success-toast {
    color: ${({ theme }) => theme.tokens.text.success};
    background-color: ${({ theme }) => theme.tokens.background.success.default};
    border: 1px solid ${({ theme }) => theme.tokens.background.accent.green.low};
  }

  .warning-toast {
    color: ${({ theme }) => theme.tokens.text.warning};
  }

  .error-toast {
    color: ${({ theme }) => theme.tokens.text.danger};
  }

  .toast-description{
    color: ${({ theme }) => theme.tokens.text.low};
    ${({ theme }) => ({ ...theme.mui.typography["Metropolis/Body/Regular"] })};

  }

  .toast-action-button {
    background-color: ${({ theme }) => theme.tokens.button.neutral.default} !important;
    border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default} !important;
    color: ${({ theme }) => theme.tokens.text.low} !important;
    :hover {
      color: ${({ theme }) => theme.tokens.text.high} !important;
    }
  }
  .toast-close-button {
    color: ${({ theme }) => theme.tokens.text.low} ;
    background-color: transparent ;
    border: none ;
    right: 0;
    left: auto;
    top: 45%;
    :hover {
      color: ${({ theme }) => theme.tokens.text.high};
      background-color: transparent;
      border: none;
    }
  }

`;

export const CustomToaster = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <ToastStyles />
      <Toaster
        position={isMobile ? "top-center" : "bottom-left"}
        visibleToasts={4}
        duration={10000}
        closeButton
        icons={{
          success: <CheckCircleOutlineOutlinedIcon />,
          warning: <ReportProblemOutlinedIcon />,
          error: <ErrorOutlineOutlinedIcon />,
        }}
        toastOptions={{
          classNames: {
            toast: "toast-background",
            description: "toast-description",
            success: "success-toast",
            warning: "warning-toast",
            error: "error-toast",
            default: "default-toast",
            actionButton: "toast-action-button",
            closeButton: "toast-close-button",
          },
        }}
      />
    </>
  );
};

export function displayMessage(message: DisplayMessageDetails) {
  if (!message.message) return;

  const toastOptions = {
    id:
      message.id ??
      (typeof message.message === "string"
        ? `toast-${message.type}-${message.message}`
        : undefined),
    action: message.action,
  };


  // Log errors to DataDog
  if (message.type === DisplayMessage.Error) {
    console.error("Error Toast Displayed", {
      message: message.message,
      toastId: toastOptions.id,
    });
  }

  switch (message.type) {
    case DisplayMessage.Success:
      toast.success(message.message, toastOptions);
      break;
    case DisplayMessage.Error:
      toast.error(message.message, toastOptions);
      break;
    case DisplayMessage.Info:
      toast(message.message, toastOptions);
      break;
    case DisplayMessage.Warning:
      toast.warning(message.message, toastOptions);
      break;
    default: {
      assertUnreachable(message.type, `Unhandled toast type`);
    }
  }
}
