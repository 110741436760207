import { SyncStatusPlatform } from "@ctc/types";
import { Box, Typography } from "@mui/material";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import isEqual from "lodash/isEqual";
import { memo, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { StringParam, useQueryParam } from "use-query-params";

import { useActionTableOptions } from "~/components/transactions/action-row/context/actionTableOptions";
import { useBreakdownsStore } from "~/components/transactions/BreakdownContext";
import { FilterActionType } from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { TransactionRowLoading } from "~/components/transactions/TransactionRowLoading";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { ContentPlaceholder } from "~/components/ui/ContentPlaceholder";
import { TransactionIcon } from "~/components/ui/Icons";
import { StyledLink } from "~/components/ui/StyledLink";
import { TRANSACTIONS_ROWS_PER_PAGE } from "~/constants/constants";
import { LocalStorageKey } from "~/constants/enums";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useTransactionsTotalCount } from "~/hooks/useTransactions";
import { getActionRowKey } from "~/lib/getActionRowKey";
import { useLang } from "~/redux/lang";
import { isOneToNGroupedTrade } from "~/services/transactions";
import { useGetActionsQuery } from "~/state/actions";
import { useUserSyncStatus } from "~/state/sync";
import { Links } from "~/types/enums";
import { type ActionRow } from "~/types/index";

const NonVirtualizedRows = (props: {
  tableData: ActionRow[];
  highlightQuery: string | null | undefined;
}) => {
  const { tableData, highlightQuery } = props;
  const isLoadingActions = useGetActionsQuery().isInitialLoading;

  useEffect(() => {
    if (!isLoadingActions && highlightQuery) {
      // TODO how to scroll to element if we are using txId for highlight
      const element = document.getElementById(highlightQuery);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    }
  }, [isLoadingActions, highlightQuery]);

  return (
    <Box>
      {tableData.map((row: ActionRow) => {
        return (
          <RowWrapper id={row._id} key={row._id}>
            {isLoadingActions ? (
              <TransactionRowLoading />
            ) : (
              <ActionRowMemo row={row} />
            )}
          </RowWrapper>
        );
      })}
    </Box>
  );
};
const VirtualizedRows = (props: {
  tableData: ActionRow[];
  highlightQuery: string | null | undefined;
  // The distance in pixels the table is from the top of the page
  distanceFromTopOfPage: number;
}) => {
  const { tableData, highlightQuery, distanceFromTopOfPage } = props;
  const isLoadingActions = useGetActionsQuery().isInitialLoading;
  const allBreakdownStatuses = useBreakdownsStore((state) => state.breakdowns);

  const virtualizer = useWindowVirtualizer({
    count: tableData.length,
    // estimated largest possible height of the row in pixels
    estimateSize: (index: number) => {
      const actionRow = tableData[index];
      const actionId = actionRow._id;
      const breakdownToggleStatus = allBreakdownStatuses[actionId];

      const addOnForBreakdownOpen = breakdownToggleStatus?.isOpen ? 400 : 0;
      if (isOneToNGroupedTrade(actionRow.type)) {
        const maxTxsPerSide = Math.max(
          actionRow.incoming.length,
          actionRow.outgoing.length,
        );
        return 60 * maxTxsPerSide + addOnForBreakdownOpen;
      }
      // how many txids are we showing (these are stacked)
      const txIdSet = new Set<string>();
      [...actionRow.incoming, ...actionRow.outgoing].forEach((tx) => tx.id);

      // showing multiple txids
      if (txIdSet.size > 1) {
        return 90 + addOnForBreakdownOpen;
      }

      // normal single row
      return 70 + addOnForBreakdownOpen;
    },
    scrollMargin: distanceFromTopOfPage,
    getItemKey: (index: number) => getActionRowKey(tableData[index]),
    overscan: 10,
  });
  const items = virtualizer.getVirtualItems();
  // end virtualiser

  useEffect(() => {
    let handler: number | undefined;

    if (!isLoadingActions && highlightQuery) {
      handler = window.setTimeout(() => {
        // virtualizer.
        const index = tableData.findIndex((row) => {
          const { incoming, outgoing, fees } = row;
          const txIds = [...incoming, ...outgoing, ...fees].map((tx) => tx._id);
          return (
            ("_id" in row && row._id === highlightQuery) ||
            txIds.includes(highlightQuery as string)
          );
        });
        // highlight action id is probably for an action on a different page,
        // so don't scroll
        if (index === -1) {
          return;
        }
        virtualizer.scrollToIndex(index, {
          align: "center",
          // cannot do smooth scrolling because it is not supported by the
          // library when you have dynamic heights
        });
      }, 500);
    }

    if (handler) {
      return () => {
        clearTimeout(handler);
      };
    }
  }, [isLoadingActions, highlightQuery, virtualizer]);

  if (!items.length) return null;

  return (
    <div className="List">
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
          zIndex: 1,
        }}
      >
        <div
          className="Absolute"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${
              items[0].start - virtualizer.options.scrollMargin
            }px)`,
          }}
        >
          {items.map((virtualRow) => {
            const index = virtualRow.index;
            const row = tableData[index];
            return (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                <RowWrapper id={row._id} key={row._id}>
                  {isLoadingActions ? (
                    <TransactionRowLoading />
                  ) : (
                    <ActionRowMemo row={row} />
                  )}
                </RowWrapper>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

/**
 * useCallback to stop unneccessary re-renders
 */
const ActionRows = memo(function NewTransactionRowsMemo(props: {
  tableData: ActionRow[];
  highlightQuery: string | null | undefined;
  isUsingVirtualizer: boolean;
  // The distance in pixels the table rows are from the top of the page
  distanceFromTopOfPage: number;
}) {
  const {
    tableData,
    highlightQuery,
    isUsingVirtualizer,
    distanceFromTopOfPage,
  } = props;
  if (isUsingVirtualizer) {
    return (
      <VirtualizedRows
        tableData={tableData}
        highlightQuery={highlightQuery}
        distanceFromTopOfPage={distanceFromTopOfPage}
      />
    );
  }
  return (
    <NonVirtualizedRows tableData={tableData} highlightQuery={highlightQuery} />
  );
});

export const ActionRowMemo = memo(function ActionRowMemo(props: {
  row: ActionRow;
}) {
  const { row } = props;
  const tableOptions = useActionTableOptions();
  if (!tableOptions.ActionRow) return null;
  return <tableOptions.ActionRow row={row} />;
}, isEqual);

const DEFAULT_LOADING_ROWS_COUNT = 5;

export function ActionTableContents({
  loadingRowsCount = DEFAULT_LOADING_ROWS_COUNT,
  enableVirtualiser = true,
  isLoading = false, // Show loading state on empty state instead of empty
  disableResetFilters = false,
  customEmptyMessage,
}: {
  loadingRowsCount?: number;
  enableVirtualiser?: boolean;
  isLoading?: boolean;
  disableResetFilters?: boolean;
  customEmptyMessage?: string;
}) {
  const lang = useLang();
  const { state, dispatch } = useTransactionFilter();
  const [highlightParam] = useQueryParam("highlight", StringParam);
  const totalCount = useTransactionsTotalCount();

  const actionsQueryQuery = useGetActionsQuery();
  const tableData = actionsQueryQuery.data?.transactions ?? [];
  const totalFilteredCount = actionsQueryQuery.data?.total ?? 0;
  const isLoadingActions = actionsQueryQuery.isInitialLoading;

  const syncStatus = useUserSyncStatus();
  const isDoingReportRefresh = syncStatus === SyncStatusPlatform.Pending;
  const isEmpty = tableData.length === 0;

  // Determines if empty table length was caused by a bulk delete of all
  // visible transactions, used to prevent an incorrect "no data" message.
  // Refreshing table after bulk delete all rows on a single page.
  const isDeletingAllVisibleRows =
    isEmpty && totalFilteredCount > (state.count || TRANSACTIONS_ROWS_PER_PAGE);

  // If the tx count is 0, but there is a report refresh, likely we are
  // waiting for the report refresh to finish so we have the actions to display
  const isAwaitingActions = totalFilteredCount === 0 && isDoingReportRefresh;

  const showSkeletons =
    isLoading ||
    isLoadingActions ||
    isDeletingAllVisibleRows ||
    isAwaitingActions;

  const smoothScrollEnabledDefault = undefined;
  const [smoothScrollEnabledPreference] = useLocalStorage<boolean | undefined>(
    LocalStorageKey.SmoothScroll,
    undefined,
  );

  const isUsingVirtualiser = !enableVirtualiser
    ? false
    : smoothScrollEnabledPreference === smoothScrollEnabledDefault
      ? enableVirtualiser
      : smoothScrollEnabledPreference;

  // The ref to the wrapper of the whole table
  // Used in the `scrollTo` feature to work out how far the table is
  // from the top of the page
  const parentRef = useRef<HTMLDivElement>(null);
  const rect = parentRef.current?.getBoundingClientRect();
  // the scroll distance + the distance from the top of the browser window
  // gives us the y coordinate of the top of the wrapper div
  const rectTop = rect?.top ?? 0;
  const distanceFromTopOfPage = window.scrollY + rectTop;

  return (
    <Box style={{ marginTop: "-1px" }} ref={parentRef}>
      {isEmpty ? (
        showSkeletons ? (
          // We have no data, and we are loading or delete
          // so show skeleton screens
          [
            ...Array(Math.min(loadingRowsCount, DEFAULT_LOADING_ROWS_COUNT)),
          ].map((row, i) => (
            <RowWrapper key={i}>
              <TransactionRowLoading />
            </RowWrapper>
          ))
        ) : (
          // We arent loading, so show the empty screen
          <RowWrapper
            style={{
              width: "100%",
              height: "60vh",
            }}
          >
            <Box
              style={{
                position: "absolute",
                display: "flex",
                top: 30,
                bottom: 52,
                paddingLeft: 50,
                paddingRight: 50,
                width: "100%",
                textAlign: "center",
              }}
            >
              {totalCount ? (
                <ContentPlaceholder>
                  <TransactionIcon />
                  <Typography variant="Metropolis/Header/H5">
                    {customEmptyMessage ?? lang.txTable.noTxFound}
                  </Typography>
                  {!disableResetFilters ? (
                    <Typography variant="Metropolis/Body/Light">
                      <TextIconButton
                        style={{ fontSize: "0.875rem" }}
                        onClick={() => {
                          dispatch({
                            type: FilterActionType.ResetFilter,
                          });
                        }}
                      >
                        {lang.txTable.filter.resetFilters}
                      </TextIconButton>
                    </Typography>
                  ) : null}
                </ContentPlaceholder>
              ) : (
                // No results when not filtered
                <ContentPlaceholder>
                  <TransactionIcon />
                  <Typography variant="Metropolis/Header/H5">
                    {lang.txTable.noData}
                  </Typography>
                  <Typography variant="Metropolis/Body/Light">
                    {lang.txTable.allTxs}{" "}
                    <StyledLink to={Links.Imports}>
                      {lang.txTable.importData}
                    </StyledLink>
                    .
                  </Typography>
                </ContentPlaceholder>
              )}
            </Box>
          </RowWrapper>
        )
      ) : (
        // We have data, so show the rows
        <>
          <ActionRows
            isUsingVirtualizer={isUsingVirtualiser}
            highlightQuery={highlightParam}
            tableData={tableData}
            distanceFromTopOfPage={distanceFromTopOfPage}
          />
          {/* Only show the pagination if there are pages at the minimum page size */}
        </>
      )}
    </Box>
  );
}

const RowWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  scroll-margin-top: 6.2rem;
`;
