/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/dot-notation */
/*
 * DO NOT EDIT THIS FILE MANUALLY
 * IT IS AUTOMATICALLY GENERATED AND CHANGES WILL BE OVERWRITTEN
 */

export interface FigmaTypographyVariants {
  "Metropolis/Body/Bold": React.CSSProperties;
  "Metropolis/Body/Light": React.CSSProperties;
  "Metropolis/Body/Regular": React.CSSProperties;
  "Metropolis/Header/H1": React.CSSProperties;
  "Metropolis/Header/H2": React.CSSProperties;
  "Metropolis/Header/H3": React.CSSProperties;
  "Metropolis/Header/H4": React.CSSProperties;
  "Metropolis/Header/H5": React.CSSProperties;
  "Metropolis/Caption/Large/Bold": React.CSSProperties;
  "Metropolis/Caption/Large/Regular": React.CSSProperties;
  "Metropolis/Caption/Small/Bold": React.CSSProperties;
  "Metropolis/Caption/Small/Regular": React.CSSProperties;
  "Metropolis/Caption/Medium/Bold": React.CSSProperties;
  "Metropolis/Caption/Medium/Regular": React.CSSProperties;
  "IBM Plex Mono/Body/Bold": React.CSSProperties;
  "IBM Plex Mono/Body/Light": React.CSSProperties;
  "IBM Plex Mono/Body/Regular": React.CSSProperties;
  "IBM Plex Mono/Header/H1": React.CSSProperties;
  "IBM Plex Mono/Header/H2": React.CSSProperties;
  "IBM Plex Mono/Header/H3": React.CSSProperties;
  "IBM Plex Mono/Header/H4": React.CSSProperties;
  "IBM Plex Mono/Header/H5": React.CSSProperties;
  "IBM Plex Mono/Header/Section Title": React.CSSProperties;
  "IBM Plex Mono/Caption/Large/Bold": React.CSSProperties;
  "IBM Plex Mono/Caption/Large/Regular": React.CSSProperties;
  "IBM Plex Mono/Caption/Small/Bold": React.CSSProperties;
  "IBM Plex Mono/Caption/Small/Regular": React.CSSProperties;
  "IBM Plex Mono/Caption/Medium/Bold": React.CSSProperties;
  "IBM Plex Mono/Caption/Medium/Regular": React.CSSProperties;
}

export interface FigmaTypographyVariantsOptions {
  "Metropolis/Body/Bold"?: React.CSSProperties;
  "Metropolis/Body/Light"?: React.CSSProperties;
  "Metropolis/Body/Regular"?: React.CSSProperties;
  "Metropolis/Header/H1"?: React.CSSProperties;
  "Metropolis/Header/H2"?: React.CSSProperties;
  "Metropolis/Header/H3"?: React.CSSProperties;
  "Metropolis/Header/H4"?: React.CSSProperties;
  "Metropolis/Header/H5"?: React.CSSProperties;
  "Metropolis/Caption/Large/Bold"?: React.CSSProperties;
  "Metropolis/Caption/Large/Regular"?: React.CSSProperties;
  "Metropolis/Caption/Small/Bold"?: React.CSSProperties;
  "Metropolis/Caption/Small/Regular"?: React.CSSProperties;
  "Metropolis/Caption/Medium/Bold"?: React.CSSProperties;
  "Metropolis/Caption/Medium/Regular"?: React.CSSProperties;
  "IBM Plex Mono/Body/Bold"?: React.CSSProperties;
  "IBM Plex Mono/Body/Light"?: React.CSSProperties;
  "IBM Plex Mono/Body/Regular"?: React.CSSProperties;
  "IBM Plex Mono/Header/H1"?: React.CSSProperties;
  "IBM Plex Mono/Header/H2"?: React.CSSProperties;
  "IBM Plex Mono/Header/H3"?: React.CSSProperties;
  "IBM Plex Mono/Header/H4"?: React.CSSProperties;
  "IBM Plex Mono/Header/H5"?: React.CSSProperties;
  "IBM Plex Mono/Header/Section Title"?: React.CSSProperties;
  "IBM Plex Mono/Caption/Large/Bold"?: React.CSSProperties;
  "IBM Plex Mono/Caption/Large/Regular"?: React.CSSProperties;
  "IBM Plex Mono/Caption/Small/Bold"?: React.CSSProperties;
  "IBM Plex Mono/Caption/Small/Regular"?: React.CSSProperties;
  "IBM Plex Mono/Caption/Medium/Bold"?: React.CSSProperties;
  "IBM Plex Mono/Caption/Medium/Regular"?: React.CSSProperties;
}

export interface FigmaTypographyPropsVariantOverrides {
  "Metropolis/Body/Bold": true;
  "Metropolis/Body/Light": true;
  "Metropolis/Body/Regular": true;
  "Metropolis/Header/H1": true;
  "Metropolis/Header/H2": true;
  "Metropolis/Header/H3": true;
  "Metropolis/Header/H4": true;
  "Metropolis/Header/H5": true;
  "Metropolis/Caption/Large/Bold": true;
  "Metropolis/Caption/Large/Regular": true;
  "Metropolis/Caption/Small/Bold": true;
  "Metropolis/Caption/Small/Regular": true;
  "Metropolis/Caption/Medium/Bold": true;
  "Metropolis/Caption/Medium/Regular": true;
  "IBM Plex Mono/Body/Bold": true;
  "IBM Plex Mono/Body/Light": true;
  "IBM Plex Mono/Body/Regular": true;
  "IBM Plex Mono/Header/H1": true;
  "IBM Plex Mono/Header/H2": true;
  "IBM Plex Mono/Header/H3": true;
  "IBM Plex Mono/Header/H4": true;
  "IBM Plex Mono/Header/H5": true;
  "IBM Plex Mono/Header/Section Title": true;
  "IBM Plex Mono/Caption/Large/Bold": true;
  "IBM Plex Mono/Caption/Large/Regular": true;
  "IBM Plex Mono/Caption/Small/Bold": true;
  "IBM Plex Mono/Caption/Small/Regular": true;
  "IBM Plex Mono/Caption/Medium/Bold": true;
  "IBM Plex Mono/Caption/Medium/Regular": true;
}

export const paletteBinanceLight = {
  red: {
    ["100"]: "#ffedeb",
    ["200"]: "#ffd2cc",
    ["300"]: "#ff9c8f",
    ["400"]: "#f87462",
    ["500"]: "#ef5c48",
    ["600"]: "#e34935",
    ["700"]: "#ca3521",
    ["800"]: "#ae2a19",
    ["900"]: "#601e16",
    ["1000"]: "#391813",
  },
  grey: {
    ["100"]: "#f9fafb",
    ["200"]: "#f3f4f6",
    ["300"]: "#e5e7eb",
    ["400"]: "#d1d5db",
    ["500"]: "#b3b9c4",
    ["600"]: "#8993a5",
    ["700"]: "#758195",
    ["800"]: "#626f86",
    ["900"]: "#44546f",
    ["1000"]: "#2c3e5d",
    ["1100"]: "#172b4d",
    ["1200"]: "#091e42",
    ["1300"]: "#06152e",
    ["1400"]: "#040e1f",
  },
  alpha: {
    ["10"]: "#d1d5db1a",
    ["20"]: "#d1d5db33",
    ["40"]: "#fafafa66",
    ["60"]: "#fafafa99",
  },
  brand: {
    ["50"]: "#FDF8E7",
    ["100"]: "#FBEEC2",
    ["200"]: "#F9E39D",
    ["300"]: "#F6D978",
    ["400"]: "#F4CE54",
    ["500"]: "#EFB90A",
    ["600"]: "#D7A709",
    ["700"]: "#C99402",
    ["800"]: "#A78207",
    ["900"]: "#8F6F06",
    ["1000"]: "#785D05",
  },
  green: {
    ["100"]: "#dffcf0",
    ["200"]: "#baf3db",
    ["300"]: "#7ee2b8",
    ["400"]: "#4bce97",
    ["500"]: "#2abb7f",
    ["600"]: "#22a06b",
    ["700"]: "#1f845a",
    ["800"]: "#216e4e",
    ["900"]: "#164b35",
    ["1000"]: "#133527",
  },
  orange: {
    ["100"]: "#fff4e5",
    ["200"]: "#ffe2bd",
    ["300"]: "#fec57b",
    ["400"]: "#faa53d",
    ["500"]: "#f18d13",
    ["600"]: "#d97008",
    ["700"]: "#b65c02",
    ["800"]: "#974f0c",
    ["900"]: "#5f3811",
    ["1000"]: "#43290f",
  },
  yellow: {
    ["100"]: "#fff7d6",
    ["200"]: "#f8e6a0",
    ["300"]: "#f5cd47",
    ["400"]: "#e2b203",
    ["500"]: "#cf9f02",
    ["600"]: "#b38600",
    ["700"]: "#946f00",
    ["800"]: "#9f8741",
    ["900"]: "#533f04",
    ["1000"]: "#3d2e00",
  },
  neutral: {
    ["100"]: "#FAFAFA",
    ["200"]: "#F4F4F4",
    ["300"]: "#EFEFEF",
    ["400"]: "#E4E4E4",
    ["500"]: "#D2D2D2",
    ["600"]: "#C0C0C0",
    ["700"]: "#ABABAB",
    ["800"]: "#949494",
    ["900"]: "#787878",
    ["1000"]: "#595959",
    ["1100"]: "#373737",
    ["1200"]: "#252525",
    ["1300"]: "#121212",
    ["1400"]: "#0a0000",
    ["000"]: "#ffffff",
  },
  special: {
    ["100"]: "#0c0420",
  },
};

export const binanceLight = {
  xl: "16px",
  ["2Xl"]: "24px",
  icon: {
    low: "#787878",
    high: "#121212",
    brand: "#EFB90A",
    white: "#181A1F",
    danger: "#ae2a19",
    default: "#373737",
    inverse: "#ffffff",
    success: "#216e4e",
    warning: "#974f0c",
    disabled: "#ABABAB",
  },
  text: {
    low: "#787878",
    high: "#121212",
    brand: "#C99402",
    white: "#181A1F",
    danger: "#ae2a19",
    default: "#373737",
    inverse: "#ffffff",
    success: "#216e4e",
    warning: "#974f0c",
    disabled: "#949494",
  },
  large: "12px",
  small: "4px",
  border: {
    brand: "#D7A709",
    danger: "#ca3521",
    neutral: {
      low: "#F4F4F4",
      high: "#D2D2D2",
      medium: "#EFEFEF",
      default: "#E4E4E4",
      highest: "#C0C0C0",
    },
    success: "#22a06b",
    warning: "#d97008",
    disabled: "#EFEFEF",
  },
  button: {
    brand: {
      hover: "#F4CE54",
      default: "#EFB90A",
      pressed: "#F6D978",
    },
    danger: {
      hover: "#ae2a19",
      default: "#ca3521",
      pressed: "#601e16",
    },
    subtle: {
      hover: "#E4E4E4",
      default: "#EFEFEF",
      pressed: "#D2D2D2",
    },
    neutral: {
      hover: "#EFEFEF",
      default: "#FAFAFA",
      pressed: "#E4E4E4",
    },
    success: {
      hover: "#216e4e",
      default: "#1f845a",
      pressed: "#164b35",
    },
    warning: {
      hover: "#cf9f02",
      default: "#e2b203",
      pressed: "#b38600",
    },
    disabled: "#EFEFEF",
  },
  medium: "8px",
  fontSize: {
    ["0"]: "10px",
    ["1"]: "12px",
    ["2"]: "13px",
    ["3"]: "14px",
    ["4"]: "16px",
    ["5"]: "20px",
    ["6"]: "24px",
    ["7"]: "28px",
    ["8"]: "32px",
  },
  textCase: {
    none: "none",
    uppercase: "uppercase",
  },
  elevation: {
    low: "#ffffff",
    high: "#FAFAFA",
    medium: "#ffffff",
    default: "#ffffff",
    highest: "#F4F4F4",
  },
  background: {
    brand: {
      hover: "#F9E39D",
      default: "#FBEEC2",
      pressed: "#F6D978",
    },
    input: {
      hover: "#FAFAFA",
      default: "#ffffff",
      pressed: "#ffffff",
    },
    accent: {
      red: {
        low: "#ffd2cc",
        high: "#ca3521",
        medium: "#f87462",
        lowest: "#ffedeb",
        highest: "#ae2a19",
      },
      green: {
        low: "#baf3db",
        high: "#1f845a",
        medium: "#4bce97",
        lowest: "#dffcf0",
        highest: "#216e4e",
      },
      orange: {
        low: "#ffe2bd",
        high: "#b65c02",
        medium: "#faa53d",
        lowest: "#fff4e5",
        highest: "#974f0c",
      },
      purple: {
        low: "#F6D978",
        high: "#D7A709",
        medium: "#EFB90A",
        lowest: "#FDF8E7",
        highest: "#C99402",
      },
      yellow: {
        low: "#f8e6a0",
        high: "#946f00",
        medium: "#e2b203",
        lowest: "#fff7d6",
        highest: "#9f8741",
      },
      neutral: {
        low: "#EFEFEF",
        high: "#C0C0C0",
        medium: "#D2D2D2",
        lowest: "#FAFAFA",
        highest: "#949494",
      },
    },
    danger: {
      high: "#f87462",
      hover: "#ffd2cc",
      default: "#ffedeb",
      pressed: "#ff9c8f",
    },
    neutral: {
      hover: "#EFEFEF",
      lowest: {
        hover: "#FAFAFA",
        default: "#ffffff",
        pressed: "#F4F4F4",
      },
      default: "#F4F4F4",
      pressed: "#E4E4E4",
    },
    overlay: "#00000059",
    success: {
      hover: "#baf3db",
      default: "#dffcf0",
      pressed: "#7ee2b8",
    },
    warning: {
      hover: "#f8e6a0",
      default: "#fff7d6",
      pressed: "#f5cd47",
    },
    disabled: "#EFEFEF",
  },
  special: {
    lowest: "#0c0420",
    tooltip: "#D2D2D2",
    tooltipText: "#121212",
    tooltipLinkText: "#C99402",
  },
  Metropolis: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "-2%",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "-2%",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "-2%",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0.34",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.32",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  fontWeights: {
    metropolis0: 600,
    metropolis1: 500,
    metropolis2: 400,
    ibmPlexMono3: 600,
    ibmPlexMono4: 400,
    ibmPlexMono5: 500,
  },
  lineHeights: {
    ["0"]: "40px",
    ["1"]: "36px",
    ["2"]: "32px",
    ["3"]: "28px",
    ["4"]: "24px",
    ["5"]: "20px",
    ["6"]: "16px",
    ["7"]: "12px",
  },
  cardPrimary: "16px",
  fontFamilies: {
    text: "Metropolis",
    numeric: "ibm-plex-mono",
  },
  ["IBM Plex Mono"]: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      ["Section Title"]: {
        fontSize: "13px",
        textCase: "uppercase",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  primaryButton: "4px",
  letterSpacing: {
    ["0"]: "-2%",
    ["1"]: "0.34",
    ["2"]: "-0.32",
    ["3"]: "0",
    ["4"]: "0%",
  },
  tertiaryButton: "4px",
  textDecoration: {
    none: "none",
  },
  secondaryButton: "4px",
  paragraphIndent: {
    ["0"]: "0px",
  },
  paragraphSpacing: {
    ["0"]: "0",
  },
  buttons: {
    primary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
    secondary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
    tertiary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
  },
};

export const paletteCoinbaseLight = {
  red: {
    ["100"]: "#fcf2f3",
    ["200"]: "#f7d5d8",
    ["300"]: "#f1b9be",
    ["400"]: "#e88f97",
    ["500"]: "#e06470",
    ["600"]: "#d73847",
    ["700"]: "#8e1d28",
    ["800"]: "#64141c",
    ["900"]: "#3a0c10",
    ["1000"]: "#3a0c10",
  },
  grey: {
    ["100"]: "#f9fafb",
    ["200"]: "#f3f4f6",
    ["300"]: "#e5e7eb",
    ["400"]: "#d1d5db",
    ["500"]: "#b3b9c4",
    ["600"]: "#8993a5",
    ["700"]: "#758195",
    ["800"]: "#626f86",
    ["900"]: "#44546f",
    ["1000"]: "#2c3e5d",
    ["1100"]: "#172b4d",
    ["1200"]: "#091e42",
    ["1300"]: "#06152e",
    ["1400"]: "#040e1f",
  },
  alpha: {
    ["10"]: "#d1d5db1a",
    ["20"]: "#d1d5db33",
    ["40"]: "#fafafa66",
    ["60"]: "#fafafa99",
  },
  brand: {
    ["50"]: "#e6eeff",
    ["100"]: "#e9f0fe",
    ["200"]: "#bdd2ff",
    ["300"]: "#94b6ff",
    ["400"]: "#6b9bff",
    ["500"]: "#427fff",
    ["600"]: "#1a63ff",
    ["700"]: "#0052ff",
    ["800"]: "#0040c7",
    ["900"]: "#00339e",
    ["1000"]: "#002675",
  },
  green: {
    ["100"]: "#f3fcf5",
    ["200"]: "#d6ffdf",
    ["300"]: "#afecbd",
    ["400"]: "#5dd879",
    ["500"]: "#28a745",
    ["600"]: "#1c8234",
    ["700"]: "#145523",
    ["800"]: "#0a2c12",
    ["900"]: "#041007",
    ["1000"]: "#041007",
  },
  orange: {
    ["100"]: "#fef5ef",
    ["200"]: "#fde2cf",
    ["300"]: "#fbc49d",
    ["400"]: "#faa66c",
    ["500"]: "#f8883b",
    ["600"]: "#f66a0a",
    ["700"]: "#bf5208",
    ["800"]: "#954005",
    ["900"]: "#632b04",
    ["1000"]: "#321602",
  },
  yellow: {
    ["100"]: "#fefcde",
    ["200"]: "#fff2c5",
    ["300"]: "#ffdf70",
    ["400"]: "#ffc70a",
    ["500"]: "#f8883b",
    ["600"]: "#f66a0a",
    ["700"]: "#bf5208",
    ["800"]: "#954005",
    ["900"]: "#632b04",
    ["1000"]: "#321602",
  },
  neutral: {
    ["100"]: "#f6f7f9",
    ["200"]: "#eff1f4",
    ["300"]: "#eceef1",
    ["400"]: "#d7d8db",
    ["500"]: "#c5c6c9",
    ["600"]: "#afb0b2",
    ["700"]: "#959698",
    ["800"]: "#797a7c",
    ["900"]: "#5c5d5e",
    ["1000"]: "#3f3f40",
    ["1100"]: "#24272a",
    ["1200"]: "#222323",
    ["1300"]: "#141618",
    ["1400"]: "#000000",
    ["000"]: "#ffffff",
  },
  special: {
    ["100"]: "#0c0420",
  },
};

export const coinbaseLight = {
  xl: "16px",
  ["2Xl"]: "24px",
  icon: {
    low: "#758195",
    high: "#06152e",
    brand: "#0052ff",
    white: "#ffffff",
    danger: "#64141c",
    default: "#172b4d",
    inverse: "#ffffff",
    success: "#0a2c12",
    warning: "#954005",
    disabled: "#b3b9c4",
  },
  text: {
    low: "#797a7c",
    high: "#000000",
    brand: "#0052ff",
    white: "#ffffff",
    danger: "#8e1d28",
    default: "#141618",
    inverse: "#ffffff",
    success: "#145523",
    warning: "#bf5208",
    disabled: "#959698",
  },
  large: "12px",
  small: "4px",
  border: {
    brand: "#0052ff",
    danger: "#8e1d28",
    neutral: {
      low: "#f6f7f9",
      high: "#d7d8db",
      medium: "#eff1f4",
      default: "#eceef1",
      highest: "#afb0b2",
    },
    success: "#1c8234",
    warning: "#f66a0a",
    disabled: "#eff1f4",
  },
  button: {
    brand: {
      hover: "#0040c7",
      default: "#0052ff",
      pressed: "#00339e",
    },
    danger: {
      hover: "#64141c",
      default: "#8e1d28",
      pressed: "#3a0c10",
    },
    subtle: {
      hover: "#eff1f4",
      default: "#f6f7f9",
      pressed: "#eceef1",
    },
    neutral: {
      hover: "#eff1f4",
      default: "#f6f7f9",
      pressed: "#eceef1",
    },
    success: {
      hover: "#145523",
      default: "#1c8234",
      pressed: "#0a2c12",
    },
    warning: {
      hover: "#f8883b",
      default: "#ffc70a",
      pressed: "#f66a0a",
    },
    disabled: "#eceef1",
  },
  medium: "8px",
  fontSize: {
    ["0"]: "10px",
    ["1"]: "12px",
    ["2"]: "13px",
    ["3"]: "14px",
    ["4"]: "16px",
    ["5"]: "20px",
    ["6"]: "24px",
    ["7"]: "28px",
    ["8"]: "32px",
  },
  textCase: {
    none: "none",
    uppercase: "uppercase",
  },
  elevation: {
    low: "#ffffff",
    high: "#eff1f4",
    medium: "#f6f7f9",
    default: "#ffffff",
    highest: "#eceef1",
  },
  background: {
    brand: {
      hover: "#bdd2ff",
      default: "#e9f0fe",
      pressed: "#94b6ff",
    },
    input: {
      hover: "#eff1f4",
      default: "#f6f7f9",
      pressed: "#eceef1",
    },
    accent: {
      red: {
        low: "#f7d5d8",
        high: "#8e1d28",
        medium: "#e88f97",
        lowest: "#fcf2f3",
        highest: "#64141c",
      },
      green: {
        low: "#d6ffdf",
        high: "#145523",
        medium: "#5dd879",
        lowest: "#f3fcf5",
        highest: "#0a2c12",
      },
      orange: {
        low: "#fde2cf",
        high: "#bf5208",
        medium: "#faa66c",
        lowest: "#fef5ef",
        highest: "#954005",
      },
      purple: {
        low: "#bdd2ff",
        high: "#0052ff",
        medium: "#6b9bff",
        lowest: "#e6eeff",
        highest: "#0040c7",
      },
      yellow: {
        low: "#fff2c5",
        high: "#bf5208",
        medium: "#ffc70a",
        lowest: "#fefcde",
        highest: "#954005",
      },
      neutral: {
        low: "#eceef1",
        high: "#afb0b2",
        medium: "#c5c6c9",
        lowest: "#f6f7f9",
        highest: "#797a7c",
      },
    },
    danger: {
      high: "#e88f97",
      hover: "#f7d5d8",
      default: "#fcf2f3",
      pressed: "#f1b9be",
    },
    neutral: {
      hover: "#eff1f4",
      lowest: {
        hover: "#f6f7f9",
        default: "#ffffff",
        pressed: "#eff1f4",
      },
      default: "#f6f7f9",
      pressed: "#eceef1",
    },
    overlay: "#00000059",
    success: {
      hover: "#5dd879",
      default: "#afecbd",
      pressed: "#28a745",
    },
    warning: {
      hover: "#fff2c5",
      default: "#fefcde",
      pressed: "#ffdf70",
    },
    disabled: "#eceef1",
  },
  Metropolis: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "-2%",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "-2%",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "-2%",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0.34",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.32",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 700,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 700,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  fontWeights: {
    metropolis0: 600,
    metropolis1: 500,
    metropolis2: 400,
    coinbaseSans6: 700,
    ibmPlexMono3: 600,
    ibmPlexMono4: 400,
    ibmPlexMono5: 500,
  },
  lineHeights: {
    ["0"]: "40px",
    ["1"]: "36px",
    ["2"]: "32px",
    ["3"]: "28px",
    ["4"]: "24px",
    ["5"]: "20px",
    ["6"]: "16px",
    ["7"]: "12px",
  },
  cardPrimary: "16px",
  fontFamilies: {
    text: "Metropolis",
    numeric: "ibm-plex-mono",
  },
  ["IBM Plex Mono"]: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 500,
        lineHeight: "40px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 500,
        lineHeight: "36px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 500,
        lineHeight: "32px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 500,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "Coinbase Sans",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      ["Section Title"]: {
        fontSize: "13px",
        textCase: "uppercase",
        fontFamily: "Coinbase Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Coinbase Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  primaryButton: "999px",
  letterSpacing: {
    ["0"]: "-2%",
    ["1"]: "0.34",
    ["2"]: "-0.32",
    ["3"]: "0",
    ["4"]: "0%",
  },
  tertiaryButton: "4px",
  textDecoration: {
    none: "none",
  },
  secondaryButton: "4px",
  paragraphIndent: {
    ["0"]: "0px",
  },
  paragraphSpacing: {
    ["0"]: "0",
  },
  special: {
    lowest: "#0c0420",
    tooltip: "#c5c6c9",
    tooltipText: "#000000",
    tooltipLinkText: "#0052ff",
  },
  buttons: {
    primary: {
      borderRadius: "999px",
      alignment: "center",
      asideAlignment: "center",
    },
    secondary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
    tertiary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
  },
};

export const paletteCtcDark = {
  red: {
    ["100"]: "#ffedeb",
    ["200"]: "#ffd2cc",
    ["300"]: "#ff9c8f",
    ["400"]: "#f87462",
    ["500"]: "#ef5c48",
    ["600"]: "#e34935",
    ["700"]: "#ca3521",
    ["800"]: "#ae2a19",
    ["900"]: "#601e16",
    ["1000"]: "#391813",
  },
  grey: {
    ["100"]: "#151417",
    ["200"]: "#161a1d",
    ["300"]: "#1d2125",
    ["400"]: "#22272b",
    ["500"]: "#2c333a",
    ["600"]: "#454f59",
    ["700"]: "#596773",
    ["800"]: "#738496",
    ["900"]: "#8696a7",
    ["1000"]: "#9fadbc",
    ["1100"]: "#b6c2cf",
    ["1200"]: "#c7d1db",
    ["1300"]: "#dee4ea",
    ["1400"]: "#e8edf2",
  },
  alpha: {
    ["10"]: "#3741511a",
    ["20"]: "#2c313833",
    ["40"]: "#0d0d0d66",
    ["60"]: "#0d0d0d99",
  },
  brand: {
    ["50"]: "#f5f3ff",
    ["100"]: "#ede9fe",
    ["200"]: "#e3d6fe",
    ["300"]: "#cdabf7",
    ["400"]: "#b789f4",
    ["500"]: "#9f68f1",
    ["600"]: "#7c3aed",
    ["700"]: "#5f19db",
    ["800"]: "#42109a",
    ["900"]: "#240a53",
    ["1000"]: "#1a0634",
  },
  green: {
    ["100"]: "#dffcf0",
    ["200"]: "#baf3db",
    ["300"]: "#7ee2b8",
    ["400"]: "#4bce97",
    ["500"]: "#2abb7f",
    ["600"]: "#22a06b",
    ["700"]: "#1f845a",
    ["800"]: "#216e4e",
    ["900"]: "#164b35",
    ["1000"]: "#133527",
  },
  orange: {
    ["100"]: "#fff4e5",
    ["200"]: "#ffe2bd",
    ["300"]: "#fec57b",
    ["400"]: "#faa53d",
    ["500"]: "#f18d13",
    ["600"]: "#d97008",
    ["700"]: "#b65c02",
    ["800"]: "#974f0c",
    ["900"]: "#5f3811",
    ["1000"]: "#43290f",
  },
  yellow: {
    ["100"]: "#fff7d6",
    ["200"]: "#f8e6a0",
    ["300"]: "#f5cd47",
    ["400"]: "#e2b203",
    ["500"]: "#cf9f02",
    ["600"]: "#b38600",
    ["700"]: "#946f00",
    ["800"]: "#7f5f01",
    ["900"]: "#533f04",
    ["1000"]: "#3d2e00",
  },
  neutral: {
    ["100"]: "#141117",
    ["200"]: "#19151d",
    ["300"]: "#1e1924",
    ["400"]: "#221d2b",
    ["500"]: "#2a2436",
    ["600"]: "#3b3247",
    ["700"]: "#554f5c",
    ["800"]: "#71697a",
    ["900"]: "#8d8499",
    ["1000"]: "#a499b2",
    ["1100"]: "#bcafcc",
    ["1200"]: "#d3c5e5",
    ["1300"]: "#eae1f5",
    ["1400"]: "#f1ebfa",
    ["000"]: "#0e0b0f",
  },
  special: {
    ["100"]: "#0c0420",
  },
};

export const ctcDark = {
  xl: "16px",
  ["2Xl"]: "24px",
  icon: {
    low: "#8696a7",
    high: "#dee4ea",
    brand: "#cdabf7",
    white: "#ffffff",
    danger: "#ff9c8f",
    default: "#b6c2cf",
    inverse: "#0e0b0f",
    success: "#7ee2b8",
    warning: "#f5cd47",
    disabled: "#596773",
  },
  text: {
    low: "#8696a7",
    high: "#e8edf2",
    brand: "#cdabf7",
    white: "#ffffff",
    danger: "#ff9c8f",
    default: "#c7d1db",
    inverse: "#0e0b0f",
    success: "#7ee2b8",
    warning: "#f5cd47",
    disabled: "#596773",
  },
  large: "12px",
  small: "4px",
  border: {
    brand: "#b789f4",
    danger: "#ef5c48",
    neutral: {
      low: "#19151d",
      high: "#2a2436",
      medium: "#1e1924",
      default: "#221d2b",
      highest: "#3b3247",
    },
    success: "#2abb7f",
    warning: "#cf9f02",
    disabled: "#1e1924",
  },
  button: {
    brand: {
      hover: "#9f68f1",
      default: "#7c3aed",
      pressed: "#b789f4",
    },
    danger: {
      hover: "#ff9c8f",
      default: "#f87462",
      pressed: "#ffd2cc",
    },
    subtle: {
      hover: "#42109a",
      default: "#240a53",
      pressed: "#5f19db",
    },
    neutral: {
      hover: "#19151d",
      default: "#141117",
      pressed: "#1e1924",
    },
    success: {
      hover: "#7ee2b8",
      default: "#4bce97",
      pressed: "#baf3db",
    },
    warning: {
      hover: "#f5cd47",
      default: "#e2b203",
      pressed: "#f8e6a0",
    },
    disabled: "#1e1924",
  },
  medium: "8px",
  fontSize: {
    ["0"]: "10px",
    ["1"]: "12px",
    ["2"]: "13px",
    ["3"]: "14px",
    ["4"]: "16px",
    ["5"]: "20px",
    ["6"]: "24px",
    ["7"]: "28px",
    ["8"]: "32px",
  },
  textCase: {
    none: "none",
    uppercase: "uppercase",
  },
  elevation: {
    low: "#141117",
    high: "#1e1924",
    medium: "#19151d",
    default: "#0e0b0f",
    highest: "#221d2b",
  },
  background: {
    brand: {
      hover: "#42109a",
      default: "#240a53",
      pressed: "#5f19db",
    },
    input: {
      hover: "#19151d",
      default: "#141117",
      pressed: "#141117",
    },
    accent: {
      red: {
        low: "#601e16",
        high: "#e34935",
        medium: "#ae2a19",
        lowest: "#391813",
        highest: "#ff9c8f",
      },
      green: {
        low: "#164b35",
        high: "#4bce97",
        medium: "#1f845a",
        lowest: "#133527",
        highest: "#baf3db",
      },
      orange: {
        low: "#5f3811",
        high: "#faa53d",
        medium: "#b65c02",
        lowest: "#43290f",
        highest: "#ffe2bd",
      },
      purple: {
        low: "#42109a",
        high: "#b789f4",
        medium: "#7c3aed",
        lowest: "#1a0634",
        highest: "#e3d6fe",
      },
      yellow: {
        low: "#533f04",
        high: "#e2b203",
        medium: "#946f00",
        lowest: "#3d2e00",
        highest: "#f8e6a0",
      },
      neutral: {
        low: "#1e1924",
        high: "#3b3247",
        medium: "#2a2436",
        lowest: "#19151d",
        highest: "#71697a",
      },
    },
    danger: {
      high: "#f87462",
      hover: "#601e16",
      default: "#391813",
      pressed: "#ae2a19",
    },
    neutral: {
      hover: "#1e1924",
      lowest: {
        hover: "#141117",
        default: "#0e0b0f",
        pressed: "#1e1924",
      },
      default: "#19151d",
      pressed: "#2a2436",
    },
    overlay: "rgba(22,26,29,0.5)",
    success: {
      hover: "#164b35",
      default: "#133527",
      pressed: "#216e4e",
    },
    warning: {
      hover: "#533f04",
      default: "#3d2e00",
      pressed: "#7f5f01",
    },
    disabled: "#1e1924",
  },
  Metropolis: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.32",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  fontWeights: {
    metropolis0: 600,
    metropolis1: 500,
    metropolis2: 400,
    ibmPlexMono3: 600,
    ibmPlexMono4: 400,
    ibmPlexMono5: 500,
  },
  lineHeights: {
    ["0"]: "40px",
    ["1"]: "36px",
    ["2"]: "32px",
    ["3"]: "28px",
    ["4"]: "24px",
    ["5"]: "20px",
    ["6"]: "16px",
    ["7"]: "12px",
  },
  cardPrimary: "4px",
  fontFamilies: {
    text: "Metropolis",
    numeric: "ibm-plex-mono",
  },
  ["IBM Plex Mono"]: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  primaryButton: "4px",
  letterSpacing: {
    ["0"]: "-0.64px",
    ["1"]: "0.34",
    ["2"]: "-0.32",
    ["3"]: "0",
    ["4"]: "0%",
  },
  tertiaryButton: "4px",
  textDecoration: {
    none: "none",
  },
  secondaryButton: "4px",
  paragraphIndent: {
    ["0"]: "0px",
  },
  paragraphSpacing: {
    ["0"]: "0",
  },
  special: {
    lowest: "#0c0420",
    tooltip: "#2a2436",
    tooltipText: "#e8edf2",
    tooltipLinkText: "#cdabf7",
  },
  buttons: {
    primary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
    secondary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
    tertiary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
  },
};

export const paletteCtcLight = {
  red: {
    ["100"]: "#ffedeb",
    ["200"]: "#ffd2cc",
    ["300"]: "#ff9c8f",
    ["400"]: "#f87462",
    ["500"]: "#ef5c48",
    ["600"]: "#e34935",
    ["700"]: "#ca3521",
    ["800"]: "#ae2a19",
    ["900"]: "#601e16",
    ["1000"]: "#391813",
  },
  grey: {
    ["100"]: "#f9fafb",
    ["200"]: "#f3f4f6",
    ["300"]: "#e5e7eb",
    ["400"]: "#d1d5db",
    ["500"]: "#b3b9c4",
    ["600"]: "#8993a5",
    ["700"]: "#758195",
    ["800"]: "#626f86",
    ["900"]: "#44546f",
    ["1000"]: "#2c3e5d",
    ["1100"]: "#172b4d",
    ["1200"]: "#091e42",
    ["1300"]: "#06152e",
    ["1400"]: "#040e1f",
  },
  alpha: {
    ["10"]: "#d1d5db1a",
    ["20"]: "#d1d5db33",
    ["40"]: "#fafafa66",
    ["60"]: "#fafafa99",
  },
  brand: {
    ["50"]: "#f5f3ff",
    ["100"]: "#ede9fe",
    ["200"]: "#e3d6fe",
    ["300"]: "#cdabf7",
    ["400"]: "#b789f4",
    ["500"]: "#9f68f1",
    ["600"]: "#7c3aed",
    ["700"]: "#5f19db",
    ["800"]: "#42109a",
    ["900"]: "#240a53",
    ["1000"]: "#1a0634",
  },
  green: {
    ["100"]: "#dffcf0",
    ["200"]: "#baf3db",
    ["300"]: "#7ee2b8",
    ["400"]: "#4bce97",
    ["500"]: "#2abb7f",
    ["600"]: "#22a06b",
    ["700"]: "#1f845a",
    ["800"]: "#216e4e",
    ["900"]: "#164b35",
    ["1000"]: "#133527",
  },
  orange: {
    ["100"]: "#fff4e5",
    ["200"]: "#ffe2bd",
    ["300"]: "#fec57b",
    ["400"]: "#faa53d",
    ["500"]: "#f18d13",
    ["600"]: "#d97008",
    ["700"]: "#b65c02",
    ["800"]: "#974f0c",
    ["900"]: "#5f3811",
    ["1000"]: "#43290f",
  },
  yellow: {
    ["100"]: "#fff7d6",
    ["200"]: "#f8e6a0",
    ["300"]: "#f5cd47",
    ["400"]: "#e2b203",
    ["500"]: "#cf9f02",
    ["600"]: "#b38600",
    ["700"]: "#946f00",
    ["800"]: "#9f8741",
    ["900"]: "#533f04",
    ["1000"]: "#3d2e00",
  },
  neutral: {
    ["100"]: "#faf8fc",
    ["200"]: "#f4f2f7",
    ["300"]: "#eae7ef",
    ["400"]: "#e5e0eb",
    ["500"]: "#d5cddf",
    ["600"]: "#c8bdd6",
    ["700"]: "#837595",
    ["800"]: "#726286",
    ["900"]: "#57446f",
    ["1000"]: "#412c5d",
    ["1100"]: "#2e174d",
    ["1200"]: "#220942",
    ["1300"]: "#17062e",
    ["1400"]: "#10041f",
    ["000"]: "#ffffff",
  },
  special: {
    ["100"]: "#0c0420",
  },
};

export const ctcLight = {
  xl: "16px",
  ["2Xl"]: "24px",
  icon: {
    low: "#758195",
    high: "#06152e",
    brand: "#5f19db",
    white: "#ffffff",
    danger: "#ae2a19",
    default: "#172b4d",
    inverse: "#ffffff",
    success: "#216e4e",
    warning: "#974f0c",
    disabled: "#b3b9c4",
  },
  text: {
    low: "#726286",
    high: "#17062e",
    brand: "#5f19db",
    white: "#ffffff",
    danger: "#ae2a19",
    default: "#2e174d",
    inverse: "#ffffff",
    success: "#216e4e",
    warning: "#974f0c",
    disabled: "#837595",
  },
  large: "12px",
  small: "4px",
  border: {
    brand: "#7c3aed",
    danger: "#ca3521",
    neutral: {
      low: "#f4f2f7",
      high: "#d5cddf",
      medium: "#eae7ef",
      default: "#e5e0eb",
      highest: "#c8bdd6",
    },
    success: "#22a06b",
    warning: "#d97008",
    disabled: "#eae7ef",
  },
  button: {
    brand: {
      hover: "#5f19db",
      default: "#7c3aed",
      pressed: "#42109a",
    },
    danger: {
      hover: "#ae2a19",
      default: "#ca3521",
      pressed: "#601e16",
    },
    subtle: {
      hover: "#e3d6fe",
      default: "#ede9fe",
      pressed: "#cdabf7",
    },
    neutral: {
      hover: "#f4f2f7",
      default: "#ffffff",
      pressed: "#eae7ef",
    },
    success: {
      hover: "#216e4e",
      default: "#1f845a",
      pressed: "#164b35",
    },
    warning: {
      hover: "#cf9f02",
      default: "#e2b203",
      pressed: "#b38600",
    },
    disabled: "#eae7ef",
  },
  medium: "8px",
  fontSize: {
    ["0"]: "10px",
    ["1"]: "12px",
    ["2"]: "13px",
    ["3"]: "14px",
    ["4"]: "16px",
    ["5"]: "20px",
    ["6"]: "24px",
    ["7"]: "28px",
    ["8"]: "32px",
  },
  textCase: {
    none: "none",
    uppercase: "uppercase",
  },
  elevation: {
    low: "#faf8fc",
    high: "#eae7ef",
    medium: "#f4f2f7",
    default: "#ffffff",
    highest: "#e5e0eb",
  },
  background: {
    brand: {
      hover: "#e3d6fe",
      default: "#ede9fe",
      pressed: "#cdabf7",
    },
    input: {
      hover: "#faf8fc",
      default: "#ffffff",
      pressed: "#ffffff",
    },
    accent: {
      red: {
        low: "#ffd2cc",
        high: "#ca3521",
        medium: "#f87462",
        lowest: "#ffedeb",
        highest: "#ae2a19",
      },
      green: {
        low: "#baf3db",
        high: "#1f845a",
        medium: "#4bce97",
        lowest: "#dffcf0",
        highest: "#216e4e",
      },
      orange: {
        low: "#ffe2bd",
        high: "#b65c02",
        medium: "#faa53d",
        lowest: "#fff4e5",
        highest: "#974f0c",
      },
      purple: {
        low: "#cdabf7",
        high: "#7c3aed",
        medium: "#9f68f1",
        lowest: "#f5f3ff",
        highest: "#5f19db",
      },
      yellow: {
        low: "#f8e6a0",
        high: "#946f00",
        medium: "#e2b203",
        lowest: "#fff7d6",
        highest: "#9f8741",
      },
      neutral: {
        low: "#eae7ef",
        high: "#c8bdd6",
        medium: "#d5cddf",
        lowest: "#faf8fc",
        highest: "#726286",
      },
    },
    danger: {
      high: "#f87462",
      hover: "#ffd2cc",
      default: "#ffedeb",
      pressed: "#ff9c8f",
    },
    neutral: {
      hover: "#eae7ef",
      lowest: {
        hover: "#faf8fc",
        default: "#ffffff",
        pressed: "#f4f2f7",
      },
      default: "#f4f2f7",
      pressed: "#e5e0eb",
    },
    overlay: "#00000059",
    special: {
      lowest: "#0c0420",
      ["ctcPurple"]: "#0c0420",
    },
    success: {
      hover: "#baf3db",
      default: "#dffcf0",
      pressed: "#7ee2b8",
    },
    warning: {
      hover: "#f8e6a0",
      default: "#fff7d6",
      pressed: "#f5cd47",
    },
    disabled: "#eae7ef",
  },
  Metropolis: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "Metropolis",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.32",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Metropolis",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  fontWeights: {
    metropolis0: 600,
    metropolis1: 500,
    metropolis2: 400,
    ibmPlexMono3: 600,
    ibmPlexMono4: 400,
    ibmPlexMono5: 500,
  },
  lineHeights: {
    ["0"]: "40px",
    ["1"]: "36px",
    ["2"]: "32px",
    ["3"]: "28px",
    ["4"]: "24px",
    ["5"]: "20px",
    ["6"]: "16px",
    ["7"]: "12px",
  },
  cardPrimary: "4px",
  fontFamilies: {
    text: "Metropolis",
    numeric: "ibm-plex-mono",
  },
  ["IBM Plex Mono"]: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      ["Section Title"]: {
        fontSize: "13px",
        textCase: "uppercase",
        fontFamily: "ibm-plex-mono",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "ibm-plex-mono",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  primaryButton: "4px",
  letterSpacing: {
    ["0"]: "-0.64px",
    ["1"]: "0",
    ["2"]: "-0.32",
    ["3"]: "0",
    ["4"]: "0%",
  },
  tertiaryButton: "4px",
  textDecoration: {
    none: "none",
  },
  secondaryButton: "4px",
  paragraphIndent: {
    ["0"]: "0px",
  },
  paragraphSpacing: {
    ["0"]: "0",
  },
  special: {
    lowest: "#0c0420",
    tooltip: "#d5cddf",
    tooltipText: "#17062e",
    tooltipLinkText: "#9f68f1",
  },
  font: "Metropolis",
  buttons: {
    primary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
    secondary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
    tertiary: {
      borderRadius: "4px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
  },
};

export const paletteKrakenLight = {
  red: {
    ["100"]: "#ffedeb",
    ["200"]: "#ffd2cc",
    ["300"]: "#ff9c8f",
    ["400"]: "#f87462",
    ["500"]: "#ef5c48",
    ["600"]: "#e34935",
    ["700"]: "#ca3521",
    ["800"]: "#ae2a19",
    ["900"]: "#601e16",
    ["1000"]: "#391813",
  },
  grey: {
    ["100"]: "#f9fafb",
    ["200"]: "#f3f4f6",
    ["300"]: "#e5e7eb",
    ["400"]: "#d1d5db",
    ["500"]: "#b3b9c4",
    ["600"]: "#8993a5",
    ["700"]: "#758195",
    ["800"]: "#626f86",
    ["900"]: "#44546f",
    ["1000"]: "#2c3e5d",
    ["1100"]: "#172b4d",
    ["1200"]: "#091e42",
    ["1300"]: "#06152e",
    ["1400"]: "#040e1f",
  },
  alpha: {
    ["10"]: "#d1d5db1a",
    ["20"]: "#d1d5db33",
    ["40"]: "#fafafa66",
    ["60"]: "#fafafa99",
  },
  brand: {
    ["50"]: "#f1ebfe",
    ["100"]: "#e3d6fd",
    ["200"]: "#c6adfb",
    ["300"]: "#aa84f9",
    ["400"]: "#8d5bf7",
    ["500"]: "#7132f5",
    ["600"]: "#662ddd",
    ["700"]: "#4f23ac",
    ["800"]: "#441e93",
    ["900"]: "#220f49",
    ["1000"]: "#170a31",
  },
  green: {
    ["100"]: "#dffcf0",
    ["200"]: "#baf3db",
    ["300"]: "#7ee2b8",
    ["400"]: "#4bce97",
    ["500"]: "#2abb7f",
    ["600"]: "#22a06b",
    ["700"]: "#1f845a",
    ["800"]: "#216e4e",
    ["900"]: "#164b35",
    ["1000"]: "#133527",
  },
  orange: {
    ["100"]: "#fff4e5",
    ["200"]: "#ffe2bd",
    ["300"]: "#fec57b",
    ["400"]: "#faa53d",
    ["500"]: "#f18d13",
    ["600"]: "#d97008",
    ["700"]: "#b65c02",
    ["800"]: "#974f0c",
    ["900"]: "#5f3811",
    ["1000"]: "#43290f",
  },
  yellow: {
    ["100"]: "#fff7d6",
    ["200"]: "#f8e6a0",
    ["300"]: "#f5cd47",
    ["400"]: "#e2b203",
    ["500"]: "#cf9f02",
    ["600"]: "#b38600",
    ["700"]: "#946f00",
    ["800"]: "#9f8741",
    ["900"]: "#533f04",
    ["1000"]: "#3d2e00",
  },
  neutral: {
    ["100"]: "#f6f5f9",
    ["200"]: "#F4F2F7",
    ["300"]: "#EEECF4",
    ["400"]: "#E3DFEC",
    ["500"]: "#c0b9d5",
    ["600"]: "#b4accd",
    ["700"]: "#9d93be",
    ["800"]: "#7b6ca7",
    ["900"]: "#5e5086",
    ["1000"]: "#4c416c",
    ["1100"]: "#282239",
    ["1200"]: "#1f1b2d",
    ["1300"]: "#161320",
    ["1400"]: "#0d0b13",
    ["000"]: "#ffffff",
  },
  special: {
    ["100"]: "#0c0420",
  },
};

export const krakenLight = {
  xl: "16px",
  ["2Xl"]: "24px",
  icon: {
    low: "#758195",
    high: "#161320",
    brand: "#7132f5",
    white: "#ffffff",
    danger: "#ae2a19",
    default: "#626f86",
    inverse: "#ffffff",
    success: "#216e4e",
    warning: "#974f0c",
    disabled: "#b3b9c4",
  },
  text: {
    low: "#626f86",
    high: "#161320",
    brand: "#7132f5",
    white: "#ffffff",
    danger: "#ae2a19",
    default: "#282239",
    inverse: "#ffffff",
    success: "#216e4e",
    warning: "#974f0c",
    disabled: "#8993a5",
  },
  large: "12px",
  small: "4px",
  border: {
    brand: "#7132f5",
    danger: "#ca3521",
    neutral: {
      low: "#F4F2F7",
      high: "#c0b9d5",
      medium: "#EEECF4",
      default: "#E3DFEC",
      highest: "#b4accd",
    },
    success: "#22a06b",
    warning: "#d97008",
    disabled: "#EEECF4",
  },
  button: {
    brand: {
      hover: "#4f23ac",
      default: "#7132f5",
      pressed: "#441e93",
    },
    danger: {
      hover: "#ae2a19",
      default: "#ca3521",
      pressed: "#601e16",
    },
    subtle: {
      hover: "#c6adfb",
      default: "#e3d6fd",
      pressed: "#aa84f9",
    },
    neutral: {
      hover: "#F4F2F7",
      default: "#ffffff",
      pressed: "#EEECF4",
    },
    success: {
      hover: "#216e4e",
      default: "#1f845a",
      pressed: "#164b35",
    },
    warning: {
      hover: "#cf9f02",
      default: "#e2b203",
      pressed: "#b38600",
    },
    disabled: "#EEECF4",
  },
  medium: "8px",
  fontSize: {
    ["0"]: "10px",
    ["1"]: "12px",
    ["2"]: "13px",
    ["3"]: "14px",
    ["4"]: "16px",
    ["5"]: "20px",
    ["6"]: "24px",
    ["7"]: "28px",
    ["8"]: "32px",
  },
  textCase: {
    none: "none",
    uppercase: "uppercase",
  },
  elevation: {
    low: "#ffffff",
    high: "#EEECF4",
    medium: "#F4F2F7",
    default: "#f6f5f9",
    highest: "#E3DFEC",
  },
  background: {
    brand: {
      hover: "#c6adfb",
      default: "#e3d6fd",
      pressed: "#aa84f9",
    },
    input: {
      hover: "#f6f5f9",
      default: "#ffffff",
      pressed: "#ffffff",
    },
    accent: {
      red: {
        low: "#ffd2cc",
        high: "#ca3521",
        medium: "#f87462",
        lowest: "#ffedeb",
        highest: "#ae2a19",
      },
      green: {
        low: "#baf3db",
        high: "#1f845a",
        medium: "#4bce97",
        lowest: "#dffcf0",
        highest: "#216e4e",
      },
      orange: {
        low: "#ffe2bd",
        high: "#b65c02",
        medium: "#faa53d",
        lowest: "#fff4e5",
        highest: "#974f0c",
      },
      purple: {
        low: "#aa84f9",
        high: "#662ddd",
        medium: "#7132f5",
        lowest: "#f1ebfe",
        highest: "#4f23ac",
      },
      yellow: {
        low: "#f8e6a0",
        high: "#946f00",
        medium: "#e2b203",
        lowest: "#fff7d6",
        highest: "#9f8741",
      },
      neutral: {
        low: "#EEECF4",
        high: "#b4accd",
        medium: "#c0b9d5",
        lowest: "#f6f5f9",
        highest: "#7b6ca7",
      },
    },
    danger: {
      high: "#f87462",
      hover: "#ffd2cc",
      default: "#ffedeb",
      pressed: "#ff9c8f",
    },
    neutral: {
      hover: "#EEECF4",
      lowest: {
        hover: "#f6f5f9",
        default: "#ffffff",
        pressed: "#F4F2F7",
      },
      default: "#F4F2F7",
      pressed: "#E3DFEC",
    },
    overlay: "#00000059",
    success: {
      hover: "#baf3db",
      default: "#dffcf0",
      pressed: "#7ee2b8",
    },
    warning: {
      hover: "#f8e6a0",
      default: "#fff7d6",
      pressed: "#f5cd47",
    },
    disabled: "#EEECF4",
  },
  special: {
    lowest: "#0c0420",
    tooltip: "#c0b9d5",
    tooltipText: "#161320",
    tooltipLinkText: "#7132f5",
  },
  Metropolis: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "-2%",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "-2%",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "-2%",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0.34",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.32",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  fontWeights: {
    metropolis0: 600,
    metropolis1: 500,
    metropolis2: 400,
    ibmPlexMono3: 600,
    ibmPlexMono4: 400,
    ibmPlexMono5: 500,
  },
  lineHeights: {
    ["0"]: "40px",
    ["1"]: "36px",
    ["2"]: "32px",
    ["3"]: "28px",
    ["4"]: "24px",
    ["5"]: "20px",
    ["6"]: "16px",
    ["7"]: "12px",
  },
  cardPrimary: "16px",
  fontFamilies: {
    text: "Metropolis",
    numeric: "ibm-plex-mono",
  },
  ["IBM Plex Mono"]: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      ["Section Title"]: {
        fontSize: "13px",
        textCase: "uppercase",
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "IBM Plex Sans",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  primaryButton: "12px",
  letterSpacing: {
    ["0"]: "-2%",
    ["1"]: "0.34",
    ["2"]: "-0.32",
    ["3"]: "0",
    ["4"]: "0%",
  },
  tertiaryButton: "12px",
  textDecoration: {
    none: "none",
  },
  secondaryButton: "12px",
  paragraphIndent: {
    ["0"]: "0px",
  },
  paragraphSpacing: {
    ["0"]: "0",
  },
  buttons: {
    primary: {
      borderRadius: "12px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
    secondary: {
      borderRadius: "12px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
    tertiary: {
      borderRadius: "12px",
      alignment: "center",
      asideAlignment: "flex-start",
    },
  },
};

export const paletteMetaMaskDark = {
  red: {
    ["100"]: "#fcf2f3",
    ["200"]: "#f7d5d8",
    ["300"]: "#f1b9be",
    ["400"]: "#e88f97",
    ["500"]: "#e06470",
    ["600"]: "#d73847",
    ["700"]: "#8e1d28",
    ["800"]: "#64141c",
    ["900"]: "#3a0c10",
    ["1000"]: "#3a0c10",
  },
  grey: {
    ["100"]: "#f9fafb",
    ["200"]: "#f3f4f6",
    ["300"]: "#e5e7eb",
    ["400"]: "#d1d5db",
    ["500"]: "#b3b9c4",
    ["600"]: "#8993a5",
    ["700"]: "#758195",
    ["800"]: "#626f86",
    ["900"]: "#44546f",
    ["1000"]: "#2c3e5d",
    ["1100"]: "#172b4d",
    ["1200"]: "#091e42",
    ["1300"]: "#06152e",
    ["1400"]: "#040e1f",
  },
  alpha: {
    ["10"]: "#d1d5db1a",
    ["20"]: "#d1d5db33",
    ["40"]: "#fafafa66",
    ["60"]: "#fafafa99",
  },
  brand: {
    ["50"]: "#eaf6ff",
    ["100"]: "#eaf6ff",
    ["200"]: "#a7d9fe",
    ["300"]: "#75c4fd",
    ["400"]: "#43aefc",
    ["500"]: "#1098fc",
    ["600"]: "#0376c9",
    ["700"]: "#0260a4",
    ["800"]: "#024272",
    ["900"]: "#01253f",
    ["1000"]: "#00080d",
  },
  green: {
    ["100"]: "#f3fcf5",
    ["200"]: "#d6ffdf",
    ["300"]: "#afecbd",
    ["400"]: "#5dd879",
    ["500"]: "#28a745",
    ["600"]: "#1c8234",
    ["700"]: "#145523",
    ["800"]: "#0a2c12",
    ["900"]: "#041007",
    ["1000"]: "#041007",
  },
  orange: {
    ["100"]: "#fef5ef",
    ["200"]: "#fde2cf",
    ["300"]: "#fbc49d",
    ["400"]: "#faa66c",
    ["500"]: "#f8883b",
    ["600"]: "#f66a0a",
    ["700"]: "#bf5208",
    ["800"]: "#954005",
    ["900"]: "#632b04",
    ["1000"]: "#321602",
  },
  yellow: {
    ["100"]: "#fefcde",
    ["200"]: "#fff2c5",
    ["300"]: "#ffdf70",
    ["400"]: "#ffc70a",
    ["500"]: "#f8883b",
    ["600"]: "#f66a0a",
    ["700"]: "#bf5208",
    ["800"]: "#954005",
    ["900"]: "#632b04",
    ["1000"]: "#321602",
  },
  neutral: {
    ["100"]: "#fafbfc",
    ["200"]: "#f2f4f6",
    ["300"]: "#e0e2e5",
    ["400"]: "#d6d9dc",
    ["500"]: "#bbc0c5",
    ["600"]: "#9fa6ae",
    ["700"]: "#848c96",
    ["800"]: "#6a737d",
    ["900"]: "#535a61",
    ["1000"]: "#313235",
    ["1100"]: "#24272a",
    ["1200"]: "#1F2124",
    ["1300"]: "#141618",
    ["1400"]: "#000000",
    ["000"]: "#ffffff",
  },
  special: {
    ["100"]: "#0c0420",
  },
};

export const metaMaskDark = {
  xl: "16px",
  ["2Xl"]: "24px",
  icon: {
    low: "#848c96",
    high: "#f2f4f6",
    brand: "#43aefc",
    white: "#ffffff",
    danger: "#e06470",
    default: "#bbc0c5",
    inverse: "#000000",
    success: "#28a745",
    warning: "#ffc70a",
    disabled: "#6a737d",
  },
  text: {
    low: "#848c96",
    high: "#f2f4f6",
    brand: "#43aefc",
    white: "#ffffff",
    danger: "#e88f97",
    default: "#bbc0c5",
    inverse: "#000000",
    success: "#28a745",
    warning: "#ffdf70",
    disabled: "#6a737d",
  },
  large: "12px",
  small: "4px",
  border: {
    brand: "#0376c9",
    danger: "#8e1d28",
    neutral: {
      low: "#141618",
      high: "#313235",
      medium: "#1F2124",
      default: "#24272a",
      highest: "#535a61",
    },
    success: "#1c8234",
    warning: "#f66a0a",
    disabled: "#1F2124",
  },
  button: {
    brand: {
      hover: "#0260a4",
      default: "#0376c9",
      pressed: "#024272",
    },
    danger: {
      hover: "#d73847",
      default: "#8e1d28",
      pressed: "#e06470",
    },
    subtle: {
      hover: "#024272",
      default: "#01253f",
      pressed: "#0260a4",
    },
    neutral: {
      hover: "#1F2124",
      default: "#141618",
      pressed: "#24272a",
    },
    success: {
      hover: "#28a745",
      default: "#1c8234",
      pressed: "#5dd879",
    },
    warning: {
      hover: "#ffdf70",
      default: "#ffc70a",
      pressed: "#fff2c5",
    },
    disabled: "#1F2124",
  },
  medium: "8px",
  fontSize: {
    ["0"]: "10px",
    ["1"]: "12px",
    ["2"]: "13px",
    ["3"]: "14px",
    ["4"]: "16px",
    ["5"]: "20px",
    ["6"]: "24px",
    ["7"]: "28px",
    ["8"]: "32px",
  },
  textCase: {
    none: "none",
    uppercase: "uppercase",
  },
  elevation: {
    low: "#141618",
    high: "#24272a",
    medium: "#1F2124",
    default: "#000000",
    highest: "#313235",
  },
  background: {
    brand: {
      hover: "#024272",
      default: "#01253f",
      pressed: "#0260a4",
    },
    input: {
      hover: "#24272a",
      default: "#141618",
      pressed: "#141618",
    },
    accent: {
      red: {
        low: "#64141c",
        high: "#e88f97",
        medium: "#d73847",
        lowest: "#3a0c10",
        highest: "#f1b9be",
      },
      green: {
        low: "#0a2c12",
        high: "#5dd879",
        medium: "#1c8234",
        lowest: "#041007",
        highest: "#d6ffdf",
      },
      orange: {
        low: "#632b04",
        high: "#f8883b",
        medium: "#bf5208",
        lowest: "#321602",
        highest: "#fbc49d",
      },
      purple: {
        low: "#024272",
        high: "#43aefc",
        medium: "#0376c9",
        lowest: "#01253f",
        highest: "#75c4fd",
      },
      yellow: {
        low: "#bf5208",
        high: "#ffdf70",
        medium: "#f8883b",
        lowest: "#632b04",
        highest: "#fff2c5",
      },
      neutral: {
        low: "#1F2124",
        high: "#313235",
        medium: "#24272a",
        lowest: "#141618",
        highest: "#535a61",
      },
    },
    danger: {
      high: "#e88f97",
      hover: "#3a0c10",
      default: "#3a0c10",
      pressed: "#64141c",
    },
    neutral: {
      hover: "#24272a",
      lowest: {
        hover: "#141618",
        default: "#000000",
        pressed: "#1F2124",
      },
      default: "#1F2124",
      pressed: "#313235",
    },
    overlay: "#00000059",
    special: {
      lowest: "#0c0420",
      ["ctcPurple"]: "#0c0420",
    },
    success: {
      hover: "#145523",
      default: "#0a2c12",
      pressed: "#1c8234",
    },
    warning: {
      hover: "#632b04",
      default: "#321602",
      pressed: "#954005",
    },
    disabled: "#24272a",
  },
  Metropolis: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  fontWeights: {
    metropolis0: 600,
    metropolis1: 500,
    metropolis2: 400,
    ibmPlexMono3: 600,
    ibmPlexMono4: 400,
    ibmPlexMono5: 500,
  },
  lineHeights: {
    ["0"]: "40px",
    ["1"]: "36px",
    ["2"]: "32px",
    ["3"]: "28px",
    ["4"]: "24px",
    ["5"]: "20px",
    ["6"]: "16px",
    ["7"]: "12px",
  },
  cardPrimary: "16px",
  fontFamilies: {
    text: "Metropolis",
    numeric: "ibm-plex-mono",
  },
  ["IBM Plex Mono"]: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      ["Section Title"]: {
        fontSize: "13px",
        textCase: "uppercase",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  primaryButton: "999px",
  letterSpacing: {
    ["0"]: "-0.64px",
    ["1"]: "0",
    ["2"]: "0",
    ["3"]: "0",
    ["4"]: "0%",
  },
  tertiaryButton: "999px",
  textDecoration: {
    none: "none",
  },
  secondaryButton: "999px",
  paragraphIndent: {
    ["0"]: "0px",
  },
  paragraphSpacing: {
    ["0"]: "0",
  },
  special: {
    tooltip: "#fff",
    tooltipText: "#000",
    lowest: "#0c0420",
    tooltipLinkText: "#0376c9",
  },
  buttons: {
    primary: {
      borderRadius: "999px",
      alignment: "center",
      asideAlignment: "center",
    },
    secondary: {
      borderRadius: "999px",
      alignment: "center",
      asideAlignment: "center",
    },
    tertiary: {
      borderRadius: "999px",
      alignment: "center",
      asideAlignment: "center",
    },
  },
};

export const paletteMetaMaskLight = {
  red: {
    ["100"]: "#fcf2f3",
    ["200"]: "#f7d5d8",
    ["300"]: "#f1b9be",
    ["400"]: "#e88f97",
    ["500"]: "#e06470",
    ["600"]: "#d73847",
    ["700"]: "#8e1d28",
    ["800"]: "#64141c",
    ["900"]: "#3a0c10",
    ["1000"]: "#3a0c10",
  },
  grey: {
    ["100"]: "#f9fafb",
    ["200"]: "#f3f4f6",
    ["300"]: "#e5e7eb",
    ["400"]: "#d1d5db",
    ["500"]: "#b3b9c4",
    ["600"]: "#8993a5",
    ["700"]: "#758195",
    ["800"]: "#626f86",
    ["900"]: "#44546f",
    ["1000"]: "#2c3e5d",
    ["1100"]: "#172b4d",
    ["1200"]: "#091e42",
    ["1300"]: "#06152e",
    ["1400"]: "#040e1f",
  },
  alpha: {
    ["10"]: "#d1d5db1a",
    ["20"]: "#d1d5db33",
    ["40"]: "#fafafa66",
    ["60"]: "#fafafa99",
  },
  brand: {
    ["50"]: "#eaf6ff",
    ["100"]: "#eaf6ff",
    ["200"]: "#a7d9fe",
    ["300"]: "#75c4fd",
    ["400"]: "#43aefc",
    ["500"]: "#1098fc",
    ["600"]: "#0376c9",
    ["700"]: "#0260a4",
    ["800"]: "#024272",
    ["900"]: "#01253f",
    ["1000"]: "#00080d",
  },
  green: {
    ["100"]: "#f3fcf5",
    ["200"]: "#d6ffdf",
    ["300"]: "#afecbd",
    ["400"]: "#5dd879",
    ["500"]: "#28a745",
    ["600"]: "#1c8234",
    ["700"]: "#145523",
    ["800"]: "#0a2c12",
    ["900"]: "#041007",
    ["1000"]: "#041007",
  },
  orange: {
    ["100"]: "#fef5ef",
    ["200"]: "#fde2cf",
    ["300"]: "#fbc49d",
    ["400"]: "#faa66c",
    ["500"]: "#f8883b",
    ["600"]: "#f66a0a",
    ["700"]: "#bf5208",
    ["800"]: "#954005",
    ["900"]: "#632b04",
    ["1000"]: "#321602",
  },
  yellow: {
    ["100"]: "#fefcde",
    ["200"]: "#fff2c5",
    ["300"]: "#ffdf70",
    ["400"]: "#ffc70a",
    ["500"]: "#f8883b",
    ["600"]: "#f66a0a",
    ["700"]: "#bf5208",
    ["800"]: "#954005",
    ["900"]: "#632b04",
    ["1000"]: "#321602",
  },
  neutral: {
    ["100"]: "#fafbfc",
    ["200"]: "#f2f4f6",
    ["300"]: "#e0e2e5",
    ["400"]: "#d6d9dc",
    ["500"]: "#d6d9dc",
    ["600"]: "#bbc0c5",
    ["700"]: "#9fa6ae",
    ["800"]: "#848c96",
    ["900"]: "#6a737d",
    ["1000"]: "#535a61",
    ["1100"]: "#3b4046",
    ["1200"]: "#24272a",
    ["1300"]: "#141618",
    ["1400"]: "#000000",
    ["000"]: "#ffffff",
  },
  special: {
    ["100"]: "#0c0420",
  },
};

export const metaMaskLight = {
  xl: "16px",
  ["2Xl"]: "24px",
  icon: {
    low: "#758195",
    high: "#06152e",
    brand: "#0260a4",
    white: "#ffffff",
    danger: "#64141c",
    default: "#172b4d",
    inverse: "#ffffff",
    success: "#0a2c12",
    warning: "#954005",
    disabled: "#b3b9c4",
  },
  text: {
    low: "#6a737d",
    high: "#000000",
    brand: "#0260a4",
    white: "#ffffff",
    danger: "#8e1d28",
    default: "#141618",
    inverse: "#ffffff",
    success: "#145523",
    warning: "#bf5208",
    disabled: "#9fa6ae",
  },
  large: "12px",
  small: "4px",
  border: {
    brand: "#0376c9",
    danger: "#8e1d28",
    neutral: {
      low: "#e0e2e5",
      high: "#bbc0c5",
      medium: "#d6d9dc",
      default: "#d6d9dc",
      highest: "#bbc0c5",
    },
    success: "#1c8234",
    warning: "#f66a0a",
    disabled: "#e0e2e5",
  },
  button: {
    brand: {
      hover: "#0260a4",
      default: "#0376c9",
      pressed: "#024272",
    },
    danger: {
      hover: "#64141c",
      default: "#8e1d28",
      pressed: "#3a0c10",
    },
    subtle: {
      hover: "#a7d9fe",
      default: "#eaf6ff",
      pressed: "#75c4fd",
    },
    neutral: {
      hover: "#f2f4f6",
      default: "#fafbfc",
      pressed: "#e0e2e5",
    },
    success: {
      hover: "#0a2c12",
      default: "#145523",
      pressed: "#041007",
    },
    warning: {
      hover: "#f8883b",
      default: "#ffc70a",
      pressed: "#f66a0a",
    },
    disabled: "#e0e2e5",
  },
  medium: "8px",
  fontSize: {
    ["0"]: "10px",
    ["1"]: "12px",
    ["2"]: "13px",
    ["3"]: "14px",
    ["4"]: "16px",
    ["5"]: "20px",
    ["6"]: "24px",
    ["7"]: "28px",
    ["8"]: "32px",
  },
  textCase: {
    none: "none",
    uppercase: "uppercase",
  },
  elevation: {
    low: "#fafbfc",
    high: "#e0e2e5",
    medium: "#f2f4f6",
    default: "#f2f4f6",
    highest: "#d6d9dc",
  },
  background: {
    brand: {
      hover: "#a7d9fe",
      default: "#eaf6ff",
      pressed: "#75c4fd",
    },
    input: {
      hover: "#e0e2e5",
      default: "#ffffff",
      pressed: "#ffffff",
    },
    accent: {
      red: {
        low: "#f7d5d8",
        high: "#8e1d28",
        medium: "#e88f97",
        lowest: "#fcf2f3",
        highest: "#64141c",
      },
      green: {
        low: "#d6ffdf",
        high: "#145523",
        medium: "#5dd879",
        lowest: "#f3fcf5",
        highest: "#0a2c12",
      },
      orange: {
        low: "#fde2cf",
        high: "#bf5208",
        medium: "#faa66c",
        lowest: "#fef5ef",
        highest: "#954005",
      },
      purple: {
        low: "#75c4fd",
        high: "#0376c9",
        medium: "#1098fc",
        lowest: "#eaf6ff",
        highest: "#0260a4",
      },
      yellow: {
        low: "#fff2c5",
        high: "#bf5208",
        medium: "#ffc70a",
        lowest: "#fefcde",
        highest: "#954005",
      },
      neutral: {
        low: "#e0e2e5",
        high: "#bbc0c5",
        medium: "#d6d9dc",
        lowest: "#fafbfc",
        highest: "#848c96",
      },
    },
    danger: {
      high: "#e88f97",
      hover: "#f7d5d8",
      default: "#fcf2f3",
      pressed: "#f1b9be",
    },
    neutral: {
      hover: "#e0e2e5",
      lowest: {
        hover: "#f2f4f6",
        default: "#fafbfc",
        pressed: "#e0e2e5",
      },
      default: "#f2f4f6",
      pressed: "#d6d9dc",
    },
    overlay: "#00000059",
    special: {
      lowest: "#0c0420",
      ["ctcPurple"]: "#0c0420",
    },
    success: {
      hover: "#5dd879",
      default: "#afecbd",
      pressed: "#28a745",
    },
    warning: {
      hover: "#fff2c5",
      default: "#fefcde",
      pressed: "#ffdf70",
    },
    disabled: "#e0e2e5",
  },
  Metropolis: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "-0.64px",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 500,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  fontWeights: {
    metropolis0: 600,
    metropolis1: 500,
    metropolis2: 400,
    ibmPlexMono3: 600,
    ibmPlexMono4: 400,
    ibmPlexMono5: 500,
  },
  lineHeights: {
    ["0"]: "40px",
    ["1"]: "36px",
    ["2"]: "32px",
    ["3"]: "28px",
    ["4"]: "24px",
    ["5"]: "20px",
    ["6"]: "16px",
    ["7"]: "12px",
  },
  cardPrimary: "16px",
  fontFamilies: {
    text: "Metropolis",
    numeric: "ibm-plex-mono",
  },
  ["IBM Plex Mono"]: {
    Body: {
      Bold: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Light: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      Regular: {
        fontSize: "14px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Header: {
      H1: {
        fontSize: "32px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H2: {
        fontSize: "28px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H3: {
        fontSize: "24px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "32px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H4: {
        fontSize: "20px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      H5: {
        fontSize: "16px",
        textCase: "none",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
      ["Section Title"]: {
        fontSize: "13px",
        textCase: "uppercase",
        fontFamily: "Euclid Circular B",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0",
        textDecoration: "none",
        paragraphIndent: "0px",
        paragraphSpacing: "0",
      },
    },
    Caption: {
      Large: {
        Bold: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "13px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0%",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Small: {
        Bold: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "10px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "12px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
      Medium: {
        Bold: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
        Regular: {
          fontSize: "12px",
          textCase: "none",
          fontFamily: "Euclid Circular B",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0",
          textDecoration: "none",
          paragraphIndent: "0px",
          paragraphSpacing: "0",
        },
      },
    },
  },
  primaryButton: "999px",
  letterSpacing: {
    ["0"]: "-0.64px",
    ["1"]: "0",
    ["2"]: "0",
    ["3"]: "0",
    ["4"]: "0%",
  },
  tertiaryButton: "999px",
  textDecoration: {
    none: "none",
  },
  secondaryButton: "999px",
  paragraphIndent: {
    ["0"]: "0px",
  },
  paragraphSpacing: {
    ["0"]: "0",
  },
  special: {
    lowest: "#0c0420",
    tooltip: "#222222",
    tooltipText: "#ffffff",
    tooltipLinkText: "#1098fc",
  },
  buttons: {
    primary: {
      borderRadius: "999px",
      alignment: "center",
      asideAlignment: "center",
    },
    secondary: {
      borderRadius: "999px",
      alignment: "center",
      asideAlignment: "center",
    },
    tertiary: {
      borderRadius: "999px",
      alignment: "center",
      asideAlignment: "center",
    },
  },
};

