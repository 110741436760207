import { Box, Typography } from "@mui/material";
import * as React from "react";

import { LanguageButton } from "~/components/nav/LanguageButton";

import { PoweredByCtcAuthContainer } from "~/components/user/EmbeddedSignupForm";
import { ForgotPasswordForm } from "~/components/user/ForgotPasswordForm";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export const EmbeddedForgotPasswordForm: React.FC = () => {
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      minWidth="100vw"
      padding="1.5rem"
      sx={{
        backgroundColor: tokens.elevation.default,
      }}
    >
      <PoweredByCtcAuthContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginBottom="1.75rem"
        >
          <Typography variant="Metropolis/Header/H3">
            {lang.auth.forgotPassword}
          </Typography>
          <LanguageButton />
        </Box>

        <Typography
          variant={"Metropolis/Body/Regular"}
          marginBottom="1.5rem"
        >
          {lang.auth.passwordResetInstructions}
        </Typography>

        <ForgotPasswordForm />
      </PoweredByCtcAuthContainer>
    </Box>
  );
};
