import { Box, Fade, Modal } from "@mui/material";
import { bindDialog } from "material-ui-popup-state";
import { useCallback, useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components/macro";

import { bulkEditAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { CommandPaletteBar } from "~/components/transactions/command-palette/CommandPaletteBar";
import {
  BulkEditOptions,
  Hotkeys,
} from "~/components/transactions/command-palette/enums";
import {
  useBulkEditReview,
  useSelectedRowReviewedStatus,
} from "~/components/transactions/command-palette/hooks/useBulkEditReview";
import {
  useBulkEditCommandPalette,
  useCommandPalette,
} from "~/components/transactions/command-palette/hooks/useCommandPalette";
import { useNumSelectedOrFiltered } from "~/components/transactions/command-palette/hooks/useNumSelectedOrFiltered";
import {
  ModalProvider,
  useModal,
} from "~/components/transactions/command-palette/ModalProvider";
import {
  NavControllerProvider,
  useNavController,
} from "~/components/transactions/command-palette/NavController";
import { ToCategory } from "~/components/transactions/command-palette/views/recategorise/ToCategory";
import { Root } from "~/components/transactions/command-palette/views/Root";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import { CheckboxActionType } from "~/components/transactions/filter-bar/enums";
import { ErrorFallback } from "~/components/ui/error-boundary/ErrorBoundaryWrapper";
import { devices } from "~/components/ui/theme/legacy";
import { useSelectedIds } from "~/hooks/useTransactions";
import { getIsMacOs } from "~/lib/index";
import { FilterOperators } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

const StyledCommandPalette = styled(Box)`
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background: ${({ theme }) => theme.tokens.background.neutral.default};
  position: absolute;
  top: 25%;
  left: 50%;
  width: 95vw;

  transform: translate(-50%);
  box-shadow: 0px 0px 4.375rem 1rem rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  &:focus-visible {
    outline: none;
  }
  @media ${devices.tablet} {
    width: 37.5rem;
  }
`;

type BulkEditHotKeyType = {
  [key in Hotkeys]: {
    keys: string | string[];
    label: string;
  };
};

export const bulkEditHotkeys: BulkEditHotKeyType = {
  [Hotkeys.Escape]: {
    keys: "Escape",
    label: "Esc",
  },
  [Hotkeys.Enter]: {
    keys: "Enter",
    label: "Enter",
  },
  // For open command palette
  [Hotkeys.CMD_K]: {
    keys: ["k", "K"],
    label: "K",
  },
  // For categories
  [Hotkeys.C]: {
    keys: ["c", "C"],
    label: "C",
  },
  // For mark as reviewed
  [Hotkeys.R]: {
    keys: ["r", "R"],
    label: "R",
  },
};

export function CommandPaletteModal(props: {
  selectAllDisabled?: boolean;
  commandKDisabled?: boolean;
  categoriseShortcutDisabled?: boolean;
}) {
  return (
    <NavControllerProvider>
      <ModalProvider variant="dialog" popupId="commandPaletteModal">
        <CommandPaletteBar {...props} />
        <CommandPaletteModalInner {...props} />
      </ModalProvider>
    </NavControllerProvider>
  );
}

function CommandPaletteModalInner({
  commandKDisabled,
  categoriseShortcutDisabled,
}: {
  selectAllDisabled?: boolean;
  commandKDisabled?: boolean;
  categoriseShortcutDisabled?: boolean;
}) {
  const modal = useModal();
  const { isOpen, loading } = modal;
  const { close: commandPaletteClose } = useCommandPalette();
  const { open, close } = useBulkEditCommandPalette();
  const { current, views, pop } = useNavController();
  const { dispatch: checkboxDispatch } = useTransactionCheckbox();
  const isMacOS = getIsMacOs();
  const { onConfirm: onBulkEditReview } = useBulkEditReview();
  const hotkeyAnalytic = bulkEditAnalyticsKey("hotkey");
  const selectedIds = useSelectedIds();
  const filter: FilterQuery = useMemo(
    () => ({
      type: FilterOperators.ActionId,
      value: selectedIds,
    }),
    [selectedIds],
  );
  const captureAnalytics = useCaptureAnalytics();

  const { shouldReviewed } = useSelectedRowReviewedStatus({ filter });

  const handleClose = useCallback(
    // union is coming from mui
    (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
      // if you are loading, you cant close it
      if (loading) {
        return;
      }
      // at the root page, so escape gets you out
      // escape clears the checkboxes
      if (reason === "backdropClick" || views.length === 1) {
        close();
        return;
      }
      // not at the root page, so go back 1 view
      pop();
    },
    [close, loading, pop, views.length],
  );

  const { numSelected } = useNumSelectedOrFiltered();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      const { key, target, ctrlKey, metaKey } = event;
      const targetType = target.type;
      const eventType = targetType?.toLowerCase();
      const isInput =
        eventType?.includes("text") || eventType?.includes("search");

      // Bar is showing, but no palette, esc removes the selection
      if (key === bulkEditHotkeys.escape.keys && !isOpen) {
        event.preventDefault();
        checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
        captureAnalytics(hotkeyAnalytic, {
          hotkey: bulkEditHotkeys.escape.label,
          bulkEditOption: null,
          numSelected,
        });
      }
      if (key === bulkEditHotkeys.enter.keys) {
        event.preventDefault();
      }

      if (!numSelected && selectedIds.length === 0) return;

      // cmd+k for mac ||  control+k for windows
      const isCtrlMetaKey = (!isMacOS && ctrlKey) || (isMacOS && metaKey);

      // Open command palette
      if (
        bulkEditHotkeys.CommandK.keys.includes(key) &&
        isCtrlMetaKey &&
        !commandKDisabled
      ) {
        event.preventDefault();
        event.stopPropagation();
        // not open, so open the modal
        if (!isOpen) {
          open(<Root />, event);
        } else if (!loading) {
          // its open, and its not loading (we don't let you quit if its loading)
          commandPaletteClose();
        }
        captureAnalytics(hotkeyAnalytic, {
          hotkey: bulkEditHotkeys.CommandK.label,
          bulkEditOption: BulkEditOptions.Categorize,
          numSelected,
        });
      }

      // Categories (C)
      if (
        !isInput &&
        bulkEditHotkeys.C.keys.includes(key) &&
        !categoriseShortcutDisabled
      ) {
        if (!isOpen) {
          open(<ToCategory />, event);
          event.preventDefault();
          captureAnalytics(hotkeyAnalytic, {
            hotkey: bulkEditHotkeys.C.label,
            bulkEditOption: BulkEditOptions.Categorize,
            numSelected,
          });
        }
      }

      // Mark as Reviewed (R)
      if (!isInput && bulkEditHotkeys.R.keys.includes(key)) {
        if (!isOpen) {
          close();
          onBulkEditReview({
            reviewed: shouldReviewed,
            filter,
          });
          event.preventDefault();
          captureAnalytics(hotkeyAnalytic, {
            hotkey: bulkEditHotkeys.R.label,
            bulkEditOption: shouldReviewed
              ? BulkEditOptions.MarkAsReviewed
              : BulkEditOptions.MarkAsNotReviewed,
            numSelected,
          });
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    captureAnalytics,
    checkboxDispatch,
    commandPaletteClose,
    isMacOS,
    isOpen,
    loading,
    numSelected,
    open,
    onBulkEditReview,
    selectedIds,
    close,
    shouldReviewed,
    filter,
    hotkeyAnalytic,
  ]);

  return (
    <Modal
      {...bindDialog(modal)}
      onClose={handleClose}
      aria-labelledby="Command-Palette-Modal"
    >
      <Fade in={isOpen}>
        <StyledCommandPalette>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {current}
          </ErrorBoundary>
        </StyledCommandPalette>
      </Fade>
    </Modal>
  );
}
