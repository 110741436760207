import {
  useBulkFilter,
  useBulkFilterAllEnabled,
} from "~/components/transactions/command-palette/hooks/useBulkFilter";
import {
  useSelectedAll,
  useTransactionsTotalFilteredCount,
} from "~/hooks/useTransactions";
import { FilterOperators } from "~/types/enums";

export function useNumSelectedOrFiltered() {
  const filter = useBulkFilter();
  const selectedAll = useSelectedAll();
  const isBulkFilterAllEnabled = useBulkFilterAllEnabled();
  const count = useTransactionsTotalFilteredCount();
  const isManualSelection = filter?.type === FilterOperators.ActionId;

  let numSelected;
  if (isBulkFilterAllEnabled && selectedAll) numSelected = count;
  else if (filter && isManualSelection) numSelected = filter.value.length;

  return { numSelected, isManualSelection };
}
