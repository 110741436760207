import { Box, Stack } from "@mui/material";
import type * as React from "react";
import { type ReactNode } from "react";

import { type ActionTableGridArea } from "~/components/transactions/action-row/enums";
import { Align } from "~/types/enums";

export function ActionRowCell({
  children,
  id,
  style = {},
  align = Align.Left,
  disablePointerEvents = false,
}: {
  children?: ReactNode[] | ReactNode;
  id: ActionTableGridArea;
  style?: React.CSSProperties;
  align?: Align;
  disablePointerEvents?: boolean;
}) {
  return (
    <Box
      gridArea={id}
      display="flex"
      alignItems="flex-start"
      justifyContent={align}
      sx={disablePointerEvents ? { pointerEvents: "none" } : {}}
    >
      <Stack justifyContent="center" style={{ maxWidth: "100%", ...style }}>
        {children}
      </Stack>
    </Box>
  );
}
