import { get, post, put } from "~/services/core";
import { type ReferralProgramType } from "~/types/enums";

type PromoterDetails = {
  paypalEmail: string | null;
  paidBalance: number | null;
  currentBalance: number | null;
  leads: number | null;
  customers: number | null;
};

export function generateReferralCode(programType: ReferralProgramType) {
  const path = `/referrals/code`;
  return post<{ code: string }>(path, { programType });
}

export function getReferralCode(programType: ReferralProgramType) {
  const path = `/referrals/code?programType=${programType}`;
  return get<{ code: string }>(path);
}

export function updatePaypal(programType: ReferralProgramType, email: string) {
  const path = `/referrals/paypal`;
  return put(path, { programType, email });
}

export function getPromoterDetails(programType: ReferralProgramType) {
  const path = `/referrals/${programType}`;
  return get<PromoterDetails>(path);
}
