import { SaveAlt } from "@mui/icons-material";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";

import { DialogHeader } from "~/components/settings-modal/views/profile/security/common";
import { CopyButton } from "~/components/ui/ui-buttons/CopyButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { StyledDialogActions } from "~/components/ui/StyledDialogActions";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function RecoveryCodesModal({
  open,
  onClose,
  recoveryCodes,
}: {
  open: boolean;
  onClose: () => void;
  recoveryCodes: string[];
}) {
  const { tokens } = useDesign();
  const lang = useLang();

  const codePartitions: string[][] = [[], [], []];

  recoveryCodes.forEach((code, index) => {
    codePartitions[index % 3].push(code);
  });
  const copyContent = recoveryCodes.join("\n");

  const handleDownload = () => {
    const content = [lang.twoFactorAuth.recoveryCodesContent, copyContent].join(
      "\n\n",
    );
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Crypto-Tax-Calculator-2FA-recovery-codes.txt";
    a.click();
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogHeader onClose={onClose}>
        {lang.twoFactorAuth.recoveryCodes}
      </DialogHeader>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="1rem">
          <Box
            display="flex"
            flexDirection="column"
            gap="0.5rem"
            bgcolor={tokens.background.accent.purple.lowest}
            borderRadius="0.25rem"
            p="1rem"
          >
            <Typography variant="Metropolis/Body/Bold">
              {lang.twoFactorAuth.recoveryCodesInfoBoxTitle}
            </Typography>
            <Typography variant="Metropolis/Body/Regular">
              {lang.twoFactorAuth.recoveryCodesInfoBoxContent}
            </Typography>
          </Box>
          <Typography variant="Metropolis/Body/Regular">
            {lang.twoFactorAuth.recoveryCodesContent}
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            gap="0.25rem"
            padding="1rem 2.25rem"
            bgcolor={tokens.background.neutral.default}
            border={`1px solid ${tokens.border.neutral.default}`}
            borderRadius="0.25rem"
          >
            {codePartitions.map((partition, partitionIndex) => (
              <Box
                key={partitionIndex}
                display="flex"
                flexDirection="column"
                gap="1rem"
              >
                {partition.map((code, codeIndex) => (
                  <Typography variant="Metropolis/Body/Regular" key={codeIndex}>
                    {code}
                  </Typography>
                ))}
              </Box>
            ))}
          </Box>
          <Box display="flex" gap="1rem">
            <CopyButton
              contentToCopy={copyContent}
              ButtonComponent={TertiaryButton}
            />
            <TertiaryButton onClick={handleDownload}>
              <Box display="flex" gap="0.5rem" alignItems="center">
                <SaveAlt sx={{ color: tokens.text.low, fontSize: "1rem" }} />
                <Typography variant="Metropolis/Caption/Medium/Regular">
                  {lang.twoFactorAuth.download}
                </Typography>
              </Box>
            </TertiaryButton>
          </Box>
        </Box>
      </DialogContent>
      <StyledDialogActions>
        <PrimaryButton onClick={onClose} data-ctc="2fa-recovery-codes-done">
          {lang.done}
        </PrimaryButton>
      </StyledDialogActions>
    </Dialog>
  );
}
