import { type Trade } from "@ctc/types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { Tooltip } from "@mui/material";
import { useState } from "react";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import {
  assertIsCategoryBucketOption,
  assertIsCategoryOption,
  type CategoryBucketOption,
  type CategoryOption,
  formatCategoryOption,
} from "~/components/transactions/command-palette/formatCategoryOption";
import { type Option } from "~/components/transactions/command-palette/hooks/useCommandPaletteMenu";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { SmallCommandPaletteMenu } from "~/components/transactions/command-palette/SmallCommandPaletteMenu";
import { CategoryBucketIcons } from "~/components/transactions/command-palette/views/BulkEditTypeIcons";
import { CategoryBucketSecondLevelSelector } from "~/components/transactions/command-palette/views/recategorise/SubLevelTxCategory";
import {
  CategorisationFunctionFooter,
  getChipPairs,
} from "~/components/transactions/edit/CategorisationFooter";
import { STSOptOut } from "~/components/transactions/edit/STSOptOut";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { LocalStorageKey } from "~/constants/enums";
import {
  LocalStorageUserKeyGenerator,
  useLocalStorage,
} from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import { categoryBucketOrder } from "~/lib/categoryBucketOrder";
import { invariant } from "~/lib/invariant";
import { TradeInfo } from "~/lib/tradeTypeDefinitions";
import { useBestUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  getTransactionCategoryOptionsByDirection,
  isTrade,
  isUncategorisedTrade,
} from "~/services/transactions";
import { type CategoryBucket } from "~/types/enums";
import { TradeDirection } from "~/types/enums";
import {
  type ActionRow,
  type ActionType,
  type TaxSettings,
} from "~/types/index";

enum SubmitType {
  Hover = "hover",
  Click = "click",
}

function getRecentlyUsedCategories({
  direction,
  lastUsedIncoming,
  lastUsedOutgoing,
  selectedFromTrade,
}: {
  direction: TradeDirection;
  lastUsedIncoming: Trade[];
  lastUsedOutgoing: Trade[];
  selectedFromTrade: Trade;
}) {
  return direction === TradeDirection.In && lastUsedIncoming.length
    ? lastUsedIncoming.filter((t) => t !== selectedFromTrade)
    : direction === TradeDirection.Out
      ? lastUsedOutgoing.filter((t) => t !== selectedFromTrade)
      : [];
}

const FlipDirectionButton = ({
  direction,
  handleClick,
}: {
  direction: TradeDirection.In | TradeDirection.Out;
  handleClick: () => void;
}) => {
  const lang = useLang();
  return (
    <Tooltip
      title={lang.advancedTransactionOptions[direction].incorrectAction}
      placement="top"
    >
      <TextIconButton
        size="small"
        onClick={handleClick}
        sx={{ padding: "0.25rem" }}
      >
        <SwapHorizIcon sx={{ width: "1rem", height: "1rem" }} />
      </TextIconButton>
    </Tooltip>
  );
};

export const CategoryBucketSelector = ({
  row,
  selectedFromTrade,
  onSubmit,
  taxSettings,
}: {
  row: ActionRow;
  selectedFromTrade: Trade;
  onSubmit: (trade?: ActionType) => void;
  taxSettings: TaxSettings;
}) => {
  const lang = useLang();
  const { push } = useNavController();
  const { tokens } = useDesign();
  const analyticsKey = transactionAnalyticsKey("categorise");
  const captureAnalytics = useCaptureAnalytics();

  const selectedTradeDirection = TradeInfo[selectedFromTrade].direction;
  const [direction, setDirection] = useState<
    Exclude<TradeDirection, TradeDirection.Unknown>
  >(
    // We dont know the direction, default to out
    // Users can hit the "switch direction button" if they want an incoming
    selectedTradeDirection === TradeDirection.Unknown
      ? TradeDirection.Out
      : // Otherwise In/Out
        selectedTradeDirection,
  );

  const user = useBestUser();

  // get recently used
  const [lastUsedIncoming, setLastUsedIncoming] = useLocalStorage<
    Trade[] | []
  >(
    LocalStorageUserKeyGenerator(
      user?.uid,
      LocalStorageKey.LastUsedIncomingTrade,
    ),
    [],
  );

  const [lastUsedOutgoing, setLastUsedOutgoing] = useLocalStorage<
    Trade[] | []
  >(
    LocalStorageUserKeyGenerator(
      user?.uid,
      LocalStorageKey.LastUsedOutgoingTrade,
    ),
    [],
  );

  const handleDirectionClick = () => {
    setDirection((direction) => {
      return direction === TradeDirection.In
        ? TradeDirection.Out
        : TradeDirection.In;
    });
  };

  const updateLastUsedTrades = (
    trade: Trade,
    direction: TradeDirection,
  ) => {
    if (direction === TradeDirection.In) {
      setLastUsedIncoming(
        [trade, ...lastUsedIncoming.filter((t) => t !== trade)].slice(0, 3),
      );
    } else {
      setLastUsedOutgoing(
        [trade, ...lastUsedOutgoing.filter((t) => t !== trade)].slice(0, 3),
      );
    }
  };

  const recentlyUsedCategories = getRecentlyUsedCategories({
    direction,
    lastUsedIncoming,
    lastUsedOutgoing,
    selectedFromTrade,
  });

  const getRecentlyUsedCategoryOptions = (
    recentlyUsedCategories: Trade[],
  ): CategoryOption[] => {
    return recentlyUsedCategories.flatMap((category) => {
      const option = formatCategoryOption({
        lang,
        taxSettings,
        trade: category,
        tokens,
      });
      if (!option) {
        return [];
      }
      const categoryBucket = TradeInfo[category].bucket?.(taxSettings);
      invariant(categoryBucket, "Category bucket must be defined");
      return [
        {
          ...option,
          sectionLabel: "Recently Used",
          sectionId: "recentlyUsed",
          parentLabel: lang.tradeTypeGroup[categoryBucket].label,
          categoryBucket,
        },
      ];
    });
  };

  const getBucketCategories = (
    categoryTypes: Trade[],
  ): Map<CategoryBucket, Trade[]> => {
    const buckets = new Map<CategoryBucket, Trade[]>();

    for (const categoryType of categoryTypes) {
      invariant(isTrade(categoryType), "Cannot be a group");
      const data = TradeInfo[categoryType];
      if (data.direction !== direction) {
        continue;
      }
      if (!data.bucket) {
        continue;
      }
      const bucket = data.bucket(taxSettings);
      if (!buckets.has(bucket)) {
        buckets.set(bucket, []);
      }
      const categories = buckets.get(bucket);
      invariant(categories, "Categories must be defined already");
      buckets.set(bucket, [...categories, categoryType]);
    }
    return buckets;
  };

  const getBucketOptions = (
    buckets: Map<CategoryBucket, Trade[]>,
  ): CategoryBucketOption[] => {
    return Array.from(buckets)
      .map(([categoryBucket]) => {
        // do the buckets
        const Icon = CategoryBucketIcons[categoryBucket];
        return {
          label: lang.tradeTypeGroup[categoryBucket].label,
          icon: <Icon sx={{ color: `${tokens.text.low} !important` }} />,
          categoryBucket,
          description: lang.tradeTypeGroup[categoryBucket].description,
          endIcon: (
            <ChevronRightIcon
              sx={{
                color: tokens.text.low,
                height: "1rem",
                width: "1rem",
              }}
            />
          ),
          sectionId: "buckets",
          nextView: (
            <CategoryBucketSecondLevelSelector
              row={row}
              categoryBucket={categoryBucket}
              direction={direction}
              onSubmit={(trade) => {
                if (trade && isTrade(trade)) {
                  updateLastUsedTrades(trade, direction);
                }
                onSubmitWrapped(trade, SubmitType.Hover);
              }}
            />
          ),
        };
      })
      .sort((a, b) => {
        return (
          categoryBucketOrder[a.categoryBucket] -
          categoryBucketOrder[b.categoryBucket]
        );
      });
  };

  const getCategoryOptions = (
    buckets: Map<CategoryBucket, Trade[]>,
  ): CategoryOption[] => {
    return Array.from(buckets)
      .flatMap(([categoryBucket, categories]) => {
        // now do all the categories
        return categories.flatMap((category) => {
          const option = formatCategoryOption({
            lang,
            taxSettings,
            trade: category,
            tokens,
          });
          if (!option) {
            return [];
          }
          return [
            {
              ...option,
              parentLabel: lang.tradeTypeGroup[categoryBucket].label,
              categoryBucket,
              sectionId: "categories",
            },
          ];
        });
      })
      .sort((a, b) => {
        const p =
          categoryBucketOrder[a.categoryBucket] -
          categoryBucketOrder[b.categoryBucket];

        if (p !== 0) {
          return p;
        }

        // First, compare by bucket
        const bucketComparison = a.categoryBucket.localeCompare(
          b.categoryBucket,
        );
        if (bucketComparison !== 0) {
          return bucketComparison;
        }
        // If bucket values are the same, then compare by label
        return a.label.localeCompare(b.label);
      });
  };

  const recentlyUsedCategoryOptions = getRecentlyUsedCategoryOptions(
    recentlyUsedCategories,
  );

  // get all the possible categories it could be
  const possibleCategoryTypes = getTransactionCategoryOptionsByDirection({
    direction,

    // dont show any that are in recently used
  }).filter((category) => !recentlyUsedCategories.includes(category));

  const buckets = getBucketCategories(possibleCategoryTypes);

  const bucketOptions = getBucketOptions(buckets);

  const categoryOptions: CategoryOption[] = getCategoryOptions(buckets);

  const options = [
    ...recentlyUsedCategoryOptions,
    ...bucketOptions,
    ...categoryOptions,
  ];

  const onSubmitWrapped = (trade: ActionType, submitType: SubmitType) => {
    onSubmit(trade);
    captureAnalytics(analyticsKey("confirm"), {
      fromCategory: selectedFromTrade,
      toCategory: trade,
      // confirmed from hover menu vs the click-through menu
      submitType,
    });
  };

  const functionChipPairs = getChipPairs([
    ...row.incoming,
    ...row.outgoing,
    ...row.fees,
  ]);

  return (
    <SmallCommandPaletteMenu
      options={options}
      selectedOptionLabel={
        formatCategoryOption({
          lang,
          taxSettings,
          trade: row.type,
          tokens,
        })?.label
      }
      placeholder={
        lang.txTable.commandPallet.placeholders.tradeTypeSearch[direction]
      }
      onSelection={(option) => {
        // selected one of the category options
        if (assertIsCategoryOption(option)) {
          onSubmitWrapped(option.trade, SubmitType.Click);
          // Set the last used trade categories
          if (isTrade(option.trade)) {
            updateLastUsedTrades(option.trade, direction);
          }
          return;
        }
        push(option.nextView);
      }}
      searchAreaRightButton={
        <FlipDirectionButton
          direction={direction}
          handleClick={handleDirectionClick}
        />
      }
      bannerSection={
        isUncategorisedTrade(selectedFromTrade) ? <STSOptOut row={row} /> : null
      }
      footerSection={
        functionChipPairs.length > 0 ? (
          <CategorisationFunctionFooter chipLinkPairs={functionChipPairs} />
        ) : undefined
      }
      customFilter={(filterText: string) => {
        return sortOptions(options.filter(doFilter(filterText)), filterText);
      }}
    />
  );
};

function doFilter<TOption extends Option>(
  filterText: string,
): (option: TOption) => boolean {
  return (option) => {
    if (filterText.length > 0) {
      if (option.sectionId === "buckets") {
        // When searching, don't return buckets, only tx categories
        return false;
      }

      // they have typed something, do a search
      if (option.label.toLowerCase().includes(filterText.toLowerCase())) {
        return true;
      }

      // search the tags
      if (
        option.searchTags?.some((tag) => tag.includes(filterText.toLowerCase()))
      ) {
        return true;
      }
    }
    if (option.sectionId === "recentlyUsed" && !filterText.length) {
      return true;
    }
    if (option.parentLabel) {
      return false;
    }
    return true;
  };
}

// Sort tx categories alphabetically, with recently used at the top
function sortOptions<TOption extends Option>(
  options: TOption[],
  searchText: string,
) {
  return options.sort((a, b) => {
    // if any of the options are an exact match, put them at the top
    if (searchText.length > 0) {
      if (
        a.label.toLowerCase() === searchText.toLowerCase() ||
        b.searchTags?.some(
          (tag) => tag.toLowerCase() === searchText.toLowerCase(),
        )
      ) {
        return -1;
      }
      if (
        b.label.toLowerCase() === searchText.toLowerCase() ||
        b.searchTags?.some(
          (tag) => tag.toLowerCase() === searchText.toLowerCase(),
        )
      ) {
        return 1;
      }
    }
    if (a.sectionId === "recentlyUsed") {
      return -1;
    }
    if (b.sectionId === "recentlyUsed") {
      return 1;
    }

    if (assertIsCategoryBucketOption(a) && assertIsCategoryBucketOption(b)) {
      return (
        categoryBucketOrder[a.categoryBucket] -
        categoryBucketOrder[b.categoryBucket]
      );
    }

    return a.label.localeCompare(b.label);
  });
}
