import { CaptchaProvider } from "@ctc/types";

export const DEFAULT_CAPTCHA_PROVIDER = CaptchaProvider.Recaptcha;

/**
 * Get the captcha provider we're using in the server (Recaptcha or Turnstile)
 *
 * @returns CaptchaProvider
 */
export function useCaptchaProvider() {
  // Check if the feature flag is enabled
  // Note: This is a temporary solution to avoid the delay of the feature flag
  // const isTurnstileEnabled = useFeatureFlag(FeatureFlag.UseTurnstileCaptcha);
  const isTurnstileEnabled = true;

  return {
    captchaProvider: isTurnstileEnabled
      ? CaptchaProvider.Turnstile
      : DEFAULT_CAPTCHA_PROVIDER,
  };
}
