import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { type ChangeEvent, useEffect, useState } from "react";

import { useDesign } from "~/hooks/useTheme";
import { useLocalCurrencySymbol } from "~/redux/auth";
import { useLang } from "~/redux/lang";

type IgnoreBalanceRemainingValueThresholdProps = {
  disabled?: boolean;
  value: number;
  onChange: (longTermThreshold: number) => void;
  onSave?: () => void;
};

export function IgnoreBalanceRemainingValueThreshold({
  disabled = false,
  value,
  onChange,
  onSave = () => {},
}: IgnoreBalanceRemainingValueThresholdProps) {
  const { tokens } = useDesign();
  const [threshold, setThreshold] = useState<number | "">(value);
  const lang = useLang();
  const localCurrencySymbol = useLocalCurrencySymbol();

  useEffect(() => {
    setThreshold(value);
  }, [value]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const val = target.value === "" ? "" : +target.value;
    setThreshold(val);
    onChange(val || 0);
  };

  useEffect(() => {
    if (disabled && threshold === "") {
      setThreshold(value);
    }
  }, [value, disabled, threshold]);

  return (
    <Box>
      <Box display="inline-flex">
        <Typography variant="Metropolis/Header/H5">
          {lang.settings.report.ignoreBalanceRemainingValueThreshold.title}
        </Typography>
      </Box>
      <Box mt="1rem">
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "0.875rem",
            color: tokens.text.low,
          }}
        >
          {lang.settings.report.ignoreBalanceRemainingValueThreshold.subtext}
        </Typography>
      </Box>
      <TextField
        disabled={disabled}
        size="small"
        value={threshold}
        onChange={handleChange}
        onBlur={() => {
          onSave();
        }}
        margin="normal"
        variant="outlined"
        label={lang.settings.threshold}
        style={{ color: tokens.text.default }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {localCurrencySymbol}
            </InputAdornment>
          ),
        }}
        inputProps={{
          type: "number",
          min: 0,
        }}
      />
    </Box>
  );
}
