import { StatusType } from "~/components/ui/enums";
import { StatusNotification } from "~/components/ui/StatusNotification";
import { useLang } from "~/redux/lang";

export const PaymentStatusNotification = () => {
  const lang = useLang();
  const {
    billing: {
      paymentStatus: { failed },
    },
  } = lang;
  return (
    <StatusNotification
      type={StatusType.Warning}
      title={failed.title}
      description={failed.description}
    />
  );
};
