import { Trade } from "@ctc/types";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ButtonGroup, IconButton, MenuItem, Popover } from "@mui/material";
import uniq from "lodash/uniq";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import type * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import { CheckboxActionType } from "~/components/transactions/filter-bar/enums";
import { Checkbox } from "~/components/ui/Checkbox";
import { useSelectedIds } from "~/hooks/useTransactions";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { useLang } from "~/redux/lang";
import { isTrade } from "~/services/transactions";
import { useSelectableRows } from "~/state/actions";
import { type ActionRow, type ActionType } from "~/types/index";

export type BulkSelectionType = "" | "all" | "uncategorised" | ActionType;

export const FilterSelectAll = () => {
  const { dispatch } = useTransactionCheckbox();
  const lang = useLang();
  const selectedIds = useSelectedIds();
  const selectableRows = useSelectableRows();

  const [allSelectedChecked, setAllSelectedChecked] = useState(false);
  const [selectionType, setSelectionType] = useState<BulkSelectionType>("");
  const numSelected = selectedIds.length;
  const isAllSelected = numSelected === selectableRows.length;
  const indeterminate = !isAllSelected && numSelected !== 0;
  const availableOptions = uniq(selectableRows.map((row) => row.type)).sort(
    (a, b) => a.localeCompare(b),
  );
  useEffect(() => {
    if (!numSelected) {
      setSelectionType("");
      setAllSelectedChecked(false);
    }
  }, [numSelected]);

  const handleBulkSelect = (type: BulkSelectionType) => {
    let rowsToSelect: ActionRow[] = [];
    switch (type) {
      case "all":
        rowsToSelect = selectableRows;
        setAllSelectedChecked(true);
        break;
      case "uncategorised":
        rowsToSelect = selectableRows.filter(
          (row) =>
            isTrade(row.type) &&
            [Trade.In, Trade.Out].includes(row.type),
        );
        setAllSelectedChecked(false);
        break;
      case "":
        setAllSelectedChecked(false);
        break;
      default:
        rowsToSelect = selectableRows.filter((row) => row.type === type);
        setAllSelectedChecked(false);
        break;
    }
    const ids = rowsToSelect.length ? rowsToSelect.map((row) => row._id) : [];
    dispatch({
      type: CheckboxActionType.SetSelectedIds,
      ids,
    });
  };

  const newHandleChangeSelectionType = (type: BulkSelectionType) => {
    handleBulkSelect(type);
    setSelectionType(type);
  };
  const disabled = selectableRows.length === 0;

  const popupState = usePopupState({
    variant: "popover",
    popupId: "warningInfo",
  });

  const checkboxRef = useRef<HTMLDivElement | null>(null);
  const checked = allSelectedChecked || numSelected !== 0;

  return (
    <>
      <ButtonGroup
        sx={{ padding: 0, height: "1rem", alignItems: "center" }}
        ref={checkboxRef}
      >
        <Checkbox
          disableRipple
          checked={checked}
          indeterminate={indeterminate}
          size="small"
          disabled={disabled}
          onClick={() => {
            checked ? handleBulkSelect("") : handleBulkSelect("all");
          }}
        />

        <StyledTextIconButton
          disableRipple
          size="small"
          disabled={disabled}
          {...bindTrigger(popupState)}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            popupState.toggle(e);
          }}
        >
          <KeyboardArrowDown
            sx={{
              fontSize: "1.125rem",
            }}
          />
        </StyledTextIconButton>
      </ButtonGroup>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: "left",
        }}
        onClick={(e) => {
          popupState.toggle(e);
        }}
      >
        <MenuItem
          value="all"
          selected={selectionType === "all"}
          onClick={() => {
            newHandleChangeSelectionType("all");
          }}
        >
          {lang.txTable.all}
        </MenuItem>
        {availableOptions.filter(
          (option) =>
            isTrade(option) && [Trade.In, Trade.Out].includes(option),
        ).length ? (
          <MenuItem
            value="uncategorised"
            selected={selectionType === "uncategorised"}
            onClick={() => {
              newHandleChangeSelectionType("uncategorised");
            }}
          >
            {lang.txTable.uncategorised}
          </MenuItem>
        ) : null}
        {availableOptions.map((option) => (
          <MenuItem
            key={option}
            value={option}
            selected={selectionType === option}
            onClick={() => {
              newHandleChangeSelectionType(option);
            }}
          >
            {getActionTypeName({
              actionType: option,
              lang,
            })}
          </MenuItem>
        ))}
        <MenuItem
          value=""
          selected={selectionType === ""}
          onClick={() => {
            newHandleChangeSelectionType("");
          }}
        >
          {lang.txTable.none}
        </MenuItem>
      </Popover>
    </>
  );
};

const StyledTextIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.tokens.text.default};
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.25rem;

  &:hover {
    z-index: 1;
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.hover};
    color: ${({ theme }) => theme.tokens.text.high};
  }

  &.Mui-focusVisible {
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.hover};
    color: ${({ theme }) => theme.tokens.text.high};
  }
`;
