import { Plan } from "@ctc/types";
import { useMemo } from "react";

import { UserFeature } from "~/components/payment/enums";
import { useUserFeatures } from "~/components/payment/PlanSection";
import { useRetailPlanPriceData } from "~/components/plans/hooks/usePriceDataByPlanType";
import { PlanContainer } from "~/components/plans/PlanContainer";
import { PlanRank } from "~/constants/constants";
import { useLang } from "~/redux/lang";
import { Features } from "~/types/enums";

export function RetailPlanContainer({
  setSelectedPlanType,
  selectedPlanType,
}: {
  setSelectedPlanType: (plan: Plan) => void;
  selectedPlanType: Plan;
}) {
  const { priceData: retailPlans, getSelectedPlanData } =
    useRetailPlanPriceData();
  const lang = useLang();
  const { getUserFeature } = useUserFeatures({ planType: selectedPlanType });
  // Use the user feature so it's aligned with what we show in retail user plan page
  const userFeatures = [
    UserFeature.OnChain,
    UserFeature.SmartContract,
    UserFeature.LeastTaxFirstOut,
    UserFeature.AdvancedTaxReports,
    UserFeature.TaxLossHarvesting,
    UserFeature.AuditReport,
    UserFeature.TradingStockReport,
  ];

  const additionalFeatures = [
    ...userFeatures.map((feature) => {
      const featureDetails = getUserFeature(feature);
      return {
        text: featureDetails.label,
        available: !!featureDetails.enabled,
      };
    }),
    {
      text: lang.payment.base.copy.highlightFeatures.prioritySupport,
      available: selectedPlanType === Plan.Trader,
    },
  ];

  const highlightedFeatures = [Features.MultiTaxYears];

  const sortedPlans = useMemo(() => {
    return retailPlans.sort(
      (a, b) => PlanRank[a.planType] - PlanRank[b.planType],
    );
  }, [retailPlans]);

  return (
    <PlanContainer
      plans={sortedPlans}
      highlightedFeatures={highlightedFeatures}
      setSelectedPlanType={setSelectedPlanType}
      additionalFeatures={additionalFeatures}
      defaultPlanData={getSelectedPlanData(selectedPlanType)}
    />
  );
}
