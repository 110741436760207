import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";

import { queryClient } from "~/components/queryClient";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import {
  isFilterEmpty,
  useTransactionFilter,
} from "~/components/transactions/filter-bar/FilterContext";
import { useGetActionsQuery } from "~/state/actions";
import { FilterOperators } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

// Stores map of JSON.stringify(filterQuery) -> transactionId[]
const filterSessionMap = new Map<string, string[]>();

export function clearFilterSession() {
  const sessionFilterKey = filterSessionMap.keys().next().value as
    | string
    | undefined;
  const sessionFilter = sessionFilterKey
    ? JSON.parse(sessionFilterKey)
    : undefined;

  if (sessionFilter) {
    queryClient.invalidateQueries({
      predicate: (query) => {
        // find any cached data that matches that session filter
        // and clear it
        const { queryKey } = query;
        if (
          queryKey[0] === "actions" &&
          queryKey[1] === "lists" &&
          queryKey[2] === "query"
        ) {
          const queryPart = queryKey[3] as { filter: FilterQuery };
          if (isEqual(queryPart.filter, sessionFilter)) {
            return true;
          }
        }
        return false;
      },
    });
  }
  filterSessionMap.clear();
}
export function getFilterSessionTransactionIds(
  filter: FilterQuery | undefined,
) {
  if (!filter || isFilterEmpty(filter)) {
    return undefined;
  }
  const result = filterSessionMap.get(JSON.stringify(filter));
  return result;
}
export function updateFilterSessionTransactionIds(
  filter: FilterQuery | undefined,
  transactionIds: string[],
) {
  if (!filter || isFilterEmpty(filter)) {
    return undefined;
  }

  // Temporarily disabling all filter sessions to cut the
  // complexity of our action queries
  return undefined;

  const existing = filterSessionMap.get(JSON.stringify(filter)) ?? [];

  const remainingExisting = truncateExisting(existing, transactionIds.length);
  const newTxIds = uniq([...remainingExisting, ...transactionIds]);
  filterSessionMap.set(JSON.stringify(filter), [...newTxIds]);

  /**
   * Given the amount of txids we are adding to the filter session, truncate
   * the existing ids so we never keep more than 50 of existing transactions.
   *
   * We do let you add more than 50, so you can add 250, but then next time
   * if you want to add 1 more, it will remove the 250 from existing, and you
   * will be left with 1.
   *
   * @param existingTxIds The existing txids in the filter session
   * @param countOfNewTxIds Number of new txs being added to filter session
   * @returns
   */
  function truncateExisting(existingTxIds: string[], countOfNewTxIds: number) {
    // the maximum number of transaction ids to store from previous session
    const MAX_COUNT = 50;

    if (existingTxIds.length + countOfNewTxIds < MAX_COUNT) {
      return existingTxIds;
    }
    const amountOfExistingToKeep = Math.max(MAX_COUNT - countOfNewTxIds, 0);
    return existingTxIds.slice(existingTxIds.length - amountOfExistingToKeep);
  }
}

const useGetCurrentFilter = () => {
  const {
    state: { filter },
  } = useTransactionFilter();
  return filter;
};

export function useUpdateFilterSessionTransactionIds() {
  const tableFilter = useGetCurrentFilter();
  return (transactionIds: string[]) => {
    updateFilterSessionTransactionIds(tableFilter, transactionIds);
  };
}

export const useBulkEditTransactionIds = () => {
  const filter = useBulkFilter();
  const query = useGetActionsQuery();

  return (
    query.data?.transactions.flatMap((actionRow) => {
      if (
        filter?.type === FilterOperators.ActionId &&
        !filter.value.includes(actionRow._id)
      ) {
        // action ids, only return the selected actions
        return [];
      }
      return [
        ...actionRow.incoming,
        ...actionRow.outgoing,
        ...actionRow.fees,
      ].map((tx) => tx._id);
    }) ?? []
  );
};

export function useBulkUpdateFilterSessionTransactionIds() {
  const transactionIdsBeingUpdated = useBulkEditTransactionIds();
  const tableFilter = useGetCurrentFilter();
  return () => {
    updateFilterSessionTransactionIds(tableFilter, transactionIdsBeingUpdated);
  };
}
