export const sizes = {
  phone: "400px",
  tabletS: "600px",
  tablet: "768px",
  tabletL: "900px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "1600px",
};

export const devices = {
  phone: `(min-width: ${sizes.phone})`,
  tabletS: `(min-width: ${sizes.tabletS})`,
  tablet: `(min-width: ${sizes.tablet})`,
  tabletL: `(min-width: ${sizes.tabletL})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
