import {
  type CostBasisRedistributionMethod,
  type InventoryMethod,
} from "@ctc/types";

import { deleteRequest, get, post, put } from "~/services/core";

export type TaxPeriodDTO = {
  id: string;
  isComplete: boolean;
  startDate: Date;
  endDate: Date;
  inventoryMethod: InventoryMethod;
  specificInventoryByExchange: boolean;
  costBasisRedistributionMethod: CostBasisRedistributionMethod;
  isLocked: boolean;
};

export function getAllTaxPeriods() {
  const path = "/tax-period";
  return get<TaxPeriodDTO[]>(path);
}

export function createNewPeriod({
  endDate,
  isLocked,
}: {
  endDate: Date;
  isLocked: boolean;
}) {
  const path = "/tax-period";
  return post<true>(path, { endDate, isLocked });
}

export function deletePeriod({ id }: { id: string }) {
  const path = `/tax-period/${id}`;
  return deleteRequest(path);
}

export function updatePeriod({
  id,
  update,
}: {
  id: string;
  update: { isLocked: boolean };
}) {
  const path = `/tax-period/${id}`;
  return put<true>(path, update);
}
