import { Typography } from "@mui/material";

import { Ellipsis } from "~/components/transactions/Ellipsis";
import { formatDisplayAddress } from "~/components/transactions/helpers";
import { CensoredBox } from "~/components/ui/CensoredComponents";
import { useDesign } from "~/hooks/useTheme";

export const CensoredEllipsisAddress = ({
  name,
  address,
}: {
  name?: string;
  address: string;
}) => {
  const { tokens } = useDesign();
  const parts = [];
  if (name) parts.push(name);
  parts.push(formatDisplayAddress(address, false, true));

  return (
    <CensoredBox display="flex" alignItems="center">
      <Ellipsis maxWidth={150} disableMargin>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "0.75rem",
            color: tokens.text.default,

            textUnderlineOffset: "2px",
          }}
        >
          {parts.join(" ")}
        </Typography>
      </Ellipsis>
    </CensoredBox>
  );
};
