import { Box, type SxProps, type Theme, Typography } from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

const Form = styled.div`
  && {
    width: 100%;
  }
  .MuiSelect-select.MuiSelect-select {
    color: ${({ theme }) => theme.tokens.text.default};
  }
  .MuiSelect-icon {
    color: ${({ theme }) => theme.tokens.text.default};
    top: calc(50% - 0.75rem);
  }
  .MuiInput-underline:before {
    border-bottom: none;
  }
`;

export function IconlessDropdown({
  children,
  title,
  titleStyle,
}: {
  children: React.ReactNode;
  title: string;
  titleStyle?: SxProps<Theme>;
}) {
  return (
    <>
      <Typography
        variant="Metropolis/Body/Light"
        sx={{
          marginBottom: "0.25rem",
          ...titleStyle,
        }}
      >
        {title}
      </Typography>
      <Box display="inline-flex" width="100%">
        <Form>{children}</Form>
      </Box>
    </>
  );
}
