import { type Blockchain } from "@ctc/types";
import { OpenInNew } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import Identicon from "@polkadot/react-identicon";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, type LinkProps } from "react-router-dom";

import { MarkAsEscrow } from "~/components/transactions/entity/MarkAsEscrow";
import { getAddressLink, getBlockie } from "~/components/transactions/helpers";
import { LazyLoadImageWithFallback } from "~/components/transactions/ImageWithFallback";
import { Address } from "~/components/ui/Address";
import { EntityAddresses } from "~/components/ui/EntityAddresses";
import { getBlockchainLogoSrc } from "~/components/ui/ExchangeLogo";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { IdentifyAddress } from "~/components/ui/IdentifyAddress";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useAutoAnimate } from "~/hooks/useAutoAnimate";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useEntityLookup } from "~/state/entities";
import { Links } from "~/types/enums";
import { isPolkadotCompatibleChain } from "~/types/index";

enum DialogType {
  RefAddress = "REF_ADDRESS",
  ManageAddress = "MANAGE_ADDRESS",
  IdentifyAddress = "IDENTIFY_ADDRESS",
}

export function ManageEntityDialog({
  isOpen,
  handleClose,
  address,
  blockchain,
  isSmartContract,
}: {
  isOpen: boolean;
  handleClose: () => void;
  address: string;
  blockchain?: Blockchain;
  isSmartContract?: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const addressLink = getAddressLink(address, undefined, blockchain);
  const existingEntity = useEntityLookup(address, blockchain);

  const isRefMatch =
    existingEntity &&
    "ref" in existingEntity &&
    existingEntity.ref.toLowerCase() === address.toLowerCase();

  const state = isRefMatch
    ? DialogType.RefAddress
    : existingEntity
      ? DialogType.ManageAddress
      : DialogType.IdentifyAddress;

  const [animationParent] = useAutoAnimate({
    duration: 180,
  });

  const title = {
    [DialogType.IdentifyAddress]: lang.tag.identifyAddressModal.title,
    [DialogType.ManageAddress]: lang.tag.manageAddressModal.title,
    [DialogType.RefAddress]: lang.tag.refAddressModal.title({
      entityDisplayName: existingEntity?.displayName ?? "this is a test",
    }),
  };
  return (
    <GeneralDialog
      isOpen={isOpen}
      title={title[state]}
      handleClose={() => {
        handleClose();
      }}
      cancelText={
        state === DialogType.ManageAddress
          ? lang.tag.manageAddressModal.doneButton
          : lang.tag.identifyAddressModal.doneButton
      }
      leftButton={
        state === DialogType.IdentifyAddress ? (
          <ImportWalletButton address={address} blockchain={blockchain} />
        ) : null
      }
      stopPropagation
      closeOnClickAway
    >
      <Box minWidth="24.5rem">
        <Stack direction="column" gap="0.75rem" ref={animationParent}>
          {state === DialogType.IdentifyAddress ? (
            <Box
              sx={{
                fontSize: "0.875rem",
                color: tokens.text.default,
              }}
            >
              {lang.tag.identifyAddressModal.description}
            </Box>
          ) : null}

          {state !== DialogType.RefAddress && (
            <Address
              address={address}
              blockchain={blockchain}
              addressIcon={
                <WalletImage address={address} blockchain={blockchain} />
              }
              addressLinkExternal={addressLink}
            />
          )}

          {state === DialogType.ManageAddress ? <Divider /> : null}

          {state === DialogType.RefAddress && existingEntity ? (
            <Box
              sx={{
                fontSize: "0.875rem",
                color: tokens.text.default,
              }}
            >
              {lang.tag.refAddressModal.description({
                entityDisplayName: existingEntity?.displayName,
              })}
            </Box>
          ) : null}

          {state === DialogType.ManageAddress && existingEntity ? (
            <Box
              sx={{
                fontSize: "0.875rem",
                color: tokens.text.default,
              }}
            >
              {isSmartContract
                ? lang.tag.manageAddressModal.descriptionContract({
                    entityDisplayName: existingEntity.displayName,
                  })
                : lang.tag.manageAddressModal.description({
                    entityDisplayName: existingEntity.displayName,
                  })}
            </Box>
          ) : null}

          {!existingEntity ? (
            <IdentifyAddress addresses={[{ address, blockchain }]} />
          ) : null}

          {existingEntity ? (
            <EntityAddresses
              currentAddress={isRefMatch ? undefined : { address, blockchain }}
              entity={existingEntity}
              isTreeView
            />
          ) : null}

          {state !== DialogType.IdentifyAddress && existingEntity ? (
            <MarkAsEscrow entity={existingEntity} />
          ) : null}
        </Stack>
      </Box>
    </GeneralDialog>
  );
}

export function WalletImage({
  address,
  blockchain,
  width = 20,
  height = 20,
  margin = "0",
  disableBlockchainLogo = false,
}: {
  address: string;
  blockchain?: string;
  height?: number;
  width?: number;
  margin?: string;
  disableBlockchainLogo?: boolean;
}) {
  const { tokens } = useDesign();
  if (address.length < 15) return null;

  const src = getBlockie(address);
  const blockchainSrc = getBlockchainLogoSrc(blockchain);

  if (!src) return null;

  return (
    <Box
      margin={margin}
      height={height}
      display="inline-flex"
      position="relative"
    >
      {blockchain && isPolkadotCompatibleChain(blockchain) ? (
        <Identicon
          value={address}
          theme="polkadot"
          size={width}
          style={{ cursor: "auto" }}
        />
      ) : (
        <LazyLoadImage
          height={height}
          width={width}
          src={src}
          alt={`${address}-logo`}
          effect="blur"
          style={{ borderRadius: width / 2 }}
        />
      )}

      {blockchainSrc && !disableBlockchainLogo ? (
        <Box position="absolute" top={-height / 2} right={-height / 4}>
          <LazyLoadImageWithFallback
            height={height / 2}
            width={width / 2}
            src={blockchainSrc}
            alt={`${blockchain}-logo`}
            effect="blur"
            style={{
              borderRadius: width / 4,
              background: tokens.background.neutral.lowest.default,
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
}

const ImportWalletButton = ({
  address,
  blockchain,
}: {
  address: string;
  blockchain?: Blockchain;
}) => {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const to: LinkProps["to"] = blockchain
    ? {
        pathname: `${Links.ImportNew}/${blockchain}`,
        search: new URLSearchParams({
          address,
        }).toString(),
      }
    : Links.Imports;

  return (
    <TextButton
      {...{
        component: Link,
        to,
        target: isEmbedded ? "_self" : "_blank",
      }}
      endIcon={<OpenInNew />}
    >
      {lang.txTable.savedAddresses.importWallet}
    </TextButton>
  );
};
