import { EntityType } from "~/types/enums";
import { type Entity } from "~/types/index";

export function getAllAddresses(entity: Entity | undefined) {
  if (!entity) {
    return [];
  }
  return [
    // add the user addresses
    ...entity.addresses,
    // add the global addresses
    ...(entity.type !== EntityType.Manual ? entity.globalAddresses : []),
  ];
}
