import { Check, ContentCopyOutlined } from "@mui/icons-material";
import { useState } from "react";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { CensoredTooltip } from "~/components/ui/CensoredComponents";
import { useCopyToClipboard } from "~/hooks/useCopyToClipboard";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

interface CopyIconButtonProps {
  contentToCopy: string;
  fontSize?: string;
  handleClick?: () => void;
  enableTooltip?: boolean;
  width?: number;
  color?: string;
  noPadding?: boolean;
  copyText?: string;
}

export const CopyIconButton: React.FC<CopyIconButtonProps> = ({
  contentToCopy,
  fontSize,
  handleClick,
  enableTooltip = true,
  width = 18,
  color,
  noPadding,
  copyText,
}) => {
  const { tokens } = useDesign();
  const [hoverTooltipIsOpen, setHoverTooltipIsOpen] = useState(false); // hover tooltip

  const handleCopyClick = () => {
    setHoverTooltipIsOpen(false);
    if (handleClick) {
      handleClick();
    }
  };

  const { copied, onClick } = useCopyToClipboard(
    contentToCopy,
    handleCopyClick,
  );
  const lang = useLang();

  const showCopyTooltip = enableTooltip ? hoverTooltipIsOpen && !copied : false;
  const showCopiedTooltip = enableTooltip ? copied : false;

  const CopyTitle = (
    <span style={{ width: "fit-content", fontSize }}>
      {copyText || lang.copy}
    </span>
  );

  const CopiedTitle = (
    <span style={{ display: "flex", alignItems: "center", fontSize }}>
      <Check style={{ fontSize: "1.5em", marginTop: "-0.1em" }} />
      <span style={{ marginLeft: "0.25em" }}>{lang.copied}</span>
    </span>
  );

  const TooltipTitle = showCopiedTooltip ? CopiedTitle : CopyTitle;

  return (
    <CensoredTooltip
      title={TooltipTitle}
      open={showCopyTooltip || showCopiedTooltip}
      onOpen={
        !copied
          ? () => {
              setHoverTooltipIsOpen(true);
            }
          : undefined
      }
      onClose={
        !copied
          ? () => {
              setHoverTooltipIsOpen(false);
            }
          : undefined
      }
    >
      <TextIconButton
        onClick={onClick}
        size="small"
        sx={{ p: noPadding ? 0 : undefined }}
      >
        <ContentCopyOutlined
          fontSize="inherit"
          style={{ color: color ?? tokens.icon.default, width }}
        />
      </TextIconButton>
    </CensoredTooltip>
  );
};
