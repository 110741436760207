import { type Trade } from "@ctc/types";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Popover, Typography } from "@mui/material";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { useCallback } from "react";

import { Severity } from "~/components/transactions/action-row/enums";
import { WarningDot } from "~/components/transactions/action-row/Warnings";
import { useCommandPaletteBones } from "~/components/transactions/command-palette/useCommandPalleteBones";
import { CategoryBucketSelector } from "~/components/transactions/command-palette/views/recategorise/TopLevelTxCategory";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { isTrade, isUncategorisedTrade } from "~/services/transactions";
import { type ActionRow } from "~/types/index";

export function EditTxTradeButton({
  row,
  selectedTrade,
  onChange,
  showAccountSymbol = undefined,
}: {
  row: ActionRow;
  selectedTrade: Trade;
  showAccountSymbol?: React.ReactNode;
  onChange: (value: Trade) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const taxSettings = useTaxSettings();
  const { modal, open, close, current, views, pop } = useCommandPaletteBones();

  const handleClose = useCallback(
    // union is coming from mui
    (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
      // if you are loading, you cant close it
      if (modal.loading) {
        return;
      }
      // at the root page, so escape gets you out
      if (reason === "backdropClick" || views.length === 1) {
        close();
        return;
      }
      // not at the root page, so go back 1 view
      pop();
    },
    [close, modal.loading, pop, views.length],
  );

  if (!taxSettings) return null;

  const root = (
    <CategoryBucketSelector
      row={row}
      selectedFromTrade={selectedTrade}
      taxSettings={taxSettings}
      onSubmit={(trade) => {
        if (!trade || !isTrade(trade)) return;

        onChange(trade);
        close();
      }}
    />
  );

  const Icon = TradeIcons[selectedTrade];

  return (
    <>
      <Popover
        {...bindPopover(modal)}
        PaperProps={{
          sx: {
            maxWidth: "22.5rem",
            width: "100%",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "left",
        }}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        onClose={handleClose}
      >
        <Box
          sx={{
            backgroundColor: tokens.elevation.medium,
          }}
        >
          {current}
        </Box>
      </Popover>
      <TextButton
        size="small"
        {...bindTrigger(modal)}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          e.currentTarget.blur();
          open(root, e);
        }}
        startIcon={<Icon sx={{ color: `${tokens.text.default} !important` }} />}
        endIcon={
          isUncategorisedTrade(selectedTrade) ? (
            <WarningDot severity={Severity.Warn} />
          ) : null
        }
      >
        <Box display="flex" alignItems="center" gap="0.5rem">
          {showAccountSymbol}
          <Typography variant="IBM Plex Mono/Caption/Medium/Regular">
            {lang.tradeType[selectedTrade]}
          </Typography>
          <KeyboardArrowDown
            sx={{
              fontSize: "1rem",
              color: tokens.text.default,
              mt: "0.125rem",
              ml: "-0.25rem",
            }}
          />
        </Box>
      </TextButton>
    </>
  );
}
