import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { AccountChip } from "~/components/transactions/command-palette/views/change-destination-account/AccountChip";
import { Confirmation } from "~/components/transactions/command-palette/views/change-destination-account/Confirmation";
import { type Party } from "~/components/transactions/command-palette/views/change-destination-account/types";
import { getOptionsFromExchangeOptions } from "~/components/transactions/command-palette/views/change-source-account/getOptionsFromExchangeOptions";
import { useLang } from "~/redux/lang";
import { useLoadExchangeOptions } from "~/state/exchanges";

export const ToDestinationAccount = ({ fromParty }: { fromParty: Party }) => {
  const { push } = useNavController();
  // https://linear.app/ctc/issue/GEM-362/td-switch-exchange-loading-to-react-query
  const { data: exchangeOptions, isLoading } = useLoadExchangeOptions();
  const lang = useLang();

  // get the from currencies
  const options = exchangeOptions
    ? getOptionsFromExchangeOptions(exchangeOptions, fromParty)
    : [];

  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.DestinationAccount} />
          <AccountChip party={fromParty} />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.changeTo}
      disabled={isLoading}
      loading={isLoading}
      options={options}
      onSelection={({ toParty }) => {
        push(<Confirmation fromParty={fromParty} toParty={toParty} />);
      }}
    />
  );
};
