import { useEffect, useState } from "react";

/**
 * Hook to detect if the current browser is Chromium-based (Chrome, Edge, Opera, etc.)
 * @returns {boolean} True if the browser is Chromium-based, false otherwise
 */
export function useIsChromium() {
  const [isChromium, setIsChromium] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    // Check for Chromium, Chrome, Edge, or Opera user agent
    const chromiumRegex = /chrome|chromium|crios|edge|opera/g;
    setIsChromium(chromiumRegex.test(userAgent));
  }, []);

  return isChromium;
}
