import { Trade } from "@ctc/types";
import { type Blockchain } from "@ctc/types";
import { type ReactNode } from "react";

import { Account } from "~/components/transactions/action-row/Account";
import { type ActionRowFeeType } from "~/components/transactions/action-row/types";
import { getPartyDetails, type PartyDetails } from "~/services/transactions";
import { type Side } from "~/types/enums";
import { type ActionRow, type TransactionDetails } from "~/types/index";

const generateKey = (partyDetails: PartyDetails) =>
  `${partyDetails.exchange}-${partyDetails.displayName}`;

export function getAccounts({
  row,
  side,
  txs,
  otherSideTxs,
}: {
  row: ActionRow;
  side: Side;
  txs: TransactionDetails[];
  otherSideTxs: TransactionDetails[];
}) {
  const accounts = [];

  if (txs.length === 0 && otherSideTxs.length > 0) {
    const details = getPartyDetails(otherSideTxs[0], side);
    accounts.push(
      <Account
        row={row}
        key={generateKey(details)}
        exchange={details.exchange}
        displayName={details.displayName}
        currencySymbol={otherSideTxs[0].currencyIdentifier.symbol}
        blockchain={otherSideTxs[0].blockchain as Blockchain}
        showDepositIcon={false}
        trade={otherSideTxs[0].trade}
        importType={otherSideTxs[0].importType}
        isSmartContract={details.isSmartContract}
        side={side}
      />,
    );
  } else if (txs?.[0]) {
    const details = getPartyDetails(txs[0], side);
    accounts.push(
      <Account
        row={row}
        key={generateKey(details)}
        exchange={details.exchange}
        displayName={details.displayName}
        currencySymbol={txs[0].currencyIdentifier.symbol}
        blockchain={txs[0].blockchain as Blockchain}
        showDepositIcon={false}
        trade={txs[0].trade}
        importType={txs[0].importType}
        isSmartContract={details.isSmartContract}
        side={side}
      />,
    );
  }

  return accounts;
}

export function feeAccounts({
  row,
  fees,
}: {
  row: ActionRow;
  fees: ActionRowFeeType[];
}) {
  const accountsNames: string[] = [];
  const accounts: ReactNode[] = [];

  fees.forEach((fee) => {
    if (!accountsNames.includes(fee.exchange)) {
      accountsNames.push(fee.exchange);
      accounts.push(
        <Account
          row={row}
          key={generateKey({
            exchange: fee.exchange,
            displayName: fee.displayName,
            blockchain: undefined,
          })}
          exchange={fee.exchange}
          displayName={fee.displayName}
          currencySymbol={fee.currency.symbol}
          trade={Trade.Fee}
        />,
      );
    }
  });

  return accounts;
}
