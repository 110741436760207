import { Country } from "@ctc/types";
import {
  Box,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { type ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components/macro";

import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";

enum RadioButtonValue {
  Custom = "custom",
  Default = "default",
  Never = "never",
}

type LongTermThresholdSelectorProps = {
  disabled?: boolean;
  defaultValue?: number;
  value: number;
  onChange: (longTermThreshold: number) => void;
  onSave?: (longTermThreshold: number) => void;
};

const countriesWithoutLongTermThreshold = [Country.France, Country.Italy];

function getRadioButtonValue(
  value: number,
  defaultValue?: number,
): RadioButtonValue {
  let result: RadioButtonValue = RadioButtonValue.Custom;
  if (defaultValue === value || value === undefined) {
    result = RadioButtonValue.Default;
  } else if (value === 0) {
    result = RadioButtonValue.Never;
  }

  return result;
}

export function LongTermThresholdSelector({
  disabled = false,
  defaultValue,
  value,
  onChange,
  onSave = () => {},
}: LongTermThresholdSelectorProps) {
  const lang = useLang();
  const country = useCountry();
  const [radioButtonValue, setRadioButtonValue] = useState<RadioButtonValue>(
    () => {
      if (!!defaultValue && value === defaultValue) {
        return RadioButtonValue.Default;
      } else if (value === 0) {
        return RadioButtonValue.Never;
      }
      return RadioButtonValue.Custom;
    },
  );
  const [customValue, setCustomValue] = useState<"" | number>(value);
  const [autoFocus, setAutoFocus] = useState(false);

  useEffect(() => {
    if (disabled) {
      setRadioButtonValue(getRadioButtonValue(value, defaultValue));
    }
  }, [defaultValue, value, disabled]);

  // France don't have a long term threshold
  if (country && countriesWithoutLongTermThreshold.includes(country)) {
    return null;
  }

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setRadioButtonValue(target.value as RadioButtonValue);
    setAutoFocus(true);

    let newValue = 0;

    switch (target.value) {
      case "never":
        onChange(0);
        break;
      case "custom":
        newValue = value;
        setCustomValue(value);
        break;
      default:
        if (defaultValue) {
          newValue = defaultValue;
          onChange(defaultValue);
        }
    }

    if (target.value !== "custom") {
      onSave(newValue);
    }
  };

  const handleBlur = () => {
    onSave(value);
  };

  const handleCustomValueChange = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    const customValue = +target.value || "";
    setCustomValue(customValue);
    onChange(customValue || 0);
  };

  return (
    <SettingsBox>
      <Typography variant="Metropolis/Header/H5">
        {lang.settings.longTermThreshold.longTermThreshold}
      </Typography>
      <Box mt="1rem">
        <RadioGroup
          aria-label={lang.settings.longTermThreshold.longTermThreshold}
          value={radioButtonValue}
          onChange={handleChange}
        >
          {defaultValue ? (
            <FormControlLabel
              disabled={disabled}
              value="default"
              control={<Radio color="primary" />}
              label={
                <SelectorTypography>
                  {lang.settings.longTermThreshold.default({ defaultValue })}
                </SelectorTypography>
              }
            />
          ) : null}
          <FormControlLabel
            disabled={disabled}
            value="never"
            control={<Radio color="primary" />}
            label={
              <SelectorTypography>
                {lang.settings.longTermThreshold.never}
              </SelectorTypography>
            }
          />
          <FormControlLabel
            disabled={disabled}
            value="custom"
            control={<Radio color="primary" />}
            label={
              <SelectorTypography>
                {lang.settings.longTermThreshold.custom}
              </SelectorTypography>
            }
          />
        </RadioGroup>
      </Box>
      {radioButtonValue === "custom" && (
        <TextField
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
          variant="outlined"
          value={customValue}
          onChange={handleCustomValueChange}
          onBlur={handleBlur}
          inputProps={{
            type: "number",
            min: 1,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {lang.settings.longTermThreshold.months}
              </InputAdornment>
            ),
          }}
        />
      )}
    </SettingsBox>
  );
}

const SelectorTypography = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.tokens.text.default};
`;
