import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { AssetAccountTopSection } from "~/components/transactions/command-palette/views/change-asset-account/AssetAccountTopSection";
import { invariant } from "~/lib/invariant";
import { type ErpAccount } from "~/redux/types";
import { useNewBulkOpMutation } from "~/state/actions";
import { BulkOperations } from "~/types/enums";
import { type CurrencyIdentifier } from "~/types/index";

export const Confirmation = ({
  assetCurrency,
  erpAssetAccount,
}: {
  assetCurrency: CurrencyIdentifier;
  erpAssetAccount: ErpAccount;
}) => {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");

  const bulkOp = useNewBulkOpMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.ChangeAssetAccount,
  });

  const onConfirm = () => {
    bulkOp.mutate(
      {
        filter,
        operation: {
          type: BulkOperations.ChangeErpAssetAccount,
          erpAccountCode: erpAssetAccount.code,
          assetCurrency,
        },
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangeAssetAccount} />
          <AssetAccountTopSection
            fromCurrency={assetCurrency}
            assetAccount={erpAssetAccount}
          />
        </BulkEditChips>
      }
      onConfirm={onConfirm}
      loading={bulkOp.isPending}
    />
  );
};
