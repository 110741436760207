import { Plan } from "@ctc/types";

import { getAlwaysAllowPaymentPlanType } from "~/components/settings-modal/views/plan/helpers/getAlwaysAllowPaymentPlantype";
import { getHighestPlanRankCurrentUser } from "~/services/user";
import {
  isBusinessPlanType,
  type PlanDataBase,
  type UserInfo,
} from "~/types/index";

export function getDefaultPlanType(
  user?: UserInfo | null,
  plans?: Partial<Record<Plan, PlanDataBase>>,
  isLimitedExceeded?: (plan: Plan) => boolean,
  override: "business" | "retail" | null = null,
) {
  if (!user) {
    return Plan.Investor;
  }
  const plan = getHighestPlanRankCurrentUser(user)?.paidPlan || Plan.Free;

  // User on BusinessPackage plan are beta user, they don't actually paid for the plan
  if (
    (isBusinessPlanType(plan) && override !== "retail") ||
    override === "business"
  ) {
    return Plan.Business;
  }

  if (plan === Plan.Free) {
    if (!plans) {
      return Plan.Investor;
    }

    // Check if transaction limit has been exceeded for the Investor plan
    if (isLimitedExceeded?.(Plan.Investor)) {
      const alwaysAllowedPaymentPlan = getAlwaysAllowPaymentPlanType(plans);
      if (alwaysAllowedPaymentPlan) {
        return alwaysAllowedPaymentPlan;
      }
    }
    return Plan.Investor;
  }

  return plan;
}
