import { Add } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { rulesIntegrationsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { DrawerInnerContent } from "~/components/contacts/DrawerInnerContent";
import { DisplayRuleCard, NewRuleCard } from "~/components/rules/RuleCard";
import { type DrawerRulesManagementForEntityProps } from "~/components/rules/types";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import {
  Drawer,
  DrawerTitle,
  StyledHeaderContent,
} from "~/components/ui/Drawer";
import { useDesign } from "~/hooks/useTheme";
import { useCanAccessFeature } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useGetRulesByIdsQuery } from "~/state/rules";
import { Features } from "~/types/enums";

export function DisplayRulesDrawerContent({
  rules,
  allowAdd = false,
  addingInitiallyOpen = false,
  initialName,
  initialFilter,
  initialOperations,
  handleAdd,
  enableTxCounts = false,
  hideHeader = false,
  editRuleId,
}: DrawerRulesManagementForEntityProps) {
  const { tokens } = useDesign();
  const lang = useLang();
  const [isAdding, setIsAdding] = useState(addingInitiallyOpen);
  const analyticsKey = rulesIntegrationsAnalyticsKey("modal");
  const captureAnalytics = useCaptureAnalytics();

  const isRulesDisabled = !useCanAccessFeature(Features.Rules);

  return (
    <Box display="flex" flexDirection="column" gap="0.5rem">
      {hideHeader ? null : (
        <Typography variant="Metropolis/Caption/Medium/Regular" color={tokens.text.low}>
          {lang.contacts.edit.rules}
        </Typography>
      )}

      {rules.map((rule) => {
        return (
          <DisplayRuleCard
            key={rule.id}
            rule={rule}
            enableTxCounts={enableTxCounts}
          />
        );
      })}

      {isAdding ? (
        <NewRuleCard
          handleClose={() => {
            setIsAdding(false);
            if (handleAdd) {
              handleAdd();
            }
          }}
          initialName={initialName}
          initialFilter={initialFilter}
          initialOperations={initialOperations}
          editRuleId={editRuleId}
        />
      ) : allowAdd ? (
        <Tooltip title={isRulesDisabled ? lang.contacts.ruleCard.tooltip : ""}>
          <Box>
            <TextButton
              size="small"
              onClick={() => {
                setIsAdding(true);
                captureAnalytics(analyticsKey("add rule clicked"));
              }}
              disabled={isRulesDisabled}
              startIcon={<Add />}
            >
              {rules.length
                ? lang.contacts.ruleCard.createMoreRules
                : lang.contacts.ruleCard.createRule}
            </TextButton>
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );
}

export function DisplayRulesDrawer({
  isOpen,
  setIsOpen,
  ...props
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
} & DrawerRulesManagementForEntityProps) {
  const lang = useLang();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <StyledHeaderContent>
        <DrawerTitle title={lang.contacts.rules.importsDrawerTitle} />
      </StyledHeaderContent>
      <DrawerInnerContent>
        <DisplayRulesDrawerContent {...props} />
      </DrawerInnerContent>
    </Drawer>
  );
}

export function RuleDrawerById({
  isOpen,
  setIsOpen,
  ruleIds,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  ruleIds: string[];
}) {
  const lang = useLang();
  const ruleQuery = useGetRulesByIdsQuery(ruleIds);
  const rules = ruleQuery.data;

  const ruleDrawerContent =
    rules && rules.length > 0 ? (
      <DisplayRulesDrawerContent rules={rules} />
    ) : (
      <Typography>{lang.contacts.rules.ruleNotFound}</Typography>
    );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <StyledHeaderContent>
        <DrawerTitle title={lang.contacts.rules.importsDrawerTitle} />
      </StyledHeaderContent>
      <DrawerInnerContent>
        {ruleQuery.isLoading ? (
          <Stack alignItems="center" justifyContent="center" height="10rem">
            <CircularProgress />
          </Stack>
        ) : (
          ruleDrawerContent
        )}
      </DrawerInnerContent>
    </Drawer>
  );
}
