import { EmbeddedSignupForm } from "~/components/user/EmbeddedSignupForm";
import { SignupForm } from "~/components/user/SignupForm";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign } from "~/hooks/useTheme";
import { type Links } from "~/types/enums";

type SignupWrapperProps = {
  path: Links.Signup | Links.SignupAccountant;
};

export const SignupWrapper = ({ path }: SignupWrapperProps) => {
  const { tokens } = useDesign();

  document.body.style.backgroundColor = tokens.elevation.default;

  const isEmbedded = useIsEmbedded();

  // If we're in an embedded context, show the embedded form
  if (isEmbedded) {
    return <EmbeddedSignupForm />;
  }

  // Otherwise show the regular signup form
  return <SignupForm path={path} />;
};
