import { Check } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Stack, Typography } from "@mui/material";
import { createContext, useContext, useState } from "react";
import styled from "styled-components/macro";
import { StringParam, useQueryParam } from "use-query-params";

import { onboardingAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { TourProvider } from "~/components/onboarding-v2/Tour";
import { UncategorisedTableCalcoTour } from "~/components/reconciliation/components/UncategorisedTableCalcoTour";
import { ViewAssociatedTxButton } from "~/components/reconciliation/ViewAssociatedTxButton";
import {
  OnboardingReconUncategorisedActionRow,
  ReconUncategorisedActionRow,
  type TransactionActionRowProps,
} from "~/components/transactions/action-row/actionRow";
import { ReconUncategorisedActionRowHeader } from "~/components/transactions/action-row/ActionRowHeader";
import { getActionRowCounterParty } from "~/components/transactions/action-row/helpers";
import { ActionTypeSelector } from "~/components/transactions/ActionTypeSelector";
import { ModalProvider } from "~/components/transactions/command-palette/ModalProvider";
import { NavControllerProvider } from "~/components/transactions/command-palette/NavController";
import { ActionTypeSelectorButtonType } from "~/components/transactions/enums";
import {
  ContextIdType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import {
  FilterProvider,
  useTransactionFilter,
} from "~/components/transactions/filter-bar/FilterContext";
import { TransactionsTableWithFilters } from "~/components/transactions/TransactionsTableWithFilters";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { Calco } from "~/components/ui/calco/Calco";
import { LogoSpinner } from "~/components/ui/LogoSpinner";
import { devices } from "~/components/ui/theme/legacy";
import { useDesign } from "~/hooks/useTheme";
import { middleTrim } from "~/lib/index";
import { invariant } from "~/lib/invariant";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { isUncategorisedTrade } from "~/services/transactions";
import {
  useGetActionsQuery,
  useGetActionsQueryNoContext,
} from "~/state/actions";
import { ReconciliationIssues, Sort } from "~/types/enums";
import { type ActionRow } from "~/types/index";

export const UncategorisedActionContext = createContext<{
  actions: ActionRow[];
  onFinish?: () => void;
}>({ actions: [] });

function useUncategorisedActionContext() {
  const actionsQueryQuery = useGetActionsQuery();
  const [highlightedTransactionId, setHighlight] = useQueryParam(
    "highlight",
    StringParam,
  );
  const { actions: uncategorisedActions, onFinish } = useContext(
    UncategorisedActionContext,
  );

  const { dispatch } = useTransactionFilter();

  if (actionsQueryQuery.isLoading) {
    return undefined;
  }
  const displayedActions = actionsQueryQuery.data?.transactions ?? [];

  const selectedAction = highlightedTransactionId
    ? displayedActions.find((action) =>
        getActionRowHasTransactionId(action, highlightedTransactionId),
      )
    : undefined;

  /**
   * Selects a problem
   * Used when moving next/previous
   * @param row
   */
  function selectProblemAction(row: ActionRow) {
    const transactionId = getTransactionsFromAction(row)[0]._id;
    dispatch({
      type: FilterActionType.SetHighLight,
      highlight: transactionId,
    });
    setHighlight(`${transactionId}`);
    // is the next action already displayed on screen?
    // if so we don't need to do anything, just scroll to it
    const isActionToSelectAlreadyOnScreen = displayedActions.find((action) =>
      getActionRowHasTransactionId(action, transactionId),
    );
    if (isActionToSelectAlreadyOnScreen) {
      return;
    }
    // not on screen, so view in context it
    dispatch({
      type: FilterActionType.SetReconciliationViewOneByOneFilter,
      transactionId,
    });
  }

  if (!selectedAction) {
    // Selects the first uncategorised action
    selectProblemAction(uncategorisedActions[0]);
    return undefined;
  }

  const nextAction = getNextUncategorisedAction({
    selectedAction,
    uncategorisedActions,
    direction: NextUncategorisedActionDirection.Next,
  });

  const previousAction = getNextUncategorisedAction({
    selectedAction,
    uncategorisedActions,
    direction: NextUncategorisedActionDirection.Prev,
  });

  return {
    onFinish,
    uncategorisedActions,
    selectProblemAction,
    gotoPreviousAction: previousAction
      ? () => {
          selectProblemAction(previousAction);
        }
      : undefined,
    gotoNextAction: nextAction
      ? () => {
          selectProblemAction(nextAction);
        }
      : undefined,
  };
}

const reconType = ReconciliationIssues.UncategorisedTransactions;

/**
 * Shows uncategorised transactions one at a time
 */
export function UncategorisedTransactionsInContext({
  actionsQuery,
  onFinish,
}: {
  actionsQuery: Parameters<typeof useGetActionsQueryNoContext>[0];
  onFinish?: () => void;
}) {
  const [highlightedTransactionId] = useQueryParam("highlight", StringParam);
  const query = useGetActionsQueryNoContext(actionsQuery);
  const [uncategorisedActions, setUncategorisedActions] = useState<
    ActionRow[] | undefined
  >(undefined);
  if (query.isLoading) {
    return <LogoSpinner />;
  }
  if (query.data?.data.transactions.length === 0) {
    // there are no transactions to categorise
    // so we are done
    onFinish?.();
    return null;
  }

  if (uncategorisedActions === undefined) {
    // We need to keep a copy of the uncategorised actions, so that if the user
    // makes changes, we still have this initial list
    setUncategorisedActions(query.data?.data.transactions);
  }
  if (!uncategorisedActions) {
    // TODO: Need to handle this in the future
    return null;
  }

  return (
    <FilterProvider
      initialState={
        highlightedTransactionId
          ? {
              filter: undefined,
              viewInContextId: highlightedTransactionId,
              contextIdType: ContextIdType.TxId,
              highlight: highlightedTransactionId,
              sort: Sort.UncategorisedSizeDescending,
              count: 10,
            }
          : undefined
      }
    >
      <UncategorisedActionContext.Provider
        value={{
          actions: uncategorisedActions,
          onFinish,
        }}
      >
        <TourProvider numberOfSteps={6}>
          <UncategorisedNavigate />
        </TourProvider>
      </UncategorisedActionContext.Provider>
    </FilterProvider>
  );
}

/**
 * For an action, get all the transactions
 * @param row
 * @returns
 */
function getTransactionsFromAction(row: ActionRow) {
  return [...row.incoming, ...row.outgoing, ...row.fees];
}
/**
 * For an action, check if the action has a transaction Id
 * @param row
 * @param transactionId
 * @returns
 */
export function getActionRowHasTransactionId(
  row: ActionRow,
  transactionId: string,
) {
  return getTransactionsFromAction(row).find((tx) => tx._id === transactionId);
}

enum NextUncategorisedActionDirection {
  Next = "next",
  Prev = "prev",
}
/**
 * Gets the next or previous action based on timestamp
 * @param selectedAction
 * @param direction
 * @returns
 */
function getNextUncategorisedAction({
  selectedAction,
  direction,
  uncategorisedActions,
}: {
  selectedAction: ActionRow;
  uncategorisedActions: ActionRow[];
  direction: NextUncategorisedActionDirection;
}) {
  const selectedTx = getTransactionsFromAction(selectedAction)[0];

  const index = uncategorisedActions.findIndex((action) => {
    if (getTransactionsFromAction(action)[0]._id === selectedTx._id) {
      return true;
    }
    return false;
  });
  invariant(index !== -1, "Could not find the selected action in the list");
  if (direction === NextUncategorisedActionDirection.Prev && index === 0) {
    // already at the first one
    return undefined;
  }
  if (
    direction === NextUncategorisedActionDirection.Next &&
    index === uncategorisedActions.length - 1
  ) {
    // already at the last one
    return undefined;
  }
  if (direction === NextUncategorisedActionDirection.Next) {
    return uncategorisedActions[index + 1];
  }
  return uncategorisedActions[index - 1];
}

const BottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.high};
  background: ${({ theme }) => theme.tokens.elevation.low};
  @media ${devices.tabletL} {
    padding-right: 6.25rem;
  }
`;

function NavigateButtons() {
  const controls = useUncategorisedActionContext();
  const lang = useLang().reconciliation.issues[reconType];
  const captureAnalytics = useCaptureAnalytics();
  const analyticKey = onboardingAnalyticsKey(
    "reconciliation uncategorised navigate buttons",
  );
  if (!controls) {
    return (
      <>
        <SecondaryButton disabled>
          {lang.inContext.previousTransaction}
        </SecondaryButton>
        <SecondaryButton disabled>
          {lang.inContext.nextTransaction}
        </SecondaryButton>
      </>
    );
  }

  const { gotoNextAction, gotoPreviousAction, onFinish } = controls;

  return (
    <>
      <SecondaryButton
        disabled={!gotoPreviousAction}
        onClick={() => {
          captureAnalytics(analyticKey("clicked previous"));
          if (gotoPreviousAction) {
            gotoPreviousAction();
            return;
          }
        }}
      >
        {lang.inContext.previousTransaction}
      </SecondaryButton>
      <SecondaryButton
        onClick={() => {
          if (gotoNextAction) {
            captureAnalytics(analyticKey("clicked next"));
            gotoNextAction();
            return;
          }
          if (onFinish) {
            captureAnalytics(analyticKey("clicked finish"));
            onFinish();
            return;
          }
        }}
      >
        {!gotoNextAction && onFinish
          ? lang.inContext.finish
          : lang.inContext.nextTransaction}
      </SecondaryButton>
    </>
  );
}

export function UncategorisedNavigate() {
  return (
    <div>
      <BottomBar>
        <NavigateButtons />
      </BottomBar>
      <Stack direction="column" gap="0.5rem">
        <div>
          <TransactionsTableWithFilters
            isCountEnabled={false}
            showFilterBar={false}
            header={<ReconUncategorisedActionRowHeader />}
            row={UncategorisedRecActionRowOneByOne}
            disablePaginationFooter
          />
        </div>
      </Stack>
    </div>
  );
}

function UncategorisedRecActionRowOneByOne({ row }: TransactionActionRowProps) {
  const user = useUser();
  const [highlight] = useQueryParam("highlight", StringParam);
  const isActive = getActionRowHasTransactionId(row, highlight ?? "");
  const { tokens } = useDesign();
  const lang = useLang();
  const isUncategorised = isUncategorisedTrade(row.type);
  const reconLang = lang.reconciliation.issues[reconType];

  const counterParty = getActionRowCounterParty(row);
  const counterPartyDisplayName = counterParty
    ? middleTrim(counterParty.displayName)
    : "";
  return (
    <Box
      sx={{
        border: isActive
          ? `1px solid ${tokens.background.accent.purple.medium}`
          : null,
      }}
    >
      {isActive ? (
        <Stack
          direction="row"
          alignItems="center"
          gap="0.25rem"
          css={`
            border-bottom: 1px solid ${tokens.background.accent.purple.medium};
            background: ${tokens.background.brand.default};
          `}
          justifyContent="space-between"
          padding="0 0.5rem 0 0 "
        >
          <Stack direction="row" gap="0.25rem" alignItems="center">
            <Box
              sx={{
                width: "2.375rem",
                height: "2.375rem",
              }}
            >
              {isUncategorised ? (
                <Calco type="wizard" />
              ) : (
                <Calco type="wizardStaff" />
              )}
            </Box>
            <UncategorisedTableCalcoTour
              title={
                lang.calcoTour.reconciliation.uncategorise.categoriesButton
              }
              stepNumber={5}
              row={row}
              placement="top"
            >
              <NavControllerProvider>
                <ModalProvider variant="popover" popupId="category-selector">
                  <ActionTypeSelector
                    row={row}
                    trigger={
                      isUncategorisedTrade(row.type)
                        ? ActionTypeSelectorButtonType.Primary
                        : ActionTypeSelectorButtonType.Secondary
                    }
                    uncatLabelOverride={
                      lang.similarTransactionsSuggestion.modal.buttonCTA
                    }
                    labelOverride={
                      <Stack direction="row" gap="0.5rem" alignItems="center">
                        <Typography variant="Metropolis/Body/Regular">
                          {reconLang.inContext.categorised}
                        </Typography>
                        <div
                          css={`
                            display: flex;
                            padding: 0.125rem 0.5rem;
                            justify-content: center;
                            align-items: center;
                            gap: 0.25rem;
                            border-radius: 1.5rem;
                            background: ${(props: any) =>
                              props.theme.tokens.background.success.default};
                          `}
                        >
                          <Check
                            css={`
                              width: 1rem;
                              height: 1rem;
                            `}
                          />
                        </div>
                      </Stack>
                    }
                  />
                </ModalProvider>
              </NavControllerProvider>
            </UncategorisedTableCalcoTour>
            <UncategorisedTableCalcoTour
              title={lang.calcoTour.reconciliation.uncategorise.associateTx({
                counterParty: counterPartyDisplayName ?? lang.theCounterParty,
              })}
              stepNumber={4}
              row={row}
              placement="top"
            >
              <ViewAssociatedTxButton row={row} />
            </UncategorisedTableCalcoTour>
          </Stack>

          <InRowNextButton row={row} />
        </Stack>
      ) : null}

      {user?.isOnboarding ? (
        <OnboardingReconUncategorisedActionRow
          row={row}
          isActive={!!isActive}
        />
      ) : (
        <ReconUncategorisedActionRow row={row} />
      )}
    </Box>
  );
}

function InRowNextButton({ row }: { row: ActionRow }) {
  const controls = useUncategorisedActionContext();
  const isUncategorised = isUncategorisedTrade(row.type);
  const lang = useLang().reconciliation.issues[reconType];
  const captureAnalytics = useCaptureAnalytics();
  const analyticKey = onboardingAnalyticsKey(
    "reconciliation uncategorised in row navigate buttons",
  );

  // no next (at the end) and no finish, show nothing
  // this is what appears on the recon page
  if (!controls?.gotoNextAction && !controls?.onFinish) {
    return null;
  }
  // no next + finish, is onboarding page
  if (!controls?.gotoNextAction && controls?.onFinish) {
    return (
      <PrimaryButton
        sx={{
          maxHeight: "1.75rem",
        }}
        onClick={() => {
          captureAnalytics(analyticKey("clicked finish"));
          if (controls.onFinish) {
            controls.onFinish();
            return;
          }
        }}
        endIcon={<ArrowForwardIcon />}
      >
        {lang.inContext.finish}
      </PrimaryButton>
    );
  }
  // yes next but uncategorised, so can skip
  if (controls?.gotoNextAction && isUncategorised) {
    return (
      <TextButton
        onClick={() => {
          captureAnalytics(analyticKey("clicked skip"));
          if (controls.gotoNextAction) {
            controls.gotoNextAction();
            return;
          }
        }}
        endIcon={<ArrowForwardIcon sx={{ height: "1rem", width: "1rem" }} />}
        sx={{ height: "1.75rem" }}
      >
        {lang.inContext.notSure}
      </TextButton>
    );
  }
  // yes next but categorised, so can next
  if (controls?.gotoNextAction) {
    return (
      <PrimaryButton
        sx={{
          maxHeight: "1.75rem",
        }}
        onClick={() => {
          captureAnalytics(analyticKey("clicked next"));
          if (controls.gotoNextAction) {
            controls.gotoNextAction();
            return;
          }
        }}
        endIcon={<ArrowForwardIcon />}
      >
        {lang.inContext.goToNext}
      </PrimaryButton>
    );
  }

  return null;
}
