import { Lock, LockOpen } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  FormControl,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  type UseFormReturn,
} from "react-hook-form";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureActionAnalytics } from "~/analytics/posthog";
import { BlockchainComboBox } from "~/components/transactions/BlockchainComboBox";
import {
  exchangeSourceValidator,
  isSameExchangeBlockchain,
  LockedTxEditFallback,
} from "~/components/transactions/edit/helper";
import { UnlockBlockchainDialog } from "~/components/transactions/edit/UnlockBlockchainDialog";
import { ExchangeComboBox } from "~/components/transactions/ExchangeComboBox";
import { GeneralDialogForm } from "~/components/ui/GeneralDialog";
import { ctcLightTheme as theme } from "~/components/ui/theme/mui";
import { TertiaryIconButton } from "~/components/ui/ui-buttons/icon-buttons/TertiaryIconButton";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import {
  type HyperlinkKeyToType,
  HyperlinkTextContent,
} from "~/lib/HyperlinkTextContent";
import { invariant } from "~/lib/invariant";
import { ActionDefinitions } from "~/lib/tradeTypeDefinitions";
import { useLang } from "~/redux/lang";
import { type PutTransactionPayload } from "~/services/actions";
import { useAtomicUpdateTransactions } from "~/state/actions";
import { type GroupedTrade, Links, type Side } from "~/types/enums";
import {
  type ActionData,
  type ActionRow,
  type ExchangeOption,
  type TransactionDetails,
} from "~/types/index";

export type EditActionAccountValues = {
  blockchainFrom?: string | null;
  blockchainTo?: string | null;
  from: ExchangeOption;
  to: ExchangeOption;
};

export const getValues = ({
  row,
}: {
  row: ActionRow;
}): EditActionAccountValues => {
  const groupActionDefinition: ActionData | undefined =
    ActionDefinitions[row.type as GroupedTrade];

  const isMultichain = groupActionDefinition?.isMultichain === true;
  const firstOutgoing = row.outgoing[0] as TransactionDetails | undefined;

  let from: TransactionDetails | undefined;
  let to: TransactionDetails | undefined;
  let blockchainTo: string | undefined;
  let blockchainFrom: string | undefined;

  if (isMultichain) {
    // Must come from independent sides.
    from = row.outgoing[0];
    to = row.incoming[0];
  } else if (groupActionDefinition?.isTransferLike === false) {
    if (firstOutgoing) {
      // @url https://cryptotaxcalculatorco.slack.com/archives/C02NHEC3SN8/p1683263683370289
      from = firstOutgoing;
      to = firstOutgoing;
    } else {
      // Some actions like multi-mint don't have any outgoing TX.
      from = row.incoming[0];
      to = row.incoming[0];
    }
  } else {
    from = row.outgoing[0] || row.incoming[0];
    to = row.incoming[0] || row.outgoing[0];
  }

  const txBCOutgoing = row.outgoing.find((tx) => tx.blockchain);
  const txBCIncoming = row.incoming.find((tx) => tx.blockchain);

  if (isMultichain) {
    // `to` MUST` come from outgoing.
    blockchainFrom = txBCOutgoing?.blockchain;
    blockchainTo = txBCIncoming?.blockchain;
  } else {
    blockchainFrom = txBCOutgoing?.blockchain || txBCIncoming?.blockchain;
    blockchainTo = txBCIncoming?.blockchain || blockchainFrom;
  }

  return {
    blockchainFrom,
    blockchainTo,
    from: {
      label: from ? from.from : "",
      name: from ? from.fromDisplayName : "",
      blockchain: from ? from.blockchain : "",
      currency: from ? from.currencyIdentifier.symbol : "",
    },
    to: {
      label: to ? to.to : "",
      name: to ? to.toDisplayName : "",
      blockchain: to ? to.blockchain : "",
      currency: to ? to.currencyIdentifier.symbol : "",
    },
  };
};

export const applyUpdates = (
  row: ActionRow,
  form: UseFormReturn<EditActionAccountValues>,
  formData: EditActionAccountValues,
  updateMap: Map<string, PutTransactionPayload>,
) => {
  const groupActionDefinition: ActionData | undefined =
    ActionDefinitions[row.type as GroupedTrade];

  const isFromDirtyField = form.formState.dirtyFields.from;
  const isToDirtyField = form.formState.dirtyFields.to;

  const update = (id: string, update: PutTransactionPayload) => {
    if (!updateMap.has(id)) updateMap.set(id, { _id: id });

    if (isFromDirtyField || isToDirtyField) {
      const value = updateMap.get(id);
      updateMap.set(id, { ...value, ...update });
    }
  };

  // Always handle fees, and make sure it always matches the source blockchain.
  row.fees.forEach((tx) => {
    update(tx._id, {
      blockchain: formData.from.blockchain || null,
    });
  });

  if (groupActionDefinition?.isMultichain === true) {
    // Can't assume to/from are the same on all tx, eg: bridge.
    row.outgoing.forEach((tx) => {
      update(tx._id, {
        to: formData.from.label,
        blockchain: formData.from.blockchain || null,
      });
    });

    row.incoming.forEach((tx) => {
      update(tx._id, {
        from: formData.to.label,
        blockchain: formData.to.blockchain || null,
      });
    });
  } else if (groupActionDefinition?.isTransferLike === false) {
    // @url https://cryptotaxcalculatorco.slack.com/archives/C02NHEC3SN8/p1683263683370289
    row.outgoing.forEach((tx) => {
      update(tx._id, {
        from: formData.from.label,
        to: formData.to.label,
        blockchain: formData.from.blockchain || null,
      });
    });

    row.incoming.forEach((tx) => {
      update(tx._id, {
        from: formData.to.label,
        to: formData.from.label,
        blockchain: formData.from.blockchain || null,
      });
    });
  } else {
    // Update both sides of tx.
    row.outgoing.forEach((tx) => {
      update(tx._id, {
        from: formData.from.label,
        to: formData.to.label,
        blockchain: formData.from.blockchain || null,
      });
    });

    row.incoming.forEach((tx) => {
      update(tx._id, {
        from: formData.from.label,
        to: formData.to.label,
        blockchain: formData.from.blockchain || null,
      });
    });
  }
};

export const EditActionAccountsModal = ({
  row,
  isOpen = false,
  onClose,
  isBlockchain,
  showUnknownAccountWarning,
  side,
}: {
  row: ActionRow;
  isOpen?: boolean;
  onClose: () => void;
  isBlockchain: boolean;
  showUnknownAccountWarning: boolean;
  side?: Side;
}) => {
  const lang = useLang();
  const updateRowTransactionMutation = useAtomicUpdateTransactions();
  const captureActionAnalytics = useCaptureActionAnalytics();
  const analyticsKey = transactionAnalyticsKey("edit");
  const form = useForm<EditActionAccountValues>({
    mode: "onBlur",
    defaultValues: getValues({ row }),
  });

  const { isDirty, isValid } = form.formState;
  const isDisabled = !isDirty || !isValid;

  const handleSubmit = (formData: EditActionAccountValues) => {
    if (isDisabled) return;

    const updateMap = new Map<string, PutTransactionPayload>();
    applyUpdates(row, form, formData, updateMap);
    const payload = Array.from(updateMap.values());
    captureActionAnalytics(analyticsKey("quick edit account submitted"), row, {
      isUnknownAccount: showUnknownAccountWarning,
    });
    updateRowTransactionMutation.mutate(
      {
        update: {
          updateTx: { payload, applySts: false, createCategoryRule: false },
        },
        actionId: row._id,
      },
      {
        onSuccess() {
          form.reset();
          onClose();
        },
      },
    );
  };

  return (
    <GeneralDialogForm
      pending={updateRowTransactionMutation.isPending}
      isOpen={isOpen}
      title={lang.editTx.accounts}
      handleClose={() => {
        form.reset();
        onClose();
      }}
      closeOnClickAway
      fullWidth
      maxWidth="sm"
      actionText={lang.update}
      stopPropagation
      handleAction={form.handleSubmit(handleSubmit)}
      disableAction={isDisabled}
    >
      <Box pt="0.5rem" display="flex" flexDirection="column" gap=".75rem">
        <FormProvider {...form}>
          <EditActionAccounts row={row} />
        </FormProvider>
        {showUnknownAccountWarning && side ? (
          <UnknownTransactionInfo side={side} isBlockchain={isBlockchain} />
        ) : null}
      </Box>
    </GeneralDialogForm>
  );
};

const UnknownTransactionInfo = ({
  side,
  isBlockchain,
}: {
  side: Side;
  isBlockchain: boolean;
}) => {
  const lang = useLang();
  const design = useDesign();
  const linkTextKey = "linkTextKey";
  const hyperlinkProps = ((): HyperlinkKeyToType => {
    return { [linkTextKey]: { linkTo: Links.ImportSearch } };
  })();
  return (
    <Box
      sx={{
        display: "flex",
        padding: "0.5rem 0.75rem",
        flexDirection: "column",
        gap: "0.5rem",
        backgroundColor: design.tokens.elevation.medium,
      }}
    >
      <Typography variant="Metropolis/Header/H5">
        {lang.txTable.unknownAccountWarning.title({ side })}
      </Typography>
      <Typography
        variant="Metropolis/Body/Regular"
        color={design.tokens.text.low}
      >
        {lang.txTable.unknownAccountWarning.body({
          side,
          isBlockchain,
        })}
      </Typography>
      <Typography variant="Metropolis/Body/Bold">
        {lang.txTable.unknownAccountWarning.subTitle({ side })}
      </Typography>

      <Typography
        variant="Metropolis/Body/Regular"
        color={design.tokens.text.low}
      >
        <HyperlinkTextContent
          text={lang.txTable.unknownAccountWarning.CTA({
            side,
            linkTextKey,
          })}
          actions={hyperlinkProps}
        />
      </Typography>
    </Box>
  );
};

export const EditActionAccounts = ({ row }: { row: ActionRow }) => {
  const lang = useLang();
  const design = useDesign();
  const methods = useFormContext();
  const isBreakpointMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [from, to, trade] = methods.watch(["from", "to", "trade"]);
  const groupActionDefinition: ActionData | undefined =
    ActionDefinitions[trade as GroupedTrade] ||
    ActionDefinitions[row.type as GroupedTrade];
  const isMultichain = groupActionDefinition?.isMultichain;

  if (row.isLocked) {
    return <LockedTxEditFallback />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={5.75}>
        <FormControl fullWidth>
          <Controller
            control={methods.control}
            name="from"
            rules={{
              required: lang.editTx.mustSource,
              validate: (value: ExchangeOption) => {
                if (!exchangeSourceValidator(value)) {
                  return lang.editTx.mustSource;
                }

                if (!isMultichain && !isSameExchangeBlockchain(value, to)) {
                  return lang.editTx.cantMixBlockchain;
                }

                return true;
              },
            }}
            render={({
              field: { value, onBlur },
              fieldState: { invalid, error },
            }) => (
              <ExchangeComboBox
                id="from-combo-box"
                label={lang.manual.from}
                value={value}
                onBlur={onBlur}
                style={{ fontSize: "0.875rem", minHeight: "3.25rem" }}
                size="small"
                setValue={(value: ExchangeOption | null) => {
                  const blockchain =
                    value?.blockchain || from.blockchain || null;

                  methods.setValue(
                    "from",
                    value
                      ? {
                          ...value,
                          blockchain,
                        }
                      : { label: "", name: "", blockchain: "" },
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                    },
                  );

                  methods.setValue("blockchainFrom", blockchain, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });

                  methods.trigger();
                }}
                helperText={error?.message}
                error={invalid}
                required
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={0.5} style={{ textAlign: "center" }}>
        {isBreakpointMdUp ? (
          <ArrowForwardIcon
            style={{
              fontSize: "0.875rem",
              marginTop: "1.25rem",
            }}
          />
        ) : (
          <ArrowDownwardIcon
            style={{
              fontSize: "0.875rem",
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={5.75}>
        <FormControl fullWidth>
          <Controller
            control={methods.control}
            name="to"
            rules={{
              required: lang.editTx.mustDestination,
              validate: (value: ExchangeOption) => {
                if (!exchangeSourceValidator(value)) {
                  return lang.editTx.mustDestination;
                }

                if (!isMultichain && !isSameExchangeBlockchain(value, from)) {
                  return lang.editTx.cantMixBlockchain;
                }

                return true;
              },
            }}
            render={({
              field: { value, onBlur },
              fieldState: { error, invalid },
            }) => (
              <ExchangeComboBox
                id="to-combo-box"
                value={value}
                onBlur={onBlur}
                label={lang.manual.to}
                style={{ fontSize: "0.875rem", minHeight: "3.25rem" }}
                size="small"
                setValue={(value: ExchangeOption | null) => {
                  const blockchain = value?.blockchain || to.blockchain || null;

                  methods.setValue(
                    "to",
                    value
                      ? {
                          ...value,
                          blockchain,
                        }
                      : { label: "", name: "", blockchain: "" },
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                    },
                  );

                  methods.setValue("blockchainTo", blockchain, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });

                  methods.trigger();
                }}
                helperText={error?.message}
                error={invalid}
                required
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const EditActionAccountsAdvanced = ({ row }: { row: ActionRow }) => {
  const { setValue, watch, trigger } = useFormContext();
  const [to, from] = watch(["to", "from"]);
  const [editBlockchainWarningApproved, setEditBlockchainWarningApproved] =
    useState(false);
  const [unlockDialogOpen, setUnlockDialogOpen] = useState(false);
  const [locked, setLocked] = useState(true);
  const groupActionDefinition: ActionData | undefined =
    ActionDefinitions[row.type as GroupedTrade];
  const isMultichain = groupActionDefinition?.isMultichain;

  const setBlockchainFrom = (value: string | null) => {
    setValue("blockchainFrom", value || "", {
      shouldDirty: true,
    });

    const updatedFrom = {
      ...from,
      blockchain: value || undefined,
    };

    setValue("from", updatedFrom, {
      shouldValidate: true,
      shouldDirty: true,
    });

    // Assume that BC CAN be changed across all incoming/outgoing tx.
    if (!isMultichain) {
      const updatedTo = {
        ...to,
        blockchain: value || undefined,
      };

      setValue("to", updatedTo, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    trigger();
  };

  const setBlockchainTo = (value: string | null) => {
    setValue("blockchainTo", value || "", {
      shouldDirty: true,
    });

    if (!isMultichain) return;

    const updatedTo = {
      ...to,
      blockchain: value || undefined,
    };

    setValue("to", updatedTo, {
      shouldValidate: true,
      shouldDirty: true,
    });

    trigger();
  };

  const handleLock = () => {
    if (locked && !editBlockchainWarningApproved) {
      setUnlockDialogOpen(true);
    } else if (locked) {
      setLocked(false);
    } else {
      setLocked(true);
    }
  };

  return (
    <Box>
      <UnlockBlockchainDialog
        isOpen={unlockDialogOpen}
        handleClose={() => {
          setUnlockDialogOpen(false);
        }}
        handleAction={() => {
          setLocked(false);
          setUnlockDialogOpen(false);
          setEditBlockchainWarningApproved(true);
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={isMultichain ? 5.75 : 12}>
          <Box display="flex" alignItems="stretch">
            <BlockchainController
              name="blockchainFrom"
              isLocked={locked}
              setValue={setBlockchainFrom}
            />
            <BlockchainControllerLock isLocked={locked} onClick={handleLock} />
          </Box>
        </Grid>
        {isMultichain ? (
          <>
            <Grid item xs={0.5} />
            <Grid item xs={5.75}>
              <Box display="flex" alignItems="stretch">
                <BlockchainController
                  name="blockchainTo"
                  isLocked={locked}
                  setValue={setBlockchainTo}
                />
                <BlockchainControllerLock
                  isLocked={locked}
                  onClick={handleLock}
                />
              </Box>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
};

const BlockchainController = ({
  name,
  isLocked,
  setValue,
}: {
  name: keyof Translation["editTx"];
  isLocked: boolean;
  setValue: (value: string | null) => void;
}) => {
  const { control } = useFormContext();
  const lang = useLang();
  const label = lang.editTx[name];
  invariant(typeof label === "string", `Unable to find label for ${name}`);
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <BlockchainComboBox
          id="base-currency-combo-box"
          label={label}
          value={value}
          onBlur={onBlur}
          placeholder={lang.editTx.blockchainPlaceholder}
          setValue={setValue}
          error={invalid}
          helperText={error?.message}
          inputProps={{
            "data-hj-allow": true,
            sx: {
              fontSize: "0.875rem",
            },
          }}
          disabled={isLocked}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: "100%",
          }}
        />
      )}
    />
  );
};

const BlockchainControllerLock = ({
  isLocked,
  onClick,
}: {
  isLocked: boolean;
  onClick: () => void;
}) => {
  return (
    <TertiaryIconButton
      style={{
        marginLeft: "-1px",
        padding: "0 1rem",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }}
      onClick={onClick}
    >
      {isLocked ? (
        <Lock
          style={{
            fontSize: "1.25rem",
          }}
        />
      ) : (
        <LockOpen
          style={{
            fontSize: "1.25rem",
          }}
        />
      )}
    </TertiaryIconButton>
  );
};
