import {
  type CostBasisRedistributionMethod,
  type InventoryMethod,
  LATEST_PERIOD_ID,
  ReportFormat,
} from "@ctc/types";
import {
  InfoOutlined,
  LockOpenOutlined,
  WarningAmber,
} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { lockPeriodAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { PackCustomModal } from "~/components/report/download-popup/PackCustomModal";
import { type ReportItem } from "~/components/report/helpers/useReportsForCountry";
import { IssuesWhenLockingModal } from "~/components/settings-modal/views/lock-period/IssuesWhenLockingModal";
import { useFormatPeriodDate } from "~/components/settings-modal/views/lock-period/useFormatPeriodDate";
import { Checkbox } from "~/components/ui/Checkbox";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useDesign } from "~/hooks/useTheme";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { type TaxPeriodDTO } from "~/services/period";
import {
  useDeletePeriodMutation,
  useGetAllTaxPeriodsQuery,
  useLockPeriodEndDate,
  useUpdatePeriodMutation,
} from "~/state/period";
import { useReconciliationSteps } from "~/state/reconciliation";
import { OtherReportType } from "~/types/enums";

export function LockedPeriodList() {
  const lang = useLang();
  const allLockedPeriodsQuery = useGetAllTaxPeriodsQuery();
  const deletePeriodMutation = useDeletePeriodMutation();
  const updatePeriodMutation = useUpdatePeriodMutation();
  const [selectedPeriodId, setSelectedPeriodId] = useState<string | undefined>(
    undefined,
  );
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const { tokens } = useDesign();
  const periodsSorted = useMemo(() => {
    return allLockedPeriodsQuery.data?.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    );
  }, [allLockedPeriodsQuery.data]);

  const reconciliationCount = useReconciliationSteps({
    includeCurrentlyHiddenSteps: true,
  });

  const taxSettings = useTaxSettings();
  const { specificInventoryByExchange } = taxSettings ?? {};

  if (!periodsSorted) {
    return (
      <Box>
        <LinearProgress />
      </Box>
    );
  }

  if (periodsSorted.length === 0) {
    return (
      <Box
        display="flex"
        mt={1}
        justifyContent="center"
        alignItems="center"
        p={1}
      >
        <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
          {lang.settings.lockPeriod.noLockPeriods}
        </Typography>
      </Box>
    );
  }

  const now = new Date();
  const lastPeriodEndDate = periodsSorted[0].endDate;
  const showCurrentPeriod = now.valueOf() > lastPeriodEndDate.valueOf();

  const onDeleteMutuate = (id: string, cleanup: () => void) => {
    deletePeriodMutation.mutate(
      { id },
      {
        onSuccess() {
          cleanup();
        },
      },
    );
  };

  const onLockMutuate = (id: string, cleanup: () => void) => {
    if (reconciliationCount > 0) {
      setIsWarningModalOpen(true);
    } else {
      updatePeriodMutation.mutate(
        { id, update: { isLocked: true } },
        {
          onSuccess() {
            cleanup();
          },
        },
      );
    }
  };

  /**
   * show the cost basis redistribution method if the current period is using
   * spec ID and the previous period is not
   */
  const firstPeriod = periodsSorted.length > 0 ? periodsSorted[0] : null;
  const showRedistributionCurrentPeriod = !!(
    firstPeriod &&
    !firstPeriod.specificInventoryByExchange &&
    specificInventoryByExchange
  );

  return (
    <>
      <IssuesWhenLockingModal
        isOpen={isWarningModalOpen && !!selectedPeriodId}
        onClose={() => {
          setIsWarningModalOpen(false);
          setSelectedPeriodId(undefined);
        }}
        onIgnoreIssuesAndLock={() => {
          setIsWarningModalOpen(false);
          if (selectedPeriodId) {
            updatePeriodMutation.mutate(
              { id: selectedPeriodId, update: { isLocked: true } },
              {
                onSuccess() {
                  setSelectedPeriodId(undefined);
                },
              },
            );
          }
        }}
      />
      <ConfirmDialog
        selectedPeriodId={selectedPeriodId}
        setSelectedPeriodId={setSelectedPeriodId}
        onDelete={onDeleteMutuate}
        onLock={onLockMutuate}
        isPending={
          deletePeriodMutation.isPending || updatePeriodMutation.isPending
        }
      />
      <Box display="flex" flexDirection="column" gap="1rem" mt="1rem">
        {showCurrentPeriod && (
          <CurrentPeriod
            startDate={new Date(periodsSorted[0].endDate.valueOf() + 1)}
            showCostBasisRedistributionMethod={showRedistributionCurrentPeriod}
          />
        )}
        {periodsSorted.map((period, index) => {
          /** determine whether to show the cost basis redistribution method,
           * only show if the current period is using spec ID and the previous
           * period is not
           */
          const prevPeriod =
            index + 1 < periodsSorted.length && periodsSorted[index + 1];
          const showCostBasisRedistributionMethod =
            prevPeriod &&
            period.specificInventoryByExchange &&
            !prevPeriod.specificInventoryByExchange;

          return (
            <PeriodItem
              key={period.id}
              period={period}
              handleClick={() => {
                setSelectedPeriodId(period.id);
              }}
              isDeleting={deletePeriodMutation.isPending}
              showCostBasisRedistributionMethod={
                showCostBasisRedistributionMethod
              }
            />
          );
        })}
      </Box>
    </>
  );
}

function ConfirmDialog({
  selectedPeriodId,
  isPending,
  setSelectedPeriodId,
  onDelete,
  onLock,
}: {
  selectedPeriodId: string | undefined;
  isPending: boolean;
  setSelectedPeriodId: (val: string | undefined) => void;
  onDelete: (id: string, cleanup: () => void) => void;
  onLock: (id: string, cleanup: () => void) => void;
}) {
  const lang = useLang();
  const allLockedPeriodsQuery = useGetAllTaxPeriodsQuery();

  const { tokens } = useDesign();
  const [checkboxBalances, setCheckboxBalances] = useState(false);
  const [checkboxGains, setCheckboxGains] = useState(false);
  const [checkboxReports, setCheckboxReports] = useState(false);
  const captureAnalytics = useCaptureAnalytics();
  const periodsSorted = useMemo(() => {
    return allLockedPeriodsQuery.data?.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    );
  }, [allLockedPeriodsQuery.data]);

  const lockPeriodListAnalyticsKey = lockPeriodAnalyticsKey("list");

  if (!periodsSorted) {
    return null;
  }

  const isDeletingHistoricalPeriod =
    periodsSorted.findIndex((period) => period.id === selectedPeriodId) > 0;

  function resetConfirmation() {
    setSelectedPeriodId(undefined);
    setCheckboxBalances(false);
    setCheckboxGains(false);
    setCheckboxReports(false);
  }

  if (!selectedPeriodId) {
    return null;
  }

  const selectedPeriod = periodsSorted.find(
    (period) => period.id === selectedPeriodId,
  );

  if (!selectedPeriod) {
    return null;
  }

  // Confirm locking period
  if (!selectedPeriod.isLocked) {
    return (
      <GeneralDialog
        pending={isPending}
        isOpen={!!selectedPeriodId}
        handleAction={() => {
          captureAnalytics(lockPeriodListAnalyticsKey("confirm lock"), {
            startDate: selectedPeriod?.startDate,
            endDate: selectedPeriod?.endDate,
          });

          const id = selectedPeriodId;
          onLock(id, resetConfirmation);
        }}
        handleClose={() => {
          resetConfirmation();
        }}
        title={lang.settings.lockPeriod.lockUnlockedWarning.title}
        maxWidth="xs"
        fullWidth
        actionText={lang.settings.lockPeriod.lockUnlockedWarning.action}
        cancelText={lang.cancel}
        stopPropagation
        closeOnClickAway
        critical
      >
        <WarningBox>
          <WarningAmber
            sx={{
              color: tokens.text.danger,
            }}
          />
          <Typography variant="Metropolis/Body/Regular">
            {lang.settings.lockPeriod.lockUnlockedWarning.warningLockExisting}
          </Typography>
        </WarningBox>
        <Typography variant="Metropolis/Body/Regular" mt="1rem">
          {lang.settings.lockPeriod.lockUnlockedWarning.line1}
        </Typography>
        <Typography variant="Metropolis/Body/Regular" mt="1rem">
          {lang.settings.lockPeriod.lockUnlockedWarning.line2}
        </Typography>
      </GeneralDialog>
    );
  }

  // Confirm deleting period
  return (
    <GeneralDialog
      disableAction={!(checkboxBalances && checkboxGains && checkboxReports)}
      pending={isPending}
      isOpen={!!selectedPeriodId}
      handleAction={() => {
        captureAnalytics(lockPeriodListAnalyticsKey("remove"), {
          startDate: selectedPeriod?.startDate,
          endDate: selectedPeriod?.endDate,
        });

        onDelete(selectedPeriodId, resetConfirmation);
      }}
      handleClose={() => {
        resetConfirmation();
      }}
      title={lang.settings.lockPeriod.dialog.title}
      maxWidth="xs"
      fullWidth
      actionText={lang.settings.lockPeriod.dialog.action}
      cancelText={lang.cancel}
      stopPropagation
      closeOnClickAway
      critical
    >
      <WarningBox>
        <WarningAmberIcon
          sx={{
            color: tokens.text.danger,
          }}
        />
        <Typography variant="Metropolis/Body/Regular">
          {isDeletingHistoricalPeriod
            ? lang.settings.lockPeriod.dialog.warningWithSubsequent
            : lang.settings.lockPeriod.dialog.warning}
        </Typography>
      </WarningBox>

      <Typography variant="Metropolis/Body/Regular" mt="1rem">
        {lang.settings.lockPeriod.dialog.warningParagraph}
      </Typography>

      <Typography variant="Metropolis/Body/Regular" mt="1rem">
        {lang.settings.lockPeriod.dialog.text}
      </Typography>
      <ConfirmCheckboxList>
        {[
          {
            value: checkboxBalances,
            onChange: () => {
              setCheckboxBalances(!checkboxBalances);
            },
            label: lang.settings.lockPeriod.dialog.checklist.balances,
          },
          {
            value: checkboxGains,
            onChange: () => {
              setCheckboxGains(!checkboxGains);
            },
            label: lang.settings.lockPeriod.dialog.checklist.gains,
          },
          {
            value: checkboxReports,
            onChange: () => {
              setCheckboxReports(!checkboxReports);
            },
            label: lang.settings.lockPeriod.dialog.checklist.reports,
          },
        ].map((checkbox) => (
          <li key={checkbox.label}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox.value}
                  onChange={checkbox.onChange}
                />
              }
              label={
                <Typography variant="Metropolis/Body/Regular">
                  {checkbox.label}
                </Typography>
              }
            />
          </li>
        ))}
      </ConfirmCheckboxList>
      <Typography
        variant="Metropolis/Body/Regular"
        mt="0.5rem"
        color={tokens.text.low}
        fontSize="0.75rem"
      >
        {lang.settings.lockPeriod.dialog.confirm}
      </Typography>
    </GeneralDialog>
  );
}

function DateCell({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date | undefined;
}) {
  const lang = useLang();
  const userCountry = useCountry();
  const formatPeriodDate = useFormatPeriodDate();

  return (
    <DateRangeContainer gridArea="date">
      <Typography variant="Metropolis/Body/Regular">
        {`${formatPeriodDate({
          date: startDate,
          userCountry,
        })}`}
      </Typography>
      <Typography variant="Metropolis/Body/Regular">-</Typography>
      <Typography
        variant="Metropolis/Body/Regular"
        sx={{
          textAlign: "right",
        }}
      >
        {endDate
          ? `${formatPeriodDate({ date: endDate, userCountry })}`
          : lang.settings.lockPeriod.now}
      </Typography>
    </DateRangeContainer>
  );
}

function InventoryCell({
  inventoryMethod,
  specificInventoryByExchange,
  costBasisRedistributionMethod,
  showCostBasisRedistributionMethod,
}: {
  inventoryMethod: InventoryMethod;
  specificInventoryByExchange: boolean;
  costBasisRedistributionMethod: CostBasisRedistributionMethod;
  showCostBasisRedistributionMethod: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const specIDlang = lang.settings.report.specificInventoryByExchange;

  return (
    <Box
      gridArea="inventory"
      display="flex"
      flexDirection="column"
      gap="0.25rem"
      pl="1rem"
      borderLeft="1px solid"
      borderColor={tokens.border.neutral.default}
    >
      <Typography
        variant="Metropolis/Body/Regular"
        color={tokens.text.disabled}
      >{`${lang.report[inventoryMethod]} (${inventoryMethod})`}</Typography>
      <Typography
        variant="Metropolis/Body/Regular"
        color={tokens.text.disabled}
      >
        {`${specIDlang.title}: ${specificInventoryByExchange ? specIDlang.options.on.title : specIDlang.options.off.title}`}
      </Typography>
      {showCostBasisRedistributionMethod ? (
        <Box display="flex" gap="0.5rem">
          <Typography
            variant="Metropolis/Body/Regular"
            color={tokens.text.disabled}
          >
            {`${lang.report.costBasisRedistributionMethod.title}: ${lang.report.costBasisRedistributionMethod[costBasisRedistributionMethod]}`}
          </Typography>
          <Tooltip title={lang.report.costBasisRedistributionMethod.tooltip}>
            <InfoOutlined sx={{ fontSize: "1rem", color: tokens.text.low }} />
          </Tooltip>
        </Box>
      ) : null}
    </Box>
  );
}

function TaxLotReportCell({
  periodId,
  startDate,
}: {
  periodId: string | LATEST_PERIOD_ID;
  startDate: Date;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const lang = useLang();

  const actions = [
    {
      buttonText: lang.report.pdf,
      format: ReportFormat.PDF,
      menuText: lang.report.pdf,
    },
    {
      buttonText: lang.report.csv,
      format: ReportFormat.CSV,
      menuText: lang.report.csv,
    },
  ];

  const reports: ReportItem[] = [];

  if (startDate.valueOf() !== new Date(0).valueOf()) {
    reports.push({
      type: OtherReportType.TaxLots,
      actions,
      title: lang.settings.lockPeriod.inventorySnapshots.opening.title,
      description:
        lang.settings.lockPeriod.inventorySnapshots.opening.description,
      queryParams: {
        lockPeriodId: periodId,
        closing: "false",
      },
    });
  }

  reports.push({
    type: OtherReportType.TaxLots,
    actions,
    title: lang.settings.lockPeriod.inventorySnapshots.closing.title,
    description:
      lang.settings.lockPeriod.inventorySnapshots.closing.description,
    queryParams: {
      lockPeriodId: periodId,
      closing: "true",
    },
  });

  return (
    <>
      <PackCustomModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        disableFilter
        disableMultiselect
        reports={reports}
        reportPacks={[]}
        title={lang.settings.lockPeriod.inventorySnapshots.title}
      />
      <Box gridArea="report" display="flex" gap="0.5rem">
        <TextButton
          size="small"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <Typography variant="Metropolis/Body/Regular" color="inherit">
            {lang.settings.lockPeriod.inventorySnapshots.title}
          </Typography>
        </TextButton>
      </Box>
    </>
  );
}

function LockCell({
  period,
  isLoading,
  isDeleting,
  handleClick,
}: {
  period: TaxPeriodDTO;
  isLoading: boolean;
  isDeleting: boolean;
  handleClick: () => void;
}) {
  const lastPeriodDate = useLockPeriodEndDate();
  const { tokens } = useDesign();
  const lang = useLang();

  const isHistoricalLockPeriod =
    isLoading ||
    new Date(period.endDate).valueOf() !== lastPeriodDate.valueOf() ||
    isDeleting;

  const getTooltipText = () => {
    if (!period.isLocked) {
      return lang.settings.lockPeriod.lockPeriodTooltip;
    }
    return isHistoricalLockPeriod
      ? lang.settings.lockPeriod.unlockAllSubsequentButtonTooltip
      : lang.settings.lockPeriod.unlockButtonTooltip;
  };

  return (
    <Box gridArea="action">
      <Tooltip
        title={
          <span style={{ whiteSpace: "pre-line" }}>{getTooltipText()}</span>
        }
        placement="top"
      >
        <TextIconButton size="small" onClick={handleClick} disabled={isLoading}>
          {isLoading ? (
            <CircularProgress size="1.5rem" />
          ) : period.isLocked ? (
            <LockIcon
              sx={{
                fontSize: "1.5rem",
                color: tokens.icon.danger,
              }}
            />
          ) : (
            <LockOpenOutlined
              sx={{
                fontSize: "1.5rem",
                color: tokens.icon.default,
              }}
            />
          )}
        </TextIconButton>
      </Tooltip>
    </Box>
  );
}

function PeriodItem({
  period,
  handleClick,
  isDeleting,
  showCostBasisRedistributionMethod,
}: {
  period: TaxPeriodDTO;
  handleClick: () => void;
  isDeleting: boolean;
  showCostBasisRedistributionMethod: boolean;
}) {
  return (
    <LockPeriodItemContainer>
      <DateCell startDate={period.startDate} endDate={period.endDate} />
      <InventoryCell
        inventoryMethod={period.inventoryMethod}
        specificInventoryByExchange={period.specificInventoryByExchange}
        costBasisRedistributionMethod={period.costBasisRedistributionMethod}
        showCostBasisRedistributionMethod={showCostBasisRedistributionMethod}
      />
      <TaxLotReportCell periodId={period.id} startDate={period.startDate} />
      <LockCell
        period={period}
        isLoading={!period.isComplete}
        isDeleting={isDeleting}
        handleClick={handleClick}
      />
    </LockPeriodItemContainer>
  );
}

function CurrentPeriod({
  startDate,
  showCostBasisRedistributionMethod,
}: {
  startDate: Date;
  showCostBasisRedistributionMethod: boolean;
}) {
  const { tokens } = useDesign();
  const taxSettings = useTaxSettings();
  const lang = useLang();

  if (!taxSettings) return null;

  const {
    inventoryMethod,
    specificInventoryByExchange,
    costBasisRedistributionMethod,
  } = taxSettings;

  return (
    <LockPeriodItemContainer>
      <DateCell startDate={startDate} endDate={undefined} />
      <InventoryCell
        inventoryMethod={inventoryMethod}
        specificInventoryByExchange={specificInventoryByExchange}
        costBasisRedistributionMethod={costBasisRedistributionMethod}
        showCostBasisRedistributionMethod={showCostBasisRedistributionMethod}
      />
      <TaxLotReportCell periodId={LATEST_PERIOD_ID} startDate={startDate} />
      <Box gridArea="action">
        <Tooltip
          title={lang.settings.lockPeriod.currentPeriod.tooltip}
          placement="top"
        >
          <span>
            <TextIconButton size="small" disabled>
              <LockOpenOutlined
                sx={{
                  fontSize: "1.5rem",
                  color: tokens.text.disabled,
                }}
              />
            </TextIconButton>
          </span>
        </Tooltip>
      </Box>
    </LockPeriodItemContainer>
  );
}

const LockPeriodItemContainer = styled(Box)`
  && {
    width: 100%;
    min-height: 3rem;
    display: grid;
    padding-bottom: 1rem;

    align-items: center;
    grid-template-rows: auto;
    grid-template-areas: "date inventory report action";
    grid-template-columns: 12rem 2fr 1fr 2rem;
    column-gap: 1rem;

    border-bottom: 1px solid
      ${({ theme }) => theme.tokens.border.neutral.default};
  }
`;

const DateRangeContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
`;

export const WarningBox = styled(Box)`
  display: flex;
  padding: 0.25rem 0.5rem;

  align-items: center;
  gap: 0.5rem;

  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.danger};
  background: ${({ theme }) => theme.tokens.background.danger.default};
`;

const ConfirmCheckboxList = styled.ul`
  list-style-type: none;
  position: relative;
  margin-top: 0.5rem !important;
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -0.5rem;
  }
`;
