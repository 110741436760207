import "styled-components/macro";

import { Tooltip } from "@mui/material";

import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { devices } from "~/components/ui/theme/legacy";
import { useDesign } from "~/hooks/useTheme";
import { type CurrencyIdentifier } from "~/types/index";

export function CurrencyStack(props: {
  currencies: CurrencyIdentifier[];
  showTooltip?: boolean;
  max?: number;
  iconDiameter?: number;
}) {
  const { tokens } = useDesign();
  const {
    currencies,
    max = 99999,
    iconDiameter = 28,
    showTooltip = false,
  } = props;
  return (
    <div
      css={`
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        {currencies
          .slice(0, Math.min(max, currencies.length))
          .map((currency, i) => (
            <Tooltip
              title={showTooltip ? currency.name : ""}
              key={i}
              placement="top"
              data-uncensored="true"
            >
              <div
                css={`
                  transition: margin 0.5s;
                  display: flex;
                  align-items: center;
                  margin-right: ${i === currencies.length || i === max - 1
                    ? 0
                    : "-1rem"};
                  @media ${devices.tablet} {
                    margin-right: ${i === currencies.length || i === max - 1
                      ? 0
                      : "-1rem"};
                  }
                  @media ${devices.laptopL} {
                    margin-right: ${i === currencies.length || i === max - 1
                      ? 0
                      : "-0.6rem"};
                  }
                `}
              >
                <CurrencyLogo
                  style={{
                    borderRadius: currency.nftId ? "0.25rem" : "100%",
                  }}
                  currency={currency}
                  margin="0"
                  height={iconDiameter}
                  width={iconDiameter}
                  mobileHeight={20}
                  mobileWidth={20}
                  showBlockchainSymbol={false}
                />
              </div>
            </Tooltip>
          ))}
      </div>
      {currencies.length > max ? (
        <div
          css={`
            height: 1.25rem;
            width: 1.25rem;
            line-height: 1rem;
            @media ${devices.laptop} {
              height: 1.75rem;
              width: 1.75rem;
              line-height: 1.4rem;
            }
            color: ${tokens.text.disabled};
            text-align: center;
          `}
        >
          ...
        </div>
      ) : null}
    </div>
  );
}
