import posthog from "posthog-js";
import { useState, useEffect } from "react";

export function useIsPreLoginFeatureFlagsLoaded() {
  const [isFeatureFlagsLoaded, setIsFeatureFlagsLoaded] = useState(false);
  useEffect(() => {
    // PostHog will immediately fire this callback if feature flags are already loaded,
    // otherwise it will fire when they load in the future
    const unsubscribe = posthog.onFeatureFlags(() => {
      setIsFeatureFlagsLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return isFeatureFlagsLoaded;
}
