import { Box, Skeleton } from "@mui/material";
import styled from "styled-components/macro";

import { transactionActionRowLayoutStyles } from "~/components/transactions/action-row/TransactionActionRowLayout";
import { useMinScreenWidthForTableContent } from "~/components/transactions/useTableStopsScrollingPx";
import { transactionActionRowColumns } from "~/hooks/useTransactionActionRowColumns";

export const SkeletonLayout = styled(Box)`
  padding: 0.75rem;
  background-color: ${({ theme }) =>
    theme.tokens.background.neutral.lowest.default};
  ${transactionActionRowLayoutStyles}
`;

export const TransactionRowLoading = () => {
  const columns = transactionActionRowColumns();
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  return (
    <SkeletonLayout
      columns={columns}
      minScreenSizeForContent={minScreenSizeForContent}
    >
      {columns.map((area) => (
        <Box style={{ gridArea: area }} key={area}>
          <StyledSkeleton width="100%" variant="rectangular" animation="wave" />
        </Box>
      ))}
    </SkeletonLayout>
  );
};

const StyledSkeleton = styled(Skeleton)`
  height: 2rem;
  border-radius: 4px;
`;
