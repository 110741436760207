import { CheckCircle, Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useContext } from "react";

import { EmailVerificationContext } from "~/contexts/EmailVerificationContext";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function EmailVerificationBanner() {
  const bannerContext = useContext(EmailVerificationContext);
  const handleBannerClose = () => {
    bannerContext?.setBannerIsOpen(false);
  };
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <Box
      sx={{
        backgroundColor: tokens.background.success.hover,
        padding: "0.5rem 1rem",
        display: "flex",
        minHeight: "3rem",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <CheckCircle
          sx={{ marginRight: "0.5rem", color: tokens.icon.success }}
        />
        <Typography variant="Metropolis/Body/Regular">
          {lang.settings.profile.verifiedBanner}
        </Typography>
      </Box>
      <Close onClick={handleBannerClose} />
    </Box>
  );
}
