import { Box } from "@mui/material";
import styled from "styled-components/macro";

import { CommandMenuListStyled } from "~/components/transactions/command-palette/CommandMenuList";
import { useLang } from "~/redux/lang";

export const NoResultsStyle = styled.div`
  ${({ theme }) => ({ ...theme.mui.typography['Metropolis/Caption/Medium/Regular'] })};
  text-align: center;
  padding: 1.25rem;
  color: ${({ theme }) => theme.tokens.text.default};
`;

export function NoResults({
  loading,
  hasResults,
}: {
  loading: boolean;
  hasResults: boolean;
}) {
  const lang = useLang();
  if (hasResults) {
    return null;
  }
  return (
    <CommandMenuListStyled as="div">
      <NoResultsStyle>
        {!loading ? <Box>{"¯\\_(ツ)_/¯"}</Box> : null}
        {loading
          ? lang.txTable.commandPallet.loadingOptions
          : lang.txTable.commandPallet.noOptions}
      </NoResultsStyle>
    </CommandMenuListStyled>
  );
}
