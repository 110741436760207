import { SyncStatusPlatform } from "@ctc/types";
import uniq from "lodash/uniq";

import { MIN_ADDR_LEN } from "~/constants/constants";
import { type ImportState as State } from "~/redux/types";
import {
  useAvailableImportOption,
  useGetAllImportOptions,
} from "~/state/imports";
import { useGetSavedAccounts } from "~/state/importsV2";
import { type ImportOptionV2 } from "~/types/index";

enum Import {
  SetFilteredImportOptions = "setFilteredImportOptions",
  SetPendingFilesToUpload = "setPendingFilesToUpload",
  RemovePendingFilesToUpload = "removePendingFilesToUpload",
  Reset = "RESET_IMPORTS",
}

const initialState: State = {
  existingImportOptions: [],
  filteredImportOptions: [],
};
type ImportAction = SetFilteredImportOptions | ResetImportOptions;

type SetFilteredImportOptions = {
  type: Import.SetFilteredImportOptions;
  importOptions: ImportOptionV2[];
};

type ResetImportOptions = {
  type: Import.Reset;
};

export function importsReducer(
  state = initialState,
  action: ImportAction,
): State {
  switch (action.type) {
    case Import.SetFilteredImportOptions:
      return {
        ...state,
        filteredImportOptions: action.importOptions,
      };
    case Import.Reset:
      return initialState;
    default:
      return {
        ...state,
      };
  }
}

export const getFileSyncLabel = (exchangeName: string, id: string) =>
  `CSV_${exchangeName}_${id}`;

export function setFilteredImportOptions(
  importOptions: ImportOptionV2[],
): SetFilteredImportOptions {
  return {
    type: Import.SetFilteredImportOptions,
    importOptions,
  };
}

export const useUniqueBlockchainsFromImports = () => {
  const savedAccounts = useGetSavedAccounts();
  const allBlockchains = savedAccounts.flatMap((i) =>
    i.wallets.map((c) => c.blockchain),
  );
  return Array.from(uniq(allBlockchains));
};

export function useIsAddressLike(label: string, addressLink?: string | null) {
  const importOption = useAvailableImportOption(label);
  if (addressLink) {
    return true;
  }
  if (importOption || label === "unknown") {
    return false;
  }
  if (label.length < MIN_ADDR_LEN) {
    return false;
  }
  // Only alpha-numerics and '-'.
  return /^[0-9a-z-]+$/i.exec(label);
}

export function useIsAdressLikeFn() {
  const importOptions = useGetAllImportOptions().data?.lookup ?? {};

  return (label: string, addressLink?: string | null) => {
    const importOption = importOptions[label];

    if (addressLink) {
      return true;
    }

    if (importOption || label === "unknown") {
      return false;
    }

    if (label.length < MIN_ADDR_LEN) {
      return false;
    }

    // Only alpha-numerics and '-'.
    return !!/^[0-9a-z-]+$/i.exec(label);
  };
}

export const useImportsIntercomData = () => {
  const importedFiles = useGetSavedAccounts().flatMap((i) => i.files);
  const numSucessfullyImportedFiles = importedFiles.filter(
    (i) => i.status === SyncStatusPlatform.Success,
  ).length;
  const numFailedImportedFiles = importedFiles.filter(
    (i) => i.status === SyncStatusPlatform.Fail,
  ).length;
  return {
    num_sucessfully_imported_files: numSucessfullyImportedFiles,
    num_failed_imported_files: numFailedImportedFiles,
  };
};
