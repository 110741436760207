import { useEffect, useMemo } from "react";

import { type BroadcastChannels } from "~/hooks/enums";

// This hook facilitates communication between different tabs or windows of the same origin using the BroadcastChannel API.
// It is particularly useful for synchronizing actions or sharing data across multiple instances of the application in real-time.
export const useBroadcastChannel = ({
  channelName,
  onMessage,
}: {
  channelName: BroadcastChannels;
  onMessage: (event: MessageEvent<string>) => void;
}) => {
  const channel = useMemo(
    () => new BroadcastChannel(channelName),
    [channelName],
  );

  useEffect(() => {
    channel.addEventListener("message", onMessage);
    return () => {
      channel.removeEventListener("message", onMessage);
    };
  }, [channel, onMessage]);

  useEffect(() => {
    return () => {
      channel.close();
    };
  }, [channel]);

  return {
    broadcast: (message: string) => {
      channel.postMessage(message);
    },
  };
};
