import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, TextField, type TextFieldProps } from "@mui/material";
import { forwardRef, useState } from "react";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";

// eslint-disable-next-line react/display-name
export const PasswordTextField = forwardRef<
  HTMLInputElement,
  Omit<TextFieldProps, "type">
>(({ InputProps, ...props }, ref) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <TextField
      type={isPasswordVisible ? "text" : "password"}
      className="fs-exclude"
      ref={ref}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <TextIconButton
              size="small"
              aria-label="toggle password visibility"
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible);
              }}
            >
              {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
            </TextIconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...props}
    />
  );
});
