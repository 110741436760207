import { Box, Typography } from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";


import { useDesign } from "~/hooks/useTheme";

type ChipProps = React.ComponentProps<typeof Box> & {
  children?: React.ReactNode;
  pointer?: boolean;
};

export const GreyChipStyled = styled.div`
  display: flex;
  padding: 0.125rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  border-radius: 1.5rem;
  background: ${(props) => props.theme.tokens.background.accent.neutral.low};
`;
export const GreyChip = ({ children }: { children: React.ReactNode }) => {
  return (
    <GreyChipStyled>
      <Typography
        variant={"Metropolis/Caption/Medium/Regular"}
      >
        {children}
      </Typography>
    </GreyChipStyled>
  );
};

export const Chip = ({ children, sx, ...props }: ChipProps) => {
  const { tokens } = useDesign();
  return (
    <Box
      borderRadius="4rem"
      p="2px 0.5rem"
      fontSize="0.75rem"
      fontWeight={500}
      color={tokens.text.default}
      bgcolor={tokens.background.brand.default}
      width="fit-content"
      textOverflow="ellipsis"
      maxWidth="100%"
      overflow="hidden"
      whiteSpace="nowrap"
      sx={{
        cursor: props.pointer ? "pointer" : "default",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
