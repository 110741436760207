import { Plan } from "@ctc/types";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";

import { useLimitExceeded } from "~/components/payment/helpers";
import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { getDefaultPlanType } from "~/components/settings-modal/views/plan/helpers/getDefaultPlanType";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { useUser } from "~/redux/auth";
import { usePlans } from "~/state/plans";

export function useOpenPlanTabToPlan() {
  const user = useUser();
  const { setActive } = useSettingsModal();
  const plansQuery = usePlans();
  const { isLimitedExceeded } = useLimitExceeded();
  const [, setSelectedPlanType] = useQueryParam(
    "plan",
    withDefault(
      createEnumParam(Object.values(Plan)),
      getDefaultPlanType(user, plansQuery?.data, isLimitedExceeded),
    ),
  );

  return (plan: Plan) => {
    setActive(SettingsTabs.Plan);
    setSelectedPlanType(plan);
  };
}
