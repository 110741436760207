import { HelpOutline } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { useLang } from "~/redux/lang";
import { useMarkEntityEscrowMutation } from "~/state/entities";
import { type Entity, isExchangeEntity } from "~/types/index";

export function MarkAsEscrow({ entity }: { entity: Entity }) {
  const { isEscrow } = entity;
  const [checked, setChecked] = useState(isEscrow ?? false);
  const markAsEscrowMutation = useMarkEntityEscrowMutation();
  const lang = useLang();

  const isDisabled = isExchangeEntity(entity) && !!entity.globalIsEscrow;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="0.25rem"
    >
      <Tooltip
        title={isDisabled ? lang.txTable.entities.globalEscrowTooltip : ""}
      >
        <span>
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                disabled={isDisabled}
                onChange={(e) => {
                  setChecked(e.target.checked);
                  markAsEscrowMutation.mutate({
                    entity,
                    escrow: e.target.checked,
                  });
                }}
              />
            }
            label={
              <Typography variant="Metropolis/Caption/Medium/Regular">
                {lang.txTable.entities.switchText}
              </Typography>
            }
          />
        </span>
      </Tooltip>
      <Tooltip title={lang.txTable.entities.explainerTooltip}>
        <HelpOutline sx={{ fontSize: "1rem" }} />
      </Tooltip>
    </Box>
  );
}
