import "react-lazy-load-image-component/src/effects/blur.css";

import { type Blockchain } from "@ctc/types";
import { CurrencySourcePlatform } from "@ctc/types";
import { Avatar, Box, useMediaQuery } from "@mui/material";
import chroma from "chroma-js";
import { transparentize } from "polished";
import { type CSSProperties, useState } from "react";
import styled from "styled-components/macro";

import { exchangeColorConfig } from "~/components/imports-v2/exchangeColorConfigGenerated";
import { LazyLoadImageWithFallback } from "~/components/transactions/ImageWithFallback";
import { getBlockchainLogoSrc } from "~/components/ui/ExchangeLogo";
import { ctcLightTheme as theme } from "~/components/ui/theme/mui";
import { IMAGE_PROXY_URL } from "~/constants/constants";
import { useDesign } from "~/hooks/useTheme";
import {
  getCloudFrontImageUrl,
  getCloudFrontNftImageUrl,
  IconType,
} from "~/lib/getCloudFrontImageUrl";
import { type CurrencyIdentifier } from "~/types/index";

enum LogoSrc {
  Crypto = "crypto",
  Fiat = "fiat",
}

export type CurrencyLogoProps = {
  currency: CurrencyIdentifier;
  className?: string;
  width?: number;
  height?: number;
  mobileWidth?: number;
  mobileHeight?: number;
  margin?: string;
  style?: CSSProperties;
  blockchain?: Blockchain;
  showBlockchainSymbol?: boolean;
};

function getLogoIdentifier(currency: CurrencyIdentifier): {
  source: LogoSrc;
  id: string;
  externalImageUrl?: string;
  contractAddress?: string;
  nftId?: string;
} | null {
  const { id, source, externalImageUrl, contractAddress, nftId } = currency;
  if (id) {
    if (source === CurrencySourcePlatform.Fiat) {
      return {
        source: LogoSrc.Fiat,
        id,
        externalImageUrl,
      };
    }
    return {
      source: LogoSrc.Crypto,
      id,
      externalImageUrl,
      contractAddress,
      nftId,
    };
  }
  return null;
}

export function getProxiedImageUrl(url: string) {
  return `${IMAGE_PROXY_URL}/image/fetch/${encodeURIComponent(url)}`;
}

function getCurrencyLogoSrc(
  currency: CurrencyIdentifier,
  fallbackLevel: number,
  blockchain?: string,
) {
  const identifier = getLogoIdentifier(currency);
  if (identifier) {
    const { id, externalImageUrl, contractAddress, nftId } = identifier;

    if (blockchain && contractAddress && nftId) {
      const internal = getCloudFrontNftImageUrl(
        blockchain,
        contractAddress,
        nftId,
      );
      const external = externalImageUrl
        ? [getProxiedImageUrl(externalImageUrl)]
        : [];

      // Preference external images for NFTs until we can back-fill NFT images.
      return [...external, ...internal, "/default-nft-logo.png"];
    }

    if (id && fallbackLevel < 1) {
      return getCloudFrontImageUrl(IconType.CurrencyIcons, id);
    }

    if (externalImageUrl) {
      return getProxiedImageUrl(externalImageUrl);
    }
  }

  // use default
  return null;
}

function getCurrencyLogoAlt(currency: CurrencyIdentifier | string) {
  if (typeof currency === "string") {
    return `${currency}-logo`;
  }
  return `${currency.symbol}-logo`;
}

function stringAvatar(name: string) {
  return name.replace(/[^\w]/gi, "").trim().substring(0, 2).toUpperCase();
}

const CurrencyAvatar = styled(({ width, height, ...props }) => (
  <Avatar {...props} />
))`
  && {
    color: ${({ theme }) => theme.tokens.text.brand};
    background-color: ${({ theme }) => theme.tokens.button.subtle.default};
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    font-size: ${({ width }) => width / 2}px;
    font-weight: 600;
    border: 1px solid
      ${({ theme }) => transparentize(0.9, theme.tokens.border.brand)};
  }
`;

export function CurrencyLogo({
  currency,
  className,
  width = 24,
  height = 24,
  mobileWidth,
  mobileHeight,
  margin = "0.5rem",
  style,
  blockchain,
  showBlockchainSymbol = false,
}: CurrencyLogoProps) {
  const [prevCurrency, setPrevCurrency] = useState(currency);
  const [fallbackLevel, setFallbackLevel] = useState(0);

  if (currency !== prevCurrency) {
    setPrevCurrency(currency);
    setFallbackLevel(0);
  }

  const { tokens } = useDesign();
  const onImageError = () => {
    setFallbackLevel((prevLevel) => prevLevel + 1);
  };
  const src = getCurrencyLogoSrc(currency, fallbackLevel, blockchain);
  const alt = getCurrencyLogoAlt(currency);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const imageHeight = isMobile && mobileHeight ? mobileHeight : height;
  const imageWidth = isMobile && mobileWidth ? mobileWidth : width;

  const blockchainSrc = getBlockchainLogoSrc(blockchain);

  const fileConfig = (exchangeColorConfig as any)[currency.symbol];
  const bg = chroma
    .mix(
      fileConfig?.backgroundColor || tokens.background.neutral.default,
      tokens.background.neutral.default,
      0.5,
      "rgb",
    )
    .hex();

  return (
    <Box
      className={className}
      margin={margin}
      height={height}
      display="inline-flex"
      position="relative"
      alignItems="center"
    >
      {fallbackLevel >= 2 || !src ? (
        <CurrencyAvatar
          height={imageHeight}
          width={imageWidth}
          alt={alt}
          style={{
            ...style,
            borderRadius: currency.nftId ? "0.25rem" : imageWidth / 2,
          }}
        >
          {currency.symbol ? stringAvatar(currency.symbol) : null}
        </CurrencyAvatar>
      ) : (
        <LazyLoadImageWithFallback
          height={imageHeight}
          width={imageWidth}
          src={src}
          alt={alt}
          effect="blur"
          style={{
            ...style,
            borderRadius: currency.nftId ? "0.25rem" : imageWidth / 2,
            background: bg,
          }}
          onError={onImageError}
        />
      )}
      {showBlockchainSymbol && !!blockchainSrc ? (
        <Box
          position="absolute"
          bottom={-(imageHeight / 4)}
          right={-(imageHeight / 4)}
        >
          <LazyLoadImageWithFallback
            height={imageHeight / 2}
            width={imageWidth / 2}
            src={blockchainSrc}
            alt={`${blockchain}-logo`}
            effect="blur"
            style={{
              borderRadius: imageWidth / 4,
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
}
