import { Plan } from "@ctc/types";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Switch, Typography, useMediaQuery } from "@mui/material";
import styled from "styled-components";

import { REFERRER_CONFIG } from "~/components/payment/referrer-config";
import { useIsMobile } from "~/components/ui/hooks";
import { devices } from "~/components/ui/theme/legacy";
import { type CouponCode } from "~/constants/enums";
import { useDesign } from "~/hooks/useTheme";
import { useLocalCurrency, useUser } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { type CouponConfig, CouponService } from "~/services/coupon";
import { useBestCoupons } from "~/state/billing";

/**
 * OfferCard component renders a coupon promotion card for a given coupon
 */
const OfferCard = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.high};
  background: ${({ theme }) => theme.tokens.elevation.high};
  padding: 1.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
`;
/**
 * PartnerOfferChip component renders a chip for a given coupon
 * Displayed in the top right corner of the card
 */
const PartnerOfferChip = styled.div`
  padding: 0.125rem 0.5rem;
  border-radius: 0rem 0.4rem;
  background: ${({ theme }) => theme.tokens.background.accent.neutral.medium};
`;

/**
 * PromoCouponCard component renders a coupon promotion card for a given coupon.
 *
 * @param {Object} props - The component props.
 * @param {string} props.coupon - The coupon code.
 * @param {string[]} props.features - The list of features for the coupon.
 * @returns {JSX.Element} A single promo coupon card element.
 */
function PromoCouponCard({
  coupon,
  descriptions,
}: {
  coupon: CouponConfig;
  descriptions: string[];
}) {
  const lang = useLang();
  const { tokens } = useDesign();

  const referrerConfig = coupon.referrerSource
    ? REFERRER_CONFIG[coupon.referrerSource]
    : undefined;

  const promoLang = referrerConfig?.isReferralOffer
    ? lang.payment.promoBanner.referralOffer
    : lang.payment.promoBanner.partnerOffer;

  return (
    <OfferCard>
      {referrerConfig && (
        <>
          <Box
            display="flex"
            alignItems="center"
            height="1.3rem"
            sx={{
              "& svg, & img": {
                height: "1.3rem !important",
                width: "auto !important",
              },
            }}
          >
            {referrerConfig.logo()}
          </Box>
          <Box position="absolute" top="0" right="0">
            <PartnerOfferChip>
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                color={tokens.text.default}
              >
                {promoLang}
              </Typography>
            </PartnerOfferChip>
          </Box>
        </>
      )}

      <Box display="flex" flexDirection="column" height="100%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap="0.25rem"
          height="100%"
        >
          {descriptions.map((feature, i) => (
            <Box display="flex" alignItems="center" gap="0.25rem" key={i}>
              <CheckIcon
                sx={{
                  width: "1rem",
                  fill: tokens.text.success,
                }}
              />
              <Typography
                variant="Metropolis/Body/Regular"
                color={tokens.text.high}
              >
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </OfferCard>
  );
}

/**
 * ApplyPromosToggle component renders a header with a toggle switch for applying promos.
 * This toggle is necessary because of Stripe's session behavior with coupons:
 * When a coupon is applied during Stripe session creation, it cannot be removed by the user.
 * This prevents users from applying potentially better coupon codes they might have from other sources.
 * By providing this toggle, users can opt-out of automatic coupon application before session creation,
 * allowing them to manually apply different coupon codes if they have better offers.
 *
 * @param {Object} props - The component props
 * @param {boolean} props.applyCoupon - Whether the coupon is currently applied
 * @param {(applied: boolean) => void} props.setApplyCoupon - Callback to update the coupon applied state
 * @returns {JSX.Element} A header with a toggle switch
 */
function ApplyPromosToggle({
  applyCoupon,
  setApplyCoupon,
}: {
  applyCoupon: boolean;
  setApplyCoupon: (applied: boolean) => void;
}) {
  const lang = useLang();
  const promoLang = lang.payment.yourOffers;

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="Metropolis/Header/H4">{promoLang}</Typography>
      <Switch
        checked={applyCoupon}
        onChange={() => {
          setApplyCoupon(!applyCoupon);
        }}
        color="primary"
      />
    </Box>
  );
}

/**
 * PromoHeaderBanner component renders a grid of coupon promotion cards.
 * It performs early exits if conditions are not met and maps over the available coupons
 * to display each in a grid of three columns.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.applyCoupon - State indicating if a coupon is applied.
 * @param {(applied: boolean) => void} props.setApplyCoupon - Function to toggle coupon applied state.
 * @returns {JSX.Element | null} PromoHeaderBanner component or null if conditions are not met.
 */
export function PromoHeaderBanner({
  applyCoupon,
  setApplyCoupon,
}: {
  applyCoupon: boolean;
  setApplyCoupon: (applyCoupon: boolean) => void;
}) {
  const isMobile = useIsMobile();
  const user = useUser();
  const lang = useLang();
  const isLaptop = useMediaQuery(devices.laptop);
  const localCurrency = useLocalCurrency();
  const locale = useLanguagePreference();

  const bestCoupons = useBestCoupons();
  if (!bestCoupons.data?.couponCodes) {
    return null;
  }
  if (bestCoupons.isLoading) {
    return null;
  }

  if (!user?.referrerSource || user?.paidPlan !== Plan.Free) {
    return null;
  }

  const referrerConfig = REFERRER_CONFIG[user.referrerSource];
  if (!referrerConfig) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap="0.75rem">
      <ApplyPromosToggle
        applyCoupon={applyCoupon}
        setApplyCoupon={setApplyCoupon}
      />

      <Box
        display="grid"
        gridTemplateColumns={isMobile ? "1fr" : "repeat(3, 1fr)"}
        gap="1.25rem"
        mb={isLaptop ? "4rem" : 0}
      >
        {(bestCoupons.data.couponCodes as CouponCode[]).map(
          (couponCode, index) => {
            const coupon = CouponService.getCouponConfig(couponCode);
            const descriptions = coupon?.descriptions({
              user,
              localCurrency,
              locale,
              lang,
            });
            if (!coupon) {
              return null;
            }
            return (
              <PromoCouponCard
                key={index}
                coupon={coupon}
                descriptions={descriptions ?? []}
              />
            );
          },
        )}
      </Box>
    </Box>
  );
}
