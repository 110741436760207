import {
  Country,
  Plan,
  ReportFormat,
  ReportPackTypePlatform,
} from "@ctc/types";
import { Close, InfoOutlined, MoreVert, SaveAlt } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { getReportCategory } from "~/components/report/helpers/getReportCategory";
import {
  getReportSubtitleLang,
  getReportTypeLang,
} from "~/components/report/helpers/lang";
import {
  sortReportsByLockedPlan,
  sortReportsByPlanOrder,
  sortReportsByVisibility,
} from "~/components/report/helpers/reportOrder";
import { type ReportPack } from "~/components/report/helpers/useReportPacksForCountry";
import { type ReportItem } from "~/components/report/helpers/useReportsForCountry";
import {
  type FallbackSrcs,
  ImageWithFallback,
} from "~/components/transactions/ImageWithFallback";
import { Checkbox } from "~/components/ui/Checkbox";
import { getIntegrationLogoSrc } from "~/components/ui/ExchangeLogo";
import { useIsMobile } from "~/components/ui/hooks";
import { DownloadIcon } from "~/components/ui/Icons";
import { TextDropdown } from "~/components/ui/TextDropdown";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { findNextPlanWithFeature } from "~/contexts/paywall-modal-context/PaywallModal";
import { PaywallModalContext } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { ReportModalsContext } from "~/contexts/ReportModalsContext";
import { useDesign } from "~/hooks/useTheme";
import {
  useBestUser,
  useCountry,
  useHighestRankingPlanUser,
  useIsFreePlan,
  useIsManagingClients,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useDownloadReports, useIsDownloadingPack } from "~/redux/report";
import { usePlans } from "~/state/plans";
import {
  Features,
  IrsReportDownloadType,
  IrsReportType,
  Links,
  NormalReportType,
  OtherReportType,
} from "~/types/enums";
import {
  AdvancedReports,
  type ReportDownloadType,
  type ReportType,
} from "~/types/index";

export const MutedCheckbox = styled(Checkbox)`
  svg path {
    fill: ${({ theme, checked, indeterminate, disabled }) =>
      checked || indeterminate
        ? theme.tokens.icon.brand
        : disabled
          ? theme.tokens.icon.disabled
          : theme.tokens.icon.low};
  }
`;

export type ReportMeta = {
  id: ReportDownloadType;
  title: string;
  description: string;
  hasPdf: boolean;
  hasCsv: boolean;
  hasGenerate: boolean;
  hasTxf: boolean;
  hasXml: boolean;
  requiredPlan?: string;
  lockedPlan: boolean;
  iconSrc: FallbackSrcs;
  visible: boolean;
  queryParams?: Record<string, string>;
};

export function PackCustomModal({
  open,
  onClose,
  reports,
  reportPacks,
  title,
  disableFilter = false,
  disableMultiselect = false,
}: {
  open: boolean;
  onClose: () => void;
  reports: ReportItem[];
  reportPacks: ReportPack[];
  title?: string;
  disableFilter?: boolean;
  disableMultiselect?: boolean;
}) {
  const { tokens } = useDesign();
  const borderColor = tokens.border.neutral.medium;
  const user = useBestUser();

  const lang = useLang();
  const customModal = lang.report.packs.customModal;
  const country = useCountry();
  const { data: plans, isLoading: isLoadingPlans } = usePlans();
  const highestRankingPlanUser = useHighestRankingPlanUser();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const reportModalsContext = useContext(ReportModalsContext);

  const allFilters = [
    {
      title: lang.report.packs.filters.all,
      key: "All",
    },
    {
      title: lang.report.packs.filters.self,
      key: "Self",
      packType: ReportPackTypePlatform.Self,
    },
    {
      title: lang.report.packs.filters.accountant,
      key: "Accountant",
      packType: ReportPackTypePlatform.Accountant,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState("All");

  const getReportVisibility = (
    report: ReportItem,
    selectedReportPack?: ReportPack,
  ) => {
    // Report is visible in a custom modal tab if:
    // - The report type is BGL and the user is in Australia
    // - The selected tab is "All"
    // - The report is included in the selected report tab pack
    // - The report is in the default self/accountant reports (and those tabs are selected)
    if (
      report.type === OtherReportType.BGL &&
      user?.country !== Country.Australia
    ) {
      return false;
    }

    if (
      selectedFilter === "All" ||
      selectedReportPack?.reports.includes(report.type) ||
      getReportCategory(report.type) === selectedFilter
    ) {
      return true;
    }

    return false;
  };

  const reportsForRows: ReportMeta[] = reports.map((report) => {
    let requiredPlan: undefined | string;
    const queryParams = report.queryParams;
    const selectedFilterPackType = allFilters.find(
      (filter) => filter.key === selectedFilter,
    )?.packType;

    const selectedReportPack = reportPacks.find(
      (pack) => pack.type === selectedFilterPackType,
    );

    const isAdvancedReport = AdvancedReports.includes(
      report.type as NormalReportType,
    );
    const isTradingStockReport =
      report.type === NormalReportType.TradingStockAu;

    if (
      isTradingStockReport &&
      !highestRankingPlanUser?.features[Features.TradingStockReport]
    ) {
      requiredPlan = findNextPlanWithFeature(
        Features.TradingStockReport,
        plans,
      )?.[0];
    } else if (
      isAdvancedReport &&
      !highestRankingPlanUser?.features[Features.AdvancedReports]
    ) {
      requiredPlan = findNextPlanWithFeature(
        Features.AdvancedReports,
        plans,
      )?.[0];
    } else if (highestRankingPlanUser?.paidPlan === Plan.Free) {
      // All other reports require a rookie plan (at minimum)
      requiredPlan = Plan.Rookie;
    }

    return {
      id: report.type,
      title: report.title ?? getReportTypeLang(lang, report.type, country),
      description:
        report.description ?? getReportSubtitleLang(lang, report.type, country),
      hasPdf: report.actions.some(
        (action) => action.buttonText === lang.report.pdf && !action.disabled,
      ),
      hasCsv: report.actions.some(
        (action) => action.buttonText === lang.report.csv && !action.disabled,
      ),
      hasGenerate: report.actions.some(
        (action) =>
          action.buttonText == lang.report.download.buttonIrs &&
          !action.disabled,
      ),
      hasTxf: report.actions.some(
        (action) => action.buttonText == lang.report.txf && !action.disabled,
      ),
      hasXml: report.actions.some(
        (action) => action.buttonText == lang.report.xml && !action.disabled,
      ),
      requiredPlan,
      lockedPlan: requiredPlan !== undefined,
      iconSrc: getIntegrationLogoSrc(report.logoName, "svg"),
      visible: getReportVisibility(report, selectedReportPack),
      queryParams,
    };
  });

  reportsForRows
    .sort(sortReportsByLockedPlan)
    .sort(sortReportsByVisibility)
    .sort(sortReportsByPlanOrder);

  const enabledReportsInView = reportsForRows.filter(
    (report) => !report.lockedPlan,
  );

  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());

  const selectAllReports = () => {
    if (enabledReportsInView.every((report) => selectedIds.has(report.id))) {
      setSelectedIds(new Set([]));
    } else {
      for (const report of enabledReportsInView) {
        selectedIds.add(report.id);
        setSelectedIds(new Set(selectedIds));
      }
    }
  };

  const selectedReports = Array.from(selectedIds.values())
    .map((id) => enabledReportsInView.find((report) => report.id === id)!)
    .filter((report) => report !== undefined);
  const selectedPdfCount = selectedReports.filter(
    (report) => report.hasPdf || report.hasGenerate,
  ).length;
  const selectedCsvCount: number = selectedReports.filter(
    (report) => report.hasCsv,
  ).length;

  const packDownloads = useIsDownloadingPack();

  const downloadReports = useDownloadReports();
  const isFree = useIsFreePlan();
  const isManagingClients = useIsManagingClients();
  const navigate = useNavigate();
  const captureAnalytics = useCaptureAnalytics();
  const paywallModalContext = useContext(PaywallModalContext);

  const upgradePath = () => {
    if (isManagingClients) {
      captureAnalytics(
        "report:upgrade_plan_paywall_modal:clicked_upgrade_plan_chip_accountant",
      );
      paywallModalContext?.setUpgradeClientDialogOpen(true);
    } else {
      captureAnalytics(
        "report:upgrade_plan_paywall_modal:clicked_upgrade_plan_chip",
      );
      navigate(Links.Plans);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { display: "flex" } }}
    >
      {isLoadingPlans ? (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8rem",
          }}
        >
          <CircularProgress size="2rem" />
        </DialogContent>
      ) : (
        <>
          <Box position="absolute" top={12} right={10}>
            <TextIconButton
              onClick={onClose}
              size="small"
              data-ctc="report-custom-modal-close"
            >
              <Close />
            </TextIconButton>
          </Box>
          <SelectPlanBanner />
          <DialogTitle>{title ?? customModal.title}</DialogTitle>
          {!disableFilter && (
            <DialogContent sx={{ flex: "0 0" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  flexWrap: "wrap",
                }}
              >
                {allFilters.map((filter) => (
                  <TertiaryButton
                    key={filter.key}
                    highlighted={selectedFilter === filter.key}
                    onClick={() => {
                      setSelectedFilter(filter.key);
                    }}
                  >
                    {filter.title}
                  </TertiaryButton>
                ))}
              </Box>
            </DialogContent>
          )}
          <DialogContent sx={{ flex: 1, paddingTop: 0 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {enabledReportsInView.length > 0 && !disableMultiselect ? (
                    <TableCell
                      padding="checkbox"
                      align="left"
                      sx={{ borderColor }}
                    >
                      <MutedCheckbox
                        indeterminate={
                          !enabledReportsInView.every((report) =>
                            selectedIds.has(report.id),
                          ) &&
                          enabledReportsInView.some((report) =>
                            selectedIds.has(report.id),
                          )
                        }
                        checked={
                          enabledReportsInView.length > 0 &&
                          enabledReportsInView.every((report) =>
                            selectedIds.has(report.id),
                          )
                        }
                        disabled={enabledReportsInView.length === 0}
                        onClick={selectAllReports}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell sx={{ borderColor, color: tokens.text.low }}>
                    {customModal.columns.report}
                  </TableCell>
                  {isMobile ? null : (
                    <>
                      <TableCell sx={{ borderColor, color: tokens.text.low }}>
                        {customModal.columns.details}
                      </TableCell>
                      <TableCell sx={{ borderColor }} />
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportsForRows.map((report) => {
                  const { id, description, lockedPlan } = report;

                  const toggleSelected = () => {
                    if (lockedPlan) {
                      return;
                    }

                    if (!selectedIds.has(id)) {
                      selectedIds.add(id);
                    } else {
                      selectedIds.delete(id);
                    }
                    setSelectedIds(new Set(selectedIds));
                  };

                  // override bordercolor if not visible
                  const cellBorderColor: string = report.visible
                    ? borderColor
                    : "transparent";

                  return (
                    <TableRow
                      key={id}
                      sx={{
                        ":hover": {
                          background: lockedPlan
                            ? undefined
                            : tokens.background.neutral.hover,
                        },
                        cursor: lockedPlan ? "default" : "pointer",
                        display: report.visible ? undefined : "none",
                      }}
                    >
                      {enabledReportsInView.length > 0 &&
                      !disableMultiselect ? (
                        <TableCell
                          padding="checkbox"
                          align="left"
                          sx={{
                            borderColor: cellBorderColor,
                          }}
                          onClick={toggleSelected}
                        >
                          {!lockedPlan ? (
                            <MutedCheckbox
                              checked={selectedIds.has(id)}
                              disabled={lockedPlan}
                            />
                          ) : null}
                        </TableCell>
                      ) : null}

                      {isMobile ? (
                        <TableCell
                          sx={{ borderColor: cellBorderColor }}
                          onClick={toggleSelected}
                        >
                          <ReportTitle
                            report={report}
                            onUpgradePath={upgradePath}
                          />
                          <Box
                            sx={{
                              color: lockedPlan
                                ? tokens.text.disabled
                                : tokens.text.default,
                            }}
                          >
                            {description}
                          </Box>
                          <Box display="flex" alignItems="center">
                            <DownloadButtons report={report} />
                            {report.hasXml ? (
                              <TextIconButton
                                size="small"
                                sx={{
                                  marginRight: "0.6rem",
                                }}
                                onClick={() => {
                                  reportModalsContext?.setIsOpenBGLAccountIDDialog(
                                    true,
                                  );
                                }}
                              >
                                <MoreVert />
                              </TextIconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      ) : (
                        <>
                          <TableCell
                            sx={{
                              borderColor: cellBorderColor,
                            }}
                            onClick={toggleSelected}
                          >
                            <ReportTitle
                              report={report}
                              onUpgradePath={upgradePath}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              borderColor: cellBorderColor,
                            }}
                            onClick={toggleSelected}
                          >
                            <Box
                              sx={{
                                color: lockedPlan
                                  ? tokens.text.low
                                  : tokens.text.default,
                              }}
                            >
                              {description}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ borderColor: cellBorderColor }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                borderColor: cellBorderColor,
                                lineHeight: "unset",
                                gap: "0.4rem",
                              }}
                            >
                              <DownloadButtons report={report} />
                              {report.hasXml ? (
                                <TextIconButton
                                  size="small"
                                  sx={{
                                    marginRight: "0.5rem",
                                  }}
                                  onClick={() => {
                                    reportModalsContext?.setIsOpenBGLAccountIDDialog(
                                      true,
                                    );
                                  }}
                                >
                                  <MoreVert fontSize="inherit" />
                                </TextIconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
          {!isFree && !disableMultiselect ? (
            <DialogContent dividers sx={{ flex: "0 0" }}>
              <DownloadPackButton
                isLoading={!!packDownloads.customPDF?.length}
                onClick={() =>
                  dispatch(
                    downloadReports({
                      reportFormat: ReportFormat.PDF,
                      reportDownloadTypes: selectedReports
                        .filter((report) => report.hasPdf || report.hasGenerate)
                        .map((report) => report.id),
                      packId: "customPDF",
                      packType: ReportPackTypePlatform.Custom,
                    }),
                  )
                }
                selectedCount={selectedPdfCount}
                label={lang.report.packs.customModal.pdf}
              />
              <DownloadPackButton
                isLoading={!!packDownloads.customCSV?.length}
                onClick={() =>
                  dispatch(
                    downloadReports({
                      reportFormat: ReportFormat.CSV,
                      reportDownloadTypes: selectedReports
                        .filter((report) => report.hasCsv)
                        .map((report) => report.id),
                      packId: "customCSV",
                      packType: ReportPackTypePlatform.Custom,
                    }),
                  )
                }
                selectedCount={selectedCsvCount}
                label={lang.report.packs.customModal.csv}
              />
            </DialogContent>
          ) : null}
        </>
      )}
    </Dialog>
  );
}

function SelectPlanBanner() {
  const isMobile = useIsMobile();
  const lang = useLang();
  const { tokens } = useDesign();
  const isFree = useIsFreePlan();
  const isManagingClients = useIsManagingClients();
  const navigate = useNavigate();
  const captureAnalytics = useCaptureAnalytics();
  const paywallModalContext = useContext(PaywallModalContext);

  // Only show the banner if the user is on the free plan
  if (!isFree) return null;

  const handleCta = () => {
    if (isManagingClients) {
      captureAnalytics(
        "report:upgrade_plan_paywall_modal:clicked_upgrade_plan_chip_accountant",
      );
      paywallModalContext?.setUpgradeClientDialogOpen(true);
    } else {
      captureAnalytics(
        "report:upgrade_plan_paywall_modal:clicked_upgrade_plan_chip",
      );
      navigate(Links.Plans);
    }
  };

  return (
    <Box
      bgcolor={tokens.background.brand.default}
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        padding: "1rem 3rem 1rem 1.5rem",
        alignItems: isMobile ? "flex-start" : "center",
        gap: "0.75rem",
      }}
    >
      <InfoOutlined sx={{ color: tokens.text.warning }} />
      <Typography variant="Metropolis/Header/H5">
        {lang.report.packs.customModal.selectPlanBanner.body}
      </Typography>
      <PrimaryButton
        sx={{ ml: isMobile ? undefined : "auto" }}
        onClick={handleCta}
      >
        {lang.report.packs.customModal.selectPlanBanner.cta}
      </PrimaryButton>
    </Box>
  );
}

function ReportTitle({
  report,
  onUpgradePath,
}: {
  report: ReportMeta;
  onUpgradePath: () => void;
}) {
  const { requiredPlan, id, title, iconSrc } = report;
  const { tokens } = useDesign();
  const lang = useLang();

  return (
    <Box
      display="flex"
      sx={{
        alignItems: "center",
        color: tokens.text.default,
      }}
    >
      {iconSrc && (
        <Box
          mr={2}
          sx={{
            alignItems: "center",
            backgroundColor: tokens.background.accent.purple.lowest,
            borderRadius: "50%",
            display: "flex",
            height: "1.25rem",
            justifyContent: "center",
            width: "1rem",
            marginRight: "0.5rem",
          }}
        >
          <ImageWithFallback src={iconSrc} alt={`${id}-icon`} />
        </Box>
      )}
      <Typography variant="Metropolis/Body/Bold">{title}</Typography>
      {requiredPlan ? (
        <Tooltip
          title={lang.report.packs.customModal.upgrade({
            planTitle: lang.payment.base.copy.plans[requiredPlan as Plan].title,
          })}
          onClick={onUpgradePath}
          placement="top"
        >
          <Chip
            size="small"
            label={lang.payment.base.copy.plans[requiredPlan as Plan].title}
            onClick={onUpgradePath}
            sx={{
              marginLeft: "0.5rem",
              cursor: "pointer",
              lineHeight: "normal",
              backgroundColor: tokens.background.accent.neutral.medium,
              color: tokens.text.low,
            }}
          />
        </Tooltip>
      ) : null}
    </Box>
  );
}

function DownloadButtons({ report }: { report: ReportMeta }) {
  const { id, hasPdf, hasGenerate, hasCsv, hasTxf, hasXml } = report;
  const customModal = useLang().report.packs.customModal;
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const downloadReports = useDownloadReports();
  const packDownloads = useIsDownloadingPack();

  const isDownloadingReportWithFormat = (reportFormats: ReportFormat[]) => {
    return reportFormats.some((format) => {
      const packId = `custom-${format}-${id}`;
      return (
        packDownloads[packId]?.length > 0 ||
        isCustomPackDownloading(format, id, packDownloads)
      );
    });
  };

  const handleDownload = (format: ReportFormat) => {
    dispatch(
      downloadReports({
        reportFormat: format,
        reportDownloadTypes: [id],
        packId: `custom-${format}-${id}`,
        packType: ReportPackTypePlatform.Custom,
        queryParams: report.queryParams,
      }),
    );
  };

  const options = [
    hasTxf ? ReportFormat.TXF : "",
    hasXml ? ReportFormat.XML : "",
    hasCsv ? ReportFormat.CSV : "",
    hasPdf || hasGenerate ? ReportFormat.PDF : "",
  ]
    .filter((o): o is ReportFormat => o !== "")
    .map((o) => ({
      name: o.toUpperCase(),
      isLoading: isDownloadingReportWithFormat([o]),
    }));

  const handleSelect = (option: string) => {
    switch (option) {
      case "TXF":
        handleDownload(ReportFormat.TXF);
        break;
      case "XML":
        handleDownload(ReportFormat.XML);
        break;
      case "CSV":
        handleDownload(ReportFormat.CSV);
        break;
      case "PDF":
        handleDownload(ReportFormat.PDF);
        break;
      default:
        break;
    }
  };

  const styling = isMobile
    ? {}
    : {
        display: "flex",
        justifyContent: "flex-end",
        gap: 1,
      };

  return (
    <Box sx={{ display: "flex", justifyContent: isMobile ? "left" : "right" }}>
      <Box
        sx={{
          color: tokens.text.brand,
          fontWeight: 500,
          width: "7rem",
          ...styling,
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {report.requiredPlan && options.length > 0 ? (
          <TextDropdown
            text={customModal.getSample}
            endIcon={<DownloadIcon size="1rem" color={tokens.text.low} />}
            options={options}
            onSelect={handleSelect}
          />
        ) : null}

        {!report.requiredPlan
          ? options.map((format) => {
              return (
                <DownloadFiletypeButton
                  key={format.name}
                  reportType={id}
                  reportFormat={
                    ReportFormat[format.name as keyof typeof ReportFormat]
                  }
                  label={
                    customModal[
                      format.name.toLowerCase() as keyof typeof customModal
                    ] as string
                  }
                  isLoading={isDownloadingReportWithFormat([
                    ReportFormat[format.name as keyof typeof ReportFormat],
                  ])}
                  queryParams={report.queryParams}
                />
              );
            })
          : null}
      </Box>
    </Box>
  );
}

const DownloadFiletypeButtonContainer = styled.span<{ isLoading: boolean }>`
  cursor: pointer;
  padding-right: 12px;
  color: ${({ theme, isLoading }) =>
    isLoading ? theme.tokens.text.disabled : theme.tokens.icon.brand};

  &:hover {
    text-decoration: underline;
  }
`;

function DownloadFiletypeButton({
  reportFormat,
  reportType,
  label,
  isLoading,
  queryParams,
}: {
  reportFormat: ReportFormat;
  reportType: ReportDownloadType;
  label: string;
  isLoading: boolean;
  queryParams?: Record<string, string>;
}) {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const downloadReports = useDownloadReports();
  const labelRef = useRef<HTMLSpanElement>(null);
  const [labelDimensions, setLabelDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const packId = `custom-${reportFormat}-${reportType}`;

  const onClick = () => {
    dispatch(
      downloadReports({
        reportFormat,
        reportDownloadTypes: [reportType],
        packId,
        packType: ReportPackTypePlatform.Custom,
        queryParams,
      }),
    );
  };

  useEffect(() => {
    if (labelRef.current) {
      setLabelDimensions({
        width: labelRef.current.offsetWidth,
        height: labelRef.current.offsetHeight,
      });
    }
  }, [label]);

  return (
    <DownloadFiletypeButtonContainer
      data-ctc={`report-download-${reportFormat}-${reportType}`}
      isLoading={isLoading}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isMobile ? "flex-start" : "center",
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              width: labelDimensions ? `${labelDimensions.width}px` : "auto",
              height: labelDimensions ? `${labelDimensions.height}px` : "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size="0.75rem" />
          </Box>
        ) : (
          <span ref={labelRef}>{label}</span>
        )}
      </Box>
    </DownloadFiletypeButtonContainer>
  );
}

function isCustomPackDownloading(
  reportFormat: ReportFormat,
  reportType: ReportDownloadType,
  packDownloads: ReturnType<typeof useIsDownloadingPack>,
): boolean {
  const customPackDownloads =
    (() => {
      if (reportFormat === ReportFormat.CSV) return packDownloads.customCSV;
      if (reportFormat === ReportFormat.PDF) return packDownloads.customPDF;
    })() || [];

  return reportType === IrsReportDownloadType.IRS_8949
    ? customPackDownloads.includes(IrsReportType.IRS_8949_SHORT)
    : customPackDownloads.includes(reportType as ReportType);
}

function DownloadPackButton({
  selectedCount,
  isLoading,
  onClick,
  label,
}: {
  selectedCount: number;
  isLoading: boolean;
  onClick: () => void;
  label: string;
}) {
  const { tokens } = useDesign();
  const customModal = useLang().report.packs.customModal;
  const isFree = useIsFreePlan();

  // If the user is on the free plan, they won't be able to download/multi-select anything
  // So we don't want to show the button
  if (isFree) return null;

  return (
    <TextButton
      disabled={selectedCount === 0}
      onClick={onClick}
      startIcon={
        isLoading ? (
          <CircularProgress size="1.25rem" />
        ) : (
          <SaveAlt
            sx={{
              width: "1.25rem",
              height: "1.25rem",
              color:
                selectedCount === 0 ? tokens.icon.disabled : tokens.icon.brand,
              transition: "color 0.25s",
            }}
          />
        )
      }
    >
      {customModal.downloadSelected({ selectedCount, isFree })}
      <Chip size="small" label={label} sx={{ marginLeft: "0.5rem" }} />
    </TextButton>
  );
}
