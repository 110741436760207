import { type Blockchain } from "@ctc/types";
import { Stack } from "@mui/material";
import { useContext } from "react";

import { ApiImportList } from "~/components/imports/api/ApiImportList";
import { OAuthImportList } from "~/components/imports/api/OAuthImportList";
import { ImportFormContext } from "~/components/imports/context/index";
import { CSVImportList } from "~/components/imports/csv/CsvImportList";
import { type ImportControllers } from "~/components/imports/ImportForm";
import { useImportContext } from "~/components/imports/ImportItem";
import {
  MultiChainWalletImportList,
  WalletImportList,
} from "~/components/imports/wallet/WalletImportList";
import { useCorrectImportOption, useDeleteCsvMutation } from "~/state/csvs";
import {
  useGetMapSavedImportsByIntegrations,
  useGetSavedImportsByIntegration,
} from "~/state/importsV2";
import { ImportMethod, IntegrationCategory } from "~/types/enums";
import {
  type ImportMethodV2,
  type ImportOptionV2,
  type SavedFileImport,
  type SavedImportByIntegration,
  type WalletImportMethod,
} from "~/types/index";

import { isConnectWalletImportMethod } from "./helpers";
import { isDerivedBlockchainImportMethod } from "./wallet/ConnectWallet";
import { useIsOnboardingMobileImportExperiment } from "../../hooks/useIsOnboardingMobileImportExperiment";

function WalletImportListContainer({
  importOption,
  controllers,
  blockchain,
  savedImport,
  bulkWalletChains,
  importMethod,
  importOptionAccountingForBlockchain,
}: {
  savedImport: SavedImportByIntegration;
  importOption: ImportOptionV2;
  controllers: ImportControllers;
  blockchain?: Blockchain | false;
  blockchains?: Blockchain[];
  bulkWalletChains?: Blockchain[];
  importMethod: ImportMethodV2;
  importOptionAccountingForBlockchain: ImportOptionV2;
}) {
  const { walletInputController } = controllers;
  const isConnectWallet = isConnectWalletImportMethod(importMethod);
  const ignoreBlockchainSelector =
    isDerivedBlockchainImportMethod(importMethod);
  const blockchainsOrImportOptionAccountingForBlockchain = isConnectWallet
    ? importMethod.blockchains
    : ([importOptionAccountingForBlockchain.id] as Blockchain[]);
  const savedImportsMap = useGetMapSavedImportsByIntegrations(
    blockchainsOrImportOptionAccountingForBlockchain,
  );

  if (
    isConnectWallet &&
    ignoreBlockchainSelector &&
    blockchainsOrImportOptionAccountingForBlockchain.length
  ) {
    return blockchainsOrImportOptionAccountingForBlockchain.map((chain) => {
      const savedConnectWalletImport = savedImportsMap[chain];
      if (!savedConnectWalletImport) {
        return null;
      }
      return (
        <WalletImportList
          key={savedConnectWalletImport.id}
          savedImport={savedConnectWalletImport}
          handleDelete={walletInputController.handleDelete}
          handleEdit={walletInputController.handleEdit}
          blockchain={chain}
          walletName={importOption.name}
          editingWalletAddress={walletInputController.editingWalletAddress}
          importSource={importOption.id}
        />
      );
    });
  }

  if (blockchain) {
    return (
      <WalletImportList
        savedImport={savedImport}
        handleDelete={walletInputController.handleDelete}
        handleEdit={walletInputController.handleEdit}
        blockchain={blockchain}
        walletName={importOption.name}
        editingWalletAddress={walletInputController.editingWalletAddress}
      />
    );
  }

  if (bulkWalletChains) {
    return (
      <MultiChainWalletImportList
        handleDelete={walletInputController.handleDelete}
        handleEdit={walletInputController.handleEdit}
        blockchains={bulkWalletChains}
        walletName={importOption.name}
        editingWalletAddress={walletInputController.editingWalletAddress}
      />
    );
  }

  return null;
}

export function ImportItemList({
  importOption,
  controllers,
  importMethod,
}: {
  importOption: ImportOptionV2;
  controllers: ImportControllers;
  importMethod: ImportMethodV2;
}) {
  const { apiInputController } = controllers;
  const context = useContext(ImportFormContext);
  const importOptionAccountingForBlockchain = useCorrectImportOption(
    importOption,
    context?.selectedBlockchain,
  );
  const { onImportDeleted } = useImportContext();
  const savedImport = useGetSavedImportsByIntegration(
    importOptionAccountingForBlockchain.id,
  );
  const deleteCsv = useDeleteCsvMutation();
  const isOnboardingMobileImportExperiment =
    useIsOnboardingMobileImportExperiment();
  const blockchain =
    context?.selectedBlockchain ||
    (savedImport?.category === IntegrationCategory.Blockchain &&
      (savedImport?.id as Blockchain));

  const handleDeleteFile = (file: SavedFileImport) => {
    deleteCsv.mutate(file);
    onImportDeleted?.();
  };

  const getBulkWalletChains = () => {
    const bulKWalletMethod = importOption.importMethods.find(
      (method) => method.type === ImportMethod.BulkWallet,
    );
    const walletMethod = importOption.importMethods.find(
      (method) => method.type === ImportMethod.Wallet,
    );
    if (
      bulKWalletMethod &&
      walletMethod &&
      isWalletImportMethod(walletMethod) &&
      walletMethod.blockchains.length
    ) {
      return walletMethod.blockchains;
    }
    return undefined;
  };

  const bulkWalletChains = getBulkWalletChains();

  if (!savedImport || isOnboardingMobileImportExperiment) {
    return null;
  }

  return (
    <Stack direction="column" spacing="0.25rem">
      <OAuthImportList
        importOption={importOption}
        handleDelete={apiInputController.handleDelete}
        savedImport={savedImport}
      />
      <ApiImportList
        importOption={importOption}
        savedImport={savedImport}
        handleDelete={apiInputController.handleDelete}
        handleUpdate={apiInputController.handleUpdate}
        handleEdit={apiInputController.handleEdit}
        editingApiId={apiInputController.editingApiId}
      />
      <WalletImportListContainer
        importOption={importOption}
        controllers={controllers}
        blockchain={blockchain}
        savedImport={savedImport}
        bulkWalletChains={bulkWalletChains}
        importMethod={importMethod}
        importOptionAccountingForBlockchain={
          importOptionAccountingForBlockchain
        }
      />
      <CSVImportList
        savedImport={savedImport}
        handleDelete={handleDeleteFile}
        importOption={importOption}
      />
    </Stack>
  );
}

function isWalletImportMethod(m: ImportMethodV2): m is WalletImportMethod {
  return m.type === ImportMethod.Wallet;
}
