import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { displayMessage } from "~/components/ui/Toaster";
import { useIsReferEnabled } from "~/hooks/useIsReferEnabled";
import { HttpError } from "~/services/core";
import * as referralService from "~/services/referrals";
import { DisplayMessage } from "~/types/enums";
import { type ReferralProgramType } from "~/types/enums";

export const referralKey = {
  all: () => ["referrals"] as const,
  program: (programType: ReferralProgramType) =>
    ["referrals", programType] as const,
  promoterDetails: (programType: ReferralProgramType) =>
    ["referrals", programType, "promoterDetails"] as const,
};

export const useGetReferralCode = (programType: ReferralProgramType) => {
  const isReferAFriendEnabled = useIsReferEnabled();

  return useQuery({
    queryKey: referralKey.program(programType),
    queryFn: async () => {
      const resGetCode = await referralService.getReferralCode(programType);
      // no error return the code
      if (!resGetCode.error) {
        return resGetCode.data;
      }

      // unexpected error
      if (resGetCode.status !== 404) {
        displayMessage({
          message: resGetCode.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(resGetCode, ["getReferralCode"]);
      }

      const resGenerateCode =
        await referralService.generateReferralCode(programType);

      if (resGenerateCode.error) {
        displayMessage({
          message: resGenerateCode.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(resGenerateCode, ["getReferralCode"]);
      }

      return resGenerateCode.data;
    },
    enabled: isReferAFriendEnabled,
  });
};

export const useUpdatePaypal = (programType: ReferralProgramType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (email: string) => {
      const res = await referralService.updatePaypal(programType, email);
      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["updatePaypal"]);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: referralKey.promoterDetails(programType),
      });
      displayMessage({
        message: "Paypal email updated",
        type: DisplayMessage.Success,
      });
    },
    onError: () => {
      displayMessage({
        message: "Failed to update paypal email",
        type: DisplayMessage.Error,
      });
    },
  });
};

export const useGetPromoterDetails = (programType: ReferralProgramType) => {
  return useQuery({
    queryKey: referralKey.promoterDetails(programType),
    queryFn: async () => {
      const res = await referralService.getPromoterDetails(programType);

      if (!res.error) {
        return res.data;
      }

      return null;
    },
  });
};
