import { Button } from "@mui/material";
import { useEffect, useState } from "react";

import { AddCardDialog } from "~/components/settings-modal/views/billing/AddCardDialog";
import { PaymentMethodItem } from "~/components/settings-modal/views/billing/PaymentMethodItem";
import { ConfigurationPanel } from "~/components/ui/ConfigurationPanel";
import { useLang } from "~/redux/lang";
import { usePaymentMethodQuery } from "~/state/billing";

const UpdateCardButton = () => {
  const [open, setOpen] = useState(false);
  const lang = useLang();
  return (
    <>
      <AddCardDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        {lang.billing.paymentMethod.button}
      </Button>
    </>
  );
};

export const PaymentMethodPanel = () => {
  const lang = useLang();
  const {
    data: paymentMethod,
    isLoading,
    refetch: refetchPaymentMethod,
  } = usePaymentMethodQuery();

  useEffect(() => {
    refetchPaymentMethod();
  }, [refetchPaymentMethod]);

  // We don't want to show the panel if there is no payment method
  // Since it can become a loophole to allow accountant get 1 month free for first client
  if (!paymentMethod) {
    return null;
  }
  return (
    <ConfigurationPanel
      title={lang.billing.paymentMethod.title}
      isLoading={isLoading}
      actionButtons={<UpdateCardButton />}
    >
      <PaymentMethodItem paymentMethod={paymentMethod || null} />
    </ConfigurationPanel>
  );
};
