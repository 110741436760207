import React from "react";

import { Alert, Box, SxProps, TextField, Typography } from "@mui/material";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useLang } from "~/redux/lang";
import { useDesign } from "~/hooks/useTheme";

export function Verify2FAInput({
  error,
  setCode,
  hideTitle = false,
  sx,
}: {
  error: string | undefined;
  setCode: (code: string) => void;
  hideTitle?: boolean;
  sx?: SxProps;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem 1.5rem",
        ...sx,
      }}
    >
      {!hideTitle && (
        <Typography variant={isMobile ? "Metropolis/Header/H2" : "Metropolis/Header/H3"}>
          {lang.twoFactorAuth.twoStepVerification}
        </Typography>
      )}
      <Typography>{lang.twoFactorAuth.enterCodeSubText}</Typography>
      {error ? (
        <Alert severity="error" sx={{ mb: "2rem" }}>
          {error}
        </Alert>
      ) : null}
      <TextField
        variant="outlined"
        name="code"
        label={lang.twoFactorAuth.enterCodeLabel}
        required
        helperText={lang.twoFactorAuth.enterCodeOrRecoveryCodeHelperText}
        fullWidth
        sx={{
          "&.MuiFormControl-root": {
            backgroundColor: "inherit",
          },
        }}
        InputProps={{
          sx: {
            backgroundColor: tokens.background.input.default,
          },
        }}
        onChange={(e) => {
          setCode(e.target.value);
        }}
      />
      {/* When BE supports stay signed in for 30 days, add this back */}
      {/* <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <Checkbox
                checked={staySignedIn}
                onChange={handleStaySignedInCheckChange}
                color="primary"
                sx={{
                  padding: 0,
                  margin: 0,
                }}
              />
              <Typography variant="Metropolis/Body/Light">
                {lang.twoFactorAuth.staySignedIn}
              </Typography>
            </Box> */}
    </Box>
  );
}
