import { useQuery } from "@tanstack/react-query";

import { displayMessage } from "~/components/ui/Toaster";
import { useCanAccessFeature } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { HttpError } from "~/services/core";
import * as dashboardAPI from "~/services/dashboard";
import * as reportAPI from "~/services/report";
import { DisplayMessage } from "~/types/enums";
import { Features, type InflowOutflowPortfolioTimeframe } from "~/types/enums";

export const holdingKeys = {
  all: () => ["dashboard"] as const,
  holdings: () => [...holdingKeys.all(), "holdings"] as const,

  chart: () => [...holdingKeys.all(), "chart"] as const,
  inflowOutflow: (timeframe: InflowOutflowPortfolioTimeframe) =>
    [...holdingKeys.chart(), "inflowOutflow", timeframe] as const,
};

export function useGetDashboardHoldingsQuery() {
  const lang = useLang();
  return useQuery({
    queryKey: holdingKeys.holdings(),
    queryFn: async () => {
      const res = await dashboardAPI.getDashboardHoldings();

      if (res.error) {
        displayMessage({
          message: lang.dashboard.error,
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["getHoldings"]);
      }
      return res.data;
    },
    staleTime: Infinity,
  });
}

export function useInflowOutflowPortfolio(
  timeframe: InflowOutflowPortfolioTimeframe,
) {
  const enabled = useCanAccessFeature(Features.Treasury);

  return useQuery({
    queryKey: holdingKeys.inflowOutflow(timeframe),
    queryFn: async () => {
      const res = await reportAPI.getInflowOutflowPortfolio(timeframe);

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["fetchInflowOutflowChart"]);
      }

      return { data: res.data, generating: false };
    },
    enabled,
  });
}
