import { type Country, type SupportedLang } from "@ctc/types";
import qs from "query-string";

import { deleteRequest, get, post, put } from "~/services/core";
import {
  type Notifications,
  type UpdateAlpha,
  type UpdateCountry,
  type UpdateEmailReports,
  type UpdateLanguage,
  type UpdateName,
  type UpdateShowIgnored,
  type UserInfo,
} from "~/types/index";

export function getUserSettings() {
  const path = "/settings/user";
  return get<UserInfo>(path);
}

export async function updateEmail(email: string) {
  const path = "/auth/email";
  return put<{ email: string }>(path, { email });
}

export function onboardingCompleted() {
  const path = "/settings/onboarding-completed";
  return put<void>(path, {});
}

export function updateName(name: string) {
  const path = "/settings/update/name";

  return post<UpdateName>(path, { name });
}

export function updateAlphaStatus(isInAlpha: boolean) {
  const path = "/settings/update/alpha";

  return post<UpdateAlpha>(path, { isInAlpha });
}

export function updateEmailReports({
  emailReports,
  shouldShowEmailReportsModal,
}: {
  emailReports?: boolean;
  shouldShowEmailReportsModal?: boolean;
}) {
  const path = "/settings/update/email-reports";

  return post<UpdateEmailReports>(path, {
    emailReports,
    shouldShowEmailReportsModal,
  });
}

export function updateCountry(country: Country) {
  const path = "/settings/update/country";
  return post<UpdateCountry>(path, { country });
}

export function requestEmailVerificationEmail() {
  const path = "/settings/user/email/verification";
  return get<void>(path);
}

export function updateLanguage(language: SupportedLang) {
  const path = "/settings/update/language";
  return post<UpdateLanguage>(path, { language });
}

export function updateShowSpamTransactions(showSpamTransactions: boolean) {
  const path = "/settings/update/show-spam-transactions";
  return post<UpdateShowIgnored>(path, { showSpamTransactions });
}

export function deleteUserData() {
  const path = "/settings/delete/data/all";
  return deleteRequest<true>(path);
}

export function deleteEverything() {
  const path = "/settings/delete/data/user/warning/all";
  return deleteRequest<true>(path);
}

export function putNotifications(notifications: Notifications) {
  const query = qs.stringify({ ...notifications });
  const path = `/settings/notifications?${query}`;
  return put<Notifications>(path, {});
}

export function putNotificationsAuthUser(notifications: Notifications) {
  const query = qs.stringify({ ...notifications });
  const path = `/settings/auth-user/notifications?${query}`;
  return put<Notifications>(path, {});
}

export function setDataSource(ownsChildProfileData: boolean) {
  const path = "/settings/user/data-source";
  return put(path, { ownsChildProfileData });
}

export function getSupportedLangs() {
  const path = "/settings/supported-languages";
  return get<{ langs: SupportedLang[] }>(path);
}

export function get2faQrCode() {
  const path = "/settings/2fa/totp-svg";
  return get<{ qr: string; secret: string }>(path);
}

export function enable2fa(code: string, password: string) {
  const path = "/settings/2fa/enable";
  return post<{ recoveryCodes: string[] }>(path, { code, password });
}

export function disable2fa(code: string, password: string) {
  const path = "/settings/2fa/disable";
  return post<void>(path, { code, password });
}

export function regenerateRecoveryCodes(code: string, password: string) {
  const path = "/settings/2fa/regenerate-recovery-codes";
  return post<{ recoveryCodes: string[] }>(path, { code, password });
}

export function postReferrer(referrerSource: string) {
  const path = "/settings/user/referrer";
  return post<{ referrerSource: string }>(path, { referrerSource });
}

export function ReOnboardingCompleted() {
  const path = "/settings/re-onboarding-completed";
  return put<void>(path, {});
}

export async function restoreUserData() {
  return post("/settings/restore-data", {});
}

export async function userArchiveStatus() {
  return get<{
    status: ArchiveStatus;
  }>("/settings/archive-status");
}

type ArchiveStatus =
  | "archiving"
  | "archived"
  | "restoring"
  | "dirty-archive"
  | "active";
