import { Box } from "@mui/material";
import styled from "styled-components/macro";

export const GlowTopLeft = styled(Box)`
  &:before {
    content: "";
    border-radius: 21.25rem;
    background: #6319db;
    filter: blur(118px);
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 60%;
    z-index: -1;
    opacity: 0.5;
  }
`;

export const GlowTopRight = styled(Box)`
  &:before {
    content: "";
    border-radius: 21.25rem;
    background: #ab3aa7;
    filter: blur(110px);
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 60%;
    z-index: -1;
    opacity: 0.5;
  }
`;

export const GlowBottomLeft = styled(Box)`
  &:before {
    content: "";
    border-radius: 21.25rem;
    background: #8f31d9;
    filter: blur(118px);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60%;
    height: 50%;
    z-index: -1;
    opacity: 0.5;
  }
`;

export const GlowBottomRight = styled(Box)`
  &:before {
    content: "";
    border-radius: 21.25rem;
    background: #4d209c;
    filter: blur(106px);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    z-index: -1;
    opacity: 0.5;
  }
`;
