import { number, plural, select } from "@messageformat/runtime";
import { es } from "@messageformat/runtime/lib/cardinals";
export default {
  accept: () => "Aceptar",
  acceptTest: () => "Aceptar prueba",
  accountant: {
    addClient: () => "Nuevo cliente",
    addClientError: () =>
      "No se puede agregar un nuevo cliente. Si el problema persiste, comuníquese con el servicio de asistencia.",
    billingOwner: {
      accountant: () => "Contador",
      client: () => "Cliente",
    },
    billingPage: {
      failed: () => "Fallido",
      paid: () => "Pagado",
    },
    clientDefault: () => "Mi primer cliente",
    clientInListNotFound: () =>
      "No se pudo encontrar el cliente en la lista de clientes",
    clientListHeader: {
      billingDate: () => "Fecha de facturación",
      dateAdded: () => "fecha añadida",
      email: () => "correo electrónico",
      name: () => "nombre",
      plan: () => "plan",
      status: () => "estado",
    },
    clientTable: {
      billingTooltip: () =>
        "El plan ha sido cancelado y permanecerá activo hasta la próxima fecha de facturación.",
      clearFilters: () => "Limpiar filtros",
      headers: {
        billingDate: () => "Fecha de facturación",
        dateAdded: () => "Fecha añadida",
        name: () => "Nombre",
        plan: () => "Plan",
        subscriber: () => "Abonado",
        transaction: () => "Transacción",
      },
      invitePending: () => "Invitación pendiente:",
      invited: () => "Invitado",
      newClient: () => "Nuevo cliente",
      noClients: () => "Tus clientes aparecerán aquí",
      noClientsMatchFilter: () =>
        "No hay clientes que coincidan con el filtro ",
      view: () => "vista",
    },
    clientVersionNotFound: () =>
      "No se pudo determinar la versión de suscripción. Si el problema persiste, comuníquese con el servicio de asistencia.",
    defaultAccount: () => "Cuenta predeterminada",
    emptyState: {
      body: () => "Comience agregando su primer cliente.",
      title: () => "Aún no tienes ningún cliente",
    },
    errorLoadingClient: () => "Error al cargar el cliente",
    invite: {
      emailInvalid: () => "Dirección de correo electrónico no válida",
      emailRequired: () => "Se requiere correo electrónico",
      fromName: () => "Su nombre",
      fromNamePlaceholder: () => "Tu nombre completo",
      invite: () => "Invitar",
      inviteAccountant: () => "Invitar Contador",
      inviteWarning: () =>
        "Cuando el contador acepte la invitación, sus datos reemplazarán a los de ellos.",
      nameInvalid: () => "Sólo se permiten caracteres alfanuméricos.",
      nameRequired: () => "Nombre requerido",
      toEmail: () => "Correo electrónico del contable",
      toEmailPlaceholder: () => "El correo electrónico de su contable",
    },
    loadingClients: () => "Cargando...",
    maxClientLimit: {
      text: () => "Ha alcanzado el límite máximo de clientes para su cuenta.",
      title: () => "Límite de clientes alcanzado",
    },
    mostPopularPlanText: () => "Los más populares",
    moveAccountSettings: {
      body: () =>
        "Ahora puede encontrar la información y la configuración de su cuenta en la navegación bajo cuenta.",
      title: () => "Hemos movido la configuración de la cuenta.",
    },
    newClient: {
      buttonText: (d) =>
        select(d.isSaving, {
          true: " Agregando cliente ",
          other: " Agregar cliente ",
        }),
      clientType: {
        new: () => "Beta",
        title: () => "Tipo de cliente:",
        type: {
          business: () => "Negocio",
          individual: () => "Individual",
        },
      },
      error: {
        invalidName: (d) =>
          "Nombre no válido " +
          d.name +
          " . Solo se permiten caracteres alfanuméricos.",
        missingCountry: () => "Debes seleccionar un país",
        missingName: () => "Debes ingresar un nombre para tu cliente",
      },
      form: {
        email: {
          helperText: () =>
            "Agregue una dirección de correo electrónico para invitar y compartir el acceso con el cliente.",
          label: () => "Correo electrónico (opcional)",
        },
        name: {
          business: {
            helperText: () => "Este nombre aparecerá en la lista de clientes.",
            label: () => "Nombre de la organización o del cliente",
          },
          individual: {
            helperText: () => "Este nombre aparecerá en la lista de clientes.",
            label: () => "Nombre del cliente",
          },
        },
      },
      planCard: {
        aYear: () => " Un año",
        creditsAvailable: (d) =>
          d.credits +
          " " +
          select(d.singleCredit, { true: " crédito ", other: " créditos " }) +
          " disponibles",
        free: () => "Gratis",
        nextYear: (d) => "Luego " + d.price + " para los años siguientes.",
      },
      section1: {
        field1: () => "Apodo del cliente",
        field2: () => "Correo electrónico (opcional)",
        field3: () => "País",
        title: () => "Agregar información del cliente",
      },
      section2: {
        title: () => "Seleccione un plan",
      },
      section3: {
        body1: () => "El pago vence ",
        body2: () => " facturado en ",
        title: () => "Pago",
      },
      success: () => "Nuevo cliente añadido",
      title: () => "Agregar nuevo cliente",
    },
    noClientFound: () => "No se encontraron clientes",
    noClientSelected: () => "No se ha seleccionado ningún cliente",
    or: () => "O",
    savingClient: () => "Agregar cliente",
    subscriber: {
      accountant: () => "Contador",
      both: () => "Ambos",
      bothCTA: () =>
        "Posible pago doble. Póngase en contacto con el servicio de asistencia.",
      client: () => "Cliente",
      free: () => "Gratis",
      header: () => "abonado",
      nobody: () => "—",
      tooltip: () => "Pagador de la cuenta.",
    },
    upgradeClient: {
      cancel: () => "Cancelar",
      clientName: () => "Nombre del cliente: ",
      failMsg: () => "Error en la actualización del cliente",
      nextStepButtonText: () => "Próximo",
      payAmountAndUpgrade: (d) => "Pagar " + d.amount + " y actualizar",
      payAndUpgrade: () => "Pagar y actualizar",
      payment: {
        defaultErrorMsg: () =>
          "Comuníquese con el servicio de asistencia si esto continúa.",
        detail1: () => "Pago de ",
        detail2: () => " se facturará el ",
        noneRequired: () => "No se requiere pago",
        title: () => "Pago",
      },
      renewalErrorBanner: {
        body: () =>
          "Tiene facturas vencidas, no puede actualizar clientes mientras las facturas estén impagas",
        buttonText: () => "Ver facturas impagadas",
        title: () => "Las facturas pasadas están atrasadas",
      },
      selectAPlan: () => "Seleccione un plan",
      stepLabels: {
        payment: () => "Pago",
        selectPlan: () => "Seleccione un plan",
      },
      title: () => "Actualizar cliente",
      upgrade: () => "Mejora",
    },
  },
  accountantPortal: {
    createAccount: () => "Crear una cuenta",
    dialogLabel: () => "Incorporación de contadores",
    errorMessage: () =>
      "Se produjo un error al actualizar los planes. Comuníquese con el servicio de asistencia",
    paymentCtaPrefix: () => "Actualizar para ",
    steps: {
      accountantInfo: {
        accountantCompanyDescription: () =>
          "El nombre de su práctica contable.",
        continue: () => "Continuar",
        nameDescription: () => "Nombre que verán tus clientes.",
        required: () => "*Campos obligatorios",
      },
      payment: {
        cancelAnyTimeText: () => " Puedes cancelar en cualquier momento.",
        cardDetailsLabel: () => "Datos de la tarjeta",
        couponText: () => "¿Tienes un código de cupón?",
        discountAppliedText: () => "Descuento aplicado",
        discountDescription1: () => "Se le cobrará ",
        discountDescription2: () => " durante el primer año y ",
        discountDescription3: () => " para los años siguientes.",
        label: () => "Pago",
        paymentDescription1: () => "Se le cobrará ",
        paymentDescription2: () =>
          " anualmente hasta que canceles tu suscripción.",
        planTextSuffix: () => " plan",
        submitButtonSuffix: () => " y terminar",
        subtotalText: () => "Total parcial",
        title: () => "Resumen de pago",
        yearlySubscriptionText: () => "Suscripción anual",
      },
      welcome: {
        info: {
          switching: {
            subtitle: () =>
              "Al cambiar al Portal del Contador no podrá revertir la acción. Puede administrar su cuenta desde la configuración, incluido el cambio de dirección de correo electrónico.",
            title: () => "Cambio de cuentas",
          },
          transactions: {
            subtitle: () =>
              "Tus transacciones y plan de pagos serán transferidos a tu nuevo Portal de Contabilidad y tus datos aparecerán en la lista de clientes como primer cliente. Podrás seguir gestionando tus transacciones como antes.",
            title: () => "Transacciones y migración de datos",
          },
        },
      },
    },
    subtitle: () =>
      "Administra y calcula los impuestos de tus clientes en un solo lugar.",
    title: () => "Bienvenido al Portal del Contador",
  },
  accountingIntegrations: {
    connectAccountingSoftware: () => "Conectar software de contabilidad",
    connectIntegration: {
      connected: {
        connected: () => "Conectado",
        connectedAt: (d) => "Conectado a " + d.time,
        disconnect: () => "Eliminar conexión",
        disconnectWarning: {
          actionText: () => "Desconectar",
          alert: () =>
            "Se eliminarán las asignaciones de cuentas; deberá volver a asignarlas al volver a conectarse.",
          body: (d) =>
            "Esto desconectará su cuenta de " +
            d.erp +
            " y ya no podrá sincronizar sus transacciones con " +
            d.erp +
            " .",
          title: (d) => "Desconectar " + d.erp,
        },
        selectTenant: () => "Seleccionar empresa",
      },
      disconnected: {
        title: () => "Conectar integración",
      },
    },
    defaults: {
      accountMapping: {
        asset: {
          title: () => "Cuenta de activos",
          tooltip: () =>
            "Cuenta que representa el valor de los activos en la cartera del usuario",
        },
        cash: {
          title: () => "Cuenta de efectivo",
          tooltip: () =>
            "Cuenta que representa los fondos en efectivo. Si vende criptomonedas, representaremos los ingresos en efectivo aquí y viceversa cuando compre criptomonedas. Las transacciones entre criptomonedas no tendrán ningún impacto en esta cuenta.",
        },
        expense: {
          title: () => "Cuenta de gastos",
          tooltip: () => "Cuenta que representa gastos en criptomonedas",
        },
        fee: {
          title: () => "Cuenta de honorarios",
          tooltip: () =>
            "Cuenta que representa el importe pagado en concepto de honorarios",
        },
        income: {
          title: () => "Cuenta de ingresos",
          tooltip: () =>
            "Cuenta que representa ingresos provenientes de criptomonedas",
        },
        loan: {
          title: () => "Cuenta de préstamo",
          tooltip: () =>
            "Cuenta que representa préstamos y reembolsos de criptomonedas",
        },
        loss: {
          title: () => "Cuenta de pérdidas",
          tooltip: () =>
            "Cuenta que representa la cantidad de criptomonedas perdidas",
        },
        "realised-gain": {
          title: () => "Cuenta de ganancia realizada",
          tooltip: () =>
            "Cuenta que representa las ganancias que se han obtenido por la enajenación de criptoactivos",
        },
        "realised-loss": {
          title: () => "Cuenta de pérdidas realizadas",
          tooltip: () =>
            "Cuenta que representa las pérdidas que se han producido por la enajenación de criptoactivos",
        },
        "zero-cost-buy": {
          title: () => "Falta cuenta de historial de compras",
          tooltip: () =>
            "Cuando se compra u obtiene un activo y no se realiza ningún pago por él, consideramos que la base de costo del activo es el valor de mercado y esta cuenta se utiliza para equilibrar el otro lado del diario.",
        },
      },
      empty: () => "Comience conectando su integración contable",
      mapped: () => "Mapeado",
      required: () => "Requerido",
      selectorPlaceholder: (d) => "Seleccione " + d.account + " en " + d.erp,
      title: () => "Cuentas predeterminadas",
      unmapped: () => "Sin mapear",
    },
    integrations: () => "Mapeo predeterminado",
    rollupSettings: {
      options: {
        day: () => "Día",
        month: () => "Mes",
        none: () => "Sin rollup",
      },
      placeholder: () => "Seleccionar período de acumulación",
      subtitle: () =>
        "Todas las transacciones del período seleccionado se agruparán en una única entrada de diario.",
      title: () => "Configuración de rollup",
    },
    rules: () => "Reglas personalizadas",
    subtitle: (d) =>
      "Asigne categorías en CTC a sus diarios en " + d.erp + " .",
    subtitleWithoutERP: () =>
      "Asigne categorías en CTC a su software de contabilidad.",
    title: () => "Integraciones contables",
    warningSettings: {
      label: () =>
        "Permitir que las transacciones con advertencias se sincronicen",
      subtitle: (d) =>
        "Si está desactivado, todas las advertencias deben resolverse en una transacción determinada antes de que pueda sincronizarse con " +
        d.erp,
      title: () =>
        "Permitir que las transacciones con advertencias se sincronicen",
    },
  },
  accountingSteps: {
    body: () => "Todo lo que necesitas para gestionar a tus clientes",
    steps: {
      0: {
        body: () =>
          "Nuestro Portal de Contabilidad le permite invitar y administrar clientes, todo desde un panel de control fácil de usar.",
        title: () => "Gestiona todos tus clientes en un solo lugar",
      },
      1: {
        body: () =>
          "Brindamos soporte directo a cientos de exchanges, cadenas de bloques y servicios. Solo agregue claves API y direcciones de billetera.",
        title: () =>
          "Obtenga fácilmente datos de clientes a través del intercambio directo e integraciones de blockchain",
      },
    },
    title: () => "Herramientas de contabilidad y demostración",
  },
  add: () => "Agregar",
  addManualTX: {
    currencyPaidIn: () => "Moneda en la que pagó la tarifa",
    currencyPlaceholder: () => "por ejemplo BTC, ETH, XRP",
    error: () => "Algo salió mal al agregar la transacción.",
    exchangeName: () => "Nombre de intercambio",
    feePaidIn: () => "Cantidad de tarifa pagada en la moneda de tarifa",
    in: () => "en",
    mustDestination: () => "Debes introducir un destino",
    mustEnterExchange: () => "Debes ingresar un nombre de intercambio",
    mustSource: () => "Debes ingresar una fuente",
    optional: () => "Opcional",
    paidInFees: () => "pagado en cuotas",
    priceForOne: () => "Precio por 1",
    quotePlaceholder: () => "por ejemplo, TUSD, BTC, ETH, XRP",
    saving: () => "Ahorro",
    success: () => "Transacción manual creada.",
    totalQuantityOf: () => "Cantidad total de",
    totalValueIn: () => "Valor total en",
    viewTransaction: () => "Vista",
    whereDestination: () => "¿Cuál es el destino de su transacción?",
    whereFrom: () => "¿De dónde te estás transfiriendo?",
    whereSource: () => "¿Dónde está la fuente de su transacción?",
    whereTo: () => "¿A dónde te estás transfiriendo?",
    whichCategory: () => "¿Qué categoría elegir?",
  },
  adjustedPrice: {
    considersWashSalesDescription: () =>
      " y teniendo en cuenta las reglas de venta con pérdida",
    description: () =>
      "Este es el precio ajustado después de aplicar el precio promedio del pool.",
  },
  advanced: () => "Avanzado",
  advancedTransactionOptions: {
    findCategory: () => "Buscar categoría",
    grouped: {
      description: () =>
        "Es muy importante que te asegures de haber importado todos los datos de todas las fuentes posibles.",
      title: () => "Transacciones agrupadas",
    },
    in: {
      categoryGuide: () => "Guía de categorías de transacciones",
      description: () =>
        "Es muy importante que te asegures de haber importado todos los datos de todas las fuentes posibles.",
      incorrectAction: () => "Ver categorías salientes",
      incorrectPrefix: () => "¿No es una transacción entrante?",
      inputGroup: () => "Categorías entrantes",
      title: () => "Transacciones entrantes",
    },
    out: {
      categoryGuide: () => "Guía de categorías de transacciones",
      description: () =>
        "Es muy importante que te asegures de haber importado todos los datos de todas las fuentes posibles.",
      incorrectAction: () => "Ver categorías entrantes",
      incorrectPrefix: () => "¿No es una transacción saliente?",
      inputGroup: () => "Categorías salientes",
      title: () => "Transacciones salientes",
    },
  },
  alerts: {
    customCSV: () =>
      "Asegúrese de que sus datos estén preparados correctamente y en formato de archivo CSV",
    noCookie: () =>
      "Habilite las cookies, de lo contrario no podrá iniciar sesión. Si está utilizando el navegador Brave, debe hacer clic en el león cerca de la barra de navegación y deshabilitar los escudos para este sitio.",
  },
  all: () => "Todo",
  allPlans: () =>
    "Todos los planes tienen una garantía de devolución de dinero de 30 días.",
  api: {
    addressesIdentified: (d) =>
      "Direcciones identificadas como " + d.exchangeName,
    apiKeyLabel: () => "Clave API",
    connect: () => "Conectar",
    delete: () => "Eliminar API",
    deleteConfirmationText: (d) =>
      "¿Está seguro de que desea eliminar la clave API " +
      d.exchangeName +
      ' " ' +
      d.api +
      ' "? Esto también eliminará TODAS las transacciones importadas por esta clave API. Esta acción no se puede deshacer.',
    disabledExplanation: () =>
      "No puedes sincronizar a través de OAuth si ya has sincronizado a través de la clave API",
    edit: () => "Editar detalles de la API",
    editNickname: () => "Editar el nombre de la API",
    editingSubmit: () => "Actualizar API",
    exchanges: {
      binance: {
        crossMarginCurrencies: () => "Monedas de margen cruzado",
        syncMarginTickers: () => "Sincronizar margen cruzado",
        syncMarginTickersExplanation: () =>
          "Seleccione las divisas de margen cruzado que ha negociado. Esto es necesario para mejorar el rendimiento debido a las limitaciones impuestas por la API de Binance al obtener sus datos.",
      },
    },
    existingSubmit: (d) => "Agregar API adicional " + d.source,
    hardSync: {
      confirmationAllAPIs: (d) =>
        "¿Sincronizar firmemente las API " + d.name + " ?",
      confirmationOneAPI: () => "¿API de sincronización dura?",
      textOneAPI: (d) =>
        "La sincronización dura importará todas las transacciones de la API " +
        d.name +
        " desde " +
        d.date +
        " . Esto puede generar, en ocasiones, transacciones duplicadas que deben gestionarse manualmente, en caso de que la integración haya cambiado desde la última sincronización.",
      titleAllChipAPIs: () =>
        "Vuelva a sincronizar todos los datos, esto devolverá todas las transacciones eliminadas para esta integración.",
      titleOneAPI: (d) =>
        "Resincronizar todas las transacciones desde " +
        d.date +
        " para la API " +
        d.name,
    },
    import: () => "Importar",
    importRules: {
      existingApi: () => "La clave API ya existe.",
      missingApi: () => "Debes ingresar una clave API",
      missingPassphrase: () => "Debes ingresar una contraseña",
      missingSecret: () => "Debes ingresar el secreto",
      missingUid: () => "Debes ingresar un uid",
      multipleKeyError: (d) =>
        "No se pueden agregar varias claves API para " + d.name,
      uidHint: () =>
        'Sugerencia: A veces, este puede ser el nombre o la "memoria" que le diste a la API.',
    },
    maxTickerLabel: () =>
      "Has alcanzado la cantidad máxima de mercados permitidos por la API. Si tienes más mercados para agregar, impórtalos mediante CSV.",
    missingTickerAlert: (d) =>
      d.exchangeName +
      " requiere que se especifiquen todos los tickers al solicitar historiales de transacciones a través de su API. Agregue TODOS los tickers que haya negociado para obtener informes de impuestos precisos. También puede importarlos usando la opción CSV.",
    name: () => "Nombre de la API (opcional)",
    nickname: () => "Apodo",
    passwordLabel: () => "Frase de contraseña",
    reauthorize: () => "Reautorizar",
    resync: () => "Resincronizar",
    secretLabel: () => "Secreto",
    softSync: (d) => "Sincronizar transacciones de API de " + d.name + " desde",
    submit: (d) => "Agregar API " + d.source,
    sync: () => "Sincronizar",
    syncing: () => "Sincronización",
    tickerLabel: () => "Seleccionar todos los tickers utilizados",
    uidLabel: () => "Identificación única",
    update: () => "Actualizar detalles de la API",
  },
  apply: () => "Aplicar",
  auth: {
    acceptTermsToContinue: () =>
      "Debes aceptar los Términos de Servicio y la Política de Privacidad para continuar",
    accountantClient: () => "¿Clientes con criptomonedas?",
    accountantCompanyName: () => "Nombre de empresa",
    accountantContactName: () => "Nombre de contacto",
    accountantSignUp: () => "Regístrate como contador",
    accountantSignupSubTitle: () =>
      "Registrarse lleva menos de un minuto: no se requiere tarjeta de crédito",
    accountantSignupTitle: () => "Registro de contable",
    accountantWorkEmail: () => "Correo electrónico de trabajo",
    alreadyAccount: () => "¿Tienes una cuenta?",
    and: () => "y",
    areAccountant: () => "¿Es usted contador?",
    backTo: () => "Regresar a",
    bitcoinDotComAu30Banner: () =>
      "Descuento del 30% aplicado en planes de pago",
    breaker: () => "O",
    captcha: {
      and: () => "y",
      apply: () => "aplicar.",
      privacyPolicy: () => "política de privacidad",
      protectedBy: () =>
        "Este sitio está protegido por reCAPTCHA y se aplican las políticas de privacidad de Google. ",
      termsOfService: () => "Condiciones de servicio",
      verificationFailed: () => "Error en la verificación. Inténtalo de nuevo.",
    },
    coinbase: () => "Coinbase",
    coinbase30Banner: () =>
      "Se aplican 10 000 transacciones gratuitas de Coinbase y un 30 % de descuento en planes pagos",
    coinbaseOne1DollarBanner: (d) =>
      d.rookiePlanPrice +
      " Plan novato. 40% de descuento en todos los planes adicionales",
    coinbaseOne1DollarBannerNoCurrency: () =>
      "Oferta exclusiva para miembros de Coinbase aplicada",
    coinbaseOne40Banner: () =>
      "40% de descuento en planes pagos aplicado al momento de registrarse, exclusivamente para miembros de Coinbase One",
    coinbaseOnePremium40Banner: () =>
      "50% de descuento en el nivel premium de CTC, Trader",
    coinjar30Banner: () =>
      "1000 transacciones gratuitas en CoinJar y 30 % de descuento en planes pagos aplicados al momento de registrarse",
    coinstatsBanner: () =>
      "$30 de descuento en planes pagos aplicados al momento de registrarse",
    compliance: () =>
      "Facilite el cumplimiento con la calculadora de impuestos sobre criptomonedas",
    countryWarningBody: () =>
      "No puedes aceptar una invitación de un contador de otro país. Ponte en contacto con el servicio de asistencia.",
    countryWarningTitle: () => "Su país es diferente al de su contable.",
    customerNum: () => "Más de 200.000 clientes",
    differentSignUpMethod: () =>
      "Su correo electrónico ya existe con otro método de registro. Inténtelo nuevamente con el método original.",
    email: () => "Correo electrónico",
    embedded: {
      connectWith: (d) => "Conectarse con " + d.provider,
      signInWith: (d) => "Iniciar sesión con " + d.provider,
      tagline: () => "Informes fiscales transparentes y precisos",
      title: () => "Comience a utilizar Tax Hub",
    },
    errors: {
      invalidCode: () => "Código 2fa no válido. Inténtalo de nuevo.",
      invalidCredentials: () =>
        "Correo electrónico o contraseña no válidos. Inténtalo de nuevo.",
      invalidSignup: () => "Registro no válido. Inténtalo de nuevo.",
    },
    forgot: () => "¿Olvidaste tu contraseña?",
    forgotPassword: () => "Restablecer contraseña",
    goBack: () => "Volver",
    google: () => "Google",
    iAgree: () => "Al crear una cuenta, usted acepta los",
    independentReserve30Banner: () =>
      "Descuento del 30% aplicado en planes de pago",
    invalidCombination: () =>
      "Combinación de correo electrónico y contraseña no válida",
    invalidToken: () => "Token o correo electrónico no válido",
    inviteReceived: () => "Invitación recibida de:",
    login: () => "Acceso",
    loginPage: {
      oauth: {
        coinbase: () => "Iniciar sesión con Coinbase",
        google: () => "Iniciar sesión con Google",
        metamask: () => "Iniciar sesión con MetaMask",
      },
      title: () => "Bienvenido de nuevo",
    },
    loginTitle: () => "¡Bienvenido de nuevo!",
    loginTitleEmbedded: () => "Iniciar sesión en Tax Hub",
    metamask: () => "MetaMask",
    metamask30Banner: () =>
      "30% de descuento en planes pagos aplicado al momento de registrarse",
    missingEmail: () =>
      "Para utilizar esta función, ingrese un correo electrónico a través de la página de configuración",
    noAccount: () => "¿No tienes una cuenta?",
    notAccountant: () => "¿No eres contador?",
    officialPartner: () => "SOCIO OFICIAL DE",
    officialPartners: () => "Socios oficiales",
    orLogin: () => "O inicie sesión con correo electrónico",
    orSignup: () => "O regístrate con correo electrónico",
    orSwitchTo: () => "o",
    password: () => "Contraseña",
    passwordCondition: () =>
      "Su contraseña debe tener al menos 8 caracteres. También debe contener un número (0-9) o un carácter especial (!@#$%^&*).",
    passwordResetButton: () => "Restablecer contraseña",
    passwordResetFailed: () => "Error al restablecer la contraseña",
    passwordResetInstructions: () =>
      "Ingrese su dirección de correo electrónico a continuación y le enviaremos un enlace para restablecer su contraseña:",
    passwordResetSuccessfully: () => "Restablecimiento de contraseña exitoso",
    passwordUpdatedSuccessfully: () => "Contraseña actualizada exitosamente",
    privacyPolicy: () => "política de privacidad",
    recaptcha: {
      part1: () => "Este sitio está protegido por reCAPTCHA y la",
      part2: () => "y",
      part3: () => "de Google aplican.",
    },
    recogniseBody: () => "Sus datos serán compartidos con ellos",
    recogniseTitle: () =>
      "¡Asegúrate de reconocer el correo electrónico de tu contador!",
    resetEmailSent: (d) =>
      "Si el correo electrónico proporcionado está asociado a una cuenta, se ha enviado un correo electrónico de restablecimiento a " +
      d.email,
    resetPasswordSubTitle: () =>
      "Introduce una nueva contraseña para tu cuenta",
    resetPasswordTitle: () => "Restablecer su contraseña",
    setPasswordTitle: () => "Establezca su contraseña",
    signInWith: () => "Iniciar sesión con:",
    signin: () => "Acceso",
    signup: () => "Inscribirse",
    signupPage: {
      accurateCryptoTaxes: () => "Impuestos de criptomonedas precisos.",
      mobileTitle: () => "¡Comienza gratis!",
      noGuesswork: () => "Sin conjeturas.",
      oauth: {
        coinbase: () => "Registrarse con Coinbase",
        google: () => "Registrarse con Google",
        metamask: () => "Registrarse con MetaMask",
      },
      terms: {
        and: () => "y",
        body: () => "Acepto los",
        privacyPolicy: () => "Política de privacidad",
        termsNotAccepted: () =>
          "Por favor, acepta los Términos de servicio y la Política de privacidad para continuar",
        termsOfService: () => "Términos de servicio",
      },
      text: () => "Registrarse",
      title: () => "Regístrate y comienza gratis",
      trustpilot: {
        reviews: () => "Más de 500 reseñas",
        title: () => "Basado en",
      },
    },
    signupPromoText: {
      phantom: () =>
        "Obtén 200 transacciones gratuitas de Phantom Wallet y un 20% de descuento en tu plan CTC",
    },
    signupSubTitle: () => "Registrarse toma menos de un minuto",
    signupTitle: () => "Crea tu cuenta gratis ahora",
    somethingWentWrong: () => "Algo salió mal, por favor inténtalo de nuevo",
    strongPassword: () => "Genial. Tu contraseña cumple con los requisitos.",
    termsAndConditions: () => "Condiciones de servicio",
    toSignUp: () =>
      "¡Regístrese para usar la Calculadora de Impuestos Criptomonedas!",
    tooManyPasswordResetAttempts: () =>
      "Demasiados intentos de restablecer contraseña, inténtelo nuevamente más tarde.",
    transparentReports: () => "Informes fiscales transparentes y precisos",
    trustPilotExcellent: () => "Excelente",
    trustedBy: () => "Con la confianza de",
    unableToResetEmail: (d) =>
      "No se puede restablecer la contraseña del correo electrónico: " +
      d.email,
    unlock: () => "Cuenta desbloqueada",
    userSignUp: () => "Regístrate como usuario",
    worldwide: () => "mundial.",
    xero: () => "Xero",
    yourAccountant: () => "Tu contador",
    youreInvited: () => "Estás invitado por",
  },
  autoClose: {
    title: () =>
      "Esta pestaña debería cerrarse automáticamente, pero puedes cerrarla ahora.",
  },
  back: () => "Atrás",
  billing: {
    44359: () => "44359: Algo salió mal al crear el método de pago",
    45732: () => "45732: Algo salió mal al encontrar la tarjeta",
    addCard: {
      cancel: () => "Cancelar",
      description: () =>
        "Esta nueva tarjeta se convertirá en tu método de pago predeterminado",
      loading: () => "Agregar tarjeta",
      submit: () => "Añadir tarjeta",
      title: () => "Agregar una tarjeta",
    },
    billingHistory: {
      table: {
        amount: () => "Cantidad",
        date: () => "Fecha",
        description: () => "Descripción",
      },
      title: () => "Historial de facturación",
    },
    paymentFailed: () =>
      "Error en el pago. Algo salió mal. Inténtalo de nuevo o ponte en contacto con el servicio de asistencia si el problema persiste.",
    paymentFailedCode: (d) =>
      "Error en el pago. Algo salió mal con nuestro procesador de pagos. Inténtalo de nuevo o ponte en contacto con el servicio de asistencia con el código de error " +
      d.code +
      " .",
    paymentMethod: {
      button: () => "Añadir tarjeta",
      ending: () => "final",
      expired: () => "Venció",
      expires: () => "Caduca",
      inUse: () => "En uso",
      title: () => "Tarjeta activa",
    },
    paymentStatus: {
      failed: {
        description: () =>
          "Actualice la información de su tarjeta para continuar utilizando todas las funciones de la aplicación.",
        title: () => "Pago fallido",
      },
    },
    somethingWentWrong: () => "Algo salió mal",
    stripe: {
      44359: () => "44359: Algo salió mal al crear el método de pago",
      45732: () =>
        "45732: Algo salió mal al encontrar el elemento de la tarjeta",
      applied: () => "Aplicado",
      apply: () => "Aplicar",
      cardSuccessfullyAdded: () => "Tarjeta añadida exitosamente.",
      clear: () => "Claro",
      couponExpired: (d) => "El cupón " + d.name + " ha expirado",
      couponNotValid: (d) => "El cupón " + d.name + " no es válido",
      discountApplied: (d) =>
        "¡Descuento aplicado " + d.percent + " en tu primer año!",
      failedToAddTheCard: () => "No se pudo agregar la tarjeta",
      noPortal: () =>
        "No se pudo acceder al portal de Stripe. Inténtalo nuevamente o comunícate con el servicio de asistencia.",
      noSession: () =>
        "No se pudo crear una sesión de Stripe. Inténtalo de nuevo o ponte en contacto con el servicio de asistencia.",
      noStripe: () =>
        "Se produjo un error al acceder a Stripe. Inténtalo nuevamente o comunícate con el servicio de asistencia.",
    },
    title: () => "Facturación",
    wentWrongBillingHistory: () =>
      "Se produjo un error al obtener el historial de facturación. Inténtalo nuevamente más tarde.",
    wentWrongCoupon: () =>
      "Se produjo un error al obtener los detalles del cupón. Inténtalo nuevamente más tarde.",
    wentWrongPaymentMethods: () =>
      "Se produjo un error al obtener los métodos de pago. Inténtalo nuevamente más tarde.",
  },
  bulkSelect: {
    moreActions: () => "Más acciones",
    recategoriseAs: () => "Recategorizar como",
  },
  button: {
    accept: () => "Aceptar",
    decline: () => "Rechazar",
    pending: () => "Pendiente",
  },
  calcoTour: {
    buttons: {
      back: () => "Atrás",
      next: () => "Próximo",
    },
    reconciliation: {
      uncategorise: {
        CTA: () => "Empezar a categorizar",
        addAnotherAccount: () =>
          "Si te das cuenta de que olvidaste importar una cuenta, puedes agregar otra aquí",
        associateTx: (d) =>
          "Para ayudarlo a identificar y categorizar esta transacción, vea todas sus otras transacciones con " +
          d.counterParty,
        categoriesButton: () =>
          "Si sabe cuál es esta transacción, categorícela y automáticamente aplicaremos la categoría a otras transacciones similares.",
        exampleTx: () =>
          "A continuación se muestra un ejemplo de una transacción que debes categorizar.",
        incomingWallet: () => "Esta es la billetera que recibió el activo.",
        outgoingWallet: () => "Esta es la billetera que envió el activo.",
        typeSelectorIncoming: () =>
          "Éste está etiquetado como 'entrante' porque ha recibido un activo, pero se requiere una categoría más específica para determinar el resultado fiscal.",
        typeSelectorOutgoing: () =>
          "Éste está etiquetado como 'saliente' porque ha enviado un activo, pero se requiere una categoría más específica para determinar el resultado fiscal.",
      },
    },
  },
  cancel: () => "Cancelar",
  changeLang: {
    changeLang: () => "Cambiar idioma",
    error: () => "Error al guardar la preferencia de idioma",
    language: () => "Idioma",
    languages: () => "Idiomas",
    pleaseSelect: () => "Por favor seleccione su idioma",
  },
  clear: () => "Claro",
  clientPays: {
    bannerText: () =>
      "Para que su contador pueda generar sus informes fiscales, deberá actualizar su suscripción.",
    bannerTitle: () => "Actualización para generar informes fiscales",
    buttonText: () => "Suscripción de actualización",
  },
  clients: {
    cancelSub: {
      areYouSure: () =>
        "Una vez que finalice su período de facturación actual, se cancelará este cliente. Se conservarán todos los datos y vínculos con el cliente. ¿Está seguro de que desea cancelar la suscripción?",
      buttonText: () => "Cancelar suscripción",
      exit: () => "Salida",
    },
    clone: {
      clone: () => "Clon",
      cloneClient: () => "Clonar cliente",
      clonesAccountantData: () =>
        "Esto clonará los datos que haya cargado para este cliente (es decir, no los datos de su cliente si está viendo su cuenta). Esto no afectará la cuenta existente. La cuenta clonada será gratuita, pero podrá actualizarse.",
    },
    delete: {
      accessOutcome: () =>
        "Los datos serán inaccesibles para usted y sus colaboradores.",
      areYouSure: (d) =>
        select(d.name, {
          unknown: " ¿Está seguro de que desea eliminar este cliente? ",
          other:
            ' ¿Está seguro de que desea eliminar el cliente " ' +
            d.name +
            ' "? ',
        }),
      cannotBeUndone: () => "Esta acción no se puede deshacer.",
      clientAccessOutcome: (d) =>
        select(d.accountantOwnedData, {
          true: " Su cliente también perderá el acceso a sus datos. ",
          other: " Su cliente seguirá teniendo acceso a sus datos. ",
        }),
      deleteButton: () => "Borrar",
      deleteClient: () => "Eliminar cliente",
    },
    invite: {
      emailRequired: () => "Se requiere correo electrónico",
      fromName: () => "Su nombre",
      fromNamePlaceholder: () => "Tu nombre completo",
      invite: () => "Invitar",
      inviteClient: () => "Invitar cliente",
      inviteWarning: () =>
        "Cuando el cliente acepta la invitación, por defecto verá los datos que has subido en su nombre. Si el cliente desea ver los datos originales que ha subido, tiene esta opción en su configuración.",
      nameRequired: () => "Nombre requerido",
      toEmail: () => "Correo electrónico del cliente",
      toEmailPlaceholder: () => "El correo electrónico de su cliente",
    },
    migrate: {
      settingsBody: () =>
        "A partir del 8 de septiembre de 2022, se cancelaron todas las cuentas de demostración. Todas las transacciones importadas anteriormente se migraron a un nuevo cliente, que se puede eliminar en cualquier momento.",
      settingsTitle: () => "No más cuentas demo",
    },
    newAccountInvite: {
      acceptFailed: () =>
        "No se pudo aceptar la invitación, por favor intente nuevamente.",
      declineFailed: () =>
        "No se pudo rechazar la invitación, por favor intente nuevamente.",
      isNotActionable: () =>
        "Esta invitación ya no es procesable, comuníquese con el soporte para obtener ayuda.",
      textAccountantOwns: (d) =>
        "Su contable ( " +
        d.email +
        " ) lo ha invitado a colaborar. Si acepta esta invitación, podrá ver y editar los datos que su contable ha cargado en su nombre. Puede revocar el acceso en cualquier momento, pero los datos permanecerán en poder de su contable. ¿Está seguro de que desea aceptar esta invitación?",
      textClientOwns: (d) =>
        "Su contable ( " +
        d.email +
        " ) lo ha invitado a colaborar. Si acepta esta invitación, podrá ver y editar sus datos. Puede revocar el acceso en cualquier momento. ¿Está seguro de que desea aceptar esta invitación?",
      title: () => "Usted ha sido invitado por su contable",
    },
    reinvite: {
      emailRequired: () => "Se requiere correo electrónico",
      fromName: () => "Su nombre",
      fromNamePlaceholder: () => "Tu nombre completo",
      nameRequired: () => "Nombre requerido",
      reinvite: () => "Reinvitar",
      reinviteClient: () => "Reinvitar al cliente",
      reinviteWarning: () =>
        "El cliente verá la cuenta compartida al aceptar la invitación.",
      toEmail: () => "Correo electrónico del cliente",
      toEmailPlaceholder: () => "El correo electrónico de su cliente",
    },
    settings: () => "Configuración del cliente",
    uncancelSub: {
      areYouSure: () =>
        "Este cliente ya no se cancelará y se le facturará cuando comience el nuevo período de facturación. ¿Está seguro de que desea cancelar la suscripción?",
      buttonText: () => "Cancelar suscripción",
      exit: () => "Salida",
    },
  },
  close: () => "Cerca",
  collaborators: {
    cancelInvitation: () => "Cancelar invitación",
    cancelInvitationGenericError: () =>
      "Se produjo un error al eliminar la invitación. Inténtalo nuevamente más tarde.",
    cancelInvitationSuccess: () => "Invitación cancelada exitosamente",
    email: () => "Correo electrónico",
    invitationSent: () => "¡Invitación enviada!",
    inviteTeamMember: () => "Invitar a un miembro del equipo",
    inviteTeamMembers: {
      linkText: () => "Invitar a miembros",
      postLinkText: () => "colaborar y compartir clientes",
    },
    name: () => "Nombre",
    pending: () => "Pendiente",
    reinvite: () => "Reinvitar a un miembro del equipo",
    reinviteSuccess: () => "Miembro del equipo re-invitado exitosamente.",
    revokeAccess: () => "Revocar acceso",
    revokeAccessSuccess: () => "Acceso revocado exitosamente.",
    send: () => "Enviar",
    team: () => "Equipo",
    teamMemberEmail: () =>
      "Dirección de correo electrónico del miembro del equipo",
    teamMemberName: () => "Nombre completo del miembro del equipo",
    teamMembers: () => "Miembros del equipo",
    teamMembersEmpty: () => "No hay miembros del equipo invitados",
  },
  confirm: () => "Confirmar",
  contacts: {
    addAddress: {
      addAddress: () => "Agregar dirección",
      address: () => "DIRECCIÓN",
      blockchain: () => "Cadena de bloques",
      buttons: {
        add: () => "Agregar",
        cancel: () => "Cancelar",
      },
      duplicateAddress: () => "Dirección duplicada",
      title: () => "Añadir nueva dirección",
    },
    addContact: () => "Agregar contacto",
    addressBook: () => "Contactos",
    contact: () => "Nombre de contacto",
    create: {
      addAddress: () => "Agregar dirección",
      address: () => "DIRECCIÓN",
      alreadyExists: () => "El contacto ya existe",
      associatedAddresses: () => "Direcciones asociadas",
      blockchain: () => "Cadena de bloques",
      cancel: () => "Cancelar",
      createContact: () => "Crear contacto",
      duplicateAddress: () => "Dirección duplicada",
      name: () => "Nombre de contacto",
      nameTitle: () => "Nombre",
      title: () => "Nuevo contacto",
    },
    csv: {
      errorFileNotCsv: () => "Debe ser un CSV",
      exportCsv: () => "Exportar direcciones no identificadas en formato CSV",
      importCsv: () => "Importar CSV",
      success: (d) => d.number + " contactos actualizados",
    },
    edit: {
      associatedAddresses: () => "Direcciones asociadas",
      rules: () => "Normas",
    },
    entityType: {
      contract: () => "Contrato inteligente",
      imported: () => "Billetera importada",
      manual: () => "Contacto manual",
    },
    error: () => "Error",
    findAddress: () => "Encontrar dirección",
    identified: () => "Identificado",
    identifiedTable: {
      allTooltip: () =>
        "Algunas direcciones de este contacto se han identificado en todas las redes.",
      asterisk: () => "*",
      networks: () => "Redes",
      rules: () => "Normas",
      rulesApplied: (d) =>
        plural(d.count, 0, es, {
          one: " 1 regla ",
          other: number("es", d.count, 0) + " reglas ",
        }) + " aplicadas",
      setRule: () => "Establecer regla",
      transactions: () => "Actas",
    },
    newContact: () => "Agregar nuevo contacto",
    noContacts: () =>
      'Haga clic en "Agregar contacto" para agregar su primer contacto.',
    noResults: () => "No hay resultados de búsqueda",
    noUnidentified: () =>
      "Todas las direcciones identificadas. No hay nada que ver aquí.",
    required: () => "Requerido",
    ruleCard: {
      active: () => "Activo",
      addCondition: () => "Añadir condición",
      appliesTo: (d) =>
        "Esta regla se aplica a " +
        plural(d.count, 0, es, {
          one: " 1 transacción ",
          other: number("es", d.count, 0) + " transacciones ",
        }),
      cancel: () => "Cancelar",
      createMoreRules: () => "Crear otra regla",
      createRule: () => "Crear regla",
      deleteRule: () => "Eliminar regla",
      disableRule: () => "Deshabilitar regla",
      disabled: () => "Desactivado",
      disabledTooltip: () =>
        "La regla no se aplica a las transacciones nuevas. Las transacciones existentes siguen vinculadas a esta regla y sus efectos no se verán anulados por otras reglas.",
      editRule: () => "Editar",
      enableRule: () => "Habilitar regla",
      enabledTooltip: () =>
        "Esta regla está activa y se aplicará a transacciones nuevas y existentes.",
      if: () => "SI",
      newRule: (d) => "Regla " + d.number,
      save: () => "Ahorrar",
      setAccount: () => "Agregar operación",
      then: () => "ENTONCES",
      tooltip: () => "Conecte su software de contabilidad para crear reglas",
      view: () => "Vista",
      willApplyTo: (d) =>
        "Esta regla se aplicará a " +
        plural(d.count, 0, es, {
          one: " 1 transacción ",
          other: number("es", d.count, 0) + " transacciones ",
        }),
    },
    rules: {
      importsDrawerTitle: () => "Administrar reglas",
      operators: {
        changeErpAssetAccount: () => "Cuenta de activos",
        changeErpCashAccount: () => "Cuenta de efectivo",
        changeErpGainsAccount: () => "Cuenta de ganancias",
        changeErpLoanAccount: () => "Cuenta de préstamo",
        changeErpPnLAccount: () => "Cuenta de pérdidas y ganancias",
        recategorise: () => "Categoría",
      },
      ruleNotFound: () => "Regla no encontrada",
      rulesImportsButton: () => "Normas",
    },
    table: {
      address: () => "DIRECCIÓN",
      addresses: () => "Direcciones",
      name: () => "Nombre",
      transactions: () => "Actas",
      type: () => "Tipo",
    },
    unidentified: () => "No identificado",
    unidentifiedTable: {
      assets: () => "Activos enviados/recibidos",
      chains: () => "Cadenas",
      firstDate: () => "Primera cita de tx",
      lastDate: () => "Última fecha de tx",
      modal: {
        assets: () => "Activos:",
        buttonCTA: () => "Identificar dirección",
        importCTA: () => "Importarlo",
        importLabel: () => "¿Es esta tu billetera?",
        transferAmount: () => "Monto de transferencia:",
        txs: () => "Actas:",
      },
      transferAmount: () => "Monto de la transferencia",
      txs: () => "Actas",
    },
  },
  context: () => "Ver en contexto",
  copied: () => "Copiado",
  copy: () => "Copiar",
  copyAddress: () => "Copiar dirección",
  copyTxLink: () => "Copiar enlace",
  copyTxLinkSuccess: () => "Enlace de transacción copiado al portapapeles",
  countries: {
    AD: () => "Andorra",
    AE: () => "Emiratos Árabes Unidos",
    AF: () => "Afganistán",
    AG: () => "Antigua y Barbuda",
    AI: () => "Anguila",
    AL: () => "Albania",
    AM: () => "Armenia",
    AO: () => "Angola",
    AQ: () => "Antártida",
    AR: () => "Argentina",
    AS: () => "Samoa Americana",
    AT: () => "Austria",
    AU: () => "Australia",
    AW: () => "Aruba",
    AZ: () => "Azerbaiyán",
    BA: () => "Bosnia y Herzegovina",
    BB: () => "Barbados",
    BD: () => "Bangladés",
    BE: () => "Bélgica",
    BF: () => "Burkina Faso",
    BG: () => "Bulgaria",
    BH: () => "Bahréin",
    BI: () => "Burundi",
    BJ: () => "Benín",
    BL: () => "San Bartolomé",
    BM: () => "islas Bermudas",
    BN: () => "Brunéi Darussalam",
    BO: () => "Bolivia",
    BQ: () => "Bonaire",
    BR: () => "Brasil",
    BS: () => "Bahamas",
    BT: () => "Bután",
    BV: () => "Isla Bouvet",
    BW: () => "Botsuana",
    BY: () => "Bielorrusia",
    BZ: () => "Belice",
    CA: () => "Canadá",
    CC: () => "Islas Cocos",
    CD: () => "República Democrática del Congo",
    CF: () => "República Centroafricana",
    CG: () => "Congo",
    CH: () => "Suiza",
    CI: () => "Costa de Marfil",
    CK: () => "Islas Cook",
    CL: () => "Chile",
    CM: () => "Camerún",
    CN: () => "Porcelana",
    CO: () => "Colombia",
    CR: () => "Costa Rica",
    CU: () => "Cuba",
    CV: () => "Cabo Verde",
    CW: () => "Curazao",
    CX: () => "Isla de Navidad",
    CY: () => "Chipre",
    CZ: () => "República Checa",
    DE: () => "Alemania",
    DJ: () => "Yibuti",
    DK: () => "Dinamarca",
    DM: () => "Dominica",
    DO: () => "República Dominicana",
    DZ: () => "Argelia",
    EC: () => "Ecuador",
    EE: () => "Estonia",
    EG: () => "Egipto",
    EH: () => "Sahara Occidental",
    ER: () => "Eritrea",
    ES: () => "España",
    ET: () => "Etiopía",
    FI: () => "Finlandia",
    FJ: () => "Fiyi",
    FK: () => "Islas Malvinas",
    FM: () => "Micronesia",
    FO: () => "Islas Feroe",
    FR: () => "Francia",
    GA: () => "Gabón",
    GD: () => "Granada",
    GE: () => "Georgia",
    GF: () => "Guayana Francesa",
    GG: () => "Guernsey",
    GH: () => "Ghana",
    GI: () => "Gibraltar",
    GL: () => "Tierra Verde",
    GN: () => "Guinea",
    GP: () => "Guadalupe",
    GQ: () => "Guinea Ecuatorial",
    GR: () => "Grecia",
    GS: () => "Islas Georgias del Sur y Sandwich del Sur",
    GT: () => "Guatemala",
    GU: () => "Guam",
    GW: () => "Guinea-Bissau",
    GY: () => "Guayana",
    HK: () => "Hong Kong",
    HM: () => "Islas Heard y McDonald",
    HN: () => "Honduras",
    HR: () => "Croacia",
    HT: () => "Haití",
    HU: () => "Hungría",
    ID: () => "Indonesia",
    IE: () => "Irlanda",
    IL: () => "Israel",
    IM: () => "Isla de Man",
    IN: () => "India",
    IQ: () => "Irak",
    IR: () => "Irán",
    IS: () => "Islandia",
    IT: () => "Italia",
    JE: () => "Jersey",
    JM: () => "Jamaica",
    JO: () => "Jordán",
    JP: () => "Japón",
    KE: () => "Kenia",
    KG: () => "Kirguistán",
    KI: () => "Kiribati",
    KN: () => "San Cristóbal y Nieves",
    KP: () => "Corea del Norte",
    KR: () => "Corea del Sur",
    KW: () => "Kuwait",
    KY: () => "Islas Caimán",
    KZ: () => "Kazajstán",
    LA: () => "República Democrática Popular Lao",
    LB: () => "Líbano",
    LC: () => "Santa Lucía",
    LI: () => "Liechtenstein",
    LK: () => "Sri Lanka",
    LR: () => "Liberia",
    LS: () => "Lesoto",
    LT: () => "Lituania",
    LU: () => "Luxemburgo",
    LV: () => "Letonia",
    LY: () => "Libia",
    MA: () => "Marruecos",
    MC: () => "Mónaco",
    MD: () => "Moldavia",
    ME: () => "Montenegro",
    MF: () => "San Martín",
    MG: () => "Madagascar",
    MH: () => "Islas Marshall",
    MK: () => "Macedonia del Norte",
    ML: () => "Malí",
    MM: () => "Birmania",
    MN: () => "Mongolia",
    MO: () => "Macao",
    MP: () => "Islas Marianas del Norte",
    MQ: () => "Martinica",
    MR: () => "Mauritania",
    MS: () => "Montserrat",
    MT: () => "Malta",
    MU: () => "Mauricio",
    MV: () => "Maldivas",
    MW: () => "Malaui",
    MX: () => "México",
    MY: () => "Malasia",
    MZ: () => "Mozambique",
    NA: () => "Namibia",
    NC: () => "Nueva Caledonia",
    NE: () => "Níger",
    NF: () => "Isla Norfolk",
    NG: () => "Nigeria",
    NI: () => "Nicaragua",
    NL: () => "Países Bajos",
    NO: () => "Noruega",
    NP: () => "Nepal",
    NR: () => "Nauru",
    NU: () => "Niue",
    NZ: () => "Nueva Zelanda",
    OM: () => "Omán",
    PA: () => "Panamá",
    PE: () => "Perú",
    PG: () => "Papúa Nueva Guinea",
    PH: () => "Filipinas",
    PK: () => "Pakistán",
    PL: () => "Polonia",
    PM: () => "San Pedro y Miquelón",
    PN: () => "Islas Pitcairn",
    PR: () => "Puerto Rico",
    PS: () => "Palestina",
    PT: () => "Portugal",
    PW: () => "Palaos",
    PY: () => "Paraguay",
    QA: () => "Katar",
    RE: () => "Reunión",
    RO: () => "Rumania",
    RS: () => "Serbia",
    RU: () => "Federación Rusa",
    RW: () => "Ruanda",
    SA: () => "Arabia Saudita",
    SB: () => "Islas Salomón",
    SC: () => "Seychelles",
    SD: () => "Sudán",
    SE: () => "Suecia",
    SG: () => "Singapur",
    SH: () => "Santa Elena",
    SI: () => "Eslovenia",
    SJ: () => "Svalbard y Jan Mayen",
    SK: () => "Eslovaquia",
    SL: () => "Sierra Leona",
    SM: () => "San Marino",
    SN: () => "Senegal",
    SO: () => "Somalia",
    SR: () => "Surinam",
    SS: () => "Sudán del Sur",
    ST: () => "Santo Tomé y Príncipe",
    SV: () => "El Salvador",
    SX: () => "San Martín",
    SY: () => "República Árabe Siria",
    TC: () => "Islas Turcas y Caicos",
    TG: () => "Ir",
    TH: () => "Tailandia",
    TJ: () => "Tayikistán",
    TK: () => "Tokelau",
    TL: () => "Timor Oriental",
    TM: () => "Turkmenistán",
    TN: () => "Túnez",
    TO: () => "Tonga",
    TR: () => "Pavo",
    TT: () => "Trinidad y Tobago",
    TV: () => "Tuvalu",
    TW: () => "Taiwán",
    TZ: () => "Tanzania",
    UA: () => "Ucrania",
    UG: () => "Uganda",
    UK: () => "Reino Unido",
    US: () => "Estados Unidos",
    UY: () => "Uruguay",
    UZ: () => "Uzbekistán",
    VA: () => "Ciudad del Vaticano",
    VC: () => "San Vicente y las Granadinas",
    VE: () => "Venezuela",
    VG: () => "Islas Vírgenes Británicas",
    VI: () => "Islas Vírgenes de los Estados Unidos",
    VN: () => "Vietnam",
    VU: () => "Vanuatu",
    WF: () => "Wallis y Futuna",
    WS: () => "Samoa",
    YE: () => "Yemen",
    YT: () => "Mayotte",
    ZA: () => "Sudáfrica",
    ZM: () => "Zambia",
    ZW: () => "Zimbabue",
  },
  countryAbbreviations: {
    AD: () => "Y",
    AE: () => "Emiratos Árabes Unidos",
    AF: () => "Grupo Aéreo",
    AG: () => "AGB",
    AI: () => "AGL",
    AL: () => "ALBA",
    AM: () => "BRAZO",
    AO: () => "ANG",
    AQ: () => "HORMIGA",
    AR: () => "ARG",
    AS: () => "AMS",
    AT: () => "Autor",
    AU: () => "Australia",
    AW: () => "ARU",
    AZ: () => "AZE",
    BA: () => "BOS",
    BB: () => "BAR",
    BD: () => "PROHIBICIÓN",
    BE: () => "BEL",
    BF: () => "REBABA",
    BG: () => "BUL",
    BH: () => "BHR",
    BI: () => "BDI",
    BJ: () => "Ben",
    BL: () => "decodificador",
    BM: () => "BER",
    BN: () => "Bruto",
    BO: () => "Bolívar",
    BQ: () => "BUENO",
    BR: () => "SOSTÉN",
    BS: () => "BAH",
    BT: () => "Universidad de Benito Juárez",
    BV: () => "BVT",
    BW: () => "BOT",
    BY: () => "BLR",
    BZ: () => "Blasfemia",
    CA: () => "PODER",
    CC: () => "CCK",
    CD: () => "BACALAO",
    CF: () => "c y f",
    CG: () => "DIENTE",
    CH: () => "IUE",
    CI: () => "CUNA",
    CK: () => "CKI",
    CL: () => "CHI",
    CM: () => "LEVA",
    CN: () => "China",
    CO: () => "COLUMNA",
    CR: () => "CRI",
    CU: () => "CACHORRO",
    CV: () => "TAXI",
    CW: () => "CANALLA",
    CX: () => "CRI",
    CY: () => "Chipre",
    CZ: () => "República Checa",
    DE: () => "Alemania",
    DJ: () => "DJI",
    DK: () => "GUARIDA",
    DM: () => "Dirección de Medio Ambiente",
    DO: () => "DOMINGO",
    DZ: () => "ALGO",
    EC: () => "Unidad de control electrónico",
    EE: () => "EST",
    EG: () => "EGI",
    EH: () => "ESH",
    ER: () => "ERI",
    ES: () => "ESP",
    ET: () => "ETH",
    FI: () => "ALETA",
    FJ: () => "FIJ",
    FK: () => "FLK",
    FM: () => "Estados Unidos de América",
    FO: () => "DESDE",
    FR: () => "Francia",
    GA: () => "CHARLA",
    GD: () => "Gratitud",
    GE: () => "GEO",
    GF: () => "RFA",
    GG: () => "GGY",
    GH: () => "AHA (Agencia de Salud Pública)",
    GI: () => "GIBRALTAR",
    GL: () => "GRL",
    GN: () => "GINEBRA",
    GP: () => "BPL",
    GQ: () => "EQU",
    GR: () => "GRE",
    GS: () => "SGS",
    GT: () => "GTM",
    GU: () => "GOMA",
    GW: () => "GNB",
    GY: () => "CHICO",
    HK: () => "Gobierno de Hong Kong",
    HM: () => "Pantalla táctil",
    HN: () => "Honrado",
    HR: () => "CRO",
    HT: () => "HTI",
    HU: () => "HUNO",
    ID: () => "Nombre de dominio internacional (IDN)",
    IE: () => "IRA",
    IL: () => "ISR",
    IM: () => "IMN",
    IN: () => "India",
    IQ: () => "IRQ",
    IR: () => "IRA",
    IS: () => "HIELO",
    IT: () => "ES",
    JE: () => "JOY",
    JM: () => "ATASCO",
    JO: () => "Jornada",
    JP: () => "Japón",
    KE: () => "CONOCIDO",
    KG: () => "KGZ",
    KI: () => "KIRO",
    KN: () => "STK",
    KP: () => "PRK",
    KR: () => "COR",
    KW: () => "KUW",
    KY: () => "ISLA PEQUEÑA",
    KZ: () => "Kazajistán",
    LA: () => "LAO",
    LB: () => "LEB",
    LC: () => "STL",
    LI: () => "MENTIR",
    LK: () => "ISR",
    LR: () => "LIB",
    LS: () => "LOS",
    LT: () => "Licencia de uso",
    LU: () => "LUX",
    LV: () => "LAT",
    LY: () => "LBY",
    MA: () => "MOC",
    MC: () => "LUN",
    MD: () => "MDA",
    ME: () => "EMN",
    MF: () => "STM",
    MG: () => "ENOJADO",
    MH: () => "MHL",
    MK: () => "NMK",
    ML: () => "MLI",
    MM: () => "vacuna triple vírica",
    MN: () => "MGL",
    MO: () => "IMPERMEABLE",
    MP: () => "NMI",
    MQ: () => "Cuota máxima",
    MR: () => "metro",
    MS: () => "MSR",
    MT: () => "MLT",
    MU: () => "MUS",
    MV: () => "VMD",
    MW: () => "FAUCES",
    MX: () => "México",
    MY: () => "MÁS",
    MZ: () => "MOZ",
    NA: () => "NOMBRE DEL PAÍS",
    NC: () => "NCL",
    NE: () => "NER",
    NF: () => "No se puede",
    NG: () => "NIG",
    NI: () => "NIC",
    NL: () => "No se ha podido",
    NO: () => "NI",
    NP: () => "NPL",
    NR: () => "Unidad de Investigación Nuclear",
    NU: () => "Universidad Nacional Autónoma de México",
    NZ: () => "Nueva Zelanda",
    OM: () => "OMA",
    PA: () => "CACEROLA",
    PE: () => "POR",
    PG: () => "PNG",
    PH: () => "FI",
    PK: () => "Paquete",
    PL: () => "POLONIA",
    PM: () => "PTP-S",
    PN: () => "PCN",
    PR: () => "PRI",
    PS: () => "PSE",
    PT: () => "POR",
    PW: () => "El hombre más valiente",
    PY: () => "PAR",
    QA: () => "CAL",
    RE: () => "REU",
    RO: () => "memoria de sólo lectura",
    RS: () => "SER",
    RU: () => "ruso",
    RW: () => "RWA",
    SA: () => "SAU",
    SB: () => "SOL",
    SC: () => "SEÑORÍA",
    SD: () => "SDN",
    SE: () => "Sueco",
    SG: () => "PECADO",
    SH: () => "Algo",
    SI: () => "SVN",
    SJ: () => "SJM",
    SK: () => "eslovaco",
    SL: () => "LES",
    SM: () => "SMR",
    SN: () => "SEN",
    SO: () => "SOMA",
    SR: () => "Sur",
    SS: () => "Unidad de estado sólido",
    ST: () => "SAO",
    SV: () => "ELLOS",
    SX: () => "SMX",
    SY: () => "Siria",
    TC: () => "TCA",
    TG: () => "ATAVIAR",
    TH: () => "Eso",
    TJ: () => "TJK",
    TK: () => "TKL",
    TL: () => "TLS",
    TM: () => "TKM",
    TN: () => "TONEL",
    TO: () => "TONELADA",
    TR: () => "Turista",
    TT: () => "OTT",
    TV: () => "TÜV",
    TW: () => "TWN",
    TZ: () => "BRONCEARSE",
    UA: () => "Reino Unido",
    UG: () => "Universidad de Georgia",
    UK: () => "Reino Unido",
    US: () => "A NOSOTROS",
    UY: () => "uruguayo",
    UZ: () => "Uzbekistán",
    VA: () => "TINA",
    VC: () => "Vuelo sin escalas",
    VE: () => "Ven",
    VG: () => "VGB",
    VI: () => "VIR",
    VN: () => "RIVALIZAR",
    VU: () => "VUT",
    WF: () => "Fuerza Aérea de los Estados Unidos",
    WS: () => "SAM",
    YE: () => "Sí, sí",
    YT: () => "Mito",
    ZA: () => "Sociedad Anónima",
    ZM: () => "ZAM",
    ZW: () => "ZWE",
  },
  coupon: {
    label: () => "Introducir código promocional",
    placeholder: () => "Código promocional",
  },
  couponIntegration: {
    CB140: () => "Coinbase",
    COINBASE30: () => "Coinbase",
    COINJARUK: () => "CoinJar",
    COINSTATS: () => "CoinStats",
    CTCFRIEND20: () => "Recomienda a un amigo",
    METAMASK10: () => "Metamask",
    MMCTC30: () => "Máscara meta",
    bitcoincomau30: () => "bitcoin.com.au",
    cb1rookie: () => "Base de monedas",
    coinbase140: () => "Base de monedas",
    coinbase140p: () => "Base de monedas",
    coinbase1p: () => "Base de monedas",
    ir30: () => "Independent Reserve",
    metamask30: () => "Máscara meta",
  },
  createTransactionBefore: () => "Crear transacción antes",
  ctcAutomations: () => "✨ Magia CTC ✨",
  currency: {
    errorLoadingAllCurrencies: () => "Error al cargar todas las monedas",
    errorLoadingUserCurrencies: () => "Error al cargar las monedas del usuario",
    unableToFindPrice: (d) =>
      "No se puede encontrar el precio de mercado para " + d.name,
    wentWrongFetchingPrice: (d) =>
      "Algo salió mal al obtener el precio de " + d.name,
  },
  currentPlan: () => "Plan actual",
  darkModeBanner: {
    actionButton: () => "Prueba el modo oscuro",
    cancelButton: () => "No, gracias",
    description: () =>
      "Cambia entre el modo Oscuro y Claro desde el menú de tu perfil.",
    title: () => "¡Ha llegado el modo oscuro!",
  },
  dashboard: {
    allAssets: () => "Todos los activos",
    balance: () => "Balance",
    carousel: {
      moneyBack: {
        description: () => "Prueba sin riesgo",
        subtitle: () =>
          "Ofrecemos una garantía de devolución del dinero de 30 días en todos los planes.",
        title: () => "Garantía de devolución de dinero",
      },
      partnerOffer: {
        description: () => "30% de descuento",
        subtitle: () => "Aplicado automáticamente al finalizar la compra",
        title: () => "Oferta especial para socios",
      },
    },
    chartCalculationDialog: {
      body1: () =>
        "Para obtener un resultado preciso, importe todas sus transacciones ",
      note: () =>
        "Nota: Si no se puede determinar el precio de una moneda durante el tiempo en que se mantuvo, se excluirá.",
      title: () => "Explicación de los cálculos de gráficos",
    },
    costBasis: () => "Base de costos",
    costBasisTooltip: () =>
      "Base de costos combinada (incluidas las tarifas) de los activos que tienen un valor de mercado actual",
    currentBalance: () => "Saldo actual",
    embedded: {
      accuracyChecks: () => "Comprobaciones de precisión",
      accuracyComplete: () => "Todas tus tareas de precisión están completas.",
      accuracyTasks: () => "Tareas de precisión",
      accuracyTasksAction: () => "Iniciar revisión",
      accuracyTasksDescription: () =>
        "Hay tareas de precisión que completar que ayudan a garantizar que sus ganancias sean correctas y compatibles (se requiere plan).",
      countdownBanner: {
        title: () => "Tiempo restante para presentar sus impuestos al IRS",
        tooltip: () =>
          "La fecha límite para la presentación de impuestos es el martes 15 de abril de 2025",
      },
      estimatedGains: () => "Ganancias de capital estimadas",
      estimatedGainsTooltip: () =>
        "La ganancia de capital es una estimación basada en la información proporcionada.",
      fy: () => "Año fiscal",
      getTaxReports: () => "Obtener informes fiscales",
      missingAccounts: () => "Cuentas desaparecidas",
      missingAccountsAction: () => "Agregar cuentas",
      missingAccountsDescription: () =>
        "Algunas cuentas no están vinculadas, lo que provoca la pérdida de transacciones. Esto puede provocar que se sobreestimen sus ganancias.",
      remindMeLater: {
        title: () =>
          "Obtén un recordatorio para la temporada fiscal y consigue un 5% adicional de descuento",
        description: () =>
          "¿Prefieres hacer tus impuestos más tarde? Lo entendemos. Regístrate y añadiremos un 5% adicional de descuento cuando adquieras un plan.",
        buttonText: () => "Recuérdame",
        modals: {
          reminder: {
            chipText: () => "Establecer un recordatorio por correo electrónico",
            title: () =>
              "¡Obtenga un 5% adicional de descuento en todos los planes!",
            description: () =>
              "Proporcione su correo electrónico para obtener un total del 35% de descuento en su informe fiscal, solo por ser usuario de MetaMask.",
            emailLabel: () => "Introduzca su dirección de correo electrónico",
            emailError: () =>
              "Por favor, introduzca una dirección de correo electrónico válida",
            buttonText: () => "Recuérdeme",
            privacyNotice: () =>
              "Al continuar, acepta recibir correos electrónicos de marketing ocasionales.",
          },
          success: {
            chipText: () => "¡Recordatorio establecido!",
            title: () => "Descuento adicional del 5% aplicado.",
            description: () =>
              "Ha obtenido un 35% de descuento en su informe fiscal por ser usuario de MetaMask.",
            buttonText: () => "Obtenga su informe fiscal ahora",
          },
        },
      },
      savingsOpportunity: () => "Tu oportunidad de ahorro:",
      savingsOpportunityTooltip: () =>
        "La oportunidad de ahorro es la cantidad estimada en la que podría reducir sus ganancias de capital si conecta todas sus cuentas y revisa las tareas de cumplimiento.",
    },
    error: () =>
      "Se produjo un error al obtener los datos del panel. Inténtalo de nuevo más tarde.",
    feesPaid: () => "Tasas pagadas",
    feesPaidTooltip: () =>
      "El valor fiduciario total de las tarifas que ha pagado.",
    fiatInvested: () => "Fiat invirtió",
    fiatProceeds: () => "Ingresos fiduciarios",
    fiatProceedsTooltip: () =>
      "La cantidad de dinero fiduciario que has recibido como resultado de la venta de tus criptomonedas",
    heading: () => "Panel",
    holdings: {
      balanceSyncTimeExplainer: () =>
        "Los saldos de tiempo se sincronizaron por última vez para esta importación",
      balances: {
        subheading: () =>
          "Alterne entre los saldos calculados por Crypto Tax Calculator y el saldo real en el exchange. Cualquier diferencia puede deberse a transacciones no conciliadas o faltantes.",
        title: () => "Saldos restantes",
      },
      balancesSubheading: () =>
        "Este es un texto explicativo sobre cómo se calculan los saldos y cómo el usuario puede cambiar entre el saldo en la aplicación y el saldo de intercambio.",
      balancesTitle: () => "Saldos restantes",
      breakdownListNegative: () =>
        "Las tenencias actuales son negativas. Esto puede deberse a que no se han categorizado los datos o a que faltan datos.",
      breakdownListTitle: () => "Tenencias de criptomonedas",
      breakdownListTooltip: {
        marketPrice: (d) =>
          "Precio de mercado de " + d.symbol + " en " + d.currency,
        positiveBalance: (d) =>
          "El saldo coincide con las tenencias en " + d.exchangeName,
        review: {
          access: () =>
            "para acceder a nuestra herramienta de revisión y obtener más información.",
          subscribe: () => "Suscribir",
          tips: () => "Revisar",
          toLearnMore: () => "sus transacciones para obtener más información.",
        },
        warningBalanceLessThan: (d) =>
          "Hemos calculado " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " ( " +
          d.fiatDifference +
          " ) es menor que lo que informó su exchange. Esto puede deberse a que no se han categorizado los datos o a que faltan datos.",
        warningBalanceMoreThan: (d) =>
          "Hemos calculado " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " ( " +
          d.fiatDifference +
          " ) más de lo que informó su exchange. Esto puede deberse a que no se han categorizado los datos o a que faltan datos.",
        warningBalanceNoMarketPriceLessThan: (d) =>
          "Hemos calculado " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " es menor que lo que informó su exchange. Esto puede deberse a que no se han categorizado los datos o a que faltan datos.",
        warningBalanceNoMarketPriceMoreThan: (d) =>
          "Hemos calculado " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " más de lo que informó su exchange. Esto puede deberse a que no se han categorizado los datos o a que faltan datos.",
      },
      cost: () => "Costo",
      costTooltip: () => "Base de costo total",
      costTooltipTitle: () => "Base de Costo Total",
      currency: () => "Divisa",
      empty: {
        title: () => "No hay datos añadidos",
      },
      marketPrice: () => "Precio",
      marketPriceTooltip: () => "Precio actual del mercado",
      marketValue: () => "Valor",
      marketValueTooltip: () => "Valor actual del mercado",
      moreThan: () => "Más que",
      of: () => "de",
      quantity: () => "Cantidad",
      roi: () => "Retorno de la inversión",
      roiTooltip: () => "Retorno de la inversión",
      rowsPerPage: () => "Filas por página",
      showAppBalances: () => "Mostrar saldos en la aplicación",
      syncStatus: {
        fail: () => "Sincronización fallida",
        noSync: () => "Sin última sincronización",
        success: () => "Sincronizado",
      },
      title: () => "Activos",
      tooltip: {
        off: () => "Mostrando los saldos importados desde la API de origen",
        on: () => "Mostrando los saldos calculados desde la aplicación",
        sort: () => "Haga clic para ordenar por valor total de mercado",
      },
      unrealizedGain: () => "Ganancia no realizada",
      unrealizedGainTooltip: () => "Ganancia no realizada",
    },
    holdingsTypes: {
      calculatedBody: () =>
        "Muestra los saldos calculados por CTC, en función de las billeteras y los exchanges importados. La precisión depende de la conciliación correcta de las transacciones.",
      calculatedSwitch: () => "Calculado",
      calculatedTitle: () => "Balance histórico",
      done: () => "Hecho",
      reportedBody: () =>
        "Muestra una vista en vivo de los saldos informados por sus exchanges y billeteras. Los saldos que no estén en sus billeteras no se mostrarán. Tenga en cuenta que las importaciones CSV, así como algunos exchanges y billeteras, no admiten informes en vivo de saldos y, en estos casos, se utilizarán las tenencias calculadas por CTC.",
      reportedSwitch: () => "Reportado",
      reportedTitle: () => "Saldo en vivo",
      title: () => "Tipo de equilibrio",
    },
    howThisChartIsCalculated: () => "Cómo se calcula este gráfico",
    inflow: () => "Afluencia",
    inflowOutflowChart: {
      header: () => "Entrada/Salida",
      legend: {
        fees: () => "Honorarios",
        inflow: () => "Afluencia",
        net: () => "Flujo neto",
        outflow: () => "Salida",
      },
      linear: () => "Cambiar a lineal",
      log: () => "Cambiar a logarítmico",
      timeframeSelector: {
        month: () => "Mes",
        placeholder: () => "Seleccione el período de tiempo",
        quarterToDate: () => "Trimestre hasta la fecha",
        year: () => "Año",
        ytd: () => "El año hasta la fecha",
      },
      tooltip: {
        fees: () => "Honorarios:",
        inflow: () => "Afluencia:",
        net: () => "Flujo neto:",
        outflow: () => "Salida:",
      },
    },
    inflowTooltip: () =>
      "El valor de los activos recibidos en sus cuentas conectadas. Se excluyen las ganancias o pérdidas generadas por operaciones.",
    losers: () => "Perdedores",
    missingImportsWarning: {
      actionButton: () => "Administrar configuraciones",
      description: () =>
        'Sin todas las fuentes de datos no podemos reflejar con precisión su saldo total. Puede administrar qué datos desea ver en la configuración, en "Configuración de saldo".',
      importsTitle: () => "Activos importados",
      nextStepButton: () => "Importar datos",
      overallSubtitle: () =>
        "Se trata de activos que se encuentran en billeteras o exchanges y que aún no has importado.",
      overallTitle: () => "Activos detectados",
      title: () => "Administrar datos importados",
    },
    negativeBalanceWarning: (d) =>
      "El saldo de sus tenencias " +
      d.currencySymbol +
      " es negativo, revise sus transacciones para conciliar este error",
    netFiatInvested: () => "Inversión neta fiduciaria",
    netFiatInvestedTooltip: () =>
      "La cantidad de dinero fiduciario que ha utilizado para comprar criptomonedas",
    nfts: {
      filter: {
        allChains: () => "Todas las cadenas",
        chainPlaceholder: () => "Buscar por cadena",
      },
      imageNotFound: () => "Imagen no encontrada",
      noNFTs: () => "No se encontraron NFT",
      noNFTsCTA: (d) =>
        "Sus NFT aparecerán aquí. Para comenzar, [importe datos.](" +
        d.linkTextKey +
        ")",
    },
    noAssetAllocation: {
      body: () => "Tus activos más destacados aparecerán aquí. Para empezar",
      importData: () => "importar datos",
      title: () => "No hay datos añadidos",
    },
    noHoldings: {
      body: () =>
        "Aquí aparecerán todos los activos que haya importado. Para comenzar",
      importData: () => "importar datos",
      title: () => "No hay datos añadidos",
    },
    noWinnersLosers: {
      body: () =>
        "Aquí aparecerán sus principales ganancias y pérdidas. Para empezar",
      importData: () => "importar datos",
      title: () => "No hay datos añadidos",
    },
    noWinnersLosersToDisplay: (d) =>
      "No hay " + d.direction + " para mostrar...",
    notApplicable: () => "N / A",
    other: () => "Otro",
    outflow: () => "Salida",
    outflowTooltip: () =>
      "El valor de los activos enviados por sus cuentas conectadas. Se excluyen las ganancias o pérdidas generadas por las operaciones.",
    pageHeading: () => "Cartera",
    percentageTooltipTitle: () =>
      "Cambio relativo en el balance general vs. base de costos",
    portfolioChart: {
      error: () =>
        "Error al cargar el rendimiento de la cartera, inténtelo nuevamente más tarde",
      loading: () =>
        "Generando datos históricos, esto puede tomar algún tiempo...",
      noData: {
        body: () =>
          "El rendimiento de su cartera aparecerá aquí. Para comenzar",
        importData: () => "importar datos",
        title: () => "No hay datos añadidos",
      },
      totalValue: () => "Valor total",
    },
    portfolioPerformance: () => "Rendimiento de la cartera",
    portfolioPerformanceTooltip: () =>
      "El rendimiento de su cartera durante el último año. Se ignoran las tenencias negativas o los saldos de valor cero.",
    portfolioTimeframe: {
      all: () => "Todo",
      day: () => "1D",
      month: () => "1M",
      week: () => "1W",
      year: () => "1 año",
      ytd: () => "el último año",
    },
    portfolioTooltip: {
      accuracyNote: () =>
        "El rendimiento se basa en los saldos calculados en la Calculadora de impuestos sobre criptomonedas. La precisión depende de la conciliación correcta de las transacciones.",
      all: () => "El rendimiento de su cartera desde su primera compra.",
      day: () => "El rendimiento de su cartera durante el último día.",
      ignoreNegativeHoldings: () =>
        "Se ignoran las tenencias negativas o los saldos de valor cero.",
      month: () => "El rendimiento de su cartera durante el último mes.",
      week: () => "El rendimiento de su cartera durante la última semana.",
      year: () => "El rendimiento de su cartera durante el último año.",
    },
    somethingWentWrong: () => "Algo salió mal. Intente recargar la página.",
    step1: {
      content: () =>
        "Importa todo tu historial de transacciones seleccionando tu exchange y siguiendo las instrucciones asociadas. Puedes importar mediante CSV, API o conectar tu billetera directamente.",
      detailedGuide: () => "Guía detallada",
      link: () => "/imports",
      title: () => "1. Importar datos",
    },
    step2: {
      content: () =>
        "Asegúrese de haber importado sus transacciones de todos sus intercambios y de haberlas categorizado correctamente en la página de transacciones.",
      link: () => "/actas",
      title: () => "2. Revisar transacciones",
    },
    step3: {
      content: () =>
        "Vaya a la página de informes para ver y descargar los archivos de informes fiscales del año fiscal actual y de todos los años anteriores. Luego, puede compartir estos informes con su contador.",
      link: () => "/report",
      title: () => "3. Obtener informe",
    },
    steps: {
      getStarted: () => "Empezar",
      gettingStarted: () =>
        "Simplemente siga los pasos a continuación para comenzar:",
    },
    tabs: {
      nfts: () => "NFT",
      overview: () => "Descripción general",
    },
    title: () => "¡Estás cerca de resolver tus impuestos!",
    topAssets: () => "Asignación de tokens",
    totalReturn: () => "Rendimiento total",
    totalReturnTooltip: () =>
      "Su rendimiento total es el rendimiento histórico de su inversión en criptomonedas. Se calcula como la suma del valor de su cartera y los ingresos netos en moneda fiduciaria después de deducir la moneda fiduciaria neta invertida.",
    treasury: () => "Tesorería",
    treasuryTimeframe: {
      lastMonth: () => "Mes pasado",
      lastYear: () => "El año pasado",
      monthToDate: () => "Mes hasta la fecha",
      yearToDate: () => "El año hasta la fecha",
    },
    unrealizedPosition: () => "Ganancias no realizadas",
    unrealizedPositionTooltip: () =>
      "La diferencia entre el patrimonio neto de su cartera actual y el costo que tuvo para adquirir los activos que componen su cartera.",
    valueOfHoldings: () => "Valor de las tenencias",
    valueOfHoldingsTooltip: () =>
      "La diferencia entre su base de costos y el valor actual de todos sus activos",
    valueOfHoldingsTooltipTitle: () => "Ganancia no realizada:",
    winners: () => "Ganadores",
    winnersAndLosers: {
      help: () => "Mejore la precisión categorizando las transacciones",
      importData: () => "importar datos",
      noData: () =>
        "No hay ganadores ni perdedores identificados en sus cálculos, continúe importando sus transacciones para mejorar la precisión.",
      noDataBody: () =>
        "Aquí aparecerán sus activos con mejor y peor rendimiento. Para comenzar",
      noDataTitle: () => "No hay datos añadidos",
      title: () => "Ganadores y perdedores",
    },
  },
  dataSource: {
    radioButtonLabels: {
      accountant: () => "Datos del contable",
      client: (d) =>
        select(d.accountant, { true: " Cliente ", other: " Mi " }) +
        " datos importados",
    },
    subtitle: () =>
      "Seleccione una fuente de datos compartida entre usted y su contador",
    title: () => "Datos de trabajo",
  },
  delete: () => "Borrar",
  deny: () => "Denegar",
  developer: {
    activeFlags: () => "Banderas activas",
    addFlag: () => "Añadir bandera",
    clearFlagOverrides: () => "Borrar anulaciones",
    enableLocalCensoring: () => "Habilitar la censura local",
    noActiveFlags: () => "No hay banderas activas",
    tabs: {
      currencies: () => "Monedas",
      editorIntegration: () => "Saltar al código",
      entities: () => "Entidades",
      featureFlags: () => "Banderas de características",
      reconciliation: () => "Reconciliación",
      reportRefresh: () => "Actualizar informe",
      rules: () => "Reglas de integración de software de contabilidad",
      seedData: () => "Datos de la semilla",
      sessionRecording: () => "Grabaciones de sesiones",
      userData: () => "Datos del usuario",
    },
    title: () => "Revelador",
  },
  done: () => "Hecho",
  duplicate: () => "Duplicado",
  edit: () => "Editar",
  editTx: {
    accounts: () => "Cuentas",
    addFee: () => "Añadir tarifa",
    advanced: () => "Avanzado",
    baseCurrency: () => "Moneda base",
    blockchain: () => "Cadena de bloques",
    blockchainFrom: () => "Fuente Blockchain",
    blockchainPlaceholder: () => "por ejemplo ETH, Avalanche, etc.",
    blockchainTo: () => "Destino Blockchain",
    buyCurrency: () => "Comprar moneda",
    buyFee: () => "Tarifa de compra",
    buyFeeFor: () => "Tarifa de compra por transacción en",
    buyPrice: () => "Precio de compra",
    buyQuantity: () => "Comprar Cantidad",
    cantEditLocked: () => "No puedes editar una transacción bloqueada",
    cantEditLockedButton: () => "Ver configuración de transacciones de bloqueo",
    cantMixBlockchain: () =>
      "No se pueden mezclar hacia y desde cadenas de bloques",
    currency: () => "Divisa",
    currencyPaidIn: () => "Moneda en la que pagó la tarifa (si corresponde)",
    destinationOfTransaction: () => "Destino de la transacción",
    editFee: () => "Tarifa de edición",
    editPriceCurrencyValue: () => "Editar precio, moneda y valor",
    feeForIncoming: () => "Tarifa por transacción entrante en",
    feeForOutgoing: () => "Tarifa por transacción saliente en",
    feeQuantityPaidIn: () => "Cantidad de tarifa pagada en la moneda de tarifa",
    feeValue: () => "Valor de la tarifa",
    in: () => "en",
    inFee: () => "En cuota",
    inPrice: () => "En precio",
    inQuantity: () => "En cantidad",
    inTime: () => "A tiempo",
    linkValueTooltipTradeLike: () =>
      "Asegúrese de que los valores totales de ambos lados permanezcan iguales ajustando los valores de las transacciones.",
    linkValueTooltipTransferLike: () =>
      "Asegúrese de que la cantidad total de ambos lados permanezca igual ajustando las cantidades de transacción.",
    lookupPrice: () => "Consultar precio de mercado",
    mustBeLess: () => "Debe tener menos de 256 caracteres",
    mustBuy: () => "Debes introducir un precio de compra",
    mustCategory: () => "Debes ingresar una categoría",
    mustCurrency: () => "Debes ingresar una moneda",
    mustDestination: () => "Debes introducir un destino",
    mustFee: () => "Debes introducir un valor de tarifa",
    mustHaveBlockchain: () =>
      "Debes asignar una cadena de bloques a esta transacción",
    mustOutTime: () => "Debes introducir una hora de salida válida",
    mustPrice: () => "Debes introducir un precio",
    mustQuantity: () => "Debes introducir una cantidad",
    mustSell: () => "Debes introducir un precio de venta",
    mustSource: () => "Debes ingresar una fuente",
    mustTime: () => "Debes introducir una hora válida",
    mustValue: () => "Debes introducir un valor",
    noPriceFound: (d) =>
      "No se encontró precio para " +
      d.currency +
      " . Ingrese el precio manualmente o ",
    notes: () => "Notas",
    optionalNotes: () => "Notas opcionales para esta transacción",
    outFee: () => "Tarifa de salida",
    outPrice: () => "Precio de salida",
    outQuantity: () => "Cantidad fuera",
    outTime: () => "Tiempo fuera",
    paidInFees: () => "pagado en cuotas",
    price: () => "Precio",
    priceForOne: () => "Precio por 1",
    quantity: () => "Cantidad",
    removeFee: () => "Eliminar tarifa",
    sellCurrency: () => "Vender moneda",
    sellFee: () => "Tarifa de venta",
    sellFeeFor: () => "Tarifa de venta por transacción en",
    sellPrice: () => "Precio de venta",
    sellQuantity: () => "Vender Cantidad",
    somethingWentWrong: () => "Algo salió mal al actualizar la transacción.",
    sourceOfTransaction: () => "Origen de la transacción",
    swapIconIncoming: () => "Cambiar a transacción entrante",
    swapIconOutgoing: () => "Cambiar a transacción saliente",
    totalFeeValueIn: () => "Valor total de la tarifa en",
    totalQuantityOf: () => "Cantidad total de",
    totalValue: (d) => "Valor total: " + d.amount,
    treatAsNFT: (d) => "¿Tratar " + d.symbol + " como NFT?",
    treatAsNFTTooltip: () =>
      "Cambiar esto actualizará todas las transacciones con esta moneda.",
    ungroupCriteria: {
      differentAssetType: () =>
        "Las monedas no satisfacen los requisitos del tipo de activo",
      differentCurrency: () => "Las monedas son las mismas",
      differentSource: () =>
        "Las transacciones no provienen de fuentes separadas (no hay transferencias internas)",
      dontGroup: () => "La bandera de no agrupar está activada",
      fiatCurrency: () =>
        "Las transacciones deben ser transacciones en moneda fiduciaria.",
      sameAccount: () => "Las transacciones no tienen cuentas coincidentes",
      sameCurrency: () => "Las monedas son diferentes",
      sameFromTo: () =>
        "Las transacciones no tienen valores de origen y destino coincidentes",
      sameTimestamp: () => "Las marcas de tiempo son diferentes",
      sameTxId: () => "Los identificadores de tx son diferentes",
      sameValueFeeExclusive: () =>
        "Los valores de cada lado no son lo suficientemente similares",
      sameValueFeeInclusive: () =>
        "Los valores de cada lado no son lo suficientemente similares",
      similarQuantity: () =>
        "Las cantidades de cada lado no son lo suficientemente similares",
      similarTimestamp: () => " Las marcas de tiempo son demasiado diferentes",
    },
    ungroupWarning: (d) =>
      "Las modificaciones que ha realizado pueden provocar que estas transacciones se desagrupen por los siguientes " +
      plural(d.count, 0, es, { 1: " reason ", other: " reason " }) +
      " :",
    unlock: () => "Cambiar",
    unlockBlockchainBody: () =>
      "Seleccionar una nueva cadena de bloques puede significar que sus transacciones anteriores en esta cadena ya no se concilien con precisión",
    unlockBlockchainTitle: () =>
      "¿Estás seguro de que deseas cambiar la cadena de bloques?",
    validTime: () => "Debes introducir una hora válida",
    validatePositiveFeeValue: () => "La tarifa debe ser mayor que cero",
    validatePositiveFiatValue: () =>
      "El valor fiduciario debe ser mayor que cero",
    validatePositivePriceValue: () => "El precio debe ser mayor que cero",
    validatePositiveQuantityValue: () => "La cantidad debe ser mayor que cero",
    validatePositiveValue: () => "El valor debe ser mayor que cero",
    value: () => "Valor",
    valueLockLabel: () => "Vincular el valor de cada lado de la transacción",
    willSetBlockchain: () =>
      "La cadena de bloques en la que se encuentra la dirección.",
  },
  enforceUpgrade: {
    tooltip: () =>
      "Su recuento de transacciones ha excedido el límite para este plan",
  },
  enterprise: {
    getReport: () => "Obtener informe",
    incomingInvoicesDownloadModal: {
      billingPeriod: () => "Periodo de facturación",
      customDateRange: () => "Intervalo de fechas personalizado",
      downloadCsv: () => "Descargar CSV",
      downloadPdf: () => "Descargar PDF",
      endDate: () => "Fecha de finalización",
      startDate: () => "Fecha de inicio",
      startDateBeforeEndDateError: () =>
        "La fecha de inicio debe ser anterior a la fecha de finalización",
      title: () => "Descargar informe",
    },
    office: {
      addNew: {
        button: () => "Agregar nueva oficina",
        dialog: {
          accept: () => "Agregar oficina",
          title: () => "¿Añadir nueva oficina?",
        },
      },
      addNewButton: () => "Agregar nueva oficina",
      addNewOffice: () => "Agregar nueva oficina",
      all: () => "Todo",
      allOffices: () => "Todas las oficinas",
      offices: () => "Oficinas",
    },
    valueCards: {
      numberOfOffices: () => "Número de oficinas",
      totalAccountants: () => "Contadores totales",
      totalClients: () => "Total de clientes",
      upcomingInvoices: () => "Factura próxima",
    },
  },
  error: () => "Error",
  errorBoundaries: {
    debugInfo: () => "Información de depuración",
    error: () => "Error",
    oopsSomethingWentWrong: () => "¡Ups! Algo salió mal :(",
    weCantProcess: () =>
      "No podemos procesar tu solicitud. Intenta actualizar la página. Si el problema persiste, ponte en contacto con nuestro equipo de soporte.",
  },
  errorMessageGeneric: () => "Algo salió mal",
  errorReporting: {
    actionButtonText: {
      contactSupport: () => "Contactar con soporte técnico",
      retrySync: () => "Reintentar sincronización",
      updateAddress: () => "Actualizar dirección",
      updateApiKey: () => "Actualizar clave API",
      viewDetails: () => "Ver detalles",
    },
    csvErrorDetails: {
      description: () =>
        "Se omitieron las siguientes filas del CSV debido a problemas en el CSV.",
      title: (d) => d.exchangeName + " transacciones omitidas",
    },
  },
  etaTime: {
    momentHumanizeEquivalent: {
      "a few seconds": () => "unos segundos",
      day: () => "día",
      days: () => "días",
      hour: () => "hora",
      hours: () => "horas",
      minute: () => "minuto",
      minutes: () => "minutos",
      month: () => "mes",
      months: () => "meses",
      second: () => "segundo",
      seconds: () => "artículos de segunda clase",
      week: () => "semana",
      weeks: () => "semanas",
      year: () => "año",
    },
    tooltip: (d) =>
      "Las sincronizaciones suelen tardar " +
      d.avgHumanizeTime +
      " pero pueden tardar hasta " +
      d.p95HumanizeTime +
      " en completarse",
    tooltipSameTime: (d) =>
      "Las sincronizaciones suelen tardar " +
      d.avgHumanizeTime +
      " en completarse",
  },
  existing: {
    cancel: () => "Cancelar plan",
    goBack: () => "Volver",
    pricingUrl: () => "https://cryptotaxcalculator.io/pricing/personal/",
    title: () => "Plan actual",
    upgrade: () => "Mejora",
    upgradePlan: () => "Plan de actualización",
    viewPricingInfo: () => "Ver información de precios",
  },
  existingImports: {
    title: () => "Importaciones existentes",
  },
  faqAccountant: {
    description: () => "Preguntas frecuentes",
    questions: {
      0: {
        answer: () =>
          "Puede estar sujeto tanto al impuesto sobre las ganancias de capital como al impuesto sobre la renta según el tipo de transacción de criptomonedas y sus circunstancias particulares. Por ejemplo, es posible que deba pagar las ganancias de capital sobre las ganancias obtenidas por la compra y venta de criptomonedas, o el impuesto sobre la renta sobre los intereses obtenidos al mantener criptomonedas.",
        question: () => "¿Cómo se calcula el impuesto sobre las criptomonedas?",
      },
      1: {
        answer: () =>
          "La forma en que se gravan las criptomonedas en la mayoría de los países implica que los inversores podrían tener que pagar impuestos, independientemente de si obtuvieron ganancias o pérdidas generales. Dependiendo de sus circunstancias, los impuestos generalmente se realizan en el momento de la transacción y no sobre la posición general al final del año financiero.",
        question: () =>
          "Perdí dinero al operar con criptomonedas. ¿Debo pagar impuestos?",
      },
      2: {
        answer: () =>
          "En la mayoría de los países, se exige que registres el valor de la criptomoneda en tu moneda local en el momento de la transacción. Hacerlo a mano puede llevar mucho tiempo, ya que la mayoría de los registros de las casas de cambio no tienen un precio de referencia y los registros entre casas de cambio no son fácilmente compatibles.",
        question: () =>
          "¿Cómo calculo el impuesto sobre las transacciones de cripto a cripto?",
      },
      3: {
        answer: () =>
          "Solo tiene que importar su historial de transacciones y nosotros le ayudaremos a categorizarlas y calcular las ganancias y los ingresos obtenidos. Luego, podrá generar los informes adecuados para enviarlos a su contador y mantener registros detallados a mano para fines de auditoría.",
        question: () =>
          "¿Cómo puede la Calculadora de impuestos de criptomonedas ayudar con los impuestos de criptomonedas?",
      },
      4: {
        answer: () =>
          "Siempre recomendamos que trabajes con tu contador para revisar tus registros. Sin embargo, preparar manualmente tus libros puede llevar mucho tiempo y la mayoría de los contadores solo usarán software como Crypto Tax Calculator para hacerlo, cobrando una prima considerable.",
        question: () =>
          "¿No puedo simplemente pedirle a mi contador que haga esto por mí?",
      },
      5: {
        answer: () =>
          "Nos encargamos de todas las actividades que no sean de intercambio, como transacciones en cadena como airdrops, staking, minería, ICO y otras actividades de DeFi. No importa qué actividad hayas realizado en criptomonedas, te tenemos cubierto con nuestra función de categorización fácil de usar, similar a Expensify.",
        question: () => "¿Maneja usted actividades no cambiarias?",
      },
    },
    title: () => "Preguntas frecuentes del contable",
  },
  faqPricing: {
    description: () => "Preguntas frecuentes",
    questions: {
      0: {
        answer: () =>
          "Puede estar sujeto tanto al impuesto sobre las ganancias de capital como al impuesto sobre la renta según el tipo de transacción de criptomonedas y sus circunstancias particulares. Por ejemplo, es posible que deba pagar las ganancias de capital sobre las ganancias obtenidas por la compra y venta de criptomonedas, o el impuesto sobre la renta sobre los intereses obtenidos al mantener criptomonedas.",
        question: () => "¿Cómo se calcula el impuesto sobre las criptomonedas?",
      },
      1: {
        answer: () =>
          "La forma en que se gravan las criptomonedas en la mayoría de los países implica que los inversores podrían tener que pagar impuestos, independientemente de si obtuvieron ganancias o pérdidas generales. Dependiendo de sus circunstancias, los impuestos generalmente se realizan en el momento de la transacción y no sobre la posición general al final del año financiero.",
        question: () =>
          "Perdí dinero al operar con criptomonedas. ¿Debo pagar impuestos?",
      },
      2: {
        answer: () =>
          "En la mayoría de los países, se exige que registres el valor de la criptomoneda en tu moneda local en el momento de la transacción. Hacerlo a mano puede llevar mucho tiempo, ya que la mayoría de los registros de las casas de cambio no tienen un precio de referencia y los registros entre casas de cambio no son fácilmente compatibles.",
        question: () =>
          "¿Cómo calculo el impuesto sobre las transacciones de cripto a cripto?",
      },
      3: {
        answer: () =>
          "Solo tiene que importar su historial de transacciones y nosotros le ayudaremos a categorizarlas y calcular las ganancias y los ingresos obtenidos. Luego, podrá generar los informes adecuados para enviarlos a su contador y mantener registros detallados a mano para fines de auditoría.",
        question: () =>
          "¿Cómo puede la Calculadora de impuestos de criptomonedas ayudar con los impuestos de criptomonedas?",
      },
      4: {
        answer: () =>
          "Siempre recomendamos que trabajes con tu contador para revisar tus registros. Sin embargo, preparar manualmente tus libros puede llevar mucho tiempo y la mayoría de los contadores solo usarán software como Crypto Tax Calculator para hacerlo, cobrando una prima considerable.",
        question: () =>
          "¿No puedo simplemente pedirle a mi contador que haga esto por mí?",
      },
      5: {
        answer: () =>
          "Nos encargamos de todas las actividades que no sean de intercambio, como transacciones en cadena como airdrops, staking, minería, ICO y otras actividades de DeFi. No importa qué actividad hayas realizado en criptomonedas, te tenemos cubierto con nuestra función de categorización fácil de usar, similar a Expensify.",
        question: () => "¿Maneja usted actividades no cambiarias?",
      },
    },
    title: () => "Preguntas frecuentes",
  },
  faqs: {
    capitalGainsWrong: {
      ans: {
        0: () =>
          "Hay dos razones principales por las que esto sucede. O bien has clasificado algunas transacciones entre billeteras como compra/venta cuando solo estabas moviendo criptomonedas (depósito/retiro). De lo contrario, podrías haber olvidado agregar algunas transacciones o identificaciones de billetera que podrían compensar algunas de tus ganancias de capital.",
      },
      q: () => "¿Qué pasa si mis ganancias de capital parecen incorrectas?",
    },
    ensureAccuracy: {
      ans: {
        0: () =>
          "Hay dos pasos para garantizar que su informe fiscal sea lo más preciso posible:",
        1: () => "Importa todos tus datos",
        2: () =>
          "Esto incluye transacciones de fiat a criptomoneda, de criptomoneda a criptomoneda, de criptomoneda a fiat, transacciones DeFi, staking, airdrops, transacciones de este año, pero también transacciones de cuando compraste criptomoneda por primera vez. Todo debe estar incluido para brindar un informe preciso.",
        3: () => "Verificar/clasificar cada transacción",
        4: () =>
          "Puede resultar difícil recordar qué fue cada transacción, pero esto también es imperativo. Si clasificas una transacción como depósito/retiro, eso no afectará tus impuestos, pero una compra/venta sí. Si clasificas una transacción como airdrop, staking, interés, etc., se clasificará como ingreso, no como ganancia de capital. El punto es que la forma en que etiquetas cada transacción es importante, por lo que debes ser preciso en la forma en que las etiquetas.",
        5: () => "Corrija sus advertencias de saldo restante",
        6: () =>
          "Si dispone de criptomonedas, pero no tiene saldo suficiente, aparecerá un mensaje de advertencia de saldo restante en la aplicación. Debe verificar sus notificaciones para asegurarse de que no tenga ninguna advertencia de saldo restante. Si tiene estas advertencias, puede resolverlas siguiendo los pasos de la guía aquí: https://cryptotaxcalculator.io/guides/negative-balance-remaining-warnings ",
      },
      q: () => "¿Cómo puedo asegurarme de que este informe sea preciso?",
    },
    howToUseCTC: {
      ans: {
        0: () =>
          "Lo primero que debe hacer es navegar a la página de importación y cargar TODOS los datos de transacciones de las cuentas de exchange y las transacciones de billetera. Cada cuenta de exchange o billetera tendrá instrucciones de importación que implican cargar datos CSV, configurar una API o ingresar los detalles de su billetera.",
        1: () =>
          "Una vez que se haya cargado todo, revise su historial de transacciones. Esto implicará clasificar cada transacción por tipo. Esté atento a los saldos que no coinciden; por ejemplo, CTC resaltará si su cuenta entra en un historial de compras faltante, lo que significa que le faltan algunas transacciones.",
        2: () =>
          "Por último, vaya a la página del informe fiscal y haga una comprobación rápida para asegurarse de que los números y el desglose de la cartera parezcan razonables. Luego, podrá descargar su informe fiscal de manera segura.",
        3: () =>
          "Para obtener información más detallada, lea nuestra guía aquí: https://cryptotaxcalculator.io/guides/how-to-use-crypto-tax-calculator",
      },
      q: () => "¿Cómo uso la calculadora de impuestos sobre criptomonedas?",
    },
    missingIncome: {
      ans: {
        0: () =>
          "Sí, las ganancias de capital a largo plazo y las ganancias de capital a corto plazo se informan por separado.",
        1: () =>
          "Las ganancias sobre activos mantenidos durante un año o menos se clasifican como ganancias a corto plazo, mientras que las ganancias sobre activos mantenidos durante más de un año se clasifican como ganancias a largo plazo.",
        2: () =>
          "Dependiendo de su jurisdicción, usted puede ser elegible para un descuento fiscal sobre cualquier ganancia a largo plazo.",
      },
      q: () => "¿Se reportan por separado las ganancias a largo y corto plazo?",
    },
  },
  for: () => "para",
  forceSyncErp: (d) => "Forzar resincronización a " + d.erp,
  gainsToggles: () => "Opciones de categorías de ganancias de capital",
  generic: {
    isAccountant: () => "¿Es usted contable? Haga clic aquí",
    notAccountant: () => "Volver a precios individuales",
    select: () => "Seleccionar",
    yourPlan: () => "Tu plan",
  },
  groupingWizard: {
    actionButton: () => "Cerca",
    backPage: () => "Atrás",
    matchCountPage: {
      description: () =>
        "Para que las transacciones se agrupen, es necesario tener la cantidad correcta de transacciones de entrada y salida para formar el grupo deseado.",
      ratios: {
        NToOne: (d) =>
          "Los grupos " +
          d.type +
          " requieren al menos una transacción de entrada y una transacción de salida para formar un grupo.",
        nToM: (d) =>
          "Los grupos " +
          d.type +
          " requieren al menos una transacción de entrada y al menos una transacción de salida para formar un grupo, y la cantidad de entradas debe coincidir con la cantidad de salidas.",
        nToN: (d) =>
          "Los grupos " +
          d.type +
          " requieren al menos una transacción de entrada y al menos una transacción de salida para formar un grupo.",
        oneToN: (d) =>
          "Los grupos " +
          d.type +
          " requieren una transacción de entrada y al menos una transacción de salida para formar un grupo.",
        oneToOne: (d) =>
          "Los grupos " +
          d.type +
          " requieren una transacción de entrada y una transacción de salida para formar un grupo.",
      },
      selected: (d) =>
        "Ha seleccionado " +
        plural(d.inCount, 0, es, {
          one: " 1 en transacción ",
          other: number("es", d.inCount, 0) + " en transacciones ",
        }) +
        " y " +
        plural(d.outCount, 0, es, {
          one: " 1 fuera de transacción ",
          other: number("es", d.outCount, 0) + " fuera de transacciones ",
        }) +
        " .",
      title: (d) =>
        "¿Ha seleccionado el número correcto de transacciones para crear un grupo " +
        d.type +
        " ?",
    },
    matchCriteriaPage: {
      description: () =>
        "Para que las transacciones se agrupen, los datos deben satisfacer un conjunto de criterios para crear correctamente un grupo.",
      differentAssetType: {
        cta: () =>
          "Las monedas en un derivado deben ser diferentes tipos de activos y debe tener exactamente un token de posición.",
        title: () => "Monedas:",
      },
      differentCurrency: {
        cta: () =>
          "Las monedas en una transacción deben ser diferentes, no se puede vender una moneda para comprar la misma moneda.",
        title: () => "Monedas:",
      },
      differentSources: {
        cta: () =>
          "Para realizar un intercambio, tanto el lado de compra como el de venta deben ser de la misma importación; no se puede realizar un intercambio entre diferentes importaciones.",
        title: () => "Fuentes de importación:",
      },
      dontGroup: {
        cta: () =>
          "Una o más de las transacciones que ha seleccionado tienen activada la bandera de no agrupar. Esta bandera se activa cuando utiliza la función de desagrupar para evitar que las transacciones se agrupen. Si desea que estas transacciones se agrupen, desactive la bandera de no agrupar",
        enableGrouping: () => "Habilitar agrupación",
        title: () => "Desagrupar bandera:",
      },
      fiatCurrency: {
        cta: () =>
          "Las transacciones deben ser transacciones en moneda fiduciaria.",
        title: () => "Moneda fiduciaria:",
      },
      noIssues: {
        cta: () =>
          "No se han encontrado problemas de agrupación para las transacciones seleccionadas. Intente realizar un recálculo manual para agrupar estas transacciones. Si las transacciones siguen sin agruparse después de un recálculo manual, comuníquese con el servicio de asistencia.",
        title: () => "No se encontraron problemas de agrupación",
      },
      quantity: {
        cta: () =>
          "La cantidad que se está transando en las transacciones de entrada no coincide con las cantidades que se están transando en las transacciones de salida. Para agrupar estas transacciones, seleccione la cantidad lateral que desea utilizar para el grupo",
        title: () => "Cantidad de moneda:",
      },
      sameAccount: {
        cta: () =>
          "Las cuentas de los usuarios en las transacciones deben coincidir. Para agrupar estas transacciones, asegúrese de que todas sean de la misma cuenta.",
        title: () => "Cuentas:",
      },
      sameCurrency: {
        cta: () =>
          "La moneda que se envía no coincide con la moneda que se recibe. Para agrupar estas transacciones, edite las transacciones con la moneda que desea utilizar para el grupo.",
        title: () => "Monedas:",
      },
      sameFromTo: {
        cta: () =>
          "La dirección de origen y la dirección de destino deben coincidir. Para agrupar estas transacciones, edite las transacciones en el par de origen/destino que desea utilizar para el grupo.",
        title: () => "Desde y hasta:",
      },
      timestamp: {
        cta: () =>
          "Las transacciones seleccionadas no se encuentran dentro de la ventana de marca de tiempo requerida para ser agrupadas. Para agrupar estas transacciones, edite las transacciones con la marca de tiempo que desea utilizar para el grupo.",
        title: () => "Marcas de tiempo:",
      },
      title: (d) =>
        "¿Ha seleccionado transacciones que satisfacen todos los criterios de un grupo " +
        d.type +
        " ?",
      txId: {
        cta: () =>
          "Las transacciones seleccionadas no tienen el mismo ID de transacción. No es posible editar el ID de transacción en este momento",
        title: () => "ID de transacción:",
      },
      value: {
        cta: () =>
          "Los valores de entrada de esta transacción no coinciden con los valores de salida. Para agrupar estas transacciones, edite las transacciones en el valor del lado que desea utilizar para el grupo.",
        title: () => "Valor de la transacción:",
      },
    },
    matchTypePage: {
      allowed: () => "Tipos de grupos permitidos:",
      description: () =>
        "Para que las transacciones se agrupen, sus categorías deben coincidir con los subtipos de una de nuestras categorías agrupadas.",
      selected: () => "Categorías que seleccionaste:",
      title: () =>
        "¿Los tipos de transacciones seleccionados encajan en algún grupo?",
    },
    modalTitle: () => "Asistente de agrupación",
    nextPage: () => "Próximo",
  },
  help: () => "Ayuda",
  holdingsDropdown: {
    assets: () => "Activos",
    balances: () => "Saldos",
    calcBal: () => "Saldo calculado",
    calculatedTooltip: () => "El saldo calculado por Crypto Tax Calculator",
    currentPrice: () => "Precio actual",
    holdings: () => "Valores en cartera",
    noData: () => "N / A",
    noHoldings: () => "Sin tenencias",
    price: () => "Precio",
    quantity: () => "Cantidad",
    quantityTooltip: () =>
      "El monto informado por el intercambio o la billetera, o si no se informa ningún monto, el monto calculado por Crypto Tax Calculator",
    repBal: () => "Saldo reportado",
    reportedTooltip: () => "El saldo informado por el exchange o wallet",
    value: () => "Valor",
    valueTooltip: () =>
      "El valor informado por el intercambio o la billetera, o si no se informa ningún monto, el valor calculado por Crypto Tax Calculator",
    wallets: () => "Carteras",
  },
  ignore: () => "Ignorar",
  ignoreIssue: () => "Ignorar problema",
  importStepType: {
    critical: () => "crítico",
    note: () => "nota",
    step: () => "paso",
    video: () => "video",
  },
  imports: {
    accountTableTypes: {
      blockchain: () => "Cadena de bloques",
      exchange: () => "Intercambio",
    },
    accounts: () => "Cuentas",
    accountsTableHeadings: {
      account: () => "Cuenta",
      assets: () => "Activos",
      balance: () => "Balance",
      synced: () => "Sincronizado",
      tx: () => "Telecomunicaciones",
      type: () => "Tipo",
    },
    addIntegrationTitle: () => "Agregar cuenta",
    addIntegrations: () => "Agregar cuenta",
    addSource: (d) => "Agregar fuente " + d.source + " adicional",
    addYourFirst: () => "Añade tu primera cuenta para comenzar",
    additionalNotes: () => "Notas adicionales",
    advancedOptions: () => "Opciones avanzadas",
    api: () => "api",
    apiDisabled: (d) =>
      select(d.isNew, {
        true: " La opción de sincronización automática a través de API no está disponible ",
        other:
          " La opción de sincronización automática a través de API no está disponible porque ya se importaron datos a través de la carga de archivos ",
      }),
    apiStillSyncing: () =>
      "La API aún se está sincronizando, espere unos minutos.",
    apiWarning: () =>
      "¡Nunca agregue claves API con permisos privados que habiliten el comercio!",
    backToExistingImports: () => "Regreso a las importaciones existentes",
    backToImport: () => "Volver a la búsqueda de importaciones",
    backToImportTypeSelector: () =>
      "Volver a la selección del tipo de importación",
    backToIntegrations: () => "Volver a cuentas",
    backToIntegrationsSearch: () => "Volver a la búsqueda de cuentas",
    beta: () => "Beta",
    betaTooltipDefault: () =>
      "Esta integración está en versión beta y puede tener algunos problemas. Informe cualquier problema a nuestro equipo de soporte.",
    breakdownTooltip: () => "Haga clic para ordenar",
    browse: () => "Subir",
    bulkSyncTooltip: {
      api: (d) =>
        "Vuelva a sincronizar sus API de " +
        d.name +
        " a partir del " +
        d.dateTime,
      deprecatedTooltip: (d) =>
        "La API " + d.name + " ya no está operativa, impórtela mediante CSV",
      failed: () =>
        "Error de sincronización, haga clic para volver a sincronizar",
      lastSyncTime: () => "última hora de sincronización",
      lockedPeriod: (d) =>
        "Resincronización desde el final del último período bloqueado: " +
        d.dateTime,
      noSync: () => "Nada que resincronizar",
      pending: () => "Sincronización",
      wallet: (d) =>
        "Vuelva a sincronizar sus billeteras " +
        d.name +
        " desde " +
        d.dateTime,
    },
    chainDisabled: (d) => d.name + " deshabilitado",
    chips: {
      api: () => "API",
      csv: () => "CSV",
      service: () => "Servicio",
      wallet: (d) =>
        plural(d.walletCount, 0, es, {
          0: " Monedero ",
          one: " 1 Monedero ",
          other: number("es", d.walletCount, 0) + " Monederos ",
        }),
    },
    clickHere: () => "Haga clic aquí",
    connectWallet: () => "Conectar billetera",
    connectWalletErrors: {
      duplicateWallet: (d) =>
        "Esta billetera " + d.address + " ya ha sido conectada",
      duplicateWallets: (d) =>
        "Estas billeteras " + d.addresses + " ya han sido conectadas",
      maxWallets: (d) =>
        "Solo puedes conectar " + d.max + " billeteras a la vez",
      walletNotInstalled: (d) =>
        "La billetera " +
        d.wallet +
        " no está instalada. Instale " +
        d.wallet +
        " desde su sitio web oficial o como una extensión para continuar conectando su billetera",
    },
    connected: () => "Conectado",
    connectionSuccess: () => "¡Conexión exitosa!",
    continue: () => "Continuar",
    cryptoHoldings: () => "Tenencias de criptomonedas",
    csv: () => "archivo csv",
    csvAlerts: {
      fileSizeTooBig: () =>
        "Se ha superado el tamaño máximo de 20 MB. Elimine los archivos o proporcione archivos más pequeños.",
      fileTypeNotSupported: () => "Solo admitimos archivos CSV, XLS o XLSX.",
      invalidFileName: (d) =>
        "Los nombres de archivo " +
        d.fileNames +
        " contienen símbolos no válidos.",
      missingAddress: () =>
        "Debes ingresar la dirección asociada a este archivo CSV",
      missingCurrencySymbols: () =>
        "Debes ingresar la moneda ya que esta varía entre archivos CSV",
      noFiles: () => "¡No hay archivo seleccionado!",
      onlyOneFile: () => "Por favor, cargue sólo un archivo.",
    },
    csvCaps: () => "CSV",
    csvDisabled: (d) =>
      select(d.isNew, {
        true: " La opción de carga de archivos no está disponible ",
        other:
          " La opción de carga de archivos no está disponible porque ya se importaron datos a través de sincronización automática ",
      }),
    csvExtraInfo: {
      address: () => "DIRECCIÓN",
      addressHelper: () =>
        "Ingresa la dirección de billetera asociada con este CSV",
      addressPlaceholder: () => "Introduzca la dirección de la billetera",
      currencyHelper: () => "Por favor, introduzca la moneda en este CSV",
      currencySymbol: () => "Símbolo de moneda",
      fiatCurrency: () =>
        "Confirme la moneda fiduciaria de este CSV, por ejemplo, NZD o AUD",
      tickerPlaceHolder: () => "Introduzca el valor del ticker, p. ej. BTC",
    },
    csvWarning: () =>
      "Recomendamos utilizar importaciones CSV siempre que sea posible",
    customCSV: () => "Importar CSV personalizado",
    customCsvButton: () =>
      "¿No encuentras tu exchange? Haz clic aquí para agregar un CSV personalizado",
    customCsvLabel: () => "Nombre de intercambio",
    customCsvPlaceholder: () => "por ejemplo, Binance, Coinbase",
    deleteApiKeyConfirmTitle: () => "Eliminar clave API",
    deleteImportExchangeConfirmation: {
      body: (d) =>
        "¿Está seguro de que desea eliminar todos los datos de " +
        d.name +
        " ? Esto eliminará todas las transacciones asociadas con esta importación. Esta acción no se puede deshacer.",
      title: () => "Eliminar importación",
    },
    deleteImportedFileConfirmation: {
      body: () =>
        "¿Está seguro de que desea eliminar el archivo? Esto también eliminará TODAS las transacciones importadas por este archivo. Esta acción no se puede deshacer.",
      title: () => "Eliminar archivo",
    },
    deleteOauthKeyConfirmText: (d) =>
      "¿Está seguro de que desea desconectar su cuenta " +
      d.exchangeName +
      " ? Esto eliminará TODAS las transacciones importadas desde su cuenta " +
      d.exchangeName +
      " conectada. Esta acción no se puede deshacer.",
    deleteOauthKeyConfirmTitle: (d) => "Desconectar " + d.exchangeName,
    deleteWalletConfirmTitle: () => "Eliminar billetera",
    deprecated: () => "Obsoleto",
    deprecatedTooltipDefault: () =>
      "Esta integración ha quedado obsoleta y ya no se mantiene de forma activa.",
    deprecation: {
      description: () =>
        "Esta fuente de importación se ha retirado y ya no se mantiene. Importe datos utilizando nuestro formato CSV manual. https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
      title: () => "Fuente jubilada",
    },
    dialogOptions: {
      addApi: () => "Agregar API",
      addCsv: () => "Agregar CSV",
      addWallet: () => "Agregar dirección de billetera",
      copyAddress: () => "Copiar dirección",
      delete: () => "Borrar",
      deleteAll: () => "Borrar todo",
      downloadCsv: () => "Descargar CSV",
      editApi: () => "Editar API",
      editNickname: () => "Editar apodo",
      hardSync: () => "Sincronización dura",
      ignoreChain: () => "Ignorar cadena",
      sync: () => "Sincronizar",
      viewExplorer: () => "Ver en el explorador de bloques",
      viewLedger: () => "Libro mayor de divisas",
      viewTransactions: () => "Ver transacciones",
    },
    done: () => "Hecho",
    downloadCsvDisplayMessage: {
      info: () => "Preparando descarga del CSV...",
      success: () => "¡Descarga del CSV lista!",
    },
    drawer: {
      accountMetricsHeading: {
        balance: () => "Balance",
        feesPaid: () => "Tarifas pagadas",
        tradeVolume: () => "Volumen comercial",
        transactions: () => "Actas",
      },
      addApiKey: () => "Agregar clave API",
      addCsv: () => "Agregar CSV",
      addWallet: () => "Agregar dirección de billetera",
      currencyTableHeadings: {
        amount: () => "Cantidad",
        currencies: () => "Monedas",
        price: () => "Precio",
        value: () => "Valor",
      },
      deleteApiKey: {
        body: (d) =>
          "¿Está seguro de que desea eliminar esta clave API " +
          d.key +
          " ? Esto eliminará TODAS las transacciones importadas por esta clave. Esta acción no se puede deshacer.",
        title: () => "Eliminar clave API",
      },
      deleteBlockchain: {
        body: (d) =>
          "¿Está seguro de que desea eliminar " +
          d.address +
          " en " +
          d.blockchain +
          " ? Esto eliminará TODAS las transacciones importadas desde esta dirección en la blockchain. Esta acción no se puede deshacer.",
        title: () => "Eliminar la cadena de bloques",
      },
      deleteFile: {
        body: (d) =>
          "¿Está seguro de que desea eliminar el archivo " +
          d.name +
          " ? Esto eliminará TODAS las transacciones importadas por este archivo. Esta acción no se puede deshacer.",
        title: () => "Eliminar archivo",
      },
      deleteOauth: {
        body: (d) =>
          "¿Está seguro de que desea desconectar su cuenta " +
          d.exchangeName +
          " ? Esto eliminará TODAS las transacciones importadas desde su cuenta " +
          d.exchangeName +
          " conectada. Esta acción no se puede deshacer.",
        title: (d) => "Desconectar " + d.exchangeName,
      },
      nftTableHeadings: {
        collections: () => "Colecciones",
        combinedCost: () => "Costo combinado",
        ntfs: () => "NFT",
        totalSpend: () => "Gasto total",
      },
      overview: () => "Descripción general",
      showLess: () => "Mostrar menos monedas",
      showLessNft: () => "Mostrar menos NFT",
      showMore: () => "Mostrar más monedas",
      showMoreNft: () => "Mostrar más NFT",
      statusBanner: {
        accountHasNoTransactions: () => "La cuenta no tiene transacciones",
        and: () => "y",
        chainSyncFailed: (d) => "Error en la sincronización " + d.chains,
        generalImportsFailed: (d) =>
          select(d.all, { true: " All ", other: " Some " }) +
          " Las importaciones " +
          d.integration +
          " fallaron",
        generalSyncsFailed: (d) =>
          select(d.all, { true: " All ", other: " Some " }) +
          " Las importaciones " +
          d.integration +
          " no se pudieron sincronizar",
        selectedImportFailed: (d) =>
          d.name +
          " la importación falló " +
          select(d.reason, { undefined: "", other: " : " + d.reason }) +
          select(d.code, { undefined: "", other: " - código: " + d.code }),
        selectedImportHasNoTransactions: (d) =>
          "La importación " + d.name + " no tiene transacciones",
        selectedSyncFailed: (d) =>
          "Error de sincronización " +
          d.name +
          " " +
          select(d.reason, { undefined: "", other: " : " + d.reason }) +
          select(d.code, { undefined: "", other: " - code: " + d.code }),
      },
      viewCollection: () => "Ver colección",
      viewLedger: () => "Libro mayor de divisas",
      viewOnTxTable: () => "Ver en la tabla de transacciones",
      viewTxs: () => "Ver transacciones",
    },
    dropFiles: () => "Suelte sus archivos aquí",
    editSource: (d) => "Editar " + d.source + " fuente",
    emptyHoldings: () => "Sin tenencias de criptomonedas...",
    enable: () => "Permitir",
    enableImportChainTransactions: () =>
      "Habilitar la importación de transacciones de esta cadena",
    error: (d) =>
      "Error al importar " +
      select(d.blockchain, { true: "address " + d.name, other: d.name }) +
      select(d.showError, { true: ": " + d.error, other: "" }) +
      select(d.showCode, { true: " - code: " + d.code, other: "" }),
    exchangeGridTitle: {
      all: () => "Todos los intercambios y billeteras",
      allBlockchains: () => "Todas las cadenas de bloques y billeteras",
      popular: () => "Más populares",
      results: () => "Resultados",
    },
    existingOptions: () => "Carteras y casas de cambio importadas",
    findAccount: () => "Encontrar cuenta",
    firstTx: () => "Primera transacción importada:",
    goBack: () => "Volver",
    hardSync: () => "Sincronización dura",
    import: () => "Importar",
    importBannerMessages: {
      connectingToIntegration: (d) => "Conectando a " + d.name,
      success: () => "Importación exitosa",
    },
    importByManualCSV: (d) =>
      'Importar CSV con plantilla CTC para " ' + d.exchange + ' "',
    importCSV: (d) => "Importar CSV " + d.source,
    importDataTitle: () => "Agregar cuenta",
    importExchange: (d) => "Importar " + d.exchangeName,
    importFailed: () => "Importación fallida",
    importFileDuplicateWarning: () =>
      "Todas las transacciones en este archivo fueron identificadas como duplicadas y no fueron importadas.",
    importFrom: {
      chip: {
        notRecommended: () => "No recomendado",
        recommended: () => "Recomendado",
      },
      modal: {
        actionText: () => "Continuar con la importación",
        text: () =>
          "El uso de la función “Importar desde la fecha” impide la importación de transacciones anteriores a la fecha seleccionada. Esto puede provocar errores en el historial de compras que se pueden corregir mediante una transacción “manual” para proporcionar el saldo inicial de la importación antes de la fecha de importación.",
        title: () =>
          "La importación desde una fecha determinada puede provocar la falta de datos",
      },
      selector: {
        beginning: () => "Comienzo",
        custom: () => "Fecha personalizada",
        title: () => "Iniciar importación desde:",
      },
    },
    importInstructionsAccordionTitle: () => "Instrucciones paso a paso",
    importTypeModal: {
      buttonLabel: {
        csv: {
          active: () => "Subir CSV",
          default: () => "Subir",
        },
      },
      description: () => "¿Cómo le gustaría importar sus datos?",
      importMethod: {
        api: {
          description: () =>
            "Acceso de solo lectura. La conexión es confidencial y está cifrada de forma segura.",
          title: () => "Conectarse a través de API",
        },
        "bulk-wallet": {
          description: () =>
            "Importa transacciones pegando tus direcciones públicas desde tu aplicación o extensión Coinbase Wallet",
          title: () => "Importar direcciones públicas",
        },
        "connect-wallet": {
          description: () =>
            "Importa transacciones de forma segura desde la cadena de bloques",
          title: () => "Conectar billetera",
        },
        csv: {
          description: () => "Subir un archivo de historial de transacciones ",
          title: () => "Importar mediante archivo CSV",
        },
        oauth: {
          description: () =>
            "Importar transacciones automáticamente a través de OAuth",
          title: () => "Sincronización automática",
        },
        wallet: {
          description: () =>
            "Importa automáticamente transacciones desde la cadena de bloques pegando la dirección de tu billetera",
          title: () => "Sincronización de direcciones",
        },
      },
      instructionTitles: {
        api: () => "Instrucciones de sincronización de API",
        "bulk-wallet": () =>
          "Instrucciones para la sincronización masiva de billeteras",
        "connect-wallet": () => "Instrucciones para conectar la billetera",
        csv: () => "Instrucciones para subir archivos",
        oauth: (d) => "Instrucciones para conectar " + d.exchange,
        wallet: () => "Instrucciones de sincronización de billetera",
      },
      methods: {
        api: () => "Introducir detalles de la API",
        "bulk-wallet": () => "Introducir direcciones de billetera",
        wallet: () => "Ingresar la dirección de la billetera",
      },
      title: (d) => "Importar " + d.name,
    },
    imported: () => "Importado",
    importedFileWarning: () => "No se importaron transacciones de este archivo",
    importedTransactions: (d) =>
      "Se importó " +
      d.count +
      " " +
      plural(d.count, 0, es, {
        one: " transacción ",
        other: " transacciones ",
      }) +
      " de " +
      d.exchangeDisplayName +
      " .",
    imports: () => "Importaciones",
    integrationChips: {
      all: () => "Todo",
      blockchains: () => "Cadenas de bloques",
      exchanges: () => "Intercambios",
      services: () => "Servicios",
      wallets: () => "Carteras",
    },
    integrationSuccessful: () => "Integración exitosa",
    integrations: () => "Integraciones",
    label: () => "Selecciona tu exchange o wallet",
    lastLockedSyncWarning: {
      action: () => "Ver periodos bloqueados",
      body: (d) =>
        "Las transacciones anteriores a " + d.date + " no se importarán",
      title: () => "Periodos bloqueados:",
    },
    lastSynced: () => "Última sincronización",
    lastTx: () => "Última transacción importada:",
    manualCSV: {
      csvText: () => "CSV",
      customCSVToExchange: {
        description: {
          link: () =>
            "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
          linkText: () => " formato CSV personalizado.",
          preLinkText: () =>
            "Si desea importar transacciones personalizadas a esta cuenta, siga los pasos a continuación utilizando nuestro",
        },
        title: () => "Importación de transacciones personalizadas",
      },
      excelText: () => "Plantilla de Excel",
      finishAdding: () => "Terminar de agregar archivos y continuar",
      googleSheetsText: () => "Plantilla de hoja de cálculo de Google",
      learnMore: {
        link: () =>
          "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
        text: () => "Obtenga más información sobre las importaciones de CSV",
      },
      seeAdvancedGuide: {
        link: () =>
          "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
        linkText: () => "Guía avanzada de CSV",
        postLinkText: () =>
          "para operaciones en las que las tarifas aún no se han aplicado con los datos comerciales.",
        preLinkText: () => "Vea nuestro",
      },
      steps: {
        0: () =>
          "Complete los detalles de su transacción en la plantilla de Excel, CSV o Google Sheets.",
        1: () =>
          "Asegúrese de que todos los campos estén completos para evitar errores de carga.",
        2: () => "Exporte su documento como CSV y haga clic en cargar.",
      },
      warningText: () =>
        "Asegúrese de que sus datos estén preparados correctamente y en el formato de archivo CSV personalizado de Crypto Tax Calculator",
    },
    manualCSVImport: () => "Utilice la plantilla CSV de CTC para importar",
    manualImportTooltip: () =>
      "Si no puede encontrar su billetera o cambio, puede importar sus datos utilizando la plantilla CSV de CTC",
    marketValue: (d) => "Valor de mercado en " + d.localCurrency,
    nameOfSource: () => "Nombre de la fuente",
    noExchange: () => "¿No encuentras tu cambio?",
    noImports: () =>
      "¡No se encontraron intercambios, cadenas de bloques ni billeteras importadas!",
    noOptions: () => "Sin opciones",
    noSync: () => "Sin última sincronización",
    noTxFound: (d) =>
      "No se encontraron " +
      select(d.showNew, { true: " new ", other: " " }) +
      " transacciones para " +
      d.exchangeDisplayName +
      " .",
    noTxFoundWallet: (d) =>
      "No se encontraron " +
      select(d.showNew, { true: " new ", other: " " }) +
      " transacciones para la billetera " +
      d.blockchain +
      " " +
      d.address +
      " .",
    note: () => "Nota",
    notifications: {
      kucoinfutures: {
        buttonLabel: () => "Despedir",
        text: () =>
          "Sus datos de futuros de Kucoin se han consolidado en la integración de Kucoin",
        title: () => "Futuros de Kucoin eliminados",
      },
    },
    oauth: {
      connect: (d) => "Conectarse a " + d.exchange,
      description: (d) =>
        "Se le solicitará que inicie sesión en " +
        d.exchange +
        " y otorgue acceso de solo lectura a su cuenta. Esto no nos da acceso para transferir sus fondos.",
    },
    onboarding: {
      expect: () => "Esto es lo que puedes esperar",
      getStarted: () => "Empezar",
      steps: {
        0: () => "Elige tu país",
        1: () => "Integra tus exchanges, billeteras y cadenas de bloques",
        2: () =>
          "Obtén tu informe fiscal y verifica que tus datos sean exactos",
      },
      welcome: () =>
        "Bienvenido a la calculadora de impuestos sobre criptomonedas",
    },
    options: {
      api: () => "Sincronización mediante API",
      "bulk-wallet": () => "Monedero a granel",
      connect: (d) => "Conectar " + d.exchange,
      csv: () => "Subir archivo",
      oauth: (d) => "Conectar " + d.exchange,
      "single-import": () => "Importación única",
    },
    prompt: () => "Por ejemplo, Coinbase, Bitcoin",
    relatedChains: {
      account: () => "Cuenta",
      addBlockchain: () => "Añadir blockchain",
      cluster: {
        dontImport: () => "No importar",
        ignore: () => "No preguntar de nuevo",
        import: () => "Importar",
        text: () =>
          "¿Quieres importar el resto de las billeteras en este clúster?",
        title: () => "Detectamos que su dirección pertenece a un clúster",
      },
      dontImport: () => "No importar",
      import: () => "Importar",
      importTransactions: () =>
        "¿Importar transacciones en las siguientes cadenas para esta cuenta?",
      scanning: (d) =>
        "Escaneo de cadenas EVM en busca de transacciones en " + d.wallet,
      scanningNoResults: (d) =>
        "No encontramos ninguna otra Blockchain bajo " + d.address,
      scanningSuccess: () =>
        "Se encontraron transacciones en otras cadenas EVM para",
      stepProgress: (d) => "Monedero " + d.step + " de " + d.totalSteps + " :",
      stillScanning: () => "Todavía escaneando...",
      syncingOnChains: (d) =>
        "Sincronización " + d.wallet + " en cadenas " + d.chains,
      title: () => "Se detectaron transacciones en cadenas relacionadas",
    },
    requestAnIntegration: () => "Solicitar una integración",
    resultNotFound: () => "Resultado no encontrado",
    searchPrompt: () => "Busca tu billetera, exchange o blockchain",
    secureConnect: () => "Conexión segura",
    secureConnectNote: () =>
      "Solo solicitamos permisos de visualización. Nunca solicitaremos claves privadas y no podemos transferir fondos.",
    secureImport: (d) =>
      "Importe de forma segura su historial de transacciones " +
      d.exchangeName +
      " :",
    selectCountry: {
      chooseJurisdiction: () => "Elija su jurisdicción fiscal",
      findCountry: () => "Encuentra tu país",
      pleaseSelect: () => "Por favor seleccione un país para continuar",
      search: () => "Buscar",
      somethingWentWrong: (d) =>
        "Algo salió mal al configurar el país: $ " + d.message,
      somethingWentWrongSupport: () => "Algo salió mal, contacta al soporte.",
    },
    selectFile: () => "o arrastra y suelta tu archivo aquí",
    selectFiles: () => "o arrastra y suelta tus archivos aquí",
    skippedRows: {
      cta: (d) =>
        plural(d.count, 0, es, {
          1: " 1 fila omitida ",
          other: number("es", d.count, 0) + " filas omitidas ",
        }),
      errors: {
        apiError: () =>
          "Se produjo un error al interactuar con una API externa.",
        genericError: () => "Se produjo un error inesperado.",
        lockPeriod: () =>
          "Se omitió la fila porque habría creado una transacción en un período bloqueado",
        noOp: () =>
          "La fila se omitió intencionalmente debido a que los datos no eran relevantes o porque se recuperarían de un CSV diferente.",
        noTransactions: () => "No se encontraron transacciones en el CSV.",
        unhandledFields: () => "Se encontraron campos inesperados en la fila.",
        unhandledTimestamp: () =>
          "La marca de tiempo de la fila no tenía el formato esperado.",
        unhandledTrade: () => "La fila tenía un tipo de comercio no admitido.",
        userSpecifiedCurrencyIssue: () =>
          "Hubo un error con la moneda especificada por el usuario.",
      },
      header: () => "filas saltadas",
      info: (d) =>
        plural(d.count, 0, es, {
          1: " 1 fila en el CSV se omitió debido al siguiente motivo ",
          other:
            number("es", d.count, 0) +
            " filas en el CSV se omitieron debido a los siguientes motivos ",
        }),
    },
    softWalletHeading: (d) =>
      "Importamos tus datos " +
      d.name +
      " directamente desde la cadena de bloques. Selecciona la red de cadena de bloques de tu billetera a continuación.",
    softWalletNetworkSelect: () => "Ingresa a tu red de billeteras",
    softWalletPrompt: () => "Por ejemplo, Bitcoin, Ethereum",
    solanaBalanceWarning: () =>
      "El saldo incluye los SOL en stake y los SOL guardados en cuentas de tokens. Es posible que algunos exploradores no los incluyan en el saldo general.",
    sortImports: {
      aToZ: () => "Nombre AZ",
      mostTransactions: () => "La mayoría de las transacciones",
      zToA: () => "Nombre ZA",
    },
    successImportWallet: (d) =>
      "Se importaron con éxito transacciones desde la billetera " +
      d.blockchain +
      " " +
      d.address,
    suspectedMissingImports: {
      addAccount: () => "Agregar cuenta",
      chips: {
        amount: () => "Monto de transferencia:",
        count: () => "Actas:",
        currencies: () => "Monedas:",
      },
      explainer: (d) =>
        "Parece que realizó " +
        plural(d.count, 0, es, {
          1: " 1 transacción ",
          other: number("es", d.count, 0) + " transacciones ",
        }) +
        " que involucran " +
        d.symbol1 +
        plural(d.additionalCurrencyCount, 0, es, {
          0: select(d.symbol2, { undefined: "", other: " y " + d.symbol2 }),
          1: select(d.symbol2, {
            undefined: "",
            other: ", " + d.symbol2 + " y 1 moneda más ",
          }),
          other: select(d.symbol2, {
            undefined: "",
            other:
              ", " +
              d.symbol2 +
              " y " +
              number("es", d.additionalCurrencyCount, 0) +
              " monedas más ",
          }),
        }) +
        " en " +
        d.exchange +
        " " +
        select(d.startDate, {
          undefined: " en ",
          other: " entre " + d.startDate + " y ",
        }) +
        " " +
        d.endDate +
        ".",
      title: () => "Cuentas recomendadas",
      toastBody: () => "Puedes agregar esta cuenta en cualquier momento",
      toastHeading: () => "Recomendación rechazada",
      tooltip: () =>
        "Hemos detectado interacciones con estas cuentas. Conectar todas tus cuentas mejorará la precisión de tu informe fiscal.",
      viewTransactions: (d) =>
        "Ver " +
        plural(d.count, 0, es, {
          1: " transacción ",
          other: " transacciones ",
        }) +
        ".",
      whyThis: () => "¿Por qué esta cuenta?",
    },
    sync: () => "Sincronizar",
    syncAll: () => "Sincronizar todo",
    syncFailed: () => "Sincronización fallida",
    syncStatus: {
      failed: (d) =>
        select(d.isCsv, {
          true: " Error en la importación ",
          other: " Error en la sincronización ",
        }),
      noSync: () => "Sin sincronización",
      pending: (d) =>
        select(d.isCsv, { true: " Importando ", other: " Sincronizando " }),
      queued: (d) =>
        select(d.isCsv, { true: " Importando ", other: " En cola " }),
      success: (d) =>
        select(d.isCsv, { true: " Importado ", other: " Sincronizado " }),
    },
    syncedFrom: () => "Sincronizado desde:",
    syncing: () => "Sincronizando...",
    syncingWallet: (d) => "Sincronización de la billetera " + d.name,
    title: () => "Agregar cuenta",
    transactionCount: {
      noTransactions: () => "Sin transacciones",
      noTxs: () => "Sin TX",
      transaction: () => "transacción",
      transactions: () => "actas",
      tx: () => "Tejas",
      txs: () => "TX",
    },
    transactionsImported: () => "Transacciones importadas",
    updatedAt: (d) => "Actualizado " + d.time,
    uploaded: (d) => "Cargado " + d.time,
    verifying: () => "Verificando",
    view: () => "Vista",
    viewGuide: () => "Ver guía",
    viewMode: {
      byAddress: () => "Por Monedero",
      byBlockchain: () => "Por Blockchain",
    },
    viewSBSInstructions: () => "Ver instrucciones paso a paso",
    wentWrongAvailableImports: () =>
      "Se produjo un error al obtener las importaciones disponibles. Inténtalo nuevamente más tarde.",
    wentWrongCurrentHoldings: () =>
      "Se produjo un error al obtener los valores actuales. Inténtalo nuevamente más tarde.",
    wentWrongDeleteFile: () =>
      "Se produjo un error al intentar eliminar el archivo. Inténtalo de nuevo más tarde.",
    wentWrongDeletingCSV: (d) =>
      "Algo salió mal al eliminar el archivo CSV " + d.name + " .",
    wentWrongDownloadFile: () => "No se pudo descargar el archivo",
    wentWrongExistingSaved: () =>
      "Se produjo un error al solicitar importaciones guardadas existentes. Inténtelo nuevamente más tarde",
  },
  inTxCategoryOptionsTitle: () => "Transacciones entrantes",
  incomeToggles: () => "Opciones de categorías de ingresos",
  info: () => "Información",
  instructions: () => "Instrucciones",
  integrationWarnings: {
    btc: {
      invalidWallet: {
        description: () =>
          "Le recomendamos que utilice su dirección xPub, yPub o zPub porque las billeteras de Bitcoin generan múltiples direcciones bajo una cuenta maestra",
        noTxs: () =>
          "No se importaron transacciones porque la dirección de billetera utilizada no es una dirección xPub, yPub o zPub. Recomendamos importar su dirección xPub, yPub o zPub.",
        title: () => "Por favor, introduzca una dirección xPub, yPub o zPub",
        wrongFormat: () =>
          "La dirección de billetera utilizada no es una dirección xPub, yPub o zPub. Recomendamos importar su dirección xPub, yPub o zPub para garantizar que se importen todas sus transacciones de Bitcoin.",
      },
    },
    default: {
      noTxs: () =>
        "No se detectaron transacciones para esta billetera importada. Esto podría deberse a que la dirección de la billetera se ingresó incorrectamente o a que la billetera no tiene transacciones en la cadena de bloques seleccionada. Verifique los detalles de su billetera y la cadena de bloques seleccionada.",
    },
  },
  invitation: {
    buttonText: {
      accepted: () => "Aceptar",
      declined: () => "Rechazar",
      deleted: () => "Borrar",
      pending: () => "Invitar",
      revoked: () => "Revocar acceso",
      revokedLink: () => "Revocar acceso",
    },
    confirmationDialog: {
      collaborator: {
        accepted: {
          actionText: () => "Aceptar invitación",
          cancelText: () => "Atrás",
          text: () =>
            "¿Está seguro de que desea aceptar esta invitación? Esto lo agregará como miembro del equipo de la organización del contador, donde podrá administrar a sus clientes.",
          title: () => "¿Aceptar invitación?",
        },
        declined: {
          actionText: () => "Rechazar invitación",
          cancelText: () => "Atrás",
          text: () =>
            "¿Está seguro de que desea rechazar esta invitación? No se le agregará como miembro del equipo de la organización del contador.",
          title: () => "¿Rechazar la invitación?",
        },
        deleted: {
          actionText: () => "Borrar",
          cancelText: () => "Atrás",
          text: () => "¿Estás seguro que deseas eliminar esta invitación?",
          title: () => "¿Borrar invitación?",
        },
        pending: {
          actionText: () => "Cancelar",
          cancelText: () => "Atrás",
          text: () => "Esta es una invitación pendiente",
          title: () => "Invitación pendiente",
        },
        revoked: {
          actionText: () => "Revocar acceso",
          cancelText: () => "Atrás",
          confirmationPrompt: () =>
            "Escriba 'revoke-accountant-access-forever' para proceder con la revocación de acceso.",
          confirmationText: () => "revoke-accountant-access-forever",
          text: () =>
            "¿Está seguro de que desea revocar el acceso al perfil de su contador? Esto eliminará su capacidad para ver o editar cualquier dato cargado a través del conjunto de datos de su contador. Incluso si usted ha estado cargando datos por su cuenta, la propiedad puede seguir perteneciendo a su contador.",
          title: () => "¿Revocar el acceso?",
        },
      },
      other: {
        accepted: {
          actionText: () => "Aceptar invitación",
          cancelText: () => "Atrás",
          text: () =>
            "Después de aceptar la invitación, tendrá la opción de mostrar los datos cargados por su contador o los datos cargados por su propia cuenta. Si su contador no ha cargado ninguno de sus datos, le sugerimos que elija mostrar los datos de esta cuenta. Si lo desea, puede revocar este acceso en cualquier momento; sin embargo, los datos permanecerán con su contador y volverá a sus datos originales. ¿Está seguro de que desea aceptar esta invitación?",
          title: () => "¿Aceptar invitación?",
        },
        declined: {
          actionText: () => "Rechazar invitación",
          cancelText: () => "Atrás",
          text: () => "¿Estás seguro que deseas rechazar esta invitación?",
          title: () => "¿Rechazar la invitación?",
        },
        deleted: {
          actionText: () => "Borrar",
          cancelText: () => "Atrás",
          text: () => "¿Estás seguro que deseas eliminar esta invitación?",
          title: () => "¿Eliminar invitación?",
        },
        pending: {
          actionText: () => "Cancelar",
          cancelText: () => "Atrás",
          text: () => "Esta es una invitación pendiente",
          title: () => "Invitación pendiente",
        },
        revoked: {
          actionText: () => "Revocar acceso",
          cancelText: () => "Atrás",
          text: () =>
            "¿Está seguro de que desea revocar el acceso al perfil de su contador? Ya no podrá leer ni editar los datos que su contador haya cargado en su nombre.",
          title: () => "¿Revocar el acceso?",
        },
      },
    },
    noInvites: () => "Aún no has recibido ninguna invitación de contables",
    receivedInvitations: () => "Recibi invitaciones",
    warning: () =>
      "¡Algo salió mal! Comuníquese con el servicio de asistencia si el problema continúa",
    wentWrongGettingInvitations: () =>
      "Se produjo un error al recibir las invitaciones. Inténtalo nuevamente más tarde.",
  },
  invitationStatus: {
    accepted: () => "Aceptado",
    declined: () => "Rechazado",
    deleted: () => "Eliminado",
    notInvited: () => "No invitado",
    pending: () => "Pendiente",
    revoked: () => "Revocado",
    revokedLink: () => "Revocado",
  },
  invite: () => "Invitar cliente",
  keys: {
    deprecated: () => "Obsoleto",
    deprecatedInfo: (d) =>
      d.exchange +
      " ha dejado obsoleto este método de autenticación. Aún lo admitimos, pero recomendamos actualizar sus claves según las nuevas instrucciones. [Más información]( " +
      d.linkTextKey +
      " )",
    deprecatedInfoByExchange: {
      coinbase: (d) =>
        d.exchange +
        " ha dejado obsoleto este método de autenticación. Actualice sus claves según las nuevas instrucciones. [Más información]( " +
        d.linkTextKey +
        " )",
    },
    wentWrongDeleteExchangeAPI: (d) =>
      "Se produjo un error al intentar eliminar las claves API de " +
      d.name +
      " . Inténtalo de nuevo más tarde.",
    wentWrongDeletingAPI: () =>
      "Se produjo un error al intentar eliminar la clave API. Inténtalo de nuevo más tarde.",
    wentWrongSavingAPI: (d) =>
      "Algo salió mal al guardar las claves API " + d.name + " .",
    wentWrongUpdatingAPI: (d) =>
      "Algo salió mal al actualizar las claves API " + d.name + " .",
  },
  labels: {
    addExistingLabels: () => "Agregar etiquetas existentes",
    addLabel: () => "Agregar etiqueta",
    cancel: () => "Cancelar",
    createNewLabel: () => "Crear nueva etiqueta",
    labelColor: () => "Color de la etiqueta",
    labelDescription: () => "Descripción de la etiqueta (opcional)",
    labelName: () => "Nombre de la etiqueta",
    noLabels: () => "No se crearon etiquetas",
    saveAndAdd: () => "Guardar y agregar",
  },
  language: () => "Idioma",
  learnMore: () => "Obtenga más información en el centro de ayuda",
  ledger: {
    banner: {
      text: () =>
        "La falta de historial de compras se produce cuando se han vendido más criptoactivos de los que se compraron originalmente. Esto sugiere que algunos registros de adquisición pueden faltar, no estar categorizados o estar categorizados incorrectamente.",
      title: () => "Este es el banner del libro mayor",
    },
    confirmations: {
      deleteTransactionConfirmation: {
        text: () =>
          "¡Atención! Esta acción no se puede revertir. ¿Está seguro de que desea eliminar esta transacción?",
        title: () => "Eliminar transacción",
      },
      ignoreTransactionConfirmation: {
        text: () =>
          "¿Está seguro de que desea ignorar esta transacción? Al realizar esta acción, se ignorará esta transacción en todos los cálculos de impuestos. Las transacciones ignoradas se pueden ocultar de la tabla de transacciones en los filtros avanzados.",
        title: () => "Ignorar transacción",
      },
    },
    deposit: () => "Depósito",
    descriptions: {
      amountAcquired: (d) =>
        "La cantidad total de " +
        d.ticker +
        " que has adquirido y registrado en la aplicación.",
      amountDisposed: (d) =>
        "La cantidad total de " +
        d.ticker +
        " que ha vendido o eliminado, según se registra en la aplicación.",
      amountMissing: (d) =>
        "La cantidad de " +
        d.ticker +
        " que no se contabilizan en sus transacciones. Esto puede deberse a transacciones no categorizadas o a datos faltantes.",
      amountUncategorised: (d) =>
        "Cantidad de " +
        d.ticker +
        " que aún no se ha categorizado. Para obtener informes y cálculos impositivos precisos, asigne categorías a estas transacciones.",
    },
    errors: {
      unableLoadRecon: () => "No se puede cargar el libro mayor",
    },
    filters: {
      onlyShowNegativeBalance: () =>
        "Mostrar solo el historial de compras faltante",
    },
    goBack: () => "Volver",
    headings: {
      amountNegative: () => "Cantidad negativa:",
      holdings: () => "Valores en cartera:",
      taxImpact: () => "Impacto fiscal:",
      transactions: () => "Actas:",
      value: () => "Valor:",
    },
    headings2: {
      amountAcquired: () => "Monto total adquirido",
      amountDisposed: () => "Monto total dispuesto",
      amountMissing: () => "Cantidad faltante",
      amountUncategorised: () => "Cantidad Sin categorizar",
      transactions: () => "Actas",
    },
    ledger: () => "Libro mayor",
    ledgerSourceFilter: {
      accounts: () => "Cuentas",
      noOptions: () => "Sin opciones",
      placeholder: () => "Mostrar todas las cuentas",
      selectAll: () => "Seleccionar todo",
    },
    loading: () => "Cargando",
    negativeBalanceOverallTooltip: () =>
      "A este activo le falta historial de compras.",
    negativeBalanceTooltip: (d) =>
      "Se han producido más transacciones de venta que de compra " +
      d.ticker +
      " . Verificar las transacciones entrantes.",
    newest: () => "El más nuevo",
    notFound: () => "Extraviado",
    oldest: () => "Más antiguo",
    pageTitle: (d) => "Libro mayor " + d.currencyName,
    tableHeader: {
      account: () => "Cuenta",
      amount: () => "Cantidad",
      dateAndType: () => "Fecha y tipo",
      overallBalance: () => "Balance general",
      tooltips: {
        account: () =>
          "Monederos, exchanges, datos importados o similares y el saldo de esta cuenta inmediatamente después de realizada la transacción.",
        overallBalance: () =>
          "El saldo total de todas sus cuentas inmediatamente después de que se realizó la transacción.",
      },
    },
    uncategorisedTooltip: () =>
      "Debes categorizar esta transacción para que cuente para el saldo del libro mayor.",
    unmatchedTooltip: () =>
      "Esta transacción no cuenta para el saldo del libro mayor porque es unilateral.",
    withdrawal: () => "Retiro",
  },
  legacyPlanTooltip: () =>
    "Usted está en un plan heredado con 3000 transacciones",
  manual: {
    Date: () => "Fecha",
    addNewTransaction: () => "Agregar nueva transacción",
    addTransaction: () => "Agregar transacción",
    currency: () => "Divisa",
    feeCurrency: () => "Moneda de la tarifa",
    feeQuantity: () => "Cantidad de la tarifa",
    from: () => "De",
    price: () => "Precio",
    quantity: () => "Cantidad",
    quoteCurrency: () => "Moneda de cotización",
    quoteQuantity: () => "Cantidad de cotización",
    receiveCurrency: () => "Comprar moneda",
    receiveQuantity: () => "Comprar cantidad",
    requiredText: {
      currency: () => "Debes ingresar una moneda",
      feeCurrency: () => "Debes ingresar una moneda de tarifa",
      feeQuantity: () => "Debes introducir una cantidad de tarifa",
      notInLockedPeriod: () => "La fecha no debe estar en el período bloqueado",
      price: () => "Debes introducir un precio",
      quantity: () => "Debes introducir una cantidad",
      time: (d) =>
        "Debes ingresar una hora válida en el formato " + d.timeFormat,
      value: () => "Debes introducir un valor",
    },
    sellCurrency: () => "Vender moneda",
    sellQuantity: () => "Vender cantidad",
    stepTitle: {
      category: () => "Categoría de transacción",
      details: (d) =>
        select(d.category, {
          trade: " Detalles del comercio ",
          transfer: " Detalles de la transferencia ",
          other: " Detalles de la transacción ",
        }),
      fees: () => "Honorarios",
      sourceAndDest: () => "Origen y destino",
    },
    time: () => "Tiempo",
    to: () => "A",
    trade: () => "Categoría",
    transactionType: {
      other: () => "Otro",
      trade: () => "Comercio",
      transfer: () => "Transferir",
    },
    transactionTypeDescription: {
      other: () => "Todas las demás transacciones",
      trade: () => "Aquí es donde vendes una moneda por otra.",
      transfer: () =>
        "Aquí es donde usted transfiere entre sus propias billeteras/intercambios.",
    },
    value: () => "Valor",
  },
  manualCSV: {
    addCustomCSV: () => "Agregar CSV personalizado",
    advanced: {
      link: () =>
        "https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-manual-csv-import/",
      part1: () =>
        "Para operaciones avanzadas donde las tarifas aún no se han aplicado con los datos comerciales, consulte nuestra ",
      part2: () => "Guía avanzada de CSV.",
    },
    advancedOptions: () => "Opciones avanzadas",
    cryptoGroup: () => "Criptomoneda",
    dateFormatHelper: () =>
      "Seleccione el formato de fecha que aparece en el archivo CSV. Si no se especifica, el formato de fecha predeterminado será AAAA/MM/DD HH:mm:ss.",
    example: () => "Descargar un ejemplo de formato CSV",
    exchange: () => "Intercambio",
    exchangeRule: () => "Debes ingresar a un exchange",
    fiatGroup: () => "Fíat",
    here: () => "aquí",
    importFromDateHelper: () =>
      "Seleccione la fecha a partir de la cual desea comenzar a importar transacciones. Si no se especifica, se importarán todas las transacciones.",
    importHelper: {
      helpText: () => "¿Necesita ayuda para importar datos?",
      link: () =>
        "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
    },
    manualGroup: () => "Manual",
    manualSelectCurrencies: () => "Asignar monedas manualmente",
    manualSelectDateFormat: () => "Seleccionar manualmente el formato de fecha",
    manualSelectImportFromDate: () =>
      "Seleccionar manualmente la fecha de inicio de importación",
    manualSelectTimezone: () => "Seleccionar zona horaria manualmente",
    nameExists: () =>
      "El nombre ya existe, para subirlo a este intercambio selecciónelo desde la página de importación",
    nameTooLong: () => "Nombre demasiado largo",
    noOptions: () => "Sin opciones",
    patternRule: () => "Solo se permiten caracteres alfanuméricos y espacios.",
    pendingUploadMessage: {
      manyFiles: (d) => d.numFiles + " archivos para cargar",
      singleFile: (d) => "1 archivo ( " + d.filename + " ) para cargar",
    },
    preparationWarning: () =>
      "Los datos CSV requieren preparación antes de cargarlos",
    selectCurrencies: () => "Seleccionar monedas",
    selectDateFormat: () => "Seleccionar formato de fecha",
    simple: {
      link: () => "https://cryptotaxcalculator.io/advanced.csv",
      part1: () => "Descargar un CSV de muestra",
      part2: () => " para ver un ejemplo del formato requerido.",
    },
    tickerHelper: () =>
      'Seleccione los tickers que aparecen en su CSV y que desea asignar a una moneda específica. Por ejemplo, si desea asignar el ticker "BTC" a "Bitcoin", seleccione "Bitcoin (BTC)" en el menú desplegable a continuación. Los tickers no especificados se asignarán según su clasificación de capitalización de mercado, siempre que sea posible.',
    timezoneHelper: () =>
      "Seleccione la zona horaria en la que se realizaron las transacciones. Si no se especifica, la zona horaria se detectará automáticamente.",
    title: () => "Importar datos mediante CSV",
    uploadFileWarning: () => "Por favor, cargue un archivo CSV",
  },
  markAsReviewed: () => "Marcar como revisado",
  markAsUnreviewed: () => "Marcar como no revisado",
  mobileDemandTest: {
    buttonText: () => "Obtener la app",
    chip: () => "Registrarse para la BETA",
    description: () =>
      "Registra tu interés y sé uno de los primeros en obtener acceso anticipado a la App de CTC – haciendo los impuestos de criptomonedas más fáciles que nunca.",
    noThanks: () => "No, gracias",
    registerInterest: () => "Registrar interés",
    title: () => "Sé uno de los primeros en obtener la App",
    toast: {
      description: () => "Mantente atento a tu bandeja de entrada",
      title: () => "Has registrado tu interés",
    },
  },
  mockReport: {
    disabled: () => "Deshabilitado para datos de prueba",
    infoBanner: {
      button: () => "Ver datos de ejemplo",
      subtext: () =>
        "Puede ver los datos de prueba antes de elegir un plan de precios.",
      title: () => "Ver datos de ejemplo",
    },
    signup: {
      button: (d) =>
        select(d.isAccountant, {
          true: " Actualizar cliente ",
          other: " Comenzar ",
        }),
      errorMessage: () =>
        "Se produjo un error al actualizar el cliente. Comuníquese con el servicio de asistencia.",
      subtext: (d) =>
        select(d.isAccountant, {
          true: select(d.txCount, {
            0: "Actualmente, este cliente no tiene un plan pago. Para generar un informe de impuestos, deberá comprar un plan 👉",
            other:
              "Actualmente, este cliente tiene " +
              d.txCount +
              " importado " +
              plural(d.txCount, 0, es, {
                1: "transaction",
                other: "transactions",
              }) +
              ". Para generar un informe de impuestos, deberá actualizar este cliente al plan " +
              d.suggestedPlan +
              " 👉",
          }),
          other:
            "Elija uno de nuestros planes para ver sus datos y descargar informes de impuestos.",
        }),
      title: (d) =>
        select(d.isAccountant, {
          true:
            " ¿Quieres generar un informe de impuestos para " +
            d.clientName +
            " ? ",
          other: " Genera tu informe de impuestos ",
        }),
    },
    testData: () => "Datos de prueba",
  },
  na: () => "N / A",
  name: () => "Nombre",
  nav: {
    billing: () => "Facturación",
    contacts: () => "Contactos",
    developer: () => "Revelador",
    imports: () => "Importar datos de Exchange",
    logout: () => "Cerrar sesión",
    manageSubscription: () => "Administrar suscripción",
    manual: () => "Importación manual de CSV",
    menu: () => "Menú",
    plans: () => "Planes",
    report: () => "Ver informe fiscal",
    settings: () => "Ajustes",
    transactions: () => "Revisar transacciones",
    wallet: () => "Importar datos de la billetera",
    warnings: () => "Advertencias",
  },
  navbar: {
    accountingIntegrations: () => "Integraciones contables",
    addressBook: () => "Libreta de direcciones",
    clientSettings: () => "Configuración del cliente",
    clients: () => "Clientela",
    cryptoIntegrations: () => "Integraciones de criptomonedas",
    dashboard: () => "Panel",
    followUsOnX: () => "Síguenos en X",
    footer: {
      buttonCta: () => "Desbloquea ahorros fiscales",
      referralXOffAllPlans: (d) =>
        "Referido " + d.discount + "% de descuento en todos los planes",
      xPercentOffAllPlans: (d) =>
        d.discount + "% de descuento en todos los planes",
      youHaveXTransactions: (d) => "Tienes " + d.count + " transacciones",
    },
    help: () => "Ir al centro de ayuda",
    helpCenter: () => "Centro de ayuda",
    imports: () => "Importaciones",
    integrations: () => "Cuentas",
    more: () => "Más",
    myClients: () => "Mis clientes",
    mySettings: () => "Configuración del espacio de trabajo",
    myTeam: () => "Mi equipo",
    portfolio: () => "Cartera",
    reconciliation: () => "Reconciliación",
    referAFriend: () => "Recomendar a un amigo",
    referFriends: () => "Invita a un amigo y obtén $40",
    referFriendsV2: (d) => "Recomienda y gana " + d.reward,
    referGetHundredCash: (d) => "Obtén " + d.reward + " en efectivo",
    report: () => "Informes",
    review: () => "Revisar",
    rules: () => "Normas",
    selectClient: () => "Seleccionar cliente",
    settings: () => "Ajustes",
    subheadings: {
      integrations: () => "Cuentas",
      reconciliation: () => "Reconciliación",
      reports: () => "Informes",
    },
    subscribe: () => "Suscribir",
    support: () => "Chatear con soporte",
    tips: () => "Consejos",
    title: () => "CTC",
    tooltip: (d) =>
      "[Suscríbete]( " +
      d.linkTextKey +
      " ) para ver consejos sobre cómo ahorrar en tu declaración de impuestos",
    tooltipReview: (d) =>
      "[Suscríbete]( " +
      d.linkTextKey +
      " ) para revisar tus transacciones y ahorrar en tu declaración de impuestos",
    transactionSubItems: {
      failed: () => "Fallido",
      notReadyToSync: () => "No está listo para sincronizar",
      outOfDate: () => "Desactualizado",
      readyToSync: () => "Listo para sincronizar",
      synced: () => "Sincronizado",
    },
    transactions: () => "Actas",
  },
  new: () => "Nuevo",
  newTxModal: {
    actionButton: () => "¡Échale un vistazo!",
    back: () => "Atrás",
    description: {
      0: () =>
        "Se ha mejorado la tabla de transacciones para permitir una comprensión rápida y sencilla de los datos.",
      1: () =>
        "Cuando categorizas una transacción, ahora identificamos transacciones similares para aplicarlas en forma masiva, de modo que no tengas que repetirlas.",
      2: () =>
        "Reemplazamos la página de Reconciliación con una nueva versión optimizada llamada 'Consejos' que está disponible para suscriptores activos.",
      3: () =>
        "Nuestro algoritmo de automatización se ha actualizado para que ahora pueda categorizar de manera más inteligente las transacciones complejas en cadena.",
    },
    nextPage: () => "Próximo",
    title: () => "¡Nueva tabla de transacciones y más!",
  },
  next: () => "Próximo",
  no: () => "No",
  noResults: () => "No hay resultados",
  none: () => "Ninguno",
  notifications: {
    missingBlockchain: {
      blogLink: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6388387-how-to-resolve-missing-blockchain-warnings",
      cta: () => "Resolver",
      link: () => "/transacciones?advertencia=faltaBlockchain",
      primary: () => "Falta la cadena de bloques",
      secondary: () => "Revisar transacciones donde no se encontró blockchain.",
    },
    missingPrice: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/missing-market-prices",
      cta: () => "Resolver",
      link: () => "/transactions?warning=missingPrice",
      primary: () => "Precios de mercado faltantes",
      secondary: () =>
        "Revisar y actualizar las transacciones donde no se encontró precio de mercado.",
    },
    negativeBalance: {
      blogLink: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
      cta: () => "Resolver",
      link: () => "/transactions?warning=negativeBalance",
      primary: () => "Historial de compras faltante",
      secondary: () =>
        "Revise las transacciones que provocan que sus saldos sean negativos.",
    },
    uncategorised: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/cryptocurrency-tax-categories",
      cta: () => "Resolver",
      link: () => "/transactions?warning=uncategorised",
      primary: () => "Transacciones sin categorizar",
      secondary: () =>
        "Recategorizó sus transacciones para mejorar la precisión de la calculadora.",
    },
    zeroCostBuy: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/negative-balance-remaining-warnings",
      blogLink2: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
      cta: () => "Resolver",
      link: () => "/transactions?warning=negativeBalance",
      primary: () => "Compras de costo cero",
      secondary: () =>
        "Revise las transacciones de venta en las que tenga una base de costo de cero debido a un historial de compras anterior no resuelto. Esto puede provocar que las ganancias informadas sean mayores de lo que deberían ser.",
    },
  },
  onboardingV2: {
    LetsReviewTx: {
      body1: () => "¿No debería esto automatizarse, dices?",
      body2: () =>
        "Sí, la mayoría de sus transacciones se categorizan automáticamente, pero en algunos casos no se puede determinar la categoría. Para garantizar la precisión, las marcamos para que las aclare.",
      cta: () => "Continuar",
      title: () => "Bien, revisemos algunas de tus transacciones.",
    },
    accountConfirmation: {
      CTA: () => "Continuar importando",
      nonCTA: () => "Saltar al siguiente paso",
      subtitle: () =>
        "Se requiere un historial completo de transacciones para garantizar que los números sean correctos.",
      title: (d) =>
        "¡Has " +
        plural(d.count, 0, es, {
          one: " solo agregado 1 cuenta ",
          other: " agregado " + number("es", d.count, 0) + " cuentas ",
        }) +
        " !",
      title2: () => "¿Estás seguro de que has importado todo?",
    },
    accountCountry: {
      plusMore: (d) => "+ " + d.count + " más",
      showLess: () => "Mostrar menos",
      showMore: () => "Mostrar más",
      subtitle: () =>
        "Esto determina las reglas fiscales predeterminadas y la moneda local.",
      title: () => "Elija su jurisdicción fiscal",
    },
    accountList: {
      addAnotherAccount: () => "Agregar otra cuenta",
      yourAccounts: () => "Sus cuentas",
    },
    accountTheme: {
      footerButton: () => "Próximo",
      subtitle: () => "Siempre puedes cambiarlo más tarde.",
      themes: {
        dark: () => "Modo oscuro",
        light: () => "Modo claro",
        system: () => "Modo de sistema",
      },
      title: () => "¿Qué tema prefieres?",
    },
    accountType: {
      footerButton: () => "Próximo",
      securityCta: () => "Seguridad y privacidad de los datos",
      subtitle: () => "¿Qué tipo de usuario eres?",
      title: () =>
        "Bienvenido a la Calculadora de impuestos sobre criptomonedas 👋",
      types: {
        accountant: () => "Contador",
        individual: () => "Individual",
      },
    },
    accuracy: {
      content: {
        accounts: () => "cuentas",
        categorise: () => "Clasifique sus transacciones",
        complete: () =>
          "Se debe proporcionar un historial completo de todas sus transacciones. Esto permite que nuestro algoritmo de cálculo de impuestos compare automáticamente las transacciones en todas sus cuentas, lo que minimiza el trabajo requerido por su parte y garantiza que las ganancias calculadas sean precisas.",
        considerations: () => "Consideraciones clave:",
        costBase: () => "base de costos",
        determine: () => "Para determinar con precisión su",
        ensure: () =>
          "Si bien la mayoría de las transacciones se categorizarán automáticamente, en algunos casos no se puede determinar la categoría correcta. La categorización de sus transacciones garantiza que se aplique el resultado fiscal correcto.",
        import: () => "Importa todos tus ",
        inactive: () => " Incluso aquellos que ya no utilizas activamente.",
        noAccess: () =>
          "Tenga en cuenta que CTC nunca tiene acceso a los fondos de los usuarios. Las importaciones están estrictamente restringidas a la información de transacciones necesaria para calcular los impuestos.",
      },
      footerBack: () => "Atrás",
      footerNext: () => "Próximo",
      subtitle: () => "Elaboración de informes fiscales precisos",
      title: () => "Cómo obtener informes fiscales precisos",
    },
    areYouSure: {
      body1: {
        costBasis: () => "base de costo",
        costBasisTooltip: () =>
          "El precio original que pagó por el activo, más las tarifas de transacción",
        frontPart: () =>
          "Un historial completo de sus transacciones mejorará drásticamente la precisión de los números, lo que le permitirá realizar un seguimiento preciso de sus transacciones. ",
        lastPart: () => " en todos sus activos.",
      },
      body2: {
        accounts: () => "cuentas,",
        accountsTooltip: () =>
          "Carteras e intercambios que utilizas para realizar transacciones con criptomonedas.",
        frontPart: () =>
          "Esto es particularmente importante cuando se transfieren activos entre ",
        lastPart: () =>
          " Por ejemplo, al enviar criptomonedas desde un exchange a otra billetera. Si ese exchange no se ha importado, no podremos determinar la base de costo de ese activo.",
      },
      buttons: {
        doneImport: () => "Estoy seguro de que he importado todo.",
        importMore: () => "Déjame importar algunos más",
      },
      footer: () =>
        "Siempre puedes agregar más billeteras más adelante, pero te recomendamos hacerlo ahora para garantizar una experiencia fluida.",
      title: () => "¿Estás seguro de que has importado todas tus cuentas?",
    },
    calculating: {
      autoSaved: () => "El progreso se guarda automáticamente",
      buttons: {
        continue: () => "Continuar",
        finish: () => "Finalizar",
        goBack: () => "Volver",
        importMore: () => "Importar más",
        skip: () => "Entrar a la aplicación",
      },
      loading10Minutes: () =>
        "Vaya, parece que tienes muchas transacciones. Puede que esto lleve algo de tiempo, pero nos ocuparemos de ello. Te enviaremos un correo electrónico tan pronto como se complete la importación.",
      loading3Minutes: () =>
        "La importación de sus transacciones podría tomar algún tiempo, mientras espera, continúe configurando su cuenta.",
      loadingETA: () => "Esto suele tardar entre 2 y 3 minutos.",
      loadingETAFake: () => "Esto no tomará mucho tiempo",
      loadingTakingLongerThanUsual: () =>
        "Esto está tomando más tiempo de lo habitual. Todavía estoy haciendo números.",
      loadingText: {
        calculatingTheCostBaseForEachAsset: () =>
          "Calcular la base de costos para cada activo",
        categorisingYourTransactions: () =>
          "Categorización de sus transacciones",
        categorizingBridges: () => "Detección de puentes",
        categorizingStaking: () => "Detección de staking",
        categorizingSwaps: () => "Detección de swaps",
        categorizingTrades: () => "Detección de operaciones",
        categorizingTransfers: () => "Detección de transferencias",
        computingGainsForEachTransaction: () =>
          "Calcular las ganancias de cada transacción",
        detectingAnyCryptoIncome: () =>
          "Detectar cualquier ingreso criptográfico",
        finalizingInitialTaxReports: () =>
          "Finalización de los informes fiscales iniciales",
        importingTransactionData: () => "Importación de datos de transacciones",
        summarizingCapitalGainByTaxYear: () =>
          "Resumen de la ganancia de capital por año fiscal",
      },
      titleCalculationsComplete: () => "¡Cálculos completos!",
    },
    cexImportReminder: {
      bodyText: () =>
        "Es importante que importes todas tus billeteras, incluidas las plataformas de intercambio centralizadas que hayas utilizado. Si no tenemos toda tu actividad comercial, los números no serán correctos.",
      modalText: () =>
        "Por ejemplo, algunos intercambios que quizás hayas utilizado y necesites importar son:",
      primaryButton: () => "Importar un intercambio",
      secondaryButton: () => "Continuar sin importar",
      title: () => "No olvides importar tus exchanges centralizados",
    },
    coinbaseOauth: {
      import: {
        connectCoinbase: () => "Conectar Coinbase",
        continue: () => "Continuar",
        importedSubtitle: () => "Continúe configurando su cuenta.",
        importedTitle: () => "Transacciones de Coinbase importadas",
        importingBanner: () =>
          "La importación de sus transacciones de Coinbase podría llevar algún tiempo mientras espera,",
        importingBannerLink: () => "Continúe configurando su cuenta",
        importingSubtitle: () =>
          "Mientras tanto, continuemos configurando su cuenta.",
        importingTitle: () => "Estamos importando tus transacciones",
        skipConnectCoinbase: () => "¿No tienes una cuenta Coinbase?",
        skipConnectCoinbaseCTA: () => "Continuar sin conectar Coinbase",
        subtitle: () => "Vamos a conectar tu cuenta",
        title: () => "Conecta tu cuenta de Coinbase",
      },
      welcomeTitle: () => "Bienvenido usuario de Coinbase 👋",
    },
    competitorSurvey: {
      options: {
        no: () => "No",
        yes: () => "Sí",
      },
      title: () =>
        "¿Has utilizado anteriormente otro software de impuestos criptográficos?",
    },
    example: () => "Ejemplo",
    import: {
      autoImported: () => "Auto importado",
      footer: () => "+ más de 2.900 integraciones más.",
      footerCTA: () => "Busca el tuyo",
      hasSavedAccounts: {
        finishImport: () => "He añadido todas mis cuentas",
        keepAdding: () => "Sigue añadiendo tus cuentas",
        or: () => "o",
        subTitle: () =>
          "¡Asegúrate de agregar todos tus exchanges y billeteras para que los números sumen!",
        title: () => "👉Agrega tu próxima cuenta",
      },
      importManualCSV: () => "Importación manual de CSV",
      navBar: {
        importType: {
          api: () => "API",
          "bulk-wallet": () => "Billetera masiva",
          "connect-wallet": () => "Conectar billetera",
          csv: () => "CSV",
          oauth: () => "Conectar",
          "soft-wallet": () => "Conectar",
          wallet: () => "API",
        },
      },
      searchBar: {
        importWallet: () => "Importar billetera",
        placeholder: () => "Busca tu exchange o pega tu wallet",
        section: {
          allOthers: () => "Todos los exchanges y monederos",
          manual: () => "Manual",
          popular: () => "Popular",
        },
      },
      subTitle: () =>
        "Busca tu exchange o pega la dirección de tu billetera 👇 ",
      title: () => "Añade tu primera cuenta",
    },
    metamask: {
      accurateCpaEndorsed: () =>
        "Informes fiscales precisos, avalados por un contador público certificado, sin conjeturas",
      accurateCryptoTaxes: () =>
        "Impuestos criptográficos precisos.\nSin conjeturas.",
      continueWith: (d) => "Continuar con " + d.provider,
      cryptoTaxCalculator: () => "Calculadora de impuestos sobre criptomonedas",
      cta: () => "Continuar",
      disclaimer: () =>
        "*Solo para nuevos clientes de la Calculadora de impuestos sobre criptomonedas. El descuento se aplica solo durante el primer año.",
      fullSupportIRS: () =>
        "Compatibilidad total con las normas del IRS. Importe directamente a TurboTax o H&R Block, o compártalos de forma segura con su contador.",
      manageCryptoTaxes: () =>
        "Administra tus impuestos sobre criptomonedas directamente desde donde operas.",
      newCustomersDiscount: () =>
        "Los nuevos clientes obtienen un 30% de descuento*",
      partnerAlt: () => "Socio oficial CryptoTaxCalculator x Metamask",
      previewAlt: () => "Vista previa del centro de impuestos",
      skip: () => "O usar correo electrónico y contraseña",
      supportsAllWallets: () =>
        "Admite todas sus billeteras, intercambios y actividad en cadena.",
      taxhubPoweredBy: () => "Tax Hub impulsado por",
    },
    onMobileBanner: {
      title: () =>
        "Parece que estás en un dispositivo móvil. Para disfrutar de la mejor experiencia, inicia sesión desde tu computadora de escritorio.",
    },
    onYourWay: {
      title: () => "¡Genial, estás camino a obtener tu informe de impuestos!",
      viewPlans: () => "Ver planes",
      viewReports: () => "Ver informes",
    },
    platformTypes: {
      cex: () => "Intercambios de criptomonedas centralizados",
      count: (d) => "+ " + d.count + " más",
      defi: () => "Actividad en cadena (DeFi y NFT)",
      footerBack: () => "Atrás",
      footerNext: () => "Próximo",
      footerSkip: () => "Saltar",
      subtitle: () =>
        "Personalice su experiencia en función del tipo de comerciante de criptomonedas que sea:",
      title: () => "¿En qué tipos de plataformas has operado?",
    },
    progressBar: {
      confirmCountry: () => "Confirmar país",
      freeTaxPreview: () => "Vista previa de impuestos gratuita",
      importData: () => "Importar datos",
      selectIntegration: () => "Seleccionar integración",
    },
    provider: {
      connect: {
        "2fa": () => "Ingresa tu código 2FA",
        cta: () => "Iniciar sesión y vincular cuenta",
        disclaimer: (d) =>
          "Al iniciar sesión, vinculará su cuenta de " +
          d.provider +
          " con Crypto Tax Calculator, lo que le permitirá iniciar sesión con 1 clic desde Independent Reserve.",
        email: () => "Correo electrónico",
        password: () => "Contraseña",
        subtitle: () =>
          "Parece que ya tienes una cuenta de Calculadora de impuestos de criptomonedas con ese correo electrónico.",
        title: () => "Inicia sesión para continuar",
      },
      setPassword: {
        cta: () => "Guardar contraseña",
        email: () => "Correo electrónico",
        password: () => "Contraseña",
        passwordsDoNotMatch: () => "Las contraseñas no coinciden",
        retypePassword: () => "Vuelva a escribir la contraseña",
        title: () => "Establezca su contraseña",
      },
      setPasswordSuccess: {
        cta: () => "Continuar",
        description: () =>
          "Utilice esta contraseña para iniciar sesión en cryptotaxcalculator.io",
        providerLoginNote: (d) =>
          "Nota: También puedes iniciar sesión siempre con 1 clic desde tu cuenta de " +
          d.provider +
          ".",
        title: () => "¡Contraseña establecida!",
      },
      syncing: {
        bannerCTA: () => "Continúe configurando su cuenta",
        bannerText: (d) =>
          "La importación de sus transacciones " +
          d.provider +
          " podría demorar algún tiempo mientras espera,",
        completeTitle: () => "Transacciones importadas",
        getTaxReportCta: () => "Obtener informe fiscal",
        inProgressTitle: () => "Estamos importando tus transacciones",
        viewTaxSummaryCta: () => "Ver resumen de impuestos",
      },
      welcome: {
        cta: () => "Continuar",
        description: () =>
          "Crypto Tax Calculator es una plataforma todo en uno para calcular sus impuestos sobre criptomonedas. Prepararemos informes fiscales que incluyen:",
        items: {
          0: () => "Ganancias a largo plazo",
          1: () => "Ganancias a corto plazo",
          2: () => "Ingreso",
          3: () => "Gastos",
          4: () => "Deducciones",
        },
        title: () =>
          "Bienvenido a la calculadora de impuestos sobre criptomonedas",
      },
    },
    security: {
      buttonText: () => "Seguridad y privacidad de los datos",
      privacy: {
        description: () =>
          "Nunca le solicitaremos las claves privadas de su billetera de criptomonedas. Nuestras integraciones solo requieren acceso de lectura a sus datos y todas las claves API se almacenan y cifran de forma segura en tránsito y en reposo.",
        title: () => "Privacidad total de datos",
      },
      soc2: {
        description: () =>
          "Contamos con certificación SOC 2 Tipo 2, con mecanismos seguros implementados para garantizar la seguridad de sus datos.",
        title: () => "Certificación SOC 2 Tipo 2",
      },
      subtitle: () => "Nos tomamos muy en serio la seguridad de los datos.",
      title: () => "Tus datos están seguros",
    },
    skipAndEnterApp: () => "Saltar y entrar a la aplicación",
    taxCalculator: {
      buttons: {
        addAnotherAccount: () => "Agregar otra cuenta",
        back: () => "Atrás",
        getTaxReport: () => "Obtener informe fiscal",
      },
      disclaimer: () =>
        "Esta es una estimación basada en los datos importados hasta el momento. Para obtener un resultado preciso, se requiere una imagen completa de todas sus transacciones desde que compró criptomonedas por primera vez.",
      estimatedGain: () => "Ganancia estimada",
      heading: () => "Estimación de ganancias de capital",
      income: () => "Ingreso",
      tooltips: {
        estimatedGain: () =>
          "La ganancia estimada representa sus ganancias totales de criptomonedas, esto se calcula como Ganancias de capital + Ingresos - Pérdidas de capital.",
        gains: () =>
          "Las ganancias que ha obtenido de operaciones que resultaron en ganancias. (Operaciones con las que ganó dinero)",
        income: () =>
          "Ingresos recibidos de actividades criptográficas, generalmente de recompensas por staking",
        losses: () =>
          "Las pérdidas que ha tenido en las operaciones (operaciones en las que perdió dinero)",
        txCount: () => "El número de transacciones en sus cuentas",
      },
      tradingGains: () => "Ganancias comerciales",
      tradingLosses: () => "Pérdidas comerciales",
      transactions: (d) => d.count + " transacciones",
    },
    taxableStaking: {
      content: {
        added: () => "Luego hay que añadir el 98,34 a su",
        assessableIncome: () => "ingreso imponible",
        at: () => "en",
        breakdown: () => "Desglosándolo:",
        rewards: () => "El contrato te recompensa con 0,001 ETH cada mes",
        value: () =>
          "El valor de la recompensa está determinado por el valor de mercado del activo cuando lo recibió.",
        year: () => "Para el año",
        youStake: () => "Usted apuesta 1 Ethereum en un contrato de staking",
      },
      footerBack: () => "Atrás",
      footerNext: () => "Próximo",
      headings: {
        assessableIncome: () => "Ingresos imponibles",
        stakingContract: () => "Contrato de staking",
        stakingRewards: () => "Recompensas por staking",
        yourSalary: () => "Tu salario",
      },
      subtitle: () => "Guía fiscal sobre criptomonedas para:",
      title: () => "Las recompensas por staking son ingresos",
    },
    taxableTrades: {
      content: {
        andYour: () => "Y tu",
        breakdown: () => "Desglosándolo:",
        capitalGain: () => "ganancia de capital",
        costBase: () => "base de costos",
        for: () => "Por los 5 Ethereum que adquiriste ahora son $20,000",
        is15k: () => "Son $15.000",
        is5k: () => "Son $5,000",
        marketValue: () => "valor comercial",
        the: () => "El",
        youTrade: () => "Intercambias 1 Bitcoin por 5 Ethereum",
        your: () => "Su",
        yourBitcoin: () => "para ti 1 Bitcoin son $20,000 y el",
      },
      footerBack: () => "Atrás",
      footerNext: () => "Próximo",
      headings: {
        capitalGain: () => "Ganancia de capital",
        costBase: () => "Base de costos",
        marketValue: () => "Valor comercial",
      },
      subtitle: () => "Guía fiscal sobre criptomonedas para:",
      title: () =>
        "Las transacciones entre criptomonedas están sujetas a impuestos",
    },
    tooltip: {
      accounts: () =>
        "Carteras e intercambios que utilizas para realizar transacciones con criptomonedas.",
      assessableIncome: () =>
        "Ingresos obtenidos que están sujetos a impuestos. Por ejemplo, su salario.",
      capitalGain: () =>
        "El importe obtenido después de vender un activo y deducir la base del costo. ",
      costBase: () =>
        "El precio original que pagó por el activo, más las tarifas de transacción",
      marketValue: () => "El valor del activo en tiempo real.",
    },
    tradingTypes: {
      footerBack: () => "Atrás",
      footerNext: () => "Próximo",
      footerSkip: () => "Saltar",
      subtitle: () =>
        "Personalice su experiencia en función del tipo de comerciante de criptomonedas que sea:",
      title: () => "¿Qué tipos de actividades comerciales ha realizado?",
      types: {
        airdrop: () => "Lanzamientos aéreos",
        liquidityPool: () => "Fondos de liquidez",
        nft: () => "NFT",
        other: () => "Otro",
        staking: () => "Estaca",
        trading: () => "Comercio",
      },
    },
    userValueSurvey: {
      options: {
        accurateReport: () => "Obtener un informe preciso",
        auditProtection: () => "Protección frente a una auditoría",
        minimiseTax: () => "Minimizar mi factura de impuestos",
        other: () => "Otro",
        simplifyTax: () =>
          "Simplificando los impuestos sobre las criptomonedas",
        timeSave: () => "Ahorro de tiempo en la preparación de impuestos",
      },
      title: () =>
        "¿Qué resultado es el más importante para usted al utilizar una solución fiscal criptográfica?",
    },
    viewAssociatedTransaction: (d) =>
      "Ver asociado " +
      plural(d.txCount, 0, es, {
        0: " transacciones ",
        one: " transacción ( " + number("es", d.txCount, 0) + " ) ",
        other: " transacciones ( " + number("es", d.txCount, 0) + " ) ",
      }),
    wallets: {
      address: () => "DIRECCIÓN",
      blockchain: () => "Cadena de bloques",
      continue: () => "Continuar",
      importTransactions: () => "Transacciones de importación",
      subtitle: () =>
        "Vamos a importar transacciones en las siguientes billeteras",
      title: () => "¡Ahoy, usuario de MetaMask!",
    },
  },
  organisation: {
    wentWrongGettingDashboard: () =>
      "Se produjo un error al obtener el panel de control. Inténtalo nuevamente más tarde.",
  },
  outTxCategoryOptionsTitle: () => "Transacciones salientes",
  pageFailed: () => "Algo salió mal. Intente recargar la página.",
  pageTitle: {
    AccountingIntegrations: () => "Integraciones contables",
    Alpha: () => "Características de Alpha",
    BalanceLedger: () => "Libro de balance",
    Billing: () => "Facturación",
    Clients: () => "Clientela",
    ClientsAdd: () => "Agregar cliente",
    Close: () => "Cerca",
    ConnectProvider: () => "Conectar proveedor",
    Contacts: () => "Contactos",
    ContactsIdentified: () => "Contactos identificados",
    ContactsUnidentified: () => "Contactos no identificados",
    Dashboard: () => "Cartera",
    Developer: () => "Banderas de características",
    DeveloperEditorIntegration: () => "Saltar al código",
    DeveloperEntities: () => "Entidades",
    DeveloperReport: () => "Actualización de informes",
    DeveloperSeedData: () => "Datos de la semilla",
    DeveloperSessionRecording: () => "Grabaciones de sesiones",
    DeveloperUserData: () => "Datos del usuario",
    Forgot: () => "Has olvidado tu contraseña",
    ImportCustom: () => "Cuenta personalizada",
    ImportNew: () => "Agregar cuenta",
    ImportSearch: () => "Buscar cuentas",
    Imports: () => "Cuentas",
    Invite: () => "Invitar",
    Ledger: () => "Libro mayor",
    Login: () => "Acceso",
    Onboarding: () => "Incorporación",
    OnboardingAccuracy: () => "Exactitud",
    OnboardingAreYouSure: () => "Está seguro",
    OnboardingCompetitorSurvey: () => "Otro software de impuestos",
    OnboardingEmbededOfferIntro: () => "Bienvenido",
    OnboardingImportCEXReminder: () => "Recordatorio de cambio centralizado",
    OnboardingImports: () => "Cuentas",
    OnboardingImportsCalculating: () => "Calcular importación",
    OnboardingImportsCustom: () => "Cuentas personalizadas",
    OnboardingImportsNew: () => "Nueva cuenta",
    OnboardingImportsOauth: () => "Cuentas OAuth",
    OnboardingImportsSearch: () => "Buscar cuentas",
    OnboardingOnYourWay: () => "En camino",
    OnboardingPayment: () => "Pago",
    OnboardingPaymentStatus: () => "Estado del pago",
    OnboardingPaymentSuccess: () => "Pago exitoso",
    OnboardingPlatformTypes: () => "Tipos de plataformas",
    OnboardingProviderPasswordSetSuccess: () => "Contraseña establecida",
    OnboardingProviderSetPassword: () => "Establecer contraseña",
    OnboardingProviderSyncing: () => "Conectando",
    OnboardingProviderWelcome: () => "Bienvenido",
    OnboardingReconUncategorisedTransactions: () =>
      "Transacciones sin categorizar",
    OnboardingSelectCountry: () => "Seleccione país",
    OnboardingSelectTheme: () => "Seleccionar tema de color",
    OnboardingSelectType: () => "Seleccione el tipo de cuenta",
    OnboardingTaxSettings: () => "Configuración de impuestos de la ATO",
    OnboardingTaxableStaking: () => "Participación sujeta a impuestos",
    OnboardingTaxableTrades: () => "Operaciones sujetas a impuestos",
    OnboardingTradingTypes: () => "Tipos de comercio",
    OnboardingUserValueSurvey: () => "Importante para ti",
    OnboardingWallets: () => "Carteras",
    OptInSupportAccess: () => "Acceso a soporte opcional",
    PartnerBenefit: () => "Beneficio de socio",
    Payment: () => "Pago",
    PaymentPending: () => "Pago pendiente",
    PaymentStatus: () => "Estado del pago",
    PaymentSuccess: () => "Pago exitoso",
    Plans: () => "Planes",
    ReOnboarding: () => "Reincorporación",
    ReOnboardingAccuracy: () => "Exactitud",
    ReOnboardingTaxableStaking: () => "Participación sujeta a impuestos",
    ReOnboardingTaxableTrades: () => "Operaciones sujetas a impuestos",
    ReOnboardingWelcomeBack: () => "Bienvenido de nuevo",
    Reconciliation: () => "Reconciliación",
    ReconciliationMissingBlockchain: () => "Falta la cadena de bloques",
    ReconciliationMissingPriceIssues: () => "Problemas de precios faltantes",
    ReconciliationNegativeBalanceIssues: () => "Problemas de saldo negativo",
    ReconciliationUncategorisedTransaction: () =>
      "Transacciones sin categorizar",
    ReconciliationUnmatchedTransfer: () => "Transferencias sin igual",
    ReferAFriend: () => "Recomendar a un amigo",
    Report: () => "Informe",
    Reset: () => "Restablecer contraseña",
    RestoreUserData: () => "Restaurar datos",
    Rules: () => "Normas",
    Signup: () => "Inscribirse",
    SignupAccountant: () => "Registro de contable",
    TokenLogin: () => "Inicio de sesión con token",
    Transactions: () => "Actas",
    TransactionsAdd: () => "Agregar transacción",
    TransactionsMissingPrice: () => "Transacciones de precios faltantes",
    TransactionsNegativeBalance: () => "Transacciones con saldo negativo",
    TransactionsSearch: () => "Buscar transacciones",
    TransactionsUncategorised: () => "Transacciones sin categorizar",
    Unlock: () => "Desbloquear cuenta",
    Wallets: () => "Carteras",
  },
  partnerEmployeeBenefits: {
    discount: {
      cta: () => "Reclama tu descuento",
      description: () => "100% de descuento en todos los planes",
      title: (d) =>
        "Como empleado de " +
        d.partner +
        ", obtienes un 100% de descuento en tu suscripción de CTC durante un año",
    },
    discountApplied: {
      cta: () => "Ver planes",
      description: () =>
        "Hemos aplicado automáticamente este cupón a tu cuenta. Procede con el pago para canjear este descuento.",
      title: () => "100% de descuento aplicado en todos los planes",
    },
    verifyEmail: {
      confirmEmail: () => "Verifica tu correo electrónico",
      cta: () => "Enviar",
      error400: () =>
        "Parece que ese correo electrónico ya ha reclamado un código de descuento",
      error403: (d) =>
        "Por favor, introduzca un correo electrónico de trabajo válido de " +
        d.partner,
      error500: () => "Algo salió mal, por favor contacte con soporte técnico",
      helperText: (d) =>
        "Debes tener un correo electrónico válido de " +
        d.partner +
        " para reclamar",
      placeholder: (d) =>
        "Introduce el correo electrónico laboral de " + d.partner,
      reEnterEmail: () => "Vuelve a introducir el correo electrónico",
      success: () =>
        "Te hemos enviado un correo electrónico con un enlace que aplicará automáticamente tu código de cupón",
    },
  },
  partnership: {
    officialPartner: () => "SOCIOS OFICIALES",
  },
  password: {
    case: () => "Su contraseña debe contener al menos 1 letra mayúscula",
    long: () => "Su contraseña debe tener menos de 64 caracteres",
    number: () =>
      "Su contraseña debe contener al menos 1 número o carácter especial.",
    short: () => "Su contraseña es débil. Debe contener al menos 8 caracteres.",
  },
  payment: {
    accountantPackage: {
      clientDefault: () => "Empezar",
      color: () => "blue",
      cta: () => "Empezar",
      description: () =>
        "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
      details: {
        0: {
          icon: () => "CheckCircleIcon",
          text: () => "Cubre DeFi, DEX, derivados y staking.",
        },
      },
      points: {
        0: () => "Intercambios ilimitados",
        1: () => "Acceso a todos los informes",
        2: () => "Cubre los años fiscales 2013-2023",
      },
      pointsByCountry: {
        AU: {
          0: () => "Exchanges ilimitados",
          1: () => "Acceso a todos los informes",
          2: () => "Cubre los años fiscales 2013-2023",
          3: () => "Soporte basado en Australia",
        },
      },
      subtitle: () =>
        "Para contables y tenedores de libros que gestionan registros de clientes.",
      title: () => "Contador",
    },
    backToTop: () => "Volver al inicio",
    base: {
      allTicks: {
        0: () =>
          "Todos los planes tienen garantía de devolución de dinero de 30 días.",
        1: () => "Soporte de chat en vivo. ¡Contáctanos si necesitas ayuda!",
        2: () =>
          "La tarifa anual cubre todos los ejercicios económicos desde 2013.",
        3: () =>
          "No hay costo extra por realizar impuestos de años anteriores.",
      },
      cancel: () => "Cancelar",
      cancelAnytime: () => "Cancelar en cualquier momento",
      compare: {
        subtitle: () => "Elige el mejor plan para tus necesidades.",
        title: () => "Comparación de características",
      },
      copy: {
        actions: {
          buy: {
            text: () => "Comprar",
          },
          current: () => "Plan actual",
          getStarted: {
            text: () => "Empezar",
          },
          signUp: {
            text: () => "Comprar ahora",
          },
          upgrade: {
            text: () => "Actualizar a",
          },
          upgradeFor: (d) => "Actualiza por " + d.upgradePrice,
        },
        annually: () => "Anualmente",
        costCurrentPlanDeducted: () => "Costo del plan actual deducido",
        discountAppliedFirstYear: (d) =>
          d.discount + " % de descuento aplicado",
        features: {
          advancedTaxReports: () => "Informes fiscales avanzados",
          auditReport: () => "Informe de auditoría",
          integrations: () => "Integraciones ilimitadas",
          ltfo: () => "Algoritmo de minimización de impuestos",
          onchain: () => "Transacciones automatizadas en cadena",
          portfolioTracking: () => "Seguimiento de cartera",
          report: () =>
            "Informes fiscales ilimitados (TODOS los años fiscales)",
          reportAuthority: (d) =>
            "Informes fiscales ilimitados " +
            d.authority +
            " (TODOS los años fiscales)",
          smartContract: () => "Interacciones de contratos inteligentes",
          support: () => "Apoyo",
          tlh: () => "Herramienta de recolección de pérdidas fiscales",
          tsr: () => "Informe de negociación de acciones",
          txLimit: (d) => d.txLimit + " transacciones",
          xero: () => "Integración con Xero",
        },
        featuresReport: {
          reportAllFinancialYears: () => "Todos los ejercicios económicos",
          reportUnlimited: () => "Informes ilimitados",
        },
        featuresTableCell: {
          experimentSupportEmailChat: () => "Correo electrónico + chat",
          experimentSupportPriority: () => "Prioridad + Asistencia experta",
          integrations: () => "Ilimitado",
          supportEmailChat: () => "Soporte por correo electrónico y chat",
          supportPriority: () => "Soporte prioritario",
        },
        featuresTableHeader: {
          integrations: () => "Integraciones",
          support: () => "Apoyo",
          txLimit: () => "Actas",
        },
        featuresTooltip: {
          advancedTaxReports: () =>
            "Informe de gastos, informe de inventario, informe de operaciones con derivados, informe de obsequios salientes y de activos perdidos o robados",
          auditReport: () =>
            "Un informe que proporciona un registro de los datos importados y todos los cambios realizados, incluyendo la configuración fiscal aplicada.",
          ltfo: () =>
            "El uso de nuestro algoritmo más eficaz desde el punto de vista fiscal podría ahorrarle posibles impuestos",
          tlh: () =>
            "Una herramienta para identificar activos en pérdida con el fin de venderlos antes de que finalice el año fiscal para reducir su ganancia de capital general.",
        },
        firstYear: (d) =>
          "durante el primer año, luego se facturará " +
          d.price +
          " anualmente",
        freeTransactions: () => "+ 10,000 transacciones gratuitas en Coinbase",
        highlightFeatures: {
          "advanced-inventory": () => "Algoritmo de minimización de impuestos",
          "advanced-reports": () => "Informes fiscales avanzados",
          allOtherFeatures: () => "+ todo incluido en el plan Trader",
          comingSoon: () => "Muy pronto",
          contacts: () => "Libreta de direcciones",
          erp: () => "Integraciones contables",
          "lock-tax-year": () => "Bloquear años fiscales",
          "multi-tax-years": () =>
            "Informes fiscales ilimitados para todos los años fiscales",
          prioritySupport: () => "Soporte prioritario",
          rules: () => "Motor de reglas mejorado",
          sidebar: () => "barra lateral",
          "smart-contract-transactions": () =>
            "Transacciones de contratos inteligentes",
          "tax-loss-harvesting": () =>
            "Herramienta de recolección de pérdidas fiscales",
          tips: () => "Consejos",
          "trading-stock-report": () => "Informe de negociación de acciones",
          treasury: () => "Panel de control de la Tesorería",
        },
        planCommonDetails: () =>
          "El plan único cubre todos los años fiscales desde 2013 hasta 2022",
        plans: {
          accountant: {
            clientDataDescription: () =>
              "Brindamos soporte directo a cientos de exchanges, cadenas de bloques y servicios. Solo agregue claves API y direcciones de billetera.",
            clientDataTitle: () =>
              "Obtenga fácilmente datos de clientes a través del intercambio directo e integraciones de blockchain",
            clientDefault: () => "Empezar",
            color: () => "blue",
            description: () =>
              "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
            descriptionFooter: () =>
              "Comience de forma gratuita y comience a pagar solo cuando importe los datos de sus clientes.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Cubre DeFi, DEX, derivados y staking.",
              },
            },
            getStarted: () => "Empieza gratis",
            manageClientsDescription: () =>
              "Nuestro Portal de Contabilidad le permite invitar y administrar clientes, todo desde un panel de control fácil de usar.",
            manageClientsTitle: () =>
              "Gestiona todos tus clientes en un solo lugar",
            points: {
              0: () =>
                "Invita y administra clientes desde tu panel de administración de clientes",
              1: () => "Admite hasta 100.000 transacciones por cliente",
              2: () => "Cubre DeFi, DEX, derivados y staking.",
            },
            subtitle: () =>
              "Gestione fácilmente la declaración de impuestos de criptomonedas de sus clientes con nuestra suite de contabilidad profesional.",
            title: () => "Suite de Contabilidad",
            toolsDemo: () => "Herramientas de contabilidad y demostración",
          },
          advanced: {
            color: () => "purple",
            cta: () => "Comprar ahora",
            description: () =>
              "Desbloquea un algoritmo propietario para maximizar los descuentos a largo plazo y las pérdidas a corto plazo.",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para inversores activos que tienen transacciones complejas.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Cubre DeFi, DEX, derivados, staking y transacciones " +
                  d.txLimit +
                  " .",
              },
            },
            points: {
              0: () => "10.000 transacciones",
              1: () => "Intercambios ilimitados",
              2: () => "Importaciones de carteras",
              3: () => "Staking, Minería, Airdrops, DeFi",
              4: () => "Operaciones con CFD y posiciones largas/cortas",
              5: () => "Operaciones con margen y futuros",
              6: () => "Informe de Impuesto sobre la Renta",
              7: () => "Informe sobre el impuesto a las ganancias de capital",
              8: () => "Soporte de chat en línea",
              9: () => "Cubre los años fiscales 2013-2023",
            },
            subtitle: () =>
              "Para inversores en criptomonedas que han entrado en el mundo de las finanzas descentralizadas",
            title: () => "Inversor",
          },
          business: {
            color: () => "blue",
            cta: () => "Empezar",
            description: () =>
              "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Cubre DeFi, DEX, derivados y staking.",
              },
            },
            points: {
              0: () => "Acceso a todos los informes",
              1: () => "Cubre los años fiscales 2013-2023",
            },
            retailPlansPage: {
              cta: () => "Comprar negocio",
              description: () =>
                "Herramienta precisa de contabilidad de criptomonedas para empresas",
              points: {
                0: {
                  description: () => "Se integra con Xero y Quickbooks",
                  feature: () => "Integraciones contables",
                },
                1: {
                  description: () =>
                    "Realice un seguimiento y administre todas las direcciones y entidades en un solo lugar ",
                  feature: () => "Libreta de direcciones",
                },
                2: {
                  description: () =>
                    "Cree y administre reglas automatizadas para categorizar y mapear transacciones en la importación",
                  feature: () => "Motor de reglas",
                },
                3: {
                  feature: () => "+ Todas las funciones del plan Trader",
                },
              },
              subtitle: () => "Automatice su contabilidad de criptomonedas",
              title: () => "Plan de negocios",
            },
            subtitle: () => "Para empresas web3",
            title: () => "Negocio",
          },
          businessPackage: {
            color: () => "blue",
            cta: () => "Empezar",
            description: () =>
              "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Cubre DeFi, DEX, derivados y staking.",
              },
            },
            points: {
              0: () => "Intercambios ilimitados",
              1: () => "Acceso a todos los informes",
              2: () => "Cubre los años fiscales 2013-2023",
            },
            subtitle: () =>
              "Para contables y tenedores de libros que gestionan registros de clientes.",
            title: () => "Negocio",
          },
          business_pro: {
            caption: () =>
              "¿Más de 10 000 transacciones? Contáctenos para obtener un precio líder en el mercado.",
            color: () => "blue",
            cta: () => "Empezar",
            description: () =>
              "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Cubre DeFi, DEX, derivados y staking.",
              },
            },
            points: {
              0: () => "Intercambios ilimitados",
              1: () => "Acceso a todos los informes",
              2: () => "Cubre los años fiscales 2013-2023",
            },
            subtitle: () => "Para empresas más grandes o más consolidadas",
            title: () => "Profesional de negocios",
          },
          business_starter: {
            color: () => "blue",
            cta: () => "Empezar",
            description: () =>
              "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Cubre DeFi, DEX, derivados y staking.",
              },
            },
            points: {
              0: () => "Intercambios ilimitados",
              1: () => "Acceso a todos los informes",
              2: () => "Cubre los años fiscales 2013-2023",
            },
            subtitle: () => "Para startups y pequeñas empresas",
            title: () => "Iniciador de negocios",
          },
          business_trial: {
            color: () => "blue",
            cta: () => "Empezar",
            description: () =>
              "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Cubre DeFi, DEX, derivados y staking.",
              },
            },
            points: {
              0: () => "Intercambios ilimitados",
              1: () => "Acceso a todos los informes",
              2: () => "Cubre los años fiscales 2013-2023",
            },
            subtitle: () => "Para startups y pequeñas empresas",
            title: () => "Plan de negocios",
          },
          client: {
            collaborator: {
              color: () => "blue",
              cta: () => "Empezar",
              description: () =>
                "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
              details: {
                0: {
                  icon: () => "CheckCircleIcon",
                  text: () => "Cubre DeFi, DEX, derivados y staking.",
                },
              },
              points: {
                0: () => "Intercambios ilimitados",
                1: () => "Acceso a todos los informes",
                2: () => "Cubre los años fiscales 2013-2023",
              },
              subtitle: () =>
                "Para contables y tenedores de libros que gestionan registros de clientes.",
              title: () => "Contador",
            },
            color: () => "blue",
            cta: () => "Empezar",
            description: () =>
              "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Cubre DeFi, DEX, derivados y staking.",
              },
            },
            enterprise: {
              color: () => "blue",
              cta: () => "Empezar",
              description: () =>
                "Solución fiscal criptográfica todo en uno para contables y tenedores de libros.",
              details: {
                0: {
                  icon: () => "CheckCircleIcon",
                  text: () => "Cubre DeFi, DEX, derivados y staking.",
                },
              },
              points: {
                0: () => "Intercambios ilimitados",
                1: () => "Acceso a todos los informes",
                2: () => "Cubre los años fiscales 2013-2023",
              },
              subtitle: () =>
                "Para contables y tenedores de libros que gestionan registros de clientes.",
              title: () => "Contador",
            },
            points: {
              0: () => "Intercambios ilimitados",
              1: () => "Acceso a todos los informes",
              2: () => "Cubre los años fiscales 2013-2023",
            },
            subtitle: () =>
              "Para contables y tenedores de libros que gestionan registros de clientes.",
            title: () => "Contador",
          },
          collaborator: {
            subtitle: () => "colaborador",
            title: () => "Colaborador",
          },
          enterprise: {
            subtitle: () => "empresa",
            title: () => "Empresa",
          },
          expert: {
            color: () => "blue",
            cta: () => "Comprar ahora",
            description: () =>
              "Solución de alta capacidad para traders activos\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para traders intradía con un gran volumen de transacciones.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Admite todos los planes para inversores y hasta " +
                  d.txLimit +
                  " transacciones.",
              },
            },
            points: {
              0: () => "100.000 transacciones",
              1: () => "Todas las funciones para inversores",
              2: () => "Comparar estrategias fiscales",
            },
            subtitle: () =>
              "Para los comerciantes de criptomonedas que desean declarar sus operaciones como un negocio",
            title: () => "Comerciante",
          },
          free: {
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para usuarios que incursionan en el espacio criptográfico.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Admite transacciones " +
                  d.txLimit +
                  " donde los activos se mantienen en intercambios centralizados.",
              },
            },
            subtitle: () => "gratis",
            title: () => "Gratis",
          },
          investorUs: {
            color: () => "grey",
            cta: () => "Comprar ahora",
            description: () =>
              "Para inversores experimentados en criptomonedas",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para los entusiastas de las criptomonedas que utilizan intercambios centralizados.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Admite el almacenamiento de activos en billeteras y hasta " +
                  d.txLimit +
                  " transacciones.",
              },
            },
            points: {
              0: () => "1.000 transacciones",
              1: () => "Integraciones ilimitadas",
              2: () =>
                "Informes fiscales ilimitados para todos los años fiscales",
              3: () => "Integración con TurboTax",
              4: () => "Panel de control de cartera",
              5: () => "Invita a tu contable",
              6: () => "Cálculos automáticos de impuestos DeFi",
              7: () => "Minimización de impuestos",
            },
            subtitle: () => "Para inversores experimentados en criptomonedas",
            title: () => "Inversor",
          },
          investorUsPlus: {
            color: () => "grey",
            cta: () => "Comprar ahora",
            description: () =>
              "Para inversores experimentados en criptomonedas",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para los entusiastas de las criptomonedas que utilizan intercambios centralizados.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Admite el almacenamiento de activos en billeteras y hasta " +
                  d.txLimit +
                  " transacciones.",
              },
            },
            points: {
              0: () => "3.000 transacciones",
              1: () => "Integraciones ilimitadas",
              2: () =>
                "Informes fiscales ilimitados para todos los años fiscales",
              3: () => "Integración con TurboTax",
              4: () => "Panel de control de cartera",
              5: () => "Invita a tu contable",
              6: () => "Cálculos automáticos de impuestos DeFi",
              7: () => "Minimización de impuestos",
            },
            subtitle: () => "Para inversores experimentados en criptomonedas",
            title: () => "Inversionista +",
          },
          pro: {
            subtitle: () =>
              "Para los comerciantes de criptomonedas que desean declarar sus operaciones como un negocio",
            title: () => "Pro",
          },
          rookie: {
            color: () => "grey",
            cta: () => "Comprar ahora",
            description: () =>
              "Solución rentable para que los aficionados ocasionales a las criptomonedas gestionen sin esfuerzo los impuestos sobre las criptomonedas",
            points: {
              0: () => "200 transacciones",
              1: () => "Intercambios ilimitados",
              2: () => "Informe de Impuesto sobre la Renta",
              3: () => "Informe sobre el impuesto a las ganancias de capital",
              4: () => "Cubre los años fiscales 2013-2023",
              5: () => "Soporte de chat en línea",
            },
            subtitle: () =>
              "Para los usuarios de criptomonedas que han comenzado a incursionar en el mundo del trading",
            title: () => "Novato",
          },
          rookie_100: {
            color: () => "grey",
            cta: () => "Comprar ahora",
            description: () =>
              "Solución rentable para que los aficionados ocasionales a las criptomonedas gestionen sin esfuerzo los impuestos sobre las criptomonedas",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para usuarios que incursionan en el espacio criptográfico.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Admite transacciones " +
                  d.txLimit +
                  " donde los activos se mantienen en intercambios centralizados.",
              },
            },
            points: {
              0: () => "100 transacciones",
              1: () => "Lanzamientos aéreos",
              2: () => "Intercambios ilimitados",
              3: () => "Informe de Impuesto sobre la Renta",
              4: () => "Informe sobre el impuesto a las ganancias de capital",
              5: () => "Cubre los años fiscales 2013-2023",
              6: () => "Soporte de chat en línea",
            },
            subtitle: () =>
              "Para los usuarios de criptomonedas que han comenzado a incursionar en el mundo del trading",
            title: () => "Novato",
          },
          standard: {
            color: () => "grey",
            cta: () => "Comprar ahora",
            description: () =>
              "Soporte integral en cadena para DeFi y NFT, con integraciones directas de contratos inteligentes",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para los entusiastas de las criptomonedas que utilizan intercambios centralizados.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Admite el almacenamiento de activos en billeteras y hasta " +
                  d.txLimit +
                  " transacciones.",
              },
            },
            points: {
              0: () => "2.500 transacciones",
              1: () => "Intercambios ilimitados",
              2: () => "Importaciones de carteras",
              3: () => "Lanzamientos aéreos",
              4: () => "Informe de Impuesto sobre la Renta",
              5: () => "Informe sobre el impuesto a las ganancias de capital",
              6: () => "Cubre los años fiscales 2013-2023",
              7: () => "Soporte de chat en línea",
            },
            subtitle: () =>
              "Para los entusiastas de las criptomonedas que utilizan intercambios centralizados y tienden a mantener sus criptomonedas.",
            title: () => "Aficionado",
          },
          standard_100: {
            color: () => "grey",
            cta: () => "Comprar ahora",
            description: () =>
              "Soporte integral en cadena para DeFi y NFT, con integraciones directas de contratos inteligentes",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para los entusiastas de las criptomonedas que utilizan intercambios centralizados.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Admite el almacenamiento de activos en billeteras y hasta " +
                  d.txLimit +
                  " transacciones.",
              },
            },
            points: {
              0: () => "1.000 transacciones",
              1: () => "Intercambios ilimitados",
              2: () => "Importaciones de carteras",
              3: () => "Lanzamientos aéreos",
              4: () => "Informe de Impuesto sobre la Renta",
              5: () => "Informe sobre el impuesto a las ganancias de capital",
              6: () => "Cubre los años fiscales 2013-2023",
              7: () => "Soporte de chat en línea",
            },
            subtitle: () =>
              "Para los entusiastas de las criptomonedas que utilizan intercambios centralizados y tienden a mantener sus criptomonedas.",
            title: () => "Aficionado",
          },
          traderUs: {
            color: () => "blue",
            cta: () => "Comprar ahora",
            description: () =>
              "Para fanáticos experimentados de las criptomonedas\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para traders intradía con un gran volumen de transacciones.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Admite todos los planes para inversores y hasta " +
                  d.txLimit +
                  " transacciones.",
              },
            },
            points: {
              0: () => "10.000 transacciones",
              1: () => "Todas las funciones de Investor+",
              2: () => "Informes fiscales avanzados",
              3: () => "Registro de auditoría",
            },
            subtitle: () =>
              "Para los comerciantes de criptomonedas que desean declarar sus operaciones como un negocio",
            title: () => "Comerciante",
          },
          traderUsPlus: {
            color: () => "blue",
            cta: () => "Comprar ahora",
            description: () =>
              "Para fanáticos experimentados de las criptomonedas\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Para traders intradía con un gran volumen de transacciones.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Admite todos los planes para inversores y hasta " +
                  d.txLimit +
                  " transacciones.",
              },
            },
            points: {
              0: () => "100.000 transacciones",
              1: () => "Todas las funciones de Investor+",
              2: () => "Informes fiscales avanzados",
              3: () => "Registro de auditoría",
            },
            subtitle: () =>
              "Para los comerciantes de criptomonedas que desean declarar sus operaciones como un negocio",
            title: () => "Comerciante +",
          },
        },
        plus: () => "+",
        subtitle: () =>
          "Pague el impuesto más bajo y obtenga los informes más precisos con CTC.",
        subtitleAccountant: () =>
          "Administre fácilmente las declaraciones de impuestos sobre criptomonedas de sus clientes con nuestra suite de contabilidad profesional",
        tabTitle: () =>
          "Calculadora de impuestos sobre criptomonedas para empresas",
        title: () => "Elige tu plan",
        titleAccountants: () => "Suite de contabilidad",
        usExperimentPlanDescription: {
          investorUs: () => "Para inversores experimentados en criptomonedas ",
          investorUsPlus: () =>
            "Para inversores experimentados en criptomonedas ",
          rookie_100: () => "Para aquellos que recién comienzan",
          traderUs: () => "Para fanáticos experimentados de las criptomonedas",
          traderUsPlus: () =>
            "Para fanáticos experimentados de las criptomonedas",
        },
        usExperimentPlanTitle: {
          investorUs: () => "Inversor",
          investorUsPlus: () => "Inversionista +",
          rookie_100: () => "Novato",
          traderUs: () => "Comerciante",
          traderUsPlus: () => "Comerciante +",
        },
        usExperimentPlanTxCount: {
          investorUs: {
            hasPlusTxs: () => "3.000",
            txCount: () => "1.000",
          },
          rookie_100: {
            hasPlusTxs: () => "0",
            txCount: () => "100",
          },
          traderUs: {
            hasPlusTxs: () => "100.000+",
            txCount: () => "10.000",
          },
        },
        usUserExperimentFeatures: {
          accountantInvite: () => "Invita a tu contable",
          advancedTaxReports: () => "Informes fiscales avanzados",
          auditLog: () => "Registro de auditoría",
          dashboard: () => "Panel de control de cartera",
          defiTaxAutomation: () => "Cálculos automáticos de impuestos DeFi",
          integrations: () => "Integraciones ilimitadas",
          support: () => "Apoyo",
          taxMinimization: () => "Minimización de impuestos",
          taxYearReports: () =>
            "Informes fiscales ilimitados para todos los años fiscales",
          turboTaxIntegration: () => "Integración con TurboTax",
        },
        usUserExperimentFeaturesTooltip: {
          accountantInvite: () =>
            "Colabore con su asesor fiscal. Los contables obtienen acceso a CTC sin costo adicional.",
          advancedTaxReports: () =>
            "Informes precisos para todos los escenarios fiscales en gastos, inventario, comercio de derivados, donaciones salientes y activos perdidos o robados.",
          auditLog: () =>
            "Reduzca el riesgo de auditorías con un registro completo de los datos que se importaron y los cambios que se les realizaron.",
          dashboard: () =>
            "Gane confianza en los números con una vista fácil de usar de toda su cartera.",
          defiTaxAutomation: () =>
            "Nosotros hacemos el trabajo pesado para que usted no tenga que hacerlo.Evaluación automática de las implicaciones fiscales en toda su actividad en cadena y contratos inteligentes.",
          integrations: () =>
            "Importa todas tus billeteras y fuentes en minutos.",
          support: () =>
            "No lo haga solo. Nuestro equipo de expertos en cumplimiento de las normas criptográficas estará encantado de ayudarle.",
          taxMinimization: () =>
            "No gaste demasiado en impuestos, con el algoritmo de minimización de impuestos patentado de CTC, la capacidad de elegir el método de inventario más eficiente y la recolección de pérdidas fiscales.",
          taxYearReports: () =>
            "No hay costes adicionales por informes de años anteriores. *Tos tos* a diferencia de nuestros competidores.",
          turboTaxIntegration: () =>
            "CTC se conecta con las versiones de navegador y de escritorio de TurboTax, para que usted pueda enviar sus impuestos con facilidad.",
        },
        validFirstYear: () => "Válido para tu primer año de suscripción",
        wasPrice: (d) => "era " + d.price,
      },
      currency: () => "Dólar estadounidense",
      currencySymbol: () => "$",
      email: () => "Correo electrónico",
      guarantee: () => "Garantía de devolución de dinero de 30 días",
      loading: () => "Tratamiento...",
      payNow: () => "Pagar ahora",
      period: () => "año",
      plan: () => "Plan",
      planDisabled: () =>
        "El número de transacciones de este cliente ha excedido el límite para este plan.",
      title: () => "Planes de precios",
    },
    billedYearly: () => "Facturación anual",
    billing: {
      chips: {
        cancelledChip: (d) => "Cancelado: Expira el " + d.date,
        renewChip: (d) => "Renovar el: " + d.date,
        trialEndsChip: (d) => "El ensayo finaliza: " + d.date,
      },
      cta: {
        cancelPlan: () => "Cancelar plan",
        reSubscribe: () => "Volver a suscribirse",
      },
      footer: {
        client: {
          cancelledPlanFooter: (d) => "Este plan no se renovará el " + d.date,
          estimatedPaymentFooter: (d) =>
            "Su próximo pago estimado para este cliente será " +
            d.amount +
            " el " +
            d.date,
          estimatedPaymentFooterWithTrial: (d) =>
            "Su próximo pago estimado para este cliente será " +
            d.amount +
            " el " +
            d.date +
            " , después de que finalice la prueba",
        },
      },
      lineItems: {
        billingCycle: () => "Ciclo de facturación:",
        couponWithAmount: (d) =>
          d.couponId + " ( " + d.amountOff + " % de descuento)",
        planPrice: () => "Precio del plan:",
        totalDue: () => "Total adeudado:",
        totalDueDate: (d) => "Total a pagar el " + d.date + " :",
      },
      payment: {
        activeCard: () => "Tarjeta activa:",
        changePayment: () => "Cambiar método de pago",
        discount: (d) => d.amount + " % de descuento",
        end: () =>
          " Anualmente hasta que canceles tu suscripción. Puedes cancelarla en cualquier momento.",
        endWithDate: (d) =>
          " el " +
          d.date +
          " y luego anualmente en esa fecha hasta que canceles la suscripción, puedes cancelar en cualquier momento.",
        start: () => "Se le cobrará ",
      },
    },
    cancelled: {
      button: () => "Cancelado",
      tooltip: (d) =>
        "El plan " +
        d.plan +
        " ha sido cancelado. Permanecerá activo hasta el final del período de facturación actual.",
    },
    coupon: {
      unknownCoupon: () => "Oferta exclusiva",
    },
    currentPlan: () => "El plan actual del cliente",
    disclaimer: () =>
      "*Todos los planes pueden ser deducibles de impuestos cuando se incurre en ellos como un gasto relacionado con la gestión de sus propios asuntos fiscales.",
    downgradeWarning: () =>
      "Este plan no está disponible porque ya ha utilizado funciones de un plan de nivel superior durante este período de facturación.",
    enterprise: {
      demoCTA: {
        badge: () => "Primera oferta para el cliente",
        button: () => "Programar una demostración",
        newRelease: {
          badge: () => "Nueva oferta de lanzamiento",
          ctaLink: () => "Programe una llamada con nosotros",
          ctaText: () => "Si tiene alguna pregunta o desea una demostración",
          subtitle: () =>
            "Empieza a utilizar un cliente empresarial de forma gratuita, sin límites ni restricciones. Después de 30 días, nos pondremos en contacto contigo para hacer un check-in.",
          title: () => "¡30 días gratis!",
        },
        subtitle: () =>
          "Permítanos ayudarlo a comenzar con su primer cliente comercial. Programe una demostración con nuestro equipo.",
        title: () => "Prueba gratuita de 30 días para tu primer cliente.",
      },
    },
    excludes: () => "Excluye:",
    freeTx: {
      coinbase: (d) => d.freeTxCount + " transacciones gratuitas de Coinbase",
      coinjar: (d) => d.freeTxCount + " transacciones gratuitas de CoinJar",
      default: (d) => d.freeTxCount + " transacciones gratuitas",
      phantom: (d) => d.freeTxCount + " transacciones gratuitas de Phantom",
    },
    haveSmartContractTx: () =>
      "Tienes transacciones de contratos inteligentes.",
    ignoredTx: (d) =>
      d.ignoredTxCount + " transacciones marcadas como spam o ignoradas",
    moneyBackGuarantee: () => "Garantía de devolución de dinero de 30 días",
    moneyBackGuaranteeLower: () =>
      "Garantía de devolución de dinero de 30 días",
    mostPopular: () => "Los más populares",
    paymentCycle: {
      annual: () => "Anualmente",
      monthly: () => "Mensual",
    },
    paymentCycleShort: {
      annual: () => "Pensilvania",
      monthly: () => "p.m",
    },
    perMonthBilledYearly: () => "Por mes, facturado anualmente",
    promoBanner: {
      applied: () => "Aplicado",
      bitcoinDotComAu: {
        0: () => "Descuento del 30% en planes de pago",
      },
      coinbase: {
        0: () => "10.000 transacciones gratuitas en Coinbase",
        1: () => "30% de descuento en planes pagos",
      },
      coinbaseone: {
        otherPlans: () =>
          "40% de descuento en planes para aficionados, inversores y comerciantes",
        rookie: (d) => d.price + " Plan novato",
      },
      coinbaseonepremium: {
        0: () => "50% de descuento en el nivel premium de CTC, Trader",
        1: () =>
          "40% de descuento en planes para novatos, aficionados e inversores",
      },
      coinjar: {
        0: () => "1000 transacciones gratuitas en CoinJar",
        1: () => "30% de descuento en planes pagos",
      },
      coinstats: () => "de tu primer año con CTC",
      independentReserve: {
        0: () => "Descuento del 30% en planes de pago",
      },
      metamask: {
        0: () => "30% de descuento en tu primer año",
      },
      partnerOffer: () => "Oferta oficial de socio",
      phantom20: {
        0: () => "20% de descuento en planes de pago (solo primer año)",
        1: () => "200 transacciones gratuitas de la cartera Phantom",
      },
      referAFriend: {
        0: () => "20% de descuento en todos los planes minoristas",
      },
      referralOffer: () => "Oferta de referidos",
    },
    renewFailed: {
      bannerTitle: () => "¡Oh no! No pudimos procesar tu pago.",
      renewError: () =>
        "Por favor verifique su método de pago predeterminado y que tenga fondos suficientes para cubrir su factura.",
      renewPlan: () => "Plan de renovación",
      renewSuccess: () => "Su suscripción se ha renovado con éxito",
      settingText: () =>
        "Para generar informes, renueva tu plan. Actualiza tus datos de pago procediendo a gestionar pagos.",
    },
    save20: () => "Ahorre 20%",
    taxDeductible: () => "Deducible de impuestos*",
    tooManyTx: () => "Tiene demasiadas transacciones para este plan",
    totalTxs: () => "Transacciones totales",
    txCount: {
      prefix: () => "Tienes",
      suffix: () => "transacciones facturables",
    },
    txCountFiltered: (d) =>
      "Tienes <s> " +
      d.filtered +
      " </s> <span> " +
      d.count +
      " </span> transacciones",
    viewAccountant: () => "Ver plan contable",
    viewBusinessPlan: () => "Ver plan de negocios",
    viewIndividualPlan: () => "Ver planes individuales",
    yourOffers: () => "Tus ofertas",
  },
  paymentPortal: {
    cardDetailsLabel: () => "Datos de la tarjeta*",
    dialogLabel: () => "Pago",
    payPrefixText: () => "Pagar",
    paymentError: () => "Error al procesar el pago",
  },
  paymentStatus: {
    pending: {
      subtitle: () =>
        "Si el problema tarda demasiado, prueba a actualizar la página. Ponte en contacto con el servicio de asistencia si el problema persiste.",
      title: () => "Procesando pago...",
    },
    success: {
      buttonText: () => "Obtenga su informe fiscal",
      subtitle: () => "Gracias por elegir Crypto Tax Calculator.",
      title: () => "¡Compra exitosa!",
    },
  },
  paywallBanner: {
    cancel: () => "Cancelar",
    implementations: {
      accountant: {
        enforceUpgrade: {
          buttonText: () => "Plan de actualización",
          subtitle: () =>
            "Pídale a su cliente que actualice su plan para descargar informes fiscales",
          subtitleMulti: () => "Su informe incluye:",
          title: (d) =>
            "Su cliente ha excedido el límite de transacciones actual en su plan ( " +
            d.count +
            " de " +
            d.limit +
            " )",
          titleMulti: () =>
            "Su cliente ha excedido los límites de su plan al tener:",
        },
        renewalFailed: {
          buttonText: () => "Administrar suscripción",
          subtitle: () =>
            "Pídale a su cliente que resuelva el problema de pago para descargar los informes de impuestos.",
          title: () => "¡Oh, no! No pudimos procesar el pago de tu cliente.",
        },
        signUp: {
          buttonText: () => "Inscribirse",
          subtitle: () =>
            "Para generar un informe fiscal, deberá actualizar su cliente al siguiente plan.",
          title: () => "¿Quieres generar un informe fiscal para tu cliente?",
        },
      },
      standard: {
        enforceUpgrade: {
          buttonText: () => "Plan de actualización",
          subtitle: () => "Actualice su plan para descargar informes fiscales",
          subtitleMulti: () => "Su informe incluye:",
          title: (d) =>
            "Ha excedido el límite de transacciones actual en su plan ( " +
            d.count +
            " de " +
            d.limit +
            " )",
          titleMulti: () => "Has excedido los límites de tu plan al tener:",
        },
        renewalFailed: {
          buttonText: () => "Administrar suscripción",
          subtitle: () =>
            "Verifique su suscripción y detalles de pago para corregir el problema.",
          title: () => "¡Oh no! No pudimos procesar tu pago.",
        },
        signUp: {
          buttonText: () => "Plan de actualización",
          subtitle: () =>
            "Para generar un informe fiscal, deberá actualizar al siguiente plan.",
          title: () => "¿Quieres generar un informe fiscal?",
        },
      },
    },
    paywallReasons: {
      importCount: (d) => "Más de " + d.importLimit + " importaciones",
      tradeType: () => "Transacciones de contratos inteligentes",
      txCount: (d) => "Más de " + d.txLimit + " transacciones",
    },
    reportTable: () => "Actualice para acceder a la tabla de informes",
    upgradeFor: () => "Actualizar para",
    upgradeNow: () => "Actualizar ahora",
  },
  paywallModal: {
    cancel: () => "Cancelar",
    downloadSample: () => "Descargar informe de muestra",
    implementations: {
      advancedReports: {
        prompt: () => "Desbloquee estos informes avanzados: ",
        title: () => "Ver la imagen completa",
      },
      allReports: {
        prompt: () => "Desbloquea estos informes: ",
        title: () =>
          "Suscríbete para acceder a todos los informes y formularios fiscales",
      },
      inventory: {
        prompt: () => "Actualice al plan para inversores para",
        title: () => "Optimice su posición fiscal",
      },
      multiTaxYears: {
        subtitle: () =>
          "Actualice a un plan pago para acceder a los informes correspondientes a todos los años fiscales que necesite.",
        title: () => "Varios años fiscales, una suscripción",
      },
      paywall: {
        subtitle: () => "Actualice para obtener acceso a sus informes.",
        title: () => "Has excedido las inclusiones de tu plan",
      },
      smartContractInteraction: {
        banner: {
          subText: () => "Transacciones de contratos inteligentes",
          text: () => "Su informe incluye:",
          title: () => "Se requiere actualización",
        },
        subtitle: () =>
          "Actualice al plan para aficionados para obtener soporte para todo tipo de DeFi, actividad compleja en cadena e importaciones ilimitadas.",
        title: () => "Libera tu degeneración interior",
      },
      tips: {
        subtitle: () =>
          "Actualice a un plan pago para acceder a consejos que pueden ayudarlo a mejorar la precisión de sus informes y ahorrar en su factura de impuestos.",
        subtitleReview: () =>
          "Actualice a un plan pago para acceder a nuestro proceso de revisión guiada, que puede ayudarlo a mejorar la precisión de su informe y ahorrar en su factura de impuestos.",
        title: () => "Suscríbete para obtener oportunidades de ahorro",
      },
      tradingStockReport: {
        subtitle: () =>
          "Actualice al plan Trader para obtener acceso al Informe de acciones de Trader",
        title: () => "Flexibilidad de informes profesionales",
      },
    },
    irsModal: {
      dateString: (d) =>
        d.days + " días : " + d.hours + " horas : " + d.minutes + " mins",
      irsTaxSubmissionDeadline: () =>
        "Fecha límite de presentación de impuestos del IRS",
    },
    irsVariantChoosePlan: {
      cta: () => "Proceder al pago",
      secondary: () => "Comparar planes",
      title: () => "Actualiza tu plan.\nDesbloquea ahorros.",
      txCount: (d) =>
        "Tienes " +
        plural(d.txCount, 0, es, {
          one: "1 transacción",
          other: number("es", d.txCount, 0) + " transacciones",
        }),
      txSubtitle: (d) => "Hasta " + d.txCount + " transacciones",
    },
    irsVariantPreSelectedPlan: {
      cta: () => "Adquirir plan",
      disclaimerOne: () => "Garantía de devolución de 30 días",
      disclaimerTwo: () => "*El descuento aplica solo al primer año",
      discountApplied: () => "Descuento aplicado",
      features: {
        0: {
          description: () => "Mantente al día con las autoridades fiscales",
          title: () => "Informes fiscales preparados para auditorías",
        },
        1: {
          description: () =>
            "Obtén consejos para reducir legalmente tu factura fiscal",
          title: () => "Desbloquea ahorros fiscales",
        },
        2: {
          description: () =>
            "Te guiaremos durante el proceso para garantizar que tu informe sea preciso",
          title: () => "No lo hagas solo",
        },
      },
      link: () => "Comparar planes",
      price: (d) =>
        d.price +
        plural(d.discount, 0, es, {
          0: "",
          other: " (ahorra " + number("es", d.discount, 0) + "%)*",
        }),
      title: () => "Actualiza tu plan y desbloquea todas las funcionalidades",
    },
    irsVariantViewPlans: {
      cta: () => "Ver planes",
      metamaskOffer: () => "30% de descuento en todos los planes",
      reportsReady: () => "Tus informes están listos",
    },
    modalText: {
      "annex-so-other-income": () =>
        "Descargue un informe alemán de muestra: Anlage So (Sonstige Einkünfte)",
      audit: () => "Descargar muestra del Informe de Transacción de Auditoría",
      bgl: () => "Descargar muestra del XML del BGL Simple Fund 360",
      "capital-gains": () =>
        "Descargar muestra del Informe de Ganancias de Capital",
      "capital-gains-nz": () =>
        "Descargar muestra del Informe de ganancias/pérdidas realizadas",
      cerfa2086: () => "Descargar modelo de formulario n°2086",
      expense: () => "Descargar muestra de Informe de Gastos Varios",
      "gift-and-lost": () =>
        "Descargar muestra del Informe de obsequios salientes y bienes perdidos o robados",
      income: () => "Descargar muestra del Informe de Ingresos",
      inventory: () => "Descargar muestra de Informe de inventario",
      "inventory-v2": () => "Descargar muestra de Informe de inventario",
      "irs-1040-s1": () =>
        "Descargar muestra del Anexo 1 del IRS (Formulario 1040)",
      "irs-1040-sd": () => "Descargar muestra del Anexo D (Formulario 1040)",
      "irs-8949": () => "Descargar muestra del formulario 8949 del IRS",
      "irs-8949-consolidated": () =>
        "Descargar muestra del Formulario 8949 (Consolidado) del IRS",
      "irs-8949-long": () =>
        "Descargar muestra del Formulario 8949 de largo plazo",
      "irs-8949-long-consolidated": () =>
        "Descargar muestra del Formulario 8949 a Largo Plazo (Consolidado)",
      "irs-8949-short": () =>
        "Descargar muestra del Formulario 8949 Corto Plazo",
      "irs-8949-short-consolidated": () =>
        "Descargar muestra del Formulario 8949 Consolidado a Corto Plazo",
      "modelo-100": () => "Descargar muestra del Informe Modelo 100",
      mygov: () => "Descargar muestra del informe MyGov",
      "nft-capital-gains": () => "Ejemplo de informe NFT descargado",
      "nft-report": () => "Descargar muestra del informe NFT",
      pack: () => "Descargar informes de muestra",
      "report-summary": () => "Descargar muestra del resumen del informe",
      "start-of-year-holdings": () =>
        "Descargar muestra del Informe de tenencias de inicio de año",
      "tax-lots": () =>
        "Descargar muestra del informe de resumen de inventario",
      "taxact-csv": () =>
        "Descargar muestra de TaxAct - Informe de ganancias de capital",
      "trading-pnl": () =>
        "Descargar muestra del informe de operaciones con derivados",
      "trading-stock": () =>
        "Descargar muestra del informe de negociación de acciones",
      transaction: () => "Descargar muestra del Informe de Transacciones",
      "turbotax-canadian-pdf": () => "Descargar muestra del PDF de TurboTax",
      "turbotax-csv": () =>
        "Descargar muestra del Informe de Ganancias de Capital de TurboTax",
      "turbotax-txf": () =>
        "Descargar muestra del informe de ganancias de capital de TurboTax TXF",
      "wiso-steuer": () => "Descargue un ejemplo de informe CSV de WISO Steuer",
    },
    reportTable: () => "Actualice para acceder a la tabla de informes",
    upgradeFor: () => "Actualizar para",
    upgradeNow: () => "Actualizar ahora",
  },
  pending: () => "Pendiente",
  planCard: {
    annualSubscription: () => "Suscripción anual",
    bestValue: () => "Los más populares",
    billedYearly: () => "facturado anualmente",
    taxDeductible: () => "Deducible de impuestos",
  },
  planFeatures: {
    coreFeatures: {
      features: {
        assetHistoryLedgers: () =>
          "Libros contables de historial de activos de partida doble completos",
        deFiDEX: () => "Integraciones DeFi y DEX",
        fileImports: () => "API de Exchange e importaciones CSV",
        marginTrades: () => "Operaciones con margen y futuros",
        miningStaking: () => "Minería, staking, préstamos y airdrops",
        nftTrading: () => "Comercio de NFT",
        smartErrorSuggestions: () =>
          "Sugerencias de manejo inteligente de errores",
        transanctionCategorisation: () =>
          "Algoritmo de categorización automática de transacciones",
        transferMatching: () => "Coincidencia de transferencias inteligente",
        walletSync: () => "Sincronización de billetera en cadena",
      },
      name: () => "Funciones avanzadas",
    },
    dynamic: {
      features: {
        complexTransactions: () => "Transacciones complejas en cadena",
        integrations: () => "Integraciones",
        inventoryAdvanced: () => "Métodos avanzados de inventario",
        portfolioTracking: () => "Seguimiento de cartera",
        reportPreview: () => "Vista previa del informe gratuito",
        reportsAdvanced: () => "Informes fiscales avanzados",
        reportsPrevYears: () => "Informes de años fiscales anteriores",
        supportEmailChat: () => "Soporte por correo electrónico y chat",
        supportPriority: () => "Soporte prioritario",
        taxLossHarvesting: () =>
          "Herramienta de recolección de pérdidas fiscales",
        transactions: () => "Transacciones en los informes fiscales",
      },
      name: () => "Características básicas",
    },
    integrations: {
      features: {
        blockchains: () => "Cadenas de bloques",
        centralisedExchanges: () => "Intercambios centralizados",
        decentralisedExchanges: () => "Intercambios descentralizados",
        nftMarketplaces: () => "Mercados de NFT",
        smartContract: () => "Soporte de contratos inteligentes",
      },
      name: () => "Integraciones",
    },
    smartSuggestions: {
      features: {
        airdropDetection: () => "Detección de lanzamiento aéreo",
        balanceVerification: () => "Verificación automática de saldo",
        incorrectCategoristion: () => "Detección de categorización incorrecta",
        integration: () => "Sugerencias de integración",
        negativeBalance: () => "Faltan advertencias del historial de compras",
        spamDetection: () => "Detección de tokens de spam",
        transactionCategory: () => "Sugerencias de categorías de transacciones",
        transferMatching: () => "Sugerencias de transferencia coincidentes",
      },
      name: () => "Sugerencias inteligentes",
    },
    support: {
      features: {
        chat: () => "Soporte por chat",
        email: () => "Soporte por correo electrónico",
        priority: () => "Soporte prioritario",
      },
      name: () => "Apoyo",
    },
    transactionsManagement: {
      features: {
        advancedFiltering: () => "Filtrado avanzado",
        bulkEdit: () => "Funcionalidad de edición masiva",
        noteTaking: () => "Toma de notas",
        protocolIdentification: () =>
          "Identificación y etiquetado del protocolo",
        quickSearch: () => "Búsqueda rápida",
      },
      name: () => "Gestión de transacciones",
    },
  },
  plans: {
    personal: () => "Personal",
    professional: () => "Contador",
  },
  "polygon-zkevm": {
    "wallet-api": {
      0: () =>
        'Ingresa la dirección de tu billetera Polygon Hermez (zkEVM) en la Calculadora de impuestos criptográficos, proporciona un apodo opcional y haz clic en "Agregar billetera".',
      1: {
        items: {
          0: () =>
            'Puede agregar varias billeteras ingresando otra dirección y haciendo clic en "Agregar billetera adicional"',
        },
        type: () => "nota",
      },
    },
  },
  popup: {
    enablePopups: () => "Habilite las ventanas emergentes para continuar",
  },
  portfolioToggles: () => "Alternancias de cartera",
  qbo: {
    accountMapping: {
      accounts: {
        asset: {
          title: () => "Cuenta de activos",
          tooltip: () =>
            "Cuenta que representa el valor de los activos en la cartera del usuario",
        },
        cash: {
          title: () => "Cuenta de efectivo",
          tooltip: () =>
            "Cuenta que representa los fondos en efectivo. Si vende criptomonedas, representaremos los ingresos en efectivo aquí y viceversa cuando compre criptomonedas. Las transacciones entre criptomonedas no tendrán ningún impacto en esta cuenta.",
        },
        expense: {
          title: () => "Cuenta de gastos",
          tooltip: () => "Cuenta que representa gastos en criptomonedas",
        },
        fee: {
          title: () => "Cuenta de honorarios",
          tooltip: () =>
            "Cuenta que representa el importe pagado en concepto de honorarios",
        },
        income: {
          title: () => "Cuenta de ingresos",
          tooltip: () =>
            "Cuenta que representa ingresos provenientes de criptomonedas",
        },
        loan: {
          title: () => "Cuenta de préstamo",
          tooltip: () =>
            "Cuenta que representa préstamos y reembolsos de criptomonedas",
        },
        loss: {
          title: () => "Cuenta de pérdidas",
          tooltip: () =>
            "Cuenta que representa la cantidad de criptomonedas perdidas",
        },
        "realised-gain": {
          title: () => "Cuenta de ganancia realizada",
          tooltip: () =>
            "Cuenta que representa las ganancias que se han obtenido por la enajenación de criptoactivos",
        },
        "realised-loss": {
          title: () => "Cuenta de pérdidas realizadas",
          tooltip: () =>
            "Cuenta que representa las pérdidas que se han producido por la enajenación de criptoactivos",
        },
        "zero-cost-buy": {
          title: () => "Falta cuenta de historial de compras",
          tooltip: () =>
            "Cuando se compra u obtiene un activo y no se realiza ningún pago por él, consideramos que la base de costo del activo es el valor de mercado y esta cuenta se utiliza para equilibrar el otro lado del diario.",
        },
      },
      lastSynced: (d) => "Última sincronización " + d.date,
      refreshInstructions: () =>
        "Si ha creado una nueva cuenta en QuickBooks y no la ve, actualice la lista.",
      syncError: () => "No pudimos sincronizar los datos.",
      syncInstructions: () =>
        "Para comenzar a sincronizar, debe elegir a qué cuentas se asignarán los datos. Puede actualizar las cuentas en cualquier momento. La sincronización se puede iniciar cuando se configura la asignación.",
      title: () => "Mapeo de cuentas",
    },
    buttons: {
      disconnect: () => "Desconectarse de QuickBooks",
      refreshList: {
        completed: () => "Lista de cuentas de QuickBooks actualizada",
        done: () => "Actualizar lista de cuentas",
        pending: () => "Lista de actualización",
        tooltip: {
          done: () => "Actualizar cuentas disponibles",
          pending: () => "Esto puede tomar algún tiempo",
        },
      },
      syncAccounts: {
        done: () => "Sincronizar cuentas",
        pending: () => "Sincronización de cuentas",
        tooltip: {
          disabled: () =>
            "Asegúrese de que cada cuenta esté seleccionada para habilitar la sincronización",
          done: () => "Sincronizar cuentas con QuickBooks",
          pending: () => "Esto puede tomar algún tiempo",
        },
      },
    },
    connect: () => "Conectarse a QuickBooks",
    errors: {
      disconnecting: () => "Algo salió mal al desconectar QuickBooks.",
      fetchingAccounts: () =>
        "Algo salió mal al obtener la lista de cuentas de QuickBooks.",
      fetchingSettings: () =>
        "Algo salió mal al obtener la configuración de QuickBooks.",
      fetchingTenants: () =>
        "Algo salió mal al obtener la lista de inquilinos de QuickBooks.",
      updatingAccounts: () =>
        "Algo salió mal al actualizar las cuentas de QuickBooks.",
      updatingTenant: () =>
        "Algo salió mal al actualizar el inquilino de QuickBooks.",
    },
    integrationName: () => "QuickBooks en línea",
    integrationShortName: () => "OBC",
    newIntegration: {
      connected: () => " Conectado",
      connectedAt: (d) => "Última conexión " + d.date,
      description: () =>
        "Resuma las actividades comerciales diarias en registros contables dentro de QuickBooks. Esto puede ayudarlo a realizar un seguimiento de sus finanzas y comprender las fuentes de ingresos, pérdidas y gastos. Las asignaciones de cuentas se pueden personalizar para que coincidan con la forma en que administra su contabilidad.",
      disconnectConfirmation: () =>
        "¡Advertencia! ¿Está seguro de que desea desconectar su cuenta de QuickBooks? Esta acción no se puede deshacer; deberá volver a seleccionar sus cuentas en el futuro. Los diarios sincronizados permanecerán en QuickBooks.",
      disconnected: () => "Desconectado",
      status: () => "Estado: ",
      title: () => "Conectar cuenta de QuickBooks",
    },
    reportFooter: {
      settingsLink: () => "Ver configuración para más información",
      subTitle: () =>
        "Conecte la calculadora de impuestos de criptomonedas con QuickBooks",
      title: () => "Integración con QuickBooks",
    },
    rollup: {
      day: () => "Día",
      description: {
        day: () =>
          "Todas las transacciones de un día calendario determinado se agruparán en un único diario.",
        month: () =>
          "Todas las transacciones de un mes calendario determinado se agruparán en un único diario.",
        none: () =>
          "Todas las transacciones se exportarán como diarios individuales.",
      },
      month: () => "Mes",
      none: () => "Sin rollup",
    },
    syncDialog: {
      confirmation: () =>
        "¿Está seguro de que desea sincronizar? Asegúrese de que sus datos sean correctos. Las modificaciones que se realicen en las transacciones que ya se hayan sincronizado no se reflejarán en QuickBooks.",
      error: () => "¡Oh, no! Parece que algo salió mal...",
      syncing: () =>
        "Estamos sincronizando datos en este momento, esto puede llevar algún tiempo...",
    },
    tenantList: {
      ensureCorrectTenant: () =>
        "Asegúrese de que se haya seleccionado la cuenta correcta para la fuente de datos.",
      exportJournals: () => "Revistas de exportación",
      listPlaceholder: () => "Seleccionar fuente de datos",
      listTitle: () => "Cuenta QuickBooks",
    },
  },
  reOnboarding: {
    sync: {
      ExchangeBlockchain: () => "Intercambio/cadena de bloques",
      lastSynced: () => "Última sincronización",
      noImport: () =>
        "No se detectó ningún intercambio ni blockchain, agrega tu primera cuenta para comenzar",
    },
    welcomeBack: {
      afterSyncBanner: () =>
        "La sincronización puede llevar algún tiempo, mientras tanto, repase las reglas tributarias clave e ingrese a la aplicación.",
      brushUp: () => "Repase las normas fiscales clave",
      integrationsPage: () => "Página de integraciones",
      letMeIn: () => "Solo dejame entrar",
      subTitle: () =>
        "Antes de volver a hacerlo, recuerda volver a sincronizar tus cuentas e importar cualquier cuenta nueva. Aquí tienes una lista de tus exchanges y cadenas de bloques:",
      sync: () => "Sincronizar",
      syncFailedBanner: () =>
        "Algunas sincronizaciones de integración han fallado, para resolver estos problemas proceda a la",
      title: () =>
        "Bienvenido de nuevo mi viejo amigo, es hora de hacer impuestos nuevamente.",
    },
  },
  reconciliation: {
    allTransactions: () => "Todas las transacciones",
    api: {
      assignBlockchainSource: () =>
        "Algo salió mal al asignar la cadena de bloques. Inténtalo de nuevo.",
      bulkLookupMarketPriceError: () =>
        "Se produjo un error al buscar estas monedas. Inténtalo nuevamente.",
      generateTransferError: () =>
        "Algo salió mal al generar transferencias. Inténtalo nuevamente.",
      identifyUnknownParty: () =>
        "Se produjo un error al reemplazar la dirección desconocida. Inténtalo nuevamente.",
      ignoreAllMissingPriceError: () =>
        "Algo salió mal al ignorar el problema de precios de mercado faltantes. Inténtalo de nuevo.",
      ignoreError: () =>
        "Algo salió mal al ignorar el problema. Inténtalo de nuevo.",
      markAirdropError: () =>
        "Se produjo un error al marcar esta transacción como airdrop. Inténtalo de nuevo.",
      markAllAirdropError: () =>
        "Algo salió mal al marcar estas transacciones como airdrops. Inténtalo de nuevo.",
      markAllSpamCurrencyError: () =>
        "Se produjo un error al marcar estas transacciones como spam. Inténtalo nuevamente.",
      markSpamCurrencyError: () =>
        "Se produjo un error al marcar esta transacción como spam. Inténtalo nuevamente.",
      refreshError: () =>
        "Se produjo un error al actualizar los datos de conciliación. Inténtalo nuevamente.",
    },
    backToTransactions: () => "Volver a transacciones",
    completedCountChip: (d) => d.issueCount + " % categorizado",
    completedCountEmbeddedChip: (d) => d.issueCount + " % completado",
    explainer: {
      consider: () => "Considere lo siguiente:",
      done: () => "Hecho",
      usefulLinks: () => "Enlaces útiles:",
    },
    fullyReconciledState: {
      ctaText: () => "Obtener informe fiscal",
      disclaimer: () =>
        "Asegúrese siempre de comprobar dos veces que todo parezca correcto.",
      neutralMessage: () => "Actualmente no hay sugerencias de reconciliación.",
      successMessage: () => "¡Todas las sugerencias han sido resueltas!",
      successSubMessage: () =>
        "¡Has puesto en práctica todas nuestras sugerencias de reconciliación! ¡Buen trabajo!",
    },
    helpArticles: () => "Artículos de ayuda",
    ignoreIssue: () => "Ignorar problema",
    ignoreWarning: () => "Ignorar la advertencia",
    impactChip: {
      high: () => "Alto impacto",
      low: () => "Bajo impacto",
      medium: () => "Impacto medio",
    },
    issueCountChip: (d) =>
      plural(d.issueCount, 0, es, {
        one: " 1 sugerencia ",
        other: number("es", d.issueCount, 0) + " sugerencias ",
      }),
    issues: {
      incorrectSendReceives: {
        associatedTransactions: () => "Actas",
        ctaButton: {
          importDestination: () => "Destino de importación",
          importSource: () => "Fuente de importación",
          name: () => "Nombre",
        },
        description: () =>
          "Las transacciones en esta categoría se han categorizado incorrectamente como Envíos o Recepciones Internas. Los Envíos y Recepciones solo deben usarse entre billeteras que le pertenecen. Estas transacciones deben tener una categoría diferente o la billetera desconocida debe ser importada.",
        ignore: () => "Ignorar",
        ignoreIssue: {
          action: () => "Ignorar problema",
          description: (d) =>
            "Esto ignorará todas las advertencias de importación sospechosas de falta en las transacciones hacia o desde " +
            select(d.source, { missing: "this source", other: d.source }) +
            ".",
          title: () => "¿Ignorar el supuesto problema de importación faltante?",
        },
        importSource: () => "Fuente de importación",
        imports: {
          address: () => "DIRECCIÓN",
          bannerText: () =>
            "Importar una billetera que no es suya o una dirección de contrato afectará sus obligaciones fiscales.",
          bannerTitle: () =>
            "¡Asegúrese de que esta billetera le pertenece antes de importarla!",
          goBack: () => "Volver",
          label: () => "Encuentra tu exchange o billetera",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
            title: () => "Solución de problemas de equilibrio durante el vuelo",
          },
        },
        missingSource: () => "Fuente",
        navButton: () => "Envíos/recepciones incorrectos",
        notMyWallet: () => "No es mi billetera",
        steps: {
          0: () =>
            "¿El origen o destino te pertenece? Usa el botón de importación e importa el exchange o la billetera.",
          1: () =>
            "Si el origen o destino no te pertenece, expande el acordeón y categoriza las transacciones correctamente.",
        },
        tableHeader: {
          assets: () => "Activos",
          associatedGainsLaptop: () => "Ganancias asociadas",
          destination: () => "Destino",
          source: () => "Fuente",
          transactions: () => "Tx",
          transactionsLaptop: () => "Actas",
          transferAmount: () => "Cantidad",
          transferAmountLaptop: () => "Monto de la transferencia",
        },
        title: () => "Envíos/recepciones incorrectos",
        transferBalance: () => "Monto de la transferencia",
        transferBalanceTooltip: () =>
          "El monto de la transferencia representa el valor fiduciario de la criptomoneda que se envía hacia y desde esta presunta importación faltante.",
        viewTransactions: () => "Ver transacciones",
      },
      missingBlockchain: {
        assignBlockchain: () => "Asignar blockchain",
        chipTitle: () => "Resolver las cadenas de bloques faltantes",
        description: () => "Asignar una cadena a estas transacciones",
        headers: {
          amount: () => "Cantidad",
          assets: () => "Activos",
          source: () => "Fuente",
          transactions: () => "Actas",
          transferAmount: () => "Monto de la transferencia",
          txs: () => "Tx",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6388387-how-to-resolve-missing-blockchain-warnings",
            title: () => "Cómo solucionar los avisos de falta de blockchain",
          },
        },
        navButton: () => "Asignar cadenas de bloques",
        steps: {
          0: () =>
            "¿Reconoces la transacción y recuerdas la cadena de bloques?",
          1: () =>
            "¿Qué explorador de bloques devuelve un resultado para el ID de transacción?",
        },
        title: () => "Cadenas de bloques faltantes",
      },
      missingPrice: {
        added: () => "Agregado",
        bulkLookupButton: {
          failUpdated: () =>
            "No se pudieron encontrar precios de mercado para estas transacciones",
          label: () => "Consultar precio",
          successUpdated: (d) =>
            "Precio de mercado actualizado para " +
            plural(d.count, 0, es, {
              one: " 1 transaction ",
              other: number("es", d.count, 0) + " transactions ",
            }),
        },
        chipTitle: () => "Resolver precios faltantes",
        ctaButton: {
          label: () => "Ignorar la advertencia",
          labelLaptop: () => "Ignorar la advertencia",
        },
        description: () =>
          "No se pudieron encontrar los precios de una moneda en las siguientes transacciones",
        drawer: {
          cta: () => "¿Por qué faltan los precios?",
          headerTwo: () =>
            "¿Qué sucede si el token con precio faltante es común y figura en servicios como Coingecko?",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5411667-how-to-fix-missing-market-price-warning",
              title: () => "Precios de mercado faltantes",
            },
          },
          linkSectionHeader: () => "Artículos de ayuda",
          listItems: {
            0: () => "El token tiene una liquidez extremadamente baja.",
            1: () =>
              "El token es completamente nuevo y aún no ha sido capturado por el oráculo de precios.",
            2: () => "El token no figura en los principales exchanges y dexes.",
            3: () => "No estante en la lista.",
          },
          listTitle: () =>
            "Algunos escenarios en los que falta información sobre precios históricos del mercado:",
          paragraphOne: () =>
            "Los precios faltantes suelen ocurrir cuando el precio de un token no existe en ninguna de las bases de datos de oráculos de precios que utilizamos. Esto es extremadamente raro, ya que tenemos precios para más de 300 000 monedas.",
          paragraphTwo: () =>
            "Si puede encontrar el token en Coingecko, es muy probable que CTC esté asignando la instancia incorrecta del token. Por ejemplo, se ha asignado un token con el mismo ticker a la transacción. Puede resolver el problema haciendo clic en la moneda y cambiando la moneda en el menú proporcionado.",
        },
        ignoreAllIssue: {
          action: () => "Ignorar todo",
          description: () =>
            "Esta acción ignorará las advertencias de precios faltantes para todas las transacciones en su cuenta y es irreversible. Solo debe continuar si los precios son $0.",
          title: () => "¿Ignorar todas las advertencias de precios faltantes?",
        },
        ignoreIssue: {
          action: () => "Ignorar advertencias",
          description: (d) =>
            select(d.name, {
              unknown:
                " Esto ignorará todas las advertencias de precios faltantes en las transacciones que usen esta moneda. ",
              other:
                " Esto ignorará todas las advertencias de precios faltantes en las transacciones que usen " +
                d.name +
                " . ",
            }),
          title: (d) =>
            "¿Ignorar los problemas de precios faltantes para " +
            select(d.name, { unknown: " esta moneda ", other: " nombre " }) +
            select(d.ticker, { unknown: "", other: " ( " + d.ticker + " ) " }) +
            " ?",
        },
        missingPrice: () => "Precio faltante",
        navButton: () => "Resolver precios faltantes",
        tableHeader: {
          assets: () => "Activos",
          associatedGains: () => "Ganancias",
          associatedGainsLaptop: () => "Ganancias asociadas",
          currency: () => "Divisa",
          transactions: () => "Tx",
          transactionsLaptop: () => "Actas",
        },
        title: () => "Transacciones con precios faltantes",
        viewTransactions: () => "Ver transacciones",
      },
      negativeBalance: {
        amountExplanation: () =>
          "El impacto de no corregir un problema de historial de compras faltante es que sus obligaciones tributarias se calcularán como si la base de costo de cualquier activo afectado fuera cero. Esto aumentará sus impuestos a pagar.",
        chipTitle: () => "Resolver el historial de compras faltante",
        ctaButton: {
          label: () => "Libro mayor de divisas",
          labelLaptop: () => "Ver en la tabla de transacciones",
        },
        currency: () => "Divisa",
        description: () =>
          "La falta de historial de compras se produce cuando se han vendido más criptoactivos de los que se compraron originalmente. Esto sugiere que algunos registros de adquisición pueden faltar, no estar categorizados o estar categorizados incorrectamente.",
        drawer: {
          cta: () => "¿Qué es lo que falta en el historial de compras?",
          headerTwo: () => "Solución al historial de compras faltante.",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
              title: () =>
                "Solución de problemas de historial de compras faltante",
            },
            1: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5305370-faq-what-is-a-zero-cost-buy",
              title: () => "¿Qué es una compra a coste cero?",
            },
          },
          linkSectionHeader: () => "Artículos de ayuda",
          listTitle: () =>
            "Si definitivamente ha cargado todas sus cuentas, pueden aparecer advertencias de historial de compras faltantes debido a una o más de las siguientes razones:",
          orderedListItems: {
            0: () => "Una compra o un intercambio faltante",
            1: () =>
              "Las transacciones no se han categorizado o se han categorizado incorrectamente. Por ejemplo, una compra se ha categorizado como una recepción.",
            2: () =>
              "Envío/recepción faltante Los envíos y las recepciones siempre deben agruparse en una transferencia. Por ejemplo, cada envío debe tener una recepción correspondiente y viceversa.",
          },
          paragraphOne: () =>
            "La falta de historial de compras se produce cuando se han vendido más criptoactivos de los que se compraron originalmente. Esto sugiere que algunos registros de adquisición pueden faltar, no estar categorizados o estar categorizados incorrectamente.",
          paragraphThree: () =>
            "En algunos casos, es posible que uno de sus intercambios no informe con precisión las recompensas o tarifas de staking.",
          paragraphTwoBoldSection: () =>
            "El primer paso para solucionar el problema del historial de compras faltante es asegurarse de haber cargado TODAS sus cuentas de billetera y cambio.",
          paragraphTwoRegularSection: () =>
            "Esta es la causa más común ya que genera lagunas importantes en su historial de transacciones.",
        },
        gains: () => "Ganancias asociadas",
        grouping: {
          byCurrency: () => "Por moneda",
          byWallet: () => "Por importación",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
            title: () =>
              "Solución de problemas de historial de compras faltante",
          },
          1: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
            title: () => "Solución de problemas de equilibrio durante el vuelo",
          },
          2: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5284400-balance-remaining-is-incorrect",
            title: () => "El saldo restante es incorrecto",
          },
          3: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5305370-handling-zero-cost-buy-transactions",
            title: () => "Manejo de transacciones de compra con costo cero",
          },
        },
        navButton: () => "Resolver el historial de compras faltante",
        steps: {
          0: () =>
            "Asegúrese de haber importado todas las transacciones correspondientes a todos los años financieros, incluidas las operaciones OTC.",
          1: () =>
            "Haga clic en las transacciones asociadas y se abrirá una nueva ventana con todas las transacciones de esa fuente en particular. Asegúrese de que todas las transacciones estén categorizadas.",
          2: () =>
            "Cree una transacción manual para manejar cualquier dato faltante de los intercambios o transacciones OTC.",
        },
        tableHeader: {
          amountNegative: () => "Cantidad",
          amountNegativeDesktop: () => "Cantidad faltante",
          assets: () => "Monedas",
          associatedGains: () => "Ganancias",
          associatedGainsLaptop: () => "Ganancias asociadas",
          currency: () => "Divisa",
          source: () => "Fuente",
          taxImpact: () => "Impacto fiscal",
          transactions: () => "Tx",
          transactionsDesktop: () => "Actas",
          transferAmount: () => "Cantidad",
          transferAmountDesktop: () => "Monto de la transferencia",
        },
        title: () => "Revisar historial de compras faltante",
        transactions: () => "Actas",
        viewLedger: () => "Libro mayor de divisas",
        viewTransactions: () => "Ver transacciones",
      },
      negativeBalanceByWallet: {
        title: () => "Problemas con el historial de compras faltante",
      },
      smartContractInteractions: {
        categorise: () => "Clasificar por categorías",
        description: () =>
          "Estas transacciones son interacciones entre sus billeteras y contratos inteligentes. Investigue cada dirección de contrato y etiquétela con un nombre adecuado si es necesario, luego clasifique las transacciones.",
        navButton: () => "Categorizar transacciones",
        tableHeader: {
          addresses: () => "Direcciones",
          amount: () => "Cantidad",
          assets: () => "Activos",
          transactions: () => "Actas",
          transferAmount: () => "Monto de la transferencia",
          txs: () => "Tx",
        },
        title: () => "Interacciones de contratos inteligentes",
      },
      suspectedAirdropTransaction: {
        description: () =>
          "Los supuestos Airdrops incluyen direcciones de origen de Airdrops conocidas. Marque una dirección para confirmar que se trata de un Airdrop.",
        links: {
          0: {
            href: () =>
              "https://cryptotaxcalculator.io/blog/crypto-airdrop-tax",
            title: () => "¿Cómo se gravan los airdrops?",
          },
        },
        markAllAsTrade: {
          button: () => "Marcar todo como Airdrops",
          confirm: () => "Confirmar",
          description: () =>
            'Esto asignará la categoría "Airdrop" a todas las transacciones enumeradas aquí y resolverá estos problemas.',
          title: () => "¿Marcar todas las transacciones como Airdrops?",
        },
        markAsNotTrade: () => "Marcar como no Airdrop",
        markAsTrade: () => "Marcar como Airdrop",
        markSelectedAsTrade: () => "Marcar como seleccionado como airdrops",
        navButton: () => "Categorizar transacciones",
        notTrade: () => "No es Airdrop",
        steps: {
          0: () => "¿Reconoces el nombre del token?",
          1: () =>
            "¿Recuerdas haber interactuado con el protocolo para recibir el token?",
        },
        title: () => "Sospechosos lanzamientos desde el aire",
        trade: () => "Entrega por paracaídas",
      },
      suspectedBurnTransaction: {
        description: () =>
          "Hemos detectado algunas transacciones que enviaron tokens a una dirección de quemador conocida. Marque si estas transacciones son quemadoras.",
        markAllAsTrade: {
          button: () => "Marcar todo como quemado",
          confirm: () => "Confirmar",
          description: () =>
            'Esto asignará la categoría "Quemar" a todas las transacciones enumeradas aquí y resolverá estos problemas.',
          title: () => "¿Marcar todas las transacciones como quemadas?",
        },
        markAsNotTrade: () => "Marcar como no quemar",
        markAsTrade: () => "Marcar como quemado",
        markSelectedAsTrade: () => "Marcar seleccionado como quemado",
        navButton: () => "Categorizar transacciones",
        notTrade: () => "No quemar",
        steps: {
          0: () => "¿Reconoces el nombre del token?",
          1: () =>
            "¿Recuerdas haber enviado los tokens a una dirección de quema?",
        },
        title: () => "Sospechosas transacciones de Burn",
        trade: () => "Quemar",
      },
      suspectedMissingImports: {
        associatedTransactions: () => "Actas",
        ctaButton: {
          label: () => "Importar",
          name: () => "Nombre",
        },
        description: () =>
          "Las importaciones sospechosas de faltar son direcciones con las que interactuó y que no se importaron a Crypto Tax Calculator. Resuelva las advertencias de esta categoría de conciliación importando la dirección faltante o confirmando que esta billetera no le pertenece.",
        ignore: () => "Ignorar",
        ignoreIssue: {
          action: () => "Ignorar problema",
          description: (d) =>
            "Esto ignorará todas las advertencias de importación sospechosas de falta en las transacciones hacia o desde " +
            select(d.source, { missing: "this source", other: d.source }) +
            ".",
          title: () => "¿Ignorar el supuesto problema de importación faltante?",
        },
        importSource: () => "Fuente de importación",
        imports: {
          address: () => "DIRECCIÓN",
          bannerText: () =>
            "Importar una billetera que no es suya o una dirección de contrato afectará sus obligaciones fiscales.",
          bannerTitle: () =>
            "¡Asegúrese de que esta billetera le pertenece antes de importarla!",
          goBack: () => "Volver",
          label: () => "Encuentra tu exchange o billetera",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279670-import-exchange-transactions",
            title: () => "Transacciones de intercambio de importación",
          },
          1: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279724-import-wallet-and-defi-transactions",
            title: () => "Importación de billeteras y transacciones DeFi",
          },
          2: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5597463-how-to-add-an-additional-wallet-for-the-same-blockchain",
            title: () =>
              "¿Cómo agregar una billetera adicional para la misma blockchain?",
          },
        },
        missingSource: () => "Fuente",
        navButton: () => "Importaciones sospechosas de faltar",
        notMyWallet: () => "No es mi billetera",
        steps: {
          0: () =>
            "¿Has transferido activos entre tus propias billeteras? Es posible que hayas olvidado importar los datos de una de estas billeteras.",
          1: () =>
            'Si se trata de una transacción entre usted y una billetera externa, puede seleccionar la categoría que mejor describa estas transacciones. No elija "Enviar" o "Recibir", ya que estas categorías solo deben usarse para transferencias entre billeteras de su propiedad.',
        },
        tableHeader: {
          assets: () => "Activos",
          associatedGainsLaptop: () => "Ganancias asociadas",
          source: () => "Fuente",
          transactions: () => "Tx",
          transactionsLaptop: () => "Actas",
          transferAmount: () => "Cantidad",
          transferAmountLaptop: () => "Monto de la transferencia",
        },
        title: () => "Importaciones sospechosas de faltar",
        transferBalance: () => "Monto de la transferencia",
        transferBalanceTooltip: () =>
          "El monto de la transferencia representa el valor fiduciario de la criptomoneda que se envía hacia y desde esta presunta importación faltante.",
        viewImport: () => "Ver importación",
        viewTransactions: () => "Ver transacciones",
      },
      suspectedSpamToken: {
        currency: () => "Divisa",
        description: () =>
          "Los tokens sospechosos de ser spam se identifican como aquellos que no tienen datos de precios de mercado, que solo tienen una única transacción entrante o que provienen de una fuente de spam conocida. Marcar una transacción como spam la ocultará y la excluirá de los cálculos de saldo.",
        headers: {
          asset: () => "Activo",
          price: () => "Precio",
          source: () => "Fuente",
          transactions: () => "Actas",
          txs: () => "Tx",
          value: () => "Valor",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6185693-dealing-with-scam-airdrops",
            title: () => "Cómo lidiar con los airdrops fraudulentos",
          },
        },
        markAllAsSpam: {
          button: () => "Marcar todo como spam",
          confirm: () => "Confirmar",
          description: () =>
            'Esto asignará la categoría "Spam" a todas las transacciones enumeradas aquí y resolverá estos problemas.',
          title: () => "¿Marcar todas las transacciones como spam?",
        },
        markAsNotSpam: () => "Marcar como no spam",
        markAsSpam: () => "Marcar como spam",
        markSelectedAsSpam: () => "Marcar seleccionado como spam",
        navButton: () => "Categorizar transacciones",
        notSpam: () => "No es spam",
        spam: () => "Correo basura",
        steps: {
          0: () => "¿Reconoces el nombre del token?",
          1: () =>
            "¿Recuerdas haber interactuado con el protocolo para recibir el token?",
          2: () =>
            "Si no se aplica ninguna de las opciones anteriores, es probable que este token sea un intento de estafa y debe marcarse como spam.",
        },
        title: () => "Tokens de spam sospechosos",
        transactionSource: () => "Origen de la transacción",
        transactions: () => "Actas",
      },
      uncategorisedIntraExchangeTransactions: {
        associatedTransactions: () => "Actas",
        ctaButton: {
          label: () => "Clasificar por categorías",
        },
        description: () =>
          "Las transacciones intra-exchange son transacciones en las que tanto el origen como el destino son el mismo exchange. Las transacciones de esta categoría suelen ser pagos de intereses, recompensas por staking, ingresos o airdrops.",
        missingSource: () => "Fuente",
        navButton: () => "Categorizar transacciones",
        tableHeader: {
          assets: () => "Activos",
          associatedGainsLaptop: () => "Ganancias asociadas",
          source: () => "Fuente",
          transactions: () => "Tx",
          transactionsLaptop: () => "Actas",
          transferAmount: () => "Cantidad",
          transferAmountLaptop: () => "Monto de la transferencia",
        },
        title: () => "Transacciones intrabursátiles",
        transferBalance: () => "Monto de la transferencia",
        transferBalanceTooltip: () =>
          "El monto de la transferencia representa el valor fiduciario de la criptomoneda que se envía hacia y desde esta presunta importación faltante.",
        viewTransactions: () => "Ver transacciones",
      },
      uncategorisedTransactions: {
        button: () => "Clasificar por categorías",
        chipTitle: () => "Categorizar transacciones",
        description: () =>
          "Aplique la categoría adecuada a las siguientes transacciones para mejorar la precisión de su informe",
        drawer: {
          cta: () => "Aprenda sobre la categorización",
          headerOne: () => "Por qué es importante categorizar",
          headerTwo: () => "¿No debería todo categorizarse automáticamente?",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5279619-categorize-transactions",
              title: () =>
                "Guía detallada para todos los tipos de transacciones",
            },
          },
          linkSectionHeader: () => "Artículos de ayuda",
          paragraphOne: () =>
            'La categorización permite a CTC aplicar el resultado fiscal adecuado a la transacción y hacer un seguimiento preciso de la base de costos en todo el historial de transacciones. Las transacciones no categorizadas se etiquetan como "Entrantes" y "Salientes" y se ignoran en los cálculos de impuestos, lo que puede generar mayores ganancias de capital debido a las brechas en el seguimiento de la base de costos.',
          paragraphTwo: () =>
            "Si ha importado todas sus cuentas, incluso las que ya no utiliza, la mayoría de sus transacciones deberían categorizarse automáticamente. En algunos casos, no se pudo determinar la categoría apropiada para las transacciones. En lugar de hacer suposiciones, las resaltamos para que las pueda aclarar.",
        },
        inContext: {
          categorised: () => "¡Categorizado!",
          finish: () => "Finalizar",
          goToNext: () => "Ir a la siguiente transacción",
          nextTransaction: () => "Próxima transacción",
          notSure: () => "¿No estás seguro? Pasa a la siguiente transacción",
          previousTransaction: () => "Transacción anterior",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279619-categorise-transactions",
            title: () => "Categorizar transacciones",
          },
        },
        navButton: () => "Categorizar transacciones",
        similarChip: (d) => d.count + " similares",
        steps: {
          0: () =>
            "Asegúrese de haber importado todos los datos de todas las fuentes posibles.",
          1: () =>
            "Asigne manualmente las categorías de las transacciones que ve a continuación.",
        },
        title: () => "Aclarar transacciones",
        transactionSource: () => "Origen de la transacción",
        type: () => "Categoría",
        uncategorisedTransactions: () => "Transacciones sin categorizar",
        viewSwitcher: {
          inContext: () => "En contexto",
          onlyUncategorised: () => "Solo sin categorizar",
        },
        viewTransactions: () => "Ver transacciones",
      },
      uncategorisedWalletInteractions: {
        associatedTransactions: () => "Actas",
        categorise: () => "Clasificar por categorías",
        description: () =>
          "Las interacciones de billetera son otras direcciones de billetera con las que han interactuado las billeteras importadas, como sus propias billeteras, billeteras de intercambio o la billetera de otra persona. Resuelva estas advertencias categorizando las interacciones correctamente.",
        navButton: () => "Categorizar transacciones",
        steps: {
          0: () =>
            '¿Ha transferido activos entre sus propias billeteras? Si es así, las transacciones salientes deben categorizarse como "Enviar" y las transacciones entrantes como "Recibir".',
          1: () =>
            "Si ha interactuado con la billetera de otra persona, es posible que desee considerar categorías como regalo, uso personal, venta o compra.",
        },
        tableHeader: {
          assets: () => "Activos",
          transactions: () => "Tx",
          transactionsLaptop: () => "Actas",
          transferAmount: () => "Cantidad",
          transferAmountLaptop: () => "Monto de la transferencia",
          wallets: () => "Carteras",
        },
        title: () => "Interacciones con la billetera",
        transferBalance: () => "Monto de la transferencia",
      },
      unknownParty: {
        ctaButton: {
          label: () => "Reemplazar a una parte desconocida",
        },
        description: () =>
          "Una parte desconocida se produce cuando una billetera o un exchange no informa el origen o el destino de una transacción. Esto significa que se informa el movimiento de activos entrantes o salientes, pero la otra parte queda como “desconocida”. Para corregir este problema, la fuente o el destino desconocidos deben reemplazarse por una parte conocida.",
        modal: {
          actionButton: () => "Reemplazar a una parte desconocida",
          actionButtonMobile: () => "Reemplazar partido",
          cancelButton: () => "Cancelar",
          description: (d) =>
            "Esto reemplazará a todas las partes desconocidas " +
            d.direction +
            " en",
          destinationDirection: () => "destino",
          exchangePlaceholder: () => "Partido conocido",
          sourceDirection: () => "fuente",
          title: () => "Reemplazar partido",
        },
        navButton: () => "Identificar la parte",
        steps: {
          0: () =>
            "¿Has importado todas tus billeteras y plataformas de intercambio? Nuestro algoritmo de comparación puede detectar y resolver automáticamente el problema de la parte desconocida.",
          1: () =>
            '¿Sabe a dónde se envió o de dónde se recibió el activo? Si es así, puede asignar manualmente la parte a la transacción utilizando el botón "Reemplazar parte desconocida".',
        },
        tableHeader: {
          Destination: () => "Destino",
          assets: () => "Activos",
          source: () => "Fuente",
          transactions: () => "Actas",
          transactionsMobile: () => "Gracias",
          transferAmount: () => "Monto de la transferencia",
          transferAmountMobile: () => "Cantidad",
        },
        title: () => "Partes desconocidas",
      },
      unmatchedTransfer: {
        button: () => "Resolver",
        chipTitle: () => "Resolver transferencias no coincidentes",
        ctaButton: {
          import: () => "Contraparte de importación",
          recategorise: () => "Recategorizar",
        },
        description: () =>
          "Vincula correctamente todas las transferencias para garantizar que todos tus costos base se calculen correctamente",
        drawer: {
          cta: () => "Cómo solucionar transferencias no coincidentes",
          headerFive: () => "Para resolver estas discrepancias, puede:",
          headerFour: () => "Resolución de transferencias no coincidentes",
          headerOne: () => "¿Qué son las transferencias no coincidentes?",
          headerSix: () =>
            "La coincidencia precisa de transferencias es crucial para el cálculo adecuado de impuestos y para garantizar que su historial de transacciones de criptomonedas esté completo.",
          headerThree: () => "Esto suele ocurrir en tres situaciones:",
          headerTwo: () => "Entendiendo las transferencias no coincidentes",
          listOneItems: {
            0: () =>
              "Ha enviado o recibido criptomonedas desde una billetera que no se ha importado a su cuenta",
            1: () =>
              "Una transacción ha sido categorizada como envío o recepción, pero no podemos encontrar su contraparte correspondiente",
            2: () =>
              "Ha utilizado un puente para transferir activos entre diferentes cadenas de bloques y solo podemos ver un lado de la transacción del puente.",
          },
          listThreeItems: {
            0: () =>
              "Recategorice la transacción si se ha etiquetado incorrectamente como envío/recepción o puente de entrada/salida.",
          },
          listTwoItems: {
            0: () =>
              "Importe la billetera de la contraparte faltante para completar el par de transferencia",
          },
          paragraphOne: () =>
            "Las transferencias no coincidentes ocurren cuando hay una transacción saliente desde su billetera importada a un destino desconocido, o cuando a una transacción de envío/recepción le falta su par de transferencia correspondiente.",
          paragraphThree: () =>
            "Si tiene una transferencia no coincidente entre dos fuentes que ya ha importado, tiene dos opciones:",
          paragraphTwo: () =>
            "Cuando envía o recibe criptomonedas entre billeteras o cuentas, siempre debe haber dos lados de la transacción: la billetera que envía y la billetera que recibe. Una transferencia no coincidente indica que solo podemos ver un lado de esto, lo que puede generar un seguimiento inexacto de sus tenencias y su base de costos asociada.",
          subListOneItems: {
            0: () =>
              'Habilite la configuración "Asumir transferencias" para que coincidan automáticamente las transferencias entre sus cuentas',
            1: () =>
              "Genere manualmente una transferencia seleccionando “Generar transferencia” en el menú de opciones de la transacción",
          },
        },
        headers: {
          amount: () => "Cantidad",
          assets: () => "Activos",
          source: () => "Contraparte",
          transactions: () => "Actas",
          transferAmount: () => "Monto de la transferencia",
          txs: () => "Tx",
        },
        navButton: () => "Resolver transferencias no coincidentes",
        steps: {
          0: () =>
            "Asegúrese de haber importado todos los datos de todas las fuentes posibles.",
          1: () =>
            "Reasigne manualmente las categorías de las transacciones que ve a continuación.",
        },
        title: () => "Resolver transferencias no coincidentes",
      },
    },
    loadMore: () => "Cargar más",
    noRemainingIssues: {
      allCleared: (d) =>
        "¡Buen trabajo! Ya has resuelto todos los problemas " + d.issueTitle,
    },
    noTransactionsState: {
      importCTA: () => "Ir a Importaciones",
      instructions: () => "No hay datos añadidos",
      instructionsSubtext: () =>
        "Importa tus cuentas para comenzar la conciliación.",
    },
    quantity: (d) => "Cantidad: " + d.amount,
    reconcileCTA: {
      itemsReconciled: () => "Transacciones conciliadas",
      progressBanner: {
        body: () =>
          "Algunas transacciones tienen problemas que aún no se han solucionado. Mejorar la integridad de sus datos puede reducir su carga fiscal.",
        completeText: () =>
          "No hay sugerencias de conciliación, ¡genial! Asegúrate siempre de comprobar dos veces que todo esté correcto.",
        title: () => "Estado actual de la conciliación",
      },
      reconcileItems: (d) =>
        plural(d.issueCount, 0, es, {
          one: " Reconciliar transacción ",
          other: " Reconciliar transacciones ",
        }),
      suggestionBanner: {
        body: (d) =>
          "Quedan " +
          plural(d.suggestionNum, 0, es, {
            one: " es 1 sugerencia ",
            other: " son " + number("es", d.suggestionNum, 0) + " sugerencias ",
          }) +
          " para ayudarle a conciliar sus transacciones.",
        cta: () => "Sugerencias de revisión",
        suggestionChip: (d) =>
          plural(d.suggestionNum, 0, es, {
            one: " 1 sugerencia ",
            other: number("es", d.suggestionNum, 0) + " sugerencias ",
          }),
      },
    },
    resolvedChip: {
      resolved: () => "Resuelto",
    },
    sidebar: {
      knowledgeBase: () => "Base de conocimientos",
      steps: {
        categorise: () => "Clasificar por categorías",
        correct: () => "Correcto",
        import: () => "Importar",
        prepare: () => "Preparar",
        resolve: () => "Resolver",
      },
      suggestions: (d) =>
        plural(d.count, 0, es, {
          one: " 1 sugerencia ",
          other: number("es", d.count, 0) + " sugerencias ",
        }),
      tip: {
        message: () =>
          "Para obtener resultados óptimos, concilie sus transacciones en el orden en que aparecen de arriba a abajo en la barra lateral.",
        title: () => "Consejo profesional",
      },
    },
    tipsInfoModal: {
      items: {
        0: () =>
          "Todas las transacciones en la cadena de bloques son visibles para la autoridad fiscal y se pueden rastrear hasta su intercambio KYC.",
        1: () =>
          "Debe proporcionar una imagen completa de todo su historial comercial.",
        2: () =>
          "Te ayudamos a identificar cualquier problema para que puedas pasar una auditoría",
      },
      subtitle: () =>
        "Hemos identificado algunos consejos para reducir su obligación tributaria y garantizar la precisión de los informes.",
      subtitleReview: () =>
        "Revise estos elementos para reducir su obligación tributaria y garantizar la precisión de los informes.",
      title: (d) => "Potencialmente podría ahorrar " + d.amount,
    },
    title: () => "Reconciliación",
    tradeSuggestion: {
      currency: () => "Divisa",
      headers: {
        amount: () => "Cantidad",
        asset: () => "Activo",
        price: () => "Precio",
        transactions: () => "Actas",
        txs: () => "Tx",
        value: () => "Valor",
      },
      transactionSource: () => "Origen de la transacción",
      transactions: () => "Actas",
    },
    unignoreIssue: () => "Dejar de ignorar el problema",
    viewTransactions: () => "Ver transacciones",
  },
  referAFriend: {
    copyCTA: () => "Copiar",
    disclaimer: (d) =>
      "*Por cada usuario que se registre en CTC y adquiera un plan por primera vez utilizando tu enlace de referencia único, recibirás " +
      d.reward +
      " en efectivo o el importe total que el individuo referido pague por su plan, lo que sea menor. Los pagos se distribuirán a través de PayPal 60 días después de finalizar el mes en el que se suscribieron a un plan. El importe final recibido puede variar si se producen fluctuaciones de divisas entre el momento de obtener la recompensa y recibir el pago. Todos los pagos se realizan en AUD. Para calificar, los usuarios referidos deben registrarse utilizando tu enlace de referencia personal. Las auto-referencias no son elegibles para recibir beneficios de recompensa.",
    helpCenterLink: () => "Centro de ayuda",
    messageUs: (d) =>
      "Envíanos un DM [@CryptoTaxHQ](" +
      d.linkTextKey +
      ") y exploremos cómo podemos colaborar!",
    moreDetails: (d) =>
      "Para más detalles, por favor visita nuestro [centro de ayuda](" +
      d.linkTextKey +
      ").",
    paypalSection: {
      available: () => "Disponible",
      availableTooltip: () =>
        "El importe disponible que puedes reclamar en efectivo",
      claimCTA: () => "Reclamar ahora",
      claimCTATooltip: () => "Debes esperar 60 días antes de reclamar",
      connectCTA: () => "Conectar PayPal",
      connectedSubtext: () =>
        "Las ganancias están disponibles para reclamar una vez que el individuo referido haya estado en un plan de pago durante 60 días",
      error: () =>
        "Por favor, introduce una dirección de correo electrónico válida",
      lifetimeEarnings: () => "Ganancias totales",
      lifetimeEarningsTooltip: () => "Importe total ganado y pagado a ti",
      modal: {
        connectCTA: () => "Conectar",
        connectPlaceholder: () => "Introduce los detalles de PayPal",
        connectSubtext: () =>
          "Introduce tu dirección de correo electrónico de PayPal",
        connectTitle: () => "Conectar PayPal",
        disconnectCTA: () => "Desconectar PayPal",
        updateCTA: () => "Cambiar PayPal",
        updatePlaceholder: () => "Introduce nuevos detalles de PayPal",
        updateSubtext: (d) => d.username + " está actualmente conectado",
        updateTitle: () => "Actualizar PayPal",
      },
      plansPurchased: () => "Planes adquiridos",
      plansPurchasedTooltip: () =>
        "El número de usuarios que han adquirido un plan utilizando tu enlace de referencia",
      subtext: () =>
        "Comienza a compartir tu enlace de referencia, luego conecta tu PayPal para reclamar efectivo.",
      title: () => "Tus ganancias por referidos",
      updateCTA: () => "Actualizar PayPal",
    },
    socialLinks: {
      email: (d) =>
        "He estado utilizando Crypto Tax Calculator para mis impuestos de criptomonedas - es rápido, fácil y preciso, además soporta toneladas de integraciones.\n\n Si todavía necesitas hacer los tuyos, puedes usar mi enlace de referencia y ahorrar un 20% en tu plan.\n\n " +
        d.link,
      emailSubject: () =>
        "¡Ahorra un 20% en Crypto Tax Calculator cuando uses mi enlace!",
      telegram: (d) =>
        "Para cualquiera que como yo casi abandona haciendo sus impuestos de criptomonedas, prueba @CryptoTaxHQ. Salvó totalmente mi cordura. Si quieres probarlos usa mi enlace de referencia ¡y ambos ahorramos! " +
        d.link,
      x: (d) =>
        "Para cualquiera que como yo casi abandona haciendo sus impuestos de criptomonedas, prueba @CryptoTaxHQ. Salvó totalmente mi cordura. Si quieres probarlos usa mi enlace de referencia ¡y ambos ahorramos! " +
        d.link,
    },
    subtext: (d) =>
      "Comparte CTC con tus amigos para ganar hasta " +
      d.reward +
      " en efectivo por cada referencia que hagas. Ellos ahorrarán un 20% en su plan, y tú recibirás hasta " +
      d.reward +
      " en efectivo por cada referencia. ¡¿Qué te parece como situación beneficiosa para ambos?!",
    title: (d) => "Obtén " + d.reward + " en efectivo* por cada referencia",
    workWithUs: () =>
      "¿Tienes una comunidad criptográfica o un gran número de seguidores en X?",
  },
  referrals: {
    description: () =>
      "Cuando un amigo se registra en CTC y compra un plan por primera vez usando tu enlace de referencia, recibirás un crédito de $40 para tu suscripción. Si acumulas suficientes créditos, tu próxima suscripción podría ser gratis. Para calificar, tu amigo debe registrarse usando tu enlace de referencia personal.",
    linkText: () => "Su enlace de referencia:",
    subtext: () =>
      "Obtén $40 de descuento en CTC por cada amigo que recomiendes. Ellos también recibirán $40 de descuento. ¡Ka-ching! 💰",
    title: () => "Dona $40 y recibe $40",
  },
  report: {
    ACB: () => "Base de costo ajustada",
    AVCO: () => "Costo promedio",
    FIFO: () => "Primero en entrar, primero en salir",
    HIFO: () => "El más alto entra primero en salir",
    HMRC: () => "Hacienda y Aduanas de Su Majestad",
    IRS: {
      done: () => "Hecho",
      downloadIrs8949Long: () =>
        "Descargar el formulario 8949 del IRS sobre ganancias a largo plazo",
      downloadIrs8949LongConsolidated: () =>
        "Descargar el Formulario 8949 del IRS sobre ganancias a largo plazo (consolidado)",
      downloadIrs8949Short: () =>
        "Descargar el formulario 8949 del IRS sobre ganancias a corto plazo",
      downloadIrs8949ShortConsolidated: () =>
        "Descargar el Formulario 8949 del IRS sobre ganancias a corto plazo (consolidado)",
      downloadIrsForm: () => "Descargar el Anexo 1 del IRS (Formulario 1040)",
      downloadIrsScheduleDForm: () =>
        "Descargar el Anexo D del IRS (Formulario 1040)",
      provideIrsDetails: () => "Proporcione sus datos del IRS",
      stepComplete: () => "Se han completado todos los pasos: ya está.",
    },
    LIFO: () => "Último en entrar, primero en salir",
    LTFO: () => "Más eficaz desde el punto de vista fiscal",
    PFU: () => "PFU / Francia",
    acknowledged: () => "Admitido",
    airdropIncome: () => "Lanzamientos aéreos",
    allTradeSelector: () => "Todos los tipos de txs",
    amountRemaining: () => "Cantidad restante",
    amountYouCouldSave: () =>
      "La cantidad que podría ahorrar si mejorara la precisión de sus informes",
    approvals: () => "Aprobaciones",
    assetSummary: {
      amountRemainingEndFy: () => "Saldo final del ejercicio",
      amountRemainingEndFyTooltip: () =>
        "Saldo total mantenido al final del año fiscal.",
      amountRemainingStartFy: () => "Saldo inicial del año fiscal",
      capitalGain: () => "Pérdidas y ganancias de capital",
      cost: () => "Costo",
      currency: () => "Divisa",
      fee: () => "Honorarios",
      income: () => "Ingreso",
      longTerm: () => "A largo plazo",
      noDisposals: () => "No se han producido enajenaciones en este periodo.",
      overallGain: () => "Pérdidas y ganancias totales",
      proceeds: () => "Producto",
      quantity: () => "Cantidad eliminada",
      quantityTooltip: () => "Cantidad total enajenada durante el año fiscal.",
      shortTerm: () => "Corto plazo",
    },
    ausFinancialYear: (d) =>
      "1 de julio " + d.prevYear + " - 30 de junio " + d.year,
    buyTime: () => "Compró",
    capitalGainSelector: {
      all: () => "Todo",
      gains: () => "Ganancias",
      longTermCapitalGains: () => "Ganancias a largo plazo",
      longTermCapitalLosses: () => "Pérdidas a largo plazo",
      shortTermCapitalGains: () => "Ganancias a corto plazo",
      shortTermCapitalLosses: () => "Pérdidas a corto plazo",
      totalLosses: () => "Pérdidas",
    },
    checkWithAgent: () => "¿No está seguro? Consulte con su agente fiscal.",
    closed: () => "Cerrado",
    confirmInventoryMethod: () => "Confirmar método de inventario",
    confirmationText: (d) =>
      "Ha seleccionado el método de inventario " +
      d.method +
      " , que no es la opción predeterminada. Consulte siempre con su asesor fiscal antes de utilizar el método de inventario " +
      d.method +
      " .",
    cost: () => "Base de costos",
    costBasisRedistributionMethod: {
      "largest-wallet-highest-cost": () =>
        "Billetera más grande, costo más alto",
      "largest-wallet-lowest-cost": () =>
        "La billetera más grande y de menor costo",
      "least-adjustment": () => "Ajuste mínimo",
      title: () => "Método de redistribución",
      tooltip: () =>
        "El método utilizado para redistribuir la base de costo de los activos al cambiar entre los métodos de base de costo universal y por billetera e intercambio",
    },
    csv: () => "CSV",
    currency: () => "Divisa",
    customDateRange: () => "Fechas del ejercicio económico",
    customDates: () => "Seleccionar fechas personalizadas",
    defaultPaywallBannerText: () => "Suscríbete para ver tu informe",
    derivativeTradingIncome: () => "Ingresos por operaciones con derivados",
    disclaimer: (d) =>
      "Debe utilizar estos resultados como una estimación y solo como orientación. Aunque intentamos garantizar la precisión de estos resultados, pueden producirse errores. Asegúrese de verificar los resultados. " +
      select(d.isRegularUser, {
        true: " Si tiene alguna duda, hable con su profesional de impuestos. ",
        other: "",
      }),
    disclaimerTitle: () =>
      "Los resultados calculados se basan en la información proporcionada en el momento del cálculo.",
    download: {
      bglAccountIDModal: {
        buttonText: () => "Hecho",
        copyId: () => "Copiar ID",
        subtitle: () =>
          "Utilice este ID como el ID de su cuenta al importar sus datos a BGL:",
        title: () => "Identificación de cuenta BGL",
      },
      buttonIrs: () => "Generar formulario",
      downloading: () => "Descargando...",
      exportCSV: () => "Exportar a archivo CSV",
      exportIrs1040s1Pdf: () =>
        "Exportación al IRS Anexo 1 (Formulario 1040) PDF",
      exportIrs8949Pdf: () => "Exportación al IRS Formulario 8949 PDF",
      exportPDF: () => "Exportar a archivo PDF",
      exportTurboTaxOnline: () => "Exportar a TurboTax Online (CSV)",
      exportTurboTaxTXF: () => "Exportar a TurboTax Desktop (TXF)",
      irsDownload: {
        customTitle: () => "Detalles del formulario del IRS (opcional)",
        download: () => "Descargar",
        name: () => "Nombre(s) que aparecen en la declaración",
        skip: (d) =>
          "Dado que no tiene ninguna ganancia de término " +
          d.termLength +
          " , puede omitir este paso.",
        social: () => "Número de Seguro Social o TIN",
        whyUserData: (d) =>
          "Si desea que completemos automáticamente sus datos en " +
          d.formName +
          " , ingrese su nombre y un número de seguro social o TIN válido. Respetamos su privacidad, por lo que estos datos no se almacenan en ningún lugar y solo se utilizan para generar los archivos PDF.",
        whyUserDataCustom: () =>
          "Si desea que completemos automáticamente sus datos, ingrese su nombre y un número de seguro social o de identificación fiscal válido. Respetamos su privacidad, por lo que estos datos no se almacenan en ningún lugar y solo se utilizan para generar los archivos PDF.",
      },
      loading: () => "Descargando",
      sample: () => "Muestra",
      tabCryptoTax: () => "Informe fiscal",
      tabGermanReports: () => "Formularios alemanes",
      tabIrs: () => "Formularios del IRS",
      tabTaxIntegrations: () => "Integraciones de software",
      title: () => "Descargar informes",
      tradingStockReportDownload: {
        buttons: {
          cancel: () => "Cancelar",
          downloadReport: () => "Descargar informe",
        },
        costBasisMethod: {
          description: () =>
            "La base de costo de los activos se utiliza para determinar los valores de apertura y cierre de sus acciones comerciales. Si existe un valor de mercado correspondiente para un activo, este se anulará.",
          title: () => "Utilice el método de base de costos",
        },
        description: () =>
          "Seleccione el método que desea utilizar para determinar los valores de apertura y cierre de sus acciones comerciales.",
        fallbackMethod: {
          description: () =>
            "Se utilizan valores de mercado para todos los activos. Cuando no se puede obtener un precio de mercado y, por lo tanto, no se puede determinar un valor de mercado, se utiliza en su lugar la base de costo.",
          title: () => "Utilice el método de respaldo",
        },
        marketValueMethod: {
          description: () =>
            'Se utilizan valores de mercado para todos los activos. Cuando no se puede recuperar un precio de mercado y, por lo tanto, no se puede determinar un valor de mercado, mostraremos el valor como "N/A" (No disponible). Este es el método utilizado de forma predeterminada en el pasado.',
          title: () =>
            "Utilizar el método del valor de mercado (predeterminado)",
        },
        table: {
          defaultValuationMethod: () =>
            "Método del valor de mercado (predeterminado)",
          tooltip: () =>
            'Para esta tabla se utiliza el método de valoración predeterminado, lo que significa que se utilizan valores de mercado para todos los activos. Cuando no se puede recuperar un precio de mercado y, por lo tanto, no se puede determinar un valor de mercado, mostraremos el valor como "N/A".',
        },
        title: () => "Informe de negociación de acciones",
      },
      turbotaxModal: {
        buttonText: () => "Vaya a TurboTax ahora",
        helpLinkText: () => "Guía de importación en línea de TurboTax",
        subtitle: () =>
          "Este informe se puede importar a TurboTax en línea siguiendo las instrucciones a continuación.",
        title: () => "Informe de TurboTax",
      },
    },
    downloadReports: {
      downloadReportAnyway: () => "Descargar informe de todos modos",
      primaryButton: () => "Descargar informes",
      quickDownload: () => "Descarga rápida",
      title: () => "Informes",
    },
    emailReportsModal: {
      always: () => "Envíe siempre los informes por correo electrónico",
      body: () =>
        "Este es un informe extenso y puede tardar más de un minuto en generarse. ¿Le gustaría recibir este informe por correo electrónico una vez que esté completo?",
      dontShowAgain: () => "No mostrar esto otra vez",
      emailMe: () => "Envíame un correo electrónico",
      illWait: () => "Esperaré",
      title: () => "¿Informe por correo electrónico?",
    },
    errorTitle: () => "Informar advertencias",
    estimatedGainBanner: {
      title: () => "Ganancia estimada",
      tooltip: () =>
        "La ganancia estimada representa el total de sus ganancias provenientes de las criptomonedas. Se calcula como Ganancias de capital + Ingresos - Pérdidas de capital. Esta estimación se basa en los datos importados hasta el momento. Para obtener un resultado preciso, se requiere una descripción completa de todas sus transacciones desde que compró criptomonedas por primera vez.",
    },
    expense: () => "Gastos",
    expenses: () => "Gastos",
    fee: () => "Tarifa",
    fees: () => "Honorarios",
    financialYear: () => "Año financiero",
    form3916ExchangeInfo: {
      address: () => "DIRECCIÓN",
      company: () => "Designación de la organización de gestión de cuentas (*)",
      country: () => "País",
      title: () => "Declaración de Cuentas en el Exterior",
      website: () =>
        "URL del sitio web de la organización de gestión de cuentas",
    },
    fyChip: (d) => "A " + d.timeframe,
    gain: () => "Ganancia (pérdida)",
    gainNoCGT: () => "Ganancia (pérdida)",
    grid: {
      softwareConnections: () => "Conexiones de software",
      taxReports: () => "Informes fiscales",
      txTypes: () => "Tipos de transacciones",
    },
    headings: {
      derivativeTradingIncome: () => "Ingresos por operaciones con derivados",
      expenses: () => "Gastos",
      gains: () => "Ganancias sobre el capital",
      longTermCapitalGains: () => "Ganancias de capital a largo plazo",
      longTermCapitalLosses: () => "Pérdidas de capital a largo plazo",
      nonTaxableTransactions: () => "Transacciones no sujetas a impuestos",
      ordinaryIncome: () => "Ingresos ordinarios",
      shortTermCapitalGains: () => "Ganancias de capital a corto plazo",
      shortTermCapitalLosses: () => "Pérdidas de capital a corto plazo",
      totalCapitalGains: () => "Ganancias netas de capital",
      totalExpenses: () => "Gastos netos",
      totalIncome: () => "Lngresos netos",
      totalLosses: () => "Pérdidas",
    },
    income: () => "Ingreso",
    incurredTime: () => "Incurrido",
    interestIncome: () => "Interés",
    inventory: {
      endBalance: () => "Saldo de cierre",
      endCostBasis: () => "Costo de cierre",
      endValue: () => "Valor de mercado de cierre",
      startBalance: () => "Saldo inicial",
      startCostBasis: () => "Costo de apertura",
      startValue: () => "Valor de mercado inicial",
    },
    inventoryMethod: () => "Método de inventario",
    loanRepayments: () => "Reembolsos de préstamos",
    lockedPeriod: {
      all: () => "Este período está bloqueado",
      costBasisTracking: () =>
        "No se puede modificar la configuración de seguimiento de la base de costos mientras se utilizan períodos bloqueados",
      multipleInventoryMethods: () => "Varios",
      none: () => "Período de bloqueo",
      some: () => "Este período contiene transacciones bloqueadas",
      tooltip: () =>
        "No puedes modificar la configuración durante un período bloqueado",
    },
    longShortIncome: () => "Ingresos por operaciones con margen",
    longTermGains: () => "Ganancias de capital a largo plazo",
    longTermGainsNoCGT: () => "Beneficios realizados a largo plazo",
    lookTooltip: (d) => "[Suscríbete]( " + d.linkTextKey + " ) para ver",
    miningRewards: () => "Minería",
    miscellaneousExpenses: () => "Gastos",
    missingPurchase: {
      text: () => "Compra faltante",
      tooltip: (d) =>
        "Falta la compra para esta disposición, hemos asumido una base de costo de $0.00. [Resolver compra faltante]( " +
        d.linkTextKey +
        " ) historial para asignar correctamente la base de costo.",
    },
    modifyTaxSettings: () => "Editar",
    nav: {
      reportSummary: () => "Resumen del informe",
      taxReports: () => "Informes fiscales",
    },
    noFrenchReportDataModal: {
      body1: (d) =>
        "No hay transacciones criptográficas a fiat registradas para " +
        d.year +
        " , por lo que no se requiere Forumalaire 2086.",
      body2: (d) =>
        "Si esperaba tener algunas transacciones de criptomonedas a moneda fiduciaria durante " +
        d.year +
        " , verifique que se hayan importado todas las transacciones. Verifique otros informes para ver si hay posibles transacciones de ingresos.",
      title: (d) =>
        "No se han realizado transacciones entre criptomonedas y monedas fiduciarias en " +
        d.year,
    },
    noRecordsFound: () => "No se encontraron registros relevantes...",
    nzFinancialYear: (d) =>
      "1 de abril " + d.prevYear + " - 31 de marzo " + d.year,
    opened: () => "Abierto",
    ordinaryIncome: () => "Ingresos ordinarios",
    otherIncome: () => "Ingreso",
    otherReports: () => "Otros informes e integraciones",
    overallGain: () => "Ganancia general",
    packs: {
      customModal: {
        columns: {
          details: () => "Detalles",
          report: () => "Informe",
        },
        csv: () => "CSV",
        downloadSelected: (d) =>
          select(d.isFree, {
            true: " Descargar informes de muestra ( " + d.selectedCount + " ) ",
            other: " Descargar seleccionados ( " + d.selectedCount + " ) ",
          }),
        getSample: () => "Obtener muestra",
        pdf: () => "PDF",
        selectPlanBanner: {
          body: () => "Seleccione un plan para descargar sus informes",
          cta: () => "Seleccione un plan",
        },
        title: () => "Informes fiscales",
        txf: () => "Archivo TXF",
        upgrade: (d) =>
          "Actualice a " + d.planTitle + " para acceder a este informe",
        xml: () => "XML",
      },
      filters: {
        accountant: () => "Para contables",
        all: () => "Todo",
        self: () => "Para auto-informe",
      },
      metadata: {
        8949: {
          description: () => "Ventas y otras disposiciones",
          title: () => "Formulario 8949 del IRS",
        },
        accountant: {
          description: () => "Envíalo a tu contable",
          title: () => "Paquete de contable",
        },
        custom: {
          description: () => "Ver todos los informes disponibles",
          title: () => "Costumbre",
        },
        form3916bis: {
          description: () => "Declaración de Cuentas Extranjeras",
          title: () => "Formulario 3916 bis",
        },
        fr2086: {
          description: () => "Para la presentación de operaciones gravables",
          title: () => "Formulario n°2086",
        },
        mygov: {
          description: () => "Presentar archivo a través de MyGov",
          title: () => "Informe de la ATO (MyTax)",
        },
        scheduleS1AndSD: {
          description: () => "Ingresos adicionales y ajustes a los ingresos",
          title: () => "Formularios 1040 s1 y sD del IRS",
        },
        self: {
          description: () => "Para presentar sus propios impuestos",
          title: () => "Autoinforme",
        },
        taxLots: {
          description: () =>
            "Vea una instantánea de los lotes que componen su inventario",
          title: () => "Instantánea del inventario",
        },
        turbotax: {
          description: () => "Para importar a TurboTax Online",
          title: () => "TurboTax en línea",
        },
      },
    },
    paywallBannerFYText: () =>
      "Seleccione un plan para ver informes de varios años fiscales",
    paywallBannerText: () => "Seleccione un plan para ver su informe",
    paywallBannerTextCollaborator: () =>
      "Pídale a su contador que se suscriba para ver su informe",
    paywallBannerTextMap: {
      accountant: {
        accountantOrCollaboratorView: {
          free: () =>
            "Seleccione un plan para ver las ganancias y descargar los informes de sus clientes",
          importCount: () =>
            "Su cliente ha alcanzado el límite de importación para este plan. Actualice para ver los informes de su cliente",
          tradeType: () =>
            "Su cliente tiene transacciones de contrato inteligente que no son compatibles con este plan. Actualice para ver los informes de su cliente",
          txCount: () =>
            "Su cliente ha alcanzado el límite de transacciones para este plan. Actualice para ver los informes de su cliente",
          unpaid: () =>
            "Tiene una suscripción pendiente de pago. Pague la suscripción pendiente para ver los informes de sus clientes",
        },
        clientView: {
          free: () =>
            "Pídale a su contador que se suscriba para ver su informe",
          importCount: () =>
            "Ha alcanzado el límite de importaciones para su plan. Solicite a su contador que se suscriba para ver su informe",
          tradeType: () =>
            "Tiene transacciones de contratos inteligentes que no son compatibles con su plan. Pídale a su contador que se suscriba para ver su informe",
          txCount: () =>
            "Ha alcanzado el límite de transacciones de su plan. Solicite a su contador que se suscriba a un plan superior para poder ver su informe.",
          unpaid: () =>
            "Pídale a su contador que se suscriba para ver su informe",
        },
      },
      both: {
        accountantOrCollaboratorView: {
          free: () =>
            "Seleccione un plan para ver las ganancias y descargar los informes de sus clientes",
          importCount: () =>
            "Su cliente ha alcanzado el límite de importación para este plan. Actualice o solicite a su cliente que actualice para ver los informes de su cliente",
          tradeType: () =>
            "Su cliente tiene transacciones de contrato inteligente que no son compatibles con este plan. Actualice o solicite a su cliente que actualice para ver los informes de su cliente",
          txCount: () =>
            "Su cliente ha alcanzado el límite de transacciones para este plan. Actualice su plan o pídale a su cliente que lo actualice para poder ver sus informes",
          unpaid: () =>
            "Tiene una suscripción pendiente de pago. Pague la suscripción pendiente para ver los informes de sus clientes",
        },
        clientView: {
          free: () =>
            "Seleccione un plan o solicite a su contador que se suscriba para ver su informe",
          importCount: () =>
            "Ha alcanzado el límite de importaciones para su plan. Seleccione un plan o solicite a su contador que se suscriba para ver su informe",
          tradeType: () =>
            "Tiene transacciones de contratos inteligentes que no son compatibles con su plan. Seleccione un plan o solicite a su contador que se suscriba para ver su informe",
          txCount: () =>
            "Ha alcanzado el límite de transacciones de su plan. Seleccione un plan o solicite a su contador que se suscriba a un plan superior para ver su informe.",
          unpaid: () =>
            "Su suscripción no está paga. Seleccione un plan o solicite a su contador que se suscriba para ver su informe",
        },
      },
      client: {
        accountantOrCollaboratorView: {
          free: () => "Seleccione un plan para ver el informe de su cliente",
          importCount: () =>
            "Su cliente ha alcanzado el límite de importación para este plan. Pídale a su cliente que actualice su plan para ver su informe",
          tradeType: () =>
            "Su cliente tiene transacciones de contrato inteligente que no son compatibles con este plan. Pídale a su cliente que actualice su plan para ver su informe",
          txCount: () =>
            "Su cliente ha alcanzado el límite de transacciones para este plan. Pídale a su cliente que actualice su plan para ver su informe",
          unpaid: () =>
            "Su cliente tiene una suscripción pendiente de pago. Pídale que pague la suscripción pendiente para ver su informe",
        },
        clientView: {
          free: () => "Seleccione un plan para ver su informe",
          importCount: () =>
            "Has alcanzado el límite de importaciones para tu plan. Selecciona un plan para ver tu informe",
          tradeType: () =>
            "Tiene transacciones de contratos inteligentes que no son compatibles con su plan. Seleccione un plan para ver su informe",
          txCount: () =>
            "Has alcanzado el límite de transacciones de tu plan. Suscríbete a un plan superior para ver tu informe",
          unpaid: () =>
            "Su suscripción no está pagada. Suscríbase para ver su informe",
        },
      },
      user: {
        free: () => "Seleccione un plan para descargar su informe",
        importCount: () =>
          "Has alcanzado el límite de importaciones para tu plan. Selecciona un plan para descargar tu informe",
        tradeType: () =>
          "Tiene transacciones de contratos inteligentes que no son compatibles con su plan. Seleccione un plan para descargar su informe",
        txCount: () =>
          "Has alcanzado el límite de transacciones de tu plan. Suscríbete a un plan superior para ver tu informe",
        unpaid: () =>
          "Su suscripción no está pagada. Suscríbase para ver su informe",
      },
    },
    paywalledNumberTooltip: () => "Suscríbete para ver",
    pdf: () => "PDF",
    proceeds: () => "Producto",
    quantity: () => "Cantidad",
    receivedTime: () => "Recibió",
    reportDetails: () => "Detalles del informe",
    reportSubTitles: {
      "annex-so-other-income": () =>
        "Informe alemán con Anlage SO autocompletado",
      audit: () =>
        "Enumera todas las transacciones, su origen y las modificaciones registradas por los usuarios.",
      bgl: () => "Permite la importación al software BGL Simple Fund 360",
      "capital-gains": () =>
        "Eventos de ganancias de capital por compra y venta de criptomonedas",
      "capital-gains-nz": () =>
        "Eventos de ganancias/pérdidas realizadas por la compra y venta de criptomonedas",
      cerfa2086: () => "Para la presentación de operaciones gravables",
      expense: () => "Gastos varios incurridos al operar con criptomonedas",
      form3916bis: () => "Declaración de cuentas en el exterior",
      "gift-and-lost": () =>
        "Registro detallado de cada regalo saliente y transacción perdida o robada",
      income: () => "Ingresos obtenidos al recibir criptomonedas",
      inventory: () => "Resumen de stock de inicio y fin de año",
      "inventory-v2": () => "Resumen de stock de inicio y fin de año",
      "irs-1040-s1": () => "Ingresos adicionales y ajustes a los ingresos",
      "irs-1040-sd": () => "Ganancias y pérdidas de capital",
      "irs-8949": () => "Ventas y otras enajenaciones de activos de capital",
      "irs-8949-consolidated": () =>
        "Ventas y otras enajenaciones de activos de capital (consolidadas por moneda)",
      "modelo-100": () =>
        "Formulario Modelo 100 autocompletado con plusvalías e ingresos",
      mygov: () => "Para declarar sus impuestos usted mismo en MyGov",
      "nft-report": () =>
        "Ganancias de capital y regalías por compra y venta de NFT",
      "report-summary": () =>
        "Resumen de alto nivel de su actividad comercial completa",
      "start-of-year-holdings": () =>
        "Resumen de sus tenencias al inicio del año",
      "tax-lots": () =>
        "Vea una instantánea de los lotes que componen su inventario",
      "taxact-csv": () => "Permite la importación al software TaxAct Online",
      "trading-pnl": () =>
        "Ganancias o pérdidas de operaciones con derivados, márgenes y futuros",
      "trading-stock": () => "Se utiliza si está operando como empresa.",
      transaction: () => "Registros detallados de cada transacción",
      "turbotax-canadian-pdf": () =>
        "Permite pegar en TurboTax Online para usuarios canadienses",
      "turbotax-csv": () =>
        "Permite la importación al software TurboTax Online",
      "turbotax-txf": () =>
        "Permite la importación al software TurboTax CD/DVD",
      "wiso-steuer": () => " Control WISO en formato csv",
    },
    reportType: {
      "annex-so-other-income": () =>
        "Informe alemán - Anlage So (Sonstige Einkünfte)",
      audit: () => "Informe de transacción de auditoría",
      bgl: () => "Exportación de datos de BGL Simple Fund 360",
      "capital-gains": () => "Informe de ganancias de capital",
      "capital-gains-nz": () => "Informe de ganancias/pérdidas realizadas",
      cerfa2086: () => "Formulario n°2086",
      expense: () => "Informe de gastos varios",
      form3916bis: () => "Formulario 3916 bis",
      "gift-and-lost": () =>
        "Informe de obsequios salientes y bienes perdidos o robados",
      income: () => "Informe de ingresos",
      inventory: () => "Informe de inventario",
      "inventory-v2": () => "Informe de inventario",
      "irs-1040-s1": () => "Anexo 1 del IRS (Formulario 1040)",
      "irs-1040-sd": () => "Anexo D (Formulario 1040)",
      "irs-8949": () => "Formulario 8949 del IRS",
      "irs-8949-consolidated": () => "Formulario 8949 del IRS (consolidado)",
      "irs-8949-long": () => "Formulario 8949 a largo plazo",
      "irs-8949-long-consolidated": () =>
        "Formulario 8949 a largo plazo (consolidado)",
      "irs-8949-short": () => "Formulario 8949 Corto Plazo",
      "irs-8949-short-consolidated": () =>
        "Formulario 8949 Corto Plazo (Consolidado)",
      "modelo-100": () => "Modelo 100",
      mygov: () => "Informe MyTax de la ATO",
      "nft-report": () => "Informe NFT",
      "report-summary": () => "Resumen del informe",
      "start-of-year-holdings": () => "Informe de tenencias de inicio de año",
      "tax-lots": () => "Informe de resumen de inventario",
      "taxact-csv": () => "TaxAct - Informe de ganancias de capital",
      "trading-pnl": () => "Informe sobre operaciones con derivados",
      "trading-stock": () => "Informe de negociación de acciones (Trader)",
      transaction: () => "Informe de transacciones",
      "turbotax-canadian-pdf": () => "PDF de TurboTax",
      "turbotax-csv": () => "TurboTax - Informe de ganancias de capital",
      "turbotax-txf": () => "TurboTax TXF - Informe de ganancias de capital",
      "wiso-steuer": () => "Informe CSV de WISO Steuer",
    },
    reportTypeSelector: () => "Tipo de informe",
    rewardsAndInterest: () => "Recompensas e intereses",
    rowsPerPage: () => "Filas por página",
    royalties: () => "Regalías",
    saFinancialYear: (d) =>
      "1 de marzo " +
      d.prevYear +
      " - " +
      d.febLastDay +
      " de febrero de " +
      d.year,
    saveMoney: () => "¡Complete los pasos de revisión para ahorrar dinero!",
    savingsOpportunity: () => "Oportunidad de ahorro",
    selectPlan: () => "Seleccione un plan",
    sellTime: () => "Vendido",
    shortTermGains: () => "Ganancias de capital a corto plazo",
    shortTermGainsNoCGT: () => "Ganancias totales realizadas",
    stakingIncome: () => "Recompensas por participación",
    summary: {
      reviewTips: {
        button: () => "Revisar transacciones para ahorrar",
        subTitle: {
          control: () => "Tu oportunidad de ahorro es ",
          ourTaxMinimisationAlgorithmCouldSaveYou: () =>
            "Nuestro algoritmo de minimización de impuestos podría ahorrarle dinero ",
          youCouldMinimiseYourTaxLiabilityBy: () =>
            "Podría minimizar su obligación tributaria al: ",
          youCouldSaveXyzInTaxes: {
            prefix: () => "Podrías ahorrar ",
            suffix: () => " en impuestos",
          },
        },
        title: () => "¡No pagues más impuestos! 💸",
        tooltip: () =>
          "La cantidad estimada en la que podría reducir su ganancia de capital al categorizar y resolver todos los problemas en revisión.",
      },
      title: () =>
        "Suscríbete para ver consejos sobre cómo ahorrar en tu declaración de impuestos",
      txs: (d) => plural(d.count, 0, es, { 1: " tx ", other: " txs " }),
    },
    tablePagination: (d) => d.from + " - " + d.to + " de " + d.count,
    taxReports: () => "Informes fiscales",
    taxSettingWarnings: {
      uncategorisedBothAsTaxable: () =>
        "Las transacciones salientes no categorizadas se tratan como ventas en el mercado y las transacciones entrantes no categorizadas se tratan como compras en el mercado.",
      uncategorisedInAsTaxable: () =>
        "Transacciones entrantes no categorizadas tratadas como compras en el mercado",
      uncategorisedOutAsTaxable: () =>
        "Transacciones salientes no categorizadas tratadas como ventas en el mercado",
    },
    taxSettings: () => "Configuración de impuestos",
    taxablePortfolioValue: () => "Valor de la cartera",
    tooltips: {
      airdrop: () =>
        "Ingresos obtenidos a partir de criptomonedas lanzadas desde el aire",
      approvals: () =>
        "Cargos de aprobación incurridos al operar con criptomonedas",
      capitalLosses: () =>
        "Pérdidas de capital totales realizadas a lo largo del ejercicio",
      capitalLossesNoCGT: () =>
        "Pérdidas totales realizadas durante el ejercicio económico",
      derivativeTradingIncome: () =>
        "Ganancias o pérdidas de operaciones con derivados, márgenes y futuros",
      expenses: () =>
        "Gastos varios en los que se incurre al operar con criptomonedas",
      fees: () => "Tarifas que se aplican al operar con criptomonedas",
      income: () =>
        "Otros ingresos recibidos, como por recompensas, referencias, bonificaciones, etc.",
      loan: () =>
        "Interés y/o capital pagado por mantener una posición abierta de préstamo o margen",
      longTermCapitalLosses: (d) =>
        "Pérdidas de capital totales realizadas de activos mantenidos durante más de " +
        d.longTermThreshold +
        " meses",
      longTermThreshold: (d) =>
        "Ganancias de capital totales realizadas a partir de activos mantenidos durante más de " +
        d.longTermThreshold +
        " meses",
      longTermThresholdNoCGT: (d) =>
        "Ganancias totales de activos mantenidos durante más de " +
        d.longTermThreshold +
        " meses",
      mining: () => "Recompensas mineras que se consideran ingresos",
      miscellaneousExpenses: () =>
        "Gastos varios incurridos a lo largo del ejercicio",
      miscellaneousIncome: () =>
        "Ingresos varios obtenidos durante el ejercicio económico",
      noCapitalGains: () =>
        "Ganancias de capital totales realizadas a lo largo del ejercicio",
      noCapitalGainsNoCGT: () =>
        "Beneficios totales obtenidos a lo largo del ejercicio",
      noLongTermThreshold: () =>
        "No hay umbral a largo plazo, todas las ganancias se consideran a corto plazo",
      pnl: () =>
        "Ganancias obtenidas a partir de márgenes comerciales, futuros, perpetuos, opciones, etc.",
      royalty: () => "Ingresos obtenidos por regalías",
      shortTermCapitalLosses: (d) =>
        "Pérdidas de capital totales realizadas de activos mantenidos durante menos de " +
        d.longTermThreshold +
        " meses",
      staking: () =>
        "Ingresos obtenidos por intereses y recompensas de staking",
      totalCapitalGains: (d) =>
        "Ganancias de capital totales realizadas a partir de activos mantenidos durante menos de " +
        d.longTermThreshold +
        " meses",
      totalCapitalGainsNoCGT: (d) =>
        "Ganancias/pérdidas totales realizadas de activos mantenidos durante menos de " +
        d.longTermThreshold +
        " meses",
      totalGains: () =>
        "Ganancias netas de capital, más ingresos netos, menos gastos netos",
    },
    totalCapitalGains: () => "Ganancias totales de capital",
    totalCapitalGainsNoCGT: () => "Ganancias/pérdidas totales realizadas",
    totalGains: () => "Ganancias totales",
    totalLosses: () => "Pérdidas totales de capital",
    totalLossesNoCGT: () => "Pérdidas totales realizadas",
    tradingPnL: () => "PnL de operaciones",
    tradingStockAu: {
      acquiredStockQuantity: () => "Cantidad adquirida",
      acquiredTotalValue: () => "Valor adquirido",
      currency: () => "Divisa",
      disposedStockQuantity: () => "Cantidad eliminada",
      disposedTotalValue: () => "Valor enajenado",
      endingPrice: () => "Precio final",
      endingStock: () => "Stock final",
      endingValue: () => "Valor final",
      netProfit: () => "Beneficio neto",
      startingPrice: () => "Precio inicial",
      startingStock: () => "Stock inicial",
      startingValue: () => "Valor inicial",
    },
    txf: () => "Archivo TXF",
    ukFinancialYear: (d) =>
      "6 de abril " + d.prevYear + " - 5 de abril " + d.year,
    unknownGains: () => "Ganancias desconocidas",
    unknownGainsNoCGT: () => "Ganancias desconocidas",
    upgradeToPlanTooltip: (d) =>
      "Actualice al " + d.plan + " para acceder a este informe",
    viewAll: () => "Ver todo",
    viewOtherReports: () => "Ver otros informes",
    warning: () =>
      "Las advertencias pueden provocar cálculos incorrectos. Asegúrese de haber importado todos los datos y de haber confirmado las transacciones.",
    warningActionText: () => "Revisar transacciones",
    warningTitle: () => "Tiene advertencias de transacciones pendientes",
    warningZeroCost: {
      bodyText1: () => "Hemos detectado problemas con tus transacciones ",
      bodyText2: () =>
        "Una transacción con costo cero ocurre cuando hay una venta pero no hay activos disponibles. Puede reducir sus impuestos solucionando los problemas con los activos faltantes. También puede ignorar esta advertencia. ",
      bodyValuePostNumber: () => ". ",
      bodyValuePreNumber: () => "totalizando ",
      breakdownPrefix: () =>
        "Principales divisas con problemas no resueltos y activos asumidos vendidos",
      buttonText: () => "Ver todas las monedas",
      includedInGains: () => "¡No pagues más de lo necesario!",
      innerBodyText: () =>
        "Hemos asumido un coste de cero para los activos vendidos.",
      innerTitle: () => "Todas las monedas con problemas no resueltos",
      title: () =>
        "Se necesitan revisiones: la ganancia obtenida puede ser inexacta.",
      titleNoCGT: () =>
        "Se necesitan revisiones: las ganancias obtenidas pueden ser inexactas.",
    },
    warningZeroCostSummary: {
      body: (d) =>
        "Hemos asumido un costo de cero para el total de activos vendidos " +
        d.total +
        " . Puede reducir sus impuestos solucionando los problemas con los activos faltantes.",
      title: () => "Se necesita revisión",
    },
    wentWrongDownloadingBGL: () =>
      "Algo salió mal al exportar el XML de BGL Simple Fund 360.",
    wentWrongDownloadingCSV: () => "Algo salió mal al descargar el CSV.",
    wentWrongDownloadingPDF: () => "Algo salió mal al descargar el PDF.",
    wentWrongDownloadingTXF: () =>
      "Algo salió mal al descargar el TXF de TurboTax.",
    wentWrongRefreshing: () => "Algo salió mal al actualizar el informe.",
    xml: () => "XML",
    ytd: () => "Año fiscal hasta la fecha",
  },
  reportError: {
    button: {
      display: () => "Mostrar errores",
      hide: () => "Ocultar errores",
    },
    soldOut: () =>
      "Vender transacciones sin saldo restante suficiente: se asume un costo base de cero dólares",
    title: () => "Comprobación de cordura",
    tooltip: {
      soldOut: () =>
        "Supusimos que el costo de estas transacciones era cero dólares. Esto se debe a que se realizan transacciones de venta sin que quede suficiente cantidad. Si se trata de una cantidad grande, puede deberse a transacciones faltantes. De lo contrario, si se trata de una cantidad pequeña, puede deberse a intereses de cambio, redondeo de tarifas, etc. Puede filtrar por errores en la página de revisión de transacciones.",
      uncategorized: () =>
        "Ignoramos estas transacciones al realizar los cálculos. Puede hacer clic en el enlace para categorizar las transacciones de entrada y salida.",
    },
    uncategorized: () =>
      "Transacciones no categorizadas: estas transacciones han sido ignoradas",
  },
  reset: () => "Reiniciar",
  retry: () => "Rever",
  rules: {
    addRule: () => "Agregar regla",
    categoryRuleWarning: () =>
      "Para crear una regla de categoría, asegúrese de que una de sus condiciones sea que la categoría de transmisión sea solo entrante o saliente.",
    createRule: () => "Crear regla",
    deleteDialog: {
      text: {
        checkbox: () =>
          "Permitir que otras reglas sobrescriban los cambios realizados previamente por esta regla",
        lineOne: () => "Esto será",
        lineTwo: () =>
          "Tenga en cuenta que los cambios realizados por esta regla permanecerán vigentes a menos que se modifiquen manualmente o mediante una nueva regla.",
        listOne: () =>
          "Separar esta regla de las transacciones a las que se ha aplicado",
        listTwo: () =>
          "Detener la aplicación de la regla a futuras transacciones",
      },
      title: () => "Eliminar regla",
    },
    disableDialog: {
      button: () => "Desactivar",
      text: {
        listOne: () =>
          "Esto impedirá que la regla se aplique a futuras transacciones.",
        listThree: () =>
          "Siempre puedes volver a habilitar esta regla más tarde.",
        listTwo: () =>
          "Las transacciones que tengan esta regla aplicada actualmente seguirán viéndose afectadas por ella. Esto significa que las nuevas reglas con los mismos criterios no anularán esta regla.",
      },
      title: () => "Deshabilitar regla",
    },
    enableDialog: {
      button: () => "Permitir",
      text: {
        listOne: () =>
          "Al habilitar esta regla, se aplicará a cualquier transacción que cumpla sus condiciones y que aún no tenga una regla aplicada.",
      },
      title: () => "Habilitar regla",
    },
    learnAboutRules: {
      button: () => "Conozca las reglas",
      content: () =>
        "Las reglas le permiten automatizar tanto la categorización de sus transacciones como la asignación del plan de cuentas para cada transacción. Esto puede resultar útil cuando tiene muchas acciones comunes que desea que CTC realice por usted.",
      content2list1: () =>
        "Las reglas se aplican en el orden en que aparecen en la tabla. Esto significa que si tiene dos reglas que podrían aplicarse a una transacción, la que esté más arriba en la tabla siempre tendrá prioridad.",
      content2list2: () =>
        "Las reglas no se pueden aplicar parcialmente. Esto significa que, si tiene una regla de mayor prioridad que solo establece la cuenta de activos y luego una regla de menor prioridad que aplica tanto a las cuentas de activos como a las de ganancias, solo se aplicará la regla de mayor prioridad debido al primer punto y, luego, la segunda no se puede aplicar porque la cuenta de activos ya ha sido establecida por una regla.",
      content2list3: () =>
        "Las reglas no se pueden sobrescribir una vez aplicadas. Esto significa que si tiene una regla existente aplicada a una transacción y luego crea una nueva regla que también se aplicaría a esta transacción y le otorga una prioridad más alta, seguirá sin aplicarse a esta transacción, a menos que elimine la primera regla.",
      content2part1: () =>
        "Hay tres cosas principales que recordar al trabajar con reglas:",
      content2part2: () =>
        "¡Al recordar estos principios podrás dominar las reglas y acelerar tus flujos de trabajo!",
      content3: () =>
        "Puede crear reglas directamente desde esta página, o en la libreta de direcciones o en las páginas de integraciones para reglas relacionadas con billeteras y exchanges específicos, pero también puede crear reglas en contexto en la página de transacciones. Al categorizar transacciones o definir el plan de cuentas para una transacción, verá la opción de aplicar esta elección a transacciones futuras. Si selecciona esta opción, se creará una regla basada en su elección y un criterio que identifique transacciones similares a la que está trabajando actualmente, y se agregará automáticamente al final de la jerarquía en la página de reglas.",
      subtitle1: () => "¿Qué son las reglas?",
      subtitle2: () => "¿Cómo funcionan las reglas?",
      subtitle3: () => "¿Por dónde empiezo con las reglas?",
      title: () => "Conozca las reglas",
    },
    navTabs: {
      rules: () => "Reglas activas",
      rulesDisabled: () => "Reglas para discapacitados",
    },
    operationShorthands: {
      changeErpAssetAccount: () => "Activo",
      changeErpCashAccount: () => "Dinero",
      changeErpGainsAccount: () => "Ganancias",
      changeErpLoanAccount: () => "Préstamo",
      changeErpPnLAccount: () => "Pérdidas y ganancias",
      recategorise: () => "Categoría",
    },
    operatorSelection: {
      accountPlaceholder: () => "Seleccione una cuenta",
      categoryPlaceholder: () => "Seleccione una categoría",
    },
    pageTitle: () => "Normas",
    rulesTable: {
      empty: () => "Sin reglas",
      headers: {
        accountsMapping: () => "Mapeo de cuentas",
        conditions: () => "Condiciones",
        name: () => "Nombre",
      },
      loadMore: () => "Cargar más",
      row: {
        options: {
          delete: () => "Eliminar regla",
          deleteInfo: () =>
            "La regla será eliminada, las transacciones donde la regla ha sido aplicada serán libres de tener nuevas reglas aplicadas a ellas.",
          disable: () => "Deshabilitar regla",
          disableInfo: () =>
            "La regla no se aplicará a las nuevas transacciones. Sin embargo, las transacciones existentes seguirán rigiéndose por esta regla.",
          edit: () => "Editar",
          enable: () => "Habilitar regla",
          moveToBottom: () => "Mover hacia abajo",
          moveToTop: () => "Mover al inicio",
          viewRule: () => "Ver regla",
          viewTxs: () => "Ver transacciones",
        },
      },
    },
  },
  save: () => "Ahorrar",
  selectCountry: {
    autoDetectedCountry: {
      applyLatestRules: (d) =>
        "Aplicaremos las últimas normas fiscales " + d.authority + ".",
      countryDetected: (d) =>
        "Parece que estás en " +
        select(d.prefixCountryWithThe, { true: " the ", other: "" }) +
        d.countryName +
        ".",
      plusMore: (d) => "+ " + d.count + " más",
      selectAnotherCountry: () => "O seleccione otro país",
      submitCTA: () => "Vamos",
    },
    body: () =>
      "Su país debe determinar las reglas fiscales para esta calculadora",
    continue: () => "Continuar",
    loadingOption: () => "Opciones de carga...",
    noOptions: () => "Sin opciones",
    noResults: () => "No hay resultados",
    optimizeTaxSettings: {
      AT: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del BMF.",
      AU: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de la ATO.",
      BE: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del FPSF.",
      CA: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de la CRA.",
      CH: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de la SFTA.",
      DE: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del BZSt.",
      ES: () =>
        "Optimizaremos tu configuración fiscal para cumplir con los requisitos de la AEAT.",
      FI: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del TLC.",
      FR: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de la DGFIP.",
      GR: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de la IAPR.",
      IE: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del TRC.",
      IN: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del ITR.",
      IT: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del ADE.",
      JP: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de la NTA.",
      NL: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de la DTCA.",
      NO: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de la NTA.",
      NZ: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del IRD.",
      PT: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de la PTCA.",
      SE: () =>
        "Optimizaremos su configuración de impuestos para cumplir con los requisitos de la STA.",
      SG: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del IRAS.",
      UK: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos de HMRC.",
      US: () =>
        "Optimizaremos su configuración de impuestos para cumplir con los requisitos del IRS.",
      ZA: () =>
        "Optimizaremos su configuración fiscal para cumplir con los requisitos del SARS.",
    },
    optimizeTaxSettingsGeneric: (d) =>
      "Optimizaremos su configuración de impuestos para cumplir con los requisitos de " +
      d.taxAuthority +
      ".",
    pending: () => "Pendiente",
    reportForms: {
      ATO: () => "ATO",
      Form8949: () => "Formulario 8949",
      HMRC: () => "Hacienda y Aduanas de Su Majestad",
      IRS: () => "Hacienda",
      adjustedCostBase: () => "Base de costo ajustada",
      averageCostBase: () => "Base de costo promedio",
      bedAndBreakfast: () => "Alojamiento y desayuno durante 30 días",
      capitalGainsReport: () => "Informe de ganancias de capital",
      cerfa2086: () => "Formulario n°2086",
      cra: () => "Agencia de Protección Ambiental (CRA)",
      incomeReport: () => "Informe de ingresos",
      myTax: () => "Informe myTax de la ATO",
      reports: () => "Informes fiscales",
      sameDay: () => "Regla del mismo día",
      schedule3: () => "Anexo 3",
      scheduleD: () => "Anexo D 1040",
      superficialLoss: () => "Regla de pérdida superficial",
      t1Income: () => "Ingreso T1",
      turboTaxOnline: () => "TurboTaxOnline",
    },
    title: () => "Seleccione país",
    title2: () => "Seleccione país",
  },
  settings: {
    accounting: {
      dataManagement: () => "Gestión de datos",
      inviteLink: {
        body: () =>
          "Al utilizar este enlace, los clientes serán dirigidos a crear una cuenta en CTC. Sus datos se compartirán automáticamente con usted.",
        bodyWide: () =>
          "Comparte este enlace con tus clientes, quienes serán dirigidos a crear una cuenta en CTC. Sus datos serán compartidos contigo automáticamente.",
        copy: () => "Copiar",
        customiseLink: () => "Personalizar enlace",
        generatingCode: () => "Generando código...",
        new: () => "Nuevo",
        title: () => "Enlace de invitación personal",
        titleCollaborator: () => "Enlace de invitación para contables",
        titleWide: () => "Su enlace de invitación de cliente personal ",
        titleWideCollaborator: () =>
          "Enlace de invitación de cliente de su contable",
      },
      profile: () => "Configuraciones del contable",
      title: () => "Contabilidad",
    },
    accountingData: {
      updateDataSourceError: () =>
        "Se produjo un error al actualizar la fuente de datos. Inténtelo nuevamente más tarde.",
      updateDataSourceSuccess: () => "Fuente de datos actualizada con éxito",
    },
    billing: {
      description: {
        part1: () => "Administra tu suscripción. Ver ",
        part2: () => "Condiciones del servicio.",
      },
      manage: () => "Administrar suscripción",
      nextBilling: () => "Próxima fecha de facturación",
      nextBillingDetails: (d) => d.date + " por " + d.amount + " " + d.currency,
      perYear: () => "/año",
      title: () => "Detalles de la suscripción",
      upgrade: () => "Suscripción de actualización",
    },
    clearData: {
      buttonText: () => "Borrar datos",
      description: (d) =>
        select(d.isAccountant, {
          true: " Borra todos los datos de clientes de la cuenta, incluidas las transacciones y las fuentes de importación. Esta acción no es reversible. ",
          other:
            " Borra todos los datos de su cuenta. Esta acción no es reversible. ",
        }),
      text: (d) =>
        select(d.isAccountant, {
          true: " ¡Advertencia! ¿Realmente desea eliminar todos los datos de sus clientes? Esta acción no se puede deshacer. ",
          other:
            " ¡Advertencia! ¿Realmente desea eliminar todos sus datos? Esta acción no se puede deshacer. ",
        }),
      titleClient: (d) => "Borrar datos de " + d.clientName,
      titleClientFallback: () => "Borrar datos del cliente",
      titleSelf: () => "Borrar datos",
    },
    clientAccount: () => "Perfil del cliente",
    clientData: {
      dataBody: () => "Fuente de datos compartida entre usted y su cliente.",
      invitation: {
        status: {
          accepted: () => "Invitación aceptada",
          declined: () => "Invitación rechazada",
          default: () => "Invitar al contable",
          pending: (d) => "Invitación " + d.direction,
          revoked: () => "Invitación revocada",
        },
        verboseStatus: {
          accepted: () => "El cliente ha aceptado tu invitación",
          declined: () => "Parece que tu invitación fue rechazada",
          deleted: () => "Parece que tu invitación fue eliminada",
          pending: () =>
            "Invitación enviada. Recibirás un correo electrónico cuando tu cliente haya aceptado la invitación.",
          revoked: () => "El cliente ha revocado su invitación",
          revokedLink: () => "El cliente ha revocado su invitación",
        },
      },
      invitationBody: () =>
        "Agrega el correo electrónico de tu cliente para invitarlo a compartir y administrar datos. Una vez que tu cliente haya aceptado la invitación, no podrás cambiar su dirección de correo electrónico.",
      invitationStatus: () => "Estado de la invitación",
      paymentPlan: () => "Plan de pago",
      profile: () => "Datos del cliente",
      tooltip: () =>
        "Esta configuración sólo puede ser cambiada por el cliente",
    },
    dataCleared: () => "Datos borrados",
    exitSurvey: {
      cancellationReasonQuestion: (d) =>
        select(d.variation, {
          DeleteAccount:
            " Antes de eliminar su cuenta, ¿cuál es el motivo principal por el que canceló su servicio? ",
          other:
            " Antes de continuar, ¿cuál fue el motivo principal por el que canceló su plan? ",
        }),
      cancellationReasons: {
        accountant: () => "Utilizando un contable",
        countrySupport: () => "País no compatible",
        hardToUse: () => "Difícil de usar",
        importSupport: () => "No se admiten intercambios ni cadenas de bloques",
        incorrectResults: () => "Resultados incorrectos",
        noLongerTradingCrypto: () => "Ya no se comercia con criptomonedas",
        timeConsuming: () => "Pérdida de tiempo",
        tooExpensive: () => "Demasiado caro",
      },
      continue: () => "Continuar",
      otherCommentsQuestion: () => "¿Hay algo más que quieras que sepamos?",
      title: (d) =>
        select(d.variation, {
          DeleteAccount: " Eliminar su cuenta ",
          other: " Plan cancelado ",
        }),
    },
    hideSpamError: () =>
      "Algo salió mal al cambiar la configuración del filtro, inténtalo nuevamente más tarde",
    hmrc: {
      actionText: () => "Admitido",
      text: () =>
        "Los cálculos heredados de HM Revenue and Customs solo deben usarse como referencia; utilice el método actualizado para presentar sus impuestos.",
      title: () =>
        "Confirme que desea ver los cálculos heredados de HM Revenue and Customs",
    },
    lockPeriod: {
      add: {
        firstTransaction: () => "Tu primera transacción",
        from: () => "De",
        to: () => "A",
      },
      addButton: () => "Añadir período bloqueado",
      bannerText: () =>
        "El bloqueo sólo se recomienda para períodos anteriores que ya se hayan enviado a su oficina de impuestos.",
      beginningOfTime: () => "Comenzar",
      cancel: () => "Cancelar",
      currentPeriod: {
        tooltip: () =>
          "Haga clic en el botón Agregar período de bloqueo que se encuentra arriba para crear un nuevo período de bloqueo",
      },
      description: () =>
        "Al bloquear un período, se evitará que se produzcan cambios en las transacciones dentro de ese período. El propósito de esta herramienta es permitirle garantizar que sus cálculos de impuestos para años fiscales anteriores no cambien una vez que haya enviado sus informes fiscales.",
      dialog: {
        action: () => "Periodo de desbloqueo",
        checklist: {
          balances: () => "Saldos a cambiar",
          gains: () => "Ganancias calculadas para cambiar",
          reports: () => "Informes sobre cambios",
        },
        confirm: () =>
          "Confirme que reconoce los riesgos indicados anteriormente marcando las casillas de verificación antes de continuar.",
        text: () => "Desbloquear este período puede provocar:",
        title: () => "¿Periodo de desbloqueo?",
        warning: () => "Advertencia: esto no se puede deshacer.",
        warningParagraph: () =>
          "Para conservar una copia de los valores de sus informes actuales, le recomendamos que descargue sus informes de impuestos antes de desbloquear el año fiscal, ya que los valores pueden cambiar.",
        warningWithSubsequent: () =>
          "Advertencia: Todos los períodos siguientes también se desbloquearán. Esto no se puede deshacer.",
      },
      disabledCreateLock: () => "Estás encerrado hasta hoy",
      inventoryMethodDisabled: (d) =>
        "No se pueden agregar períodos bloqueados al utilizar el método de inventario " +
        d.inventoryMethod,
      inventorySnapshots: {
        closing: {
          description: () =>
            "Una instantánea de los lotes que componen su inventario al final del período, antes de que se apliquen redistribuciones para el siguiente período.",
          title: () => "Instantánea de inventario de cierre",
        },
        opening: {
          description: () =>
            "Una instantánea de los lotes que componen su inventario al inicio del período, después de que se hayan aplicado las redistribuciones necesarias.",
          title: () => "Apertura de la instantánea del inventario",
        },
        title: () => "Instantáneas de inventario",
      },
      issuesModal: {
        cancel: () => "Cancelar",
        currentIssues: () => "Cuestiones actuales",
        description: () =>
          "Algunos elementos necesitan su atención antes de bloquear este período para garantizar la precisión.",
        ignoreAndLock: () => "Ignorar y bloquear de todos modos",
        newIssueExplainer: () =>
          "Debido a que se está preparando para bloquear este período, hemos marcado las transferencias no coincidentes que deben resolverse para bloquear su base de costos correctamente.",
        title: () => "Cuestiones que deben abordarse antes del bloqueo",
        viewIssues: () => "Ver y resolver problemas",
        warning: () =>
          "Bloquear ahora sin solucionar estos problemas podría generar problemas de datos irresolubles y podría obligarlo a desbloquear este período más adelante.",
      },
      lockPeriodTooltip: () => "Período de bloqueo",
      lockUnlockedWarning: {
        action: () => "Bloquear de todos modos",
        line1: () =>
          "Asegúrese de haber conciliado todas las transacciones antes de bloquearlas. Si no lo hace, podrían surgir problemas en el futuro.",
        line2: () =>
          "Siempre puedes desbloquear estos períodos más tarde, pero no se conservarán los cambios en la configuración de impuestos.",
        title: () => "Confirmar periodos de bloqueo",
        warning: () =>
          "Advertencia: la creación de un nuevo período bloqueado también bloqueará cualquier período desbloqueado",
        warningLockExisting: () =>
          "Advertencia: al bloquear este período también se bloquearán todos los períodos desbloqueados anteriormente.",
      },
      noLockPeriods: () => "No tienes periodos bloqueados",
      now: () => "Ahora",
      saveButton: () => "Guardar período bloqueado",
      title: () => "Períodos de bloqueo",
      unlockAllSubsequentButtonTooltip: () =>
        "Todos los períodos posteriores a este también se desbloquearán.",
      unlockButtonTooltip: () => "Periodo de desbloqueo",
    },
    longTermThreshold: {
      custom: () => "Costumbre",
      default: (d) => "Predeterminado ( " + d.defaultValue + " + meses)",
      longTermThreshold: () => "Umbral de largo plazo",
      months: () => "meses",
      never: () => "Nunca",
    },
    manageData: {
      buttonText: () => "Eliminar cuenta",
      clear: () => "Claro",
      confirmClearData: () => "borrar datos para siempre",
      confirmClearDataPrompt: () =>
        "Escriba 'clear-data-forever' para proceder a borrar los datos de esta cuenta.",
      confirmDelete: () => "eliminar-esta-cuenta-para-siempre",
      confirmDeletePrompt: () =>
        "Escriba 'delete-this-account-forever' para proceder a eliminar esta cuenta.",
      delete: () => "Borrar",
      description: (d) =>
        select(d.isAccountant, {
          true: " Al eliminar la cuenta del cliente, se eliminarán tanto los datos como el cliente. Esta acción no es reversible. ",
          other:
            " Al eliminar permanentemente su cuenta, se eliminarán todos sus datos de nuestro sistema y se cerrará su sesión. ",
        }),
      manageData: () => "Gestionar datos",
      text: (d) =>
        select(d.isAccountant, {
          true: " ¡Atención! Se te desconectará de los datos de tu cliente y es posible que se eliminen. Su suscripción, si has pagado por una, se cancelará. No hay forma de deshacer esta acción. ",
          other:
            " ¡Atención! Se eliminarán todos tus datos. Una vez completado, se cerrará tu sesión y tu cuenta se eliminará de nuestro sistema. No hay forma de deshacer esta acción. ",
        }),
      titleClient: (d) => "Eliminar " + d.clientName + " como cliente",
      titleClientFallback: () => "Eliminar cliente",
      titleSelf: () => "Eliminar cuenta",
    },
    notifications: {
      errors: {
        generic: () =>
          "No se pudo actualizar la configuración de notificaciones. Comuníquese con el servicio de asistencia.",
      },
    },
    pageTitle: (d) => d.tab + " configuraciones",
    plan: {
      clientOwned: {
        body: () =>
          "Comuníquese directamente con el cliente y pídale que actualice su cuenta si desea acceder a funciones bloqueadas o a un mayor número de transacciones.",
        title: () => "Este cliente está administrando su propia facturación.",
      },
      title: () => "Planes",
    },
    portfolio: () => "Cartera",
    portfolioTab: {
      assetTracking: () => "Seguimiento de activos",
    },
    priceWindow: {
      off: () =>
        "No se utilizará ninguna ventana de igualación de precios para determinar los precios del mercado.",
      on: (d) =>
        "Cuando no existe un precio de mercado exacto para un activo criptográfico, utilice el precio disponible más cercano dentro de un período de tiempo de " +
        d.value +
        " horas",
      title: () => "Ventana de caída de precios",
    },
    privacy: {
      part1: () => "No compartimos sus datos. Lea nuestra ",
      part2: () => "política de privacidad",
    },
    profile: {
      addEmail: () => "Agregar correo electrónico",
      addInviteCode: () => "Añade tu código de invitación",
      addName: () => "Añade tu nombre",
      changeEmail: () => "Cambiar correo electrónico",
      changeInviteCode: () => "Cambia tu código de invitación",
      changeName: () => "Cambiar nombre",
      country: () => "País",
      debug: {
        title: () => "Apoyo",
        uid: {
          notification: () => "UID copiado al portapapeles",
          title: () => "ID de usuario",
        },
      },
      duplicateError: () => "Este código ya está en uso",
      edit: () => "Editar",
      email: () => "Correo electrónico",
      emailAddressChanged: () => "Dirección de correo electrónico cambiada",
      emailError: () => "Error de correo electrónico",
      emailSent: () => "Correo electrónico enviado",
      experience: {
        smoothScrolling: {
          title: () => "Desplazamiento suave (solo este navegador)",
          tooltipOff: () =>
            "El desplazamiento suave en la página de Transacciones está deshabilitado para este navegador",
          tooltipOn: () =>
            "El desplazamiento suave en la página de Transacciones está habilitado para este navegador",
        },
        title: () => "Experiencia",
      },
      inviteCode: () => "Código de invitación",
      inviteCodeChanged: () => "Código de invitación cambiado",
      lengthError: () =>
        "Su código debe tener entre 4 y 24 caracteres de longitud.",
      linkedClientsEmail: () => "Correo electrónico del cliente",
      linkedClientsName: () => "Nombre del cliente",
      localCurrency: () => "Moneda local",
      logout: () => "Cerrar sesión",
      name: () => "Nombre",
      nameChanged: () => "Nombre cambiado",
      nameToolTip: () =>
        "Si se proporciona, este nombre se mostrará en los informes generados.",
      noLinkedClient: () => "No hay ningún cliente vinculado",
      notConnected: () => "No conectado",
      oauth: {
        areYouSure: (d) =>
          "¿Está seguro de que desea desconectar " + d.oauth + " OAuth?",
        cancel: () => "Cancelar",
        connected: () => "Conectado",
        disconnect: () => "Desconectar",
        error: () =>
          "No se pudo actualizar la configuración de OAuth. Inténtalo nuevamente más tarde.",
        success: () => "Desconectado de OAuth exitosamente",
        title: (d) => d.oauth + " OAuth",
        walletOauth: () => "Conectado a través de billetera",
        warning: (d) =>
          "La desconexión de OAuth no se puede deshacer. Si se desconecta, deberá iniciar sesión con su correo electrónico ( " +
          d.email +
          " ) y contraseña.",
      },
      password: {
        accountPassword: () => "Contraseña de la cuenta",
        changePassword: () => "Cambiar la contraseña",
        confirmNewPassword: () => "Vuelva a escribir la nueva contraseña",
        dontMatch: () => "Las contraseñas no coinciden",
        newPassword: () => "Nueva contraseña",
        oldPassword: () => "Contraseña anterior",
        resetPassword: () => "restablecer contraseña",
        saveNewPassword: () => "Guardar nueva contraseña",
      },
      resendVerificationEmail: () => "Reenviar correo electrónico",
      title: () => "Perfil",
      unverified: () => "Inconfirmado",
      validationError: () =>
        "Por favor, introduzca un código de invitación válido y seguro para URL",
      verified: () => "Verificado",
      verifiedBanner: () => "Tu correo electrónico ha sido verificado",
      verifyEmail: () => "Verificar correo electrónico",
      wentWrongUpdatingEmail: () =>
        "Algo salió mal al actualizar el correo electrónico",
      wentWrongUpdatingInviteCode: () =>
        "Algo salió mal al actualizar el código de invitación",
      wentWrongUpdatingName: () => "Algo salió mal al actualizar el nombre",
    },
    report: {
      airdropsAsIncome: {
        title: () => "Considere los lanzamientos aéreos como ingresos",
        tooltip: {
          off: () =>
            "Los airdrops actualmente se consideran adquisiciones con un costo base cero.",
          on: () => "Los airdrops actualmente se consideran ingresos",
        },
      },
      allowBridgeFromUncategorised: {
        title: () => "Identificar transacciones puente automáticamente",
        tooltip: {
          off: () =>
            "Las transacciones puente no se identifican automáticamente de las transacciones no categorizadas",
          on: () =>
            "Las transacciones puente se identifican automáticamente a partir de las transacciones no categorizadas",
        },
      },
      allowGenerateFeeOnGroupedQuantityDiff: {
        title: () => "Detección automática de tarifas por transferencias",
        tooltip: {
          off: () =>
            "No genera automáticamente una tarifa si hay una discrepancia entre los montos enviados y recibidos para transferencias/puentes agrupados",
          on: () =>
            "Generar automáticamente una tarifa si hay una discrepancia entre los montos enviados y recibidos para transferencias/puentes agrupados",
        },
      },
      assumeTransferCounterparts: {
        title: () =>
          "Generar automáticamente las contrapartidas de transferencias no coincidentes",
        tooltip: {
          off: () =>
            "CTC no intentará generar la contraparte de transferencias no coincidentes",
          on: () =>
            "CTC intentará generar la contraparte de las transferencias no coincidentes cuando pueda hacerlo de manera segura.",
        },
      },
      bridgeAsNonTaxable: {
        title: () => "Tratar los puentes como enajenaciones no gravables",
        tooltip: {
          off: () =>
            "En la actualidad, los puentes se consideran enajenaciones y adquisiciones sujetas a impuestos.",
          on: () =>
            "En la actualidad, los puentes se consideran transferencias de base de costos.",
        },
      },
      collateralAsNonTaxable: {
        title: () =>
          "Tratar los depósitos y retiros de garantía como no sujetos a impuestos",
        tooltip: {
          off: () =>
            "Los depósitos y retiros de garantías actualmente se consideran enajenaciones y adquisiciones sujetas a impuestos.",
          on: () =>
            "Los depósitos y retiros de garantía actualmente se tratan como transferencias.",
        },
      },
      cryptoToCryptoNonTaxable: {
        title: () =>
          "Tratar las transacciones entre criptomonedas como enajenaciones no sujetas a impuestos",
        tooltip: {
          nftToCryptoTaxable: () =>
            "Las transacciones de NFT a criptomonedas seguirán siendo enajenaciones y adquisiciones sujetas a impuestos",
          off: () =>
            "Las transacciones entre criptomonedas se consideran actualmente enajenaciones y adquisiciones sujetas a impuestos.",
          on: () =>
            "Actualmente, las transacciones entre criptomonedas se consideran transferencias de base de costos.",
          onWithDate: (d) =>
            "Las transacciones de cripto a cripto a partir del " +
            d.date +
            " en adelante se consideran transferencias de base de costos.",
        },
      },
      emailReports: {
        options: {
          off: {
            description: () =>
              "Al descargar informes, nunca los enviaremos por correo electrónico.",
            title: () => "Nunca envíe informes por correo electrónico",
          },
          on: {
            description: () =>
              "Al descargar informes, también le enviaremos un enlace para descargar el informe. Este enlace será válido durante 1 día.",
            title: () => "Envíe siempre los informes por correo electrónico",
          },
        },
        title: () => "Informes por correo electrónico",
        wentWrong: () =>
          "Algo salió mal al actualizar la configuración de su informe de correo electrónico.",
      },
      holdingsBalanceType: {
        title: () => "Utilice los saldos reportados en la cartera",
        tooltip: {
          off: () =>
            "Utilice el saldo calculado determinado por el algoritmo del motor de impuestos",
          on: () => "Utilice el saldo reportado dado por la integración",
        },
      },
      ignoreBalanceRemainingValueThreshold: {
        subtext: () =>
          "Ignoramos los problemas de saldo restante por debajo de un cierto umbral fiduciario debido a problemas de redondeo de decimales. Esto suele deberse a que los datos de transacciones de cambio se han redondeado hacia arriba. Puede ajustar este umbral a continuación.",
        title: () => "Ajustar la precisión del equilibrio",
      },
      ignoreFiat: {
        title: () => "Ignorar las ganancias en las conversiones de divisas",
        tooltip: {
          off: () =>
            "Las ganancias en las conversiones de divisas se consideran actualmente",
          on: () =>
            "Las ganancias en las conversiones de divisas se ignoran actualmente",
        },
      },
      ignoreFiatLabel: () => "Ignorar Fiat",
      irs2025Warning: {
        chip: () => "Acerca de los próximos cambios en las normas del IRS",
        content: {
          body1: () =>
            "No se preocupe: su declaración de impuestos del año fiscal 24 no se verá afectada por estos cambios y podrá continuar utilizando el método de inventario universal si así lo prefiere.",
          body2: () =>
            "Estos cambios solo se aplican a las transacciones de criptomonedas a partir del 1 de enero de 2025. Deberá utilizar el método por billetera para su declaración de impuestos del año fiscal 2025, que presentará en 2026.",
          header1: () =>
            "¿Qué significa esto para su declaración de impuestos del año fiscal 24?",
          header2: () =>
            "¿Cuándo es necesario cambiar al seguimiento por billetera?",
          summary: () =>
            'A partir del 1 de enero de 2025, el IRS requerirá el uso de un método por billetera (también conocido como "Identificación específica por billetera e intercambio") para rastrear la base de costos de sus criptomonedas.',
        },
        learnMore: () => "Más información",
      },
      lostStolenAsNonTaxable: {
        title: () =>
          "Tratar las transacciones perdidas o robadas como enajenaciones no gravables",
        tooltip: {
          off: () =>
            "Las transacciones perdidas o robadas actualmente se consideran enajenaciones sujetas a impuestos.",
          on: () =>
            "Las transacciones perdidas o robadas actualmente se consideran enajenaciones no sujetas a impuestos.",
        },
      },
      miningAsIncome: {
        title: () => "Tratar la minería como un ingreso",
        tooltip: {
          off: () =>
            "Actualmente, las transacciones mineras se tratan como adquisiciones con una base de costo cero.",
          on: () =>
            "Las transacciones mineras actualmente se consideran ingresos",
        },
      },
      outgoingGiftAsNonTaxable: {
        title: () =>
          "Tratar el obsequio saliente como una disposición no sujeta a impuestos",
        tooltip: {
          off: () =>
            "Las transacciones de donaciones salientes actualmente se consideran enajenaciones sujetas a impuestos.",
          on: () =>
            "Las transacciones de donaciones salientes actualmente se tratan como enajenaciones no sujetas a impuestos.",
        },
      },
      personalUseAsNonTaxable: {
        title: () => "Tratar el uso personal como una disposición no gravable",
        tooltip: {
          off: () =>
            "Las transacciones de uso personal actualmente se consideran enajenaciones gravables.",
          on: () =>
            "Las transacciones de uso personal actualmente se consideran enajenaciones no gravables.",
        },
      },
      report: () => "Informe",
      royaltiesAsIncome: {
        title: () => "Tratar las regalías como ingresos",
        tooltip: {
          off: () =>
            "Las transacciones de regalías actualmente se tratan como adquisiciones con una base de costo cero.",
          on: () =>
            "Las transacciones de regalías actualmente se tratan como ingresos.",
        },
      },
      showAllAccountsOnPortfolio: {
        title: () => "Mostrar todas las cuentas en cartera",
        tooltip: {
          off: () => "En la cartera sólo se muestran las cuentas importadas",
          on: () =>
            "Cuando está desactivada, solo se muestran en la cartera las cuentas importadas. Cuando está activada, las tenencias de su cartera también incluirán cuentas sospechosas, que son billeteras y bolsas con las que se ha interactuado pero que no se han importado. Nota: Esta opción afecta solo a la vista de la cartera, no a los cálculos de impuestos.",
        },
      },
      showFiatHoldings: {
        title: () =>
          "Mostrar tenencias fiduciarias nativas en informes y paneles",
        tooltip: {
          off: () =>
            "Las tenencias de Fiat actualmente no se muestran en los informes ni en el panel de control",
          on: () =>
            "Las tenencias de Fiat se muestran actualmente en los informes y en el panel de control.",
        },
      },
      specificInventoryByExchange: {
        disabledReason: () => "No permitido en el método de inventario actual",
        options: {
          mixed: {
            description: () =>
              "Durante este período se utilizan tanto el seguimiento universal como el de los costos de billetera y de cambio.",
            title: () => "Configuraciones mixtas",
          },
          off: {
            description: () =>
              "La base de costos se rastreará universalmente en todas las billeteras e intercambios.",
            title: () => "Universal",
          },
          on: {
            description: () =>
              "La base de costo se rastrea por billetera y por exchange. Esto significa que puede haber múltiples bases de costo para un activo según el precio de compra en el que se compró la moneda.",
            title: () => "Por monedero y cambio",
          },
        },
        title: () => "Seguimiento de la base de costos",
      },
      splitFeesEvenlyAcrossGroupedMints: {
        title: () =>
          "Dividir las tarifas de manera uniforme entre las transacciones de acuñación agrupadas",
        tooltip: {
          off: () =>
            "Las transacciones de acuñación agrupadas actualmente no tienen tarifas divididas de manera uniforme entre todos los activos acuñados",
          on: () =>
            "Las transacciones de acuñación agrupadas actualmente tienen tarifas divididas de manera uniforme entre todos los activos acuñados.",
        },
      },
      stakingAsIncome: {
        title: () => "Considere las recompensas de staking como ingresos",
        tooltip: {
          off: () =>
            "Las recompensas por staking actualmente se tratan como adquisiciones con una base de costo cero.",
          on: () =>
            "Las recompensas por staking actualmente se consideran ingresos",
        },
      },
      stakingDepositWithdrawalNonTaxable: {
        title: () =>
          "Tratar los depósitos y retiros de apuestas como no sujetos a impuestos",
        tooltip: {
          off: () =>
            "Los depósitos y retiros de staking actualmente se consideran enajenaciones y adquisiciones sujetas a impuestos.",
          on: () =>
            "Los depósitos y retiros de apuestas actualmente se tratan como transferencias.",
        },
      },
      taxSettings: () => "Configuración de impuestos",
      title: () => "Configuración de informes",
      treatLiquidityAddRemovalNonTaxable: {
        title: () => "Tratar el aumento de liquidez como no sujeto a impuestos",
        tooltip: {
          off: () =>
            "Las transacciones de liquidez actualmente se consideran enajenaciones y adquisiciones sujetas a impuestos.",
          on: () =>
            "Las transacciones de liquidez actualmente se tratan como transferencias de base de costo",
        },
      },
      uncategorisedInAsTaxable: {
        title: () =>
          "Tratar las transacciones entrantes no categorizadas como compras",
        tooltip: {
          off: () =>
            "Las transacciones entrantes no categorizadas actualmente se tratan como transferencias no sujetas a impuestos.",
          on: () =>
            "Las transacciones entrantes no categorizadas actualmente se tratan como compras.",
        },
      },
      uncategorisedOutAsTaxable: {
        title: () =>
          "Tratar las transacciones salientes no categorizadas como ventas",
        tooltip: {
          off: () =>
            "Las transacciones salientes no categorizadas actualmente se tratan como transferencias no gravables.",
          on: () =>
            "Las transacciones salientes no categorizadas actualmente se tratan como ventas.",
        },
      },
      updatedTaxSettingsError: () =>
        "Se produjo un error al actualizar la configuración de impuestos. Inténtelo nuevamente más tarde.",
      updatedTaxSettingsSuccess: () =>
        "Configuración de impuestos actualizada correctamente",
    },
    reportActionButton: {
      action: () => "Actualizar cálculos",
      loadingAction: () => "Refrescante",
      loadingTooltip: () => "Esto puede tomar algún tiempo",
      tooltip: () =>
        "Activar manualmente una actualización de todos los cálculos",
    },
    reportSettings: () => "Configuración de informes",
    sidebar: {
      loading: () => "Cargando...",
      searchClients: () => "Buscar clientes",
    },
    somethingWentWrong: () =>
      "¡Algo salió mal! Comuníquese con el servicio de asistencia",
    somethingWentWrongContact: () =>
      "¡Algo salió mal! Comuníquese con el servicio de asistencia si el problema continúa",
    supportAccess: {
      body: {
        accessGrantedAt: (d) => "Acceso concedido: " + d.accessGrantedAt,
        button: {
          grantAccess: () => "Conceder acceso",
          revokeAccess: () => "Revocar el acceso",
        },
        description: () =>
          "Permita que el equipo de soporte acceda a los datos de su cuenta para ayudar a resolver problemas. El acceso se concederá por un máximo de 30 días.",
        title: () => "Otorgar acceso a asistencia",
      },
      title: () => "Apoyo",
    },
    tabs: {
      account: () => "Cuenta",
      alpha: () => "Alfa",
      billing: () => "Facturación",
      general: () => "Impuesto",
      integrations: () => "Cuentas",
      "integrations-deprecated": () => "Integraciones",
      loading: () => "Cargando",
      "lock-period": () => "Períodos de bloqueo",
      permissions: () => "Permisos",
      plan: () => "Plan",
      portfolio: () => "Cartera",
      team: () => "Equipo",
    },
    team: {
      header: {
        email: () => "Correo electrónico",
        lastLogin: () => "Último inicio de sesión",
        name: () => "Nombre",
        search: () => "Buscar",
      },
      invite: () => "Invitar",
      inviteTeamMember: () => "Invitar a un miembro del equipo",
      inviteTooltip: (d) => "Invitado " + d.date,
      managementAndPermissions: () => "Gestión",
      noResults: () => "No hay resultados",
      noTeamMembers: () => "No hay miembros del equipo",
      owner: () => "Dueño",
    },
    threshold: () => "Límite",
    timezone: () => "Zona horaria",
    title: () => "Ajustes",
  },
  similarTransactionsSuggestion: {
    accept: () => "Confirmar",
    applyToFuture: () => "Aplicar a futuras transacciones",
    categorizeItAs: () => "Categorizar como:",
    deny: () => "Denegar",
    modal: {
      buttonCTA: () => "Seleccionar categoría",
      receivedFrom: () => "Recibido de:",
      sentTo: () => "Enviado a:",
      similarTxs: () => "Transacciones similares:",
    },
    noSimilar: () => "No se encontraron transacciones similares",
    notNow: () => "Ahora no",
    operators: {
      actionId: () => "Identificación de acción",
      after: () => "Después",
      and: () => "Y",
      before: () => "Antes",
      blockchain: () => "Cadena de bloques",
      category: () => "Categoría",
      comment: () => "Comentario",
      comments: () => "Comentarios",
      currency: () => "Divisa",
      date: () => "Fecha",
      description: () => "Descripción",
      feeCurrency: () => "Moneda de la tarifa",
      from: () => "Fuente",
      hasRule: () =>
        "Ocultar transacciones que no se ven afectadas por las reglas",
      id: () => "IDENTIFICACIÓN",
      importId: () => "Identificación de importación",
      importType: () => "Tipo de importación",
      isSmartContractInteraction: () => "Interacción",
      missingPrice: () => "Precio faltante",
      negativeBalance: () => "Historial de compras faltante",
      nftCollection: () => "Colección NFT",
      not: () => "No",
      or: () => "O",
      party: () => "Fiesta",
      reconciliation: () => "Reconciliación",
      reviewed: () => "Revisado",
      rule: () => "Regla",
      ruleOperator: () => "Operador de regla",
      source: () => "Cuenta",
      suggestion: () => "Sugerencia",
      sync: () => "Sincronizar",
      to: () => "Destino",
      trade: () => "Categoría",
      txCurrency: () => "Moneda Tx",
      txFunctionName: () => "Firma de transacción",
      txFunctionSignature: () => "Id. del método de transacción",
      txId: () => "Identificación de transacción",
      warning: () => "Advertencia",
    },
    optOut: {
      button: () => "Vista",
      loadingSimilar: () => "Encontrar transacciones similares",
      noSimilar: () => "No hay transacciones similares",
      title: (d) =>
        "Aplicar este cambio a [ " +
        plural(d.count, 0, es, {
          one: " 1 transacción similar ",
          other: number("es", d.count, 0) + " transacciones similares ",
        }) +
        " ]( " +
        d.linkTextKey +
        " )",
      tooltip: {
        criteriaFour: () => "Tener la misma firma de transacción",
        criteriaOne: () => "Están en la misma dirección",
        criteriaThree: () => "Tener la misma moneda",
        criteriaTwo: () => "Son hacia y desde las mismas billeteras",
        title: () => "Son transacciones similares aquellas que:",
      },
    },
    similarTransactionsFound: (d) =>
      plural(d.count, 0, es, {
        one: " 1 transacción similar ",
        other: number("es", d.count, 0) + " transacciones similares ",
      }),
    successToast: (d) =>
      "Categorizado " +
      d.count +
      " similar " +
      plural(d.count, 0, es, {
        one: " transacción ",
        other: " transacciones ",
      }),
    viewTransactions: () => "Ver transacciones",
    weHaveFound: (d) =>
      "Hemos encontrado " +
      plural(d.count, 0, es, {
        one: " 1 transacción similar ",
        other: number("es", d.count, 0) + " transacciones similares ",
      }) +
      " . " +
      plural(d.count, 0, es, {
        one: " Esta transacción tiene ",
        other: " Estas transacciones tienen ",
      }) +
      " lo mismo:",
  },
  skip: () => "Saltar",
  spam: () => "Marcar como spam",
  start: () => "Comenzar",
  support: {
    contact: () =>
      "Comuníquese con el servicio de asistencia para obtener ayuda.",
    deleteAccountDisabled: () =>
      "Esta función no está disponible para el plan de contabilidad. Comuníquese con el servicio de asistencia para obtener ayuda.",
  },
  supportedLanguages: {
    de: () => "Alemán",
    eng: () => "Inglés",
    es: () => "Español",
    fr: () => "Francés",
    it: () => "italiano",
    xx: () => "Lenguaje de depuración xx",
    xxl: () => "Xx largo",
    xxs: () => "Xx corto",
  },
  sync: {
    actionErpSyncFailed: (d) => "No se pudo sincronizar con " + d.erpProvider,
    apiStillSyncing: () =>
      "La API aún se está sincronizando, espere unos minutos.",
    integrationSyncing: (d) => d.name + " se está sincronizando...",
    reportRefreshFailed: () => "Los cálculos no se pudieron actualizar",
    syncFailed: (d) =>
      "Error en la sincronización de " +
      d.name +
      " . Asegúrate de seguir correctamente las instrucciones de importación. Si esto continúa, comunícate con el servicio de asistencia.",
    syncOneHour: (d) =>
      "Sincronización con la API " +
      d.name +
      " . Este proceso puede tardar hasta 1 hora.",
    syncSeveralMinutes: (d) =>
      "Sincronización con la API " +
      d.name +
      " . Este proceso puede tardar varios minutos.",
    syncingAPIs: (d) => "Sincronización de las API " + d.name,
    syncingBlockchainWallets: (d) => "Sincronización de billeteras " + d.name,
    syncingName: (d) => "Sincronizando " + d.name,
    uploadDeprecated: {
      coinbase: () =>
        "Estás utilizando una clave API de Coinbase obsoleta, actualiza tu API según las últimas instrucciones",
      generic: () =>
        "Estás utilizando una clave API obsoleta, actualiza tu API según las últimas instrucciones",
    },
    uploadFail: (d) =>
      "Ocurre algo al importar " +
      d.name +
      " . Si esto continúa, comuníquese con el servicio de asistencia.",
    uploadingFiveMins: (d) =>
      "Cargando datos " +
      d.name +
      " . Esto puede tardar hasta 5 minutos. Puedes seguir usando la aplicación durante este tiempo.",
    xeroSyncFailed: () => "Error en la sincronización de Xero",
  },
  syncErrorReasons: {
    "api-changed": {
      ctaType: () => "Comuníquese con el soporte para obtener más ayuda.",
      message: () => "Los datos recibidos del intercambio han cambiado.",
    },
    "api-unavailable": {
      ctaType: () => "Por favor, inténtelo de nuevo más tarde.",
      message: (d) =>
        "El servidor API " +
        d.exchangeName +
        " no está disponible actualmente.",
    },
    "app-error": {
      ctaType: () =>
        "Comuníquese con el soporte técnico si el problema persiste.",
      message: () => "Se produjo un error inesperado en la aplicación.",
    },
    "csv-invalid-column": {
      ctaType: () => "Verifique el archivo y vuelva a intentarlo.",
      message: () => "El archivo CSV contiene una columna no válida.",
    },
    "csv-not-required": {
      ctaType: () => "Asegúrese de haber cargado el archivo correcto.",
      message: () => "El archivo CSV no es necesario para esta integración.",
    },
    "csv-not-supported": {
      ctaType: () => "Asegúrese de haber cargado el archivo correcto.",
      message: () => "El archivo CSV no es compatible con esta integración.",
    },
    "expired-api-key": {
      ctaType: () =>
        "Genere una nueva clave siguiendo las instrucciones de integración paso a paso.",
      message: () => "Clave API caducada.",
    },
    "invalid-api-key": {
      ctaType: () => "Verifique las instrucciones y vuelva a intentarlo.",
      message: () => "Clave API no válida.",
    },
    "invalid-csv": {
      ctaType: () => "Verifique el archivo y el contenido.",
      message: () => "Formato CSV no válido.",
    },
    "invalid-csv-headers": {
      ctaType: () => "Verifique el formato y vuelva a intentarlo.",
      message: () => "Nombres de columnas no válidos.",
    },
    "invalid-permissions": {
      ctaType: () =>
        "Verifique si las restricciones de IP o acceso están configuradas correctamente.",
      message: () => "Permisos de API faltantes.",
    },
    "invalid-user-defined-field": {
      ctaType: () => "Verifique sus entradas o configuración.",
      message: () => "La solicitud contiene campos no válidos.",
    },
    "invalid-wallet-address": {
      ctaType: () => "Verifique las instrucciones y vuelva a intentarlo.",
      message: () => "Dirección de billetera no válida.",
    },
    "no-transactions": {
      ctaType: () => "Verificar la fuente de datos o entradas.",
      message: () => "No se encontraron transacciones",
    },
    "rate-limit-failed": {
      ctaType: () => "Inténtalo de nuevo en 5 minutos.",
      message: () => "Límite de velocidad alcanzado.",
    },
    timeout: {
      ctaType: () => "Por favor, inténtelo de nuevo más tarde.",
      message: () => "Se agotó el tiempo de solicitud. ",
    },
    "too-many-transactions": {
      ctaType: () => "Comuníquese con el soporte para obtener más ayuda.",
      message: () => "Límite de transacción excedido",
    },
    "unknown-error": {
      ctaType: () => "Comuníquese con el soporte para obtener más ayuda.",
      message: () => "Se produjo un error desconocido.",
    },
    "unsupported-data-format": {
      ctaType: () => "Comuníquese con el soporte para obtener más ayuda.",
      message: () => "El formato de datos recibido de la API no es compatible.",
    },
  },
  syncHistory: {
    confirm: {
      text: () =>
        "¿Está seguro de que desea eliminar todos los datos de esta sincronización? Esto eliminará todas las transacciones asociadas con esta sincronización. Esta acción no se puede deshacer.",
      title: () => "Eliminar sincronización de importación",
      warning: (d) => d.name + " el " + d.date,
    },
    date: () => "Fecha",
    delete: () => "Borrar",
    hardSync: () => "Sincronización dura",
    id: () => "IDENTIFICACIÓN",
    initialSync: () => "Sincronización inicial",
    name: () => "Nombre",
    noneFound: () =>
      "No se encontró ningún historial de sincronización exitosa.",
    title: () => "Historial de sincronización",
    transactions: () => "actas",
    transactionsHeader: () => "Actas",
    viewTx: () => "Ver transacciones",
  },
  syncStatus: {
    failed: () => "La actualización falló",
    reconciliation: () => "Conciliación de transacciones",
    retry: () => "Actualizar cálculos",
    updated: () => "Actualizado",
    updating: () => "Actualización en progreso",
  },
  tabNav: {
    connectedAccounts: () => "Cuentas conectadas",
    more: () => "Más",
    nfts: () => "NFT",
    plan: () => "Plan",
    report: () => "Informe",
    review: () => "Revisar",
    settings: () => "Ajustes",
    summary: () => "Resumen",
    transactions: () => "Actas",
  },
  tag: {
    createNew: () => "Crear identidad",
    createNewIdentity: () => "Crear nueva identidad",
    deleteMessage: (d) =>
      d.address + " ya no se identificará como " + d.entityName,
    groups: {
      Contract: () => "Contratos",
      Exchange: () => "Intercambios",
      Extension: () => "Intercambios",
      Manual: () => "Costumbre",
    },
    identified: (d) => d.count + " identificado",
    identify: () => "Identificar",
    identifyAddress: () => "Identificar dirección",
    identifyAddressModal: {
      description: () =>
        "Esto identificará la dirección en todas las transacciones.",
      doneButton: () => "Hecho",
      title: () => "Identificar dirección",
    },
    identifyDescription: () =>
      "Esto identificará la dirección en todas las transacciones.",
    manageAddressModal: {
      description: (d) =>
        "Esta dirección se identifica como " + d.entityDisplayName + " .",
      descriptionContract: (d) =>
        "Este contrato se identifica como " + d.entityDisplayName + " .",
      doneButton: () => "Hecho",
      title: () => "Administrar dirección",
    },
    refAddressModal: {
      description: (d) =>
        "Todas las direcciones identificadas como " +
        d.entityDisplayName +
        " .",
      doneButton: () => "Hecho",
      title: (d) => "Administrar " + d.entityDisplayName,
    },
    startTypingName: () => "Empieza a escribir un nombre",
    thisAddress: () => "Esta dirección",
  },
  taxAuthorityByCountry: {
    AD: () => "DT",
    AE: () => "FTA",
    AF: () => "ARCA",
    AG: () => "IRD",
    AI: () => "IRD",
    AL: () => "GDT",
    AM: () => "SRC",
    AO: () => "AGT",
    AR: () => "AFIP",
    AS: () => "ASG-T",
    AT: () => "BMF",
    AU: () => "ATO",
    AW: () => "DIMP",
    AZ: () => "STM",
    BA: () => "ITA",
    BB: () => "BRA",
    BD: () => "NBR",
    BE: () => "FPSF",
    BF: () => "DGI",
    BG: () => "NRA",
    BH: () => "NBR",
    BI: () => "OBR",
    BJ: () => "DGI",
    BM: () => "OTC",
    BN: () => "MOFE",
    BO: () => "SIN",
    BQ: () => "BCN",
    BR: () => "RFB",
    BS: () => "IRD",
    BT: () => "DRC",
    BV: () => "BVT",
    BW: () => "BURS",
    BY: () => "MTC",
    BZ: () => "IRD",
    CA: () => "CRA",
    CF: () => "DGID",
    CG: () => "DGI",
    CH: () => "SFTA",
    CI: () => "DGI",
    CL: () => "SII",
    CM: () => "DGT",
    CN: () => "STA",
    CO: () => "DIAN",
    CR: () => "DGT",
    CU: () => "ONAT",
    CV: () => "DNRE",
    CW: () => "NOB",
    CY: () => "TAD",
    CZ: () => "FSAD",
    DE: () => "BZSt",
    DJ: () => "DGI",
    DK: () => "SKAT",
    DM: () => "IRD",
    DO: () => "DGII",
    DZ: () => "DGI",
    EC: () => "SRI",
    EE: () => "ETCB",
    EG: () => "ETA",
    ER: () => "IRD",
    ES: () => "AEAT",
    ET: () => "ERCA",
    FI: () => "FTA",
    FJ: () => "FRCS",
    FK: () => "FIG",
    FM: () => "DoF",
    FO: () => "TAKS",
    FR: () => "DGFIP",
    GA: () => "DGI",
    GB: () => "HMRC",
    GD: () => "IRD",
    GE: () => "GRS",
    GF: () => "DGFIP",
    GH: () => "GRA",
    GI: () => "ITO",
    GL: () => "TSN",
    GM: () => "GRA",
    GN: () => "DNI",
    GP: () => "DGFIP",
    GQ: () => "DGI",
    GR: () => "IAPR",
    GT: () => "SAT",
    GU: () => "DRT",
    GW: () => "DGI",
    GY: () => "GRA",
    HK: () => "IRD",
    HN: () => "SAR",
    HR: () => "TA",
    HT: () => "DGI",
    HU: () => "NTCA",
    ID: () => "DGT",
    IE: () => "TRC",
    IL: () => "ITA",
    IM: () => "ITD",
    IN: () => "ITR",
    IR: () => "INTA",
    IS: () => "RSK",
    IT: () => "ADE",
    JE: () => "RT",
    JM: () => "TAJ",
    JO: () => "ISTD",
    JP: () => "NTA",
    KE: () => "KRA",
    KG: () => "STS",
    KH: () => "GDT",
    KI: () => "MFED",
    KM: () => "AGID",
    KN: () => "IRD",
    KP: () => "MoF",
    KR: () => "NTS",
    KW: () => "MT",
    KY: () => "DCI",
    KZ: () => "SRC",
    LA: () => "TD",
    LB: () => "MoF",
    LC: () => "IRD",
    LI: () => "STV",
    LK: () => "IRD",
    LR: () => "LRA",
    LS: () => "LRA",
    LT: () => "STI",
    LU: () => "ACD",
    LV: () => "SRS",
    LY: () => "TD",
    MA: () => "DGI",
    MC: () => "DSF",
    MD: () => "STS",
    ME: () => "TA",
    MF: () => "DGFIP",
    MG: () => "DGI",
    MH: () => "MIRC",
    MK: () => "PRO",
    ML: () => "DGI",
    MM: () => "IRD",
    MN: () => "MTA",
    MO: () => "DSF",
    MP: () => "DRT",
    MQ: () => "DGFIP",
    MR: () => "DGI",
    MS: () => "IRD",
    MT: () => "CfR",
    MU: () => "MRA",
    MV: () => "MIRA",
    MW: () => "MRA",
    MX: () => "SAT",
    MY: () => "LHDN",
    MZ: () => "AT",
    NA: () => "IR",
    NC: () => "DSF",
    NE: () => "DGI",
    NG: () => "FIRS",
    NI: () => "DGI",
    NL: () => "DTCA",
    NO: () => "NTA",
    NP: () => "IRD",
    NR: () => "DoF",
    NU: () => "TR",
    NZ: () => "IRD",
    OM: () => "TR",
    PA: () => "DGI",
    PE: () => "SUNAT",
    PF: () => "DICP",
    PG: () => "IRC",
    PH: () => "BIR",
    PK: () => "FBR",
    PL: () => "KAS",
    PM: () => "DGFIP",
    PR: () => "DH",
    PS: () => "ITA",
    PT: () => "PTCA",
    PW: () => "BRT",
    PY: () => "SET",
    QA: () => "GTA",
    RE: () => "DGFIP",
    RO: () => "ANAF",
    RS: () => "PU",
    RU: () => "FTS",
    RW: () => "RRA",
    SA: () => "GAZT",
    SB: () => "IRD",
    SC: () => "SRC",
    SD: () => "SRTD",
    SE: () => "STA",
    SG: () => "IRAS",
    SI: () => "FURS",
    SK: () => "FR",
    SL: () => "NRA",
    SM: () => "SMR",
    SN: () => "DGID",
    SO: () => "MoF",
    SR: () => "DB",
    SS: () => "NRA",
    SV: () => "DGII",
    SX: () => "TD",
    SY: () => "MoF",
    SZ: () => "SRA",
    TC: () => "RD",
    TD: () => "DGI",
    TG: () => "OTR",
    TH: () => "RD",
    TJ: () => "TC",
    TL: () => "DNRT",
    TM: () => "MTT",
    TN: () => "DGI",
    TO: () => "IRD",
    TR: () => "GIB",
    TT: () => "BIR",
    TV: () => "IRD",
    TW: () => "NTBT",
    TZ: () => "TRA",
    UA: () => "STS",
    UG: () => "URA",
    UK: () => "HMRC",
    US: () => "IRS",
    UY: () => "DGI",
    UZ: () => "STC",
    VA: () => "N/A",
    VC: () => "IRD",
    VE: () => "SENIAT",
    VG: () => "IRD",
    VI: () => "IRB",
    VN: () => "GDT",
    VU: () => "DCIR",
    WF: () => "DGFIP",
    WS: () => "MfR",
    YE: () => "TY",
    YT: () => "DGFIP",
    ZA: () => "SARS",
    ZM: () => "ZRA",
    ZW: () => "ZIMRA",
  },
  taxDivision: {
    buy: () => "Comprar",
    disposal: () => "Desecho",
    group: () => "Grupos",
    hidden: () => "Oculto",
    ignore: () => "Ignorar",
    income: () => "Ingreso",
    lastUsed: () => "Seleccionado recientemente",
    payment: () => "Pago",
    transfer: () => "Transferir",
    withdrawal: () => "Retiro",
  },
  taxLossHarvesting: {
    errors: {
      unableToLoad: () =>
        "No se puede cargar el modal de recolección de pérdidas",
    },
    gain: () => "Ganancias",
    iconTooltips: {
      editPrice: () => "Editar precio",
      refreshPrice: () => "Actualizar precios de mercado",
      resetPrice: () => "Restablecer el precio al precio actual del mercado",
      savePrice: () => "Ahorrar precio",
    },
    open: () => "Pérdidas de cosecha",
    openWhenNoLosses: () => "Ver herramienta de cosecha",
    paywallModal: {
      aboutLTFO: () =>
        "Acerca de nuestro método de inventario con mayor eficacia fiscal",
      aboutTaxLossHarvesting: () =>
        "Acerca de la recolección de pérdidas fiscales",
      benefits: {
        lockIn: () =>
          "Potencialmente bloquear pérdidas de capital para transferirlas a años futuros",
        lockInNoCGT: () =>
          "Potencialmente bloquear pérdidas no realizadas para transferirlas a años futuros",
        offset: () =>
          "Potencialmente compense cualquier ganancia de capital fuera de las criptomonedas con pérdidas de capital de criptomonedas",
        offsetNoCGT: () =>
          "Potencialmente compensar cualquier ganancia realizada fuera de las criptomonedas con pérdidas realizadas en criptomonedas",
        optimize: () =>
          "Optimice sus impuestos con el método de inventario más eficaz desde el punto de vista fiscal",
      },
      subtitlePost: () => "en pérdidas.",
      subtitlePre: () => "Podrías cosechar",
      title: () => "Reducir potencialmente su factura de impuestos.",
      upgradePrompt: () =>
        "Actualice al plan de inversor o superior para desbloquear la recolección de pérdidas",
    },
    postHarvestCapitalGains: () => "Ganancias de capital poscosecha",
    postHarvestCapitalGainsNoCGT: () => "Ganancias poscosecha",
    realisedCapitalGains: () => "Ganancias de capital realizadas",
    realisedCapitalGainsNoCGT: () => "Ganancias realizadas",
    showAssetsWithoutMarketPrice: () => "Mostrar activos sin precio de mercado",
    summary: {
      netCapitalGainLoss: () => "Ganancia/pérdida neta de capital",
      netCapitalGainLossNoCGT: () => "Ganancia/pérdida neta realizada",
    },
    table: {
      loading: () => "Cargando...",
      noHoldings: () => "No hay propiedades para mostrar",
      rowSelectionDisabled: () =>
        "Sólo puedes seleccionar monedas con pérdidas no realizadas.",
      title: () => "Oportunidades de ahorro fiscal",
    },
    tableHeaders: {
      amountHeld: () => "Monto retenido",
      amountToSell: () => "Cantidad a vender",
      asset: () => "Activo",
      costBasis: () => "Base de costos",
      currentMarketValue: () => "Valor comercial",
      price: () => "Precio",
      unrealizedGainsLosses: () => "Ganancias/pérdidas no realizadas",
    },
    tableSearch: () => "Encontrar activo",
    taxSavingOpportunities: () => "Oportunidades de ahorro fiscal",
    title: () => "Recolección de pérdidas fiscales",
    tooltips: {
      unrealizedGains: () =>
        "La diferencia entre el costo base de un activo y su valor de mercado actual",
    },
    viewTaxLosses: () => "Ver pérdidas fiscales",
  },
  taxRule: {
    "bed-and-breakfast": () => "Regla BnB",
    "fee-forwarded": () => "Tarifa reenviada",
    "margin-fee": () => "Tarifa de margen",
    "recalculated-after-bed-and-breakfast": () => "Recálculo de BnB",
    "same-day": () => "Regla del mismo día",
    "superficial-loss-from-buy-back": () => "Recompra superficial",
    "superficial-loss-from-sell": () => "Venta superficial",
    "zero-cost-buy": () => "Falta de equilibrio",
  },
  taxSettingBanner: {
    affectCalculationsWarning: () =>
      "Tenga en cuenta que estos cambios pueden afectar sus cálculos para años fiscales anteriores.",
    cta: () => "Echa un vistazo",
    title: () => "✨¡Nueva automatización disponible!",
  },
  taxToggles: () => "Conmutadores de impuestos",
  testKey: () =>
    "Esto AHORA no se traducirá, se agregará texto traducido adicional",
  theCounterParty: () => "La contraparte",
  theme: {
    betaModal: {
      actionText: () => "Cambiar al modo oscuro",
      content1: () =>
        "Todavía estamos solucionando algunos problemas con los colores en el modo oscuro. Es posible que algunos componentes no se muestren correctamente.",
      content2: () =>
        "Esto no tiene ningún impacto en sus cálculos ni en sus datos.",
      title: () => "El modo oscuro está en versión beta",
    },
    short: {
      dark: () => "Oscuro",
      light: () => "Luz",
      system: () => "Sistema",
    },
    toggle: {
      dark: () => "Modo oscuro",
      light: () => "Modo claro",
      system: () => "Predeterminado del sistema",
    },
  },
  title: () => "Calculadora de impuestos sobre criptomonedas",
  to: () => "a",
  tokenLogin: {
    errorSubtitle: () =>
      "Por favor verifique su enlace de inicio de sesión, o puede intentar iniciar sesión usando su correo electrónico y contraseña.",
    errorTitle: () => "No se puede iniciar sesión",
    subtitle: () => "Por favor espere mientras iniciamos sesión en su cuenta",
    title: () => "Iniciando sesión en su cuenta",
    visitLogin: () => "Ir a la página de inicio de sesión",
  },
  topBanner: {
    coupon: () => "VIERNES NEGRO 2020",
    msg: () =>
      "Black Friday • Obtenga un 60% de descuento en la suscripción del primer año • CÓDIGO: ",
  },
  tradeType: {
    addLiquidity: () => "Añadir liquidez",
    airdrop: () => "Entrega por paracaídas",
    approval: () => "Aprobación",
    "arbitrary-uncategorised": () => "Clasificar por categorías",
    borrow: () => "Préstamo",
    bridge: () => "Puente",
    "bridge-trade": () => "Comercio entre cadenas",
    "bridge-transfer": () => "Puente",
    bridgeIn: () => "Puente En",
    bridgeOut: () => "Puente fuera",
    bridgeTradeIn: () => "Compra de cadena cruzada",
    bridgeTradeOut: () => "Venta entre cadenas",
    bridgeTransferIn: () => "Transferencia de puente en",
    bridgeTransferOut: () => "Transferencia de puente de salida",
    burn: () => "Quemar",
    buy: () => "Comprar",
    cashback: () => "Reembolso",
    chainSplit: () => "División de cadena",
    collateral: () => "Depósito de garantía",
    collateralWithdrawal: () => "Retiro de garantías",
    "decrease-position": () => "Disminuir posición",
    decreasePositionDeposit: () => "Disminuir posición",
    deposit: () => "Recibir",
    derivatives: () => "PnL de operaciones",
    expense: () => "Gastos",
    failed: () => "Fallido",
    failedIn: () => "Falló en",
    failedOut: () => "Falló",
    fee: () => "Tarifa",
    fiat: () => "Fíat",
    "fiat-transfer": () => "Transferencia fiduciaria",
    fiatDeposit: () => "Depósito fiduciario",
    fiatWithdrawal: () => "Retiro de Fiat",
    gift: () => "Regalo",
    gifts: () => "Regalos",
    ignoreIn: () => "Ignorar en",
    ignoreOut: () => "Ignorar",
    ignored: () => "Ignorado",
    in: () => "Entrante",
    income: () => "Ingreso",
    "increase-position": () => "Aumentar posición",
    increasePositionWithdrawal: () => "Aumentar posición",
    interest: () => "Interés",
    liquidate: () => "Liquidación",
    liquidity: () => "Liquidez",
    loan: () => "Préstamos",
    loanRepayment: () => "Reembolso del préstamo",
    long: () => "Largo",
    lost: () => "Perdido",
    marginFee: () => "Tarifa de margen",
    mining: () => "Minería",
    mint: () => "Menta",
    "multi-mint": () => "Menta",
    "multi-trade": () => "Comercio",
    notAvailable: () => "Compra a costo cero",
    out: () => "Extrovertido",
    outgoingGift: () => "Regalo saliente",
    personalUse: () => "Uso personal",
    "provide-liquidity-trade": () => "Añadir liquidez",
    realisedLoss: () => "Pérdida realizada",
    realisedProfit: () => "Utilidad realizada",
    receivePQ: () => "Recibir token de posición",
    receivingLiquidityProviderToken: () => "Recibir token de recibo",
    "remove-liquidity-trade": () => "Eliminar liquidez",
    removeLiquidity: () => "Eliminar liquidez",
    returningLiquidityProviderToken: () => "Enviar token de recibo",
    reward: () => "Premio",
    royalty: () => "Regalías",
    sell: () => "Vender",
    sendPQ: () => "Enviar token de posición",
    short: () => "Corto",
    spamIn: () => "Correo basura",
    "stake-deposit-group": () => "Depósito de participación",
    "stake-withdrawal-group": () => "Retiro de staking",
    staking: () => "Recompensa por participación",
    stakingDeposit: () => "Depósito de participación",
    stakingWithdrawal: () => "Retiro de staking",
    stakings: () => "Estaca",
    stolen: () => "Robado",
    swap: () => "Intercambio",
    swapIn: () => "Intercambiar en",
    swapOut: () => "Intercambio",
    swaps: () => "Intercambios",
    trade: () => "Comercio",
    transfer: () => "Transferir",
    uncategorised: () => "Sin categorizar",
    unknown: () => "Desconocido",
    withdrawal: () => "Enviar",
  },
  tradeTypeCombined: {
    borrow_loanRepayment: () => "Préstamo y reembolso de préstamos",
    bridgeIn_bridgeOut: () => "Puente de entrada y salida",
    bridgeTradeIn_bridgeTradeOut: () => "Compra y venta de cadenas cruzadas",
    bridgeTransferIn_bridgeTransferOut: () => "Puente de entrada y salida",
    buy_sell: () => "Compra y venta",
    collateralWithdrawal_collateral: () => "Depósito y retiro de garantías",
    failedIn_failedOut: () => "Falló dentro y fuera",
    fiatDeposit_fiatWithdrawal: () => "Depósito y retiro de dinero fiduciario",
    ignoreIn_ignoreOut: () => "Ignorar entrada y salida",
    receive_send: () => "Recibir y enviar",
    receivingLiquidityProviderToken_stakingDeposit: () =>
      "Depósito de staking y recepción de tokens de recibo",
    removeLiquidity_addLiquidity: () => "Eliminar y agregar liquidez",
    returningLiquidityProviderToken_stakingWithdrawal: () =>
      "Retiro de staking y envío de recibo de token",
    stakingWithdrawal_stakingDeposit: () => "Retiro y depósito de staking",
  },
  tradeTypeGroup: {
    acquisition: {
      description: () =>
        "Usted se convirtió en propietario de un activo criptográfico y estableció la base del costo del activo.",
      label: () => "Adquisición",
    },
    derivatives: {
      description: () =>
        "Recibió ganancias al operar con derivados, por ejemplo, futuros/margen.",
      label: () => "Derivados",
    },
    disposal: {
      description: () =>
        "Ya no eres el propietario de un activo criptográfico, lo que desencadena un evento de CGT.",
      label: () => "Desecho",
    },
    expenses: {
      description: () => "Utilizaste un activo para pagar un gasto.",
      label: () => "Gastos",
    },
    "fiat-transfer": {
      description: () => "Se transfirió Fiat pero se mantuvo la propiedad.",
      label: () => "Transferencia fiduciaria",
    },
    ignore: {
      description: () =>
        "Ignorar la transacción de los cálculos de impuestos y saldos.",
      label: () => "Ignorar",
    },
    income: {
      description: () =>
        "Recibió ingresos en criptomonedas. También puede categorizar comisiones, etc. en esta categoría.",
      label: () => "Ingreso",
    },
    loss: {
      description: () =>
        "Perdió criptomonedas y desea reclamar la base del costo como una deducción de impuestos.",
      label: () => "Pérdida",
    },
    transfer: {
      description: () =>
        "Se transfirió la criptomoneda pero se mantuvo la propiedad.",
      label: () => "Transferir",
    },
  },
  tradeTypeTaxDescription: {
    addLiquidity: () => "Ha añadido estas monedas a un fondo de liquidez",
    airdrop: () =>
      'Recibiste tokens "gratis" como parte de una promoción o similar',
    approval: () => "Aprobaste el uso de un contrato inteligente",
    "arbitrary-uncategorised": () => "Clasificar por categorías",
    borrow: () =>
      "Recibió criptomonedas o dinero fiduciario como resultado de proporcionar una garantía",
    "bridge-trade": () =>
      "Completaste un intercambio entre cadenas, que implica el intercambio de una moneda por otra en diferentes cadenas de bloques.",
    "bridge-transfer": () =>
      "Entrada/salida de puente agrupada automáticamente",
    bridgeIn: () =>
      'Se utiliza para transferir la base de costos de una cadena de bloques a otra. Nota: debe coincidir con otra transacción categorizada como "Bridge Out"',
    bridgeOut: () =>
      'Se utiliza para transferir la base de costos de una cadena de bloques a otra. Nota: debe coincidir con otra transacción categorizada como "Bridge In"',
    bridgeTradeIn: () =>
      "Intercambiaste criptomonedas en una cadena de bloques y las enviaste a otra cadena de bloques a través de un puente.",
    bridgeTradeOut: () =>
      "Recibiste criptomonedas en una cadena de bloques a partir de una transacción iniciada en una cadena de bloques diferente.",
    bridgeTransferIn: () =>
      'Se utiliza para transferir la base de costos de una cadena de bloques a otra. Nota: debe coincidir con otra transacción categorizada como "Bridge Out"',
    bridgeTransferOut: () =>
      'Se utiliza para transferir la base de costos de una cadena de bloques a otra. Nota: debe coincidir con otra transacción categorizada como "Bridge In"',
    burn: () =>
      "Envió sus existencias a una dirección de correo electrónico no válida.",
    buy: () => "No es un hecho imponible",
    cashback: () =>
      "Recibió un reembolso en una transacción, como una reducción de tarifa o un reembolso en efectivo de un pago con tarjeta de crédito, etc.",
    chainSplit: () =>
      "Recibiste criptomonedas como resultado de una bifurcación dura",
    collateral: () =>
      "Ha reservado estas monedas como garantía para un préstamo. Esto actúa como un retiro de su cuenta.",
    collateralWithdrawal: () =>
      "Ha retirado estas monedas de una plataforma de préstamos. Esto funciona de manera similar a un depósito en su cuenta.",
    "decrease-position": () =>
      "Se utiliza para cerrar o reducir una posición existente en un protocolo. Se utiliza para derivados o staking.",
    decreasePositionDeposit: () =>
      "Cerró o redujo una posición y recibió retornos en su cuenta.",
    deposit: () => "No es un hecho imponible",
    expense: () =>
      "Esto actúa de manera similar a una venta, sin embargo, desea etiquetarlo como un gasto.",
    fee: () => "Tuviste un gasto misceláneo",
    "fiat-transfer": () => "Se transfirió Fiat pero se mantuvo la propiedad.",
    fiatDeposit: () =>
      "Transfirió dinero fiduciario a un exchange desde su cuenta bancaria",
    fiatWithdrawal: () =>
      "Con suerte, retiraste de tu intercambio más de lo que invertiste",
    gift: () =>
      "Recibió un regalo de un tercero, por ejemplo, un miembro de la familia.",
    in: () => "Transacción sin categorizar",
    income: () =>
      "Recibiste ingresos en criptomonedas. También puedes categorizar comisiones, etc. en esta categoría",
    "increase-position": () =>
      "Se utiliza para abrir o aumentar una posición existente en un protocolo. Se utiliza para derivados o staking.",
    increasePositionWithdrawal: () =>
      "Abrió o aumentó una posición invirtiendo criptomonedas desde su cuenta.",
    interest: () => "Recibiste intereses por prestar tu criptomoneda",
    liquidate: () => "Estabas operando con apalancamiento y te cobraron margen",
    loanRepayment: () => "Pagaste la deuda de un préstamo",
    lost: () =>
      "Perdió criptomonedas y desea reclamar la base del costo como una deducción de impuestos.",
    marginFee: () => "Usted realizó un pago de intereses sobre un préstamo",
    mint: () =>
      'Esto actúa de manera similar a una "compra" y un caso de uso común es cuando un usuario está "acuñando" NFT.',
    "multi-mint": () =>
      'Esto actúa de manera similar a una "compra" y se usa comúnmente para "acuñar" NFT.',
    "multi-trade": () => "multi-comercio",
    out: () => "Transacción sin categorizar",
    outgoingGift: () =>
      "Le diste un regalo a un tercero, por ejemplo, a un miembro de la familia.",
    personalUse: () =>
      "Realizó una compra para uso personal y desea reclamarla como no sujeta a impuestos (advertencia: consulte con su contador antes de usarla para asegurarse de que cumple con los criterios)",
    "provide-liquidity-trade": () =>
      "Ha agregado tokens a un fondo de liquidez a cambio de un token de recibo.",
    realisedLoss: () =>
      "Tuvo una pérdida al negociar derivados, por ejemplo, futuros/margen",
    realisedProfit: () =>
      "Recibió ganancias al operar con derivados, por ejemplo, futuros/margen",
    receivePQ: () =>
      "Abrió o aumentó una posición y recibió un token de posición.",
    receivingLiquidityProviderToken: () =>
      "Has recibido tokens por agregar monedas a un fondo de liquidez",
    "remove-liquidity-trade": () =>
      "Ha devuelto su token de liquidez a cambio de recuperar sus fondos del fondo de liquidez.",
    removeLiquidity: () =>
      "Has eliminado estas monedas de un fondo de liquidez",
    returningLiquidityProviderToken: () =>
      "Has devuelto tokens por eliminar monedas de un fondo de liquidez",
    royalty: () =>
      "Recibió ingresos por ventas secundarias, por ejemplo, del creador de NFT",
    sell: () => "Activa un evento de impuesto a las ganancias de capital",
    sendPQ: () =>
      "Cerró o redujo una posición devolviendo el token de posición.",
    staking: () => "Obtuviste intereses al hacer staking",
    stakingDeposit: () =>
      "Ha depositado estas monedas en un fondo de staking. Esto funciona de manera similar a un retiro.",
    stakingWithdrawal: () =>
      "Ha retirado estas monedas del fondo de staking. Esto funciona de manera similar a un depósito.",
    stolen: () =>
      "Le han robado su criptomoneda y desea reclamar el costo base como deducción de impuestos.",
    swap: () => "Intercambio de entrada/salida agrupado automáticamente",
    trade: () => "Compra/venta agrupada automáticamente",
    transfer: () => "Retiro/depósito agrupado automáticamente",
    withdrawal: () => "No es un hecho imponible",
  },
  tradeTypeTaxDescriptionExpanded: {
    addLiquidity: () =>
      "Contribuiste con criptomonedas a un fondo de liquidez.",
    airdrop: () =>
      "Recibiste una distribución gratuita de criptomonedas directamente en tu billetera.",
    approval: () =>
      "Otorgó permisos en los protocolos DeFi, que a menudo implican ciertos costos.",
    "arbitrary-uncategorised": () => "Clasificar por categorías",
    borrow: () =>
      "Usted tomó prestadas criptomonedas de una plataforma, generalmente depositando una garantía.",
    "bridge-trade": () =>
      "Completaste un intercambio entre cadenas, que implica el intercambio de una moneda por otra en diferentes cadenas de bloques.",
    "bridge-transfer": () =>
      "Transferiste criptomonedas de una cadena de bloques a otra.",
    bridgeIn: () =>
      "Recibiste criptomonedas de otra blockchain a través de un puente.",
    bridgeOut: () =>
      "Enviaste criptomonedas a otra blockchain a través de un puente.",
    bridgeTradeIn: () =>
      "Intercambiaste criptomonedas en una cadena de bloques y las enviaste a otra cadena de bloques a través de un puente.",
    bridgeTradeOut: () =>
      "Recibiste criptomonedas en una cadena de bloques a partir de una transacción iniciada en una cadena de bloques diferente.",
    bridgeTransferIn: () =>
      "Recibiste criptomonedas de otra blockchain a través de un puente.",
    bridgeTransferOut: () =>
      "Enviaste criptomonedas a otra blockchain a través de un puente.",
    burn: () =>
      "Enviaste criptomonedas a una dirección quemada, eliminándolas efectivamente de circulación.",
    buy: () =>
      "Compró criptomonedas usando moneda fiduciaria u otra criptomoneda.",
    cashback: () =>
      "Recibiste reembolsos en criptomonedas por compras o actividades específicas.",
    chainSplit: () =>
      "Obtuviste una nueva criptomoneda debido a una bifurcación o división de la cadena de bloques.",
    collateral: () =>
      "Utilizaste criptomonedas como garantía para préstamos u otras actividades.",
    collateralWithdrawal: () =>
      "Recuperaste criptomonedas que anteriormente utilizabas como garantía.",
    "decrease-position": () =>
      "Se utiliza para cerrar o reducir una posición existente en un protocolo. Se utiliza para derivados o staking.",
    decreasePositionDeposit: () =>
      "Cerró o redujo una posición y recibió retornos en su cuenta.",
    deposit: () =>
      "Recibiste criptomonedas de otra cuenta que es de tu propiedad.",
    expense: () =>
      "Utilizaste criptomonedas para servicios, gastos varios o relacionados con el negocio.",
    failedOut: () =>
      "Una transacción que falló durante el procesamiento o no se completó. Excluida de los cálculos de impuestos y saldos.",
    fee: () => "Usted pagó tarifas de red o de transacción.",
    "fiat-transfer": () => "Se transfirió Fiat pero se mantuvo la propiedad.",
    fiatDeposit: () =>
      "Depositó moneda fiduciaria en un exchange o plataforma.",
    fiatWithdrawal: () =>
      "Retiraste moneda fiduciaria de un exchange o plataforma.",
    gift: () => "Recibiste criptomonedas como regalo de otra parte.",
    ignoreIn: () =>
      "Una transacción entrante que está excluida de los cálculos de impuestos y saldos.",
    ignoreOut: () =>
      "Una transacción saliente que está excluida de los cálculos de impuestos y saldos.",
    in: () => "Una transacción entrante que no ha sido categorizada",
    income: () => "Recibió ingresos en criptomonedas de diversas actividades.",
    "increase-position": () =>
      "Se utiliza para abrir o aumentar una posición existente en un protocolo. Se utiliza para derivados o staking.",
    increasePositionWithdrawal: () =>
      "Abrió o aumentó una posición invirtiendo criptomonedas desde su cuenta.",
    interest: () =>
      "Obtuviste intereses al prestar o mantener tus criptomonedas.",
    liquidate: () =>
      "Vendió criptomonedas, a menudo con pérdidas, para cubrir deudas o llamadas de margen.",
    loanRepayment: () =>
      "Usted devolvió las criptomonedas prestadas de una plataforma.",
    lost: () =>
      "Perdió criptomonedas debido a errores, claves olvidadas u otras razones.",
    marginFee: () =>
      "Usted incurrió en tarifas por operaciones con margen o apalancamiento.",
    mining: () =>
      "Obtuviste criptomonedas validando transacciones o realizando tareas en una cadena de bloques.",
    mint: () =>
      "Creaste nuevas criptomonedas o NFT, generalmente en plataformas descentralizadas.",
    "multi-mint": () =>
      "Creaste nuevas criptomonedas o NFT, generalmente en plataformas descentralizadas.",
    "multi-trade": () =>
      "Intercambiaste dos activos (por ejemplo, cripto/fiat o cripto/cripto).",
    out: () => "Una transacción saliente que no ha sido categorizada.",
    outgoingGift: () =>
      "Le regaló criptomonedas a un tercero, como por ejemplo a un familiar.",
    personalUse: () =>
      "Utilizaste criptomonedas para gastos o compras personales.",
    "provide-liquidity-trade": () =>
      "Agregó criptomonedas a un fondo de liquidez y recibió un token de recibo a cambio.",
    realisedLoss: () =>
      "Sufrió una pérdida al cerrar un contrato financiero o derivado.",
    realisedProfit: () =>
      "Obtuvo ganancias al cerrar un contrato financiero o derivado.",
    receivePQ: () =>
      "Abrió o aumentó una posición y recibió un token de posición.",
    receivingLiquidityProviderToken: () =>
      "Recibiste tokens como recompensa por brindar liquidez a un pool.",
    "remove-liquidity-trade": () =>
      "Devolvió su token de liquidez para recuperar criptomonedas del grupo de liquidez.",
    removeLiquidity: () => "Retiraste criptomonedas de un fondo de liquidez.",
    returningLiquidityProviderToken: () =>
      "Devolviste tokens que representan tu parte en un fondo de liquidez.",
    royalty: () =>
      "Recibió criptomonedas como resultado de regalías provenientes de creaciones basadas en criptomonedas, como los NFT.",
    sell: () =>
      "Vendiste tus criptomonedas por moneda fiduciaria u otra criptomoneda.",
    sendPQ: () =>
      "Cerró o redujo una posición devolviendo el token de posición.",
    spamIn: () =>
      "Recibió criptomonedas sin su consentimiento. Esto suele ser el resultado de correo basura o estafas. Excluido de los cálculos de impuestos y saldos.",
    "stake-deposit-group": () =>
      "Ha depositado un token en un contrato de staking y ha recibido un token de recibo que representa su posición.",
    "stake-withdrawal-group": () =>
      "Ha retirado activos apostados de un contrato de staking y ha devuelto el token de recibo que representa la posición.",
    staking: () =>
      "Recibiste criptomonedas o recompensas adicionales como resultado de apostar tus criptomonedas.",
    stakingDeposit: () =>
      "Depositó criptomonedas en un contrato o plataforma de staking.",
    stakingWithdrawal: () =>
      "Retiraste criptomonedas de un contrato o plataforma de staking.",
    stolen: () => "Tu criptomoneda fue robada.",
    swap: () =>
      "Realizó una transacción de criptomoneda a criptomoneda no sujeta a impuestos.",
    swapIn: () =>
      "Recibió criptomonedas de una transacción de cripto a criptomoneda no sujeta a impuestos.",
    swapOut: () =>
      "Usted adquirió criptomonedas mediante un intercambio de cripto a criptomoneda no sujeto a impuestos.",
    trade: () =>
      "Intercambiaste dos activos (por ejemplo, cripto/fiat o cripto/cripto).",
    transfer: () =>
      "Transferiste criptomonedas entre tus cuentas, manteniendo la propiedad.",
    withdrawal: () =>
      "Transferiste criptomonedas de una cuenta a otra que era de tu propiedad.",
  },
  transactionEdit: {
    dialog: {
      actionButton: () => "Salir sin guardar",
      body: () => "Se perderán todos los cambios realizados.",
      cancel: () => "Cancelar",
      title: () => "¿Salir sin guardar?",
    },
    somethingWentWrong: () => "Algo salió mal.",
    trade: () => "Editar comercio",
    transaction: () => "Editar transacción",
    transfer: () => "Editar transferencia",
  },
  transactionTableModal: {
    defaultTitle: (d) =>
      plural(d.count, 0, es, {
        one: " 1 Transacción ",
        other: number("es", d.count, 0) + " Transacciones ",
      }),
    viewTxTable: () => "Ver en la tabla tx",
  },
  transactions: {
    add: () => "Agregar",
    deletedTheTxs: () => "Se eliminaron las transacciones.",
    groups: {
      exchanges: () => "Intercambios",
      other: () => "Otro",
      wallets: () => "Carteras",
    },
    partiallyDeleted: () =>
      "Algo salió mal al eliminar la transacción. La transacción solo se eliminó parcialmente.",
    reconcile: (d) => "Conciliar " + d.count + " elementos",
    title: () => "Actas",
    ungroup: {
      action: () => "Desagrupar",
      body1: () => "¿Está seguro de que desea desagrupar estas transacciones?",
      title: () => "Desagrupar transacciones",
    },
    updateTable: () => "Actualizar la tabla de transacciones",
    wentWrongDeleting: () => "Algo salió mal al eliminar las transacciones",
    wentWrongDeletingComment: () => "Algo salió mal al eliminar el comentario",
    wentWrongDeletingTx: () => "Algo salió mal al eliminar la transacción.",
    wentWrongDuplicatingTx: () =>
      "Algo salió mal al duplicar las transacciones. Inténtalo nuevamente.",
    wentWrongFetching: () =>
      "Se produjo un error al obtener el precio de mercado. Inténtalo nuevamente.",
    wentWrongIgnoringWarning: () => "Algo salió mal al ignorar la advertencia.",
    wentWrongLeavingNote: () => "Algo salió mal al dejar una nota",
    wentWrongRecategorizing: () =>
      "Algo salió mal al recategorizar las transacciones. Inténtalo nuevamente.",
    wentWrongRegrouping: () => "Algo salió mal al reagrupar las transacciones",
    wentWrongUngrouping: () => "Algo salió mal al desagrupar las transacciones",
    wentWrongUpdateCurrency: () =>
      "Se produjo un error al actualizar la moneda de las transacciones. Inténtalo nuevamente.",
    wentWrongUpdatingTrade: () =>
      "Algo salió mal al actualizar la transacción comercial.",
    wentWrongUpdatingTx: () => "Algo salió mal al actualizar la transacción.",
  },
  transferring: () => "Transferencia",
  tryAgain: () => "Intentar otra vez",
  twoFactorAuth: {
    "2FA": () => "2FA",
    authenticationCode: () => "Código de autenticación",
    authy: () => "Auténtico",
    disableError: () => "No se pudo desactivar 2FA",
    disableSuccess: () => "2FA deshabilitado exitosamente",
    disableTwoFactorAuth: () => "Deshabilitar 2FA",
    done: () => "Hecho",
    download: () => "Descargar",
    enableError: () => "No se pudo habilitar 2FA",
    enableSuccess: () => "2FA habilitado exitosamente",
    enableTwoFactorAuth: () => "Habilitar 2FA",
    enterCodeLabel: () => "Introduzca el código de 6 dígitos",
    enterCodeOrRecoveryCodeHelperText: () =>
      "Código de autenticación de 6 dígitos o un código de recuperación",
    enterCodeSubText: () =>
      "Ingresa el código de 6 dígitos de tu aplicación de autenticación",
    enterCodeToChangePassword: () =>
      "Ingresa el código de 6 dígitos generado por tu aplicación de autenticación o uno de los códigos de recuperación para cambiar tu contraseña",
    enterCodeToDisable: () =>
      "Ingresa el código de 6 dígitos generado por tu aplicación de autenticación para deshabilitar 2FA",
    enterCodeToRegenerate: () =>
      "Ingrese el código de 6 dígitos generado por su aplicación de autenticación para regenerar y ver sus códigos de recuperación",
    enterYourPassword: () => "1. Ingrese su contraseña",
    googleAuthenticator: () => "Autenticador de Google",
    manualCodeEntry: () =>
      'Si no puede escanear el código, copie el código a continuación, ingréselo en su aplicación de autenticación y, si hay un menú desplegable con opciones, elija "Basado en tiempo".',
    or: () => " o ",
    password: () => "Contraseña",
    passwordRequired: () => "Se requiere contraseña",
    qrCodeError: () => "No se pudo generar el código QR",
    qrCodeForTwoFactor: () => "Código QR para 2FA",
    recoveryCodes: () => "Ver códigos de recuperación",
    recoveryCodesContent: () =>
      "Si pierde su dispositivo móvil, deberá utilizar uno de estos códigos de recuperación para iniciar sesión en su cuenta de CTC. Cada código solo se puede utilizar una vez.",
    recoveryCodesInfoBoxContent: () =>
      "Si pierde su dispositivo de autenticación y no tiene acceso a sus códigos de recuperación, ¡perderá el acceso a su cuenta CTC!",
    recoveryCodesInfoBoxTitle: () => "Guarde los códigos en un lugar seguro",
    regenerate: () => "Regenerado",
    regenerateError: () =>
      "No se pudieron regenerar los códigos de recuperación",
    regenerateRecoveryCodes: () => "Regenerar códigos de recuperación",
    regenerateSuccess: () => "Códigos de recuperación regenerados exitosamente",
    scanQrCode: () => "2. Escanee este código QR",
    scanWithApp: () =>
      "Escanee el código QR a continuación con una aplicación como ",
    staySignedIn: () => "Mantener la sesión iniciada durante 30 días",
    submit: () => "Entregar",
    twoStepVerification: () => "Verificación en dos pasos",
    verify: () => "Verificar",
    verifyAuthenticationCode: () => "3. Verificar código de autenticación",
    verifyCode: () => "Verificar código",
  },
  txLimitExceeded: () => "Límite de transmisión excedido",
  txTable: {
    account: () => "Cuenta",
    accountBalance: {
      in: () => "Saldo entrante",
      out: () => "Saldo saliente",
    },
    add: () => "Agregar transacción",
    addComment: () => "Añadir comentario",
    addPrice: () => "Añadir precio",
    all: () => "Todo",
    allTxs: () => "Todas tus transacciones aparecerán aquí. Para empezar",
    amount: () => "Cantidad",
    assignMissingBlockchain: () => "Asignar blockchain",
    assumedTransfer: () =>
      "Parte de esta transacción fue generada automáticamente por nuestro motor de sugerencias inteligentes",
    balanceRemainingInfo: {
      account: () => "Cuenta",
      change: () => "Cambiar",
      currency: () => "Divisa",
      endingBalance: () => "Saldo final",
      overallBalance: () => "Balance general",
      startingBalance: () => "Saldo inicial",
      tooltips: {
        change: () =>
          "El cambio en el saldo como resultado de esta transacción",
        endingBalance: () =>
          "El saldo mantenido en esta cuenta después de esta transacción",
        overallBalance: () =>
          "El saldo mantenido en todas las cuentas después de esta transacción.",
        startingBalance: () =>
          "El saldo mantenido en esta cuenta antes de esta transacción.",
      },
      type: () => "Categoría",
      viewLedger: () => "Libro mayor de divisas",
    },
    bulkEditBar: {
      addComment: () => "Añadir comentario...",
      bulkLookupConfirmationText: () =>
        "¿Está seguro de que desea consultar el precio de mercado de las transacciones seleccionadas? Esta acción anulará el precio actual de esta transacción y no se podrá deshacer.",
      changeCurrency: () => "Cambiar moneda",
      clearSelection: () => "Borrar selección",
      close: () => "Cerrar menú de edición",
      command: () => "Dominio",
      confirm: () => "Confirmar",
      delete: () => "Borrar",
      deleteConfirmationText: () =>
        "¡Atención! Esta acción no se puede revertir. ¿Está seguro de que desea eliminar estas transacciones? Le recomendamos que ignore las transacciones siempre que sea posible.",
      edit: () => "Editar",
      editAll: () => "Editar todo",
      groupingWizard: () => "Asistente de agrupación (Beta)",
      groups: () => "Grupos",
      ignore: () => "Ignorar",
      ignoreConfirmationText: () =>
        "¿Está seguro de que desea ignorar estas transacciones? Al realizar esta acción, se ignorarán estas transacciones en cualquier cálculo de impuestos. Las transacciones ignoradas se pueden ocultar de la tabla de transacciones en los filtros avanzados.",
      markAsReviewed: () => "Marcar como revisado",
      markAsUnreviewed: () => "Marcar como no revisado",
      numSelected: (d) => d.numSelected + " seleccionado",
      numSelectedAll: (d) =>
        "Se seleccionan todas las " +
        d.numSelected +
        " transacciones en este filtro",
      open: () => "Abrir menú de comandos",
      recategorizeError: () =>
        "Algo salió mal al recategorizar las transacciones. Inténtalo nuevamente.",
      selectAll: () =>
        "Seleccione todas las transacciones que coincidan con este filtro",
      selected: () => "Seleccionado",
      spam: () => "Marcar como spam",
      spamConfirmationText: () =>
        "¿Está seguro de que desea marcar estas transacciones como spam? Al realizar esta acción, se ignorarán estas transacciones en cualquier cálculo de impuestos. Las transacciones spam se pueden ocultar de la tabla de transacciones en los filtros avanzados.",
      txCount: (d) => d.numSelected + " transacciones",
    },
    bulkLookupPriceConfirmationTitle: () => "Consultar precio de mercado",
    commandPallet: {
      acceptedForProcessing: () =>
        "Actualizando ahora, esto puede tardar unos minutos...",
      actions: {
        accept: () => "Aceptar",
        add: () => "Agregar",
        confirm: () => "Confirmar",
        goBack: () => "Volver",
      },
      bulkEditOptionDescriptions: {
        txRewind: () =>
          "Ingrese la fecha y la hora a las que desea revertir las modificaciones de las transacciones. Nota: Esta acción solo deshará los cambios realizados en las transacciones entre el momento actual y la marca de tiempo seleccionada. No eliminará las transacciones recién creadas o importadas, ni restaurará las transacciones eliminadas. Solo se revertirán las modificaciones que estén visibles en la pestaña de historial de las filas de transacciones relevantes.",
      },
      bulkEditOptions: {
        addCommentToTransactions: () => "Añadir comentario a las transacciones",
        allowGrouping: () => "Permitir agrupación",
        categorize: () => "Clasificar por categorías",
        changeAssetAccount: () => "Cambiar cuenta de activos",
        changeCashAccount: () => "Cambiar cuenta de efectivo",
        changeCurrency: () => "Cambiar moneda",
        changeFeeCurrency: () => "Cambiar la moneda de la tarifa",
        changeGainsAccount: () => "Cuenta de Ganancias por Cambio",
        changeLoanAccount: () => "Cambiar cuenta de préstamo",
        changePnLAccount: () => "Cambiar cuenta PnL",
        changePrice: () => "Cambiar precio",
        delete: () => "Borrar",
        destinationAccount: () => "Cambiar cuenta entrante",
        groupingWizard: () => "Asistente de agrupación (Beta)",
        ignore: () => "Ignorar",
        ignoreWarnings: () => "Ignorar las advertencias",
        lookUpMarketPrice: () => "Consultar precio de mercado",
        markAsNotReviewed: () => "Sin revisar",
        markAsReviewed: () => "Marcar como revisado",
        markAsSpam: () => "Marcar como spam",
        sourceAccount: () => "Cambiar cuenta de salida",
        syncToErp: () =>
          "Sincronización con la integración del software de contabilidad",
        txRewind: () => "Rebobinar transacciones (Beta)",
        ungroup: () => "Desagrupar",
      },
      chips: {
        type: () => "Tipo",
      },
      currentlySelected: () => "Actualmente seleccionado",
      groups: () => "Grupos",
      ignoreWarningsOptions: {
        all: () => "Todo",
        missingBlockchain: () => "Falta la cadena de bloques",
        missingPrice: () => "Precio faltante",
        negativeBalance: () => "Historial de compras faltante",
        uncategorised: () => "Sin categorizar",
        unmatchedTransfer: () => "Transferencia inigualable",
      },
      loadingOptions: () => "Opciones de carga...",
      noOptions: () => "Sin opciones",
      notAvailableOnSelectAll: () =>
        "No disponible en el modo Seleccionar todo",
      numSelected: (d) => d.numSelected + " transacciones",
      placeholders: {
        account: () => "¿Qué cuenta...?",
        category: () => "¿Qué categoría...?",
        changeTo: () => "Cambiar a...",
        currency: () => "¿Qué moneda...?",
        ignoreWarning: () => "Ignorar advertencias...",
        incomingAccount: () => "¿Qué cuenta entrante...?",
        outgoingAccount: () => "¿Qué cuenta de salida...?",
        searchSubType: (d) => "Buscar " + d.subType,
        toCategory: () => "Cambiar a...",
        toSourceAccount: () => "Cambiar a...",
        tradeType: () => "¿Qué categoría...?",
        tradeTypeSearch: {
          group: () => "Buscar categorías agrupadas",
          hint: () => "¡Sugerencia! Esta función de contrato es",
          in: () => "Buscar categorías entrantes",
          out: () => "Buscar categorías salientes",
          uncategorisedTooltipOverride: (d) =>
            "La categorización de esta transacción resolverá " +
            d.value +
            " en las advertencias de transacciones no categorizadas",
        },
      },
      successMessage: {
        addCommentToTransactions: (d) =>
          "Comentario añadido el " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        allowGrouping: (d) =>
          plural(d.count, 0, es, {
            1: " 1 Transacción ",
            other: number("es", d.count, 0) + " Transacciones ",
          }) + " se permite agrupar",
        categorize: (d) =>
          "Recategorizado exitosamente " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        changeAssetAccount: (d) =>
          "Cuenta de activos actualizada el " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        changeCashAccount: (d) =>
          "Cuenta de efectivo actualizada el " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        changeCurrency: (d) =>
          "Se modificó correctamente la moneda para " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        changeFeeCurrency: (d) =>
          "La moneda de la tarifa se modificó el " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        changeGainsAccount: (d) =>
          "La cuenta de ganancias se actualizó el " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        changeLoanAccount: (d) =>
          "Cuenta de préstamo actualizada el " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        changePnLAccount: (d) =>
          "Cuenta PnL actualizada el " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        changePrice: (d) =>
          "Se actualizó exitosamente el precio de " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        delete: (d) =>
          "Eliminado " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        destinationAccount: (d) =>
          "La cuenta entrante cambió el " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        ignore: (d) =>
          "Ignorado " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        ignoreWarnings: (d) =>
          "Se ignoraron las advertencias " +
          d.warning +
          " en " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        lookUpMarketPrice: (d) =>
          "Busqué el precio de mercado en " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        markAsNotReviewed: (d) =>
          plural(d.count, 0, es, {
            1: " 1 Transacción ",
            other: number("es", d.count, 0) + " Transacciones ",
          }) + " marcadas como no revisadas",
        markAsReviewed: (d) =>
          plural(d.count, 0, es, {
            1: " 1 Transacción ",
            other: number("es", d.count, 0) + " Transacciones ",
          }) + " marcadas como revisadas",
        markAsSpam: (d) =>
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }) + " marcadas como spam",
        sourceAccount: (d) =>
          "La cuenta de salida cambió el " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        syncToErp: (d) =>
          "Solicitud de sincronización enviada para " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        txRewind: (d) =>
          "Rebobinar " +
          plural(d.count, 0, es, {
            1: " 1 transacción ",
            other: number("es", d.count, 0) + " transacciones ",
          }),
        ungroup: (d) =>
          plural(d.count, 0, es, {
            1: " 1 Transacción ",
            other: number("es", d.count, 0) + " Transacciones ",
          }) + " sin agrupar",
      },
      syncToErp: (d) => "Sincronizar con " + d.erp,
      typeSearchCommand: () => "Escriba un comando o busque...",
      undo: () => "Deshacer",
      undoFailed: () => "No se pudieron deshacer los cambios",
      undoLoading: () => "Deshaciendo cambios...",
      undoSuccess: () => "¡Éxito!",
    },
    commentLabels: {
      allComments: () => "Todos los comentarios",
      hasComments: () => "Tiene comentarios",
    },
    contractAddress: () => "Dirección del contrato",
    cost: () => "Base de costos",
    counterparty: () => "Contraparte",
    currency: () => "Divisa",
    date: {
      helperFrom: () => "Debes introducir una fecha de inicio válida",
      helperTo: () => "Debes ingresar una fecha válida",
    },
    deleteBulkTransactionsConfirmationTitle: (d) =>
      "Eliminar " +
      plural(d.txCount, 0, es, {
        one: " 1 transacción ",
        other: number("es", d.txCount, 0) + " transacciones ",
      }),
    deleteTransactionConfirmationTitle: () => "Eliminar transacción",
    destination: () => "Destino",
    dialogs: {
      deleteConfirmationText: () =>
        "¡Atención! Esta acción no se puede revertir. ¿Está seguro de que desea eliminar esta transacción? Le recomendamos que ignore la transacción siempre que sea posible.",
      ignoreTransactionText: () =>
        "¿Está seguro de que desea ignorar esta transacción? Al realizar esta acción, se ignorará esta transacción en todos los cálculos de impuestos. Las transacciones ignoradas se pueden ocultar de la tabla de transacciones en los filtros avanzados.",
      spamTransactionText: () =>
        "¿Está seguro de que desea marcar esta transacción como spam? Al realizar esta acción, se ignorará esta transacción en cualquier cálculo de impuestos. Las transacciones spam se pueden ocultar de la tabla de transacciones en los filtros avanzados.",
    },
    entities: {
      explainerTooltip: () =>
        "Si permite que esta entidad actúe como agente de depósito en garantía, podrá retener fondos y ejecutar enajenaciones en su nombre. Algunos ejemplos de situaciones en las que podría querer habilitar esto son las entidades que representan contratos de subasta, contratos de promedio de costo en dólares o cualquier tipo de interacción en la que deposite fondos mientras mantiene la propiedad, hasta un momento posterior en el que el contrato disponga de ellos en su nombre.",
      falsePositive: () => "Ya no está marcado como depósito en garantía",
      globalEscrowTooltip: () =>
        "Esta entidad ha sido marcada como agente de depósito en garantía por CTC",
      switchText: () =>
        "Permitir que esta entidad actúe como agente de depósito en garantía",
      truePositive: () => "Marcado como depósito en garantía",
    },
    expansionPanel: {
      adjPrice: () => "Precio ajustado",
      amount: () => "Cantidad",
      balanceError: () => "Los saldos son negativos",
      chartOfAccounts: {
        accountMappingNotRequired: () =>
          "No se requieren asignaciones de cuentas",
        accountNotFound: (d) =>
          "La cuenta que fue mapeada no se puede encontrar dentro del plan de cuentas de " +
          d.erp,
        accountTypeLabel: {
          asset: () => "Activo",
          cash: () => "Dinero",
          gain: () => "Ganar",
          loan: () => "Préstamo",
          pl: () => "PNL",
          "zero-cost-buy": () => "ZCB",
        },
        assetAccount: () => "Cuenta de activos",
        cashAccount: () => "Cuenta de efectivo",
        dropdown: () => "Buscar cuenta",
        erpAccounts: (d) => "Cuentas " + d.erp,
        from: () => "De",
        gainAccount: () => "Cuenta de ganancia",
        hiddenAccounts: () =>
          "Hemos filtrado esta lista para no incluir cuentas bancarias ni ninguna otra cuenta que no se pueda registrar en el diario.",
        loanAccount: () => "Cuenta de préstamo",
        plAccount: () => "Cuenta de pérdidas y ganancias",
        title: () => "Plan de cuentas",
        to: () => "A",
        transaction: () => "Transacción",
        uncategorised: () =>
          "Establezca la categoría de transacción para asignar cuentas",
        zeroCostBuyAccount: () => "Cuenta ZCB",
      },
      comments: {
        accountant: () => "Contador",
        button: () => "Deja un comentario",
        client: () => "Cliente",
        collaborator: () => "Colaborador",
        comments: (d) =>
          plural(d.count, 0, es, {
            0: " Comentarios ",
            other: " Comentarios ( " + d.count + " ) ",
          }),
        editError: () => "Algo salió mal al editar el comentario",
        edited: () => "(editado)",
        editedAt: (d) => "Editado " + d.time,
        noComments: () => "Los comentarios aparecerán aquí",
        placeholder: () => "Añadir un comentario...",
        saveChanges: () => "Guardar cambios",
        you: () => "Tú",
      },
      cost: () => "Base de costos",
      currency: () => "Divisa",
      date: () => "Fecha",
      delete: {
        body: () =>
          "Una vez que elimines el comentario, desaparecerá para siempre.",
        title: () => "¿Borrar el comentario?",
      },
      events: () => "Eventos asociados a esta transacción",
      fee: () => "Honorarios",
      functionName: (d) => "Función de contrato: " + d.functionName,
      functionNameLabel: () => "Función del contrato",
      gain: () => "Ganar",
      history: {
        changed: () => "cambió",
        created: () => "creado manualmente",
        currentVersion: () => "Versión actual",
        deleted: () => "Eliminado",
        false: () => "No",
        from: () => "de",
        headings: {
          actor: () => "Actor",
          date: () => "Fecha",
          transaction: () => "Cambiar",
        },
        imported: () => "importado",
        modalTitle: (d) => "Restaurar transacciones a " + d.timestamp,
        noChange: () => "Sin cambios",
        noChangesFromCurrentDetected: () => "No se detectaron cambios",
        notRecorded: () => "No registrado",
        notSet: () => "No establecido",
        original: () => "Original",
        restore: (d) => "Restaurar a " + d.time,
        restoreOnly: () => "Restaurar",
        thisVersion: () => "Esta versión",
        thisWillChange: () => "Esto cambiará:",
        title: () => "Historial de edición",
        to: () => "a",
        transaction: () => "transacción",
        transactionKeys: {
          blockchain: () => "Cadena de bloques",
          currencyIdentifier: () => "Divisa",
          description: () => "Descripción",
          dontGroup: () => "No agrupes",
          "erp.accountMappings.asset": () => "Anulación de cuenta de activos",
          "erp.accountMappings.cash": () => "Anulación de cuenta de efectivo",
          "erp.accountMappings.gain": () => "Anulación de cuenta de ganancia",
          "erp.accountMappings.pl": () => "Anulación de cuenta PnL",
          fee: () => "Tarifa",
          feeCurrencyIdentifier: () => "Moneda de la tarifa",
          feeQuantity: () => "Cantidad de la tarifa",
          from: () => "De",
          functionName: () => "Función Tx",
          functionSignature: () => "Firma de Tx",
          price: () => "Precio",
          quantity: () => "Cantidad",
          reviewed: () => "Revisado",
          suggestion: () => "Sugerencia",
          timestamp: () => "Marca de tiempo",
          to: () => "A",
          trade: () => "Categoría",
        },
        true: () => "Sí",
        unableToCompare: () => "No se puede comparar",
        unableToFindPreviousVersion: () =>
          "No se puede encontrar la versión anterior",
        undo: () => "Deshacer",
        undoLatestChange: () => "¿Deshacer este cambio?",
        updateReason: {
          import: () => "Precio de CTC en Oracle",
          migration: () => "Sistema CTC",
          rule: () => "Servicio de Normas del CTC",
          system: () => "Sistema CTC",
          transactionMatching: () => "Coincidencia de transacciones de CTC",
          unknown: () => "Desconocido",
          user: () => "Usuario",
        },
        updateReasonActorCTCSystem: () => "Sistema CTC",
        updateReasonActorUnknown: () => "Desconocido",
        updateReasonActorUser: () => "Usuario",
      },
      id: () => "IDENTIFICACIÓN:",
      importSource: (d) => "Origen de la importación: " + d.sourceDisplayName,
      inflightBalanceError: () =>
        "La falta de un historial de compras indica que faltan algunas de tus transacciones salientes. Esto puede suceder si no has importado todos tus datos",
      moreInfo: () => "Más información",
      moreInfoPopover: {
        importSource: () => "Fuente de importación:",
        learnAbout: () => "Conozca más sobre",
        title: () => "Información adicional",
      },
      na: () => "N / A",
      notes: (d) => "Descripción: " + d.notes,
      price: () => "Precio",
      proceeds: () => "Producto",
      receivedFrom: () => "Recibido de",
      rolloverFrom: (d) => "Refinanciamiento desde " + d.currency,
      ruleApplied: () => "Regla aplicada",
      sentBy: () => "Enviado a",
      sourceType: (d) => "Precio Fuente: " + d.sourceType,
      sourceTypes: {
        ManualUpdate: () => "Actualización manual",
        PriceFromAction: () => "Precio de la acción",
        PriceWindow: () => "Ventana de caída de precios",
      },
      time: () => "Tiempo",
      total: () => "Total",
      tradedOn: () => "Comercializado en",
      transactionId: (d) =>
        plural(d.count, 0, es, { one: " Tx ID ", other: " Tx IDs " }),
      transactionIdLabel: () => "Identificación de Tx",
      transactionsTab: () => "Actas",
      type: () => "Categoría",
      ungroup: {
        buttonText: () => "Permitir agrupación",
        infoBody: () =>
          "Esta transacción se ha desagrupado manualmente y, como resultado, nuestro algoritmo de agrupación ya no la considerará parte de una operación o transferencia.",
        infoTitle: () => "Transacción no agrupada",
        title: () => "Desagrupado",
      },
      updating: () => "Actualización de transacciones",
      value: () => "Valor",
      viewAssociated: () => "Ver transacciones asociadas",
      viewBalance: () => "Ver libro mayor",
      viewRelatedTx: () => "Ver transacciones relacionadas",
      viewTaxRule: () => "Ver regla fiscal",
      viewTx: () => "Ver TX",
    },
    exportCSV: {
      filterOptionCaption: () => "Las transacciones coinciden con este filtro",
      filterOptionTitle: () => "Transacciones en filtro",
      pageOptionCaption: () => "transacciones actualmente en la página",
      pageOptionTitle: () => "Transacciones en la página",
      paywall: {
        button: () => "Seleccione un plan",
        text: () => "Se requiere un plan para exportar transacciones.",
      },
      pending: {
        captionPt1: () =>
          "El informe se descargará automáticamente cuando esté listo.",
        captionPt2: () => "Puedes cerrar esta ventana.",
        title: () => "Generando informe",
      },
      submitButton: (d) => "Exportar " + d.count + " transacciones",
      success: {
        captionPt1: () => "Si no ve su archivo, puede ",
        captionPt2: () => "Descárgalo de nuevo",
        title: () => "Su exportación ha sido descargada",
      },
      title: () => "Transacciones de exportación CSV",
    },
    fee: () => "Tarifa",
    feeValue: (d) => "Valor de la tarifa: " + d.amount,
    fiatTradeLabel: {
      deposit: () => "Depósito",
      withdrawal: () => "Retiro",
    },
    filter: {
      addFilter: () => "Añadir filtro",
      advanced: () => "Avanzado",
      advancedFilters: () => "Borrar filtros para buscar",
      category: () => "Categoría",
      clearFilters: () => "Limpiar filtros",
      comment: () => "Comentarios",
      currency: () => "Divisa",
      date: () => "Fecha",
      descriptions: {
        actionId: () => "Buscar una transacción por su ID",
        after: () => "Buscar transacciones posteriores a la fecha especificada",
        and: () =>
          "Busque transacciones que coincidan con TODOS los filtros seleccionados",
        before: () => "Buscar transacciones anteriores a la fecha especificada",
        blockchain: () =>
          "Busque transacciones por la cadena de bloques en la que ocurrieron",
        category: () => "Buscar transacciones por su categoría",
        comment: () =>
          "Búsqueda de transacciones por el contenido de sus comentarios",
        comments: () =>
          "Búsqueda de transacciones por el contenido de sus comentarios",
        currency: () =>
          "Búsqueda de transacciones por la moneda que se está transfiriendo",
        date: () => "Buscar transacciones en una fecha específica",
        description: () =>
          "Búsqueda de transacciones por el contenido de su descripción",
        erpAssetAccount: () =>
          "Búsqueda de transacciones por su integración contable, anulación del mapeo de cuentas de activos",
        erpCashAccount: () =>
          "Búsqueda de transacciones por su integración contable, anulación del mapeo de cuenta de efectivo",
        erpGainsAccount: () =>
          "Búsqueda de transacciones por su integración contable, ganancia de anulación del mapeo de cuentas",
        erpLoanAccount: () =>
          "Búsqueda de transacciones por su integración contable, anulación del mapeo de cuentas de préstamos",
        erpPnlAccount: () =>
          "Búsqueda de transacciones por su integración contable Anulación del mapeo de cuentas de pérdidas y ganancias",
        erpSyncStatus: () =>
          "Búsqueda de transacciones por su estado de sincronización de integración contable",
        feeCurrency: () =>
          "Busque transacciones por la moneda en la que se pagó la tarifa",
        from: () => 'Buscar transacciones por su cuenta "de"',
        hasRule: () =>
          "Ocultar transacciones que no se ven afectadas por las reglas",
        id: () => "Buscar una transacción por su ID",
        importId: () =>
          "Busque transacciones por la sincronización en la que se importaron",
        importType: () =>
          "Busque transacciones por el tipo de importación por la que fueron traídas",
        isSmartContractInteraction: () =>
          "Búsqueda de transacciones con contratos inteligentes",
        ledger: () =>
          "Buscar transacciones relacionadas con la vista del libro mayor actual",
        missingPrice: () => "Buscar transacciones por",
        negativeBalance: () => "Buscar transacciones por",
        nftCollection: () =>
          "Busque transacciones que intercambien NFT de una colección específica",
        not: () =>
          "Buscar transacciones que NO coincidan con el filtro seleccionado",
        or: () =>
          "Busque transacciones que coincidan con ALGUNOS de los filtros seleccionados",
        party: () =>
          "Búsqueda de transacciones por las cuentas transaccionadas entre",
        reconciliation: () =>
          "Búsqueda de transacciones relacionadas con una propina",
        reviewed: () => "Búsqueda de transacciones revisadas",
        rule: () =>
          "Búsqueda de transacciones manipuladas por una regla específica",
        ruleOperator: () =>
          "Búsqueda de transacciones manipuladas por un operador de regla específico",
        source: () =>
          "Búsqueda de transacciones por las cuentas transaccionadas entre",
        suggestion: () =>
          "Busque transacciones que fueron categorizadas por nuestro motor de sugerencias inteligentes",
        sync: () =>
          "Busque transacciones por la sincronización en la que se importaron",
        taxOutcomeType: () =>
          "Búsqueda de transacciones por su resultado fiscal",
        to: () => 'Buscar transacciones por su cuenta "a"',
        trade: () => "Busque transacciones por su tipo",
        txCurrency: () =>
          "Busque transacciones por las monedas que se realizaron",
        txFunctionName: () =>
          "Búsqueda de transacciones por la función de contrato",
        txFunctionSignature: () =>
          "Búsqueda de transacciones por el id del método",
        txId: () => "Búsqueda de transacciones por hash de blockchain",
        warning: () => "Busque transacciones por las advertencias que tienen",
      },
      erpSyncStatus: {
        NOT_READY_TO_SYNC: () => "No está listo para sincronizar",
        SYNC_FAILED: () => "Fallido",
        SYNC_IN_PROGRESS: () => "En curso",
        SYNC_NOT_REQUESTED: () => "Listo para sincronizar",
        SYNC_NO_OP: () => "Sin operación",
        SYNC_OUT_OF_DATE: () => "Desactualizado",
        SYNC_SUCCESSFUL: () => "Exitoso",
      },
      filter: () => "Filtrar",
      filterBuilderOperatorOverrides: {
        category: () => "Categoría",
      },
      filters: () => "Filtros",
      hasRule: () =>
        "Ocultar transacciones que no se ven afectadas por las reglas",
      hideIgnored: () => "Ocultar transacciones ignoradas o spam",
      hideLockedTransactions: () => "Ocultar transacciones bloqueadas",
      hideNonSuggested: () =>
        "Mostrar solo transacciones categorizadas de forma inteligente",
      hideReviewed: () => "Ocultar transacciones revisadas",
      importSource: () => "Fuente de importación",
      importType: () => "Tipo de importación",
      imports: () => "Importaciones",
      includesFee: {
        doesNotIncludesFee: () => "Sin cargos",
        includesFee: () => "Tiene tarifas",
      },
      isSmartContractInteraction: {
        smartContract: () => "Contrato inteligente",
        wallet: () => "Billetera",
      },
      lockPeriodTooltip: () => "Agregue un período bloqueado para habilitar",
      manual: () => "Manual",
      moreFilters: () => "Más filtros",
      negation: {
        is: () => "Es",
        isNot: () => "No es",
      },
      noOptions: () => "Sin opciones",
      noResults: () => "No hay resultados",
      noWarnings: () => "No tienes advertencias",
      operators: {
        actionId: () => "Identificación de acción",
        after: () => "Después",
        and: () => "Y",
        before: () => "Antes",
        blockchain: () => "Cadena de bloques",
        category: () => "Categoría",
        comment: () => "Comentario",
        comments: () => "Comentarios",
        currency: () => "Divisa",
        date: () => "Fecha",
        description: () => "Descripción",
        erpAssetAccount: () => "Cuenta de activos",
        erpCashAccount: () => "Cuenta de efectivo",
        erpGainsAccount: () => "Cuenta de ganancia",
        erpLoanAccount: () => "Cuenta de préstamo",
        erpPnlAccount: () => "Cuenta de pérdidas y ganancias",
        erpSyncStatus: () =>
          "Estado de sincronización de la integración contable",
        feeCurrency: () => "Moneda de la tarifa",
        from: () => "De",
        hasRule: () =>
          "Ocultar transacciones que no se ven afectadas por las reglas",
        id: () => "IDENTIFICACIÓN",
        importId: () => "Identificación de importación",
        importType: () => "Tipo de importación",
        includesFee: () => "Honorarios",
        integrations: () => "Integración",
        isSmartContractInteraction: () => "Interacción",
        ledger: () => "Mostrar saldos",
        lockPeriod: () => "Período de bloqueo",
        missingPrice: () => "Precio faltante",
        negativeBalance: () => "Historial de compras faltante",
        nftCollection: () => "Colección NFT",
        not: () => "No",
        or: () => "O",
        party: () => "Fiesta",
        reconciliation: () => "Reconciliación",
        reviewed: () => "Revisado",
        rule: () => "Regla",
        ruleOperator: () => "Operador de regla",
        source: () => "Cuenta",
        suggestion: () => "Sugerencia",
        sync: () => "Sincronizar",
        taxOutcomeType: () => "Resultado fiscal",
        to: () => "A",
        trade: () => "Categoría",
        txCurrency: () => "Moneda Tx",
        txFunctionName: () => "Firma de Tx",
        txFunctionSignature: () => "Identificación del método Tx",
        txId: () => "Identificación de Tx",
        warning: () => "Advertencia",
      },
      otherFilter: () => "Vista",
      placeholder: () => "Buscar transacciones",
      present: () => "Presente",
      resetFilters: () => "Restablecer filtros",
      search: () => "Operadores de filtros de búsqueda",
      selectAll: () => "Seleccionar todo",
      settingsFilter: () => "Mesa",
      showIgnored: () => "Mostrar transacciones ignoradas/spam",
      sort: () => "Clasificar",
      source: () => "Fuente",
      table: () => "Saldos",
      taxOutcomeType: {
        income: () => "Ingreso",
        longTermCapitalGain: () => "Ganancia de capital a largo plazo",
        longTermCapitalLoss: () => "Pérdida de capital a largo plazo",
        shortTermCapitalGain: () => "Ganancia de capital a corto plazo",
        shortTermCapitalLoss: () => "Pérdida de capital a corto plazo",
      },
      textMatching: {
        is: () => "Contiene",
        isNot: () => "No contiene",
      },
      transactionId: () => "Introduzca el ID de la transacción",
      trySearchingFor: () => "Intente buscar...",
      txFunctionName: () => "Firma de Tx",
      txFunctionSignature: () => "Identificación del método Tx",
      txId: () => "Identificación de Tx",
      txSignature: () => "Firma",
      typeToSearch: () => "Escribe para buscar",
      warningLabel: (d) => d.label + " ( " + d.number + " )",
      warnings: () => "Advertencias",
    },
    filteredCount: (d) =>
      d.totalFilteredCount + " de " + d.totalCount + " transacciones",
    from: () => "Extrovertido",
    gain: () => "Ganar",
    generateReport: () => "Generar informe",
    id: () => "IDENTIFICACIÓN",
    identifyAddress: () => "Identificar dirección",
    ignoreBulkTransactionsConfirmationTitle: () => "Ignorar transacciones",
    ignoreTransactionConfirmationTitle: () => "Ignorar transacción",
    ignored: () => "Ignorado",
    ignoredWarningLabels: {
      missingBlockchain: () => "Falta la cadena de bloques (ignorada)",
      negativeBalance: () => "Historial de compras faltante (ignorado)",
    },
    importData: () => "importar datos",
    importFilterOther: () => "Otro",
    importMoreData: () => "Importar más datos",
    importType: {
      api: () => "API",
      "assumed-fee": () => "Tarifa asumida",
      "bulk-wallet": () => "Importación masiva",
      csv: () => "CSV",
      manual: () => "Manual",
      "manual-csv": () => "Manual CSV",
      oauth: () => "OAuth",
      "soft-transfer": () => "Sugerencia inteligente",
      "soft-wallet": () => "Monedero blando",
      wallet: () => "Billetera",
      "wallet-api": () => "API de billetera",
      "wallet-csv": () => "CSV de billetera",
    },
    invalidBulkEditTooltip: () =>
      "No se pueden editar de forma masiva transacciones agrupadas",
    invalidCategoryTradeUncategorised: (d) =>
      "Mejore la precisión categorizando esta transacción. Actualmente, se utiliza la categoría predeterminada " +
      d.tradeType +
      " , lo que podría generar un evento de ganancia de capital.",
    labels: () => "Etiquetas",
    missingBlockchainDescription: () =>
      "Esto establecerá la cadena de bloques de la que proviene esta dirección.",
    moreThan: () => "Más que",
    noComments: () => "Sin comentarios",
    noData: () => "No hay datos añadidos",
    noTxFound: () => "No se encontraron transacciones",
    noTxMatching: () =>
      "No se encontraron transacciones que coincidan con el filtro. Intente con otro filtro.",
    noTxMatchingWithReportRefreshRunning: () =>
      "Actualmente estamos actualizando sus transacciones, es posible que se encuentren más transacciones después de que esto se complete.",
    none: () => "Ninguno",
    of: () => "de",
    openInNewTab: () => "Abrir en nueva pestaña",
    overallBalance: () => "Balance general",
    pagination: {
      pageXofY: (d) => d.pageNum + " de " + d.totalPages,
    },
    plusMore: (d) => "+ " + d.count + " más",
    price: () => "Precio",
    quantity: () => "Cantidad",
    recentComments: () => "Comentarios recientes",
    recentCommentsMore: (d) => "+ " + d.count + " más",
    remaining: () => "Restante",
    rowsPerPage: () => "Filas por página:",
    savedAddresses: {
      addError: () =>
        "Algo salió mal al guardar la dirección de la cadena de bloques",
      body: () =>
        "Esto cambiará el nombre de la dirección dondequiera que haya una transacción hacia y desde esta dirección.",
      deleteError: () =>
        "Algo salió mal al eliminar la dirección de blockchain guardada",
      importWallet: () => "Importar billetera",
      lengthError: () => "El nombre no puede exceder los 64 caracteres.",
      nameAddress: () => "Nombre dirección",
      placeholder: () => "Apodo",
      title: () => "Nombre dirección",
      updateError: () =>
        "Algo salió mal al actualizar la dirección de blockchain guardada",
    },
    search: () => "Buscar",
    select: () => "Seleccionar",
    showing: () => "Demostración: ",
    similarTxs: () => "Txs similares",
    smartSuggestion: () =>
      "Esta transacción ha sido categorizada automáticamente por nuestro motor de sugerencias inteligentes",
    sort: {
      feeAscending: () => "Tarifa: baja a alta",
      feeDescending: () => "Tarifa - De alta a baja",
      gainAscending: () => "Ganancia: de baja a alta",
      gainDescending: () => "Ganancia - De alta a baja",
      lastModifiedAscending: () => "Ordenar por primera edición",
      lastModifiedDescending: () => "Ordenar por última edición",
      newestFirst: () => "El más nuevo",
      oldestFirst: () => "Más antiguo",
      uncategorisedSizeAscending: () => "Sin categorizarTamañoAscendente",
      uncategorisedSizeDescending: () => "Sin categorizarTamañoDescendente",
      uncategorisedValueAscending: () => "Sin categorizarValor ascendente",
      uncategorisedValueDescending: () => "Sin categorizarValor Descendente",
      valueAscending: () => "Valor - Bajo a alto",
      valueDescending: () => "Valor - De alto a bajo",
    },
    source: () => "Fuente",
    sourceFilter: {
      exchanges: () => "Intercambios",
      other: () => "Otro",
      wallets: () => "Carteras",
    },
    spamBulkTransactionsConfirmationTitle: () =>
      "Marcar transacciones como spam",
    spamTransactionConfirmationTitle: () => "Marcar transacción como spam",
    time: () => "Fecha",
    title: () => "Actas",
    to: () => "Entrante",
    tooltipGain: (d) => "Ganancia total de " + d.totalGain,
    tooltipLoss: (d) => "Pérdida total de " + d.totalLoss,
    total: () => "Total",
    totalFiltered: () => "Recuento total de transacciones filtradas",
    totalTxCount: (d) => d.totalCount + " transacciones",
    totalTxs: () => "Recuento total de transacciones",
    trade: () => "Categoría",
    transaction: () => "Transacción",
    transactionId: () => "Identificación de Tx",
    transactions: () => "Actas: ",
    txRow: {
      balanceRemaining: (d) => "Saldo restante ( " + d.displayName + " ):",
      balanceRemainingOverall: () => "Saldo restante (general):",
      cost: () => "Costo",
      erp: {
        categoriseToSync: () => "Clasifique esta transacción para sincronizar",
        mapAllAccounts: () => "Completar todas las asignaciones de cuentas",
        provideDefaultAccountMappings: () =>
          "Proporcionar asignaciones de cuentas predeterminadas",
        reportFailed: () =>
          "No se pueden sincronizar las transacciones mientras los cálculos están en estado de error",
        resolveAllWarnings: () =>
          "Resolver todas las advertencias de transacciones",
        status: {
          NOT_READY_TO_SYNC: (d) =>
            "No está listo para sincronizar con " + d.erpProvider,
          SYNC_FAILED: (d) => "No se pudo sincronizar con " + d.erpProvider,
          SYNC_IN_PROGRESS: (d) =>
            "Sincronización con " + d.erpProvider + " en curso",
          SYNC_NOT_REQUESTED: (d) => "Sincronizar con " + d.erpProvider,
          SYNC_NO_OP: (d) =>
            "No hay diarios para sincronizar con " + d.erpProvider,
          SYNC_OUT_OF_DATE: (d) => "Resincronizar con " + d.erpProvider,
          SYNC_SUCCESSFUL: (d) => "Sincronizado con " + d.erpProvider,
        },
        viewJournal: () => "ver diario",
        viewPreviousJournal: () => "ver diario anterior",
        waitForCalculationsToFinish: () =>
          "Por favor espere a que finalicen sus cálculos.",
      },
      feeValue: () => "Valor de la tarifa de",
      lockedRowTooltip: {
        action: () => "Ver configuración de transacciones de bloqueo",
        text: () => "No puedes desbloquear transacciones individuales",
      },
      moreOptions: {
        generateMatchingTransfer: () => "Generar coincidencia",
      },
      noFees: () => "No se pagan tasas",
      overflowCurrencies: (d) => "+ " + d.count + " más...",
      prefix: {
        exchange: () => "Intercambio",
        from: () => "Fuente",
        fromTo: () => "Origen/Destino",
        to: () => "Destino",
      },
      similarCount: (d) =>
        plural(d.count, 0, es, {
          one: " 1 tx ",
          other: number("es", d.count, 0) + " txs ",
        }),
      similarValue: (d) => d.symbol + d.value + " total",
      singleSideLabels: {
        from: () => "Fuente:",
        to: () => "Destino:",
      },
      smartContract: () => "Contrato inteligente",
      tooltip: () => "Hora de la transacción (en su zona horaria local)",
      totalTransactionValue: () => "Valor total de la transacción",
    },
    type: () => "Categoría",
    typeUpdateWarningModal: {
      transferSmartContract: {
        change: () => "Cambiar categoría",
        contract: () => "Contrato",
        ignore: () => "Ignorar",
        receiveFromAction: () =>
          "Seleccione una categoría más descriptiva para clasificar adecuadamente las transacciones con contratos inteligentes.",
        receiveFromInfo: () =>
          "Esta transacción se realiza mediante un contrato inteligente. La categoría “Recibir” solo debe utilizarse cuando se recibe desde otra billetera de su propiedad.",
        receiveFromTitle: () => "Recibir de un contrato inteligente",
        sendToAction: () =>
          "Seleccione una categoría más descriptiva para clasificar adecuadamente las transacciones con contratos inteligentes.",
        sendToInfo: () =>
          "Esta transacción se realiza mediante un contrato inteligente. La categoría “Enviar” solo debe utilizarse cuando se envía a su propia billetera.",
        sendToTitle: () => "Envío a un contrato inteligente",
      },
    },
    uncategorised: () => "Sin categorizar",
    unknownAccountWarning: {
      CTA: (d) =>
        "Si la cuenta " +
        select(d.side, {
          from: " recibes ",
          to: " envías ",
          other: " interactúas con ",
        }) +
        " te pertenece, [importala]( " +
        d.linkTextKey +
        ' ). La transacción se clasificará automáticamente como una "transferencia".',
      body: (d) =>
        "La dirección " +
        select(d.side, {
          from: " shipping ",
          to: " reception ",
          other: " unknown ",
        }) +
        " para esta transacción no fue proporcionada por " +
        select(d.isBlockchain, {
          true: " blockchain ",
          false: " exchange ",
          other: " account ",
        }) +
        ".",
      subTitle: (d) =>
        "¿Es esta una transferencia " +
        select(d.side, {
          from: " desde otro ",
          to: " a otro ",
          other: " con una cuenta criptográfica desconocida ",
        }) +
        " que usas?",
      title: (d) =>
        select(d.side, {
          from: " Transacción entrante de un remitente desconocido ",
          to: " Transacción saliente a un destinatario desconocido ",
          other: " Transacción con una parte desconocida ",
        }),
      tooltip: (d) =>
        select(d.side, {
          from: " Remitente desconocido ",
          to: " Destinatario desconocido ",
          other: " Parte desconocida ",
        }),
    },
    updatePrice: () => "Actualizar precio",
    updateValue: () => "Actualizar valor",
    value: () => "Valor",
    viewAddress: () => "Ver detalles de la dirección",
    viewAssociatedTransactionsModal: {
      exchange: () => "Intercambio",
      import: (d) => "Si este es tu " + d.importType,
      smartContract: () => "Contrato inteligente",
      transactions: () => "Actas:",
      type: () => "Tipo:",
      wallet: () => "Billetera",
    },
    viewComments: () => "Ver comentarios",
    viewLedger: () => "Libro mayor de divisas",
    viewTx: () => "Ver detalles de la transacción",
    warningLabels: {
      all: () => "Todo",
      missingBlockchain: () => "Falta la cadena de bloques",
      missingPrice: () => "Precio de mercado faltante",
      negativeBalance: () => "Historial de compras faltante",
      uncategorised: () => "Requiere categorización",
      unknownInOut: () => "Entrada/salida desconocida",
      unmatchedTransfer: () => "Transferencia inigualable",
      zeroCostBuy: () => "Compra a costo cero",
    },
    warnings: {
      bridgeTransferIn: () =>
        "Este es un puente de un solo lado: haga coincidir esta transacción con el otro lado del puente.",
      bridgeTransferOut: () =>
        "Este es un puente de un solo lado: haga coincidir esta transacción con el otro lado del puente.",
      bridgein: () =>
        "Este es un puente de un solo lado: haga coincidir esta transacción con el otro lado del puente.",
      bridgeout: () =>
        "Este es un puente de un solo lado: haga coincidir esta transacción con el otro lado del puente.",
      receive: () =>
        "Esta recepción proviene de una billetera que no ha sido importada o faltan datos para el lado de envío de la transacción. \n\n Si esta transacción es de un tercero, seleccione otra categoría adecuada. \n\n Si a esta recepción le falta el lado de envío, puede conciliar las transferencias no coincidentes en la página de conciliación.",
      send: () =>
        "Este envío es a una billetera que no ha sido importada o faltan datos del lado receptor de la transacción. \n\n Si esta transacción es para un tercero, seleccione otra categoría adecuada. \n\n Si en este envío falta el lado de recepción, puede conciliar las transferencias no coincidentes en la página de conciliación.",
      uncategorized: (d) =>
        'Siga nuestros <a href="' +
        d.link +
        '" target="_blank"> consejos </a> para obtener más contexto sobre esta transacción.',
      uncategorizedReview: () =>
        "Clasifique esta transacción para mejorar la precisión del informe",
    },
  },
  uncategorisedModal: {
    actionTooltip: () =>
      "Las transacciones en el grupo deben tener una categoría",
    cancel: () => "Cancelar",
    moreOptions: () => "Más opciones",
    save: () => "Ahorrar",
    subTitle: () => "Transacciones en grupo:",
    title: () => "Categorizar grupo",
  },
  uncategorisedToggles: () => "Tratamiento de transacciones sin categorizar",
  unknownInOut: {
    url: () =>
      "https://help.cryptotaxcalculator.io/en/articles/5279619-categorise-transactions",
  },
  update: () => "Actualizar",
  userArchive: {
    failedToRestore: () => "No se pudieron restaurar los datos archivados",
    restoreData: () => "Restaurar sus datos",
    title: () =>
      "¡Bienvenido de nuevo! Ha pasado tiempo desde tu último inicio de sesión, por lo que hemos archivado tus datos para mantenerlos seguros. Pulsa restaurar datos a continuación para continuar con la conciliación",
  },
  viewAssociatedTransactions: () => "Ver transacciones asociadas",
  wallet: {
    address: (d) => "Ingresa la dirección de la billetera " + d.source,
    addresses: () => "Direcciones de billetera",
    bulk: () => "Pegue aquí sus direcciones públicas",
    bulkError: () => "No se pueden analizar las direcciones de la billetera",
    bulkHelperText: () =>
      "Pegue las direcciones de su billetera exactamente como fueron exportadas desde la aplicación de billetera Coinbase",
    deleteConfirmationText: (d) =>
      "¿Está seguro de que desea eliminar la billetera " +
      d.wallet +
      ' " ' +
      d.walletAddress +
      ' "? Esto también eliminará TODAS las transacciones asociadas con esta billetera. Esta acción no se puede deshacer.',
    editDetails: () => "Editar los detalles de la billetera",
    editingSubmit: () => "Actualizar el nombre de la billetera",
    equivWallet: (d) =>
      "Esta billetera utiliza la cadena de bloques " +
      d.name +
      ' para sincronizarse. Una vez sincronizada, puede ver las billeteras conectadas en el panel " ' +
      d.name +
      ' " de esta página.',
    existingSubmit: (d) => "Agregar billetera " + d.source + " adicional",
    hardSync: {
      beginningOfTime: () => "El principio del tiempo",
      confirmationOneWallet: () => "¿Billetera sincronizada de forma rígida?",
      lastLockedPeriod: () =>
        "(la fecha de finalización de su período bloqueado más reciente)",
      syncedFromDate: () =>
        "(la fecha desde la cual se sincroniza esta billetera)",
      textOneWallet: (d) =>
        "La sincronización dura importará todas las transacciones de la billetera " +
        d.name +
        " desde " +
        d.date +
        " . Esto a veces puede generar transacciones duplicadas que deben gestionarse manualmente, en caso de que la integración haya cambiado desde la última sincronización.",
      titleOneWallet: (d) =>
        "Resincronizar todas las transacciones de la billetera " +
        d.name +
        " desde " +
        d.date,
    },
    hardSyncWarning: (d) =>
      "La sincronización rígida de las billeteras " +
      d.name +
      " creadas antes del 5 de mayo de 2022 dará como resultado transacciones duplicadas debido a cambios recientes. Recomendamos a los usuarios que crearon billeteras " +
      d.name +
      " antes de esta fecha que eliminen su billetera y la vuelvan a importar, o que eviten la sincronización rígida de sus billeteras.",
    importRelatedWallets: {
      actionTitle: () => "Escanear cadenas EVM",
      actionTooltip: () =>
        "Escanee las transacciones asociadas con esta dirección de billetera en todas las cadenas compatibles con EVM.",
      noResults: () => "No se encontró actividad relacionada",
      syncError: () => "Error al obtener las billeteras relacionadas",
      syncSuccess: () =>
        "Se han detectado transacciones en varias cadenas de bloques. Ahora se importarán.",
      title: () => "Escanear cadenas EVM",
      tooltipOff: () =>
        "Actualmente no se importarán las transacciones en cadenas relacionadas",
      tooltipOn: () =>
        "Actualmente se importarán las transacciones en cadenas relacionadas",
    },
    importRules: {
      addressAlreadyExistsError: () =>
        "Esta dirección de billetera ya ha sido importada.",
      bulkEmpty: (d) =>
        "Debes ingresar tu lista de direcciones públicas " + d.name + " .",
      bulkExisting: (d) =>
        "Una de las direcciones " +
        d.name +
        " que ingresaste ya está agregada.",
      bulkInvalid: (d) =>
        "Una de las direcciones ingresadas no es una dirección de billetera " +
        d.name +
        " válida.",
      empty: (d) =>
        "Debes ingresar la dirección de tu billetera pública " + d.name + " .",
      existing: (d) => "La dirección de billetera " + d.name + " ya se agregó.",
      invalid: (d) => "No es una dirección de billetera " + d.name + " válida.",
      invalidAddressError: () =>
        "Esta no es una dirección de billetera válida para esta fuente de importación.",
      invalidBulkBlockchain: (d) =>
        'Valor de cadena de bloques no válido " ' + d.chain + ' ".',
      invalidEnsError: () => "No se pudo resolver el nombre ENS.",
      invalidSnsError: () => "No se pudo resolver el nombre de SNS.",
      tooManyWallets: (d) =>
        "Solo puedes importar un máximo de " +
        d.maxWalletImports +
        " billeteras a la vez.",
      tooManyWalletsError: () =>
        "Esta billetera excede la cantidad máxima de billeteras que puedes importar a la vez.",
    },
    invalid: () =>
      "Nombre de billetera no válido, no utilice los caracteres: $ ; \" ' `",
    name: () => "Nombre de la billetera",
    nameRequired: (d) => "Ingrese un nombre para su billetera " + d.walletName,
    nickname: () => "Apodo (opcional)",
    nicknameCanBeAddedLater: () =>
      "Puedes ponerles un apodo a estas billeteras después de importarlas",
    relatedChains: {
      body: (d) => d.chains,
      title: () => "Cadenas EVM relacionadas:",
    },
    safetyInfo: {
      frontPart: () => "Estamos solicitando su ",
      highlightPart: () => "Solo dirección de billetera pública",
      lastPart: () =>
        "Nunca solicitaremos claves privadas y no podemos transferir fondos.",
    },
    softSync: {
      action: () => "Sincronización suave",
      title: (d) =>
        "Sincronizar las transacciones de la billetera " + d.name + " desde",
    },
    submit: (d) => "Importar billetera " + d.source,
    submitMultiple: () => "Importar carteras",
    title: () => "Importar datos de la billetera",
    type: () => "Tipo de cadena de bloques",
    walletNicknameUpdate: () =>
      "El apodo de la billetera se actualizó correctamente",
    wentWrongSavingWalletData: () =>
      "Algo salió mal al guardar los datos de la billetera.",
    wentWrongSyncingWalletData: () =>
      "Algo salió mal al sincronizar los datos de la billetera.",
  },
  warningDisplayName: {
    all: () => "Todo",
    missingBlockchain: () => "Falta la cadena de bloques",
    missingPrice: () => "Precio de mercado faltante",
    negativeBalance: () => "Falta historial de compras",
    suspectedAirdropTransaction: () => "Sospecha de transacción de airdrop",
    uncategorised: () => "Sin categorizar",
    unknownInOut: () => "Sospecha de importación desaparecida",
    zeroCostBuy: () => "Compra sin costo",
  },
  warnings: {
    missingBlockchain: () =>
      "No pudimos determinar la cadena de bloques para esta transacción. Esto suele deberse a que la dirección de la billetera es la misma en varias cadenas de bloques. \n\n Asignar la cadena de bloques para resolver esta advertencia.",
    missingPrice: (d) =>
      "No tenemos un precio de mercado para el token " +
      d.symbol +
      " en esta fecha, por lo que se asumió un precio de cero. Navegue hasta el gráfico de precios de este token y verifique cuál era el precio en esta fecha específica, luego edite la transacción para actualizar el precio a este valor. Esto debería resolver la advertencia.",
    negativeBalance: (d) =>
      select(d.balanceRemaining, {
        true:
          " Esta transacción provocó que su saldo general de " +
          d.symbol +
          " fuera negativo, lo que dio como resultado un saldo restante de " +
          d.balanceTotal +
          " " +
          d.symbol +
          " . \n\n ¿Has comprado " +
          d.symbol +
          " en un exchange o en otra fuente? Es probable que esta sea la pieza que falta. Asegúrate de importar los datos de transacciones de todas las fuentes en las que hayas comprado " +
          d.symbol +
          " y esto debería resolver el problema. ",
        other: "",
      }),
    uncategorised: (d) =>
      "Esta transacción está utilizando la " +
      select(d.trade, {
        in: " categoría predeterminada 'Entrada desconocida', que puede generar saldos inexactos ",
        out: " categoría predeterminada 'Salida desconocida', que puede desencadenar un evento de ganancia de capital ",
        other: " categoría 'Desconocida' que debe cambiarse ",
      }) +
      " . Si importa todos sus intercambios y billeteras, nuestro algoritmo debería poder categorizar esto automáticamente. De lo contrario, es posible que falten datos, en cuyo caso puede seleccionar manualmente una categoría en el menú desplegable.",
    unknownInOut: () =>
      "¿Transfirió activos entre sus propias billeteras? Es posible que haya olvidado importar los datos de una de estas billeteras. \n\n Si se trata de una transacción entre usted y una billetera externa, puede seleccionar la categoría que mejor describa esta transacción en el menú desplegable de la izquierda (por ejemplo, regalo recibido, recompensas de staking, airdrop, etc.). \n\n También puedes nombrar esta dirección para resolver futuras advertencias de importaciones sospechosas de faltar en esta dirección.",
    zeroCostBuy: () =>
      "No existe ningún saldo para cubrir esta transacción. Como resultado, se asumió una compra de costo cero (lo que da como resultado una base de costo de cero). Esto puede provocar que las ganancias calculadas sean mayores de lo que deberían. Corrija las advertencias previas de historial de compras faltantes para resolver este problema.",
  },
  warningsDropdown: {
    missingImportData: {
      description: () =>
        "Sin todas las fuentes de datos no podemos reflejar con precisión su saldo general.",
      title: () => "Faltan datos de importación",
    },
  },
  was: () => "era",
  watchVideo: () => "Ver video",
  worksWith: () => "Funciona con",
  xero: {
    accountMapping: {
      accounts: {
        asset: {
          title: () => "Cuenta de activos",
          tooltip: () =>
            "Cuenta que representa el valor de los activos en la cartera del usuario",
        },
        cash: {
          title: () => "Cuenta de efectivo",
          tooltip: () =>
            "Cuenta que representa los fondos en efectivo. Si vende criptomonedas, representaremos los ingresos en efectivo aquí y viceversa cuando compre criptomonedas. Las transacciones entre criptomonedas no tendrán ningún impacto en esta cuenta.",
        },
        expense: {
          title: () => "Cuenta de gastos",
          tooltip: () => "Cuenta que representa gastos en criptomonedas",
        },
        fee: {
          title: () => "Cuenta de honorarios",
          tooltip: () =>
            "Cuenta que representa el importe pagado en concepto de honorarios",
        },
        income: {
          title: () => "Cuenta de ingresos",
          tooltip: () =>
            "Cuenta que representa ingresos provenientes de criptomonedas",
        },
        loan: {
          title: () => "Cuenta de préstamo",
          tooltip: () =>
            "Cuenta que representa préstamos y reembolsos de criptomonedas",
        },
        loss: {
          title: () => "Cuenta de pérdidas",
          tooltip: () =>
            "Cuenta que representa la cantidad de criptomonedas perdidas",
        },
        "realised-gain": {
          title: () => "Cuenta de ganancia realizada",
          tooltip: () =>
            "Cuenta que representa las ganancias que se han obtenido por la enajenación de criptoactivos",
        },
        "realised-loss": {
          title: () => "Cuenta de pérdidas realizadas",
          tooltip: () =>
            "Cuenta que representa las pérdidas que se han producido por la enajenación de criptoactivos",
        },
        "zero-cost-buy": {
          title: () => "Falta cuenta de historial de compras",
          tooltip: () =>
            "Cuando se compra u obtiene un activo y no se realiza ningún pago por él, consideramos que la base de costo del activo es el valor de mercado y esta cuenta se utiliza para equilibrar el otro lado del diario.",
        },
      },
      lastSynced: (d) => "Última sincronización " + d.date,
      refreshInstructions: () =>
        "Si ha creado una nueva cuenta en Xero y no la ve, actualice la lista.",
      syncError: () => "No pudimos sincronizar los datos.",
      syncInstructions: () =>
        "Para comenzar a sincronizar, debe elegir a qué cuentas se asignarán los datos. Puede actualizar las cuentas en cualquier momento. La sincronización se puede iniciar cuando se configura la asignación.",
      title: () => "Mapeo de cuentas",
    },
    buttons: {
      disconnect: () => "Desconectarse de Xero",
      refreshList: {
        completed: () => "Lista de cuentas de Xero actualizada",
        done: () => "Actualizar lista de cuentas",
        pending: () => "Lista de actualización",
        tooltip: {
          done: () => "Actualizar cuentas disponibles",
          pending: () => "Esto puede tomar algún tiempo",
        },
      },
      syncAccounts: {
        done: () => "Sincronizar cuentas",
        pending: () => "Sincronización de cuentas",
        tooltip: {
          disabled: () =>
            "Asegúrese de que cada cuenta esté seleccionada para habilitar la sincronización",
          done: () => "Sincronizar cuentas con Xero",
          pending: () => "Esto puede tomar algún tiempo",
        },
      },
    },
    connect: () => "Conectarse a Xero",
    errors: {
      disconnecting: () => "Algo salió mal al desconectar Xero.",
      fetchingAccounts: () =>
        "Algo salió mal al obtener la lista de cuentas de Xero.",
      fetchingSettings: () =>
        "Algo salió mal al obtener la configuración de Xero.",
      fetchingTenants: () =>
        "Algo salió mal al obtener la lista de inquilinos de Xero.",
      updatingAccounts: () =>
        "Algo salió mal al actualizar las cuentas de Xero.",
      updatingTenant: () =>
        "Algo salió mal al actualizar el inquilino de Xero.",
    },
    integrationName: () => "Xero",
    integrationShortName: () => "Xero",
    newIntegration: {
      connected: () => " Conectado",
      connectedAt: (d) => "Última conexión " + d.date,
      description: () =>
        "Resuma las actividades comerciales diarias en registros contables dentro de Xero. Esto puede ayudarlo a realizar un seguimiento de sus finanzas y comprender las fuentes de ingresos, pérdidas y gastos. Las asignaciones de cuentas se pueden personalizar para que coincidan con la forma en que administra su contabilidad.",
      disconnectConfirmation: () =>
        "¡Advertencia! ¿Está seguro de que desea desconectar su cuenta de Xero? Esta acción no se puede deshacer; deberá volver a seleccionar sus cuentas en el futuro. Los diarios sincronizados permanecerán en Xero.",
      disconnected: () => "Desconectado",
      status: () => "Estado: ",
      title: () => "Conectar cuenta de Xero",
    },
    reportFooter: {
      settingsLink: () => "Ver configuración para más información",
      subTitle: () =>
        "Conecte la calculadora de impuestos de criptomonedas con Xero",
      title: () => "Integración con Xero",
    },
    rollup: {
      day: () => "Día",
      description: {
        day: () =>
          "Todas las transacciones de un día calendario determinado se agruparán en un único diario.",
        month: () =>
          "Todas las transacciones de un mes calendario determinado se agruparán en un único diario.",
        none: () =>
          "Todas las transacciones se exportarán como diarios individuales.",
      },
      month: () => "Mes",
      none: () => "Sin rollup",
    },
    syncDialog: {
      confirmation: () =>
        "¿Está seguro de que desea sincronizar? Asegúrese de que sus datos sean correctos. Las modificaciones que se realicen en las transacciones que ya se hayan sincronizado no se reflejarán en Xero.",
      error: () => "¡Oh, no! Parece que algo salió mal...",
      syncing: () =>
        "Estamos sincronizando datos en este momento, esto puede llevar algún tiempo...",
    },
    tenantList: {
      ensureCorrectTenant: () =>
        "Asegúrese de que se haya seleccionado la cuenta correcta para la fuente de datos.",
      exportJournals: () => "Revistas de exportación",
      listPlaceholder: () => "Seleccionar fuente de datos",
      listTitle: () => "Cuenta Xero",
    },
  },
  yes: () => "Sí",
};
