import { FilterOperators } from "~/types/enums";
import { type AndFilter, type FilterQuery } from "~/types/index";

/**
 * A simple filter needs to be an "AND"
 * and not contain any nested groups
 * @param filter A filter query;
 * @returns boolean
 */
export function isSimpleFilterQuery(
  filter: FilterQuery | undefined,
): filter is AndFilter {
  if (!filter) {
    return true;
  }

  return (
    filter.type === FilterOperators.And &&
    Boolean(
      !filter.rules.find(
        (r) =>
          r.type === FilterOperators.Or ||
          r.type === FilterOperators.Not ||
          r.type === FilterOperators.And,
      ),
    )
  );
}
