import { Blockchain } from "@ctc/types";
import WAValidator from "@swyftx/api-crypto-address-validator/dist/wallet-address-validator.min";
import blockies from "blockies-ts";
import Identicon from "identicon.js";
import padStart from "lodash/padStart";
import uniqWith from "lodash/uniqWith";
import { type UrlUpdateType } from "use-query-params";

import { MAX_ADDR_LENGTH, MAX_EXCHANGE_LENGTH } from "~/constants/constants";
import { LocalStorageKey } from "~/constants/enums";
import { LocalStorageUserKeyGenerator } from "~/hooks/useLocalStorage";
import { geIdAndBlockchain, middleTrim } from "~/lib/index";
import { toWalletExchangeId } from "~/lib/toWalletExchangeId";
import { useIsAddressLike } from "~/redux/imports";
import { isValidCardanoWalletAddress } from "~/services/wallet/isValidWalletAddress";
import { useEntityLookup } from "~/state/entities";
import {
  BlockChairCrypto,
  BTCAltNetworks,
  ImportType,
  TaxDivision,
} from "~/types/enums";
import {
  type ActionRow,
  type CurrencyIdentifier,
  type EVMBlockchain,
  isEVMBlockchain,
  type MintscanBlockchain,
  PolkadotChainsConfig,
  type PolkadotCompatibleChain,
  type TransactionDetails,
} from "~/types/index";

export function isEthHash(hash: string): boolean {
  return hash?.startsWith("0x");
}

export function isWallet(importType: ImportType): boolean {
  return [
    ImportType.Wallet,
    ImportType.SoftWallet,
    ImportType.WalletCSV,
    ImportType.WalletAPI,
  ].includes(importType);
}

const BlockchairName = {
  [BlockChairCrypto.BTC]: "bitcoin",
  [BlockChairCrypto.BCH]: "bitcoin-cash",
  [BlockChairCrypto.BCHA]: "bitcoin-abc",
  [BlockChairCrypto.BSV]: "bitcoin-sv",
  [BlockChairCrypto.XRP]: "ripple",
  [BlockChairCrypto.ADA]: "cardano",
  [BlockChairCrypto.LTC]: "litecoin",
  [BlockChairCrypto.EOS]: "eos",
  [BlockChairCrypto.XTZ]: "tezos",
  [BlockChairCrypto.XLM]: "stellar",
  [BlockChairCrypto.XRM]: "monero",
  [BlockChairCrypto.DASH]: "dash",
  [BlockChairCrypto.ZEC]: "zcash",
  [BlockChairCrypto.DOGE]: "dogecoin",
  [BlockChairCrypto.XIN]: "mixin",
  [BlockChairCrypto.GRS]: "groestlcoin",
};

const EVMExplorerBaseURL: Record<EVMBlockchain, string> = {
  [Blockchain.ETH]: "https://etherscan.io",
  [Blockchain.Abstract]: "https://abscan.org",
  [Blockchain.ARB]: "https://arbiscan.io",
  [Blockchain.ArbitrumNova]: "https://nova.arbiscan.io",
  [Blockchain.Aurora]: "https://explorer.mainnet.aurora.dev",
  [Blockchain.Avalanche]: "https://subnets.avax.network/c-chain",
  [Blockchain.Base]: "https://basescan.org",
  [Blockchain.Berachain]: "https://berascan.com",
  [Blockchain.BitTorrentChain]: "https://bttcscan.com",
  [Blockchain.Blast]: "https://blastscan.io",
  [Blockchain.Boba]: "https://bobascan.com",
  [Blockchain.BSC]: "https://bscscan.com",
  [Blockchain.Canto]: "https://evm.explorer.canto.io",
  [Blockchain.Celo]: "https://explorer.celo.org/mainnet",
  [Blockchain.CLVChain]: "https://clvscan.com",
  [Blockchain.Cronos]: "https://cronoscan.com",
  [Blockchain.EthereumClassic]: "https://etc.blockscout.com",
  [Blockchain.Fantom]: "https://explorer.fantom.network",
  [Blockchain.Flare]: "https://flare-explorer.flare.network",
  [Blockchain.Immutable]: "https://explorer.immutable.com",
  [Blockchain.Kava]: "https://explorer.kava.io",
  [Blockchain.Linea]: "https://lineascan.build",
  [Blockchain.MantaPacific]: "https://pacific-explorer.manta.network",
  [Blockchain.Mantle]: "https://explorer.mantle.xyz",
  [Blockchain.Metis]: "https://andromeda-explorer.metis.io",
  [Blockchain.Mode]: "https://modescan.io",
  [Blockchain.Moonbeam]: "https://moonbeam.moonscan.io",
  [Blockchain.Moonriver]: "https://moonriver.moonscan.io",
  [Blockchain.OPT]: "https://optimistic.etherscan.io",
  [Blockchain.Polygon]: "https://polygonscan.com",
  [Blockchain.PolygonZkEvm]: "https://zkevm.polygonscan.com",
  [Blockchain.PulseChain]: "https://scan.pulsechainfoundation.org/#",
  [Blockchain.Scroll]: "https://scrollscan.com",
  [Blockchain.Sonic]: "https://sonicscan.org",
  [Blockchain.Taiko]: "https://taikoscan.io",
  [Blockchain.Unichain]: "https://uniscan.xyz",
  [Blockchain.Velas]: "https://evmexplorer.velas.com",
  [Blockchain.Xdai]: "https://gnosisscan.io",
  [Blockchain.ZetaChain]: "https://zetachain.blockscout.com",
  [Blockchain.ZkSync]: "https://era.zksync.network",
  [Blockchain.Zora]: "https://explorer.zora.energy",
};

const BlockchainWithHyphenId = [Blockchain.Hedera];

/**
 * Configuration for blockchain explorers
 */
type BlockchainExplorerConfigType = {
  /**
   * Function to generate explorer transaction URL
   * @param formattedTxId - The formatted transaction ID (usually without any delimiters)
   * @param originalTxId - The original, unmodified transaction ID as it appears in the source
   */
  getTxUrl?: (formattedTxId: string, originalTxId: string) => string | null;

  /** Function to generate explorer address URL */
  getAddressUrl?: (address: string) => string | null;

  /** Whether this blockchain has a supported explorer */
  hasExplorer: boolean;
};

/**
 * Helper function to create explorer config for Blockchair-supported blockchains
 * Generates transaction and address URLs based on the cryptocurrency symbol
 * @param currency - The cryptocurrency symbol supported by Blockchair
 * @returns Configuration for the Blockchair explorer
 */
const createBlockchairExplorerConfig = (
  currency: BlockChairCrypto,
): BlockchainExplorerConfigType => {
  return {
    getTxUrl: (formattedTxId: string) => {
      // Special case for XLM (Stellar)
      if (currency === BlockChairCrypto.XLM) {
        return `https://stellar.expert/explorer/public/tx/${formattedTxId}`;
      }

      // Special case for XRP (Ripple)
      if (currency === BlockChairCrypto.XRP) {
        return `https://xrpscan.com/tx/${formattedTxId}`;
      }

      return `https://blockchair.com/${BlockchairName[currency]}/transaction/${formattedTxId}`;
    },
    getAddressUrl: (address: string) => {
      // Special case for XLM (Stellar)
      if (currency === BlockChairCrypto.XLM) {
        return `https://stellar.expert/explorer/public/account/${address}`;
      }

      // Special case for XRP (Ripple)
      if (currency === BlockChairCrypto.XRP) {
        return `https://xrpscan.com/account/${address}`;
      }

      return `https://blockchair.com/${BlockchairName[currency]}/address/${address}`;
    },
    hasExplorer: true,
  };
};

/**
 * Helper function to create explorer config for EVM-compatible chains
 * Generates transaction and address URLs based on the blockchain's explorer base URL
 * @param blockchain - The EVM-compatible blockchain
 * @returns Configuration for the blockchain explorer
 */
const createEVMExplorerConfig = (
  blockchain: EVMBlockchain,
  txPathSegment = "tx",
): BlockchainExplorerConfigType => {
  return {
    getTxUrl: (formattedTxId: string) => {
      if (isEthHash(formattedTxId)) {
        return `${EVMExplorerBaseURL[blockchain]}/${txPathSegment}/${formattedTxId}`;
      }
      return null;
    },
    getAddressUrl: (address: string) => {
      return `${EVMExplorerBaseURL[blockchain]}/address/${address}`;
    },
    hasExplorer: true,
  };
};

/**
 * Helper function to create explorer config for Mintscan-compatible chains
 * Generates transaction and address URLs based on the blockchain's configuration
 * @param blockchain - The Mintscan-compatible blockchain
 * @returns Configuration for the blockchain explorer
 */
const createMintscanExplorerConfig = (
  blockchain: MintscanBlockchain,
): BlockchainExplorerConfigType => {
  return {
    getTxUrl: (formattedTxId: string) =>
      `https://mintscan.io/${blockchain.toLowerCase()}/tx/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://mintscan.io/${blockchain.toLowerCase()}/address/${address}`,
    hasExplorer: true,
  };
};

/**
 * Helper function to create explorer config for Polkadot chains
 * Generates transaction and address URLs based on the blockchain's configuration
 * @param blockchain - The Polkadot-compatible blockchain
 * @returns Configuration for the blockchain explorer
 */
const createPolkadotExplorerConfig = (
  blockchain: PolkadotCompatibleChain,
): BlockchainExplorerConfigType => {
  return {
    getTxUrl: (formattedTxId: string) => {
      const config = PolkadotChainsConfig[blockchain];
      if (config.explorer === "polkaholic") {
        return `https://polkaholic.com/tx/${formattedTxId}`;
      }
      return `${config.explorerURL}/extrinsic/${formattedTxId}`;
    },
    getAddressUrl: (address: string) => {
      const config = PolkadotChainsConfig[blockchain];
      if (config.explorer === "polkaholic") {
        return `https://polkaholic.io/account/${address}`;
      }
      return `${config.explorerURL}/account/${address}`;
    },
    hasExplorer: true,
  };
};

export const MultiAddressWallets = [
  Blockchain.Cardano,
  Blockchain.Solana,
  Blockchain.Avalanche,
  Blockchain.BTC,
  Blockchain.LTC,
  Blockchain.DOGE,
  Blockchain.DASH,
  Blockchain.ZEC,
];

const customTransactionLinks: Record<string, (id: string) => string> = {
  GAS: (id: string) => `https://neoscan.io/transaction/${id}`,
  NEO: (id: string) => `https://neoscan.io/transaction/${id}`,
};

export function syncCountQueryParamWithLocalStorage(
  uuid: string | null | undefined,
  countQuery: number | null | undefined,
  setCountQuery: (
    newValue: number | null | undefined,
    updateType?: UrlUpdateType | undefined,
  ) => void,
) {
  if (!countQuery || !uuid) return;

  localStorage.setItem(
    LocalStorageUserKeyGenerator(uuid, LocalStorageKey.RowsPerPage),
    `${countQuery}`,
  );

  const setting = localStorage.getItem(
    LocalStorageUserKeyGenerator(uuid, LocalStorageKey.RowsPerPage),
  );

  const shouldUpdate = Number(setting) !== countQuery;
  if (shouldUpdate) setCountQuery(Number(setting));
}

export const BlockchainExplorerConfig: Record<
  Blockchain,
  BlockchainExplorerConfigType
> = {
  // Custom explorers per blockchain
  [Blockchain.Aptos]: {
    getTxUrl: (formattedTxId: string) =>
      `https://aptoscan.com/transaction/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://aptoscan.com/account/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Starknet]: {
    getTxUrl: (formattedTxId: string) =>
      `https://voyager.online/tx/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://voyager.online/contract/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Hedera]: {
    getTxUrl: (_: string, id: string) =>
      `https://hashscan.io/mainnet/transaction/${id}`,
    getAddressUrl: (address: string) =>
      `https://hashscan.io/mainnet/account/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Kaspa]: {
    getTxUrl: (formattedTxId: string) =>
      `https://kas.fyi/transaction/${formattedTxId}`,
    getAddressUrl: (address: string) => `https://kas.fyi/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Hyperliquid]: {
    getTxUrl: (formattedTxId: string) =>
      `https://app.hyperliquid.xyz/explorer/tx/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://app.hyperliquid.xyz/explorer/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Thorchain]: {
    getTxUrl: (formattedTxId: string) =>
      `https://runescan.io/tx/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://runescan.io/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Stacks]: {
    getTxUrl: (formattedTxId: string) =>
      `https://explorer.hiro.so/txid/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://explorer.hiro.so/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.TON]: {
    getTxUrl: (formattedTxId: string) =>
      `https://tonviewer.com/transaction/${formattedTxId}`,
    getAddressUrl: (address: string) => `https://tonviewer.com/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Near]: {
    getTxUrl: (formattedTxId: string) =>
      `https://nearblocks.io/txns/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://nearblocks.io/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Sui]: {
    getTxUrl: (_: string, id: string) => `https://suiscan.xyz/mainnet/tx/${id}`,
    getAddressUrl: (address: string) =>
      `https://suiscan.xyz/mainnet/account/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Theta]: {
    getTxUrl: (formattedTxId: string) =>
      isEthHash(formattedTxId)
        ? `https://explorer.thetatoken.org/txs/${formattedTxId}`
        : null,
    getAddressUrl: (address: string) =>
      `https://explorer.thetatoken.org/account/${address}`,
    hasExplorer: true,
  },
  [Blockchain.BinanceChain]: {
    getTxUrl: (formattedTxId: string) => {
      if (isEthHash(formattedTxId)) {
        return `https://bscscan.com/tx/${formattedTxId}`;
      }
      if (formattedTxId.length === 64) {
        return `https://explorer.binance.org/tx/${formattedTxId}`;
      }
      return null;
    },
    getAddressUrl: (address: string) => {
      if (isEthHash(address)) {
        return `https://bscscan.com/address/${address}`;
      }
      if (address.startsWith("bnb")) {
        return `https://explorer.binance.org/address/${address}`;
      }
      return null;
    },
    hasExplorer: true,
  },
  [Blockchain.Vechain]: {
    getTxUrl: (formattedTxId: string) =>
      isEthHash(formattedTxId)
        ? `https://explore.vechain.org/transactions/${formattedTxId}`
        : null,
    getAddressUrl: (address: string) =>
      isEthHash(address)
        ? `https://explore.vechain.org/accounts/${address}`
        : null,
    hasExplorer: true,
  },
  [Blockchain.Nolus]: {
    getTxUrl: (formattedTxId: string) =>
      `https://nolus.explorers.guru/transaction/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://nolus.explorers.guru/account/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Algorand]: {
    getTxUrl: (formattedTxId: string) => {
      // if a txId has symbols it needs to be UTF8 encoded and directed to the tx/group endpoint
      const encodedId = encodeURIComponent(formattedTxId);
      if (encodedId === formattedTxId) {
        return `https://allo.info/tx/${encodedId}`;
      }
      return `https://allo.info/tx/group/${encodedId}`;
    },
    getAddressUrl: (address: string) => `https://allo.info/account/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Agoric]: {
    getTxUrl: (formattedTxId: string) =>
      `https://atomscan.com/agoric/transactions/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://atomscan.com/agoric/accounts/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Iotex]: {
    getTxUrl: (formattedTxId: string) =>
      `https://iotexscout.io/tx/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://iotexscout.io/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Solana]: {
    getTxUrl: (formattedTxId: string) =>
      `https://solscan.io/tx/${formattedTxId}`,
    getAddressUrl: (address: string) => `https://solscan.io/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.SolanaV2]: {
    getTxUrl: (formattedTxId: string) =>
      `https://solscan.io/tx/${formattedTxId}`,
    getAddressUrl: (address: string) => `https://solscan.io/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.TerraClassic]: {
    getTxUrl: (formattedTxId: string) =>
      `https://finder.terra.money/classic/tx/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://finder.terra.money/classic/account/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Terra2]: {
    getTxUrl: (formattedTxId: string) =>
      `https://finder.terra.money/mainnet/tx/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://finder.terra.money/mainnet/account/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Tron]: {
    getTxUrl: (formattedTxId: string) =>
      `https://tronscan.org/#/transaction/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://tronscan.org/#/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Cardano]: {
    getTxUrl: (formattedTxId: string) => {
      if (formattedTxId.length === 64) {
        return `https://cardanoscan.io/transaction/${formattedTxId}`;
      }
      return null;
    },
    getAddressUrl: (address: string) => {
      if (isValidCardanoWalletAddress(address)) {
        if (address.startsWith("addr1")) {
          return `https://cardanoscan.io/address/${address}`;
        }
        return `https://cardanoscan.io/stakeKey/${address}`;
      }
      return null;
    },
    hasExplorer: true,
  },

  // Polkadot chains
  [Blockchain.Acala]: createPolkadotExplorerConfig(Blockchain.Acala),
  [Blockchain.Ajuna]: createPolkadotExplorerConfig(Blockchain.Ajuna),
  [Blockchain.Altair]: createPolkadotExplorerConfig(Blockchain.Altair),
  [Blockchain.Amplitude]: createPolkadotExplorerConfig(Blockchain.Amplitude),
  [Blockchain.Aventus]: createPolkadotExplorerConfig(Blockchain.Aventus),
  [Blockchain.Bajun]: createPolkadotExplorerConfig(Blockchain.Bajun),
  [Blockchain.Basilisk]: createPolkadotExplorerConfig(Blockchain.Basilisk),
  [Blockchain.BifrostDot]: createPolkadotExplorerConfig(Blockchain.BifrostDot),
  [Blockchain.BifrostKsm]: createPolkadotExplorerConfig(Blockchain.BifrostKsm),
  [Blockchain.Bitgreen]: createPolkadotExplorerConfig(Blockchain.Bitgreen),
  [Blockchain.Bitcountrypioneer]: createPolkadotExplorerConfig(
    Blockchain.Bitcountrypioneer,
  ),
  [Blockchain.Bridgehub]: createPolkadotExplorerConfig(Blockchain.Bridgehub),
  [Blockchain.Calamari]: createPolkadotExplorerConfig(Blockchain.Calamari),
  [Blockchain.Centrifuge]: createPolkadotExplorerConfig(Blockchain.Centrifuge),
  [Blockchain.Collectives]: createPolkadotExplorerConfig(
    Blockchain.Collectives,
  ),
  [Blockchain.Composable]: createPolkadotExplorerConfig(Blockchain.Composable),
  [Blockchain.Crab]: createPolkadotExplorerConfig(Blockchain.Crab),
  [Blockchain.Crust]: createPolkadotExplorerConfig(Blockchain.Crust),
  [Blockchain.Daoipci]: createPolkadotExplorerConfig(Blockchain.Daoipci),
  [Blockchain.Darwinia]: createPolkadotExplorerConfig(Blockchain.Darwinia),
  [Blockchain.Encointer]: createPolkadotExplorerConfig(Blockchain.Encointer),
  [Blockchain.Equilibrium]: createPolkadotExplorerConfig(
    Blockchain.Equilibrium,
  ),
  [Blockchain.Frequency]: createPolkadotExplorerConfig(Blockchain.Frequency),
  [Blockchain.Genshiro]: createPolkadotExplorerConfig(Blockchain.Genshiro),
  [Blockchain.Gm]: createPolkadotExplorerConfig(Blockchain.Gm),
  [Blockchain.Hashed]: createPolkadotExplorerConfig(Blockchain.Hashed),
  [Blockchain.Hydradx]: createPolkadotExplorerConfig(Blockchain.Hydradx),
  [Blockchain.Imbue]: createPolkadotExplorerConfig(Blockchain.Imbue),
  [Blockchain.Integritee]: createPolkadotExplorerConfig(Blockchain.Integritee),
  [Blockchain.IntegriteeShell]: createPolkadotExplorerConfig(
    Blockchain.IntegriteeShell,
  ),
  [Blockchain.Interlay]: createPolkadotExplorerConfig(Blockchain.Interlay),
  [Blockchain.Kabocha]: createPolkadotExplorerConfig(Blockchain.Kabocha),
  [Blockchain.Kapex]: createPolkadotExplorerConfig(Blockchain.Kapex),
  [Blockchain.Karura]: createPolkadotExplorerConfig(Blockchain.Karura),
  [Blockchain.Khala]: createPolkadotExplorerConfig(Blockchain.Khala),
  [Blockchain.Kilt]: createPolkadotExplorerConfig(Blockchain.Kilt),
  [Blockchain.Kintsugi]: createPolkadotExplorerConfig(Blockchain.Kintsugi),
  [Blockchain.Krest]: createPolkadotExplorerConfig(Blockchain.Krest),
  [Blockchain.Kusama]: createPolkadotExplorerConfig(Blockchain.Kusama),
  [Blockchain.KusamaShiden]: createPolkadotExplorerConfig(
    Blockchain.KusamaShiden,
  ),
  [Blockchain.Kylin]: createPolkadotExplorerConfig(Blockchain.Kylin),
  [Blockchain.Litentry]: createPolkadotExplorerConfig(Blockchain.Litentry),
  [Blockchain.Litmus]: createPolkadotExplorerConfig(Blockchain.Litmus),
  [Blockchain.Manta]: createPolkadotExplorerConfig(Blockchain.Manta),
  [Blockchain.Mangatax]: createPolkadotExplorerConfig(Blockchain.Mangatax),
  [Blockchain.Nodle]: createPolkadotExplorerConfig(Blockchain.Nodle),
  [Blockchain.Origintrail]: createPolkadotExplorerConfig(
    Blockchain.Origintrail,
  ),
  [Blockchain.Parallel]: createPolkadotExplorerConfig(Blockchain.Parallel),
  [Blockchain.ParallelHeiko]: createPolkadotExplorerConfig(
    Blockchain.ParallelHeiko,
  ),
  [Blockchain.Pendulum]: createPolkadotExplorerConfig(Blockchain.Pendulum),
  [Blockchain.Phala]: createPolkadotExplorerConfig(Blockchain.Phala),
  [Blockchain.Picasso]: createPolkadotExplorerConfig(Blockchain.Picasso),
  [Blockchain.Polkadot]: createPolkadotExplorerConfig(Blockchain.Polkadot),
  [Blockchain.PolkadotAstar]: createPolkadotExplorerConfig(
    Blockchain.PolkadotAstar,
  ),
  [Blockchain.PolkadotClover]: createPolkadotExplorerConfig(
    Blockchain.PolkadotClover,
  ),
  [Blockchain.Quartz]: createPolkadotExplorerConfig(Blockchain.Quartz),
  [Blockchain.Robonomics]: createPolkadotExplorerConfig(Blockchain.Robonomics),
  [Blockchain.Shadow]: createPolkadotExplorerConfig(Blockchain.Shadow),
  [Blockchain.Shibuya]: createPolkadotExplorerConfig(Blockchain.Shibuya),
  [Blockchain.Sora]: createPolkadotExplorerConfig(Blockchain.Sora),
  [Blockchain.Statemine]: createPolkadotExplorerConfig(Blockchain.Statemine),
  [Blockchain.Statemint]: createPolkadotExplorerConfig(Blockchain.Statemint),
  [Blockchain.Subsocialx]: createPolkadotExplorerConfig(Blockchain.Subsocialx),
  [Blockchain.Subzero]: createPolkadotExplorerConfig(Blockchain.Subzero),
  [Blockchain.T3rn]: createPolkadotExplorerConfig(Blockchain.T3rn),
  [Blockchain.Tinkernet]: createPolkadotExplorerConfig(Blockchain.Tinkernet),
  [Blockchain.Turing]: createPolkadotExplorerConfig(Blockchain.Turing),
  [Blockchain.Unique]: createPolkadotExplorerConfig(Blockchain.Unique),
  [Blockchain.Zeitgeist]: createPolkadotExplorerConfig(Blockchain.Zeitgeist),

  // EVM Blockchains
  // All EVMExplorerBaseURL entries should be covered here
  [Blockchain.ETH]: createEVMExplorerConfig(Blockchain.ETH),
  [Blockchain.ARB]: createEVMExplorerConfig(Blockchain.ARB),
  [Blockchain.Abstract]: createEVMExplorerConfig(Blockchain.Abstract),
  [Blockchain.ArbitrumNova]: createEVMExplorerConfig(Blockchain.ArbitrumNova),
  [Blockchain.Aurora]: createEVMExplorerConfig(Blockchain.Aurora),
  [Blockchain.Avalanche]: createEVMExplorerConfig(Blockchain.Avalanche),
  [Blockchain.BSC]: createEVMExplorerConfig(Blockchain.BSC),
  [Blockchain.Base]: createEVMExplorerConfig(Blockchain.Base),
  [Blockchain.Berachain]: createEVMExplorerConfig(Blockchain.Berachain),
  [Blockchain.BitTorrentChain]: createEVMExplorerConfig(
    Blockchain.BitTorrentChain,
  ),
  [Blockchain.Blast]: createEVMExplorerConfig(Blockchain.Blast),
  [Blockchain.Boba]: createEVMExplorerConfig(Blockchain.Boba),
  [Blockchain.CLVChain]: createEVMExplorerConfig(Blockchain.CLVChain),
  [Blockchain.Canto]: createEVMExplorerConfig(Blockchain.Canto),
  [Blockchain.Celo]: createEVMExplorerConfig(Blockchain.Celo),
  [Blockchain.Cronos]: createEVMExplorerConfig(Blockchain.Cronos),
  [Blockchain.EthereumClassic]: createEVMExplorerConfig(
    Blockchain.EthereumClassic,
  ),
  [Blockchain.Fantom]: createEVMExplorerConfig(
    Blockchain.Fantom,
    "transactions",
  ),
  [Blockchain.Flare]: createEVMExplorerConfig(Blockchain.Flare),
  [Blockchain.Immutable]: createEVMExplorerConfig(Blockchain.Immutable),
  [Blockchain.Kava]: createEVMExplorerConfig(Blockchain.Kava),
  [Blockchain.Linea]: createEVMExplorerConfig(Blockchain.Linea),
  [Blockchain.MantaPacific]: createEVMExplorerConfig(Blockchain.MantaPacific),
  [Blockchain.Mantle]: createEVMExplorerConfig(Blockchain.Mantle),
  [Blockchain.Metis]: createEVMExplorerConfig(Blockchain.Metis),
  [Blockchain.Mode]: createEVMExplorerConfig(Blockchain.Mode),
  [Blockchain.Moonbeam]: createEVMExplorerConfig(Blockchain.Moonbeam),
  [Blockchain.Moonriver]: createEVMExplorerConfig(Blockchain.Moonriver),
  [Blockchain.OPT]: createEVMExplorerConfig(Blockchain.OPT),
  [Blockchain.Polygon]: createEVMExplorerConfig(Blockchain.Polygon),
  [Blockchain.PolygonZkEvm]: createEVMExplorerConfig(Blockchain.PolygonZkEvm),
  [Blockchain.PulseChain]: createEVMExplorerConfig(Blockchain.PulseChain),
  [Blockchain.Scroll]: createEVMExplorerConfig(Blockchain.Scroll),
  [Blockchain.Sonic]: createEVMExplorerConfig(Blockchain.Sonic),
  [Blockchain.Taiko]: createEVMExplorerConfig(Blockchain.Taiko),
  [Blockchain.Unichain]: createEVMExplorerConfig(Blockchain.Unichain),
  [Blockchain.Velas]: createEVMExplorerConfig(Blockchain.Velas),
  [Blockchain.Xdai]: createEVMExplorerConfig(Blockchain.Xdai),
  [Blockchain.ZetaChain]: createEVMExplorerConfig(Blockchain.ZetaChain),
  [Blockchain.ZkSync]: createEVMExplorerConfig(Blockchain.ZkSync),
  [Blockchain.Zora]: createEVMExplorerConfig(Blockchain.Zora),

  // Mintscan Blockchains
  [Blockchain.Akash]: createMintscanExplorerConfig(Blockchain.Akash),
  [Blockchain.Archway]: createMintscanExplorerConfig(Blockchain.Archway),
  [Blockchain.BitSong]: createMintscanExplorerConfig(Blockchain.BitSong),
  [Blockchain.Celestia]: createMintscanExplorerConfig(Blockchain.Celestia),
  [Blockchain.Chihuahua]: createMintscanExplorerConfig(Blockchain.Chihuahua),
  [Blockchain.CosmosHub]: {
    // For CosmosHub, the blockchain on mintscan is just cosmos, not cosmoshub
    getTxUrl: (formattedTxId: string) =>
      `https://www.mintscan.io/cosmos/tx/${formattedTxId}`,
    getAddressUrl: (address: string) =>
      `https://www.mintscan.io/cosmos/address/${address}`,
    hasExplorer: true,
  },
  [Blockchain.Dymension]: createMintscanExplorerConfig(Blockchain.Dymension),
  [Blockchain.Evmos]: createMintscanExplorerConfig(Blockchain.Evmos),
  [Blockchain.FetchAi]: createMintscanExplorerConfig(Blockchain.FetchAi),
  [Blockchain.Injective]: createMintscanExplorerConfig(Blockchain.Injective),
  [Blockchain.Juno]: createMintscanExplorerConfig(Blockchain.Juno),
  [Blockchain.Kujira]: createMintscanExplorerConfig(Blockchain.Kujira),
  [Blockchain.Kyve]: createMintscanExplorerConfig(Blockchain.Kyve),
  [Blockchain.Neutron]: createMintscanExplorerConfig(Blockchain.Neutron),
  [Blockchain.Osmosis]: createMintscanExplorerConfig(Blockchain.Osmosis),
  [Blockchain.Regen]: createMintscanExplorerConfig(Blockchain.Regen),
  [Blockchain.Saga]: createMintscanExplorerConfig(Blockchain.Saga),
  [Blockchain.Sentinel]: createMintscanExplorerConfig(Blockchain.Sentinel),
  [Blockchain.Stargaze]: createMintscanExplorerConfig(Blockchain.Stargaze),
  [Blockchain.Sei]: createMintscanExplorerConfig(Blockchain.Sei),

  // Blockchair
  [Blockchain.BTC]: createBlockchairExplorerConfig(BlockChairCrypto.BTC),
  [Blockchain.BCH]: createBlockchairExplorerConfig(BlockChairCrypto.BCH),
  [Blockchain.BSV]: createBlockchairExplorerConfig(BlockChairCrypto.BSV),
  [Blockchain.Ripple]: createBlockchairExplorerConfig(BlockChairCrypto.XRP),
  [Blockchain.LTC]: createBlockchairExplorerConfig(BlockChairCrypto.LTC),
  [Blockchain.Stellar]: createBlockchairExplorerConfig(BlockChairCrypto.XLM),
  [Blockchain.DASH]: createBlockchairExplorerConfig(BlockChairCrypto.DASH),
  [Blockchain.ZEC]: createBlockchairExplorerConfig(BlockChairCrypto.ZEC),
  [Blockchain.DOGE]: createBlockchairExplorerConfig(BlockChairCrypto.DOGE),
  [Blockchain.GRS]: createBlockchairExplorerConfig(BlockChairCrypto.GRS),

  // No defined explorers for these blockchains
  [Blockchain.Dydx4]: { hasExplorer: false },
  [Blockchain.Neo]: { hasExplorer: false },
};

export function getTxIdLink(
  id: string,
  currency: string,
  blockchain: string | undefined,
): string | null {
  if (!blockchain) return null;
  const [formattedId] = geIdAndBlockchain(id); // remove anything after the __ deliminator

  // Check if blockchain is in our config and has an explorer
  if (blockchain in BlockchainExplorerConfig) {
    const config = BlockchainExplorerConfig[blockchain as Blockchain];
    if (config.hasExplorer && config.getTxUrl) {
      return config.getTxUrl(formattedId, id);
    }
  }

  // Handle Dydx4
  if (blockchain === Blockchain.Dydx4) return null;

  // Handle Ethereum-style transaction hashes
  if (isEthHash(formattedId)) {
    if (!blockchain && ["THETA", "TFUEL"].includes(currency)) {
      return `https://explorer.thetatoken.org/txs/${formattedId}`;
    }
    return `https://etherscan.io/tx/${formattedId}`;
  }

  // Handle BTC alt networks
  if (blockchain === BTCAltNetworks.DFI) {
    return `https://defiscan.live/transactions/${formattedId}`;
  }

  // Handle custom transaction links by currency
  if (customTransactionLinks[currency]) {
    return customTransactionLinks[currency](formattedId);
  }

  // Handle BlockChair cryptocurrencies
  if (Object.values(BlockChairCrypto).includes(currency as BlockChairCrypto)) {
    const blockchairConfig = createBlockchairExplorerConfig(
      currency as BlockChairCrypto,
    );
    if (blockchairConfig.getTxUrl) {
      return blockchairConfig.getTxUrl(formattedId, id);
    }
  }

  // Default fallback
  return `https://${currency.toLowerCase()}.tokenview.io/en/tx/${formattedId}`;
}

/**
 * Gets a link to the token page for a particular address
 */
export function getTokenLink(
  address: string,
  currency: string | undefined,
  blockchain: string | undefined,
): string | null {
  if (blockchain === Blockchain.Aptos) {
    const basePath = "https://aptoscan.com/";

    if (address?.includes("::")) {
      return `${basePath}coin/${address}`;
    }

    return `${basePath}fungible-asset/${address}`;
  }

  if (blockchain === Blockchain.Hedera) {
    // this accepts both token id and evm contract address format
    return `https://hashscan.io/mainnet/token/${address}`;
  }

  if (blockchain === Blockchain.Sui) {
    return `https://suiscan.xyz/mainnet/object/${address.split("::")[0]}`;
  }
  if (blockchain === Blockchain.BTC) {
    const isInscriptionId = /^[a-f0-9]{64}i[0-9]+$/.test(address);
    if (isInscriptionId) {
      return `https://ordinals.com/inscription/${address.split("::")[0]}`;
    }
    return getAddressLink(address, currency, blockchain);
  }
  if (isEthHash(address)) {
    if (
      blockchain === Blockchain.Theta ||
      (currency && !blockchain && ["THETA", "TFUEL"].includes(currency))
    ) {
      return `https://explorer.thetatoken.org/token/${address}`;
    }
    if (
      blockchain &&
      [Blockchain.BSC, Blockchain.BinanceChain].includes(
        blockchain as Blockchain,
      )
    ) {
      return `https://bscscan.com/token/${address}`;
    }
    if (blockchain === Blockchain.Vechain) {
      // doesn't seem to have a token page
      return `https://explore.vechain.org/accounts/${address}`;
    }
    if (blockchain && isEVMBlockchain(blockchain)) {
      return `${EVMExplorerBaseURL[blockchain]}/token/${address}`;
    }
    return `https://etherscan.io/token/${address}`;
  }
  // for the other explorers, we default to the address link
  // each of these need to be checked
  return getAddressLink(address, currency, blockchain);
}

export function getAddressLink(
  address: string,
  currency: string | undefined,
  blockchain: string | undefined,
): string | null {
  // Check if blockchain is in our config and has an explorer with address URL
  if (blockchain && blockchain in BlockchainExplorerConfig) {
    const config = BlockchainExplorerConfig[blockchain as Blockchain];
    if (config.hasExplorer && config.getAddressUrl) {
      return config.getAddressUrl(address);
    }
  }

  // Handle Ethereum-style addresses
  if (isEthHash(address)) {
    // Special case for Theta blockchain
    if (currency && !blockchain && ["THETA", "TFUEL"].includes(currency)) {
      return `https://explorer.thetatoken.org/account/${address}`;
    }
    // Default to Ethereum mainnet
    return `https://etherscan.io/address/${address}`;
  }

  // Handle BinanceChain addresses starting with bnb
  if (address.startsWith("bnb")) {
    return `https://explorer.binance.org/address/${address}`;
  }

  // Handle BTC alt networks
  if (blockchain === BTCAltNetworks.DFI) {
    return `https://defiscan.live/address/${address}`;
  }

  // Handle BlockChair cryptocurrencies
  if (currency && isValidAddress(address, currency, blockchain)) {
    if (
      !Object.values(BlockChairCrypto).includes(currency as BlockChairCrypto)
    ) {
      return `https://${currency.toLowerCase()}.tokenview.io/en/address/${address}`;
    }
    // Use the createBlockchairExplorerConfig helper for BlockChair cryptocurrencies
    const blockchairConfig = createBlockchairExplorerConfig(
      currency as BlockChairCrypto,
    );
    if (blockchairConfig.getAddressUrl) {
      return blockchairConfig.getAddressUrl(address);
    }
  }
  return null;
}

export function isValidAddress(
  address: string,
  currency: string,
  blockchain?: string,
): boolean {
  if (
    blockchain &&
    [
      Blockchain.Neo,
      Blockchain.BinanceChain,
      Blockchain.Solana,
      Blockchain.SolanaV2,
      Blockchain.Avalanche,
      Blockchain.TerraClassic,
      Blockchain.Osmosis,
      Blockchain.Cardano,
      Blockchain.Near,
      Blockchain.TON,
    ].includes(blockchain as Blockchain)
  ) {
    return true;
  }

  let block = currency;
  if (isEthHash(address)) {
    block = "ETH";
  }

  try {
    return WAValidator.validate(address, block) as boolean;
  } catch (err) {
    return false;
  }
}

/**
 * Gets the blockie for a valid address
 * @param address This must always be a valid address
 */
export function getBlockie(address: string): string | null {
  try {
    const seed = address.toLowerCase();
    if (isEthHash(seed)) {
      return blockies.create({ seed }).toDataURL();
    }
    // Identicon requires a minimum 15 character seed.
    const seedPadded = padStart(seed, 15, "abcd1234");
    const data = new Identicon(seedPadded, {
      foreground: [124, 58, 237, 160], // light purple hue
    }).toString();
    return `data:image/png;base64,${data}`;
  } catch (e) {
    return null;
  }
}

export function validatePositiveValue(errorMessage: string) {
  return (value: string) => !value || parseFloat(value) >= 0 || errorMessage;
}
export function validateGreaterZeroValue(errorMessage: string) {
  return (value: string) => !value || parseFloat(value) > 0 || errorMessage;
}

export function getPossibleExchangeIds(
  label: string,
  importType: ImportType,
  blockchain: string | undefined,
): [string] | [string, string] {
  // If it is a manual import, it is necessary to check both with or without
  // the blockchain as it could be either an exchange or wallet
  const walletExchangeId = toWalletExchangeId(label, blockchain);
  if (isWallet(importType)) {
    return [walletExchangeId];
  }
  return importType === ImportType.Manual || blockchain
    ? [label, walletExchangeId]
    : [label];
}

export function getManualCSVExchangeId(
  exchangeId?: string,
): string | undefined {
  return exchangeId
    ?.replace(/-manual$/, "")
    .replaceAll("-", " ")
    .toLowerCase();
}

export function getCurrencySymbol(
  currency: CurrencyIdentifier | string,
): string {
  if (typeof currency === "string") {
    return currency;
  }
  return currency.symbol;
}

export function getCurrencyIdentifier(
  currency: CurrencyIdentifier | string,
): CurrencyIdentifier | null {
  if (typeof currency === "string") {
    return null;
  }
  if (currency.source === "manual") {
    const identifier = {
      ...currency,
      name: currency.symbol.toLowerCase(), // the original name looks like 'Add "CUSTOM"' so we want to fix this
    };
    return identifier;
  }
  return currency;
}

export function useExchangeDisplayName(
  exchange: string,
  blockchain: Blockchain | undefined,
  displayName: string,
): string {
  const isAnAddress = !!useIsAddressLike(exchange.trim());
  const entity = useEntityLookup(exchange, blockchain);
  const isNicknamed =
    Boolean(entity) || (exchange !== displayName && isAnAddress); // Refers to the nickname for an imported wallet
  const finalDisplayName = entity
    ? `${entity.displayName}${isAnAddress ? ` (${exchange.trim()})` : ""} `
    : displayName.trim();

  return formatDisplayAddress(finalDisplayName, isNicknamed, isAnAddress);
}

export const formatDisplayAddress = (
  displayName: string, // could be an address 0x123, could be name + address like SushiSwap (0x123)
  isNickname: boolean, // true if name + address like "SushiSwap (0x123)"
  isBlockchain: boolean,
): string => {
  // name of exchange is too long
  if (!isBlockchain && displayName.length > MAX_EXCHANGE_LENGTH) {
    return `${displayName.slice(0, MAX_EXCHANGE_LENGTH)}\u2026`;
  }

  if (isNickname && displayName.length > MAX_EXCHANGE_LENGTH) {
    return `${displayName.slice(0, MAX_EXCHANGE_LENGTH)}\u2026`;
  }

  // address w/o nickname is too long
  if (isBlockchain && displayName.length > MAX_ADDR_LENGTH) {
    return middleTrim(displayName, MAX_ADDR_LENGTH);
  }

  return displayName;
};

type TradeItem = {
  label: string;
  taxDivision: Exclude<TaxDivision | "lastUsed", TaxDivision.Hidden>;
};

const TaxDivisionSort: Record<
  Exclude<TaxDivision | "lastUsed" | "group", TaxDivision.Hidden>,
  number
> = {
  lastUsed: 100,
  group: 80,
  [TaxDivision.Buy]: 70,
  [TaxDivision.Disposal]: 60,
  [TaxDivision.Transfer]: 50,
  [TaxDivision.Income]: 30,
  [TaxDivision.Ignore]: 20,
  [TaxDivision.Payment]: 10,
  [TaxDivision.Withdrawal]: 0,
};

export function sortTradeTypeItems(a: TradeItem, b: TradeItem): number {
  const taxDivisionSort =
    TaxDivisionSort[b.taxDivision] - TaxDivisionSort[a.taxDivision];
  if (taxDivisionSort !== 0) {
    return taxDivisionSort;
  }

  // If tax division is the same, sort alphabetically based on option label
  return a.label.localeCompare(b.label);
}

export type IdChip = {
  id: string;
  currencyIdentifier: CurrencyIdentifier;
  importType: ImportType;
  link: string | null;
  blockchain?: string;
};

export function getIdLink(tx: TransactionDetails): string | null {
  const { id: rawId, currencyIdentifier, importType, blockchain } = tx;
  let id = rawId.trim();

  // some ids have a suffix like -0, -1, for chains
  // where we have to split up the transaction into 'sub transactions'
  // for the purpose of parsing (e.g. cosmos)
  // want to ensure the link is still valid, so just grab the part before this suffix
  if (
    id.includes("-") &&
    // Hedera has a hyphen id for transactions
    !BlockchainWithHyphenId.includes(blockchain as Blockchain)
  ) {
    id = id.split("-")[0];
  }

  return blockchain &&
    (isEthHash(id) || isWallet(importType) || id.length === 64) // length of 64 indicates a hash
    ? getTxIdLink(id, currencyIdentifier.symbol, blockchain)
    : null;
}

export function getIdChips(row: ActionRow) {
  // Note: dont include fee tx ids
  const allTxs = [...row.outgoing, ...row.incoming];

  // If we have a wallet import on any of the transactions, we only want to show wallet imports.
  // This means we can hide txIds for APIs, OAuths or CSVs.
  const containsWalletImport = allTxs.some(
    (tx) => tx.importType === ImportType.Wallet,
  );
  const txs = allTxs.filter(
    (tx) => !containsWalletImport || tx.importType === ImportType.Wallet,
  );

  const chips = uniqWith(
    txs.map((tx) => {
      const { id, currencyIdentifier, blockchain, importType } = tx;
      const chip: IdChip = {
        id,
        currencyIdentifier,
        blockchain,
        importType,
        link: getIdLink(tx),
      };
      return chip;
    }),
    (a, b) =>
      a.id.toLowerCase() === b.id.toLowerCase() &&
      a.blockchain === b.blockchain,
  );

  return chips;
}
