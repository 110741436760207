import { Box, Link, Typography } from "@mui/material";
import Linkify from "react-linkify";

import { getInstructionsImage } from "~/components/imports/import-instructions-experiment/getInstructionsImage";
import { useDesign } from "~/hooks/useTheme";
import { useUser } from "~/redux/auth";

export function ImageInstructionsList({
  steps,
  exchangeName,
  importType,
}: {
  steps: { text: string; fileName?: string }[] | string[];
  exchangeName: string;
  importType: string;
}) {
  return (
    <Box display="flex" flexDirection="column" gap="0.5rem" alignSelf="stretch">
      {steps.map((step, i) => (
        <ImageInstructionsListItem
          key={typeof step === "string" ? step : step.text ?? i}
          item={typeof step === "string" ? step : step.text}
          index={i}
          totalSteps={steps.length}
          image={typeof step === "string" ? undefined : step.fileName}
          exchangeName={exchangeName}
          importType={importType}
        />
      ))}
    </Box>
  );
}

export function ImageInstructionsListItem({
  item,
  index,
  totalSteps,
  noNumber,
  image,
  exchangeName,
  importType,
}: {
  item: string;
  index: number;
  totalSteps: number;
  noNumber?: boolean;
  image?: string;
  exchangeName: string;
  importType: string;
}) {
  const { tokens } = useDesign();
  const isOnboarding = !!useUser()?.isOnboarding;
  if (totalSteps === 1 || noNumber) {
    return (
      <Typography variant="Metropolis/Body/Light" flex="1 0 0" fontWeight={500}>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <Link
              href={decoratedHref}
              key={key}
              target="_blank"
              rel="noopener noreferrer"
            >
              {decoratedText}
            </Link>
          )}
        >
          {item}
        </Linkify>
      </Typography>
    );
  }

  return (
    <Box
      display="flex"
      gap="0.375rem"
      bgcolor={tokens.elevation.medium}
      borderRadius="0.5rem"
      padding="0.5rem"
    >
      <Box display="flex">
        <Box
          width="1.25rem"
          minWidth="1.25rem"
          height="1.25rem"
          borderRadius="0.5rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={
            isOnboarding
              ? tokens.elevation.medium
              : tokens.background.accent.neutral.low
          }
          border={`1px solid ${tokens.border.neutral.default}`}
          pt={isOnboarding ? undefined : "1px"}
        >
          <Typography variant="Metropolis/Caption/Medium/Regular" color={tokens.text.high}>
            {index + 1}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="Metropolis/Body/Light"
        flex="1 0 0"
        overflow="auto"
        fontWeight={500}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <Link
              href={decoratedHref}
              key={key}
              target="_blank"
              rel="noopener noreferrer"
            >
              {decoratedText}
            </Link>
          )}
        >
          {item}
        </Linkify>
        {image ? (
          <img src={getInstructionsImage(exchangeName, importType, image)} />
        ) : null}
      </Typography>
    </Box>
  );
}
