import {
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Radio,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";

import { type Variation } from "~/components/settings-modal/views/enums";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useLang } from "~/redux/lang";
import { submitSurveyResponse } from "~/services/survey";

export const ExitSurvey = ({
  isOpen,
  handleClose = () => {},
  handleSubmit,
  variation,
}: {
  isOpen: boolean;
  handleClose?: () => void;
  handleSubmit: () => void;
  variation: Variation;
}) => {
  const lang = useLang();
  const cancellationReasons = lang.settings.exitSurvey.cancellationReasons;

  const cancellationReasonKeys = useMemo(() => {
    const keys = Object.keys(cancellationReasons);
    keys.sort(() => Math.random() - 0.5);
    return keys;
  }, [cancellationReasons]);

  const [primaryReason, setPrimaryReason] = useState<string | null>(null);
  const [comments, setComments] = useState("");

  const handleAction = () => {
    if (primaryReason) {
      submitSurveyResponse({
        surveyType: variation,
        comments,
        primaryReason,
      });
    }

    setPrimaryReason(null);
    setComments("");
    handleSubmit();
  };

  return (
    <GeneralDialog
      title={lang.settings.exitSurvey.title({ variation })}
      actionText={lang.settings.exitSurvey.continue}
      isOpen={isOpen}
      handleClose={handleClose}
      showCancel={variation === "DeleteAccount"}
      disableClose={variation === "CancelPlan"}
      handleAction={handleAction}
      closeOnClickAway
      disableAction={primaryReason === null}
      pending={false}
    >
      <DialogContentText>
        {lang.settings.exitSurvey.cancellationReasonQuestion({ variation })}
      </DialogContentText>
      <FormGroup>
        {cancellationReasonKeys.map((reason) => {
          const handleToggle = (checked: boolean) => {
            setPrimaryReason(checked ? reason : null);
          };

          return (
            <FormControlLabel
              key={reason}
              control={
                <Radio
                  onChange={(e) => {
                    handleToggle(e.target.checked);
                  }}
                  checked={primaryReason === reason}
                />
              }
              label={
                cancellationReasons[reason as keyof typeof cancellationReasons]
              }
            />
          );
        })}
      </FormGroup>
      <br />
      <DialogContentText>
        {lang.settings.exitSurvey.otherCommentsQuestion}
      </DialogContentText>
      <TextField
        value={comments}
        onChange={(e) => {
          setComments(e.target.value);
        }}
        multiline
        rows={4}
        fullWidth
      />
    </GeneralDialog>
  );
};
