import {
  ARCHIVE_STATUS_ACTIVE,
  ARCHIVE_STATUS_ARCHIVED,
  ARCHIVE_STATUS_RESTORING,
} from "@ctc/types";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { OnboardingAccountMenu } from "~/components/onboarding-v2/OnboardingAccountMenu";
import { OnboardingTitle } from "~/components/onboarding-v2/OnboardingTitle";
import { OnboardingPageContainer } from "~/components/onboarding-v2/ui/OnboardingPageContainer";
import { OnBoardingWindow } from "~/components/onboarding-v2/ui/OnBoardingWindow";
import { OnMobileWarningBanner } from "~/components/onboarding-v2/ui/OnMobileWarningBanner";
import { useIsMobile } from "~/components/ui/hooks";
import { LogoSpinner } from "~/components/ui/LogoSpinner";
import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useArchiveStatus, useRestoreUser } from "~/state/archiveUser";

export function RestoreUserData() {
  const isMobile = useIsMobile();
  const lang = useLang();
  const { tokens } = useDesign();

  const [isLoading, setIsLoading] = useState(false);

  // if the page loads and a restore is in progress, the isLoading will
  // be false, so this needs to poll to update the button spinner
  const { data: archiveStatus } = useArchiveStatus({
    refetchInterval: 1000 * 10,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (archiveStatus === ARCHIVE_STATUS_ACTIVE) {
      window.location.reload();
    }
  }, [archiveStatus]);

  const { mutateAsync: restoreUserData } = useRestoreUser();

  if (archiveStatus === undefined) {
    return <LogoSpinner />;
  }

  return (
    <OnboardingPageContainer>
      <OnboardingAccountMenu />
      <OnMobileWarningBanner />
      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        direction={isMobile ? "column" : "row"}
      >
        <OnBoardingWindow
          display="flex"
          alignItems="center"
          gap="1.5rem"
          flexDirection="column"
        >
          <OnboardingTitle textAlign="center">{lang.title}</OnboardingTitle>
          <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
            {lang.userArchive.title}
          </Typography>
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            gap="0.75rem"
            mb="0.7rem"
          >
            <PrimaryLoadingButton
              disabled={archiveStatus !== ARCHIVE_STATUS_ARCHIVED}
              loading={isLoading || archiveStatus === ARCHIVE_STATUS_RESTORING}
              onMouseDown={async () => {
                setIsLoading(true);
                await restoreUserData();
              }}
            >
              {lang.userArchive.restoreData}
            </PrimaryLoadingButton>
          </Box>
        </OnBoardingWindow>
      </Stack>
    </OnboardingPageContainer>
  );
}
