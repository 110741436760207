import styled from "styled-components/macro";

export const DividerStyled = styled.div<{
  sectionId: string | undefined;
  index: number;
}>`
  ${(props) =>
    props.sectionId && props.index !== 0
      ? `border-top: 1px solid ${props.theme.tokens.border.neutral.medium};
        padding-top: 0.5rem;      
      `
      : ""};
`;
