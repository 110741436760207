import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box } from "@mui/material";
import { type FunctionComponent, type ReactNode } from "react";
import styled from "styled-components/macro";

import { FilterActionType } from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { useDesign } from "~/hooks/useTheme";
import { Align } from "~/types/enums";
import { type Sort } from "~/types/enums";

export type ActionHeaderCellProps = {
  align?: Align;
  sort?: {
    sortAscending: Sort;
    sortDescending: Sort;
  };
  children: ReactNode;
};

const Sorter: FunctionComponent<
  ActionHeaderCellProps & {
    sort: NonNullable<ActionHeaderCellProps["sort"]>;
  }
> = ({ sort, children, align = Align.Left }) => {
  const { state, dispatch } = useTransactionFilter();
  const { tokens } = useDesign();
  const { sortAscending, sortDescending } = sort;

  const isSorted =
    state.sort === sortAscending || state.sort === sortDescending;

  const isRight = align === Align.Right;

  return (
    <Box
      display="flex"
      sx={{
        height: "100%",
        whiteSpace: "nowrap",
        cursor: "pointer",
        fontWeight: isSorted ? "600" : "500",
        color: isSorted ? tokens.text.high : tokens.text.low,
        ":hover": {
          color: tokens.text.default,
        },
      }}
      position="relative"
      alignItems="center"
      justifyContent={align}
      gap="0.25rem"
      onClick={() => {
        if (state.sort === sortDescending) {
          dispatch({
            type: FilterActionType.SetSort,
            sort: sortAscending,
          });
        } else {
          dispatch({
            type: FilterActionType.SetSort,
            sort: sortDescending,
          });
        }
      }}
    >
      {children}
      {isSorted ? (
        state.sort === sortAscending ? (
          <KeyboardArrowUp
            style={{
              position: isRight ? "absolute" : "inherit",
              right: isRight ? "-1.5rem" : "inherit",
              color: tokens.text.default,
            }}
          />
        ) : (
          <KeyboardArrowDown
            style={{
              position: isRight ? "absolute" : "inherit",
              right: isRight ? "-1.5rem" : "inherit",
              color:
                state.sort === sortDescending
                  ? tokens.text.default
                  : tokens.text.disabled,
            }}
          />
        )
      ) : null}
    </Box>
  );
};

export const ActionHeaderCell: FunctionComponent<ActionHeaderCellProps> = ({
  children,
  sort,
  align = Align.Left,
}) => {
  const { tokens } = useDesign();
  if (sort) {
    return (
      <Sorter sort={sort} align={align}>
        <ActionHeaderCellStyled>{children}</ActionHeaderCellStyled>
      </Sorter>
    );
  }

  return (
    <ActionHeaderCellStyled color={tokens.text.low} justifyContent={align}>
      {children}
    </ActionHeaderCellStyled>
  );
};

export const ActionHeaderCellStyled = styled(Box)`
  font-size: 0.813rem;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
  height: 100%;
  white-space: nowrap;
`;
