import { InventoryMethod, Trade } from "@ctc/types";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import union from "lodash/union";
import qs from "query-string";

import { type GroupingCheckResult } from "~/components/transactions/grouping-wizard/index";
import { ActionDefinitions, TradeInfo } from "~/lib/tradeTypeDefinitions";
import { get } from "~/services/core";
import {
  GroupedActionRatio,
  GroupedTrade,
  Side,
  TradeDirection,
} from "~/types/enums";
import {
  type ActionRow,
  type ActionType,
  type TradeData,
  type TransactionDetails,
  type TransactionFilterOptions,
} from "~/types/index";

export const BURN_ADDRESSES = ["0x0000000000000000000000000000000000000000"];

export function getTransactionFilterOptions() {
  const path = "/transactions/filter-options";
  return get<Partial<TransactionFilterOptions>>(path);
}

export const inTrade: Readonly<Trade[]> = Object.entries(TradeInfo).reduce(
  (acc, curr) => {
    const [trade, definition] = curr as [Trade, TradeData];
    const whitelist = ["unknown", "in"];
    if (whitelist.includes(definition.direction)) acc.push(trade);
    return acc;
  },
  [] as Trade[],
);

export const outTrade: Readonly<Trade[]> = Object.entries(TradeInfo).reduce(
  (acc, curr) => {
    const [trade, definition] = curr as [Trade, TradeData];
    const whitelist = ["unknown", "out"];
    if (whitelist.includes(definition.direction)) acc.push(trade);
    return acc;
  },
  [] as Trade[],
);

export function isInTrade(trade: Trade): boolean {
  if (!TradeInfo[trade]) return false;
  const direction = TradeInfo[trade].direction;
  if (direction === TradeDirection.Unknown) return true;
  return direction === "in";
}

export function isOutTrade(trade: Trade): boolean {
  if (!TradeInfo[trade]) return false;
  const direction = TradeInfo[trade].direction;
  if (direction === TradeDirection.Unknown) return true;
  return direction === "out";
}

export const editBlockedTradeOptions: Readonly<Trade[]> = Object.entries(
  TradeInfo,
).reduce((acc, curr) => {
  const [trade, definition] = curr as [Trade, TradeData];
  if (definition.isEditBlocked) acc.push(trade);
  return acc;
}, [] as Trade[]);

export const allTrades: Readonly<Trade[]> = union(inTrade, outTrade);

function getOutdatedTradeSide(trade: string): Trade {
  switch (trade) {
    case Trade.StakingReward:
      return Trade.Interest;
    case "reward":
      return Trade.In;
    default:
      return Trade.In;
  }
}
/** "reward" is a hardcoded outdated trade from 2021. intentionally excluded from Trade enum */
const hardcodedOutdatedTrades = ["reward"];

export const outdatedTrades = Object.entries(TradeInfo).reduce((acc, curr) => {
  const [trade, definition] = curr as [Trade, TradeData];
  if (definition.isOutdated) acc.push(trade);
  return acc;
}, hardcodedOutdatedTrades);

export function isOutdatedTrade(trade: string) {
  return outdatedTrades.includes(trade);
}

export function isTrade(trade: ActionType): trade is Trade {
  return Object.values(Trade).includes(trade as any);
}
export function isGroupedTrade(trade: ActionType): trade is GroupedTrade {
  return Object.values(GroupedTrade).includes(trade as any);
}
export function isOneToNGroupedTrade(trade: ActionType): boolean {
  return !!(
    isGroupedTrade(trade) &&
    ActionDefinitions[trade].groupRatio === GroupedActionRatio.OneToN
  );
}

export const manualInputTradeOptions: Readonly<Trade[]> = Object.entries(
  TradeInfo,
).reduce((acc, curr) => {
  const [trade, definition] = curr as [Trade, TradeData];
  if (definition.isManual) acc.push(trade);
  return acc;
}, [] as Trade[]);

/**
 * The subset of ignoredTrades which are a transfer between different exchanges
 */

export const recategoriseTrades: Readonly<ActionType[]> = [
  Trade.Out,
  Trade.In,
  Trade.Unknown,
  GroupedTrade.Uncategorised,
];

export function isUncategorisedTrade(trade: ActionType): boolean {
  return recategoriseTrades.includes(trade);
}

export function considersWashSales(inventoryMethod: InventoryMethod): boolean {
  switch (inventoryMethod) {
    case InventoryMethod.HMRC:
    case InventoryMethod.ACB:
      return true;
    default:
      return false;
  }
}

export function filterGroupedTradeOptions(options: GroupedTrade[]) {
  const availableOptions = options.filter(
    (o) => !ActionDefinitions[o].excludeFromRecategorisation,
  );

  return availableOptions;
}

/**
 * Get trade transition categories, used to build inputs for category selection.
 *
 * @param tradeInput  The type we are changing from
 * @returns
 */
export function getTransactionCategoryOptions({
  tradeInput,
}: {
  tradeInput: ActionType;
}): ActionType[] {
  if (isGroupedTrade(tradeInput)) {
    const options = filterGroupedTradeOptions(Object.values(GroupedTrade));

    const filteredOptions = options.filter(
      (option) =>
        ActionDefinitions[option].groupRecategorisationType ===
        ActionDefinitions[tradeInput].groupRecategorisationType,
    );

    return filteredOptions;
  }

  let tradeType = tradeInput as Trade;

  // Map the outdated trade to the correct side.
  if (isOutdatedTrade(tradeType)) {
    tradeType = getOutdatedTradeSide(tradeType);
  }

  return getTransactionCategoryOptionsByDirection({
    direction:
      tradeType === Trade.Unknown
        ? TradeDirection.Unknown
        : isInTrade(tradeType)
          ? TradeDirection.In
          : TradeDirection.Out,
  });
}

export function getTransactionCategoryOptionsByDirection({
  direction,
}: {
  direction: TradeDirection;
  restrictedGroups?: boolean;
}) {
  const options =
    direction === TradeDirection.Unknown
      ? allTrades
      : direction === TradeDirection.In
        ? inTrade
        : outTrade;

  // Remove restricted options.
  return (options || [])
    .filter((option) => option !== Trade.Unknown)
    .filter((option) => !editBlockedTradeOptions.includes(option));
}

export function isBurnAddress(address: string) {
  return BURN_ADDRESSES.includes(address);
}

/**
 * This edge case can happen if a user adds a transaction manually where
 * both sources are "unknown".
 */
export function isActionToFromUnknown(row: ActionRow) {
  const txs = [...row.outgoing, ...row.incoming];
  const from = txs.map((tx) => tx.from);
  const to = txs.map((tx) => tx.to);
  return from.includes("unknown") && to.includes("unknown");
}

/**
 * Checks that a tx row can be assumed to be imported. A transaction can be
 * assumed as imported if the to/from addresses match.
 */
export function isActionAssumedSourceImported(row: ActionRow) {
  if (isActionToFromUnknown(row)) return false;

  const txs = [...row.outgoing, ...row.incoming];
  const from = new Set(txs.map((tx) => tx.from));
  const to = new Set(txs.map((tx) => tx.to));

  return isEqual(from, to);
}

// Certain trades require an alternative display but need maintain they're key.
// eg: Unknown in and out trades should be displayed as a deposit or withdrawal.
// Expose `trade` to allow caller to alter trade type from `row`.
export function getActionFaux(row: ActionRow, trade: ActionType) {
  const isGrouped = isGroupedTrade(trade);

  // Some transactions may be transferred to/from the same wallet. If
  // an unknown in/out transaction has the same to/from address or are both from
  // an unknown source, the type cannot be assumed as a "fake" send/receive.
  // This is because some sources (such as BSC) will model some transactions
  // (eg: airdrop) as unknown in/out from the same address, we don't want to
  // assume they were a transfer.
  if (isActionAssumedSourceImported(row) || isActionToFromUnknown(row)) {
    if (trade === Trade.In) return Trade.In;
    if (trade === Trade.Out) return Trade.Out;
  }

  // for now we keep the trade type as is.
  if (trade === Trade.In && !isGrouped) return Trade.In;
  if (trade === Trade.Out && !isGrouped) return Trade.Out;

  return trade as Trade;
}

export function isBridgeTrade(trade: Trade) {
  return [Trade.BridgeIn, Trade.BridgeOut].includes(trade);
}

export function isSwapTrade(trade: Trade) {
  return [Trade.SwapIn, Trade.SwapOut].includes(trade);
}

export function getTradePair(trade: Trade) {
  let inTrade;
  let outTrade;

  const tradeInfo = TradeInfo[trade];
  const { negativeValueTrade, direction } = tradeInfo;

  if (direction === "in") {
    inTrade = trade;
    outTrade = negativeValueTrade;
  } else {
    inTrade = negativeValueTrade;
    outTrade = trade;
  }

  return { inTrade, outTrade };
}

export type PartyDetails = {
  exchange: string;
  displayName: string;
  isSmartContract?: boolean;
  blockchain: string | undefined;
};

/**
 * There is weirdness between trades and other trades as to which side is the
 * side we care about this function handles it
 */
export function getPartyDetails(
  transaction: TransactionDetails,
  side: Side,
): PartyDetails {
  const handler: Record<Side, PartyDetails> = {
    [Side.From]: {
      exchange: transaction.from,
      displayName: transaction.fromDisplayName,
      isSmartContract: transaction.isFromSmartContract,
      blockchain: transaction.blockchain,
    },
    [Side.To]: {
      exchange: transaction.to,
      displayName: transaction.toDisplayName,
      isSmartContract: transaction.isToSmartContract,
      blockchain: transaction.blockchain,
    },
  };

  return handler[side];
}

export function getPartyDetailsFromTx(
  transaction: TransactionDetails,
): PartyDetails {
  if (TradeInfo[transaction.trade].direction === "in") {
    return {
      exchange: transaction.to,
      displayName: transaction.toDisplayName,
      isSmartContract: transaction.isToSmartContract,
      blockchain: transaction.blockchain,
    };
  }
  return {
    exchange: transaction.from,
    displayName: transaction.fromDisplayName,
    isSmartContract: transaction.isFromSmartContract,
    blockchain: transaction.blockchain,
  };
}

export function formatFunctionName(name: string): string {
  if (name.startsWith("0x")) return name;
  return startCase(name);
}

export function groupingCheck(ids: string[]) {
  const query = qs.stringify({ ids });
  const path = `/transactions/grouping-check?${query}`;
  return get<GroupingCheckResult>(path);
}

export function isTransferLike(type: ActionType): boolean {
  return isGroupedTrade(type) && !!ActionDefinitions[type].isTransferLike;
}
