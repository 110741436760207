import { Button, type ButtonProps } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

export const NavButton = withStyles({
  root: {
    color: "inherit",
    minWidth: 0,
  },
  label: {
    textTransform: "none",
  },
})(Button) as React.ComponentType<ButtonProps>;
