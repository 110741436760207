import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { CategoryChip } from "~/components/transactions/command-palette/views/recategorise/CategoryChip";
import { invariant } from "~/lib/invariant";
import { useNewBulkRecategoriseActionsMutation } from "~/state/actions";
import { type ActionType } from "~/types/index";

export const Confirmation = ({
  toActionType,
}: {
  toActionType: ActionType;
}) => {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");
  const bulkRecategoriseActionsMutation =
    useNewBulkRecategoriseActionsMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.Categorize,
  });

  const onConfirm = () => {
    bulkRecategoriseActionsMutation.mutate(
      {
        filter,
        toActionType,
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.Categorize} />
          <CategoryChip trade={toActionType} />
        </BulkEditChips>
      }
      onConfirm={onConfirm}
      loading={bulkRecategoriseActionsMutation.isPending}
    />
  );
};
