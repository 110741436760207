import { type AccountantState as State, type AppThunk } from "~/redux/types";
import { useSelector } from "~/redux/useSelector";
import { type ClientDetails } from "~/types/index";

enum Accountant {
  SetActiveClient = "SET_ACTIVE_CLIENT",
  SaveClient = "client/saveClient",
  SaveClientRequest = "client/saveClient/request",
  SaveClientSuccess = "client/saveClient/success",
  SaveClientFailure = "client/saveClient/failure",
  DeleteClient = "client/deleteClient",
  DeleteClientRequest = "client/deleteClient/request",
  DeleteClientSuccess = "client/deleteClient/success",
  DeleteClientFailure = "client/deleteClient/failure",
  UpdateActiveClient = "client/updateActiveClient",
  AddClientSuccess = "client/addClient/success",
  SetIsProcessingPayment = "client/isProcessingPayment",
}

const initialState: State = {
  clientList: [],
  loading: false,
  addClientSuccess: false,
  isProcessingPayment: false,
};

type AccountantAction =
  | { type: Accountant.SaveClientRequest }
  | { type: Accountant.SaveClientFailure }
  | { type: Accountant.SaveClientSuccess }
  | { type: Accountant.DeleteClientRequest }
  | { type: Accountant.DeleteClientSuccess }
  | { type: Accountant.DeleteClientFailure }
  | {
      type: Accountant.AddClientSuccess;
      payload: { visible: boolean };
    }
  | {
      type: Accountant.SetIsProcessingPayment;
      payload: boolean;
    };

export function accountantReducer(
  state = initialState,
  action: AccountantAction,
): State {
  switch (action.type) {
    case Accountant.AddClientSuccess:
      return {
        ...state,
        addClientSuccess: action.payload.visible,
      };
    case Accountant.SetIsProcessingPayment:
      return {
        ...state,
        isProcessingPayment: action.payload,
      };
    default:
      return state;
  }
}

export function setAddClientSuccess(payload: {
  visible: boolean;
}): AccountantAction {
  return {
    type: Accountant.AddClientSuccess,
    payload,
  };
}

export function SetIsProcessingPayment(payload: boolean) {
  return {
    type: Accountant.SetIsProcessingPayment,
    payload,
  };
}

export function updateActiveClient(payload: Partial<ClientDetails>) {
  return {
    type: Accountant.UpdateActiveClient,
    payload,
  };
}

export const useIsAddClientSuccess = () =>
  useSelector((state) => state.accountant.addClientSuccess);

export const hideAddClientSuccess = (): AppThunk => async (dispatch) => {
  dispatch(setAddClientSuccess({ visible: false }));
};

export const useIsProcessingPayment = () =>
  useSelector((state) => state.accountant.isProcessingPayment);
