import { createContext, type FunctionComponent, useContext } from "react";

import { type ActionRow } from "~/types/index";

export type ActionTableOptions = {
  isBulkSelectEnabled: boolean;
  ActionRow?: FunctionComponent<{ row: ActionRow }>;
  isDateGroupClickFilterEnabled: boolean;
};

export const ActionTableOptionsContext = createContext<ActionTableOptions>({
  isBulkSelectEnabled: true,
  isDateGroupClickFilterEnabled: false,
  ActionRow: undefined,
});

export const useActionTableOptions = () => {
  return useContext(ActionTableOptionsContext);
};
