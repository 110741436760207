interface TextStyle {
  name: string;
  value: {
    fontFamily?: string;
    fontWeight?: number | string;
    fontSize?: string | number;
    lineHeight?: string | number;
    letterSpacing?: string | number;
  };
}

type ValidTextStyle = {
  name: string;
  style: Record<string, any>;
};

/**
 * Recursively flattens nested text styles into a single-level object with path-based keys
 * @param styles - The nested styles object to flatten
 * @param prefix - The current path prefix for nested keys
 * @returns Record of flattened text styles with path-based keys
 */
export function getFlattenedTextStyles(
  styles: Record<string, any>,
  prefix: string,
): Record<string, any> {
  const result: Record<string, any> = {};

  // Early exit if styles is not an object
  if (typeof styles !== "object" || styles === null) {
    return result;
  }

  // Check if current object is a leaf node (text style definition)
  if ("fontFamily" in styles) {
    result[prefix] = styles;
    return result;
  }

  // Recursively process nested objects
  for (const key in styles) {
    const newPrefix = prefix ? `${prefix}/${key}` : key;
    const nestedStyles = getFlattenedTextStyles(styles[key], newPrefix);
    Object.assign(result, nestedStyles);
  }

  return result;
}
