import { displayMessage } from "~/components/ui/Toaster";

import { type BroadcastChannels } from "../hooks/enums";
import { DisplayMessage, type PopupTarget } from "../types/enums";

export type PopupOptions = {
  url: string;
  width?: number;
  height?: number;
  target: PopupTarget | "_self" | "_blank" | "_parent" | "_top";
};

function setupMonitor({
  channelName,
  popup,
  onClose,
  onMessage,
}: {
  channelName: BroadcastChannels;
  popup: Window;
  onClose: () => void;
  onMessage: (event: MessageEvent) => void;
}) {
  let channel: BroadcastChannel | undefined;
  let checkClosed: NodeJS.Timer | undefined;

  try {
    channel = new BroadcastChannel(channelName);

    const cleanup = () => {
      try {
        clearInterval(checkClosed);
        channel?.close();
        window.removeEventListener("unload", handleUnload);
        onClose();
      } catch (error) {
        onClose();
      }
    };

    checkClosed = setInterval(() => {
      if (popup.closed) {
        cleanup();
      }
    }, 100);

    const handleUnload = () => {
      cleanup();
    };

    window.addEventListener("unload", handleUnload);
    channel.addEventListener("message", onMessage);

    return cleanup;
  } catch (error) {
    clearInterval(checkClosed);
    channel?.close();
    onClose();
    return () => {};
  }
}

export function createPopup({
  channelName,
  options,
  onPopupCreated,
  onPopupClosed,
  onMessage,
  failedToOpenMessage,
  onPopupOpenFailed,
}: {
  channelName: BroadcastChannels;
  options: PopupOptions;
  onPopupCreated: (popup: Window) => void;
  onPopupClosed: () => void;
  onMessage: (event: MessageEvent) => void;
  onPopupOpenFailed?: () => void;
  failedToOpenMessage?: string;
}): Window | null {
  const { url, width = 500, height = 600, target } = options;

  const perms = [
    "popup=true",
    `width=${width}`,
    `height=${height}`,
    "noalias", // Prevents the new window from accessing named windows
    "status=no", // Hides the status bar
    "toolbar=no", // Hides the browser toolbar
  ];

  const popup = window.open(url, target, perms.join(","));

  if (popup) {
    // For security reasons we don't want the popup to have access to the parent window
    // We cannot use noopener because it prevents us from getting a reference to the popup window which is required to detect if it is closed
    popup.opener = null;
    setupMonitor({
      channelName,
      popup,
      onClose: onPopupClosed,
      onMessage,
    });
    onPopupCreated(popup);
    return popup;
  }

  if (failedToOpenMessage) {
    // Show a toast notification when the popup fails to open
    displayMessage({
      message: failedToOpenMessage,
      type: DisplayMessage.Error,
    });
  }

  onPopupOpenFailed?.();

  return null;
}
