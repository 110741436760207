import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteNumberInput } from "~/components/transactions/command-palette/CommandPaletteNumberInput";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { Confirmation } from "~/components/transactions/command-palette/views/change-price/Confirmation";
import { PriceTopSection } from "~/components/transactions/command-palette/views/change-price/PriceTopSection";
import { displayFiatValue } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLanguagePreference } from "~/redux/lang";
import { type CurrencyIdentifier } from "~/types/index";

export function SetPrice({ currency }: { currency: CurrencyIdentifier }) {
  const { push } = useNavController();
  const locale = useLanguagePreference();
  const localCurrency = useLocalCurrency();

  return (
    <CommandPaletteNumberInput
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangePrice} />
          <PriceTopSection currency={currency} />
        </BulkEditChips>
      }
      onConfirm={(price) => {
        push(<Confirmation currency={currency} price={price} />);
      }}
      placeholder={displayFiatValue({
        value: 0,
        localCurrency,
        locale,
        fractionDigits: 2,
      })}
    />
  );
}
