import { CircularProgress, DialogActions } from "@mui/material";
import styled from "styled-components/macro";

import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";

export const StyledDialogActions = styled(({ dividers, ...props }) => (
  <DialogActions {...props} />
))`
  && {
    padding: 1rem 1.5rem;
    background-color: ${({ theme }) => theme.tokens.elevation.low};
    border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  }
`;

export const CancelOrConfirmBar = ({
  handleCancel,
  handleConfirm,
  cancelText,
  confirmText,
  isProcessingConfirm,
  isConfirmDisabled,
  isCancelDisabled,
}: {
  handleCancel: (event: any) => void;
  handleConfirm: (event: any) => void;
  cancelText: string;
  confirmText: string;
  isProcessingConfirm?: boolean;
  isConfirmDisabled?: boolean;
  isCancelDisabled?: boolean;
}) => {
  return (
    <ButtonBox>
      <TertiaryButton
        type="submit"
        variant="outlined"
        onClick={handleCancel}
        disabled={isCancelDisabled}
        sx={{ mr: "0.5rem" }}
      >
        {cancelText}
      </TertiaryButton>
      <PrimaryButton
        type="submit"
        variant="contained"
        onClick={handleConfirm}
        startIcon={isProcessingConfirm && <CircularProgress size="1rem" />}
        disabled={isConfirmDisabled}
      >
        {confirmText}
      </PrimaryButton>
    </ButtonBox>
  );
};

const ButtonBox = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background-color: ${({ theme }) => theme.tokens.background.neutral.default};
  padding: 1.5rem;
  align-items: center;
`;
