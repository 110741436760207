import coinbaseLogo from "~/assets/coinbase_wordmark.svg";

export const CoinbaseLogo = () => {
  return (
    <img
      src={coinbaseLogo}
      alt="coinbase"
      style={{
        width: "170px",
        marginBottom: "-0.5rem",
      }}
    />
  );
};
