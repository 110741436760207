export enum PaywallModalType {
  GenericPaywall = "GenericPaywall",
  MultiTaxYears = "MultiTaxYears",
  TradingStockReport = "TradingStockReport",
  AdvancedReports = "AdvancedReports",
  AdvancedInventory = "AdvancedInventory",
  TaxLossHarvesting = "TaxLossHarvesting",
  AllReports = "AllReports",
  Tips = "Tips",
  SmartContractInteraction = "SmartContractInteraction",
}
