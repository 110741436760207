import qs from "query-string";

import { deleteRequest, get, post, put } from "~/services/core";
import { type RefreshReason } from "~/types/enums";

export async function getDataSets() {
  const path = "/developer/seed-data-sets";
  return get<
    {
      _id: string;
      uid: string;
      name: string;
      email: string;
      password: string;
      description: string;
      props: Record<string, number>;
      transactionCount: number;
    }[]
  >(path);
}

export async function cloneDataSetToNewClient(args: {
  clientName: string;
  dataSetId: string;
}) {
  const path = `/developer/seed-data-sets/${args.dataSetId}/clone-to-new-client`;
  return post<void>(path, { clientName: args.clientName });
}

export async function cloneDataSetToSelf(args: { dataSetId: string }) {
  const path = `/developer/seed-data-sets/${args.dataSetId}/clone-to-self`;
  return post<void>(path, {});
}

export async function editDataSet(args: {
  dataSetId: string;
  name: string;
  description: string;
}) {
  const path = `/developer/seed-data-sets/${args.dataSetId}`;
  return post<void>(path, { name: args.name, description: args.description });
}

export async function deleteDataSet(args: { dataSetId: string }) {
  const path = `/developer/seed-data-sets/${args.dataSetId}`;
  return deleteRequest<void>(path);
}

export async function createDataSet(args: {
  name: string;
  description: string;
}) {
  const path = `/developer/seed-data-sets`;
  return post<void>(path, { name: args.name, description: args.description });
}

export async function developerReportRefresh(reason: RefreshReason) {
  const query = qs.stringify({ reason });
  const path = `/developer/report?${query}`;
  return post<void>(path, {});
}

export async function updateOnboardingStatus(newIsOnboarding: boolean) {
  const path = `/developer/user/current/onboarding-status`;
  return put<{ error: boolean; data: { isOnboarding: boolean } }>(path, {
    newIsOnboarding,
  });
}

export async function archiveUserData() {
  return post("/developer/user/archive-data", {});
}
