import { ArrowForward, Lock } from "@mui/icons-material";
import { Box, Fade, Popper, Stack, Tooltip, Typography } from "@mui/material";
import {
  bindHover,
  bindPopper,
  usePopupState,
} from "material-ui-popup-state/hooks";
import type React from "react";

import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import { CheckboxActionType } from "~/components/transactions/filter-bar/enums";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { Checkbox } from "~/components/ui/Checkbox";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { useDesign } from "~/hooks/useTheme";
import { useIsSelectedById } from "~/hooks/useTransactions";
import { useLang } from "~/redux/lang";

export function ActionRowCheckbox({
  hover,
  row,
  isCheckboxEnabled = true,
}: ActionRowCellProps & { isCheckboxEnabled?: boolean }) {
  const { tokens } = useDesign();
  const lang = useLang();
  const isSelected = useIsSelectedById(row._id);
  const Icon = TradeIcons[row.type];
  const { setActive } = useSettingsModal();

  const popupState = usePopupState({
    variant: "popper",
    popupId: "lockPeriod",
  });

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="1.75rem"
      width="1.75rem"
      flexShrink={0}
    >
      {row.isLocked ? (
        <>
          <TextIconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
            }}
            disableRipple
            {...bindHover(popupState)}
          >
            <Lock style={{ color: tokens.text.default, fontSize: "1.25rem" }} />
          </TextIconButton>
          <Popper
            {...bindPopper(popupState)}
            placement="top"
            transition
            style={{ zIndex: 10000, marginTop: "0.125rem" }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box
                  onMouseLeave={() => {
                    popupState.close();
                  }}
                  bgcolor={tokens.elevation.low}
                  border={`1px solid ${tokens.border.neutral.medium}`}
                  p="0.5rem"
                  borderRadius="0.25rem"
                  mt="0.25rem"
                >
                  <Stack
                    direction="row"
                    spacing="0.25rem"
                    flexWrap="nowrap"
                    alignItems="center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Typography
                      variant="Metropolis/Caption/Medium/Regular"
                      sx={{ color: tokens.text.low }}
                    >
                      {lang.txTable.txRow.lockedRowTooltip.text}
                    </Typography>
                    <TextButton
                      size="small"
                      onClick={() => {
                        setActive(SettingsTabs.LockPeriod);
                      }}
                      sx={{
                        color: tokens.text.brand,
                        fontSize: "0.75rem",
                        borderRadius: "0.25rem",
                        textDecoration: "none",
                      }}
                      endIcon={<ArrowForward />}
                    >
                      {lang.txTable.txRow.lockedRowTooltip.action}
                    </TextButton>
                  </Stack>
                </Box>
              </Fade>
            )}
          </Popper>
        </>
      ) : isCheckboxEnabled && (hover || isSelected) ? (
        <NewCheckboxSelection id={row._id} />
      ) : (
        <Icon />
      )}
    </Stack>
  );
}

export const NewCheckboxSelection = ({
  id,
  checkboxDisabled = false,
}: {
  id: string;
  checkboxDisabled?: boolean;
}) => {
  const lang = useLang();
  const {
    dispatch: checkboxDispatch,
    handleShiftSelect,
    handleSelect,
  } = useTransactionCheckbox();
  const checked = useIsSelectedById(id);

  const stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    stopPropagation(e);

    if (e.shiftKey) {
      handleShiftSelect(id);
    } else if (checked) {
      checkboxDispatch({
        type: CheckboxActionType.RemoveSelectedId,
        id,
      });
    } else {
      handleSelect(id);
    }
  };

  const checkbox = (
    <Checkbox
      size="small"
      checked={checked}
      onClick={handleClick}
      color="primary"
      disabled={checkboxDisabled}
    />
  );

  return (
    <div>
      {checkboxDisabled ? (
        <Tooltip title={lang.txTable.invalidBulkEditTooltip}>
          <div onClick={stopPropagation}>{checkbox}</div>
        </Tooltip>
      ) : (
        checkbox
      )}
    </div>
  );
};
