import { Box, Typography } from "@mui/material";

import { Subheading } from "~/components/settings-modal/views/profile/Profile";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useIsMobile } from "~/components/ui/hooks";
import { useLogout } from "~/redux/auth";
import { useLang } from "~/redux/lang";

export function LogoutSettings() {
  const logout = useLogout();
  const lang = useLang();
  const isMobile = useIsMobile();

  return (
    <Box display="flex" flexDirection="column" gap="0.25rem" mt="0.5rem">
      <Typography variant="Metropolis/Body/Light" display="inline">
        <Subheading>{lang.settings.profile.logout}</Subheading>
      </Typography>
      <TertiaryButton
        variant="outlined"
        size="small"
        onClick={() => {
          logout();
        }}
        sx={{
          width: isMobile ? "100%" : "fit-content",
          marginTop: "0.25rem",
        }}
      >
        {lang.settings.profile.logout}
      </TertiaryButton>
    </Box>
  );
}
