import { type LocalCurrency } from "@ctc/types";
import { Tooltip } from "@mui/material";

import { EnDash } from "~/components/transactions/action-row/EnDash";
import { GainLossBox } from "~/components/transactions/GainLossCell";
import { Cell } from "~/components/transactions/styles";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue, displayQuantity } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLanguagePreference } from "~/redux/lang";

export function DisplayValueCell({
  value,
  displayFiat,
  displayColor,
  isBold,
  lineThrough,
  tooltip,
}: {
  value: number | null;
  displayFiat?: LocalCurrency;
  displayColor?: boolean;
  isBold?: boolean;
  lineThrough?: boolean;
  tooltip?: string;
}) {
  const { tokens } = useDesign();
  const lang = useLanguagePreference();
  const localCurrency = useLocalCurrency();
  let col = tokens.text.default;
  if (displayColor && value) {
    col =
      value > 0
        ? tokens.text.success
        : value < 0
          ? tokens.text.danger
          : tokens.text.default;
  }

  const style = {
    fontFamily: tokens.fontFamilies.numeric,
    fontWeight: isBold ? 600 : 500,
    paddingTop: isBold ? "0.5rem" : undefined,
    paddingBottom: isBold ? "0.5rem" : undefined,
    textDecoration: lineThrough ? "line-through" : undefined,
    color: col,
    opacity: lineThrough ? "50%" : undefined,
  };

  if (displayColor && isBold && displayFiat) {
    return (
      <Cell align="right" style={style}>
        <GainLossBox totalGain={value} />
      </Cell>
    );
  }

  if (value === null) {
    return (
      <Cell align="right">
        <EnDash />
      </Cell>
    );
  }

  const FiatValueComponent = (
    <span>
      {displayFiatValue({
        value,
        localCurrency,
        locale: lang,
        disableCurrencySymbol: true,
      })}
    </span>
  );

  return (
    <Cell align="right" style={style}>
      {tooltip && displayFiat ? (
        <Tooltip
          title={tooltip ? <div>{tooltip}</div> : <></>}
          style={{ cursor: "pointer" }}
        >
          {FiatValueComponent}
        </Tooltip>
      ) : displayFiat ? (
        FiatValueComponent
      ) : (
        <div>{displayQuantity(value, lang)}</div>
      )}
    </Cell>
  );
}
