/*
 * Partner companies we want to track as referrers/affiliates of our product
 */
export enum ReferrerSource {
  Coinbase = "coinbase",
  MetaMask = "metamask",
  CoinbaseOne = "coinbaseone",
  CoinbaseOnePremium = "coinbaseone-premium",
  Coinstats = "coinstats",
  Coinjar = "coinjar",
  IndependentReserve = "independent-reserve",
  BitcoinDotComAu = "bitcoin-dot-com-au",
  Phantom = "phantom",

  // Embedded experience referrer sources
  MetamaskEmbedded = "metamask-embedded",
  CoinbaseEmbedded = "coinbase-embedded",
  KrakenEmbedded = "kraken-embedded",
  BinanceEmbedded = "binance-embedded",
  ReferAFriend = "refer-a-friend",
  // ...Add more here

  // This is used for internal CTC testing purposes
  // instead of having to manually generate coupons
  CTC = "ctc",
}

/**
 * Constants for partners that have employee benefit programs with us
 * References values from ReferrerSource to maintain a single source of truth
 */
export const PartnersWithEmployeeBenefitConstants = {
  Coinbase: ReferrerSource.Coinbase,
  CTC: ReferrerSource.CTC,
} as const;

/**
 * Type representing partners with employee benefits
 * Derived from the constants to ensure type safety
 */
export type PartnerWithEmployeeBenefit =
  (typeof PartnersWithEmployeeBenefitConstants)[keyof typeof PartnersWithEmployeeBenefitConstants];

/**
 * Map of partner names to their email domains
 */
export const PARTNER_EMAIL_DOMAINS: Record<PartnerWithEmployeeBenefit, string> =
  {
    [ReferrerSource.Coinbase]: "coinbase.com",
    [ReferrerSource.CTC]: "cryptotaxcalculator.io",
  };
/**
 * This is used for posthog to track the source of the tax hub
 */
export enum TaxHubClient {
  MetaMask = "metamask.io",
  Coinbase = "coinbase.com",
  Kraken = "kraken.com",
  Binance = "binance.com",
}

/**
 * This is used for posthog to track the source of the event
 */
export enum EventSource {
  WebApp = "web-app",
  TaxHub = "tax-hub",
}

/**
 * Type guard that checks if a string is a valid ReferrerSource enum value.
 * Used to validate referrer sources in embedded mode.
 *
 * @param {string} source - The referrer source string to validate
 * @returns {boolean} True if the source is a valid ReferrerSource enum value, false otherwise
 */
export const isValidReferrerSource = (
  source: string,
): source is ReferrerSource => {
  return Object.values(ReferrerSource).includes(source as ReferrerSource);
};

/**
 * Type guard that checks if a partner has employee benefits
 * @param {PartnerWithEmployeeBenefit} partner - The partner to check
 * @returns {boolean} True if the partner has employee benefits, false otherwise
 */
export const havePartnerEmployeeBenefits = (
  partner: string,
): partner is PartnerWithEmployeeBenefit => {
  return Object.values(PartnersWithEmployeeBenefitConstants).includes(
    partner as PartnerWithEmployeeBenefit,
  );
};

/**
 * List of referrer sources that are allowed to bypass the complex transaction paywall
 * These sources will not be subject to the standard paywall restrictions for complex transactions
 */
const bypassComplexTxPaywallReferrers: ReferrerSource[] = [
  ReferrerSource.MetamaskEmbedded,
  ReferrerSource.Phantom,
];

/**
 * Type guard that checks if a referrer source is allowed to bypass the complex transaction paywall
 *
 * @param {ReferrerSource} source - The referrer source to check
 * @returns {boolean} True if the source is allowed to bypass the complex transaction paywall, false otherwise
 */
export const isBypassComplexTxPaywallReferrerSource = (
  source: ReferrerSource,
): source is ReferrerSource => {
  return bypassComplexTxPaywallReferrers.includes(source);
};
