import { get, put } from "~/services/core";
import { AssetType } from "~/types/enums";
import {
  type CurrencyIdentifier,
  type CurrencyIdentifierDerivative,
  type CurrencyIdentifierToken,
} from "~/types/index";

export function getUserCurrencies() {
  const path = "/currencies/user";
  return get<CurrencyIdentifier[]>(path);
}

export async function searchCurrencies(search: string) {
  return get<{ currencies: CurrencyIdentifier[] }>(
    `/currencies?search=${search}`,
  );
}

export async function markCurrencyAsNFT(
  currencyId: string,
  isMarkedAsNFT: boolean,
) {
  return put<{ isMarkedAsNFT: boolean }>(`/currencies/${currencyId}/mark-nft`, {
    isMarkedAsNFT,
  });
}

export function isCurrencyNFTForTaxPurposes(
  currency: CurrencyIdentifier,
): boolean {
  return currency.isMarkedAsNFT ?? !!currency.nftId;
}

// We make an explicit list as a shortcut, really this doesn't affect many users.
// If we invest more time into this we should look at storing this in the db.
export function isMarkAsNFTEnabledForCurrency(
  currency: string | null,
): boolean {
  if (!currency) {
    return false;
  }
  switch (currency) {
    case "ethereum":
    case "solana":
    case "avalanche-2":
    case "cardano":
    case "bitcoin":
    case "binancecoin":
    case "matic-network":
    case "near":
    case "litecoin":
    case "mantle":
    case "stellar":
    case "cosmos":
    case "crypto-com-chain":
      return false;
  }

  return true;
}

export const isCurrencyDerivative = (
  currency: CurrencyIdentifier,
): currency is CurrencyIdentifierDerivative => {
  return currency?.assetType === AssetType.Derivative;
};

export const isCurrencyToken = (
  currency: CurrencyIdentifier,
): currency is CurrencyIdentifierToken => {
  // Any currency without a type is marked as tangible currencies populated in
  // the DB do not have a type.
  if (!currency.assetType) return true;

  return currency.assetType === AssetType.Token;
};
