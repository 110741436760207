import { useCallback } from "react";

import { trackIntercomEvent } from "~/services/intercom";

import { isFeatureEnabled, useCaptureAnalytics } from "../analytics/posthog";
import { FeatureFlag, type ImportType } from "../types/enums";

interface ImportFailedMetadata {
  name: string;
  importType: ImportType;
  errorMessage: string;
  blockchain?: string;
}

export function useTrackImportFailedIntercomEvent() {
  const captureAnalytics = useCaptureAnalytics();

  return useCallback(
    ({ name, importType, errorMessage, blockchain }: ImportFailedMetadata) => {
      // Check if activeSupport feature flag is enabled
      if (!isFeatureEnabled(FeatureFlag.ActiveSupport)) {
        return;
      }

      const metadata = {
        integration: name,
        importType,
        errorMessage,
        blockchain,
      };

      // Track event for intercom
      trackIntercomEvent("import_failed", metadata);
      // Track event for posthog analytics
      captureAnalytics("intercom:import_failed", metadata);
    },
    [captureAnalytics],
  );
}
