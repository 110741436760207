import { SyncStatusPlatform } from "@ctc/types";
import groupBy from "lodash/groupBy";
import moment from "moment-timezone";
import { type useNavigate } from "react-router-dom";

import { TableHeaders } from "~/components/imports-v2/enums";
import { type SortType } from "~/components/imports-v2/types";
import { middleTrim } from "~/lib/index";
import { useFetchSavedAccountsForView } from "~/state/importsV2";
import {
  FilterOperators,
  ImportMethod,
  ImportType,
  ImportViewMode,
  IntegrationCategory,
  Links,
} from "~/types/enums";
import {
  BlockchainName,
  type CurrencyIdentifier,
  type Entity,
  type ExchangeEntity,
  type FilterQuery,
  type GroupedCurrencyIdentifier,
  type ImportOptionV2,
  isBlockchainImport,
  type SavedImport,
  type SavedImportOptionByAccount,
  type SavedKeyImport,
  type SavedWalletImport,
} from "~/types/index";

const LENGTH_THRESHOLD = 7;
const MAX_IMPORTS_FOR_BULK_SYNC = 100;

export function shouldCensorIntegrationName(
  importOption: SavedImportOptionByAccount | ImportOptionV2,
) {
  return (
    isRawAddress(importOption) ||
    importOption.category === IntegrationCategory.Manual
  );
}

function isRawAddress(
  importOption: SavedImportOptionByAccount | ImportOptionV2,
) {
  return (
    isBlockchainImport(importOption) && importOption.id === importOption.name
  );
}

export function getName(
  importOption: SavedImportOptionByAccount,
  isMobile: boolean,
  maxLength?: number,
) {
  const maxNameLength = maxLength || LENGTH_THRESHOLD;
  if (isRawAddress(importOption)) {
    return middleTrim(importOption.name);
  }

  if (importOption.name.length > maxNameLength && isMobile) {
    return `${importOption.name.slice(0, 5)}...`;
  }

  return importOption.name;
}

// compare dateStringA and dateStringB for ascending order
export const compareDateString = (
  dateStringA: string | null,
  dateStringB: string | null,
) => {
  const dateA = dateStringA ? new Date(dateStringA) : null;
  const dateB = dateStringB ? new Date(dateStringB) : null;
  if (dateA && dateB) {
    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
  } else if (!dateA && dateB) {
    return -1;
  } else if (dateA && !dateB) {
    return 1;
  }
  return 0;
};

export const onlyHasImportedFiles = (
  importOption: SavedImportOptionByAccount,
) => {
  return (
    !!importOption.files.length &&
    !importOption.keys.length &&
    !importOption.oauths.length &&
    !importOption.wallets.length
  );
};

export const sortByField = (
  sortOptions: SortType,
  a: SavedImportOptionByAccount,
  b: SavedImportOptionByAccount,
  showSpamTransactions = true,
): number => {
  const { ascending, field } = sortOptions;
  const primarySortOrder = ascending ? 1 : -1;

  const getTxCount = (account: SavedImportOptionByAccount): number => {
    const { totalTxCount = 0, spamTxCount = 0 } = account;
    return showSpamTransactions ? totalTxCount : totalTxCount - spamTxCount;
  };

  // First compare by the selected field
  let primarySort = 0;
  switch (field) {
    case TableHeaders.Account:
      primarySort = a.name.localeCompare(b.name);
      break;
    case TableHeaders.Synced:
      primarySort = compareDateString(a.lastSyncComplete, b.lastSyncComplete);
      break;
    case TableHeaders.Balance:
      primarySort = a.value - b.value;
      break;
    case TableHeaders.Type:
      primarySort = a.category.localeCompare(b.category);
      break;
    case TableHeaders.Tx:
      primarySort = getTxCount(a) - getTxCount(b);
      break;
    case TableHeaders.Assets:
      primarySort = a.assets.length - b.assets.length;
      break;
  }

  // Apply primary sort order
  primarySort *= primarySortOrder;

  // If primary sort results in equality and it's not Balance field,
  // use balance as secondary sort (always descending)
  if (primarySort === 0 && field !== TableHeaders.Balance) {
    return b.value - a.value; // Secondary sort is always descending
  }

  return primarySort;
};

export const hasAvailableSyncMethods = (
  importOption: SavedImportOptionByAccount,
) => {
  const totalSyncs =
    importOption.keys.length +
    importOption.oauths.length +
    importOption.wallets.length;
  return totalSyncs > 0 && MAX_IMPORTS_FOR_BULK_SYNC <= 100;
};

export const isSyncSuccess = (importOption: SavedImportOptionByAccount) => {
  return importOption.syncStatus === SyncStatusPlatform.Success;
};

export const isSyncPendingOrQueued = (
  importOption: SavedImportOptionByAccount,
) => {
  return isSyncPending(importOption) || isSyncQueued(importOption);
};

const isSyncPending = (importOption: SavedImportOptionByAccount) => {
  return importOption.syncStatus === SyncStatusPlatform.Pending;
};

const isSyncQueued = (importOption: SavedImportOptionByAccount) => {
  return importOption.syncStatus === SyncStatusPlatform.Queued;
};

export const getTrimmedStack = <T>(
  allItems: T[],
  logosShown = 5,
): {
  shownItems: T[];
  otherItemsCount: string | number;
} => {
  const shownItems = allItems.slice(0, logosShown);

  let otherItemsCount: string | number = allItems.slice(logosShown).length;

  if (otherItemsCount > 1000) {
    // e.g 1000 -> "1K", 10000 -> "10K"
    otherItemsCount = new Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(otherItemsCount);
  }

  return {
    shownItems,
    otherItemsCount,
  };
};

export const getNFTStack = (
  nft: GroupedCurrencyIdentifier,
  max: number,
): {
  shownCurrencies: CurrencyIdentifier[];
  otherCurrenciesCount: string | number;
} => {
  const totalLength = nft.imageURLs?.length ?? 0;
  if (totalLength <= max) {
    return {
      shownCurrencies:
        nft.imageURLs?.map((url) => ({
          ...nft,
          externalImageUrl: url,
        })) ?? [],
      otherCurrenciesCount: 0,
    };
  }

  const imageURLs = nft.imageURLs?.slice(0, max - 1) ?? [];
  let otherCurrenciesCount: string | number =
    nft.imageURLs?.slice(max).length || 0;

  if (otherCurrenciesCount > 1000) {
    // e.g 1000 -> "1K", 10000 -> "10K"
    otherCurrenciesCount = new Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(otherCurrenciesCount);
  }

  return {
    shownCurrencies: imageURLs.map((url) => ({
      ...nft,
      externalImageUrl: url,
    })),
    otherCurrenciesCount,
  };
};

export const getLastTimeSync = (
  timezone: string,
  lastSyncTime: string | null,
): string => {
  if (!lastSyncTime) {
    return "<1m";
  }

  const timestamp = moment.tz(new Date(), timezone);

  const minutes = timestamp.diff(lastSyncTime, "minutes");

  if (minutes < 60) {
    return `${minutes === 0 ? `<1` : minutes}m`;
  }

  const hours = timestamp.diff(lastSyncTime, "hours");

  if (hours < 24) {
    return `${hours}h`;
  }

  return `${timestamp.diff(lastSyncTime, "days")}d`;
};

// Formats a list of names into a string with the given final separator.
// use for something like "Alice, Bob, and Michael"
export function formatNames(names: string[], finalSeparator: string): string {
  switch (names.length) {
    case 0:
      return "";
    case 1:
      return names[0];
    case 2:
      return names.join(` ${finalSeparator} `);
    default:
      return `${names.slice(0, -1).join(", ")} ${finalSeparator} ${names.at(
        -1,
      )}`;
  }
}

export function getAllImports(
  account: SavedImportOptionByAccount,
): SavedImport[] {
  return [
    ...account.wallets,
    ...account.keys,
    ...account.oauths,
    ...account.files,
  ];
}

export function haveAllImportsFailed(
  account: SavedImportOptionByAccount,
): boolean {
  return getAllImports(account).every(
    (importOption) => importOption.status === SyncStatusPlatform.Fail,
  );
}

export function getDisplayName(
  option: SavedImport,
  account: SavedImportOptionByAccount,
): string {
  const fallbackName = `${account.name}`;
  if (isSavedChainImport(option)) {
    return (
      BlockchainName[option.blockchain] ||
      option.name ||
      `${fallbackName} chain}`
    );
  }

  if (isSavedKeyImport(option)) {
    return (
      option.name || `${fallbackName} API${option.key ? ` ${option.key}` : ""}`
    );
  }

  if (account.oauths.find((oauth) => oauth.id === option.id)) {
    return option.name || `${fallbackName} OAuth`;
  }

  if (account.files.find((file) => file.id === option.id)) {
    return option.name || `${fallbackName} CSV`;
  }

  return fallbackName;
}

export function isSavedChainImport(
  option: SavedImport,
): option is SavedWalletImport {
  const key: keyof SavedWalletImport = "blockchain";
  return key in option;
}

export function isSavedKeyImport(
  option: SavedImport,
): option is SavedKeyImport {
  const key: keyof SavedKeyImport = "key";
  return key in option;
}

export function getEntityOrAccountSource(
  importOption: SavedImportOptionByAccount,
  entities: Entity[],
) {
  const entity = entities.find((entity) => {
    const exchange = entity as ExchangeEntity;
    if (exchange.ref === importOption.id) {
      return entity;
    }
  });

  if (entity) {
    return {
      ...importOption,
      id: entity._id,
      name: entity.displayName,
    };
  }

  return importOption;
}

export function openDrawer(
  navigate: ReturnType<typeof useNavigate>,
  account: string,
) {
  navigate(`${Links.Imports}/${account}`);
}

export function closeDrawer(navigate: ReturnType<typeof useNavigate>) {
  navigate(Links.Imports);
}

export function isSyncableImport(option: SavedImport): boolean {
  return [ImportType.API, ImportType.Wallet, ImportType.OAuth].includes(
    option.importType,
  );
}

export function getSavedAccountFilterRules(
  account: SavedImportOptionByAccount,
  entities: Entity[],
): FilterQuery[] {
  const allSourceFilter = getEntityOrAccountSource(account, entities);
  return [
    {
      type: FilterOperators.Source,
      value: [
        ...account.wallets.map((c) => c.id),
        ...(allSourceFilter ? [allSourceFilter.id] : []),
      ],
    },
  ];
}

export function createManualImportOption(
  importOption: SavedImportOptionByAccount,
): ImportOptionV2 {
  return {
    id: importOption.id,
    name: importOption.name,
    category: importOption.category,
    importMethods: [
      {
        type: ImportMethod.CSV,
        etaMs: null,
        avgMs: null,
        p95Ms: null,
      },
    ],
    manual: true,
  };
}

export const useImportV2Metadata = () => {
  const savedAccounts = useFetchSavedAccountsForView(ImportViewMode.ByAddress)
    ?.data?.all;

  if (!savedAccounts) return {};

  const importGroups = groupBy(savedAccounts, (account) => account.syncStatus);
  const importCategory = groupBy(savedAccounts, (account) => account.category);

  return {
    importFailedCount: importGroups[SyncStatusPlatform.Fail]?.length || 0,
    importPendingCount: importGroups[SyncStatusPlatform.Pending]?.length || 0,
    importQueuedCount: importGroups[SyncStatusPlatform.Queued]?.length || 0,
    importSucceedCount: importGroups[SyncStatusPlatform.Success]?.length || 0,
    importedBlockchainCount:
      importCategory[IntegrationCategory.Blockchain]?.length || 0,
    importedCentraliseExchangeCount:
      importCategory[IntegrationCategory.CEX]?.length || 0,
    importedDecentraliseExchangeCount:
      importCategory[IntegrationCategory.DEX]?.length || 0,
    importedWalletCount:
      importCategory[IntegrationCategory.Wallet]?.length || 0,
    importedServiceCount:
      importCategory[IntegrationCategory.Service]?.length || 0,
    importedManualCount:
      importCategory[IntegrationCategory.Manual]?.length || 0,
    totalAccountsCount: savedAccounts.length,
  };
};

/**
 * Type guard to check if a currency identifier is a grouped currency identifier
 * @param currency The currency identifier to check
 * @returns True if the currency is a grouped currency identifier
 */
export function isGroupedCurrencyIdentifier(
  currency: CurrencyIdentifier,
): currency is GroupedCurrencyIdentifier {
  return "nftIds" in currency;
}
