import { PersonAdd } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { type AccountantInviteFormValues } from "~/components/settings-modal/views/team/types";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { devices } from "~/components/ui/theme/legacy";
import { displayMessage } from "~/components/ui/Toaster";
import { EMAIL_VALIDATION_REGEX } from "~/components/user/validators";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useInviteTeamMemberMutation } from "~/state/invites";
import { DisplayMessage } from "~/types/enums";

export function InviteTeamMember() {
  const lang = useLang();
  const { tokens } = useDesign();
  const inviteTeamMemberMutation = useInviteTeamMemberMutation();
  const [inviting, setInviting] = useState(false);
  const footerRef = useRef<HTMLDivElement>(null);
  const isWide = useMediaQuery(devices.tablet);
  const { handleSubmit, control, reset, clearErrors } =
    useForm<AccountantInviteFormValues>({
      mode: "onBlur",
      defaultValues: {
        toName: "",
        toEmail: "",
      },
    });

  async function onSubmit(formData: AccountantInviteFormValues) {
    await inviteTeamMemberMutation.mutateAsync(formData);
    displayMessage({
      message: lang.collaborators.invitationSent,
      type: DisplayMessage.Success,
    });

    reset();
    setInviting(false);
  }

  useEffect(() => {
    if (inviting && isWide) {
      footerRef.current?.scrollIntoView();
    }
  }, [inviting, isWide]);

  return (
    <>
      {inviting ? (
        <form>
          <Box display="flex" flexDirection="column" gap="1rem">
            <Typography variant="Metropolis/Header/H5" color={tokens.text.high}>
              {lang.settings.team.inviteTeamMember}
            </Typography>
            <Controller
              control={control}
              name="toName"
              rules={{ required: lang.accountant.invite.nameRequired }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  type="text"
                  label={lang.collaborators.name}
                  placeholder={lang.collaborators.teamMemberName}
                  InputLabelProps={{ required: true }}
                  error={invalid}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
            <Controller
              control={control}
              name="toEmail"
              rules={{
                required: lang.accountant.invite.emailRequired,
                pattern: {
                  value: EMAIL_VALIDATION_REGEX,
                  message: lang.accountant.invite.emailInvalid,
                },
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  type="text"
                  label={lang.collaborators.email}
                  placeholder={lang.collaborators.teamMemberEmail}
                  InputLabelProps={{ required: true }}
                  error={invalid}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
            <Box display="flex" gap="0.5rem">
              <TertiaryButton
                size="small"
                onClick={() => {
                  setInviting(false);
                  reset();
                  clearErrors();
                }}
              >
                {lang.cancel}
              </TertiaryButton>
              <PrimaryButton
                size="small"
                onClick={handleSubmit(onSubmit)}
                disabled={inviteTeamMemberMutation.isPending}
                startIcon={
                  inviteTeamMemberMutation.isPending ? (
                    <CircularProgress size="1.125rem" />
                  ) : null
                }
              >
                {lang.settings.team.invite}
              </PrimaryButton>
            </Box>
          </Box>
        </form>
      ) : (
        <TertiaryButton
          size="small"
          startIcon={<PersonAdd />}
          onClick={() => {
            setInviting(true);
          }}
        >
          {lang.settings.team.inviteTeamMember}
        </TertiaryButton>
      )}
      <Box ref={footerRef} />
    </>
  );
}
