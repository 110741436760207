import uniqBy from "lodash/uniqBy";
import { useMemo } from "react";

import { useGetFilterOptionsQuery } from "~/state/actions";
import { useFetchSavedAccounts } from "~/state/importsV2";
import { type CustomExchanges } from "~/types/enums";
import { nonEntityableAddresses } from "~/types/index";

export function useGetUnidentifiedAddresses() {
  const filterOptionsQuery = useGetFilterOptionsQuery();
  const savedAccounts = useFetchSavedAccounts();
  const uniqueInteractedAddressed = useMemo(
    () =>
      uniqBy(
        getNonEntitiedAddresses(filterOptionsQuery.data, savedAccounts.data),
        "label",
      ),
    [filterOptionsQuery.data, savedAccounts.data],
  );
  return {
    ...filterOptionsQuery,
    data: uniqueInteractedAddressed,
  };
}

function getNonEntitiedAddresses(
  // It thinks the ['data'] is an enum, so disabling
  data: ReturnType<typeof useGetFilterOptionsQuery>["data"] | undefined,
  savedAccounts: ReturnType<typeof useFetchSavedAccounts>["data"] | undefined,
) {
  if (!data) {
    return [];
  }
  return [...(data.to ?? []), ...(data.from ?? [])].filter(
    (filterOption) =>
      // not already an entity
      !filterOption.entity &&
      // not some restricted address like "bank"
      !nonEntityableAddresses.includes(filterOption.label as CustomExchanges) &&
      // not already imported
      !savedAccounts?.all.some((account) =>
        account.wallets.some(
          (wallet) =>
            wallet.address.toLowerCase() === filterOption.label.toLowerCase(),
        ),
      ),
  );
}
