import { ReferrerSource, SyncStatusPlatform } from "@ctc/types";
import { ChevronRight, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Chip,
  Divider,
  Skeleton,
  type SxProps,
  type Theme,
  Tooltip,
  Typography,
  type TypographyProps,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useContext, useState } from "react";
import styled from "styled-components/macro";
import { isSyncPendingOrQueued } from "~/components/imports-v2/helpers";

import { CapitalGainsCategories } from "~/components/reconciliation/enums";
import { useGetTotalGainsValue } from "~/components/report/components/summary-box/helpers/useGetTotalGainsValue";
import { TipsBanner } from "~/components/report/components/summary-box/TipsBanner";
import { PaywalledNumberBox } from "~/components/report/PaywalledNumberBox";
import { ReportFilter } from "~/components/report/ReportFilterContext";
import { useIsMobile } from "~/components/ui/hooks";
import { sizes } from "~/components/ui/theme/legacy";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { FYContext } from "~/contexts/FYContext";
import { useScreenedFiatValue } from "~/hooks/useScreenedFiatValue";
import { useDesign } from "~/hooks/useTheme";
import { minus, plus } from "~/lib/index";
import { useCountry, useHighestRankingPlanUser, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { useGetSavedAccounts } from "~/state/importsV2";
import { useReconciliationSteps } from "~/state/reconciliation";
import { useUserSyncStatus } from "~/state/sync";
import { NormalReportType } from "~/types/enums";
import {
  type ActionType,
  type EmptySummarySectionData,
  isBusinessPaidPlanType,
  type PayloadZeroCostBasis,
  type ReportSummary,
  shouldReplaceCGTWithPNL,
  shouldReplaceCGTWithPortfolioValue,
  type SummarySectionData,
  type SummarySectionTypeData,
} from "~/types/index";

type LangKeyMap = Partial<{
  [key in NormalReportType | CapitalGainsCategories]: {
    titleLang: string;
    tooltipLang: string;
  };
}>;

const reportSummaryLangMapping: LangKeyMap = {
  [NormalReportType.Income]: {
    titleLang: "ordinaryIncome",
    tooltipLang: "miscellaneousIncome",
  },
  [NormalReportType.Expense]: {
    titleLang: "expenses",
    tooltipLang: "miscellaneousExpenses",
  },
  [NormalReportType.TradingPnl]: {
    titleLang: "derivativeTradingIncome",
    tooltipLang: "derivativeTradingIncome",
  },
  [CapitalGainsCategories.Gains]: {
    titleLang: "gains",
    tooltipLang: "totalGains",
  },
  [CapitalGainsCategories.LongTermGains]: {
    titleLang: "longTermCapitalGains",
    tooltipLang: "longTermThreshold",
  },
  [CapitalGainsCategories.ShortTermGains]: {
    titleLang: "shortTermCapitalGains",
    tooltipLang: "totalCapitalGains",
  },
  [CapitalGainsCategories.ShortTermLosses]: {
    titleLang: "shortTermCapitalLosses",
    tooltipLang: "shortTermCapitalLosses",
  },
  [CapitalGainsCategories.LongTermLosses]: {
    titleLang: "longTermCapitalLosses",
    tooltipLang: "longTermCapitalLosses",
  },
  [CapitalGainsCategories.Losses]: {
    titleLang: "totalLosses",
    tooltipLang: "capitalLosses",
  },
};

export const getCapitalGains = ({
  shortTermGains,
  longTermGains,
  totalLosses,
}: ReportSummary["capitalGains"]) =>
  minus(plus(shortTermGains, longTermGains), totalLosses);

function FiatValue({
  amount,
  variant,
  sx,
}: {
  amount: number;
  variant: TypographyProps["variant"];
  sx?: SxProps<Theme>;
}) {
  const { tokens } = useDesign();
  const displayScreenedFiatValue = useScreenedFiatValue();
  const syncStatus = useUserSyncStatus();
  const isReportRefreshing = syncStatus === SyncStatusPlatform.Pending;

  return (
    <PaywalledNumberBox isLoading={isReportRefreshing} small>
      <Typography variant={variant} sx={{ ...sx }}>
        {displayScreenedFiatValue(amount)}
      </Typography>
    </PaywalledNumberBox>
  );
}

function SummarySectionCategoryType({
  type,
  count,
  amount,
  category,
  report,
}: {
  type: ActionType;
  count: number;
  amount: number;
  report: NormalReportType;
  category: CapitalGainsCategories;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const Icon = TradeIcons[type] ?? (() => null);
  const [hover, setHover] = useState(false);
  const reportFilter = useContext(ReportFilter);
  const isTablet = useMediaQuery(`(max-width: ${sizes.tabletL} )`); // Same breakpoint as nav bar mobile
  const onClick = () => {
    reportFilter?.setReportType(report);
    reportFilter?.setCategory(category);

    reportFilter?.setTradeType(type);
    reportFilter?.setShowModal(true);
  };
  const { mui } = useDesign();
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "space-between",
        color: tokens.text.low,
        padding: "0.375rem 0",
        borderRadius: "0.5rem",
        ":hover": {
          background: tokens.button.neutral.hover,
          color: tokens.text.high,
          cursor: isTablet ? "pointer" : "default",
        },
        height: "2.25rem",
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        if (isTablet) {
          onClick();
          return;
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="1.75rem"
          width="1.75rem"
          borderRadius="1.75rem"
          sx={{ backgroundColor: tokens.background.accent.purple.lowest }}
        >
          <Icon fontSize="small" sx={{ color: tokens.button.brand.default }} />
        </Box>
        <Typography
          variant="Metropolis/Body/Regular"
          sx={{ color: hover ? tokens.text.high : tokens.text.low }}
        >
          {lang.tradeType[type]}
        </Typography>
        <Chip
          sx={{
            background: tokens.background.accent.neutral.low,
            color: hover ? tokens.text.high : tokens.text.low,
          }}
          size="small"
          label={`${count} tx`}
        />
        {isTablet ? null : (
          <StyledTextButton
            onClick={onClick}
            sx={{
              display: hover ? "block" : "none",
            }}
            size="small"
          >
            {lang.imports.view}
          </StyledTextButton>
        )}
      </Box>
      <FiatValue
        amount={amount}
        variant="IBM Plex Mono/Body/Bold"
        sx={{
          color: hover ? tokens.text.high : tokens.text.low,
        }}
      />
    </Box>
  );
}

function SummarySectionCategory({
  summaryData,
  report,
}: {
  summaryData: SummarySectionData | EmptySummarySectionData;
  report: NormalReportType;
}) {
  const { tokens, mui } = useDesign();
  const lang = useLang();
  const [isOpen, setIsOpen] = useState(false);
  const taxSettings = useTaxSettings();
  const { amount, tradeTypes, category, reportType } = summaryData;
  const toggleOpen = () => {
    if (amount !== 0) {
      setIsOpen((open) => !open);
    }
  };

  const reportSummaryLangLookup = (
    key: NormalReportType | CapitalGainsCategories | undefined,
  ): { title: string; tooltip: string } => {
    if (!key) return { title: "", tooltip: "" };

    const titleLangLookup: Record<string, string> = lang.report.headings;
    const tooltipLang: any = lang.report.tooltips;

    const title =
      titleLangLookup[reportSummaryLangMapping[key]?.titleLang || ""];

    const tooltipKey = reportSummaryLangMapping[key]?.tooltipLang || "";
    const tooltip =
      tooltipKey && typeof tooltipLang[tooltipKey] !== "undefined"
        ? typeof tooltipLang[tooltipKey] === "string"
          ? tooltipLang[tooltipKey]
          : tooltipLang[tooltipKey]({
              longTermThreshold: taxSettings?.longTermThreshold,
            })
        : "";

    return { title, tooltip };
  };

  const getBackground = () => {
    return isOpen ? tokens.elevation.low : tokens.elevation.default;
  };

  const getHoverBackground = () => {
    return tokens.elevation.low;
  };

  return (
    <Box
      sx={{
        background: getBackground(),
        color:
          amount === 0
            ? tokens.text.disabled
            : isOpen
              ? tokens.text.high
              : tokens.text.low,
        p: "0.75rem 0.75rem 0.75rem 0",
        borderRadius: "0.25rem",
        ":hover": {
          background: amount === 0 ? "inherit" : getHoverBackground(),
          color: amount === 0 ? tokens.text.disabled : tokens.text.high,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "inherit",
          alignItems: "center",
          mb: isOpen ? "0.75rem" : 0,
          cursor: amount === 0 ? "default" : "pointer",
        }}
        onClick={toggleOpen}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <ChevronRight
            sx={{
              fill: amount === 0 ? tokens.icon.disabled : "default",
              transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.2s",
            }}
          />
          {summaryData.amount === undefined && <Skeleton width="10rem" />}
          <Typography variant="Metropolis/Body/Bold" sx={{ color: "inherit" }}>
            {reportSummaryLangLookup(category || reportType).title}
          </Typography>
          <Tooltip
            title={reportSummaryLangLookup(category || reportType).tooltip}
            placement="top"
          >
            <InfoOutlined
              sx={{ width: "1rem", height: "1rem", color: "inherit" }}
            />
          </Tooltip>
        </Box>
        {amount !== undefined ? (
          <FiatValue
            amount={amount}
            variant="IBM Plex Mono/Body/Bold"
            sx={{ color: "inherit" }}
          />
        ) : (
          <Skeleton width="10rem" />
        )}
      </Box>
      {isOpen ? (
        <Box paddingLeft="1.5rem">
          {tradeTypes.map(({ type, count, amount }) => (
            <SummarySectionCategoryType
              key={`${category}-${type}`}
              type={type}
              count={count}
              amount={amount}
              category={category || CapitalGainsCategories.All}
              report={report}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
}

function SummarySection({
  amount,
  categories,
  sectionTitle,
  defaultReport,
}: SummarySectionTypeData & {
  sectionTitle: string;
  defaultReport?: NormalReportType;
}) {
  return (
    <Box key={sectionTitle}>
      <Box
        display="flex"
        p="0.25rem 0.75rem 0.25rem 0"
        gap="0.5rem"
        alignItems="flex-start"
        alignSelf="stretch"
        mt="1rem"
      >
        <Typography variant="Metropolis/Header/H5" flex="1 0 0">
          {sectionTitle}
        </Typography>
        {amount !== undefined ? (
          <FiatValue amount={amount} variant="IBM Plex Mono/Header/H5" />
        ) : (
          <Skeleton height="2rem" width="10rem" />
        )}
      </Box>
      <StyledDivider />
      {categories !== undefined ? (
        Object.entries(categories).map(([report, summaryData]) => (
          <>
            <SummarySectionCategory
              key={report}
              summaryData={summaryData}
              report={defaultReport || (report as NormalReportType)}
            />
            <StyledDivider />
          </>
        ))
      ) : (
        <Skeleton height="5rem" />
      )}
    </Box>
  );
}

const TotalGains = ({
  summaryData,
}: {
  summaryData: ReportSummary | undefined;
}) => {
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const savedAccounts = useGetSavedAccounts();
  const syncStatus = useUserSyncStatus();
  const isReportRefreshing = syncStatus === SyncStatusPlatform.Pending;
  const user = useUser();
  const referrerSource = user?.referrerSource;
  const isMetamaskFlow = referrerSource === ReferrerSource.MetamaskEmbedded;
  const isAnySyncPendingOrQueued = savedAccounts.some(isSyncPendingOrQueued);

  const getTotalGainsValue = useGetTotalGainsValue();

  const shouldShowSkeleton =
    (isMetamaskFlow && isAnySyncPendingOrQueued) ||
    isReportRefreshing ||
    !summaryData;
  const totalGains = (
    <Typography
      variant={isMobile ? "IBM Plex Mono/Header/H3" : "IBM Plex Mono/Header/H1"}
      data-ctc="totalGains"
    >
      {shouldShowSkeleton ? (
        <Skeleton width="10rem" />
      ) : (
        getTotalGainsValue(summaryData)
      )}
    </Typography>
  );

  // Metamask specifically wants the total gains number to not be paywalled when in embedded mode
  if (isMetamaskFlow) {
    return totalGains;
  }

  return (
    <PaywalledNumberBox isLoading={isReportRefreshing || !summaryData}>
      {totalGains}
    </PaywalledNumberBox>
  );
};

export function SummaryBox({
  summaryData,
  isLoading,
  zcbForPeriod,
}: {
  summaryData: ReportSummary | undefined;
  isLoading: boolean;
  zcbForPeriod: PayloadZeroCostBasis;
}) {
  const theme = useTheme();
  const { tokens } = useDesign();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm")); // Is sm or smaller.
  const isXSmall = useMediaQuery(theme.breakpoints.down("xs")); // Is xs.
  const lang = useLang();
  const country = useCountry();
  const reconciliationSteps = useReconciliationSteps({
    includeCurrentlyHiddenSteps: false,
  });
  const isMobile = useIsMobile();
  const { timeframe } = useContext(FYContext);
  const zeroCostTotal = zcbForPeriod.reduce(
    (total, line) => plus(total, line.gain),
    0,
  );
  const highestRankingPlanUser = useHighestRankingPlanUser();

  const shouldShowTipsBanner =
    reconciliationSteps > 0 &&
    zeroCostTotal > 0 &&
    !isBusinessPaidPlanType(highestRankingPlanUser?.paidPlan ?? "");

  // chip only applies to portfolio value and if they're not on a custom view
  const chipLabel =
    shouldReplaceCGTWithPortfolioValue(country) && !timeframe?.custom
      ? lang.report.fyChip({
          timeframe: `1st Jan ${timeframe?.year}`,
        })
      : "";

  const showChip = !!chipLabel;

  const getReportTitleDetails = () => {
    if (shouldReplaceCGTWithPNL(country)) {
      return {
        text: lang.report.totalCapitalGainsNoCGT,
        tooltip: undefined,
      };
    } else if (shouldReplaceCGTWithPortfolioValue(country)) {
      return {
        text: lang.report.taxablePortfolioValue,
        tooltip: undefined,
      };
    }
    return {
      text: lang.report.totalGains,
      tooltip: lang.report.tooltips.totalGains,
    };
  };

  const { text: reportTitle, tooltip: reportTitleTooltip } =
    getReportTitleDetails();

  return (
    <Box flex="1">
      <Box
        mb="0.5rem"
        display="flex"
        justifyContent="space-between"
        gap="1rem"
        position="relative"
        flexDirection={{ xs: "column", sm: "row" }}
        pr="0.75rem"
      >
        <Box display="flex" gap="0.5rem" alignItems="center">
          <Typography
            variant={isMobile ? "Metropolis/Header/H3" : "Metropolis/Header/H1"}
            sx={{
              flexShrink: 0,
              wordWrap: isSmall ? "normal" : undefined,
              maxWidth: isXSmall ? "12rem" : isSmall ? "15rem" : undefined,
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {reportTitle}
            {reportTitleTooltip ? (
              <Tooltip title={reportTitleTooltip} placement="top">
                <InfoOutlined
                  sx={{ width: "1.5rem", height: "1.5rem", color: "inherit" }}
                />
              </Tooltip>
            ) : null}
          </Typography>
          {showChip ? (
            <Chip
              sx={{
                background: tokens.background.accent.neutral.low,
                color: tokens.text.low,
              }}
              size="small"
              label={chipLabel}
              className="tour-paywall"
            />
          ) : null}
        </Box>

        <TotalGains summaryData={summaryData} />
      </Box>

      {shouldShowTipsBanner ? (
        <TipsBanner
          isLoading={isLoading}
          savingsOpportunityValue={zeroCostTotal}
        />
      ) : null}

      <Box style={{ color: tokens.text.default }} position="relative" mt="1rem">
        <SummarySection
          amount={summaryData?.summarySections.totalCapitalGains.amount}
          categories={
            summaryData?.summarySections.totalCapitalGains.categories ?? {
              longTermCapitalGains: {
                amount: undefined,
                tradeTypes: [],
              },
              shortTermCapitalGains: {
                amount: undefined,
                tradeTypes: [],
              },
              totalLosses: {
                amount: undefined,
                tradeTypes: [],
              },
            }
          }
          defaultReport={NormalReportType.CapitalGains}
          sectionTitle={lang.report.headings.totalCapitalGains}
        />
        <SummarySection
          amount={summaryData?.summarySections.totalIncome.amount}
          categories={
            summaryData?.summarySections.totalIncome.categories ?? {
              income: {
                amount: undefined,
                tradeTypes: [],
              },
              "trading-pnl": {
                amount: undefined,
                tradeTypes: [],
              },
            }
          }
          sectionTitle={lang.report.headings.totalIncome}
        />
        <SummarySection
          amount={summaryData?.summarySections.totalExpenses.amount}
          categories={
            summaryData?.summarySections.totalExpenses.categories ?? {
              expense: {
                amount: undefined,
                tradeTypes: [],
              },
            }
          }
          sectionTitle={lang.report.headings.totalExpenses}
        />
      </Box>
    </Box>
  );
}

const StyledDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.tokens.border.neutral.low};
`;

const StyledTextButton = styled(TextButton)`
  && {
    ${({ theme }) => ({ ...theme.mui.typography['Metropolis/Caption/Medium/Regular'] })};
    padding: 0.25rem 0.5rem;
    color: ${({ theme }) => theme.tokens.text.brand};
    min-width: 0;
  }
`;
