import { useMutation, useQueryClient } from "@tanstack/react-query";

import { HttpError } from "~/services/core";
import * as featureFlagsApi from "~/services/featureFlags";
import { type FeatureFlag } from "~/types/enums";

export const featureFlagKeys = {
  all: () => ["featureFlags"] as const,
  list: () => [...featureFlagKeys.all(), "list"] as const,
};

/**
 * Hook to create or update a feature flag in BE
 */
export function useCreateOrUpdateBEFeatureFlagMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, HttpError, { flag: FeatureFlag; value: string }>({
    mutationFn: async ({ flag, value }) => {
      const res = await featureFlagsApi.createOrUpdateFeatureFlag(flag, value);
      if (res.error) {
        throw new HttpError(res, ["createOrUpdateFeatureFlag"]);
      }
    },
    onSuccess: () => {
      // Invalidate the feature flags list query to trigger a refetch
      queryClient.invalidateQueries({ queryKey: featureFlagKeys.list() });
    },
  });
}

/**
 * Hook to remove a feature flag in BE
 */
export function useRemoveBEFeatureFlagMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, HttpError, FeatureFlag>({
    mutationFn: async (flag) => {
      const res = await featureFlagsApi.removeFeatureFlag(flag);
      if (res.error) {
        throw new HttpError(res, ["removeFeatureFlag"]);
      }
    },
    onSuccess: () => {
      // Invalidate the feature flags list query to trigger a refetch
      queryClient.invalidateQueries({ queryKey: featureFlagKeys.list() });
    },
  });
}
