import { useQuery, useQueryClient } from "@tanstack/react-query";
import isNil from "lodash/isNil";
import { useMemo } from "react";

import { useDebounce } from "~/hooks/useDebounce";
import { HttpError } from "~/services/core";
import * as currencyAPI from "~/services/currencies";

export const currencyKeys = {
  all: () => ["currencies"] as const,

  user: () => [...currencyKeys.all(), "user"] as const,
  allCurrencies: () => [...currencyKeys.all(), "allCurrencies"] as const,

  search: (search: string) =>
    [...currencyKeys.allCurrencies(), { search }] as const,
};

/**
 * Use when we only care about the 'users' accessible currencies
 * e.g. what they have actually imported via their transactions
 */
export const useUserCurrenciesQuery = () => {
  return useQuery({
    queryKey: currencyKeys.user(),
    queryFn: async () => {
      const res = await currencyAPI.getUserCurrencies();
      if (res.error) {
        throw new HttpError(res, ["getUserCurrencies"]);
      }

      return res.data;
    },
  });
};

export const useCurrencySearchQuery = (search: string | undefined) => {
  const queryClient = useQueryClient();
  const debouncedSearchTerm = useDebounce<string | undefined>(search, 500);

  const hasPreexistingRes = !isNil(
    queryClient.getQueryData(currencyKeys.search(search ?? "")),
  );

  return useQuery({
    // Check if we're already listening to this search, else used the debounced term, else use empty while waiting for the debounced term
    queryKey: currencyKeys.search(
      (hasPreexistingRes ? search : debouncedSearchTerm) ?? "",
    ),
    queryFn: async () => {
      const res = await currencyAPI.searchCurrencies(debouncedSearchTerm ?? "");

      if (res.error) {
        throw new HttpError(res, ["currencySearch"]);
      }

      return {
        search,
        ...res.data,
      };
    },
    enabled: Boolean(debouncedSearchTerm?.length),
  });
};

export const useCurrencySearchResults = (input: string | undefined) => {
  const userCurrenciesQuery = useUserCurrenciesQuery();
  const searchQuery = useCurrencySearchQuery(input);

  const queries = [userCurrenciesQuery, searchQuery];

  const currencies = useMemo(() => {
    const currencies = (userCurrenciesQuery.data ?? []).concat(
      searchQuery.data?.currencies ?? [],
    );

    const idSet = new Set<string>();
    const dedupedCurrencies = currencies.filter((c) => {
      if (!idSet.has(c.id)) {
        idSet.add(c.id);
        return true;
      }
      return false;
    });

    return dedupedCurrencies;
  }, [userCurrenciesQuery.data, searchQuery.data?.currencies]);

  const isLoading = queries.some(
    (result) => !result?.data && result?.status === "pending",
  );
  const isFetching = queries.some((result) => result?.isFetching);

  const isSearching =
    !!input && input.length > 0 && searchQuery.data?.search !== input;

  return {
    data: {
      search: searchQuery.data?.search,
      currencies,
    },
    isLoading,
    isFetching,
    isSearching,
    queries,
  };
};
