import { SyncStatusPlatform } from "@ctc/types";
import {
  Cached,
  CheckCircleOutline,
  Error,
  HourglassEmpty,
} from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";

import { getLastTimeSync } from "~/components/imports-v2/helpers";
import { useIsMobile } from "~/components/ui/hooks";

import { useInterval } from "~/hooks/useInterval";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";

const Chip = styled(Box)`
  && {
    display: flex;
    gap: 0.25rem;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
    align-items: center;
    white-space: nowrap;
  }
`;

const iconStyle = {
  width: "1rem",
  height: "1rem",
  flexShrink: 0,
};

/**
 * Typography for the sync chip.
 * Wraps text if its too long (especially for other languages like German)
 */
const ChipTypography = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & Omit<React.ComponentProps<typeof Typography>, "variant">) => (
  <Typography
    variant="Metropolis/Caption/Medium/Regular"
    data-uncensored="true"
    sx={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }}
    {...props}
  >
    {children}
  </Typography>
);

export const SyncChip = ({
  syncStatus,
  lastSyncTime,
  isCsv,
}: {
  syncStatus: SyncStatusPlatform | undefined;
  lastSyncTime: string | null;
  isCsv?: boolean;
}) => {
  switch (syncStatus) {
    case SyncStatusPlatform.Success:
      return isCsv ? (
        <SuccessCsvSync />
      ) : (
        <SuccessSync lastSyncTime={lastSyncTime} />
      );
    case SyncStatusPlatform.Queued:
      return <QueuedSync />;
    case SyncStatusPlatform.Pending:
      return <PendingSync />;
    case SyncStatusPlatform.Fail:
      return isCsv ? <FailedCsvSync /> : <FailedSync />;
    case undefined:
      return <NoSync />;
    default: {
      const exhaustiveCheck: never = syncStatus;
      return <NoSync />;
    }
  }
};

const NoSync = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const isMobile = useIsMobile();

  return (
    <Chip
      sx={{ "&:hover": { backgroundColor: tokens.background.neutral.hover } }}
      bgcolor={tokens.background.neutral.default}
    >
      <HourglassEmpty sx={{ color: tokens.icon.default, ...iconStyle }} />
      {!isMobile && (
        <ChipTypography color={tokens.text.default}>
          {lang.imports.syncStatus.noSync}
        </ChipTypography>
      )}
    </Chip>
  );
};

const SuccessSync = ({ lastSyncTime }: { lastSyncTime: string | null }) => {
  const { tokens } = useDesign();
  const timezone = useTimezone();
  const [lastTimeSync, setLastTimeSync] = useState("<1m");
  const isMobile = useIsMobile();

  useEffect(() => {
    // initial mount
    setLastTimeSync(getLastTimeSync(timezone, lastSyncTime));
    return () => {
      setLastTimeSync("<1m");
    };
  }, []);

  const intervalCallback = useCallback(() => {
    setLastTimeSync(getLastTimeSync(timezone, lastSyncTime));
  }, [lastSyncTime, timezone]);

  useInterval(intervalCallback, 1000);

  return (
    <Chip
      sx={{
        "&:hover": { backgroundColor: tokens.background.success.hover },
      }}
      bgcolor={tokens.background.success.default}
    >
      {!isMobile && (
        <ChipTypography color={tokens.text.success}>
          {lastTimeSync}
        </ChipTypography>
      )}
      <Cached
        sx={{
          color: tokens.icon.success,
          ...iconStyle,
        }}
      />
    </Chip>
  );
};

const FailedSync = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const isMobile = useIsMobile();

  return (
    <Chip
      sx={{ "&:hover": { backgroundColor: tokens.background.danger.hover } }}
      bgcolor={tokens.background.danger.default}
    >
      <Error sx={{ color: tokens.text.danger, ...iconStyle }} />
      {!isMobile && (
        <ChipTypography color={tokens.text.danger}>
          {lang.imports.syncFailed}
        </ChipTypography>
      )}
    </Chip>
  );
};

const PendingSync = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const isMobile = useIsMobile();

  return (
    <Chip
      sx={{ "&:hover": { backgroundColor: tokens.background.warning.hover } }}
      bgcolor={tokens.background.warning.default}
    >
      <CircularProgress
        size="0.8rem"
        sx={{ color: tokens.icon.warning, ...iconStyle }}
      />
      {!isMobile && (
        <ChipTypography color={tokens.text.warning}>
          {lang.api.syncing}
        </ChipTypography>
      )}
    </Chip>
  );
};

const QueuedSync = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const isMobile = useIsMobile();

  return (
    <Chip
      sx={{ "&:hover": { backgroundColor: tokens.background.warning.hover } }}
      bgcolor={tokens.background.warning.default}
    >
      <CircularProgress
        size="0.8rem"
        sx={{ color: tokens.icon.warning, ...iconStyle }}
      />
      {!isMobile && (
        <ChipTypography color={tokens.text.warning}>
          {lang.imports.syncStatus.queued({ isCsv: false })}
        </ChipTypography>
      )}
    </Chip>
  );
};

const SuccessCsvSync = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const isMobile = useIsMobile();

  return (
    <Chip bgcolor={tokens.background.success.default}>
      <CheckCircleOutline sx={{ color: tokens.icon.success, ...iconStyle }} />
      {!isMobile && (
        <ChipTypography color={tokens.text.success}>
          {lang.imports.imported}
        </ChipTypography>
      )}
    </Chip>
  );
};

const FailedCsvSync = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const isMobile = useIsMobile();

  return (
    <Chip bgcolor={tokens.background.danger.default}>
      <Error sx={{ color: tokens.text.danger, ...iconStyle }} />
      {!isMobile && (
        <ChipTypography color={tokens.text.danger}>
          {lang.imports.importFailed}
        </ChipTypography>
      )}
    </Chip>
  );
};
