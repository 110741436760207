import { Close, KeyboardArrowDown } from "@mui/icons-material";
import { Box, MenuItem, Popover, Typography } from "@mui/material";
import { bindPopover } from "material-ui-popup-state";
import { bindToggle, usePopupState } from "material-ui-popup-state/hooks";

import { type SettingsTabs } from "~/components/settings-modal/views/enums";
import { useTabsForUser } from "~/components/settings-modal/views/hooks/useTabsForUser";
import { SettingsPageContents } from "~/components/settings-modal/views/index";
import { ErrorBoundaryWrapper } from "~/components/ui/error-boundary/ErrorBoundaryWrapper";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function MobileSettings({
  setOpen,
  active,
  setActive,
}: {
  setOpen: (open: boolean) => void;
  active: SettingsTabs | undefined;
  setActive: (active: SettingsTabs) => void;
}) {
  const { tokens } = useDesign();

  return (
    <Box width="100%" height="100%" bgcolor={tokens.elevation.default}>
      <Box
        width="calc(100% - 2rem)"
        position="sticky"
        display="flex"
        top="0"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={`1px solid ${tokens.border.neutral.default}`}
        pt="1rem"
        pb="0.5rem"
        mx="1rem"
      >
        <PageSelect active={active} setActive={setActive} />
        <TextIconButton
          onClick={() => {
            setOpen(false);
          }}
          data-ctc="settings-close"
        >
          <Close />
        </TextIconButton>
      </Box>
      <Box width="100%" height="100%" overflow="auto" p="1rem" pb="3rem">
        <ErrorBoundaryWrapper>
          <SettingsPageContents
            active={active}
            setActive={setActive}
            setOpen={setOpen}
          />
        </ErrorBoundaryWrapper>
        <br />
      </Box>
    </Box>
  );
}

function PageSelect({
  active,
  setActive,
}: {
  active: SettingsTabs | undefined;
  setActive: (active: SettingsTabs) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const settingsTabs = useTabsForUser();

  const title = (tab: SettingsTabs) => {
    return lang.settings.tabs[tab];
  };

  const popupState = usePopupState({
    variant: "popover",
    popupId: "mobile-settings-modal",
  });

  return (
    <>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          bgcolor={tokens.background.input.default}
          display="flex"
          flexDirection="column"
        >
          {settingsTabs.map((option) => {
            const Icon = option.icon;
            const isActive = active === option.name;

            return (
              <MenuItem
                key={option.name}
                selected={isActive}
                onClick={() => {
                  setActive(option.name);
                  popupState.close();
                }}
              >
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Icon sx={{ fontSize: "1rem" }} />
                  <Typography
                    variant="Metropolis/Body/Regular"
                    sx={{
                      color: tokens.text.low,
                      "&:hover": {
                        color: tokens.text.high,
                      },
                    }}
                  >
                    {title(option.name)}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Box>
      </Popover>
      <TertiaryButton
        endIcon={<KeyboardArrowDown />}
        {...bindToggle(popupState)}
      >
        <Typography variant="Metropolis/Body/Regular" color="inherit">
          {active ? title(active) : ""}
        </Typography>
      </TertiaryButton>
    </>
  );
}
