export enum ActionTypeSelectorButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Text = "text",
}
export enum GainLossVariant {
  Box = "box",
  Text = "text",
}
export enum BreakdownTab {
  ChartOfAccounts,
  Transactions,
  ViewBalance,
  Comments,
  History,
}
export enum ActionButtonTypes {
  Secondary = "secondary",
  Tertiary = "tertiary",
}
