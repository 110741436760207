import {
  AccountCircle,
  BarChart,
  CalculateOutlined,
  CreditCard as BillingIcon,
  FontDownload,
  GroupOutlined,
  Lock,
  PersonAdd as PermissionsIcon,
  Storefront,
} from "@mui/icons-material";

import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { type SettingsTab } from "~/components/settings-modal/views/types";
import { useActiveClient } from "~/hooks/useActiveClient";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import {
  useIsCollaborator,
  useIsLockPeriodEnabled,
  useIsManagingClients,
  useUser,
} from "~/redux/auth";

export function useTabsForUser() {
  const user = useUser();
  const isEmbedded = useIsEmbedded();
  const isLockPeriodEnabled = useIsLockPeriodEnabled();
  const isManagingClients = useIsManagingClients();
  const activeClient = useActiveClient();
  const isCollaborator = useIsCollaborator();

  const isIndividualOrClient =
    !isManagingClients || (isManagingClients && activeClient);

  const profileTab: SettingsTab = {
    name: SettingsTabs.Profile,
    icon: AccountCircle,
  };
  const generalTab: SettingsTab = {
    name: SettingsTabs.General,
    icon: CalculateOutlined,
  };
  const portfolioTab: SettingsTab = {
    name: SettingsTabs.Portfolio,
    icon: BarChart,
  };
  const billingTab: SettingsTab = {
    name: SettingsTabs.Billing,
    icon: BillingIcon,
  };
  const permissionsTab: SettingsTab = {
    name: SettingsTabs.Permissions,
    icon: PermissionsIcon,
  };
  const alphaTab: SettingsTab = {
    name: SettingsTabs.Alpha,
    icon: FontDownload,
  };
  const lockPeriodTab = {
    name: SettingsTabs.LockPeriod,
    icon: Lock,
  };
  const teamTab = {
    name: SettingsTabs.Team,
    icon: GroupOutlined,
  };

  const planTab = {
    name: SettingsTabs.Plan,
    icon: Storefront,
  };

  const settingsContent: SettingsTab[] = [profileTab];

  // general (users or v1)
  if (isIndividualOrClient) {
    // Don't show the portfolio tab in the embedded experience
    if (!isEmbedded) {
      settingsContent.push(generalTab, portfolioTab);
    } else {
      settingsContent.push(generalTab);
    }
  }

  if (isManagingClients && !activeClient) {
    settingsContent.push(billingTab);
  }

  // Only show plan on client setting
  if (isManagingClients && activeClient) {
    settingsContent.push(planTab);
  }

  if (!isManagingClients) {
    settingsContent.push(permissionsTab);
  }

  // Dont show on the accountant workspace settings
  if (isLockPeriodEnabled && isIndividualOrClient) {
    settingsContent.push(lockPeriodTab);
  }

  // If this is an accountant viewing their own settings, and they are NOT a collaborator
  if (isManagingClients && !activeClient && !isCollaborator) {
    settingsContent.push(teamTab);
  }

  if (user?.isInAlpha) {
    settingsContent.push(alphaTab);
  }

  return settingsContent;
}
