import { type Blockchain } from "@ctc/types";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  type BoxProps,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useFormContext } from "react-hook-form";
import styled from "styled-components/macro";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import {
  ActionRowWrapper,
  type TransactionActionRowProps,
} from "~/components/transactions/action-row/actionRow";
import { ActionRowFee } from "~/components/transactions/action-row/ActionRowFee";
import { ActionRowFrom } from "~/components/transactions/action-row/ActionRowFrom";
import { ActionRowTime } from "~/components/transactions/action-row/ActionRowTime";
import { ActionRowTo } from "~/components/transactions/action-row/ActionRowTo";
import { ActionRowType } from "~/components/transactions/action-row/ActionRowType";
import { ActionRowValue } from "~/components/transactions/action-row/ActionRowValue";
import { ActionTableGridArea } from "~/components/transactions/action-row/enums";
import { transactionActionRowLayoutStyles } from "~/components/transactions/action-row/TransactionActionRowLayout";
import { ActionTypeSelector } from "~/components/transactions/ActionTypeSelector";
import { BulkEditAction } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { ModalProvider } from "~/components/transactions/command-palette/ModalProvider";
import { NavControllerProvider } from "~/components/transactions/command-palette/NavController";
import {
  CategorisationFunctionFooter,
  getChipPairs,
} from "~/components/transactions/edit/CategorisationFooter";
import { ActionTypeSelectorButtonType } from "~/components/transactions/enums";
import { FilterProvider } from "~/components/transactions/filter-bar/FilterContext";
import {
  formatDisplayAddress,
  getAddressLink,
} from "~/components/transactions/helpers";
import { useMinScreenWidthForTableContent } from "~/components/transactions/useTableStopsScrollingPx";
import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { Chip } from "~/components/ui/Chips";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { ErrorFallback } from "~/components/ui/error-boundary/ErrorBoundaryWrapper";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { ExternalAddressLink } from "~/components/ui/ExternalAddressLink";
import { displayMessage } from "~/components/ui/Toaster";
import { TransactionTableModalInner } from "~/components/ui/TransactionTableModal";
import { useDesign } from "~/hooks/useTheme";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { HyperlinkTextContent } from "~/lib/HyperlinkTextContent";
import { useCanAccessFeature } from "~/redux/auth";
import { useIsAddressLike } from "~/redux/imports";
import { useLang } from "~/redux/lang";
import { isInTrade, isUncategorisedTrade } from "~/services/transactions";
import { useGetSimilarTransactionDetails } from "~/state/actions";
import { useEntityLookup } from "~/state/entities";
import { DisplayMessage, Features } from "~/types/enums";
import { type ActionRow } from "~/types/index";

export function STSOptOut({
  row,
  disableBackgroundFill = false,
}: {
  row: ActionRow;
  disableBackgroundFill?: boolean;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  const [isOpen, setIsOpen] = useState(false);
  const methods = useFormContext();
  const analyticsKey = transactionAnalyticsKey("sts");
  const linkTextKey = "linkTextKey";
  const captureAnalytics = useCaptureAnalytics();
  const [applySts, createCategoryRule] = methods.watch([
    "applySts",
    "createCategoryRule",
  ]);

  const sts = useGetSimilarTransactionDetails(row._id);
  const isRulesEnabled = useCanAccessFeature(Features.Rules);

  if (sts.isPending) {
    return (
      <StsBox disableBackgroundFill={disableBackgroundFill}>
        <Box display="flex" alignItems="center" gap="0.75rem">
          <CircularProgress size="0.75rem" sx={{ color: tokens.text.low }} />
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            sx={{ color: tokens.text.low, fontSize: "0.75rem" }}
          >
            {lang.similarTransactionsSuggestion.optOut.loadingSimilar}
          </Typography>
        </Box>
      </StsBox>
    );
  }

  if (sts.data?.count && sts.data?.count > 0) {
    return (
      <>
        <STSCheekyLoaderModal row={row} isOpen={isOpen} setIsOpen={setIsOpen} />

        <StsBox disableBackgroundFill={disableBackgroundFill}>
          <Box width="100%" display="flex" flexDirection="column">
            <Box display="flex" gap="0.25rem" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={applySts}
                    onChange={({ target: { checked } }) => {
                      methods.setValue("applySts", checked);
                      // deselect rule
                      if (!checked) {
                        methods.setValue("createCategoryRule", false);
                      }
                      captureAnalytics(analyticsKey("toggle opt out"), {
                        state: checked ? "optIn" : "optOut",
                      });
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem" } }}
                  />
                }
                label={
                  <Typography
                    variant="Metropolis/Caption/Medium/Regular"
                    sx={{ color: tokens.text.low, fontSize: "0.75rem" }}
                  >
                    <HyperlinkTextContent
                      text={lang.similarTransactionsSuggestion.optOut.title({
                        linkTextKey,
                        count: sts.data?.count,
                      })}
                      actions={{
                        [linkTextKey]: {
                          fn: () => {
                            setIsOpen(true);
                            captureAnalytics(analyticsKey("view similar"));
                          },
                        },
                      }}
                    />
                  </Typography>
                }
              />
              <StsTooltip />
            </Box>
            {isRulesEnabled ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createCategoryRule}
                    onChange={(e) => {
                      methods.setValue("createCategoryRule", e.target.checked);
                      captureAnalytics(analyticsKey("toggle create rule"), {
                        state: e.target.checked ? "optIn" : "optOut",
                      });
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem" } }}
                    disabled={!applySts}
                  />
                }
                label={
                  <Typography
                    variant="Metropolis/Caption/Medium/Regular"
                    sx={{ color: tokens.text.low, fontSize: "0.75rem" }}
                  >
                    {lang.similarTransactionsSuggestion.applyToFuture}
                  </Typography>
                }
              />
            ) : null}
          </Box>
        </StsBox>
      </>
    );
  }

  return (
    <StsBox disableBackgroundFill={disableBackgroundFill}>
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        sx={{ color: tokens.text.low, fontSize: "0.75rem" }}
      >
        {lang.similarTransactionsSuggestion.optOut.noSimilar}
      </Typography>
    </StsBox>
  );
}

function CounterPartyChip({
  exchange,
  blockchain,
  currencySymbol,
  displayName,
}: {
  exchange: string;
  blockchain: Blockchain;
  currencySymbol: string;
  displayName: string;
}) {
  const { tokens } = useDesign();
  const isAnAddress = !!useIsAddressLike(exchange.trim());
  const entity = useEntityLookup(exchange, blockchain as Blockchain);
  const addressLink = getAddressLink(exchange, currencySymbol, blockchain);

  const isNicknamed =
    Boolean(entity) ||
    (exchange.toLowerCase() !== displayName.toLowerCase() && isAnAddress);

  const finalDisplayName = entity ? entity.displayName : displayName.trim();

  return (
    <Chip
      color={tokens.text.low}
      bgcolor={tokens.background.accent.neutral.low}
    >
      <Box display="flex" alignItems="center" gap="0.25rem" ml="-0.25rem">
        <ExchangeLogo
          name={exchange}
          blockchain={blockchain}
          currencySymbol={currencySymbol}
          width={16}
          height={16}
          margin="0"
        />
        <Typography
          sx={{
            fontSize: "0.75rem",
            fontWeight: 600,
            color: tokens.text.default,
            transform: "capitalize",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexShrink: 1,
          }}
        >
          {isNicknamed
            ? finalDisplayName
            : formatDisplayAddress(finalDisplayName, isNicknamed, isAnAddress)}
        </Typography>
        {addressLink ? (
          <Box height="0.875rem" mt="-0.625rem">
            <ExternalAddressLink
              link={addressLink}
              color={tokens.text.default}
              width={14}
              noPadding
            />
          </Box>
        ) : null}
        <Box height="0.875rem" mt="-0.625rem">
          <CopyIconButton
            contentToCopy={exchange}
            color={tokens.text.default}
            width={14}
            noPadding
          />
        </Box>
      </Box>
    </Chip>
  );
}

// Put it in its own function so the get sts data hook only fires when this is
// rendered, rather than on every row
function STSModalContents({
  row,
  isOpen,
  setIsOpen,
}: {
  row: ActionRow;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) {
  const lang = useLang();
  const sts = useGetSimilarTransactionDetails(row._id);
  // This is for uncategorised txs only so single sided only so just merge
  // incoming and outgoing and take the first (and only) tx
  const txs = [...row.outgoing, ...row.incoming];
  const tx = txs[0];

  const bestCurrency = tx.currencyIdentifier;

  const functionChipPairs = getChipPairs([tx]);

  const counterpartyDetails = isInTrade(tx.trade)
    ? {
        exchange: tx.from,
        displayName: tx.fromDisplayName,
        blockchain: tx.blockchain,
      }
    : {
        exchange: tx.to,
        displayName: tx.toDisplayName,
        blockchain: tx.blockchain,
      };
  const { exchange, blockchain, displayName } = counterpartyDetails;
  const currencySymbol = bestCurrency.symbol;

  useEffect(() => {
    if (!isUncategorisedTrade(row.type)) {
      setIsOpen(false);
    }
  }, [row.type, setIsOpen]);

  return (
    <>
      {sts.isPending ? (
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="25rem"
            height="20rem"
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      ) : (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <FilterProvider initialState={{ filter: sts.data?.filter }}>
            <TransactionTableModalInner
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              filter={sts.data?.filter}
              row={SimilarTransactionRow}
              customEmptyMessage={lang.similarTransactionsSuggestion.noSimilar}
              title={
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <CurrencyLogo currency={bestCurrency} margin="0" />
                  <Typography variant="Metropolis/Header/H4">{`${getActionTypeName({ actionType: row.type, lang })} ${
                    bestCurrency.symbol
                  }`}</Typography>
                </Box>
              }
              chips={[
                {
                  key: "counterparty",
                  title: isInTrade(tx.trade)
                    ? lang.similarTransactionsSuggestion.modal.receivedFrom
                    : lang.similarTransactionsSuggestion.modal.sentTo,
                  value: (
                    <CounterPartyChip
                      exchange={exchange}
                      blockchain={blockchain as Blockchain}
                      currencySymbol={currencySymbol}
                      displayName={displayName}
                    />
                  ),
                },
                {
                  key: "count",
                  title: lang.similarTransactionsSuggestion.modal.similarTxs,
                  value: (
                    <Typography variant="Metropolis/Caption/Medium/Regular">
                      {sts.data?.count ?? 0}
                    </Typography>
                  ),
                },
              ]}
              footerAction={
                functionChipPairs.length > 0 ? (
                  <CategorisationFunctionFooter
                    chipLinkPairs={functionChipPairs}
                  />
                ) : undefined
              }
              headerAction={
                <NavControllerProvider>
                  <ModalProvider variant="popover" popupId="category-selector">
                    <ActionTypeSelector
                      row={row}
                      trigger={ActionTypeSelectorButtonType.Primary}
                      uncatLabelOverride={
                        lang.similarTransactionsSuggestion.modal.buttonCTA
                      }
                    />
                  </ModalProvider>
                </NavControllerProvider>
              }
            />
          </FilterProvider>
        </ErrorBoundary>
      )}
    </>
  );
}

export function STSCheekyLoaderModal(props: {
  row: ActionRow;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) {
  const { isOpen, setIsOpen } = props;
  return (
    <Dialog
      open={isOpen}
      maxWidth="xl"
      onClose={() => {
        setIsOpen(false);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <STSModalContents {...props} />
    </Dialog>
  );
}

const gridTemplateAreas = [
  ActionTableGridArea.Type,
  ActionTableGridArea.Time,
  ActionTableGridArea.From,
  ActionTableGridArea.To,
  ActionTableGridArea.Value,
  ActionTableGridArea.Fee,
  ActionTableGridArea.Actions,
];

const defaultSizeOverrides = { [ActionTableGridArea.Actions]: "2rem" };

const STSRowLayout = styled(Box)`
  ${transactionActionRowLayoutStyles}
  pointer-events: none;
`;

function SimilarTransactionRow({ row }: TransactionActionRowProps) {
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  return (
    <ActionRowWrapper row={row}>
      <STSRowLayout
        columns={gridTemplateAreas}
        defaultSizeOverrides={defaultSizeOverrides}
        minScreenSizeForContent={minScreenSizeForContent}
      >
        <ActionRowType row={row} isCheckboxEnabled={false} />
        <ActionRowTime row={row} />
        <ActionRowFrom row={row} />
        <ActionRowTo row={row} />
        <ActionRowValue row={row} />
        <ActionRowFee row={row} />
      </STSRowLayout>
    </ActionRowWrapper>
  );
}

export function displayStsSuccessMessage({
  bulkEditId,
  message,
}: {
  bulkEditId: string;
  message: string;
}) {
  displayMessage({
    message,
    type: DisplayMessage.Success,
    id: bulkEditId,
    action: <BulkEditAction bulkEditId={bulkEditId} />,
  });
}

function StsBox({
  children,
  disableBackgroundFill,
  ...props
}: { disableBackgroundFill: boolean } & BoxProps) {
  const { tokens } = useDesign();

  return (
    <Box
      display="flex"
      width="100%"
      px="0.75rem"
      borderRadius=" 0.25rem"
      bgcolor={disableBackgroundFill ? undefined : tokens.elevation.high}
      justifyContent="space-between"
      alignItems="center"
      gap="0.5rem"
      minHeight="2.125rem"
      {...props}
    >
      {children}
    </Box>
  );
}

function StsTooltip() {
  const { tokens } = useDesign();
  const lang = useLang().similarTransactionsSuggestion.optOut.tooltip;
  return (
    <Tooltip
      title={
        <Box>
          <Typography variant="Metropolis/Caption/Medium/Regular">{lang.title}</Typography>
          <ul style={{ margin: 0, paddingLeft: "1.5rem" }}>
            <li>
              <Typography variant="Metropolis/Caption/Medium/Regular">
                {lang.criteriaOne}
              </Typography>
            </li>
            <li>
              <Typography variant="Metropolis/Caption/Medium/Regular">
                {lang.criteriaTwo}
              </Typography>
            </li>
            <li>
              <Typography variant="Metropolis/Caption/Medium/Regular">
                {lang.criteriaThree}
              </Typography>
            </li>
            <li>
              <Typography variant="Metropolis/Caption/Medium/Regular">
                {lang.criteriaFour}
              </Typography>
            </li>
          </ul>
        </Box>
      }
      placement="top"
    >
      <InfoOutlined sx={{ fontSize: "1rem", color: tokens.text.low }} />
    </Tooltip>
  );
}
