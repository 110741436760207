/**
 * Hook to detect if the application is running inside an iframe
 * @returns boolean indicating if the app is embedded in an iframe
 */
export function useIsEmbedded(): boolean {
  return isWindowEmbedded();
}

export function isWindowEmbedded(): boolean {
  return window.self !== window.top;
}
