import { Box, Typography } from "@mui/material";
import { type ReactNode } from "react";
import styled from "styled-components/macro";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

/**
 * Displays 2 chips. If only 1 is provided, the outline is dotted
 * @returns
 */
export const DualChip = ({
  leftIcon,
  leftLabel,
  rightIcon,
  rightLabel,
}: {
  leftIcon?: ReactNode;
  leftLabel: string;
  rightIcon?: ReactNode;
  rightLabel: string;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const isComplete = leftIcon && rightIcon;
  return (
    <SectionBox
      sx={{
        border: isComplete ? "none !important" : "inherit",
      }}
    >
      {leftIcon ? (
        leftIcon
      ) : (
        <CurrencyOutline
          sx={{
            border: tokens.text.default,
          }}
        />
      )}
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        sx={{ color: tokens.text.default }}
      >
        {leftLabel}
      </Typography>

      {lang.to}

      {rightIcon ? (
        rightIcon
      ) : (
        <CurrencyOutline
          sx={{
            border: tokens.text.default,
          }}
        />
      )}
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        sx={{ color: tokens.text.default }}
      >
        {rightLabel}
      </Typography>
    </SectionBox>
  );
};

const SectionBox = styled(Box)`
  ${({ theme }) => ({ ...theme.mui.typography['Metropolis/Caption/Medium/Regular'] })};
  height: 1.25rem;
  border-radius: 1.5rem;
  border: 1px dashed ${({ theme }) => theme.tokens.text.disabled};
  background: ${({ theme }) => theme.tokens.background.accent.neutral.low};
  display: flex;
  padding: 0.625rem 0.5rem 0.625rem 0.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`;

const CurrencyOutline = styled(Box)`
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border-width: 1px;
  border-style: dashed;
`;
