import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";

export function BGLAccountIDDialog({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  const handleClose = () => {
    setIsOpen(false);
  };

  if (!user?.activeDataSource.uid) return null;

  const copyText = lang.report.download.bglAccountIDModal.copyId;

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="0.75rem"
        >
          <Typography variant="Metropolis/Header/H4">
            {lang.report.download.bglAccountIDModal.title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <Typography>
            {lang.report.download.bglAccountIDModal.subtitle}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap="0.5rem"
            padding="0.25rem 0.75rem"
            border={`1px solid ${tokens.border.neutral.default}`}
            color={tokens.background.neutral.default}
            borderRadius="0.25rem"
            width="fit-content"
          >
            <Typography>{user.activeProfileDetails.uid}</Typography>
            <CopyIconButton
              contentToCopy={user.activeProfileDetails.uid}
              copyText={copyText}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogContent dividers>
        <Box display="flex" justifyContent="flex-end">
          <TertiaryButton
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {lang.report.download.bglAccountIDModal.buttonText}
          </TertiaryButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
