import { Box, Typography } from "@mui/material";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import CtcLogoLight from "~/components/ui/images/CTC-Black.svg";
import CtcLogoDark from "~/components/ui/images/CTC-White.svg";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { Theme } from "~/types/enums";

export function Partnership({
  partnerSrcLight,
  partnerSrcDark,
  partnerAlt,
  disableHeader = false,
  headerFontSize = 0.5,
  logoHeight = "30px",
  themeOverride,
}: {
  partnerSrcLight: string;
  partnerSrcDark: string;
  partnerAlt: string;
  /**
   * if true, disables the "official partners" text
   */
  disableHeader?: boolean;
  /**
   * Font size for the "official partners" text, in rem
   */
  headerFontSize?: number;
  /**
   * height of the logo, in px for the partner images
   */
  logoHeight?: string;
  /**
   * Override the theme for the partnership component (if on a non-themed
   * background for example)
   */
  themeOverride?: Theme;
}) {
  const lang = useLang();
  const { tokens, resolvedMode } = useDesign();
  const theme = themeOverride ?? resolvedMode;
  const isLightMode = theme === Theme.Light;

  return (
    <Box display="flex" flexDirection="column" gap="0.25rem">
      {disableHeader ? null : (
        <Box>
          <Typography
            fontWeight={600}
            fontSize={`${headerFontSize}rem`}
            letterSpacing={`${headerFontSize / 4}rem`}
            color={tokens.text.default}
            textTransform="uppercase"
          >
            {lang.partnership.officialPartner}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" gap="0.75rem">
        <LazyLoadImage
          src={isLightMode ? CtcLogoLight : CtcLogoDark}
          alt="CTC"
          effect="blur"
          height={logoHeight}
        />

        <Typography
          variant="Metropolis/Body/Regular"
          color={tokens.text.low}
          fontWeight={500}
        >
          ×
        </Typography>
        <LazyLoadImage
          src={isLightMode ? partnerSrcLight : partnerSrcDark}
          alt={partnerAlt}
          effect="blur"
          height={logoHeight}
        />
      </Box>
    </Box>
  );
}
