import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useContext, useState } from "react";

import { GainLossEntry } from "~/components/report/components/summary-box/GainLossEntry";
import { getGainLossData } from "~/components/report/components/summary-box/helpers/getGainLossData";
import {
  type GainLossData,
  type GainLossEntryType,
} from "~/components/report/components/summary-box/helpers/getGainLossData";
import { getCapitalGains } from "~/components/report/components/summary-box/SummaryBox";
import { useTLHTableRows } from "~/components/report/tax-loss-harvesting-modal/useTLHTableRows";
import { useIsMobile } from "~/components/ui/hooks";
import { TaxLossHarvestingContext } from "~/contexts/TaxLossHarvestingContext";
import { useAutoAnimate } from "~/hooks/useAutoAnimate";
import { useDesign } from "~/hooks/useTheme";
import { minus } from "~/lib/index";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { type ReportSummary, shouldReplaceCGTWithPNL } from "~/types/index";

const getSimulatedGainLossData = (
  summaryData: ReportSummary | null | void,
  { short, long, loss }: GainLossData,
  lossesToHarvest: number,
): GainLossData => {
  return {
    short,
    long,
    loss: {
      ...loss,
      total: summaryData ? (loss.total || 0) + lossesToHarvest : null,
    },
  };
};

export const TLHSummary = ({
  summaryData,
}: {
  summaryData: ReportSummary | undefined | void;
}) => {
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const lang = useLang();
  const country = useCountry();
  const taxSettings = useTaxSettings();
  const tlhContext = useContext(TaxLossHarvestingContext);
  const taxLossRows = useTLHTableRows();
  const [isGainLossCollapsed, setIsGainLossCollapsed] = useState(false);

  const [animationParent] = useAutoAnimate({
    duration: 180,
  });

  if (!tlhContext) return <> </>;

  const toggleGainLossCollapsed = () => {
    setIsGainLossCollapsed(!isGainLossCollapsed);
  };

  const lossesToHarvest = taxLossRows
    ? minus(
        0,
        taxLossRows
          .filter((row) => !!row.gain && row.gain < 0 && !!row.amountToSell)
          .reduce((acc, row) => acc + row.gain || 0, 0),
      )
    : 0;

  const gainLossData = getGainLossData(lang, summaryData, taxSettings, country);
  const simulatedGainLossData = getSimulatedGainLossData(
    summaryData,
    gainLossData,
    lossesToHarvest,
  );
  const title = shouldReplaceCGTWithPNL(country)
    ? lang.taxLossHarvesting.summary.netCapitalGainLossNoCGT
    : lang.taxLossHarvesting.summary.netCapitalGainLoss;

  const totalCapitalGainsData = {
    title,
    total: summaryData ? getCapitalGains(summaryData.capitalGains) : null,
  };

  const totalPostHarvestCapitalGainsData = {
    title,
    total: summaryData
      ? getCapitalGains(summaryData.capitalGains) - lossesToHarvest
      : null,
  };

  const gainLossStyleOverrideMain = {
    borderTop: `1px solid ${tokens.border.neutral.default}`,
    paddingTop: "0.5rem",
    paddingBottom: "0.25rem",
  };

  const IconComponent = isGainLossCollapsed ? ExpandLess : ExpandMore;

  return (
    <>
      {isMobile ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="3rem"
          padding="0.75rem 1.5rem 0.75rem 1rem"
          border={`1px solid ${tokens.border.neutral.default}`}
          onClick={toggleGainLossCollapsed}
          sx={{ cursor: "pointer" }}
          borderRadius="0.25rem"
          mx="0.5rem"
          mt={isMobile ? "0.5rem" : "1.5rem"}
        >
          <Typography fontWeight="600">
            {lang.taxLossHarvesting.gain}
          </Typography>

          <IconComponent
            style={{
              fontSize: "1rem",
            }}
          />
        </Box>
      ) : null}
      <Box
        ref={animationParent}
        px={isMobile ? "0.5rem" : "2rem"}
        mt={isMobile ? (isGainLossCollapsed ? "0" : "0.5rem") : "1.5rem"}
      >
        {isMobile && isGainLossCollapsed ? null : (
          <Box
            bgcolor={tokens.elevation.low}
            p="1rem 2rem 0.5rem 2rem"
            display="grid"
            borderRadius="0.25rem"
            border={`1px solid ${tokens.border.neutral.default}`}
            sx={{
              gridTemplateRows: isMobile ? "1fr 1fr" : undefined,
              gridTemplateColumns: isMobile ? undefined : "1fr 1fr",
            }}
          >
            <Box
              sx={{
                paddingRight: isMobile ? undefined : "0.75rem",
                borderRight: isMobile
                  ? undefined
                  : `1px solid ${tokens.border.neutral.default}`,
              }}
            >
              <Typography variant="Metropolis/Body/Bold" marginBottom="0.5rem">
                {shouldReplaceCGTWithPNL(country)
                  ? lang.taxLossHarvesting.realisedCapitalGainsNoCGT
                  : lang.taxLossHarvesting.realisedCapitalGains}
              </Typography>
              {Object.values(gainLossData).map((data?: GainLossEntryType) => {
                if (!data) return null;
                return (
                  <GainLossEntry
                    key={data.title}
                    data={data}
                    sxMain={gainLossStyleOverrideMain}
                    sxLeftText={{ fontWeight: "500" }}
                  />
                );
              })}
              <GainLossEntry
                data={totalCapitalGainsData}
                sxMain={gainLossStyleOverrideMain}
                sxRightText={{ fontSize: "1rem" }}
              />
            </Box>
            <Box
              sx={{
                marginTop: isMobile ? "1rem" : undefined,
                paddingLeft: isMobile ? undefined : "0.75rem",
              }}
            >
              <Typography variant="Metropolis/Body/Bold" marginBottom="0.5rem">
                {shouldReplaceCGTWithPNL(country)
                  ? lang.taxLossHarvesting.postHarvestCapitalGainsNoCGT
                  : lang.taxLossHarvesting.postHarvestCapitalGains}
              </Typography>
              {Object.values(simulatedGainLossData).map(
                (data?: GainLossEntryType) => {
                  if (!data) return null;
                  return (
                    <GainLossEntry
                      key={data.title}
                      data={data}
                      sxMain={gainLossStyleOverrideMain}
                      sxLeftText={{ fontWeight: "500" }}
                      sxRightText={{
                        color:
                          lossesToHarvest > 0 &&
                          (data.title === lang.report.totalLosses ||
                            data.title === lang.report.totalLossesNoCGT)
                            ? tokens.text.warning
                            : undefined,
                      }}
                    />
                  );
                },
              )}
              <GainLossEntry
                data={totalPostHarvestCapitalGainsData}
                sxMain={gainLossStyleOverrideMain}
                sxRightText={{
                  fontSize: "1rem",
                  color: lossesToHarvest > 0 ? tokens.text.warning : undefined,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
