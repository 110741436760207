import { type Country, SyncStatusPlatform } from "@ctc/types";
import moment from "moment-timezone";
import z from "zod";

import {
  type ImportDrawerOptionsType,
  validOnboardingImportOptions,
} from "~/components/imports-v2/types";
import { MAX_RELATED_WALLETS_ACTION } from "~/constants/constants";
import { type Translation } from "~/lang/index";
import {
  ImportMethod,
  IntegrationCategory,
  RelatedWalletsAction,
} from "~/types/enums";
import {
  type ConnectWalletImportMethod,
  type ImportMethodV2,
  type ImportTypeInstruction,
  type ImportTypeInstructionsWithImages,
  type ImportTypeInstructionWithImages,
  type SavedImportOptionByAccount,
} from "~/types/index";

export function getImportInstructionKey(
  instruction: ImportTypeInstruction,
  fallback: number,
): string {
  if (instruction.title) return instruction.title;
  if (instruction.link) return instruction.link;

  for (const item of instruction.items ?? []) {
    if (item) return item.substring(0, 10).replace(" ", "-");
  }

  return fallback.toString();
}

export function getImportInstructionKeyV2(
  instruction: ImportTypeInstructionWithImages,
  fallback: number,
): string {
  if (instruction.title) return instruction.title;
  if (instruction.link) return instruction.link;

  for (const item of instruction.items ?? []) {
    if (typeof item === "object" && item !== null) {
      return `${item.text.substring(0, 10).replace(" ", "-")}-${item.fileName}`;
    }
    return item.substring(0, 10).replace(" ", "-");
  }

  return fallback.toString();
}

export function formatLastSyncTimestamp(
  lastImportedTxTimestamp: Date,
  timezone: string,
) {
  return moment.tz(lastImportedTxTimestamp, timezone).format("ll LTS");
}

export function formatHardSyncTimestamp(
  importFromDate: Date | undefined,
  timezone: string,
  lang: Translation,
  lockedPeriodEndDate: Date | undefined,
) {
  const dates = [importFromDate, lockedPeriodEndDate].filter(Boolean);
  if (dates.length === 0) return lang.wallet.hardSync.beginningOfTime;

  // If there is both an importFromDate and a lockedPeriodEndDate, we need to find the most recent date
  const mostRecentDate = moment.max(dates.map((d) => moment(d)));
  const isImportFromDate =
    importFromDate && moment(importFromDate).isSame(mostRecentDate);

  const langKey = isImportFromDate
    ? lang.wallet.hardSync.syncedFromDate
    : lang.wallet.hardSync.lastLockedPeriod;

  return `${moment.tz(mostRecentDate, timezone).format("ll LTS")} ${langKey}`;
}

export function idOrNameInArray<T extends { id: string; name: string }>(
  array: T[],
  strToMatch: string,
  locale: string,
): boolean {
  return (
    array.findIndex(
      (option) =>
        strToMatch.localeCompare(option.id, locale, {
          sensitivity: "base",
        }) == 0 ||
        strToMatch.localeCompare(option.name, locale, {
          sensitivity: "base",
        }) == 0,
    ) > -1
  );
}

export function getSyncTitle({
  lang,
  savedImport,
  timezone,
  country,
  hasSyncOption,
  isCsvOnly,
  lockPeriodEndDate,
  formatPeriodDate,
}: {
  lang: Translation;
  savedImport: SavedImportOptionByAccount;
  timezone: string;
  country: Country | undefined;
  hasSyncOption: boolean;
  isCsvOnly: boolean;
  lockPeriodEndDate: Date;
  formatPeriodDate: (args: {
    date: Date;
    userCountry: Country | undefined;
  }) => string;
}): string {
  const { syncStatus } = savedImport;
  const lastImportedTxTimestamp = savedImport.lastImportedTxTimestamp
    ? new Date(savedImport.lastImportedTxTimestamp)
    : null;

  if (isCsvOnly || !syncStatus) {
    return "";
  }

  const lastSyncTime = lastImportedTxTimestamp
    ? formatLastSyncTimestamp(lastImportedTxTimestamp, timezone)
    : lang.imports.bulkSyncTooltip.lastSyncTime;

  if (syncStatus === SyncStatusPlatform.Pending) {
    return lang.imports.bulkSyncTooltip.pending;
  }
  if (syncStatus === SyncStatusPlatform.Fail) {
    return lang.imports.bulkSyncTooltip.failed;
  }

  if (!hasSyncOption) {
    return lang.imports.bulkSyncTooltip.noSync;
  }

  if (
    // If there is a lock period date that is not 0 and NO last imported tx timestamp,
    (lockPeriodEndDate &&
      lockPeriodEndDate > new Date(0) &&
      !lastImportedTxTimestamp) ||
    // If there is a last imported tx timestamp but the lock period date is greater than the last imported tx timestamp,
    (lastImportedTxTimestamp && lockPeriodEndDate > lastImportedTxTimestamp)
  ) {
    return lang.imports.bulkSyncTooltip.lockedPeriod({
      dateTime: formatPeriodDate({
        date: lockPeriodEndDate,
        userCountry: country,
      }),
    });
  }

  return savedImport.category === IntegrationCategory.Blockchain
    ? lang.imports.bulkSyncTooltip.wallet({
        name: savedImport.name,
        dateTime: lastSyncTime,
      })
    : lang.imports.bulkSyncTooltip.api({
        name: savedImport.name,
        dateTime: lastSyncTime,
      });
}

/**
 * If we are onboarding we want to limit the options we show users so they don't
 * get distracted by irrelevant actions e.g. hard-syncing or sync-history.
 */
export function getOptionFilterForOnboarding(isOnboarding: boolean) {
  if (!isOnboarding) {
    return () => true;
  }
  return (option: ImportDrawerOptionsType) =>
    validOnboardingImportOptions.includes(option.id);
}

export function isConnectWalletImportMethod(
  importMethod: ImportMethodV2,
): importMethod is ConnectWalletImportMethod {
  return importMethod.type === ImportMethod.ConnectWallet;
}

const ImportTypeInstructionWithImagesStepJsonSchema = z
  .object({
    title: z.string().optional(),
    link: z.string().optional(),
    items: z
      .array(
        z.union([
          z.object({
            text: z.string(),
            fileName: z.string().optional(),
          }),
          z.string(),
        ]),
      )
      .optional(),
  })
  .strict();

const ImportTypeInstructionJsonSchema = z
  .object({
    title: z.string().optional(),
    link: z.string().optional(),
    items: z.array(z.string()).optional(),
  })
  .strict();

const ImportTypeInstructionsImagesJsonSchema = z
  .object({
    step: z.array(ImportTypeInstructionWithImagesStepJsonSchema).optional(),
    video: z.array(ImportTypeInstructionJsonSchema).optional(),
    note: z.array(ImportTypeInstructionJsonSchema).optional(),
    critical: z.array(ImportTypeInstructionJsonSchema).optional(),
  })
  .strict();

export type ImportTypeInstructionsImagesJson = z.infer<
  typeof ImportTypeInstructionsImagesJsonSchema
>;

export function validateImportTypeInstructionsWithImages(json: {
  importInstructions: Record<
    string,
    Record<string, ImportTypeInstructionsWithImages>
  >;
}) {
  try {
    const instructions = json.importInstructions;
    for (const integration of Object.keys(instructions)) {
      const integrationInstructions = instructions[integration];
      for (const importTypeKey of Object.keys(integrationInstructions)) {
        ImportTypeInstructionsImagesJsonSchema.parse(
          integrationInstructions[importTypeKey],
        );
      }
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

// From an incident when a user had >650 addresses for a blockchain.
// 50 is a limit where we'd likely have degradation in performance from rate limiting from blockchain explorers.
// If we want to increase this limit, we should look at having a job queue for related chains.
export function getRelatedWalletsAction(
  walletsCount: number,
): RelatedWalletsAction.Notify | RelatedWalletsAction.Ignore {
  return walletsCount < MAX_RELATED_WALLETS_ACTION
    ? RelatedWalletsAction.Notify
    : RelatedWalletsAction.Ignore;
}
