export function getInstructionsImage(
  exchangeName: string,
  importType: string,
  fileName: string,
) {
  const href = new URL(
    `../../../assets/instructions-images/${exchangeName}-${importType}/${fileName}`,
    import.meta.url,
  ).href;
  if (href.endsWith("undefined")) {
    return undefined;
  }
  return href;
}
