import { Box } from "@mui/material";
import styled from "styled-components/macro";

export const StyledContainer = styled(Box)`
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  text-align: center;
`;
