import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export function useIsMobile() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return isSmall;
}

/*
 * This hook is used to conditionally auto-focus a field
 * (i.e. it'll autofocus an input on desktop devices but not on mobile)
 *
 * Reason we use this - we don't like it when the keyboard pops up on mobile
 * automatically (on auto-focused inputs)
 */
export function useConditionalAutoFocus() {
  const isMobile = useIsMobile();
  return isMobile ? {} : { autoFocus: true };
}
