import { WbSunny } from "@mui/icons-material";
import { Box, ListItemIcon } from "@mui/material";

import { globalAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { StyledListItem } from "~/components/nav/AccountMenu";
import {
  ButtonGroup,
  type ButtonGroupActions,
} from "~/components/ui/ui-buttons/ButtonGroup";
import { useDesign, useThemeContext } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { Size, Theme } from "~/types/enums";

export function DarkModeToggleListItem() {
  const { tokens } = useDesign();
  const lang = useLang();
  const themeContext = useThemeContext();
  const captureAnalytics = useCaptureAnalytics();
  const analyticsKey = globalAnalyticsKey("theme");

  const themeActions: ButtonGroupActions[] = [
    {
      text: lang.theme.short[Theme.Dark],
      size: Size.Small,
      onClick: () => {
        onClickTheme(Theme.Dark);
      },
    },
    {
      text: lang.theme.short[Theme.Light],
      size: Size.Small,
      onClick: () => {
        onClickTheme(Theme.Light);
      },
    },
    {
      text: lang.theme.short[Theme.System],
      size: Size.Small,
      onClick: () => {
        onClickTheme(Theme.System);
      },
    },
  ];

  const getSelectedIndex = () => {
    if (themeContext.selectedTheme === Theme.Dark) {
      return 0;
    } else if (themeContext.selectedTheme === Theme.Light) {
      return 1;
    }
    return 2;
  };

  const onClickTheme = (theme: Theme) => {
    themeContext.setTheme(theme);
    captureAnalytics(analyticsKey(theme));
  };

  return (
    <StyledListItem disableRipple>
      <Box
        display="flex"
        alignItems="center"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ListItemIcon
          style={{
            marginLeft: "-0.5rem",
            minWidth: "1.75rem",
            fontSize: "inherit",
            color: tokens.text.default,
          }}
        >
          <WbSunny fontSize="small" />
        </ListItemIcon>
        <ButtonGroup
          actions={themeActions}
          selectedIndex={getSelectedIndex()}
        />
      </Box>
    </StyledListItem>
  );
}
