import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Link,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import {
  DialogFooter,
  DialogHeader,
} from "~/components/settings-modal/views/profile/security/common";
import { PasswordTextField } from "~/components/ui/PasswordTextField";
import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { authyLink, googleAuthenticatorLink } from "~/constants/constants";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useEnableTwoFactorMutation, useQrCode } from "~/state/security";

// display secret in chunks of 4 characters
function chunkSecret(secret: string) {
  return Array.from(secret)
    .reduce((acc, char, i) => {
      if (i % 4 === 0 && i !== 0) {
        acc.push(" ");
      }
      acc.push(char);
      return acc;
    }, [] as string[])
    .join("");
}

export function Enable2faModal({
  open,
  onClose,
  openRecoveryCodes,
}: {
  open: boolean;
  onClose: () => void;
  openRecoveryCodes: (recoveryCodes: string[]) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const qrCodeQuery = useQrCode();
  const enable2faMutation = useEnableTwoFactorMutation();

  const { qr, secret } = qrCodeQuery.data ?? {};

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    getValues,
  } = useForm<{
    password: string;
    code: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      password: "",
      code: "",
    },
  });

  const onSubmit = async () => {
    const res = await enable2faMutation.mutateAsync(getValues());
    openRecoveryCodes(res.recoveryCodes);
    onClose();
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogHeader onClose={onClose}>
        {lang.twoFactorAuth.verifyCode}
      </DialogHeader>
      <DialogContent>
        <Box display="flex" gap="0.5rem" flexDirection="column">
          <Typography variant="Metropolis/Body/Bold">
            {lang.twoFactorAuth.enterYourPassword}
          </Typography>
          <Controller
            control={control}
            name="password"
            rules={{ required: lang.twoFactorAuth.passwordRequired }}
            render={({ field, fieldState: { invalid, error } }) => (
              <PasswordTextField
                {...field}
                name="password"
                fullWidth
                label={lang.twoFactorAuth.password}
                variant="outlined"
                error={!!invalid}
                helperText={error?.message}
              />
            )}
          />
        </Box>
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <Box display="flex" flexDirection="column" gap="0.3rem">
          <Typography variant="Metropolis/Body/Bold">
            {lang.twoFactorAuth.scanQrCode}
          </Typography>
          <Typography variant="Metropolis/Body/Regular">
            {lang.twoFactorAuth.scanWithApp}
            <Link
              href={googleAuthenticatorLink}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              color={tokens.text.brand}
            >
              {lang.twoFactorAuth.googleAuthenticator}
            </Link>
            {lang.twoFactorAuth.or}
            <Link
              href={authyLink}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              color={tokens.text.brand}
            >
              {lang.twoFactorAuth.authy}
            </Link>
          </Typography>
        </Box>
        <Box
          marginTop="1rem"
          display="flex"
          gap="0.5rem"
          justifyContent="space-between"
        >
          {qr && !qrCodeQuery.isFetching ? (
            <Box
              bgcolor="white"
              padding="0.5rem"
              border="1px solid #ccc"
              borderRadius="0.5rem"
              width="12rem"
              height="12rem"
              alignSelf="center"
              dangerouslySetInnerHTML={{ __html: qr }}
            />
          ) : (
            <Box
              width="12rem"
              height="12rem"
              display="flex"
              justifyContent="center"
              alignItems="center"
              alignSelf="center"
            >
              <CircularProgress />
            </Box>
          )}
          <Box display="flex" gap="1rem" flexDirection="column">
            <Box maxWidth="20rem">
              <Typography variant="Metropolis/Body/Regular">
                {lang.twoFactorAuth.manualCodeEntry}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap="1rem"
              bgcolor={tokens.background.neutral.default}
              alignItems="center"
              padding="0.2rem 0.5rem"
              borderRadius="0.5rem"
            >
              {secret && !qrCodeQuery.isFetching ? (
                <>
                  <Typography
                    data-ctc="2fa-key"
                    variant="Metropolis/Body/Regular"
                    fontWeight="bold"
                  >
                    {chunkSecret(secret)}
                  </Typography>
                  <CopyIconButton contentToCopy={secret} />
                </>
              ) : (
                <Skeleton width="100%" height="2rem" />
              )}
            </Box>
          </Box>
        </Box>
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <Box display="flex" gap="0.5rem" flexDirection="column">
          <Typography variant="Metropolis/Body/Bold">
            {lang.twoFactorAuth.verifyAuthenticationCode}
          </Typography>
          <Typography variant="Metropolis/Body/Regular">
            {lang.twoFactorAuth.enterCodeSubText}
          </Typography>
          <Controller
            control={control}
            name="code"
            rules={{
              required: lang.twoFactorAuth.enterCodeSubText,
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                name="code"
                fullWidth
                label={lang.twoFactorAuth.authenticationCode}
                sx={{
                  marginTop: "1rem",
                }}
                error={!!invalid}
                helperText={error?.message}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogFooter
        actionText={lang.twoFactorAuth.verify}
        dataCtc="2fa-enable"
        onCancel={onCancel}
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isSubmitting}
      />
    </Dialog>
  );
}
