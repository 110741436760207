import { type Plan, type ReportFormat } from "@ctc/types";
import { createContext, type PropsWithChildren, useState } from "react";

import { AnalyticEvent } from "~/analytics/analyticsProperties";
import { useCaptureAnalyticEvents } from "~/analytics/captureAnalyticEvents";
import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { useOpenPlanTabToPlan } from "~/components/settings-modal/views/plan/hooks/useOpenPlanTabToPlan";
import { PaywallModalType } from "~/contexts/paywall-modal-context/enums";
import { useIsIrsPaywallModalVariant } from "~/contexts/paywall-modal-context/irs-paywall-modal/hooks";
import { IrsPaywallModal } from "~/contexts/paywall-modal-context/irs-paywall-modal/IrsPaywallModal";
import { PaywallModal } from "~/contexts/paywall-modal-context/PaywallModal";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { type ReportDownloadType } from "~/types/index";

export type SampleReportDownload = {
  sampleName: ReportDownloadType[];
  downloadSampleFunc: () => void;
  sampleType: ReportFormat;
};

type PaywallModalContextType = {
  selectedModal: PaywallModalType | null;
  setSelectedModal: (newContent: PaywallModalType | null) => void;
  sampleReportDownload: SampleReportDownload | null;
  setSampleReportDownload: (newSample: SampleReportDownload | null) => void;
  upgradeClientDialogOpen: boolean;
  setUpgradeClientDialogOpen: (value: boolean | Plan | undefined) => void;
};

export const PaywallModalContext =
  createContext<PaywallModalContextType | null>(null);

export const PaywallModalContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { setActive } = useSettingsModal();
  const openToPlan = useOpenPlanTabToPlan();
  const [selectedModal, setSelectedModal] = useState<PaywallModalType | null>(
    null,
  );
  const [sampleReportDownload, setSampleReportDownload] =
    useState<SampleReportDownload | null>(null);

  const [upgradeClientDialogOpen, _setUpgradeClientDialogOpen] =
    useState<boolean>(false);

  const isIrsPaywallModalEnabled = useIsIrsPaywallModalVariant();
  const captureAnalytics = useCaptureAnalyticEvents();

  const setUpgradeClientDialogOpen = (value: boolean | Plan | undefined) => {
    // Dont handle closing the settings modal when the feature flag is on
    if (!value) return;

    // If a specific tab is specified, open to it, else just open to the default
    if (typeof value === "boolean") {
      setActive(SettingsTabs.Plan);
    } else {
      openToPlan(value);
    }
  };

  function setSelectedModalAndClearSample(newContent: PaywallModalType | null) {
    if (!newContent) setSampleReportDownload(null);
    setSelectedModal(newContent);

    if (newContent) {
      captureAnalytics(AnalyticEvent.PAYWALL_MODAL.VIEWED, {
        type: newContent,
      });
    }
  }

  /**
   * We don't want to show the generic new paywalls for the following variants
   * as they are already well designed and specific for the specific scenarios
   * in which they are show - as asked for by Remi
   */
  const excludedVariants: (PaywallModalType | null)[] = [
    PaywallModalType.AdvancedInventory,
  ];

  if (isIrsPaywallModalEnabled && !excludedVariants.includes(selectedModal)) {
    return (
      <PaywallModalContext.Provider
        value={{
          selectedModal,
          setSelectedModal: setSelectedModalAndClearSample,
          sampleReportDownload,
          setSampleReportDownload,
          upgradeClientDialogOpen,
          setUpgradeClientDialogOpen,
        }}
      >
        <IrsPaywallModal
          open={!!selectedModal}
          onClose={() => {
            setSelectedModal(null);
          }}
        />
        {children}
      </PaywallModalContext.Provider>
    );
  }

  return (
    <PaywallModalContext.Provider
      value={{
        selectedModal,
        setSelectedModal: setSelectedModalAndClearSample,
        sampleReportDownload,
        setSampleReportDownload,
        upgradeClientDialogOpen,
        setUpgradeClientDialogOpen,
      }}
    >
      {selectedModal ? <PaywallModal selectedModal={selectedModal} /> : null}
      {children}
    </PaywallModalContext.Provider>
  );
};
