import WAValidator from "@swyftx/api-crypto-address-validator/dist/wallet-address-validator.min";
import { bech32, bech32m } from "bech32";
import bs58check from "bs58check";
import coininfo, { type BlockchainConfig } from "coininfo";

import { WAVNetwork } from "~/types/wallet-address-validator";

/**
 * Supported BTC addresses.
 *
 * Legacy (P2PKH): 1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa (WAV supported)
 * Nested SegWit (P2SH): 3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy (WAV supported)
 * BTC Native SegWit (Bech32): bc1qewks54nna2kyja28a37fhchsn9yx0rl2e08h89 (WAV supported)
 * LTC Native SegWit (Bech32): ltc1qu7eg7dvfcrhky7hfgzksedqjfe3c2pgvr8mmcu (CTC supported)
 * Taproot (P2TR): bc1pl9d8z0a4xy5h9k7y2xmhdxj8yjh95v7nxjgx9s (CTC supported)
 * Extended Public Keys: (CTC supported)
 * xpub (Legacy P2PKH): xpub6DXjnPuuM4xAdxrEFoqQeT38E164vkniBHNsChCLQNGzB3nmGhwpvjp15rNHH8sd2uXJheCddijuLYtx7ybmUcQhVFeT6v87LWHpFusLwrX
 * ypub (P2SH-P2WPKH): ypub6XFqcFYssqsCPxGTn5nCzDg8poRcSBALnmFFFytu7iiaTs1vw3UV5YrxXuc3gCmyZiLmqPPjJXkBhBxx1BrErMcgeGzbHvYNG8kLZdvRFNr
 * zpub (Bech32): zpub6mcGaKnuioprJZCWScZ4zdSWuPRUryra8osNyoTEVgJVpuMj3xJEXo2kNhpSDaBVCiUHPEcD7b7Qd1ZtnJQgqhNLgXv6Sg7dDUFuZVXwAgE
 * @type {string[]}
 */

export const extendedPublicKey = {
  btc: {
    bip32: coininfo.bitcoin.main.versions.bip32.public, // xpub
    // @url https://en.bitcoin.it/wiki/BIP_0049
    bip49: 0x049d7cb2, // ypub
    // @url https://en.bitcoin.it/wiki/BIP_0084
    bip84: 0x04b24746, // zpub
  },
  ltc: {
    bip32: coininfo.litecoin.main.versions.bip32.public, // ltub
    // @url https://litecoin.info/docs/key-concepts/addresses-prefixes
    bip49: 0x01b26ef6, // mtub
  },
  doge: {
    bip32: coininfo.dogecoin.main.versions.bip32.public, // dgub
  },
};

export function validateTaprootAddress(
  address: string,
  coin: BlockchainConfig,
) {
  try {
    const decoded = bech32m.decode(address);
    const prefix = coin.bech32;

    if (!prefix) return false;

    if (decoded.prefix !== prefix) return false;

    // Check for the correct witness version and length for Taproot (32 bytes).
    const data = bech32m.fromWords(decoded.words.slice(1));
    const witnessVersion = decoded.words[0];
    return witnessVersion === 1 && data.length === 32;
  } catch (error) {
    return false;
  }
}

export function validateNativeSegwitAddr(address: string, prefix: string) {
  try {
    const decoded = bech32.decode(address);

    if (decoded.prefix !== prefix) {
      return false;
    }

    // Check the witness version and data length for P2WPKH (20 bytes) or
    // P2WSH (32 bytes)
    const witnessVersion = decoded.words[0];
    const data = bech32.fromWords(decoded.words.slice(1));
    return witnessVersion === 0 && (data.length === 20 || data.length === 32);
  } catch (error) {
    return false;
  }
}

function getCoinPublicPrefix(pubKey: number) {
  // Create a DataView to handle the conversion.
  const buffer = new ArrayBuffer(4); // 4 bytes for a 32-bit integer
  const view = new DataView(buffer);

  // Set the number in big-endian format.
  view.setUint32(0, pubKey, false); // false for big-endian

  return new Uint8Array(buffer);
}

// eg: xpub, ypub, zpub
export function validateExtendedPublicAddress(address: string, pubKey: number) {
  try {
    const decoded = bs58check.decode(address);

    // Convert prefix bytes to hex string
    const expectedPrefix = getCoinPublicPrefix(pubKey);

    const prefixHash = decoded.slice(0, 4).join("__");
    const expectedPrefixHash = expectedPrefix.join("__");

    // Check if prefix matches expected prefix for the coin
    return prefixHash === expectedPrefixHash;
  } catch (e) {
    return false;
  }
}

export function isValidDogeAddress(addr: string) {
  return [
    WAValidator.validate(addr, WAVNetwork.DOGE),
    validateExtendedPublicAddress(addr, extendedPublicKey.doge.bip32),
    validateExtendedPublicAddress(addr, extendedPublicKey.btc.bip32),
    validateExtendedPublicAddress(addr, extendedPublicKey.btc.bip49),
    validateExtendedPublicAddress(addr, extendedPublicKey.btc.bip84),
  ].some((v) => !!v);
}

export function isValidLtcAddress(addr: string) {
  return [
    WAValidator.validate(addr, WAVNetwork.LTC),
    validateExtendedPublicAddress(addr, extendedPublicKey.ltc.bip32),
    validateExtendedPublicAddress(addr, extendedPublicKey.ltc.bip49),
    validateExtendedPublicAddress(addr, extendedPublicKey.btc.bip32),
    validateExtendedPublicAddress(addr, extendedPublicKey.btc.bip49),
    validateExtendedPublicAddress(addr, extendedPublicKey.btc.bip84),
    validateNativeSegwitAddr(addr, "ltc"),
  ].some((v) => !!v);
}

export function isValidBtcAddress(addr: string) {
  return [
    WAValidator.validate(addr, WAVNetwork.BTC),
    validateExtendedPublicAddress(addr, extendedPublicKey.btc.bip32),
    validateExtendedPublicAddress(addr, extendedPublicKey.btc.bip49),
    validateExtendedPublicAddress(addr, extendedPublicKey.btc.bip84),
    validateTaprootAddress(addr, coininfo.bitcoin.main),
  ].some((v) => !!v);
}
