import { Blockchain, OAuthProvider, Plan, ReferrerSource } from "@ctc/types";
import { Button, useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { onboardingAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { useImportV2Metadata } from "~/components/imports-v2/helpers";
import { useOnboardingContext } from "~/components/onboarding-v2/context/onboarding";
import {
  Flow,
  OnboardingEducationPlatformType,
  OnboardingEducationTradingType,
  OnboardingPageActionType,
  UserAccountType,
} from "~/components/onboarding-v2/enums";
import { type Provider } from "~/components/onboarding-v2/provider/types";
import { useCompleteReOnboarding } from "~/components/re-onboarding-flow/helper";
import { isReferrerSource } from "~/constants/constants";
import {
  BootstrapQueryParameter,
  LocalStorageKey,
  SessionStorageKeys,
} from "~/constants/enums";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import {
  setUpdate,
  setUpdateBestActiveUser,
  useIsManagingClients,
  useUser,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { trackIntercomEvent, updateIntercomUser } from "~/services/intercom";
import { onboardingCompleted } from "~/services/settings";
import { isValidWalletAddress } from "~/services/wallet/isValidWalletAddress";
import { useSpamMutation } from "~/state/actions";
import {
  useAvailableImportOption,
  useLoadingAllImportOptions,
} from "~/state/imports";
import { useFetchSavedAccounts } from "~/state/importsV2";
import { Links } from "~/types/enums";
import { type UserInfo } from "~/types/index";

import { captureGoogleAnalytics } from "../../analytics/google";
import { getDefaultExitOnboardingLink } from "~/utils/navigation";

export function useCompleteOnboarding() {
  const dispatch = useDispatch();
  const lang = useLang().imports.selectCountry;
  const importMetadata = useImportV2Metadata();
  const analyticsKey = onboardingAnalyticsKey("completed");
  const captureAnalytics = useCaptureAnalytics();
  const showSpamMutation = useSpamMutation();

  return async () => {
    try {
      // After onboarding, we by default hide spam transactions
      showSpamMutation.mutate({
        showSpamTransactions: false,
      });
      dispatch(setUpdate({ isOnboarding: false }));
      dispatch(setUpdateBestActiveUser({ isOnboarding: false }));
      const res = await onboardingCompleted();

      if (res.error) {
        dispatch(setUpdate({ isOnboarding: true }));
        dispatch(setUpdateBestActiveUser({ isOnboarding: true }));
        alert(lang.somethingWentWrongSupport);
        return false;
      }

      captureAnalytics(analyticsKey("metadata"), importMetadata);

      // Add intercom tracking
      trackIntercomEvent("onboarding_completed");
      updateIntercomUser({
        is_onboarding: false,
      });

      // Add GA4 tracking
      captureGoogleAnalytics({
        category: "onboarding",
        action: "Onboarding Completed",
        label: "onboarding_completed",
      });
      return true;
    } catch (error) {
      return false;
    }
  };
}

export const useSubmitOnEnter = (handler: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handler();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handler]);
};

export function useCanViewTypePage() {
  const user = useUser();
  const [inviteCode] = useLocalStorage<string | undefined>(
    LocalStorageKey.InviteCode,
    undefined,
  );

  return !((user && user.paidPlan === Plan.Accountant) || !!inviteCode);
}

export const LinkTextButton = styled(Button)<{ active?: boolean }>`
  && {
    transition: none;
    border-bottom: 1px solid transparent;
    padding: 0;
    border-radius: 0;
    background: transparent;
    color: ${({ theme, active }) =>
      active ? theme.tokens.text.default : theme.tokens.text.low};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
  }

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.tokens.text.low};
  }
`;

export const usePageNavigation = () => {
  const location = useLocation();
  const user = useUser();
  const taxSettings = useTaxSettings();
  const currentPage = location.pathname;
  const {
    state: {
      flow,
      accountType,
      tradingTypes,
      seenAreYouSurePage,
      seenImportCEXReminderPage,
      providerId,
    },
  } = useOnboardingContext();

  const navigate = useNavigate();
  const isCoinbaseFlow = flow === Flow.CoinbaseOnboarding;
  const isMetaMaskFlow = flow === Flow.MetaMaskOnboarding;
  const isProviderFlow = flow === Flow.ProviderOnboarding && !!providerId;
  const { data: savedAccounts } = useFetchSavedAccounts();
  const { accounts: metaMaskAccounts } = getMetaMaskOnboardingPayload();
  const hasWalletFromAuthProvider =
    user?.oAuthProvider === OAuthProvider.Moralis && user?.oAuthId;

  const connectedWalletCount =
    metaMaskAccounts.length + (hasWalletFromAuthProvider ? 1 : 0);
  const hasAlreadyImportedAccount =
    savedAccounts?.all && savedAccounts.all.length > 0;
  const completeOnboarding = useCompleteOnboarding();
  const completeReOnboarding = useCompleteReOnboarding();

  // we only show education when crypto to crypto is taxable at the moment
  const isEducationTradingEnabled =
    tradingTypes.includes(OnboardingEducationTradingType.Trading) &&
    !taxSettings?.cryptoToCryptoNonTaxable;

  // we only show education when staking is income at the moment
  const { Staking } = OnboardingEducationTradingType;
  const conds = new Set(tradingTypes);
  const isEducationStakingEnabled =
    !!taxSettings?.stakingAsIncome &&
    !![Staking].find((cond) => conds.has(cond));

  const isManagingClients = useIsManagingClients();
  const defaultExitOnboardingLink = getDefaultExitOnboardingLink(user);
  const getLink = useCallback(
    (
      action: OnboardingPageActionType,
    ): { endOfOnboarding?: true; endOfReOnboarding?: true; link: string } => {
      switch (true) {
        case currentPage.startsWith(Links.OnboardingSelectTheme): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingSelectCountry };
            }
            default: {
              return { link: currentPage };
            }
          }
        }
        case currentPage.startsWith(Links.OnboardingSelectCountry): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              if (
                accountType === UserAccountType.Accountant ||
                user?.paidPlan === Plan.Accountant
              ) {
                // after accountants have added a country their onboarding experience is complete
                return { endOfOnboarding: true, link: Links.Clients };
              }

              if (isCoinbaseFlow) {
                return { link: Links.OnboardingImportsOauth };
              }
              if (
                isMetaMaskFlow &&
                (connectedWalletCount > 0 || hasAlreadyImportedAccount)
              ) {
                return { link: Links.OnboardingWallets };
              }
              if (isProviderFlow) {
                return { link: Links.OnboardingProviderWelcome };
              }
              return { link: Links.OnboardingImportsSearch };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingImportsOauth): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingImportsCalculating };
            }
            case OnboardingPageActionType.Skip: {
              return { link: Links.OnboardingImportsSearch };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingWallets): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              if (isMetaMaskFlow) {
                return { link: Links.Dashboard, endOfOnboarding: true };
              }
              return { link: defaultExitOnboardingLink, endOfOnboarding: true };
            }

            case OnboardingPageActionType.Skip: {
              return { link: Links.Imports, endOfOnboarding: true };
            }
            default: {
              return { link: currentPage };
            }
          }
        }
        case currentPage.startsWith(Links.OnboardingPlatformTypes): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingTradingTypes };
            }
            case OnboardingPageActionType.Skip: {
              return { link: Links.OnboardingImportsSearch };
            }
            default: {
              return { link: currentPage };
            }
          }
        }
        case currentPage.startsWith(Links.OnboardingTradingTypes): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingAccuracy };
            }
            case OnboardingPageActionType.Skip: {
              return { link: Links.OnboardingImportsSearch };
            }
            case OnboardingPageActionType.Invalid:
            case OnboardingPageActionType.Previous: {
              return { link: Links.OnboardingPlatformTypes };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingAccuracy): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              if (isEducationTradingEnabled) {
                return { link: Links.OnboardingTaxableTrades };
              }
              if (isEducationStakingEnabled) {
                return { link: Links.OnboardingTaxableStaking };
              }
              return { link: Links.OnboardingImportsSearch };
            }
            case OnboardingPageActionType.Invalid:
            case OnboardingPageActionType.Previous: {
              return { link: Links.OnboardingTradingTypes };
            }
            default:
              return { link: Links.ReOnboardingTaxableTrades };
          }
        }
        case currentPage.startsWith(Links.ReOnboardingAccuracy): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.ReOnboardingTaxableTrades };
            }
            case OnboardingPageActionType.Previous: {
              return { link: Links.ReOnboardingWelcomeBack };
            }
            default:
              return { link: Links.ReOnboardingTaxableTrades };
          }
        }
        case currentPage.startsWith(Links.OnboardingTaxableTrades): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              if (isEducationStakingEnabled) {
                return { link: Links.OnboardingTaxableStaking };
              }
              return { link: Links.OnboardingImportsSearch };
            }
            case OnboardingPageActionType.Previous: {
              return { link: Links.OnboardingAccuracy };
            }
            case OnboardingPageActionType.Invalid: {
              return { link: Links.OnboardingTradingTypes };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.ReOnboardingTaxableTrades): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.ReOnboardingTaxableStaking };
            }
            case OnboardingPageActionType.Previous: {
              return { link: Links.ReOnboardingAccuracy };
            }

            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingTaxableStaking): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingImportsSearch };
            }
            case OnboardingPageActionType.Previous: {
              if (isEducationTradingEnabled) {
                return { link: Links.OnboardingTaxableTrades };
              }
              return { link: Links.OnboardingAccuracy };
            }
            case OnboardingPageActionType.Invalid: {
              return { link: Links.OnboardingTradingTypes };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.ReOnboardingTaxableStaking): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return {
                link: defaultExitOnboardingLink,
                endOfReOnboarding: true,
              };
            }
            case OnboardingPageActionType.Previous: {
              return { link: Links.ReOnboardingTaxableTrades };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingImportsSearch): {
          switch (action) {
            case OnboardingPageActionType.Finish: {
              return { link: defaultExitOnboardingLink, endOfOnboarding: true };
            }
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingImportsCalculating };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingAreYouSure): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: defaultExitOnboardingLink, endOfOnboarding: true };
            }
            case OnboardingPageActionType.Previous: {
              return { link: Links.OnboardingImportsSearch };
            }
            default:
              return { link: currentPage };
          }
        }

        case currentPage.startsWith(Links.OnboardingImportsCustom):
        case currentPage.startsWith(Links.OnboardingImportsNew): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              if (isManagingClients) {
                return {
                  link: defaultExitOnboardingLink,
                  endOfOnboarding: true,
                };
              }
              return { link: Links.OnboardingImportsCalculating };
            }
            case OnboardingPageActionType.Previous: {
              return { link: Links.OnboardingImportsSearch };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.ReOnboardingWelcomeBack): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.ReOnboardingAccuracy };
            }
            case OnboardingPageActionType.Finish: {
              return {
                link: defaultExitOnboardingLink,
                endOfReOnboarding: true,
              };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingImportCEXReminder): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              if (seenAreYouSurePage) {
                return {
                  link: defaultExitOnboardingLink,
                  endOfOnboarding: true,
                };
              }
              return { link: Links.OnboardingAreYouSure };
            }
            case OnboardingPageActionType.Previous: {
              return { link: Links.OnboardingImportsSearch };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(
          Links.OnboardingReconUncategorisedTransactions,
        ): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingOnYourWay };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingImportsCalculating): {
          switch (action) {
            case OnboardingPageActionType.Previous: {
              return { link: Links.OnboardingImportsSearch };
            }
            case OnboardingPageActionType.Invalid: {
              return { link: Links.Imports, endOfOnboarding: true };
            }
            case OnboardingPageActionType.Finish: {
              return { link: defaultExitOnboardingLink, endOfOnboarding: true };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingOnYourWay): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.Report, endOfOnboarding: true };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingPaymentStatus): {
          switch (action) {
            case OnboardingPageActionType.Finish: {
              return { link: Links.Report, endOfOnboarding: true };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingPayment): {
          switch (action) {
            case OnboardingPageActionType.Skip: {
              return { link: Links.Report, endOfOnboarding: true };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingProviderWelcome): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              if (!user?.email) {
                // If the oauth user does not have an email associated with it
                // then we can't set a password, so we go straight to syncing
                // This can happen for Independent Reserve users who have
                // multiple portfolios in CTC
                return { link: Links.OnboardingProviderSyncing };
              }
              return { link: Links.OnboardingProviderSetPassword };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingProviderSetPassword): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingProviderPasswordSetSuccess };
            }
            case OnboardingPageActionType.Skip: {
              return { link: Links.OnboardingProviderSyncing };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(
          Links.OnboardingProviderPasswordSetSuccess,
        ): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingProviderSyncing };
            }
            case OnboardingPageActionType.Skip: {
              return { link: Links.Imports, endOfOnboarding: true };
            }
            default:
              return { link: currentPage };
          }
        }
        case currentPage.startsWith(Links.OnboardingProviderSyncing): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.Report, endOfOnboarding: true };
            }
            default:
              return { link: Links.Imports, endOfOnboarding: true };
          }
        }
        case currentPage.startsWith(Links.OnboardingEmbededOfferIntro): {
          switch (action) {
            case OnboardingPageActionType.Next: {
              return { link: Links.OnboardingSelectCountry };
            }
          }
        }
        default:
          return { link: currentPage };
      }
    },
    [
      currentPage,
      isCoinbaseFlow,
      isMetaMaskFlow,
      accountType,
      user?.paidPlan,
      isEducationTradingEnabled,
      isEducationStakingEnabled,
      connectedWalletCount,
      seenAreYouSurePage,
      seenImportCEXReminderPage,
      hasAlreadyImportedAccount,
      isManagingClients,
      defaultExitOnboardingLink,
    ],
  );

  const navigateTo = useCallback(
    (action: OnboardingPageActionType) => {
      const next = getLink(action);
      if (!next) {
        return;
      }
      const { endOfOnboarding, endOfReOnboarding, link } = next;
      if (endOfOnboarding) {
        completeOnboarding();
      }
      if (endOfReOnboarding) {
        completeReOnboarding();
      }
      navigate(link);
    },
    [completeOnboarding, completeReOnboarding, getLink, navigate],
  );

  return { navigateTo };
};

/**
 * Gets the referrer source with priority given to the user document over localStorage
 * @param user The user info object
 * @returns The determined referrer source or undefined if none found
 */
export function getReferrerSource(user?: UserInfo): ReferrerSource | undefined {
  // First check the user document as source of truth
  if (user?.referrerSource && isReferrerSource(user.referrerSource)) {
    return user.referrerSource;
  }

  // Fall back to localStorage if user referrer not available
  const localReferrerSource = localStorage.getItem(
    LocalStorageKey.ReferrerSource,
  ) as ReferrerSource | null;

  if (localReferrerSource && isReferrerSource(localReferrerSource)) {
    return localReferrerSource;
  }

  return undefined;
}

export const getInitialFlow = (
  user: UserInfo,
):
  | { flow: Exclude<Flow, Flow.ProviderOnboarding> }
  | { flow: Flow.ProviderOnboarding; providerId: Provider } => {
  const isReOnboarding = user?.isReOnboarding || false;
  const isOnboarding = user?.isOnboarding || false;
  const referrerSource = getReferrerSource(user);

  switch (true) {
    // Re-onboarding flow
    case isReOnboarding && !isOnboarding: {
      return { flow: Flow.ReOnboarding };
    }

    // Provider-based onboarding (IndependentReserve, BitcoinDotComAu)
    case user?.oAuthProvider === OAuthProvider.IndependentReserve ||
      user?.oAuthProvider === OAuthProvider.BitcoinDotComAu: {
      return {
        flow: Flow.ProviderOnboarding,
        providerId: user.oAuthProvider,
      };
    }

    // Coinbase OAuth provider
    case user?.oAuthProvider === OAuthProvider.Coinbase: {
      return { flow: Flow.CoinbaseOnboarding };
    }

    // No referrer source, default flow
    case !referrerSource: {
      return { flow: Flow.Onboarding };
    }

    // Coinbase-related referrer sources
    case referrerSource === ReferrerSource.Coinbase ||
      referrerSource === ReferrerSource.CoinbaseOne ||
      referrerSource === ReferrerSource.CoinbaseOnePremium: {
      return { flow: Flow.CoinbaseOnboarding };
    }

    // MetaMask-related referrer sources
    case referrerSource === ReferrerSource.MetaMask ||
      referrerSource === ReferrerSource.MetamaskEmbedded: {
      return { flow: Flow.MetaMaskOnboarding };
    }

    // Default flow
    default: {
      return { flow: Flow.Onboarding };
    }
  }
};

export function getDefaultPlatformType(flow: Flow) {
  switch (flow) {
    case Flow.CoinbaseOnboarding:
      return [OnboardingEducationPlatformType.Cex];
    case Flow.MetaMaskOnboarding:
      return [OnboardingEducationPlatformType.DeFi];
    default:
      return [];
  }
}

// If user have referrerSource, we will show the referrer import option
function getReferrerImportSource(referrerSource?: ReferrerSource) {
  if (!referrerSource || !isReferrerSource(referrerSource)) {
    return null;
  }
  switch (referrerSource) {
    case ReferrerSource.Coinbase:
    case ReferrerSource.CoinbaseOne:
    case ReferrerSource.CoinbaseOnePremium: {
      return ReferrerSource.Coinbase;
    }
    case ReferrerSource.MetamaskEmbedded:
    case ReferrerSource.CoinbaseEmbedded:
    case ReferrerSource.KrakenEmbedded:
    case ReferrerSource.BinanceEmbedded:
    case ReferrerSource.MetaMask:
    case ReferrerSource.Coinstats:
    case ReferrerSource.Coinjar:
    case ReferrerSource.IndependentReserve:
    case ReferrerSource.BitcoinDotComAu:
    case ReferrerSource.Phantom:
    case ReferrerSource.ReferAFriend:
    case ReferrerSource.CTC: {
      return referrerSource;
    }
    default: {
      const exhaustiveCheck: never = referrerSource;
      throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
  }
}

export const useReferrerImportOption = () => {
  const user = useUser();
  const referrerSource = user?.referrerSource;
  const isLoadingAvailableImportOptions = useLoadingAllImportOptions();

  const referrerImportSource = getReferrerImportSource(referrerSource);

  // If user is signed up through coinbase oauth, we will show the coinbase import option
  // todo: `coinbase`, `independentreserve`, `bitcoindotcomau` are `Integration` enum values
  // todo: we should use the `Integration` enum for this but it is not shared between FE and BE
  const getOAuthImportSource = () => {
    if (user?.oAuthProvider === OAuthProvider.Coinbase) {
      return OAuthProvider.Coinbase;
    }

    if (user?.oAuthProvider === OAuthProvider.IndependentReserve) {
      return "independentreserve";
    }

    if (user?.oAuthProvider === OAuthProvider.BitcoinDotComAu) {
      return "bitcoindotcomau";
    }

    return null;
  };

  const oAuthImportSource = getOAuthImportSource();
  return {
    importOption: useAvailableImportOption(
      oAuthImportSource ?? referrerImportSource,
    ),
    isLoadingAvailableImportOptions,
  };
};

export type MetaMaskPayload = {
  accounts: string[];
  networks: string[];
};

function parseMetaMaskPayload(data: string | undefined): MetaMaskPayload {
  const base = { accounts: [], networks: [] };
  try {
    const payload = data ? JSON.parse(data) : base;
    if (!payload || typeof payload !== "object") {
      return base;
    }
    // here we assume accounts and networks are given as strings
    // if signup with MM we want to remove that address as its being auto-imported
    const accounts = Array.isArray(payload.accounts) ? payload.accounts : [];
    const networks = Array.isArray(payload.networks) ? payload.networks : [];
    return { accounts, networks };
  } catch (e) {
    return base;
  }
}

export function parseAndValidateMetaMaskParams(
  searchParams: URLSearchParams,
): MetaMaskPayload | null {
  const defaultPayload: MetaMaskPayload = { accounts: [], networks: [] };

  const rawAccounts = searchParams.get(BootstrapQueryParameter.Accounts);
  const rawNetworks = searchParams.get(BootstrapQueryParameter.Networks);

  // We need both params to be present
  // we need to check for null as we could have empty strings
  if (rawAccounts === null || rawNetworks === null) {
    return null;
  }

  // Split by comma and filter out empty strings
  const accountsList = rawAccounts.split(",").filter(Boolean);
  const networksList = rawNetworks.split(",").filter(Boolean);

  // We need both arrays to have items
  if (!accountsList.length || !networksList.length) {
    return defaultPayload;
  }

  // Filter out invalid addresses using "isValidWalletAddress"
  const validatedAccounts = Array.from(
    new Set(
      accountsList.filter((acct) =>
        isValidWalletAddress(acct, Blockchain.ETH, false),
      ),
    ),
  );

  // Filter out invalid networks (must be numeric)
  const validatedNetworks = Array.from(
    new Set(
      networksList.filter((net) => {
        const parsed = parseInt(net, 10);
        return !Number.isNaN(parsed);
      }),
    ),
  );

  return {
    accounts: validatedAccounts,
    networks: validatedNetworks,
  };
}

export function setMetaMaskOnboardingPayloadFromQuery(
  searchParams: URLSearchParams,
) {
  // If both are valid, we store them in session storage
  /**
   * Store MetaMask wallet information in sessionStorage rather than localStorage
   * to ensure it's only available for the current browser session.
   * This prevents previously stored wallet data from being used for new users
   * who sign up without wallets being provided.
   *
   * If the accounts or networks arrays are empty, we remove the session storage item
   * as it means metamask has told us they have no connected accounts
   * this could happen when wallet is disconnected from the portfolio page
   */
  const metamaskPayload = parseAndValidateMetaMaskParams(searchParams);

  // if payload was missing or invalid do nothing
  if (!metamaskPayload) {
    return;
  }

  const { accounts, networks } = metamaskPayload;
  if (accounts.length && networks.length) {
    sessionStorage.setItem(
      SessionStorageKeys.MetaMaskWallets,
      JSON.stringify({ accounts, networks }),
    );
  } else {
    // if metamask has told us they have no connected accounts clear the session storage
    sessionStorage.removeItem(SessionStorageKeys.MetaMaskWallets);
  }
}

export const getMetaMaskOnboardingPayload = (): MetaMaskPayload => {
  // cookie is used for tracking when users signup via the MM HP link
  // We prefer session storage as it comes from the query params which is a closer source of truth
  const metaMaskPayloadJson =
    sessionStorage.getItem(SessionStorageKeys.MetaMaskWallets) ??
    Cookies.get(LocalStorageKey.MetaMaskWallets);
  const metaMaskPayload = parseMetaMaskPayload(
    decodeURI(metaMaskPayloadJson ?? ""),
  );
  return metaMaskPayload;
};
export function useSavedReferrerAccount() {
  const { data: savedAccounts, isLoading: isLoadingSavedAccounts } =
    useFetchSavedAccounts();
  const {
    importOption: referrerImportOption,
    isLoadingAvailableImportOptions,
  } = useReferrerImportOption();

  const savedReferrerAccount = savedAccounts?.all.find(
    (account) => account.id === referrerImportOption?.id,
  );
  return {
    savedReferrerAccount,
    isLoading: isLoadingSavedAccounts || isLoadingAvailableImportOptions,
  };
}

export const useAccountListMediaQuery = () => {
  // This is the media query that determines if we should show the full list or drawer
  // This window size is a special case for the onboarding page that show account list
  return useMediaQuery(`(max-width: 1140px)`);
};

export const useOnboardingProgressBarMediaQuery = () => {
  // This is the media query that determines if we should show the full onboarding progress bar
  return useMediaQuery(`(max-width: 1400px)`);
};

export const useOnboardingProgressStepOnlyMediaQuery = () => {
  return useMediaQuery(`(max-height: 500px)`);
};
