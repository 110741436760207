import { AccountantInvite } from "~/components/settings-modal/views/permissions/AccountantInvite";
import { DataSource } from "~/components/settings-modal/views/profile/DataSource";
import { useIsManagingClients, useUser } from "~/redux/auth";

export const Accounting = () => {
  const isManagingClients = useIsManagingClients();
  const user = useUser();

  return (
    <>
      {!isManagingClients && <AccountantInvite />}
      {(user?.parentProfile || isManagingClients) &&
      !user?.activeDataSource.isAccountantOrCollaborator ? (
        <DataSource accountant={isManagingClients} />
      ) : null}
    </>
  );
};
