import {
  AccessTime,
  ArrowDownward,
  ArrowUpward,
  Clear,
  ErrorOutline,
  MoreVert,
  Search,
} from "@mui/icons-material";
import {
  Box,
  type BoxProps,
  CircularProgress,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popover,
  type SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Fuse from "fuse.js";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import moment from "moment-timezone";
import { type ReactNode, useMemo, useState } from "react";
import styled from "styled-components";

import { Sorts } from "~/components/settings-modal/views/team/enum";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { Calco, type CalcoVariants } from "~/components/ui/calco/Calco";
import { displayMessage } from "~/components/ui/Toaster";
import { LocalStorageKey } from "~/constants/enums";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import { bubbleUndefinedToTheBottom } from "~/lib/bubbleUndefinedToTheBottomSort";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  useCancelInvitationMutation,
  useInviteTeamMemberMutation,
  useTeam,
} from "~/state/invites";
import { DisplayMessage, InvitationStatus } from "~/types/enums";
import {
  type InvitationDetails,
  type TeamInvitationDetails,
} from "~/types/index";

export function TeamTable() {
  const { tokens } = useDesign();
  const user = useUser();
  const lang = useLang();
  const teamQuery = useTeam();
  const invitations = teamQuery.data ?? [];
  const [sort, setSort] = useLocalStorage<Sorts>(
    LocalStorageKey.TeamSort,
    Sorts.NameDescending,
  );
  const [search, setSearch] = useState("");

  if (!user) {
    return null;
  }

  const enrichedTeam: Partial<TeamInvitationDetails>[] = useMemo(
    () =>
      [
        ...invitations,
        { name: user.name, toEmail: user.email, lastLogin: user.lastLogin },
      ].sort(sortByType(sort)),
    [invitations, sort],
  );

  const fuse = new Fuse(enrichedTeam, { keys: ["name", "toName", "toEmail"] });
  const filteredTeam = search.length
    ? fuse.search(search).map((result) => result.item)
    : enrichedTeam;

  const isFullInvitation = (
    invitation: Partial<TeamInvitationDetails>,
  ): invitation is InvitationDetails => {
    return invitation._id !== undefined;
  };

  return (
    <Box maxWidth="100%" overflow="auto">
      <Table>
        <thead>
          <tr>
            <SortHeader
              label={lang.settings.team.header.name}
              sort={sort}
              setSort={setSort}
              ascending={Sorts.NameAscending}
              descending={Sorts.NameDescending}
            >
              <TextField
                placeholder={lang.settings.team.header.search}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        sx={{ fontSize: "1rem", color: tokens.text.low }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment:
                    search && search.length ? (
                      <InputAdornment position="end">
                        <TextIconButton
                          size="small"
                          onClick={() => {
                            setSearch("");
                          }}
                        >
                          <Clear
                            sx={{ fontSize: "1rem", color: tokens.text.low }}
                          />
                        </TextIconButton>
                      </InputAdornment>
                    ) : null,
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    py: "0.25rem",
                    px: "0",
                    fontSize: "0.75rem",
                    bgcolor: "transparent",
                    minWidth: "10rem",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </SortHeader>
            <SortHeader
              label={lang.settings.team.header.email}
              sort={sort}
              setSort={setSort}
              ascending={Sorts.EmailAscending}
              descending={Sorts.EmailDescending}
            />

            <SortHeader
              label={lang.settings.team.header.lastLogin}
              sort={sort}
              setSort={setSort}
              ascending={Sorts.LastActiveAscending}
              descending={Sorts.LastActiveDescending}
            />
            <TableHeaderCell />
          </tr>
        </thead>
        <tbody>
          {enrichedTeam.length && !teamQuery.isLoading ? (
            filteredTeam.length ? (
              filteredTeam.map((invitation) => (
                <StyledTableRow
                  key={invitation.toEmail}
                  status={invitation.status}
                >
                  <TableBodyCell color="inherit">
                    <Typography
                      variant="Metropolis/Caption/Medium/Regular"
                      color="inherit"
                      maxWidth="100%"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                    >
                      {invitation.name ?? invitation.toName}
                    </Typography>
                    <StatusChip
                      status={invitation.status}
                      isOwner={invitation.toEmail === user.email}
                      createdAt={
                        invitation.createdAt
                          ? new Date(invitation.createdAt)
                          : undefined
                      }
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    <Typography
                      variant="Metropolis/Caption/Medium/Regular"
                      color="inherit"
                      maxWidth="100%"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                    >
                      {invitation.toEmail}
                    </Typography>
                  </TableBodyCell>
                  <TableBodyCell>
                    <Typography
                      variant="Metropolis/Caption/Medium/Regular"
                      color="inherit"
                    >
                      {invitation.lastLogin
                        ? moment(invitation.lastLogin).fromNow()
                        : "-"}
                    </Typography>
                  </TableBodyCell>
                  <TableBodyCell p={0}>
                    {isFullInvitation(invitation) ? (
                      <TeamMemberActionMenu invitation={invitation} />
                    ) : null}
                  </TableBodyCell>
                </StyledTableRow>
              ))
            ) : (
              <EmptyState text={lang.settings.team.noResults} />
            )
          ) : (
            <EmptyState
              text={lang.settings.team.noTeamMembers}
              isLoading={teamQuery.isLoading}
            />
          )}
        </tbody>
      </Table>
    </Box>
  );
}

function EmptyState({
  text,
  isLoading = false,
  variant = "banana",
}: {
  text: string;
  isLoading?: boolean;
  variant?: CalcoVariants;
}) {
  return (
    <tr>
      <td colSpan={999}>
        <Box
          width="100%"
          height="25rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="0.5rem"
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Calco type={variant} height={150} />
              <Typography variant="Metropolis/Body/Regular">{text}</Typography>
            </>
          )}
        </Box>
      </td>
    </tr>
  );
}

const RejectedStatuses = [
  InvitationStatus.Declined,
  InvitationStatus.Revoked,
  InvitationStatus.RevokedLink,
  InvitationStatus.Deleted,
];

function StatusChip({
  status,
  createdAt,
  isOwner = false,
}: {
  status?: InvitationStatus;
  createdAt?: Date;
  isOwner?: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang();

  if (status === InvitationStatus.Accepted && !isOwner) {
    return null;
  }

  const getBgColorProps = (status?: InvitationStatus): BoxProps => {
    switch (status) {
      case InvitationStatus.Declined:
      case InvitationStatus.Revoked:
      case InvitationStatus.RevokedLink:
      case InvitationStatus.Deleted:
        return {
          bgcolor: tokens.background.danger.default,
        };
      default:
        return {
          bgcolor: tokens.background.accent.neutral.medium,
        };
    }
  };

  const StatusIcon = ({ status }: { status?: InvitationStatus }) => {
    switch (status) {
      case InvitationStatus.Declined:
      case InvitationStatus.Revoked:
      case InvitationStatus.RevokedLink:
      case InvitationStatus.Deleted:
        return <ErrorOutline color="inherit" sx={{ fontSize: "1rem" }} />;
      case InvitationStatus.Pending:
        return <AccessTime color="inherit" sx={{ fontSize: "1rem" }} />;
      default:
        return null;
    }
  };

  const tooltipTitle =
    status === InvitationStatus.Pending && !!createdAt
      ? lang.settings.team.inviteTooltip({
          date: moment(createdAt).format("DD MMM YYYY"),
        })
      : "";

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <Box
        display="flex"
        alignItems="center"
        gap="0.25rem"
        p="0.125rem 0.5rem"
        borderRadius="0.25rem"
        className="statusChip"
        color="inherit"
        {...getBgColorProps(status)}
      >
        <StatusIcon status={status} />
        <Typography variant="Metropolis/Caption/Medium/Regular" color="inherit">
          {status
            ? lang.invitationStatus[status]
            : isOwner
              ? lang.settings.team.owner
              : null}
        </Typography>
      </Box>
    </Tooltip>
  );
}

function sortByType(sort: Sorts) {
  return (
    a: Partial<TeamInvitationDetails>,
    b: Partial<TeamInvitationDetails>,
  ) => {
    switch (sort) {
      case Sorts.NameDescending: {
        return bubbleUndefinedToTheBottom<string>((a, b) => a.localeCompare(b))(
          a.name,
          b.name,
        );
      }
      case Sorts.NameAscending: {
        return bubbleUndefinedToTheBottom<string>((a, b) => b.localeCompare(a))(
          a.name,
          b.name,
        );
      }
      case Sorts.EmailAscending: {
        return bubbleUndefinedToTheBottom<string>((a, b) => b.localeCompare(a))(
          a.toEmail,
          b.toEmail,
        );
      }
      case Sorts.EmailDescending: {
        return bubbleUndefinedToTheBottom<string>((a, b) => a.localeCompare(b))(
          a.toEmail,
          b.toEmail,
        );
      }
      case Sorts.LastActiveAscending: {
        return bubbleUndefinedToTheBottom<Date>(
          (a, b) => new Date(a).getTime() - new Date(b).getTime(),
        )(a.lastLogin, b.lastLogin);
      }
      case Sorts.LastActiveDescending: {
        return bubbleUndefinedToTheBottom<Date>(
          (a, b) => new Date(b).getTime() - new Date(a).getTime(),
        )(a.lastLogin, b.lastLogin);
      }
      default: {
        const exhaustiveCheck: never = sort;
        return 0;
      }
    }
  };
}

function SortHeader({
  label,
  sort,
  setSort,
  ascending,
  descending,
  children,
}: {
  label: string;
  sort: Sorts;
  setSort: (sort: Sorts) => void;
  ascending: Sorts;
  descending: Sorts;
  children?: ReactNode;
}) {
  const { tokens } = useDesign();
  const isSelected = sort === ascending || sort === descending;
  const isDescending = sort === descending;

  const Icon: typeof SvgIcon = isDescending ? ArrowDownward : ArrowUpward;

  return (
    <TableHeaderCell
      onClick={() => {
        setSort(isDescending ? ascending : descending);
      }}
      style={{
        cursor: "pointer",
        color: isSelected ? tokens.text.high : tokens.text.low,
      }}
    >
      <Box display="flex" alignItems="center" gap="0.5rem">
        <Typography variant="Metropolis/Caption/Medium/Regular" color="inherit">
          {label}
        </Typography>
        {isSelected ? <Icon sx={{ fontSize: "1rem" }} /> : null}
        {children}
      </Box>
    </TableHeaderCell>
  );
}

function TeamMemberActionMenu({
  invitation,
}: {
  invitation: InvitationDetails;
}) {
  const lang = useLang();
  const inviteTeamMemberMutation = useInviteTeamMemberMutation();
  const cancelInvitationMutation = useCancelInvitationMutation();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "team-member-options",
  });

  const reinvitationDisabled = invitation.status !== InvitationStatus.Pending;

  const onReinviteCollaborator = async () => {
    popupState.close();
    await inviteTeamMemberMutation.mutateAsync({
      toEmail: invitation.toEmail,
      toName: invitation.toName,
    });
    displayMessage({
      type: DisplayMessage.Info,
      message: lang.collaborators.reinviteSuccess,
    });
  };

  const onCancelInvitation = async () => {
    popupState.close();
    await cancelInvitationMutation.mutateAsync(invitation._id);
    displayMessage({
      message: lang.collaborators.cancelInvitationSuccess,
      type: DisplayMessage.Error,
    });
  };

  const onRevokeAccess = async () => {
    popupState.close();
    await cancelInvitationMutation.mutateAsync(invitation._id);
    displayMessage({
      message: lang.collaborators.revokeAccessSuccess,
      type: DisplayMessage.Error,
    });
  };

  return (
    <>
      <TextIconButton size="small" {...bindTrigger(popupState)}>
        <MoreVert sx={{ fontSize: "1rem" }} />
      </TextIconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: "0.125rem" }}
      >
        <List>
          <ListItem
            button
            onClick={onReinviteCollaborator}
            disabled={reinvitationDisabled}
          >
            <ListItemText>{lang.collaborators.reinvite}</ListItemText>
          </ListItem>
          {invitation.status === InvitationStatus.Pending && (
            <ListItem button onClick={onCancelInvitation}>
              <ListItemText>{lang.collaborators.cancelInvitation}</ListItemText>
            </ListItem>
          )}
          {invitation.status === InvitationStatus.Accepted && (
            <ListItem button onClick={onRevokeAccess}>
              <ListItemText>{lang.collaborators.revokeAccess}</ListItemText>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
}

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  min-width: 25rem;
  background: ${(props) =>
    props.theme.tokens.background.neutral.lowest.default};
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.tokens.border.neutral.default};
  overflow: hidden;
  & tr:last-child td {
    border-bottom: none;
  }
`;

const TableHeaderCell = styled.th`
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid
    ${(props) => props.theme.tokens.border.neutral.default};
  white-space: nowrap;
`;

const StyledTableBodyCell = styled.td`
  vertical-align: top;
  padding: 0.5rem;
  border-bottom: 1px solid
    ${(props) => props.theme.tokens.border.neutral.default};
  white-space: nowrap;
`;

const StyledTableRow = styled.tr<{ status?: InvitationStatus }>`
  color: ${({ theme }) => theme.tokens.text.default};

  &:hover {
    color: ${({ theme }) => theme.tokens.text.high};
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.hover};
  }

  & .statusChip {
    color: ${({ status, theme }) =>
      RejectedStatuses.includes(status as InvitationStatus)
        ? theme.tokens.text.danger
        : theme.tokens.text.low};
  }

  ${({ status, theme }) =>
    !RejectedStatuses.includes(status as InvitationStatus)
      ? `&:hover .statusChip {
            color: ${theme.tokens.text.high};
          }`
      : null}
`;

function TableBodyCell({
  children,
  ...props
}: { children: ReactNode } & BoxProps) {
  return (
    <StyledTableBodyCell>
      <Box
        display="flex"
        alignItems="center"
        gap="0.5rem"
        minHeight="2rem"
        maxWidth="20rem"
        {...props}
      >
        {children}
      </Box>
    </StyledTableBodyCell>
  );
}
