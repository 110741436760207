import { type SyncErrorIssue, SyncStatusPlatform } from "@ctc/types";
import {
  Delete,
  Download,
  Error as ErrorIcon,
  OpenInNew,
} from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { getOptionFilterForOnboarding } from "~/components/imports/helpers";
import { ImportErrorReportingBox } from "~/components/imports/import-error-report-experiment/ImportErrorBox";
import {
  ImportIndicator,
  useGetTooltipTitle,
} from "~/components/imports/ImportHeaderStatus";
import { ImportListItemWrapper } from "~/components/imports/ImportListItemWrapper";
import { AdditionalFileMetadata } from "~/components/imports/ImportMetadata";
import { MoreOptionsDropdown } from "~/components/imports-v2/MoreOptionsDialog";
import {
  type ImportDrawerOptionsType,
  ImportOptionAction,
} from "~/components/imports-v2/types";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { CensoredTypography } from "~/components/ui/CensoredComponents";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useDesign } from "~/hooks/useTheme";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useDownloadCsvMutation } from "~/state/csvs";
import { FilterOperators, ImportType } from "~/types/enums";
import { type SavedFileImport } from "~/types/index";

import { useIsEmbedded } from "../../../hooks/useIsEmbedded";

function CsvStatusIndicator({
  status,
  numTxsInserted,
  numRowsParsed,
  isDuplicate,
  error,
  exchangeName,
}: {
  status: SyncStatusPlatform;
  numTxsInserted: number;
  numRowsParsed: number;
  isDuplicate: boolean;
  error?: string | SyncErrorIssue;
  exchangeName: string;
}) {
  const lang = useLang();
  const title = useGetTooltipTitle(error, exchangeName);
  const hasTxs = numTxsInserted > 0;
  const hasRows = numRowsParsed > 0;
  const isExistingFile =
    !(status === SyncStatusPlatform.Pending) && isDuplicate;

  if (status === SyncStatusPlatform.Fail && hasTxs && hasRows) {
    return (
      <Tooltip title={title ?? lang.imports.importedFileWarning}>
        <ErrorIcon color="error" />
      </Tooltip>
    );
  }

  const tooltip =
    error ??
    (isExistingFile ? lang.imports.importFileDuplicateWarning : undefined);

  if (status) {
    return (
      <ImportIndicator
        importType={ImportType.CSV}
        syncStatus={status}
        isExistingFile={isExistingFile}
        tooltipTitle={tooltip}
        exchangeName={exchangeName}
      />
    );
  }

  return null;
}

export function CSVFileItem({
  file,
  handleDelete,
  exchangeName,
}: {
  file: SavedFileImport;
  exchangeName: string;
  handleDelete: (file: SavedFileImport) => void;
}) {
  const isOnboarding = !!useUser()?.isOnboarding;
  const { tokens } = useDesign();
  const downloadCsvMutation = useDownloadCsvMutation();
  const captureAnalytics = useCaptureAnalytics();
  const {
    name,
    createdAt,
    status,
    numTxsInserted,
    firstTxDate,
    lastTxDate,
    parseErrors,
    numRowsParsed,
    errorIssue,
    isDuplicate,
  } = file;
  const date = moment(createdAt).format("lll");
  const isMobile = useIsMobile();

  const isEmbedded = useIsEmbedded();
  const lang = useLang();

  const importId = `${ImportType.CSV}_${file.id}`;

  const handleViewTxs = () => {
    const viewTxLink = getTransactionPageLink({
      state: {
        filter: {
          type: FilterOperators.And,
          rules: [
            {
              type: FilterOperators.ImportId,
              value: [importId],
            },
          ],
        },
      },
    });
    window.open(viewTxLink, isEmbedded ? "_self" : "_blank");
  };

  const handleDownloadCsv = () => {
    try {
      console.log("Downloading CSV file:", file);

      // Track download initiated event for support analytics
      captureAnalytics("csv_download_initiated", {
        fileId: file.id,
        fileName: file.name,
        exchangeName,
        importId,
        syncStatus: status,
        numTxsInserted,
        isDuplicate: !!isDuplicate,
        hasError: !!errorIssue,
        errorType: errorIssue ? errorIssue.reason : undefined,
      });

      downloadCsvMutation.mutate(file);
    } catch (err) {
      console.error("Error downloading CSV file:", err);

      // Track download error for support analytics
      captureAnalytics("csv_download_error", {
        fileId: file.id,
        fileName: file.name,
        exchangeName,
        importId,
        syncStatus: status,
        errorMessage: err.message || "Unknown error",
        errorStack: err.stack || "",
      });
    }
  };

  const options: ImportDrawerOptionsType[] = [
    // view txs
    ...(status === SyncStatusPlatform.Success
      ? [
          {
            id: ImportOptionAction.ViewTransactions,
            label: lang.reconciliation.viewTransactions,
            icon: <OpenInNew fontSize="small" />,
            handler: handleViewTxs,
          },
        ]
      : []),
    // download csv
    ...(file.isDownloadable
      ? [
          {
            id: ImportOptionAction.DownloadCSV,
            label: lang.imports.dialogOptions.downloadCsv,
            icon: <Download fontSize="small" />,
            handler: handleDownloadCsv,
          },
        ]
      : []),
    // delete
    {
      id: ImportOptionAction.Delete,
      label: lang.delete,
      icon: <Delete fontSize="small" />,
      handler: () => {
        handleDelete(file);
      },
    },
  ].filter(getOptionFilterForOnboarding(isOnboarding));

  return (
    <Box>
      <ImportListItemWrapper>
        <Box component="span" display="inline-flex" mr={1}>
          <CsvStatusIndicator
            status={status}
            error={file.errorIssue}
            numTxsInserted={numTxsInserted}
            numRowsParsed={numRowsParsed}
            isDuplicate={isDuplicate}
            exchangeName={exchangeName}
          />
        </Box>
        <Tooltip title={name ?? ""} placement="top">
          <span>
            <CensoredTypography variant="Metropolis/Body/Light" noWrap maxWidth="8.75rem">
              {name}
            </CensoredTypography>
          </span>
        </Tooltip>
        <Box display="flex" alignItems="center" ml="auto">
          {!isMobile && (
            <Box minWidth="8rem" mr={2} ml={1}>
              <Typography
                align="right"
                variant="Metropolis/Body/Light"
                style={{ color: tokens.text.low }}
                noWrap
              >
                {date}
              </Typography>
            </Box>
          )}
          <MoreOptionsDropdown options={options} tooltipPlacement="right" />
        </Box>
      </ImportListItemWrapper>
      <AdditionalFileMetadata
        name={name}
        firstTxDate={firstTxDate}
        lastTxDate={lastTxDate}
        parseErrors={parseErrors}
      />
      <ImportErrorReportingBox
        issue={errorIssue}
        handleSoftSync={() => {}}
        handleUpdate={() => {}}
        importType={ImportType.CSV}
        exchangeName={exchangeName}
        syncStatus={status}
      />
    </Box>
  );
}
