import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Menu, MenuItem, styled, Typography } from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useContext } from "react";

import { importAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { ImportFormContext } from "~/components/imports/context/index";
import {
  type ButtonGroupActions,
  TertiaryButtonGroup,
} from "~/components/ui/ui-buttons/ButtonGroup";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useIsOnboardingMobileImportExperiment } from "~/hooks/useIsOnboardingMobileImportExperiment";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useCorrectImportOption } from "~/state/csvs";
import { useGetSavedImportsByIntegration } from "~/state/importsV2";
import { ImportMethod, Size } from "~/types/enums";
import {
  type ApiImportMethod,
  type ImportMethodV2,
  type ImportOptionV2,
  type WalletImportMethod,
} from "~/types/index";

const analyticsKey = importAnalyticsKey("import_method");

type ImportMethodConfig = ButtonGroupActions & {
  type: ImportMethodV2["type"];
};

export const defaultImportMethodPriorities: ImportMethodV2["type"][] = [
  ImportMethod.OAuth,
  ImportMethod.Wallet,
  ImportMethod.ConnectWallet,
  ImportMethod.BulkWallet,
  ImportMethod.API,
  ImportMethod.CSV,
];

function isAPILikeMethod(
  importMethod: ImportMethodV2,
): importMethod is ApiImportMethod | WalletImportMethod {
  switch (importMethod.type) {
    // For now we group them under an "API" button as they are mutually exclusive.
    case ImportMethod.API:
    case ImportMethod.Wallet:
      return true;
    case ImportMethod.ConnectWallet:
    case ImportMethod.OAuth:
    case ImportMethod.CSV:
    case ImportMethod.BulkWallet:
      return false;
  }
}

export function ImportSourceToggle({
  importOption,
  importMethod,
  showForOnboardingMobileImportExperiment,
}: {
  importOption: ImportOptionV2;
  importMethod: ImportMethodV2;
  showForOnboardingMobileImportExperiment?: boolean;
}) {
  const { name, importMethods } = importOption;
  const importFormContext = useContext(ImportFormContext);
  const importOptionAccountingForBlockchain = useCorrectImportOption(
    importOption,
    importFormContext?.selectedBlockchain,
  );
  const savedImport = useGetSavedImportsByIntegration(
    importOptionAccountingForBlockchain.id,
  );
  const isOnboardingMobileImportExperiment =
    useIsOnboardingMobileImportExperiment();
  const captureAnalytics = useCaptureAnalytics();

  // Only allow oauth if it hasn't been imported yet.
  const isOAuthEnabled = !savedImport?.oauths.length;
  const lang = useLang();

  const buttonImportMethods = defaultImportMethodPriorities.flatMap(
    (method) => {
      const foundMethod = importMethods.find(({ type }) => type === method);
      if (!foundMethod) {
        return [];
      }
      if (foundMethod.type === ImportMethod.OAuth && !isOAuthEnabled) {
        return [];
      }
      return [foundMethod];
    },
  );

  if (buttonImportMethods.length < 2) return null;

  const hasBulkWallet = importMethods.some(
    (method) => method.type === ImportMethod.BulkWallet,
  );

  const buttonActions: ImportMethodConfig[] = buttonImportMethods.map(
    (method) => ({
      text: isAPILikeMethod(method)
        ? hasBulkWallet
          ? lang.imports.options["single-import"]
          : lang.imports.options[ImportMethod.API]
        : method.type === ImportMethod.OAuth
          ? lang.imports.options[ImportMethod.OAuth]({ exchange: name })
          : method.type === ImportMethod.ConnectWallet
            ? lang.imports.options["connect"]({ exchange: name })
            : lang.imports.options[method.type],
      onClick: () => {
        captureAnalytics(analyticsKey("selected"), {
          integration: importOption.id,
          importType: method.type,
        });
        importFormContext?.setImportMethod(method);
        importFormContext?.setSelectedBlockchain(undefined);
      },
      type: method.type,
      size: Size.Small,
      disabled: method.type === ImportMethod.OAuth && !isOAuthEnabled,
    }),
  );

  if (showForOnboardingMobileImportExperiment) {
    return (
      <OnboardingMobileImportDropdown
        actions={buttonActions}
        importMethod={importMethod}
      />
    );
  } else if (isOnboardingMobileImportExperiment) {
    return null;
  }

  return (
    <Box pb="1rem" pt="0.5rem">
      <TertiaryButtonGroup
        actions={buttonActions}
        selectedIndex={buttonImportMethods.indexOf(importMethod)}
      />
    </Box>
  );
}

function OnboardingMobileImportDropdown({
  actions,
  importMethod,
}: {
  actions: ImportMethodConfig[];
  importMethod: ImportMethodV2;
}) {
  const lang = useLang();
  const { tokens } = useDesign();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "erp-connect",
    disableAutoFocus: true,
  });

  return (
    <>
      <StyledTertiaryButton
        {...bindTrigger(popupState)}
        endIcon={<KeyboardArrowDown sx={{ color: tokens.text.low }} />}
      >
        <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
          {lang.onboardingV2.import.navBar.importType[importMethod.type]}
        </Typography>
      </StyledTertiaryButton>

      <Menu
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            marginTop: "-0.375rem",
          },
        }}
      >
        {actions.map((action) => (
          <MenuItem key={action.type} onClick={action.onClick}>
            <Typography
              variant="Metropolis/Body/Regular"
              color={
                action.type === importMethod.type
                  ? tokens.text.high
                  : tokens.text.low
              }
            >
              {action.text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const StyledTertiaryButton = styled(TertiaryButton)`
  padding: 0.75rem 1rem;
`;
