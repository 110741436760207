import qs from "query-string";

import { deleteRequest, get, post, put } from "~/services/core";
import { type Entity, type NewEntity } from "~/types/index";

export type GetEntitiesResponse = { entities: Entity[] };

export async function getEntities() {
  return get<GetEntitiesResponse>("/entities");
}

export async function searchEntities(search: string) {
  return get<{ entities: Entity[] }>(`/entities?search=${search}`);
}

export async function createEntity(entity: NewEntity) {
  return post<{ entity: Entity }>("/entities", entity);
}

export async function updateEntity(
  entityId: Entity["_id"],
  data: Partial<Entity>,
) {
  return put<{ entity: Entity }>(`/entities/${entityId}`, data);
}

export async function deleteEntity(entityId: Entity["_id"]) {
  return deleteRequest(`/entities/${entityId}`);
}

export async function getEntitiesTxCounts(ids: string[]) {
  return get<Record<string, number>>(
    `/entities/transaction-counts?${qs.stringify({ ids })}`,
  );
}

export async function uploadEntityCsvFile(file: File) {
  const formData = new FormData();
  formData.append("myFile", file, file.name);
  return post<{ modifiedCount: number }>("/entities/csv", formData, {
    stringify: false,
    defaultContentType: true,
  });
}
