export default {
  importInstructions: {
    abra: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Crypto Tax Calculator will ignore transfers involving 'Interest-earning' cryptocurrencies as these are not taxable events, so the number of rows imported may be less than the total number of original rows in the CSV.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Please read and follow the detailed export steps for Abra on their website: https://support.abra.com/hc/en-us/articles/360002528832-How-can-I-obtain-my-full-transaction-history- to obtain a CSV file via email.",
              1: () =>
                "Once you receive the email with the CSV file, download it and upload the CSV file here.",
              2: () =>
                "If you have multiple Abra wallets, repeat these steps for each of them.",
            },
          },
        },
      },
    },
    abstract: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Open your Abstract wallet",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the address into Crypto Tax Calculator, add an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    agoric: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Agoric in early development",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Agoric wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    airswap: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "Select the exchange from the dropdown menu",
              3: () => "In the date range select the month you traded",
              4: () => "Keep the other default settings",
              5: () => "Click 'Retrieve History'",
              6: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              7: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    akash: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Akash wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    algorand: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () => "Copy your public Algorand wallet address",
              2: () =>
                "In CTC, choose the relevant blockchain from the dropdown",
              3: () =>
                "Paste your public wallet address and click 'Import Wallet'",
            },
          },
        },
      },
    },
    altcointrader: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your AltCoinTrader account",
              1: () =>
                "Download your deposit, withdrawal and all of your trading CSV files from AltCoinTrader",
              2: () => "Upload these files here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    aptos: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Open your Aptos wallet",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the address into Crypto Tax Calculator, add an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    arbitrum: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We now support ERC-1155 tokens, however, we are unable to import your current balances for these tokens. This may result in discrepancies between the balance imported from the source API and the balance calculated by the app.",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find the wallet address that you have used for trading via Arbitrum (starting with 0x)",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    "arbitrum-nova": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the wallet address that you have used for trading via Arbitrum (starting with 0x)",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    archway: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Archway wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    atani: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Atani is currently in beta and under further development. Please let our support team know if you run into any integration issues.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your Atani account",
              1: () => "Navigate to the Portfolio tab",
              2: () =>
                "Find and click on Download Reports, then choose Historical Data",
              3: () => "Choose your preferred date range",
              4: () => "Press Download to get your report",
            },
          },
        },
      },
    },
    atomicwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Due to limitations of the Atomic Wallet Transaction History CSV, we are unable to automatically import the purchase side of any in-app trade. If you have made any trades within Atomic Wallet, please generate the acquisition side of the trade via our `Add Manual Transaction` button or by importing a custom CSV using the `Importing custom transactions` instructions below.",
            },
            title: () => "In-app trades",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "In the Wallet tab, select the coin you need your history for.",
              1: () => "Click the settings gear icon at the top-right corner.",
              2: () => "Click Export Transactions.",
              3: () =>
                "A CSV file with your transaction history for that currency should have been downloaded into your Desktop folder.",
              4: () =>
                "Import that file into Crypto Tax Calculator, and specify the currency symbol. For example, for a Bitcoin wallet you would need to paste in 'BTC'",
            },
          },
          1: {
            title: () =>
              "Note: Please do not use the History page on the menu bar to generate a CSV report. A separate report needs to be generated for each currency you’ve interacted with",
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open your Atomic wallet.",
              1: () =>
                "Select the cryptocurrency you would like to import. For example, Bitcoin or Ethereum.",
              2: () =>
                "Copy the corresponding public wallet address for that asset. To find this, you may need to click 'Recieve'.",
              3: () =>
                "In CTC, choose the corresponding blockchain network from the drop down.",
              4: () =>
                "Paste your public wallet address into CTC and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    aurora: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "We're currently experiencing some issues gathering bridging transactions, and are working on a solution. If in the meantime you are experiencing this problem, please try our manual CSV upload process instead. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "Bridge Transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Aurora wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    avalanche: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "There may be issues importing all addresses given the address you provide, because Avalanche tries to obscure relationships between addresses for security.",
              1: () =>
                "As a result, you may have to import additional addresses as outlined in this guide: https://cryptotaxcalculator.io/integrations/avalanche-tax/#please-note.",
            },
          },
          1: {
            items: {
              0: () =>
                "Due to issues with our API provider we are unable to fetch your transactions on the X and P chain. We are currently investigating alternate providers to remedy this issue.",
            },
            title: () => "Missing X and P chain transactions",
          },
          2: {
            items: {
              0: () =>
                "We now support ERC-1155 tokens, however, we are unable to import your current balances for these tokens. This may result in discrepancies between the balance imported from the source API and the balance calculated by the app.",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "To import your C-Chain address (starting with 0x), simply enter it into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    bambooRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    base: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Base wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    bch: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We also support xpub, ypub and zpub addresses however, xpub is preferred",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Bitcoin Cash wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    berachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We now support ERC-1155 tokens, however, we are unable to import your current balances for these tokens. This may result in discrepancies between the balance imported from the source API and the balance calculated by the app.",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Berachain wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    bigone: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Log in to your BigONE account",
              1: () => "Navigate to your account settings",
              2: () => "Under 'Exchange Settings' you can generate an API key",
              3: () =>
                "Enter both the 'API Key' and 'API Secret' here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    binance: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Currently we support API syncing of historical records for the following operations: Crypto Deposits & Withdrawals, Fiat Deposits & Withdrawal, Spot Trades, BNB 'Dust' Small Balances Converts and Distributions",
              1: () =>
                "Binance API has certain limitations - trades made with delisted coins cannot be synced. If you've got trades like this we recommend uploading your history via CSV instead",
              2: () =>
                "Due to Binance API limitations only the most recent 90 days of OTC trades and crypto-to-crypto conversions can be retrieved.",
              3: () =>
                "Since September 2020 Binance is not providing an API endpoint to obtain historical data for certain coin purchases from 'Cash Balance' transactions. Additionally Binance does not provide an endpoint for 'Locked Staking' (https://dev.binance.vision/t/earn-locked-staking/1453)",
              4: () =>
                "Binance does not provide an API endpoint to fetch historical data for Fiat Purchase History, Binance Leveraged Tokens (BLVT), Binance Liquid Swap (Swaps), Binance Savings Interest, USD-M Futures Income, COIN-M Futures Income, Cross Margin Trades and Isolated Margin Trades",
              5: () => "To add unsupported transactions:",
              6: () => "1. Download and open the CSV template",
              7: () => "2. Fill out your transaction details",
              8: () =>
                "3. Be sure to fill all included fields to prevent errors when uploading",
              9: () => "4. Export your document as a CSV and upload here",
              10: () => "5. Add CSV directly to the associated wallet",
            },
            title: () => "API Syncing - Missing transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "In your Binance account, go to 'API Management' from the 'Profile' dropdown. (https://www.binance.com/en/my/settings/api-management)",
              1: () =>
                "Click ‘Create API' and choose 'System generated' for API Key type and click 'Next'.",
              2: () =>
                "Set a label of your choosing, click 'Next' and complete two factor authentication.",
              3: () =>
                "Keep IP access set to 'Unrestricted’ and ensure ‘enable reading’ is checked.",
              4: () =>
                "Paste the API and Secret keys into Crypto Tax Calculator and click 'Add Binance API'.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/19oc30s0mVM",
            title: () => "How to import your binance API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Binance has recently limited the CSV exports to a maximum range of 3-month history at a time, with only 6 exports per month allowed. If you've done a lot of trading we suggest using the API import option",
              1: () =>
                "Currently our CSV import option supports Deposits, Withdrawals, Fiat, Spot, Coin-Futures, USDT-Futures, Isolated Margin and Cross Margin transactions",
              2: () =>
                "If you received a '.zip' or '.tar.gz' folder from Binance you will first need to extract the contents of the compressed folder. You can use a service such as https://extract.me/ to extract the contents. You can then upload the extracted file(s) to Crypto Tax Calculator",
              3: () =>
                "Binance does not include trading fees for P2P trades in their CSV. We recommend importing via API if you have done P2P trading.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sign in to your Binance account and go to Wallet > Overview.",
              1: () => "Click Orders > Transaction History.",
              2: () => "Click 'Export Transaction Records' in the top right.",
              3: () =>
                "Select 'Customize' for the time range and set the start/end dates (up to 12 months at a time).",
              4: () =>
                "Ensure 'All' is selected for Account, Coin and Sub-account.",
              5: () => "Click 'Generate' and wait for the report.",
              6: () => "Repeat for your entire trading history.",
              7: () =>
                "If the file is '.zip' or '.tar.gz', extract it before uploading.",
              8: () => "Upload the extracted files to Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/N7TNcCW2N7g",
            title: () => "How to import your Binance CSV files",
          },
        },
      },
    },
    "binance-smart-chain": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We now support ERC-1155 tokens, however, we are unable to import your current balances for these tokens. This may result in discrepancies between the balance imported from the source API and the balance calculated by the app.",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Binance Smart Chain wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    binancechain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Binance has officially shut down the Binance Chain (BNB Beacon Chain). As a result, historical data from this chain can no longer be obtained via API. Previously imported transactions will remain accessible in your account. You can still view your transaction history on https://explorer.bnbchain.org/ until Dec 3, 2024. To add new transactions, please use the custom CSV import option. For more details about the shutdown, please visit https://www.bnbchain.org/en/bnb-chain-fusion.",
            },
            title: () => "Binance Chain (BNB Beacon Chain) Shutdown",
          },
        },
        step: {
          0: {
            title: () =>
              "Binance has officially shut down the Binance Chain (BNB Beacon Chain). As a result, historical data from this chain can no longer be obtained via API.",
          },
        },
      },
    },
    binancedex: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "This will sync trades performed on Binance DEX as well as any Binance Chain transactions associated with this wallet",
              1: () =>
                "On some occasions there is no data available for the receiving of BNB and BEP2 Tokens via a Cross Chain Bridge into your Binance Chain wallet. In this case you may have to manually enter respective 'receive' transactions into Crypto Tax Calculator",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find the Binance Chain (BEP2) wallet address which you used for trading via Binance DEX",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    binanceus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Currently we support API syncing of historical records for the following operations: Crypto Deposits & Withdrawals, Fiat Deposits & Withdrawal, Spot Trades, BNB 'Dust' Small Balances Converts and Distributions",
              1: () =>
                "Binance API has certain limitations - trades made with delisted coins cannot be synced. If you've got trades like this we recommend uploading your history via CSV instead",
              2: () =>
                "Due to Binance API limitations only the most recent 90 days of OTC trades and crypto-to-crypto conversions can be retrieved.",
              3: () =>
                "Since September 2020 Binance is not providing an API endpoint to obtain historical data for certain coin purchases from 'Cash Balance' transactions. Additionally Binance does not provide an endpoint for 'Locked Staking' (https://dev.binance.vision/t/earn-locked-staking/1453)",
              4: () =>
                "Binance does not provide an API endpoint to fetch historical data for Fiat Purchase History, Binance Leveraged Tokens (BLVT), Binance Liquid Swap (Swaps), Binance Savings Interest, USD-M Futures Income, COIN-M Futures Income, Cross Margin Trades and Isolated Margin Trades",
              5: () => "To add unsupported transactions:",
              6: () => "1. Download and open the CSV template",
              7: () => "2. Fill out your transaction details",
              8: () =>
                "3. Be sure to fill all included fields to prevent errors when uploading",
              9: () => "4. Export your document as a CSV and upload here",
              10: () => "5. Add CSV directly to the associated wallet",
            },
            title: () => "API Syncing - Missing transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Sign in to your BinanceUS account",
              1: () =>
                "On the top right of the navbar hover over the 'Profile' icon and click on 'API Management' from the dropdown (https://www.binance.com/en/my/settings/api-management)",
              2: () =>
                "Enter the label for a new API as 'Crypto Tax Calculator' and click on ‘Create API’ (yellow button)",
              3: () => "You may have to enter some verification information",
              4: () => "Once created, copy the 'API Key' and 'Secret Key'",
              5: () =>
                "Click Edit restrictions and uncheck 'Enable Spot Trading'. Also, be sure that 'Enable Withdrawals' is unchecked",
              6: () =>
                "Double check that 'Can Read' is the only option checked!",
              7: () =>
                "Leave the IP access restriction selection as 'Unrestricted'",
              8: () =>
                "Paste the copied 'API Key' and 'Secret Key' here into Crypto Tax Calculator and click 'Add API'",
              9: () =>
                "Syncing this API can take approximately 20 minutes due to restrictions on BinanceUS's side",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Currently our CSV import option only supports Deposits, Withdrawals, and Fiat/Spot Wallet Trades. If you have other types of historical records please import via our 'setup Autosync' option.",
              1: () =>
                "If you received a '.zip' or '.tar.gz' folder from BinanceUS you will first need to extract the contents of the compressed folder. You can use a service such as https://extract.me/ to extract the contents. You can then upload the extracted file(s) to Crypto Tax Calculator.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sign in to your BinanceUS account and click on 'Wallet' on the navigation bar.",
              1: () =>
                "Select the 'Export Reports' option in yellow writing on the right side.",
              2: () =>
                "Under Custom Reports, select All for the Asset and Transactions type; for the Range, select the desired period (up to a year maximum at a time, see step 5), and select CSV for the File Format.",
              3: () =>
                "Click on 'Generate' and wait for the report to be generated - this may take some time.",
              4: () =>
                "Repeat the export for every year you have been trading on BinanceUS.",
              5: () =>
                "If the file is a '.zip' or '.tar.gz' format, you will need to extract the contents of the compressed folder first before uploading; please refer to the notes.",
              6: () => "Upload the files to Crypto Tax Calculator.",
            },
            title: () => "For Deposits & Withdrawals and Spot Account Trades:",
          },
        },
      },
    },
    bingx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your BingX account and navigate to Assets > Asset Records",
              1: () =>
                "Click 'Export' on the transaction history page or use this link: https://bingx.com/en/transaction-history/?timeKey=ninetyDaysFlag",
              2: () =>
                "Select the Time Range and choose all Accounts: Fund, USD-M Perpetual Futures, Coin-M Perpetual Futures, and Standard Futures.",
              3: () =>
                "Set the File Type to Excel and the Data Format to Standard, then click 'Generate'.",
              4: () =>
                "Wait for the Excel file to generate and download it once it is ready.",
              5: () =>
                "Open the Excel file, which will contain four separate sheets. Save each sheet as an individual .csv file and import them into CTC one by one.",
            },
          },
        },
      },
    },
    bitaroo: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Bitaroo account",
              1: () =>
                "Download your trading, deposit and withdrawal CSV files from Bitaroo",
              2: () => "Upload these files here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitbuy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Bitbuy's detailed report for trading activity sometimes don't include deposits or withdrawals to and from Bitbuy. If you have deposits and withdrawals please reach out to our support for assistance in importing these transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sign in to your Bitbuy account here (https://app.bitbuy.ca/sign-in).",
              1: () =>
                "Click the drop-down menu next to your name (top right) and select 'Documents'.",
              2: () =>
                "To get your transaction history before May 25, 2024, go to the 'Legacy Documents' tab and download your files.",
              3: () =>
                "For all activity after May 25, 2024, go to the 'Statements' tab and download the CSV files.",
              4: () =>
                "Upload all your downloaded CSV files here into Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    bitcoindotcomau: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Bitcoin.com.au account",
              1: () => "In the top menu, go to Transactions → Tax",
              2: () =>
                "Click the 'Get started with Crypto Tax Calculator' button",
              3: () =>
                "You’ll be redirected to Crypto Tax Calculator (CTC), where your account will be automatically linked for seamless integration",
            },
          },
        },
      },
    },
    bitfinex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Currently we do not support Bitfinex derivative trading data - if you have performed derivative trading via Bitfinex please contact support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to Bitfinex.",
              1: () =>
                "In the top right corner, hover over the person icon, and select 'API keys' from the dropdown. This should navigate you to 'https://www.bitfinex.com/api'.",
              2: () => "Click on the 'Create New Key' tab.",
              3: () =>
                "Ensure default permissions are unchanged and all ‘Read’ permissions have been enabled.",
              4: () =>
                "At the bottom of the page, enter a name for the API key (e.g. Crypto Tax Calculator) in the 'Label Your API Key' field, and then click the 'Generate API key' button.",
              5: () =>
                "You may have to enter your 2FA token if you have 2FA enabled.",
              6: () =>
                "Once you receive a verification email from Bitfinex, confirm the new API key.",
              7: () =>
                "Copy the 'API key' and 'API key secret' and enter them both into Crypto Tax Calculator.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Currently we do not support Bitfinex derivative trading data - if you have performed derivative trading via Bitfinex please contact support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to Bitfinex.",
              1: () =>
                "In the top right corner, hover over 'Wallet' and select 'Reports' from the dropdown. This should navigate you to 'https://report.bitfinex.com'.",
              2: () => "Click on the calendar icon at the top of the page.",
              3: () =>
                "Select a custom range of dates that include your entire trading history.",
              4: () => "Click on the 'Export' button, a popup should appear.",
              5: () =>
                "In the 'Data to Export' field select 'Ledgers' and 'Movements' only. Select the 'Date Format' to be 'DD-MM-YY'. Check the box which says 'Display Milliseconds'",
              6: () =>
                "Click the 'Export' button at the bottom of the popup. This will generate CSV files which will be sent to your EMAIL.",
              7: () =>
                "In your email client, open the email and click 'Download CSV'. This will download a .zip file containing two CSVs (one for ledgers and one for movements).",
              8: () => "Extract and upload BOTH CSV to Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    bitflyer: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login to Bitflyer",
              1: () => "Navigate to API",
              2: () => "Click on 'Generate New Key' button",
              3: () =>
                "Copy the 'API Key' and 'API Secret' and enter them both here into Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your BitFlyer account",
              1: () => "Download your Transaction History",
              2: () => "Upload the CSV file into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitforex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "If the CSV files fail to upload, try changing the extension from '.csv' to '.xlsx' and try again.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to your BitForex account",
              1: () =>
                "Contact support and ask them for your trading CSV files",
              2: () => "Upload these files here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitget: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "To retrieve older data, please import your trades via CSV.",
            },
            title: () =>
              "Bitget’s API only provides the last 90 days of trade history",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to Bitget",
              1: () =>
                "Navigate to API management from the navigation bar at the top",
              2: () => "From the drop-down menu, choose API Management",
              3: () => "Click on Create a New API",
              4: () => "Click on System-Generated API Key",
              5: () => "In the pop-up window, create a passphrase for your API",
              6: () =>
                "Under Permissions, select Read-only across all checkboxes",
              7: () => "Complete the two-factor verification and click 'Next'.",
              8: () => "Copy across your API key and Secret",
            },
          },
        },
      },
    },
    bitgo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Make sure your report has encompassed all the years which you have been trading cryptocurrency",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your BitGo account on a web browser",
              1: () =>
                "Navigate to the 'Reports' section from the navigation bar at the top",
              2: () => "Select 'All coins/tokens' in the dropdown menu",
              3: () => "Download your report as a CSV file",
              4: () =>
                "Upload the file you just downloaded here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitkeep: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Only Solana wallet addresses stored in your BitKeep wallet are currently supported",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find the Solana blockchain wallet address that you have used for trading via BitKeep",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    bitmart: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login to your Bitmart account.",
              1: () => "Hover the Account logo in the upper right corner.",
              2: () => "Click Account.",
              3: () => "Click API management.",
              4: () => "Input your preferred Memo.",
              5: () => "Click submit.",
              6: () => "Copy the API key, secret and Memo.",
              7: () => "Paste into the relevant fields on CTC",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "BitMart does not have an API with full transaction history, nor a CSV available in their UI. You will need to email them to request the CSV export of your trade history. This can take 5 days.",
              1: () =>
                "Once you have received the trade history export you can import it into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitmax: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "AscendEX API currently only supplies the last 30 days of trade history",
              1: () => "You will need to create new API keys after 90 days",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your AscendEX account",
              1: () => "Click on the avatar icon in the top right corner",
              2: () => "Click on 'API Setting' and click 'New API key'",
              3: () =>
                "Check the ‘View’ Permission for the key, and enter your email verification code and 2FA code",
              4: () => "Click 'Generate API key'",
              5: () => "Copy across your API key and Secret",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "AscendEX currently does not have a CSV export for your deposit/withdrawals. You will need to add this manually.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your AscendEX account",
              1: () =>
                "Select 'My Order' (https://bitmax.io/en/order/cash-order)",
              2: () => "Click 'Request to Export'",
              3: () =>
                "You can then click the button on the right 'My Exported Files' and download the file once complete",
              4: () =>
                "Upload the downloaded CSV file directly here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitmex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Make sure you have not enabled any order permissions when creating the API Key!",
              1: () =>
                "If the API is not importing your data, it may be due to you not completing BitMEX's new KYC verification policy. If this happens to you, please complete their KYC verification before trying again.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your BitMEX account",
              1: () => "Click on your username in the top right corner",
              2: () =>
                "Navigate to the account page by clicking 'Account & Security'",
              3: () =>
                "On the left-hand side of the page select 'API Keys' (https://www.bitmex.com/app/apiKeys)",
              4: () =>
                "When creating the API Key make sure the key permissions are blank, as indicated by '-'",
              5: () => "Double check you have not enabled any permissions",
              6: () => "Enter your 2FA if required and click 'Create API Key'",
              7: () =>
                "Copy the ID and Secret, and enter it as the 'API Key' and 'API Secret' here into Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () => "You will need to save the CSV for every page of data",
              1: () =>
                "If the CSV is not importing, it may be due to your data having a different timestamp format than what we were expecting. Please reach out to our support if this occurs.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your BitMEX account",
              1: () => "Click on your username in the top right corner",
              2: () => "Navigate to account and security",
              3: () =>
                "On the left-hand side of the page select 'Wallet -> Balances' (https://www.bitmex.com/app/wallet)",
              4: () => "In the top right corner click 'Save as CSV'",
              5: () =>
                'Please make sure that the name of the CSV file you just downloaded starts with "Wallet History". There are other types of downloadable CSV files which we don\'t support',
              6: () => "Upload the CSV file here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitpanda: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Log in to Bitpanda and select the panda icon in the top right corner.",
              1: () => "Select API key and enter a name for your API key.",
              2: () => "Under scope, check transaction, balance and trading.",
              3: () => "Select generate new API key.",
              4: () =>
                "Click on Confirm, which will generate a confirmation email.",
              5: () =>
                "Copy the API Key, and enter it as the 'API Key' and 'API Secret' here into Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Log in to Bitpanda and select the panda icon in the top right corner.",
              1: () =>
                "Select history then select transaction history in the top right.",
              2: () =>
                "Export the CSV and import it into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitrue: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Currently our CSV import option only supports Deposits, Withdrawals, and Trade History.",
              1: () =>
                "If you have any records we don't support and you would like to request it to be added, please contact us via our chat support",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Sign in to Bitrue",
              1: () =>
                "Hover over Trade in the top left corner and select Spot. Then click Order History. Alternatively you can try this direct link (https://www.bitrue.com/order/spot/history-orders)",
              2: () => "Click export in the top right",
              3: () =>
                "In the modal popup, select “Trade History” as the data option",
              4: () =>
                "Select the dates you were trading and click the button “OK”",
              5: () =>
                "Bitrue only allows 3 months at a time, so you might need to download multiple times to cover your entire trading history",
            },
          },
        },
      },
    },
    bitso: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "In the top right navigation bar, click on the user profile drop down option and click on History (https://bitso.com/r/history/alltransactions)",
              1: () =>
                "At the bottom of the transactions table, you should see 'Download your transactions in a CSV file' followed by four options: deposits, withdrawals, conversions and trades",
              2: () =>
                "Click on each option, then take the four CSV files you just downloaded and import them into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bitsong: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your BitSong wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    bitstamp: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Repeat the steps above to add API keys for all associated accounts.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to My profile > Settings > Account settings > API access",
              1: () => "Click 'Create new API key'",
              2: () => "Click the 'Advanced Settings' toggle",
              3: () =>
                "Select an appropriate account under 'Associated Account",
              4: () =>
                "Ensure only 'General Permissions > View account balance', 'General Permissions > View your transactions' and 'Earn > View and manage Earn functionality' are toggled on and click 'Create new API key'",
              5: () => "Enter your Bitstamp 2FA code and click 'Confirm'",
              6: () =>
                "Copy the API key and Secret into Crypto Tax Calculator. Do NOT click 'Add Bitstamp API' yet. Note the Secret is only available for a few minutes and will not be shown again. If you lose your secret, you will need to create a new API key.",
              7: () => "In Bitstamp, click 'Activate API key'",
              8: () =>
                "Bitstamp will send you a confirmation email. Click the 'Activate API key' button on that email.",
              9: () => "Click 'Add Bitstamp API'",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Sub Accounts transactions are already included in the downloaded CSV.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your Bitstamp account",
              1: () =>
                "Go to the Transactions page by clicking the user icon in the top-right corner, then 'Activity', and then 'Transaction history' in the left sidebar (https://www.bitstamp.net/account/transactions)",
              2: () => "Click the 'Open export options' button",
              3: () =>
                "Select the 'Date range' that covers all your transaction history",
              4: () => "Choose 'All' for the currencies",
              5: () => "Choose 'All' for the Transaction types",
              6: () => "Choose 'RFC 4180 (new)' for the CSV format",
              7: () =>
                "Click the 'Export selection' button to download the CSV file",
              8: () => "Upload this CSV here into Crypto Tax Calculator",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                "Click 'Connect to Bitstamp' to go to the Bitstamp Exchange",
              1: () => "Log in to your Bitstamp account.",
              2: () =>
                "If your account has 2FA enabled, enter the required code. Otherwise, skip this step.",
              3: () => "Select the appropriate sub account",
              4: () => "Click the 'Accept' button",
            },
          },
        },
      },
    },
    bittorrentchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "We're currently experiencing some issues gathering ERC-1155 tokens and bridging transactions, and are working on a solution. If in the meantime you are experiencing this problem, please try our manual CSV upload process instead. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155 Tokens & Bridge Transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your BitTorrent Chain wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    bittrex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login to Bittrex",
              1: () => "Navigate to API Keys",
              2: () => "Click on 'Add new key' button",
              3: () =>
                "Ensure 'READ INFO' permission for the new key is enabled",
              4: () => "Once done, click the 'Save' button",
              5: () => "Copy the API Key and API Secret",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "To download your deposit/withdrawal history CSV you need to file a support ticket with Bittrex. Once you receive these CSVs you can upload them to Crypto Tax Calculator. See more details here: https://bittrex.zendesk.com/hc/en-us/articles/360001359006-Where-s-my-Order-History-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Login to Bittrex, navigate to 'Orders' in the top corner, and scroll down to Order History.",
              1: () =>
                "Click on ‘Download History’ button and select the year you traded and click on 'Download'.",
              2: () =>
                "You can only download one year at a time, but you should add each year that you traded to Crypto Tax Calculator.",
              3: () =>
                "Make sure you do not open or resave the file before importing because you might affect the format of the CSV.",
            },
            title: () => "For Order History:",
          },
        },
      },
    },
    bitvavo: {
      api: {
        note: {
          0: {
            items: {
              0: () => "We currently import Deposits, Withdrawals and Trades",
              1: () =>
                "We can't import Staking income as the Bitvavo API currently does not support this",
              2: () =>
                "If you use Staking, it is possible that the balances for staked currencies reported by us will not match those reported by Bitvavo unless you manually enter the Staking income transactions",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to Bitvavo exchange",
              1: () =>
                "Navigate to API settings page (https://account.bitvavo.com/user/api)",
              2: () => "Click 'Request new API key' button",
              3: () =>
                "Generate read-only API keys that do not enable trading or withdrawals",
              4: () =>
                "Insert the API key and secret here into Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Bitvavo exchange",
              1: () => "In the top right corner, click your profile name",
              2: () => "Click 'Transaction history'",
              3: () => "Click the download button in the top right corner",
              4: () => "Upload the CSV here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    bityard: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Bityard account",
              1: () => "For Trade History:",
              2: () =>
                "Navigate to the 'Orders' section and select 'Spot' - 'Trade History' from the menu (https://www.bityard.com/en-US/account/fm/spotHistory/2)",
              3: () =>
                "Click the 'Export' button and select the desired time period for the exported data",
              4: () => "For Deposit & Withdrawal History:",
              5: () =>
                "Navigate to the 'Assets' section and select 'My Assets' - 'Funds History' - 'Deposit History' or 'Withdraw History' from the menu (https://www.bityard.com/en-US/account/fm/fundHistory/1)",
              6: () =>
                "Click the 'Export' button and select the desired time period for the exported data",
              7: () => "Upload both CSVs here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    blast: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Blast wallet address (starting with 0x) into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    blockchaindotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You can only import your Deposit and Withdrawal History via the CSV import. Additionally, the CSV doesn't provide information regarding fees paid for these deposits/withdrawals",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log into your Wallet (https://login.blockchain.com/#/login) and select a currency on the left (e.g. Bitcoin)",
              1: () =>
                "If you have any balance for the selected cryptocurrency, you will see a 'Download' button - click this button",
              2: () =>
                "You will be asked to select a range of dates you want to download your transactions for. Make sure you select the period that covers your entire transaction history for the selected cryptocurrency",
              3: () =>
                "Click on 'Generate Report'. This button will change to 'Download Report' once the report is ready to be downloaded. Click on this button to download your report",
              4: () =>
                "Upload the file you just downloaded here into Crypto Tax Calculator",
              5: () =>
                "Make sure you repeat this process for all the cryptocurrencies you are storing on our Wallet",
            },
          },
        },
      },
    },
    blockearner: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Login to Block Earner and go to the Transactions page (https://app.blockearner.com.au/transactions)",
              1: () =>
                "Download the necessary files based on your trading history.",
              2: () =>
                "The transaction file can be found in the top right of the screen within each respective account: Loans, DeFi, Crypto Buy/Sell and Cash.",
              3: () =>
                "Upload all downloaded files here into Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    blockfi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "We ignore the 'Trade' Transaction Type on the 'Transaction History' CSV file. Instead, we use the more accurate information in the 'Trading' CSV file to get your trading data.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log into your BlockFi account and click your profile icon in the top right",
              1: () => "Select 'Reports' from the dropdown menu",
              2: () =>
                "At the bottom of the page, you will see 'Trading' and 'Transaction History'",
              3: () =>
                "For 'Trading' select the date range you wish to export (or download all) and click the 'Download .csv' button - this will download all your detailed trading data",
              4: () =>
                "For 'Transaction History' select the date range you wish to export (or download all) and click the 'Download .csv' button - this will download all your detailed transaction data such as deposits, withdrawals, interest payments, etc",
              5: () => "Upload both CSV files here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    boba: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "We're currently experiencing some issues gathering ERC-1155 tokens, and are working on a solution. If in the meantime you are experiencing this problem, please try our manual CSV upload process instead. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Boba wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    brave_software: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open Brave Wallet in the browser",
              1: () =>
                "From the Accounts tab, click to copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste your public wallet address and click 'Import Wallet'",
            },
          },
        },
      },
    },
    bsv: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We also support xpub, ypub and zpub addresses however, xpub is preferred",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Bitcoin SV wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    btc: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We also support xpub, ypub and zpub addresses. We recommend you use xPub. Read this guide (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator) for more details.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () =>
                "Copy your public wallet address starting with 'xpub...'",
              2: () =>
                "Paste your public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    btcAltNetwork: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Enter your " +
                d.exchangeDisplayName +
                " wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'. Please note that xpub, ypub, and zpub addresses are not currently supported.",
            },
          },
        },
      },
    },
    btcalpha: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Under the accounts section of your account navigate to the API section",
              1: () => "Create a new API key",
              2: () => "Copy and paste the keys into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    btccomau: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to BTC.com.au",
              1: () =>
                "Navigate to Settings > Trade Reports per the menu on the left of your screen",
              2: () =>
                "Click on 'Single Report' button on the right of the line just below the heading 'Activity Reporting'",
              3: () =>
                "Choose 'Transaction' in the 'Report Type' dropdown list",
              4: () => "Fill out the start and end dates",
              5: () => "Click the button 'Create'",
              6: () =>
                "Click the button 'Create Report' in the confirmation popup",
              7: () =>
                "After the prompt that report creation is successful, you may still need to refresh the page to see the new report",
              8: () =>
                "Click 'Download Report' in the latest row under Reports Available to Download",
              9: () =>
                "Upload the downloaded CSV here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    btcmarkets: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "For more information see our detailed steps: https://cryptotaxcalculator.io/integrations/btcmarkets-tax/",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Login to BTC Markets and navigate to the ‘API Key’ page (https://app.btcmarkets.net/account/apikey)",
              1: () =>
                "Click ‘Add new API Key’ ensure permissions are set to ‘Read Only’, then click ‘Submit’",
              2: () =>
                "Copy the secret and paste it into ‘Secret Key’ in Crypto Tax Calculator",
              3: () =>
                "Click ‘Submit’, then click the copy button on the API key and paste it into Crypto Tax Calculator.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Login to BTC Markets and Navigate to Buy/Sell > Trade History",
              1: () =>
                "Click on 'Export to CSV' (This should download as 'AccountTradingReport_XXX.csv')",
              2: () => "Upload the CSV file here Crypto Tax Calculator",
            },
            title: () => "For Trading Data:",
          },
          1: {
            items: {
              0: () =>
                "Login to BTC Markets and Navigate to Account > Transaction History",
              1: () =>
                "Make sure Currency is 'ALL' and click on 'Export to CSV' (This should download as 'TransactionReport_XXX.csv').",
              2: () => "Upload the CSV file here to Crypto Tax Calculator",
            },
            title: () => "For Deposits & Withdrawals:",
          },
        },
      },
    },
    bybit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "There may be some small balance discrepancies between your balances on Bybit and the balances reported in Crypto Tax Calculator. This is an issue on Bybit's side and is due to small rounding differences between the Bybit PNL records and their Wallet records. If there are any significant discrepancies please reach out to our support via our web chat.",
              1: () =>
                "Bybit API only imports conversion transactions from the last 6 months. If you have transactions older than that, please import them using a CSV file",
              2: () =>
                "Due to limitations on Bybit's end, the API key will expire after three months from the creation time",
              3: () =>
                "Your main accounts API will include sub account data, so there is no need to create a second one as this could lead to duplicates within your account.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Login to Bybit, hover over your name in the top right corner, and select 'API' (https://www.bybit.com/app/user/api-management)",
              1: () =>
                "Click on the 'Create New Key' button. If you haven't set up 2FA already, you will be prompted to do so.",
              2: () => "Select 'system-generated API keys' as the API Key Type",
              3: () =>
                "Select 'API Transaction' as the API key usage, and enter a name of your choosing.",
              4: () =>
                "Change the top toggle to 'Read-Only' and leave the IP address field blank.",
              5: () =>
                "Click the checkboxes for 'Unified Trading' and 'Assets'",
              6: () =>
                "Click 'Submit' and then copy and paste the API key & secret here into CTC",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "We STRONGLY recommend importing via the 'Sync via API' option and directly connecting to Bybit API as this has more accurate data.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "We require you to upload your 'Derivative Closed P&L History' and 'Asset History' CSV files.",
              1: () =>
                "You can generate the 'Derivative Closed P&L History' file via this link (https://www.bybit.com/user/assets/order/closed-pnl/inverse).",
              2: () =>
                "Go to Orders > Derivatives Order > Close P&L > Click on the 'Export' button from the upper right corner.",
              3: () =>
                "NOTE: Traders can export up to two years of data while the maximum data range exportable in a single batch is three months.",
              4: () =>
                "You can generate your 'Asset History' file via this link (https://www.bybit.com/user/assets/exportStatment).",
              5: () =>
                "NOTE: when exporting your 'Account Statement' within your Asset History, ensure that all the Account Types are selected (Spot, Funding, Unified Trading, USDC Derivatives).",
              6: () => "Importantly, we do not require the trade history CSV",
              7: () =>
                "The Closed P&L report shows the gains/losses earned from trading futures etc.",
              8: () =>
                "The Asset History report will allow you to import the deposit/withdrawals",
            },
          },
        },
      },
    },
    calebandbrown: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to the Portal at app.calebandbrown.com",
              1: () =>
                "Navigate to the Transactions page (left hand side menu)",
              2: () =>
                "To the top-right of the Transaction table, is the Export Summary button",
              3: () =>
                "Choose Transaction Summary, select 'All time' as the Time Period and click export",
            },
          },
        },
      },
    },
    canto: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "We're currently experiencing some issues gathering ERC-1155 tokens and bridging transactions, and are working on a solution. If in the meantime you are experiencing this problem, please try our manual CSV upload process instead. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155 Tokens & Bridge Transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Canto wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    cardano: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Only enter ONE address from each wallet. We perform a lookup and pull in the data for all other addresses in the wallet automatically.",
              1: () =>
                "Importing multiple addresses from the same wallet will result in duplicate transactions.",
              2: () =>
                "Currently we do not support obtaining the complete transaction history of wallets with more than 1000 individual addresses, or when the number of transactions is more than 2000 in some cases. We are working to provide additional support for such wallets.",
            },
            title: () => "Multiple wallet addresses",
          },
          1: {
            items: {
              0: () =>
                "If you import an address starting with ‘addr1’, we automatically change it to the account owner’s address that starts with ‘stake1’.",
              1: () =>
                "This enables us to import all other addresses you may create in the same wallet and capture your staking rewards.",
            },
            title: () => "Why is my address stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () =>
                "Copy your public wallet address starting with 'addr1...' or 'stake1...'",
              2: () =>
                "Paste your public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    cashapp: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Login to Cash App and navigate to the Activity Page (https://cash.app/account/activity)",
              1: () => "Click 'Statements'",
              2: () => "Click 'Export CSV'",
              3: () => "You can now upload this CSV to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    celestia: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Celestia wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    celo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "We're currently experiencing some issues gathering ERC-1155 tokens, and are working on a solution. If in the meantime you are experiencing this problem, please try our manual CSV upload process instead. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Celo wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    celsius: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Celsius account",
              1: () =>
                "Click on the dropdown menu in the upper right of the platform",
              2: () => "Select 'API' and then click 'Create New Key'",
              3: () =>
                "Copy the generated 'API Key' here into Crypto Tax Calculator, enter an optional nickname, and then click 'Add API' to sync your API",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "For more detailed steps with screenshots, view the support page at https://support.celsius.network/hc/en-us/articles/360004491118-How-do-I-export-my-transaction-history-as-CSV-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Open the Celsius App and tap on the 'Celsius logo'.",
              1: () => "Tap on the 'Profile' button.",
              2: () =>
                "Tap on the 'Download transaction history' under the setting section.",
              3: () =>
                "Once you tap on the Download transaction history button, check the email that you used to register your Celsius wallet. Click on the Download button in the email.",
              4: () =>
                "Upload the CSV into Crypto Tax Calculator using the import button.",
            },
          },
        },
      },
    },
    cex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login to Cex.io",
              1: () =>
                "Navigate to API Access page https://cex.io/trade/profile#/api",
              2: () => "Click the ‘Generate API Key’ button",
              3: () =>
                "Navigate to Permissions to enable the ‘account balance’ and ‘open orders’ permissions only",
              4: () => "Click the ‘Generate Key’ button",
              5: () => "Copy the User ID, API Key and API Secret",
              6: () => "Click the ‘Activate’ button next to your new API Key",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to Cex.io",
              1: () =>
                "Navigate to Finance > Transactions https://cex.io/finance/transactions",
              2: () =>
                "Select ‘All transaction types’ with dates ranging through all trades",
              3: () =>
                "Click on ‘Download to CSV’ button before uploading to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    changelly: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to: My Account -> History https://changelly.com/history",
              1: () => "Press 'Export CSV'",
              2: () => "Upload the CSV file here",
            },
          },
        },
      },
    },
    chihuahua: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Chihuahua wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    chiliz: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Files should follow the format: Timestamp (UTC), Exchange, Asset Traded, Amount Traded, Asset Received, Amount Received",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "You can import transactions from Chiliz exchange and associated apps e.g. Socios",
            },
          },
        },
      },
    },
    clvchain: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "We're currently experiencing some issues gathering ERC-1155 tokens, and are working on a solution. If in the meantime you are experiencing this problem, please try our manual CSV upload process instead. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your CLV P-Chain wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    coin98: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Only Solana wallet addresses stored in your Coin98 wallet are currently supported",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find the Solana blockchain wallet address that you have used for trading via Coin98",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    coinbase: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Please ensure that your Coinbase currency setting matches your local currency at https://accounts.coinbase.com/profile > Preferences > Currency.",
              1: () =>
                "This is a read-only API connection. For security reasons, Crypto Tax Calculator does not support the connection of keys that enable trading or withdrawal. Read more at  https://cryptotaxcalculator.io/privacy-policy",
              2: () =>
                "There might be some transactions missing due to involving currency that Coinbase does not support.(https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/lost-assets)",
              3: () =>
                "If successfully imported via API keys, do not use the 'Connect Coinbase' method as this will cause duplicates.",
              4: () =>
                "Staking withdrawals and deposits are ignored, but this does not affect reports.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log into the Coinbase Developer Platform (https://portal.cloud.coinbase.com/).",
              1: () => "Go to API keys.",
              2: () =>
                "In 'Create API key', set a nickname, select your Portfolio, and choose 'View (read-only)' permissions.",
              3: () => "Under 'Advanced Settings' select ECDSA",
              4: () => "Click 'Create & download'.",
              5: () => "Complete 2-factor authentication.",
              6: () =>
                "Copy the API Key Name and Secret and paste them into Crypto Tax Calculator.",
              7: () =>
                "Your API secret is shown only once. Download the JSON file as a backup. If lost, create a new API key in Coinbase and update it in Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/7GbjlA9B3bI",
            title: () => "How to import your Coinbase API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "If you have used fill trades, the 'Sync via API' or 'Connect Coinbase' options may give more accurate results.",
              1: () =>
                "Please ensure that your Coinbase currency setting matches your local currency at https://accounts.coinbase.com/profile > Preferences > Currency.",
              2: () =>
                "Staking withdrawals and deposits are ignored, but this does not affect reports.",
            },
          },
          1: {
            items: {
              0: () =>
                "Advanced Trade rows do not contain complete pricing information.",
              1: () =>
                "This may lead to minor differences between your CSV import and the Advanced Trade details displayed within Coinbase.",
              2: () =>
                "For best results, try importing via our API integration.",
            },
            title: () => "Coinbase API best for Advanced Trades",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Go to Accounts > Statement (https://accounts.coinbase.com/statements)",
              1: () =>
                "In 'Generate custom statement,' keep the fields set to 'Transaction history,' 'All Assets,' 'All transactions,' and 'All time.'",
              2: () => "Select 'CSV' and click 'Generate.'",
              3: () => "Upload the report.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/nm2-aOO5iI4",
            title: () => "How to import your Coinbase CSV files",
          },
        },
      },
      oauth: {
        note: {
          0: {
            items: {
              0: () =>
                "Please ensure that your Coinbase currency setting matches your local currency at https://accounts.coinbase.com/profile > Preferences > Currency.",
              1: () =>
                "If you have connected using this method you do not need to update your keys as per the February 2025 legacy key deprecation.",
              2: () =>
                "If the connection is not working, please click on 'Reauthorize'.",
              3: () =>
                "Staking withdrawals and deposits are ignored, but this does not affect reports.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Click 'Connect Coinbase' to open a new tab.",
              1: () => "Log in to your Coinbase account.",
              2: () => "Authorize read-only access.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/qHL8O_alo8Q",
            title: () => "How to import your Coinbase data via Oauth",
          },
        },
      },
    },
    "coinbase-wallet": {
      "bulk-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open Coinbase wallet on your mobile or in your browser",
              1: () =>
                "Click your wallet name in the top left side of the screen",
              2: () => "Click 'add & manage wallets'",
              3: () => "Select a wallet",
              4: () => "Click export public addresses",
              5: () => "Click Copy",
              6: () =>
                "Paste the list of wallets in the textbox exactly as it was copied",
              7: () => "All your wallets in Coinbase wallet will be connected",
            },
          },
        },
      },
    },
    coinbaseexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Coinbase Exchange account.",
              1: () => "Create a new API key.",
              2: () => "Ensure to set appropriate permissions (view-only).",
              3: () =>
                "Once created, paste the API Key and Secret into Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinbaseprime: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "We've fixed an issue affecting TWAP transactions imported before March 2025. To restore missing transactions, please delete and re-add your API keys. Performing a hard-sync will also import the missing transactions, but may result in duplicate entries, so be sure to review and remove any duplicates. For more information, please contact support.",
            },
            title: () => "TWAP Transactions Update",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to Coinbase Prime",
              1: () =>
                "On the lower-left corner of the page, click the Gear settings icon",
              2: () => "Click APIs",
              3: () => "Next to 'Activated Keys', click 'Create API Key'",
              4: () =>
                "In the pop-up window, enter the 'API Name', 'Expiration Date' and view-only 'Access Type'",
              5: () => "Click 'Continue'",
              6: () => "Verify your identity with YubiKey",
              7: () => "Wait for consensus approvals",
              8: () => "Under 'Pending Keys', find your API Key",
              9: () => "Click 'Activate Key'",
              10: () =>
                "Click 'Activate API Key' and verify with YubiKey once again",
              11: () =>
                "Copy and save the generated API key, secret and passphrase. Paste the Access Key to ‘API key’, the Passphrase to ‘Passphrase’ and the Signing Key to ‘Secret’.",
              12: () =>
                "More detailed instructions can be found at: https://help.coinbase.com/en/prime/coinbase-prime-api/creating-a-coinbase-prime-api",
            },
          },
        },
      },
    },
    coinbasepro: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "If you have more than 500 OTC trades, we recommend uploading the OTC trades using a CSV. Please be aware duplicate OTC trades may arise when combining API and CSV in this way.",
              1: () =>
                "All OTC trades imported via API contain 'OTC' in the transaction description, which combined with bulk deletion can be used to easily remove any duplicates.",
            },
            title: () => "OTC Trades",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "As of November 20, 2023, Coinbase Pro and its API have been deprecated by Coinbase. If you haven't imported the API before this date, please switch to 'Upload File' and follow the instructions to upload your Coinbase Pro transaction history via CSV.",
              1: () =>
                "If you've already imported the API before this date, and sync fails due to an expired API key, import your Coinbase Pro transaction history via CSV starting from your API's last synced date.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Please note: We only handle Deposits and Withdrawals from the 'Account Statement' CSV. All other transactions are taken from the 'Fill Statement' CSV",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your Coinbase account.",
              1: () => "Click on the profile icon in the top right corner.",
              2: () =>
                "Select 'Reports' from the dropdown menu (https://www.coinbase.com/reports).",
              3: () =>
                "Select 'View Statements' in the 'Coinbase Pro Statements' section (https://accounts.coinbase.com/statements/pro).",
              4: () =>
                "Leave the Portfolio, Account Type, and Date drop downs as their respective 'All' settings.",
              5: () =>
                "Select 'Account' for Report Type, select CSV, and then click 'Generate' to download the 'account.csv' file",
              6: () =>
                "Select 'Fills' for Report Type, select CSV, and click Generate to download the 'fills.csv' file",
              7: () =>
                "Upload both of these CSV files into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinberry: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Make sure your report has encompassed all the years which you have been trading cryptocurrency",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your Coinberry account on a web browser",
              1: () =>
                "Navigate to your Dashboard, and click on 'Coinberry Activity' to download your trading data Coinberry CSV download",
              2: () =>
                "Do not open or resave the file before importing, as this might change the format of the CSV file",
              3: () =>
                "Upload the file you just downloaded here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coincheck: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Coincheck account",
              1: () =>
                "In the menu on the left hand side, select the gear icon, then click 'API Key'",
              2: () =>
                "Complete the 2FA check, then click 'Add another API key'",
              3: () =>
                "In the window that pops up, under the 'Order' heading, select the 'Trading history' option",
              4: () =>
                "Under the 'Account' heading, select the 'Send history', and 'Deposit history' options",
              5: () =>
                "Under the 'Withdraw JPY' heading, select the 'JPY Withdraw history' option",
              6: () =>
                "Leave the IP field blank, enter your password and click 'OK' to generate the API key",
              7: () =>
                "Enter both the 'API Key' and 'API Secret' here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coincorner: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "The file must be a CSV file. Please export to CSV if you have a .xlsx file",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "You can import transactions from a CSV file exported by Coincorner",
            },
          },
        },
      },
    },
    coindcx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Open the CoinDCX mobile app and click the profile icon in the top-left corner.",
              1: () => "Select 'Download Reports'.",
              2: () =>
                "Click 'Trade Report' within the 'Download Reports' section.",
              3: () =>
                "Enter the financial year and time period, then click 'Generate'. Your report will download automatically.",
              4: () =>
                "If your trades span multiple periods, repeat this step as needed.",
              5: () => "Upload the files to Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your CoinEx account",
              1: () => "Expand the 'Assets' menu and click on 'History'",
              2: () =>
                "Set the time range of your transaction data and click 'Export' to download an '.xlsx' file",
              3: () => "Upload your '.xlsx' file into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinexchange: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Please note, withdraws and deposits are not included in the transaction data CSV and will have to be added manually",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "This exchange has been deactivated, however you can still upload your saved transaction data in a CSV format",
            },
          },
        },
      },
    },
    coinfalcon: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Navigate to the Account section of your account",
              1: () => "Click on API access",
              2: () =>
                "Create a new API key and copy the keys into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinfield: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "CoinField only lets you download reports for one market at a time, so make sure you generate reports which encompass all the markets you have traded in (e.g BTC/USD, ETH/CAD) and upload these here",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your CoinField account on a web browser",
              1: () =>
                "Navigate to your Dashboard, and click on Trade>Trade-History from the navigation bar on the left",
              2: () => "Download your trade history CSV file",
              3: () =>
                "Make sure you repeat this process for all the markets/cryptocurrencies you have traded with",
              4: () =>
                "Upload the files you just downloaded here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinjar: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login to your CoinJar account",
              1: () =>
                "Click the button below to be redirected to CoinJar in a new tab",
              2: () => "Enable report permissions in your CoinJar account",
              3: () => "We will then sync your CoinJar transaction history",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "CSV import option does not support Bundles, Unbundles, and Liquidate Transactions, if you have these transactions, please import via the custom CSV option",
              1: () =>
                "If your withdrawals were for personal use or sale transactions you can mark them as such on the next page",
              2: () =>
                "If you traded via CoinJar exchange you must also add the 'fill' CSV file",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to CoinJar",
              1: () => "In the top navbar click on ‘More’",
              2: () => "On the left sidebar click on 'Reports'",
              3: () => "Click on ‘Export Account Statement (CSV)’",
              4: () =>
                "Click Custom Date Range and select your entire trading history as the date range, then click export",
              5: () =>
                "You should receive an email with your CSV. Download this CSV and upload here",
            },
          },
        },
      },
    },
    coinjarexchange: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login into your CoinJar Exchange account",
              1: () =>
                "Under the settings of your account navigate to the API section",
              2: () => "Create a new API key",
              3: () => "Make sure the key belongs to the 'readonly' scope",
              4: () =>
                "Copy the 'secret' of your new API key here into Crypto Tax Calculator and provide a nickname",
            },
          },
        },
      },
    },
    coinlist: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Our CoinList CSV integration is currently in Beta. If you experience any issues or have feedback please contact our support",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your CoinList account",
              1: () =>
                "Click on the dropdown menu in the upper right of the CoinList platform and navigate to 'Wallets'",
              2: () =>
                "Select your wallets and set the time range of the transaction data and click 'Download CSV'",
              3: () => "Upload CSV file into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinlistpro: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Log in to your CoinList Pro account",
              1: () =>
                "Click on the dropdown menu in the upper right of the CoinList Pro platform",
              2: () => "Select 'API' and then click 'Create New Key'",
              3: () => "Generate a unique API key with 'read only' permissions",
              4: () =>
                "Copy the generated 'API Key' and 'API Secret' here into Crypto Tax Calculator, enter an optional nickname, and then click 'Add API' to sync your API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your CoinList Pro account",
              1: () =>
                "Click on the dropdown menu in the upper right of the CoinList Pro platform",
              2: () =>
                "Select Statements and set 'Statement Type', 'Start Date' and 'End Date' and click 'Request statement'",
              3: () =>
                "Upload BOTH fills CSV and account CSV into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinmetro: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your CoinMetro account on a web browser",
              1: () =>
                "Navigate to your Dashboard, and scroll down to your Transaction History",
              2: () =>
                "Select the date range corresponding to the start of your trading period or the period of interest using the 'Date range' dropdown menu",
              3: () =>
                "Select 'Deposit & Withdraw' in the 'Types' dropdown menu",
              4: () =>
                "Click 'Download CSV' to download your transaction history",
              5: () =>
                "Ensure you repeat this with all your traded assets, which can be selected in the 'Asset' dropdown menu",
              6: () =>
                "Upload the files you just downloaded here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinsmart: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Log into your Bitbuy account using your old Coinsmart credentials and contact Bitbuy's customer support team to request your transaction history.",
              1: () => "Upload your CSV files here into Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinspot: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "We sync all your CoinSpot data via the API. This includes trades, airdrops, crypto transfers, AUD deposits/withdrawals, affiliate income payments, and referral income payments.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log in to CoinSpot and go to the ‘API’ page (https://www.coinspot.com.au/my/api).",
              1: () => "Click 'Generate New API Key.'",
              2: () => "Name the key and set the API Key Type to 'Read Only.'",
              3: () => "Enter your Two-Factor Code and click 'Create New Key.'",
              4: () =>
                "Confirm the API key creation by clicking on the link sent to your email.",
              5: () => "Copy and paste the 'API Key' into CTC",
              6: () =>
                "Reveal, then copy and paste the 'Secret Key' into CTC (you won't be able to access this again).",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/Pls-kQAg94U",
            title: () => "How to import your Coinspot API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "The CSV does not include information on airdrops, AUD deposits/withdrawals or affiliate/referral payments, you will have to either add these manually or use the CoinSpot API sync",
            },
            title: () => "Missing transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Visit https://www.coinspot.com.au/my/orders/history",
              1: () =>
                "Click 'Sends/Receives CSV' to download your send and receive history as 'sendreceives.csv.'",
              2: () =>
                "Click 'Buys/Sells CSV' to download your order history as 'orderhistory.csv.'",
              3: () => "Upload both files to Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/IXAkAHO2N9k",
            title: () => "How to import your Coinspot CSV's",
          },
        },
      },
    },
    coinsquare: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Sign in to your Coinsquare account here (https://app-new.coinsquare.com/sign-in).",
              1: () =>
                "Click the drop-down menu next to your name (top right) and select 'Documents'.",
              2: () =>
                "Under 'Statements', select the tax year which you need your transaction history for and click 'Download'.",
              3: () =>
                "Upload all your downloaded CSV files here into Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    coinstash: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Coinstash account",
              1: () =>
                "Go to your settings page (https://coinstash.com.au/settings)",
              2: () =>
                "Scroll down to the 'Reports' section. Click on the 'Download' button next to 'Transaction History'",
              3: () => "Now upload the CSV into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    coinswitch: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log into your CoinSwitch Kuber account.",
              1: () =>
                "Tap the blue profile icon in the top-left corner of the home page.",
              2: () =>
                "Go to the 'Reports' section and download your account statement.",
              3: () => "Select the 'Tax P&L' section.",
              4: () =>
                "Choose the financial year, select 'All Quarters', and tap 'Send Statement'.",
              5: () => "Upload the file to Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    cointracking: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Once imported, transactions will be broken out into their corresponding exchange imports where relevant.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "You can import all your trading history from Cointracking",
              1: () =>
                "Login into your CoinTracking account, and in the navbar click on 'Enter Coins' (https://cointracking.info/enter_coins.php)",
              2: () =>
                "In the table you will see an 'Export' button. Click this button and select 'CSV' from the dropdown selection. A CSV file will download automatically",
              3: () =>
                "Upload the downloaded CSV file here to Crypto Tax Calculator.",
            },
            title: () => "Trade Table (recommended):",
          },
          1: {
            items: {
              0: () =>
                "Alternatively you can export your closing balance for the last financial year.",
              1: () =>
                "Please make sure your export is in your local fiat currency.",
              2: () => "The date format should be 'DD.MM.YYYY'.",
            },
            title: () => "Closing Balance:",
          },
        },
      },
    },
    cointree: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Please be sure to take note of your API Secret in a secure place.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to Cointree",
              1: () =>
                "Click your profile icon in the top right corner and navigate to 'Settings'",
              2: () => "Navigate to 'API Keys' (last option in the top menu)",
              3: () =>
                "Enter a Display Name for your API key in the 'API Key Display Name' field",
              4: () =>
                "Choose an access level for your API key and click on 'Generate API Key + Secret'",
              5: () =>
                "Copy and paste your API key and API secret into Crypto Tax Calculator",
              6: () => "Provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to Cointree",
              1: () => "Navigate to Wallet > Transactions",
              2: () =>
                "Click on 'Export' button in the 'All transactions' table",
              3: () =>
                "Click on 'Export orders (csv)' from the dropdown menu to download your trading history",
              4: () =>
                "Click on 'Export transactions (csv)' from the dropdown menu to download your deposit/withdrawal history",
              5: () => "Upload both CSV files here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "cosmos/ibc": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () =>
                "Navigate to the account you would like to import and copy your public wallet address",
              2: () =>
                "In CTC, choose the relevant blockchain network from the dropdown",
              3: () =>
                "Paste your public wallet address and click 'Import Wallet'",
            },
          },
        },
      },
    },
    cosmoshub: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Data before 02/20/2021 is unavailable due to a limitation of the data source.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Cosmos Hub wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    coss: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login to Coss",
              1: () => "Navigate to API Management Page",
              2: () => "Type Name of Key",
              3: () => "Click ‘Create New Key’ button",
              4: () => "Enter your 2FA Code",
              5: () => "Click ‘Next’ Button",
              6: () =>
                "Check your email and click ‘Click Here to Create API Key’ link",
              7: () => "Copy both the Public Key and Secret Key",
              8: () =>
                "Click ‘Edit’ button to turn off Trading Permission for this key",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to Coss",
              1: () => "Navigate to Trade History",
              2: () => "Click ‘Export Complete Trade History’ button",
              3: () =>
                "Ensure dates selected are for a range prior to your account opening and now",
              4: () => "Click ‘Export’",
              5: () => "Upload CSV to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    crex24: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "When generating the API keys double-check to make sure you only select R1 and R3 options.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log into CREX24",
              1: () => "Click 'My Account' in the top right corner",
              2: () => "Click the 'API keys' tab",
              3: () =>
                "Click 'New API key' and enter the code you received via email",
              4: () => "You should now see a new API key in the table",
              5: () =>
                "Set the 'Status' tab to 'ON' and tick the R1 and R3 checkboxes",
              6: () =>
                "Copy the API Key and Secret and paste in Crypto Tax Calculator",
            },
          },
        },
      },
    },
    cronos: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We now support CRC-20 tokens. If you have previously imported your Cronos account you may need to 'hard sync' your API to refresh historic data.",
            },
            title: () => "CRC-20 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find the wallet address that you have used for trading via Cronos (starting with 0x)",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    cryptodotcom: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "The date format must be one of the following: 'DD-MM-YY H:mm', 'M/D/YY H:mm', or 'YYYY-MM-DD HH:mm:ss'. If you do not have the correct date format in your CSV please reach out to our support team via web chat.",
              1: () =>
                "If you import your 'fiat_transactions_record' CSV, you MUST remove all 'viban_purchase' and 'recurring_buy_order' Transaction Kind rows. As this data is already recorded on the 'crypto_transactions_record' CSV, importing both will cause import errors.",
            },
            title: () => "CSV formatting",
          },
          1: {
            items: {
              0: () =>
                "Sometimes the exported CSV is corrupted - if you are getting upload errors please try saving the file again locally as a CSV and then re-importing.",
            },
            title: () => "Upload errors",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Open your Crypto.com app and go to the 'Accounts' tab.",
              1: () => "Click on 'Transaction history', then 'Export'.",
              2: () => "Select 'Crypto wallet' under 'Transaction'.",
              3: () =>
                "Select a Start date for all transactions and set End date to today.",
              4: () => "Click 'Export to CSV' to download the file.",
              5: () => "Upload the CSV file to Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    cryptodotcomexchange: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "The API can take up to 90 minutes to sync since we need to iterate through every day and it is throttled by Crypto.com",
              1: () =>
                "Sometimes the API fails to sync due to issues with Crypto.com. If this is happening to you please try the CSV import instead",
              2: () =>
                "Crypto.com's API will only support the last 6 months of transaction history. If you require older data, please use the CSV method.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Click Settings > API Keys (https://crypto.com/exchange/personal/api-management).",
              1: () => "Generate read-only API keys that do not enable trading",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "We do not currently support Margin or Derivative trading due to country restrictions. If you have traded these products please contact our in-app chat support. Additionally, the withdrawal CSV does not include fees. You will have to update these manually.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Crypto.com provides multiple CSV formats for exporting data. You’ll need all of them (Transactions, Deposit & Withdrawals, and Trade History) to import your complete transaction history.",
              1: () =>
                "Please note that Crypto.com allows a maximum date range of 180 days per export. To cover your entire transaction history, you may need to export multiple files for each format.",
              2: () => "Log in to your Crypto.com account.",
              3: () => "Change the language to English (if it isn’t already).",
              4: () =>
                "From the dashboard, select Wallet from the menu on the left.",
              5: () =>
                "Navigate to the Transactions tab. Choose a date range and click Export to download the Transactions CSV file.",
              6: () => "Return to the Wallet menu.",
              7: () =>
                "Navigate to the Deposit & Withdrawal tab. Choose a date range and click Export to download the Deposit & Withdrawals CSV file.",
              8: () =>
                "From the dashboard, select Orders from the menu on the left.",
              9: () =>
                "Navigate to the Trade History tab. Choose a date range and click Export to download the Trade History CSV file.",
              10: () =>
                "Import all three downloaded files (deposit&Withdrawal.csv, OEX_TRANSACTION.csv into CTC, OEX_TRADE.csv) into CTC.",
            },
          },
        },
      },
    },
    cryptospend: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Wayex account",
              1: () =>
                "Go to the 'Account' page, scroll down to transactions then press 'Get Transactions'",
              2: () =>
                "This will generate a CSV file which will be sent to your email",
              3: () => "Download the CSV file from your email",
              4: () => "Upload this CSV here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    cryptotaxcalculatorlegacy: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Only for importing manual trades from legacy.cryptotaxcalculator.io",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Please first email us if you are converting from legacy to obtain the manual trades as a CSV file",
              1: () => "Upload the CSV here to Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    customCSV: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Advanced CSV for more detailed transactions: https://cryptotaxcalculator.io/guides/advanced-manual-csv-import",
              1: () =>
                "Make sure your data is prepared correctly and in a .csv file format!",
            },
            title: () =>
              "If your exchange is not available via our automatic import, you can import it using one of our custom CSV templates:",
          },
        },
      },
    },
    daedalus: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Only enter ONE address from each wallet. We perform a lookup and pull in the data for all other addresses in the wallet automatically.",
              1: () =>
                "Importing multiple addresses from the same wallet will result in duplicate transactions.",
              2: () =>
                "Currently we do not support obtaining the complete transaction history of wallets where each individual address has more than 10 transactions. We are working to provide additional support for such wallets.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter any shelly address (addr1..) or stake address (stake1...) from your Daedalus wallet into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You can now alternatively import your Daedalus wallet transactions directly via the blockchain by connecting with the 'Sync via API' option. This will pull in all transactions and staking rewards for your Daedalus wallet",
              1: () =>
                "The transaction CSV appears to have erroneous data on some occasions. This is an issue with Daedalus - please contact support if you notice any discepencies",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log into your Daedalus wallet and export your reward CSV",
              1: () => "You can also import the transactions CSV",
            },
          },
        },
      },
    },
    dash: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We also support xpub, ypub and zpub addresses however, xpub is preferred",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Dash wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    ddex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "Select the exchange from the dropdown menu",
              3: () => "In the date range select the month you traded",
              4: () => "Keep the other default settings",
              5: () => "Click 'Retrieve History'",
              6: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              7: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    decentrex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () =>
                "On the left hand side in the menu expand 'Exchange History' and click Decentrex",
              3: () => "Under 'Operation types' click 'Trades + Funds'",
              4: () => "In the date range select the month you traded",
              5: () => "Keep the other default settings",
              6: () => "Click 'Retrieve History'",
              7: () =>
                "Once complete you can export the default csv under 'Export Trades' and 'Export Funds'",
              8: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload these CSV files",
            },
          },
        },
      },
    },
    deltaBalances: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "Select the exchange from the dropdown menu",
              3: () => "In the date range select the month you traded",
              4: () => "Keep the other default settings",
              5: () => "Click 'Retrieve History'",
              6: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              7: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    deribit: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Open the API Keys page on Deribit and select Add New API Key",
              1: () => "Select read permission for Account, Trade and Wallet",
              2: () => "Copy the key and secret into Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Login to Deribit and click on your username in the top right corner and select 'Transaction Log'",
              1: () => "Select the dates that you traded and download the logs",
              2: () =>
                "If you traded Ethereum you will also need to click on Ethereum in the top left corner and repeat the above steps",
              3: () =>
                "You can then import the CSV files into the app to report profit/loss income earned from trading",
            },
          },
        },
      },
    },
    digitalsurge: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Digital Surge account",
              1: () =>
                "Click on the hamburger menu at the top right of the page. In the dropdown popover, select 'API key' to navigate to the API Key page (https://digitalsurge.com.au/account-settings/api-keys)",
              2: () =>
                "On the top right of the page, click on the 'Create a new API key' button",
              3: () =>
                "In the popup, make sure you select 'Read only' and then click 'Next'",
              4: () =>
                "Enter the 2FA confirmation code and then click 'Generate the API key'",
              5: () =>
                "Copy the displayed API key, paste it into the 'Secret' field here on Crypto Tax Calculator, enter an optional nickname, and then click 'Add API' to sync your API",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Digital Surge account",
              1: () =>
                "Click on the hamburger menu at the top right of the page. In the dropdown popover select 'Transaction history' to navigate to the Transaction History page (https://digitalsurge.com.au/account-settings/transactions-history)",
              2: () =>
                "On the Transaction History page, click on the 'Export history' button (for mobile, click on the three dots to the right of the Transaction History title)",
              3: () =>
                "In the dropdown, select the 'Trades history' option to open the 'Export trades history' popup. In this popup, leave the date selection empty (this covers your complete trading history), and choose the format as 'CSV'. Once selected, click the 'Export history' button to download your 'trades.csv' file",
              4: () =>
                "In the dropdown, select the 'Transfers history' option to open the 'Export transfers history' popup. In this popup, leave the date selection empty, and choose the format as 'CSV'. Once selected, click the 'Export history' button to download your 'transfers.csv' file",
              5: () =>
                "Upload both the 'trades.csv' and the 'transfers.csv' files here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    doge: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We also support xpub, ypub and zpub addresses however, xpub is preferred",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Dogecoin wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    dydx: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Visit trade.dYdX.exchange and connect your wallet(s)",
              1: () => "Click on ‘Portfolio’ in the menu bar",
              2: () => "Select ‘History’ in the side panel",
              3: () =>
                "Click on ‘Export’ on the top right corner of the screen",
              4: () =>
                "Download all 3 CSV’s available: Transfers, Trades, Funding",
              5: () => "Upload the extracted files to Crypto Tax Calculator",
              6: () =>
                "Note: Currently, you will have a missing purchase history issue due to the way dYdX records the short trades. We are working on a resolution. For now, you can potentially calculate the PnL and use our manual CSV to add a Realized Loss/Profit for the short trade.",
            },
          },
        },
      },
    },
    dydx4: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "At this stage, only derivative trade operations such as increasing/decreasing a position are supported.",
            },
            title: () => "Limited Operation Support",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Locate your dYdX v4 address by navigating to the protocol application and copying the address from the top right menu",
              1: () =>
                "Enter the address into Crypto Tax Calculator, provide an optional nickname and click 'Add Wallet'",
            },
          },
        },
      },
    },
    dymension: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Dymension wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    easyTrade: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "Select the exchange from the dropdown menu",
              3: () => "In the date range select the month you traded",
              4: () => "Keep the other default settings",
              5: () => "Click 'Retrieve History'",
              6: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              7: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    easycrypto: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "We currently support buys and sells on Easy Crypto. If you are performing swaps or other types of trades, please contact support and send through your CSV file to our support team",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your Easy Crypto account",
              1: () =>
                "Navigate to 'My Account' -> 'Orders' (https://easycrypto.ai/au/account/orders)",
              2: () =>
                "Near the bottom of the page you will see an link to 'Download a CSV of your completed orders'. Click on this link, and a CSV file will be downloaded to your computer",
              3: () =>
                "Upload this downloaded file here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    elbaite: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Elbaite account",
              1: () => "Navigate to the [Trades] tab",
              2: () => "Click [Export to CSV]",
              3: () =>
                "Upload the CSV file here to your Crypto Tax Calculator account",
            },
          },
        },
      },
    },
    enclaves: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () =>
                "On the left hand side in the menu expand 'Exchange History' and click Enclaves",
              3: () => "Under 'Operation types' click 'Trades + Funds'",
              4: () => "In the date range select the month you traded",
              5: () => "Keep the other default settings",
              6: () => "Click 'Retrieve History'",
              7: () =>
                "Once complete you can export the default csv under 'Export Trades' and 'Export Funds'",
              8: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload these CSV files",
            },
          },
        },
      },
    },
    enkrypt: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open your Enkrypt wallet",
              1: () => "Click on the network you want to import",
              2: () => "Click the copy address icon",
              3: () =>
                "In Crypto Tax Calculator select the corresponding network",
              4: () => "Paste the address and click 'Add Wallet'",
            },
          },
        },
      },
    },
    ercDex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    eth: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We now support ERC-1155 tokens, however, we are unable to import your current balances for these tokens. This may result in discrepancies between the balance imported from the source API and the balance calculated by the app.\nCurrently ERC-404 tokens aren't supported although we are working on adding them. For now, you will need to manually add these transactions into the app.",
            },
            title: () => "Notes",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Enter your Ethereum wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    ethen: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () =>
                "On the left hand side in the menu expand 'Exchange History' and click ETHEN",
              3: () => "Under 'Operation types' click 'Trades + Funds'",
              4: () => "In the date range select the month you traded",
              5: () => "Keep the other default settings",
              6: () => "Click 'Retrieve History'",
              7: () =>
                "Once complete you can export the default csv under 'Export Trades' and 'Export Funds'",
              8: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload these CSV files",
            },
          },
        },
      },
    },
    etherc: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "Select the exchange from the dropdown menu",
              3: () => "In the date range select the month you traded",
              4: () => "Keep the other default settings",
              5: () => "Click 'Retrieve History'",
              6: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              7: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    etherdelta: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () =>
                "On the left hand side in the menu expand 'Exchange History' and click EtherDelta",
              3: () => "Under 'Operation types' click 'Trades + Funds'",
              4: () => "In the date range select the month you traded",
              5: () => "Keep the other default settings",
              6: () => "Click 'Retrieve History'",
              7: () =>
                "Once complete you can export the default csv under 'Export Trades' and 'Export Funds'",
              8: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload these CSV files",
            },
          },
        },
      },
    },
    "ethereum-classic": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "There may be some missing internal transactions for some wallets.",
            },
            title: () => "Missing internal transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Ethereum Classic wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    etherscan: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "The best way to import data from EtherScan is to directly import your public wallet address",
              1: () =>
                "Paste your Ethereum public wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    ethex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    ethfinex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "Select the exchange from the dropdown menu",
              3: () => "In the date range select the month you traded",
              4: () => "Keep the other default settings",
              5: () => "Click 'Retrieve History'",
              6: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              7: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    etoro: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "eToro frequently changes the CSV file format of exported trades. If your file does not import to the platform, it is possible they have changed it again. In this case, we recommend using the advanced CTC CSV import for your eToro trades.",
              1: () =>
                "As Crypto Tax Calculator is designed for crypto transactions, we are unable to import non-crypto trades. If your eToro account includes such trades, you'll need to account for them externally.",
            },
            title: () => "Notes:",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your eToro account. You can access the statement directly here (https://www.etoro.com/documents/accountstatement) or follow the below steps.",
              1: () =>
                "From the sidebar, click on 'Portfolio' then navigate to 'History'",
              2: () =>
                "Expand the drop down menu on the top right and click 'Account Statement'",
              3: () =>
                "Set the time range of your transaction data and click 'Create'",
              4: () =>
                "On the top right, click the 'XLS' icon to download an '.xlsx' file",
              5: () =>
                "In the 'Currency Symbol' field of the import box, match the currency exactly to your CSV header (e.g., use USD instead of $).",
              6: () => "Upload your '.xlsx' file into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    evmos: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Evmos in early development",
              1: () => "Data before 4/28/2022 unavailable",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Evmos wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    exmo: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "We do not support specific IP addresses on our platform, please ensure that the 'API Access from trusted IP addresses only' is disabled when generating an API key",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your Exmo account and navigate to the settings tab",
              1: () =>
                "Click on the API tab and ensure that the 'API Access from trusted IP addresses only' is toggled off",
              2: () =>
                "Create an API key by clicking on 'Generate API Key' and enter it here to Crypto Tax Calculator as 'Api Key' and 'Secret' respectively",
            },
          },
        },
      },
    },
    exodus: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to the history section of your wallet (top right of your Exodus wallet)",
              1: () => "Click the export all transactions option",
              2: () => "Upload the file to Crypto Tax Calculator",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open your Exodus wallet.",
              1: () =>
                "Select the cryptocurrency you would like to import. For example, Bitcoin or Ethereum.",
              2: () =>
                "Copy the corresponding public wallet address for that asset.",
              3: () =>
                "In CTC, choose the corresponding blockchain network from the drop down.",
              4: () =>
                "Paste your public wallet address into CTC and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    fantom: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We now support ERC-1155 tokens, however, we are unable to import your current balances for these tokens. This may result in discrepancies between the balance imported from the source API and the balance calculated by the app.",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Fantom wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    "fearless-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open your Fearless wallet",
              1: () =>
                "On the top right, select the network you want to import",
              2: () => "Click the copy address icon on the top left",
              3: () =>
                "In Crypto Tax Calculator select the corresponding network",
              4: () => "Paste the address and click 'Add Wallet'",
            },
          },
        },
      },
    },
    fetchai: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Fetch.AI wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    flare: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "There may be some missing internal transactions for some wallets.",
            },
            title: () => "Missing internal transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Flare wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    ftx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Re-format the file(s) into CTC’s advanced CSV format (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
              1: () => "Upload your CSV file(s) here",
            },
            title: () =>
              "FTX is no longer operational. Please use Crypto Tax Calculator's advanced CSV format to import your FTX transactions.",
          },
        },
      },
    },
    ftxus: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX is no longer operational and it is no longer possible to obtain historical data from this exchange. FTX Transactions that have been previously imported will still be accessible in your account. New imports must use existing FTX CSVs or the custom CSV import option. API syncs are no longer possible.",
            },
            title: () => "FTX No Longer Operational",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX is no longer operational. Please use the custom CSV import option to import your FTX transactions.",
          },
        },
      },
      csv: {
        critical: {
          0: {
            items: {
              0: () =>
                "FTX is no longer operational and it is no longer possible to obtain historical data from this exchange. FTX Transactions that have been previously imported will still be accessible in your account. New imports must use existing FTX CSVs or the custom CSV import option. API syncs are no longer possible.",
            },
            title: () => "FTX No Longer Operational",
          },
        },
        step: {
          0: {
            title: () =>
              "FTX is no longer operational. Please use the custom CSV import option to import your FTX transactions.",
          },
        },
      },
    },
    gate: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login to Gate.io",
              1: () => "Navigate to API Keys page",
              2: () => "If applicable, enter fund password and TOTP",
              3: () => "Click ‘Show API Keys’ button",
              4: () => "Copy both API Key and API Secret",
              5: () => "Enable Read-Only for your API Key",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to Gate.io",
              1: () => "Navigate to Wallets > Trade History",
              2: () => "Click ‘ALL’ for Markets and Coin",
              3: () => "Ensure dates selected range through all trades",
              4: () =>
                "Click ‘Download’ button which appears as a downward pointing arrow",
              5: () => "Upload CSV to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    gateio: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "There may be some minor balance discrepancies due to rounding issues on the CSV files themselves",
            },
            title: () => "CSV Formatting",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your Gate.io account and head to https://www.gate.io/myaccount/mypurselog . You can also access this page from the sidebar menu > Transaction History.",
              1: () => "Select ‘All’ for Coin and ‘All’ for Filter.",
              2: () => "Click ‘Filter’ > Download",
              3: () =>
                "You can also click ‘Request batch data’ in the top right corner.",
              4: () => "Select ‘All’ for Coin and submit the application.",
              5: () =>
                "You can download 90 days of transaction history each time. If you have more, repeat the steps for different periods.",
              6: () => "Import the exported file ‘mypurselog.csv’ into CTC.",
            },
          },
        },
      },
    },
    gemini: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Crypto Tax Calculator should be the only service that uses the API key you have provided. You will most likely experience import issues if you use an API key that is shared with another service.",
              1: () =>
                "Please note that transactions with amounts less than 10^-12 (of any currency) will not be imported.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Login to Gemini and navigate to Account > Settings (top-right of the screen)",
              1: () => "Scroll down to the API tab on the left side",
              2: () => "Click create a new API key (Don't use an existing key)",
              3: () => "Select scope: 'Primary' and click 'Create API Key'",
              4: () =>
                "Tick the checkbox 'Auditor' under API key settings. Do NOT anything else in the API settings.",
              5: () => "Copy the API Key and Secret",
              6: () => "Tick 'Yes' to the copy and paste checkbox",
              7: () => "Click confirm",
              8: () =>
                "Add the copied API Key and Secret into Crypto Tax Calculator",
            },
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () =>
                "Click 'Connect to Gemini' to get redirected to the Gemini Exchange",
              1: () => "Log in to your Gemini account.",
              2: () => "Provide the 2FA code if required",
              3: () =>
                "Select the account you want to connect then click 'Continue'",
              4: () => "Click the 'Allow' button",
            },
          },
        },
      },
    },
    "giddy-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () =>
                "Open your Giddy Wallet mobile app and navigate to the 'Wallet' section (bottom left corner).",
              1: () => "Click the QR icon on the top left.",
              2: () => "Copy the public wallet address starting with '0x...'",
              3: () => "Paste the address and click 'Import Wallet'",
            },
          },
        },
      },
    },
    graviex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Navigate to the history page of your account",
              1: () => "Click trade history and export the data",
              2: () => "Upload to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    grs: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We also support xpub, ypub and zpub addresses however, xpub is preferred",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Groestlcoin wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    hedera: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Enter your Hedera wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    hitbtc: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "We currently do not support margin interest charges or futures funding payments for the API integration, as HitBTC do not offer an API endpoint to get this information. We are working with HitBTC to resolve this.",
              1: () =>
                "HitBTC does not return spot trades history for delisted coins such as VERI. This can result in balances not matching up if you had historical trades on those pairs.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sign in to your HitBTC account and navigate to the HitBTC API Keys page - Under the Accounts tab (https://hitbtc.com/settings/api-keys)",
              1: () =>
                "Click the 'New Api Key' button and enter your two-factor authentication code",
              2: () =>
                "Enable both checkboxes: 'Order book, History, Spot balance' and 'Payment Information'",
              3: () =>
                "Copy the API Key and Secret and enter it into Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "We currently do not support futures funding payments for the CSV integration. We are working to resolve this.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sign in to your HitBTC account and navigate to the HitBTC Reports page (https://hitbtc.com/reports/trades)",
              1: () =>
                "For wallet transactions: Select 'Transactions History' under 'Wallet'",
              2: () => "For spot trades: Select 'My Trades' under 'Spot'",
              3: () =>
                "For margin trades: Select 'My Trades' and 'Interests' under 'Margin'",
              4: () => "Click Export as .csv and download the CSV file",
              5: () => "Upload your CSV file(s) here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    hodlnaut: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "For more detailed steps with screenshots, view Hodlnaut's support page at: https://www.hodlnaut.com/crypto-guides/hodlnaut-revamps-csv-format",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Once logged into Hodlnaut, navigate to 'My Account' on the top menu bar",
              1: () =>
                "Scroll down to 'Transactions' and click on the export button on the top right corner",
              2: () => "Download the CSV report onto your device",
              3: () =>
                "Upload the CSV into Crypto Tax Calculator using the import button.",
            },
          },
        },
      },
    },
    honeyswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We currently auto-categorize Swaps, and Adding/Removing Liquidity on Honeyswap",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find the Gnosis Chain wallet address that you have used for trading via Honeyswap",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    hotbit: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "As Hotbit's download export history option only allows you to export 30 days of trades at a time, you will have to contact their support and request to obtain a full record of your trading history",
              1: () =>
                "Hotbit support should send you a .xlsx file with the following headers: 'time', 'user_id', 'market', 'side', 'role', 'price', 'amount', 'deal', 'fee', 'platform', 'stock', 'deal_stock'. Confirm the received file has the above headers, do not modify or resave the file in the process",
              2: () => "Upload the .xlsx file here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    huobi: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login to Huobi",
              1: () => "Navigate to API Management Page",
              2: () => "Enter a note of your choosing",
              3: () => "Click ‘Create’ button",
              4: () => "Copy both Access Key and Private Key",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "You will have to contact support and ask them to email you the 'Transaction History', 'Withdrawal History' and 'Deposit History' CSV files",
              1: () =>
                "These can take up to 5 days to be sent through from Huobi",
              2: () =>
                "Once you have received these files you can upload them here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    hyperliquid: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We are currently unable to automatically import transactions related to referral rewards. This may result in minor balance discrepancies. To ensure your records are accurate, we recommend manually importing these transactions if applicable. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () => "Referral rewards",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Enter your Hyper wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    ice3x: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to ice3x",
              1: () =>
                "Navigate to account > history > orders https://ice3x.com/account/history",
              2: () => "Click on ‘Export to Excel’ button",
              3: () => "Upload to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    idex: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Login to IDEX http://idex.market",
              1: () => "Navigate to Profile > Wallet Address",
              2: () => "Copy your IDEX Wallet Address",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to IDEX",
              1: () =>
                "Navigate to Orders > Trade History https://idex.market/trades",
              2: () => "Click on ‘Download Trade History’",
              3: () => "Upload CSV to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    idt: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    immutable: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Immutable X wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
              1: () =>
                "Please note: Due to unreliable data from Immutable X, some wallets may fail to import transactions.",
              2: () =>
                "Until we can secure a reliable data source, you will need to manually import this data using our custom CSV method.",
            },
          },
        },
      },
    },
    independentreserve: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Independent Reserve account",
              1: () => "In the top menu, go to Transactions → Tax",
              2: () =>
                "Click the 'Get started with Crypto Tax Calculator' button",
              3: () =>
                "You’ll be redirected to Crypto Tax Calculator (CTC), where your account will be automatically linked for seamless integration",
            },
            title: () => "Automatic connection (recommended)",
          },
          1: {
            items: {
              0: () => "Log in to your Independent Reserve account",
              1: () =>
                "Click on your profile name in the top right corner, then select 'Settings'",
              2: () => "In the Settings page, go to the 'API keys' tab",
              3: () => "Click on 'Generate API key' to create a new API key",
              4: () =>
                "Select 'Read-only (recommended)' option and add the API Key description as 'Crypto Tax Calculator'",
              5: () => "Enter your password and click 'Generate'",
              6: () =>
                "Copy the 'API Key' and 'API Secret' and paste them here into Crypto Tax Calculator",
            },
            title: () =>
              "Manually connect your Independent Reserve account with an API key",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/4-HxdE3BdmU",
            title: () => "How to import your Independent Reserve API",
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Independent Reserve account",
              1: () =>
                "In the top navbar, navigate to the 'TAX AND REPORTS' dropdown menu and select 'Reports'",
            },
          },
          1: {
            items: {
              0: () => "Under reports, click the 'Order history report' button",
              1: () =>
                "In the popup, select the date range which covers your complete order history for the account",
              2: () =>
                "Select the 'Download order history as CSV file' option, and then click 'download' to download your order history CSV",
              3: () =>
                "Upload this 'OrderStatement' CSV file here into Crypto Tax Calculator",
            },
            title: () => "For Order History:",
          },
          2: {
            items: {
              0: () =>
                "Under reports, click the 'Transaction History Report' button",
              1: () =>
                "In the popup, select the date range which covers your complete order history for the account",
              2: () =>
                "Select the 'Download transaction history as CSV file' option, and then click 'download' to download your transaction history CSV",
              3: () =>
                "Upload this 'TransactionHistory' CSV file here into Crypto Tax Calculator",
            },
            title: () => "For Deposits/Withdrawals:",
          },
        },
        video: {
          0: {
            items: {
              0: () =>
                "We will not pull in your 'Trades' from the 'TransactionHistory' CSV file but instead from the 'OrderStatement' CSV file as this is more accurate.",
              1: () => "Currently, the only datetime formats we support are:",
              2: () => "'DD/MM/YYYY HH:mm' (Eg. 12/02/2020 13:09)",
              3: () => "'DD MMM YYYY HH:mm:ss' (Eg. 12 Feb 2020 13:09:22)",
            },
            link: () => "https://youtu.be/Qaz6XSJ-jck",
            title: () => "How to import your Independent Reserve CSV files",
          },
        },
      },
    },
    injective: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Injective wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    iotex: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "IoTeX is in early development",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your IoTeX wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    "jaxx-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Login to Jaxx Liberty",
              1: () => "Select the three line menu (top right corner)",
            },
          },
          1: {
            items: {
              0: () => "Select View Backup Phrase",
              1: () => "Check the It's safe box and click View Backup Phrase",
              2: () => "Navigate to https://iancoleman.io/bip39",
              3: () =>
                "Paste the Jaxx backup phrase into the BIP39 Mnemonic field",
              4: () => "Select Coin",
              5: () => "Select Tab BIP44",
              6: () =>
                "Type '0' in External / Internal to get xPub for receive addresses",
              7: () => "Copy the BIP32 Extended Public Key",
              8: () =>
                "Type '1' in External / Internal to get xPub for change addresses",
              9: () => "Copy BIP32 Extended Public Key",
              10: () => "Add the xPub address into CTC and click sync",
            },
            title: () =>
              "For Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin, and Zcash wallets",
          },
          2: {
            items: {
              0: () => "Select Display Private Keys",
              1: () => "Check the 'It's safe' box and click View Private Keys",
              2: () =>
                "Select the relevant coin wallet (can repeat for multiple wallets)",
              3: () => "Copy public address(es). Do not copy private keys",
              4: () => "Find the relevant addresses for each transaction",
              5: () => "Copy each address",
              6: () => "Paste into CTC and click sync",
            },
            title: () => "For all other coins",
          },
        },
      },
    },
    juno: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Juno in early development",
              1: () =>
                "Transfers/staking labels only (others appear as unknown)",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Juno wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    kaspa: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Enter your Kaspa wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    kava: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "We're currently experiencing some issues gathering ERC-1155 tokens and non-EVM transactions, and are working on a solution. If in the meantime you are experiencing this problem, please try our manual CSV upload process instead. (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
            },
            title: () => "ERC-1155 Tokens and Non-EVM Transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Kava EVM wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    kraken: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Kraken's API has a strict rate limit of about 50 transactions every 6 seconds, which may slow down syncing large numbers of transactions. For more details, visit their [API rate limit page](https://www.kraken.com/features/api#api-call-rate-limit).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log in to Kraken and go to Settings > Security > API. (https://pro.kraken.com/app/settings/api).",
              1: () => "Click “Create API Key”.",
              2: () =>
                "Name your API key, and select the permissions: “Query”, “Query closed orders & trades”, “Query ledger entries” and “Export data”.",
              3: () =>
                "Leave all other permissions and settings as-is and click “Generate key”.",
              4: () =>
                "Copy the ‘API Key’ and ‘Private key’ into Crypto Tax Calculator as 'API Key' and 'Secret'.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/2WAYzAyPOKQ",
            title: () => "How to import your Kraken API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Currently, the only date formats we support for Kraken CSV are MM/DD/YYYY, YYYY-MM-DD, DD/MM/YY HH:mm, YYYY-MM-DD HH:mm:ss.SSS, YYYY-MM-DD HH:mm:ss and MM/DD/YYYY HH:mm",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your Kraken account, go to the 'History' tab, then click 'Export'.",
              1: () =>
                "Select 'Ledger' for the export type, choose your trading period, check 'Select All', and click 'Submit'.",
              2: () =>
                "Wait a few seconds, refresh if needed, and download the CSV in a .zip file.",
              3: () =>
                "Unzip the file and upload the CSV(s) to Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/X7v6RyNCe7A",
            title: () => "How to import your Kraken CSV's",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () => "Click 'Connect to Kraken' to go to the Kraken Exchange",
              1: () => "Log in to your Kraken account.",
              2: () =>
                "If your account uses a Passkey or 2FA, enter the required code. Otherwise, skip this step.",
              3: () => "Click the 'Allow' button",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/Hrn695jsmoA",
            title: () => "How to import your Kraken account via Oauth",
          },
        },
      },
    },
    kucoin: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "KuCoin's API only provides the last year's transaction history. Use a CSV file for older data.",
              1: () =>
                "We currently support Deposits, Withdrawals, Spot and Margin Trading and Futures historical data for KuCoin. It should be noted that balances may not be correct as we do not obtain the Margin borrow/lend history.",
              2: () =>
                "Kucoin have recently disabled their historical trade endpoint. You'll have to add this data into Crypto Tax Calculator separately using the CTC CSV import option in the meantime until this issue is resolved (https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import)",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log in to KuCoin and go to API Management > API Keys (https://www.kucoin.com/account/api)",
              1: () => "Click 'Create API' and select 'API-Based Trading.'",
              2: () => "Enter a name and passphrase of your choosing.",
              3: () => "Enable only 'General' permission; disable others.",
              4: () =>
                "Set IP restriction to 'No', click 'Next’ and complete security verification",
              5: () =>
                "Copy the Passphrase, API Key, and API Secret and paste into Crypto Tax Calculator",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/PtORZYAm-gc",
            title: () => "How to import your Kucoin API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () => "Login to KuCoin",
              1: () =>
                "Navigate to Orders > Spot Trade History > Trade History",
              2: () =>
                "Click on the 'click here to view transactions of the older version'",
              3: () => "Click on ‘Export to CSV’",
              4: () =>
                "KuCoin only allows for 100 days to be exported at a time, so select 100-day intervals from your all-time trading history",
              5: () =>
                "Once the CSV is downloaded, navigate to https://www.kucoin.com/account/download",
              6: () => "Click on the download icon",
            },
            title: () => "Version 1 CSV Import Instructions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your KuCoin account and go to the Order Center. (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1)",
              1: () => "Click 'Export.'",
              2: () =>
                "Select the desired report: Deposit/Withdrawal History, Spot Orders, Margin Orders, or Futures Orders.",
              3: () => "Choose the date range (up to 12 months per export).",
              4: () =>
                "Check 'Includes Sub-Accounts' if needed (only for Spot, Margin, and Futures).",
              5: () => "Select '.csv' and click 'Confirm' to download.",
              6: () =>
                "Upload the relevant files to CTC: Deposit History, Withdrawal Record, Spot Orders - Filled Orders, Margin Orders - Filled Orders - Cross & Isolated, Margin Orders - Borrowings - Cross & Isolated, and Realised PnL.",
            },
          },
          1: {
            items: {
              0: () =>
                "Follow the steps on KuCoin Help Center (https://www.kucoin.com/support/14047696578841)",
              1: () =>
                "Log in to your Kucoin account and visit the Order Center (https://www.kucoin.com/order/trade?spm=kcWeb.B1DownloadCenter.orders.1)",
              2: () => "Click the “Export” button",
              3: () =>
                "Click the market drop down menu and select, Deposit/Withdrawal History, Spot Orders, Margin orders, Futures Orders",
              4: () =>
                "Choose the date range you wish to download (Only 12 months per export)",
              5: () =>
                "If you need to include data from sub-accounts, check the 'Includes Sub-Accounts' option. (Only Spot, Margin, and Futures reports are supported for sub-accounts).",
              6: () =>
                "Select '.csv' and then click 'confirm' to download the files.",
              7: () =>
                "Upload the necessary files to CTC. Not all downloaded files are useful for CTC, the relevant files include: Deposit History, Withdrawal Record, Spot Orders - Filled Orders, Margin Orders - Filled Orders - Cross & Isolated, Margin Orders - Borrowings - Cross & Isolated, and Realised PnL",
            },
            title: () => "Trade History",
          },
          2: {
            title: () => "Deposit & Withdrawal History",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/81gyRIU1HYM",
            title: () => "How to import your Kucoin CSV",
          },
        },
      },
    },
    kujira: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Kujira in early development",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Kujira wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    kyber: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "Select the exchange from the dropdown menu",
              3: () => "In the date range select the month you traded",
              4: () => "Keep the other default settings",
              5: () => "Click 'Retrieve History'",
              6: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              7: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    kyve_network: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Kyve wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    latoken: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "You will need to contact LATOKEN support to request the transaction history report",
              1: () =>
                "Once you have received this you can import it directly into the platform",
            },
          },
        },
      },
    },
    leather_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () => "Click ‘connect wallet’ and follow the prompts.",
            },
          },
        },
      },
    },
    ledger_blue: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Connect your Ledger to your device",
              1: () => "Login to Ledger Live desktop app",
              2: () => "Open the Ledger wallet for the coin of your choice",
              3: () => "Select the relevant account (left hand side)",
            },
          },
          1: {
            items: {
              0: () => "Click the wrench icon (top right side)",
              1: () => "Click Advanced Logs",
              2: () =>
                "Copy the HD wallet address prefixed with 'xpub' between the quotes",
              3: () => "Paste into CTC",
            },
            title: () =>
              "For Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin, and Zcash wallets",
          },
          2: {
            items: {
              0: () =>
                "View the list of transactions and addresses in the wallet",
              1: () => "Copy each address",
              2: () => "Paste into CTC",
            },
            title: () => "For all other coins",
          },
        },
      },
    },
    ledgerwallet: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "The CSV export from Ledger Live may have missing transactions, which may cause the balances calculated by Crypto Tax Calculator to be incorrect. Where possible, we recommend you sync each of the underlying blockchain wallets directly using the 'Sync via API' option.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Click on Accounts on the left panel.",
              1: () =>
                "Click on the ⋯ Options button in the upper right-hand corner.",
              2: () => "Choose the Export operation history option.",
              3: () =>
                "Select the accounts to include in the window that appears.",
              4: () => "Click on Save.",
              5: () => "Save the file in a folder of your choice.",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Login to Ledger Live desktop app",
              1: () => "Open the Ledger wallet for the coin of your choice",
              2: () => "Select the relevant account (left hand side)",
            },
          },
          1: {
            items: {
              0: () => "Click the wrench icon (top right side)",
              1: () => "Click Advanced Logs",
              2: () =>
                "Copy the HD wallet address prefixed with 'xpub' between the quotes",
              3: () => "Paste into CTC",
            },
            title: () =>
              "For Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin, and Zcash wallets",
          },
          2: {
            items: {
              0: () =>
                "View the list of transactions and addresses in the wallet",
              1: () => "Copy each address",
              2: () => "Paste into CTC",
            },
            title: () => "For all other coins",
          },
        },
      },
    },
    ledn: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Make sure your report has encompassed all the years which you have been trading cryptocurrency",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your Ledn account on a web browser",
              1: () =>
                "Navigate to the 'Trade' page from the navigation menu on the left",
              2: () => "Click on 'Download CSV' to download your trading data",
              3: () =>
                "Do not open or resave the file before importing, as this might change the format of the CSV file",
              4: () =>
                "Upload the file you just downloaded here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    linea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Linea wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    liqui: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to the funds/wallet section in the top right corner",
              1: () =>
                "Select the date range and export via email (you might have to do this multiple times for all your transactions)",
              2: () =>
                "Once the email arrives upload the file received to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    liquid: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "We support Spot, Margin, Infinity, Perpetuals, and Cryptocurrency Withdrawals.",
              1: () =>
                "Liquid API doesn't provide information for Cryptocurrency Deposits. You will have to add these manually if you would like to track these.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Login to Liquid and open the Liquid menu by clicking your user icon in the top-right",
              1: () =>
                "Navigate to API Tokens > API Keys https://app.liquid.com/settings/api-tokens",
              2: () => "Click the ‘Create API Token’ button",
              3: () =>
                "Select the following Read-only permission: 'Accounts', 'Executions', 'Orders', 'Positions', 'Trading Accounts' and 'Withdrawals'",
              4: () =>
                "Despite the instructions appearing as if the IP address is required, the API key is able to be generated without requiring one. Leave this blank.",
              5: () => "Click the 'Create Token' button",
              6: () =>
                "Copy the Token ID (API Key) and Token Secret (API Secret) and enter them here into Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to Liquid",
              1: () =>
                "Navigate to Wallets > Transactions > Scroll down to Overview Report",
              2: () =>
                "Ensure that 'Select timezone' is 'UTC+00:00' and ensure the dates selected range through all trades",
              3: () => "Click the 'Send Report Via Email' button",
              4: () =>
                "Once you receive the email from Liquid, download the CSV file and upload it here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    localbitcoins: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to your LocalBitcoins account",
              1: () =>
                "In the top right corner of the navbar, click on the profile icon and click on 'Settings' from the dropdown menu to navigate to your settings (https://localbitcoins.com/settings)",
              2: () =>
                "Click on the 'Security' tab, then under 'Personal Data', click 'View'",
              3: () =>
                "Click on the blue 'Collect personal data' button to begin the generation of your complete trading history",
              4: () =>
                "Once the download is ready, click the blue 'Download personal data' button to download your data in a .zip file",
              5: () =>
                "Extract the contents of the .zip file and isolate the two files called 'contacts.csv' and 'transaction_history.csv'",
              6: () =>
                "Finally, import these two files here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    lros: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Living Room of Satoshi (LROS) account",
              1: () =>
                "In the top navbar, click on 'My Account' to navigate to the bills page (https://www.livingroomofsatoshi.com/bills)",
              2: () =>
                "Download the CSV file covering your complete payment history",
              3: () => "Upload this CSV here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    ltc: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the Ltub wallet public key for your Litecoin wallet. To find this, (for example) go to Ledger Live and enter your Litecoin account. Click the wrench icon. Expand ‘Advanced Logs’ under ‘Edit Account’. Copy the address that begins with ‘Ltub’.",
              1: () =>
                "Once found, copy this public key, and head to an Xpub converter such as https://jlopp.github.io/xpub-converter/ to convert your 'Ltub' address to an 'Xpub' address.",
              2: () =>
                "Copy and paste the converted 'Xpub' address into the Crypto Tax Calculator import field (to the left) and click 'Add Wallet' to sync your transactions.",
            },
          },
        },
      },
    },
    luno: {
      api: {
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your Luno account (https://www.luno.com/en/exchange)",
              1: () =>
                "In the top right corner click on Profile and navigate to Security -> API Keys",
              2: () =>
                "Create the API key and make sure the key permission is `Read-only access`",
              3: () =>
                "Make sure you authorise the API key by following the link sent to you via SMS or email before using it",
              4: () =>
                "Enter both the 'API Key' and 'API Secret' here into Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your Luno account (https://www.luno.com/en/exchange)",
              1: () =>
                "Download the CSV export of your transactions by going to your Wallets -> BTC Wallet (for example) -> Transactions -> Download CSV",
              2: () => "Repeat this for each of your wallets on Luno",
              3: () =>
                "You can now upload the CSV files into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    lykke: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your Lykke account and on the navbar at the top of the screen click 'FUNDS'",
              1: () => "Once on the Funds page, click on the 'History' button",
              2: () =>
                "Make sure you have 'All' selected so you're viewing Deposits, Withdrawals and Trading",
              3: () => "Click the 'EXPORT TO CSV' button to download your CSV",
              4: () => "Upload the CSV here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "manta-pacific": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "There may be some missing internal transactions for some wallets.",
            },
            title: () => "Missing internal transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Manta Pacific wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    mantle: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Mantle wallet address (starting with 0x) into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    mercatox: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to Mercatox",
              1: () =>
                "Navigate to Wallet > Transaction History https://mercatox.com/wallet/transactions",
              2: () =>
                "Click on the ‘Download CSV’ button before uploading to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    metamask: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () => "Click ‘connect wallet’ and follow the prompts.",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open your MetaMask wallet",
              1: () =>
                "For each account associated with MetaMask, select the account and hover over the account name",
              2: () => "Copy the public wallet address starting with '0x...'",
              3: () =>
                "Paste the copied public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    metis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Metis wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    mode: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Copy your Mode public wallet address starting with '0x...'",
              1: () => "Paste your public wallet address into CTC",
              2: () => "Provide a nickname, and click 'Import Wallet'",
            },
          },
        },
      },
    },
    moonbeam: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Currently we are unable to fetch your moonbeam rewards. We are actively working on a solution to this. In the meantime you can add your rewards via our manual CSV import process",
            },
            title: () => "Missing Rewards",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Moonbeam wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    moonriver: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Moonriver wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    mudrex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Open the Mudrex mobile app and log into your account.",
              1: () =>
                "Tap your profile icon in the top-left corner (it shows your initials or an emoji).",
              2: () =>
                "Scroll down in the profile section and select 'Transaction Statement' under Reports and Statements.",
              3: () => "Choose the financial year",
              4: () =>
                "Tap 'Request Statement', and you'll receive an email with the statement",
              5: () => "Upload the file to Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    multichain: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet.",
              1: () =>
                "Select the cryptocurrency you would like to import. For example, Bitcoin or Ethereum.",
              2: () =>
                "Copy the corresponding public wallet address for that asset. To find this, you may need to click 'Recieve'.",
              3: () =>
                "In CTC, choose the corresponding blockchain network from the drop down.",
              4: () =>
                "Paste your public wallet address into CTC and click 'Import Wallet'.",
            },
          },
        },
      },
    },
    mxc: {
      api: {
        critical: {
          0: {
            items: {
              0: () =>
                "The MEXC API currently only gives access to the last 30 days transaction history. To import your entire transaction history, use the MEXC 'upload file' import method",
            },
            title: () => "MEXC API limitations.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "API Keys generated from MEXC are only valid for 90 days. A new key will need to be created and added after this time.",
              1: () =>
                "The MEXC API does NOT give access to deposits/withdrawals/airdrops etc, these will have to be added manually.",
              2: () =>
                "We currently do NOT support derivative or margin trading via MEXC.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log into your MEXC account",
              1: () =>
                "In the top right of the screen, hover over the account picture and select 'API Management' from the dropdown list (https://www.mexc.com/user/openapi)",
              2: () =>
                "Under the 'Spot' heading, select 'Account: View Account Details' and 'Trade: View Order Details'",
              3: () =>
                "In the Notes (Required), provide a nickname for the API key, such as 'Crypto Tax Calculator'",
              4: () =>
                "Tick the 'I have read and agree to the Risk Reminders for API Users' and click the button 'Create'",
              5: () =>
                "Copy the 'Access Key' and 'Secret Key' and enter them both into Crypto Tax Calculator",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your MEXC account. There are four different files we require based on your trading history",
              1: () =>
                "For futures trading data: On the top menu bar click 'Orders' and select 'Futures Orders'",
              2: () =>
                "Select 'Order & Trade History' and click the 'Export Order History' button (https://futures.mexc.com/orders/contract?type=historic)",
              3: () =>
                "In the popup window, select the time range you wish to download, select ‘EXCEL’ for the Export Format and press the 'Export' button to export your transaction records",
              4: () =>
                "For spot trading data: On the top menu bar click 'Orders' and select 'Spot Orders'",
              5: () =>
                "Select 'Trade History' and click the 'Export' button (https://www.mexc.com/orders/spot?tab=deal)",
              6: () =>
                "In the popup window, select the time range you wish to download, and press the 'Generate’ button to export your transaction records",
              7: () =>
                "For deposit history data: On the top menu bar click 'Wallets' and select 'Funding History'",
              8: () =>
                "Select 'Deposit' and click the 'Export' button (https://www.mexc.com/assets/record)",
              9: () =>
                "In the popup window, select the time range you wish to download, deselect ‘Encrypt the file’ and press the 'Generate’ button to export your deposit records",
              10: () =>
                "For withdrawal history data: On the top menu bar click 'Wallets' and select 'Funding History'",
              11: () =>
                "Select 'Withdrawal' and click the 'Export' button (https://www.mexc.com/assets/record)",
              12: () =>
                "In the popup window, select the time range you wish to download, deselect ‘Encrypt the file’ and press the 'Generate’ button to export your withdrawal records.",
              13: () =>
                "Upload all of the downloaded files here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    myEtherWallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "For each account associated with MEW:",
              1: () =>
                "Find the account's public Ethereum blockchain address, and copy this address to the clipboard. Hint: If you are using MEW Mobile App, you can find these details under 'Manage accounts' on the MEW Mobile App.",
              2: () =>
                "Paste the copied Ethereum wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    myTonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "You can enter either your bounceable, non bounceable, TON DNS, or raw format address, and we will translate it into the raw format for you.",
            },
            title: () => "Address types",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find and copy your TON wallet address from within the MyTonWallet extension",
              1: () => "Enter your wallet address into Crypto Tax Calculator",
              2: () => "Provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    ndax: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to NDAX",
              1: () =>
                "On the left hand side click on reports and export your 'Ledger' CSV",
              2: () => "You can then upload this into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    near: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Support for NFT transactions are coming soon",
            },
            title: () => "Not all operations are supported",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Near wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    neo: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "Our data provider for this blockchain is currently down. We are investigating a new provider; however, we suggest importing your data using our Custom CSV Template for now. https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-custom-csv-import",
            },
            title: () => "Our Neo integration is currently unavailable.",
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "Please note: due to unclaimed GAS, there may be a discrepancy between the calculated and reported GAS balances.",
            },
            title: () => "Balance Discrepencies",
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () => "Copy your public NEO wallet address",
              2: () =>
                "In CTC, choose the relevant blockchain from the dropdown",
              3: () =>
                "Paste your public wallet address and click 'Import Wallet'",
            },
          },
        },
      },
    },
    netcoins: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Our Netcoins integration is currently in Beta, if you have any issues please reach out to our support with your CSV file from Netcoins",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your Netcoins account",
              1: () =>
                "On your balances page, scroll down to the Transactions section",
              2: () =>
                "Click the 'export' button at the top of the transactions table to download a CSV export of your transactions",
              3: () => "Upload this CSV here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    neutron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Neutron wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    newton: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log into your Newton account on a web browser",
              1: () =>
                "Navigate to the 'Full History' button beside the 'Recent Activity' header. This will take you to the 'Account History' page",
              2: () => "Click on the 'Export account history' button",
              3: () =>
                "Export the 'Report for YYYY' where 'YYYY' is the year you wish to export (e.g. 2020). This will download a CSV file with a name of the format 'account-history-YYYY-Firstname-Lastname.csv'",
              4: () =>
                "Upload the file you just downloaded here into Crypto Tax Calculator",
              5: () =>
                "Make sure you repeat this process for all the years which there are reports available for downloading",
            },
          },
        },
      },
    },
    nexo: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Please note that the 'sell' transactions corresponding to each 'buy' will have to be added manually, as the 'sell' side of the exchange is not available in the data. You can do this in the 'Review Transactions' page.",
              1: () =>
                "Please ensure the date and time is in the format 'YYYY-MM-DD HH:mm:ss' or 'MM/DD/YYYY HH:mm'",
              2: () =>
                "Note: Some CSV rows may not be imported as they don't represent trading history, but rather Nexo's internal records. For example, an interest row with a negative USD amount represents a record of interest to be paid, and not an actual trade.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log into Nexo and click the transactions tab (https://platform.nexo.io/transactions)",
              1: () =>
                "Select the date range that covers all your transaction history",
              2: () => "Keep the default transaction types and assets as 'All'",
              3: () => "Export the CSV and import into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    nftbank: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "The CSVs in the 'only_symbol' folder do not include your NFT ids, only the symbol",
              1: () =>
                "Do not upload the CSVs from the 'only_symbol' folder if you have already uploaded the relevant CSV from the root folder or vice versa",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your NFTBank account on a web browser",
              1: () => "Navigate to your Portfolio, and click on 'Tax Filing'",
              2: () =>
                "Select the 'Crypto Tax Calculator' option and the wallets you want to export",
              3: () => "Confirm your email address and click 'Submit'",
              4: () =>
                "Download the zip file containing the CSV files sent to your email",
              5: () =>
                "Upload the relevant CSV files contained in the zip file (not the zip file itself) here",
            },
          },
        },
      },
    },
    nicehash: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "The Organisation ID can be found on the API Keys page and will not be given with the Key and Secret",
              1: () =>
                "Mining fees may be missing for old transactions which can cause inaccuracies in the calculated balances. If you experience this issue you can instead import via the 'Upload File' method, which typically includes these fees",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your NiceHash account",
              1: () =>
                "On the top right-hand corner of the navbar, click on your profile icon, and then click the orange 'My Settings' button to navigate to the settings page (https://www.nicehash.com/my/settings/)",
              2: () =>
                "On the left-hand nav, click on 'API Keys' (https://www.nicehash.com/my/settings/keys)",
              3: () =>
                "Click on 'Create New API Key' to open the API Key creation dialog",
              4: () =>
                "On 'Wallet Permissions', allow permission to 'View balances, wallet activities and deposit addresses'",
              5: () =>
                "On 'Market place Permissions', allow permission to 'View hashpower orders'",
              6: () =>
                "On 'Exchange Permissions', allow permission to 'View exchange orders'",
              7: () =>
                "On 'Mining Permissions', allow permission to 'View mining data and statistics'",
              8: () =>
                "You will be prompted to provide some additional authentication",
              9: () =>
                "Copy the API Key, Secret and Organisation ID of your account, and paste here in Crypto Tax Calculator as the 'API Key', 'Secret' and 'UID' respectively",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Separate reports will need to be generated for each cryptocurrency for which you have historical activity. This can be done by following the above steps for each of the different currencies.",
              1: () =>
                "Crypto Tax Calculator takes any mining fees directly out of the amount obtained (from mining) at the time of receiving the payment",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your NiceHash account",
              1: () =>
                "On the top right-hand corner of the navbar, click on your profile icon, and then click the orange 'My Settings' button to navigate to the settings page (https://www.nicehash.com/my/settings/)",
              2: () =>
                "On the left-hand nav, click on 'Reports' (https://www.nicehash.com/my/settings/reports)",
              3: () =>
                "Click on the 'New Report' to open the report creation dialog",
              4: () =>
                "You will now have to select the cryptocurrency for the report, as well as the 'FIAT' currency. Make sure the 'FIAT' currency is in your local currency and the same as you have in Crypto Tax Calculator. Ensure the 'Time-aggregation' is 'NONE'. Select the from/to date range which includes all your activity. Ensure the time zone is GMT'",
              5: () =>
                "Once the form is filled, click on 'Create' to generate the report. The report will take some time to process. Once processed, the status will turn to 'ready'",
              6: () =>
                "Download the report by clicking on the three dots under 'Action' and select 'Download Report' from the dropdown. This will download the report as a CSV file",
              7: () => "You can now upload this CSV into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    nolus: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Nolus wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    oasisdex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "Select the exchange from the dropdown menu",
              3: () => "In the date range select the month you traded",
              4: () => "Keep the other default settings",
              5: () => "Click 'Retrieve History'",
              6: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              7: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    oceanex: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Hover over ‘funds’ on the top of the page",
              1: () => "From the dropdown menu select trade history",
              2: () => "Export search results",
              3: () =>
                "Upload all files you downloaded to Crypto Tax Calculator. Make sure you have all your transactions",
            },
          },
        },
      },
    },
    okex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Currently, margin and futures trades are not supported. We are working to add support for these trades.",
              1: () =>
                "OKX only allows for the export of trade history data for the past 3 months from their API. If you have trades older than that, you will also need to upload a CSV file with older transactions.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to OKX",
              1: () =>
                "Navigate to the API menu (https://www.okx.com/account/my-api)",
              2: () => "Click 'Create V5 API Key'",
              3: () =>
                "Enter a name and passphrase, leave the IP address field blank, and only check the 'Read' box under permissions",
              4: () =>
                "Verify your account with an email code and 2FA authenticator code, and click confirm",
              5: () =>
                "Enter the API key, secret and the passphrase you created here into Crypto Tax Calculator",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/ZOf5ZjUYsEQ",
            title: () => "How to import your OKX API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Currently, we only support CSVs from the 'Unified' account type.",
              1: () =>
                "Currently, options trades are not supported. We are working to add support for these trades.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Login to OKX",
              1: () =>
                "Navigate to the reports center (https://www.okx.com/balance/report-center/)",
              2: () =>
                "For trades, under 'Trading history', click download, select 'All' as the instrument, and set the date range appropriately to cover all your transactions",
              3: () =>
                "Upload the 'Trading History' CSV here into Crypto Tax Calculator",
              4: () =>
                "For deposits and withdrawals, under 'Funding bill', click download, select 'Funding' as the account type, and set the date range appropriately to cover all your transactions",
              5: () =>
                "Upload the 'Funding Account History' CSV here into Crypto Tax Calculator",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/HpNmKgPLu3k",
            title: () => "How to import your OKX CSV files",
          },
        },
      },
      oauth: {
        step: {
          0: {
            items: {
              0: () => "Click 'Connect to OKX' to open a new tab.",
              1: () => "Log in to your OKX account.",
              2: () => "Click 'Approve' button",
            },
          },
        },
      },
    },
    openMask: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "You can enter either your bounceable, non bounceable, TON DNS, or raw format address, and we will translate it into the raw format for you.",
            },
            title: () => "Address types",
          },
          1: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find and copy your TON wallet address within the OpenMask extension",
              1: () => "Enter your wallet address into Crypto Tax Calculator",
              2: () => "Provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    opensea: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "In OpenSea, navigate to your profile",
              1: () => "Copy your public wallet address starting with '0x...'",
              2: () =>
                "Paste the copied public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    optimism: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the wallet address that you have used for trading via Optimism (starting with 0x)",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    osmosis: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Osmosis wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    pancakeSwap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We currently auto categorize Exchange and Liquidity transactions on PancakeSwap",
              1: () =>
                "If you have done Farming, Pools, or IFOs your transactions will be imported but you may need to review and re-categorize these appropriately",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find the Binance Smart Chain wallet address that you have used for trading via PancakeSwap",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    paradex: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    phantom: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the Solana blockchain wallet address that you have used for trading via Phantom",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    phemex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Phemex API only supports 3 months of historical data, so instead we use the CSV",
              1: () =>
                "We currently only support spot trading, deposit/withdrawal and closed PNL CSVs",
            },
          },
          1: {
            items: {
              0: () =>
                "Sign in to Phemex and hover over the 'Asset' menu in the top right corner",
              1: () =>
                "In the dropdown, click on 'History Order' > 'Spot History' and then click ‘Order History’ (https://phemex.com/assets/spot-history)",
              2: () =>
                "You can then click 'Export History' on the right and select the time period you have traded",
              3: () =>
                "You can only select 3 months at a time, so if you have used the platform longer than that you will have to download it for each month",
              4: () =>
                "Make sure you have downloaded your entire order history and then upload the files to Crypto Tax Calculator",
            },
            title: () => "Spot Trades:",
          },
          2: {
            items: {
              0: () =>
                "Sign in to Phemex and hover over the 'Asset' menu in the top right corner",
              1: () =>
                "In the dropdown, click on the 'History Order' and then ‘Deposit/withdraw History’ (https://phemex.com/assets/deposit-withdraw-history)",
              2: () =>
                "Select the ‘Deposit’ tab and then click ‘Generate Deposits Report’",
              3: () => "Select a date range and then click ‘Generate’",
              4: () =>
                "You can now click on the tab 'Withdraw' and also download this data",
              5: () =>
                "Again you only have the option to download 3 months of data at a time. Please make sure you have downloaded all deposit/withdrawals history and then upload all files to Crypto Tax Calculator",
            },
            title: () => "Deposit/Withdrawals:",
          },
          3: {
            items: {
              0: () =>
                "Sign in to Phemex and hover over the 'Asset' menu in the top right corner",
              1: () =>
                "In the dropdown, click on 'History Order' and then 'Futures History' (https://phemex.com/assets/contract-history)",
              2: () => "Click 'Closed PNL' from the available options",
              3: () =>
                "You can then click 'Generate Futures Trading Closed PNL Report' on the right and select the time period you have traded",
              4: () =>
                "Again you only have the option to download 3 months of data at a time. Please make sure you have downloaded all closed PNL history and then upload all files to Crypto Tax Calculator",
            },
            title: () => "Closed PNL:",
          },
        },
      },
    },
    pionex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Go to the Transaction History' tab on the left-hand side",
              1: () =>
                "Select the timeframe of the transaction history that you'd like to download and click submit",
              2: () =>
                "Once the files are ready, you will receive an email from Pionex once the file is ready to download",
              3: () =>
                "Go back to your Pionex account > Transaction History to download the file",
              4: () =>
                "Unzip the downloaded files and import 'trading.csv' and 'depositwithdraw.csv' into CTC",
              5: () =>
                "If you have other transaction files, please import them by using our custom CSV",
              6: () => "Note: There is a limit of 3 exports per month",
            },
          },
        },
      },
    },
    polkadot: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
          1: {
            items: {
              0: () => "Currently IBC cross chain transfers are not supported",
            },
            title: () => "Missing transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Polkadot wallet address (starting with 1) into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    polkadotjs: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open your Polkadot.js wallet",
              1: () => "Click the settings icon on the top right",
              2: () =>
                "Select 'Display Address Format For' and choose the desired network",
              3: () =>
                "Go back to your accounts view and click the copy to clipboard icon next to the desired account",
              4: () =>
                "In Crypto Tax Calculator select the corresponding network",
              5: () => "Paste the address and click 'Add Wallet'",
            },
          },
        },
      },
    },
    polkagate: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open your Polkagate wallet",
              1: () =>
                "Click the 3 dots menu next to the account you want to import",
              2: () => "Select the desired network in the 'chain' dropdown",
              3: () =>
                "Go back to your accounts view and click the copy to clipboard icon next to the desired account",
              4: () =>
                "In Crypto Tax Calculator select the corresponding network",
              5: () => "Paste the address and click 'Add Wallet'",
            },
          },
        },
      },
    },
    poloniex: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Please note, at this time, the Poloniex API will only sync transfers occurring from 22 January 2020, and trades occurring from 30 July 2021 (https://docs.poloniex.com/#authenticated-endpoints-trades).",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Login to Poloniex and click on the 'Settings' tab in the navigation bar",
              1: () =>
                "Navigate to the 'API Keys' page (https://poloniex.com/apiKeys)",
              2: () => "Click ‘Create New Key’ button",
              3: () =>
                "If applicable, enter your two-factor authentication code and or email instructions",
              4: () =>
                "Once you receive a verification email from Poloniex, confirm your new API key",
              5: () =>
                "For your new API key, deselect ‘Enable Trading’ and Enable Withdrawals’ checkboxes",
              6: () => "Set the IP access restriction to Unrestricted",
              7: () => "Copy both API Key and API Secret",
              8: () =>
                "Paste the copied 'API Key' and 'API secret' here into Crypto Tax Calculator and click 'Add API'",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Please import all your relevant 'Activities' (e.g Deposits, Withdrawal, Trades, etc) for which there is data so we can correctly categorize your transactions.",
              1: () =>
                "At this time we do not support automatic imports for 'Margin Borrowing' or 'Lending' Activity Types.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Login to Poloniex and click on the 'Activity' tab in the navigation bar",
              1: () =>
                "This should navigate you to the 'Wallets' history page (https://poloniex.com/activity/wallet)",
              2: () =>
                "At the top right of the screen, under the navigation bar, click on the 'Export' button",
              3: () =>
                "In Export Activity, select your 'Activity Type'. Currently we support the following: 'Deposits', 'Withdrawals', 'Trades', 'Distributions'",
              4: () =>
                "Once you have selected your Activity Type, select the date range which covers all its history",
              5: () =>
                "Click the 'Download' button. You should receive a CSV called '[activityType]-[selectedDateRange].csv' where [activityType] is your selected Activity Type, and the [selectedDateRange] is the date range you selected",
              6: () =>
                "Import the CSV(s) here to Crypto Tax Calculator without resaving or opening them (this can change the format on some computers)",
            },
          },
        },
      },
    },
    polygon: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
              1: () => "Balances may be delayed by up to a couple of hours",
            },
          },
          1: {
            items: {
              0: () =>
                "We now support ERC-1155 tokens, however, we are unable to import your current balances for these tokens. This may result in discrepancies between the balance imported from the source API and the balance calculated by the app.",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Polygon (Matic) wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    "polygon-zkevm": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Polygon Hermez (zkEVM) wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    pooltool: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "As PoolTool doesn't hold any of your assets, there will be no cryptocurrency holdings directly associated with this import. Instead, the staking rewards are associated with your bech32 stake address itself",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Navigate to (https://pooltool.io)",
              1: () => "In the top left, click 'rewards data for taxes'",
              2: () => "Paste your staking reward address",
              3: () =>
                "in the bottom left select: format - generic; currency - US Dollar; period - the year for which you want to export your staking data",
              4: () =>
                "Click 'Download Data' to download your data as a CSV file",
              5: () =>
                "Enter your bech32 stake address, and then upload this CSV file here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    probit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Probit's API sync only provides access to your trading history. You must manually import your Probit deposits and withdrawals into Crypto Tax Calculator separately. You can do this through our advanced custom CSV template: https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log into your Probit account",
              1: () =>
                "On the top right of the navbar hover over 'My Page' and click on 'API Management' from the dropdown (https://www.probit.com/en-us/user-center/api-management/api-credential)",
              2: () =>
                "Make sure the 'API Management' tab is highlighted, and enter 'Crypto Tax Calculator' as the 'API Key Label' and click 'Create new key'",
              3: () =>
                "You will be prompted to provide some additional authentication",
              4: () =>
                "Copy/save the 'Client ID' and 'Clients Secret Key' somewhere secure",
              5: () =>
                " Once authenticated you MUST edit the API key permissions - click on the 'Edit' button and then uncheck the 'Trading' checkbox and then click 'Edit Complete'",
              6: () =>
                "IMPORTANT: Make sure the only checkbox that is checked is 'Read(Default)'",
              7: () =>
                "Enter the 'Client ID' as the API Key and 'Clients Secret Key' as the Secret here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    protocol: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) =>
                "Open the wallet you used to interact with " +
                d.exchangeDisplayName,
              1: () => "Copy your public wallet address",
              2: () =>
                "In CTC, choose the relevant blockchain from the dropdown",
              3: () =>
                "Paste your public wallet address and click 'Import Wallet'",
              4: (d) =>
                "CTC will import all transactions from the drop down, including those associated with " +
                d.exchangeDisplayName,
            },
          },
        },
      },
    },
    pulsechain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
              1: () =>
                "The integration is currently in Beta and so may periodically fail at times. Please retry after a while if that occurs.",
            },
          },
          1: {
            items: {
              0: () => "We do not support ERC-1155 transactions at the moment.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your PulseChain wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    quantfury: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "At this stage, you will need to contact Quantfury support and request the trade history .xlsx file.",
              1: () =>
                "The format should be: 'Name, Action, Quantity, Price, Value, Date, Total Position'.",
              2: () =>
                "Once you have received this format, you will need to open it up and export it into a CSV format.",
              3: () =>
                "You will also need to remove any non-crypto transactions since we do not handle these",
              4: () =>
                "We are working with Quantfury to improve support for this",
            },
          },
        },
      },
    },
    quickswap: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We currently auto-categorize Swaps, and Adding/Removing Liquidity on QuickSwap",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find the Polygon (Matic) wallet address that you have used for trading via QuickSwap",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    "rabby-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Click on the Rabby extension in your browser",
              1: () =>
                "For each account associated with Rabby, select the account and click on the copy to clipboard icon",
              2: () =>
                "Paste the copied public wallet address (for the specified network) into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    radarRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    "rainbow-wallet": {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Click on the Rainbow Wallet extension in your browser",
              1: () =>
                "For each account associated with Rainbow Wallet, select the account and click the copy icon",
              2: () =>
                "Paste the copied public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    relaypay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your RelayPay account",
              1: () =>
                "Click on your username in the top right corner and select 'My Profile' (https://relaypay.io/profile)",
              2: () => "Navigate to the 'Buy Crypto' tab",
              3: () => "Click on 'Download all as CSV'",
              4: () =>
                "Repeat step 3 for the 'Bank Transfer' and 'Bill Payment' tabs",
              5: () =>
                "You can then import the CSV files here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    revolut: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to Revolut.",
              1: () => "Select 'Crypto' on the bottom menu.",
              2: () => "Choose a cryptocurrency.",
              3: () => "Tap on 'Statement'.",
              4: () => "Choose a time period.",
              5: () => "Select 'Generate'.",
              6: () =>
                "Repeat steps 3 to 6 for each cryptocurrency, as you can't generate all at once. ",
              7: () => "Download the statement in your Revolut app.",
            },
          },
        },
      },
    },
    ripple: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'.",
              1: () =>
                "For XRP, we currently only support XRP 'Payment' Transactions. If you have other transaction types (e.g. different currencies) please contact us via our chat support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () => "Copy your public XRP wallet address",
              2: () =>
                "In CTC, choose the relevant blockchain from the dropdown",
              3: () =>
                "Paste your public wallet address and click 'Import Wallet'",
            },
          },
        },
      },
    },
    robinhood: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Withdrawals and deposits are not supported via the API, please use the CSV import option",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your Robinhood account. Click 'Account' in the top-right corner, then select 'Settings'.",
              1: () =>
                "Navigate to the 'Crypto' section and locate the 'API Trading' option.",
              2: () =>
                "Follow https://help.cryptotaxcalculator.io/en/articles/10448126-import-robinhood-transactions-into-crypto-tax-calculator-using-api-keys to generate a base64 key pair.",
              3: () =>
                "Add the generated 'Public Key' to your Robinhood account.",
              4: () =>
                "Under API Permissions, enable all Read permissions. Skip setting any allowed IPs or expiration date for the API key.",
              5: () =>
                "Confirm the API key creation. Robinhood will display the API key.",
              6: () =>
                "In your CTC account, enter the API key into 'API Key' and the 'Private Key' from Step 3 into 'Secret'.",
            },
          },
        },
      },
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Reach out to Robinhood Support by visiting https://robinhood.com/contact",
              1: () =>
                "Click Documents and taxes → Transaction history → Contact us",
              2: () =>
                "Choose how you would like to be contacted, by email or phone",
              3: () =>
                "Request a copy of your transactions for your buy/sells and send/receives. This may be two files",
              4: () =>
                "Once you have your file(s), convert from PDF to CSV if necessary",
              5: () =>
                "Re-format the file(s) into CTC’s advanced CSV format (https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import)",
              6: () => "Upload your CSV file(s) here",
            },
          },
        },
      },
    },
    robinhood_wallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "This is for the Robinhood Web3 non-custodial wallet. To import your Robinhood Exchange account, please go here: https://app.cryptotaxcalculator.io/imports/new/robinhood",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Open the Robinhood non-custodial web3 wallet app",
              1: () =>
                "From the Home screen, select Menu (top left) and choose Receive",
              2: () => "Copy your public address",
              3: () => "Choose the relevant blockchain from the dropdown",
              4: () => "Paste the public address and click sync",
            },
          },
        },
      },
    },
    ronin: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Visit https://nftbank.ai/ and Sign In",
              1: () => "Click on the ‘Tax Filing’ tab in the left menu",
              2: () => "Select ‘Get your transaction history for tax filing’",
              3: () => "Select ‘Crypto Tax Calculator’ and click ‘Continue’",
              4: () =>
                "Select ‘Original Currency - Tax Service Provider Format’",
              5: () =>
                "If you haven’t already imported your Ronin wallet, click ‘Add Wallet’ and follow the NFTbank instructions",
              6: () =>
                "Once imported, select your Ronin wallet(s), click ‘Continue’",
              7: () =>
                "Enter your email where you will receive a download link",
              8: () =>
                "The file downloaded should be a .zip file, open the file to show its contents",
              9: () =>
                "Within the new folder, find the ronin.csv file and import that into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    saga: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Saga wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    scroll: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Scroll wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    sei: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Enter your Sei wallet address",
              1: () => "Add an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    sentinel: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Sentinel wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    shakepay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Shakepay account.",
              1: () => "Go to the main “Wallets” page.",
              2: () =>
                "Click on the “Download history” button next to the Transaction History title on the main page.",
              3: () => "Import the CSV to Crypto Tax Calculator.",
              4: () =>
                "Check https://help.shakepay.com/en/articles/3336094-where-can-i-see-my-full-transaction-history-monthly-statements-on-shakepay for more details.",
            },
          },
        },
      },
    },
    sharkRelay: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    singularx: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io/trades.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "Select the exchange from the dropdown menu",
              3: () => "In the date range select the month you traded",
              4: () => "Keep the other default settings",
              5: () => "Click 'Retrieve History'",
              6: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              7: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    softWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "If you can't find your coin or network, it means Crypto Tax Calculator cannot sync transactions for it yet. Please reach out to our live chat so we can add support!",
              1: () =>
                "Alternatively, you can enter these transactions manually via CTC's advanced CSV import option: https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import ",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Select the network",
              1: () =>
                "Copy your xpub, ypub, or zpub public key.  (e.g. BTC xpub key)",
              2: () => "Paste the public address and click 'Import wallet'.",
              3: () =>
                "More details (https://help.cryptotaxcalculator.io/en/articles/9158009-how-to-import-bitcoin-transactions-in-crypto-tax-calculator)",
            },
            title: () =>
              "Bitcoin, BitcoinCash, Dogecoin, BSV, and other networks with xpub wallets:",
          },
          1: {
            items: {
              0: () => "Select the network",
              1: () => "Copy your public wallet address.",
              2: () => "Paste your public address and click 'Import Wallet'.",
            },
            title: () =>
              "Ethereum (and ETH Tokens), Binance Smart Chain, Cardano, Ripple, Gnosis, Polygon, and other networks with an EVM wallet address:",
          },
          2: {
            items: {
              0: () => "Select the network",
              1: () =>
                "Find your Ltub public key (e.g, in Ledger Live under 'Advanced Logs').",
              2: () =>
                "Convert the Ltub address to an Xpub address here (https://jlopp.github.io/xpub-converter/).",
              3: () => "Paste the Xpub address and click 'Import Wallet'.",
            },
            title: () => "Litecoin:",
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/g8JcDe6zRZA",
            title: () => "How to import your Ledger Bitcoin wallet",
          },
        },
      },
    },
    solana: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'.",
            },
          },
          1: {
            items: {
              0: () =>
                "If uploading transactions via the manual CSV import please use 'solana-deprecated' or 'sol-deprecated' as the blockchain.",
            },
            title: () => "Using Manual CSV Import",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Solana wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    "solana-v2": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () => "Copy your public Solana wallet address",
              2: () =>
                "Paste your public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    solflare: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the Solana blockchain wallet address that you have used for trading via SolFlare",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    sollet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the Solana blockchain wallet address that you have used for trading via Sollet",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    sonic: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
          1: {
            items: {
              0: () =>
                "Live balances for tokens on Sonic are currently unavailable. Transaction details and tax calculations are unaffected",
            },
            title: () => "Live balances",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Copy your Sonic public wallet address starting with '0x...'",
              1: () => "Paste your public wallet address into CTC",
              2: () => "Provide a nickname, and click 'Import Wallet'",
            },
          },
        },
      },
    },
    spiritswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the Fantom blockchain wallet address that you have used for trading via SpiritSwap.",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    spookyswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the Fantom blockchain wallet address that you have used for trading via SpookySwap.",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    stacks: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Stacks wallet address (starting with SP) into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    staketax: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Search your wallet address on stake.tax and select Crypto Tax Calculator in the download prompt",
              1: () => "Upload the CSV here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    stargaze: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Stargaze in early development",
              1: () =>
                "Transfers/staking labels only (others appear as unknown)",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Stargaze wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    starknet: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Open your Starknet wallet (Argent, Braavos, etc.)",
              1: () =>
                "Copy your public wallet address or contract address starting with '0x...'",
              2: () =>
                "Paste the address into Crypto Tax Calculator, add an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    stellar: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () => "Copy your public Stellar wallet address",
              2: () =>
                "In CTC, choose the relevant blockchain from the dropdown",
              3: () =>
                "Paste your public wallet address and click 'Import Wallet'",
            },
          },
        },
      },
    },
    stex: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Log in to STEX, click your email in the top right and select profile.",
              1: () =>
                "On the left hand side click 'Report' (https://app.stex.com/en/profile/report)",
              2: () => "Click the 'User History' and click the export button",
              3: () => "You can now upload this CSV into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    stormrake: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Stormrake account",
              1: () => "Download your Transaction History",
              2: () => "Upload the CSV file into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "substrate-chains": {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We may have some missing fees on bridge transactions as the blockchain does not provide this information in some cases.",
              1: () =>
                "Additionally we may be missing some transfer transactions from the blockchain. If you experience this issue, please contact support.",
            },
            title: () => "Missing Transactions",
          },
          1: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) =>
                "Enter your " +
                d.exchangeDisplayName +
                " wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    subwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open your SubWallet wallet",
              1: () =>
                "Choose the account you want to import on the top account dropdown",
              2: () => "Click the copy address icon",
              3: () => "Copy the address of the desired network",
              4: () =>
                "In Crypto Tax Calculator select the corresponding network",
              5: () => "Paste the address and click 'Add Wallet'",
            },
          },
        },
      },
    },
    sui: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () => "Open your Sui wallet",
              1: () =>
                "For each account associated with Sui, select the account and hover over the account name",
              2: () => "Copy the public wallet address starting with '0x...'",
              3: () =>
                "Paste the copied public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    swissborg: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "For more detailed steps, SwissBorg has put together a guide here: https://help.swissborg.com/hc/en-gb/articles/360016499698-Account-Statement",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log in to your SwissBorg account via the SwissBorg App",
              1: () => "Go to your 'profile' and tap 'Account Statement'",
              2: () =>
                "Select the date range which covers your complete trading history",
              3: () =>
                "Select the reference currency as your local fiat currency",
              4: () => "Choose the 'Excel' format, and save the file",
              5: () => "Upload this file here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    switcheo: {
      csv: {
        note: {
          0: {
            items: {
              0: () => "Date format should be YYYY-MM-DD HH:mm:ss",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Switcheo Exporter (https://benammann.github.io/switcheo-exporter/#/).",
              1: () => "Enter your public wallet address and export the CSV.",
              2: () =>
                "Click the 'Download CSV' button in the top right corner.",
              3: () =>
                "You can now upload this CSV into Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    swyftx: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "We are currently working with Swyftx to support their new Staking feature. Staking deposits and withdrawals will not be imported at this time",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Visit https://trade.swyftx.com.au/profile/api/",
              1: () => "Click 'Create New Key.'",
              2: () => "Click 'I Understand' after reading the warning.",
              3: () =>
                "Enter a label (e.g., Crypto Tax Calculator), set the 'Scope' to 'Read Only,' and enter your Swyftx password to confirm.",
              4: () =>
                "Copy the 'API Key' and 'Access Token,' then paste them into Crypto Tax Calculator as the 'API Key' and 'Secret.'",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q9QJesWUiMk",
            title: () => "How to import your Swyftx API",
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "The CSV export from Swyftx may have inconsistencies with Daylight Savings timestamps. For better accuracy, use API import if possible.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Visit https://trade.swyftx.com/profile/transaction-reports",
              1: () => "Select the transaction period",
              2: () => "Click 'Download.csv.'",
              3: () => "Upload the CSV to Crypto Tax Calculator.",
            },
          },
        },
        video: {
          0: {
            link: () => "https://youtu.be/q_WXPgSRQoQ",
            title: () => "How to import your Swyftx CSV files",
          },
        },
      },
    },
    taiko: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Taiko Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Copy your Taiko public wallet address starting with '0x...'",
              1: () => "Paste your public wallet address into CTC",
              2: () => "Provide a nickname, and click 'Import Wallet'",
            },
          },
        },
      },
    },
    talisman: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open your Talisman wallet",
              1: () => "Click on the account you want to import",
              2: () => "Click the copy address icon",
              3: () => "Select the desired network",
              4: () =>
                "In Crypto Tax Calculator select the corresponding network",
              5: () => "Paste the address and click 'Add Wallet'",
            },
          },
        },
      },
    },
    terra: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () =>
                "If you imported your Terra transactions before 18 May 2022, you may run into pricing errors in-app due to the renaming of LUNA to LUNC. We recommend deleting, and re-importing your Terra Classic wallet in order to rectify these issues.",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Terra wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    terra2: {
      "wallet-api": {
        critical: {
          0: {
            items: {
              0: () => "Terra 2.0 is in early development",
            },
          },
        },
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Terra 2.0 wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    theta: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'.",
              1: () =>
                "We currently do not support TNT721 tokens, and you will have to add these manually in the 'Review Transactions' page.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Theta wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    thetaboard: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Before uploading the file make sure you enter your wallet address so we know where to associate these transactions to.",
              1: () =>
                "We currently do not support TNT721 tokens, and you will have to add these manually in the 'Review Transactions' page.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Thetaboard (https://thetaboard.io/wallet/explorer).",
              1: () => "Enter your public wallet address.",
              2: () =>
                "Click 'Export Transaction History' (under Transactions).",
              3: () =>
                "You can now upload this CSV into Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    thetawallet: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Theta wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "Before uploading the file make sure you enter your wallet address so we know where to associate these transactions to",
              1: () =>
                "If you want more accurate fee information you should use 'Theta' import instead which reads from the blockchain",
              2: () =>
                "We currently do not support TNT721 tokens, and you will have to add these manually in the 'Review Transactions' page.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Navigate to the ThetaScan (http://www.thetascan.io/).",
              1: () => "Enter your public wallet address.",
              2: () => "Click 'Export Transactions' (under your balance)",
              3: () => "You can now upload this CSV into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    thorchain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We currently only support the 'Send' and 'Swap' transaction types.",
            },
            title: () => "Supported Types",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your THORChain wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add THORChain Wallet'",
            },
          },
        },
      },
    },
    toast: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "For Ripple, we currently only support XRP 'Payment' Transactions. If you have other transaction types (e.g. different currencies) please contact us via our chat support.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "For each account associated with your Toast wallet:",
              1: () => "Find the account's public blockchain address.",
              2: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    tokenJar: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    tokenStore: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances (https://deltabalances.github.io)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () =>
                "On the left hand side in the menu expand 'Exchange History' and click TokenStore",
              3: () => "Under 'Operation types' click 'Trades + Funds'",
              4: () => "In the date range select the month you traded",
              5: () => "Keep the other default settings",
              6: () => "Click 'Retrieve History'",
              7: () =>
                "Once complete you can export the default csv under 'Export Trades' and 'Export Funds'",
              8: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload these CSV files",
            },
          },
        },
      },
    },
    tokenion: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    ton: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can enter either your bounceable, non bounceable, TON DNS, or raw format address, and we will translate it into the raw format for you.",
            },
            title: () => "Address types",
          },
          1: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your TON wallet address or TON DNS into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    tonWallet: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "You can enter either your bounceable, non bounceable, TON DNS, or raw format address, and we will translate it into the raw format for you.",
            },
            title: () => "Address types",
          },
          1: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find your TON wallet address within the Wallet app in telegram. To view your address, you may need to click the 'Add Crypto' button, followed by the 'External Wallet', then 'Toncoin' options, and copy the address from there",
              1: () => "Enter your wallet address into Crypto Tax Calculator",
              2: () => "Provide an optional nickname, and click 'Add Wallet'",
              3: () =>
                "Repeat the above steps for any TON Space wallets you have, finding the address by clicking on the TON Space within your wallet and clicking on the address shorthand to copy it to your clipboard",
            },
          },
        },
      },
    },
    toncoin: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "You can enter either your bounceable, non bounceable, TON DNS, or raw format address, and we will translate it into the raw format for you.",
            },
            title: () => "Address types",
          },
          1: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your TON wallet address or TON DNS into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    tonhub: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "You can enter either your bounceable, non bounceable, TON DNS, or raw format address, and we will translate it into the raw format for you.",
            },
            title: () => "Address types",
          },
          1: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find and copy your TON wallet address from within the Tonhub app. To view your address, you may need to click the 'receieve' button, and copy the address from there",
              1: () => "Enter your wallet address into Crypto Tax Calculator",
              2: () => "Provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    tonkeeper: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "You can enter either your bounceable, non bounceable, TON DNS, or raw format address, and we will translate it into the raw format for you.",
            },
            title: () => "Address types",
          },
          1: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find your TON wallet address within the Tonkeeper app, either on telegram, or in the mobile or desktop app. To view your address, you may need to click the 'receieve' button, and copy the address from there",
              1: () => "Enter your wallet address into Crypto Tax Calculator",
              2: () => "Provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    tonpay: {
      "soft-wallet": {
        note: {
          0: {
            items: {
              0: () =>
                "You can enter either your bounceable, non bounceable, TON DNS, or raw format address, and we will translate it into the raw format for you.",
            },
            title: () => "Address types",
          },
          1: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Find and copy your TON wallet address from within the Tonpay telegram app. You can view your wallet address by typing /start and selecting the 'Finance', then 'Wallet' option",
              1: () => "Enter your wallet address into Crypto Tax Calculator",
              2: () => "Provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    tradeSatoshi: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Login to TradeSatoshi",
              1: () =>
                "Navigate to Order History https://tradesatoshi.com/Orders",
              2: () =>
                "Click on ‘Export’ button before uploading CSV to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    "trader-joe": {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the Avalanche C-Chain wallet address that you have used for trading via Trader Joe.",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    trezor: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to add the ticker symbol for each currency you would like to upload",
              1: () =>
                "Please ensure the date & time is in the format 'DD/MM/YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss' or 'M/D/YYYY h:mm:ss A'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "You can follow the steps here to export your CSV data (https://wiki.trezor.io/User_manual:Transaction_details)",
            },
          },
        },
      },
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Connect your Trezor to your device",
              1: () => "Visit wallet.trezor.io",
            },
          },
          1: {
            items: {
              0: () =>
                "Select the relevant account, and and navigate to the Basic tab",
              1: () => "Click on Show XPUBs",
              2: () => "Copy the xPub address",
              3: () => "Paste into CTC",
            },
            title: () =>
              "For Bitcoin, Bitcoin Cash, Dash, Dogecoin, Litecoin, and Zcash wallets",
          },
          2: {
            items: {
              0: () =>
                "Select Select the relevant coin, and view public wallet addresses underneath Transactions",
              1: () => "Copy each public address",
              2: () => "Paste into CTC",
            },
            title: () => "For all other coins",
          },
        },
      },
    },
    tron: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'.",
              1: () =>
                "Currently we only support TRX and TRC10 Tron tokens, any other transactions will have to be imported manually into Crypto Tax Calculator.",
              2: () =>
                "The import only covers transfers. We do not handle on-chain interactions such as deploying smart contract at this stage.",
            },
          },
        },
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () => "Copy your public Tron wallet address",
              2: () =>
                "In CTC, choose the relevant blockchain from the dropdown",
              3: () =>
                "Paste your public wallet address and click 'Import Wallet'",
            },
          },
        },
      },
    },
    trustwallet: {
      "soft-wallet": {
        step: {
          0: {
            items: {
              0: () => "Open the Trust Wallet mobile app or browser extension",
              1: () => "If necessary, choose your wallet under Settings",
              2: () => "On the Wallet tab, choose Receive and select the token",
              3: () => "Copy the public address ",
              4: () =>
                "Paste into CTC and press sync to import your transactions",
            },
          },
        },
      },
    },
    unichain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "We now support ERC-1155 tokens, however, we are unable to import your current balances for these tokens. This may result in discrepancies between the balance imported from the source API and the balance calculated by the app.",
            },
            title: () => "ERC-1155 Tokens",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Unichain wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    uniswap: {
      "wallet-api": {
        step: {
          0: {
            items: {
              0: () =>
                "Find the Ethereum blockchain wallet address that you have used for trading via Uniswap.",
              1: () =>
                "Enter this address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'.",
            },
          },
        },
      },
    },
    uphold: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "See more details steps for the different Uphold interfaces here: https://support.uphold.com/hc/en-us/articles/360046137012-How-can-I-download-my-transaction-history-",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Sign in to your Uphold Account and go to the wallets dashboard",
              1: () =>
                "At the left, click the icon for 'Activity' to go to the activity page (https://wallet.uphold.com/dashboard/activity)",
              2: () =>
                "In the left panel, at the top right, click the Document icon",
              3: () => "Click on the green button 'Generate Report'",
              4: () =>
                "A few minutes later you'll receive an email with the option to download your transaction history in CSV format",
              5: () =>
                "Upload the downloaded CSV file here to Crypto Tax Calculator",
            },
          },
        },
      },
    },
    valr: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log in to your VALR account",
              1: () => "Download your transaction history CSV file from VALR",
              2: () => "Upload this CSV file here into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    vechain: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to 'https://explore.vechain.org/download?address=INSERT_ADDRESS' but replace INSERT_ADDRESS with your public wallet address",
              1: () =>
                "Select the time period you prefer your transaction history to range from",
              2: () => "Verify you are a human",
              3: () => "Click 'Export'",
              4: () => "Upload the CSV file here",
            },
          },
        },
      },
      "wallet-api": {
        step: {
          0: {
            items: {
              0: (d) => "Open your " + d.exchangeDisplayName + " wallet",
              1: () => "Copy your public Vechain wallet address",
              2: () =>
                "In CTC, choose the relevant blockchain from the dropdown",
              3: () =>
                "Paste your public wallet address and click 'Import Wallet'",
            },
          },
        },
      },
    },
    velas: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
          1: {
            items: {
              0: () =>
                "There may be some missing internal transactions for some wallets.",
            },
            title: () => "Missing internal transactions",
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Velas wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    virgocx: {
      csv: {
        step: {
          0: {
            items: {
              0: () =>
                "Log in to your VirgoCX account here (https://virgocx.ca/login).",
              1: () =>
                "Click 'eDocument' on the top right corner under your name.",
              2: () => "In the left navigation bar, click 'Data Export'.",
              3: () =>
                "Select 'Transaction Data' as your report type and 'Cryptocurrency' as your asset.",
              4: () =>
                "Under 'Date', choose the range which includes your complete transaction history with VirgoCX. You might need to download multiple files to ensure full coverage.",
              5: () => "Proceed with exporting the CSV files.",
              6: () =>
                "Complete this same process but select 'Fiat Currency' as the asset this time.",
              7: () =>
                "Repeat all the steps above, but now choose 'Trade Data' instead of 'Transaction Data.'",
              8: () => "Upload the CSV files into Crypto Tax Calculator here.",
            },
          },
        },
      },
    },
    wealth99: {
      api: {
        step: {
          0: {
            items: {
              0: () => "Log in to your Wealth99 exchange account",
              1: () =>
                "Go to Settings on the left side menu and select the 'Security' tab",
              2: () => "Click 'Create an API Key'",
              3: () => "Ensure that you have checked 'Enable read info'",
              4: () =>
                "Copy the 'API Key' and 'API Secret', enter them both into Crypto Tax Calculator here, and click 'Sync API' to sync your data",
            },
          },
        },
      },
    },
    xdai: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
              1: () =>
                "As there is no data available for the receiving of the xDai via the xDai Bridge when converting DAI to XDAI from the Ethereum Network, you will have to manually enter these transactions as 'buys' into Crypto Tax Calculator",
              2: () =>
                "As there is no data available for the receiving of tokens on the xDai Chain via the xDai OmniBridge when bridging from the Ethereum Network, you will have to manually enter these transactions as 'buys' into Crypto Tax Calculator",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Gnosis wallet address into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    xverse_wallet: {
      "connect-wallet": {
        step: {
          0: {
            items: {
              0: () => "Click ‘connect wallet’ and follow the prompts.",
            },
          },
        },
      },
    },
    yobit: {
      api: {
        note: {
          0: {
            items: {
              0: () =>
                "Yobit API only gets transaction history from a week ago",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Under your account name select API keys",
              1: () => "Create a new API key for Crypto Tax Calculator",
              2: () =>
                "Paste the key and secret key into Crypto Tax Calculator",
            },
          },
        },
      },
    },
    yoroi: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You can alternatively import your Yoroi wallet transactions directly via the blockchain by connecting with the 'Sync via API' option. This will pull in all transactions and staking rewards for your Yoroi wallet",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () => "Log into Yoroi",
              1: () => "Navigate to the transaction summary page",
              2: () => "In the top right you will see the export icon",
              3: () => "You can now import this CSV into Crypto Tax Calculator",
            },
          },
        },
      },
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Only enter ONE address from each wallet. We perform a lookup and pull in the data for all other addresses in the wallet automatically.",
              1: () =>
                "Importing multiple addresses from the same wallet will result in duplicate transactions.",
              2: () =>
                "Currently we do not support obtaining the complete transaction history of wallets where each individual address has more than 10 transactions. We are working to provide additional support for such wallets.",
            },
          },
          1: {
            items: {
              0: () =>
                "If you import an address starting with ‘addr1’, we automatically change it to the account owner’s address that starts with ‘stake1’.",
              1: () =>
                "This enables us to import all other addresses you may create in the same wallet and capture your staking rewards.",
            },
            title: () => "Why is my address stake1…?",
          },
        },
        step: {
          0: {
            items: {
              0: () => "Open your Yoroi wallet and click Wallet > Receive",
              1: () =>
                "Click 'Has Balance' and then copy one of the public address's starting with 'addr1…'",
              2: () =>
                "Paste your public wallet address into CTC and click 'Import Wallet'",
            },
          },
        },
      },
    },
    zebpay: {
      csv: {
        step: {
          0: {
            items: {
              0: () => "Log into your ZebPay account.",
              1: () => "Click on the profile icon in the top-right corner.",
              2: () => "Select Statements.",
              3: () => "Choose Consolidated Account Statement.",
              4: () => "Set the start and end dates, then click Submit.",
              5: () =>
                "You'll receive the report via email — download it from there.",
              6: () => "Upload the files to Crypto Tax Calculator.",
            },
          },
        },
      },
    },
    zeroExProtocol: {
      csv: {
        note: {
          0: {
            items: {
              0: () =>
                "You will need to import the CSV files for each month you traded",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Navigate to Delta Balances > Exchange History > 0x Protocol (https://deltabalances.github.io/history4.html)",
              1: () =>
                "Enter your public wallet address in the search bar at the top of the page and click the search icon",
              2: () => "In the date range select the month you traded",
              3: () => "Keep the other default settings",
              4: () => "Click 'Retrieve History'",
              5: () =>
                "Once complete click 'Export Trades' and select 'Default V2 (CSV)'",
              6: () =>
                "In Crypto Tax Calculator you can now enter your wallet address and upload this CSV",
            },
          },
        },
      },
    },
    zetachain: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your ZetaChain wallet address (starting with 0x) into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    zksync: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "Note that zkSync Era transactions will not be imported until they are verified by the blockchain. This typically takes 30-60 minutes but can extend to 24 hours or longer. This may also result in temporary balance discrepancies, which will be resolved after the transactions are verified.",
            },
            title: () => "zkSync Era transaction delay",
          },
          1: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your zkSync Era wallet address (starting with 0x) into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
    zora: {
      "wallet-api": {
        note: {
          0: {
            items: {
              0: () =>
                "You can add multiple wallets by entering another address and clicking 'Add Additional Wallet'",
            },
          },
        },
        step: {
          0: {
            items: {
              0: () =>
                "Enter your Zora wallet address (starting with 0x) into Crypto Tax Calculator, provide an optional nickname, and click 'Add Wallet'",
            },
          },
        },
      },
    },
  },
  importInstructionsNotice: {
    binance: () =>
      "Binance has shared in its 2021-08-06 announcement, that API access will be provided only for accounts which have completed identity verification. If your API sync is not working, we recommend completing Binance's KYC (intermediate) identity verification before trying again.",
    coinsmart: () =>
      "Coinsmart has stopped operations as of October 2023 and all user accounts have been migrated to Bitbuy. See announcement: 'https://www.coinsmart.com/blog/important-notice-changes-to-your-coinsmart-account/'",
  },
};
