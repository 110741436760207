import { type PopupState, usePopupState } from "material-ui-popup-state/hooks";
import { createContext, type ReactNode, useContext, useState } from "react";

// Define the modal context type
export type ModalContextType = PopupState & {
  setLoading: (loading: boolean) => void;
  loading: boolean;
};

// Create the modal context
const ModalContext = createContext<ModalContextType | undefined>(undefined);

export type PopupStateParams = Parameters<typeof usePopupState>[0];

// Create the modal provider
export const ModalProvider = ({
  children,
  ...usePopupStateProps
}: { children: ReactNode } & PopupStateParams &
  Required<Pick<PopupStateParams, "popupId">>) => {
  const popupState = usePopupState({
    ...usePopupStateProps,
  });
  const [loading, setLoading] = useState(false);

  // Provide the modal context value to the child components
  const modalContextValue: ModalContextType = {
    ...popupState,
    setLoading,
    loading,
  };

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
    </ModalContext.Provider>
  );
};

// Custom hook to access the modal context
export const useModal = (): ModalContextType => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return modalContext;
};
