import { Close } from "@mui/icons-material";
import { AppBar, Box, Divider, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";

interface DialogExitBarProps {
  title?: string;
  handleClose: () => void;
  closeDisabled: boolean;
  barMode?: boolean;
}

export function DialogExitBar({
  title,
  handleClose,
  closeDisabled,
  barMode,
}: DialogExitBarProps) {
  const { tokens } = useDesign();
  return (
    <StyledAppBar barMode={barMode}>
      <Box
        display="inline-flex"
        alignItems="center"
        justifyContent={barMode ? "flex-start" : "flex-end"}
        color="inherit"
      >
        <TextIconButton
          size="medium"
          onClick={() => {
            handleClose();
          }}
          disabled={closeDisabled}
          sx={{ color: tokens.icon.low }}
        >
          <Close />
        </TextIconButton>
        {barMode && (
          <>
            <StyledDivider orientation="vertical" flexItem variant="middle" />
            <StyledBox>
              <StyledTypography variant="Metropolis/Body/Regular">{title}</StyledTypography>
            </StyledBox>
          </>
        )}
      </Box>
    </StyledAppBar>
  );
}
// Note for a transparent background on dark mode we need to clear the background image due to a bug
// https://github.com/mui/material-ui/issues/28997
const StyledAppBar = styled((props) => <AppBar {...props} />)`
  position: ${({ barMode }) => (barMode ? "sticky" : "absolute")};
  border-bottom: ${({ barMode, theme }) =>
    barMode ? `1px solid ${theme.tokens.border.neutral.default}` : "none"};
  box-shadow: none;
  padding: 0.5rem;
  background-color: ${({ barMode, theme }) =>
    barMode ? theme.tokens.background.neutral.default : "transparent"};
  background-image: none;
`;

const StyledDivider = styled(Divider)`
  margin-right: 0.5rem;
`;

const StyledBox = styled(Box)`
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  line-height: 0;
`;
