import { CategoryBucket } from "~/types/enums";

export const categoryBucketOrder: Record<CategoryBucket, number> = {
  [CategoryBucket.Acquisition]: 10,
  [CategoryBucket.Disposal]: 20,
  [CategoryBucket.Transfer]: 30,
  [CategoryBucket.Income]: 40,
  [CategoryBucket.Expenses]: 50,
  [CategoryBucket.Derivatives]: 60,
  [CategoryBucket.Loss]: 70,
  [CategoryBucket.Ignore]: 80,
};
