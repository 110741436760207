import moment from "moment-timezone";
import { useContext } from "react";

import { FYContext } from "~/contexts/FYContext";
import {
  useHighestRankingPlanUser,
  usePaywallInfo,
  useUser,
} from "~/redux/auth";
import { Features } from "~/types/enums";

/**
 * @returns If the user can see the timeframe (true) or if they should be
 * paywalled (false)
 */
export const useCanSeeTimeframe = () => {
  const user = useUser();
  const highestRankingPlanUser = useHighestRankingPlanUser();
  const { timeframe, fyDates } = useContext(FYContext);
  const paywallInfo = usePaywallInfo();

  if (!user || !highestRankingPlanUser || paywallInfo.isPaywalled) return false;

  // timeframe wont be available if observing TLH via the dashboard
  // so we need to check if the user has the multi-tax years feature first
  if (highestRankingPlanUser.features[Features.MultiTaxYears]) return true;

  if (!timeframe || !fyDates.length) return false;

  const previousFY = fyDates[1];

  // Momentify to make check sturdier
  const timeframeStart = moment(timeframe.start);
  const previousStart = moment(previousFY.start).startOf("day");

  if (timeframeStart.isSameOrAfter(previousStart)) return true;

  return false;
};
