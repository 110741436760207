import { SyncStatusPlatform } from "@ctc/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StringParam, useQueryParam } from "use-query-params";

import { FORM_IMPORT_SUBMITTED_EVENT } from "~/analytics/analytics";
import { importNewAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import {
  importAccountKey,
  useCaptureImportAnalytics,
} from "~/components/imports/AnalyticsHelpers";
import { queryClient } from "~/components/queryClient";
import { middleTrim } from "~/lib/index";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useSelector } from "~/redux/useSelector";
import {
  fireAnalyticsOnDelete,
  fireAnalyticsOnUpload,
} from "~/segment/importAnalytics";
import { HttpError } from "~/services/core";
import * as importsV2 from "~/services/imports";
import {
  type GetUpdatesFunc,
  optimisticallyUpdateAccountPending,
  optimisticallyUpdateKey,
} from "~/services/imports";
import * as keys from "~/services/keys";
import * as oauth from "~/services/oauth";
import { entityKeys } from "~/state/entities";
import {
  accountHasImports,
  savedImports,
  useViewModeSyncKey,
} from "~/state/importsV2";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import {
  useSetSyncMutation,
  useUploadDeprecatedHandler,
  useUploadPendingHandler,
} from "~/state/sync";
import { ImportType, IntegrationCategory } from "~/types/enums";
import {
  type AllSavedImports,
  type ImportOptionV2,
  type KeyDetails,
  type KeyUpdateDetails,
  type SavedImportByIntegration,
  type SavedImportOptionByAccount,
  type SavedKeyImport,
} from "~/types/index";

export const useSaveKeyMutation = (importOption: ImportOptionV2) => {
  const queryClient = useQueryClient();
  const viewModeKey = useViewModeSyncKey();
  const user = useUser();
  const errorMessage = useSelector((state) =>
    state.lang.map.keys.wentWrongSavingAPI({
      name: importOption.name,
    }),
  );
  const captureAnalytics = useCaptureAnalytics();
  const syncKey = useSyncKeyMutation(importOption.name);
  const getTempSyncKey = (key: KeyDetails) =>
    `${importOption.id}_${key.apiKey}`;
  const syncSet = useSetSyncMutation();
  const [suspectedMissingImport] = useQueryParam(
    "suspectedMissingImport",
    StringParam,
  );
  const isSuspectedMissingImport = suspectedMissingImport === "true";

  return useMutation({
    retry: 3,
    mutationFn: async ({
      importOption,
      key,
    }: {
      importOption: ImportOptionV2;
      key: KeyDetails;
    }) => {
      syncSet.mutate({
        scope: getTempSyncKey(key),
        status: SyncStatusPlatform.Pending,
      });
      captureAnalytics("exchange_imported", {
        integration: importOption.id,
        exchangeName: importOption.name,
        importType: ImportType.API,
        isSuspectedMissingImport,
        isNicknamed: !!key.name,
      });
      captureAnalytics(importNewAnalyticsKey(FORM_IMPORT_SUBMITTED_EVENT), {
        integration: importOption.id,
        exchangeName: importOption.name,
        importType: ImportType.API,
        isNicknamed: !!key.name,
      });
      await fireAnalyticsOnUpload(user, {
        label: importOption.id,
        name: importOption.name,
        importType: ImportType.API,
      });
      const res = await keys.saveKey(importOption.id, key);
      if (res.error) {
        throw new Error(errorMessage);
      }
      syncKey.mutate({
        exchangeId: importOption.id,
        name: importOption.name,
        isHardSync: true,
        isOAuth: false,
        apiDetails: res.data,
      });

      return res.data;
    },
    onMutate: async ({ importOption, key }) => {
      const getUpdates: GetUpdatesFunc = ({
        prevByAccount,
        prevByIntegration,
      }) => {
        const dummyKey: SavedKeyImport = {
          id: getTempSyncKey(key),
          name: key.name,
          key: middleTrim(key.apiKey || key.uid || "", 12, 3),
          status: SyncStatusPlatform.Pending,
          createdAt: new Date().toISOString(),
          completedAt: null,
          importType: ImportType.API,
          lastImportedTxTimestamp: null,
        };

        const newByAccount: SavedImportOptionByAccount = {
          id: importOption.id,
          name: importOption.name,
          category: importOption.category,
          availableImportMethods: [],
          assets: [],
          value: 0,
          totalTxCount: undefined,
          spamTxCount: undefined,
          lastImportedTxTimestamp: null,
          lastSyncComplete: null,
          oauths: [],
          wallets: [],
          files: [],
          ...prevByAccount,
          syncStatus: SyncStatusPlatform.Pending,
          keys: [...(prevByAccount?.keys || []), dummyKey],
        };

        const newByIntegration: SavedImportByIntegration = {
          id: importOption.id,
          name: importOption.name,
          category: importOption.category,
          files: [],
          oauths: [],
          wallets: [],
          ...prevByIntegration,
          keys: [...(prevByIntegration?.keys || []), dummyKey],
        };

        return {
          newByAccount,
          newByIntegration,
        };
      };

      const { previousData } = await optimisticallyUpdateKey({
        queryClient,
        viewModeKey,
        accountId: importOption.id,
        getUpdates,
      });

      return {
        previousData,
      };
    },
    onError(
      err,
      params,
      context:
        | {
            previousData?: AllSavedImports;
          }
        | undefined,
    ) {
      const previousData = context?.previousData;
      queryClient.setQueryData(viewModeKey, previousData);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
      queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const useSyncKeyMutation = (name: string) => {
  const errorMessage = useSelector((state) =>
    state.lang.map.sync.syncFailed({ name }),
  );
  const lang = useLang();
  const uploadPending = useUploadPendingHandler();
  const uploadDeprecated = useUploadDeprecatedHandler();
  const syncSet = useSetSyncMutation();
  const captureAnalytics = useCaptureImportAnalytics();
  const viewModeKey = useViewModeSyncKey();

  return useMutation({
    retry: 3,
    mutationFn: async ({
      exchangeId,
      name,
      isHardSync,
      isOAuth,
      apiDetails,
      showMessage = true,
    }: {
      exchangeId: string;
      name: string;
      isHardSync: boolean;
      isOAuth?: boolean;
      apiDetails: KeyDetails;
      showMessage?: boolean;
    }) => {
      captureAnalytics(importAccountKey("resync"), {
        isHardSync,
        exchange: exchangeId,
        type: isOAuth ? "oauth" : "api",
      });

      if (apiDetails.isDeprecatedKey) {
        uploadDeprecated({
          id: exchangeId,
        });
      }

      // @todo - we won't need this check if the types are better defined
      const { id } = apiDetails;
      if (!id) {
        throw new Error(errorMessage);
      }

      let res;

      if (isOAuth) {
        res = await oauth.syncOAuthKey(id, isHardSync);
      } else {
        res = await keys.syncKey(id, isHardSync);
      }
      if (res.error) {
        syncSet.mutate({ scope: exchangeId, status: SyncStatusPlatform.Fail });
        throw new Error(errorMessage);
      }

      const message =
        apiDetails?.exchangeName === "binance"
          ? lang.sync.syncOneHour({ name })
          : lang.sync.syncSeveralMinutes({ name });

      uploadPending({
        name,
        id: exchangeId,
        message,
        showMessage,
      });

      return res.data;
    },
    onMutate: async ({ exchangeId, name, isOAuth, apiDetails }) => {
      const { previousData } = await optimisticallyUpdateKey({
        queryClient,
        viewModeKey,
        accountId: exchangeId,
        getUpdates: optimisticallyUpdateAccountPending(
          apiDetails.id,
          exchangeId,
          name,
          IntegrationCategory.CEX,
          isOAuth ? "oauths" : "keys",
        ),
      });

      return {
        previousData,
      };
    },
    onError(
      err,
      params,
      context:
        | {
            previousData?: AllSavedImports;
          }
        | undefined,
    ) {
      const previousData = context?.previousData;
      queryClient.setQueryData(viewModeKey, previousData);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
    },
  });
};

export const useDeleteKeyMutation = () => {
  const queryClient = useQueryClient();
  const errorMessage = useSelector(
    (state) => state.lang.map.keys.wentWrongDeletingAPI,
  );
  const user = useUser();
  const viewModeKey = useViewModeSyncKey();
  return useMutation({
    mutationFn: async ({
      id,
      isOAuth,
      savedImport,
    }: {
      id: string;
      isOAuth: boolean;
      savedImport: SavedImportByIntegration;
    }) => {
      const res = await (async () => {
        return importsV2.deleteImportByIdAndType({
          importType: isOAuth ? ImportType.OAuth : ImportType.API,
          importId: id,
        });
      })();

      await fireAnalyticsOnDelete(user, {
        label: savedImport?.id,
        name: savedImport?.name,
        importType: isOAuth ? ImportType.OAuth : ImportType.API,
      });
      if (res.error) {
        throw new Error(errorMessage);
      }
      return res.data;
    },
    onMutate: async ({ id, isOAuth, savedImport }) => {
      const getUpdates: GetUpdatesFunc = ({
        prevByAccount,
        prevByIntegration,
      }) => {
        const keyToUpdate = isOAuth ? "oauths" : "keys";
        const updatedByAccount: SavedImportOptionByAccount | undefined =
          prevByAccount
            ? {
                ...prevByAccount,
                [keyToUpdate]: prevByAccount[keyToUpdate].filter(
                  (k) => k.id !== id,
                ),
              }
            : undefined;
        const updatedByIntegration: SavedImportByIntegration | undefined =
          prevByIntegration
            ? {
                ...prevByIntegration,
                [keyToUpdate]: prevByIntegration[keyToUpdate].filter(
                  (k) => k.id !== id,
                ),
              }
            : undefined;

        return {
          newByAccount:
            updatedByAccount && accountHasImports(updatedByAccount)
              ? updatedByAccount
              : null,
          newByIntegration:
            updatedByIntegration && accountHasImports(updatedByIntegration)
              ? updatedByIntegration
              : null,
        };
      };

      const { previousData } = await optimisticallyUpdateKey({
        queryClient,
        viewModeKey,
        accountId: savedImport.id,
        getUpdates,
      });

      return { previousData };
    },
    onError: (
      err,
      action,
      context:
        | {
            previousData?: AllSavedImports;
          }
        | undefined,
    ) => {
      if (context?.previousData) {
        queryClient.setQueryData(viewModeKey, context.previousData);
      }
      queryErrorHandler(err);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
      queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

/**
 * Used to update the nickname of the API key
 */
export const useUpdateKeyMutation = (importOption: ImportOptionV2) => {
  const viewModeKey = useViewModeSyncKey();
  const queryClient = useQueryClient();
  const errorMessage = useSelector((state) =>
    state.lang.map.keys.wentWrongUpdatingAPI({
      name: importOption.name,
    }),
  );
  const syncKey = useSyncKeyMutation(importOption.name);
  const syncSet = useSetSyncMutation();

  return useMutation({
    mutationFn: async ({
      importOption,
      id,
      data,
    }: {
      importOption: ImportOptionV2;
      id: string;
      data: KeyUpdateDetails;
    }) => {
      const needsToSync =
        data.apiKey ||
        data.password ||
        data.secret ||
        data.uid ||
        data.tickers?.length;

      if (needsToSync) {
        syncSet.mutate({
          scope: importOption.id,
          status: SyncStatusPlatform.Pending,
        });
      }

      const res = await keys.updateKey(id, data);

      if (res.error) {
        throw new HttpError({ ...res, msg: res.msg || errorMessage }, [
          "updateKey",
        ]);
      }

      if (needsToSync) {
        syncKey.mutate({
          exchangeId: importOption.id,
          name: importOption.name,
          isOAuth: false,
          apiDetails: res.data,
          isHardSync: false,
        });
      }

      return res.data;
    },
    onMutate: async ({ importOption, id, data }) => {
      const getUpdates: GetUpdatesFunc = ({
        prevByAccount,
        prevByIntegration,
      }) => {
        const newByAccount: SavedImportOptionByAccount = {
          id: importOption.id,
          name: importOption.name,
          category: importOption.category,
          availableImportMethods: [],
          assets: [],
          value: 0,
          totalTxCount: undefined,
          spamTxCount: undefined,
          lastImportedTxTimestamp: null,
          lastSyncComplete: null,
          oauths: [],
          wallets: [],
          files: [],
          syncStatus: undefined,
          ...prevByAccount,
          keys: (prevByAccount?.keys || []).map((k) =>
            k.id === id
              ? {
                  ...k,
                  ...data,
                }
              : k,
          ),
        };

        const newByIntegration: SavedImportByIntegration = {
          id: importOption.id,
          name: importOption.name,
          category: importOption.category,
          files: [],
          oauths: [],
          wallets: [],
          ...prevByIntegration,
          keys: (prevByAccount?.keys || []).map((k) =>
            k.id === id
              ? {
                  ...k,
                  ...data,
                }
              : k,
          ),
        };

        return {
          newByAccount,
          newByIntegration,
        };
      };

      const { previousData } = await optimisticallyUpdateKey({
        queryClient,
        viewModeKey,
        accountId: importOption.id,
        getUpdates,
      });

      return {
        previousData,
      };
    },
    onError(
      err,
      params,
      context:
        | {
            previousData?: AllSavedImports;
          }
        | undefined,
    ) {
      const previousData = context?.previousData;
      queryClient.setQueryData(viewModeKey, previousData);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });
      queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};
