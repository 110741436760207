import { TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import upperFirst from "lodash/upperFirst";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { displayMessage } from "~/components/ui/Toaster";
import { useLang } from "~/redux/lang";
import * as invitationsAPI from "~/services/invitations";
import { clientKeys } from "~/state/clients";
import { invitationKeys } from "~/state/invitations";
import { DisplayMessage, InvitationType } from "~/types/enums";
import {
  type ClientDetails,
  type CreateInvitationPayload,
} from "~/types/index";

type AccountantInviteFormValues = Pick<
  CreateInvitationPayload,
  "fromName" | "toEmail"
>;

function getDefaultValues(): AccountantInviteFormValues {
  return {
    fromName: "",
    toEmail: "",
  };
}

type DialogProps = {
  open: boolean;
  handleClose: () => void;
};

export const NewInvitationDialog = ({
  open,
  handleClose,
  invitationType,
  clientProfile,
}: DialogProps & {
  invitationType: InvitationType.Accountant | InvitationType.Client;
  clientProfile?: ClientDetails["uuid"];
}) => {
  const lang = useLang();
  const queryClient = useQueryClient();
  const [pending, setPending] = useState(false);
  const { handleSubmit, control, reset, clearErrors } =
    useForm<AccountantInviteFormValues>({
      mode: "onBlur",
      defaultValues: getDefaultValues(),
    });

  async function onSubmit(formData: AccountantInviteFormValues) {
    setPending(true);
    const res = await invitationsAPI.createInvitation({
      ...formData,
      invitationType,
      clientProfile,
    });
    setPending(false);
    if (res.error) {
      displayMessage({
        type: DisplayMessage.Error,
        message: upperFirst(res.msg) || lang.errorMessageGeneric,
      });
    } else {
      void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
      void queryClient.invalidateQueries({ queryKey: clientKeys.all() });
      onClose();
    }
  }

  function onClose() {
    clearErrors();
    reset();
    handleClose();
  }
  const dialogLangOptions = {
    [InvitationType.Accountant]: {
      title: lang.accountant.invite.inviteAccountant,
      warning: lang.accountant.invite.inviteWarning,
      fromName: {
        label: lang.accountant.invite.fromName,
        placeholder: lang.accountant.invite.fromNamePlaceholder,
      },
      nameRequired: lang.accountant.invite.nameRequired,
      toEmail: {
        label: lang.accountant.invite.toEmail,
        placeholder: lang.accountant.invite.toEmailPlaceholder,
      },
      emailRequired: lang.accountant.invite.emailRequired,
    },
    [InvitationType.Client]: {
      title: lang.clients.invite.inviteClient,
      warning: lang.clients.invite.inviteWarning,
      fromName: {
        label: lang.clients.invite.fromName,
        placeholder: lang.clients.invite.fromNamePlaceholder,
      },
      nameRequired: lang.clients.invite.nameRequired,
      toEmail: {
        label: lang.clients.invite.toEmail,
        placeholder: lang.clients.invite.toEmailPlaceholder,
      },
      emailRequired: lang.clients.invite.emailRequired,
    },
  };

  const dialogLang = dialogLangOptions[invitationType];

  return (
    <GeneralDialog
      title={dialogLang.title}
      actionText={lang.accountant.invite.invite}
      isOpen={open}
      handleClose={onClose}
      handleAction={handleSubmit(onSubmit)}
      pending={pending}
    >
      <form>
        <Typography>{dialogLang.warning}</Typography>
        <Controller
          control={control}
          name="fromName"
          rules={{
            required: dialogLang.nameRequired,
            pattern: {
              value: /^[A-Za-zÀ-ÖØ-öø-ÿ'\- 0-9]+$/u,
              message: lang.accountant.invite.nameInvalid,
            },
          }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              type="text"
              label={dialogLang.fromName.label}
              placeholder={dialogLang.fromName.placeholder}
              InputLabelProps={{ required: true }}
              error={invalid}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="toEmail"
          rules={{ required: dialogLang.emailRequired }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              type="text"
              label={dialogLang.toEmail.label}
              placeholder={dialogLang.toEmail.placeholder}
              InputLabelProps={{ required: true }}
              error={invalid}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </form>
    </GeneralDialog>
  );
};
