import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { Confirmation } from "~/components/transactions/command-palette/views/ignore-warnings/Confirmation";
import { DisableVisible } from "~/components/ui/Icons";
import { useLang } from "~/redux/lang";
import { Warning } from "~/types/enums";

export const IgnoreWarnings = () => {
  const lang = useLang();
  const { push } = useNavController();
  const ignoreWarningsOptionsLang =
    lang.txTable.commandPallet.ignoreWarningsOptions;

  // Todo: filter options based on warnings on selected actions
  const options = [
    {
      type: Warning.All,
      label: ignoreWarningsOptionsLang[Warning.All],
      icon: <DisableVisible />,
    },
    {
      type: Warning.Uncategorised,
      label: ignoreWarningsOptionsLang[Warning.Uncategorised],
      icon: <DisableVisible />,
    },
    {
      type: Warning.MissingPrice,
      label: ignoreWarningsOptionsLang[Warning.MissingPrice],
      icon: <DisableVisible />,
    },
    {
      type: Warning.NegativeBalance,
      label: ignoreWarningsOptionsLang[Warning.NegativeBalance],
      icon: <DisableVisible />,
    },
    {
      type: Warning.MissingBlockchain,
      label: ignoreWarningsOptionsLang[Warning.MissingBlockchain],
      icon: <DisableVisible />,
    },
    {
      type: Warning.UnmatchedTransfer,
      label: ignoreWarningsOptionsLang[Warning.UnmatchedTransfer],
      icon: <DisableVisible />,
    },
  ];

  return (
    <CommandPaletteMenu
      topArea={<BulkEditOptionChip type={BulkEditOptions.IgnoreWarnings} />}
      options={options}
      placeholder={lang.txTable.commandPallet.placeholders.ignoreWarning}
      onSelection={({ type, label }) => {
        push(<Confirmation type={type} label={label} options={options} />);
      }}
    />
  );
};
