import { useQuery } from "@tanstack/react-query";

import { getFYOptions } from "~/services/report";

export const fyDatesKeys = {
  all: () => ["fy-dates"] as const,
};

/**
 * Gets all the possible financial years for this user
 */
export function useGetFinancialYearOptions() {
  return useQuery({
    queryKey: fyDatesKeys.all(),
    queryFn: async () => {
      const res = await getFYOptions();

      if (res.error) return [];

      return res.data.map(({ year, start, end }) => ({
        year,
        start: new Date(start),
        end: new Date(end),
      }));
    },
    placeholderData: [],
  });
}
