export enum Hotkeys {
  Escape = "escape",
  Enter = "enter",
  CMD_K = "CommandK",
  C = "C",
  R = "R",
}
export enum BulkEditOptions {
  Categorize = "categorize",
  ChangeCurrency = "changeCurrency",
  MarkAsReviewed = "markAsReviewed",
  MarkAsNotReviewed = "markAsNotReviewed",
  ChangeFeeCurrency = "changeFeeCurrency",
  AddCommentToTransactions = "addCommentToTransactions",
  LookUpMarketPrice = "lookUpMarketPrice",
  MarkAsSpam = "markAsSpam",
  IgnoreWarnings = "ignoreWarnings",
  SourceAccount = "sourceAccount",
  DestinationAccount = "destinationAccount",
  GroupingWizard = "groupingWizard",
  Ignore = "ignore",
  Delete = "delete",
  ChangeAssetAccount = "changeAssetAccount",
  ChangePnLAccount = "changePnLAccount",
  ChangeGainsAccount = "changeGainsAccount",
  ChangeLoanAccount = "changeLoanAccount",
  ChangeCashAccount = "changeCashAccount",
  SyncToErp = "syncToErp",
  ChangePrice = "changePrice",
  TxRewind = "txRewind",
  Ungroup = "ungroup",
  AllowGrouping = "allowGrouping",
}
