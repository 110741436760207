import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress, useMediaQuery } from "@mui/material";
import capitalize from "lodash/capitalize";
import { useContext } from "react";
import styled from "styled-components/macro";

import { navButtonAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { isDemoAccount } from "~/components/clients/helpers/index";
import { SmallCommandPaletteDropdown } from "~/components/nav/SmallCommandPaletteDropdown";
import { SmallCommandPaletteMenu } from "~/components/transactions/command-palette/SmallCommandPaletteMenu";
import { CensoredTypography } from "~/components/ui/CensoredComponents";
import { TertiaryIconButton } from "~/components/ui/ui-buttons/icon-buttons/TertiaryIconButton";
import {
  baseTertiaryButtonColorStyles,
  TertiaryButton,
} from "~/components/ui/ui-buttons/TertiaryButton";
import { FYContext } from "~/contexts/FYContext";
import { useActiveClient, useSetActiveClient } from "~/hooks/useActiveClient";
import { useDesign } from "~/hooks/useTheme";
import { useIsManagingClients, useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useClientList } from "~/state/clients";

function getFirstInitials(name: string, numInitials = 3) {
  return name
    .split(" ")
    .map((word) => word[0])
    .slice(0, numInitials)
    .join("")
    .toUpperCase();
}

export function ActiveClient({ collapsed }: { collapsed: boolean }) {
  const { tokens } = useDesign();
  const user = useUser();
  const { isLoading: isLoadingClientList, data: _clientList = [] } =
    useClientList();
  const client = useActiveClient();
  const isManagingClients = useIsManagingClients();
  const lang = useLang();
  const isNarrow = useMediaQuery("(min-width: 1350px)");

  const clientList = _clientList
    .filter((client) => !isDemoAccount(client))
    .sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));

  const captureAnalytics = useCaptureAnalytics();
  const setActiveClient = useSetActiveClient();
  const activeClient = useActiveClient();
  const fyContext = useContext(FYContext);

  if (!user || !isManagingClients) return null;

  const buttonText = isLoadingClientList
    ? lang.accountant.loadingClients
    : client
      ? capitalize(client.name)
      : lang.accountant.noClientSelected;

  return (
    <SmallCommandPaletteDropdown
      popupId="client-switcher"
      palette={(close) => (
        <SmallCommandPaletteMenu
          options={clientList.map((client) => ({
            label: capitalize(client.name),
            client,
          }))}
          selectedOptionLabel={client ? capitalize(client.name) : undefined}
          placeholder={lang.navbar.selectClient}
          onSelection={({ client }) => {
            const { uuid } = client;
            if (activeClient?.uuid === uuid) {
              return;
            }
            setActiveClient(uuid);
            fyContext.refetch();
            close();
          }}
        />
      )}
      button={(props) =>
        collapsed ? (
          <StyledIconButton
            {...props}
            size="small"
            onClick={(e) => {
              captureAnalytics(navButtonAnalyticsKey, {
                button_name: "clientsDropdown",
              });
              props.onClick(e);
            }}
            sx={{ px: "0", width: "2.25rem" }}
          >
            {isLoadingClientList ? (
              <CircularProgress
                size={16}
                sx={{ color: tokens.text.disabled }}
              />
            ) : (
              <CensoredTypography variant="Metropolis/Body/Regular">
                {getFirstInitials(buttonText)}
              </CensoredTypography>
            )}
          </StyledIconButton>
        ) : (
          <TertiaryButton
            {...props}
            onClick={(e) => {
              captureAnalytics(navButtonAnalyticsKey, {
                button_name: "clientsDropdown",
              });
              props.onClick(e);
            }}
            fullWidth
            css={`
              display: flex;
              justify-content: space-between;
            `}
          >
            <CensoredTypography
              variant="Metropolis/Body/Regular"
              textAlign="left"
              sx={{ minWidth: "8rem" }}
            >
              {buttonText}
            </CensoredTypography>
            {!collapsed ? <ExpandMoreIcon /> : null}
          </TertiaryButton>
        )
      }
    />
  );
}

const StyledIconButton = styled(TertiaryIconButton)`
  ${baseTertiaryButtonColorStyles}
  && {
    width: 100%;
  }
`;
