import { Check } from "@mui/icons-material";
import { Box, Chip, Fade, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useIsMobile } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useGetSavedAccounts } from "~/state/importsV2";

const FADE_IN_ANIMATION_MS = 500;

export function ProgressAutoSavedBadge() {
  const isMobile = useIsMobile();
  const { tokens } = useDesign();
  const lang = useLang();
  const location = useLocation();
  const [showBadge, setShowBadge] = useState<boolean>(false);
  const savedAccounts = useGetSavedAccounts();
  const badgeText = lang.onboardingV2.calculating.autoSaved;

  useEffect(() => {
    // At the moment, we only show the auto-saved badge on any onboarding
    // imports page (if they've imported at least one account)
    setShowBadge(
      location.pathname.includes("/onboarding/imports") &&
        savedAccounts.length > 0,
    );
  }, [location, savedAccounts]);

  return (
    <Fade in={showBadge} timeout={FADE_IN_ANIMATION_MS}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {isMobile ? (
          <Tooltip title={badgeText}>
            <Box
              sx={{
                height: "1.5rem",
                width: "2.5rem",
                backgroundColor: tokens.background.accent.neutral.medium,
                borderRadius: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Check sx={{ color: tokens.text.high, fontSize: "1.125rem" }} />
            </Box>
          </Tooltip>
        ) : (
          <Chip
            icon={<Check />}
            size="small"
            label={badgeText}
            sx={{
              color: tokens.text.high,
              backgroundColor: tokens.background.accent.neutral.medium,
            }}
          />
        )}
      </Box>
    </Fade>
  );
}
