import { Help } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import {
  bindPopper,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useRef } from "react";

import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { getUserCountryDateFormat } from "~/lib/date";
import { useCountry } from "~/redux/auth";
import { useLang } from "~/redux/lang";

export const ImportFromDateOverride = ({
  value,
  setValue,
}: {
  /** The currently selected date */
  value: Date | null;
  /** Function to update the selected date */
  setValue: (value: Date | null) => void;
}) => {
  const lang = useLang();
  const country = useCountry();
  const containerRef = useRef();
  const dateFormat = getUserCountryDateFormat(country);

  const handleChange = (date: Date | null) => {
    setValue(date);
  };

  const popupState = usePopupState({
    variant: "popper",
    popupId: "tickerInfo",
  });

  return (
    <>
      <Typography
        variant="Metropolis/Body/Light"
        style={{ marginRight: "0.125rem" }}
        display="inline"
      >
        {lang.manualCSV.manualSelectImportFromDate}
      </Typography>
      <ClickAwayListener
        onClickAway={() => {
          popupState.close();
        }}
      >
        <Box component="span">
          <TextIconButton
            size="small"
            {...bindTrigger(popupState)}
            color={popupState.isOpen ? "primary" : "default"}
          >
            <Help fontSize="inherit" />
          </TextIconButton>
          <Popper
            {...bindPopper(popupState)}
            container={containerRef.current}
            placement="right-end"
            transition
            style={{ zIndex: 2000 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={250}>
                <Paper>
                  <Box p="1rem" maxWidth="20rem">
                    <Typography variant="Metropolis/Body/Light">
                      {lang.manualCSV.importFromDateHelper}
                    </Typography>
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
      <Box mt="0.5rem">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={value}
            onChange={(date) => {
              handleChange(date);
            }}
            inputFormat={dateFormat}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};
