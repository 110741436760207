import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  type AutocompleteChangeReason,
  type FilterOptionsState,
} from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { type ReactNode, type SyntheticEvent } from "react";
import * as React from "react";

import { FilterFooter } from "~/components/filter/filterFooter";
import { type FilterProps } from "~/components/filter/FilterProps";
import { type Option } from "~/components/filter/types";
import {
  ListboxComponent,
  renderGroup,
} from "~/components/transactions/AutocompleteListboxComponent";
import { Checkbox } from "~/components/ui/Checkbox";
import { StyledTooltip } from "~/components/ui/StyledTooltip";
import { useLang } from "~/redux/lang";

export interface FilterMultiSelectOptionProps {
  inline?: boolean;
  search?: boolean;
  disableClearable?: boolean;
}

interface FilterMultiSelectRowProps<T = any>
  extends FilterProps<string[]>,
    FilterMultiSelectOptionProps {
  selectionOptions: Option<T>[];
  selectionDisabled?: string[];
  filterOptions?: (
    options: Option<T>[],
    state: FilterOptionsState<Option<T>>,
  ) => Option<T>[];
  groupBy?: (option: Option<T>) => string;
  sorted?: boolean;
  renderOptionContent?: (option: Option<T>) => ReactNode;
  renderTag?: (option: Option<T>, tagProps: any) => ReactNode;
  limitTags?: number;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useInlineAutocompleteStyles = makeStyles({
  popperDisablePortal: {
    position: "static",
  },
  paper: {
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
    margin: -10,
    marginTop: 0,
    marginBottom: 10,
  },
  root: {
    margin: 10,
  },
  inputRoot: {},
  option: {},
});

export const FilterMultiSelect: React.FC<FilterMultiSelectRowProps> = ({
  name,
  selection,
  selectionDisabled = [],
  setSelection,
  selectionOptions,
  filterOptions,
  renderOptionContent,
  renderTag,
  inline,
  groupBy,
  sorted,
  search = !inline,
  disableClearable,
  limitTags,
  applyFilters,
  handleClose,
}) => {
  const lang = useLang();
  const inlineAutocompleteStyles = useInlineAutocompleteStyles();
  const ListboxComponentCallback = React.useCallback(
    (props: React.ComponentProps<typeof ListboxComponent>) => {
      return <ListboxComponent {...props} itemSize={40} overscanCount={1} />;
    },
    [],
  );
  const [inputValue, setInputValue] = React.useState("");
  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: Option<any>[],
    reason: AutocompleteChangeReason,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (reason === "clear") {
      setSelection([...selectionDisabled]);
    } else {
      setSelection([...newValue.map((option) => option.value)]);
      setInputValue("");
    }
  };

  const value: Option[] | undefined = selection?.map((v) => ({
    value: v,
    label: selectionOptions.find((option) => option.value === v)?.label || "",
  }));

  const clear = () => {
    setSelection([]);
  };

  // sort the selected options alphabetically
  let sortedSelectionOptions = selectionOptions;
  if (!sorted) {
    sortedSelectionOptions = selectionOptions.sort((a, b) =>
      a.label && b.label ? a.label.localeCompare(b.label) : 1,
    );
  }
  if (inline && !search) {
    // return list implementation

    return (
      <Grid container justifyContent="center">
        <List style={{ width: "100%" }}>
          {sortedSelectionOptions.map((option) => {
            const { value, label } = option;
            const disabled =
              selectionDisabled.includes(value) && !selection?.includes(value);
            return (
              <ListItem
                key={label}
                selected={selection?.includes(value)}
                disabled={disabled}
                onClick={
                  disabled
                    ? undefined
                    : () => {
                        setSelection(
                          selection?.includes(value)
                            ? selection.filter((x) => x !== value)
                            : [...(selection || []), value],
                        );
                      }
                }
                style={disabled ? { pointerEvents: "none" } : {}}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selection?.includes(value) || false}
                  size="small"
                />
                {renderOptionContent ? (
                  renderOptionContent(option)
                ) : (
                  <Box ml="1rem">
                    <Typography variant="Metropolis/Body/Light">{option.label}</Typography>
                  </Box>
                )}
              </ListItem>
            );
          })}
        </List>
        {applyFilters && (
          <FilterFooter
            onApply={applyFilters}
            onReset={clear}
            onClose={() => {
              if (handleClose) handleClose();
            }}
            selection={selection}
          />
        )}
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Autocomplete
        fullWidth
        multiple
        disablePortal={inline}
        open={inline ? true : undefined}
        groupBy={groupBy}
        popupIcon={inline ? null : undefined}
        classes={inline ? inlineAutocompleteStyles : {}}
        size="small"
        limitTags={inline ? limitTags : 2}
        options={sortedSelectionOptions}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        value={value || []}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionDisabled={(option) => selectionDisabled.includes(option.value)}
        filterOptions={filterOptions}
        disableClearable={disableClearable}
        noOptionsText={lang.txTable.filter.noOptions}
        inputValue={inputValue}
        onInputChange={(event, value, reason) => {
          if (event && event.type === "blur") {
            setInputValue("");
          } else if (reason !== "reset") {
            // "reset" is triggered by a "programmatic change" aka
            // when a user clicks one of the options
            setInputValue(value);
          }
        }}
        renderOption={(props, option, { selected }) => (
          <StyledTooltip
            title={option.data?.name || option.label}
            placement="top"
            position="top"
          >
            <Box component="li" {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
                size="small"
              />
              {renderOptionContent ? (
                renderOptionContent(option)
              ) : (
                <Box ml="1rem">
                  <Typography variant="Metropolis/Body/Light">{option.label}</Typography>
                </Box>
              )}
            </Box>
          </StyledTooltip>
        )}
        ListboxComponent={ListboxComponentCallback}
        renderGroup={renderGroup}
        renderTags={
          renderTag
            ? (value: Option[], getTagProps) =>
                value
                  .map((v) => selectionOptions.find((o) => v.value === o.value))
                  .filter(Boolean)
                  .map((o, index) =>
                    renderTag(o as Option, getTagProps({ index })),
                  )
            : undefined
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={name}
            fullWidth
          />
        )}
      />
    </Grid>
  );
};
