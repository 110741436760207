import { Close } from "@mui/icons-material";
import { Box, type Breakpoint, DialogContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { AnalyticEvent } from "~/analytics/analyticsProperties";
import { useCaptureAnalyticEvents } from "~/analytics/captureAnalyticEvents";
import { calculateUsDeadlineTimeRemaining } from "~/components/dashboard/embedded-dashboard/CountdownBanner";
import { type TimeRemaining } from "~/components/dashboard/embedded-dashboard/types";
import { GeneralDialogModal } from "~/components/ui/GeneralDialog";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";

export function IrsPaywallModalBase({
  open,
  onClose,
  variant,
  showCountdown = false,
  children,
  maxWidth = "sm",
}: {
  open: boolean;
  onClose: () => void;
  /**
   * The variant of the modal, for analytics purposes
   */
  variant: string;
  /**
   * Whether the end of tax time countdown is being shown
   */
  showCountdown?: boolean;
  /**
   * The modal contents
   */
  children: React.ReactNode;
  /**
   * The max width of the modal
   */
  maxWidth?: Breakpoint;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  const timezone = useTimezone();
  const captureAnalytics = useCaptureAnalyticEvents();

  const formatTimeRemaining = useFormatTimeRemaining();

  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(
    calculateUsDeadlineTimeRemaining(timezone),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateUsDeadlineTimeRemaining(timezone));
    }, 60000); // Update every minute

    return () => {
      clearInterval(timer);
    };
  }, [timezone]);

  const handleClose = () => {
    onClose();
    captureAnalytics(AnalyticEvent.PAYWALL_MODAL.CLOSED, {
      showTaxSeasonCountdown: showCountdown,
      variant,
    });
  };

  return (
    <GeneralDialogModal
      isOpen={open}
      handleClose={handleClose}
      fullWidth
      autoFullScreen
      maxWidth={maxWidth}
      title=""
    >
      {showCountdown ? (
        <DialogContent sx={{ padding: 0, width: "100%", height: "100%" }}>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={`1px solid ${tokens.border.neutral.default}`}
            bgcolor={tokens.elevation.high}
            px="0.5rem"
            py="0.125rem"
          >
            <Box />
            <Box
              display="flex"
              gap="0.25rem"
              flexWrap="wrap"
              justifyContent="center"
            >
              <Typography
                variant="Metropolis/Body/Regular"
                color={tokens.text.high}
              >
                {lang.paywallModal.irsModal.irsTaxSubmissionDeadline}
              </Typography>
              <Typography
                variant="Metropolis/Body/Regular"
                color={tokens.text.warning}
              >
                {formatTimeRemaining(timeRemaining)}
              </Typography>
            </Box>
            <CloseButton onClose={handleClose} />
          </Box>
          <Box width="100%" p="1rem" height="calc(100% - 3rem)">
            {children}
          </Box>
        </DialogContent>
      ) : (
        <DialogContent sx={{ padding: "1rem", width: "100%", height: "100%" }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            height="0rem"
            width="100%"
          >
            <Box>
              <CloseButton onClose={handleClose} />
            </Box>
          </Box>
          <Box width="100%" height="100%">
            {children}
          </Box>
        </DialogContent>
      )}
    </GeneralDialogModal>
  );
}

function CloseButton({ onClose }: { onClose: () => void }) {
  return (
    <TextIconButton aria-label="close" onClick={onClose} size="small">
      <Close sx={{ fontSize: "1rem" }} />
    </TextIconButton>
  );
}

function useFormatTimeRemaining() {
  const lang = useLang();

  return (timeRemaining: TimeRemaining) => {
    const { days, hours, minutes } = timeRemaining;

    // Pad numbers with leading zeros to ensure 2 digits
    const paddedDays = days.toString().padStart(2, "0");
    const paddedHours = hours.toString().padStart(2, "0");
    const paddedMinutes = minutes.toString().padStart(2, "0");

    return lang.paywallModal.irsModal.dateString({
      days: paddedDays,
      hours: paddedHours,
      minutes: paddedMinutes,
    });
  };
}
