export enum LoadUserType {
  Signup = "signup",
  Login = "login",
  UserUpdate = "userUpdate",
}

export enum CollaborationSyncEvent {
  InviteAccepted = "inviteAccepted",
  InviteRevoked = "inviteRevoked",
  InviteDeclined = "inviteDeclined",
  InvitePending = "invitePending",
  DataSourceChanged = "dataSourceChanged",
  PlanUpgraded = "planUpgraded",
}
