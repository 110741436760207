import {
  type Blockchain,
  type LocalCurrency,
  type SupportedLang,
} from "@ctc/types";
import { Trade } from "@ctc/types";
import { ArrowForward } from "@mui/icons-material";
import RestoreIcon from "@mui/icons-material/Restore";
import { Box, Typography } from "@mui/material";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import moment from "moment-timezone";
import type * as React from "react";
import { useState } from "react";
import styled, { css } from "styled-components/macro";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureActionAnalytics } from "~/analytics/posthog";
import { formatDisplayAddress } from "~/components/transactions/helpers";
import { BlockchainLogo } from "~/components/ui/BlockchainLogo";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { type Tokens } from "~/components/ui/theme/tokens";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { useDesign } from "~/hooks/useTheme";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { displayFiatValue } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useIsAddressLike } from "~/redux/imports";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { type ErpAccount } from "~/redux/types";
import { formatFunctionName } from "~/services/transactions";
import { useAtomicUpdateTransactions } from "~/state/actions";
import { getEntityExchangeLogoName, useEntityLookup } from "~/state/entities";
import { useErpAvailableAccountsQuery } from "~/state/erp";
import { ERPAccountType, UpdateReason } from "~/types/enums";
import {
  type ActionPreviousVersion,
  type ActionRow,
  BlockchainName,
  type Entity,
  type TransactionDetails,
} from "~/types/index";

export const historyGridCssBase = css`
  display: grid;
  grid-template-columns: 10rem 10rem 1fr 10rem;
  row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-areas: "date actor transaction actions";
`;

export const HistoryItemRow = styled.div`
  ${historyGridCssBase}

  padding-left: 0.5rem;
  padding-right: 0.5rem;

  * + & {
    padding-top: 1rem;
    border-top: ${(props) =>
      `1px solid ${props.theme.tokens.border.neutral.default}`};
  }
  padding-bottom: 1rem;
`;

/**
 * Show User for user, import for import, and everything else just show system
 * @returns
 */
function HistoryActor({ historyItem }: { historyItem: ActionPreviousVersion }) {
  const { tokens } = useDesign();
  const lang = useLang();

  const actorText = historyItem.updateReason
    ? [UpdateReason.Import, UpdateReason.User].includes(
        historyItem.updateReason,
      )
      ? lang.txTable.expansionPanel.history.updateReason[
          historyItem.updateReason
        ]
      : lang.txTable.expansionPanel.history.updateReason[UpdateReason.System]
    : lang.txTable.expansionPanel.history.updateReason.unknown;

  return (
    <Typography variant="Metropolis/Body/Regular" color={tokens.text.default}>
      {actorText}
      {historyItem.original
        ? ` ${lang.txTable.expansionPanel.history.imported}`
        : ""}
    </Typography>
  );
}

export function HistoryItem({
  historyItems,
  index,
  historyItem,
  row,
  isFirstOriginal,
  isUndo,
}: {
  historyItems: ActionPreviousVersion[];
  index: number;
  historyItem: ActionPreviousVersion;
  row: ActionRow;
  isFirstOriginal: boolean;
  isUndo: boolean;
}) {
  const { tokens } = useDesign();
  const rowDate = historyItem.createdAt ?? historyItem.updatedAt;
  const lang = useLang();
  const [isConfirming, setIsConfirming] = useState(false);

  return (
    <>
      {isUndo ? (
        <UndoDialog
          historyItems={historyItems}
          historyItem={historyItem}
          row={row}
          isConfirming={isConfirming}
          setIsConfirming={setIsConfirming}
        />
      ) : (
        <RestoreDialog
          historyItems={historyItems}
          historyItem={historyItem}
          row={row}
          isConfirming={isConfirming}
          setIsConfirming={setIsConfirming}
        />
      )}
      <HistoryItemRow>
        <Box gridArea="date">
          <Typography
            variant="Metropolis/Body/Regular"
            color={tokens.text.default}
            css={`
              & {
                text-transform: lowercase;
              }
              &:first-letter {
                text-transform: uppercase;
              }
            `}
          >
            {rowDate ? moment(rowDate).fromNow() : null}
          </Typography>
          <Typography
            variant="Metropolis/Caption/Medium/Regular"
            color={tokens.text.low}
          >
            {rowDate ? moment(rowDate).format("D MMM YYYY H:mm") : null}
          </Typography>
        </Box>
        <Box gridArea="actor">
          <HistoryActor historyItem={historyItem} />
        </Box>

        {!historyItem.original ? (
          <Box gridArea="transaction">
            {historyItem.transactions.map((toTx) => {
              const fromTx = historyItems.reduce<
                TransactionDetails | undefined
              >((acc, historyItem, historyIndex) => {
                if (acc) {
                  return acc;
                }
                if (historyIndex <= index) {
                  return acc;
                }
                return historyItem.transactions.find(
                  (possibleFromTx) => possibleFromTx._id === toTx._id,
                );
              }, undefined);

              if (!fromTx) {
                return null;
              }
              const changedKeys = getChangedKeys(toTx, fromTx);
              if (!changedKeys.length) {
                return null;
              }
              return (
                <TransactionDiff key={toTx._id} toTx={toTx} fromTx={fromTx} />
              );
            })}
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1 }} gridArea="transaction">
            {historyItem.transactions.map((tx) => (
              <OriginalTransaction key={tx._id} originalTx={tx} />
            ))}
          </Box>
        )}
        <Box gridArea="actions">
          <TextButton
            startIcon={<RestoreIcon />}
            size="small"
            onClick={() => {
              setIsConfirming(true);
            }}
            disabled={row.isLocked || (isFirstOriginal && historyItem.original)}
          >
            {isFirstOriginal && historyItem.original
              ? lang.txTable.expansionPanel.history.currentVersion
              : isUndo
                ? lang.txTable.expansionPanel.history.undo
                : lang.txTable.expansionPanel.history.restoreOnly}
          </TextButton>
        </Box>
      </HistoryItemRow>
    </>
  );
}

function UndoDialog({
  historyItems,
  historyItem,
  row,
  isConfirming,
  setIsConfirming,
}: {
  historyItems: ActionPreviousVersion[];
  historyItem: ActionPreviousVersion;
  row: ActionRow;
  isConfirming: boolean;
  setIsConfirming: (isConfirming: boolean) => void;
}) {
  const actionId = row._id;
  const updateRowTransactionMutation = useAtomicUpdateTransactions();

  const lang = useLang();

  const captureActionAnalytics = useCaptureActionAnalytics();
  const analyticsKey = transactionAnalyticsKey("history");

  const fromTxMap = new Map<string, TransactionDetails>();
  const changedKeysMap = new Map<string, ReturnType<typeof getChangedKeys>>();

  // Remove the current item from the list of history items. The undo dialog is
  // only available for the first item so just slice it
  const filteredHistoryItems = historyItems.slice(1);

  const targetTxs: TransactionDetails[] = [];
  // For each transaction in the current history item, find the newest version
  // in the filtered history items and add it to the targetTxs array
  historyItem.transactions.forEach((tx) => {
    const latestTxHi = filteredHistoryItems.find((hi) =>
      hi.transactions.find((tx1) => tx1._id === tx._id),
    );
    const latestTx = latestTxHi?.transactions.find((tx1) => tx1._id === tx._id);
    if (latestTx) {
      targetTxs.push(latestTx);
    }
  });

  const filteredTxs = targetTxs.filter((tx) => {
    const fromTx = historyItem.transactions.find((tx1) => tx1._id === tx._id);
    if (!fromTx) {
      return false;
    }

    const changedKeys = getChangedKeys(tx, fromTx);
    if (!changedKeys.length) {
      return false;
    }

    fromTxMap.set(tx._id, fromTx);
    changedKeysMap.set(tx._id, changedKeys);
    return true;
  });
  return (
    <GeneralDialog
      pending={updateRowTransactionMutation.isPending}
      isOpen={isConfirming}
      handleAction={() => {
        captureActionAnalytics(analyticsKey("restored"), row);
        updateRowTransactionMutation.mutate(
          {
            actionId,
            update: {
              updateTx: {
                payload: targetTxs,
                applySts: false,
                createCategoryRule: false,
              },
            },
          },
          {
            onSuccess() {
              setIsConfirming(false);
            },
          },
        );
      }}
      handleClose={() => {
        setIsConfirming(false);
      }}
      title={lang.txTable.expansionPanel.history.undoLatestChange}
      maxWidth="md"
      actionText={lang.accept}
      cancelText={lang.cancel}
      stopPropagation
      closeOnClickAway
    >
      <Box minWidth="18.75rem">
        {lang.txTable.expansionPanel.history.thisWillChange}
        <Box ml="1rem">
          {filteredTxs.length ? (
            filteredTxs.map((tx) => {
              const fromTx = fromTxMap.get(tx._id);
              const changedKeys = changedKeysMap.get(tx._id);
              if (!fromTx || !changedKeys) {
                return (
                  <ul>
                    <li>
                      {
                        lang.txTable.expansionPanel.history
                          .noChangesFromCurrentDetected
                      }
                    </li>
                  </ul>
                );
              }
              return <TransactionDiff key={tx._id} toTx={tx} fromTx={fromTx} />;
            })
          ) : (
            <ul>
              <li>
                {
                  lang.txTable.expansionPanel.history
                    .noChangesFromCurrentDetected
                }
              </li>
            </ul>
          )}
        </Box>
      </Box>
    </GeneralDialog>
  );
}

function RestoreDialog({
  historyItems,
  historyItem,
  row,
  isConfirming,
  setIsConfirming,
}: {
  historyItems: ActionPreviousVersion[];
  historyItem: ActionPreviousVersion;
  row: ActionRow;
  isConfirming: boolean;
  setIsConfirming: (isConfirming: boolean) => void;
}) {
  const actionId = row._id;
  const updateRowTransactionMutation = useAtomicUpdateTransactions();

  const lang = useLang();
  const restoreToTime = historyItem.updatedAt
    ? moment(historyItem.updatedAt).format("DD MMM YY HH:mm:ss")
    : lang.txTable.expansionPanel.history.thisVersion;

  const captureActionAnalytics = useCaptureActionAnalytics();
  const analyticsKey = transactionAnalyticsKey("history");

  const fromTxMap = new Map<string, TransactionDetails>();
  const changedKeysMap = new Map<string, ReturnType<typeof getChangedKeys>>();

  const filteredTxs = historyItem.transactions.filter((tx) => {
    const fromTx = historyItems[0].transactions.find(
      (tx1) => tx1._id === tx._id,
    );
    if (!fromTx) {
      return false;
    }

    const changedKeys = getChangedKeys(tx, fromTx);
    if (!changedKeys.length) {
      return false;
    }

    fromTxMap.set(tx._id, fromTx);
    changedKeysMap.set(tx._id, changedKeys);
    return true;
  });

  return (
    <GeneralDialog
      pending={updateRowTransactionMutation.isPending}
      isOpen={isConfirming}
      handleAction={() => {
        captureActionAnalytics(analyticsKey("restored"), row);
        updateRowTransactionMutation.mutate(
          {
            actionId,
            update: {
              updateTx: {
                payload: historyItem.transactions,
                applySts: false,
                createCategoryRule: false,
              },
            },
          },
          {
            onSuccess() {
              setIsConfirming(false);
            },
          },
        );
      }}
      handleClose={() => {
        setIsConfirming(false);
      }}
      title={lang.txTable.expansionPanel.history.modalTitle({
        timestamp: restoreToTime,
      })}
      maxWidth="md"
      actionText={lang.accept}
      cancelText={lang.cancel}
      stopPropagation
      closeOnClickAway
    >
      <Box minWidth="18.75rem">
        {lang.txTable.expansionPanel.history.thisWillChange}
        <Box ml="1rem">
          {filteredTxs.length ? (
            filteredTxs.map((tx) => {
              const fromTx = fromTxMap.get(tx._id);
              const changedKeys = changedKeysMap.get(tx._id);
              if (!fromTx || !changedKeys) {
                return (
                  <ul>
                    <li>
                      {
                        lang.txTable.expansionPanel.history
                          .noChangesFromCurrentDetected
                      }
                    </li>
                  </ul>
                );
              }
              return <TransactionDiff key={tx._id} toTx={tx} fromTx={fromTx} />;
            })
          ) : (
            <ul>
              <li>
                {
                  lang.txTable.expansionPanel.history
                    .noChangesFromCurrentDetected
                }
              </li>
            </ul>
          )}
        </Box>
      </Box>
    </GeneralDialog>
  );
}

function getOnImportKeys(originalTx: TransactionDetails) {
  const trackedKeys = [
    "trade",
    "timestamp",
    "currencyIdentifier",
    "from",
    "to",
    "quantity",
    "price",
    "functionName",
    "blockchain",
  ] as const;

  return trackedKeys.filter((key) => {
    if (originalTx.trade === Trade.Fee && key === "to") {
      return false;
    }

    return !isNil(originalTx[key]);
  });
}

function getChangedKeys(fromTx: TransactionDetails, toTx: TransactionDetails) {
  const assetPath = `erp.accountMappings.${ERPAccountType.Asset}` as const;
  const pnlPath = `erp.accountMappings.${ERPAccountType.PL}` as const;
  const gainPath = `erp.accountMappings.${ERPAccountType.Gain}` as const;
  const trackedKeys = [
    "timestamp",
    "currencyIdentifier",
    "trade",
    "from",
    "to",
    "quantity",
    "price",
    "fee",
    "feeQuantity",
    "feeCurrencyIdentifier",
    "functionName",
    "blockchain",
    "dontGroup",
    "reviewed",
    assetPath,
    pnlPath,
    gainPath,
  ] as const;

  return trackedKeys.filter((key) => {
    if (key === "currencyIdentifier" || key === "feeCurrencyIdentifier") {
      return fromTx[key]?.id !== toTx[key]?.id;
    }
    const fromValue = get(fromTx, key);
    const toValue = get(toTx, key);
    // not defined before or after, so ignore it
    if (!fromValue && !toValue) {
      return false;
    }
    return !isEqual(fromValue, toValue);
  });
}

function OriginalTransaction({
  originalTx,
}: {
  originalTx: TransactionDetails;
}) {
  const trackedKeys = getOnImportKeys(originalTx);

  return (
    <Box display="flex" flexDirection="column" gap="0.25rem" mb="0.25rem">
      {trackedKeys.map((key) => (
        <KeyChangeRow
          key={key}
          txKey={key}
          fromTx={undefined}
          toTx={originalTx}
        />
      ))}
    </Box>
  );
}

function TransactionDiff({
  toTx,
  fromTx,
}: {
  toTx: TransactionDetails;
  fromTx: TransactionDetails | undefined;
}) {
  const lang = useLang();
  const { tokens } = useDesign();

  if (!fromTx) {
    return null;
  }
  const Icon = TradeIcons[fromTx.trade];
  const changedKeys = getChangedKeys(fromTx, toTx);

  return (
    <Box display="flex" flexDirection="column" gap="0.25rem" mb="0.25rem">
      <Box display="flex">
        <HistoryText variant="Metropolis/Body/Regular" type="title">
          <Box display="flex" alignItems="center" gap="0.25rem">
            <Icon style={{ fontSize: "1rem", color: tokens.text.low }} />
            <CurrencyLogo
              currency={fromTx.currencyIdentifier}
              width={16}
              height={16}
              margin="0"
            />
            {getActionTypeName({
              actionType: fromTx.trade,
              lang,
            })}
            :
          </Box>
        </HistoryText>
      </Box>
      <Box display="flex" flexDirection="column" gap="0.25rem" ml="1rem">
        {changedKeys.map((key) => (
          <KeyChangeRow key={key} txKey={key} fromTx={fromTx} toTx={toTx} />
        ))}
      </Box>
    </Box>
  );
}

function useAddressFromToTxInfo({
  fromTx,
  toTx,
}: {
  fromTx: TransactionDetails | undefined;
  toTx: TransactionDetails;
}) {
  const fromTxBlockchain = fromTx?.blockchain;
  const toTxBlockchain = toTx.blockchain;

  const fromTxFromEntity = useEntityLookup(
    fromTx?.from ?? "",
    fromTxBlockchain as Blockchain,
  );
  const fromTxToEntity = useEntityLookup(
    fromTx?.to ?? "",
    fromTxBlockchain as Blockchain,
  );
  const toTxFromEntity = useEntityLookup(
    toTx.from,
    toTxBlockchain as Blockchain,
  );
  const toTxToEntity = useEntityLookup(toTx.to, toTxBlockchain as Blockchain);
  const fromTxFromIsAnAddress = !!useIsAddressLike(fromTx?.from ?? "".trim());
  const fromTxToIsAnAddress = !!useIsAddressLike(fromTx?.to ?? "".trim());
  const toTxFromIsAnAddress = !!useIsAddressLike(toTx.from.trim());
  const toTxToIsAnAddress = !!useIsAddressLike(toTx.to.trim());

  const fromTxAddressInfo = {
    from: {
      entity: fromTxFromEntity,
      isAnAddress: fromTxFromIsAnAddress,
    },
    to: {
      entity: fromTxToEntity,
      isAnAddress: fromTxToIsAnAddress,
    },
  };
  const toTxAddressInfo = {
    from: {
      entity: toTxFromEntity,
      isAnAddress: toTxFromIsAnAddress,
    },
    to: {
      entity: toTxToEntity,
      isAnAddress: toTxToIsAnAddress,
    },
  };

  return { fromTxAddressInfo, toTxAddressInfo };
}

function KeyChangeRow({
  txKey,
  fromTx,
  toTx,
}: {
  txKey: ReturnType<typeof getChangedKeys>[number];
  fromTx: TransactionDetails | undefined;
  toTx: TransactionDetails;
}) {
  const erpAccounts = useErpAvailableAccountsQuery().data;
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const { tokens } = useDesign();
  const { fromTxAddressInfo, toTxAddressInfo } = useAddressFromToTxInfo({
    fromTx,
    toTx,
  });

  const old = getPrettyValue({
    transaction: fromTx,
    key: txKey,
    lang,
    languagePreference,
    localCurrency,
    erpAccounts,
    tokens,
    addressInfo: fromTxAddressInfo,
  });
  return (
    <Box display="flex" gap="0.25rem" alignItems="center">
      <HistoryText variant="Metropolis/Body/Regular" type="title">
        {lang.txTable.expansionPanel.history.transactionKeys[txKey]}:
      </HistoryText>
      {old ? (
        <>
          <HistoryText variant="Metropolis/Body/Regular" type="old">
            {old}
          </HistoryText>
          <ArrowForward style={{ fontSize: "1rem", color: tokens.text.low }} />
        </>
      ) : null}
      <HistoryText variant="Metropolis/Body/Regular" type="new">
        {getPrettyValue({
          transaction: toTx,
          key: txKey,
          lang,
          languagePreference,
          localCurrency,
          erpAccounts,
          tokens,
          addressInfo: toTxAddressInfo,
        })}
      </HistoryText>
    </Box>
  );
}

function showEntityName({
  entity,
  exchange,
  isAnAddress,
}: {
  entity: Entity;
  exchange: string;
  isAnAddress: boolean;
}) {
  if (isAnAddress) {
    return `${entity.displayName} (${formatDisplayAddress(exchange, false, true)})`;
  }

  return entity.displayName;
}

function getPrettyValue({
  transaction,
  key,
  lang,
  localCurrency,
  languagePreference,
  erpAccounts,
  tokens,
  addressInfo,
}: {
  transaction: TransactionDetails | undefined;
  key: ReturnType<typeof getChangedKeys>[number];
  lang: ReturnType<typeof useLang>;
  localCurrency: LocalCurrency | undefined;
  languagePreference: SupportedLang;
  erpAccounts?: ErpAccount[];
  tokens: Tokens;
  addressInfo: {
    from: {
      entity: Entity | undefined;
      isAnAddress: boolean;
    };
    to: {
      entity: Entity | undefined;
      isAnAddress: boolean;
    };
  };
}): React.ReactNode {
  if (!transaction) {
    return null;
  }
  if (key === "trade") {
    const Icon = TradeIcons[transaction[key]];
    return (
      <Box display="flex" alignItems="center" gap="0.25rem">
        <Icon style={{ fontSize: "1rem", color: tokens.text.low }} />
        {getActionTypeName({
          actionType: transaction[key],
          lang,
        })}
      </Box>
    );
  }
  if (key === "currencyIdentifier" || key === "feeCurrencyIdentifier") {
    const currency = transaction[key];
    if (!currency) {
      return "-";
    }
    return (
      <Box
        display="flex"
        alignItems="center"
        gap="0.25rem"
        data-uncensored="true"
      >
        <CurrencyLogo currency={currency} width={16} height={16} margin="0" />
        {`${currency.name} (${currency.symbol})`}
      </Box>
    );
  }
  if (key === "fee" || key === "price") {
    return displayFiatValue({
      value: transaction[key],
      localCurrency,
      locale: languagePreference,
    });
  }
  if (key === "from" || key === "to") {
    const blockchain = transaction.blockchain;
    const currencySymbol = transaction.currencyIdentifier.symbol;
    const { entity, isAnAddress } = addressInfo[key];
    const exchange = transaction[key];
    const displayNameKey = key === "from" ? "fromDisplayName" : "toDisplayName";
    const displayName = transaction[displayNameKey] ?? transaction[key];

    const isNicknamed =
      Boolean(entity) ||
      (exchange.toLowerCase() !== displayName.toLowerCase() && isAnAddress);

    const finalDisplayName = entity
      ? showEntityName({
          entity,
          exchange,
          isAnAddress,
        })
      : displayName.trim();

    return (
      <Box display="flex" alignItems="center" gap="0.25rem">
        <ExchangeLogo
          name={getEntityExchangeLogoName(entity) ?? exchange}
          blockchain={blockchain}
          currencySymbol={currencySymbol}
          width={16}
          height={16}
          margin="0"
        />
        {isNicknamed
          ? finalDisplayName
          : formatDisplayAddress(finalDisplayName, isNicknamed, isAnAddress)}
      </Box>
    );
  }
  if (key === "blockchain") {
    const blockchain = transaction[key] as Blockchain | undefined;
    if (!blockchain) {
      return "-";
    }
    return (
      <Box display="flex" alignItems="center" gap="0.25rem">
        <BlockchainLogo
          blockchain={blockchain}
          width={16}
          height={16}
          margin="0"
        />
        {BlockchainName[blockchain]}
      </Box>
    );
  }
  if (key === "timestamp") {
    return moment(transaction[key]).format("D MMM YYYY H:mm:ss");
  }
  if (key === "functionName") {
    return formatFunctionName(transaction[key] ?? "");
  }
  // Handle from/to with icons
  if (
    key === "erp.accountMappings.asset" ||
    key === "erp.accountMappings.gain" ||
    key === "erp.accountMappings.pl"
  ) {
    // handle the ERP mappings
    const erpAccountCode = get(transaction, key);
    const erpAccount = erpAccounts?.find(
      (account) => account.code === erpAccountCode,
    );
    if (!erpAccount) {
      return lang.txTable.expansionPanel.history.notSet;
    }
    return `${erpAccount.name} (${erpAccount.code})`;
  }
  if (typeof get(transaction, key) === "boolean") {
    const langKey = `${get(transaction, key)}` as "true" | "false";
    return lang.txTable.expansionPanel.history[langKey];
  }
  if (typeof get(transaction, key) !== "number" && !get(transaction, key)) {
    return "-";
  }
  if (typeof get(transaction, key) === "string") {
    return get(transaction, key);
  }
  // So if there is something I don't know about, it wont crash
  return JSON.stringify(get(transaction, key));
}

const HistoryText = styled(({ type, ...props }) => <Typography {...props} />)`
  && {
    padding: 0.13rem 0.25rem;
    background-color: ${({ theme }) => theme.tokens.elevation.high};
    border-radius: 0.25rem;
    color: ${({ theme, type }) =>
      type === "title"
        ? theme.tokens.text.low
        : type === "old"
          ? theme.tokens.text.danger
          : theme.tokens.text.default};
    text-decoration: ${({ type }) =>
      type === "old" ? "line-through" : "none"};
  }
`;
