import { type Blockchain } from "@ctc/types";
import { Box, Grid, TextField } from "@mui/material";
import moment from "moment-timezone";
import { useContext, useState } from "react";
import { type Control, Controller } from "react-hook-form";

import { AdvancedImportOptions } from "~/components/imports/advancedImportOptions";
import { MuiImportGrid } from "~/components/imports/api/index";
import { ImportFormContext } from "~/components/imports/context/index";
import { getRelatedWalletsAction } from "~/components/imports/helpers";
import { ImportFromSelector } from "~/components/imports/ImportFromSelector";
import { useImportContext } from "~/components/imports/ImportItem";
import { ImportTypeEtaBox } from "~/components/imports/ImportTypeEtaBox";
import { OnboardingNavBar } from "~/components/imports/OnboardingNavBar";
import { type WalletInputController } from "~/components/imports/wallet/index";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { displayMessage } from "~/components/ui/Toaster";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { isValidWalletAddress } from "~/services/wallet/isValidWalletAddress";
import { formatWalletAddress } from "~/services/wallets";
import {
  useEntityLookupAsync,
  useRemoveAddressFromEntityMutation,
} from "~/state/entities";
import { useFetchSavedAccounts } from "~/state/importsV2";
import { useAddWalletMutation } from "~/state/wallets";
import { DisplayMessage, ImportMethod } from "~/types/enums";
import {
  type BulkWalletImportMethod,
  type ImportOptionV2,
} from "~/types/index";

export function BulkWallet({
  importMethod,
  importOption,
  controller,
}: {
  importOption: ImportOptionV2;
  importMethod: BulkWalletImportMethod;
  controller: WalletInputController;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const [importFromModal, setImportFromModal] = useState(false);
  const { setSyncStarted } = useImportContext();
  const addWallet = useAddWalletMutation({ isUsingImportForm: true });
  const removeAddressFromEntityMutation = useRemoveAddressFromEntityMutation();
  const { getEntityForAddress } = useEntityLookupAsync();
  const importFormContext = useContext(ImportFormContext);
  const savedAccounts = useFetchSavedAccounts();

  const {
    formController,
    setEditingWalletAddress,
    setImportFromTime,
    importFromTime,
  } = controller;

  const { handleSubmit, setValue, reset, control } = formController;

  function checkModal(formData: {
    id: string;
    address: string;
    name: string | undefined;
    importFromTimeMillis?: number;
  }) {
    if (formData.importFromTimeMillis) {
      setImportFromModal(true);
      return;
    }
    onSubmit(formData);
  }

  const onAddressChange = (address: string) => {
    setValue("address", address, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleImportFromChange = (date: string) => {
    const value = date ? moment(date).valueOf() : undefined;
    setValue("importFromTimeMillis", value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  async function onSubmit(formData: {
    id: string;
    address: string;
    name: string | undefined;
    importFromTimeMillis?: number;
  }) {
    const { regex, keyMapping } = importMethod;
    const { address } = formData;

    const regexPattern = new RegExp(regex, "g");
    const matches = address.match(regexPattern);

    const resultArray: { blockchain: Blockchain; address: string }[] = (
      matches || []
    )
      .flatMap((match) => {
        const [key, value] = match.split(":") || [];

        if (keyMapping[key]) {
          return { blockchain: keyMapping[key], address: value.trim() };
        }

        displayMessage({
          message: lang.wallet.importRules.invalidBulkBlockchain({
            chain: key,
          }),
          type: DisplayMessage.Error,
        });
        return [];
      })
      .filter(({ address, blockchain }) => {
        const parsedAddress = formatWalletAddress(address, blockchain);

        // Check the address is valid for the blockchain
        if (!isValidWalletAddress(parsedAddress, blockchain, false)) {
          displayMessage({
            message: lang.wallet.importRules.invalid({
              name: address,
            }),
            type: DisplayMessage.Error,
          });

          return false;
        }

        // Check if address already exists for blockchain
        const existingAddressesLowerCase =
          savedAccounts.data?.importsByIntegration[blockchain]?.wallets.map(
            ({ address }) => address.toLowerCase(),
          ) ?? [];

        if (existingAddressesLowerCase.includes(parsedAddress.toLowerCase())) {
          displayMessage({
            message: lang.wallet.importRules.existing({
              name: address,
            }),
            type: DisplayMessage.Error,
          });

          return false;
        }

        return true;
      });

    resultArray.forEach(({ address, blockchain }) => {
      const parsedAddress = formatWalletAddress(address, blockchain);

      setTimeout(() => {
        // Pushes sync state change onto the next event loop, allowing the view
        // mode state to settle, removing this means that the drawer will switch
        // view modes while it is open.
        setSyncStarted({
          value: true,
          accountId: parsedAddress,
          savedIntegration: {
            name: formData.name,
            type: ImportMethod.Wallet,
            importFromTimeMillis: formData.importFromTimeMillis,
            importSource: formData.id,
            addresses: resultArray.map((result) =>
              formatWalletAddress(result.address, blockchain),
            ),
            blockchain,
          },
        });
      }, 0);

      const savedAddress = getEntityForAddress(formData.address);

      addWallet.mutate({
        address: parsedAddress,
        type: blockchain,
        name: formData.name,
        importFromTimeMillis: formData.importFromTimeMillis,
        relatedWalletsAction: getRelatedWalletsAction(resultArray.length),
        importSource: importOption.id,
        importMethod: ImportMethod.BulkWallet,
      });
      if (savedAddress) {
        removeAddressFromEntityMutation.mutate({
          entity: savedAddress,
          removedAddress: {
            address: formData.address,
            blockchain,
          },
        });
      }
    });

    if (resultArray.length === 0) {
      displayMessage({
        message: lang.wallet.bulkError,
        type: DisplayMessage.Error,
      });
    } else {
      reset();
      setEditingWalletAddress(undefined);
      setImportFromTime(undefined);
      setImportFromModal(false);
      importFormContext?.setShowImportForm(false);
    }
  }

  return (
    <>
      <ConfirmationDialog
        isOpen={importFromModal}
        title={lang.imports.importFrom.modal.title}
        text={lang.imports.importFrom.modal.text}
        actionText={lang.imports.importFrom.modal.actionText}
        handleClose={() => {
          setImportFromModal(false);
        }}
        handleAction={handleSubmit(onSubmit)}
        critical
      />
      <Grid container direction="column" spacing={1}>
        <MuiImportGrid item xs={12}>
          <form onSubmit={handleSubmit(checkModal)}>
            <Controller
              control={control}
              name="address"
              rules={{
                required: lang.wallet.importRules.bulkEmpty({
                  name: importOption.name,
                }),
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  margin="normal"
                  variant="outlined"
                  type="text"
                  required
                  label={lang.wallet.addresses}
                  placeholder={lang.wallet.bulk}
                  fullWidth
                  error={invalid}
                  helperText={error?.message || lang.wallet.bulkHelperText}
                  onChange={(e) => {
                    onAddressChange(e.target.value);
                  }}
                  sx={{
                    backgroundColor: "transparent",
                    ".MuiInputBase-fullWidth": {
                      backgroundColor: tokens.background.input.default,
                    },
                  }}
                  multiline
                  minRows={4}
                  maxRows={4}
                />
              )}
            />
            <ControllerWalletName control={control} />
            <ImportTypeEtaBox
              avgMs={importMethod.avgMs}
              p95Ms={importMethod.p95Ms}
            />
            <AdvancedImportOptions
              importOption={importOption}
              importMethod={ImportMethod.BulkWallet}
            >
              <ControllerWalletName control={control} />
              <ImportFromSelector
                control={control}
                handleChange={handleImportFromChange}
                disabled={false}
                importFromTime={importFromTime}
              />
            </AdvancedImportOptions>
            <Box
              display="flex"
              justifyContent="flex-start"
              flexGrow={1}
              flexShrink={0}
              alignItems="baseline"
              marginTop="0.75rem"
            >
              <PrimaryButton type="submit" variant="contained">
                {lang.wallet.submitMultiple}
              </PrimaryButton>
            </Box>
          </form>
        </MuiImportGrid>
      </Grid>
      <OnboardingNavBar
        importOption={importOption}
        importMethod={importMethod}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
}

function ControllerWalletName({ control }: { control: Control<any> }) {
  const { tokens } = useDesign();
  const lang = useLang();
  return (
    <Controller
      control={control}
      name="name"
      rules={{
        validate: {
          invalid: (value) =>
            value?.match(/[$;"'`]|(\.\.)/g) ? lang.wallet.invalid : true,
        },
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <TextField
          {...field}
          margin="normal"
          variant="outlined"
          type="text"
          error={invalid}
          helperText={error?.message}
          label={lang.wallet.nickname}
          fullWidth
          sx={{
            backgroundColor: "transparent",
            ".MuiOutlinedInput-input": {
              backgroundColor: tokens.background.input.default,
            },
          }}
        />
      )}
    />
  );
}
