import { EmailVerificationStatus, OAuthProvider } from "@ctc/types";
import { ContentCopyOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import isUndefined from "lodash/isUndefined";
import type * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { isFeatureEnabled } from "~/analytics/posthog";
import { InviteLinkVariant } from "~/components/settings-modal/views/enums";
import { ClientInviteData } from "~/components/settings-modal/views/permissions/ClientInviteData";
import { AccountantInviteLink } from "~/components/settings-modal/views/profile/AccountantInviteLink";
import { Accounting } from "~/components/settings-modal/views/profile/Accounting";
import { EmailSettings } from "~/components/settings-modal/views/profile/EmailSettings";
import { LanguageSelector } from "~/components/settings-modal/views/profile/LanguageSelector";
import { LogoutSettings } from "~/components/settings-modal/views/profile/LogoutSettings";
import { ManageData } from "~/components/settings-modal/views/profile/ManageData";
import { TwoFactorAuth } from "~/components/settings-modal/views/profile/security/index";
import { SupportAccess } from "~/components/settings-modal/views/profile/SupportAccess";
import { UpdatePassword } from "~/components/settings-modal/views/profile/UpdatePassword";
import { SettingsBox } from "~/components/settings-modal/views/SettingsBox";
import { ToggleSwitchBox } from "~/components/settings-modal/views/tax/ToggleSwitchBox";
import { Chip } from "~/components/ui/Chips";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useIsMobile } from "~/components/ui/hooks";
import { PasswordTextField } from "~/components/ui/PasswordTextField";
import { Spinner } from "~/components/ui/Spinner";
import { displayMessage } from "~/components/ui/Toaster";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { AlertWarningIcon } from "~/components/user/SignupForm";
import { formatEmail } from "~/components/user/validators";
import { OAuthNames } from "~/constants/constants";
import { LocalStorageKey } from "~/constants/enums";
import { useActiveClient } from "~/hooks/useActiveClient";
import { useCountries } from "~/hooks/useCountries";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import { CountryFlag } from "~/lib/CountryFlag";
import { middleTrim } from "~/lib/index";
import { passwordChecker } from "~/lib/passwordChecker";
import { updateActiveClient } from "~/redux/accountant";
import {
  loadUser,
  setUpdate,
  setUpdateBestActiveUser,
  useBestUser,
  useEmail,
  useIsAccountant,
  useIsLoadingUser,
  useIsManagingClients,
  useName,
  useUser,
} from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import * as settingsAPI from "~/services/settings";
import { requestEmailVerificationEmail } from "~/services/settings";
import { oauthToPassword } from "~/services/user";
import { clientKeys } from "~/state/clients";
import { useBestInvitation } from "~/state/invitations";
import { DisplayMessage, FeatureFlag } from "~/types/enums";
import { type UserInfo } from "~/types/index";

import { EditButtons, VerifyEmailState } from "./EditButtons";

export const Subheading = styled.span`
  font-weight: 500;
`;

interface ProfileDisabledFieldProps {
  isMobile?: boolean;
  width: string;
  shouldHaveMarginTopOnMobile?: boolean;
  heading: string;
  value: JSX.Element | string | undefined;
}

function ProfileDisabledField({
  isMobile,
  width,
  shouldHaveMarginTopOnMobile,
  heading,
  value,
}: ProfileDisabledFieldProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={isMobile ? "100%" : width}
      mt={!!isMobile && !!shouldHaveMarginTopOnMobile ? "0.5rem" : "0rem"}
    >
      <Typography variant="Metropolis/Body/Light" display="inline">
        <Subheading>{heading}</Subheading>
      </Typography>
      <StyledTextFieldDisabled mt="0.25rem">
        {value || ""}
      </StyledTextFieldDisabled>
    </Box>
  );
}

// TODO handle active vs normal profile
export function Profile() {
  const dispatch = useDispatch();
  const defaultName = useName();
  const isAccountant = useIsAccountant();
  const defaultEmail = useEmail();
  const activeClient = useActiveClient();
  const { tokens } = useDesign();
  const [email, setEmail] = useState(defaultEmail);
  const [name, setName] = useState(defaultName);
  const [verifyEmailState, setVerifyEmailState] = useState<
    VerifyEmailState | undefined
  >();
  // this is just used to show that the email is no longer verified
  // once they have updated their email
  const [emailUpdated, setEmailUpdated] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [nameEditMode, setNameEditMode] = useState(false);
  const isManagingClients = useIsManagingClients();
  const isLoadingUser = useIsLoadingUser();
  const { isLoading: isLoadingBestInvitation } = useBestInvitation();
  const lang = useLang();
  const authUser = useUser();
  const user = useBestUser();
  const isMobile = useIsMobile();
  const isNarrow = !useMediaQuery("(min-width: 500px)");
  const queryClient = useQueryClient();
  const { getByCode } = useCountries();
  const [smoothScrollEnabledPreference, setSmoothScrollEnabledPreference] =
    useLocalStorage<boolean>(
      LocalStorageKey.SmoothScroll,
      // ON by default
      true,
    );

  const [emailReports, setEmailReports] = useState(!!user?.emailReports);

  const showEmailReports =
    !!defaultEmail &&
    (!isManagingClients || (isManagingClients && !activeClient));

  const toggleEmailReports = async (newValue: boolean) => {
    // Optimistically set the value
    setEmailReports(newValue);

    const res = await settingsAPI.updateEmailReports({
      emailReports: newValue,
    });

    if (res.error) {
      setEmailReports(!newValue);
      displayMessage({
        message: lang.settings.report.emailReports.wentWrong,
        type: DisplayMessage.Error,
      });
    } else {
      // @TODO: this is a hacky way to reload the user. fetech this state better
      dispatch(loadUser(LoadUserType.UserUpdate));
    }
  };

  if (!user || !authUser) return null;

  const { childProfileDetails } = authUser;
  const { isOAuth, localCurrency, oAuthProvider } = user;

  const hasOAuth = isOAuth && oAuthProvider;
  const OAuthConfig: Record<
    OAuthProvider,
    {
      canModifyEmail: boolean;
      canModifyPassword: boolean;
    }
  > = {
    [OAuthProvider.Moralis]: {
      canModifyEmail: true,
      canModifyPassword: false,
    },
    [OAuthProvider.IndependentReserve]: {
      canModifyEmail: false,
      canModifyPassword: true,
    },
    [OAuthProvider.BitcoinDotComAu]: {
      canModifyEmail: false,
      canModifyPassword: true,
    },
    [OAuthProvider.Google]: {
      canModifyEmail: false,
      canModifyPassword: false,
    },
    [OAuthProvider.Xero]: {
      canModifyEmail: false,
      canModifyPassword: false,
    },
    [OAuthProvider.Coinbase]: {
      canModifyEmail: false,
      canModifyPassword: false,
    },
  };

  const PasswordConfig = {
    canModifyEmail: true,
    canModifyPassword: true,
  };

  const providerConfig = hasOAuth ? OAuthConfig[oAuthProvider] : PasswordConfig;

  const canModifyEmail = providerConfig?.canModifyEmail;
  const canModifyPassword = providerConfig?.canModifyPassword;

  const hasEmail = !!defaultEmail;

  const country = getByCode(user.country);
  if (!country) {
    return null;
  }

  async function updateEmail() {
    if (!email) return;
    setEditMode(false);
    dispatch(setUpdate({ email }));
    const res = await settingsAPI.updateEmail(email);
    if (res.error) {
      setEmail(defaultEmail);
      dispatch(setUpdate({ email: defaultEmail }));
      displayMessage({
        message: res?.msg || lang.settings.profile.wentWrongUpdatingEmail,
        type: DisplayMessage.Error,
      });
    } else {
      // make the email unverified if it was changed
      setVerifyEmailState(undefined);
      setEmailUpdated(true);
      setEmail(res.data.email);
      displayMessage({
        message: lang.settings.profile.emailAddressChanged,
        type: DisplayMessage.Success,
      });
    }
  }

  async function updateName() {
    setNameEditMode(false);
    if (!isUndefined(name)) {
      const res = await settingsAPI.updateName(name as string);
      if (res.error) {
        setName(defaultName); // if error, reset the field to the old value
        displayMessage({
          message: lang.settings.profile.wentWrongUpdatingName,
          type: DisplayMessage.Error,
        });
      } else {
        setName(res.data.name);
        dispatch(setUpdateBestActiveUser({ name: res.data.name }));
        if (isManagingClients)
          dispatch(updateActiveClient({ name: res.data.name }));
        queryClient.invalidateQueries({ queryKey: clientKeys.all() });
        displayMessage({
          message: lang.settings.profile.nameChanged,
          type: DisplayMessage.Success,
        });
      }
    }
  }

  async function handleVerifyEmail() {
    setVerifyEmailState(VerifyEmailState.Sending);
    const res = await requestEmailVerificationEmail();
    if (res.error) {
      setVerifyEmailState(VerifyEmailState.Errored);
    }
    setVerifyEmailState(VerifyEmailState.Sent);
  }

  function handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(formatEmail(e.target.value));
  }

  function handleChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    const update = e.target.value;
    setName(update);
  }

  function reset() {
    setEmail(defaultEmail);
    setName(defaultName);
  }

  function handleCancel() {
    reset();
    setEditMode(false);
  }
  function handleCancelName() {
    reset();
    setNameEditMode(false);
  }

  const isEditingClientProfile = isManagingClients && activeClient;

  // Should now be set via the user's notifications (new approach), but also allow opting in via
  // posthog feature flag (previous approach) to prevent messing with existing supportal users
  const canViewSupportAccess =
    !!authUser.notifications?.optInSupportAccess ||
    isFeatureEnabled(FeatureFlag.SupportAccess);

  if (isLoadingUser || isLoadingBestInvitation) {
    return (
      <Box
        width="100%"
        height="20rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const settingsTitle = !isAccountant
    ? lang.settings.profile.title
    : isEditingClientProfile
      ? lang.settings.clientData.profile
      : lang.settings.accounting.profile;

  return (
    <>
      <SettingsBox title={settingsTitle}>
        <Box display="flex" flexDirection="column" gap="1rem">
          {hasOAuth && (
            <OAuthSettings authUser={authUser} oAuthProvider={oAuthProvider} />
          )}
          {/** Allows the user to edit their email */}
          <EditButtons
            editMode={editMode}
            setEditMode={setEditMode}
            handleChangeValue={handleChangeEmail}
            handleCancel={handleCancel}
            handleUpdate={updateEmail}
            isEmailVerified={
              emailUpdated
                ? false
                : user.emailVerificationStatus ===
                  EmailVerificationStatus.Verified
            }
            verifyEmailState={verifyEmailState}
            handleVerifyEmail={handleVerifyEmail}
            canVerifyEmail={
              !isEditingClientProfile && canModifyEmail && hasEmail
            }
            isSpacesAllowed={false}
            buttonText={
              email
                ? lang.settings.profile.changeEmail
                : lang.settings.profile.addEmail
            }
            placeholder={
              isEditingClientProfile
                ? lang.settings.profile.noLinkedClient
                : lang.settings.profile.email
            }
            value={
              isEditingClientProfile ? childProfileDetails?.email || "" : email
            }
            fieldName={
              isEditingClientProfile
                ? lang.settings.profile.linkedClientsEmail
                : lang.settings.profile.email
            }
            allowEdit={!isEditingClientProfile && canModifyEmail}
          />
          <Box>
            {/* Allows the user to edit their name */}
            <EditButtons
              editMode={nameEditMode}
              setEditMode={setNameEditMode}
              value={name}
              handleChangeValue={handleChangeName}
              handleCancel={handleCancelName}
              handleUpdate={updateName}
              buttonText={lang.settings.profile.changeName}
              placeholder={lang.settings.profile.addName}
              fieldName={
                isEditingClientProfile
                  ? lang.settings.profile.linkedClientsName
                  : lang.settings.profile.name
              }
            />
          </Box>
          <Box
            display={isMobile ? "block" : "flex"}
            width="100%"
            maxWidth="22rem"
            justifyContent="space-between"
          >
            <ProfileDisabledField
              width="60%"
              heading={lang.settings.profile.country}
              value={
                <Box display="flex" gap="0.5rem" alignItems="center">
                  <CountryFlag
                    isoCode={country.isoString || country.code}
                    height={16}
                  />
                  {country.label}
                </Box>
              }
              isMobile={isMobile}
            />
            <ProfileDisabledField
              width="35%"
              heading={lang.settings.profile.localCurrency}
              value={localCurrency}
              shouldHaveMarginTopOnMobile
              isMobile={isMobile}
            />
          </Box>
          <Box width="100%" maxWidth="22rem">
            <Typography variant="Metropolis/Body/Light" display="inline">
              <Subheading>{lang.language}</Subheading>
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              width={isMobile ? "100%" : "60%"}
              mt="0.25rem"
            >
              <LanguageSelector />
            </Box>
          </Box>
          {/** Allows the user to update their password */}
          {!isEditingClientProfile && canModifyPassword && (
            <>
              <UpdatePassword />
              <TwoFactorAuth />
            </>
          )}
          <LogoutSettings />
        </Box>
      </SettingsBox>
      {!isEditingClientProfile ? (
        <AccountantInviteLink variant={InviteLinkVariant.EDIT} />
      ) : null}
      <ClientInviteData />
      {showEmailReports && (
        <SettingsBox>
          <Typography variant="Metropolis/Header/H5">
            {lang.settings.report.emailReports.title}
          </Typography>
          <EmailSettings
            emailReports={emailReports}
            onChange={toggleEmailReports}
          />
        </SettingsBox>
      )}
      <SettingsBox>
        <Typography variant="Metropolis/Header/H5">
          {lang.settings.profile.experience.title}
        </Typography>
        {/* Enables or disables the virtualiser
          Ideally used by support if a user is encountering issue with virtualiser */}
        <ToggleSwitchBox
          title={lang.settings.profile.experience.smoothScrolling.title}
          checked={smoothScrollEnabledPreference}
          tooltipOn={lang.settings.profile.experience.smoothScrolling.tooltipOn}
          tooltipOff={
            lang.settings.profile.experience.smoothScrolling.tooltipOff
          }
          onChange={() => {
            setSmoothScrollEnabledPreference(!smoothScrollEnabledPreference);
          }}
        />
      </SettingsBox>
      <SettingsBox>
        <Typography variant="Metropolis/Header/H5">
          {lang.settings.profile.debug.title}
        </Typography>
        <Stack gap="1rem">
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            alignItems="center"
          >
            {/* Put the users id in the settings so we can get it from prod without the network panel */}

            {lang.settings.profile.debug.uid.title}

            <code
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                background: tokens.background.accent.neutral.low,
                color: tokens.text.default,
                padding: "0.25rem 0.5rem",
                borderRadius: "0.25rem",
                fontWeight: 600,
                cursor: "pointer",
                fontSize: "0.75rem",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                displayMessage({
                  message: lang.settings.profile.debug.uid.notification,
                  type: DisplayMessage.Info,
                });
                return navigator.clipboard.writeText(user.uid);
              }}
            >
              {isNarrow ? middleTrim(user.uid, 10) : user.uid}
              <Tooltip title={user.uid} disableHoverListener={!isNarrow}>
                <ContentCopyOutlined
                  fontSize="inherit"
                  style={{ color: tokens.icon.default }}
                />
              </Tooltip>
            </code>
          </Box>
          {/* 'Grant Support Access' hidden by default.  Can be revealed by updating the logged-in user's notifications */}
          {canViewSupportAccess && <SupportAccess />}
        </Stack>
      </SettingsBox>
      <SettingsBox>
        <Typography variant="Metropolis/Header/H5">
          {lang.settings.manageData.manageData}
        </Typography>
        {isManagingClients && !activeClient?.isDefaultAccount ? (
          <Accounting />
        ) : null}

        <ManageData />
      </SettingsBox>
    </>
  );
}

function OAuthSettings({
  authUser,
  oAuthProvider,
}: {
  authUser: UserInfo;
  oAuthProvider: OAuthProvider;
}) {
  const lang = useLang();
  const profileLang = lang.settings.profile;
  const passwordLang = lang.password;
  const { tokens } = useDesign();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const isWalletOAuth = oAuthProvider === OAuthProvider.Moralis;

  const onClose = () => {
    setIsOpen(false);
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setConfirmError("");
    setIsSubmitting(false);
  };

  const handleNewPassword = (newPassword: string) => {
    setNewPassword(newPassword);

    const validPassword = passwordChecker(newPassword);

    if (validPassword.error) {
      setPasswordError(passwordLang[validPassword.msg]);
    } else {
      setPasswordError("");
    }

    if (confirmPassword && confirmPassword !== newPassword) {
      setConfirmError(profileLang.password.dontMatch);
    } else if (confirmPassword === newPassword) {
      setConfirmError("");
    }
  };

  const handleConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);

    if (confirmPassword && confirmPassword !== newPassword) {
      setConfirmError(profileLang.password.dontMatch);
    } else {
      setConfirmError("");
    }
  };

  const onSubmit = async () => {
    if (confirmPassword === newPassword) {
      setIsSubmitting(true);
      try {
        await oauthToPassword({ newPassword });
        dispatch(
          setUpdate({
            oAuthProvider: undefined,
            isOAuth: false,
          }),
        );
        displayMessage({
          message: profileLang.oauth.success,
          type: DisplayMessage.Success,
        });
        setIsSubmitting(false);
        onClose();
      } catch (e) {
        setIsSubmitting(false);
        displayMessage({
          message: profileLang.oauth.error,
          type: DisplayMessage.Error,
        });
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="0.5rem">
      <Box
        display="flex"
        justifyContent="flex-start"
        width="100%"
        alignItems="center"
      >
        <Typography
          variant="Metropolis/Body/Light"
          display="inline"
          sx={{
            pr: "0.5rem",
          }}
        >
          <Subheading>
            {isWalletOAuth
              ? profileLang.oauth.walletOauth
              : profileLang.oauth.title({
                  oauth: OAuthNames[oAuthProvider],
                })}
          </Subheading>
        </Typography>
        <Chip bgcolor={tokens.background.success.default}>
          <Typography
            variant="Metropolis/Body/Light"
            sx={{
              color: tokens.text.success,
            }}
          >
            {profileLang.oauth.connected}
          </Typography>
        </Chip>
      </Box>
      {isWalletOAuth ? (
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.high}
        >
          {authUser.activeProfileDetails.oAuthId}
        </Typography>
      ) : (
        <>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <TertiaryButton
              variant="outlined"
              size="small"
              sx={{ width: "fit-content", marginRight: "0.375rem" }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {profileLang.oauth.disconnect}
            </TertiaryButton>
          </Box>
          <GeneralDialog
            fullWidth
            disableAction={
              !!passwordError ||
              !!confirmError ||
              !newPassword ||
              !confirmPassword ||
              isSubmitting
            }
            closeOnClickAway
            handleClose={onClose}
            isOpen={isOpen}
            title={profileLang.oauth.areYouSure({
              oauth: OAuthNames[oAuthProvider],
            })}
            handleAction={onSubmit}
            actionText={profileLang.oauth.disconnect}
          >
            {isSubmitting && <Spinner />}
            <Box width="100%">
              <Typography variant="Metropolis/Body/Regular">
                {/* other oauth methods do have an email */}
                {profileLang.oauth.warning({
                  email: authUser.activeProfileDetails.email as string,
                })}
              </Typography>
              <form>
                <PasswordTextField
                  onChange={(e) => {
                    handleNewPassword(e.target.value);
                  }}
                  label={profileLang.password.newPassword}
                  error={!!passwordError}
                />
                {passwordError && (
                  <Box display="flex" alignItems="center">
                    <AlertWarningIcon />
                    <Typography
                      color={tokens.text.danger}
                      variant="Metropolis/Caption/Medium/Regular"
                      sx={{ marginTop: "0.125rerm" }}
                    >
                      {passwordError}
                    </Typography>
                  </Box>
                )}
                <PasswordTextField
                  onChange={(e) => {
                    handleConfirmPassword(e.target.value);
                  }}
                  label={profileLang.password.confirmNewPassword}
                  error={!!confirmError}
                />
                {confirmError && (
                  <Box display="flex" alignItems="center">
                    <AlertWarningIcon />
                    <Typography
                      color={tokens.text.danger}
                      variant="Metropolis/Caption/Medium/Regular"
                      sx={{ marginTop: "0.125rerm" }}
                    >
                      {confirmError}
                    </Typography>
                  </Box>
                )}
              </form>
            </Box>
          </GeneralDialog>
        </>
      )}
    </Box>
  );
}

export const StyledTextFieldDisabled = styled(({ placeholder, ...props }) => (
  <Box {...props} />
))`
  && {
    padding: 0.5125rem 0.5rem 0.4rem 0.69rem;
    color: ${({ theme }) => theme.tokens.text.disabled};
    font-size: 0.875rem;
    font-weight: 400;
    background-color: ${({ theme }) => theme.tokens.background.disabled};
    border: 1px solid ${({ theme }) => theme.tokens.border.disabled};
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
