import { useMemo } from "react";
import { useLocation, useMatch } from "react-router-dom";

/**
 * Custom hook to check if a given URL matches the current URL (including query parameters).
 * @param {string} url - The URL to check against the current location.
 * @returns {boolean} - True if the URL matches the current location, false otherwise.
 */
export function useIsUrlMatch(url: string): boolean {
  const location = useLocation();

  // Parse input URL to extract path and query string
  const urlObj = useMemo(() => new URL(url, window.location.origin), [url]);
  const linkPath = urlObj.pathname;
  const linkQueryParams = new URLSearchParams(urlObj.search);

  // Check if the path matches using `useMatch`
  const match = useMatch(linkPath);

  // Parse current location query params
  const currentQueryParams = new URLSearchParams(location.search);

  // Utility function to compare query parameters
  const areQueryParamsEqual = (
    linkQueryParams: URLSearchParams,
    currentQueryParams: URLSearchParams,
  ): boolean => {
    const linkEntries = Array.from(linkQueryParams.entries());
    for (let i = 0; i < linkEntries.length; i++) {
      const key = linkEntries[i][0];
      const value = linkEntries[i][1];
      if (currentQueryParams.get(key) !== value) {
        return false;
      }
    }
    return true;
  };

  // Determine if the pathname and query parameters match
  const isPathMatch = !!match;
  const isQueryMatch = areQueryParamsEqual(linkQueryParams, currentQueryParams);

  return isPathMatch && isQueryMatch;
}
