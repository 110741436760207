import { SyncStatusPlatform } from "@ctc/types";
import { Tooltip } from "@mui/material";
import moment from "moment-timezone";
import type * as React from "react";

import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";

export function LastSyncTimestampWrapper({
  status,
  lastCompleted,
  showTooltip = true,
  errorCode,
  errorMsg = "Unknown reason",
  children,
}: {
  status: SyncStatusPlatform | null;
  lastCompleted?: Date | null;
  showTooltip?: boolean;
  errorCode?: number;
  errorMsg?: string;
  children: React.ReactElement;
}) {
  const lang = useLang();
  const timezone = useTimezone();
  const date = moment.tz(lastCompleted, timezone).format("ll");
  const time = moment.tz(lastCompleted, timezone).format("LTS");
  let displayString = `${
    status === SyncStatusPlatform.Fail
      ? lang.imports.syncFailed
      : lang.imports.lastSynced
  } ${date} ${time}`;

  if (status === SyncStatusPlatform.Fail && errorMsg) {
    displayString = displayString.concat(`: ${errorMsg}`);
  }
  if (errorCode) {
    displayString = displayString.concat(`- code: ${errorCode}`);
  }

  // don't do this, just make the title an empty string if you don't want the tooltip
  return showTooltip && lastCompleted ? (
    <Tooltip title={displayString}>{children}</Tooltip>
  ) : (
    <>{children}</>
  );
}
