import { type Plan } from "@ctc/types";

import { useCurrentPlan } from "~/components/payment/helpers";
import { ClientManaged } from "~/components/settings-modal/views/plan/ClientManaged";
import { Upgrade } from "~/components/settings-modal/views/plan/Upgrade";
import { PlanRank } from "~/constants/constants";
import { useClientAccountantPaid, useClientPaid, useUser } from "~/redux/auth";

export function PageContents({ selectedPlanType }: { selectedPlanType: Plan }) {
  const user = useUser();
  const currentPlan = useCurrentPlan();
  const clientPaying = useClientPaid();
  const accountantPaying = useClientAccountantPaid();

  const isDoubleBilled = clientPaying && accountantPaying;

  if (isHigherPlan(currentPlan, selectedPlanType)) {
    if (clientPaying && !isDoubleBilled) {
      return <ClientManaged />;
    }

    return <Upgrade selectedPlanType={selectedPlanType} />;
  }

  if (clientPaying && !isDoubleBilled) return null;

  if (isSamePlanRank(currentPlan, selectedPlanType)) {
    // Manage current billing section goes here
    return null;
  }

  // cant downgrade
  return null;
}

function isHigherPlan(currentPlan: Plan | undefined, selectedPlanType: Plan) {
  if (!currentPlan) {
    return false;
  }

  const currentRank = PlanRank[currentPlan];
  const selectedRank = PlanRank[selectedPlanType];

  return selectedRank > currentRank;
}

function isSamePlanRank(currentPlan: Plan | undefined, selectedPlanType: Plan) {
  if (!currentPlan) {
    return false;
  }

  const currentRank = PlanRank[currentPlan];
  const selectedRank = PlanRank[selectedPlanType];

  return currentRank === selectedRank;
}
