import { Trade } from "@ctc/types";
import {
  Assistant,
  DisabledByDefault,
  History,
  LibraryAddCheck,
  PriceChangeOutlined,
} from "@mui/icons-material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BarChartIcon from "@mui/icons-material/BarChart";
import BlockIcon from "@mui/icons-material/Block";
import CommentIcon from "@mui/icons-material/Comment";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DeleteIcon from "@mui/icons-material/Delete";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MoneyOffCsredIcon from "@mui/icons-material/MoneyOffCsred";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SyncIcon from "@mui/icons-material/Sync";
import { type SvgIconProps } from "@mui/material";
import type * as React from "react";

import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { DisableVisible } from "~/components/ui/Icons";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { CategoryBucket, GroupedTrade } from "~/types/enums";

export const BulkEditTypeIcons = {
  [BulkEditOptions.Categorize]: <SwapHorizIcon />,
  [BulkEditOptions.ChangeCurrency]: <CurrencyExchangeIcon />,
  [BulkEditOptions.MarkAsReviewed]: <AssignmentIcon />,
  [BulkEditOptions.MarkAsNotReviewed]: <AssignmentIcon />,
  [BulkEditOptions.ChangeFeeCurrency]: <LocalGasStationIcon />,
  [BulkEditOptions.AddCommentToTransactions]: <CommentIcon />,
  [BulkEditOptions.SourceAccount]: <ArrowRightAltIcon />,
  [BulkEditOptions.DestinationAccount]: (
    <ArrowRightAltIcon sx={{ transform: "rotate(180deg)" }} />
  ),
  [BulkEditOptions.IgnoreWarnings]: <DisableVisible />,
  [BulkEditOptions.Ignore]: <BlockIcon />,
  [BulkEditOptions.Delete]: <DeleteIcon />,
  [BulkEditOptions.MarkAsSpam]: <MoneyOffCsredIcon />,
  [BulkEditOptions.GroupingWizard]: <Assistant />,
  [BulkEditOptions.LookUpMarketPrice]: <FindReplaceIcon />,
  [BulkEditOptions.ChangePrice]: <PriceChangeOutlined />,
  [BulkEditOptions.TxRewind]: <History />,
  [BulkEditOptions.Ungroup]: <DisabledByDefault />,
  [BulkEditOptions.AllowGrouping]: <LibraryAddCheck />,
  // Bookkeeping accounts
  [BulkEditOptions.ChangeAssetAccount]: <MenuBookIcon />,
  [BulkEditOptions.ChangePnLAccount]: <MenuBookIcon />,
  [BulkEditOptions.ChangeGainsAccount]: <MenuBookIcon />,
  [BulkEditOptions.ChangeLoanAccount]: <MenuBookIcon />,
  [BulkEditOptions.ChangeCashAccount]: <MenuBookIcon />,
  [BulkEditOptions.SyncToErp]: <SyncIcon />,
} as const satisfies Record<BulkEditOptions, JSX.Element>;

export const CategoryBucketIcons: Record<
  CategoryBucket,
  React.FunctionComponent<SvgIconProps>
> = {
  [CategoryBucket.Transfer]: TradeIcons[GroupedTrade.Trade],
  [CategoryBucket.Acquisition]: TradeIcons[Trade.Buy],
  [CategoryBucket.Income]: TradeIcons[Trade.Income],
  [CategoryBucket.Ignore]: TradeIcons[Trade.IgnoreIn],
  [CategoryBucket.Derivatives]: BarChartIcon,
  [CategoryBucket.Disposal]: TradeIcons[Trade.Sell],
  [CategoryBucket.Loss]: TradeIcons[Trade.Stolen],
  [CategoryBucket.Expenses]: TradeIcons[Trade.Expense],
};
