// Import services/core before reducer to resolve circular dependencies on `store` for hot reloading
import "~/services/core";

import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";

import { rootReducerWrapper } from "~/redux/reducer";

const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  trace: true,
  traceLimit: 25,
  maxAge: 25,
});

export const store = createStore(
  rootReducerWrapper,
  composeEnhancers(applyMiddleware(thunk)),
);
