import { createTheme, type ThemeOptions } from "@mui/material/styles";


import {
  // ctcFonts,
  ctcLight,
  // ctcTextStyles,
  type FigmaTypographyPropsVariantOverrides,
  type FigmaTypographyVariants,
  type FigmaTypographyVariantsOptions,
} from "~/components/ui/theme/generated";
import { getTokens, type Tokens } from "~/components/ui/theme/tokens";
import { getFlattenedTextStyles } from "~/design-tokens/utils/getFlattenedTextStyles";
import { BrandStyle, type ResolvedTheme, Theme } from "~/types/enums";
// Adding typography variants
declare module "@mui/material/styles" {
  interface TypographyVariants extends FigmaTypographyVariants {
    
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends FigmaTypographyVariantsOptions {
   
  }
}

// adding font variants
// https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides
    extends FigmaTypographyPropsVariantOverrides {
  }
}

const muiComponentsGeneric = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: "0.875rem",
        fontWeight: 500,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize: "0.875rem",
        fontWeight: 500,
      },
    },
  },
};

/**
 * Gets the font family from text tokens
 * @param tokens The text tokens
 * @returns The font family string or 'sans-serif' as fallback
 */
function getFontFamily(tokens: Record<string, any>): string {
  const firstKey = Object.keys(tokens)[0];
  if (!firstKey) return "sans-serif";

  const style = tokens[firstKey];
  return style?.fontFamily ?? "sans-serif";
}

function getLightThemeOptions(lightModeTokens: Tokens): ThemeOptions {
  const defaultTextTokens = getFlattenedTextStyles(
    lightModeTokens.Metropolis,
    "Metropolis",
  );
  const numericTextTokens = getFlattenedTextStyles(
    lightModeTokens["IBM Plex Mono"],
    "IBM Plex Mono",
  );
  const textStyles = { ...defaultTextTokens, ...numericTextTokens };

  const fontFamily = getFontFamily(defaultTextTokens);
  const numericFontFamily = getFontFamily(numericTextTokens);
  return {
    typography: {
      fontSize: 14,
      ...textStyles,
      fontFamily: `${fontFamily}, sans-serif`,
      button: {
        textTransform: "none",
      },
    },
    palette: {
      primary: {
        light: lightModeTokens.background.accent.purple.low,
        main: lightModeTokens.text.brand,
        dark: lightModeTokens.button.brand.pressed,
      },

      text: {
        secondary: lightModeTokens.text.default,
        primary: lightModeTokens.text.default,
      },
    },
    components: {
      ...muiComponentsGeneric,
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: lightModeTokens.background.neutral.lowest.default,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            ...muiComponentsGeneric.MuiMenuItem.styleOverrides.root,
            color: lightModeTokens.text.default,
            background: lightModeTokens.background.input.default,
            "&:hover": {
              background: lightModeTokens.button.neutral.hover,
            },
            "&.Mui-selected": {
              background: lightModeTokens.background.brand.hover,
              color: lightModeTokens.text.brand,
            },
            "&.Mui-disabled": {
              background: lightModeTokens.background.neutral.lowest,
              color: lightModeTokens.text.disabled,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            background: lightModeTokens.background.overlay,
            ".MuiDialog-paper": {
              background: lightModeTokens.background.neutral.lowest,
              border: `1px solid ${lightModeTokens.border.neutral.default}`,
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: lightModeTokens.elevation.default,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            background: lightModeTokens.elevation.default,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            "&.Mui-selected": {
              color: lightModeTokens.text.brand,
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Metropolis';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Metropolis');
          }
        `,
      },
      // TODO: Find the way to override it
      MuiTooltip: {
        defaultProps: {
          arrow: true,
        },
        styleOverrides: {
          tooltip: {
            backgroundColor: lightModeTokens.special.tooltip,
            color: lightModeTokens.special.tooltipText,
          },
          arrow: {
            color: lightModeTokens.special.tooltip,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: lightModeTokens.background.accent.neutral.low,
              cursor: "pointer",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            color: lightModeTokens.text.default,
            background: lightModeTokens.background.input.default,
            "&:hover": {
              background: lightModeTokens.button.neutral.hover,
            },
            "&.Mui-selected": {
              background: lightModeTokens.background.brand.hover,
              color: lightModeTokens.text.brand,
            },
            "&.Mui-disabled": {
              background: lightModeTokens.background.neutral.lowest,
              color: lightModeTokens.text.disabled,
            },
          },
          paper: {
            border: `1px solid ${lightModeTokens.border.neutral.default}`,
          },
          root: {
            ".MuiAutocomplete-input::-webkit-search-cancel-button": {
              display: "none",
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableFocusRipple: true,
        },
        styleOverrides: {
          root: {
            fontWeight: 600,
          },
        },
        variants: [
          {
            props: { variant: "outlined" }, // Primary is now the default prop on all of the buttons
            style: {
              border: "1px solid rgba(0, 0, 0, 0.23)",
              color: lightModeTokens.text.default,
            },
          },
        ],
      },
      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            sx: {
              "&.Mui-focused": {
                color: lightModeTokens.text.brand,
              },
            },
          },
        },
        styleOverrides: {
          root: {
            backgroundColor: lightModeTokens.background.input.default,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            backgroundColor: lightModeTokens.background.input.default,
            color: lightModeTokens.text.default,
          },
        },
        defaultProps: {
          SelectDisplayProps: {
            style: {
              color: lightModeTokens.text.default,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: lightModeTokens.border.neutral.default,
            },
            "&:hover:not(.Mui-focused) > fieldset": {
              borderColor: `${lightModeTokens.border.neutral.highest} !important`,
            },
            "&&.Mui-focused > fieldset": {
              borderColor: `${lightModeTokens.border.brand} !important`,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${lightModeTokens.border.neutral.default}`,
          },
          indicator: {
            backgroundColor: lightModeTokens.border.brand,
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: lightModeTokens.background.accent.purple.high,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            border: `1px solid ${lightModeTokens.border.neutral.default}`,
          },
        },
      },
      MuiListItemText: {
        ...muiComponentsGeneric.MuiListItemText,
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: lightModeTokens.background.accent.neutral.highest,
          },
          colorPrimary: {
            "&.Mui-checked": {
              color: lightModeTokens.background.accent.purple.high,
            },
          },
          track: {
            opacity: 1,
            background: lightModeTokens.background.accent.neutral.high,
            ".Mui-checked.Mui-checked + &": {
              opacity: 1,
              background: lightModeTokens.background.accent.purple.low,
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            // map over all new typography styles, if the key ends in h1 through to h5,
            // return 'h1' through to 'h5', for all other keys return 'div'
            ...Object.keys(textStyles).reduce((acc, key) => {
              if (key.toLowerCase().endsWith("h1")) {
                return { ...acc, [key]: "h1" };
              }
              if (key.toLowerCase().endsWith("h2")) {
                return { ...acc, [key]: "h2" };
              }
              if (key.toLowerCase().endsWith("h3")) {
                return { ...acc, [key]: "h3" };
              }
              if (key.toLowerCase().endsWith("h4")) {
                return { ...acc, [key]: "h4" };
              }
              if (key.toLowerCase().endsWith("h5")) {
                return { ...acc, [key]: "h5" };
              }
              return { ...acc, [key]: "div" };
            }, {}),
          },
        },
        styleOverrides: {
          root: {
            color: lightModeTokens.text.default,
          },
        },
      },
    },
  };
}

// TODO: this is legacy
// It's being used throughout our app to access the mui breakpoints
// We need to come up with a better solution for this
export const ctcLightTheme = createTheme(getLightThemeOptions(ctcLight));

function getDarkThemeOptions(darkModeTokens: Tokens): ThemeOptions {
  const defaultTextTokens = getFlattenedTextStyles(
    darkModeTokens.Metropolis,
    "Metropolis",
  );

  const numericTextTokens = getFlattenedTextStyles(
    darkModeTokens["IBM Plex Mono"],
    "IBM Plex Mono",
  );
  const textStyles = { ...defaultTextTokens, ...numericTextTokens };
  const textFontFamily = getFontFamily(defaultTextTokens);
  const numericFontFamily = getFontFamily(numericTextTokens);

  return {
    typography: {
      fontSize: 14,
      ...textStyles,
      fontFamily: `${textFontFamily}, sans-serif`,
      button: {
        textTransform: "none",
      },
    },
    palette: {
      mode: "dark",
      primary: {
        light: darkModeTokens.background.accent.purple.low,
        main: darkModeTokens.text.brand,
        dark: darkModeTokens.button.brand.pressed,
      },
      text: {
        secondary: darkModeTokens.text.default,
        primary: darkModeTokens.text.default,
      },
    },
    components: {
      ...muiComponentsGeneric,
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: darkModeTokens.background.neutral.lowest.default,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            ...muiComponentsGeneric.MuiMenuItem.styleOverrides.root,
            color: darkModeTokens.text.default,
            background: darkModeTokens.background.input.default,
            "&:hover": {
              background: darkModeTokens.button.neutral.hover,
            },
            "&.Mui-selected": {
              background: darkModeTokens.background.brand.hover,
              color: darkModeTokens.text.brand,
            },
            "&.Mui-disabled": {
              background: darkModeTokens.background.neutral.lowest,
              color: darkModeTokens.text.disabled,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            background: darkModeTokens.background.overlay,
            ".MuiDialog-paper": {
              background: darkModeTokens.background.neutral.lowest,
              border: `1px solid ${darkModeTokens.border.neutral.default}`,
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: darkModeTokens.elevation.default,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            background: darkModeTokens.elevation.default,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            "&.Mui-selected": {
              color: darkModeTokens.text.brand,
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
            @font-face {
              font-family: 'Metropolis';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Metropolis');
            }
          `,
      },
      // TODO: Find the way to override it
      MuiTooltip: {
        defaultProps: {
          arrow: true,
        },
        styleOverrides: {
          tooltip: {
            backgroundColor: darkModeTokens.special.tooltip,
            color: darkModeTokens.special.tooltipText,
          },
          arrow: {
            color: darkModeTokens.special.tooltip,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: darkModeTokens.background.accent.neutral.low,
              cursor: "pointer",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            color: darkModeTokens.text.default,
            background: darkModeTokens.background.input.default,
            "&:hover": {
              background: darkModeTokens.button.neutral.hover,
            },
            "&.Mui-selected": {
              background: darkModeTokens.background.brand.hover,
              color: darkModeTokens.text.brand,
            },
            "&.Mui-disabled": {
              background: darkModeTokens.background.neutral.lowest,
              color: darkModeTokens.text.disabled,
            },
          },
          paper: {
            border: `1px solid ${darkModeTokens.border.neutral.default}`,
          },
          root: {
            ".MuiAutocomplete-input::-webkit-search-cancel-button": {
              display: "none",
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableFocusRipple: true,
        },
        styleOverrides: {
          root: {
            fontWeight: 600,
          },
        },
        variants: [
          {
            props: { variant: "outlined" }, // Primary is now the default prop on all of the buttons
            style: {
              border: "1px solid rgba(0, 0, 0, 0.23)",
              color: darkModeTokens.text.default,
            },
          },
        ],
      },
      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            sx: {
              "&.Mui-focused": {
                color: darkModeTokens.text.brand,
              },
            },
          },
        },
        styleOverrides: {
          root: {
            backgroundColor: darkModeTokens.background.input.default,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: darkModeTokens.border.neutral.default,
            },
            "&:hover:not(.Mui-focused) > fieldset": {
              borderColor: `${darkModeTokens.border.neutral.highest} !important`,
            },
            "&&.Mui-focused > fieldset": {
              borderColor: `${darkModeTokens.border.brand} !important`,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            backgroundColor: darkModeTokens.background.input.default,
            color: darkModeTokens.text.default,
          },
        },
        defaultProps: {
          SelectDisplayProps: {
            style: {
              color: darkModeTokens.text.default,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${darkModeTokens.border.neutral.default}`,
          },
          indicator: {
            backgroundColor: darkModeTokens.border.brand,
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: darkModeTokens.background.accent.purple.high,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            border: `1px solid ${darkModeTokens.border.neutral.default}`,
          },
        },
      },
      MuiListItemText: {
        ...muiComponentsGeneric.MuiListItemText,
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: darkModeTokens.background.accent.neutral.highest,
          },
          colorPrimary: {
            "&.Mui-checked": {
              color: darkModeTokens.background.accent.purple.high,
            },
          },
          track: {
            opacity: 1,
            background: darkModeTokens.background.accent.neutral.high,
            ".Mui-checked.Mui-checked + &": {
              opacity: 1,
              background: darkModeTokens.background.accent.purple.low,
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            // map over all new typography styles, if the key ends in h1 through to h5,
            // return 'h1' through to 'h5', for all other keys return 'div'
            ...Object.keys(textStyles).reduce((acc, key) => {
              if (key.toLowerCase().endsWith("h1")) {
                return { ...acc, [key]: "h1" };
              }
              if (key.toLowerCase().endsWith("h2")) {
                return { ...acc, [key]: "h2" };
              }
              if (key.toLowerCase().endsWith("h3")) {
                return { ...acc, [key]: "h3" };
              }
              if (key.toLowerCase().endsWith("h4")) {
                return { ...acc, [key]: "h4" };
              }
              if (key.toLowerCase().endsWith("h5")) {
                return { ...acc, [key]: "h5" };
              }
              return { ...acc, [key]: "div" };
            }, {}),
          },
        },
        styleOverrides: {
          root: {
            color: darkModeTokens.text.default,
          },
        },
      },
    },
  };
}

/**
 * Gets the MUI theme based on brand style and theme mode
 * @param brandStyle The brand style to use (CTC or Metamask), defaults to CTC
 * @param mode The resolved theme mode (Light or Dark)
 * @returns The corresponding MUI theme
 */
export const getMUITheme = (
  brandStyle: BrandStyle = BrandStyle.CTC,
  mode: ResolvedTheme,
) => {
  const tokens = getTokens(brandStyle, mode);
  if (mode === Theme.Dark) {
    return createTheme(getDarkThemeOptions(tokens));
  }
  return createTheme(getLightThemeOptions(tokens));
};
