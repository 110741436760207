import { SyncStatusPlatform } from "@ctc/types";
import { Box, type BoxProps } from "@mui/material";
import type * as React from "react";
import { type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";

import { horizontalNavHeight } from "~/components/nav/SideNav";
import { embeddedHorizontalNavHeight } from "~/components/nav/TabNav";
import { queryClient } from "~/components/queryClient";
import { TransactionActionRowV2 } from "~/components/transactions/action-row/actionRow";
import { ActionRowHeader } from "~/components/transactions/action-row/ActionRowHeader";
import {
  type ActionTableOptions,
  ActionTableOptionsContext,
} from "~/components/transactions/action-row/context/actionTableOptions";
import { ActionTableContents } from "~/components/transactions/ActionTable";
import { CommandPaletteModal } from "~/components/transactions/command-palette/CommandPaletteModal";
import { tableElementId } from "~/components/transactions/constants";
import { FilterBar } from "~/components/transactions/filter-bar/FilterBar";
import { FilterPagination } from "~/components/transactions/filter-bar/FilterPagination";
import { syncCountQueryParamWithLocalStorage } from "~/components/transactions/helpers";
import { TxTableOverflowHorizontalScroll } from "~/components/transactions/TxTableOverflowHorizontalScroll";
import { TxTableOverflowShadow } from "~/components/transactions/TxTableOverflowShadow";
import { useMinScreenWidthForTableContent } from "~/components/transactions/useTableStopsScrollingPx";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useLocalStorageCountQueryParams } from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import { useUser } from "~/redux/auth";
import { CollaborationSyncEvent } from "~/redux/enums";
import { Scope } from "~/state/enums";
import { savedImports } from "~/state/importsV2";
import {
  useAllStatusesUpdate,
  useRemoveSyncMutation,
  useShouldCollaborationSync,
} from "~/state/sync";
import { Align } from "~/types/enums";
import { type Warning } from "~/types/enums";

export function TransactionsTableWithFilters({
  forcedWarnings,
  showFilterBar = true,
  loadingRowsCount,
  isDateGroupClickFilterEnabled = true,
  isBulkSelectEnabled = true,
  disablePaginationFooter = false,
  header = <ActionRowHeader />,
  row = TransactionActionRowV2,
  enableVirtualiser = true,
  isUrlDriven = false,
  autoScrollableX,
  disableBorder = false,
  disableHeader = false,
  disableResetFilters = false,
  customEmptyMessage,
  isLoading = false, // Show loading state on empty state instead of empty
  showCsvDownload = false,
  bulkSelectProps,
}: {
  sortQueryOverride?: string | null;
  pageQueryOverride?: number | null;
  //null is significant from undefined: null still hides the sort control while undefined does not
  forcedWarnings?: [Warning];
  // display or hide the filter bar, defaults to on
  showFilterBar?: boolean;
  // passing in filters means we dont use url query params to store the state
  loadingRowsCount?: number;
  isDateGroupClickFilterEnabled?: boolean;
  isMultiGroupSelectable?: boolean;
  isBulkSelectEnabled?: boolean;
  isCountEnabled?: boolean;
  disablePaginationFooter?: boolean;
  header?: ReactNode | ReactNode[];
  row?: ActionTableOptions["ActionRow"];
  enableVirtualiser?: boolean;
  isUrlDriven?: boolean;
  autoScrollableX?: boolean;
  disableBorder?: boolean;
  disableHeader?: boolean;
  disableResetFilters?: boolean;
  customEmptyMessage?: string;
  isLoading?: boolean;
  showCsvDownload?: boolean;
  bulkSelectProps?: {
    selectAllDisabled?: boolean;
    commandKDisabled?: boolean;
    categoriseShortcutDisabled?: boolean;
  };
}) {
  const { tokens } = useDesign();
  const [showAssociatedQuery] = useQueryParam("showAssociated", BooleanParam);
  const [idQuery] = useQueryParam("id", StringParam);
  const syncRemove = useRemoveSyncMutation();
  const syncStatuses = useAllStatusesUpdate();
  const filterRef = useRef<HTMLDivElement>(null);
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const scrollPoint = useMinScreenWidthForTableContent();

  useEffect(() => {
    if (filterRef.current) {
      setFilterHeight(filterRef.current.offsetHeight);
    }
  }, [filterRef]);

  const txTableOptions = useMemo(
    () => ({
      isDateGroupClickFilterEnabled,
      isBulkSelectEnabled,
      ActionRow: row,
    }),
    [isDateGroupClickFilterEnabled, isBulkSelectEnabled, row],
  );

  const uuid = useUser()?.uid;

  // if another sort setting is introduced for transactions table, this will need to be re-factored so we aren't
  // passing in 3+ individual settings
  const [countQuery, setCountQuery] = useLocalStorageCountQueryParams();

  const { shouldCollabSync, consumeCollabEvent } = useShouldCollaborationSync(
    [
      CollaborationSyncEvent.InviteAccepted,
      CollaborationSyncEvent.InviteRevoked,
      CollaborationSyncEvent.DataSourceChanged,
    ],
    {
      activeClientShouldMatch: true,
    },
  );

  useEffect(() => {
    syncCountQueryParamWithLocalStorage(uuid, countQuery, setCountQuery);
  }, [uuid, countQuery, setCountQuery]);

  // refresh transaction data if out of sync with client
  useEffect(() => {
    if (shouldCollabSync) {
      queryClient.invalidateQueries({ queryKey: savedImports.all() });

      consumeCollabEvent();
    }
  }, [shouldCollabSync, consumeCollabEvent]);

  useEffect(() => {
    const scopeIgnore: string[] = [Scope.Portfolio];
    const syncStatusesFiltered = Object.keys(syncStatuses).reduce(
      (acc, key) => {
        if (scopeIgnore.includes(key)) return acc;
        acc[key] = syncStatuses[key];
        return acc;
      },
      {} as Record<string, SyncStatusPlatform>,
    );

    const hasSuccess = Object.values(syncStatusesFiltered).includes(
      SyncStatusPlatform.Success,
    );

    if (hasSuccess) {
      // Prevent infinite loop by removing any successful status messages.
      Object.entries(syncStatuses).forEach(([key, value]) => {
        if (value === SyncStatusPlatform.Success) {
          syncRemove.mutate({ scope: key });
        }
      });
    }
  }, [
    countQuery,
    idQuery,
    showAssociatedQuery,
    syncStatuses,
    forcedWarnings,
    syncRemove,
  ]);

  return (
    <ActionTableOptionsContext.Provider value={txTableOptions}>
      {isBulkSelectEnabled ? (
        <CommandPaletteModal {...bulkSelectProps} />
      ) : null}

      {/* This empty div allow scroll above sticky filter header */}
      <div id={tableElementId} />

      <Box color={tokens.text.default}>
        <StickySection zIndex={StickySectionZIndex.FilterBar}>
          <div ref={filterRef}>
            {showFilterBar ? (
              <FilterBar showCsvDownload={showCsvDownload} />
            ) : null}
          </div>
        </StickySection>
        <Box
          border={
            disableBorder
              ? undefined
              : `1px solid ${tokens.border.neutral.default}`
          }
          borderTop={0}
          borderRadius="0.25rem"
        >
          <TxTableOverflowShadow scrollPoint={scrollPoint}>
            <TxTableOverflowHorizontalScroll
              scrollPoint={scrollPoint}
              autoScrollableX={!!autoScrollableX}
            >
              <Box
                position="relative"
                sx={{
                  width: "fit-content",
                  [`@media (min-width: ${scrollPoint}px)`]: {
                    width: "auto",
                  },
                }}
              >
                <StickySection
                  offset={filterHeight}
                  borderRadius="0.25rem"
                  borderTop={
                    disableBorder
                      ? undefined
                      : `1px solid ${tokens.border.neutral.default}`
                  }
                  zIndex={StickySectionZIndex.TableHeaders}
                >
                  {!disableHeader ? header : null}
                </StickySection>
                <ActionTableContents
                  loadingRowsCount={loadingRowsCount}
                  enableVirtualiser={enableVirtualiser}
                  isLoading={isLoading}
                  disableResetFilters={disableResetFilters}
                  customEmptyMessage={customEmptyMessage}
                />
              </Box>
            </TxTableOverflowHorizontalScroll>
          </TxTableOverflowShadow>
          {!disablePaginationFooter ? (
            <Box
              p="0.5rem"
              bgcolor={tokens.background.neutral.lowest.default}
              width="100%"
              borderTop={`1px solid ${tokens.border.neutral.default}`}
              borderRadius="0 0 0.25rem 0.25rem"
            >
              <FilterPagination isUrlDriven={isUrlDriven} align={Align.Right} />
            </Box>
          ) : null}
        </Box>
      </Box>
    </ActionTableOptionsContext.Provider>
  );
}

enum StickySectionZIndex {
  FilterBar = 120,
  TableHeaders = 110,
}

function StickySection({
  offset = 0,
  zIndex,
  children,
  ...props
}: { offset?: number; zIndex: number } & BoxProps) {
  const minScreenSizeForContent = useMinScreenWidthForTableContent();
  const isEmbedded = useIsEmbedded();

  return (
    <StickyBox
      offset={offset}
      minScreenSizeForContent={minScreenSizeForContent}
      zIndex={zIndex}
      isEmbedded={isEmbedded}
      {...props}
    >
      {children}
    </StickyBox>
  );
}

const StickyBox = styled(Box)<{
  minScreenSizeForContent: number;
  offset: number;
  zIndex: number;
  isEmbedded: boolean;
}>`
  @media (min-width: ${({ minScreenSizeForContent }) =>
      minScreenSizeForContent}px) {
    position: sticky;
    top: ${({ offset, isEmbedded }) =>
      offset
        ? `calc(${offset}px + ${isEmbedded ? embeddedHorizontalNavHeight : horizontalNavHeight})`
        : isEmbedded
          ? embeddedHorizontalNavHeight
          : horizontalNavHeight};
    z-index: ${({ zIndex }) => zIndex};
  }
`;
