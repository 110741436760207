import { Plan, ReferrerSource } from "@ctc/types";
import { Links } from "~/types/enums";
import { type UserInfo } from "~/types/index";

/**
 * Determines the default path a user should be redirected to after completing onboarding
 * @param user - User information including referrer source
 * @returns The path to redirect the user to
 */
export function getDefaultExitOnboardingLink(
  user: UserInfo | null | undefined,
): string {
  if (!user) return Links.Imports;

  // Send coinbase users to the report page
  if (user.referrerSource === ReferrerSource.CoinbaseEmbedded) {
    return Links.Report;
  }

  return Links.Imports;
}

/**
 * Determines the default in-app redirect path when no path is specified in URL
 * @param user - User information
 * @param isEmbedded - Whether the app is running in embedded mode
 * @returns The path to redirect the user to
 */
export function getDefaultInAppRedirectPath(
  user: UserInfo,
  isEmbedded: boolean,
): string {
  if (
    user.paidPlan === Plan.Accountant ||
    user.paidPlan === Plan.Collaborator
  ) {
    return Links.Clients;
  }

  if (isEmbedded) {
    return getEmbeddedDefaultInAppRedirectPath(user);
  }

  return Links.Imports;
}

/**
 * Determines the default redirect path for embedded app mode
 * @param user - User information
 * @returns The path to redirect the embedded user to
 */
function getEmbeddedDefaultInAppRedirectPath(user: UserInfo): string {
  // Send coinbase users to the report page
  if (user.referrerSource === ReferrerSource.CoinbaseEmbedded) {
    return Links.Report;
  }

  return Links.Dashboard;
}
