import { Close, FileDownload } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { TradingStockValuationMethod } from "~/components/reconciliation/enums";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function TradingStockInventorySelectorDialog({
  isOpen,
  setIsOpen,
  successCallback,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  successCallback:
    | ((valuationMethod: TradingStockValuationMethod) => void)
    | (() => void);
}) {
  const lang = useLang();
  const handleClose = () => {
    setIsOpen(false);
  };
  const captureAnalytics = useCaptureAnalytics();
  const { tokens } = useDesign();

  const { control, getValues, reset, handleSubmit } = useForm<{
    valuationMethod: TradingStockValuationMethod;
  }>({
    mode: "onBlur",
    defaultValues: {
      valuationMethod: undefined,
    },
  });

  useEffect(() => {
    if (isOpen) reset();
  }, [isOpen, reset]);

  const clickDownload = () => {
    successCallback?.(getValues().valuationMethod);
    setIsOpen(false);

    captureAnalytics("trading_stock_report_valuation_method", {
      TradingStockValuationMethod: getValues().valuationMethod,
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="Metropolis/Header/H4">
            {lang.report.download.tradingStockReportDownload.title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers style={{ paddingTop: "1rem" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box mb="1rem" lineHeight="1rem">
              <Typography variant="Metropolis/Caption/Medium/Regular" color={tokens.text.low}>
                {lang.report.download.tradingStockReportDownload.description}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="valuationMethod"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  defaultValue={TradingStockValuationMethod.MarketValue}
                >
                  <Box display="flex" flexDirection="column" gap="1rem">
                    <Box display="flex" alignItems="center" gap="0.5rem">
                      <Radio value={TradingStockValuationMethod.MarketValue} />
                      <Box display="flex" flexDirection="column">
                        <Typography variant="Metropolis/Caption/Medium/Bold">
                          {
                            lang.report.download.tradingStockReportDownload
                              .marketValueMethod.title
                          }
                        </Typography>
                        <Typography
                          variant="Metropolis/Caption/Medium/Regular"
                          color={tokens.text.low}
                        >
                          {
                            lang.report.download.tradingStockReportDownload
                              .marketValueMethod.description
                          }
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="0.5rem">
                      <Radio value={TradingStockValuationMethod.Fallback} />
                      <Box display="flex" flexDirection="column">
                        <Typography variant="Metropolis/Caption/Medium/Bold">
                          {
                            lang.report.download.tradingStockReportDownload
                              .fallbackMethod.title
                          }
                        </Typography>
                        <Typography
                          variant="Metropolis/Caption/Medium/Regular"
                          color={tokens.text.low}
                        >
                          {
                            lang.report.download.tradingStockReportDownload
                              .fallbackMethod.description
                          }
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="0.5rem">
                      <Radio value={TradingStockValuationMethod.CostBasis} />
                      <Box display="flex" flexDirection="column">
                        <Typography variant="Metropolis/Caption/Medium/Bold">
                          {
                            lang.report.download.tradingStockReportDownload
                              .costBasisMethod.title
                          }
                        </Typography>
                        <Typography
                          variant="Metropolis/Caption/Medium/Regular"
                          color={tokens.text.low}
                        >
                          {
                            lang.report.download.tradingStockReportDownload
                              .costBasisMethod.description
                          }
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </RadioGroup>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent>
        <Box display="flex" gap="1rem" justifyContent="flex-end">
          <TertiaryButton
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {lang.report.download.tradingStockReportDownload.buttons.cancel}
          </TertiaryButton>
          <TertiaryButton onClick={handleSubmit(clickDownload)}>
            <Box display="flex" gap="0.5rem" alignItems="center">
              <FileDownload />
              {
                lang.report.download.tradingStockReportDownload.buttons
                  .downloadReport
              }
            </Box>
          </TertiaryButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
