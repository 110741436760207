import { Box } from "@mui/material";

import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { StyledTooltip } from "~/components/ui/StyledTooltip";
import { useDesign } from "~/hooks/useTheme";
import { displayCurrency, displayCurrencyName } from "~/lib/index";
import { Align } from "~/types/enums";
import { type CurrencyIdentifier } from "~/types/index";

interface CurrencyCellProps {
  currencyIdentifier: CurrencyIdentifier;
  showFullName?: boolean;
  align?: Align;
  lineThrough?: boolean;
}

export function CurrencyCellContent({
  currencyIdentifier,
  showFullName,
  lineThrough,
  align = Align.Left,
}: CurrencyCellProps) {
  const { tokens } = useDesign();
  const justifyContent = align === Align.Right ? "flex-end" : "flex-start";
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={justifyContent}
      gap="0.5rem"
      sx={{
        textDecoration: lineThrough ? "line-through" : undefined,
        opacity: lineThrough ? "50%" : undefined,
      }}
    >
      <CurrencyLogo
        currency={currencyIdentifier}
        margin="0"
        width={18}
        height={18}
      />
      <StyledTooltip
        title={currencyIdentifier.name}
        placement="bottom"
        position="bottom"
        style={{ cursor: "pointer" }}
      >
        <Box color={tokens.text.default} data-uncensored="true">
          {showFullName
            ? displayCurrencyName(currencyIdentifier)
            : displayCurrency(currencyIdentifier.symbol)}
        </Box>
      </StyledTooltip>
    </Box>
  );
}
