import { TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { type DataSet } from "~/components/developer/seed-data/index";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useEditSeedDataSetMetadataMutation } from "~/state/developer";

export const EditDataSetModal = ({
  isOpen,
  handleClose,
  dataSet,
}: {
  isOpen: boolean;
  handleClose: () => void;
  dataSet: DataSet;
}) => {
  const mutation = useEditSeedDataSetMetadataMutation();

  const { control, getValues, reset } = useForm();

  const onSubmit = () => {
    const values = getValues();
    mutation
      .mutateAsync({
        dataSetId: dataSet._id,
        name: values.name || dataSet.name,
        description: values.description || dataSet.description,
      })
      .then(reset)
      .then(handleClose);
  };

  return (
    <GeneralDialog
      isOpen={isOpen}
      title="Edit metadata"
      handleClose={handleClose}
      actionText="Save"
      handleAction={() => {
        onSubmit();
      }}
      pending={mutation.isPending}
    >
      <form>
        <Controller
          control={control}
          name="name"
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              type="text"
              label="Name"
              defaultValue={dataSet.name}
              InputLabelProps={{ required: true }}
              error={invalid}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          rules={{ required: "Description is required" }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              type="text"
              label="Description"
              defaultValue={dataSet.description}
              InputLabelProps={{ required: true }}
              error={invalid}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </form>
    </GeneralDialog>
  );
};
