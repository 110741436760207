import { Divider, Stack } from "@mui/material";

import { getTrimmedStack } from "~/components/imports-v2/helpers";
import { ActionRowHoverStyled } from "~/components/transactions/action-row/Overflower";
import { DetailsOverflowCell } from "~/components/transactions/action-row/TransactionDetailsInfoPanel";
import {
  InlineTxFunctionName,
  InlineTxId,
  InlineTxRowText,
} from "~/components/transactions/action-row/TransactionId";
import { getIdChips, type IdChip } from "~/components/transactions/helpers";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { formatFunctionName } from "~/services/transactions";
import { type ActionRow } from "~/types/index";

const MAX_RENDERED_IDS = 2;

export function TransactionIdAndFunctionName({ row }: { row: ActionRow }) {
  const idChips = getIdChips(row);
  const functionNames = row.functionNames.map(formatFunctionName);
  const lang = useLang();

  const { shownItems: shownChips, otherItemsCount: remainingIdChips } =
    getTrimmedStack(idChips, MAX_RENDERED_IDS);

  const renderedIdChips = shownChips.map((idChip) => (
    <TxIdComponent
      idChip={idChip}
      functionNames={functionNames}
      key={idChip.id}
    />
  ));

  if (remainingIdChips !== 0) {
    const overflowChips = idChips.slice(MAX_RENDERED_IDS);
    renderedIdChips.push(
      <DetailsOverflowCell
        displayElement={
          <InlineTxRowText
            text={lang.txTable.plusMore({ count: overflowChips.length })}
            hover={false}
            stopPropagation={false}
          />
        }
        id="chips"
        stackElements={overflowChips.map((idChip) => (
          <TxIdComponent
            idChip={idChip}
            functionNames={functionNames}
            key={idChip.id}
            forceHover
          />
        ))}
      />,
    );
  }

  return (
    <Stack alignItems="flex-start" flexDirection="column">
      {renderedIdChips}
    </Stack>
  );
}

const TxIdComponent = ({
  idChip,
  functionNames,
  forceHover,
}: {
  idChip: IdChip;
  functionNames: string[];
  forceHover?: boolean;
}) => {
  const { tokens } = useDesign();

  return (
    <div>
      <Stack alignItems="center" flexDirection="row" key={idChip.id}>
        <InlineTxId id={idChip.id} link={idChip.link} forceHover={forceHover} />
        {functionNames.length ? (
          <>
            <Divider
              orientation="vertical"
              sx={{
                height: "0.813rem",
                color: tokens.border.neutral.default,
                marginRight: "0.25rem",
              }}
            />
            <ActionRowHoverStyled>
              <Stack
                direction="row"
                alignItems="center"
                gap="0.5rem"
                flexShrink={0}
              >
                {functionNames.map((functionName, index) => (
                  <InlineTxFunctionName
                    functionName={functionName}
                    key={index}
                  />
                ))}
              </Stack>
            </ActionRowHoverStyled>
          </>
        ) : null}
      </Stack>
    </div>
  );
};
