import { WarningAmber } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { rulesIntegrationsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { AppliesToBox } from "~/components/rules/RuleCard";
import { FilterProvider } from "~/components/transactions/filter-bar/FilterContext";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type RuleDTO } from "~/services/rules";
import {
  useDeleteRuleMutation,
  useDisableRuleMutation,
  useEnableRuleMutation,
} from "~/state/rules";
import { FilterOperators } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

export function RuleDeleteConfirmationModal({
  rule,
  isOpen,
  setIsOpen,
}: {
  rule: RuleDTO;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang().rules.deleteDialog;
  const deleteRuleMutation = useDeleteRuleMutation();

  const analyticsKey = rulesIntegrationsAnalyticsKey("rule");
  const captureAnalytics = useCaptureAnalytics();

  const ruleFilter: FilterQuery = {
    type: FilterOperators.And,
    rules: [
      {
        type: FilterOperators.Rule,
        value: [rule.id],
      },
    ],
  };

  return (
    <FilterProvider
      initialState={{
        filter: ruleFilter,
      }}
    >
      <GeneralDialog
        isOpen={isOpen}
        title={
          <Box display="flex" alignItems="center" gap="0.5rem">
            <WarningAmber
              sx={{ fontSize: "2rem", color: tokens.icon.danger }}
            />
            <Typography variant="Metropolis/Header/H4">{lang.title}</Typography>
          </Box>
        }
        actionText={lang.title}
        handleClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(false);
        }}
        handleAction={() => {
          deleteRuleMutation.mutate({ id: rule.id });
          setIsOpen(false);
          captureAnalytics(analyticsKey("delete"));
        }}
        critical
        stopPropagation
        maxWidth="xs"
      >
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <Box display="flex" flexDirection="column" gap="0.125rem">
            <Typography variant="Metropolis/Body/Regular">
              {lang.text.lineOne}
            </Typography>
            <Typography variant="Metropolis/Body/Regular">
              <ul style={{ paddingLeft: "1rem", margin: 0 }}>
                <li>{lang.text.listOne}</li>
                <li>{lang.text.listTwo}</li>
              </ul>
            </Typography>
          </Box>
          <AppliesToBox filter={ruleFilter} />
          <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
            {lang.text.lineTwo}
          </Typography>
        </Box>
      </GeneralDialog>
    </FilterProvider>
  );
}

export function RuleDisableConfirmationModal({
  rule,
  isOpen,
  setIsOpen,
}: {
  rule: RuleDTO;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang().rules.disableDialog;
  const disableRuleMutation = useDisableRuleMutation();

  const analyticsKey = rulesIntegrationsAnalyticsKey("rule");
  const captureAnalytics = useCaptureAnalytics();

  return (
    <GeneralDialog
      isOpen={isOpen}
      title={
        <Box display="flex" alignItems="center" gap="0.5rem">
          <WarningAmber sx={{ fontSize: "2rem", color: tokens.icon.warning }} />
          <Typography variant="Metropolis/Header/H4">{lang.title}</Typography>
        </Box>
      }
      handleClose={() => {
        setIsOpen(false);
      }}
      actionText={lang.button}
      handleAction={() => {
        disableRuleMutation.mutate({ id: rule.id });
        setIsOpen(false);
        captureAnalytics(analyticsKey("disable"));
      }}
      stopPropagation
      maxWidth="xs"
    >
      <Box display="flex" flexDirection="column" gap="0.5rem">
        <Typography variant="Metropolis/Body/Regular">
          <ul style={{ paddingLeft: "1rem", margin: 0 }}>
            <li>{lang.text.listOne}</li>
            <li>{lang.text.listTwo}</li>
            <li>{lang.text.listThree}</li>
          </ul>
        </Typography>
      </Box>
    </GeneralDialog>
  );
}

export function RuleEnableConfirmationModal({
  rule,
  isOpen,
  setIsOpen,
}: {
  rule: RuleDTO;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) {
  const lang = useLang().rules.enableDialog;
  const enableRuleMutation = useEnableRuleMutation();

  const analyticsKey = rulesIntegrationsAnalyticsKey("rule");
  const captureAnalytics = useCaptureAnalytics();

  return (
    <GeneralDialog
      isOpen={isOpen}
      title={lang.title}
      handleClose={() => {
        setIsOpen(false);
      }}
      actionText={lang.button}
      handleAction={() => {
        enableRuleMutation.mutate({ id: rule.id });
        setIsOpen(false);
        captureAnalytics(analyticsKey("enable"));
      }}
      stopPropagation
      maxWidth="xs"
    >
      <Box>
        <Typography variant="Metropolis/Body/Regular">
          {lang.text.listOne}
        </Typography>
      </Box>
    </GeneralDialog>
  );
}
