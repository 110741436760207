import { Box, Stack, Typography } from "@mui/material";
import moment from "moment-timezone";

import { ActionHeaderCell } from "~/components/transactions/action-row/ActionHeaderCell";
import { ActionRowAccount } from "~/components/transactions/action-row/ActionRowAccount";
import { ActionRowCell } from "~/components/transactions/action-row/ActionRowCell";
import { ActionRowCheckbox } from "~/components/transactions/action-row/ActionRowCheckbox";
import { ActionRowTimeStyled } from "~/components/transactions/action-row/ActionRowTime";
import { TransactionIdAndFunctionName } from "~/components/transactions/action-row/ActionRowTxId";
import { ActionTableGridArea } from "~/components/transactions/action-row/enums";
import { Overflower } from "~/components/transactions/action-row/Overflower";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { ActionTypeSelector } from "~/components/transactions/ActionTypeSelector";
import { ModalProvider } from "~/components/transactions/command-palette/ModalProvider";
import { NavControllerProvider } from "~/components/transactions/command-palette/NavController";
import { FilterSelectAll } from "~/components/transactions/filter-bar/FilterSelectAll";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { type Sort } from "~/types/enums";

export function ActionRowType({
  row,
  hover,
  isCheckboxEnabled = true,
  disablePointerEvents = false,
}: ActionRowCellProps & {
  isCheckboxEnabled?: boolean;
}) {
  return (
    <ActionRowCell
      id={ActionTableGridArea.Type}
      disablePointerEvents={disablePointerEvents}
    >
      <Stack direction="row" gap="0.5rem">
        <ActionRowCheckbox
          row={row}
          hover={hover}
          isCheckboxEnabled={isCheckboxEnabled}
        />
        <Overflower>
          <Stack direction="row" alignItems="center" gap="0.25rem">
            <ActionRowAccount row={row} />
            <NavControllerProvider>
              <ModalProvider variant="popover" popupId="category-selector">
                <ActionTypeSelector row={row} />
              </ModalProvider>
            </NavControllerProvider>
          </Stack>
        </Overflower>
      </Stack>
      <Overflower>
        <TransactionIdAndFunctionName row={row} />
      </Overflower>
    </ActionRowCell>
  );
}

export function ActionRowTypeTime({
  row,
  hover,
  isCheckboxEnabled = true,
  disablePointerEvents = false,
}: ActionRowCellProps & {
  isCheckboxEnabled?: boolean;
}) {
  const { tokens } = useDesign();
  const timezone = useTimezone();

  const date = moment.tz(row.timestamp, timezone).format("DD MMM YY");
  const time = moment.tz(row.timestamp, timezone).format("HH:mm:ss");

  return (
    <ActionRowCell
      id={ActionTableGridArea.Type}
      disablePointerEvents={disablePointerEvents}
    >
      <Stack direction="row" gap="0.5rem">
        <ActionRowCheckbox
          row={row}
          hover={hover}
          isCheckboxEnabled={isCheckboxEnabled}
        />
        <Overflower>
          <Stack direction="row" alignItems="center" gap="0.5rem">
            <ActionRowAccount row={row} />
            <NavControllerProvider>
              <ModalProvider variant="popover" popupId="category-selector">
                <ActionTypeSelector row={row} />
              </ModalProvider>
            </NavControllerProvider>
          </Stack>
        </Overflower>
      </Stack>
      <Overflower>
        <ActionRowTimeStyled>
          <Typography
            variant="IBM Plex Mono/Caption/Small/Regular"
            sx={{
              color: tokens.text.low,
            }}
          >
            {date} {time}
          </Typography>
        </ActionRowTimeStyled>
      </Overflower>
    </ActionRowCell>
  );
}

export function ActionHeaderType({
  isCheckboxEnabled,
  sort,
}: {
  isCheckboxEnabled?: boolean;
  sort?: {
    sortAscending: Sort;
    sortDescending: Sort;
  };
}) {
  const lang = useLang();
  return (
    <Box
      style={{
        gridArea: ActionTableGridArea.Type,
        display: "flex",
        gap: "0.5rem",
      }}
    >
      {isCheckboxEnabled ? (
        <ActionHeaderCell>
          <FilterSelectAll />
        </ActionHeaderCell>
      ) : null}

      <ActionHeaderCell sort={sort}>{lang.txTable.type}</ActionHeaderCell>
    </Box>
  );
}
