import { SyncStatusPlatform } from "@ctc/types";
import { Delete, OpenInNew } from "@mui/icons-material";
import {
  Box,
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import * as React from "react";
import { type Dispatch, type SetStateAction, useState } from "react";
import styled from "styled-components/macro";

import { MoreOptionsDropdown } from "~/components/imports-v2/MoreOptionsDialog";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { Chip } from "~/components/ui/Chips";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { LogoSpinner } from "~/components/ui/LogoSpinner";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import {
  useDeleteSyncMutation,
  useGetSyncsByImportId,
} from "~/state/importsV2";
import { FilterOperators } from "~/types/enums";
import { type SavedImport, type SavedImportSync } from "~/types/index";

import { useIsEmbedded } from "../../hooks/useIsEmbedded";

const TableContainerPaper = styled(Paper)`
  background: transparent;
  border: 1px solid ${(props) => props.theme.tokens.border.neutral.medium};
  max-height: 20rem;
`;

const getDate = (sync: SavedImportSync) => {
  const date = moment(sync.requestedAt);
  return date.format("MMM DD, YYYY h:mm A");
};

const getViewTxHandler = (sync: SavedImportSync, isEmbedded?: boolean) => {
  const link = getTransactionPageLink({
    state: {
      filter: {
        type: FilterOperators.And,
        rules: [
          {
            type: FilterOperators.Sync,
            value: [sync.syncId],
          },
        ],
      },
    },
  });

  return {
    handler: () =>
      window.open(link, isEmbedded ? "_self" : "_blank", "noopener,noreferrer"),
    link,
  };
};

const useSyncHistory = (
  importId: string,
  isOpen: boolean,
): { data: SavedImportSync[]; isLoading: boolean } => {
  const { data, isLoading } = useGetSyncsByImportId(importId, isOpen);

  return { data: data ?? [], isLoading };
};

export function SyncTableDialog({
  isOpen,
  setOpen,
  import: savedImport,
  name,
}: {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  import: SavedImport;
  name: string;
}) {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const { tokens } = useDesign();
  const deleteSyncMutation = useDeleteSyncMutation();
  const [syncDelete, setSyncDelete] = useState<SavedImportSync | undefined>();
  const importId = savedImport.id;
  const { data: syncs, isLoading } = useSyncHistory(importId, isOpen);

  const syncsComplete = syncs.filter(
    (sync) => sync.status === SyncStatusPlatform.Success,
  );

  if (isLoading) {
    return (
      <GeneralDialog
        maxWidth="lg"
        fullWidth
        isOpen={isOpen}
        stopPropagation
        title={lang.syncHistory.title}
        handleClose={() => {
          setOpen(false);
        }}
      >
        <LogoSpinner />
      </GeneralDialog>
    );
  }
  return (
    <>
      <GeneralDialog
        isOpen={!!syncDelete}
        title={lang.syncHistory.confirm.title}
        actionText={lang.delete}
        handleClose={() => {
          setSyncDelete(undefined);
        }}
        handleAction={() => {
          if (syncDelete) {
            deleteSyncMutation.mutateAsync(
              { id: syncDelete.syncId },
              {
                onSuccess: () => {
                  setSyncDelete(undefined);
                },
              },
            );
          }
        }}
        pending={deleteSyncMutation.isPending}
        critical
      >
        {syncDelete ? (
          <>
            <DialogContentText>
              <ul>
                <li>{getDate(syncDelete)}</li>
                <li>
                  {syncDelete.importedTxCount} {lang.syncHistory.transactions}
                </li>
              </ul>
            </DialogContentText>
            <DialogContentText>
              {lang.syncHistory.confirm.text}
            </DialogContentText>
          </>
        ) : null}
      </GeneralDialog>
      <GeneralDialog
        maxWidth="lg"
        fullWidth
        isOpen={isOpen}
        stopPropagation
        title={lang.syncHistory.title}
        handleClose={() => {
          setOpen(false);
        }}
      >
        {isLoading ? (
          <Box display="flex" justifyContent="center" padding="5rem">
            <LogoSpinner />
          </Box>
        ) : !isEmpty(syncsComplete) ? (
          <TableContainer component={TableContainerPaper}>
            <Table
              sx={{
                "& .MuiTableHead-root": {},
                "& .MuiTableBody-root": {},
                "& .MuiTableCell-root": {
                  color: tokens.text.default,
                  fontSize: "0.875rem",
                  padding: "1rem",
                  fontWeight: 500,
                  borderColor: tokens.border.neutral.medium,
                },
                "& .MuiTableCell-head": {
                  padding: "0.5rem 1rem",
                },
                "& .MuiTableRow-root th": {
                  fontSize: "0.75rem",
                  color: tokens.text.low,
                  borderColor: tokens.border.neutral.medium,
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>{lang.syncHistory.id}</TableCell>
                  <TableCell>{lang.syncHistory.name}</TableCell>
                  <TableCell>{lang.syncHistory.date}</TableCell>
                  <TableCell>{lang.syncHistory.transactionsHeader}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {syncs
                  .filter((sync) => sync.status === SyncStatusPlatform.Success)
                  .map((sync) => {
                    // Is initial is true when sync is the oldest sync for a given import ID
                    const isInitial = !syncs.some(
                      (s) =>
                        s.importId === sync.importId &&
                        s.status === SyncStatusPlatform.Success &&
                        s.requestedAt < sync.requestedAt,
                    );
                    return (
                      <TableRow
                        key={sync.syncId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell scope="row">
                          <Box display="flex" alignItems="center" gap="0.5rem">
                            {sync.syncId}
                            {sync.isHardSync ? (
                              <Chip
                                bgcolor={
                                  isInitial
                                    ? tokens.background.success.default
                                    : tokens.background.brand.default
                                }
                                color={
                                  isInitial
                                    ? tokens.text.success
                                    : tokens.text.default
                                }
                              >
                                {isInitial
                                  ? lang.syncHistory.initialSync
                                  : lang.syncHistory.hardSync}
                              </Chip>
                            ) : null}
                          </Box>
                        </TableCell>
                        <TableCell scope="row">{name}</TableCell>
                        <TableCell scope="row">{getDate(sync)}</TableCell>
                        <TableCell scope="row">
                          <Box
                            onClick={getViewTxHandler(sync, isEmbedded).handler}
                            sx={{
                              "*": {
                                verticalAlign: "middle",
                              },
                              "&:hover": {
                                cursor: "pointer",
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {sync.importedTxCount}{" "}
                            <OpenInNew
                              fontSize="inherit"
                              style={{
                                color: tokens.text.default,
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <MoreOptionsDropdown
                            options={[
                              {
                                id: "view_transactions",
                                handler: getViewTxHandler(sync, isEmbedded)
                                  .handler,
                                label: lang.syncHistory.viewTx,
                                icon: <OpenInNew fontSize="small" />,
                              },
                              {
                                id: "delete_sync",
                                handler: () => {
                                  setSyncDelete(sync);
                                },
                                label: lang.syncHistory.delete,
                                icon: <Delete fontSize="small" />,
                              },
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box>{lang.syncHistory.noneFound}</Box>
        )}
      </GeneralDialog>
    </>
  );
}
