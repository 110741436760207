import { post, put } from "~/services/core";
import {
  type KeyCredentials,
  type KeyDetails,
  type KeyUpdateDetails,
  type ObjectIdString,
  type SyncReturnPayload,
} from "~/types/index";

/**
 * Saves an API key and returns the saved key
 * @param exchangeName The exchange that the key is associated with
 * @param key The details of the API key
 */
export function saveKey(exchangeName: string, key: KeyDetails) {
  // we want to put the exchangeName in the body of the request
  const createDetails = {
    exchangeName,
    ...key,
  };
  const path = `/keys`;
  return post<KeyCredentials>(path, createDetails);
}

/**
 * Updates an API key
 * @param id The id of the key to update
 * @param updateDetails The details of the API key to update
 */
export function updateKey(id: ObjectIdString, updateDetails: KeyUpdateDetails) {
  const path = `/keys/${id}`;
  return put<KeyCredentials>(path, updateDetails);
}

/**
 * Syncs transactions associated with an API key
 * @param id The id of the API key to sync
 * @param isHardSync Whether to perform a hard sync
 */
export async function syncKey(id: ObjectIdString, isHardSync: boolean) {
  const link = `/keys/${id}/sync`;
  return post<SyncReturnPayload>(link, {
    isHardSync,
  });
}
