import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { getOptionsFromErpAccounts } from "~/components/transactions/command-palette/helpers/getOptionsFromErpAccounts";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { CashAccountTopSection } from "~/components/transactions/command-palette/views/change-cash-account/CashAccountTopSection";
import { Confirmation } from "~/components/transactions/command-palette/views/change-cash-account/Confirmation";
import { useLang } from "~/redux/lang";
import { type PartyDetails } from "~/services/transactions";
import { useErpAvailableAccountsQuery } from "~/state/erp";

export const SelectCashAccount = ({ party }: { party: PartyDetails }) => {
  const { push } = useNavController();
  const lang = useLang();
  const availableAccounts = useErpAvailableAccountsQuery().data ?? [];
  const options = getOptionsFromErpAccounts(availableAccounts);
  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangeCashAccount} />
          <CashAccountTopSection party={party} />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.changeTo}
      options={options}
      onSelection={({ erpAccount }) => {
        push(<Confirmation party={party} erpCashAccount={erpAccount} />);
      }}
    />
  );
};
