import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { type CurrencyIdentifier } from "~/types/index";

/**
 * Currency Icon for bulk edit chip section
 */
export function BulkEditCurrencyChipIcon({
  currency,
}: {
  currency: CurrencyIdentifier;
}) {
  return (
    <CurrencyLogo
      currency={currency}
      width={16}
      height={16}
      margin="0"
      style={{
        borderRadius: "100%",
        background: "transparent",
      }}
    />
  );
}
