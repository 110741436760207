export function bubbleUndefinedToTheBottom<T>(sort: (a: T, b: T) => number) {
  return (a: T | undefined, b: T | undefined) => {
    if (a === undefined && b === undefined) {
      return 0;
    }
    if (a === undefined) {
      return 1;
    }
    if (b === undefined) {
      return -1;
    }

    return sort(a, b);
  };
}
