type HashedExchangeId = string;

type UnhashedWalletBlockchainExchangeId = {
  exchange: string;
  blockchain: string | undefined;
};

export function hashExchangeId({
  exchange,
  blockchain,
}: UnhashedWalletBlockchainExchangeId): HashedExchangeId {
  const lowerCaseExchange = exchange.toLowerCase();
  return blockchain ? `${lowerCaseExchange}__${blockchain}` : lowerCaseExchange;
}

export function unhashExchangeId(
  exchangeId: HashedExchangeId,
): UnhashedWalletBlockchainExchangeId {
  const [exchange, blockchain] = exchangeId.split("__");
  return { exchange, blockchain };
}

export function isHashedExchangeId(exchangeId: HashedExchangeId): boolean {
  return exchangeId.includes("__") && exchangeId.split("__").length === 2;
}
