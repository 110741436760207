import { Trade } from "@ctc/types";
import { Box, MenuItem, Popover, Stack, TextField } from "@mui/material";
import { bindPopover } from "material-ui-popup-state/hooks";
import { useMemo, useRef, useState } from "react";
import {
  Controller,
  useFormContext,
  type UseFormReturn,
} from "react-hook-form";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import {
  TransferSmartContractWarningModal,
  useWarningSmartContractTransfer,
} from "~/components/transactions/ActionTypeSelector";
import { useCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import { useModal } from "~/components/transactions/command-palette/ModalProvider";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { ActionCategorisationModal } from "~/components/transactions/command-palette/views/recategorise/ActionCategorisationModal";
import { GroupedCategorySelector } from "~/components/transactions/command-palette/views/recategorise/GroupedCategorySelector";
import { CategoryBucketSelector } from "~/components/transactions/command-palette/views/recategorise/TopLevelTxCategory";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { useDesign } from "~/hooks/useTheme";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { ActionDefinitions } from "~/lib/tradeTypeDefinitions";
import { useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import { type PutTransactionPayload } from "~/services/actions";
import {
  isGroupedTrade,
  isOutdatedTrade,
  isTrade,
  isUncategorisedTrade,
} from "~/services/transactions";
import { GroupedTrade, GroupRecategorisationType } from "~/types/enums";
import {
  type ActionRow,
  type ActionType,
  type TransactionDetails,
} from "~/types/index";

export type TxTrade = { txId: string; trade: Trade };

export type EditActionDrawerTradeValues = {
  trade: ActionType;
  subComponentTrades: TxTrade[];
  applySts: boolean;
  createCategoryRule: boolean;
};

export const getValues = ({
  row,
}: {
  row: ActionRow;
}): EditActionDrawerTradeValues => {
  const getTradeDataForIndividualComponents = (tx: TransactionDetails) => {
    return {
      txId: tx._id,
      trade: tx.trade,
    };
  };
  return {
    trade: row.type,
    subComponentTrades: [...row.incoming, ...row.outgoing].map(
      getTradeDataForIndividualComponents,
    ),
    applySts: isUncategorisedTrade(row.type),
    createCategoryRule: false,
  };
};

export function applyUpdates<T extends EditActionDrawerTradeValues>(
  row: ActionRow,
  form: UseFormReturn<T>,
  formData: EditActionDrawerTradeValues,
  updateMap: Map<string, PutTransactionPayload>,
) {
  const { incoming, outgoing } = row;
  const { subComponentTrades = [] } = formData;

  const dirty = form.formState.dirtyFields.subComponentTrades;
  if (!dirty) return;

  const update = (id: string, update: PutTransactionPayload) => {
    if (!updateMap.has(id)) updateMap.set(id, { _id: id });
    const value = updateMap.get(id);
    updateMap.set(id, { ...value, ...update });
  };

  const process = (txUpdates: TxTrade[], txs: TransactionDetails[]) => {
    txUpdates.forEach((txUpdate) => {
      const tx = txs.find((tx) => tx._id === txUpdate.txId);
      const { trade } = txUpdate;

      // Make sure TX exists and has been changed.
      if (!tx || trade === tx.trade) return;

      update(tx._id, { trade });
    });
  };

  process(subComponentTrades, [...incoming, ...outgoing]);
}

export function useRowTradeFromForm(
  row: ActionRow,
  subComponentTrades: TxTrade[],
) {
  return useMemo(() => {
    // We want to call a trade a trade over calling it a multi-trade
    const groupRecategorisationRanking: Record<
      GroupRecategorisationType,
      number
    > = {
      [GroupRecategorisationType.TradeTransferLike]: 0,
      [GroupRecategorisationType.LiquidityLike]: 1,
      [GroupRecategorisationType.MultiCurrencyTransaction]: 2,
    };
    // Assume the group type based on the sub component types for groups, otherwise
    // just use the current row type
    const trade = isGroupedTrade(row.type)
      ? Object.values(GroupedTrade)
          .sort(
            (a, b) =>
              groupRecategorisationRanking[
                ActionDefinitions[a].groupRecategorisationType
              ] -
              groupRecategorisationRanking[
                ActionDefinitions[b].groupRecategorisationType
              ],
          )
          .find((groupType) =>
            subComponentTrades.every((subTrade) =>
              ActionDefinitions[groupType].subActionTypes.includes(
                subTrade.trade,
              ),
            ),
          ) || GroupedTrade.Uncategorised
      : subComponentTrades[0]?.trade || row.type;

    return trade;
  }, [row.type, subComponentTrades]);
}

export const EditActionTrade = ({ row }: { row: ActionRow }) => {
  const lang = useLang();
  const anchor = useRef<HTMLDivElement>(null);
  const { tokens } = useDesign();
  const { control, setValue, watch, reset } =
    useFormContext<EditActionDrawerTradeValues>();
  const [subComponentTrades] = watch(["subComponentTrades"]);
  const trade = useRowTradeFromForm(row, subComponentTrades);
  const taxSettings = useTaxSettings();
  const analyticsKey = transactionAnalyticsKey(
    "transfer-smart-contract-warning",
  );
  const captureAnalytics = useCaptureAnalytics();

  const modal = useModal();
  const { current } = useNavController();
  const { open, close } = useCommandPalette();
  const warningSmartContractTransfer = useWarningSmartContractTransfer({
    row,
  });
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [smartContractWarningData, setSmartContractWarningData] = useState<{
    account: string | undefined;
    tx: TransactionDetails | undefined;
  }>({ account: undefined, tx: undefined });

  const Icon = trade ? TradeIcons[trade as ActionType] : null;

  // Get all trades, dropdrown will filter appropriate keys.
  const rowOptions = [
    ...Object.values(GroupedTrade),
    ...Object.values(Trade),
  ] as ActionType[];

  if (!taxSettings) return null;

  const dropdownOnSubmit = (txUpdates: TxTrade[]) => {
    setValue("subComponentTrades", txUpdates, {
      shouldValidate: true,
      shouldDirty: true,
    });
    close();
  };

  const groupedOnSubmit = (txUpdates: TxTrade[]) => {
    for (const update of txUpdates) {
      const { account, tx } = warningSmartContractTransfer.isActive(
        update.txId,
        update.trade,
      );
      if (account && tx) {
        setSmartContractWarningData({ account, tx });
        warningSmartContractTransfer.setOpen(true);
        setValue("subComponentTrades", txUpdates, {
          shouldValidate: true,
          shouldDirty: true,
        });
        return;
      }
    }

    dropdownOnSubmit(txUpdates);
  };

  const root = isGroupedTrade(row.type) ? (
    <GroupedCategorySelector
      row={row}
      groupedTradeType={row.type}
      onSubmit={groupedOnSubmit}
      openMoreOptions={() => {
        close();
        setIsMoreOptionsOpen(true);
      }}
    />
  ) : (
    <CategoryBucketSelector
      row={row}
      selectedFromTrade={row.type}
      taxSettings={taxSettings}
      onSubmit={(trade) => {
        if (!trade || !isTrade(trade)) return;

        const txs = [...row.incoming, ...row.outgoing];
        const tx = txs[0];
        if (!tx) return;

        groupedOnSubmit([{ txId: tx._id, trade }]);
      }}
    />
  );

  return (
    <Controller
      control={control}
      name="trade"
      rules={{
        required: lang.editTx.mustCategory,
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <>
          <ActionCategorisationModal
            isOpen={isMoreOptionsOpen}
            onClose={() => {
              setIsMoreOptionsOpen(false);
            }}
            action={row}
            onSubmit={groupedOnSubmit}
          />
          <div ref={anchor}>
            <TransferSmartContractWarningModal
              tx={smartContractWarningData.tx}
              account={smartContractWarningData.account}
              isOpen={warningSmartContractTransfer.open}
              onChangeClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                captureAnalytics(analyticsKey("change"), {
                  account: smartContractWarningData.account,
                  tx: smartContractWarningData.tx,
                  direction:
                    smartContractWarningData.account ===
                    smartContractWarningData.tx?.from
                      ? "from"
                      : "to",
                });
                reset();
                warningSmartContractTransfer.setOpen(false);
              }}
              onIgnoreClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                captureAnalytics(analyticsKey("leave"), {
                  account: smartContractWarningData.account,
                  tx: smartContractWarningData.tx,
                  direction:
                    smartContractWarningData.account ===
                    smartContractWarningData.tx?.from
                      ? "from"
                      : "to",
                });
                warningSmartContractTransfer.setOpen(false);
                warningSmartContractTransfer.setConfirm(true);

                dropdownOnSubmit(subComponentTrades);
                close();
              }}
            />
            <Popover
              {...bindPopover(modal)}
              PaperProps={{
                sx: {
                  width: "100%",
                  maxWidth: "22.5rem",
                },
              }}
              anchorEl={anchor.current}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: -8,
                horizontal: "left",
              }}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Box
                sx={{
                  backgroundColor: tokens.elevation.medium,
                }}
              >
                {current}
              </Box>
            </Popover>
            <TextField
              {...field}
              variant="outlined"
              select
              size="small"
              label={lang.manual.trade}
              required
              fullWidth
              error={invalid}
              sx={{
                ".MuiInputBase-root": {
                  minHeight: "3.25rem",
                },
              }}
              SelectProps={{
                open: false,
                onOpen: (e) => {
                  open(root, e);
                },
              }}
              helperText={error?.message}
              autoComplete="off"
              inputProps={{
                sx: {
                  fontSize: "0.875rem",
                  backgroundColor: tokens.background.input.default,
                },
                "data-hj-allow": true,
              }}
              value={trade}
            >
              {rowOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  disabled={isOutdatedTrade(option)}
                >
                  <Stack alignItems="center" flexDirection="row" gap="0.25rem">
                    {Icon ? <Icon /> : null}
                    <span>
                      {getActionTypeName({ actionType: option, lang })}
                    </span>
                  </Stack>
                </MenuItem>
              ))}
            </TextField>
          </div>
        </>
      )}
    />
  );
};
