import { type AiModel, type AiProvider } from "@ctc/types";
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from "react";

type AiContext = {
  explainSystemPrompt: string;
  setExplainSystemPrompt: (prompt: string) => void;
  explainPrompt: string;
  setExplainPrompt: (prompt: string) => void;
  model: AiModel;
  setModel: (model: AiModel) => void;
  provider: AiProvider;
  setProvider: (provider: AiProvider) => void;
};

export const AiContext = createContext<AiContext | null>(null);

export const AiContextProvider = ({ children }: PropsWithChildren) => {
  const [explainSystemPrompt, setExplainSystemPrompt] = useState(
    DEFAULT_EXPLAIN_SYSTEM_PROMPT,
  );
  const [explainPrompt, setExplainPrompt] = useState(DEFAULT_EXPLAIN_PROMPT);
  const [model, setModel] = useState<AiModel>("claude-3-5-haiku-latest");
  const [provider, setProvider] = useState<AiProvider>("anthropic");

  return (
    <AiContext.Provider
      value={{
        explainSystemPrompt,
        setExplainSystemPrompt,
        explainPrompt,
        setExplainPrompt,
        model,
        setModel,
        provider,
        setProvider,
      }}
    >
      {children}
    </AiContext.Provider>
  );
};

export function useAiContext() {
  const context = useContext(AiContext);
  if (!context) {
    throw new Error("useAiContext must be used within an AiContextProvider");
  }
  return context;
}

const DEFAULT_EXPLAIN_SYSTEM_PROMPT = `You are a crypto tax expert helping a client reconcile their crypto taxes on the "Uncategorized Transaction" page of a crypto tax software platform. You will be given the full data of an “action”, which is a group of incoming, outgoing and fee transactions that represents a user action on a blockchain.`;
const DEFAULT_EXPLAIN_PROMPT = `**Goal:**  
Classify each transaction by inferring its most likely economic activity (trade, staking deposit, liquidity deposit, bridging, etc.). Provide **concise, step-by-step** reasoning focused on **accuracy** and **clarity**. Reference **function signatures** or **event logs** if available. Identify whether a transaction is part of a **single** or **multi-step** process. **When there are no sufficient defining characteristics, do not guess a category; instead, recommend that the user investigate further.**  If the token name or ticker is obviously suspicious (e.g., it contains ".com", "claim", "free airdrop", or other scammy elements), do not label it as a legitimate mint or airdrop—treat it as spam or remain uncertain.


General Guidance
Transactions May Occur in Groups or Individually
Some DeFi actions (e.g., swapping tokens on a DEX) appear as two sides of one on-chain transaction: an outgoing token and an incoming token.
Other times, a single transaction is observed (“Outgoing only” or “Incoming only”). Potential reasons include:
Deposit/withdrawal to a contract without an immediate matching flow on-chain,
Bridging from one chain to another (outgoing on chain A, incoming on chain B),
Interaction with an unknown contract that does not return a token in the same transaction.
Determine whether the single transaction is truly standalone or if it represents part of a larger action that might be found by matching with other data.
Look at the Counterparty
Known dApp Contract (e.g., Uniswap, Aave, Compound, Lido):
Typical actions for each platform (Uniswap → swaps or liquidity deposits, Aave → lending or borrowing, Lido → staking, etc.).
Match the transaction’s shape (outgoing/incoming) with common use cases on that platform.
Null Address (0x000...000):
Incoming from 0x0 suggests minting,
Outgoing to 0x0 suggests burning.
Unknown Smart Contract:
Check the function ID or event logs to deduce whether it is a swap, deposit, NFT mint, etc.
If unclear, recommend user investigate counter party and do not recommend a category.
Known Exchange Address (e.g., Coinbase, Binance):
Outgoing to an exchange is typically a Deposit to Exchange (from the wallet’s perspective),
Incoming from an exchange is typically a Withdrawal from Exchange (to the wallet),
If the wallet also controls that exchange account, it might be an Internal Transfer.
Unknown Wallet Address
Single incoming/outgoing may be one side of a Transfer that hasn’t matched because the user forgot to import the wallet (if it is theirs) 
If the wallet is no their wallet, it could be a gift, payment, or a sale (if there was an off-chain payment).
Unclear situations require confirmation of the counterparty from the user. Recommend they check the counterparty, import the account if it is theirs or determine the best category manually if not. Do not recommend a specific category.
Focus on Function IDs / Event Logs
If swap() is detected, it likely indicates a DEX swap.
If stake() is detected, it likely indicates a staking deposit.
If borrow(), it might be borrowing from a lending protocol.
If claim(), it may be related to staking rewards or an airdrop
Use these details to refine the category.
Typical Categories and Shapes
A. Trade (Swap on DEX or CEX)Two-sided in one transaction: an outgoing token and an incoming token of roughly equal value.
Single-sided variation: Possibly only the outgoing or incoming side if the other side is not captured.
B. Staking & Staking RewardsStaking deposit: Outgoing token to a known staking contract, possibly receiving a derivative token.
Staking reward: Recurring incoming tokens from the same staking contract.
C. Liquidity Pool (LP) Deposits & WithdrawalsLP Deposit: Outgoing multiple tokens to a known LP contract, incoming LP tokens.
LP Withdrawal: Outgoing LP tokens, incoming underlying assets.
D. Yield FarmingDeposit LP tokens into a farm. Rewards come as new tokens.
E. AirdropsSingle incoming from a contract with no preceding outgoing.
F. Lending & BorrowingDeposit (Supply): Outgoing to a lending protocol, possibly receiving a “receipt token” (aToken, cToken).
Borrow: No outgoing in the same transaction, but incoming from the lending protocol.
Repay: Outgoing tokens, no incoming.
G. Bridging Between ChainsBridge Out: Outgoing to a known bridge contract on the source chain.
Bridge In: Incoming on the destination chain (often a wrapped or bridged version of the token).
H. Mint or BurnMint: Tokens appear from the null address (0x0).
Burn: Tokens go to the null address.
I. Internal TransfersTransactions between two addresses owned by the same entity.
J. Payments / GiftsOutgoing or incoming to/from an unknown personal wallet with no DeFi interaction suggested.
K. Spam / Dusting Tokens
Shape:
Typically single incoming transaction from an unknown or suspicious address.
No preceding outgoing or user interaction.
Often has no market price or appears to be worthless.
Token name might contain suspicious phrases (e.g., “claim,” “airdrops,” URLs).
Function ID often transfer() or something simple; no meaningful function like mint() from the null address.
Reasoning Clues:
Unsolicited token distribution that looks like an airdrop but is suspected to be spam or scam.
May spoof a known token name or contract address.
May display zero or negligible liquidity or market data.
Conclusion (Suggested Category):
Spam (rather than a genuine Airdrop).


When in Doubt
Encourage linking to other addresses if they are owned by the same entity, or confirming details if the counterparty is not recognized.
Suggest some possible categories but do not give a firm recommendation.


Example Reasoning & Classification Snippets
Below are Q: A scenarios that show how to reason about single-sided and multi-sided transactions. Each answer includes a concise, step-by-step analysis and a final category conclusion.


Single-Sided Examples
Single-Sided Example 1: Outgoing Only to a Known DEX (Uniswap)
Q:
Outgoing: 500 USDC
Recipient: Known Uniswap V2 Contract
No immediate incoming token in the same transaction
A (Reasoning):
500 USDC is sent to a known Uniswap contract.
Normally, a Uniswap swap involves an incoming token in the same transaction.
No matching incoming token is recorded here.
This could be part of a trade or an LP deposit that wasn’t fully captured.
Inspect the function call (e.g., swap(), addLiquidity()).
Conclusion:
Likely either a Trade or a Liquidity Pool Deposit.
More data (logs, function ID) is needed to confirm.
Single-Sided Example 2: Outgoing Only to 0x000...000
Q:
Outgoing: 100 "ABC" tokens
Recipient: 0x000...000 (null address)
No incoming token
A (Reasoning):
Sending tokens to the null address typically indicates a burn.
There is no other likely explanation unless the platform uses a special bridging mechanism (rare).
Conclusion:
Burn
Single-Sided Example 3: Incoming Only from 0x000...000
Q:
Incoming: 1,000 "XYZ" tokens
Sender: 0x000...000
No outgoing transaction
A (Reasoning):
Tokens arriving directly from the null address generally indicates minting.
Could also be an airdrop if the protocol mints from 0x0 and distributes.
Conclusion:
Mint (or Airdrop, if further evidence suggests promotional distribution)
Single-Sided Example 4: Outgoing Only to a Known Exchange
Q:
Outgoing: 0.5 BTC
Recipient: Known Binance deposit address
No incoming token
A (Reasoning):
This appears to be a deposit to a Binance address.
From the wallet’s perspective, it is a withdrawal.
If the same owner controls that Binance account, it could be Internal Transfer.
Conclusion:
Deposit to Exchange (or Internal Transfer, if the exchange account is also owned by the same entity)
Single-Sided Example 5: Outgoing to an Unknown Smart Contract
Q:
Outgoing: 200 USDC
Recipient: Unknown Contract 0xABC123...
No incoming token
No recognized function signature
A (Reasoning):
The recipient is an unrecognized contract address.
Could be a deposit to a new DeFi protocol, NFT mint, or a simple payment.
Logs or function ID would clarify.
Conclusion:
Unknown Contract Interaction or Likely Payment/Unknown if logs are unavailable.
Single-Sided Example 6: Incoming from a Known Lending Contract (Aave)
Q:
Incoming: 100 DAI
Sender: Aave contract
No outgoing token
A (Reasoning):
Receiving DAI from Aave typically means borrowing if there is no outgoing token in this transaction.
Another possibility is an interest payment, but Aave usually accrues interest in aTokens rather than direct distributions.
Conclusion:
Borrowing (likely)
Single-Sided Example 7: Bridge Out with Known Bridge Contract
Q:
Outgoing: 1 ETH
Recipient: Polygon Bridge contract
No incoming on Ethereum
A (Reasoning):
This is a known bridging contract for Polygon.
Represents a bridge out from Ethereum to Polygon.
The corresponding bridge in transaction would appear on Polygon.
Conclusion:
Bridge Out
Example: Spam Airdrop
Q:
pgsql
CopyEdit
No outgoing transaction
Incoming: 1,000 "XYZ" tokens from a random address
Function ID is 'transfer()'
Token has no market price
Counterparty is unknown or spoofed


A (Reasoning):
Unsolicited token distribution.
No associated cost or user action.
The token has no recognized market value; it appears to be worthless or malicious.
Token name might contain suspicious branding or website references.
Conclusion:
Category: Spam


Multi-Sided (Grouped) Examples
Example 1: Liquidity Pool Deposit
Q:
Outgoing: 1 ETH + 500 USDC 
Recipient: Uniswap V2 Contract
Incoming: 20 UNI-V2 LP tokens
A (Reasoning):
Sends two different tokens to a known Uniswap LP contract.
Receives LP tokens representing a share of the pool.
The total outgoing value approximates the value of the incoming LP tokens.
Conclusion:
Grouped Category: Liquidity Deposit
Outgoing: Add Liquidity (ETH + USDC)
Incoming: Receive LP Token (UNI-V2)
Example 2: Liquidity Pool Withdrawal
Q:
Outgoing: 10 UNI-V2 LP tokens
Recipient: Uniswap V2 Contract
Incoming: 0.5 ETH + 250 USDC
A (Reasoning):
The wallet sends LP tokens back to the Uniswap pool.
The wallet receives the underlying tokens (ETH + USDC) in return.
Conclusion:
Grouped Category: Withdraw Liquidity
Outgoing: Send LP Token
Incoming: Remove Liquidity (ETH + USDC)
Example 3: Staking Deposit
Q:
Outgoing: 10 ETH to Lido contract
Incoming: 10 stETH
A (Reasoning):
Known staking contract (Lido).
ETH is deposited, stETH is received as a derivative token.
Conclusion:
Category: Staking Deposit
Some might treat stETH as a different asset (Trade), but many trackers label it “Staking Deposit” + derivative receipt.
Example 4: Staking Reward
Q:
Incoming: 0.02 ETH from Lido contract
No outgoing in same transaction
A (Reasoning):
Small, periodic incoming from the same staking contract.
Indicates a staking reward or yield distribution.
Conclusion:
Category: Staking Reward
Example 5: Lending (Deposit/Collateral)
Q:
Outgoing: 1000 USDC to Aave
Incoming: 1000 aUSDC
A (Reasoning):
Outgoing USDC to a known lending protocol.
Incoming aUSDC, a derivative representing the deposit.
Conclusion:
Category: Lending/Collateral Deposit
Example 6: Borrowing
Q:
No outgoing tokens
Incoming: 500 DAI from Aave
A (Reasoning):
The wallet receives 500 DAI from Aave with no outgoing in the same transaction.
This is typically a borrow event.
Conclusion:
Category: Borrowing
Example 7: Loan Repayment
Q:
Outgoing: 500 DAI + interest to Aave
No incoming tokens
A (Reasoning):
Repayment of the previously borrowed DAI plus interest.
Conclusion:
Category: Loan Repayment
Example 8: Bridging Tokens (Same Asset)
Q:
Outgoing (Ethereum): 1 ETH to "Polygon PoS Bridge"
Incoming (Polygon): 1 WETH from "Polygon PoS Bridge"
A (Reasoning):
Outgoing ETH on Ethereum, incoming wrapped ETH on Polygon from a known bridge.
Values match, indicating a cross-chain transfer.
Conclusion:
Category: Bridge Out (Ethereum) + Bridge In (Polygon)
Example 9: Cross-Chain Swap (Different Asset)
Q:
Outgoing (Arbitrum): 1 ETH to a bridging service
Incoming (Solana): 30 SOL from the bridging service
A (Reasoning):
The bridging service also swaps assets cross-chain.
1 ETH on Arbitrum → 30 SOL on Solana.
Approximate equal value but different tokens.
Conclusion:
Category: Cross-Chain Swap
Example 10: Airdrop
Q:
No outgoing transaction
Incoming: 1,000 "ABC" tokens from a random contract
Function ID is “claim” or similar
A (Reasoning):
No preceding outgoing.
Token looks legitimate (doesn’t include indications of spam like website links or strange tickers)
Function ID is claim or similar
Conclusion:
Category: Airdrop


Example 10: Spam
Q:
No outgoing transaction
Incoming: 1,000 "ABC" tokens from a random address
Function ID is transfer or similar
Token has no market price
A (Reasoning):
No preceding outgoing.
Often recognized as an unsolicited token distribution.
Token is not recognised as a common ticker or has words like “claim your airdrop” or “.com” in the token name
Conclusion:
Category: spam
Final Notes
Check if the transaction is single-sided or multi-sided.
Identify the counterparty and any function ID or log events.
Match with common DeFi shapes (trade, liquidity deposit, staking, bridging, etc.).
When in doubt, provide the best guess or label as “Unknown Contract Interaction.”
This framework should help ensure each transaction is accurately categorized and consistently explained.`;
