import {
  Box,
  Skeleton,
  type SxProps,
  type Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext } from "react";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { useTLHTableRows } from "~/components/report/tax-loss-harvesting-modal/useTLHTableRows";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { devices } from "~/components/ui/theme/legacy";
import { PaywallModalType } from "~/contexts/paywall-modal-context/enums";
import { PaywallModalContext } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { TaxLossHarvestingContext } from "~/contexts/TaxLossHarvestingContext";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib/index";
import { useBestUser, useHighestRankingPlanUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Features } from "~/types/enums";

export const MIN_TLH_GAIN_FOR_SHOW_TO_USER = 10;

export function TaxLossHarvestingBanner({ sx }: { sx?: SxProps<Theme> }) {
  const { tokens } = useDesign();
  const tlhContext = useContext(TaxLossHarvestingContext);
  const paywallContext = useContext(PaywallModalContext);
  const highestRankingPlanUser = useHighestRankingPlanUser();
  const bestUser = useBestUser();
  const lang = useLang();
  const tableRows = useTLHTableRows();
  const isDesktop = useMediaQuery(devices.tablet);

  const captureAnalytics = useCaptureAnalytics();

  if (!tlhContext || !paywallContext || !highestRankingPlanUser) return null;

  const totalUnrealizedLosses = tableRows
    ? tableRows
        .filter((row) => !!row.gain && row.gain < 0)
        .reduce((acc, row) => acc + row.gain || 0, 0)
    : null;

  const isLoaded = totalUnrealizedLosses !== null;
  const hasUnrealizedLosses =
    isLoaded && Math.abs(totalUnrealizedLosses) > MIN_TLH_GAIN_FOR_SHOW_TO_USER;
  const isSubtitleHidden = isLoaded && !hasUnrealizedLosses;

  const handleClick = () => {
    const hasTLH = highestRankingPlanUser?.features[Features.TaxLossHarvesting];

    if (hasTLH) {
      tlhContext.updateIsModalOpen(true);
      captureAnalytics("tlh_modal_opened");
    } else {
      paywallContext.setSelectedModal(PaywallModalType.TaxLossHarvesting);
    }
  };

  return (
    <Box
      borderRadius="0.5rem"
      border={`1px solid ${tokens.border.brand}`}
      bgcolor={tokens.background.brand.default}
      display="flex"
      flexDirection={isDesktop || isSubtitleHidden ? "row" : "column"}
      alignItems={isSubtitleHidden ? "center" : "flex-start"}
      justifyContent="space-between"
      p="1rem"
      mt="1.5rem"
      sx={sx}
    >
      <Box>
        <Typography fontSize="1rem" fontWeight={600}>
          {lang.taxLossHarvesting.title}
        </Typography>

        {isSubtitleHidden ? null : (
          <Box
            alignItems={isDesktop ? "center" : "flex-start"}
            display="inline-flex"
            flexDirection={isDesktop ? "row" : "column"}
          >
            <Typography fontSize="0.875rem">
              {lang.taxLossHarvesting.paywallModal.title}{" "}
            </Typography>
            <Typography
              fontSize="0.875rem"
              display="inline-flex"
              marginLeft={isDesktop ? "0.35rem" : undefined}
            >
              {lang.taxLossHarvesting.paywallModal.subtitlePre}
              {!isLoaded ? (
                <Skeleton width="5rem" sx={{ margin: "0 0.35rem" }} />
              ) : hasUnrealizedLosses ? (
                <span style={{ fontWeight: 600, margin: "0 0.35rem" }}>
                  {displayFiatValue({
                    value: Math.abs(totalUnrealizedLosses),
                    localCurrency: bestUser?.localCurrency,
                    locale: bestUser?.language,
                  })}
                </span>
              ) : null}
              {lang.taxLossHarvesting.paywallModal.subtitlePost}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        alignSelf={isDesktop ? "center" : "flex-start"}
        marginTop={isDesktop ? "0" : "1rem"}
      >
        <PrimaryButton onClick={handleClick}>
          {!isLoaded || hasUnrealizedLosses
            ? lang.taxLossHarvesting.open
            : lang.taxLossHarvesting.openWhenNoLosses}
        </PrimaryButton>
      </Box>
    </Box>
  );
}
