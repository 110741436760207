import isEqual from "lodash/isEqual";

import { GroupingCriteria } from "~/lib/enums";
import {
  similarQuantity,
  similarTotalFeeExclusive,
  similarTotalFeeInclusive,
} from "~/lib/similarTotals";

type GroupingCriteriaDefinition = {
  checkFunction: (values: GroupingCheckValues) => boolean;
  sortOrder: number;
};

export type GroupingCheckValues = {
  inValue: number;
  outValue: number;
  inQuantity: number;
  outQuantity: number;
  inCurrencies: string[];
  outCurrencies: string[];
  feeValue: number;
};

export const groupingCriteriaDefinitions: Record<
  GroupingCriteria,
  GroupingCriteriaDefinition
> = {
  [GroupingCriteria.SameCurrency]: {
    checkFunction: ({ inCurrencies, outCurrencies }) =>
      isEqual(inCurrencies, outCurrencies),
    sortOrder: 1,
  },
  [GroupingCriteria.DifferentCurrency]: {
    checkFunction: ({ inCurrencies, outCurrencies }) =>
      !isEqual(inCurrencies, outCurrencies),
    sortOrder: 1,
  },
  [GroupingCriteria.SameValueFeeExclusive]: {
    checkFunction: ({ inValue, outValue }) =>
      similarTotalFeeExclusive(inValue, outValue),
    sortOrder: 3,
  },
  [GroupingCriteria.SameValueFeeInclusive]: {
    checkFunction: ({ inValue, outValue, feeValue }) =>
      similarTotalFeeInclusive(inValue, outValue, feeValue) ||
      similarTotalFeeExclusive(inValue, outValue),
    sortOrder: 3,
  },
  [GroupingCriteria.SimilarQuantity]: {
    checkFunction: ({ inQuantity, outQuantity }) =>
      similarQuantity(inQuantity, outQuantity),

    sortOrder: 2,
  },

  // Dont check these on the FE
  [GroupingCriteria.SameTimestamp]: {
    checkFunction(): boolean {
      throw new Error("Function not implemented.");
    },
    sortOrder: 0,
  },
  [GroupingCriteria.SameTxId]: {
    checkFunction(): boolean {
      throw new Error("Function not implemented.");
    },
    sortOrder: 7,
  },
  [GroupingCriteria.SimilarTimestamp]: {
    checkFunction(): boolean {
      throw new Error("Function not implemented.");
    },
    sortOrder: 0,
  },
  [GroupingCriteria.DontGroup]: {
    checkFunction(): boolean {
      throw new Error("Function not implemented.");
    },
    sortOrder: 8,
  },
  [GroupingCriteria.DifferentSource]: {
    checkFunction(): boolean {
      throw new Error("Function not implemented.");
    },
    sortOrder: 6,
  },
  [GroupingCriteria.SameFromTo]: {
    checkFunction(): boolean {
      throw new Error("Function not implemented.");
    },
    sortOrder: 5,
  },
  [GroupingCriteria.SameAccount]: {
    checkFunction(): boolean {
      throw new Error("Function not implemented.");
    },
    sortOrder: 5,
  },
  [GroupingCriteria.DifferentAssetType]: {
    checkFunction(): boolean {
      throw new Error("Function not implemented.");
    },
    sortOrder: 5,
  },
  [GroupingCriteria.FiatCurrency]: {
    checkFunction(): boolean {
      throw new Error("Function not implemented.");
    },
    sortOrder: 9,
  },
};
