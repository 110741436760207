import { type Blockchain, type Trade } from "@ctc/types";
import { DescriptionOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import type * as React from "react";
import { useState } from "react";

import { Ellipsis } from "~/components/transactions/Ellipsis";
import {
  formatDisplayAddress,
  getAddressLink,
} from "~/components/transactions/helpers";
import { ManageEntityDialog } from "~/components/transactions/ManageEntityDialog";
import {
  CensoredBox,
  CensoredTooltip,
} from "~/components/ui/CensoredComponents";
import { DisplayExchangeActionItems } from "~/components/ui/DisplayExchangeActionItems";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { useDesign } from "~/hooks/useTheme";
import { useIsAddressLike } from "~/redux/imports";
import { useLang } from "~/redux/lang";
import {
  getEntityExchangeLogoName,
  isEntityable,
  useEntityLookup,
} from "~/state/entities";
import { useGetSavedAccounts } from "~/state/importsV2";
import { Size } from "~/types/enums";
import { type ImportType, type Side } from "~/types/enums";

function TooltipWrapper({
  side,
  finalDisplayName,
  children,
}: {
  side?: Side;
  finalDisplayName: string;
  children: React.ReactElement;
}) {
  const lang = useLang();

  if (!side) return children;

  const tooltip = `${lang.txTable.txRow.prefix[side]}: ${finalDisplayName}`;

  return <CensoredTooltip title={tooltip}>{children}</CensoredTooltip>;
}

export function DisplayExchange({
  exchange, // might be an address, or an exchange name
  displayName,
  currencySymbol,
  blockchain,
  side,
  isSmartContract,
  importType,
  trade,
  enableActions = true,
  size = Size.Medium,
  color,
}: {
  exchange: string;
  displayName: string;
  currencySymbol: string;
  trade: Trade;
  blockchain?: Blockchain;
  isSmartContract?: boolean; // treat undefined as false to account for old data
  side?: Side;
  importType?: ImportType;
  enableActions?: boolean;
  size?: Size;
  color?: string;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const [isOpen, setIsOpen] = useState(false);
  const isAnAddress = !!useIsAddressLike(exchange.trim());
  const entity = useEntityLookup(exchange, blockchain);

  const textColor = color ?? tokens.text.default;

  const savedImports = useGetSavedAccounts();
  const isNameable =
    importType &&
    isEntityable(
      entity,
      exchange,
      importType,
      savedImports,
      isAnAddress,
      blockchain,
      trade,
    );

  const addressLink = getAddressLink(exchange, currencySymbol, blockchain);

  const isNicknamed =
    Boolean(entity) || (exchange !== displayName && isAnAddress); // Refers to the nickname for an imported wallet

  const finalDisplayName = entity
    ? `${entity.displayName}${isAnAddress ? ` (${exchange.trim()})` : ""} `
    : displayName.trim();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ManageEntityDialog
        isOpen={isOpen}
        handleClose={handleClose}
        address={exchange}
        blockchain={blockchain}
        isSmartContract={isSmartContract}
      />
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          {!entity && isSmartContract ? (
            <CensoredTooltip title={lang.txTable.txRow.smartContract}>
              <CensoredBox display="flex" alignItems="center">
                <DescriptionOutlined
                  style={{
                    width: 16,
                    height: 16,
                    margin: "0.5rem 0.5rem 0.5rem 0",
                    color: tokens.icon.default,
                  }}
                />
              </CensoredBox>
            </CensoredTooltip>
          ) : (
            ""
          )}
          <TooltipWrapper side={side} finalDisplayName={finalDisplayName}>
            <CensoredBox display="flex" alignItems="center">
              {!entity && isSmartContract ? (
                ""
              ) : (
                <ExchangeLogo
                  name={getEntityExchangeLogoName(entity) ?? exchange}
                  blockchain={blockchain}
                  showBlockchainSymbol={Boolean(blockchain)}
                  currencySymbol={currencySymbol}
                  width={16}
                  height={16}
                  margin={
                    Size.Small ? "0 0.5rem 0 0" : "0.5rem 0.5rem 0.5rem 0"
                  }
                />
              )}
              <Ellipsis maxWidth={150} disableMargin={isAnAddress}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: size === "small" ? "0.75rem" : "0.875rem",
                    color: textColor,
                    cursor: isNameable ? "pointer" : "inherit",
                    textDecoration: isNameable ? "underline dashed" : 0,
                    textUnderlineOffset: "2px",
                  }}
                  onClick={(e) => {
                    if (isNameable) {
                      e.stopPropagation();
                      handleOpen();
                    }
                  }}
                >
                  {formatDisplayAddress(
                    finalDisplayName,
                    isNicknamed,
                    isAnAddress,
                  )}
                </Typography>
              </Ellipsis>
            </CensoredBox>
          </TooltipWrapper>
          {enableActions && isAnAddress ? (
            <Box ml="0.5rem">
              <DisplayExchangeActionItems
                exchange={exchange}
                addressLink={addressLink}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
}
