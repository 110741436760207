export const FORM_IMPORT_SUBMITTED_EVENT = "form import submitted";

export const UNBOUNCE_SCRIPT_URL =
  "https://d3pkntwtp2ukl5.cloudfront.net/uba.js";

// https://cryptotaxcalculatorco.slack.com/archives/C04N4KJC0Q6/p1677627106561609
export const postSignUpUnbounceAnalytics = () => {
  window._ubaq = [["trackGoal", "convert"]];

  const unbounceScript = document.createElement("script");
  unbounceScript.type = "text/javascript";
  unbounceScript.async = true;
  unbounceScript.src = UNBOUNCE_SCRIPT_URL;

  const firstScript = document.getElementsByTagName("script")[0];
  firstScript?.parentNode?.insertBefore(unbounceScript, firstScript);
};
