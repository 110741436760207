import { SyncStatusPlatform } from "@ctc/types";
import {
  type QueryClient,
  type QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import isNil from "lodash/isNil";
import { useEffect } from "react";

import { displayMessage } from "~/components/ui/Toaster";
import { useCanAccessFeature } from "~/redux/auth";
import { HttpError } from "~/services/core";
import * as ruleService from "~/services/rules";
import { type RuleBulkOperation, type RuleDTO } from "~/services/rules";
import { Scope } from "~/state/enums";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import { useSetSyncMutation } from "~/state/sync";
import { DisplayMessage, Features, FilterOperators } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

export const ruleKeys = {
  all: () => ["rules"] as const,

  lists: () => [...ruleKeys.all(), "lists"] as const,
  enabled: () => [...ruleKeys.lists(), "enabled"] as const,
  disabled: () => [...ruleKeys.lists(), "disabled"] as const,
  rule: (id: string) => [...ruleKeys.all(), id] as const,
};

async function getEnabledRules() {
  const res = await ruleService.getRules({ disabled: false });
  if (res.error) {
    displayMessage({
      message: res.msg ?? "",
      type: DisplayMessage.Error,
    });
    throw new HttpError(res, ["getEnabledRules"]);
  }
  return res.data;
}

export const useGetEnabledRulesQuery = () => {
  const isRulesEnabled = useCanAccessFeature(Features.Rules);

  return useQuery({
    queryKey: ruleKeys.enabled(),
    queryFn: getEnabledRules,
    enabled: isRulesEnabled,
  });
};

export const useGetDisabledRulesQuery = () => {
  const isRulesEnabled = useCanAccessFeature(Features.Rules);

  return useQuery({
    queryKey: ruleKeys.disabled(),
    queryFn: async () => {
      const res = await ruleService.getRules({ disabled: true });
      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["getDisabledRules"]);
      }
      return res.data;
    },
    enabled: isRulesEnabled,
  });
};

/**
 * Use the hook where you can, only use this if you need it async
 * @param queryClient Get from useQueryClient if possible
 * @returns
 */
export const fetchEnabledRulesQuery = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}) => {
  return queryClient.fetchQuery({
    queryKey: ruleKeys.enabled(),
    queryFn: () => {
      return getEnabledRules();
    },
  });
};

export const useCreateNewRuleMutation = () => {
  const queryClient = useQueryClient();
  const setSync = useSetSyncMutation();

  return useMutation({
    mutationFn: async ({
      name,
      filter,
      operations,
    }: {
      name: string;
      filter: FilterQuery;
      operations: RuleBulkOperation[];
    }) => {
      const res = await ruleService.createNewRule({
        name,
        filter,
        operations,
      });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["createNewRule"]);
      }

      return res.data;
    },
    onMutate: async (variables: {
      name: string;
      filter: FilterQuery;
      operations: RuleBulkOperation[];
    }) => {
      const { name, filter, operations } = variables;
      const previous = queryClient.getQueryData<RuleDTO[]>(ruleKeys.enabled());

      // Cancel outgoing refetch (so they don't overwrite optimistic update).
      await queryClient.cancelQueries({ queryKey: ruleKeys.enabled() });

      queryClient.setQueryData<RuleDTO[]>(ruleKeys.enabled(), (old) => {
        if (!old) return old;

        const newRule: RuleDTO = {
          id: "-1",
          name,
          filter,
          operations,
          isDisabled: false,
        };

        return [...old, newRule];
      });

      return { previous };
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(ruleKeys.enabled(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      setSync.mutate({
        scope: Scope.Rules,
        status: SyncStatusPlatform.Pending,
      });
      return queryClient.invalidateQueries({ queryKey: ruleKeys.all() });
    },
  });
};

async function removeRowMutation(
  id: string,
  key: QueryKey,
  queryClient: QueryClient,
) {
  const previous = queryClient.getQueryData<RuleDTO[]>(key);

  // Cancel outgoing refetch (so they don't overwrite optimistic update).
  await queryClient.cancelQueries({ queryKey: key });

  queryClient.setQueryData<RuleDTO[]>(key, (old) => {
    if (!old) return old;

    return old.filter((rule) => rule.id !== id);
  });

  return { previous };
}

export const useDeleteRuleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const res = await ruleService.deleteRule({ id });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["deleteRule"]);
      }

      return res.data;
    },
    onMutate: async (variables: { id: string }) => {
      return removeRowMutation(variables.id, ruleKeys.enabled(), queryClient);
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(ruleKeys.enabled(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ruleKeys.all() });
    },
  });
};

export const useDisableRuleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const res = await ruleService.updateRule({
        id,
        update: { isDisabled: true },
      });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["disableRule"]);
      }

      return res.data;
    },
    onMutate: async (variables: { id: string }) => {
      return removeRowMutation(variables.id, ruleKeys.enabled(), queryClient);
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(ruleKeys.enabled(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ruleKeys.all() });
    },
  });
};

export const useEnableRuleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const res = await ruleService.updateRule({
        id,
        update: { isDisabled: false },
      });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["enableRule"]);
      }

      return res.data;
    },
    onMutate: async (variables: { id: string }) => {
      return removeRowMutation(variables.id, ruleKeys.disabled(), queryClient);
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(ruleKeys.disabled(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ruleKeys.all() });
    },
  });
};

export function useReorderRuleMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, newIndex }: { id: string; newIndex: number }) => {
      const res = await ruleService.reorderRule({ id, newIndex });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["reorderRule"]);
      }

      return res.data;
    },
    onMutate: async (variables: { id: string; newIndex: number }) => {
      const { id, newIndex } = variables;
      const previous = queryClient.getQueryData<RuleDTO[]>(ruleKeys.enabled());

      // Cancel outgoing refetch (so they don't overwrite optimistic update).
      await queryClient.cancelQueries({ queryKey: ruleKeys.enabled() });

      queryClient.setQueryData<RuleDTO[]>(ruleKeys.enabled(), (old) => {
        if (!old) return old;

        const rule = old.find((rule) => rule.id === id);
        if (!rule) return old;

        const newRules = old.filter((rule) => rule.id !== id);
        newRules.splice(newIndex, 0, rule);

        return newRules;
      });

      return { previous };
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(ruleKeys.enabled(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ruleKeys.all() });
    },
  });
}

function checkFilter(filter: FilterQuery, searchString: string): boolean {
  switch (filter.type) {
    case FilterOperators.To:
    case FilterOperators.From:
    case FilterOperators.Source:
      return checkFilterValue(filter, searchString);
    case FilterOperators.And:
    case FilterOperators.Or:
      return filter.rules.some((rule) => checkFilter(rule, searchString));
    case FilterOperators.Not:
      return checkFilter(filter.rule, searchString);
    // Add cases for other filter types as needed
    default:
      return false;
  }
}

function checkFilterValue<T extends { value: string[] }>(
  filter: T,
  searchString: string,
): boolean {
  return filter.value.some((value) => value.includes(searchString));
}

export const useRulesForEntity = ({ id }: { id: string }) => {
  const { data } = useGetEnabledRulesQuery();

  if (!data) return [];

  return data.filter((rule) => {
    const { filter } = rule;
    if (!filter) return false;

    return checkFilter(filter, id);
  });
};

export const useRenameRuleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, newName }: { id: string; newName: string }) => {
      const res = await ruleService.updateRule({
        id,
        update: { name: newName },
      });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["renameRule"]);
      }

      return res.data;
    },
    onMutate: async (variables: { id: string; newName: string }) => {
      const { id, newName } = variables;
      const previous = queryClient.getQueryData<RuleDTO[]>(ruleKeys.enabled());

      // Cancel outgoing refetch (so they don't overwrite optimistic update).
      await queryClient.cancelQueries({ queryKey: ruleKeys.enabled() });

      queryClient.setQueryData<RuleDTO[]>(ruleKeys.enabled(), (old) => {
        if (!old) return old;

        return old.map((rule) => {
          if (rule.id === id) {
            return {
              ...rule,
              name: newName,
            };
          }
          return rule;
        });
      });

      return { previous };
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(ruleKeys.enabled(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ruleKeys.all() });
    },
  });
};

async function getRulesByIds(ids: string[]) {
  const res = await ruleService.getRulesByIds({ ids });
  if (res.error) {
    displayMessage({
      message: res.msg ?? "",
      type: DisplayMessage.Error,
    });
    throw new HttpError(res, ["getRuleByIds"]);
  }
  return res.data;
}

export const useGetRulesByIdsQuery = (ids: string[]) => {
  const queryClient = useQueryClient();
  const keys = ids.join(",");

  // For each id in ids, check if there is a query key that doesn't have data
  // for it
  const allIdsAlreadyFetched = ids.every(
    (id) => !isNil(queryClient.getQueryData(ruleKeys.rule(id))),
  );

  const query = useQuery({
    queryKey: ruleKeys.rule(keys),
    queryFn: () => getRulesByIds(ids),
    enabled: ids.length > 0 && !allIdsAlreadyFetched,
  });

  useEffect(() => {
    // Update cache for each ID
    if (query.data && Array.isArray(query.data)) {
      ids.forEach((id) => {
        const key = ruleKeys.rule(id);
        // Invalidate the existing cache for the key
        queryClient.invalidateQueries({ queryKey: key });

        // Update the cache with the new data
        queryClient.setQueryData(
          key,
          query.data.filter((rule) => rule.id === id),
        );
      });
    }
  }, [query.data, ids, queryClient]);

  return query;
};

export function useEditRuleMutation() {
  const queryClient = useQueryClient();
  const setSync = useSetSyncMutation();

  return useMutation({
    mutationFn: async ({
      id,
      name,
      filter,
      operations,
    }: {
      id: string;
      name: string;
      filter: FilterQuery;
      operations: RuleBulkOperation[];
    }) => {
      const res = await ruleService.editRule({
        id,
        name,
        filter,
        operations,
      });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["editRule"]);
      }

      return res.data;
    },
    onMutate: async (variables: {
      id: string;
      name: string;
      filter: FilterQuery;
      operations: RuleBulkOperation[];
    }) => {
      const { id, name, filter, operations } = variables;
      const previous = queryClient.getQueryData<RuleDTO[]>(ruleKeys.enabled());

      // Cancel outgoing refetch (so they don't overwrite optimistic update).
      await queryClient.cancelQueries({ queryKey: ruleKeys.enabled() });

      queryClient.setQueryData<RuleDTO[]>(ruleKeys.enabled(), (old) => {
        if (!old) return old;

        const newRule: RuleDTO = {
          id,
          name,
          filter,
          operations,
          isDisabled: false,
        };

        return old.map((rule) => {
          if (rule.id === id) {
            return newRule;
          }
          return rule;
        });
      });

      return { previous };
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(ruleKeys.enabled(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess: () => {
      setSync.mutate({
        scope: Scope.Rules,
        status: SyncStatusPlatform.Pending,
      });
      return queryClient.invalidateQueries({ queryKey: ruleKeys.all() });
    },
  });
}
