import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Stack,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { StringParam, useQueryParam } from "use-query-params";

import { useTour } from "~/components/onboarding-v2/Tour";
import { getActionRowHasTransactionId } from "~/components/reconciliation/views/UncategorisedTransactionsInContext";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { Calco } from "~/components/ui/calco/Calco";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type ActionRow } from "~/types/index";

// Function to recursively search for a button or anchor tag
const findButtonOrAnchor = (
  element: Element,
): HTMLAnchorElement | HTMLButtonElement | undefined => {
  if (!element) {
    return undefined;
  }
  if (element.nodeName === "BUTTON" || element.nodeName === "A") {
    return element as HTMLAnchorElement | HTMLButtonElement;
  }
  let target: HTMLAnchorElement | HTMLButtonElement | undefined;
  Array.from(element.children).some((child) => {
    target = findButtonOrAnchor(child);
    return !!target; // Stop searching if found
  });
  return target;
};

export function UncategorisedTableCalcoTour({
  row,
  title,
  stepNumber,
  children,
  placement,
}: {
  title: string;
  stepNumber: number;
  row: ActionRow;
  children: React.ReactElement;
  placement: TooltipProps["placement"];
}) {
  const containerRef = useRef<HTMLElement | null>(null); // Ref for the container of children

  const lang = useLang();
  const [highlight] = useQueryParam("highlight", StringParam);
  const isHighlightedRow = getActionRowHasTransactionId(row, highlight ?? "");
  const { tokens } = useDesign();
  const {
    getNextProps,
    getPrevProps,
    getRootProps,
    isVisible,
    finishTour,
    atEnd,
    setFinishTour,
  } = useTour(stepNumber);

  useEffect(() => {
    const current = containerRef.current;
    if (!current) {
      return;
    }
    if (!atEnd) {
      return;
    }
    // This is very hard coded
    // At the very last tour steps, it finds the first button or anchor
    // in the children, and when that button is clicked, it ends the tour
    const elem = findButtonOrAnchor(current);
    const endTheTour = () => {
      setFinishTour(true);
    };
    elem?.addEventListener("click", endTheTour);
    return () => {
      removeEventListener("click", endTheTour);
    };
  }, [atEnd, setFinishTour]);

  const tooltipBox = (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        padding="0.5rem"
      >
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.high}
          sx={{
            wordBreak: "break-word",
          }}
        >
          {title}
        </Typography>
        <Box
          position="relative"
          sx={{
            right: "-.03rem",
            top: "-1.2rem",
          }}
        >
          <Calco type="OG2" height="65px" width="57px" />
        </Box>
      </Stack>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        padding="0.5rem"
        sx={{
          background: tokens.background.accent.neutral.medium,
          borderTop: `1px solid ${tokens.border.neutral.highest}`,
          borderRadius: "0 0 0.5rem 0.5rem",
        }}
      >
        <TextButton
          startIcon={<ArrowBackIcon />}
          sx={{ height: "1.75rem" }}
          {...getPrevProps()}
        >
          {lang.calcoTour.buttons.back}
        </TextButton>
        <PrimaryButton
          endIcon={atEnd ? null : <ArrowForwardIcon />}
          sx={{ height: "1.75rem" }}
          {...getNextProps()}
        >
          {atEnd
            ? lang.calcoTour.reconciliation.uncategorise.CTA
            : lang.calcoTour.buttons.next}
        </PrimaryButton>
      </Stack>
    </Box>
  );

  // Check if the row is highlighted
  if (!isHighlightedRow) return <>{children}</>;

  return (
    <StyledTooltip
      title={tooltipBox}
      open={isVisible && !finishTour}
      arrow
      {...getRootProps()}
      placement={placement}
    >
      <span
        style={
          finishTour
            ? {}
            : {
                transition: "all 0.25s",
                borderRadius: "0.25rem",
                padding: "0.1rem 0.25rem",
                pointerEvents: "none",
                display: "block",
                ...(isVisible
                  ? {
                      border: `1px solid ${tokens.border.brand}`,
                      pointerEvents: atEnd ? "inherit" : "none",
                    }
                  : {}),
              }
        }
        ref={containerRef}
      >
        {children}
      </span>
    </StyledTooltip>
  );
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .${tooltipClasses.tooltip} {
    padding: 0;
    background-color: ${({ theme }) => theme.tokens.elevation.highest};
    width: 18.1875rem;
    border: 1px solid;
    border-color: ${({ theme }) => theme.tokens.border.brand};
    border-radius: 0.5rem;
  }

  & .${tooltipClasses.arrow} {
    &:before {
      border: 1px solid;
      border-color: ${({ theme }) => theme.tokens.border.brand};
      background-color: ${({ theme }) => theme.tokens.elevation.highest};
    }
  }
`;
