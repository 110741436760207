import { Logout } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import styled from "styled-components/macro";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { AccountMenu } from "~/components/nav/AccountMenu";
import { HelpItem } from "~/components/nav/HelpItem";
import { type ActionItemDetails } from "~/components/nav/NavBar";
import { AccountList } from "~/components/onboarding-v2/ui/AccountList";
import { ProgressAutoSavedBadge } from "~/components/onboarding-v2/ui/ProgressAutoSavedBadge";
import { useIsMobile } from "~/components/ui/hooks";
import { sizes } from "~/components/ui/theme/legacy";
import { useDesign } from "~/hooks/useTheme";
import { useLogout } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Align } from "~/types/enums";

export const OnboardingAccountMenu = ({
  hideAccountList,
}: {
  hideAccountList?: boolean;
}) => {
  const isMobile = useIsMobile();
  const { tokens } = useDesign();
  const lang = useLang();
  const captureAnalytics = useCaptureAnalytics();
  const logout = useLogout();
  const logoutItem: ActionItemDetails = {
    name: lang.nav.logout,
    action: () => {
      logout();
      captureAnalytics("logout_clicked");
    },
    icon: <Logout fontSize="small" />,
  };

  return (
    <MenuStack>
      {isMobile ? (
        <LeftBox>{!hideAccountList && <AccountList />}</LeftBox>
      ) : null}
      <RightBox>
        <ProgressAutoSavedBadge />
        <HelpItem />
        {!hideAccountList && (
          <AccountMenu
            align={Align.Left}
            accountItems={[]}
            logoutItem={logoutItem}
          />
        )}
      </RightBox>
    </MenuStack>
  );
};

const MenuStack = styled(Stack)`
  background-color: inherit;
  gap: 0.5rem;
  border-bottom: none;
  flex-direction: row;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  left: auto;
  width: auto;
  z-index: 1001;
  padding: 0;

  @media (max-width: ${sizes.tabletS}) {
    background-color: ${({ theme }) => theme.tokens.elevation.low};
    border-bottom: 1px solid
      ${({ theme }) => theme.tokens.border.neutral.default};
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem 1rem;
  }
`;

const LeftBox = styled(Box)`
  justify-content: flex-start;
`;

const RightBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: auto;
`;
