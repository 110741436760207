export enum Impact {
  High = "high",
  Medium = "medium",
  Low = "low",
  Neutral = "neutral",
}
export enum IssueCountChipVariation {
  Badge = "badge",
  Chip = "chip",
  EmbeddedChip = "embeddedChip",
}
export enum FlexDirection {
  Column = "column",
  Inherit = "inherit",
  MozInternal = "-moz-initial",
  Initial = "initial",
  Revert = "revert",
  Unset = "unset",
  ColumnReverse = "column-reverse",
  Row = "row",
  RowReverse = "row-reverse",
}
export enum BannerSeverity {
  Info = "info",
  Notice = "notice",
  Warning = "warning",
  Error = "error",
}
export enum BannerVariant {
  List = "list",
  Row = "row",
}
export enum Term {
  Long = "long",
  Short = "short",
}
export enum CapitalGainsCategories {
  All = "all",
  ShortTermGains = "shortTermCapitalGains",
  LongTermGains = "longTermCapitalGains",
  ShortTermLosses = "shortTermCapitalLosses",
  LongTermLosses = "longTermCapitalLosses",
  Losses = "totalLosses",
  Gains = "gains",
}
export enum TradingStockValuationMethod {
  Fallback = "fallback",
  CostBasis = "costBasis",
  MarketValue = "marketValue",
}
export enum TurboTaxInformationalDialogType {
  Desktop = "desktop",
  Online = "online",
}
