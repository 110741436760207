import { Box, TextField, Typography } from "@mui/material";
import type * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Spinner } from "~/components/ui/Spinner";
import { StyledNoLineLink } from "~/components/ui/StyledLink";

import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { passwordChecker } from "~/lib/passwordChecker";
import { reset, useAuth } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

export const ResetPasswordForm: React.FC = () => {
  const lang = useLang();
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(useLocation().search);
  const token = urlParams.get("token") || "";
  const invitation = urlParams.get("invitation") || "";
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { loading } = useAuth();

  if (!token) {
    navigate("/login");
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validPassword = passwordChecker(password);
    if (validPassword.error) {
      setPasswordError(lang.password[validPassword.msg]);
      return;
    }
    setPassword("");
    dispatch(reset({ password, token, invitation }));
    navigate("/login");
  };
  const actionButtonText = invitation ? "Set Password" : "Reset Password";
  return (
    <Box sx={{ maxWidth: "100%", width: "30rem" }}>
      {loading && <Spinner />}
      <form style={{}} onSubmit={handleSubmit}>
        <TextField
          style={{ marginBottom: "1rem" }}
          className="fs-exclude"
          variant="outlined"
          name="password"
          type="password"
          label={lang.auth.password}
          autoComplete="new-password"
          required
          fullWidth
          error={!!passwordError}
          helperText={passwordError}
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError("");
          }}
          inputProps={{ maxLength: 64, minLength: 8 }}
          value={password}
        />
        <Box display="flex" justifyContent="flex-end">
          <PrimaryLoadingButton
            type="submit"
            loading={loading}
            disabled={loading}
            fullWidth
            style={{ height: "3.5rem" }}
          >
            {actionButtonText}
          </PrimaryLoadingButton>
        </Box>
      </form>
      <Box mt="1rem">
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          align="center"
        >
          {`${lang.auth.backTo} `}
          <StyledNoLineLink to={Links.Login}>
            {lang.auth.login}
          </StyledNoLineLink>
          {` ${lang.auth.orSwitchTo} `}
          <StyledNoLineLink to={Links.Signup}>
            {lang.auth.signup}
          </StyledNoLineLink>
        </Typography>
      </Box>
    </Box>
  );
};
