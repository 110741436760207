// SyncContext.js
import { SyncStatusPlatform } from "@ctc/types";
import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useFetchSavedAccounts } from "~/state/importsV2";
import { type SavedImportOptionByAccount } from "~/types/index";

type BulkSyncContextValue = {
  shouldBulkSync: boolean;
  selected: SavedImportOptionByAccount[];
  setSelected: (selected: SavedImportOptionByAccount[]) => void;
  isSyncing: boolean;
  showAfterSyncBanner: boolean;
  isLoadingAccounts: boolean;
  savedAccounts: SavedImportOptionByAccount[];
  handleSync: () => void;
  haveReSynced: boolean;
  syncFailed: boolean;
};

const contextDefaults: BulkSyncContextValue = {
  shouldBulkSync: false,
  selected: [],
  setSelected: () => {},
  isSyncing: false,
  showAfterSyncBanner: false,
  isLoadingAccounts: false,
  savedAccounts: [],
  handleSync: () => {},
  haveReSynced: false,
  syncFailed: false,
};

const BulkSyncContext = createContext(contextDefaults);

export const useBulkSyncContext = () => useContext(BulkSyncContext);

export const BulkSyncProvider = ({ children }: { children: ReactNode }) => {
  const { data: savedImportOptionsQuery, isLoading: isLoadingAccounts } =
    useFetchSavedAccounts();

  const savedAccounts = useMemo(() => {
    return savedImportOptionsQuery?.all || [];
  }, [savedImportOptionsQuery?.all]);
  const [shouldBulkSync, setShouldBulkSync] = useState(false);
  const [selected, setSelected] = useState<SavedImportOptionByAccount[]>([]);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [showAfterSyncBanner, setShowAfterSyncBanner] =
    useState<boolean>(false);
  const [haveReSynced, setHaveReSynced] = useState<boolean>(false);
  const [syncFailed, setSyncFailed] = useState<boolean>(false);

  useEffect(() => {
    if (haveReSynced && savedAccounts.length !== 0) {
      for (const account of savedAccounts) {
        if (account.syncStatus === SyncStatusPlatform.Fail) {
          setSyncFailed(true);
          break;
        }
      }
    }
  }, [savedAccounts, haveReSynced, isSyncing]);

  const triggerBulkSync = () => {
    setShouldBulkSync(true);
    // Reset after triggering
    setTimeout(() => {
      setShouldBulkSync(false);
      setSelected([]);
    }, 10);
  };

  const handleSync = () => {
    if (selected.length === 0) {
      return;
    }

    setIsSyncing(true);
    triggerBulkSync();
    setTimeout(() => {
      setShowAfterSyncBanner(true);
      setHaveReSynced(true);
    }, 7000);
  };

  return (
    <BulkSyncContext.Provider
      value={{
        shouldBulkSync,
        selected,
        setSelected,
        isSyncing,
        showAfterSyncBanner,
        isLoadingAccounts,
        savedAccounts,
        handleSync,
        haveReSynced,
        syncFailed,
      }}
    >
      {children}
    </BulkSyncContext.Provider>
  );
};
