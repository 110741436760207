import { Blockchain } from "@ctc/types";
import { normalize } from "viem/ens";

import { AddressError } from "~/components/imports/enums";
import {
  type ConnectWalletConfigHandler,
  type ConnectWalletConfigKey,
} from "~/components/imports/wallet/types";
import { useNameServiceNameResolve } from "~/state/wallets";

import { leatherConfig } from "./custom-connect-wallet-integrations/leather";
import { xverseConfig } from "./custom-connect-wallet-integrations/xverse";

type NameServiceConfig = {
  suffix: string;
  useResolver: (address: string) => {
    data: string | undefined | null;
    isLoading: boolean;
  };
  normalize?: (address: string) => string;
  errorType: AddressError;
};

export const nameServiceConfig: Record<string, NameServiceConfig> = {
  eth: {
    suffix: ".eth",
    useResolver: (address: string) =>
      useNameServiceNameResolve(Blockchain.ETH, address, ".eth"), // its ethereum all the way down
    normalize,
    errorType: AddressError.invalidEns,
  },
  sol: {
    suffix: ".sol",
    useResolver: (address: string) =>
      useNameServiceNameResolve(Blockchain.SolanaV2, address, ".sol"),
    errorType: AddressError.invalidSns,
  },
};

export const nameServiceNameSuffixes = Object.values(nameServiceConfig).map(
  (config) => config.suffix,
);

/**
 *  use `importOption.id` as the key for the config
 *
 *  use `ConnectWalletConfigHandler` like xverse if the appkit does not support the wallet
 */
export const walletConnectorConfig: Record<
  string,
  ConnectWalletConfigHandler | ConnectWalletConfigKey
> = {
  xverse_wallet: xverseConfig,
  metamask: "metamask",
  leather_wallet: leatherConfig,
};
