import { DateRange } from "@mui/icons-material";
import moment from "moment-timezone";

import { CommandPaletteChip } from "~/components/transactions/command-palette/CommandPaletteTitle";
import { useDateTimeMomentFormat } from "~/redux/auth";

export function TxRewindTopSection({ timestamp }: { timestamp: Date }) {
  const dateTimeFormat = useDateTimeMomentFormat();
  return (
    <CommandPaletteChip
      icon={<DateRange />}
      size="small"
      label={moment(timestamp).format(dateTimeFormat)}
    />
  );
}
