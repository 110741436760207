import { type Plan } from "@ctc/types";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { hashPlanChangeEvent } from "~/components/payment-status/helpers";
import { LocalStorageKey } from "~/constants/enums";
import { useActiveClient } from "~/hooks/useActiveClient";
import { updateActiveClient } from "~/redux/accountant";
import { loadUser, setUpdateBestActiveUser } from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import { upgradeClient } from "~/services/accountant";
import { type Data } from "~/services/core";
import { clientKeys } from "~/state/clients";
import { Links } from "~/types/enums";
import { type ClientDetails } from "~/types/index";

export function useHandlePayAndUpgrade() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useActiveClient();
  const queryClient = useQueryClient();
  const upgradeLang = useLang().accountant.upgradeClient;

  return async ({
    selectedPlan,
    setError,
    setIsPaymentProcessing,
    handleSuccess = () => {},
  }: {
    selectedPlan: Plan;
    setError: (value: string) => void;
    setIsPaymentProcessing: (value: boolean) => void;
    handleSuccess?: () => void;
  }) => {
    setIsPaymentProcessing(true);

    if (!client) return; // This is not possible via the UI, so no need to throw an error

    localStorage.setItem(
      LocalStorageKey.PlanUpgradeEvent,
      hashPlanChangeEvent({
        newPlan: selectedPlan,
        oldPlan: client.paidPlan,
      }),
    );

    const res: Data<ClientDetails> = await upgradeClient(
      client.uuid,
      selectedPlan,
    );

    if (res.error) {
      setError(res.msg || upgradeLang.payment.defaultErrorMsg);
    } else {
      dispatch(updateActiveClient(res.data));
      dispatch(setUpdateBestActiveUser({ paidPlan: res.data.paidPlan }));
      queryClient.invalidateQueries({ queryKey: clientKeys.all() });
      navigate(Links.PaymentSuccess);
      handleSuccess();
    }
    setIsPaymentProcessing(false);
    dispatch(loadUser(LoadUserType.UserUpdate));
  };
}
