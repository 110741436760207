import {
  Box,
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { DateTimePicker } from "~/components/transactions/edit/DateTimePicker";
import { Chip } from "~/components/ui/Chips";
import { useDesign } from "~/hooks/useTheme";
import { useDateTimePlaceholder } from "~/redux/auth";
import { useLang } from "~/redux/lang";

enum SelectOptions {
  Beginning = "beginning",
  Custom = "custom",
}
const RecommendedOption = SelectOptions.Beginning;

function RecommendedChip({
  isSelectedEqualToRecommended,
}: {
  isSelectedEqualToRecommended: boolean;
}) {
  const lang = useLang().imports.importFrom;
  const { tokens } = useDesign();

  return (
    <Chip
      alignItems="center"
      overflow="visible"
      bgcolor={
        isSelectedEqualToRecommended
          ? tokens.background.success.default
          : tokens.background.warning.default
      }
    >
      <Typography
        variant="Metropolis/Caption/Medium/Regular"
        textAlign="center"
        color={
          isSelectedEqualToRecommended
            ? tokens.text.success
            : tokens.text.warning
        }
      >
        {isSelectedEqualToRecommended
          ? lang.chip.recommended
          : lang.chip.notRecommended}
      </Typography>
    </Chip>
  );
}

export function ImportFromSelector({
  handleChange,
  control,
  disabled,
  importFromTime,
}: {
  handleChange: (date: string) => void;
  control: any;
  disabled: boolean;
  importFromTime?: Date;
}) {
  const lang = useLang();
  const [optionSelected, setOptionSelected] = useState(RecommendedOption);
  const importFromLang = lang.imports.importFrom;
  const timePlaceHolder = useDateTimePlaceholder();

  useEffect(() => {
    setOptionSelected(
      importFromTime ? SelectOptions.Custom : SelectOptions.Beginning,
    );
  }, [importFromTime]);

  useEffect(() => {
    if (optionSelected === SelectOptions.Beginning) {
      handleChange("");
    }
  }, [handleChange, optionSelected]);

  const isSelectedEqualToRecommended = optionSelected === RecommendedOption;
  const shouldShowRecommendedChip = !isSelectedEqualToRecommended;

  return (
    <>
      <Box display="flex" flexDirection="column" mt="0.5rem">
        <Box
          display="flex"
          flexDirection="row"
          gap="0.8rem"
          flexWrap="wrap"
          mt="0.5rem"
          alignItems="center"
          mb="0.5rem"
        >
          <Typography variant="Metropolis/Caption/Medium/Regular" textAlign="center">
            {importFromLang.selector.title}
          </Typography>

          {shouldShowRecommendedChip ? (
            <RecommendedChip
              isSelectedEqualToRecommended={isSelectedEqualToRecommended}
            />
          ) : null}
        </Box>
        <Box>
          <FormControl fullWidth>
            <Select
              fullWidth
              value={optionSelected}
              onChange={(event: SelectChangeEvent) => {
                const value = event.target.value as SelectOptions;
                setOptionSelected(value);
              }}
              disabled={disabled}
            >
              <MenuItem value={SelectOptions.Beginning}>
                {importFromLang.selector.beginning}
              </MenuItem>
              <MenuItem value={SelectOptions.Custom}>
                {importFromLang.selector.custom}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Controller
        control={control}
        name="importFromTimeMillis"
        rules={{
          required:
            optionSelected == SelectOptions.Custom
              ? `${lang.manual.requiredText.time({
                  timeFormat: timePlaceHolder,
                })}`
              : false,
        }}
        render={({
          field: { value, onBlur },
          fieldState: { invalid, error },
        }) => {
          const parsedValue = value ? moment(value)?.toISOString() || "" : "";
          const parsedDefaultValue = importFromTime
            ? moment(importFromTime)?.toISOString()
            : "";
          const selectedValue = disabled ? parsedDefaultValue : parsedValue;
          return (
            <FormControl fullWidth error={invalid}>
              {optionSelected === SelectOptions.Custom && (
                <Box mt="0.5rem">
                  <DateTimePicker
                    onBlur={onBlur}
                    label=""
                    value={selectedValue}
                    handleChange={handleChange}
                    helperText={error?.message}
                    error={invalid}
                    inputProps={{ "data-hj-allow": true }}
                    size="medium"
                    required
                    disabled={disabled}
                  />
                </Box>
              )}
            </FormControl>
          );
        }}
      />
    </>
  );
}
