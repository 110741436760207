import { CurrencySourcePlatform } from "@ctc/types";
import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import partition from "lodash/partition";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LedgerActionRow } from "~/components/transactions/action-row/actionRow";
import { LedgerRowHeader } from "~/components/transactions/action-row/ActionRowHeader";
import { CurrencyComboBox } from "~/components/transactions/CurrencyComboBox";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  CheckboxActionType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { FilterDate } from "~/components/transactions/filter-bar/FilterDate";
import { FilterPagination } from "~/components/transactions/filter-bar/FilterPagination";
import { FilterReset } from "~/components/transactions/filter-bar/FilterReset";
import { FilterAutocomplete } from "~/components/transactions/filter-bar/FilterSearch";
import { TransactionsTableWithFilters } from "~/components/transactions/TransactionsTableWithFilters";
import { devices } from "~/components/ui/theme/legacy";
import { useDesign } from "~/hooks/useTheme";
import { type Translation } from "~/lang/index";
import { hashExchangeId } from "~/lib/hashExchangeId";
import { displayCryptoQuantity, displayCurrency } from "~/lib/index";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { useGetActionsQuery, useGetFilterOptionsQuery } from "~/state/actions";
import {
  useGetLedgerDataQuery,
  useGetLedgerFilterOptions,
} from "~/state/ledger";
import { Align, FilterOperators, Links, Size } from "~/types/enums";
import {
  type ActionRow,
  type CurrencyIdentifier,
  type FilterQuery,
  type SourceFilterOption,
} from "~/types/index";

export function ActionBalanceLedger({
  ledgerCurrency,
}: {
  ledgerCurrency: CurrencyIdentifier;
}) {
  const lang = useLang();
  const navigate = useNavigate();
  const { tokens } = useDesign();
  const theme = useTheme();
  const { dispatch, state } = useTransactionFilter();
  const { dispatch: checkboxDispatch } = useTransactionCheckbox();
  const [negativeBalancesOnly, setNegativeBalancesOnly] = useState(false);
  // Sources, eg "binance" or source _id like "62b357234aed234897"
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const languagePreference = useLanguagePreference();

  const txData = useGetActionsQuery();
  const filterOptions = useGetFilterOptionsQuery();
  const ledgerData = useGetLedgerDataQuery(state.filter, ledgerCurrency);
  const ledgerAccounts = useGetLedgerFilterOptions(ledgerCurrency);

  const restrictedFilterOptions = useMemo(
    () =>
      getAccountFilterOptions(
        filterOptions.data?.source ?? [],
        ledgerAccounts.data ?? [],
      ),
    [filterOptions.data?.source, ledgerAccounts.data],
  );

  function setQuery(query: FilterQuery) {
    dispatch({
      type: FilterActionType.SetFilter,
      filter: query,
    });
    checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
  }

  const setFilters = (negative: boolean, sources?: string[]) => {
    const rules: FilterQuery[] = negative
      ? [
          {
            type: FilterOperators.NegativeBalance,
            value: ledgerCurrency.id,
          },
        ]
      : [
          {
            type: FilterOperators.Currency,
            value: [ledgerCurrency.id],
          },
        ];

    if (sources?.length) {
      rules.push({
        type: FilterOperators.Source,
        value: sources,
      });
    }

    setQuery({
      type: FilterOperators.And,
      rules,
    });
  };

  const handleToggleNegativeBalances = () => {
    setNegativeBalancesOnly(!negativeBalancesOnly);
    setFilters(!negativeBalancesOnly, selectedSources);
  };

  const handleReset = () => {
    setSelectedSources([]);
    setNegativeBalancesOnly(false);
    setQuery({
      type: FilterOperators.And,
      rules: [
        {
          type: FilterOperators.Currency,
          value: [ledgerCurrency.id],
        },
      ],
    });
  };

  const isTablet = useMediaQuery(devices.tablet);

  const showToggle =
    ledgerData.data?.amountMissing !== undefined &&
    ledgerData.data?.amountMissing !== 0;

  const filterOptionsQuery = useGetFilterOptionsQuery();

  // Should always be an active currency filter applied, if the rules
  // length is greater, another user applied filter has been added.
  const isFiltersActive =
    state.filter?.type === FilterOperators.And
      ? state.filter.rules.length > 1
      : false;

  const [fiatOptions, cryptoOptions] = partition(
    filterOptionsQuery.data?.currency || [],
    (option) => option.source === CurrencySourcePlatform.Fiat,
  );

  const createRow = useCallback(
    ({ row }: { row: ActionRow }) => (
      <LedgerActionRow row={row} currencyIdentifier={ledgerCurrency} />
    ),
    [ledgerCurrency],
  );

  return (
    <Stack direction="column" gap="1rem" sx={{ marginBottom: "3.125rem" }}>
      <Box mb="0.75rem">
        {filterOptionsQuery.isSuccess ? (
          <Stack direction="row" spacing="1rem" alignItems="center">
            <Typography
              sx={{ fontSize: "2rem", fontWeight: 600, marginTop: "0.125rem" }}
            >
              {lang.ledger.ledger}:
            </Typography>
            <Box style={{ minWidth: "8.5rem" }}>
              <CurrencyComboBox
                expandOnFocus
                id="currency"
                size="small"
                value={ledgerCurrency}
                sx={{ paddingRight: "2.5rem !important" }}
                autocomplete={{ freeSolo: false, clearIcon: true }}
                setValue={(currency) => {
                  if (!currency) return;
                  const url = [Links.Ledger, currency.id].join("/");
                  navigate(url);
                }}
                options={[...fiatOptions, ...cryptoOptions]
                  .filter((option) => !option.isUserMarkedSpam)
                  .map((option) => {
                    const { contractAddress, nftId, ...rest } = option;
                    return rest;
                  })}
                inputProps={{
                  "data-hj-allow": true,
                  style: { fontSize: "0.875rem" },
                }}
              />
            </Box>
            {ledgerData.isSuccess ? (
              <Box
                style={{
                  padding: "0.5rem 0.75rem",
                  background: tokens.background.neutral.default,
                  borderRadius: "4px",
                }}
              >
                <Typography variant="Metropolis/Body/Bold" style={{ fontSize: "1rem" }}>
                  {txData.data?.total || 0}
                  {" tx"}
                </Typography>
              </Box>
            ) : (
              <Skeleton
                sx={{ fontSize: "2rem" }}
                width="4rem"
                variant="rectangular"
                animation="wave"
              />
            )}
          </Stack>
        ) : (
          <Skeleton
            sx={{ fontSize: "2rem" }}
            width="20%"
            variant="rectangular"
            animation="wave"
          />
        )}
      </Box>
      {ledgerData.data?.amountMissing ? (
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item lg={7}>
            <Box
              style={{
                padding: "0.875rem 1rem",
                background: tokens.background.neutral.default,
                border: `1px solid ${tokens.border.neutral.default}`,
                borderRadius: "4px",
              }}
            >
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item lg={2.5}>
                  <Typography
                    variant="Metropolis/Body/Regular"
                    style={{ fontSize: "0.75rem", marginBottom: "0.5rem" }}
                  >
                    {lang.ledger.headings2.amountMissing}
                  </Typography>
                  <Typography
                    variant="Metropolis/Body/Bold"
                    style={{ fontSize: "1.25rem", color: tokens.text.danger }}
                  >
                    {displayCryptoQuantity({
                      quantity: -1 * (ledgerData.data?.amountMissing || 0),
                      locale: languagePreference,
                      precision: 6,
                      displayLessThan: true,
                    })}{" "}
                    {displayCurrency(ledgerCurrency.symbol)}
                  </Typography>
                </Grid>
                <Grid item lg={9.5}>
                  <Box
                    sx={{
                      [theme.breakpoints.up("lg")]: {
                        paddingLeft: "1rem",
                        borderLeft: `1px solid ${tokens.border.neutral.medium}`,
                      },
                    }}
                  >
                    <Typography variant="Metropolis/Body/Regular">
                      {lang.ledger.banner.text}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : null}

      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="top"
        mt="-1rem"
      >
        <Stack direction="row" gap="1rem" flexWrap="wrap" mt="1rem">
          <Box style={{ minWidth: "20rem", maxWidth: "30rem" }}>
            <FilterAutocomplete
              size={Size.Small}
              filter={state.filter}
              restrictType={FilterOperators.Source}
              overwriteRestrictTypeOptions={{
                [FilterOperators.Source]: restrictedFilterOptions,
              }}
              onChange={(filter) => {
                if (filter?.type === FilterOperators.Source) {
                  setFilters(negativeBalancesOnly, filter?.value || []);
                } else {
                  setFilters(negativeBalancesOnly, []);
                }
              }}
            />
          </Box>
          <FilterDate />
          {isFiltersActive ? (
            <FilterReset onReset={handleReset} size={Size.Small} />
          ) : null}
        </Stack>
        <Stack direction="row" gap="1rem" flexWrap="wrap" mt="1rem">
          {isTablet && showToggle ? (
            <Box mt="0.2rem">
              <NegativeBalanceSwitch
                size={Size.Small}
                lang={lang}
                negativeBalancesOnly={negativeBalancesOnly}
                handleToggleNegativeBalances={handleToggleNegativeBalances}
              />
            </Box>
          ) : null}
          <FilterPagination align={Align.Left} />
        </Stack>
      </Box>
      {!isTablet && showToggle ? (
        <NegativeBalanceSwitch
          lang={lang}
          size={Size.Small}
          negativeBalancesOnly={negativeBalancesOnly}
          handleToggleNegativeBalances={handleToggleNegativeBalances}
        />
      ) : null}
      {txData.error ? <Typography>{lang.error}</Typography> : null}
      <Box>
        <TransactionsTableWithFilters
          isCountEnabled={false}
          showFilterBar={false}
          header={<LedgerRowHeader />}
          row={createRow}
        />
      </Box>
    </Stack>
  );
}

const NegativeBalanceSwitch = ({
  size,
  lang,
  negativeBalancesOnly,
  handleToggleNegativeBalances,
}: {
  size?: Size;
  lang: Translation;
  negativeBalancesOnly: boolean;
  handleToggleNegativeBalances: () => void;
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        sx={{
          fontSize: size === Size.Small ? "0.75rem" : "0.875rem",
          fontWeight: 500,
        }}
      >
        {lang.ledger.filters.onlyShowNegativeBalance}
      </Typography>
      <Switch
        size={size === Size.Small ? size : undefined}
        checked={negativeBalancesOnly}
        onChange={() => {
          handleToggleNegativeBalances();
        }}
      />
    </Box>
  );
};

function getAccountFilterOptions(
  allFilterOptions: SourceFilterOption[],
  restrictedFilterOptions: string[],
): SourceFilterOption[] {
  const filterOptionsSet = new Set<string>(restrictedFilterOptions);

  // Filter the all filter options to only ones that strictly match
  const filtered = allFilterOptions.filter((option) =>
    filterOptionsSet.has(
      hashExchangeId({
        exchange: option.name.toLowerCase(),
        blockchain: option.blockchain,
      }),
    ),
  );

  return filtered;
}
