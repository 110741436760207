import { type ReportFormat } from "@ctc/types";
import { createContext, type PropsWithChildren, useState } from "react";

import { type FinancialYear } from "~/contexts/FYContext";
import { type ReportDownloadType } from "~/types/index";

type ReportLoadingStatus = {
  format: ReportFormat;
  type: ReportDownloadType;
  timeframe: FinancialYear;
};

export type ReportDownloadContextType = {
  state: ReportLoadingStatus[];
  updateState: (newLoadingStatus: ReportLoadingStatus[]) => void;
};

export const ReportDownloadContext =
  createContext<ReportDownloadContextType | null>(null);

export const ReportDownloadContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [reportDownloadState, setReportDownloadState] = useState<
    ReportLoadingStatus[]
  >([]);

  return (
    <ReportDownloadContext.Provider
      value={{
        state: reportDownloadState,
        updateState: setReportDownloadState,
      }}
    >
      {children}
    </ReportDownloadContext.Provider>
  );
};
