import axios, { AxiosError, type AxiosResponse } from "axios";
import FileSaver from "file-saver";
import moment from "moment-timezone";
import qs from "query-string";

import {
  type CapitalGainsCategories,
  type TradingStockValuationMethod,
} from "~/components/reconciliation/enums";
import { type FinancialYear } from "~/contexts/FYContext";
import { get, post } from "~/services/core";
import { CORE } from "~/services/uri";
import {
  type HoldingsBalanceType,
  type InflowOutflowPortfolioTimeframe,
  type NormalReportType,
  OtherReportType,
  type PortfolioTimeframe,
  type Sort,
  TurboTaxReportType,
} from "~/types/enums";
import {
  type ActionType,
  type FilterQuery,
  type InflowOutflowData,
  type ReportData,
  type ReportSummary,
  type ReportType,
  type TaxSettings,
} from "~/types/index";

export const timeframeQuery = (
  timeframe?: FinancialYear,
): { fromDate?: string; toDate?: string } => {
  return timeframe
    ? {
        fromDate: timeframe.start.toISOString(),
        toDate: timeframe.end.toISOString(),
      }
    : {};
};
const timeframeQuerystring = (timeframe: FinancialYear) => {
  return qs.stringify(timeframeQuery(timeframe));
};

export function setTaxSettings(taxSettings: Partial<TaxSettings>) {
  const path = "/report/tax-settings";
  return post<TaxSettings>(path, taxSettings);
}

export function getTaxSettings() {
  const query = qs.stringify({
    timezone: moment.tz.guess(),
  });
  const path = `/report/tax-settings?${query}`;
  return get<TaxSettings>(path);
}

export function getReportSummary(timeframe: FinancialYear) {
  const query = timeframeQuerystring(timeframe);
  const path = `/report/summary?${query}`;
  return get<ReportSummary>(path);
}

export function getReportTotalCount(
  reportType: NormalReportType,
  timeframe: FinancialYear,
  params?: Pick<ReportParams, "tradeFilter" | "categoryFilter">,
) {
  const query = qs.stringify({ ...params, ...timeframeQuery(timeframe) });
  const path = `/report/count/${reportType}?${query}`;
  return get<number>(path);
}

export type ReportParams = {
  page?: number;
  count?: number;
  sortBy?: string;
  sortDirection?: "desc" | "asc" | undefined;
  year?: number;
  fromDate?: string;
  toDate?: string;
  tradingStockValuationMethod?: TradingStockValuationMethod;
  tradeFilter?: ActionType;
  categoryFilter?: CapitalGainsCategories;
};

export function getReportTable<T extends ReportData>(
  reportType: NormalReportType,
  params: ReportParams,
) {
  const query = qs.stringify(params);
  const path = `/report/table/${reportType}?${query}`;
  return get<T>(path);
}

export function getFYOptions() {
  const path = `/report/fy-options`;
  return get<FinancialYear[]>(path);
}

export async function generateReportTurboTaxTxf(
  timeframe: FinancialYear,
  timezone: string,
) {
  try {
    const reportType = TurboTaxReportType.TurboTaxTxf;
    const query = qs.stringify({
      ...timeframeQuery(timeframe),
      timezone,
      isCustomDates: !!timeframe.custom,
    });
    const path = `/report/download/${reportType}/txf?${query}`;
    const res = await axios({
      url: `${CORE}${path}`,
      method: "get",
      responseType: "text",
      withCredentials: true,
    });
    downloadFileFromResponse(res, "text/plain");
    return { error: false, data: true };
  } catch (e) {
    return { error: true, message: "Failed to generate the TurboTax TXF file" };
  }
}

export async function generateFilteredTxReport({
  filter,
  count,
  sort,
  page,
  timezone,
}: {
  filter?: FilterQuery;
  count: number;
  sort: Sort;
  page: number;
  timezone: string;
}) {
  try {
    const txFilterQuery = JSON.stringify({ filter, count, sort, page });
    const query = qs.stringify({
      txFilterQuery,
      timezone,
      reportTypes: [OtherReportType.FilteredTransaction],
      isCustomDates: false,
    });
    const path = `/report/download/csv?${query}`;
    const res = await axios({
      url: `${CORE}${path}`,
      method: "get",
      responseType: "blob",
      withCredentials: true,
    });

    downloadFileFromResponse(res, "text/csv");
    return { error: false, data: true };
  } catch (e) {
    return {
      error: true,
      message: "Failed to generate the Filtered tx report file",
    };
  }
}

/**
 * Handles the downloading of files from a server response.
 * @param res The response object from axios.
 * @param defaultType The default MIME type if not specified in the response.
 */
const downloadFileFromResponse = (
  res: AxiosResponse<any, any>,
  defaultType: string,
) => {
  const filename = res.headers["content-disposition"]?.split("filename=")[1];
  const contentType = res.headers["content-type"] || defaultType;
  const file = new Blob([res.data], { type: contentType });
  FileSaver.saveAs(file, filename);
};

/**
 * Exports the users data in the BGL format as an XML file, which they can upload to BGL
 */
export async function generateReportBglXml(
  timeframe: FinancialYear,
  timezone: string,
  allowWarnings = false,
) {
  try {
    const reportType = OtherReportType.BGL;
    const query = qs.stringify({
      ...timeframeQuery(timeframe),
      timezone,
      isCustomDates: !!timeframe.custom,
      allowWarnings,
    });
    const path = `/report/download/${reportType}/xml?${query}`;
    const res = await axios({
      url: `${CORE}${path}`,
      method: "get",
      responseType: "text",
      withCredentials: true,
    });

    downloadFileFromResponse(res, "application/xml");

    return { error: false, data: true };
  } catch (e) {
    // If the axios error response data contains a message and warnings
    if (e instanceof AxiosError) {
      if (e.response?.data) {
        let data: { message?: string; warnings?: string[] } | null = null;
        if (typeof e.response?.data === "string") {
          try {
            data = JSON.parse(e.response?.data) as {
              message?: string;
              warnings?: string[];
            };
          } catch (parseError) {
            data = null;
          }
        } else if (typeof e.response?.data === "object") {
          data = e.response?.data as {
            message?: string;
            warnings?: string[];
          };
        }
        // This condition ensures older responses without warnings aren't handled here.
        if (data?.message && data?.warnings && data.warnings.length > 0) {
          return {
            error: true,
            message: data.message,
            warnings: data.warnings,
          };
        }
      }
    }

    // Otherwise return a more generic error message
    const errorMessage =
      e instanceof Error ? e.message : "Failed to generate the BGL XML file";
    return { error: true, message: errorMessage };
  }
}

/**
 *
 * @param reportType
 * @param timeframe
 * @param timezone
 * @param queryParams Additional query params to pass to the request
 * @returns
 */
export async function generateReportCSVs(
  reportTypes: ReportType[],
  timeframe: FinancialYear,
  timezone: string,
  packType: string,
  queryParams?: Record<string, string>,
  tradingStockValuationMethod?: TradingStockValuationMethod,
) {
  try {
    const query = qs.stringify({
      reportTypes: reportTypes.join(","),
      ...timeframeQuery(timeframe),
      timezone,
      isCustomDates: !!timeframe.custom,
      ...queryParams,
      packType,
      tradingStockValuationMethod,
    });
    const path = `/report/download/csv?${query}`;
    const res = await axios({
      url: `${CORE}${path}`,
      method: "get",
      responseType: "blob",
      withCredentials: true,
    });

    downloadFileFromResponse(res, "text/csv");

    return { error: false, data: true };
  } catch (e) {
    return { error: true, message: "Failed to generate the CSV" };
  }
}

/**
 * Generates the report PDFs.
 *
 * This is done behind a worker_thread and the result are sent through via websocket when completed
 * @param reportType
 * @param queryParams Additional query params to pass to the request
 * @param timeframe
 */
export async function generateReportPDFs(
  reportTypes: ReportType[],
  timeframe: FinancialYear,
  timezone: string,
  packType: string,
  userData?: { name: string; social: string },
  queryParams?: Record<string, string>,
  packId?: string,
  tradingStockValuationMethod?: TradingStockValuationMethod,
) {
  const payload = {
    reportTypes,
    ...timeframeQuery(timeframe),
    timezone,
    name: userData?.name,
    social: userData?.social,
    isCustomDates: !!timeframe.custom,
    packId,
    packType,
    tradingStockValuationMethod,
    ...queryParams,
  };
  const path = `/report/download/pdf`;
  return post<string>(path, payload);
}

export async function getPorfolioChange(
  timeframe: PortfolioTimeframe,
  type: HoldingsBalanceType,
) {
  const path = `/report/portfolio-change?${qs.stringify({ type, timeframe })}`;
  return get<string>(path);
}

export async function getPorfolioMatrix(
  timeframe: PortfolioTimeframe,
  type: HoldingsBalanceType,
) {
  const path = `/report/portfolio-matrix?${qs.stringify({ type, timeframe })}`;
  return get<
    Record<
      string,
      {
        currencyId: string;
        balance: number;
        value: number;
        timestamp: number;
        price: number;
      }[]
    >
  >(path);
}

export async function getFiatSummary() {
  const path = `/report/fiat-summary`;
  return get<{ fiatDisposed: number; fiatProceeds: number; fiatFees: number }>(
    path,
  );
}

export async function getTreasurySummary({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) {
  const path = `/report/treasury-summary?${qs.stringify({ startDate, endDate })}`;
  return get<{ inflow: number; outflow: number; fees: number }>(path);
}

export async function getInflowOutflowPortfolio(
  timeframe: InflowOutflowPortfolioTimeframe,
) {
  const path = `/report/portfolio/inflow-outflow?${qs.stringify({ timeframe })}`;
  return get<InflowOutflowData[]>(path);
}

export function refreshReport() {
  const path = `/report/refresh`;
  return get<boolean>(path);
}
