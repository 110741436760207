import Box from "@mui/material/Box";

import phantomLogo from "~/assets/phantom-logo.svg";
import { type Theme } from "~/types/enums";

export const PhantomLogo = ({
  height,
}: {
  themeOverride?: Theme;
  /** Height of the logo in pixels. Will forcefully override any existing styles */
  height?: number;
}) => {
  return (
    <Box
      sx={{
        ...(height && {
          "& img": {
            height: `${height}px !important`,
          },
        }),
      }}
    >
      <img
        src={phantomLogo}
        alt="Phantom"
        style={{
          width: "170px",
        }}
      />
    </Box>
  );
};
