import { Box } from "@mui/material";

import { ActionHeaderCell } from "~/components/transactions/action-row/ActionHeaderCell";
import { ActionRowCell } from "~/components/transactions/action-row/ActionRowCell";
import { EnDash } from "~/components/transactions/action-row/EnDash";
import { ActionTableGridArea } from "~/components/transactions/action-row/enums";
import { ActionRowFirstLineBox } from "~/components/transactions/action-row/Overflower";
import { type ActionRowCellProps } from "~/components/transactions/action-row/types";
import { GainLossVariant } from "~/components/transactions/enums";
import { GainLossBox } from "~/components/transactions/GainLossCell";
import { displayFiatSymbol } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { Align, Sort } from "~/types/enums";

export function ActionRowGain({
  row,
  disablePointerEvents = false,
}: ActionRowCellProps) {
  const gain = row.overallGain;

  if (!gain) {
    return (
      <ActionRowCell
        id={ActionTableGridArea.Gain}
        align={Align.Right}
        disablePointerEvents={disablePointerEvents}
      >
        <ActionRowFirstLineBox>
          <EnDash />
        </ActionRowFirstLineBox>
      </ActionRowCell>
    );
  }

  if (!isNaN(gain) && Math.abs(gain) < 0.00000001) {
    return (
      <ActionRowCell
        id={ActionTableGridArea.Gain}
        align={Align.Right}
        disablePointerEvents={disablePointerEvents}
      >
        <ActionRowFirstLineBox>
          <EnDash />
        </ActionRowFirstLineBox>
      </ActionRowCell>
    );
  }

  return (
    <ActionRowCell
      id={ActionTableGridArea.Gain}
      align={Align.Right}
      disablePointerEvents={disablePointerEvents}
    >
      <ActionRowFirstLineBox>
        <GainLossBox
          totalGain={gain}
          variant={GainLossVariant.Text}
          showCurrencySymbol={false}
          showArrow={false}
        />
      </ActionRowFirstLineBox>
    </ActionRowCell>
  );
}

export function ActionHeaderGain() {
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const symbol = displayFiatSymbol(localCurrency, languagePreference);

  return (
    <Box style={{ gridArea: ActionTableGridArea.Gain }}>
      <ActionHeaderCell
        sort={{
          sortAscending: Sort.GainAscending,
          sortDescending: Sort.GainDescending,
        }}
        align={Align.Right}
      >
        {lang.txTable.gain} {symbol ? `(${symbol})` : null}
      </ActionHeaderCell>
    </Box>
  );
}
