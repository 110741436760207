import { Box, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { ActionBalanceLedger } from "~/components/balance-ledger/ActionBalanceLedger";
import { DynamicPageTitle } from "~/components/PageTitle";
import { FilterContextKey } from "~/components/transactions/filter-bar/enums";
import { FilterProvider } from "~/components/transactions/filter-bar/FilterContext";
import { devices } from "~/components/ui/theme/legacy";
import { useLang } from "~/redux/lang";
import { useUserCurrenciesQuery } from "~/state/currencies";
import { FilterOperators, Sort } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

export const BalanceLedger = () => {
  const { currencyId } = useParams<{ currencyId: string }>();
  const isDesktop = useMediaQuery(devices.desktop);
  const currencies = useUserCurrenciesQuery().data ?? [];
  const lang = useLang();
  // find the currency where the id is the same as the currencyId
  const ledgerCurrency = currencies.find(
    (currency) => currency.id.toLowerCase() === currencyId?.toLowerCase(),
  );

  if (!ledgerCurrency) {
    return null;
  }
  const initialFilter: FilterQuery = {
    type: FilterOperators.And,
    rules: [
      {
        type: FilterOperators.Currency,
        value: [ledgerCurrency.id],
      },
    ],
  };

  const pageTitle = lang.ledger.pageTitle({
    currencyName: ledgerCurrency.name,
  });

  return (
    <>
      <DynamicPageTitle title={pageTitle} />
      <FilterProvider
        persist={FilterContextKey.Ledger}
        initialState={{ filter: initialFilter, sort: Sort.DateAscending }}
        key={ledgerCurrency.id} // Add key to force re-render when currency changes
      >
        <LedgerContainer isDesktop={isDesktop}>
          <Box
            sx={{
              padding: {
                xs: "1rem",
                md: "2rem",
              },
            }}
          >
            <ActionBalanceLedger ledgerCurrency={ledgerCurrency} />
          </Box>
        </LedgerContainer>
      </FilterProvider>
    </>
  );
};

const LedgerContainer = styled(Box)<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 106.25rem;
  margin: 0 auto;
`;
