import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";

import { type PlanContainerData } from "~/components/plans/PlanContainer";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type Features } from "~/types/enums";
import { type PlanDataBase } from "~/types/index";

export type FeatureDisplay = {
  text: string;
  available: boolean;
  comingSoon?: boolean;
};

export function PlanFeatures<T extends PlanDataBase>({
  selectedPlanData,
  highlightedFeatures,
  comingSoon,
  additionalFeatures,
}: {
  selectedPlanData: PlanContainerData<T>;
  highlightedFeatures: Features[];
  comingSoon: Features[];
  additionalFeatures: FeatureDisplay[];
}) {
  const { tokens } = useDesign();
  const lang = useLang();

  const selectedPlanDetails = selectedPlanData.data;

  const planFeatures = selectedPlanDetails?.features;

  const features: FeatureDisplay[] = [
    ...highlightedFeatures.map((feature) => ({
      text: lang.payment.base.copy.highlightFeatures[feature],
      available: !!planFeatures[feature],
      comingSoon: comingSoon.includes(feature),
    })),
    ...additionalFeatures,
  ];

  return (
    <Box sx={{ padding: "0.5rem" }}>
      <Grid container>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} key={`${feature.text}-${index}`}>
            <Stack key={index} direction="row" gap="0.5rem" p="0.5rem">
              {feature.available ? (
                <CheckIcon
                  sx={{
                    color: tokens.text.success,
                  }}
                />
              ) : (
                <CloseIcon
                  sx={{
                    color: tokens.text.danger,
                  }}
                />
              )}
              <Stack direction="row" gap="0.5rem" flexWrap="wrap">
                <Typography>{feature.text}</Typography>
                {feature.comingSoon ? (
                  <Chip
                    label={lang.payment.base.copy.highlightFeatures.comingSoon}
                    size="small"
                    sx={{
                      backgroundColor: tokens.background.accent.neutral.medium,
                    }}
                  />
                ) : null}
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
