import { IconButton } from "@mui/material";
import styled from "styled-components/macro";

export const SecondaryIconButton = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.tokens.icon.brand};
    background-color: ${({ theme }) => theme.tokens.button.subtle.default};
    border-radius: 4px;
    transition: all 100ms;
    border: 1px rgba(0, 0, 0, 0) solid;
  }

  &:hover {
    border: 1px ${({ theme }) => theme.tokens.border.brand} solid;
    background-color: ${({ theme }) => theme.tokens.button.subtle.hover};
  }

  &.Mui-focusVisible {
    border: 1px ${({ theme }) => theme.tokens.border.brand} solid;
    background-color: ${({ theme }) => theme.tokens.button.subtle.pressed};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.tokens.icon.disabled};
    background-color: ${({ theme }) => theme.tokens.button.disabled};
  }
`;
