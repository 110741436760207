import { number, plural, select } from "@messageformat/runtime";
import { it } from "@messageformat/runtime/lib/cardinals";
export default {
  accept: () => "Accettare",
  acceptTest: () => "Accetta Test",
  accountant: {
    addClient: () => "Nuovo cliente",
    addClientError: () =>
      "Impossibile aggiungere un nuovo client. Se il problema persiste, contatta l'assistenza",
    billingOwner: {
      accountant: () => "Contabile",
      client: () => "Cliente",
    },
    billingPage: {
      failed: () => "Fallito",
      paid: () => "Pagato",
    },
    clientDefault: () => "Il mio primo cliente",
    clientInListNotFound: () =>
      "Impossibile trovare il client nell'elenco client",
    clientListHeader: {
      billingDate: () => "data di fatturazione",
      dateAdded: () => "data aggiunta",
      email: () => "e-mail",
      name: () => "nome",
      plan: () => "piano",
      status: () => "stato",
    },
    clientTable: {
      billingTooltip: () =>
        "Il piano è stato annullato e rimarrà attivo fino alla prossima data di fatturazione",
      clearFilters: () => "Cancella filtri",
      headers: {
        billingDate: () => "Data di fatturazione",
        dateAdded: () => "Data aggiunta",
        name: () => "Nome",
        plan: () => "Piano",
        subscriber: () => "Abbonato",
        transaction: () => "Transazione",
      },
      invitePending: () => "Invito in sospeso:",
      invited: () => "Invitato",
      newClient: () => "Nuovo cliente",
      noClients: () => "I tuoi clienti appariranno qui",
      noClientsMatchFilter: () => "Nessun cliente corrisponde al filtro ",
      view: () => "visualizzazione",
    },
    clientVersionNotFound: () =>
      "Impossibile determinare la versione dell'abbonamento. Se il problema persiste, contatta l'assistenza",
    defaultAccount: () => "Account predefinito",
    emptyState: {
      body: () => "Inizia aggiungendo il tuo primo cliente.",
      title: () => "Non hai ancora nessun cliente",
    },
    errorLoadingClient: () => "Errore durante il caricamento del client",
    invite: {
      emailInvalid: () => "Indirizzo email non valido",
      emailRequired: () => "E-mail richiesta",
      fromName: () => "Il tuo nome",
      fromNamePlaceholder: () => "Il tuo nome completo",
      invite: () => "Invitare",
      inviteAccountant: () => "Invita un contabile",
      inviteWarning: () =>
        "Quando il commercialista accetterà l'invito, i tuoi dati sostituiranno i suoi.",
      nameInvalid: () => "Sono consentiti solo caratteri alfanumerici.",
      nameRequired: () => "Nome obbligatorio",
      toEmail: () => "Email del contabile",
      toEmailPlaceholder: () => "L'email del tuo commercialista",
    },
    loadingClients: () => "Caricamento...",
    maxClientLimit: {
      text: () =>
        "Hai raggiunto il limite massimo di clienti per il tuo account.",
      title: () => "Limite di clienti raggiunto",
    },
    mostPopularPlanText: () => "I più popolari",
    moveAccountSettings: {
      body: () =>
        "Ora puoi trovare le informazioni e le impostazioni del tuo account nella barra di navigazione sotto Account.",
      title: () => "Abbiamo spostato le impostazioni dell'account",
    },
    newClient: {
      buttonText: (d) =>
        select(d.isSaving, {
          true: " Aggiunta cliente ",
          other: " Aggiungi cliente ",
        }),
      clientType: {
        new: () => "Beta",
        title: () => "Tipologia cliente:",
        type: {
          business: () => "Attività commerciale",
          individual: () => "Individuale",
        },
      },
      error: {
        invalidName: (d) =>
          "Nome non valido " +
          d.name +
          " . Sono consentiti solo caratteri alfanumerici.",
        missingCountry: () => "Devi selezionare un paese",
        missingName: () => "Devi inserire un nome per il tuo cliente",
      },
      form: {
        email: {
          helperText: () =>
            "Aggiungi l'indirizzo e-mail per invitare e condividere l'accesso con il cliente.",
          label: () => "E-mail (facoltativo)",
        },
        name: {
          business: {
            helperText: () => "Questo nome apparirà nell'elenco dei clienti.",
            label: () => "Nome dell'organizzazione o del cliente",
          },
          individual: {
            helperText: () => "Questo nome apparirà nell'elenco dei clienti.",
            label: () => "Nome del cliente",
          },
        },
      },
      planCard: {
        aYear: () => " un anno",
        creditsAvailable: (d) =>
          d.credits +
          " " +
          select(d.singleCredit, { true: " credito ", other: " crediti " }) +
          " disponibile",
        free: () => "Gratuito",
        nextYear: (d) => "Quindi " + d.price + " per gli anni successivi.",
      },
      section1: {
        field1: () => "Soprannome del cliente",
        field2: () => "E-mail (facoltativo)",
        field3: () => "Paese",
        title: () => "Aggiungere informazioni sul cliente",
      },
      section2: {
        title: () => "Seleziona un piano",
      },
      section3: {
        body1: () => "Il pagamento dovuto è ",
        body2: () => " fatturato il ",
        title: () => "Pagamento",
      },
      success: () => "Nuovo cliente aggiunto",
      title: () => "Aggiungi nuovo cliente",
    },
    noClientFound: () => "Nessun cliente trovato",
    noClientSelected: () => "Nessun cliente selezionato",
    or: () => "O",
    savingClient: () => "Aggiunta del client",
    subscriber: {
      accountant: () => "Contabile",
      both: () => "Entrambi",
      bothCTA: () => "Possibile doppio pagamento. Contattare l'assistenza.",
      client: () => "Cliente",
      free: () => "Gratuito",
      header: () => "abbonato",
      nobody: () => "—",
      tooltip: () => "Pagatore del conto.",
    },
    upgradeClient: {
      cancel: () => "Cancellare",
      clientName: () => "Nome del cliente: ",
      failMsg: () => "Aggiornamento client non riuscito",
      nextStepButtonText: () => "Prossimo",
      payAmountAndUpgrade: (d) => "Paga " + d.amount + " e aggiorna",
      payAndUpgrade: () => "Paga e aggiorna",
      payment: {
        defaultErrorMsg: () =>
          "Se il problema persiste, contattare l'assistenza",
        detail1: () => "Pagamento di ",
        detail2: () => " verrà fatturato il ",
        noneRequired: () => "Nessun pagamento richiesto",
        title: () => "Pagamento",
      },
      renewalErrorBanner: {
        body: () =>
          "Hai fatture in arretrato, non puoi aggiornare i clienti mentre le fatture sono non pagate",
        buttonText: () => "Visualizza fatture non pagate",
        title: () => "Le fatture passate sono in arretrato",
      },
      selectAPlan: () => "Seleziona un piano",
      stepLabels: {
        payment: () => "Pagamento",
        selectPlan: () => "Seleziona un piano",
      },
      title: () => "Aggiorna il client",
      upgrade: () => "Aggiornamento",
    },
  },
  accountantPortal: {
    createAccount: () => "Creare un account",
    dialogLabel: () => "Inserimento contabile",
    errorMessage: () =>
      "Si è verificato un problema durante l'aggiornamento dei piani. Contatta l'assistenza",
    paymentCtaPrefix: () => "Aggiornamento per ",
    steps: {
      accountantInfo: {
        accountantCompanyDescription: () =>
          "Il nome del tuo studio di contabilità.",
        continue: () => "Continuare",
        nameDescription: () => "Nome che i tuoi clienti vedranno.",
        required: () => "*Campi obbligatori",
      },
      payment: {
        cancelAnyTimeText: () => " Puoi annullare in qualsiasi momento.",
        cardDetailsLabel: () => "Dettagli della carta",
        couponText: () => "Hai un codice coupon?",
        discountAppliedText: () => "Sconto applicato",
        discountDescription1: () => "Ti verrà addebitato un costo ",
        discountDescription2: () => " per il primo anno e ",
        discountDescription3: () => " per gli anni successivi.",
        label: () => "Pagamento",
        paymentDescription1: () => "Ti verrà addebitato un costo ",
        paymentDescription2: () =>
          " annuale fino alla disdetta dell'abbonamento.",
        planTextSuffix: () => " piano",
        submitButtonSuffix: () => " e finire",
        subtotalText: () => "Subtotale",
        title: () => "Riepilogo del pagamento",
        yearlySubscriptionText: () => "Abbonamento annuale",
      },
      welcome: {
        info: {
          switching: {
            subtitle: () =>
              "Passando al Portale dell'Accountant non potrai annullare l'azione. Puoi gestire il tuo account dalle impostazioni, inclusa la modifica dell'indirizzo email.",
            title: () => "Cambiare account",
          },
          transactions: {
            subtitle: () =>
              "Le tue transazioni e il tuo piano di pagamento saranno trasferiti al tuo nuovo Accountant Portal e i tuoi dati appariranno nell'elenco dei clienti come primo cliente. Puoi continuare a gestire le tue transazioni come prima.",
            title: () => "Transazioni e migrazione dei dati",
          },
        },
      },
    },
    subtitle: () =>
      "Gestisci e calcola le tasse dei tuoi clienti in un unico posto.",
    title: () => "Benvenuti al Portale del Contabile",
  },
  accountingIntegrations: {
    connectAccountingSoftware: () => "Connetti il software di contabilità",
    connectIntegration: {
      connected: {
        connected: () => "Collegato",
        connectedAt: (d) => "Connesso alle " + d.time,
        disconnect: () => "Rimuovi connessione",
        disconnectWarning: {
          actionText: () => "Disconnettersi",
          alert: () =>
            "Le mappature degli account verranno rimosse e sarà necessario rimapparle al momento della riconnessione.",
          body: (d) =>
            "Questo scollegherà il tuo account da " +
            d.erp +
            " e non potrai più sincronizzare le tue transazioni con " +
            d.erp +
            " .",
          title: (d) => "Disconnetti " + d.erp,
        },
        selectTenant: () => "Seleziona azienda",
      },
      disconnected: {
        title: () => "Connetti l'integrazione",
      },
    },
    defaults: {
      accountMapping: {
        asset: {
          title: () => "Conto patrimoniale",
          tooltip: () =>
            "Conto che rappresenta il valore delle attività nel portafoglio dell'utente",
        },
        cash: {
          title: () => "Conto cassa",
          tooltip: () =>
            "Account che rappresenta le disponibilità in contanti. Se vendi criptovalute, rappresenteremo i proventi in contanti qui e viceversa quando acquisti criptovalute. Le transazioni da criptovaluta a criptovaluta non avranno alcun impatto su questo account.",
        },
        expense: {
          title: () => "Conto spese",
          tooltip: () => "Conto che rappresenta le spese in criptovaluta",
        },
        fee: {
          title: () => "Conto commissioni",
          tooltip: () =>
            "Conto che rappresenta l'importo pagato in commissioni",
        },
        income: {
          title: () => "Conto entrate",
          tooltip: () =>
            "Conto che rappresenta il reddito derivante dalle criptovalute",
        },
        loan: {
          title: () => "Conto prestito",
          tooltip: () =>
            "Conto che rappresenta prestiti e rimborsi in criptovalute",
        },
        loss: {
          title: () => "Conto perdite",
          tooltip: () =>
            "Conto che rappresenta l'importo di criptovalute perse",
        },
        "realised-gain": {
          title: () => "Conto guadagno realizzato",
          tooltip: () =>
            "Conto che rappresenta i guadagni realizzati dalla cessione di criptovalute",
        },
        "realised-loss": {
          title: () => "Conto perdite realizzate",
          tooltip: () =>
            "Conto che rappresenta le perdite derivanti dalla cessione di criptovalute",
        },
        "zero-cost-buy": {
          title: () => "Account cronologia acquisti mancante",
          tooltip: () =>
            "Quando un bene viene acquistato o ottenuto e non viene effettuato alcun pagamento per esso, consideriamo la base di costo del bene come valore di mercato e questo conto viene utilizzato per bilanciare l'altro lato del giornale",
        },
      },
      empty: () => "Inizia collegando la tua integrazione contabile",
      mapped: () => "Mappato",
      required: () => "Necessario",
      selectorPlaceholder: (d) => "Seleziona " + d.account + " in " + d.erp,
      title: () => "Account predefiniti",
      unmapped: () => "Non mappato",
    },
    integrations: () => "Mappatura predefinita",
    rollupSettings: {
      options: {
        day: () => "Giorno",
        month: () => "Mese",
        none: () => "Nessun rollup",
      },
      placeholder: () => "Seleziona il periodo di rollup",
      subtitle: () =>
        "Tutte le transazioni nel periodo selezionato verranno raggruppate in un'unica registrazione di giornale",
      title: () => "Impostazioni di rollup",
    },
    rules: () => "Regole personalizzate",
    subtitle: (d) =>
      "Associa le categorie in CTC ai tuoi diari in " + d.erp + " .",
    subtitleWithoutERP: () =>
      "Associa le categorie in CTC al tuo software di contabilità.",
    title: () => "Integrazioni contabili",
    warningSettings: {
      label: () => "Consenti la sincronizzazione delle transazioni con avvisi",
      subtitle: (d) =>
        "Se disattivato, tutti gli avvisi devono essere risolti su una determinata transazione prima che questa possa essere sincronizzata con " +
        d.erp,
      title: () => "Consenti la sincronizzazione delle transazioni con avvisi",
    },
  },
  accountingSteps: {
    body: () => "Tutto ciò di cui hai bisogno per gestire i tuoi clienti",
    steps: {
      0: {
        body: () =>
          "Il nostro portale contabile ti consente di invitare e gestire i clienti da un'unica dashboard facile da usare.",
        title: () => "Gestisci tutti i tuoi clienti in un unico posto",
      },
      1: {
        body: () =>
          "Supportiamo direttamente centinaia di exchange, blockchain e servizi. Basta aggiungere chiavi API e indirizzi wallet.",
        title: () =>
          "Ottieni facilmente i dati dei clienti tramite scambio diretto e integrazioni blockchain",
      },
    },
    title: () => "Strumenti di contabilità e demo",
  },
  add: () => "Aggiungere",
  addManualTX: {
    currencyPaidIn: () => "Valuta in cui hai pagato la commissione",
    currencyPlaceholder: () => "ad esempio BTC, ETH, XRP",
    error: () =>
      "Si è verificato un errore durante l'aggiunta della transazione.",
    exchangeName: () => "Nome dello scambio",
    feePaidIn: () =>
      "Importo della commissione pagato nella valuta della commissione",
    in: () => "In",
    mustDestination: () => "Devi inserire una destinazione",
    mustEnterExchange: () => "Devi inserire un nome di scambio",
    mustSource: () => "Devi inserire una fonte",
    optional: () => "Opzionale",
    paidInFees: () => "pagato in tasse",
    priceForOne: () => "Prezzo per 1",
    quotePlaceholder: () => "ad esempio TUSD, BTC, ETH, XRP",
    saving: () => "Risparmio",
    success: () => "Transazione manuale creata.",
    totalQuantityOf: () => "Quantità totale di",
    totalValueIn: () => "Valore totale in",
    viewTransaction: () => "Visualizzazione",
    whereDestination: () => "Dov'è la destinazione della tua transazione?",
    whereFrom: () => "Da dove stai effettuando il trasferimento?",
    whereSource: () => "Dov'è la fonte della tua transazione?",
    whereTo: () => "Dove ti stai trasferendo?",
    whichCategory: () => "Quale categoria scegliere?",
  },
  adjustedPrice: {
    considersWashSalesDescription: () =>
      " e considerando le regole di vendita fittizia",
    description: () =>
      "Questo è il prezzo rettificato dopo l'applicazione del prezzo medio della piscina",
  },
  advanced: () => "Avanzato",
  advancedTransactionOptions: {
    findCategory: () => "Trova categoria",
    grouped: {
      description: () =>
        "È molto importante assicurarsi di aver importato tutti i dati da tutte le possibili fonti.",
      title: () => "Transazioni raggruppate",
    },
    in: {
      categoryGuide: () => "Guida alle categorie di transazione",
      description: () =>
        "È molto importante assicurarsi di aver importato tutti i dati da tutte le possibili fonti.",
      incorrectAction: () => "Visualizza le categorie in uscita",
      incorrectPrefix: () => "Non è una transazione in entrata?",
      inputGroup: () => "Categorie in arrivo",
      title: () => "Transazioni in entrata",
    },
    out: {
      categoryGuide: () => "Guida alle categorie di transazione",
      description: () =>
        "È molto importante assicurarsi di aver importato tutti i dati da tutte le possibili fonti.",
      incorrectAction: () => "Visualizza le categorie in arrivo",
      incorrectPrefix: () => "Non è una transazione in uscita?",
      inputGroup: () => "Categorie in uscita",
      title: () => "Transazioni in uscita",
    },
  },
  alerts: {
    customCSV: () =>
      "Assicurati che i tuoi dati siano preparati correttamente e in un formato di file CSV",
    noCookie: () =>
      "Abilita i cookie altrimenti non potrai effettuare l'accesso! Se stai utilizzando il browser Brave devi cliccare sul leone vicino alla barra di navigazione e disabilitare gli scudi per questo sito.",
  },
  all: () => "Tutto",
  allPlans: () => "Tutti i piani hanno una garanzia di rimborso di 30 giorni",
  api: {
    addressesIdentified: (d) => "Indirizzi identificati come " + d.exchangeName,
    apiKeyLabel: () => "Chiave API",
    connect: () => "Collegare",
    delete: () => "Elimina API",
    deleteConfirmationText: (d) =>
      "Vuoi davvero eliminare la chiave API " +
      d.exchangeName +
      ' " ' +
      d.api +
      ' "? Ciò eliminerà anche TUTTE le transazioni importate da questa chiave API. Questa azione non può essere annullata.',
    disabledExplanation: () =>
      "Non è possibile sincronizzare tramite OAuth se è già stata effettuata la sincronizzazione tramite API Key",
    edit: () => "Modifica i dettagli dell'API",
    editNickname: () => "Modifica il nome dell'API",
    editingSubmit: () => "Aggiorna API",
    exchanges: {
      binance: {
        crossMarginCurrencies: () => "Valute con margine incrociato",
        syncMarginTickers: () => "Sincronizzazione trasversale del margine",
        syncMarginTickersExplanation: () =>
          "Seleziona le valute cross margin che hai negoziato. Ciò è necessario per migliorare le prestazioni a causa delle limitazioni imposte dall'API di Binance durante il recupero dei tuoi dati.",
      },
    },
    existingSubmit: (d) => "Aggiungi API " + d.source + " aggiuntiva",
    hardSync: {
      confirmationAllAPIs: (d) =>
        "API " + d.name + " di sincronizzazione fissa?",
      confirmationOneAPI: () => "API di sincronizzazione fissa?",
      textOneAPI: (d) =>
        "La sincronizzazione hard importerà tutte le transazioni per l'API " +
        d.name +
        " da " +
        d.date +
        " . Ciò potrebbe talvolta portare a transazioni duplicate che devono essere gestite manualmente, nel caso in cui l'integrazione sia cambiata dall'ultima sincronizzazione.",
      titleAllChipAPIs: () =>
        "Risincronizza tutti i dati: verranno restituite tutte le transazioni eliminate per questa integrazione.",
      titleOneAPI: (d) =>
        "Risincronizza tutte le transazioni da " +
        d.date +
        " per l'API " +
        d.name,
    },
    import: () => "Importare",
    importRules: {
      existingApi: () => "La chiave API esiste già.",
      missingApi: () => "Devi inserire una chiave API",
      missingPassphrase: () => "Devi inserire una passphrase",
      missingSecret: () => "Devi inserire il segreto",
      missingUid: () => "Devi inserire un uid",
      multipleKeyError: (d) =>
        "Impossibile aggiungere più chiavi API per " + d.name,
      uidHint: () =>
        'Suggerimento: a volte può essere il nome o il "memo" che hai dato all\'API',
    },
    maxTickerLabel: () =>
      "Hai raggiunto il numero massimo di mercati consentiti dall'API. Se hai altri mercati da aggiungere, importali tramite CSV.",
    missingTickerAlert: (d) =>
      d.exchangeName +
      " richiede che tutti i ticker siano specificati quando si richiedono cronologie di trading tramite la loro API. Aggiungi TUTTI i ticker che hai scambiato per ottenere report fiscali accurati. In alternativa, puoi importare utilizzando l'opzione CSV.",
    name: () => "Nome API (facoltativo)",
    nickname: () => "Soprannome",
    passwordLabel: () => "Frase segreta",
    reauthorize: () => "Riautorizzare",
    resync: () => "Risincronizzazione",
    secretLabel: () => "Segreto",
    softSync: (d) => "Sincronizza le transazioni API " + d.name + " da",
    submit: (d) => "Aggiungi API " + d.source,
    sync: () => "Sincronizzazione",
    syncing: () => "Sincronizzazione",
    tickerLabel: () => "Seleziona tutti i ticker utilizzati",
    uidLabel: () => "Identificativo utente",
    update: () => "Aggiorna i dettagli dell'API",
  },
  apply: () => "Fare domanda a",
  auth: {
    acceptTermsToContinue: () =>
      "Devi accettare i Termini di servizio e l'Informativa sulla privacy per continuare",
    accountantClient: () => "Clienti con criptovalute?",
    accountantCompanyName: () => "Nome dell'azienda",
    accountantContactName: () => "Nome del contatto",
    accountantSignUp: () => "iscriviti come commercialista",
    accountantSignupSubTitle: () =>
      "Ci vuole meno di un minuto per registrarsi - non è richiesta alcuna carta di credito",
    accountantSignupTitle: () => "Registrazione contabile",
    accountantWorkEmail: () => "E-mail di lavoro",
    alreadyAccount: () => "Hai un account?",
    and: () => "E",
    areAccountant: () => "Sei un contabile?",
    backTo: () => "Tornare a",
    bitcoinDotComAu30Banner: () =>
      "Sconto del 30% applicato sui piani a pagamento",
    breaker: () => "OPPURE",
    captcha: {
      and: () => "E",
      apply: () => "fare domanda a.",
      privacyPolicy: () => "politica sulla riservatezza",
      protectedBy: () => "Questo sito è protetto da reCAPTCHA e da Google ",
      termsOfService: () => "Termini di servizio",
      verificationFailed: () => "Verifica non riuscita. Riprova.",
    },
    coinbase: () => "Coinbase",
    coinbase30Banner: () =>
      "10.000 transazioni Coinbase gratuite e sconto del 30% sui piani a pagamento applicati",
    coinbaseOne1DollarBanner: (d) =>
      d.rookiePlanPrice +
      " Piano Rookie. Sconto del 40% su tutti i piani aggiuntivi",
    coinbaseOne1DollarBannerNoCurrency: () =>
      "Offerta esclusiva per i membri di Coinbase applicata",
    coinbaseOne40Banner: () =>
      "Sconto del 40% sui piani a pagamento applicato all'iscrizione, esclusivamente per i membri di Coinbase One",
    coinbaseOnePremium40Banner: () =>
      "Sconto del 50% sul livello premium di CTC, Trader",
    coinjar30Banner: () =>
      "1.000 transazioni CoinJar gratuite e sconto del 30% sui piani a pagamento applicati all'iscrizione",
    coinstatsBanner: () =>
      "$30 di sconto sui piani a pagamento applicati all'iscrizione",
    compliance: () =>
      "Rendi la conformità un gioco da ragazzi con Crypto Tax Calculator",
    countryWarningBody: () =>
      "Non puoi accettare un invito da un contabile di un altro Paese. Contatta l'assistenza.",
    countryWarningTitle: () =>
      "Il tuo paese è diverso da quello del tuo commercialista",
    customerNum: () => "Oltre 200.000 clienti",
    differentSignUpMethod: () =>
      "La tua email esiste già con un metodo di registrazione diverso. Riprova tramite il tuo metodo originale.",
    email: () => "E-mail",
    embedded: {
      connectWith: (d) => "Connettiti con " + d.provider,
      signInWith: (d) => "Accedi con " + d.provider,
      tagline: () => "Rapporti fiscali trasparenti e precisi",
      title: () => "Inizia con Tax Hub",
    },
    errors: {
      invalidCode: () => "Codice 2fa non valido. Riprova.",
      invalidCredentials: () => "Email o password non valide. Riprova.",
      invalidSignup: () => "Registrazione non valida. Riprova.",
    },
    forgot: () => "Hai dimenticato la password?",
    forgotPassword: () => "Reimposta password",
    goBack: () => "Torna indietro",
    google: () => "Google",
    iAgree: () => "Creando un account accetti i",
    independentReserve30Banner: () =>
      "Sconto del 30% applicato sui piani a pagamento",
    invalidCombination: () => "Combinazione email/password non valida",
    invalidToken: () => "Token o email non validi",
    inviteReceived: () => "Invito ricevuto da:",
    login: () => "Login",
    loginPage: {
      oauth: {
        coinbase: () => "Accedi con Coinbase",
        google: () => "Accedi con Google",
        metamask: () => "Accedi con MetaMask",
      },
      title: () => "Bentornato",
    },
    loginTitle: () => "Bentornato!",
    loginTitleEmbedded: () => "Accedi a Tax Hub",
    metamask: () => "MetaMask",
    metamask30Banner: () =>
      "Sconto del 30% sui piani a pagamento applicato all'iscrizione",
    missingEmail: () =>
      "Per utilizzare questa funzione inserisci un indirizzo email tramite la pagina delle impostazioni",
    noAccount: () => "Non hai un account?",
    notAccountant: () => "Non sei un contabile?",
    officialPartner: () => "PARTNER UFFICIALE DI",
    officialPartners: () => "Partner ufficiali",
    orLogin: () => "Oppure accedi con email",
    orSignup: () => "Oppure registrati con email",
    orSwitchTo: () => "O",
    password: () => "Password",
    passwordCondition: () =>
      "La tua password deve essere lunga almeno 8 caratteri. Deve anche contenere un numero (0-9) o un carattere speciale (!@#$%^&*).",
    passwordResetButton: () => "Reimposta password",
    passwordResetFailed: () => "Reimpostazione password non riuscita",
    passwordResetInstructions: () =>
      "Inserisci il tuo indirizzo email qui sotto e ti invieremo un link per reimpostare la tua password:",
    passwordResetSuccessfully: () => "Reimpostazione password riuscita",
    passwordUpdatedSuccessfully: () => "Password aggiornata con successo",
    privacyPolicy: () => "politica sulla riservatezza",
    recaptcha: {
      part1: () => "Questo sito è protetto da reCAPTCHA e dalla Google",
      part2: () => "e",
      part3: () => "si applicano.",
    },
    recogniseBody: () => "I tuoi dati saranno condivisi con loro",
    recogniseTitle: () =>
      "Assicurati di riconoscere l'indirizzo e-mail del tuo commercialista!",
    resetEmailSent: (d) =>
      "Se l'email fornita è associata a un account, è stata inviata un'email di reimpostazione a " +
      d.email,
    resetPasswordSubTitle: () =>
      "Inserisci una nuova password per il tuo account",
    resetPasswordTitle: () => "Reimposta la tua password",
    setPasswordTitle: () => "Imposta la tua password",
    signInWith: () => "Accedi con:",
    signin: () => "Login",
    signup: () => "Iscrizione",
    signupPage: {
      accurateCryptoTaxes: () => "Tasse crypto accurate.",
      mobileTitle: () => "Inizia gratuitamente!",
      noGuesswork: () => "Nessuna approssimazione.",
      oauth: {
        coinbase: () => "Registrati con Coinbase",
        google: () => "Registrati con Google",
        metamask: () => "Registrati con MetaMask",
      },
      terms: {
        and: () => "e",
        body: () => "Accetto i",
        privacyPolicy: () => "Informativa sulla Privacy",
        termsNotAccepted: () =>
          "Per continuare, accetta i Termini di Servizio e l'Informativa sulla Privacy",
        termsOfService: () => "Termini di Servizio",
      },
      text: () => "Registrati",
      title: () => "Registrati e inizia gratuitamente",
      trustpilot: {
        reviews: () => "Oltre 500 recensioni",
        title: () => "Basato su",
      },
    },
    signupPromoText: {
      phantom: () =>
        "Ottieni 200 transazioni gratuite con Phantom Wallet e il 20% di sconto sul tuo piano CTC",
    },
    signupSubTitle: () => "Ci vuole meno di un minuto per registrarsi",
    signupTitle: () => "Crea subito il tuo account gratuito",
    somethingWentWrong: () => "Qualcosa è andato storto, riprova",
    strongPassword: () => "Ottimo. La tua password soddisfa i requisiti.",
    termsAndConditions: () => "Termini di servizio",
    toSignUp: () => "per iscriverti a Crypto Tax Calculator!",
    tooManyPasswordResetAttempts: () =>
      "Troppi tentativi di reimpostazione della password. Riprova più tardi.",
    transparentReports: () => "Rapporti fiscali trasparenti e precisi",
    trustPilotExcellent: () => "Eccellente",
    trustedBy: () => "Di fiducia da",
    unableToResetEmail: (d) =>
      "Impossibile reimpostare la password per l'email: " + d.email,
    unlock: () => "Account sbloccato",
    userSignUp: () => "registrati come utente",
    worldwide: () => "in tutto il mondo.",
    xero: () => "Xero",
    yourAccountant: () => "il tuo commercialista",
    youreInvited: () => "Sei invitato da",
  },
  autoClose: {
    title: () =>
      "Questa scheda dovrebbe chiudersi automaticamente, ma sentiti libero di chiuderla ora.",
  },
  back: () => "Indietro",
  billing: {
    44359: () =>
      "44359: Si è verificato un errore durante la creazione del metodo di pagamento",
    45732: () =>
      "45732: Si è verificato un problema durante la ricerca della carta",
    addCard: {
      cancel: () => "Cancellare",
      description: () =>
        "Questa nuova carta diventerà il tuo metodo di pagamento predefinito",
      loading: () => "Aggiunta della carta",
      submit: () => "Aggiungi carta",
      title: () => "Aggiungi una carta",
    },
    billingHistory: {
      table: {
        amount: () => "Quantità",
        date: () => "Data",
        description: () => "Descrizione",
      },
      title: () => "Cronologia della fatturazione",
    },
    paymentFailed: () =>
      "Pagamento non riuscito. Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste.",
    paymentFailedCode: (d) =>
      "Pagamento non riuscito. Si è verificato un problema con il nostro processore di pagamento. Riprova o contatta l'assistenza con il codice di errore " +
      d.code +
      " .",
    paymentMethod: {
      button: () => "Aggiungi carta",
      ending: () => "finendo",
      expired: () => "Scaduto",
      expires: () => "Scade",
      inUse: () => "In uso",
      title: () => "Carta attiva",
    },
    paymentStatus: {
      failed: {
        description: () =>
          "Aggiorna i dati della tua carta per continuare a utilizzare tutte le funzionalità dell'app.",
        title: () => "Pagamento non riuscito",
      },
    },
    somethingWentWrong: () => "Qualcosa è andato storto",
    stripe: {
      44359: () =>
        "44359: Si è verificato un errore durante la creazione del metodo di pagamento",
      45732: () =>
        "45732: Si è verificato un problema durante la ricerca dell'elemento della carta",
      applied: () => "Applicato",
      apply: () => "Fare domanda a",
      cardSuccessfullyAdded: () => "Carta aggiunta correttamente.",
      clear: () => "Chiaro",
      couponExpired: (d) => "Il coupon " + d.name + " è scaduto",
      couponNotValid: (d) => "Il coupon " + d.name + " non è valido",
      discountApplied: (d) =>
        "Sconto applicato " + d.percent + " sul tuo primo anno!",
      failedToAddTheCard: () => "Impossibile aggiungere la carta",
      noPortal: () =>
        "Impossibile accedere al Portale Stripe. Riprova o contatta l'assistenza.",
      noSession: () =>
        "Impossibile creare una sessione Stripe. Riprova o contatta l'assistenza.",
      noStripe: () =>
        "Si è verificato un problema durante l'accesso a Stripe. Riprova o contatta l'assistenza.",
    },
    title: () => "Fatturazione",
    wentWrongBillingHistory: () =>
      "Si è verificato un problema durante l'acquisizione della cronologia di fatturazione. Riprova più tardi.",
    wentWrongCoupon: () =>
      "Si è verificato un problema durante l'acquisizione dei dettagli del coupon. Riprova più tardi.",
    wentWrongPaymentMethods: () =>
      "Si è verificato un problema con l'ottenimento dei metodi di pagamento. Riprova più tardi.",
  },
  bulkSelect: {
    moreActions: () => "Altre azioni",
    recategoriseAs: () => "Ricategorizzare come",
  },
  button: {
    accept: () => "Accettare",
    decline: () => "Declino",
    pending: () => "In attesa di",
  },
  calcoTour: {
    buttons: {
      back: () => "Indietro",
      next: () => "Prossimo",
    },
    reconciliation: {
      uncategorise: {
        CTA: () => "Inizia la categorizzazione",
        addAnotherAccount: () =>
          "Se ti accorgi di aver dimenticato di importare un account, puoi aggiungerne un altro qui",
        associateTx: (d) =>
          "Per aiutarti a identificare e categorizzare questa transazione, visualizza tutte le altre transazioni con " +
          d.counterParty,
        categoriesButton: () =>
          "Se sai di cosa tratta questa transazione, categorizzala e noi applicheremo automaticamente la categoria ad altre transazioni simili.",
        exampleTx: () =>
          "Ecco un esempio di transazione che dovresti categorizzare.",
        incomingWallet: () =>
          "Questo è il portafoglio che ha ricevuto la risorsa.",
        outgoingWallet: () =>
          "Questo è il portafoglio che ha inviato la risorsa.",
        typeSelectorIncoming: () =>
          'Questa è etichettata come "in entrata" poiché hai ricevuto un bene, ma è necessaria una categoria più specifica per determinare l\'esito fiscale.',
        typeSelectorOutgoing: () =>
          'Questa è etichettata come "in uscita" poiché hai inviato un asset, ma è necessaria una categoria più specifica per determinare l\'esito fiscale.',
      },
    },
  },
  cancel: () => "Cancellare",
  changeLang: {
    changeLang: () => "Cambia lingua",
    error: () => "Errore durante il salvataggio della preferenza della lingua",
    language: () => "Lingua",
    languages: () => "Lingue",
    pleaseSelect: () => "Seleziona la tua lingua",
  },
  clear: () => "Chiaro",
  clientPays: {
    bannerText: () =>
      "Affinché il tuo commercialista possa generare i tuoi rendiconti fiscali, dovrai aggiornare il tuo abbonamento.",
    bannerTitle: () => "Esegui l'aggiornamento per generare report fiscali",
    buttonText: () => "Aggiorna abbonamento",
  },
  clients: {
    cancelSub: {
      areYouSure: () =>
        "Una volta terminato il periodo di fatturazione corrente, questo cliente verrà annullato. Tutti i dati e i link al cliente verranno conservati. Vuoi davvero annullare l'abbonamento?",
      buttonText: () => "Annulla abbonamento",
      exit: () => "Uscita",
    },
    clone: {
      clone: () => "Clone",
      cloneClient: () => "Clona il client",
      clonesAccountantData: () =>
        "Questo clonerà i dati che hai caricato per questo cliente (ad esempio, non i dati del tuo cliente se stai visualizzando il suo account). Questo non avrà alcun impatto sull'account esistente. L'account clonato sarà un account gratuito, ma potrebbe essere aggiornato.",
    },
    delete: {
      accessOutcome: () =>
        "I dati diventeranno inaccessibili a te e ai tuoi collaboratori.",
      areYouSure: (d) =>
        select(d.name, {
          unknown: " Sei sicuro di voler eliminare questo client? ",
          other:
            ' Sei sicuro di voler eliminare il client " ' + d.name + ' "? ',
        }),
      cannotBeUndone: () => "Questa azione non può essere annullata.",
      clientAccessOutcome: (d) =>
        select(d.accountantOwnedData, {
          true: " Anche il tuo cliente perderà l'accesso ai propri dati. ",
          other: " Il tuo cliente continuerà ad avere accesso ai propri dati. ",
        }),
      deleteButton: () => "Eliminare",
      deleteClient: () => "Elimina cliente",
    },
    invite: {
      emailRequired: () => "E-mail richiesta",
      fromName: () => "Il tuo nome",
      fromNamePlaceholder: () => "Il tuo nome completo",
      invite: () => "Invitare",
      inviteClient: () => "Invita il cliente",
      inviteWarning: () =>
        "Quando il cliente accetta l'invito, per impostazione predefinita, vedrà i dati che hai caricato per suo conto. Se il cliente desidera vedere i dati originali che ha caricato, ha questa opzione nelle sue impostazioni.",
      nameRequired: () => "Nome obbligatorio",
      toEmail: () => "E-mail del cliente",
      toEmailPlaceholder: () => "L'email del tuo cliente",
    },
    migrate: {
      settingsBody: () =>
        "A partire dall'8 settembre 2022, tutti i conti demo sono stati ritirati. Tutte le transazioni importate in precedenza sono state migrate a un nuovo client, che può essere eliminato in qualsiasi momento.",
      settingsTitle: () => "Niente più conti demo",
    },
    newAccountInvite: {
      acceptFailed: () => "Impossibile accettare l'invito, riprova.",
      declineFailed: () => "Impossibile rifiutare l'invito, riprova.",
      isNotActionable: () =>
        "Questo invito non è più valido. Contatta l'assistenza per ricevere assistenza.",
      textAccountantOwns: (d) =>
        "Il tuo commercialista ( " +
        d.email +
        " ) ti ha invitato a collaborare. Accettando questo invito potrai visualizzare e modificare i dati che il tuo commercialista ha caricato per tuo conto. Puoi revocare l'accesso in qualsiasi momento, tuttavia i dati rimarranno al tuo commercialista. Sei sicuro di voler accettare questo invito?",
      textClientOwns: (d) =>
        "Il tuo commercialista ( " +
        d.email +
        " ) ti ha invitato a collaborare. Accettando questo invito, il tuo commercialista potrà visualizzare e modificare i tuoi dati. Puoi revocare l'accesso in qualsiasi momento. Sei sicuro di voler accettare questo invito?",
      title: () => "Sei stato invitato dal tuo commercialista",
    },
    reinvite: {
      emailRequired: () => "E-mail richiesta",
      fromName: () => "Il tuo nome",
      fromNamePlaceholder: () => "Il tuo nome completo",
      nameRequired: () => "Nome obbligatorio",
      reinvite: () => "Reinvitare",
      reinviteClient: () => "Reinvita il cliente",
      reinviteWarning: () =>
        "Accettando l'invito, il cliente visualizzerà l'account condiviso.",
      toEmail: () => "E-mail del cliente",
      toEmailPlaceholder: () => "L'email del tuo cliente",
    },
    settings: () => "Impostazioni del cliente",
    uncancelSub: {
      areYouSure: () =>
        "Questo cliente non verrà più annullato e ti verrà addebitato l'importo quando inizierà il nuovo periodo di fatturazione. Vuoi davvero annullare l'abbonamento?",
      buttonText: () => "Annulla abbonamento",
      exit: () => "Uscita",
    },
  },
  close: () => "Vicino",
  collaborators: {
    cancelInvitation: () => "Annulla invito",
    cancelInvitationGenericError: () =>
      "Si è verificato un problema durante l'eliminazione dell'invito. Riprova più tardi.",
    cancelInvitationSuccess: () => "Invito annullato con successo.",
    email: () => "E-mail",
    invitationSent: () => "Invito inviato!",
    inviteTeamMember: () => "Invita un membro del team",
    inviteTeamMembers: {
      linkText: () => "Invita i membri",
      postLinkText: () => "per collaborare e condividere i clienti",
    },
    name: () => "Nome",
    pending: () => "In attesa di",
    reinvite: () => "Reinvita un membro del team",
    reinviteSuccess: () => "Membro del team reinvitato con successo.",
    revokeAccess: () => "Revoca accesso",
    revokeAccessSuccess: () => "Accesso revocato con successo.",
    send: () => "Inviare",
    team: () => "Squadra",
    teamMemberEmail: () => "Indirizzo email del membro del team",
    teamMemberName: () => "Nome completo del membro del team",
    teamMembers: () => "Membri del team",
    teamMembersEmpty: () => "Nessun membro del team invitato",
  },
  confirm: () => "Confermare",
  contacts: {
    addAddress: {
      addAddress: () => "Aggiungi indirizzo",
      address: () => "Indirizzo",
      blockchain: () => "Catena di blocchi",
      buttons: {
        add: () => "Aggiungere",
        cancel: () => "Cancellare",
      },
      duplicateAddress: () => "Indirizzo duplicato",
      title: () => "Aggiungi nuovo indirizzo",
    },
    addContact: () => "Aggiungi contatto",
    addressBook: () => "Contatti",
    contact: () => "Nome contatto",
    create: {
      addAddress: () => "Aggiungi indirizzo",
      address: () => "Indirizzo",
      alreadyExists: () => "Il contatto esiste già",
      associatedAddresses: () => "Indirizzi associati",
      blockchain: () => "Catena di blocchi",
      cancel: () => "Cancellare",
      createContact: () => "Crea contatto",
      duplicateAddress: () => "Indirizzo duplicato",
      name: () => "Nome contatto",
      nameTitle: () => "Nome",
      title: () => "Nuovo contatto",
    },
    csv: {
      errorFileNotCsv: () => "Deve essere un CSV",
      exportCsv: () => "Esportazione indirizzi non identificati CSV",
      importCsv: () => "Importa CSV",
      success: (d) => d.number + " contatti aggiornati",
    },
    edit: {
      associatedAddresses: () => "Indirizzi associati",
      rules: () => "Regole",
    },
    entityType: {
      contract: () => "Contratto intelligente",
      imported: () => "Portafoglio importato",
      manual: () => "Contatto manuale",
    },
    error: () => "Errore",
    findAddress: () => "Trova indirizzo",
    identified: () => "Identificato",
    identifiedTable: {
      allTooltip: () =>
        "Alcuni indirizzi in questo contatto sono stati identificati in tutte le reti",
      asterisk: () => "*",
      networks: () => "Reti",
      rules: () => "Regole",
      rulesApplied: (d) =>
        plural(d.count, 0, it, {
          one: " 1 regola ",
          other: number("it", d.count, 0) + " regole ",
        }) + " applicato",
      setRule: () => "Imposta regola",
      transactions: () => "Transazioni",
    },
    newContact: () => "Aggiungi nuovo contatto",
    noContacts: () =>
      'Fai clic su "Aggiungi contatto" per aggiungere il tuo primo contatto.',
    noResults: () => "Nessun risultato di ricerca",
    noUnidentified: () =>
      "Tutti gli indirizzi identificati. Niente da vedere qui.",
    required: () => "Necessario",
    ruleCard: {
      active: () => "Attivo",
      addCondition: () => "Aggiungi condizione",
      appliesTo: (d) =>
        "Questa regola si applica a " +
        plural(d.count, 0, it, {
          one: " 1 transazione ",
          other: number("it", d.count, 0) + " transazioni ",
        }),
      cancel: () => "Cancellare",
      createMoreRules: () => "Crea un'altra regola",
      createRule: () => "Crea regola",
      deleteRule: () => "Elimina regola",
      disableRule: () => "Disabilita regola",
      disabled: () => "Disabilitato",
      disabledTooltip: () =>
        "La regola non viene applicata alle nuove transazioni. Le transazioni esistenti sono ancora collegate a questa regola e i suoi effetti non saranno sostituiti da altre regole",
      editRule: () => "Modificare",
      enableRule: () => "Abilita regola",
      enabledTooltip: () =>
        "Questa regola è attiva e si applicherà alle transazioni nuove ed esistenti",
      if: () => "SE",
      newRule: (d) => "Regola " + d.number,
      save: () => "Salva",
      setAccount: () => "Aggiungi operazione",
      then: () => "POI",
      tooltip: () =>
        "Si prega di collegare il software di contabilità per creare regole",
      view: () => "Visualizzazione",
      willApplyTo: (d) =>
        "Questa regola si applicherà a " +
        plural(d.count, 0, it, {
          one: " 1 transazione ",
          other: number("it", d.count, 0) + " transazioni ",
        }),
    },
    rules: {
      importsDrawerTitle: () => "Gestisci le regole",
      operators: {
        changeErpAssetAccount: () => "Conto patrimoniale",
        changeErpCashAccount: () => "Conto cassa",
        changeErpGainsAccount: () => "Conto guadagni",
        changeErpLoanAccount: () => "Conto prestito",
        changeErpPnLAccount: () => "Conto profitti e perdite",
        recategorise: () => "Categoria",
      },
      ruleNotFound: () => "Regola non trovata",
      rulesImportsButton: () => "Regole",
    },
    table: {
      address: () => "Indirizzo",
      addresses: () => "Indirizzi",
      name: () => "Nome",
      transactions: () => "Transazioni",
      type: () => "Tipo",
    },
    unidentified: () => "Non identificato",
    unidentifiedTable: {
      assets: () => "Risorse inviate/ricevute",
      chains: () => "Catene",
      firstDate: () => "Prima data di tx",
      lastDate: () => "Data dell'ultimo tx",
      modal: {
        assets: () => "Attività:",
        buttonCTA: () => "Identificare l'indirizzo",
        importCTA: () => "Importalo",
        importLabel: () => "Questo è il tuo portafoglio?",
        transferAmount: () => "Importo del bonifico:",
        txs: () => "Transazioni:",
      },
      transferAmount: () => "Importo del trasferimento",
      txs: () => "Transazioni",
    },
  },
  context: () => "Visualizza nel contesto",
  copied: () => "Copiato",
  copy: () => "Copia",
  copyAddress: () => "Copia indirizzo",
  copyTxLink: () => "Copia il collegamento",
  copyTxLinkSuccess: () =>
    "Copiato il collegamento alla transazione negli appunti",
  countries: {
    AD: () => "Andorra",
    AE: () => "Emirati Arabi Uniti",
    AF: () => "Afganistan",
    AG: () => "Antigua e Barbuda",
    AI: () => "Anguilla",
    AL: () => "Albania",
    AM: () => "Armenia",
    AO: () => "Angola",
    AQ: () => "Antartide",
    AR: () => "Argentina",
    AS: () => "Samoa americane",
    AT: () => "Austria",
    AU: () => "Australia",
    AW: () => "Aruba",
    AZ: () => "Azerbaigian",
    BA: () => "Bosnia ed Erzegovina",
    BB: () => "Barbados",
    BD: () => "Bangladesh",
    BE: () => "Belgio",
    BF: () => "Burkina Faso",
    BG: () => "Bulgaria",
    BH: () => "Bahrein",
    BI: () => "Burundi",
    BJ: () => "Benin",
    BL: () => "Saint Barthélemy",
    BM: () => "Bermuda",
    BN: () => "Brunei Darussalam",
    BO: () => "Bolivia",
    BQ: () => "Bonaire",
    BR: () => "Brasile",
    BS: () => "Bahamas",
    BT: () => "Il Bhutan",
    BV: () => "Isola Bouvet",
    BW: () => "Botswana",
    BY: () => "Bielorussia",
    BZ: () => "Belize",
    CA: () => "Canada",
    CC: () => "Isole Cocos",
    CD: () => "Repubblica Democratica del Congo",
    CF: () => "Repubblica Centrafricana",
    CG: () => "Congo",
    CH: () => "Svizzera",
    CI: () => "Costa d'Avorio",
    CK: () => "Isole Cook",
    CL: () => "Chile",
    CM: () => "Camerun",
    CN: () => "Cina",
    CO: () => "Colombia",
    CR: () => "Costa Rica",
    CU: () => "Cuba",
    CV: () => "Capo Verde",
    CW: () => "Curaçao",
    CX: () => "Isola di Natale",
    CY: () => "Cipro",
    CZ: () => "Repubblica Ceca",
    DE: () => "Germania",
    DJ: () => "Gibuti",
    DK: () => "Danimarca",
    DM: () => "La dominica",
    DO: () => "Repubblica Dominicana",
    DZ: () => "Algeria",
    EC: () => "Ecuador",
    EE: () => "Estonia",
    EG: () => "Egitto",
    EH: () => "Sahara Occidentale",
    ER: () => "Eritrea",
    ES: () => "Spagna",
    ET: () => "Etiopia",
    FI: () => "Finlandia",
    FJ: () => "Figi",
    FK: () => "Isole Falkland",
    FM: () => "Micronesia",
    FO: () => "Isole Faroe",
    FR: () => "Francia",
    GA: () => "Gabon",
    GD: () => "Grenada",
    GE: () => "Georgia",
    GF: () => "Guyana francese",
    GG: () => "Guernsey",
    GH: () => "Ghana",
    GI: () => "Gibilterra",
    GL: () => "Groenlandia",
    GN: () => "Guinea",
    GP: () => "Guadalupa",
    GQ: () => "Guinea Equatoriale",
    GR: () => "Grecia",
    GS: () => "Georgia del Sud e Isole Sandwich Australi",
    GT: () => "Guatemala",
    GU: () => "Guam",
    GW: () => "Guinea-Bissau",
    GY: () => "Guyana",
    HK: () => "Hong Kong",
    HM: () => "Isole Heard e McDonald",
    HN: () => "Honduras",
    HR: () => "Croazia",
    HT: () => "Haiti",
    HU: () => "Ungheria",
    ID: () => "Indonesia",
    IE: () => "Irlanda",
    IL: () => "Israele",
    IM: () => "Isola di Man",
    IN: () => "India",
    IQ: () => "Iraq",
    IR: () => "L'Iran",
    IS: () => "Islanda",
    IT: () => "Italia",
    JE: () => "Maglia",
    JM: () => "Giamaica",
    JO: () => "Giordania",
    JP: () => "Giappone",
    KE: () => "Kenia",
    KG: () => "Kirghizistan",
    KI: () => "Kiribati",
    KN: () => "Saint Kitts e Nevis",
    KP: () => "Corea del nord",
    KR: () => "Corea del Sud",
    KW: () => "Kuwait",
    KY: () => "Isole Cayman",
    KZ: () => "Kazakistan",
    LA: () => "Repubblica Democratica Popolare del Laos",
    LB: () => "Libano",
    LC: () => "Santa Lucia",
    LI: () => "Liechtenstein",
    LK: () => "Sri Lanka",
    LR: () => "Liberia",
    LS: () => "Lesoto",
    LT: () => "Lituania",
    LU: () => "Lussemburgo",
    LV: () => "Lettonia",
    LY: () => "Libia",
    MA: () => "Marocco",
    MC: () => "Monaco",
    MD: () => "Moldavia",
    ME: () => "Montenegro",
    MF: () => "San Martino",
    MG: () => "Madagascar",
    MH: () => "Isole Marshall",
    MK: () => "Macedonia del Nord",
    ML: () => "Mali",
    MM: () => "Birmania",
    MN: () => "Mongolia",
    MO: () => "Macao",
    MP: () => "Isole Marianne Settentrionali",
    MQ: () => "Martinica",
    MR: () => "Mauritania",
    MS: () => "Monteserrato",
    MT: () => "Malta",
    MU: () => "Maurizio",
    MV: () => "Maldive",
    MW: () => "Malawi",
    MX: () => "Messico",
    MY: () => "Malaysia",
    MZ: () => "Mozambico",
    NA: () => "La Namibia",
    NC: () => "Nuova Caledonia",
    NE: () => "Niger",
    NF: () => "Isola Norfolk",
    NG: () => "Nigeria",
    NI: () => "Nicaragua",
    NL: () => "Paesi Bassi",
    NO: () => "Norvegia",
    NP: () => "Nepal",
    NR: () => "Nauru",
    NU: () => "Niue",
    NZ: () => "Nuova Zelanda",
    OM: () => "Oman",
    PA: () => "Panama",
    PE: () => "Perù",
    PG: () => "Papua Nuova Guinea",
    PH: () => "Filippine",
    PK: () => "Pakistan",
    PL: () => "Polonia",
    PM: () => "Saint Pierre e Miquelon",
    PN: () => "Pitcairn",
    PR: () => "Portorico",
    PS: () => "Palestina",
    PT: () => "Portogallo",
    PW: () => "Palau",
    PY: () => "Il Paraguay",
    QA: () => "Qatar",
    RE: () => "Riunione",
    RO: () => "Romania",
    RS: () => "Serbia",
    RU: () => "Federazione Russa",
    RW: () => "Ruanda",
    SA: () => "Arabia Saudita",
    SB: () => "Isole Salomone",
    SC: () => "Le Seychelles",
    SD: () => "Sudan",
    SE: () => "Svezia",
    SG: () => "Singapore",
    SH: () => "Sant'Elena",
    SI: () => "Slovenia",
    SJ: () => "Svalbard e Jan Mayen",
    SK: () => "Slovacchia",
    SL: () => "Sierra Leone",
    SM: () => "San Marino",
    SN: () => "Senegal",
    SO: () => "Somalia",
    SR: () => "Suriname",
    SS: () => "Sudan del Sud",
    ST: () => "Sao Tome e Principe",
    SV: () => "El Salvador",
    SX: () => "Sint Maarten",
    SY: () => "Repubblica araba siriana",
    TC: () => "Isole Turks e Caicos",
    TG: () => "Togo",
    TH: () => "Thailandia",
    TJ: () => "Tagikistan",
    TK: () => "Tokelau",
    TL: () => "Timor Est",
    TM: () => "Turkmenistan",
    TN: () => "Tunisia",
    TO: () => "Tonga",
    TR: () => "Tacchino",
    TT: () => "Trinidad e Tobago",
    TV: () => "Tuvalu",
    TW: () => "Taiwan",
    TZ: () => "Tanzania",
    UA: () => "Ucraina",
    UG: () => "Uganda",
    UK: () => "Regno Unito",
    US: () => "Stati Uniti",
    UY: () => "Uruguay",
    UZ: () => "Uzbekistan",
    VA: () => "Città del Vaticano",
    VC: () => "Saint Vincent e Grenadine",
    VE: () => "Venezuela",
    VG: () => "Isole Vergini Britanniche",
    VI: () => "Isole Vergini americane",
    VN: () => "Vietnam",
    VU: () => "Vanuatu",
    WF: () => "Wallis e Futuna",
    WS: () => "Samoa",
    YE: () => "Yemen",
    YT: () => "Majotte",
    ZA: () => "Sudafrica",
    ZM: () => "Zambia",
    ZW: () => "Zimbabwe",
  },
  countryAbbreviations: {
    AD: () => "E",
    AE: () => "Emirati Arabi Uniti",
    AF: () => "AFG",
    AG: () => "Condizioni Generali",
    AI: () => "GL in tutto il mondo",
    AL: () => "CAMICE",
    AM: () => "BRACCIO",
    AO: () => "ANG",
    AQ: () => "FORMICA",
    AR: () => "Argomento",
    AS: () => "AMS",
    AT: () => "AUT",
    AU: () => "Australia",
    AW: () => "ARU",
    AZ: () => "AZE",
    BA: () => "BOS",
    BB: () => "SBARRA",
    BD: () => "DIVIETO",
    BE: () => "BEL",
    BF: () => "BUR",
    BG: () => "BUL",
    BH: () => "BHR",
    BI: () => "BDI",
    BJ: () => "BEN",
    BL: () => "STB",
    BM: () => "BER",
    BN: () => "BRU",
    BO: () => "BOL",
    BQ: () => "BENE",
    BR: () => "REGGISENO",
    BS: () => "BAH",
    BT: () => "BHU",
    BV: () => "BVT",
    BW: () => "BOT",
    BY: () => "BLR",
    BZ: () => "BLZ",
    CA: () => "POTERE",
    CC: () => "CCK",
    CD: () => "MERLUZZO",
    CF: () => "CAF",
    CG: () => "COG",
    CH: () => "SUI",
    CI: () => "CULLA",
    CK: () => "CKI",
    CL: () => "CHI",
    CM: () => "CAMMA",
    CN: () => "CHN",
    CO: () => "COL",
    CR: () => "CRI",
    CU: () => "CUCCIOLO",
    CV: () => "TAXI",
    CW: () => "CUR",
    CX: () => "CHR",
    CY: () => "CIPRO",
    CZ: () => "Repubblica Ceca",
    DE: () => "GER",
    DJ: () => "DJI",
    DK: () => "TANA",
    DM: () => "DMA",
    DO: () => "DOM",
    DZ: () => "ALG",
    EC: () => "centralina elettronica",
    EE: () => "est",
    EG: () => "EGIZIA",
    EH: () => "ESH",
    ER: () => "ERI",
    ES: () => "ESP",
    ET: () => "ETH",
    FI: () => "FINE",
    FJ: () => "FIJ",
    FK: () => "FLK",
    FM: () => "FSM",
    FO: () => "DA DESTRO",
    FR: () => "FRA",
    GA: () => "Parlare",
    GD: () => "GRD",
    GE: () => "GEO",
    GF: () => "Repubblica Federale Tedesca",
    GG: () => "GGG",
    GH: () => "GHA",
    GI: () => "GIB",
    GL: () => "Ragazza",
    GN: () => "GIN",
    GP: () => "BPL",
    GQ: () => "EQU",
    GR: () => "VERDE",
    GS: () => "SGS",
    GT: () => "GTM",
    GU: () => "GOMMA",
    GW: () => "GNB",
    GY: () => "TIPO",
    HK: () => "Cina",
    HM: () => "Casco",
    HN: () => "ONORE",
    HR: () => "CRO",
    HT: () => "HTI",
    HU: () => "UNGHERIA",
    ID: () => "Nome ID",
    IE: () => "IRA",
    IL: () => "ISR",
    IM: () => "IMN",
    IN: () => "India",
    IQ: () => "Qualità dell'informazione",
    IR: () => "IRA",
    IS: () => "GHIACCIO",
    IT: () => "ITA",
    JE: () => "GIOCATTOLO",
    JM: () => "MARMELLATA",
    JO: () => "GIORNO",
    JP: () => "GIAPPONESE",
    KE: () => "KEN",
    KG: () => "KGZ",
    KI: () => "Chirurgo",
    KN: () => "STK",
    KP: () => "PRK",
    KR: () => "CORO",
    KW: () => "KUW",
    KY: () => "CAIA",
    KZ: () => "CAZZO",
    LA: () => "LAOTIANO",
    LB: () => "LEB",
    LC: () => "STL",
    LI: () => "MENZOGNA",
    LK: () => "ISR",
    LR: () => "LIBERAZIONE",
    LS: () => "LES",
    LT: () => "LTU",
    LU: () => "LUSSO",
    LV: () => "LAT",
    LY: () => "LBY",
    MA: () => "MOC",
    MC: () => "LUNEDÌ",
    MD: () => "MDA",
    ME: () => "MNE",
    MF: () => "STM",
    MG: () => "PAZZO",
    MH: () => "MHL",
    MK: () => "NMK",
    ML: () => "MLI",
    MM: () => "MMR",
    MN: () => "MGL",
    MO: () => "MAC",
    MP: () => "Nuovo Messico",
    MQ: () => "MTQ",
    MR: () => "metropolitana",
    MS: () => "MSR",
    MT: () => "MLT",
    MU: () => "MUS",
    MV: () => "MDV",
    MW: () => "MAI",
    MX: () => "Messico",
    MY: () => "MASCHIO",
    MZ: () => "MOZZAFIATO",
    NA: () => "Non sono un'azienda",
    NC: () => "NCL",
    NE: () => "NERA",
    NF: () => "NFK",
    NG: () => "NON TI PIACE",
    NI: () => "NIC",
    NL: () => "NON ESEGUITO",
    NO: () => "NÉ",
    NP: () => "NPL",
    NR: () => "NRU",
    NU: () => "NIU",
    NZ: () => "Nuova Zelanda",
    OM: () => "OMA",
    PA: () => "PADELLA",
    PE: () => "PER",
    PG: () => "PNG",
    PH: () => "PHI",
    PK: () => "PAKISTAN",
    PL: () => "POLITICA",
    PM: () => "STP",
    PN: () => "PCN",
    PR: () => "PRI",
    PS: () => "PSE",
    PT: () => "PERO",
    PW: () => "Persone con disabilità",
    PY: () => "PAR",
    QA: () => "QAT",
    RE: () => "REU",
    RO: () => "ROM",
    RS: () => "SERBATOIO",
    RU: () => "Russo",
    RW: () => "RWA",
    SA: () => "SAU",
    SB: () => "SOL",
    SC: () => "SEGGIO",
    SD: () => "SDN",
    SE: () => "SVE",
    SG: () => "PECCATO",
    SH: () => "SOLO PERCHÉ",
    SI: () => "SVN",
    SJ: () => "SJM",
    SK: () => "SVK",
    SL: () => "LES",
    SM: () => "SMR",
    SN: () => "SEN",
    SO: () => "SOM",
    SR: () => "SUR",
    SS: () => "Unità SSD",
    ST: () => "SAO",
    SV: () => "ELS",
    SX: () => "SMX",
    SY: () => "SIRR",
    TC: () => "TCA",
    TG: () => "TOG",
    TH: () => "THA",
    TJ: () => "TJK",
    TK: () => "TKL",
    TL: () => "TLS",
    TM: () => "TKM",
    TN: () => "TONO",
    TO: () => "TONNELLATA",
    TR: () => "TUR",
    TT: () => "TTO",
    TV: () => "Certificazione TÜV",
    TW: () => "TWN",
    TZ: () => "ABBRONZATO",
    UA: () => "Regno Unito",
    UG: () => "UGA",
    UK: () => "Regno Unito",
    US: () => "NOI",
    UY: () => "URU",
    UZ: () => "UZB",
    VA: () => "I.V.A.",
    VC: () => "STV",
    VE: () => "VEN",
    VG: () => "VGB",
    VI: () => "VIR",
    VN: () => "VIAGGIARE",
    VU: () => "VUT",
    WF: () => "Il FOL",
    WS: () => "SAM",
    YE: () => "YEM",
    YT: () => "Il mio",
    ZA: () => "RSA",
    ZM: () => "ZAM",
    ZW: () => "ZWE",
  },
  coupon: {
    label: () => "Inserisci il codice promozionale",
    placeholder: () => "Codice promozionale",
  },
  couponIntegration: {
    CB140: () => "Coinbase",
    COINBASE30: () => "Coinbase",
    COINJARUK: () => "CoinJar",
    COINSTATS: () => "CoinStats",
    CTCFRIEND20: () => "Invita un amico",
    METAMASK10: () => "Metamask",
    MMCTC30: () => "MetaMaschera",
    bitcoincomau30: () => "bitcoin.com.au",
    cb1rookie: () => "Base monetaria",
    coinbase140: () => "Base monetaria",
    coinbase140p: () => "Base monetaria",
    coinbase1p: () => "Base monetaria",
    ir30: () => "Independent Reserve",
    metamask30: () => "MetaMaschera",
  },
  createTransactionBefore: () => "Crea transazione prima",
  ctcAutomations: () => "✨ Magia del CTC ✨",
  currency: {
    errorLoadingAllCurrencies: () =>
      "Errore durante il caricamento di tutte le valute",
    errorLoadingUserCurrencies: () =>
      "Errore durante il caricamento delle valute utente",
    unableToFindPrice: (d) =>
      "Impossibile trovare il prezzo di mercato per " + d.name,
    wentWrongFetchingPrice: (d) =>
      "Si è verificato un errore durante il recupero del prezzo per " + d.name,
  },
  currentPlan: () => "Piano attuale",
  darkModeBanner: {
    actionButton: () => "Prova la modalità scura",
    cancelButton: () => "No grazie",
    description: () =>
      "Passa dalla modalità scura a quella chiara dal menu del tuo profilo.",
    title: () => "È arrivata la modalità scura!",
  },
  dashboard: {
    allAssets: () => "Tutte le risorse",
    balance: () => "Bilancia",
    carousel: {
      moneyBack: {
        description: () => "Prova senza rischi",
        subtitle: () =>
          "Offriamo una garanzia di rimborso di 30 giorni su tutti i piani.",
        title: () => "Garanzia soddisfatti o rimborsati",
      },
      partnerOffer: {
        description: () => "Sconto del 30%",
        subtitle: () => "Applicato automaticamente al checkout",
        title: () => "Offerta speciale partner",
      },
    },
    chartCalculationDialog: {
      body1: () =>
        "Per ottenere un risultato accurato, importa tutte le tue transazioni ",
      note: () =>
        "Nota: se il prezzo di una valuta non può essere determinato durante il periodo in cui è stata detenuta, la valuta verrà esclusa.",
      title: () => "Calcoli del grafico spiegati",
    },
    costBasis: () => "Base di costo",
    costBasisTooltip: () =>
      "Base di costo combinata (comprese le commissioni) delle attività che hanno un valore di mercato corrente",
    currentBalance: () => "Saldo corrente",
    embedded: {
      accuracyChecks: () => "Controlli di accuratezza",
      accuracyComplete: () =>
        "Tutti i tuoi compiti di precisione sono stati completati.",
      accuracyTasks: () => "Compiti di precisione",
      accuracyTasksAction: () => "Inizia la revisione",
      accuracyTasksDescription: () =>
        "Ci sono attività di precisione da completare che aiutano a garantire che i tuoi guadagni siano corretti e conformi (è richiesto un piano).",
      countdownBanner: {
        title: () => "Tempo rimanente per presentare le tue tasse all'IRS",
        tooltip: () =>
          "La scadenza per la presentazione delle tasse è martedì 15 aprile 2025",
      },
      estimatedGains: () => "Stima delle plusvalenze",
      estimatedGainsTooltip: () =>
        "L'utile in conto capitale è una stima basata sulle informazioni fornite.",
      fy: () => "AF",
      getTaxReports: () => "Ottieni i report fiscali",
      missingAccounts: () => "Conti mancanti",
      missingAccountsAction: () => "Aggiungi account",
      missingAccountsDescription: () =>
        "Alcuni account non sono collegati, con conseguenti transazioni mancanti. Questo potrebbe causare una sovrastima dei tuoi guadagni.",
      remindMeLater: {
        title: () =>
          "Ricevi un promemoria per la dichiarazione fiscale e ottieni un ulteriore 5% di sconto",
        description: () =>
          "Preferisci fare la dichiarazione dei redditi più tardi? Ti capiamo. Registrati e aggiungeremo un ulteriore 5% di sconto quando acquisterai un piano.",
        buttonText: () => "Ricordamelo",
        modals: {
          reminder: {
            chipText: () => "Imposta un promemoria via email",
            title: () => "Ottieni un ulteriore 5% di sconto su tutti i piani!",
            description: () =>
              "Fornisci la tua email per ottenere uno sconto totale del 35% sul tuo report fiscale, solo per essere un utente MetaMask.",
            emailLabel: () => "Inserisci il tuo indirizzo email",
            emailError: () => "Inserisci un indirizzo email valido",
            buttonText: () => "Ricordamelo",
            privacyNotice: () =>
              "Continuando accetti di ricevere occasionalmente email di marketing.",
          },
          success: {
            chipText: () => "Promemoria impostato!",
            title: () => "Sconto extra del 5% applicato.",
            description: () =>
              "Hai ottenuto uno sconto del 35% sul tuo report fiscale per essere un utente MetaMask.",
            buttonText: () => "Ottieni subito il tuo report fiscale",
          },
        },
      },
      savingsOpportunity: () => "La tua opportunità di risparmio:",
      savingsOpportunityTooltip: () =>
        "L'opportunità di risparmio è l'importo stimato di cui potresti ridurre i tuoi guadagni in conto capitale se collegassi tutti i tuoi conti e rivedessi le attività di conformità",
    },
    error: () =>
      "Si è verificato un problema durante l'acquisizione dei dati del dashboard. Riprova più tardi.",
    feesPaid: () => "Tasse pagate",
    feesPaidTooltip: () =>
      "Il valore fiat totale delle commissioni che hai pagato.",
    fiatInvested: () => "Fiat ha investito",
    fiatProceeds: () => "Proventi Fiat",
    fiatProceedsTooltip: () =>
      "L'importo di fiat che hai ricevuto a seguito della vendita della tua criptovaluta",
    heading: () => "Pannello di controllo",
    holdings: {
      balanceSyncTimeExplainer: () =>
        "I saldi temporali sono stati sincronizzati l'ultima volta per questa importazione",
      balances: {
        subheading: () =>
          "Passa dalla visualizzazione dei saldi calcolati da Crypto Tax Calculator al saldo effettivo sullo scambio. Qualsiasi differenza potrebbe essere dovuta a transazioni non riconciliate o mancanti.",
        title: () => "Saldi rimanenti",
      },
      balancesSubheading: () =>
        "Questo è un testo esplicativo che spiega come vengono calcolati i saldi e come l'utente può passare dal saldo nell'app a quello di borsa.",
      balancesTitle: () => "Saldi rimanenti",
      breakdownListNegative: () =>
        "Le partecipazioni correnti sono negative. Ciò può essere causato da dati non categorizzati o mancanti.",
      breakdownListTitle: () => "Titoli in criptovaluta",
      breakdownListTooltip: {
        marketPrice: (d) =>
          "Prezzo di mercato di " + d.symbol + " in " + d.currency,
        positiveBalance: (d) =>
          "Il saldo corrisponde alle partecipazioni su " + d.exchangeName,
        review: {
          access: () =>
            "per accedere al nostro strumento di revisione e saperne di più.",
          subscribe: () => "Iscriviti",
          tips: () => "Revisione",
          toLearnMore: () => "le tue transazioni per saperne di più.",
        },
        warningBalanceLessThan: (d) =>
          "Abbiamo calcolato " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " ( " +
          d.fiatDifference +
          " ) in meno rispetto a quanto segnalato dal tuo exchange. Ciò può essere causato da dati non categorizzati o mancanti.",
        warningBalanceMoreThan: (d) =>
          "Abbiamo calcolato " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " ( " +
          d.fiatDifference +
          " ) in più rispetto a quanto segnalato dal tuo exchange. Ciò può essere causato da dati non categorizzati o mancanti.",
        warningBalanceNoMarketPriceLessThan: (d) =>
          "Abbiamo calcolato " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " in meno rispetto a quanto segnalato dal tuo exchange. Ciò può essere causato da dati non categorizzati o mancanti.",
        warningBalanceNoMarketPriceMoreThan: (d) =>
          "Abbiamo calcolato " +
          d.currencyAmountDifference +
          " " +
          d.currencyLabel +
          " in più rispetto a quanto segnalato dal tuo exchange. Ciò può essere causato da dati non categorizzati o mancanti.",
      },
      cost: () => "Costo",
      costTooltip: () => "Base di costo totale",
      costTooltipTitle: () => "Base di costo totale",
      currency: () => "Valuta",
      empty: {
        title: () => "Nessun dato aggiunto",
      },
      marketPrice: () => "Prezzo",
      marketPriceTooltip: () => "Prezzo di mercato attuale",
      marketValue: () => "Valore",
      marketValueTooltip: () => "Valore di mercato attuale",
      moreThan: () => "Più di",
      of: () => "Di",
      quantity: () => "Quantità",
      roi: () => "Ritorno sull'investimento",
      roiTooltip: () => "Ritorno sull'investimento",
      rowsPerPage: () => "Righe per pagina",
      showAppBalances: () => "Mostra i saldi nell'app",
      syncStatus: {
        fail: () => "Sincronizzazione non riuscita",
        noSync: () => "Nessuna ultima sincronizzazione",
        success: () => "Sincronizzato",
      },
      title: () => "Attività",
      tooltip: {
        off: () => "Visualizzazione dei saldi importati dall'API di origine",
        on: () => "Visualizzazione dei saldi calcolati dall'applicazione",
        sort: () => "Clicca per ordinare in base al valore di mercato totale",
      },
      unrealizedGain: () => "Guadagno non realizzato",
      unrealizedGainTooltip: () => "Guadagno non realizzato",
    },
    holdingsTypes: {
      calculatedBody: () =>
        "Mostra i tuoi saldi calcolati da CTC, in base ai tuoi wallet e exchange importati. La precisione si basa sulla corretta riconciliazione delle transazioni.",
      calculatedSwitch: () => "Calcolato",
      calculatedTitle: () => "Bilancio storico",
      done: () => "Fatto",
      reportedBody: () =>
        "Mostra una vista live dei tuoi saldi segnalati dai tuoi exchange e wallet. I saldi che non sono nei tuoi wallet non saranno mostrati. Nota che le importazioni CSV, così come alcuni exchange e wallet, non supportano la segnalazione live dei saldi e, in questi casi, verranno utilizzate le partecipazioni calcolate da CTC.",
      reportedSwitch: () => "Segnalato",
      reportedTitle: () => "Equilibrio in tempo reale",
      title: () => "Tipo di saldo",
    },
    howThisChartIsCalculated: () => "Come viene calcolato questo grafico",
    inflow: () => "Afflusso",
    inflowOutflowChart: {
      header: () => "Afflusso/deflusso",
      legend: {
        fees: () => "Commissioni",
        inflow: () => "Afflusso",
        net: () => "Flusso netto",
        outflow: () => "Deflusso",
      },
      linear: () => "Passa a lineare",
      log: () => "Passa a logaritmico",
      timeframeSelector: {
        month: () => "Mese",
        placeholder: () => "Seleziona l'intervallo di tempo",
        quarterToDate: () => "Trimestre ad oggi",
        year: () => "Anno",
        ytd: () => "Da inizio anno",
      },
      tooltip: {
        fees: () => "Commissioni:",
        inflow: () => "Afflusso:",
        net: () => "Flusso netto:",
        outflow: () => "Deflusso:",
      },
    },
    inflowTooltip: () =>
      "Il valore degli asset ricevuti nei tuoi conti collegati. I guadagni/le perdite realizzati dalle negoziazioni sono esclusi",
    losers: () => "Perdenti",
    missingImportsWarning: {
      actionButton: () => "Gestisci impostazioni",
      description: () =>
        'Senza tutte le fonti di dati non possiamo riflettere accuratamente il tuo saldo complessivo. Puoi gestire quali dati visualizzare nelle impostazioni, in "Impostazioni saldo".',
      importsTitle: () => "Attività importate",
      nextStepButton: () => "Importa dati",
      overallSubtitle: () =>
        "Si tratta di asset presenti su portafogli o exchange che non hai ancora importato.",
      overallTitle: () => "Risorse rilevate",
      title: () => "Gestisci i dati importati",
    },
    negativeBalanceWarning: (d) =>
      "Il saldo dei tuoi titoli " +
      d.currencySymbol +
      " è negativo, controlla le tue transazioni per riconciliare questo errore",
    netFiatInvested: () => "Fiat netto investito",
    netFiatInvestedTooltip: () =>
      "L'importo di valuta fiat che hai utilizzato per acquistare criptovalute",
    nfts: {
      filter: {
        allChains: () => "Tutte le catene",
        chainPlaceholder: () => "Cerca per catena",
      },
      imageNotFound: () => "Immagine non trovata",
      noNFTs: () => "Nessun NFT trovato",
      noNFTsCTA: (d) =>
        "I tuoi NFT appariranno qui, per iniziare [importa i dati.](" +
        d.linkTextKey +
        ")",
    },
    noAssetAllocation: {
      body: () => "I tuoi asset principali appariranno qui. Per iniziare",
      importData: () => "importare dati",
      title: () => "Nessun dato aggiunto",
    },
    noHoldings: {
      body: () =>
        "Tutti i tuoi possedimenti importati appariranno qui. Per iniziare",
      importData: () => "importare dati",
      title: () => "Nessun dato aggiunto",
    },
    noWinnersLosers: {
      body: () =>
        "I tuoi guadagni e perdite più importanti appariranno qui. Per iniziare",
      importData: () => "importare dati",
      title: () => "Nessun dato aggiunto",
    },
    noWinnersLosersToDisplay: (d) =>
      "Nessuna " + d.direction + " da visualizzare...",
    notApplicable: () => "N / A",
    other: () => "Altro",
    outflow: () => "Deflusso",
    outflowTooltip: () =>
      "Il valore degli asset inviati dai tuoi account collegati. I guadagni/le perdite realizzati dalle negoziazioni sono esclusi",
    pageHeading: () => "Portfolio",
    percentageTooltipTitle: () =>
      "Variazione relativa del saldo complessivo rispetto alla base di costo",
    portfolioChart: {
      error: () =>
        "Errore durante il caricamento delle prestazioni del portafoglio, riprova più tardi",
      loading: () =>
        "Generazione di dati storici. Potrebbe volerci del tempo...",
      noData: {
        body: () =>
          "Le performance del tuo portafoglio appariranno qui. Per iniziare",
        importData: () => "importare dati",
        title: () => "Nessun dato aggiunto",
      },
      totalValue: () => "Valore totale",
    },
    portfolioPerformance: () => "Performance del portafoglio",
    portfolioPerformanceTooltip: () =>
      "Le performance del tuo portafoglio nell'ultimo anno. Eventuali partecipazioni negative o saldi di valore pari a zero vengono ignorati.",
    portfolioTimeframe: {
      all: () => "Tutto",
      day: () => "1D",
      month: () => "1 milione",
      week: () => "1Settembre",
      year: () => "1 anno",
      ytd: () => "anno corrente",
    },
    portfolioTooltip: {
      accuracyNote: () =>
        "Le prestazioni si basano sui saldi calcolati in Crypto Tax Calculator. La precisione si basa sulla corretta riconciliazione delle transazioni.",
      all: () => "La performance del tuo portafoglio dal tuo primo acquisto.",
      day: () => "La performance del tuo portafoglio nell'ultimo giorno.",
      ignoreNegativeHoldings: () =>
        "Eventuali partecipazioni negative o saldi di valore pari a zero vengono ignorati.",
      month: () => "La performance del tuo portafoglio nell'ultimo mese.",
      week: () => "La performance del tuo portafoglio nell'ultima settimana.",
      year: () => "La performance del tuo portafoglio nell'ultimo anno.",
    },
    somethingWentWrong: () =>
      "Qualcosa è andato storto. Prova a ricaricare la pagina.",
    step1: {
      content: () =>
        "Importa tutta la cronologia delle tue transazioni selezionando il tuo exchange e seguendo le istruzioni associate. Puoi importare tramite CSV, API o connettere direttamente il tuo wallet.",
      detailedGuide: () => "Guida dettagliata",
      link: () => "/imports",
      title: () => "1. Importa dati",
    },
    step2: {
      content: () =>
        "Assicurati di aver importato le transazioni da tutti i tuoi exchange e di averle categorizzate correttamente nella pagina delle transazioni.",
      link: () => "/transazioni",
      title: () => "2. Rivedere le transazioni",
    },
    step3: {
      content: () =>
        "Vai alla pagina del report per visualizzare e scaricare i file del tuo report fiscale per l'anno fiscale corrente e per tutti gli anni fiscali precedenti. Puoi quindi condividere questi report con il tuo commercialista.",
      link: () => "/report",
      title: () => "3. Ottieni il report",
    },
    steps: {
      getStarted: () => "Iniziare",
      gettingStarted: () =>
        "Per iniziare basta seguire i passaggi sottostanti:",
    },
    tabs: {
      nfts: () => "NFT",
      overview: () => "Panoramica",
    },
    title: () => "Ora sei vicino a sistemare le tue tasse!",
    topAssets: () => "Assegnazione token",
    totalReturn: () => "Rendimento totale",
    totalReturnTooltip: () =>
      "Il tuo rendimento totale è la tua performance complessiva derivante dall'investimento in criptovalute. Calcolato come la somma del valore del tuo portafoglio e dei proventi fiat netti dopo aver dedotto i proventi fiat netti investiti.",
    treasury: () => "Tesoro",
    treasuryTimeframe: {
      lastMonth: () => "Il mese scorso",
      lastYear: () => "L'anno scorso",
      monthToDate: () => "Dall'inizio del mese a oggi",
      yearToDate: () => "Da inizio anno",
    },
    unrealizedPosition: () => "Guadagni non realizzati",
    unrealizedPositionTooltip: () =>
      "La differenza tra il patrimonio netto del tuo attuale portafoglio e quanto ti è costato acquisire le attività che compongono il tuo portafoglio.",
    valueOfHoldings: () => "Valore delle partecipazioni",
    valueOfHoldingsTooltip: () =>
      "La differenza tra la tua base di costo e il valore attuale di tutti i tuoi beni",
    valueOfHoldingsTooltipTitle: () => "Guadagno non realizzato:",
    winners: () => "Vincitori",
    winnersAndLosers: {
      help: () => "Migliorare la precisione categorizzando le transazioni",
      importData: () => "importare dati",
      noData: () =>
        "Non hai identificato vincitori e vinti nei tuoi calcoli: continua a importare le tue transazioni per migliorarne la precisione.",
      noDataBody: () =>
        "I tuoi asset con le prestazioni migliori e peggiori appariranno qui. Per iniziare",
      noDataTitle: () => "Nessun dato aggiunto",
      title: () => "Vincitori e vinti",
    },
  },
  dataSource: {
    radioButtonLabels: {
      accountant: () => "Dati del contabile",
      client: (d) =>
        select(d.accountant, { true: " Cliente ", other: " Mio " }) +
        " dati importati",
    },
    subtitle: () =>
      "Seleziona la fonte dati condivisa tra te e il tuo commercialista",
    title: () => "Dati di lavoro",
  },
  delete: () => "Eliminare",
  deny: () => "Negare",
  developer: {
    activeFlags: () => "Flag attivi",
    addFlag: () => "Aggiungi bandiera",
    clearFlagOverrides: () => "Cancella gli override",
    enableLocalCensoring: () => "Abilita la censura locale",
    noActiveFlags: () => "Nessuna bandiera attiva",
    tabs: {
      currencies: () => "Valute",
      editorIntegration: () => "Vai al codice",
      entities: () => "Entità",
      featureFlags: () => "Flag delle funzionalità",
      reconciliation: () => "Riconciliazione",
      reportRefresh: () => "Aggiorna report",
      rules: () => "Regole di integrazione del software di contabilità",
      seedData: () => "Dati del seme",
      sessionRecording: () => "Registrazioni di sessione",
      userData: () => "Dati utente",
    },
    title: () => "Sviluppatore",
  },
  done: () => "Fatto",
  duplicate: () => "Duplicato",
  edit: () => "Modificare",
  editTx: {
    accounts: () => "Conti",
    addFee: () => "Aggiungi commissione",
    advanced: () => "Avanzato",
    baseCurrency: () => "Valuta di base",
    blockchain: () => "Catena di blocchi",
    blockchainFrom: () => "Origine Blockchain",
    blockchainPlaceholder: () => "ad esempio ETH, Avalanche ecc.",
    blockchainTo: () => "Destinazione Blockchain",
    buyCurrency: () => "Acquista valuta",
    buyFee: () => "Tariffa di acquisto",
    buyFeeFor: () => "Commissione di acquisto per transazione in",
    buyPrice: () => "Prezzo di acquisto",
    buyQuantity: () => "Acquista Quantità",
    cantEditLocked: () => "Non è possibile modificare una transazione bloccata",
    cantEditLockedButton: () =>
      "Visualizza le impostazioni di blocco delle transazioni",
    cantMixBlockchain: () =>
      "Non è possibile effettuare la combinazione da e verso le blockchain",
    currency: () => "Valuta",
    currencyPaidIn: () =>
      "Valuta in cui hai pagato la commissione (se presente)",
    destinationOfTransaction: () => "Destinazione della transazione",
    editFee: () => "Modifica tariffa",
    editPriceCurrencyValue: () => "Modifica prezzo, valuta e valore",
    feeForIncoming: () => "Commissione per transazione in entrata in",
    feeForOutgoing: () => "Commissione per transazione in uscita in",
    feeQuantityPaidIn: () =>
      "Importo della commissione pagato nella valuta della commissione",
    feeValue: () => "Valore della commissione",
    in: () => "In",
    inFee: () => "In Tassa",
    inPrice: () => "Nel prezzo",
    inQuantity: () => "In quantità",
    inTime: () => "Nel tempo",
    linkValueTooltipTradeLike: () =>
      "Assicurare che i valori totali di entrambe le parti rimangano uguali modificando i valori delle transazioni.",
    linkValueTooltipTransferLike: () =>
      "Assicurare che la quantità totale di entrambe le parti rimanga uguale regolando le quantità delle transazioni.",
    lookupPrice: () => "Cerca il prezzo di mercato",
    mustBeLess: () => "Deve contenere meno di 256 caratteri",
    mustBuy: () => "Devi inserire un prezzo di acquisto",
    mustCategory: () => "Devi inserire una categoria",
    mustCurrency: () => "Devi inserire una valuta",
    mustDestination: () => "Devi inserire una destinazione",
    mustFee: () => "Devi inserire un valore di commissione",
    mustHaveBlockchain: () =>
      "Devi assegnare una blockchain a questa transazione",
    mustOutTime: () => "Devi inserire un orario di uscita valido",
    mustPrice: () => "Devi inserire un prezzo",
    mustQuantity: () => "Devi inserire una quantità",
    mustSell: () => "Devi inserire un prezzo di vendita",
    mustSource: () => "Devi inserire una fonte",
    mustTime: () => "Devi inserire un orario valido",
    mustValue: () => "Devi inserire un valore",
    noPriceFound: (d) =>
      "Nessun prezzo trovato per " +
      d.currency +
      " . Inserisci manualmente il prezzo o ",
    notes: () => "Appunti",
    optionalNotes: () => "Note facoltative per questa transazione",
    outFee: () => "Fuori quota",
    outPrice: () => "Prezzo fuori",
    outQuantity: () => "Fuori quantità",
    outTime: () => "Fuori tempo",
    paidInFees: () => "pagato in tasse",
    price: () => "Prezzo",
    priceForOne: () => "Prezzo per 1",
    quantity: () => "Quantità",
    removeFee: () => "Rimuovi commissione",
    sellCurrency: () => "Vendere Valuta",
    sellFee: () => "Commissione di vendita",
    sellFeeFor: () => "Commissione di vendita per transazione in",
    sellPrice: () => "Prezzo di vendita",
    sellQuantity: () => "Quantità di vendita",
    somethingWentWrong: () =>
      "Si è verificato un errore durante l'aggiornamento della transazione.",
    sourceOfTransaction: () => "Fonte della transazione",
    swapIconIncoming: () => "Passa alla transazione in entrata",
    swapIconOutgoing: () => "Passa alla transazione in uscita",
    totalFeeValueIn: () => "Valore totale della commissione in",
    totalQuantityOf: () => "Quantità totale di",
    totalValue: (d) => "Valore totale: " + d.amount,
    treatAsNFT: (d) => "Trattare " + d.symbol + " come NFT?",
    treatAsNFTTooltip: () =>
      "Modificando questa impostazione verranno aggiornate tutte le transazioni con questa valuta.",
    ungroupCriteria: {
      differentAssetType: () =>
        "Le valute non soddisfano i requisiti relativi al tipo di attività",
      differentCurrency: () => "Le valute sono le stesse",
      differentSource: () =>
        "Le transazioni non provengono da fonti separate (nessun trasferimento interno)",
      dontGroup: () => 'Il flag "Non raggruppare" è impostato',
      fiatCurrency: () =>
        "Le transazioni devono essere transazioni in valuta fiat",
      sameAccount: () => "Le transazioni non hanno conti corrispondenti",
      sameCurrency: () => "Le valute sono diverse",
      sameFromTo: () => "Le transazioni non hanno valori da e a corrispondenti",
      sameTimestamp: () => "I timestamp sono diversi",
      sameTxId: () => "Gli ID TX sono diversi",
      sameValueFeeExclusive: () =>
        "I valori di ciascun lato non sono abbastanza simili",
      sameValueFeeInclusive: () =>
        "I valori di ciascun lato non sono abbastanza simili",
      similarQuantity: () =>
        "Le quantità di ogni lato non sono abbastanza simili",
      similarTimestamp: () => " I timestamp sono troppo diversi",
    },
    ungroupWarning: (d) =>
      "Le modifiche apportate potrebbero causare la separazione di queste transazioni per i seguenti " +
      plural(d.count, 0, it, { 1: " reason ", other: " reasons " }) +
      " :",
    unlock: () => "Modifica",
    unlockBlockchainBody: () =>
      "La selezione di una nuova blockchain potrebbe comportare che le transazioni precedenti su questa catena non vengano più riconciliate in modo accurato",
    unlockBlockchainTitle: () =>
      "Sei sicuro di voler modificare la blockchain?",
    validTime: () => "Devi inserire un orario valido",
    validatePositiveFeeValue: () => "La tariffa deve essere maggiore di zero",
    validatePositiveFiatValue: () =>
      "Il valore Fiat deve essere maggiore di zero",
    validatePositivePriceValue: () => "Il prezzo deve essere maggiore di zero",
    validatePositiveQuantityValue: () =>
      "La quantità deve essere maggiore di zero",
    validatePositiveValue: () => "Il valore deve essere maggiore di zero",
    value: () => "Valore",
    valueLockLabel: () => "Collega il valore di ogni lato della transazione",
    willSetBlockchain: () => "La blockchain su cui si trova l'indirizzo.",
  },
  enforceUpgrade: {
    tooltip: () =>
      "Il numero delle tue transazioni ha superato il limite per questo piano",
  },
  enterprise: {
    getReport: () => "Ottieni il report",
    incomingInvoicesDownloadModal: {
      billingPeriod: () => "Periodo di fatturazione",
      customDateRange: () => "Intervallo di date personalizzato",
      downloadCsv: () => "Scarica CSV",
      downloadPdf: () => "Scarica PDF",
      endDate: () => "Data di fine",
      startDate: () => "Data di inizio",
      startDateBeforeEndDateError: () =>
        "La data di inizio deve essere antecedente alla data di fine",
      title: () => "Scarica il rapporto",
    },
    office: {
      addNew: {
        button: () => "Aggiungi nuovo ufficio",
        dialog: {
          accept: () => "Aggiungi ufficio",
          title: () => "Aggiungere un nuovo ufficio?",
        },
      },
      addNewButton: () => "Aggiungi nuovo ufficio",
      addNewOffice: () => "Aggiungi nuovo ufficio",
      all: () => "Tutto",
      allOffices: () => "Tutti gli uffici",
      offices: () => "Uffici",
    },
    valueCards: {
      numberOfOffices: () => "Numero di uffici",
      totalAccountants: () => "Contabili totali",
      totalClients: () => "Totale clienti",
      upcomingInvoices: () => "Prossima fattura",
    },
  },
  error: () => "Errore",
  errorBoundaries: {
    debugInfo: () => "Informazioni di debug",
    error: () => "Errore",
    oopsSomethingWentWrong: () => "Oops! Qualcosa è andato storto :(",
    weCantProcess: () =>
      "Non possiamo elaborare la tua richiesta. Prova ad aggiornare la pagina. Se il problema persiste, contatta il nostro team di supporto.",
  },
  errorMessageGeneric: () => "Qualcosa è andato storto",
  errorReporting: {
    actionButtonText: {
      contactSupport: () => "Contatta il supporto",
      retrySync: () => "Riprova la sincronizzazione",
      updateAddress: () => "Aggiorna indirizzo",
      updateApiKey: () => "Aggiorna la chiave API",
      viewDetails: () => "Visualizza i dettagli",
    },
    csvErrorDetails: {
      description: () =>
        "Le seguenti righe nel CSV sono state saltate a causa di problemi nel CSV.",
      title: (d) => d.exchangeName + " transazioni saltate",
    },
  },
  etaTime: {
    momentHumanizeEquivalent: {
      "a few seconds": () => "qualche secondo",
      day: () => "giorno",
      days: () => "giorni",
      hour: () => "ora",
      hours: () => "ore",
      minute: () => "minuto",
      minutes: () => "minuti",
      month: () => "mese",
      months: () => "mesi",
      second: () => "secondo",
      seconds: () => "secondi",
      week: () => "settimana",
      weeks: () => "settimane",
      year: () => "anno",
    },
    tooltip: (d) =>
      "Le sincronizzazioni solitamente richiedono " +
      d.avgHumanizeTime +
      " ma possono richiedere fino a " +
      d.p95HumanizeTime +
      " per essere completate",
    tooltipSameTime: (d) =>
      "Le sincronizzazioni solitamente richiedono " +
      d.avgHumanizeTime +
      " per essere completate",
  },
  existing: {
    cancel: () => "Annulla piano",
    goBack: () => "Torna indietro",
    pricingUrl: () => "https://cryptotaxcalculator.io/pricing/personal/",
    title: () => "Piano attuale",
    upgrade: () => "Aggiornamento",
    upgradePlan: () => "Piano di aggiornamento",
    viewPricingInfo: () => "Visualizza le informazioni sui prezzi",
  },
  existingImports: {
    title: () => "Importazioni esistenti",
  },
  faqAccountant: {
    description: () => "Domande frequenti",
    questions: {
      0: {
        answer: () =>
          "Potresti essere responsabile sia per le plusvalenze che per l'imposta sul reddito a seconda del tipo di transazione in criptovaluta e delle tue circostanze individuali. Ad esempio, potresti dover pagare le plusvalenze sui profitti derivanti dall'acquisto e dalla vendita di criptovaluta o pagare l'imposta sul reddito sugli interessi maturati quando detieni criptovaluta.",
        question: () => "Come viene calcolata la criptotassa?",
      },
      1: {
        answer: () =>
          "Il modo in cui le criptovalute sono tassate nella maggior parte dei paesi implica che gli investitori potrebbero comunque dover pagare le tasse, indipendentemente dal fatto che abbiano realizzato un profitto o una perdita complessivi. A seconda delle circostanze, le tasse vengono solitamente realizzate al momento della transazione e non sulla posizione complessiva alla fine dell'anno finanziario.",
        question: () =>
          "Ho perso soldi facendo trading di criptovalute. Pago ancora le tasse?",
      },
      2: {
        answer: () =>
          "Nella maggior parte dei paesi sei tenuto a registrare il valore della criptovaluta nella tua valuta locale al momento della transazione. Ciò può richiedere molto tempo se fatto manualmente, poiché la maggior parte dei record di cambio non ha un prezzo di riferimento e i record tra cambi non sono facilmente compatibili.",
        question: () =>
          "Come calcolo le tasse sulle transazioni cripto-cripto?",
      },
      3: {
        answer: () =>
          "Devi solo importare la cronologia delle tue transazioni e ti aiuteremo a categorizzare le tue transazioni e a calcolare profitti e entrate realizzati. Puoi quindi generare i report appropriati da inviare al tuo commercialista e tenere a portata di mano registri dettagliati per scopi di audit.",
        question: () =>
          "In che modo Crypto Tax Calculator può aiutarti con le tasse sulle criptovalute?",
      },
      4: {
        answer: () =>
          "Ti consigliamo sempre di collaborare con il tuo commercialista per rivedere i tuoi registri. Tuttavia, preparare manualmente i tuoi libri può richiedere molto tempo e la maggior parte dei commercialisti utilizzerà semplicemente un software come Crypto Tax Calculator per farlo, addebitando un sovrapprezzo elevato.",
        question: () =>
          "Non posso semplicemente chiedere al mio commercialista di farlo per me?",
      },
      5: {
        answer: () =>
          "Gestiamo tutte le attività non di scambio, come le transazioni onchain come Airdrop, Staking, Mining, ICO e altre attività DeFi. Non importa quale attività tu abbia svolto in criptovaluta, ti abbiamo coperto con la nostra funzione di categorizzazione facile da usare, simile a Expensify.",
        question: () => "Gestisci anche attività non di scambio?",
      },
    },
    title: () => "FAQ Contabile",
  },
  faqPricing: {
    description: () => "Domande frequenti",
    questions: {
      0: {
        answer: () =>
          "Potresti essere responsabile sia per le plusvalenze che per l'imposta sul reddito a seconda del tipo di transazione in criptovaluta e delle tue circostanze individuali. Ad esempio, potresti dover pagare le plusvalenze sui profitti derivanti dall'acquisto e dalla vendita di criptovaluta o pagare l'imposta sul reddito sugli interessi maturati quando detieni criptovaluta.",
        question: () => "Come viene calcolata la criptotassa?",
      },
      1: {
        answer: () =>
          "Il modo in cui le criptovalute sono tassate nella maggior parte dei paesi implica che gli investitori potrebbero comunque dover pagare le tasse, indipendentemente dal fatto che abbiano realizzato un profitto o una perdita complessivi. A seconda delle circostanze, le tasse vengono solitamente realizzate al momento della transazione e non sulla posizione complessiva alla fine dell'anno finanziario.",
        question: () =>
          "Ho perso soldi facendo trading di criptovalute. Pago ancora le tasse?",
      },
      2: {
        answer: () =>
          "Nella maggior parte dei paesi sei tenuto a registrare il valore della criptovaluta nella tua valuta locale al momento della transazione. Ciò può richiedere molto tempo se fatto manualmente, poiché la maggior parte dei record di cambio non ha un prezzo di riferimento e i record tra cambi non sono facilmente compatibili.",
        question: () =>
          "Come calcolo le tasse sulle transazioni cripto-cripto?",
      },
      3: {
        answer: () =>
          "Devi solo importare la cronologia delle tue transazioni e ti aiuteremo a categorizzare le tue transazioni e a calcolare profitti e entrate realizzati. Puoi quindi generare i report appropriati da inviare al tuo commercialista e tenere a portata di mano registri dettagliati per scopi di audit.",
        question: () =>
          "In che modo Crypto Tax Calculator può aiutarti con le tasse sulle criptovalute?",
      },
      4: {
        answer: () =>
          "Ti consigliamo sempre di collaborare con il tuo commercialista per rivedere i tuoi registri. Tuttavia, preparare manualmente i tuoi libri può richiedere molto tempo e la maggior parte dei commercialisti utilizzerà semplicemente un software come Crypto Tax Calculator per farlo, addebitando un sovrapprezzo elevato.",
        question: () =>
          "Non posso semplicemente chiedere al mio commercialista di farlo per me?",
      },
      5: {
        answer: () =>
          "Gestiamo tutte le attività non di scambio, come le transazioni onchain come Airdrop, Staking, Mining, ICO e altre attività DeFi. Non importa quale attività tu abbia svolto in criptovaluta, ti abbiamo coperto con la nostra funzione di categorizzazione facile da usare, simile a Expensify.",
        question: () => "Gestisci anche attività non di scambio?",
      },
    },
    title: () => "Domande frequenti",
  },
  faqs: {
    capitalGainsWrong: {
      ans: {
        0: () =>
          "Ci sono due motivi principali per cui questo accade. O hai classificato alcune transazioni tra wallet come acquisto/vendita quando stavi semplicemente spostando criptovaluta (deposito/prelievo). Altrimenti, potresti aver dimenticato di aggiungere alcune transazioni o ID wallet che potrebbero compensare alcuni dei tuoi guadagni in conto capitale.",
      },
      q: () =>
        "Cosa succede se i miei guadagni in conto capitale sembrano errati?",
    },
    ensureAccuracy: {
      ans: {
        0: () =>
          "Per garantire che il tuo rendiconto fiscale sia il più accurato possibile, devi seguire due passaggi:",
        1: () => "Importa tutti i tuoi dati",
        2: () =>
          "Ciò include transazioni fiat in criptovaluta, criptovaluta in criptovaluta, criptovaluta in fiat, transazioni DeFi, staking, airdrop, transazioni di quest'anno, ma anche transazioni da quando hai acquistato criptovaluta per la prima volta. Tutto deve essere incluso per fornire un report accurato.",
        3: () => "Controllare/classificare ogni transazione",
        4: () =>
          "Può essere difficile ricordare a cosa si riferiva ogni transazione, ma è anche fondamentale. Se etichetti una transazione come deposito/prelievo, ciò non avrà alcun impatto sulle tue tasse, mentre un acquisto/vendita sì. Se classifichi una transazione come airdrop, staking, interesse, ecc., verrà classificata come reddito, non come plusvalenza. Il punto è che il modo in cui etichetti ogni transazione è importante, quindi devi essere preciso nel modo in cui le etichetti.",
        5: () => "Correggi gli avvisi sul saldo rimanente",
        6: () =>
          "Se ti sbarazzi di criptovaluta, ma non hai abbastanza saldo, questo genererà un messaggio di avviso di saldo rimanente nell'app. Dovresti controllare le tue notifiche per assicurarti di non avere avvisi di saldo rimanente. Se hai questi avvisi, puoi risolverli seguendo i passaggi nella guida qui: https://cryptotaxcalculator.io/guides/negative-balance-remaining-warnings ",
      },
      q: () => "Come posso essere sicuro che questo report sia accurato?",
    },
    howToUseCTC: {
      ans: {
        0: () =>
          "La prima cosa da fare è andare alla pagina di importazione e caricare TUTTI i dati delle transazioni dagli account di exchange e dalle transazioni del portafoglio. Ogni account di exchange o portafoglio avrà istruzioni di importazione che implicano il caricamento di dati CSV, l'impostazione di un'API o l'inserimento dei dettagli del portafoglio.",
        1: () =>
          "Una volta caricato tutto, rivedi la cronologia delle transazioni. Ciò comporterà la classificazione di ogni transazione per tipo. Tieni d'occhio i saldi che non sono allineati, ad esempio, CTC evidenzierà se il tuo account entra in una cronologia degli acquisti mancanti, il che significa che mancano alcune transazioni.",
        2: () =>
          "Infine, vai alla pagina del report fiscale e fai un rapido controllo per assicurarti che i numeri e la ripartizione del portafoglio siano ragionevoli. Dopodiché puoi scaricare in tutta sicurezza il tuo report fiscale.",
        3: () =>
          "Per informazioni più dettagliate, leggi la nostra guida qui: https://cryptotaxcalculator.io/guides/how-to-use-crypto-tax-calculator",
      },
      q: () => "Come si usa Crypto Tax Calculator?",
    },
    missingIncome: {
      ans: {
        0: () =>
          "Sì, le plusvalenze a lungo termine e quelle a breve termine vengono segnalate separatamente.",
        1: () =>
          "Gli utili su attività detenute per un anno o meno sono classificati come utili a breve termine, mentre gli utili su attività detenute per più di un anno sono classificati come utili a lungo termine.",
        2: () =>
          "A seconda della giurisdizione in cui risiedi, potresti avere diritto a uno sconto fiscale su eventuali guadagni a lungo termine.",
      },
      q: () =>
        "I guadagni a breve e lungo termine vengono riportati separatamente?",
    },
  },
  for: () => "per",
  forceSyncErp: (d) => "Forza la risincronizzazione su " + d.erp,
  gainsToggles: () => "Opzioni della categoria plusvalenze",
  generic: {
    isAccountant: () => "Sei un commercialista? Clicca qui",
    notAccountant: () => "Torna ai prezzi individuali",
    select: () => "Selezionare",
    yourPlan: () => "Il tuo piano",
  },
  groupingWizard: {
    actionButton: () => "Vicino",
    backPage: () => "Indietro",
    matchCountPage: {
      description: () =>
        "Per raggruppare le transazioni, è necessario disporre del numero corretto di transazioni in uscita e in entrata per formare il gruppo desiderato.",
      ratios: {
        NToOne: (d) =>
          "I gruppi " +
          d.type +
          " richiedono almeno una transazione in entrata e una transazione in uscita per formare un gruppo.",
        nToM: (d) =>
          "I gruppi " +
          d.type +
          " richiedono almeno una transazione in entrata e almeno una transazione in uscita per formare un gruppo, e il numero di entrate deve corrispondere al numero di uscite.",
        nToN: (d) =>
          "I gruppi " +
          d.type +
          " richiedono almeno una transazione in entrata e almeno una transazione in uscita per formare un gruppo.",
        oneToN: (d) =>
          "I gruppi " +
          d.type +
          " richiedono una transazione in entrata e almeno una transazione in uscita per formare un gruppo.",
        oneToOne: (d) =>
          "I gruppi " +
          d.type +
          " richiedono una transazione in entrata e una transazione in uscita per formare un gruppo.",
      },
      selected: (d) =>
        "Hai selezionato " +
        plural(d.inCount, 0, it, {
          one: " 1 in transazione ",
          other: number("it", d.inCount, 0) + " in transazioni ",
        }) +
        " e " +
        plural(d.outCount, 0, it, {
          one: " 1 out transazione ",
          other: number("it", d.outCount, 0) + " out transazioni ",
        }) +
        " .",
      title: (d) =>
        "Hai selezionato il numero corretto di transazioni per creare un gruppo " +
        d.type +
        " ?",
    },
    matchCriteriaPage: {
      description: () =>
        "Affinché le transazioni possano essere raggruppate, i dati devono soddisfare una serie di criteri per creare correttamente un gruppo.",
      differentAssetType: {
        cta: () =>
          "Le valute in un derivato devono essere tipologie di asset diverse, è necessario avere esattamente un token di posizione.",
        title: () => "Valute:",
      },
      differentCurrency: {
        cta: () =>
          "Le valute in una transazione devono essere diverse: non è possibile vendere una valuta per acquistare la stessa valuta.",
        title: () => "Valute:",
      },
      differentSources: {
        cta: () =>
          "Per effettuare una transazione, sia il lato acquisto che quello vendita devono provenire dalla stessa importazione; non è possibile effettuare una transazione tra importazioni diverse.",
        title: () => "Fonti di importazione:",
      },
      dontGroup: {
        cta: () =>
          'Una o più delle transazioni selezionate hanno impostato il flag "non raggruppare". Questo viene impostato quando si utilizza la funzionalità di annullamento del raggruppamento per impedire il raggruppamento delle transazioni. Se si desidera che queste transazioni vengano raggruppate, si prega di annullare il flag "non raggruppare"',
        enableGrouping: () => "Abilita raggruppamento",
        title: () => "Segnalazione di annullamento del gruppo:",
      },
      fiatCurrency: {
        cta: () => "Le transazioni devono essere transazioni in valuta fiat",
        title: () => "Valuta fiat:",
      },
      noIssues: {
        cta: () =>
          "Non sono stati riscontrati problemi di raggruppamento per le transazioni selezionate. Prova a ricalcolare manualmente per raggruppare queste transazioni. Se le transazioni rimangono ancora non raggruppate dopo un ricalcolo manuale, contatta l'assistenza.",
        title: () => "Nessun problema di raggruppamento trovato",
      },
      quantity: {
        cta: () =>
          "La quantità transata nelle transazioni in entrata non corrisponde alle quantità transate nelle transazioni in uscita. Per raggruppare queste transazioni, seleziona la quantità laterale che desideri utilizzare per il gruppo",
        title: () => "Quantità di valuta:",
      },
      sameAccount: {
        cta: () =>
          "Gli account di proprietà dell'utente nei txs devono corrispondere. Per raggruppare queste transazioni, assicurati che provengano tutte dallo stesso account.",
        title: () => "Conti:",
      },
      sameCurrency: {
        cta: () =>
          "La valuta inviata non corrisponde alla valuta ricevuta. Per raggruppare queste transazioni, modifica le txs nella valuta che desideri utilizzare per il gruppo",
        title: () => "Valute:",
      },
      sameFromTo: {
        cta: () =>
          "L'indirizzo di partenza e l'indirizzo di arrivo devono corrispondere. Per raggruppare queste transazioni, modifica le txs nella coppia di partenza/arrivo che desideri utilizzare per il gruppo",
        title: () => "Da e a:",
      },
      timestamp: {
        cta: () =>
          "Le transazioni selezionate non rientrano nella finestra di timestamp richiesta per essere raggruppate. Per raggruppare queste transazioni, modifica le txs con il timestamp che desideri utilizzare per il gruppo",
        title: () => "Timestamp:",
      },
      title: (d) =>
        "Hai selezionato transazioni che soddisfano tutti i criteri di un gruppo " +
        d.type +
        " ?",
      txId: {
        cta: () =>
          "Le transazioni selezionate non hanno lo stesso ID transazione. Al momento non è possibile modificare il txId",
        title: () => "ID transazione:",
      },
      value: {
        cta: () =>
          "Il valore degli ins per questa transazione non corrisponde ai valori degli outs. Per raggruppare queste transazioni, modifica le txs nel valore laterale che desideri utilizzare per il gruppo",
        title: () => "Valore della transazione:",
      },
    },
    matchTypePage: {
      allowed: () => "Tipi di gruppo consentiti:",
      description: () =>
        "Affinché le transazioni possano essere raggruppate, le loro categorie devono corrispondere ai sottotipi di una delle nostre categorie raggruppate.",
      selected: () => "Categorie selezionate:",
      title: () =>
        "I tipi di transazione selezionati rientrano in qualche gruppo?",
    },
    modalTitle: () => "Assistente di raggruppamento",
    nextPage: () => "Prossimo",
  },
  help: () => "Aiuto",
  holdingsDropdown: {
    assets: () => "Attività",
    balances: () => "Saldi",
    calcBal: () => "Saldo calcolato",
    calculatedTooltip: () => "Il saldo calcolato da Crypto Tax Calculator",
    currentPrice: () => "Prezzo attuale",
    holdings: () => "Partecipazioni",
    noData: () => "N / A",
    noHoldings: () => "Nessuna partecipazione",
    price: () => "Prezzo",
    quantity: () => "Quantità",
    quantityTooltip: () =>
      "L'importo segnalato dall'exchange o dal portafoglio, oppure se non viene segnalato alcun importo l'importo calcolato da Crypto Tax Calculator",
    repBal: () => "Saldo segnalato",
    reportedTooltip: () => "Il saldo riportato dallo scambio o dal portafoglio",
    value: () => "Valore",
    valueTooltip: () =>
      "Il valore riportato dall'exchange o dal portafoglio, oppure se non viene riportato alcun importo il valore calcolato da Crypto Tax Calculator",
    wallets: () => "Portafogli",
  },
  ignore: () => "Ignorare",
  ignoreIssue: () => "Ignora il problema",
  importStepType: {
    critical: () => "critico",
    note: () => "nota",
    step: () => "fare un passo",
    video: () => "video",
  },
  imports: {
    accountTableTypes: {
      blockchain: () => "Catena di blocchi",
      exchange: () => "Scambio",
    },
    accounts: () => "Conti",
    accountsTableHeadings: {
      account: () => "Account",
      assets: () => "Attività",
      balance: () => "Bilancia",
      synced: () => "Sincronizzato",
      tx: () => "Trasmissione",
      type: () => "Tipo",
    },
    addIntegrationTitle: () => "Aggiungi account",
    addIntegrations: () => "Aggiungi account",
    addSource: (d) => "Aggiungi una fonte " + d.source + " aggiuntiva",
    addYourFirst: () => "Aggiungi il tuo primo account per iniziare",
    additionalNotes: () => "Note aggiuntive",
    advancedOptions: () => "Opzioni avanzate",
    api: () => "API",
    apiDisabled: (d) =>
      select(d.isNew, {
        true: " L'opzione Autosync tramite API non è disponibile ",
        other:
          " L'opzione Autosync tramite API non è disponibile in quanto i dati sono già importati tramite caricamento file ",
      }),
    apiStillSyncing: () =>
      "L'API è ancora in fase di sincronizzazione, attendere qualche minuto.",
    apiWarning: () =>
      "Non aggiungere mai chiavi API con autorizzazioni private che abilitano il trading!",
    backToExistingImports: () => "Torna alle importazioni esistenti",
    backToImport: () => "Torna alla ricerca di importazione",
    backToImportTypeSelector: () =>
      "Torna alla selezione del tipo di importazione",
    backToIntegrations: () => "Torna ai conti",
    backToIntegrationsSearch: () => "Torna alla ricerca degli account",
    beta: () => "Beta",
    betaTooltipDefault: () =>
      "Questa integrazione è in versione beta e potrebbe presentare alcuni problemi. Segnala eventuali problemi al nostro team di supporto.",
    breakdownTooltip: () => "Clicca per ordinare",
    browse: () => "Caricamento",
    bulkSyncTooltip: {
      api: (d) => "Risincronizza le tue API " + d.name + " da " + d.dateTime,
      deprecatedTooltip: (d) =>
        d.name + " L'API non è più operativa, importa tramite CSV",
      failed: () =>
        "Sincronizzazione non riuscita, fare clic per risincronizzare",
      lastSyncTime: () => "ultima sincronizzazione",
      lockedPeriod: (d) =>
        "Risincronizzazione dalla fine dell'ultimo periodo bloccato: " +
        d.dateTime,
      noSync: () => "Niente da risincronizzare",
      pending: () => "Sincronizzazione",
      wallet: (d) =>
        "Risincronizza i tuoi portafogli " + d.name + " da " + d.dateTime,
    },
    chainDisabled: (d) => d.name + " disabilitato",
    chips: {
      api: () => "API",
      csv: () => "Formato CSV",
      service: () => "Servizio",
      wallet: (d) =>
        plural(d.walletCount, 0, it, {
          0: " Portafoglio ",
          one: " 1 Portafoglio ",
          other: number("it", d.walletCount, 0) + " Portafogli ",
        }),
    },
    clickHere: () => "Clicca qui",
    connectWallet: () => "Connetti portafoglio",
    connectWalletErrors: {
      duplicateWallet: (d) =>
        "Questo portafoglio " + d.address + " è già stato connesso",
      duplicateWallets: (d) =>
        "Questi portafogli " + d.addresses + " sono già stati collegati",
      maxWallets: (d) =>
        "Puoi connettere solo " + d.max + " portafogli alla volta",
      walletNotInstalled: (d) =>
        "Il portafoglio " +
        d.wallet +
        " non è installato. Installa " +
        d.wallet +
        " dal sito Web ufficiale o come estensione per continuare a connettere il tuo portafoglio",
    },
    connected: () => "Collegato",
    connectionSuccess: () => "Connessione riuscita!",
    continue: () => "Continuare",
    cryptoHoldings: () => "Titoli in criptovaluta",
    csv: () => "file csv",
    csvAlerts: {
      fileSizeTooBig: () =>
        "Superata la dimensione massima di 20 MB. Rimuovi i file o fornisci file più piccoli.",
      fileTypeNotSupported: () => "Supportiamo solo file CSV, XLS o XLSX.",
      invalidFileName: (d) =>
        "I nomi file " + d.fileNames + " contengono simboli non validi.",
      missingAddress: () =>
        "È necessario immettere l'indirizzo associato a questo file CSV",
      missingCurrencySymbols: () =>
        "È necessario immettere la valuta poiché varia tra i file CSV",
      noFiles: () => "Nessun file selezionato!",
      onlyOneFile: () => "Si prega di caricare un solo file.",
    },
    csvCaps: () => "Formato CSV",
    csvDisabled: (d) =>
      select(d.isNew, {
        true: " L'opzione di caricamento file non è disponibile ",
        other:
          " L'opzione di caricamento file non è disponibile poiché i dati sono già importati tramite sincronizzazione automatica ",
      }),
    csvExtraInfo: {
      address: () => "Indirizzo",
      addressHelper: () =>
        "Inserisci l'indirizzo del portafoglio associato a questo CSV",
      addressPlaceholder: () => "Inserisci l'indirizzo del portafoglio",
      currencyHelper: () => "Inserisci la valuta in questo CSV",
      currencySymbol: () => "Simbolo di valuta",
      fiatCurrency: () =>
        "Conferma la valuta fiat di questo CSV, ad esempio NZD o AUD",
      tickerPlaceHolder: () => "Inserisci il valore del ticker, ad esempio BTC",
    },
    csvWarning: () =>
      "Si consiglia di utilizzare le importazioni CSV ove possibile",
    customCSV: () => "Importa CSV personalizzato",
    customCsvButton: () =>
      "Non riesci a trovare il tuo scambio? Fai clic qui per aggiungere un CSV personalizzato",
    customCsvLabel: () => "Nome dello scambio",
    customCsvPlaceholder: () => "ad esempio Binance, Coinbase",
    deleteApiKeyConfirmTitle: () => "Elimina la chiave API",
    deleteImportExchangeConfirmation: {
      body: (d) =>
        "Vuoi davvero rimuovere tutti i dati da " +
        d.name +
        " ? Questo eliminerà tutte le transazioni associate a questa importazione. Questa operazione non può essere annullata.",
      title: () => "Elimina Importazione",
    },
    deleteImportedFileConfirmation: {
      body: () =>
        "Sei sicuro di voler eliminare il file? Questo eliminerà anche TUTTE le transazioni importate da questo file. Questa azione non può essere annullata.",
      title: () => "Elimina file",
    },
    deleteOauthKeyConfirmText: (d) =>
      "Vuoi davvero scollegare il tuo account " +
      d.exchangeName +
      " ? Questo eliminerà TUTTE le transazioni importate dal tuo account " +
      d.exchangeName +
      " connesso. Questa azione non può essere annullata.",
    deleteOauthKeyConfirmTitle: (d) => "Disconnetti " + d.exchangeName,
    deleteWalletConfirmTitle: () => "Elimina portafoglio",
    deprecated: () => "Obsoleto",
    deprecatedTooltipDefault: () =>
      "Questa integrazione è stata deprecata e non è più mantenuta attivamente",
    deprecation: {
      description: () =>
        "Questa fonte di importazione è stata ritirata e non è più gestita. Importa i dati utilizzando il nostro formato CSV manuale. https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
      title: () => "Fonte in pensione",
    },
    dialogOptions: {
      addApi: () => "Aggiungi API",
      addCsv: () => "Aggiungi CSV",
      addWallet: () => "Aggiungi indirizzo portafoglio",
      copyAddress: () => "Copia indirizzo",
      delete: () => "Eliminare",
      deleteAll: () => "Elimina tutto",
      downloadCsv: () => "Scarica CSV",
      editApi: () => "Modifica API",
      editNickname: () => "Modifica Nickname",
      hardSync: () => "Sincronizzazione rigida",
      ignoreChain: () => "Ignora catena",
      sync: () => "Sincronizzazione",
      viewExplorer: () => "Visualizza nell'esploratore blocchi",
      viewLedger: () => "Registro delle valute",
      viewTransactions: () => "Visualizza le transazioni",
    },
    done: () => "Fatto",
    downloadCsvDisplayMessage: {
      info: () => "Preparazione del download CSV in corso...",
      success: () => "Download CSV pronto!",
    },
    drawer: {
      accountMetricsHeading: {
        balance: () => "Bilancia",
        feesPaid: () => "Commissioni pagate",
        tradeVolume: () => "Volume degli scambi",
        transactions: () => "Transazioni",
      },
      addApiKey: () => "Aggiungi chiave API",
      addCsv: () => "Aggiungi CSV",
      addWallet: () => "Aggiungi indirizzo portafoglio",
      currencyTableHeadings: {
        amount: () => "Quantità",
        currencies: () => "Valute",
        price: () => "Prezzo",
        value: () => "Valore",
      },
      deleteApiKey: {
        body: (d) =>
          "Vuoi davvero eliminare questa chiave API " +
          d.key +
          " ? Questo eliminerà TUTTE le transazioni importate da questa chiave. Questa azione non può essere annullata.",
        title: () => "Elimina la chiave API",
      },
      deleteBlockchain: {
        body: (d) =>
          "Sei sicuro di voler eliminare " +
          d.address +
          " su " +
          d.blockchain +
          " ? Questo eliminerà TUTTE le transazioni importate da questo indirizzo sulla blockchain. Questa azione non può essere annullata.",
        title: () => "Elimina blockchain",
      },
      deleteFile: {
        body: (d) =>
          "Sei sicuro di voler eliminare il file " +
          d.name +
          " ? Questo eliminerà TUTTE le transazioni importate da questo file. Questa azione non può essere annullata.",
        title: () => "Elimina file",
      },
      deleteOauth: {
        body: (d) =>
          "Vuoi davvero scollegare il tuo account " +
          d.exchangeName +
          " ? Questo eliminerà TUTTE le transazioni importate dal tuo account " +
          d.exchangeName +
          " connesso. Questa azione non può essere annullata.",
        title: (d) => "Disconnetti " + d.exchangeName,
      },
      nftTableHeadings: {
        collections: () => "Collezioni",
        combinedCost: () => "Costo combinato",
        ntfs: () => "NFT",
        totalSpend: () => "Spesa totale",
      },
      overview: () => "Panoramica",
      showLess: () => "Mostra meno valute",
      showLessNft: () => "Mostra meno NFT",
      showMore: () => "Mostra altre valute",
      showMoreNft: () => "Mostra altri NFT",
      statusBanner: {
        accountHasNoTransactions: () => "L'account non ha transazioni",
        and: () => "E",
        chainSyncFailed: (d) => d.chains + " sincronizzazione fallita",
        generalImportsFailed: (d) =>
          select(d.all, { true: " All ", other: " Some " }) +
          " " +
          d.integration +
          " importazioni fallite",
        generalSyncsFailed: (d) =>
          select(d.all, { true: " All ", other: " Some " }) +
          " " +
          d.integration +
          " importazioni non riuscite a sincronizzare",
        selectedImportFailed: (d) =>
          d.name +
          " importazione non riuscita " +
          select(d.reason, { undefined: "", other: " : " + d.reason }) +
          select(d.code, { undefined: "", other: " - codice: " + d.code }),
        selectedImportHasNoTransactions: (d) =>
          "L'importazione di " + d.name + " non ha transazioni",
        selectedSyncFailed: (d) =>
          d.name +
          " sincronizzazione fallita " +
          select(d.reason, { undefined: "", other: " : " + d.reason }) +
          select(d.code, { undefined: "", other: " - codice: " + d.code }),
      },
      viewCollection: () => "Visualizza la collezione",
      viewLedger: () => "Registro delle valute",
      viewOnTxTable: () => "Visualizza sulla tabella delle transazioni",
      viewTxs: () => "Visualizza le transazioni",
    },
    dropFiles: () => "Trascina qui i tuoi file",
    editSource: (d) => "Modifica " + d.source + " origine",
    emptyHoldings: () => "Nessuna criptovaluta posseduta...",
    enable: () => "Abilitare",
    enableImportChainTransactions: () =>
      "Abilita l'importazione di transazioni da questa catena",
    error: (d) =>
      "Errore durante l'importazione di " +
      select(d.blockchain, { true: "address " + d.name, other: d.name }) +
      select(d.showError, { true: ": " + d.error, other: "" }) +
      select(d.showCode, { true: " - code: " + d.code, other: "" }),
    exchangeGridTitle: {
      all: () => "Tutti gli scambi e i portafogli",
      allBlockchains: () => "Tutte le Blockchain e i Portafogli",
      popular: () => "I più popolari",
      results: () => "Risultati",
    },
    existingOptions: () => "Portafogli e scambi importati",
    findAccount: () => "Trova account",
    firstTx: () => "Prima transazione importata:",
    goBack: () => "Torna indietro",
    hardSync: () => "Sincronizzazione rigida",
    import: () => "Importare",
    importBannerMessages: {
      connectingToIntegration: (d) => "Connessione a " + d.name,
      success: () => "Importazione riuscita",
    },
    importByManualCSV: (d) =>
      'Importa CSV con modello CTC per " ' + d.exchange + ' "',
    importCSV: (d) => "Importa " + d.source + " CSV",
    importDataTitle: () => "Aggiungi account",
    importExchange: (d) => "Importa " + d.exchangeName,
    importFailed: () => "Importazione non riuscita",
    importFileDuplicateWarning: () =>
      "Tutte le transazioni in questo file sono state identificate come duplicate e non sono state importate",
    importFrom: {
      chip: {
        notRecommended: () => "Non consigliato",
        recommended: () => "Raccomandato",
      },
      modal: {
        actionText: () => "Continua con l'importazione",
        text: () =>
          'L\'utilizzo della funzionalità "Importa da data" impedisce l\'importazione di transazioni precedenti alla data selezionata. Ciò può portare a errori di cronologia degli acquisti mancanti, che possono essere corretti utilizzando una transazione "manuale" per fornire il saldo iniziale dell\'importazione prima della data di importazione',
        title: () =>
          "L'importazione dalla data potrebbe causare la mancanza di dati",
      },
      selector: {
        beginning: () => "Inizio",
        custom: () => "Data personalizzata",
        title: () => "Avvia l'importazione da:",
      },
    },
    importInstructionsAccordionTitle: () => "Istruzioni passo dopo passo",
    importTypeModal: {
      buttonLabel: {
        csv: {
          active: () => "Carica CSV",
          default: () => "Caricamento",
        },
      },
      description: () => "Come desideri importare i tuoi dati?",
      importMethod: {
        api: {
          description: () =>
            "Accesso di sola lettura. La connessione è riservata e crittografata in modo sicuro.",
          title: () => "Connettiti tramite API",
        },
        "bulk-wallet": {
          description: () =>
            "Importa le transazioni incollando i tuoi indirizzi pubblici dall'app o dall'estensione Coinbase Wallet",
          title: () => "Importare indirizzi pubblici",
        },
        "connect-wallet": {
          description: () =>
            "Importa in modo sicuro le transazioni dalla blockchain",
          title: () => "Connetti portafoglio",
        },
        csv: {
          description: () => "Carica un file storico delle transazioni ",
          title: () => "Importa tramite file CSV",
        },
        oauth: {
          description: () =>
            "Importa automaticamente le transazioni tramite OAuth",
          title: () => "Sincronizzazione automatica",
        },
        wallet: {
          description: () =>
            "Importa automaticamente le transazioni dalla blockchain incollando l'indirizzo del tuo portafoglio",
          title: () => "Sincronizzazione indirizzo",
        },
      },
      instructionTitles: {
        api: () => "Istruzioni di sincronizzazione API",
        "bulk-wallet": () =>
          "Istruzioni per la sincronizzazione del portafoglio in blocco",
        "connect-wallet": () => "Istruzioni per collegare il portafoglio",
        csv: () => "Istruzioni per caricare il file",
        oauth: (d) => "Connetti " + d.exchange + " istruzioni",
        wallet: () => "Istruzioni per la sincronizzazione del portafoglio",
      },
      methods: {
        api: () => "Inserisci i dettagli dell'API",
        "bulk-wallet": () => "Inserisci gli indirizzi del portafoglio",
        wallet: () => "Inserisci l'indirizzo del portafoglio",
      },
      title: (d) => "Importa " + d.name,
    },
    imported: () => "Importato",
    importedFileWarning: () =>
      "Nessuna transazione è stata importata da questo file",
    importedTransactions: (d) =>
      "Importato " +
      d.count +
      " " +
      plural(d.count, 0, it, {
        one: " transaction ",
        other: " transactions ",
      }) +
      " da " +
      d.exchangeDisplayName +
      " .",
    imports: () => "Importazioni",
    integrationChips: {
      all: () => "Tutto",
      blockchains: () => "Blockchain",
      exchanges: () => "Scambi",
      services: () => "Servizi",
      wallets: () => "Portafogli",
    },
    integrationSuccessful: () => "Integrazione riuscita",
    integrations: () => "Integrazioni",
    label: () => "Seleziona il tuo exchange o portafoglio",
    lastLockedSyncWarning: {
      action: () => "Visualizza i periodi bloccati",
      body: (d) =>
        "Le transazioni precedenti a " + d.date + " non verranno importate",
      title: () => "Periodi bloccati:",
    },
    lastSynced: () => "Ultima sincronizzazione",
    lastTx: () => "Ultima transazione importata:",
    manualCSV: {
      csvText: () => "Formato CSV",
      customCSVToExchange: {
        description: {
          link: () =>
            "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
          linkText: () => " formato CSV personalizzato.",
          preLinkText: () =>
            "Se desideri importare transazioni personalizzate su questo account, segui i passaggi sottostanti utilizzando il nostro",
        },
        title: () => "Importazione di transazioni personalizzate",
      },
      excelText: () => "Modello Excel",
      finishAdding: () => "Completa l'aggiunta dei file e continua",
      googleSheetsText: () => "Modello di foglio di calcolo di Google",
      learnMore: {
        link: () =>
          "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
        text: () => "Informazioni sulle importazioni CSV",
      },
      seeAdvancedGuide: {
        link: () =>
          "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
        linkText: () => "guida CSV avanzata",
        postLinkText: () =>
          "per le negoziazioni in cui le commissioni devono ancora essere applicate con i dati di negoziazione.",
        preLinkText: () => "Guarda il nostro",
      },
      steps: {
        0: () =>
          "Completa i dettagli della transazione nel modello Excel, CSV o Google Sheets.",
        1: () =>
          "Assicurati che tutti i campi siano compilati per evitare errori di caricamento.",
        2: () => "Esporta il documento come CSV e clicca su Carica.",
      },
      warningText: () =>
        "Assicurati che i tuoi dati siano preparati correttamente e nel formato di file CSV personalizzato di Crypto Tax Calculator",
    },
    manualCSVImport: () => "Utilizzare il modello CSV CTC per importare",
    manualImportTooltip: () =>
      "Se non riesci a trovare il tuo portafoglio o il tuo exchange, puoi importare i tuoi dati utilizzando il modello CSV CTC",
    marketValue: (d) => "Valore di mercato in " + d.localCurrency,
    nameOfSource: () => "Nome della fonte",
    noExchange: () => "Non riesci a trovare il tuo scambio?",
    noImports: () => "Nessun exchange, blockchain o wallet importato trovato!",
    noOptions: () => "Nessuna opzione",
    noSync: () => "Nessuna ultima sincronizzazione",
    noTxFound: (d) =>
      "Nessuna transazione " +
      select(d.showNew, { true: " new ", other: " " }) +
      " trovata per " +
      d.exchangeDisplayName +
      " .",
    noTxFoundWallet: (d) =>
      "Nessuna transazione " +
      select(d.showNew, { true: " new ", other: " " }) +
      " trovata per il portafoglio " +
      d.blockchain +
      " " +
      d.address +
      " .",
    note: () => "Nota",
    notifications: {
      kucoinfutures: {
        buttonLabel: () => "Congedare",
        text: () =>
          "I tuoi dati Kucoin Futures sono stati consolidati nell'integrazione Kucoin",
        title: () => "Rimossi i futures Kucoin",
      },
    },
    oauth: {
      connect: (d) => "Connettiti a " + d.exchange,
      description: (d) =>
        "Ti verrà chiesto di effettuare l'accesso a " +
        d.exchange +
        " e di concedere l'accesso in sola lettura al tuo account. Questo non ci dà accesso per spostare i tuoi fondi.",
    },
    onboarding: {
      expect: () => "Ecco cosa aspettarsi",
      getStarted: () => "Iniziare",
      steps: {
        0: () => "Scegli il tuo paese",
        1: () => "Integra i tuoi exchange, wallet e blockchain",
        2: () =>
          "Ottieni il tuo rapporto fiscale e verifica che i tuoi dati siano accurati",
      },
      welcome: () => "Benvenuti a Crypto Tax Calculator",
    },
    options: {
      api: () => "Sincronizzazione tramite API",
      "bulk-wallet": () => "Portafoglio all'ingrosso",
      connect: (d) => "Connetti " + d.exchange,
      csv: () => "Carica file",
      oauth: (d) => "Connetti " + d.exchange,
      "single-import": () => "Importazione singola",
    },
    prompt: () => "Ad esempio Coinbase, Bitcoin",
    relatedChains: {
      account: () => "Account",
      addBlockchain: () => "Aggiungi blockchain",
      cluster: {
        dontImport: () => "Non importare",
        ignore: () => "Non chiedere più",
        import: () => "Importare",
        text: () => "Vuoi importare il resto dei portafogli in questo cluster?",
        title: () =>
          "Abbiamo rilevato che il tuo indirizzo appartiene a un cluster",
      },
      dontImport: () => "Non importare",
      import: () => "Importare",
      importTransactions: () =>
        "Importare transazioni sulle seguenti catene per questo account?",
      scanning: (d) =>
        "Scansione delle catene EVM per le transazioni su " + d.wallet,
      scanningNoResults: (d) =>
        "Non abbiamo trovato altre Blockchain sotto " + d.address,
      scanningSuccess: () => "Transazioni su altre catene EVM trovate per",
      stepProgress: (d) =>
        "Portafoglio " + d.step + " di " + d.totalSteps + " :",
      stillScanning: () => "Ancora in fase di scansione...",
      syncingOnChains: (d) =>
        "Sincronizzazione di " + d.wallet + " sulle catene " + d.chains,
      title: () => "Rilevate transazioni su catene correlate",
    },
    requestAnIntegration: () => "Richiedi un'integrazione",
    resultNotFound: () => "Risultato non trovato",
    searchPrompt: () => "Cerca il tuo portafoglio, exchange o blockchain",
    secureConnect: () => "Connessione sicura",
    secureConnectNote: () =>
      "Stiamo richiedendo solo permessi di visualizzazione. Non richiederemo mai chiavi private e non possiamo trasferire fondi.",
    secureImport: (d) =>
      "Importa in modo sicuro la cronologia delle tue transazioni " +
      d.exchangeName +
      " :",
    selectCountry: {
      chooseJurisdiction: () => "Scegli la tua giurisdizione fiscale",
      findCountry: () => "Trova il tuo paese",
      pleaseSelect: () => "Seleziona un paese per continuare",
      search: () => "Ricerca",
      somethingWentWrong: (d) =>
        "Si è verificato un errore durante l'impostazione del paese: $ " +
        d.message,
      somethingWentWrongSupport: () =>
        "Qualcosa è andato storto, contatta l'assistenza.",
    },
    selectFile: () => "oppure trascina e rilascia il tuo file qui",
    selectFiles: () => "oppure trascina e rilascia i tuoi file qui",
    skippedRows: {
      cta: (d) =>
        plural(d.count, 0, it, {
          1: " 1 riga saltata ",
          other: number("it", d.count, 0) + " righe saltate ",
        }),
      errors: {
        apiError: () =>
          "Si è verificato un errore durante l'interazione con un'API esterna.",
        genericError: () => "Si è verificato un errore imprevisto.",
        lockPeriod: () =>
          "La riga è stata saltata perché avrebbe creato una transazione in un periodo bloccato",
        noOp: () =>
          "La riga è stata intenzionalmente saltata perché i dati non sono pertinenti o perché verranno recuperati da un CSV diverso",
        noTransactions: () => "Nessuna transazione trovata nel CSV.",
        unhandledFields: () =>
          "Sono stati trovati campi inaspettati nella riga.",
        unhandledTimestamp: () =>
          "Il timestamp della riga non era nel formato previsto.",
        unhandledTrade: () =>
          "La riga aveva un tipo di scambio non supportato.",
        userSpecifiedCurrencyIssue: () =>
          "Si è verificato un errore con la valuta specificata dall'utente.",
      },
      header: () => "righe saltate",
      info: (d) =>
        plural(d.count, 0, it, {
          1: " 1 riga nel CSV è stata saltata per il seguente motivo ",
          other:
            number("it", d.count, 0) +
            " righe nel CSV sono state saltate per i seguenti motivi ",
        }),
    },
    softWalletHeading: (d) =>
      "Importiamo i tuoi dati " +
      d.name +
      " direttamente dalla blockchain. Seleziona la rete blockchain del tuo portafoglio qui sotto.",
    softWalletNetworkSelect: () => "Inserisci la tua rete di portafogli",
    softWalletPrompt: () => "Ad esempio Bitcoin, Ethereum",
    solanaBalanceWarning: () =>
      "Il saldo include SOL puntati e SOL detenuti in account token. Alcuni esploratori potrebbero non includerli nel saldo complessivo.",
    sortImports: {
      aToZ: () => "Nome AZ",
      mostTransactions: () => "La maggior parte delle transazioni",
      zToA: () => "Nome ZA",
    },
    successImportWallet: (d) =>
      "Transazioni importate con successo dal portafoglio " +
      d.blockchain +
      " " +
      d.address,
    suspectedMissingImports: {
      addAccount: () => "Aggiungi account",
      chips: {
        amount: () => "Importo del bonifico:",
        count: () => "Transazioni:",
        currencies: () => "Valute:",
      },
      explainer: (d) =>
        "Sembra che tu abbia effettuato " +
        plural(d.count, 0, it, {
          1: " 1 transazione ",
          other: number("it", d.count, 0) + " transazioni ",
        }) +
        " che coinvolgono " +
        d.symbol1 +
        plural(d.additionalCurrencyCount, 0, it, {
          0: select(d.symbol2, { undefined: "", other: " e " + d.symbol2 }),
          1: select(d.symbol2, {
            undefined: "",
            other: ", " + d.symbol2 + ", e 1 altra valuta ",
          }),
          other: select(d.symbol2, {
            undefined: "",
            other:
              ", " +
              d.symbol2 +
              ", e " +
              number("it", d.additionalCurrencyCount, 0) +
              " altre valute ",
          }),
        }) +
        " su " +
        d.exchange +
        " " +
        select(d.startDate, {
          undefined: " in ",
          other: " tra " + d.startDate + " e ",
        }) +
        " " +
        d.endDate +
        ".",
      title: () => "Account consigliati",
      toastBody: () => "Puoi aggiungere questo account in qualsiasi momento",
      toastHeading: () => "Raccomandazione respinta",
      tooltip: () =>
        "Abbiamo notato interazioni con questi account. Collegare tutti i tuoi account migliorerà l'accuratezza del tuo report fiscale.",
      viewTransactions: (d) =>
        "Visualizza " +
        plural(d.count, 0, it, { 1: " transazione ", other: " transazioni " }) +
        ".",
      whyThis: () => "Perché questo account?",
    },
    sync: () => "Sincronizzazione",
    syncAll: () => "Sincronizza tutto",
    syncFailed: () => "Sincronizzazione non riuscita",
    syncStatus: {
      failed: (d) =>
        select(d.isCsv, {
          true: " Importazione non riuscita ",
          other: " Sincronizzazione non riuscita ",
        }),
      noSync: () => "Nessuna sincronizzazione",
      pending: (d) =>
        select(d.isCsv, {
          true: " Importazione ",
          other: " Sincronizzazione ",
        }),
      queued: (d) =>
        select(d.isCsv, { true: " Importazione ", other: " In coda " }),
      success: (d) =>
        select(d.isCsv, { true: " Importato ", other: " Sincronizzato " }),
    },
    syncedFrom: () => "Sincronizzato da:",
    syncing: () => "Sincronizzazione in corso...",
    syncingWallet: (d) => "Sincronizzazione del portafoglio " + d.name,
    title: () => "Aggiungi account",
    transactionCount: {
      noTransactions: () => "Nessuna transazione",
      noTxs: () => "Nessuna TX",
      transaction: () => "transazione",
      transactions: () => "transazioni",
      tx: () => "TX",
      txs: () => "TX",
    },
    transactionsImported: () => "Transazioni importate",
    updatedAt: (d) => "Aggiornato " + d.time,
    uploaded: (d) => "Caricato " + d.time,
    verifying: () => "Verificando",
    view: () => "Visualizzazione",
    viewGuide: () => "Visualizza guida",
    viewMode: {
      byAddress: () => "Per Portafoglio",
      byBlockchain: () => "Tramite Blockchain",
    },
    viewSBSInstructions: () => "Visualizza le istruzioni passo passo",
    wentWrongAvailableImports: () =>
      "Si è verificato un problema durante l'acquisizione delle importazioni disponibili. Riprova più tardi.",
    wentWrongCurrentHoldings: () =>
      "Si è verificato un problema durante l'acquisizione delle partecipazioni correnti. Riprova più tardi.",
    wentWrongDeleteFile: () =>
      "Si è verificato un problema durante il tentativo di eliminare il file. Riprova più tardi.",
    wentWrongDeletingCSV: (d) =>
      "Si è verificato un errore durante l'eliminazione del file CSV " +
      d.name +
      " .",
    wentWrongDownloadFile: () => "Impossibile scaricare il file",
    wentWrongExistingSaved: () =>
      "Si è verificato un problema durante la richiesta di importazioni salvate esistenti. Riprova più tardi",
  },
  inTxCategoryOptionsTitle: () => "Transazioni in entrata",
  incomeToggles: () => "Opzioni di categoria di reddito",
  info: () => "Informazioni",
  instructions: () => "Istruzioni",
  integrationWarnings: {
    btc: {
      invalidWallet: {
        description: () =>
          "Ti consigliamo di utilizzare il tuo indirizzo xPub, yPub o zPub perché i portafogli Bitcoin generano più indirizzi sotto un unico account master",
        noTxs: () =>
          "Nessuna transazione è stata importata poiché l'indirizzo del wallet utilizzato non è un indirizzo xPub, yPub o zPub. Ti consigliamo di importare il tuo indirizzo xPub, yPub o zPub.",
        title: () => "Inserisci un indirizzo xPub, yPub o zPub",
        wrongFormat: () =>
          "L'indirizzo del wallet utilizzato non è un indirizzo xPub, yPub o zPub. Ti consigliamo di importare il tuo indirizzo xPub, yPub o zPub per assicurarti che tutte le tue transazioni Bitcoin vengano importate.",
      },
    },
    default: {
      noTxs: () =>
        "Nessuna transazione rilevata per questo portafoglio importato. Ciò potrebbe essere dovuto all'inserimento errato dell'indirizzo del portafoglio o al fatto che il portafoglio non ha transazioni sulla blockchain selezionata. Verifica i dettagli del tuo portafoglio e la selezione della blockchain.",
    },
  },
  invitation: {
    buttonText: {
      accepted: () => "Accettare",
      declined: () => "Declino",
      deleted: () => "Eliminare",
      pending: () => "Invitare",
      revoked: () => "Revoca accesso",
      revokedLink: () => "Revoca accesso",
    },
    confirmationDialog: {
      collaborator: {
        accepted: {
          actionText: () => "Accetta invito",
          cancelText: () => "Indietro",
          text: () =>
            "Sei sicuro di voler accettare questo invito? Questo ti aggiungerà come membro del team dell'organizzazione del contabile, dove potrai gestire i loro clienti.",
          title: () => "Accettare l'invito?",
        },
        declined: {
          actionText: () => "Rifiuta invito",
          cancelText: () => "Indietro",
          text: () =>
            "Vuoi davvero rifiutare questo invito? Non sarai aggiunto come membro del team dell'organizzazione del contabile.",
          title: () => "Rifiutare l'invito?",
        },
        deleted: {
          actionText: () => "Eliminare",
          cancelText: () => "Indietro",
          text: () => "Vuoi davvero eliminare questo invito?",
          title: () => "Eliminare l'invito?",
        },
        pending: {
          actionText: () => "Cancellare",
          cancelText: () => "Indietro",
          text: () => "Questo è un invito in sospeso",
          title: () => "Invito in sospeso",
        },
        revoked: {
          actionText: () => "Revoca accesso",
          cancelText: () => "Indietro",
          confirmationPrompt: () =>
            "Digita 'revoke-accountant-access-forever' per procedere con la revoca dell'accesso.",
          confirmationText: () => "revoke-accountant-access-forever",
          text: () =>
            "Sei sicuro di voler revocare l'accesso al profilo del tuo commercialista? Questa azione rimuoverà la tua capacità di visualizzare o modificare qualsiasi dato caricato tramite il dataset del tuo commercialista. Anche se hai caricato personalmente i dati, la proprietà potrebbe comunque risiedere presso il tuo commercialista.",
          title: () => "Revoca l'accesso?",
        },
      },
      other: {
        accepted: {
          actionText: () => "Accetta invito",
          cancelText: () => "Indietro",
          text: () =>
            "Dopo aver accettato l'invito, avrai la possibilità di visualizzare i dati caricati dal tuo commercialista o i dati caricati dal tuo account. Se il tuo commercialista non ha caricato nessuno dei tuoi dati, ti consigliamo di scegliere di visualizzare i dati su questo account. Se lo desideri, puoi revocare questo accesso in qualsiasi momento, tuttavia i dati rimarranno al tuo commercialista e verrai riportato ai tuoi dati originali. Sei sicuro di voler accettare questo invito?",
          title: () => "Accettare l'invito?",
        },
        declined: {
          actionText: () => "Rifiuta invito",
          cancelText: () => "Indietro",
          text: () => "Sei sicuro di voler rifiutare questo invito?",
          title: () => "Rifiutare l'invito?",
        },
        deleted: {
          actionText: () => "Eliminare",
          cancelText: () => "Indietro",
          text: () => "Vuoi davvero eliminare questo invito?",
          title: () => "Eliminare l'invito?",
        },
        pending: {
          actionText: () => "Cancellare",
          cancelText: () => "Indietro",
          text: () => "Questo è un invito in sospeso",
          title: () => "Invito in sospeso",
        },
        revoked: {
          actionText: () => "Revoca accesso",
          cancelText: () => "Indietro",
          text: () =>
            "Sei sicuro di voler revocare l'accesso al profilo del tuo commercialista? Non potrai più leggere o modificare i dati che il tuo commercialista ha caricato per tuo conto.",
          title: () => "Revoca l'accesso?",
        },
      },
    },
    noInvites: () => "Non hai ancora ricevuto alcun invito dai commercialisti",
    receivedInvitations: () => "Inviti ricevuti",
    warning: () =>
      "Qualcosa è andato storto! Se il problema persiste, contatta l'assistenza.",
    wentWrongGettingInvitations: () =>
      "Si è verificato un problema con l'invio degli inviti. Riprova più tardi.",
  },
  invitationStatus: {
    accepted: () => "Accettato",
    declined: () => "Rifiutato",
    deleted: () => "Eliminato",
    notInvited: () => "Non invitato",
    pending: () => "In attesa di",
    revoked: () => "Revoca",
    revokedLink: () => "Revoca",
  },
  invite: () => "Invita il cliente",
  keys: {
    deprecated: () => "Obsoleto",
    deprecatedInfo: (d) =>
      d.exchange +
      " ha deprecato questo metodo di autenticazione. Lo supportiamo ancora, ma consigliamo di aggiornare le chiavi in base alle nuove istruzioni. [Ulteriori informazioni]( " +
      d.linkTextKey +
      " )",
    deprecatedInfoByExchange: {
      coinbase: (d) =>
        d.exchange +
        " ha deprecato questo metodo di autenticazione. Aggiorna le tue chiavi in base alle nuove istruzioni. [Ulteriori informazioni]( " +
        d.linkTextKey +
        " )",
    },
    wentWrongDeleteExchangeAPI: (d) =>
      "Si è verificato un problema durante il tentativo di eliminare le chiavi API per " +
      d.name +
      " . Riprova più tardi.",
    wentWrongDeletingAPI: () =>
      "Si è verificato un problema durante il tentativo di eliminare la chiave API. Riprova più tardi.",
    wentWrongSavingAPI: (d) =>
      "Si è verificato un problema durante il salvataggio delle chiavi API " +
      d.name +
      " .",
    wentWrongUpdatingAPI: (d) =>
      "Si è verificato un problema durante l'aggiornamento delle chiavi API " +
      d.name +
      " .",
  },
  labels: {
    addExistingLabels: () => "Aggiungi etichette esistenti",
    addLabel: () => "Aggiungi etichetta",
    cancel: () => "Cancellare",
    createNewLabel: () => "Crea nuova etichetta",
    labelColor: () => "Colore dell'etichetta",
    labelDescription: () => "Descrizione etichetta (facoltativo)",
    labelName: () => "Nome dell'etichetta",
    noLabels: () => "Nessuna etichetta creata",
    saveAndAdd: () => "Salva e aggiungi",
  },
  language: () => "Lingua",
  learnMore: () => "Scopri di più nel centro assistenza",
  ledger: {
    banner: {
      text: () =>
        "La cronologia degli acquisti mancante si verifica quando sono stati venduti più asset crittografici di quelli acquistati in origine. Ciò suggerisce che alcuni record di acquisizione potrebbero essere mancanti, non categorizzati o categorizzati in modo errato.",
      title: () => "questo è il banner del registro",
    },
    confirmations: {
      deleteTransactionConfirmation: {
        text: () =>
          "Attenzione! Questa azione non può essere annullata. Vuoi davvero eliminare questa transazione?",
        title: () => "Elimina transazione",
      },
      ignoreTransactionConfirmation: {
        text: () =>
          "Vuoi davvero ignorare questa transazione? Eseguendo questa azione ignorerai questa transazione da qualsiasi calcolo fiscale. Le transazioni ignorate possono essere nascoste dalla tabella delle transazioni nei filtri avanzati.",
        title: () => "Ignora transazione",
      },
    },
    deposit: () => "Depositare",
    descriptions: {
      amountAcquired: (d) =>
        "La quantità totale di " +
        d.ticker +
        " che hai acquisito e registrato nell'app.",
      amountDisposed: (d) =>
        "La quantità totale di " +
        d.ticker +
        " che hai venduto o smaltito, così come registrata nell'app.",
      amountMissing: (d) =>
        "La quantità di " +
        d.ticker +
        " non contabilizzata nelle tue transazioni. Ciò potrebbe essere dovuto a transazioni non categorizzate o dati mancanti.",
      amountUncategorised: (d) =>
        "Importo di " +
        d.ticker +
        " che non è stato ancora categorizzato. Per rendiconti e calcoli fiscali accurati, assegnare categorie a queste transazioni.",
    },
    errors: {
      unableLoadRecon: () => "Impossibile caricare il registro",
    },
    filters: {
      onlyShowNegativeBalance: () =>
        "Mostra solo la cronologia degli acquisti mancanti",
    },
    goBack: () => "Torna indietro",
    headings: {
      amountNegative: () => "Importo negativo:",
      holdings: () => "Titoli:",
      taxImpact: () => "Impatto fiscale:",
      transactions: () => "Transazioni:",
      value: () => "Valore:",
    },
    headings2: {
      amountAcquired: () => "Importo totale acquisito",
      amountDisposed: () => "Importo totale smaltito",
      amountMissing: () => "Importo mancante",
      amountUncategorised: () => "Importo Non categorizzato",
      transactions: () => "Transazioni",
    },
    ledger: () => "Libro mastro",
    ledgerSourceFilter: {
      accounts: () => "Conti",
      noOptions: () => "Nessuna opzione",
      placeholder: () => "Mostra tutti gli account",
      selectAll: () => "Seleziona tutto",
    },
    loading: () => "Caricamento",
    negativeBalanceOverallTooltip: () =>
      "Per questa risorsa manca la cronologia degli acquisti.",
    negativeBalanceTooltip: (d) =>
      "Ci sono state più transazioni di vendita " +
      d.ticker +
      " che acquisti. Verifica le transazioni in arrivo.",
    newest: () => "Più recente",
    notFound: () => "Non trovato",
    oldest: () => "Il più vecchio",
    pageTitle: (d) => d.currencyName + " libro mastro",
    tableHeader: {
      account: () => "Account",
      amount: () => "Quantità",
      dateAndType: () => "Data e tipo",
      overallBalance: () => "Equilibrio complessivo",
      tooltips: {
        account: () =>
          "Portafogli, exchange, dati importati o simili e il saldo di questo account immediatamente dopo che è avvenuta la transazione.",
        overallBalance: () =>
          "Il saldo totale di tutti i tuoi conti subito dopo la transazione.",
      },
    },
    uncategorisedTooltip: () =>
      "Affinché questa transazione possa essere conteggiata nel saldo del libro mastro, è necessario categorizzarla.",
    unmatchedTooltip: () =>
      "Questa transazione non viene conteggiata nel saldo del libro mastro perché è unilaterale.",
    withdrawal: () => "Ritiro",
  },
  legacyPlanTooltip: () => "Sei su un piano legacy con 3000 transazioni",
  manual: {
    Date: () => "Data",
    addNewTransaction: () => "Aggiungi nuova transazione",
    addTransaction: () => "Aggiungi transazione",
    currency: () => "Valuta",
    feeCurrency: () => "Valuta della commissione",
    feeQuantity: () => "Quantità della tariffa",
    from: () => "Da",
    price: () => "Prezzo",
    quantity: () => "Quantità",
    quoteCurrency: () => "Valuta di quotazione",
    quoteQuantity: () => "Quantità di preventivo",
    receiveCurrency: () => "Acquista valuta",
    receiveQuantity: () => "Acquista quantità",
    requiredText: {
      currency: () => "Devi inserire una valuta",
      feeCurrency: () => "È necessario inserire una valuta di commissione",
      feeQuantity: () => "È necessario immettere una quantità di commissione",
      notInLockedPeriod: () => "La data non deve essere nel periodo bloccato",
      price: () => "Devi inserire un prezzo",
      quantity: () => "Devi inserire una quantità",
      time: (d) => "Devi inserire un orario valido nel formato " + d.timeFormat,
      value: () => "Devi inserire un valore",
    },
    sellCurrency: () => "Vendere valuta",
    sellQuantity: () => "Quantità di vendita",
    stepTitle: {
      category: () => "Categoria di transazione",
      details: (d) =>
        select(d.category, {
          trade: " dettagli commercio ",
          transfer: " dettagli trasferimento ",
          other: " dettagli transazione ",
        }),
      fees: () => "Commissioni",
      sourceAndDest: () => "Origine e destinazione",
    },
    time: () => "Tempo",
    to: () => "A",
    trade: () => "Categoria",
    transactionType: {
      other: () => "Altro",
      trade: () => "Commercio",
      transfer: () => "Trasferire",
    },
    transactionTypeDescription: {
      other: () => "Tutte le altre transazioni",
      trade: () => "Qui è dove vendi una valuta per un'altra",
      transfer: () =>
        "Qui è dove puoi effettuare trasferimenti tra i tuoi portafogli/scambi",
    },
    value: () => "Valore",
  },
  manualCSV: {
    addCustomCSV: () => "Aggiungi CSV personalizzato",
    advanced: {
      link: () =>
        "https://help.cryptotaxcalculator.io/en/articles/5777675-advanced-manual-csv-import/",
      part1: () =>
        "Per le negoziazioni avanzate in cui le commissioni devono ancora essere applicate con i dati di negoziazione, vedere il nostro ",
      part2: () => "guida CSV avanzata.",
    },
    advancedOptions: () => "Opzioni avanzate",
    cryptoGroup: () => "Criptovaluta",
    dateFormatHelper: () =>
      "Seleziona il formato data che appare sul tuo CSV. Se non specificato, il formato data sarà predefinito su AAAA/MM/GG HH:mm:ss.",
    example: () => "Scarica un esempio di formato CSV",
    exchange: () => "Scambio",
    exchangeRule: () => "Devi inserire uno scambio",
    fiatGroup: () => "Fiat",
    here: () => "Qui",
    importFromDateHelper: () =>
      "Seleziona la data da cui desideri iniziare l'importazione delle transazioni. Se non specificata, verranno importate tutte le transazioni.",
    importHelper: {
      helpText: () => "Hai bisogno di aiuto per importare i dati?",
      link: () =>
        "https://help.cryptotaxcalculator.io/articles/5777675-advanced-manual-custom-csv-import",
    },
    manualGroup: () => "Manuale",
    manualSelectCurrencies: () => "Assegna manualmente le valute",
    manualSelectDateFormat: () => "Seleziona manualmente il formato della data",
    manualSelectImportFromDate: () =>
      "Seleziona manualmente la data di inizio importazione",
    manualSelectTimezone: () => "Seleziona manualmente il fuso orario",
    nameExists: () =>
      "Il nome esiste già, per caricarlo su questo scambio selezionalo dalla pagina di importazione",
    nameTooLong: () => "Nome troppo lungo",
    noOptions: () => "Nessuna opzione",
    patternRule: () => "Sono ammessi solo caratteri alfanumerici e spazi",
    pendingUploadMessage: {
      manyFiles: (d) => d.numFiles + " file da caricare",
      singleFile: (d) => "1 file ( " + d.filename + " ) da caricare",
    },
    preparationWarning: () =>
      "I dati CSV richiedono la preparazione prima del caricamento",
    selectCurrencies: () => "Seleziona valute",
    selectDateFormat: () => "Seleziona il formato della data",
    simple: {
      link: () => "https://cryptotaxcalculator.io/advanced.csv",
      part1: () => "Scarica un CSV di esempio",
      part2: () => " per vedere un esempio del formato richiesto.",
    },
    tickerHelper: () =>
      "Seleziona i ticker che appaiono sul tuo CSV e che vorresti assegnare a una valuta specifica. Ad esempio, se vuoi assegnare il ticker 'BTC' a 'Bitcoin', seleziona 'Bitcoin (BTC)' nel menu a discesa qui sotto. Tutti i ticker non specificati saranno assegnati in base alla loro classifica di capitalizzazione di mercato, ove possibile.",
    timezoneHelper: () =>
      "Seleziona il fuso orario in cui sono avvenute le transazioni. Se non specificato, il fuso orario verrà rilevato automaticamente.",
    title: () => "Importa dati tramite CSV",
    uploadFileWarning: () => "Carica un file CSV",
  },
  markAsReviewed: () => "Contrassegna come revisionato",
  markAsUnreviewed: () => "Contrassegna come non recensito",
  mobileDemandTest: {
    buttonText: () => "Ottieni l'app",
    chip: () => "Registrati per la BETA",
    description: () =>
      "Registra il tuo interesse e sii tra i primi ad ottenere l'accesso anticipato all'App CTC – rendendo la tassazione delle criptovalute più semplice che mai.",
    noThanks: () => "No grazie",
    registerInterest: () => "Registra interesse",
    title: () => "Sii tra i primi ad ottenere l'App",
    toast: {
      description: () => "Controlla la tua casella di posta",
      title: () => "Hai registrato il tuo interesse",
    },
  },
  mockReport: {
    disabled: () => "Disabilitato per i dati di prova",
    infoBanner: {
      button: () => "Visualizza i dati di esempio",
      subtext: () =>
        "È possibile visualizzare i dati di prova prima di scegliere un piano tariffario.",
      title: () => "Visualizza i dati di esempio",
    },
    signup: {
      button: (d) =>
        select(d.isAccountant, {
          true: " Aggiorna client ",
          other: " Inizia ",
        }),
      errorMessage: () =>
        "Si è verificato un problema durante l'aggiornamento del client. Contatta l'assistenza",
      subtext: (d) =>
        select(d.isAccountant, {
          true: select(d.txCount, {
            0: "Questo cliente al momento non ha un piano a pagamento. Per generare un report fiscale, dovrai acquistare un piano 👉",
            other:
              "Questo cliente al momento ha " +
              d.txCount +
              " importati " +
              plural(d.txCount, 0, it, {
                1: "transaction",
                other: "transactions",
              }) +
              ". Per generare un report fiscale, dovrai aggiornare questo cliente al piano " +
              d.suggestedPlan +
              " 👉",
          }),
          other:
            "Scegli uno dei nostri piani per visualizzare i tuoi dati e scaricare i report fiscali.",
        }),
      title: (d) =>
        select(d.isAccountant, {
          true: " Vuoi generare un report fiscale per " + d.clientName + " ? ",
          other: " Genera il tuo report fiscale ",
        }),
    },
    testData: () => "Dati di prova",
  },
  na: () => "N / A",
  name: () => "Nome",
  nav: {
    billing: () => "Fatturazione",
    contacts: () => "Contatti",
    developer: () => "Sviluppatore",
    imports: () => "Importa dati di Exchange",
    logout: () => "Esci",
    manageSubscription: () => "Gestisci abbonamento",
    manual: () => "Importazione manuale CSV",
    menu: () => "Menu",
    plans: () => "Piani",
    report: () => "Visualizza il report fiscale",
    settings: () => "Impostazioni",
    transactions: () => "Revisione delle transazioni",
    wallet: () => "Importa dati portafoglio",
    warnings: () => "Avvertenze",
  },
  navbar: {
    accountingIntegrations: () => "Integrazioni contabili",
    addressBook: () => "Rubrica",
    clientSettings: () => "Impostazioni del cliente",
    clients: () => "Clienti",
    cryptoIntegrations: () => "Integrazioni crittografiche",
    dashboard: () => "Pannello di controllo",
    followUsOnX: () => "Seguici su X",
    footer: {
      buttonCta: () => "Sblocca risparmi fiscali",
      referralXOffAllPlans: (d) =>
        "Sconto referral del " + d.discount + "% su tutti i piani",
      xPercentOffAllPlans: (d) =>
        "Sconto del " + d.discount + "% su tutti i piani",
      youHaveXTransactions: (d) => "Hai " + d.count + " transazioni",
    },
    help: () => "Vai al centro assistenza",
    helpCenter: () => "Centro assistenza",
    imports: () => "Importazioni",
    integrations: () => "Conti",
    more: () => "Di più",
    myClients: () => "I miei clienti",
    mySettings: () => "Impostazioni dell'area di lavoro",
    myTeam: () => "La mia squadra",
    portfolio: () => "Portfolio",
    reconciliation: () => "Riconciliazione",
    referAFriend: () => "Segnala un amico",
    referFriends: () => "Invita un amico - Ricevi $40",
    referFriendsV2: (d) => "Invita e guadagna " + d.reward,
    referGetHundredCash: (d) => "Ottieni " + d.reward + " in contanti",
    report: () => "Rapporti",
    review: () => "Revisione",
    rules: () => "Regole",
    selectClient: () => "Seleziona cliente",
    settings: () => "Impostazioni",
    subheadings: {
      integrations: () => "Conti",
      reconciliation: () => "Riconciliazione",
      reports: () => "Rapporti",
    },
    subscribe: () => "Iscriviti",
    support: () => "Chatta con il supporto",
    tips: () => "Suggerimenti",
    title: () => "CTC",
    tooltip: (d) =>
      "[Iscriviti]( " +
      d.linkTextKey +
      " ) per visualizzare suggerimenti su come risparmiare sulla dichiarazione dei redditi",
    tooltipReview: (d) =>
      "[Iscriviti]( " +
      d.linkTextKey +
      " ) per rivedere le tue transazioni e risparmiare sulla tua dichiarazione dei redditi",
    transactionSubItems: {
      failed: () => "Fallito",
      notReadyToSync: () => "Non pronto per la sincronizzazione",
      outOfDate: () => "Fuori moda",
      readyToSync: () => "Pronto per la sincronizzazione",
      synced: () => "Sincronizzato",
    },
    transactions: () => "Transazioni",
  },
  new: () => "Nuovo",
  newTxModal: {
    actionButton: () => "Controlla!",
    back: () => "Indietro",
    description: {
      0: () =>
        "La tabella delle transazioni è stata migliorata per consentire una comprensione rapida e semplice dei dati",
      1: () =>
        "Quando categorizzi una transazione, ora identifichiamo transazioni simili da applicare in blocco in modo che tu non debba ripeterti",
      2: () =>
        'Abbiamo sostituito la pagina Riconciliazione con una nuova versione semplificata denominata "Suggerimenti" disponibile per gli abbonati attivi',
      3: () =>
        "Il nostro algoritmo di automazione è stato aggiornato in modo da poter categorizzare in modo più intelligente le transazioni on-chain complesse",
    },
    nextPage: () => "Prossimo",
    title: () => "Nuova tabella delle transazioni e molto altro!",
  },
  next: () => "Prossimo",
  no: () => "NO",
  noResults: () => "Nessun risultato",
  none: () => "Nessuno",
  notifications: {
    missingBlockchain: {
      blogLink: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6388387-how-to-resolve-missing-blockchain-warnings",
      cta: () => "Risolvere",
      link: () => "/transazioni?warning=Blockchain mancante",
      primary: () => "Blockchain mancante",
      secondary: () =>
        "Esaminare le transazioni in cui non è stata trovata alcuna blockchain.",
    },
    missingPrice: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/missing-market-prices",
      cta: () => "Risolvere",
      link: () => "/transactions?warning=missingPrice",
      primary: () => "Prezzi di mercato mancanti",
      secondary: () =>
        "Rivedere e aggiornare le transazioni per le quali non è stato trovato alcun prezzo di mercato.",
    },
    negativeBalance: {
      blogLink: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
      cta: () => "Risolvere",
      link: () => "/transactions?warning=negativeBalance",
      primary: () => "Cronologia degli acquisti mancante",
      secondary: () =>
        "Controlla le transazioni che causano un saldo negativo.",
    },
    uncategorised: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/cryptocurrency-tax-categories",
      cta: () => "Risolvere",
      link: () => "/transactions?warning=uncategorised",
      primary: () => "Transazioni non categorizzate",
      secondary: () =>
        "Le tue transazioni sono state ricategorizzate per migliorare la precisione della calcolatrice.",
    },
    zeroCostBuy: {
      blogLink: () =>
        "https://cryptotaxcalculator.io/guides/negative-balance-remaining-warnings",
      blogLink2: () =>
        "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
      cta: () => "Risolvere",
      link: () => "/transactions?warning=negativeBalance",
      primary: () => "Acquisti a costo zero",
      secondary: () =>
        "Esamina le transazioni di vendita in cui hai una base di costo pari a zero a causa della cronologia degli acquisti mancanti precedenti non risolti. Ciò potrebbe causare guadagni segnalati più alti di quanto dovrebbero essere.",
    },
  },
  onboardingV2: {
    LetsReviewTx: {
      body1: () => "Non dovrebbe essere automatizzato, secondo te?",
      body2: () =>
        "Sì, la maggior parte delle tue transazioni vengono categorizzate automaticamente, ma in alcuni casi la categoria non può essere determinata. Per garantire l'accuratezza, le contrassegniamo per consentirti di chiarire.",
      cta: () => "Continuare",
      title: () => "Ok, rivediamo alcune delle tue transazioni",
    },
    accountConfirmation: {
      CTA: () => "Continua l'importazione",
      nonCTA: () => "Vai al passaggio successivo",
      subtitle: () =>
        "Per garantire la correttezza dei numeri è necessaria una cronologia completa delle transazioni.",
      title: (d) =>
        "Hai " +
        plural(d.count, 0, it, {
          one: " aggiunto solo 1 account ",
          other: " aggiunti " + number("it", d.count, 0) + " account ",
        }) +
        " !",
      title2: () => "Sei sicuro di aver importato tutto?",
    },
    accountCountry: {
      plusMore: (d) => "+ " + d.count + " altro",
      showLess: () => "Mostra meno",
      showMore: () => "Mostra altro",
      subtitle: () =>
        "Ciò determina le regole fiscali predefinite e la valuta locale.",
      title: () => "Scegli la tua giurisdizione fiscale",
    },
    accountList: {
      addAnotherAccount: () => "Aggiungi un altro account",
      yourAccounts: () => "I tuoi account",
    },
    accountTheme: {
      footerButton: () => "Prossimo",
      subtitle: () => "Potrai sempre modificarlo in seguito.",
      themes: {
        dark: () => "Modalità scura",
        light: () => "Modalità luce",
        system: () => "Modalità di sistema",
      },
      title: () => "Quale tema preferisci?",
    },
    accountType: {
      footerButton: () => "Prossimo",
      securityCta: () => "Sicurezza dei dati e privacy",
      subtitle: () => "Che tipo di utente sei?",
      title: () => "Benvenuti a Crypto Tax Calculator 👋",
      types: {
        accountant: () => "Contabile",
        individual: () => "Individuale",
      },
    },
    accuracy: {
      content: {
        accounts: () => "conti",
        categorise: () => "Categorizza le tue transazioni",
        complete: () =>
          "dovrebbe essere fornita una cronologia completa di tutte le tue transazioni. Ciò consente al nostro algoritmo di calcolo delle imposte di abbinare automaticamente le transazioni sui tuoi conti, riducendo al minimo il lavoro richiesto da parte tua e assicurando che i guadagni calcolati siano accurati.",
        considerations: () => "Considerazioni chiave:",
        costBase: () => "base di costo",
        determine: () => "Per determinare con precisione il tuo",
        ensure: () =>
          "Mentre la maggior parte delle transazioni verrà automaticamente categorizzata. In alcuni casi non è possibile determinare la categoria corretta. La categorizzazione delle transazioni assicura che venga applicato il risultato fiscale corretto.",
        import: () => "Importa tutti i tuoi ",
        inactive: () => " anche quelli che non utilizzi più attivamente.",
        noAccess: () =>
          "Si noti che CTC non ha mai accesso ai fondi degli utenti. Le importazioni sono strettamente limitate alle informazioni sulle transazioni necessarie per calcolare le tasse.",
      },
      footerBack: () => "Indietro",
      footerNext: () => "Prossimo",
      subtitle: () => "Produzione di report fiscali accurati",
      title: () => "Come ottenere resoconti fiscali accurati",
    },
    areYouSure: {
      body1: {
        costBasis: () => "base di costo",
        costBasisTooltip: () =>
          "Il prezzo originale pagato per l'asset, più le commissioni di transazione",
        frontPart: () =>
          "Una cronologia completa delle tue transazioni migliorerà notevolmente l'accuratezza dei numeri. Permettendoti di tracciare con precisione le tue ",
        lastPart: () => " su tutti i tuoi asset.",
      },
      body2: {
        accounts: () => "conti,",
        accountsTooltip: () =>
          "Portafogli e exchange utilizzati per effettuare transazioni in criptovaluta.",
        frontPart: () =>
          "Ciò è particolarmente importante quando si trasferiscono beni tra ",
        lastPart: () =>
          " ad esempio, quando si inviano criptovalute da un exchange a un altro wallet. Se quell'exchange non è stato importato, non saremo in grado di determinare la base di costo per quell'asset.",
      },
      buttons: {
        doneImport: () => "Sono certo di aver importato tutto",
        importMore: () => "Lasciami importarne ancora un po'",
      },
      footer: () =>
        "Puoi sempre aggiungere altri portafogli in seguito, ma ti consigliamo di farlo ora per garantire un'esperienza fluida",
      title: () => "Sei sicuro di aver importato tutti i tuoi account?",
    },
    calculating: {
      autoSaved: () => "I progressi vengono salvati automaticamente",
      buttons: {
        continue: () => "Continuare",
        finish: () => "Fine",
        goBack: () => "Torna indietro",
        importMore: () => "Importa di più",
        skip: () => "Inserisci l'app",
      },
      loading10Minutes: () =>
        "Wow, sembra che tu abbia un sacco di transazioni! Potrebbe volerci un po' di tempo, ma ci stiamo lavorando. Ti invieremo un'email non appena l'importazione sarà completata.",
      loading3Minutes: () =>
        "L'importazione delle transazioni potrebbe richiedere del tempo; nell'attesa, continua a configurare il tuo account.",
      loadingETA: () => "Di solito ci vogliono dai 2 ai 3 minuti",
      loadingETAFake: () => "Non ci vorrà molto",
      loadingTakingLongerThanUsual: () =>
        "Ci sta mettendo più tempo del solito. Sto ancora elaborando quei numeri.",
      loadingText: {
        calculatingTheCostBaseForEachAsset: () =>
          "Calcolo della base di costo per ogni attività",
        categorisingYourTransactions: () =>
          "Categorizzazione delle transazioni",
        categorizingBridges: () => "Rilevamento dei ponti",
        categorizingStaking: () => "Rilevamento dello staking",
        categorizingSwaps: () => "Rilevamento degli swap",
        categorizingTrades: () => "Rilevamento delle operazioni",
        categorizingTransfers: () => "Rilevamento dei trasferimenti",
        computingGainsForEachTransaction: () =>
          "Calcolo dei guadagni per ogni transazione",
        detectingAnyCryptoIncome: () =>
          "Rilevazione di qualsiasi reddito in criptovaluta",
        finalizingInitialTaxReports: () =>
          "Finalizzazione dei report fiscali iniziali",
        importingTransactionData: () =>
          "Importazione dei dati delle transazioni",
        summarizingCapitalGainByTaxYear: () =>
          "Riepilogo delle plusvalenze per anno fiscale",
      },
      titleCalculationsComplete: () => "Calcoli completati!",
    },
    cexImportReminder: {
      bodyText: () =>
        "È importante importare tutti i tuoi wallet, inclusi tutti gli exchange centralizzati che hai utilizzato. Se non abbiamo tutta la tua attività di trading, i numeri non saranno corretti.",
      modalText: () =>
        "Ad esempio, alcuni exchange che potresti aver utilizzato e che devi importare sono:",
      primaryButton: () => "Importa uno scambio",
      secondaryButton: () => "Continua senza importare",
      title: () => "Non dimenticare di importare i tuoi exchange centralizzati",
    },
    coinbaseOauth: {
      import: {
        connectCoinbase: () => "Connetti Coinbase",
        continue: () => "Continuare",
        importedSubtitle: () => "Continua a configurare il tuo account.",
        importedTitle: () => "Transazioni Coinbase importate",
        importingBanner: () =>
          "L'importazione delle tue transazioni Coinbase potrebbe richiedere del tempo, mentre aspetti,",
        importingBannerLink: () => "Continua a configurare il tuo account",
        importingSubtitle: () =>
          "Nel frattempo, continuiamo a configurare il tuo account.",
        importingTitle: () => "Stiamo importando le tue transazioni",
        skipConnectCoinbase: () => "Non hai un account Coinbase?",
        skipConnectCoinbaseCTA: () => "Continua senza connettere Coinbase",
        subtitle: () => "Connettiamo il tuo account",
        title: () => "Collega il tuo account Coinbase",
      },
      welcomeTitle: () => "Benvenuto utente Coinbase 👋",
    },
    competitorSurvey: {
      options: {
        no: () => "NO",
        yes: () => "SÌ",
      },
      title: () => "Hai già utilizzato un altro software per la criptovaluta?",
    },
    example: () => "Esempio",
    import: {
      autoImported: () => "Importato automaticamente",
      footer: () => "+ oltre 2.900 integrazioni in più.",
      footerCTA: () => "Cerca il tuo",
      hasSavedAccounts: {
        finishImport: () => "Ho aggiunto tutti i miei account",
        keepAdding: () => "Continua ad aggiungere i tuoi account",
        or: () => "O",
        subTitle: () =>
          "Assicurati di aggiungere tutti i tuoi exchange e wallet in modo che i numeri tornino!",
        title: () => "👉 Aggiungi il tuo prossimo account",
      },
      importManualCSV: () => "Importazione manuale CSV",
      navBar: {
        importType: {
          api: () => "API",
          "bulk-wallet": () => "Portafoglio multiplo",
          "connect-wallet": () => "Connetti portafoglio",
          csv: () => "CSV",
          oauth: () => "Connetti",
          "soft-wallet": () => "Connetti",
          wallet: () => "API",
        },
      },
      searchBar: {
        importWallet: () => "Importa portafoglio",
        placeholder: () => "Cerca il tuo exchange o incolla il tuo wallet",
        section: {
          allOthers: () => "Tutti gli scambi e i portafogli",
          manual: () => "Manuale",
          popular: () => "Popolare",
        },
      },
      subTitle: () =>
        "Cerca il tuo exchange o incolla l'indirizzo del tuo wallet 👇 ",
      title: () => "Aggiungi il tuo primo account",
    },
    metamask: {
      accurateCpaEndorsed: () =>
        "Rapporti fiscali accurati, approvati dal CPA, senza alcuna supposizione",
      accurateCryptoTaxes: () =>
        "Tasse crypto accurate.\nNessuna approssimazione.",
      continueWith: (d) => "Continua con " + d.provider,
      cryptoTaxCalculator: () =>
        "Calcolatrice delle imposte sulle criptovalute",
      cta: () => "Continuare",
      disclaimer: () =>
        "*Solo per i nuovi clienti di Crypto Tax Calculator. Lo sconto si applica solo al primo anno.",
      fullSupportIRS: () =>
        "Supporto completo per le regole IRS. Importa direttamente su TurboTax o H&R Block, oppure condividi in modo sicuro con il tuo commercialista.",
      manageCryptoTaxes: () =>
        "Gestisci le tue imposte sulle criptovalute direttamente dal luogo in cui fai trading.",
      newCustomersDiscount: () =>
        "I nuovi clienti ottengono uno sconto del 30%*",
      partnerAlt: () => "Partner ufficiale CryptoTaxCalculator x Metamask",
      previewAlt: () => "Anteprima di Tax Hub",
      skip: () => "Oppure utilizza email e password",
      supportsAllWallets: () =>
        "Supporta tutti i tuoi portafogli, exchange e attività onchain.",
      taxhubPoweredBy: () => "Tax Hub fornito da",
    },
    onMobileBanner: {
      title: () =>
        "Sembra che tu stia utilizzando un dispositivo mobile. Per un'esperienza ottimale, accedi dal tuo desktop!",
    },
    onYourWay: {
      title: () =>
        "Bene, sei sulla buona strada per ricevere la tua dichiarazione dei redditi!",
      viewPlans: () => "Visualizza i piani",
      viewReports: () => "Visualizza i report",
    },
    platformTypes: {
      cex: () => "Scambi di criptovalute centralizzati",
      count: (d) => "+ " + d.count + " altro",
      defi: () => "Attività on-chain (DeFi e NFT)",
      footerBack: () => "Indietro",
      footerNext: () => "Prossimo",
      footerSkip: () => "Saltare",
      subtitle: () =>
        "Personalizza la tua esperienza in base al tipo di trader di criptovalute che sei:",
      title: () => "Su quali tipi di piattaforme hai fatto trading?",
    },
    progressBar: {
      confirmCountry: () => "Conferma il paese",
      freeTaxPreview: () => "Anteprima fiscale gratuita",
      importData: () => "Importa dati",
      selectIntegration: () => "Seleziona integrazione",
    },
    provider: {
      connect: {
        "2fa": () => "Inserisci il tuo codice 2FA",
        cta: () => "Accedi e collega l'account",
        disclaimer: (d) =>
          "Effettuando l'accesso collegherai il tuo account " +
          d.provider +
          " con Crypto Tax Calculator, questo ti permetterà di accedere con un solo clic da Independent Reserve.",
        email: () => "E-mail",
        password: () => "Password",
        subtitle: () =>
          "Sembra che tu abbia già un account Crypto Tax Calculator associato a quell'indirizzo e-mail.",
        title: () => "Accedi per continuare",
      },
      setPassword: {
        cta: () => "Salva la password",
        email: () => "E-mail",
        password: () => "Password",
        passwordsDoNotMatch: () => "Le password non corrispondono",
        retypePassword: () => "Ripeti password",
        title: () => "Imposta la tua password",
      },
      setPasswordSuccess: {
        cta: () => "Continuare",
        description: () =>
          "Utilizza questa password per accedere a cryptotaxcalculator.io",
        providerLoginNote: (d) =>
          "Nota: puoi sempre effettuare l'accesso con 1 clic dal tuo account " +
          d.provider +
          ".",
        title: () => "Password impostata!",
      },
      syncing: {
        bannerCTA: () => "Continua a configurare il tuo account",
        bannerText: (d) =>
          "L'importazione delle transazioni " +
          d.provider +
          " potrebbe richiedere del tempo, mentre attendi,",
        completeTitle: () => "Transazioni importate",
        getTaxReportCta: () => "Ottieni il report fiscale",
        inProgressTitle: () => "Stiamo importando le tue transazioni",
        viewTaxSummaryCta: () => "Visualizza il riepilogo fiscale",
      },
      welcome: {
        cta: () => "Continuare",
        description: () =>
          "Crypto Tax Calculator è una piattaforma completa per calcolare le tue tasse sulle criptovalute. Prepareremo dei report fiscali che includono:",
        items: {
          0: () => "Guadagni a lungo termine",
          1: () => "Guadagni a breve termine",
          2: () => "Reddito",
          3: () => "Spese",
          4: () => "Detrazioni",
        },
        title: () => "Benvenuti a Crypto Tax Calculator",
      },
    },
    security: {
      buttonText: () => "Sicurezza dei dati e privacy",
      privacy: {
        description: () =>
          "Non ti chiederemo mai le chiavi private dei tuoi portafogli crittografici. Le nostre integrazioni richiedono solo l'accesso in lettura ai tuoi dati e tutte le chiavi API sono archiviate e crittografate in modo sicuro durante il transito e a riposo",
        title: () => "Privacy completa dei dati",
      },
      soc2: {
        description: () =>
          "Siamo certificati SOC 2 Tipo 2 e disponiamo di meccanismi di sicurezza per garantire la sicurezza dei tuoi dati.",
        title: () => "Certificato SOC 2 Tipo 2",
      },
      subtitle: () => "Prendiamo molto seriamente la sicurezza dei dati",
      title: () => "I tuoi dati sono al sicuro",
    },
    skipAndEnterApp: () => "Salta e accedi all'app",
    taxCalculator: {
      buttons: {
        addAnotherAccount: () => "Aggiungi un altro account",
        back: () => "Indietro",
        getTaxReport: () => "Ottieni il report fiscale",
      },
      disclaimer: () =>
        "Questa è una stima basata sui dati importati finora. Per un risultato accurato è necessario un quadro completo di tutte le tue transazioni da quando hai acquistato criptovalute per la prima volta",
      estimatedGain: () => "Guadagno stimato",
      heading: () => "Stima delle plusvalenze",
      income: () => "Reddito",
      tooltips: {
        estimatedGain: () =>
          "Il guadagno stimato rappresenta i tuoi guadagni totali dalle criptovalute e viene calcolato come Guadagni in conto capitale + Reddito - Perdite in conto capitale.",
        gains: () =>
          "Il profitto che hai realizzato dalle operazioni che hanno generato un guadagno. (Operazioni su cui hai guadagnato denaro)",
        income: () =>
          "Reddito ricevuto da attività crittografiche, solitamente da ricompense di staking",
        losses: () =>
          "Le perdite subite dalle operazioni di trading. (Operazioni su cui hai perso denaro)",
        txCount: () => "Il numero di transazioni sui tuoi account",
      },
      tradingGains: () => "Guadagni da trading",
      tradingLosses: () => "Perdite di trading",
      transactions: (d) => d.count + " transazioni",
    },
    taxableStaking: {
      content: {
        added: () => "Il 98,34 deve poi essere aggiunto al tuo",
        assessableIncome: () => "reddito imponibile",
        at: () => "A",
        breakdown: () => "Analizzandolo nel dettaglio:",
        rewards: () => "Il contratto ti premia con 0,001 ETH ogni mese",
        value: () =>
          "Il valore della ricompensa è determinato dal valore di mercato del bene al momento della ricezione",
        year: () => "per l'anno",
        youStake: () => "Metti in gioco 1 Ethereum in un contratto di staking",
      },
      footerBack: () => "Indietro",
      footerNext: () => "Prossimo",
      headings: {
        assessableIncome: () => "Reddito imponibile",
        stakingContract: () => "Contratto di picchettamento",
        stakingRewards: () => "Ricompense per lo staking",
        yourSalary: () => "Il tuo stipendio",
      },
      subtitle: () => "Guida fiscale sulle criptovalute per:",
      title: () => "Le ricompense dello staking sono entrate",
    },
    taxableTrades: {
      content: {
        andYour: () => "E il tuo",
        breakdown: () => "Analizzandolo nel dettaglio:",
        capitalGain: () => "guadagno in conto capitale",
        costBase: () => "base di costo",
        for: () =>
          "per i tuoi 5 Ethereum che hai acquisito ora valgono 20.000 $",
        is15k: () => "è $ 15.000",
        is5k: () => "è $ 5.000",
        marketValue: () => "valore di mercato",
        the: () => "IL",
        youTrade: () => "Scambi 1 Bitcoin per 5 Ethereum",
        your: () => "Tuo",
        yourBitcoin: () => "per il tuo 1 Bitcoin è $ 20.000 e il",
      },
      footerBack: () => "Indietro",
      footerNext: () => "Prossimo",
      headings: {
        capitalGain: () => "Plusvalenza",
        costBase: () => "Base di costo",
        marketValue: () => "Valore di mercato",
      },
      subtitle: () => "Guida fiscale sulle criptovalute per:",
      title: () => "Le transazioni tra criptovalute sono tassabili",
    },
    tooltip: {
      accounts: () =>
        "Portafogli e exchange utilizzati per effettuare transazioni in criptovaluta.",
      assessableIncome: () =>
        "Reddito guadagnato che è imponibile. Ad esempio il tuo stipendio.",
      capitalGain: () =>
        "Importo realizzato dopo la vendita di un bene e la deduzione della base di costo. ",
      costBase: () =>
        "Il prezzo originale pagato per l'asset, più le commissioni di transazione",
      marketValue: () => "Il valore in tempo reale dell'asset.",
    },
    tradingTypes: {
      footerBack: () => "Indietro",
      footerNext: () => "Prossimo",
      footerSkip: () => "Saltare",
      subtitle: () =>
        "Personalizza la tua esperienza in base al tipo di trader di criptovalute che sei:",
      title: () => "Che tipo di attività di trading hai svolto?",
      types: {
        airdrop: () => "Lanci aerei",
        liquidityPool: () => "Pool di liquidità",
        nft: () => "NFT",
        other: () => "Altro",
        staking: () => "Picchettamento",
        trading: () => "Commercio",
      },
    },
    userValueSurvey: {
      options: {
        accurateReport: () => "Ottenere un report accurato",
        auditProtection: () => "Protezione da un audit",
        minimiseTax: () => "Ridurre al minimo la mia bolletta fiscale",
        other: () => "Altro",
        simplifyTax: () => "Semplificare le tasse sulle criptovalute",
        timeSave: () =>
          "Risparmio di tempo nella preparazione delle dichiarazioni dei redditi",
      },
      title: () =>
        "Quale risultato è più importante per te quando utilizzi una soluzione fiscale basata sulle criptovalute?",
    },
    viewAssociatedTransaction: (d) =>
      "Visualizza " +
      plural(d.txCount, 0, it, {
        0: " transazioni ",
        one: " transazione ( " + number("it", d.txCount, 0) + " ) ",
        other: " transazioni ( " + number("it", d.txCount, 0) + " ) ",
      }),
    wallets: {
      address: () => "Indirizzo",
      blockchain: () => "Catena di blocchi",
      continue: () => "Continuare",
      importTransactions: () => "Importare transazioni",
      subtitle: () => "Importeremo le transazioni sui seguenti portafogli",
      title: () => "Ciao utente MetaMask",
    },
  },
  organisation: {
    wentWrongGettingDashboard: () =>
      "Si è verificato un problema durante l'acquisizione della dashboard. Riprova più tardi.",
  },
  outTxCategoryOptionsTitle: () => "Transazioni in uscita",
  pageFailed: () => "Qualcosa è andato storto. Prova a ricaricare la pagina.",
  pageTitle: {
    AccountingIntegrations: () => "Integrazioni contabili",
    Alpha: () => "Caratteristiche Alpha",
    BalanceLedger: () => "Bilancio contabile",
    Billing: () => "Fatturazione",
    Clients: () => "Clienti",
    ClientsAdd: () => "Aggiungi cliente",
    Close: () => "Vicino",
    ConnectProvider: () => "Connetti il fornitore",
    Contacts: () => "Contatti",
    ContactsIdentified: () => "Contatti identificati",
    ContactsUnidentified: () => "Contatti non identificati",
    Dashboard: () => "Portfolio",
    Developer: () => "Flag delle funzionalità",
    DeveloperEditorIntegration: () => "Vai al codice",
    DeveloperEntities: () => "Entità",
    DeveloperReport: () => "Aggiornamento del report",
    DeveloperSeedData: () => "Dati del seme",
    DeveloperSessionRecording: () => "Registrazioni di sessione",
    DeveloperUserData: () => "Dati utente",
    Forgot: () => "Ha dimenticato la password",
    ImportCustom: () => "Account personalizzato",
    ImportNew: () => "Aggiungi account",
    ImportSearch: () => "Cerca account",
    Imports: () => "Conti",
    Invite: () => "Invitare",
    Ledger: () => "Libro mastro",
    Login: () => "Login",
    Onboarding: () => "Inserimento",
    OnboardingAccuracy: () => "Precisione",
    OnboardingAreYouSure: () => "Sei sicuro",
    OnboardingCompetitorSurvey: () => "Altri software fiscali",
    OnboardingEmbededOfferIntro: () => "Benvenuto",
    OnboardingImportCEXReminder: () => "Promemoria di scambio centralizzato",
    OnboardingImports: () => "Conti",
    OnboardingImportsCalculating: () => "Calcolo dell'importazione",
    OnboardingImportsCustom: () => "Account personalizzati",
    OnboardingImportsNew: () => "Nuovo account",
    OnboardingImportsOauth: () => "Account OAuth",
    OnboardingImportsSearch: () => "Cerca account",
    OnboardingOnYourWay: () => "Sulla tua strada",
    OnboardingPayment: () => "Pagamento",
    OnboardingPaymentStatus: () => "Stato del pagamento",
    OnboardingPaymentSuccess: () => "Pagamento riuscito",
    OnboardingPlatformTypes: () => "Tipi di piattaforma",
    OnboardingProviderPasswordSetSuccess: () => "Password impostata",
    OnboardingProviderSetPassword: () => "Imposta password",
    OnboardingProviderSyncing: () => "Collegamento",
    OnboardingProviderWelcome: () => "Benvenuto",
    OnboardingReconUncategorisedTransactions: () =>
      "Transazioni non categorizzate",
    OnboardingSelectCountry: () => "Seleziona il paese",
    OnboardingSelectTheme: () => "Seleziona il tema colore",
    OnboardingSelectType: () => "Seleziona il tipo di account",
    OnboardingTaxSettings: () => "Impostazioni fiscali ATO",
    OnboardingTaxableStaking: () => "Staking tassabile",
    OnboardingTaxableTrades: () => "Attività imponibili",
    OnboardingTradingTypes: () => "Tipi di trading",
    OnboardingUserValueSurvey: () => "Importante per te",
    OnboardingWallets: () => "Portafogli",
    OptInSupportAccess: () => "Accesso al supporto opt-in",
    PartnerBenefit: () => "Beneficio partner",
    Payment: () => "Pagamento",
    PaymentPending: () => "Pagamento in sospeso",
    PaymentStatus: () => "Stato del pagamento",
    PaymentSuccess: () => "Pagamento riuscito",
    Plans: () => "Piani",
    ReOnboarding: () => "Re-onboarding",
    ReOnboardingAccuracy: () => "Precisione",
    ReOnboardingTaxableStaking: () => "Staking tassabile",
    ReOnboardingTaxableTrades: () => "Attività imponibili",
    ReOnboardingWelcomeBack: () => "Bentornato",
    Reconciliation: () => "Riconciliazione",
    ReconciliationMissingBlockchain: () => "Blockchain mancante",
    ReconciliationMissingPriceIssues: () => "Problemi di prezzo mancanti",
    ReconciliationNegativeBalanceIssues: () => "Problemi di saldo negativo",
    ReconciliationUncategorisedTransaction: () =>
      "Transazioni non categorizzate",
    ReconciliationUnmatchedTransfer: () => "Trasferimenti senza pari",
    ReferAFriend: () => "Segnala un amico",
    Report: () => "Rapporto",
    Reset: () => "Reimposta password",
    RestoreUserData: () => "Ripristinare i dati",
    Rules: () => "Regole",
    Signup: () => "Iscrizione",
    SignupAccountant: () => "Registrazione contabile",
    TokenLogin: () => "Accesso tramite token",
    Transactions: () => "Transazioni",
    TransactionsAdd: () => "Aggiungi transazione",
    TransactionsMissingPrice: () => "Transazioni a prezzo mancante",
    TransactionsNegativeBalance: () => "Transazioni con saldo negativo",
    TransactionsSearch: () => "Cerca transazioni",
    TransactionsUncategorised: () => "Transazioni non categorizzate",
    Unlock: () => "Sbloccare l'account",
    Wallets: () => "Portafogli",
  },
  partnerEmployeeBenefits: {
    discount: {
      cta: () => "Richiedi il tuo sconto",
      description: () => "100% di sconto su tutti i piani",
      title: (d) =>
        "In qualità di dipendente di " +
        d.partner +
        ", ottieni il 100% di sconto sul tuo abbonamento CTC per un anno",
    },
    discountApplied: {
      cta: () => "Visualizza i piani",
      description: () =>
        "Abbiamo applicato automaticamente questo coupon al tuo account. Procedi con il checkout per riscattare questo sconto.",
      title: () => "Sconto del 100% applicato su tutti i piani",
    },
    verifyEmail: {
      confirmEmail: () => "Controlla la tua email",
      cta: () => "Invia",
      error400: () =>
        "Sembra che questa email abbia già richiesto un codice sconto",
      error403: (d) => "Inserisci un'email di lavoro " + d.partner + " valida",
      error500: () => "Qualcosa è andato storto, contatta il supporto tecnico",
      helperText: (d) =>
        "Devi avere un'email " + d.partner + " valida per richiedere",
      placeholder: (d) => "Inserisci email di lavoro " + d.partner,
      reEnterEmail: () => "Reinserisci email",
      success: () =>
        "Ti abbiamo inviato un'email con un link che applicherà automaticamente il tuo codice coupon",
    },
  },
  partnership: {
    officialPartner: () => "PARTNER UFFICIALI",
  },
  password: {
    case: () => "La tua password deve contenere almeno 1 lettera maiuscola",
    long: () => "La tua password deve contenere meno di 64 caratteri",
    number: () =>
      "La password deve contenere almeno 1 numero o carattere speciale.",
    short: () => "La tua password è debole. Deve contenere almeno 8 caratteri.",
  },
  payment: {
    accountantPackage: {
      clientDefault: () => "Iniziare",
      color: () => "blue",
      cta: () => "Iniziare",
      description: () =>
        "Soluzione crittografica completa per contabili e contabili.",
      details: {
        0: {
          icon: () => "CheckCircleIcon",
          text: () => "Copre DeFi, DEX, derivati e staking",
        },
      },
      points: {
        0: () => "Scambi illimitati",
        1: () => "Accesso a tutti i report",
        2: () => "Copre gli anni fiscali 2013-2023",
      },
      pointsByCountry: {
        AU: {
          0: () => "Exchange illimitati",
          1: () => "Accesso a tutti i report",
          2: () => "Copre gli anni fiscali dal 2013 al 2023",
          3: () => "Supporto basato in Australia",
        },
      },
      subtitle: () =>
        "Per contabili e contabili che gestiscono i dati dei clienti",
      title: () => "Contabile",
    },
    backToTop: () => "Torna all'inizio",
    base: {
      allTicks: {
        0: () => "Tutti i piani hanno una garanzia di rimborso di 30 giorni.",
        1: () =>
          "Supporto tramite chat live. Contattaci se hai bisogno di aiuto!",
        2: () =>
          "La quota annuale copre tutti gli anni finanziari a partire dal 2013.",
        3: () =>
          "Nessun costo aggiuntivo per la dichiarazione dei redditi degli anni fiscali precedenti.",
      },
      cancel: () => "Cancellare",
      cancelAnytime: () => "Annulla in qualsiasi momento",
      compare: {
        subtitle: () => "Scegli il piano più adatto alle tue esigenze.",
        title: () => "Confronto delle funzionalità",
      },
      copy: {
        actions: {
          buy: {
            text: () => "Acquistare",
          },
          current: () => "Piano attuale",
          getStarted: {
            text: () => "Iniziare",
          },
          signUp: {
            text: () => "Acquista ora",
          },
          upgrade: {
            text: () => "Aggiorna a",
          },
          upgradeFor: (d) => "Aggiorna per " + d.upgradePrice,
        },
        annually: () => "Annualmente",
        costCurrentPlanDeducted: () => "Costo del piano attuale detratto",
        discountAppliedFirstYear: (d) => d.discount + " % di sconto applicato",
        features: {
          advancedTaxReports: () => "Rapporti fiscali avanzati",
          auditReport: () => "Rapporto di revisione contabile",
          integrations: () => "Integrazioni illimitate",
          ltfo: () => "Algoritmo di minimizzazione delle tasse",
          onchain: () => "Transazioni automatizzate on-chain",
          portfolioTracking: () => "Monitoraggio del portafoglio",
          report: () =>
            "Dichiarazioni fiscali illimitate (TUTTI gli anni fiscali)",
          reportAuthority: (d) =>
            "Rapporti fiscali illimitati " +
            d.authority +
            " (TUTTI gli anni fiscali)",
          smartContract: () => "Interazioni con contratti intelligenti",
          support: () => "Supporto",
          tlh: () => "Strumento di raccolta delle perdite fiscali",
          tsr: () => "Rapporto sulle azioni di trading",
          txLimit: (d) => d.txLimit + " transazioni",
          xero: () => "Integrazione Xero",
        },
        featuresReport: {
          reportAllFinancialYears: () => "Tutti gli anni finanziari",
          reportUnlimited: () => "Report illimitati",
        },
        featuresTableCell: {
          experimentSupportEmailChat: () => "E-mail + chat",
          experimentSupportPriority: () => "Priorità + Assistenza esperta",
          integrations: () => "Illimitato",
          supportEmailChat: () => "Supporto via e-mail + chat",
          supportPriority: () => "Supporto prioritario",
        },
        featuresTableHeader: {
          integrations: () => "Integrazioni",
          support: () => "Supporto",
          txLimit: () => "Transazioni",
        },
        featuresTooltip: {
          advancedTaxReports: () =>
            "Rendiconto spese, resoconto inventario, resoconto trading derivati, resoconto regalo in uscita e resoconto beni smarriti/rubati",
          auditReport: () =>
            "Un report che fornisce un registro dei dati importati e di tutte le modifiche apportate, incluse le impostazioni fiscali applicate.",
          ltfo: () =>
            "Utilizzando il nostro algoritmo più efficace dal punto di vista fiscale potresti risparmiare potenziali tasse",
          tlh: () =>
            "Uno strumento per identificare le attività in perdita al fine di venderle prima della fine dell'anno fiscale per ridurre il guadagno complessivo in conto capitale",
        },
        firstYear: (d) =>
          "per il primo anno, poi " + d.price + " fatturato annualmente",
        freeTransactions: () => "+ 10.000 transazioni Coinbase gratuite",
        highlightFeatures: {
          "advanced-inventory": () => "Algoritmo di minimizzazione delle tasse",
          "advanced-reports": () => "Rapporti fiscali avanzati",
          allOtherFeatures: () => "+ tutto incluso nel piano Trader",
          comingSoon: () => "Prossimamente",
          contacts: () => "Rubrica",
          erp: () => "Integrazioni contabili",
          "lock-tax-year": () => "Bloccare gli anni fiscali",
          "multi-tax-years": () =>
            "Dichiarazioni fiscali illimitate per tutti gli anni fiscali",
          prioritySupport: () => "Supporto prioritario",
          rules: () => "Motore di regole migliorato",
          sidebar: () => "barra laterale",
          "smart-contract-transactions": () =>
            "Transazioni di contratto intelligenti",
          "tax-loss-harvesting": () =>
            "Strumento di raccolta delle perdite fiscali",
          tips: () => "Suggerimenti",
          "trading-stock-report": () => "Rapporto sulle azioni di trading",
          treasury: () => "Dashboard del Tesoro",
        },
        planCommonDetails: () =>
          "Il piano unico copre tutti gli anni fiscali dal 2013 al 2022",
        plans: {
          accountant: {
            clientDataDescription: () =>
              "Supportiamo direttamente centinaia di exchange, blockchain e servizi. Basta aggiungere chiavi API e indirizzi wallet.",
            clientDataTitle: () =>
              "Ottieni facilmente i dati dei clienti tramite scambio diretto e integrazioni blockchain",
            clientDefault: () => "Iniziare",
            color: () => "blue",
            description: () =>
              "Soluzione crittografica completa per contabili e contabili.",
            descriptionFooter: () =>
              "Inizia gratuitamente e inizi a pagare solo quando importi i dati dei tuoi clienti.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Copre DeFi, DEX, derivati e staking",
              },
            },
            getStarted: () => "Inizia gratis",
            manageClientsDescription: () =>
              "Il nostro portale contabile ti consente di invitare e gestire i clienti da un'unica dashboard facile da usare.",
            manageClientsTitle: () =>
              "Gestisci tutti i tuoi clienti in un unico posto",
            points: {
              0: () =>
                "Invita e gestisci i clienti dalla dashboard di gestione clienti",
              1: () => "Supporta fino a 100.000 transazioni per cliente",
              2: () => "Copre DeFi, DEX, derivati e staking",
            },
            subtitle: () =>
              "Gestisci facilmente la dichiarazione dei redditi delle criptovalute dei tuoi clienti con la nostra suite di contabilità professionale.",
            title: () => "Suite contabile",
            toolsDemo: () => "Strumenti di contabilità e demo",
          },
          advanced: {
            color: () => "purple",
            cta: () => "Acquista ora",
            description: () =>
              "Sblocca un algoritmo proprietario per massimizzare gli sconti a lungo termine e le perdite a breve termine",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per investitori attivi che hanno transazioni complesse.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Copre DeFi, DEX, derivati, staking e transazioni " +
                  d.txLimit +
                  " .",
              },
            },
            points: {
              0: () => "10.000 transazioni",
              1: () => "Scambi illimitati",
              2: () => "Importazioni di portafogli",
              3: () => "Staking, Mining, Airdrop, DeFi",
              4: () => "Operazioni Long/Short e CFD",
              5: () => "Margine e Futures Trades",
              6: () => "Dichiarazione dei redditi",
              7: () => "Relazione fiscale sulle plusvalenze",
              8: () => "Supporto tramite chat online",
              9: () => "Copre gli anni fiscali 2013-2023",
            },
            subtitle: () =>
              "Per gli investitori in criptovalute che sono entrati nel mondo della finanza decentralizzata",
            title: () => "Investitore",
          },
          business: {
            color: () => "blue",
            cta: () => "Iniziare",
            description: () =>
              "Soluzione fiscale crittografica completa per contabili e ragionieri.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Copre DeFi, DEX, derivati e staking",
              },
            },
            points: {
              0: () => "Accesso a tutti i report",
              1: () => "Copre gli anni fiscali 2013-2023",
            },
            retailPlansPage: {
              cta: () => "Acquista un'attività",
              description: () =>
                "Strumento di contabilità crittografica accurato per le aziende",
              points: {
                0: {
                  description: () => "Si integra con Xero e Quickbooks",
                  feature: () => "Integrazioni contabili",
                },
                1: {
                  description: () =>
                    "Tieni traccia e gestisci tutti gli indirizzi e le entità in un unico posto ",
                  feature: () => "Rubrica",
                },
                2: {
                  description: () =>
                    "Crea e gestisci regole automatizzate per la categorizzazione e la mappatura delle transazioni durante l'importazione",
                  feature: () => "Motore di regole",
                },
                3: {
                  feature: () => "+ Tutte le funzionalità del piano trader",
                },
              },
              subtitle: () => "Automatizza la tua contabilità crittografica",
              title: () => "Piano aziendale",
            },
            subtitle: () => "Per le aziende web3",
            title: () => "Attività commerciale",
          },
          businessPackage: {
            color: () => "blue",
            cta: () => "Iniziare",
            description: () =>
              "Soluzione crittografica completa per contabili e contabili.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Copre DeFi, DEX, derivati e staking",
              },
            },
            points: {
              0: () => "Scambi illimitati",
              1: () => "Accesso a tutti i report",
              2: () => "Copre gli anni fiscali 2013-2023",
            },
            subtitle: () =>
              "Per contabili e contabili che gestiscono i dati dei clienti",
            title: () => "Attività commerciale",
          },
          business_pro: {
            caption: () =>
              "Transazioni superiori a 10.000? Contattaci per un prezzo leader di mercato.",
            color: () => "blue",
            cta: () => "Iniziare",
            description: () =>
              "Soluzione crittografica completa per contabili e contabili.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Copre DeFi, DEX, derivati e staking",
              },
            },
            points: {
              0: () => "Scambi illimitati",
              1: () => "Accesso a tutti i report",
              2: () => "Copre gli anni fiscali 2013-2023",
            },
            subtitle: () => "Per aziende più grandi o più consolidate",
            title: () => "Professionista aziendale",
          },
          business_starter: {
            color: () => "blue",
            cta: () => "Iniziare",
            description: () =>
              "Soluzione crittografica completa per contabili e contabili.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Copre DeFi, DEX, derivati e staking",
              },
            },
            points: {
              0: () => "Scambi illimitati",
              1: () => "Accesso a tutti i report",
              2: () => "Copre gli anni fiscali 2013-2023",
            },
            subtitle: () => "Per startup e piccole aziende",
            title: () => "Avviamento aziendale",
          },
          business_trial: {
            color: () => "blue",
            cta: () => "Iniziare",
            description: () =>
              "Soluzione crittografica completa per contabili e contabili.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Copre DeFi, DEX, derivati e staking",
              },
            },
            points: {
              0: () => "Scambi illimitati",
              1: () => "Accesso a tutti i report",
              2: () => "Copre gli anni fiscali 2013-2023",
            },
            subtitle: () => "Per startup e piccole aziende",
            title: () => "Piano aziendale",
          },
          client: {
            collaborator: {
              color: () => "blue",
              cta: () => "Iniziare",
              description: () =>
                "Soluzione crittografica completa per contabili e contabili.",
              details: {
                0: {
                  icon: () => "CheckCircleIcon",
                  text: () => "Copre DeFi, DEX, derivati e staking",
                },
              },
              points: {
                0: () => "Scambi illimitati",
                1: () => "Accesso a tutti i report",
                2: () => "Copre gli anni fiscali 2013-2023",
              },
              subtitle: () =>
                "Per contabili e contabili che gestiscono i dati dei clienti",
              title: () => "Contabile",
            },
            color: () => "blue",
            cta: () => "Iniziare",
            description: () =>
              "Soluzione crittografica completa per contabili e contabili.",
            details: {
              0: {
                icon: () => "CheckCircleIcon",
                text: () => "Copre DeFi, DEX, derivati e staking",
              },
            },
            enterprise: {
              color: () => "blue",
              cta: () => "Iniziare",
              description: () =>
                "Soluzione crittografica completa per contabili e contabili.",
              details: {
                0: {
                  icon: () => "CheckCircleIcon",
                  text: () => "Copre DeFi, DEX, derivati e staking",
                },
              },
              points: {
                0: () => "Scambi illimitati",
                1: () => "Accesso a tutti i report",
                2: () => "Copre gli anni fiscali 2013-2023",
              },
              subtitle: () =>
                "Per contabili e contabili che gestiscono i dati dei clienti",
              title: () => "Contabile",
            },
            points: {
              0: () => "Scambi illimitati",
              1: () => "Accesso a tutti i report",
              2: () => "Copre gli anni fiscali 2013-2023",
            },
            subtitle: () =>
              "Per contabili e contabili che gestiscono i dati dei clienti",
            title: () => "Contabile",
          },
          collaborator: {
            subtitle: () => "collaboratore",
            title: () => "Collaboratore",
          },
          enterprise: {
            subtitle: () => "impresa",
            title: () => "Impresa",
          },
          expert: {
            color: () => "blue",
            cta: () => "Acquista ora",
            description: () => "Soluzione ad alta capacità per trader attivi\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per i day trader con un elevato volume di transazioni.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Supporta tutti i piani degli investitori e fino a " +
                  d.txLimit +
                  " transazioni.",
              },
            },
            points: {
              0: () => "100.000 transazioni",
              1: () => "Tutte le funzionalità per gli investitori",
              2: () => "Confronta le strategie fiscali",
            },
            subtitle: () =>
              "Per i trader di criptovalute che vogliono dichiarare la propria attività di trading come un'attività commerciale",
            title: () => "Commerciante",
          },
          free: {
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per gli utenti che si dilettano nel settore delle criptovalute.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Supporta transazioni " +
                  d.txLimit +
                  " in cui le risorse sono detenute su exchange centralizzati.",
              },
            },
            subtitle: () => "gratuito",
            title: () => "Gratuito",
          },
          investorUs: {
            color: () => "grey",
            cta: () => "Acquista ora",
            description: () => "Per investitori esperti in criptovalute",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per gli appassionati di criptovalute che utilizzano exchange centralizzati.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Supporta l'archiviazione di asset nei portafogli e fino a " +
                  d.txLimit +
                  " transazioni.",
              },
            },
            points: {
              0: () => "1.000 transazioni",
              1: () => "Integrazioni illimitate",
              2: () =>
                "Dichiarazioni fiscali illimitate per tutti gli anni fiscali",
              3: () => "Integrazione TurboTax",
              4: () => "Dashboard del portafoglio",
              5: () => "Invita il tuo commercialista",
              6: () => "Calcoli fiscali DeFi automatizzati",
              7: () => "Minimizzazione fiscale",
            },
            subtitle: () => "Per investitori esperti in criptovalute",
            title: () => "Investitore",
          },
          investorUsPlus: {
            color: () => "grey",
            cta: () => "Acquista ora",
            description: () => "Per investitori esperti in criptovalute",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per gli appassionati di criptovalute che utilizzano exchange centralizzati.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Supporta l'archiviazione di asset nei portafogli e fino a " +
                  d.txLimit +
                  " transazioni.",
              },
            },
            points: {
              0: () => "3.000 transazioni",
              1: () => "Integrazioni illimitate",
              2: () =>
                "Dichiarazioni fiscali illimitate per tutti gli anni fiscali",
              3: () => "Integrazione TurboTax",
              4: () => "Dashboard del portafoglio",
              5: () => "Invita il tuo commercialista",
              6: () => "Calcoli fiscali DeFi automatizzati",
              7: () => "Minimizzazione fiscale",
            },
            subtitle: () => "Per investitori esperti in criptovalute",
            title: () => "Investitore +",
          },
          pro: {
            subtitle: () =>
              "Per i trader di criptovalute che vogliono dichiarare la propria attività di trading come un'attività commerciale",
            title: () => "Professionista",
          },
          rookie: {
            color: () => "grey",
            cta: () => "Acquista ora",
            description: () =>
              "Soluzione conveniente per gli amanti occasionali delle criptovalute per gestire senza sforzo le tasse sulle criptovalute",
            points: {
              0: () => "200 Transazioni",
              1: () => "Scambi illimitati",
              2: () => "Dichiarazione dei redditi",
              3: () => "Relazione fiscale sulle plusvalenze",
              4: () => "Copre gli anni fiscali 2013-2023",
              5: () => "Supporto tramite chat online",
            },
            subtitle: () =>
              "Per gli utenti di criptovalute che hanno iniziato a dilettarsi nel mondo del trading",
            title: () => "Novellino",
          },
          rookie_100: {
            color: () => "grey",
            cta: () => "Acquista ora",
            description: () =>
              "Soluzione conveniente per gli amanti occasionali delle criptovalute per gestire senza sforzo le tasse sulle criptovalute",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per gli utenti che si dilettano nel settore delle criptovalute.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Supporta transazioni " +
                  d.txLimit +
                  " in cui le risorse sono detenute su exchange centralizzati.",
              },
            },
            points: {
              0: () => "100 Transazioni",
              1: () => "Lanci aerei",
              2: () => "Scambi illimitati",
              3: () => "Dichiarazione dei redditi",
              4: () => "Relazione fiscale sulle plusvalenze",
              5: () => "Copre gli anni fiscali 2013-2023",
              6: () => "Supporto tramite chat online",
            },
            subtitle: () =>
              "Per gli utenti di criptovalute che hanno iniziato a dilettarsi nel mondo del trading",
            title: () => "Novellino",
          },
          standard: {
            color: () => "grey",
            cta: () => "Acquista ora",
            description: () =>
              "Supporto on-chain completo per DeFi e NFT, con integrazioni dirette di contratti intelligenti",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per gli appassionati di criptovalute che utilizzano exchange centralizzati.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Supporta l'archiviazione di asset nei portafogli e fino a " +
                  d.txLimit +
                  " transazioni.",
              },
            },
            points: {
              0: () => "2.500 transazioni",
              1: () => "Scambi illimitati",
              2: () => "Importazioni di portafogli",
              3: () => "Lanci aerei",
              4: () => "Dichiarazione dei redditi",
              5: () => "Relazione fiscale sulle plusvalenze",
              6: () => "Copre gli anni fiscali 2013-2023",
              7: () => "Supporto tramite chat online",
            },
            subtitle: () =>
              "Per gli appassionati di criptovalute che utilizzano exchange centralizzati e tendono a detenere",
            title: () => "Hobbista",
          },
          standard_100: {
            color: () => "grey",
            cta: () => "Acquista ora",
            description: () =>
              "Supporto on-chain completo per DeFi e NFT, con integrazioni dirette di contratti intelligenti",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per gli appassionati di criptovalute che utilizzano exchange centralizzati.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Supporta l'archiviazione di asset nei portafogli e fino a " +
                  d.txLimit +
                  " transazioni.",
              },
            },
            points: {
              0: () => "1.000 transazioni",
              1: () => "Scambi illimitati",
              2: () => "Importazioni di portafogli",
              3: () => "Lanci aerei",
              4: () => "Dichiarazione dei redditi",
              5: () => "Relazione fiscale sulle plusvalenze",
              6: () => "Copre gli anni fiscali 2013-2023",
              7: () => "Supporto tramite chat online",
            },
            subtitle: () =>
              "Per gli appassionati di criptovalute che utilizzano exchange centralizzati e tendono a detenere",
            title: () => "Hobbista",
          },
          traderUs: {
            color: () => "blue",
            cta: () => "Acquista ora",
            description: () => "Per i fanatici esperti delle criptovalute\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per i day trader con un elevato volume di transazioni.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Supporta tutti i piani degli investitori e fino a " +
                  d.txLimit +
                  " transazioni.",
              },
            },
            points: {
              0: () => "10.000 transazioni",
              1: () => "Tutte le funzionalità di Investor+",
              2: () => "Segnalazione fiscale avanzata",
              3: () => "Registro di controllo",
            },
            subtitle: () =>
              "Per i trader di criptovalute che vogliono dichiarare la propria attività di trading come un'attività commerciale",
            title: () => "Commerciante",
          },
          traderUsPlus: {
            color: () => "blue",
            cta: () => "Acquista ora",
            description: () => "Per i fanatici esperti delle criptovalute\n",
            details: {
              0: {
                icon: () => "PersonIcon",
                text: () =>
                  "Per i day trader con un elevato volume di transazioni.",
              },
              1: {
                icon: () => "CheckCircleIcon",
                text: (d) =>
                  "Supporta tutti i piani degli investitori e fino a " +
                  d.txLimit +
                  " transazioni.",
              },
            },
            points: {
              0: () => "100.000 transazioni",
              1: () => "Tutte le funzionalità di Investor+",
              2: () => "Segnalazione fiscale avanzata",
              3: () => "Registro di controllo",
            },
            subtitle: () =>
              "Per i trader di criptovalute che vogliono dichiarare la propria attività di trading come un'attività commerciale",
            title: () => "Commerciante +",
          },
        },
        plus: () => "+",
        subtitle: () =>
          "Con CTC paghi le tasse più basse e ricevi i report più accurati.",
        subtitleAccountant: () =>
          "Gestisci facilmente le dichiarazioni dei redditi in criptovaluta dei tuoi clienti con la nostra suite di contabilità professionale",
        tabTitle: () =>
          "Calcolatrice delle imposte sulle criptovalute per le aziende",
        title: () => "Scegli il tuo piano",
        titleAccountants: () => "Suite contabile",
        usExperimentPlanDescription: {
          investorUs: () => "Per investitori esperti in criptovalute ",
          investorUsPlus: () => "Per investitori esperti in criptovalute ",
          rookie_100: () => "Per chi ha appena iniziato",
          traderUs: () => "Per i fanatici esperti delle criptovalute",
          traderUsPlus: () => "Per i fanatici esperti delle criptovalute",
        },
        usExperimentPlanTitle: {
          investorUs: () => "Investitore",
          investorUsPlus: () => "Investitore +",
          rookie_100: () => "Novellino",
          traderUs: () => "Commerciante",
          traderUsPlus: () => "Commerciante +",
        },
        usExperimentPlanTxCount: {
          investorUs: {
            hasPlusTxs: () => "3.000",
            txCount: () => "1.000",
          },
          rookie_100: {
            hasPlusTxs: () => "0",
            txCount: () => "100",
          },
          traderUs: {
            hasPlusTxs: () => "100.000+",
            txCount: () => "10.000",
          },
        },
        usUserExperimentFeatures: {
          accountantInvite: () => "Invita il tuo commercialista",
          advancedTaxReports: () => "Segnalazione fiscale avanzata",
          auditLog: () => "Registro di controllo",
          dashboard: () => "Pannello di controllo del portafoglio",
          defiTaxAutomation: () => "Calcoli fiscali DeFi automatizzati",
          integrations: () => "Integrazioni illimitate",
          support: () => "Supporto",
          taxMinimization: () => "Minimizzazione fiscale",
          taxYearReports: () =>
            "Dichiarazioni fiscali illimitate per tutti gli anni fiscali",
          turboTaxIntegration: () => "Integrazione TurboTax",
        },
        usUserExperimentFeaturesTooltip: {
          accountantInvite: () =>
            "Collabora con il tuo professionista fiscale. I contabili hanno accesso a CTC senza costi aggiuntivi.",
          advancedTaxReports: () =>
            "Reporting accurato per tutti gli scenari fiscali, tra cui spese, inventario, negoziazione di derivati, donazioni in uscita e segnalazione di beni smarriti/rubati.",
          auditLog: () =>
            "Riduci il rischio di audit con un registro completo dei dati importati e delle modifiche apportate.",
          dashboard: () =>
            "Acquisisci sicurezza nei numeri grazie a una visione intuitiva dell'intero portafoglio.",
          defiTaxAutomation: () =>
            "Ci occupiamo noi del lavoro duro, così non devi farlo tu. Valutazione automatica delle implicazioni fiscali per tutte le tue attività on-chain e contratti intelligenti.",
          integrations: () =>
            "Importa tutti i tuoi portafogli e le tue fonti in pochi minuti.",
          support: () =>
            "Non farlo da solo. Il nostro team di esperti di conformità alle criptovalute è felice di darti una mano.",
          taxMinimization: () =>
            "Non spendere troppo in tasse, grazie all'algoritmo proprietario di CTC per la minimizzazione delle tasse, alla possibilità di scegliere il metodo di inventario più efficiente e alla raccolta delle perdite fiscali.",
          taxYearReports: () =>
            "Nessun costo aggiuntivo per i report degli anni precedenti. *Ehm ehm* a differenza dei nostri concorrenti.",
          turboTaxIntegration: () =>
            "CTC si collega sia alla versione browser che a quella desktop di TurboTax, così puoi inviare le tue tasse con facilità.",
        },
        validFirstYear: () => "Valido per il tuo primo anno di abbonamento",
        wasPrice: (d) => "era " + d.price,
      },
      currency: () => "Dollaro statunitense",
      currencySymbol: () => "$",
      email: () => "E-mail",
      guarantee: () => "Garanzia soddisfatti o rimborsati entro 30 giorni",
      loading: () => "Elaborazione in corso...",
      payNow: () => "Paga ora",
      period: () => "anno",
      plan: () => "Piano",
      planDisabled: () =>
        "Il numero di transazioni di questo cliente ha superato il limite previsto per questo piano.",
      title: () => "Piani tariffari",
    },
    billedYearly: () => "Fatturato annualmente",
    billing: {
      chips: {
        cancelledChip: (d) => "Annullato: scade il " + d.date,
        renewChip: (d) => "Rinnova il: " + d.date,
        trialEndsChip: (d) => "La prova termina: " + d.date,
      },
      cta: {
        cancelPlan: () => "Annulla piano",
        reSubscribe: () => "Ri-iscriviti",
      },
      footer: {
        client: {
          cancelledPlanFooter: (d) =>
            "Questo piano non verrà rinnovato il " + d.date,
          estimatedPaymentFooter: (d) =>
            "Il tuo prossimo pagamento stimato per questo cliente sarà " +
            d.amount +
            " il " +
            d.date,
          estimatedPaymentFooterWithTrial: (d) =>
            "Il tuo prossimo pagamento stimato per questo cliente sarà " +
            d.amount +
            " il " +
            d.date +
            " , dopo la fine della prova",
        },
      },
      lineItems: {
        billingCycle: () => "Ciclo di fatturazione:",
        couponWithAmount: (d) => d.couponId + " ( " + d.amountOff + " %)",
        planPrice: () => "Prezzo del piano:",
        totalDue: () => "Totale dovuto:",
        totalDueDate: (d) => "Totale dovuto il " + d.date + " :",
      },
      payment: {
        activeCard: () => "Carta attiva:",
        changePayment: () => "Cambia metodo di pagamento",
        discount: (d) => d.amount + " % di sconto",
        end: () =>
          " annualmente finché non annulli l'abbonamento. Puoi annullare in qualsiasi momento.",
        endWithDate: (d) =>
          " il " +
          d.date +
          " e poi annualmente in quella data fino all'annullamento dell'abbonamento; puoi annullare in qualsiasi momento.",
        start: () => "Ti verrà addebitato un costo ",
      },
    },
    cancelled: {
      button: () => "Annullato",
      tooltip: (d) =>
        "Il piano " +
        d.plan +
        " è stato annullato. Rimarrà attivo fino alla fine del periodo di fatturazione corrente.",
    },
    coupon: {
      unknownCoupon: () => "Offerta esclusiva",
    },
    currentPlan: () => "Il piano attuale del cliente",
    disclaimer: () =>
      "*Tutti i piani possono essere deducibili dalle tasse se sostenuti come spesa correlata alla gestione delle proprie questioni fiscali.",
    downgradeWarning: () =>
      "Questo piano non è disponibile perché hai già utilizzato le funzionalità di un piano di livello superiore durante questo periodo di fatturazione",
    enterprise: {
      demoCTA: {
        badge: () => "Offerta per il primo cliente",
        button: () => "Pianifica una demo",
        newRelease: {
          badge: () => "Offerta di nuova uscita",
          ctaLink: () => "Fissa un appuntamento con noi",
          ctaText: () => "Se hai domande o desideri una demo",
          subtitle: () =>
            "Inizia con un cliente aziendale gratuitamente, senza limiti o restrizioni. Dopo 30 giorni, ti contatteremo per un check-in.",
          title: () => "30 giorni gratis!",
        },
        subtitle: () =>
          "Lascia che ti aiutiamo a iniziare con il tuo primo cliente aziendale. Pianifica una demo con il nostro team.",
        title: () => "Prova gratuita di 30 giorni per il tuo primo cliente.",
      },
    },
    excludes: () => "Esclude:",
    freeTx: {
      coinbase: (d) => d.freeTxCount + " transazioni Coinbase gratuite",
      coinjar: (d) => d.freeTxCount + " transazioni CoinJar gratuite",
      default: (d) => d.freeTxCount + " transazioni gratuite",
      phantom: (d) => d.freeTxCount + " transazioni Phantom gratuite",
    },
    haveSmartContractTx: () => "Hai transazioni con contratti intelligenti.",
    ignoredTx: (d) =>
      d.ignoredTxCount + " transazioni contrassegnate come spam o ignorate",
    moneyBackGuarantee: () => "Garanzia di rimborso di 30 giorni",
    moneyBackGuaranteeLower: () => "Garanzia di rimborso di 30 giorni",
    mostPopular: () => "I più popolari",
    paymentCycle: {
      annual: () => "Annualmente",
      monthly: () => "Mensile",
    },
    paymentCycleShort: {
      annual: () => "per ogni",
      monthly: () => "per uomo",
    },
    perMonthBilledYearly: () => "Al mese, fatturato annualmente",
    promoBanner: {
      applied: () => "Applicato",
      bitcoinDotComAu: {
        0: () => "Sconto del 30% sui piani a pagamento",
      },
      coinbase: {
        0: () => "10.000 transazioni Coinbase gratuite",
        1: () => "Sconto del 30% sui piani a pagamento",
      },
      coinbaseone: {
        otherPlans: () => "40% di sconto sui piani Hobbyist, Investor e Trader",
        rookie: (d) => d.price + " Piano principiante",
      },
      coinbaseonepremium: {
        0: () => "Sconto del 50% sul livello premium di CTC, Trader",
        1: () => "40% di sconto sui piani Rookie, Hobbyist e Investor",
      },
      coinjar: {
        0: () => "1.000 transazioni CoinJar gratuite",
        1: () => "Sconto del 30% sui piani a pagamento",
      },
      coinstats: () => "del tuo primo anno con CTC",
      independentReserve: {
        0: () => "Sconto del 30% sui piani a pagamento",
      },
      metamask: {
        0: () => "Sconto del 30% sul tuo primo anno",
      },
      partnerOffer: () => "Offerta ufficiale del partner",
      phantom20: {
        0: () => "Sconto del 20% sui piani a pagamento (solo primo anno)",
        1: () => "200 transazioni gratuite con wallet Phantom",
      },
      referAFriend: {
        0: () => "20% di sconto su tutti i piani retail",
      },
      referralOffer: () => "Offerta di referral",
    },
    renewFailed: {
      bannerTitle: () =>
        "Oh no! Non siamo riusciti a elaborare il tuo pagamento.",
      renewError: () =>
        "Si prega di controllare attentamente il metodo di pagamento predefinito e di disporre di fondi sufficienti per coprire la fattura.",
      renewPlan: () => "Rinnova il piano",
      renewSuccess: () => "Rinnovato con successo il tuo abbonamento",
      settingText: () =>
        "Per generare report, rinnova il tuo piano. Aggiorna i tuoi dati di pagamento procedendo alla gestione dei pagamenti.",
    },
    save20: () => "Risparmia il 20%",
    taxDeductible: () => "Deducibile dalle tasse*",
    tooManyTx: () => "Hai troppe transazioni per questo piano",
    totalTxs: () => "Totale transazioni",
    txCount: {
      prefix: () => "Hai",
      suffix: () => "transazioni fatturabili",
    },
    txCountFiltered: (d) =>
      "Hai <s> " +
      d.filtered +
      " </s> <span> " +
      d.count +
      " </span> transazioni",
    viewAccountant: () => "Visualizza il piano contabile",
    viewBusinessPlan: () => "Visualizza il piano aziendale",
    viewIndividualPlan: () => "Visualizza i piani individuali",
    yourOffers: () => "Le tue offerte",
  },
  paymentPortal: {
    cardDetailsLabel: () => "Dettagli della carta*",
    dialogLabel: () => "Pagamento",
    payPrefixText: () => "Paga",
    paymentError: () => "Errore durante l'elaborazione del pagamento",
  },
  paymentStatus: {
    pending: {
      subtitle: () =>
        "Se ci vuole troppo tempo, prova ad aggiornare la pagina. Contatta l'assistenza se il problema persiste.",
      title: () => "Elaborazione del pagamento...",
    },
    success: {
      buttonText: () => "Ottieni il tuo rapporto fiscale",
      subtitle: () => "Grazie per aver scelto Crypto Tax Calculator.",
      title: () => "Acquisto effettuato con successo!",
    },
  },
  paywallBanner: {
    cancel: () => "Cancellare",
    implementations: {
      accountant: {
        enforceUpgrade: {
          buttonText: () => "Piano di aggiornamento",
          subtitle: () =>
            "Chiedi al tuo cliente di aggiornare il suo piano per scaricare i report fiscali",
          subtitleMulti: () => "Il tuo rapporto include:",
          title: (d) =>
            "Il tuo cliente ha superato il limite di transazione corrente sul suo piano ( " +
            d.count +
            " di " +
            d.limit +
            " )",
          titleMulti: () =>
            "Il tuo cliente ha superato i limiti del suo piano, in quanto:",
        },
        renewalFailed: {
          buttonText: () => "Gestisci abbonamento",
          subtitle: () =>
            "Chiedi al tuo cliente di risolvere il problema di pagamento per scaricare i report fiscali.",
          title: () =>
            "Oh no! Non siamo riusciti a elaborare il pagamento del tuo cliente.",
        },
        signUp: {
          buttonText: () => "Iscrizione",
          subtitle: () =>
            "Per generare un report fiscale, dovrai aggiornare il tuo client al piano successivo.",
          title: () => "Vuoi generare un report fiscale per il tuo cliente?",
        },
      },
      standard: {
        enforceUpgrade: {
          buttonText: () => "Piano di aggiornamento",
          subtitle: () =>
            "Aggiorna il tuo piano per scaricare i report fiscali",
          subtitleMulti: () => "Il tuo rapporto include:",
          title: (d) =>
            "Hai superato il limite di transazione corrente sul tuo piano ( " +
            d.count +
            " di " +
            d.limit +
            " )",
          titleMulti: () => "Hai superato i limiti del tuo piano, in quanto:",
        },
        renewalFailed: {
          buttonText: () => "Gestisci abbonamento",
          subtitle: () =>
            "Controlla i dettagli del tuo abbonamento e del pagamento per risolvere il problema.",
          title: () =>
            "Oh no! Non siamo riusciti a elaborare il tuo pagamento.",
        },
        signUp: {
          buttonText: () => "Piano di aggiornamento",
          subtitle: () =>
            "Per generare un report fiscale, sarà necessario effettuare l'upgrade al piano successivo.",
          title: () => "Vuoi generare un report fiscale?",
        },
      },
    },
    paywallReasons: {
      importCount: (d) => "Più di " + d.importLimit + " importazioni",
      tradeType: () => "Transazioni di contratto intelligenti",
      txCount: (d) => "Più di " + d.txLimit + " transazioni",
    },
    reportTable: () =>
      "Effettua l'aggiornamento per accedere alla tabella dei report",
    upgradeFor: () => "Aggiornamento per",
    upgradeNow: () => "Aggiorna ora",
  },
  paywallModal: {
    cancel: () => "Cancellare",
    downloadSample: () => "Scarica il report di esempio",
    implementations: {
      advancedReports: {
        prompt: () => "Sblocca questi report avanzati: ",
        title: () => "Guarda l'immagine completa",
      },
      allReports: {
        prompt: () => "Sblocca questi report: ",
        title: () => "Iscriviti per accedere a tutti i report e moduli fiscali",
      },
      inventory: {
        prompt: () => "Passa al piano per investitori per",
        title: () => "Ottimizza la tua posizione fiscale",
      },
      multiTaxYears: {
        subtitle: () =>
          "Esegui l'upgrade a un piano a pagamento per accedere ai report per tutti gli anni fiscali di cui hai bisogno.",
        title: () => "Più anni fiscali, un abbonamento",
      },
      paywall: {
        subtitle: () => "Esegui l'upgrade per accedere ai tuoi report.",
        title: () => "Hai superato le inclusioni del tuo piano",
      },
      smartContractInteraction: {
        banner: {
          subText: () => "Transazioni di contratto intelligenti",
          text: () => "Il tuo rapporto include:",
          title: () => "Aggiornamento richiesto",
        },
        subtitle: () =>
          "Passa al piano Hobbista per ottenere supporto per tutti i tipi di DeFi, attività on-chain complesse e importazioni illimitate.",
        title: () => "Libera la tua degenerazione interiore",
      },
      tips: {
        subtitle: () =>
          "Passa a un piano a pagamento per accedere a suggerimenti che potrebbero aiutarti a migliorare l'accuratezza del tuo report e a risparmiare sulla tua bolletta fiscale.",
        subtitleReview: () =>
          "Passa a un piano a pagamento per accedere al nostro processo di revisione guidata, che può aiutarti a migliorare l'accuratezza del tuo report e a risparmiare sulla tua bolletta fiscale.",
        title: () => "Iscriviti per opportunità di risparmio",
      },
      tradingStockReport: {
        subtitle: () =>
          "Passa al piano Trader per avere accesso al Trader Stock Report",
        title: () => "Flessibilità di reporting professionale",
      },
    },
    irsModal: {
      dateString: (d) =>
        d.days + " giorni : " + d.hours + " ore : " + d.minutes + " minuti",
      irsTaxSubmissionDeadline: () =>
        "Scadenza per la presentazione delle tasse IRS",
    },
    irsVariantChoosePlan: {
      cta: () => "Procedi al pagamento",
      secondary: () => "Confronta i piani",
      title: () => "Aggiorna il tuo piano.\nSblocca i risparmi.",
      txCount: (d) =>
        "Hai " +
        plural(d.txCount, 0, it, {
          one: "1 transazione",
          other: number("it", d.txCount, 0) + " transazioni",
        }),
      txSubtitle: (d) => "Fino a " + d.txCount + " transazioni",
    },
    irsVariantPreSelectedPlan: {
      cta: () => "Acquista piano",
      disclaimerOne: () => "Garanzia di rimborso di 30 giorni",
      disclaimerTwo: () => "*Lo sconto si applica solo al primo anno",
      discountApplied: () => "Sconto applicato",
      features: {
        0: {
          description: () => "Mantieniti in regola con le autorità fiscali",
          title: () => "Report fiscali pronti per l'audit",
        },
        1: {
          description: () =>
            "Ottieni suggerimenti per ridurre legalmente la tua imposta",
          title: () => "Sblocca risparmi fiscali",
        },
        2: {
          description: () =>
            "Ti guideremo attraverso il processo per garantire l'accuratezza del tuo report",
          title: () => "Non farlo da solo",
        },
      },
      link: () => "Confronta piani",
      price: (d) =>
        d.price +
        plural(d.discount, 0, it, {
          0: "",
          other: " (risparmia " + number("it", d.discount, 0) + "%)*",
        }),
      title: () => "Aggiorna il tuo piano e sblocca tutte le funzionalità",
    },
    irsVariantViewPlans: {
      cta: () => "Visualizza piani",
      metamaskOffer: () => "30% di sconto su tutti i piani",
      reportsReady: () => "I tuoi report sono pronti",
    },
    modalText: {
      "annex-so-other-income": () =>
        "Scarica un esempio del rapporto tedesco - Anlage So (Sonstige Einkünfte)",
      audit: () => "Scarica il report di esempio sulle transazioni di audit",
      bgl: () => "Scarica il campione BGL Simple Fund 360 XML",
      "capital-gains": () => "Scarica il campione del report sulle plusvalenze",
      "capital-gains-nz": () =>
        "Scarica il campione del rapporto Profitti/Perdite Realizzati",
      cerfa2086: () => "Scarica il modello di formulario n°2086",
      expense: () => "Scarica il campione del rendiconto spese varie",
      "gift-and-lost": () =>
        "Scarica il campione del rapporto sui regali in uscita e sui beni persi/rubati",
      income: () => "Scarica il campione del rapporto sui redditi",
      inventory: () => "Scarica il report di inventario di esempio",
      "inventory-v2": () => "Scarica il report di inventario di esempio",
      "irs-1040-s1": () => "Scarica il modello IRS Schedule 1 (modulo 1040)",
      "irs-1040-sd": () => "Scarica il modello di Allegato D (Modulo 1040)",
      "irs-8949": () => "Scarica il modello IRS 8949",
      "irs-8949-consolidated": () =>
        "Scarica il modello IRS Form 8949 (Consolidato)",
      "irs-8949-long": () =>
        "Scarica il modello di modulo 8949 a lungo termine",
      "irs-8949-long-consolidated": () =>
        "Scarica il modello di modulo 8949 a lungo termine (consolidato)",
      "irs-8949-short": () =>
        "Scarica il modello di modulo 8949 a breve termine",
      "irs-8949-short-consolidated": () =>
        "Scarica il modello di modulo 8949 consolidato a breve termine",
      "modelo-100": () => "Scarica il report di esempio del Modello 100",
      mygov: () => "Scarica il campione del rapporto MyGov",
      "nft-capital-gains": () => "Scaricato il report NFT di esempio",
      "nft-report": () => "Scarica il campione del report NFT",
      pack: () => "Scarica i report di esempio",
      "report-summary": () => "Scarica il campione Riepilogo del report",
      "start-of-year-holdings": () =>
        "Scarica il campione del rapporto sulle partecipazioni di inizio anno",
      "tax-lots": () =>
        "Scarica il report di esempio di snapshot dell'inventario",
      "taxact-csv": () =>
        "Scarica il campione TaxAct - Rapporto sulle plusvalenze",
      "trading-pnl": () =>
        "Scarica il report di esempio sul trading di derivati",
      "trading-stock": () =>
        "Scarica il campione del report sulle azioni di trading",
      transaction: () => "Scarica il report di esempio sulle transazioni",
      "turbotax-canadian-pdf": () => "Scarica il PDF di esempio di TurboTax",
      "turbotax-csv": () =>
        "Scarica il campione TurboTax - Report sulle plusvalenze",
      "turbotax-txf": () =>
        "Scarica il campione TurboTax TXF - Report sulle plusvalenze",
      "wiso-steuer": () => "Scarica il rapporto CSV WISO Steuer di esempio",
    },
    reportTable: () =>
      "Effettua l'aggiornamento per accedere alla tabella dei report",
    upgradeFor: () => "Aggiornamento per",
    upgradeNow: () => "Aggiorna ora",
  },
  pending: () => "In attesa di",
  planCard: {
    annualSubscription: () => "Abbonamento annuale",
    bestValue: () => "I più popolari",
    billedYearly: () => "fatturato annualmente",
    taxDeductible: () => "Deducibile dalle tasse",
  },
  planFeatures: {
    coreFeatures: {
      features: {
        assetHistoryLedgers: () =>
          "Registri completi della cronologia delle attività in partita doppia",
        deFiDEX: () => "Integrazioni DeFi e DEX",
        fileImports: () => "API di Exchange e importazioni CSV",
        marginTrades: () => "Operazioni a margine e futures",
        miningStaking: () => "Mining, Staking, Prestiti, Airdrop",
        nftTrading: () => "Negoziazione NFT",
        smartErrorSuggestions: () =>
          "Suggerimenti intelligenti per la gestione degli errori",
        transanctionCategorisation: () =>
          "Algoritmo di auto-categorizzazione delle transazioni",
        transferMatching: () => "Abbinamento intelligente dei trasferimenti",
        walletSync: () => "Sincronizzazione del portafoglio on-chain",
      },
      name: () => "Funzionalità avanzate",
    },
    dynamic: {
      features: {
        complexTransactions: () => "Transazioni on-chain complesse",
        integrations: () => "Integrazioni",
        inventoryAdvanced: () => "Metodi di inventario avanzati",
        portfolioTracking: () => "Monitoraggio del portafoglio",
        reportPreview: () => "Anteprima gratuita del report",
        reportsAdvanced: () => "Rapporti fiscali avanzati",
        reportsPrevYears: () => "Rapporti degli anni fiscali precedenti",
        supportEmailChat: () => "Supporto via e-mail + chat",
        supportPriority: () => "Supporto prioritario",
        taxLossHarvesting: () => "Strumento di raccolta delle perdite fiscali",
        transactions: () => "Transazioni nei report fiscali",
      },
      name: () => "Caratteristiche di base",
    },
    integrations: {
      features: {
        blockchains: () => "Blockchain",
        centralisedExchanges: () => "Scambi centralizzati",
        decentralisedExchanges: () => "Scambi decentralizzati",
        nftMarketplaces: () => "Mercati NFT",
        smartContract: () => "Supporto per contratti intelligenti",
      },
      name: () => "Integrazioni",
    },
    smartSuggestions: {
      features: {
        airdropDetection: () => "Rilevamento di lanci aerei",
        balanceVerification: () => "Verifica automatica del saldo",
        incorrectCategoristion: () =>
          "Rilevamento della categorizzazione errata",
        integration: () => "Suggerimenti per l'integrazione",
        negativeBalance: () =>
          "Avvisi mancanti sulla cronologia degli acquisti",
        spamDetection: () => "Rilevamento token spam",
        transactionCategory: () =>
          "Suggerimenti per la categoria di transazione",
        transferMatching: () =>
          "Suggerimenti di corrispondenza del trasferimento",
      },
      name: () => "Suggerimenti intelligenti",
    },
    support: {
      features: {
        chat: () => "Supporto tramite chat",
        email: () => "Supporto via e-mail",
        priority: () => "Supporto prioritario",
      },
      name: () => "Supporto",
    },
    transactionsManagement: {
      features: {
        advancedFiltering: () => "Filtraggio avanzato",
        bulkEdit: () => "Funzionalità di modifica in blocco",
        noteTaking: () => "Prendere appunti",
        protocolIdentification: () =>
          "Identificazione ed etichettatura del protocollo",
        quickSearch: () => "Ricerca veloce",
      },
      name: () => "Gestione delle transazioni",
    },
  },
  plans: {
    personal: () => "Personale",
    professional: () => "Contabile",
  },
  "polygon-zkevm": {
    "wallet-api": {
      0: () =>
        'Inserisci l\'indirizzo del tuo portafoglio Polygon Hermez (zkEVM) nel Crypto Tax Calculator, fornisci un nickname facoltativo e fai clic su "Aggiungi portafoglio"',
      1: {
        items: {
          0: () =>
            'Puoi aggiungere più portafogli inserendo un altro indirizzo e cliccando su "Aggiungi portafoglio aggiuntivo"',
        },
        type: () => "nota",
      },
    },
  },
  popup: {
    enablePopups: () => "Abilita i popup per continuare",
  },
  portfolioToggles: () => "Portfolio Attiva/disattiva",
  qbo: {
    accountMapping: {
      accounts: {
        asset: {
          title: () => "Conto patrimoniale",
          tooltip: () =>
            "Conto che rappresenta il valore delle attività nel portafoglio dell'utente",
        },
        cash: {
          title: () => "Conto cassa",
          tooltip: () =>
            "Account che rappresenta le disponibilità in contanti. Se vendi criptovalute, rappresenteremo i proventi in contanti qui e viceversa quando acquisti criptovalute. Le transazioni da criptovaluta a criptovaluta non avranno alcun impatto su questo account.",
        },
        expense: {
          title: () => "Conto spese",
          tooltip: () => "Conto che rappresenta le spese in criptovaluta",
        },
        fee: {
          title: () => "Conto commissioni",
          tooltip: () =>
            "Conto che rappresenta l'importo pagato in commissioni",
        },
        income: {
          title: () => "Conto entrate",
          tooltip: () =>
            "Conto che rappresenta il reddito derivante dalle criptovalute",
        },
        loan: {
          title: () => "Conto prestito",
          tooltip: () =>
            "Conto che rappresenta prestiti e rimborsi in criptovalute",
        },
        loss: {
          title: () => "Conto perdite",
          tooltip: () =>
            "Conto che rappresenta l'importo di criptovalute perse",
        },
        "realised-gain": {
          title: () => "Conto guadagno realizzato",
          tooltip: () =>
            "Conto che rappresenta i guadagni realizzati dalla cessione di criptovalute",
        },
        "realised-loss": {
          title: () => "Conto perdite realizzate",
          tooltip: () =>
            "Conto che rappresenta le perdite derivanti dalla cessione di criptovalute",
        },
        "zero-cost-buy": {
          title: () => "Account cronologia acquisti mancante",
          tooltip: () =>
            "Quando un bene viene acquistato o ottenuto e non viene effettuato alcun pagamento per esso, consideriamo la base di costo del bene come il valore di mercato e questo conto viene utilizzato per bilanciare l'altro lato del giornale",
        },
      },
      lastSynced: (d) => "Ultima sincronizzazione " + d.date,
      refreshInstructions: () =>
        "Se hai creato un nuovo account su QuickBooks e non lo vedi, aggiorna l'elenco.",
      syncError: () => "Non siamo riusciti a sincronizzare i dati.",
      syncInstructions: () =>
        "Per iniziare la sincronizzazione devi scegliere a quali dati degli Account verranno mappati. Puoi aggiornare gli Account in qualsiasi momento. La sincronizzazione può essere avviata quando è impostata la mappatura.",
      title: () => "Mappatura degli account",
    },
    buttons: {
      disconnect: () => "Disconnetti da QuickBooks",
      refreshList: {
        completed: () => "Elenco account QuickBooks aggiornato",
        done: () => "Aggiorna l'elenco degli account",
        pending: () => "Aggiornamento dell'elenco",
        tooltip: {
          done: () => "Aggiorna gli account disponibili",
          pending: () => "Potrebbe volerci del tempo",
        },
      },
      syncAccounts: {
        done: () => "Sincronizzazione degli account",
        pending: () => "Sincronizzazione degli account",
        tooltip: {
          disabled: () =>
            "Assicurati che ogni account sia selezionato per abilitare la sincronizzazione",
          done: () => "Sincronizzare gli account con QuickBooks",
          pending: () => "Potrebbe volerci del tempo",
        },
      },
    },
    connect: () => "Connettiti a QuickBooks",
    errors: {
      disconnecting: () =>
        "Si è verificato un problema durante la disconnessione di QuickBooks.",
      fetchingAccounts: () =>
        "Si è verificato un errore durante il recupero dell'elenco degli account QuickBooks.",
      fetchingSettings: () =>
        "Si è verificato un errore durante il recupero delle impostazioni di QuickBooks.",
      fetchingTenants: () =>
        "Si è verificato un errore durante il recupero dell'elenco degli inquilini di QuickBooks.",
      updatingAccounts: () =>
        "Si è verificato un errore durante l'aggiornamento degli account QuickBooks.",
      updatingTenant: () =>
        "Si è verificato un errore durante l'aggiornamento del tenant QuickBooks.",
    },
    integrationName: () => "QuickBooks in linea",
    integrationShortName: () => "QBO",
    newIntegration: {
      connected: () => " Collegato",
      connectedAt: (d) => "Ultimo collegamento " + d.date,
      description: () =>
        "Riassumi le attività commerciali giornaliere in registri contabili all'interno di QuickBooks. Questo può aiutarti a tenere traccia delle tue finanze e a comprendere le fonti di entrate, perdite e spese. Le mappature degli account possono essere personalizzate per adattarsi al modo in cui gestisci la tua contabilità.",
      disconnectConfirmation: () =>
        "Attenzione! Vuoi davvero scollegare il tuo account QuickBooks? Questa azione non può essere annullata, dovrai riselezionare i tuoi account in futuro. I tuoi diari sincronizzati rimarranno in QuickBooks.",
      disconnected: () => "Disconnesso",
      status: () => "Stato: ",
      title: () => "Connetti l'account QuickBooks",
    },
    reportFooter: {
      settingsLink: () =>
        "Visualizza le impostazioni per maggiori informazioni",
      subTitle: () =>
        "Collega il calcolatore delle imposte sulle criptovalute a QuickBooks",
      title: () => "Integrazione QuickBooks",
    },
    rollup: {
      day: () => "Giorno",
      description: {
        day: () =>
          "Tutte le transazioni effettuate in un dato giorno del calendario verranno raggruppate in un unico giornale",
        month: () =>
          "Tutte le transazioni effettuate in un dato mese solare verranno raggruppate in un unico giornale",
        none: () =>
          "Tutte le transazioni verranno esportate come giornali individuali",
      },
      month: () => "Mese",
      none: () => "Nessun rollup",
    },
    syncDialog: {
      confirmation: () =>
        "Sei sicuro di voler sincronizzare? Assicurati che i tuoi dati siano corretti. Le modifiche alle transazioni che sono già state sincronizzate non si rifletteranno in QuickBooks.",
      error: () => "Oh no! Sembra che qualcosa sia andato storto...",
      syncing: () =>
        "Stiamo sincronizzando i dati in questo momento, l'operazione potrebbe richiedere del tempo...",
    },
    tenantList: {
      ensureCorrectTenant: () =>
        "Assicurati che sia selezionato l'account corretto per il feed di dati.",
      exportJournals: () => "Esportazione di riviste",
      listPlaceholder: () => "Seleziona feed dati",
      listTitle: () => "Conto QuickBooks",
    },
  },
  reOnboarding: {
    sync: {
      ExchangeBlockchain: () => "Scambio/blockchain",
      lastSynced: () => "Ultima sincronizzazione",
      noImport: () =>
        "Nessun exchange o blockchain rilevato, aggiungi il tuo primo account per iniziare",
    },
    welcomeBack: {
      afterSyncBanner: () =>
        "La sincronizzazione potrebbe richiedere del tempo, nel frattempo ripassa le principali norme fiscali e inizia a usare l'app!",
      brushUp: () => "Ripassa le principali norme fiscali",
      integrationsPage: () => "pagina integrazioni",
      letMeIn: () => "Lasciami entrare",
      subTitle: () =>
        "Prima di riprendere a lavorare, ricordati di risincronizzare i tuoi account e di importare eventuali nuovi account. Ecco un elenco dei tuoi exchange e blockchain:",
      sync: () => "Sincronizzazione",
      syncFailedBanner: () =>
        "Alcune sincronizzazioni di integrazione non sono riuscite, per risolvere questi problemi procedere al",
      title: () =>
        "Bentornato mio vecchio amico, è tempo di fare di nuovo le tasse",
    },
  },
  reconciliation: {
    allTransactions: () => "Tutte le transazioni",
    api: {
      assignBlockchainSource: () =>
        "Si è verificato un problema durante l'assegnazione della blockchain. Riprova.",
      bulkLookupMarketPriceError: () =>
        "Si è verificato un problema durante la ricerca di queste valute. Riprova.",
      generateTransferError: () =>
        "Si è verificato un problema durante la generazione dei trasferimenti. Riprova.",
      identifyUnknownParty: () =>
        "Si è verificato un problema durante la sostituzione della parte sconosciuta. Riprova.",
      ignoreAllMissingPriceError: () =>
        "Qualcosa è andato storto ignorando tutti i problemi relativi ai prezzi di mercato mancanti. Riprova.",
      ignoreError: () =>
        "Qualcosa è andato storto ignorando il problema. Riprova.",
      markAirdropError: () =>
        "Si è verificato un problema durante la marcatura di questa transazione come airdrop. Riprova.",
      markAllAirdropError: () =>
        "Si è verificato un problema durante la marcatura di queste transazioni come airdrop. Riprova.",
      markAllSpamCurrencyError: () =>
        "Qualcosa è andato storto quando ho contrassegnato queste transazioni come spam. Riprova.",
      markSpamCurrencyError: () =>
        "Si è verificato un problema durante la marcatura di questa transazione come spam. Riprova.",
      refreshError: () =>
        "Si è verificato un problema durante l'aggiornamento dei dati di riconciliazione. Riprova.",
    },
    backToTransactions: () => "Torna alle transazioni",
    completedCountChip: (d) => d.issueCount + " % categorizzato",
    completedCountEmbeddedChip: (d) => d.issueCount + " % completato",
    explainer: {
      consider: () => "Considera quanto segue:",
      done: () => "Fatto",
      usefulLinks: () => "Link utili:",
    },
    fullyReconciledState: {
      ctaText: () => "Ottieni il report fiscale",
      disclaimer: () =>
        "Assicuratevi sempre di controllare due volte che tutto sia corretto.",
      neutralMessage: () =>
        "Al momento non ci sono suggerimenti di riconciliazione.",
      successMessage: () => "Tutti i suggerimenti sono stati risolti!",
      successSubMessage: () =>
        "Hai seguito tutti i nostri suggerimenti di riconciliazione, bel lavoro!",
    },
    helpArticles: () => "Articoli di aiuto",
    ignoreIssue: () => "Ignora il problema",
    ignoreWarning: () => "Ignora avviso",
    impactChip: {
      high: () => "Alto impatto",
      low: () => "Basso impatto",
      medium: () => "Impatto medio",
    },
    issueCountChip: (d) =>
      plural(d.issueCount, 0, it, {
        one: " 1 suggerimento ",
        other: number("it", d.issueCount, 0) + " suggerimenti ",
      }),
    issues: {
      incorrectSendReceives: {
        associatedTransactions: () => "Transazioni",
        ctaButton: {
          importDestination: () => "Destinazione di importazione",
          importSource: () => "Importa origine",
          name: () => "Nome",
        },
        description: () =>
          "Le transazioni in questa categoria sono state classificate in modo errato come Invii o Ricezioni interne. Invia e Ricevi devono essere utilizzati solo tra i wallet che ti appartengono. Queste transazioni devono avere una categoria diversa oppure deve essere importato il wallet sconosciuto.",
        ignore: () => "Ignorare",
        ignoreIssue: {
          action: () => "Ignora il problema",
          description: (d) =>
            "Verranno ignorati tutti gli avvisi di importazione sospetti mancanti sulle transazioni da o verso " +
            select(d.source, { missing: "this source", other: d.source }) +
            ".",
          title: () =>
            "Vuoi ignorare il problema di sospetta importazione mancante?",
        },
        importSource: () => "Importa origine",
        imports: {
          address: () => "Indirizzo",
          bannerText: () =>
            "L'importazione di un portafoglio che non è tuo o di un indirizzo contrattuale avrà un impatto sui tuoi obblighi fiscali.",
          bannerTitle: () =>
            "Prima di importarlo, assicurati che il portafoglio appartenga a te!",
          goBack: () => "Torna indietro",
          label: () => "Trova il tuo exchange o portafoglio",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
            title: () => "Risolvere i problemi di bilanciamento in volo",
          },
        },
        missingSource: () => "Fonte",
        navButton: () => "Invii/ricezioni errati",
        notMyWallet: () => "Non è il mio portafoglio",
        steps: {
          0: () =>
            "La fonte o la destinazione appartengono a te? Utilizza il pulsante di importazione e importa l'exchange o il wallet.",
          1: () =>
            "Se la fonte o la destinazione non ti appartengono. Espandi l'accordion e categorizza correttamente le transazioni.",
        },
        tableHeader: {
          assets: () => "Attività",
          associatedGainsLaptop: () => "Guadagni associati",
          destination: () => "Destinazione",
          source: () => "Fonte",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transazioni",
          transferAmount: () => "Quantità",
          transferAmountLaptop: () => "Importo del trasferimento",
        },
        title: () => "Invii/ricezioni errati",
        transferBalance: () => "Importo del trasferimento",
        transferBalanceTooltip: () =>
          "L'importo del trasferimento rappresenta il valore fiat della criptovaluta inviata da e verso questa presunta importazione mancante",
        viewTransactions: () => "Visualizza le transazioni",
      },
      missingBlockchain: {
        assignBlockchain: () => "Assegna blockchain",
        chipTitle: () => "Risolvi le blockchain mancanti",
        description: () => "Assegna una catena a queste transazioni",
        headers: {
          amount: () => "Quantità",
          assets: () => "Attività",
          source: () => "Fonte",
          transactions: () => "Transazioni",
          transferAmount: () => "Importo del trasferimento",
          txs: () => "Tx's",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6388387-how-to-resolve-missing-blockchain-warnings",
            title: () => "Come risolvere gli avvisi Blockchain mancanti",
          },
        },
        navButton: () => "Assegnare blockchain",
        steps: {
          0: () => "Riconosci la transazione e ricordi la blockchain?",
          1: () =>
            "Quale block explorer restituisce un risultato per l'ID transazione?",
        },
        title: () => "Blockchain mancanti",
      },
      missingPrice: {
        added: () => "Aggiunto",
        bulkLookupButton: {
          failUpdated: () =>
            "Non è stato possibile trovare i prezzi di mercato per queste transazioni",
          label: () => "Cerca prezzo",
          successUpdated: (d) =>
            "Prezzo di mercato aggiornato per " +
            plural(d.count, 0, it, {
              one: " 1 transazione ",
              other: number("it", d.count, 0) + " transazioni ",
            }),
        },
        chipTitle: () => "Risolvi i prezzi mancanti",
        ctaButton: {
          label: () => "Ignora avviso",
          labelLaptop: () => "Ignora avviso",
        },
        description: () =>
          "Non è stato possibile trovare i prezzi per una valuta nelle seguenti transazioni",
        drawer: {
          cta: () => "Perché mancano i prezzi?",
          headerTwo: () =>
            "Cosa succede se il token con prezzo mancante è comune e quotato su servizi come Coingecko",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5411667-how-to-fix-missing-market-price-warning",
              title: () => "Prezzi di mercato mancanti",
            },
          },
          linkSectionHeader: () => "Articoli di aiuto",
          listItems: {
            0: () => "Il token ha una liquidità estremamente bassa.",
            1: () =>
              "Il token è nuovo di zecca e non è ancora stato acquisito dall'oracolo dei prezzi.",
            2: () => "Il token non è quotato sui principali exchange e DEX.",
            3: () => "Non elencato.",
          },
          listTitle: () =>
            "Alcuni scenari in cui mancano le informazioni sui prezzi di mercato storici:",
          paragraphOne: () =>
            "I prezzi mancanti si verificano solitamente quando un prezzo per un token non esiste in nessuno dei database di oracoli di prezzi che utilizziamo. Ciò è estremamente raro poiché abbiamo prezzi per oltre 300.000 valute.",
          paragraphTwo: () =>
            "Se riesci a trovare il token su Coingecko, allora è molto probabile che CTC stia assegnando l'istanza errata del token. Ad esempio, un token con lo stesso ticker è stato assegnato alla transazione. Puoi risolvere cliccando sulla valuta e cambiando la valuta nel menu fornito.",
        },
        ignoreAllIssue: {
          action: () => "Ignora tutto",
          description: () =>
            "Questo ignorerà gli avvisi di prezzo mancanti per tutte le transazioni nel tuo account ed è irreversibile. Dovresti procedere solo se i prezzi sono $0.",
          title: () => "Ignorare tutti gli avvisi sui prezzi mancanti?",
        },
        ignoreIssue: {
          action: () => "Ignora gli avvisi",
          description: (d) =>
            select(d.name, {
              unknown:
                " Questo ignorerà tutti gli avvisi di prezzo mancanti sulle transazioni che utilizzano questa valuta. ",
              other:
                " Questo ignorerà tutti gli avvisi di prezzo mancanti sulle transazioni che utilizzano " +
                d.name +
                " . ",
            }),
          title: (d) =>
            "Ignora i problemi relativi ai prezzi mancanti per " +
            select(d.name, { unknown: " questa valuta ", other: " nome " }) +
            select(d.ticker, { unknown: "", other: " ( " + d.ticker + " ) " }) +
            " ?",
        },
        missingPrice: () => "Prezzo mancante",
        navButton: () => "Risolvi i prezzi mancanti",
        tableHeader: {
          assets: () => "Attività",
          associatedGains: () => "Guadagni",
          associatedGainsLaptop: () => "Guadagni associati",
          currency: () => "Valuta",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transazioni",
        },
        title: () => "Transazioni con prezzi mancanti",
        viewTransactions: () => "Visualizza le transazioni",
      },
      negativeBalance: {
        amountExplanation: () =>
          "L'impatto della mancata correzione di un problema di cronologia degli acquisti mancante è che i tuoi obblighi fiscali saranno calcolati come se la tua base di costo per qualsiasi asset interessato fosse zero. Ciò aumenterà le tue imposte da pagare.",
        chipTitle: () => "Risolvi la cronologia degli acquisti mancante",
        ctaButton: {
          label: () => "Registro delle valute",
          labelLaptop: () => "Visualizza nella tabella delle transazioni",
        },
        currency: () => "Valuta",
        description: () =>
          "La cronologia degli acquisti mancante si verifica quando sono stati venduti più asset crittografici di quelli acquistati in origine. Ciò suggerisce che alcuni record di acquisizione potrebbero essere mancanti, non categorizzati o categorizzati in modo errato.",
        drawer: {
          cta: () => "Cosa manca nella cronologia degli acquisti?",
          headerTwo: () =>
            "Risoluzione del problema della cronologia degli acquisti mancante.",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
              title: () =>
                "Risoluzione dei problemi relativi alla cronologia degli acquisti mancanti",
            },
            1: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5305370-faq-what-is-a-zero-cost-buy",
              title: () => "Che cosa è un acquisto a costo zero",
            },
          },
          linkSectionHeader: () => "Articoli di aiuto",
          listTitle: () =>
            "Se hai sicuramente caricato tutti i tuoi account, la mancata visualizzazione degli avvisi sulla cronologia degli acquisti potrebbe essere dovuta a uno o più dei seguenti motivi:",
          orderedListItems: {
            0: () => "Un acquisto o uno scambio mancante",
            1: () =>
              "Le transazioni non sono state categorizzate o sono state categorizzate in modo errato. Ad esempio, un acquisto è stato categorizzato come ricezione.",
            2: () =>
              "Un invio/ricezione mancante Gli invii e le ricezioni devono sempre essere raggruppati in un trasferimento. Ad esempio, ogni invio deve avere una ricezione corrispondente e viceversa.",
          },
          paragraphOne: () =>
            "La cronologia degli acquisti mancante si verifica quando sono stati venduti più asset crittografici di quelli acquistati in origine. Ciò suggerisce che alcuni record di acquisizione potrebbero essere mancanti, non categorizzati o categorizzati in modo errato.",
          paragraphThree: () =>
            "In alcuni casi uno dei tuoi exchange potrebbe non riuscire a segnalare correttamente le ricompense o le commissioni di staking.",
          paragraphTwoBoldSection: () =>
            "Il primo passo per risolvere il problema della cronologia degli acquisti mancante è assicurarti di aver caricato TUTTI i tuoi account wallet e exchange.",
          paragraphTwoRegularSection: () =>
            "Questa è la causa più comune poiché determina lacune significative nella cronologia delle transazioni.",
        },
        gains: () => "Guadagni associati",
        grouping: {
          byCurrency: () => "Per valuta",
          byWallet: () => "Per importazione",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6005779-solving-negative-balances",
            title: () =>
              "Risoluzione dei problemi relativi alla cronologia degli acquisti mancanti",
          },
          1: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6140443-solving-in-flight-balance-issues",
            title: () => "Risolvere i problemi di bilanciamento in volo",
          },
          2: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5284400-balance-remaining-is-incorrect",
            title: () => "Il saldo rimanente non è corretto",
          },
          3: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5305370-handling-zero-cost-buy-transactions",
            title: () => "Gestione delle transazioni di acquisto a costo zero",
          },
        },
        navButton: () => "Risolvi la cronologia degli acquisti mancante",
        steps: {
          0: () =>
            "Assicuratevi di aver importato tutte le transazioni di tutti gli anni finanziari, comprese le negoziazioni OTC.",
          1: () =>
            "Fai clic sulle transazioni associate e si aprirà una nuova finestra con tutte le transazioni da quella particolare fonte. Assicurati che tutte le transazioni siano categorizzate.",
          2: () =>
            "Crea una transazione manuale per gestire eventuali dati mancanti dagli exchange o dalle transazioni OTC.",
        },
        tableHeader: {
          amountNegative: () => "Quantità",
          amountNegativeDesktop: () => "Importo mancante",
          assets: () => "Valute",
          associatedGains: () => "Guadagni",
          associatedGainsLaptop: () => "Guadagni associati",
          currency: () => "Valuta",
          source: () => "Fonte",
          taxImpact: () => "Impatto fiscale",
          transactions: () => "Tx's",
          transactionsDesktop: () => "Transazioni",
          transferAmount: () => "Quantità",
          transferAmountDesktop: () => "Importo del trasferimento",
        },
        title: () => "Rivedi la cronologia degli acquisti mancanti",
        transactions: () => "Transazioni",
        viewLedger: () => "Registro delle valute",
        viewTransactions: () => "Visualizza le transazioni",
      },
      negativeBalanceByWallet: {
        title: () => "Problemi con la cronologia degli acquisti mancanti",
      },
      smartContractInteractions: {
        categorise: () => "Classificare",
        description: () =>
          "Queste transazioni sono interazioni tra i tuoi wallet e smart contract. Esamina ogni indirizzo di contratto e contrassegnali con un nome appropriato, se necessario, quindi categorizza le transazioni.",
        navButton: () => "Categorizza le transazioni",
        tableHeader: {
          addresses: () => "Indirizzi",
          amount: () => "Quantità",
          assets: () => "Attività",
          transactions: () => "Transazioni",
          transferAmount: () => "Importo del trasferimento",
          txs: () => "Tx's",
        },
        title: () => "Interazioni con contratti intelligenti",
      },
      suspectedAirdropTransaction: {
        description: () =>
          "Gli Airdrop sospetti includono indirizzi noti di origine degli Airdrop. Contrassegna un indirizzo per confermare che si tratti di un Airdrop.",
        links: {
          0: {
            href: () =>
              "https://cryptotaxcalculator.io/blog/crypto-airdrop-tax",
            title: () => "Come vengono tassati gli airdrop?",
          },
        },
        markAllAsTrade: {
          button: () => "Contrassegna tutto come Airdrop",
          confirm: () => "Confermare",
          description: () =>
            'In questo modo la categoria "Airdrop" verrà assegnata a tutte le transazioni qui elencate e i problemi verranno risolti.',
          title: () => "Contrassegnare tutte le transazioni come Airdrop?",
        },
        markAsNotTrade: () => "Contrassegna come non Airdrop",
        markAsTrade: () => "Contrassegna come Airdrop",
        markSelectedAsTrade: () => "Contrassegna selezionati come airdrop",
        navButton: () => "Categorizza le transazioni",
        notTrade: () => "Non Airdrop",
        steps: {
          0: () => "Riconosci il nome del token?",
          1: () =>
            "Ricordi di aver interagito con il protocollo per ricevere il token?",
        },
        title: () => "Sospetti lanci aerei",
        trade: () => "Lancio aereo",
      },
      suspectedBurnTransaction: {
        description: () =>
          "Abbiamo rilevato alcune transazioni che hanno inviato token a un indirizzo burner noto. Indica se queste transazioni sono burn.",
        markAllAsTrade: {
          button: () => "Segna tutto come Brucia",
          confirm: () => "Confermare",
          description: () =>
            'In questo modo la categoria "Brucia" verrà assegnata a tutte le transazioni qui elencate e i problemi verranno risolti.',
          title: () => "Contrassegnare tutte le transazioni come Burn?",
        },
        markAsNotTrade: () => "Contrassegna come non bruciare",
        markAsTrade: () => "Contrassegna come brucia",
        markSelectedAsTrade: () => "Contrassegna selezionato come masterizzato",
        navButton: () => "Categorizza le transazioni",
        notTrade: () => "Non bruciare",
        steps: {
          0: () => "Riconosci il nome del token?",
          1: () => "Ricordi di aver inviato i token a un indirizzo burn?",
        },
        title: () => "Transazioni sospette di Burn",
        trade: () => "Bruciare",
      },
      suspectedMissingImports: {
        associatedTransactions: () => "Transazioni",
        ctaButton: {
          label: () => "Importare",
          name: () => "Nome",
        },
        description: () =>
          "Le importazioni sospette mancanti sono indirizzi con cui hai interagito che non sono stati importati in Crypto Tax Calculator. Risolvi gli avvisi di questa categoria di riconciliazione importando l'indirizzo mancante o confermando che questo portafoglio non ti appartiene.",
        ignore: () => "Ignorare",
        ignoreIssue: {
          action: () => "Ignora il problema",
          description: (d) =>
            "Verranno ignorati tutti gli avvisi di importazione mancanti sospetti sulle transazioni da o verso " +
            select(d.source, { missing: "this source", other: d.source }) +
            ".",
          title: () =>
            "Vuoi ignorare il problema di sospetta importazione mancante?",
        },
        importSource: () => "Importa origine",
        imports: {
          address: () => "Indirizzo",
          bannerText: () =>
            "L'importazione di un portafoglio che non è tuo o di un indirizzo contrattuale avrà un impatto sui tuoi obblighi fiscali.",
          bannerTitle: () =>
            "Prima di importarlo, assicurati che il portafoglio appartenga a te!",
          goBack: () => "Torna indietro",
          label: () => "Trova il tuo exchange o portafoglio",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279670-import-exchange-transactions",
            title: () => "Importare transazioni di cambio",
          },
          1: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279724-import-wallet-and-defi-transactions",
            title: () => "Importa portafoglio e transazioni DeFi",
          },
          2: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5597463-how-to-add-an-additional-wallet-for-the-same-blockchain",
            title: () =>
              "Come aggiungere un ulteriore portafoglio per la stessa blockchain?",
          },
        },
        missingSource: () => "Fonte",
        navButton: () => "Importazioni sospette mancanti",
        notMyWallet: () => "Non è il mio portafoglio",
        steps: {
          0: () =>
            "Hai trasferito asset tra i tuoi wallet? Potresti aver dimenticato di importare i dati da uno di questi wallet.",
          1: () =>
            'Se si tratta di una transazione tra te e un portafoglio esterno, puoi selezionare la categoria che descrive meglio queste transazioni. Non scegliere "Invia" o "Ricevi" poiché queste categorie dovrebbero essere utilizzate solo per i trasferimenti tra i portafogli di tua proprietà.',
        },
        tableHeader: {
          assets: () => "Attività",
          associatedGainsLaptop: () => "Guadagni associati",
          source: () => "Fonte",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transazioni",
          transferAmount: () => "Quantità",
          transferAmountLaptop: () => "Importo del trasferimento",
        },
        title: () => "Importazioni sospette mancanti",
        transferBalance: () => "Importo del trasferimento",
        transferBalanceTooltip: () =>
          "L'importo del trasferimento rappresenta il valore fiat della criptovaluta inviata da e verso questa presunta importazione mancante",
        viewImport: () => "Visualizza importazione",
        viewTransactions: () => "Visualizza le transazioni",
      },
      suspectedSpamToken: {
        currency: () => "Valuta",
        description: () =>
          "I token sospetti di spam vengono identificati come privi di dati sul prezzo di mercato, con una sola transazione in entrata o provenienti da una fonte di spam nota. Contrassegnare una transazione come spam la nasconderà ed escluderà dai calcoli del saldo.",
        headers: {
          asset: () => "Risorsa",
          price: () => "Prezzo",
          source: () => "Fonte",
          transactions: () => "Transazioni",
          txs: () => "Tx's",
          value: () => "Valore",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/6185693-dealing-with-scam-airdrops",
            title: () => "Come gestire gli airdrop truffa",
          },
        },
        markAllAsSpam: {
          button: () => "Contrassegna tutto come spam",
          confirm: () => "Confermare",
          description: () =>
            'In questo modo, a tutte le transazioni elencate qui verrà assegnata la categoria "Spam" e i problemi verranno risolti.',
          title: () => "Contrassegnare tutte le transazioni come spam?",
        },
        markAsNotSpam: () => "Contrassegna come non spam",
        markAsSpam: () => "Contrassegna come spam",
        markSelectedAsSpam: () => "Contrassegna selezionato come spam",
        navButton: () => "Categorizza le transazioni",
        notSpam: () => "Non è spam",
        spam: () => "Spam",
        steps: {
          0: () => "Riconosci il nome del token?",
          1: () =>
            "Ricordi di aver interagito con il protocollo per ricevere il token?",
          2: () =>
            "Se non si verifica nessuna delle condizioni sopra indicate, è probabile che questo token sia un tentativo di truffa e dovrebbe essere contrassegnato come spam.",
        },
        title: () => "Sospetti token spam",
        transactionSource: () => "Origine della transazione",
        transactions: () => "Transazioni",
      },
      uncategorisedIntraExchangeTransactions: {
        associatedTransactions: () => "Transazioni",
        ctaButton: {
          label: () => "Classificare",
        },
        description: () =>
          "Le transazioni intra-exchange sono transazioni in cui sia la fonte che la destinazione sono lo stesso exchange. Le transazioni in questa categoria sono comunemente pagamenti di interessi, ricompense di staking, reddito o airdrop.",
        missingSource: () => "Fonte",
        navButton: () => "Categorizza le transazioni",
        tableHeader: {
          assets: () => "Attività",
          associatedGainsLaptop: () => "Guadagni associati",
          source: () => "Fonte",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transazioni",
          transferAmount: () => "Quantità",
          transferAmountLaptop: () => "Importo del trasferimento",
        },
        title: () => "Transazioni intra-borsa",
        transferBalance: () => "Importo del trasferimento",
        transferBalanceTooltip: () =>
          "L'importo del trasferimento rappresenta il valore fiat della criptovaluta inviata da e verso questa presunta importazione mancante",
        viewTransactions: () => "Visualizza le transazioni",
      },
      uncategorisedTransactions: {
        button: () => "Classificare",
        chipTitle: () => "Categorizza le transazioni",
        description: () =>
          "Applica la categoria appropriata alle seguenti transazioni per migliorare l'accuratezza del tuo report",
        drawer: {
          cta: () => "Scopri di più sulla categorizzazione",
          headerOne: () => "Perché la categorizzazione è importante",
          headerTwo: () =>
            "Non dovrebbe essere tutto categorizzato automaticamente?",
          helpLinks: {
            0: {
              href: () =>
                "https://help.cryptotaxcalculator.io/en/articles/5279619-categorize-transactions",
              title: () => "Guida dettagliata per tutti i tipi di transazione",
            },
          },
          linkSectionHeader: () => "Articoli di aiuto",
          paragraphOne: () =>
            'La categorizzazione consente a CTC di applicare l\'esito fiscale appropriato alla transazione e di tracciare con precisione la base di costo nella cronologia delle transazioni. Le transazioni non categorizzate sono etichettate come "In entrata" e "In uscita", queste vengono ignorate dai calcoli fiscali, il che può comportare maggiori guadagni in conto capitale a causa di lacune nel monitoraggio della base di costo.',
          paragraphTwo: () =>
            "Se hai importato tutti i tuoi account, anche quelli che non utilizzi più. La maggior parte delle tue transazioni dovrebbe essere automaticamente categorizzata. In alcuni casi non è stato possibile determinare una categoria appropriata per le transazioni. Invece di fare supposizioni, le evidenziamo per chiarire.",
        },
        inContext: {
          categorised: () => "Categorizzato!",
          finish: () => "Fine",
          goToNext: () => "Vai alla transazione successiva",
          nextTransaction: () => "Prossima transazione",
          notSure: () => "Non sei sicuro? Vai alla transazione successiva",
          previousTransaction: () => "Transazione precedente",
        },
        links: {
          0: {
            href: () =>
              "https://help.cryptotaxcalculator.io/en/articles/5279619-categorise-transactions",
            title: () => "Categorizza le transazioni",
          },
        },
        navButton: () => "Categorizza le transazioni",
        similarChip: (d) => d.count + " simili",
        steps: {
          0: () =>
            "Assicurati di aver importato tutti i dati da tutte le fonti possibili.",
          1: () =>
            "Assegna manualmente le categorie delle transazioni che vedi qui sotto.",
        },
        title: () => "Chiarire le transazioni",
        transactionSource: () => "Origine della transazione",
        type: () => "Categoria",
        uncategorisedTransactions: () => "Transazioni non categorizzate",
        viewSwitcher: {
          inContext: () => "Nel contesto",
          onlyUncategorised: () => "Solo non categorizzati",
        },
        viewTransactions: () => "Visualizza le transazioni",
      },
      uncategorisedWalletInteractions: {
        associatedTransactions: () => "Transazioni",
        categorise: () => "Classificare",
        description: () =>
          "Le interazioni del wallet sono altri indirizzi wallet con cui i tuoi wallet importati hanno interagito, come i tuoi wallet, i wallet degli exchange o il wallet di un'altra persona. Risolvi questi avvisi categorizzando correttamente le interazioni.",
        navButton: () => "Categorizza le transazioni",
        steps: {
          0: () =>
            'Hai trasferito asset tra i tuoi wallet? Se sì, le transazioni in uscita dovrebbero essere categorizzate come "Invia" e quelle in entrata come "Ricevi".',
          1: () =>
            "Se hai interagito con il portafoglio di qualcun altro, potresti prendere in considerazione categorie come regalo, uso personale, vendita o acquisto.",
        },
        tableHeader: {
          assets: () => "Attività",
          transactions: () => "Tx's",
          transactionsLaptop: () => "Transazioni",
          transferAmount: () => "Quantità",
          transferAmountLaptop: () => "Importo del trasferimento",
          wallets: () => "Portafogli",
        },
        title: () => "Interazioni del portafoglio",
        transferBalance: () => "Importo del trasferimento",
      },
      unknownParty: {
        ctaButton: {
          label: () => "Sostituisci parte sconosciuta",
        },
        description: () =>
          'Una parte sconosciuta si verifica quando un portafoglio/exchange non segnala la fonte o la destinazione di una transazione. Ciò significa che il movimento di asset in entrata o in uscita viene segnalato ma lascia l\'altra parte come "sconosciuta". Per correggere questo problema, la fonte/destinazione sconosciuta deve essere sostituita con una parte nota.',
        modal: {
          actionButton: () => "Sostituisci parte sconosciuta",
          actionButtonMobile: () => "Sostituisci partito",
          cancelButton: () => "Cancellare",
          description: (d) =>
            "Questo sostituirà tutte " +
            d.direction +
            " sconosciute della parte",
          destinationDirection: () => "destinazione",
          exchangePlaceholder: () => "Festa conosciuta",
          sourceDirection: () => "fonte",
          title: () => "Sostituisci partito",
        },
        navButton: () => "Identificare la festa",
        steps: {
          0: () =>
            "Hai importato tutti i tuoi wallet e exchange? Il nostro algoritmo di corrispondenza potrebbe essere in grado di rilevare e risolvere automaticamente la parte sconosciuta.",
          1: () =>
            'Sai dove è stato inviato o ricevuto l\'asset? In tal caso, puoi assegnare manualmente la parte alla transazione utilizzando il pulsante "Sostituisci parte sconosciuta".',
        },
        tableHeader: {
          Destination: () => "Destinazione",
          assets: () => "Attività",
          source: () => "Fonte",
          transactions: () => "Transazioni",
          transactionsMobile: () => "TXS-Trasferimenti",
          transferAmount: () => "Importo del trasferimento",
          transferAmountMobile: () => "Quantità",
        },
        title: () => "Partiti sconosciuti",
      },
      unmatchedTransfer: {
        button: () => "Risolvere",
        chipTitle: () => "Risolvi i trasferimenti non corrispondenti",
        ctaButton: {
          import: () => "Controparte di importazione",
          recategorise: () => "Ricategorizzare",
        },
        description: () =>
          "Collega correttamente tutti i trasferimenti per garantire che tutte le basi di costo siano calcolate correttamente",
        drawer: {
          cta: () => "Come risolvere i trasferimenti non abbinati",
          headerFive: () => "Per risolvere queste discrepanze, puoi:",
          headerFour: () => "Risoluzione dei trasferimenti non corrispondenti",
          headerOne: () => "Cosa sono i trasferimenti non abbinati?",
          headerSix: () =>
            "Un abbinamento accurato dei trasferimenti è fondamentale per un corretto calcolo delle imposte e per garantire che la cronologia delle transazioni in criptovaluta sia completa",
          headerThree: () => "Ciò si verifica in genere in tre scenari:",
          headerTwo: () => "Comprensione dei trasferimenti non abbinati",
          listOneItems: {
            0: () =>
              "Hai inviato o ricevuto criptovalute da un portafoglio che non è stato importato nel tuo account",
            1: () =>
              "Una transazione è stata classificata come invio o ricezione, ma non riusciamo a trovare la sua controparte corrispondente",
            2: () =>
              "Hai utilizzato un ponte per trasferire asset tra diverse blockchain e possiamo vedere solo un lato della transazione del ponte",
          },
          listThreeItems: {
            0: () =>
              "Riclassificare la transazione se è stata etichettata in modo errato come invio/ricezione o bridge out/in",
          },
          listTwoItems: {
            0: () =>
              "Importa il portafoglio della controparte mancante per completare la coppia di trasferimento",
          },
          paragraphOne: () =>
            "I trasferimenti non abbinati si verificano quando c'è una transazione in uscita dal tuo portafoglio importato verso una destinazione sconosciuta, oppure quando una transazione di invio/ricezione non ha la coppia di trasferimento corrispondente.",
          paragraphThree: () =>
            "Se si verifica un trasferimento non corrispondente tra due fonti che hai già importato, hai due opzioni:",
          paragraphTwo: () =>
            "Quando invii o ricevi criptovalute tra wallet o account, ci dovrebbero sempre essere due lati della transazione: il wallet di invio e il wallet di ricezione. Un trasferimento non abbinato indica che possiamo vedere solo un lato di questo, il che può portare a un monitoraggio impreciso dei tuoi averi e della loro base di costo associata.",
          subListOneItems: {
            0: () =>
              'Abilita l\'impostazione "Assumi trasferimenti" per abbinare automaticamente i trasferimenti tra i tuoi account',
            1: () =>
              'Genera manualmente un trasferimento selezionando "Genera trasferimento" dal menu delle opzioni della transazione',
          },
        },
        headers: {
          amount: () => "Quantità",
          assets: () => "Attività",
          source: () => "Controparte",
          transactions: () => "Transazioni",
          transferAmount: () => "Importo del trasferimento",
          txs: () => "Tx's",
        },
        navButton: () => "Risolvi i trasferimenti non corrispondenti",
        steps: {
          0: () =>
            "Assicurati di aver importato tutti i dati da tutte le fonti possibili.",
          1: () =>
            "Riassegna manualmente le categorie delle transazioni che vedi sotto.",
        },
        title: () => "Risolvi i trasferimenti non corrispondenti",
      },
    },
    loadMore: () => "Carica altro",
    noRemainingIssues: {
      allCleared: (d) =>
        "Ottimo lavoro! Ora hai risolto tutti i " + d.issueTitle,
    },
    noTransactionsState: {
      importCTA: () => "Vai a Importazioni",
      instructions: () => "Nessun dato aggiunto",
      instructionsSubtext: () =>
        "Importa i tuoi conti per iniziare la riconciliazione.",
    },
    quantity: (d) => "Quantità: " + d.amount,
    reconcileCTA: {
      itemsReconciled: () => "Transazioni riconciliate",
      progressBanner: {
        body: () =>
          "Alcune transazioni hanno ancora dei problemi da correggere. Migliorare l'integrità dei tuoi dati potrebbe ridurre il tuo carico fiscale.",
        completeText: () =>
          "Non ci sono suggerimenti di riconciliazione, bene! Assicurati sempre di ricontrollare che tutto sembri corretto.",
        title: () => "Stato attuale della riconciliazione",
      },
      reconcileItems: (d) =>
        plural(d.issueCount, 0, it, {
          one: " Riconcilia transazione ",
          other: " Riconcilia transazioni ",
        }),
      suggestionBanner: {
        body: (d) =>
          "Rimangono " +
          plural(d.suggestionNum, 0, it, {
            one: " è 1 suggerimento ",
            other:
              " sono " + number("it", d.suggestionNum, 0) + " suggerimenti ",
          }) +
          " per aiutarti a riconciliare le tue transazioni.",
        cta: () => "Suggerimenti per la revisione",
        suggestionChip: (d) =>
          plural(d.suggestionNum, 0, it, {
            one: " 1 suggerimento ",
            other: number("it", d.suggestionNum, 0) + " suggerimenti ",
          }),
      },
    },
    resolvedChip: {
      resolved: () => "Risolto",
    },
    sidebar: {
      knowledgeBase: () => "Base di conoscenza",
      steps: {
        categorise: () => "Classificare",
        correct: () => "Corretto",
        import: () => "Importare",
        prepare: () => "Preparare",
        resolve: () => "Risolvere",
      },
      suggestions: (d) =>
        plural(d.count, 0, it, {
          one: " 1 suggerimento ",
          other: number("it", d.count, 0) + " suggerimenti ",
        }),
      tip: {
        message: () =>
          "Per risultati ottimali, riconcilia le tue transazioni nell'ordine in cui appaiono nella barra laterale, dall'alto verso il basso.",
        title: () => "Consiglio da professionista",
      },
    },
    tipsInfoModal: {
      items: {
        0: () =>
          "Tutte le transazioni sulla blockchain sono visibili all'autorità fiscale e possono essere ricondotte al tuo exchange KYC",
        1: () =>
          "Devi fornire un quadro completo di tutta la cronologia delle tue negoziazioni",
        2: () =>
          "Ti aiutiamo a identificare eventuali problemi in modo da poter superare un audit",
      },
      subtitle: () =>
        "Abbiamo individuato alcuni suggerimenti per ridurre il tuo debito fiscale e garantire l'accuratezza dei report.",
      subtitleReview: () =>
        "Rivedi questi elementi per ridurre il tuo debito fiscale e garantire l'accuratezza dei report.",
      title: (d) => "Potresti potenzialmente risparmiare " + d.amount,
    },
    title: () => "Riconciliazione",
    tradeSuggestion: {
      currency: () => "Valuta",
      headers: {
        amount: () => "Quantità",
        asset: () => "Risorsa",
        price: () => "Prezzo",
        transactions: () => "Transazioni",
        txs: () => "Tx's",
        value: () => "Valore",
      },
      transactionSource: () => "Origine della transazione",
      transactions: () => "Transazioni",
    },
    unignoreIssue: () => "Rimuovi ignora problema",
    viewTransactions: () => "Visualizza le transazioni",
  },
  referAFriend: {
    copyCTA: () => "Copia",
    disclaimer: (d) =>
      "*Per ogni utente che si iscrive a CTC e acquista un piano per la prima volta utilizzando il tuo link di referral unico, riceverai " +
      d.reward +
      " in contanti o l'importo totale che l'individuo referenziato paga per il suo piano, a seconda di quale sia inferiore. I pagamenti saranno distribuiti tramite PayPal 60 giorni dopo la fine del mese in cui si sono iscritti a un piano. L'importo finale ricevuto può variare se si verificano fluttuazioni valutarie tra il momento in cui si guadagna la ricompensa e il ricevimento del pagamento. Tutti i pagamenti sono effettuati in AUD. Per qualificarsi, gli utenti referenziati devono registrarsi utilizzando il tuo link di referral personale. Le auto-referenze non sono idonee a ricevere i benefici della ricompensa.",
    helpCenterLink: () => "Centro assistenza",
    messageUs: (d) =>
      "Mandaci un DM [@CryptoTaxHQ](" +
      d.linkTextKey +
      ") ed esploriamo come possiamo collaborare!",
    moreDetails: (d) =>
      "Per maggiori dettagli, visita il nostro [centro assistenza](" +
      d.linkTextKey +
      ").",
    paypalSection: {
      available: () => "Disponibile",
      availableTooltip: () =>
        "L'importo disponibile che puoi richiedere in contanti",
      claimCTA: () => "Richiedi ora",
      claimCTATooltip: () => "Devi attendere 60 giorni prima di richiedere",
      connectCTA: () => "Connetti PayPal",
      connectedSubtext: () =>
        "I guadagni sono disponibili per essere richiesti una volta che l'individuo referenziato è stato su un piano a pagamento per 60 giorni",
      error: () => "Inserisci un indirizzo email valido",
      lifetimeEarnings: () => "Guadagni totali",
      lifetimeEarningsTooltip: () => "Importo totale guadagnato e pagato a te",
      modal: {
        connectCTA: () => "Connetti",
        connectPlaceholder: () => "Inserisci i dettagli PayPal",
        connectSubtext: () => "Inserisci il tuo indirizzo email PayPal",
        connectTitle: () => "Connetti PayPal",
        disconnectCTA: () => "Disconnetti PayPal",
        updateCTA: () => "Cambia PayPal",
        updatePlaceholder: () => "Inserisci nuovi dettagli PayPal",
        updateSubtext: (d) => d.username + " è attualmente connesso",
        updateTitle: () => "Aggiorna PayPal",
      },
      plansPurchased: () => "Piani acquistati",
      plansPurchasedTooltip: () =>
        "Il numero di utenti che hanno acquistato un piano utilizzando il tuo link di referral",
      subtext: () =>
        "Inizia a condividere il tuo link di referral, poi collega il tuo PayPal per richiedere il denaro.",
      title: () => "I tuoi guadagni da referral",
      updateCTA: () => "Aggiorna PayPal",
    },
    socialLinks: {
      email: (d) =>
        "Ho utilizzato Crypto Tax Calculator per le mie tasse sulle criptovalute - è veloce, facile e preciso, inoltre supporta numerose integrazioni.\n\n Se devi ancora fare le tue, puoi utilizzare il mio link di referral e risparmiare il 20% sul tuo piano.\n\n " +
        d.link,
      emailSubject: () =>
        "Risparmia il 20% su Crypto Tax Calculator quando usi il mio link!",
      telegram: (d) =>
        "Per chiunque altro come me che ha quasi abbandonato per la frustrazione il calcolo delle tasse sulle criptovalute, date un'occhiata a @CryptoTaxHQ. Ha totalmente salvato la mia sanità mentale. Se volete provarli, usate il mio link di referral e risparmiamo entrambi! " +
        d.link,
      x: (d) =>
        "Per chiunque altro come me che ha quasi abbandonato per la frustrazione il calcolo delle tasse sulle criptovalute, date un'occhiata a @CryptoTaxHQ. Ha totalmente salvato la mia sanità mentale. Se volete provarli, usate il mio link di referral e risparmiamo entrambi! " +
        d.link,
    },
    subtext: (d) =>
      "Condividi CTC con i tuoi amici per guadagnare fino a " +
      d.reward +
      " in contanti per ogni referral che fai. Loro risparmieranno un ottimo 20% sul loro piano, e tu riceverai fino a " +
      d.reward +
      " in contanti per ogni referral. Questa sì che è una situazione vantaggiosa per tutti!",
    title: (d) => "Ottieni " + d.reward + " in contanti* per ogni referral",
    workWithUs: () =>
      "Hai una community di criptovalute o un seguito numeroso su X?",
  },
  referrals: {
    description: () =>
      "Quando un amico si iscrive a CTC e acquista un piano per la prima volta usando il tuo link di riferimento, riceverai un credito di $ 40 per il tuo abbonamento. Accumula abbastanza crediti e il tuo prossimo abbonamento potrebbe essere gratuito. Per qualificarsi, il tuo amico deve iscriversi usando il tuo link di riferimento personale.",
    linkText: () => "Il tuo link di riferimento:",
    subtext: () =>
      "Ottieni $ 40 di sconto su CTC per ogni amico che presenti. Anche loro riceveranno $ 40 di sconto. Ka-ching 💰",
    title: () => "Dona 40 $ e ricevi 40 $",
  },
  report: {
    ACB: () => "Base di costo rettificata",
    AVCO: () => "Costo medio",
    FIFO: () => "Il primo ad arrivare è il primo ad uscire",
    HIFO: () => "Il più alto in First Out",
    HMRC: () => "HM Revenue and Customs",
    IRS: {
      done: () => "Fatto",
      downloadIrs8949Long: () =>
        "Scarica il modulo IRS 8949 Guadagni a lungo termine",
      downloadIrs8949LongConsolidated: () =>
        "Scarica il modulo IRS 8949 Guadagni a lungo termine (consolidati)",
      downloadIrs8949Short: () =>
        "Scarica il modulo IRS 8949 Guadagni a breve termine",
      downloadIrs8949ShortConsolidated: () =>
        "Scarica il modulo IRS 8949 Guadagni a breve termine (consolidati)",
      downloadIrsForm: () => "Scarica il modulo IRS Schedule 1 (modulo 1040)",
      downloadIrsScheduleDForm: () =>
        "Scarica il modulo IRS Schedule D (modulo 1040)",
      provideIrsDetails: () => "Fornisci i tuoi dettagli IRS",
      stepComplete: () => "Tutti i passaggi sono completati: hai finito",
    },
    LIFO: () => "Ultimo arrivato, primo uscito",
    LTFO: () => "Più efficace dal punto di vista fiscale",
    PFU: () => "PFU / Francia",
    acknowledged: () => "Riconosciuto",
    airdropIncome: () => "Lanci aerei",
    allTradeSelector: () => "Tutti i tipi di txs",
    amountRemaining: () => "Importo rimanente",
    amountYouCouldSave: () =>
      "L'importo che potresti risparmiare migliorando l'accuratezza del tuo report",
    approvals: () => "Approvazioni",
    assetSummary: {
      amountRemainingEndFy: () => "Saldo di fine anno fiscale",
      amountRemainingEndFyTooltip: () =>
        "Saldo totale detenuto alla fine dell'anno fiscale.",
      amountRemainingStartFy: () => "Saldo iniziale dell'anno fiscale",
      capitalGain: () => "Capitale P/L",
      cost: () => "Costo",
      currency: () => "Valuta",
      fee: () => "Commissioni",
      income: () => "Reddito",
      longTerm: () => "A lungo termine",
      noDisposals: () => "Nessuna cessione in questo periodo.",
      overallGain: () => "Totale P/L",
      proceeds: () => "Proventi",
      quantity: () => "Quantità smaltita",
      quantityTooltip: () => "Quantità totale ceduta durante l'anno fiscale.",
      shortTerm: () => "A breve termine",
    },
    ausFinancialYear: (d) =>
      "1 luglio " + d.prevYear + " - 30 giugno " + d.year,
    buyTime: () => "Comprato",
    capitalGainSelector: {
      all: () => "Tutto",
      gains: () => "Guadagni",
      longTermCapitalGains: () => "Guadagni a lungo termine",
      longTermCapitalLosses: () => "Perdite a lungo termine",
      shortTermCapitalGains: () => "Guadagni a breve termine",
      shortTermCapitalLosses: () => "Perdite a breve termine",
      totalLosses: () => "Perdite",
    },
    checkWithAgent: () =>
      "Non sei sicuro? Rivolgiti al tuo consulente fiscale.",
    closed: () => "Chiuso",
    confirmInventoryMethod: () => "Conferma metodo inventario",
    confirmationText: (d) =>
      "Hai selezionato il metodo di inventario " +
      d.method +
      " , che non è l'opzione predefinita. Consulta sempre il tuo consulente fiscale prima di utilizzare il metodo di inventario " +
      d.method +
      " .",
    cost: () => "Base di costo",
    costBasisRedistributionMethod: {
      "largest-wallet-highest-cost": () =>
        "Portafoglio più grande, costo più elevato",
      "largest-wallet-lowest-cost": () =>
        "Portafoglio più grande, costo più basso",
      "least-adjustment": () => "Minimo aggiustamento",
      title: () => "Metodo di ridistribuzione",
      tooltip: () =>
        "Il metodo utilizzato per ridistribuire la base di costo delle attività quando si passa dal metodo universale a quello basato sul portafoglio e sulla base di costo di scambio",
    },
    csv: () => "Formato CSV",
    currency: () => "Valuta",
    customDateRange: () => "Date dell'anno finanziario",
    customDates: () => "Seleziona date personalizzate",
    defaultPaywallBannerText: () => "Iscriviti per visualizzare il tuo report",
    derivativeTradingIncome: () => "Reddito da negoziazione di derivati",
    disclaimer: (d) =>
      "Dovresti usare questi risultati solo come stima e a scopo di orientamento. Sebbene cerchiamo di garantire l'accuratezza di questi risultati, potrebbero verificarsi degli errori. Assicurati di verificare i risultati. " +
      select(d.isRegularUser, {
        true: " In caso di dubbi, parla con il tuo consulente fiscale. ",
        other: "",
      }),
    disclaimerTitle: () =>
      "I risultati calcolati si basano sulle informazioni fornite al momento del calcolo.",
    download: {
      bglAccountIDModal: {
        buttonText: () => "Fatto",
        copyId: () => "Copia ID",
        subtitle: () =>
          "Utilizza questo ID come ID account quando importi i tuoi dati in BGL:",
        title: () => "ID account BGL",
      },
      buttonIrs: () => "Genera modulo",
      downloading: () => "Scaricamento in corso...",
      exportCSV: () => "Esporta in file CSV",
      exportIrs1040s1Pdf: () => "Esporta in IRS Schedule 1 (Modulo 1040) PDF",
      exportIrs8949Pdf: () => "Esporta in PDF modulo IRS 8949",
      exportPDF: () => "Esporta in file PDF",
      exportTurboTaxOnline: () => "Esporta in TurboTax Online (CSV)",
      exportTurboTaxTXF: () => "Esporta su TurboTax Desktop (TXF)",
      irsDownload: {
        customTitle: () => "Dettagli del modulo IRS (facoltativo)",
        download: () => "Scaricamento",
        name: () => "Nome/i mostrato/i al reso",
        skip: (d) =>
          "Poiché non hai ottenuto alcun guadagno di " +
          d.termLength +
          " termini, puoi saltare questo passaggio.",
        social: () => "SSN o TIN",
        whyUserData: (d) =>
          "Se desideri che i tuoi dati vengano automaticamente compilati su " +
          d.formName +
          " , inserisci il tuo nome e un SSN/TIN valido. Rispettiamo la tua privacy, quindi questi dati non vengono archiviati da nessuna parte e vengono utilizzati solo per generare i PDF.",
        whyUserDataCustom: () =>
          "Se desideri che i tuoi dati vengano compilati automaticamente, inserisci il tuo nome e un SSN/TIN valido. Rispettiamo la tua privacy, quindi questi dati non vengono salvati da nessuna parte e vengono utilizzati solo per generare i PDF.",
      },
      loading: () => "Scaricamento",
      sample: () => "Campione",
      tabCryptoTax: () => "Rapporto fiscale",
      tabGermanReports: () => "Forme tedesche",
      tabIrs: () => "Moduli IRS",
      tabTaxIntegrations: () => "Integrazioni software",
      title: () => "Scarica i report",
      tradingStockReportDownload: {
        buttons: {
          cancel: () => "Cancellare",
          downloadReport: () => "Scarica il rapporto",
        },
        costBasisMethod: {
          description: () =>
            "La base di costo delle attività viene utilizzata per determinare i valori di apertura e chiusura del tuo stock di trading. Se esiste un valore di mercato corrispondente per un'attività, verrà sovrascritto.",
          title: () => "Utilizzare il metodo di base del costo",
        },
        description: () =>
          "Seleziona il metodo che desideri utilizzare per determinare i valori di apertura e chiusura delle tue azioni in negoziazione.",
        fallbackMethod: {
          description: () =>
            "I valori di mercato vengono utilizzati per tutti gli asset. Quando non è possibile recuperare un prezzo di mercato e quindi non è possibile determinare un valore di mercato, viene utilizzata la base di costo.",
          title: () => "Utilizzare il metodo di fallback",
        },
        marketValueMethod: {
          description: () =>
            'I valori di mercato vengono utilizzati per tutti gli asset. Quando non è possibile recuperare un prezzo di mercato e quindi non è possibile determinare un valore di mercato, mostreremo il valore come "N/A" (non disponibile). Questo è il metodo utilizzato di default in passato.',
          title: () =>
            "Utilizzare il metodo del valore di mercato (predefinito)",
        },
        table: {
          defaultValuationMethod: () =>
            "Metodo del valore di mercato (predefinito)",
          tooltip: () =>
            'Per questa tabella viene utilizzato il metodo di valutazione predefinito, ovvero per tutte le attività vengono utilizzati i valori di mercato; laddove non è possibile reperire un prezzo di mercato e quindi determinare un valore di mercato, verrà visualizzato il valore come "N/D".',
        },
        title: () => "Rapporto sulle azioni di trading",
      },
      turbotaxModal: {
        buttonText: () => "Vai subito su TurboTax",
        helpLinkText: () => "Guida online all'importazione di TurboTax",
        subtitle: () =>
          "Questo report può essere importato in TurboTax online seguendo le istruzioni riportate di seguito.",
        title: () => "Rapporto TurboTax",
      },
    },
    downloadReports: {
      downloadReportAnyway: () => "Scarica comunque il report",
      primaryButton: () => "Scarica i report",
      quickDownload: () => "Download rapido",
      title: () => "Rapporti",
    },
    emailReportsModal: {
      always: () => "Invia sempre i report via email",
      body: () =>
        "Questo è un report di grandi dimensioni e potrebbe richiedere più di 1 minuto per essere generato. Vuoi che questo report ti venga inviato via email una volta completato?",
      dontShowAgain: () => "Non mostrare più questo messaggio",
      emailMe: () => "Mandami un'email",
      illWait: () => "Aspetterò",
      title: () => "Segnala tramite email?",
    },
    errorTitle: () => "Segnala Avvisi",
    estimatedGainBanner: {
      title: () => "Guadagno stimato",
      tooltip: () =>
        "Il guadagno stimato rappresenta i tuoi guadagni totali dalle criptovalute, calcolato come Guadagni in conto capitale + Reddito - Perdite in conto capitale. Questa stima si basa sui dati importati finora. Per un risultato accurato è necessario un quadro completo di tutte le tue transazioni da quando hai acquistato criptovalute per la prima volta.",
    },
    expense: () => "Spese",
    expenses: () => "Spese",
    fee: () => "Tassa",
    fees: () => "Commissioni",
    financialYear: () => "Anno finanziario",
    form3916ExchangeInfo: {
      address: () => "Indirizzo",
      company: () =>
        "Designazione dell'organizzazione di gestione dell'account (*)",
      country: () => "Paese",
      title: () => "Dichiarazione dei conti esteri",
      website: () =>
        "URL del sito web dell'organizzazione di gestione dell'account",
    },
    fyChip: (d) => "Al " + d.timeframe,
    gain: () => "Guadagno (perdita)",
    gainNoCGT: () => "Profitto (perdita)",
    grid: {
      softwareConnections: () => "Connessioni software",
      taxReports: () => "Rapporti fiscali",
      txTypes: () => "Tipi di transazione",
    },
    headings: {
      derivativeTradingIncome: () => "Reddito da negoziazione di derivati",
      expenses: () => "Spese",
      gains: () => "Plusvalenze",
      longTermCapitalGains: () => "Plusvalenze a lungo termine",
      longTermCapitalLosses: () => "Perdite di capitale a lungo termine",
      nonTaxableTransactions: () => "Transazioni non imponibili",
      ordinaryIncome: () => "Reddito ordinario",
      shortTermCapitalGains: () => "Plusvalenze a breve termine",
      shortTermCapitalLosses: () => "Perdite di capitale a breve termine",
      totalCapitalGains: () => "Plusvalenze nette",
      totalExpenses: () => "Spese nette",
      totalIncome: () => "Reddito netto",
      totalLosses: () => "Perdite",
    },
    income: () => "Reddito",
    incurredTime: () => "Incorso",
    interestIncome: () => "Interesse",
    inventory: {
      endBalance: () => "Saldo di chiusura",
      endCostBasis: () => "Costo di chiusura",
      endValue: () => "Valore di mercato di chiusura",
      startBalance: () => "Saldo di apertura",
      startCostBasis: () => "Costo di apertura",
      startValue: () => "Valore di mercato iniziale",
    },
    inventoryMethod: () => "Metodo di inventario",
    loanRepayments: () => "Rimborsi del prestito",
    lockedPeriod: {
      all: () => "Questo periodo è bloccato",
      costBasisTracking: () =>
        "Non è possibile modificare l'impostazione di monitoraggio della base di costo durante l'utilizzo di periodi bloccati",
      multipleInventoryMethods: () => "Vario",
      none: () => "Periodo di blocco",
      some: () => "Questo periodo contiene transazioni bloccate",
      tooltip: () =>
        "Non è possibile modificare le impostazioni per un periodo bloccato",
    },
    longShortIncome: () => "Reddito da trading a margine",
    longTermGains: () => "Plusvalenze a lungo termine",
    longTermGainsNoCGT: () => "Profitti realizzati a lungo termine",
    lookTooltip: (d) => "[Iscriviti]( " + d.linkTextKey + " ) per visualizzare",
    miningRewards: () => "Estrazione mineraria",
    miscellaneousExpenses: () => "Spese",
    missingPurchase: {
      text: () => "Acquisto mancante",
      tooltip: (d) =>
        "Manca l'acquisto per questa cessione, abbiamo ipotizzato una base di costo pari a $ 0,00. [Risolvi acquisto mancante]( " +
        d.linkTextKey +
        " ) cronologia per assegnare correttamente la base di costo.",
    },
    modifyTaxSettings: () => "Modificare",
    nav: {
      reportSummary: () => "Riepilogo del rapporto",
      taxReports: () => "Report fiscali",
    },
    noFrenchReportDataModal: {
      body1: (d) =>
        "Non sono state registrate transazioni crypto-fiat per " +
        d.year +
        " , quindi Forumalaire 2086 non è richiesto.",
      body2: (d) =>
        "Se ti aspettavi di avere delle transazioni crypto-fiat per " +
        d.year +
        " , controlla due volte che tutte le transazioni siano importate. Controlla altri report per potenziali transazioni di reddito.",
      title: (d) => "Nessuna transazione da criptovaluta a fiat in " + d.year,
    },
    noRecordsFound: () => "Non sono stati trovati record rilevanti...",
    nzFinancialYear: (d) => "1 aprile " + d.prevYear + " - 31 marzo " + d.year,
    opened: () => "Aperto",
    ordinaryIncome: () => "Reddito ordinario",
    otherIncome: () => "Reddito",
    otherReports: () => "Altri report e integrazioni",
    overallGain: () => "Guadagno complessivo",
    packs: {
      customModal: {
        columns: {
          details: () => "Dettagli",
          report: () => "Rapporto",
        },
        csv: () => "Formato CSV",
        downloadSelected: (d) =>
          select(d.isFree, {
            true: " Scarica report di esempio ( " + d.selectedCount + " ) ",
            other: " Scarica selezionati ( " + d.selectedCount + " ) ",
          }),
        getSample: () => "Ottieni un campione",
        pdf: () => "Il PDF è in inglese.",
        selectPlanBanner: {
          body: () => "Seleziona un piano per scaricare i tuoi report",
          cta: () => "Seleziona un piano",
        },
        title: () => "Rapporti fiscali",
        txf: () => "TXF",
        upgrade: (d) =>
          "Esegui l'upgrade a " + d.planTitle + " per accedere a questo report",
        xml: () => "Formato XML",
      },
      filters: {
        accountant: () => "Per i commercialisti",
        all: () => "Tutto",
        self: () => "Per l'auto-segnalazione",
      },
      metadata: {
        8949: {
          description: () => "Vendite e altre disposizioni",
          title: () => "Modulo IRS 8949",
        },
        accountant: {
          description: () => "Invia al tuo commercialista",
          title: () => "Pacchetto contabile",
        },
        custom: {
          description: () => "Visualizza tutti i report disponibili",
          title: () => "Costume",
        },
        form3916bis: {
          description: () => "Dichiarazione dei conti esteri",
          title: () => "Modulo 3916 bis",
        },
        fr2086: {
          description: () =>
            "Per la presentazione delle transazioni imponibili",
          title: () => "Modulo n°2086",
        },
        mygov: {
          description: () => "Invia tramite MyGov",
          title: () => "Rapporto ATO (MyTax)",
        },
        scheduleS1AndSD: {
          description: () => "Reddito aggiuntivo e adeguamenti del reddito",
          title: () => "IRS 1040 s1 e sD",
        },
        self: {
          description: () =>
            "Per presentare la propria dichiarazione dei redditi",
          title: () => "Auto-segnalazione",
        },
        taxLots: {
          description: () =>
            "Visualizza un'istantanea dei lotti che compongono il tuo inventario",
          title: () => "Istantanea dell'inventario",
        },
        turbotax: {
          description: () => "Per l'importazione in TurboTax Online",
          title: () => "TurboTax Online",
        },
      },
    },
    paywallBannerFYText: () =>
      "Seleziona un piano per visualizzare i report di più anni fiscali",
    paywallBannerText: () =>
      "Seleziona un piano per visualizzare il tuo report",
    paywallBannerTextCollaborator: () =>
      "Chiedi al tuo commercialista di abbonarsi per visualizzare il tuo report",
    paywallBannerTextMap: {
      accountant: {
        accountantOrCollaboratorView: {
          free: () =>
            "Seleziona un piano per vedere i guadagni e scaricare i report dei tuoi clienti",
          importCount: () =>
            "Il tuo cliente ha raggiunto il limite di importazione per questo piano. Esegui l'upgrade per visualizzare i report del tuo cliente",
          tradeType: () =>
            "Il tuo cliente ha transazioni di smart contract che non sono supportate in questo piano. Esegui l'upgrade per visualizzare i report del tuo cliente",
          txCount: () =>
            "Il tuo cliente ha raggiunto il limite di transazione per questo piano. Esegui l'upgrade per visualizzare i report del tuo cliente",
          unpaid: () =>
            "Hai un abbonamento non pagato. Paga l'abbonamento in sospeso per visualizzare i report del tuo cliente",
        },
        clientView: {
          free: () =>
            "Chiedi al tuo commercialista di abbonarsi per visualizzare il tuo report",
          importCount: () =>
            "Hai raggiunto il limite di importazioni per il tuo piano. Chiedi al tuo commercialista di abbonarsi per visualizzare il tuo report",
          tradeType: () =>
            "Hai transazioni di smart contract che non sono supportate nel tuo piano. Chiedi al tuo commercialista di iscriversi per visualizzare il tuo report",
          txCount: () =>
            "Hai raggiunto il limite di transazione per il tuo piano. Chiedi al tuo commercialista di abbonarsi a un piano superiore per visualizzare il tuo report",
          unpaid: () =>
            "Chiedi al tuo commercialista di abbonarsi per visualizzare il tuo report",
        },
      },
      both: {
        accountantOrCollaboratorView: {
          free: () =>
            "Seleziona un piano per vedere i guadagni e scaricare i report dei tuoi clienti",
          importCount: () =>
            "Il tuo cliente ha raggiunto il limite di importazione per questo piano. Esegui l'upgrade o chiedi al tuo cliente di eseguire l'upgrade per visualizzare i report del tuo cliente",
          tradeType: () =>
            "Il tuo cliente ha transazioni di smart contract che non sono supportate in questo piano. Esegui l'upgrade o chiedi al tuo cliente di eseguire l'upgrade per visualizzare i report del tuo cliente",
          txCount: () =>
            "Il tuo cliente ha raggiunto il limite di transazione per questo piano. Esegui l'upgrade o chiedi al tuo cliente di eseguire l'upgrade per visualizzare i report del tuo cliente",
          unpaid: () =>
            "Hai un abbonamento non pagato. Paga l'abbonamento in sospeso per visualizzare i report del tuo cliente",
        },
        clientView: {
          free: () =>
            "Seleziona un piano o chiedi al tuo commercialista di abbonarsi per visualizzare il tuo report",
          importCount: () =>
            "Hai raggiunto il limite di importazioni per il tuo piano. Seleziona un piano o chiedi al tuo commercialista di abbonarsi per visualizzare il tuo report",
          tradeType: () =>
            "Hai transazioni di smart contract che non sono supportate nel tuo piano. Seleziona un piano o chiedi al tuo commercialista di abbonarsi per visualizzare il tuo report",
          txCount: () =>
            "Hai raggiunto il limite di transazione per il tuo piano. Seleziona un piano o chiedi al tuo commercialista di abbonarsi a un piano superiore per visualizzare il tuo report",
          unpaid: () =>
            "Il tuo abbonamento non è pagato. Seleziona un piano o chiedi al tuo commercialista di abbonarsi per visualizzare il tuo report",
        },
      },
      client: {
        accountantOrCollaboratorView: {
          free: () =>
            "Seleziona un piano per visualizzare il report del tuo cliente",
          importCount: () =>
            "Il tuo cliente ha raggiunto il limite di importazione per questo piano. Chiedi al tuo cliente di effettuare l'upgrade per visualizzare il suo report",
          tradeType: () =>
            "Il tuo cliente ha transazioni di smart contract che non sono supportate in questo piano. Chiedi al tuo cliente di effettuare l'upgrade per visualizzare il suo report",
          txCount: () =>
            "Il tuo cliente ha raggiunto il limite di transazione per questo piano. Chiedi al tuo cliente di effettuare l'upgrade per visualizzare il suo report",
          unpaid: () =>
            "Il tuo cliente ha un abbonamento non pagato. Chiedi al tuo cliente di pagare l'abbonamento in sospeso per visualizzare il suo report",
        },
        clientView: {
          free: () => "Seleziona un piano per visualizzare il tuo report",
          importCount: () =>
            "Hai raggiunto il limite di importazioni per il tuo piano. Seleziona un piano per visualizzare il tuo report",
          tradeType: () =>
            "Hai transazioni di smart contract che non sono supportate nel tuo piano. Seleziona un piano per visualizzare il tuo report",
          txCount: () =>
            "Hai raggiunto il limite di transazione per il tuo piano. Abbonati a un piano superiore per visualizzare il tuo report",
          unpaid: () =>
            "Il tuo abbonamento non è pagato. Iscriviti per visualizzare il tuo report",
        },
      },
      user: {
        free: () => "Seleziona un piano per scaricare il tuo report",
        importCount: () =>
          "Hai raggiunto il limite di importazioni per il tuo piano. Seleziona un piano per scaricare il tuo report",
        tradeType: () =>
          "Hai transazioni di smart contract che non sono supportate nel tuo piano. Seleziona un piano per scaricare il tuo report",
        txCount: () =>
          "Hai raggiunto il limite di transazione per il tuo piano. Abbonati a un piano superiore per visualizzare il tuo report",
        unpaid: () =>
          "Il tuo abbonamento non è pagato. Iscriviti per visualizzare il tuo report",
      },
    },
    paywalledNumberTooltip: () => "Iscriviti per visualizzare",
    pdf: () => "Il PDF è in inglese.",
    proceeds: () => "Proventi",
    quantity: () => "Quantità",
    receivedTime: () => "Ricevuto",
    reportDetails: () => "Dettagli del rapporto",
    reportSubTitles: {
      "annex-so-other-income": () =>
        "Rapporto tedesco con Anlage SO completato automaticamente",
      audit: () =>
        "Elenca tutte le transazioni, la loro origine e le modifiche registrate dagli utenti",
      bgl: () => "Consente l'importazione nel software BGL Simple Fund 360",
      "capital-gains": () =>
        "Eventi di plusvalenza derivanti dall'acquisto e dalla vendita di criptovalute",
      "capital-gains-nz": () =>
        "Eventi di profitto/perdita realizzati dall'acquisto e dalla vendita di criptovalute",
      cerfa2086: () => "Per la presentazione delle transazioni imponibili",
      expense: () => "Spese varie sostenute durante il trading di criptovalute",
      form3916bis: () => "Dichiarazione di conti esteri",
      "gift-and-lost": () =>
        "Registrazione dettagliata di ogni regalo in uscita e transazione persa/rubata",
      income: () => "Reddito guadagnato quando si riceve criptovaluta",
      inventory: () => "Riepilogo delle scorte di inizio e fine anno",
      "inventory-v2": () => "Riepilogo delle scorte di inizio e fine anno",
      "irs-1040-s1": () => "Reddito aggiuntivo e adeguamenti del reddito",
      "irs-1040-sd": () => "Plusvalenze e minusvalenze",
      "irs-8949": () => "Vendite e altre cessioni di beni capitali",
      "irs-8949-consolidated": () =>
        "Vendite e altre cessioni di attività in conto capitale (consolidate per valuta)",
      "modelo-100": () =>
        "Modello 100 compilato automaticamente con plusvalenze e redditi",
      mygov: () => "Per l'autodichiarazione delle tasse in MyGov",
      "nft-report": () =>
        "Eventi di plusvalenze e royalties derivanti dall'acquisto e dalla vendita di NFT",
      "report-summary": () =>
        "Riepilogo di alto livello della tua attività di trading completa",
      "start-of-year-holdings": () =>
        "Riepilogo dei tuoi titoli all'inizio dell'anno",
      "tax-lots": () =>
        "Visualizza un'istantanea dei lotti che compongono il tuo inventario",
      "taxact-csv": () => "Consente l'importazione nel software TaxAct Online",
      "trading-pnl": () =>
        "Profitti o perdite derivanti da derivati, margini e futures",
      "trading-stock": () => "Utilizzato se si opera come azienda",
      transaction: () => "Registrazioni dettagliate di ogni transazione",
      "turbotax-canadian-pdf": () =>
        "Consente l'incollaggio in TurboTax Online per gli utenti canadesi",
      "turbotax-csv": () =>
        "Consente l'importazione nel software TurboTax Online",
      "turbotax-txf": () =>
        "Consente l'importazione nel software TurboTax CD/DVD",
      "wiso-steuer": () => " CSV formattato da WISO Steuer",
    },
    reportType: {
      "annex-so-other-income": () =>
        "Rapporto tedesco - Anlage So (Sonstige Einkünfte)",
      audit: () => "Rapporto di transazione di revisione",
      bgl: () => "Esportazione dati BGL Simple Fund 360",
      "capital-gains": () => "Rapporto sulle plusvalenze",
      "capital-gains-nz": () => "Rapporto sui profitti/perdite realizzati",
      cerfa2086: () => "Modulo n°2086",
      expense: () => "Rapporto spese varie",
      form3916bis: () => "Modulo 3916 bis",
      "gift-and-lost": () =>
        "Report sui regali in uscita e sui beni persi/rubati",
      income: () => "Rapporto sui redditi",
      inventory: () => "Rapporto di inventario",
      "inventory-v2": () => "Rapporto di inventario",
      "irs-1040-s1": () => "Allegato 1 dell'IRS (modulo 1040)",
      "irs-1040-sd": () => "Allegato D (Modulo 1040)",
      "irs-8949": () => "Modulo IRS 8949",
      "irs-8949-consolidated": () => "Modulo IRS 8949 (consolidato)",
      "irs-8949-long": () => "Modulo 8949 a lungo termine",
      "irs-8949-long-consolidated": () =>
        "Modulo 8949 a lungo termine (consolidato)",
      "irs-8949-short": () => "Modulo 8949 a breve termine",
      "irs-8949-short-consolidated": () =>
        "Modulo 8949 a breve termine (consolidato)",
      "modelo-100": () => "Modello 100",
      mygov: () => "Rapporto ATO myTax",
      "nft-report": () => "Rapporto NFT",
      "report-summary": () => "Riepilogo del rapporto",
      "start-of-year-holdings": () =>
        "Rapporto sulle partecipazioni di inizio anno",
      "tax-lots": () => "Report di snapshot dell'inventario",
      "taxact-csv": () => "TaxAct - Relazione sulle plusvalenze",
      "trading-pnl": () => "Rapporto di trading sui derivati",
      "trading-stock": () => "Rapporto sulle azioni di trading (Trader)",
      transaction: () => "Rapporto sulle transazioni",
      "turbotax-canadian-pdf": () => "TurboTax PDF",
      "turbotax-csv": () => "TurboTax - Report sulle plusvalenze",
      "turbotax-txf": () => "TurboTax TXF - Rapporto sulle plusvalenze",
      "wiso-steuer": () => "Rapporto CSV di WISO Steuer",
    },
    reportTypeSelector: () => "Tipo di rapporto",
    rewardsAndInterest: () => "Premi e interessi",
    rowsPerPage: () => "Righe per pagina",
    royalties: () => "Diritti d'autore",
    saFinancialYear: (d) =>
      "1° marzo " + d.prevYear + " - " + d.febLastDay + " febbraio " + d.year,
    saveMoney: () =>
      "Completa i passaggi della revisione per risparmiare denaro!",
    savingsOpportunity: () => "Opportunità di risparmio",
    selectPlan: () => "Seleziona un piano",
    sellTime: () => "Venduto",
    shortTermGains: () => "Plusvalenze a breve termine",
    shortTermGainsNoCGT: () => "Totale profitti realizzati",
    stakingIncome: () => "Ricompense per lo Staking",
    summary: {
      reviewTips: {
        button: () => "Rivedi le transazioni per risparmiare",
        subTitle: {
          control: () => "La tua opportunità di risparmio è ",
          ourTaxMinimisationAlgorithmCouldSaveYou: () =>
            "Il nostro algoritmo di minimizzazione delle tasse potrebbe farti risparmiare ",
          youCouldMinimiseYourTaxLiabilityBy: () =>
            "Potresti ridurre al minimo la tua responsabilità fiscale ",
          youCouldSaveXyzInTaxes: {
            prefix: () => "Potresti risparmiare ",
            suffix: () => " nelle tasse",
          },
        },
        title: () => "Non pagare le tasse in eccesso! 💸",
        tooltip: () =>
          "Importo stimato di cui potresti ridurre il tuo guadagno in conto capitale categorizzando e risolvendo tutti i problemi in esame.",
      },
      title: () =>
        "Iscriviti per visualizzare i suggerimenti su come risparmiare sulla tua dichiarazione dei redditi",
      txs: (d) => plural(d.count, 0, it, { 1: " tx ", other: " txs " }),
    },
    tablePagination: (d) => d.from + " - " + d.to + " di " + d.count,
    taxReports: () => "Rapporti fiscali",
    taxSettingWarnings: {
      uncategorisedBothAsTaxable: () =>
        "Le transazioni in uscita non categorizzate vengono trattate come vendite sul mercato e le transazioni in entrata non categorizzate vengono trattate come acquisti sul mercato.",
      uncategorisedInAsTaxable: () =>
        "Le transazioni in entrata non categorizzate sono trattate come acquisti sul mercato",
      uncategorisedOutAsTaxable: () =>
        "Le transazioni in uscita non categorizzate sono trattate come vendite sul mercato",
    },
    taxSettings: () => "Impostazioni fiscali",
    taxablePortfolioValue: () => "Valore del portafoglio",
    tooltips: {
      airdrop: () => "Reddito guadagnato da criptovalute lanciate via airdrop",
      approvals: () =>
        "Costi di approvazione sostenuti durante il trading di criptovalute",
      capitalLosses: () =>
        "Totale perdite di capitale realizzate durante l'esercizio finanziario",
      capitalLossesNoCGT: () =>
        "Perdite totali realizzate durante l'esercizio finanziario",
      derivativeTradingIncome: () =>
        "Profitti o perdite derivanti da derivati, margini e futures",
      expenses: () =>
        "Spese varie sostenute durante il trading di criptovalute",
      fees: () => "Commissioni sostenute durante il trading di criptovalute",
      income: () =>
        "Altri redditi ricevuti, come premi, segnalazioni, bonus, ecc.",
      loan: () =>
        "Interessi e/o capitale pagati per il mantenimento di un prestito aperto o di una posizione di margine",
      longTermCapitalLosses: (d) =>
        "Totale perdite di capitale realizzate da attività detenute per più di " +
        d.longTermThreshold +
        " mesi",
      longTermThreshold: (d) =>
        "Totale plusvalenze realizzate da attività detenute per più di " +
        d.longTermThreshold +
        " mesi",
      longTermThresholdNoCGT: (d) =>
        "Profitti totali da attività detenute per più di " +
        d.longTermThreshold +
        " mesi",
      mining: () => "Ricompense minerarie considerate reddito",
      miscellaneousExpenses: () =>
        "Spese varie sostenute durante l'esercizio finanziario",
      miscellaneousIncome: () =>
        "Redditi vari conseguiti durante l'anno finanziario",
      noCapitalGains: () =>
        "Totale plusvalenze realizzate durante l'anno finanziario",
      noCapitalGainsNoCGT: () =>
        "Totale utili realizzati durante l'anno finanziario",
      noLongTermThreshold: () =>
        "Nessuna soglia a lungo termine, tutti i guadagni sono considerati a breve termine",
      pnl: () =>
        "Profitto realizzato da margine di negoziazione, futures, perpetui, opzioni, ecc.",
      royalty: () => "Reddito guadagnato come royalty",
      shortTermCapitalLosses: (d) =>
        "Totale perdite di capitale realizzate da attività detenute per meno di " +
        d.longTermThreshold +
        " mesi",
      staking: () => "Reddito guadagnato da interessi e ricompense di staking",
      totalCapitalGains: (d) =>
        "Totale plusvalenze realizzate da attività detenute per meno di " +
        d.longTermThreshold +
        " mesi",
      totalCapitalGainsNoCGT: (d) =>
        "Totale profitti/perdite realizzati da attività detenute per meno di " +
        d.longTermThreshold +
        " mesi",
      totalGains: () =>
        "Guadagni netti in conto capitale, più reddito netto, meno spese nette",
    },
    totalCapitalGains: () => "Plusvalenze totali",
    totalCapitalGainsNoCGT: () => "Totale profitti/perdite realizzati",
    totalGains: () => "Guadagni totali",
    totalLosses: () => "Perdite totali di capitale",
    totalLossesNoCGT: () => "Perdite totali realizzate",
    tradingPnL: () => "Trading PnL",
    tradingStockAu: {
      acquiredStockQuantity: () => "Quantità acquisita",
      acquiredTotalValue: () => "Valore acquisito",
      currency: () => "Valuta",
      disposedStockQuantity: () => "Quantità smaltita",
      disposedTotalValue: () => "Valore ceduto",
      endingPrice: () => "Prezzo finale",
      endingStock: () => "Stock finale",
      endingValue: () => "Valore finale",
      netProfit: () => "Utile netto",
      startingPrice: () => "Prezzo di partenza",
      startingStock: () => "Scorta iniziale",
      startingValue: () => "Valore iniziale",
    },
    txf: () => "TXF",
    ukFinancialYear: (d) => "6 aprile " + d.prevYear + " - 5 aprile " + d.year,
    unknownGains: () => "Guadagni sconosciuti",
    unknownGainsNoCGT: () => "Profitti sconosciuti",
    upgradeToPlanTooltip: (d) =>
      "Esegui l'upgrade al " + d.plan + " per accedere a questo report",
    viewAll: () => "Visualizza tutto",
    viewOtherReports: () => "Visualizza altri report",
    warning: () =>
      "Gli avvisi possono portare a calcoli errati. Assicurati di aver importato tutti i tuoi dati e di aver confermato le tue transazioni",
    warningActionText: () => "Esaminare le transazioni",
    warningTitle: () => "Hai avvisi di transazione in sospeso",
    warningZeroCost: {
      bodyText1: () => "Abbiamo rilevato problemi con le tue transazioni ",
      bodyText2: () =>
        "Una transazione a costo zero si verifica quando c'è una vendita ma non c'è alcun asset disponibile. Puoi ridurre le tue tasse risolvendo i problemi con gli asset mancanti. Puoi anche ignorare questo avviso. ",
      bodyValuePostNumber: () => ". ",
      bodyValuePreNumber: () => "totale ",
      breakdownPrefix: () =>
        "Principali valute con problemi irrisolti e asset presumibilmente venduti",
      buttonText: () => "Visualizza tutte le valute",
      includedInGains: () => "Non pagare più del dovuto!",
      innerBodyText: () =>
        "Abbiamo ipotizzato un costo pari a zero per le attività vendute",
      innerTitle: () => "Tutte le valute con problemi irrisolti",
      title: () =>
        "Sono necessarie revisioni: il guadagno realizzato potrebbe non essere accurato.",
      titleNoCGT: () =>
        "Sono necessarie revisioni: i profitti realizzati potrebbero essere imprecisi.",
    },
    warningZeroCostSummary: {
      body: (d) =>
        "Abbiamo ipotizzato un costo pari a zero per le attività vendute totale " +
        d.total +
        " . Puoi ridurre le tue tasse risolvendo i problemi con le attività mancanti.",
      title: () => "Revisione necessaria",
    },
    wentWrongDownloadingBGL: () =>
      "Si è verificato un errore durante l'esportazione del file XML BGL Simple Fund 360.",
    wentWrongDownloadingCSV: () =>
      "Si è verificato un errore durante il download del CSV.",
    wentWrongDownloadingPDF: () =>
      "Si è verificato un errore durante il download del PDF.",
    wentWrongDownloadingTXF: () =>
      "Si è verificato un errore durante il download del file TurboTax TXF.",
    wentWrongRefreshing: () =>
      "Si è verificato un errore durante l'aggiornamento del report.",
    xml: () => "Formato XML",
    ytd: () => "FY ad oggi",
  },
  reportError: {
    button: {
      display: () => "Mostra errori",
      hide: () => "Nascondi errori",
    },
    soldOut: () =>
      "vendere transazioni senza un saldo residuo sufficiente - base di costo zero presunta",
    title: () => "Controllo di sanità mentale",
    tooltip: {
      soldOut: () =>
        "Abbiamo ipotizzato una base di costo pari a zero dollari per queste transazioni. Ciò è causato dall'effettuazione di transazioni di vendita senza una quantità rimanente sufficiente. Se si tratta di un importo elevato, potrebbe essere causato da transazioni mancanti. Altrimenti, se si tratta di un importo ridotto, potrebbe essere causato da interessi di cambio, arrotondamento delle commissioni, ecc. Puoi filtrare per errori nella pagina di revisione delle transazioni.",
      uncategorized: () =>
        "Abbiamo ignorato queste transazioni quando abbiamo fatto i calcoli. Puoi cliccare sul link per categorizzare le transazioni in entrata/uscita.",
    },
    uncategorized: () =>
      "transazioni non categorizzate - queste transazioni sono state ignorate",
  },
  reset: () => "Reset",
  retry: () => "Riprova",
  rules: {
    addRule: () => "Aggiungi regola",
    categoryRuleWarning: () =>
      "Per creare una regola di categoria, assicurati che una delle tue condizioni sia che la categoria tx sia solo in entrata o in uscita",
    createRule: () => "Crea regola",
    deleteDialog: {
      text: {
        checkbox: () =>
          "Consenti ad altre regole di sovrascrivere le modifiche apportate in precedenza da questa regola",
        lineOne: () => "Questo lo farà",
        lineTwo: () =>
          "Si noti che le modifiche apportate da questa regola rimarranno in vigore a meno che non vengano modificate manualmente o tramite una nuova regola.",
        listOne: () =>
          "Scollega questa regola dalle transazioni a cui è stata applicata",
        listTwo: () =>
          "Interrompere l'applicazione della regola alle transazioni future",
      },
      title: () => "Elimina regola",
    },
    disableDialog: {
      button: () => "Disabilitare",
      text: {
        listOne: () =>
          "Ciò impedirà che la regola venga applicata alle transazioni future.",
        listThree: () =>
          "Puoi sempre riattivare questa regola in un secondo momento",
        listTwo: () =>
          "Le transazioni con questa regola attualmente applicata ne rimarranno influenzate. Ciò significa che le nuove regole con gli stessi criteri non sostituiranno questa regola.",
      },
      title: () => "Disabilita regola",
    },
    enableDialog: {
      button: () => "Abilitare",
      text: {
        listOne: () =>
          "Abilitando questa regola, questa verrà applicata a qualsiasi transazione che ne soddisfi le condizioni e a cui non sia già stata applicata una regola.",
      },
      title: () => "Abilita regola",
    },
    learnAboutRules: {
      button: () => "Scopri le regole",
      content: () =>
        "Le regole ti consentono di automatizzare sia la categorizzazione delle tue transazioni, sia la mappatura del piano dei conti per ogni transazione. Questo può essere utile quando hai molte azioni comuni che vorresti che CTC completasse per te!",
      content2list1: () =>
        "Le regole si applicano nell'ordine in cui appaiono nella tabella. Ciò significa che se hai due regole che potrebbero essere entrambe applicate a una transazione, quella più in alto nella tabella avrà sempre la priorità.",
      content2list2: () =>
        "Le regole non possono essere applicate parzialmente. Ciò significa che se hai una regola di priorità più alta che imposta solo l'account asset, quindi una regola di priorità più bassa che applica sia l'account asset che quello guadagni, solo la regola di priorità più alta verrà applicata a causa del primo punto, e poi la seconda non potrà essere applicata poiché l'account asset è già stato impostato da una regola.",
      content2list3: () =>
        "Le regole non possono essere sovrascritte una volta applicate. Ciò significa che se hai una regola esistente applicata a una transazione e poi crei una nuova regola che si applicherebbe anche a questa transazione e le dai una priorità più alta, non si applicherà comunque a questa transazione, a meno che tu non elimini la prima regola.",
      content2part1: () =>
        "Ci sono tre cose principali da ricordare quando si lavora con le regole:",
      content2part2: () =>
        "Ricordando questi principi puoi padroneggiare le regole e velocizzare i tuoi flussi di lavoro!",
      content3: () =>
        "Puoi creare regole direttamente da questa pagina, o dalle pagine della rubrica o delle integrazioni per le regole che si riferiscono a specifici wallet e exchange, ma puoi anche creare regole nel contesto della pagina delle transazioni! Quando categorizzi le transazioni o definisci il piano dei conti per una transazione, vedrai l'opzione per applicare questa scelta alle transazioni future. Se selezionata, questa creerà una regola basata sulla tua scelta e un criterio che identifica transazioni simili a quella su cui stai lavorando al momento, e verrà automaticamente aggiunta in fondo alla gerarchia nella pagina delle regole!",
      subtitle1: () => "Cosa sono le regole?",
      subtitle2: () => "Come funzionano le regole?",
      subtitle3: () => "Da dove inizio con le regole?",
      title: () => "Scopri le regole",
    },
    navTabs: {
      rules: () => "Regole attive",
      rulesDisabled: () => "Regole disabilitate",
    },
    operationShorthands: {
      changeErpAssetAccount: () => "Risorsa",
      changeErpCashAccount: () => "Contanti",
      changeErpGainsAccount: () => "Guadagni",
      changeErpLoanAccount: () => "Prestito",
      changeErpPnLAccount: () => "Profitti e perdite",
      recategorise: () => "Categoria",
    },
    operatorSelection: {
      accountPlaceholder: () => "Seleziona un account",
      categoryPlaceholder: () => "Seleziona una categoria",
    },
    pageTitle: () => "Regole",
    rulesTable: {
      empty: () => "Nessuna regola",
      headers: {
        accountsMapping: () => "Mappatura degli account",
        conditions: () => "Condizioni",
        name: () => "Nome",
      },
      loadMore: () => "Carica altro",
      row: {
        options: {
          delete: () => "Elimina regola",
          deleteInfo: () =>
            "La regola verrà eliminata e le transazioni a cui è stata applicata la regola potranno essere libere di applicare nuove regole.",
          disable: () => "Disabilita regola",
          disableInfo: () =>
            "La regola non verrà applicata alle nuove transazioni. Tuttavia, le transazioni esistenti continueranno a essere disciplinate da questa regola.",
          edit: () => "Modificare",
          enable: () => "Abilita regola",
          moveToBottom: () => "Sposta in basso",
          moveToTop: () => "Sposta in alto",
          viewRule: () => "Visualizza la regola",
          viewTxs: () => "Visualizza le transazioni",
        },
      },
    },
  },
  save: () => "Salva",
  selectCountry: {
    autoDetectedCountry: {
      applyLatestRules: (d) =>
        "Applicheremo le ultime norme fiscali " + d.authority + ".",
      countryDetected: (d) =>
        "Sembra che ti trovi in " +
        select(d.prefixCountryWithThe, { true: " the ", other: "" }) +
        d.countryName +
        ".",
      plusMore: (d) => "+ " + d.count + " altro",
      selectAnotherCountry: () => "Oppure seleziona un altro paese",
      submitCTA: () => "Andiamo",
    },
    body: () =>
      "Il tuo Paese è tenuto a determinare le norme fiscali per questa calcolatrice",
    continue: () => "Continuare",
    loadingOption: () => "Opzioni di caricamento...",
    noOptions: () => "Nessuna opzione",
    noResults: () => "Nessun risultato",
    optimizeTaxSettings: {
      AT: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti del BMF.",
      AU: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'ATO.",
      BE: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'FPSF.",
      CA: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti della CRA.",
      CH: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'SFTA.",
      DE: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti del BZSt.",
      ES: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'AEAT.",
      FI: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'FTA.",
      FR: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti della DGFIP.",
      GR: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'IAPR.",
      IE: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti della TRC.",
      IN: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'ITR.",
      IT: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'ADE.",
      JP: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti della NTA.",
      NL: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti del DTCA.",
      NO: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti della NTA.",
      NZ: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'IRD.",
      PT: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti del PTCA.",
      SE: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti della STA.",
      SG: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'IRAS.",
      UK: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'HMRC.",
      US: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti dell'IRS.",
      ZA: () =>
        "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti della SARS.",
    },
    optimizeTaxSettingsGeneric: (d) =>
      "Ottimizzeremo le tue impostazioni fiscali per soddisfare i requisiti di " +
      d.taxAuthority +
      ".",
    pending: () => "In attesa di",
    reportForms: {
      ATO: () => "ATO",
      Form8949: () => "Modulo 8949",
      HMRC: () => "HM Revenue and Customs",
      IRS: () => "Agenzia delle Entrate",
      adjustedCostBase: () => "Base di costo rettificata",
      averageCostBase: () => "Base di costo media",
      bedAndBreakfast: () => "Pernottamento e colazione di 30 giorni",
      capitalGainsReport: () => "Rapporto sulle plusvalenze",
      cerfa2086: () => "Modulo n°2086",
      cra: () => "CRA",
      incomeReport: () => "Rapporto sui redditi",
      myTax: () => "Rapporto ATO myTax",
      reports: () => "Rapporti fiscali",
      sameDay: () => "Regola dello stesso giorno",
      schedule3: () => "Allegato 3",
      scheduleD: () => "Allegato D 1040",
      superficialLoss: () => "Regola della perdita superficiale",
      t1Income: () => "Reddito T1",
      turboTaxOnline: () => "TurboTaxOnline",
    },
    title: () => "Seleziona Paese",
    title2: () => "Seleziona il paese",
  },
  settings: {
    accounting: {
      dataManagement: () => "Gestione dei dati",
      inviteLink: {
        body: () =>
          "Utilizzando questo link, i clienti saranno indirizzati a creare un account con CTC. I loro dati saranno automaticamente condivisi con te.",
        bodyWide: () =>
          "Condividi questo link con i tuoi clienti, saranno indirizzati a creare un account con CTC. I loro dati saranno automaticamente condivisi con te.",
        copy: () => "Copia",
        customiseLink: () => "Personalizza collegamento",
        generatingCode: () => "Generazione del codice...",
        new: () => "Nuovo",
        title: () => "Link di invito personale",
        titleCollaborator: () => "Link di invito per commercialista",
        titleWide: () => "Il tuo link di invito personale per i clienti ",
        titleWideCollaborator: () =>
          "Link di invito al cliente del tuo commercialista",
      },
      profile: () => "Impostazioni del contabile",
      title: () => "Contabilità",
    },
    accountingData: {
      updateDataSourceError: () =>
        "Si è verificato un errore durante l'aggiornamento della fonte dati. Riprova più tardi.",
      updateDataSourceSuccess: () => "Fonte dati aggiornata correttamente",
    },
    billing: {
      description: {
        part1: () => "Gestisci il tuo abbonamento. Visualizza ",
        part2: () => "termini di servizio.",
      },
      manage: () => "Gestisci abbonamento",
      nextBilling: () => "Prossima data di fatturazione",
      nextBillingDetails: (d) => d.date + " per " + d.amount + " " + d.currency,
      perYear: () => "/anno",
      title: () => "Dettagli dell'abbonamento",
      upgrade: () => "Aggiorna abbonamento",
    },
    clearData: {
      buttonText: () => "Cancella dati",
      description: (d) =>
        select(d.isAccountant, {
          true: " Cancella tutti i dati del cliente dall'account, incluse le transazioni e le origini di importazione. Questa azione non è reversibile. ",
          other:
            " Cancella tutti i dati dal tuo account. Questa azione non è reversibile. ",
        }),
      text: (d) =>
        select(d.isAccountant, {
          true: " Attenzione! Vuoi davvero eliminare tutti i dati del tuo cliente? Questa azione non può essere annullata. ",
          other:
            " Attenzione! Vuoi davvero eliminare tutti i tuoi dati? Questa azione non può essere annullata. ",
        }),
      titleClient: (d) => "Cancella i dati per " + d.clientName,
      titleClientFallback: () => "Cancella i dati del cliente",
      titleSelf: () => "Cancella dati",
    },
    clientAccount: () => "Profilo del cliente",
    clientData: {
      dataBody: () => "Fonte di dati condivisa tra te e il tuo cliente.",
      invitation: {
        status: {
          accepted: () => "Invito accettato",
          declined: () => "Invito rifiutato",
          default: () => "Invita un commercialista",
          pending: (d) => "Invito " + d.direction,
          revoked: () => "Invito revocato",
        },
        verboseStatus: {
          accepted: () => "Il cliente ha accettato il tuo invito",
          declined: () => "Sembra che il tuo invito sia stato rifiutato",
          deleted: () => "Sembra che il tuo invito sia stato eliminato",
          pending: () =>
            "Invito inviato. Riceverai un'e-mail quando il tuo cliente avrà accettato l'invito",
          revoked: () => "Il cliente ha revocato il tuo invito",
          revokedLink: () => "Il cliente ha revocato il tuo invito",
        },
      },
      invitationBody: () =>
        "Aggiungi l'email del tuo cliente per invitarlo a condividere e gestire i dati. Dopo che il tuo cliente ha accettato l'invito, non potrai più cambiare il suo indirizzo email.",
      invitationStatus: () => "Stato dell'invito",
      paymentPlan: () => "Piano di pagamento",
      profile: () => "Dettagli del cliente",
      tooltip: () =>
        "Questa impostazione può essere modificata solo dal cliente",
    },
    dataCleared: () => "Dati cancellati",
    exitSurvey: {
      cancellationReasonQuestion: (d) =>
        select(d.variation, {
          DeleteAccount:
            " Prima di eliminare il tuo account, qual è il motivo principale per cui hai annullato il servizio? ",
          other:
            " Prima di continuare, qual è stato il motivo principale per cui hai annullato il tuo piano? ",
        }),
      cancellationReasons: {
        accountant: () => "Utilizzare un contabile",
        countrySupport: () => "Paese non supportato",
        hardToUse: () => "Difficile da usare",
        importSupport: () => "Exchange o blockchain non supportati",
        incorrectResults: () => "Risultati non corretti",
        noLongerTradingCrypto: () => "Non faccio più trading di criptovalute",
        timeConsuming: () => "Richiede tempo",
        tooExpensive: () => "Troppo costoso",
      },
      continue: () => "Continuare",
      otherCommentsQuestion: () =>
        "C'è qualcos'altro che vorresti farci sapere?",
      title: (d) =>
        select(d.variation, {
          DeleteAccount: " Elimina il tuo account ",
          other: " Piano annullato ",
        }),
    },
    hideSpamError: () =>
      "Si è verificato un errore durante la modifica delle impostazioni del filtro, riprova più tardi",
    hmrc: {
      actionText: () => "Riconosciuto",
      text: () =>
        "I calcoli legacy dell'HM Revenue and Customs devono essere utilizzati solo come riferimento, si prega di utilizzare il metodo aggiornato per presentare le tasse",
      title: () =>
        "Conferma di voler visualizzare i calcoli legacy di HM Revenue and Customs",
    },
    lockPeriod: {
      add: {
        firstTransaction: () => "La tua prima transazione",
        from: () => "Da",
        to: () => "A",
      },
      addButton: () => "Aggiungi periodo bloccato",
      bannerText: () =>
        "Si consiglia di bloccare i dati solo per i periodi precedenti già inviati all'ufficio delle imposte.",
      beginningOfTime: () => "Inizio",
      cancel: () => "Cancellare",
      currentPeriod: {
        tooltip: () =>
          "Fare clic sul pulsante Aggiungi periodo di blocco sopra per creare un nuovo periodo di blocco",
      },
      description: () =>
        "Il blocco di un periodo impedirà che si verifichino modifiche alle transazioni entro quel periodo. Lo scopo di questo strumento è di consentirti di garantire che i tuoi calcoli fiscali per gli anni fiscali passati non cambino una volta inviati i tuoi report fiscali.",
      dialog: {
        action: () => "Periodo di sblocco",
        checklist: {
          balances: () => "Saldi da cambiare",
          gains: () => "Guadagni calcolati per cambiare",
          reports: () => "Segnalazioni di modifica",
        },
        confirm: () =>
          "Conferma di riconoscere i rischi sopra indicati selezionando le caselle di controllo prima di continuare",
        text: () => "Lo sblocco di questo periodo potrebbe causare:",
        title: () => "Periodo di sblocco?",
        warning: () => "Attenzione: questa operazione non può essere annullata",
        warningParagraph: () =>
          "Per conservare una copia dei valori correnti del tuo rendiconto, ti consigliamo di scaricare i tuoi rendiconti fiscali prima di sbloccare l'anno fiscale, poiché i valori potrebbero cambiare.",
        warningWithSubsequent: () =>
          "Attenzione: anche tutti i periodi successivi verranno sbloccati. Questa operazione non può essere annullata",
      },
      disabledCreateLock: () => "Sei bloccato fino ad oggi",
      inventoryMethodDisabled: (d) =>
        "Non è possibile aggiungere periodi bloccati quando si utilizza il metodo di inventario " +
        d.inventoryMethod,
      inventorySnapshots: {
        closing: {
          description: () =>
            "Un'istantanea dei lotti che compongono il tuo inventario alla fine del periodo, prima che vengano applicate eventuali ridistribuzioni per il periodo successivo.",
          title: () => "Istantanea dell'inventario di chiusura",
        },
        opening: {
          description: () =>
            "Un'istantanea dei lotti che compongono il tuo inventario all'inizio del periodo, dopo che sono state applicate tutte le ridistribuzioni necessarie.",
          title: () => "Apertura dell'istantanea dell'inventario",
        },
        title: () => "Istantanee dell'inventario",
      },
      issuesModal: {
        cancel: () => "Cancellare",
        currentIssues: () => "Problemi attuali",
        description: () =>
          "Per garantire l'accuratezza di alcuni elementi, è necessario prestare attenzione prima di bloccare questo periodo.",
        ignoreAndLock: () => "Ignora e blocca comunque",
        newIssueExplainer: () =>
          "Poiché ti stai preparando a bloccare questo periodo, abbiamo segnalato i trasferimenti non abbinati che devono essere risolti per bloccare correttamente la tua base di costo.",
        title: () => "Problemi da affrontare prima del blocco",
        viewIssues: () => "Visualizza e risolvi i problemi",
        warning: () =>
          "Se esegui il blocco ora senza risolvere questi problemi, potresti incorrere in problemi di dati irrisolvibili e potresti essere costretto a sbloccare questo periodo in un secondo momento.",
      },
      lockPeriodTooltip: () => "Periodo di blocco",
      lockUnlockedWarning: {
        action: () => "Blocca comunque",
        line1: () =>
          "Assicurati di aver riconciliato tutte le transazioni prima di bloccare. Non farlo potrebbe causare problemi futuri.",
        line2: () =>
          "Puoi sempre sbloccare questi periodi in seguito, ma le modifiche alle impostazioni fiscali non verranno conservate",
        title: () => "Conferma periodi di blocco",
        warning: () =>
          "Attenzione: la creazione di un nuovo periodo bloccato bloccherà anche tutti i periodi sbloccati",
        warningLockExisting: () =>
          "Attenzione: il blocco di questo periodo bloccherà anche tutti i periodi sbloccati in precedenza",
      },
      noLockPeriods: () => "Non hai periodi bloccati",
      now: () => "Ora",
      saveButton: () => "Salva il periodo bloccato",
      title: () => "Periodi di blocco",
      unlockAllSubsequentButtonTooltip: () =>
        "Saranno sbloccati anche tutti i periodi successivi a questo",
      unlockButtonTooltip: () => "Periodo di sblocco",
    },
    longTermThreshold: {
      custom: () => "Costume",
      default: (d) => "Predefinito ( " + d.defaultValue + " + mesi)",
      longTermThreshold: () => "Soglia a lungo termine",
      months: () => "mesi",
      never: () => "Mai",
    },
    manageData: {
      buttonText: () => "Elimina account",
      clear: () => "Chiaro",
      confirmClearData: () => "cancella-dati-per-sempre",
      confirmClearDataPrompt: () =>
        "Digita 'clear-data-forever' per procedere alla cancellazione dei dati di questo account.",
      confirmDelete: () => "elimina-questo-account-per-sempre",
      confirmDeletePrompt: () =>
        "Digita 'delete-this-account-forever' per procedere all'eliminazione di questo account.",
      delete: () => "Eliminare",
      description: (d) =>
        select(d.isAccountant, {
          true: " Eliminando l'account del cliente, verranno rimossi sia i dati che il cliente. Questa azione non è reversibile. ",
          other:
            " Eliminando definitivamente il tuo account, tutti i tuoi dati verranno rimossi dal nostro sistema e verrai disconnesso. ",
        }),
      manageData: () => "Gestire i dati",
      text: (d) =>
        select(d.isAccountant, {
          true: " Attenzione! Verrai disconnesso dai dati del tuo cliente e questi potrebbero essere eliminati. Il suo abbonamento, se ne hai pagato uno, verrà annullato. Non c'è modo di annullare questa azione. ",
          other:
            " Attenzione! Tutti i tuoi dati verranno eliminati. Una volta completato, verrai disconnesso e il tuo account verrà rimosso dal nostro sistema. Non c'è modo di annullare questa azione. ",
        }),
      titleClient: (d) => "Elimina " + d.clientName + " come cliente",
      titleClientFallback: () => "Elimina cliente",
      titleSelf: () => "Elimina account",
    },
    notifications: {
      errors: {
        generic: () =>
          "Impossibile aggiornare le impostazioni di notifica. Contatta il supporto.",
      },
    },
    pageTitle: (d) => d.tab + " impostazioni",
    plan: {
      clientOwned: {
        body: () =>
          "Se desideri accedere a funzionalità bloccate o a un numero maggiore di transazioni, contatta direttamente il cliente e chiedigli di aggiornare il suo account.",
        title: () => "Questo cliente gestisce la propria fatturazione",
      },
      title: () => "Piani",
    },
    portfolio: () => "Portfolio",
    portfolioTab: {
      assetTracking: () => "Monitoraggio delle risorse",
    },
    priceWindow: {
      off: () =>
        "Non verrà utilizzata alcuna finestra di adeguamento dei prezzi per determinare i prezzi di mercato",
      on: (d) =>
        "Quando non esiste un prezzo di mercato esatto per una criptovaluta, utilizzare il prezzo disponibile più vicino entro un intervallo di tempo di " +
        d.value +
        " ore",
      title: () => "Finestra di ribasso dei prezzi",
    },
    privacy: {
      part1: () => "Non condividiamo i tuoi dati. Leggi il nostro ",
      part2: () => "politica sulla riservatezza",
    },
    profile: {
      addEmail: () => "Aggiungi email",
      addInviteCode: () => "Aggiungi il tuo codice invito",
      addName: () => "Aggiungi il tuo nome",
      changeEmail: () => "Cambia email",
      changeInviteCode: () => "Cambia il tuo codice di invito",
      changeName: () => "Cambia nome",
      country: () => "Paese",
      debug: {
        title: () => "Supporto",
        uid: {
          notification: () => "Copiato UID negli appunti",
          title: () => "ID utente",
        },
      },
      duplicateError: () => "Questo codice è già in uso",
      edit: () => "Modificare",
      email: () => "E-mail",
      emailAddressChanged: () => "L'indirizzo email è cambiato",
      emailError: () => "Errore e-mail",
      emailSent: () => "E-mail inviata",
      experience: {
        smoothScrolling: {
          title: () => "Scorrimento fluido (solo questo browser)",
          tooltipOff: () =>
            "Lo scorrimento fluido sulla pagina Transazioni è disabilitato per questo browser",
          tooltipOn: () =>
            "Per questo browser è abilitato lo scorrimento fluido sulla pagina Transazioni",
        },
        title: () => "Esperienza",
      },
      inviteCode: () => "Codice invito",
      inviteCodeChanged: () => "Codice invito modificato",
      lengthError: () => "Il codice deve essere lungo da 4 a 24 caratteri.",
      linkedClientsEmail: () => "E-mail del cliente",
      linkedClientsName: () => "Nome del cliente",
      localCurrency: () => "Valuta locale",
      logout: () => "Esci",
      name: () => "Nome",
      nameChanged: () => "Nome cambiato",
      nameToolTip: () =>
        "Se fornito, questo nome verrà visualizzato nei report generati",
      noLinkedClient: () => "Nessun client collegato",
      notConnected: () => "Non connesso",
      oauth: {
        areYouSure: (d) => "Vuoi davvero disconnettere " + d.oauth + " OAuth?",
        cancel: () => "Cancellare",
        connected: () => "Collegato",
        disconnect: () => "Disconnettersi",
        error: () =>
          "Impossibile aggiornare le impostazioni OAuth. Riprova più tardi.",
        success: () => "Disconnesso da OAuth con successo",
        title: (d) => d.oauth + " Autorizzazione OAuth",
        walletOauth: () => "Connesso tramite portafoglio",
        warning: (d) =>
          "La disconnessione di OAuth non può essere annullata. Se disconnesso, dovrai effettuare l'accesso utilizzando la tua email ( " +
          d.email +
          " ) e la tua password.",
      },
      password: {
        accountPassword: () => "Password dell'account",
        changePassword: () => "Cambiare la password",
        confirmNewPassword: () => "Riscrivi la nuova password",
        dontMatch: () => "Le password non corrispondono",
        newPassword: () => "Nuova password",
        oldPassword: () => "Vecchia password",
        resetPassword: () => "Reimposta password",
        saveNewPassword: () => "Salva la nuova password",
      },
      resendVerificationEmail: () => "Invia di nuovo l'email",
      title: () => "Profilo",
      unverified: () => "Non verificato",
      validationError: () => "Inserisci un codice di invito URL-safe valido",
      verified: () => "Verificato",
      verifiedBanner: () => "La tua email è stata verificata",
      verifyEmail: () => "Verifica email",
      wentWrongUpdatingEmail: () =>
        "Si è verificato un errore durante l'aggiornamento dell'email",
      wentWrongUpdatingInviteCode: () =>
        "Si è verificato un errore durante l'aggiornamento del codice di invito",
      wentWrongUpdatingName: () =>
        "Qualcosa è andato storto durante l'aggiornamento del nome",
    },
    report: {
      airdropsAsIncome: {
        title: () => "Considera gli airdrop come entrate",
        tooltip: {
          off: () =>
            "Gli airdrop sono attualmente trattati come acquisizioni con una base di costo pari a zero",
          on: () => "Gli airdrop sono attualmente considerati entrate",
        },
      },
      allowBridgeFromUncategorised: {
        title: () => "Identificare automaticamente le transazioni bridge",
        tooltip: {
          off: () =>
            "Le transazioni bridge non vengono identificate automaticamente dalle transazioni non categorizzate",
          on: () =>
            "Le transazioni bridge vengono identificate automaticamente dalle transazioni non categorizzate",
        },
      },
      allowGenerateFeeOnGroupedQuantityDiff: {
        title: () =>
          "Rilevamento automatico delle commissioni per i trasferimenti",
        tooltip: {
          off: () =>
            "Non genera automaticamente una commissione se c'è una discrepanza tra gli importi inviati e ricevuti per trasferimenti/ponti raggruppati",
          on: () =>
            "Genera automaticamente una commissione se c'è una discrepanza tra gli importi inviati e ricevuti per trasferimenti/ponti raggruppati",
        },
      },
      assumeTransferCounterparts: {
        title: () =>
          "Genera automaticamente le controparti dei trasferimenti non abbinati",
        tooltip: {
          off: () =>
            "CTC non tenterà di generare la controparte di trasferimenti non abbinati",
          on: () =>
            "CTC tenterà di generare la controparte dei trasferimenti non abbinati quando potrà farlo in sicurezza",
        },
      },
      bridgeAsNonTaxable: {
        title: () => "Trattare i ponti come cessione non imponibile",
        tooltip: {
          off: () =>
            "I ponti sono attualmente trattati come cessioni e acquisizioni imponibili",
          on: () =>
            "I ponti sono attualmente trattati come trasferimenti di base di costo",
        },
      },
      collateralAsNonTaxable: {
        title: () =>
          "Trattare i depositi e i prelievi collaterali come non imponibili",
        tooltip: {
          off: () =>
            "I depositi e i prelievi collaterali sono attualmente trattati come cessioni e acquisizioni imponibili",
          on: () =>
            "I depositi e i prelievi collaterali sono attualmente trattati come trasferimenti",
        },
      },
      cryptoToCryptoNonTaxable: {
        title: () =>
          "Trattare le transazioni tra criptovalute come cessioni non imponibili",
        tooltip: {
          nftToCryptoTaxable: () =>
            "Le transazioni NFT in criptovalute rimarranno cessioni e acquisizioni imponibili",
          off: () =>
            "Le transazioni tra criptovalute sono attualmente trattate come cessioni e acquisizioni imponibili",
          on: () =>
            "Le transazioni tra criptovalute sono attualmente trattate come trasferimenti di base di costo",
          onWithDate: (d) =>
            "Le transazioni da criptovaluta a criptovaluta a partire dal " +
            d.date +
            " in poi sono trattate come trasferimenti di base di costo",
        },
      },
      emailReports: {
        options: {
          off: {
            description: () =>
              "Quando scarichiamo i report, non li invieremo mai via email.",
            title: () => "Non inviare mai report via email",
          },
          on: {
            description: () =>
              "Quando scarichi i report, ti invieremo anche un link per scaricare il report. Questo link sarà valido per 1 giorno.",
            title: () => "Invia sempre i report via email",
          },
        },
        title: () => "Rapporti via e-mail",
        wentWrong: () =>
          "Si è verificato un errore durante l'aggiornamento delle impostazioni del rapporto e-mail.",
      },
      holdingsBalanceType: {
        title: () => "Utilizzare i saldi segnalati sul portafoglio",
        tooltip: {
          off: () =>
            "Utilizzare il saldo calcolato determinato dall'algoritmo del motore fiscale",
          on: () => "Utilizzare il saldo riportato fornito dall'integrazione",
        },
      },
      ignoreBalanceRemainingValueThreshold: {
        subtext: () =>
          "Ignoriamo i problemi di saldo rimanente al di sotto di una certa soglia fiat a causa di problemi di arrotondamento delle cifre decimali. Ciò è solitamente causato da dati di transazione di cambio che sono stati arrotondati per eccesso. Puoi modificare questa soglia di seguito.",
        title: () => "Regola la precisione dell'equilibrio",
      },
      ignoreFiat: {
        title: () => "Ignora i guadagni sulle conversioni forex",
        tooltip: {
          off: () =>
            "I guadagni sulle conversioni forex sono attualmente considerati",
          on: () =>
            "I guadagni sulle conversioni forex sono attualmente ignorati",
        },
      },
      ignoreFiatLabel: () => "Ignora Fiat",
      irs2025Warning: {
        chip: () =>
          "Informazioni sulle imminenti modifiche alle norme dell'IRS",
        content: {
          body1: () =>
            "Non preoccuparti: la tua dichiarazione dei redditi per l'anno fiscale 2024 non sarà influenzata da queste modifiche e puoi continuare a utilizzare il metodo dell'inventario universale, se preferisci.",
          body2: () =>
            "Queste modifiche si applicano solo alle transazioni in criptovaluta a partire dal 1° gennaio 2025. Dovrai utilizzare il metodo per portafoglio per la tua dichiarazione dei redditi FY25, che presenterai nel 2026.",
          header1: () =>
            "Cosa significa questo per la tua dichiarazione dei redditi dell'anno fiscale 2024?",
          header2: () =>
            "Quando è necessario passare al monitoraggio per portafoglio?",
          summary: () =>
            "A partire dal 1° gennaio 2025, l'IRS richiederà l'utilizzo di un metodo basato sul portafoglio (noto anche come \"ID specifico per portafoglio ed exchange\") per tracciare la base di costo delle tue criptovalute.",
        },
        learnMore: () => "Saperne di più",
      },
      lostStolenAsNonTaxable: {
        title: () =>
          "Trattare le transazioni perse o rubate come cessioni non imponibili",
        tooltip: {
          off: () =>
            "Le transazioni perse o rubate sono attualmente trattate come cessioni imponibili",
          on: () =>
            "Le transazioni perse o rubate sono attualmente trattate come cessioni non imponibili",
        },
      },
      miningAsIncome: {
        title: () => "Considera l'attività mineraria come un reddito",
        tooltip: {
          off: () =>
            "Le transazioni minerarie sono attualmente trattate come acquisizioni con una base di costo pari a zero",
          on: () =>
            "Le transazioni minerarie sono attualmente trattate come reddito",
        },
      },
      outgoingGiftAsNonTaxable: {
        title: () =>
          "Considerare il regalo in uscita come una cessione non imponibile",
        tooltip: {
          off: () =>
            "Le transazioni di donazione in uscita sono attualmente trattate come cessioni imponibili",
          on: () =>
            "Le transazioni di donazione in uscita sono attualmente trattate come cessioni non imponibili",
        },
      },
      personalUseAsNonTaxable: {
        title: () =>
          "Considerare l'uso personale come uno smaltimento non imponibile",
        tooltip: {
          off: () =>
            "Le transazioni per uso personale sono attualmente trattate come cessioni imponibili",
          on: () =>
            "Le transazioni per uso personale sono attualmente trattate come cessioni non imponibili",
        },
      },
      report: () => "Rapporto",
      royaltiesAsIncome: {
        title: () => "Trattare le royalties come reddito",
        tooltip: {
          off: () =>
            "Le transazioni di royalty sono attualmente trattate come acquisizioni con una base di costo pari a zero",
          on: () =>
            "Le transazioni di royalty sono attualmente trattate come reddito",
        },
      },
      showAllAccountsOnPortfolio: {
        title: () => "Mostra tutti i conti nel portafoglio",
        tooltip: {
          off: () =>
            "Nel portafoglio vengono mostrati solo gli account importati",
          on: () =>
            "Se disattivato, nel portafoglio vengono visualizzati solo gli account importati. Se attivato, le partecipazioni del portafoglio includeranno anche account sospetti, ovvero wallet ed exchange con cui si è interagito ma che non sono stati importati. Nota: questo interruttore influisce solo sulla visualizzazione del portafoglio, non sui calcoli delle imposte.",
        },
      },
      showFiatHoldings: {
        title: () =>
          "Mostra le partecipazioni fiat native nei report e nella dashboard",
        tooltip: {
          off: () =>
            "Le partecipazioni Fiat non sono attualmente visualizzate nei report e nel cruscotto",
          on: () =>
            "Le partecipazioni Fiat sono attualmente visualizzate nei report e sul cruscotto",
        },
      },
      specificInventoryByExchange: {
        disabledReason: () =>
          "Non consentito sul metodo di inventario corrente",
        options: {
          mixed: {
            description: () =>
              "Durante questo periodo vengono utilizzati sia il monitoraggio universale che quello basato sui costi per portafoglio e cambio.",
            title: () => "Impostazioni miste",
          },
          off: {
            description: () =>
              "La base di costo verrà monitorata universalmente su tutti i portafogli e gli exchange.",
            title: () => "Universale",
          },
          on: {
            description: () =>
              "La base di costo è tracciata da wallet ed exchange. Ciò significa che potrebbero esserci più basi di costo per un asset in base al prezzo di acquisto in cui è stata acquistata la valuta.",
            title: () => "Tramite portafoglio e cambio",
          },
        },
        title: () => "Monitoraggio della base di costo",
      },
      splitFeesEvenlyAcrossGroupedMints: {
        title: () =>
          "Dividere le commissioni in modo uniforme tra le transazioni di zecca raggruppate",
        tooltip: {
          off: () =>
            "Le transazioni di conio raggruppate attualmente non prevedono commissioni divise equamente tra tutti gli asset coniati",
          on: () =>
            "Le transazioni di zecca raggruppate attualmente hanno commissioni divise equamente tra tutti i beni coniati",
        },
      },
      stakingAsIncome: {
        title: () => "Considera le ricompense dello staking come entrate",
        tooltip: {
          off: () =>
            "Le ricompense dello staking sono attualmente trattate come acquisizioni con una base di costo pari a zero",
          on: () =>
            "Le ricompense dello staking sono attualmente considerate come entrate",
        },
      },
      stakingDepositWithdrawalNonTaxable: {
        title: () =>
          "Trattare i depositi e i prelievi tramite staking come non imponibili",
        tooltip: {
          off: () =>
            "I depositi e i prelievi tramite staking sono attualmente trattati come cessioni e acquisizioni imponibili",
          on: () =>
            "I depositi e i prelievi tramite staking sono attualmente trattati come trasferimenti",
        },
      },
      taxSettings: () => "Impostazioni fiscali",
      title: () => "Impostazioni del report",
      treatLiquidityAddRemovalNonTaxable: {
        title: () => "Trattare l'aggiunta di liquidità come non imponibile",
        tooltip: {
          off: () =>
            "Le transazioni di liquidità sono attualmente trattate come cessioni e acquisizioni imponibili",
          on: () =>
            "Le transazioni di liquidità sono attualmente trattate come trasferimenti di base imponibile",
        },
      },
      uncategorisedInAsTaxable: {
        title: () =>
          "Tratta le transazioni in entrata non categorizzate come acquisti",
        tooltip: {
          off: () =>
            "Le transazioni in entrata non categorizzate sono attualmente trattate come trasferimenti non imponibili",
          on: () =>
            "Le transazioni in entrata non categorizzate sono attualmente trattate come acquisti",
        },
      },
      uncategorisedOutAsTaxable: {
        title: () =>
          "Tratta le transazioni in uscita non categorizzate come vendite",
        tooltip: {
          off: () =>
            "Le transazioni in uscita non categorizzate sono attualmente trattate come trasferimenti non imponibili",
          on: () =>
            "Le transazioni in uscita non categorizzate sono attualmente trattate come vendite",
        },
      },
      updatedTaxSettingsError: () =>
        "Si è verificato un errore durante l'aggiornamento delle impostazioni fiscali. Riprova più tardi.",
      updatedTaxSettingsSuccess: () =>
        "Impostazioni fiscali aggiornate correttamente",
    },
    reportActionButton: {
      action: () => "Aggiorna i calcoli",
      loadingAction: () => "Rinfrescante",
      loadingTooltip: () => "Potrebbe volerci del tempo",
      tooltip: () => "Attiva manualmente un aggiornamento di tutti i calcoli",
    },
    reportSettings: () => "Impostazioni del report",
    sidebar: {
      loading: () => "Caricamento...",
      searchClients: () => "Cerca clienti",
    },
    somethingWentWrong: () => "Qualcosa è andato storto! Contatta il supporto",
    somethingWentWrongContact: () =>
      "Qualcosa è andato storto! Se il problema persiste, contatta l'assistenza.",
    supportAccess: {
      body: {
        accessGrantedAt: (d) => "Accesso concesso: " + d.accessGrantedAt,
        button: {
          grantAccess: () => "Concedi l'accesso",
          revokeAccess: () => "Revoca l'accesso",
        },
        description: () =>
          "Consenti al supporto di accedere ai dati del tuo account per aiutarti a risolvere i problemi. L'accesso sarà concesso per un massimo di 30 giorni.",
        title: () => "Accesso al supporto per la concessione",
      },
      title: () => "Supporto",
    },
    tabs: {
      account: () => "Account",
      alpha: () => "Alfa",
      billing: () => "Fatturazione",
      general: () => "Tassare",
      integrations: () => "Conti",
      "integrations-deprecated": () => "Integrazioni",
      loading: () => "Caricamento",
      "lock-period": () => "Periodi di blocco",
      permissions: () => "Permessi",
      plan: () => "Piano",
      portfolio: () => "Portfolio",
      team: () => "Squadra",
    },
    team: {
      header: {
        email: () => "E-mail",
        lastLogin: () => "Ultimo accesso",
        name: () => "Nome",
        search: () => "Ricerca",
      },
      invite: () => "Invitare",
      inviteTeamMember: () => "Invita un membro del team",
      inviteTooltip: (d) => "Invitato " + d.date,
      managementAndPermissions: () => "Gestione",
      noResults: () => "Nessun risultato",
      noTeamMembers: () => "Nessun membro del team",
      owner: () => "Proprietario",
    },
    threshold: () => "Soglia",
    timezone: () => "Fuso orario",
    title: () => "Impostazioni",
  },
  similarTransactionsSuggestion: {
    accept: () => "Confermare",
    applyToFuture: () => "Applicare alle transazioni future",
    categorizeItAs: () => "Categorizzare come:",
    deny: () => "Negare",
    modal: {
      buttonCTA: () => "Seleziona la categoria",
      receivedFrom: () => "Ricevuto da:",
      sentTo: () => "Inviato a:",
      similarTxs: () => "Transazioni simili:",
    },
    noSimilar: () => "Nessuna transazione simile trovata",
    notNow: () => "Non adesso",
    operators: {
      actionId: () => "ID azione",
      after: () => "Dopo",
      and: () => "E",
      before: () => "Prima",
      blockchain: () => "Catena di blocchi",
      category: () => "Categoria",
      comment: () => "Commento",
      comments: () => "Commenti",
      currency: () => "Valuta",
      date: () => "Data",
      description: () => "Descrizione",
      feeCurrency: () => "Valuta della commissione",
      from: () => "Fonte",
      hasRule: () => "Nascondi le transazioni non interessate dalle regole",
      id: () => "ID",
      importId: () => "ID di importazione",
      importType: () => "Tipo di importazione",
      isSmartContractInteraction: () => "Interazione",
      missingPrice: () => "Prezzo mancante",
      negativeBalance: () => "Cronologia degli acquisti mancante",
      nftCollection: () => "Collezione NFT",
      not: () => "Non",
      or: () => "O",
      party: () => "Festa",
      reconciliation: () => "Riconciliazione",
      reviewed: () => "Recensito",
      rule: () => "Regola",
      ruleOperator: () => "Operatore di regola",
      source: () => "Account",
      suggestion: () => "Suggerimento",
      sync: () => "Sincronizzazione",
      to: () => "Destinazione",
      trade: () => "Categoria",
      txCurrency: () => "Valuta Tx",
      txFunctionName: () => "Firma della transazione",
      txFunctionSignature: () => "ID metodo transazione",
      txId: () => "ID transazione",
      warning: () => "Avvertimento",
    },
    optOut: {
      button: () => "Visualizzazione",
      loadingSimilar: () => "Trovare transazioni simili",
      noSimilar: () => "Nessuna transazione simile",
      title: (d) =>
        "Applica questa modifica a [ " +
        plural(d.count, 0, it, {
          one: " 1 transazione simile ",
          other: number("it", d.count, 0) + " transazioni simili ",
        }) +
        " ]( " +
        d.linkTextKey +
        " )",
      tooltip: {
        criteriaFour: () => "Avere la stessa firma di transazione",
        criteriaOne: () => "Sono nella stessa direzione",
        criteriaThree: () => "Hanno la stessa valuta",
        criteriaTwo: () => "Sono da e verso gli stessi portafogli",
        title: () => "Transazioni simili sono quelle che:",
      },
    },
    similarTransactionsFound: (d) =>
      plural(d.count, 0, it, {
        one: " 1 Transazione simile ",
        other: number("it", d.count, 0) + " Transazioni simili ",
      }),
    successToast: (d) =>
      "Categorizzato " +
      d.count +
      " simile " +
      plural(d.count, 0, it, { one: " transazione ", other: " transazioni " }),
    viewTransactions: () => "Visualizza le transazioni",
    weHaveFound: (d) =>
      "Abbiamo trovato " +
      plural(d.count, 0, it, {
        one: " 1 transazione simile ",
        other: number("it", d.count, 0) + " transazioni simili ",
      }) +
      " . " +
      plural(d.count, 0, it, {
        one: " Questa transazione ha ",
        other: " Queste transazioni hanno ",
      }) +
      " lo stesso:",
  },
  skip: () => "Saltare",
  spam: () => "Contrassegna come spam",
  start: () => "Inizio",
  support: {
    contact: () => "Si prega di contattare il supporto per assistenza",
    deleteAccountDisabled: () =>
      "Questa funzionalità non è disponibile per il piano contabile. Contatta il supporto per ricevere assistenza.",
  },
  supportedLanguages: {
    de: () => "Tedesco",
    eng: () => "Inglese",
    es: () => "Spagnolo",
    fr: () => "Francese",
    it: () => "Italiano",
    xx: () => "Linguaggio di debug Xx",
    xxl: () => "Xx lungo",
    xxs: () => "Xx corto",
  },
  sync: {
    actionErpSyncFailed: (d) =>
      "Impossibile sincronizzare con " + d.erpProvider,
    apiStillSyncing: () =>
      "L'API è ancora in fase di sincronizzazione, attendere qualche minuto.",
    integrationSyncing: (d) => d.name + " si sta sincronizzando...",
    reportRefreshFailed: () => "I calcoli non sono riusciti ad aggiornare",
    syncFailed: (d) =>
      "Sincronizzazione per " +
      d.name +
      " non riuscita. Assicurati di seguire correttamente le istruzioni di importazione. Se il problema persiste, contatta l'assistenza.",
    syncOneHour: (d) =>
      "Sincronizzazione con l'API " +
      d.name +
      " . Questo processo può richiedere fino a 1 ora.",
    syncSeveralMinutes: (d) =>
      "Sincronizzazione con l'API " +
      d.name +
      " . Questo processo può richiedere diversi minuti.",
    syncingAPIs: (d) => "Sincronizzazione delle API " + d.name,
    syncingBlockchainWallets: (d) =>
      "Sincronizzazione dei portafogli " + d.name,
    syncingName: (d) => "Sincronizzazione " + d.name,
    uploadDeprecated: {
      coinbase: () =>
        "Stai utilizzando una chiave API Coinbase obsoleta, aggiorna la tua API secondo le istruzioni più recenti",
      generic: () =>
        "Stai utilizzando una chiave API obsoleta, aggiorna la tua API secondo le istruzioni più recenti",
    },
    uploadFail: (d) =>
      "Qualcosa durante l'importazione " +
      d.name +
      " . Se il problema persiste, contattare l'assistenza.",
    uploadingFiveMins: (d) =>
      "Caricamento dei dati " +
      d.name +
      " . Potrebbero volerci fino a 5 minuti. Puoi continuare a usare l'app durante questo periodo.",
    xeroSyncFailed: () => "Sincronizzazione Xero non riuscita",
  },
  syncErrorReasons: {
    "api-changed": {
      ctaType: () => "Per ulteriore assistenza, contattare l'assistenza.",
      message: () => "I dati ricevuti dallo scambio sono cambiati.",
    },
    "api-unavailable": {
      ctaType: () => "Riprova più tardi.",
      message: (d) =>
        "Il server API " + d.exchangeName + " non è al momento disponibile.",
    },
    "app-error": {
      ctaType: () => "Se il problema persiste, contattare l'assistenza.",
      message: () => "Si è verificato un errore imprevisto nell'applicazione.",
    },
    "csv-invalid-column": {
      ctaType: () => "Controllare il file e riprovare.",
      message: () => "Il file CSV contiene una colonna non valida.",
    },
    "csv-not-required": {
      ctaType: () => "Assicurati di aver caricato il file giusto.",
      message: () => "Per questa integrazione non è necessario il file CSV.",
    },
    "csv-not-supported": {
      ctaType: () => "Assicurati di aver caricato il file giusto.",
      message: () => "Il file CSV non è supportato per questa integrazione.",
    },
    "expired-api-key": {
      ctaType: () =>
        "Genera una nuova chiave seguendo passo dopo passo le istruzioni di integrazione.",
      message: () => "Chiave API scaduta.",
    },
    "invalid-api-key": {
      ctaType: () => "Controllare le istruzioni e riprovare.",
      message: () => "Chiave API non valida.",
    },
    "invalid-csv": {
      ctaType: () => "Controllare il file e il contenuto.",
      message: () => "Formato CSV non valido.",
    },
    "invalid-csv-headers": {
      ctaType: () => "Controlla il formato e riprova.",
      message: () => "Nomi di colonna non validi.",
    },
    "invalid-permissions": {
      ctaType: () =>
        "Controllare se le restrizioni IP o di accesso sono configurate correttamente.",
      message: () => "Autorizzazioni API mancanti.",
    },
    "invalid-user-defined-field": {
      ctaType: () => "Verifica i tuoi input o la configurazione.",
      message: () => "La richiesta contiene campi non validi.",
    },
    "invalid-wallet-address": {
      ctaType: () => "Controllare le istruzioni e riprovare.",
      message: () => "Indirizzo wallet non valido.",
    },
    "no-transactions": {
      ctaType: () => "Verificare la fonte dei dati o gli input.",
      message: () => "Non sono state trovate transazioni.",
    },
    "rate-limit-failed": {
      ctaType: () => "Riprova tra 5 minuti.",
      message: () => "Limite di velocità raggiunto.",
    },
    timeout: {
      ctaType: () => "Riprova più tardi.",
      message: () => "La richiesta è scaduta. ",
    },
    "too-many-transactions": {
      ctaType: () => "Per ulteriore assistenza, contattare l'assistenza.",
      message: () => "Limite di transazione superato",
    },
    "unknown-error": {
      ctaType: () => "Per ulteriore assistenza, contattare l'assistenza.",
      message: () => "Si è verificato un errore sconosciuto.",
    },
    "unsupported-data-format": {
      ctaType: () => "Per ulteriore assistenza, contattare l'assistenza.",
      message: () => "Il formato dei dati ricevuto dall'API non è supportato.",
    },
  },
  syncHistory: {
    confirm: {
      text: () =>
        "Vuoi davvero rimuovere tutti i dati da questa sincronizzazione? Questo eliminerà tutte le transazioni associate a questa sincronizzazione. Questa operazione non può essere annullata.",
      title: () => "Elimina importazione sincronizzazione",
      warning: (d) => d.name + " il " + d.date,
    },
    date: () => "Data",
    delete: () => "Eliminare",
    hardSync: () => "Sincronizzazione rigida",
    id: () => "ID",
    initialSync: () => "Sincronizzazione iniziale",
    name: () => "Nome",
    noneFound: () => "Nessuna cronologia di sincronizzazione riuscita trovata.",
    title: () => "Sincronizza cronologia",
    transactions: () => "transazioni",
    transactionsHeader: () => "Transazioni",
    viewTx: () => "Visualizza le transazioni",
  },
  syncStatus: {
    failed: () => "Aggiornamento non riuscito",
    reconciliation: () => "Riconciliazione delle transazioni",
    retry: () => "Aggiorna i calcoli",
    updated: () => "Aggiornato",
    updating: () => "Aggiornamento in corso",
  },
  tabNav: {
    connectedAccounts: () => "Account collegati",
    more: () => "Di più",
    nfts: () => "NFT",
    plan: () => "Piano",
    report: () => "Rapporto",
    review: () => "Revisione",
    settings: () => "Impostazioni",
    summary: () => "Riepilogo",
    transactions: () => "Transazioni",
  },
  tag: {
    createNew: () => "Creare identità",
    createNewIdentity: () => "Crea una nuova identità",
    deleteMessage: (d) =>
      d.address + " non sarà più identificato come " + d.entityName,
    groups: {
      Contract: () => "Contratti",
      Exchange: () => "Scambi",
      Extension: () => "Scambi",
      Manual: () => "Costume",
    },
    identified: (d) => d.count + " identificati",
    identify: () => "Identificare",
    identifyAddress: () => "Identificare l'indirizzo",
    identifyAddressModal: {
      description: () =>
        "Ciò identificherà l'indirizzo in tutte le transazioni.",
      doneButton: () => "Fatto",
      title: () => "Identificare l'indirizzo",
    },
    identifyDescription: () =>
      "Ciò identificherà l'indirizzo in tutte le transazioni.",
    manageAddressModal: {
      description: (d) =>
        "Questo indirizzo è identificato come " + d.entityDisplayName + " .",
      descriptionContract: (d) =>
        "Questo contratto è identificato come " + d.entityDisplayName + " .",
      doneButton: () => "Fatto",
      title: () => "Gestisci indirizzo",
    },
    refAddressModal: {
      description: (d) =>
        "Tutti gli indirizzi identificati come " + d.entityDisplayName + " .",
      doneButton: () => "Fatto",
      title: (d) => "Gestisci " + d.entityDisplayName,
    },
    startTypingName: () => "Inizia a digitare un nome",
    thisAddress: () => "Questo indirizzo",
  },
  taxAuthorityByCountry: {
    AD: () => "DT",
    AE: () => "FTA",
    AF: () => "ARCA",
    AG: () => "IRD",
    AI: () => "IRD",
    AL: () => "GDT",
    AM: () => "SRC",
    AO: () => "AGT",
    AR: () => "AFIP",
    AS: () => "ASG-T",
    AT: () => "BMF",
    AU: () => "ATO",
    AW: () => "DIMP",
    AZ: () => "STM",
    BA: () => "ITA",
    BB: () => "BRA",
    BD: () => "NBR",
    BE: () => "FPSF",
    BF: () => "DGI",
    BG: () => "NRA",
    BH: () => "NBR",
    BI: () => "OBR",
    BJ: () => "DGI",
    BM: () => "OTC",
    BN: () => "MOFE",
    BO: () => "SIN",
    BQ: () => "BCN",
    BR: () => "RFB",
    BS: () => "IRD",
    BT: () => "DRC",
    BV: () => "BVT",
    BW: () => "BURS",
    BY: () => "MTC",
    BZ: () => "IRD",
    CA: () => "CRA",
    CF: () => "DGID",
    CG: () => "DGI",
    CH: () => "SFTA",
    CI: () => "DGI",
    CL: () => "SII",
    CM: () => "DGT",
    CN: () => "STA",
    CO: () => "DIAN",
    CR: () => "DGT",
    CU: () => "ONAT",
    CV: () => "DNRE",
    CW: () => "NOB",
    CY: () => "TAD",
    CZ: () => "FSAD",
    DE: () => "BZSt",
    DJ: () => "DGI",
    DK: () => "SKAT",
    DM: () => "IRD",
    DO: () => "DGII",
    DZ: () => "DGI",
    EC: () => "SRI",
    EE: () => "ETCB",
    EG: () => "ETA",
    ER: () => "IRD",
    ES: () => "AEAT",
    ET: () => "ERCA",
    FI: () => "FTA",
    FJ: () => "FRCS",
    FK: () => "FIG",
    FM: () => "DoF",
    FO: () => "TAKS",
    FR: () => "DGFIP",
    GA: () => "DGI",
    GB: () => "HMRC",
    GD: () => "IRD",
    GE: () => "GRS",
    GF: () => "DGFIP",
    GH: () => "GRA",
    GI: () => "ITO",
    GL: () => "TSN",
    GM: () => "GRA",
    GN: () => "DNI",
    GP: () => "DGFIP",
    GQ: () => "DGI",
    GR: () => "IAPR",
    GT: () => "SAT",
    GU: () => "DRT",
    GW: () => "DGI",
    GY: () => "GRA",
    HK: () => "IRD",
    HN: () => "SAR",
    HR: () => "TA",
    HT: () => "DGI",
    HU: () => "NTCA",
    ID: () => "DGT",
    IE: () => "TRC",
    IL: () => "ITA",
    IM: () => "ITD",
    IN: () => "ITR",
    IR: () => "INTA",
    IS: () => "RSK",
    IT: () => "ADE",
    JE: () => "RT",
    JM: () => "TAJ",
    JO: () => "ISTD",
    JP: () => "NTA",
    KE: () => "KRA",
    KG: () => "STS",
    KH: () => "GDT",
    KI: () => "MFED",
    KM: () => "AGID",
    KN: () => "IRD",
    KP: () => "MoF",
    KR: () => "NTS",
    KW: () => "MT",
    KY: () => "DCI",
    KZ: () => "SRC",
    LA: () => "TD",
    LB: () => "MoF",
    LC: () => "IRD",
    LI: () => "STV",
    LK: () => "IRD",
    LR: () => "LRA",
    LS: () => "LRA",
    LT: () => "STI",
    LU: () => "ACD",
    LV: () => "SRS",
    LY: () => "TD",
    MA: () => "DGI",
    MC: () => "DSF",
    MD: () => "STS",
    ME: () => "TA",
    MF: () => "DGFIP",
    MG: () => "DGI",
    MH: () => "MIRC",
    MK: () => "PRO",
    ML: () => "DGI",
    MM: () => "IRD",
    MN: () => "MTA",
    MO: () => "DSF",
    MP: () => "DRT",
    MQ: () => "DGFIP",
    MR: () => "DGI",
    MS: () => "IRD",
    MT: () => "CfR",
    MU: () => "MRA",
    MV: () => "MIRA",
    MW: () => "MRA",
    MX: () => "SAT",
    MY: () => "LHDN",
    MZ: () => "AT",
    NA: () => "IR",
    NC: () => "DSF",
    NE: () => "DGI",
    NG: () => "FIRS",
    NI: () => "DGI",
    NL: () => "DTCA",
    NO: () => "NTA",
    NP: () => "IRD",
    NR: () => "DoF",
    NU: () => "TR",
    NZ: () => "IRD",
    OM: () => "TR",
    PA: () => "DGI",
    PE: () => "SUNAT",
    PF: () => "DICP",
    PG: () => "IRC",
    PH: () => "BIR",
    PK: () => "FBR",
    PL: () => "KAS",
    PM: () => "DGFIP",
    PR: () => "DH",
    PS: () => "ITA",
    PT: () => "PTCA",
    PW: () => "BRT",
    PY: () => "SET",
    QA: () => "GTA",
    RE: () => "DGFIP",
    RO: () => "ANAF",
    RS: () => "PU",
    RU: () => "FTS",
    RW: () => "RRA",
    SA: () => "GAZT",
    SB: () => "IRD",
    SC: () => "SRC",
    SD: () => "SRTD",
    SE: () => "STA",
    SG: () => "IRAS",
    SI: () => "FURS",
    SK: () => "FR",
    SL: () => "NRA",
    SM: () => "SMR",
    SN: () => "DGID",
    SO: () => "MoF",
    SR: () => "DB",
    SS: () => "NRA",
    SV: () => "DGII",
    SX: () => "TD",
    SY: () => "MoF",
    SZ: () => "SRA",
    TC: () => "RD",
    TD: () => "DGI",
    TG: () => "OTR",
    TH: () => "RD",
    TJ: () => "TC",
    TL: () => "DNRT",
    TM: () => "MTT",
    TN: () => "DGI",
    TO: () => "IRD",
    TR: () => "GIB",
    TT: () => "BIR",
    TV: () => "IRD",
    TW: () => "NTBT",
    TZ: () => "TRA",
    UA: () => "STS",
    UG: () => "URA",
    UK: () => "HMRC",
    US: () => "IRS",
    UY: () => "DGI",
    UZ: () => "STC",
    VA: () => "N/A",
    VC: () => "IRD",
    VE: () => "SENIAT",
    VG: () => "IRD",
    VI: () => "IRB",
    VN: () => "GDT",
    VU: () => "DCIR",
    WF: () => "DGFIP",
    WS: () => "MfR",
    YE: () => "TY",
    YT: () => "DGFIP",
    ZA: () => "SARS",
    ZM: () => "ZRA",
    ZW: () => "ZIMRA",
  },
  taxDivision: {
    buy: () => "Acquistare",
    disposal: () => "Disposizione",
    group: () => "Gruppi",
    hidden: () => "Nascosto",
    ignore: () => "Ignorare",
    income: () => "Reddito",
    lastUsed: () => "Selezionati di recente",
    payment: () => "Pagamento",
    transfer: () => "Trasferire",
    withdrawal: () => "Ritiro",
  },
  taxLossHarvesting: {
    errors: {
      unableToLoad: () => "Impossibile caricare la modalità Loss Harvesting",
    },
    gain: () => "Guadagni",
    iconTooltips: {
      editPrice: () => "Modifica prezzo",
      refreshPrice: () => "Aggiornare i prezzi di mercato",
      resetPrice: () => "Ripristina il prezzo al prezzo di mercato corrente",
      savePrice: () => "Salva il prezzo",
    },
    open: () => "Perdite del raccolto",
    openWhenNoLosses: () => "Visualizza lo strumento di raccolta",
    paywallModal: {
      aboutLTFO: () =>
        "Informazioni sul nostro metodo di inventario fiscalmente più efficace",
      aboutTaxLossHarvesting: () =>
        "Informazioni sulla raccolta delle perdite fiscali",
      benefits: {
        lockIn: () =>
          "Bloccare potenzialmente le perdite di capitale per riportarle agli anni futuri",
        lockInNoCGT: () =>
          "Bloccare potenzialmente le perdite non realizzate per riportarle agli anni futuri",
        offset: () =>
          "Compensare potenzialmente eventuali guadagni in conto capitale al di fuori delle criptovalute con perdite di capitale in criptovalute",
        offsetNoCGT: () =>
          "Compensare potenzialmente eventuali profitti realizzati al di fuori delle criptovalute con le perdite realizzate dalle criptovalute",
        optimize: () =>
          "Ottimizza le tue tasse con il metodo di inventario più efficace dal punto di vista fiscale",
      },
      subtitlePost: () => "in perdite.",
      subtitlePre: () => "Potresti raccogliere",
      title: () => "Ridurre potenzialmente il tuo carico fiscale.",
      upgradePrompt: () =>
        "Passa al piano per investitori o superiore per sbloccare la raccolta delle perdite",
    },
    postHarvestCapitalGains: () => "Plusvalenze post-raccolto",
    postHarvestCapitalGainsNoCGT: () => "Profitti post-raccolto",
    realisedCapitalGains: () => "Plusvalenze realizzate",
    realisedCapitalGainsNoCGT: () => "Profitti realizzati",
    showAssetsWithoutMarketPrice: () =>
      "Mostra le attività senza prezzo di mercato",
    summary: {
      netCapitalGainLoss: () => "Guadagno/perdita netto in conto capitale",
      netCapitalGainLossNoCGT: () => "Profitto/perdita netta realizzata",
    },
    table: {
      loading: () => "Caricamento...",
      noHoldings: () => "Nessuna partecipazione da mostrare",
      rowSelectionDisabled: () =>
        "È possibile selezionare solo valute con perdite non realizzate.",
      title: () => "Opportunità di risparmio fiscale",
    },
    tableHeaders: {
      amountHeld: () => "Importo trattenuto",
      amountToSell: () => "Importo da vendere",
      asset: () => "Risorsa",
      costBasis: () => "Base di costo",
      currentMarketValue: () => "Valore di mercato",
      price: () => "Prezzo",
      unrealizedGainsLosses: () => "Guadagni/perdite non realizzati",
    },
    tableSearch: () => "Trova risorsa",
    taxSavingOpportunities: () => "Opportunità di risparmio fiscale",
    title: () => "Raccolta delle perdite fiscali",
    tooltips: {
      unrealizedGains: () =>
        "La differenza tra il costo di base di un bene e il suo valore di mercato attuale",
    },
    viewTaxLosses: () => "Visualizza le perdite fiscali",
  },
  taxRule: {
    "bed-and-breakfast": () => "Regola BnB",
    "fee-forwarded": () => "Commissione inoltrata",
    "margin-fee": () => "Commissione di margine",
    "recalculated-after-bed-and-breakfast": () => "Ricalcolo BnB",
    "same-day": () => "Regola dello stesso giorno",
    "superficial-loss-from-buy-back": () => "Riacquisto superficiale",
    "superficial-loss-from-sell": () => "Vendita superficiale",
    "zero-cost-buy": () => "Saldo mancante",
  },
  taxSettingBanner: {
    affectCalculationsWarning: () =>
      "Si prega di notare che queste opzioni potrebbero avere un impatto sui calcoli degli anni fiscali passati.",
    cta: () => "Dai un'occhiata",
    title: () => "✨ Nuova automazione disponibile!",
  },
  taxToggles: () => "Toggle fiscali",
  testKey: () => "Questo ORA non verrà tradotto, aggiungi testo tradotto extra",
  theCounterParty: () => "la controparte",
  theme: {
    betaModal: {
      actionText: () => "Passa alla modalità scura",
      content1: () =>
        "Stiamo ancora sistemando alcuni problemi con i colori in modalità scura. Alcuni componenti potrebbero non essere visualizzati correttamente.",
      content2: () => "Ciò non ha alcun impatto sui calcoli e sui dati.",
      title: () => "La modalità scura è in versione beta",
    },
    short: {
      dark: () => "Buio",
      light: () => "Leggero",
      system: () => "Sistema",
    },
    toggle: {
      dark: () => "Modalità scura",
      light: () => "Modalità luce",
      system: () => "Predefinito del sistema",
    },
  },
  title: () => "Calcolatrice delle imposte sulle criptovalute",
  to: () => "A",
  tokenLogin: {
    errorSubtitle: () =>
      "Controlla il tuo link di accesso oppure prova ad accedere utilizzando il tuo indirizzo email e la tua password.",
    errorTitle: () => "Impossibile effettuare l'accesso",
    subtitle: () => "Attendi mentre effettuiamo l'accesso al tuo account",
    title: () => "Ti accedo al tuo account",
    visitLogin: () => "Vai alla pagina di accesso",
  },
  topBanner: {
    coupon: () => "VENERDÌ_NERO_2020",
    msg: () =>
      "Black Friday • sconto del 60% sul primo anno di abbonamento • CODICE: ",
  },
  tradeType: {
    addLiquidity: () => "Aggiungere Liquidità",
    airdrop: () => "Lancio aereo",
    approval: () => "Approvazione",
    "arbitrary-uncategorised": () => "Classificare",
    borrow: () => "Prestito",
    bridge: () => "Ponte",
    "bridge-trade": () => "Commercio a catena incrociata",
    "bridge-transfer": () => "Ponte",
    bridgeIn: () => "Ponte in",
    bridgeOut: () => "Ponte fuori",
    bridgeTradeIn: () => "Acquista catena incrociata",
    bridgeTradeOut: () => "Vendita a catena incrociata",
    bridgeTransferIn: () => "Trasferimento ponte in",
    bridgeTransferOut: () => "Trasferimento ponte in uscita",
    burn: () => "Bruciare",
    buy: () => "Acquistare",
    cashback: () => "Rimborso",
    chainSplit: () => "Catena divisa",
    collateral: () => "Deposito collaterale",
    collateralWithdrawal: () => "Ritiro delle garanzie",
    "decrease-position": () => "Diminuire la posizione",
    decreasePositionDeposit: () => "Diminuire la posizione",
    deposit: () => "Ricevere",
    derivatives: () => "Trading PnL",
    expense: () => "Spese",
    failed: () => "Fallito",
    failedIn: () => "Fallito in",
    failedOut: () => "Fallito",
    fee: () => "Tassa",
    fiat: () => "Fiat",
    "fiat-transfer": () => "Trasferimento Fiat",
    fiatDeposit: () => "Deposito Fiat",
    fiatWithdrawal: () => "Ritiro Fiat",
    gift: () => "Regalo",
    gifts: () => "Regali",
    ignoreIn: () => "Ignora in",
    ignoreOut: () => "Ignora fuori",
    ignored: () => "Ignorato",
    in: () => "In arrivo",
    income: () => "Reddito",
    "increase-position": () => "Aumentare la posizione",
    increasePositionWithdrawal: () => "Aumentare la posizione",
    interest: () => "Interesse",
    liquidate: () => "Liquidazione",
    liquidity: () => "Liquidità",
    loan: () => "Prestiti",
    loanRepayment: () => "Rimborso del prestito",
    long: () => "Lungo",
    lost: () => "Perduto",
    marginFee: () => "Commissione di margine",
    mining: () => "Estrazione mineraria",
    mint: () => "Menta",
    "multi-mint": () => "Menta",
    "multi-trade": () => "Commercio",
    notAvailable: () => "Acquisto a costo zero",
    out: () => "In uscita",
    outgoingGift: () => "Regalo in uscita",
    personalUse: () => "Uso personale",
    "provide-liquidity-trade": () => "Aggiungere Liquidità",
    realisedLoss: () => "Perdita realizzata",
    realisedProfit: () => "Profitto realizzato",
    receivePQ: () => "Ricevi token di posizione",
    receivingLiquidityProviderToken: () => "Ricevi il token di ricevuta",
    "remove-liquidity-trade": () => "Rimuovi Liquidità",
    removeLiquidity: () => "Rimuovi Liquidità",
    returningLiquidityProviderToken: () => "Invia token di ricevuta",
    reward: () => "Ricompensa",
    royalty: () => "Diritti d'autore",
    sell: () => "Vendere",
    sendPQ: () => "Invia token di posizione",
    short: () => "Corto",
    spamIn: () => "Spam",
    "stake-deposit-group": () => "Deposito di Staking",
    "stake-withdrawal-group": () => "Prelievo di puntata",
    staking: () => "Ricompensa di puntata",
    stakingDeposit: () => "Deposito di Staking",
    stakingWithdrawal: () => "Prelievo di puntata",
    stakings: () => "Picchettamento",
    stolen: () => "Rubato",
    swap: () => "Scambio",
    swapIn: () => "Scambia in",
    swapOut: () => "Scambia fuori",
    swaps: () => "Scambi",
    trade: () => "Commercio",
    transfer: () => "Trasferire",
    uncategorised: () => "Non categorizzato",
    unknown: () => "Sconosciuto",
    withdrawal: () => "Inviare",
  },
  tradeTypeCombined: {
    borrow_loanRepayment: () => "Prestito e rimborso del prestito",
    bridgeIn_bridgeOut: () => "Ponte dentro e fuori",
    bridgeTradeIn_bridgeTradeOut: () => "Catena incrociata Acquista e vendi",
    bridgeTransferIn_bridgeTransferOut: () => "Ponte dentro e fuori",
    buy_sell: () => "Acquista e vendi",
    collateralWithdrawal_collateral: () => "Deposito e prelievo di garanzie",
    failedIn_failedOut: () => "Fallito dentro e fuori",
    fiatDeposit_fiatWithdrawal: () => "Deposito e prelievo Fiat",
    ignoreIn_ignoreOut: () => "Ignora dentro e fuori",
    receive_send: () => "Ricevi e invia",
    receivingLiquidityProviderToken_stakingDeposit: () =>
      "Staking Deposit & Ricevi il token di ricevuta",
    removeLiquidity_addLiquidity: () => "Rimuovi e aggiungi liquidità",
    returningLiquidityProviderToken_stakingWithdrawal: () =>
      "Prelievo di Staking e invio del token di ricevuta",
    stakingWithdrawal_stakingDeposit: () => "Prelievo e deposito dello Staking",
  },
  tradeTypeGroup: {
    acquisition: {
      description: () =>
        "Sei diventato proprietario di una criptovaluta, stabilendo la base di costo per la stessa.",
      label: () => "Acquisizione",
    },
    derivatives: {
      description: () =>
        "Hai ricevuto profitti dal trading di derivati, ad esempio futures/margine.",
      label: () => "Derivati",
    },
    disposal: {
      description: () =>
        "Non sei più proprietario di una criptovaluta e si innesca un evento CGT.",
      label: () => "Disposizione",
    },
    expenses: {
      description: () => "Hai utilizzato un bene per pagare una spesa.",
      label: () => "Spese",
    },
    "fiat-transfer": {
      description: () =>
        "La Fiat venne trasferita ma la proprietà venne mantenuta.",
      label: () => "Trasferimento Fiat",
    },
    ignore: {
      description: () =>
        "Ignora la transazione nei calcoli delle imposte e del saldo.",
      label: () => "Ignorare",
    },
    income: {
      description: () =>
        "Hai ricevuto entrate in criptovalute. Puoi anche categorizzare commissioni ecc. in questa categoria.",
      label: () => "Reddito",
    },
    loss: {
      description: () =>
        "Hai perso criptovalute e vuoi dedurre il costo di base dalle tasse.",
      label: () => "Perdita",
    },
    transfer: {
      description: () =>
        "La criptovaluta è stata trasferita ma la proprietà è stata mantenuta.",
      label: () => "Trasferire",
    },
  },
  tradeTypeTaxDescription: {
    addLiquidity: () => "Hai aggiunto queste monete a un pool di liquidità",
    airdrop: () =>
      'Hai ricevuto token "gratuiti" come parte di una promozione o simile',
    approval: () => "Hai approvato l'uso di uno smart contract",
    "arbitrary-uncategorised": () => "Classificare",
    borrow: () =>
      "Hai ricevuto criptovalute o valute fiat a seguito della fornitura di garanzie",
    "bridge-trade": () =>
      "Hai completato uno scambio cross-chain, che prevede lo scambio di una valuta con un'altra su blockchain diverse.",
    "bridge-transfer": () => "Bridge in/out raggruppato automaticamente",
    bridgeIn: () =>
      "Utilizzato per trasferire la base di costo da una blockchain all'altra. Nota: deve corrispondere a un'altra transazione classificata come 'Bridge Out'",
    bridgeOut: () =>
      "Utilizzato per trasferire la base di costo da una blockchain all'altra. Nota: deve corrispondere a un'altra transazione classificata come 'Bridge In'",
    bridgeTradeIn: () =>
      "Hai scambiato criptovalute su una blockchain e le hai inviate a un'altra blockchain tramite un ponte.",
    bridgeTradeOut: () =>
      "Hai ricevuto criptovalute su una blockchain da una transazione avviata su una blockchain diversa.",
    bridgeTransferIn: () =>
      "Utilizzato per trasferire la base di costo da una blockchain all'altra. Nota: deve corrispondere a un'altra transazione classificata come 'Bridge Out'",
    bridgeTransferOut: () =>
      "Utilizzato per trasferire la base di costo da una blockchain all'altra. Nota: deve corrispondere a un'altra transazione classificata come 'Bridge In'",
    burn: () => "Hai inviato i tuoi beni a un indirizzo bruciatore.",
    buy: () => "Non è un evento imponibile",
    cashback: () =>
      "Ha ricevuto uno sconto su una transazione, come una riduzione di commissione o un rimborso in contanti da un pagamento con carta di credito, ecc.",
    chainSplit: () => "Hai ricevuto criptovalute a seguito di un hard fork",
    collateral: () =>
      "Hai messo da parte queste monete come garanzia per un prestito. Questo funziona come un prelievo dal tuo account",
    collateralWithdrawal: () =>
      "Hai prelevato queste monete da una piattaforma di prestito/prestito. Questo funziona in modo simile a un deposito sul tuo account",
    "decrease-position": () =>
      "Utilizzato per chiudere o ridurre una posizione esistente in un protocollo. Utilizzato per derivati o staking.",
    decreasePositionDeposit: () =>
      "Hai chiuso o ridotto una posizione e hai ricevuto rendimenti sul tuo conto.",
    deposit: () => "Non è un evento imponibile",
    expense: () =>
      "Questo funziona in modo simile a una vendita, tuttavia desideri etichettarlo come una spesa",
    fee: () => "Hai avuto una spesa varia",
    "fiat-transfer": () =>
      "La Fiat venne trasferita ma la proprietà venne mantenuta.",
    fiatDeposit: () =>
      "Hai trasferito denaro fiat dal tuo conto bancario a un exchange",
    fiatWithdrawal: () =>
      "Hai incassato dal tuo exchange, si spera, più di quanto hai investito",
    gift: () =>
      "Hai ricevuto un regalo da una terza parte, ad esempio un familiare",
    in: () => "Transazione non categorizzata",
    income: () =>
      "Hai ricevuto entrate in criptovalute. Puoi anche categorizzare commissioni ecc. in questa categoria",
    "increase-position": () =>
      "Utilizzato per aprire o aumentare una posizione esistente in un protocollo. Utilizzato per derivati o staking.",
    increasePositionWithdrawal: () =>
      "Hai aperto o aumentato una posizione investendo criptovalute dal tuo account.",
    interest: () =>
      "Hai ricevuto interessi per aver prestato la tua criptovaluta",
    liquidate: () =>
      "Stavi facendo trading con leva finanziaria e ti è stato chiesto un margine",
    loanRepayment: () => "Hai rimborsato il debito su un prestito",
    lost: () =>
      "Hai perso criptovalute e vuoi dedurre il costo di base dalle tasse.",
    marginFee: () => "Hai effettuato un pagamento di interessi su un prestito",
    mint: () =>
      'Questo funziona in modo simile a un "acquisto" e un caso d\'uso comune è quando un utente "conia" NFT',
    "multi-mint": () =>
      'Questo funziona in modo simile a un "acquisto" ed è comunemente utilizzato per "coniare" NFT',
    "multi-trade": () => "multi-commercio",
    out: () => "Transazione non categorizzata",
    outgoingGift: () =>
      "Hai fatto un regalo a una terza parte, ad esempio un membro della famiglia",
    personalUse: () =>
      "Hai effettuato un acquisto per uso personale e vuoi dichiararlo come non imponibile (attenzione: verifica con il tuo commercialista prima di utilizzare questo servizio per assicurarti di soddisfare i criteri)",
    "provide-liquidity-trade": () =>
      "Hai aggiunto token a un pool di liquidità in cambio di un token di ricevuta.",
    realisedLoss: () =>
      "Hai subito una perdita quando hai fatto trading di derivati, ad esempio futures/margine",
    realisedProfit: () =>
      "Hai ricevuto profitti dal trading di derivati, ad esempio futures/margine",
    receivePQ: () =>
      "Hai aperto o aumentato una posizione e hai ricevuto un token di posizione.",
    receivingLiquidityProviderToken: () =>
      "Hai ricevuto token per l'aggiunta di monete in un pool di liquidità",
    "remove-liquidity-trade": () =>
      "Hai restituito il tuo token di liquidità in cambio del recupero dei tuoi fondi dal pool di liquidità.",
    removeLiquidity: () => "Hai rimosso queste monete da un pool di liquidità",
    returningLiquidityProviderToken: () =>
      "Hai restituito token per aver rimosso monete da un pool di liquidità",
    royalty: () =>
      "Hai ricevuto entrate dalle vendite secondarie, ad esempio dal creatore di NFT",
    sell: () => "Innesca un evento fiscale sulle plusvalenze",
    sendPQ: () =>
      "Hai chiuso o ridotto una posizione restituendo il token di posizione.",
    staking: () => "Hai guadagnato interessi dallo staking",
    stakingDeposit: () =>
      "Hai depositato queste monete in uno staking pool. Questo funziona in modo simile a un prelievo",
    stakingWithdrawal: () =>
      "Hai prelevato queste monete dallo staking pool. Questo funziona in modo simile a un deposito",
    stolen: () =>
      "Ti hanno rubato la criptovaluta e vuoi richiedere la detrazione fiscale del costo di base.",
    swap: () => "Scambio automatico in entrata/uscita",
    trade: () => "Acquisto/vendita raggruppati automaticamente",
    transfer: () => "Prelievo/deposito raggruppato automaticamente",
    withdrawal: () => "Non è un evento imponibile",
  },
  tradeTypeTaxDescriptionExpanded: {
    addLiquidity: () => "Hai versato criptovalute in un pool di liquidità.",
    airdrop: () =>
      "Hai ricevuto una distribuzione gratuita di criptovalute direttamente nel tuo portafoglio.",
    approval: () =>
      "Hai concesso autorizzazioni nei protocolli DeFi, spesso comportando determinati costi.",
    "arbitrary-uncategorised": () => "Classificare",
    borrow: () =>
      "Hai preso in prestito criptovalute da una piattaforma, in genere depositando una garanzia.",
    "bridge-trade": () =>
      "Hai completato uno scambio cross-chain, che prevede lo scambio di una valuta con un'altra su blockchain diverse.",
    "bridge-transfer": () =>
      "Hai trasferito criptovalute da una blockchain all'altra.",
    bridgeIn: () =>
      "Hai ricevuto criptovalute da un'altra blockchain tramite un bridge.",
    bridgeOut: () =>
      "Hai inviato criptovalute a un'altra blockchain tramite un ponte.",
    bridgeTradeIn: () =>
      "Hai scambiato criptovalute su una blockchain e le hai inviate a un'altra blockchain tramite un ponte.",
    bridgeTradeOut: () =>
      "Hai ricevuto criptovalute su una blockchain da una transazione avviata su una blockchain diversa.",
    bridgeTransferIn: () =>
      "Hai ricevuto criptovalute da un'altra blockchain tramite un bridge.",
    bridgeTransferOut: () =>
      "Hai inviato criptovalute a un'altra blockchain tramite un ponte.",
    burn: () =>
      "Hai inviato criptovalute a un indirizzo burn, rimuovendole di fatto dalla circolazione.",
    buy: () =>
      "Hai acquistato criptovalute utilizzando valuta fiat o altre criptovalute.",
    cashback: () =>
      "Hai ricevuto rimborsi in criptovalute per acquisti o attività specifiche.",
    chainSplit: () =>
      "Hai ottenuto nuove criptovalute grazie a una biforcazione o divisione della blockchain.",
    collateral: () =>
      "Hai utilizzato le criptovalute come garanzia per prestiti o altre attività.",
    collateralWithdrawal: () =>
      "Hai recuperato le criptovalute precedentemente utilizzate come garanzia.",
    "decrease-position": () =>
      "Utilizzato per chiudere o ridurre una posizione esistente in un protocollo. Utilizzato per derivati o staking.",
    decreasePositionDeposit: () =>
      "Hai chiuso o ridotto una posizione e hai ricevuto rendimenti sul tuo conto.",
    deposit: () =>
      "Hai ricevuto criptovalute da un altro account di tua proprietà.",
    expense: () =>
      "Hai utilizzato le criptovalute per servizi, spese varie o spese aziendali.",
    failedOut: () =>
      "Una transazione che non è riuscita durante l'elaborazione o non è stata completata. Esclusa dai calcoli delle imposte e del saldo.",
    fee: () => "Hai pagato commissioni di rete o di transazione.",
    "fiat-transfer": () =>
      "La Fiat venne trasferita ma la proprietà venne mantenuta.",
    fiatDeposit: () =>
      "Hai depositato valuta fiat su un exchange o una piattaforma.",
    fiatWithdrawal: () =>
      "Hai prelevato valuta fiat da un exchange o da una piattaforma.",
    gift: () => "Hai ricevuto criptovalute in regalo da un'altra persona.",
    ignoreIn: () =>
      "Una transazione in entrata esclusa dai calcoli delle imposte e del saldo.",
    ignoreOut: () =>
      "Una transazione in uscita esclusa dai calcoli delle imposte e del saldo.",
    in: () => "Una transazione in entrata che non è stata categorizzata",
    income: () => "Hai ricevuto entrate in criptovalute da varie attività.",
    "increase-position": () =>
      "Utilizzato per aprire o aumentare una posizione esistente in un protocollo. Utilizzato per derivati o staking.",
    increasePositionWithdrawal: () =>
      "Hai aperto o aumentato una posizione investendo criptovalute dal tuo account.",
    interest: () =>
      "Hai guadagnato interessi prestando o trattenendo le tue criptovalute.",
    liquidate: () =>
      "Hai venduto criptovalute, spesso in perdita, per coprire debiti o richieste di margine.",
    loanRepayment: () =>
      "Hai rimborsato le criptovalute prese in prestito da una piattaforma.",
    lost: () =>
      "Hai perso le criptovalute a causa di errori, chiavi dimenticate o altri motivi.",
    marginFee: () =>
      "Hai sostenuto delle commissioni per il trading con margine o con leva finanziaria.",
    mining: () =>
      "Hai guadagnato criptovalute convalidando transazioni o eseguendo attività su una blockchain.",
    mint: () =>
      "Hai creato nuove criptovalute o NFT, in genere eseguite su piattaforme decentralizzate.",
    "multi-mint": () =>
      "Hai creato nuove criptovalute o NFT, in genere eseguite su piattaforme decentralizzate.",
    "multi-trade": () =>
      "Hai negoziato due asset (ad esempio cripto/fiat o cripto/cripto).",
    out: () => "Una transazione in uscita che non è stata categorizzata.",
    outgoingGift: () =>
      "Hai regalato criptovalute a una terza parte, ad esempio a un familiare.",
    personalUse: () =>
      "Hai utilizzato le criptovalute per spese o acquisti personali.",
    "provide-liquidity-trade": () =>
      "Hai aggiunto criptovalute a un pool di liquidità e in cambio hai ricevuto un token di ricevuta.",
    realisedLoss: () =>
      "Hai subito una perdita dalla chiusura di un contratto finanziario o derivato.",
    realisedProfit: () =>
      "Hai realizzato un profitto chiudendo un contratto finanziario o derivato.",
    receivePQ: () =>
      "Hai aperto o aumentato una posizione e hai ricevuto un token di posizione.",
    receivingLiquidityProviderToken: () =>
      "Hai ricevuto dei token come ricompensa per aver fornito liquidità a un pool.",
    "remove-liquidity-trade": () =>
      "Hai restituito il tuo token di liquidità per recuperare criptovalute dal pool di liquidità.",
    removeLiquidity: () =>
      "Hai prelevato criptovalute da un pool di liquidità.",
    returningLiquidityProviderToken: () =>
      "Hai restituito token che rappresentano la tua quota in un pool di liquidità.",
    royalty: () =>
      "Hai ricevuto criptovalute come risultato delle royalties derivanti da creazioni basate sulle criptovalute, come gli NFT.",
    sell: () =>
      "Hai venduto le tue criptovalute in cambio di valuta fiat o altre criptovalute.",
    sendPQ: () =>
      "Hai chiuso o ridotto una posizione restituendo il token di posizione.",
    spamIn: () =>
      "Hai ricevuto criptovalute senza il tuo consenso. Spesso è il risultato di spam o truffe. Escluso dai calcoli delle tasse e del saldo.",
    "stake-deposit-group": () =>
      "Hai depositato un token in un contratto di staking e hai ricevuto un token di ricevuta che rappresenta la tua posizione.",
    "stake-withdrawal-group": () =>
      "Hai prelevato gli asset puntati da un contratto di staking e restituito il token di ricevuta che rappresenta la posizione.",
    staking: () =>
      "Hai ricevuto criptovalute aggiuntive o ricompense come risultato dello staking delle tue criptovalute.",
    stakingDeposit: () =>
      "Hai depositato criptovalute in un contratto o in una piattaforma di staking.",
    stakingWithdrawal: () =>
      "Hai prelevato criptovalute da un contratto o da una piattaforma di staking.",
    stolen: () => "La tua criptovaluta è stata rubata.",
    swap: () =>
      "Hai effettuato una transazione cripto-cripto non soggetta a imposizione fiscale.",
    swapIn: () =>
      "Hai ricevuto criptovalute da una transazione cripto-cripto non soggetta a imposte.",
    swapOut: () =>
      "Hai acquisito criptovalute tramite una transazione cripto-cripto non soggetta a imposte.",
    trade: () =>
      "Hai negoziato due asset (ad esempio cripto/fiat o cripto/cripto).",
    transfer: () =>
      "Hai trasferito criptovalute tra i tuoi account, mantenendone la proprietà.",
    withdrawal: () =>
      "Hai trasferito criptovalute da un account di tua proprietà a un altro.",
  },
  transactionEdit: {
    dialog: {
      actionButton: () => "Esci senza salvare",
      body: () => "Tutte le modifiche apportate andranno perse.",
      cancel: () => "Cancellare",
      title: () => "Uscire senza salvare?",
    },
    somethingWentWrong: () => "Qualcosa è andato storto.",
    trade: () => "Modifica commercio",
    transaction: () => "Modifica transazione",
    transfer: () => "Modifica trasferimento",
  },
  transactionTableModal: {
    defaultTitle: (d) =>
      plural(d.count, 0, it, {
        one: " 1 Transazione ",
        other: number("it", d.count, 0) + " Transazioni ",
      }),
    viewTxTable: () => "Visualizza sulla tabella tx",
  },
  transactions: {
    add: () => "Aggiungere",
    deletedTheTxs: () => "Eliminate le transazioni.",
    groups: {
      exchanges: () => "Scambi",
      other: () => "Altro",
      wallets: () => "Portafogli",
    },
    partiallyDeleted: () =>
      "Qualcosa è andato storto durante l'eliminazione della transazione. La transazione è stata eliminata solo in parte.",
    reconcile: (d) => "Riconcilia " + d.count + " elementi",
    title: () => "Transazioni",
    ungroup: {
      action: () => "Separare",
      body1: () => "Vuoi davvero separare queste transazioni?",
      title: () => "Separare le transazioni",
    },
    updateTable: () => "Aggiornamento della tabella delle transazioni",
    wentWrongDeleting: () =>
      "Si è verificato un problema durante l'eliminazione delle transazioni",
    wentWrongDeletingComment: () =>
      "Si è verificato un errore durante l'eliminazione del commento",
    wentWrongDeletingTx: () =>
      "Si è verificato un errore durante l'eliminazione della transazione.",
    wentWrongDuplicatingTx: () =>
      "Si è verificato un problema durante la duplicazione delle transazioni. Riprova.",
    wentWrongFetching: () =>
      "Si è verificato un problema durante il recupero del prezzo di mercato. Riprova.",
    wentWrongIgnoringWarning: () =>
      "Si è verificato un problema ignorando l'avviso.",
    wentWrongLeavingNote: () =>
      "Qualcosa è andato storto quando ho lasciato una nota",
    wentWrongRecategorizing: () =>
      "Si è verificato un problema durante la ricategorizzazione delle transazioni. Riprova.",
    wentWrongRegrouping: () =>
      "Si è verificato un errore durante il raggruppamento delle transazioni",
    wentWrongUngrouping: () =>
      "Si è verificato un errore durante la separazione delle transazioni",
    wentWrongUpdateCurrency: () =>
      "Si è verificato un problema durante l'aggiornamento della valuta delle transazioni. Riprova.",
    wentWrongUpdatingTrade: () =>
      "Si è verificato un errore durante l'aggiornamento della transazione commerciale.",
    wentWrongUpdatingTx: () =>
      "Si è verificato un errore durante l'aggiornamento della transazione.",
  },
  transferring: () => "Trasferimento",
  tryAgain: () => "Riprova",
  twoFactorAuth: {
    "2FA": () => "2FA",
    authenticationCode: () => "Codice di autenticazione",
    authy: () => "Autenticità",
    disableError: () => "Impossibile disabilitare 2FA",
    disableSuccess: () => "2FA disabilitato con successo",
    disableTwoFactorAuth: () => "Disabilitare 2FA",
    done: () => "Fatto",
    download: () => "Scaricamento",
    enableError: () => "Impossibile abilitare 2FA",
    enableSuccess: () => "2FA abilitato con successo",
    enableTwoFactorAuth: () => "Abilita 2FA",
    enterCodeLabel: () => "Inserisci il codice a 6 cifre",
    enterCodeOrRecoveryCodeHelperText: () =>
      "Codice di autenticazione a 6 cifre o codice di ripristino",
    enterCodeSubText: () =>
      "Inserisci il codice a 6 cifre dalla tua app di autenticazione",
    enterCodeToChangePassword: () =>
      "Inserisci il codice a 6 cifre generato dalla tua app di autenticazione o uno dei codici di recupero per modificare la tua password",
    enterCodeToDisable: () =>
      "Inserisci il codice a 6 cifre generato dalla tua app di autenticazione per disattivare 2FA",
    enterCodeToRegenerate: () =>
      "Inserisci il codice a 6 cifre generato dalla tua app di autenticazione per rigenerare e visualizzare i tuoi codici di recupero",
    enterYourPassword: () => "1. Inserisci la tua password",
    googleAuthenticator: () => "Autenticazione Google",
    manualCodeEntry: () =>
      'Se non riesci a scansionare il codice, copia il codice sottostante, inseriscilo nella tua app di autenticazione e, se è presente un menu a discesa con le opzioni, seleziona "Basato sul tempo"',
    or: () => " O ",
    password: () => "Password",
    passwordRequired: () => "La password è obbligatoria",
    qrCodeError: () => "Impossibile generare il codice QR",
    qrCodeForTwoFactor: () => "Codice QR per 2FA",
    recoveryCodes: () => "Visualizza i codici di recupero",
    recoveryCodesContent: () =>
      "Se perdi il tuo dispositivo mobile, dovrai usare uno di questi codici di recupero per accedere al tuo account CTC. Ogni codice può essere usato solo una volta.",
    recoveryCodesInfoBoxContent: () =>
      "Se perdi il tuo dispositivo di autenticazione e non hai accesso ai tuoi codici di recupero, perderai l'accesso al tuo account CTC!",
    recoveryCodesInfoBoxTitle: () => "Conserva i codici in un posto sicuro",
    regenerate: () => "Rigenerare",
    regenerateError: () => "Impossibile rigenerare i codici di ripristino",
    regenerateRecoveryCodes: () => "Rigenera i codici di recupero",
    regenerateSuccess: () => "Codici di ripristino rigenerati con successo",
    scanQrCode: () => "2. Scansiona questo codice QR",
    scanWithApp: () => "Scansiona il codice QR qui sotto con un'app come ",
    staySignedIn: () => "Rimani connesso per 30 giorni",
    submit: () => "Invia",
    twoStepVerification: () => "Verifica in due passaggi",
    verify: () => "Verificare",
    verifyAuthenticationCode: () => "3. Verifica il codice di autenticazione",
    verifyCode: () => "Verifica codice",
  },
  txLimitExceeded: () => "Limite Tx superato",
  txTable: {
    account: () => "Account",
    accountBalance: {
      in: () => "Saldo in entrata",
      out: () => "Saldo in uscita",
    },
    add: () => "Aggiungi transazione",
    addComment: () => "Aggiungi commento",
    addPrice: () => "Aggiungi prezzo",
    all: () => "Tutto",
    allTxs: () => "Tutte le tue transazioni appariranno qui. Per iniziare",
    amount: () => "Quantità",
    assignMissingBlockchain: () => "Assegna blockchain",
    assumedTransfer: () =>
      "Parte di questa transazione è stata generata automaticamente dal nostro motore di suggerimenti intelligenti",
    balanceRemainingInfo: {
      account: () => "Account",
      change: () => "Modifica",
      currency: () => "Valuta",
      endingBalance: () => "Saldo finale",
      overallBalance: () => "Equilibrio complessivo",
      startingBalance: () => "Saldo iniziale",
      tooltips: {
        change: () =>
          "La variazione del saldo risultante da questa transazione",
        endingBalance: () =>
          "Il saldo detenuto in questo conto a seguito di questa transazione",
        overallBalance: () =>
          "Il saldo presente su tutti i conti dopo questa transazione.",
        startingBalance: () =>
          "Il saldo presente su questo conto prima di questa transazione.",
      },
      type: () => "Categoria",
      viewLedger: () => "Registro valuta",
    },
    bulkEditBar: {
      addComment: () => "Aggiungi commento...",
      bulkLookupConfirmationText: () =>
        "Vuoi davvero cercare il prezzo di mercato per le transazioni selezionate? Questo sovrascriverà il prezzo esistente di questa transazione e non potrà essere annullato.",
      changeCurrency: () => "Cambia valuta",
      clearSelection: () => "Cancella selezione",
      close: () => "Chiudi Modifica Menu",
      command: () => "Comando",
      confirm: () => "Confermare",
      delete: () => "Eliminare",
      deleteConfirmationText: () =>
        "Attenzione! Questa azione non può essere annullata. Vuoi davvero eliminare queste transazioni? Ti consigliamo di ignorare le transazioni ove possibile.",
      edit: () => "Modificare",
      editAll: () => "Modifica tutto",
      groupingWizard: () => "Assistente di raggruppamento (Beta)",
      groups: () => "Gruppi",
      ignore: () => "Ignorare",
      ignoreConfirmationText: () =>
        "Vuoi davvero ignorare queste transazioni? Eseguendo questa azione ignorerai queste transazioni da qualsiasi calcolo fiscale. Le transazioni ignorate possono essere nascoste dalla tabella delle transazioni nei filtri avanzati.",
      markAsReviewed: () => "Contrassegna come recensito",
      markAsUnreviewed: () => "Contrassegna come non revisionato",
      numSelected: (d) => d.numSelected + " selezionati",
      numSelectedAll: (d) =>
        "Sono selezionate tutte le " +
        d.numSelected +
        " transazioni in questo filtro",
      open: () => "Apri il menu dei comandi",
      recategorizeError: () =>
        "Si è verificato un problema durante la ricategorizzazione delle transazioni. Riprova.",
      selectAll: () =>
        "Seleziona tutte le transazioni che corrispondono a questo filtro",
      selected: () => "Selezionato",
      spam: () => "Contrassegna come spam",
      spamConfirmationText: () =>
        "Sei sicuro di voler contrassegnare queste transazioni come spam? Eseguendo questa azione, queste transazioni verranno ignorate da qualsiasi calcolo delle imposte. Le transazioni spam possono essere nascoste dalla tabella delle transazioni nei filtri avanzati.",
      txCount: (d) => d.numSelected + " transazioni",
    },
    bulkLookupPriceConfirmationTitle: () => "Cerca prezzo di mercato",
    commandPallet: {
      acceptedForProcessing: () =>
        "Aggiornamento in corso, potrebbero volerci alcuni minuti...",
      actions: {
        accept: () => "Accettare",
        add: () => "Aggiungere",
        confirm: () => "Confermare",
        goBack: () => "Torna indietro",
      },
      bulkEditOptionDescriptions: {
        txRewind: () =>
          "Inserisci la data e l'ora a cui desideri annullare le modifiche alle transazioni. Nota: questa azione annullerà solo le modifiche apportate alle transazioni tra il momento corrente e il timestamp selezionato. Non eliminerà le transazioni appena create o importate, né ripristinerà le transazioni eliminate. Saranno annullate solo le modifiche visibili nella scheda cronologia delle righe delle transazioni pertinenti.",
      },
      bulkEditOptions: {
        addCommentToTransactions: () => "Aggiungi commento alle transazioni",
        allowGrouping: () => "Consenti raggruppamento",
        categorize: () => "Classificare",
        changeAssetAccount: () => "Cambia account attività",
        changeCashAccount: () => "Cambia Conto Cassa",
        changeCurrency: () => "Cambia valuta",
        changeFeeCurrency: () => "Cambio valuta di commissione",
        changeGainsAccount: () => "Cambia account guadagni",
        changeLoanAccount: () => "Cambiare il conto del prestito",
        changePnLAccount: () => "Cambia account PnL",
        changePrice: () => "Cambia prezzo",
        delete: () => "Eliminare",
        destinationAccount: () => "Cambia account in entrata",
        groupingWizard: () => "Assistente di raggruppamento (Beta)",
        ignore: () => "Ignorare",
        ignoreWarnings: () => "Ignora gli avvisi",
        lookUpMarketPrice: () => "Cerca il prezzo di mercato",
        markAsNotReviewed: () => "Non recensire",
        markAsReviewed: () => "Contrassegna come revisionato",
        markAsSpam: () => "Contrassegna come spam",
        sourceAccount: () => "Cambia account in uscita",
        syncToErp: () =>
          "Sincronizzazione con l'integrazione del software di contabilità",
        txRewind: () => "Riavvolgi transazioni (Beta)",
        ungroup: () => "Separare",
      },
      chips: {
        type: () => "Tipo",
      },
      currentlySelected: () => "Attualmente selezionato",
      groups: () => "Gruppi",
      ignoreWarningsOptions: {
        all: () => "Tutto",
        missingBlockchain: () => "Blockchain mancante",
        missingPrice: () => "Prezzo mancante",
        negativeBalance: () => "Cronologia degli acquisti mancante",
        uncategorised: () => "Non categorizzato",
        unmatchedTransfer: () => "Trasferimento senza pari",
      },
      loadingOptions: () => "Opzioni di caricamento...",
      noOptions: () => "Nessuna opzione",
      notAvailableOnSelectAll: () =>
        "Non disponibile in modalità Seleziona tutto",
      numSelected: (d) => d.numSelected + " transazioni",
      placeholders: {
        account: () => "Quale account...",
        category: () => "Quale categoria...",
        changeTo: () => "Cambia in...",
        currency: () => "Quale valuta...",
        ignoreWarning: () => "Ignora gli avvisi...",
        incomingAccount: () => "Quale conto in entrata...",
        outgoingAccount: () => "Quale conto in uscita...",
        searchSubType: (d) => "Cerca " + d.subType,
        toCategory: () => "Cambia in...",
        toSourceAccount: () => "Cambia in...",
        tradeType: () => "Quale categoria...",
        tradeTypeSearch: {
          group: () => "Cerca categorie raggruppate",
          hint: () => "Suggerimento! Questa funzione di contratto è",
          in: () => "Cerca categorie in arrivo",
          out: () => "Cerca categorie in uscita",
          uncategorisedTooltipOverride: (d) =>
            "La categorizzazione di questa transazione risolverà " +
            d.value +
            " negli avvisi di transazione non categorizzati",
        },
      },
      successMessage: {
        addCommentToTransactions: (d) =>
          "Commento aggiunto su " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        allowGrouping: (d) =>
          plural(d.count, 0, it, {
            1: " 1 Transazione ",
            other: number("it", d.count, 0) + " Transazioni ",
          }) + " consentito per essere raggruppato",
        categorize: (d) =>
          "Ricategorizzazione corretta " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        changeAssetAccount: (d) =>
          "Conto patrimoniale aggiornato il " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        changeCashAccount: (d) =>
          "Conto cassa aggiornato il " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        changeCurrency: (d) =>
          "Valuta modificata con successo per " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        changeFeeCurrency: (d) =>
          "La valuta della commissione è cambiata il " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        changeGainsAccount: (d) =>
          "Conto guadagni aggiornato il " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        changeLoanAccount: (d) =>
          "Conto prestito aggiornato il " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        changePnLAccount: (d) =>
          "Conto PnL aggiornato il " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        changePrice: (d) =>
          "Aggiornato correttamente il prezzo per " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        delete: (d) =>
          "Eliminato " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        destinationAccount: (d) =>
          "Conto in entrata modificato il " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        ignore: (d) =>
          "Ignorato " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        ignoreWarnings: (d) =>
          "Avvisi ignorati " +
          d.warning +
          " su " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        lookUpMarketPrice: (d) =>
          "Cercato prezzo di mercato su " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        markAsNotReviewed: (d) =>
          plural(d.count, 0, it, {
            1: " 1 Transazione ",
            other: number("it", d.count, 0) + " Transazioni ",
          }) + " contrassegnato come non revisionato",
        markAsReviewed: (d) =>
          plural(d.count, 0, it, {
            1: " 1 Transazione ",
            other: number("it", d.count, 0) + " Transazioni ",
          }) + " contrassegnato come revisionato",
        markAsSpam: (d) =>
          plural(d.count, 0, it, {
            1: " 1 Transazione ",
            other: number("it", d.count, 0) + " Transazioni ",
          }) + " contrassegnato come spam",
        sourceAccount: (d) =>
          "Conto in uscita modificato il " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        syncToErp: (d) =>
          "Richiesta di sincronizzazione inviata per " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        txRewind: (d) =>
          "Riavvolto " +
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }),
        ungroup: (d) =>
          plural(d.count, 0, it, {
            1: " 1 transazione ",
            other: number("it", d.count, 0) + " transazioni ",
          }) + " non raggruppato",
      },
      syncToErp: (d) => "Sincronizzazione con " + d.erp,
      typeSearchCommand: () => "Digita un comando o cerca...",
      undo: () => "Disfare",
      undoFailed: () => "Impossibile annullare le modifiche",
      undoLoading: () => "Annullamento delle modifiche in corso...",
      undoSuccess: () => "Successo!",
    },
    commentLabels: {
      allComments: () => "Tutti i commenti",
      hasComments: () => "Ha commenti",
    },
    contractAddress: () => "Indirizzo del contratto",
    cost: () => "Base di costo",
    counterparty: () => "Controparte",
    currency: () => "Valuta",
    date: {
      helperFrom: () => "È necessario inserire una data di inizio valida",
      helperTo: () => "È necessario inserire una data valida",
    },
    deleteBulkTransactionsConfirmationTitle: (d) =>
      "Elimina " +
      plural(d.txCount, 0, it, {
        one: " 1 transazione ",
        other: number("it", d.txCount, 0) + " transazioni ",
      }),
    deleteTransactionConfirmationTitle: () => "Elimina transazione",
    destination: () => "Destinazione",
    dialogs: {
      deleteConfirmationText: () =>
        "Attenzione! Questa azione non può essere annullata. Vuoi davvero eliminare questa transazione? Ti consigliamo di ignorare la transazione, ove possibile.",
      ignoreTransactionText: () =>
        "Vuoi davvero ignorare questa transazione? Eseguendo questa azione ignorerai questa transazione da qualsiasi calcolo fiscale. Le transazioni ignorate possono essere nascoste dalla tabella delle transazioni nei filtri avanzati.",
      spamTransactionText: () =>
        "Sei sicuro di voler contrassegnare questa transazione come spam? Eseguendo questa azione ignorerai questa transazione da qualsiasi calcolo delle imposte. Le transazioni spam possono essere nascoste dalla tabella delle transazioni nei filtri avanzati.",
    },
    entities: {
      explainerTooltip: () =>
        "Consentire a questa entità di agire come agente di deposito a garanzia le consentirà di detenere fondi ed eseguire cessioni per tuo conto. Esempi di casi in cui potresti voler abilitare questa opzione sono entità che rappresentano contratti d'asta, contratti di media dei costi in dollari o qualsiasi tipo di interazione in cui depositi fondi mantenendo la proprietà, fino a un momento successivo in cui il contratto li dispone per tuo conto.",
      falsePositive: () => "Non più contrassegnato come deposito a garanzia",
      globalEscrowTooltip: () =>
        "Questa entità è stata contrassegnata come agente di deposito a garanzia da CTC",
      switchText: () =>
        "Consenti a questa entità di agire come agente di deposito a garanzia",
      truePositive: () => "Contrassegnato come deposito a garanzia",
    },
    expansionPanel: {
      adjPrice: () => "Prezzo rettificato",
      amount: () => "Quantità",
      balanceError: () => "I saldi sono negativi",
      chartOfAccounts: {
        accountMappingNotRequired: () =>
          "Non sono richieste mappature degli account",
        accountNotFound: (d) =>
          "L'account che è stato mappato non può essere trovato nel piano dei conti di " +
          d.erp,
        accountTypeLabel: {
          asset: () => "Risorsa",
          cash: () => "Contanti",
          gain: () => "Guadagno",
          loan: () => "Prestito",
          pl: () => "PnL",
          "zero-cost-buy": () => "ZCB",
        },
        assetAccount: () => "Conto patrimoniale",
        cashAccount: () => "Conto di cassa",
        dropdown: () => "Cerca account",
        erpAccounts: (d) => d.erp + " Conti",
        from: () => "Da",
        gainAccount: () => "Conto Guadagno",
        hiddenAccounts: () =>
          "Abbiamo filtrato questo elenco per non includere conti bancari e qualsiasi altro conto che non può essere registrato su journal",
        loanAccount: () => "Conto di prestito",
        plAccount: () => "Conto P&L",
        title: () => "Piano dei conti",
        to: () => "A",
        transaction: () => "Transazione",
        uncategorised: () =>
          "Impostare la categoria di transazione per assegnare gli account",
        zeroCostBuyAccount: () => "Conto ZCB",
      },
      comments: {
        accountant: () => "Contabile",
        button: () => "Lascia un commento",
        client: () => "Cliente",
        collaborator: () => "Collaboratore",
        comments: (d) =>
          plural(d.count, 0, it, {
            0: " Commenti ",
            other: " Commenti ( " + d.count + " ) ",
          }),
        editError: () =>
          "Qualcosa è andato storto durante la modifica del commento",
        edited: () => "(modificato)",
        editedAt: (d) => "Modificato " + d.time,
        noComments: () => "I commenti appariranno qui",
        placeholder: () => "Aggiungi un commento...",
        saveChanges: () => "Salvare le modifiche",
        you: () => "Voi",
      },
      cost: () => "Base di costo",
      currency: () => "Valuta",
      date: () => "Data",
      delete: {
        body: () => "Una volta eliminato, il commento sarà perso per sempre.",
        title: () => "Vuoi eliminare il commento?",
      },
      events: () => "Eventi associati a questa transazione",
      fee: () => "Commissioni",
      functionName: (d) => "Funzione del contratto: " + d.functionName,
      functionNameLabel: () => "Funzione del contratto",
      gain: () => "Guadagno",
      history: {
        changed: () => "cambiato",
        created: () => "creato manualmente",
        currentVersion: () => "Versione corrente",
        deleted: () => "Eliminato",
        false: () => "NO",
        from: () => "da",
        headings: {
          actor: () => "Attore",
          date: () => "Data",
          transaction: () => "Modifica",
        },
        imported: () => "importato",
        modalTitle: (d) => "Ripristina transazioni a " + d.timestamp,
        noChange: () => "Nessun cambiamento",
        noChangesFromCurrentDetected: () => "Nessuna modifica rilevata",
        notRecorded: () => "Non registrato",
        notSet: () => "Non impostato",
        original: () => "Originale",
        restore: (d) => "Ripristina a " + d.time,
        restoreOnly: () => "Ripristinare",
        thisVersion: () => "Questa versione",
        thisWillChange: () => "Questo cambierà:",
        title: () => "Modifica cronologia",
        to: () => "A",
        transaction: () => "transazione",
        transactionKeys: {
          blockchain: () => "Catena di blocchi",
          currencyIdentifier: () => "Valuta",
          description: () => "Descrizione",
          dontGroup: () => "Non raggruppare",
          "erp.accountMappings.asset": () =>
            "Sostituzione dell'account patrimoniale",
          "erp.accountMappings.cash": () => "Override del conto cassa",
          "erp.accountMappings.gain": () => "Ottieni l'override dell'account",
          "erp.accountMappings.pl": () => "Sostituzione dell'account PnL",
          fee: () => "Tassa",
          feeCurrencyIdentifier: () => "Valuta della commissione",
          feeQuantity: () => "Quantità della tariffa",
          from: () => "Da",
          functionName: () => "Funzione Tx",
          functionSignature: () => "Firma Tx",
          price: () => "Prezzo",
          quantity: () => "Quantità",
          reviewed: () => "Recensito",
          suggestion: () => "Suggerimento",
          timestamp: () => "Marca temporale",
          to: () => "A",
          trade: () => "Categoria",
        },
        true: () => "SÌ",
        unableToCompare: () => "Impossibile confrontare",
        unableToFindPreviousVersion: () =>
          "Impossibile trovare la versione precedente",
        undo: () => "Disfare",
        undoLatestChange: () => "Annullare questa modifica?",
        updateReason: {
          import: () => "Oracolo dei prezzi CTC",
          migration: () => "Sistema CTC",
          rule: () => "Servizio regole CTC",
          system: () => "Sistema CTC",
          transactionMatching: () => "Corrispondenza delle transazioni CTC",
          unknown: () => "Sconosciuto",
          user: () => "Utente",
        },
        updateReasonActorCTCSystem: () => "Sistema CTC",
        updateReasonActorUnknown: () => "Sconosciuto",
        updateReasonActorUser: () => "Utente",
      },
      id: () => "ID:",
      importSource: (d) => "Origine di importazione: " + d.sourceDisplayName,
      inflightBalanceError: () =>
        "Una cronologia degli acquisti mancante indica che alcune delle tue transazioni in uscita sono mancanti. Ciò può accadere se non hai importato tutti i tuoi dati",
      moreInfo: () => "Maggiori informazioni",
      moreInfoPopover: {
        importSource: () => "Origine dell'importazione:",
        learnAbout: () => "Scopri di più",
        title: () => "Informazioni aggiuntive",
      },
      na: () => "N / A",
      notes: (d) => "Descrizione: " + d.notes,
      price: () => "Prezzo",
      proceeds: () => "Proventi",
      receivedFrom: () => "Ricevuto da",
      rolloverFrom: (d) => "Rollover da " + d.currency,
      ruleApplied: () => "Regola applicata",
      sentBy: () => "Inviato a",
      sourceType: (d) => "Fonte del prezzo: " + d.sourceType,
      sourceTypes: {
        ManualUpdate: () => "Aggiornamento manuale",
        PriceFromAction: () => "Prezzo da azione",
        PriceWindow: () => "Finestra di ribasso dei prezzi",
      },
      time: () => "Tempo",
      total: () => "Totale",
      tradedOn: () => "Negoziato su",
      transactionId: (d) =>
        plural(d.count, 0, it, { one: " Tx ID ", other: " Tx ID " }),
      transactionIdLabel: () => "ID di trasmissione",
      transactionsTab: () => "Transazioni",
      type: () => "Categoria",
      ungroup: {
        buttonText: () => "Consenti raggruppamento",
        infoBody: () =>
          "Questa transazione è stata separata manualmente e, di conseguenza, non sarà più considerata dal nostro algoritmo di raggruppamento come parte di una transazione o di un trasferimento.",
        infoTitle: () => "Transazione non raggruppata",
        title: () => "Non raggruppato",
      },
      updating: () => "Aggiornamento delle transazioni",
      value: () => "Valore",
      viewAssociated: () => "Visualizza le transazioni associate",
      viewBalance: () => "Visualizza registro",
      viewRelatedTx: () => "Visualizza le transazioni correlate",
      viewTaxRule: () => "Visualizza la regola fiscale",
      viewTx: () => "Visualizza TX",
    },
    exportCSV: {
      filterOptionCaption: () => "le transazioni corrispondono a questo filtro",
      filterOptionTitle: () => "Transazioni nel filtro",
      pageOptionCaption: () => "transazioni attualmente sulla pagina",
      pageOptionTitle: () => "Transazioni sulla pagina",
      paywall: {
        button: () => "Seleziona un piano",
        text: () => "Per esportare le transazioni è necessario un piano.",
      },
      pending: {
        captionPt1: () =>
          "Il report verrà scaricato automaticamente quando sarà pronto.",
        captionPt2: () => "Puoi chiudere questa finestra.",
        title: () => "Generazione del report",
      },
      submitButton: (d) => "Esporta " + d.count + " transazioni",
      success: {
        captionPt1: () => "Se non vedi il tuo file, puoi ",
        captionPt2: () => "scaricalo di nuovo",
        title: () => "La tua esportazione è stata scaricata",
      },
      title: () => "Esportazione transazioni CSV",
    },
    fee: () => "Tassa",
    feeValue: (d) => "Valore della commissione: " + d.amount,
    fiatTradeLabel: {
      deposit: () => "Depositare",
      withdrawal: () => "Ritiro",
    },
    filter: {
      addFilter: () => "Aggiungi filtro",
      advanced: () => "Avanzato",
      advancedFilters: () => "Cancella i filtri per cercare",
      category: () => "Categoria",
      clearFilters: () => "Cancella filtri",
      comment: () => "Commenti",
      currency: () => "Valuta",
      date: () => "Data",
      descriptions: {
        actionId: () => "Cerca una transazione tramite il suo ID",
        after: () => "Cerca le transazioni successive alla data specificata",
        and: () =>
          "Cerca le transazioni che corrispondono a TUTTI i filtri selezionati",
        before: () => "Cerca le transazioni precedenti alla data specificata",
        blockchain: () =>
          "Cerca le transazioni in base alla blockchain su cui si sono verificate",
        category: () => "Cerca le transazioni per categoria",
        comment: () =>
          "Cerca le transazioni in base al contenuto dei loro commenti",
        comments: () =>
          "Cerca le transazioni in base al contenuto dei loro commenti",
        currency: () =>
          "Cerca le transazioni in base alla valuta in cui si effettua la transazione",
        date: () => "Cerca transazioni in una data specifica",
        description: () =>
          "Cerca le transazioni in base al contenuto della loro descrizione",
        erpAssetAccount: () =>
          "Cerca le transazioni tramite la loro sovrascrittura della mappatura degli account delle attività di integrazione contabile",
        erpCashAccount: () =>
          "Cerca le transazioni tramite la loro mappatura del conto di cassa di integrazione contabile",
        erpGainsAccount: () =>
          "Cerca le transazioni tramite la loro integrazione contabile, il guadagno, l'override della mappatura dell'account",
        erpLoanAccount: () =>
          "Cerca le transazioni tramite la loro mappatura del conto prestito di integrazione contabile",
        erpPnlAccount: () =>
          "Cerca le transazioni tramite la loro integrazione contabile, override della mappatura dei conti P&L",
        erpSyncStatus: () =>
          "Cerca le transazioni in base allo stato di sincronizzazione dell'integrazione contabile",
        feeCurrency: () =>
          "Cerca le transazioni in base alla valuta in cui è stata pagata la commissione",
        from: () => 'Cerca le transazioni in base al loro conto "da"',
        hasRule: () => "Nascondi le transazioni non interessate dalle regole",
        id: () => "Cerca una transazione tramite il suo ID",
        importId: () =>
          "Cerca le transazioni in base alla sincronizzazione in cui sono state importate",
        importType: () =>
          "Cerca le transazioni in base al tipo di importazione da cui sono state effettuate",
        isSmartContractInteraction: () =>
          "Cerca transazioni con contratti intelligenti",
        ledger: () =>
          "Cerca le transazioni relative alla vista corrente del registro",
        missingPrice: () => "Cerca le transazioni per",
        negativeBalance: () => "Cerca le transazioni per",
        nftCollection: () =>
          "Cerca transazioni che scambiano NFT da una collezione specifica",
        not: () =>
          "Cerca le transazioni che NON corrispondono al filtro selezionato",
        or: () =>
          "Cerca le transazioni che corrispondono ad ALCUNI dei filtri selezionati",
        party: () => "Cerca le transazioni per i conti transati tra",
        reconciliation: () => "Cerca le transazioni relative a una mancia",
        reviewed: () => "Cerca le transazioni revisionate",
        rule: () => "Cerca transazioni manipolate da una regola specifica",
        ruleOperator: () =>
          "Cerca transazioni manipolate da uno specifico operatore di regola",
        source: () => "Cerca le transazioni per i conti transati tra",
        suggestion: () =>
          "Cerca le transazioni che sono state categorizzate dal nostro motore di suggerimenti intelligenti",
        sync: () =>
          "Cerca le transazioni in base alla sincronizzazione in cui sono state importate",
        taxOutcomeType: () =>
          "Cerca le transazioni in base al loro esito fiscale",
        to: () => 'Cerca le transazioni in base al loro conto "a"',
        trade: () => "Cerca le transazioni in base al tipo",
        txCurrency: () =>
          "Cerca le transazioni in base alle valute in cui sono state effettuate",
        txFunctionName: () =>
          "Cerca le transazioni tramite la funzione contratto",
        txFunctionSignature: () =>
          "Cerca le transazioni tramite l'ID del metodo",
        txId: () => "Cerca le transazioni tramite l'hash della blockchain",
        warning: () => "Cerca le transazioni in base agli avvisi che hanno",
      },
      erpSyncStatus: {
        NOT_READY_TO_SYNC: () => "Non pronto per la sincronizzazione",
        SYNC_FAILED: () => "Fallito",
        SYNC_IN_PROGRESS: () => "In corso",
        SYNC_NOT_REQUESTED: () => "Pronto per la sincronizzazione",
        SYNC_NO_OP: () => "Nessuna operazione",
        SYNC_OUT_OF_DATE: () => "Fuori Data",
        SYNC_SUCCESSFUL: () => "Riuscito",
      },
      filter: () => "Filtro",
      filterBuilderOperatorOverrides: {
        category: () => "Categoria",
      },
      filters: () => "Filtri",
      hasRule: () => "Nascondi le transazioni non interessate dalle regole",
      hideIgnored: () => "Nascondi transazioni ignorate/spam",
      hideLockedTransactions: () => "Nascondi le transazioni bloccate",
      hideNonSuggested: () =>
        "Mostra solo le transazioni categorizzate in modo intelligente",
      hideReviewed: () => "Nascondi le transazioni revisionate",
      importSource: () => "Importa origine",
      importType: () => "Tipo di importazione",
      imports: () => "Importazioni",
      includesFee: {
        doesNotIncludesFee: () => "Nessuna commissione",
        includesFee: () => "Ha delle commissioni",
      },
      isSmartContractInteraction: {
        smartContract: () => "Contratto intelligente",
        wallet: () => "Portafoglio",
      },
      lockPeriodTooltip: () => "Aggiungi un periodo bloccato per abilitare",
      manual: () => "Manuale",
      moreFilters: () => "Altri filtri",
      negation: {
        is: () => "È",
        isNot: () => "Non è",
      },
      noOptions: () => "Nessuna opzione",
      noResults: () => "Nessun risultato",
      noWarnings: () => "Non hai avvisi",
      operators: {
        actionId: () => "ID azione",
        after: () => "Dopo",
        and: () => "E",
        before: () => "Prima",
        blockchain: () => "Catena di blocchi",
        category: () => "Categoria",
        comment: () => "Commento",
        comments: () => "Commenti",
        currency: () => "Valuta",
        date: () => "Data",
        description: () => "Descrizione",
        erpAssetAccount: () => "Conto patrimoniale",
        erpCashAccount: () => "Conto di cassa",
        erpGainsAccount: () => "Conto Guadagno",
        erpLoanAccount: () => "Conto di prestito",
        erpPnlAccount: () => "Conto P&L",
        erpSyncStatus: () =>
          "Stato di sincronizzazione dell'integrazione contabile",
        feeCurrency: () => "Valuta della commissione",
        from: () => "Da",
        hasRule: () => "Nascondi le transazioni non interessate dalle regole",
        id: () => "ID",
        importId: () => "ID di importazione",
        importType: () => "Tipo di importazione",
        includesFee: () => "Commissioni",
        integrations: () => "Integrazione",
        isSmartContractInteraction: () => "Interazione",
        ledger: () => "Mostra saldi",
        lockPeriod: () => "Periodo di blocco",
        missingPrice: () => "Prezzo mancante",
        negativeBalance: () => "Cronologia degli acquisti mancante",
        nftCollection: () => "Collezione NFT",
        not: () => "Non",
        or: () => "O",
        party: () => "Festa",
        reconciliation: () => "Riconciliazione",
        reviewed: () => "Recensito",
        rule: () => "Regola",
        ruleOperator: () => "Operatore di regola",
        source: () => "Account",
        suggestion: () => "Suggerimento",
        sync: () => "Sincronizzazione",
        taxOutcomeType: () => "Risultato fiscale",
        to: () => "A",
        trade: () => "Categoria",
        txCurrency: () => "Valuta Tx",
        txFunctionName: () => "Firma Tx",
        txFunctionSignature: () => "ID metodo Tx",
        txId: () => "ID di trasmissione",
        warning: () => "Avvertimento",
      },
      otherFilter: () => "Visualizzazione",
      placeholder: () => "Trova transazioni",
      present: () => "Presente",
      resetFilters: () => "Ripristina filtri",
      search: () => "Operatori di filtro di ricerca",
      selectAll: () => "Seleziona tutto",
      settingsFilter: () => "Tavolo",
      showIgnored: () => "Mostra transazioni ignorate/spam",
      sort: () => "Ordinare",
      source: () => "Fonte",
      table: () => "Saldi",
      taxOutcomeType: {
        income: () => "Reddito",
        longTermCapitalGain: () => "Guadagno di capitale a lungo termine",
        longTermCapitalLoss: () => "Perdita di capitale a lungo termine",
        shortTermCapitalGain: () =>
          "Guadagno in conto capitale a breve termine",
        shortTermCapitalLoss: () => "Perdita di capitale a breve termine",
      },
      textMatching: {
        is: () => "Contiene",
        isNot: () => "Non contiene",
      },
      transactionId: () => "Inserisci l'ID della transazione",
      trySearchingFor: () => "Prova a cercare...",
      txFunctionName: () => "Firma Tx",
      txFunctionSignature: () => "ID metodo Tx",
      txId: () => "ID di trasmissione",
      txSignature: () => "Firma",
      typeToSearch: () => "Digita per cercare",
      warningLabel: (d) => d.label + " ( " + d.number + " )",
      warnings: () => "Avvertenze",
    },
    filteredCount: (d) =>
      d.totalFilteredCount + " di " + d.totalCount + " transazioni",
    from: () => "In uscita",
    gain: () => "Guadagno",
    generateReport: () => "Genera rapporto",
    id: () => "ID",
    identifyAddress: () => "Identificare l'indirizzo",
    ignoreBulkTransactionsConfirmationTitle: () => "Ignora transazioni",
    ignoreTransactionConfirmationTitle: () => "Ignora transazione",
    ignored: () => "Ignorato",
    ignoredWarningLabels: {
      missingBlockchain: () => "Blockchain mancante (ignorata)",
      negativeBalance: () => "Cronologia degli acquisti mancante (ignorata)",
    },
    importData: () => "importare dati",
    importFilterOther: () => "Altro",
    importMoreData: () => "Importa più dati",
    importType: {
      api: () => "API",
      "assumed-fee": () => "Tariffa assunta",
      "bulk-wallet": () => "Importazione in blocco",
      csv: () => "Formato CSV",
      manual: () => "Manuale",
      "manual-csv": () => "Manuale CSV",
      oauth: () => "Autenticazione OAuth",
      "soft-transfer": () => "Suggerimento intelligente",
      "soft-wallet": () => "Portafoglio morbido",
      wallet: () => "Portafoglio",
      "wallet-api": () => "API del portafoglio",
      "wallet-csv": () => "Portafoglio CSV",
    },
    invalidBulkEditTooltip: () =>
      "Impossibile modificare in blocco le transazioni raggruppate",
    invalidCategoryTradeUncategorised: (d) =>
      "Migliora l'accuratezza categorizzando questa transazione. Attualmente utilizza la categoria predefinita " +
      d.tradeType +
      " , che potrebbe innescare un evento di guadagno in conto capitale.",
    labels: () => "Etichette",
    missingBlockchainDescription: () =>
      "Questo imposterà la blockchain da cui proviene questo indirizzo.",
    moreThan: () => "Più di",
    noComments: () => "Non ci sono commenti",
    noData: () => "Nessun dato aggiunto",
    noTxFound: () => "Nessuna transazione trovata",
    noTxMatching: () =>
      "Non sono state trovate transazioni corrispondenti al filtro. Prova un filtro diverso.",
    noTxMatchingWithReportRefreshRunning: () =>
      "Stiamo aggiornando le tue transazioni, potrebbero essere trovate altre transazioni una volta completato questo processo.",
    none: () => "Nessuno",
    of: () => "Di",
    openInNewTab: () => "Apri in una nuova scheda",
    overallBalance: () => "Equilibrio complessivo",
    pagination: {
      pageXofY: (d) => d.pageNum + " di " + d.totalPages,
    },
    plusMore: (d) => "+ " + d.count + " altro",
    price: () => "Prezzo",
    quantity: () => "Quantità",
    recentComments: () => "Commenti recenti",
    recentCommentsMore: (d) => "+ " + d.count + " altro",
    remaining: () => "Rimanente",
    rowsPerPage: () => "Righe per pagina:",
    savedAddresses: {
      addError: () =>
        "Si è verificato un errore durante il salvataggio dell'indirizzo blockchain",
      body: () =>
        "Ciò rinominerà l'indirizzo ovunque ci sia una transazione da e verso questo indirizzo.",
      deleteError: () =>
        "Si è verificato un errore durante l'eliminazione dell'indirizzo blockchain salvato",
      importWallet: () => "Importa portafoglio",
      lengthError: () => "Il nome non può superare i 64 caratteri",
      nameAddress: () => "Nome indirizzo",
      placeholder: () => "Soprannome",
      title: () => "Nome indirizzo",
      updateError: () =>
        "Si è verificato un errore durante l'aggiornamento dell'indirizzo blockchain salvato",
    },
    search: () => "Ricerca",
    select: () => "Selezionare",
    showing: () => "Visualizzazione: ",
    similarTxs: () => "Tx simili",
    smartSuggestion: () =>
      "Questa transazione è stata categorizzata automaticamente dal nostro motore di suggerimenti intelligenti",
    sort: {
      feeAscending: () => "Tariffa - Da bassa ad alta",
      feeDescending: () => "Tariffa - Da alta a bassa",
      gainAscending: () => "Guadagno - Da basso ad alto",
      gainDescending: () => "Guadagno - Da alto a basso",
      lastModifiedAscending: () => "Ordina per prima modifica",
      lastModifiedDescending: () => "Ordina per ultima modifica",
      newestFirst: () => "Più recente",
      oldestFirst: () => "Il più vecchio",
      uncategorisedSizeAscending: () => "non categorizzatoDimensioneAscendente",
      uncategorisedSizeDescending: () =>
        "non categorizzatoDimensioneDecrescente",
      uncategorisedValueAscending: () => "non categorizzatoValoreAscendente",
      uncategorisedValueDescending: () => "non categorizzatoValoreDecrescente",
      valueAscending: () => "Valore - Da basso ad alto",
      valueDescending: () => "Valore - Da alto a basso",
    },
    source: () => "Fonte",
    sourceFilter: {
      exchanges: () => "Scambi",
      other: () => "Altro",
      wallets: () => "Portafogli",
    },
    spamBulkTransactionsConfirmationTitle: () =>
      "Contrassegna le transazioni come spam",
    spamTransactionConfirmationTitle: () =>
      "Contrassegna la transazione come spam",
    time: () => "Data",
    title: () => "Transazioni",
    to: () => "In arrivo",
    tooltipGain: (d) => "Guadagno totale di " + d.totalGain,
    tooltipLoss: (d) => "Perdita totale di " + d.totalLoss,
    total: () => "Totale",
    totalFiltered: () => "Numero totale delle transazioni filtrate",
    totalTxCount: (d) => d.totalCount + " transazioni",
    totalTxs: () => "Numero totale delle transazioni",
    trade: () => "Categoria",
    transaction: () => "Transazione",
    transactionId: () => "ID di trasmissione",
    transactions: () => "Transazioni: ",
    txRow: {
      balanceRemaining: (d) => "Saldo rimanente ( " + d.displayName + " ):",
      balanceRemainingOverall: () => "Saldo rimanente (totale):",
      cost: () => "Costo",
      erp: {
        categoriseToSync: () =>
          "Categorizza questa transazione per sincronizzarla",
        mapAllAccounts: () => "Completare tutte le mappature degli account",
        provideDefaultAccountMappings: () =>
          "Fornire mappature account predefinite",
        reportFailed: () =>
          "Impossibile sincronizzare le transazioni mentre i calcoli sono in stato di errore",
        resolveAllWarnings: () => "Risolvi tutti gli avvisi di transazione",
        status: {
          NOT_READY_TO_SYNC: (d) =>
            "Non pronto per la sincronizzazione con " + d.erpProvider,
          SYNC_FAILED: (d) => "Impossibile sincronizzare con " + d.erpProvider,
          SYNC_IN_PROGRESS: (d) =>
            "Sincronizzazione con " + d.erpProvider + " in corso",
          SYNC_NOT_REQUESTED: (d) => "Sincronizzazione con " + d.erpProvider,
          SYNC_NO_OP: (d) =>
            "Nessun diario da sincronizzare con " + d.erpProvider,
          SYNC_OUT_OF_DATE: (d) => "Risincronizza con " + d.erpProvider,
          SYNC_SUCCESSFUL: (d) => "Sincronizzato con " + d.erpProvider,
        },
        viewJournal: () => "visualizza il diario",
        viewPreviousJournal: () => "visualizza il diario precedente",
        waitForCalculationsToFinish: () =>
          "Attendi il completamento dei calcoli",
      },
      feeValue: () => "Valore della commissione di",
      lockedRowTooltip: {
        action: () => "Visualizza le impostazioni di blocco delle transazioni",
        text: () => "Non è possibile sbloccare singole transazioni",
      },
      moreOptions: {
        generateMatchingTransfer: () => "Genera corrispondenza",
      },
      noFees: () => "Nessuna commissione pagata",
      overflowCurrencies: (d) => "+ " + d.count + " altro...",
      prefix: {
        exchange: () => "Scambio",
        from: () => "Fonte",
        fromTo: () => "Origine/Destinazione",
        to: () => "Destinazione",
      },
      similarCount: (d) =>
        plural(d.count, 0, it, {
          one: " 1 tx ",
          other: number("it", d.count, 0) + " txs ",
        }),
      similarValue: (d) => d.symbol + d.value + " totale",
      singleSideLabels: {
        from: () => "Fonte:",
        to: () => "Destinazione:",
      },
      smartContract: () => "Contratto intelligente",
      tooltip: () => "Ora della transazione (nel fuso orario locale)",
      totalTransactionValue: () => "Valore totale della transazione",
    },
    type: () => "Categoria",
    typeUpdateWarningModal: {
      transferSmartContract: {
        change: () => "Cambia categoria",
        contract: () => "Contrarre",
        ignore: () => "Ignorare",
        receiveFromAction: () =>
          "Seleziona una categoria più descrittiva per classificare correttamente le transazioni con contratti intelligenti.",
        receiveFromInfo: () =>
          'Questa transazione avviene con uno smart contract. La categoria "Ricevi" dovrebbe essere utilizzata solo quando si riceve da un altro wallet di tua proprietà.',
        receiveFromTitle: () => "Ricezione da uno smart contract",
        sendToAction: () =>
          "Seleziona una categoria più descrittiva per classificare correttamente le transazioni con contratti intelligenti.",
        sendToInfo: () =>
          'Questa transazione avviene con uno smart contract. La categoria "Invia" dovrebbe essere utilizzata solo quando si invia al proprio wallet.',
        sendToTitle: () => "Invio a uno smart contract",
      },
    },
    uncategorised: () => "Non categorizzato",
    unknownAccountWarning: {
      CTA: (d) =>
        "Se l'account con cui stai " +
        select(d.side, {
          from: " receiving from ",
          to: " send to ",
          other: " interaction with ",
        }) +
        " ti appartiene, [importalo]( " +
        d.linkTextKey +
        ' ). La transazione verrà quindi automaticamente classificata correttamente come "trasferimento".',
      body: (d) =>
        "L'indirizzo " +
        select(d.side, {
          from: " invio ",
          to: " ricezione ",
          other: " sconosciuto ",
        }) +
        " per questa transazione non è stato fornito da " +
        select(d.isBlockchain, {
          true: " blockchain ",
          false: " scambio ",
          other: " account ",
        }) +
        ".",
      subTitle: (d) =>
        "Si tratta di un trasferimento " +
        select(d.side, {
          from: " from another ",
          to: " to another ",
          other: " with an unknown ",
        }) +
        " account crittografico che utilizzi?",
      title: (d) =>
        select(d.side, {
          from: " Transazione in arrivo da un mittente sconosciuto ",
          to: " Transazione in uscita verso un destinatario sconosciuto ",
          other: " Transazione con una parte sconosciuta ",
        }),
      tooltip: (d) =>
        select(d.side, {
          from: " Mittente sconosciuto ",
          to: " Destinatario sconosciuto ",
          other: " Soggetto sconosciuto ",
        }),
    },
    updatePrice: () => "Aggiorna prezzo",
    updateValue: () => "Aggiorna valore",
    value: () => "Valore",
    viewAddress: () => "Visualizza i dettagli dell'indirizzo",
    viewAssociatedTransactionsModal: {
      exchange: () => "Scambio",
      import: (d) => "Se questo è il tuo " + d.importType,
      smartContract: () => "Contratto intelligente",
      transactions: () => "Transazioni:",
      type: () => "Tipo:",
      wallet: () => "Portafoglio",
    },
    viewComments: () => "Visualizza commenti",
    viewLedger: () => "Registro delle valute",
    viewTx: () => "Visualizza i dettagli della transazione",
    warningLabels: {
      all: () => "Tutto",
      missingBlockchain: () => "Blockchain mancante",
      missingPrice: () => "Prezzo di mercato mancante",
      negativeBalance: () => "Cronologia degli acquisti mancante",
      uncategorised: () => "Richiede categorizzazione",
      unknownInOut: () => "Sconosciuto dentro/fuori",
      unmatchedTransfer: () => "Trasferimento senza pari",
      zeroCostBuy: () => "Acquisto a costo zero",
    },
    warnings: {
      bridgeTransferIn: () =>
        "Questo è un ponte unilaterale: si prega di abbinare questa transazione con l'altro lato del ponte.",
      bridgeTransferOut: () =>
        "Questo è un ponte unilaterale: si prega di abbinare questa transazione con l'altro lato del ponte.",
      bridgein: () =>
        "Questo è un ponte unilaterale: si prega di abbinare questa transazione con l'altro lato del ponte.",
      bridgeout: () =>
        "Questo è un ponte unilaterale: si prega di abbinare questa transazione con l'altro lato del ponte.",
      receive: () =>
        "Questa ricezione proviene da un portafoglio che non è stato importato oppure mancano dati per il lato invio della transazione. \n\n Se la transazione proviene da una terza parte, selezionare un'altra categoria appropriata. \n\n Se a questa ricezione manca il lato invio, è possibile riconciliare i trasferimenti non corrispondenti nella pagina di riconciliazione.",
      send: () =>
        "Questo invio è a un portafoglio che non è stato importato o mancano dati per il lato ricevente della transazione. \n\n Se la transazione è a favore di una terza parte, selezionare un'altra categoria appropriata. \n\n Se a questo invio manca il lato ricezione, è possibile riconciliare i trasferimenti non corrispondenti nella pagina di riconciliazione.",
      uncategorized: (d) =>
        'Segui i nostri <a href="' +
        d.link +
        '" target="_blank"> suggerimenti </a> per fornire più contesto su questa transazione',
      uncategorizedReview: () =>
        "Categorizza questa transazione per migliorare l'accuratezza del report",
    },
  },
  uncategorisedModal: {
    actionTooltip: () => "Le transazioni nel gruppo devono avere una categoria",
    cancel: () => "Cancellare",
    moreOptions: () => "Altre opzioni",
    save: () => "Salva",
    subTitle: () => "Transazioni nel gruppo:",
    title: () => "Categorizza gruppo",
  },
  uncategorisedToggles: () => "Trattamento delle transazioni non categorizzate",
  unknownInOut: {
    url: () =>
      "https://help.cryptotaxcalculator.io/en/articles/5279619-categorise-transactions",
  },
  update: () => "Aggiorna",
  userArchive: {
    failedToRestore: () => "Impossibile ripristinare i dati archiviati",
    restoreData: () => "Ripristina i tuoi dati",
    title: () =>
      "Bentornato! È passato un po' di tempo dall'ultimo accesso, quindi abbiamo archiviato i tuoi dati per conservarli in sicurezza. Premi ripristina dati qui sotto per continuare la riconciliazione",
  },
  viewAssociatedTransactions: () => "Visualizza le transazioni associate",
  wallet: {
    address: (d) => "Inserisci " + d.source + " Indirizzo portafoglio",
    addresses: () => "Indirizzi del portafoglio",
    bulk: () => "Incolla qui i tuoi indirizzi pubblici",
    bulkError: () => "Impossibile analizzare gli indirizzi del portafoglio",
    bulkHelperText: () =>
      "Incolla gli indirizzi del tuo portafoglio esattamente come sono stati esportati dall'app Coinbase Wallet",
    deleteConfirmationText: (d) =>
      "Vuoi davvero rimuovere il portafoglio " +
      d.wallet +
      ' " ' +
      d.walletAddress +
      ' "? Questo eliminerà anche TUTTE le transazioni associate a questo portafoglio. Questa operazione non può essere annullata.',
    editDetails: () => "Modifica i dettagli del portafoglio",
    editingSubmit: () => "Aggiorna il nome del portafoglio",
    equivWallet: (d) =>
      "Questo portafoglio usa la blockchain " +
      d.name +
      ' per la sincronizzazione. Una volta sincronizzato, puoi visualizzare i tuoi portafogli connessi nel pannello " ' +
      d.name +
      ' " in questa pagina.',
    existingSubmit: (d) =>
      "Aggiungi un portafoglio " + d.source + " aggiuntivo",
    hardSync: {
      beginningOfTime: () => "l'inizio del tempo",
      confirmationOneWallet: () => "Portafoglio con sincronizzazione fissa?",
      lastLockedPeriod: () =>
        "(la data di fine del tuo periodo di blocco più recente)",
      syncedFromDate: () =>
        "(la data in cui questo portafoglio è stato sincronizzato)",
      textOneWallet: (d) =>
        "La sincronizzazione hard importerà tutte le transazioni per il portafoglio " +
        d.name +
        " da " +
        d.date +
        " . Ciò potrebbe talvolta causare transazioni duplicate che devono essere gestite manualmente, nel caso in cui l'integrazione sia cambiata dall'ultima sincronizzazione.",
      titleOneWallet: (d) =>
        "Risincronizza tutte le transazioni per il portafoglio " +
        d.name +
        " da " +
        d.date,
    },
    hardSyncWarning: (d) =>
      "La sincronizzazione hard-syncing dei wallet " +
      d.name +
      " creati prima del 5 maggio 2022 comporterà transazioni duplicate a causa di recenti modifiche. Consigliamo agli utenti che hanno creato wallet " +
      d.name +
      " prima di questa data di eliminare il proprio wallet e reimportarlo, oppure di evitare la sincronizzazione hard-syncing dei propri wallet.",
    importRelatedWallets: {
      actionTitle: () => "Scansione delle catene EVM",
      actionTooltip: () =>
        "Esegui la scansione delle transazioni associate a questo indirizzo di portafoglio in tutte le catene compatibili con EVM.",
      noResults: () => "Nessuna attività correlata trovata.",
      syncError: () => "Errore durante il recupero dei portafogli correlati",
      syncSuccess: () =>
        "Sono state rilevate transazioni su più blockchain. Ora verranno importate.",
      title: () => "Scansione delle catene EVM",
      tooltipOff: () =>
        "Attualmente le transazioni sulle catene correlate non verranno importate",
      tooltipOn: () =>
        "Attualmente le transazioni sulle catene correlate verranno importate",
    },
    importRules: {
      addressAlreadyExistsError: () =>
        "Questo indirizzo wallet è già stato importato.",
      bulkEmpty: (d) =>
        "Devi inserire l'elenco degli indirizzi pubblici " + d.name + " .",
      bulkExisting: (d) =>
        "Uno degli indirizzi " + d.name + " inseriti è già stato aggiunto.",
      bulkInvalid: (d) =>
        "Uno degli indirizzi inseriti non è un indirizzo di portafoglio " +
        d.name +
        " valido.",
      empty: (d) =>
        "Devi inserire l'indirizzo del tuo portafoglio pubblico " +
        d.name +
        " .",
      existing: (d) =>
        "L'indirizzo del portafoglio " + d.name + " è già stato aggiunto.",
      invalid: (d) =>
        "L'indirizzo del portafoglio " + d.name + " non è valido.",
      invalidAddressError: () =>
        "Questo non è un indirizzo wallet valido per questa origine di importazione.",
      invalidBulkBlockchain: (d) =>
        'Valore blockchain non valido " ' + d.chain + ' ".',
      invalidEnsError: () => "Non è stato possibile risolvere il nome ENS.",
      invalidSnsError: () => "Non è stato possibile risolvere il nome SNS.",
      tooManyWallets: (d) =>
        "Puoi importare solo un massimo di " +
        d.maxWalletImports +
        " portafogli alla volta.",
      tooManyWalletsError: () =>
        "Questo portafoglio supera il numero massimo di portafogli che puoi importare contemporaneamente.",
    },
    invalid: () =>
      "Nome del portafoglio non valido, non utilizzare i caratteri: $ ; \" ' `",
    name: () => "Nome del portafoglio",
    nameRequired: (d) =>
      "Inserisci un nome per il tuo portafoglio " + d.walletName,
    nickname: () => "Soprannome (facoltativo)",
    nicknameCanBeAddedLater: () =>
      "Puoi dare un soprannome a questi portafogli dopo averli importati",
    relatedChains: {
      body: (d) => d.chains,
      title: () => "Catene EVM correlate:",
    },
    safetyInfo: {
      frontPart: () => "Stiamo richiedendo il tuo ",
      highlightPart: () => "solo indirizzo di portafoglio pubblico",
      lastPart: () =>
        "Non richiederemo mai chiavi private e non possiamo trasferire fondi.",
    },
    softSync: {
      action: () => "Sincronizzazione morbida",
      title: (d) =>
        "Sincronizza le transazioni del portafoglio " + d.name + " da",
    },
    submit: (d) => "Importa " + d.source + " Wallet",
    submitMultiple: () => "Importa portafogli",
    title: () => "Importa dati portafoglio",
    type: () => "Tipo di Blockchain",
    walletNicknameUpdate: () =>
      "Nickname del portafoglio aggiornato con successo",
    wentWrongSavingWalletData: () =>
      "Si è verificato un errore durante il salvataggio dei dati del portafoglio.",
    wentWrongSyncingWalletData: () =>
      "Si è verificato un errore durante la sincronizzazione dei dati del portafoglio.",
  },
  warningDisplayName: {
    all: () => "Tutto",
    missingBlockchain: () => "Blockchain mancante",
    missingPrice: () => "Prezzo di mercato mancante",
    negativeBalance: () => "Cronologia degli acquisti mancante",
    suspectedAirdropTransaction: () => "Sospetta transazione airdrop",
    uncategorised: () => "Non categorizzato",
    unknownInOut: () => "Sospetta importazione mancante",
    zeroCostBuy: () => "Acquisto a costo zero",
  },
  warnings: {
    missingBlockchain: () =>
      "Non siamo riusciti a determinare la blockchain per questa transazione. Ciò è spesso dovuto allo stesso indirizzo di portafoglio su più blockchain. \n\n Assegna la blockchain per risolvere questo avviso.",
    missingPrice: (d) =>
      "Non abbiamo un prezzo di mercato per il token " +
      d.symbol +
      " in questa data, quindi è stato ipotizzato un prezzo pari a zero. Vai al grafico dei prezzi di questo token e controlla qual era il prezzo in questa data specifica, quindi modifica la transazione per aggiornare il prezzo a questo valore. Questo dovrebbe risolvere l'avviso.",
    negativeBalance: (d) =>
      select(d.balanceRemaining, {
        true:
          " Questa transazione ha reso negativo il tuo saldo complessivo di " +
          d.symbol +
          " , con un saldo residuo di " +
          d.balanceTotal +
          " " +
          d.symbol +
          " . \n\n Hai acquistato " +
          d.symbol +
          " da un exchange o da un'altra fonte? Questo è probabilmente il pezzo mancante. Assicurati di importare i dati delle transazioni da tutte le fonti da cui hai acquistato " +
          d.symbol +
          " , e questo dovrebbe risolvere il problema. ",
        other: "",
      }),
    uncategorised: (d) =>
      "Questa transazione utilizza la categoria " +
      select(d.trade, {
        in: " categoria predefinita 'Unknown In', che potrebbe causare saldi non accurati ",
        out: " categoria predefinita 'Unknown Out', che potrebbe innescare un evento di guadagno in conto capitale ",
        other: " categoria 'Unknown' che deve essere modificata ",
      }) +
      " . Se importi tutti i tuoi exchange e wallet, il nostro algoritmo dovrebbe essere in grado di categorizzarlo automaticamente. In caso contrario, potrebbero mancare dei dati, nel qual caso puoi selezionare manualmente una categoria nel menu a discesa.",
    unknownInOut: () =>
      "Hai trasferito asset tra i tuoi wallet? Potresti aver dimenticato di importare i dati da uno di questi wallet. \n\n Se si tratta di una transazione tra te e un portafoglio esterno, puoi selezionare la categoria che meglio descrive questa transazione nel menu a discesa a sinistra (ad esempio regalo ricevuto, ricompense di staking, airdrop ecc.). \n\n Puoi anche assegnare un nome a questo indirizzo per risolvere futuri avvisi di presunta importazione mancante per questo indirizzo.",
    zeroCostBuy: () =>
      "Non esiste alcun saldo esistente per coprire questa transazione. Di conseguenza, è stato assunto un acquisto a costo zero (che si traduce in una base di costo pari a zero). Ciò potrebbe causare guadagni calcolati più alti di quanto dovrebbero. Correggi eventuali avvisi mancanti precedenti sulla cronologia degli acquisti per risolvere questo problema.",
  },
  warningsDropdown: {
    missingImportData: {
      description: () =>
        "Senza tutte le fonti di dati non possiamo riflettere accuratamente il tuo saldo complessivo.",
      title: () => "Dati di importazione mancanti",
    },
  },
  was: () => "era",
  watchVideo: () => "Guarda il video",
  worksWith: () => "Funziona con",
  xero: {
    accountMapping: {
      accounts: {
        asset: {
          title: () => "Conto patrimoniale",
          tooltip: () =>
            "Conto che rappresenta il valore delle attività nel portafoglio dell'utente",
        },
        cash: {
          title: () => "Conto cassa",
          tooltip: () =>
            "Account che rappresenta le disponibilità in contanti. Se vendi criptovalute, rappresenteremo i proventi in contanti qui e viceversa quando acquisti criptovalute. Le transazioni da criptovaluta a criptovaluta non avranno alcun impatto su questo account.",
        },
        expense: {
          title: () => "Conto spese",
          tooltip: () => "Conto che rappresenta le spese in criptovaluta",
        },
        fee: {
          title: () => "Conto commissioni",
          tooltip: () =>
            "Conto che rappresenta l'importo pagato in commissioni",
        },
        income: {
          title: () => "Conto entrate",
          tooltip: () =>
            "Conto che rappresenta il reddito derivante dalle criptovalute",
        },
        loan: {
          title: () => "Conto prestito",
          tooltip: () =>
            "Conto che rappresenta prestiti e rimborsi in criptovalute",
        },
        loss: {
          title: () => "Conto perdite",
          tooltip: () =>
            "Conto che rappresenta l'importo di criptovalute perse",
        },
        "realised-gain": {
          title: () => "Conto guadagno realizzato",
          tooltip: () =>
            "Conto che rappresenta i guadagni realizzati dalla cessione di criptovalute",
        },
        "realised-loss": {
          title: () => "Conto perdite realizzate",
          tooltip: () =>
            "Conto che rappresenta le perdite derivanti dalla cessione di criptovalute",
        },
        "zero-cost-buy": {
          title: () => "Account cronologia acquisti mancante",
          tooltip: () =>
            "Quando un bene viene acquistato o ottenuto e non viene effettuato alcun pagamento per esso, consideriamo la base di costo del bene come valore di mercato e questo conto viene utilizzato per bilanciare l'altro lato del giornale",
        },
      },
      lastSynced: (d) => "Ultima sincronizzazione " + d.date,
      refreshInstructions: () =>
        "Se hai creato un nuovo account su Xero e non lo vedi, aggiorna l'elenco.",
      syncError: () => "Non siamo riusciti a sincronizzare i dati.",
      syncInstructions: () =>
        "Per iniziare la sincronizzazione devi scegliere a quali dati degli Account verranno mappati. Puoi aggiornare gli Account in qualsiasi momento. La sincronizzazione può essere avviata quando è impostata la mappatura.",
      title: () => "Mappatura degli account",
    },
    buttons: {
      disconnect: () => "Disconnettiti da Xero",
      refreshList: {
        completed: () => "Elenco account Xero aggiornato",
        done: () => "Aggiorna l'elenco degli account",
        pending: () => "Aggiornamento dell'elenco",
        tooltip: {
          done: () => "Aggiorna gli account disponibili",
          pending: () => "Potrebbe volerci del tempo",
        },
      },
      syncAccounts: {
        done: () => "Sincronizzazione degli account",
        pending: () => "Sincronizzazione degli account",
        tooltip: {
          disabled: () =>
            "Assicurati che ogni account sia selezionato per abilitare la sincronizzazione",
          done: () => "Sincronizza gli account con Xero",
          pending: () => "Potrebbe volerci del tempo",
        },
      },
    },
    connect: () => "Connettiti a Xero",
    errors: {
      disconnecting: () =>
        "Si è verificato un problema durante la disconnessione di Xero.",
      fetchingAccounts: () =>
        "Si è verificato un errore durante il recupero dell'elenco degli account Xero.",
      fetchingSettings: () =>
        "Si è verificato un errore durante il recupero delle impostazioni di Xero.",
      fetchingTenants: () =>
        "Si è verificato un errore durante il recupero dell'elenco dei tenant Xero.",
      updatingAccounts: () =>
        "Si è verificato un errore durante l'aggiornamento degli account Xero.",
      updatingTenant: () =>
        "Si è verificato un errore durante l'aggiornamento del tenant Xero.",
    },
    integrationName: () => "Xero",
    integrationShortName: () => "Xero",
    newIntegration: {
      connected: () => " Collegato",
      connectedAt: (d) => "Ultimo collegamento " + d.date,
      description: () =>
        "Riassumi le attività commerciali giornaliere in registri contabili all'interno di Xero. Questo può aiutarti a tenere traccia delle tue finanze e a comprendere le fonti di entrate, perdite e spese. Le mappature degli account possono essere personalizzate per adattarsi al modo in cui gestisci la tua contabilità.",
      disconnectConfirmation: () =>
        "Attenzione! Vuoi davvero scollegare il tuo account Xero? Questa azione non può essere annullata, dovrai riselezionare i tuoi account in futuro. I tuoi diari sincronizzati rimarranno in Xero.",
      disconnected: () => "Disconnesso",
      status: () => "Stato: ",
      title: () => "Collega l'account Xero",
    },
    reportFooter: {
      settingsLink: () =>
        "Visualizza le impostazioni per maggiori informazioni",
      subTitle: () => "Collega il calcolatore delle criptovalute con Xero",
      title: () => "Integrazione Xero",
    },
    rollup: {
      day: () => "Giorno",
      description: {
        day: () =>
          "Tutte le transazioni effettuate in un dato giorno del calendario verranno raggruppate in un unico giornale",
        month: () =>
          "Tutte le transazioni effettuate in un dato mese solare verranno raggruppate in un unico giornale",
        none: () =>
          "Tutte le transazioni verranno esportate come giornali individuali",
      },
      month: () => "Mese",
      none: () => "Nessun rollup",
    },
    syncDialog: {
      confirmation: () =>
        "Sei sicuro di voler sincronizzare? Assicurati che i tuoi dati siano corretti. Le modifiche alle transazioni che sono già state sincronizzate non si rifletteranno in Xero.",
      error: () => "Oh no! Sembra che qualcosa sia andato storto...",
      syncing: () =>
        "Stiamo sincronizzando i dati in questo momento, l'operazione potrebbe richiedere del tempo...",
    },
    tenantList: {
      ensureCorrectTenant: () =>
        "Assicurati che sia selezionato l'account corretto per il feed di dati.",
      exportJournals: () => "Esportazione di riviste",
      listPlaceholder: () => "Seleziona feed dati",
      listTitle: () => "Conto Xero",
    },
  },
  yes: () => "SÌ",
};
