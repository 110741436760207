import { Blockchain } from "@ctc/types";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { Box, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function WalletWarningWrapper({
  blockchain,
  hasInvalidWalletError = false,
}: {
  blockchain: Blockchain;
  hasInvalidWalletError: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang();

  // make sure we show this for "btc" for now
  return blockchain === Blockchain.BTC && hasInvalidWalletError ? (
    <WarningBox>
      <WarningAmber
        sx={{
          position: "absolute",
          color: tokens.icon.warning,
        }}
      />
      <Box
        sx={{
          marginLeft: "2.5rem",
        }}
      >
        <Typography
          variant="Metropolis/Header/H5"
          sx={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
        >
          {lang.integrationWarnings[blockchain].invalidWallet.title}
        </Typography>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          sx={{
            color: tokens.text.low,
          }}
        >
          {lang.integrationWarnings[blockchain].invalidWallet.description}
        </Typography>
      </Box>
    </WarningBox>
  ) : null;
}

const WarningBox = styled(Box)`
  background-color: ${(props) => props.theme.tokens.background.warning.default};
  border-radius: 0.25rem;
  display: block;
  padding: 1rem;
  position: relative;
  height: auto;
  border: 0.063rem solid ${(props) => props.theme.tokens.border.warning};
`;
