import { Button, List, ListItem } from "@mui/material";
import { toast } from "sonner";

import { type ToastAction } from "~/types/index";

export type DisplayWarningMessageDetails = {
  title: string;
  message: string;
  warnings: string[];
  id?: string;
  action?: ToastAction;
};

export function displayReportWarning(details: DisplayWarningMessageDetails) {
  const id = details.id ?? `display-report-warning-${Date.now().toString()}`;
  const toastOptions = {
    id,
    description: (
      <div>
        <div>{details.message}</div>
        <List>
          {details.warnings.map((warning) => (
            <ListItem key={warning}>{warning}</ListItem>
          ))}
        </List>
        {details.action ? (
          <Button
            onClick={(e) => {
              details.action?.onClick(e);
              toast.dismiss(id);
            }}
          >
            {(details.action as ToastAction).label}
          </Button>
        ) : null}
      </div>
    ),
    duration: 30000,
  };

  toast.warning(details.title, toastOptions);
}
