import { Box, type BoxProps } from "@mui/material";

import { useDesign } from "~/hooks/useTheme";

export function AccountingIntegrationCard(props: BoxProps) {
  const { tokens } = useDesign();
  const { children } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      gap="1rem"
      p="1rem"
      borderRadius="0.25rem"
      border={`1px solid ${tokens.border.neutral.default}`}
      bgcolor={tokens.button.neutral.default}
      {...props}
    >
      {children}
    </Box>
  );
}
