import { Box, Typography } from "@mui/material";

import { Calco } from "~/components/ui/calco/Calco";
import { useIsMobile } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function CalcoNoRows({ isLoading }: { isLoading: boolean }) {
  const { tokens } = useDesign();
  const isMobile = useIsMobile();
  const lang = useLang();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin={isMobile ? "0" : "4rem 0"}
    >
      {/* Having issues with rem's use pixels for now */}
      <Calco type="osht" width="150px" />
      <Typography
        variant="Metropolis/Header/H5"
        style={{
          color: tokens.text.high,
          paddingTop: "0.5rem",
        }}
      >
        {isLoading
          ? lang.taxLossHarvesting.table.loading
          : lang.taxLossHarvesting.table.noHoldings}
      </Typography>
    </Box>
  );
}
