import { type Blockchain, SyncStatusPlatform } from "@ctc/types";
import { useState } from "react";

import { WalletItem } from "~/components/imports/wallet/WalletItem";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useFetchSavedAccounts } from "~/state/importsV2";
import {
  type SavedImportByIntegration,
  type SavedWalletImport,
} from "~/types/index";

export interface WalletImportListProps {
  savedImport?: SavedImportByIntegration;
  handleDelete: (wallet: SavedWalletImport) => void;
  handleEdit: (wallet: SavedWalletImport) => void;
  editingWalletAddress?: string;
  blockchain: Blockchain;
  walletName: string;
  /**
   * Only show wallets that match the `importSource` i.e only show `bitcoin` wallets that were imported from `xverse_wallet`
   * Used for `ConnectWallet` for now
   */
  importSource?: string | null;
}

export function WalletImportList({
  savedImport,
  handleDelete,
  handleEdit,
  editingWalletAddress,
  blockchain,
  walletName,
  importSource,
}: WalletImportListProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [deletingWallet, setDeletingWallet] = useState<SavedWalletImport>();
  const user = useUser();
  const wallets = importSource
    ? savedImport?.wallets?.filter(
        (wallet) =>
          wallet.importSource === importSource ||
          wallet.status === SyncStatusPlatform.Pending,
      )
    : savedImport?.wallets;

  if (!wallets?.length || !user) {
    return null;
  }

  return (
    <>
      <DeleteWalletDialog
        deletingWallet={deletingWallet}
        walletName={walletName}
        isOpen={open}
        setOpen={setOpen}
        setDeletingWallet={setDeletingWallet}
        handleDelete={handleDelete}
      />
      {wallets.map((wallet) => (
        <WalletItem
          key={wallet.id}
          wallet={wallet}
          walletName={walletName}
          blockchain={blockchain}
          highlighted={editingWalletAddress === wallet.address}
          handleEdit={handleEdit}
          handleDelete={() => {
            setDeletingWallet(wallet);
            setOpen(true);
          }}
        />
      ))}
    </>
  );
}

export function MultiChainWalletImportList({
  handleDelete,
  handleEdit,
  editingWalletAddress,
  blockchains,
  walletName,
}: {
  handleDelete: (wallet: SavedWalletImport) => void;
  handleEdit: (wallet: SavedWalletImport) => void;
  editingWalletAddress?: string;
  blockchains: Blockchain[];
  walletName: string;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [deletingWallet, setDeletingWallet] = useState<SavedWalletImport>();
  const savedAccounts = useFetchSavedAccounts();

  const savedImports =
    blockchains.flatMap((blockchain) => {
      return savedAccounts.data?.importsByIntegration[blockchain] || [];
    }) || [];

  const wallets = savedImports.flatMap((savedImport) => savedImport.wallets);

  return (
    <>
      <DeleteWalletDialog
        deletingWallet={deletingWallet}
        walletName={walletName}
        isOpen={open}
        setOpen={setOpen}
        setDeletingWallet={setDeletingWallet}
        handleDelete={handleDelete}
      />
      {wallets.map((wallet) => (
        <WalletItem
          key={wallet.id}
          wallet={wallet}
          walletName={walletName}
          blockchain={wallet.blockchain}
          highlighted={editingWalletAddress === wallet.address}
          handleEdit={handleEdit}
          handleDelete={() => {
            setDeletingWallet(wallet);
            setOpen(true);
          }}
        />
      ))}
    </>
  );
}

function DeleteWalletDialog({
  deletingWallet,
  walletName,
  isOpen,
  setOpen,
  setDeletingWallet,
  handleDelete,
}: {
  deletingWallet: SavedWalletImport | undefined;
  walletName: string;
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  setDeletingWallet: (val: SavedWalletImport | undefined) => void;
  handleDelete: (wallet: SavedWalletImport) => void;
}) {
  const lang = useLang();

  const deleteConfirmationText = deletingWallet
    ? lang.wallet.deleteConfirmationText({
        wallet: walletName,
        walletAddress:
          deletingWallet.name || formatWallet(deletingWallet.address),
      })
    : "";
  function handleConfirm(confirm: boolean, handler?: () => void): void {
    setOpen(false);
    if (confirm && handler) {
      handler();
      setDeletingWallet(undefined);
    }
  }

  function formatWallet(addr: string) {
    return addr.length > 44 ? `${addr.slice(0, 44)}...` : addr;
  }

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={lang.imports.deleteWalletConfirmTitle}
      text={deleteConfirmationText}
      actionText={lang.delete}
      handleClose={() => {
        handleConfirm(false);
      }}
      handleAction={() =>
        deletingWallet
          ? handleConfirm(true, () => {
              handleDelete(deletingWallet);
            })
          : null
      }
    />
  );
}
