import {
  CreditCard,
  OpenInNew,
  Settings,
  Storefront,
} from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { handlePayments } from "~/components/nav/AccountMenu";
import { StepItemType } from "~/components/nav/enum";
import {
  type ButtonItemDetails,
  type LinkItemDetails,
  type StepItemDetails,
} from "~/components/nav/types";
import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { Calco } from "~/components/ui/calco/Calco";
import { sizes } from "~/components/ui/theme/legacy";
import { REFERRAL_REWARD } from "~/constants/constants";
import { useSettingsModal } from "~/contexts/SettingsModalContext";
import { useSetActiveClient } from "~/hooks/useActiveClient";
import { useIsReferEnabled } from "~/hooks/useIsReferEnabled";
import { useDesign } from "~/hooks/useTheme";
import { displayFiatValue } from "~/lib";
import {
  useClientAccountantPaid,
  useClientPaid,
  useIsAccountant,
  useIsCollaborator,
  useIsEnterprise,
  useIsManagedClient,
  useIsManagingClients,
  useIsManagingOwnBilling,
  useIsStripeEnabled,
  useUser,
  useUserHasPaid,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

export function useAccountItems(): StepItemDetails[] {
  const lang = useLang();
  const { tokens } = useDesign();
  const isPaidPlan = useUserHasPaid();
  const isManagingClients = useIsManagingClients();
  const isManagingOwnBilling = useIsManagingOwnBilling();
  const isEnterprise = useIsEnterprise();
  const isClientPaidByAccountant = useClientAccountantPaid();
  const isClientPaid = useClientPaid();
  const isManagedClient = useIsManagedClient();
  const isFreeActiveUserClient =
    !(isClientPaid || isClientPaidByAccountant) && isManagedClient;
  const isAccountant = useIsAccountant();
  const isCollaborator = useIsCollaborator();
  const isStripeEnabled = useIsStripeEnabled();
  const { setOpen: setSettingsModalOpen, setActive } = useSettingsModal();
  const setActiveClient = useSetActiveClient();
  const isTablet = useMediaQuery(`(max-width: ${sizes.tabletL} )`);
  const isReferFriendEnabled = useIsReferEnabled();
  const isOnboarding = useUser()?.isOnboarding;
  const isManagePayments = isPaidPlan && !isManagingClients && !isOnboarding;
  const captureAnalytics = useCaptureAnalytics();
  const stripe = useStripe();
  const [loadingPortalSession, setLoadingPortalSession] = useState(false);
  const user = useUser();
  const billingItem: LinkItemDetails = {
    id: "billing",
    type: StepItemType.Link,
    name: lang.nav.billing,
    to: Links.Billing,
    icon: <CreditCard fontSize="small" />,
  };

  const plansItem: LinkItemDetails = {
    id: "plans",
    type: StepItemType.Link,
    name: lang.nav.plans,
    to: Links.Plans,
    icon: <Storefront fontSize="small" />,
  };

  const managePaymentsItem: ButtonItemDetails = {
    id: "payments",
    type: StepItemType.Button,
    name: lang.nav.manageSubscription,
    onClick: () => {
      captureAnalytics("manage_payments_clicked");
      handlePayments(setLoadingPortalSession, stripe, lang);
    },
    icon: <CreditCard fontSize="small" />,
    endAdornment: loadingPortalSession ? (
      <CircularProgress size="1rem" sx={{ marginLeft: "0.5rem" }} />
    ) : (
      <OpenInNew
        fontSize="inherit"
        sx={{ marginLeft: "0.5rem", color: tokens.text.default }}
      />
    ),
    disabled: loadingPortalSession,
  };

  const referFriendsItem: LinkItemDetails = {
    id: "refer",
    type: StepItemType.Link,
    name: lang.navbar.referFriendsV2({
      reward: displayFiatValue({
        value: REFERRAL_REWARD,
        localCurrency: user?.planCurrency,
        fractionDigits: 0,
      }),
    }),
    to: Links.ReferAFriend,
    // eslint-disable-next-line
    icon: (<Calco type={"captain"} width="22px" />) as any,
  };

  const followUsOnXItem: LinkItemDetails = {
    id: "follow-us-on-x",
    type: StepItemType.Link,
    name: lang.navbar.followUsOnX,
    to: "https://x.com/cryptotaxhq",
    icon: <XIcon fontSize="small" />,
  };

  const settingsItem: StepItemDetails = isManagingClients
    ? {
        id: "workspaceSettings",
        type: StepItemType.Button,
        name: lang.navbar.mySettings,
        onClick: () => {
          setActiveClient("");
          setActive(SettingsTabs.Loading);
        },
        icon: <Settings fontSize="small" />,
      }
    : {
        id: "settings",
        type: StepItemType.Button,
        name: lang.nav.settings,
        onClick: () => {
          setSettingsModalOpen(true);
        },
        icon: <Settings fontSize="small" />,
      };

  // Enterprise (H&R Block)
  if (isEnterprise) {
    return [settingsItem];
  }

  const accountItems: StepItemDetails[] = [settingsItem];

  const showBilling =
    isPaidPlan && isManagingOwnBilling && isAccountant && isStripeEnabled;

  const showPlans = () => {
    // Plans page is shown to accountants and those managing billings who are a managed client
    // Show plans to paid users who are linked to accountants
    if (isAccountant || (isPaidPlan && !isManagingClients)) {
      return true;
    }

    // Hide plans from free users who have linked to accountants or otherwise
    // Plans to be hidden from users whose accountants have paid for them and also collaborators as they shouldn't upgrade or cancel plans
    if (isFreeActiveUserClient ?? isClientPaidByAccountant ?? isCollaborator) {
      return false;
    }

    // show plans to users who are not linked to accountant on paid plan and all free users
    return true;
  };

  const isShowPlans = showPlans();

  if (showBilling) {
    accountItems.push(billingItem);
  }

  // show the plans if managing own billing
  if (isShowPlans) {
    accountItems.push(plansItem);
  }

  if (isManagePayments) {
    accountItems.push(managePaymentsItem);
  }

  if (isReferFriendEnabled) {
    accountItems.push(referFriendsItem);
  }

  // Show the follow us on X item last
  accountItems.push(followUsOnXItem);

  return accountItems;
}
