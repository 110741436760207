import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  InputBase,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type * as React from "react";
import { type Dispatch, type SetStateAction } from "react";
import { VariableSizeList } from "react-window";
import styled from "styled-components/macro";

import { CommandMenuListStyled } from "~/components/transactions/command-palette/CommandMenuList";
import {
  CommandPaletteTitle,
  TitleContainer,
} from "~/components/transactions/command-palette/CommandPaletteTitle";
import {
  type Option,
  useCommandPaletteMenu,
} from "~/components/transactions/command-palette/hooks/useCommandPaletteMenu";
import { NoResults } from "~/components/transactions/command-palette/NoResults";
import { ProgressDivider } from "~/components/transactions/command-palette/ProgressDivider";
import { DividerStyled } from "~/components/transactions/command-palette/views/DividerStyled";
import { Ellipsis } from "~/components/transactions/Ellipsis";
import { useIsMobile } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

type CommandPaletteMenuProps<TOption extends Option> = {
  options: TOption[] | Readonly<TOption[]>;
  onSelection: (option: TOption) => void;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  topArea?: React.ReactNode;
  searchAreaRightButton?: React.ReactNode;
  onSearch?: (value: string) => void;
};

export const Row = ({
  index,
  style,
  data,
}: {
  index: number;
  style: any;
  data: {
    // cant get the generic TOption to work with option
    // so having to use any
    option: any;
    disabled: boolean | undefined;
    setSelectedIndex: Dispatch<SetStateAction<number>>;
    handleOptionSelect: (option: any, index: number) => void;
    selectedIndex: number;
    sectionLabel: string | undefined;
    sectionId: string | undefined;
    usingKeyNav: boolean;
  }[];
}) => {
  const rowData = data[index];
  const { option } = rowData as {
    option: Option;
  };
  const { sectionLabel, sectionId } = rowData;
  const isSelected = index === rowData.selectedIndex;
  const { tokens } = useDesign();
  const lang = useLang();
  const isMobile = useIsMobile();

  const MenuItem = (
    <StyledMenuItem
      disabled={rowData.disabled || rowData.option.disabled}
      onClick={() => {
        rowData.handleOptionSelect(option, index);
      }}
      selected={isSelected}
      sx={{
        ":hover": {
          backgroundColor: rowData.usingKeyNav
            ? "transparent"
            : tokens.background.neutral.hover,
          color: rowData.usingKeyNav ? tokens.text.low : tokens.text.high,
        },
        "&.Mui-selected:hover": {
          backgroundColor: tokens.background.neutral.pressed,
          color: tokens.text.default,
        },
      }}
    >
      <IconLabel>
        {option.icon}
        <Ellipsis maxWidth={isMobile ? 190 : 500}> {option.label}</Ellipsis>
      </IconLabel>
      <Stack direction="row" gap="0.5rem" alignItems="center">
        {option.endIcon ? option.endIcon : null}
        {option.chevron && isSelected ? <ChevronRightIcon /> : null}
      </Stack>
    </StyledMenuItem>
  );

  return (
    <Box
      style={{
        ...style,
        paddingTop: "0.5rem",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      }}
    >
      <DividerStyled sectionId={sectionId} index={index}>
        {sectionLabel ? (
          <Typography variant="Metropolis/Caption/Medium/Regular">
            {option.sectionLabel}
          </Typography>
        ) : null}

        {rowData.option.disabled ? (
          <Tooltip
            title={
              rowData.option.disabledTooltip ??
              lang.txTable.commandPallet.notAvailableOnSelectAll
            }
            placement="top"
          >
            <div>{MenuItem}</div>
          </Tooltip>
        ) : (
          MenuItem
        )}
      </DividerStyled>
    </Box>
  );
};

export const CommandPaletteMenu = <TOption extends Option>({
  options,
  onSelection,
  disabled,
  loading,
  placeholder,
  topArea,
  searchAreaRightButton,
  onSearch,
}: CommandPaletteMenuProps<TOption>) => {
  const {
    filteredOptions,
    setIsUsingKeyNav,
    inputSearchProps,
    variableSizeListProps,
    noResultProps,
  } = useCommandPaletteMenu({
    disabled,
    loading,
    onSelection,
    options,
    placeholder,
    itemSize: (option, index: number, filteredOptions) => {
      const prevOption = filteredOptions[index - 1];
      if (option.sectionId !== prevOption?.sectionId && index !== 0) {
        return 50 + 1 + 16 * 0.5;
      }
      return 50;
    },
    maxMenuHeight: 300,
    onSearch,
  });

  return (
    <Box>
      <TitleContainer>
        <CommandPaletteTitle topArea={topArea} loading={loading} />
        <Stack direction="row" gap="0.5rem" alignItems="center">
          <InputSearch {...inputSearchProps} />
          {searchAreaRightButton}
        </Stack>
      </TitleContainer>

      <ProgressDivider loading={loading} />

      <NoResults {...noResultProps} />

      {filteredOptions.length > 0 ? (
        <CommandMenuListStyled
          as="div"
          onMouseMove={() => {
            setIsUsingKeyNav(false);
          }}
        >
          <VariableSizeList {...variableSizeListProps}>{Row}</VariableSizeList>
        </CommandMenuListStyled>
      ) : null}
    </Box>
  );
};

const InputSearch = styled(InputBase)(({ theme }) => ({
  ...theme.mui.typography['Metropolis/Caption/Medium/Regular'],
  fontSize: "1.25rem",
  width: "100%",
  "& .MuiInputBase-input": {
    color: theme.tokens.text.default,
    "&::placeholder": {
      color: theme.tokens.text.disabled,
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)`
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => ({ ...theme.mui.typography['Metropolis/Caption/Medium/Regular'] })};
  color: ${({ theme }) => theme.tokens.text.low};
  padding: 0.75rem 1rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.tokens.background.neutral.default};

  &.Mui-selected {
    background-color: ${({ theme }) => theme.tokens.background.neutral.pressed};
    color: ${({ theme }) => theme.tokens.text.default};
  }
`;

export const IconLabel = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`;
