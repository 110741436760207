import { type Trade } from "@ctc/types";

import { useSelectedIds } from "~/hooks/useTransactions";
import { useGetActionsQuery } from "~/state/actions";

/**
 * Gets the unique set of trade types the user has selected
 * @returns The set of trade types
 */
export function useSelectedTradeTypes() {
  const selectedIds = useSelectedIds();
  const query = useGetActionsQuery();
  const uniqueTradeTypesSet = new Set<string>();
  query.data?.transactions.forEach((action) => {
    if (selectedIds.length && !selectedIds.includes(action._id)) {
      return [];
    }
    [...action.outgoing, ...action.incoming, ...action.fees].forEach(
      (transaction) => {
        uniqueTradeTypesSet.add(transaction.trade);
      },
    );
  });
  const uniqueTradeTypes = Array.from(uniqueTradeTypesSet) as Trade[];
  return uniqueTradeTypes;
}
