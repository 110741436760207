import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { getOptionsFromTradeTypes } from "~/components/transactions/command-palette/helpers/getOptionsFromTradeTypes";
import { useSelectedTradeTypes } from "~/components/transactions/command-palette/helpers/useSelectedTradeTypes";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { GainsAccountTopSection } from "~/components/transactions/command-palette/views/change-gains-account/GainsAccountTopSection";
import { SelectGainsAccount } from "~/components/transactions/command-palette/views/change-gains-account/SelectGainsAccount";
import { useLang } from "~/redux/lang";

/**
 * Select which trade type the user wants to map a gains account
 * If there is only one, we skip this screen
 */
export const SelectGainsTradeType = () => {
  const { push } = useNavController();
  const lang = useLang();

  const tradeTypes = useSelectedTradeTypes();
  // Only one trade type, so we dont even need to ask
  if (tradeTypes.length === 1) {
    return <SelectGainsAccount tradeType={tradeTypes[0]} />;
  }
  // get trade type options
  const options = getOptionsFromTradeTypes(tradeTypes, lang);
  return (
    <CommandPaletteMenu
      topArea={<GainsAccountTopSection />}
      placeholder={lang.txTable.commandPallet.placeholders.tradeType}
      options={options}
      onSelection={({ tradeType }) => {
        push(<SelectGainsAccount tradeType={tradeType} />);
      }}
    />
  );
};
