import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { type PartyDetails } from "~/services/transactions";

/**
 * Party Icon for bulk edit chip section
 */
export function BulkEditPartyChipIcon({ party }: { party: PartyDetails }) {
  return (
    <ExchangeLogo
      width={16}
      height={16}
      name={party.exchange}
      walletAddress={party.exchange}
      blockchain={party.blockchain}
      showBlockchainSymbol={!!party.blockchain}
    />
  );
}
