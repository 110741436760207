import { CheckCircleOutline, InfoOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";

import { AccountingIntegrationCard } from "~/components/accounting-integrations/AccountingIntegrationCard";
import { AccountSelector } from "~/components/settings-modal/views/integrations/AccountSelector";
import { Calco } from "~/components/ui/calco/Calco";
import { useDesign } from "~/hooks/useTheme";
import { useErpLang, useLang } from "~/redux/lang";
import { type ErpAccount } from "~/redux/types";
import {
  useErpAvailableAccountsQuery,
  useErpSettingsQuery,
  useErpUserAccounts,
  useUpdateErpAccountMutation,
} from "~/state/erp";
import { ErpUserAccounts } from "~/types/enums";

export const REQUIRED_ACCOUNTS_IN_SELECTION_ORDER = [
  ErpUserAccounts.Cash,
  ErpUserAccounts.Fee,
  ErpUserAccounts.RealisedGain,
  ErpUserAccounts.RealisedLoss,
  ErpUserAccounts.ZeroCostBuy,
];

export function DefaultCards() {
  const lang = useLang();
  const erpQuery = useErpSettingsQuery();
  const erp = erpQuery.data;

  if (erpQuery.isPending) {
    return (
      <AccountingIntegrationCard>
        <Box
          display="flex"
          width="100%"
          height="25rem"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      </AccountingIntegrationCard>
    );
  }

  if (!erp) {
    return (
      <AccountingIntegrationCard>
        <Box textAlign="center" p="2rem">
          <Calco type="moon" height="170px" />
          <Typography>{lang.accountingIntegrations.defaults.empty}</Typography>
        </Box>
      </AccountingIntegrationCard>
    );
  }

  return (
    <AccountingIntegrationCard>
      <Typography variant="Metropolis/Header/H4">
        {lang.accountingIntegrations.defaults.title}
      </Typography>
      {REQUIRED_ACCOUNTS_IN_SELECTION_ORDER.map((accountType) => (
        <AccountMappingSection
          key={accountType}
          accountType={accountType}
          required
        />
      ))}
    </AccountingIntegrationCard>
  );
}

function AccountMappingSection({
  accountType,
  required = false,
}: {
  accountType: ErpUserAccounts;
  required?: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang().accountingIntegrations.defaults;

  const erpQuery = useErpSettingsQuery();
  const erp = erpQuery.data;
  const erpLang = useErpLang(erp?.erp);

  const updateUserAccountMutation = useUpdateErpAccountMutation();
  const erpUserAccounts = useErpUserAccounts();
  const erpAccountsQuery = useErpAvailableAccountsQuery();
  const isLoadingData = erpAccountsQuery.isPending;

  const selectedAccount = erpUserAccounts ? erpUserAccounts[accountType] : null;

  const handleAccountChange = (
    account: ErpUserAccounts,
    value: ErpAccount | null,
  ) => {
    if (value && value !== selectedAccount) {
      updateUserAccountMutation.mutate({ account, value });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="0.5rem">
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="0.5rem"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center" gap="0.5rem">
          <Typography variant="Metropolis/Header/H5">
            {lang.accountMapping[accountType].title}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="0.5rem" flexWrap="wrap">
          {isLoadingData ? (
            <Skeleton width="5rem" height="1.5rem" />
          ) : (
            <>
              {required && !selectedAccount ? (
                <Box
                  p="0.12rem 0.5rem"
                  borderRadius="1.5rem"
                  bgcolor={tokens.background.warning.default}
                  color={tokens.text.warning}
                  fontSize="0.75rem"
                >
                  {lang.required}
                </Box>
              ) : null}
              <MappedChip mapped={!!selectedAccount} />
            </>
          )}
        </Box>
      </Box>
      <Typography variant="Metropolis/Caption/Medium/Regular" color={tokens.text.low}>
        {lang.accountMapping[accountType].tooltip}
      </Typography>
      {isLoadingData ? (
        <Skeleton />
      ) : (
        <AccountSelector
          title={undefined}
          tooltip={undefined}
          placeholder={lang.selectorPlaceholder({
            account: lang.accountMapping[accountType].title.toLowerCase(),
            erp: erpLang.integrationShortName,
          })}
          value={selectedAccount}
          handleChange={(_e, value) => {
            handleAccountChange(accountType, value);
          }}
        />
      )}
    </Box>
  );
}

function MappedChip({ mapped }: { mapped: boolean }) {
  const { tokens } = useDesign();
  const lang = useLang().accountingIntegrations.defaults;

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="0.25rem"
      p="0.12rem 0.5rem"
      borderRadius="1.5rem"
      bgcolor={
        mapped
          ? tokens.background.success.default
          : tokens.background.accent.neutral.low
      }
      color={mapped ? tokens.text.success : tokens.text.default}
      fontSize="0.75rem"
    >
      {mapped ? (
        <CheckCircleOutline sx={{ fontSize: "1rem" }} />
      ) : (
        <InfoOutlined sx={{ fontSize: "1rem" }} />
      )}
      {mapped ? lang.mapped : lang.unmapped}
    </Box>
  );
}
