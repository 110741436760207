import { Typography } from "@mui/material";
import { useContext } from "react";

import { PaywallModalContext } from "~/contexts/paywall-modal-context/PaywallModalContext";
import { useDesign } from "~/hooks/useTheme";
import {
  type HyperlinkKeyToType,
  HyperlinkTextContent,
} from "~/lib/HyperlinkTextContent";
import {
  useIsAccountant,
  useIsCollaborator,
  useIsEnterprise,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";

export function TipsPaywallTooltip() {
  const lang = useLang();
  const { tokens } = useDesign();
  const isAccountant = useIsAccountant();
  const isEnterprise = useIsEnterprise();
  const isCollaborator = useIsCollaborator();
  const paywallContext = useContext(PaywallModalContext);

  const linkTextKey = "linkTextKey";
  const hyperlinkProps = ((): HyperlinkKeyToType => {
    // If the user is an accountant, open the accountant modal
    if (isAccountant) {
      return {
        [linkTextKey]: {
          fn: () => {
            paywallContext?.setUpgradeClientDialogOpen(true);
          },
        },
      };
    }

    // If the user is a regular user, redirect them to the plans page
    if (!isCollaborator && !isEnterprise) {
      return { [linkTextKey]: { linkTo: "/plans" } };
    }

    return { [linkTextKey]: { fn: null } };
  })();

  return (
    <Typography variant="Metropolis/Body/Regular" color={tokens.special.tooltipText}>
      <HyperlinkTextContent
        text={lang.navbar.tooltipReview({
          linkTextKey,
        })}
        actions={hyperlinkProps}
        linkColor={tokens.special.tooltipLinkText}
      />
    </Typography>
  );
}
