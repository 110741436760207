export enum CheckboxActionType {
  SetSelectedIds = "SetSelectedIds",
  SetShiftSelectedId = "SetShiftSelectedId",
  RemoveSelectedId = "RemoveSelectedId",
  AddSelectedId = "ShiftAddSelectedId",
  ResetSelectedIds = "ResetSelectedIds",
  SetSelectAll = "SetSelectAll",
  ResetSelectAll = "ResetSelectAll",
}
export enum ButtonType {
  Text = "text",
  Tertiary = "tertiary",
}
export enum FilterActionType {
  SetFilter = "SetFilter",
  ResetFilter = "ResetFilter",
  GoToPage = "GoToPage",
  SetCount = "SetCount",
  SetSort = "SetSort",
  SetHighLight = "SetHighLight",
  SetLedger = "SetLedger",
  SetHideLocked = "SetHideLocked",
  SetReconciliationViewOneByOneFilter = "SetReconciliationViewOneByOneFilter",
}
export enum FilterContextKey {
  TransactionsPage = "transactions",
  Ledger = "ledger",
  UncategorisedTipsPage = "uncategorised",
  UnmatchedTransfersPage = "unmatched-transfers",
}
export enum ContextIdType {
  TxId = "txId",
  ActionId = "actionId",
}
