import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { setUpdateBestActiveUser, useUser } from "~/redux/auth";
import * as settingsAPI from "~/services/settings";

export function Alpha() {
  const user = useUser();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const changeStatus = (newStatus: boolean) => async () => {
    setLoading(true);
    const res = await settingsAPI.updateAlphaStatus(newStatus);

    if (!res) return;

    dispatch(setUpdateBestActiveUser({ isInAlpha: newStatus }));
    setLoading(false);
  };

  if (!user || loading) return <p>Loading</p>;

  return (
    <Box sx={{ margin: 4 }}>
      {user.isInAlpha ? (
        <Button onClick={changeStatus(false)}>Opt Out</Button>
      ) : (
        <Button onClick={changeStatus(true)}>Opt In</Button>
      )}
    </Box>
  );
}
