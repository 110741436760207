import { type LocalCurrency } from "@ctc/types";

import { hashExchangeId } from "~/lib/hashExchangeId";
import { getPartyDetails } from "~/services/transactions";
import { FilterOperators, Side } from "~/types/enums";
import { type ActionRow, type FilterQuery } from "~/types/index";

// Given an action row, return the counterparty transaction side
export function getActionRowCounterParty(actionRow: ActionRow) {
  const { incoming, outgoing, fees } = actionRow;

  if (outgoing.length) {
    return getPartyDetails(outgoing[0], Side.To);
  }

  if (incoming.length) {
    return getPartyDetails(incoming[0], Side.From);
  }

  if (fees.length) {
    return getPartyDetails(fees[0], Side.From);
  }

  return null;
}

export function getAssociatedTxFilterQuery(row: ActionRow) {
  const counterParty = getActionRowCounterParty(row);
  const filter: FilterQuery = {
    type: FilterOperators.Or,
    rules: counterParty
      ? [
          {
            type: FilterOperators.To,
            value: [hashExchangeId(counterParty)],
          },
          {
            type: FilterOperators.From,
            value: [hashExchangeId(counterParty)],
          },
        ]
      : [],
  };

  return filter;
}

export function getCompactFormattedValue(
  locale: string,
  value: number,
  localCurrency: LocalCurrency | null,
  defaultValue: string,
) {
  // Validate locale
  try {
    // Test if locale is valid
    new Intl.NumberFormat(locale);
  } catch (e) {
    console.warn(`Invalid locale "${locale}", falling back to 'en-US'`);
    locale = "en-US";
  }

  const options: Intl.NumberFormatOptions = {
    notation: "compact",
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  };

  if (!localCurrency) {
    console.warn(`Invalid currency code: ${localCurrency}. Defaulting to USD.`);
    options.currency = "USD"; // Default to USD if invalid
  }

  // Values over 100,000,000 should be formatted by $xxx.x M
  if (Math.abs(value) >= 100_000_000) {
    return new Intl.NumberFormat(locale, {
      ...options,
    }).format(value);
  }

  return defaultValue;
}
