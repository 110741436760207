import { CaptchaProvider } from "@ctc/types";
import { Alert, Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { LanguageButton } from "~/components/nav/LanguageButton";
import { Breaker } from "~/components/ui/Breaker";
import { PasswordTextField } from "~/components/ui/PasswordTextField";
import { StyledNoLineLink } from "~/components/ui/StyledLink";

import { displayMessage } from "~/components/ui/Toaster";
import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { MetamaskWalletAuthButton } from "~/components/user/components/MetamaskWalletAuthButton";
import { EmbeddedNonChromiumBlock } from "~/components/user/EmbeddedNonChromiumBlock";
import { useIsChromiumOrNewerSafari184 } from "~/components/user/hooks/useIsChromiumOrNewerSafari184";
import { Recaptcha } from "~/components/user/Recaptcha";
import { Turnstile } from "~/components/user/Turnstile";
import {
  formatEmail,
  isValidEmail,
  stripSpacesOnKeyDown,
} from "~/components/user/validators";
import { useCaptcha } from "~/hooks/useCaptchaVerification";
import { useIsEmbeddedOAuthAvailable } from "~/hooks/useIsEmbeddedOAuthAvailable";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useDesign } from "~/hooks/useTheme";
import {
  login,
  resetError,
  useAuth,
  useWalletOAuthLogin,
  verify2fa,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Analytics } from "~/segment/index";
import { DisplayMessage, Links } from "~/types/enums";

import { PoweredByCtcAuthContainer } from "./EmbeddedSignupForm";

export const EmbeddedLoginForm = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [incorrectMethod] = useQueryParam("incorrectMethod", StringParam);
  const [isCodeRequired, setIsCodeRequired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [code, setCode] = useState("");
  const { error } = useAuth();
  const { isLoading: isLoadingWalletAuth } = useWalletOAuthLogin();
  const isEmbeddedOAuthAvailable = useIsEmbeddedOAuthAvailable();
  const isMobile = useIsMobile();

  const {
    recaptchaRef,
    setTurnstileCaptcha,
    getCaptchaToken,
    captchaProvider,
    turnstileCaptcha,
  } = useCaptcha();

  // Check if our form is valid
  const canSubmit = email.length > 0 && password.length > 0;

  useEffect(() => {
    if (incorrectMethod) {
      displayMessage({
        message: lang.auth.differentSignUpMethod,
        type: DisplayMessage.Error,
      });
    }
  }, [incorrectMethod, lang.auth.differentSignUpMethod]);

  const removeAuthWarning = () => dispatch(resetError());

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    removeAuthWarning();

    if (!isValidEmail(email)) {
      displayMessage({
        message: lang.auth.invalidCombination,
        type: DisplayMessage.Error,
      });
      return;
    }

    const captcha = await getCaptchaToken({ login: true });
    if (!captcha) {
      return; // Error already displayed by the hook
    }

    const analytics = await Analytics.getInstance();
    if (!isCodeRequired) {
      analytics.track({
        event: "embedded_login_button_clicked",
        props: { email },
      });
    }

    dispatch(
      login(
        { email, password, captcha, captchaProvider },
        setIsCodeRequired,
        setIsSubmitting,
      ),
    );
  };

  const handle2faSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    removeAuthWarning();

    dispatch(verify2fa({ code }, setIsSubmitting));
  };

  const isChromiumOrNewerSafari = useIsChromiumOrNewerSafari184();
  if (!isChromiumOrNewerSafari) {
    return <EmbeddedNonChromiumBlock />;
  }

  if (isCodeRequired) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        minWidth="100vw"
        padding="1.5rem"
        sx={{
          backgroundColor: tokens.elevation.default,
        }}
      >
        <PoweredByCtcAuthContainer>
          <Box
            component="form"
            onSubmit={handle2faSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Typography variant="Metropolis/Header/H3">
              {lang.twoFactorAuth.twoStepVerification}
            </Typography>
            <Typography>{lang.twoFactorAuth.enterCodeSubText}</Typography>
            {error ? (
              <Alert severity="error" sx={{ mb: "2rem" }}>
                {error}
              </Alert>
            ) : null}
            <TextField
              variant="outlined"
              name="code"
              label={lang.twoFactorAuth.enterCodeLabel}
              required
              helperText={lang.twoFactorAuth.enterCodeOrRecoveryCodeHelperText}
              fullWidth
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: "0.75rem",
                justifyContent: "flex-end",
                marginTop: "1rem",
                width: "100%",
              }}
            >
              <TertiaryButton
                variant="outlined"
                onClick={() => {
                  setIsCodeRequired(false);
                  setCode("");
                  setIsSubmitting(false);
                }}
              >
                {lang.cancel}
              </TertiaryButton>
              <PrimaryLoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
              >
                {lang.twoFactorAuth.submit}
              </PrimaryLoadingButton>
            </Box>
          </Box>
        </PoweredByCtcAuthContainer>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      minWidth="100vw"
      padding="1.5rem"
      sx={{
        backgroundColor: tokens.elevation.default,
      }}
    >
      <PoweredByCtcAuthContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginBottom="1.75rem"
        >
          <Box display="flex" flexDirection="column" gap="0.25rem">
            <Typography
              variant={
                isMobile ? "Metropolis/Header/H4" : "Metropolis/Header/H3"
              }
            >
              {lang.auth.loginTitleEmbedded}
            </Typography>
            <Box textAlign="center">
              <Typography variant="Metropolis/Caption/Large/Regular">
                {`${lang.auth.noAccount} `}
                <StyledNoLineLink to={Links.Signup} onClick={removeAuthWarning}>
                  {lang.auth.signup}
                </StyledNoLineLink>
              </Typography>
            </Box>
          </Box>
          <LanguageButton />
        </Box>
        {isEmbeddedOAuthAvailable ? (
          <Box display="flex" flexDirection="column" gap="0.5rem" width="100%">
            <MetamaskWalletAuthButton
              isLoading={isLoadingWalletAuth}
              text={lang.auth.embedded.signInWith({
                provider: lang.auth.metamask,
              })}
            />

            <Breaker
              text={lang.auth.orLogin}
              background={tokens.elevation.low}
            />
          </Box>
        ) : null}
        <form
          onSubmit={handleSubmit}
          style={{
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {error ? <Alert severity="error">{error}</Alert> : null}
          <TextField
            variant="outlined"
            name="email"
            type="email"
            label={lang.auth.email}
            required
            fullWidth
            onKeyDown={stripSpacesOnKeyDown}
            onChange={(e) => {
              setEmail(formatEmail(e.target.value));
            }}
            value={email}
          />
          <PasswordTextField
            variant="outlined"
            name="password"
            label={lang.auth.password}
            autoComplete="current-password"
            required
            fullWidth
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
          />
          <Box display="flex" flexDirection="row-reverse">
            <Typography variant="Metropolis/Caption/Medium/Regular">
              <Link
                to={Links.Forgot}
                style={{ textDecoration: "none", color: tokens.text.brand }}
              >
                {lang.auth.forgot}
              </Link>
            </Typography>
          </Box>
          {captchaProvider === CaptchaProvider.Turnstile ? (
            <Box padding="0.0625rem">
              <Turnstile onVerify={setTurnstileCaptcha} />
            </Box>
          ) : null}
          <PrimaryLoadingButton
            type="submit"
            disabled={
              isSubmitting ||
              !canSubmit ||
              (captchaProvider === CaptchaProvider.Turnstile &&
                !turnstileCaptcha)
            }
            fullWidth
            loading={isSubmitting}
            sx={{
              height: "2.75rem",
              borderRadius: "62.5rem",
            }}
          >
            <Typography variant={"Metropolis/Header/H5"} color="inherit">
              {lang.auth.signin}
            </Typography>
          </PrimaryLoadingButton>
        </form>
      </PoweredByCtcAuthContainer>
      {captchaProvider === CaptchaProvider.Recaptcha ? (
        <Box marginTop="1rem">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </Box>
      ) : null}
    </Box>
  );
};
