import { Typography } from "@mui/material";
import styled from "styled-components/macro";

import { useDesign } from "~/hooks/useTheme";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import * as auth from "~/services/user";

const Banner = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  padding: 0 1.125rem;
  min-height: 3rem;
  color: ${({ theme }) => theme.tokens.text.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PromotionSpan = styled.span`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const CouponSpan = styled.span`
  color: ${({ theme }) => theme.tokens.text.danger};
  font-weight: 700;
`;

export function CouponBanner(): JSX.Element | null {
  const { tokens } = useDesign();
  const lang = useLang();
  const user = useUser();
  if (user && auth.isPaidPlan(user.paidPlan)) {
    return null;
  }

  return (
    <Banner color={tokens.special.lowest}>
      <Typography variant="Metropolis/Body/Regular" align="center">
        <PromotionSpan>
          {lang.topBanner.msg}
          <CouponSpan>{lang.topBanner.coupon}</CouponSpan>
        </PromotionSpan>
      </Typography>
    </Banner>
  );
}
