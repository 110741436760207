import { ReferrerSource } from "@ctc/types";

import { useScreenedFiatValue } from "~/hooks/useScreenedFiatValue";
import { displayFiatValue } from "~/lib/index";
import { useCountry, useLocalCurrency, useUser } from "~/redux/auth";
import { useLanguagePreference } from "~/redux/lang";
import {
  type ReportSummary,
  shouldReplaceCGTWithPortfolioValue,
} from "~/types/index";

/**
 * Given the report summary data, computes the total gains value. Has logic to
 * handle screening the number, and considers the embedded experience
 */
export function useGetTotalGainsValue() {
  const displayScreenedFiatValue = useScreenedFiatValue();
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const country = useCountry();

  const user = useUser();
  const referrerSource = user?.referrerSource;

  return (summaryData: ReportSummary) => {
    const value = shouldReplaceCGTWithPortfolioValue(country)
      ? summaryData.openingValue
      : summaryData.totalProfit;

    // Metamask specifically wants the total gains number to not be paywalled when in embedded mode
    if (referrerSource === ReferrerSource.MetamaskEmbedded) {
      return displayFiatValue({
        value,
        localCurrency,
        locale: languagePreference,
      });
    }

    return displayScreenedFiatValue(value);
  };
}
