import { useQuery } from "@tanstack/react-query";

import { displayMessage } from "~/components/ui/Toaster";
import { useUser } from "~/redux/auth";
import { HttpError } from "~/services/core";
import { getPlans } from "~/services/subscription";
import { DisplayMessage } from "~/types/enums";

export const plansQueryKeys = {
  all: () => ["plans"] as const,

  lists: () => [...plansQueryKeys.all(), "lists"] as const,

  retailPlans: () => [...plansQueryKeys.lists(), "retail"] as const,
  businessPlans: () => [...plansQueryKeys.lists(), "business"] as const,
};

export const usePlans = () => {
  const user = useUser();
  return useQuery({
    queryKey: plansQueryKeys.retailPlans(),
    queryFn: async () => {
      const res = await getPlans({ business: false });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["get plans"]);
      }

      return res.data;
    },
    enabled: !!user?.country,
  });
};

export const useBusinessPlans = () => {
  return useQuery({
    queryKey: plansQueryKeys.businessPlans(),
    queryFn: async () => {
      const res = await getPlans({ business: true });

      if (res.error) {
        displayMessage({
          message: res.msg ?? "",
          type: DisplayMessage.Error,
        });
        throw new HttpError(res, ["get business plans"]);
      }

      return res.data;
    },
  });
};

export const useAllPlans = () => {
  const retailPlans = usePlans();
  const businessPlans = useBusinessPlans();

  return {
    ...retailPlans.data,
    ...businessPlans.data,
  };
};
