import { ArrowDropDown } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Divider, Popover, Stack } from "@mui/material";
import sum from "lodash/sum";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";

import { useCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import {
  ModalProvider,
  useModal,
} from "~/components/transactions/command-palette/ModalProvider";
import {
  NavControllerProvider,
  useNavController,
} from "~/components/transactions/command-palette/NavController";
import { SmallCommandPaletteMenu } from "~/components/transactions/command-palette/SmallCommandPaletteMenu";
import { BasicPopover } from "~/components/transactions/filter-bar/BasicPopover";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  ButtonType,
  CheckboxActionType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { FilterAutocomplete } from "~/components/transactions/filter-bar/FilterSearch";
import { useIsMobile } from "~/components/ui/hooks";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useAutoAnimate } from "~/hooks/useAutoAnimate";
import { useDesign } from "~/hooks/useTheme";
import { FilterCountType } from "~/lib/enums";
import {
  filterOperatorConfig,
  getFilterCount,
  isQueryBuilderEnabledOperator,
} from "~/lib/filterOperatorConfig";
import { useCanAccessFeature } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useGetFilterOptionsQuery } from "~/state/actions";
import { useErpSettingsQuery } from "~/state/erp";
import { Features, FilterOperators, type Warning } from "~/types/enums";
import { type FilterQuery, isAndFilter, isOrFilter } from "~/types/index";

type FilterControl = {
  field: string;
  label: string;
  renderDropdownContent?: (handleCancel: () => null) => JSX.Element;
};
enum NegationOptions {
  Is = "is",
  IsNot = "isNot",
}

type SelectDropdownProps = {
  selection: FilterControl | string;
  selectionOptions: FilterControl[];
  setSelection: (v: string) => void;
  buttonType: ButtonType;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
  disableAddFullWidth?: boolean;
};

export const SelectDropdownWrapper = (props: SelectDropdownProps) => {
  return (
    <NavControllerProvider>
      <ModalProvider variant="popover" popupId="filter-builder-selector-">
        <SelectDropdown {...props} />
      </ModalProvider>
    </NavControllerProvider>
  );
};

const SelectDropdown = ({
  selection,
  selectionOptions,
  setSelection,
  buttonType,
  startIcon,
  endIcon,
  disabled,
  disableAddFullWidth,
}: SelectDropdownProps) => {
  const { tokens } = useDesign();
  const anchor = useRef<HTMLDivElement>(null);
  const modal = useModal();
  const { open, close } = useCommandPalette();
  const { current, views, pop } = useNavController();
  const lang = useLang();

  const optionsWithDescription = useMemo(() => {
    const descriptions: Record<string, string> =
      lang.txTable.filter.descriptions;
    return selectionOptions.map((option) => {
      if (option.field in descriptions) {
        return {
          ...option,
          description: descriptions[option.field],
        };
      }
      return option;
    });
  }, [selectionOptions, lang]);

  const handleClose = useCallback(
    (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
      // if you are loading, you cant close it
      if (modal.loading) {
        return;
      }
      // at the root page, so escape gets you out
      if (reason === "backdropClick" || views.length === 1) {
        close();
        return;
      }
      // not at the root page, so go back 1 view
      pop();
    },
    [close, modal.loading, pop, views.length],
  );

  const FilterButton =
    buttonType === ButtonType.Text ? TextButton : TertiaryButton;

  const root = (
    <SmallCommandPaletteMenu
      options={optionsWithDescription}
      placeholder={lang.txTable.filter.search}
      selectedOptionLabel={
        typeof selection === "string" ? selection : selection.label
      }
      onSelection={(option) => {
        setSelection(option.field);
        close();
      }}
    />
  );

  const widthStyle = disableAddFullWidth ? {} : { width: "100%" };
  return (
    <Box>
      <div ref={anchor}>
        <FilterButton
          {...bindTrigger(modal)}
          size="medium"
          disabled={disabled}
          sx={
            buttonType === ButtonType.Text
              ? {
                  display: "flex",
                  justifyContent: "flex-start",
                  ...widthStyle,
                }
              : {
                  whiteSpace: "nowrap",
                }
          }
          startIcon={startIcon}
          endIcon={endIcon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();

            open(root, e);
          }}
        >
          {typeof selection === "object" ? selection.label : selection}
        </FilterButton>

        <Popover
          {...bindPopover(modal)}
          anchorEl={anchor.current}
          PaperProps={{
            sx: {
              maxWidth: "22.5rem",
              width: "100%",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: -8,
            horizontal: "left",
          }}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
          onClose={handleClose}
        >
          <Box
            sx={{
              backgroundColor: tokens.elevation.medium,
            }}
          >
            {current}
          </Box>
        </Popover>
      </div>
    </Box>
  );
};

function AndOrQuery(props: {
  onQueryChange: (newQuery: FilterQuery | undefined) => void;
  operator: FilterOperators.And | FilterOperators.Or;
  rules: FilterQuery[];
  selectionOptions: FilterControl[];
  disableAddFullWidth?: boolean;
  addText?: string;
  disableDivider?: boolean;
}) {
  const lang = useLang();
  const {
    onQueryChange,
    operator,
    rules,
    selectionOptions,
    addText,
    disableAddFullWidth,
    disableDivider,
  } = props;
  const [animationParent] = useAutoAnimate({
    duration: 180,
  });

  const rulesWithoutDates = rules.map((filter) => {
    const isRejected = includes(
      rejectedFilterQueryBuilderOperators,
      filter.type,
    );

    return isRejected ? null : filter;
  });

  return (
    <>
      {rulesWithoutDates.filter(Boolean).length ? (
        <Stack direction="column" spacing={1}>
          <Stack
            direction="column"
            spacing={1}
            divider={
              disableDivider ? null : (
                <Divider orientation="horizontal" flexItem />
              )
            }
            ref={animationParent}
          >
            {rulesWithoutDates.map(
              (filter: FilterQuery | null, index: number) => {
                if (!filter) return null;

                return (
                  <QueryBuilderGeneral
                    query={filter}
                    onQueryChange={(newQuery) => {
                      onQueryChange({
                        type: operator,
                        rules: newQuery
                          ? [
                              ...rules.slice(0, index),
                              newQuery,
                              ...rules.slice(index + 1),
                            ]
                          : [
                              ...rules.slice(0, index),
                              ...rules.slice(index + 1),
                            ],
                      });
                    }}
                    index={index}
                    key={`${filter.type}_${index}`}
                    onCombinatorChange={(newCombinator) => {
                      onQueryChange({
                        type: newCombinator as any,
                        rules,
                      });
                    }}
                    combinator={operator}
                    onRemoveFilter={() => {
                      const newRules = [
                        ...rules.slice(0, index),
                        ...rules.slice(index + 1),
                      ];
                      if (!newRules.length) {
                        onQueryChange(undefined);
                        return;
                      }
                      onQueryChange({
                        type: operator,
                        rules: newRules,
                      });
                    }}
                    selectableFilterOptions={selectionOptions}
                  />
                );
              },
            )}
          </Stack>
        </Stack>
      ) : null}
      <SelectDropdownWrapper
        selection={addText ?? lang.txTable.filter.addFilter}
        selectionOptions={selectionOptions}
        setSelection={(filter) => {
          onQueryChange({
            type: operator,
            rules: [...rules, { type: filter as any, value: [] }],
          });
        }}
        buttonType={ButtonType.Text}
        disabled={selectionOptions.length === 0}
        startIcon={<AddIcon />}
        disableAddFullWidth={disableAddFullWidth}
      />
    </>
  );
}

const rejectedFilterQueryBuilderOperators = [
  FilterOperators.ActionTrade,
  FilterOperators.Reconciliation,
  FilterOperators.NegativeBalance,
  FilterOperators.MissingPrice,
  FilterOperators.HasComments,
  FilterOperators.Date,
  FilterOperators.Before,
  FilterOperators.After,
  FilterOperators.Reviewed,
  FilterOperators.Suggestion,
  FilterOperators.TransactionId,
] as const;

function includes<T extends U, U>(coll: readonly T[], el: U): el is T {
  return coll.includes(el as T);
}

export function QueryBuilderGeneral({
  query,
  onQueryChange,
  index,
  onCombinatorChange,
  combinator,
  onRemoveFilter,
  selectableFilterOptions,
  disableAddFullWidth = false,
  addText,
  disableDivider = false,
}: {
  query: FilterQuery;
  onQueryChange: (newQuery: FilterQuery | undefined) => void;
  onCombinatorChange?: (newCombinator: string) => void;
  onRemoveFilter?: () => void;
  index?: number;
  forcedWarnings?: [Warning];
  combinator?: FilterOperators.And | FilterOperators.Or;
  selectableFilterOptions: FilterControl[];
  disableAddFullWidth?: boolean;
  addText?: string;
  disableDivider?: boolean;
}) {
  const lang = useLang();
  const isMobile = useIsMobile();

  const unwrapNotFilter = (
    query: FilterQuery,
  ): {
    isNegated: boolean;
    unwrappedQuery: FilterQuery;
  } => {
    if (query.type === FilterOperators.Not) {
      return { isNegated: true, unwrappedQuery: query.rule };
    }
    return { isNegated: false, unwrappedQuery: query };
  };

  const { isNegated, unwrappedQuery } = unwrapNotFilter(query);
  const operator = unwrappedQuery.type;

  const changeQuery = (newQuery: FilterQuery | undefined) => {
    if (isNegated && !!newQuery) {
      onQueryChange({
        type: FilterOperators.Not,
        rule: newQuery,
      });
    } else {
      onQueryChange(newQuery);
    }
  };

  if (
    includes(
      [...rejectedFilterQueryBuilderOperators, FilterOperators.Not],
      operator,
    )
  ) {
    return null;
  }

  // Initialize filter options labels
  const filterControl = Object.values(FilterOperators).filter(
    (operator) => filterOperatorConfig[operator].queryBuilderOptionShowing,
  );

  const combinatorOptions = (
    [FilterOperators.And, FilterOperators.Or] as const
  ).map((operator) => ({
    field: operator,
    label: lang.txTable.filter.operators[operator],
  }));

  if (operator === FilterOperators.And || operator === FilterOperators.Or) {
    const rules = unwrappedQuery.rules;
    return (
      <AndOrQuery
        onQueryChange={(newQuery: FilterQuery | undefined) => {
          onQueryChange(newQuery);
        }}
        operator={operator}
        rules={rules}
        selectionOptions={selectableFilterOptions}
        addText={addText}
        disableAddFullWidth={disableAddFullWidth}
        disableDivider={disableDivider}
      />
    );
  }

  const updateOperator = (newOperator: string) => {
    changeQuery({
      type: newOperator as any,
      value: [],
    });
  };

  const onNegationChange = (newOperator: string) => {
    if (newOperator === NegationOptions.IsNot) {
      onQueryChange({
        type: FilterOperators.Not,
        rule: unwrappedQuery,
      });
    } else if (newOperator === NegationOptions.Is) {
      onQueryChange(unwrappedQuery);
    }
  };

  const selectedFilter = filterControl.find((obj) => obj === operator);
  const selectedFilterLabel = selectedFilter
    ? // if there is a filter builder label override, use that (e.g. Transaction Categories over Categories)
      // otherwise just use the standard one
      lang.txTable.filter.filterBuilderOperatorOverrides[
        selectedFilter as keyof typeof lang.txTable.filter.filterBuilderOperatorOverrides
      ] ?? lang.txTable.filter.operators[selectedFilter]
    : undefined;
  const selectedCombinator = combinatorOptions.find(
    (obj) => obj.field === combinator,
  );

  const negationLang =
    selectedFilter && filterOperatorConfig[selectedFilter].textMatching
      ? lang.txTable.filter.textMatching
      : lang.txTable.filter.negation;

  const negationOptions = Object.values(NegationOptions).map(
    (o: NegationOptions) => ({
      field: o,
      label: negationLang[o],
    }),
  );

  const selectedNegation =
    negationLang[isNegated ? NegationOptions.IsNot : NegationOptions.Is];

  const isQueryBuilderEnabled = isQueryBuilderEnabledOperator(selectedFilter);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing="0.5rem"
      alignItems={isMobile ? undefined : "flex-start"}
    >
      <Stack direction="row" spacing="0.75rem" alignItems="center">
        {index && index > 0 && onCombinatorChange && selectedCombinator ? (
          <SelectDropdownWrapper
            selection={selectedCombinator}
            selectionOptions={combinatorOptions}
            setSelection={(v) => {
              onCombinatorChange(v);
            }}
            disabled={index !== 1}
            buttonType={ButtonType.Tertiary}
            endIcon={<ArrowDropDown />}
          />
        ) : null}

        {selectedFilterLabel ? (
          <SelectDropdownWrapper
            selection={selectedFilterLabel}
            selectionOptions={selectableFilterOptions}
            setSelection={(v) => {
              updateOperator(v);
            }}
            buttonType={ButtonType.Tertiary}
            endIcon={<ArrowDropDown />}
            disabled={!isQueryBuilderEnabled}
          />
        ) : null}

        <SelectDropdownWrapper
          selection={selectedNegation}
          selectionOptions={negationOptions}
          setSelection={(v) => {
            onNegationChange(v);
          }}
          buttonType={ButtonType.Tertiary}
          endIcon={<ArrowDropDown />}
          disabled={!isQueryBuilderEnabled}
        />
      </Stack>
      <Stack
        direction="row"
        maxWidth="100%"
        width="100%"
        spacing="0.75rem"
        alignItems="center"
      >
        <FilterAutocomplete
          filter={unwrappedQuery}
          onChange={(filter) => {
            let isEmpty = !filter;

            if (filter && "value" in filter && Array.isArray(filter.value)) {
              isEmpty = !filter.value.length;
            }

            if (isEmpty && onRemoveFilter) {
              onRemoveFilter();
            } else {
              changeQuery(filter);
            }
          }}
          restrictType={operator}
          disabled={!isQueryBuilderEnabled}
        />

        {onRemoveFilter ? (
          <TextIconButton
            aria-label="delete"
            size="small"
            onClick={onRemoveFilter}
          >
            <ClearIcon sx={{ fontSize: "1rem" }} />
          </TextIconButton>
        ) : null}
      </Stack>
    </Stack>
  );
}

function FilterQueryBuilderPopover({
  forcedWarnings,
}: {
  forcedWarnings?: [Warning];
}) {
  const lang = useLang();
  const chartOfAccountsEnabled = useCanAccessFeature(Features.ERP);
  const rulesEnabled = useCanAccessFeature(Features.Rules);
  const { state, dispatch } = useTransactionFilter();
  const { dispatch: checkboxDispatch } = useTransactionCheckbox();
  // we track changes in the global filter state
  const [previousGlobalFilter, setPreviousGlobalFilter] = useState<
    FilterQuery | undefined
  >();
  // we have an internal filter state, that we sync with the global
  // but we also allow users to have non-valid filters inside this
  // e.g. a currency filter with no inputs
  // once its valid, we will send it to the global filter
  const [filter, setFilter] = useState<FilterQuery | undefined>(state.filter);

  // sync our internal filter state, with the global whenever it changes
  if (previousGlobalFilter !== state.filter) {
    setPreviousGlobalFilter(state.filter);
    setFilter(state.filter);
  }

  const query = filter ?? {
    type: FilterOperators.And,
    rules: [],
  };

  const erpSettings = useErpSettingsQuery();
  const filterOptionsQuery = useGetFilterOptionsQuery();

  const erp = erpSettings.data?.erp;

  // Initialize filter options
  const filterQueryBuilderOperators = Object.values(FilterOperators).filter(
    (operator) => filterOperatorConfig[operator].queryBuilderEnabled,
  );
  const filterControl = useMemo(() => {
    return filterQueryBuilderOperators
      .map((operator) => ({
        field: operator,
        label:
          lang.txTable.filter.filterBuilderOperatorOverrides[
            operator as keyof typeof lang.txTable.filter.filterBuilderOperatorOverrides
          ] ?? lang.txTable.filter.operators[operator],
      }))
      .filter(
        (filterOption) =>
          !(
            ((!erp || !chartOfAccountsEnabled) &&
              [
                FilterOperators.ErpAssetAccount,
                FilterOperators.ErpGainsAccount,
                FilterOperators.ErpLoanAccount,
                FilterOperators.ErpPnlAccount,
                FilterOperators.ErpSyncStatus,
              ].includes(filterOption.field)) ||
            (!rulesEnabled &&
              [FilterOperators.RuleOperator, FilterOperators.Rule].includes(
                filterOption.field,
              ))
          ),
      );
  }, [
    filterQueryBuilderOperators,
    erp,
    lang,
    chartOfAccountsEnabled,
    rulesEnabled,
  ]);

  const getSelectedOperators = (filter: FilterQuery) => {
    if (isAndFilter(filter) || isOrFilter(filter)) {
      return filter.rules.map((r) => r.type);
    }
    return [query.type];
  };

  const selectedOperators = getSelectedOperators(query);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [selectedOperators]);

  if (filterOptionsQuery.isInitialLoading) {
    return null;
  }

  return (
    <FilterContainer>
      <QueryBuilderGeneral
        query={query}
        onQueryChange={(newQuery) => {
          if (!newQuery) {
            dispatch({
              type: FilterActionType.ResetFilter,
            });
            checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
            setFilter(undefined);
            return;
          }
          const inactiveCount = getInactiveFilterCount(newQuery);
          if (inactiveCount > 0) {
            // dont sync to the global, dont do the search
            // there are some empty inputs, so lets wait for the user to
            // remove them, or fill them in
            setFilter(newQuery);
            return;
          }

          // if this is an `or` query, and it only has 1 element
          // we will switch it to be an and
          if (
            newQuery.type === FilterOperators.Or &&
            newQuery.rules.length === 1
          ) {
            dispatch({
              type: FilterActionType.SetFilter,
              filter: {
                type: FilterOperators.And,
                rules: newQuery.rules,
              },
            });
          } else {
            dispatch({
              type: FilterActionType.SetFilter,
              filter: newQuery,
            });
            setFilter(newQuery);
          }
          checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
        }}
        forcedWarnings={forcedWarnings}
        selectableFilterOptions={filterControl}
      />
    </FilterContainer>
  );
}

export function getInactiveFilterCount(
  filter: FilterQuery | undefined,
  count = 0,
): number {
  if (!filter) {
    return count + 1;
  }
  if (
    filter.type === FilterOperators.And ||
    filter.type === FilterOperators.Or
  ) {
    // Recursive filter, look at the children
    return (
      count +
      sum(filter.rules.map((filter) => getInactiveFilterCount(filter, count)))
    );
  }
  if (filter.type === FilterOperators.Not) {
    return count + getInactiveFilterCount(filter.rule, count);
  }
  if (Array.isArray(filter.value) && filter.value.length === 0) {
    // e.g. Currency: []
    return 1;
  }
  // Everything else is fine
  return 0;
}

export function FilterQueryBuilder() {
  const lang = useLang();
  const isLoading = useGetFilterOptionsQuery().isInitialLoading;

  const {
    state: { filter },
  } = useTransactionFilter();

  const filterCount = getFilterCount(filter, FilterCountType.Query);

  return (
    <BasicPopover
      buttonText={lang.txTable.filter.filter}
      content={<FilterQueryBuilderPopover />}
      endIcon={
        filterCount && !isLoading ? (
          <FilterCount>{filterCount}</FilterCount>
        ) : (
          <FilterListIcon />
        )
      }
      disabled={isLoading}
      active={Boolean(filterCount)}
    />
  );
}

export const FilterCount = styled(Box)`
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem !important;
  line-height: 1rem;
  background: ${({ theme }) => theme.tokens.icon.brand};
  border-radius: 100%;
  color: ${({ theme }) => theme.tokens.text.inverse};
`;

const FilterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: ${({ theme }) => theme.tokens.background.neutral.default};
  border-radius: 0.25rem;
  width: 50rem;
  max-width: 90vw;
  padding: 0.75rem;
`;
