import { Alert, DialogContentText as MuDialogContentText } from "@mui/material";
import styled from "styled-components/macro";

import {
  GeneralDialog,
  type GeneralDialogProps,
} from "~/components/ui/GeneralDialog";

const DialogContentText = styled(MuDialogContentText)`
  overflow-wrap: anywhere;
`;

export const ConfirmationDialog = ({
  text,
  warning,
  ...rest
}: { text: string; warning?: string } & Omit<
  GeneralDialogProps,
  "children"
>) => {
  return (
    <GeneralDialog {...rest}>
      <DialogContentText gutterBottom={!!warning}>{text}</DialogContentText>
      {warning && (
        <DialogContentText>
          <DialogContentText>
            <Alert severity="warning">{warning}</Alert>
          </DialogContentText>
        </DialogContentText>
      )}
    </GeneralDialog>
  );
};
