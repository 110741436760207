import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
// @ts-ignore
import InputMask from "react-input-mask";

import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useLang } from "~/redux/lang";

export function IrsCustomReportDialog({
  isOpen,
  setIsOpen,
  successCallback,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  successCallback: ((args: { name: string; social: string }) => void) | null;
}) {
  const lang = useLang();
  const handleClose = () => {
    setIsOpen(false);
  };

  const { control, getValues, reset, handleSubmit } = useForm<{
    name: string;
    social: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      social: "",
    },
  });

  useEffect(() => {
    if (isOpen) reset();
  }, [isOpen, reset]);

  const clickDownload = () => {
    successCallback?.(getValues());
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="Metropolis/Header/H4">
          {lang.report.download.irsDownload.customTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box mb="1rem">
              <Typography variant="Metropolis/Body/Light">
                {lang.report.download.irsDownload.whyUserDataCustom}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  size="small"
                  type="text"
                  label={lang.report.download.irsDownload.name}
                  fullWidth
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="social"
              rules={{
                validate: (value) =>
                  value.length === 0 ||
                  value.length === 11 ||
                  "Please enter a valid SSN or TIN or leave blank",
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <InputMask
                  mask="999-99-9999"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  alwaysShowMask
                  maskChar={null}
                >
                  {() => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      label={lang.report.download.irsDownload.social}
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent dividers>
        <Box ml="1rem">
          <PrimaryButton onClick={handleSubmit(clickDownload)}>
            {lang.report.download.irsDownload.download}
          </PrimaryButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
