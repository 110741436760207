import {
  Assessment,
  Code,
  DataObject as DataObjectIcon,
  Inventory,
  PersonAdd,
  Settings as SettingsIcon,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  type SvgIconProps,
  Typography,
} from "@mui/material";
import type * as React from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { AbsoluteRoutes } from "~/components/AbsoluteRoutes";
import { DeveloperReport } from "~/components/developer/DeveloperReport";
import { EditorIntegration } from "~/components/developer/EditorIntegration";
import { Entities } from "~/components/developer/Entities";
import { FeatureFlags } from "~/components/developer/FeatureFlags";
import { SeedData } from "~/components/developer/seed-data/index";
import { SessionRecordingSettings } from "~/components/developer/SessionRecording";
import { UserData } from "~/components/developer/UserData";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

enum Tabs {
  FeatureFlags = "featureFlags",
  Reconciliation = "reconciliation",
  SeedData = "seedData",
  Entities = "entities",
  EditorIntegration = "editorIntegration",
  ReportRefresh = "reportRefresh",
  SessionRecording = "sessionRecording",
  UserData = "userData",
}

type Tab = {
  name: Tabs;
  icon: React.FC<SvgIconProps>;
  link: string;
};

const tabGeneral: Tab = {
  name: Tabs.FeatureFlags,
  icon: SettingsIcon,
  link: Links.Developer,
};

const tabDatasets: Tab = {
  name: Tabs.SeedData,
  icon: DataObjectIcon,
  link: Links.DeveloperSeedData,
};

const tabEditorIntegration: Tab = {
  name: Tabs.EditorIntegration,
  icon: Code,
  link: Links.DeveloperEditorIntegration,
};

const tabEntities: Tab = {
  name: Tabs.Entities,
  icon: Inventory,
  link: Links.DeveloperEntities,
};

const tabReport: Tab = {
  name: Tabs.ReportRefresh,
  icon: Assessment,
  link: Links.DeveloperReport,
};

const tabSessionRecording: Tab = {
  name: Tabs.SessionRecording,
  icon: Visibility,
  link: Links.DeveloperSessionRecording,
};

const tabUserData: Tab = {
  name: Tabs.UserData,
  icon: PersonAdd,
  link: Links.DeveloperUserData,
};

export function Developer() {
  const lang = useLang();
  const isMobile = useIsMobile();
  const menuItems: Tab[] = [
    tabGeneral,
    tabUserData,
    tabDatasets,
    tabEntities,
    tabEditorIntegration,
    tabReport,
    tabSessionRecording,
  ];

  const title = (tab: Tab) => {
    return lang.developer.tabs[tab.name];
  };

  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = (
    menuItems.find((tab) => tab.link === location.pathname) || menuItems[0]
  ).name;

  return (
    <Box pl={8} pr={2}>
      <Grid container justifyContent="center" spacing={8}>
        <Grid item style={{ paddingLeft: 0 }}>
          <Box display="flex" flexDirection="column">
            <Typography variant="Metropolis/Header/H4">{lang.developer.title}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" mt={1}>
            {menuItems.map((menuItem, key) => {
              const Icon = menuItem.icon;
              return (
                <StyledButton
                  active={activeTab === menuItem.name}
                  isMobile={isMobile}
                  onClick={() => {
                    navigate(menuItem.link);
                  }}
                  key={key}
                >
                  <StyledBox>
                    <Icon />
                    <StyledTypography
                      active={activeTab === menuItem.name}
                      sx={{ marginTop: "0.2rem" }}
                    >
                      {title(menuItem)}
                    </StyledTypography>
                  </StyledBox>
                </StyledButton>
              );
            })}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          lg={6}
          xl={5}
          style={{
            paddingLeft: isMobile ? "1rem" : "4rem",
            paddingTop: isMobile ? "0" : "4rem",
          }}
        >
          <AbsoluteRoutes>
            <Route
              path={`${Links.DeveloperSeedData}/*`}
              element={<SeedData />}
            />
            <Route
              path={`${Links.DeveloperEntities}/*`}
              element={<Entities />}
            />
            <Route
              path={`${Links.DeveloperEditorIntegration}/*`}
              element={<EditorIntegration />}
            />
            <Route
              path={`${Links.DeveloperReport}/*`}
              element={<DeveloperReport />}
            />
            <Route
              path={`${Links.DeveloperSessionRecording}/*`}
              element={<SessionRecordingSettings />}
            />
            <Route path={`${Links.Developer}/*`} element={<FeatureFlags />} />
            <Route
              path={`${Links.DeveloperUserData}/*`}
              element={<UserData />}
            />
          </AbsoluteRoutes>
        </Grid>
      </Grid>
    </Box>
  );
}

const StyledButton = styled(({ active, isMobile, ...props }) => (
  <Button {...props} />
))`
  && {
    width: ${({ isMobile }) => (isMobile ? "80vw" : "15rem")};
    color: ${({ active, theme }) =>
      active ? theme.tokens.text.brand : theme.tokens.text.disabled};
    ${({ isMobile }) => isMobile && "margin: 0.125rem 0;"}
    ${({ isMobile, theme }) =>
      isMobile &&
      `background-color: ${theme.tokens.background.neutral.default};`}
    ${({ active, theme }) =>
      active && `background-color: ${theme.tokens.background.brand.default};`}
  }
`;

const StyledBox = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;

const StyledTypography = styled(({ active, ...props }) => (
  <Typography {...props} />
))`
  && {
    font-size: 0.875rem;
    margin-left: 0.5rem;
    color: ${({ active, theme }) =>
      active ? theme.tokens.text.brand : theme.tokens.text.default};
  }
`;
