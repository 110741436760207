import { Analytics } from "~/segment";
import { type UserInfo } from "~/types/index";

async function fireAnalytics(
  event: string,
  user: UserInfo | undefined | null,
  extraProps?: Record<string, any>,
) {
  const analytics = await Analytics.getInstance();
  analytics.track({
    event,
    user,
    props: {
      ...extraProps,
    },
  });
}

export function fireAnalyticsOnUpload(
  user: UserInfo | undefined | null,
  extraProps?: Record<string, any>,
) {
  return fireAnalytics("exchange_imported", user, extraProps);
}

export function fireAnalyticsOnDelete(
  user: UserInfo | undefined | null,
  extraProps?: Record<string, any>,
) {
  return fireAnalytics("exchange_deleted", user, extraProps);
}
