import { type Blockchain } from "@ctc/types";
import { Box, Grid, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useContext, useEffect } from "react";

import { MuiImportGrid } from "~/components/imports/api/index";
import { ImportFormContext } from "~/components/imports/context/index";
import { ListboxComponent } from "~/components/transactions/AutocompleteListboxComponent";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { useLang } from "~/redux/lang";
import {
  BlockchainName,
  type ConnectWalletImportMethod,
  type ImportOptionV2,
  type WalletImportMethod,
} from "~/types/index";

export function BlockchainSelector({
  importOption,
  importMethod,
}: {
  importOption: ImportOptionV2;
  importMethod: WalletImportMethod | ConnectWalletImportMethod;
}) {
  const supportedBlockchains = importMethod.blockchains;
  const lang = useLang();
  const isOnlyOneOption = supportedBlockchains.length === 1;
  const importFormContext = useContext(ImportFormContext);

  useEffect(() => {
    if (isOnlyOneOption)
      importFormContext?.setSelectedBlockchain(supportedBlockchains[0]);
  }, [isOnlyOneOption, supportedBlockchains, importFormContext]);

  function handleChange(_e: any, newValue: Blockchain | null) {
    importFormContext?.setSelectedBlockchain(newValue || undefined);
  }

  if (!importFormContext || !supportedBlockchains.length || isOnlyOneOption) {
    return null;
  }

  const { selectedBlockchain } = importFormContext;

  return (
    <Grid container direction="column" spacing={1}>
      <MuiImportGrid item xs={12}>
        <Typography variant="Metropolis/Body/Regular">
          {lang.imports.softWalletHeading({ name: importOption.name })}
        </Typography>
      </MuiImportGrid>
      <MuiImportGrid item xs={12}>
        <Box my="0.75rem">
          <Autocomplete
            fullWidth
            value={selectedBlockchain}
            onChange={handleChange}
            options={supportedBlockchains}
            noOptionsText={lang.imports.noOptions}
            getOptionLabel={(option) => BlockchainName[option] || option}
            ListboxComponent={(props) => (
              <ListboxComponent {...props} itemSize={52} />
            )}
            renderOption={(props, option) => {
              return (
                <Box component="li" {...props}>
                  <Box display="flex" alignItems="center">
                    <ExchangeLogo name={option.toLowerCase()} />
                    <Box display="inline-flex" ml="0.375rem">
                      {BlockchainName[option] || option}
                    </Box>
                  </Box>
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={lang.imports.softWalletNetworkSelect}
                InputLabelProps={{
                  ...params.InputLabelProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  ...(selectedBlockchain && {
                    startAdornment: <ExchangeLogo name={selectedBlockchain} />,
                  }),
                }}
                placeholder={lang.imports.softWalletPrompt}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Box>
      </MuiImportGrid>
    </Grid>
  );
}
