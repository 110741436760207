import { AddManualTransaction } from "~/components/transactions/manual/AddManualTransaction";
import {
  Drawer,
  DrawerTitle,
  StyledHeaderContent,
} from "~/components/ui/Drawer";
import { useLang } from "~/redux/lang";
import { type CurrencyIdentifier } from "~/types/index";

export const AddManualTransactionDrawer = ({
  handleClose,
  open,
  setDisabled,
  transactionTime,
  currencyIdentifier,
  noRedirect,
}: {
  handleClose: (isSaving: boolean) => void;
  open: boolean;
  setDisabled: (showBtn: boolean) => void;
  transactionTime?: string;
  currencyIdentifier?: CurrencyIdentifier;
  noRedirect?: boolean;
}) => {
  const lang = useLang();

  return (
    <Drawer
      isOpen={open}
      aria-labelledby="form-dialog-title"
      onClose={() => {
        handleClose(false);
      }}
    >
      <StyledHeaderContent>
        <DrawerTitle title={lang.manual.addNewTransaction} />
      </StyledHeaderContent>
      <AddManualTransaction
        handleClose={handleClose}
        setDisabled={setDisabled}
        transactionTime={transactionTime}
        currencyIdentifier={currencyIdentifier}
        noRedirect={noRedirect}
      />
    </Drawer>
  );
};
