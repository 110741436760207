import { type Trade } from "@ctc/types";
import ArticleIcon from "@mui/icons-material/Article";

import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { DualChip } from "~/components/transactions/command-palette/views/DualChip";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { useLang } from "~/redux/lang";
import { type ErpAccount } from "~/redux/types";

/**
 * The chips at the top of bulk change gains account
 */
export const LoanAccountTopSection = ({
  tradeType,
  loanAccount,
}: {
  tradeType?: Trade;
  loanAccount?: ErpAccount;
}) => {
  const lang = useLang();
  const Icon = tradeType ? TradeIcons[tradeType] : null;
  return (
    <BulkEditChips>
      <BulkEditOptionChip type={BulkEditOptions.ChangeLoanAccount} />
      <DualChip
        leftIcon={tradeType && Icon ? <Icon /> : undefined}
        leftLabel={
          tradeType
            ? getActionTypeName({ actionType: tradeType, lang })
            : lang.txTable.commandPallet.chips.type
        }
        rightLabel={
          loanAccount
            ? loanAccount.name
            : lang.txTable.expansionPanel.chartOfAccounts.plAccount
        }
        rightIcon={
          loanAccount ? (
            <ArticleIcon sx={{ height: "1rem", width: "1rem" }} />
          ) : undefined
        }
      />
    </BulkEditChips>
  );
};
