import get from "lodash/get";

import { type RootState } from "~/redux/types";

export const createLoadingSelector =
  (actions: string[]) =>
  (state: RootState): boolean => {
    // returns true only when all actions is not loading
    return actions.some((action) => get(state, `api.loading.${action}`));
  };
