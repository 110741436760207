import { CaptchaProvider, Env } from "@ctc/types";
import { useRef, useState } from "react";
import type ReCAPTCHA from "react-google-recaptcha";
import { useTurnstile } from "react-turnstile";

import { useFeatureFlag } from "~/analytics/posthog";
import { displayMessage } from "~/components/ui/Toaster";
import { useCaptchaProvider } from "~/hooks/useCaptchaProvider";
import { useLang } from "~/redux/lang";
import { DisplayMessage, FeatureFlag } from "~/types/enums";

/**
 * Hook to handle CAPTCHA generation and state
 * @returns Object containing CAPTCHA state and generation function
 */
export function useCaptcha() {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const turnstile = useTurnstile();
  const [turnstileCaptcha, setTurnstileCaptcha] = useState<string | null>(null);
  const { captchaProvider } = useCaptchaProvider();
  const lang = useLang();

  // Determine if CAPTCHA should be used based on feature flag and environment
  const shouldUseCaptchaOnLogin =
    !useFeatureFlag(FeatureFlag.disableRecaptchaOnLogin) &&
    [Env.Staging, Env.Prod].includes(import.meta.env.VITE_APP_ENV);

  /**
   * Returns the CAPTCHA token generated by the captcha provider
   * @returns Promise resolving to the CAPTCHA token or null if verification failed
   */
  const getCaptchaToken = async ({
    login = false,
  }: {
    login?: boolean;
  }): Promise<string | null> => {
    // If CAPTCHA is disabled on login, return a dummy token
    if (!shouldUseCaptchaOnLogin && login) {
      console.info(`CAPTCHA is disabled, returning dummy token`);
      return "captcha-disabled";
    }

    let captchaToken = turnstileCaptcha;

    if (captchaProvider === CaptchaProvider.Recaptcha) {
      const captchaElement = recaptchaRef.current;
      console.info(`Captcha element before verification:`, captchaElement);

      captchaToken = (await captchaElement?.executeAsync()) || null;
      console.info(`Captcha token length:`, captchaToken?.length);

      // Reset the captcha element to ensure it's ready for the next verification
      captchaElement?.reset();
      console.info(`Captcha element after reset:`, captchaElement);
    } else if (captchaProvider === CaptchaProvider.Turnstile) {
      // Reset the turnstile captcha to ensure it's ready for the next verification
      turnstile.reset();
    }

    if (!captchaToken && shouldUseCaptchaOnLogin && login) {
      console.info(`Captcha token empty, displaying error message`);
      displayMessage({
        message: lang.auth.captcha.verificationFailed,
        type: DisplayMessage.Error,
      });
      return null;
    }

    return captchaToken;
  };

  return {
    recaptchaRef,
    turnstileCaptcha,
    setTurnstileCaptcha,
    getCaptchaToken,
    captchaProvider,
  };
}
