import { Trade } from "@ctc/types";

import { GroupingCriteria } from "~/lib/enums";
import {
  CategoryBucket,
  GroupedActionRatio,
  GroupedTrade,
  GroupRecategorisationType,
  TaxDivision,
  TradeDirection,
} from "~/types/enums";
import { type ActionData, type TradeData } from "~/types/index";

export const TradeInfo: Record<Trade, TradeData> = {
  [Trade.Buy]: {
    direction: TradeDirection.In,
    isManual: true,
    taxDivision: () => TaxDivision.Buy,
    negativeValueTrade: Trade.Sell,
    isPartOfGroup: true,
    bucket: () => CategoryBucket.Acquisition,
  },
  [Trade.ZeroCostBuy]: {
    direction: TradeDirection.In,
    isHidden: true,
    isEditBlocked: true,
    taxDivision: () => TaxDivision.Hidden,
  },
  [Trade.Sell]: {
    direction: TradeDirection.Out,
    isManual: true,
    taxDivision: () => TaxDivision.Disposal,
    negativeValueTrade: Trade.Buy,
    isPartOfGroup: true,
    bucket: () => CategoryBucket.Disposal,
  },
  [Trade.In]: {
    direction: TradeDirection.In,
    isEditBlocked: true,
    taxDivision: () => TaxDivision.Hidden,
    negativeValueTrade: Trade.Out,
  },
  [Trade.Out]: {
    direction: TradeDirection.Out,
    isEditBlocked: true,
    taxDivision: () => TaxDivision.Hidden,
    negativeValueTrade: Trade.In,
  },
  [Trade.Deposit]: {
    direction: TradeDirection.In,
    isManual: true,
    taxDivision: () => TaxDivision.Transfer,
    negativeValueTrade: Trade.Withdrawal,
    isPartOfGroup: true,
    bucket: () => CategoryBucket.Transfer,
  },
  [Trade.Withdrawal]: {
    direction: TradeDirection.Out,
    isManual: true,
    taxDivision: () => TaxDivision.Transfer,
    negativeValueTrade: Trade.Deposit,
    isPartOfGroup: true,
    bucket: () => CategoryBucket.Transfer,
  },
  [Trade.FiatDeposit]: {
    direction: TradeDirection.In,
    isManual: true,
    taxDivision: () => TaxDivision.Transfer,
    negativeValueTrade: Trade.FiatWithdrawal,
    bucket: () => CategoryBucket.Transfer,
  },
  [Trade.FiatWithdrawal]: {
    direction: TradeDirection.Out,
    isManual: true,
    taxDivision: () => TaxDivision.Withdrawal,
    negativeValueTrade: Trade.FiatDeposit,
    bucket: () => CategoryBucket.Disposal,
  },
  [Trade.Lost]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: ({ lostStolenAsNonTaxable }) =>
      lostStolenAsNonTaxable ? TaxDivision.Transfer : TaxDivision.Disposal,
    bucket: ({ lostStolenAsNonTaxable }) =>
      lostStolenAsNonTaxable ? CategoryBucket.Transfer : CategoryBucket.Loss,
  },
  [Trade.Stolen]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: ({ lostStolenAsNonTaxable }) =>
      lostStolenAsNonTaxable ? TaxDivision.Transfer : TaxDivision.Disposal,
    searchTags: ["rug"],
    bucket: ({ lostStolenAsNonTaxable }) =>
      lostStolenAsNonTaxable ? CategoryBucket.Transfer : CategoryBucket.Loss,
  },
  [Trade.Burn]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Disposal,
    bucket: () => CategoryBucket.Loss,
  },
  [Trade.PersonalUse]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: ({ personalUseAsNonTaxable }) =>
      personalUseAsNonTaxable ? TaxDivision.Transfer : TaxDivision.Disposal,
    bucket: ({ personalUseAsNonTaxable }) =>
      personalUseAsNonTaxable
        ? CategoryBucket.Transfer
        : CategoryBucket.Disposal,
  },
  [Trade.ChainSplit]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Buy,
    bucket: () => CategoryBucket.Income,
  },
  [Trade.Fee]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Payment,
    bucket: () => CategoryBucket.Disposal,
  },
  [Trade.Approval]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Payment,
    searchTags: ["approve", "fee"],
    bucket: () => CategoryBucket.Disposal,
  },
  [Trade.IgnoreIn]: {
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Ignore,
    negativeValueTrade: Trade.IgnoreOut,
    noFeeTx: true,
    bucket: () => CategoryBucket.Ignore,
  },
  [Trade.IgnoreOut]: {
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Ignore,
    negativeValueTrade: Trade.IgnoreIn,
    noFeeTx: true,
    bucket: () => CategoryBucket.Ignore,
  },
  [Trade.Spam]: {
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Ignore,
    noFeeTx: true,
    searchTags: ["scam"],
    bucket: () => CategoryBucket.Ignore,
  },
  [Trade.Unknown]: {
    isEditBlocked: true,
    direction: TradeDirection.Unknown,
    taxDivision: () => TaxDivision.Hidden,
  },
  [Trade.FailedIn]: {
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Ignore,
    negativeValueTrade: Trade.FailedOut,
    bucket: () => CategoryBucket.Ignore,
  },
  [Trade.FailedOut]: {
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Ignore,
    negativeValueTrade: Trade.FailedIn,
    bucket: () => CategoryBucket.Ignore,
  },
  [Trade.Interest]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Income,
    bucket: () => CategoryBucket.Income,
  },
  [Trade.Airdrop]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: ({ airdropsAsIncome }) =>
      airdropsAsIncome ? TaxDivision.Income : TaxDivision.Buy,
    bucket: ({ airdropsAsIncome }) =>
      airdropsAsIncome ? CategoryBucket.Income : CategoryBucket.Acquisition,
  },
  [Trade.StakingReward]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: ({ stakingAsIncome }) =>
      stakingAsIncome ? TaxDivision.Income : TaxDivision.Buy,
    bucket: ({ stakingAsIncome }) =>
      stakingAsIncome ? CategoryBucket.Income : CategoryBucket.Acquisition,
  },
  [Trade.Income]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Income,
    bucket: () => CategoryBucket.Income,
  },
  [Trade.Mint]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Buy,
    bucket: () => CategoryBucket.Acquisition,
  },
  [Trade.Royalty]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: ({ royaltiesAsIncome }) =>
      royaltiesAsIncome ? TaxDivision.Income : TaxDivision.Buy,
    bucket: ({ royaltiesAsIncome }) =>
      royaltiesAsIncome ? CategoryBucket.Income : CategoryBucket.Acquisition,
  },
  [Trade.Gift]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Transfer,
    bucket: () => CategoryBucket.Acquisition,
  },
  [Trade.OutgoingGift]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: ({ outgoingGiftAsNonTaxable }) =>
      outgoingGiftAsNonTaxable ? TaxDivision.Transfer : TaxDivision.Disposal,
    bucket: () => CategoryBucket.Disposal,
  },
  [Trade.Rebate]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Transfer,
    bucket: () => CategoryBucket.Acquisition,
  },
  [Trade.Mining]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: ({ miningAsIncome }) =>
      miningAsIncome ? TaxDivision.Income : TaxDivision.Buy,
    bucket: () => CategoryBucket.Income,
  },
  [Trade.Long]: {
    isHidden: true,
    isEditBlocked: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Hidden,
  },
  [Trade.Short]: {
    isHidden: true,
    isEditBlocked: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Hidden,
  },
  [Trade.RealisedProfit]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Income,
    bucket: () => CategoryBucket.Derivatives,
  },
  [Trade.RealisedLoss]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Payment,
    bucket: () => CategoryBucket.Derivatives,
  },
  [Trade.MarginFee]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Payment,
    bucket: () => CategoryBucket.Derivatives,
  },
  [Trade.Expense]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Disposal,
    bucket: () => CategoryBucket.Expenses,
  },
  [Trade.Borrow]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Transfer,
    searchTags: ["loan", "borrow"],
    negativeValueTrade: Trade.LoanRepayment,
    bucket: () => CategoryBucket.Acquisition,
  },
  [Trade.LoanRepayment]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Payment,
    negativeValueTrade: Trade.Borrow,
    bucket: () => CategoryBucket.Disposal,
  },
  [Trade.SwapIn]: {
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Transfer,
    negativeValueTrade: Trade.SwapIn,
    isPartOfGroup: true,
    bucket: () => CategoryBucket.Transfer,
  },
  [Trade.SwapOut]: {
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Transfer,
    negativeValueTrade: Trade.SwapOut,
    isPartOfGroup: true,
    bucket: () => CategoryBucket.Transfer,
  },
  [Trade.BridgeIn]: {
    direction: TradeDirection.In,
    taxDivision: ({ bridgeAsNonTaxable }) =>
      bridgeAsNonTaxable ? TaxDivision.Transfer : TaxDivision.Buy,
    negativeValueTrade: Trade.BridgeOut,
    isPartOfGroup: true,
    isManual: true,
    bucket: ({ bridgeAsNonTaxable }) =>
      bridgeAsNonTaxable ? CategoryBucket.Transfer : CategoryBucket.Acquisition,
  },
  [Trade.BridgeOut]: {
    direction: TradeDirection.Out,
    taxDivision: ({ bridgeAsNonTaxable }) =>
      bridgeAsNonTaxable ? TaxDivision.Transfer : TaxDivision.Disposal,
    negativeValueTrade: Trade.BridgeIn,
    isPartOfGroup: true,
    isManual: true,
    bucket: ({ bridgeAsNonTaxable }) =>
      bridgeAsNonTaxable ? CategoryBucket.Transfer : CategoryBucket.Disposal,
  },
  [Trade.BridgeTradeIn]: {
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Buy,
    negativeValueTrade: Trade.BridgeTradeOut,
    isPartOfGroup: true,
    isManual: true,
    bucket: () => CategoryBucket.Acquisition,
  },
  [Trade.BridgeTradeOut]: {
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Disposal,
    negativeValueTrade: Trade.BridgeTradeIn,
    isPartOfGroup: true,
    isManual: true,
    bucket: () => CategoryBucket.Disposal,
  },
  [Trade.Liquidate]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Payment,
    bucket: () => CategoryBucket.Loss,
  },
  [Trade.StakingDeposit]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: ({ stakingDepositWithdrawalNonTaxable }) =>
      stakingDepositWithdrawalNonTaxable
        ? TaxDivision.Transfer
        : TaxDivision.Disposal,
    negativeValueTrade: Trade.StakingWithdrawal,
    searchTags: ["staking", "stake"],
    bucket: ({ stakingDepositWithdrawalNonTaxable }) =>
      stakingDepositWithdrawalNonTaxable
        ? CategoryBucket.Transfer
        : CategoryBucket.Disposal,
  },
  [Trade.StakingWithdrawal]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: ({ stakingDepositWithdrawalNonTaxable }) =>
      stakingDepositWithdrawalNonTaxable
        ? TaxDivision.Transfer
        : TaxDivision.Buy,
    negativeValueTrade: Trade.StakingDeposit,
    searchTags: ["unstaking", "unstake"],
    bucket: () => CategoryBucket.Transfer,
  },
  [Trade.AddLiquidity]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: ({ liquidityAddRemovalNonTaxable }) =>
      liquidityAddRemovalNonTaxable
        ? TaxDivision.Transfer
        : TaxDivision.Disposal,
    negativeValueTrade: Trade.RemoveLiquidity,
    searchTags: ["lp", "liquidity"],
    isPartOfGroup: true,
    bucket: ({ liquidityAddRemovalNonTaxable }) =>
      liquidityAddRemovalNonTaxable
        ? CategoryBucket.Transfer
        : CategoryBucket.Disposal,
  },
  [Trade.RemoveLiquidity]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: ({ liquidityAddRemovalNonTaxable }) =>
      liquidityAddRemovalNonTaxable ? TaxDivision.Transfer : TaxDivision.Buy,
    negativeValueTrade: Trade.AddLiquidity,
    searchTags: ["lp", "liquidity"],
    isPartOfGroup: true,
    bucket: ({ liquidityAddRemovalNonTaxable }) =>
      liquidityAddRemovalNonTaxable
        ? CategoryBucket.Transfer
        : CategoryBucket.Acquisition,
  },
  [Trade.ReceiveReceiptToken]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: ({ liquidityAddRemovalNonTaxable }) =>
      liquidityAddRemovalNonTaxable ? TaxDivision.Transfer : TaxDivision.Buy,
    searchTags: ["lp", "liquidity"],
    isPartOfGroup: true,
    bucket: ({ liquidityAddRemovalNonTaxable }) =>
      liquidityAddRemovalNonTaxable
        ? CategoryBucket.Transfer
        : CategoryBucket.Acquisition,
  },
  [Trade.SendReceiptToken]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: ({ liquidityAddRemovalNonTaxable }) =>
      liquidityAddRemovalNonTaxable
        ? TaxDivision.Transfer
        : TaxDivision.Disposal,
    searchTags: ["lp", "liquidity"],
    isPartOfGroup: true,
    bucket: ({ liquidityAddRemovalNonTaxable }) =>
      liquidityAddRemovalNonTaxable
        ? CategoryBucket.Transfer
        : CategoryBucket.Disposal,
  },
  [Trade.CollateralDeposit]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: ({ collateralAsNonTaxable }) =>
      collateralAsNonTaxable ? TaxDivision.Transfer : TaxDivision.Disposal,
    negativeValueTrade: Trade.CollateralWithdrawal,
    bucket: ({ collateralAsNonTaxable }) =>
      collateralAsNonTaxable
        ? CategoryBucket.Transfer
        : CategoryBucket.Disposal,
  },
  [Trade.CollateralWithdrawal]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: ({ collateralAsNonTaxable }) =>
      collateralAsNonTaxable ? TaxDivision.Transfer : TaxDivision.Buy,
    negativeValueTrade: Trade.CollateralDeposit,
    bucket: ({ collateralAsNonTaxable }) =>
      collateralAsNonTaxable
        ? CategoryBucket.Transfer
        : CategoryBucket.Acquisition,
  },
  [Trade.IncreasePositionWithdrawal]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Disposal,
    negativeValueTrade: Trade.ReceivePQ,
    bucket: () => CategoryBucket.Derivatives,
    searchTags: ["position"],
  },
  [Trade.DecreasePositionDeposit]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Buy,
    negativeValueTrade: Trade.SendPQ,
    bucket: () => CategoryBucket.Derivatives,
    searchTags: ["position"],
  },
  [Trade.ReceivePQ]: {
    isManual: true,
    direction: TradeDirection.In,
    taxDivision: () => TaxDivision.Buy,
    negativeValueTrade: Trade.IncreasePositionWithdrawal,
    bucket: () => CategoryBucket.Derivatives,
    searchTags: ["position"],
  },
  [Trade.SendPQ]: {
    isManual: true,
    direction: TradeDirection.Out,
    taxDivision: () => TaxDivision.Disposal,
    negativeValueTrade: Trade.DecreasePositionDeposit,
    bucket: () => CategoryBucket.Derivatives,
    searchTags: ["position"],
  },
};

export const ActionDefinitions: Record<GroupedTrade, ActionData> = {
  [GroupedTrade.MultiTrade]: {
    subActionTypes: [Trade.Buy, Trade.Sell],
    isTransferLike: false,
    outTrade: Trade.Sell,
    inTrade: Trade.Buy,
    isFilterable: false,
    groupRatio: GroupedActionRatio.NToM,
    groupRecategorisationType: GroupRecategorisationType.TradeTransferLike,
    groupCriteria: [
      GroupingCriteria.SameValueFeeInclusive,
      GroupingCriteria.DifferentCurrency,
    ],
    excludeFromRecategorisation: true,
  },
  [GroupedTrade.Trade]: {
    subActionTypes: [Trade.Buy, Trade.Sell],
    isTransferLike: false,
    outTrade: Trade.Sell,
    inTrade: Trade.Buy,
    isFilterable: true,
    groupRatio: GroupedActionRatio.NToM,
    groupRecategorisationType: GroupRecategorisationType.TradeTransferLike,
    groupCriteria: [
      GroupingCriteria.SameValueFeeInclusive,
      GroupingCriteria.DifferentCurrency,
    ],
  },
  [GroupedTrade.StakingDepositGroup]: {
    subActionTypes: [Trade.StakingDeposit, Trade.ReceiveReceiptToken],
    isTransferLike: false,
    outTrade: Trade.StakingDeposit,
    inTrade: Trade.ReceiveReceiptToken,
    isFilterable: false,
    groupRatio: GroupedActionRatio.OneToOne,
    groupRecategorisationType: GroupRecategorisationType.LiquidityLike,
    groupCriteria: [
      GroupingCriteria.SameValueFeeExclusive,
      GroupingCriteria.DifferentCurrency,
    ],
  },
  [GroupedTrade.StakingWithdrawalGroup]: {
    subActionTypes: [Trade.StakingWithdrawal, Trade.SendReceiptToken],
    isTransferLike: false,
    outTrade: Trade.SendReceiptToken,
    inTrade: Trade.StakingWithdrawal,

    isFilterable: false,
    groupRatio: GroupedActionRatio.OneToOne,
    groupRecategorisationType: GroupRecategorisationType.LiquidityLike,
    groupCriteria: [
      GroupingCriteria.SameValueFeeExclusive,
      GroupingCriteria.DifferentCurrency,
    ],
  },
  [GroupedTrade.Transfer]: {
    subActionTypes: [Trade.Withdrawal, Trade.Deposit],
    isTransferLike: true,
    outTrade: Trade.Withdrawal,
    inTrade: Trade.Deposit,
    isFilterable: true,
    groupRatio: GroupedActionRatio.OneToOne,
    groupRecategorisationType: GroupRecategorisationType.TradeTransferLike,
    groupCriteria: [
      GroupingCriteria.SameCurrency,
      GroupingCriteria.SimilarQuantity,
    ],
  },
  [GroupedTrade.FiatTransfer]: {
    subActionTypes: [Trade.FiatWithdrawal, Trade.FiatDeposit],
    isTransferLike: true,
    outTrade: Trade.FiatWithdrawal,
    inTrade: Trade.FiatDeposit,
    isFilterable: true,
    groupRatio: GroupedActionRatio.OneToOne,
    groupRecategorisationType: GroupRecategorisationType.TradeTransferLike,
    groupCriteria: [
      GroupingCriteria.SameCurrency,
      GroupingCriteria.SimilarQuantity,
    ],
  },
  [GroupedTrade.Swap]: {
    subActionTypes: [Trade.SwapIn, Trade.SwapOut],
    isTransferLike: false,
    outTrade: Trade.SwapOut,
    inTrade: Trade.SwapIn,
    isFilterable: true,
    groupRatio: GroupedActionRatio.OneToOne,
    groupRecategorisationType: GroupRecategorisationType.TradeTransferLike,
    groupCriteria: [GroupingCriteria.SameValueFeeInclusive],
  },
  [GroupedTrade.Bridge]: {
    subActionTypes: [Trade.BridgeIn, Trade.BridgeOut],
    isTransferLike: true,
    outTrade: Trade.BridgeOut,
    inTrade: Trade.BridgeIn,
    isFilterable: true,
    isMultichain: true,
    groupRatio: GroupedActionRatio.OneToOne,
    groupRecategorisationType: GroupRecategorisationType.TradeTransferLike,
    groupCriteria: [
      GroupingCriteria.SameValueFeeInclusive,
      GroupingCriteria.SimilarQuantity,
    ],
  },
  [GroupedTrade.BridgeTrade]: {
    subActionTypes: [Trade.BridgeTradeIn, Trade.BridgeTradeOut],
    isTransferLike: false,
    outTrade: Trade.BridgeTradeOut,
    inTrade: Trade.BridgeTradeIn,
    isFilterable: true,
    isMultichain: true,
    groupRatio: GroupedActionRatio.OneToOne,
    groupRecategorisationType: GroupRecategorisationType.TradeTransferLike,
    groupCriteria: [
      GroupingCriteria.DifferentCurrency,
      GroupingCriteria.SameValueFeeInclusive,
    ],
  },
  [GroupedTrade.RemoveLiquidityTrade]: {
    subActionTypes: [Trade.SendReceiptToken, Trade.RemoveLiquidity],
    isTransferLike: false,
    groupRatio: GroupedActionRatio.OneToN,
    groupRecategorisationType: GroupRecategorisationType.LiquidityLike,
    outTrade: Trade.SendReceiptToken,
    inTrade: Trade.RemoveLiquidity,
    isFilterable: false,
    groupCriteria: [GroupingCriteria.SameValueFeeExclusive],
  },
  [GroupedTrade.ProvideLiquidityTrade]: {
    subActionTypes: [Trade.AddLiquidity, Trade.ReceiveReceiptToken],
    isTransferLike: false,
    groupRatio: GroupedActionRatio.OneToN,
    groupRecategorisationType: GroupRecategorisationType.LiquidityLike,
    outTrade: Trade.AddLiquidity,
    inTrade: Trade.ReceiveReceiptToken,
    isFilterable: false,
    groupCriteria: [GroupingCriteria.SameValueFeeExclusive],
  },
  [GroupedTrade.MultiMint]: {
    subActionTypes: [Trade.Mint],
    isTransferLike: false,
    groupRatio: GroupedActionRatio.NToM,
    groupRecategorisationType:
      GroupRecategorisationType.MultiCurrencyTransaction,
    inTrade: Trade.Mint,
    isFilterable: false,
    groupCriteria: [],
    excludeFromRecategorisation: true,
  },
  [GroupedTrade.IncreasePosition]: {
    subActionTypes: [Trade.ReceivePQ, Trade.IncreasePositionWithdrawal],
    inTrade: Trade.ReceivePQ,
    outTrade: Trade.IncreasePositionWithdrawal,
    groupRatio: GroupedActionRatio.OneToOne,
    groupRecategorisationType: GroupRecategorisationType.TradeTransferLike,
    isTransferLike: false,
    isFilterable: false,
    groupCriteria: [
      GroupingCriteria.SameValueFeeInclusive,
      GroupingCriteria.DifferentCurrency,
    ],
  },
  [GroupedTrade.DecreasePosition]: {
    subActionTypes: [Trade.SendPQ, Trade.DecreasePositionDeposit],
    inTrade: Trade.DecreasePositionDeposit,
    outTrade: Trade.SendPQ,
    groupRatio: GroupedActionRatio.OneToOne,
    groupRecategorisationType: GroupRecategorisationType.TradeTransferLike,
    isTransferLike: false,
    isFilterable: false,
    groupCriteria: [
      GroupingCriteria.SameValueFeeInclusive,
      GroupingCriteria.DifferentCurrency,
    ],
  },
  [GroupedTrade.Uncategorised]: {
    subActionTypes: [Trade.In, Trade.Out],
    outTrade: Trade.Out,
    inTrade: Trade.In,
    isTransferLike: false,
    groupRatio: GroupedActionRatio.NToM,
    groupRecategorisationType:
      GroupRecategorisationType.MultiCurrencyTransaction,
    isFilterable: false,
    isMultichain: false,
    groupCriteria: [],
    excludeFromRecategorisation: true,
  },
};
