import { Country } from "@ctc/types";

import { useLang } from "~/redux/lang";
import { Region } from "~/types/enums";
import { type CountryType } from "~/types/index";

// Note: Order here is the order they will be displayed in the country selector during onboarding

export const useCountries = (): {
  getAll: () => CountryType[];
  getByCode: (code: Country) => CountryType | undefined;
} => {
  const lang = useLang();
  const countryRecord: Record<Country, CountryType> = {
    [Country.USA]: {
      code: Country.USA,
      label: lang.countries.US,
      region: Region.NorthAmerica,
    },
    [Country.Australia]: {
      code: Country.Australia,
      label: lang.countries.AU,
      region: Region.AsiaPacific,
    },
    [Country.Canada]: {
      code: Country.Canada,
      label: lang.countries.CA,
      region: Region.NorthAmerica,
    },
    [Country.Germany]: {
      code: Country.Germany,
      label: lang.countries.DE,
      region: Region.Europe,
    },
    [Country.NewZealand]: {
      code: Country.NewZealand,
      label: lang.countries.NZ,
      region: Region.AsiaPacific,
    },
    [Country.Spain]: {
      code: Country.Spain,
      label: lang.countries.ES,
      region: Region.Europe,
    },
    [Country.SouthAfrica]: {
      code: Country.SouthAfrica,
      label: lang.countries.ZA,
      region: Region.Africa,
    },
    [Country.UK]: {
      code: Country.UK,
      label: lang.countries.UK,
      isoString: "GB",
      region: Region.Europe,
    },
    [Country.Austria]: {
      code: Country.Austria,
      label: lang.countries.AT,
      region: Region.Europe,
    },
    [Country.Belgium]: {
      code: Country.Belgium,
      label: lang.countries.BE,
      region: Region.Europe,
    },
    [Country.Finland]: {
      code: Country.Finland,
      label: lang.countries.FI,
      region: Region.Europe,
    },
    [Country.France]: {
      code: Country.France,
      label: lang.countries.FR,
      region: Region.Europe,
    },
    [Country.Greece]: {
      code: Country.Greece,
      label: lang.countries.GR,
      region: Region.Europe,
    },
    [Country.Ireland]: {
      code: Country.Ireland,
      label: lang.countries.IE,
      region: Region.Europe,
    },
    [Country.Italy]: {
      code: Country.Italy,
      label: lang.countries.IT,
      region: Region.Europe,
    },
    [Country.Japan]: {
      code: Country.Japan,
      label: lang.countries.JP,
      region: Region.AsiaPacific,
    },
    [Country.Netherlands]: {
      code: Country.Netherlands,
      label: lang.countries.NL,
      region: Region.Europe,
    },
    [Country.Norway]: {
      code: Country.Norway,
      label: lang.countries.NO,
      region: Region.Europe,
    },
    [Country.Portugal]: {
      code: Country.Portugal,
      label: lang.countries.PT,
      region: Region.Europe,
    },
    [Country.Singapore]: {
      code: Country.Singapore,
      label: lang.countries.SG,
      region: Region.AsiaPacific,
    },
    [Country.Sweden]: {
      code: Country.Sweden,
      label: lang.countries.SE,
      region: Region.Europe,
    },
    [Country.Switzerland]: {
      code: Country.Switzerland,
      label: lang.countries.CH,
      region: Region.Europe,
    },
    [Country.India]: {
      code: Country.India,
      label: lang.countries.IN,
      region: Region.AsiaPacific,
    },
    [Country.Albania]: {
      code: Country.Albania,
      label: lang.countries.AL,
      region: Region.Europe,
    },
    [Country.Algeria]: {
      code: Country.Algeria,
      label: lang.countries.DZ,
      region: Region.Africa,
    },
    [Country.AmericanSamoa]: {
      code: Country.AmericanSamoa,
      label: lang.countries.AS,
      region: Region.AsiaPacific,
    },
    [Country.Andorra]: {
      code: Country.Andorra,
      label: lang.countries.AD,
      region: Region.Europe,
    },
    [Country.Angola]: {
      code: Country.Angola,
      label: lang.countries.AO,
      region: Region.Africa,
    },
    [Country.Anguilla]: {
      code: Country.Anguilla,
      label: lang.countries.AI,
      region: Region.NorthAmerica,
    },
    [Country.AntiguaAndBarbuda]: {
      code: Country.AntiguaAndBarbuda,
      label: lang.countries.AG,
      region: Region.NorthAmerica,
    },
    [Country.Argentina]: {
      code: Country.Argentina,
      label: lang.countries.AR,
      region: Region.NorthAmerica,
    },
    [Country.Armenia]: {
      code: Country.Armenia,
      label: lang.countries.AM,
      region: Region.AsiaPacific,
    },
    [Country.Aruba]: {
      code: Country.Aruba,
      label: lang.countries.AW,
      region: Region.NorthAmerica,
    },
    [Country.Azerbaijan]: {
      code: Country.Azerbaijan,
      label: lang.countries.AZ,
      region: Region.AsiaPacific,
    },
    [Country.Bahamas]: {
      code: Country.Bahamas,
      label: lang.countries.BS,
      region: Region.NorthAmerica,
    },
    [Country.Bahrain]: {
      code: Country.Bahrain,
      label: lang.countries.BH,
      region: Region.AsiaPacific,
    },
    [Country.Bangladesh]: {
      code: Country.Bangladesh,
      label: lang.countries.BD,
      region: Region.AsiaPacific,
    },
    [Country.Barbados]: {
      code: Country.Barbados,
      label: lang.countries.BB,
      region: Region.NorthAmerica,
    },
    [Country.Belize]: {
      code: Country.Belize,
      label: lang.countries.BZ,
      region: Region.NorthAmerica,
    },
    [Country.Benin]: {
      code: Country.Benin,
      label: lang.countries.BJ,
      region: Region.Africa,
    },
    [Country.Bermuda]: {
      code: Country.Bermuda,
      label: lang.countries.BM,
      region: Region.NorthAmerica,
    },
    [Country.Bhutan]: {
      code: Country.Bhutan,
      label: lang.countries.BT,
      region: Region.AsiaPacific,
    },
    [Country.Bolivia]: {
      code: Country.Bolivia,
      label: lang.countries.BO,
      region: Region.NorthAmerica,
    },
    [Country.Bonaire]: {
      code: Country.Bonaire,
      label: lang.countries.BQ,
      region: Region.NorthAmerica,
    },
    [Country.BosniaAndHerzegovina]: {
      code: Country.BosniaAndHerzegovina,
      label: lang.countries.BA,
      region: Region.Europe,
    },
    [Country.Botswana]: {
      code: Country.Botswana,
      label: lang.countries.BW,
      region: Region.Africa,
    },
    [Country.Brazil]: {
      code: Country.Brazil,
      label: lang.countries.BR,
      region: Region.NorthAmerica,
    },
    [Country.BruneiDarussalam]: {
      code: Country.BruneiDarussalam,
      label: lang.countries.BN,
      region: Region.AsiaPacific,
    },
    [Country.Bulgaria]: {
      code: Country.Bulgaria,
      label: lang.countries.BG,
      region: Region.Europe,
    },
    [Country.BurkinaFaso]: {
      code: Country.BurkinaFaso,
      label: lang.countries.BF,
      region: Region.Africa,
    },
    [Country.Burundi]: {
      code: Country.Burundi,
      label: lang.countries.BI,
      region: Region.Africa,
    },
    [Country.CaboVerde]: {
      code: Country.CaboVerde,
      label: lang.countries.CV,
      region: Region.Africa,
    },
    [Country.Cameroon]: {
      code: Country.Cameroon,
      label: lang.countries.CM,
      region: Region.Africa,
    },
    [Country.CaymanIslands]: {
      code: Country.CaymanIslands,
      label: lang.countries.KY,
      region: Region.NorthAmerica,
    },
    [Country.Chile]: {
      code: Country.Chile,
      label: lang.countries.CL,
      region: Region.NorthAmerica,
    },
    [Country.Colombia]: {
      code: Country.Colombia,
      label: lang.countries.CO,
      region: Region.NorthAmerica,
    },
    [Country.CookIslands]: {
      code: Country.CookIslands,
      label: lang.countries.CK,
      region: Region.AsiaPacific,
    },
    [Country.CostaRica]: {
      code: Country.CostaRica,
      label: lang.countries.CR,
      region: Region.NorthAmerica,
    },
    [Country.CoteDIvoire]: {
      code: Country.CoteDIvoire,
      label: lang.countries.CI,
      region: Region.Africa,
    },
    [Country.Croatia]: {
      code: Country.Croatia,
      label: lang.countries.HR,
      region: Region.Europe,
    },
    [Country.Curacao]: {
      code: Country.Curacao,
      label: lang.countries.CW,
      region: Region.NorthAmerica,
    },
    [Country.Cyprus]: {
      code: Country.Cyprus,
      label: lang.countries.CY,
      region: Region.Europe,
    },
    [Country.CzechRepublic]: {
      code: Country.CzechRepublic,
      label: lang.countries.CZ,
      region: Region.Europe,
    },
    [Country.Denmark]: {
      code: Country.Denmark,
      label: lang.countries.DK,
      region: Region.Europe,
    },
    [Country.Djibouti]: {
      code: Country.Djibouti,
      label: lang.countries.DJ,
      region: Region.Africa,
    },
    [Country.Dominica]: {
      code: Country.Dominica,
      label: lang.countries.DM,
      region: Region.NorthAmerica,
    },
    [Country.DominicanRepublic]: {
      code: Country.DominicanRepublic,
      label: lang.countries.DO,
      region: Region.NorthAmerica,
    },
    [Country.Ecuador]: {
      code: Country.Ecuador,
      label: lang.countries.EC,
      region: Region.NorthAmerica,
    },
    [Country.Egypt]: {
      code: Country.Egypt,
      label: lang.countries.EG,
      region: Region.Africa,
    },
    [Country.ElSalvador]: {
      code: Country.ElSalvador,
      label: lang.countries.SV,
      region: Region.NorthAmerica,
    },
    [Country.Estonia]: {
      code: Country.Estonia,
      label: lang.countries.EE,
      region: Region.Europe,
    },
    [Country.FalklandIslands]: {
      code: Country.FalklandIslands,
      label: lang.countries.FK,
      region: Region.NorthAmerica,
    },
    [Country.FaroeIslands]: {
      code: Country.FaroeIslands,
      label: lang.countries.FO,
      region: Region.Europe,
    },
    [Country.Fiji]: {
      code: Country.Fiji,
      label: lang.countries.FJ,
      region: Region.AsiaPacific,
    },
    [Country.FrenchGuiana]: {
      code: Country.FrenchGuiana,
      label: lang.countries.GF,
      region: Region.NorthAmerica,
    },
    [Country.Gabon]: {
      code: Country.Gabon,
      label: lang.countries.GA,
      region: Region.Africa,
    },
    [Country.Georgia]: {
      code: Country.Georgia,
      label: lang.countries.GE,
      region: Region.AsiaPacific,
    },
    [Country.Ghana]: {
      code: Country.Ghana,
      label: lang.countries.GH,
      region: Region.Africa,
    },
    [Country.Gibraltar]: {
      code: Country.Gibraltar,
      label: lang.countries.GI,
      region: Region.Europe,
    },
    [Country.Greenland]: {
      code: Country.Greenland,
      label: lang.countries.GL,
      region: Region.NorthAmerica,
    },
    [Country.Grenada]: {
      code: Country.Grenada,
      label: lang.countries.GD,
      region: Region.NorthAmerica,
    },
    [Country.Guadeloupe]: {
      code: Country.Guadeloupe,
      label: lang.countries.GP,
      region: Region.NorthAmerica,
    },
    [Country.Guam]: {
      code: Country.Guam,
      label: lang.countries.GU,
      region: Region.AsiaPacific,
    },
    [Country.Guatemala]: {
      code: Country.Guatemala,
      label: lang.countries.GT,
      region: Region.NorthAmerica,
    },
    [Country.Guernsey]: {
      code: Country.Guernsey,
      label: lang.countries.GG,
      region: Region.Europe,
    },
    [Country.Guyana]: {
      code: Country.Guyana,
      label: lang.countries.GY,
      region: Region.NorthAmerica,
    },
    [Country.Honduras]: {
      code: Country.Honduras,
      label: lang.countries.HN,
      region: Region.NorthAmerica,
    },
    [Country.HongKong]: {
      code: Country.HongKong,
      label: lang.countries.HK,
      region: Region.AsiaPacific,
    },
    [Country.Hungary]: {
      code: Country.Hungary,
      label: lang.countries.HU,
      region: Region.Europe,
    },
    [Country.Iceland]: {
      code: Country.Iceland,
      label: lang.countries.IS,
      region: Region.Europe,
    },
    [Country.Indonesia]: {
      code: Country.Indonesia,
      label: lang.countries.ID,
      region: Region.AsiaPacific,
    },
    [Country.IsleOfMan]: {
      code: Country.IsleOfMan,
      label: lang.countries.IM,
      region: Region.Europe,
    },
    [Country.Israel]: {
      code: Country.Israel,
      label: lang.countries.IL,
      region: Region.AsiaPacific,
    },
    [Country.Jamaica]: {
      code: Country.Jamaica,
      label: lang.countries.JM,
      region: Region.NorthAmerica,
    },
    [Country.Jersey]: {
      code: Country.Jersey,
      label: lang.countries.JE,
      region: Region.Europe,
    },
    [Country.Jordan]: {
      code: Country.Jordan,
      label: lang.countries.JO,
      region: Region.AsiaPacific,
    },
    [Country.Kazakhstan]: {
      code: Country.Kazakhstan,
      label: lang.countries.KZ,
      region: Region.AsiaPacific,
    },
    [Country.Kenya]: {
      code: Country.Kenya,
      label: lang.countries.KE,
      region: Region.Africa,
    },
    [Country.Kiribati]: {
      code: Country.Kiribati,
      label: lang.countries.KI,
      region: Region.AsiaPacific,
    },
    [Country.Kuwait]: {
      code: Country.Kuwait,
      label: lang.countries.KW,
      region: Region.AsiaPacific,
    },
    [Country.Kyrgyzstan]: {
      code: Country.Kyrgyzstan,
      label: lang.countries.KG,
      region: Region.AsiaPacific,
    },
    [Country.LaoPeoplesDemocraticRepublic]: {
      code: Country.LaoPeoplesDemocraticRepublic,
      label: lang.countries.LA,
      region: Region.AsiaPacific,
    },
    [Country.Latvia]: {
      code: Country.Latvia,
      label: lang.countries.LV,
      region: Region.Europe,
    },
    [Country.Lesotho]: {
      code: Country.Lesotho,
      label: lang.countries.LS,
      region: Region.Africa,
    },
    [Country.Liechtenstein]: {
      code: Country.Liechtenstein,
      label: lang.countries.LI,
      region: Region.Europe,
    },
    [Country.Lithuania]: {
      code: Country.Lithuania,
      label: lang.countries.LT,
      region: Region.Europe,
    },
    [Country.Luxembourg]: {
      code: Country.Luxembourg,
      label: lang.countries.LU,
      region: Region.Europe,
    },
    [Country.Macao]: {
      code: Country.Macao,
      label: lang.countries.MO,
      region: Region.AsiaPacific,
    },
    [Country.Madagascar]: {
      code: Country.Madagascar,
      label: lang.countries.MG,
      region: Region.Africa,
    },
    [Country.Malawi]: {
      code: Country.Malawi,
      label: lang.countries.MW,
      region: Region.Africa,
    },
    [Country.Malaysia]: {
      code: Country.Malaysia,
      label: lang.countries.MY,
      region: Region.AsiaPacific,
    },
    [Country.Maldives]: {
      code: Country.Maldives,
      label: lang.countries.MV,
      region: Region.AsiaPacific,
    },
    [Country.Malta]: {
      code: Country.Malta,
      label: lang.countries.MT,
      region: Region.Europe,
    },
    [Country.MarshallIslands]: {
      code: Country.MarshallIslands,
      label: lang.countries.MH,
      region: Region.AsiaPacific,
    },
    [Country.Martinique]: {
      code: Country.Martinique,
      label: lang.countries.MQ,
      region: Region.NorthAmerica,
    },
    [Country.Mauritius]: {
      code: Country.Mauritius,
      label: lang.countries.MU,
      region: Region.Africa,
    },
    [Country.Mayotte]: {
      code: Country.Mayotte,
      label: lang.countries.YT,
      region: Region.Africa,
    },
    [Country.Mexico]: {
      code: Country.Mexico,
      label: lang.countries.MX,
      region: Region.NorthAmerica,
    },
    [Country.Micronesia]: {
      code: Country.Micronesia,
      label: lang.countries.FM,
      region: Region.AsiaPacific,
    },
    [Country.Moldova]: {
      code: Country.Moldova,
      label: lang.countries.MD,
      region: Region.Europe,
    },
    [Country.Monaco]: {
      code: Country.Monaco,
      label: lang.countries.MC,
      region: Region.Europe,
    },
    [Country.Mongolia]: {
      code: Country.Mongolia,
      label: lang.countries.MN,
      region: Region.AsiaPacific,
    },
    [Country.Montenegro]: {
      code: Country.Montenegro,
      label: lang.countries.ME,
      region: Region.Europe,
    },
    [Country.Montserrat]: {
      code: Country.Montserrat,
      label: lang.countries.MS,
      region: Region.NorthAmerica,
    },
    [Country.Morocco]: {
      code: Country.Morocco,
      label: lang.countries.MA,
      region: Region.Africa,
    },
    [Country.Mozambique]: {
      code: Country.Mozambique,
      label: lang.countries.MZ,
      region: Region.Africa,
    },
    [Country.Namibia]: {
      code: Country.Namibia,
      label: lang.countries.NA,
      region: Region.Africa,
    },
    [Country.Nauru]: {
      code: Country.Nauru,
      label: lang.countries.NR,
      region: Region.AsiaPacific,
    },
    [Country.Nepal]: {
      code: Country.Nepal,
      label: lang.countries.NP,
      region: Region.AsiaPacific,
    },
    [Country.NewCaledonia]: {
      code: Country.NewCaledonia,
      label: lang.countries.NC,
      region: Region.AsiaPacific,
    },
    [Country.Nicaragua]: {
      code: Country.Nicaragua,
      label: lang.countries.NI,
      region: Region.NorthAmerica,
    },
    [Country.Nigeria]: {
      code: Country.Nigeria,
      label: lang.countries.NG,
      region: Region.Africa,
    },
    [Country.Niue]: {
      code: Country.Niue,
      label: lang.countries.NU,
      region: Region.AsiaPacific,
    },
    [Country.NorthMacedonia]: {
      code: Country.NorthMacedonia,
      label: lang.countries.MK,
      region: Region.Europe,
    },
    [Country.NorthernMarianaIslands]: {
      code: Country.NorthernMarianaIslands,
      label: lang.countries.MP,
      region: Region.AsiaPacific,
    },
    [Country.Oman]: {
      code: Country.Oman,
      label: lang.countries.OM,
      region: Region.AsiaPacific,
    },
    [Country.Pakistan]: {
      code: Country.Pakistan,
      label: lang.countries.PK,
      region: Region.AsiaPacific,
    },
    [Country.Palau]: {
      code: Country.Palau,
      label: lang.countries.PW,
      region: Region.AsiaPacific,
    },
    [Country.Panama]: {
      code: Country.Panama,
      label: lang.countries.PA,
      region: Region.NorthAmerica,
    },
    [Country.PapuaNewGuinea]: {
      code: Country.PapuaNewGuinea,
      label: lang.countries.PG,
      region: Region.AsiaPacific,
    },
    [Country.Paraguay]: {
      code: Country.Paraguay,
      label: lang.countries.PY,
      region: Region.NorthAmerica,
    },
    [Country.Peru]: {
      code: Country.Peru,
      label: lang.countries.PE,
      region: Region.NorthAmerica,
    },
    [Country.Philippines]: {
      code: Country.Philippines,
      label: lang.countries.PH,
      region: Region.AsiaPacific,
    },
    [Country.Poland]: {
      code: Country.Poland,
      label: lang.countries.PL,
      region: Region.Europe,
    },
    [Country.PuertoRico]: {
      code: Country.PuertoRico,
      label: lang.countries.PR,
      region: Region.NorthAmerica,
    },
    [Country.Qatar]: {
      code: Country.Qatar,
      label: lang.countries.QA,
      region: Region.AsiaPacific,
    },
    [Country.Reunion]: {
      code: Country.Reunion,
      label: lang.countries.RE,
      region: Region.Africa,
    },
    [Country.Romania]: {
      code: Country.Romania,
      label: lang.countries.RO,
      region: Region.Europe,
    },
    [Country.RussianFederation]: {
      code: Country.RussianFederation,
      label: lang.countries.RU,
      region: Region.Europe,
    },
    [Country.Rwanda]: {
      code: Country.Rwanda,
      label: lang.countries.RW,
      region: Region.Africa,
    },
    [Country.SaintBarthelemy]: {
      code: Country.SaintBarthelemy,
      label: lang.countries.BL,
      region: Region.NorthAmerica,
    },
    [Country.SaintHelena]: {
      code: Country.SaintHelena,
      label: lang.countries.SH,
      region: Region.Africa,
    },
    [Country.SaintKittsAndNevis]: {
      code: Country.SaintKittsAndNevis,
      label: lang.countries.KN,
      region: Region.NorthAmerica,
    },
    [Country.SaintLucia]: {
      code: Country.SaintLucia,
      label: lang.countries.LC,
      region: Region.NorthAmerica,
    },
    [Country.SaintMartin]: {
      code: Country.SaintMartin,
      label: lang.countries.MF,
      region: Region.NorthAmerica,
    },
    [Country.SaintPierreAndMiquelon]: {
      code: Country.SaintPierreAndMiquelon,
      label: lang.countries.PM,
      region: Region.NorthAmerica,
    },
    [Country.SaintVincentAndTheGrenadines]: {
      code: Country.SaintVincentAndTheGrenadines,
      label: lang.countries.VC,
      region: Region.NorthAmerica,
    },
    [Country.Samoa]: {
      code: Country.Samoa,
      label: lang.countries.WS,
      region: Region.AsiaPacific,
    },
    [Country.SanMarino]: {
      code: Country.SanMarino,
      label: lang.countries.SM,
      region: Region.Europe,
    },
    [Country.SaoTomeAndPrincipe]: {
      code: Country.SaoTomeAndPrincipe,
      label: lang.countries.ST,
      region: Region.Africa,
    },
    [Country.SaudiArabia]: {
      code: Country.SaudiArabia,
      label: lang.countries.SA,
      region: Region.AsiaPacific,
    },
    [Country.Senegal]: {
      code: Country.Senegal,
      label: lang.countries.SN,
      region: Region.Africa,
    },
    [Country.Serbia]: {
      code: Country.Serbia,
      label: lang.countries.RS,
      region: Region.Europe,
    },
    [Country.Seychelles]: {
      code: Country.Seychelles,
      label: lang.countries.SC,
      region: Region.Africa,
    },
    [Country.SintMaarten]: {
      code: Country.SintMaarten,
      label: lang.countries.SX,
      region: Region.NorthAmerica,
    },
    [Country.Slovakia]: {
      code: Country.Slovakia,
      label: lang.countries.SK,
      region: Region.Europe,
    },
    [Country.Slovenia]: {
      code: Country.Slovenia,
      label: lang.countries.SI,
      region: Region.Europe,
    },
    [Country.SolomonIslands]: {
      code: Country.SolomonIslands,
      label: lang.countries.SB,
      region: Region.AsiaPacific,
    },
    [Country.SouthKorea]: {
      code: Country.SouthKorea,
      label: lang.countries.KR,
      region: Region.AsiaPacific,
    },
    [Country.SriLanka]: {
      code: Country.SriLanka,
      label: lang.countries.LK,
      region: Region.AsiaPacific,
    },
    [Country.Suriname]: {
      code: Country.Suriname,
      label: lang.countries.SR,
      region: Region.NorthAmerica,
    },
    [Country.Taiwan]: {
      code: Country.Taiwan,
      label: lang.countries.TW,
      region: Region.AsiaPacific,
    },
    [Country.Tajikistan]: {
      code: Country.Tajikistan,
      label: lang.countries.TJ,
      region: Region.AsiaPacific,
    },
    [Country.Tanzania]: {
      code: Country.Tanzania,
      label: lang.countries.TZ,
      region: Region.Africa,
    },
    [Country.Thailand]: {
      code: Country.Thailand,
      label: lang.countries.TH,
      region: Region.AsiaPacific,
    },
    [Country.TimorLeste]: {
      code: Country.TimorLeste,
      label: lang.countries.TL,
      region: Region.AsiaPacific,
    },
    [Country.Togo]: {
      code: Country.Togo,
      label: lang.countries.TG,
      region: Region.Africa,
    },
    [Country.Tonga]: {
      code: Country.Tonga,
      label: lang.countries.TO,
      region: Region.AsiaPacific,
    },
    [Country.TrinidadAndTobago]: {
      code: Country.TrinidadAndTobago,
      label: lang.countries.TT,
      region: Region.NorthAmerica,
    },
    [Country.Tunisia]: {
      code: Country.Tunisia,
      label: lang.countries.TN,
      region: Region.Africa,
    },
    [Country.Turkey]: {
      code: Country.Turkey,
      label: lang.countries.TR,
      region: Region.Europe,
    },
    [Country.TurksAndCaicosIslands]: {
      code: Country.TurksAndCaicosIslands,
      label: lang.countries.TC,
      region: Region.NorthAmerica,
    },
    [Country.Tuvalu]: {
      code: Country.Tuvalu,
      label: lang.countries.TV,
      region: Region.AsiaPacific,
    },
    [Country.Uganda]: {
      code: Country.Uganda,
      label: lang.countries.UG,
      region: Region.Africa,
    },
    [Country.Ukraine]: {
      code: Country.Ukraine,
      label: lang.countries.UA,
      region: Region.Europe,
    },
    [Country.UnitedArabEmirates]: {
      code: Country.UnitedArabEmirates,
      label: lang.countries.AE,
      region: Region.AsiaPacific,
    },
    [Country.Uruguay]: {
      code: Country.Uruguay,
      label: lang.countries.UY,
      region: Region.NorthAmerica,
    },
    [Country.Uzbekistan]: {
      code: Country.Uzbekistan,
      label: lang.countries.UZ,
      region: Region.AsiaPacific,
    },
    [Country.Vanuatu]: {
      code: Country.Vanuatu,
      label: lang.countries.VU,
      region: Region.AsiaPacific,
    },
    [Country.VaticanCity]: {
      code: Country.VaticanCity,
      label: lang.countries.VA,
      region: Region.Europe,
    },
    [Country.Vietnam]: {
      code: Country.Vietnam,
      label: lang.countries.VN,
      region: Region.AsiaPacific,
    },
    [Country.VirginIslandsBritish]: {
      code: Country.VirginIslandsBritish,
      label: lang.countries.VG,
      region: Region.NorthAmerica,
    },
    [Country.VirginIslandsUS]: {
      code: Country.VirginIslandsUS,
      label: lang.countries.VI,
      region: Region.NorthAmerica,
    },
    [Country.WallisAndFutuna]: {
      code: Country.WallisAndFutuna,
      label: lang.countries.WF,
      region: Region.AsiaPacific,
    },
    [Country.Zambia]: {
      code: Country.Zambia,
      label: lang.countries.ZM,
      region: Region.Africa,
    },
    [Country.Zimbabwe]: {
      code: Country.Zimbabwe,
      label: lang.countries.ZW,
      region: Region.Africa,
    },
  };

  const priorityCountries = [
    countryRecord[Country.USA],
    countryRecord[Country.Australia],
    countryRecord[Country.Canada],
    countryRecord[Country.Germany],
    countryRecord[Country.NewZealand],
    countryRecord[Country.Spain],
    countryRecord[Country.SouthAfrica],
    countryRecord[Country.UK],
    countryRecord[Country.Austria],
    countryRecord[Country.Belgium],
    countryRecord[Country.Finland],
    countryRecord[Country.France],
    countryRecord[Country.Greece],
    countryRecord[Country.Ireland],
    countryRecord[Country.Italy],
    countryRecord[Country.Japan],
    countryRecord[Country.Netherlands],
    countryRecord[Country.Norway],
    countryRecord[Country.Portugal],
    countryRecord[Country.Singapore],
    countryRecord[Country.Sweden],
    countryRecord[Country.Switzerland],
    countryRecord[Country.India],
  ];

  const priorityCountrySet = new Set(priorityCountries);
  // order of the countries is important here
  const countries: CountryType[] = [
    ...priorityCountries,
    ...Object.values(countryRecord).filter(
      (country) => !priorityCountrySet.has(country),
    ),
  ];

  return {
    getAll: () => countries,
    getByCode: (code: Country) => countryRecord[code],
  };
};
