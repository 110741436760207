import { useUser } from "~/redux/auth";
import { usePaymentMethodQuery } from "~/state/billing";
import { useClientList } from "~/state/clients";
import { RenewalStateError } from "~/types/index";

export function useIsFirstClientUpgrade() {
  const user = useUser();
  const { data: clientList = [] } = useClientList();
  const { data: paymentMethod } = usePaymentMethodQuery();

  // Enterprise users never get shown their first client upgrade.
  if (user?.isWithinEnterprise) {
    return false;
  }

  // If the user has a stripe record AND a payment method, then we shouldn't
  // need to re-enter those details and shouldn't proceed to the first client
  // flow.
  if (user?.isStripeEnabled && paymentMethod) {
    return false;
  }

  // don't capture payment if any clients are in renewal error state.
  if (
    clientList.some(
      (client) => client.renewalErrorState === RenewalStateError.Unpaid,
    )
  ) {
    return false;
  }

  // if they already have v2 subscriptions, then they must have already
  // upgraded a client in the past.
  if (user?.hasV2Subscriptions) {
    return false;
  }

  return true;
}
