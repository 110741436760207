import { Trade } from "@ctc/types";
import { type Blockchain } from "@ctc/types";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import * as React from "react";
import styled from "styled-components/macro";

import { ERPProvider } from "~/components/settings-modal/views/enums";
import { TransactionAccountSelector } from "~/components/transactions/action-row/TransactionAccountSelector";
import { TransactionDetailsInfoPanel } from "~/components/transactions/action-row/TransactionDetailsInfoPanel";
import { AmountText } from "~/components/transactions/action-row/TxAmount";
import { Cell } from "~/components/transactions/styles";
import { TableRow } from "~/components/transactions/TransactionRow";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { DisplayExchange } from "~/components/ui/DisplayExchange";
import { UserOverwritableErpAccounts } from "~/constants/constants";
import { useActionRow } from "~/contexts/ActionRowContext";
import { useDesign } from "~/hooks/useTheme";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { displayFiatValue, multiply } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import {
  isInTrade,
  isOutTrade,
  isUncategorisedTrade,
} from "~/services/transactions";
import { useErpSettingsQuery } from "~/state/erp";
import { Align, Size } from "~/types/enums";
import { type TransactionDetails } from "~/types/index";

const textStyle = (tokens: ReturnType<typeof useDesign>["tokens"]) => ({
  fontFamily: tokens.fontFamilies.numeric,
  fontWeight: 500,
  color: tokens.text.default,
  fontSize: "0.75rem",
});

export function ChartOfAccountsTable() {
  const actionRow = useActionRow();

  const txs = [...actionRow.incoming, ...actionRow.outgoing, ...actionRow.fees];

  if (!txs.length) {
    // WTF is this? How is this possible?
    return null;
  }
  return (
    <TableContainer>
      <TransactionDetailsInfoPanel row={actionRow} />
      <StyledTable className="chart-of-accounts">
        <BreakdownTableHead />
        {txs.map((tx) => (
          <ChartOfAccountsRow tx={tx} key={tx._id} />
        ))}
      </StyledTable>
    </TableContainer>
  );
}

function BreakdownTableHead() {
  const lang = useLang();
  const chartOfAccountsLang = useLang().txTable.expansionPanel.chartOfAccounts;
  const erpSettings = useErpSettingsQuery();

  return (
    <TableHead>
      <TableRow disablePointer>
        <HeadingCell align={Align.Left}>
          {chartOfAccountsLang.transaction}
        </HeadingCell>
        <HeadingCell align={Align.Left}>{chartOfAccountsLang.from}</HeadingCell>
        <HeadingCell align={Align.Left}>{chartOfAccountsLang.to}</HeadingCell>
        <HeadingCell align={Align.Left}>
          {erpSettings.data?.erpType
            ? chartOfAccountsLang.erpAccounts({
                erp: lang[erpSettings.data?.erpType].integrationName,
              })
            : lang[ERPProvider.Xero].integrationName}
        </HeadingCell>
      </TableRow>
    </TableHead>
  );
}

function HeadingCell({
  children,
  colSpan,
  align = Align.Left,
  style = {},
}: {
  children?: React.ReactNode;
  colSpan?: number;
  align?: Align;
  style?: React.CSSProperties;
}) {
  const { tokens } = useDesign();
  return (
    <Cell
      colSpan={colSpan}
      align={align}
      sx={{
        padding: "0.375rem",
        fontSize: "0.75rem",
      }}
      style={{
        background: tokens.elevation.default,
        borderBottom: `1px solid ${tokens.border.neutral.default}`,
        ...style,
        color: tokens.text.low,
      }}
    >
      {children}
    </Cell>
  );
}

const ErpAccounts = ({ tx }: { tx: TransactionDetails }) => {
  const lang = useLang();
  // Disables the account selector if any update is in progress
  const [isAccountUpdateInProgress, setIsAccountUpdateInProgress] =
    React.useState(false);

  const requiredAccountTypes = React.useMemo(() => {
    return UserOverwritableErpAccounts.filter(
      (type) => tx?.erp.accountMappings[type],
    );
  }, [tx]);

  const requiredAccountCells = requiredAccountTypes.map((type) => (
    <Box key={`${tx._id}-${type}`} width="100%" overflow="hidden">
      <TransactionAccountSelector
        disabled={isAccountUpdateInProgress}
        tx={tx}
        accountType={type}
        onEdit={setIsAccountUpdateInProgress}
      />
    </Box>
  ));

  if (isUncategorisedTrade(tx.trade)) {
    return (
      <Cell>{lang.txTable.expansionPanel.chartOfAccounts.uncategorised}</Cell>
    );
  }

  if (requiredAccountTypes.length === 0) {
    return (
      <Cell>
        {lang.txTable.expansionPanel.chartOfAccounts.accountMappingNotRequired}
      </Cell>
    );
  }

  return (
    <Cell style={{ maxWidth: "55rem", overflow: "hidden" }}>
      <Stack direction="row" gap="0.25rem" sx={{ width: "100%" }}>
        {requiredAccountCells}
      </Stack>
    </Cell>
  );
};

const ChartOfAccountsRow = ({ tx }: { tx: TransactionDetails }) => {
  const locale = useLanguagePreference();
  const localCurrency = useLocalCurrency();
  const { tokens } = useDesign();
  const value = multiply(tx.quantity, tx.price);

  return (
    <TableBody>
      <StyledTableRow
        disablePointer
        disableBackground
        style={{ height: "1.75rem" }}
      >
        <Cell
          align={Align.Left}
          style={{ maxWidth: "8.5rem", overflow: "hidden" }}
        >
          <Box display="flex" flexDirection="column" gap="0.25rem">
            <TradeCell trade={tx.trade} />
            <Box display="flex" gap="0.5rem">
              <CurrencyLogo
                currency={tx.currencyIdentifier}
                margin="0"
                width={18}
                height={18}
              />
              <AmountText
                enableDirection={false}
                amount={tx.quantity}
                currency={tx.currencyIdentifier}
                price={tx.price}
                size={Size.Small}
              />
            </Box>
            <Typography
              sx={{
                ...textStyle(tokens),
                color: tokens.text.low,
              }}
            >
              {displayFiatValue({ value, localCurrency, locale })}
            </Typography>
          </Box>
        </Cell>
        <Cell
          align={Align.Left}
          style={{ maxWidth: "8.75rem", overflow: "hidden" }}
        >
          <DisplayExchange
            exchange={tx.from}
            displayName={tx.fromDisplayName}
            currencySymbol={tx.currencyIdentifier.symbol}
            blockchain={tx.blockchain as Blockchain | undefined}
            trade={tx.trade}
            size={Size.Small}
          />
        </Cell>
        <Cell
          align={Align.Left}
          style={{ maxWidth: "8.75rem", overflow: "hidden" }}
        >
          <DisplayExchange
            exchange={tx.to}
            displayName={tx.toDisplayName}
            currencySymbol={tx.currencyIdentifier.symbol}
            blockchain={tx.blockchain as Blockchain | undefined}
            trade={tx.trade}
            size={Size.Small}
          />
        </Cell>
        <ErpAccounts tx={tx} />
      </StyledTableRow>
    </TableBody>
  );
};

function TradeCell({ trade }: { trade: Trade }) {
  const lang = useLang();
  const { tokens } = useDesign();

  return (
    <Box mr="0.25rem" display="flex" alignItems="center">
      <TradeSign trade={trade} />
      <Typography
        sx={{
          ...textStyle(tokens),
        }}
      >
        {getActionTypeName({ actionType: trade, lang })}
      </Typography>
    </Box>
  );
}

const TradeSign = ({ trade }: { trade: Trade }) => {
  const { tokens } = useDesign();
  const styleProps = {
    display: "inline-block",
    marginRight: "0.5rem",
    marginLeft: "0.125rem",
  };
  if (
    [Trade.IgnoreIn, Trade.IgnoreOut, Trade.Unknown].includes(trade)
  ) {
    return null;
  }
  if (isOutTrade(trade)) {
    return (
      <div style={{ color: tokens.icon.danger, ...styleProps }}>&ndash;</div>
    );
  }
  if (isInTrade(trade)) {
    return <div style={{ color: tokens.icon.success, ...styleProps }}>+</div>;
  }
  return null;
};

const StyledTable = styled(
  ({ ...props }: unknown & React.ComponentProps<typeof Table>) => (
    <Table {...props} />
  ),
)`
  border-collapse: inherit;
  &&& {
    th,
    td {
      font-weight: 500;
      white-space: nowrap;
    }

    tbody > tr > td {
      border: 0;

      border-bottom: 1px solid
        ${({ theme }) => theme.tokens.border.neutral.default} !important;
    }

    tbody > tr:last-child > td {
      padding: 0.375rem;
      font-size: 0.75rem;
    }

    tbody:last-child > tr > td {
      padding: 0.7rem 0.375rem;
      font-size: 0.75rem;
      border-bottom: none !important;
    }
  }
`;

const StyledTableRow = styled(
  ({ ...props }: unknown & React.ComponentProps<typeof TableRow>) => (
    <TableRow {...props} />
  ),
)`
  && {
    font-size: 0.75rem;
    padding: 0.375rem 0.375rem;

    border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.default} !important;
  }

  :hover {
    background-color: ${({ theme }) =>
      theme.tokens.background.neutral.lowest.hover};
  }
`;
