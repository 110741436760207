import { type FieldError } from "react-hook-form";

import { type Translation } from "~/lang/index";

export function getErrorHelperText(
  error: FieldError | undefined,
  lang: Translation,
) {
  if (!error) {
    return null;
  }
  if (error.message !== "") {
    return error.message;
  }
  if (error.type === "required") {
    return lang.contacts.required;
  }
  return lang.contacts.error;
}
