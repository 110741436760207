import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { getOptionsFromCurrencies } from "~/components/transactions/command-palette/helpers/getOptionsFromCurrencies";
import { useSelectedCurrencies } from "~/components/transactions/command-palette/helpers/useSelectedCurrencies";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { CurrencyTopSection } from "~/components/transactions/command-palette/views/change-currency/CurrencyTopSection";
import { ToCurrency } from "~/components/transactions/command-palette/views/change-currency/ToCurrency";
import { type BulkEditCurrencyOptions } from "~/components/transactions/command-palette/views/change-currency/types";
import { useLang } from "~/redux/lang";

export const FromCurrency = ({
  bulkEditOption,
}: {
  bulkEditOption: BulkEditCurrencyOptions;
}) => {
  const { push } = useNavController();
  const lang = useLang();

  // The user can only change non-fee currencies
  // We assume that we are not going to get the fee currencies wrong
  const fromCurrencies = useSelectedCurrencies({
    includeFees: false,
    onlyFees: bulkEditOption === BulkEditOptions.ChangeFeeCurrency,
  });
  // Only one currency, so we dont even need to ask
  if (fromCurrencies.length === 1) {
    return (
      <ToCurrency
        fromCurrency={fromCurrencies[0]}
        bulkEditOption={bulkEditOption}
      />
    );
  }

  // get the from currencies
  const options = getOptionsFromCurrencies(fromCurrencies);
  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={bulkEditOption} />
          <CurrencyTopSection />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.currency}
      options={options}
      onSelection={({ currency }) => {
        push(
          <ToCurrency
            fromCurrency={currency}
            bulkEditOption={bulkEditOption}
          />,
        );
      }}
    />
  );
};
