import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components/macro";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureActionAnalytics } from "~/analytics/posthog";
import { useSuccessButton } from "~/components/reconciliation/hooks";
import { ModalProvider } from "~/components/transactions/command-palette/ModalProvider";
import { NavControllerProvider } from "~/components/transactions/command-palette/NavController";
import {
  EditActionAccounts,
  EditActionAccountsAdvanced,
  type EditActionAccountValues,
} from "~/components/transactions/edit/EditActionAccounts";
import * as editAccounts from "~/components/transactions/edit/EditActionAccounts";
import {
  EditActionAmounts,
  type EditActionAmountsValues,
} from "~/components/transactions/edit/EditActionAmounts";
import * as editAmounts from "~/components/transactions/edit/EditActionAmounts";
import {
  EditActionDate,
  type EditActionDrawerDateValues,
} from "~/components/transactions/edit/EditActionDate";
import * as editDate from "~/components/transactions/edit/EditActionDate";
import {
  EditActionFeeAdvanced,
  type EditActionFeeValues,
} from "~/components/transactions/edit/EditActionFee";
import * as editFee from "~/components/transactions/edit/EditActionFee";
import {
  type EditActionDrawerTradeValues,
  EditActionTrade,
} from "~/components/transactions/edit/EditActionTrade";
import * as editTrade from "~/components/transactions/edit/EditActionTrade";
import { LockedTxEditFallback } from "~/components/transactions/edit/helper";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import {
  Drawer,
  DrawerContent,
  DrawerTitle,
  StyledHeaderContent,
} from "~/components/ui/Drawer";
import { useLang } from "~/redux/lang";
import { type PutTransactionPayload } from "~/services/actions";
import { useAtomicUpdateTransactions } from "~/state/actions";
import { GroupedTrade } from "~/types/enums";
import { type ActionRow } from "~/types/index";

export type EditActionValues = EditActionAccountValues &
  EditActionDrawerTradeValues &
  EditActionDrawerDateValues &
  EditActionAmountsValues &
  EditActionFeeValues;

export const EditActionDrawer = ({
  row,
  isOpen,
  onClose,
}: {
  row: ActionRow;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const lang = useLang();
  const [isConfirming, setIsConfirming] = useState(false);
  const atomicUpdateTransactions = useAtomicUpdateTransactions();
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const { getButtonProps } = useSuccessButton(atomicUpdateTransactions);
  const captureActionAnalytics = useCaptureActionAnalytics();
  const analyticsKey = transactionAnalyticsKey("edit");

  const form = useForm<EditActionValues>({
    mode: "onBlur",
    defaultValues: {
      ...editTrade.getValues({ row }),
      ...editAccounts.getValues({ row }),
      ...editDate.getValues({ row }),
      ...editAmounts.getValues({ row }),
      ...editFee.getValues({ row }),
    },
  });

  // isDirty is true if you change a value then change it back
  // so we need to check if there are any dirty fields.
  const { isDirty, dirtyFields, isValid } = form.formState;
  const isDisabled =
    !isDirty || Object.keys(dirtyFields).length === 0 || !isValid;

  const doClose = () => {
    form.reset();
    onClose();
  };

  // We need to purge stale data and reset the form when it is opened.
  useEffect(() => {
    if (isOpen) {
      form.reset({
        ...editTrade.getValues({ row }),
        ...editAccounts.getValues({ row }),
        ...editDate.getValues({ row }),
        ...editAmounts.getValues({ row }),
        ...editFee.getValues({ row }),
      });
    }
  }, [row, isOpen, form]);

  const handleSubmit = async (formData: EditActionValues) => {
    if (isDisabled) return;

    const updateMap = new Map<string, PutTransactionPayload>();
    const deletedTxIds: string[] = [];

    // @todo Fix any.
    editAccounts.applyUpdates(row, form as any, formData as any, updateMap);
    editTrade.applyUpdates(row, form as any, formData as any, updateMap);
    editDate.applyUpdates(row, form as any, formData as any, updateMap);
    editAmounts.applyUpdates(row, form as any, formData as any, updateMap);
    editFee.applyUpdates(
      row,
      form as any,
      formData as any,
      updateMap,
      deletedTxIds,
    );

    const transactionsArray = Array.from(updateMap.values());
    const transactionUpdates = transactionsArray.filter((update) => update._id);
    const feeTxCreates = transactionsArray.filter((update) => !update._id);

    const dirtyCurrencyIds = editAmounts.getUpdatedMarkAsNFTCurrencyIds({
      formState: form.formState,
      formData,
    });
    const markAsNFTUpdates = dirtyCurrencyIds.reduce(
      (updatesObj: Record<string, boolean>, currencyId) => {
        const isMarkedAsNFT = formData.updateMarkAsNFT[currencyId];
        if (isMarkedAsNFT === undefined) {
          return updatesObj; // This shouldn't happen.
        }
        updatesObj[currencyId] = isMarkedAsNFT;
        return updatesObj;
      },
      {},
    );

    atomicUpdateTransactions.mutate(
      {
        actionId: row._id,
        update: {
          updateTx: {
            payload: transactionUpdates,
            applySts: formData.applySts ?? false,
            createCategoryRule: formData.createCategoryRule ?? false,
          },
          createFeeTx: { payload: feeTxCreates },
          deleteTx: { payload: Array.from(deletedTxIds.values()) },
        },
        markAsNFT: markAsNFTUpdates,
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const handleClose = () => {
    if (isDirty) {
      setIsConfirming(true);
    } else {
      doClose();
    }
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={isConfirming}
        title={lang.transactionEdit.dialog.title}
        text={lang.transactionEdit.dialog.body}
        actionText={lang.transactionEdit.dialog.actionButton}
        cancelText={lang.transactionEdit.dialog.cancel}
        handleClose={() => {
          // Exit cancelled, continue editing.
          setIsConfirming(false);
        }}
        handleAction={async () => {
          // Exit confirmed, exit editing.
          setIsConfirming(false);
          doClose();
        }}
        critical
        stopPropagation
      />
      <Drawer isOpen={isOpen} onClose={handleClose}>
        {row.isLocked ? (
          <LockedTxEditFallback />
        ) : (
          <Box>
            <StyledHeaderContent>
              <DrawerTitle title={lang.transactionEdit.transaction} />
            </StyledHeaderContent>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Box py="6rem" px="1.5rem">
                  <Stack flexDirection="column" gap="1rem">
                    <NavControllerProvider>
                      <ModalProvider
                        variant="popover"
                        popupId="category-selector"
                      >
                        <EditActionTrade row={row} />
                      </ModalProvider>
                    </NavControllerProvider>
                    <EditActionDate />
                    <EditActionAccounts row={row} />
                    <EditActionAmounts
                      row={row}
                      editTrade={row.type === GroupedTrade.Uncategorised}
                    />
                    <EditActionFeeAdvanced />
                    <Box>
                      <TextButton
                        endIcon={advancedOpen ? <ExpandLess /> : <ExpandMore />}
                        onClick={() => {
                          setAdvancedOpen((open) => !open);
                        }}
                      >
                        {lang.editTx.advanced}
                      </TextButton>
                    </Box>
                    <Stack
                      direction="column"
                      gap="1rem"
                      display={advancedOpen ? "flex" : "none"}
                    >
                      <EditActionAccountsAdvanced row={row} />
                    </Stack>
                  </Stack>
                </Box>
              </form>
            </FormProvider>
            <StyledFooterContent>
              <Stack direction="row" spacing="1rem">
                <Box>
                  <TertiaryButton onClick={handleClose}>
                    {lang.cancel}
                  </TertiaryButton>
                </Box>
                <Box>
                  <PrimaryButton
                    onClick={form.handleSubmit(handleSubmit)}
                    {...getButtonProps({
                      type: "submit",
                      disabled: isDisabled,
                    })}
                  >
                    {lang.save}
                  </PrimaryButton>
                </Box>
              </Stack>
            </StyledFooterContent>
          </Box>
        )}
      </Drawer>
    </>
  );
};

const StyledFooterContent = styled(DrawerContent)`
  display: flex;
  align-items: center;
  justify-content: start;
  position: fixed;
  background-color: ${({ theme }) => theme.tokens.elevation.default};
  width: 100%;
  z-index: 2;
  bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.high};
  height: 5rem;
`;
