import { useState } from "react";

import { CSVFileItem } from "~/components/imports/csv/CsvFileItem";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { useLang } from "~/redux/lang";
import { useLoadingSavedImports } from "~/state/imports";
import {
  type ImportOptionV2,
  type SavedFileImport,
  type SavedImportByIntegration,
} from "~/types/index";

interface CsvImportListProps {
  savedImport?: SavedImportByIntegration;
  importOption: ImportOptionV2;
  handleDelete: (file: SavedFileImport) => void;
}

export function CSVImportList({
  savedImport,
  importOption,
  handleDelete,
}: CsvImportListProps) {
  const [open, setOpen] = useState(false);
  const [deletingFile, setDeletingFile] = useState<SavedFileImport>();

  const isLoading = useLoadingSavedImports();
  if (!savedImport?.files.length && !isLoading) {
    return null;
  }

  const handleDeleteRequest = (file: SavedFileImport) => {
    setDeletingFile(file);
    setOpen(true);
  };

  return (
    <>
      <DeleteCSVDialog
        deletingFile={deletingFile}
        isOpen={open}
        setOpen={setOpen}
        setDeletingFile={setDeletingFile}
        handleDelete={handleDelete}
      />
      {savedImport?.files.map((file) => (
        <CSVFileItem
          key={file.id}
          handleDelete={handleDeleteRequest}
          file={file}
          exchangeName={importOption.name}
        />
      ))}
    </>
  );
}

function DeleteCSVDialog({
  deletingFile,

  isOpen,
  setOpen,
  setDeletingFile,
  handleDelete,
}: {
  deletingFile: SavedFileImport | undefined;

  isOpen: boolean;
  setOpen: (val: boolean) => void;
  setDeletingFile: (val: SavedFileImport | undefined) => void;
  handleDelete: (wallet: SavedFileImport) => void;
}) {
  const lang = useLang();

  const handleConfirm = (confirm: boolean) => {
    setOpen(false);
    if (confirm && deletingFile) {
      handleDelete(deletingFile);
      setDeletingFile(undefined);
    }
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={lang.imports.deleteImportedFileConfirmation.title}
      text={lang.imports.deleteImportedFileConfirmation.body}
      actionText={lang.delete}
      handleClose={() => {
        handleConfirm(false);
      }}
      handleAction={() => {
        handleConfirm(true);
      }}
      critical
    />
  );
}
