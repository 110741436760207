import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";

import { Pagination } from "~/components/nav/Pagination";
import { DrawerContent } from "~/components/ui/Drawer";
import { useDesign } from "~/hooks/useTheme";

export const StyledFooterContent = styled(DrawerContent)`
  display: flex;
  align-items: center;
  justify-content: start;
  position: fixed;
  background-color: ${({ theme }) => theme.tokens.elevation.default};
  width: 100%;
  z-index: 2;
  bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.tokens.border.neutral.high};
  height: 5rem;
`;

export const Table = styled.table`
  width: 100%;

  border-spacing: 0;
  min-width: 25rem;
  background: ${(props) =>
    props.theme.tokens.background.neutral.lowest.default};
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.tokens.border.neutral.default};
  overflow: hidden;
  & tr:last-child td {
    border-bottom: none;
  }
`;

export const TableHeaderCell = styled.th`
  padding: 0.5rem;

  text-align: left;
  border-bottom: 1px solid
    ${(props) => props.theme.tokens.border.neutral.default};
`;

export const TableBodyCell = styled.td`
  vertical-align: top;
  padding: 0.5rem;
  border-bottom: 1px solid
    ${(props) => props.theme.tokens.border.neutral.default};
`;
export const TableRow = styled.tr`
  cursor: pointer;
  width: 100%;
  &:hover ${TableBodyCell} {
    background: ${(props) =>
      props.theme.tokens.background.neutral.lowest.hover};

    span {
      color: ${(props) => props.theme.tokens.text.high} !important;
    }
  }
`;
export const AddressPill = styled.div`
  border-radius: 4rem;
  background: ${(props) => props.theme.tokens.background.accent.neutral.low};

  padding: 0.25rem 0.75rem;

  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export function PaginationFooter<T>({ data }: { data: T[] | undefined }) {
  const { tokens } = useDesign();
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [rowsPerPage, setRowsPerPage] = useQueryParam(
    "pageSize",
    withDefault(NumberParam, 10),
  );

  return (
    <tfoot>
      <tr>
        <td colSpan={100}>
          <Box
            p="0.5rem"
            width="100%"
            display="flex"
            flexDirection="row-reverse"
            alignItems="center"
            borderTop={`1px solid ${tokens.border.neutral.default}`}
          >
            <Pagination
              count={data?.length ?? 0}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
              page={page}
              onPageChange={(_e, page) => {
                setPage(page);
              }}
              onRowsPerPageChange={(rows) => {
                setRowsPerPage(rows);
              }}
            />
          </Box>
        </td>
      </tr>
    </tfoot>
  );
}
