import { type LocalCurrency } from "@ctc/types";
import { Trade } from "@ctc/types";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepIcon,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { type ReactNode, useEffect, useState } from "react";
import { Controller, type ControllerProps, useForm } from "react-hook-form";
import styled from "styled-components/macro";

import { useSuccessButton } from "~/components/reconciliation/hooks";
import { CurrencyComboBox } from "~/components/transactions/CurrencyComboBox";
import { DateTimePicker } from "~/components/transactions/edit/DateTimePicker";
import {
  exchangeSourceValidator,
  isSameExchangeBlockchain,
} from "~/components/transactions/edit/helper";
import { PriceField } from "~/components/transactions/edit/PriceField";
import { ExchangeComboBox } from "~/components/transactions/ExchangeComboBox";
import { ContextIdType } from "~/components/transactions/filter-bar/enums";
import { getViewInContextPageLink } from "~/components/transactions/filter-bar/FilterContext";
import {
  getCurrencyIdentifier,
  getCurrencySymbol,
  validatePositiveValue,
} from "~/components/transactions/helpers";
import { DrawerContent } from "~/components/ui/Drawer";
import { displayMessage } from "~/components/ui/Toaster";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { categoriseTransactionsLink } from "~/constants/constants";
import { blurTarget } from "~/lib/blurCurrentTarget";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { divide, multiply, safeFloat } from "~/lib/index";
import { useDateTimePlaceholder, useLocalCurrency } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { manualInputTradeOptions } from "~/services/transactions";
import { useCreateManualActionMutation } from "~/state/actions";
import { useLockPeriodEndDate } from "~/state/period";
import { DisplayMessage, ManualTransactionType } from "~/types/enums";
import {
  type ActionType,
  type CurrencyIdentifier,
  type ExchangeOption,
  type ManualTransactionEntry,
  type ManualTransactionFormValues,
} from "~/types/index";

import { useIsEmbedded } from "../../../hooks/useIsEmbedded";

function formatTransactionEntry(
  formValues: ManualTransactionFormValues,
  localCurrency: LocalCurrency | undefined,
): ManualTransactionEntry {
  const { baseCurrency, quoteCurrency, feeCurrency } = formValues;

  const manualTransactionEntry = {
    ...formValues,
    baseCurrency: getCurrencySymbol(baseCurrency),
    baseCurrencyIdentifier: getCurrencyIdentifier(baseCurrency),
    quoteCurrency: quoteCurrency ? getCurrencySymbol(quoteCurrency) : undefined,
    quoteCurrencyIdentifier: quoteCurrency
      ? getCurrencyIdentifier(quoteCurrency)
      : undefined,
    feeCurrency: feeCurrency ? getCurrencySymbol(feeCurrency) : undefined,
    feeCurrencyIdentifier: feeCurrency
      ? getCurrencyIdentifier(feeCurrency)
      : undefined,
    priceCurrency: localCurrency,
  } as ManualTransactionEntry;
  return manualTransactionEntry;
}

function getDefaultValues(): ManualTransactionFormValues {
  return {
    txnCategory: ManualTransactionType.Trade,
    timestamp: "",
    trade: "",
    baseCurrency: "",
    baseQuantity: "",
    quoteCurrency: "",
    quoteQuantity: "",
    feeCurrency: "",
    feeQuantity: "",
    from: {
      label: "",
      name: "",
    },
    exchangeName: {
      label: "",
      name: "",
    },
    to: {
      label: "",
      name: "",
    },
    pricePerUnit: "",
    totalValue: "",
  };
}

const useStepperStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

export const AddManualTransaction = ({
  defaultValues = getDefaultValues(),
  initialStep = 0,
  handleClose,
  setDisabled,
  transactionTime,
  currencyIdentifier,
  noRedirect = false,
}: {
  defaultValues?: ManualTransactionFormValues;
  initialStep?: number;
  handleClose: (isSaving: boolean) => void;
  setDisabled: (showBtn: boolean) => void;
  transactionTime?: string;
  currencyIdentifier?: CurrencyIdentifier;
  noRedirect?: boolean;
}) => {
  const isEmbedded = useIsEmbedded();
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const lockPeriodEndDate = useLockPeriodEndDate();

  if (transactionTime) defaultValues.timestamp = transactionTime;
  if (currencyIdentifier) defaultValues.baseCurrency = currencyIdentifier;

  const {
    handleSubmit,
    formState,
    setValue,
    clearErrors,
    control,
    reset,
    watch,
    trigger,
    unregister,
    getValues,
  } = useForm<ManualTransactionFormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });

  const stepperClasses = useStepperStyles();

  const { isDirty, isValid } = formState;

  const addMutation = useCreateManualActionMutation();
  const { getButtonProps } = useSuccessButton(addMutation);

  const onClose = (id?: string) => {
    clearErrors();
    reset();
    handleClose(false);
    if (id) {
      // view this newly created manual tx in context
      if (!noRedirect) {
        const link = getViewInContextPageLink(id, ContextIdType.TxId);
        const message = (
          <Typography variant="Metropolis/Body/Light">
            {lang.addManualTX.success}
            <span> </span>
            <Link href={link} underline="none">
              {lang.addManualTX.viewTransaction}
            </Link>
          </Typography>
        );

        displayMessage({
          message,
          type: DisplayMessage.Success,
        });
      }

      handleClose(true);
    }
  };

  const onSubmit = async (formData: ManualTransactionFormValues) => {
    if (isDirty) {
      const entry = formatTransactionEntry(formData, localCurrency);

      addMutation.mutate(entry, {
        onSuccess: (data) => {
          const txId = data[0];
          onClose(txId);
        },
      });
    }
  };

  useEffect(() => {
    if (isDirty) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isDirty, setDisabled]);

  const [
    txnCategory,
    timestamp,
    baseCurrency,
    quoteCurrency,
    feeCurrency,
    feeQuantity,
    trade,
    from,
    to,
    exchangeName,
    totalValue,
  ] = watch([
    "txnCategory",
    "timestamp",
    "baseCurrency",
    "quoteCurrency",
    "feeCurrency",
    "feeQuantity",
    "trade",
    "from",
    "to",
    "exchangeName",
    "totalValue",
  ]);

  // track the active and previous step for validation purposes
  const [activeStep, setActiveStep] = useState(initialStep);

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const prevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // trigger the form validation, so the steps ahead which may be invalid
    // don't block the previous step
    trigger("txnCategory");
  };

  const timePlaceHolder = useDateTimePlaceholder();
  const onQuantityChange = (quantity: string) => {
    setValue("baseQuantity", quantity, {
      shouldValidate: true,
      shouldDirty: true,
    });
    const price = getValues("pricePerUnit");
    const totalValue = getValues("totalValue");

    if (price.length > 0 && quantity.length > 0) {
      const value = multiply(safeFloat(price), safeFloat(quantity));
      setValue("totalValue", value.toString(), {
        shouldValidate: true,
      });
    } else if (totalValue.length > 0 && quantity.length > 0) {
      const price = divide(safeFloat(totalValue), safeFloat(quantity));
      setValue("pricePerUnit", price.toString(), {
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    if (txnCategory === ManualTransactionType.Trade) {
      setValue("trade", Trade.Sell);
    }
    if (txnCategory === ManualTransactionType.Transfer) {
      setValue("trade", Trade.Deposit);
    }
    if (txnCategory !== ManualTransactionType.Trade) {
      unregister(["quoteCurrency", "quoteQuantity"]);
    }
  }, [txnCategory, setValue, unregister]);

  const txnCategoryField = (
    <Controller
      control={control}
      name="txnCategory"
      rules={{
        required: true,
      }}
      render={({ field, fieldState: { invalid } }) => (
        <FormControl fullWidth variant="outlined" error={invalid}>
          <RadioGroup
            {...field}
            name="txnCategory"
            onChange={(event) => {
              field.onChange(event);
            }}
          >
            {[
              ManualTransactionType.Trade,
              ManualTransactionType.Transfer,
              ManualTransactionType.Other,
            ].map((tradeType) => (
              <FormControlLabel
                control={<Radio />}
                value={tradeType}
                key={tradeType}
                style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                label={
                  <span>
                    <Typography variant="Metropolis/Body/Regular">
                      {lang.manual.transactionType[tradeType]}
                    </Typography>
                    <Typography variant="Metropolis/Body/Regular">
                      {lang.manual.transactionTypeDescription[tradeType]}
                    </Typography>
                  </span>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );

  const timeField = (
    <Controller
      control={control}
      name="timestamp"
      rules={{
        required:
          activeStep >= 1
            ? `${lang.manual.requiredText.time({
                timeFormat: timePlaceHolder,
              })}`
            : false,
        validate: {
          // Checks that the date is not in the locked period
          // You can't add a manual transaction into a locked period
          notInLockedPeriod: (value: string) => {
            const selectedDate = new Date(value);
            const isValid = selectedDate > lockPeriodEndDate;
            return isValid || lang.manual.requiredText.notInLockedPeriod;
          },
        },
      }}
      render={({
        field: { value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <FormControl fullWidth error={invalid}>
          <DateTimePicker
            onBlur={onBlur}
            label=""
            value={value}
            handleChange={(date: string) => {
              setValue("timestamp", date, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            helperText={error?.message}
            error={invalid}
            required
            inputProps={{ "data-hj-allow": true }}
          />
        </FormControl>
      )}
    />
  );

  const baseCurrencyField = (
    <Controller
      control={control}
      name="baseCurrency"
      rules={{
        required: activeStep >= 1 ? lang.manual.requiredText.currency : false,
      }}
      render={({
        field: { value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <FormControl fullWidth error={invalid}>
          <CurrencyComboBox
            id="base-currency-combo-box"
            value={value}
            onBlur={onBlur}
            disabled={[Trade.In, Trade.Out].includes(
              trade as Trade,
            )}
            placeholder={lang.addManualTX.currencyPlaceholder}
            setValue={(value: CurrencyIdentifier | string | null) => {
              setValue("baseCurrency", value || "", {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            error={invalid}
            helperText={error?.message}
            inputProps={{ "data-hj-allow": true }}
          />
        </FormControl>
      )}
    />
  );
  const baseQuantityField = (
    <Controller
      control={control}
      name="baseQuantity"
      rules={{
        required: activeStep >= 1 ? lang.manual.requiredText.quantity : false,
        validate: {
          invalid: validatePositiveValue(
            lang.editTx.validatePositiveQuantityValue,
          ),
        },
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <TextField
          {...field}
          fullWidth
          variant="outlined"
          type="number"
          inputProps={{
            "data-hj-allow": true,
            min: 0,
            step: "any",
            required: false,
            onWheel: blurTarget,
          }}
          error={invalid}
          onChange={(e) => {
            onQuantityChange(e.target.value);
          }}
          helperText={
            error?.message ||
            (baseCurrency &&
              `${lang.addManualTX.totalQuantityOf} ${getCurrencySymbol(
                baseCurrency,
              )}`) ||
            null
          }
          required
        />
      )}
    />
  );
  const quoteCurrencyField = (
    <Controller
      control={control}
      name="quoteCurrency"
      rules={{
        required: activeStep >= 1 ? lang.manual.requiredText.currency : false,
      }}
      render={({
        field: { value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <CurrencyComboBox
          value={value}
          onBlur={onBlur}
          id="quote-currency-combo-box"
          placeholder={lang.addManualTX.quotePlaceholder}
          setValue={(value: CurrencyIdentifier | string | null) => {
            setValue("quoteCurrency", value || "", {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
          helperText={error?.message}
          error={invalid}
          inputProps={{ "data-hj-allow": true }}
        />
      )}
    />
  );
  const quoteQuantityField = (
    <Controller
      control={control}
      name="quoteQuantity"
      rules={{
        required: activeStep >= 1 ? lang.manual.requiredText.quantity : false,
        validate: {
          invalid: validatePositiveValue(
            lang.editTx.validatePositiveQuantityValue,
          ),
        },
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <TextField
          {...field}
          fullWidth
          variant="outlined"
          type="number"
          inputProps={{
            "data-hj-allow": true,
            min: 0,
            step: "any",
            onWheel: blurTarget,
          }}
          error={invalid}
          helperText={
            error?.message ||
            (quoteCurrency &&
              quoteCurrency !== undefined &&
              `${lang.addManualTX.totalQuantityOf} ${getCurrencySymbol(
                quoteCurrency,
              )}`)
          }
        />
      )}
    />
  );
  const priceField = (
    <Controller
      control={control}
      name="pricePerUnit"
      rules={{
        required: activeStep >= 1 ? lang.manual.requiredText.price : false,
        validate: {
          invalid: validatePositiveValue(
            lang.editTx.validatePositivePriceValue,
          ),
        },
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <PriceField
          {...field}
          currency={baseCurrency}
          timestamp={timestamp}
          required
          setPrice={(marketPrice: string) => {
            setValue("pricePerUnit", marketPrice, {
              shouldValidate: true,
              shouldDirty: true,
            });
            const quantity = getValues("baseQuantity");
            if (quantity.length > 0) {
              const totalValue = multiply(
                safeFloat(marketPrice),
                safeFloat(quantity),
              );
              setValue("totalValue", totalValue.toString(), {
                shouldValidate: true,
              });
            }
          }}
          error={invalid}
          helperText={
            error?.message ||
            (baseCurrency &&
              `${lang.addManualTX.priceForOne} ${
                typeof baseCurrency === "string"
                  ? baseCurrency
                  : baseCurrency?.symbol
              } ${lang.addManualTX.in} ${localCurrency}`) ||
            null
          }
          inputProps={{ "data-hj-allow": true }}
        />
      )}
    />
  );

  const totalValueField = (
    <Controller
      control={control}
      name="totalValue"
      rules={{
        required: activeStep >= 1 ? lang.manual.requiredText.value : false,
        validate: {
          invalid: validatePositiveValue(lang.editTx.validatePositiveValue),
        },
      }}
      render={({ field, fieldState: { invalid, error } }) => (
        <TextField
          {...field}
          fullWidth
          variant="outlined"
          type="number"
          inputProps={{
            "data-hj-allow": true,
            min: 0,
            step: "any",
            onWheel: blurTarget,
          }}
          onChange={(e) => {
            const totalValue = e.target.value;
            setValue("totalValue", totalValue, {
              shouldValidate: true,
              shouldDirty: true,
            });
            const quantity = getValues("baseQuantity");
            if (quantity.length > 0) {
              const price = divide(safeFloat(totalValue), safeFloat(quantity));
              setValue("pricePerUnit", price.toString(), {
                shouldValidate: true,
              });
            }
          }}
          error={invalid}
          helperText={
            error?.message ||
            (totalValue &&
              totalValue !== undefined &&
              `${lang.addManualTX.totalValueIn} ${localCurrency}`)
          }
        />
      )}
    />
  );

  const tradeTypes = alphabeticallySortTradeTypes(
    manualInputTradeOptions as ActionType[],
    lang.tradeType,
  );

  const tradeField = (
    <Controller
      control={control}
      name="trade"
      rules={{
        required: activeStep >= 1 ? "Required" : false,
      }}
      render={({ field, fieldState: { invalid } }) => (
        <FormControl fullWidth variant="outlined" error={invalid}>
          <Select
            {...field}
            name="trade"
            displayEmpty
            required
            inputProps={{ "data-hj-allow": true, required: false }}
            error={invalid}
          >
            {tradeTypes.map((tradeType) => (
              <MenuItem key={tradeType} value={tradeType}>
                {getActionTypeName({
                  actionType: tradeType,
                  lang,
                })}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ cursor: "pointer", textAlign: "right" }}>
            <Link
              href={categoriseTransactionsLink}
              underline="none"
              sx={{ fontWeight: 500 }}
              target={isEmbedded ? "_self" : "_blank"}
              rel="noopener noreferrer"
            >
              <span>
                <span style={{ textDecoration: "underline" }}>
                  {lang.addManualTX.whichCategory}
                </span>
              </span>
            </Link>
          </FormHelperText>
        </FormControl>
      )}
    />
  );

  const exchangeField = (
    name: "exchangeName" | "from" | "to",
    rules: ControllerProps["rules"],
  ) => (
    <Controller
      // @ts-ignore
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <ExchangeComboBox
          name="exchangeName"
          value={value}
          onBlur={onBlur}
          id={`${name}-combo-box`}
          setValue={(value: ExchangeOption | null) => {
            setValue(name, value || { label: "", name: "" }, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
          helperText={error?.message}
          error={invalid}
        />
      )}
    />
  );

  const feeQuantityField = (
    <Controller
      control={control}
      name="feeQuantity"
      rules={{
        required:
          activeStep >= 3 && feeCurrency
            ? lang.manual.requiredText.feeQuantity
            : false,
        validate:
          activeStep >= 3
            ? {
                invalid: validatePositiveValue(
                  lang.editTx.validatePositiveQuantityValue,
                ),
              }
            : undefined,
      }}
      render={({
        field: { onBlur, ...rest },
        fieldState: { invalid, error },
      }) => (
        <TextField
          {...rest}
          fullWidth
          variant="outlined"
          type="number"
          inputProps={{
            "data-hj-allow": true,
            min: 0,
            step: "any",
            onWheel: blurTarget,
          }}
          error={invalid}
          onBlur={() => {
            onBlur();
            trigger("feeCurrency");
          }}
          helperText={
            error?.message ||
            (feeCurrency &&
              feeCurrency !== undefined &&
              `${lang.addManualTX.totalQuantityOf} ${getCurrencySymbol(
                feeCurrency,
              )} ${lang.addManualTX.paidInFees}`) ||
            lang.addManualTX.feePaidIn
          }
        />
      )}
    />
  );

  const feeCurrencyField = (
    <Controller
      control={control}
      name="feeCurrency"
      rules={{
        required:
          activeStep >= 3 && feeQuantity
            ? lang.manual.requiredText.feeCurrency
            : false,
      }}
      render={({
        field: { value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <CurrencyComboBox
          id="fee-currency-combo-box"
          value={value}
          onBlur={() => {
            onBlur();
            trigger("feeQuantity");
          }}
          placeholder={lang.addManualTX.quotePlaceholder}
          setValue={(value: CurrencyIdentifier | string | null) => {
            setValue("feeCurrency", value || "", {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
          error={invalid}
          helperText={error?.message || lang.addManualTX.currencyPaidIn}
          inputProps={{ "data-hj-allow": true }}
        />
      )}
    />
  );
  const steps: {
    id: string;
    label: string;
    isOptional: boolean;
    isValid?: () => boolean;
    content: ReactNode;
  }[] = [
    {
      id: "category",
      label: lang.manual.stepTitle.category,
      isOptional: false,
      content: (
        <Grid item xs={12}>
          {txnCategoryField}
        </Grid>
      ),
    },
    {
      id: "details",
      label: lang.manual.stepTitle.details({ category: txnCategory }),
      isOptional: false,
      content: (
        <>
          {txnCategory === ManualTransactionType.Trade ? (
            <>
              <Grid item xs={12}>
                <Box display="flex">
                  <Typography variant="Metropolis/Body/Regular" pr={1}>
                    {lang.manual.time}
                  </Typography>
                  <Typography variant="Metropolis/Caption/Medium/Regular">
                    ({timePlaceHolder})
                  </Typography>
                </Box>
                {timeField}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.sellCurrency}
                </Typography>
                {baseCurrencyField}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.sellQuantity}
                </Typography>
                {baseQuantityField}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.receiveCurrency}
                </Typography>
                {quoteCurrencyField}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.receiveQuantity}
                </Typography>
                {quoteQuantityField}
              </Grid>
            </>
          ) : txnCategory === ManualTransactionType.Transfer ? (
            <>
              <Grid item xs={12}>
                <Box display="flex">
                  <Typography variant="Metropolis/Body/Regular" pr={1}>
                    {lang.manual.time}
                  </Typography>
                  <Typography variant="Metropolis/Caption/Medium/Regular">
                    ({timePlaceHolder})
                  </Typography>
                </Box>
                {timeField}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.currency}
                </Typography>
                {baseCurrencyField}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.quantity}
                </Typography>
                {baseQuantityField}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.price}
                </Typography>
                {priceField}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.value}
                </Typography>
                {totalValueField}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Box display="flex">
                  <Typography variant="Metropolis/Body/Regular" pr={1}>
                    {lang.manual.time}
                  </Typography>
                  <Typography variant="Metropolis/Caption/Medium/Regular">
                    ({timePlaceHolder})
                  </Typography>
                </Box>
                {timeField}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.trade}
                </Typography>
                {tradeField}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.currency}
                </Typography>
                {baseCurrencyField}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.quantity}
                </Typography>
                {baseQuantityField}
              </Grid>

              <Grid item xs={6}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.price}
                </Typography>
                {priceField}
              </Grid>

              <Grid item xs={6}>
                <Typography variant="Metropolis/Body/Regular">
                  {lang.manual.value}
                </Typography>
                {totalValueField}
              </Grid>
            </>
          )}
        </>
      ),
    },
    {
      id: "sourceanddest",
      label: lang.manual.stepTitle.sourceAndDest,
      isOptional: false,
      content: (
        <>
          {txnCategory === ManualTransactionType.Trade ? (
            <Grid item xs={12}>
              <Typography variant="Metropolis/Body/Regular">
                {lang.addManualTX.exchangeName}
              </Typography>
              {exchangeField("exchangeName", {
                validate:
                  activeStep >= 2
                    ? {
                        sameBlockchain: (value: ExchangeOption) => {
                          return (
                            isSameExchangeBlockchain(value, exchangeName) ||
                            lang.editTx.cantMixBlockchain
                          );
                        },
                        validLabel: (value: ExchangeOption) =>
                          Boolean(value?.label) ||
                          lang.addManualTX.mustEnterExchange,
                      }
                    : undefined,
              })}
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {txnCategory === ManualTransactionType.Transfer
                    ? lang.addManualTX.whereFrom
                    : lang.addManualTX.whereSource}
                </Typography>
                {exchangeField("from", {
                  validate:
                    activeStep >= 2
                      ? {
                          sameBlockchain: (value: ExchangeOption) =>
                            isSameExchangeBlockchain(value, to) ||
                            lang.editTx.cantMixBlockchain,
                          validLabel: (value: ExchangeOption) =>
                            Boolean(value?.label) ||
                            lang.addManualTX.mustSource,
                          validSource: (value: ExchangeOption) =>
                            exchangeSourceValidator(value) ||
                            lang.addManualTX.mustSource,
                        }
                      : undefined,
                })}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Metropolis/Body/Regular">
                  {txnCategory === ManualTransactionType.Transfer
                    ? lang.addManualTX.whereTo
                    : lang.addManualTX.whereDestination}
                </Typography>
                {exchangeField("to", {
                  validate:
                    activeStep >= 2
                      ? {
                          sameBlockchain: (value: ExchangeOption) =>
                            isSameExchangeBlockchain(value, from) ||
                            lang.editTx.cantMixBlockchain,

                          validLabel: (value: ExchangeOption) =>
                            Boolean(value?.label) ||
                            lang.addManualTX.mustDestination,
                          validSource: (value: ExchangeOption) =>
                            exchangeSourceValidator(value) ||
                            lang.addManualTX.mustDestination,
                        }
                      : undefined,
                })}
              </Grid>
            </>
          )}
        </>
      ),
    },
    {
      id: "fees",
      label: lang.manual.stepTitle.fees,
      isOptional: true,
      content: (
        <>
          <Grid item xs={12}>
            <Typography variant="Metropolis/Body/Regular">
              {lang.manual.feeCurrency}
            </Typography>
            {feeCurrencyField}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="Metropolis/Body/Regular">
              {lang.manual.feeQuantity}
            </Typography>
            {feeQuantityField}
          </Grid>
        </>
      ),
    },
  ];

  return (
    <>
      <Box mt="4rem" mb="5rem">
        <DrawerContent>
          <form onSubmit={handleSubmit(onSubmit)} aria-label="form">
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              classes={stepperClasses}
            >
              {steps.map((step) => (
                <Step key={step.id}>
                  <StepLabel StepIconComponent={StyledStepIcon}>
                    {step.label}
                    {step.isOptional ? (
                      <Box component="span" ml={1}>
                        <Chip
                          style={{ borderRadius: "0.25rem" }}
                          label={lang.addManualTX.optional}
                          size="small"
                        />
                      </Box>
                    ) : null}
                  </StepLabel>
                  <StepContent>
                    <Grid container spacing={1}>
                      {step.content}
                    </Grid>
                    <Box mb={2} mt={2}>
                      {activeStep !== 0 && (
                        <StyledButton
                          disabled={activeStep === 0}
                          onClick={prevStep}
                          variant="outlined"
                        >
                          {lang.back}
                        </StyledButton>
                      )}

                      {activeStep !== steps.length - 1 && (
                        <StyledButton
                          variant="contained"
                          color="primary"
                          name="next"
                          onClick={nextStep}
                          disabled={!isValid}
                        >
                          {lang.next}
                        </StyledButton>
                      )}
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </form>
        </DrawerContent>
      </Box>
      <StyledDrawerContent>
        <StyledModalFooter style={{ position: "fixed", padding: 0 }}>
          <Box>
            <PrimaryButton
              type="submit"
              {...getButtonProps({
                onClick: handleSubmit(onSubmit),
                disabled: activeStep !== steps.length - 1 || !isValid,
              })}
            >
              {lang.manual.addTransaction}
            </PrimaryButton>
            <Box component="span" ml="0.5rem">
              <TertiaryButton
                onClick={() => {
                  handleClose(false);
                }}
              >
                {lang.cancel}
              </TertiaryButton>
            </Box>
          </Box>
        </StyledModalFooter>
      </StyledDrawerContent>
    </>
  );
};
const StyledModalFooter = styled.div`
  && {
    padding: 0rem 1.5rem;
    display: block;
  }
`;

const StyledStepIcon = styled(StepIcon)`
  && {
    color: ${({ theme }) => theme.tokens.icon.brand};
  }

  &.Mui-completed {
    color: ${({ theme }) => theme.tokens.icon.low};
  }

  &.MuiStepIcon-text {
    color: ${({ theme }) => theme.tokens.text.default};
  }
`;

const StyledDrawerContent = styled(DrawerContent)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  background-color: ${({ theme }) => theme.tokens.elevation.default};
  width: 100%;
  z-index: 2;
  bottom: 0;
  height: 5rem;
`;

/**
 * Sorts a list of trade types in alphabetical order based on their display name,
 * but bubbles buy/sell and send/receive to the top of the list
 * @param tradeTypes The list of trade types to order
 * @param lang Lang mapping of the display names of the trade types
 */
export function alphabeticallySortTradeTypes(
  tradeTypes: ActionType[],
  lang: Record<ActionType, string>,
) {
  const buySell: ActionType[] = [Trade.Buy, Trade.Sell];
  const sendRecieve: ActionType[] = [Trade.Withdrawal, Trade.Deposit];
  const sorted = [...tradeTypes]
    .sort((a, b) => (lang[a] < lang[b] ? -1 : 1))
    .sort((a, b) =>
      sendRecieve.includes(a) ? -1 : sendRecieve.includes(b) ? 1 : 0,
    )
    .sort((a, b) => (buySell.includes(a) ? -1 : buySell.includes(b) ? 1 : 0));
  return sorted;
}

const StyledButton = styled(Button)`
  && {
    margin-right: 1rem;
  }
`;
