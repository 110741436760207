import { OpenInNew } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

import { ActionRowSecondLineStyle } from "~/components/transactions/action-row/Overflower";
import { Ellipsis } from "~/components/transactions/Ellipsis";
import { type IdChip } from "~/components/transactions/helpers";
import { LazyLoadImageWithFallback } from "~/components/transactions/ImageWithFallback";
import { CensoredBox } from "~/components/ui/CensoredComponents";
import { DisplayExchangeActionItems } from "~/components/ui/DisplayExchangeActionItems";
import { AddressLink } from "~/components/ui/enums";
import { getBlockchainLogoSrc } from "~/components/ui/ExchangeLogo";
import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useHover } from "~/hooks/useHover";
import { useDesign } from "~/hooks/useTheme";
import { middleTrim } from "~/lib/index";

export function TransactionRowId({ idChip }: { idChip: IdChip }) {
  const { tokens } = useDesign();

  // This should never be null, but sometimes it is.  And when it is, the site crashes.
  // https://cryptotaxcalculatorco.slack.com/archives/C03A2KT8ZTR/p1657261558349699
  const { id, blockchain, link } = idChip;
  if (!id) return null;

  const width = 16,
    height = 16;
  const src = getBlockchainLogoSrc(blockchain);

  const startIcon = link ? (
    <Box display="flex" alignItems="center">
      <LazyLoadImageWithFallback
        height={height}
        width={width}
        src={src}
        alt={`${blockchain}-logo`}
        effect="blur"
        style={{ borderRadius: width / 2 }}
      />
    </Box>
  ) : undefined;

  return (
    <CensoredBox display="flex" gap="0.25rem" my="0.25rem">
      <StyledTextButton
        href={link}
        target="_blank" // todo: fix when we move to new tab logic component
        rel="noopener noreferrer"
        size="small"
        startIcon={startIcon}
        endIcon={link ? <OpenInNew style={{ fontSize: "1rem" }} /> : null}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
        }}
      >
        <Ellipsis style={{ marginTop: "0.25rem" }}>{middleTrim(id)}</Ellipsis>
      </StyledTextButton>
      <CopyIconButton
        contentToCopy={id}
        width={16}
        color={tokens.icon.default}
      />
    </CensoredBox>
  );
}

export function InlineTxFunctionName({
  functionName,
}: {
  functionName: string;
}) {
  const hover = useHover();
  return (
    <div ref={hover.ref}>
      <Box mt="-1px" mb="1px">
        <InlineTxRowText text={functionName} hover={hover.value} />
      </Box>
    </div>
  );
}

export function InlineTxId({
  id,
  link,
  forceHover,
}: {
  id: string;
  link: string | null;
  forceHover?: boolean;
}) {
  const hover = useHover();
  return (
    <div ref={hover.ref}>
      <InlineTxRowText
        text={id}
        link={link ?? undefined}
        hover={forceHover || hover.value}
        shouldTrim
      />
    </div>
  );
}

export function InlineTxRowText({
  text,
  hover,
  link,
  shouldTrim = false,
  stopPropagation = true,
}: {
  text: string;
  hover: boolean;
  link?: string;
  shouldTrim?: boolean;
  stopPropagation?: boolean;
}) {
  const { tokens } = useDesign();
  // This should never be null, but sometimes it is.  And when it is, the site crashes.
  // https://cryptotaxcalculatorco.slack.com/archives/C03A2KT8ZTR/p1657261558349699
  if (!text) return null;

  return (
    <CensoredBox display="flex">
      <StyledInlineTextButton
        href={link}
        target="_blank" // todo: fix when we move to new tab logic component
        rel="noopener noreferrer"
        size="small"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          if (stopPropagation) e.stopPropagation();
        }}
      >
        <Typography
          variant="IBM Plex Mono/Caption/Small/Regular"
          sx={{
            lineHeight: null,
            color: hover ? tokens.text.high : tokens.text.low,
          }}
        >
          {shouldTrim || !hover ? middleTrim(text) : text}
        </Typography>
        {hover ? (
          <Box marginLeft="0.25rem">
            <DisplayExchangeActionItems
              type={AddressLink.Tx}
              exchange={text}
              addressLink={link}
              width={14}
              noPadding
            />
          </Box>
        ) : null}
      </StyledInlineTextButton>
    </CensoredBox>
  );
}

const StyledTextButton = styled((props: any) => (
  <TextButton component="a" {...props} />
))`
  && {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: ${({ theme }) => theme.tokens.text.default};
    font-weight: 500;
  }

  & :hover {
    color: ${({ theme }) => theme.tokens.text.brand};
  }
`;

const StyledInlineTextButton = styled((props: any) => (
  <TextButton component="a" {...props} />
))`
  && {
    padding: 0 0.25rem;
    ${ActionRowSecondLineStyle}
    min-width: 0;
  }

  & :hover {
    svg {
      color: ${({ theme }) => theme.tokens.text.default};
    }
  }
`;
