import { type PaymentPopupState } from "./types";
import { useSelector } from "./useSelector";

enum PaymentPopupAction {
  SetWindow = "paymentPopup/setWindow",
  SetIsOpen = "paymentPopup/setIsOpen",
}

const initialState: PaymentPopupState = {
  window: null,
  isOpen: false,
};

type PaymentPopupActionType =
  | {
      type: PaymentPopupAction.SetWindow;
      payload: Window | null;
    }
  | {
      type: PaymentPopupAction.SetIsOpen;
      payload: boolean;
    };

export function paymentPopupReducer(
  state = initialState,
  action: PaymentPopupActionType,
): PaymentPopupState {
  switch (action.type) {
    case PaymentPopupAction.SetWindow:
      return {
        ...state,
        window: action.payload,
      };
    case PaymentPopupAction.SetIsOpen:
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
}

// Action creators
export function setPaymentPopup(window: Window | null) {
  return {
    type: PaymentPopupAction.SetWindow,
    payload: window,
  };
}

export function setIsPaymentPopupOpen(isOpen: boolean) {
  return {
    type: PaymentPopupAction.SetIsOpen,
    payload: isOpen,
  };
}

// Selectors
export const usePaymentPopupWindow = () =>
  useSelector((state) => state.paymentPopup.window);

export const useIsPaymentPopupOpen = () =>
  useSelector((state) => state.paymentPopup.isOpen);
