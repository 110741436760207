import { geIdAndBlockchain, middleTrim } from "~/lib";
import {
  type Entity,
  type ExchangeNames,
  type SavedWalletImport,
} from "~/types/index";

export const IN_FLIGHT_EXCHANGE_ID = "in_flight";

export function getTitleByExchangeId(
  exchangeIdFull: string,
  exchangeNames: ExchangeNames,
  wallet: SavedWalletImport | undefined,
  entity: Entity | undefined,
): string {
  if (entity) {
    return entity.displayName;
  }
  if (exchangeIdFull === IN_FLIGHT_EXCHANGE_ID) {
    return "In Flight";
  }
  const [exchangeId] = geIdAndBlockchain(exchangeIdFull);
  if (exchangeId === "unknown") {
    return "Unknown";
  }

  if (wallet && wallet.name) {
    return wallet.name;
  } else if (wallet && wallet.address) {
    return middleTrim(wallet.address, undefined, 4);
  }

  if (exchangeNames[exchangeId]) {
    return exchangeNames[exchangeId];
  }

  return middleTrim(exchangeId, undefined, 4);
}

export function getWallet(
  wallets: SavedWalletImport[],
  exchangeId: string,
  blockchain?: string,
): SavedWalletImport | undefined {
  return wallets.find((w) => {
    const isSameAddresses =
      w.address.toLowerCase() === exchangeId.toLowerCase();
    if (blockchain) {
      // If there is a blockchain appended at the end
      // we use it to verify we have the correct wallet.
      return isSameAddresses && w.blockchain === blockchain;
    }
    return isSameAddresses;
  });
}
