import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { FeatureFlagSelector } from "~/components/settings-modal/views/alpha/FeatureFlagSelector";
import { Billing } from "~/components/settings-modal/views/billing/index";
import { SettingsTabs } from "~/components/settings-modal/views/enums";
import { useTabsForUser } from "~/components/settings-modal/views/hooks/useTabsForUser";
import { LockPeriod } from "~/components/settings-modal/views/lock-period/index";
import { Plan } from "~/components/settings-modal/views/plan/index";
import { PortfolioSettings } from "~/components/settings-modal/views/portfolio/index";
import { Accounting } from "~/components/settings-modal/views/profile/Accounting";
import { Profile } from "~/components/settings-modal/views/profile/Profile";
import { ReportSettings } from "~/components/settings-modal/views/tax/ReportSettings";
import { Team } from "~/components/settings-modal/views/team/index";
import { useIsLoadingUser } from "~/redux/auth";
import { Links } from "~/types/enums";

export function SettingsPageContents({
  active,
  setActive,
  setOpen,
}: {
  active: SettingsTabs | undefined;
  setActive: (active: SettingsTabs) => void;
  setOpen: (open: boolean) => void;
}) {
  const tabs = useTabsForUser().map((tab) => tab.name);
  const isLoadingUser = useIsLoadingUser();

  useEffect(() => {
    if (!isLoadingUser) {
      if (active === SettingsTabs.Loading) {
        setActive(SettingsTabs.Profile);
      }
    }
  }, [isLoadingUser, active, setActive]);

  // Dont show anything while the user is loading
  if (isLoadingUser) {
    return (
      <Box
        minWidth="15rem"
        width="100%"
        height="25rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  // if a tab is selected, the user is loaded, and its an inaccessible tab
  if (active && ![...tabs, SettingsTabs.Loading].includes(active)) {
    setActive(SettingsTabs.Profile);
  }

  switch (active) {
    case SettingsTabs.Profile:
      return <Profile />;
    case SettingsTabs.Billing:
      return <Billing />;
    case SettingsTabs.General:
      return <ReportSettings />;
    case SettingsTabs.Portfolio:
      return <PortfolioSettings />;
    case SettingsTabs.Permissions:
      return <Accounting />;
    case SettingsTabs.Alpha:
      return <FeatureFlagSelector />;
    case SettingsTabs.LockPeriod:
      return <LockPeriod />;
    case SettingsTabs.Team:
      return <Team />;
    case SettingsTabs.Plan:
      return <Plan />;
    case SettingsTabs.IntegrationsRedirect: {
      setOpen(false);
      return <Navigate to={`${Links.AccountingIntegrations}`} />;
    }
    default:
      return (
        <Box
          minWidth="15rem"
          width="100%"
          height="25rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
  }
}
