import { type LoadingState } from "~/redux/types";

export function loadingReducer(
  state: LoadingState = {},
  action: { type: string },
) {
  const { type } = action;
  const matches = /(.*)\/(request|success|failure)/.exec(type);

  // not a */request | */success | */failure actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving get/todos/request
    //      and false when receiving get/todos/success OR get/todos/failure
    [requestName]: requestState === "request",
  };
}
