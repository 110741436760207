import { MIN_FIAT_VALUE } from "~/components/transactions/action-row/types";
import { displayFiatCompactValue } from "~/lib/index";

// Show a compact fiat value with fixed two decimal places, e.g. 1000 instead of $1,000.00
export const getCompactFiatValue = (value: number, precision: number) => {
  const isLessThenMinFiatValue = Math.abs(value) < MIN_FIAT_VALUE;
  const isLoss = value < 0;
  const isZero = value === 0;

  const displayLessThenMinFiatValue = ` < ${
    isLoss ? `-` : ""
  }${MIN_FIAT_VALUE}`;

  return isLessThenMinFiatValue && !isZero
    ? displayLessThenMinFiatValue
    : displayFiatCompactValue(value, undefined, undefined, precision);
};
