import { formatCategoryOption } from "~/components/transactions/command-palette/formatCategoryOption";
import {
  type HoverMenuProps,
  SmallCommandPaletteMenu,
} from "~/components/transactions/command-palette/SmallCommandPaletteMenu";
import { useDesign } from "~/hooks/useTheme";
import { invariant } from "~/lib/invariant";
import { TradeInfo } from "~/lib/tradeTypeDefinitions";
import { useLang } from "~/redux/lang";
import { useTaxSettings } from "~/redux/report";
import {
  getTransactionCategoryOptionsByDirection,
  isTrade,
} from "~/services/transactions";
import { type CategoryBucket, type TradeDirection } from "~/types/enums";
import { type ActionRow, type ActionType } from "~/types/index";

type CategoryBucketSecondLevelSelectorProps = {
  row: ActionRow;
  direction: TradeDirection;
  categoryBucket: CategoryBucket;
  onSubmit: (trade: ActionType) => void;
} & Partial<HoverMenuProps>; // props added when in hover menu mode;

export const CategoryBucketSecondLevelSelector = ({
  row,
  direction,
  categoryBucket,
  onSubmit,
  onClose,
  inHoverMenu,
}: CategoryBucketSecondLevelSelectorProps) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const taxSettings = useTaxSettings();

  if (!taxSettings) {
    return null;
  }

  // possible categories it could be that match the bucket and the direction
  const categories = getTransactionCategoryOptionsByDirection({
    direction,
  }).filter((category) => {
    invariant(isTrade(category), "Cannot be a grouped type");
    const data = TradeInfo[category];
    if (!data.bucket) {
      return false;
    }
    if (data.direction !== direction) {
      return false;
    }
    if (data.bucket(taxSettings) !== categoryBucket) {
      return false;
    }
    return true;
  });

  // convert to an option
  const options = categories
    .flatMap((category) => {
      invariant(isTrade(category), "Cannot be a grouped type");
      const option = formatCategoryOption({
        lang,
        taxSettings,
        tokens,
        trade: category,
      });
      if (!option) {
        return [];
      }
      return [option];
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const categoryBucketLabel = lang.tradeTypeGroup[categoryBucket].label;
  return (
    <SmallCommandPaletteMenu
      hideSearch={Boolean(inHoverMenu)}
      title={lang.tradeTypeGroup[categoryBucket].description}
      options={options}
      selectedOptionLabel={
        formatCategoryOption({
          lang,
          taxSettings,
          trade: row.type,
          tokens,
        })?.label
      }
      placeholder={lang.txTable.commandPallet.placeholders.searchSubType({
        subType: categoryBucketLabel.toLowerCase(),
      })}
      onSelection={({ trade }) => {
        onClose?.();
        onSubmit(trade);
      }}
    />
  );
};
