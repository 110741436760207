import { Box } from "@mui/material";
import styled from "styled-components/macro";

export const HotkeyIcon = styled(Box)`
  ${({ theme }) => ({ ...theme.mui.typography["Metropolis/Body/Regular"] })};
  padding: 0.25rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.tokens.elevation.highest};
  color: ${({ theme }) => theme.tokens.text.disabled};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
