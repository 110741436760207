import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { type PartyDetails } from "~/services/transactions";

export function getOptionsFromParties(parties: PartyDetails[]) {
  return (
    parties.flatMap((p) => {
      return [
        {
          label: p.displayName,
          icon: (
            <ExchangeLogo
              width={20}
              height={20}
              name={p.exchange}
              walletAddress={p.exchange}
              blockchain={p.blockchain}
              showBlockchainSymbol={!!p.blockchain}
            />
          ),
          party: p,
          chevron: true,
        },
      ];
    }) ?? []
  );
}
