import { type Blockchain, SupportedLang } from "@ctc/types";

import { ERPProvider } from "~/components/settings-modal/views/enums";
import { displayMessage } from "~/components/ui/Toaster";
import { LocalStorageKey } from "~/constants/enums";
import {
  setMomentLocale,
  type Translation,
  translationMap,
} from "~/lang/index";
import { type AppThunk, type LangState as State } from "~/redux/types";
import { useSelector } from "~/redux/useSelector";
import * as settingsAPI from "~/services/settings";
import { useAvailableImportOption } from "~/state/imports";
import {
  DisplayMessage,
  ImportMethod,
  ImportType,
  IntegrationCategory,
} from "~/types/enums";
import { type ImportTypeInstructionsJson } from "~/types/importInstructions";
import {
  BrowserLanguageMap,
  type ImportMethodV2,
  type ImportTypeInstructions,
  isPolkadotCompatibleChain,
  LanguageLocales,
} from "~/types/index";

enum Lang {
  SetLanguagePreference = "lang/setLanguagePreference",
  SetLanguageOptions = "lang/setLanguageOptions",
  SetUpdateLogin = "lang/setUpdateLogin",
}

type LangAction =
  | { type: Lang.SetLanguagePreference; language: SupportedLang }
  | { type: Lang.SetLanguageOptions; langs: SupportedLang[] }
  | { type: Lang.SetUpdateLogin; value: boolean };

const initialLanguage = getInitialLanguage();
const initialState: State = {
  current: initialLanguage,
  map: translationMap[initialLanguage],
  languageOptions: [SupportedLang.En, SupportedLang.De, SupportedLang.Es],
  updated: false,
};

export function lang(state = initialState, action: LangAction): State {
  switch (action.type) {
    case Lang.SetLanguagePreference:
      return translationMap[action.language]
        ? {
            ...state,
            map: translationMap[action.language],
            current: action.language,
          }
        : state;
    case Lang.SetLanguageOptions:
      return {
        ...state,
        languageOptions: action.langs,
      };
    case Lang.SetUpdateLogin:
      return {
        ...state,
        updated: action.value,
      };
    default:
      return { ...state };
  }
}

function getInitialLanguage(): SupportedLang {
  const browserLanguage = navigator.language;

  if (BrowserLanguageMap[browserLanguage]) {
    return BrowserLanguageMap[browserLanguage];
  }
  return SupportedLang.En;
}

export function setLanguagePreference(language: SupportedLang) {
  return {
    type: Lang.SetLanguagePreference,
    language,
  };
}

function setLanguageOptions(langs: SupportedLang[]) {
  return {
    type: Lang.SetLanguageOptions,
    langs,
  };
}

export function setUpdateLogin(value: boolean) {
  return {
    type: Lang.SetUpdateLogin,
    value,
  };
}

export function useLang() {
  return useSelector((state) => state.lang.map);
}

export function useErpLang(erp: ERPProvider | undefined) {
  const langs = useLang();
  if (!erp) {
    // this is just a sane default
    // @todo - erp should never be undefined here
    return langs[ERPProvider.Xero];
  }
  return langs[erp];
}

export function useLanguagePreference(): SupportedLang {
  return useSelector((state) => state.lang.current);
}

export function useLocale(): string {
  const languagePreference = useLanguagePreference();
  return LanguageLocales[languagePreference];
}

export function useNotice(exchangeLabel: string): string | null {
  const lang = useSelector((state) => state.lang.map.importInstructionsNotice);
  const exchangeLabelTyped = exchangeLabel as keyof typeof lang;
  const instructions = lang[exchangeLabelTyped];
  return instructions || null;
}

export function useSteps({
  exchangeLabel,
  importType,
  importMethod,
  exchangeDisplayName = "",
  selectedBlockchain,
}: {
  exchangeLabel: string;
  importType: string;
  importMethod: ImportMethodV2;
  exchangeDisplayName?: string;
  selectedBlockchain?: Blockchain | undefined;
}): ImportTypeInstructions | null {
  const lang = useSelector((state) => state.lang.map);
  const importOption = useAvailableImportOption(exchangeLabel);
  // If the integration supports its own CSV - add advanced CSV format as a note
  // If the integration doesn't supports a CSV - add advanced CSV format as a section
  const isManualCSVOnly =
    importOption?.manual ||
    importType === ImportType.ManualCSV ||
    (importMethod.type === ImportMethod.CSV && importMethod.options?.manual) ||
    importOption?.category === IntegrationCategory.Manual;

  const isImportingCSV =
    importType === ImportType.CSV || importType === ImportType.ManualCSV;

  const resolvedSteps = getSteps({
    lang: lang.importInstructions,
    exchangeLabel,
    importType,
    importMethod,
    exchangeDisplayName,
    selectedBlockchain,
  });

  if (isManualCSVOnly && isImportingCSV) {
    return {
      ...resolvedSteps,
      step: [
        ...(resolvedSteps?.step || []),
        { items: lang.imports.manualCSV.steps },
      ],
    };
  }
  return resolvedSteps;
}

export function getSteps({
  lang,
  exchangeLabel,
  importType,
  importMethod,
  exchangeDisplayName,
  selectedBlockchain,
}: {
  lang: Translation["importInstructions"];
  exchangeLabel: string;
  importType: string;
  importMethod: ImportMethodV2;
  exchangeDisplayName: string;
  selectedBlockchain: Blockchain | undefined;
}) {
  const instructions = lang[exchangeLabel as keyof typeof lang];

  if (instructions && importType in instructions) {
    const importTypeTyped = importType as keyof typeof instructions;
    const base = instructions[importTypeTyped] as ImportTypeInstructionsJson;
    return {
      ...base,
      step: base.step
        ? base.step.map((item) => ({
            ...item,
            items: item.items
              ? item.items.map((element) => {
                  if (typeof element === "function") {
                    return element({ exchangeDisplayName });
                  }
                  return element;
                })
              : undefined,
          }))
        : undefined,
    };
  }

  if (
    importMethod.type === ImportMethod.Wallet &&
    importMethod.options?.isBtcAltNetwork &&
    exchangeDisplayName &&
    importType === (ImportType.WalletAPI as string)
  ) {
    const base = lang.btcAltNetwork["wallet-api"] as ImportTypeInstructionsJson;

    return {
      ...base,
      step: base.step
        ? base.step.map((item) => ({
            ...item,
            items: item.items
              ? item.items.map((element) => {
                  if (typeof element === "function") {
                    return element({ exchangeDisplayName });
                  }
                  return element;
                })
              : undefined,
          }))
        : undefined,
    };
  }

  if (
    isPolkadotCompatibleChain(exchangeLabel) &&
    importMethod.type === ImportMethod.Wallet
  ) {
    const base = lang["substrate-chains"][
      "wallet-api"
    ] as ImportTypeInstructionsJson;

    return {
      ...base,
      step: base.step
        ? base.step.map((item) => ({
            ...item,
            items: item.items
              ? item.items.map((element) => {
                  if (typeof element === "function") {
                    return element({ exchangeDisplayName });
                  }
                  return element;
                })
              : undefined,
          }))
        : base.step,
    };
  }

  if (importType === (ImportType.SoftWallet as string)) {
    // If user has selected a blockchain, use that blockchain's instructions
    // Otherwise don't display any instructions
    if (!selectedBlockchain) return null;

    const selectedBlockchainType = selectedBlockchain as keyof typeof lang;
    const baseIntegration = lang[selectedBlockchainType];

    if (!baseIntegration) return null;

    const baseInstructions =
      "wallet-api" in baseIntegration
        ? (baseIntegration["wallet-api"] as ImportTypeInstructionsJson)
        : undefined;

    if (baseInstructions) {
      return {
        ...baseInstructions,
        step: baseInstructions.step
          ? baseInstructions.step.map((item) => ({
              ...item,
              items: item.items
                ? item.items.map((element) => {
                    if (typeof element === "function") {
                      return element({ exchangeDisplayName });
                    }
                    return element;
                  })
                : undefined,
            }))
          : undefined,
      };
    }
  }

  return null;
}

export const updateLanguage =
  (language: SupportedLang): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const user = getState().auth.user;

    // Optimistically update the language preference and moment locale
    dispatch(setLanguagePreference(language));
    setMomentLocale(language);
    localStorage.setItem(LocalStorageKey.Lang, language);

    if (!user) {
      // case where user isnt logged in
      dispatch(setUpdateLogin(true));
      return;
    }

    // optimistic update
    const langs = state.lang.map;

    const res = await settingsAPI.updateLanguage(language);

    if (res.error) {
      /**
       * dont revert optimistic update (allow user to keep browsing in the
       * language they chose), but alert that their preference wasn't able to
       * be saved
       */
      displayMessage({
        message: langs.changeLang.error,
        type: DisplayMessage.Error,
      });
    }
  };

export const getLanguageOptions = (): AppThunk => async (dispatch) => {
  const res = await settingsAPI.getSupportedLangs();
  if (!res.error && res.data.langs && res.data.langs.length > 0) {
    dispatch(setLanguageOptions(res.data.langs));
  }
};

export function useLanguageOptions() {
  return useSelector((state) => state.lang.languageOptions);
}
