import { type Blockchain } from "@ctc/types";
import { Close, ErrorOutline } from "@mui/icons-material";
import {
  Box,
  type ChipProps,
  CircularProgress,
  Typography,
  type TypographyProps,
} from "@mui/material";
import { normalize } from "viem/ens";

import { type AddressError } from "~/components/imports/enums";
import { BlockchainStack } from "~/components/imports-v2/BlockchainStack";
import {
  formatDisplayAddress,
  getAddressLink,
} from "~/components/transactions/helpers";
import { CopyIconButton } from "~/components/ui/ui-buttons/icon-buttons/CopyIconButton";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import {
  CensoredBox,
  CensoredTooltip,
  CensoredTypography,
} from "~/components/ui/CensoredComponents";
import { Chip } from "~/components/ui/Chips";
import { AddressChipStyle } from "~/components/ui/enums";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { ExternalAddressLink } from "~/components/ui/ExternalAddressLink";
import { type Tokens } from "~/components/ui/theme/tokens";
import { useDesign } from "~/hooks/useTheme";
import { middleTrim } from "~/lib/index";
import { useLang } from "~/redux/lang";
import { DefaultBlockchainCurrency } from "~/types/index";

export type AddressChipProps = {
  style?: AddressChipStyle;
  address: string;
  blockchain?: Blockchain;
  blockchainsStack?: Blockchain[];
  exchangeLogoSize?: number;
  enableCopy?: boolean; // Enable the copy address icon
  enableLink?: boolean; // Enable link to the address on an explorer e.g. Etherscan.
  enableDelete?: boolean; // Enable the close icon
  enableBlockchainStack?: boolean; // Enable the blockchain stack
  onDelete?: (event: any) => void;
  addressError?: AddressError;
  chipProps?: ChipProps;
  typographyProps?: TypographyProps;
  tooltip?: string;
  isLoading?: boolean;
  displayAddress?: string;
};

function getColors(
  tokens: Tokens,
  style: AddressChipStyle,
): { backgroundColor: string; fontColor: string } {
  if (style === "default") {
    return {
      backgroundColor: tokens.background.accent.neutral.low,
      fontColor: tokens.text.default,
    };
  } else if (style === "raised") {
    return {
      backgroundColor: tokens.background.brand.default,
      fontColor: tokens.text.default,
    };
  } else if (style === "text") {
    return {
      backgroundColor: "transparent",
      fontColor: "inherit",
    };
  }
  return {
    backgroundColor: tokens.button.brand.default,
    fontColor: tokens.text.white,
  };
}

export function AddressChipWithNickname({
  nickname,
  ...props
}: AddressChipProps & { nickname?: string }) {
  if (!nickname) {
    return <AddressChip {...props} />;
  }
  // this is a hack to get the tooltip to work, should really be using styles
  // like we do for the API list item
  const MAX_WALLET_NAME_LENGTH = 30;
  const walletIsOverLength = nickname.length > MAX_WALLET_NAME_LENGTH;
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CensoredTooltip
        title={walletIsOverLength ? nickname : ""}
        placement="top"
      >
        <CensoredTypography variant="Metropolis/Body/Regular" mr={1} noWrap>
          {nickname ? middleTrim(nickname) : ""}
        </CensoredTypography>
      </CensoredTooltip>
      <AddressChip {...props} />
    </Box>
  );
}

// viems normalize function throws on invalid characters e.g. ":", which could be inputted if a user accidently pastes a url
// just catching so the page doesnt break
export function normalizeAddress(address: string) {
  try {
    return normalize(address);
  } catch (e) {
    return undefined;
  }
}

export function AddressChip({
  style = AddressChipStyle.Default,
  address,
  blockchain,
  blockchainsStack = [],
  exchangeLogoSize = 12,
  enableCopy,
  enableLink,
  enableDelete,
  enableBlockchainStack,
  onDelete,
  addressError,
  chipProps = {},
  typographyProps = {},
  tooltip: _tooltip,
  isLoading,
  displayAddress: _displayAddress,
}: AddressChipProps) {
  const { tokens } = useDesign();
  const lang = useLang();
  const currencySymbol = blockchain && DefaultBlockchainCurrency[blockchain];
  const addressLink = getAddressLink(address, currencySymbol, blockchain);
  const { backgroundColor, fontColor } = getColors(tokens, style);

  const displayAddress = _displayAddress ?? address;
  const tooltip = _tooltip ?? displayAddress;
  return (
    <Chip
      bgcolor={backgroundColor}
      color={fontColor}
      border={addressError ? `1px solid ${tokens.border.danger}` : "initial"}
      display="flex"
      alignItems="center"
      {...chipProps}
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <CensoredTooltip
            title={
              <>
                <Typography variant="Metropolis/Caption/Medium/Regular">
                  {tooltip}
                </Typography>
                {addressError ? (
                  <Typography
                    variant="Metropolis/Caption/Medium/Bold"
                    color={tokens.text.danger}
                  >
                    {lang.wallet.importRules[addressError]}
                  </Typography>
                ) : null}
              </>
            }
          >
            <CensoredBox display="flex" alignItems="center" gap="0.25rem">
              {addressError ? (
                <ErrorOutline
                  sx={{
                    width: "1rem",
                    height: "1rem",
                    color: tokens.text.danger,
                  }}
                />
              ) : isLoading ? (
                <CircularProgress size={exchangeLogoSize} />
              ) : (
                <ExchangeLogo
                  name={address}
                  blockchain={blockchain}
                  currencySymbol={currencySymbol}
                  width={exchangeLogoSize}
                  height={exchangeLogoSize}
                  margin="0.25rem 0"
                />
              )}
              <Typography
                {...typographyProps}
                sx={{
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  color: fontColor,
                  backgroundColor,
                  mb: "-1.5px", // Align with other items,
                  ...(typographyProps.sx || {}),
                }}
              >
                {formatDisplayAddress(displayAddress, false, true)}
              </Typography>
            </CensoredBox>
          </CensoredTooltip>
          {enableCopy || enableLink || enableDelete || enableBlockchainStack ? (
            <Box ml="0.375rem">
              <Box display="flex" flexWrap="nowrap">
                {enableLink && addressLink ? (
                  <Box mr={enableCopy ? "0.375rem" : undefined}>
                    <ExternalAddressLink
                      link={addressLink}
                      tooltip={lang.txTable.viewAddress}
                      width={14}
                      color={fontColor}
                      noPadding
                    />
                  </Box>
                ) : null}
                {enableCopy ? (
                  <CopyIconButton
                    contentToCopy={address}
                    width={14}
                    color={fontColor}
                    noPadding
                  />
                ) : null}
                {enableDelete ? (
                  <TextIconButton onClick={onDelete} sx={{ padding: 0 }}>
                    <Close sx={{ width: "0.875rem", height: "0.875rem" }} />
                  </TextIconButton>
                ) : null}
                {enableBlockchainStack && blockchainsStack.length ? (
                  <BlockchainStack
                    showOnMobile
                    blockchains={blockchainsStack}
                    stackSize={4}
                  />
                ) : null}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Chip>
  );
}
