import { CaptchaProvider, OAuthProvider, Plan } from "@ctc/types";
import { Warning } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useMatch, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { REFERRER_CONFIG } from "~/components/payment/referrer-config";
import { Breaker } from "~/components/ui/Breaker";
import { SuccessIcon } from "~/components/ui/Icons";
import { PasswordTextField } from "~/components/ui/PasswordTextField";
import { StyledNoLineLink } from "~/components/ui/StyledLink";
import { TermsAndConditions } from "~/components/ui/TermsAndConditions";

import { displayMessage } from "~/components/ui/Toaster";
import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { OAuthLoginButton } from "~/components/user/OAuthLoginButton";
import { Recaptcha } from "~/components/user/Recaptcha";
import { Turnstile } from "~/components/user/Turnstile";
import { UserDetailsPage } from "~/components/user/UserDetails";
import {
  formatEmail,
  isValidEmail,
  stripSpacesOnKeyDown,
} from "~/components/user/validators";
import { AnalyticsUTMParams, isReferrerSource } from "~/constants/constants";
import { AccountantClientWithCrypto, LocalStorageKey } from "~/constants/enums";
import { useCaptcha } from "~/hooks/useCaptchaVerification";
import { useDesign } from "~/hooks/useTheme";
import { passwordChecker } from "~/lib/passwordChecker";
import { resetError, signup, useAuth, useWalletOAuthLogin } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Analytics } from "~/segment/index";
import { DisplayMessage, Links } from "~/types/enums";

// Green box for any promotion offer banner
const PromotionBannerBox = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;

  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.tokens.border.success};
  background: ${(props) => props.theme.tokens.background.success.default};
`;

export const SignupForm = ({
  path,
}: {
  path: Links.Signup | Links.SignupAccountant;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const isAccountantSignup = !!useMatch(Links.SignupAccountant);
  const dispatch = useDispatch();
  const { loading, error } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const captureAnalytics = useCaptureAnalytics();

  // used for extra detail when accountants sign up
  const [accountantClientsWithCrypto, setAccountantClientsWithCrypto] =
    useState<AccountantClientWithCrypto | null>(null);
  const [accountantCompanyName, setAccountantCompanyName] = useState("");
  const [accountantContactName, setAccountantContactName] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const params = useParams<Record<string, string>>();
  const referrerSource = localStorage.getItem(LocalStorageKey.ReferrerSource);
  const { isLoading: isLoadingWalletAuth, handleWalletConnect } =
    useWalletOAuthLogin();
  // Check if our form is valid
  const isFormValid =
    isValidEmail(email) && !passwordError && password.length > 0;

  const {
    recaptchaRef,
    setTurnstileCaptcha,
    getCaptchaToken,
    captchaProvider,
    turnstileCaptcha,
  } = useCaptcha();

  const referrerConfig = isReferrerSource(referrerSource)
    ? REFERRER_CONFIG[referrerSource]
    : undefined;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      displayMessage({
        message: lang.auth.invalidCombination,
        type: DisplayMessage.Error,
      });
      return;
    }

    const captcha = await getCaptchaToken({ login: false });
    if (!captcha) {
      return; // Error already displayed by the hook
    }

    const validPassword = passwordChecker(password);
    if (validPassword.error) {
      setPasswordError(lang.password[validPassword.msg]);
      return;
    }

    const queryParams = Object.keys(params)
      .filter(
        (param) =>
          AnalyticsUTMParams.includes(param) ||
          (param.endsWith("clid") && param.length < 20),
      )
      .reduce((obj, k) => ({ ...obj, [k]: params[k] }), {});

    const formattedEmail = formatEmail(email);

    const analytics = await Analytics.getInstance();
    analytics.track({
      event: "sign_up_button_clicked",
      props: { email: formattedEmail, ...queryParams },
    });

    if (isAccountantSignup) {
      captureAnalytics("accountant_clients_with_crypto", {
        accountantClientsWithCrypto,
      });
    }

    const signupData = isAccountantSignup
      ? {
          email: formattedEmail,
          password,
          captcha,
          captchaProvider,
          accountantClientsWithCrypto,
          accountantCompanyName,
          accountantContactName,
          paidPlan: Plan.Accountant,
        }
      : {
          email: formattedEmail,
          password,
          captcha,
          captchaProvider,
          paidPlan: Plan.Free,
        };
    dispatch(signup(signupData));
  };

  useEffect(() => {
    const handlePasswordChange = () => {
      if (password) {
        const validPassword = passwordChecker(password);
        if (validPassword.error) {
          setPasswordError(lang.password[validPassword.msg]);
        }
      }
    };
    handlePasswordChange();
  }, [password, lang.password]);

  const removeAuthWarning = () => dispatch(resetError());

  return (
    <UserDetailsPage
      formText={{
        title: lang.auth.signupPage.title,
      }}
      isSignUp
    >
      {isAccountantSignup ? null : (
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <OAuthLoginButton
            text={lang.auth.signupPage.oauth.metamask}
            action={handleWalletConnect}
            oAuthProvider={OAuthProvider.Moralis}
            loading={isLoadingWalletAuth}
          />
          <OAuthLoginButton
            text={lang.auth.signupPage.oauth.google}
            oAuthProvider={OAuthProvider.Google}
            disabled={isLoadingWalletAuth}
          />
          <OAuthLoginButton
            text={lang.auth.signupPage.oauth.coinbase}
            oAuthProvider={OAuthProvider.Coinbase}
            disabled={isLoadingWalletAuth}
          />

          <Breaker
            text={lang.auth.breaker}
            background={tokens.elevation.default}
          />
        </Box>
      )}
      <Box display="flex" flexDirection="column" justifyContent="space-around">
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box gap="1rem" display="flex" flexDirection="column">
            {error ? <Alert severity="error">{error}</Alert> : null}
            {isAccountantSignup ? (
              <>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel id="accountant-client-label">
                    {lang.auth.accountantClient}
                  </InputLabel>
                  <Select
                    id="accountant-client-label"
                    required
                    label={lang.auth.accountantClient}
                    value={accountantClientsWithCrypto}
                    onChange={(e) => {
                      setAccountantClientsWithCrypto(
                        e.target.value as AccountantClientWithCrypto,
                      );
                    }}
                  >
                    {Object.values(AccountantClientWithCrypto).map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  variant="outlined"
                  name="accountantCompanyName"
                  type="text"
                  label={lang.auth.accountantCompanyName}
                  required
                  fullWidth
                  onChange={(e) => {
                    setAccountantCompanyName(e.target.value);
                  }}
                  value={accountantCompanyName}
                />

                <TextField
                  variant="outlined"
                  name="accountantContactName"
                  type="text"
                  label={lang.auth.accountantContactName}
                  required
                  fullWidth
                  onChange={(e) => {
                    setAccountantContactName(e.target.value);
                  }}
                  value={accountantContactName}
                />
              </>
            ) : null}
            <TextField
              autoComplete="off"
              variant="outlined"
              name="email"
              type="email"
              label={
                isAccountantSignup
                  ? lang.auth.accountantWorkEmail
                  : lang.auth.email
              }
              required
              fullWidth
              onKeyDown={stripSpacesOnKeyDown}
              onChange={(e) => {
                setEmail(formatEmail(e.target.value));
              }}
              value={email}
            />
            <PasswordTextField
              variant="outlined"
              name="password"
              label={lang.auth.password}
              autoComplete="new-password"
              required
              fullWidth
              error={!!passwordError}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError("");
              }}
              inputProps={{ maxLength: 64, minLength: 8 }}
              value={password}
            />
            {passwordError && password && (
              <Box display="flex" alignItems="center">
                <AlertWarningIcon />
                <Typography
                  color={tokens.text.danger}
                  variant="Metropolis/Caption/Medium/Regular"
                >
                  {passwordError}
                </Typography>
              </Box>
            )}
            {!passwordError && password && (
              <Box display="flex" alignItems="center" gap="0.5rem">
                <SuccessIcon size="0.8rem" />
                <Typography
                  variant="Metropolis/Caption/Medium/Regular"
                  color={tokens.text.default}
                >
                  {lang.auth.strongPassword}
                </Typography>
              </Box>
            )}

            <Box display="flex">
              <Box display="flex" alignItems="center">
                <TermsAndConditions />
              </Box>
            </Box>
            {referrerConfig?.signupPromoText(lang) ? (
              <PromotionBannerBox
                style={{ marginTop: "0rem", marginBottom: "0rem" }}
              >
                <CheckCircleIcon
                  style={{
                    color: tokens.border.success,
                  }}
                />
                <Typography variant="Metropolis/Body/Regular">
                  {referrerConfig.signupPromoText(lang)}
                </Typography>
              </PromotionBannerBox>
            ) : null}
            {captchaProvider === CaptchaProvider.Turnstile ? (
              <Box display="flex" padding="0.0625rem" justifyContent="center">
                <Turnstile onVerify={setTurnstileCaptcha} />
              </Box>
            ) : null}
            <PrimaryLoadingButton
              type="submit"
              disabled={
                loading ||
                !isFormValid ||
                (captchaProvider === CaptchaProvider.Turnstile &&
                  !turnstileCaptcha)
              }
              loading={loading}
              fullWidth
              css={`
                height: 3.5rem;
              `}
            >
              <Typography
                variant="Metropolis/Header/H5"
                color={tokens.text.white}
              >
                {lang.auth.signup}
              </Typography>
            </PrimaryLoadingButton>
          </Box>
        </form>

        <Box textAlign="center" padding="0.5rem" paddingTop="1rem">
          <Typography variant="Metropolis/Caption/Medium/Regular">
            {`${lang.auth.alreadyAccount} `}
            <StyledNoLineLink to={Links.Login} onClick={removeAuthWarning}>
              {lang.auth.login}
            </StyledNoLineLink>
            {`, ${lang.auth.orSwitchTo} `}
            <StyledNoLineLink
              to={isAccountantSignup ? Links.Signup : Links.SignupAccountant}
            >
              {isAccountantSignup
                ? lang.auth.userSignUp
                : lang.auth.accountantSignUp}
            </StyledNoLineLink>
          </Typography>
        </Box>
        {captchaProvider === CaptchaProvider.Recaptcha ? (
          <Box display="flex" justifyContent="center">
            <Recaptcha recaptchaRef={recaptchaRef} />
          </Box>
        ) : null}
      </Box>
    </UserDetailsPage>
  );
};

export const AlertWarningIcon = styled(Warning)`
  color: ${({ theme }) => theme.tokens.text.danger};
  margin-right: 0.5rem;
  width: 1.1rem;
`;
