import { Link, type LinkProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components/macro";

export const StyledLink = ({ to, ...props }: { to: string } & LinkProps) => (
  <Link component={RouterLink} to={to} {...props} />
);

export const StyledNoLineLink = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.tokens.text.brand};
`;
