import { Trade } from "@ctc/types";
import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  type UseFormReturn,
} from "react-hook-form";
import short from "short-uuid";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureActionAnalytics } from "~/analytics/posthog";
import { createTxAmountPatch } from "~/components/transactions/edit/createTxAmountPatches";
import { type EditActionValues } from "~/components/transactions/edit/EditActionDrawer";
import {
  EditActionTxAmount,
  type TxAmount,
} from "~/components/transactions/edit/EditActionTxAmount";
import { EditType } from "~/components/transactions/edit/enums";
import { LockedTxEditFallback } from "~/components/transactions/edit/helper";
import { TradeTypeTitle } from "~/components/transactions/edit/TradeTypeTitle";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { GeneralDialogForm } from "~/components/ui/GeneralDialog";
import { useDesign } from "~/hooks/useTheme";
import { divide } from "~/lib/index";
import { invariant } from "~/lib/invariant";
import { useLang } from "~/redux/lang";
import {
  getFeesFromActionRow,
  type PutTransactionPayload,
} from "~/services/actions";
import { useAtomicUpdateTransactions } from "~/state/actions";
import { Direction, QuickEditFocusField } from "~/types/enums";
import { type ActionRow } from "~/types/index";

export type EditActionFeeValues = {
  fee: (TxAmount & { editType: EditType })[];
};

export const FEE_FIELD_ARRAY_NAME = "fee" as const;

type EditFeeDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  row: ActionRow;
};

// Show all fee and a summary of fees
export const getValues = ({ row }: { row: ActionRow }): EditActionFeeValues => {
  const fees = getFeesFromActionRow(row);

  return {
    fee: [
      ...fees.map((tx) => {
        return {
          // add this property to manage when we do with the backend
          editType: EditType.Existing,

          txId: tx.id,
          timestamp: tx.timestamp,
          currency: tx.currency,
          quantity: tx.quantity.toString(),
          price: divide(tx.value, tx.quantity).toString(),
          value: tx.value.toString(),
          markIgnoreMissingPrice: false,
          groupById: tx.groupById,
          blockchain: tx.blockchain,
        };
      }),
    ],
  };
};

export const applyUpdates = (
  row: ActionRow,
  form: UseFormReturn<EditActionFeeValues>,
  formData: EditActionFeeValues,
  updateMap: Map<string, PutTransactionPayload>,
  deletedTxIds: string[],
) => {
  const dirtyFees = form.formState.dirtyFields.fee || [];

  formData.fee.map((formDataFee, index) => {
    if (formDataFee.editType === EditType.Removed) {
      deletedTxIds.push(formDataFee.txId);
      return;
    }
    // for creates, we just use a fake id, this won't get sent to the BE
    const transactionId = formDataFee.txId ?? short.generate();
    if (formDataFee.editType === EditType.FeeAdded) {
      if (!updateMap.has(transactionId)) {
        updateMap.set(transactionId, {});
      }
    }
    if (formDataFee.editType === EditType.Existing) {
      if (!updateMap.has(transactionId)) {
        updateMap.set(transactionId, { _id: transactionId });
      }
    }

    const dirty = dirtyFees[index];

    const {
      price,
      currency,
      quantity,
      priceValueCurrency,
      groupById,
      markIgnoreMissingPrice,
      blockchain,
    } = formDataFee;

    createTxAmountPatch({
      dirty,
      price,
      txId: transactionId,
      quantity,
      priceValueCurrency,
      currency,
      markIgnoreMissingPrice,
      groupById,
      updateMap,
      blockchain,
    });
  });
};

export function EditActionFee({
  isOpen,
  handleClose,
  row,
}: EditFeeDialogProps) {
  const lang = useLang();
  const form = useForm<EditActionFeeValues>({
    mode: "onBlur",
    defaultValues: getValues({ row }),
  });
  const updateRowTransactionMutation = useAtomicUpdateTransactions();
  const captureActionAnalytics = useCaptureActionAnalytics();
  const analyticsKey = transactionAnalyticsKey("edit");

  const { control, handleSubmit, formState } = form;

  const { fields } = useFieldArray({
    control,
    name: FEE_FIELD_ARRAY_NAME,
  });

  // We need to purge stale data and reset the form when it is opened.
  useEffect(() => {
    if (isOpen) {
      form.reset(getValues({ row }));
    }
  }, [row, isOpen, form]);

  const { isDirty, isValid } = formState;
  const isDisabled = !isDirty || !isValid;

  const submit = (formData: EditActionFeeValues) => {
    if (isDisabled) return;

    const updateMap = new Map<string, PutTransactionPayload>();
    const deletedTxIds: string[] = [];
    applyUpdates(row, form, formData, updateMap, deletedTxIds);
    const payload = Array.from(updateMap.values());
    captureActionAnalytics(analyticsKey("quick edit fee submitted"), row);
    updateRowTransactionMutation.mutate(
      {
        update: {
          updateTx: { payload, applySts: false, createCategoryRule: false },
        },
        actionId: row._id,
      },
      {
        onSuccess() {
          form.reset();
          handleClose();
        },
      },
    );
  };

  return (
    <GeneralDialogForm
      pending={updateRowTransactionMutation.isPending}
      isOpen={isOpen}
      handleClose={() => {
        handleClose();
        form.reset();
      }}
      fullWidth
      title={lang.editTx.editFee}
      maxWidth="md"
      handleAction={handleSubmit(submit)}
      actionText={lang.update}
      cancelText={lang.cancel}
      stopPropagation
      closeOnClickAway
      disableAction={isDisabled}
    >
      {row.isLocked ? (
        <LockedTxEditFallback />
      ) : (
        <FormProvider {...form}>
          {fields.map((field, index) => (
            <EditActionTxAmount
              key={field.id}
              index={index}
              side={Direction.Outgoing}
              name={FEE_FIELD_ARRAY_NAME}
              showPriceValueSelector={false}
              showLookupMarketPrice
              focusField={QuickEditFocusField.Fee}
              timestamp={field.timestamp}
              blockchain={field.blockchain}
            />
          ))}
        </FormProvider>
      )}
    </GeneralDialogForm>
  );
}

export const EditActionFeeAdvanced = () => {
  const { control, watch, setValue, getValues } =
    useFormContext<EditActionValues>();
  const { tokens } = useDesign();
  const { fields, append, remove } = useFieldArray({
    control,
    name: FEE_FIELD_ARRAY_NAME,
  });
  const lang = useLang();

  const watchedFees = watch(FEE_FIELD_ARRAY_NAME);
  return (
    <Stack
      sx={{
        padding: "0.75rem",
        border: `1px solid ${tokens.border.neutral.medium}`,
        backgroundColor: tokens.elevation.medium,
        borderRadius: "0.25rem",
      }}
      gap="0.75rem"
      direction="column"
    >
      <Stack mb="-0.75rem" direction="row">
        <TradeTypeTitle trade={Trade.Fee} />
      </Stack>
      {fields.map((field, index) => {
        if (watchedFees[index].editType === EditType.Removed) {
          return null;
        }
        return (
          <EditActionTxAmount
            key={field.id}
            index={index}
            side={Direction.Outgoing}
            name={FEE_FIELD_ARRAY_NAME}
            showPriceValueSelector={false}
            onRemove={() => {
              invariant(field.groupById, "Fee field must have a groupById");
              // in the in/out txs, find the matching groupById
              // and remove it from that tx
              const [outgoingAmounts, incomingAmounts] = getValues([
                "amountOutgoing",
                "amountIncoming",
              ]);
              const outgoingIndex = outgoingAmounts.findIndex(
                (amount) => amount.groupById === field.groupById,
              );
              const incomingIndex = incomingAmounts.findIndex(
                (amount) => amount.groupById === field.groupById,
              );
              invariant(
                outgoingIndex >= 0 || incomingIndex >= 0,
                "Could not find tx grouped with fee",
              );
              // remove it
              const prefix =
                outgoingIndex >= 0
                  ? (`amountOutgoing.${outgoingIndex}` as const)
                  : (`amountIncoming.${incomingIndex}` as const);
              setValue(`${prefix}.groupById`, null, {
                shouldValidate: true,
                shouldDirty: true,
              });

              // existing tx, remove it
              if (field.editType === EditType.Existing) {
                setValue(
                  `${FEE_FIELD_ARRAY_NAME}.${index}.editType`,
                  EditType.Removed,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                );
              }
              if (field.editType === EditType.FeeAdded) {
                // never been saved to the db, so just delete it
                remove(index);
              }
            }}
            showLookupMarketPrice
            timestamp={watchedFees[index].timestamp}
            blockchain={watchedFees[index].blockchain}
          />
        );
      })}
      {!watchedFees.find(
        (fee) =>
          fee.editType === EditType.Existing ||
          fee.editType === EditType.FeeAdded,
      ) ? (
        <div>
          <TextButton
            startIcon={<AddIcon />}
            onClick={() => {
              const [amountOutgoing, amountIncoming] = getValues([
                "amountOutgoing",
                "amountIncoming",
              ]);
              const groupById = short().new();

              if (amountOutgoing.length) {
                // the incoming/outgoing and the new fee
                const firstOutgoing = amountOutgoing[0];
                setValue(`amountOutgoing.${0}.groupById`, groupById);
                // TODO: fee is attached to first tx currently
                // user should be able to choose if it goes on incoming or outgoing
                const emptyFee = getEmptyFee(firstOutgoing);
                // remove(fields.length - 1);
                append(emptyFee);
                return;
              }

              if (amountIncoming.length) {
                // the incoming/outgoing and the new fee
                const firstIncoming = amountIncoming[0];
                setValue(`amountIncoming.${0}.groupById`, groupById);
                // TODO: fee is attached to first tx currently
                // user should be able to choose if it goes on incoming or outgoing
                const emptyFee = getEmptyFee(firstIncoming);
                // remove(fields.length - 1);
                append(emptyFee);
              }
            }}
          >
            {lang.editTx.addFee}
          </TextButton>
        </div>
      ) : null}
    </Stack>
  );
};
function getEmptyFee(txDataAmount: TxAmount) {
  return {
    editType: EditType.FeeAdded,
    ...txDataAmount,
    txId: "",
    currency: undefined,
    quantity: "0",
    price: "0",
    value: "0",
  };
}
