import { type EntityAddressQuery } from "@ctc/types";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  InputAdornment,
  type InputProps,
  Skeleton,
  Stack,
  TextField,
  type TextFieldProps,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { type ReactNode, useState } from "react";

import { contactsIntegrationsAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { useSuccessButton } from "~/components/reconciliation/hooks";
import { WalletImage } from "~/components/transactions/ManageEntityDialog";
import { EntityLogo } from "~/components/ui/EntityAddresses";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import {
  useAddAddressToEntityMutation,
  useCreateEntityMutation,
  useEntitiesQuery,
  useEntitySearchQuery,
} from "~/state/entities";
import { EntityType } from "~/types/enums";
import { type Entity, isExchangeEntity } from "~/types/index";

type IdentifyAddressProps = {
  addresses: EntityAddressQuery[];
};

enum AutocompleteOptionType {
  Entity = "ENTITY",
  New = "NEW",
  Searching = "SEARCHING",
}

const useAutocompleteStyles = () => {
  const { tokens } = useDesign();
  return makeStyles({
    groupLabel: {
      lineHeight: "2rem",
      fontSize: "0.75rem",
      color: tokens.text.disabled,
      background: tokens.background.neutral.default,
      padding: "0 0.75rem",
    },
  })();
};

export const IdentifyAddress = ({
  addresses,
  placeholder,
  inputProps,
  disabled = false,
}: IdentifyAddressProps & {
  placeholder?: string;
  inputProps?: TextFieldProps;
  disabled?: boolean;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();
  const [selectedEntity, setSelectedEntity] = useState<Entity | null>();
  const [input, setInput] = useState<string | undefined>();
  const autocompleteStyles = useAutocompleteStyles();
  const captureAnalytics = useCaptureAnalytics();
  const analyticsKey = contactsIntegrationsAnalyticsKey("unidentified");
  const entitiesQuery = useEntitiesQuery();
  const searchQuery = useEntitySearchQuery(input);
  const entities =
    (!input || input?.length === 0
      ? entitiesQuery.data?.entities
      : searchQuery.fetchStatus === "fetching"
        ? entitiesQuery.data?.entities
        : searchQuery.data?.entities) ?? [];

  const createEntityMutation = useCreateEntityMutation();
  const addAddressToEntityMutation = useAddAddressToEntityMutation();

  if (!addresses.length) {
    return null;
  }

  const isSearching =
    input && input.length > 0 && searchQuery.data?.search !== input;

  const isLoading =
    isSearching ||
    entitiesQuery.isPending ||
    createEntityMutation.isPending ||
    addAddressToEntityMutation.isPending;

  const optionsSorted = [
    // convert the entities to autocomplete options and sort them by their group
    ...entities
      .map((e) => ({
        type: AutocompleteOptionType.Entity as const,
        entityId: e._id,
        displayName: e.displayName,
        group: lang.tag.groups[e.type],
        entity: e,
        count:
          e.addresses.length +
          (e.type !== EntityType.Manual ? e.globalAddresses.length : 0),
      }))
      .sort((a, b) => {
        if (a.group === b.group) {
          return -(b.displayName || "").localeCompare(a.displayName || "");
        }
        return -(b.group || "").localeCompare(a.group || "");
      }),

    // when we are searching, add skeletons
    ...(isSearching
      ? [
          {
            type: AutocompleteOptionType.Searching as const,
            group: lang.tag.groups[EntityType.Exchange],
            // Autocomplete requires a string for these values that
            // are unique
            displayName: "Loading 1",
          },
          {
            type: AutocompleteOptionType.Searching as const,
            group: lang.tag.groups[EntityType.Exchange],
            // Autocomplete requires a string for these values that
            // are unique
            displayName: "Loading 2",
          },
        ]
      : []),

    // if they have typed in text, then its an option to create an entity
    // provided there isnt one with an exact match name
    ...(!input ||
    !entities.find((e) => e.displayName.toLowerCase() === input.toLowerCase())
      ? [
          {
            type: AutocompleteOptionType.New as const,
            group: lang.tag.createNewIdentity,
            displayName: input ? input.trim() : lang.tag.startTypingName,
          },
        ]
      : []),
  ];

  const { getButtonProps: createEntityButtonProps } =
    useSuccessButton(createEntityMutation);

  const createEndAdornment = (params: InputProps) => {
    const render = params.endAdornment || null;
    if (!isLoading) return render;
    return <CircularProgress size="1rem" />;
  };

  const getEntityOptionImage = (entity: Entity): ReactNode => {
    if (isExchangeEntity(entity)) {
      return <ExchangeLogo name={entity.ref} margin="0" />;
    }

    if (entity.addresses.length) {
      return <WalletImage address={entity.addresses[0].address} />;
    }

    return (
      <ExchangeLogo
        name={entity._id}
        margin="0"
        walletAddress={addresses[0].address}
      />
    );
  };

  return (
    <Stack direction="column" spacing="1rem">
      <Autocomplete
        disabled={
          createEntityMutation.isPending ||
          addAddressToEntityMutation.isPending ||
          disabled
        }
        options={optionsSorted}
        filterOptions={(options, { inputValue }) => {
          return options.filter(
            (o) =>
              // show it if its a text match
              (o.type === AutocompleteOptionType.Entity &&
                o.displayName
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())) ||
              // show the search type if its there
              o.type === AutocompleteOptionType.Searching ||
              // always show the new type if its there
              o.type === AutocompleteOptionType.New,
          );
        }}
        classes={autocompleteStyles}
        onChange={async (e, value) => {
          if (value?.type === AutocompleteOptionType.New && !input?.length) {
            // empty input, dont make anything
            return;
          }
          setInput(undefined);
          // its an entity option
          if (value && value.type === AutocompleteOptionType.Entity) {
            const entity = entities.find((e) => e._id === value.entityId);

            if (entity) {
              captureAnalytics(analyticsKey("identify"));
              const res = await Promise.all(
                addresses.map(async (address) =>
                  addAddressToEntityMutation.mutateAsync({
                    entity,
                    newAddress: address,
                  }),
                ),
              );
              setSelectedEntity(res[0].entity);
            }
            return;
          }
        }}
        getOptionLabel={(option) => option.displayName ?? ""}
        getOptionDisabled={(option) =>
          option.type === AutocompleteOptionType.New ||
          option.type === AutocompleteOptionType.Searching
        }
        groupBy={(option) => option.group ?? ""}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={
              selectedEntity?.displayName ??
              placeholder ??
              lang.tag.identifyAddress
            }
            onChange={(e) => {
              setInput(e.target.value);
            }}
            InputProps={
              selectedEntity
                ? {
                    ...params.InputProps,
                    endAdornment: createEndAdornment(params.InputProps),
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: tokens.text.default, marginRight: 0 }}
                      >
                        <EntityLogo entity={selectedEntity} />
                      </InputAdornment>
                    ),
                  }
                : {
                    ...params.InputProps,
                    endAdornment: createEndAdornment(params.InputProps),
                  }
            }
            {...inputProps}
          />
        )}
        renderOption={(autocompleteProps, option) => (
          <Box
            {...autocompleteProps}
            component="li"
            onClick={
              autocompleteProps["aria-disabled"]
                ? undefined
                : autocompleteProps.onClick
            }
            className=""
            sx={{
              padding:
                option.type === AutocompleteOptionType.Searching
                  ? "0.15rem 0.75rem"
                  : "0.375rem 0.75rem",
              cursor:
                option.type === AutocompleteOptionType.New
                  ? undefined
                  : "pointer",
              background: tokens.background.neutral.default,
              "&.Mui-focused, &:hover": {
                backgroundColor: tokens.background.neutral.hover,
              },
            }}
            width="100%"
            maxWidth="100%"
            color={tokens.text.default}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing="0.5rem"
              sx={{ width: "100%" }}
            >
              {option.type === AutocompleteOptionType.Entity
                ? getEntityOptionImage(option.entity)
                : null}
              {option.type === AutocompleteOptionType.Searching ? (
                <Skeleton width="100%" height="2rem" />
              ) : option.displayName ? (
                <Box
                  maxWidth={
                    option.type === AutocompleteOptionType.Entity &&
                    option.count
                      ? "calc(95% - 6rem)"
                      : "95%"
                  }
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color={tokens.text.default}
                >
                  <Typography
                    variant="Metropolis/Body/Regular"
                    sx={{
                      color:
                        !input && option.type === "NEW"
                          ? tokens.text.high
                          : tokens.text.default,
                    }}
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {option.displayName}
                  </Typography>
                </Box>
              ) : null}

              {option.type === AutocompleteOptionType.Entity && option.count ? (
                <Box sx={{ marginLeft: "auto !important" }}>
                  <Chip
                    label={`${option.count} identified`}
                    size="small"
                    sx={{ fontSize: "0.75rem", fontWeight: 500 }}
                  />
                </Box>
              ) : null}
              {input?.length && option.type === "NEW" ? (
                <SecondaryButton
                  size="small"
                  {...createEntityButtonProps({
                    sx: { marginLeft: "auto !important" },
                  })}
                  disabled={!input}
                  onClick={async () => {
                    // its the new option
                    const res = await createEntityMutation.mutateAsync({
                      entity: {
                        displayName: input ?? "",
                        type: EntityType.Manual,
                        addresses,
                      },
                    });
                    setSelectedEntity(res.entity);
                  }}
                >
                  {input ? lang.tag.identify : lang.tag.createNewIdentity}
                </SecondaryButton>
              ) : null}
            </Stack>
          </Box>
        )}
      />
    </Stack>
  );
};
