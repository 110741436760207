import "styled-components/macro";

import { Box, TextField, Typography } from "@mui/material";
import type * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { StyledNoLineLink } from "~/components/ui/StyledLink";

import { PrimaryLoadingButton } from "~/components/ui/ui-buttons/PrimaryButton";
import {
  formatEmail,
  stripSpacesOnKeyDown,
} from "~/components/user/validators";
import { useDesign } from "~/hooks/useTheme";
import { forgot, useAuth } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

export const ForgotPasswordForm: React.FC = () => {
  const { tokens } = useDesign();
  const lang = useLang();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { loading } = useAuth();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmail("");
    dispatch(forgot({ email: formatEmail(email) }));
    navigate("/login");
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextField
          style={{ marginBottom: "1rem" }}
          variant="outlined"
          name="email"
          type="email"
          label={lang.auth.email}
          required
          fullWidth
          onKeyDown={stripSpacesOnKeyDown}
          onChange={(e) => {
            setEmail(formatEmail(e.target.value));
          }}
          value={email}
        />
        <PrimaryLoadingButton
          type="submit"
          disabled={loading}
          fullWidth
          loading={loading}
          style={{ height: "3.5rem" }}
        >
          {lang.auth.passwordResetButton}
        </PrimaryLoadingButton>
      </form>
      <Box mt="1rem">
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          align="center"
        >
          {`${lang.auth.backTo} `}
          <StyledNoLineLink to={Links.Login}>
            {lang.auth.login}
          </StyledNoLineLink>
          {` ${lang.auth.orSwitchTo} `}
          <StyledNoLineLink to={Links.Signup}>
            {lang.auth.signup}
          </StyledNoLineLink>
        </Typography>
      </Box>
    </Box>
  );
};
