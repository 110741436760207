import { Box } from "@mui/material";

import { useDesign } from "~/hooks/useTheme";

export function NumericCell({
  params,
  formatter,
}: {
  params: any;
  formatter: (value: any) => any;
}) {
  const { tokens } = useDesign();
  return (
    <Box sx={{ fontFamily: tokens.fontFamilies.numeric, fontWeight: "bold" }}>
      {formatter(params)}
    </Box>
  );
}
