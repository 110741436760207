import { BulkEditChips } from "~/components/transactions/command-palette/BulkEditChips";
import { CommandPaletteMenu } from "~/components/transactions/command-palette/CommandPaletteMenu";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { getOptionsFromErpAccounts } from "~/components/transactions/command-palette/helpers/getOptionsFromErpAccounts";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { BulkEditOptionChip } from "~/components/transactions/command-palette/views/BulkEditOptionChip";
import { AssetAccountTopSection } from "~/components/transactions/command-palette/views/change-asset-account/AssetAccountTopSection";
import { Confirmation } from "~/components/transactions/command-palette/views/change-asset-account/Confirmation";
import { useLang } from "~/redux/lang";
import { useErpAvailableAccountsQuery } from "~/state/erp";
import { type CurrencyIdentifier } from "~/types/index";

export const SelectAssetAccount = ({
  fromCurrency,
}: {
  fromCurrency: CurrencyIdentifier;
}) => {
  const { push } = useNavController();
  const lang = useLang();
  const availableAccounts = useErpAvailableAccountsQuery().data ?? [];
  const options = getOptionsFromErpAccounts(availableAccounts);
  return (
    <CommandPaletteMenu
      topArea={
        <BulkEditChips>
          <BulkEditOptionChip type={BulkEditOptions.ChangeAssetAccount} />
          <AssetAccountTopSection fromCurrency={fromCurrency} />
        </BulkEditChips>
      }
      placeholder={lang.txTable.commandPallet.placeholders.changeTo}
      options={options}
      onSelection={({ erpAccount }) => {
        push(
          <Confirmation
            assetCurrency={fromCurrency}
            erpAssetAccount={erpAccount}
          />,
        );
      }}
    />
  );
};
