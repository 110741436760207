import moment from "moment-timezone";
import type * as React from "react";
import { createContext, useEffect, useState } from "react";

import { type Translation } from "~/lang/index";
import { useBestUser } from "~/redux/auth";
import { useGetFinancialYearOptions } from "~/state/fyDates";

export type FinancialYear = {
  year: number;
  start: Date;
  end: Date;
  custom?: boolean;
};

type FYContextType = {
  timeframe?: FinancialYear;
  setTimeframe: (timeframe?: FinancialYear) => void;
  fyDates: FinancialYear[];
  refetch: () => void;
};

export const FYContext = createContext<FYContextType>({
  timeframe: undefined,
  setTimeframe: () => {},
  fyDates: [],
  refetch: () => {},
});

export const displayTimeframe = (
  {
    start,
    end,
  }: {
    start: Date | number;
    end: Date | number;
  },
  lang: Translation,
  fullStr = false,
) => {
  const strFormat = fullStr ? "MMM Do YYYY" : "YYYY";
  const startDate = moment(start).format(strFormat);
  const endDate = moment(end).format(strFormat);

  if (!fullStr && startDate === endDate) return startDate;

  return `${startDate} - ${
    startDate === new Date().getFullYear().toString()
      ? lang.txTable.filter.present
      : endDate
  }`;
};

export const getStartingTimeframe = (
  fyDates: FinancialYear[],
  lastTxDateString?: string,
) => {
  // Determine which tax year report to display based on the lastTxDateString.
  // If the lastTxDateString is within 3 months after the end of the last completed tax year,
  // return the last completed tax year. Otherwise, return based on the lastTxDateString.

  const INDEX_OF_CURRENT_FY = 0;
  const INDEX_OF_LAST_COMPLETED_FY = 1;
  const defaultStartingDate = fyDates[INDEX_OF_CURRENT_FY];

  const endOfTaxTime = moment(defaultStartingDate.start)
    .add(3, "months")
    .toDate();

  const lastTxDate = lastTxDateString ? new Date(lastTxDateString) : undefined;

  // User have no tx data, so show current fy
  if (!lastTxDate) return defaultStartingDate;

  const within3MonthsPostCompletedFY =
    moment(lastTxDate).isSameOrAfter(defaultStartingDate.start) &&
    moment(lastTxDate).isSameOrBefore(endOfTaxTime);

  // User have last tx data, but within 3 months post completed fy, so show completed fy
  if (within3MonthsPostCompletedFY) {
    return fyDates[INDEX_OF_LAST_COMPLETED_FY];
  }

  // After 3 Months Post Completed FY, return timeframe based on lastTxDateString
  const timeframeOfLastTXDate = fyDates.find(
    (fy) =>
      moment(lastTxDate).isSameOrAfter(fy.start) &&
      moment(lastTxDate).isSameOrBefore(fy.end),
  );

  // No matching timeframe found, return default
  if (!timeframeOfLastTXDate) return defaultStartingDate;

  // If the start date of the found timeframe is earlier or the same as the default start date, return it
  if (
    timeframeOfLastTXDate &&
    moment(timeframeOfLastTXDate.start).isSameOrBefore(
      defaultStartingDate.start,
    )
  ) {
    return timeframeOfLastTXDate;
  }

  // Otherwise, return the default
  return defaultStartingDate;
};

export const FYContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const user = useBestUser();
  const [timeframe, setTimeframe] = useState<FinancialYear | undefined>();

  const maybeSetInitialTimeframe = (dates?: FinancialYear[]) => {
    if (user && dates?.length && !timeframe) {
      setTimeframe(getStartingTimeframe(dates, user?.lastTxDate)); // Set to the last completed tax year
    }
  };

  const fyDatesQuery = useGetFinancialYearOptions();

  useEffect(() => {
    maybeSetInitialTimeframe(fyDatesQuery.data);
  }, [user, fyDatesQuery.data?.length]);

  useEffect(() => {
    if (
      user?.country &&
      !fyDatesQuery.data?.length &&
      !fyDatesQuery.isFetching
    ) {
      fyDatesQuery.refetch();
    }
  }, [user?.country, fyDatesQuery.data?.length, fyDatesQuery.isFetching]);

  return (
    <FYContext.Provider
      value={{
        timeframe,
        setTimeframe,
        fyDates: fyDatesQuery.data || [],
        refetch: () => {
          fyDatesQuery.refetch();
          setTimeframe(undefined);
        },
      }}
    >
      {children}
    </FYContext.Provider>
  );
};
