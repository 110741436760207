import { type Option } from "~/components/transactions/command-palette/hooks/useCommandPaletteMenu";
import { type Tokens } from "~/components/ui/theme/tokens";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { type Translation } from "~/lang/index";
import { getActionTypeName } from "~/lib/getActionTypeName";
import { ActionDefinitions, TradeInfo } from "~/lib/tradeTypeDefinitions";
import { isGroupedTrade, isInTrade, isOutTrade } from "~/services/transactions";
import {
  TaxDivision,
  type CategoryBucket,
  type TradeDirection,
} from "~/types/enums";
import { type ActionType, type TaxSettings } from "~/types/index";

export type CategoryBucketOption = Option & {
  categoryBucket: CategoryBucket;
};

export type CategoryOption = Option & {
  trade: ActionType;
  taxDivision: TaxDivision;
  direction?: TradeDirection;
};

export function assertIsCategoryOption(
  option: Option,
): option is CategoryOption {
  return "trade" in option;
}

export function assertIsCategoryBucketOption(
  option: Option,
): option is CategoryBucketOption {
  return "categoryBucket" in option;
}

export function formatCategoryOption({
  trade,
  taxSettings,
  lang,
  tokens,
  showSubActionTypes = false,
  showDisallowed = false,
}: {
  trade: ActionType;
  taxSettings: TaxSettings;
  lang: Translation;
  showDisallowed?: boolean;
  showSubActionTypes?: boolean;
  tokens: Tokens;
}): CategoryOption | undefined {
  const label = getActionTypeName({ actionType: trade, lang });
  const Icon = TradeIcons[trade];
  if (isGroupedTrade(trade)) {
    const { subActionTypes } = ActionDefinitions[trade];
    const inLabel = subActionTypes.find((type) => isInTrade(type));
    const outLabel = subActionTypes.find((type) => isOutTrade(type));
    const subActionTypesLabel = [inLabel, outLabel]
      .filter(Boolean)
      .map((subType) =>
        subType ? getActionTypeName({ actionType: subType, lang }) : undefined,
      )
      .join(" & ");
    return {
      label: showSubActionTypes
        ? subActionTypesLabel
        : `${label} (${subActionTypesLabel})`,
      icon: <Icon />,
      trade,
      taxDivision: TaxDivision.Group,
    };
  }
  const definition = TradeInfo[trade];
  if (
    !showDisallowed &&
    (definition.isOutdated || definition.isHidden || definition.isEditBlocked)
  ) {
    return undefined;
  }

  const taxDivision = definition.taxDivision(taxSettings);

  const description =
    lang.tradeTypeTaxDescriptionExpanded[
      trade as keyof typeof lang.tradeTypeTaxDescriptionExpanded
    ];
  return {
    label,
    icon: <Icon sx={{ color: `${tokens.text.low} !important` }} />,
    trade,
    description,
    taxDivision,
    direction: definition.direction,
    searchTags: definition.searchTags || [],
  };
}
