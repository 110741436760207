import { Box } from "@mui/material";
import { Link } from "react-router-dom";

import { useDesign } from "~/hooks/useTheme";
import { getTextWithLinks } from "~/lib/getTextWithLinks";

type LinkProp = {
  linkTo: string;
  onClickAction?: () => void;
};

type FnProp = {
  fn: (() => void) | null;
};

export type HyperlinkKeyToType = Record<string, LinkProp | FnProp>;

export type HyperlinkTextContentProps = {
  /**
   * The text to be displayed.
   */
  text: string;
  /**
   * The actions to be performed when the link is clicked.
   */
  actions: HyperlinkKeyToType;
  /**
   * Whether to open the link in a new tab.
   * If not provided, the link will open in the same tab.
   */
  openInNew?: boolean;
  /**
   * The color of the link text.
   * If not provided, the default color will be the brand color.
   */
  linkColor?: string;
};

/**
 * Replaces the text between square brackets with a link text style, and
 * on click calls the fn function or a link. If fn is null, then the text is displayed
 * without the square brackets.
 * @param param0 Text and fn props
 * @returns The component to be wrapped within a <Typography>
 *
 * Example: In lang,
 *     "tooltipReplaced": "[Subscribe]({subKey}) to view tips on how to save on your tax return"
 * Calling this component with: text={lang.navbar.tooltipReplaced({ subKey })}
 * and something like actions={[subKey]: { fn: () => console.log("hello") }}
 *
 */
export function HyperlinkTextContent({
  text,
  actions,
  openInNew,
  linkColor,
}: HyperlinkTextContentProps) {
  const { tokens } = useDesign();

  if (
    !text.includes("[") ||
    !text.includes("]") ||
    !text.includes("(") ||
    !text.includes(")")
  ) {
    throw new Error(
      "HyperlinkTextContent: text must contain [ and ] to indicate the hyperlink",
    );
  }

  // Split all occurrences of [*](*)
  const textWithLinks = getTextWithLinks(text);

  return (
    <>
      {textWithLinks.map((segment) => {
        if (!("key" in segment)) {
          return segment.text;
        }

        const { key } = segment;
        const action = actions[key];
        if (!action) {
          throw new Error(
            `HyperlinkTextContent: action for key ${key} not found`,
          );
        }

        if ("linkTo" in action) {
          const { linkTo, onClickAction } = action;
          if (linkTo.startsWith("/") && !openInNew) {
            return (
              <Link
                key={key}
                to={action.linkTo}
                style={{
                  color: linkColor ?? tokens.text.brand,
                  textDecorationColor: linkColor ?? tokens.text.brand,
                }}
                onClick={onClickAction}
              >
                {segment.text}
              </Link>
            );
          }
          // Else it's an external link: open in a new tab
          return (
            <a
              key={key}
              style={{ color: linkColor ?? tokens.text.brand }}
              href={action.linkTo}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClickAction}
            >
              {segment.text}
            </a>
          );
        }

        const { fn } = action;
        if (fn === null) {
          return (
            <Box component="span" color={linkColor ?? tokens.text.brand}>
              {segment.text}
            </Box>
          );
        }

        return (
          <a
            key={key}
            style={{ color: linkColor ?? tokens.text.brand }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              fn();
            }}
          >
            {segment.text}
          </a>
        );
      })}
    </>
  );
}
