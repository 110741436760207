import {
  LocalCurrency,
  Plan,
  ReferrerSource,
  type SupportedLang,
} from "@ctc/types";
import Cookies from "js-cookie";

import {
  BootstrapQueryParameter,
  CookieKey,
  CouponCode,
  LocalStorageKey,
} from "~/constants/enums";
import { type Translation } from "~/lang";
import { displayFiatValue } from "~/lib";
import { invariant } from "~/lib/invariant";
import { isPlan } from "~/lib/isPlan";
import {
  type RetailPlanData,
  type StripeCouponCode,
  type StripeCouponDetails,
  type UserInfo,
} from "~/types";

/**
 * Status of the subscription renewal coupon application process
 */
export enum SubscriptionRenewalStatus {
  ApplyToFutureRenewals = "applyToFutureRenewals",
  DoNotApply = "doNotApply",
}

export type CouponConfig = {
  /** Plans eligible for this coupon, or all plans if not specified */
  getEligiblity?: (params: { plan: Plan }) => boolean;
  /** Features of the coupon */
  descriptions: (params: {
    lang: Translation;
    user: UserInfo;
    localCurrency: LocalCurrency | undefined;
    locale: SupportedLang;
  }) => string[];
  /** Referrer source for this coupon */
  referrerSource?: ReferrerSource;
  /** Convert our internal coupon code to a Stripe coupon code
   * Stripe coupon codes need to be adjusted for the user's plan currency
   * if the coupon is a fixed amount off. For percent off coupons we don't need to do this.
   */
  getStripeCouponCode: (params: {
    user: Pick<UserInfo, "planCurrency">;
  }) => StripeCouponCode;
  /**
   * Whether to apply the coupon to future subscription renewals
   * This is for coupons that are applied to the user's subscription
   * via a url param or a cookie
   */
  subscriptionRenewals?: SubscriptionRenewalStatus;
};

export type CouponDiscount = {
  discountInCents: number;
  couponCode: CouponCode;
  stripeCouponCode: StripeCouponCode;
};

export type BestCouponsResult = {
  couponCodes: CouponCode[];
  stripeCouponCodes: StripeCouponCode[];
  couponsByPlan: Partial<Record<Plan, CouponDiscount>>;
};

export const COUPON_CONFIGS: Record<CouponCode, CouponConfig> = {
  [CouponCode.Coinbase30]: {
    descriptions: ({ lang }) => lang.payment.promoBanner.coinbase,
    referrerSource: ReferrerSource.Coinbase,
    getStripeCouponCode: () => CouponCode.Coinbase30 as StripeCouponCode,
  },
  [CouponCode.MetaMaskCTC30]: {
    descriptions: ({ lang }) => lang.payment.promoBanner.metamask,
    referrerSource: ReferrerSource.MetaMask,
    getStripeCouponCode: () => CouponCode.MetaMaskCTC30 as StripeCouponCode,
  },
  [CouponCode.MetaMaskEmbedded30]: {
    descriptions: ({ lang }) => lang.payment.promoBanner.metamask,
    referrerSource: ReferrerSource.MetamaskEmbedded,
    getStripeCouponCode: () =>
      CouponCode.MetaMaskEmbedded30 as StripeCouponCode,
  },
  [CouponCode.CoinbaseOne40]: {
    descriptions: ({ lang }) => [
      lang.payment.promoBanner.coinbaseone.otherPlans,
    ],
    referrerSource: ReferrerSource.CoinbaseOne,
    getStripeCouponCode: () => CouponCode.CoinbaseOne40 as StripeCouponCode,
  },
  [CouponCode.Coinstats]: {
    descriptions: ({ lang, user, localCurrency, locale }) => {
      const coinstatsDiscount = displayFiatValue({
        value: 30,
        localCurrency: user.planCurrency ?? localCurrency,
        locale,
        fractionDigits: 0,
      });
      return [`${coinstatsDiscount} ${lang.payment.promoBanner.coinstats}`];
    },
    referrerSource: ReferrerSource.Coinstats,
    getStripeCouponCode: ({ user: { planCurrency } }) =>
      `${CouponCode.Coinstats}-${planCurrency}` as StripeCouponCode,
  },
  [CouponCode.Coinjar]: {
    descriptions: ({ lang }) => lang.payment.promoBanner.coinjar,
    referrerSource: ReferrerSource.Coinjar,
    getStripeCouponCode: () => CouponCode.Coinjar as StripeCouponCode,
  },
  [CouponCode.CoinbaseOne1DollarRookie]: {
    getEligiblity: ({ plan }) => plan === Plan.Rookie,
    descriptions: ({ lang, user: { planCurrency }, localCurrency, locale }) => {
      // INR is 99 INR, but everything else is $1,1 pound,1 euro etc
      const value = planCurrency === LocalCurrency.INR ? 99 : 1;
      const price = displayFiatValue({
        value,
        localCurrency: planCurrency ?? localCurrency,
        locale,
        fractionDigits: 0,
      });
      const rookieFeature = lang.payment.promoBanner.coinbaseone.rookie({
        price,
      });
      return [rookieFeature];
    },
    referrerSource: ReferrerSource.CoinbaseOne,
    getStripeCouponCode: ({ user: { planCurrency } }) =>
      `${CouponCode.CoinbaseOne1DollarRookie}${planCurrency}` as StripeCouponCode,
    subscriptionRenewals: SubscriptionRenewalStatus.ApplyToFutureRenewals,
  },
  [CouponCode.CoinbaseOne40PercentOff]: {
    getEligiblity: ({ plan }) => plan !== Plan.Rookie,
    descriptions: ({ lang }) => {
      const otherPlansFeature = lang.payment.promoBanner.coinbaseone.otherPlans;
      return [otherPlansFeature];
    },
    referrerSource: ReferrerSource.CoinbaseOne,
    getStripeCouponCode: () =>
      CouponCode.CoinbaseOne40PercentOff as StripeCouponCode,
    subscriptionRenewals: SubscriptionRenewalStatus.ApplyToFutureRenewals,
  },
  [CouponCode.CoinbaseOnePremiumTrader50]: {
    getEligiblity: ({ plan }) => plan === Plan.Trader,
    descriptions: ({ lang }) => [
      lang.payment.promoBanner.coinbaseonepremium[0],
    ],
    referrerSource: ReferrerSource.CoinbaseOnePremium,
    getStripeCouponCode: () =>
      CouponCode.CoinbaseOnePremiumTrader50 as StripeCouponCode,
    subscriptionRenewals: SubscriptionRenewalStatus.ApplyToFutureRenewals,
  },
  [CouponCode.CoinbaseOnePremium40PercentOff]: {
    getEligiblity: ({ plan }) => plan !== Plan.Trader,
    descriptions: ({ lang }) => [
      lang.payment.promoBanner.coinbaseonepremium[1],
    ],
    referrerSource: ReferrerSource.CoinbaseOnePremium,
    getStripeCouponCode: () =>
      CouponCode.CoinbaseOnePremium40PercentOff as StripeCouponCode,
    subscriptionRenewals: SubscriptionRenewalStatus.ApplyToFutureRenewals,
  },
  [CouponCode.IndependentReserve30]: {
    descriptions: ({ lang }) => lang.payment.promoBanner.independentReserve,
    referrerSource: ReferrerSource.IndependentReserve,
    getStripeCouponCode: () =>
      CouponCode.IndependentReserve30 as StripeCouponCode,
  },
  [CouponCode.BitcoinDotComAu30]: {
    descriptions: ({ lang }) => lang.payment.promoBanner.bitcoinDotComAu,
    referrerSource: ReferrerSource.BitcoinDotComAu,
    getStripeCouponCode: () => CouponCode.BitcoinDotComAu30 as StripeCouponCode,
  },
  [CouponCode.ReferAFriend20]: {
    descriptions: ({ lang }) => [lang.payment.promoBanner.referAFriend[0]],
    referrerSource: ReferrerSource.ReferAFriend,
    getStripeCouponCode: () => CouponCode.ReferAFriend20 as StripeCouponCode,
  },
  /** Phantom 20% off Coupon */
  [CouponCode.Phantom20]: {
    descriptions: ({ lang }) => lang.payment.promoBanner.phantom20,
    referrerSource: ReferrerSource.Phantom,
    getStripeCouponCode: () => CouponCode.Phantom20 as StripeCouponCode,
  },
};

/**
 * These are coupons that are no longer valid.
 * If they appear in a users local storage we ignore them.
 */
const deprecatedCoupons = new Set([CouponCode.CoinbaseOne40, "METAMASK10"]);

/**
 * Service for managing coupon codes across the application
 */
export class CouponService {
  /**
   * Returns the stripe coupon code for a given coupon code
   * @param couponCode - The coupon code to get the stripe coupon code for
   * @param user - The user to get the stripe coupon code for
   * @returns The stripe coupon code
   */
  private static getStripeCouponCode({
    couponCode,
    user,
  }: {
    couponCode: CouponCode;
    user: Pick<UserInfo, "planCurrency"> | null | undefined;
  }) {
    const couponConfig = COUPON_CONFIGS[couponCode];
    if (!couponConfig) {
      // This is a coupon code that we don't have a config for
      // Might still be a valid coupon, we just don't have banners to display
      return couponCode as StripeCouponCode;
    }
    return couponConfig.getStripeCouponCode({
      user: { planCurrency: user?.planCurrency },
    });
  }
  /**
   * Checks if a Stripe coupon is expired based on its redeem_by timestamp
   *
   * @param {StripeCouponDetails} coupon - The Stripe coupon details to check
   * @returns {boolean} True if the coupon is expired, false otherwise
   */
  public static getIsStripeCouponExpired(coupon: StripeCouponDetails): boolean {
    if (coupon.redeem_by === null) {
      return false;
    }
    return Date.now() >= coupon.redeem_by * 1000;
  }

  /**
   * Returns an array of features that are enabled for a given coupon code
   * @param couponCode - The coupon code to get features for
   * @returns Array of feature strings enabled for this coupon
   */
  public static getCouponFeatures({
    couponCode,
    lang,
    user,
    localCurrency,
    locale,
  }: {
    couponCode: CouponCode | StripeCouponCode;
    lang: Translation;
    user: UserInfo;
    localCurrency: LocalCurrency | undefined;
    locale: SupportedLang;
  }): string[] {
    const sanitisedCouponCode = this.toCouponCode(couponCode);
    return COUPON_CONFIGS[sanitisedCouponCode].descriptions({
      lang,
      user,
      localCurrency,
      locale,
    });
  }

  /**
   * Splits a comma-separated string of coupons into an array
   * @param coupons - Comma-separated string of coupons
   * @returns Array of coupon strings, or empty array if input is null/undefined
   */
  private static splitCoupons(coupons: string | null | undefined): string[] {
    return coupons?.split(",") ?? [];
  }

  /**
   * Checks if a coupon is deprecated
   * @param coupon - The coupon code to check
   * @returns True if the coupon is deprecated, false otherwise
   */
  private static isCouponDeprecated(coupon: string): boolean {
    return deprecatedCoupons.has(coupon);
  }

  /**
   * Stores the manual coupon from URL parameters or cookies
   * Manual coupons are coupons directly placed into the URL via a query param
   * These are different than referrer coupons, which are coupons that are applied
   * based on the user's referrer source and plan.
   * @param urlParams - URL search params object
   */
  public static storeManualCoupon(urlParams: URLSearchParams): void {
    const cookieCoupons = Cookies.get(CookieKey.Coupon);
    Cookies.remove(CookieKey.Coupon);
    const urlParamCoupons = urlParams.get(BootstrapQueryParameter.Coupon);
    if (urlParamCoupons) {
      urlParams.delete(BootstrapQueryParameter.Coupon);
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    }
    if (!cookieCoupons && !urlParamCoupons) {
      return;
    }

    const couponsToAdd = [
      ...this.splitCoupons(cookieCoupons),
      ...this.splitCoupons(urlParamCoupons),
    ];

    // Add each coupon individually using the addCoupon method
    couponsToAdd.forEach(coupon => {
      this.addCoupon(coupon as CouponCode);
    });
  }

  /**
   * Gets the manual coupon code from local storage
   * @returns The stored coupon code if valid, undefined otherwise
   */
  public static getManualStoredCoupons(): CouponCode[] {
    const storedCoupons = localStorage.getItem(LocalStorageKey.StripeCoupon);
    if (!storedCoupons) {
      return [];
    }
    return this.splitCoupons(storedCoupons)
      .map((coupon) => this.toCouponCode(coupon))
      .filter((coupon) => !this.isCouponDeprecated(coupon));
  }

  /**
   * Returns the coupon config for a given coupon code
   * @param couponCode - The coupon code to get the config for
   * @returns The coupon config if found, undefined otherwise
   */
  public static getCouponConfig(
    couponCode: CouponCode,
  ): CouponConfig | undefined {
    return COUPON_CONFIGS[couponCode];
  }

  /**
   * Returns the stripe coupon codes for a given user
   * Stripe coupons are different than our internal coupon codes
   * We need to convert our internal coupon codes to stripe coupon codes
   * Stripe coupon codes are different for each plan currency for fixed amount off coupons
   * @param user - The user to get the stripe coupon codes for
   * @returns The stripe coupon codes if found, [] otherwise
   */
  public static getUserStripeCouponCodes(
    user: Pick<UserInfo, "referrerSource" | "planCurrency"> | null | undefined,
  ) {
    const userCouponCodes = this.getUserCouponCodes(user);

    // Map each coupon code to its corresponding Stripe coupon code
    const stripeCouponCodes = userCouponCodes.map((couponCode) => {
      return this.getStripeCouponCode({
        couponCode,
        user,
      });
    });

    // Remove duplicates by converting to Set and back to Array
    return Array.from(new Set(stripeCouponCodes));
  }

  /**
   * Returns the coupon codes for a given user
   * @param user - The user to get the coupon codes for
   * @returns The coupon codes if found, [] otherwise
   */
  public static getUserCouponCodes(
    user: Pick<UserInfo, "referrerSource"> | null | undefined,
  ) {
    const couponCodes = new Set<CouponCode>();
    // Check local storage first
    const storedCoupons = CouponService.getManualStoredCoupons();
    storedCoupons.forEach((coupon) => couponCodes.add(coupon));

    // Check if the referrer source has a coupon code for the given plan
    if (user) {
      const { referrerSource } = user;
      const referrerCoupons = Object.entries(COUPON_CONFIGS)
        .filter(
          ([_, couponConfig]) => couponConfig.referrerSource === referrerSource,
        )
        .map(([couponCode]) => couponCode as CouponCode);
      if (referrerCoupons) {
        referrerCoupons.forEach((coupon) => couponCodes.add(coupon));
      }
    }
    return Array.from(couponCodes);
  }

  public static getUserFutureStripeCouponCodes(
    user: Pick<UserInfo, "planCurrency" | "referrerSource"> | null | undefined,
  ) {
    const userCouponCodes = this.getUserCouponCodes(user);
    // filter those to future coupons
    const futureCouponCodes = userCouponCodes.flatMap((couponCode) => {
      const couponConfig = COUPON_CONFIGS[couponCode];
      if (!couponConfig) {
        return [];
      }
      if (
        couponConfig.subscriptionRenewals !==
        SubscriptionRenewalStatus.ApplyToFutureRenewals
      ) {
        return [];
      }
      return [couponCode];
    });
    return futureCouponCodes.map((couponCode) => {
      return this.getStripeCouponCode({
        couponCode,
        user,
      });
    });
  }

  /**
   * Clears any stored coupon codes
   */
  public static clearManualCoupons(): void {
    localStorage.removeItem(LocalStorageKey.StripeCoupon);
    Cookies.remove("coupon");
  }

  /**
   * Adds a specific coupon to the list of stored coupons
   */
  public static addCoupon(couponCode: CouponCode): boolean {
    const sanitizedCoupon = this.toCouponCode(couponCode);
    if (this.isCouponDeprecated(sanitizedCoupon)) return false;
    
    const existingCoupons = this.getManualStoredCoupons();
    if (existingCoupons.includes(sanitizedCoupon)) return false;
    
    const updatedCoupons = [...existingCoupons, sanitizedCoupon];
    localStorage.setItem(LocalStorageKey.StripeCoupon, updatedCoupons.join(","));
    return true;
  }

  /**
   * Calculates the discount amount for a given coupon and price
   * @param couponDetails - The Stripe coupon details
   * @param price - The original price to apply the discount to
   * @returns The discount amount in cents
   */
  private static calculateDiscountAmount(
    couponDetails: StripeCouponDetails,
    price: number,
  ): number {
    if (couponDetails.percent_off) {
      return (couponDetails.percent_off / 100) * price;
    }
    if (couponDetails.amount_off) {
      return couponDetails.amount_off;
    }
    return 0;
  }

  /**
   * Converts a stripe coupon code to a coupon code
   * @param couponCodeText - The coupon code to convert
   * @returns The coupon code
   */
  private static toCouponCode(
    couponCodeText: StripeCouponCode | CouponCode | string,
  ): CouponCode {
    if (couponCodeText.startsWith(CouponCode.CoinbaseOne1DollarRookie)) {
      return CouponCode.CoinbaseOne1DollarRookie;
    }
    if (couponCodeText.startsWith(CouponCode.Coinstats)) {
      return CouponCode.Coinstats;
    }
    // Might not be a valid coupon code, but we will try and ask stripe later
    // So we just return what we have
    return couponCodeText as CouponCode;
  }

  /**
   * Calculates the best coupon for a specific plan
   *
   * @param plan - The plan to calculate the best coupon for
   * @param price - The price of the plan in cents
   * @param stripeCouponsDetails - Details of each coupon from stripe
   * @returns The best coupon discount for the plan, or undefined if no eligible coupons
   */
  public static calculateBestCouponForPlan(
    plan: Plan,
    price: number,
    stripeCouponsDetails: StripeCouponDetails[],
  ): CouponDiscount | undefined {
    let maxDiscount = 0;
    let bestCouponCode: CouponCode | undefined;
    let bestStripeCouponCode: StripeCouponCode | undefined;

    for (const couponDetails of stripeCouponsDetails) {
      if (!couponDetails) continue;

      const couponCode = this.toCouponCode(couponDetails.id);
      const couponConfig = COUPON_CONFIGS[couponCode];
      const isEligible = couponConfig?.getEligiblity?.({ plan }) ?? true;
      if (!isEligible) continue;

      const currentDiscount = this.calculateDiscountAmount(
        couponDetails,
        price,
      );
      if (currentDiscount > maxDiscount) {
        maxDiscount = currentDiscount;
        bestCouponCode = couponCode;
        bestStripeCouponCode = couponDetails.id;
      }
    }

    if (!bestCouponCode || !bestStripeCouponCode) {
      return undefined;
    }

    return {
      discountInCents: maxDiscount,
      couponCode: bestCouponCode,
      stripeCouponCode: bestStripeCouponCode,
    };
  }

  /**
   * A user can have many coupons in the system. But we need to work out the ones
   * that give them the maximum discount to their plans. This returns
   * the best coupon for each plan. This means at max we have 4 active coupons, 1 for
   * each plan.
   *
   * @param plans - Record of plan data containing prices
   * @param stripeCouponsDetails - Details of each coupon from stripe
   * @returns Object containing best coupons and their discounts by plan
   */
  public static calculateBestCoupons(
    plans: Partial<Record<Plan, Pick<RetailPlanData, "amount">>>,
    stripeCouponsDetails: StripeCouponDetails[],
  ): BestCouponsResult {
    const bestCoupons: Partial<Record<Plan, CouponDiscount>> = {};

    for (const [plan, planData] of Object.entries(plans)) {
      invariant(isPlan(plan), `Plan ${plan} not found in plans`);
      const price = planData.amount;

      const bestCoupon = this.calculateBestCouponForPlan(
        plan as Plan,
        price,
        stripeCouponsDetails,
      );

      if (bestCoupon) {
        bestCoupons[plan as Plan] = bestCoupon;
      }
    }

    return {
      couponCodes: Array.from(
        new Set(Object.values(bestCoupons).map(({ couponCode }) => couponCode)),
      ),
      stripeCouponCodes: Array.from(
        new Set(
          Object.values(bestCoupons).map(
            ({ stripeCouponCode }) => stripeCouponCode,
          ),
        ),
      ),
      couponsByPlan: bestCoupons,
    };
  }
}
