import { create } from "zustand";

import { BreakdownTab } from "~/components/transactions/enums";

type BreakdownStatus = {
  isOpen: boolean;
  tab: BreakdownTab;
};

type BreakdownState = {
  breakdowns: Record<string, BreakdownStatus>;
  setBreakdownStatus: (actionId: string, payload: BreakdownStatus) => void;
  toggleBreakdown: (actionId: string, tab: BreakdownTab) => void;
  breakdownDefaultTab: BreakdownTab;
  setBreakdownDefaultTab: (tab: BreakdownTab) => void;
  clearAllBreakdownsState: () => void;
};

/**
 * Stores the breakdown open/closed status + which tab is visible for
 * each of the transaction rows
 *
 * Stored in here instead of in the rows themselves because of the virtualisation.
 * If we store it in the rows, as the rows are recycled, the state is lost.
 * Meaning if you open a breakdown, scroll down, then scroll back up, the breakdown
 * will end up closed (since thats the default state when new).
 */
export const useBreakdownsStore = create<BreakdownState>()((set) => ({
  breakdownDefaultTab: BreakdownTab.Transactions,
  setBreakdownDefaultTab: (tab: BreakdownTab) => {
    set(() => ({
      breakdownDefaultTab: tab,
    }));
  },
  breakdowns: {},
  toggleBreakdown: (actionId, tab) => {
    set((state) => ({
      breakdowns: {
        ...state.breakdowns,
        [actionId]: {
          ...state.breakdowns[actionId],
          tab,
          isOpen: !state.breakdowns[actionId]?.isOpen,
        },
      },
    }));
  },
  setBreakdownStatus: (actionId, payload) => {
    set((state) => ({
      breakdowns: {
        ...state.breakdowns,
        [actionId]: payload,
      },
    }));
  },
  clearAllBreakdownsState: () => {
    set(() => ({
      breakdowns: {},
      breakdownDefaultTab: BreakdownTab.Transactions,
    }));
  },
}));
