import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  type DialogProps,
  DialogTitle,
  type PaperProps,
  type SxProps,
  type Theme,
  Typography,
  type TypographyTypeMap,
} from "@mui/material";
import { type TransitionProps } from "@mui/material/transitions";
import { createStyles, makeStyles } from "@mui/styles";
import type * as React from "react";
import { Transition } from "react-transition-group";

import { useIsMobile } from "~/components/ui/hooks";
import { StyledDialogActions } from "~/components/ui/StyledDialogActions";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { SecondaryButton } from "~/components/ui/ui-buttons/SecondaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { WarningButton } from "~/components/ui/ui-buttons/WarningButton";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export type GeneralDialogProps = {
  actionText?: string;
  cancelText?: string;
  isOpen: boolean;
  title: string | React.ReactNode;
  titleBackgroundColor?: string;
  titleVariant?: TypographyTypeMap["props"]["variant"];
  dialogPaperStyle?: Partial<PaperProps>;
  titleStyle?: SxProps<Theme>;
  titleEndComponent?: React.ReactNode;
  contentStyle?: SxProps<Theme>;
  icons?: React.ReactNode;
  maxWidth?: DialogProps["maxWidth"];
  fullWidth?: boolean;
  pending?: boolean;
  critical?: boolean;
  secondary?: boolean;
  disableAction?: boolean;
  showCancel?: boolean;
  dividers?: boolean;
  closeOnClickAway?: boolean;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
  disableClose?: boolean;
  handleAction?: (event: React.MouseEvent<HTMLElement>) => void;
  actionButtonEndIcon?: React.ReactNode;
  hideActions?: boolean;
  leftButton?: React.ReactNode;
  stopPropagation?: boolean;
  headerComponent?: React.ReactNode;
  autoFullScreen?: boolean;
  disableFade?: boolean;
  actionProps?: any;
  onCancel?: () => void; // Executes on cancel in addition to handleClose
  uncensored?: boolean;
  centerCloseButton?: boolean;
  dialogRootStyles?: SxProps<Theme>;
  boxContainerStyles?: SxProps<Theme>;
};

export const useCloseButtonStyles = makeStyles(
  ({ breakpoints, spacing, palette }: Theme) => {
    const mobileBreakpoint = breakpoints.down("xs");
    const base = {
      color: palette.grey[500],
      position: "absolute",
      right: spacing(2),
      [mobileBreakpoint]: {
        right: spacing(0.5),
        padding: 4,
      },
    } as const;
    return createStyles({
      closeButton: {
        ...base,
        top: spacing(2),
        [mobileBreakpoint]: {
          ...(base[mobileBreakpoint] as {
            right: string;
            padding: number;
          }),
          top: spacing(0.5),
        },
      },
      closeButtonCentred: base,
    });
  },
);

const InstantTransition = ({ children, ...rest }: TransitionProps) => {
  return (
    <Transition timeout={0} enter={false} exit={false} {...rest}>
      {children}
    </Transition>
  );
};

export function GeneralDialogForm({
  handleClose,
  disableClose = false,
  isOpen,
  title,
  titleBackgroundColor,
  titleVariant,
  handleAction,
  actionText,
  cancelText,
  maxWidth,
  icons,
  fullWidth = false,
  pending = false,
  critical = false,
  secondary = false,
  disableAction = false,
  showCancel = true,
  dividers = true,
  closeOnClickAway = false,
  children,
  hideActions = false,
  leftButton = null,
  stopPropagation = false,
  actionButtonEndIcon,
  onCancel,
}: GeneralDialogProps & {
  handleAction?: (event: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const classes = useCloseButtonStyles();

  const isMobile = useIsMobile();

  // if we have a 100% wide modal, on mobile switch it to full screen
  const sizeProps =
    isMobile && fullWidth && maxWidth === "xl"
      ? {
          fullScreen: true,
        }
      : {
          fullWidth,
          maxWidth,
        };

  const onClose = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    reason?: "backdropClick" | "escapeKeyDown",
  ) => {
    if (stopPropagation) e.stopPropagation();
    if (!closeOnClickAway && reason === "backdropClick") return false;
    handleClose(e);
  };

  const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} {...sizeProps}>
      <form onSubmit={handleAction}>
        <Box onClick={onClick}>
          <DialogTitle sx={{ background: titleBackgroundColor }}>
            <Box display="flex">
              {icons && (
                <Box pr="0.5rem" textAlign="center" height="2.5rem">
                  {icons}
                </Box>
              )}
              <Typography
                variant={titleVariant || "Metropolis/Header/H4"}
                sx={{ color: tokens.text.high }}
              >
                {title}
              </Typography>
              {!disableClose && (
                <TextIconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                  size="medium"
                >
                  <CloseIcon />
                </TextIconButton>
              )}
            </Box>
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
          {!hideActions ? (
            <StyledDialogActions
              dividers={dividers}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{leftButton}</div>
              <Box sx={{ display: "flex", gap: "0.75rem" }}>
                {showCancel && (
                  <TertiaryButton
                    onClick={(e) => {
                      if (onCancel) {
                        onCancel();
                      }
                      handleClose(e);
                    }}
                    variant="outlined"
                    color="secondary"
                    sx={{ height: "2.45rem" }}
                  >
                    {cancelText || lang.cancel}
                  </TertiaryButton>
                )}
                {!!actionText && !!handleAction ? (
                  critical ? (
                    <WarningButton
                      onClick={handleAction}
                      sx={{ height: "2.4rem" }}
                      disabled={pending || disableAction}
                      startIcon={
                        pending ? <CircularProgress size="1.125rem" /> : null
                      }
                    >
                      {actionText}
                    </WarningButton>
                  ) : secondary ? (
                    <SecondaryButton
                      onClick={handleAction}
                      disabled={pending || disableAction}
                      startIcon={
                        pending ? <CircularProgress size="1.125rem" /> : null
                      }
                      endIcon={actionButtonEndIcon}
                    >
                      {pending ? lang.pending : actionText}
                    </SecondaryButton>
                  ) : (
                    <PrimaryButton
                      type="submit"
                      disabled={pending || disableAction}
                      startIcon={
                        pending ? <CircularProgress size="1.125rem" /> : null
                      }
                      endIcon={actionButtonEndIcon}
                    >
                      {pending ? lang.pending : actionText}
                    </PrimaryButton>
                  )
                ) : null}
              </Box>
            </StyledDialogActions>
          ) : null}
        </Box>
      </form>
    </Dialog>
  );
}

export function GeneralDialog({
  children,
  ...props
}: GeneralDialogProps & { children: React.ReactNode }) {
  return (
    <GeneralDialogModal {...props}>
      <GeneralDialogTitle {...props} />
      <GeneralDialogContent {...props}>{children}</GeneralDialogContent>
      <GeneralDialogActions {...props} />
    </GeneralDialogModal>
  );
}

export function GeneralDialogModal({
  children,
  ...props
}: GeneralDialogProps & { children: React.ReactNode }) {
  const {
    handleClose,
    isOpen,
    dialogPaperStyle,
    maxWidth,
    fullWidth = false,
    closeOnClickAway = false,
    stopPropagation = false,
    autoFullScreen,
    disableFade,
    dialogRootStyles = {},
    boxContainerStyles = {},
  } = props;

  const { tokens } = useDesign();
  const lang = useLang();

  const isMobile = useIsMobile();
  const isFullScreen =
    (isMobile && autoFullScreen) ||
    (isMobile && fullWidth && maxWidth === "xl"); // maxWidth is never set to xl.
  // if we have a 100% wide modal, on mobile switch it to full screen
  const dialogSizeProps = isFullScreen
    ? {
        fullScreen: true,
      }
    : {
        fullWidth,
        maxWidth,
      };

  const paperProps = {
    ...dialogPaperStyle,
    sx: {
      ...dialogPaperStyle?.sx,
      ...(isFullScreen
        ? {
            height: "100%",
            backgroundColor: `${tokens.elevation.low} !important`,
            boxShadow: "inherit",
            backgroundImage: "none",
            "& > .MuiBox-root": { height: "100%", width: "100%" },
          }
        : {}),
    },
  };

  const onClose = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    reason?: "backdropClick" | "escapeKeyDown",
  ) => {
    if (!closeOnClickAway && reason === "backdropClick") return false;
    handleClose(e);
    if (stopPropagation) e.stopPropagation();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      {...dialogSizeProps}
      PaperProps={paperProps}
      {...(disableFade
        ? { TransitionComponent: InstantTransition, transitionDuration: 0 }
        : {})}
      sx={dialogRootStyles}
    >
      <Box
        onClick={(e) => {
          if (stopPropagation) e.stopPropagation();
        }}
        sx={{
          ...boxContainerStyles,
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
}

export function GeneralDialogTitle(props: GeneralDialogProps) {
  const {
    handleClose,
    disableClose = false,
    title,
    titleVariant,
    titleBackgroundColor,
    titleEndComponent,
    titleStyle,
    icons,
    headerComponent,
    centerCloseButton = false,
    uncensored = false,
  } = props;

  const { tokens } = useDesign();
  const lang = useLang();
  const classes = useCloseButtonStyles();

  return (
    <DialogTitle
      sx={{
        p: {
          xs: "1rem",
          md: "1.5rem",
        },
        position: "sticky",
        top: 0,
        zIndex: 1000,
        background: titleBackgroundColor,
        ...titleStyle,
      }}
    >
      <Box display="flex" alignItems="center">
        {icons && (
          <Box
            display="flex"
            pr="0.5rem"
            textAlign="center"
            height="2.5rem"
            alignItems="center"
          >
            {icons}
          </Box>
        )}
        <Typography
          variant={titleVariant || "Metropolis/Header/H4"}
          sx={{ color: tokens.text.high }}
          data-uncensored={uncensored && "true"}
        >
          {title}
        </Typography>
        {titleEndComponent}
        {!disableClose && (
          <TextIconButton
            aria-label="close"
            data-ctc="general-dialogue-close"
            className={
              centerCloseButton
                ? classes.closeButtonCentred
                : classes.closeButton
            }
            onClick={handleClose}
            size="medium"
          >
            <CloseIcon />
          </TextIconButton>
        )}
      </Box>
      {headerComponent}
    </DialogTitle>
  );
}

export function GeneralDialogContent({
  children,
  ...props
}: GeneralDialogProps & { children: React.ReactNode }) {
  const { contentStyle } = props;

  return <DialogContent sx={{ ...contentStyle }}>{children}</DialogContent>;
}

export function GeneralDialogActions(props: GeneralDialogProps) {
  const {
    handleClose,
    handleAction,
    actionText,
    cancelText,
    pending = false,
    critical = false,
    secondary = false,
    disableAction = false,
    showCancel = true,
    dividers = true,
    hideActions = false,
    leftButton = null,
    actionButtonEndIcon,
    actionProps,
    onCancel,
    uncensored = false,
  } = props;

  const lang = useLang();

  if (hideActions) return null;

  return (
    <StyledDialogActions
      dividers={dividers}
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <div>{leftButton}</div>
      <Box sx={{ display: "flex", gap: "0.75rem" }}>
        {showCancel && (
          <TertiaryButton
            onClick={(e) => {
              if (onCancel) {
                onCancel();
              }
              handleClose(e);
            }}
            variant="outlined"
            color="secondary"
            sx={{ height: "2.45rem" }}
            data-uncensored={uncensored && "true"}
          >
            {cancelText || lang.cancel}
          </TertiaryButton>
        )}
        {!!actionText && !!handleAction ? (
          critical ? (
            <WarningButton
              onClick={handleAction}
              sx={{ height: "2.4rem" }}
              disabled={pending || disableAction}
              startIcon={pending ? <CircularProgress size="1.125rem" /> : null}
              data-uncensored={uncensored && "true"}
            >
              {actionText}
            </WarningButton>
          ) : secondary ? (
            <SecondaryButton
              onClick={handleAction}
              disabled={pending || disableAction}
              startIcon={pending ? <CircularProgress size="1.125rem" /> : null}
              endIcon={actionButtonEndIcon}
              data-uncensored={uncensored && "true"}
            >
              {pending ? lang.pending : actionText}
            </SecondaryButton>
          ) : (
            <PrimaryButton
              onClick={handleAction}
              disabled={pending || disableAction}
              startIcon={pending ? <CircularProgress size="1.125rem" /> : null}
              endIcon={actionButtonEndIcon}
              {...(actionProps ?? {})}
              data-uncensored={uncensored && "true"}
            >
              {pending ? lang.pending : actionText}
            </PrimaryButton>
          )
        ) : null}
      </Box>
    </StyledDialogActions>
  );
}
