import { Box, Popover } from "@mui/material";
import { bindPopover } from "material-ui-popup-state";
import { type RefObject, useCallback } from "react";

import { useCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import { type ModalContextType } from "~/components/transactions/command-palette/ModalProvider";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { useDesign } from "~/hooks/useTheme";

// Contains some of the default code for the small command palette modal
export function SmallCommandPaletteModal({
  anchor,
  modal,
}: {
  modal: ModalContextType;
  anchor: RefObject<HTMLDivElement>;
}) {
  const { close } = useCommandPalette();
  const { tokens } = useDesign();
  const { current, views, pop } = useNavController();

  const handleClose = useCallback(
    (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
      // if you are loading, you cant close it
      if (modal.loading) {
        return;
      }
      // at the root page, so escape gets you out
      if (reason === "backdropClick" || views.length === 1) {
        close();
        return;
      }
      // not at the root page, so go back 1 view
      pop();
    },
    [close, modal.loading, pop, views.length],
  );

  return (
    <Popover
      {...bindPopover(modal)}
      anchorEl={anchor.current}
      PaperProps={{
        sx: {
          maxWidth: "22.5rem",
          width: "100%",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: -8,
        horizontal: "left",
      }}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      onClose={handleClose}
    >
      <Box
        sx={{
          backgroundColor: tokens.elevation.medium,
        }}
      >
        {current}
      </Box>
    </Popover>
  );
}
