import { Country, ReferrerSource } from "@ctc/types";
import { useQueries, useQuery } from "@tanstack/react-query";
import keyBy from "lodash/keyBy";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { isFeatureEnabled } from "~/analytics/posthog";
import { walletConnectorConfig } from "~/components/imports/wallet/config";
import { queryClient } from "~/components/queryClient";
import {
  popularExchangesAU,
  popularExchangesCA,
  popularExchangesGlobal,
  popularExchangesUK,
  popularExchangesUS,
} from "~/constants/constants";
import { geIdAndBlockchain } from "~/lib/index";
import { useUser } from "~/redux/auth";
import { setFilteredImportOptions } from "~/redux/imports";
import { HttpError } from "~/services/core";
import { getEntities } from "~/services/entities";
import * as importsV2 from "~/services/imports";
import { entityKeys, useEntityLookupAsync } from "~/state/entities";
import {
  allSavedImportsQueryFn,
  savedImports,
  useGetSavedAccounts,
} from "~/state/importsV2";
import {
  FeatureFlag,
  ImportMethod,
  ImportViewMode,
  IntegrationCategory,
} from "~/types/enums";
import {
  type ConnectWalletImportMethod,
  type ImportOptionV2,
  type SavedImportOptionByAccount,
} from "~/types/index";

export const importsKeys = {
  all: () => ["availableImportsAll"] as const,
};

export const preFetchAllImportOptions = () => {
  queryClient.prefetchQuery({
    queryKey: importsKeys.all(),
    queryFn: fetchAllImportOptions,
  });
};

export const useLoadingAllImportOptions = () => {
  const isLoading =
    queryClient.getQueryState(importsKeys.all())?.status === "pending";
  return isLoading;
};

export const useLoadingSavedImports = () => {
  return useQueries({
    queries: [
      ...(Object.values(ImportViewMode) as ImportViewMode[]).map(
        (viewMode) => ({
          queryKey: savedImports.list(viewMode),
          queryFn: () => allSavedImportsQueryFn(viewMode),
        }),
      ),
      {
        queryKey: entityKeys.lists(),
        queryFn: () => getEntities(),
      },
    ],
    combine: (result) => result.some((q) => q.status === "pending"),
  });
};

const filterAllImportOptionsList = (
  savedAccounts: SavedImportOptionByAccount[],
  allImportsList: ImportOptionV2[],
) => {
  const savedImportKeys = keyBy(
    savedAccounts,
    (i: SavedImportOptionByAccount) => i.id,
  );

  return allImportsList.filter((i: ImportOptionV2) => {
    // Only show deprecated if it's already imported.
    if (i.isDeprecated) {
      const show = !!savedImportKeys[i.id];
      return show;
    }

    return true;
  });
};

const updateImportMethods = (allImportsList: ImportOptionV2[]) => {
  allImportsList.forEach((importOption) => {
    // Remove OAuth from the importMethods if disabled and if it is okex
    if (importOption.id === "okex" && !isFeatureEnabled(FeatureFlag.OkxOAuth)) {
      importOption.importMethods = importOption.importMethods.filter(
        (method) => method.type !== ImportMethod.OAuth,
      );
    }
  });

  return allImportsList;
};

export const useGetAllImportOptions = () => {
  const dispatch = useDispatch();
  const savedAccounts = useGetSavedAccounts();

  const query = useQuery({
    queryKey: importsKeys.all(),
    queryFn: fetchAllImportOptions,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (query.data) {
      const filtered = filterAllImportOptionsList(
        savedAccounts ?? [],
        query.data.list,
      );

      const updated = updateImportMethods(filtered);

      dispatch(setFilteredImportOptions(updated));
    }
  }, [query, dispatch, savedAccounts]);

  if (query.data) {
    const filtered = filterAllImportOptionsList(
      savedAccounts ?? [],
      query.data.list,
    );

    return {
      ...query,
      data: {
        ...query.data,
        list: updateImportMethods(filtered),
      },
    };
  }

  return query;
};

const fetchAllImportOptions = async () => {
  const res = await importsV2.getAvailableImports();

  if (res.error) {
    throw new HttpError(res, ["getAllImportOptions"]);
  }

  const importListWithWalletConnect = res.data.map(
    (importOption): ImportOptionV2 => {
      const importMethods = importOption.importMethods;

      const walletImportMethod = importMethods.find(
        (method) => method.type === ImportMethod.Wallet,
      );

      const isImportOptionWallet =
        importOption.category === IntegrationCategory.Wallet &&
        walletImportMethod;
      const walletConnectOrConfig = walletConnectorConfig[importOption.id];

      if (isImportOptionWallet && !!walletConnectOrConfig) {
        return {
          ...importOption,
          importMethods: [
            ...importMethods,
            {
              ...walletImportMethod,
              type: ImportMethod.ConnectWallet,
              walletConnectOrConfig,
            } as ConnectWalletImportMethod,
          ],
        };
      }

      return {
        ...importOption,
        importMethods,
      };
    },
  );

  const lookup = keyBy(importListWithWalletConnect, (i) => i.id);
  return {
    list: importListWithWalletConnect,
    lookup,
  };
};

const getPopularImportOptions = (country: Country | undefined) => {
  switch (country) {
    case Country.USA:
      return popularExchangesUS;
    case Country.Australia:
      return popularExchangesAU;
    case Country.UK:
      return popularExchangesUK;
    case Country.Canada:
      return popularExchangesCA;
    default:
      return popularExchangesGlobal;
  }
};

export const usePopularImportOptions = () => {
  const imports = useGetAllImportOptions().data?.list;
  const optionMap = new Map(imports?.map((op) => [op.id.toLowerCase(), op]));
  const user = useUser();
  const isCoinbaseUser = user?.referrerSource === ReferrerSource.Coinbase;
  const popularOptionList = getPopularImportOptions(user?.country);

  if (isCoinbaseUser) {
    const coinbaseIndex = popularOptionList.indexOf("coinbase");
    const coinbaseWalletIndex = popularOptionList.indexOf("coinbase-wallet");

    // If "coinbase" and "coinbase-wallet" is in the array, move them to the beginning
    if (coinbaseIndex !== -1 && coinbaseWalletIndex !== -1) {
      // Remove "coinbase" from its current position
      popularOptionList.splice(coinbaseIndex, 1);
      // Subtract 1 from coinbaseWalletIndex because we removed "coinbase" from the array (these assumes the 'coinbase' is before 'coinbase-wallet' in the array, which they are in the popular options)
      popularOptionList.splice(coinbaseWalletIndex - 1, 1);
      // Add "coinbase" and "coinbase-wallet" at the beginning
      popularOptionList.unshift("coinbase", "coinbase-wallet");
    }
  }

  return popularOptionList
    .map((id) => optionMap.get(id))
    .filter((option): option is ImportOptionV2 => !!option);
};

export const useAvailableImportOptions = () => {
  const imports = useGetAllImportOptions().data?.list ?? [];
  return imports;
};

export const useAvailableImportOptionsLookup = () => {
  const imports = useGetAllImportOptions().data?.lookup ?? {};
  return imports;
};

export const useAvailableImportOption = (
  label: string | null | undefined,
): ImportOptionV2 | undefined => {
  const lookup = useGetAllImportOptions().data?.lookup ?? {};
  if (label) {
    return lookup[label];
  }
};

// Check if a from/to is imported.
export const useIsAccountImported = () => {
  const savedAccounts = useGetSavedAccounts();
  const { getEntityByRef } = useEntityLookupAsync();
  const importedParties = new Set<string>();

  savedAccounts.forEach((savedAccount) => {
    if (!savedAccount.id) return;

    // We should be able to just use .id directly, but we call this function because it does some formatting.
    const [id] = geIdAndBlockchain(savedAccount.id);
    importedParties.add(id.toLowerCase());

    if (savedAccount.wallets) {
      savedAccount.wallets.forEach((wallet) => {
        importedParties.add(wallet.address.toLowerCase());
      });
    }

    const entity = getEntityByRef(id);
    if (!entity) return;

    entity.addresses.forEach(({ address }) => importedParties.add(address));
    if ("globalAddresses" in entity) {
      entity.globalAddresses.forEach(({ address }) =>
        importedParties.add(address.toLowerCase()),
      );
    }
  });

  return (lookup: string) => importedParties.has(lookup.toLowerCase());
};
