import { Button, Stack } from "@mui/material";
import type * as React from "react";
import { createContext, useContext, useState } from "react";

import {
  importGlobalKey,
  useCaptureImportAnalytics,
} from "~/components/imports/AnalyticsHelpers";
import { LocalStorageKey } from "~/constants/enums";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { ImportViewMode } from "~/types/enums";

type ImportViewModeContext = {
  viewMode: ImportViewMode;
  setViewMode: (viewMode: ImportViewMode) => void;
};

const importViewModeContextDefault: ImportViewModeContext = {
  viewMode: ImportViewMode.ByBlockchain,
  setViewMode: () => {},
};

const ImportViewModeContextObj = createContext(importViewModeContextDefault);

export const ImportViewModeProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [viewModePreference, setViewModePreference] = useLocalStorage(
    LocalStorageKey.ImportViewMode,
    importViewModeContextDefault.viewMode,
  );

  const [viewMode, setViewMode] = useState(viewModePreference);

  return (
    <ImportViewModeContextObj.Provider
      value={{
        viewMode,
        setViewMode: (viewMode: ImportViewMode) => {
          setViewModePreference(viewMode);
          setViewMode(viewMode);
        },
      }}
    >
      {children}
    </ImportViewModeContextObj.Provider>
  );
};

export const useImportViewMode = () => {
  const { viewMode, setViewMode } = useContext(ImportViewModeContextObj);
  return { viewMode, setViewMode };
};

const ImportViewModeToggleButton = ({
  viewMode,
}: {
  viewMode: ImportViewMode;
}) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const viewModeContext = useImportViewMode();
  const isActive = viewModeContext.viewMode === viewMode;
  const captureAnalytics = useCaptureImportAnalytics();

  return (
    <Button
      disabled={isActive}
      sx={{
        fontSize: "0.75rem",
        padding: "0.25rem 0.75rem",
        color: tokens.text.low,
        background: tokens.button.neutral.default,
        "&.Mui-disabled": {
          color: tokens.text.high,
          background: tokens.button.neutral.pressed,
        },
        "&:hover": {
          color: tokens.text.high,
        },
      }}
      onClick={() => {
        viewModeContext.setViewMode(viewMode);
        captureAnalytics(importGlobalKey("toggle view mode"), { viewMode });
      }}
    >
      {lang.imports.viewMode[viewMode]}
    </Button>
  );
};

export const ImportViewModeToggle = () => {
  return (
    <Stack direction="row" gap="0.5rem">
      <ImportViewModeToggleButton viewMode={ImportViewMode.ByBlockchain} />
      <ImportViewModeToggleButton viewMode={ImportViewMode.ByAddress} />
    </Stack>
  );
};
