import { InventoryMethod } from "@ctc/types";
import { Add, WarningAmber } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { lockPeriodAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { WarningBox } from "~/components/settings-modal/views/lock-period/LockPeriodList";
import {
  GeneralDialogContent,
  GeneralDialogModal,
  type GeneralDialogProps,
  GeneralDialogTitle,
} from "~/components/ui/GeneralDialog";
import { StyledDialogActions } from "~/components/ui/StyledDialogActions";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useSettingsLockPeriod } from "~/hooks/useSettingsLockPeriod";
import { useDesign } from "~/hooks/useTheme";
import { useBestUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import {
  useCreateNewPeriodMutation,
  useGetAllTaxPeriodsQuery,
  useLockPeriodEndDate,
} from "~/state/period";

import { useReconciliationSteps } from "../../../../state/reconciliation";
import { IssuesWhenLockingModal } from "./IssuesWhenLockingModal";

const AddButtonContent = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LockPeriodForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type FormValues = {
  endDate: string;
};

export const LOCK_PERIOD_DISABLED_INVENTORY_METHODS = [
  InventoryMethod.HMRC,
  InventoryMethod.ACB,
  // these 2 are only disabled because we have not tested swapping to/from other inventory methods with them
  InventoryMethod.AVCO,
  InventoryMethod.PFU,
];

export function AddPeriod() {
  const startDate = useLockPeriodEndDate();
  const allLockedPeriodsQuery = useGetAllTaxPeriodsQuery();
  const {
    getDefaultEndDateOverride,
    defaultEndDate,
    startAddingLockPeriod,
    stopAddingLockPeriod,
    isAdding,
  } = useSettingsLockPeriod();

  const [isConfirming, setIsConfirming] = useState(false);

  const addPeriodMutation = useCreateNewPeriodMutation();
  const { tokens } = useDesign();
  const lang = useLang();
  const lockPeriodListAnalyticsKey = lockPeriodAnalyticsKey("add");
  const captureAnalytics = useCaptureAnalytics();
  const dateFormat = "YYYY-MM-DD";
  const isLockedUpToToday =
    moment(startDate).format(dateFormat) === moment().format(dateFormat);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const bestUser = useBestUser();
  const userInventoryMethod = bestUser?.inventoryMethod as InventoryMethod;
  const cantCreateLockDueToInventoryMethod =
    LOCK_PERIOD_DISABLED_INVENTORY_METHODS.includes(userInventoryMethod);

  const isStillLoadingLockPeriod = allLockedPeriodsQuery.isLoading;

  const startDateString = moment(startDate).format(dateFormat);
  const isStartDateEpoch =
    startDateString === moment(new Date(0)).format(dateFormat);

  const startDateLabel = isStartDateEpoch
    ? lang.settings.lockPeriod.beginningOfTime
    : startDateString;

  // Check if there are any locked periods
  const hasLockedPeriods =
    !isStillLoadingLockPeriod &&
    allLockedPeriodsQuery.data &&
    allLockedPeriodsQuery.data.length > 0;

  const { register, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      endDate: getDefaultEndDateOverride(),
    },
  });
  const reconciliationCount = useReconciliationSteps({
    includeCurrentlyHiddenSteps: true,
  });

  const [formData, setFormData] = useState<FormValues | null>(null);

  const doLock = (data: FormValues) => {
    const endDate = moment(data.endDate).endOf("day").utc().toDate();
    captureAnalytics(lockPeriodListAnalyticsKey("add"), {
      startDate,
      endDate,
    });
    addPeriodMutation.mutate({ endDate, isLocked: true });
    stopAddingLockPeriod();
    reset();
  };

  const onSubmit = (data: FormValues) => {
    setFormData(data);
    if (reconciliationCount > 0) {
      setIsWarningModalOpen(true);
    } else {
      doLock(data);
    }
    setIsConfirming(false);
  };

  const isAnyPeriodUnlocked = allLockedPeriodsQuery.data?.some(
    (period) => !period.isLocked,
  );

  return (
    <>
      <IssuesWhenLockingModal
        isOpen={isWarningModalOpen}
        onClose={() => {
          setIsWarningModalOpen(false);
          setFormData(null);
        }}
        onIgnoreIssuesAndLock={() => {
          setIsWarningModalOpen(false);
          if (formData) {
            doLock(formData);
          }
        }}
      />
      {isAdding ? (
        <Box display="flex" flexDirection="column" gap="1rem">
          <LockPeriodForm onSubmit={handleSubmit(onSubmit)}>
            <ConfirmLockingUnlockedPeriodsDialog
              isOpen={isConfirming}
              handleClose={() => {
                setIsConfirming(false);
              }}
              pending={addPeriodMutation.isPending}
              onSubmit={() => handleSubmit(onSubmit)()}
            />
            <Box
              display="flex"
              gap="0.5rem"
              width="100%"
              justifyContent="space-between"
            >
              <Box width="50%">
                <label htmlFor="from">
                  <Typography variant="Metropolis/Body/Regular">
                    {lang.settings.lockPeriod.add.from}
                  </Typography>
                </label>
                {hasLockedPeriods ? (
                  <Input
                    name="from"
                    type="date"
                    value={startDateLabel}
                    disabled
                    style={{ color: tokens.text.disabled }}
                  />
                ) : (
                  <Input
                    name="from"
                    type="text"
                    value={lang.settings.lockPeriod.add.firstTransaction}
                    disabled
                    style={{ color: tokens.text.disabled }}
                  />
                )}
              </Box>
              <Box width="50%">
                <label htmlFor="endDate">
                  <Typography variant="Metropolis/Body/Regular">
                    {lang.settings.lockPeriod.add.to}
                  </Typography>
                </label>
                <Input
                  {...register("endDate", { required: true })}
                  type="date"
                  max={moment().format(dateFormat)}
                  min={moment(startDate).add(1, "day").format(dateFormat)}
                  style={{ color: tokens.text.default }}
                />
              </Box>
            </Box>

            {isAnyPeriodUnlocked ? (
              <PrimaryButton
                onClick={() => {
                  setIsConfirming(true);
                }}
                sx={{ width: "100%", justifyContent: "center" }}
              >
                {lang.settings.lockPeriod.saveButton}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                type="submit"
                sx={{ width: "100%", justifyContent: "center" }}
              >
                {lang.settings.lockPeriod.saveButton}
              </PrimaryButton>
            )}
          </LockPeriodForm>
          <TextButton
            onClick={() => {
              stopAddingLockPeriod();
              reset();
            }}
          >
            {lang.settings.lockPeriod.cancel}
          </TextButton>
        </Box>
      ) : (
        <Tooltip
          title={
            isLockedUpToToday
              ? lang.settings.lockPeriod.disabledCreateLock
              : cantCreateLockDueToInventoryMethod
                ? lang.settings.lockPeriod.inventoryMethodDisabled({
                    inventoryMethod: lang.report[userInventoryMethod],
                  })
                : ""
          }
        >
          <div>
            <TertiaryButton
              disabled={
                isLockedUpToToday ||
                isStillLoadingLockPeriod ||
                cantCreateLockDueToInventoryMethod
              }
              onClick={() => {
                startAddingLockPeriod();
              }}
              sx={{ width: "100%" }}
            >
              <AddButtonContent>
                <Add /> {lang.settings.lockPeriod.addButton}
              </AddButtonContent>
            </TertiaryButton>
          </div>
        </Tooltip>
      )}
    </>
  );
}

function ConfirmLockingUnlockedPeriodsDialog({
  isOpen,
  handleClose,
  pending,
  onSubmit,
}: {
  isOpen: boolean;
  handleClose: () => void;
  pending: boolean;
  onSubmit: () => void;
}) {
  const lang = useLang();
  const { tokens } = useDesign();

  const props: GeneralDialogProps = {
    isOpen,
    handleClose,
    title: lang.settings.lockPeriod.lockUnlockedWarning.title,
    maxWidth: "xs",
    fullWidth: true,
    stopPropagation: true,
    closeOnClickAway: true,
  };

  return (
    <GeneralDialogModal {...props}>
      <GeneralDialogTitle {...props} />
      <GeneralDialogContent {...props}>
        <WarningBox>
          <WarningAmber
            sx={{
              color: tokens.text.danger,
            }}
          />
          <Typography variant="Metropolis/Body/Regular">
            {lang.settings.lockPeriod.lockUnlockedWarning.warning}
          </Typography>
        </WarningBox>
        <Typography variant="Metropolis/Body/Regular" mt="1rem">
          {lang.settings.lockPeriod.lockUnlockedWarning.line1}
        </Typography>
        <Typography variant="Metropolis/Body/Regular" mt="1rem">
          {lang.settings.lockPeriod.lockUnlockedWarning.line2}
        </Typography>
      </GeneralDialogContent>
      <StyledDialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "0.75rem" }}>
          <TertiaryButton onClick={handleClose}>{lang.cancel}</TertiaryButton>
          <PrimaryButton onClick={onSubmit} disabled={pending}>
            {pending
              ? lang.pending
              : lang.settings.lockPeriod.lockUnlockedWarning.action}
          </PrimaryButton>
        </Box>
      </StyledDialogActions>
    </GeneralDialogModal>
  );
}

const Input = styled.input`
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background: ${({ theme }) => theme.tokens.background.input.default};
  font-size: 1.125rem;
  height: 2.5rem;
`;
