import { Box, SvgIcon, Tooltip, Typography } from "@mui/material";

import AmericanExpress from "~/assets/creditcard/amex.svg?react";
import DinersClub from "~/assets/creditcard/diners.svg?react";
import Discover from "~/assets/creditcard/discover.svg?react";
import Generic from "~/assets/creditcard/generic.svg?react";
import JCB from "~/assets/creditcard/jcb.svg?react";
import MasterCard from "~/assets/creditcard/mastercard.svg?react";
import UnionPay from "~/assets/creditcard/unionpay.svg?react";
import Visa from "~/assets/creditcard/visa.svg?react";
import { PendingIcon, SuccessIcon } from "~/components/ui/Icons";
import { NoData } from "~/components/ui/NoData";
import { CreditCardLabel } from "~/constants/constants";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { CardBrand } from "~/types/enums";
import { type PaymentMethod } from "~/types/index";

const CreditCardSVG = ({ brand }: { brand: CardBrand }) => {
  switch (brand) {
    case CardBrand.MasterCard:
      return <MasterCard />;
    case CardBrand.Visa:
      return <Visa />;
    case CardBrand.Amex:
      return <AmericanExpress />;
    case CardBrand.Diners:
      return <DinersClub />;
    case CardBrand.Discover:
      return <Discover />;
    case CardBrand.JCB:
      return <JCB />;
    case CardBrand.UnionPay:
      return <UnionPay />;
    case CardBrand.Unknown:
    default:
      return <Generic />;
  }
};
// Get the list of active cards from the BE
// display only the relevant information
// add ability to update the active card -> throws a dialog popup
export const CardLogo = ({ brand }: { brand: CardBrand }) => {
  return (
    <Tooltip title={CreditCardLabel[brand]}>
      <SvgIcon>
        <CreditCardSVG brand={brand} />
      </SvgIcon>
    </Tooltip>
  );
};

const StatusIcon = ({ isExpired }: { isExpired: boolean }) => {
  if (isExpired) {
    return <PendingIcon />;
  }
  return <SuccessIcon />;
};

const CardStatus = ({ isExpired }: { isExpired: boolean }) => {
  const lang = useLang();
  const statusText = isExpired
    ? lang.billing.paymentMethod.expired
    : lang.billing.paymentMethod.inUse;

  return (
    <Box display="inline-flex" alignItems="center">
      <Typography
        variant="Metropolis/Body/Light"
        display="inline"
        style={{ marginRight: "0.5rem" }}
      >
        {statusText}
      </Typography>
      <StatusIcon isExpired={isExpired} />
    </Box>
  );
};

const PaymentMethodDetails = ({ card }: { card: PaymentMethod["card"] }) => {
  const lang = useLang();
  const { tokens } = useDesign();
  const { isExpired, brand, brandName, last4, expiryMonth, expiryYear } = card;
  return (
    <Box display="flex">
      <Box width="2rem" mr={1}>
        <CardLogo brand={brand} />
      </Box>
      <Box>
        <Box component="span">
          <Typography variant="Metropolis/Body/Regular">{`${brandName} ${lang.billing.paymentMethod.ending} ${last4}`}</Typography>
        </Box>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.low}
        >{`${
          isExpired
            ? lang.billing.paymentMethod.expired
            : lang.billing.paymentMethod.expires
        } ${expiryMonth}/${expiryYear}`}</Typography>
      </Box>
    </Box>
  );
};

export const PaymentMethodItem = ({
  paymentMethod,
  disableStatus = false,
}: {
  paymentMethod: PaymentMethod | null;
  disableStatus?: boolean;
}) => {
  if (!paymentMethod) {
    // TODO: handle this properly
    return <NoData title="No credit card on file" />;
  }
  const { id, card } = paymentMethod;
  return (
    <Box>
      <Box key={id} display="flex" justifyContent="space-between">
        <PaymentMethodDetails card={card} />
        {disableStatus ? null : <CardStatus isExpired={card.isExpired} />}
      </Box>
    </Box>
  );
};
