import { toast } from "sonner";

import { bulkEditAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { type BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditCommandPalette } from "~/components/transactions/command-palette/hooks/useCommandPalette";
import { useNumSelectedOrFiltered } from "~/components/transactions/command-palette/hooks/useNumSelectedOrFiltered";
import { displayMessage } from "~/components/ui/Toaster";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useLang } from "~/redux/lang";
import { useBulkUndoMutation } from "~/state/actions";
import { BulkUpdateStatus, DisplayMessage } from "~/types/enums";

export const BulkEditAction = ({
  bulkEditId,
  showUndoButton = true,
}: {
  bulkEditId: string | undefined;
  showUndoButton?: boolean;
}) => {
  const lang = useLang();
  const bulkUndoMutation = useBulkUndoMutation();
  const captureAnalytics = useCaptureAnalytics();
  if (!bulkEditId || !showUndoButton) {
    return null;
  }

  return (
    <TertiaryButton
      onClick={() => {
        captureAnalytics(bulkEditAnalyticsKey("undo"));
        toast.dismiss(bulkEditId);
        toast.promise(bulkUndoMutation.mutateAsync({ bulkEditId }), {
          loading: lang.txTable.commandPallet.undoLoading,
          success: lang.txTable.commandPallet.undoSuccess,
          error: lang.txTable.commandPallet.undoFailed,
        });
      }}
      size="small"
      sx={{
        whiteSpace: "nowrap",
      }}
    >
      {lang.txTable.commandPallet.undo}
    </TertiaryButton>
  );
};

export function useBulkEditSuccess({
  bulkEditOption,
  showUndoButton = true,
}: {
  bulkEditOption: Exclude<
    BulkEditOptions,
    BulkEditOptions.GroupingWizard | BulkEditOptions.IgnoreWarnings
  >;
  showUndoButton?: boolean;
}) {
  const { numSelected } = useNumSelectedOrFiltered();
  const lang = useLang();
  const successMessage = lang.txTable.commandPallet.successMessage;
  const selectedCount = numSelected ? numSelected : 0;

  const captureAnalytics = useCaptureAnalytics();

  const { close } = useBulkEditCommandPalette();

  return (data: {
    result: undefined | BulkUpdateStatus.AcceptedForProcessing;
    bulkEditId: string | undefined;
  }) => {
    close();
    if (data.result !== BulkUpdateStatus.AcceptedForProcessing) {
      displayMessage({
        message: successMessage[bulkEditOption]({
          count: selectedCount,
        }),
        type: DisplayMessage.Success,
        id: data.bulkEditId,
        action: (
          <BulkEditAction
            bulkEditId={data.bulkEditId}
            showUndoButton={showUndoButton}
          />
        ),
      });
    } else {
      displayMessage({
        message: lang.txTable.commandPallet.acceptedForProcessing,
        type: DisplayMessage.Info,
        id: data.bulkEditId,
        action: (
          <BulkEditAction
            bulkEditId={data.bulkEditId}
            showUndoButton={showUndoButton}
          />
        ),
      });
    }
    captureAnalytics(bulkEditAnalyticsKey("confirmation"), {
      numSelected,
      bulkEditOption,
    });
  };
}
