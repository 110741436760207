import { Box, MenuItem, Select, Typography } from "@mui/material";
import type * as React from "react";
import { useState } from "react";

import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useDesign } from "~/hooks/useTheme";
import { useTriggerDeveloperReportRefresh } from "~/state/developer";
import { Scope } from "~/state/enums";
import {
  useAllStatusesUpdate,
  useReportStatus,
  useSyncStatus,
} from "~/state/sync";
import { RefreshReason } from "~/types/enums";

export function DeveloperReport() {
  const { tokens } = useDesign();
  const allSyncStates = useAllStatusesUpdate();
  const reportStatus = useReportStatus();
  const report = useSyncStatus(Scope.Report);
  const developerRefreshMutation = useTriggerDeveloperReportRefresh();
  const [reason, setReason] = useState<RefreshReason>(RefreshReason.Manual);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (!reason) return;

    developerRefreshMutation.mutate({ reason });
  };

  return (
    <Box
      display="flex"
      gap="0.5rem"
      flexDirection="column"
      component="form"
      px={{ xs: 2, sm: 0 }}
      mt={4}
      onSubmit={handleSubmit}
    >
      <Typography
        variant="Metropolis/Header/H4"
        sx={{
          marginBottom: "1.5rem",
        }}
      >
        Report refresh
      </Typography>
      <Box>
        <Typography variant="Metropolis/Body/Bold">
          Trigger a report refresh with a specific reason
        </Typography>
      </Box>
      <Select value={reason} sx={{ width: "100%" }}>
        {Object.values(RefreshReason).map((type) => {
          return (
            <MenuItem
              key={type}
              value={type}
              onClick={() => {
                setReason(type);
              }}
            >
              {type}
            </MenuItem>
          );
        })}
      </Select>
      <PrimaryButton
        type="submit"
        sx={{ width: "5rem", alignSelf: "flex-end" }}
      >
        Refresh
      </PrimaryButton>
      <Box
        width="100%"
        p="0.5rem"
        bgcolor={tokens.elevation.high}
        border={`1px solid ${tokens.border.neutral.default}`}
        borderRadius="0.25rem"
        whiteSpace="pre-wrap"
      >
        <Typography>
          {JSON.stringify({ allSyncStates, report, reportStatus }, null, 2)}
        </Typography>
      </Box>
    </Box>
  );
}
