import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  isFilterEmpty,
  useTransactionFilter,
} from "~/components/transactions/filter-bar/FilterContext";
import { useSelectedIds } from "~/hooks/useTransactions";
import { useGetActionsQuery } from "~/state/actions";
import { FilterOperators } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

export const useBulkFilterAllEnabled = () => {
  const txFilter = useTransactionFilter();
  const actionsQuery = useGetActionsQuery();
  const selectedIds = useSelectedIds();
  const isMultiPage = (actionsQuery.data?.total || 0) > txFilter.state.count;
  const isAllSelected =
    selectedIds.length === actionsQuery.data?.transactions?.length;
  return !isFilterEmpty(txFilter.state.filter) && isMultiPage && isAllSelected;
};

export const useBulkFilter = () => {
  const { state } = useTransactionCheckbox();
  const selectedIds = useSelectedIds();
  const isBulkFilterAllEnabled = useBulkFilterAllEnabled();

  const {
    state: { filter },
  } = useTransactionFilter();

  if (isBulkFilterAllEnabled && state.selectAll) {
    return filter;
  }

  if (selectedIds.length) {
    const actionIdFilter: FilterQuery = {
      type: FilterOperators.ActionId,
      value: selectedIds,
    };

    return actionIdFilter;
  }

  if (isFilterEmpty(filter)) {
    return undefined;
  }

  return filter;
};
