import { SyncStatusPlatform } from "@ctc/types";
import { CircularProgress } from "@mui/material";

import { ActionHeaderCell } from "~/components/transactions/action-row/ActionHeaderCell";
import { useDesign } from "~/hooks/useTheme";
import { useGetActionsQuery } from "~/state/actions";
import { useReportStatus } from "~/state/sync";
import { Align } from "~/types/enums";

export function ActionHeaderLoading() {
  const { tokens } = useDesign();
  const data = useReportStatus();
  const actionsQueryQuery = useGetActionsQuery();

  const isLoading =
    [SyncStatusPlatform.Pending, SyncStatusPlatform.Queued].includes(
      data?.reportStatus as SyncStatusPlatform,
    ) || actionsQueryQuery.isFetching;

  return (
    <ActionHeaderCell align={Align.Right}>
      {isLoading ? (
        <CircularProgress
          size="1rem"
          sx={{ marginRight: "0.5rem", color: tokens.icon.disabled }}
        />
      ) : null}
    </ActionHeaderCell>
  );
}
