import { SyncStatusPlatform } from "@ctc/types";
import {
  Api,
  Cached,
  Delete,
  Edit,
  OpenInNew,
  RestartAlt,
} from "@mui/icons-material";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useState } from "react";

import { type ApiImportListProps } from "~/components/imports/api/ApiImportList";
import { HardSyncConfirmationDialog } from "~/components/imports/HardSyncConfirmationDialog";
import {
  formatHardSyncTimestamp,
  getOptionFilterForOnboarding,
} from "~/components/imports/helpers";
import { ImportErrorReportingBox } from "~/components/imports/import-error-report-experiment/ImportErrorBox";
import { ImportIndicator } from "~/components/imports/ImportHeaderStatus";
import { ImportListItemContainer } from "~/components/imports/ImportListContainer";
import { ImportListItemWrapper } from "~/components/imports/ImportListItemWrapper";
import { SyncedFrom } from "~/components/imports/ImportMetadata";
import { LastSyncTimestampWrapper } from "~/components/imports/LastSyncTimestampWrapper";
import { useInImportErrorReportExperiment } from "~/components/imports/useImportErrorReportExperiment";
import { MoreOptionsDropdown } from "~/components/imports-v2/MoreOptionsDialog";
import { SyncTableDialog } from "~/components/imports-v2/SyncHistoryDialog";
import {
  type ImportDrawerOptionsType,
  ImportOptionAction,
} from "~/components/imports-v2/types";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { CensoredTypography } from "~/components/ui/CensoredComponents";
import { Chip } from "~/components/ui/Chips";

import { displayMessage } from "~/components/ui/Toaster";
import { useDesign } from "~/hooks/useTheme";
import { HyperlinkTextContent } from "~/lib/HyperlinkTextContent";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useTimezone } from "~/redux/report";
import { useSyncKeyMutation } from "~/state/keys";
import { useGetLockPeriodEndDateIfExists } from "~/state/period";
import {
  DeprecatedAPIs,
  DisplayMessage,
  FilterOperators,
  ImportType,
} from "~/types/enums";
import { type KeyCredentials, type KeyDetails } from "~/types/index";

import { useIsEmbedded } from "../../../hooks/useIsEmbedded";

const getDeprecatedApiKeyHelpArticleUrl = (exchangeName: string): string => {
  const DEPRECATED_API_KEY_HELP_ARTICLES = {
    coinbase: "10510561-coinbase-api-update-02-05-2025",
    default: "6983444-what-to-do-if-the-imported-api-has-expired",
  } as const;

  const exchange = exchangeName.toLowerCase();
  const articleId =
    DEPRECATED_API_KEY_HELP_ARTICLES[
      exchange as keyof typeof DEPRECATED_API_KEY_HELP_ARTICLES
    ] || DEPRECATED_API_KEY_HELP_ARTICLES.default;
  return `https://help.cryptotaxcalculator.io/en/articles/${articleId}`;
};

export const ApiKeyItem = ({
  exchangeLabel,
  exchangeName,
  keyDetails,
  editing = false,
  handleDelete,
  handleEdit,
  handleUpdate,
}: {
  exchangeLabel: string;
  exchangeName: string;
  keyDetails: KeyCredentials;
  editing?: boolean;
  handleDelete: ApiImportListProps["handleDelete"];
  handleEdit: ApiImportListProps["handleEdit"];
  handleUpdate: ApiImportListProps["handleUpdate"];
}) => {
  const { tokens } = useDesign();
  const isEmbedded = useIsEmbedded();
  const isOnboarding = !!useUser()?.isOnboarding;
  const syncKey = useSyncKeyMutation(exchangeName);
  const [isSyncHistoryOpen, setSyncHistoryOpen] = useState(false);

  const lang = useLang();
  const {
    apiKey,
    name,
    errorIssue,
    syncStatus,
    importFromDate,
    isDeprecatedKey,
  } = keyDetails;

  const isSyncing = syncStatus === SyncStatusPlatform.Pending;
  const isInImportErrorExperiment = useInImportErrorReportExperiment();

  const lockPeriodEndDate = useGetLockPeriodEndDateIfExists();

  function handleSync(apiDetails: KeyDetails, isHardSync: boolean) {
    if (!isSyncing) {
      syncKey.mutate({
        exchangeId: exchangeLabel,
        name: exchangeName,
        isHardSync,
        isOAuth: false,
        apiDetails,
      });
    } else {
      displayMessage({
        message: lang.sync.apiStillSyncing,
        type: DisplayMessage.Info,
      });
    }
  }

  const displayName = name
    ? `(${name}) - ${apiKey || exchangeName}`
    : apiKey || exchangeName;

  const timezone = useTimezone();
  const dateTime = keyDetails.lastImportedTxTimestamp
    ? moment.tz(keyDetails.lastImportedTxTimestamp, timezone).format("ll LTS")
    : lang.imports.bulkSyncTooltip.lastSyncTime;

  const softSyncTitle = `${lang.api.softSync({
    name: keyDetails.name || "",
  })} ${dateTime}`;

  const editNicknameText = keyDetails.name
    ? lang.api.editNickname
    : lang.api.nickname;

  const handleSoftSync = () => {
    handleSync(keyDetails, false);
  };

  const [isHardSyncOpen, setIsHardSyncOpen] = useState(false);

  const handleHardSync = () => {
    setIsHardSyncOpen(true);
  };

  const importId = `${ImportType.API}_${keyDetails.id}`;

  const apiIsDeprecated = Object.values(DeprecatedAPIs).includes(
    exchangeLabel as DeprecatedAPIs,
  );

  const handleViewTxs = () => {
    const link = getTransactionPageLink({
      state: {
        filter: {
          type: FilterOperators.And,
          rules: [
            {
              type: FilterOperators.ImportId,
              value: [importId],
            },
          ],
        },
      },
    });
    window.open(link, isEmbedded ? "_self" : "_blank");
  };

  const options: ImportDrawerOptionsType[] = [
    // view txs
    ...(syncStatus === SyncStatusPlatform.Success
      ? [
          {
            id: ImportOptionAction.ViewTransactions,
            label: lang.reconciliation.viewTransactions,
            icon: <OpenInNew fontSize="small" />,
            handler: handleViewTxs,
          },
        ]
      : []),
    // soft sync
    {
      id: ImportOptionAction.Sync,
      disabled: isSyncing || apiIsDeprecated,
      tooltipTitle: softSyncTitle,
      label: isSyncing ? lang.imports.syncing : lang.imports.sync,
      icon: <Cached fontSize="small" />,
      handler: handleSoftSync,
    },
    {
      id: ImportOptionAction.SyncHistory,
      disabled: isSyncing,
      tooltipTitle: lang.syncHistory.title,
      label: lang.syncHistory.title,
      icon: <HistoryIcon fontSize="small" />,
      handler: () => {
        setSyncHistoryOpen(true);
      },
    },
    // hard sync

    ...(!isSyncing
      ? [
          {
            id: ImportOptionAction.HardSync,
            tooltipTitle: lang.api.hardSync.titleOneAPI({
              name: keyDetails.name || "",
              date: formatHardSyncTimestamp(
                importFromDate,
                timezone,
                lang,
                lockPeriodEndDate,
              ),
            }),
            label: lang.imports.hardSync,
            icon: <RestartAlt fontSize="small" />,
            handler: handleHardSync,
            disabled: apiIsDeprecated,
          },
        ]
      : []),

    // update
    {
      id: ImportOptionAction.Update,
      label: lang.api.editingSubmit,
      icon: <Api fontSize="small" />,
      handler: () => {
        handleUpdate(keyDetails);
      },
    },
    // edit
    {
      id: ImportOptionAction.Edit,
      label: editNicknameText,
      icon: <Edit fontSize="small" />,
      handler: () => {
        handleEdit(keyDetails);
      },
    },
    // delete
    {
      id: ImportOptionAction.Delete,
      label: lang.delete,
      icon: <Delete fontSize="small" />,
      handler: () => {
        handleDelete(keyDetails);
      },
    },
  ].filter(getOptionFilterForOnboarding(isOnboarding));

  return (
    <>
      <HardSyncConfirmationDialog
        open={isHardSyncOpen}
        title={lang.api.hardSync.confirmationOneAPI}
        setOpen={setIsHardSyncOpen}
        onHardSync={() => {
          handleSync(keyDetails, true);
        }}
        text={lang.api.hardSync.textOneAPI({
          name: keyDetails.name || keyDetails.apiKey,
          date: formatHardSyncTimestamp(
            importFromDate,
            timezone,
            lang,
            lockPeriodEndDate,
          ),
        })}
      />
      <SyncTableDialog
        isOpen={isSyncHistoryOpen}
        setOpen={setSyncHistoryOpen}
        import={{
          ...keyDetails,
          completedAt: keyDetails.lastSyncComplete?.toISOString() || null,
          importType: ImportType.API,
        }}
        name={displayName}
      />
      <ImportListItemContainer>
        <ImportListItemWrapper highlighted={editing}>
          {syncStatus === SyncStatusPlatform.Fail ? (
            <Box mr={1}>
              <ImportIndicator
                importType={ImportType.API}
                syncStatus={syncStatus}
                tooltipTitle={errorIssue}
                exchangeName={exchangeName}
              />
            </Box>
          ) : syncStatus ? (
            <LastSyncTimestampWrapper
              status={syncStatus}
              errorCode={keyDetails.syncDisplayError?.status}
              errorMsg={keyDetails.syncDisplayError?.message}
              lastCompleted={keyDetails.lastSyncComplete}
            >
              <Box component="span" display="inline-flex" mr={1}>
                <ImportIndicator
                  importType={ImportType.API}
                  syncStatus={syncStatus}
                  exchangeName={exchangeName}
                />
              </Box>
            </LastSyncTimestampWrapper>
          ) : null}
          <CensoredTypography variant="Metropolis/Body/Light" noWrap>
            {displayName}
          </CensoredTypography>
          {isDeprecatedKey ? (
            <Box ml="0.25rem">
              <Tooltip
                title={
                  <Typography
                    variant="Metropolis/Caption/Medium/Regular"
                    color={tokens.special.tooltipText}
                  >
                    <HyperlinkTextContent
                      text={
                        lang.keys.deprecatedInfoByExchange?.[
                          exchangeName.toLowerCase() as keyof typeof lang.keys.deprecatedInfoByExchange
                        ]
                          ? lang.keys.deprecatedInfoByExchange[
                              exchangeName.toLowerCase() as keyof typeof lang.keys.deprecatedInfoByExchange
                            ]({
                              exchange: exchangeName,
                              linkTextKey: "link",
                            })
                          : lang.keys.deprecatedInfo({
                              exchange: exchangeName,
                              linkTextKey: "link",
                            })
                      }
                      actions={{
                        link: {
                          linkTo:
                            getDeprecatedApiKeyHelpArticleUrl(exchangeName),
                        },
                      }}
                    />
                  </Typography>
                }
              >
                <Box>
                  <Chip sx={{ "*": { verticalAlign: "middle" } }}>
                    {lang.keys.deprecated}
                  </Chip>
                </Box>
              </Tooltip>
            </Box>
          ) : null}
          <Box ml="auto">
            <MoreOptionsDropdown options={options} tooltipPlacement="right" />
          </Box>
        </ImportListItemWrapper>
        <SyncedFrom importFromDate={importFromDate} />
        {editing ? null : (
          <ImportErrorReportingBox
            issue={errorIssue}
            handleSoftSync={handleSoftSync}
            handleUpdate={() => {
              handleUpdate(keyDetails);
            }}
            importType={ImportType.API}
            exchangeName={exchangeName}
            syncStatus={syncStatus}
          />
        )}
      </ImportListItemContainer>
    </>
  );
};
