import { combineReducers } from "redux";

import { accountantReducer as accountant } from "~/redux/accountant";
import { apiReducer as api } from "~/redux/api/index";
import { auth } from "~/redux/auth";
import { reducer as developer } from "~/redux/developer";
import { importsReducer as imports } from "~/redux/imports";
import { lang } from "~/redux/lang";
import { organisationReducer as organisation } from "~/redux/organisation";
import { paymentPopupReducer as paymentPopup } from "~/redux/paymentPopup";
import { portfolioReducer as portfolio } from "~/redux/portfolio";
import { reportReducer as report } from "~/redux/report";

const rootReducer = combineReducers({
  auth,
  imports,
  lang,
  report,
  portfolio,
  accountant,
  api,
  organisation,
  developer,
  paymentPopup,
});

export const rootReducerWrapper = (state: any, action: any) =>
  rootReducer(action.type === "LOGOUT_SUCCESS" ? undefined : state, action);
