import { Box, Switch, Typography } from "@mui/material";
import { useState } from "react";

export function FilterOtherToggle({
  label,
  checked,
  onToggle,

  disabled,
}: {
  label: string;
  checked: boolean;
  onToggle: (value: boolean) => void;

  disabled?: boolean;
}) {
  const [toggle, setToggle] = useState(checked);

  const [prevItems, setPrevItems] = useState(checked);
  if (checked !== prevItems) {
    setPrevItems(checked);
    setToggle(checked);
  }

  return (
    <Box display="flex" alignItems="center" py="0.25rem" width="100%">
      <Switch
        checked={toggle}
        onClick={() => {
          const newValue = !toggle;
          setToggle(newValue);
          onToggle(newValue);
        }}
        disabled={disabled}
      />
      <Typography sx={{ fontSize: "0.875rem", fontWeight: 500 }}>
        {label}
      </Typography>
    </Box>
  );
}
