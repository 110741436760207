import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, InputBase, Stack } from "@mui/material";
import { type ComponentProps, useState } from "react";
import { VariableSizeList } from "react-window";
import styled from "styled-components/macro";

import { CommandMenuListStyled } from "~/components/transactions/command-palette/CommandMenuList";
import {
  type CommandPaletteMenu,
  Row,
} from "~/components/transactions/command-palette/CommandPaletteMenu";
import {
  CommandPaletteTitle,
  TitleContainer,
} from "~/components/transactions/command-palette/CommandPaletteTitle";
import { useCommandPaletteMenu } from "~/components/transactions/command-palette/hooks/useCommandPaletteMenu";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { ProgressDivider } from "~/components/transactions/command-palette/ProgressDivider";
import { useLang } from "~/redux/lang";

export function CommandPaletteNumberInput({
  topArea,
  onConfirm,
  loading = false,
  placeholder = "0",
}: {
  topArea?: ComponentProps<typeof CommandPaletteMenu>["topArea"];
  onConfirm: (value: number) => void;
  loading?: boolean;
  placeholder?: string;
}) {
  const [value, setValue] = useState<number | null>(null);
  const { pop } = useNavController();
  const lang = useLang();

  const options = [
    {
      label: lang.txTable.commandPallet.actions.accept,
      icon: <CheckIcon />,
    },
    {
      label: lang.txTable.commandPallet.actions.goBack,
      icon: <CloseIcon />,
    },
  ];

  const onSelection = (option: (typeof options)[number]) => {
    if (option.label === lang.txTable.commandPallet.actions.accept) {
      onConfirm(value ?? 0);
    } else if (option.label === lang.txTable.commandPallet.actions.goBack) {
      pop();
    }
  };

  const { setIsUsingKeyNav, variableSizeListProps, inputSearchProps } =
    useCommandPaletteMenu({
      loading,
      onSelection,
      options,
      placeholder,
      itemSize: () => 50,
      maxMenuHeight: 300,
      disabled: loading,
    });

  const { onKeyDown, inputRef, disabled } = inputSearchProps;
  const limitedInputSearchProps = { onKeyDown, inputRef, disabled };

  return (
    <Box>
      <TitleContainer>
        <CommandPaletteTitle topArea={topArea} loading={loading} />
        <Stack direction="row" gap="0.5rem" alignItems="center">
          <Input
            value={value}
            onChange={(e) => {
              setValue(Number(e.target.value));
            }}
            type="number"
            placeholder={placeholder}
            {...limitedInputSearchProps}
          />
        </Stack>
      </TitleContainer>

      <ProgressDivider loading={loading} />

      <CommandMenuListStyled
        as="div"
        onMouseMove={() => {
          setIsUsingKeyNav(false);
        }}
      >
        <VariableSizeList {...variableSizeListProps}>{Row}</VariableSizeList>
      </CommandMenuListStyled>
    </Box>
  );
}

const Input = styled(InputBase)(({ theme }) => ({
  ...theme.mui.typography['Metropolis/Caption/Medium/Regular'],
  fontSize: "1.25rem",
  width: "100%",
  "& .MuiInputBase-input": {
    color: theme.tokens.text.default,
    "&::placeholder": {
      color: theme.tokens.text.disabled,
    },
  },
}));
