import {
  Box,
  FormControlLabel,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export function RadioButton({
  title,
  description,
  value,
  disabled,
}: {
  title: string;
  description: string;
  value: string | number | boolean;
  disabled?: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang().settings.report.specificInventoryByExchange;

  return (
    <FormControlLabel
      value={value}
      control={
        <Tooltip title={disabled ? lang.disabledReason : ""} placement="top">
          <span>
            <Radio color="primary" disabled={disabled} value={value} />
          </span>
        </Tooltip>
      }
      style={{ alignItems: "flex-start" }}
      label={
        <Box mt={1}>
          <Typography variant="Metropolis/Body/Regular">{title}</Typography>
          <Box mr={1}>
            <Typography
              variant="Metropolis/Body/Regular"
              color={tokens.text.low}
            >
              {description}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
}
