import { type Blockchain } from "@ctc/types";
import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import styled from "styled-components";

import {
  AdditionalNotes,
  requiredIntegrationsOnboardingNote,
} from "~/components/imports/AdditionalNotes";
import {
  getImportInstructionKeyV2,
  validateImportTypeInstructionsWithImages,
} from "~/components/imports/helpers";
import { ImageInstructionsList } from "~/components/imports/import-instructions-experiment/ImageInstructionsList";
import { ImportVideoV2 } from "~/components/imports/import-instructions-experiment/ImportVideov2";
import instructions from "~/components/imports/import-instructions-experiment/instructions.json";
import { ManualCSVActions } from "~/components/imports/ManualCSVInstructions";
import { useInImportInstructionsWithImagesExperiment } from "~/components/onboarding-v2/hooks/useInImportInstructionsWithImagesExperiment";
import { type Size } from "~/hooks/useComponentSize";
import { useUser } from "~/redux/auth";
import { useLang, useNotice } from "~/redux/lang";
import { useAvailableImportOption } from "~/state/imports";
import { ImportMethod, ImportType, IntegrationCategory } from "~/types/enums";
import {
  BlockchainName,
  type ImportMethodV2,
  type ImportTypeInstructionsWithImages,
  type WalletOptions,
} from "~/types/index";

// Assumes instructions are at least 38rem high, which for the case of the A/B test is true.
const InstructionsContainer = styled(Box)<{ containerHeight: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${({ containerHeight }) =>
    containerHeight ? `max(${containerHeight}px, 38rem)` : "38rem"};
  border: 1px solid ${({ theme }) => theme.tokens.border.neutral.default};
  background-color: ${({ theme }) => theme.tokens.elevation.low};
  padding: 0.5rem;
  border-radius: 0.5rem;
`;

const StyledInstructionImagesContainer = styled(Box)<{
  containerHeight: number;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  overflow-y: auto;
  flex: 1;
  max-height: 100%;
`;

export function ImportInstructionsWithImages({
  exchangeLabel,
  importType,
  importMethod,
  selectedBlockchain,
  size: formSize,
}: {
  exchangeLabel: string;
  importType: ImportType | ImportMethod;
  importMethod: ImportMethodV2;
  selectedBlockchain: Blockchain | undefined;
  size: Size;
}) {
  const lang = useLang();
  const inImportInstructionsWithImagesExperiment =
    useInImportInstructionsWithImagesExperiment();
  const importOption = useAvailableImportOption(exchangeLabel);
  const exchangeLabelTyped =
    exchangeLabel as keyof typeof instructions.importInstructions;
  const instructionsWithImages =
    instructions.importInstructions[exchangeLabelTyped];
  const importTypeTyped = importType as keyof typeof instructionsWithImages;
  const isUnspecifiedBlockchainAllowed = !!(
    importMethod.options as WalletOptions | undefined
  )?.isUnspecifiedBlockchainAllowed;

  const potentialNote = useNotice(exchangeLabel);

  const user = useUser();
  const dontShowOnboardingNote = !!(
    user?.isOnboarding &&
    !requiredIntegrationsOnboardingNote[importOption?.id ?? ""]?.includes(
      importType as ImportType,
    )
  );

  if (
    !inImportInstructionsWithImagesExperiment ||
    !validateImportTypeInstructionsWithImages(instructions) ||
    !instructionsWithImages?.[importTypeTyped]
  ) {
    return null;
  }

  const notice =
    importOption?.category === IntegrationCategory.Wallet &&
    (importOption?.category.length === 1 || isUnspecifiedBlockchainAllowed) &&
    selectedBlockchain
      ? lang.wallet.equivWallet({ name: BlockchainName[selectedBlockchain] })
      : potentialNote;

  // If the integration supports its own CSV - add advanced CSV format as a note
  // If the integration doesn't supports a CSV - add advanced CSV format as a section
  const isManualCSVOnly =
    importOption?.manual ||
    importType === ImportType.ManualCSV ||
    (importMethod.type === ImportMethod.CSV && importMethod.options?.manual) ||
    importOption?.category === IntegrationCategory.Manual;

  const isImportingCSV =
    importType === ImportType.CSV || importType === ImportType.ManualCSV;

  const {
    step: stepWithImages,
    video,
    note,
  } = instructionsWithImages[
    importTypeTyped
  ] as ImportTypeInstructionsWithImages;

  return (
    <InstructionsContainer containerHeight={formSize.height}>
      <Box display="flex" justifyContent="space-between" mb="0.75rem">
        <Typography
          variant="Metropolis/Header/H5"
          sx={{
            paddingTop: "0.375rem",
          }}
        >
          {lang.instructions}:
        </Typography>
        {video && video?.length > 0 ? (
          <>
            {video.map((videoLinkInstr) => (
              <ImportVideoV2 key={videoLinkInstr.link} note={videoLinkInstr} />
            ))}
          </>
        ) : null}
      </Box>
      <StyledInstructionImagesContainer containerHeight={formSize.height}>
        {stepWithImages?.map((stepInstr, index) => (
          <Fragment key={getImportInstructionKeyV2(stepInstr, index)}>
            {stepInstr.title ? (
              <Typography
                variant="Metropolis/Body/Light"
                style={{ fontWeight: 500 }}
                gutterBottom
              >
                <span>{stepInstr.title}</span>
              </Typography>
            ) : null}
            {stepInstr.items && stepInstr.items?.length > 0 ? (
              <ImageInstructionsList
                steps={stepInstr.items}
                exchangeName={exchangeLabel}
                importType={importType}
              />
            ) : null}
          </Fragment>
        )) ?? null}
        {notice && (
          <Box>
            <Typography variant="Metropolis/Body/Light">{lang.imports.note}:</Typography>
            <Typography variant="Metropolis/Body/Light">{notice}</Typography>
          </Box>
        )}
        <AdditionalNotes
          noteSteps={note ?? []}
          isManualCSVOnly={isManualCSVOnly}
          isImportingCSV={isImportingCSV}
          dontShowOnboardingNote={dontShowOnboardingNote}
        />
        {isManualCSVOnly && isImportingCSV && <ManualCSVActions />}
      </StyledInstructionImagesContainer>
    </InstructionsContainer>
  );
}
