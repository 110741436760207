import { Country } from "@ctc/types";

import { useExperimentVariant } from "~/analytics/posthog";
import { useUser } from "~/redux/auth";
import { FeatureFlag } from "~/types/enums";

/**
 * Checks if the user has access to business plans
 * @returns {boolean} If the user is in the import loop with reconciliation experiment
 */
export function useIsBusinessPlansEnabled() {
  const user = useUser();

  const inFreeTrialExperiment = useExperimentVariant(
    FeatureFlag.BusinessClientDrawer,
    "freeTrial",
  );

  const inNormalBusinessPaymentFlowExperiment = useExperimentVariant(
    FeatureFlag.BusinessClientDrawer,
    "paymentFlow",
  );

  // business plans are not available in India as we don't have appropriate pricing
  // If there are other countries that we don't have appropriate pricing for, we should build a scalable solution
  if (user?.country === Country.India) {
    return false;
  }

  return inFreeTrialExperiment || inNormalBusinessPaymentFlowExperiment;
}
