import { useEffect, useRef, useState } from "react";

export interface Size {
  width: number;
  height: number;
}

export function useComponentSize(): {
  ref: React.RefObject<HTMLDivElement>;
  size: Size;
} {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return { ref, size };
}
