import { BulkEditCurrencyChipIcon } from "~/components/transactions/command-palette/BulkEditCurrencyChipIcon";
import { DualChip } from "~/components/transactions/command-palette/views/DualChip";
import { displayFiatValue } from "~/lib/index";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { type CurrencyIdentifier } from "~/types/index";

export function PriceTopSection({
  currency,
  price,
}: {
  currency?: CurrencyIdentifier;
  price?: number;
}) {
  const lang = useLang();
  const localCurrency = useLocalCurrency();
  const locale = useLanguagePreference();

  return (
    <DualChip
      leftIcon={
        currency ? <BulkEditCurrencyChipIcon currency={currency} /> : undefined
      }
      leftLabel={currency ? currency.symbol : lang.txTable.currency}
      rightLabel={
        price
          ? displayFiatValue({
              value: price,
              localCurrency,
              locale,
              fractionDigits: 2,
            })
          : displayFiatValue({
              value: 0,
              localCurrency,
              locale,
              fractionDigits: 2,
            })
      }
      rightIcon
    />
  );
}
