import { useEffect, useState } from "react";

import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { useNumSelectedOrFiltered } from "~/components/transactions/command-palette/hooks/useNumSelectedOrFiltered";
import { useNavController } from "~/components/transactions/command-palette/NavController";
import { ConfirmationDialog } from "~/components/ui/ConfirmationDialog";
import { invariant } from "~/lib/invariant";
import { useLang } from "~/redux/lang";
import { useNewBulkDeleteActionsMutation } from "~/state/actions";

export const DeleteConfirmation = ({ open }: { open: boolean }) => {
  const { pop } = useNavController();
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");
  const { numSelected } = useNumSelectedOrFiltered();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const bulkDeleteActionsMutation = useNewBulkDeleteActionsMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.Delete,
  });
  const lang = useLang();
  const bulkEditOptionsLabel = lang.txTable.commandPallet.bulkEditOptions;

  useEffect(() => {
    if (open) {
      setIsDeleteDialogOpen(true);
    }
  }, [open]);

  const handleConfirmDelete = () => {
    bulkDeleteActionsMutation.mutate(
      {
        filter,
      },
      {
        onSuccess(data) {
          onSuccess(data);
          setIsDeleteDialogOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmationDialog
      isOpen={isDeleteDialogOpen}
      title={lang.txTable.deleteBulkTransactionsConfirmationTitle({
        txCount: numSelected ? numSelected : 0,
      })}
      text={lang.txTable.bulkEditBar.deleteConfirmationText}
      actionText={bulkEditOptionsLabel.delete}
      handleClose={() => {
        setIsDeleteDialogOpen(false);
        pop();
      }}
      handleAction={handleConfirmDelete}
      pending={bulkDeleteActionsMutation.isPending}
      critical
    />
  );
};
