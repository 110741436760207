import { Box, Button, type ButtonProps } from "@mui/material";
import { type ReactNode } from "react";
import { css } from "styled-components";
import styled from "styled-components/macro";

import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TextButton } from "~/components/ui/ui-buttons/TextButton";
import { useDesign } from "~/hooks/useTheme";
import { type Size } from "~/types/enums";

export type ButtonGroupActions = {
  text: string;
  size?: Size;
  onClick: () => void;
  disabled?: boolean;
  startIcon?: ReactNode;
};

export function ButtonGroup({
  actions,
  selectedIndex,
}: {
  actions: ButtonGroupActions[];
  selectedIndex: number; // Function to determine which button is selected
}) {
  return (
    <StyledBox>
      {actions.map((action, index) => {
        const ButtonComponent =
          index === selectedIndex ? PrimaryButton : TextButton;
        return (
          <ButtonComponent
            key={action.text}
            onClick={action.onClick}
            size={action.size}
            disabled={action.disabled}
          >
            {action.text}
          </ButtonComponent>
        );
      })}
    </StyledBox>
  );
}

export function TertiaryButtonGroup({
  actions,
  selectedIndex,
}: {
  actions: ButtonGroupActions[];
  selectedIndex: number; // Function to determine which button is selected
}) {
  return (
    <Box css={wrapperCss}>
      {actions.map((action, index) => (
        <TertiaryButtonGroupButton
          key={action.text}
          onClick={action.onClick}
          size={action.size}
          disabled={action.disabled || index === selectedIndex}
          startIcon={action.startIcon}
        >
          {action.text}
        </TertiaryButtonGroupButton>
      ))}
    </Box>
  );
}

function TertiaryButtonGroupButton({
  children,
  ...props
}: { children: ReactNode } & ButtonProps) {
  const { tokens } = useDesign();
  return (
    <Button
      sx={{
        fontSize: "0.75rem",
        padding: "0.25rem 0.75rem",
        color: tokens.text.low,
        background: tokens.button.neutral.default,
        "&.Mui-disabled": {
          color: tokens.text.high,
          background: tokens.button.neutral.pressed,
        },
        "&:hover": {
          color: tokens.text.high,
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

export function ImportErrorActionButtonGroup({
  actions,
}: {
  actions: ButtonGroupActions[];
}) {
  return (
    <Box css={wrapperCss}>
      {actions.map((action, index) => (
        <ImportErrorActionButton
          key={action.text}
          onClick={action.onClick}
          size={action.size}
          startIcon={action.startIcon}
        >
          {action.text}
        </ImportErrorActionButton>
      ))}
    </Box>
  );
}

function ImportErrorActionButton({
  children,
  startIcon,
  ...props
}: { children: ReactNode; startIcon?: ReactNode } & ButtonProps) {
  const { tokens } = useDesign();
  return (
    <Button
      startIcon={startIcon}
      sx={{
        fontSize: "0.75rem",
        padding: "0.25rem 0.75rem",
        color: tokens.text.low,
        background: tokens.button.neutral.default,
        borderRadius: "0.25rem",
        border: `1px solid ${tokens.border.neutral.default}`,
        "&.Mui-disabled": {
          color: tokens.text.high,
          background: tokens.button.neutral.pressed,
        },
        "&:hover": {
          color: tokens.text.high,
          border: `1px solid ${tokens.border.neutral.highest}`,
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

const wrapperCss = css`
  width: max-content;
  max-width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  flex-shrink: 0;
  gap: 0.25rem;
`;

const StyledBox = styled(Box)`
  && {
    ${wrapperCss}
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px ${({ theme }) => theme.tokens.border.brand} solid;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.125rem;
    padding-right: 2px;
  }
`;
