import Cookies from "js-cookie";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { queryClient } from "~/components/queryClient";
import { loadUser, useAuth } from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { resetPortfolio } from "~/redux/portfolio";
import { store } from "~/redux/store";
import { type AuthState as State } from "~/redux/types";
import { URI_DOMAIN } from "~/services/uri";
import { useClientList } from "~/state/clients";
import { Scope } from "~/state/enums";
import { useRemoveSyncMutation } from "~/state/sync";
import { type ClientDetails } from "~/types/index";

const SELECTED_CLIENT_KEY = "SELECTED_CLIENT";
const getSelectedClientKey = (uid: string) => `${uid}_${SELECTED_CLIENT_KEY}`;

export const useActiveClient = (): ClientDetails | undefined => {
  const { data: clientList = [] } = useClientList();
  const { user } = useAuth();
  const uid = user?.uid;

  if (!uid) {
    return undefined;
  }

  const clientId = Cookies.get(getSelectedClientKey(uid));
  const client = clientList.find(({ uuid: uid }) => uid === clientId);

  return client;
};

export const useSetActiveClient = () => {
  const dispatch = useDispatch();
  const removeSyncStatus = useRemoveSyncMutation();
  const { user } = useAuth();
  const authId = user?.uid;

  return useCallback(
    (selectedClientId: string) => {
      if (!authId) {
        return;
      }

      // No-op if the selected client is already set
      const currentlySelectedClientId = Cookies.get(
        getSelectedClientKey(authId),
      );
      if (currentlySelectedClientId === selectedClientId) {
        return;
      }

      Cookies.set(getSelectedClientKey(authId), selectedClientId, {
        // @todo fix our setup to [service].[env].cryptotaxcalculator.io so we can actually limit the cookies
        // to the appropriate envs
        domain: ["test", "local"].includes(import.meta.env.VITE_APP_ENV) // setting the domain doesnt work in local or test and it isn't needed
          ? undefined
          : `.${URI_DOMAIN.split(".").slice(-2).join(".")}`, // set cookie domain to core base domain
        // eg core is https://api.cryptotaxcalculator.io
        // but we can't set the cookie domain to that as its not allowed
        // so we set it to .cryptotaxcalculator.io
        // which means it will be shared across all subdomains
        expires: 365,
      });
      // Reset report status so that sync data will default to new active client.
      removeSyncStatus.mutate({ scope: Scope.Report });
      dispatch(resetPortfolio());
      dispatch(loadUser(LoadUserType.Login));
      queryClient.clear();
    },
    [authId, dispatch, removeSyncStatus],
  );
};

export function getSelectedClientFromCookie(): string {
  const uid = (store.getState().auth as State).user?.uid;
  if (!uid) {
    return "";
  }
  return Cookies.get(getSelectedClientKey(uid)) || "";
}
