import {
  type AiModel,
  type AiProvider,
  AnthropicModels,
  DeepseekModels,
  GoogleVertexAiModels,
  OpenAiModels,
  type Trade,
} from "@ctc/types";
import { AutoAwesome, Check } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Markdown from "react-markdown";

import { useFeatureFlag } from "~/analytics/posthog";
import {
  CurrencyAmountPriceValueTooltip,
  useGetTxAmountText,
} from "~/components/transactions/action-row/TxAmount";
import { CurrencyLogo } from "~/components/ui/CurrencyLogo";
import { Drawer } from "~/components/ui/Drawer";
import { BlockchainSymbolPosition } from "~/components/ui/enums";
import { ExchangeLogo } from "~/components/ui/ExchangeLogo";
import { TradeIcons } from "~/components/ui/TradeIcons";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { useAiContext } from "~/contexts/AiContext";
import { useDesign } from "~/hooks/useTheme";
import { displayCurrency, displayFiatValue, multiply } from "~/lib";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import { type ExplainActionResponse } from "~/services/actions";
import {
  getPartyDetailsFromTx,
  isUncategorisedTrade,
} from "~/services/transactions";
import {
  useAtomicUpdateTransactions,
  useExplainActionQuery,
} from "~/state/actions";
import { FeatureFlag } from "~/types/enums";
import { type ActionRow, type TransactionDetails } from "~/types/index";

export function ExplainActionButton({ row }: { row: ActionRow }) {
  const { tokens } = useDesign();
  const [open, setOpen] = useState(false);

  if (!isUncategorisedTrade(row.type)) return null;

  return (
    <>
      <ExplainActionDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        row={row}
      />
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <TextIconButton
          size="small"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AutoAwesome
            fontSize="small"
            style={{
              color: row.isLocked ? tokens.text.disabled : tokens.text.low,
            }}
          />
        </TextIconButton>
      </Box>
    </>
  );
}

function ExplainActionDrawer({
  open,
  onClose,
  row,
}: {
  open: boolean;
  onClose: () => void;
  row: ActionRow;
}) {
  const { tokens } = useDesign();

  return (
    <Drawer
      isOpen={open}
      onClose={onClose}
      backgroundColor={tokens.elevation.medium}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        gap="1rem"
        p="1.5rem"
      >
        <Box display="flex" alignItems="center" gap="0.5rem">
          <AutoAwesome sx={{ fontSize: "1.5rem" }} />
          <Typography variant="Metropolis/Header/H4">Clarity</Typography>
        </Box>
        <ExplainWithIterationWrapper row={row} onClose={onClose} />
      </Box>
    </Drawer>
  );
}

function ExplainWithIterationWrapper({
  row,
  onClose,
}: {
  row: ActionRow;
  onClose: () => void;
}) {
  const isExperimentationEnabled = useFeatureFlag(
    FeatureFlag.ExperimentAiExplain,
  );
  const { data, isLoading, isFetching, isPending } = useExplainActionQuery(
    row._id,
  );

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <ExplainActionDrawerContent
        explanation={data?.explanation ?? ""}
        suggestions={data?.suggestions ?? {}}
        isLoading={isLoading || isFetching || isPending}
        onClose={onClose}
        row={row}
      />
      {isExperimentationEnabled ? (
        <RapidExperimentationAiExplain data={data} row={row} />
      ) : null}
    </Box>
  );
}

function ExplainActionDrawerContent({
  row,
  explanation,
  suggestions,
  isLoading,
  onClose,
}: {
  row: ActionRow;
  explanation: string;
  suggestions: Record<string, Trade>;
  isLoading: boolean;
  onClose: () => void;
}) {
  if (isLoading) {
    return (
      <Box
        width="100%"
        height="20rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <Typography variant="Metropolis/Body/Regular">
        <Markdown>{explanation}</Markdown>
      </Typography>
      <TransactionSuggestionList
        row={row}
        suggestions={suggestions}
        onClose={onClose}
      />
    </Box>
  );
}

function RapidExperimentationAiExplain({
  data,
  row,
}: {
  data: ExplainActionResponse | undefined;
  row: ActionRow;
}) {
  const { tokens } = useDesign();

  const {
    explainSystemPrompt,
    setExplainSystemPrompt,
    explainPrompt,
    setExplainPrompt,
    model,
    setModel,
    provider,
    setProvider,
  } = useAiContext();
  const [text, setText] = useState(explainPrompt);
  const [systemPrompt, setSystemPrompt] = useState(explainSystemPrompt);
  const [mod, setMod] = useState<AiModel>(model);
  const [prov, setProv] = useState<AiProvider>(provider);

  const modelOptions = getModelOptions(prov);

  const enabledProviders = ["openai", "anthropic", "deepseek"];

  return (
    <>
      <Divider />
      <Box display="flex" flexDirection="column" gap="0.5rem">
        <Typography variant="Metropolis/Header/H5">Select the model</Typography>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          flex="1 0 0"
          fontWeight={500}
        >
          Change which open ai model is used. Try be concious about cost per
          token
        </Typography>
        <Box
          display="flex"
          gap="0.5rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <Select
            value={prov}
            onChange={(e) => {
              setProv(e.target.value as AiProvider);

              // reset the model to the first option
              setMod(modelOptions[0]);
            }}
            label="Provider"
            fullWidth
          >
            {enabledProviders.map((p) => (
              <MenuItem key={p} value={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={mod}
            onChange={(e) => {
              setMod(e.target.value as AiModel);
            }}
            label="Model"
            fullWidth
          >
            {modelOptions.map((m) => (
              <MenuItem key={m} value={m}>
                {m}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Typography variant="Metropolis/Header/H5">
          Customise the system prompt
        </Typography>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          flex="1 0 0"
          fontWeight={500}
        >
          This is passed in as a system message to the AI. Following this is the
          full list of transaction categories and the full action row.
        </Typography>
        <TextField
          value={systemPrompt}
          onChange={(e) => {
            setSystemPrompt(e.target.value);
          }}
          fullWidth
          multiline
        />
        <Typography variant="Metropolis/Header/H5">
          Customise the user prompt
        </Typography>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          flex="1 0 0"
          fontWeight={500}
        >
          This is the only thing in the user prompt section
        </Typography>
        <TextField
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          fullWidth
          multiline
          minRows={4}
        />
        <PrimaryButton
          onClick={() => {
            setExplainPrompt(text);
            setExplainSystemPrompt(systemPrompt);
            setModel(mod);
            setProvider(prov);
          }}
          sx={{ alignSelf: "flex-end" }}
        >
          Re-explain
        </PrimaryButton>

        <Divider />
        <Typography variant="Metropolis/Header/H5">
          Current response info
        </Typography>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          flex="1 0 0"
          fontWeight={500}
        >
          Active model:
        </Typography>
        <Box
          bgcolor={tokens.elevation.low}
          border={`1px solid ${tokens.border.neutral.default}`}
          borderRadius="0.25rem"
          padding="0.5rem"
        >
          <Typography
            variant="IBM Plex Mono/Caption/Small/Regular"
            whiteSpace="pre-wrap"
          >
            {provider} - {model}
          </Typography>
        </Box>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          flex="1 0 0"
          fontWeight={500}
        >
          Confidence: {data?.confidence}
        </Typography>
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          flex="1 0 0"
          fontWeight={500}
        >
          Active prompt:
        </Typography>
        <Box
          bgcolor={tokens.elevation.low}
          border={`1px solid ${tokens.border.neutral.default}`}
          borderRadius="0.25rem"
          padding="0.5rem"
        >
          <Typography
            variant="IBM Plex Mono/Caption/Small/Regular"
            whiteSpace="pre-wrap"
          >
            {`System message:\n
${data?.systemMessage}
\n\n
User prompt:\n
${explainPrompt}`}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

function getModelOptions(provider: AiProvider): readonly AiModel[] {
  switch (provider) {
    case "openai":
      return OpenAiModels;
    case "anthropic":
      return AnthropicModels;
    case "deepseek":
      return DeepseekModels;
    case "google-vertexai":
      return GoogleVertexAiModels;
    default:
      const exhaustiveCheck: never = provider;
      throw new Error(`Unknown provider: ${exhaustiveCheck}`);
  }
}

function TransactionSuggestionList({
  row,
  suggestions,
  onClose,
}: {
  row: ActionRow;
  suggestions: Record<string, Trade>;
  onClose: () => void;
}) {
  const updateRowTransactionMutation = useAtomicUpdateTransactions();

  const txs = [...row.incoming, ...row.outgoing];

  const filteredTxs = txs.filter((tx) => suggestions[tx._id]);

  if (filteredTxs.length === 0) return null;

  return (
    <Box display="flex" flexDirection="column" gap="0.5rem">
      <Typography variant="Metropolis/Header/H5">
        Suggestied categorisations:
      </Typography>
      <Box display="flex" flexDirection="column" width="100%">
        {filteredTxs.map((tx) => {
          const trade = suggestions[tx._id];

          return (
            <TransactionSuggestion key={tx._id} tx={tx} newTrade={trade} />
          );
        })}
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <PrimaryButton
          size="small"
          startIcon={<Check />}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            updateRowTransactionMutation.mutate({
              actionId: row._id,
              update: {
                updateTx: {
                  payload: filteredTxs.map((tx) => ({
                    _id: tx._id,
                    trade: suggestions[tx._id],
                  })),
                  applySts: false,
                  createCategoryRule: false,
                },
              },
            });
            onClose();
          }}
        >
          Apply all suggestions
        </PrimaryButton>
      </Box>
    </Box>
  );
}

function TransactionSuggestion({
  tx,
  newTrade,
}: {
  tx: TransactionDetails;
  newTrade: Trade;
}) {
  const lang = useLang();
  const { tokens } = useDesign();

  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const getAmountText = useGetTxAmountText();

  const amountText = getAmountText(tx);
  const { exchange, blockchain } = getPartyDetailsFromTx(tx);
  const Icon = TradeIcons[newTrade];

  if (!localCurrency) return null;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py="0.25rem"
      px="1rem"
      width="100%"
      borderTop={`1px solid ${tokens.border.neutral.default}`}
      flexWrap="wrap"
    >
      <Box maxWidth="50%" width="50%" minWidth="15rem">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <Icon sx={{ color: `${tokens.text.default} !important` }} />
          <ExchangeLogo
            name={exchange}
            blockchain={blockchain}
            showBlockchainSymbol
            blockchainSymbolPosition={BlockchainSymbolPosition.BottomRight}
            currencySymbol={tx.currencyIdentifier.symbol}
            width={16}
            height={16}
            margin="0"
            showDepositIcon={false}
            background={tokens.background.neutral.default}
          />
          <Typography variant="IBM Plex Mono/Caption/Medium/Regular">
            {lang.tradeType[newTrade]}
          </Typography>
          <AutoAwesome
            sx={{
              fontSize: "1rem",
              color: tokens.text.default,
            }}
          />
        </Box>
      </Box>
      <Box
        width="25%"
        maxWidth="25%"
        display="flex"
        alignItems="center"
        gap="0.25rem"
        minWidth="8rem"
      >
        <CurrencyLogo
          currency={tx.currencyIdentifier}
          margin="0"
          height={16}
          width={16}
        />
        <CurrencyAmountPriceValueTooltip tx={tx}>
          <Typography
            variant="IBM Plex Mono/Caption/Medium/Regular"
            maxWidth="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >{`${amountText} ${displayCurrency(tx.currencyIdentifier.symbol)}`}</Typography>
        </CurrencyAmountPriceValueTooltip>
      </Box>
      <Box width="25%" maxWidth="25%" textAlign="right" minWidth="8rem">
        <Typography
          variant="IBM Plex Mono/Caption/Medium/Regular"
          maxWidth="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {displayFiatValue({
            value: multiply(tx.quantity, tx.price),
            localCurrency,
            locale: languagePreference,
          })}
        </Typography>
      </Box>
    </Box>
  );
}
