import { Close as CloseIcon } from "@mui/icons-material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Dialog, Divider, Popover, Stack, Typography } from "@mui/material";
import {
  bindDialog,
  bindPopover,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useState } from "react";

import { FilterButton } from "~/components/transactions/filter-bar/BasicPopover";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  CheckboxActionType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { TextIconButton } from "~/components/ui/ui-buttons/icon-buttons/TextIconButton";
import {
  DatePickerComponent,
  filterDateFormatter,
} from "~/components/ui/DatePicker";
import { DateFormat, DateTime } from "~/components/ui/enums";
import { useIsMobile } from "~/components/ui/hooks";
import { useDesign } from "~/hooks/useTheme";
import { transformDateTzToProfile } from "~/lib/date";
import { useLang } from "~/redux/lang";
import { useGetFilterOptionsQuery } from "~/state/actions";
import { FilterOperators, Size } from "~/types/enums";
import {
  type AfterFilter,
  type BeforeFilter,
  type DateFilter,
  type FilterQuery,
} from "~/types/index";

function getHasFilterDate(
  filter: FilterQuery | undefined,
  state = false,
): boolean {
  if (!filter) {
    return false;
  }
  if (
    filter.type === FilterOperators.And ||
    filter.type === FilterOperators.Or
  ) {
    // And/Or filter, look at the children
    return (
      state ||
      filter.rules
        .map((filter) => getHasFilterDate(filter, state))
        .some(Boolean)
    );
  }
  return (
    isBeforeFilter(filter) || isAfterFilter(filter) || isDateFilter(filter)
  );
}

export function FilterDate({ size = Size.Small }: { size?: Size }) {
  const { tokens } = useDesign();
  const lang = useLang();
  const disabled = useGetFilterOptionsQuery().isInitialLoading;
  const isMobile = useIsMobile();
  const {
    state: { filter },
  } = useTransactionFilter();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "tertiary-popup",
  });

  const hasDateFilter = getHasFilterDate(filter);

  return (
    <>
      <FilterButton
        popoverProps={bindToggle(popupState)}
        endIcon={<CalendarMonthOutlinedIcon />}
        size={size}
        disabled={disabled}
        active={hasDateFilter}
      >
        {lang.txTable.filter.date}
      </FilterButton>
      {isMobile ? (
        <Dialog {...bindDialog(popupState)}>
          <Stack
            px="0.938rem"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ backgroundColor: tokens.background.neutral.lowest.default }}
          >
            <Stack direction="row" alignItems="center" spacing="0.75rem">
              <CalendarMonthOutlinedIcon
                sx={{ height: "1.25rem", width: "1.125rem" }}
              />
              <Typography variant="Metropolis/Header/H5">
                {lang.txTable.filter.date}
              </Typography>
            </Stack>
            <TextIconButton
              aria-label="close"
              onClick={popupState.close}
              size="small"
            >
              <CloseIcon />
            </TextIconButton>
          </Stack>
          <Divider />
          <FilterDatePopover onClose={popupState.close} />
        </Dialog>
      ) : (
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: -5,
            horizontal: "left",
          }}
        >
          <FilterDatePopover onClose={popupState.close} />
        </Popover>
      )}
    </>
  );
}

function isBeforeFilter(filter: FilterQuery): filter is BeforeFilter {
  return filter.type === FilterOperators.Before;
}

function isAfterFilter(filter: FilterQuery): filter is AfterFilter {
  return filter.type === FilterOperators.After;
}

function isDateFilter(filter: FilterQuery): filter is DateFilter {
  return filter.type === FilterOperators.Date;
}
function setDatePicker({ filter }: { filter: FilterQuery }) {
  const DEFAULT_CALENDER_START_DATE = "01/01/2008";

  if (filter.type !== FilterOperators.And || filter.rules.length === 0) {
    return { startDate: null, endDate: null };
  }

  const beforeFilter = filter.rules.find(isBeforeFilter);
  const afterFilter = filter.rules.find(isAfterFilter);
  const dateFilter = filter.rules.find(isDateFilter);

  // Don't set date if no date is provided
  if (!beforeFilter && !afterFilter && !dateFilter) {
    return { startDate: null, endDate: null };
  }

  // Date picker can't show both date range and date
  if ((beforeFilter || afterFilter) && dateFilter) {
    return { startDate: null, endDate: null };
  }

  // Date picker can't show more then 1 single date
  if (dateFilter && dateFilter.value.length > 1)
    return { startDate: null, endDate: null };

  if (dateFilter) {
    const date = dateFilter.value[0];

    return {
      startDate: filterDateFormatter(date, DateFormat.Date),
      endDate: filterDateFormatter(date, DateFormat.Date),
    };
  }

  return {
    startDate: afterFilter
      ? filterDateFormatter(afterFilter.value, DateFormat.Date)
      : filterDateFormatter(DEFAULT_CALENDER_START_DATE, DateFormat.Date),
    endDate: beforeFilter
      ? filterDateFormatter(beforeFilter.value, DateFormat.Date)
      : new Date(),
  };
}

const defaultFilterQuery: FilterQuery = {
  type: FilterOperators.And,
  rules: [],
};

function FilterDatePopover({ onClose }: { onClose: () => void }) {
  const { state, dispatch } = useTransactionFilter();
  const filterOptions = useGetFilterOptionsQuery();
  const presets = filterOptions.data?.preset;

  const { dispatch: checkboxDispatch } = useTransactionCheckbox();
  const filter = state.filter ?? defaultFilterQuery;
  const [startDate, setStartDate] = useState<Date | null>(
    setDatePicker({ filter }).startDate,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    setDatePicker({ filter }).endDate,
  );
  const [prevFilter, setPrevFilter] = useState(filter);
  if (filter?.type !== FilterOperators.And) {
    return <div>It must be an And query</div>;
  }

  if (filter !== prevFilter) {
    setPrevFilter(filter);
    const result = setDatePicker({ filter });
    setStartDate(result.startDate);
    setEndDate(result.endDate);
  }

  function setQuery(query: FilterQuery) {
    dispatch({
      type: FilterActionType.SetFilter,
      filter: query,
    });
    checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
  }

  const onChange = (dates: any) => {
    const [start, end] = dates;
    // Start/end dates use browser time, which needs to be converted to the tz
    // specified on user profile, `moment` sets the default tz on tax
    // settings load, so the current `moment` time needs to be set with the
    // specified date, month and year to form a correct value to be send to BE.
    setStartDate(start ? transformDateTzToProfile(start) : null);
    setEndDate(end ? transformDateTzToProfile(end) : null);
  };

  const handleClose = () => {
    onClose();
    setStartDate(null);
    setEndDate(null);
  };

  const onApply = () => {
    const dateFilters: FilterQuery[] = [];
    const prev = setDatePicker({ filter: prevFilter });

    // Prevents an empty date selection from setting a new empty filter.
    if (!prev.startDate && !prev.startDate && !startDate && !endDate) {
      return;
    }

    // Date range
    if (startDate && endDate) {
      dateFilters.push(
        {
          type: FilterOperators.After,
          value: filterDateFormatter(
            startDate,
            DateFormat.Number,
            DateTime.Start,
          ),
        },
        {
          type: FilterOperators.Before,
          value: filterDateFormatter(endDate, DateFormat.Number, DateTime.End),
        },
      );
    }

    // Single date
    if (startDate && !endDate) {
      dateFilters.push({
        type: FilterOperators.Date,
        value: [
          filterDateFormatter(startDate, DateFormat.Number, DateTime.Start),
        ],
      });
    }

    if (filter) {
      const notDateFilters = filter.rules.filter(
        (query) =>
          query.type !== FilterOperators.After &&
          query.type !== FilterOperators.Before &&
          query.type !== FilterOperators.Date,
      );

      setQuery({
        ...filter,
        rules: [...notDateFilters, ...dateFilters],
      });
    } else {
      setQuery({
        type: FilterOperators.And,
        rules: [...dateFilters],
      });
    }
  };

  return (
    <div>
      <DatePickerComponent
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        showFooter
        showInputs
        presets={presets}
        onClose={handleClose}
        onApply={onApply}
      />
    </div>
  );
}
