import { Env } from "@ctc/types";

import { type TwitterEvents, type TwitterProperties } from "~/types/twitter";

const shouldEnable = (): boolean => {
  return (
    import.meta.env.VITE_APP_ENV === Env.Prod &&
    Boolean(import.meta.env.VITE_APP_TWITTER_ID) &&
    Boolean(window.twq)
  );
};

export function captureTwitterAnalytics(
  event: TwitterEvents,
  additionalProperties: TwitterProperties = {},
) {
  if (!shouldEnable()) return;

  window.twq(event, import.meta.env.VITE_APP_TWITTER_ID, additionalProperties);
}
