import { Box, Switch, Typography } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { useContext, useEffect } from "react";

import { TableColumns } from "~/components/report/tax-loss-harvesting-modal/enums";
import { useIsMobile } from "~/components/ui/hooks";
import { TaxLossHarvestingContext } from "~/contexts/TaxLossHarvestingContext";
import { useLang } from "~/redux/lang";

const emptyPriceFilter = {
  columnField: TableColumns.Price,
  operatorValue: "isNotEmpty",
};

export function WithoutMarketPriceToggle() {
  const lang = useLang();
  const apiRef = useGridApiContext();
  const tlhContext = useContext(TaxLossHarvestingContext);
  const isMobile = useIsMobile();

  // set the default filter depending on the context
  useEffect(() => {
    if (tlhContext && !tlhContext.showZeroPrice)
      apiRef.current.upsertFilterItem(emptyPriceFilter);
  }, [apiRef, tlhContext]);
  if (!tlhContext) return null;

  return (
    <Box
      display="flex"
      alignItems="center"
      paddingLeft="0.5rem"
      width={isMobile ? "100%" : undefined}
    >
      <Typography sx={{ fontSize: "0.875rem", fontWeight: 500 }}>
        {lang.taxLossHarvesting.showAssetsWithoutMarketPrice}
      </Typography>
      <Switch
        checked={tlhContext.showZeroPrice}
        onChange={() => {
          if (!tlhContext.showZeroPrice) {
            apiRef.current.deleteFilterItem(emptyPriceFilter);
            tlhContext.updateShowZeroPrice(true);
          } else {
            apiRef.current.upsertFilterItem(emptyPriceFilter);
            tlhContext.updateShowZeroPrice(false);
          }
        }}
      />
    </Box>
  );
}
