import { type InvitationDirection } from "~/types/enums";
import { InvitationStatus, InvitationType } from "~/types/enums";
import { type InvitationDetails } from "~/types/index";

export function getInvitationDescription(
  invitation: InvitationDetails | null,
  direction: InvitationDirection | null,
): string {
  if (!invitation || !direction) {
    return "You can invite an accountant to share and manage your data. Learn more";
  }
  if (invitation.invitationType === InvitationType.Collaborator) {
    switch (invitation.status) {
      case InvitationStatus.Pending:
        return direction === "sent"
          ? `${invitation.toEmail}`
          : `${invitation.fromEmail} has invited you to join as a team member of their office`;
      default:
        return direction === "sent"
          ? invitation.toEmail
          : invitation.fromName
            ? `${invitation.fromName} (${invitation.fromEmail})`
            : invitation.fromEmail;
    }
  }
  return direction === "sent" ? invitation.toEmail : invitation.fromEmail;
}
