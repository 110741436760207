import { Env } from "@ctc/types";

const DEV_FE_URL = "https://dev.app.cryptotaxcalculator.io";
const PROD_FE_URL = "https://app.cryptotaxcalculator.io";

/**
 * Get the cloudfront image url for the given icon type and id
 * @param iconType - the type of icon, exchange or currency as these live in different folders to avoid collisions
 * @param id - the id of the icon, currency id or the exchange name
 * @param filetype - the filetype of the icon, default is png
 * @returns the cloudfront image url
 */
export function getCloudFrontImageUrl(
  iconType: IconType.CurrencyIcons | IconType.ExchangeLogos,
  id: string,
  filetype = "png",
) {
  // these two envs aren't hosted on the same domain as the cloudfront distribution
  // so they can just pull from the dev cloudfront
  const baseURL = [Env.SupportProd, Env.Prod].includes(
    import.meta.env.VITE_APP_ENV,
  )
    ? PROD_FE_URL
    : DEV_FE_URL;

  return `${baseURL}/${iconType}/${id}.${filetype}`;
}

export function getCloudFrontNftImageUrl(
  blockchain: string,
  contractAddress: string,
  tokenId: string,
) {
  // these two envs aren't hosted on the same domain as the cloudfront distribution
  // so they can just pull from the dev cloudfront
  const baseURL = [Env.SupportProd, Env.Prod].includes(
    import.meta.env.VITE_APP_ENV,
  )
    ? PROD_FE_URL
    : DEV_FE_URL;

  // Images typically seem to be png, use that as first attempt.
  const filetypes = ["png", "jpg", "webp", "gif"];

  return filetypes.map((filetype) => {
    return `${baseURL}/${IconType.Nft}/${blockchain}/${contractAddress}/${tokenId}.${filetype}`;
  });
}

export enum IconType {
  ExchangeLogos = "exchange-logos",
  CurrencyIcons = "icons",
  Nft = "nft",
}
