import { Plan } from "@ctc/types";
import { WarningAmberOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useCaptureAnalytics } from "~/analytics/posthog";
import { useCompleteOnboarding } from "~/components/onboarding-v2/helpers";
import { StyledDialogActions } from "~/components/ui/StyledDialogActions";
import { displayMessage } from "~/components/ui/Toaster";
import { getCodeDetails } from "~/components/user/invite-link-page/InviteLinkPage";
import { LocalStorageKey } from "~/constants/enums";
import { useDesign } from "~/hooks/useTheme";
import { loadUser, useCountry, useUser } from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import { Analytics } from "~/segment/index";
import * as invitationsAPI from "~/services/invitations";
import { invitationKeys, useBestInvitation } from "~/state/invitations";
import { inviteKeys } from "~/state/invites";
import {
  DisplayMessage,
  InvitationDirection,
  InvitationStatus,
  InvitationType,
} from "~/types/enums";

export function AcceptAccountantInvite() {
  const queryClient = useQueryClient();
  const { data: bestInvitation } = useBestInvitation();
  const country = useCountry();
  const dispatch = useDispatch();
  const lang = useLang();
  const user = useUser();
  const [isActionsDisabled, setIsActionsDisabled] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<
    "accepted" | "declined" | undefined
  >(undefined);
  const [open, setOpen] = useState(true);
  const [code, setCode] = useState<string | null>();
  const location = useLocation();
  const completeOnboarding = useCompleteOnboarding();

  const captureAnalytics = useCaptureAnalytics();

  const codeDetails = useQuery({
    queryKey: inviteKeys.details(),
    queryFn: getCodeDetails(code as string),
    enabled: !!code,
  });

  useEffect(() => {
    if (code) localStorage.setItem(LocalStorageKey.InviteCode, code);
  }, [code]);

  useEffect(() => {
    setCode(localStorage.getItem(LocalStorageKey.InviteCode));
  }, [location.pathname]);

  const hasInviteToAccept =
    bestInvitation?.invitation &&
    bestInvitation.invitation.status === InvitationStatus.Pending &&
    bestInvitation.invitation.invitationType === InvitationType.Client &&
    bestInvitation.type === InvitationDirection.Received &&
    country;

  const hasCodeToAccept =
    !!code &&
    typeof code === "string" &&
    !codeDetails.isPending &&
    !user?.parentProfileDetails;

  const noCodeToAccept = !hasCodeToAccept && !hasInviteToAccept;

  const noInvitePlans = [Plan.Accountant, Plan.Collaborator, Plan.Enterprise];

  if (
    noCodeToAccept ||
    !user ||
    !user.country ||
    noInvitePlans.find((plan) => user.paidPlan === plan)
  )
    return <div />;

  const handleAcceptAction = async () => {
    setIsActionsDisabled(true);
    setSelectedOption("accepted");
    captureAnalytics("accept_accountant_invite", {
      user: user._id,
    });

    if (hasInviteToAccept) {
      if (!bestInvitation.invitation) return;
      const { invitation } = bestInvitation;
      const res = await invitationsAPI.updateInvitation(invitation._id, {
        status: InvitationStatus.Accepted,
      });
      if (res.error) {
        displayMessage({
          message: lang.clients.newAccountInvite.acceptFailed,
          type: DisplayMessage.Error,
        });
      } else {
        const analytics = await Analytics.getInstance();
        analytics.track({
          event: "accountant_invite_accepted",
          user,
          props: invitation
            ? {
                invitation_id: invitation._id,
                accountant_email: invitation.fromEmail,
              }
            : {
                inviteCode: code,
                accountant_email: codeDetails.data?.email,
              },
        });
        void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
      }
    }

    if (code) {
      const res = await invitationsAPI.acceptInviteCode(code);
      if (res.error) {
        displayMessage({
          message: res.msg || lang.clients.newAccountInvite.acceptFailed,
          type: DisplayMessage.Error,
        });
      }

      localStorage.removeItem(LocalStorageKey.InviteCode);
    }

    if (user.isOnboarding) {
      await completeOnboarding();
    }

    setSelectedOption(undefined);
    setIsActionsDisabled(false);
    dispatch(loadUser(LoadUserType.Login));
    setOpen(false);
  };

  const handleDeclineAction = async () => {
    setIsActionsDisabled(true);
    setSelectedOption("declined");
    captureAnalytics("decline_accountant_invite", {
      user: user._id,
    });

    if (hasInviteToAccept) {
      if (!bestInvitation.invitation) return;
      const { invitation } = bestInvitation;
      const res = await invitationsAPI.updateInvitation(invitation._id, {
        status: InvitationStatus.Declined,
      });
      if (res.error) {
        displayMessage({
          message: lang.clients.newAccountInvite.declineFailed,
          type: DisplayMessage.Error,
        });
      } else {
        void queryClient.invalidateQueries({ queryKey: invitationKeys.all() });
      }
    }

    if (code) {
      localStorage.removeItem(LocalStorageKey.InviteCode);
    }

    setSelectedOption(undefined);
    setIsActionsDisabled(false);
    setOpen(false);
  };

  const bodyLang = hasInviteToAccept
    ? lang.clients.newAccountInvite.textAccountantOwns
    : lang.clients.newAccountInvite.textClientOwns;

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="Metropolis/Header/H4">
          {lang.clients.newAccountInvite.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="Metropolis/Header/H5">
          {bodyLang({
            email:
              bestInvitation?.invitation?.fromEmail ||
              codeDetails.data?.email ||
              "",
          })}
        </Typography>

        <NoticeModal
          title={lang.auth.recogniseTitle}
          body={lang.auth.recogniseBody}
        />
      </DialogContent>
      <StyledDialogActions>
        <Button
          onClick={handleDeclineAction}
          variant="outlined"
          color="secondary"
          disabled={isActionsDisabled}
          startIcon={
            selectedOption === "declined" ? (
              <CircularProgress size="1.125rem" />
            ) : null
          }
        >
          {selectedOption === "declined"
            ? lang.button.pending
            : lang.button.decline}
        </Button>
        <Button
          variant="contained"
          color={selectedOption === "accepted" ? "secondary" : "primary"}
          onClick={handleAcceptAction}
          disabled={isActionsDisabled}
          startIcon={
            selectedOption === "accepted" ? (
              <CircularProgress size="1.125rem" />
            ) : null
          }
        >
          {selectedOption === "accepted"
            ? lang.button.pending
            : lang.button.accept}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

const NoticeModal = ({
  title,
  body,
  danger,
}: {
  title: string;
  body: string;
  danger?: boolean;
}) => {
  const { tokens } = useDesign();

  const [bgColor, iconColor] = danger
    ? [tokens.background.danger.default, tokens.button.danger.default]
    : [tokens.background.warning.default, tokens.button.warning.default];

  return (
    <Box
      sx={{
        background: bgColor,
        marginTop: "0.5rem",
        paddingY: 1,
        paddingX: 1.5,
        borderRadius: 1,
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <WarningAmberOutlined
          sx={{
            fill: iconColor,
            marginRight: 1,
          }}
        />
        <Box>
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontWeight: "bold",
              marginBottom: 0.5,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.75rem",
              opacity: 0.8,
            }}
          >
            {body}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
