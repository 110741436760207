import {
  paletteBinanceLight,
  paletteCoinbaseLight,
  paletteCtcDark,
  paletteCtcLight,
  paletteKrakenLight,
  paletteMetaMaskLight,
} from "~/components/ui/theme/generated";
import { BrandStyle, type ResolvedTheme, Theme } from "~/types/enums";

/**
 * Palette configuration mapping for different brand styles and theme modes
 */
const PALETTE_CONFIG = {
  [BrandStyle.CTC]: {
    [Theme.Dark]: paletteCtcDark,
    [Theme.Light]: paletteCtcLight,
  },
  // Metamask and Coinbase are the same for light and dark mode
  [BrandStyle.MetaMask]: {
    [Theme.Dark]: paletteMetaMaskLight,
    [Theme.Light]: paletteMetaMaskLight,
  },
  [BrandStyle.Coinbase]: {
    [Theme.Dark]: paletteCoinbaseLight,
    [Theme.Light]: paletteCoinbaseLight,
  },
  [BrandStyle.Kraken]: {
    [Theme.Dark]: paletteKrakenLight,
    [Theme.Light]: paletteKrakenLight,
  },
  [BrandStyle.Binance]: {
    [Theme.Dark]: paletteBinanceLight,
    [Theme.Light]: paletteBinanceLight,
  },
} satisfies Record<BrandStyle, Record<ResolvedTheme, Palette>>;

type Palette =
  | typeof paletteCtcDark
  | typeof paletteMetaMaskLight
  | typeof paletteCtcLight
  | typeof paletteCoinbaseLight;

/**
 * Gets the palette based on brand style and theme mode
 * @param brandStyle The brand style to use (CTC or Metamask), defaults to CTC
 * @param mode The resolved theme mode (Light or Dark)
 * @returns The corresponding palette
 */
export const getPalette = (
  brandStyle: BrandStyle,
  mode: ResolvedTheme,
): Palette => {
  return PALETTE_CONFIG[brandStyle][mode];
};
