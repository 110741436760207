import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { ImportSourceToggle } from "~/components/imports/ImportSourceToggle";
import { FixedMobileAppBarPosition } from "~/components/onboarding-v2/enums";
import { FixedMobileAppBar } from "~/components/onboarding-v2/FixedMobileAppBar";
import { ShieldIcon } from "~/components/ui/Icons";
import { PrimaryButton } from "~/components/ui/ui-buttons/PrimaryButton";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useIsOnboardingMobileImportExperiment } from "~/hooks/useIsOnboardingMobileImportExperiment";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { type ImportMethodV2, type ImportOptionV2 } from "~/types";
import { ImportMethod, Links } from "~/types/enums";

export function OnboardingNavBar({
  importOption,
  importMethod,
  handleSubmit,
}: {
  importOption: ImportOptionV2;
  importMethod: ImportMethodV2;
  handleSubmit?: () => void;
}) {
  const lang = useLang();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { tokens } = useDesign();
  const isInOnboardingMobileImportExperiment =
    useIsOnboardingMobileImportExperiment();

  if (!isMobile || !isInOnboardingMobileImportExperiment) {
    return null;
  }

  const isSecureConnectMethod = IsSecureConnectMethod({ importMethod });

  return (
    <FixedMobileAppBar
      position={FixedMobileAppBarPosition.BOTTOM}
      justifyContent="space-between"
      style={{
        backgroundColor: tokens.elevation.default,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        pb="2rem"
        pt="1rem"
        width="100%"
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap="0.5rem" alignItems="center">
            <StyledTertiaryButton
              onClick={() => {
                navigate(Links.OnboardingImportsSearch);
              }}
            >
              <ArrowBackIcon
                sx={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: tokens.text.low,
                }}
              />
            </StyledTertiaryButton>
            <ImportSourceToggle
              importOption={importOption}
              importMethod={importMethod}
              showForOnboardingMobileImportExperiment
            />
          </Box>
          {handleSubmit && (
            <PrimaryButton
              onClick={handleSubmit}
              startIcon={isSecureConnectMethod ? <ShieldIcon /> : undefined}
              endIcon={
                <ArrowForwardIcon sx={{ width: "1rem", height: "1rem" }} />
              }
            >
              <Typography
                variant="Metropolis/Body/Regular"
                color={tokens.text.white}
              >
                {isSecureConnectMethod
                  ? lang.imports.secureConnect
                  : lang.imports.continue}
              </Typography>
            </PrimaryButton>
          )}
        </Box>

        {!isSecureConnectMethod ? null : (
          <Box>
            <Typography
              variant="Metropolis/Caption/Medium/Regular"
              color={tokens.text.low}
              textAlign="center"
            >
              {lang.imports.secureConnectNote}
            </Typography>
          </Box>
        )}
      </Box>
    </FixedMobileAppBar>
  );
}

function IsSecureConnectMethod({
  importMethod,
}: {
  importMethod: ImportMethodV2;
}) {
  return (
    importMethod.type !== ImportMethod.CSV &&
    importMethod.type !== ImportMethod.ConnectWallet
  );
}

export function OnboardingNavBarSpacing({
  importMethod,
}: {
  importMethod: ImportMethodV2;
}) {
  // TODO: find dynamic solution
  const isSecureConnectMethod = IsSecureConnectMethod({ importMethod });
  return <Box pb="2rem" mb={isSecureConnectMethod ? "5.875rem" : "2.875rem"} />;
}

const StyledTertiaryButton = styled(TertiaryButton)`
  && {
    padding: 0.75rem 0.75rem;
    min-width: 0;
  }
`;
