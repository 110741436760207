import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { OnboardingTitle } from "~/components/onboarding-v2/OnboardingTitle";
import { OnboardingPageContainer } from "~/components/onboarding-v2/ui/OnboardingPageContainer";
import { OnBoardingWindow } from "~/components/onboarding-v2/ui/OnBoardingWindow";
import {
  PrimaryButton,
  PrimaryLoadingButton,
} from "~/components/ui/ui-buttons/PrimaryButton";
import { Verify2FAInput } from "~/components/ui/Verify2FAInput";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign } from "~/hooks/useTheme";
import {
  Auth,
  resetError,
  setAuth,
  tokenLogin,
  useAuth,
  useLogout,
  verify2fa,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { Links } from "~/types/enums";

export const TokenLogin = () => {
  const { tokens } = useDesign();

  const lang = useLang();
  const isEmbedded = useIsEmbedded();
  const { error } = useAuth();
  const navigate = useNavigate();
  const [loginToken] = useQueryParam("loginToken", StringParam);
  const [providerIdQuery] = useQueryParam("providerId", StringParam);
  const [isCodeRequired, setIsCodeRequired] = useState(false);
  const [code, setCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  document.body.style.backgroundColor = tokens.elevation.default;

  useEffect(() => {
    if (isCodeRequired) {
      return;
    }
    if (loginToken) {
      dispatch(tokenLogin({ loginToken }, setIsCodeRequired));
    } else {
      dispatch(
        setAuth({
          type: Auth.LoginFail,
          error: lang.tokenLogin.errorTitle,
        }),
      );
    }
  }, [loginToken, providerIdQuery, isCodeRequired]);

  // If we're in an embedded context, show the embedded form
  if (isEmbedded) {
    navigate(Links.Login);
    return null;
  }

  const handle2faSubmit = async (e: React.FormEvent<unknown>) => {
    e.preventDefault();
    dispatch(resetError());

    dispatch(verify2fa({ code }, setIsSubmitting));
  };

  return (
    <OnboardingPageContainer>
      <OnBoardingWindow
        display="flex"
        flexDirection="column"
        alignItems={isCodeRequired ? "flex-start" : "center"}
      >
        <OnboardingTitle textAlign="center">
          {lang.tokenLogin.title}
        </OnboardingTitle>
        {isCodeRequired ? (
          <>
            <Verify2FAInput
              error={error}
              setCode={setCode}
              sx={{ padding: 0, paddingBottom: "1rem", marginTop: "0.5rem" }}
              hideTitle
            />
            <PrimaryLoadingButton
              onClick={handle2faSubmit}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {lang.twoFactorAuth.submit}
            </PrimaryLoadingButton>
          </>
        ) : (
          <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
            {lang.tokenLogin.subtitle}
          </Typography>
        )}
      </OnBoardingWindow>
    </OnboardingPageContainer>
  );
};

export function TokenLoginForm() {
  const lang = useLang();
  const navigate = useNavigate();
  const { error } = useAuth();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {error ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <PrimaryButton
            onClick={() => {
              navigate(Links.Login);
            }}
          >
            {lang.tokenLogin.visitLogin}
          </PrimaryButton>
        </Box>
      ) : null}
    </Box>
  );
}

export function TokenLoginLogout() {
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
}
