import { useQuery, useQueryClient } from "@tanstack/react-query";

export enum ProgressType {
  ReportRefresh = "report refresh",
}

export type ProgressValue = {
  /** number between 0 and max, or any number if max is ommitted */
  value: number;
  /** max value, if omitted, value is absolute */
  max?: number;
};

export const progressKeys = {
  all: () => ["progress"] as const,
  type: (type: ProgressType) => [...progressKeys.all(), { type }] as const,
};

/**
 * Clear progress from our RQ store
 *
 * This is used for clearing out progress we know its stale.
 * Useful when you restart a process, and there is a delay when the async
 * process starts up on the backend and resets the progress to zero.
 */
export const useClearProgress = (type: ProgressType) => {
  const queryClient = useQueryClient();
  function clear() {
    queryClient.setQueryData(progressKeys.type(type), undefined);
  }
  return { clear };
};

/**
 * Progress query for the report refresh
 */
export const useProgressQuery = (
  progressType: ProgressType = ProgressType.ReportRefresh,
) => {
  const progressQuery = useQuery<{
    value: number;
    max?: number;
  }>({
    queryKey: progressKeys.type(progressType),
    enabled: false,
  });
  return progressQuery;
};
