import { useDispatch } from "react-redux";

import { displayMessage } from "~/components/ui/Toaster";
import { setUpdate, setUpdateBestActiveUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { ReOnboardingCompleted } from "~/services/settings";
import { DisplayMessage } from "~/types/enums";

export function useCompleteReOnboarding() {
  const dispatch = useDispatch();
  const lang = useLang().imports.selectCountry;

  return async () => {
    try {
      dispatch(setUpdate({ isReOnboarding: false }));
      dispatch(setUpdateBestActiveUser({ isReOnboarding: false }));
      const res = await ReOnboardingCompleted();
      if (res.error) {
        dispatch(setUpdate({ isReOnboarding: true }));
        dispatch(setUpdateBestActiveUser({ isReOnboarding: true }));
        dispatch(
          displayMessage({
            message: lang.somethingWentWrongSupport,
            type: DisplayMessage.Error,
          }),
        );
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  };
}
