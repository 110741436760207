import { get, post } from "~/services/core";
import {
  type CreateOrganisationTeamPayload,
  type OrganisationDetails,
  type OrganisationUpcomingInvoice,
} from "~/types/index";

export function getOrganisation() {
  const path = "/organisation";
  return get<OrganisationDetails>(path);
}

export function getUpcomingInvoice() {
  const path = "/organisation/upcoming-invoice";
  return get<OrganisationUpcomingInvoice>(path);
}

export function createOrganisationTeam(
  id: string,
  payload: CreateOrganisationTeamPayload,
) {
  const path = `/organisation/${id}/team`;
  return post<void>(path, payload);
}
