import { type SupportedLang } from "@ctc/types";
import { Public } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@mui/material";
import {
  bindPopover,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { NavButton } from "~/components/nav/NavButton";
import { DropdownIcon } from "~/components/ui/Icons";
import { BootstrapQueryParameter } from "~/constants/enums";
import { useDesign } from "~/hooks/useTheme";

import {
  updateLanguage,
  useLang,
  useLanguageOptions,
  useLanguagePreference,
} from "../../redux/lang";

export function LanguageButton() {
  const { tokens } = useDesign();
  const lang = useLang();
  const languagePreference = useLanguagePreference();
  const languageOptions = useLanguageOptions() || [];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "account-popup",
  });

  const handleClick = (language: SupportedLang) => {
    dispatch(updateLanguage(language));
    popupState.close();
    // remove lang from the url if it doesn't match the chosen lang
    const queryParamsCopy = new URLSearchParams(searchParams.toString());
    if (
      queryParamsCopy.has(BootstrapQueryParameter.Lang) &&
      queryParamsCopy.get(BootstrapQueryParameter.Lang) !== language
    ) {
      queryParamsCopy.delete(BootstrapQueryParameter.Lang);
      navigate(
        {
          search: queryParamsCopy.toString(),
        },
        { replace: true },
      );
    }
  };

  const getButtonColor = () => {
    if (popupState.isOpen) {
      return tokens.icon.brand;
    }

    return tokens.text.low;
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        popupState.close();
      }}
    >
      <div>
        <NavButton
          {...bindToggle(popupState)}
          endIcon={<DropdownIcon />}
          style={{
            color: getButtonColor(),
          }}
        >
          <Public />
        </NavButton>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
        >
          <Box>
            <List>
              {languageOptions.map((language, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => {
                    handleClick(language);
                  }}
                >
                  <ListItemText
                    primary={lang.supportedLanguages[language]}
                    primaryTypographyProps={{
                      align: "left",
                      color:
                        language === languagePreference
                          ? "primary"
                          : "textPrimary",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Popover>
      </div>
    </ClickAwayListener>
  );
}
