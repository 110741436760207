import { type Trade } from "@ctc/types";

import { ConfirmationUI } from "~/components/transactions/command-palette/CommandPaletteConfirmation";
import { BulkEditOptions } from "~/components/transactions/command-palette/enums";
import { useBulkEditSuccess } from "~/components/transactions/command-palette/hooks/useBulkEditSuccess";
import { useBulkFilter } from "~/components/transactions/command-palette/hooks/useBulkFilter";
import { LoanAccountTopSection } from "~/components/transactions/command-palette/views/change-loan-account/LoanAccountTopSection";
import { invariant } from "~/lib/invariant";
import { type ErpAccount } from "~/redux/types";
import { useNewBulkOpMutation } from "~/state/actions";
import { BulkOperations } from "~/types/enums";

export const Confirmation = ({
  tradeType,
  erpLoanAccount,
}: {
  tradeType: Trade;
  erpLoanAccount: ErpAccount;
}) => {
  const filter = useBulkFilter();
  invariant(filter, "Must have a filter");

  const bulkOp = useNewBulkOpMutation();
  const onSuccess = useBulkEditSuccess({
    bulkEditOption: BulkEditOptions.ChangeLoanAccount,
  });

  const onConfirm = () => {
    bulkOp.mutate(
      {
        filter,
        operation: {
          type: BulkOperations.ChangeErpLoanAccount,
          erpAccountCode: erpLoanAccount.code,
          tradeType,
        },
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <ConfirmationUI
      topArea={
        <LoanAccountTopSection
          tradeType={tradeType}
          loanAccount={erpLoanAccount}
        />
      }
      onConfirm={onConfirm}
      loading={bulkOp.isPending}
    />
  );
};
