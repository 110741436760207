import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tooltip,
  type TooltipProps,
  Typography,
} from "@mui/material";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

export const PlanLabel = ({
  tooltip,
  label,
  isLink,
  placement = "right",
  isLegacyPlan,
}: {
  tooltip?: string;
  label: string;
  isLink?: boolean;
  placement?: TooltipProps["placement"];
  isLegacyPlan?: boolean;
}) => {
  const { tokens } = useDesign();
  const lang = useLang();

  if (isLegacyPlan) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Typography
          variant="Metropolis/Body/Bold"
          sx={{ color: tokens.text.default, fontWeight: 700 }}
        >
          {label}
        </Typography>
        <Tooltip title={lang.legacyPlanTooltip} placement="top">
          <IconButton size="small">
            <InfoOutlined
              fontSize="small"
              sx={{
                color: tokens.icon.low,
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip} placement={placement}>
          <Typography
            variant="Metropolis/Body/Bold"
            sx={{
              color: tokens.text.default,
              fontWeight: 700,
              borderBottom: `1px dashed ${tokens.text.default}`,
              "&:hover": {
                borderColor: isLink ? tokens.text.brand : tokens.text.default,
                color: isLink ? tokens.text.brand : tokens.text.default,
              },
            }}
          >
            {label}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          variant="Metropolis/Body/Bold"
          style={{
            color: tokens.text.default,
            fontWeight: 700,
          }}
        >
          {label}
        </Typography>
      )}
    </>
  );
};
