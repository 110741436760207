import { useSideNavWidth } from "~/components/nav/SideNav";
import { TX_TABLE_SCREEN_WIDTH } from "~/components/transactions/styles";

/**
 * Returns the screen width in pixels where the table stops using overflow auto
 * to scroll the content.
 * After this screen width, the table does not have a horizontal scroll
 * A larger width is required if you have the side nav turned on.
 * @returns
 */
export function useMinScreenWidthForTableContent() {
  const sideNavWidth = useSideNavWidth();
  // The point where the table will stop scrolling
  // You need enough room for the table + the side nav (if its on)
  const scrollPoint = TX_TABLE_SCREEN_WIDTH + sideNavWidth;

  return scrollPoint;
}
