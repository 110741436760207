/*
 * DO NOT EDIT THIS FILE MANUALLY
 * IT IS AUTOMATICALLY GENERATED AND CHANGES WILL BE OVERWRITTEN
 * SEE `scripts/compilerTranslations.ts` FOR MORE INFO
 */

import lang from "./it.js";
type Arg = string | number | boolean;
export default {
  accept: lang["accept"](),
  acceptTest: lang["acceptTest"](),
  accountant: {
    addClient: lang["accountant"]["addClient"](),
    addClientError: lang["accountant"]["addClientError"](),
    billingOwner: {
      accountant: lang["accountant"]["billingOwner"]["accountant"](),
      client: lang["accountant"]["billingOwner"]["client"](),
    },
    billingPage: {
      failed: lang["accountant"]["billingPage"]["failed"](),
      paid: lang["accountant"]["billingPage"]["paid"](),
    },
    clientDefault: lang["accountant"]["clientDefault"](),
    clientInListNotFound: lang["accountant"]["clientInListNotFound"](),
    clientListHeader: {
      billingDate: lang["accountant"]["clientListHeader"]["billingDate"](),
      dateAdded: lang["accountant"]["clientListHeader"]["dateAdded"](),
      email: lang["accountant"]["clientListHeader"]["email"](),
      name: lang["accountant"]["clientListHeader"]["name"](),
      plan: lang["accountant"]["clientListHeader"]["plan"](),
      status: lang["accountant"]["clientListHeader"]["status"](),
    },
    clientTable: {
      billingTooltip: lang["accountant"]["clientTable"]["billingTooltip"](),
      clearFilters: lang["accountant"]["clientTable"]["clearFilters"](),
      headers: {
        billingDate:
          lang["accountant"]["clientTable"]["headers"]["billingDate"](),
        dateAdded: lang["accountant"]["clientTable"]["headers"]["dateAdded"](),
        name: lang["accountant"]["clientTable"]["headers"]["name"](),
        plan: lang["accountant"]["clientTable"]["headers"]["plan"](),
        subscriber:
          lang["accountant"]["clientTable"]["headers"]["subscriber"](),
        transaction:
          lang["accountant"]["clientTable"]["headers"]["transaction"](),
      },
      invitePending: lang["accountant"]["clientTable"]["invitePending"](),
      invited: lang["accountant"]["clientTable"]["invited"](),
      newClient: lang["accountant"]["clientTable"]["newClient"](),
      noClients: lang["accountant"]["clientTable"]["noClients"](),
      noClientsMatchFilter:
        lang["accountant"]["clientTable"]["noClientsMatchFilter"](),
      view: lang["accountant"]["clientTable"]["view"](),
    },
    clientVersionNotFound: lang["accountant"]["clientVersionNotFound"](),
    defaultAccount: lang["accountant"]["defaultAccount"](),
    emptyState: {
      body: lang["accountant"]["emptyState"]["body"](),
      title: lang["accountant"]["emptyState"]["title"](),
    },
    errorLoadingClient: lang["accountant"]["errorLoadingClient"](),
    invite: {
      emailInvalid: lang["accountant"]["invite"]["emailInvalid"](),
      emailRequired: lang["accountant"]["invite"]["emailRequired"](),
      fromName: lang["accountant"]["invite"]["fromName"](),
      fromNamePlaceholder:
        lang["accountant"]["invite"]["fromNamePlaceholder"](),
      invite: lang["accountant"]["invite"]["invite"](),
      inviteAccountant: lang["accountant"]["invite"]["inviteAccountant"](),
      inviteWarning: lang["accountant"]["invite"]["inviteWarning"](),
      nameInvalid: lang["accountant"]["invite"]["nameInvalid"](),
      nameRequired: lang["accountant"]["invite"]["nameRequired"](),
      toEmail: lang["accountant"]["invite"]["toEmail"](),
      toEmailPlaceholder: lang["accountant"]["invite"]["toEmailPlaceholder"](),
    },
    loadingClients: lang["accountant"]["loadingClients"](),
    maxClientLimit: {
      text: lang["accountant"]["maxClientLimit"]["text"](),
      title: lang["accountant"]["maxClientLimit"]["title"](),
    },
    mostPopularPlanText: lang["accountant"]["mostPopularPlanText"](),
    moveAccountSettings: {
      body: lang["accountant"]["moveAccountSettings"]["body"](),
      title: lang["accountant"]["moveAccountSettings"]["title"](),
    },
    newClient: {
      buttonText: (args: { isSaving: Arg }): string =>
        lang["accountant"]["newClient"]["buttonText"](args) as string,
      clientType: {
        new: lang["accountant"]["newClient"]["clientType"]["new"](),
        title: lang["accountant"]["newClient"]["clientType"]["title"](),
        type: {
          business:
            lang["accountant"]["newClient"]["clientType"]["type"]["business"](),
          individual:
            lang["accountant"]["newClient"]["clientType"]["type"][
              "individual"
            ](),
        },
      },
      error: {
        invalidName: (args: { name: Arg }): string =>
          lang["accountant"]["newClient"]["error"]["invalidName"](
            args,
          ) as string,
        missingCountry:
          lang["accountant"]["newClient"]["error"]["missingCountry"](),
        missingName: lang["accountant"]["newClient"]["error"]["missingName"](),
      },
      form: {
        email: {
          helperText:
            lang["accountant"]["newClient"]["form"]["email"]["helperText"](),
          label: lang["accountant"]["newClient"]["form"]["email"]["label"](),
        },
        name: {
          business: {
            helperText:
              lang["accountant"]["newClient"]["form"]["name"]["business"][
                "helperText"
              ](),
            label:
              lang["accountant"]["newClient"]["form"]["name"]["business"][
                "label"
              ](),
          },
          individual: {
            helperText:
              lang["accountant"]["newClient"]["form"]["name"]["individual"][
                "helperText"
              ](),
            label:
              lang["accountant"]["newClient"]["form"]["name"]["individual"][
                "label"
              ](),
          },
        },
      },
      planCard: {
        aYear: lang["accountant"]["newClient"]["planCard"]["aYear"](),
        creditsAvailable: (args: { credits: Arg; singleCredit: Arg }): string =>
          lang["accountant"]["newClient"]["planCard"]["creditsAvailable"](
            args,
          ) as string,
        free: lang["accountant"]["newClient"]["planCard"]["free"](),
        nextYear: (args: { price: Arg }): string =>
          lang["accountant"]["newClient"]["planCard"]["nextYear"](
            args,
          ) as string,
      },
      section1: {
        field1: lang["accountant"]["newClient"]["section1"]["field1"](),
        field2: lang["accountant"]["newClient"]["section1"]["field2"](),
        field3: lang["accountant"]["newClient"]["section1"]["field3"](),
        title: lang["accountant"]["newClient"]["section1"]["title"](),
      },
      section2: {
        title: lang["accountant"]["newClient"]["section2"]["title"](),
      },
      section3: {
        body1: lang["accountant"]["newClient"]["section3"]["body1"](),
        body2: lang["accountant"]["newClient"]["section3"]["body2"](),
        title: lang["accountant"]["newClient"]["section3"]["title"](),
      },
      success: lang["accountant"]["newClient"]["success"](),
      title: lang["accountant"]["newClient"]["title"](),
    },
    noClientFound: lang["accountant"]["noClientFound"](),
    noClientSelected: lang["accountant"]["noClientSelected"](),
    or: lang["accountant"]["or"](),
    savingClient: lang["accountant"]["savingClient"](),
    subscriber: {
      accountant: lang["accountant"]["subscriber"]["accountant"](),
      both: lang["accountant"]["subscriber"]["both"](),
      bothCTA: lang["accountant"]["subscriber"]["bothCTA"](),
      client: lang["accountant"]["subscriber"]["client"](),
      free: lang["accountant"]["subscriber"]["free"](),
      header: lang["accountant"]["subscriber"]["header"](),
      nobody: lang["accountant"]["subscriber"]["nobody"](),
      tooltip: lang["accountant"]["subscriber"]["tooltip"](),
    },
    upgradeClient: {
      cancel: lang["accountant"]["upgradeClient"]["cancel"](),
      clientName: lang["accountant"]["upgradeClient"]["clientName"](),
      failMsg: lang["accountant"]["upgradeClient"]["failMsg"](),
      nextStepButtonText:
        lang["accountant"]["upgradeClient"]["nextStepButtonText"](),
      payAmountAndUpgrade: (args: { amount: Arg }): string =>
        lang["accountant"]["upgradeClient"]["payAmountAndUpgrade"](
          args,
        ) as string,
      payAndUpgrade: lang["accountant"]["upgradeClient"]["payAndUpgrade"](),
      payment: {
        defaultErrorMsg:
          lang["accountant"]["upgradeClient"]["payment"]["defaultErrorMsg"](),
        detail1: lang["accountant"]["upgradeClient"]["payment"]["detail1"](),
        detail2: lang["accountant"]["upgradeClient"]["payment"]["detail2"](),
        noneRequired:
          lang["accountant"]["upgradeClient"]["payment"]["noneRequired"](),
        title: lang["accountant"]["upgradeClient"]["payment"]["title"](),
      },
      renewalErrorBanner: {
        body: lang["accountant"]["upgradeClient"]["renewalErrorBanner"][
          "body"
        ](),
        buttonText:
          lang["accountant"]["upgradeClient"]["renewalErrorBanner"][
            "buttonText"
          ](),
        title:
          lang["accountant"]["upgradeClient"]["renewalErrorBanner"]["title"](),
      },
      selectAPlan: lang["accountant"]["upgradeClient"]["selectAPlan"](),
      stepLabels: {
        payment: lang["accountant"]["upgradeClient"]["stepLabels"]["payment"](),
        selectPlan:
          lang["accountant"]["upgradeClient"]["stepLabels"]["selectPlan"](),
      },
      title: lang["accountant"]["upgradeClient"]["title"](),
      upgrade: lang["accountant"]["upgradeClient"]["upgrade"](),
    },
  },
  accountantPortal: {
    createAccount: lang["accountantPortal"]["createAccount"](),
    dialogLabel: lang["accountantPortal"]["dialogLabel"](),
    errorMessage: lang["accountantPortal"]["errorMessage"](),
    paymentCtaPrefix: lang["accountantPortal"]["paymentCtaPrefix"](),
    steps: {
      accountantInfo: {
        accountantCompanyDescription:
          lang["accountantPortal"]["steps"]["accountantInfo"][
            "accountantCompanyDescription"
          ](),
        continue:
          lang["accountantPortal"]["steps"]["accountantInfo"]["continue"](),
        nameDescription:
          lang["accountantPortal"]["steps"]["accountantInfo"][
            "nameDescription"
          ](),
        required:
          lang["accountantPortal"]["steps"]["accountantInfo"]["required"](),
      },
      payment: {
        cancelAnyTimeText:
          lang["accountantPortal"]["steps"]["payment"]["cancelAnyTimeText"](),
        cardDetailsLabel:
          lang["accountantPortal"]["steps"]["payment"]["cardDetailsLabel"](),
        couponText:
          lang["accountantPortal"]["steps"]["payment"]["couponText"](),
        discountAppliedText:
          lang["accountantPortal"]["steps"]["payment"]["discountAppliedText"](),
        discountDescription1:
          lang["accountantPortal"]["steps"]["payment"][
            "discountDescription1"
          ](),
        discountDescription2:
          lang["accountantPortal"]["steps"]["payment"][
            "discountDescription2"
          ](),
        discountDescription3:
          lang["accountantPortal"]["steps"]["payment"][
            "discountDescription3"
          ](),
        label: lang["accountantPortal"]["steps"]["payment"]["label"](),
        paymentDescription1:
          lang["accountantPortal"]["steps"]["payment"]["paymentDescription1"](),
        paymentDescription2:
          lang["accountantPortal"]["steps"]["payment"]["paymentDescription2"](),
        planTextSuffix:
          lang["accountantPortal"]["steps"]["payment"]["planTextSuffix"](),
        submitButtonSuffix:
          lang["accountantPortal"]["steps"]["payment"]["submitButtonSuffix"](),
        subtotalText:
          lang["accountantPortal"]["steps"]["payment"]["subtotalText"](),
        title: lang["accountantPortal"]["steps"]["payment"]["title"](),
        yearlySubscriptionText:
          lang["accountantPortal"]["steps"]["payment"][
            "yearlySubscriptionText"
          ](),
      },
      welcome: {
        info: {
          switching: {
            subtitle:
              lang["accountantPortal"]["steps"]["welcome"]["info"]["switching"][
                "subtitle"
              ](),
            title:
              lang["accountantPortal"]["steps"]["welcome"]["info"]["switching"][
                "title"
              ](),
          },
          transactions: {
            subtitle:
              lang["accountantPortal"]["steps"]["welcome"]["info"][
                "transactions"
              ]["subtitle"](),
            title:
              lang["accountantPortal"]["steps"]["welcome"]["info"][
                "transactions"
              ]["title"](),
          },
        },
      },
    },
    subtitle: lang["accountantPortal"]["subtitle"](),
    title: lang["accountantPortal"]["title"](),
  },
  accountingIntegrations: {
    connectAccountingSoftware:
      lang["accountingIntegrations"]["connectAccountingSoftware"](),
    connectIntegration: {
      connected: {
        connected:
          lang["accountingIntegrations"]["connectIntegration"]["connected"][
            "connected"
          ](),
        connectedAt: (args: { time: Arg }): string =>
          lang["accountingIntegrations"]["connectIntegration"]["connected"][
            "connectedAt"
          ](args) as string,
        disconnect:
          lang["accountingIntegrations"]["connectIntegration"]["connected"][
            "disconnect"
          ](),
        disconnectWarning: {
          actionText:
            lang["accountingIntegrations"]["connectIntegration"]["connected"][
              "disconnectWarning"
            ]["actionText"](),
          alert:
            lang["accountingIntegrations"]["connectIntegration"]["connected"][
              "disconnectWarning"
            ]["alert"](),
          body: (args: { erp: Arg }): string =>
            lang["accountingIntegrations"]["connectIntegration"]["connected"][
              "disconnectWarning"
            ]["body"](args) as string,
          title: (args: { erp: Arg }): string =>
            lang["accountingIntegrations"]["connectIntegration"]["connected"][
              "disconnectWarning"
            ]["title"](args) as string,
        },
        selectTenant:
          lang["accountingIntegrations"]["connectIntegration"]["connected"][
            "selectTenant"
          ](),
      },
      disconnected: {
        title:
          lang["accountingIntegrations"]["connectIntegration"]["disconnected"][
            "title"
          ](),
      },
    },
    defaults: {
      accountMapping: {
        asset: {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "asset"
            ]["title"](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "asset"
            ]["tooltip"](),
        },
        cash: {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "cash"
            ]["title"](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "cash"
            ]["tooltip"](),
        },
        expense: {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "expense"
            ]["title"](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "expense"
            ]["tooltip"](),
        },
        fee: {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"]["fee"][
              "title"
            ](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"]["fee"][
              "tooltip"
            ](),
        },
        income: {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "income"
            ]["title"](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "income"
            ]["tooltip"](),
        },
        loan: {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "loan"
            ]["title"](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "loan"
            ]["tooltip"](),
        },
        loss: {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "loss"
            ]["title"](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "loss"
            ]["tooltip"](),
        },
        "realised-gain": {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "realised-gain"
            ]["title"](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "realised-gain"
            ]["tooltip"](),
        },
        "realised-loss": {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "realised-loss"
            ]["title"](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "realised-loss"
            ]["tooltip"](),
        },
        "zero-cost-buy": {
          title:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "zero-cost-buy"
            ]["title"](),
          tooltip:
            lang["accountingIntegrations"]["defaults"]["accountMapping"][
              "zero-cost-buy"
            ]["tooltip"](),
        },
      },
      empty: lang["accountingIntegrations"]["defaults"]["empty"](),
      mapped: lang["accountingIntegrations"]["defaults"]["mapped"](),
      required: lang["accountingIntegrations"]["defaults"]["required"](),
      selectorPlaceholder: (args: { account: Arg; erp: Arg }): string =>
        lang["accountingIntegrations"]["defaults"]["selectorPlaceholder"](
          args,
        ) as string,
      title: lang["accountingIntegrations"]["defaults"]["title"](),
      unmapped: lang["accountingIntegrations"]["defaults"]["unmapped"](),
    },
    integrations: lang["accountingIntegrations"]["integrations"](),
    rollupSettings: {
      options: {
        day: lang["accountingIntegrations"]["rollupSettings"]["options"][
          "day"
        ](),
        month:
          lang["accountingIntegrations"]["rollupSettings"]["options"][
            "month"
          ](),
        none: lang["accountingIntegrations"]["rollupSettings"]["options"][
          "none"
        ](),
      },
      placeholder:
        lang["accountingIntegrations"]["rollupSettings"]["placeholder"](),
      subtitle: lang["accountingIntegrations"]["rollupSettings"]["subtitle"](),
      title: lang["accountingIntegrations"]["rollupSettings"]["title"](),
    },
    rules: lang["accountingIntegrations"]["rules"](),
    subtitle: (args: { erp: Arg }): string =>
      lang["accountingIntegrations"]["subtitle"](args) as string,
    subtitleWithoutERP: lang["accountingIntegrations"]["subtitleWithoutERP"](),
    title: lang["accountingIntegrations"]["title"](),
    warningSettings: {
      label: lang["accountingIntegrations"]["warningSettings"]["label"](),
      subtitle: (args: { erp: Arg }): string =>
        lang["accountingIntegrations"]["warningSettings"]["subtitle"](
          args,
        ) as string,
      title: lang["accountingIntegrations"]["warningSettings"]["title"](),
    },
  },
  accountingSteps: {
    body: lang["accountingSteps"]["body"](),
    steps: [
      {
        body: lang["accountingSteps"]["steps"][0]["body"](),
        title: lang["accountingSteps"]["steps"][0]["title"](),
      },
      {
        body: lang["accountingSteps"]["steps"][1]["body"](),
        title: lang["accountingSteps"]["steps"][1]["title"](),
      },
    ],
    title: lang["accountingSteps"]["title"](),
  },
  add: lang["add"](),
  addManualTX: {
    currencyPaidIn: lang["addManualTX"]["currencyPaidIn"](),
    currencyPlaceholder: lang["addManualTX"]["currencyPlaceholder"](),
    error: lang["addManualTX"]["error"](),
    exchangeName: lang["addManualTX"]["exchangeName"](),
    feePaidIn: lang["addManualTX"]["feePaidIn"](),
    in: lang["addManualTX"]["in"](),
    mustDestination: lang["addManualTX"]["mustDestination"](),
    mustEnterExchange: lang["addManualTX"]["mustEnterExchange"](),
    mustSource: lang["addManualTX"]["mustSource"](),
    optional: lang["addManualTX"]["optional"](),
    paidInFees: lang["addManualTX"]["paidInFees"](),
    priceForOne: lang["addManualTX"]["priceForOne"](),
    quotePlaceholder: lang["addManualTX"]["quotePlaceholder"](),
    saving: lang["addManualTX"]["saving"](),
    success: lang["addManualTX"]["success"](),
    totalQuantityOf: lang["addManualTX"]["totalQuantityOf"](),
    totalValueIn: lang["addManualTX"]["totalValueIn"](),
    viewTransaction: lang["addManualTX"]["viewTransaction"](),
    whereDestination: lang["addManualTX"]["whereDestination"](),
    whereFrom: lang["addManualTX"]["whereFrom"](),
    whereSource: lang["addManualTX"]["whereSource"](),
    whereTo: lang["addManualTX"]["whereTo"](),
    whichCategory: lang["addManualTX"]["whichCategory"](),
  },
  adjustedPrice: {
    considersWashSalesDescription:
      lang["adjustedPrice"]["considersWashSalesDescription"](),
    description: lang["adjustedPrice"]["description"](),
  },
  advanced: lang["advanced"](),
  advancedTransactionOptions: {
    findCategory: lang["advancedTransactionOptions"]["findCategory"](),
    grouped: {
      description:
        lang["advancedTransactionOptions"]["grouped"]["description"](),
      title: lang["advancedTransactionOptions"]["grouped"]["title"](),
    },
    in: {
      categoryGuide:
        lang["advancedTransactionOptions"]["in"]["categoryGuide"](),
      description: lang["advancedTransactionOptions"]["in"]["description"](),
      incorrectAction:
        lang["advancedTransactionOptions"]["in"]["incorrectAction"](),
      incorrectPrefix:
        lang["advancedTransactionOptions"]["in"]["incorrectPrefix"](),
      inputGroup: lang["advancedTransactionOptions"]["in"]["inputGroup"](),
      title: lang["advancedTransactionOptions"]["in"]["title"](),
    },
    out: {
      categoryGuide:
        lang["advancedTransactionOptions"]["out"]["categoryGuide"](),
      description: lang["advancedTransactionOptions"]["out"]["description"](),
      incorrectAction:
        lang["advancedTransactionOptions"]["out"]["incorrectAction"](),
      incorrectPrefix:
        lang["advancedTransactionOptions"]["out"]["incorrectPrefix"](),
      inputGroup: lang["advancedTransactionOptions"]["out"]["inputGroup"](),
      title: lang["advancedTransactionOptions"]["out"]["title"](),
    },
  },
  alerts: {
    customCSV: lang["alerts"]["customCSV"](),
    noCookie: lang["alerts"]["noCookie"](),
  },
  all: lang["all"](),
  allPlans: lang["allPlans"](),
  api: {
    addressesIdentified: (args: { exchangeName: Arg }): string =>
      lang["api"]["addressesIdentified"](args) as string,
    apiKeyLabel: lang["api"]["apiKeyLabel"](),
    connect: lang["api"]["connect"](),
    delete: lang["api"]["delete"](),
    deleteConfirmationText: (args: { exchangeName: Arg; api: Arg }): string =>
      lang["api"]["deleteConfirmationText"](args) as string,
    disabledExplanation: lang["api"]["disabledExplanation"](),
    edit: lang["api"]["edit"](),
    editNickname: lang["api"]["editNickname"](),
    editingSubmit: lang["api"]["editingSubmit"](),
    exchanges: {
      binance: {
        crossMarginCurrencies:
          lang["api"]["exchanges"]["binance"]["crossMarginCurrencies"](),
        syncMarginTickers:
          lang["api"]["exchanges"]["binance"]["syncMarginTickers"](),
        syncMarginTickersExplanation:
          lang["api"]["exchanges"]["binance"]["syncMarginTickersExplanation"](),
      },
    },
    existingSubmit: (args: { source: Arg }): string =>
      lang["api"]["existingSubmit"](args) as string,
    hardSync: {
      confirmationAllAPIs: (args: { name: Arg }): string =>
        lang["api"]["hardSync"]["confirmationAllAPIs"](args) as string,
      confirmationOneAPI: lang["api"]["hardSync"]["confirmationOneAPI"](),
      textOneAPI: (args: { name: Arg; date: Arg }): string =>
        lang["api"]["hardSync"]["textOneAPI"](args) as string,
      titleAllChipAPIs: lang["api"]["hardSync"]["titleAllChipAPIs"](),
      titleOneAPI: (args: { date: Arg; name: Arg }): string =>
        lang["api"]["hardSync"]["titleOneAPI"](args) as string,
    },
    import: lang["api"]["import"](),
    importRules: {
      existingApi: lang["api"]["importRules"]["existingApi"](),
      missingApi: lang["api"]["importRules"]["missingApi"](),
      missingPassphrase: lang["api"]["importRules"]["missingPassphrase"](),
      missingSecret: lang["api"]["importRules"]["missingSecret"](),
      missingUid: lang["api"]["importRules"]["missingUid"](),
      multipleKeyError: (args: { name: Arg }): string =>
        lang["api"]["importRules"]["multipleKeyError"](args) as string,
      uidHint: lang["api"]["importRules"]["uidHint"](),
    },
    maxTickerLabel: lang["api"]["maxTickerLabel"](),
    missingTickerAlert: (args: { exchangeName: Arg }): string =>
      lang["api"]["missingTickerAlert"](args) as string,
    name: lang["api"]["name"](),
    nickname: lang["api"]["nickname"](),
    passwordLabel: lang["api"]["passwordLabel"](),
    reauthorize: lang["api"]["reauthorize"](),
    resync: lang["api"]["resync"](),
    secretLabel: lang["api"]["secretLabel"](),
    softSync: (args: { name: Arg }): string =>
      lang["api"]["softSync"](args) as string,
    submit: (args: { source: Arg }): string =>
      lang["api"]["submit"](args) as string,
    sync: lang["api"]["sync"](),
    syncing: lang["api"]["syncing"](),
    tickerLabel: lang["api"]["tickerLabel"](),
    uidLabel: lang["api"]["uidLabel"](),
    update: lang["api"]["update"](),
  },
  apply: lang["apply"](),
  auth: {
    acceptTermsToContinue: lang["auth"]["acceptTermsToContinue"](),
    accountantClient: lang["auth"]["accountantClient"](),
    accountantCompanyName: lang["auth"]["accountantCompanyName"](),
    accountantContactName: lang["auth"]["accountantContactName"](),
    accountantSignUp: lang["auth"]["accountantSignUp"](),
    accountantSignupSubTitle: lang["auth"]["accountantSignupSubTitle"](),
    accountantSignupTitle: lang["auth"]["accountantSignupTitle"](),
    accountantWorkEmail: lang["auth"]["accountantWorkEmail"](),
    alreadyAccount: lang["auth"]["alreadyAccount"](),
    and: lang["auth"]["and"](),
    areAccountant: lang["auth"]["areAccountant"](),
    backTo: lang["auth"]["backTo"](),
    bitcoinDotComAu30Banner: lang["auth"]["bitcoinDotComAu30Banner"](),
    breaker: lang["auth"]["breaker"](),
    captcha: {
      and: lang["auth"]["captcha"]["and"](),
      apply: lang["auth"]["captcha"]["apply"](),
      privacyPolicy: lang["auth"]["captcha"]["privacyPolicy"](),
      protectedBy: lang["auth"]["captcha"]["protectedBy"](),
      termsOfService: lang["auth"]["captcha"]["termsOfService"](),
      verificationFailed: lang["auth"]["captcha"]["verificationFailed"](),
    },
    coinbase: lang["auth"]["coinbase"](),
    coinbase30Banner: lang["auth"]["coinbase30Banner"](),
    coinbaseOne1DollarBanner: (args: { rookiePlanPrice: Arg }): string =>
      lang["auth"]["coinbaseOne1DollarBanner"](args) as string,
    coinbaseOne1DollarBannerNoCurrency:
      lang["auth"]["coinbaseOne1DollarBannerNoCurrency"](),
    coinbaseOne40Banner: lang["auth"]["coinbaseOne40Banner"](),
    coinbaseOnePremium40Banner: lang["auth"]["coinbaseOnePremium40Banner"](),
    coinjar30Banner: lang["auth"]["coinjar30Banner"](),
    coinstatsBanner: lang["auth"]["coinstatsBanner"](),
    compliance: lang["auth"]["compliance"](),
    countryWarningBody: lang["auth"]["countryWarningBody"](),
    countryWarningTitle: lang["auth"]["countryWarningTitle"](),
    customerNum: lang["auth"]["customerNum"](),
    differentSignUpMethod: lang["auth"]["differentSignUpMethod"](),
    email: lang["auth"]["email"](),
    embedded: {
      connectWith: (args: { provider: Arg }): string =>
        lang["auth"]["embedded"]["connectWith"](args) as string,
      signInWith: (args: { provider: Arg }): string =>
        lang["auth"]["embedded"]["signInWith"](args) as string,
      tagline: lang["auth"]["embedded"]["tagline"](),
      title: lang["auth"]["embedded"]["title"](),
    },
    errors: {
      invalidCode: lang["auth"]["errors"]["invalidCode"](),
      invalidCredentials: lang["auth"]["errors"]["invalidCredentials"](),
      invalidSignup: lang["auth"]["errors"]["invalidSignup"](),
    },
    forgot: lang["auth"]["forgot"](),
    forgotPassword: lang["auth"]["forgotPassword"](),
    goBack: lang["auth"]["goBack"](),
    google: lang["auth"]["google"](),
    iAgree: lang["auth"]["iAgree"](),
    independentReserve30Banner: lang["auth"]["independentReserve30Banner"](),
    invalidCombination: lang["auth"]["invalidCombination"](),
    invalidToken: lang["auth"]["invalidToken"](),
    inviteReceived: lang["auth"]["inviteReceived"](),
    login: lang["auth"]["login"](),
    loginPage: {
      oauth: {
        coinbase: lang["auth"]["loginPage"]["oauth"]["coinbase"](),
        google: lang["auth"]["loginPage"]["oauth"]["google"](),
        metamask: lang["auth"]["loginPage"]["oauth"]["metamask"](),
      },
      title: lang["auth"]["loginPage"]["title"](),
    },
    loginTitle: lang["auth"]["loginTitle"](),
    loginTitleEmbedded: lang["auth"]["loginTitleEmbedded"](),
    metamask: lang["auth"]["metamask"](),
    metamask30Banner: lang["auth"]["metamask30Banner"](),
    missingEmail: lang["auth"]["missingEmail"](),
    noAccount: lang["auth"]["noAccount"](),
    notAccountant: lang["auth"]["notAccountant"](),
    officialPartner: lang["auth"]["officialPartner"](),
    officialPartners: lang["auth"]["officialPartners"](),
    orLogin: lang["auth"]["orLogin"](),
    orSignup: lang["auth"]["orSignup"](),
    orSwitchTo: lang["auth"]["orSwitchTo"](),
    password: lang["auth"]["password"](),
    passwordCondition: lang["auth"]["passwordCondition"](),
    passwordResetButton: lang["auth"]["passwordResetButton"](),
    passwordResetFailed: lang["auth"]["passwordResetFailed"](),
    passwordResetInstructions: lang["auth"]["passwordResetInstructions"](),
    passwordResetSuccessfully: lang["auth"]["passwordResetSuccessfully"](),
    passwordUpdatedSuccessfully: lang["auth"]["passwordUpdatedSuccessfully"](),
    privacyPolicy: lang["auth"]["privacyPolicy"](),
    recaptcha: {
      part1: lang["auth"]["recaptcha"]["part1"](),
      part2: lang["auth"]["recaptcha"]["part2"](),
      part3: lang["auth"]["recaptcha"]["part3"](),
    },
    recogniseBody: lang["auth"]["recogniseBody"](),
    recogniseTitle: lang["auth"]["recogniseTitle"](),
    resetEmailSent: (args: { email: Arg }): string =>
      lang["auth"]["resetEmailSent"](args) as string,
    resetPasswordSubTitle: lang["auth"]["resetPasswordSubTitle"](),
    resetPasswordTitle: lang["auth"]["resetPasswordTitle"](),
    setPasswordTitle: lang["auth"]["setPasswordTitle"](),
    signInWith: lang["auth"]["signInWith"](),
    signin: lang["auth"]["signin"](),
    signup: lang["auth"]["signup"](),
    signupPage: {
      accurateCryptoTaxes: lang["auth"]["signupPage"]["accurateCryptoTaxes"](),
      mobileTitle: lang["auth"]["signupPage"]["mobileTitle"](),
      noGuesswork: lang["auth"]["signupPage"]["noGuesswork"](),
      oauth: {
        coinbase: lang["auth"]["signupPage"]["oauth"]["coinbase"](),
        google: lang["auth"]["signupPage"]["oauth"]["google"](),
        metamask: lang["auth"]["signupPage"]["oauth"]["metamask"](),
      },
      terms: {
        and: lang["auth"]["signupPage"]["terms"]["and"](),
        body: lang["auth"]["signupPage"]["terms"]["body"](),
        privacyPolicy: lang["auth"]["signupPage"]["terms"]["privacyPolicy"](),
        termsNotAccepted:
          lang["auth"]["signupPage"]["terms"]["termsNotAccepted"](),
        termsOfService: lang["auth"]["signupPage"]["terms"]["termsOfService"](),
      },
      text: lang["auth"]["signupPage"]["text"](),
      title: lang["auth"]["signupPage"]["title"](),
      trustpilot: {
        reviews: lang["auth"]["signupPage"]["trustpilot"]["reviews"](),
        title: lang["auth"]["signupPage"]["trustpilot"]["title"](),
      },
    },
    signupPromoText: { phantom: lang["auth"]["signupPromoText"]["phantom"]() },
    signupSubTitle: lang["auth"]["signupSubTitle"](),
    signupTitle: lang["auth"]["signupTitle"](),
    somethingWentWrong: lang["auth"]["somethingWentWrong"](),
    strongPassword: lang["auth"]["strongPassword"](),
    termsAndConditions: lang["auth"]["termsAndConditions"](),
    toSignUp: lang["auth"]["toSignUp"](),
    tooManyPasswordResetAttempts:
      lang["auth"]["tooManyPasswordResetAttempts"](),
    transparentReports: lang["auth"]["transparentReports"](),
    trustPilotExcellent: lang["auth"]["trustPilotExcellent"](),
    trustedBy: lang["auth"]["trustedBy"](),
    unableToResetEmail: (args: { email: Arg }): string =>
      lang["auth"]["unableToResetEmail"](args) as string,
    unlock: lang["auth"]["unlock"](),
    userSignUp: lang["auth"]["userSignUp"](),
    worldwide: lang["auth"]["worldwide"](),
    xero: lang["auth"]["xero"](),
    yourAccountant: lang["auth"]["yourAccountant"](),
    youreInvited: lang["auth"]["youreInvited"](),
  },
  autoClose: { title: lang["autoClose"]["title"]() },
  back: lang["back"](),
  billing: {
    "44359": lang["billing"]["44359"](),
    "45732": lang["billing"]["45732"](),
    addCard: {
      cancel: lang["billing"]["addCard"]["cancel"](),
      description: lang["billing"]["addCard"]["description"](),
      loading: lang["billing"]["addCard"]["loading"](),
      submit: lang["billing"]["addCard"]["submit"](),
      title: lang["billing"]["addCard"]["title"](),
    },
    billingHistory: {
      table: {
        amount: lang["billing"]["billingHistory"]["table"]["amount"](),
        date: lang["billing"]["billingHistory"]["table"]["date"](),
        description:
          lang["billing"]["billingHistory"]["table"]["description"](),
      },
      title: lang["billing"]["billingHistory"]["title"](),
    },
    paymentFailed: lang["billing"]["paymentFailed"](),
    paymentFailedCode: (args: { code: Arg }): string =>
      lang["billing"]["paymentFailedCode"](args) as string,
    paymentMethod: {
      button: lang["billing"]["paymentMethod"]["button"](),
      ending: lang["billing"]["paymentMethod"]["ending"](),
      expired: lang["billing"]["paymentMethod"]["expired"](),
      expires: lang["billing"]["paymentMethod"]["expires"](),
      inUse: lang["billing"]["paymentMethod"]["inUse"](),
      title: lang["billing"]["paymentMethod"]["title"](),
    },
    paymentStatus: {
      failed: {
        description:
          lang["billing"]["paymentStatus"]["failed"]["description"](),
        title: lang["billing"]["paymentStatus"]["failed"]["title"](),
      },
    },
    somethingWentWrong: lang["billing"]["somethingWentWrong"](),
    stripe: {
      "44359": lang["billing"]["stripe"]["44359"](),
      "45732": lang["billing"]["stripe"]["45732"](),
      applied: lang["billing"]["stripe"]["applied"](),
      apply: lang["billing"]["stripe"]["apply"](),
      cardSuccessfullyAdded:
        lang["billing"]["stripe"]["cardSuccessfullyAdded"](),
      clear: lang["billing"]["stripe"]["clear"](),
      couponExpired: (args: { name: Arg }): string =>
        lang["billing"]["stripe"]["couponExpired"](args) as string,
      couponNotValid: (args: { name: Arg }): string =>
        lang["billing"]["stripe"]["couponNotValid"](args) as string,
      discountApplied: (args: { percent: Arg }): string =>
        lang["billing"]["stripe"]["discountApplied"](args) as string,
      failedToAddTheCard: lang["billing"]["stripe"]["failedToAddTheCard"](),
      noPortal: lang["billing"]["stripe"]["noPortal"](),
      noSession: lang["billing"]["stripe"]["noSession"](),
      noStripe: lang["billing"]["stripe"]["noStripe"](),
    },
    title: lang["billing"]["title"](),
    wentWrongBillingHistory: lang["billing"]["wentWrongBillingHistory"](),
    wentWrongCoupon: lang["billing"]["wentWrongCoupon"](),
    wentWrongPaymentMethods: lang["billing"]["wentWrongPaymentMethods"](),
  },
  bulkSelect: {
    moreActions: lang["bulkSelect"]["moreActions"](),
    recategoriseAs: lang["bulkSelect"]["recategoriseAs"](),
  },
  button: {
    accept: lang["button"]["accept"](),
    decline: lang["button"]["decline"](),
    pending: lang["button"]["pending"](),
  },
  calcoTour: {
    buttons: {
      back: lang["calcoTour"]["buttons"]["back"](),
      next: lang["calcoTour"]["buttons"]["next"](),
    },
    reconciliation: {
      uncategorise: {
        CTA: lang["calcoTour"]["reconciliation"]["uncategorise"]["CTA"](),
        addAnotherAccount:
          lang["calcoTour"]["reconciliation"]["uncategorise"][
            "addAnotherAccount"
          ](),
        associateTx: (args: { counterParty: Arg }): string =>
          lang["calcoTour"]["reconciliation"]["uncategorise"]["associateTx"](
            args,
          ) as string,
        categoriesButton:
          lang["calcoTour"]["reconciliation"]["uncategorise"][
            "categoriesButton"
          ](),
        exampleTx:
          lang["calcoTour"]["reconciliation"]["uncategorise"]["exampleTx"](),
        incomingWallet:
          lang["calcoTour"]["reconciliation"]["uncategorise"][
            "incomingWallet"
          ](),
        outgoingWallet:
          lang["calcoTour"]["reconciliation"]["uncategorise"][
            "outgoingWallet"
          ](),
        typeSelectorIncoming:
          lang["calcoTour"]["reconciliation"]["uncategorise"][
            "typeSelectorIncoming"
          ](),
        typeSelectorOutgoing:
          lang["calcoTour"]["reconciliation"]["uncategorise"][
            "typeSelectorOutgoing"
          ](),
      },
    },
  },
  cancel: lang["cancel"](),
  changeLang: {
    changeLang: lang["changeLang"]["changeLang"](),
    error: lang["changeLang"]["error"](),
    language: lang["changeLang"]["language"](),
    languages: lang["changeLang"]["languages"](),
    pleaseSelect: lang["changeLang"]["pleaseSelect"](),
  },
  clear: lang["clear"](),
  clientPays: {
    bannerText: lang["clientPays"]["bannerText"](),
    bannerTitle: lang["clientPays"]["bannerTitle"](),
    buttonText: lang["clientPays"]["buttonText"](),
  },
  clients: {
    cancelSub: {
      areYouSure: lang["clients"]["cancelSub"]["areYouSure"](),
      buttonText: lang["clients"]["cancelSub"]["buttonText"](),
      exit: lang["clients"]["cancelSub"]["exit"](),
    },
    clone: {
      clone: lang["clients"]["clone"]["clone"](),
      cloneClient: lang["clients"]["clone"]["cloneClient"](),
      clonesAccountantData: lang["clients"]["clone"]["clonesAccountantData"](),
    },
    delete: {
      accessOutcome: lang["clients"]["delete"]["accessOutcome"](),
      areYouSure: (args: { name: Arg }): string =>
        lang["clients"]["delete"]["areYouSure"](args) as string,
      cannotBeUndone: lang["clients"]["delete"]["cannotBeUndone"](),
      clientAccessOutcome: (args: { accountantOwnedData: Arg }): string =>
        lang["clients"]["delete"]["clientAccessOutcome"](args) as string,
      deleteButton: lang["clients"]["delete"]["deleteButton"](),
      deleteClient: lang["clients"]["delete"]["deleteClient"](),
    },
    invite: {
      emailRequired: lang["clients"]["invite"]["emailRequired"](),
      fromName: lang["clients"]["invite"]["fromName"](),
      fromNamePlaceholder: lang["clients"]["invite"]["fromNamePlaceholder"](),
      invite: lang["clients"]["invite"]["invite"](),
      inviteClient: lang["clients"]["invite"]["inviteClient"](),
      inviteWarning: lang["clients"]["invite"]["inviteWarning"](),
      nameRequired: lang["clients"]["invite"]["nameRequired"](),
      toEmail: lang["clients"]["invite"]["toEmail"](),
      toEmailPlaceholder: lang["clients"]["invite"]["toEmailPlaceholder"](),
    },
    migrate: {
      settingsBody: lang["clients"]["migrate"]["settingsBody"](),
      settingsTitle: lang["clients"]["migrate"]["settingsTitle"](),
    },
    newAccountInvite: {
      acceptFailed: lang["clients"]["newAccountInvite"]["acceptFailed"](),
      declineFailed: lang["clients"]["newAccountInvite"]["declineFailed"](),
      isNotActionable: lang["clients"]["newAccountInvite"]["isNotActionable"](),
      textAccountantOwns: (args: { email: Arg }): string =>
        lang["clients"]["newAccountInvite"]["textAccountantOwns"](
          args,
        ) as string,
      textClientOwns: (args: { email: Arg }): string =>
        lang["clients"]["newAccountInvite"]["textClientOwns"](args) as string,
      title: lang["clients"]["newAccountInvite"]["title"](),
    },
    reinvite: {
      emailRequired: lang["clients"]["reinvite"]["emailRequired"](),
      fromName: lang["clients"]["reinvite"]["fromName"](),
      fromNamePlaceholder: lang["clients"]["reinvite"]["fromNamePlaceholder"](),
      nameRequired: lang["clients"]["reinvite"]["nameRequired"](),
      reinvite: lang["clients"]["reinvite"]["reinvite"](),
      reinviteClient: lang["clients"]["reinvite"]["reinviteClient"](),
      reinviteWarning: lang["clients"]["reinvite"]["reinviteWarning"](),
      toEmail: lang["clients"]["reinvite"]["toEmail"](),
      toEmailPlaceholder: lang["clients"]["reinvite"]["toEmailPlaceholder"](),
    },
    settings: lang["clients"]["settings"](),
    uncancelSub: {
      areYouSure: lang["clients"]["uncancelSub"]["areYouSure"](),
      buttonText: lang["clients"]["uncancelSub"]["buttonText"](),
      exit: lang["clients"]["uncancelSub"]["exit"](),
    },
  },
  close: lang["close"](),
  collaborators: {
    cancelInvitation: lang["collaborators"]["cancelInvitation"](),
    cancelInvitationGenericError:
      lang["collaborators"]["cancelInvitationGenericError"](),
    cancelInvitationSuccess: lang["collaborators"]["cancelInvitationSuccess"](),
    email: lang["collaborators"]["email"](),
    invitationSent: lang["collaborators"]["invitationSent"](),
    inviteTeamMember: lang["collaborators"]["inviteTeamMember"](),
    inviteTeamMembers: {
      linkText: lang["collaborators"]["inviteTeamMembers"]["linkText"](),
      postLinkText:
        lang["collaborators"]["inviteTeamMembers"]["postLinkText"](),
    },
    name: lang["collaborators"]["name"](),
    pending: lang["collaborators"]["pending"](),
    reinvite: lang["collaborators"]["reinvite"](),
    reinviteSuccess: lang["collaborators"]["reinviteSuccess"](),
    revokeAccess: lang["collaborators"]["revokeAccess"](),
    revokeAccessSuccess: lang["collaborators"]["revokeAccessSuccess"](),
    send: lang["collaborators"]["send"](),
    team: lang["collaborators"]["team"](),
    teamMemberEmail: lang["collaborators"]["teamMemberEmail"](),
    teamMemberName: lang["collaborators"]["teamMemberName"](),
    teamMembers: lang["collaborators"]["teamMembers"](),
    teamMembersEmpty: lang["collaborators"]["teamMembersEmpty"](),
  },
  confirm: lang["confirm"](),
  contacts: {
    addAddress: {
      addAddress: lang["contacts"]["addAddress"]["addAddress"](),
      address: lang["contacts"]["addAddress"]["address"](),
      blockchain: lang["contacts"]["addAddress"]["blockchain"](),
      buttons: {
        add: lang["contacts"]["addAddress"]["buttons"]["add"](),
        cancel: lang["contacts"]["addAddress"]["buttons"]["cancel"](),
      },
      duplicateAddress: lang["contacts"]["addAddress"]["duplicateAddress"](),
      title: lang["contacts"]["addAddress"]["title"](),
    },
    addContact: lang["contacts"]["addContact"](),
    addressBook: lang["contacts"]["addressBook"](),
    contact: lang["contacts"]["contact"](),
    create: {
      addAddress: lang["contacts"]["create"]["addAddress"](),
      address: lang["contacts"]["create"]["address"](),
      alreadyExists: lang["contacts"]["create"]["alreadyExists"](),
      associatedAddresses: lang["contacts"]["create"]["associatedAddresses"](),
      blockchain: lang["contacts"]["create"]["blockchain"](),
      cancel: lang["contacts"]["create"]["cancel"](),
      createContact: lang["contacts"]["create"]["createContact"](),
      duplicateAddress: lang["contacts"]["create"]["duplicateAddress"](),
      name: lang["contacts"]["create"]["name"](),
      nameTitle: lang["contacts"]["create"]["nameTitle"](),
      title: lang["contacts"]["create"]["title"](),
    },
    csv: {
      errorFileNotCsv: lang["contacts"]["csv"]["errorFileNotCsv"](),
      exportCsv: lang["contacts"]["csv"]["exportCsv"](),
      importCsv: lang["contacts"]["csv"]["importCsv"](),
      success: (args: { number: Arg }): string =>
        lang["contacts"]["csv"]["success"](args) as string,
    },
    edit: {
      associatedAddresses: lang["contacts"]["edit"]["associatedAddresses"](),
      rules: lang["contacts"]["edit"]["rules"](),
    },
    entityType: {
      contract: lang["contacts"]["entityType"]["contract"](),
      imported: lang["contacts"]["entityType"]["imported"](),
      manual: lang["contacts"]["entityType"]["manual"](),
    },
    error: lang["contacts"]["error"](),
    findAddress: lang["contacts"]["findAddress"](),
    identified: lang["contacts"]["identified"](),
    identifiedTable: {
      allTooltip: lang["contacts"]["identifiedTable"]["allTooltip"](),
      asterisk: lang["contacts"]["identifiedTable"]["asterisk"](),
      networks: lang["contacts"]["identifiedTable"]["networks"](),
      rules: lang["contacts"]["identifiedTable"]["rules"](),
      rulesApplied: (args: { count: Arg }): string =>
        lang["contacts"]["identifiedTable"]["rulesApplied"](args) as string,
      setRule: lang["contacts"]["identifiedTable"]["setRule"](),
      transactions: lang["contacts"]["identifiedTable"]["transactions"](),
    },
    newContact: lang["contacts"]["newContact"](),
    noContacts: lang["contacts"]["noContacts"](),
    noResults: lang["contacts"]["noResults"](),
    noUnidentified: lang["contacts"]["noUnidentified"](),
    required: lang["contacts"]["required"](),
    ruleCard: {
      active: lang["contacts"]["ruleCard"]["active"](),
      addCondition: lang["contacts"]["ruleCard"]["addCondition"](),
      appliesTo: (args: { count: Arg }): string =>
        lang["contacts"]["ruleCard"]["appliesTo"](args) as string,
      cancel: lang["contacts"]["ruleCard"]["cancel"](),
      createMoreRules: lang["contacts"]["ruleCard"]["createMoreRules"](),
      createRule: lang["contacts"]["ruleCard"]["createRule"](),
      deleteRule: lang["contacts"]["ruleCard"]["deleteRule"](),
      disableRule: lang["contacts"]["ruleCard"]["disableRule"](),
      disabled: lang["contacts"]["ruleCard"]["disabled"](),
      disabledTooltip: lang["contacts"]["ruleCard"]["disabledTooltip"](),
      editRule: lang["contacts"]["ruleCard"]["editRule"](),
      enableRule: lang["contacts"]["ruleCard"]["enableRule"](),
      enabledTooltip: lang["contacts"]["ruleCard"]["enabledTooltip"](),
      if: lang["contacts"]["ruleCard"]["if"](),
      newRule: (args: { number: Arg }): string =>
        lang["contacts"]["ruleCard"]["newRule"](args) as string,
      save: lang["contacts"]["ruleCard"]["save"](),
      setAccount: lang["contacts"]["ruleCard"]["setAccount"](),
      then: lang["contacts"]["ruleCard"]["then"](),
      tooltip: lang["contacts"]["ruleCard"]["tooltip"](),
      view: lang["contacts"]["ruleCard"]["view"](),
      willApplyTo: (args: { count: Arg }): string =>
        lang["contacts"]["ruleCard"]["willApplyTo"](args) as string,
    },
    rules: {
      importsDrawerTitle: lang["contacts"]["rules"]["importsDrawerTitle"](),
      operators: {
        changeErpAssetAccount:
          lang["contacts"]["rules"]["operators"]["changeErpAssetAccount"](),
        changeErpCashAccount:
          lang["contacts"]["rules"]["operators"]["changeErpCashAccount"](),
        changeErpGainsAccount:
          lang["contacts"]["rules"]["operators"]["changeErpGainsAccount"](),
        changeErpLoanAccount:
          lang["contacts"]["rules"]["operators"]["changeErpLoanAccount"](),
        changeErpPnLAccount:
          lang["contacts"]["rules"]["operators"]["changeErpPnLAccount"](),
        recategorise: lang["contacts"]["rules"]["operators"]["recategorise"](),
      },
      ruleNotFound: lang["contacts"]["rules"]["ruleNotFound"](),
      rulesImportsButton: lang["contacts"]["rules"]["rulesImportsButton"](),
    },
    table: {
      address: lang["contacts"]["table"]["address"](),
      addresses: lang["contacts"]["table"]["addresses"](),
      name: lang["contacts"]["table"]["name"](),
      transactions: lang["contacts"]["table"]["transactions"](),
      type: lang["contacts"]["table"]["type"](),
    },
    unidentified: lang["contacts"]["unidentified"](),
    unidentifiedTable: {
      assets: lang["contacts"]["unidentifiedTable"]["assets"](),
      chains: lang["contacts"]["unidentifiedTable"]["chains"](),
      firstDate: lang["contacts"]["unidentifiedTable"]["firstDate"](),
      lastDate: lang["contacts"]["unidentifiedTable"]["lastDate"](),
      modal: {
        assets: lang["contacts"]["unidentifiedTable"]["modal"]["assets"](),
        buttonCTA:
          lang["contacts"]["unidentifiedTable"]["modal"]["buttonCTA"](),
        importCTA:
          lang["contacts"]["unidentifiedTable"]["modal"]["importCTA"](),
        importLabel:
          lang["contacts"]["unidentifiedTable"]["modal"]["importLabel"](),
        transferAmount:
          lang["contacts"]["unidentifiedTable"]["modal"]["transferAmount"](),
        txs: lang["contacts"]["unidentifiedTable"]["modal"]["txs"](),
      },
      transferAmount: lang["contacts"]["unidentifiedTable"]["transferAmount"](),
      txs: lang["contacts"]["unidentifiedTable"]["txs"](),
    },
  },
  context: lang["context"](),
  copied: lang["copied"](),
  copy: lang["copy"](),
  copyAddress: lang["copyAddress"](),
  copyTxLink: lang["copyTxLink"](),
  copyTxLinkSuccess: lang["copyTxLinkSuccess"](),
  countries: {
    AD: lang["countries"]["AD"](),
    AE: lang["countries"]["AE"](),
    AF: lang["countries"]["AF"](),
    AG: lang["countries"]["AG"](),
    AI: lang["countries"]["AI"](),
    AL: lang["countries"]["AL"](),
    AM: lang["countries"]["AM"](),
    AO: lang["countries"]["AO"](),
    AQ: lang["countries"]["AQ"](),
    AR: lang["countries"]["AR"](),
    AS: lang["countries"]["AS"](),
    AT: lang["countries"]["AT"](),
    AU: lang["countries"]["AU"](),
    AW: lang["countries"]["AW"](),
    AZ: lang["countries"]["AZ"](),
    BA: lang["countries"]["BA"](),
    BB: lang["countries"]["BB"](),
    BD: lang["countries"]["BD"](),
    BE: lang["countries"]["BE"](),
    BF: lang["countries"]["BF"](),
    BG: lang["countries"]["BG"](),
    BH: lang["countries"]["BH"](),
    BI: lang["countries"]["BI"](),
    BJ: lang["countries"]["BJ"](),
    BL: lang["countries"]["BL"](),
    BM: lang["countries"]["BM"](),
    BN: lang["countries"]["BN"](),
    BO: lang["countries"]["BO"](),
    BQ: lang["countries"]["BQ"](),
    BR: lang["countries"]["BR"](),
    BS: lang["countries"]["BS"](),
    BT: lang["countries"]["BT"](),
    BV: lang["countries"]["BV"](),
    BW: lang["countries"]["BW"](),
    BY: lang["countries"]["BY"](),
    BZ: lang["countries"]["BZ"](),
    CA: lang["countries"]["CA"](),
    CC: lang["countries"]["CC"](),
    CD: lang["countries"]["CD"](),
    CF: lang["countries"]["CF"](),
    CG: lang["countries"]["CG"](),
    CH: lang["countries"]["CH"](),
    CI: lang["countries"]["CI"](),
    CK: lang["countries"]["CK"](),
    CL: lang["countries"]["CL"](),
    CM: lang["countries"]["CM"](),
    CN: lang["countries"]["CN"](),
    CO: lang["countries"]["CO"](),
    CR: lang["countries"]["CR"](),
    CU: lang["countries"]["CU"](),
    CV: lang["countries"]["CV"](),
    CW: lang["countries"]["CW"](),
    CX: lang["countries"]["CX"](),
    CY: lang["countries"]["CY"](),
    CZ: lang["countries"]["CZ"](),
    DE: lang["countries"]["DE"](),
    DJ: lang["countries"]["DJ"](),
    DK: lang["countries"]["DK"](),
    DM: lang["countries"]["DM"](),
    DO: lang["countries"]["DO"](),
    DZ: lang["countries"]["DZ"](),
    EC: lang["countries"]["EC"](),
    EE: lang["countries"]["EE"](),
    EG: lang["countries"]["EG"](),
    EH: lang["countries"]["EH"](),
    ER: lang["countries"]["ER"](),
    ES: lang["countries"]["ES"](),
    ET: lang["countries"]["ET"](),
    FI: lang["countries"]["FI"](),
    FJ: lang["countries"]["FJ"](),
    FK: lang["countries"]["FK"](),
    FM: lang["countries"]["FM"](),
    FO: lang["countries"]["FO"](),
    FR: lang["countries"]["FR"](),
    GA: lang["countries"]["GA"](),
    GD: lang["countries"]["GD"](),
    GE: lang["countries"]["GE"](),
    GF: lang["countries"]["GF"](),
    GG: lang["countries"]["GG"](),
    GH: lang["countries"]["GH"](),
    GI: lang["countries"]["GI"](),
    GL: lang["countries"]["GL"](),
    GN: lang["countries"]["GN"](),
    GP: lang["countries"]["GP"](),
    GQ: lang["countries"]["GQ"](),
    GR: lang["countries"]["GR"](),
    GS: lang["countries"]["GS"](),
    GT: lang["countries"]["GT"](),
    GU: lang["countries"]["GU"](),
    GW: lang["countries"]["GW"](),
    GY: lang["countries"]["GY"](),
    HK: lang["countries"]["HK"](),
    HM: lang["countries"]["HM"](),
    HN: lang["countries"]["HN"](),
    HR: lang["countries"]["HR"](),
    HT: lang["countries"]["HT"](),
    HU: lang["countries"]["HU"](),
    ID: lang["countries"]["ID"](),
    IE: lang["countries"]["IE"](),
    IL: lang["countries"]["IL"](),
    IM: lang["countries"]["IM"](),
    IN: lang["countries"]["IN"](),
    IQ: lang["countries"]["IQ"](),
    IR: lang["countries"]["IR"](),
    IS: lang["countries"]["IS"](),
    IT: lang["countries"]["IT"](),
    JE: lang["countries"]["JE"](),
    JM: lang["countries"]["JM"](),
    JO: lang["countries"]["JO"](),
    JP: lang["countries"]["JP"](),
    KE: lang["countries"]["KE"](),
    KG: lang["countries"]["KG"](),
    KI: lang["countries"]["KI"](),
    KN: lang["countries"]["KN"](),
    KP: lang["countries"]["KP"](),
    KR: lang["countries"]["KR"](),
    KW: lang["countries"]["KW"](),
    KY: lang["countries"]["KY"](),
    KZ: lang["countries"]["KZ"](),
    LA: lang["countries"]["LA"](),
    LB: lang["countries"]["LB"](),
    LC: lang["countries"]["LC"](),
    LI: lang["countries"]["LI"](),
    LK: lang["countries"]["LK"](),
    LR: lang["countries"]["LR"](),
    LS: lang["countries"]["LS"](),
    LT: lang["countries"]["LT"](),
    LU: lang["countries"]["LU"](),
    LV: lang["countries"]["LV"](),
    LY: lang["countries"]["LY"](),
    MA: lang["countries"]["MA"](),
    MC: lang["countries"]["MC"](),
    MD: lang["countries"]["MD"](),
    ME: lang["countries"]["ME"](),
    MF: lang["countries"]["MF"](),
    MG: lang["countries"]["MG"](),
    MH: lang["countries"]["MH"](),
    MK: lang["countries"]["MK"](),
    ML: lang["countries"]["ML"](),
    MM: lang["countries"]["MM"](),
    MN: lang["countries"]["MN"](),
    MO: lang["countries"]["MO"](),
    MP: lang["countries"]["MP"](),
    MQ: lang["countries"]["MQ"](),
    MR: lang["countries"]["MR"](),
    MS: lang["countries"]["MS"](),
    MT: lang["countries"]["MT"](),
    MU: lang["countries"]["MU"](),
    MV: lang["countries"]["MV"](),
    MW: lang["countries"]["MW"](),
    MX: lang["countries"]["MX"](),
    MY: lang["countries"]["MY"](),
    MZ: lang["countries"]["MZ"](),
    NA: lang["countries"]["NA"](),
    NC: lang["countries"]["NC"](),
    NE: lang["countries"]["NE"](),
    NF: lang["countries"]["NF"](),
    NG: lang["countries"]["NG"](),
    NI: lang["countries"]["NI"](),
    NL: lang["countries"]["NL"](),
    NO: lang["countries"]["NO"](),
    NP: lang["countries"]["NP"](),
    NR: lang["countries"]["NR"](),
    NU: lang["countries"]["NU"](),
    NZ: lang["countries"]["NZ"](),
    OM: lang["countries"]["OM"](),
    PA: lang["countries"]["PA"](),
    PE: lang["countries"]["PE"](),
    PG: lang["countries"]["PG"](),
    PH: lang["countries"]["PH"](),
    PK: lang["countries"]["PK"](),
    PL: lang["countries"]["PL"](),
    PM: lang["countries"]["PM"](),
    PN: lang["countries"]["PN"](),
    PR: lang["countries"]["PR"](),
    PS: lang["countries"]["PS"](),
    PT: lang["countries"]["PT"](),
    PW: lang["countries"]["PW"](),
    PY: lang["countries"]["PY"](),
    QA: lang["countries"]["QA"](),
    RE: lang["countries"]["RE"](),
    RO: lang["countries"]["RO"](),
    RS: lang["countries"]["RS"](),
    RU: lang["countries"]["RU"](),
    RW: lang["countries"]["RW"](),
    SA: lang["countries"]["SA"](),
    SB: lang["countries"]["SB"](),
    SC: lang["countries"]["SC"](),
    SD: lang["countries"]["SD"](),
    SE: lang["countries"]["SE"](),
    SG: lang["countries"]["SG"](),
    SH: lang["countries"]["SH"](),
    SI: lang["countries"]["SI"](),
    SJ: lang["countries"]["SJ"](),
    SK: lang["countries"]["SK"](),
    SL: lang["countries"]["SL"](),
    SM: lang["countries"]["SM"](),
    SN: lang["countries"]["SN"](),
    SO: lang["countries"]["SO"](),
    SR: lang["countries"]["SR"](),
    SS: lang["countries"]["SS"](),
    ST: lang["countries"]["ST"](),
    SV: lang["countries"]["SV"](),
    SX: lang["countries"]["SX"](),
    SY: lang["countries"]["SY"](),
    TC: lang["countries"]["TC"](),
    TG: lang["countries"]["TG"](),
    TH: lang["countries"]["TH"](),
    TJ: lang["countries"]["TJ"](),
    TK: lang["countries"]["TK"](),
    TL: lang["countries"]["TL"](),
    TM: lang["countries"]["TM"](),
    TN: lang["countries"]["TN"](),
    TO: lang["countries"]["TO"](),
    TR: lang["countries"]["TR"](),
    TT: lang["countries"]["TT"](),
    TV: lang["countries"]["TV"](),
    TW: lang["countries"]["TW"](),
    TZ: lang["countries"]["TZ"](),
    UA: lang["countries"]["UA"](),
    UG: lang["countries"]["UG"](),
    UK: lang["countries"]["UK"](),
    US: lang["countries"]["US"](),
    UY: lang["countries"]["UY"](),
    UZ: lang["countries"]["UZ"](),
    VA: lang["countries"]["VA"](),
    VC: lang["countries"]["VC"](),
    VE: lang["countries"]["VE"](),
    VG: lang["countries"]["VG"](),
    VI: lang["countries"]["VI"](),
    VN: lang["countries"]["VN"](),
    VU: lang["countries"]["VU"](),
    WF: lang["countries"]["WF"](),
    WS: lang["countries"]["WS"](),
    YE: lang["countries"]["YE"](),
    YT: lang["countries"]["YT"](),
    ZA: lang["countries"]["ZA"](),
    ZM: lang["countries"]["ZM"](),
    ZW: lang["countries"]["ZW"](),
  },
  countryAbbreviations: {
    AD: lang["countryAbbreviations"]["AD"](),
    AE: lang["countryAbbreviations"]["AE"](),
    AF: lang["countryAbbreviations"]["AF"](),
    AG: lang["countryAbbreviations"]["AG"](),
    AI: lang["countryAbbreviations"]["AI"](),
    AL: lang["countryAbbreviations"]["AL"](),
    AM: lang["countryAbbreviations"]["AM"](),
    AO: lang["countryAbbreviations"]["AO"](),
    AQ: lang["countryAbbreviations"]["AQ"](),
    AR: lang["countryAbbreviations"]["AR"](),
    AS: lang["countryAbbreviations"]["AS"](),
    AT: lang["countryAbbreviations"]["AT"](),
    AU: lang["countryAbbreviations"]["AU"](),
    AW: lang["countryAbbreviations"]["AW"](),
    AZ: lang["countryAbbreviations"]["AZ"](),
    BA: lang["countryAbbreviations"]["BA"](),
    BB: lang["countryAbbreviations"]["BB"](),
    BD: lang["countryAbbreviations"]["BD"](),
    BE: lang["countryAbbreviations"]["BE"](),
    BF: lang["countryAbbreviations"]["BF"](),
    BG: lang["countryAbbreviations"]["BG"](),
    BH: lang["countryAbbreviations"]["BH"](),
    BI: lang["countryAbbreviations"]["BI"](),
    BJ: lang["countryAbbreviations"]["BJ"](),
    BL: lang["countryAbbreviations"]["BL"](),
    BM: lang["countryAbbreviations"]["BM"](),
    BN: lang["countryAbbreviations"]["BN"](),
    BO: lang["countryAbbreviations"]["BO"](),
    BQ: lang["countryAbbreviations"]["BQ"](),
    BR: lang["countryAbbreviations"]["BR"](),
    BS: lang["countryAbbreviations"]["BS"](),
    BT: lang["countryAbbreviations"]["BT"](),
    BV: lang["countryAbbreviations"]["BV"](),
    BW: lang["countryAbbreviations"]["BW"](),
    BY: lang["countryAbbreviations"]["BY"](),
    BZ: lang["countryAbbreviations"]["BZ"](),
    CA: lang["countryAbbreviations"]["CA"](),
    CC: lang["countryAbbreviations"]["CC"](),
    CD: lang["countryAbbreviations"]["CD"](),
    CF: lang["countryAbbreviations"]["CF"](),
    CG: lang["countryAbbreviations"]["CG"](),
    CH: lang["countryAbbreviations"]["CH"](),
    CI: lang["countryAbbreviations"]["CI"](),
    CK: lang["countryAbbreviations"]["CK"](),
    CL: lang["countryAbbreviations"]["CL"](),
    CM: lang["countryAbbreviations"]["CM"](),
    CN: lang["countryAbbreviations"]["CN"](),
    CO: lang["countryAbbreviations"]["CO"](),
    CR: lang["countryAbbreviations"]["CR"](),
    CU: lang["countryAbbreviations"]["CU"](),
    CV: lang["countryAbbreviations"]["CV"](),
    CW: lang["countryAbbreviations"]["CW"](),
    CX: lang["countryAbbreviations"]["CX"](),
    CY: lang["countryAbbreviations"]["CY"](),
    CZ: lang["countryAbbreviations"]["CZ"](),
    DE: lang["countryAbbreviations"]["DE"](),
    DJ: lang["countryAbbreviations"]["DJ"](),
    DK: lang["countryAbbreviations"]["DK"](),
    DM: lang["countryAbbreviations"]["DM"](),
    DO: lang["countryAbbreviations"]["DO"](),
    DZ: lang["countryAbbreviations"]["DZ"](),
    EC: lang["countryAbbreviations"]["EC"](),
    EE: lang["countryAbbreviations"]["EE"](),
    EG: lang["countryAbbreviations"]["EG"](),
    EH: lang["countryAbbreviations"]["EH"](),
    ER: lang["countryAbbreviations"]["ER"](),
    ES: lang["countryAbbreviations"]["ES"](),
    ET: lang["countryAbbreviations"]["ET"](),
    FI: lang["countryAbbreviations"]["FI"](),
    FJ: lang["countryAbbreviations"]["FJ"](),
    FK: lang["countryAbbreviations"]["FK"](),
    FM: lang["countryAbbreviations"]["FM"](),
    FO: lang["countryAbbreviations"]["FO"](),
    FR: lang["countryAbbreviations"]["FR"](),
    GA: lang["countryAbbreviations"]["GA"](),
    GD: lang["countryAbbreviations"]["GD"](),
    GE: lang["countryAbbreviations"]["GE"](),
    GF: lang["countryAbbreviations"]["GF"](),
    GG: lang["countryAbbreviations"]["GG"](),
    GH: lang["countryAbbreviations"]["GH"](),
    GI: lang["countryAbbreviations"]["GI"](),
    GL: lang["countryAbbreviations"]["GL"](),
    GN: lang["countryAbbreviations"]["GN"](),
    GP: lang["countryAbbreviations"]["GP"](),
    GQ: lang["countryAbbreviations"]["GQ"](),
    GR: lang["countryAbbreviations"]["GR"](),
    GS: lang["countryAbbreviations"]["GS"](),
    GT: lang["countryAbbreviations"]["GT"](),
    GU: lang["countryAbbreviations"]["GU"](),
    GW: lang["countryAbbreviations"]["GW"](),
    GY: lang["countryAbbreviations"]["GY"](),
    HK: lang["countryAbbreviations"]["HK"](),
    HM: lang["countryAbbreviations"]["HM"](),
    HN: lang["countryAbbreviations"]["HN"](),
    HR: lang["countryAbbreviations"]["HR"](),
    HT: lang["countryAbbreviations"]["HT"](),
    HU: lang["countryAbbreviations"]["HU"](),
    ID: lang["countryAbbreviations"]["ID"](),
    IE: lang["countryAbbreviations"]["IE"](),
    IL: lang["countryAbbreviations"]["IL"](),
    IM: lang["countryAbbreviations"]["IM"](),
    IN: lang["countryAbbreviations"]["IN"](),
    IQ: lang["countryAbbreviations"]["IQ"](),
    IR: lang["countryAbbreviations"]["IR"](),
    IS: lang["countryAbbreviations"]["IS"](),
    IT: lang["countryAbbreviations"]["IT"](),
    JE: lang["countryAbbreviations"]["JE"](),
    JM: lang["countryAbbreviations"]["JM"](),
    JO: lang["countryAbbreviations"]["JO"](),
    JP: lang["countryAbbreviations"]["JP"](),
    KE: lang["countryAbbreviations"]["KE"](),
    KG: lang["countryAbbreviations"]["KG"](),
    KI: lang["countryAbbreviations"]["KI"](),
    KN: lang["countryAbbreviations"]["KN"](),
    KP: lang["countryAbbreviations"]["KP"](),
    KR: lang["countryAbbreviations"]["KR"](),
    KW: lang["countryAbbreviations"]["KW"](),
    KY: lang["countryAbbreviations"]["KY"](),
    KZ: lang["countryAbbreviations"]["KZ"](),
    LA: lang["countryAbbreviations"]["LA"](),
    LB: lang["countryAbbreviations"]["LB"](),
    LC: lang["countryAbbreviations"]["LC"](),
    LI: lang["countryAbbreviations"]["LI"](),
    LK: lang["countryAbbreviations"]["LK"](),
    LR: lang["countryAbbreviations"]["LR"](),
    LS: lang["countryAbbreviations"]["LS"](),
    LT: lang["countryAbbreviations"]["LT"](),
    LU: lang["countryAbbreviations"]["LU"](),
    LV: lang["countryAbbreviations"]["LV"](),
    LY: lang["countryAbbreviations"]["LY"](),
    MA: lang["countryAbbreviations"]["MA"](),
    MC: lang["countryAbbreviations"]["MC"](),
    MD: lang["countryAbbreviations"]["MD"](),
    ME: lang["countryAbbreviations"]["ME"](),
    MF: lang["countryAbbreviations"]["MF"](),
    MG: lang["countryAbbreviations"]["MG"](),
    MH: lang["countryAbbreviations"]["MH"](),
    MK: lang["countryAbbreviations"]["MK"](),
    ML: lang["countryAbbreviations"]["ML"](),
    MM: lang["countryAbbreviations"]["MM"](),
    MN: lang["countryAbbreviations"]["MN"](),
    MO: lang["countryAbbreviations"]["MO"](),
    MP: lang["countryAbbreviations"]["MP"](),
    MQ: lang["countryAbbreviations"]["MQ"](),
    MR: lang["countryAbbreviations"]["MR"](),
    MS: lang["countryAbbreviations"]["MS"](),
    MT: lang["countryAbbreviations"]["MT"](),
    MU: lang["countryAbbreviations"]["MU"](),
    MV: lang["countryAbbreviations"]["MV"](),
    MW: lang["countryAbbreviations"]["MW"](),
    MX: lang["countryAbbreviations"]["MX"](),
    MY: lang["countryAbbreviations"]["MY"](),
    MZ: lang["countryAbbreviations"]["MZ"](),
    NA: lang["countryAbbreviations"]["NA"](),
    NC: lang["countryAbbreviations"]["NC"](),
    NE: lang["countryAbbreviations"]["NE"](),
    NF: lang["countryAbbreviations"]["NF"](),
    NG: lang["countryAbbreviations"]["NG"](),
    NI: lang["countryAbbreviations"]["NI"](),
    NL: lang["countryAbbreviations"]["NL"](),
    NO: lang["countryAbbreviations"]["NO"](),
    NP: lang["countryAbbreviations"]["NP"](),
    NR: lang["countryAbbreviations"]["NR"](),
    NU: lang["countryAbbreviations"]["NU"](),
    NZ: lang["countryAbbreviations"]["NZ"](),
    OM: lang["countryAbbreviations"]["OM"](),
    PA: lang["countryAbbreviations"]["PA"](),
    PE: lang["countryAbbreviations"]["PE"](),
    PG: lang["countryAbbreviations"]["PG"](),
    PH: lang["countryAbbreviations"]["PH"](),
    PK: lang["countryAbbreviations"]["PK"](),
    PL: lang["countryAbbreviations"]["PL"](),
    PM: lang["countryAbbreviations"]["PM"](),
    PN: lang["countryAbbreviations"]["PN"](),
    PR: lang["countryAbbreviations"]["PR"](),
    PS: lang["countryAbbreviations"]["PS"](),
    PT: lang["countryAbbreviations"]["PT"](),
    PW: lang["countryAbbreviations"]["PW"](),
    PY: lang["countryAbbreviations"]["PY"](),
    QA: lang["countryAbbreviations"]["QA"](),
    RE: lang["countryAbbreviations"]["RE"](),
    RO: lang["countryAbbreviations"]["RO"](),
    RS: lang["countryAbbreviations"]["RS"](),
    RU: lang["countryAbbreviations"]["RU"](),
    RW: lang["countryAbbreviations"]["RW"](),
    SA: lang["countryAbbreviations"]["SA"](),
    SB: lang["countryAbbreviations"]["SB"](),
    SC: lang["countryAbbreviations"]["SC"](),
    SD: lang["countryAbbreviations"]["SD"](),
    SE: lang["countryAbbreviations"]["SE"](),
    SG: lang["countryAbbreviations"]["SG"](),
    SH: lang["countryAbbreviations"]["SH"](),
    SI: lang["countryAbbreviations"]["SI"](),
    SJ: lang["countryAbbreviations"]["SJ"](),
    SK: lang["countryAbbreviations"]["SK"](),
    SL: lang["countryAbbreviations"]["SL"](),
    SM: lang["countryAbbreviations"]["SM"](),
    SN: lang["countryAbbreviations"]["SN"](),
    SO: lang["countryAbbreviations"]["SO"](),
    SR: lang["countryAbbreviations"]["SR"](),
    SS: lang["countryAbbreviations"]["SS"](),
    ST: lang["countryAbbreviations"]["ST"](),
    SV: lang["countryAbbreviations"]["SV"](),
    SX: lang["countryAbbreviations"]["SX"](),
    SY: lang["countryAbbreviations"]["SY"](),
    TC: lang["countryAbbreviations"]["TC"](),
    TG: lang["countryAbbreviations"]["TG"](),
    TH: lang["countryAbbreviations"]["TH"](),
    TJ: lang["countryAbbreviations"]["TJ"](),
    TK: lang["countryAbbreviations"]["TK"](),
    TL: lang["countryAbbreviations"]["TL"](),
    TM: lang["countryAbbreviations"]["TM"](),
    TN: lang["countryAbbreviations"]["TN"](),
    TO: lang["countryAbbreviations"]["TO"](),
    TR: lang["countryAbbreviations"]["TR"](),
    TT: lang["countryAbbreviations"]["TT"](),
    TV: lang["countryAbbreviations"]["TV"](),
    TW: lang["countryAbbreviations"]["TW"](),
    TZ: lang["countryAbbreviations"]["TZ"](),
    UA: lang["countryAbbreviations"]["UA"](),
    UG: lang["countryAbbreviations"]["UG"](),
    UK: lang["countryAbbreviations"]["UK"](),
    US: lang["countryAbbreviations"]["US"](),
    UY: lang["countryAbbreviations"]["UY"](),
    UZ: lang["countryAbbreviations"]["UZ"](),
    VA: lang["countryAbbreviations"]["VA"](),
    VC: lang["countryAbbreviations"]["VC"](),
    VE: lang["countryAbbreviations"]["VE"](),
    VG: lang["countryAbbreviations"]["VG"](),
    VI: lang["countryAbbreviations"]["VI"](),
    VN: lang["countryAbbreviations"]["VN"](),
    VU: lang["countryAbbreviations"]["VU"](),
    WF: lang["countryAbbreviations"]["WF"](),
    WS: lang["countryAbbreviations"]["WS"](),
    YE: lang["countryAbbreviations"]["YE"](),
    YT: lang["countryAbbreviations"]["YT"](),
    ZA: lang["countryAbbreviations"]["ZA"](),
    ZM: lang["countryAbbreviations"]["ZM"](),
    ZW: lang["countryAbbreviations"]["ZW"](),
  },
  coupon: {
    label: lang["coupon"]["label"](),
    placeholder: lang["coupon"]["placeholder"](),
  },
  couponIntegration: {
    CB140: lang["couponIntegration"]["CB140"](),
    COINBASE30: lang["couponIntegration"]["COINBASE30"](),
    COINJARUK: lang["couponIntegration"]["COINJARUK"](),
    COINSTATS: lang["couponIntegration"]["COINSTATS"](),
    CTCFRIEND20: lang["couponIntegration"]["CTCFRIEND20"](),
    METAMASK10: lang["couponIntegration"]["METAMASK10"](),
    MMCTC30: lang["couponIntegration"]["MMCTC30"](),
    bitcoincomau30: lang["couponIntegration"]["bitcoincomau30"](),
    cb1rookie: lang["couponIntegration"]["cb1rookie"](),
    coinbase140: lang["couponIntegration"]["coinbase140"](),
    coinbase140p: lang["couponIntegration"]["coinbase140p"](),
    coinbase1p: lang["couponIntegration"]["coinbase1p"](),
    ir30: lang["couponIntegration"]["ir30"](),
    metamask30: lang["couponIntegration"]["metamask30"](),
  },
  createTransactionBefore: lang["createTransactionBefore"](),
  ctcAutomations: lang["ctcAutomations"](),
  currency: {
    errorLoadingAllCurrencies: lang["currency"]["errorLoadingAllCurrencies"](),
    errorLoadingUserCurrencies:
      lang["currency"]["errorLoadingUserCurrencies"](),
    unableToFindPrice: (args: { name: Arg }): string =>
      lang["currency"]["unableToFindPrice"](args) as string,
    wentWrongFetchingPrice: (args: { name: Arg }): string =>
      lang["currency"]["wentWrongFetchingPrice"](args) as string,
  },
  currentPlan: lang["currentPlan"](),
  darkModeBanner: {
    actionButton: lang["darkModeBanner"]["actionButton"](),
    cancelButton: lang["darkModeBanner"]["cancelButton"](),
    description: lang["darkModeBanner"]["description"](),
    title: lang["darkModeBanner"]["title"](),
  },
  dashboard: {
    allAssets: lang["dashboard"]["allAssets"](),
    balance: lang["dashboard"]["balance"](),
    carousel: {
      moneyBack: {
        description:
          lang["dashboard"]["carousel"]["moneyBack"]["description"](),
        subtitle: lang["dashboard"]["carousel"]["moneyBack"]["subtitle"](),
        title: lang["dashboard"]["carousel"]["moneyBack"]["title"](),
      },
      partnerOffer: {
        description:
          lang["dashboard"]["carousel"]["partnerOffer"]["description"](),
        subtitle: lang["dashboard"]["carousel"]["partnerOffer"]["subtitle"](),
        title: lang["dashboard"]["carousel"]["partnerOffer"]["title"](),
      },
    },
    chartCalculationDialog: {
      body1: lang["dashboard"]["chartCalculationDialog"]["body1"](),
      note: lang["dashboard"]["chartCalculationDialog"]["note"](),
      title: lang["dashboard"]["chartCalculationDialog"]["title"](),
    },
    costBasis: lang["dashboard"]["costBasis"](),
    costBasisTooltip: lang["dashboard"]["costBasisTooltip"](),
    currentBalance: lang["dashboard"]["currentBalance"](),
    embedded: {
      accuracyChecks: lang["dashboard"]["embedded"]["accuracyChecks"](),
      accuracyComplete: lang["dashboard"]["embedded"]["accuracyComplete"](),
      accuracyTasks: lang["dashboard"]["embedded"]["accuracyTasks"](),
      accuracyTasksAction:
        lang["dashboard"]["embedded"]["accuracyTasksAction"](),
      accuracyTasksDescription:
        lang["dashboard"]["embedded"]["accuracyTasksDescription"](),
      countdownBanner: {
        title: lang["dashboard"]["embedded"]["countdownBanner"]["title"](),
        tooltip: lang["dashboard"]["embedded"]["countdownBanner"]["tooltip"](),
      },
      estimatedGains: lang["dashboard"]["embedded"]["estimatedGains"](),
      estimatedGainsTooltip:
        lang["dashboard"]["embedded"]["estimatedGainsTooltip"](),
      fy: lang["dashboard"]["embedded"]["fy"](),
      getTaxReports: lang["dashboard"]["embedded"]["getTaxReports"](),
      missingAccounts: lang["dashboard"]["embedded"]["missingAccounts"](),
      missingAccountsAction:
        lang["dashboard"]["embedded"]["missingAccountsAction"](),
      missingAccountsDescription:
        lang["dashboard"]["embedded"]["missingAccountsDescription"](),
      remindMeLater: {
        title: lang["dashboard"]["embedded"]["remindMeLater"]["title"](),
        description:
          lang["dashboard"]["embedded"]["remindMeLater"]["description"](),
        buttonText:
          lang["dashboard"]["embedded"]["remindMeLater"]["buttonText"](),
        modals: {
          reminder: {
            chipText:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "reminder"
              ]["chipText"](),
            title:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "reminder"
              ]["title"](),
            description:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "reminder"
              ]["description"](),
            emailLabel:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "reminder"
              ]["emailLabel"](),
            emailError:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "reminder"
              ]["emailError"](),
            buttonText:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "reminder"
              ]["buttonText"](),
            privacyNotice:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "reminder"
              ]["privacyNotice"](),
          },
          success: {
            chipText:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "success"
              ]["chipText"](),
            title:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "success"
              ]["title"](),
            description:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "success"
              ]["description"](),
            buttonText:
              lang["dashboard"]["embedded"]["remindMeLater"]["modals"][
                "success"
              ]["buttonText"](),
          },
        },
      },
      savingsOpportunity: lang["dashboard"]["embedded"]["savingsOpportunity"](),
      savingsOpportunityTooltip:
        lang["dashboard"]["embedded"]["savingsOpportunityTooltip"](),
    },
    error: lang["dashboard"]["error"](),
    feesPaid: lang["dashboard"]["feesPaid"](),
    feesPaidTooltip: lang["dashboard"]["feesPaidTooltip"](),
    fiatInvested: lang["dashboard"]["fiatInvested"](),
    fiatProceeds: lang["dashboard"]["fiatProceeds"](),
    fiatProceedsTooltip: lang["dashboard"]["fiatProceedsTooltip"](),
    heading: lang["dashboard"]["heading"](),
    holdings: {
      balanceSyncTimeExplainer:
        lang["dashboard"]["holdings"]["balanceSyncTimeExplainer"](),
      balances: {
        subheading: lang["dashboard"]["holdings"]["balances"]["subheading"](),
        title: lang["dashboard"]["holdings"]["balances"]["title"](),
      },
      balancesSubheading: lang["dashboard"]["holdings"]["balancesSubheading"](),
      balancesTitle: lang["dashboard"]["holdings"]["balancesTitle"](),
      breakdownListNegative:
        lang["dashboard"]["holdings"]["breakdownListNegative"](),
      breakdownListTitle: lang["dashboard"]["holdings"]["breakdownListTitle"](),
      breakdownListTooltip: {
        marketPrice: (args: { symbol: Arg; currency: Arg }): string =>
          lang["dashboard"]["holdings"]["breakdownListTooltip"]["marketPrice"](
            args,
          ) as string,
        positiveBalance: (args: { exchangeName: Arg }): string =>
          lang["dashboard"]["holdings"]["breakdownListTooltip"][
            "positiveBalance"
          ](args) as string,
        review: {
          access:
            lang["dashboard"]["holdings"]["breakdownListTooltip"]["review"][
              "access"
            ](),
          subscribe:
            lang["dashboard"]["holdings"]["breakdownListTooltip"]["review"][
              "subscribe"
            ](),
          tips: lang["dashboard"]["holdings"]["breakdownListTooltip"]["review"][
            "tips"
          ](),
          toLearnMore:
            lang["dashboard"]["holdings"]["breakdownListTooltip"]["review"][
              "toLearnMore"
            ](),
        },
        warningBalanceLessThan: (args: {
          currencyAmountDifference: Arg;
          currencyLabel: Arg;
          fiatDifference: Arg;
        }): string =>
          lang["dashboard"]["holdings"]["breakdownListTooltip"][
            "warningBalanceLessThan"
          ](args) as string,
        warningBalanceMoreThan: (args: {
          currencyAmountDifference: Arg;
          currencyLabel: Arg;
          fiatDifference: Arg;
        }): string =>
          lang["dashboard"]["holdings"]["breakdownListTooltip"][
            "warningBalanceMoreThan"
          ](args) as string,
        warningBalanceNoMarketPriceLessThan: (args: {
          currencyAmountDifference: Arg;
          currencyLabel: Arg;
        }): string =>
          lang["dashboard"]["holdings"]["breakdownListTooltip"][
            "warningBalanceNoMarketPriceLessThan"
          ](args) as string,
        warningBalanceNoMarketPriceMoreThan: (args: {
          currencyAmountDifference: Arg;
          currencyLabel: Arg;
        }): string =>
          lang["dashboard"]["holdings"]["breakdownListTooltip"][
            "warningBalanceNoMarketPriceMoreThan"
          ](args) as string,
      },
      cost: lang["dashboard"]["holdings"]["cost"](),
      costTooltip: lang["dashboard"]["holdings"]["costTooltip"](),
      costTooltipTitle: lang["dashboard"]["holdings"]["costTooltipTitle"](),
      currency: lang["dashboard"]["holdings"]["currency"](),
      empty: { title: lang["dashboard"]["holdings"]["empty"]["title"]() },
      marketPrice: lang["dashboard"]["holdings"]["marketPrice"](),
      marketPriceTooltip: lang["dashboard"]["holdings"]["marketPriceTooltip"](),
      marketValue: lang["dashboard"]["holdings"]["marketValue"](),
      marketValueTooltip: lang["dashboard"]["holdings"]["marketValueTooltip"](),
      moreThan: lang["dashboard"]["holdings"]["moreThan"](),
      of: lang["dashboard"]["holdings"]["of"](),
      quantity: lang["dashboard"]["holdings"]["quantity"](),
      roi: lang["dashboard"]["holdings"]["roi"](),
      roiTooltip: lang["dashboard"]["holdings"]["roiTooltip"](),
      rowsPerPage: lang["dashboard"]["holdings"]["rowsPerPage"](),
      showAppBalances: lang["dashboard"]["holdings"]["showAppBalances"](),
      syncStatus: {
        fail: lang["dashboard"]["holdings"]["syncStatus"]["fail"](),
        noSync: lang["dashboard"]["holdings"]["syncStatus"]["noSync"](),
        success: lang["dashboard"]["holdings"]["syncStatus"]["success"](),
      },
      title: lang["dashboard"]["holdings"]["title"](),
      tooltip: {
        off: lang["dashboard"]["holdings"]["tooltip"]["off"](),
        on: lang["dashboard"]["holdings"]["tooltip"]["on"](),
        sort: lang["dashboard"]["holdings"]["tooltip"]["sort"](),
      },
      unrealizedGain: lang["dashboard"]["holdings"]["unrealizedGain"](),
      unrealizedGainTooltip:
        lang["dashboard"]["holdings"]["unrealizedGainTooltip"](),
    },
    holdingsTypes: {
      calculatedBody: lang["dashboard"]["holdingsTypes"]["calculatedBody"](),
      calculatedSwitch:
        lang["dashboard"]["holdingsTypes"]["calculatedSwitch"](),
      calculatedTitle: lang["dashboard"]["holdingsTypes"]["calculatedTitle"](),
      done: lang["dashboard"]["holdingsTypes"]["done"](),
      reportedBody: lang["dashboard"]["holdingsTypes"]["reportedBody"](),
      reportedSwitch: lang["dashboard"]["holdingsTypes"]["reportedSwitch"](),
      reportedTitle: lang["dashboard"]["holdingsTypes"]["reportedTitle"](),
      title: lang["dashboard"]["holdingsTypes"]["title"](),
    },
    howThisChartIsCalculated: lang["dashboard"]["howThisChartIsCalculated"](),
    inflow: lang["dashboard"]["inflow"](),
    inflowOutflowChart: {
      header: lang["dashboard"]["inflowOutflowChart"]["header"](),
      legend: {
        fees: lang["dashboard"]["inflowOutflowChart"]["legend"]["fees"](),
        inflow: lang["dashboard"]["inflowOutflowChart"]["legend"]["inflow"](),
        net: lang["dashboard"]["inflowOutflowChart"]["legend"]["net"](),
        outflow: lang["dashboard"]["inflowOutflowChart"]["legend"]["outflow"](),
      },
      linear: lang["dashboard"]["inflowOutflowChart"]["linear"](),
      log: lang["dashboard"]["inflowOutflowChart"]["log"](),
      timeframeSelector: {
        month:
          lang["dashboard"]["inflowOutflowChart"]["timeframeSelector"][
            "month"
          ](),
        placeholder:
          lang["dashboard"]["inflowOutflowChart"]["timeframeSelector"][
            "placeholder"
          ](),
        quarterToDate:
          lang["dashboard"]["inflowOutflowChart"]["timeframeSelector"][
            "quarterToDate"
          ](),
        year: lang["dashboard"]["inflowOutflowChart"]["timeframeSelector"][
          "year"
        ](),
        ytd: lang["dashboard"]["inflowOutflowChart"]["timeframeSelector"][
          "ytd"
        ](),
      },
      tooltip: {
        fees: lang["dashboard"]["inflowOutflowChart"]["tooltip"]["fees"](),
        inflow: lang["dashboard"]["inflowOutflowChart"]["tooltip"]["inflow"](),
        net: lang["dashboard"]["inflowOutflowChart"]["tooltip"]["net"](),
        outflow:
          lang["dashboard"]["inflowOutflowChart"]["tooltip"]["outflow"](),
      },
    },
    inflowTooltip: lang["dashboard"]["inflowTooltip"](),
    losers: lang["dashboard"]["losers"](),
    missingImportsWarning: {
      actionButton:
        lang["dashboard"]["missingImportsWarning"]["actionButton"](),
      description: lang["dashboard"]["missingImportsWarning"]["description"](),
      importsTitle:
        lang["dashboard"]["missingImportsWarning"]["importsTitle"](),
      nextStepButton:
        lang["dashboard"]["missingImportsWarning"]["nextStepButton"](),
      overallSubtitle:
        lang["dashboard"]["missingImportsWarning"]["overallSubtitle"](),
      overallTitle:
        lang["dashboard"]["missingImportsWarning"]["overallTitle"](),
      title: lang["dashboard"]["missingImportsWarning"]["title"](),
    },
    negativeBalanceWarning: (args: { currencySymbol: Arg }): string =>
      lang["dashboard"]["negativeBalanceWarning"](args) as string,
    netFiatInvested: lang["dashboard"]["netFiatInvested"](),
    netFiatInvestedTooltip: lang["dashboard"]["netFiatInvestedTooltip"](),
    nfts: {
      filter: {
        allChains: lang["dashboard"]["nfts"]["filter"]["allChains"](),
        chainPlaceholder:
          lang["dashboard"]["nfts"]["filter"]["chainPlaceholder"](),
      },
      imageNotFound: lang["dashboard"]["nfts"]["imageNotFound"](),
      noNFTs: lang["dashboard"]["nfts"]["noNFTs"](),
      noNFTsCTA: (args: { linkTextKey: Arg }): string =>
        lang["dashboard"]["nfts"]["noNFTsCTA"](args) as string,
    },
    noAssetAllocation: {
      body: lang["dashboard"]["noAssetAllocation"]["body"](),
      importData: lang["dashboard"]["noAssetAllocation"]["importData"](),
      title: lang["dashboard"]["noAssetAllocation"]["title"](),
    },
    noHoldings: {
      body: lang["dashboard"]["noHoldings"]["body"](),
      importData: lang["dashboard"]["noHoldings"]["importData"](),
      title: lang["dashboard"]["noHoldings"]["title"](),
    },
    noWinnersLosers: {
      body: lang["dashboard"]["noWinnersLosers"]["body"](),
      importData: lang["dashboard"]["noWinnersLosers"]["importData"](),
      title: lang["dashboard"]["noWinnersLosers"]["title"](),
    },
    noWinnersLosersToDisplay: (args: { direction: Arg }): string =>
      lang["dashboard"]["noWinnersLosersToDisplay"](args) as string,
    notApplicable: lang["dashboard"]["notApplicable"](),
    other: lang["dashboard"]["other"](),
    outflow: lang["dashboard"]["outflow"](),
    outflowTooltip: lang["dashboard"]["outflowTooltip"](),
    pageHeading: lang["dashboard"]["pageHeading"](),
    percentageTooltipTitle: lang["dashboard"]["percentageTooltipTitle"](),
    portfolioChart: {
      error: lang["dashboard"]["portfolioChart"]["error"](),
      loading: lang["dashboard"]["portfolioChart"]["loading"](),
      noData: {
        body: lang["dashboard"]["portfolioChart"]["noData"]["body"](),
        importData:
          lang["dashboard"]["portfolioChart"]["noData"]["importData"](),
        title: lang["dashboard"]["portfolioChart"]["noData"]["title"](),
      },
      totalValue: lang["dashboard"]["portfolioChart"]["totalValue"](),
    },
    portfolioPerformance: lang["dashboard"]["portfolioPerformance"](),
    portfolioPerformanceTooltip:
      lang["dashboard"]["portfolioPerformanceTooltip"](),
    portfolioTimeframe: {
      all: lang["dashboard"]["portfolioTimeframe"]["all"](),
      day: lang["dashboard"]["portfolioTimeframe"]["day"](),
      month: lang["dashboard"]["portfolioTimeframe"]["month"](),
      week: lang["dashboard"]["portfolioTimeframe"]["week"](),
      year: lang["dashboard"]["portfolioTimeframe"]["year"](),
      ytd: lang["dashboard"]["portfolioTimeframe"]["ytd"](),
    },
    portfolioTooltip: {
      accuracyNote: lang["dashboard"]["portfolioTooltip"]["accuracyNote"](),
      all: lang["dashboard"]["portfolioTooltip"]["all"](),
      day: lang["dashboard"]["portfolioTooltip"]["day"](),
      ignoreNegativeHoldings:
        lang["dashboard"]["portfolioTooltip"]["ignoreNegativeHoldings"](),
      month: lang["dashboard"]["portfolioTooltip"]["month"](),
      week: lang["dashboard"]["portfolioTooltip"]["week"](),
      year: lang["dashboard"]["portfolioTooltip"]["year"](),
    },
    somethingWentWrong: lang["dashboard"]["somethingWentWrong"](),
    step1: {
      content: lang["dashboard"]["step1"]["content"](),
      detailedGuide: lang["dashboard"]["step1"]["detailedGuide"](),
      link: lang["dashboard"]["step1"]["link"](),
      title: lang["dashboard"]["step1"]["title"](),
    },
    step2: {
      content: lang["dashboard"]["step2"]["content"](),
      link: lang["dashboard"]["step2"]["link"](),
      title: lang["dashboard"]["step2"]["title"](),
    },
    step3: {
      content: lang["dashboard"]["step3"]["content"](),
      link: lang["dashboard"]["step3"]["link"](),
      title: lang["dashboard"]["step3"]["title"](),
    },
    steps: {
      getStarted: lang["dashboard"]["steps"]["getStarted"](),
      gettingStarted: lang["dashboard"]["steps"]["gettingStarted"](),
    },
    tabs: {
      nfts: lang["dashboard"]["tabs"]["nfts"](),
      overview: lang["dashboard"]["tabs"]["overview"](),
    },
    title: lang["dashboard"]["title"](),
    topAssets: lang["dashboard"]["topAssets"](),
    totalReturn: lang["dashboard"]["totalReturn"](),
    totalReturnTooltip: lang["dashboard"]["totalReturnTooltip"](),
    treasury: lang["dashboard"]["treasury"](),
    treasuryTimeframe: {
      lastMonth: lang["dashboard"]["treasuryTimeframe"]["lastMonth"](),
      lastYear: lang["dashboard"]["treasuryTimeframe"]["lastYear"](),
      monthToDate: lang["dashboard"]["treasuryTimeframe"]["monthToDate"](),
      yearToDate: lang["dashboard"]["treasuryTimeframe"]["yearToDate"](),
    },
    unrealizedPosition: lang["dashboard"]["unrealizedPosition"](),
    unrealizedPositionTooltip: lang["dashboard"]["unrealizedPositionTooltip"](),
    valueOfHoldings: lang["dashboard"]["valueOfHoldings"](),
    valueOfHoldingsTooltip: lang["dashboard"]["valueOfHoldingsTooltip"](),
    valueOfHoldingsTooltipTitle:
      lang["dashboard"]["valueOfHoldingsTooltipTitle"](),
    winners: lang["dashboard"]["winners"](),
    winnersAndLosers: {
      help: lang["dashboard"]["winnersAndLosers"]["help"](),
      importData: lang["dashboard"]["winnersAndLosers"]["importData"](),
      noData: lang["dashboard"]["winnersAndLosers"]["noData"](),
      noDataBody: lang["dashboard"]["winnersAndLosers"]["noDataBody"](),
      noDataTitle: lang["dashboard"]["winnersAndLosers"]["noDataTitle"](),
      title: lang["dashboard"]["winnersAndLosers"]["title"](),
    },
  },
  dataSource: {
    radioButtonLabels: {
      accountant: lang["dataSource"]["radioButtonLabels"]["accountant"](),
      client: (args: { accountant: Arg }): string =>
        lang["dataSource"]["radioButtonLabels"]["client"](args) as string,
    },
    subtitle: lang["dataSource"]["subtitle"](),
    title: lang["dataSource"]["title"](),
  },
  delete: lang["delete"](),
  deny: lang["deny"](),
  developer: {
    activeFlags: lang["developer"]["activeFlags"](),
    addFlag: lang["developer"]["addFlag"](),
    clearFlagOverrides: lang["developer"]["clearFlagOverrides"](),
    enableLocalCensoring: lang["developer"]["enableLocalCensoring"](),
    noActiveFlags: lang["developer"]["noActiveFlags"](),
    tabs: {
      currencies: lang["developer"]["tabs"]["currencies"](),
      editorIntegration: lang["developer"]["tabs"]["editorIntegration"](),
      entities: lang["developer"]["tabs"]["entities"](),
      featureFlags: lang["developer"]["tabs"]["featureFlags"](),
      reconciliation: lang["developer"]["tabs"]["reconciliation"](),
      reportRefresh: lang["developer"]["tabs"]["reportRefresh"](),
      rules: lang["developer"]["tabs"]["rules"](),
      seedData: lang["developer"]["tabs"]["seedData"](),
      sessionRecording: lang["developer"]["tabs"]["sessionRecording"](),
      userData: lang["developer"]["tabs"]["userData"](),
    },
    title: lang["developer"]["title"](),
  },
  done: lang["done"](),
  duplicate: lang["duplicate"](),
  edit: lang["edit"](),
  editTx: {
    accounts: lang["editTx"]["accounts"](),
    addFee: lang["editTx"]["addFee"](),
    advanced: lang["editTx"]["advanced"](),
    baseCurrency: lang["editTx"]["baseCurrency"](),
    blockchain: lang["editTx"]["blockchain"](),
    blockchainFrom: lang["editTx"]["blockchainFrom"](),
    blockchainPlaceholder: lang["editTx"]["blockchainPlaceholder"](),
    blockchainTo: lang["editTx"]["blockchainTo"](),
    buyCurrency: lang["editTx"]["buyCurrency"](),
    buyFee: lang["editTx"]["buyFee"](),
    buyFeeFor: lang["editTx"]["buyFeeFor"](),
    buyPrice: lang["editTx"]["buyPrice"](),
    buyQuantity: lang["editTx"]["buyQuantity"](),
    cantEditLocked: lang["editTx"]["cantEditLocked"](),
    cantEditLockedButton: lang["editTx"]["cantEditLockedButton"](),
    cantMixBlockchain: lang["editTx"]["cantMixBlockchain"](),
    currency: lang["editTx"]["currency"](),
    currencyPaidIn: lang["editTx"]["currencyPaidIn"](),
    destinationOfTransaction: lang["editTx"]["destinationOfTransaction"](),
    editFee: lang["editTx"]["editFee"](),
    editPriceCurrencyValue: lang["editTx"]["editPriceCurrencyValue"](),
    feeForIncoming: lang["editTx"]["feeForIncoming"](),
    feeForOutgoing: lang["editTx"]["feeForOutgoing"](),
    feeQuantityPaidIn: lang["editTx"]["feeQuantityPaidIn"](),
    feeValue: lang["editTx"]["feeValue"](),
    in: lang["editTx"]["in"](),
    inFee: lang["editTx"]["inFee"](),
    inPrice: lang["editTx"]["inPrice"](),
    inQuantity: lang["editTx"]["inQuantity"](),
    inTime: lang["editTx"]["inTime"](),
    linkValueTooltipTradeLike: lang["editTx"]["linkValueTooltipTradeLike"](),
    linkValueTooltipTransferLike:
      lang["editTx"]["linkValueTooltipTransferLike"](),
    lookupPrice: lang["editTx"]["lookupPrice"](),
    mustBeLess: lang["editTx"]["mustBeLess"](),
    mustBuy: lang["editTx"]["mustBuy"](),
    mustCategory: lang["editTx"]["mustCategory"](),
    mustCurrency: lang["editTx"]["mustCurrency"](),
    mustDestination: lang["editTx"]["mustDestination"](),
    mustFee: lang["editTx"]["mustFee"](),
    mustHaveBlockchain: lang["editTx"]["mustHaveBlockchain"](),
    mustOutTime: lang["editTx"]["mustOutTime"](),
    mustPrice: lang["editTx"]["mustPrice"](),
    mustQuantity: lang["editTx"]["mustQuantity"](),
    mustSell: lang["editTx"]["mustSell"](),
    mustSource: lang["editTx"]["mustSource"](),
    mustTime: lang["editTx"]["mustTime"](),
    mustValue: lang["editTx"]["mustValue"](),
    noPriceFound: (args: { currency: Arg }): string =>
      lang["editTx"]["noPriceFound"](args) as string,
    notes: lang["editTx"]["notes"](),
    optionalNotes: lang["editTx"]["optionalNotes"](),
    outFee: lang["editTx"]["outFee"](),
    outPrice: lang["editTx"]["outPrice"](),
    outQuantity: lang["editTx"]["outQuantity"](),
    outTime: lang["editTx"]["outTime"](),
    paidInFees: lang["editTx"]["paidInFees"](),
    price: lang["editTx"]["price"](),
    priceForOne: lang["editTx"]["priceForOne"](),
    quantity: lang["editTx"]["quantity"](),
    removeFee: lang["editTx"]["removeFee"](),
    sellCurrency: lang["editTx"]["sellCurrency"](),
    sellFee: lang["editTx"]["sellFee"](),
    sellFeeFor: lang["editTx"]["sellFeeFor"](),
    sellPrice: lang["editTx"]["sellPrice"](),
    sellQuantity: lang["editTx"]["sellQuantity"](),
    somethingWentWrong: lang["editTx"]["somethingWentWrong"](),
    sourceOfTransaction: lang["editTx"]["sourceOfTransaction"](),
    swapIconIncoming: lang["editTx"]["swapIconIncoming"](),
    swapIconOutgoing: lang["editTx"]["swapIconOutgoing"](),
    totalFeeValueIn: lang["editTx"]["totalFeeValueIn"](),
    totalQuantityOf: lang["editTx"]["totalQuantityOf"](),
    totalValue: (args: { amount: Arg }): string =>
      lang["editTx"]["totalValue"](args) as string,
    treatAsNFT: (args: { symbol: Arg }): string =>
      lang["editTx"]["treatAsNFT"](args) as string,
    treatAsNFTTooltip: lang["editTx"]["treatAsNFTTooltip"](),
    ungroupCriteria: {
      differentAssetType:
        lang["editTx"]["ungroupCriteria"]["differentAssetType"](),
      differentCurrency:
        lang["editTx"]["ungroupCriteria"]["differentCurrency"](),
      differentSource: lang["editTx"]["ungroupCriteria"]["differentSource"](),
      dontGroup: lang["editTx"]["ungroupCriteria"]["dontGroup"](),
      fiatCurrency: lang["editTx"]["ungroupCriteria"]["fiatCurrency"](),
      sameAccount: lang["editTx"]["ungroupCriteria"]["sameAccount"](),
      sameCurrency: lang["editTx"]["ungroupCriteria"]["sameCurrency"](),
      sameFromTo: lang["editTx"]["ungroupCriteria"]["sameFromTo"](),
      sameTimestamp: lang["editTx"]["ungroupCriteria"]["sameTimestamp"](),
      sameTxId: lang["editTx"]["ungroupCriteria"]["sameTxId"](),
      sameValueFeeExclusive:
        lang["editTx"]["ungroupCriteria"]["sameValueFeeExclusive"](),
      sameValueFeeInclusive:
        lang["editTx"]["ungroupCriteria"]["sameValueFeeInclusive"](),
      similarQuantity: lang["editTx"]["ungroupCriteria"]["similarQuantity"](),
      similarTimestamp: lang["editTx"]["ungroupCriteria"]["similarTimestamp"](),
    },
    ungroupWarning: (args: { count: Arg }): string =>
      lang["editTx"]["ungroupWarning"](args) as string,
    unlock: lang["editTx"]["unlock"](),
    unlockBlockchainBody: lang["editTx"]["unlockBlockchainBody"](),
    unlockBlockchainTitle: lang["editTx"]["unlockBlockchainTitle"](),
    validTime: lang["editTx"]["validTime"](),
    validatePositiveFeeValue: lang["editTx"]["validatePositiveFeeValue"](),
    validatePositiveFiatValue: lang["editTx"]["validatePositiveFiatValue"](),
    validatePositivePriceValue: lang["editTx"]["validatePositivePriceValue"](),
    validatePositiveQuantityValue:
      lang["editTx"]["validatePositiveQuantityValue"](),
    validatePositiveValue: lang["editTx"]["validatePositiveValue"](),
    value: lang["editTx"]["value"](),
    valueLockLabel: lang["editTx"]["valueLockLabel"](),
    willSetBlockchain: lang["editTx"]["willSetBlockchain"](),
  },
  enforceUpgrade: { tooltip: lang["enforceUpgrade"]["tooltip"]() },
  enterprise: {
    getReport: lang["enterprise"]["getReport"](),
    incomingInvoicesDownloadModal: {
      billingPeriod:
        lang["enterprise"]["incomingInvoicesDownloadModal"]["billingPeriod"](),
      customDateRange:
        lang["enterprise"]["incomingInvoicesDownloadModal"][
          "customDateRange"
        ](),
      downloadCsv:
        lang["enterprise"]["incomingInvoicesDownloadModal"]["downloadCsv"](),
      downloadPdf:
        lang["enterprise"]["incomingInvoicesDownloadModal"]["downloadPdf"](),
      endDate: lang["enterprise"]["incomingInvoicesDownloadModal"]["endDate"](),
      startDate:
        lang["enterprise"]["incomingInvoicesDownloadModal"]["startDate"](),
      startDateBeforeEndDateError:
        lang["enterprise"]["incomingInvoicesDownloadModal"][
          "startDateBeforeEndDateError"
        ](),
      title: lang["enterprise"]["incomingInvoicesDownloadModal"]["title"](),
    },
    office: {
      addNew: {
        button: lang["enterprise"]["office"]["addNew"]["button"](),
        dialog: {
          accept: lang["enterprise"]["office"]["addNew"]["dialog"]["accept"](),
          title: lang["enterprise"]["office"]["addNew"]["dialog"]["title"](),
        },
      },
      addNewButton: lang["enterprise"]["office"]["addNewButton"](),
      addNewOffice: lang["enterprise"]["office"]["addNewOffice"](),
      all: lang["enterprise"]["office"]["all"](),
      allOffices: lang["enterprise"]["office"]["allOffices"](),
      offices: lang["enterprise"]["office"]["offices"](),
    },
    valueCards: {
      numberOfOffices: lang["enterprise"]["valueCards"]["numberOfOffices"](),
      totalAccountants: lang["enterprise"]["valueCards"]["totalAccountants"](),
      totalClients: lang["enterprise"]["valueCards"]["totalClients"](),
      upcomingInvoices: lang["enterprise"]["valueCards"]["upcomingInvoices"](),
    },
  },
  error: lang["error"](),
  errorBoundaries: {
    debugInfo: lang["errorBoundaries"]["debugInfo"](),
    error: lang["errorBoundaries"]["error"](),
    oopsSomethingWentWrong: lang["errorBoundaries"]["oopsSomethingWentWrong"](),
    weCantProcess: lang["errorBoundaries"]["weCantProcess"](),
  },
  errorMessageGeneric: lang["errorMessageGeneric"](),
  errorReporting: {
    actionButtonText: {
      contactSupport:
        lang["errorReporting"]["actionButtonText"]["contactSupport"](),
      retrySync: lang["errorReporting"]["actionButtonText"]["retrySync"](),
      updateAddress:
        lang["errorReporting"]["actionButtonText"]["updateAddress"](),
      updateApiKey:
        lang["errorReporting"]["actionButtonText"]["updateApiKey"](),
      viewDetails: lang["errorReporting"]["actionButtonText"]["viewDetails"](),
    },
    csvErrorDetails: {
      description: lang["errorReporting"]["csvErrorDetails"]["description"](),
      title: (args: { exchangeName: Arg }): string =>
        lang["errorReporting"]["csvErrorDetails"]["title"](args) as string,
    },
  },
  etaTime: {
    momentHumanizeEquivalent: {
      "a few seconds":
        lang["etaTime"]["momentHumanizeEquivalent"]["a few seconds"](),
      day: lang["etaTime"]["momentHumanizeEquivalent"]["day"](),
      days: lang["etaTime"]["momentHumanizeEquivalent"]["days"](),
      hour: lang["etaTime"]["momentHumanizeEquivalent"]["hour"](),
      hours: lang["etaTime"]["momentHumanizeEquivalent"]["hours"](),
      minute: lang["etaTime"]["momentHumanizeEquivalent"]["minute"](),
      minutes: lang["etaTime"]["momentHumanizeEquivalent"]["minutes"](),
      month: lang["etaTime"]["momentHumanizeEquivalent"]["month"](),
      months: lang["etaTime"]["momentHumanizeEquivalent"]["months"](),
      second: lang["etaTime"]["momentHumanizeEquivalent"]["second"](),
      seconds: lang["etaTime"]["momentHumanizeEquivalent"]["seconds"](),
      week: lang["etaTime"]["momentHumanizeEquivalent"]["week"](),
      weeks: lang["etaTime"]["momentHumanizeEquivalent"]["weeks"](),
      year: lang["etaTime"]["momentHumanizeEquivalent"]["year"](),
    },
    tooltip: (args: { avgHumanizeTime: Arg; p95HumanizeTime: Arg }): string =>
      lang["etaTime"]["tooltip"](args) as string,
    tooltipSameTime: (args: { avgHumanizeTime: Arg }): string =>
      lang["etaTime"]["tooltipSameTime"](args) as string,
  },
  existing: {
    cancel: lang["existing"]["cancel"](),
    goBack: lang["existing"]["goBack"](),
    pricingUrl: lang["existing"]["pricingUrl"](),
    title: lang["existing"]["title"](),
    upgrade: lang["existing"]["upgrade"](),
    upgradePlan: lang["existing"]["upgradePlan"](),
    viewPricingInfo: lang["existing"]["viewPricingInfo"](),
  },
  existingImports: { title: lang["existingImports"]["title"]() },
  faqAccountant: {
    description: lang["faqAccountant"]["description"](),
    questions: [
      {
        answer: lang["faqAccountant"]["questions"][0]["answer"](),
        question: lang["faqAccountant"]["questions"][0]["question"](),
      },
      {
        answer: lang["faqAccountant"]["questions"][1]["answer"](),
        question: lang["faqAccountant"]["questions"][1]["question"](),
      },
      {
        answer: lang["faqAccountant"]["questions"][2]["answer"](),
        question: lang["faqAccountant"]["questions"][2]["question"](),
      },
      {
        answer: lang["faqAccountant"]["questions"][3]["answer"](),
        question: lang["faqAccountant"]["questions"][3]["question"](),
      },
      {
        answer: lang["faqAccountant"]["questions"][4]["answer"](),
        question: lang["faqAccountant"]["questions"][4]["question"](),
      },
      {
        answer: lang["faqAccountant"]["questions"][5]["answer"](),
        question: lang["faqAccountant"]["questions"][5]["question"](),
      },
    ],
    title: lang["faqAccountant"]["title"](),
  },
  faqPricing: {
    description: lang["faqPricing"]["description"](),
    questions: [
      {
        answer: lang["faqPricing"]["questions"][0]["answer"](),
        question: lang["faqPricing"]["questions"][0]["question"](),
      },
      {
        answer: lang["faqPricing"]["questions"][1]["answer"](),
        question: lang["faqPricing"]["questions"][1]["question"](),
      },
      {
        answer: lang["faqPricing"]["questions"][2]["answer"](),
        question: lang["faqPricing"]["questions"][2]["question"](),
      },
      {
        answer: lang["faqPricing"]["questions"][3]["answer"](),
        question: lang["faqPricing"]["questions"][3]["question"](),
      },
      {
        answer: lang["faqPricing"]["questions"][4]["answer"](),
        question: lang["faqPricing"]["questions"][4]["question"](),
      },
      {
        answer: lang["faqPricing"]["questions"][5]["answer"](),
        question: lang["faqPricing"]["questions"][5]["question"](),
      },
    ],
    title: lang["faqPricing"]["title"](),
  },
  faqs: {
    capitalGainsWrong: {
      ans: [lang["faqs"]["capitalGainsWrong"]["ans"][0]()],
      q: lang["faqs"]["capitalGainsWrong"]["q"](),
    },
    ensureAccuracy: {
      ans: [
        lang["faqs"]["ensureAccuracy"]["ans"][0](),
        lang["faqs"]["ensureAccuracy"]["ans"][1](),
        lang["faqs"]["ensureAccuracy"]["ans"][2](),
        lang["faqs"]["ensureAccuracy"]["ans"][3](),
        lang["faqs"]["ensureAccuracy"]["ans"][4](),
        lang["faqs"]["ensureAccuracy"]["ans"][5](),
        lang["faqs"]["ensureAccuracy"]["ans"][6](),
      ],
      q: lang["faqs"]["ensureAccuracy"]["q"](),
    },
    howToUseCTC: {
      ans: [
        lang["faqs"]["howToUseCTC"]["ans"][0](),
        lang["faqs"]["howToUseCTC"]["ans"][1](),
        lang["faqs"]["howToUseCTC"]["ans"][2](),
        lang["faqs"]["howToUseCTC"]["ans"][3](),
      ],
      q: lang["faqs"]["howToUseCTC"]["q"](),
    },
    missingIncome: {
      ans: [
        lang["faqs"]["missingIncome"]["ans"][0](),
        lang["faqs"]["missingIncome"]["ans"][1](),
        lang["faqs"]["missingIncome"]["ans"][2](),
      ],
      q: lang["faqs"]["missingIncome"]["q"](),
    },
  },
  for: lang["for"](),
  forceSyncErp: (args: { erp: Arg }): string =>
    lang["forceSyncErp"](args) as string,
  gainsToggles: lang["gainsToggles"](),
  generic: {
    isAccountant: lang["generic"]["isAccountant"](),
    notAccountant: lang["generic"]["notAccountant"](),
    select: lang["generic"]["select"](),
    yourPlan: lang["generic"]["yourPlan"](),
  },
  groupingWizard: {
    actionButton: lang["groupingWizard"]["actionButton"](),
    backPage: lang["groupingWizard"]["backPage"](),
    matchCountPage: {
      description: lang["groupingWizard"]["matchCountPage"]["description"](),
      ratios: {
        NToOne: (args: { type: Arg }): string =>
          lang["groupingWizard"]["matchCountPage"]["ratios"]["NToOne"](
            args,
          ) as string,
        nToM: (args: { type: Arg }): string =>
          lang["groupingWizard"]["matchCountPage"]["ratios"]["nToM"](
            args,
          ) as string,
        nToN: (args: { type: Arg }): string =>
          lang["groupingWizard"]["matchCountPage"]["ratios"]["nToN"](
            args,
          ) as string,
        oneToN: (args: { type: Arg }): string =>
          lang["groupingWizard"]["matchCountPage"]["ratios"]["oneToN"](
            args,
          ) as string,
        oneToOne: (args: { type: Arg }): string =>
          lang["groupingWizard"]["matchCountPage"]["ratios"]["oneToOne"](
            args,
          ) as string,
      },
      selected: (args: { inCount: Arg; outCount: Arg }): string =>
        lang["groupingWizard"]["matchCountPage"]["selected"](args) as string,
      title: (args: { type: Arg }): string =>
        lang["groupingWizard"]["matchCountPage"]["title"](args) as string,
    },
    matchCriteriaPage: {
      description: lang["groupingWizard"]["matchCriteriaPage"]["description"](),
      differentAssetType: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["differentAssetType"][
          "cta"
        ](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["differentAssetType"][
            "title"
          ](),
      },
      differentCurrency: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["differentCurrency"][
          "cta"
        ](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["differentCurrency"][
            "title"
          ](),
      },
      differentSources: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["differentSources"][
          "cta"
        ](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["differentSources"][
            "title"
          ](),
      },
      dontGroup: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["dontGroup"]["cta"](),
        enableGrouping:
          lang["groupingWizard"]["matchCriteriaPage"]["dontGroup"][
            "enableGrouping"
          ](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["dontGroup"]["title"](),
      },
      fiatCurrency: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["fiatCurrency"][
          "cta"
        ](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["fiatCurrency"][
            "title"
          ](),
      },
      noIssues: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["noIssues"]["cta"](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["noIssues"]["title"](),
      },
      quantity: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["quantity"]["cta"](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["quantity"]["title"](),
      },
      sameAccount: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["sameAccount"][
          "cta"
        ](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["sameAccount"]["title"](),
      },
      sameCurrency: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["sameCurrency"][
          "cta"
        ](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["sameCurrency"][
            "title"
          ](),
      },
      sameFromTo: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["sameFromTo"]["cta"](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["sameFromTo"]["title"](),
      },
      timestamp: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["timestamp"]["cta"](),
        title:
          lang["groupingWizard"]["matchCriteriaPage"]["timestamp"]["title"](),
      },
      title: (args: { type: Arg }): string =>
        lang["groupingWizard"]["matchCriteriaPage"]["title"](args) as string,
      txId: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["txId"]["cta"](),
        title: lang["groupingWizard"]["matchCriteriaPage"]["txId"]["title"](),
      },
      value: {
        cta: lang["groupingWizard"]["matchCriteriaPage"]["value"]["cta"](),
        title: lang["groupingWizard"]["matchCriteriaPage"]["value"]["title"](),
      },
    },
    matchTypePage: {
      allowed: lang["groupingWizard"]["matchTypePage"]["allowed"](),
      description: lang["groupingWizard"]["matchTypePage"]["description"](),
      selected: lang["groupingWizard"]["matchTypePage"]["selected"](),
      title: lang["groupingWizard"]["matchTypePage"]["title"](),
    },
    modalTitle: lang["groupingWizard"]["modalTitle"](),
    nextPage: lang["groupingWizard"]["nextPage"](),
  },
  help: lang["help"](),
  holdingsDropdown: {
    assets: lang["holdingsDropdown"]["assets"](),
    balances: lang["holdingsDropdown"]["balances"](),
    calcBal: lang["holdingsDropdown"]["calcBal"](),
    calculatedTooltip: lang["holdingsDropdown"]["calculatedTooltip"](),
    currentPrice: lang["holdingsDropdown"]["currentPrice"](),
    holdings: lang["holdingsDropdown"]["holdings"](),
    noData: lang["holdingsDropdown"]["noData"](),
    noHoldings: lang["holdingsDropdown"]["noHoldings"](),
    price: lang["holdingsDropdown"]["price"](),
    quantity: lang["holdingsDropdown"]["quantity"](),
    quantityTooltip: lang["holdingsDropdown"]["quantityTooltip"](),
    repBal: lang["holdingsDropdown"]["repBal"](),
    reportedTooltip: lang["holdingsDropdown"]["reportedTooltip"](),
    value: lang["holdingsDropdown"]["value"](),
    valueTooltip: lang["holdingsDropdown"]["valueTooltip"](),
    wallets: lang["holdingsDropdown"]["wallets"](),
  },
  ignore: lang["ignore"](),
  ignoreIssue: lang["ignoreIssue"](),
  importStepType: {
    critical: lang["importStepType"]["critical"](),
    note: lang["importStepType"]["note"](),
    step: lang["importStepType"]["step"](),
    video: lang["importStepType"]["video"](),
  },
  imports: {
    accountTableTypes: {
      blockchain: lang["imports"]["accountTableTypes"]["blockchain"](),
      exchange: lang["imports"]["accountTableTypes"]["exchange"](),
    },
    accounts: lang["imports"]["accounts"](),
    accountsTableHeadings: {
      account: lang["imports"]["accountsTableHeadings"]["account"](),
      assets: lang["imports"]["accountsTableHeadings"]["assets"](),
      balance: lang["imports"]["accountsTableHeadings"]["balance"](),
      synced: lang["imports"]["accountsTableHeadings"]["synced"](),
      tx: lang["imports"]["accountsTableHeadings"]["tx"](),
      type: lang["imports"]["accountsTableHeadings"]["type"](),
    },
    addIntegrationTitle: lang["imports"]["addIntegrationTitle"](),
    addIntegrations: lang["imports"]["addIntegrations"](),
    addSource: (args: { source: Arg }): string =>
      lang["imports"]["addSource"](args) as string,
    addYourFirst: lang["imports"]["addYourFirst"](),
    additionalNotes: lang["imports"]["additionalNotes"](),
    advancedOptions: lang["imports"]["advancedOptions"](),
    api: lang["imports"]["api"](),
    apiDisabled: (args: { isNew: Arg }): string =>
      lang["imports"]["apiDisabled"](args) as string,
    apiStillSyncing: lang["imports"]["apiStillSyncing"](),
    apiWarning: lang["imports"]["apiWarning"](),
    backToExistingImports: lang["imports"]["backToExistingImports"](),
    backToImport: lang["imports"]["backToImport"](),
    backToImportTypeSelector: lang["imports"]["backToImportTypeSelector"](),
    backToIntegrations: lang["imports"]["backToIntegrations"](),
    backToIntegrationsSearch: lang["imports"]["backToIntegrationsSearch"](),
    beta: lang["imports"]["beta"](),
    betaTooltipDefault: lang["imports"]["betaTooltipDefault"](),
    breakdownTooltip: lang["imports"]["breakdownTooltip"](),
    browse: lang["imports"]["browse"](),
    bulkSyncTooltip: {
      api: (args: { name: Arg; dateTime: Arg }): string =>
        lang["imports"]["bulkSyncTooltip"]["api"](args) as string,
      deprecatedTooltip: (args: { name: Arg }): string =>
        lang["imports"]["bulkSyncTooltip"]["deprecatedTooltip"](args) as string,
      failed: lang["imports"]["bulkSyncTooltip"]["failed"](),
      lastSyncTime: lang["imports"]["bulkSyncTooltip"]["lastSyncTime"](),
      lockedPeriod: (args: { dateTime: Arg }): string =>
        lang["imports"]["bulkSyncTooltip"]["lockedPeriod"](args) as string,
      noSync: lang["imports"]["bulkSyncTooltip"]["noSync"](),
      pending: lang["imports"]["bulkSyncTooltip"]["pending"](),
      wallet: (args: { name: Arg; dateTime: Arg }): string =>
        lang["imports"]["bulkSyncTooltip"]["wallet"](args) as string,
    },
    chainDisabled: (args: { name: Arg }): string =>
      lang["imports"]["chainDisabled"](args) as string,
    chips: {
      api: lang["imports"]["chips"]["api"](),
      csv: lang["imports"]["chips"]["csv"](),
      service: lang["imports"]["chips"]["service"](),
      wallet: (args: { walletCount: Arg }): string =>
        lang["imports"]["chips"]["wallet"](args) as string,
    },
    clickHere: lang["imports"]["clickHere"](),
    connectWallet: lang["imports"]["connectWallet"](),
    connectWalletErrors: {
      duplicateWallet: (args: { address: Arg }): string =>
        lang["imports"]["connectWalletErrors"]["duplicateWallet"](
          args,
        ) as string,
      duplicateWallets: (args: { addresses: Arg }): string =>
        lang["imports"]["connectWalletErrors"]["duplicateWallets"](
          args,
        ) as string,
      maxWallets: (args: { max: Arg }): string =>
        lang["imports"]["connectWalletErrors"]["maxWallets"](args) as string,
      walletNotInstalled: (args: { wallet: Arg }): string =>
        lang["imports"]["connectWalletErrors"]["walletNotInstalled"](
          args,
        ) as string,
    },
    connected: lang["imports"]["connected"](),
    connectionSuccess: lang["imports"]["connectionSuccess"](),
    continue: lang["imports"]["continue"](),
    cryptoHoldings: lang["imports"]["cryptoHoldings"](),
    csv: lang["imports"]["csv"](),
    csvAlerts: {
      fileSizeTooBig: lang["imports"]["csvAlerts"]["fileSizeTooBig"](),
      fileTypeNotSupported:
        lang["imports"]["csvAlerts"]["fileTypeNotSupported"](),
      invalidFileName: (args: { fileNames: Arg }): string =>
        lang["imports"]["csvAlerts"]["invalidFileName"](args) as string,
      missingAddress: lang["imports"]["csvAlerts"]["missingAddress"](),
      missingCurrencySymbols:
        lang["imports"]["csvAlerts"]["missingCurrencySymbols"](),
      noFiles: lang["imports"]["csvAlerts"]["noFiles"](),
      onlyOneFile: lang["imports"]["csvAlerts"]["onlyOneFile"](),
    },
    csvCaps: lang["imports"]["csvCaps"](),
    csvDisabled: (args: { isNew: Arg }): string =>
      lang["imports"]["csvDisabled"](args) as string,
    csvExtraInfo: {
      address: lang["imports"]["csvExtraInfo"]["address"](),
      addressHelper: lang["imports"]["csvExtraInfo"]["addressHelper"](),
      addressPlaceholder:
        lang["imports"]["csvExtraInfo"]["addressPlaceholder"](),
      currencyHelper: lang["imports"]["csvExtraInfo"]["currencyHelper"](),
      currencySymbol: lang["imports"]["csvExtraInfo"]["currencySymbol"](),
      fiatCurrency: lang["imports"]["csvExtraInfo"]["fiatCurrency"](),
      tickerPlaceHolder: lang["imports"]["csvExtraInfo"]["tickerPlaceHolder"](),
    },
    csvWarning: lang["imports"]["csvWarning"](),
    customCSV: lang["imports"]["customCSV"](),
    customCsvButton: lang["imports"]["customCsvButton"](),
    customCsvLabel: lang["imports"]["customCsvLabel"](),
    customCsvPlaceholder: lang["imports"]["customCsvPlaceholder"](),
    deleteApiKeyConfirmTitle: lang["imports"]["deleteApiKeyConfirmTitle"](),
    deleteImportExchangeConfirmation: {
      body: (args: { name: Arg }): string =>
        lang["imports"]["deleteImportExchangeConfirmation"]["body"](
          args,
        ) as string,
      title: lang["imports"]["deleteImportExchangeConfirmation"]["title"](),
    },
    deleteImportedFileConfirmation: {
      body: lang["imports"]["deleteImportedFileConfirmation"]["body"](),
      title: lang["imports"]["deleteImportedFileConfirmation"]["title"](),
    },
    deleteOauthKeyConfirmText: (args: { exchangeName: Arg }): string =>
      lang["imports"]["deleteOauthKeyConfirmText"](args) as string,
    deleteOauthKeyConfirmTitle: (args: { exchangeName: Arg }): string =>
      lang["imports"]["deleteOauthKeyConfirmTitle"](args) as string,
    deleteWalletConfirmTitle: lang["imports"]["deleteWalletConfirmTitle"](),
    deprecated: lang["imports"]["deprecated"](),
    deprecatedTooltipDefault: lang["imports"]["deprecatedTooltipDefault"](),
    deprecation: {
      description: lang["imports"]["deprecation"]["description"](),
      title: lang["imports"]["deprecation"]["title"](),
    },
    dialogOptions: {
      addApi: lang["imports"]["dialogOptions"]["addApi"](),
      addCsv: lang["imports"]["dialogOptions"]["addCsv"](),
      addWallet: lang["imports"]["dialogOptions"]["addWallet"](),
      copyAddress: lang["imports"]["dialogOptions"]["copyAddress"](),
      delete: lang["imports"]["dialogOptions"]["delete"](),
      deleteAll: lang["imports"]["dialogOptions"]["deleteAll"](),
      downloadCsv: lang["imports"]["dialogOptions"]["downloadCsv"](),
      editApi: lang["imports"]["dialogOptions"]["editApi"](),
      editNickname: lang["imports"]["dialogOptions"]["editNickname"](),
      hardSync: lang["imports"]["dialogOptions"]["hardSync"](),
      ignoreChain: lang["imports"]["dialogOptions"]["ignoreChain"](),
      sync: lang["imports"]["dialogOptions"]["sync"](),
      viewExplorer: lang["imports"]["dialogOptions"]["viewExplorer"](),
      viewLedger: lang["imports"]["dialogOptions"]["viewLedger"](),
      viewTransactions: lang["imports"]["dialogOptions"]["viewTransactions"](),
    },
    done: lang["imports"]["done"](),
    downloadCsvDisplayMessage: {
      info: lang["imports"]["downloadCsvDisplayMessage"]["info"](),
      success: lang["imports"]["downloadCsvDisplayMessage"]["success"](),
    },
    drawer: {
      accountMetricsHeading: {
        balance:
          lang["imports"]["drawer"]["accountMetricsHeading"]["balance"](),
        feesPaid:
          lang["imports"]["drawer"]["accountMetricsHeading"]["feesPaid"](),
        tradeVolume:
          lang["imports"]["drawer"]["accountMetricsHeading"]["tradeVolume"](),
        transactions:
          lang["imports"]["drawer"]["accountMetricsHeading"]["transactions"](),
      },
      addApiKey: lang["imports"]["drawer"]["addApiKey"](),
      addCsv: lang["imports"]["drawer"]["addCsv"](),
      addWallet: lang["imports"]["drawer"]["addWallet"](),
      currencyTableHeadings: {
        amount: lang["imports"]["drawer"]["currencyTableHeadings"]["amount"](),
        currencies:
          lang["imports"]["drawer"]["currencyTableHeadings"]["currencies"](),
        price: lang["imports"]["drawer"]["currencyTableHeadings"]["price"](),
        value: lang["imports"]["drawer"]["currencyTableHeadings"]["value"](),
      },
      deleteApiKey: {
        body: (args: { key: Arg }): string =>
          lang["imports"]["drawer"]["deleteApiKey"]["body"](args) as string,
        title: lang["imports"]["drawer"]["deleteApiKey"]["title"](),
      },
      deleteBlockchain: {
        body: (args: { address: Arg; blockchain: Arg }): string =>
          lang["imports"]["drawer"]["deleteBlockchain"]["body"](args) as string,
        title: lang["imports"]["drawer"]["deleteBlockchain"]["title"](),
      },
      deleteFile: {
        body: (args: { name: Arg }): string =>
          lang["imports"]["drawer"]["deleteFile"]["body"](args) as string,
        title: lang["imports"]["drawer"]["deleteFile"]["title"](),
      },
      deleteOauth: {
        body: (args: { exchangeName: Arg }): string =>
          lang["imports"]["drawer"]["deleteOauth"]["body"](args) as string,
        title: (args: { exchangeName: Arg }): string =>
          lang["imports"]["drawer"]["deleteOauth"]["title"](args) as string,
      },
      nftTableHeadings: {
        collections:
          lang["imports"]["drawer"]["nftTableHeadings"]["collections"](),
        combinedCost:
          lang["imports"]["drawer"]["nftTableHeadings"]["combinedCost"](),
        ntfs: lang["imports"]["drawer"]["nftTableHeadings"]["ntfs"](),
        totalSpend:
          lang["imports"]["drawer"]["nftTableHeadings"]["totalSpend"](),
      },
      overview: lang["imports"]["drawer"]["overview"](),
      showLess: lang["imports"]["drawer"]["showLess"](),
      showLessNft: lang["imports"]["drawer"]["showLessNft"](),
      showMore: lang["imports"]["drawer"]["showMore"](),
      showMoreNft: lang["imports"]["drawer"]["showMoreNft"](),
      statusBanner: {
        accountHasNoTransactions:
          lang["imports"]["drawer"]["statusBanner"][
            "accountHasNoTransactions"
          ](),
        and: lang["imports"]["drawer"]["statusBanner"]["and"](),
        chainSyncFailed: (args: { chains: Arg }): string =>
          lang["imports"]["drawer"]["statusBanner"]["chainSyncFailed"](
            args,
          ) as string,
        generalImportsFailed: (args: { all: Arg; integration: Arg }): string =>
          lang["imports"]["drawer"]["statusBanner"]["generalImportsFailed"](
            args,
          ) as string,
        generalSyncsFailed: (args: { all: Arg; integration: Arg }): string =>
          lang["imports"]["drawer"]["statusBanner"]["generalSyncsFailed"](
            args,
          ) as string,
        selectedImportFailed: (args: {
          name: Arg;
          reason: Arg;
          code: Arg;
        }): string =>
          lang["imports"]["drawer"]["statusBanner"]["selectedImportFailed"](
            args,
          ) as string,
        selectedImportHasNoTransactions: (args: { name: Arg }): string =>
          lang["imports"]["drawer"]["statusBanner"][
            "selectedImportHasNoTransactions"
          ](args) as string,
        selectedSyncFailed: (args: {
          name: Arg;
          reason: Arg;
          code: Arg;
        }): string =>
          lang["imports"]["drawer"]["statusBanner"]["selectedSyncFailed"](
            args,
          ) as string,
      },
      viewCollection: lang["imports"]["drawer"]["viewCollection"](),
      viewLedger: lang["imports"]["drawer"]["viewLedger"](),
      viewOnTxTable: lang["imports"]["drawer"]["viewOnTxTable"](),
      viewTxs: lang["imports"]["drawer"]["viewTxs"](),
    },
    dropFiles: lang["imports"]["dropFiles"](),
    editSource: (args: { source: Arg }): string =>
      lang["imports"]["editSource"](args) as string,
    emptyHoldings: lang["imports"]["emptyHoldings"](),
    enable: lang["imports"]["enable"](),
    enableImportChainTransactions:
      lang["imports"]["enableImportChainTransactions"](),
    error: (args: {
      blockchain: Arg;
      name: Arg;
      showError: Arg;
      error: Arg;
      showCode: Arg;
      code: Arg;
    }): string => lang["imports"]["error"](args) as string,
    exchangeGridTitle: {
      all: lang["imports"]["exchangeGridTitle"]["all"](),
      allBlockchains: lang["imports"]["exchangeGridTitle"]["allBlockchains"](),
      popular: lang["imports"]["exchangeGridTitle"]["popular"](),
      results: lang["imports"]["exchangeGridTitle"]["results"](),
    },
    existingOptions: lang["imports"]["existingOptions"](),
    findAccount: lang["imports"]["findAccount"](),
    firstTx: lang["imports"]["firstTx"](),
    goBack: lang["imports"]["goBack"](),
    hardSync: lang["imports"]["hardSync"](),
    import: lang["imports"]["import"](),
    importBannerMessages: {
      connectingToIntegration: (args: { name: Arg }): string =>
        lang["imports"]["importBannerMessages"]["connectingToIntegration"](
          args,
        ) as string,
      success: lang["imports"]["importBannerMessages"]["success"](),
    },
    importByManualCSV: (args: { exchange: Arg }): string =>
      lang["imports"]["importByManualCSV"](args) as string,
    importCSV: (args: { source: Arg }): string =>
      lang["imports"]["importCSV"](args) as string,
    importDataTitle: lang["imports"]["importDataTitle"](),
    importExchange: (args: { exchangeName: Arg }): string =>
      lang["imports"]["importExchange"](args) as string,
    importFailed: lang["imports"]["importFailed"](),
    importFileDuplicateWarning: lang["imports"]["importFileDuplicateWarning"](),
    importFrom: {
      chip: {
        notRecommended:
          lang["imports"]["importFrom"]["chip"]["notRecommended"](),
        recommended: lang["imports"]["importFrom"]["chip"]["recommended"](),
      },
      modal: {
        actionText: lang["imports"]["importFrom"]["modal"]["actionText"](),
        text: lang["imports"]["importFrom"]["modal"]["text"](),
        title: lang["imports"]["importFrom"]["modal"]["title"](),
      },
      selector: {
        beginning: lang["imports"]["importFrom"]["selector"]["beginning"](),
        custom: lang["imports"]["importFrom"]["selector"]["custom"](),
        title: lang["imports"]["importFrom"]["selector"]["title"](),
      },
    },
    importInstructionsAccordionTitle:
      lang["imports"]["importInstructionsAccordionTitle"](),
    importTypeModal: {
      buttonLabel: {
        csv: {
          active:
            lang["imports"]["importTypeModal"]["buttonLabel"]["csv"][
              "active"
            ](),
          default:
            lang["imports"]["importTypeModal"]["buttonLabel"]["csv"][
              "default"
            ](),
        },
      },
      description: lang["imports"]["importTypeModal"]["description"](),
      importMethod: {
        api: {
          description:
            lang["imports"]["importTypeModal"]["importMethod"]["api"][
              "description"
            ](),
          title:
            lang["imports"]["importTypeModal"]["importMethod"]["api"][
              "title"
            ](),
        },
        "bulk-wallet": {
          description:
            lang["imports"]["importTypeModal"]["importMethod"]["bulk-wallet"][
              "description"
            ](),
          title:
            lang["imports"]["importTypeModal"]["importMethod"]["bulk-wallet"][
              "title"
            ](),
        },
        "connect-wallet": {
          description:
            lang["imports"]["importTypeModal"]["importMethod"][
              "connect-wallet"
            ]["description"](),
          title:
            lang["imports"]["importTypeModal"]["importMethod"][
              "connect-wallet"
            ]["title"](),
        },
        csv: {
          description:
            lang["imports"]["importTypeModal"]["importMethod"]["csv"][
              "description"
            ](),
          title:
            lang["imports"]["importTypeModal"]["importMethod"]["csv"][
              "title"
            ](),
        },
        oauth: {
          description:
            lang["imports"]["importTypeModal"]["importMethod"]["oauth"][
              "description"
            ](),
          title:
            lang["imports"]["importTypeModal"]["importMethod"]["oauth"][
              "title"
            ](),
        },
        wallet: {
          description:
            lang["imports"]["importTypeModal"]["importMethod"]["wallet"][
              "description"
            ](),
          title:
            lang["imports"]["importTypeModal"]["importMethod"]["wallet"][
              "title"
            ](),
        },
      },
      instructionTitles: {
        api: lang["imports"]["importTypeModal"]["instructionTitles"]["api"](),
        "bulk-wallet":
          lang["imports"]["importTypeModal"]["instructionTitles"][
            "bulk-wallet"
          ](),
        "connect-wallet":
          lang["imports"]["importTypeModal"]["instructionTitles"][
            "connect-wallet"
          ](),
        csv: lang["imports"]["importTypeModal"]["instructionTitles"]["csv"](),
        oauth: (args: { exchange: Arg }): string =>
          lang["imports"]["importTypeModal"]["instructionTitles"]["oauth"](
            args,
          ) as string,
        wallet:
          lang["imports"]["importTypeModal"]["instructionTitles"]["wallet"](),
      },
      methods: {
        api: lang["imports"]["importTypeModal"]["methods"]["api"](),
        "bulk-wallet":
          lang["imports"]["importTypeModal"]["methods"]["bulk-wallet"](),
        wallet: lang["imports"]["importTypeModal"]["methods"]["wallet"](),
      },
      title: (args: { name: Arg }): string =>
        lang["imports"]["importTypeModal"]["title"](args) as string,
    },
    imported: lang["imports"]["imported"](),
    importedFileWarning: lang["imports"]["importedFileWarning"](),
    importedTransactions: (args: {
      count: Arg;
      exchangeDisplayName: Arg;
    }): string => lang["imports"]["importedTransactions"](args) as string,
    imports: lang["imports"]["imports"](),
    integrationChips: {
      all: lang["imports"]["integrationChips"]["all"](),
      blockchains: lang["imports"]["integrationChips"]["blockchains"](),
      exchanges: lang["imports"]["integrationChips"]["exchanges"](),
      services: lang["imports"]["integrationChips"]["services"](),
      wallets: lang["imports"]["integrationChips"]["wallets"](),
    },
    integrationSuccessful: lang["imports"]["integrationSuccessful"](),
    integrations: lang["imports"]["integrations"](),
    label: lang["imports"]["label"](),
    lastLockedSyncWarning: {
      action: lang["imports"]["lastLockedSyncWarning"]["action"](),
      body: (args: { date: Arg }): string =>
        lang["imports"]["lastLockedSyncWarning"]["body"](args) as string,
      title: lang["imports"]["lastLockedSyncWarning"]["title"](),
    },
    lastSynced: lang["imports"]["lastSynced"](),
    lastTx: lang["imports"]["lastTx"](),
    manualCSV: {
      csvText: lang["imports"]["manualCSV"]["csvText"](),
      customCSVToExchange: {
        description: {
          link: lang["imports"]["manualCSV"]["customCSVToExchange"][
            "description"
          ]["link"](),
          linkText:
            lang["imports"]["manualCSV"]["customCSVToExchange"]["description"][
              "linkText"
            ](),
          preLinkText:
            lang["imports"]["manualCSV"]["customCSVToExchange"]["description"][
              "preLinkText"
            ](),
        },
        title: lang["imports"]["manualCSV"]["customCSVToExchange"]["title"](),
      },
      excelText: lang["imports"]["manualCSV"]["excelText"](),
      finishAdding: lang["imports"]["manualCSV"]["finishAdding"](),
      googleSheetsText: lang["imports"]["manualCSV"]["googleSheetsText"](),
      learnMore: {
        link: lang["imports"]["manualCSV"]["learnMore"]["link"](),
        text: lang["imports"]["manualCSV"]["learnMore"]["text"](),
      },
      seeAdvancedGuide: {
        link: lang["imports"]["manualCSV"]["seeAdvancedGuide"]["link"](),
        linkText:
          lang["imports"]["manualCSV"]["seeAdvancedGuide"]["linkText"](),
        postLinkText:
          lang["imports"]["manualCSV"]["seeAdvancedGuide"]["postLinkText"](),
        preLinkText:
          lang["imports"]["manualCSV"]["seeAdvancedGuide"]["preLinkText"](),
      },
      steps: [
        lang["imports"]["manualCSV"]["steps"][0](),
        lang["imports"]["manualCSV"]["steps"][1](),
        lang["imports"]["manualCSV"]["steps"][2](),
      ],
      warningText: lang["imports"]["manualCSV"]["warningText"](),
    },
    manualCSVImport: lang["imports"]["manualCSVImport"](),
    manualImportTooltip: lang["imports"]["manualImportTooltip"](),
    marketValue: (args: { localCurrency: Arg }): string =>
      lang["imports"]["marketValue"](args) as string,
    nameOfSource: lang["imports"]["nameOfSource"](),
    noExchange: lang["imports"]["noExchange"](),
    noImports: lang["imports"]["noImports"](),
    noOptions: lang["imports"]["noOptions"](),
    noSync: lang["imports"]["noSync"](),
    noTxFound: (args: { showNew: Arg; exchangeDisplayName: Arg }): string =>
      lang["imports"]["noTxFound"](args) as string,
    noTxFoundWallet: (args: {
      showNew: Arg;
      blockchain: Arg;
      address: Arg;
    }): string => lang["imports"]["noTxFoundWallet"](args) as string,
    note: lang["imports"]["note"](),
    notifications: {
      kucoinfutures: {
        buttonLabel:
          lang["imports"]["notifications"]["kucoinfutures"]["buttonLabel"](),
        text: lang["imports"]["notifications"]["kucoinfutures"]["text"](),
        title: lang["imports"]["notifications"]["kucoinfutures"]["title"](),
      },
    },
    oauth: {
      connect: (args: { exchange: Arg }): string =>
        lang["imports"]["oauth"]["connect"](args) as string,
      description: (args: { exchange: Arg }): string =>
        lang["imports"]["oauth"]["description"](args) as string,
    },
    onboarding: {
      expect: lang["imports"]["onboarding"]["expect"](),
      getStarted: lang["imports"]["onboarding"]["getStarted"](),
      steps: [
        lang["imports"]["onboarding"]["steps"][0](),
        lang["imports"]["onboarding"]["steps"][1](),
        lang["imports"]["onboarding"]["steps"][2](),
      ],
      welcome: lang["imports"]["onboarding"]["welcome"](),
    },
    options: {
      api: lang["imports"]["options"]["api"](),
      "bulk-wallet": lang["imports"]["options"]["bulk-wallet"](),
      connect: (args: { exchange: Arg }): string =>
        lang["imports"]["options"]["connect"](args) as string,
      csv: lang["imports"]["options"]["csv"](),
      oauth: (args: { exchange: Arg }): string =>
        lang["imports"]["options"]["oauth"](args) as string,
      "single-import": lang["imports"]["options"]["single-import"](),
    },
    prompt: lang["imports"]["prompt"](),
    relatedChains: {
      account: lang["imports"]["relatedChains"]["account"](),
      addBlockchain: lang["imports"]["relatedChains"]["addBlockchain"](),
      cluster: {
        dontImport: lang["imports"]["relatedChains"]["cluster"]["dontImport"](),
        ignore: lang["imports"]["relatedChains"]["cluster"]["ignore"](),
        import: lang["imports"]["relatedChains"]["cluster"]["import"](),
        text: lang["imports"]["relatedChains"]["cluster"]["text"](),
        title: lang["imports"]["relatedChains"]["cluster"]["title"](),
      },
      dontImport: lang["imports"]["relatedChains"]["dontImport"](),
      import: lang["imports"]["relatedChains"]["import"](),
      importTransactions:
        lang["imports"]["relatedChains"]["importTransactions"](),
      scanning: (args: { wallet: Arg }): string =>
        lang["imports"]["relatedChains"]["scanning"](args) as string,
      scanningNoResults: (args: { address: Arg }): string =>
        lang["imports"]["relatedChains"]["scanningNoResults"](args) as string,
      scanningSuccess: lang["imports"]["relatedChains"]["scanningSuccess"](),
      stepProgress: (args: { step: Arg; totalSteps: Arg }): string =>
        lang["imports"]["relatedChains"]["stepProgress"](args) as string,
      stillScanning: lang["imports"]["relatedChains"]["stillScanning"](),
      syncingOnChains: (args: { wallet: Arg; chains: Arg }): string =>
        lang["imports"]["relatedChains"]["syncingOnChains"](args) as string,
      title: lang["imports"]["relatedChains"]["title"](),
    },
    requestAnIntegration: lang["imports"]["requestAnIntegration"](),
    resultNotFound: lang["imports"]["resultNotFound"](),
    searchPrompt: lang["imports"]["searchPrompt"](),
    secureConnect: lang["imports"]["secureConnect"](),
    secureConnectNote: lang["imports"]["secureConnectNote"](),
    secureImport: (args: { exchangeName: Arg }): string =>
      lang["imports"]["secureImport"](args) as string,
    selectCountry: {
      chooseJurisdiction:
        lang["imports"]["selectCountry"]["chooseJurisdiction"](),
      findCountry: lang["imports"]["selectCountry"]["findCountry"](),
      pleaseSelect: lang["imports"]["selectCountry"]["pleaseSelect"](),
      search: lang["imports"]["selectCountry"]["search"](),
      somethingWentWrong: (args: { message: Arg }): string =>
        lang["imports"]["selectCountry"]["somethingWentWrong"](args) as string,
      somethingWentWrongSupport:
        lang["imports"]["selectCountry"]["somethingWentWrongSupport"](),
    },
    selectFile: lang["imports"]["selectFile"](),
    selectFiles: lang["imports"]["selectFiles"](),
    skippedRows: {
      cta: (args: { count: Arg }): string =>
        lang["imports"]["skippedRows"]["cta"](args) as string,
      errors: {
        apiError: lang["imports"]["skippedRows"]["errors"]["apiError"](),
        genericError:
          lang["imports"]["skippedRows"]["errors"]["genericError"](),
        lockPeriod: lang["imports"]["skippedRows"]["errors"]["lockPeriod"](),
        noOp: lang["imports"]["skippedRows"]["errors"]["noOp"](),
        noTransactions:
          lang["imports"]["skippedRows"]["errors"]["noTransactions"](),
        unhandledFields:
          lang["imports"]["skippedRows"]["errors"]["unhandledFields"](),
        unhandledTimestamp:
          lang["imports"]["skippedRows"]["errors"]["unhandledTimestamp"](),
        unhandledTrade:
          lang["imports"]["skippedRows"]["errors"]["unhandledTrade"](),
        userSpecifiedCurrencyIssue:
          lang["imports"]["skippedRows"]["errors"][
            "userSpecifiedCurrencyIssue"
          ](),
      },
      header: lang["imports"]["skippedRows"]["header"](),
      info: (args: { count: Arg }): string =>
        lang["imports"]["skippedRows"]["info"](args) as string,
    },
    softWalletHeading: (args: { name: Arg }): string =>
      lang["imports"]["softWalletHeading"](args) as string,
    softWalletNetworkSelect: lang["imports"]["softWalletNetworkSelect"](),
    softWalletPrompt: lang["imports"]["softWalletPrompt"](),
    solanaBalanceWarning: lang["imports"]["solanaBalanceWarning"](),
    sortImports: {
      aToZ: lang["imports"]["sortImports"]["aToZ"](),
      mostTransactions: lang["imports"]["sortImports"]["mostTransactions"](),
      zToA: lang["imports"]["sortImports"]["zToA"](),
    },
    successImportWallet: (args: { blockchain: Arg; address: Arg }): string =>
      lang["imports"]["successImportWallet"](args) as string,
    suspectedMissingImports: {
      addAccount: lang["imports"]["suspectedMissingImports"]["addAccount"](),
      chips: {
        amount: lang["imports"]["suspectedMissingImports"]["chips"]["amount"](),
        count: lang["imports"]["suspectedMissingImports"]["chips"]["count"](),
        currencies:
          lang["imports"]["suspectedMissingImports"]["chips"]["currencies"](),
      },
      explainer: (args: {
        count: Arg;
        symbol1: Arg;
        additionalCurrencyCount: Arg;
        symbol2: Arg;
        exchange: Arg;
        startDate: Arg;
        endDate: Arg;
      }): string =>
        lang["imports"]["suspectedMissingImports"]["explainer"](args) as string,
      title: lang["imports"]["suspectedMissingImports"]["title"](),
      toastBody: lang["imports"]["suspectedMissingImports"]["toastBody"](),
      toastHeading:
        lang["imports"]["suspectedMissingImports"]["toastHeading"](),
      tooltip: lang["imports"]["suspectedMissingImports"]["tooltip"](),
      viewTransactions: (args: { count: Arg }): string =>
        lang["imports"]["suspectedMissingImports"]["viewTransactions"](
          args,
        ) as string,
      whyThis: lang["imports"]["suspectedMissingImports"]["whyThis"](),
    },
    sync: lang["imports"]["sync"](),
    syncAll: lang["imports"]["syncAll"](),
    syncFailed: lang["imports"]["syncFailed"](),
    syncStatus: {
      failed: (args: { isCsv: Arg }): string =>
        lang["imports"]["syncStatus"]["failed"](args) as string,
      noSync: lang["imports"]["syncStatus"]["noSync"](),
      pending: (args: { isCsv: Arg }): string =>
        lang["imports"]["syncStatus"]["pending"](args) as string,
      queued: (args: { isCsv: Arg }): string =>
        lang["imports"]["syncStatus"]["queued"](args) as string,
      success: (args: { isCsv: Arg }): string =>
        lang["imports"]["syncStatus"]["success"](args) as string,
    },
    syncedFrom: lang["imports"]["syncedFrom"](),
    syncing: lang["imports"]["syncing"](),
    syncingWallet: (args: { name: Arg }): string =>
      lang["imports"]["syncingWallet"](args) as string,
    title: lang["imports"]["title"](),
    transactionCount: {
      noTransactions: lang["imports"]["transactionCount"]["noTransactions"](),
      noTxs: lang["imports"]["transactionCount"]["noTxs"](),
      transaction: lang["imports"]["transactionCount"]["transaction"](),
      transactions: lang["imports"]["transactionCount"]["transactions"](),
      tx: lang["imports"]["transactionCount"]["tx"](),
      txs: lang["imports"]["transactionCount"]["txs"](),
    },
    transactionsImported: lang["imports"]["transactionsImported"](),
    updatedAt: (args: { time: Arg }): string =>
      lang["imports"]["updatedAt"](args) as string,
    uploaded: (args: { time: Arg }): string =>
      lang["imports"]["uploaded"](args) as string,
    verifying: lang["imports"]["verifying"](),
    view: lang["imports"]["view"](),
    viewGuide: lang["imports"]["viewGuide"](),
    viewMode: {
      byAddress: lang["imports"]["viewMode"]["byAddress"](),
      byBlockchain: lang["imports"]["viewMode"]["byBlockchain"](),
    },
    viewSBSInstructions: lang["imports"]["viewSBSInstructions"](),
    wentWrongAvailableImports: lang["imports"]["wentWrongAvailableImports"](),
    wentWrongCurrentHoldings: lang["imports"]["wentWrongCurrentHoldings"](),
    wentWrongDeleteFile: lang["imports"]["wentWrongDeleteFile"](),
    wentWrongDeletingCSV: (args: { name: Arg }): string =>
      lang["imports"]["wentWrongDeletingCSV"](args) as string,
    wentWrongDownloadFile: lang["imports"]["wentWrongDownloadFile"](),
    wentWrongExistingSaved: lang["imports"]["wentWrongExistingSaved"](),
  },
  inTxCategoryOptionsTitle: lang["inTxCategoryOptionsTitle"](),
  incomeToggles: lang["incomeToggles"](),
  info: lang["info"](),
  instructions: lang["instructions"](),
  integrationWarnings: {
    btc: {
      invalidWallet: {
        description:
          lang["integrationWarnings"]["btc"]["invalidWallet"]["description"](),
        noTxs: lang["integrationWarnings"]["btc"]["invalidWallet"]["noTxs"](),
        title: lang["integrationWarnings"]["btc"]["invalidWallet"]["title"](),
        wrongFormat:
          lang["integrationWarnings"]["btc"]["invalidWallet"]["wrongFormat"](),
      },
    },
    default: { noTxs: lang["integrationWarnings"]["default"]["noTxs"]() },
  },
  invitation: {
    buttonText: {
      accepted: lang["invitation"]["buttonText"]["accepted"](),
      declined: lang["invitation"]["buttonText"]["declined"](),
      deleted: lang["invitation"]["buttonText"]["deleted"](),
      pending: lang["invitation"]["buttonText"]["pending"](),
      revoked: lang["invitation"]["buttonText"]["revoked"](),
      revokedLink: lang["invitation"]["buttonText"]["revokedLink"](),
    },
    confirmationDialog: {
      collaborator: {
        accepted: {
          actionText:
            lang["invitation"]["confirmationDialog"]["collaborator"][
              "accepted"
            ]["actionText"](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["collaborator"][
              "accepted"
            ]["cancelText"](),
          text: lang["invitation"]["confirmationDialog"]["collaborator"][
            "accepted"
          ]["text"](),
          title:
            lang["invitation"]["confirmationDialog"]["collaborator"][
              "accepted"
            ]["title"](),
        },
        declined: {
          actionText:
            lang["invitation"]["confirmationDialog"]["collaborator"][
              "declined"
            ]["actionText"](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["collaborator"][
              "declined"
            ]["cancelText"](),
          text: lang["invitation"]["confirmationDialog"]["collaborator"][
            "declined"
          ]["text"](),
          title:
            lang["invitation"]["confirmationDialog"]["collaborator"][
              "declined"
            ]["title"](),
        },
        deleted: {
          actionText:
            lang["invitation"]["confirmationDialog"]["collaborator"]["deleted"][
              "actionText"
            ](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["collaborator"]["deleted"][
              "cancelText"
            ](),
          text: lang["invitation"]["confirmationDialog"]["collaborator"][
            "deleted"
          ]["text"](),
          title:
            lang["invitation"]["confirmationDialog"]["collaborator"]["deleted"][
              "title"
            ](),
        },
        pending: {
          actionText:
            lang["invitation"]["confirmationDialog"]["collaborator"]["pending"][
              "actionText"
            ](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["collaborator"]["pending"][
              "cancelText"
            ](),
          text: lang["invitation"]["confirmationDialog"]["collaborator"][
            "pending"
          ]["text"](),
          title:
            lang["invitation"]["confirmationDialog"]["collaborator"]["pending"][
              "title"
            ](),
        },
        revoked: {
          actionText:
            lang["invitation"]["confirmationDialog"]["collaborator"]["revoked"][
              "actionText"
            ](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["collaborator"]["revoked"][
              "cancelText"
            ](),
          confirmationPrompt:
            lang["invitation"]["confirmationDialog"]["collaborator"]["revoked"][
              "confirmationPrompt"
            ](),
          confirmationText:
            lang["invitation"]["confirmationDialog"]["collaborator"]["revoked"][
              "confirmationText"
            ](),
          text: lang["invitation"]["confirmationDialog"]["collaborator"][
            "revoked"
          ]["text"](),
          title:
            lang["invitation"]["confirmationDialog"]["collaborator"]["revoked"][
              "title"
            ](),
        },
      },
      other: {
        accepted: {
          actionText:
            lang["invitation"]["confirmationDialog"]["other"]["accepted"][
              "actionText"
            ](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["other"]["accepted"][
              "cancelText"
            ](),
          text: lang["invitation"]["confirmationDialog"]["other"]["accepted"][
            "text"
          ](),
          title:
            lang["invitation"]["confirmationDialog"]["other"]["accepted"][
              "title"
            ](),
        },
        declined: {
          actionText:
            lang["invitation"]["confirmationDialog"]["other"]["declined"][
              "actionText"
            ](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["other"]["declined"][
              "cancelText"
            ](),
          text: lang["invitation"]["confirmationDialog"]["other"]["declined"][
            "text"
          ](),
          title:
            lang["invitation"]["confirmationDialog"]["other"]["declined"][
              "title"
            ](),
        },
        deleted: {
          actionText:
            lang["invitation"]["confirmationDialog"]["other"]["deleted"][
              "actionText"
            ](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["other"]["deleted"][
              "cancelText"
            ](),
          text: lang["invitation"]["confirmationDialog"]["other"]["deleted"][
            "text"
          ](),
          title:
            lang["invitation"]["confirmationDialog"]["other"]["deleted"][
              "title"
            ](),
        },
        pending: {
          actionText:
            lang["invitation"]["confirmationDialog"]["other"]["pending"][
              "actionText"
            ](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["other"]["pending"][
              "cancelText"
            ](),
          text: lang["invitation"]["confirmationDialog"]["other"]["pending"][
            "text"
          ](),
          title:
            lang["invitation"]["confirmationDialog"]["other"]["pending"][
              "title"
            ](),
        },
        revoked: {
          actionText:
            lang["invitation"]["confirmationDialog"]["other"]["revoked"][
              "actionText"
            ](),
          cancelText:
            lang["invitation"]["confirmationDialog"]["other"]["revoked"][
              "cancelText"
            ](),
          text: lang["invitation"]["confirmationDialog"]["other"]["revoked"][
            "text"
          ](),
          title:
            lang["invitation"]["confirmationDialog"]["other"]["revoked"][
              "title"
            ](),
        },
      },
    },
    noInvites: lang["invitation"]["noInvites"](),
    receivedInvitations: lang["invitation"]["receivedInvitations"](),
    warning: lang["invitation"]["warning"](),
    wentWrongGettingInvitations:
      lang["invitation"]["wentWrongGettingInvitations"](),
  },
  invitationStatus: {
    accepted: lang["invitationStatus"]["accepted"](),
    declined: lang["invitationStatus"]["declined"](),
    deleted: lang["invitationStatus"]["deleted"](),
    notInvited: lang["invitationStatus"]["notInvited"](),
    pending: lang["invitationStatus"]["pending"](),
    revoked: lang["invitationStatus"]["revoked"](),
    revokedLink: lang["invitationStatus"]["revokedLink"](),
  },
  invite: lang["invite"](),
  keys: {
    deprecated: lang["keys"]["deprecated"](),
    deprecatedInfo: (args: { exchange: Arg; linkTextKey: Arg }): string =>
      lang["keys"]["deprecatedInfo"](args) as string,
    deprecatedInfoByExchange: {
      coinbase: (args: { exchange: Arg; linkTextKey: Arg }): string =>
        lang["keys"]["deprecatedInfoByExchange"]["coinbase"](args) as string,
    },
    wentWrongDeleteExchangeAPI: (args: { name: Arg }): string =>
      lang["keys"]["wentWrongDeleteExchangeAPI"](args) as string,
    wentWrongDeletingAPI: lang["keys"]["wentWrongDeletingAPI"](),
    wentWrongSavingAPI: (args: { name: Arg }): string =>
      lang["keys"]["wentWrongSavingAPI"](args) as string,
    wentWrongUpdatingAPI: (args: { name: Arg }): string =>
      lang["keys"]["wentWrongUpdatingAPI"](args) as string,
  },
  labels: {
    addExistingLabels: lang["labels"]["addExistingLabels"](),
    addLabel: lang["labels"]["addLabel"](),
    cancel: lang["labels"]["cancel"](),
    createNewLabel: lang["labels"]["createNewLabel"](),
    labelColor: lang["labels"]["labelColor"](),
    labelDescription: lang["labels"]["labelDescription"](),
    labelName: lang["labels"]["labelName"](),
    noLabels: lang["labels"]["noLabels"](),
    saveAndAdd: lang["labels"]["saveAndAdd"](),
  },
  language: lang["language"](),
  learnMore: lang["learnMore"](),
  ledger: {
    banner: {
      text: lang["ledger"]["banner"]["text"](),
      title: lang["ledger"]["banner"]["title"](),
    },
    confirmations: {
      deleteTransactionConfirmation: {
        text: lang["ledger"]["confirmations"]["deleteTransactionConfirmation"][
          "text"
        ](),
        title:
          lang["ledger"]["confirmations"]["deleteTransactionConfirmation"][
            "title"
          ](),
      },
      ignoreTransactionConfirmation: {
        text: lang["ledger"]["confirmations"]["ignoreTransactionConfirmation"][
          "text"
        ](),
        title:
          lang["ledger"]["confirmations"]["ignoreTransactionConfirmation"][
            "title"
          ](),
      },
    },
    deposit: lang["ledger"]["deposit"](),
    descriptions: {
      amountAcquired: (args: { ticker: Arg }): string =>
        lang["ledger"]["descriptions"]["amountAcquired"](args) as string,
      amountDisposed: (args: { ticker: Arg }): string =>
        lang["ledger"]["descriptions"]["amountDisposed"](args) as string,
      amountMissing: (args: { ticker: Arg }): string =>
        lang["ledger"]["descriptions"]["amountMissing"](args) as string,
      amountUncategorised: (args: { ticker: Arg }): string =>
        lang["ledger"]["descriptions"]["amountUncategorised"](args) as string,
    },
    errors: { unableLoadRecon: lang["ledger"]["errors"]["unableLoadRecon"]() },
    filters: {
      onlyShowNegativeBalance:
        lang["ledger"]["filters"]["onlyShowNegativeBalance"](),
    },
    goBack: lang["ledger"]["goBack"](),
    headings: {
      amountNegative: lang["ledger"]["headings"]["amountNegative"](),
      holdings: lang["ledger"]["headings"]["holdings"](),
      taxImpact: lang["ledger"]["headings"]["taxImpact"](),
      transactions: lang["ledger"]["headings"]["transactions"](),
      value: lang["ledger"]["headings"]["value"](),
    },
    headings2: {
      amountAcquired: lang["ledger"]["headings2"]["amountAcquired"](),
      amountDisposed: lang["ledger"]["headings2"]["amountDisposed"](),
      amountMissing: lang["ledger"]["headings2"]["amountMissing"](),
      amountUncategorised: lang["ledger"]["headings2"]["amountUncategorised"](),
      transactions: lang["ledger"]["headings2"]["transactions"](),
    },
    ledger: lang["ledger"]["ledger"](),
    ledgerSourceFilter: {
      accounts: lang["ledger"]["ledgerSourceFilter"]["accounts"](),
      noOptions: lang["ledger"]["ledgerSourceFilter"]["noOptions"](),
      placeholder: lang["ledger"]["ledgerSourceFilter"]["placeholder"](),
      selectAll: lang["ledger"]["ledgerSourceFilter"]["selectAll"](),
    },
    loading: lang["ledger"]["loading"](),
    negativeBalanceOverallTooltip:
      lang["ledger"]["negativeBalanceOverallTooltip"](),
    negativeBalanceTooltip: (args: { ticker: Arg }): string =>
      lang["ledger"]["negativeBalanceTooltip"](args) as string,
    newest: lang["ledger"]["newest"](),
    notFound: lang["ledger"]["notFound"](),
    oldest: lang["ledger"]["oldest"](),
    pageTitle: (args: { currencyName: Arg }): string =>
      lang["ledger"]["pageTitle"](args) as string,
    tableHeader: {
      account: lang["ledger"]["tableHeader"]["account"](),
      amount: lang["ledger"]["tableHeader"]["amount"](),
      dateAndType: lang["ledger"]["tableHeader"]["dateAndType"](),
      overallBalance: lang["ledger"]["tableHeader"]["overallBalance"](),
      tooltips: {
        account: lang["ledger"]["tableHeader"]["tooltips"]["account"](),
        overallBalance:
          lang["ledger"]["tableHeader"]["tooltips"]["overallBalance"](),
      },
    },
    uncategorisedTooltip: lang["ledger"]["uncategorisedTooltip"](),
    unmatchedTooltip: lang["ledger"]["unmatchedTooltip"](),
    withdrawal: lang["ledger"]["withdrawal"](),
  },
  legacyPlanTooltip: lang["legacyPlanTooltip"](),
  manual: {
    Date: lang["manual"]["Date"](),
    addNewTransaction: lang["manual"]["addNewTransaction"](),
    addTransaction: lang["manual"]["addTransaction"](),
    currency: lang["manual"]["currency"](),
    feeCurrency: lang["manual"]["feeCurrency"](),
    feeQuantity: lang["manual"]["feeQuantity"](),
    from: lang["manual"]["from"](),
    price: lang["manual"]["price"](),
    quantity: lang["manual"]["quantity"](),
    quoteCurrency: lang["manual"]["quoteCurrency"](),
    quoteQuantity: lang["manual"]["quoteQuantity"](),
    receiveCurrency: lang["manual"]["receiveCurrency"](),
    receiveQuantity: lang["manual"]["receiveQuantity"](),
    requiredText: {
      currency: lang["manual"]["requiredText"]["currency"](),
      feeCurrency: lang["manual"]["requiredText"]["feeCurrency"](),
      feeQuantity: lang["manual"]["requiredText"]["feeQuantity"](),
      notInLockedPeriod: lang["manual"]["requiredText"]["notInLockedPeriod"](),
      price: lang["manual"]["requiredText"]["price"](),
      quantity: lang["manual"]["requiredText"]["quantity"](),
      time: (args: { timeFormat: Arg }): string =>
        lang["manual"]["requiredText"]["time"](args) as string,
      value: lang["manual"]["requiredText"]["value"](),
    },
    sellCurrency: lang["manual"]["sellCurrency"](),
    sellQuantity: lang["manual"]["sellQuantity"](),
    stepTitle: {
      category: lang["manual"]["stepTitle"]["category"](),
      details: (args: { category: Arg }): string =>
        lang["manual"]["stepTitle"]["details"](args) as string,
      fees: lang["manual"]["stepTitle"]["fees"](),
      sourceAndDest: lang["manual"]["stepTitle"]["sourceAndDest"](),
    },
    time: lang["manual"]["time"](),
    to: lang["manual"]["to"](),
    trade: lang["manual"]["trade"](),
    transactionType: {
      other: lang["manual"]["transactionType"]["other"](),
      trade: lang["manual"]["transactionType"]["trade"](),
      transfer: lang["manual"]["transactionType"]["transfer"](),
    },
    transactionTypeDescription: {
      other: lang["manual"]["transactionTypeDescription"]["other"](),
      trade: lang["manual"]["transactionTypeDescription"]["trade"](),
      transfer: lang["manual"]["transactionTypeDescription"]["transfer"](),
    },
    value: lang["manual"]["value"](),
  },
  manualCSV: {
    addCustomCSV: lang["manualCSV"]["addCustomCSV"](),
    advanced: {
      link: lang["manualCSV"]["advanced"]["link"](),
      part1: lang["manualCSV"]["advanced"]["part1"](),
      part2: lang["manualCSV"]["advanced"]["part2"](),
    },
    advancedOptions: lang["manualCSV"]["advancedOptions"](),
    cryptoGroup: lang["manualCSV"]["cryptoGroup"](),
    dateFormatHelper: lang["manualCSV"]["dateFormatHelper"](),
    example: lang["manualCSV"]["example"](),
    exchange: lang["manualCSV"]["exchange"](),
    exchangeRule: lang["manualCSV"]["exchangeRule"](),
    fiatGroup: lang["manualCSV"]["fiatGroup"](),
    here: lang["manualCSV"]["here"](),
    importFromDateHelper: lang["manualCSV"]["importFromDateHelper"](),
    importHelper: {
      helpText: lang["manualCSV"]["importHelper"]["helpText"](),
      link: lang["manualCSV"]["importHelper"]["link"](),
    },
    manualGroup: lang["manualCSV"]["manualGroup"](),
    manualSelectCurrencies: lang["manualCSV"]["manualSelectCurrencies"](),
    manualSelectDateFormat: lang["manualCSV"]["manualSelectDateFormat"](),
    manualSelectImportFromDate:
      lang["manualCSV"]["manualSelectImportFromDate"](),
    manualSelectTimezone: lang["manualCSV"]["manualSelectTimezone"](),
    nameExists: lang["manualCSV"]["nameExists"](),
    nameTooLong: lang["manualCSV"]["nameTooLong"](),
    noOptions: lang["manualCSV"]["noOptions"](),
    patternRule: lang["manualCSV"]["patternRule"](),
    pendingUploadMessage: {
      manyFiles: (args: { numFiles: Arg }): string =>
        lang["manualCSV"]["pendingUploadMessage"]["manyFiles"](args) as string,
      singleFile: (args: { filename: Arg }): string =>
        lang["manualCSV"]["pendingUploadMessage"]["singleFile"](args) as string,
    },
    preparationWarning: lang["manualCSV"]["preparationWarning"](),
    selectCurrencies: lang["manualCSV"]["selectCurrencies"](),
    selectDateFormat: lang["manualCSV"]["selectDateFormat"](),
    simple: {
      link: lang["manualCSV"]["simple"]["link"](),
      part1: lang["manualCSV"]["simple"]["part1"](),
      part2: lang["manualCSV"]["simple"]["part2"](),
    },
    tickerHelper: lang["manualCSV"]["tickerHelper"](),
    timezoneHelper: lang["manualCSV"]["timezoneHelper"](),
    title: lang["manualCSV"]["title"](),
    uploadFileWarning: lang["manualCSV"]["uploadFileWarning"](),
  },
  markAsReviewed: lang["markAsReviewed"](),
  markAsUnreviewed: lang["markAsUnreviewed"](),
  mobileDemandTest: {
    buttonText: lang["mobileDemandTest"]["buttonText"](),
    chip: lang["mobileDemandTest"]["chip"](),
    description: lang["mobileDemandTest"]["description"](),
    noThanks: lang["mobileDemandTest"]["noThanks"](),
    registerInterest: lang["mobileDemandTest"]["registerInterest"](),
    title: lang["mobileDemandTest"]["title"](),
    toast: {
      description: lang["mobileDemandTest"]["toast"]["description"](),
      title: lang["mobileDemandTest"]["toast"]["title"](),
    },
  },
  mockReport: {
    disabled: lang["mockReport"]["disabled"](),
    infoBanner: {
      button: lang["mockReport"]["infoBanner"]["button"](),
      subtext: lang["mockReport"]["infoBanner"]["subtext"](),
      title: lang["mockReport"]["infoBanner"]["title"](),
    },
    signup: {
      button: (args: { isAccountant: Arg }): string =>
        lang["mockReport"]["signup"]["button"](args) as string,
      errorMessage: lang["mockReport"]["signup"]["errorMessage"](),
      subtext: (args: {
        isAccountant: Arg;
        txCount: Arg;
        suggestedPlan: Arg;
      }): string => lang["mockReport"]["signup"]["subtext"](args) as string,
      title: (args: { isAccountant: Arg; clientName: Arg }): string =>
        lang["mockReport"]["signup"]["title"](args) as string,
    },
    testData: lang["mockReport"]["testData"](),
  },
  na: lang["na"](),
  name: lang["name"](),
  nav: {
    billing: lang["nav"]["billing"](),
    contacts: lang["nav"]["contacts"](),
    developer: lang["nav"]["developer"](),
    imports: lang["nav"]["imports"](),
    logout: lang["nav"]["logout"](),
    manageSubscription: lang["nav"]["manageSubscription"](),
    manual: lang["nav"]["manual"](),
    menu: lang["nav"]["menu"](),
    plans: lang["nav"]["plans"](),
    report: lang["nav"]["report"](),
    settings: lang["nav"]["settings"](),
    transactions: lang["nav"]["transactions"](),
    wallet: lang["nav"]["wallet"](),
    warnings: lang["nav"]["warnings"](),
  },
  navbar: {
    accountingIntegrations: lang["navbar"]["accountingIntegrations"](),
    addressBook: lang["navbar"]["addressBook"](),
    clientSettings: lang["navbar"]["clientSettings"](),
    clients: lang["navbar"]["clients"](),
    cryptoIntegrations: lang["navbar"]["cryptoIntegrations"](),
    dashboard: lang["navbar"]["dashboard"](),
    followUsOnX: lang["navbar"]["followUsOnX"](),
    footer: {
      buttonCta: lang["navbar"]["footer"]["buttonCta"](),
      referralXOffAllPlans: (args: { discount: Arg }): string =>
        lang["navbar"]["footer"]["referralXOffAllPlans"](args) as string,
      xPercentOffAllPlans: (args: { discount: Arg }): string =>
        lang["navbar"]["footer"]["xPercentOffAllPlans"](args) as string,
      youHaveXTransactions: (args: { count: Arg }): string =>
        lang["navbar"]["footer"]["youHaveXTransactions"](args) as string,
    },
    help: lang["navbar"]["help"](),
    helpCenter: lang["navbar"]["helpCenter"](),
    imports: lang["navbar"]["imports"](),
    integrations: lang["navbar"]["integrations"](),
    more: lang["navbar"]["more"](),
    myClients: lang["navbar"]["myClients"](),
    mySettings: lang["navbar"]["mySettings"](),
    myTeam: lang["navbar"]["myTeam"](),
    portfolio: lang["navbar"]["portfolio"](),
    reconciliation: lang["navbar"]["reconciliation"](),
    referAFriend: lang["navbar"]["referAFriend"](),
    referFriends: lang["navbar"]["referFriends"](),
    referFriendsV2: (args: { reward: Arg }): string =>
      lang["navbar"]["referFriendsV2"](args) as string,
    referGetHundredCash: (args: { reward: Arg }): string =>
      lang["navbar"]["referGetHundredCash"](args) as string,
    report: lang["navbar"]["report"](),
    review: lang["navbar"]["review"](),
    rules: lang["navbar"]["rules"](),
    selectClient: lang["navbar"]["selectClient"](),
    settings: lang["navbar"]["settings"](),
    subheadings: {
      integrations: lang["navbar"]["subheadings"]["integrations"](),
      reconciliation: lang["navbar"]["subheadings"]["reconciliation"](),
      reports: lang["navbar"]["subheadings"]["reports"](),
    },
    subscribe: lang["navbar"]["subscribe"](),
    support: lang["navbar"]["support"](),
    tips: lang["navbar"]["tips"](),
    title: lang["navbar"]["title"](),
    tooltip: (args: { linkTextKey: Arg }): string =>
      lang["navbar"]["tooltip"](args) as string,
    tooltipReview: (args: { linkTextKey: Arg }): string =>
      lang["navbar"]["tooltipReview"](args) as string,
    transactionSubItems: {
      failed: lang["navbar"]["transactionSubItems"]["failed"](),
      notReadyToSync: lang["navbar"]["transactionSubItems"]["notReadyToSync"](),
      outOfDate: lang["navbar"]["transactionSubItems"]["outOfDate"](),
      readyToSync: lang["navbar"]["transactionSubItems"]["readyToSync"](),
      synced: lang["navbar"]["transactionSubItems"]["synced"](),
    },
    transactions: lang["navbar"]["transactions"](),
  },
  new: lang["new"](),
  newTxModal: {
    actionButton: lang["newTxModal"]["actionButton"](),
    back: lang["newTxModal"]["back"](),
    description: [
      lang["newTxModal"]["description"][0](),
      lang["newTxModal"]["description"][1](),
      lang["newTxModal"]["description"][2](),
      lang["newTxModal"]["description"][3](),
    ],
    nextPage: lang["newTxModal"]["nextPage"](),
    title: lang["newTxModal"]["title"](),
  },
  next: lang["next"](),
  no: lang["no"](),
  noResults: lang["noResults"](),
  none: lang["none"](),
  notifications: {
    missingBlockchain: {
      blogLink: lang["notifications"]["missingBlockchain"]["blogLink"](),
      cta: lang["notifications"]["missingBlockchain"]["cta"](),
      link: lang["notifications"]["missingBlockchain"]["link"](),
      primary: lang["notifications"]["missingBlockchain"]["primary"](),
      secondary: lang["notifications"]["missingBlockchain"]["secondary"](),
    },
    missingPrice: {
      blogLink: lang["notifications"]["missingPrice"]["blogLink"](),
      cta: lang["notifications"]["missingPrice"]["cta"](),
      link: lang["notifications"]["missingPrice"]["link"](),
      primary: lang["notifications"]["missingPrice"]["primary"](),
      secondary: lang["notifications"]["missingPrice"]["secondary"](),
    },
    negativeBalance: {
      blogLink: lang["notifications"]["negativeBalance"]["blogLink"](),
      cta: lang["notifications"]["negativeBalance"]["cta"](),
      link: lang["notifications"]["negativeBalance"]["link"](),
      primary: lang["notifications"]["negativeBalance"]["primary"](),
      secondary: lang["notifications"]["negativeBalance"]["secondary"](),
    },
    uncategorised: {
      blogLink: lang["notifications"]["uncategorised"]["blogLink"](),
      cta: lang["notifications"]["uncategorised"]["cta"](),
      link: lang["notifications"]["uncategorised"]["link"](),
      primary: lang["notifications"]["uncategorised"]["primary"](),
      secondary: lang["notifications"]["uncategorised"]["secondary"](),
    },
    zeroCostBuy: {
      blogLink: lang["notifications"]["zeroCostBuy"]["blogLink"](),
      blogLink2: lang["notifications"]["zeroCostBuy"]["blogLink2"](),
      cta: lang["notifications"]["zeroCostBuy"]["cta"](),
      link: lang["notifications"]["zeroCostBuy"]["link"](),
      primary: lang["notifications"]["zeroCostBuy"]["primary"](),
      secondary: lang["notifications"]["zeroCostBuy"]["secondary"](),
    },
  },
  onboardingV2: {
    LetsReviewTx: {
      body1: lang["onboardingV2"]["LetsReviewTx"]["body1"](),
      body2: lang["onboardingV2"]["LetsReviewTx"]["body2"](),
      cta: lang["onboardingV2"]["LetsReviewTx"]["cta"](),
      title: lang["onboardingV2"]["LetsReviewTx"]["title"](),
    },
    accountConfirmation: {
      CTA: lang["onboardingV2"]["accountConfirmation"]["CTA"](),
      nonCTA: lang["onboardingV2"]["accountConfirmation"]["nonCTA"](),
      subtitle: lang["onboardingV2"]["accountConfirmation"]["subtitle"](),
      title: (args: { count: Arg }): string =>
        lang["onboardingV2"]["accountConfirmation"]["title"](args) as string,
      title2: lang["onboardingV2"]["accountConfirmation"]["title2"](),
    },
    accountCountry: {
      plusMore: (args: { count: Arg }): string =>
        lang["onboardingV2"]["accountCountry"]["plusMore"](args) as string,
      showLess: lang["onboardingV2"]["accountCountry"]["showLess"](),
      showMore: lang["onboardingV2"]["accountCountry"]["showMore"](),
      subtitle: lang["onboardingV2"]["accountCountry"]["subtitle"](),
      title: lang["onboardingV2"]["accountCountry"]["title"](),
    },
    accountList: {
      addAnotherAccount:
        lang["onboardingV2"]["accountList"]["addAnotherAccount"](),
      yourAccounts: lang["onboardingV2"]["accountList"]["yourAccounts"](),
    },
    accountTheme: {
      footerButton: lang["onboardingV2"]["accountTheme"]["footerButton"](),
      subtitle: lang["onboardingV2"]["accountTheme"]["subtitle"](),
      themes: {
        dark: lang["onboardingV2"]["accountTheme"]["themes"]["dark"](),
        light: lang["onboardingV2"]["accountTheme"]["themes"]["light"](),
        system: lang["onboardingV2"]["accountTheme"]["themes"]["system"](),
      },
      title: lang["onboardingV2"]["accountTheme"]["title"](),
    },
    accountType: {
      footerButton: lang["onboardingV2"]["accountType"]["footerButton"](),
      securityCta: lang["onboardingV2"]["accountType"]["securityCta"](),
      subtitle: lang["onboardingV2"]["accountType"]["subtitle"](),
      title: lang["onboardingV2"]["accountType"]["title"](),
      types: {
        accountant:
          lang["onboardingV2"]["accountType"]["types"]["accountant"](),
        individual:
          lang["onboardingV2"]["accountType"]["types"]["individual"](),
      },
    },
    accuracy: {
      content: {
        accounts: lang["onboardingV2"]["accuracy"]["content"]["accounts"](),
        categorise: lang["onboardingV2"]["accuracy"]["content"]["categorise"](),
        complete: lang["onboardingV2"]["accuracy"]["content"]["complete"](),
        considerations:
          lang["onboardingV2"]["accuracy"]["content"]["considerations"](),
        costBase: lang["onboardingV2"]["accuracy"]["content"]["costBase"](),
        determine: lang["onboardingV2"]["accuracy"]["content"]["determine"](),
        ensure: lang["onboardingV2"]["accuracy"]["content"]["ensure"](),
        import: lang["onboardingV2"]["accuracy"]["content"]["import"](),
        inactive: lang["onboardingV2"]["accuracy"]["content"]["inactive"](),
        noAccess: lang["onboardingV2"]["accuracy"]["content"]["noAccess"](),
      },
      footerBack: lang["onboardingV2"]["accuracy"]["footerBack"](),
      footerNext: lang["onboardingV2"]["accuracy"]["footerNext"](),
      subtitle: lang["onboardingV2"]["accuracy"]["subtitle"](),
      title: lang["onboardingV2"]["accuracy"]["title"](),
    },
    areYouSure: {
      body1: {
        costBasis: lang["onboardingV2"]["areYouSure"]["body1"]["costBasis"](),
        costBasisTooltip:
          lang["onboardingV2"]["areYouSure"]["body1"]["costBasisTooltip"](),
        frontPart: lang["onboardingV2"]["areYouSure"]["body1"]["frontPart"](),
        lastPart: lang["onboardingV2"]["areYouSure"]["body1"]["lastPart"](),
      },
      body2: {
        accounts: lang["onboardingV2"]["areYouSure"]["body2"]["accounts"](),
        accountsTooltip:
          lang["onboardingV2"]["areYouSure"]["body2"]["accountsTooltip"](),
        frontPart: lang["onboardingV2"]["areYouSure"]["body2"]["frontPart"](),
        lastPart: lang["onboardingV2"]["areYouSure"]["body2"]["lastPart"](),
      },
      buttons: {
        doneImport:
          lang["onboardingV2"]["areYouSure"]["buttons"]["doneImport"](),
        importMore:
          lang["onboardingV2"]["areYouSure"]["buttons"]["importMore"](),
      },
      footer: lang["onboardingV2"]["areYouSure"]["footer"](),
      title: lang["onboardingV2"]["areYouSure"]["title"](),
    },
    calculating: {
      autoSaved: lang["onboardingV2"]["calculating"]["autoSaved"](),
      buttons: {
        continue: lang["onboardingV2"]["calculating"]["buttons"]["continue"](),
        finish: lang["onboardingV2"]["calculating"]["buttons"]["finish"](),
        goBack: lang["onboardingV2"]["calculating"]["buttons"]["goBack"](),
        importMore:
          lang["onboardingV2"]["calculating"]["buttons"]["importMore"](),
        skip: lang["onboardingV2"]["calculating"]["buttons"]["skip"](),
      },
      loading10Minutes:
        lang["onboardingV2"]["calculating"]["loading10Minutes"](),
      loading3Minutes: lang["onboardingV2"]["calculating"]["loading3Minutes"](),
      loadingETA: lang["onboardingV2"]["calculating"]["loadingETA"](),
      loadingETAFake: lang["onboardingV2"]["calculating"]["loadingETAFake"](),
      loadingTakingLongerThanUsual:
        lang["onboardingV2"]["calculating"]["loadingTakingLongerThanUsual"](),
      loadingText: {
        calculatingTheCostBaseForEachAsset:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "calculatingTheCostBaseForEachAsset"
          ](),
        categorisingYourTransactions:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "categorisingYourTransactions"
          ](),
        categorizingBridges:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "categorizingBridges"
          ](),
        categorizingStaking:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "categorizingStaking"
          ](),
        categorizingSwaps:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "categorizingSwaps"
          ](),
        categorizingTrades:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "categorizingTrades"
          ](),
        categorizingTransfers:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "categorizingTransfers"
          ](),
        computingGainsForEachTransaction:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "computingGainsForEachTransaction"
          ](),
        detectingAnyCryptoIncome:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "detectingAnyCryptoIncome"
          ](),
        finalizingInitialTaxReports:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "finalizingInitialTaxReports"
          ](),
        importingTransactionData:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "importingTransactionData"
          ](),
        summarizingCapitalGainByTaxYear:
          lang["onboardingV2"]["calculating"]["loadingText"][
            "summarizingCapitalGainByTaxYear"
          ](),
      },
      titleCalculationsComplete:
        lang["onboardingV2"]["calculating"]["titleCalculationsComplete"](),
    },
    cexImportReminder: {
      bodyText: lang["onboardingV2"]["cexImportReminder"]["bodyText"](),
      modalText: lang["onboardingV2"]["cexImportReminder"]["modalText"](),
      primaryButton:
        lang["onboardingV2"]["cexImportReminder"]["primaryButton"](),
      secondaryButton:
        lang["onboardingV2"]["cexImportReminder"]["secondaryButton"](),
      title: lang["onboardingV2"]["cexImportReminder"]["title"](),
    },
    coinbaseOauth: {
      import: {
        connectCoinbase:
          lang["onboardingV2"]["coinbaseOauth"]["import"]["connectCoinbase"](),
        continue: lang["onboardingV2"]["coinbaseOauth"]["import"]["continue"](),
        importedSubtitle:
          lang["onboardingV2"]["coinbaseOauth"]["import"]["importedSubtitle"](),
        importedTitle:
          lang["onboardingV2"]["coinbaseOauth"]["import"]["importedTitle"](),
        importingBanner:
          lang["onboardingV2"]["coinbaseOauth"]["import"]["importingBanner"](),
        importingBannerLink:
          lang["onboardingV2"]["coinbaseOauth"]["import"][
            "importingBannerLink"
          ](),
        importingSubtitle:
          lang["onboardingV2"]["coinbaseOauth"]["import"][
            "importingSubtitle"
          ](),
        importingTitle:
          lang["onboardingV2"]["coinbaseOauth"]["import"]["importingTitle"](),
        skipConnectCoinbase:
          lang["onboardingV2"]["coinbaseOauth"]["import"][
            "skipConnectCoinbase"
          ](),
        skipConnectCoinbaseCTA:
          lang["onboardingV2"]["coinbaseOauth"]["import"][
            "skipConnectCoinbaseCTA"
          ](),
        subtitle: lang["onboardingV2"]["coinbaseOauth"]["import"]["subtitle"](),
        title: lang["onboardingV2"]["coinbaseOauth"]["import"]["title"](),
      },
      welcomeTitle: lang["onboardingV2"]["coinbaseOauth"]["welcomeTitle"](),
    },
    competitorSurvey: {
      options: {
        no: lang["onboardingV2"]["competitorSurvey"]["options"]["no"](),
        yes: lang["onboardingV2"]["competitorSurvey"]["options"]["yes"](),
      },
      title: lang["onboardingV2"]["competitorSurvey"]["title"](),
    },
    example: lang["onboardingV2"]["example"](),
    import: {
      autoImported: lang["onboardingV2"]["import"]["autoImported"](),
      footer: lang["onboardingV2"]["import"]["footer"](),
      footerCTA: lang["onboardingV2"]["import"]["footerCTA"](),
      hasSavedAccounts: {
        finishImport:
          lang["onboardingV2"]["import"]["hasSavedAccounts"]["finishImport"](),
        keepAdding:
          lang["onboardingV2"]["import"]["hasSavedAccounts"]["keepAdding"](),
        or: lang["onboardingV2"]["import"]["hasSavedAccounts"]["or"](),
        subTitle:
          lang["onboardingV2"]["import"]["hasSavedAccounts"]["subTitle"](),
        title: lang["onboardingV2"]["import"]["hasSavedAccounts"]["title"](),
      },
      importManualCSV: lang["onboardingV2"]["import"]["importManualCSV"](),
      navBar: {
        importType: {
          api: lang["onboardingV2"]["import"]["navBar"]["importType"]["api"](),
          "bulk-wallet":
            lang["onboardingV2"]["import"]["navBar"]["importType"][
              "bulk-wallet"
            ](),
          "connect-wallet":
            lang["onboardingV2"]["import"]["navBar"]["importType"][
              "connect-wallet"
            ](),
          csv: lang["onboardingV2"]["import"]["navBar"]["importType"]["csv"](),
          oauth:
            lang["onboardingV2"]["import"]["navBar"]["importType"]["oauth"](),
          "soft-wallet":
            lang["onboardingV2"]["import"]["navBar"]["importType"][
              "soft-wallet"
            ](),
          wallet:
            lang["onboardingV2"]["import"]["navBar"]["importType"]["wallet"](),
        },
      },
      searchBar: {
        importWallet:
          lang["onboardingV2"]["import"]["searchBar"]["importWallet"](),
        placeholder:
          lang["onboardingV2"]["import"]["searchBar"]["placeholder"](),
        section: {
          allOthers:
            lang["onboardingV2"]["import"]["searchBar"]["section"][
              "allOthers"
            ](),
          manual:
            lang["onboardingV2"]["import"]["searchBar"]["section"]["manual"](),
          popular:
            lang["onboardingV2"]["import"]["searchBar"]["section"]["popular"](),
        },
      },
      subTitle: lang["onboardingV2"]["import"]["subTitle"](),
      title: lang["onboardingV2"]["import"]["title"](),
    },
    metamask: {
      accurateCpaEndorsed:
        lang["onboardingV2"]["metamask"]["accurateCpaEndorsed"](),
      accurateCryptoTaxes:
        lang["onboardingV2"]["metamask"]["accurateCryptoTaxes"](),
      continueWith: (args: { provider: Arg }): string =>
        lang["onboardingV2"]["metamask"]["continueWith"](args) as string,
      cryptoTaxCalculator:
        lang["onboardingV2"]["metamask"]["cryptoTaxCalculator"](),
      cta: lang["onboardingV2"]["metamask"]["cta"](),
      disclaimer: lang["onboardingV2"]["metamask"]["disclaimer"](),
      fullSupportIRS: lang["onboardingV2"]["metamask"]["fullSupportIRS"](),
      manageCryptoTaxes:
        lang["onboardingV2"]["metamask"]["manageCryptoTaxes"](),
      newCustomersDiscount:
        lang["onboardingV2"]["metamask"]["newCustomersDiscount"](),
      partnerAlt: lang["onboardingV2"]["metamask"]["partnerAlt"](),
      previewAlt: lang["onboardingV2"]["metamask"]["previewAlt"](),
      skip: lang["onboardingV2"]["metamask"]["skip"](),
      supportsAllWallets:
        lang["onboardingV2"]["metamask"]["supportsAllWallets"](),
      taxhubPoweredBy: lang["onboardingV2"]["metamask"]["taxhubPoweredBy"](),
    },
    onMobileBanner: {
      title: lang["onboardingV2"]["onMobileBanner"]["title"](),
    },
    onYourWay: {
      title: lang["onboardingV2"]["onYourWay"]["title"](),
      viewPlans: lang["onboardingV2"]["onYourWay"]["viewPlans"](),
      viewReports: lang["onboardingV2"]["onYourWay"]["viewReports"](),
    },
    platformTypes: {
      cex: lang["onboardingV2"]["platformTypes"]["cex"](),
      count: (args: { count: Arg }): string =>
        lang["onboardingV2"]["platformTypes"]["count"](args) as string,
      defi: lang["onboardingV2"]["platformTypes"]["defi"](),
      footerBack: lang["onboardingV2"]["platformTypes"]["footerBack"](),
      footerNext: lang["onboardingV2"]["platformTypes"]["footerNext"](),
      footerSkip: lang["onboardingV2"]["platformTypes"]["footerSkip"](),
      subtitle: lang["onboardingV2"]["platformTypes"]["subtitle"](),
      title: lang["onboardingV2"]["platformTypes"]["title"](),
    },
    progressBar: {
      confirmCountry: lang["onboardingV2"]["progressBar"]["confirmCountry"](),
      freeTaxPreview: lang["onboardingV2"]["progressBar"]["freeTaxPreview"](),
      importData: lang["onboardingV2"]["progressBar"]["importData"](),
      selectIntegration:
        lang["onboardingV2"]["progressBar"]["selectIntegration"](),
    },
    provider: {
      connect: {
        "2fa": lang["onboardingV2"]["provider"]["connect"]["2fa"](),
        cta: lang["onboardingV2"]["provider"]["connect"]["cta"](),
        disclaimer: (args: { provider: Arg }): string =>
          lang["onboardingV2"]["provider"]["connect"]["disclaimer"](
            args,
          ) as string,
        email: lang["onboardingV2"]["provider"]["connect"]["email"](),
        password: lang["onboardingV2"]["provider"]["connect"]["password"](),
        subtitle: lang["onboardingV2"]["provider"]["connect"]["subtitle"](),
        title: lang["onboardingV2"]["provider"]["connect"]["title"](),
      },
      setPassword: {
        cta: lang["onboardingV2"]["provider"]["setPassword"]["cta"](),
        email: lang["onboardingV2"]["provider"]["setPassword"]["email"](),
        password: lang["onboardingV2"]["provider"]["setPassword"]["password"](),
        passwordsDoNotMatch:
          lang["onboardingV2"]["provider"]["setPassword"][
            "passwordsDoNotMatch"
          ](),
        retypePassword:
          lang["onboardingV2"]["provider"]["setPassword"]["retypePassword"](),
        title: lang["onboardingV2"]["provider"]["setPassword"]["title"](),
      },
      setPasswordSuccess: {
        cta: lang["onboardingV2"]["provider"]["setPasswordSuccess"]["cta"](),
        description:
          lang["onboardingV2"]["provider"]["setPasswordSuccess"][
            "description"
          ](),
        providerLoginNote: (args: { provider: Arg }): string =>
          lang["onboardingV2"]["provider"]["setPasswordSuccess"][
            "providerLoginNote"
          ](args) as string,
        title:
          lang["onboardingV2"]["provider"]["setPasswordSuccess"]["title"](),
      },
      syncing: {
        bannerCTA: lang["onboardingV2"]["provider"]["syncing"]["bannerCTA"](),
        bannerText: (args: { provider: Arg }): string =>
          lang["onboardingV2"]["provider"]["syncing"]["bannerText"](
            args,
          ) as string,
        completeTitle:
          lang["onboardingV2"]["provider"]["syncing"]["completeTitle"](),
        getTaxReportCta:
          lang["onboardingV2"]["provider"]["syncing"]["getTaxReportCta"](),
        inProgressTitle:
          lang["onboardingV2"]["provider"]["syncing"]["inProgressTitle"](),
        viewTaxSummaryCta:
          lang["onboardingV2"]["provider"]["syncing"]["viewTaxSummaryCta"](),
      },
      welcome: {
        cta: lang["onboardingV2"]["provider"]["welcome"]["cta"](),
        description:
          lang["onboardingV2"]["provider"]["welcome"]["description"](),
        items: [
          lang["onboardingV2"]["provider"]["welcome"]["items"][0](),
          lang["onboardingV2"]["provider"]["welcome"]["items"][1](),
          lang["onboardingV2"]["provider"]["welcome"]["items"][2](),
          lang["onboardingV2"]["provider"]["welcome"]["items"][3](),
          lang["onboardingV2"]["provider"]["welcome"]["items"][4](),
        ],
        title: lang["onboardingV2"]["provider"]["welcome"]["title"](),
      },
    },
    security: {
      buttonText: lang["onboardingV2"]["security"]["buttonText"](),
      privacy: {
        description:
          lang["onboardingV2"]["security"]["privacy"]["description"](),
        title: lang["onboardingV2"]["security"]["privacy"]["title"](),
      },
      soc2: {
        description: lang["onboardingV2"]["security"]["soc2"]["description"](),
        title: lang["onboardingV2"]["security"]["soc2"]["title"](),
      },
      subtitle: lang["onboardingV2"]["security"]["subtitle"](),
      title: lang["onboardingV2"]["security"]["title"](),
    },
    skipAndEnterApp: lang["onboardingV2"]["skipAndEnterApp"](),
    taxCalculator: {
      buttons: {
        addAnotherAccount:
          lang["onboardingV2"]["taxCalculator"]["buttons"][
            "addAnotherAccount"
          ](),
        back: lang["onboardingV2"]["taxCalculator"]["buttons"]["back"](),
        getTaxReport:
          lang["onboardingV2"]["taxCalculator"]["buttons"]["getTaxReport"](),
      },
      disclaimer: lang["onboardingV2"]["taxCalculator"]["disclaimer"](),
      estimatedGain: lang["onboardingV2"]["taxCalculator"]["estimatedGain"](),
      heading: lang["onboardingV2"]["taxCalculator"]["heading"](),
      income: lang["onboardingV2"]["taxCalculator"]["income"](),
      tooltips: {
        estimatedGain:
          lang["onboardingV2"]["taxCalculator"]["tooltips"]["estimatedGain"](),
        gains: lang["onboardingV2"]["taxCalculator"]["tooltips"]["gains"](),
        income: lang["onboardingV2"]["taxCalculator"]["tooltips"]["income"](),
        losses: lang["onboardingV2"]["taxCalculator"]["tooltips"]["losses"](),
        txCount: lang["onboardingV2"]["taxCalculator"]["tooltips"]["txCount"](),
      },
      tradingGains: lang["onboardingV2"]["taxCalculator"]["tradingGains"](),
      tradingLosses: lang["onboardingV2"]["taxCalculator"]["tradingLosses"](),
      transactions: (args: { count: Arg }): string =>
        lang["onboardingV2"]["taxCalculator"]["transactions"](args) as string,
    },
    taxableStaking: {
      content: {
        added: lang["onboardingV2"]["taxableStaking"]["content"]["added"](),
        assessableIncome:
          lang["onboardingV2"]["taxableStaking"]["content"][
            "assessableIncome"
          ](),
        at: lang["onboardingV2"]["taxableStaking"]["content"]["at"](),
        breakdown:
          lang["onboardingV2"]["taxableStaking"]["content"]["breakdown"](),
        rewards: lang["onboardingV2"]["taxableStaking"]["content"]["rewards"](),
        value: lang["onboardingV2"]["taxableStaking"]["content"]["value"](),
        year: lang["onboardingV2"]["taxableStaking"]["content"]["year"](),
        youStake:
          lang["onboardingV2"]["taxableStaking"]["content"]["youStake"](),
      },
      footerBack: lang["onboardingV2"]["taxableStaking"]["footerBack"](),
      footerNext: lang["onboardingV2"]["taxableStaking"]["footerNext"](),
      headings: {
        assessableIncome:
          lang["onboardingV2"]["taxableStaking"]["headings"][
            "assessableIncome"
          ](),
        stakingContract:
          lang["onboardingV2"]["taxableStaking"]["headings"][
            "stakingContract"
          ](),
        stakingRewards:
          lang["onboardingV2"]["taxableStaking"]["headings"][
            "stakingRewards"
          ](),
        yourSalary:
          lang["onboardingV2"]["taxableStaking"]["headings"]["yourSalary"](),
      },
      subtitle: lang["onboardingV2"]["taxableStaking"]["subtitle"](),
      title: lang["onboardingV2"]["taxableStaking"]["title"](),
    },
    taxableTrades: {
      content: {
        andYour: lang["onboardingV2"]["taxableTrades"]["content"]["andYour"](),
        breakdown:
          lang["onboardingV2"]["taxableTrades"]["content"]["breakdown"](),
        capitalGain:
          lang["onboardingV2"]["taxableTrades"]["content"]["capitalGain"](),
        costBase:
          lang["onboardingV2"]["taxableTrades"]["content"]["costBase"](),
        for: lang["onboardingV2"]["taxableTrades"]["content"]["for"](),
        is15k: lang["onboardingV2"]["taxableTrades"]["content"]["is15k"](),
        is5k: lang["onboardingV2"]["taxableTrades"]["content"]["is5k"](),
        marketValue:
          lang["onboardingV2"]["taxableTrades"]["content"]["marketValue"](),
        the: lang["onboardingV2"]["taxableTrades"]["content"]["the"](),
        youTrade:
          lang["onboardingV2"]["taxableTrades"]["content"]["youTrade"](),
        your: lang["onboardingV2"]["taxableTrades"]["content"]["your"](),
        yourBitcoin:
          lang["onboardingV2"]["taxableTrades"]["content"]["yourBitcoin"](),
      },
      footerBack: lang["onboardingV2"]["taxableTrades"]["footerBack"](),
      footerNext: lang["onboardingV2"]["taxableTrades"]["footerNext"](),
      headings: {
        capitalGain:
          lang["onboardingV2"]["taxableTrades"]["headings"]["capitalGain"](),
        costBase:
          lang["onboardingV2"]["taxableTrades"]["headings"]["costBase"](),
        marketValue:
          lang["onboardingV2"]["taxableTrades"]["headings"]["marketValue"](),
      },
      subtitle: lang["onboardingV2"]["taxableTrades"]["subtitle"](),
      title: lang["onboardingV2"]["taxableTrades"]["title"](),
    },
    tooltip: {
      accounts: lang["onboardingV2"]["tooltip"]["accounts"](),
      assessableIncome: lang["onboardingV2"]["tooltip"]["assessableIncome"](),
      capitalGain: lang["onboardingV2"]["tooltip"]["capitalGain"](),
      costBase: lang["onboardingV2"]["tooltip"]["costBase"](),
      marketValue: lang["onboardingV2"]["tooltip"]["marketValue"](),
    },
    tradingTypes: {
      footerBack: lang["onboardingV2"]["tradingTypes"]["footerBack"](),
      footerNext: lang["onboardingV2"]["tradingTypes"]["footerNext"](),
      footerSkip: lang["onboardingV2"]["tradingTypes"]["footerSkip"](),
      subtitle: lang["onboardingV2"]["tradingTypes"]["subtitle"](),
      title: lang["onboardingV2"]["tradingTypes"]["title"](),
      types: {
        airdrop: lang["onboardingV2"]["tradingTypes"]["types"]["airdrop"](),
        liquidityPool:
          lang["onboardingV2"]["tradingTypes"]["types"]["liquidityPool"](),
        nft: lang["onboardingV2"]["tradingTypes"]["types"]["nft"](),
        other: lang["onboardingV2"]["tradingTypes"]["types"]["other"](),
        staking: lang["onboardingV2"]["tradingTypes"]["types"]["staking"](),
        trading: lang["onboardingV2"]["tradingTypes"]["types"]["trading"](),
      },
    },
    userValueSurvey: {
      options: {
        accurateReport:
          lang["onboardingV2"]["userValueSurvey"]["options"][
            "accurateReport"
          ](),
        auditProtection:
          lang["onboardingV2"]["userValueSurvey"]["options"][
            "auditProtection"
          ](),
        minimiseTax:
          lang["onboardingV2"]["userValueSurvey"]["options"]["minimiseTax"](),
        other: lang["onboardingV2"]["userValueSurvey"]["options"]["other"](),
        simplifyTax:
          lang["onboardingV2"]["userValueSurvey"]["options"]["simplifyTax"](),
        timeSave:
          lang["onboardingV2"]["userValueSurvey"]["options"]["timeSave"](),
      },
      title: lang["onboardingV2"]["userValueSurvey"]["title"](),
    },
    viewAssociatedTransaction: (args: { txCount: Arg }): string =>
      lang["onboardingV2"]["viewAssociatedTransaction"](args) as string,
    wallets: {
      address: lang["onboardingV2"]["wallets"]["address"](),
      blockchain: lang["onboardingV2"]["wallets"]["blockchain"](),
      continue: lang["onboardingV2"]["wallets"]["continue"](),
      importTransactions:
        lang["onboardingV2"]["wallets"]["importTransactions"](),
      subtitle: lang["onboardingV2"]["wallets"]["subtitle"](),
      title: lang["onboardingV2"]["wallets"]["title"](),
    },
  },
  organisation: {
    wentWrongGettingDashboard:
      lang["organisation"]["wentWrongGettingDashboard"](),
  },
  outTxCategoryOptionsTitle: lang["outTxCategoryOptionsTitle"](),
  pageFailed: lang["pageFailed"](),
  pageTitle: {
    AccountingIntegrations: lang["pageTitle"]["AccountingIntegrations"](),
    Alpha: lang["pageTitle"]["Alpha"](),
    BalanceLedger: lang["pageTitle"]["BalanceLedger"](),
    Billing: lang["pageTitle"]["Billing"](),
    Clients: lang["pageTitle"]["Clients"](),
    ClientsAdd: lang["pageTitle"]["ClientsAdd"](),
    Close: lang["pageTitle"]["Close"](),
    ConnectProvider: lang["pageTitle"]["ConnectProvider"](),
    Contacts: lang["pageTitle"]["Contacts"](),
    ContactsIdentified: lang["pageTitle"]["ContactsIdentified"](),
    ContactsUnidentified: lang["pageTitle"]["ContactsUnidentified"](),
    Dashboard: lang["pageTitle"]["Dashboard"](),
    Developer: lang["pageTitle"]["Developer"](),
    DeveloperEditorIntegration:
      lang["pageTitle"]["DeveloperEditorIntegration"](),
    DeveloperEntities: lang["pageTitle"]["DeveloperEntities"](),
    DeveloperReport: lang["pageTitle"]["DeveloperReport"](),
    DeveloperSeedData: lang["pageTitle"]["DeveloperSeedData"](),
    DeveloperSessionRecording: lang["pageTitle"]["DeveloperSessionRecording"](),
    DeveloperUserData: lang["pageTitle"]["DeveloperUserData"](),
    Forgot: lang["pageTitle"]["Forgot"](),
    ImportCustom: lang["pageTitle"]["ImportCustom"](),
    ImportNew: lang["pageTitle"]["ImportNew"](),
    ImportSearch: lang["pageTitle"]["ImportSearch"](),
    Imports: lang["pageTitle"]["Imports"](),
    Invite: lang["pageTitle"]["Invite"](),
    Ledger: lang["pageTitle"]["Ledger"](),
    Login: lang["pageTitle"]["Login"](),
    Onboarding: lang["pageTitle"]["Onboarding"](),
    OnboardingAccuracy: lang["pageTitle"]["OnboardingAccuracy"](),
    OnboardingAreYouSure: lang["pageTitle"]["OnboardingAreYouSure"](),
    OnboardingCompetitorSurvey:
      lang["pageTitle"]["OnboardingCompetitorSurvey"](),
    OnboardingEmbededOfferIntro:
      lang["pageTitle"]["OnboardingEmbededOfferIntro"](),
    OnboardingImportCEXReminder:
      lang["pageTitle"]["OnboardingImportCEXReminder"](),
    OnboardingImports: lang["pageTitle"]["OnboardingImports"](),
    OnboardingImportsCalculating:
      lang["pageTitle"]["OnboardingImportsCalculating"](),
    OnboardingImportsCustom: lang["pageTitle"]["OnboardingImportsCustom"](),
    OnboardingImportsNew: lang["pageTitle"]["OnboardingImportsNew"](),
    OnboardingImportsOauth: lang["pageTitle"]["OnboardingImportsOauth"](),
    OnboardingImportsSearch: lang["pageTitle"]["OnboardingImportsSearch"](),
    OnboardingOnYourWay: lang["pageTitle"]["OnboardingOnYourWay"](),
    OnboardingPayment: lang["pageTitle"]["OnboardingPayment"](),
    OnboardingPaymentStatus: lang["pageTitle"]["OnboardingPaymentStatus"](),
    OnboardingPaymentSuccess: lang["pageTitle"]["OnboardingPaymentSuccess"](),
    OnboardingPlatformTypes: lang["pageTitle"]["OnboardingPlatformTypes"](),
    OnboardingProviderPasswordSetSuccess:
      lang["pageTitle"]["OnboardingProviderPasswordSetSuccess"](),
    OnboardingProviderSetPassword:
      lang["pageTitle"]["OnboardingProviderSetPassword"](),
    OnboardingProviderSyncing: lang["pageTitle"]["OnboardingProviderSyncing"](),
    OnboardingProviderWelcome: lang["pageTitle"]["OnboardingProviderWelcome"](),
    OnboardingReconUncategorisedTransactions:
      lang["pageTitle"]["OnboardingReconUncategorisedTransactions"](),
    OnboardingSelectCountry: lang["pageTitle"]["OnboardingSelectCountry"](),
    OnboardingSelectTheme: lang["pageTitle"]["OnboardingSelectTheme"](),
    OnboardingSelectType: lang["pageTitle"]["OnboardingSelectType"](),
    OnboardingTaxSettings: lang["pageTitle"]["OnboardingTaxSettings"](),
    OnboardingTaxableStaking: lang["pageTitle"]["OnboardingTaxableStaking"](),
    OnboardingTaxableTrades: lang["pageTitle"]["OnboardingTaxableTrades"](),
    OnboardingTradingTypes: lang["pageTitle"]["OnboardingTradingTypes"](),
    OnboardingUserValueSurvey: lang["pageTitle"]["OnboardingUserValueSurvey"](),
    OnboardingWallets: lang["pageTitle"]["OnboardingWallets"](),
    OptInSupportAccess: lang["pageTitle"]["OptInSupportAccess"](),
    PartnerBenefit: lang["pageTitle"]["PartnerBenefit"](),
    Payment: lang["pageTitle"]["Payment"](),
    PaymentPending: lang["pageTitle"]["PaymentPending"](),
    PaymentStatus: lang["pageTitle"]["PaymentStatus"](),
    PaymentSuccess: lang["pageTitle"]["PaymentSuccess"](),
    Plans: lang["pageTitle"]["Plans"](),
    ReOnboarding: lang["pageTitle"]["ReOnboarding"](),
    ReOnboardingAccuracy: lang["pageTitle"]["ReOnboardingAccuracy"](),
    ReOnboardingTaxableStaking:
      lang["pageTitle"]["ReOnboardingTaxableStaking"](),
    ReOnboardingTaxableTrades: lang["pageTitle"]["ReOnboardingTaxableTrades"](),
    ReOnboardingWelcomeBack: lang["pageTitle"]["ReOnboardingWelcomeBack"](),
    Reconciliation: lang["pageTitle"]["Reconciliation"](),
    ReconciliationMissingBlockchain:
      lang["pageTitle"]["ReconciliationMissingBlockchain"](),
    ReconciliationMissingPriceIssues:
      lang["pageTitle"]["ReconciliationMissingPriceIssues"](),
    ReconciliationNegativeBalanceIssues:
      lang["pageTitle"]["ReconciliationNegativeBalanceIssues"](),
    ReconciliationUncategorisedTransaction:
      lang["pageTitle"]["ReconciliationUncategorisedTransaction"](),
    ReconciliationUnmatchedTransfer:
      lang["pageTitle"]["ReconciliationUnmatchedTransfer"](),
    ReferAFriend: lang["pageTitle"]["ReferAFriend"](),
    Report: lang["pageTitle"]["Report"](),
    Reset: lang["pageTitle"]["Reset"](),
    RestoreUserData: lang["pageTitle"]["RestoreUserData"](),
    Rules: lang["pageTitle"]["Rules"](),
    Signup: lang["pageTitle"]["Signup"](),
    SignupAccountant: lang["pageTitle"]["SignupAccountant"](),
    TokenLogin: lang["pageTitle"]["TokenLogin"](),
    Transactions: lang["pageTitle"]["Transactions"](),
    TransactionsAdd: lang["pageTitle"]["TransactionsAdd"](),
    TransactionsMissingPrice: lang["pageTitle"]["TransactionsMissingPrice"](),
    TransactionsNegativeBalance:
      lang["pageTitle"]["TransactionsNegativeBalance"](),
    TransactionsSearch: lang["pageTitle"]["TransactionsSearch"](),
    TransactionsUncategorised: lang["pageTitle"]["TransactionsUncategorised"](),
    Unlock: lang["pageTitle"]["Unlock"](),
    Wallets: lang["pageTitle"]["Wallets"](),
  },
  partnerEmployeeBenefits: {
    discount: {
      cta: lang["partnerEmployeeBenefits"]["discount"]["cta"](),
      description: lang["partnerEmployeeBenefits"]["discount"]["description"](),
      title: (args: { partner: Arg }): string =>
        lang["partnerEmployeeBenefits"]["discount"]["title"](args) as string,
    },
    discountApplied: {
      cta: lang["partnerEmployeeBenefits"]["discountApplied"]["cta"](),
      description:
        lang["partnerEmployeeBenefits"]["discountApplied"]["description"](),
      title: lang["partnerEmployeeBenefits"]["discountApplied"]["title"](),
    },
    verifyEmail: {
      confirmEmail:
        lang["partnerEmployeeBenefits"]["verifyEmail"]["confirmEmail"](),
      cta: lang["partnerEmployeeBenefits"]["verifyEmail"]["cta"](),
      error400: lang["partnerEmployeeBenefits"]["verifyEmail"]["error400"](),
      error403: (args: { partner: Arg }): string =>
        lang["partnerEmployeeBenefits"]["verifyEmail"]["error403"](
          args,
        ) as string,
      error500: lang["partnerEmployeeBenefits"]["verifyEmail"]["error500"](),
      helperText: (args: { partner: Arg }): string =>
        lang["partnerEmployeeBenefits"]["verifyEmail"]["helperText"](
          args,
        ) as string,
      placeholder: (args: { partner: Arg }): string =>
        lang["partnerEmployeeBenefits"]["verifyEmail"]["placeholder"](
          args,
        ) as string,
      reEnterEmail:
        lang["partnerEmployeeBenefits"]["verifyEmail"]["reEnterEmail"](),
      success: lang["partnerEmployeeBenefits"]["verifyEmail"]["success"](),
    },
  },
  partnership: { officialPartner: lang["partnership"]["officialPartner"]() },
  password: {
    case: lang["password"]["case"](),
    long: lang["password"]["long"](),
    number: lang["password"]["number"](),
    short: lang["password"]["short"](),
  },
  payment: {
    accountantPackage: {
      clientDefault: lang["payment"]["accountantPackage"]["clientDefault"](),
      color: lang["payment"]["accountantPackage"]["color"](),
      cta: lang["payment"]["accountantPackage"]["cta"](),
      description: lang["payment"]["accountantPackage"]["description"](),
      details: [
        {
          icon: lang["payment"]["accountantPackage"]["details"][0]["icon"](),
          text: lang["payment"]["accountantPackage"]["details"][0]["text"](),
        },
      ],
      points: [
        lang["payment"]["accountantPackage"]["points"][0](),
        lang["payment"]["accountantPackage"]["points"][1](),
        lang["payment"]["accountantPackage"]["points"][2](),
      ],
      pointsByCountry: {
        AU: [
          lang["payment"]["accountantPackage"]["pointsByCountry"]["AU"][0](),
          lang["payment"]["accountantPackage"]["pointsByCountry"]["AU"][1](),
          lang["payment"]["accountantPackage"]["pointsByCountry"]["AU"][2](),
          lang["payment"]["accountantPackage"]["pointsByCountry"]["AU"][3](),
        ],
      },
      subtitle: lang["payment"]["accountantPackage"]["subtitle"](),
      title: lang["payment"]["accountantPackage"]["title"](),
    },
    backToTop: lang["payment"]["backToTop"](),
    base: {
      allTicks: [
        lang["payment"]["base"]["allTicks"][0](),
        lang["payment"]["base"]["allTicks"][1](),
        lang["payment"]["base"]["allTicks"][2](),
        lang["payment"]["base"]["allTicks"][3](),
      ],
      cancel: lang["payment"]["base"]["cancel"](),
      cancelAnytime: lang["payment"]["base"]["cancelAnytime"](),
      compare: {
        subtitle: lang["payment"]["base"]["compare"]["subtitle"](),
        title: lang["payment"]["base"]["compare"]["title"](),
      },
      copy: {
        actions: {
          buy: {
            text: lang["payment"]["base"]["copy"]["actions"]["buy"]["text"](),
          },
          current: lang["payment"]["base"]["copy"]["actions"]["current"](),
          getStarted: {
            text: lang["payment"]["base"]["copy"]["actions"]["getStarted"][
              "text"
            ](),
          },
          signUp: {
            text: lang["payment"]["base"]["copy"]["actions"]["signUp"][
              "text"
            ](),
          },
          upgrade: {
            text: lang["payment"]["base"]["copy"]["actions"]["upgrade"][
              "text"
            ](),
          },
          upgradeFor: (args: { upgradePrice: Arg }): string =>
            lang["payment"]["base"]["copy"]["actions"]["upgradeFor"](
              args,
            ) as string,
        },
        annually: lang["payment"]["base"]["copy"]["annually"](),
        costCurrentPlanDeducted:
          lang["payment"]["base"]["copy"]["costCurrentPlanDeducted"](),
        discountAppliedFirstYear: (args: { discount: Arg }): string =>
          lang["payment"]["base"]["copy"]["discountAppliedFirstYear"](
            args,
          ) as string,
        features: {
          advancedTaxReports:
            lang["payment"]["base"]["copy"]["features"]["advancedTaxReports"](),
          auditReport:
            lang["payment"]["base"]["copy"]["features"]["auditReport"](),
          integrations:
            lang["payment"]["base"]["copy"]["features"]["integrations"](),
          ltfo: lang["payment"]["base"]["copy"]["features"]["ltfo"](),
          onchain: lang["payment"]["base"]["copy"]["features"]["onchain"](),
          portfolioTracking:
            lang["payment"]["base"]["copy"]["features"]["portfolioTracking"](),
          report: lang["payment"]["base"]["copy"]["features"]["report"](),
          reportAuthority: (args: { authority: Arg }): string =>
            lang["payment"]["base"]["copy"]["features"]["reportAuthority"](
              args,
            ) as string,
          smartContract:
            lang["payment"]["base"]["copy"]["features"]["smartContract"](),
          support: lang["payment"]["base"]["copy"]["features"]["support"](),
          tlh: lang["payment"]["base"]["copy"]["features"]["tlh"](),
          tsr: lang["payment"]["base"]["copy"]["features"]["tsr"](),
          txLimit: (args: { txLimit: Arg }): string =>
            lang["payment"]["base"]["copy"]["features"]["txLimit"](
              args,
            ) as string,
          xero: lang["payment"]["base"]["copy"]["features"]["xero"](),
        },
        featuresReport: {
          reportAllFinancialYears:
            lang["payment"]["base"]["copy"]["featuresReport"][
              "reportAllFinancialYears"
            ](),
          reportUnlimited:
            lang["payment"]["base"]["copy"]["featuresReport"][
              "reportUnlimited"
            ](),
        },
        featuresTableCell: {
          experimentSupportEmailChat:
            lang["payment"]["base"]["copy"]["featuresTableCell"][
              "experimentSupportEmailChat"
            ](),
          experimentSupportPriority:
            lang["payment"]["base"]["copy"]["featuresTableCell"][
              "experimentSupportPriority"
            ](),
          integrations:
            lang["payment"]["base"]["copy"]["featuresTableCell"][
              "integrations"
            ](),
          supportEmailChat:
            lang["payment"]["base"]["copy"]["featuresTableCell"][
              "supportEmailChat"
            ](),
          supportPriority:
            lang["payment"]["base"]["copy"]["featuresTableCell"][
              "supportPriority"
            ](),
        },
        featuresTableHeader: {
          integrations:
            lang["payment"]["base"]["copy"]["featuresTableHeader"][
              "integrations"
            ](),
          support:
            lang["payment"]["base"]["copy"]["featuresTableHeader"]["support"](),
          txLimit:
            lang["payment"]["base"]["copy"]["featuresTableHeader"]["txLimit"](),
        },
        featuresTooltip: {
          advancedTaxReports:
            lang["payment"]["base"]["copy"]["featuresTooltip"][
              "advancedTaxReports"
            ](),
          auditReport:
            lang["payment"]["base"]["copy"]["featuresTooltip"]["auditReport"](),
          ltfo: lang["payment"]["base"]["copy"]["featuresTooltip"]["ltfo"](),
          tlh: lang["payment"]["base"]["copy"]["featuresTooltip"]["tlh"](),
        },
        firstYear: (args: { price: Arg }): string =>
          lang["payment"]["base"]["copy"]["firstYear"](args) as string,
        freeTransactions: lang["payment"]["base"]["copy"]["freeTransactions"](),
        highlightFeatures: {
          "advanced-inventory":
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "advanced-inventory"
            ](),
          "advanced-reports":
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "advanced-reports"
            ](),
          allOtherFeatures:
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "allOtherFeatures"
            ](),
          comingSoon:
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "comingSoon"
            ](),
          contacts:
            lang["payment"]["base"]["copy"]["highlightFeatures"]["contacts"](),
          erp: lang["payment"]["base"]["copy"]["highlightFeatures"]["erp"](),
          "lock-tax-year":
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "lock-tax-year"
            ](),
          "multi-tax-years":
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "multi-tax-years"
            ](),
          prioritySupport:
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "prioritySupport"
            ](),
          rules:
            lang["payment"]["base"]["copy"]["highlightFeatures"]["rules"](),
          sidebar:
            lang["payment"]["base"]["copy"]["highlightFeatures"]["sidebar"](),
          "smart-contract-transactions":
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "smart-contract-transactions"
            ](),
          "tax-loss-harvesting":
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "tax-loss-harvesting"
            ](),
          tips: lang["payment"]["base"]["copy"]["highlightFeatures"]["tips"](),
          "trading-stock-report":
            lang["payment"]["base"]["copy"]["highlightFeatures"][
              "trading-stock-report"
            ](),
          treasury:
            lang["payment"]["base"]["copy"]["highlightFeatures"]["treasury"](),
        },
        planCommonDetails:
          lang["payment"]["base"]["copy"]["planCommonDetails"](),
        plans: {
          accountant: {
            clientDataDescription:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "clientDataDescription"
              ](),
            clientDataTitle:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "clientDataTitle"
              ](),
            clientDefault:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "clientDefault"
              ](),
            color:
              lang["payment"]["base"]["copy"]["plans"]["accountant"]["color"](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "description"
              ](),
            descriptionFooter:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "descriptionFooter"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["accountant"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["accountant"][
                  "details"
                ][0]["text"](),
              },
            ],
            getStarted:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "getStarted"
              ](),
            manageClientsDescription:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "manageClientsDescription"
              ](),
            manageClientsTitle:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "manageClientsTitle"
              ](),
            points: [
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "points"
              ][2](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["accountant"]["title"](),
            toolsDemo:
              lang["payment"]["base"]["copy"]["plans"]["accountant"][
                "toolsDemo"
              ](),
          },
          advanced: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["advanced"]["color"](),
            cta: lang["payment"]["base"]["copy"]["plans"]["advanced"]["cta"](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["advanced"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["advanced"][
                  "details"
                ][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["advanced"][
                  "details"
                ][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["advanced"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][2](),
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][3](),
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][4](),
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][5](),
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][6](),
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][7](),
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][8](),
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "points"
              ][9](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["advanced"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["advanced"]["title"](),
          },
          business: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["business"]["color"](),
            cta: lang["payment"]["base"]["copy"]["plans"]["business"]["cta"](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["business"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["business"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["business"][
                  "details"
                ][0]["text"](),
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["business"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["business"][
                "points"
              ][1](),
            ],
            retailPlansPage: {
              cta: lang["payment"]["base"]["copy"]["plans"]["business"][
                "retailPlansPage"
              ]["cta"](),
              description:
                lang["payment"]["base"]["copy"]["plans"]["business"][
                  "retailPlansPage"
                ]["description"](),
              points: [
                {
                  description:
                    lang["payment"]["base"]["copy"]["plans"]["business"][
                      "retailPlansPage"
                    ]["points"][0]["description"](),
                  feature:
                    lang["payment"]["base"]["copy"]["plans"]["business"][
                      "retailPlansPage"
                    ]["points"][0]["feature"](),
                },
                {
                  description:
                    lang["payment"]["base"]["copy"]["plans"]["business"][
                      "retailPlansPage"
                    ]["points"][1]["description"](),
                  feature:
                    lang["payment"]["base"]["copy"]["plans"]["business"][
                      "retailPlansPage"
                    ]["points"][1]["feature"](),
                },
                {
                  description:
                    lang["payment"]["base"]["copy"]["plans"]["business"][
                      "retailPlansPage"
                    ]["points"][2]["description"](),
                  feature:
                    lang["payment"]["base"]["copy"]["plans"]["business"][
                      "retailPlansPage"
                    ]["points"][2]["feature"](),
                },
                {
                  feature:
                    lang["payment"]["base"]["copy"]["plans"]["business"][
                      "retailPlansPage"
                    ]["points"][3]["feature"](),
                },
              ],
              subtitle:
                lang["payment"]["base"]["copy"]["plans"]["business"][
                  "retailPlansPage"
                ]["subtitle"](),
              title:
                lang["payment"]["base"]["copy"]["plans"]["business"][
                  "retailPlansPage"
                ]["title"](),
            },
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["business"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["business"]["title"](),
          },
          businessPackage: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["businessPackage"][
                "color"
              ](),
            cta: lang["payment"]["base"]["copy"]["plans"]["businessPackage"][
              "cta"
            ](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["businessPackage"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"][
                  "businessPackage"
                ]["details"][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"][
                  "businessPackage"
                ]["details"][0]["text"](),
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["businessPackage"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["businessPackage"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["businessPackage"][
                "points"
              ][2](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["businessPackage"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["businessPackage"][
                "title"
              ](),
          },
          business_pro: {
            caption:
              lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                "caption"
              ](),
            color:
              lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                "color"
              ](),
            cta: lang["payment"]["base"]["copy"]["plans"]["business_pro"][
              "cta"
            ](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                  "details"
                ][0]["text"](),
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                "points"
              ][2](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["business_pro"][
                "title"
              ](),
          },
          business_starter: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["business_starter"][
                "color"
              ](),
            cta: lang["payment"]["base"]["copy"]["plans"]["business_starter"][
              "cta"
            ](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["business_starter"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"][
                  "business_starter"
                ]["details"][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"][
                  "business_starter"
                ]["details"][0]["text"](),
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["business_starter"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["business_starter"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["business_starter"][
                "points"
              ][2](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["business_starter"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["business_starter"][
                "title"
              ](),
          },
          business_trial: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["business_trial"][
                "color"
              ](),
            cta: lang["payment"]["base"]["copy"]["plans"]["business_trial"][
              "cta"
            ](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["business_trial"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"][
                  "business_trial"
                ]["details"][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"][
                  "business_trial"
                ]["details"][0]["text"](),
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["business_trial"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["business_trial"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["business_trial"][
                "points"
              ][2](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["business_trial"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["business_trial"][
                "title"
              ](),
          },
          client: {
            collaborator: {
              color:
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "collaborator"
                ]["color"](),
              cta: lang["payment"]["base"]["copy"]["plans"]["client"][
                "collaborator"
              ]["cta"](),
              description:
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "collaborator"
                ]["description"](),
              details: [
                {
                  icon: lang["payment"]["base"]["copy"]["plans"]["client"][
                    "collaborator"
                  ]["details"][0]["icon"](),
                  text: lang["payment"]["base"]["copy"]["plans"]["client"][
                    "collaborator"
                  ]["details"][0]["text"](),
                },
              ],
              points: [
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "collaborator"
                ]["points"][0](),
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "collaborator"
                ]["points"][1](),
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "collaborator"
                ]["points"][2](),
              ],
              subtitle:
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "collaborator"
                ]["subtitle"](),
              title:
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "collaborator"
                ]["title"](),
            },
            color:
              lang["payment"]["base"]["copy"]["plans"]["client"]["color"](),
            cta: lang["payment"]["base"]["copy"]["plans"]["client"]["cta"](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["client"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["client"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["client"][
                  "details"
                ][0]["text"](),
              },
            ],
            enterprise: {
              color:
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "enterprise"
                ]["color"](),
              cta: lang["payment"]["base"]["copy"]["plans"]["client"][
                "enterprise"
              ]["cta"](),
              description:
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "enterprise"
                ]["description"](),
              details: [
                {
                  icon: lang["payment"]["base"]["copy"]["plans"]["client"][
                    "enterprise"
                  ]["details"][0]["icon"](),
                  text: lang["payment"]["base"]["copy"]["plans"]["client"][
                    "enterprise"
                  ]["details"][0]["text"](),
                },
              ],
              points: [
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "enterprise"
                ]["points"][0](),
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "enterprise"
                ]["points"][1](),
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "enterprise"
                ]["points"][2](),
              ],
              subtitle:
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "enterprise"
                ]["subtitle"](),
              title:
                lang["payment"]["base"]["copy"]["plans"]["client"][
                  "enterprise"
                ]["title"](),
            },
            points: [
              lang["payment"]["base"]["copy"]["plans"]["client"]["points"][0](),
              lang["payment"]["base"]["copy"]["plans"]["client"]["points"][1](),
              lang["payment"]["base"]["copy"]["plans"]["client"]["points"][2](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["client"]["subtitle"](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["client"]["title"](),
          },
          collaborator: {
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["collaborator"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["collaborator"][
                "title"
              ](),
          },
          enterprise: {
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["enterprise"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["enterprise"]["title"](),
          },
          expert: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["expert"]["color"](),
            cta: lang["payment"]["base"]["copy"]["plans"]["expert"]["cta"](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["expert"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["expert"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["expert"][
                  "details"
                ][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["expert"][
                  "details"
                ][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["expert"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["expert"]["points"][0](),
              lang["payment"]["base"]["copy"]["plans"]["expert"]["points"][1](),
              lang["payment"]["base"]["copy"]["plans"]["expert"]["points"][2](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["expert"]["subtitle"](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["expert"]["title"](),
          },
          free: {
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["free"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["free"][
                  "details"
                ][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["free"][
                  "details"
                ][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["free"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["free"]["subtitle"](),
            title: lang["payment"]["base"]["copy"]["plans"]["free"]["title"](),
          },
          investorUs: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["investorUs"]["color"](),
            cta: lang["payment"]["base"]["copy"]["plans"]["investorUs"][
              "cta"
            ](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                  "details"
                ][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                  "details"
                ][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "points"
              ][2](),
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "points"
              ][3](),
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "points"
              ][4](),
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "points"
              ][5](),
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "points"
              ][6](),
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "points"
              ][7](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["investorUs"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["investorUs"]["title"](),
          },
          investorUsPlus: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "color"
              ](),
            cta: lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
              "cta"
            ](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"][
                  "investorUsPlus"
                ]["details"][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"][
                  "investorUsPlus"
                ]["details"][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"][
                  "investorUsPlus"
                ]["details"][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "points"
              ][2](),
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "points"
              ][3](),
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "points"
              ][4](),
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "points"
              ][5](),
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "points"
              ][6](),
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "points"
              ][7](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["investorUsPlus"][
                "title"
              ](),
          },
          pro: {
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["pro"]["subtitle"](),
            title: lang["payment"]["base"]["copy"]["plans"]["pro"]["title"](),
          },
          rookie: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["rookie"]["color"](),
            cta: lang["payment"]["base"]["copy"]["plans"]["rookie"]["cta"](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["rookie"][
                "description"
              ](),
            points: [
              lang["payment"]["base"]["copy"]["plans"]["rookie"]["points"][0](),
              lang["payment"]["base"]["copy"]["plans"]["rookie"]["points"][1](),
              lang["payment"]["base"]["copy"]["plans"]["rookie"]["points"][2](),
              lang["payment"]["base"]["copy"]["plans"]["rookie"]["points"][3](),
              lang["payment"]["base"]["copy"]["plans"]["rookie"]["points"][4](),
              lang["payment"]["base"]["copy"]["plans"]["rookie"]["points"][5](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["rookie"]["subtitle"](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["rookie"]["title"](),
          },
          rookie_100: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"]["color"](),
            cta: lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
              "cta"
            ](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                  "details"
                ][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                  "details"
                ][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                "points"
              ][2](),
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                "points"
              ][3](),
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                "points"
              ][4](),
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                "points"
              ][5](),
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                "points"
              ][6](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["rookie_100"]["title"](),
          },
          standard: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["standard"]["color"](),
            cta: lang["payment"]["base"]["copy"]["plans"]["standard"]["cta"](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["standard"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["standard"][
                  "details"
                ][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["standard"][
                  "details"
                ][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["standard"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "points"
              ][2](),
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "points"
              ][3](),
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "points"
              ][4](),
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "points"
              ][5](),
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "points"
              ][6](),
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "points"
              ][7](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["standard"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["standard"]["title"](),
          },
          standard_100: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "color"
              ](),
            cta: lang["payment"]["base"]["copy"]["plans"]["standard_100"][
              "cta"
            ](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                  "details"
                ][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                  "details"
                ][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "points"
              ][2](),
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "points"
              ][3](),
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "points"
              ][4](),
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "points"
              ][5](),
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "points"
              ][6](),
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "points"
              ][7](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["standard_100"][
                "title"
              ](),
          },
          traderUs: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["traderUs"]["color"](),
            cta: lang["payment"]["base"]["copy"]["plans"]["traderUs"]["cta"](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                  "details"
                ][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                  "details"
                ][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                "points"
              ][2](),
              lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                "points"
              ][3](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["traderUs"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["traderUs"]["title"](),
          },
          traderUsPlus: {
            color:
              lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                "color"
              ](),
            cta: lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
              "cta"
            ](),
            description:
              lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                "description"
              ](),
            details: [
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                  "details"
                ][0]["icon"](),
                text: lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                  "details"
                ][0]["text"](),
              },
              {
                icon: lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                  "details"
                ][1]["icon"](),
                text: (args: { txLimit: Arg }): string =>
                  lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                    "details"
                  ][1]["text"](args) as string,
              },
            ],
            points: [
              lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                "points"
              ][0](),
              lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                "points"
              ][1](),
              lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                "points"
              ][2](),
              lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                "points"
              ][3](),
            ],
            subtitle:
              lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                "subtitle"
              ](),
            title:
              lang["payment"]["base"]["copy"]["plans"]["traderUsPlus"][
                "title"
              ](),
          },
        },
        plus: lang["payment"]["base"]["copy"]["plus"](),
        subtitle: lang["payment"]["base"]["copy"]["subtitle"](),
        subtitleAccountant:
          lang["payment"]["base"]["copy"]["subtitleAccountant"](),
        tabTitle: lang["payment"]["base"]["copy"]["tabTitle"](),
        title: lang["payment"]["base"]["copy"]["title"](),
        titleAccountants: lang["payment"]["base"]["copy"]["titleAccountants"](),
        usExperimentPlanDescription: {
          investorUs:
            lang["payment"]["base"]["copy"]["usExperimentPlanDescription"][
              "investorUs"
            ](),
          investorUsPlus:
            lang["payment"]["base"]["copy"]["usExperimentPlanDescription"][
              "investorUsPlus"
            ](),
          rookie_100:
            lang["payment"]["base"]["copy"]["usExperimentPlanDescription"][
              "rookie_100"
            ](),
          traderUs:
            lang["payment"]["base"]["copy"]["usExperimentPlanDescription"][
              "traderUs"
            ](),
          traderUsPlus:
            lang["payment"]["base"]["copy"]["usExperimentPlanDescription"][
              "traderUsPlus"
            ](),
        },
        usExperimentPlanTitle: {
          investorUs:
            lang["payment"]["base"]["copy"]["usExperimentPlanTitle"][
              "investorUs"
            ](),
          investorUsPlus:
            lang["payment"]["base"]["copy"]["usExperimentPlanTitle"][
              "investorUsPlus"
            ](),
          rookie_100:
            lang["payment"]["base"]["copy"]["usExperimentPlanTitle"][
              "rookie_100"
            ](),
          traderUs:
            lang["payment"]["base"]["copy"]["usExperimentPlanTitle"][
              "traderUs"
            ](),
          traderUsPlus:
            lang["payment"]["base"]["copy"]["usExperimentPlanTitle"][
              "traderUsPlus"
            ](),
        },
        usExperimentPlanTxCount: {
          investorUs: {
            hasPlusTxs:
              lang["payment"]["base"]["copy"]["usExperimentPlanTxCount"][
                "investorUs"
              ]["hasPlusTxs"](),
            txCount:
              lang["payment"]["base"]["copy"]["usExperimentPlanTxCount"][
                "investorUs"
              ]["txCount"](),
          },
          rookie_100: {
            hasPlusTxs:
              lang["payment"]["base"]["copy"]["usExperimentPlanTxCount"][
                "rookie_100"
              ]["hasPlusTxs"](),
            txCount:
              lang["payment"]["base"]["copy"]["usExperimentPlanTxCount"][
                "rookie_100"
              ]["txCount"](),
          },
          traderUs: {
            hasPlusTxs:
              lang["payment"]["base"]["copy"]["usExperimentPlanTxCount"][
                "traderUs"
              ]["hasPlusTxs"](),
            txCount:
              lang["payment"]["base"]["copy"]["usExperimentPlanTxCount"][
                "traderUs"
              ]["txCount"](),
          },
        },
        usUserExperimentFeatures: {
          accountantInvite:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "accountantInvite"
            ](),
          advancedTaxReports:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "advancedTaxReports"
            ](),
          auditLog:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "auditLog"
            ](),
          dashboard:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "dashboard"
            ](),
          defiTaxAutomation:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "defiTaxAutomation"
            ](),
          integrations:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "integrations"
            ](),
          support:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "support"
            ](),
          taxMinimization:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "taxMinimization"
            ](),
          taxYearReports:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "taxYearReports"
            ](),
          turboTaxIntegration:
            lang["payment"]["base"]["copy"]["usUserExperimentFeatures"][
              "turboTaxIntegration"
            ](),
        },
        usUserExperimentFeaturesTooltip: {
          accountantInvite:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "accountantInvite"
            ](),
          advancedTaxReports:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "advancedTaxReports"
            ](),
          auditLog:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "auditLog"
            ](),
          dashboard:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "dashboard"
            ](),
          defiTaxAutomation:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "defiTaxAutomation"
            ](),
          integrations:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "integrations"
            ](),
          support:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "support"
            ](),
          taxMinimization:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "taxMinimization"
            ](),
          taxYearReports:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "taxYearReports"
            ](),
          turboTaxIntegration:
            lang["payment"]["base"]["copy"]["usUserExperimentFeaturesTooltip"][
              "turboTaxIntegration"
            ](),
        },
        validFirstYear: lang["payment"]["base"]["copy"]["validFirstYear"](),
        wasPrice: (args: { price: Arg }): string =>
          lang["payment"]["base"]["copy"]["wasPrice"](args) as string,
      },
      currency: lang["payment"]["base"]["currency"](),
      currencySymbol: lang["payment"]["base"]["currencySymbol"](),
      email: lang["payment"]["base"]["email"](),
      guarantee: lang["payment"]["base"]["guarantee"](),
      loading: lang["payment"]["base"]["loading"](),
      payNow: lang["payment"]["base"]["payNow"](),
      period: lang["payment"]["base"]["period"](),
      plan: lang["payment"]["base"]["plan"](),
      planDisabled: lang["payment"]["base"]["planDisabled"](),
      title: lang["payment"]["base"]["title"](),
    },
    billedYearly: lang["payment"]["billedYearly"](),
    billing: {
      chips: {
        cancelledChip: (args: { date: Arg }): string =>
          lang["payment"]["billing"]["chips"]["cancelledChip"](args) as string,
        renewChip: (args: { date: Arg }): string =>
          lang["payment"]["billing"]["chips"]["renewChip"](args) as string,
        trialEndsChip: (args: { date: Arg }): string =>
          lang["payment"]["billing"]["chips"]["trialEndsChip"](args) as string,
      },
      cta: {
        cancelPlan: lang["payment"]["billing"]["cta"]["cancelPlan"](),
        reSubscribe: lang["payment"]["billing"]["cta"]["reSubscribe"](),
      },
      footer: {
        client: {
          cancelledPlanFooter: (args: { date: Arg }): string =>
            lang["payment"]["billing"]["footer"]["client"][
              "cancelledPlanFooter"
            ](args) as string,
          estimatedPaymentFooter: (args: { amount: Arg; date: Arg }): string =>
            lang["payment"]["billing"]["footer"]["client"][
              "estimatedPaymentFooter"
            ](args) as string,
          estimatedPaymentFooterWithTrial: (args: {
            amount: Arg;
            date: Arg;
          }): string =>
            lang["payment"]["billing"]["footer"]["client"][
              "estimatedPaymentFooterWithTrial"
            ](args) as string,
        },
      },
      lineItems: {
        billingCycle: lang["payment"]["billing"]["lineItems"]["billingCycle"](),
        couponWithAmount: (args: { couponId: Arg; amountOff: Arg }): string =>
          lang["payment"]["billing"]["lineItems"]["couponWithAmount"](
            args,
          ) as string,
        planPrice: lang["payment"]["billing"]["lineItems"]["planPrice"](),
        totalDue: lang["payment"]["billing"]["lineItems"]["totalDue"](),
        totalDueDate: (args: { date: Arg }): string =>
          lang["payment"]["billing"]["lineItems"]["totalDueDate"](
            args,
          ) as string,
      },
      payment: {
        activeCard: lang["payment"]["billing"]["payment"]["activeCard"](),
        changePayment: lang["payment"]["billing"]["payment"]["changePayment"](),
        discount: (args: { amount: Arg }): string =>
          lang["payment"]["billing"]["payment"]["discount"](args) as string,
        end: lang["payment"]["billing"]["payment"]["end"](),
        endWithDate: (args: { date: Arg }): string =>
          lang["payment"]["billing"]["payment"]["endWithDate"](args) as string,
        start: lang["payment"]["billing"]["payment"]["start"](),
      },
    },
    cancelled: {
      button: lang["payment"]["cancelled"]["button"](),
      tooltip: (args: { plan: Arg }): string =>
        lang["payment"]["cancelled"]["tooltip"](args) as string,
    },
    coupon: { unknownCoupon: lang["payment"]["coupon"]["unknownCoupon"]() },
    currentPlan: lang["payment"]["currentPlan"](),
    disclaimer: lang["payment"]["disclaimer"](),
    downgradeWarning: lang["payment"]["downgradeWarning"](),
    enterprise: {
      demoCTA: {
        badge: lang["payment"]["enterprise"]["demoCTA"]["badge"](),
        button: lang["payment"]["enterprise"]["demoCTA"]["button"](),
        newRelease: {
          badge:
            lang["payment"]["enterprise"]["demoCTA"]["newRelease"]["badge"](),
          ctaLink:
            lang["payment"]["enterprise"]["demoCTA"]["newRelease"]["ctaLink"](),
          ctaText:
            lang["payment"]["enterprise"]["demoCTA"]["newRelease"]["ctaText"](),
          subtitle:
            lang["payment"]["enterprise"]["demoCTA"]["newRelease"][
              "subtitle"
            ](),
          title:
            lang["payment"]["enterprise"]["demoCTA"]["newRelease"]["title"](),
        },
        subtitle: lang["payment"]["enterprise"]["demoCTA"]["subtitle"](),
        title: lang["payment"]["enterprise"]["demoCTA"]["title"](),
      },
    },
    excludes: lang["payment"]["excludes"](),
    freeTx: {
      coinbase: (args: { freeTxCount: Arg }): string =>
        lang["payment"]["freeTx"]["coinbase"](args) as string,
      coinjar: (args: { freeTxCount: Arg }): string =>
        lang["payment"]["freeTx"]["coinjar"](args) as string,
      default: (args: { freeTxCount: Arg }): string =>
        lang["payment"]["freeTx"]["default"](args) as string,
      phantom: (args: { freeTxCount: Arg }): string =>
        lang["payment"]["freeTx"]["phantom"](args) as string,
    },
    haveSmartContractTx: lang["payment"]["haveSmartContractTx"](),
    ignoredTx: (args: { ignoredTxCount: Arg }): string =>
      lang["payment"]["ignoredTx"](args) as string,
    moneyBackGuarantee: lang["payment"]["moneyBackGuarantee"](),
    moneyBackGuaranteeLower: lang["payment"]["moneyBackGuaranteeLower"](),
    mostPopular: lang["payment"]["mostPopular"](),
    paymentCycle: {
      annual: lang["payment"]["paymentCycle"]["annual"](),
      monthly: lang["payment"]["paymentCycle"]["monthly"](),
    },
    paymentCycleShort: {
      annual: lang["payment"]["paymentCycleShort"]["annual"](),
      monthly: lang["payment"]["paymentCycleShort"]["monthly"](),
    },
    perMonthBilledYearly: lang["payment"]["perMonthBilledYearly"](),
    promoBanner: {
      applied: lang["payment"]["promoBanner"]["applied"](),
      bitcoinDotComAu: [lang["payment"]["promoBanner"]["bitcoinDotComAu"][0]()],
      coinbase: [
        lang["payment"]["promoBanner"]["coinbase"][0](),
        lang["payment"]["promoBanner"]["coinbase"][1](),
      ],
      coinbaseone: {
        otherPlans:
          lang["payment"]["promoBanner"]["coinbaseone"]["otherPlans"](),
        rookie: (args: { price: Arg }): string =>
          lang["payment"]["promoBanner"]["coinbaseone"]["rookie"](
            args,
          ) as string,
      },
      coinbaseonepremium: [
        lang["payment"]["promoBanner"]["coinbaseonepremium"][0](),
        lang["payment"]["promoBanner"]["coinbaseonepremium"][1](),
      ],
      coinjar: [
        lang["payment"]["promoBanner"]["coinjar"][0](),
        lang["payment"]["promoBanner"]["coinjar"][1](),
      ],
      coinstats: lang["payment"]["promoBanner"]["coinstats"](),
      independentReserve: [
        lang["payment"]["promoBanner"]["independentReserve"][0](),
      ],
      metamask: [lang["payment"]["promoBanner"]["metamask"][0]()],
      partnerOffer: lang["payment"]["promoBanner"]["partnerOffer"](),
      phantom20: [
        lang["payment"]["promoBanner"]["phantom20"][0](),
        lang["payment"]["promoBanner"]["phantom20"][1](),
      ],
      referAFriend: [lang["payment"]["promoBanner"]["referAFriend"][0]()],
      referralOffer: lang["payment"]["promoBanner"]["referralOffer"](),
    },
    renewFailed: {
      bannerTitle: lang["payment"]["renewFailed"]["bannerTitle"](),
      renewError: lang["payment"]["renewFailed"]["renewError"](),
      renewPlan: lang["payment"]["renewFailed"]["renewPlan"](),
      renewSuccess: lang["payment"]["renewFailed"]["renewSuccess"](),
      settingText: lang["payment"]["renewFailed"]["settingText"](),
    },
    save20: lang["payment"]["save20"](),
    taxDeductible: lang["payment"]["taxDeductible"](),
    tooManyTx: lang["payment"]["tooManyTx"](),
    totalTxs: lang["payment"]["totalTxs"](),
    txCount: {
      prefix: lang["payment"]["txCount"]["prefix"](),
      suffix: lang["payment"]["txCount"]["suffix"](),
    },
    txCountFiltered: (args: { filtered: Arg; count: Arg }): string =>
      lang["payment"]["txCountFiltered"](args) as string,
    viewAccountant: lang["payment"]["viewAccountant"](),
    viewBusinessPlan: lang["payment"]["viewBusinessPlan"](),
    viewIndividualPlan: lang["payment"]["viewIndividualPlan"](),
    yourOffers: lang["payment"]["yourOffers"](),
  },
  paymentPortal: {
    cardDetailsLabel: lang["paymentPortal"]["cardDetailsLabel"](),
    dialogLabel: lang["paymentPortal"]["dialogLabel"](),
    payPrefixText: lang["paymentPortal"]["payPrefixText"](),
    paymentError: lang["paymentPortal"]["paymentError"](),
  },
  paymentStatus: {
    pending: {
      subtitle: lang["paymentStatus"]["pending"]["subtitle"](),
      title: lang["paymentStatus"]["pending"]["title"](),
    },
    success: {
      buttonText: lang["paymentStatus"]["success"]["buttonText"](),
      subtitle: lang["paymentStatus"]["success"]["subtitle"](),
      title: lang["paymentStatus"]["success"]["title"](),
    },
  },
  paywallBanner: {
    cancel: lang["paywallBanner"]["cancel"](),
    implementations: {
      accountant: {
        enforceUpgrade: {
          buttonText:
            lang["paywallBanner"]["implementations"]["accountant"][
              "enforceUpgrade"
            ]["buttonText"](),
          subtitle:
            lang["paywallBanner"]["implementations"]["accountant"][
              "enforceUpgrade"
            ]["subtitle"](),
          subtitleMulti:
            lang["paywallBanner"]["implementations"]["accountant"][
              "enforceUpgrade"
            ]["subtitleMulti"](),
          title: (args: { count: Arg; limit: Arg }): string =>
            lang["paywallBanner"]["implementations"]["accountant"][
              "enforceUpgrade"
            ]["title"](args) as string,
          titleMulti:
            lang["paywallBanner"]["implementations"]["accountant"][
              "enforceUpgrade"
            ]["titleMulti"](),
        },
        renewalFailed: {
          buttonText:
            lang["paywallBanner"]["implementations"]["accountant"][
              "renewalFailed"
            ]["buttonText"](),
          subtitle:
            lang["paywallBanner"]["implementations"]["accountant"][
              "renewalFailed"
            ]["subtitle"](),
          title:
            lang["paywallBanner"]["implementations"]["accountant"][
              "renewalFailed"
            ]["title"](),
        },
        signUp: {
          buttonText:
            lang["paywallBanner"]["implementations"]["accountant"]["signUp"][
              "buttonText"
            ](),
          subtitle:
            lang["paywallBanner"]["implementations"]["accountant"]["signUp"][
              "subtitle"
            ](),
          title:
            lang["paywallBanner"]["implementations"]["accountant"]["signUp"][
              "title"
            ](),
        },
      },
      standard: {
        enforceUpgrade: {
          buttonText:
            lang["paywallBanner"]["implementations"]["standard"][
              "enforceUpgrade"
            ]["buttonText"](),
          subtitle:
            lang["paywallBanner"]["implementations"]["standard"][
              "enforceUpgrade"
            ]["subtitle"](),
          subtitleMulti:
            lang["paywallBanner"]["implementations"]["standard"][
              "enforceUpgrade"
            ]["subtitleMulti"](),
          title: (args: { count: Arg; limit: Arg }): string =>
            lang["paywallBanner"]["implementations"]["standard"][
              "enforceUpgrade"
            ]["title"](args) as string,
          titleMulti:
            lang["paywallBanner"]["implementations"]["standard"][
              "enforceUpgrade"
            ]["titleMulti"](),
        },
        renewalFailed: {
          buttonText:
            lang["paywallBanner"]["implementations"]["standard"][
              "renewalFailed"
            ]["buttonText"](),
          subtitle:
            lang["paywallBanner"]["implementations"]["standard"][
              "renewalFailed"
            ]["subtitle"](),
          title:
            lang["paywallBanner"]["implementations"]["standard"][
              "renewalFailed"
            ]["title"](),
        },
        signUp: {
          buttonText:
            lang["paywallBanner"]["implementations"]["standard"]["signUp"][
              "buttonText"
            ](),
          subtitle:
            lang["paywallBanner"]["implementations"]["standard"]["signUp"][
              "subtitle"
            ](),
          title:
            lang["paywallBanner"]["implementations"]["standard"]["signUp"][
              "title"
            ](),
        },
      },
    },
    paywallReasons: {
      importCount: (args: { importLimit: Arg }): string =>
        lang["paywallBanner"]["paywallReasons"]["importCount"](args) as string,
      tradeType: lang["paywallBanner"]["paywallReasons"]["tradeType"](),
      txCount: (args: { txLimit: Arg }): string =>
        lang["paywallBanner"]["paywallReasons"]["txCount"](args) as string,
    },
    reportTable: lang["paywallBanner"]["reportTable"](),
    upgradeFor: lang["paywallBanner"]["upgradeFor"](),
    upgradeNow: lang["paywallBanner"]["upgradeNow"](),
  },
  paywallModal: {
    cancel: lang["paywallModal"]["cancel"](),
    downloadSample: lang["paywallModal"]["downloadSample"](),
    implementations: {
      advancedReports: {
        prompt:
          lang["paywallModal"]["implementations"]["advancedReports"][
            "prompt"
          ](),
        title:
          lang["paywallModal"]["implementations"]["advancedReports"]["title"](),
      },
      allReports: {
        prompt:
          lang["paywallModal"]["implementations"]["allReports"]["prompt"](),
        title: lang["paywallModal"]["implementations"]["allReports"]["title"](),
      },
      inventory: {
        prompt:
          lang["paywallModal"]["implementations"]["inventory"]["prompt"](),
        title: lang["paywallModal"]["implementations"]["inventory"]["title"](),
      },
      multiTaxYears: {
        subtitle:
          lang["paywallModal"]["implementations"]["multiTaxYears"][
            "subtitle"
          ](),
        title:
          lang["paywallModal"]["implementations"]["multiTaxYears"]["title"](),
      },
      paywall: {
        subtitle:
          lang["paywallModal"]["implementations"]["paywall"]["subtitle"](),
        title: lang["paywallModal"]["implementations"]["paywall"]["title"](),
      },
      smartContractInteraction: {
        banner: {
          subText:
            lang["paywallModal"]["implementations"]["smartContractInteraction"][
              "banner"
            ]["subText"](),
          text: lang["paywallModal"]["implementations"][
            "smartContractInteraction"
          ]["banner"]["text"](),
          title:
            lang["paywallModal"]["implementations"]["smartContractInteraction"][
              "banner"
            ]["title"](),
        },
        subtitle:
          lang["paywallModal"]["implementations"]["smartContractInteraction"][
            "subtitle"
          ](),
        title:
          lang["paywallModal"]["implementations"]["smartContractInteraction"][
            "title"
          ](),
      },
      tips: {
        subtitle: lang["paywallModal"]["implementations"]["tips"]["subtitle"](),
        subtitleReview:
          lang["paywallModal"]["implementations"]["tips"]["subtitleReview"](),
        title: lang["paywallModal"]["implementations"]["tips"]["title"](),
      },
      tradingStockReport: {
        subtitle:
          lang["paywallModal"]["implementations"]["tradingStockReport"][
            "subtitle"
          ](),
        title:
          lang["paywallModal"]["implementations"]["tradingStockReport"][
            "title"
          ](),
      },
    },
    irsModal: {
      dateString: (args: { days: Arg; hours: Arg; minutes: Arg }): string =>
        lang["paywallModal"]["irsModal"]["dateString"](args) as string,
      irsTaxSubmissionDeadline:
        lang["paywallModal"]["irsModal"]["irsTaxSubmissionDeadline"](),
    },
    irsVariantChoosePlan: {
      cta: lang["paywallModal"]["irsVariantChoosePlan"]["cta"](),
      secondary: lang["paywallModal"]["irsVariantChoosePlan"]["secondary"](),
      title: lang["paywallModal"]["irsVariantChoosePlan"]["title"](),
      txCount: (args: { txCount: Arg }): string =>
        lang["paywallModal"]["irsVariantChoosePlan"]["txCount"](args) as string,
      txSubtitle: (args: { txCount: Arg }): string =>
        lang["paywallModal"]["irsVariantChoosePlan"]["txSubtitle"](
          args,
        ) as string,
    },
    irsVariantPreSelectedPlan: {
      cta: lang["paywallModal"]["irsVariantPreSelectedPlan"]["cta"](),
      disclaimerOne:
        lang["paywallModal"]["irsVariantPreSelectedPlan"]["disclaimerOne"](),
      disclaimerTwo:
        lang["paywallModal"]["irsVariantPreSelectedPlan"]["disclaimerTwo"](),
      discountApplied:
        lang["paywallModal"]["irsVariantPreSelectedPlan"]["discountApplied"](),
      features: [
        {
          description:
            lang["paywallModal"]["irsVariantPreSelectedPlan"]["features"][0][
              "description"
            ](),
          title:
            lang["paywallModal"]["irsVariantPreSelectedPlan"]["features"][0][
              "title"
            ](),
        },
        {
          description:
            lang["paywallModal"]["irsVariantPreSelectedPlan"]["features"][1][
              "description"
            ](),
          title:
            lang["paywallModal"]["irsVariantPreSelectedPlan"]["features"][1][
              "title"
            ](),
        },
        {
          description:
            lang["paywallModal"]["irsVariantPreSelectedPlan"]["features"][2][
              "description"
            ](),
          title:
            lang["paywallModal"]["irsVariantPreSelectedPlan"]["features"][2][
              "title"
            ](),
        },
      ],
      link: lang["paywallModal"]["irsVariantPreSelectedPlan"]["link"](),
      price: (args: { price: Arg; discount: Arg }): string =>
        lang["paywallModal"]["irsVariantPreSelectedPlan"]["price"](
          args,
        ) as string,
      title: lang["paywallModal"]["irsVariantPreSelectedPlan"]["title"](),
    },
    irsVariantViewPlans: {
      cta: lang["paywallModal"]["irsVariantViewPlans"]["cta"](),
      metamaskOffer:
        lang["paywallModal"]["irsVariantViewPlans"]["metamaskOffer"](),
      reportsReady:
        lang["paywallModal"]["irsVariantViewPlans"]["reportsReady"](),
    },
    modalText: {
      "annex-so-other-income":
        lang["paywallModal"]["modalText"]["annex-so-other-income"](),
      audit: lang["paywallModal"]["modalText"]["audit"](),
      bgl: lang["paywallModal"]["modalText"]["bgl"](),
      "capital-gains": lang["paywallModal"]["modalText"]["capital-gains"](),
      "capital-gains-nz":
        lang["paywallModal"]["modalText"]["capital-gains-nz"](),
      cerfa2086: lang["paywallModal"]["modalText"]["cerfa2086"](),
      expense: lang["paywallModal"]["modalText"]["expense"](),
      "gift-and-lost": lang["paywallModal"]["modalText"]["gift-and-lost"](),
      income: lang["paywallModal"]["modalText"]["income"](),
      inventory: lang["paywallModal"]["modalText"]["inventory"](),
      "inventory-v2": lang["paywallModal"]["modalText"]["inventory-v2"](),
      "irs-1040-s1": lang["paywallModal"]["modalText"]["irs-1040-s1"](),
      "irs-1040-sd": lang["paywallModal"]["modalText"]["irs-1040-sd"](),
      "irs-8949": lang["paywallModal"]["modalText"]["irs-8949"](),
      "irs-8949-consolidated":
        lang["paywallModal"]["modalText"]["irs-8949-consolidated"](),
      "irs-8949-long": lang["paywallModal"]["modalText"]["irs-8949-long"](),
      "irs-8949-long-consolidated":
        lang["paywallModal"]["modalText"]["irs-8949-long-consolidated"](),
      "irs-8949-short": lang["paywallModal"]["modalText"]["irs-8949-short"](),
      "irs-8949-short-consolidated":
        lang["paywallModal"]["modalText"]["irs-8949-short-consolidated"](),
      "modelo-100": lang["paywallModal"]["modalText"]["modelo-100"](),
      mygov: lang["paywallModal"]["modalText"]["mygov"](),
      "nft-capital-gains":
        lang["paywallModal"]["modalText"]["nft-capital-gains"](),
      "nft-report": lang["paywallModal"]["modalText"]["nft-report"](),
      pack: lang["paywallModal"]["modalText"]["pack"](),
      "report-summary": lang["paywallModal"]["modalText"]["report-summary"](),
      "start-of-year-holdings":
        lang["paywallModal"]["modalText"]["start-of-year-holdings"](),
      "tax-lots": lang["paywallModal"]["modalText"]["tax-lots"](),
      "taxact-csv": lang["paywallModal"]["modalText"]["taxact-csv"](),
      "trading-pnl": lang["paywallModal"]["modalText"]["trading-pnl"](),
      "trading-stock": lang["paywallModal"]["modalText"]["trading-stock"](),
      transaction: lang["paywallModal"]["modalText"]["transaction"](),
      "turbotax-canadian-pdf":
        lang["paywallModal"]["modalText"]["turbotax-canadian-pdf"](),
      "turbotax-csv": lang["paywallModal"]["modalText"]["turbotax-csv"](),
      "turbotax-txf": lang["paywallModal"]["modalText"]["turbotax-txf"](),
      "wiso-steuer": lang["paywallModal"]["modalText"]["wiso-steuer"](),
    },
    reportTable: lang["paywallModal"]["reportTable"](),
    upgradeFor: lang["paywallModal"]["upgradeFor"](),
    upgradeNow: lang["paywallModal"]["upgradeNow"](),
  },
  pending: lang["pending"](),
  planCard: {
    annualSubscription: lang["planCard"]["annualSubscription"](),
    bestValue: lang["planCard"]["bestValue"](),
    billedYearly: lang["planCard"]["billedYearly"](),
    taxDeductible: lang["planCard"]["taxDeductible"](),
  },
  planFeatures: {
    coreFeatures: {
      features: {
        assetHistoryLedgers:
          lang["planFeatures"]["coreFeatures"]["features"][
            "assetHistoryLedgers"
          ](),
        deFiDEX: lang["planFeatures"]["coreFeatures"]["features"]["deFiDEX"](),
        fileImports:
          lang["planFeatures"]["coreFeatures"]["features"]["fileImports"](),
        marginTrades:
          lang["planFeatures"]["coreFeatures"]["features"]["marginTrades"](),
        miningStaking:
          lang["planFeatures"]["coreFeatures"]["features"]["miningStaking"](),
        nftTrading:
          lang["planFeatures"]["coreFeatures"]["features"]["nftTrading"](),
        smartErrorSuggestions:
          lang["planFeatures"]["coreFeatures"]["features"][
            "smartErrorSuggestions"
          ](),
        transanctionCategorisation:
          lang["planFeatures"]["coreFeatures"]["features"][
            "transanctionCategorisation"
          ](),
        transferMatching:
          lang["planFeatures"]["coreFeatures"]["features"][
            "transferMatching"
          ](),
        walletSync:
          lang["planFeatures"]["coreFeatures"]["features"]["walletSync"](),
      },
      name: lang["planFeatures"]["coreFeatures"]["name"](),
    },
    dynamic: {
      features: {
        complexTransactions:
          lang["planFeatures"]["dynamic"]["features"]["complexTransactions"](),
        integrations:
          lang["planFeatures"]["dynamic"]["features"]["integrations"](),
        inventoryAdvanced:
          lang["planFeatures"]["dynamic"]["features"]["inventoryAdvanced"](),
        portfolioTracking:
          lang["planFeatures"]["dynamic"]["features"]["portfolioTracking"](),
        reportPreview:
          lang["planFeatures"]["dynamic"]["features"]["reportPreview"](),
        reportsAdvanced:
          lang["planFeatures"]["dynamic"]["features"]["reportsAdvanced"](),
        reportsPrevYears:
          lang["planFeatures"]["dynamic"]["features"]["reportsPrevYears"](),
        supportEmailChat:
          lang["planFeatures"]["dynamic"]["features"]["supportEmailChat"](),
        supportPriority:
          lang["planFeatures"]["dynamic"]["features"]["supportPriority"](),
        taxLossHarvesting:
          lang["planFeatures"]["dynamic"]["features"]["taxLossHarvesting"](),
        transactions:
          lang["planFeatures"]["dynamic"]["features"]["transactions"](),
      },
      name: lang["planFeatures"]["dynamic"]["name"](),
    },
    integrations: {
      features: {
        blockchains:
          lang["planFeatures"]["integrations"]["features"]["blockchains"](),
        centralisedExchanges:
          lang["planFeatures"]["integrations"]["features"][
            "centralisedExchanges"
          ](),
        decentralisedExchanges:
          lang["planFeatures"]["integrations"]["features"][
            "decentralisedExchanges"
          ](),
        nftMarketplaces:
          lang["planFeatures"]["integrations"]["features"]["nftMarketplaces"](),
        smartContract:
          lang["planFeatures"]["integrations"]["features"]["smartContract"](),
      },
      name: lang["planFeatures"]["integrations"]["name"](),
    },
    smartSuggestions: {
      features: {
        airdropDetection:
          lang["planFeatures"]["smartSuggestions"]["features"][
            "airdropDetection"
          ](),
        balanceVerification:
          lang["planFeatures"]["smartSuggestions"]["features"][
            "balanceVerification"
          ](),
        incorrectCategoristion:
          lang["planFeatures"]["smartSuggestions"]["features"][
            "incorrectCategoristion"
          ](),
        integration:
          lang["planFeatures"]["smartSuggestions"]["features"]["integration"](),
        negativeBalance:
          lang["planFeatures"]["smartSuggestions"]["features"][
            "negativeBalance"
          ](),
        spamDetection:
          lang["planFeatures"]["smartSuggestions"]["features"][
            "spamDetection"
          ](),
        transactionCategory:
          lang["planFeatures"]["smartSuggestions"]["features"][
            "transactionCategory"
          ](),
        transferMatching:
          lang["planFeatures"]["smartSuggestions"]["features"][
            "transferMatching"
          ](),
      },
      name: lang["planFeatures"]["smartSuggestions"]["name"](),
    },
    support: {
      features: {
        chat: lang["planFeatures"]["support"]["features"]["chat"](),
        email: lang["planFeatures"]["support"]["features"]["email"](),
        priority: lang["planFeatures"]["support"]["features"]["priority"](),
      },
      name: lang["planFeatures"]["support"]["name"](),
    },
    transactionsManagement: {
      features: {
        advancedFiltering:
          lang["planFeatures"]["transactionsManagement"]["features"][
            "advancedFiltering"
          ](),
        bulkEdit:
          lang["planFeatures"]["transactionsManagement"]["features"][
            "bulkEdit"
          ](),
        noteTaking:
          lang["planFeatures"]["transactionsManagement"]["features"][
            "noteTaking"
          ](),
        protocolIdentification:
          lang["planFeatures"]["transactionsManagement"]["features"][
            "protocolIdentification"
          ](),
        quickSearch:
          lang["planFeatures"]["transactionsManagement"]["features"][
            "quickSearch"
          ](),
      },
      name: lang["planFeatures"]["transactionsManagement"]["name"](),
    },
  },
  plans: {
    personal: lang["plans"]["personal"](),
    professional: lang["plans"]["professional"](),
  },
  "polygon-zkevm": {
    "wallet-api": [
      lang["polygon-zkevm"]["wallet-api"][0](),
      {
        items: [lang["polygon-zkevm"]["wallet-api"][1]["items"][0]()],
        type: lang["polygon-zkevm"]["wallet-api"][1]["type"](),
      },
    ],
  },
  popup: { enablePopups: lang["popup"]["enablePopups"]() },
  portfolioToggles: lang["portfolioToggles"](),
  qbo: {
    accountMapping: {
      accounts: {
        asset: {
          title: lang["qbo"]["accountMapping"]["accounts"]["asset"]["title"](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["asset"]["tooltip"](),
        },
        cash: {
          title: lang["qbo"]["accountMapping"]["accounts"]["cash"]["title"](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["cash"]["tooltip"](),
        },
        expense: {
          title:
            lang["qbo"]["accountMapping"]["accounts"]["expense"]["title"](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["expense"]["tooltip"](),
        },
        fee: {
          title: lang["qbo"]["accountMapping"]["accounts"]["fee"]["title"](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["fee"]["tooltip"](),
        },
        income: {
          title: lang["qbo"]["accountMapping"]["accounts"]["income"]["title"](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["income"]["tooltip"](),
        },
        loan: {
          title: lang["qbo"]["accountMapping"]["accounts"]["loan"]["title"](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["loan"]["tooltip"](),
        },
        loss: {
          title: lang["qbo"]["accountMapping"]["accounts"]["loss"]["title"](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["loss"]["tooltip"](),
        },
        "realised-gain": {
          title:
            lang["qbo"]["accountMapping"]["accounts"]["realised-gain"][
              "title"
            ](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["realised-gain"][
              "tooltip"
            ](),
        },
        "realised-loss": {
          title:
            lang["qbo"]["accountMapping"]["accounts"]["realised-loss"][
              "title"
            ](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["realised-loss"][
              "tooltip"
            ](),
        },
        "zero-cost-buy": {
          title:
            lang["qbo"]["accountMapping"]["accounts"]["zero-cost-buy"][
              "title"
            ](),
          tooltip:
            lang["qbo"]["accountMapping"]["accounts"]["zero-cost-buy"][
              "tooltip"
            ](),
        },
      },
      lastSynced: (args: { date: Arg }): string =>
        lang["qbo"]["accountMapping"]["lastSynced"](args) as string,
      refreshInstructions:
        lang["qbo"]["accountMapping"]["refreshInstructions"](),
      syncError: lang["qbo"]["accountMapping"]["syncError"](),
      syncInstructions: lang["qbo"]["accountMapping"]["syncInstructions"](),
      title: lang["qbo"]["accountMapping"]["title"](),
    },
    buttons: {
      disconnect: lang["qbo"]["buttons"]["disconnect"](),
      refreshList: {
        completed: lang["qbo"]["buttons"]["refreshList"]["completed"](),
        done: lang["qbo"]["buttons"]["refreshList"]["done"](),
        pending: lang["qbo"]["buttons"]["refreshList"]["pending"](),
        tooltip: {
          done: lang["qbo"]["buttons"]["refreshList"]["tooltip"]["done"](),
          pending:
            lang["qbo"]["buttons"]["refreshList"]["tooltip"]["pending"](),
        },
      },
      syncAccounts: {
        done: lang["qbo"]["buttons"]["syncAccounts"]["done"](),
        pending: lang["qbo"]["buttons"]["syncAccounts"]["pending"](),
        tooltip: {
          disabled:
            lang["qbo"]["buttons"]["syncAccounts"]["tooltip"]["disabled"](),
          done: lang["qbo"]["buttons"]["syncAccounts"]["tooltip"]["done"](),
          pending:
            lang["qbo"]["buttons"]["syncAccounts"]["tooltip"]["pending"](),
        },
      },
    },
    connect: lang["qbo"]["connect"](),
    errors: {
      disconnecting: lang["qbo"]["errors"]["disconnecting"](),
      fetchingAccounts: lang["qbo"]["errors"]["fetchingAccounts"](),
      fetchingSettings: lang["qbo"]["errors"]["fetchingSettings"](),
      fetchingTenants: lang["qbo"]["errors"]["fetchingTenants"](),
      updatingAccounts: lang["qbo"]["errors"]["updatingAccounts"](),
      updatingTenant: lang["qbo"]["errors"]["updatingTenant"](),
    },
    integrationName: lang["qbo"]["integrationName"](),
    integrationShortName: lang["qbo"]["integrationShortName"](),
    newIntegration: {
      connected: lang["qbo"]["newIntegration"]["connected"](),
      connectedAt: (args: { date: Arg }): string =>
        lang["qbo"]["newIntegration"]["connectedAt"](args) as string,
      description: lang["qbo"]["newIntegration"]["description"](),
      disconnectConfirmation:
        lang["qbo"]["newIntegration"]["disconnectConfirmation"](),
      disconnected: lang["qbo"]["newIntegration"]["disconnected"](),
      status: lang["qbo"]["newIntegration"]["status"](),
      title: lang["qbo"]["newIntegration"]["title"](),
    },
    reportFooter: {
      settingsLink: lang["qbo"]["reportFooter"]["settingsLink"](),
      subTitle: lang["qbo"]["reportFooter"]["subTitle"](),
      title: lang["qbo"]["reportFooter"]["title"](),
    },
    rollup: {
      day: lang["qbo"]["rollup"]["day"](),
      description: {
        day: lang["qbo"]["rollup"]["description"]["day"](),
        month: lang["qbo"]["rollup"]["description"]["month"](),
        none: lang["qbo"]["rollup"]["description"]["none"](),
      },
      month: lang["qbo"]["rollup"]["month"](),
      none: lang["qbo"]["rollup"]["none"](),
    },
    syncDialog: {
      confirmation: lang["qbo"]["syncDialog"]["confirmation"](),
      error: lang["qbo"]["syncDialog"]["error"](),
      syncing: lang["qbo"]["syncDialog"]["syncing"](),
    },
    tenantList: {
      ensureCorrectTenant: lang["qbo"]["tenantList"]["ensureCorrectTenant"](),
      exportJournals: lang["qbo"]["tenantList"]["exportJournals"](),
      listPlaceholder: lang["qbo"]["tenantList"]["listPlaceholder"](),
      listTitle: lang["qbo"]["tenantList"]["listTitle"](),
    },
  },
  reOnboarding: {
    sync: {
      ExchangeBlockchain: lang["reOnboarding"]["sync"]["ExchangeBlockchain"](),
      lastSynced: lang["reOnboarding"]["sync"]["lastSynced"](),
      noImport: lang["reOnboarding"]["sync"]["noImport"](),
    },
    welcomeBack: {
      afterSyncBanner: lang["reOnboarding"]["welcomeBack"]["afterSyncBanner"](),
      brushUp: lang["reOnboarding"]["welcomeBack"]["brushUp"](),
      integrationsPage:
        lang["reOnboarding"]["welcomeBack"]["integrationsPage"](),
      letMeIn: lang["reOnboarding"]["welcomeBack"]["letMeIn"](),
      subTitle: lang["reOnboarding"]["welcomeBack"]["subTitle"](),
      sync: lang["reOnboarding"]["welcomeBack"]["sync"](),
      syncFailedBanner:
        lang["reOnboarding"]["welcomeBack"]["syncFailedBanner"](),
      title: lang["reOnboarding"]["welcomeBack"]["title"](),
    },
  },
  reconciliation: {
    allTransactions: lang["reconciliation"]["allTransactions"](),
    api: {
      assignBlockchainSource:
        lang["reconciliation"]["api"]["assignBlockchainSource"](),
      bulkLookupMarketPriceError:
        lang["reconciliation"]["api"]["bulkLookupMarketPriceError"](),
      generateTransferError:
        lang["reconciliation"]["api"]["generateTransferError"](),
      identifyUnknownParty:
        lang["reconciliation"]["api"]["identifyUnknownParty"](),
      ignoreAllMissingPriceError:
        lang["reconciliation"]["api"]["ignoreAllMissingPriceError"](),
      ignoreError: lang["reconciliation"]["api"]["ignoreError"](),
      markAirdropError: lang["reconciliation"]["api"]["markAirdropError"](),
      markAllAirdropError:
        lang["reconciliation"]["api"]["markAllAirdropError"](),
      markAllSpamCurrencyError:
        lang["reconciliation"]["api"]["markAllSpamCurrencyError"](),
      markSpamCurrencyError:
        lang["reconciliation"]["api"]["markSpamCurrencyError"](),
      refreshError: lang["reconciliation"]["api"]["refreshError"](),
    },
    backToTransactions: lang["reconciliation"]["backToTransactions"](),
    completedCountChip: (args: { issueCount: Arg }): string =>
      lang["reconciliation"]["completedCountChip"](args) as string,
    completedCountEmbeddedChip: (args: { issueCount: Arg }): string =>
      lang["reconciliation"]["completedCountEmbeddedChip"](args) as string,
    explainer: {
      consider: lang["reconciliation"]["explainer"]["consider"](),
      done: lang["reconciliation"]["explainer"]["done"](),
      usefulLinks: lang["reconciliation"]["explainer"]["usefulLinks"](),
    },
    fullyReconciledState: {
      ctaText: lang["reconciliation"]["fullyReconciledState"]["ctaText"](),
      disclaimer:
        lang["reconciliation"]["fullyReconciledState"]["disclaimer"](),
      neutralMessage:
        lang["reconciliation"]["fullyReconciledState"]["neutralMessage"](),
      successMessage:
        lang["reconciliation"]["fullyReconciledState"]["successMessage"](),
      successSubMessage:
        lang["reconciliation"]["fullyReconciledState"]["successSubMessage"](),
    },
    helpArticles: lang["reconciliation"]["helpArticles"](),
    ignoreIssue: lang["reconciliation"]["ignoreIssue"](),
    ignoreWarning: lang["reconciliation"]["ignoreWarning"](),
    impactChip: {
      high: lang["reconciliation"]["impactChip"]["high"](),
      low: lang["reconciliation"]["impactChip"]["low"](),
      medium: lang["reconciliation"]["impactChip"]["medium"](),
    },
    issueCountChip: (args: { issueCount: Arg }): string =>
      lang["reconciliation"]["issueCountChip"](args) as string,
    issues: {
      incorrectSendReceives: {
        associatedTransactions:
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "associatedTransactions"
          ](),
        ctaButton: {
          importDestination:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "ctaButton"
            ]["importDestination"](),
          importSource:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "ctaButton"
            ]["importSource"](),
          name: lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "ctaButton"
          ]["name"](),
        },
        description:
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "description"
          ](),
        ignore:
          lang["reconciliation"]["issues"]["incorrectSendReceives"]["ignore"](),
        ignoreIssue: {
          action:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "ignoreIssue"
            ]["action"](),
          description: (args: { source: Arg }): string =>
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "ignoreIssue"
            ]["description"](args) as string,
          title:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "ignoreIssue"
            ]["title"](),
        },
        importSource:
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "importSource"
          ](),
        imports: {
          address:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "imports"
            ]["address"](),
          bannerText:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "imports"
            ]["bannerText"](),
          bannerTitle:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "imports"
            ]["bannerTitle"](),
          goBack:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "imports"
            ]["goBack"](),
          label:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "imports"
            ]["label"](),
        },
        links: [
          {
            href: lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "links"
            ][0]["href"](),
            title:
              lang["reconciliation"]["issues"]["incorrectSendReceives"][
                "links"
              ][0]["title"](),
          },
        ],
        missingSource:
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "missingSource"
          ](),
        navButton:
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "navButton"
          ](),
        notMyWallet:
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "notMyWallet"
          ](),
        steps: [
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "steps"
          ][0](),
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "steps"
          ][1](),
        ],
        tableHeader: {
          assets:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "tableHeader"
            ]["assets"](),
          associatedGainsLaptop:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "tableHeader"
            ]["associatedGainsLaptop"](),
          destination:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "tableHeader"
            ]["destination"](),
          source:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "tableHeader"
            ]["source"](),
          transactions:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "tableHeader"
            ]["transactions"](),
          transactionsLaptop:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "tableHeader"
            ]["transactionsLaptop"](),
          transferAmount:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "tableHeader"
            ]["transferAmount"](),
          transferAmountLaptop:
            lang["reconciliation"]["issues"]["incorrectSendReceives"][
              "tableHeader"
            ]["transferAmountLaptop"](),
        },
        title:
          lang["reconciliation"]["issues"]["incorrectSendReceives"]["title"](),
        transferBalance:
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "transferBalance"
          ](),
        transferBalanceTooltip:
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "transferBalanceTooltip"
          ](),
        viewTransactions:
          lang["reconciliation"]["issues"]["incorrectSendReceives"][
            "viewTransactions"
          ](),
      },
      missingBlockchain: {
        assignBlockchain:
          lang["reconciliation"]["issues"]["missingBlockchain"][
            "assignBlockchain"
          ](),
        chipTitle:
          lang["reconciliation"]["issues"]["missingBlockchain"]["chipTitle"](),
        description:
          lang["reconciliation"]["issues"]["missingBlockchain"][
            "description"
          ](),
        headers: {
          amount:
            lang["reconciliation"]["issues"]["missingBlockchain"]["headers"][
              "amount"
            ](),
          assets:
            lang["reconciliation"]["issues"]["missingBlockchain"]["headers"][
              "assets"
            ](),
          source:
            lang["reconciliation"]["issues"]["missingBlockchain"]["headers"][
              "source"
            ](),
          transactions:
            lang["reconciliation"]["issues"]["missingBlockchain"]["headers"][
              "transactions"
            ](),
          transferAmount:
            lang["reconciliation"]["issues"]["missingBlockchain"]["headers"][
              "transferAmount"
            ](),
          txs: lang["reconciliation"]["issues"]["missingBlockchain"]["headers"][
            "txs"
          ](),
        },
        links: [
          {
            href: lang["reconciliation"]["issues"]["missingBlockchain"][
              "links"
            ][0]["href"](),
            title:
              lang["reconciliation"]["issues"]["missingBlockchain"]["links"][0][
                "title"
              ](),
          },
        ],
        navButton:
          lang["reconciliation"]["issues"]["missingBlockchain"]["navButton"](),
        steps: [
          lang["reconciliation"]["issues"]["missingBlockchain"]["steps"][0](),
          lang["reconciliation"]["issues"]["missingBlockchain"]["steps"][1](),
        ],
        title: lang["reconciliation"]["issues"]["missingBlockchain"]["title"](),
      },
      missingPrice: {
        added: lang["reconciliation"]["issues"]["missingPrice"]["added"](),
        bulkLookupButton: {
          failUpdated:
            lang["reconciliation"]["issues"]["missingPrice"][
              "bulkLookupButton"
            ]["failUpdated"](),
          label:
            lang["reconciliation"]["issues"]["missingPrice"][
              "bulkLookupButton"
            ]["label"](),
          successUpdated: (args: { count: Arg }): string =>
            lang["reconciliation"]["issues"]["missingPrice"][
              "bulkLookupButton"
            ]["successUpdated"](args) as string,
        },
        chipTitle:
          lang["reconciliation"]["issues"]["missingPrice"]["chipTitle"](),
        ctaButton: {
          label:
            lang["reconciliation"]["issues"]["missingPrice"]["ctaButton"][
              "label"
            ](),
          labelLaptop:
            lang["reconciliation"]["issues"]["missingPrice"]["ctaButton"][
              "labelLaptop"
            ](),
        },
        description:
          lang["reconciliation"]["issues"]["missingPrice"]["description"](),
        drawer: {
          cta: lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
            "cta"
          ](),
          headerTwo:
            lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
              "headerTwo"
            ](),
          helpLinks: [
            {
              href: lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
                "helpLinks"
              ][0]["href"](),
              title:
                lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
                  "helpLinks"
                ][0]["title"](),
            },
          ],
          linkSectionHeader:
            lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
              "linkSectionHeader"
            ](),
          listItems: [
            lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
              "listItems"
            ][0](),
            lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
              "listItems"
            ][1](),
            lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
              "listItems"
            ][2](),
            lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
              "listItems"
            ][3](),
          ],
          listTitle:
            lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
              "listTitle"
            ](),
          paragraphOne:
            lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
              "paragraphOne"
            ](),
          paragraphTwo:
            lang["reconciliation"]["issues"]["missingPrice"]["drawer"][
              "paragraphTwo"
            ](),
        },
        ignoreAllIssue: {
          action:
            lang["reconciliation"]["issues"]["missingPrice"]["ignoreAllIssue"][
              "action"
            ](),
          description:
            lang["reconciliation"]["issues"]["missingPrice"]["ignoreAllIssue"][
              "description"
            ](),
          title:
            lang["reconciliation"]["issues"]["missingPrice"]["ignoreAllIssue"][
              "title"
            ](),
        },
        ignoreIssue: {
          action:
            lang["reconciliation"]["issues"]["missingPrice"]["ignoreIssue"][
              "action"
            ](),
          description: (args: { name: Arg }): string =>
            lang["reconciliation"]["issues"]["missingPrice"]["ignoreIssue"][
              "description"
            ](args) as string,
          title: (args: { name: Arg; ticker: Arg }): string =>
            lang["reconciliation"]["issues"]["missingPrice"]["ignoreIssue"][
              "title"
            ](args) as string,
        },
        missingPrice:
          lang["reconciliation"]["issues"]["missingPrice"]["missingPrice"](),
        navButton:
          lang["reconciliation"]["issues"]["missingPrice"]["navButton"](),
        tableHeader: {
          assets:
            lang["reconciliation"]["issues"]["missingPrice"]["tableHeader"][
              "assets"
            ](),
          associatedGains:
            lang["reconciliation"]["issues"]["missingPrice"]["tableHeader"][
              "associatedGains"
            ](),
          associatedGainsLaptop:
            lang["reconciliation"]["issues"]["missingPrice"]["tableHeader"][
              "associatedGainsLaptop"
            ](),
          currency:
            lang["reconciliation"]["issues"]["missingPrice"]["tableHeader"][
              "currency"
            ](),
          transactions:
            lang["reconciliation"]["issues"]["missingPrice"]["tableHeader"][
              "transactions"
            ](),
          transactionsLaptop:
            lang["reconciliation"]["issues"]["missingPrice"]["tableHeader"][
              "transactionsLaptop"
            ](),
        },
        title: lang["reconciliation"]["issues"]["missingPrice"]["title"](),
        viewTransactions:
          lang["reconciliation"]["issues"]["missingPrice"][
            "viewTransactions"
          ](),
      },
      negativeBalance: {
        amountExplanation:
          lang["reconciliation"]["issues"]["negativeBalance"][
            "amountExplanation"
          ](),
        chipTitle:
          lang["reconciliation"]["issues"]["negativeBalance"]["chipTitle"](),
        ctaButton: {
          label:
            lang["reconciliation"]["issues"]["negativeBalance"]["ctaButton"][
              "label"
            ](),
          labelLaptop:
            lang["reconciliation"]["issues"]["negativeBalance"]["ctaButton"][
              "labelLaptop"
            ](),
        },
        currency:
          lang["reconciliation"]["issues"]["negativeBalance"]["currency"](),
        description:
          lang["reconciliation"]["issues"]["negativeBalance"]["description"](),
        drawer: {
          cta: lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
            "cta"
          ](),
          headerTwo:
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "headerTwo"
            ](),
          helpLinks: [
            {
              href: lang["reconciliation"]["issues"]["negativeBalance"][
                "drawer"
              ]["helpLinks"][0]["href"](),
              title:
                lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
                  "helpLinks"
                ][0]["title"](),
            },
            {
              href: lang["reconciliation"]["issues"]["negativeBalance"][
                "drawer"
              ]["helpLinks"][1]["href"](),
              title:
                lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
                  "helpLinks"
                ][1]["title"](),
            },
          ],
          linkSectionHeader:
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "linkSectionHeader"
            ](),
          listTitle:
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "listTitle"
            ](),
          orderedListItems: [
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "orderedListItems"
            ][0](),
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "orderedListItems"
            ][1](),
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "orderedListItems"
            ][2](),
          ],
          paragraphOne:
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "paragraphOne"
            ](),
          paragraphThree:
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "paragraphThree"
            ](),
          paragraphTwoBoldSection:
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "paragraphTwoBoldSection"
            ](),
          paragraphTwoRegularSection:
            lang["reconciliation"]["issues"]["negativeBalance"]["drawer"][
              "paragraphTwoRegularSection"
            ](),
        },
        gains: lang["reconciliation"]["issues"]["negativeBalance"]["gains"](),
        grouping: {
          byCurrency:
            lang["reconciliation"]["issues"]["negativeBalance"]["grouping"][
              "byCurrency"
            ](),
          byWallet:
            lang["reconciliation"]["issues"]["negativeBalance"]["grouping"][
              "byWallet"
            ](),
        },
        links: [
          {
            href: lang["reconciliation"]["issues"]["negativeBalance"][
              "links"
            ][0]["href"](),
            title:
              lang["reconciliation"]["issues"]["negativeBalance"]["links"][0][
                "title"
              ](),
          },
          {
            href: lang["reconciliation"]["issues"]["negativeBalance"][
              "links"
            ][1]["href"](),
            title:
              lang["reconciliation"]["issues"]["negativeBalance"]["links"][1][
                "title"
              ](),
          },
          {
            href: lang["reconciliation"]["issues"]["negativeBalance"][
              "links"
            ][2]["href"](),
            title:
              lang["reconciliation"]["issues"]["negativeBalance"]["links"][2][
                "title"
              ](),
          },
          {
            href: lang["reconciliation"]["issues"]["negativeBalance"][
              "links"
            ][3]["href"](),
            title:
              lang["reconciliation"]["issues"]["negativeBalance"]["links"][3][
                "title"
              ](),
          },
        ],
        navButton:
          lang["reconciliation"]["issues"]["negativeBalance"]["navButton"](),
        steps: [
          lang["reconciliation"]["issues"]["negativeBalance"]["steps"][0](),
          lang["reconciliation"]["issues"]["negativeBalance"]["steps"][1](),
          lang["reconciliation"]["issues"]["negativeBalance"]["steps"][2](),
        ],
        tableHeader: {
          amountNegative:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "amountNegative"
            ](),
          amountNegativeDesktop:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "amountNegativeDesktop"
            ](),
          assets:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "assets"
            ](),
          associatedGains:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "associatedGains"
            ](),
          associatedGainsLaptop:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "associatedGainsLaptop"
            ](),
          currency:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "currency"
            ](),
          source:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "source"
            ](),
          taxImpact:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "taxImpact"
            ](),
          transactions:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "transactions"
            ](),
          transactionsDesktop:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "transactionsDesktop"
            ](),
          transferAmount:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "transferAmount"
            ](),
          transferAmountDesktop:
            lang["reconciliation"]["issues"]["negativeBalance"]["tableHeader"][
              "transferAmountDesktop"
            ](),
        },
        title: lang["reconciliation"]["issues"]["negativeBalance"]["title"](),
        transactions:
          lang["reconciliation"]["issues"]["negativeBalance"]["transactions"](),
        viewLedger:
          lang["reconciliation"]["issues"]["negativeBalance"]["viewLedger"](),
        viewTransactions:
          lang["reconciliation"]["issues"]["negativeBalance"][
            "viewTransactions"
          ](),
      },
      negativeBalanceByWallet: {
        title:
          lang["reconciliation"]["issues"]["negativeBalanceByWallet"][
            "title"
          ](),
      },
      smartContractInteractions: {
        categorise:
          lang["reconciliation"]["issues"]["smartContractInteractions"][
            "categorise"
          ](),
        description:
          lang["reconciliation"]["issues"]["smartContractInteractions"][
            "description"
          ](),
        navButton:
          lang["reconciliation"]["issues"]["smartContractInteractions"][
            "navButton"
          ](),
        tableHeader: {
          addresses:
            lang["reconciliation"]["issues"]["smartContractInteractions"][
              "tableHeader"
            ]["addresses"](),
          amount:
            lang["reconciliation"]["issues"]["smartContractInteractions"][
              "tableHeader"
            ]["amount"](),
          assets:
            lang["reconciliation"]["issues"]["smartContractInteractions"][
              "tableHeader"
            ]["assets"](),
          transactions:
            lang["reconciliation"]["issues"]["smartContractInteractions"][
              "tableHeader"
            ]["transactions"](),
          transferAmount:
            lang["reconciliation"]["issues"]["smartContractInteractions"][
              "tableHeader"
            ]["transferAmount"](),
          txs: lang["reconciliation"]["issues"]["smartContractInteractions"][
            "tableHeader"
          ]["txs"](),
        },
        title:
          lang["reconciliation"]["issues"]["smartContractInteractions"][
            "title"
          ](),
      },
      suspectedAirdropTransaction: {
        description:
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "description"
          ](),
        links: [
          {
            href: lang["reconciliation"]["issues"][
              "suspectedAirdropTransaction"
            ]["links"][0]["href"](),
            title:
              lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
                "links"
              ][0]["title"](),
          },
        ],
        markAllAsTrade: {
          button:
            lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
              "markAllAsTrade"
            ]["button"](),
          confirm:
            lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
              "markAllAsTrade"
            ]["confirm"](),
          description:
            lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
              "markAllAsTrade"
            ]["description"](),
          title:
            lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
              "markAllAsTrade"
            ]["title"](),
        },
        markAsNotTrade:
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "markAsNotTrade"
          ](),
        markAsTrade:
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "markAsTrade"
          ](),
        markSelectedAsTrade:
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "markSelectedAsTrade"
          ](),
        navButton:
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "navButton"
          ](),
        notTrade:
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "notTrade"
          ](),
        steps: [
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "steps"
          ][0](),
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "steps"
          ][1](),
        ],
        title:
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "title"
          ](),
        trade:
          lang["reconciliation"]["issues"]["suspectedAirdropTransaction"][
            "trade"
          ](),
      },
      suspectedBurnTransaction: {
        description:
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "description"
          ](),
        markAllAsTrade: {
          button:
            lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
              "markAllAsTrade"
            ]["button"](),
          confirm:
            lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
              "markAllAsTrade"
            ]["confirm"](),
          description:
            lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
              "markAllAsTrade"
            ]["description"](),
          title:
            lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
              "markAllAsTrade"
            ]["title"](),
        },
        markAsNotTrade:
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "markAsNotTrade"
          ](),
        markAsTrade:
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "markAsTrade"
          ](),
        markSelectedAsTrade:
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "markSelectedAsTrade"
          ](),
        navButton:
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "navButton"
          ](),
        notTrade:
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "notTrade"
          ](),
        steps: [
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "steps"
          ][0](),
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "steps"
          ][1](),
        ],
        title:
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "title"
          ](),
        trade:
          lang["reconciliation"]["issues"]["suspectedBurnTransaction"][
            "trade"
          ](),
      },
      suspectedMissingImports: {
        associatedTransactions:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "associatedTransactions"
          ](),
        ctaButton: {
          label:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "ctaButton"
            ]["label"](),
          name: lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "ctaButton"
          ]["name"](),
        },
        description:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "description"
          ](),
        ignore:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "ignore"
          ](),
        ignoreIssue: {
          action:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "ignoreIssue"
            ]["action"](),
          description: (args: { source: Arg }): string =>
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "ignoreIssue"
            ]["description"](args) as string,
          title:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "ignoreIssue"
            ]["title"](),
        },
        importSource:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "importSource"
          ](),
        imports: {
          address:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "imports"
            ]["address"](),
          bannerText:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "imports"
            ]["bannerText"](),
          bannerTitle:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "imports"
            ]["bannerTitle"](),
          goBack:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "imports"
            ]["goBack"](),
          label:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "imports"
            ]["label"](),
        },
        links: [
          {
            href: lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "links"
            ][0]["href"](),
            title:
              lang["reconciliation"]["issues"]["suspectedMissingImports"][
                "links"
              ][0]["title"](),
          },
          {
            href: lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "links"
            ][1]["href"](),
            title:
              lang["reconciliation"]["issues"]["suspectedMissingImports"][
                "links"
              ][1]["title"](),
          },
          {
            href: lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "links"
            ][2]["href"](),
            title:
              lang["reconciliation"]["issues"]["suspectedMissingImports"][
                "links"
              ][2]["title"](),
          },
        ],
        missingSource:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "missingSource"
          ](),
        navButton:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "navButton"
          ](),
        notMyWallet:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "notMyWallet"
          ](),
        steps: [
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "steps"
          ][0](),
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "steps"
          ][1](),
        ],
        tableHeader: {
          assets:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "tableHeader"
            ]["assets"](),
          associatedGainsLaptop:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "tableHeader"
            ]["associatedGainsLaptop"](),
          source:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "tableHeader"
            ]["source"](),
          transactions:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "tableHeader"
            ]["transactions"](),
          transactionsLaptop:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "tableHeader"
            ]["transactionsLaptop"](),
          transferAmount:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "tableHeader"
            ]["transferAmount"](),
          transferAmountLaptop:
            lang["reconciliation"]["issues"]["suspectedMissingImports"][
              "tableHeader"
            ]["transferAmountLaptop"](),
        },
        title:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "title"
          ](),
        transferBalance:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "transferBalance"
          ](),
        transferBalanceTooltip:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "transferBalanceTooltip"
          ](),
        viewImport:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "viewImport"
          ](),
        viewTransactions:
          lang["reconciliation"]["issues"]["suspectedMissingImports"][
            "viewTransactions"
          ](),
      },
      suspectedSpamToken: {
        currency:
          lang["reconciliation"]["issues"]["suspectedSpamToken"]["currency"](),
        description:
          lang["reconciliation"]["issues"]["suspectedSpamToken"][
            "description"
          ](),
        headers: {
          asset:
            lang["reconciliation"]["issues"]["suspectedSpamToken"]["headers"][
              "asset"
            ](),
          price:
            lang["reconciliation"]["issues"]["suspectedSpamToken"]["headers"][
              "price"
            ](),
          source:
            lang["reconciliation"]["issues"]["suspectedSpamToken"]["headers"][
              "source"
            ](),
          transactions:
            lang["reconciliation"]["issues"]["suspectedSpamToken"]["headers"][
              "transactions"
            ](),
          txs: lang["reconciliation"]["issues"]["suspectedSpamToken"][
            "headers"
          ]["txs"](),
          value:
            lang["reconciliation"]["issues"]["suspectedSpamToken"]["headers"][
              "value"
            ](),
        },
        links: [
          {
            href: lang["reconciliation"]["issues"]["suspectedSpamToken"][
              "links"
            ][0]["href"](),
            title:
              lang["reconciliation"]["issues"]["suspectedSpamToken"][
                "links"
              ][0]["title"](),
          },
        ],
        markAllAsSpam: {
          button:
            lang["reconciliation"]["issues"]["suspectedSpamToken"][
              "markAllAsSpam"
            ]["button"](),
          confirm:
            lang["reconciliation"]["issues"]["suspectedSpamToken"][
              "markAllAsSpam"
            ]["confirm"](),
          description:
            lang["reconciliation"]["issues"]["suspectedSpamToken"][
              "markAllAsSpam"
            ]["description"](),
          title:
            lang["reconciliation"]["issues"]["suspectedSpamToken"][
              "markAllAsSpam"
            ]["title"](),
        },
        markAsNotSpam:
          lang["reconciliation"]["issues"]["suspectedSpamToken"][
            "markAsNotSpam"
          ](),
        markAsSpam:
          lang["reconciliation"]["issues"]["suspectedSpamToken"][
            "markAsSpam"
          ](),
        markSelectedAsSpam:
          lang["reconciliation"]["issues"]["suspectedSpamToken"][
            "markSelectedAsSpam"
          ](),
        navButton:
          lang["reconciliation"]["issues"]["suspectedSpamToken"]["navButton"](),
        notSpam:
          lang["reconciliation"]["issues"]["suspectedSpamToken"]["notSpam"](),
        spam: lang["reconciliation"]["issues"]["suspectedSpamToken"]["spam"](),
        steps: [
          lang["reconciliation"]["issues"]["suspectedSpamToken"]["steps"][0](),
          lang["reconciliation"]["issues"]["suspectedSpamToken"]["steps"][1](),
          lang["reconciliation"]["issues"]["suspectedSpamToken"]["steps"][2](),
        ],
        title:
          lang["reconciliation"]["issues"]["suspectedSpamToken"]["title"](),
        transactionSource:
          lang["reconciliation"]["issues"]["suspectedSpamToken"][
            "transactionSource"
          ](),
        transactions:
          lang["reconciliation"]["issues"]["suspectedSpamToken"][
            "transactions"
          ](),
      },
      uncategorisedIntraExchangeTransactions: {
        associatedTransactions:
          lang["reconciliation"]["issues"][
            "uncategorisedIntraExchangeTransactions"
          ]["associatedTransactions"](),
        ctaButton: {
          label:
            lang["reconciliation"]["issues"][
              "uncategorisedIntraExchangeTransactions"
            ]["ctaButton"]["label"](),
        },
        description:
          lang["reconciliation"]["issues"][
            "uncategorisedIntraExchangeTransactions"
          ]["description"](),
        missingSource:
          lang["reconciliation"]["issues"][
            "uncategorisedIntraExchangeTransactions"
          ]["missingSource"](),
        navButton:
          lang["reconciliation"]["issues"][
            "uncategorisedIntraExchangeTransactions"
          ]["navButton"](),
        tableHeader: {
          assets:
            lang["reconciliation"]["issues"][
              "uncategorisedIntraExchangeTransactions"
            ]["tableHeader"]["assets"](),
          associatedGainsLaptop:
            lang["reconciliation"]["issues"][
              "uncategorisedIntraExchangeTransactions"
            ]["tableHeader"]["associatedGainsLaptop"](),
          source:
            lang["reconciliation"]["issues"][
              "uncategorisedIntraExchangeTransactions"
            ]["tableHeader"]["source"](),
          transactions:
            lang["reconciliation"]["issues"][
              "uncategorisedIntraExchangeTransactions"
            ]["tableHeader"]["transactions"](),
          transactionsLaptop:
            lang["reconciliation"]["issues"][
              "uncategorisedIntraExchangeTransactions"
            ]["tableHeader"]["transactionsLaptop"](),
          transferAmount:
            lang["reconciliation"]["issues"][
              "uncategorisedIntraExchangeTransactions"
            ]["tableHeader"]["transferAmount"](),
          transferAmountLaptop:
            lang["reconciliation"]["issues"][
              "uncategorisedIntraExchangeTransactions"
            ]["tableHeader"]["transferAmountLaptop"](),
        },
        title:
          lang["reconciliation"]["issues"][
            "uncategorisedIntraExchangeTransactions"
          ]["title"](),
        transferBalance:
          lang["reconciliation"]["issues"][
            "uncategorisedIntraExchangeTransactions"
          ]["transferBalance"](),
        transferBalanceTooltip:
          lang["reconciliation"]["issues"][
            "uncategorisedIntraExchangeTransactions"
          ]["transferBalanceTooltip"](),
        viewTransactions:
          lang["reconciliation"]["issues"][
            "uncategorisedIntraExchangeTransactions"
          ]["viewTransactions"](),
      },
      uncategorisedTransactions: {
        button:
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "button"
          ](),
        chipTitle:
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "chipTitle"
          ](),
        description:
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "description"
          ](),
        drawer: {
          cta: lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "drawer"
          ]["cta"](),
          headerOne:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "drawer"
            ]["headerOne"](),
          headerTwo:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "drawer"
            ]["headerTwo"](),
          helpLinks: [
            {
              href: lang["reconciliation"]["issues"][
                "uncategorisedTransactions"
              ]["drawer"]["helpLinks"][0]["href"](),
              title:
                lang["reconciliation"]["issues"]["uncategorisedTransactions"][
                  "drawer"
                ]["helpLinks"][0]["title"](),
            },
          ],
          linkSectionHeader:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "drawer"
            ]["linkSectionHeader"](),
          paragraphOne:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "drawer"
            ]["paragraphOne"](),
          paragraphTwo:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "drawer"
            ]["paragraphTwo"](),
        },
        inContext: {
          categorised:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "inContext"
            ]["categorised"](),
          finish:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "inContext"
            ]["finish"](),
          goToNext:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "inContext"
            ]["goToNext"](),
          nextTransaction:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "inContext"
            ]["nextTransaction"](),
          notSure:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "inContext"
            ]["notSure"](),
          previousTransaction:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "inContext"
            ]["previousTransaction"](),
        },
        links: [
          {
            href: lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "links"
            ][0]["href"](),
            title:
              lang["reconciliation"]["issues"]["uncategorisedTransactions"][
                "links"
              ][0]["title"](),
          },
        ],
        navButton:
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "navButton"
          ](),
        similarChip: (args: { count: Arg }): string =>
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "similarChip"
          ](args) as string,
        steps: [
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "steps"
          ][0](),
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "steps"
          ][1](),
        ],
        title:
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "title"
          ](),
        transactionSource:
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "transactionSource"
          ](),
        type: lang["reconciliation"]["issues"]["uncategorisedTransactions"][
          "type"
        ](),
        uncategorisedTransactions:
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "uncategorisedTransactions"
          ](),
        viewSwitcher: {
          inContext:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "viewSwitcher"
            ]["inContext"](),
          onlyUncategorised:
            lang["reconciliation"]["issues"]["uncategorisedTransactions"][
              "viewSwitcher"
            ]["onlyUncategorised"](),
        },
        viewTransactions:
          lang["reconciliation"]["issues"]["uncategorisedTransactions"][
            "viewTransactions"
          ](),
      },
      uncategorisedWalletInteractions: {
        associatedTransactions:
          lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
            "associatedTransactions"
          ](),
        categorise:
          lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
            "categorise"
          ](),
        description:
          lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
            "description"
          ](),
        navButton:
          lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
            "navButton"
          ](),
        steps: [
          lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
            "steps"
          ][0](),
          lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
            "steps"
          ][1](),
        ],
        tableHeader: {
          assets:
            lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
              "tableHeader"
            ]["assets"](),
          transactions:
            lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
              "tableHeader"
            ]["transactions"](),
          transactionsLaptop:
            lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
              "tableHeader"
            ]["transactionsLaptop"](),
          transferAmount:
            lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
              "tableHeader"
            ]["transferAmount"](),
          transferAmountLaptop:
            lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
              "tableHeader"
            ]["transferAmountLaptop"](),
          wallets:
            lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
              "tableHeader"
            ]["wallets"](),
        },
        title:
          lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
            "title"
          ](),
        transferBalance:
          lang["reconciliation"]["issues"]["uncategorisedWalletInteractions"][
            "transferBalance"
          ](),
      },
      unknownParty: {
        ctaButton: {
          label:
            lang["reconciliation"]["issues"]["unknownParty"]["ctaButton"][
              "label"
            ](),
        },
        description:
          lang["reconciliation"]["issues"]["unknownParty"]["description"](),
        modal: {
          actionButton:
            lang["reconciliation"]["issues"]["unknownParty"]["modal"][
              "actionButton"
            ](),
          actionButtonMobile:
            lang["reconciliation"]["issues"]["unknownParty"]["modal"][
              "actionButtonMobile"
            ](),
          cancelButton:
            lang["reconciliation"]["issues"]["unknownParty"]["modal"][
              "cancelButton"
            ](),
          description: (args: { direction: Arg }): string =>
            lang["reconciliation"]["issues"]["unknownParty"]["modal"][
              "description"
            ](args) as string,
          destinationDirection:
            lang["reconciliation"]["issues"]["unknownParty"]["modal"][
              "destinationDirection"
            ](),
          exchangePlaceholder:
            lang["reconciliation"]["issues"]["unknownParty"]["modal"][
              "exchangePlaceholder"
            ](),
          sourceDirection:
            lang["reconciliation"]["issues"]["unknownParty"]["modal"][
              "sourceDirection"
            ](),
          title:
            lang["reconciliation"]["issues"]["unknownParty"]["modal"][
              "title"
            ](),
        },
        navButton:
          lang["reconciliation"]["issues"]["unknownParty"]["navButton"](),
        steps: [
          lang["reconciliation"]["issues"]["unknownParty"]["steps"][0](),
          lang["reconciliation"]["issues"]["unknownParty"]["steps"][1](),
        ],
        tableHeader: {
          Destination:
            lang["reconciliation"]["issues"]["unknownParty"]["tableHeader"][
              "Destination"
            ](),
          assets:
            lang["reconciliation"]["issues"]["unknownParty"]["tableHeader"][
              "assets"
            ](),
          source:
            lang["reconciliation"]["issues"]["unknownParty"]["tableHeader"][
              "source"
            ](),
          transactions:
            lang["reconciliation"]["issues"]["unknownParty"]["tableHeader"][
              "transactions"
            ](),
          transactionsMobile:
            lang["reconciliation"]["issues"]["unknownParty"]["tableHeader"][
              "transactionsMobile"
            ](),
          transferAmount:
            lang["reconciliation"]["issues"]["unknownParty"]["tableHeader"][
              "transferAmount"
            ](),
          transferAmountMobile:
            lang["reconciliation"]["issues"]["unknownParty"]["tableHeader"][
              "transferAmountMobile"
            ](),
        },
        title: lang["reconciliation"]["issues"]["unknownParty"]["title"](),
      },
      unmatchedTransfer: {
        button:
          lang["reconciliation"]["issues"]["unmatchedTransfer"]["button"](),
        chipTitle:
          lang["reconciliation"]["issues"]["unmatchedTransfer"]["chipTitle"](),
        ctaButton: {
          import:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["ctaButton"][
              "import"
            ](),
          recategorise:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["ctaButton"][
              "recategorise"
            ](),
        },
        description:
          lang["reconciliation"]["issues"]["unmatchedTransfer"][
            "description"
          ](),
        drawer: {
          cta: lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
            "cta"
          ](),
          headerFive:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "headerFive"
            ](),
          headerFour:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "headerFour"
            ](),
          headerOne:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "headerOne"
            ](),
          headerSix:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "headerSix"
            ](),
          headerThree:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "headerThree"
            ](),
          headerTwo:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "headerTwo"
            ](),
          listOneItems: [
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "listOneItems"
            ][0](),
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "listOneItems"
            ][1](),
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "listOneItems"
            ][2](),
          ],
          listThreeItems: [
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "listThreeItems"
            ][0](),
          ],
          listTwoItems: [
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "listTwoItems"
            ][0](),
          ],
          paragraphOne:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "paragraphOne"
            ](),
          paragraphThree:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "paragraphThree"
            ](),
          paragraphTwo:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "paragraphTwo"
            ](),
          subListOneItems: [
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "subListOneItems"
            ][0](),
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["drawer"][
              "subListOneItems"
            ][1](),
          ],
        },
        headers: {
          amount:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["headers"][
              "amount"
            ](),
          assets:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["headers"][
              "assets"
            ](),
          source:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["headers"][
              "source"
            ](),
          transactions:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["headers"][
              "transactions"
            ](),
          transferAmount:
            lang["reconciliation"]["issues"]["unmatchedTransfer"]["headers"][
              "transferAmount"
            ](),
          txs: lang["reconciliation"]["issues"]["unmatchedTransfer"]["headers"][
            "txs"
          ](),
        },
        navButton:
          lang["reconciliation"]["issues"]["unmatchedTransfer"]["navButton"](),
        steps: [
          lang["reconciliation"]["issues"]["unmatchedTransfer"]["steps"][0](),
          lang["reconciliation"]["issues"]["unmatchedTransfer"]["steps"][1](),
        ],
        title: lang["reconciliation"]["issues"]["unmatchedTransfer"]["title"](),
      },
    },
    loadMore: lang["reconciliation"]["loadMore"](),
    noRemainingIssues: {
      allCleared: (args: { issueTitle: Arg }): string =>
        lang["reconciliation"]["noRemainingIssues"]["allCleared"](
          args,
        ) as string,
    },
    noTransactionsState: {
      importCTA: lang["reconciliation"]["noTransactionsState"]["importCTA"](),
      instructions:
        lang["reconciliation"]["noTransactionsState"]["instructions"](),
      instructionsSubtext:
        lang["reconciliation"]["noTransactionsState"]["instructionsSubtext"](),
    },
    quantity: (args: { amount: Arg }): string =>
      lang["reconciliation"]["quantity"](args) as string,
    reconcileCTA: {
      itemsReconciled:
        lang["reconciliation"]["reconcileCTA"]["itemsReconciled"](),
      progressBanner: {
        body: lang["reconciliation"]["reconcileCTA"]["progressBanner"][
          "body"
        ](),
        completeText:
          lang["reconciliation"]["reconcileCTA"]["progressBanner"][
            "completeText"
          ](),
        title:
          lang["reconciliation"]["reconcileCTA"]["progressBanner"]["title"](),
      },
      reconcileItems: (args: { issueCount: Arg }): string =>
        lang["reconciliation"]["reconcileCTA"]["reconcileItems"](
          args,
        ) as string,
      suggestionBanner: {
        body: (args: { suggestionNum: Arg }): string =>
          lang["reconciliation"]["reconcileCTA"]["suggestionBanner"]["body"](
            args,
          ) as string,
        cta: lang["reconciliation"]["reconcileCTA"]["suggestionBanner"][
          "cta"
        ](),
        suggestionChip: (args: { suggestionNum: Arg }): string =>
          lang["reconciliation"]["reconcileCTA"]["suggestionBanner"][
            "suggestionChip"
          ](args) as string,
      },
    },
    resolvedChip: {
      resolved: lang["reconciliation"]["resolvedChip"]["resolved"](),
    },
    sidebar: {
      knowledgeBase: lang["reconciliation"]["sidebar"]["knowledgeBase"](),
      steps: {
        categorise: lang["reconciliation"]["sidebar"]["steps"]["categorise"](),
        correct: lang["reconciliation"]["sidebar"]["steps"]["correct"](),
        import: lang["reconciliation"]["sidebar"]["steps"]["import"](),
        prepare: lang["reconciliation"]["sidebar"]["steps"]["prepare"](),
        resolve: lang["reconciliation"]["sidebar"]["steps"]["resolve"](),
      },
      suggestions: (args: { count: Arg }): string =>
        lang["reconciliation"]["sidebar"]["suggestions"](args) as string,
      tip: {
        message: lang["reconciliation"]["sidebar"]["tip"]["message"](),
        title: lang["reconciliation"]["sidebar"]["tip"]["title"](),
      },
    },
    tipsInfoModal: {
      items: [
        lang["reconciliation"]["tipsInfoModal"]["items"][0](),
        lang["reconciliation"]["tipsInfoModal"]["items"][1](),
        lang["reconciliation"]["tipsInfoModal"]["items"][2](),
      ],
      subtitle: lang["reconciliation"]["tipsInfoModal"]["subtitle"](),
      subtitleReview:
        lang["reconciliation"]["tipsInfoModal"]["subtitleReview"](),
      title: (args: { amount: Arg }): string =>
        lang["reconciliation"]["tipsInfoModal"]["title"](args) as string,
    },
    title: lang["reconciliation"]["title"](),
    tradeSuggestion: {
      currency: lang["reconciliation"]["tradeSuggestion"]["currency"](),
      headers: {
        amount:
          lang["reconciliation"]["tradeSuggestion"]["headers"]["amount"](),
        asset: lang["reconciliation"]["tradeSuggestion"]["headers"]["asset"](),
        price: lang["reconciliation"]["tradeSuggestion"]["headers"]["price"](),
        transactions:
          lang["reconciliation"]["tradeSuggestion"]["headers"][
            "transactions"
          ](),
        txs: lang["reconciliation"]["tradeSuggestion"]["headers"]["txs"](),
        value: lang["reconciliation"]["tradeSuggestion"]["headers"]["value"](),
      },
      transactionSource:
        lang["reconciliation"]["tradeSuggestion"]["transactionSource"](),
      transactions: lang["reconciliation"]["tradeSuggestion"]["transactions"](),
    },
    unignoreIssue: lang["reconciliation"]["unignoreIssue"](),
    viewTransactions: lang["reconciliation"]["viewTransactions"](),
  },
  referAFriend: {
    copyCTA: lang["referAFriend"]["copyCTA"](),
    disclaimer: (args: { reward: Arg }): string =>
      lang["referAFriend"]["disclaimer"](args) as string,
    helpCenterLink: lang["referAFriend"]["helpCenterLink"](),
    messageUs: (args: { linkTextKey: Arg }): string =>
      lang["referAFriend"]["messageUs"](args) as string,
    moreDetails: (args: { linkTextKey: Arg }): string =>
      lang["referAFriend"]["moreDetails"](args) as string,
    paypalSection: {
      available: lang["referAFriend"]["paypalSection"]["available"](),
      availableTooltip:
        lang["referAFriend"]["paypalSection"]["availableTooltip"](),
      claimCTA: lang["referAFriend"]["paypalSection"]["claimCTA"](),
      claimCTATooltip:
        lang["referAFriend"]["paypalSection"]["claimCTATooltip"](),
      connectCTA: lang["referAFriend"]["paypalSection"]["connectCTA"](),
      connectedSubtext:
        lang["referAFriend"]["paypalSection"]["connectedSubtext"](),
      error: lang["referAFriend"]["paypalSection"]["error"](),
      lifetimeEarnings:
        lang["referAFriend"]["paypalSection"]["lifetimeEarnings"](),
      lifetimeEarningsTooltip:
        lang["referAFriend"]["paypalSection"]["lifetimeEarningsTooltip"](),
      modal: {
        connectCTA:
          lang["referAFriend"]["paypalSection"]["modal"]["connectCTA"](),
        connectPlaceholder:
          lang["referAFriend"]["paypalSection"]["modal"][
            "connectPlaceholder"
          ](),
        connectSubtext:
          lang["referAFriend"]["paypalSection"]["modal"]["connectSubtext"](),
        connectTitle:
          lang["referAFriend"]["paypalSection"]["modal"]["connectTitle"](),
        disconnectCTA:
          lang["referAFriend"]["paypalSection"]["modal"]["disconnectCTA"](),
        updateCTA:
          lang["referAFriend"]["paypalSection"]["modal"]["updateCTA"](),
        updatePlaceholder:
          lang["referAFriend"]["paypalSection"]["modal"]["updatePlaceholder"](),
        updateSubtext: (args: { username: Arg }): string =>
          lang["referAFriend"]["paypalSection"]["modal"]["updateSubtext"](
            args,
          ) as string,
        updateTitle:
          lang["referAFriend"]["paypalSection"]["modal"]["updateTitle"](),
      },
      plansPurchased: lang["referAFriend"]["paypalSection"]["plansPurchased"](),
      plansPurchasedTooltip:
        lang["referAFriend"]["paypalSection"]["plansPurchasedTooltip"](),
      subtext: lang["referAFriend"]["paypalSection"]["subtext"](),
      title: lang["referAFriend"]["paypalSection"]["title"](),
      updateCTA: lang["referAFriend"]["paypalSection"]["updateCTA"](),
    },
    socialLinks: {
      email: (args: { link: Arg }): string =>
        lang["referAFriend"]["socialLinks"]["email"](args) as string,
      emailSubject: lang["referAFriend"]["socialLinks"]["emailSubject"](),
      telegram: (args: { link: Arg }): string =>
        lang["referAFriend"]["socialLinks"]["telegram"](args) as string,
      x: (args: { link: Arg }): string =>
        lang["referAFriend"]["socialLinks"]["x"](args) as string,
    },
    subtext: (args: { reward: Arg }): string =>
      lang["referAFriend"]["subtext"](args) as string,
    title: (args: { reward: Arg }): string =>
      lang["referAFriend"]["title"](args) as string,
    workWithUs: lang["referAFriend"]["workWithUs"](),
  },
  referrals: {
    description: lang["referrals"]["description"](),
    linkText: lang["referrals"]["linkText"](),
    subtext: lang["referrals"]["subtext"](),
    title: lang["referrals"]["title"](),
  },
  report: {
    ACB: lang["report"]["ACB"](),
    AVCO: lang["report"]["AVCO"](),
    FIFO: lang["report"]["FIFO"](),
    HIFO: lang["report"]["HIFO"](),
    HMRC: lang["report"]["HMRC"](),
    IRS: {
      done: lang["report"]["IRS"]["done"](),
      downloadIrs8949Long: lang["report"]["IRS"]["downloadIrs8949Long"](),
      downloadIrs8949LongConsolidated:
        lang["report"]["IRS"]["downloadIrs8949LongConsolidated"](),
      downloadIrs8949Short: lang["report"]["IRS"]["downloadIrs8949Short"](),
      downloadIrs8949ShortConsolidated:
        lang["report"]["IRS"]["downloadIrs8949ShortConsolidated"](),
      downloadIrsForm: lang["report"]["IRS"]["downloadIrsForm"](),
      downloadIrsScheduleDForm:
        lang["report"]["IRS"]["downloadIrsScheduleDForm"](),
      provideIrsDetails: lang["report"]["IRS"]["provideIrsDetails"](),
      stepComplete: lang["report"]["IRS"]["stepComplete"](),
    },
    LIFO: lang["report"]["LIFO"](),
    LTFO: lang["report"]["LTFO"](),
    PFU: lang["report"]["PFU"](),
    acknowledged: lang["report"]["acknowledged"](),
    airdropIncome: lang["report"]["airdropIncome"](),
    allTradeSelector: lang["report"]["allTradeSelector"](),
    amountRemaining: lang["report"]["amountRemaining"](),
    amountYouCouldSave: lang["report"]["amountYouCouldSave"](),
    approvals: lang["report"]["approvals"](),
    assetSummary: {
      amountRemainingEndFy:
        lang["report"]["assetSummary"]["amountRemainingEndFy"](),
      amountRemainingEndFyTooltip:
        lang["report"]["assetSummary"]["amountRemainingEndFyTooltip"](),
      amountRemainingStartFy:
        lang["report"]["assetSummary"]["amountRemainingStartFy"](),
      capitalGain: lang["report"]["assetSummary"]["capitalGain"](),
      cost: lang["report"]["assetSummary"]["cost"](),
      currency: lang["report"]["assetSummary"]["currency"](),
      fee: lang["report"]["assetSummary"]["fee"](),
      income: lang["report"]["assetSummary"]["income"](),
      longTerm: lang["report"]["assetSummary"]["longTerm"](),
      noDisposals: lang["report"]["assetSummary"]["noDisposals"](),
      overallGain: lang["report"]["assetSummary"]["overallGain"](),
      proceeds: lang["report"]["assetSummary"]["proceeds"](),
      quantity: lang["report"]["assetSummary"]["quantity"](),
      quantityTooltip: lang["report"]["assetSummary"]["quantityTooltip"](),
      shortTerm: lang["report"]["assetSummary"]["shortTerm"](),
    },
    ausFinancialYear: (args: { prevYear: Arg; year: Arg }): string =>
      lang["report"]["ausFinancialYear"](args) as string,
    buyTime: lang["report"]["buyTime"](),
    capitalGainSelector: {
      all: lang["report"]["capitalGainSelector"]["all"](),
      gains: lang["report"]["capitalGainSelector"]["gains"](),
      longTermCapitalGains:
        lang["report"]["capitalGainSelector"]["longTermCapitalGains"](),
      longTermCapitalLosses:
        lang["report"]["capitalGainSelector"]["longTermCapitalLosses"](),
      shortTermCapitalGains:
        lang["report"]["capitalGainSelector"]["shortTermCapitalGains"](),
      shortTermCapitalLosses:
        lang["report"]["capitalGainSelector"]["shortTermCapitalLosses"](),
      totalLosses: lang["report"]["capitalGainSelector"]["totalLosses"](),
    },
    checkWithAgent: lang["report"]["checkWithAgent"](),
    closed: lang["report"]["closed"](),
    confirmInventoryMethod: lang["report"]["confirmInventoryMethod"](),
    confirmationText: (args: { method: Arg }): string =>
      lang["report"]["confirmationText"](args) as string,
    cost: lang["report"]["cost"](),
    costBasisRedistributionMethod: {
      "largest-wallet-highest-cost":
        lang["report"]["costBasisRedistributionMethod"][
          "largest-wallet-highest-cost"
        ](),
      "largest-wallet-lowest-cost":
        lang["report"]["costBasisRedistributionMethod"][
          "largest-wallet-lowest-cost"
        ](),
      "least-adjustment":
        lang["report"]["costBasisRedistributionMethod"]["least-adjustment"](),
      title: lang["report"]["costBasisRedistributionMethod"]["title"](),
      tooltip: lang["report"]["costBasisRedistributionMethod"]["tooltip"](),
    },
    csv: lang["report"]["csv"](),
    currency: lang["report"]["currency"](),
    customDateRange: lang["report"]["customDateRange"](),
    customDates: lang["report"]["customDates"](),
    defaultPaywallBannerText: lang["report"]["defaultPaywallBannerText"](),
    derivativeTradingIncome: lang["report"]["derivativeTradingIncome"](),
    disclaimer: (args: { isRegularUser: Arg }): string =>
      lang["report"]["disclaimer"](args) as string,
    disclaimerTitle: lang["report"]["disclaimerTitle"](),
    download: {
      bglAccountIDModal: {
        buttonText:
          lang["report"]["download"]["bglAccountIDModal"]["buttonText"](),
        copyId: lang["report"]["download"]["bglAccountIDModal"]["copyId"](),
        subtitle: lang["report"]["download"]["bglAccountIDModal"]["subtitle"](),
        title: lang["report"]["download"]["bglAccountIDModal"]["title"](),
      },
      buttonIrs: lang["report"]["download"]["buttonIrs"](),
      downloading: lang["report"]["download"]["downloading"](),
      exportCSV: lang["report"]["download"]["exportCSV"](),
      exportIrs1040s1Pdf: lang["report"]["download"]["exportIrs1040s1Pdf"](),
      exportIrs8949Pdf: lang["report"]["download"]["exportIrs8949Pdf"](),
      exportPDF: lang["report"]["download"]["exportPDF"](),
      exportTurboTaxOnline:
        lang["report"]["download"]["exportTurboTaxOnline"](),
      exportTurboTaxTXF: lang["report"]["download"]["exportTurboTaxTXF"](),
      irsDownload: {
        customTitle: lang["report"]["download"]["irsDownload"]["customTitle"](),
        download: lang["report"]["download"]["irsDownload"]["download"](),
        name: lang["report"]["download"]["irsDownload"]["name"](),
        skip: (args: { termLength: Arg }): string =>
          lang["report"]["download"]["irsDownload"]["skip"](args) as string,
        social: lang["report"]["download"]["irsDownload"]["social"](),
        whyUserData: (args: { formName: Arg }): string =>
          lang["report"]["download"]["irsDownload"]["whyUserData"](
            args,
          ) as string,
        whyUserDataCustom:
          lang["report"]["download"]["irsDownload"]["whyUserDataCustom"](),
      },
      loading: lang["report"]["download"]["loading"](),
      sample: lang["report"]["download"]["sample"](),
      tabCryptoTax: lang["report"]["download"]["tabCryptoTax"](),
      tabGermanReports: lang["report"]["download"]["tabGermanReports"](),
      tabIrs: lang["report"]["download"]["tabIrs"](),
      tabTaxIntegrations: lang["report"]["download"]["tabTaxIntegrations"](),
      title: lang["report"]["download"]["title"](),
      tradingStockReportDownload: {
        buttons: {
          cancel:
            lang["report"]["download"]["tradingStockReportDownload"]["buttons"][
              "cancel"
            ](),
          downloadReport:
            lang["report"]["download"]["tradingStockReportDownload"]["buttons"][
              "downloadReport"
            ](),
        },
        costBasisMethod: {
          description:
            lang["report"]["download"]["tradingStockReportDownload"][
              "costBasisMethod"
            ]["description"](),
          title:
            lang["report"]["download"]["tradingStockReportDownload"][
              "costBasisMethod"
            ]["title"](),
        },
        description:
          lang["report"]["download"]["tradingStockReportDownload"][
            "description"
          ](),
        fallbackMethod: {
          description:
            lang["report"]["download"]["tradingStockReportDownload"][
              "fallbackMethod"
            ]["description"](),
          title:
            lang["report"]["download"]["tradingStockReportDownload"][
              "fallbackMethod"
            ]["title"](),
        },
        marketValueMethod: {
          description:
            lang["report"]["download"]["tradingStockReportDownload"][
              "marketValueMethod"
            ]["description"](),
          title:
            lang["report"]["download"]["tradingStockReportDownload"][
              "marketValueMethod"
            ]["title"](),
        },
        table: {
          defaultValuationMethod:
            lang["report"]["download"]["tradingStockReportDownload"]["table"][
              "defaultValuationMethod"
            ](),
          tooltip:
            lang["report"]["download"]["tradingStockReportDownload"]["table"][
              "tooltip"
            ](),
        },
        title:
          lang["report"]["download"]["tradingStockReportDownload"]["title"](),
      },
      turbotaxModal: {
        buttonText: lang["report"]["download"]["turbotaxModal"]["buttonText"](),
        helpLinkText:
          lang["report"]["download"]["turbotaxModal"]["helpLinkText"](),
        subtitle: lang["report"]["download"]["turbotaxModal"]["subtitle"](),
        title: lang["report"]["download"]["turbotaxModal"]["title"](),
      },
    },
    downloadReports: {
      downloadReportAnyway:
        lang["report"]["downloadReports"]["downloadReportAnyway"](),
      primaryButton: lang["report"]["downloadReports"]["primaryButton"](),
      quickDownload: lang["report"]["downloadReports"]["quickDownload"](),
      title: lang["report"]["downloadReports"]["title"](),
    },
    emailReportsModal: {
      always: lang["report"]["emailReportsModal"]["always"](),
      body: lang["report"]["emailReportsModal"]["body"](),
      dontShowAgain: lang["report"]["emailReportsModal"]["dontShowAgain"](),
      emailMe: lang["report"]["emailReportsModal"]["emailMe"](),
      illWait: lang["report"]["emailReportsModal"]["illWait"](),
      title: lang["report"]["emailReportsModal"]["title"](),
    },
    errorTitle: lang["report"]["errorTitle"](),
    estimatedGainBanner: {
      title: lang["report"]["estimatedGainBanner"]["title"](),
      tooltip: lang["report"]["estimatedGainBanner"]["tooltip"](),
    },
    expense: lang["report"]["expense"](),
    expenses: lang["report"]["expenses"](),
    fee: lang["report"]["fee"](),
    fees: lang["report"]["fees"](),
    financialYear: lang["report"]["financialYear"](),
    form3916ExchangeInfo: {
      address: lang["report"]["form3916ExchangeInfo"]["address"](),
      company: lang["report"]["form3916ExchangeInfo"]["company"](),
      country: lang["report"]["form3916ExchangeInfo"]["country"](),
      title: lang["report"]["form3916ExchangeInfo"]["title"](),
      website: lang["report"]["form3916ExchangeInfo"]["website"](),
    },
    fyChip: (args: { timeframe: Arg }): string =>
      lang["report"]["fyChip"](args) as string,
    gain: lang["report"]["gain"](),
    gainNoCGT: lang["report"]["gainNoCGT"](),
    grid: {
      softwareConnections: lang["report"]["grid"]["softwareConnections"](),
      taxReports: lang["report"]["grid"]["taxReports"](),
      txTypes: lang["report"]["grid"]["txTypes"](),
    },
    headings: {
      derivativeTradingIncome:
        lang["report"]["headings"]["derivativeTradingIncome"](),
      expenses: lang["report"]["headings"]["expenses"](),
      gains: lang["report"]["headings"]["gains"](),
      longTermCapitalGains:
        lang["report"]["headings"]["longTermCapitalGains"](),
      longTermCapitalLosses:
        lang["report"]["headings"]["longTermCapitalLosses"](),
      nonTaxableTransactions:
        lang["report"]["headings"]["nonTaxableTransactions"](),
      ordinaryIncome: lang["report"]["headings"]["ordinaryIncome"](),
      shortTermCapitalGains:
        lang["report"]["headings"]["shortTermCapitalGains"](),
      shortTermCapitalLosses:
        lang["report"]["headings"]["shortTermCapitalLosses"](),
      totalCapitalGains: lang["report"]["headings"]["totalCapitalGains"](),
      totalExpenses: lang["report"]["headings"]["totalExpenses"](),
      totalIncome: lang["report"]["headings"]["totalIncome"](),
      totalLosses: lang["report"]["headings"]["totalLosses"](),
    },
    income: lang["report"]["income"](),
    incurredTime: lang["report"]["incurredTime"](),
    interestIncome: lang["report"]["interestIncome"](),
    inventory: {
      endBalance: lang["report"]["inventory"]["endBalance"](),
      endCostBasis: lang["report"]["inventory"]["endCostBasis"](),
      endValue: lang["report"]["inventory"]["endValue"](),
      startBalance: lang["report"]["inventory"]["startBalance"](),
      startCostBasis: lang["report"]["inventory"]["startCostBasis"](),
      startValue: lang["report"]["inventory"]["startValue"](),
    },
    inventoryMethod: lang["report"]["inventoryMethod"](),
    loanRepayments: lang["report"]["loanRepayments"](),
    lockedPeriod: {
      all: lang["report"]["lockedPeriod"]["all"](),
      costBasisTracking: lang["report"]["lockedPeriod"]["costBasisTracking"](),
      multipleInventoryMethods:
        lang["report"]["lockedPeriod"]["multipleInventoryMethods"](),
      none: lang["report"]["lockedPeriod"]["none"](),
      some: lang["report"]["lockedPeriod"]["some"](),
      tooltip: lang["report"]["lockedPeriod"]["tooltip"](),
    },
    longShortIncome: lang["report"]["longShortIncome"](),
    longTermGains: lang["report"]["longTermGains"](),
    longTermGainsNoCGT: lang["report"]["longTermGainsNoCGT"](),
    lookTooltip: (args: { linkTextKey: Arg }): string =>
      lang["report"]["lookTooltip"](args) as string,
    miningRewards: lang["report"]["miningRewards"](),
    miscellaneousExpenses: lang["report"]["miscellaneousExpenses"](),
    missingPurchase: {
      text: lang["report"]["missingPurchase"]["text"](),
      tooltip: (args: { linkTextKey: Arg }): string =>
        lang["report"]["missingPurchase"]["tooltip"](args) as string,
    },
    modifyTaxSettings: lang["report"]["modifyTaxSettings"](),
    nav: {
      reportSummary: lang["report"]["nav"]["reportSummary"](),
      taxReports: lang["report"]["nav"]["taxReports"](),
    },
    noFrenchReportDataModal: {
      body1: (args: { year: Arg }): string =>
        lang["report"]["noFrenchReportDataModal"]["body1"](args) as string,
      body2: (args: { year: Arg }): string =>
        lang["report"]["noFrenchReportDataModal"]["body2"](args) as string,
      title: (args: { year: Arg }): string =>
        lang["report"]["noFrenchReportDataModal"]["title"](args) as string,
    },
    noRecordsFound: lang["report"]["noRecordsFound"](),
    nzFinancialYear: (args: { prevYear: Arg; year: Arg }): string =>
      lang["report"]["nzFinancialYear"](args) as string,
    opened: lang["report"]["opened"](),
    ordinaryIncome: lang["report"]["ordinaryIncome"](),
    otherIncome: lang["report"]["otherIncome"](),
    otherReports: lang["report"]["otherReports"](),
    overallGain: lang["report"]["overallGain"](),
    packs: {
      customModal: {
        columns: {
          details:
            lang["report"]["packs"]["customModal"]["columns"]["details"](),
          report: lang["report"]["packs"]["customModal"]["columns"]["report"](),
        },
        csv: lang["report"]["packs"]["customModal"]["csv"](),
        downloadSelected: (args: { isFree: Arg; selectedCount: Arg }): string =>
          lang["report"]["packs"]["customModal"]["downloadSelected"](
            args,
          ) as string,
        getSample: lang["report"]["packs"]["customModal"]["getSample"](),
        pdf: lang["report"]["packs"]["customModal"]["pdf"](),
        selectPlanBanner: {
          body: lang["report"]["packs"]["customModal"]["selectPlanBanner"][
            "body"
          ](),
          cta: lang["report"]["packs"]["customModal"]["selectPlanBanner"][
            "cta"
          ](),
        },
        title: lang["report"]["packs"]["customModal"]["title"](),
        txf: lang["report"]["packs"]["customModal"]["txf"](),
        upgrade: (args: { planTitle: Arg }): string =>
          lang["report"]["packs"]["customModal"]["upgrade"](args) as string,
        xml: lang["report"]["packs"]["customModal"]["xml"](),
      },
      filters: {
        accountant: lang["report"]["packs"]["filters"]["accountant"](),
        all: lang["report"]["packs"]["filters"]["all"](),
        self: lang["report"]["packs"]["filters"]["self"](),
      },
      metadata: {
        "8949": {
          description:
            lang["report"]["packs"]["metadata"]["8949"]["description"](),
          title: lang["report"]["packs"]["metadata"]["8949"]["title"](),
        },
        accountant: {
          description:
            lang["report"]["packs"]["metadata"]["accountant"]["description"](),
          title: lang["report"]["packs"]["metadata"]["accountant"]["title"](),
        },
        custom: {
          description:
            lang["report"]["packs"]["metadata"]["custom"]["description"](),
          title: lang["report"]["packs"]["metadata"]["custom"]["title"](),
        },
        form3916bis: {
          description:
            lang["report"]["packs"]["metadata"]["form3916bis"]["description"](),
          title: lang["report"]["packs"]["metadata"]["form3916bis"]["title"](),
        },
        fr2086: {
          description:
            lang["report"]["packs"]["metadata"]["fr2086"]["description"](),
          title: lang["report"]["packs"]["metadata"]["fr2086"]["title"](),
        },
        mygov: {
          description:
            lang["report"]["packs"]["metadata"]["mygov"]["description"](),
          title: lang["report"]["packs"]["metadata"]["mygov"]["title"](),
        },
        scheduleS1AndSD: {
          description:
            lang["report"]["packs"]["metadata"]["scheduleS1AndSD"][
              "description"
            ](),
          title:
            lang["report"]["packs"]["metadata"]["scheduleS1AndSD"]["title"](),
        },
        self: {
          description:
            lang["report"]["packs"]["metadata"]["self"]["description"](),
          title: lang["report"]["packs"]["metadata"]["self"]["title"](),
        },
        taxLots: {
          description:
            lang["report"]["packs"]["metadata"]["taxLots"]["description"](),
          title: lang["report"]["packs"]["metadata"]["taxLots"]["title"](),
        },
        turbotax: {
          description:
            lang["report"]["packs"]["metadata"]["turbotax"]["description"](),
          title: lang["report"]["packs"]["metadata"]["turbotax"]["title"](),
        },
      },
    },
    paywallBannerFYText: lang["report"]["paywallBannerFYText"](),
    paywallBannerText: lang["report"]["paywallBannerText"](),
    paywallBannerTextCollaborator:
      lang["report"]["paywallBannerTextCollaborator"](),
    paywallBannerTextMap: {
      accountant: {
        accountantOrCollaboratorView: {
          free: lang["report"]["paywallBannerTextMap"]["accountant"][
            "accountantOrCollaboratorView"
          ]["free"](),
          importCount:
            lang["report"]["paywallBannerTextMap"]["accountant"][
              "accountantOrCollaboratorView"
            ]["importCount"](),
          tradeType:
            lang["report"]["paywallBannerTextMap"]["accountant"][
              "accountantOrCollaboratorView"
            ]["tradeType"](),
          txCount:
            lang["report"]["paywallBannerTextMap"]["accountant"][
              "accountantOrCollaboratorView"
            ]["txCount"](),
          unpaid:
            lang["report"]["paywallBannerTextMap"]["accountant"][
              "accountantOrCollaboratorView"
            ]["unpaid"](),
        },
        clientView: {
          free: lang["report"]["paywallBannerTextMap"]["accountant"][
            "clientView"
          ]["free"](),
          importCount:
            lang["report"]["paywallBannerTextMap"]["accountant"]["clientView"][
              "importCount"
            ](),
          tradeType:
            lang["report"]["paywallBannerTextMap"]["accountant"]["clientView"][
              "tradeType"
            ](),
          txCount:
            lang["report"]["paywallBannerTextMap"]["accountant"]["clientView"][
              "txCount"
            ](),
          unpaid:
            lang["report"]["paywallBannerTextMap"]["accountant"]["clientView"][
              "unpaid"
            ](),
        },
      },
      both: {
        accountantOrCollaboratorView: {
          free: lang["report"]["paywallBannerTextMap"]["both"][
            "accountantOrCollaboratorView"
          ]["free"](),
          importCount:
            lang["report"]["paywallBannerTextMap"]["both"][
              "accountantOrCollaboratorView"
            ]["importCount"](),
          tradeType:
            lang["report"]["paywallBannerTextMap"]["both"][
              "accountantOrCollaboratorView"
            ]["tradeType"](),
          txCount:
            lang["report"]["paywallBannerTextMap"]["both"][
              "accountantOrCollaboratorView"
            ]["txCount"](),
          unpaid:
            lang["report"]["paywallBannerTextMap"]["both"][
              "accountantOrCollaboratorView"
            ]["unpaid"](),
        },
        clientView: {
          free: lang["report"]["paywallBannerTextMap"]["both"]["clientView"][
            "free"
          ](),
          importCount:
            lang["report"]["paywallBannerTextMap"]["both"]["clientView"][
              "importCount"
            ](),
          tradeType:
            lang["report"]["paywallBannerTextMap"]["both"]["clientView"][
              "tradeType"
            ](),
          txCount:
            lang["report"]["paywallBannerTextMap"]["both"]["clientView"][
              "txCount"
            ](),
          unpaid:
            lang["report"]["paywallBannerTextMap"]["both"]["clientView"][
              "unpaid"
            ](),
        },
      },
      client: {
        accountantOrCollaboratorView: {
          free: lang["report"]["paywallBannerTextMap"]["client"][
            "accountantOrCollaboratorView"
          ]["free"](),
          importCount:
            lang["report"]["paywallBannerTextMap"]["client"][
              "accountantOrCollaboratorView"
            ]["importCount"](),
          tradeType:
            lang["report"]["paywallBannerTextMap"]["client"][
              "accountantOrCollaboratorView"
            ]["tradeType"](),
          txCount:
            lang["report"]["paywallBannerTextMap"]["client"][
              "accountantOrCollaboratorView"
            ]["txCount"](),
          unpaid:
            lang["report"]["paywallBannerTextMap"]["client"][
              "accountantOrCollaboratorView"
            ]["unpaid"](),
        },
        clientView: {
          free: lang["report"]["paywallBannerTextMap"]["client"]["clientView"][
            "free"
          ](),
          importCount:
            lang["report"]["paywallBannerTextMap"]["client"]["clientView"][
              "importCount"
            ](),
          tradeType:
            lang["report"]["paywallBannerTextMap"]["client"]["clientView"][
              "tradeType"
            ](),
          txCount:
            lang["report"]["paywallBannerTextMap"]["client"]["clientView"][
              "txCount"
            ](),
          unpaid:
            lang["report"]["paywallBannerTextMap"]["client"]["clientView"][
              "unpaid"
            ](),
        },
      },
      user: {
        free: lang["report"]["paywallBannerTextMap"]["user"]["free"](),
        importCount:
          lang["report"]["paywallBannerTextMap"]["user"]["importCount"](),
        tradeType:
          lang["report"]["paywallBannerTextMap"]["user"]["tradeType"](),
        txCount: lang["report"]["paywallBannerTextMap"]["user"]["txCount"](),
        unpaid: lang["report"]["paywallBannerTextMap"]["user"]["unpaid"](),
      },
    },
    paywalledNumberTooltip: lang["report"]["paywalledNumberTooltip"](),
    pdf: lang["report"]["pdf"](),
    proceeds: lang["report"]["proceeds"](),
    quantity: lang["report"]["quantity"](),
    receivedTime: lang["report"]["receivedTime"](),
    reportDetails: lang["report"]["reportDetails"](),
    reportSubTitles: {
      "annex-so-other-income":
        lang["report"]["reportSubTitles"]["annex-so-other-income"](),
      audit: lang["report"]["reportSubTitles"]["audit"](),
      bgl: lang["report"]["reportSubTitles"]["bgl"](),
      "capital-gains": lang["report"]["reportSubTitles"]["capital-gains"](),
      "capital-gains-nz":
        lang["report"]["reportSubTitles"]["capital-gains-nz"](),
      cerfa2086: lang["report"]["reportSubTitles"]["cerfa2086"](),
      expense: lang["report"]["reportSubTitles"]["expense"](),
      form3916bis: lang["report"]["reportSubTitles"]["form3916bis"](),
      "gift-and-lost": lang["report"]["reportSubTitles"]["gift-and-lost"](),
      income: lang["report"]["reportSubTitles"]["income"](),
      inventory: lang["report"]["reportSubTitles"]["inventory"](),
      "inventory-v2": lang["report"]["reportSubTitles"]["inventory-v2"](),
      "irs-1040-s1": lang["report"]["reportSubTitles"]["irs-1040-s1"](),
      "irs-1040-sd": lang["report"]["reportSubTitles"]["irs-1040-sd"](),
      "irs-8949": lang["report"]["reportSubTitles"]["irs-8949"](),
      "irs-8949-consolidated":
        lang["report"]["reportSubTitles"]["irs-8949-consolidated"](),
      "modelo-100": lang["report"]["reportSubTitles"]["modelo-100"](),
      mygov: lang["report"]["reportSubTitles"]["mygov"](),
      "nft-report": lang["report"]["reportSubTitles"]["nft-report"](),
      "report-summary": lang["report"]["reportSubTitles"]["report-summary"](),
      "start-of-year-holdings":
        lang["report"]["reportSubTitles"]["start-of-year-holdings"](),
      "tax-lots": lang["report"]["reportSubTitles"]["tax-lots"](),
      "taxact-csv": lang["report"]["reportSubTitles"]["taxact-csv"](),
      "trading-pnl": lang["report"]["reportSubTitles"]["trading-pnl"](),
      "trading-stock": lang["report"]["reportSubTitles"]["trading-stock"](),
      transaction: lang["report"]["reportSubTitles"]["transaction"](),
      "turbotax-canadian-pdf":
        lang["report"]["reportSubTitles"]["turbotax-canadian-pdf"](),
      "turbotax-csv": lang["report"]["reportSubTitles"]["turbotax-csv"](),
      "turbotax-txf": lang["report"]["reportSubTitles"]["turbotax-txf"](),
      "wiso-steuer": lang["report"]["reportSubTitles"]["wiso-steuer"](),
    },
    reportType: {
      "annex-so-other-income":
        lang["report"]["reportType"]["annex-so-other-income"](),
      audit: lang["report"]["reportType"]["audit"](),
      bgl: lang["report"]["reportType"]["bgl"](),
      "capital-gains": lang["report"]["reportType"]["capital-gains"](),
      "capital-gains-nz": lang["report"]["reportType"]["capital-gains-nz"](),
      cerfa2086: lang["report"]["reportType"]["cerfa2086"](),
      expense: lang["report"]["reportType"]["expense"](),
      form3916bis: lang["report"]["reportType"]["form3916bis"](),
      "gift-and-lost": lang["report"]["reportType"]["gift-and-lost"](),
      income: lang["report"]["reportType"]["income"](),
      inventory: lang["report"]["reportType"]["inventory"](),
      "inventory-v2": lang["report"]["reportType"]["inventory-v2"](),
      "irs-1040-s1": lang["report"]["reportType"]["irs-1040-s1"](),
      "irs-1040-sd": lang["report"]["reportType"]["irs-1040-sd"](),
      "irs-8949": lang["report"]["reportType"]["irs-8949"](),
      "irs-8949-consolidated":
        lang["report"]["reportType"]["irs-8949-consolidated"](),
      "irs-8949-long": lang["report"]["reportType"]["irs-8949-long"](),
      "irs-8949-long-consolidated":
        lang["report"]["reportType"]["irs-8949-long-consolidated"](),
      "irs-8949-short": lang["report"]["reportType"]["irs-8949-short"](),
      "irs-8949-short-consolidated":
        lang["report"]["reportType"]["irs-8949-short-consolidated"](),
      "modelo-100": lang["report"]["reportType"]["modelo-100"](),
      mygov: lang["report"]["reportType"]["mygov"](),
      "nft-report": lang["report"]["reportType"]["nft-report"](),
      "report-summary": lang["report"]["reportType"]["report-summary"](),
      "start-of-year-holdings":
        lang["report"]["reportType"]["start-of-year-holdings"](),
      "tax-lots": lang["report"]["reportType"]["tax-lots"](),
      "taxact-csv": lang["report"]["reportType"]["taxact-csv"](),
      "trading-pnl": lang["report"]["reportType"]["trading-pnl"](),
      "trading-stock": lang["report"]["reportType"]["trading-stock"](),
      transaction: lang["report"]["reportType"]["transaction"](),
      "turbotax-canadian-pdf":
        lang["report"]["reportType"]["turbotax-canadian-pdf"](),
      "turbotax-csv": lang["report"]["reportType"]["turbotax-csv"](),
      "turbotax-txf": lang["report"]["reportType"]["turbotax-txf"](),
      "wiso-steuer": lang["report"]["reportType"]["wiso-steuer"](),
    },
    reportTypeSelector: lang["report"]["reportTypeSelector"](),
    rewardsAndInterest: lang["report"]["rewardsAndInterest"](),
    rowsPerPage: lang["report"]["rowsPerPage"](),
    royalties: lang["report"]["royalties"](),
    saFinancialYear: (args: {
      prevYear: Arg;
      febLastDay: Arg;
      year: Arg;
    }): string => lang["report"]["saFinancialYear"](args) as string,
    saveMoney: lang["report"]["saveMoney"](),
    savingsOpportunity: lang["report"]["savingsOpportunity"](),
    selectPlan: lang["report"]["selectPlan"](),
    sellTime: lang["report"]["sellTime"](),
    shortTermGains: lang["report"]["shortTermGains"](),
    shortTermGainsNoCGT: lang["report"]["shortTermGainsNoCGT"](),
    stakingIncome: lang["report"]["stakingIncome"](),
    summary: {
      reviewTips: {
        button: lang["report"]["summary"]["reviewTips"]["button"](),
        subTitle: {
          control:
            lang["report"]["summary"]["reviewTips"]["subTitle"]["control"](),
          ourTaxMinimisationAlgorithmCouldSaveYou:
            lang["report"]["summary"]["reviewTips"]["subTitle"][
              "ourTaxMinimisationAlgorithmCouldSaveYou"
            ](),
          youCouldMinimiseYourTaxLiabilityBy:
            lang["report"]["summary"]["reviewTips"]["subTitle"][
              "youCouldMinimiseYourTaxLiabilityBy"
            ](),
          youCouldSaveXyzInTaxes: {
            prefix:
              lang["report"]["summary"]["reviewTips"]["subTitle"][
                "youCouldSaveXyzInTaxes"
              ]["prefix"](),
            suffix:
              lang["report"]["summary"]["reviewTips"]["subTitle"][
                "youCouldSaveXyzInTaxes"
              ]["suffix"](),
          },
        },
        title: lang["report"]["summary"]["reviewTips"]["title"](),
        tooltip: lang["report"]["summary"]["reviewTips"]["tooltip"](),
      },
      title: lang["report"]["summary"]["title"](),
      txs: (args: { count: Arg }): string =>
        lang["report"]["summary"]["txs"](args) as string,
    },
    tablePagination: (args: { from: Arg; to: Arg; count: Arg }): string =>
      lang["report"]["tablePagination"](args) as string,
    taxReports: lang["report"]["taxReports"](),
    taxSettingWarnings: {
      uncategorisedBothAsTaxable:
        lang["report"]["taxSettingWarnings"]["uncategorisedBothAsTaxable"](),
      uncategorisedInAsTaxable:
        lang["report"]["taxSettingWarnings"]["uncategorisedInAsTaxable"](),
      uncategorisedOutAsTaxable:
        lang["report"]["taxSettingWarnings"]["uncategorisedOutAsTaxable"](),
    },
    taxSettings: lang["report"]["taxSettings"](),
    taxablePortfolioValue: lang["report"]["taxablePortfolioValue"](),
    tooltips: {
      airdrop: lang["report"]["tooltips"]["airdrop"](),
      approvals: lang["report"]["tooltips"]["approvals"](),
      capitalLosses: lang["report"]["tooltips"]["capitalLosses"](),
      capitalLossesNoCGT: lang["report"]["tooltips"]["capitalLossesNoCGT"](),
      derivativeTradingIncome:
        lang["report"]["tooltips"]["derivativeTradingIncome"](),
      expenses: lang["report"]["tooltips"]["expenses"](),
      fees: lang["report"]["tooltips"]["fees"](),
      income: lang["report"]["tooltips"]["income"](),
      loan: lang["report"]["tooltips"]["loan"](),
      longTermCapitalLosses: (args: { longTermThreshold: Arg }): string =>
        lang["report"]["tooltips"]["longTermCapitalLosses"](args) as string,
      longTermThreshold: (args: { longTermThreshold: Arg }): string =>
        lang["report"]["tooltips"]["longTermThreshold"](args) as string,
      longTermThresholdNoCGT: (args: { longTermThreshold: Arg }): string =>
        lang["report"]["tooltips"]["longTermThresholdNoCGT"](args) as string,
      mining: lang["report"]["tooltips"]["mining"](),
      miscellaneousExpenses:
        lang["report"]["tooltips"]["miscellaneousExpenses"](),
      miscellaneousIncome: lang["report"]["tooltips"]["miscellaneousIncome"](),
      noCapitalGains: lang["report"]["tooltips"]["noCapitalGains"](),
      noCapitalGainsNoCGT: lang["report"]["tooltips"]["noCapitalGainsNoCGT"](),
      noLongTermThreshold: lang["report"]["tooltips"]["noLongTermThreshold"](),
      pnl: lang["report"]["tooltips"]["pnl"](),
      royalty: lang["report"]["tooltips"]["royalty"](),
      shortTermCapitalLosses: (args: { longTermThreshold: Arg }): string =>
        lang["report"]["tooltips"]["shortTermCapitalLosses"](args) as string,
      staking: lang["report"]["tooltips"]["staking"](),
      totalCapitalGains: (args: { longTermThreshold: Arg }): string =>
        lang["report"]["tooltips"]["totalCapitalGains"](args) as string,
      totalCapitalGainsNoCGT: (args: { longTermThreshold: Arg }): string =>
        lang["report"]["tooltips"]["totalCapitalGainsNoCGT"](args) as string,
      totalGains: lang["report"]["tooltips"]["totalGains"](),
    },
    totalCapitalGains: lang["report"]["totalCapitalGains"](),
    totalCapitalGainsNoCGT: lang["report"]["totalCapitalGainsNoCGT"](),
    totalGains: lang["report"]["totalGains"](),
    totalLosses: lang["report"]["totalLosses"](),
    totalLossesNoCGT: lang["report"]["totalLossesNoCGT"](),
    tradingPnL: lang["report"]["tradingPnL"](),
    tradingStockAu: {
      acquiredStockQuantity:
        lang["report"]["tradingStockAu"]["acquiredStockQuantity"](),
      acquiredTotalValue:
        lang["report"]["tradingStockAu"]["acquiredTotalValue"](),
      currency: lang["report"]["tradingStockAu"]["currency"](),
      disposedStockQuantity:
        lang["report"]["tradingStockAu"]["disposedStockQuantity"](),
      disposedTotalValue:
        lang["report"]["tradingStockAu"]["disposedTotalValue"](),
      endingPrice: lang["report"]["tradingStockAu"]["endingPrice"](),
      endingStock: lang["report"]["tradingStockAu"]["endingStock"](),
      endingValue: lang["report"]["tradingStockAu"]["endingValue"](),
      netProfit: lang["report"]["tradingStockAu"]["netProfit"](),
      startingPrice: lang["report"]["tradingStockAu"]["startingPrice"](),
      startingStock: lang["report"]["tradingStockAu"]["startingStock"](),
      startingValue: lang["report"]["tradingStockAu"]["startingValue"](),
    },
    txf: lang["report"]["txf"](),
    ukFinancialYear: (args: { prevYear: Arg; year: Arg }): string =>
      lang["report"]["ukFinancialYear"](args) as string,
    unknownGains: lang["report"]["unknownGains"](),
    unknownGainsNoCGT: lang["report"]["unknownGainsNoCGT"](),
    upgradeToPlanTooltip: (args: { plan: Arg }): string =>
      lang["report"]["upgradeToPlanTooltip"](args) as string,
    viewAll: lang["report"]["viewAll"](),
    viewOtherReports: lang["report"]["viewOtherReports"](),
    warning: lang["report"]["warning"](),
    warningActionText: lang["report"]["warningActionText"](),
    warningTitle: lang["report"]["warningTitle"](),
    warningZeroCost: {
      bodyText1: lang["report"]["warningZeroCost"]["bodyText1"](),
      bodyText2: lang["report"]["warningZeroCost"]["bodyText2"](),
      bodyValuePostNumber:
        lang["report"]["warningZeroCost"]["bodyValuePostNumber"](),
      bodyValuePreNumber:
        lang["report"]["warningZeroCost"]["bodyValuePreNumber"](),
      breakdownPrefix: lang["report"]["warningZeroCost"]["breakdownPrefix"](),
      buttonText: lang["report"]["warningZeroCost"]["buttonText"](),
      includedInGains: lang["report"]["warningZeroCost"]["includedInGains"](),
      innerBodyText: lang["report"]["warningZeroCost"]["innerBodyText"](),
      innerTitle: lang["report"]["warningZeroCost"]["innerTitle"](),
      title: lang["report"]["warningZeroCost"]["title"](),
      titleNoCGT: lang["report"]["warningZeroCost"]["titleNoCGT"](),
    },
    warningZeroCostSummary: {
      body: (args: { total: Arg }): string =>
        lang["report"]["warningZeroCostSummary"]["body"](args) as string,
      title: lang["report"]["warningZeroCostSummary"]["title"](),
    },
    wentWrongDownloadingBGL: lang["report"]["wentWrongDownloadingBGL"](),
    wentWrongDownloadingCSV: lang["report"]["wentWrongDownloadingCSV"](),
    wentWrongDownloadingPDF: lang["report"]["wentWrongDownloadingPDF"](),
    wentWrongDownloadingTXF: lang["report"]["wentWrongDownloadingTXF"](),
    wentWrongRefreshing: lang["report"]["wentWrongRefreshing"](),
    xml: lang["report"]["xml"](),
    ytd: lang["report"]["ytd"](),
  },
  reportError: {
    button: {
      display: lang["reportError"]["button"]["display"](),
      hide: lang["reportError"]["button"]["hide"](),
    },
    soldOut: lang["reportError"]["soldOut"](),
    title: lang["reportError"]["title"](),
    tooltip: {
      soldOut: lang["reportError"]["tooltip"]["soldOut"](),
      uncategorized: lang["reportError"]["tooltip"]["uncategorized"](),
    },
    uncategorized: lang["reportError"]["uncategorized"](),
  },
  reset: lang["reset"](),
  retry: lang["retry"](),
  rules: {
    addRule: lang["rules"]["addRule"](),
    categoryRuleWarning: lang["rules"]["categoryRuleWarning"](),
    createRule: lang["rules"]["createRule"](),
    deleteDialog: {
      text: {
        checkbox: lang["rules"]["deleteDialog"]["text"]["checkbox"](),
        lineOne: lang["rules"]["deleteDialog"]["text"]["lineOne"](),
        lineTwo: lang["rules"]["deleteDialog"]["text"]["lineTwo"](),
        listOne: lang["rules"]["deleteDialog"]["text"]["listOne"](),
        listTwo: lang["rules"]["deleteDialog"]["text"]["listTwo"](),
      },
      title: lang["rules"]["deleteDialog"]["title"](),
    },
    disableDialog: {
      button: lang["rules"]["disableDialog"]["button"](),
      text: {
        listOne: lang["rules"]["disableDialog"]["text"]["listOne"](),
        listThree: lang["rules"]["disableDialog"]["text"]["listThree"](),
        listTwo: lang["rules"]["disableDialog"]["text"]["listTwo"](),
      },
      title: lang["rules"]["disableDialog"]["title"](),
    },
    enableDialog: {
      button: lang["rules"]["enableDialog"]["button"](),
      text: { listOne: lang["rules"]["enableDialog"]["text"]["listOne"]() },
      title: lang["rules"]["enableDialog"]["title"](),
    },
    learnAboutRules: {
      button: lang["rules"]["learnAboutRules"]["button"](),
      content: lang["rules"]["learnAboutRules"]["content"](),
      content2list1: lang["rules"]["learnAboutRules"]["content2list1"](),
      content2list2: lang["rules"]["learnAboutRules"]["content2list2"](),
      content2list3: lang["rules"]["learnAboutRules"]["content2list3"](),
      content2part1: lang["rules"]["learnAboutRules"]["content2part1"](),
      content2part2: lang["rules"]["learnAboutRules"]["content2part2"](),
      content3: lang["rules"]["learnAboutRules"]["content3"](),
      subtitle1: lang["rules"]["learnAboutRules"]["subtitle1"](),
      subtitle2: lang["rules"]["learnAboutRules"]["subtitle2"](),
      subtitle3: lang["rules"]["learnAboutRules"]["subtitle3"](),
      title: lang["rules"]["learnAboutRules"]["title"](),
    },
    navTabs: {
      rules: lang["rules"]["navTabs"]["rules"](),
      rulesDisabled: lang["rules"]["navTabs"]["rulesDisabled"](),
    },
    operationShorthands: {
      changeErpAssetAccount:
        lang["rules"]["operationShorthands"]["changeErpAssetAccount"](),
      changeErpCashAccount:
        lang["rules"]["operationShorthands"]["changeErpCashAccount"](),
      changeErpGainsAccount:
        lang["rules"]["operationShorthands"]["changeErpGainsAccount"](),
      changeErpLoanAccount:
        lang["rules"]["operationShorthands"]["changeErpLoanAccount"](),
      changeErpPnLAccount:
        lang["rules"]["operationShorthands"]["changeErpPnLAccount"](),
      recategorise: lang["rules"]["operationShorthands"]["recategorise"](),
    },
    operatorSelection: {
      accountPlaceholder:
        lang["rules"]["operatorSelection"]["accountPlaceholder"](),
      categoryPlaceholder:
        lang["rules"]["operatorSelection"]["categoryPlaceholder"](),
    },
    pageTitle: lang["rules"]["pageTitle"](),
    rulesTable: {
      empty: lang["rules"]["rulesTable"]["empty"](),
      headers: {
        accountsMapping:
          lang["rules"]["rulesTable"]["headers"]["accountsMapping"](),
        conditions: lang["rules"]["rulesTable"]["headers"]["conditions"](),
        name: lang["rules"]["rulesTable"]["headers"]["name"](),
      },
      loadMore: lang["rules"]["rulesTable"]["loadMore"](),
      row: {
        options: {
          delete: lang["rules"]["rulesTable"]["row"]["options"]["delete"](),
          deleteInfo:
            lang["rules"]["rulesTable"]["row"]["options"]["deleteInfo"](),
          disable: lang["rules"]["rulesTable"]["row"]["options"]["disable"](),
          disableInfo:
            lang["rules"]["rulesTable"]["row"]["options"]["disableInfo"](),
          edit: lang["rules"]["rulesTable"]["row"]["options"]["edit"](),
          enable: lang["rules"]["rulesTable"]["row"]["options"]["enable"](),
          moveToBottom:
            lang["rules"]["rulesTable"]["row"]["options"]["moveToBottom"](),
          moveToTop:
            lang["rules"]["rulesTable"]["row"]["options"]["moveToTop"](),
          viewRule: lang["rules"]["rulesTable"]["row"]["options"]["viewRule"](),
          viewTxs: lang["rules"]["rulesTable"]["row"]["options"]["viewTxs"](),
        },
      },
    },
  },
  save: lang["save"](),
  selectCountry: {
    autoDetectedCountry: {
      applyLatestRules: (args: { authority: Arg }): string =>
        lang["selectCountry"]["autoDetectedCountry"]["applyLatestRules"](
          args,
        ) as string,
      countryDetected: (args: {
        prefixCountryWithThe: Arg;
        countryName: Arg;
      }): string =>
        lang["selectCountry"]["autoDetectedCountry"]["countryDetected"](
          args,
        ) as string,
      plusMore: (args: { count: Arg }): string =>
        lang["selectCountry"]["autoDetectedCountry"]["plusMore"](
          args,
        ) as string,
      selectAnotherCountry:
        lang["selectCountry"]["autoDetectedCountry"]["selectAnotherCountry"](),
      submitCTA: lang["selectCountry"]["autoDetectedCountry"]["submitCTA"](),
    },
    body: lang["selectCountry"]["body"](),
    continue: lang["selectCountry"]["continue"](),
    loadingOption: lang["selectCountry"]["loadingOption"](),
    noOptions: lang["selectCountry"]["noOptions"](),
    noResults: lang["selectCountry"]["noResults"](),
    optimizeTaxSettings: {
      AT: lang["selectCountry"]["optimizeTaxSettings"]["AT"](),
      AU: lang["selectCountry"]["optimizeTaxSettings"]["AU"](),
      BE: lang["selectCountry"]["optimizeTaxSettings"]["BE"](),
      CA: lang["selectCountry"]["optimizeTaxSettings"]["CA"](),
      CH: lang["selectCountry"]["optimizeTaxSettings"]["CH"](),
      DE: lang["selectCountry"]["optimizeTaxSettings"]["DE"](),
      ES: lang["selectCountry"]["optimizeTaxSettings"]["ES"](),
      FI: lang["selectCountry"]["optimizeTaxSettings"]["FI"](),
      FR: lang["selectCountry"]["optimizeTaxSettings"]["FR"](),
      GR: lang["selectCountry"]["optimizeTaxSettings"]["GR"](),
      IE: lang["selectCountry"]["optimizeTaxSettings"]["IE"](),
      IN: lang["selectCountry"]["optimizeTaxSettings"]["IN"](),
      IT: lang["selectCountry"]["optimizeTaxSettings"]["IT"](),
      JP: lang["selectCountry"]["optimizeTaxSettings"]["JP"](),
      NL: lang["selectCountry"]["optimizeTaxSettings"]["NL"](),
      NO: lang["selectCountry"]["optimizeTaxSettings"]["NO"](),
      NZ: lang["selectCountry"]["optimizeTaxSettings"]["NZ"](),
      PT: lang["selectCountry"]["optimizeTaxSettings"]["PT"](),
      SE: lang["selectCountry"]["optimizeTaxSettings"]["SE"](),
      SG: lang["selectCountry"]["optimizeTaxSettings"]["SG"](),
      UK: lang["selectCountry"]["optimizeTaxSettings"]["UK"](),
      US: lang["selectCountry"]["optimizeTaxSettings"]["US"](),
      ZA: lang["selectCountry"]["optimizeTaxSettings"]["ZA"](),
    },
    optimizeTaxSettingsGeneric: (args: { taxAuthority: Arg }): string =>
      lang["selectCountry"]["optimizeTaxSettingsGeneric"](args) as string,
    pending: lang["selectCountry"]["pending"](),
    reportForms: {
      ATO: lang["selectCountry"]["reportForms"]["ATO"](),
      Form8949: lang["selectCountry"]["reportForms"]["Form8949"](),
      HMRC: lang["selectCountry"]["reportForms"]["HMRC"](),
      IRS: lang["selectCountry"]["reportForms"]["IRS"](),
      adjustedCostBase:
        lang["selectCountry"]["reportForms"]["adjustedCostBase"](),
      averageCostBase:
        lang["selectCountry"]["reportForms"]["averageCostBase"](),
      bedAndBreakfast:
        lang["selectCountry"]["reportForms"]["bedAndBreakfast"](),
      capitalGainsReport:
        lang["selectCountry"]["reportForms"]["capitalGainsReport"](),
      cerfa2086: lang["selectCountry"]["reportForms"]["cerfa2086"](),
      cra: lang["selectCountry"]["reportForms"]["cra"](),
      incomeReport: lang["selectCountry"]["reportForms"]["incomeReport"](),
      myTax: lang["selectCountry"]["reportForms"]["myTax"](),
      reports: lang["selectCountry"]["reportForms"]["reports"](),
      sameDay: lang["selectCountry"]["reportForms"]["sameDay"](),
      schedule3: lang["selectCountry"]["reportForms"]["schedule3"](),
      scheduleD: lang["selectCountry"]["reportForms"]["scheduleD"](),
      superficialLoss:
        lang["selectCountry"]["reportForms"]["superficialLoss"](),
      t1Income: lang["selectCountry"]["reportForms"]["t1Income"](),
      turboTaxOnline: lang["selectCountry"]["reportForms"]["turboTaxOnline"](),
    },
    title: lang["selectCountry"]["title"](),
    title2: lang["selectCountry"]["title2"](),
  },
  settings: {
    accounting: {
      dataManagement: lang["settings"]["accounting"]["dataManagement"](),
      inviteLink: {
        body: lang["settings"]["accounting"]["inviteLink"]["body"](),
        bodyWide: lang["settings"]["accounting"]["inviteLink"]["bodyWide"](),
        copy: lang["settings"]["accounting"]["inviteLink"]["copy"](),
        customiseLink:
          lang["settings"]["accounting"]["inviteLink"]["customiseLink"](),
        generatingCode:
          lang["settings"]["accounting"]["inviteLink"]["generatingCode"](),
        new: lang["settings"]["accounting"]["inviteLink"]["new"](),
        title: lang["settings"]["accounting"]["inviteLink"]["title"](),
        titleCollaborator:
          lang["settings"]["accounting"]["inviteLink"]["titleCollaborator"](),
        titleWide: lang["settings"]["accounting"]["inviteLink"]["titleWide"](),
        titleWideCollaborator:
          lang["settings"]["accounting"]["inviteLink"][
            "titleWideCollaborator"
          ](),
      },
      profile: lang["settings"]["accounting"]["profile"](),
      title: lang["settings"]["accounting"]["title"](),
    },
    accountingData: {
      updateDataSourceError:
        lang["settings"]["accountingData"]["updateDataSourceError"](),
      updateDataSourceSuccess:
        lang["settings"]["accountingData"]["updateDataSourceSuccess"](),
    },
    billing: {
      description: {
        part1: lang["settings"]["billing"]["description"]["part1"](),
        part2: lang["settings"]["billing"]["description"]["part2"](),
      },
      manage: lang["settings"]["billing"]["manage"](),
      nextBilling: lang["settings"]["billing"]["nextBilling"](),
      nextBillingDetails: (args: {
        date: Arg;
        amount: Arg;
        currency: Arg;
      }): string =>
        lang["settings"]["billing"]["nextBillingDetails"](args) as string,
      perYear: lang["settings"]["billing"]["perYear"](),
      title: lang["settings"]["billing"]["title"](),
      upgrade: lang["settings"]["billing"]["upgrade"](),
    },
    clearData: {
      buttonText: lang["settings"]["clearData"]["buttonText"](),
      description: (args: { isAccountant: Arg }): string =>
        lang["settings"]["clearData"]["description"](args) as string,
      text: (args: { isAccountant: Arg }): string =>
        lang["settings"]["clearData"]["text"](args) as string,
      titleClient: (args: { clientName: Arg }): string =>
        lang["settings"]["clearData"]["titleClient"](args) as string,
      titleClientFallback:
        lang["settings"]["clearData"]["titleClientFallback"](),
      titleSelf: lang["settings"]["clearData"]["titleSelf"](),
    },
    clientAccount: lang["settings"]["clientAccount"](),
    clientData: {
      dataBody: lang["settings"]["clientData"]["dataBody"](),
      invitation: {
        status: {
          accepted:
            lang["settings"]["clientData"]["invitation"]["status"][
              "accepted"
            ](),
          declined:
            lang["settings"]["clientData"]["invitation"]["status"][
              "declined"
            ](),
          default:
            lang["settings"]["clientData"]["invitation"]["status"]["default"](),
          pending: (args: { direction: Arg }): string =>
            lang["settings"]["clientData"]["invitation"]["status"]["pending"](
              args,
            ) as string,
          revoked:
            lang["settings"]["clientData"]["invitation"]["status"]["revoked"](),
        },
        verboseStatus: {
          accepted:
            lang["settings"]["clientData"]["invitation"]["verboseStatus"][
              "accepted"
            ](),
          declined:
            lang["settings"]["clientData"]["invitation"]["verboseStatus"][
              "declined"
            ](),
          deleted:
            lang["settings"]["clientData"]["invitation"]["verboseStatus"][
              "deleted"
            ](),
          pending:
            lang["settings"]["clientData"]["invitation"]["verboseStatus"][
              "pending"
            ](),
          revoked:
            lang["settings"]["clientData"]["invitation"]["verboseStatus"][
              "revoked"
            ](),
          revokedLink:
            lang["settings"]["clientData"]["invitation"]["verboseStatus"][
              "revokedLink"
            ](),
        },
      },
      invitationBody: lang["settings"]["clientData"]["invitationBody"](),
      invitationStatus: lang["settings"]["clientData"]["invitationStatus"](),
      paymentPlan: lang["settings"]["clientData"]["paymentPlan"](),
      profile: lang["settings"]["clientData"]["profile"](),
      tooltip: lang["settings"]["clientData"]["tooltip"](),
    },
    dataCleared: lang["settings"]["dataCleared"](),
    exitSurvey: {
      cancellationReasonQuestion: (args: { variation: Arg }): string =>
        lang["settings"]["exitSurvey"]["cancellationReasonQuestion"](
          args,
        ) as string,
      cancellationReasons: {
        accountant:
          lang["settings"]["exitSurvey"]["cancellationReasons"]["accountant"](),
        countrySupport:
          lang["settings"]["exitSurvey"]["cancellationReasons"][
            "countrySupport"
          ](),
        hardToUse:
          lang["settings"]["exitSurvey"]["cancellationReasons"]["hardToUse"](),
        importSupport:
          lang["settings"]["exitSurvey"]["cancellationReasons"][
            "importSupport"
          ](),
        incorrectResults:
          lang["settings"]["exitSurvey"]["cancellationReasons"][
            "incorrectResults"
          ](),
        noLongerTradingCrypto:
          lang["settings"]["exitSurvey"]["cancellationReasons"][
            "noLongerTradingCrypto"
          ](),
        timeConsuming:
          lang["settings"]["exitSurvey"]["cancellationReasons"][
            "timeConsuming"
          ](),
        tooExpensive:
          lang["settings"]["exitSurvey"]["cancellationReasons"][
            "tooExpensive"
          ](),
      },
      continue: lang["settings"]["exitSurvey"]["continue"](),
      otherCommentsQuestion:
        lang["settings"]["exitSurvey"]["otherCommentsQuestion"](),
      title: (args: { variation: Arg }): string =>
        lang["settings"]["exitSurvey"]["title"](args) as string,
    },
    hideSpamError: lang["settings"]["hideSpamError"](),
    hmrc: {
      actionText: lang["settings"]["hmrc"]["actionText"](),
      text: lang["settings"]["hmrc"]["text"](),
      title: lang["settings"]["hmrc"]["title"](),
    },
    lockPeriod: {
      add: {
        firstTransaction:
          lang["settings"]["lockPeriod"]["add"]["firstTransaction"](),
        from: lang["settings"]["lockPeriod"]["add"]["from"](),
        to: lang["settings"]["lockPeriod"]["add"]["to"](),
      },
      addButton: lang["settings"]["lockPeriod"]["addButton"](),
      bannerText: lang["settings"]["lockPeriod"]["bannerText"](),
      beginningOfTime: lang["settings"]["lockPeriod"]["beginningOfTime"](),
      cancel: lang["settings"]["lockPeriod"]["cancel"](),
      currentPeriod: {
        tooltip: lang["settings"]["lockPeriod"]["currentPeriod"]["tooltip"](),
      },
      description: lang["settings"]["lockPeriod"]["description"](),
      dialog: {
        action: lang["settings"]["lockPeriod"]["dialog"]["action"](),
        checklist: {
          balances:
            lang["settings"]["lockPeriod"]["dialog"]["checklist"]["balances"](),
          gains:
            lang["settings"]["lockPeriod"]["dialog"]["checklist"]["gains"](),
          reports:
            lang["settings"]["lockPeriod"]["dialog"]["checklist"]["reports"](),
        },
        confirm: lang["settings"]["lockPeriod"]["dialog"]["confirm"](),
        text: lang["settings"]["lockPeriod"]["dialog"]["text"](),
        title: lang["settings"]["lockPeriod"]["dialog"]["title"](),
        warning: lang["settings"]["lockPeriod"]["dialog"]["warning"](),
        warningParagraph:
          lang["settings"]["lockPeriod"]["dialog"]["warningParagraph"](),
        warningWithSubsequent:
          lang["settings"]["lockPeriod"]["dialog"]["warningWithSubsequent"](),
      },
      disabledCreateLock:
        lang["settings"]["lockPeriod"]["disabledCreateLock"](),
      inventoryMethodDisabled: (args: { inventoryMethod: Arg }): string =>
        lang["settings"]["lockPeriod"]["inventoryMethodDisabled"](
          args,
        ) as string,
      inventorySnapshots: {
        closing: {
          description:
            lang["settings"]["lockPeriod"]["inventorySnapshots"]["closing"][
              "description"
            ](),
          title:
            lang["settings"]["lockPeriod"]["inventorySnapshots"]["closing"][
              "title"
            ](),
        },
        opening: {
          description:
            lang["settings"]["lockPeriod"]["inventorySnapshots"]["opening"][
              "description"
            ](),
          title:
            lang["settings"]["lockPeriod"]["inventorySnapshots"]["opening"][
              "title"
            ](),
        },
        title: lang["settings"]["lockPeriod"]["inventorySnapshots"]["title"](),
      },
      issuesModal: {
        cancel: lang["settings"]["lockPeriod"]["issuesModal"]["cancel"](),
        currentIssues:
          lang["settings"]["lockPeriod"]["issuesModal"]["currentIssues"](),
        description:
          lang["settings"]["lockPeriod"]["issuesModal"]["description"](),
        ignoreAndLock:
          lang["settings"]["lockPeriod"]["issuesModal"]["ignoreAndLock"](),
        newIssueExplainer:
          lang["settings"]["lockPeriod"]["issuesModal"]["newIssueExplainer"](),
        title: lang["settings"]["lockPeriod"]["issuesModal"]["title"](),
        viewIssues:
          lang["settings"]["lockPeriod"]["issuesModal"]["viewIssues"](),
        warning: lang["settings"]["lockPeriod"]["issuesModal"]["warning"](),
      },
      lockPeriodTooltip: lang["settings"]["lockPeriod"]["lockPeriodTooltip"](),
      lockUnlockedWarning: {
        action:
          lang["settings"]["lockPeriod"]["lockUnlockedWarning"]["action"](),
        line1: lang["settings"]["lockPeriod"]["lockUnlockedWarning"]["line1"](),
        line2: lang["settings"]["lockPeriod"]["lockUnlockedWarning"]["line2"](),
        title: lang["settings"]["lockPeriod"]["lockUnlockedWarning"]["title"](),
        warning:
          lang["settings"]["lockPeriod"]["lockUnlockedWarning"]["warning"](),
        warningLockExisting:
          lang["settings"]["lockPeriod"]["lockUnlockedWarning"][
            "warningLockExisting"
          ](),
      },
      noLockPeriods: lang["settings"]["lockPeriod"]["noLockPeriods"](),
      now: lang["settings"]["lockPeriod"]["now"](),
      saveButton: lang["settings"]["lockPeriod"]["saveButton"](),
      title: lang["settings"]["lockPeriod"]["title"](),
      unlockAllSubsequentButtonTooltip:
        lang["settings"]["lockPeriod"]["unlockAllSubsequentButtonTooltip"](),
      unlockButtonTooltip:
        lang["settings"]["lockPeriod"]["unlockButtonTooltip"](),
    },
    longTermThreshold: {
      custom: lang["settings"]["longTermThreshold"]["custom"](),
      default: (args: { defaultValue: Arg }): string =>
        lang["settings"]["longTermThreshold"]["default"](args) as string,
      longTermThreshold:
        lang["settings"]["longTermThreshold"]["longTermThreshold"](),
      months: lang["settings"]["longTermThreshold"]["months"](),
      never: lang["settings"]["longTermThreshold"]["never"](),
    },
    manageData: {
      buttonText: lang["settings"]["manageData"]["buttonText"](),
      clear: lang["settings"]["manageData"]["clear"](),
      confirmClearData: lang["settings"]["manageData"]["confirmClearData"](),
      confirmClearDataPrompt:
        lang["settings"]["manageData"]["confirmClearDataPrompt"](),
      confirmDelete: lang["settings"]["manageData"]["confirmDelete"](),
      confirmDeletePrompt:
        lang["settings"]["manageData"]["confirmDeletePrompt"](),
      delete: lang["settings"]["manageData"]["delete"](),
      description: (args: { isAccountant: Arg }): string =>
        lang["settings"]["manageData"]["description"](args) as string,
      manageData: lang["settings"]["manageData"]["manageData"](),
      text: (args: { isAccountant: Arg }): string =>
        lang["settings"]["manageData"]["text"](args) as string,
      titleClient: (args: { clientName: Arg }): string =>
        lang["settings"]["manageData"]["titleClient"](args) as string,
      titleClientFallback:
        lang["settings"]["manageData"]["titleClientFallback"](),
      titleSelf: lang["settings"]["manageData"]["titleSelf"](),
    },
    notifications: {
      errors: {
        generic: lang["settings"]["notifications"]["errors"]["generic"](),
      },
    },
    pageTitle: (args: { tab: Arg }): string =>
      lang["settings"]["pageTitle"](args) as string,
    plan: {
      clientOwned: {
        body: lang["settings"]["plan"]["clientOwned"]["body"](),
        title: lang["settings"]["plan"]["clientOwned"]["title"](),
      },
      title: lang["settings"]["plan"]["title"](),
    },
    portfolio: lang["settings"]["portfolio"](),
    portfolioTab: {
      assetTracking: lang["settings"]["portfolioTab"]["assetTracking"](),
    },
    priceWindow: {
      off: lang["settings"]["priceWindow"]["off"](),
      on: (args: { value: Arg }): string =>
        lang["settings"]["priceWindow"]["on"](args) as string,
      title: lang["settings"]["priceWindow"]["title"](),
    },
    privacy: {
      part1: lang["settings"]["privacy"]["part1"](),
      part2: lang["settings"]["privacy"]["part2"](),
    },
    profile: {
      addEmail: lang["settings"]["profile"]["addEmail"](),
      addInviteCode: lang["settings"]["profile"]["addInviteCode"](),
      addName: lang["settings"]["profile"]["addName"](),
      changeEmail: lang["settings"]["profile"]["changeEmail"](),
      changeInviteCode: lang["settings"]["profile"]["changeInviteCode"](),
      changeName: lang["settings"]["profile"]["changeName"](),
      country: lang["settings"]["profile"]["country"](),
      debug: {
        title: lang["settings"]["profile"]["debug"]["title"](),
        uid: {
          notification:
            lang["settings"]["profile"]["debug"]["uid"]["notification"](),
          title: lang["settings"]["profile"]["debug"]["uid"]["title"](),
        },
      },
      duplicateError: lang["settings"]["profile"]["duplicateError"](),
      edit: lang["settings"]["profile"]["edit"](),
      email: lang["settings"]["profile"]["email"](),
      emailAddressChanged: lang["settings"]["profile"]["emailAddressChanged"](),
      emailError: lang["settings"]["profile"]["emailError"](),
      emailSent: lang["settings"]["profile"]["emailSent"](),
      experience: {
        smoothScrolling: {
          title:
            lang["settings"]["profile"]["experience"]["smoothScrolling"][
              "title"
            ](),
          tooltipOff:
            lang["settings"]["profile"]["experience"]["smoothScrolling"][
              "tooltipOff"
            ](),
          tooltipOn:
            lang["settings"]["profile"]["experience"]["smoothScrolling"][
              "tooltipOn"
            ](),
        },
        title: lang["settings"]["profile"]["experience"]["title"](),
      },
      inviteCode: lang["settings"]["profile"]["inviteCode"](),
      inviteCodeChanged: lang["settings"]["profile"]["inviteCodeChanged"](),
      lengthError: lang["settings"]["profile"]["lengthError"](),
      linkedClientsEmail: lang["settings"]["profile"]["linkedClientsEmail"](),
      linkedClientsName: lang["settings"]["profile"]["linkedClientsName"](),
      localCurrency: lang["settings"]["profile"]["localCurrency"](),
      logout: lang["settings"]["profile"]["logout"](),
      name: lang["settings"]["profile"]["name"](),
      nameChanged: lang["settings"]["profile"]["nameChanged"](),
      nameToolTip: lang["settings"]["profile"]["nameToolTip"](),
      noLinkedClient: lang["settings"]["profile"]["noLinkedClient"](),
      notConnected: lang["settings"]["profile"]["notConnected"](),
      oauth: {
        areYouSure: (args: { oauth: Arg }): string =>
          lang["settings"]["profile"]["oauth"]["areYouSure"](args) as string,
        cancel: lang["settings"]["profile"]["oauth"]["cancel"](),
        connected: lang["settings"]["profile"]["oauth"]["connected"](),
        disconnect: lang["settings"]["profile"]["oauth"]["disconnect"](),
        error: lang["settings"]["profile"]["oauth"]["error"](),
        success: lang["settings"]["profile"]["oauth"]["success"](),
        title: (args: { oauth: Arg }): string =>
          lang["settings"]["profile"]["oauth"]["title"](args) as string,
        walletOauth: lang["settings"]["profile"]["oauth"]["walletOauth"](),
        warning: (args: { email: Arg }): string =>
          lang["settings"]["profile"]["oauth"]["warning"](args) as string,
      },
      password: {
        accountPassword:
          lang["settings"]["profile"]["password"]["accountPassword"](),
        changePassword:
          lang["settings"]["profile"]["password"]["changePassword"](),
        confirmNewPassword:
          lang["settings"]["profile"]["password"]["confirmNewPassword"](),
        dontMatch: lang["settings"]["profile"]["password"]["dontMatch"](),
        newPassword: lang["settings"]["profile"]["password"]["newPassword"](),
        oldPassword: lang["settings"]["profile"]["password"]["oldPassword"](),
        resetPassword:
          lang["settings"]["profile"]["password"]["resetPassword"](),
        saveNewPassword:
          lang["settings"]["profile"]["password"]["saveNewPassword"](),
      },
      resendVerificationEmail:
        lang["settings"]["profile"]["resendVerificationEmail"](),
      title: lang["settings"]["profile"]["title"](),
      unverified: lang["settings"]["profile"]["unverified"](),
      validationError: lang["settings"]["profile"]["validationError"](),
      verified: lang["settings"]["profile"]["verified"](),
      verifiedBanner: lang["settings"]["profile"]["verifiedBanner"](),
      verifyEmail: lang["settings"]["profile"]["verifyEmail"](),
      wentWrongUpdatingEmail:
        lang["settings"]["profile"]["wentWrongUpdatingEmail"](),
      wentWrongUpdatingInviteCode:
        lang["settings"]["profile"]["wentWrongUpdatingInviteCode"](),
      wentWrongUpdatingName:
        lang["settings"]["profile"]["wentWrongUpdatingName"](),
    },
    report: {
      airdropsAsIncome: {
        title: lang["settings"]["report"]["airdropsAsIncome"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["airdropsAsIncome"]["tooltip"][
            "off"
          ](),
          on: lang["settings"]["report"]["airdropsAsIncome"]["tooltip"]["on"](),
        },
      },
      allowBridgeFromUncategorised: {
        title:
          lang["settings"]["report"]["allowBridgeFromUncategorised"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["allowBridgeFromUncategorised"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["allowBridgeFromUncategorised"][
            "tooltip"
          ]["on"](),
        },
      },
      allowGenerateFeeOnGroupedQuantityDiff: {
        title:
          lang["settings"]["report"]["allowGenerateFeeOnGroupedQuantityDiff"][
            "title"
          ](),
        tooltip: {
          off: lang["settings"]["report"][
            "allowGenerateFeeOnGroupedQuantityDiff"
          ]["tooltip"]["off"](),
          on: lang["settings"]["report"][
            "allowGenerateFeeOnGroupedQuantityDiff"
          ]["tooltip"]["on"](),
        },
      },
      assumeTransferCounterparts: {
        title:
          lang["settings"]["report"]["assumeTransferCounterparts"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["assumeTransferCounterparts"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["assumeTransferCounterparts"][
            "tooltip"
          ]["on"](),
        },
      },
      bridgeAsNonTaxable: {
        title: lang["settings"]["report"]["bridgeAsNonTaxable"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["bridgeAsNonTaxable"]["tooltip"][
            "off"
          ](),
          on: lang["settings"]["report"]["bridgeAsNonTaxable"]["tooltip"][
            "on"
          ](),
        },
      },
      collateralAsNonTaxable: {
        title: lang["settings"]["report"]["collateralAsNonTaxable"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["collateralAsNonTaxable"]["tooltip"][
            "off"
          ](),
          on: lang["settings"]["report"]["collateralAsNonTaxable"]["tooltip"][
            "on"
          ](),
        },
      },
      cryptoToCryptoNonTaxable: {
        title:
          lang["settings"]["report"]["cryptoToCryptoNonTaxable"]["title"](),
        tooltip: {
          nftToCryptoTaxable:
            lang["settings"]["report"]["cryptoToCryptoNonTaxable"]["tooltip"][
              "nftToCryptoTaxable"
            ](),
          off: lang["settings"]["report"]["cryptoToCryptoNonTaxable"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["cryptoToCryptoNonTaxable"]["tooltip"][
            "on"
          ](),
          onWithDate: (args: { date: Arg }): string =>
            lang["settings"]["report"]["cryptoToCryptoNonTaxable"]["tooltip"][
              "onWithDate"
            ](args) as string,
        },
      },
      emailReports: {
        options: {
          off: {
            description:
              lang["settings"]["report"]["emailReports"]["options"]["off"][
                "description"
              ](),
            title:
              lang["settings"]["report"]["emailReports"]["options"]["off"][
                "title"
              ](),
          },
          on: {
            description:
              lang["settings"]["report"]["emailReports"]["options"]["on"][
                "description"
              ](),
            title:
              lang["settings"]["report"]["emailReports"]["options"]["on"][
                "title"
              ](),
          },
        },
        title: lang["settings"]["report"]["emailReports"]["title"](),
        wentWrong: lang["settings"]["report"]["emailReports"]["wentWrong"](),
      },
      holdingsBalanceType: {
        title: lang["settings"]["report"]["holdingsBalanceType"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["holdingsBalanceType"]["tooltip"][
            "off"
          ](),
          on: lang["settings"]["report"]["holdingsBalanceType"]["tooltip"][
            "on"
          ](),
        },
      },
      ignoreBalanceRemainingValueThreshold: {
        subtext:
          lang["settings"]["report"]["ignoreBalanceRemainingValueThreshold"][
            "subtext"
          ](),
        title:
          lang["settings"]["report"]["ignoreBalanceRemainingValueThreshold"][
            "title"
          ](),
      },
      ignoreFiat: {
        title: lang["settings"]["report"]["ignoreFiat"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["ignoreFiat"]["tooltip"]["off"](),
          on: lang["settings"]["report"]["ignoreFiat"]["tooltip"]["on"](),
        },
      },
      ignoreFiatLabel: lang["settings"]["report"]["ignoreFiatLabel"](),
      irs2025Warning: {
        chip: lang["settings"]["report"]["irs2025Warning"]["chip"](),
        content: {
          body1:
            lang["settings"]["report"]["irs2025Warning"]["content"]["body1"](),
          body2:
            lang["settings"]["report"]["irs2025Warning"]["content"]["body2"](),
          header1:
            lang["settings"]["report"]["irs2025Warning"]["content"][
              "header1"
            ](),
          header2:
            lang["settings"]["report"]["irs2025Warning"]["content"][
              "header2"
            ](),
          summary:
            lang["settings"]["report"]["irs2025Warning"]["content"][
              "summary"
            ](),
        },
        learnMore: lang["settings"]["report"]["irs2025Warning"]["learnMore"](),
      },
      lostStolenAsNonTaxable: {
        title: lang["settings"]["report"]["lostStolenAsNonTaxable"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["lostStolenAsNonTaxable"]["tooltip"][
            "off"
          ](),
          on: lang["settings"]["report"]["lostStolenAsNonTaxable"]["tooltip"][
            "on"
          ](),
        },
      },
      miningAsIncome: {
        title: lang["settings"]["report"]["miningAsIncome"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["miningAsIncome"]["tooltip"]["off"](),
          on: lang["settings"]["report"]["miningAsIncome"]["tooltip"]["on"](),
        },
      },
      outgoingGiftAsNonTaxable: {
        title:
          lang["settings"]["report"]["outgoingGiftAsNonTaxable"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["outgoingGiftAsNonTaxable"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["outgoingGiftAsNonTaxable"]["tooltip"][
            "on"
          ](),
        },
      },
      personalUseAsNonTaxable: {
        title: lang["settings"]["report"]["personalUseAsNonTaxable"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["personalUseAsNonTaxable"]["tooltip"][
            "off"
          ](),
          on: lang["settings"]["report"]["personalUseAsNonTaxable"]["tooltip"][
            "on"
          ](),
        },
      },
      report: lang["settings"]["report"]["report"](),
      royaltiesAsIncome: {
        title: lang["settings"]["report"]["royaltiesAsIncome"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["royaltiesAsIncome"]["tooltip"][
            "off"
          ](),
          on: lang["settings"]["report"]["royaltiesAsIncome"]["tooltip"][
            "on"
          ](),
        },
      },
      showAllAccountsOnPortfolio: {
        title:
          lang["settings"]["report"]["showAllAccountsOnPortfolio"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["showAllAccountsOnPortfolio"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["showAllAccountsOnPortfolio"][
            "tooltip"
          ]["on"](),
        },
      },
      showFiatHoldings: {
        title: lang["settings"]["report"]["showFiatHoldings"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["showFiatHoldings"]["tooltip"][
            "off"
          ](),
          on: lang["settings"]["report"]["showFiatHoldings"]["tooltip"]["on"](),
        },
      },
      specificInventoryByExchange: {
        disabledReason:
          lang["settings"]["report"]["specificInventoryByExchange"][
            "disabledReason"
          ](),
        options: {
          mixed: {
            description:
              lang["settings"]["report"]["specificInventoryByExchange"][
                "options"
              ]["mixed"]["description"](),
            title:
              lang["settings"]["report"]["specificInventoryByExchange"][
                "options"
              ]["mixed"]["title"](),
          },
          off: {
            description:
              lang["settings"]["report"]["specificInventoryByExchange"][
                "options"
              ]["off"]["description"](),
            title:
              lang["settings"]["report"]["specificInventoryByExchange"][
                "options"
              ]["off"]["title"](),
          },
          on: {
            description:
              lang["settings"]["report"]["specificInventoryByExchange"][
                "options"
              ]["on"]["description"](),
            title:
              lang["settings"]["report"]["specificInventoryByExchange"][
                "options"
              ]["on"]["title"](),
          },
        },
        title:
          lang["settings"]["report"]["specificInventoryByExchange"]["title"](),
      },
      splitFeesEvenlyAcrossGroupedMints: {
        title:
          lang["settings"]["report"]["splitFeesEvenlyAcrossGroupedMints"][
            "title"
          ](),
        tooltip: {
          off: lang["settings"]["report"]["splitFeesEvenlyAcrossGroupedMints"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["splitFeesEvenlyAcrossGroupedMints"][
            "tooltip"
          ]["on"](),
        },
      },
      stakingAsIncome: {
        title: lang["settings"]["report"]["stakingAsIncome"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["stakingAsIncome"]["tooltip"][
            "off"
          ](),
          on: lang["settings"]["report"]["stakingAsIncome"]["tooltip"]["on"](),
        },
      },
      stakingDepositWithdrawalNonTaxable: {
        title:
          lang["settings"]["report"]["stakingDepositWithdrawalNonTaxable"][
            "title"
          ](),
        tooltip: {
          off: lang["settings"]["report"]["stakingDepositWithdrawalNonTaxable"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["stakingDepositWithdrawalNonTaxable"][
            "tooltip"
          ]["on"](),
        },
      },
      taxSettings: lang["settings"]["report"]["taxSettings"](),
      title: lang["settings"]["report"]["title"](),
      treatLiquidityAddRemovalNonTaxable: {
        title:
          lang["settings"]["report"]["treatLiquidityAddRemovalNonTaxable"][
            "title"
          ](),
        tooltip: {
          off: lang["settings"]["report"]["treatLiquidityAddRemovalNonTaxable"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["treatLiquidityAddRemovalNonTaxable"][
            "tooltip"
          ]["on"](),
        },
      },
      uncategorisedInAsTaxable: {
        title:
          lang["settings"]["report"]["uncategorisedInAsTaxable"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["uncategorisedInAsTaxable"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["uncategorisedInAsTaxable"]["tooltip"][
            "on"
          ](),
        },
      },
      uncategorisedOutAsTaxable: {
        title:
          lang["settings"]["report"]["uncategorisedOutAsTaxable"]["title"](),
        tooltip: {
          off: lang["settings"]["report"]["uncategorisedOutAsTaxable"][
            "tooltip"
          ]["off"](),
          on: lang["settings"]["report"]["uncategorisedOutAsTaxable"][
            "tooltip"
          ]["on"](),
        },
      },
      updatedTaxSettingsError:
        lang["settings"]["report"]["updatedTaxSettingsError"](),
      updatedTaxSettingsSuccess:
        lang["settings"]["report"]["updatedTaxSettingsSuccess"](),
    },
    reportActionButton: {
      action: lang["settings"]["reportActionButton"]["action"](),
      loadingAction: lang["settings"]["reportActionButton"]["loadingAction"](),
      loadingTooltip:
        lang["settings"]["reportActionButton"]["loadingTooltip"](),
      tooltip: lang["settings"]["reportActionButton"]["tooltip"](),
    },
    reportSettings: lang["settings"]["reportSettings"](),
    sidebar: {
      loading: lang["settings"]["sidebar"]["loading"](),
      searchClients: lang["settings"]["sidebar"]["searchClients"](),
    },
    somethingWentWrong: lang["settings"]["somethingWentWrong"](),
    somethingWentWrongContact: lang["settings"]["somethingWentWrongContact"](),
    supportAccess: {
      body: {
        accessGrantedAt: (args: { accessGrantedAt: Arg }): string =>
          lang["settings"]["supportAccess"]["body"]["accessGrantedAt"](
            args,
          ) as string,
        button: {
          grantAccess:
            lang["settings"]["supportAccess"]["body"]["button"][
              "grantAccess"
            ](),
          revokeAccess:
            lang["settings"]["supportAccess"]["body"]["button"][
              "revokeAccess"
            ](),
        },
        description: lang["settings"]["supportAccess"]["body"]["description"](),
        title: lang["settings"]["supportAccess"]["body"]["title"](),
      },
      title: lang["settings"]["supportAccess"]["title"](),
    },
    tabs: {
      account: lang["settings"]["tabs"]["account"](),
      alpha: lang["settings"]["tabs"]["alpha"](),
      billing: lang["settings"]["tabs"]["billing"](),
      general: lang["settings"]["tabs"]["general"](),
      integrations: lang["settings"]["tabs"]["integrations"](),
      "integrations-deprecated":
        lang["settings"]["tabs"]["integrations-deprecated"](),
      loading: lang["settings"]["tabs"]["loading"](),
      "lock-period": lang["settings"]["tabs"]["lock-period"](),
      permissions: lang["settings"]["tabs"]["permissions"](),
      plan: lang["settings"]["tabs"]["plan"](),
      portfolio: lang["settings"]["tabs"]["portfolio"](),
      team: lang["settings"]["tabs"]["team"](),
    },
    team: {
      header: {
        email: lang["settings"]["team"]["header"]["email"](),
        lastLogin: lang["settings"]["team"]["header"]["lastLogin"](),
        name: lang["settings"]["team"]["header"]["name"](),
        search: lang["settings"]["team"]["header"]["search"](),
      },
      invite: lang["settings"]["team"]["invite"](),
      inviteTeamMember: lang["settings"]["team"]["inviteTeamMember"](),
      inviteTooltip: (args: { date: Arg }): string =>
        lang["settings"]["team"]["inviteTooltip"](args) as string,
      managementAndPermissions:
        lang["settings"]["team"]["managementAndPermissions"](),
      noResults: lang["settings"]["team"]["noResults"](),
      noTeamMembers: lang["settings"]["team"]["noTeamMembers"](),
      owner: lang["settings"]["team"]["owner"](),
    },
    threshold: lang["settings"]["threshold"](),
    timezone: lang["settings"]["timezone"](),
    title: lang["settings"]["title"](),
  },
  similarTransactionsSuggestion: {
    accept: lang["similarTransactionsSuggestion"]["accept"](),
    applyToFuture: lang["similarTransactionsSuggestion"]["applyToFuture"](),
    categorizeItAs: lang["similarTransactionsSuggestion"]["categorizeItAs"](),
    deny: lang["similarTransactionsSuggestion"]["deny"](),
    modal: {
      buttonCTA: lang["similarTransactionsSuggestion"]["modal"]["buttonCTA"](),
      receivedFrom:
        lang["similarTransactionsSuggestion"]["modal"]["receivedFrom"](),
      sentTo: lang["similarTransactionsSuggestion"]["modal"]["sentTo"](),
      similarTxs:
        lang["similarTransactionsSuggestion"]["modal"]["similarTxs"](),
    },
    noSimilar: lang["similarTransactionsSuggestion"]["noSimilar"](),
    notNow: lang["similarTransactionsSuggestion"]["notNow"](),
    operators: {
      actionId:
        lang["similarTransactionsSuggestion"]["operators"]["actionId"](),
      after: lang["similarTransactionsSuggestion"]["operators"]["after"](),
      and: lang["similarTransactionsSuggestion"]["operators"]["and"](),
      before: lang["similarTransactionsSuggestion"]["operators"]["before"](),
      blockchain:
        lang["similarTransactionsSuggestion"]["operators"]["blockchain"](),
      category:
        lang["similarTransactionsSuggestion"]["operators"]["category"](),
      comment: lang["similarTransactionsSuggestion"]["operators"]["comment"](),
      comments:
        lang["similarTransactionsSuggestion"]["operators"]["comments"](),
      currency:
        lang["similarTransactionsSuggestion"]["operators"]["currency"](),
      date: lang["similarTransactionsSuggestion"]["operators"]["date"](),
      description:
        lang["similarTransactionsSuggestion"]["operators"]["description"](),
      feeCurrency:
        lang["similarTransactionsSuggestion"]["operators"]["feeCurrency"](),
      from: lang["similarTransactionsSuggestion"]["operators"]["from"](),
      hasRule: lang["similarTransactionsSuggestion"]["operators"]["hasRule"](),
      id: lang["similarTransactionsSuggestion"]["operators"]["id"](),
      importId:
        lang["similarTransactionsSuggestion"]["operators"]["importId"](),
      importType:
        lang["similarTransactionsSuggestion"]["operators"]["importType"](),
      isSmartContractInteraction:
        lang["similarTransactionsSuggestion"]["operators"][
          "isSmartContractInteraction"
        ](),
      missingPrice:
        lang["similarTransactionsSuggestion"]["operators"]["missingPrice"](),
      negativeBalance:
        lang["similarTransactionsSuggestion"]["operators"]["negativeBalance"](),
      nftCollection:
        lang["similarTransactionsSuggestion"]["operators"]["nftCollection"](),
      not: lang["similarTransactionsSuggestion"]["operators"]["not"](),
      or: lang["similarTransactionsSuggestion"]["operators"]["or"](),
      party: lang["similarTransactionsSuggestion"]["operators"]["party"](),
      reconciliation:
        lang["similarTransactionsSuggestion"]["operators"]["reconciliation"](),
      reviewed:
        lang["similarTransactionsSuggestion"]["operators"]["reviewed"](),
      rule: lang["similarTransactionsSuggestion"]["operators"]["rule"](),
      ruleOperator:
        lang["similarTransactionsSuggestion"]["operators"]["ruleOperator"](),
      source: lang["similarTransactionsSuggestion"]["operators"]["source"](),
      suggestion:
        lang["similarTransactionsSuggestion"]["operators"]["suggestion"](),
      sync: lang["similarTransactionsSuggestion"]["operators"]["sync"](),
      to: lang["similarTransactionsSuggestion"]["operators"]["to"](),
      trade: lang["similarTransactionsSuggestion"]["operators"]["trade"](),
      txCurrency:
        lang["similarTransactionsSuggestion"]["operators"]["txCurrency"](),
      txFunctionName:
        lang["similarTransactionsSuggestion"]["operators"]["txFunctionName"](),
      txFunctionSignature:
        lang["similarTransactionsSuggestion"]["operators"][
          "txFunctionSignature"
        ](),
      txId: lang["similarTransactionsSuggestion"]["operators"]["txId"](),
      warning: lang["similarTransactionsSuggestion"]["operators"]["warning"](),
    },
    optOut: {
      button: lang["similarTransactionsSuggestion"]["optOut"]["button"](),
      loadingSimilar:
        lang["similarTransactionsSuggestion"]["optOut"]["loadingSimilar"](),
      noSimilar: lang["similarTransactionsSuggestion"]["optOut"]["noSimilar"](),
      title: (args: { count: Arg; linkTextKey: Arg }): string =>
        lang["similarTransactionsSuggestion"]["optOut"]["title"](
          args,
        ) as string,
      tooltip: {
        criteriaFour:
          lang["similarTransactionsSuggestion"]["optOut"]["tooltip"][
            "criteriaFour"
          ](),
        criteriaOne:
          lang["similarTransactionsSuggestion"]["optOut"]["tooltip"][
            "criteriaOne"
          ](),
        criteriaThree:
          lang["similarTransactionsSuggestion"]["optOut"]["tooltip"][
            "criteriaThree"
          ](),
        criteriaTwo:
          lang["similarTransactionsSuggestion"]["optOut"]["tooltip"][
            "criteriaTwo"
          ](),
        title:
          lang["similarTransactionsSuggestion"]["optOut"]["tooltip"]["title"](),
      },
    },
    similarTransactionsFound: (args: { count: Arg }): string =>
      lang["similarTransactionsSuggestion"]["similarTransactionsFound"](
        args,
      ) as string,
    successToast: (args: { count: Arg }): string =>
      lang["similarTransactionsSuggestion"]["successToast"](args) as string,
    viewTransactions:
      lang["similarTransactionsSuggestion"]["viewTransactions"](),
    weHaveFound: (args: { count: Arg }): string =>
      lang["similarTransactionsSuggestion"]["weHaveFound"](args) as string,
  },
  skip: lang["skip"](),
  spam: lang["spam"](),
  start: lang["start"](),
  support: {
    contact: lang["support"]["contact"](),
    deleteAccountDisabled: lang["support"]["deleteAccountDisabled"](),
  },
  supportedLanguages: {
    de: lang["supportedLanguages"]["de"](),
    eng: lang["supportedLanguages"]["eng"](),
    es: lang["supportedLanguages"]["es"](),
    fr: lang["supportedLanguages"]["fr"](),
    it: lang["supportedLanguages"]["it"](),
    xx: lang["supportedLanguages"]["xx"](),
    xxl: lang["supportedLanguages"]["xxl"](),
    xxs: lang["supportedLanguages"]["xxs"](),
  },
  sync: {
    actionErpSyncFailed: (args: { erpProvider: Arg }): string =>
      lang["sync"]["actionErpSyncFailed"](args) as string,
    apiStillSyncing: lang["sync"]["apiStillSyncing"](),
    integrationSyncing: (args: { name: Arg }): string =>
      lang["sync"]["integrationSyncing"](args) as string,
    reportRefreshFailed: lang["sync"]["reportRefreshFailed"](),
    syncFailed: (args: { name: Arg }): string =>
      lang["sync"]["syncFailed"](args) as string,
    syncOneHour: (args: { name: Arg }): string =>
      lang["sync"]["syncOneHour"](args) as string,
    syncSeveralMinutes: (args: { name: Arg }): string =>
      lang["sync"]["syncSeveralMinutes"](args) as string,
    syncingAPIs: (args: { name: Arg }): string =>
      lang["sync"]["syncingAPIs"](args) as string,
    syncingBlockchainWallets: (args: { name: Arg }): string =>
      lang["sync"]["syncingBlockchainWallets"](args) as string,
    syncingName: (args: { name: Arg }): string =>
      lang["sync"]["syncingName"](args) as string,
    uploadDeprecated: {
      coinbase: lang["sync"]["uploadDeprecated"]["coinbase"](),
      generic: lang["sync"]["uploadDeprecated"]["generic"](),
    },
    uploadFail: (args: { name: Arg }): string =>
      lang["sync"]["uploadFail"](args) as string,
    uploadingFiveMins: (args: { name: Arg }): string =>
      lang["sync"]["uploadingFiveMins"](args) as string,
    xeroSyncFailed: lang["sync"]["xeroSyncFailed"](),
  },
  syncErrorReasons: {
    "api-changed": {
      ctaType: lang["syncErrorReasons"]["api-changed"]["ctaType"](),
      message: lang["syncErrorReasons"]["api-changed"]["message"](),
    },
    "api-unavailable": {
      ctaType: lang["syncErrorReasons"]["api-unavailable"]["ctaType"](),
      message: (args: { exchangeName: Arg }): string =>
        lang["syncErrorReasons"]["api-unavailable"]["message"](args) as string,
    },
    "app-error": {
      ctaType: lang["syncErrorReasons"]["app-error"]["ctaType"](),
      message: lang["syncErrorReasons"]["app-error"]["message"](),
    },
    "csv-invalid-column": {
      ctaType: lang["syncErrorReasons"]["csv-invalid-column"]["ctaType"](),
      message: lang["syncErrorReasons"]["csv-invalid-column"]["message"](),
    },
    "csv-not-required": {
      ctaType: lang["syncErrorReasons"]["csv-not-required"]["ctaType"](),
      message: lang["syncErrorReasons"]["csv-not-required"]["message"](),
    },
    "csv-not-supported": {
      ctaType: lang["syncErrorReasons"]["csv-not-supported"]["ctaType"](),
      message: lang["syncErrorReasons"]["csv-not-supported"]["message"](),
    },
    "expired-api-key": {
      ctaType: lang["syncErrorReasons"]["expired-api-key"]["ctaType"](),
      message: lang["syncErrorReasons"]["expired-api-key"]["message"](),
    },
    "invalid-api-key": {
      ctaType: lang["syncErrorReasons"]["invalid-api-key"]["ctaType"](),
      message: lang["syncErrorReasons"]["invalid-api-key"]["message"](),
    },
    "invalid-csv": {
      ctaType: lang["syncErrorReasons"]["invalid-csv"]["ctaType"](),
      message: lang["syncErrorReasons"]["invalid-csv"]["message"](),
    },
    "invalid-csv-headers": {
      ctaType: lang["syncErrorReasons"]["invalid-csv-headers"]["ctaType"](),
      message: lang["syncErrorReasons"]["invalid-csv-headers"]["message"](),
    },
    "invalid-permissions": {
      ctaType: lang["syncErrorReasons"]["invalid-permissions"]["ctaType"](),
      message: lang["syncErrorReasons"]["invalid-permissions"]["message"](),
    },
    "invalid-user-defined-field": {
      ctaType:
        lang["syncErrorReasons"]["invalid-user-defined-field"]["ctaType"](),
      message:
        lang["syncErrorReasons"]["invalid-user-defined-field"]["message"](),
    },
    "invalid-wallet-address": {
      ctaType: lang["syncErrorReasons"]["invalid-wallet-address"]["ctaType"](),
      message: lang["syncErrorReasons"]["invalid-wallet-address"]["message"](),
    },
    "no-transactions": {
      ctaType: lang["syncErrorReasons"]["no-transactions"]["ctaType"](),
      message: lang["syncErrorReasons"]["no-transactions"]["message"](),
    },
    "rate-limit-failed": {
      ctaType: lang["syncErrorReasons"]["rate-limit-failed"]["ctaType"](),
      message: lang["syncErrorReasons"]["rate-limit-failed"]["message"](),
    },
    timeout: {
      ctaType: lang["syncErrorReasons"]["timeout"]["ctaType"](),
      message: lang["syncErrorReasons"]["timeout"]["message"](),
    },
    "too-many-transactions": {
      ctaType: lang["syncErrorReasons"]["too-many-transactions"]["ctaType"](),
      message: lang["syncErrorReasons"]["too-many-transactions"]["message"](),
    },
    "unknown-error": {
      ctaType: lang["syncErrorReasons"]["unknown-error"]["ctaType"](),
      message: lang["syncErrorReasons"]["unknown-error"]["message"](),
    },
    "unsupported-data-format": {
      ctaType: lang["syncErrorReasons"]["unsupported-data-format"]["ctaType"](),
      message: lang["syncErrorReasons"]["unsupported-data-format"]["message"](),
    },
  },
  syncHistory: {
    confirm: {
      text: lang["syncHistory"]["confirm"]["text"](),
      title: lang["syncHistory"]["confirm"]["title"](),
      warning: (args: { name: Arg; date: Arg }): string =>
        lang["syncHistory"]["confirm"]["warning"](args) as string,
    },
    date: lang["syncHistory"]["date"](),
    delete: lang["syncHistory"]["delete"](),
    hardSync: lang["syncHistory"]["hardSync"](),
    id: lang["syncHistory"]["id"](),
    initialSync: lang["syncHistory"]["initialSync"](),
    name: lang["syncHistory"]["name"](),
    noneFound: lang["syncHistory"]["noneFound"](),
    title: lang["syncHistory"]["title"](),
    transactions: lang["syncHistory"]["transactions"](),
    transactionsHeader: lang["syncHistory"]["transactionsHeader"](),
    viewTx: lang["syncHistory"]["viewTx"](),
  },
  syncStatus: {
    failed: lang["syncStatus"]["failed"](),
    reconciliation: lang["syncStatus"]["reconciliation"](),
    retry: lang["syncStatus"]["retry"](),
    updated: lang["syncStatus"]["updated"](),
    updating: lang["syncStatus"]["updating"](),
  },
  tabNav: {
    connectedAccounts: lang["tabNav"]["connectedAccounts"](),
    more: lang["tabNav"]["more"](),
    nfts: lang["tabNav"]["nfts"](),
    plan: lang["tabNav"]["plan"](),
    report: lang["tabNav"]["report"](),
    review: lang["tabNav"]["review"](),
    settings: lang["tabNav"]["settings"](),
    summary: lang["tabNav"]["summary"](),
    transactions: lang["tabNav"]["transactions"](),
  },
  tag: {
    createNew: lang["tag"]["createNew"](),
    createNewIdentity: lang["tag"]["createNewIdentity"](),
    deleteMessage: (args: { address: Arg; entityName: Arg }): string =>
      lang["tag"]["deleteMessage"](args) as string,
    groups: {
      Contract: lang["tag"]["groups"]["Contract"](),
      Exchange: lang["tag"]["groups"]["Exchange"](),
      Extension: lang["tag"]["groups"]["Extension"](),
      Manual: lang["tag"]["groups"]["Manual"](),
    },
    identified: (args: { count: Arg }): string =>
      lang["tag"]["identified"](args) as string,
    identify: lang["tag"]["identify"](),
    identifyAddress: lang["tag"]["identifyAddress"](),
    identifyAddressModal: {
      description: lang["tag"]["identifyAddressModal"]["description"](),
      doneButton: lang["tag"]["identifyAddressModal"]["doneButton"](),
      title: lang["tag"]["identifyAddressModal"]["title"](),
    },
    identifyDescription: lang["tag"]["identifyDescription"](),
    manageAddressModal: {
      description: (args: { entityDisplayName: Arg }): string =>
        lang["tag"]["manageAddressModal"]["description"](args) as string,
      descriptionContract: (args: { entityDisplayName: Arg }): string =>
        lang["tag"]["manageAddressModal"]["descriptionContract"](
          args,
        ) as string,
      doneButton: lang["tag"]["manageAddressModal"]["doneButton"](),
      title: lang["tag"]["manageAddressModal"]["title"](),
    },
    refAddressModal: {
      description: (args: { entityDisplayName: Arg }): string =>
        lang["tag"]["refAddressModal"]["description"](args) as string,
      doneButton: lang["tag"]["refAddressModal"]["doneButton"](),
      title: (args: { entityDisplayName: Arg }): string =>
        lang["tag"]["refAddressModal"]["title"](args) as string,
    },
    startTypingName: lang["tag"]["startTypingName"](),
    thisAddress: lang["tag"]["thisAddress"](),
  },
  taxAuthorityByCountry: {
    AD: lang["taxAuthorityByCountry"]["AD"](),
    AE: lang["taxAuthorityByCountry"]["AE"](),
    AF: lang["taxAuthorityByCountry"]["AF"](),
    AG: lang["taxAuthorityByCountry"]["AG"](),
    AI: lang["taxAuthorityByCountry"]["AI"](),
    AL: lang["taxAuthorityByCountry"]["AL"](),
    AM: lang["taxAuthorityByCountry"]["AM"](),
    AO: lang["taxAuthorityByCountry"]["AO"](),
    AR: lang["taxAuthorityByCountry"]["AR"](),
    AS: lang["taxAuthorityByCountry"]["AS"](),
    AT: lang["taxAuthorityByCountry"]["AT"](),
    AU: lang["taxAuthorityByCountry"]["AU"](),
    AW: lang["taxAuthorityByCountry"]["AW"](),
    AZ: lang["taxAuthorityByCountry"]["AZ"](),
    BA: lang["taxAuthorityByCountry"]["BA"](),
    BB: lang["taxAuthorityByCountry"]["BB"](),
    BD: lang["taxAuthorityByCountry"]["BD"](),
    BE: lang["taxAuthorityByCountry"]["BE"](),
    BF: lang["taxAuthorityByCountry"]["BF"](),
    BG: lang["taxAuthorityByCountry"]["BG"](),
    BH: lang["taxAuthorityByCountry"]["BH"](),
    BI: lang["taxAuthorityByCountry"]["BI"](),
    BJ: lang["taxAuthorityByCountry"]["BJ"](),
    BM: lang["taxAuthorityByCountry"]["BM"](),
    BN: lang["taxAuthorityByCountry"]["BN"](),
    BO: lang["taxAuthorityByCountry"]["BO"](),
    BQ: lang["taxAuthorityByCountry"]["BQ"](),
    BR: lang["taxAuthorityByCountry"]["BR"](),
    BS: lang["taxAuthorityByCountry"]["BS"](),
    BT: lang["taxAuthorityByCountry"]["BT"](),
    BV: lang["taxAuthorityByCountry"]["BV"](),
    BW: lang["taxAuthorityByCountry"]["BW"](),
    BY: lang["taxAuthorityByCountry"]["BY"](),
    BZ: lang["taxAuthorityByCountry"]["BZ"](),
    CA: lang["taxAuthorityByCountry"]["CA"](),
    CF: lang["taxAuthorityByCountry"]["CF"](),
    CG: lang["taxAuthorityByCountry"]["CG"](),
    CH: lang["taxAuthorityByCountry"]["CH"](),
    CI: lang["taxAuthorityByCountry"]["CI"](),
    CL: lang["taxAuthorityByCountry"]["CL"](),
    CM: lang["taxAuthorityByCountry"]["CM"](),
    CN: lang["taxAuthorityByCountry"]["CN"](),
    CO: lang["taxAuthorityByCountry"]["CO"](),
    CR: lang["taxAuthorityByCountry"]["CR"](),
    CU: lang["taxAuthorityByCountry"]["CU"](),
    CV: lang["taxAuthorityByCountry"]["CV"](),
    CW: lang["taxAuthorityByCountry"]["CW"](),
    CY: lang["taxAuthorityByCountry"]["CY"](),
    CZ: lang["taxAuthorityByCountry"]["CZ"](),
    DE: lang["taxAuthorityByCountry"]["DE"](),
    DJ: lang["taxAuthorityByCountry"]["DJ"](),
    DK: lang["taxAuthorityByCountry"]["DK"](),
    DM: lang["taxAuthorityByCountry"]["DM"](),
    DO: lang["taxAuthorityByCountry"]["DO"](),
    DZ: lang["taxAuthorityByCountry"]["DZ"](),
    EC: lang["taxAuthorityByCountry"]["EC"](),
    EE: lang["taxAuthorityByCountry"]["EE"](),
    EG: lang["taxAuthorityByCountry"]["EG"](),
    ER: lang["taxAuthorityByCountry"]["ER"](),
    ES: lang["taxAuthorityByCountry"]["ES"](),
    ET: lang["taxAuthorityByCountry"]["ET"](),
    FI: lang["taxAuthorityByCountry"]["FI"](),
    FJ: lang["taxAuthorityByCountry"]["FJ"](),
    FK: lang["taxAuthorityByCountry"]["FK"](),
    FM: lang["taxAuthorityByCountry"]["FM"](),
    FO: lang["taxAuthorityByCountry"]["FO"](),
    FR: lang["taxAuthorityByCountry"]["FR"](),
    GA: lang["taxAuthorityByCountry"]["GA"](),
    GB: lang["taxAuthorityByCountry"]["GB"](),
    GD: lang["taxAuthorityByCountry"]["GD"](),
    GE: lang["taxAuthorityByCountry"]["GE"](),
    GF: lang["taxAuthorityByCountry"]["GF"](),
    GH: lang["taxAuthorityByCountry"]["GH"](),
    GI: lang["taxAuthorityByCountry"]["GI"](),
    GL: lang["taxAuthorityByCountry"]["GL"](),
    GM: lang["taxAuthorityByCountry"]["GM"](),
    GN: lang["taxAuthorityByCountry"]["GN"](),
    GP: lang["taxAuthorityByCountry"]["GP"](),
    GQ: lang["taxAuthorityByCountry"]["GQ"](),
    GR: lang["taxAuthorityByCountry"]["GR"](),
    GT: lang["taxAuthorityByCountry"]["GT"](),
    GU: lang["taxAuthorityByCountry"]["GU"](),
    GW: lang["taxAuthorityByCountry"]["GW"](),
    GY: lang["taxAuthorityByCountry"]["GY"](),
    HK: lang["taxAuthorityByCountry"]["HK"](),
    HN: lang["taxAuthorityByCountry"]["HN"](),
    HR: lang["taxAuthorityByCountry"]["HR"](),
    HT: lang["taxAuthorityByCountry"]["HT"](),
    HU: lang["taxAuthorityByCountry"]["HU"](),
    ID: lang["taxAuthorityByCountry"]["ID"](),
    IE: lang["taxAuthorityByCountry"]["IE"](),
    IL: lang["taxAuthorityByCountry"]["IL"](),
    IM: lang["taxAuthorityByCountry"]["IM"](),
    IN: lang["taxAuthorityByCountry"]["IN"](),
    IR: lang["taxAuthorityByCountry"]["IR"](),
    IS: lang["taxAuthorityByCountry"]["IS"](),
    IT: lang["taxAuthorityByCountry"]["IT"](),
    JE: lang["taxAuthorityByCountry"]["JE"](),
    JM: lang["taxAuthorityByCountry"]["JM"](),
    JO: lang["taxAuthorityByCountry"]["JO"](),
    JP: lang["taxAuthorityByCountry"]["JP"](),
    KE: lang["taxAuthorityByCountry"]["KE"](),
    KG: lang["taxAuthorityByCountry"]["KG"](),
    KH: lang["taxAuthorityByCountry"]["KH"](),
    KI: lang["taxAuthorityByCountry"]["KI"](),
    KM: lang["taxAuthorityByCountry"]["KM"](),
    KN: lang["taxAuthorityByCountry"]["KN"](),
    KP: lang["taxAuthorityByCountry"]["KP"](),
    KR: lang["taxAuthorityByCountry"]["KR"](),
    KW: lang["taxAuthorityByCountry"]["KW"](),
    KY: lang["taxAuthorityByCountry"]["KY"](),
    KZ: lang["taxAuthorityByCountry"]["KZ"](),
    LA: lang["taxAuthorityByCountry"]["LA"](),
    LB: lang["taxAuthorityByCountry"]["LB"](),
    LC: lang["taxAuthorityByCountry"]["LC"](),
    LI: lang["taxAuthorityByCountry"]["LI"](),
    LK: lang["taxAuthorityByCountry"]["LK"](),
    LR: lang["taxAuthorityByCountry"]["LR"](),
    LS: lang["taxAuthorityByCountry"]["LS"](),
    LT: lang["taxAuthorityByCountry"]["LT"](),
    LU: lang["taxAuthorityByCountry"]["LU"](),
    LV: lang["taxAuthorityByCountry"]["LV"](),
    LY: lang["taxAuthorityByCountry"]["LY"](),
    MA: lang["taxAuthorityByCountry"]["MA"](),
    MC: lang["taxAuthorityByCountry"]["MC"](),
    MD: lang["taxAuthorityByCountry"]["MD"](),
    ME: lang["taxAuthorityByCountry"]["ME"](),
    MF: lang["taxAuthorityByCountry"]["MF"](),
    MG: lang["taxAuthorityByCountry"]["MG"](),
    MH: lang["taxAuthorityByCountry"]["MH"](),
    MK: lang["taxAuthorityByCountry"]["MK"](),
    ML: lang["taxAuthorityByCountry"]["ML"](),
    MM: lang["taxAuthorityByCountry"]["MM"](),
    MN: lang["taxAuthorityByCountry"]["MN"](),
    MO: lang["taxAuthorityByCountry"]["MO"](),
    MP: lang["taxAuthorityByCountry"]["MP"](),
    MQ: lang["taxAuthorityByCountry"]["MQ"](),
    MR: lang["taxAuthorityByCountry"]["MR"](),
    MS: lang["taxAuthorityByCountry"]["MS"](),
    MT: lang["taxAuthorityByCountry"]["MT"](),
    MU: lang["taxAuthorityByCountry"]["MU"](),
    MV: lang["taxAuthorityByCountry"]["MV"](),
    MW: lang["taxAuthorityByCountry"]["MW"](),
    MX: lang["taxAuthorityByCountry"]["MX"](),
    MY: lang["taxAuthorityByCountry"]["MY"](),
    MZ: lang["taxAuthorityByCountry"]["MZ"](),
    NA: lang["taxAuthorityByCountry"]["NA"](),
    NC: lang["taxAuthorityByCountry"]["NC"](),
    NE: lang["taxAuthorityByCountry"]["NE"](),
    NG: lang["taxAuthorityByCountry"]["NG"](),
    NI: lang["taxAuthorityByCountry"]["NI"](),
    NL: lang["taxAuthorityByCountry"]["NL"](),
    NO: lang["taxAuthorityByCountry"]["NO"](),
    NP: lang["taxAuthorityByCountry"]["NP"](),
    NR: lang["taxAuthorityByCountry"]["NR"](),
    NU: lang["taxAuthorityByCountry"]["NU"](),
    NZ: lang["taxAuthorityByCountry"]["NZ"](),
    OM: lang["taxAuthorityByCountry"]["OM"](),
    PA: lang["taxAuthorityByCountry"]["PA"](),
    PE: lang["taxAuthorityByCountry"]["PE"](),
    PF: lang["taxAuthorityByCountry"]["PF"](),
    PG: lang["taxAuthorityByCountry"]["PG"](),
    PH: lang["taxAuthorityByCountry"]["PH"](),
    PK: lang["taxAuthorityByCountry"]["PK"](),
    PL: lang["taxAuthorityByCountry"]["PL"](),
    PM: lang["taxAuthorityByCountry"]["PM"](),
    PR: lang["taxAuthorityByCountry"]["PR"](),
    PS: lang["taxAuthorityByCountry"]["PS"](),
    PT: lang["taxAuthorityByCountry"]["PT"](),
    PW: lang["taxAuthorityByCountry"]["PW"](),
    PY: lang["taxAuthorityByCountry"]["PY"](),
    QA: lang["taxAuthorityByCountry"]["QA"](),
    RE: lang["taxAuthorityByCountry"]["RE"](),
    RO: lang["taxAuthorityByCountry"]["RO"](),
    RS: lang["taxAuthorityByCountry"]["RS"](),
    RU: lang["taxAuthorityByCountry"]["RU"](),
    RW: lang["taxAuthorityByCountry"]["RW"](),
    SA: lang["taxAuthorityByCountry"]["SA"](),
    SB: lang["taxAuthorityByCountry"]["SB"](),
    SC: lang["taxAuthorityByCountry"]["SC"](),
    SD: lang["taxAuthorityByCountry"]["SD"](),
    SE: lang["taxAuthorityByCountry"]["SE"](),
    SG: lang["taxAuthorityByCountry"]["SG"](),
    SI: lang["taxAuthorityByCountry"]["SI"](),
    SK: lang["taxAuthorityByCountry"]["SK"](),
    SL: lang["taxAuthorityByCountry"]["SL"](),
    SM: lang["taxAuthorityByCountry"]["SM"](),
    SN: lang["taxAuthorityByCountry"]["SN"](),
    SO: lang["taxAuthorityByCountry"]["SO"](),
    SR: lang["taxAuthorityByCountry"]["SR"](),
    SS: lang["taxAuthorityByCountry"]["SS"](),
    SV: lang["taxAuthorityByCountry"]["SV"](),
    SX: lang["taxAuthorityByCountry"]["SX"](),
    SY: lang["taxAuthorityByCountry"]["SY"](),
    SZ: lang["taxAuthorityByCountry"]["SZ"](),
    TC: lang["taxAuthorityByCountry"]["TC"](),
    TD: lang["taxAuthorityByCountry"]["TD"](),
    TG: lang["taxAuthorityByCountry"]["TG"](),
    TH: lang["taxAuthorityByCountry"]["TH"](),
    TJ: lang["taxAuthorityByCountry"]["TJ"](),
    TL: lang["taxAuthorityByCountry"]["TL"](),
    TM: lang["taxAuthorityByCountry"]["TM"](),
    TN: lang["taxAuthorityByCountry"]["TN"](),
    TO: lang["taxAuthorityByCountry"]["TO"](),
    TR: lang["taxAuthorityByCountry"]["TR"](),
    TT: lang["taxAuthorityByCountry"]["TT"](),
    TV: lang["taxAuthorityByCountry"]["TV"](),
    TW: lang["taxAuthorityByCountry"]["TW"](),
    TZ: lang["taxAuthorityByCountry"]["TZ"](),
    UA: lang["taxAuthorityByCountry"]["UA"](),
    UG: lang["taxAuthorityByCountry"]["UG"](),
    UK: lang["taxAuthorityByCountry"]["UK"](),
    US: lang["taxAuthorityByCountry"]["US"](),
    UY: lang["taxAuthorityByCountry"]["UY"](),
    UZ: lang["taxAuthorityByCountry"]["UZ"](),
    VA: lang["taxAuthorityByCountry"]["VA"](),
    VC: lang["taxAuthorityByCountry"]["VC"](),
    VE: lang["taxAuthorityByCountry"]["VE"](),
    VG: lang["taxAuthorityByCountry"]["VG"](),
    VI: lang["taxAuthorityByCountry"]["VI"](),
    VN: lang["taxAuthorityByCountry"]["VN"](),
    VU: lang["taxAuthorityByCountry"]["VU"](),
    WF: lang["taxAuthorityByCountry"]["WF"](),
    WS: lang["taxAuthorityByCountry"]["WS"](),
    YE: lang["taxAuthorityByCountry"]["YE"](),
    YT: lang["taxAuthorityByCountry"]["YT"](),
    ZA: lang["taxAuthorityByCountry"]["ZA"](),
    ZM: lang["taxAuthorityByCountry"]["ZM"](),
    ZW: lang["taxAuthorityByCountry"]["ZW"](),
  },
  taxDivision: {
    buy: lang["taxDivision"]["buy"](),
    disposal: lang["taxDivision"]["disposal"](),
    group: lang["taxDivision"]["group"](),
    hidden: lang["taxDivision"]["hidden"](),
    ignore: lang["taxDivision"]["ignore"](),
    income: lang["taxDivision"]["income"](),
    lastUsed: lang["taxDivision"]["lastUsed"](),
    payment: lang["taxDivision"]["payment"](),
    transfer: lang["taxDivision"]["transfer"](),
    withdrawal: lang["taxDivision"]["withdrawal"](),
  },
  taxLossHarvesting: {
    errors: {
      unableToLoad: lang["taxLossHarvesting"]["errors"]["unableToLoad"](),
    },
    gain: lang["taxLossHarvesting"]["gain"](),
    iconTooltips: {
      editPrice: lang["taxLossHarvesting"]["iconTooltips"]["editPrice"](),
      refreshPrice: lang["taxLossHarvesting"]["iconTooltips"]["refreshPrice"](),
      resetPrice: lang["taxLossHarvesting"]["iconTooltips"]["resetPrice"](),
      savePrice: lang["taxLossHarvesting"]["iconTooltips"]["savePrice"](),
    },
    open: lang["taxLossHarvesting"]["open"](),
    openWhenNoLosses: lang["taxLossHarvesting"]["openWhenNoLosses"](),
    paywallModal: {
      aboutLTFO: lang["taxLossHarvesting"]["paywallModal"]["aboutLTFO"](),
      aboutTaxLossHarvesting:
        lang["taxLossHarvesting"]["paywallModal"]["aboutTaxLossHarvesting"](),
      benefits: {
        lockIn:
          lang["taxLossHarvesting"]["paywallModal"]["benefits"]["lockIn"](),
        lockInNoCGT:
          lang["taxLossHarvesting"]["paywallModal"]["benefits"][
            "lockInNoCGT"
          ](),
        offset:
          lang["taxLossHarvesting"]["paywallModal"]["benefits"]["offset"](),
        offsetNoCGT:
          lang["taxLossHarvesting"]["paywallModal"]["benefits"][
            "offsetNoCGT"
          ](),
        optimize:
          lang["taxLossHarvesting"]["paywallModal"]["benefits"]["optimize"](),
      },
      subtitlePost: lang["taxLossHarvesting"]["paywallModal"]["subtitlePost"](),
      subtitlePre: lang["taxLossHarvesting"]["paywallModal"]["subtitlePre"](),
      title: lang["taxLossHarvesting"]["paywallModal"]["title"](),
      upgradePrompt:
        lang["taxLossHarvesting"]["paywallModal"]["upgradePrompt"](),
    },
    postHarvestCapitalGains:
      lang["taxLossHarvesting"]["postHarvestCapitalGains"](),
    postHarvestCapitalGainsNoCGT:
      lang["taxLossHarvesting"]["postHarvestCapitalGainsNoCGT"](),
    realisedCapitalGains: lang["taxLossHarvesting"]["realisedCapitalGains"](),
    realisedCapitalGainsNoCGT:
      lang["taxLossHarvesting"]["realisedCapitalGainsNoCGT"](),
    showAssetsWithoutMarketPrice:
      lang["taxLossHarvesting"]["showAssetsWithoutMarketPrice"](),
    summary: {
      netCapitalGainLoss:
        lang["taxLossHarvesting"]["summary"]["netCapitalGainLoss"](),
      netCapitalGainLossNoCGT:
        lang["taxLossHarvesting"]["summary"]["netCapitalGainLossNoCGT"](),
    },
    table: {
      loading: lang["taxLossHarvesting"]["table"]["loading"](),
      noHoldings: lang["taxLossHarvesting"]["table"]["noHoldings"](),
      rowSelectionDisabled:
        lang["taxLossHarvesting"]["table"]["rowSelectionDisabled"](),
      title: lang["taxLossHarvesting"]["table"]["title"](),
    },
    tableHeaders: {
      amountHeld: lang["taxLossHarvesting"]["tableHeaders"]["amountHeld"](),
      amountToSell: lang["taxLossHarvesting"]["tableHeaders"]["amountToSell"](),
      asset: lang["taxLossHarvesting"]["tableHeaders"]["asset"](),
      costBasis: lang["taxLossHarvesting"]["tableHeaders"]["costBasis"](),
      currentMarketValue:
        lang["taxLossHarvesting"]["tableHeaders"]["currentMarketValue"](),
      price: lang["taxLossHarvesting"]["tableHeaders"]["price"](),
      unrealizedGainsLosses:
        lang["taxLossHarvesting"]["tableHeaders"]["unrealizedGainsLosses"](),
    },
    tableSearch: lang["taxLossHarvesting"]["tableSearch"](),
    taxSavingOpportunities:
      lang["taxLossHarvesting"]["taxSavingOpportunities"](),
    title: lang["taxLossHarvesting"]["title"](),
    tooltips: {
      unrealizedGains:
        lang["taxLossHarvesting"]["tooltips"]["unrealizedGains"](),
    },
    viewTaxLosses: lang["taxLossHarvesting"]["viewTaxLosses"](),
  },
  taxRule: {
    "bed-and-breakfast": lang["taxRule"]["bed-and-breakfast"](),
    "fee-forwarded": lang["taxRule"]["fee-forwarded"](),
    "margin-fee": lang["taxRule"]["margin-fee"](),
    "recalculated-after-bed-and-breakfast":
      lang["taxRule"]["recalculated-after-bed-and-breakfast"](),
    "same-day": lang["taxRule"]["same-day"](),
    "superficial-loss-from-buy-back":
      lang["taxRule"]["superficial-loss-from-buy-back"](),
    "superficial-loss-from-sell":
      lang["taxRule"]["superficial-loss-from-sell"](),
    "zero-cost-buy": lang["taxRule"]["zero-cost-buy"](),
  },
  taxSettingBanner: {
    affectCalculationsWarning:
      lang["taxSettingBanner"]["affectCalculationsWarning"](),
    cta: lang["taxSettingBanner"]["cta"](),
    title: lang["taxSettingBanner"]["title"](),
  },
  taxToggles: lang["taxToggles"](),
  testKey: lang["testKey"](),
  theCounterParty: lang["theCounterParty"](),
  theme: {
    betaModal: {
      actionText: lang["theme"]["betaModal"]["actionText"](),
      content1: lang["theme"]["betaModal"]["content1"](),
      content2: lang["theme"]["betaModal"]["content2"](),
      title: lang["theme"]["betaModal"]["title"](),
    },
    short: {
      dark: lang["theme"]["short"]["dark"](),
      light: lang["theme"]["short"]["light"](),
      system: lang["theme"]["short"]["system"](),
    },
    toggle: {
      dark: lang["theme"]["toggle"]["dark"](),
      light: lang["theme"]["toggle"]["light"](),
      system: lang["theme"]["toggle"]["system"](),
    },
  },
  title: lang["title"](),
  to: lang["to"](),
  tokenLogin: {
    errorSubtitle: lang["tokenLogin"]["errorSubtitle"](),
    errorTitle: lang["tokenLogin"]["errorTitle"](),
    subtitle: lang["tokenLogin"]["subtitle"](),
    title: lang["tokenLogin"]["title"](),
    visitLogin: lang["tokenLogin"]["visitLogin"](),
  },
  topBanner: {
    coupon: lang["topBanner"]["coupon"](),
    msg: lang["topBanner"]["msg"](),
  },
  tradeType: {
    addLiquidity: lang["tradeType"]["addLiquidity"](),
    airdrop: lang["tradeType"]["airdrop"](),
    approval: lang["tradeType"]["approval"](),
    "arbitrary-uncategorised": lang["tradeType"]["arbitrary-uncategorised"](),
    borrow: lang["tradeType"]["borrow"](),
    bridge: lang["tradeType"]["bridge"](),
    "bridge-trade": lang["tradeType"]["bridge-trade"](),
    "bridge-transfer": lang["tradeType"]["bridge-transfer"](),
    bridgeIn: lang["tradeType"]["bridgeIn"](),
    bridgeOut: lang["tradeType"]["bridgeOut"](),
    bridgeTradeIn: lang["tradeType"]["bridgeTradeIn"](),
    bridgeTradeOut: lang["tradeType"]["bridgeTradeOut"](),
    bridgeTransferIn: lang["tradeType"]["bridgeTransferIn"](),
    bridgeTransferOut: lang["tradeType"]["bridgeTransferOut"](),
    burn: lang["tradeType"]["burn"](),
    buy: lang["tradeType"]["buy"](),
    cashback: lang["tradeType"]["cashback"](),
    chainSplit: lang["tradeType"]["chainSplit"](),
    collateral: lang["tradeType"]["collateral"](),
    collateralWithdrawal: lang["tradeType"]["collateralWithdrawal"](),
    "decrease-position": lang["tradeType"]["decrease-position"](),
    decreasePositionDeposit: lang["tradeType"]["decreasePositionDeposit"](),
    deposit: lang["tradeType"]["deposit"](),
    derivatives: lang["tradeType"]["derivatives"](),
    expense: lang["tradeType"]["expense"](),
    failed: lang["tradeType"]["failed"](),
    failedIn: lang["tradeType"]["failedIn"](),
    failedOut: lang["tradeType"]["failedOut"](),
    fee: lang["tradeType"]["fee"](),
    fiat: lang["tradeType"]["fiat"](),
    "fiat-transfer": lang["tradeType"]["fiat-transfer"](),
    fiatDeposit: lang["tradeType"]["fiatDeposit"](),
    fiatWithdrawal: lang["tradeType"]["fiatWithdrawal"](),
    gift: lang["tradeType"]["gift"](),
    gifts: lang["tradeType"]["gifts"](),
    ignoreIn: lang["tradeType"]["ignoreIn"](),
    ignoreOut: lang["tradeType"]["ignoreOut"](),
    ignored: lang["tradeType"]["ignored"](),
    in: lang["tradeType"]["in"](),
    income: lang["tradeType"]["income"](),
    "increase-position": lang["tradeType"]["increase-position"](),
    increasePositionWithdrawal:
      lang["tradeType"]["increasePositionWithdrawal"](),
    interest: lang["tradeType"]["interest"](),
    liquidate: lang["tradeType"]["liquidate"](),
    liquidity: lang["tradeType"]["liquidity"](),
    loan: lang["tradeType"]["loan"](),
    loanRepayment: lang["tradeType"]["loanRepayment"](),
    long: lang["tradeType"]["long"](),
    lost: lang["tradeType"]["lost"](),
    marginFee: lang["tradeType"]["marginFee"](),
    mining: lang["tradeType"]["mining"](),
    mint: lang["tradeType"]["mint"](),
    "multi-mint": lang["tradeType"]["multi-mint"](),
    "multi-trade": lang["tradeType"]["multi-trade"](),
    notAvailable: lang["tradeType"]["notAvailable"](),
    out: lang["tradeType"]["out"](),
    outgoingGift: lang["tradeType"]["outgoingGift"](),
    personalUse: lang["tradeType"]["personalUse"](),
    "provide-liquidity-trade": lang["tradeType"]["provide-liquidity-trade"](),
    realisedLoss: lang["tradeType"]["realisedLoss"](),
    realisedProfit: lang["tradeType"]["realisedProfit"](),
    receivePQ: lang["tradeType"]["receivePQ"](),
    receivingLiquidityProviderToken:
      lang["tradeType"]["receivingLiquidityProviderToken"](),
    "remove-liquidity-trade": lang["tradeType"]["remove-liquidity-trade"](),
    removeLiquidity: lang["tradeType"]["removeLiquidity"](),
    returningLiquidityProviderToken:
      lang["tradeType"]["returningLiquidityProviderToken"](),
    reward: lang["tradeType"]["reward"](),
    royalty: lang["tradeType"]["royalty"](),
    sell: lang["tradeType"]["sell"](),
    sendPQ: lang["tradeType"]["sendPQ"](),
    short: lang["tradeType"]["short"](),
    spamIn: lang["tradeType"]["spamIn"](),
    "stake-deposit-group": lang["tradeType"]["stake-deposit-group"](),
    "stake-withdrawal-group": lang["tradeType"]["stake-withdrawal-group"](),
    staking: lang["tradeType"]["staking"](),
    stakingDeposit: lang["tradeType"]["stakingDeposit"](),
    stakingWithdrawal: lang["tradeType"]["stakingWithdrawal"](),
    stakings: lang["tradeType"]["stakings"](),
    stolen: lang["tradeType"]["stolen"](),
    swap: lang["tradeType"]["swap"](),
    swapIn: lang["tradeType"]["swapIn"](),
    swapOut: lang["tradeType"]["swapOut"](),
    swaps: lang["tradeType"]["swaps"](),
    trade: lang["tradeType"]["trade"](),
    transfer: lang["tradeType"]["transfer"](),
    uncategorised: lang["tradeType"]["uncategorised"](),
    unknown: lang["tradeType"]["unknown"](),
    withdrawal: lang["tradeType"]["withdrawal"](),
  },
  tradeTypeCombined: {
    borrow_loanRepayment: lang["tradeTypeCombined"]["borrow_loanRepayment"](),
    bridgeIn_bridgeOut: lang["tradeTypeCombined"]["bridgeIn_bridgeOut"](),
    bridgeTradeIn_bridgeTradeOut:
      lang["tradeTypeCombined"]["bridgeTradeIn_bridgeTradeOut"](),
    bridgeTransferIn_bridgeTransferOut:
      lang["tradeTypeCombined"]["bridgeTransferIn_bridgeTransferOut"](),
    buy_sell: lang["tradeTypeCombined"]["buy_sell"](),
    collateralWithdrawal_collateral:
      lang["tradeTypeCombined"]["collateralWithdrawal_collateral"](),
    failedIn_failedOut: lang["tradeTypeCombined"]["failedIn_failedOut"](),
    fiatDeposit_fiatWithdrawal:
      lang["tradeTypeCombined"]["fiatDeposit_fiatWithdrawal"](),
    ignoreIn_ignoreOut: lang["tradeTypeCombined"]["ignoreIn_ignoreOut"](),
    receive_send: lang["tradeTypeCombined"]["receive_send"](),
    receivingLiquidityProviderToken_stakingDeposit:
      lang["tradeTypeCombined"][
        "receivingLiquidityProviderToken_stakingDeposit"
      ](),
    removeLiquidity_addLiquidity:
      lang["tradeTypeCombined"]["removeLiquidity_addLiquidity"](),
    returningLiquidityProviderToken_stakingWithdrawal:
      lang["tradeTypeCombined"][
        "returningLiquidityProviderToken_stakingWithdrawal"
      ](),
    stakingWithdrawal_stakingDeposit:
      lang["tradeTypeCombined"]["stakingWithdrawal_stakingDeposit"](),
  },
  tradeTypeGroup: {
    acquisition: {
      description: lang["tradeTypeGroup"]["acquisition"]["description"](),
      label: lang["tradeTypeGroup"]["acquisition"]["label"](),
    },
    derivatives: {
      description: lang["tradeTypeGroup"]["derivatives"]["description"](),
      label: lang["tradeTypeGroup"]["derivatives"]["label"](),
    },
    disposal: {
      description: lang["tradeTypeGroup"]["disposal"]["description"](),
      label: lang["tradeTypeGroup"]["disposal"]["label"](),
    },
    expenses: {
      description: lang["tradeTypeGroup"]["expenses"]["description"](),
      label: lang["tradeTypeGroup"]["expenses"]["label"](),
    },
    "fiat-transfer": {
      description: lang["tradeTypeGroup"]["fiat-transfer"]["description"](),
      label: lang["tradeTypeGroup"]["fiat-transfer"]["label"](),
    },
    ignore: {
      description: lang["tradeTypeGroup"]["ignore"]["description"](),
      label: lang["tradeTypeGroup"]["ignore"]["label"](),
    },
    income: {
      description: lang["tradeTypeGroup"]["income"]["description"](),
      label: lang["tradeTypeGroup"]["income"]["label"](),
    },
    loss: {
      description: lang["tradeTypeGroup"]["loss"]["description"](),
      label: lang["tradeTypeGroup"]["loss"]["label"](),
    },
    transfer: {
      description: lang["tradeTypeGroup"]["transfer"]["description"](),
      label: lang["tradeTypeGroup"]["transfer"]["label"](),
    },
  },
  tradeTypeTaxDescription: {
    addLiquidity: lang["tradeTypeTaxDescription"]["addLiquidity"](),
    airdrop: lang["tradeTypeTaxDescription"]["airdrop"](),
    approval: lang["tradeTypeTaxDescription"]["approval"](),
    "arbitrary-uncategorised":
      lang["tradeTypeTaxDescription"]["arbitrary-uncategorised"](),
    borrow: lang["tradeTypeTaxDescription"]["borrow"](),
    "bridge-trade": lang["tradeTypeTaxDescription"]["bridge-trade"](),
    "bridge-transfer": lang["tradeTypeTaxDescription"]["bridge-transfer"](),
    bridgeIn: lang["tradeTypeTaxDescription"]["bridgeIn"](),
    bridgeOut: lang["tradeTypeTaxDescription"]["bridgeOut"](),
    bridgeTradeIn: lang["tradeTypeTaxDescription"]["bridgeTradeIn"](),
    bridgeTradeOut: lang["tradeTypeTaxDescription"]["bridgeTradeOut"](),
    bridgeTransferIn: lang["tradeTypeTaxDescription"]["bridgeTransferIn"](),
    bridgeTransferOut: lang["tradeTypeTaxDescription"]["bridgeTransferOut"](),
    burn: lang["tradeTypeTaxDescription"]["burn"](),
    buy: lang["tradeTypeTaxDescription"]["buy"](),
    cashback: lang["tradeTypeTaxDescription"]["cashback"](),
    chainSplit: lang["tradeTypeTaxDescription"]["chainSplit"](),
    collateral: lang["tradeTypeTaxDescription"]["collateral"](),
    collateralWithdrawal:
      lang["tradeTypeTaxDescription"]["collateralWithdrawal"](),
    "decrease-position": lang["tradeTypeTaxDescription"]["decrease-position"](),
    decreasePositionDeposit:
      lang["tradeTypeTaxDescription"]["decreasePositionDeposit"](),
    deposit: lang["tradeTypeTaxDescription"]["deposit"](),
    expense: lang["tradeTypeTaxDescription"]["expense"](),
    fee: lang["tradeTypeTaxDescription"]["fee"](),
    "fiat-transfer": lang["tradeTypeTaxDescription"]["fiat-transfer"](),
    fiatDeposit: lang["tradeTypeTaxDescription"]["fiatDeposit"](),
    fiatWithdrawal: lang["tradeTypeTaxDescription"]["fiatWithdrawal"](),
    gift: lang["tradeTypeTaxDescription"]["gift"](),
    in: lang["tradeTypeTaxDescription"]["in"](),
    income: lang["tradeTypeTaxDescription"]["income"](),
    "increase-position": lang["tradeTypeTaxDescription"]["increase-position"](),
    increasePositionWithdrawal:
      lang["tradeTypeTaxDescription"]["increasePositionWithdrawal"](),
    interest: lang["tradeTypeTaxDescription"]["interest"](),
    liquidate: lang["tradeTypeTaxDescription"]["liquidate"](),
    loanRepayment: lang["tradeTypeTaxDescription"]["loanRepayment"](),
    lost: lang["tradeTypeTaxDescription"]["lost"](),
    marginFee: lang["tradeTypeTaxDescription"]["marginFee"](),
    mint: lang["tradeTypeTaxDescription"]["mint"](),
    "multi-mint": lang["tradeTypeTaxDescription"]["multi-mint"](),
    "multi-trade": lang["tradeTypeTaxDescription"]["multi-trade"](),
    out: lang["tradeTypeTaxDescription"]["out"](),
    outgoingGift: lang["tradeTypeTaxDescription"]["outgoingGift"](),
    personalUse: lang["tradeTypeTaxDescription"]["personalUse"](),
    "provide-liquidity-trade":
      lang["tradeTypeTaxDescription"]["provide-liquidity-trade"](),
    realisedLoss: lang["tradeTypeTaxDescription"]["realisedLoss"](),
    realisedProfit: lang["tradeTypeTaxDescription"]["realisedProfit"](),
    receivePQ: lang["tradeTypeTaxDescription"]["receivePQ"](),
    receivingLiquidityProviderToken:
      lang["tradeTypeTaxDescription"]["receivingLiquidityProviderToken"](),
    "remove-liquidity-trade":
      lang["tradeTypeTaxDescription"]["remove-liquidity-trade"](),
    removeLiquidity: lang["tradeTypeTaxDescription"]["removeLiquidity"](),
    returningLiquidityProviderToken:
      lang["tradeTypeTaxDescription"]["returningLiquidityProviderToken"](),
    royalty: lang["tradeTypeTaxDescription"]["royalty"](),
    sell: lang["tradeTypeTaxDescription"]["sell"](),
    sendPQ: lang["tradeTypeTaxDescription"]["sendPQ"](),
    staking: lang["tradeTypeTaxDescription"]["staking"](),
    stakingDeposit: lang["tradeTypeTaxDescription"]["stakingDeposit"](),
    stakingWithdrawal: lang["tradeTypeTaxDescription"]["stakingWithdrawal"](),
    stolen: lang["tradeTypeTaxDescription"]["stolen"](),
    swap: lang["tradeTypeTaxDescription"]["swap"](),
    trade: lang["tradeTypeTaxDescription"]["trade"](),
    transfer: lang["tradeTypeTaxDescription"]["transfer"](),
    withdrawal: lang["tradeTypeTaxDescription"]["withdrawal"](),
  },
  tradeTypeTaxDescriptionExpanded: {
    addLiquidity: lang["tradeTypeTaxDescriptionExpanded"]["addLiquidity"](),
    airdrop: lang["tradeTypeTaxDescriptionExpanded"]["airdrop"](),
    approval: lang["tradeTypeTaxDescriptionExpanded"]["approval"](),
    "arbitrary-uncategorised":
      lang["tradeTypeTaxDescriptionExpanded"]["arbitrary-uncategorised"](),
    borrow: lang["tradeTypeTaxDescriptionExpanded"]["borrow"](),
    "bridge-trade": lang["tradeTypeTaxDescriptionExpanded"]["bridge-trade"](),
    "bridge-transfer":
      lang["tradeTypeTaxDescriptionExpanded"]["bridge-transfer"](),
    bridgeIn: lang["tradeTypeTaxDescriptionExpanded"]["bridgeIn"](),
    bridgeOut: lang["tradeTypeTaxDescriptionExpanded"]["bridgeOut"](),
    bridgeTradeIn: lang["tradeTypeTaxDescriptionExpanded"]["bridgeTradeIn"](),
    bridgeTradeOut: lang["tradeTypeTaxDescriptionExpanded"]["bridgeTradeOut"](),
    bridgeTransferIn:
      lang["tradeTypeTaxDescriptionExpanded"]["bridgeTransferIn"](),
    bridgeTransferOut:
      lang["tradeTypeTaxDescriptionExpanded"]["bridgeTransferOut"](),
    burn: lang["tradeTypeTaxDescriptionExpanded"]["burn"](),
    buy: lang["tradeTypeTaxDescriptionExpanded"]["buy"](),
    cashback: lang["tradeTypeTaxDescriptionExpanded"]["cashback"](),
    chainSplit: lang["tradeTypeTaxDescriptionExpanded"]["chainSplit"](),
    collateral: lang["tradeTypeTaxDescriptionExpanded"]["collateral"](),
    collateralWithdrawal:
      lang["tradeTypeTaxDescriptionExpanded"]["collateralWithdrawal"](),
    "decrease-position":
      lang["tradeTypeTaxDescriptionExpanded"]["decrease-position"](),
    decreasePositionDeposit:
      lang["tradeTypeTaxDescriptionExpanded"]["decreasePositionDeposit"](),
    deposit: lang["tradeTypeTaxDescriptionExpanded"]["deposit"](),
    expense: lang["tradeTypeTaxDescriptionExpanded"]["expense"](),
    failedOut: lang["tradeTypeTaxDescriptionExpanded"]["failedOut"](),
    fee: lang["tradeTypeTaxDescriptionExpanded"]["fee"](),
    "fiat-transfer": lang["tradeTypeTaxDescriptionExpanded"]["fiat-transfer"](),
    fiatDeposit: lang["tradeTypeTaxDescriptionExpanded"]["fiatDeposit"](),
    fiatWithdrawal: lang["tradeTypeTaxDescriptionExpanded"]["fiatWithdrawal"](),
    gift: lang["tradeTypeTaxDescriptionExpanded"]["gift"](),
    ignoreIn: lang["tradeTypeTaxDescriptionExpanded"]["ignoreIn"](),
    ignoreOut: lang["tradeTypeTaxDescriptionExpanded"]["ignoreOut"](),
    in: lang["tradeTypeTaxDescriptionExpanded"]["in"](),
    income: lang["tradeTypeTaxDescriptionExpanded"]["income"](),
    "increase-position":
      lang["tradeTypeTaxDescriptionExpanded"]["increase-position"](),
    increasePositionWithdrawal:
      lang["tradeTypeTaxDescriptionExpanded"]["increasePositionWithdrawal"](),
    interest: lang["tradeTypeTaxDescriptionExpanded"]["interest"](),
    liquidate: lang["tradeTypeTaxDescriptionExpanded"]["liquidate"](),
    loanRepayment: lang["tradeTypeTaxDescriptionExpanded"]["loanRepayment"](),
    lost: lang["tradeTypeTaxDescriptionExpanded"]["lost"](),
    marginFee: lang["tradeTypeTaxDescriptionExpanded"]["marginFee"](),
    mining: lang["tradeTypeTaxDescriptionExpanded"]["mining"](),
    mint: lang["tradeTypeTaxDescriptionExpanded"]["mint"](),
    "multi-mint": lang["tradeTypeTaxDescriptionExpanded"]["multi-mint"](),
    "multi-trade": lang["tradeTypeTaxDescriptionExpanded"]["multi-trade"](),
    out: lang["tradeTypeTaxDescriptionExpanded"]["out"](),
    outgoingGift: lang["tradeTypeTaxDescriptionExpanded"]["outgoingGift"](),
    personalUse: lang["tradeTypeTaxDescriptionExpanded"]["personalUse"](),
    "provide-liquidity-trade":
      lang["tradeTypeTaxDescriptionExpanded"]["provide-liquidity-trade"](),
    realisedLoss: lang["tradeTypeTaxDescriptionExpanded"]["realisedLoss"](),
    realisedProfit: lang["tradeTypeTaxDescriptionExpanded"]["realisedProfit"](),
    receivePQ: lang["tradeTypeTaxDescriptionExpanded"]["receivePQ"](),
    receivingLiquidityProviderToken:
      lang["tradeTypeTaxDescriptionExpanded"][
        "receivingLiquidityProviderToken"
      ](),
    "remove-liquidity-trade":
      lang["tradeTypeTaxDescriptionExpanded"]["remove-liquidity-trade"](),
    removeLiquidity:
      lang["tradeTypeTaxDescriptionExpanded"]["removeLiquidity"](),
    returningLiquidityProviderToken:
      lang["tradeTypeTaxDescriptionExpanded"][
        "returningLiquidityProviderToken"
      ](),
    royalty: lang["tradeTypeTaxDescriptionExpanded"]["royalty"](),
    sell: lang["tradeTypeTaxDescriptionExpanded"]["sell"](),
    sendPQ: lang["tradeTypeTaxDescriptionExpanded"]["sendPQ"](),
    spamIn: lang["tradeTypeTaxDescriptionExpanded"]["spamIn"](),
    "stake-deposit-group":
      lang["tradeTypeTaxDescriptionExpanded"]["stake-deposit-group"](),
    "stake-withdrawal-group":
      lang["tradeTypeTaxDescriptionExpanded"]["stake-withdrawal-group"](),
    staking: lang["tradeTypeTaxDescriptionExpanded"]["staking"](),
    stakingDeposit: lang["tradeTypeTaxDescriptionExpanded"]["stakingDeposit"](),
    stakingWithdrawal:
      lang["tradeTypeTaxDescriptionExpanded"]["stakingWithdrawal"](),
    stolen: lang["tradeTypeTaxDescriptionExpanded"]["stolen"](),
    swap: lang["tradeTypeTaxDescriptionExpanded"]["swap"](),
    swapIn: lang["tradeTypeTaxDescriptionExpanded"]["swapIn"](),
    swapOut: lang["tradeTypeTaxDescriptionExpanded"]["swapOut"](),
    trade: lang["tradeTypeTaxDescriptionExpanded"]["trade"](),
    transfer: lang["tradeTypeTaxDescriptionExpanded"]["transfer"](),
    withdrawal: lang["tradeTypeTaxDescriptionExpanded"]["withdrawal"](),
  },
  transactionEdit: {
    dialog: {
      actionButton: lang["transactionEdit"]["dialog"]["actionButton"](),
      body: lang["transactionEdit"]["dialog"]["body"](),
      cancel: lang["transactionEdit"]["dialog"]["cancel"](),
      title: lang["transactionEdit"]["dialog"]["title"](),
    },
    somethingWentWrong: lang["transactionEdit"]["somethingWentWrong"](),
    trade: lang["transactionEdit"]["trade"](),
    transaction: lang["transactionEdit"]["transaction"](),
    transfer: lang["transactionEdit"]["transfer"](),
  },
  transactionTableModal: {
    defaultTitle: (args: { count: Arg }): string =>
      lang["transactionTableModal"]["defaultTitle"](args) as string,
    viewTxTable: lang["transactionTableModal"]["viewTxTable"](),
  },
  transactions: {
    add: lang["transactions"]["add"](),
    deletedTheTxs: lang["transactions"]["deletedTheTxs"](),
    groups: {
      exchanges: lang["transactions"]["groups"]["exchanges"](),
      other: lang["transactions"]["groups"]["other"](),
      wallets: lang["transactions"]["groups"]["wallets"](),
    },
    partiallyDeleted: lang["transactions"]["partiallyDeleted"](),
    reconcile: (args: { count: Arg }): string =>
      lang["transactions"]["reconcile"](args) as string,
    title: lang["transactions"]["title"](),
    ungroup: {
      action: lang["transactions"]["ungroup"]["action"](),
      body1: lang["transactions"]["ungroup"]["body1"](),
      title: lang["transactions"]["ungroup"]["title"](),
    },
    updateTable: lang["transactions"]["updateTable"](),
    wentWrongDeleting: lang["transactions"]["wentWrongDeleting"](),
    wentWrongDeletingComment:
      lang["transactions"]["wentWrongDeletingComment"](),
    wentWrongDeletingTx: lang["transactions"]["wentWrongDeletingTx"](),
    wentWrongDuplicatingTx: lang["transactions"]["wentWrongDuplicatingTx"](),
    wentWrongFetching: lang["transactions"]["wentWrongFetching"](),
    wentWrongIgnoringWarning:
      lang["transactions"]["wentWrongIgnoringWarning"](),
    wentWrongLeavingNote: lang["transactions"]["wentWrongLeavingNote"](),
    wentWrongRecategorizing: lang["transactions"]["wentWrongRecategorizing"](),
    wentWrongRegrouping: lang["transactions"]["wentWrongRegrouping"](),
    wentWrongUngrouping: lang["transactions"]["wentWrongUngrouping"](),
    wentWrongUpdateCurrency: lang["transactions"]["wentWrongUpdateCurrency"](),
    wentWrongUpdatingTrade: lang["transactions"]["wentWrongUpdatingTrade"](),
    wentWrongUpdatingTx: lang["transactions"]["wentWrongUpdatingTx"](),
  },
  transferring: lang["transferring"](),
  tryAgain: lang["tryAgain"](),
  twoFactorAuth: {
    "2FA": lang["twoFactorAuth"]["2FA"](),
    authenticationCode: lang["twoFactorAuth"]["authenticationCode"](),
    authy: lang["twoFactorAuth"]["authy"](),
    disableError: lang["twoFactorAuth"]["disableError"](),
    disableSuccess: lang["twoFactorAuth"]["disableSuccess"](),
    disableTwoFactorAuth: lang["twoFactorAuth"]["disableTwoFactorAuth"](),
    done: lang["twoFactorAuth"]["done"](),
    download: lang["twoFactorAuth"]["download"](),
    enableError: lang["twoFactorAuth"]["enableError"](),
    enableSuccess: lang["twoFactorAuth"]["enableSuccess"](),
    enableTwoFactorAuth: lang["twoFactorAuth"]["enableTwoFactorAuth"](),
    enterCodeLabel: lang["twoFactorAuth"]["enterCodeLabel"](),
    enterCodeOrRecoveryCodeHelperText:
      lang["twoFactorAuth"]["enterCodeOrRecoveryCodeHelperText"](),
    enterCodeSubText: lang["twoFactorAuth"]["enterCodeSubText"](),
    enterCodeToChangePassword:
      lang["twoFactorAuth"]["enterCodeToChangePassword"](),
    enterCodeToDisable: lang["twoFactorAuth"]["enterCodeToDisable"](),
    enterCodeToRegenerate: lang["twoFactorAuth"]["enterCodeToRegenerate"](),
    enterYourPassword: lang["twoFactorAuth"]["enterYourPassword"](),
    googleAuthenticator: lang["twoFactorAuth"]["googleAuthenticator"](),
    manualCodeEntry: lang["twoFactorAuth"]["manualCodeEntry"](),
    or: lang["twoFactorAuth"]["or"](),
    password: lang["twoFactorAuth"]["password"](),
    passwordRequired: lang["twoFactorAuth"]["passwordRequired"](),
    qrCodeError: lang["twoFactorAuth"]["qrCodeError"](),
    qrCodeForTwoFactor: lang["twoFactorAuth"]["qrCodeForTwoFactor"](),
    recoveryCodes: lang["twoFactorAuth"]["recoveryCodes"](),
    recoveryCodesContent: lang["twoFactorAuth"]["recoveryCodesContent"](),
    recoveryCodesInfoBoxContent:
      lang["twoFactorAuth"]["recoveryCodesInfoBoxContent"](),
    recoveryCodesInfoBoxTitle:
      lang["twoFactorAuth"]["recoveryCodesInfoBoxTitle"](),
    regenerate: lang["twoFactorAuth"]["regenerate"](),
    regenerateError: lang["twoFactorAuth"]["regenerateError"](),
    regenerateRecoveryCodes: lang["twoFactorAuth"]["regenerateRecoveryCodes"](),
    regenerateSuccess: lang["twoFactorAuth"]["regenerateSuccess"](),
    scanQrCode: lang["twoFactorAuth"]["scanQrCode"](),
    scanWithApp: lang["twoFactorAuth"]["scanWithApp"](),
    staySignedIn: lang["twoFactorAuth"]["staySignedIn"](),
    submit: lang["twoFactorAuth"]["submit"](),
    twoStepVerification: lang["twoFactorAuth"]["twoStepVerification"](),
    verify: lang["twoFactorAuth"]["verify"](),
    verifyAuthenticationCode:
      lang["twoFactorAuth"]["verifyAuthenticationCode"](),
    verifyCode: lang["twoFactorAuth"]["verifyCode"](),
  },
  txLimitExceeded: lang["txLimitExceeded"](),
  txTable: {
    account: lang["txTable"]["account"](),
    accountBalance: {
      in: lang["txTable"]["accountBalance"]["in"](),
      out: lang["txTable"]["accountBalance"]["out"](),
    },
    add: lang["txTable"]["add"](),
    addComment: lang["txTable"]["addComment"](),
    addPrice: lang["txTable"]["addPrice"](),
    all: lang["txTable"]["all"](),
    allTxs: lang["txTable"]["allTxs"](),
    amount: lang["txTable"]["amount"](),
    assignMissingBlockchain: lang["txTable"]["assignMissingBlockchain"](),
    assumedTransfer: lang["txTable"]["assumedTransfer"](),
    balanceRemainingInfo: {
      account: lang["txTable"]["balanceRemainingInfo"]["account"](),
      change: lang["txTable"]["balanceRemainingInfo"]["change"](),
      currency: lang["txTable"]["balanceRemainingInfo"]["currency"](),
      endingBalance: lang["txTable"]["balanceRemainingInfo"]["endingBalance"](),
      overallBalance:
        lang["txTable"]["balanceRemainingInfo"]["overallBalance"](),
      startingBalance:
        lang["txTable"]["balanceRemainingInfo"]["startingBalance"](),
      tooltips: {
        change: lang["txTable"]["balanceRemainingInfo"]["tooltips"]["change"](),
        endingBalance:
          lang["txTable"]["balanceRemainingInfo"]["tooltips"][
            "endingBalance"
          ](),
        overallBalance:
          lang["txTable"]["balanceRemainingInfo"]["tooltips"][
            "overallBalance"
          ](),
        startingBalance:
          lang["txTable"]["balanceRemainingInfo"]["tooltips"][
            "startingBalance"
          ](),
      },
      type: lang["txTable"]["balanceRemainingInfo"]["type"](),
      viewLedger: lang["txTable"]["balanceRemainingInfo"]["viewLedger"](),
    },
    bulkEditBar: {
      addComment: lang["txTable"]["bulkEditBar"]["addComment"](),
      bulkLookupConfirmationText:
        lang["txTable"]["bulkEditBar"]["bulkLookupConfirmationText"](),
      changeCurrency: lang["txTable"]["bulkEditBar"]["changeCurrency"](),
      clearSelection: lang["txTable"]["bulkEditBar"]["clearSelection"](),
      close: lang["txTable"]["bulkEditBar"]["close"](),
      command: lang["txTable"]["bulkEditBar"]["command"](),
      confirm: lang["txTable"]["bulkEditBar"]["confirm"](),
      delete: lang["txTable"]["bulkEditBar"]["delete"](),
      deleteConfirmationText:
        lang["txTable"]["bulkEditBar"]["deleteConfirmationText"](),
      edit: lang["txTable"]["bulkEditBar"]["edit"](),
      editAll: lang["txTable"]["bulkEditBar"]["editAll"](),
      groupingWizard: lang["txTable"]["bulkEditBar"]["groupingWizard"](),
      groups: lang["txTable"]["bulkEditBar"]["groups"](),
      ignore: lang["txTable"]["bulkEditBar"]["ignore"](),
      ignoreConfirmationText:
        lang["txTable"]["bulkEditBar"]["ignoreConfirmationText"](),
      markAsReviewed: lang["txTable"]["bulkEditBar"]["markAsReviewed"](),
      markAsUnreviewed: lang["txTable"]["bulkEditBar"]["markAsUnreviewed"](),
      numSelected: (args: { numSelected: Arg }): string =>
        lang["txTable"]["bulkEditBar"]["numSelected"](args) as string,
      numSelectedAll: (args: { numSelected: Arg }): string =>
        lang["txTable"]["bulkEditBar"]["numSelectedAll"](args) as string,
      open: lang["txTable"]["bulkEditBar"]["open"](),
      recategorizeError: lang["txTable"]["bulkEditBar"]["recategorizeError"](),
      selectAll: lang["txTable"]["bulkEditBar"]["selectAll"](),
      selected: lang["txTable"]["bulkEditBar"]["selected"](),
      spam: lang["txTable"]["bulkEditBar"]["spam"](),
      spamConfirmationText:
        lang["txTable"]["bulkEditBar"]["spamConfirmationText"](),
      txCount: (args: { numSelected: Arg }): string =>
        lang["txTable"]["bulkEditBar"]["txCount"](args) as string,
    },
    bulkLookupPriceConfirmationTitle:
      lang["txTable"]["bulkLookupPriceConfirmationTitle"](),
    commandPallet: {
      acceptedForProcessing:
        lang["txTable"]["commandPallet"]["acceptedForProcessing"](),
      actions: {
        accept: lang["txTable"]["commandPallet"]["actions"]["accept"](),
        add: lang["txTable"]["commandPallet"]["actions"]["add"](),
        confirm: lang["txTable"]["commandPallet"]["actions"]["confirm"](),
        goBack: lang["txTable"]["commandPallet"]["actions"]["goBack"](),
      },
      bulkEditOptionDescriptions: {
        txRewind:
          lang["txTable"]["commandPallet"]["bulkEditOptionDescriptions"][
            "txRewind"
          ](),
      },
      bulkEditOptions: {
        addCommentToTransactions:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "addCommentToTransactions"
          ](),
        allowGrouping:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "allowGrouping"
          ](),
        categorize:
          lang["txTable"]["commandPallet"]["bulkEditOptions"]["categorize"](),
        changeAssetAccount:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "changeAssetAccount"
          ](),
        changeCashAccount:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "changeCashAccount"
          ](),
        changeCurrency:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "changeCurrency"
          ](),
        changeFeeCurrency:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "changeFeeCurrency"
          ](),
        changeGainsAccount:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "changeGainsAccount"
          ](),
        changeLoanAccount:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "changeLoanAccount"
          ](),
        changePnLAccount:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "changePnLAccount"
          ](),
        changePrice:
          lang["txTable"]["commandPallet"]["bulkEditOptions"]["changePrice"](),
        delete: lang["txTable"]["commandPallet"]["bulkEditOptions"]["delete"](),
        destinationAccount:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "destinationAccount"
          ](),
        groupingWizard:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "groupingWizard"
          ](),
        ignore: lang["txTable"]["commandPallet"]["bulkEditOptions"]["ignore"](),
        ignoreWarnings:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "ignoreWarnings"
          ](),
        lookUpMarketPrice:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "lookUpMarketPrice"
          ](),
        markAsNotReviewed:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "markAsNotReviewed"
          ](),
        markAsReviewed:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "markAsReviewed"
          ](),
        markAsSpam:
          lang["txTable"]["commandPallet"]["bulkEditOptions"]["markAsSpam"](),
        sourceAccount:
          lang["txTable"]["commandPallet"]["bulkEditOptions"][
            "sourceAccount"
          ](),
        syncToErp:
          lang["txTable"]["commandPallet"]["bulkEditOptions"]["syncToErp"](),
        txRewind:
          lang["txTable"]["commandPallet"]["bulkEditOptions"]["txRewind"](),
        ungroup:
          lang["txTable"]["commandPallet"]["bulkEditOptions"]["ungroup"](),
      },
      chips: { type: lang["txTable"]["commandPallet"]["chips"]["type"]() },
      currentlySelected:
        lang["txTable"]["commandPallet"]["currentlySelected"](),
      groups: lang["txTable"]["commandPallet"]["groups"](),
      ignoreWarningsOptions: {
        all: lang["txTable"]["commandPallet"]["ignoreWarningsOptions"]["all"](),
        missingBlockchain:
          lang["txTable"]["commandPallet"]["ignoreWarningsOptions"][
            "missingBlockchain"
          ](),
        missingPrice:
          lang["txTable"]["commandPallet"]["ignoreWarningsOptions"][
            "missingPrice"
          ](),
        negativeBalance:
          lang["txTable"]["commandPallet"]["ignoreWarningsOptions"][
            "negativeBalance"
          ](),
        uncategorised:
          lang["txTable"]["commandPallet"]["ignoreWarningsOptions"][
            "uncategorised"
          ](),
        unmatchedTransfer:
          lang["txTable"]["commandPallet"]["ignoreWarningsOptions"][
            "unmatchedTransfer"
          ](),
      },
      loadingOptions: lang["txTable"]["commandPallet"]["loadingOptions"](),
      noOptions: lang["txTable"]["commandPallet"]["noOptions"](),
      notAvailableOnSelectAll:
        lang["txTable"]["commandPallet"]["notAvailableOnSelectAll"](),
      numSelected: (args: { numSelected: Arg }): string =>
        lang["txTable"]["commandPallet"]["numSelected"](args) as string,
      placeholders: {
        account: lang["txTable"]["commandPallet"]["placeholders"]["account"](),
        category:
          lang["txTable"]["commandPallet"]["placeholders"]["category"](),
        changeTo:
          lang["txTable"]["commandPallet"]["placeholders"]["changeTo"](),
        currency:
          lang["txTable"]["commandPallet"]["placeholders"]["currency"](),
        ignoreWarning:
          lang["txTable"]["commandPallet"]["placeholders"]["ignoreWarning"](),
        incomingAccount:
          lang["txTable"]["commandPallet"]["placeholders"]["incomingAccount"](),
        outgoingAccount:
          lang["txTable"]["commandPallet"]["placeholders"]["outgoingAccount"](),
        searchSubType: (args: { subType: Arg }): string =>
          lang["txTable"]["commandPallet"]["placeholders"]["searchSubType"](
            args,
          ) as string,
        toCategory:
          lang["txTable"]["commandPallet"]["placeholders"]["toCategory"](),
        toSourceAccount:
          lang["txTable"]["commandPallet"]["placeholders"]["toSourceAccount"](),
        tradeType:
          lang["txTable"]["commandPallet"]["placeholders"]["tradeType"](),
        tradeTypeSearch: {
          group:
            lang["txTable"]["commandPallet"]["placeholders"]["tradeTypeSearch"][
              "group"
            ](),
          hint: lang["txTable"]["commandPallet"]["placeholders"][
            "tradeTypeSearch"
          ]["hint"](),
          in: lang["txTable"]["commandPallet"]["placeholders"][
            "tradeTypeSearch"
          ]["in"](),
          out: lang["txTable"]["commandPallet"]["placeholders"][
            "tradeTypeSearch"
          ]["out"](),
          uncategorisedTooltipOverride: (args: { value: Arg }): string =>
            lang["txTable"]["commandPallet"]["placeholders"]["tradeTypeSearch"][
              "uncategorisedTooltipOverride"
            ](args) as string,
        },
      },
      successMessage: {
        addCommentToTransactions: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "addCommentToTransactions"
          ](args) as string,
        allowGrouping: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["allowGrouping"](
            args,
          ) as string,
        categorize: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["categorize"](
            args,
          ) as string,
        changeAssetAccount: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "changeAssetAccount"
          ](args) as string,
        changeCashAccount: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "changeCashAccount"
          ](args) as string,
        changeCurrency: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["changeCurrency"](
            args,
          ) as string,
        changeFeeCurrency: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "changeFeeCurrency"
          ](args) as string,
        changeGainsAccount: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "changeGainsAccount"
          ](args) as string,
        changeLoanAccount: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "changeLoanAccount"
          ](args) as string,
        changePnLAccount: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "changePnLAccount"
          ](args) as string,
        changePrice: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["changePrice"](
            args,
          ) as string,
        delete: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["delete"](
            args,
          ) as string,
        destinationAccount: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "destinationAccount"
          ](args) as string,
        ignore: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["ignore"](
            args,
          ) as string,
        ignoreWarnings: (args: { warning: Arg; count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["ignoreWarnings"](
            args,
          ) as string,
        lookUpMarketPrice: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "lookUpMarketPrice"
          ](args) as string,
        markAsNotReviewed: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"][
            "markAsNotReviewed"
          ](args) as string,
        markAsReviewed: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["markAsReviewed"](
            args,
          ) as string,
        markAsSpam: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["markAsSpam"](
            args,
          ) as string,
        sourceAccount: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["sourceAccount"](
            args,
          ) as string,
        syncToErp: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["syncToErp"](
            args,
          ) as string,
        txRewind: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["txRewind"](
            args,
          ) as string,
        ungroup: (args: { count: Arg }): string =>
          lang["txTable"]["commandPallet"]["successMessage"]["ungroup"](
            args,
          ) as string,
      },
      syncToErp: (args: { erp: Arg }): string =>
        lang["txTable"]["commandPallet"]["syncToErp"](args) as string,
      typeSearchCommand:
        lang["txTable"]["commandPallet"]["typeSearchCommand"](),
      undo: lang["txTable"]["commandPallet"]["undo"](),
      undoFailed: lang["txTable"]["commandPallet"]["undoFailed"](),
      undoLoading: lang["txTable"]["commandPallet"]["undoLoading"](),
      undoSuccess: lang["txTable"]["commandPallet"]["undoSuccess"](),
    },
    commentLabels: {
      allComments: lang["txTable"]["commentLabels"]["allComments"](),
      hasComments: lang["txTable"]["commentLabels"]["hasComments"](),
    },
    contractAddress: lang["txTable"]["contractAddress"](),
    cost: lang["txTable"]["cost"](),
    counterparty: lang["txTable"]["counterparty"](),
    currency: lang["txTable"]["currency"](),
    date: {
      helperFrom: lang["txTable"]["date"]["helperFrom"](),
      helperTo: lang["txTable"]["date"]["helperTo"](),
    },
    deleteBulkTransactionsConfirmationTitle: (args: { txCount: Arg }): string =>
      lang["txTable"]["deleteBulkTransactionsConfirmationTitle"](
        args,
      ) as string,
    deleteTransactionConfirmationTitle:
      lang["txTable"]["deleteTransactionConfirmationTitle"](),
    destination: lang["txTable"]["destination"](),
    dialogs: {
      deleteConfirmationText:
        lang["txTable"]["dialogs"]["deleteConfirmationText"](),
      ignoreTransactionText:
        lang["txTable"]["dialogs"]["ignoreTransactionText"](),
      spamTransactionText: lang["txTable"]["dialogs"]["spamTransactionText"](),
    },
    entities: {
      explainerTooltip: lang["txTable"]["entities"]["explainerTooltip"](),
      falsePositive: lang["txTable"]["entities"]["falsePositive"](),
      globalEscrowTooltip: lang["txTable"]["entities"]["globalEscrowTooltip"](),
      switchText: lang["txTable"]["entities"]["switchText"](),
      truePositive: lang["txTable"]["entities"]["truePositive"](),
    },
    expansionPanel: {
      adjPrice: lang["txTable"]["expansionPanel"]["adjPrice"](),
      amount: lang["txTable"]["expansionPanel"]["amount"](),
      balanceError: lang["txTable"]["expansionPanel"]["balanceError"](),
      chartOfAccounts: {
        accountMappingNotRequired:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "accountMappingNotRequired"
          ](),
        accountNotFound: (args: { erp: Arg }): string =>
          lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "accountNotFound"
          ](args) as string,
        accountTypeLabel: {
          asset:
            lang["txTable"]["expansionPanel"]["chartOfAccounts"][
              "accountTypeLabel"
            ]["asset"](),
          cash: lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "accountTypeLabel"
          ]["cash"](),
          gain: lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "accountTypeLabel"
          ]["gain"](),
          loan: lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "accountTypeLabel"
          ]["loan"](),
          pl: lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "accountTypeLabel"
          ]["pl"](),
          "zero-cost-buy":
            lang["txTable"]["expansionPanel"]["chartOfAccounts"][
              "accountTypeLabel"
            ]["zero-cost-buy"](),
        },
        assetAccount:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "assetAccount"
          ](),
        cashAccount:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"]["cashAccount"](),
        dropdown:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"]["dropdown"](),
        erpAccounts: (args: { erp: Arg }): string =>
          lang["txTable"]["expansionPanel"]["chartOfAccounts"]["erpAccounts"](
            args,
          ) as string,
        from: lang["txTable"]["expansionPanel"]["chartOfAccounts"]["from"](),
        gainAccount:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"]["gainAccount"](),
        hiddenAccounts:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "hiddenAccounts"
          ](),
        loanAccount:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"]["loanAccount"](),
        plAccount:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"]["plAccount"](),
        title: lang["txTable"]["expansionPanel"]["chartOfAccounts"]["title"](),
        to: lang["txTable"]["expansionPanel"]["chartOfAccounts"]["to"](),
        transaction:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"]["transaction"](),
        uncategorised:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "uncategorised"
          ](),
        zeroCostBuyAccount:
          lang["txTable"]["expansionPanel"]["chartOfAccounts"][
            "zeroCostBuyAccount"
          ](),
      },
      comments: {
        accountant:
          lang["txTable"]["expansionPanel"]["comments"]["accountant"](),
        button: lang["txTable"]["expansionPanel"]["comments"]["button"](),
        client: lang["txTable"]["expansionPanel"]["comments"]["client"](),
        collaborator:
          lang["txTable"]["expansionPanel"]["comments"]["collaborator"](),
        comments: (args: { count: Arg }): string =>
          lang["txTable"]["expansionPanel"]["comments"]["comments"](
            args,
          ) as string,
        editError: lang["txTable"]["expansionPanel"]["comments"]["editError"](),
        edited: lang["txTable"]["expansionPanel"]["comments"]["edited"](),
        editedAt: (args: { time: Arg }): string =>
          lang["txTable"]["expansionPanel"]["comments"]["editedAt"](
            args,
          ) as string,
        noComments:
          lang["txTable"]["expansionPanel"]["comments"]["noComments"](),
        placeholder:
          lang["txTable"]["expansionPanel"]["comments"]["placeholder"](),
        saveChanges:
          lang["txTable"]["expansionPanel"]["comments"]["saveChanges"](),
        you: lang["txTable"]["expansionPanel"]["comments"]["you"](),
      },
      cost: lang["txTable"]["expansionPanel"]["cost"](),
      currency: lang["txTable"]["expansionPanel"]["currency"](),
      date: lang["txTable"]["expansionPanel"]["date"](),
      delete: {
        body: lang["txTable"]["expansionPanel"]["delete"]["body"](),
        title: lang["txTable"]["expansionPanel"]["delete"]["title"](),
      },
      events: lang["txTable"]["expansionPanel"]["events"](),
      fee: lang["txTable"]["expansionPanel"]["fee"](),
      functionName: (args: { functionName: Arg }): string =>
        lang["txTable"]["expansionPanel"]["functionName"](args) as string,
      functionNameLabel:
        lang["txTable"]["expansionPanel"]["functionNameLabel"](),
      gain: lang["txTable"]["expansionPanel"]["gain"](),
      history: {
        changed: lang["txTable"]["expansionPanel"]["history"]["changed"](),
        created: lang["txTable"]["expansionPanel"]["history"]["created"](),
        currentVersion:
          lang["txTable"]["expansionPanel"]["history"]["currentVersion"](),
        deleted: lang["txTable"]["expansionPanel"]["history"]["deleted"](),
        false: lang["txTable"]["expansionPanel"]["history"]["false"](),
        from: lang["txTable"]["expansionPanel"]["history"]["from"](),
        headings: {
          actor:
            lang["txTable"]["expansionPanel"]["history"]["headings"]["actor"](),
          date: lang["txTable"]["expansionPanel"]["history"]["headings"][
            "date"
          ](),
          transaction:
            lang["txTable"]["expansionPanel"]["history"]["headings"][
              "transaction"
            ](),
        },
        imported: lang["txTable"]["expansionPanel"]["history"]["imported"](),
        modalTitle: (args: { timestamp: Arg }): string =>
          lang["txTable"]["expansionPanel"]["history"]["modalTitle"](
            args,
          ) as string,
        noChange: lang["txTable"]["expansionPanel"]["history"]["noChange"](),
        noChangesFromCurrentDetected:
          lang["txTable"]["expansionPanel"]["history"][
            "noChangesFromCurrentDetected"
          ](),
        notRecorded:
          lang["txTable"]["expansionPanel"]["history"]["notRecorded"](),
        notSet: lang["txTable"]["expansionPanel"]["history"]["notSet"](),
        original: lang["txTable"]["expansionPanel"]["history"]["original"](),
        restore: (args: { time: Arg }): string =>
          lang["txTable"]["expansionPanel"]["history"]["restore"](
            args,
          ) as string,
        restoreOnly:
          lang["txTable"]["expansionPanel"]["history"]["restoreOnly"](),
        thisVersion:
          lang["txTable"]["expansionPanel"]["history"]["thisVersion"](),
        thisWillChange:
          lang["txTable"]["expansionPanel"]["history"]["thisWillChange"](),
        title: lang["txTable"]["expansionPanel"]["history"]["title"](),
        to: lang["txTable"]["expansionPanel"]["history"]["to"](),
        transaction:
          lang["txTable"]["expansionPanel"]["history"]["transaction"](),
        transactionKeys: {
          blockchain:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "blockchain"
            ](),
          currencyIdentifier:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "currencyIdentifier"
            ](),
          description:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "description"
            ](),
          dontGroup:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "dontGroup"
            ](),
          "erp.accountMappings.asset":
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "erp.accountMappings.asset"
            ](),
          "erp.accountMappings.cash":
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "erp.accountMappings.cash"
            ](),
          "erp.accountMappings.gain":
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "erp.accountMappings.gain"
            ](),
          "erp.accountMappings.pl":
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "erp.accountMappings.pl"
            ](),
          fee: lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
            "fee"
          ](),
          feeCurrencyIdentifier:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "feeCurrencyIdentifier"
            ](),
          feeQuantity:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "feeQuantity"
            ](),
          from: lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
            "from"
          ](),
          functionName:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "functionName"
            ](),
          functionSignature:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "functionSignature"
            ](),
          price:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "price"
            ](),
          quantity:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "quantity"
            ](),
          reviewed:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "reviewed"
            ](),
          suggestion:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "suggestion"
            ](),
          timestamp:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "timestamp"
            ](),
          to: lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
            "to"
          ](),
          trade:
            lang["txTable"]["expansionPanel"]["history"]["transactionKeys"][
              "trade"
            ](),
        },
        true: lang["txTable"]["expansionPanel"]["history"]["true"](),
        unableToCompare:
          lang["txTable"]["expansionPanel"]["history"]["unableToCompare"](),
        unableToFindPreviousVersion:
          lang["txTable"]["expansionPanel"]["history"][
            "unableToFindPreviousVersion"
          ](),
        undo: lang["txTable"]["expansionPanel"]["history"]["undo"](),
        undoLatestChange:
          lang["txTable"]["expansionPanel"]["history"]["undoLatestChange"](),
        updateReason: {
          import:
            lang["txTable"]["expansionPanel"]["history"]["updateReason"][
              "import"
            ](),
          migration:
            lang["txTable"]["expansionPanel"]["history"]["updateReason"][
              "migration"
            ](),
          rule: lang["txTable"]["expansionPanel"]["history"]["updateReason"][
            "rule"
          ](),
          system:
            lang["txTable"]["expansionPanel"]["history"]["updateReason"][
              "system"
            ](),
          transactionMatching:
            lang["txTable"]["expansionPanel"]["history"]["updateReason"][
              "transactionMatching"
            ](),
          unknown:
            lang["txTable"]["expansionPanel"]["history"]["updateReason"][
              "unknown"
            ](),
          user: lang["txTable"]["expansionPanel"]["history"]["updateReason"][
            "user"
          ](),
        },
        updateReasonActorCTCSystem:
          lang["txTable"]["expansionPanel"]["history"][
            "updateReasonActorCTCSystem"
          ](),
        updateReasonActorUnknown:
          lang["txTable"]["expansionPanel"]["history"][
            "updateReasonActorUnknown"
          ](),
        updateReasonActorUser:
          lang["txTable"]["expansionPanel"]["history"][
            "updateReasonActorUser"
          ](),
      },
      id: lang["txTable"]["expansionPanel"]["id"](),
      importSource: (args: { sourceDisplayName: Arg }): string =>
        lang["txTable"]["expansionPanel"]["importSource"](args) as string,
      inflightBalanceError:
        lang["txTable"]["expansionPanel"]["inflightBalanceError"](),
      moreInfo: lang["txTable"]["expansionPanel"]["moreInfo"](),
      moreInfoPopover: {
        importSource:
          lang["txTable"]["expansionPanel"]["moreInfoPopover"][
            "importSource"
          ](),
        learnAbout:
          lang["txTable"]["expansionPanel"]["moreInfoPopover"]["learnAbout"](),
        title: lang["txTable"]["expansionPanel"]["moreInfoPopover"]["title"](),
      },
      na: lang["txTable"]["expansionPanel"]["na"](),
      notes: (args: { notes: Arg }): string =>
        lang["txTable"]["expansionPanel"]["notes"](args) as string,
      price: lang["txTable"]["expansionPanel"]["price"](),
      proceeds: lang["txTable"]["expansionPanel"]["proceeds"](),
      receivedFrom: lang["txTable"]["expansionPanel"]["receivedFrom"](),
      rolloverFrom: (args: { currency: Arg }): string =>
        lang["txTable"]["expansionPanel"]["rolloverFrom"](args) as string,
      ruleApplied: lang["txTable"]["expansionPanel"]["ruleApplied"](),
      sentBy: lang["txTable"]["expansionPanel"]["sentBy"](),
      sourceType: (args: { sourceType: Arg }): string =>
        lang["txTable"]["expansionPanel"]["sourceType"](args) as string,
      sourceTypes: {
        ManualUpdate:
          lang["txTable"]["expansionPanel"]["sourceTypes"]["ManualUpdate"](),
        PriceFromAction:
          lang["txTable"]["expansionPanel"]["sourceTypes"]["PriceFromAction"](),
        PriceWindow:
          lang["txTable"]["expansionPanel"]["sourceTypes"]["PriceWindow"](),
      },
      time: lang["txTable"]["expansionPanel"]["time"](),
      total: lang["txTable"]["expansionPanel"]["total"](),
      tradedOn: lang["txTable"]["expansionPanel"]["tradedOn"](),
      transactionId: (args: { count: Arg }): string =>
        lang["txTable"]["expansionPanel"]["transactionId"](args) as string,
      transactionIdLabel:
        lang["txTable"]["expansionPanel"]["transactionIdLabel"](),
      transactionsTab: lang["txTable"]["expansionPanel"]["transactionsTab"](),
      type: lang["txTable"]["expansionPanel"]["type"](),
      ungroup: {
        buttonText:
          lang["txTable"]["expansionPanel"]["ungroup"]["buttonText"](),
        infoBody: lang["txTable"]["expansionPanel"]["ungroup"]["infoBody"](),
        infoTitle: lang["txTable"]["expansionPanel"]["ungroup"]["infoTitle"](),
        title: lang["txTable"]["expansionPanel"]["ungroup"]["title"](),
      },
      updating: lang["txTable"]["expansionPanel"]["updating"](),
      value: lang["txTable"]["expansionPanel"]["value"](),
      viewAssociated: lang["txTable"]["expansionPanel"]["viewAssociated"](),
      viewBalance: lang["txTable"]["expansionPanel"]["viewBalance"](),
      viewRelatedTx: lang["txTable"]["expansionPanel"]["viewRelatedTx"](),
      viewTaxRule: lang["txTable"]["expansionPanel"]["viewTaxRule"](),
      viewTx: lang["txTable"]["expansionPanel"]["viewTx"](),
    },
    exportCSV: {
      filterOptionCaption:
        lang["txTable"]["exportCSV"]["filterOptionCaption"](),
      filterOptionTitle: lang["txTable"]["exportCSV"]["filterOptionTitle"](),
      pageOptionCaption: lang["txTable"]["exportCSV"]["pageOptionCaption"](),
      pageOptionTitle: lang["txTable"]["exportCSV"]["pageOptionTitle"](),
      paywall: {
        button: lang["txTable"]["exportCSV"]["paywall"]["button"](),
        text: lang["txTable"]["exportCSV"]["paywall"]["text"](),
      },
      pending: {
        captionPt1: lang["txTable"]["exportCSV"]["pending"]["captionPt1"](),
        captionPt2: lang["txTable"]["exportCSV"]["pending"]["captionPt2"](),
        title: lang["txTable"]["exportCSV"]["pending"]["title"](),
      },
      submitButton: (args: { count: Arg }): string =>
        lang["txTable"]["exportCSV"]["submitButton"](args) as string,
      success: {
        captionPt1: lang["txTable"]["exportCSV"]["success"]["captionPt1"](),
        captionPt2: lang["txTable"]["exportCSV"]["success"]["captionPt2"](),
        title: lang["txTable"]["exportCSV"]["success"]["title"](),
      },
      title: lang["txTable"]["exportCSV"]["title"](),
    },
    fee: lang["txTable"]["fee"](),
    feeValue: (args: { amount: Arg }): string =>
      lang["txTable"]["feeValue"](args) as string,
    fiatTradeLabel: {
      deposit: lang["txTable"]["fiatTradeLabel"]["deposit"](),
      withdrawal: lang["txTable"]["fiatTradeLabel"]["withdrawal"](),
    },
    filter: {
      addFilter: lang["txTable"]["filter"]["addFilter"](),
      advanced: lang["txTable"]["filter"]["advanced"](),
      advancedFilters: lang["txTable"]["filter"]["advancedFilters"](),
      category: lang["txTable"]["filter"]["category"](),
      clearFilters: lang["txTable"]["filter"]["clearFilters"](),
      comment: lang["txTable"]["filter"]["comment"](),
      currency: lang["txTable"]["filter"]["currency"](),
      date: lang["txTable"]["filter"]["date"](),
      descriptions: {
        actionId: lang["txTable"]["filter"]["descriptions"]["actionId"](),
        after: lang["txTable"]["filter"]["descriptions"]["after"](),
        and: lang["txTable"]["filter"]["descriptions"]["and"](),
        before: lang["txTable"]["filter"]["descriptions"]["before"](),
        blockchain: lang["txTable"]["filter"]["descriptions"]["blockchain"](),
        category: lang["txTable"]["filter"]["descriptions"]["category"](),
        comment: lang["txTable"]["filter"]["descriptions"]["comment"](),
        comments: lang["txTable"]["filter"]["descriptions"]["comments"](),
        currency: lang["txTable"]["filter"]["descriptions"]["currency"](),
        date: lang["txTable"]["filter"]["descriptions"]["date"](),
        description: lang["txTable"]["filter"]["descriptions"]["description"](),
        erpAssetAccount:
          lang["txTable"]["filter"]["descriptions"]["erpAssetAccount"](),
        erpCashAccount:
          lang["txTable"]["filter"]["descriptions"]["erpCashAccount"](),
        erpGainsAccount:
          lang["txTable"]["filter"]["descriptions"]["erpGainsAccount"](),
        erpLoanAccount:
          lang["txTable"]["filter"]["descriptions"]["erpLoanAccount"](),
        erpPnlAccount:
          lang["txTable"]["filter"]["descriptions"]["erpPnlAccount"](),
        erpSyncStatus:
          lang["txTable"]["filter"]["descriptions"]["erpSyncStatus"](),
        feeCurrency: lang["txTable"]["filter"]["descriptions"]["feeCurrency"](),
        from: lang["txTable"]["filter"]["descriptions"]["from"](),
        hasRule: lang["txTable"]["filter"]["descriptions"]["hasRule"](),
        id: lang["txTable"]["filter"]["descriptions"]["id"](),
        importId: lang["txTable"]["filter"]["descriptions"]["importId"](),
        importType: lang["txTable"]["filter"]["descriptions"]["importType"](),
        isSmartContractInteraction:
          lang["txTable"]["filter"]["descriptions"][
            "isSmartContractInteraction"
          ](),
        ledger: lang["txTable"]["filter"]["descriptions"]["ledger"](),
        missingPrice:
          lang["txTable"]["filter"]["descriptions"]["missingPrice"](),
        negativeBalance:
          lang["txTable"]["filter"]["descriptions"]["negativeBalance"](),
        nftCollection:
          lang["txTable"]["filter"]["descriptions"]["nftCollection"](),
        not: lang["txTable"]["filter"]["descriptions"]["not"](),
        or: lang["txTable"]["filter"]["descriptions"]["or"](),
        party: lang["txTable"]["filter"]["descriptions"]["party"](),
        reconciliation:
          lang["txTable"]["filter"]["descriptions"]["reconciliation"](),
        reviewed: lang["txTable"]["filter"]["descriptions"]["reviewed"](),
        rule: lang["txTable"]["filter"]["descriptions"]["rule"](),
        ruleOperator:
          lang["txTable"]["filter"]["descriptions"]["ruleOperator"](),
        source: lang["txTable"]["filter"]["descriptions"]["source"](),
        suggestion: lang["txTable"]["filter"]["descriptions"]["suggestion"](),
        sync: lang["txTable"]["filter"]["descriptions"]["sync"](),
        taxOutcomeType:
          lang["txTable"]["filter"]["descriptions"]["taxOutcomeType"](),
        to: lang["txTable"]["filter"]["descriptions"]["to"](),
        trade: lang["txTable"]["filter"]["descriptions"]["trade"](),
        txCurrency: lang["txTable"]["filter"]["descriptions"]["txCurrency"](),
        txFunctionName:
          lang["txTable"]["filter"]["descriptions"]["txFunctionName"](),
        txFunctionSignature:
          lang["txTable"]["filter"]["descriptions"]["txFunctionSignature"](),
        txId: lang["txTable"]["filter"]["descriptions"]["txId"](),
        warning: lang["txTable"]["filter"]["descriptions"]["warning"](),
      },
      erpSyncStatus: {
        NOT_READY_TO_SYNC:
          lang["txTable"]["filter"]["erpSyncStatus"]["NOT_READY_TO_SYNC"](),
        SYNC_FAILED:
          lang["txTable"]["filter"]["erpSyncStatus"]["SYNC_FAILED"](),
        SYNC_IN_PROGRESS:
          lang["txTable"]["filter"]["erpSyncStatus"]["SYNC_IN_PROGRESS"](),
        SYNC_NOT_REQUESTED:
          lang["txTable"]["filter"]["erpSyncStatus"]["SYNC_NOT_REQUESTED"](),
        SYNC_NO_OP: lang["txTable"]["filter"]["erpSyncStatus"]["SYNC_NO_OP"](),
        SYNC_OUT_OF_DATE:
          lang["txTable"]["filter"]["erpSyncStatus"]["SYNC_OUT_OF_DATE"](),
        SYNC_SUCCESSFUL:
          lang["txTable"]["filter"]["erpSyncStatus"]["SYNC_SUCCESSFUL"](),
      },
      filter: lang["txTable"]["filter"]["filter"](),
      filterBuilderOperatorOverrides: {
        category:
          lang["txTable"]["filter"]["filterBuilderOperatorOverrides"][
            "category"
          ](),
      },
      filters: lang["txTable"]["filter"]["filters"](),
      hasRule: lang["txTable"]["filter"]["hasRule"](),
      hideIgnored: lang["txTable"]["filter"]["hideIgnored"](),
      hideLockedTransactions:
        lang["txTable"]["filter"]["hideLockedTransactions"](),
      hideNonSuggested: lang["txTable"]["filter"]["hideNonSuggested"](),
      hideReviewed: lang["txTable"]["filter"]["hideReviewed"](),
      importSource: lang["txTable"]["filter"]["importSource"](),
      importType: lang["txTable"]["filter"]["importType"](),
      imports: lang["txTable"]["filter"]["imports"](),
      includesFee: {
        doesNotIncludesFee:
          lang["txTable"]["filter"]["includesFee"]["doesNotIncludesFee"](),
        includesFee: lang["txTable"]["filter"]["includesFee"]["includesFee"](),
      },
      isSmartContractInteraction: {
        smartContract:
          lang["txTable"]["filter"]["isSmartContractInteraction"][
            "smartContract"
          ](),
        wallet:
          lang["txTable"]["filter"]["isSmartContractInteraction"]["wallet"](),
      },
      lockPeriodTooltip: lang["txTable"]["filter"]["lockPeriodTooltip"](),
      manual: lang["txTable"]["filter"]["manual"](),
      moreFilters: lang["txTable"]["filter"]["moreFilters"](),
      negation: {
        is: lang["txTable"]["filter"]["negation"]["is"](),
        isNot: lang["txTable"]["filter"]["negation"]["isNot"](),
      },
      noOptions: lang["txTable"]["filter"]["noOptions"](),
      noResults: lang["txTable"]["filter"]["noResults"](),
      noWarnings: lang["txTable"]["filter"]["noWarnings"](),
      operators: {
        actionId: lang["txTable"]["filter"]["operators"]["actionId"](),
        after: lang["txTable"]["filter"]["operators"]["after"](),
        and: lang["txTable"]["filter"]["operators"]["and"](),
        before: lang["txTable"]["filter"]["operators"]["before"](),
        blockchain: lang["txTable"]["filter"]["operators"]["blockchain"](),
        category: lang["txTable"]["filter"]["operators"]["category"](),
        comment: lang["txTable"]["filter"]["operators"]["comment"](),
        comments: lang["txTable"]["filter"]["operators"]["comments"](),
        currency: lang["txTable"]["filter"]["operators"]["currency"](),
        date: lang["txTable"]["filter"]["operators"]["date"](),
        description: lang["txTable"]["filter"]["operators"]["description"](),
        erpAssetAccount:
          lang["txTable"]["filter"]["operators"]["erpAssetAccount"](),
        erpCashAccount:
          lang["txTable"]["filter"]["operators"]["erpCashAccount"](),
        erpGainsAccount:
          lang["txTable"]["filter"]["operators"]["erpGainsAccount"](),
        erpLoanAccount:
          lang["txTable"]["filter"]["operators"]["erpLoanAccount"](),
        erpPnlAccount:
          lang["txTable"]["filter"]["operators"]["erpPnlAccount"](),
        erpSyncStatus:
          lang["txTable"]["filter"]["operators"]["erpSyncStatus"](),
        feeCurrency: lang["txTable"]["filter"]["operators"]["feeCurrency"](),
        from: lang["txTable"]["filter"]["operators"]["from"](),
        hasRule: lang["txTable"]["filter"]["operators"]["hasRule"](),
        id: lang["txTable"]["filter"]["operators"]["id"](),
        importId: lang["txTable"]["filter"]["operators"]["importId"](),
        importType: lang["txTable"]["filter"]["operators"]["importType"](),
        includesFee: lang["txTable"]["filter"]["operators"]["includesFee"](),
        integrations: lang["txTable"]["filter"]["operators"]["integrations"](),
        isSmartContractInteraction:
          lang["txTable"]["filter"]["operators"][
            "isSmartContractInteraction"
          ](),
        ledger: lang["txTable"]["filter"]["operators"]["ledger"](),
        lockPeriod: lang["txTable"]["filter"]["operators"]["lockPeriod"](),
        missingPrice: lang["txTable"]["filter"]["operators"]["missingPrice"](),
        negativeBalance:
          lang["txTable"]["filter"]["operators"]["negativeBalance"](),
        nftCollection:
          lang["txTable"]["filter"]["operators"]["nftCollection"](),
        not: lang["txTable"]["filter"]["operators"]["not"](),
        or: lang["txTable"]["filter"]["operators"]["or"](),
        party: lang["txTable"]["filter"]["operators"]["party"](),
        reconciliation:
          lang["txTable"]["filter"]["operators"]["reconciliation"](),
        reviewed: lang["txTable"]["filter"]["operators"]["reviewed"](),
        rule: lang["txTable"]["filter"]["operators"]["rule"](),
        ruleOperator: lang["txTable"]["filter"]["operators"]["ruleOperator"](),
        source: lang["txTable"]["filter"]["operators"]["source"](),
        suggestion: lang["txTable"]["filter"]["operators"]["suggestion"](),
        sync: lang["txTable"]["filter"]["operators"]["sync"](),
        taxOutcomeType:
          lang["txTable"]["filter"]["operators"]["taxOutcomeType"](),
        to: lang["txTable"]["filter"]["operators"]["to"](),
        trade: lang["txTable"]["filter"]["operators"]["trade"](),
        txCurrency: lang["txTable"]["filter"]["operators"]["txCurrency"](),
        txFunctionName:
          lang["txTable"]["filter"]["operators"]["txFunctionName"](),
        txFunctionSignature:
          lang["txTable"]["filter"]["operators"]["txFunctionSignature"](),
        txId: lang["txTable"]["filter"]["operators"]["txId"](),
        warning: lang["txTable"]["filter"]["operators"]["warning"](),
      },
      otherFilter: lang["txTable"]["filter"]["otherFilter"](),
      placeholder: lang["txTable"]["filter"]["placeholder"](),
      present: lang["txTable"]["filter"]["present"](),
      resetFilters: lang["txTable"]["filter"]["resetFilters"](),
      search: lang["txTable"]["filter"]["search"](),
      selectAll: lang["txTable"]["filter"]["selectAll"](),
      settingsFilter: lang["txTable"]["filter"]["settingsFilter"](),
      showIgnored: lang["txTable"]["filter"]["showIgnored"](),
      sort: lang["txTable"]["filter"]["sort"](),
      source: lang["txTable"]["filter"]["source"](),
      table: lang["txTable"]["filter"]["table"](),
      taxOutcomeType: {
        income: lang["txTable"]["filter"]["taxOutcomeType"]["income"](),
        longTermCapitalGain:
          lang["txTable"]["filter"]["taxOutcomeType"]["longTermCapitalGain"](),
        longTermCapitalLoss:
          lang["txTable"]["filter"]["taxOutcomeType"]["longTermCapitalLoss"](),
        shortTermCapitalGain:
          lang["txTable"]["filter"]["taxOutcomeType"]["shortTermCapitalGain"](),
        shortTermCapitalLoss:
          lang["txTable"]["filter"]["taxOutcomeType"]["shortTermCapitalLoss"](),
      },
      textMatching: {
        is: lang["txTable"]["filter"]["textMatching"]["is"](),
        isNot: lang["txTable"]["filter"]["textMatching"]["isNot"](),
      },
      transactionId: lang["txTable"]["filter"]["transactionId"](),
      trySearchingFor: lang["txTable"]["filter"]["trySearchingFor"](),
      txFunctionName: lang["txTable"]["filter"]["txFunctionName"](),
      txFunctionSignature: lang["txTable"]["filter"]["txFunctionSignature"](),
      txId: lang["txTable"]["filter"]["txId"](),
      txSignature: lang["txTable"]["filter"]["txSignature"](),
      typeToSearch: lang["txTable"]["filter"]["typeToSearch"](),
      warningLabel: (args: { label: Arg; number: Arg }): string =>
        lang["txTable"]["filter"]["warningLabel"](args) as string,
      warnings: lang["txTable"]["filter"]["warnings"](),
    },
    filteredCount: (args: {
      totalFilteredCount: Arg;
      totalCount: Arg;
    }): string => lang["txTable"]["filteredCount"](args) as string,
    from: lang["txTable"]["from"](),
    gain: lang["txTable"]["gain"](),
    generateReport: lang["txTable"]["generateReport"](),
    id: lang["txTable"]["id"](),
    identifyAddress: lang["txTable"]["identifyAddress"](),
    ignoreBulkTransactionsConfirmationTitle:
      lang["txTable"]["ignoreBulkTransactionsConfirmationTitle"](),
    ignoreTransactionConfirmationTitle:
      lang["txTable"]["ignoreTransactionConfirmationTitle"](),
    ignored: lang["txTable"]["ignored"](),
    ignoredWarningLabels: {
      missingBlockchain:
        lang["txTable"]["ignoredWarningLabels"]["missingBlockchain"](),
      negativeBalance:
        lang["txTable"]["ignoredWarningLabels"]["negativeBalance"](),
    },
    importData: lang["txTable"]["importData"](),
    importFilterOther: lang["txTable"]["importFilterOther"](),
    importMoreData: lang["txTable"]["importMoreData"](),
    importType: {
      api: lang["txTable"]["importType"]["api"](),
      "assumed-fee": lang["txTable"]["importType"]["assumed-fee"](),
      "bulk-wallet": lang["txTable"]["importType"]["bulk-wallet"](),
      csv: lang["txTable"]["importType"]["csv"](),
      manual: lang["txTable"]["importType"]["manual"](),
      "manual-csv": lang["txTable"]["importType"]["manual-csv"](),
      oauth: lang["txTable"]["importType"]["oauth"](),
      "soft-transfer": lang["txTable"]["importType"]["soft-transfer"](),
      "soft-wallet": lang["txTable"]["importType"]["soft-wallet"](),
      wallet: lang["txTable"]["importType"]["wallet"](),
      "wallet-api": lang["txTable"]["importType"]["wallet-api"](),
      "wallet-csv": lang["txTable"]["importType"]["wallet-csv"](),
    },
    invalidBulkEditTooltip: lang["txTable"]["invalidBulkEditTooltip"](),
    invalidCategoryTradeUncategorised: (args: { tradeType: Arg }): string =>
      lang["txTable"]["invalidCategoryTradeUncategorised"](args) as string,
    labels: lang["txTable"]["labels"](),
    missingBlockchainDescription:
      lang["txTable"]["missingBlockchainDescription"](),
    moreThan: lang["txTable"]["moreThan"](),
    noComments: lang["txTable"]["noComments"](),
    noData: lang["txTable"]["noData"](),
    noTxFound: lang["txTable"]["noTxFound"](),
    noTxMatching: lang["txTable"]["noTxMatching"](),
    noTxMatchingWithReportRefreshRunning:
      lang["txTable"]["noTxMatchingWithReportRefreshRunning"](),
    none: lang["txTable"]["none"](),
    of: lang["txTable"]["of"](),
    openInNewTab: lang["txTable"]["openInNewTab"](),
    overallBalance: lang["txTable"]["overallBalance"](),
    pagination: {
      pageXofY: (args: { pageNum: Arg; totalPages: Arg }): string =>
        lang["txTable"]["pagination"]["pageXofY"](args) as string,
    },
    plusMore: (args: { count: Arg }): string =>
      lang["txTable"]["plusMore"](args) as string,
    price: lang["txTable"]["price"](),
    quantity: lang["txTable"]["quantity"](),
    recentComments: lang["txTable"]["recentComments"](),
    recentCommentsMore: (args: { count: Arg }): string =>
      lang["txTable"]["recentCommentsMore"](args) as string,
    remaining: lang["txTable"]["remaining"](),
    rowsPerPage: lang["txTable"]["rowsPerPage"](),
    savedAddresses: {
      addError: lang["txTable"]["savedAddresses"]["addError"](),
      body: lang["txTable"]["savedAddresses"]["body"](),
      deleteError: lang["txTable"]["savedAddresses"]["deleteError"](),
      importWallet: lang["txTable"]["savedAddresses"]["importWallet"](),
      lengthError: lang["txTable"]["savedAddresses"]["lengthError"](),
      nameAddress: lang["txTable"]["savedAddresses"]["nameAddress"](),
      placeholder: lang["txTable"]["savedAddresses"]["placeholder"](),
      title: lang["txTable"]["savedAddresses"]["title"](),
      updateError: lang["txTable"]["savedAddresses"]["updateError"](),
    },
    search: lang["txTable"]["search"](),
    select: lang["txTable"]["select"](),
    showing: lang["txTable"]["showing"](),
    similarTxs: lang["txTable"]["similarTxs"](),
    smartSuggestion: lang["txTable"]["smartSuggestion"](),
    sort: {
      feeAscending: lang["txTable"]["sort"]["feeAscending"](),
      feeDescending: lang["txTable"]["sort"]["feeDescending"](),
      gainAscending: lang["txTable"]["sort"]["gainAscending"](),
      gainDescending: lang["txTable"]["sort"]["gainDescending"](),
      lastModifiedAscending: lang["txTable"]["sort"]["lastModifiedAscending"](),
      lastModifiedDescending:
        lang["txTable"]["sort"]["lastModifiedDescending"](),
      newestFirst: lang["txTable"]["sort"]["newestFirst"](),
      oldestFirst: lang["txTable"]["sort"]["oldestFirst"](),
      uncategorisedSizeAscending:
        lang["txTable"]["sort"]["uncategorisedSizeAscending"](),
      uncategorisedSizeDescending:
        lang["txTable"]["sort"]["uncategorisedSizeDescending"](),
      uncategorisedValueAscending:
        lang["txTable"]["sort"]["uncategorisedValueAscending"](),
      uncategorisedValueDescending:
        lang["txTable"]["sort"]["uncategorisedValueDescending"](),
      valueAscending: lang["txTable"]["sort"]["valueAscending"](),
      valueDescending: lang["txTable"]["sort"]["valueDescending"](),
    },
    source: lang["txTable"]["source"](),
    sourceFilter: {
      exchanges: lang["txTable"]["sourceFilter"]["exchanges"](),
      other: lang["txTable"]["sourceFilter"]["other"](),
      wallets: lang["txTable"]["sourceFilter"]["wallets"](),
    },
    spamBulkTransactionsConfirmationTitle:
      lang["txTable"]["spamBulkTransactionsConfirmationTitle"](),
    spamTransactionConfirmationTitle:
      lang["txTable"]["spamTransactionConfirmationTitle"](),
    time: lang["txTable"]["time"](),
    title: lang["txTable"]["title"](),
    to: lang["txTable"]["to"](),
    tooltipGain: (args: { totalGain: Arg }): string =>
      lang["txTable"]["tooltipGain"](args) as string,
    tooltipLoss: (args: { totalLoss: Arg }): string =>
      lang["txTable"]["tooltipLoss"](args) as string,
    total: lang["txTable"]["total"](),
    totalFiltered: lang["txTable"]["totalFiltered"](),
    totalTxCount: (args: { totalCount: Arg }): string =>
      lang["txTable"]["totalTxCount"](args) as string,
    totalTxs: lang["txTable"]["totalTxs"](),
    trade: lang["txTable"]["trade"](),
    transaction: lang["txTable"]["transaction"](),
    transactionId: lang["txTable"]["transactionId"](),
    transactions: lang["txTable"]["transactions"](),
    txRow: {
      balanceRemaining: (args: { displayName: Arg }): string =>
        lang["txTable"]["txRow"]["balanceRemaining"](args) as string,
      balanceRemainingOverall:
        lang["txTable"]["txRow"]["balanceRemainingOverall"](),
      cost: lang["txTable"]["txRow"]["cost"](),
      erp: {
        categoriseToSync: lang["txTable"]["txRow"]["erp"]["categoriseToSync"](),
        mapAllAccounts: lang["txTable"]["txRow"]["erp"]["mapAllAccounts"](),
        provideDefaultAccountMappings:
          lang["txTable"]["txRow"]["erp"]["provideDefaultAccountMappings"](),
        reportFailed: lang["txTable"]["txRow"]["erp"]["reportFailed"](),
        resolveAllWarnings:
          lang["txTable"]["txRow"]["erp"]["resolveAllWarnings"](),
        status: {
          NOT_READY_TO_SYNC: (args: { erpProvider: Arg }): string =>
            lang["txTable"]["txRow"]["erp"]["status"]["NOT_READY_TO_SYNC"](
              args,
            ) as string,
          SYNC_FAILED: (args: { erpProvider: Arg }): string =>
            lang["txTable"]["txRow"]["erp"]["status"]["SYNC_FAILED"](
              args,
            ) as string,
          SYNC_IN_PROGRESS: (args: { erpProvider: Arg }): string =>
            lang["txTable"]["txRow"]["erp"]["status"]["SYNC_IN_PROGRESS"](
              args,
            ) as string,
          SYNC_NOT_REQUESTED: (args: { erpProvider: Arg }): string =>
            lang["txTable"]["txRow"]["erp"]["status"]["SYNC_NOT_REQUESTED"](
              args,
            ) as string,
          SYNC_NO_OP: (args: { erpProvider: Arg }): string =>
            lang["txTable"]["txRow"]["erp"]["status"]["SYNC_NO_OP"](
              args,
            ) as string,
          SYNC_OUT_OF_DATE: (args: { erpProvider: Arg }): string =>
            lang["txTable"]["txRow"]["erp"]["status"]["SYNC_OUT_OF_DATE"](
              args,
            ) as string,
          SYNC_SUCCESSFUL: (args: { erpProvider: Arg }): string =>
            lang["txTable"]["txRow"]["erp"]["status"]["SYNC_SUCCESSFUL"](
              args,
            ) as string,
        },
        viewJournal: lang["txTable"]["txRow"]["erp"]["viewJournal"](),
        viewPreviousJournal:
          lang["txTable"]["txRow"]["erp"]["viewPreviousJournal"](),
        waitForCalculationsToFinish:
          lang["txTable"]["txRow"]["erp"]["waitForCalculationsToFinish"](),
      },
      feeValue: lang["txTable"]["txRow"]["feeValue"](),
      lockedRowTooltip: {
        action: lang["txTable"]["txRow"]["lockedRowTooltip"]["action"](),
        text: lang["txTable"]["txRow"]["lockedRowTooltip"]["text"](),
      },
      moreOptions: {
        generateMatchingTransfer:
          lang["txTable"]["txRow"]["moreOptions"]["generateMatchingTransfer"](),
      },
      noFees: lang["txTable"]["txRow"]["noFees"](),
      overflowCurrencies: (args: { count: Arg }): string =>
        lang["txTable"]["txRow"]["overflowCurrencies"](args) as string,
      prefix: {
        exchange: lang["txTable"]["txRow"]["prefix"]["exchange"](),
        from: lang["txTable"]["txRow"]["prefix"]["from"](),
        fromTo: lang["txTable"]["txRow"]["prefix"]["fromTo"](),
        to: lang["txTable"]["txRow"]["prefix"]["to"](),
      },
      similarCount: (args: { count: Arg }): string =>
        lang["txTable"]["txRow"]["similarCount"](args) as string,
      similarValue: (args: { symbol: Arg; value: Arg }): string =>
        lang["txTable"]["txRow"]["similarValue"](args) as string,
      singleSideLabels: {
        from: lang["txTable"]["txRow"]["singleSideLabels"]["from"](),
        to: lang["txTable"]["txRow"]["singleSideLabels"]["to"](),
      },
      smartContract: lang["txTable"]["txRow"]["smartContract"](),
      tooltip: lang["txTable"]["txRow"]["tooltip"](),
      totalTransactionValue:
        lang["txTable"]["txRow"]["totalTransactionValue"](),
    },
    type: lang["txTable"]["type"](),
    typeUpdateWarningModal: {
      transferSmartContract: {
        change:
          lang["txTable"]["typeUpdateWarningModal"]["transferSmartContract"][
            "change"
          ](),
        contract:
          lang["txTable"]["typeUpdateWarningModal"]["transferSmartContract"][
            "contract"
          ](),
        ignore:
          lang["txTable"]["typeUpdateWarningModal"]["transferSmartContract"][
            "ignore"
          ](),
        receiveFromAction:
          lang["txTable"]["typeUpdateWarningModal"]["transferSmartContract"][
            "receiveFromAction"
          ](),
        receiveFromInfo:
          lang["txTable"]["typeUpdateWarningModal"]["transferSmartContract"][
            "receiveFromInfo"
          ](),
        receiveFromTitle:
          lang["txTable"]["typeUpdateWarningModal"]["transferSmartContract"][
            "receiveFromTitle"
          ](),
        sendToAction:
          lang["txTable"]["typeUpdateWarningModal"]["transferSmartContract"][
            "sendToAction"
          ](),
        sendToInfo:
          lang["txTable"]["typeUpdateWarningModal"]["transferSmartContract"][
            "sendToInfo"
          ](),
        sendToTitle:
          lang["txTable"]["typeUpdateWarningModal"]["transferSmartContract"][
            "sendToTitle"
          ](),
      },
    },
    uncategorised: lang["txTable"]["uncategorised"](),
    unknownAccountWarning: {
      CTA: (args: { side: Arg; linkTextKey: Arg }): string =>
        lang["txTable"]["unknownAccountWarning"]["CTA"](args) as string,
      body: (args: { side: Arg; isBlockchain: Arg }): string =>
        lang["txTable"]["unknownAccountWarning"]["body"](args) as string,
      subTitle: (args: { side: Arg }): string =>
        lang["txTable"]["unknownAccountWarning"]["subTitle"](args) as string,
      title: (args: { side: Arg }): string =>
        lang["txTable"]["unknownAccountWarning"]["title"](args) as string,
      tooltip: (args: { side: Arg }): string =>
        lang["txTable"]["unknownAccountWarning"]["tooltip"](args) as string,
    },
    updatePrice: lang["txTable"]["updatePrice"](),
    updateValue: lang["txTable"]["updateValue"](),
    value: lang["txTable"]["value"](),
    viewAddress: lang["txTable"]["viewAddress"](),
    viewAssociatedTransactionsModal: {
      exchange:
        lang["txTable"]["viewAssociatedTransactionsModal"]["exchange"](),
      import: (args: { importType: Arg }): string =>
        lang["txTable"]["viewAssociatedTransactionsModal"]["import"](
          args,
        ) as string,
      smartContract:
        lang["txTable"]["viewAssociatedTransactionsModal"]["smartContract"](),
      transactions:
        lang["txTable"]["viewAssociatedTransactionsModal"]["transactions"](),
      type: lang["txTable"]["viewAssociatedTransactionsModal"]["type"](),
      wallet: lang["txTable"]["viewAssociatedTransactionsModal"]["wallet"](),
    },
    viewComments: lang["txTable"]["viewComments"](),
    viewLedger: lang["txTable"]["viewLedger"](),
    viewTx: lang["txTable"]["viewTx"](),
    warningLabels: {
      all: lang["txTable"]["warningLabels"]["all"](),
      missingBlockchain:
        lang["txTable"]["warningLabels"]["missingBlockchain"](),
      missingPrice: lang["txTable"]["warningLabels"]["missingPrice"](),
      negativeBalance: lang["txTable"]["warningLabels"]["negativeBalance"](),
      uncategorised: lang["txTable"]["warningLabels"]["uncategorised"](),
      unknownInOut: lang["txTable"]["warningLabels"]["unknownInOut"](),
      unmatchedTransfer:
        lang["txTable"]["warningLabels"]["unmatchedTransfer"](),
      zeroCostBuy: lang["txTable"]["warningLabels"]["zeroCostBuy"](),
    },
    warnings: {
      bridgeTransferIn: lang["txTable"]["warnings"]["bridgeTransferIn"](),
      bridgeTransferOut: lang["txTable"]["warnings"]["bridgeTransferOut"](),
      bridgein: lang["txTable"]["warnings"]["bridgein"](),
      bridgeout: lang["txTable"]["warnings"]["bridgeout"](),
      receive: lang["txTable"]["warnings"]["receive"](),
      send: lang["txTable"]["warnings"]["send"](),
      uncategorized: (args: { link: Arg }): string =>
        lang["txTable"]["warnings"]["uncategorized"](args) as string,
      uncategorizedReview: lang["txTable"]["warnings"]["uncategorizedReview"](),
    },
  },
  uncategorisedModal: {
    actionTooltip: lang["uncategorisedModal"]["actionTooltip"](),
    cancel: lang["uncategorisedModal"]["cancel"](),
    moreOptions: lang["uncategorisedModal"]["moreOptions"](),
    save: lang["uncategorisedModal"]["save"](),
    subTitle: lang["uncategorisedModal"]["subTitle"](),
    title: lang["uncategorisedModal"]["title"](),
  },
  uncategorisedToggles: lang["uncategorisedToggles"](),
  unknownInOut: { url: lang["unknownInOut"]["url"]() },
  update: lang["update"](),
  userArchive: {
    failedToRestore: lang["userArchive"]["failedToRestore"](),
    restoreData: lang["userArchive"]["restoreData"](),
    title: lang["userArchive"]["title"](),
  },
  viewAssociatedTransactions: lang["viewAssociatedTransactions"](),
  wallet: {
    address: (args: { source: Arg }): string =>
      lang["wallet"]["address"](args) as string,
    addresses: lang["wallet"]["addresses"](),
    bulk: lang["wallet"]["bulk"](),
    bulkError: lang["wallet"]["bulkError"](),
    bulkHelperText: lang["wallet"]["bulkHelperText"](),
    deleteConfirmationText: (args: {
      wallet: Arg;
      walletAddress: Arg;
    }): string => lang["wallet"]["deleteConfirmationText"](args) as string,
    editDetails: lang["wallet"]["editDetails"](),
    editingSubmit: lang["wallet"]["editingSubmit"](),
    equivWallet: (args: { name: Arg }): string =>
      lang["wallet"]["equivWallet"](args) as string,
    existingSubmit: (args: { source: Arg }): string =>
      lang["wallet"]["existingSubmit"](args) as string,
    hardSync: {
      beginningOfTime: lang["wallet"]["hardSync"]["beginningOfTime"](),
      confirmationOneWallet:
        lang["wallet"]["hardSync"]["confirmationOneWallet"](),
      lastLockedPeriod: lang["wallet"]["hardSync"]["lastLockedPeriod"](),
      syncedFromDate: lang["wallet"]["hardSync"]["syncedFromDate"](),
      textOneWallet: (args: { name: Arg; date: Arg }): string =>
        lang["wallet"]["hardSync"]["textOneWallet"](args) as string,
      titleOneWallet: (args: { name: Arg; date: Arg }): string =>
        lang["wallet"]["hardSync"]["titleOneWallet"](args) as string,
    },
    hardSyncWarning: (args: { name: Arg }): string =>
      lang["wallet"]["hardSyncWarning"](args) as string,
    importRelatedWallets: {
      actionTitle: lang["wallet"]["importRelatedWallets"]["actionTitle"](),
      actionTooltip: lang["wallet"]["importRelatedWallets"]["actionTooltip"](),
      noResults: lang["wallet"]["importRelatedWallets"]["noResults"](),
      syncError: lang["wallet"]["importRelatedWallets"]["syncError"](),
      syncSuccess: lang["wallet"]["importRelatedWallets"]["syncSuccess"](),
      title: lang["wallet"]["importRelatedWallets"]["title"](),
      tooltipOff: lang["wallet"]["importRelatedWallets"]["tooltipOff"](),
      tooltipOn: lang["wallet"]["importRelatedWallets"]["tooltipOn"](),
    },
    importRules: {
      addressAlreadyExistsError:
        lang["wallet"]["importRules"]["addressAlreadyExistsError"](),
      bulkEmpty: (args: { name: Arg }): string =>
        lang["wallet"]["importRules"]["bulkEmpty"](args) as string,
      bulkExisting: (args: { name: Arg }): string =>
        lang["wallet"]["importRules"]["bulkExisting"](args) as string,
      bulkInvalid: (args: { name: Arg }): string =>
        lang["wallet"]["importRules"]["bulkInvalid"](args) as string,
      empty: (args: { name: Arg }): string =>
        lang["wallet"]["importRules"]["empty"](args) as string,
      existing: (args: { name: Arg }): string =>
        lang["wallet"]["importRules"]["existing"](args) as string,
      invalid: (args: { name: Arg }): string =>
        lang["wallet"]["importRules"]["invalid"](args) as string,
      invalidAddressError:
        lang["wallet"]["importRules"]["invalidAddressError"](),
      invalidBulkBlockchain: (args: { chain: Arg }): string =>
        lang["wallet"]["importRules"]["invalidBulkBlockchain"](args) as string,
      invalidEnsError: lang["wallet"]["importRules"]["invalidEnsError"](),
      invalidSnsError: lang["wallet"]["importRules"]["invalidSnsError"](),
      tooManyWallets: (args: { maxWalletImports: Arg }): string =>
        lang["wallet"]["importRules"]["tooManyWallets"](args) as string,
      tooManyWalletsError:
        lang["wallet"]["importRules"]["tooManyWalletsError"](),
    },
    invalid: lang["wallet"]["invalid"](),
    name: lang["wallet"]["name"](),
    nameRequired: (args: { walletName: Arg }): string =>
      lang["wallet"]["nameRequired"](args) as string,
    nickname: lang["wallet"]["nickname"](),
    nicknameCanBeAddedLater: lang["wallet"]["nicknameCanBeAddedLater"](),
    relatedChains: {
      body: (args: { chains: Arg }): string =>
        lang["wallet"]["relatedChains"]["body"](args) as string,
      title: lang["wallet"]["relatedChains"]["title"](),
    },
    safetyInfo: {
      frontPart: lang["wallet"]["safetyInfo"]["frontPart"](),
      highlightPart: lang["wallet"]["safetyInfo"]["highlightPart"](),
      lastPart: lang["wallet"]["safetyInfo"]["lastPart"](),
    },
    softSync: {
      action: lang["wallet"]["softSync"]["action"](),
      title: (args: { name: Arg }): string =>
        lang["wallet"]["softSync"]["title"](args) as string,
    },
    submit: (args: { source: Arg }): string =>
      lang["wallet"]["submit"](args) as string,
    submitMultiple: lang["wallet"]["submitMultiple"](),
    title: lang["wallet"]["title"](),
    type: lang["wallet"]["type"](),
    walletNicknameUpdate: lang["wallet"]["walletNicknameUpdate"](),
    wentWrongSavingWalletData: lang["wallet"]["wentWrongSavingWalletData"](),
    wentWrongSyncingWalletData: lang["wallet"]["wentWrongSyncingWalletData"](),
  },
  warningDisplayName: {
    all: lang["warningDisplayName"]["all"](),
    missingBlockchain: lang["warningDisplayName"]["missingBlockchain"](),
    missingPrice: lang["warningDisplayName"]["missingPrice"](),
    negativeBalance: lang["warningDisplayName"]["negativeBalance"](),
    suspectedAirdropTransaction:
      lang["warningDisplayName"]["suspectedAirdropTransaction"](),
    uncategorised: lang["warningDisplayName"]["uncategorised"](),
    unknownInOut: lang["warningDisplayName"]["unknownInOut"](),
    zeroCostBuy: lang["warningDisplayName"]["zeroCostBuy"](),
  },
  warnings: {
    missingBlockchain: lang["warnings"]["missingBlockchain"](),
    missingPrice: (args: { symbol: Arg }): string =>
      lang["warnings"]["missingPrice"](args) as string,
    negativeBalance: (args: {
      balanceRemaining: Arg;
      symbol: Arg;
      balanceTotal: Arg;
    }): string => lang["warnings"]["negativeBalance"](args) as string,
    uncategorised: (args: { trade: Arg }): string =>
      lang["warnings"]["uncategorised"](args) as string,
    unknownInOut: lang["warnings"]["unknownInOut"](),
    zeroCostBuy: lang["warnings"]["zeroCostBuy"](),
  },
  warningsDropdown: {
    missingImportData: {
      description:
        lang["warningsDropdown"]["missingImportData"]["description"](),
      title: lang["warningsDropdown"]["missingImportData"]["title"](),
    },
  },
  was: lang["was"](),
  watchVideo: lang["watchVideo"](),
  worksWith: lang["worksWith"](),
  xero: {
    accountMapping: {
      accounts: {
        asset: {
          title: lang["xero"]["accountMapping"]["accounts"]["asset"]["title"](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["asset"]["tooltip"](),
        },
        cash: {
          title: lang["xero"]["accountMapping"]["accounts"]["cash"]["title"](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["cash"]["tooltip"](),
        },
        expense: {
          title:
            lang["xero"]["accountMapping"]["accounts"]["expense"]["title"](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["expense"]["tooltip"](),
        },
        fee: {
          title: lang["xero"]["accountMapping"]["accounts"]["fee"]["title"](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["fee"]["tooltip"](),
        },
        income: {
          title:
            lang["xero"]["accountMapping"]["accounts"]["income"]["title"](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["income"]["tooltip"](),
        },
        loan: {
          title: lang["xero"]["accountMapping"]["accounts"]["loan"]["title"](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["loan"]["tooltip"](),
        },
        loss: {
          title: lang["xero"]["accountMapping"]["accounts"]["loss"]["title"](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["loss"]["tooltip"](),
        },
        "realised-gain": {
          title:
            lang["xero"]["accountMapping"]["accounts"]["realised-gain"][
              "title"
            ](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["realised-gain"][
              "tooltip"
            ](),
        },
        "realised-loss": {
          title:
            lang["xero"]["accountMapping"]["accounts"]["realised-loss"][
              "title"
            ](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["realised-loss"][
              "tooltip"
            ](),
        },
        "zero-cost-buy": {
          title:
            lang["xero"]["accountMapping"]["accounts"]["zero-cost-buy"][
              "title"
            ](),
          tooltip:
            lang["xero"]["accountMapping"]["accounts"]["zero-cost-buy"][
              "tooltip"
            ](),
        },
      },
      lastSynced: (args: { date: Arg }): string =>
        lang["xero"]["accountMapping"]["lastSynced"](args) as string,
      refreshInstructions:
        lang["xero"]["accountMapping"]["refreshInstructions"](),
      syncError: lang["xero"]["accountMapping"]["syncError"](),
      syncInstructions: lang["xero"]["accountMapping"]["syncInstructions"](),
      title: lang["xero"]["accountMapping"]["title"](),
    },
    buttons: {
      disconnect: lang["xero"]["buttons"]["disconnect"](),
      refreshList: {
        completed: lang["xero"]["buttons"]["refreshList"]["completed"](),
        done: lang["xero"]["buttons"]["refreshList"]["done"](),
        pending: lang["xero"]["buttons"]["refreshList"]["pending"](),
        tooltip: {
          done: lang["xero"]["buttons"]["refreshList"]["tooltip"]["done"](),
          pending:
            lang["xero"]["buttons"]["refreshList"]["tooltip"]["pending"](),
        },
      },
      syncAccounts: {
        done: lang["xero"]["buttons"]["syncAccounts"]["done"](),
        pending: lang["xero"]["buttons"]["syncAccounts"]["pending"](),
        tooltip: {
          disabled:
            lang["xero"]["buttons"]["syncAccounts"]["tooltip"]["disabled"](),
          done: lang["xero"]["buttons"]["syncAccounts"]["tooltip"]["done"](),
          pending:
            lang["xero"]["buttons"]["syncAccounts"]["tooltip"]["pending"](),
        },
      },
    },
    connect: lang["xero"]["connect"](),
    errors: {
      disconnecting: lang["xero"]["errors"]["disconnecting"](),
      fetchingAccounts: lang["xero"]["errors"]["fetchingAccounts"](),
      fetchingSettings: lang["xero"]["errors"]["fetchingSettings"](),
      fetchingTenants: lang["xero"]["errors"]["fetchingTenants"](),
      updatingAccounts: lang["xero"]["errors"]["updatingAccounts"](),
      updatingTenant: lang["xero"]["errors"]["updatingTenant"](),
    },
    integrationName: lang["xero"]["integrationName"](),
    integrationShortName: lang["xero"]["integrationShortName"](),
    newIntegration: {
      connected: lang["xero"]["newIntegration"]["connected"](),
      connectedAt: (args: { date: Arg }): string =>
        lang["xero"]["newIntegration"]["connectedAt"](args) as string,
      description: lang["xero"]["newIntegration"]["description"](),
      disconnectConfirmation:
        lang["xero"]["newIntegration"]["disconnectConfirmation"](),
      disconnected: lang["xero"]["newIntegration"]["disconnected"](),
      status: lang["xero"]["newIntegration"]["status"](),
      title: lang["xero"]["newIntegration"]["title"](),
    },
    reportFooter: {
      settingsLink: lang["xero"]["reportFooter"]["settingsLink"](),
      subTitle: lang["xero"]["reportFooter"]["subTitle"](),
      title: lang["xero"]["reportFooter"]["title"](),
    },
    rollup: {
      day: lang["xero"]["rollup"]["day"](),
      description: {
        day: lang["xero"]["rollup"]["description"]["day"](),
        month: lang["xero"]["rollup"]["description"]["month"](),
        none: lang["xero"]["rollup"]["description"]["none"](),
      },
      month: lang["xero"]["rollup"]["month"](),
      none: lang["xero"]["rollup"]["none"](),
    },
    syncDialog: {
      confirmation: lang["xero"]["syncDialog"]["confirmation"](),
      error: lang["xero"]["syncDialog"]["error"](),
      syncing: lang["xero"]["syncDialog"]["syncing"](),
    },
    tenantList: {
      ensureCorrectTenant: lang["xero"]["tenantList"]["ensureCorrectTenant"](),
      exportJournals: lang["xero"]["tenantList"]["exportJournals"](),
      listPlaceholder: lang["xero"]["tenantList"]["listPlaceholder"](),
      listTitle: lang["xero"]["tenantList"]["listTitle"](),
    },
  },
  yes: lang["yes"](),
};
