import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import type * as React from "react";
import { useDispatch } from "react-redux";

import {
  experiments,
  isExperiment,
  useFeatureFlags,
} from "~/analytics/posthog";
import { useConditionalAutoFocus } from "~/components/ui/hooks";
import { Spinner } from "~/components/ui/Spinner";
import { displayMessage } from "~/components/ui/Toaster";
import { useDesign } from "~/hooks/useTheme";
import { useIsLoadingUser, useUser } from "~/redux/auth";
import {
  setFeatureFlagOverride,
  setFeatureFlagOverrides,
  useFeatureFlagOverrides,
} from "~/redux/developer";
import { useLang } from "~/redux/lang";
import { DisplayMessage } from "~/types/enums";
import { type FeatureFlag } from "~/types/enums";

function FeatureFlagsForm() {
  const { tokens } = useDesign();
  const conditionalAutoFocus = useConditionalAutoFocus();
  const lang = useLang();
  const featureFlagOverrides = useFeatureFlagOverrides();
  const dispatch = useDispatch();
  const { flags } = useFeatureFlags();

  const handleClearOverrides = () => {
    dispatch(setFeatureFlagOverrides({}));

    displayMessage({
      message: "Flags cleared",
      type: DisplayMessage.Success,
    });
  };

  const handleFlagDelete = (flag: string) => () => {
    const update = { ...featureFlagOverrides };
    delete update[flag];
    dispatch(setFeatureFlagOverrides(update));
    displayMessage({
      message: "Flag removed",
      type: DisplayMessage.Success,
    });
  };

  const handleSubmit = (event: any, values: any) => {
    event.preventDefault();

    const newFlags = values.reduce((acc: any, value: any) => {
      // default to existing override, or first experiment variant, or true when adding
      let enabled = featureFlagOverrides[value]?.enabled;
      if (!enabled) {
        enabled = isExperiment(value) ? experiments[value][0] : true;
      }

      return {
        ...acc,
        [value]: { name: value, enabled },
      };
    }, {});

    displayMessage({
      message:
        Object.keys(newFlags).length > Object.keys(featureFlagOverrides).length
          ? "Flag added"
          : "Flag removed",
      type: DisplayMessage.Success,
    });

    dispatch(setFeatureFlagOverrides(newFlags));
  };

  const selectedValues = Object.values(featureFlagOverrides).map(
    ({ name }) => name,
  );

  const flagsList: React.ReactNode[] = [];
  Object.values(featureFlagOverrides).forEach((flag) => {
    const flagName = flag.name as FeatureFlag;
    const options = isExperiment(flagName)
      ? experiments[flagName].map((variant) => ({
          label: variant,
          value: variant,
        }))
      : [
          { label: "On", value: true },
          { label: "Off", value: false },
        ];

    flagsList.push(
      <Box
        key={flag.name}
        sx={{
          borderTop: `1px solid ${tokens.border.neutral.default}`,
          padding: ".75rem",
          marginTop: "-1px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
        >
          <Typography variant="Metropolis/Body/Light" sx={{ marginRight: "auto" }}>
            <code
              style={{
                background: tokens.background.accent.neutral.low,
                padding: "0.25rem 0.5rem",
                borderRadius: 3,
                fontWeight: 600,
              }}
            >
              {flag.name}
            </code>
          </Typography>

          <Select
            value={flag.enabled}
            onChange={(e) => {
              const newValue =
                e.target.value === "true"
                  ? true
                  : e.target.value === "false"
                    ? false
                    : e.target.value;
              dispatch(setFeatureFlagOverride(flag.name, newValue));
            }}
            label="Value"
          >
            {options.map((option) => {
              return (
                <MenuItem key={option.label} value={`${option.value}`}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>

          <DeleteForeverIcon
            sx={{
              color: tokens.icon.danger,
              cursor: "pointer",
            }}
            fontSize="small"
            onClick={handleFlagDelete(flag.name)}
          />
        </Stack>
      </Box>,
    );
  });

  return (
    <Stack direction="column" spacing={3} sx={{ width: "100%" }}>
      <Box>
        <Stack direction="row" alignItems="flex-start">
          <Typography variant="Metropolis/Header/H4">
            {lang.developer.tabs.featureFlags}
          </Typography>
          <Button
            disabled={!Object.keys(featureFlagOverrides).length}
            color="error"
            variant="contained"
            size="small"
            sx={{ marginLeft: "auto" }}
            onClick={handleClearOverrides}
          >
            {lang.developer.clearFlagOverrides}
          </Button>
        </Stack>
      </Box>
      <Alert severity="warning">
        Use care when using feature flags - there is increased risk to your
        data, so do not use them on your real account.
      </Alert>
      <Box>
        <Box>
          <Typography variant="Metropolis/Body/Bold" sx={{ marginBottom: "1.5rem" }}>
            {lang.developer.addFlag}
          </Typography>
        </Box>
        <Autocomplete
          multiple
          value={selectedValues}
          options={flags}
          onChange={handleSubmit}
          renderInput={(params) => (
            <TextField
              {...params}
              {...conditionalAutoFocus}
              fullWidth
              label="Flag ID"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Box>
      <Box>
        <Typography variant="Metropolis/Body/Bold" sx={{ marginBottom: "1rem" }}>
          {lang.developer.activeFlags}
        </Typography>
        <Box
          sx={{
            borderRadius: "3px",
            border: `1px solid ${tokens.border.neutral.default}`,
            overflow: "hidden",
          }}
        >
          {flagsList.length ? (
            flagsList
          ) : (
            <Box sx={{ padding: ".75rem" }}>
              <Typography variant="Metropolis/Body/Light">
                {lang.developer.noActiveFlags}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  );
}

export function FeatureFlags() {
  const isLoadingUser = useIsLoadingUser();
  const user = useUser();
  if (isLoadingUser) {
    return <Spinner />;
  }
  if (!user) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Box px={{ xs: 2, sm: 0 }} mt={4}>
        <FeatureFlagsForm />
      </Box>
    </Grid>
  );
}
