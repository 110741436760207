import { Box, Link, Switch, Tooltip, Typography } from "@mui/material";

import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

type ToggleSwitchBoxProps = {
  title: string;
  checked: boolean;
  tooltipOn: string;
  tooltipOff: string;
  onChange: (checked: boolean) => void;
  learnMoreLink?: string;
  highlighted?: boolean;
};

function getLearnMoreLink({ link, lang }: { link: string; lang: any }) {
  return (
    <Link href={link} target="_blank">
      {lang.learnMore}
    </Link>
  );
}

export function ToggleSwitchBox({
  title,
  checked,
  tooltipOn,
  tooltipOff,
  onChange,
  learnMoreLink,
  highlighted = false,
}: ToggleSwitchBoxProps) {
  const { tokens } = useDesign();
  const lang = useLang();

  const tooltipContent = (
    <Box>
      <Typography variant="inherit">
        {checked ? tooltipOn : tooltipOff}
      </Typography>
      {learnMoreLink && (
        <Typography variant="inherit">
          {getLearnMoreLink({
            lang,
            link: learnMoreLink,
          })}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      gap="0.5rem"
      borderRadius="0.25rem"
      marginLeft="-0.5rem"
      paddingLeft="0.5rem"
      border={`1px solid ${highlighted ? tokens.border.brand : "rgba(0,0,0,0)"}`}
      bgcolor={highlighted ? tokens.background.brand.default : "rgba(0,0,0,0)"}
    >
      <Tooltip title={tooltipContent} placement="right">
        <Switch
          checked={checked}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          color="primary"
          inputProps={{
            "aria-label": title,
          }}
        />
      </Tooltip>
      <Typography variant="Metropolis/Body/Regular" color={tokens.text.low}>
        {highlighted ? `✨ ${title}` : title}
      </Typography>
    </Box>
  );
}
