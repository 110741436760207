import { type Plan } from "@ctc/types";
import { useStripe } from "@stripe/react-stripe-js";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  captureGoogleAnalytics,
  captureGoogleAnalyticsEnhanced,
} from "~/analytics/google";
import { handlePayments } from "~/components/nav/AccountMenu";
import { getBilledCurrencyForCountry } from "~/components/payment/helpers";
import { getGAItem } from "~/components/payment/payment/helpers/getGAItem";
import { usePaymentPopup } from "~/components/payment/usePaymentPopup";
import { hashPlanChangeEvent } from "~/components/payment-status/helpers";
import { displayMessage } from "~/components/ui/Toaster";
import { LocalStorageKey } from "~/constants/enums";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import {
  getCountryPricesDEPRECATED,
  useUserDiscountDEPRECATED,
} from "~/hooks/useUserDiscountDEPRECATED";
import {
  loadUser,
  useCountry,
  useIsAccountant,
  useIsManagingOwnBilling,
  useUser,
  useUserHasPaid,
} from "~/redux/auth";
import { LoadUserType } from "~/redux/enums";
import { useLang } from "~/redux/lang";
import { Analytics } from "~/segment/index";
import * as subscriptionAPI from "~/services/subscription";
import { type StripeCouponCode } from "~/types";
import { DisplayMessage, Links } from "~/types/enums";

interface PaymentContextType {
  /** Whether the payment process is currently redirecting */
  isRedirecting: boolean;
  /** Handler for changing plans */
  handlePlanChange: (
    plan: Plan,
    applyCoupon?: boolean,
    couponCode?: StripeCouponCode,
  ) => Promise<void>;
}

const PaymentContext = createContext<PaymentContextType | null>(null);

export function PaymentProvider({ children }: { children: React.ReactNode }) {
  const stripe = useStripe();
  const lang = useLang();
  const user = useUser();
  const navigate = useNavigate();
  const isManagingOwnBilling = useIsManagingOwnBilling();
  const country = useCountry();
  const isPaidPlan = useUserHasPaid();
  const discount = useUserDiscountDEPRECATED();
  const isAccountant = useIsAccountant();
  const isEmbedded = useIsEmbedded();
  const dispatch = useDispatch();
  const { openPopup: openPaymentPopup, isOpen: isPaymentPopupOpen } =
    usePaymentPopup();
  const [isRedirecting, setIsRedirecting] = useState(isPaymentPopupOpen);

  const currentPlan: Plan = user?.paidPlan as Plan;

  useEffect(() => {
    if (isEmbedded) setIsRedirecting(isPaymentPopupOpen);
  }, [isEmbedded, isPaymentPopupOpen]);

  const handlePlanChange = async (
    plan: Plan,
    applyCoupon = true,
    couponCode: StripeCouponCode | undefined,
  ) => {
    if (!user || !country || !isManagingOwnBilling || !stripe) return;

    if (isPaidPlan) {
      if (plan === currentPlan) {
        return;
      }
      await handlePayments(setIsRedirecting, stripe, lang, plan);
      navigate(Links.PaymentPending);
      return;
    }
    setIsRedirecting(true);

    // Create checkout session
    const res = await subscriptionAPI.createCheckoutSession({
      plan,
      applyCoupon,
      isEmbedded,
      couponCode,
    });

    if (res.error) {
      setIsRedirecting(false);
      displayMessage({
        message: res.msg || lang.billing.stripe.noSession,
        type: DisplayMessage.Error,
      });
      return;
    }

    const analytics = await Analytics.getInstance();
    analytics.track({
      event: "plan_purchase_clicked",
      user,
      props: { current_plan: user?.paidPlan, upgrade_plan: plan },
    });
    captureGoogleAnalytics({
      category: "payment",
      action: `click buy now for ${plan}`,
    });
    const billedCurrency = getBilledCurrencyForCountry(country);
    const prices = getCountryPricesDEPRECATED(country);
    const total = discount.priceList[plan];
    const item = getGAItem({
      isAccountant,
      newPlan: plan,
      currency: billedCurrency,
      price: prices[plan],
      total,
      currentPlan,
    });
    captureGoogleAnalyticsEnhanced("event", "begin_checkout", {
      currency: billedCurrency,
      value: total,
      items: [item],
    });

    localStorage.setItem(
      LocalStorageKey.PlanUpgradeEvent,
      hashPlanChangeEvent({
        newPlan: plan,
        oldPlan: currentPlan,
      }),
    );

    // embedded experience should open payment in popup
    if (isEmbedded) {
      openPaymentPopup({
        options: {
          url: res.data.url,
        },
        onPaymentSuccess: () => {
          setIsRedirecting(false);
        },
        onPaymentCancelled: () => {
          setIsRedirecting(false);
          localStorage.removeItem(LocalStorageKey.PlanUpgradeEvent);
        },
        onPopupOpenFailed: () => {
          setIsRedirecting(false);
        },
        onClose: () => {
          // Always reload the user when the payment popup closes
          // This way if we miss the websocket at least the user will be reloaded and put into a paid state
          dispatch(loadUser(LoadUserType.UserUpdate));
        },
      });
    } else {
      // For normal experience: redirect in same tab
      stripe.redirectToCheckout({ sessionId: res.data.id });
    }
  };

  return (
    <PaymentContext.Provider value={{ isRedirecting, handlePlanChange }}>
      {children}
    </PaymentContext.Provider>
  );
}

export function usePayment() {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error("usePayment must be used within a PaymentProvider");
  }
  return context;
}
