export enum TableColumns {
  CurrencyIcon = "currencyIcon",
  Currency = "currency",
  Balance = "balance",
  Price = "price",
  Value = "value",
  Cost = "cost",
  Gain = "gain",
  AmountToSell = "amountToSell",
}
