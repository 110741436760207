import {
  Box,
  Button,
  CircularProgress,
  TextField,
  type TextFieldProps,
  Typography,
} from "@mui/material";
import type * as React from "react";
import styled from "styled-components/macro";

import { CensoredBox } from "~/components/ui/CensoredComponents";
import { Chip } from "~/components/ui/Chips";
import { TertiaryButton } from "~/components/ui/ui-buttons/TertiaryButton";
import { stripSpacesOnKeyDown } from "~/components/user/validators";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";

import { StyledTextFieldDisabled, Subheading } from "./Profile";

export enum VerifyEmailState {
  Sending = "sending",
  Sent = "sent",
  Errored = "errored",
}

/** Props for the EmailVerificationStatus component */
interface EmailVerificationStatusProps {
  /** Whether the email can be verified */
  canVerifyEmail: boolean;
  /** Whether the email is verified */
  isEmailVerified: boolean;
  /** Function to handle email verification */
  handleVerifyEmail?: () => void;
  /** Current state of email verification process */
  verifyEmailState?: VerifyEmailState;
  /** Language utilities object */
  lang: any;
  /** Theme tokens */
  tokens: any;
}

function EmailVerificationStatus({
  canVerifyEmail,
  isEmailVerified,
  handleVerifyEmail,
  verifyEmailState,
  lang,
  tokens,
}: EmailVerificationStatusProps) {
  if (!canVerifyEmail) return null;

  return (
    <>
      {!isEmailVerified && handleVerifyEmail && (
        <TertiaryButton
          variant="outlined"
          size="small"
          onClick={handleVerifyEmail}
          disabled={verifyEmailState === VerifyEmailState.Sending}
        >
          {verifyEmailState
            ? lang.settings.profile.resendVerificationEmail
            : lang.settings.profile.verifyEmail}
        </TertiaryButton>
      )}
      <Box sx={{ marginLeft: "0.375rem" }}>
        {(() => {
          if (!verifyEmailState) return null;
          if (verifyEmailState === VerifyEmailState.Sending) {
            return <CircularProgress size="1.5rem" />;
          }
          const emailSentSuccessfully =
            verifyEmailState === VerifyEmailState.Sent;
          return (
            <Chip
              bgcolor={
                emailSentSuccessfully
                  ? tokens.background.success.default
                  : tokens.background.danger.hover
              }
            >
              <Typography
                variant="Metropolis/Body/Light"
                sx={{
                  color: emailSentSuccessfully
                    ? tokens.text.success
                    : tokens.text.danger,
                }}
              >
                {emailSentSuccessfully
                  ? lang.settings.profile.emailSent
                  : lang.settings.profile.emailError}
              </Typography>
            </Chip>
          );
        })()}
      </Box>
    </>
  );
}

export function EditButtons({
  editMode,
  setEditMode,
  value,
  handleChangeValue,
  handleCancel,
  handleUpdate,
  isEmailVerified,
  verifyEmailState,
  handleVerifyEmail,
  canVerifyEmail,
  fieldName,
  buttonText,
  placeholder,
  isSpacesAllowed = true,
  allowEdit = true,
  inputProps,
  error,
  helperText,
  disabled,
}: {
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  value: string | undefined;
  handleChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCancel: () => void;
  handleUpdate: () => void;
  isEmailVerified?: boolean;
  verifyEmailState?: VerifyEmailState;
  handleVerifyEmail?: () => void;
  canVerifyEmail?: boolean;
  fieldName: string;
  buttonText: string;
  placeholder?: string;
  allowEdit?: boolean;
  isSpacesAllowed?: boolean;
  inputProps?: any;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}) {
  const lang = useLang();
  const { tokens } = useDesign();
  return (
    <CensoredBox display="flex" flexDirection="column" maxWidth="22rem">
      <Box
        display="flex"
        justifyContent="flex-start"
        width="100%"
        alignItems="center"
      >
        <Typography
          variant="Metropolis/Body/Light"
          display="inline"
          sx={{
            pr: "0.5rem",
          }}
        >
          <Subheading>{fieldName}</Subheading>
        </Typography>
        {handleVerifyEmail &&
          (canVerifyEmail ? (
            <Chip
              bgcolor={
                isEmailVerified
                  ? tokens.background.success.default
                  : tokens.background.accent.neutral.low
              }
            >
              <Typography
                variant="Metropolis/Body/Light"
                sx={{
                  color: isEmailVerified
                    ? tokens.text.success
                    : tokens.text.default,
                }}
              >
                {isEmailVerified
                  ? lang.settings.profile.verified
                  : lang.settings.profile.unverified}
              </Typography>
            </Chip>
          ) : (
            <Chip bgcolor={tokens.background.accent.neutral.low}>
              <Typography
                variant="Metropolis/Caption/Medium/Regular"
                sx={{
                  color: tokens.text.high,
                }}
              >
                {lang.settings.profile.notConnected}
              </Typography>
            </Chip>
          ))}
      </Box>
      <Box mt="0.5rem">
        <ProfileEditField
          inputProps={inputProps}
          error={error}
          helperText={helperText}
          disabled={disabled}
          isEditing={editMode}
          value={value}
          onChange={handleChangeValue}
          onKeyDown={isSpacesAllowed ? undefined : stripSpacesOnKeyDown}
          type="text"
          placeholder={placeholder}
          required
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        mt="0.5rem"
        alignItems="center"
      >
        {allowEdit &&
          (!editMode ? (
            <TertiaryButton
              variant="outlined"
              size="small"
              onClick={() => {
                setEditMode(true);
              }}
              sx={{ width: "fit-content", marginRight: "0.375rem" }}
            >
              {buttonText}
            </TertiaryButton>
          ) : (
            <>
              <TertiaryButton
                variant="outlined"
                size="small"
                onClick={handleCancel}
                sx={{ marginRight: "0.375rem" }}
              >
                {lang.cancel}
              </TertiaryButton>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={error}
                onClick={handleUpdate}
                sx={{ fontSize: "0.875rem" }}
              >
                {lang.save}
              </Button>
            </>
          ))}
        {canVerifyEmail && !editMode && (
          <EmailVerificationStatus
            canVerifyEmail={canVerifyEmail}
            isEmailVerified={isEmailVerified ?? false}
            handleVerifyEmail={handleVerifyEmail}
            verifyEmailState={verifyEmailState}
            lang={lang}
            tokens={tokens}
          />
        )}
      </Box>
    </CensoredBox>
  );
}

function ProfileEditField({
  isEditing,
  value,
  ...textFieldProps
}: {
  isEditing: boolean;
  value: string | undefined;
} & TextFieldProps) {
  if (!isEditing) {
    return (
      <StyledTextFieldDisabled placeholder={!value}>
        {value || textFieldProps.placeholder}
      </StyledTextFieldDisabled>
    );
  }
  return (
    // Input fields are censored by default
    <StyledTextField
      variant="outlined"
      size="small"
      value={value}
      {...textFieldProps}
    />
  );
}

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    max-width: 22rem;
    background: none;
  }
  .MuiInputBase-input {
    font-size: 0.875rem;
    padding: 0.6rem 0.5rem 0.5rem 0.75rem;
  }
  .MuiInputBase-root {
    background: ${({ theme }) => theme.tokens.background.input.default};
  }
`;
