import { Player } from "@lottiefiles/react-lottie-player";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import animation from "~/contexts/paywall-modal-context/irs-paywall-modal/animations/upsell-sidepanel.json";
import { useDesign } from "~/hooks/useTheme";

export function AnimationPanel({
  topSection,
  disableAnimation = false,
}: {
  /** The content to display in the top section of the panel */
  topSection?: React.ReactNode | null;
  /** Whether to disable the animation */
  disableAnimation?: boolean;
}) {
  const { tokens } = useDesign();
  const topSectionRef = useRef<HTMLDivElement>(null);
  const [minHeight, setMinHeight] = useState("20rem");
  const observerRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    if (!topSectionRef.current) return;

    const updateHeight = (height: number) => {
      setMinHeight(
        `calc(${disableAnimation ? "0rem" : "18rem"} + ${height}px)`,
      );
    };

    observerRef.current = new ResizeObserver((entries) => {
      for (const entry of entries) {
        updateHeight(entry.contentRect.height);
      }
    });

    observerRef.current.observe(topSectionRef.current);

    return () => {
      observerRef.current?.disconnect();
    };
  }, [disableAnimation]);

  if (disableAnimation && topSectionRef.current) {
    const topSectionHeight = topSectionRef.current.offsetHeight;
    if (topSectionHeight === 0) {
      return null;
    }
  }

  return (
    <Box
      display="flex"
      borderRadius="0.5rem"
      border={`1px solid ${tokens.border.neutral.high}`}
      sx={{
        background: "linear-gradient(180deg, #250B54 0%, #622CBF 100%)",
        position: "relative",
      }}
      flexDirection="column"
      justifyContent="space-between"
      overflow="hidden"
      width={disableAnimation ? "100%" : "11.625rem"}
      height="100%"
      minHeight={minHeight}
    >
      {disableAnimation ? null : (
        <Box
          sx={{
            position: "absolute",
            bottom: -8,
            left: 0,
            right: 0,
          }}
        >
          <Player autoplay keepLastFrame src={animation} />
        </Box>
      )}
      {topSection && <Box ref={topSectionRef}>{topSection}</Box>}
    </Box>
  );
}
