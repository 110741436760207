import { type Trade } from "@ctc/types";
import { Link, WarningAmberOutlined } from "@mui/icons-material";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import sum from "lodash/sum";
import { useEffect, useRef, useState } from "react";
import {
  FormProvider,
  type FormState,
  useFieldArray,
  useForm,
  useFormContext,
  type UseFormReturn,
} from "react-hook-form";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureActionAnalytics } from "~/analytics/posthog";
import { CommandPaletteBonesProviders } from "~/components/transactions/command-palette/useCommandPalleteBones";
import { createTxAmountPatch } from "~/components/transactions/edit/createTxAmountPatches";
import {
  type EditActionDrawerTradeValues,
  getValues as getTradeValues,
  useRowTradeFromForm,
} from "~/components/transactions/edit/EditActionTrade";
import {
  EditActionTxAmount,
  type TxAmount,
} from "~/components/transactions/edit/EditActionTxAmount";
import { EditTxTradeButton } from "~/components/transactions/edit/EditTxTradeButton";
import { LockedTxEditFallback } from "~/components/transactions/edit/helper";
import { TradeTypeTitle } from "~/components/transactions/edit/TradeTypeTitle";
import { GeneralDialogForm } from "~/components/ui/GeneralDialog";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useDesign } from "~/hooks/useTheme";
import { GroupingCriteria } from "~/lib/enums";
import { groupingCriteriaDefinitions } from "~/lib/groupingCriteraDefinition";
import { displayFiatValue, multiply } from "~/lib/index";
import { ActionDefinitions, TradeInfo } from "~/lib/tradeTypeDefinitions";
import { useLocalCurrency } from "~/redux/auth";
import { useLang, useLanguagePreference } from "~/redux/lang";
import {
  getFeesFromActionRow,
  getMissingPrice,
  type PutTransactionPayload,
} from "~/services/actions";
import { isCurrencyNFTForTaxPurposes } from "~/services/currencies";
import { isGroupedTrade, isTransferLike } from "~/services/transactions";
import { useAtomicUpdateTransactions } from "~/state/actions";
import { type QuickEditFocusField } from "~/types/enums";
import { Direction, GroupedTrade, TradeDirection } from "~/types/enums";
import {
  type ActionRow,
  type ActionType,
  type TransactionDetails,
} from "~/types/index";

export type EditActionAmountsValues = {
  amountIncoming: TxAmount[];
  amountOutgoing: TxAmount[];
  updateMarkAsNFT: Record<string, boolean>;
  trade?: ActionType;
};

type AmountIncomingOutgoingKeys = Extract<
  keyof EditActionAmountsValues,
  "amountIncoming" | "amountOutgoing"
>;

export const nameMap: Record<Direction, AmountIncomingOutgoingKeys> = {
  [Direction.Incoming]: "amountIncoming",
  [Direction.Outgoing]: "amountOutgoing",
};

const relevantGroupCriteria = [
  GroupingCriteria.SameCurrency,
  GroupingCriteria.DifferentCurrency,
  GroupingCriteria.SameValueFeeExclusive,
  GroupingCriteria.SameValueFeeInclusive,
  GroupingCriteria.SimilarQuantity,
];

const getTxAmountValues = (tx: TransactionDetails): TxAmount => ({
  txId: tx._id,
  timestamp: tx.timestamp,
  currency: tx.currencyIdentifier,
  quantity: tx.quantity.toString(),
  price: tx.price.toString(),
  value: multiply(tx.price, tx.quantity).toString(),
  markIgnoreMissingPrice: tx.ignoreWarning?.missingPrice || false,
  groupById: tx.groupById,
  blockchain: tx.blockchain,
});

export const getValues = ({
  row,
}: {
  row: ActionRow;
}): EditActionAmountsValues => {
  const result = {
    amountIncoming: row.incoming.map(getTxAmountValues),
    amountOutgoing: row.outgoing.map(getTxAmountValues),
    updateMarkAsNFT: [...row.incoming, ...row.outgoing].reduce(
      (obj: Record<string, boolean>, tx) => ({
        ...obj,
        [tx.currencyIdentifier.id]: isCurrencyNFTForTaxPurposes(
          tx.currencyIdentifier,
        ),
      }),
      {},
    ),
  };

  return result;
};

export const getUpdatedMarkAsNFTCurrencyIds = ({
  formState,
  formData,
}: {
  formState: FormState<EditActionAmountsValues>;
  formData: EditActionAmountsValues;
}): string[] => {
  const dirtyCurrencyIds = Object.keys(
    formState.dirtyFields?.updateMarkAsNFT || {},
  ).filter(
    (currencyId) =>
      formState.defaultValues?.updateMarkAsNFT?.[currencyId] !==
      formData.updateMarkAsNFT[currencyId],
  );
  return dirtyCurrencyIds;
};

export function applyUpdates<T extends EditActionAmountsValues>(
  row: ActionRow,
  form: UseFormReturn<T>,
  formData: EditActionAmountsValues,
  updateMap: Map<string, PutTransactionPayload>,
) {
  const { amountIncoming = [], amountOutgoing = [] } = formData;

  const process = (
    tx: TxAmount[],
    txs: TransactionDetails[],
    name: AmountIncomingOutgoingKeys,
  ) => {
    tx.forEach((amount, index) => {
      const dirty = (form.formState.dirtyFields[name] || [])[index];
      const tx = txs[index];
      const {
        price,
        currency,
        quantity,
        priceValueCurrency,
        groupById,
        markIgnoreMissingPrice,
        blockchain,
      } = amount;

      // Make sure TX exists and has been changed.
      if (!tx || !dirty) return;

      createTxAmountPatch({
        dirty,
        price,
        txId: tx._id,
        quantity,
        priceValueCurrency,
        currency,
        groupById,
        markIgnoreMissingPrice,
        updateMap,
        blockchain,
      });
    });
  };

  process(amountOutgoing, row.outgoing, "amountOutgoing");
  process(amountIncoming, row.incoming, "amountIncoming");
}

export function EditActionAmountsDialog({
  row,
  isOpen,
  handleClose,
  focusField,
}: {
  row: ActionRow;
  isOpen: boolean;
  handleClose: () => void;
  focusField?: QuickEditFocusField;
}) {
  const lang = useLang();
  const updateRowTransactionMutation = useAtomicUpdateTransactions();
  const form = useForm<
    EditActionAmountsValues & EditActionDrawerTradeValues,
    { grouping: boolean }
  >({
    mode: "onBlur",
    defaultValues: {
      ...getTradeValues({ row }),
      ...getValues({ row }),
    },
  });

  // We need to purge stale data and reset the form when it is opened.
  useEffect(() => {
    if (isOpen) {
      form.reset({
        ...getTradeValues({ row }),
        ...getValues({ row }),
      });
    }
  }, [row, isOpen, form]);

  const captureActionAnalytics = useCaptureActionAnalytics();
  const analyticsKey = transactionAnalyticsKey("edit");

  const { isDirty, isValid, dirtyFields } = form.formState;
  // isDirty is true if you change a value then change it back
  // so we need to check if there are any dirty fields.
  const isDisabled =
    !isDirty || Object.keys(dirtyFields).length === 0 || !isValid;

  const handleSubmit = async (formData: EditActionAmountsValues) => {
    if (isDisabled) return;

    const updateMap = new Map<string, PutTransactionPayload>();
    applyUpdates(row, form, formData, updateMap);
    const payload = Array.from(updateMap.values());

    const dirtyCurrencyIds = getUpdatedMarkAsNFTCurrencyIds({
      formState: form.formState,
      formData,
    });
    const markAsNFTUpdates = dirtyCurrencyIds.reduce(
      (updatesObj: Record<string, boolean>, currencyId) => {
        const isMarkedAsNFT = formData.updateMarkAsNFT[currencyId];
        if (isMarkedAsNFT === undefined) {
          return updatesObj; // This shouldn't happen.
        }
        updatesObj[currencyId] = isMarkedAsNFT;
        return updatesObj;
      },
      {},
    );

    updateRowTransactionMutation.mutate(
      {
        actionId: row._id,
        update: {
          updateTx: { payload, applySts: false, createCategoryRule: false },
        },
        markAsNFT: markAsNFTUpdates,
      },
      {
        onSuccess: () => {
          form.reset();
          handleClose();
        },
      },
    );
    captureActionAnalytics(analyticsKey("quick edit amount submitted"), row);
  };

  return (
    <GeneralDialogForm
      pending={updateRowTransactionMutation.isPending}
      isOpen={isOpen}
      handleClose={() => {
        form.reset();
        handleClose();
      }}
      fullWidth
      title={lang.editTx.editPriceCurrencyValue}
      maxWidth="md"
      handleAction={form.handleSubmit(handleSubmit)}
      actionText={lang.update}
      cancelText={lang.cancel}
      stopPropagation
      closeOnClickAway={!updateRowTransactionMutation.isPending}
      disableAction={isDisabled}
    >
      <FormProvider {...form}>
        <EditActionAmounts row={row} focusField={focusField} />
      </FormProvider>
    </GeneralDialogForm>
  );
}

export const EditActionAmounts = ({
  row,
  focusField,
  editTrade = false,
}: {
  row: ActionRow;
  focusField?: QuickEditFocusField;
  editTrade?: boolean;
}) => {
  const { outgoing, incoming, type, isLocked } = row;
  const isUncategorisedGroup = type === GroupedTrade.Uncategorised;
  const outgoingLength = outgoing.length;
  const incomingLength = incoming.length;

  // If we are linking the value and the transaction is a transfer, we should
  // modify the quantity to achieve this
  const transferLike = isTransferLike(type);

  const isLinkable = transferLike
    ? !isUncategorisedGroup && outgoingLength === 1 && incomingLength === 1
    : !isUncategorisedGroup && outgoingLength >= 1 && incomingLength >= 1;

  const [shouldLinkValue, setShouldLinkValue] = useState(isLinkable);

  if (isLocked) {
    return <LockedTxEditFallback />;
  }

  // If we can edit the trade types, and we are in an uncategorised group, we
  // want the user to be able to edit the components of the tx individually
  if (editTrade && isUncategorisedGroup) {
    return (
      <Stack direction="column" gap="0.75rem">
        <ActionAmountsWarnings row={row} />
        {[...outgoing, ...incoming].map((tx) => {
          const definition = TradeInfo[tx.trade];
          const side =
            definition.direction === TradeDirection.In
              ? Direction.Incoming
              : Direction.Outgoing;
          return (
            <RowSideAmounts
              key={tx._id}
              tx={[tx]}
              side={side}
              shouldLinkValue={shouldLinkValue}
              modifyQuantity={transferLike}
              focusField={focusField}
              row={row}
              editTrade={editTrade}
            />
          );
        })}
      </Stack>
    );
  }

  return (
    <Stack direction="column" gap="0.75rem">
      <ActionAmountsWarnings row={row} />
      {outgoingLength > 0 ? (
        <RowSideAmounts
          tx={[...outgoing]}
          side={Direction.Outgoing}
          shouldLinkValue={shouldLinkValue}
          modifyQuantity={transferLike}
          focusField={focusField}
          row={row}
          editTrade={editTrade}
        />
      ) : null}
      {isLinkable ? (
        <LinkValueToggle
          checked={shouldLinkValue}
          setChecked={setShouldLinkValue}
          transferLike={transferLike}
        />
      ) : null}
      {incomingLength > 0 ? (
        <RowSideAmounts
          tx={[...incoming]}
          side={Direction.Incoming}
          shouldLinkValue={shouldLinkValue}
          modifyQuantity={transferLike}
          focusField={focusField}
          row={row}
          editTrade={editTrade}
        />
      ) : null}
    </Stack>
  );
};

function LinkValueToggle({
  checked,
  setChecked,
  transferLike,
}: {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  transferLike: boolean;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const onClick = () => {
    setChecked(!checked);
  };

  const tooltipText = transferLike
    ? lang.editTx.linkValueTooltipTransferLike
    : lang.editTx.linkValueTooltipTradeLike;

  return (
    <Box
      style={{
        textAlign: "center",
        transition: "margin 50ms ease-out",
        margin: checked ? "-1.25rem 0" : "0",
      }}
    >
      <FormControlLabel
        label=""
        control={
          <Tooltip title={tooltipText} arrow placement="top">
            <Checkbox
              checked={checked}
              sx={{
                padding: "0 1rem",
                color: `${tokens.text.default} !important`,
                backgroundColor: tokens.background.neutral.default,
                border: `1px solid ${tokens.border.neutral.default}`,
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: tokens.background.neutral.pressed,
                },
              }}
              icon={<LinkOffIcon />}
              checkedIcon={<Link />}
              onClick={onClick}
            />
          </Tooltip>
        }
      />
    </Box>
  );
}

function useFormGroupingErrors(row: ActionRow): GroupingCriteria[] {
  const { watch } = useFormContext<EditActionAmountsValues>();
  const { watch: watchTrade } = useFormContext<EditActionDrawerTradeValues>();
  const [subComponentTrades] = watchTrade(["subComponentTrades"]);
  const actionType = useRowTradeFromForm(row, subComponentTrades);

  const groupCriteria = isGroupedTrade(actionType)
    ? ActionDefinitions[actionType].groupCriteria
    : [];

  // If there are no grouping criteria, there can be no grouping warnings
  if (groupCriteria.length === 0) return [];

  const formValues = watch();

  let inValue = 0;
  let outValue = 0;
  let inQuantity = 0;
  let outQuantity = 0;
  const inCurrencies = new Set<string>();
  const outCurrencies = new Set<string>();

  formValues[nameMap[Direction.Incoming]].forEach((tx) => {
    if (tx.currency) {
      if (typeof tx.currency === "string") inCurrencies.add(tx.currency);
      else inCurrencies.add(tx.currency.id);
    }
    inValue += Number(tx.value);
    inQuantity += Number(tx.quantity);
  });
  formValues[nameMap[Direction.Outgoing]].forEach((tx) => {
    if (tx.currency) {
      if (typeof tx.currency === "string") outCurrencies.add(tx.currency);
      else outCurrencies.add(tx.currency.id);
    }
    outValue += Number(tx.value);
    outQuantity += Number(tx.quantity);
  });

  const fees = getFeesFromActionRow(row);
  const feeValue = sum(fees.map((fee) => fee.value));

  const checkValues = {
    inValue,
    outValue,
    inQuantity,
    outQuantity,
    inCurrencies: Array.from(inCurrencies),
    outCurrencies: Array.from(outCurrencies),
    feeValue,
  };

  const errors: GroupingCriteria[] = [];
  relevantGroupCriteria
    .filter((criteria) => groupCriteria.includes(criteria))
    .forEach((criteria) => {
      const { checkFunction } = groupingCriteriaDefinitions[criteria];
      if (!checkFunction(checkValues)) {
        errors.push(criteria);
      }
    });

  return errors;
}

function ActionAmountsWarnings({ row }: { row: ActionRow }) {
  const { tokens } = useDesign();
  const lang = useLang();
  const errors = useFormGroupingErrors(row);

  if (!errors.length) {
    return null;
  }

  return (
    <Box
      p="1rem"
      border={`1px solid ${tokens.border.warning}`}
      borderRadius="0.25rem"
      bgcolor={tokens.background.warning.default}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" alignItems="center" gap="0.25rem">
        <WarningAmberOutlined
          style={{
            fontSize: "1rem",
            color: tokens.icon.warning,
          }}
        />
        <Typography color={tokens.text.warning} variant="Metropolis/Body/Regular">
          {lang.editTx.ungroupWarning({ count: errors.length })}
        </Typography>
      </Box>
      {errors.map((e) => {
        return (
          <Typography
            color={tokens.text.warning}
            variant="Metropolis/Caption/Medium/Regular"
            sx={{ marginLeft: "1.75rem" }}
            key={e}
          >
            {"\u2022 "}
            {lang.editTx.ungroupCriteria[e]}
          </Typography>
        );
      })}
    </Box>
  );
}

/**
 * Renders a list of EditActionTxAmount components in a non-virtualized manner.
 * This is used when the list is small or on mobile devices.
 * @param tx - The list of transactions to render.
 * @param name - The name of the form field.
 * @param shouldLinkValue - Whether the value should be linked.
 * @param modifyQuantity - Whether the quantity should be modified.
 * @param focusField - The field to focus on.
 * @param side - The side of the transaction.
 * @returns The rendered list of EditActionTxAmount components.
 */
function NonVirtualizedEditActionTxAmounts({
  tx,
  name,
  shouldLinkValue,
  modifyQuantity,
  focusField,
  side,
}: {
  tx: TransactionDetails[];
  name: string;
  shouldLinkValue: boolean;
  modifyQuantity: boolean;
  focusField?: QuickEditFocusField;
  side: Direction;
}) {
  const { control } = useFormContext<EditActionAmountsValues>();
  const { fields } = useFieldArray({
    control,
    name: nameMap[side],
  });
  return (
    <>
      {tx.map((t: TransactionDetails) => {
        const { errors, ignoreWarning, price } = t;
        const showMissingPriceWarning = getMissingPrice(
          errors,
          ignoreWarning,
          price,
        );
        const fieldIndex = fields.findIndex((field) => field.txId === t._id);
        return (
          <div key={t.id}>
            <EditActionTxAmount
              key={fields[fieldIndex].id}
              index={fieldIndex}
              showMissingPriceWarning={showMissingPriceWarning}
              name={name}
              shouldLinkValue={shouldLinkValue}
              modifyQuantity={modifyQuantity}
              focusField={focusField}
              side={side}
              timestamp={t.timestamp}
              blockchain={t.blockchain}
            />
          </div>
        );
      })}
    </>
  );
}

/**
 * Renders a list of EditActionTxAmount components in a virtualized manner.
 * This is used when the list is large and on desktop devices.
 * @param tx - The list of transactions to render.
 * @param name - The name of the form field.
 * @param shouldLinkValue - Whether the value should be linked.
 * @param modifyQuantity - Whether the quantity should be modified.
 * @param focusField - The field to focus on.
 * @param side - The side of the transaction.
 * @returns The rendered list of EditActionTxAmount components.
 */
function VirtualizedEditActionTxAmounts({
  tx,
  name,
  shouldLinkValue,
  modifyQuantity,
  focusField,
  side,
}: {
  tx: TransactionDetails[];
  name: string;
  shouldLinkValue: boolean;
  modifyQuantity: boolean;
  focusField?: QuickEditFocusField;
  side: Direction;
}) {
  const { control } = useFormContext<EditActionAmountsValues>();
  const { fields } = useFieldArray({
    control,
    name: nameMap[side],
  });

  const parentRef = useRef<HTMLDivElement>(null);

  const rowSize = 70;
  const virtualizer = useVirtualizer({
    count: tx.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => rowSize,
    overscan: 1,
  });

  // The size of all the other content, incoming outgoing headings, total values,
  // submit buttons, etc.
  const sizeOfNonRowContentInPixels = 400;
  const availableHeight = window.innerHeight - sizeOfNonRowContentInPixels;
  const wholeRowsCount = Math.floor(availableHeight / 2 / rowSize);

  const finalHeight =
    // If we can fit every row in , just use that height
    wholeRowsCount > tx.length
      ? tx.length * rowSize
      : // Ohterwise show the number of rows that will fit, minus 1/2 a row
        // so that its clear you have to scroll
        wholeRowsCount * rowSize - rowSize / 2;

  return (
    <div
      ref={parentRef}
      style={{
        height: `${finalHeight}px`,
        minHeight: rowSize * 2.5,
        overflow: "auto",
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {virtualizer.getVirtualItems().map((virtualItem) => {
          const t = tx[virtualItem.index];
          const { errors, ignoreWarning, price } = t;
          const showMissingPriceWarning = getMissingPrice(
            errors,
            ignoreWarning,
            price,
          );
          const fieldIndex = fields.findIndex((field) => field.txId === t._id);

          return (
            <div
              key={virtualItem.key}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <EditActionTxAmount
                key={fields[fieldIndex].id}
                index={fieldIndex}
                showMissingPriceWarning={showMissingPriceWarning}
                name={name}
                shouldLinkValue={shouldLinkValue}
                modifyQuantity={modifyQuantity}
                focusField={focusField}
                side={side}
                timestamp={t.timestamp}
                blockchain={t.blockchain}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function RowSideAmounts(props: {
  row: ActionRow;
  tx: TransactionDetails[];
  side: Direction;
  shouldLinkValue: boolean;
  modifyQuantity: boolean;
  focusField?: QuickEditFocusField;
  editTrade?: boolean;
}) {
  const { side, tx } = props;
  return (
    <CommandPaletteBonesProviders
      variant="popover"
      popupId={`edit-${side}-${tx[0]?._id}`}
    >
      <RowSideAmountsInner {...props} />
    </CommandPaletteBonesProviders>
  );
}

function RowSideAmountsInner({
  row,
  tx,
  side,
  shouldLinkValue,
  modifyQuantity,
  focusField,
  editTrade,
}: {
  row: ActionRow;
  tx: TransactionDetails[];
  side: Direction;
  shouldLinkValue: boolean;
  modifyQuantity: boolean;
  focusField?: QuickEditFocusField;
  editTrade?: boolean;
}) {
  const name = nameMap[side];
  const { watch } = useFormContext<EditActionAmountsValues>();
  const { watch: watchTrade, setValue: setValueTrade } =
    useFormContext<EditActionDrawerTradeValues>();
  const [amount] = watch([name]);
  const [subComponentTrades] = watchTrade(["subComponentTrades"]);

  // Build ID:TX map.
  const txMap = new Map();
  tx.forEach((tx) => txMap.set(tx._id, tx));

  const totalValue = amount.reduce((acc, tx) => {
    if (!txMap.has(tx.txId)) return acc;
    return acc + Number(tx.value);
  }, 0);

  const { tokens } = useDesign();

  // ToDo: get the updated value currency from the form.
  const localCurrency = useLocalCurrency();
  const languagePreference = useLanguagePreference();
  const lang = useLang();

  const tradeType = subComponentTrades.find(
    (sct) => sct.txId === tx[0]._id,
  )?.trade;

  if (!tradeType) {
    throw new Error("trade type undefined");
  }

  const onChange = (updateTrade: Trade) => {
    setValueTrade(
      "subComponentTrades",
      subComponentTrades.map((trade) =>
        tx.some((t) => t._id === trade.txId)
          ? { ...trade, trade: updateTrade }
          : trade,
      ),
      {
        shouldValidate: false,
        shouldDirty: true,
      },
    );
  };

  const isMobile = useIsMobile();

  return (
    <Stack
      sx={{
        padding: "0.75rem",
        border: `1px solid ${tokens.border.neutral.medium}`,
        backgroundColor: tokens.elevation.medium,
        borderRadius: "0.25rem",
      }}
      gap="0.75rem"
      direction="column"
    >
      <Box mb="-0.75rem">
        {editTrade ? (
          <EditTxTradeButton
            row={row}
            selectedTrade={tradeType}
            onChange={onChange}
          />
        ) : (
          <TradeTypeTitle trade={tradeType} />
        )}
      </Box>
      {tx.length > 5 && !isMobile ? (
        <VirtualizedEditActionTxAmounts
          tx={tx}
          name={name}
          shouldLinkValue={shouldLinkValue}
          modifyQuantity={modifyQuantity}
          focusField={focusField}
          side={side}
        />
      ) : (
        <NonVirtualizedEditActionTxAmounts
          tx={tx}
          name={name}
          shouldLinkValue={shouldLinkValue}
          modifyQuantity={modifyQuantity}
          focusField={focusField}
          side={side}
        />
      )}
      {tx.length > 1 ? (
        <Typography variant="Metropolis/Body/Regular">
          {lang.editTx.totalValue({
            amount: displayFiatValue({
              value: totalValue,
              localCurrency,
              locale: languagePreference,
              fallbackMinFractionDigits: 2,
            }),
          })}
        </Typography>
      ) : null}
    </Stack>
  );
}
