export enum PlanCardVariant {
  Table = "table",
  Card = "card",
}
export enum UserFeature {
  TxLimit = "txLimit",
  Integrations = "integrations",
  PortfolioTracking = "portfolioTracking",
  Report = "report",
  OnChain = "onchain",
  SmartContract = "smartContract",
  LeastTaxFirstOut = "ltfo",
  AdvancedTaxReports = "advancedTaxReports",
  TaxLossHarvesting = "tlh",
  AuditReport = "auditReport",
  TradingStockReport = "tsr",
  Support = "support",
}
// experiment

export enum USUserExperimentFeature {
  Integrations = "integrations",
  TaxYearReports = "taxYearReports",
  TurboTaxIntegration = "turboTaxIntegration",
  Dashboard = "dashboard",
  AccountantInvite = "accountantInvite",
  DefiTaxAutomation = "defiTaxAutomation",
  TaxMinimization = "taxMinimization",
  AdvancedTaxReports = "advancedTaxReports",
  AuditLog = "auditLog",
  Support = "support",
}

export enum PaymentPopupMessage {
  PaymentSuccess = "PAYMENT_SUCCESS",
  PaymentCancelled = "PAYMENT_CANCELLED",
}
