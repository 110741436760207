import TuneIcon from "@mui/icons-material/Tune";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import styled from "styled-components/macro";

import { transactionAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { BasicPopover } from "~/components/transactions/filter-bar/BasicPopover";
import { useTransactionCheckbox } from "~/components/transactions/filter-bar/CheckboxContext";
import {
  CheckboxActionType,
  FilterActionType,
} from "~/components/transactions/filter-bar/enums";
import { useTransactionFilter } from "~/components/transactions/filter-bar/FilterContext";
import { FilterOtherToggle } from "~/components/transactions/filter-bar/FilterOtherToggle";
import { FilterCount } from "~/components/transactions/filter-bar/FilterQueryBuilder";
import { useDesign } from "~/hooks/useTheme";
import { FilterCountType } from "~/lib/enums";
import { getFilterCount } from "~/lib/filterOperatorConfig";
import {
  useCanAccessFeature,
  useIsLockPeriodEnabled,
  useIsShowingIgnoredTransactions,
} from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useGetFilterOptionsQuery, useSpamMutation } from "~/state/actions";
import { useGetAllTaxPeriodsQuery } from "~/state/period";
import { CommentsFilter, Features, FilterOperators } from "~/types/enums";
import { type FilterQuery } from "~/types/index";

export function FilterOther() {
  const lang = useLang();
  const isLoading = useGetFilterOptionsQuery().isInitialLoading;

  const {
    state: { filter, hideLocked },
  } = useTransactionFilter();

  const filterCount =
    (hideLocked ? 1 : 0) + getFilterCount(filter, FilterCountType.Other);

  return (
    <BasicPopover
      disabled={isLoading}
      buttonText={lang.txTable.filter.otherFilter}
      content={<FilterOtherPopover />}
      endIcon={
        filterCount && !isLoading ? (
          <FilterCount>{filterCount}</FilterCount>
        ) : (
          <TuneIcon />
        )
      }
      active={Boolean(filterCount)}
    />
  );
}

function FilterOtherPopover() {
  const lang = useLang();
  const { tokens } = useDesign();
  const isShowingIgnoredTransactions = useIsShowingIgnoredTransactions();
  const { state, dispatch } = useTransactionFilter();
  const { dispatch: checkboxDispatch } = useTransactionCheckbox();
  const query = state.filter ?? {
    type: FilterOperators.And,
    rules: [{ type: FilterOperators.Warning, value: [] }],
  };
  const lockedPeriods = useGetAllTaxPeriodsQuery();
  const isLockPeriodEnabled = useIsLockPeriodEnabled();
  const ruleEnabled = useCanAccessFeature(Features.Rules);
  const captureAnalytics = useCaptureAnalytics();
  const showSpamMutation = useSpamMutation();

  function setQuery(query: FilterQuery) {
    dispatch({
      type: FilterActionType.SetFilter,
      filter: query,
    });
    checkboxDispatch({ type: CheckboxActionType.ResetSelectedIds });
  }

  if (query.type !== FilterOperators.And) return null;

  const commentFilter = query.rules.find(
    (filter) => filter.type === FilterOperators.HasComments,
  );

  const reviewedFilter = query.rules.find(
    (filter) => filter.type === FilterOperators.Reviewed,
  );

  const suggestedFilter = query.rules.find(
    (filter) => filter.type === FilterOperators.Suggestion,
  );

  const ruleFilter = query.rules.find(
    (filter) => filter.type === FilterOperators.HasRule,
  );

  const setCommentFilter = () => {
    if (commentFilter) {
      setQuery({
        ...query,
        rules: [
          ...query.rules.filter(
            (filter) => filter.type !== FilterOperators.HasComments,
          ),
        ],
      });
    } else {
      setQuery({
        ...query,
        rules: [
          ...query.rules,
          {
            type: FilterOperators.HasComments,
            value: CommentsFilter.AllComments,
          },
        ],
      });
    }
  };

  const setReviewedFilter = (value: boolean) => {
    // Hide reviewed transactions
    if (value) {
      setQuery({
        ...query,
        rules: [
          ...query.rules,
          {
            type: FilterOperators.Reviewed,
            value: true,
          },
        ],
      });
    } else {
      setQuery({
        ...query,
        rules: [
          ...query.rules.filter(
            (filter) => filter.type !== FilterOperators.Reviewed,
          ),
        ],
      });
    }
  };

  const setSuggestedFilter = (value: boolean) => {
    if (value) {
      setQuery({
        ...query,
        rules: [
          ...query.rules,
          {
            type: FilterOperators.Suggestion,
            value: true,
          },
        ],
      });
    } else {
      setQuery({
        ...query,
        rules: [
          ...query.rules.filter(
            (filter) => filter.type !== FilterOperators.Suggestion,
          ),
        ],
      });
    }
  };

  const ledgerView = state.ledger;
  const ledgerAnalyticsKey = transactionAnalyticsKey("ledgerView");
  const setLedgerView = (value: boolean) => {
    captureAnalytics(ledgerAnalyticsKey("set"), { value });

    if (value) {
      dispatch({
        type: FilterActionType.SetLedger,
        ledger: true,
      });
    } else {
      dispatch({
        type: FilterActionType.SetLedger,
        ledger: false,
      });
    }
  };

  const lockPeriodFilter = state.hideLocked;
  const lockAnalyticsKey = transactionAnalyticsKey("hide-locked-transactions");
  const setLockPeriodFilter = (value: boolean) => {
    captureAnalytics(lockAnalyticsKey("set"), { value });

    dispatch({
      type: FilterActionType.SetHideLocked,
      hideLocked: value,
    });
  };

  const setRuleFilter = () => {
    if (ruleFilter) {
      setQuery({
        ...query,
        rules: [
          ...query.rules.filter(
            (filter) => filter.type !== FilterOperators.HasRule,
          ),
        ],
      });
    } else {
      setQuery({
        ...query,
        rules: [
          ...query.rules,
          {
            type: FilterOperators.HasRule,
            value: true,
          },
        ],
      });
    }
  };

  return (
    <Stack
      spacing="0.75rem"
      sx={{
        p: "0.75rem",
        background: tokens.background.neutral.lowest.default,
        borderRadius: "0.25rem",
      }}
    >
      <Box width="21.938rem">
        <StyledSubTitle>{lang.txTable.title}</StyledSubTitle>
        <FilterOtherToggle
          label={lang.txTable.filter.hideIgnored}
          checked={!isShowingIgnoredTransactions}
          disabled={showSpamMutation.isPending}
          onToggle={(checked) => {
            showSpamMutation.mutate({
              showSpamTransactions: !checked, // polarity of toggle in ui is opposite of the setting (hide in ui, show on user doc/filter)
            });
          }}
        />
        {isLockPeriodEnabled && lockedPeriods.data?.length ? (
          <Tooltip title={lang.txTable.filter.lockPeriodTooltip}>
            <span>
              <FilterOtherToggle
                label={lang.txTable.filter.hideLockedTransactions}
                checked={!!lockPeriodFilter}
                onToggle={(value) => {
                  setLockPeriodFilter(value);
                }}
              />
            </span>
          </Tooltip>
        ) : null}
        <FilterOtherToggle
          label={lang.txTable.filter.hideReviewed}
          checked={!!reviewedFilter}
          onToggle={(value) => {
            setReviewedFilter(value);
          }}
        />
        {ruleEnabled ? (
          <FilterOtherToggle
            label={lang.txTable.filter.hasRule}
            checked={!!ruleFilter}
            onToggle={setRuleFilter}
          />
        ) : null}
        {/* todo remove all references to smart suggestion star */}
        {/* <FilterOtherToggle
          label={lang.txTable.filter.hideNonSuggested}
          checked={!!suggestedFilter}
          onToggle={(value) => {
            setSuggestedFilter(value);
          }}
        /> */}
      </Box>
      <Box>
        <StyledSubTitle>{lang.txTable.filter.comment}</StyledSubTitle>
        <FilterOtherToggle
          label={lang.txTable.commentLabels.hasComments}
          checked={!!commentFilter}
          onToggle={setCommentFilter}
        />
      </Box>
      <Box>
        <StyledSubTitle>{lang.txTable.filter.table}</StyledSubTitle>
        <FilterOtherToggle
          label={lang.txTable.filter.operators.ledger}
          checked={!!ledgerView}
          onToggle={setLedgerView}
        />
      </Box>
    </Stack>
  );
}

const StyledSubTitle = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
`;
