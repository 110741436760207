import React, { useEffect } from "react";

import { ReportSettingsActionType } from "~/components/settings-modal/views/enums";
import { useTaxSettings } from "~/redux/report";
import { type TaxSettings } from "~/types/index";

type State = {
  taxSettings: TaxSettings | undefined;
};

type Action = {
  type: ReportSettingsActionType.SetTaxSettings;
  update: TaxSettings;
};

type Dispatch = (action: Action) => void;

const ReportSettingsContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function ReportSettingsReducer(state: State, action: Action): State {
  switch (action.type) {
    case ReportSettingsActionType.SetTaxSettings: {
      return {
        ...state,
        taxSettings: {
          ...state.taxSettings,
          ...action.update,
        } as TaxSettings,
      };
    }
  }
}

export function ReportSettingsProvider({
  initialState,
  children,
}: {
  initialState?: Partial<State>;
  children: React.ReactNode;
}) {
  const taxSettings = useTaxSettings();
  const [state, dispatch] = React.useReducer(ReportSettingsReducer, {
    taxSettings,
    ...initialState,
  });

  const value = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

  // I hate redux
  useEffect(() => {
    if (taxSettings) {
      dispatch({
        type: ReportSettingsActionType.SetTaxSettings,
        update: taxSettings,
      });
    }
  }, [taxSettings]);

  return (
    <ReportSettingsContext.Provider value={value}>
      {children}
    </ReportSettingsContext.Provider>
  );
}

export function useLocalReportSettings() {
  const context = React.useContext(ReportSettingsContext);
  if (context === undefined) {
    throw new Error(
      "useLocalReportSettings must be used within a ReportSettingsProvider",
    );
  }
  return context;
}
