import {
  type Blockchain,
  type EntityAddressQuery,
  EntitySource,
  Trade,
} from "@ctc/types";
import {
  type QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import isNil from "lodash/isNil";
import { useEffect } from "react";

import {
  formatDisplayAddress,
  getManualCSVExchangeId,
  getPossibleExchangeIds,
  MultiAddressWallets,
} from "~/components/transactions/helpers";
import { displayMessage } from "~/components/ui/Toaster";
import { useDebounce } from "~/hooks/useDebounce";
import { hashExchangeId } from "~/lib/hashExchangeId";
import { useLang } from "~/redux/lang";
import { HttpError } from "~/services/core";
import * as entitiesApi from "~/services/entities";
import { isBurnAddress } from "~/services/transactions";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import {
  DisplayMessage,
  EntityType,
  ImportType,
  IntegrationCategory,
} from "~/types/enums";
import {
  type Entity,
  isExchangeEntity,
  type NewEntity,
  nonEntityableAddresses,
  type SavedImportOptionByAccount,
} from "~/types/index";

export const entityKeys = {
  all: () => ["entities"] as const,

  lists: () => [...entityKeys.all(), "list"] as const,
  search: (search: string) => [...entityKeys.all(), { search }] as const,

  counts: (id: string) => [...entityKeys.all(), "counts", { id }] as const,
};

const optimisticUpdateEntity = async ({
  update,
  queryClient,
}: {
  update: (entity: Entity) => Entity;
  queryClient: QueryClient;
}) => {
  const previous = queryClient.getQueryData<entitiesApi.GetEntitiesResponse>(
    entityKeys.lists(),
  );
  await queryClient.cancelQueries({ queryKey: entityKeys.lists() });

  queryClient.setQueryData<entitiesApi.GetEntitiesResponse>(
    entityKeys.lists(),
    (old) => {
      if (!old) {
        return old;
      }
      return {
        ...old,
        entities: old.entities.map((entity) => update(entity)),
      };
    },
  );
  return { previous };
};

async function getEntities() {
  const res = await entitiesApi.getEntities();

  if (res.error) {
    throw new HttpError(res, ["getEntities"]);
  }

  if (res.data.entities) {
    // Sort by `manual` to use as a match preference when performing `find`.
    res.data.entities = [...res.data.entities].sort((entity) =>
      entity.type === EntityType.Manual ? -1 : 1,
    );
  }

  return res.data;
}

/**
 * Hook to get the list of entities
 * @returns
 */
export const useEntitiesQuery = () => {
  return useQuery({ queryKey: entityKeys.lists(), queryFn: getEntities });
};

/**
 * 99% of the time you want to use the useEntitiesQuery hook instead
 * But if you want to need to run it as an async method you can use this
 * @param queryClient Get from useQueryClient if possible
 * @returns
 */
export const fetchEntitiesQuery = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}) => {
  return queryClient.fetchQuery({
    queryKey: entityKeys.lists(),
    queryFn: getEntities,
  });
};

export const useFindEntityQuery = (filter: (entity: Entity) => boolean) => {
  const query = useEntitiesQuery();
  const entity = query.data?.entities?.find(filter);
  return {
    ...query,
    data: { entity },
  };
};

/**
 * Search for an entity (this is debounced for you)
 * @param search Search string
 * @returns query
 */
export const useEntitySearchQuery = (search: string | undefined) => {
  const debouncedSearchTerm = useDebounce<string | undefined>(search, 500);

  return useQuery({
    queryKey: entityKeys.search(debouncedSearchTerm ?? ""),
    queryFn: async () => {
      const res = await entitiesApi.searchEntities(debouncedSearchTerm ?? "");

      if (res.error) {
        throw new HttpError(res, ["entitySearch"]);
      }

      return {
        search,
        ...res.data,
      };
    },
    enabled: Boolean(debouncedSearchTerm?.length),
  });
};

export const useCreateEntityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ entity }: { entity: NewEntity }) => {
      const addresses = entity.addresses.map((address) => ({
        address: address.address,
        blockchain: address.blockchain,
        source: EntitySource.User,
      }));
      const res = await entitiesApi.createEntity({
        ...entity,
        addresses,
      });

      if (res.error) {
        throw new HttpError(res, ["createEntity"]);
      }

      return res.data;
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const useChangeEntityDisplayNameMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      entity,
      displayName,
    }: {
      entity: Entity;
      displayName: string;
    }) => {
      if (entity.displayName === displayName.trim()) {
        return;
      }

      // backend requires us to send all the data for the entity
      const res = await entitiesApi.updateEntity(entity._id, {
        ...entity,
        displayName,
      });

      if (res.error) {
        throw new HttpError(res, ["changeEntityDisplayName"]);
      }

      return res.data;
    },
    onMutate: async (variables) => {
      return optimisticUpdateEntity({
        queryClient,
        update: (entity) => {
          if (entity._id === variables.entity._id) {
            return {
              ...entity,
              displayName: variables.displayName,
            };
          }
          return entity;
        },
      });
    },
    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(entityKeys.lists(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};
export const useUpdateEntityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ entity }: { entity: Entity }) => {
      const res = await entitiesApi.updateEntity(entity._id, entity);
      if (res.error) {
        throw new HttpError(res, ["entity update"]);
      }
      return res.data;
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const useUploadEntityCsvFileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ file }: { file: File }) => {
      const res = await entitiesApi.uploadEntityCsvFile(file);
      if (res.error) {
        throw new HttpError(res, ["uploadEntityCsvFile"]);
      }
      return res.data;
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const useAddAddressToEntityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      entity,
      newAddress,
    }: {
      entity: Entity;
      newAddress: EntityAddressQuery;
    }) => {
      const res = await entitiesApi.updateEntity(entity._id, {
        _id: entity._id,
        addresses: [
          ...entity.addresses,
          { ...newAddress, source: EntitySource.User },
        ],
      });

      if (res.error) {
        throw new HttpError(res, ["addAddressToEntity"]);
      }

      return res.data;
    },
    onMutate: async (variables) => {
      const entityId = variables.entity._id;

      const key = entityKeys.lists();
      const previous =
        queryClient.getQueryData<entitiesApi.GetEntitiesResponse>(key);

      // Cancel outgoing refetch (so they don't overwrite optimistic update).
      await queryClient.cancelQueries({ queryKey: key });

      // Optimistically update to the new value
      queryClient.setQueryData<entitiesApi.GetEntitiesResponse>(key, (old) => {
        if (!old) {
          return old;
        }
        return {
          ...old,
          entities: old.entities.flatMap((entity) => {
            if (entity._id === entityId) {
              return {
                ...entity,
                addresses: [
                  ...entity.addresses,
                  {
                    ...variables.newAddress,
                    source: EntitySource.User,
                  },
                ],
              };
            }
            return entity;
          }),
        };
      });
      const result = { previous };
      return result;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context: any) => {
      const key = entityKeys.lists();
      if (context?.previous) {
        queryClient.setQueryData<entitiesApi.GetEntitiesResponse>(
          key,
          context.previous,
        );
      }
      queryErrorHandler(err);
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const useRemoveAddressFromEntityMutation = () => {
  const queryClient = useQueryClient();
  const lang = useLang();
  return useMutation({
    mutationFn: async ({
      entity,
      removedAddress,
    }: {
      entity: Entity;
      removedAddress: EntityAddressQuery;
    }) => {
      let res;
      if (
        entity.addresses.length === 1 &&
        !isExtensionHoldingEscrowState(entity)
      ) {
        // no more addresses will be left, and the extension is not holding the,
        // escrow state, so delete this entity
        res = await entitiesApi.deleteEntity(entity._id);
      } else {
        const hashRemove = hashExchangeId({
          exchange: removedAddress.address,
          blockchain: removedAddress.blockchain,
        });

        // remove that address and put/patch
        res = await entitiesApi.updateEntity(entity._id, {
          _id: entity._id,
          addresses: entity.addresses.filter(
            (address) =>
              hashExchangeId({
                exchange: address.address,
                blockchain: address.blockchain,
              }) !== hashRemove,
          ),
        });
      }

      if (res.error) {
        throw new HttpError(res, ["removeAddressFromEntity"]);
      }

      return res.data;
    },

    onMutate: async (variables: {
      entity: Entity;
      removedAddress: EntityAddressQuery;
    }) => {
      return optimisticUpdateEntity({
        queryClient,
        update: (entity) => {
          const hashRemove = hashExchangeId({
            exchange: variables.removedAddress.address,
            blockchain: variables.removedAddress.blockchain,
          });

          if (entity._id === variables.entity._id) {
            return {
              ...entity,
              addresses: entity.addresses.filter(
                (address) =>
                  hashExchangeId({
                    exchange: address.address,
                    blockchain: address.blockchain,
                  }) !== hashRemove,
              ),
            };
          }
          return entity;
        },
      });
    },
    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(entityKeys.lists(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess(data, variables) {
      displayMessage({
        message: lang.tag.deleteMessage({
          address: formatDisplayAddress(
            variables.removedAddress.address,
            false,
            true,
          ),
          entityName: variables.entity.displayName,
        }),
        type: DisplayMessage.Success,
      });
      return queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
};

export const matchAddressWithEntityAddress = ({
  address,
  blockchain,
  entityAddress,
  entityBlockchain,
}: {
  address: string;
  blockchain?: Blockchain;
  entityAddress: string;
  entityBlockchain?: Blockchain;
}) => {
  const hasAddressMatch = entityAddress.toLowerCase() === address.toLowerCase();
  // if the entity has no blockchain that means it is a catchall and should match with any chain
  // assuming addresses match
  const hasNoBlockchain = !entityBlockchain;
  const hasBlockchainsThatMatch =
    entityBlockchain &&
    blockchain &&
    entityBlockchain.toLowerCase() === blockchain.toLowerCase();

  const hasBlockchainMatch = hasNoBlockchain || hasBlockchainsThatMatch;

  return hasAddressMatch && hasBlockchainMatch;
};

export function getEntityByAddress(
  address: string,
  blockchain?: Blockchain,
  entities?: Entity[],
) {
  if (!address || !entities) {
    return undefined;
  }

  return entities.find((entity) => {
    if ("ref" in entity && entity.ref.toLowerCase() === address.toLowerCase()) {
      return true;
    }

    const matchingEntity = entity.addresses.find((a) => {
      return matchAddressWithEntityAddress({
        address,
        blockchain,
        entityAddress: a.address,
        entityBlockchain: a.blockchain as Blockchain,
      });
    });

    if (matchingEntity) {
      return matchingEntity;
    }

    if (
      entity.type === EntityType.Exchange ||
      entity.type === EntityType.Extension
    ) {
      // its addresses didnt match, so for exchanges, lets check the global
      return entity.globalAddresses.find(
        (a) => a.address.toLowerCase() === address.toLowerCase(),
      );
    }

    return false;
  });
}

// find an entity based on the address
export const useEntityLookupAsync = () => {
  const query = useEntitiesQuery();

  return {
    getEntityForAddress: (address: string, blockchain?: Blockchain) =>
      getEntityByAddress(address, blockchain, query.data?.entities),

    getEntityById: (id: string) => {
      return query.data?.entities.find((entity) => entity._id === id);
    },
    getEntityByRef: (ref: string) => {
      return query.data?.entities.find(
        (entity) => entity.type !== EntityType.Manual && entity.ref === ref,
      );
    },
  };
};

// find an entity based on the address
export const useEntityLookup = (
  address: string | undefined | null,
  blockchain?: Blockchain,
) => {
  const { getEntityForAddress } = useEntityLookupAsync();
  if (!address) {
    return undefined;
  }
  return getEntityForAddress(address, blockchain);
};

export function getEntityExchangeLogoName<T extends Entity | null | undefined>(
  entity: T,
): string | (undefined extends T ? undefined : never) {
  if (!entity) {
    return undefined as undefined extends T ? undefined : never; // asserted
  }
  if (
    entity.type === EntityType.Exchange ||
    entity.type === EntityType.Extension
  ) {
    return entity.ref;
  }
  return entity.addresses[0]?.address ?? entity._id;
}

export function isKnownSource(
  exchange: string,
  importType: ImportType,
  savedImportOptions: SavedImportOptionByAccount[],
  blockchain?: Blockchain,
  trade?: Trade,
): boolean {
  if (isBurnAddress(exchange)) return true;

  // Hack for multi-address wallets
  if (
    blockchain &&
    trade &&
    MultiAddressWallets.includes(blockchain as Blockchain) &&
    importType === ImportType.Wallet
  ) {
    const validTrades = [Trade.Withdrawal, Trade.Deposit];
    return validTrades.includes(trade);
  }
  // Attempt to find a blockchain agnostic import.
  const savedImport = savedImportOptions.find(
    ({ id: exchangeIdOrAddress, category, wallets }) => {
      const possibleAddressBlockchainIdsForImport = wallets.map((wallet) =>
        hashExchangeId({
          exchange: wallet.address,
          blockchain: wallet.blockchain,
        }),
      );
      const lowercaseExchange = exchange.toLowerCase();
      const exchangeId =
        category === IntegrationCategory.Manual
          ? getManualCSVExchangeId(exchangeIdOrAddress)
          : exchangeIdOrAddress?.toLowerCase();
      return (
        exchangeId === lowercaseExchange ||
        possibleAddressBlockchainIdsForImport.includes(lowercaseExchange)
      );
    },
  );

  // Check source exists in any blockchain agnostic imports to prevent
  // further import checking.
  const isBlockchainAgnostic =
    savedImport && savedImport.category === IntegrationCategory.Blockchain;
  if (isBlockchainAgnostic) return true;

  // Get blockchain specific import keys.
  const sourceExchangeIds = getPossibleExchangeIds(
    exchange,
    importType,
    blockchain,
  ).map((id) => id.toLowerCase());

  // Check for blockchain specific imports.
  return savedImportOptions.some(({ id, wallets }) => {
    const possibleExchangeIdsForImport = wallets.map((chain) =>
      hashExchangeId({
        exchange: chain.address,
        blockchain: chain.blockchain,
      }),
    );

    return (
      possibleExchangeIdsForImport.length &&
      (possibleExchangeIdsForImport.some((exchangeId) =>
        sourceExchangeIds.includes(exchangeId.toLowerCase()),
      ) ||
        sourceExchangeIds.includes(id.toLowerCase()))
    );
  });
}
/**
 * Can this address be converted to an entity
 * @param address
 * @param importType
 * @param existingImportOptions
 * @param isAnAddress
 * @param blockchain
 * @param trade
 * @returns
 */
export function isEntityable(
  entity: Entity | undefined,
  address: string,
  importType: ImportType,
  savedAccounts: SavedImportOptionByAccount[],
  isAnAddress: boolean,
  blockchain?: Blockchain,
  trade?: Trade,
): boolean {
  if (
    nonEntityableAddresses.some(
      (nonEntityableAddress) => address.toLowerCase() === nonEntityableAddress,
    )
  ) {
    // you can't entity 'bank' or 'unknown'
    return false;
  }

  if (entity) {
    // if we have already found its entity, then the answer is yes
    return true;
  }

  const isImportedWallet = isKnownSource(
    address,
    importType,
    savedAccounts,
    blockchain,
    trade,
  );
  if (isImportedWallet) {
    // this is a wallet we have imported, so it cant be an entity
    return false;
  }

  if (!isAnAddress) {
    // this is something that isnt a valid address, but lets let you entity it
    // makes it easier if you are testing with a short address like 0x123
    // which fails in isAnAddress
    return true;
  }

  return true;
}

// Custom hook to batch-fetch entity tx counts. Use useEntityTxCountQuery to get
// the results from this hook
export const useBatchEntitiesTxCountsQuery = (ids: string[]) => {
  const queryClient = useQueryClient();

  // Needs to be different to a page of 1 id so add trailing comma so they never collide
  const search = `${ids.join(",")},`;
  const debouncedSearchTerm = useDebounce<string | undefined>(search, 500);

  // For each id in ids, check if there is a query key that doesnt have data
  // for it
  const allIdsAlreadyCounted = ids.every(
    (id) => !isNil(queryClient.getQueryData(entityKeys.counts(id))),
  );

  const hasPreexistingRes = !isNil(
    queryClient.getQueryData(entityKeys.counts(search ?? "")),
  );

  const query = useQuery(
    // Check if we're already listening to this search, else used the debounced
    // term, else use empty while waiting for the debounced term
    {
      queryKey: entityKeys.counts(
        (hasPreexistingRes ? search : debouncedSearchTerm) ?? "",
      ),
      queryFn: async () => {
        const res = await entitiesApi.getEntitiesTxCounts(ids);

        if (res.error) {
          throw new HttpError(res, ["entitiesTxCounts"]);
        }

        return res.data;
      },
      // Only send the query if there are ids in the list, we havent already
      // got values for all the ids in the list, and we're not waiting for
      // the debounce
      enabled:
        ids.length > 0 &&
        Boolean(debouncedSearchTerm?.length) &&
        !allIdsAlreadyCounted,
    },
  );

  useEffect(() => {
    if (query.data) {
      // Update cache for each ID
      ids.forEach((id) => {
        const key = entityKeys.counts(id);
        // Invalidate the existing cache for the key
        queryClient.invalidateQueries({ queryKey: key });
        // Update the cache with the new data
        queryClient.setQueryData(key, query.data[id]);
      });
    }
  }, [query.data]);

  return query;
};

// Custom hook to get the individual entities tx count
export function useEntityTxCountQuery(id: string) {
  return useQuery<number>({
    queryKey: entityKeys.counts(id),
    enabled: false,
  });
}

export function useMarkEntityEscrowMutation() {
  const lang = useLang();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      entity,
      escrow,
    }: {
      entity: Entity;
      escrow: boolean;
    }) => {
      const res = await entitiesApi.updateEntity(entity._id, {
        isEscrow: escrow,
      });

      if (res.error) {
        throw new HttpError(res, ["markEntityEscrow"]);
      }

      // If the extension entity is only existing to hold escrow state and we
      // mark it as not escrow, then delete the entity
      if (
        entity.addresses.length === 0 &&
        isExtensionHoldingEscrowState(entity) &&
        !escrow
      ) {
        // no more addresses will be left, and the extension is not holding the,
        // escrow state, so delete this entity
        const deleteRes = await entitiesApi.deleteEntity(entity._id);
        if (deleteRes.error) {
          throw new HttpError(deleteRes, ["deleteEntity"]);
        }
      }

      return res.data;
    },
    onMutate: async (variables: { entity: Entity; escrow: boolean }) => {
      return optimisticUpdateEntity({
        queryClient,
        update: (entity) => {
          if (entity._id === variables.entity._id) {
            return {
              ...entity,
              isEscrow: !variables.escrow,
            };
          }
          return entity;
        },
      });
    },
    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(entityKeys.lists(), context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess(data, { entity, escrow }) {
      displayMessage({
        message: escrow
          ? lang.txTable.entities.truePositive
          : lang.txTable.entities.falsePositive,
        type: DisplayMessage.Success,
      });
      return queryClient.invalidateQueries({ queryKey: entityKeys.all() });
    },
  });
}

export function isExtensionHoldingEscrowState(entity: Entity) {
  return isExchangeEntity(entity) && entity.isEscrow && !entity.globalIsEscrow;
}
