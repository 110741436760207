import { Box, Divider, Typography } from "@mui/material";

import { useDesign } from "~/hooks/useTheme";

export function SettingsBox({
  title,
  children,
}: {
  title?: React.ReactNode | string;
  children: React.ReactNode;
}) {
  const { tokens } = useDesign();

  return (
    <Box>
      {title && typeof title === "string" ? (
        <Typography variant="Metropolis/Header/H4">{title}</Typography>
      ) : (
        title
      )}
      <Divider
        sx={{
          mt: "1rem",
          mb: "1.5rem",
          borderColor: tokens.border.neutral.default,
        }}
      />
      <Box display="flex" gap="1rem" flexDirection="column" overflow="hidden">
        {children}
      </Box>
    </Box>
  );
}
