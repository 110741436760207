import { Country, Env } from "@ctc/types";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useEffect } from "react";

import { CookieKey, LocalStorageKey } from "~/constants/enums";

/**
 * Map of language codes to our Country enum for cookie-based detection
 */
const LANGUAGE_TO_COUNTRY_MAP: Record<string, Country | undefined> = {
  ...Object.values(Country).reduce(
    (acc, country) => {
      acc[country.toLowerCase()] = country;
      return acc;
    },
    {} as Record<string, Country>,
  ),
  en: undefined, // If just english no auto detection
  xx: undefined, // If debug language no auto detection
};

/**
 * Cloudflare special country codes that need handling
 */
const CLOUDFLARE_SPECIAL_CODES: Record<string, Country | null> = {
  GB: Country.UK, // Cloudflare returns GB for United Kingdom
  XX: null, // Unknown location
  T1: null, // Tor exit node
};

/**
 * Query key for country detection
 */
const COUNTRY_DETECTION_KEY = ["autoDetectedCountry"];

/**
 * Hook to detect user's country in this priority order:
 * 1. Previously stored country in localStorage
 * 2. Language cookies set by the homepage
 * 3. Cloudflare geolocation using /cdn-cgi/trace endpoint
 *
 * Stores the country in localStorage if detected and no localStorage country exists
 * @returns The Country enum value if detected, undefined otherwise
 */
export function useAutoDetectedCountry(): {
  country: Country | undefined;
  isLoading: boolean;
} {
  // Check localStorage first for a previously stored country
  const storedCountry = getPreviouslyStoredCountry();

  // Then check cookies for country hint from the marketing site
  const cookieCountry = getCountryFromLanguageCookies();

  // Only fetch from Cloudflare if we don't have a country yet
  const shouldFetchFromCloudflare = !storedCountry && !cookieCountry;

  const { data: detectedCountry, isLoading } = useQuery<Country | null, Error>({
    queryKey: COUNTRY_DETECTION_KEY,
    queryFn: detectCountryFromCloudflare,
    staleTime: Infinity,
    enabled: shouldFetchFromCloudflare,
  });

  // Store detected country if found and no localStorage country exists
  useEffect(() => {
    if (detectedCountry && !storedCountry) {
      storeCountry(detectedCountry);
    }
  }, [detectedCountry, storedCountry]);

  // Store cookie country if found and no localStorage country exists
  useEffect(() => {
    if (cookieCountry && !storedCountry) {
      storeCountry(cookieCountry);
    }
  }, [cookieCountry, storedCountry]);

  // Return the country and loading state
  return {
    country: storedCountry ?? cookieCountry ?? detectedCountry ?? undefined,
    isLoading: shouldFetchFromCloudflare && isLoading,
  };
}

/**
 * Detects country using Cloudflare's trace endpoint
 *
 * returns null as query data can't be undefined
 */
async function detectCountryFromCloudflare(): Promise<Country | null> {
  const isLocalDevelopment = import.meta.env.DEV;

  // Use Cloudflare's built-in trace endpoint which includes country code
  const traceUrl = isLocalDevelopment
    ? "https://dev.app.cryptotaxcalculator.io/cdn-cgi/trace"
    : "/cdn-cgi/trace";

  const response = await fetch(traceUrl);
  const text = await response.text();

  // Parse the trace data
  const data = parseCloudflareTrace(text);

  // Return the mapped country if location is found
  if (data.loc) {
    return mapIsoCodeToCountryEnum(data.loc);
  }

  return null;
}

/**
 * Parse Cloudflare trace response into key-value pairs
 */
function parseCloudflareTrace(trace: string): Record<string, string> {
  return trace.split("\n").reduce(
    (result, line) => {
      const parts = line.split("=");
      if (parts.length === 2) {
        result[parts[0]] = parts[1];
      }
      return result;
    },
    {} as Record<string, string>,
  );
}

/**
 * Save country to localStorage for future use
 */
function storeCountry(country: Country): void {
  localStorage.setItem(LocalStorageKey.Country, country);
}

/**
 * Type guard to check if a string is a valid Country enum value
 * @param value - String to check
 * @returns True if the value is a valid Country enum value
 */
function isCountry(value: string | null): value is Country {
  return !!value && Object.values(Country).includes(value as Country);
}

/**
 * Maps an ISO country code to our Country enum
 */
function mapIsoCodeToCountryEnum(isoCode: string): Country | null {
  // Handle special cases (GB→UK, XX, T1)
  if (isoCode in CLOUDFLARE_SPECIAL_CODES) {
    return CLOUDFLARE_SPECIAL_CODES[isoCode];
  }

  if (isCountry(isoCode)) {
    return isoCode;
  }

  return null;
}

/**
 * Check for previously stored country in localStorage
 */
function getPreviouslyStoredCountry(): Country | undefined {
  const storedCountry = localStorage.getItem(LocalStorageKey.Country);

  if (isCountry(storedCountry)) {
    return storedCountry;
  }
  return undefined;
}

/**
 * Extract country from language cookies set by the homepage
 */
function getCountryFromLanguageCookies(): Country | undefined {
  const languageCookie =
    Cookies.get(CookieKey.HpSelectedCountry) ??
    Cookies.get(CookieKey.UnconfirmedCountry);

  if (!languageCookie) return undefined;
  return LANGUAGE_TO_COUNTRY_MAP[languageCookie.toLowerCase()];
}
