import { ErrorOutline } from "@mui/icons-material";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import {
  DialogFooter,
  DialogHeader,
  PasswordAuthCodeFormContents,
} from "~/components/settings-modal/views/profile/security/common";
import { useDesign } from "~/hooks/useTheme";
import { useLang } from "~/redux/lang";
import { useDisableTwoFactorMutation } from "~/state/security";

export function Disable2faModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { tokens } = useDesign();
  const lang = useLang();
  const disable2faMutation = useDisableTwoFactorMutation();

  const form = useForm<{
    password: string;
    code: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      password: "",
      code: "",
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
    getValues,
  } = form;

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSubmit = async () => {
    await disable2faMutation.mutateAsync(getValues());
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogHeader onClose={onClose}>
        <ErrorOutline
          sx={{
            fontSize: "1.8rem",
            color: tokens.icon.danger,
            margin: 0,
            padding: 0,
          }}
        />
        {lang.twoFactorAuth.disableTwoFactorAuth}
      </DialogHeader>
      <DialogContent>
        <Typography variant="Metropolis/Body/Regular">
          {lang.twoFactorAuth.enterCodeToDisable}
        </Typography>
        <FormProvider {...form}>
          <PasswordAuthCodeFormContents />
        </FormProvider>
      </DialogContent>
      <DialogFooter
        dataCtc="2fa-disable"
        onCancel={onCancel}
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isSubmitting}
      />
    </Dialog>
  );
}
