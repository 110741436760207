import { type Blockchain } from "@ctc/types";
import {
  type InfiniteData,
  type QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useErrorHandler } from "react-error-boundary";

import { reconciliationAnalyticsKey } from "~/analytics/analyticsKeys";
import { useCaptureAnalytics } from "~/analytics/posthog";
import { useIgnoreSuspectedMissingImportOnSuccess } from "~/components/imports-v2/SuspectedMissingImportOnSuccess";
import { displayMessage } from "~/components/ui/Toaster";
import { useUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useSelector } from "~/redux/useSelector";
import { bulkOp, type BulkOperation } from "~/services/actions";
import { HttpError } from "~/services/core";
import * as reconciliationAPI from "~/services/reconciliation";
import { useReconciliationItems } from "~/services/reconciliation";
import { actionKeys } from "~/state/actions";
import { refreshLedgerDataPayload } from "~/state/ledger";
import { queryErrorHandler } from "~/state/queryErrorHandler";
import {
  BulkOperations,
  DisplayMessage,
  FilterOperators,
  Links,
  ReconciliationIssues,
  Warning,
} from "~/types/enums";
import {
  type FilterQuery,
  type IssueTypeToIssuePayload,
  type MissingBlockchainIssue,
  type MissingPriceIssue,
  type ReconciliationIssuesPayload,
  type SuspectedMissingImport,
} from "~/types/index";

export const reconciliationIssueQueryKeys = {
  all: () => ["reconciliation"] as const,
  metadata: () => ["reconciliation", "metadata"] as const,
  lists: (type: ReconciliationIssues) =>
    ["reconciliation", "list", { type }] as const,
};

/**
 * @returns All the issue types on the rec page that arent hidden
 */
function useReconciliationItemsToLoad({
  getHiddenIssues = false,
}: {
  getHiddenIssues?: boolean;
} = {}) {
  const reconciliationItems = useReconciliationItems();
  const allIssueTypesToLoad = Object.values(ReconciliationIssues).filter(
    (issueType) =>
      reconciliationItems[issueType].isOnReconciliationPage &&
      (getHiddenIssues || !reconciliationItems[issueType].hidden),
  );
  return allIssueTypesToLoad;
}

export const useIsReconciliationLoading = () => {
  const queryClient = useQueryClient();
  // if any of the pages are loading
  return Object.values(ReconciliationIssues).some(
    (issueType) =>
      queryClient.getQueryState(reconciliationIssueQueryKeys.lists(issueType))
        ?.status === "pending",
  );
};

export function useResetReconciliationPayload() {
  const queryClient = useQueryClient();
  return () => {
    return queryClient.resetQueries({
      queryKey: reconciliationIssueQueryKeys.all(),
    });
  };
}

export const refreshReconciliationPayload = async (
  queryClient: QueryClient,
) => {
  queryClient.invalidateQueries({
    queryKey: reconciliationIssueQueryKeys.all(),
  });
  refreshLedgerDataPayload(queryClient);
};

export const useReconciliationMetadataQuery = () => {
  return useQuery({
    queryKey: reconciliationIssueQueryKeys.metadata(),
    queryFn: reconciliationAPI.getMetadata,
  });
};

export const useIgnoreMissingPrice = () => {
  const queryClient = useQueryClient();
  const errorMessage = useSelector(
    (state) => state.lang.map.reconciliation.api.ignoreError,
  );
  const mutation = useMutation({
    mutationFn: async (issue: MissingPriceIssue) => {
      const res = await reconciliationAPI.ignoreMissingPrice(issue.currencyId);

      if (res.error) {
        throw new HttpError({ ...res, msg: res.msg || errorMessage }, [
          "ignoreMissingPrice",
        ]);
      }

      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reconciliationIssueQueryKeys.lists(
          ReconciliationIssues.MissingPrices,
        ),
      });
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
  return mutation;
};

export const useIgnoreAllMissingPrice = () => {
  const queryClient = useQueryClient();
  const errorMessage = useSelector(
    (state) => state.lang.map.reconciliation.api.ignoreAllMissingPriceError,
  );
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await reconciliationAPI.ignoreAllMissingPrice();

      if (res.error) {
        throw new HttpError({ ...res, msg: res.msg || errorMessage }, [
          "ignoreAllMissingPrice",
        ]);
      }

      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reconciliationIssueQueryKeys.lists(
          ReconciliationIssues.MissingPrices,
        ),
      });
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
  return mutation;
};

export const useAssignBlockchainSource = () => {
  const queryClient = useQueryClient();
  const errorMessage = useSelector(
    (state) => state.lang.map.reconciliation.api.assignBlockchainSource,
  );
  const mutation = useMutation({
    mutationFn: async ({
      issue,
      blockchain,
    }: {
      issue: MissingBlockchainIssue;
      blockchain: Blockchain;
    }) => {
      const res = await reconciliationAPI.assignBlockchainSource(
        issue.transactionSource,
        blockchain,
      );

      if (res.error) {
        throw new HttpError({ ...res, msg: res.msg || errorMessage }, [
          "assignBlockchainSource",
        ]);
      }

      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reconciliationIssueQueryKeys.lists(
          ReconciliationIssues.MissingBlockchain,
        ),
      });
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
    },
  });
  return mutation;
};

export const useBulkUpdateMarketPrice = () => {
  const queryClient = useQueryClient();
  const lang = useLang().reconciliation;
  const reconciliationActionKey = reconciliationAnalyticsKey(
    ReconciliationIssues.MissingPrices,
  );
  const captureAnalytics = useCaptureAnalytics();
  const errorMessage = useSelector(
    (state) =>
      state.lang.map.reconciliation.issues[ReconciliationIssues.MissingPrices]
        .bulkLookupButton.failUpdated,
  );
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await reconciliationAPI.bulkLookupMarketPrice();

      if (res.error) {
        throw new HttpError({ ...res, msg: res.msg || errorMessage }, [
          "bulkUpdateMarketPrice",
        ]);
      }

      if (!res.data.updatedCount) {
        captureAnalytics(
          reconciliationActionKey("look_up_market_price_no_result"),
        );
        throw new Error(
          lang.issues[
            ReconciliationIssues.MissingPrices
          ].bulkLookupButton.failUpdated,
        );
      }
      return res.data;
    },
    onSuccess: async (data) => {
      // Prices were updated
      displayMessage({
        message: lang.issues[
          ReconciliationIssues.MissingPrices
        ].bulkLookupButton.successUpdated({
          count: data.updatedCount,
        }),
        type: DisplayMessage.Success,
      });

      captureAnalytics(reconciliationActionKey("look_up_market_price_success"));
      queryClient.invalidateQueries({ queryKey: actionKeys.all() });
      // Reload the reconcilitation data we need
      return queryClient.invalidateQueries({
        queryKey: reconciliationIssueQueryKeys.lists(
          ReconciliationIssues.MissingPrices,
        ),
      });
    },
    onSettled: () => {
      // reset the mutation so the button goes back to a clickable state
      setTimeout(() => {
        mutation.reset();
      }, 2000);
    },
  });
  return mutation;
};

export function useReconciliationDataForType<T extends ReconciliationIssues>(
  issueType: T,
  noPagination = false,
) {
  // const queryClient = useQueryClient();
  // The UI shows a load more, so using an infinite query
  const result = useInfiniteQuery({
    queryKey: reconciliationIssueQueryKeys.lists(issueType),
    queryFn: async (params) => {
      const res = await reconciliationAPI.getReconciliationIssuesForType(
        issueType,
        noPagination
          ? { noPagination }
          : {
              cursor: params.pageParam,
            },
      );

      if (res.error) {
        throw new HttpError(res, ["reconciliationDataForType"]);
      }

      return res.data;
    },
    initialPageParam: "",
    getNextPageParam: (lastPage: ReconciliationIssuesPayload<T>) => {
      return lastPage?.cursor;
    },
  });

  useErrorHandler(result.error);

  const issues = result.data?.pages.reduce<IssueTypeToIssuePayload<T>[]>(
    (acc, page) => {
      if (!page) {
        return acc;
      }
      const pageIssues = page?.issues;
      return [...acc, ...pageIssues];
    },
    [],
  );
  const issueCount = result.data?.pages?.[0]?.issueCount ?? 0;
  return {
    ...result,
    issues,
    issueCount,
    getIssueGroupProps: () => {
      return {
        issueCount,
        ...result,
      };
    },
  };
}

export const useAllEvenHiddenReconciliationQuery = () => {
  const lang = useLang();
  const user = useUser();
  const allIssueTypesToLoad = useReconciliationItemsToLoad({
    getHiddenIssues: true,
  });
  const queries = useQueries({
    queries: allIssueTypesToLoad.map((issueType) => {
      return {
        onError: (error: unknown) => {
          displayMessage({
            message:
              error instanceof Error
                ? error.message
                : lang.reconciliation.api.refreshError,
            type: DisplayMessage.Error,
          });

          const fakeData: ReconciliationIssuesPayload<ReconciliationIssues> = {
            issueCount: 0,
            issues: [],
            cursor: undefined,
          };

          return { pageParams: [undefined], pages: [fakeData] };
        },
        queryKey: reconciliationIssueQueryKeys.lists(issueType),
        queryFn: async () => {
          const res =
            await reconciliationAPI.getReconciliationIssuesForType(issueType);

          if (res.error) {
            throw new HttpError(res, ["reconciliationQuery"]);
          }

          return { pageParams: [undefined], pages: [res.data] };
        },
        // dont refetch within 5 minutes
        // this will be overwritten by invalidation and also any
        // of the reconciliation individual pages
        staleTime: 60000 * 5,
        enabled: !!user,
      };
    }),
  });

  // simulate the old response
  const data = allIssueTypesToLoad.reduce(
    (prev, issueType, index) => {
      const payload = queries[index]?.data?.pages?.[0];
      return {
        ...prev,
        [issueType]: payload,
      };
    },
    {} as {
      [key in ReconciliationIssues]:
        | ReconciliationIssuesPayload<key>
        | undefined;
    },
  );

  const isLoading = queries.some(
    (result) => !result?.data && result?.status === "pending",
  );
  const isFetching = queries.some((result) => result?.status === "pending");
  return {
    data,
    isLoading,
    isFetching,
  };
};

export const useReconciliationQuery = () => {
  const { data, isLoading, isFetching } = useAllEvenHiddenReconciliationQuery();
  const nonHiddenItems = useReconciliationItemsToLoad();
  const dataForNonHiddenItems = nonHiddenItems.reduce(
    (acc, issueType) => {
      return {
        ...acc,
        [issueType]: data[issueType],
      };
    },
    {} as { [key in ReconciliationIssues]: ReconciliationIssuesPayload<key> },
  );

  return {
    data: dataForNonHiddenItems,
    isLoading,
    isFetching,
  };
};

export const useReconciliationSteps = ({
  includeCurrentlyHiddenSteps,
}: {
  includeCurrentlyHiddenSteps: boolean;
}) => {
  // Always call both hooks
  const allData = useAllEvenHiddenReconciliationQuery().data;
  const visibleData = useReconciliationQuery().data;

  // Select which data to use after the hooks are called
  const reconciliationData = includeCurrentlyHiddenSteps
    ? allData
    : visibleData;

  if (!reconciliationData) {
    return 0;
  }
  const numberOfSteps = Object.values(ReconciliationIssues).reduce(
    (count, issueType) =>
      reconciliationData?.[issueType]?.issueCount ? count + 1 : count,
    0,
  );
  return numberOfSteps;
};

export const useReconciliationIntercomData = () => {
  const reconciliationData = useReconciliationQuery().data;
  const toSnakeCase = (w: string) =>
    w.replaceAll(/([A-Z])/g, "_$1").toLowerCase();
  return Object.entries(reconciliationData).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [`num_${toSnakeCase(key)}`]:
        // for some reason this comes from the transaction count for UncategorisedTransactions
        key != ReconciliationIssues.UncategorisedTransactions
          ? value?.issueCount || 0
          : value?.issues[0]?.transactionCount || 0,
    };
  }, {});
};

export const useReconciliationTotalSteps = () => {
  const reconciliationItems = useReconciliationItems();
  // both by wallet and by currency are under the 1 tab
  // so we need to filter out by hidden flag
  const validIssue = Object.values(reconciliationItems).filter(
    (issue) => issue.hidden !== true,
  );

  return validIssue.length;
};

export const useReconciliationLink = (): Links => {
  const data = useReconciliationQuery().data;
  const reconciliationItems = useReconciliationItems();

  for (const item of Object.entries(reconciliationItems)) {
    const [type, config] = item;
    if (config.hidden) continue;
    // Get the first bucket with issues, then stop.
    if ((data[type as ReconciliationIssues]?.issueCount ?? 0) > 0) {
      return config.path;
    }
  }
  return Links.Reconciliation;
};

export function useIgnoreSuspectedMissingImportMutation() {
  const queryClient = useQueryClient();
  const queryKey = reconciliationIssueQueryKeys.lists(
    ReconciliationIssues.SuspectedMissingImport,
  );

  const onSuccess = useIgnoreSuspectedMissingImportOnSuccess();

  return useMutation({
    mutationFn: async ({
      suspectedMissingImport,
    }: {
      suspectedMissingImport: SuspectedMissingImport;
    }) => {
      const { issue } = suspectedMissingImport;
      const filter: FilterQuery = {
        type: FilterOperators.And,
        rules: [
          {
            type: FilterOperators.Source,
            value: issue.accounts,
          },
        ],
      };

      const operation: BulkOperation = {
        type: BulkOperations.IgnoreWarnings,
        warnings: [Warning.SuspectedMissingImport],
      };

      const res = await bulkOp({ filter, operation });

      if (res.error) {
        throw new HttpError(res, ["bulkOp"]);
      }

      return res.data;
    },
    onMutate: async (variables: {
      suspectedMissingImport: SuspectedMissingImport;
    }) => {
      const previous =
        queryClient.getQueryData<
          InfiniteData<
            ReconciliationIssuesPayload<ReconciliationIssues.SuspectedMissingImport>
          >
        >(queryKey);

      // Cancel outgoing refetch (so they don't overwrite optimistic update).
      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<
        InfiniteData<
          ReconciliationIssuesPayload<ReconciliationIssues.SuspectedMissingImport>
        >
      >(queryKey, (old) => {
        if (!old) return old;

        const { pages, ...rest } = old;
        const updatedPages = pages.map((page) => {
          const { issues, ...rest } = page;
          const filteredIssues = issues?.filter(
            (issue) =>
              issue.accounts !==
              variables.suspectedMissingImport.issue.accounts,
          );
          return { issues: filteredIssues, ...rest };
        });

        return { pages: updatedPages, ...rest };
      });

      return { previous };
    },
    onError: (err, _action, context) => {
      if (context?.previous) {
        queryClient.setQueryData(queryKey, context.previous);
      }
      queryErrorHandler(err);
    },
    onSuccess,
  });
}
