import {
  CheckCircleOutlineOutlined,
  ErrorOutline,
  SwapHoriz,
  Sync,
  SyncDisabled,
  WarningAmber,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { type ReactNode } from "react";

import { StepItemType } from "~/components/nav/enum";
import { type LinkItemDetails } from "~/components/nav/types";
import { getTransactionPageLink } from "~/components/transactions/filter-bar/FilterContext";
import { useDesign } from "~/hooks/useTheme";
import { useHighestRankingPlanUser } from "~/redux/auth";
import { useLang } from "~/redux/lang";
import { useGetActionsCountFromFilterQuery } from "~/state/actions";
import { useErpSettingsQuery } from "~/state/erp";
import { ErpSyncStatus, FilterOperators, Links } from "~/types/enums";
import { type FilterQuery, isBusinessPlanType } from "~/types/index";

function useTxPageLinkItem({
  id,
  name,
  filter,
  icon,
  countDisabled = false,
}: {
  id: string;
  name: string;
  filter: FilterQuery;
  icon: ReactNode;
  countDisabled?: boolean;
}): LinkItemDetails {
  const { tokens } = useDesign();
  const count = useGetActionsCountFromFilterQuery({
    filter,
    disabled: countDisabled,
  }).data;

  return {
    id,
    type: StepItemType.Link,
    name,
    to: getTransactionPageLink({
      state: {
        filter: {
          type: FilterOperators.And,
          rules: [filter],
        },
      },
    }),
    icon,
    endAdornment: count ? (
      <Box
        p="0.13rem 0.25rem"
        bgcolor={tokens.background.accent.neutral.medium}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="1.5rem"
      >
        <Typography
          variant="Metropolis/Caption/Medium/Regular"
          color={tokens.text.default}
        >
          {count}
        </Typography>
      </Box>
    ) : undefined,
  };
}

export function useTransactionsTab() {
  const lang = useLang();
  const highestRankingPlanUser = useHighestRankingPlanUser();
  const isBusiness = highestRankingPlanUser?.paidPlan
    ? isBusinessPlanType(highestRankingPlanUser?.paidPlan)
    : false;
  const isErpConnected = !!useErpSettingsQuery().data;

  const shouldShowErpOptions = isBusiness && isErpConnected;

  const syncedItem = useTxPageLinkItem({
    id: "transactions_synced",
    name: lang.navbar.transactionSubItems.synced,
    filter: {
      type: FilterOperators.ErpSyncStatus,
      value: [ErpSyncStatus.SyncSuccessful],
    },
    icon: <CheckCircleOutlineOutlined />,
    countDisabled: !shouldShowErpOptions,
  });

  const readyToSyncItem = useTxPageLinkItem({
    id: "transactions_ready_to_sync",
    name: lang.navbar.transactionSubItems.readyToSync,
    filter: {
      type: FilterOperators.ErpSyncStatus,
      value: [ErpSyncStatus.SyncNotRequested],
    },
    icon: <Sync />,
    countDisabled: !shouldShowErpOptions,
  });

  const notReadyToSyncItem = useTxPageLinkItem({
    id: "transactions_not_ready_to_sync",
    name: lang.navbar.transactionSubItems.notReadyToSync,
    filter: {
      type: FilterOperators.ErpSyncStatus,
      value: [ErpSyncStatus.NotReadyToSync],
    },
    icon: <SyncDisabled />,
    countDisabled: !shouldShowErpOptions,
  });

  const outOfDateItem = useTxPageLinkItem({
    id: "transactions_out_of_date",
    name: lang.navbar.transactionSubItems.outOfDate,
    filter: {
      type: FilterOperators.ErpSyncStatus,
      value: [ErpSyncStatus.SyncOutOfDate],
    },
    icon: <WarningAmber />,
    countDisabled: !shouldShowErpOptions,
  });

  const failedItem = useTxPageLinkItem({
    id: "transactions_sync_failed",
    name: lang.navbar.transactionSubItems.failed,
    filter: {
      type: FilterOperators.ErpSyncStatus,
      value: [ErpSyncStatus.SyncFailed],
    },
    icon: <ErrorOutline />,
    countDisabled: !shouldShowErpOptions,
  });

  const transactionSubItems: LinkItemDetails[] | undefined =
    shouldShowErpOptions
      ? [
          syncedItem,
          readyToSyncItem,
          notReadyToSyncItem,
          outOfDateItem,
          failedItem,
        ]
      : undefined;

  const transactionsTab: LinkItemDetails = {
    id: "transactions",
    type: StepItemType.Link,
    name: lang.navbar.transactions,
    to: Links.Transactions,
    icon: <SwapHoriz />,
    className: "tour-transactions",
    subItems: transactionSubItems,
  };

  return transactionsTab;
}
