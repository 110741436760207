import { EmbeddedLoginForm } from "~/components/user/EmbeddedLoginForm";
import { LoginForm } from "~/components/user/LoginForm";
import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { useDesign } from "~/hooks/useTheme";

export const LoginWrapper = () => {
  const { tokens } = useDesign();

  const isEmbedded = useIsEmbedded();

  document.body.style.backgroundColor = tokens.elevation.default;

  // If we're in an embedded context, show the embedded form
  if (isEmbedded) {
    return <EmbeddedLoginForm />;
  }

  // Otherwise show the regular login form
  return <LoginForm />;
};
