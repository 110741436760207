import { Typography } from "@mui/material";

import { type DataSet } from "~/components/developer/seed-data/index";
import { GeneralDialog } from "~/components/ui/GeneralDialog";
import { useCloneDataToSelfMutation } from "~/state/developer";

export const CloneDataSetModal = ({
  isOpen,
  handleClose,
  dataSet,
}: {
  isOpen: boolean;
  handleClose: () => void;
  dataSet: DataSet;
}) => {
  const mutation = useCloneDataToSelfMutation();

  const onSubmit = () => {
    mutation.mutateAsync({ dataSetId: dataSet._id }).then(handleClose);
  };

  return (
    <GeneralDialog
      isOpen={isOpen}
      title="Clone data into this account"
      handleClose={handleClose}
      actionText="Clone"
      handleAction={() => {
        onSubmit();
      }}
      pending={mutation.isPending}
    >
      <Typography>
        This will clone the data set into the account you are currently logged
        in to.
      </Typography>
      <Typography>
        Data currently in the account will not be replaced.
      </Typography>
    </GeneralDialog>
  );
};
